/* eslint-disable react/prop-types */

import React from "react";
import { Small, Medium, Big } from "./MediaQueryComponents";
import { Div, Text4, InfoIcon } from "components/Base";
import RequestableItems from "./RequestableItems";
import Summary from "./Summary";

const Section = ({ name, children }) => (
  <Div mb={5}>
    <Text4 pb={3} fw={3} color="neutral8">
      {name}
    </Text4>
    {children}
  </Div>
);

const List = ({
  mobile,
  readOnly,
  formattedSummary,
  requestableItems,
  removeLineItem
}) => (
  <Div>
    {readOnly ? (
      <Div
        bg="warning3"
        fw={3}
        p={5}
        bra={1}
        mt={2}
        mb={4}
        display="row.center.center"
      >
        <InfoIcon fs={5} size={20} mr={3} color="gray7" />
        <Div color="gray7">
          Your event admin has turned off the ability to add or update people.
          Please reach out to them if you need to make any changes.
        </Div>
      </Div>
    ) : null}
    <Section name={"Summary of Items"}>
      {!formattedSummary.length ? (
        <Div bg="neutral0" p={5} bra={1}>
          No items have been requested or assigned for this person.
        </Div>
      ) : (
        <Div bg="white" bra={1}>
          <Summary
            mobile={mobile}
            summarySections={formattedSummary}
            removeLineItem={readOnly ? undefined : removeLineItem}
          />
        </Div>
      )}
    </Section>
    <Section name={"Request Items"}>
      {!requestableItems.length ? (
        <Div bg="neutral0" p={5} bra={1}>
          There are no items to request.
        </Div>
      ) : (
        <RequestableItems mobile={mobile} requestableItems={requestableItems} />
      )}
    </Section>
  </Div>
);

const StatusAndItems = ({
  readOnly,
  formattedSummary,
  requestableItems,
  removeLineItem
}) => (
  <>
    <Small>
      <Div width={1}>
        <List
          {...{
            mobile: true,
            readOnly,
            formattedSummary,
            requestableItems,
            removeLineItem
          }}
        />
      </Div>
    </Small>
    <Medium>
      <Div width={1}>
        <List
          {...{
            mobile: true,
            readOnly,
            formattedSummary,
            requestableItems,
            removeLineItem
          }}
        />
      </Div>
    </Medium>
    <Big>
      <Div
        width={402}
        style={{
          marginRight: 25
        }}
      >
        <List
          {...{
            readOnly,
            formattedSummary,
            requestableItems,
            removeLineItem
          }}
        />
      </Div>
    </Big>
  </>
);

export default StatusAndItems;
