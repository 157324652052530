import React from "react";
import { connect } from "react-redux";
import { Div } from "components/Base";
import * as R from "ramda";

import { eventId } from "redux/modules/portal/selectors";
import { portalUser } from "redux/modules/portal/user/selectors";

import EventList from "Schedules/ScheduleActivitiesList";

const decorate = connect(state => ({
  eventId: eventId(state),
  portalUser: portalUser(state)
}));

const Schedule = ({ portalUser = {}, eventId = "" }) => {
  const accountType = R.pathEq(["active_view", "type"], "account")(portalUser);
  const recordId = accountType
    ? R.path(["active_view", "id"], portalUser)
    : R.prop(["user_contact_id"], portalUser);

  return (
    <EventList
      overflow={false}
      source="portal"
      eventId={eventId}
      recordId={recordId}
      showScheduleName={false}
      showActivityRecordModal={false}
      EmptyStateComponent={<Div>You have no upcoming activities.</Div>}
    />
  );
};

export default decorate(Schedule);
