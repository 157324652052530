import { all, takeEvery, fork, put, select, call } from "redux-saga/effects";
import { getters, actions } from "Portal/Settings/AddFolderModal/model";
import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { registerError } from "redux/modules/errors/actions";
import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  return {
    credentials,
    eventId
  };
};

const init = function*({ payload: { id, name } }) {
  yield put(actions.setInitialData({ id, name }));
};

const saveFolder = function*() {
  const id = yield select(getters.id);

  try {
    if (!id) {
      const { credentials, eventId } = yield call(getParams);
      const title = yield select(getters.name);

      const data = {
        eventId,
        title,
        isFolder: true,
        parentPageId: null,
        layout: null,
        backgroundImageUrl: null
      };

      yield call(Api.addPage, { credentials, data });
    }
    yield all([put(actions.closeModal()), put(actions.setName(""))]);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred adding folder"
          }
        ])
      )
    ]);
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchAddFolder = function*() {
  yield takeEvery(actions.saveFolder.type, saveFolder);
};

const rootSaga = function*() {
  yield all([fork(watchAddFolder), fork(watchInit)]);
};

export default rootSaga;
