import React from "react";
import {
  BigFilledButton,
  BigOutlineButton,
  CollapsablePanelType1,
  Div,
  BigShadedInput,
  SearchIcon
} from "components/Base";
import { Title } from "Passes/SetupCommon";

import TypesTable from "components/Event/Settings/Credentials/Tables/Types";

const addS = thing => (thing === 1 ? "" : "s");

const View = ({
  credentialGroups,
  onSearch,
  showAddCredentialGroupModal,
  showCredentialModal
}) => (
  <Div
    style={{
      width: "100%"
    }}
  >
    <Title>Pass Types</Title>
    <Div display="row.flex-start.center" pb={2}>
      <BigShadedInput
        mr={3}
        flex={1}
        placeholder="Search pass types"
        onChange={onSearch}
        continuous
        LeftIcon={SearchIcon}
      />
      <BigOutlineButton mr={1} onClick={showAddCredentialGroupModal}>
        Add Category
      </BigOutlineButton>
      <BigFilledButton bg="altB5" onClick={() => showCredentialModal()}>
        Add Pass
      </BigFilledButton>
    </Div>
    <Div pt={4}>
      {credentialGroups.map(
        ({
          id,
          name,
          countOfCredentials,
          onEdit,
          onDelete,
          credentials,
          moveGroupUp,
          moveGroupDown,
          collapsedDefault,
          forceCollapse,
          reorderCredentials
        }) => (
          <CollapsablePanelType1
            key={`${id}_${countOfCredentials}`}
            headingText={name}
            subText={`${countOfCredentials} pass type${addS(
              countOfCredentials
            )}`}
            menuItems={[
              ["Edit", onEdit],
              onDelete ? ["Delete", onDelete] : undefined,
              moveGroupUp ? ["Move up", moveGroupUp] : undefined,
              moveGroupDown ? ["Move down", moveGroupDown] : undefined
            ].filter(i => i)}
            forceCollapse={forceCollapse}
            collapsedDefault={collapsedDefault}
          >
            <TypesTable
              types={credentials}
              dragTypeId={"TypesTable" + id}
              onReorder={reorderCredentials}
            />
          </CollapsablePanelType1>
        )
      )}
    </Div>
  </Div>
);

export default View;
