import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  showAddContentModal: false,
  showSelectPassesModal: false,
  types: [],
  loadingItemTypes: false,
  itemTypes: [],
  data: {}
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    save: R.identity,
    update: R.identity,
    deleteContent: R.identity,
    showAddPassModal: R.identity,
    showEditPassModal: R.identity,
    saveSelectPassesModal: R.identity,
    goToCatalog: R.identity,
    downloadURL: R.identity,
    setInitialData: (_, { payload }) => ({
      types: payload.rows
    }),
    updateField: (state, { payload: { field, value } }) => ({
      data: R.assoc(field, value, state.data)
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
