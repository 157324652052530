import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { Div } from "components/Base";
import { withRouter } from "react-router";
import CommonReviewedCard from "Event/MyApprovals/View/Common/ReviewedCard";
import CardRow from "Event/MyApprovals/View/Common/CardRow";
import { getApprovedItems } from "Event/MyApprovals/selectors";

const decorate = connect(state => ({
  approvedItems: getApprovedItems(state)
}));

const ReviewedCard = ({ approvedItems, router: { push } }) =>
  !approvedItems.itemTypes.length && !approvedItems.modules.length ? null : (
    <CommonReviewedCard title="You've reviewed everything here:">
      {approvedItems.itemTypes.length ? (
        <Div mt={2}>
          {R.map(
            item => (
              <CardRow
                key={item.id}
                complete
                barText="100% reviewed"
                name={item.name}
                url={item.path}
                push={push}
                missing={item.pending}
                total={item.pending + item.approved + item.rejected}
                done={item.approved + item.rejected}
                id={item.id}
                btnText="Go View"
              />
            ),
            approvedItems.itemTypes
          )}
        </Div>
      ) : null}
      {approvedItems.modules.length ? (
        <Div
          {...{
            bt: approvedItems.itemTypes.length ? 1 : 0,
            mt: approvedItems.itemTypes.length ? 4 : 0,
            pt: approvedItems.itemTypes.length ? 4 : 0
          }}
          bc="gray2"
        >
          {R.map(
            item => (
              <CardRow
                key={item.id}
                complete
                barText="100% reviewed"
                name={item.name}
                url={item.path}
                push={push}
                missing={item.pending}
                total={item.pending + item.approved + item.rejected}
                done={item.approved + item.rejected}
                id={item.id}
                btnText="Go View"
              />
            ),
            approvedItems.modules
          )}
        </Div>
      ) : null}
    </CommonReviewedCard>
  );

export default R.compose(
  withRouter,
  decorate
)(ReviewedCard);
