import { REQUEST, RECEIVE } from "./constants";
import * as selectors from "./selectors";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getUserAccessLevels(userId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      const userIdToUse = userId || getState().user.user.id;

      if (!userIdToUse) {
        console.warn(
          "[Warning] Cannot fetch user access levels - No user ID available"
        );
        return false;
      }

      dispatch({ type: REQUEST });
      const { payload } = await api.get(
        getState().user.user.credentials,
        userIdToUse
      );
      dispatch({ type: RECEIVE, payload });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching user's access levels"
          }
        ])
      );
    }
  };
}
