/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  getDashboardDetails: ({ credentials, eventId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/virtual/event/${eventId}/dashboard`,
        credentials,
        success,
        error
      });
    }),
  updateEventDetails: ({ credentials, eventId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/virtual/event/${eventId}/details`,
        credentials,
        data,
        success,
        error
      });
    }),
  getEventAccountRecordTypes: (credentials, eventId) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/event/${eventId}/account-types`,
        credentials,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
