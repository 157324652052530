import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import { actions, getters } from "../model";
import css from "./styles.scss";
import { BigOutlineInput, TextAreaInput } from "components/Base";
import Toggle from "material-ui/Toggle";
import FileUpload from "./FileUpload";
import DatePicker from "components/Event/FormsV2/Modals/FormEditModal/Calendar";

const decorate = R.compose(
  connect(
    state => ({
      name: getters.name(state),
      dueDate: getters.dueDate(state),
      enableReminders: getters.enableReminders(state),
      criteria: getters.criteria(state)
    }),
    {
      setName: actions.setName,
      setDueDate: actions.setDueDate,
      toggleEnableReminders: actions.toggleEnableReminders,
      setCriteria: actions.setCriteria
    }
  ),
  CSSModules(css)
);

const Details = ({
  name,
  setName,
  dueDate,
  setDueDate,
  enableReminders,
  toggleEnableReminders,
  criteria,
  setCriteria
}) => (
  <div styleName="content">
    <div styleName="inlineLabel">
      <span>
        Name this file request<span styleName="required">*</span>
      </span>
    </div>
    <BigOutlineInput
      value={name}
      onChange={setName}
      continuous
      style={{
        flexShrink: 0
      }}
    />

    <div styleName="toggleGroup">
      <div styleName="inlineLabel">
        <span>
          Due date<span styleName="required">*</span>
        </span>
        <DatePicker value={dueDate} onChange={setDueDate} />
      </div>
    </div>

    <div styleName="toggleGroup">
      <div styleName="inlineLabel">Criteria / Instructions</div>
      <TextAreaInput
        style={{ resize: "none" }}
        onChange={setCriteria}
        value={criteria}
        continuous
      />
    </div>

    <div styleName="toggleGroup">
      <div styleName="inlineLabel">
        Include sample file(s) with this request
      </div>
      <div styleName="caption">
        Would you like to include documents/files with this request? E.g. a
        sample document or a blank document
      </div>
      <FileUpload />
    </div>
    {dueDate ? (
      <div styleName="toggleGroup">
        <Toggle
          label={<div styleName="inlineLabel">Enable reminders</div>}
          toggled={enableReminders}
          onToggle={toggleEnableReminders}
        />
        <div styleName="caption">
          Remind contacts assigned to this file to submit their response.
          Automated email reminders will be sent in the week before the due
          date.
        </div>
      </div>
    ) : null}
  </div>
);

export default decorate(Details);
