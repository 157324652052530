import React from "react";
import * as R from "ramda";
import { Div, Text6, DarkTab } from "components/Base";

import Users from "./Users";
import Permissions from "./Permissions";

const View = ({ activeTabValue, tabs }) => (
  <Div style={{ minHeight: "100%" }}>
    <Div bb={1} bc="gray3" bg="white" display="row.flex-start.flex-end">
      <Div flex={1}>
        <Text6 pl={8} pt={6}>
          Users
        </Text6>

        <Div pt={3} pl={8}>
          {R.map(([tabName, onClick, isActive]) => {
            return (
              <DarkTab
                key={tabName}
                px={2}
                fs={3}
                active={isActive}
                onClick={!isActive ? onClick : undefined}
              >
                {tabName}
              </DarkTab>
            );
          })(tabs)}
        </Div>
      </Div>
    </Div>
    <Div style={{ minHeight: "100%" }} pb={14}>
      {R.prop(activeTabValue, {
        editEventSettingsUsers: <Users />,
        editEventSettingsPermissions: <Permissions />
      })}
    </Div>
  </Div>
);

export default View;
