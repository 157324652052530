import { createHandlers } from "redux-mvc";
import * as R from "ramda";
import { NAMESPACE, COLORS } from "./constants";

const iniState = {
  typeName: "",
  typeId: "",
  items: "",
  groups: [],
  groupId: "",
  selectedColor: R.propOr("", "value", COLORS[0])
};

export const model = createHandlers({
  iniState,
  reducers: {
    cancelInstance: R.always(iniState),
    saveBulkItems: R.identity,
    setInitialData: (
      _,
      { payload: { typeId, typeName, groups, groupId } }
    ) => ({
      typeId,
      typeName,
      groups,
      groupId,
      selectedColor: R.propOr("", "value", COLORS[0]),
      items: ""
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
