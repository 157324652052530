import * as R from "ramda";
import { createSelector } from "reselect";
import moment from "moment";

import { CARD_ACTIONS, FILTER_ID, GROUP_FILTER_ID } from "./constants";
import { CREDENTIAL_TYPE_ID, MEAL_TYPE_ID } from "utils/item-types";

import { getters } from "./model";
import { getters as FilterControlGetters } from "ui-kit/FilterControls";
import { getters as SearchBarGetters } from "ui-kit/SearchBar";
import { getters as TabGetters } from "ui-kit/Tabs";

import { eventDetails } from "redux/modules/event/selectors";

import columns from "./View/Columns";

export const getAssigned = createSelector(
  R.prop("list", getters),
  R.reduce(
    ({ allocated, assigned }, { assignedCount, allocatedCount }) => ({
      allocated: allocated + allocatedCount,
      assigned: assigned + assignedCount
    }),
    { allocated: 0, assigned: 0 }
  )
);

export const getAssigneeCount = createSelector(
  R.prop("list", getters),
  R.compose(R.sum, R.map(R.prop("assigneeCount")))
);

export const getGroupIds = createSelector(
  R.prop("list", getters),
  R.compose(R.uniq, R.map(R.prop("groupId")))
);

export const getGroupOptions = createSelector(
  R.prop("recordTypes", getters),
  R.map(group => ({ id: R.prop("id", group), name: R.prop("name", group) }))
);

export const getSelectedGroupTypeId = createSelector(
  (state, props) =>
    FilterControlGetters.selectedOptions(state, {
      ...props,
      instanceId: GROUP_FILTER_ID
    }),
  R.compose(R.head, R.keys)
);

export const getSelectedGroupType = createSelector(
  getSelectedGroupTypeId,
  R.prop("recordTypes", getters),
  (id, groups) => R.find(R.propEq("id", id), groups)
);

export const getSelectedGroupStatus = createSelector(
  getSelectedGroupType,
  groupType => R.prop("guest_list_enabled", groupType)
);

export const getSelectedGroupPermissionSetId = createSelector(
  getSelectedGroupType,
  groupType => R.prop("permission_set_id", groupType)
);

export const getCards = createSelector(
  R.prop("list", getters),
  getSelectedGroupStatus,
  getSelectedGroupPermissionSetId,
  getAssigneeCount,
  getAssigned,
  (
    list,
    guestListEnabledStatus,
    permissionSetId,
    assigneeCount,
    { assigned, allocated },
    isEnabled,
    isLocked,
    closeDate
  ) => {
    const isPermissionSetValid = !R.isNil(permissionSetId);
    const isGuestListEnabled = guestListEnabledStatus && isPermissionSetValid;
    return [
      {
        id: "guests-attendees",
        subtitle: "Guests & Attendees",
        title: String(assigneeCount),
        actions: []
      },
      {
        id: "groups",
        title: String(R.propOr(0, "length", list)),
        subtitle: "Groups"
      },
      {
        id: "usage",
        title: `${
          allocated > 0 ? Math.round((assigned / allocated) * 100) : 0
        }%`,
        subtitle: "Allocation Usage",
        actions: [
          {
            id: CARD_ACTIONS.SEND_EMAIL,
            label: "Send Email"
          }
        ]
      },
      {
        id: "status",
        title: !guestListEnabledStatus
          ? "Not Enabled"
          : !isPermissionSetValid
          ? "Not configured"
          : "Open",
        titleStyle: {
          bg: isGuestListEnabled ? "teal5" : "red2",
          color: "white",
          bra: 1,
          py: 1,
          px: 2,
          fw: 3,
          fs: 1,
          uppercase: true,
          display: "inline"
        },
        subtitle: !guestListEnabledStatus
          ? "Guest List not enabled"
          : !isPermissionSetValid
          ? "No portal has been configured yet"
          : closeDate
          ? `Closes ${moment(new Date(closeDate)).fromNow()}`
          : "Accepting names & changes",
        prependTitleIcon: !isGuestListEnabled
          ? {
              name: "lock",
              color: "red9"
            }
          : {},
        prependSubtitleIcon: closeDate
          ? {
              name: "clock",
              color: "red9"
            }
          : {},
        actions: [
          isPermissionSetValid
            ? {
                id: CARD_ACTIONS.SETTINGS,
                label: "Settings"
              }
            : {
                id: CARD_ACTIONS.PORTALS,
                label: "Go to Portals"
              }
        ]
      }
    ];
  }
);

export const getPageTitle = createSelector(
  getSelectedGroupType,
  R.prop("name")
);

const sortedList = createSelector(
  R.prop("list", getters),
  R.sortBy(R.compose(R.toLower, R.defaultTo(""), R.prop("groupName")))
);

const searchedList = createSelector(
  sortedList,
  R.prop("searchTerm", SearchBarGetters),
  (list, searchTerm) =>
    R.isEmpty(searchTerm)
      ? list
      : R.filter(
          g =>
            g && g.groupName?.toLowerCase().includes(searchTerm.toLowerCase()),
          list
        )
);

const filteredList = createSelector(
  searchedList,
  R.prop("selectedTab", TabGetters),
  (list, selectedTab) => {
    if (selectedTab === FILTER_ID.NO_ALLOCATIONS) {
      return R.filter(R.propEq("allocatedCount", 0), list);
    }

    if (selectedTab === FILTER_ID.NO_CONTACTS) {
      return R.filter(
        item => R.path(["primaryContacts", "length"], item) === 0,
        list
      );
    }
    return list;
  }
);

export const getGroupItems = createSelector(
  filteredList,
  R.prop("page", getters),
  R.prop("pageSize", getters),
  (list, page, pageSize) => R.nth(page, R.splitEvery(pageSize, list))
);

export const getColumns = createSelector(eventDetails, event =>
  R.map(
    column => ({ ...column, Cell: columns[R.prop("id", column)] }),
    R.filter(R.identity, [
      {
        id: "guestListEnabled",
        name: "Status"
      },
      {
        id: "primaryContacts",
        name: "Primary Contact"
      },
      {
        id: "groupName",
        name: "Group Name"
      },
      {
        id: CREDENTIAL_TYPE_ID,
        name: "Passes Allocations",
        cellDiff: ({ row }) => R.prop("itemCounts", row)
      },
      R.path(["modules", "catering", "enabled"], event)
        ? {
            id: MEAL_TYPE_ID,
            name: "Meal Allocations",
            cellDiff: ({ row }) => R.prop("itemCounts", row)
          }
        : null,
      {
        id: "pendingRequests",
        name: "Pending Requests",
        cellDiff: ({ row }) => R.prop("itemCounts", row)
      },
      // {
      //   id: "allocationRequested",
      //   name: "Requested",
      //   cellDiff: ({ row }) => R.prop("itemCounts", row)
      // },
      // {
      //   id: "allocationAllocated",
      //   name: "Allocated",
      //   cellDiff: ({ row }) => R.prop("itemCounts", row)
      // },
      // {
      //   id: "allocationAssigned",
      //   name: "Assigned",
      //   cellDiff: ({ row }) => R.prop("itemCounts", row)
      // },
      // {
      //   id: "allocationIssued",
      //   name: "Issued",
      //   cellDiff: ({ row }) => R.prop("itemCounts", row)
      // },
      {
        id: "actions",
        name: "Actions"
      }
    ])
  )
);

export const getPages = createSelector(
  filteredList,
  R.prop("pageSize", getters),
  (list, pageSize) => Math.ceil(R.length(list) / pageSize)
);

export const getTotalGroups = createSelector(searchedList, R.length);

export const getFilterTabs = createSelector(getTotalGroups, total => [
  { id: R.prop("ALL", FILTER_ID), title: "Show All", count: total },
  { id: R.prop("NO_ALLOCATIONS", FILTER_ID), title: "No Allocations" },
  { id: R.prop("NO_CONTACTS", FILTER_ID), title: "No Primary Contacts" }
]);
