import React from "react";
import {
  Div,
  FontIcon,
  Text2,
  Text3,
  Text4,
  PopoverMenu,
  BigOutlineButton,
  SmallOutlineButton
} from "components/Base";

const GoRightIcon = () => (
  <FontIcon color="inherit">keyboard_arrow_right</FontIcon>
);
const CheckIcon = () => <FontIcon color="success5">check</FontIcon>;

const IntegrationCard = ({
  icon,
  name,
  activated = false,
  disabled = false,
  integrationItemName,
  notices,
  activateIntegration,
  goToIntegration,
  onEdit,
  onEditEvent,
  onEditSettings,
  onDelete,
  enableEvent,
  enableMapping,
  enableSettings
}) => (
  <Div p={4} mt={4} shadow={2} bra={1} bg="white">
    <Div display="row.flex-start.center">
      {icon && <Div pr={2}>{icon}</Div>}
      <Text4 flex={1}>{name}</Text4>
      {activated && (
        <PopoverMenu
          Label={({ onClick }) => (
            <SmallOutlineButton onClick={onClick} py={1}>
              <FontIcon fs={3}>more_horiz</FontIcon>
            </SmallOutlineButton>
          )}
          menuItems={[
            enableEvent ? ["Change event", onEditEvent] : null,
            enableMapping ? ["Update item mapping", onEdit] : null,
            enableSettings ? ["Settings", onEditSettings] : null,
            ["Disconnect integration", onDelete]
          ].filter(m => m)}
        />
      )}
    </Div>
    {!activated ? (
      <BigOutlineButton
        display="row.space-between.center"
        mt={2}
        mx={0}
        onClick={activateIntegration}
        disabled={disabled}
      >
        Activate Integration
        <GoRightIcon />
      </BigOutlineButton>
    ) : null}
    {/*
    <BigOutlineButton
      display="row.space-between.center"
      mt={2}
      mx={0}
      onClick={activated ? goToIntegration : activateIntegration}
    >
      {activated ? `Go to ${integrationItemName}` : "Activate Integration"}
      <GoRightIcon />
    </BigOutlineButton>
    */}
    {activated && (
      <Div display="row.flex-start.center" mt={4}>
        <Div width={3 / 5}>
          {notices.map(notice => (
            <Text2 italics color="yellow9" mb={2}>
              {notice}
            </Text2>
          ))}
        </Div>
        <Div width={2 / 5} display="row.center.center">
          <CheckIcon />
          <Text3 primary>Connected</Text3>
        </Div>
      </Div>
    )}
  </Div>
);

export default IntegrationCard;
