import React from "react";

import { connect } from "react-redux";

import * as R from "ramda";

import { actions, getters } from "Items/AssignQuestionsItemsModal";

import { getFilteredSets } from "Items/AssignQuestionsItemsModal/selectors";

import {
  Div,
  collapsableHandler,
  Collapsable,
  RightIcon,
  DownIcon,
  Text0,
  SmallCheckbox,
  Text2,
  DownFilledIcon,
  CheckIcon,
  MediumFilledButton
} from "components/Base";

import { addS, capitalizeFirst } from "utils/General";

const iconProps = {
  size: 24,
  color: "black"
};

const decorate = connect(
  state => ({
    sets: getFilteredSets(state),
    setsIds: getters.setsIds(state)
  }),
  {
    toggleSet: actions.toggleSet,
    refetchSets: () => actions.refetchSets()
  }
);

const SetCollapsable = collapsableHandler(
  ({
    toggleCollapsed,
    collapsed,
    name,
    questions = [],
    isEnabled,
    id,
    toggleSet,
    showEditQuestionSetQuestionsModal,
    count_of_questions
  }) => (
    <Div shadow={1} bg="white" bra={1} p={2} mt={1}>
      <Div width={1} display="row.flex-start.center">
        <SmallCheckbox
          onClick={() => toggleSet({ isEnabled, id })}
          selected={isEnabled}
        />
        <DownFilledIcon
          mx={1}
          color="black"
          size={24}
          style={{ transform: `rotate(${collapsed ? "-90" : "0"}deg)` }}
        />
        <Div width={1} display="row.flex-start.center">
          <Div
            display="row.space-between.center"
            flex={1}
            onClick={toggleCollapsed}
          >
            <Div>
              <Text2 bold color="black">
                {name}
              </Text2>
              <Div display="row.flex-start.center">
                <Text0 color="neutral6" bold>
                  {`${count_of_questions} question${addS(count_of_questions)}`}
                </Text0>
              </Div>
            </Div>
            <MediumFilledButton
              bg="neutral0"
              color="primary7"
              onClick={showEditQuestionSetQuestionsModal}
            >
              Manage Question Set
            </MediumFilledButton>
          </Div>
        </Div>
      </Div>
      {R.length(questions) > 0 && (
        <Collapsable collapsed={collapsed}>
          <Div>
            {R.map(
              question => (
                <Div
                  width={1}
                  bra={1}
                  px={2}
                  py={1}
                  bc="neutral2"
                  ba={1}
                  bg="white"
                  display="row.flex-start.center"
                  mt={2}
                >
                  <Div width={24}>
                    {isEnabled && <CheckIcon color="primary7" size={24} />}
                  </Div>
                  <Div
                    display="row.space-between.center"
                    flex={1}
                    pl={2}
                    style={{ width: "calc(100% - 24px)" }}
                  >
                    <Div width={1}>
                      <Div display="row.flex-start" width={1}>
                        <Text2
                          bold
                          color="black"
                          style={{
                            width: "100%",
                            overflow: "hidden",
                            textOverflow: "ellipsis"
                          }}
                        >
                          {question.name}
                        </Text2>
                        {question.required && (
                          <Text2 bold color="danger8" ml={1}>
                            *
                          </Text2>
                        )}
                      </Div>
                      <Div display="row.flex-start.center">
                        {/* <Text0 color="neutral6" bold>
                      2 options
                    </Text0>
                    <EigthSizeDotIcon color="neutral6" /> */}
                        <Text0 color="neutral6" bold>
                          {capitalizeFirst(question.type)}
                        </Text0>
                        {question.is_internal ? (
                          <Text0 color="neutral6" bold ml={1}>
                            &middot; Internal Question
                          </Text0>
                        ) : null}
                      </Div>
                    </Div>
                    {/* <Text1 bold color="neutral4">
                      Contains Price Modifiers
                    </Text1> */}
                  </Div>
                </Div>
              ),
              questions
            )}
          </Div>
        </Collapsable>
      )}
    </Div>
  )
);

const QuestionsSetsCollapsable = collapsableHandler(
  ({ toggleCollapsed, collapsed, sets, toggleSet, setsIds, handlers }) => {
    const setsLength = R.length(sets);
    return (
      <Div mb={3}>
        <Div mt={1}>
          <Div
            onClick={toggleCollapsed}
            fs={4}
            fw={3}
            display="row.space-between.flex-end"
            pr={1}
          >
            <Div display="row.flex-start.center">
              {collapsed ? (
                <RightIcon {...iconProps} />
              ) : (
                <DownIcon {...iconProps} />
              )}
              <Div color="neutral7" fw={3} fs={4}>
                Questions Sets
              </Div>
            </Div>
            <Text0 bold color="neutral5">
              {`${setsLength} question set${addS(setsLength)}`}
            </Text0>
          </Div>
          <Collapsable collapsed={collapsed}>
            {R.map(
              set => (
                <SetCollapsable
                  key={set.id}
                  {...set}
                  showEditQuestionSetQuestionsModal={() =>
                    handlers.showEditQuestionSetQuestionsModal(set.id)
                  }
                  toggleSet={toggleSet}
                  isEnabled={R.any(
                    selectedId => selectedId === set.id,
                    setsIds
                  )}
                />
              ),
              sets
            )}
          </Collapsable>
        </Div>
      </Div>
    );
  }
);

export default decorate(QuestionsSetsCollapsable);
