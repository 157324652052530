import React, { Fragment } from "react";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";
import * as R from "ramda";
import { withState, compose } from "recompose";
import { actions, getters } from "SalesPortal/ItemsModal";
import {
  Div,
  Text2,
  Text6,
  CloseIcon,
  BigFilledButton,
  MediumNumberSelector,
  NumberSelector,
  Text3,
  Text1,
  EigthSizeDotIcon,
  LeftIcon
} from "components/Base";
import { addS } from "utils/General";
import {
  getSelectedItems,
  getOrderedItems,
  getTotalCartItems
} from "SalesPortal/ItemsModal/selectors";
import { HEADER } from "SalesPortal/ItemsModal/constants";

const Mobile = props => <MediaQuery {...props} maxWidth={767} />;
const Tablet = props => <MediaQuery {...props} minWidth={768} />;
const Medium = props => <MediaQuery {...props} maxWidth={1023} />;
const Default = props => <MediaQuery {...props} minWidth={1024} />;
const LowerModal = props => <MediaQuery {...props} maxWidth={1242} />;
const HigherModal = props => <MediaQuery {...props} minWidth={1243} />;

const getItemPrice = item =>
  item.price
    ? item.price
    : item.prices && item.prices.length
    ? item.prices[0]
    : 0;

const decorate = connect(
  state => ({
    itemTypesList: getters.itemTypesList(state),
    selectedItems: getSelectedItems(state),
    orderedItems: getOrderedItems(state),
    totalCartItems: getTotalCartItems(state)
  }),
  {
    selectItemType: actions.selectItemType,
    updateItemQuantity: actions.updateItemQuantity,
    updateItemCartQuantity: actions.updateItemCartQuantity
  }
);

const AddingCart = ({
  orderedItems,
  updateItemCartQuantity,
  toggleShowCartWindow,
  showCartWindow,
  mobile = false,
  navigate,
  hideModal,
  redirectLink
}) => (
  <Div width={1} height={1} display="row">
    <Medium>
      <Div
        height={1}
        width={20}
        display="column.center.center"
        bl={1}
        br={1}
        bt={1}
        bc="neutral1"
        onClick={toggleShowCartWindow}
      >
        <Div
          style={{ transform: `rotate(${showCartWindow ? "180deg" : "0deg"})` }}
        >
          <LeftIcon />
        </Div>
      </Div>
    </Medium>
    <Div
      style={{
        width: `${mobile ? "calc(100% - 20px)" : "100%"}`,
        overflow: "auto"
      }}
      height={1}
      display="column.space-between"
      p={4}
    >
      <Div width={1}>
        <Text2 color="altB7" bold>
          {`Adding to cart ${
            orderedItems.length > 0
              ? `(${orderedItems.length} item${addS(orderedItems.length)})`
              : ""
          }`}
        </Text2>
        <Div width={1}>
          {R.map(
            item => (
              <Div key={item.id} display="row.space-between" mt={4}>
                <Div>
                  <Text3 bold>{item.name}</Text3>
                  <Div display="row.flex-start.center">
                    <Text1 color="neutral5">{`${item.quantity} x $${item.price}`}</Text1>
                    <EigthSizeDotIcon color="neutral5" />
                    <Text1
                      underline
                      color="neutral5"
                      onClick={() =>
                        updateItemCartQuantity({
                          quantity: 0,
                          itemId: item.id,
                          groupId: item.groupId
                        })
                      }
                    >
                      Delete
                    </Text1>
                  </Div>
                </Div>
                <NumberSelector
                  value={item.quantity}
                  onChangeValue={quantity =>
                    updateItemCartQuantity({
                      quantity,
                      itemId: item.id,
                      groupId: item.groupId
                    })
                  }
                />
              </Div>
            ),
            orderedItems
          )}
        </Div>
      </Div>
      <Div mt={3}>
        {/* <BigFilledButton
          width={1}
          mb={1}
          bg="neutral1"
          color="black"
          RightIcon={UpIcon}
          display="row.space-between.center"
        >
          {`Already in Cart ${totalCartItems > 0 ? `(${totalCartItems})` : ""}`}
        </BigFilledButton> */}
        {/*
        <Div display="row.space-between.center">
        <BigOutlineButton width={169} color="neutral4">
            ADD TO CART
          </BigOutlineButton>
          <img
            src="https://d2dks4tzxs6xee.cloudfront.net/img/credit-card-logos.png"
            width={113}
          />
        </Div>
      */}
        <BigFilledButton
          bg="info6"
          width={1}
          mt={1}
          onClick={() => {
            navigate(redirectLink);
            hideModal();
          }}
        >
          ADD & CHECKOUT
        </BigFilledButton>
      </Div>
    </Div>
  </Div>
);

const ItemsList = ({ selectedItems, updateItemQuantity, mobile = false }) => (
  <Div
    width={666}
    bc="neutral2"
    br={1}
    height={1}
    bg="neutral0"
    p={[2, 2, 6]}
    pr={[5, 5, 6]}
    style={{
      overflow: "auto",
      height: `calc(100% - ${mobile ? HEADER.MOBILE : HEADER.TABLET}px)`
    }}
  >
    {R.map(
      item => (
        <Div
          key={item.id}
          bg="white"
          bra={1}
          ba={1}
          bl={3}
          width={1}
          bc="neutral2"
          br={1}
          style={{ borderLeftColor: item.background_color }}
          display="column"
          p={[2, 2, 4]}
          mb={2}
        >
          <Div display="column">
            <Mobile>
              <Div fw={3} fs={[3, 3, 4]} color="black">
                {item.name}
              </Div>
            </Mobile>
            <Div display="row.space-between.center">
              <Mobile>
                <Div fs={3} color="black">
                  ${getItemPrice(item)}
                </Div>
              </Mobile>
              <Tablet>
                <Div>
                  <Div fw={3} fs={[4, 4, 4]} color="black">
                    {item.name}
                  </Div>
                  <Div display="row.flex-start.center">
                    <Div fs={3} color="black">
                      ${getItemPrice(item)}
                    </Div>
                    {/*<Div fs={0} ml={1}>
                    + $15 application fee
                  </Div>*/}
                  </Div>
                </Div>
              </Tablet>
              <Medium>
                <NumberSelector
                  value={item.quantity}
                  onChangeValue={quantity =>
                    updateItemQuantity({
                      quantity,
                      id: item.id,
                      price: getItemPrice(item)
                    })
                  }
                />
              </Medium>
              <Default>
                <MediumNumberSelector
                  value={item.quantity}
                  onChangeValue={quantity =>
                    updateItemQuantity({
                      quantity,
                      id: item.id,
                      price: getItemPrice(item)
                    })
                  }
                />
              </Default>
            </Div>
            {/*
          <Mobile>
            <Div fs={0} mt={1}>
              + $15 application fee
            </Div>
          </Mobile>
          */}
          </Div>
          {item.description && item.description.length ? (
            <Div
              bt={1}
              bc="gray4"
              mt={3}
              py={3}
              fs={2}
              color="gray7"
              style={{
                whiteSpace: "pre-wrap"
              }}
            >
              {item.description}
            </Div>
          ) : null}
        </Div>
      ),
      selectedItems
    )}
  </Div>
);

const Modal = ({
  addingCartProps,
  showCartWindow,
  hideModal,
  itemTypesList,
  selectItemType,
  ItemsListProps,
  higherModal = false,
  title
}) => (
  <Div
    width={higherModal ? 1243 : 1}
    height={[1, 1, 725]}
    bra={[0, 0, 1]}
    bg="white"
    style={{ position: "absolute", maxHeight: "90%" }}
  >
    <Div
      width={1}
      height={[HEADER.MOBILE, HEADER.MOBILE, HEADER.TABLET]}
      display="row.space-between.center"
      p={[4, 4, 6]}
      bc="neutral2"
      bb={1}
    >
      <Mobile>
        <Div display="row.flext-start.center">
          <Text2 bold>Select items for</Text2>
          <Text2 ml={2}>{title}</Text2>
        </Div>
      </Mobile>
      <Tablet>
        <Div display="row.flext-start.center">
          <Text6 bold>Select items for</Text6>
          <Text6 ml={3}>{title}</Text6>
        </Div>
      </Tablet>
      <CloseIcon
        size={24}
        color={{ default: "black", hover: "danger8" }}
        transition="fast"
        style={{ cursor: "pointer" }}
        onClick={hideModal}
      />
    </Div>
    <Div display="row" height={1}>
      <Div
        width={[150, 150, 257]}
        bc="neutral2"
        br={1}
        style={{
          height: `calc(100% - ${HEADER.TABLET}px)`
        }}
      >
        {R.map(
          item => (
            <Div
              key={item.id}
              width={1}
              height={[40, 40, 48]}
              fw={3}
              px={[4, 4, 6]}
              fs={[2, 2, 4]}
              display="column.center"
              onClick={() => selectItemType({ id: item.id })}
              bg={
                item.selected
                  ? "neutral7"
                  : { default: "white", hover: "neutral7" }
              }
              color={
                item.selected ? "white" : { default: "black", hover: "white" }
              }
              transition="fast"
              style={{ cursor: "pointer" }}
            >
              {item.name}
            </Div>
          ),
          itemTypesList
        )}
      </Div>
      <Mobile>
        <ItemsList {...ItemsListProps} mobile />
      </Mobile>
      <Tablet>
        <ItemsList {...ItemsListProps} />
      </Tablet>
      <Medium>
        <Mobile>
          <Div
            width={320}
            bg="white"
            style={{
              position: "absolute",
              right: "-320px",
              transform: `translateX(${showCartWindow ? "-320px" : "-18px"})`,
              height: `calc(100% - ${HEADER.MOBILE}px)`
            }}
            transition="fast"
          >
            <AddingCart {...addingCartProps} mobile />
          </Div>
        </Mobile>
        <Tablet>
          <Div
            width={320}
            bg="white"
            style={{
              position: "absolute",
              right: "-320px",
              transform: `translateX(${showCartWindow ? "-320px" : "-18px"})`,
              height: `calc(100% - ${HEADER.TABLET}px)`
            }}
            transition="fast"
          >
            <AddingCart {...addingCartProps} mobile />
          </Div>
        </Tablet>
      </Medium>
      <Default>
        <Div
          width={320}
          style={{
            height: `calc(100% - ${HEADER.TABLET}px)`
          }}
        >
          <AddingCart {...addingCartProps} />
        </Div>
      </Default>
    </Div>
  </Div>
);

const ItemsModal = ({
  handlers: { hideModal, title, navigate, redirectLink },
  itemTypesList,
  selectItemType,
  selectedItems,
  orderedItems,
  updateItemQuantity,
  updateItemCartQuantity,
  totalCartItems,
  showCartWindow,
  setShowCartWindow
}) => {
  const addingCartProps = {
    orderedItems,
    updateItemCartQuantity,
    totalCartItems,
    toggleShowCartWindow: () => setShowCartWindow(!showCartWindow),
    showCartWindow,
    navigate,
    hideModal,
    redirectLink
  };
  const ItemsListProps = { selectedItems, updateItemQuantity };
  const modalProps = {
    addingCartProps,
    showCartWindow,
    hideModal,
    itemTypesList,
    selectItemType,
    ItemsListProps,
    title
  };
  return (
    <Fragment>
      <LowerModal>
        <Modal {...modalProps} />
      </LowerModal>
      <HigherModal>
        <Modal {...modalProps} higherModal />
      </HigherModal>
    </Fragment>
  );
};

export default compose(
  withState("showCartWindow", "setShowCartWindow", false),
  decorate
)(ItemsModal);
