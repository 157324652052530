import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Panel from "./Panel";
import Title from "components/Global/Module/Title";
import Header from "components/Event/InventoryV2/Layout/Header";
import Container from "components/Global/Module/Container";
import Content from "components/Global/Module/Content";
import { debounce, orderBy } from "lodash";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import ShowHideSwitch from "components/Global/Module/Sidebar/ShowHideSwitch";
import EmptyState from "components/Global/Module/Management/EmptyState";

const titleHash = {
  approved: "Approved Needs",
  pending: "Pending Needs"
};

@CSSModules(css)
class ByStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRows: [],
      loading: false
    };

    this.debouncedFetchParents = debounce(this.fetchParents, 50);
  }

  componentDidMount() {
    this.initialFetch();
  }

  componentWillReceiveProps(props) {
    if (props.status !== this.props.status) {
      this.initialFetch();
    }
  }

  componentWillUnmount() {
    this.debouncedFetchParents.cancel();
  }

  @autobind
  setSelectedRows(selectedRows) {
    this.setState({
      selectedRows
    });
  }

  async initialFetch() {
    this.setState({
      loading: true
    });

    await this.fetchParents();
    this.setState({
      loading: false
    });
  }

  sort(parents) {
    return orderBy(parents, [parent => parent.name], ["asc"]);
  }

  fetchParents = async () => {
    await this.props.getModulesWithCatalogItems(this.props.eventDetails.id);
    return Promise.all(
      this.props.parents.map(moduleDetails =>
        this.props.getModuleCounts(moduleDetails.id)
      )
    );
  };

  render() {
    const { location, parents, status, panelCachedCount } = this.props;

    const panels = this.sort(parents).reduce((list, parent) => {
      const count = panelCachedCount(parent.id, status);
      if (count) {
        return [
          ...list,
          <Panel
            key={parent.id}
            defaultOpen={list.length === 0}
            status={status}
            parent={parent}
            fetchParents={this.fetchParents}
            location={location}
            cachedCountOfRecords={count}
          />
        ];
      }
      return list;
    }, []);

    return (
      <Container>
        <Header>
          <ShowHideSwitch moduleId="inventory" />
          <Title>
            <div className={css.title}>{titleHash[status]}</div>
          </Title>
        </Header>
        <Content loading={this.state.loading} style={{ padding: 15 }}>
          {panels.length ? (
            panels
          ) : (
            <EmptyState>There are no {status} items for review.</EmptyState>
          )}
        </Content>
      </Container>
    );
  }
}

ByStatus.propTypes = {
  status: PropTypes.string.isRequired,
  getModuleCounts: PropTypes.func.isRequired,
  parents: PropTypes.arrayOf(PropTypes.object).isRequired,
  location: PropTypes.shape({
    query: PropTypes.object
  }).isRequired,
  eventDetails: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  getModulesWithCatalogItems: PropTypes.func.isRequired,
  panelCachedCount: PropTypes.func.isRequired
};

export default ByStatus;
