import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Div, MediumFilledButton, SmallCheckbox } from "components/Base";
import * as R from "ramda";
import { noop } from "utils/General";
import { bindInstance } from "redux-mvc";

import module, { actions } from "ui-kit/MiniItemsSelector";
import rootSaga from "ui-kit/MiniItemsSelector/sagas";

import {
  getItemGroups,
  getHasSearchTerm,
  getSelectedItemsMap,
  getSelectedCount,
  getLoading
} from "../selectors";
import { MODE_SINGLE_SELECT } from "../constants";

import LoadingOverlay from "ui-kit/LoadingOverlay";
import SearchBar from "ui-kit/SearchBar/View";
import { getItemId } from "ui-kit/MiniItemsSelector/utils";

import { VARIANTS } from "ui-kit/Theme";

module.setRootSaga(rootSaga);

const decorate = connect(
  (state, props) => ({
    itemGroups: getItemGroups(state, props),
    selectedItemsMap: getSelectedItemsMap(state, props),
    hasSearchTerm: getHasSearchTerm(state, props),
    countOfSelected: getSelectedCount(state, props),
    loading: getLoading(state, props)
  }),
  bindInstance({
    selectAllItems: actions.selectAll,
    deselectAllItems: actions.clearAll,
    selectGroup: actions.selectGroup,
    deselectGroup: actions.deselectGroup,
    toggleItem: actions.toggleItem,
    init: actions.init
  })
);

const MiniItemsSelector = ({
  selectedItemsMap = {},
  itemGroups = [],
  iniSelected = [],
  countOfSelected = 0,
  hasSearchTerm = false,
  buttonLabel = "Save",
  iniClearSearch = false,
  instanceId,
  deselectAllItems = noop,
  selectGroup = noop,
  deselectGroup = noop,
  selectAllItems = noop,
  toggleItem = noop,
  onDone = noop,
  init = noop,
  loading = false
}) => {
  useEffect(() => {
    init({
      clearSearch: iniClearSearch,
      selectedItems: Array.isArray(iniSelected)
        ? iniSelected
        : R.keys(iniSelected),
      mode: MODE_SINGLE_SELECT
    });
  }, []);

  return (
    <Div
      width={1}
      bg="gray0"
      display="column.flex-start.stretch"
      flex={1}
      style={{ overflow: "auto", position: "relative" }}
    >
      {loading && <LoadingOverlay />}
      <SearchBar
        mb={5}
        bra={0}
        width={1}
        instanceId={instanceId}
        showClear={true}
        variant={VARIANTS.SURFACE}
        style={{ flexShrink: 0 }}
      />
      <Div style={{ overflowY: "auto", flex: "1 1 auto" }}>
        {itemGroups.length ? (
          R.map(
            group => (
              <Div key={group.id} mb={4}>
                <Div
                  uppercase
                  fs={1}
                  fw={3}
                  color="gray6"
                  px={5}
                  display="row.space-between.center"
                >
                  <Div truncate>{group.name}</Div>
                  {!hasSearchTerm ? (
                    <Div display="row.flex-end.center">
                      <Div mr={2}>Select</Div>
                      <Div
                        mr={2}
                        color="purple8"
                        underline
                        onClick={() => selectGroup(group)}
                      >
                        All
                      </Div>
                      <Div
                        color="purple8"
                        underline
                        onClick={() => deselectGroup(group)}
                      >
                        None
                      </Div>
                    </Div>
                  ) : null}
                </Div>
                <Div px={4}>
                  {R.map(
                    item => (
                      <Div
                        key={item.id}
                        shadow={1}
                        bra={1}
                        bg="white"
                        my={2}
                        px={1}
                        py={1}
                        display="row.flex-start.center"
                        onClick={
                          item.disabled
                            ? undefined
                            : () =>
                                toggleItem({
                                  id: getItemId(item)
                                })
                        }
                      >
                        <SmallCheckbox
                          selected={selectedItemsMap[getItemId(item)]}
                          disabled={item.disabled}
                        />
                        <Div
                          truncate
                          ml={2}
                          fw={3}
                          color={item.disabled ? "gray4" : "gray7"}
                        >
                          {item.name}
                        </Div>
                      </Div>
                    ),
                    group.items
                  )}
                </Div>
              </Div>
            ),
            itemGroups
          )
        ) : (
          <Div display="row.center.center" my={8} fs={2}>
            {hasSearchTerm
              ? "No items matched your search"
              : "No items are available"}
          </Div>
        )}
      </Div>
      <Div
        display="row.space-between.center"
        bg="white"
        px={4}
        py={2}
        data-cy="miniItemsFooter"
      >
        <Div>
          <Div fw={3} color="gray7">
            {countOfSelected} selected
          </Div>
          <Div fs={1} fw={3} display="row.flex-start.center">
            {!hasSearchTerm ? (
              <Div
                onClick={() => selectAllItems(itemGroups)}
                color="purple8"
                underline
                mr={2}
              >
                Select all
              </Div>
            ) : null}
            {!hasSearchTerm ? (
              <Div
                onClick={() => deselectAllItems(itemGroups)}
                color="purple8"
                underline
              >
                Clear selected
              </Div>
            ) : null}
          </Div>
        </Div>
        <MediumFilledButton bg="altB5" onClick={() => onDone(selectedItemsMap)}>
          {buttonLabel}
        </MediumFilledButton>
      </Div>
    </Div>
  );
};

export default decorate(MiniItemsSelector);

export { SelectAndCreateVariants } from "./SelectAndCreateVariants";
