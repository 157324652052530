import Helpers from "utils/Global/Helpers";

export default {
  getActivityLogs: (credentials, eventId, orgId, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/activity/event/${eventId}`,
        qs: { eventId, orgId, ...data },
        credentials,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
