import PropTypes from "prop-types";
import React from "react";
import EditorBase from "./TableConnector";
import DropDown from "components/Global/Editors/DropdownEditor";

export default class DropDownEditor extends EditorBase {
  render() {
    return (
      <DropDown
        {...this.props}
        style={{
          width: "100%",
          height: 35
        }}
        value={this.state}
        onChange={this.handleChange}
      />
    );
  }
}

DropDownEditor.propTypes = {
  ...EditorBase.propTypes,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.objectOf({
        id: PropTypes.string,
        title: PropTypes.string,
        meta: PropTypes.string
      })
    ])
  ).isRequired
};
