import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  recordTypeId: null,
  activeReport: null
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    goToReport: R.identity
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
