import Handler from "components/Organization";
import Home from "Organizations/Home/View";
import SettingsHandler from "components/Organization/Settings";
import GeneralSettings from "components/Organization/Settings/General";
import UsersSettings from "components/Organization/Settings/Users";
import SettingsModule from "components/Event/Settings/Module";
import ContactProfile from "components/Organization/ContactProfile";
import AccountProfile from "components/Organization/AccountProfile";
import Main from "Organizations/Main/View";
import Events from "Organizations/Events/View";
import Files from "Files/Manage/View";

import { hot } from "App/Config/hot";

const Organization = {
  Handler,
  Home,
  SettingsHandler,
  GeneralSettings,
  UsersSettings,
  SettingsModule,
  ContactProfile,
  AccountProfile,
  Main,
  Events,
  Files
};

export default hot(Organization);
