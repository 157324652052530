import React from "react";
import {
  Div,
  CheckCircleIcon,
  Text3,
  Text4,
  ErrorIcon,
  SuperFilledButton,
  DownloadIcon,
  SuperOutlineButton
} from "components/Base";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "Modules/ImportModal";
import { PAGES } from "Modules/ImportModal/constants";
import { getCountOfRowsWithErrors } from "Modules/ImportModal/selectors";
import { navigateTo, addS } from "utils/General";
import AcceptableFormats from "Modules/ImportModal/View/AcceptableFormats";

const decorate = connect(
  state => ({
    fieldGroups: getters.fieldGroups(state),
    uploadCsvErrors: getters.uploadCsvErrors(state),
    countOfRowsWithErrors: getCountOfRowsWithErrors(state)
  }),
  {
    uploadFile: () => actions.uploadFile(),
    back: () => actions.setSelectedPage(PAGES.MAP_CSV)
  }
);

const InvalidRows = ({
  uploadFile,
  back,
  uploadCsvErrors: {
    summaryErrors,
    errorsfile: { url },
    counters: { rows_ok: totalRowsOK, rows_number: totalRecordsProcessed }
  },
  countOfRowsWithErrors,
  handlers: { hideModal }
}) => (
  <Div display="column" flex={1} height={1}>
    <Div
      p={5}
      display="row.center.flex-start"
      bb={1}
      bc="neutral2"
      height={1}
      style={{ overflowY: "auto" }}
    >
      <Div width={755} display="column">
        <Div
          bra={1}
          height={48}
          bg="success1"
          py={2}
          px={4}
          display="row.flex-start.center"
        >
          <CheckCircleIcon color="neutral6" />
          <Text4 ml={3}>{`${totalRowsOK} of ${totalRecordsProcessed} row${addS(
            totalRecordsProcessed
          )} passed validation checks`}</Text4>
        </Div>
        <Div mt={3} bra={1} bg="danger1" pb={5} pt={1} px={4} display="row">
          <Div display="row">
            <Div bra={1} height={48} display="row.flex-start.center">
              <ErrorIcon color="danger6" />
            </Div>
          </Div>

          <Div display="column" ml={3}>
            <Div bra={1} height={48} display="row.flex-start.center">
              <Text4 bold>{`${countOfRowsWithErrors} row${addS(
                countOfRowsWithErrors
              )} with issues`}</Text4>
            </Div>
            <Div display="column">
              {R.compose(
                R.map(error => (
                  <Div
                    display="row.flex-start.center"
                    minHeight={30}
                    key={error.id}
                    py={2}
                    bb={1}
                    bc="danger2"
                  >
                    <Text3>{`${error.description}:`}</Text3>
                    <Text3 ml={1} bold>
                      Row
                      {addS(error.rows.length)} {R.join(", ", error.rows)}
                    </Text3>
                  </Div>
                )),
                R.filter(e => e.rows.length)
              )(summaryErrors)}
              <SuperFilledButton
                LeftIcon={DownloadIcon}
                width={290}
                bg="white"
                color="black"
                leftIconColor="black"
                mt={2}
                onClick={() => {
                  navigateTo(url);
                }}
              >
                Download Error List as CSV
              </SuperFilledButton>
            </Div>
          </Div>
        </Div>
        <AcceptableFormats />
      </Div>
    </Div>
    <Div
      display="row.space-between.center"
      p={3}
      style={{
        flexShrink: 0
      }}
    >
      <SuperOutlineButton onClick={back}>Back</SuperOutlineButton>
      <Div display="row">
        <SuperOutlineButton onClick={hideModal}>Cancel</SuperOutlineButton>
        <SuperFilledButton ml={3} width={250} bg="altB5" onClick={uploadFile}>
          Re-Upload Resolved CSV
        </SuperFilledButton>
      </Div>
    </Div>
  </Div>
);

export default decorate(InvalidRows);
