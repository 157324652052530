import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import { STATUS_COLORS } from "EventLight/Portals/Pages/constants";
import { Popover, Div } from "components/Base";
import { actions } from "EventLight/Portals/Pages/model";
import css from "./style.scss";
import DeleteConfirmation from "ui-kit/DeleteConfirmation";
import moment from "moment";

const ActionsBtn = ({ onClick }) => (
  <div styleName="actions-btn" onClick={onClick}>
    <i className="material-icons">flash_on</i>
    <div styleName="actions-text">Actions</div>
    <i className="material-icons" style={{ color: "#A8A7AA" }}>
      expand_more
    </i>
  </div>
);

const decorate = connect(
  null,
  {
    deleteItem: actions.deleteItem,
    openAddFolderModal: actions.openAddFolderModal,
    openAddPageModal: actions.openAddPageModal
  }
);

const Row = ({
  name,
  type,
  id,
  toggleCompressedList,
  isExpanded,
  modified,
  visible_to,
  status,
  openAddFolderModal,
  deleteItem,
  paddingLeft,
  openAddPageModal
}) => (
  <div styleName="row">
    <div styleName="name" style={{ width: `${370 - paddingLeft}px` }}>
      {type === "folder" ? (
        <div styleName="folder">
          <span
            styleName="arrow-wrapper"
            style={{ transform: `rotate(${isExpanded ? 90 : 0}deg)` }}
            onClick={() => toggleCompressedList({ isExpanded, id })}
          >
            <i class="material-icons">arrow_right</i>
          </span>
          <div styleName="folder-icon">
            <div styleName="curve" />
            <div styleName="top" />
            <div styleName="bottom" />
          </div>
        </div>
      ) : (
        <div styleName="page" onClick={() => openAddPageModal({ id })}>
          <i class="material-icons">open_in_new</i>
        </div>
      )}
      <div
        styleName="name-text"
        style={{ fontWeight: type === "folder" ? 600 : "normal" }}
      >
        {name}
      </div>
    </div>
    <div styleName="modified">
      {moment(modified).format("YYYY-MM-DD, h:mm A") || "-"}
    </div>
    {/* <div styleName="visible">{visible_to || "-"}</div> */}
    <div styleName="status">
      {status ? (
        <>
          <div
            styleName="status-circle"
            style={{
              background: R.propOr("black", "light", STATUS_COLORS[status])
            }}
          />
          <div
            styleName="status-text"
            style={{
              color: R.propOr("black", "dark", STATUS_COLORS[status])
            }}
          >
            {status}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
    <div styleName="actions">
      <Popover Label={CSSModules(css)(ActionsBtn)}>
        {({ closePopover }) => (
          <Div width={120}>
            <Div
              display="row.center.center"
              px={2}
              py={4}
              bg={{ default: "white", hover: "neutral0" }}
              transition="fast"
              onClick={() => {
                if (type === "folder") {
                  openAddFolderModal({ id, folderName: name });
                } else {
                  openAddPageModal({ id });
                }
                closePopover();
              }}
            >
              Edit {type === "folder" ? "Folder" : "Page"}
            </Div>
            <DeleteConfirmation
              key={id}
              onConfirm={() => {
                deleteItem({ id });
                closePopover();
              }}
              text="Are you sure?"
            >
              <Div
                display="row.center.center"
                px={2}
                py={4}
                bg={{ default: "white", hover: "neutral0" }}
                transition="fast"
              >
                Delete {type === "folder" ? "Folder" : "Page"}
              </Div>
            </DeleteConfirmation>
          </Div>
        )}
      </Popover>
    </div>
  </div>
);

export default R.compose(
  decorate,
  CSSModules(css)
)(Row);
