import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";
import { ORDER_DETAILS_BLOCK_TYPE } from "components/Event/FormsV2/Form/Views/V3_Edit/Shared/constants/block-types";

import Controller from "./Controller";
import { getFields } from "redux/modules/modules/fields/actions";
import {
  toggleField,
  updateField,
  updateFields
} from "redux/modules/formsV2/form/fields/customerBlockFields/actions";
import { refreshForm } from "redux/modules/formsV2/form/actions";
import { updateField as updateFormField } from "redux/modules/formsV2/form/fields/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";

import { fields } from "redux/modules/modules/fields/selectors";
import { form } from "redux/modules/formsV2/form/selectors";

function mapStateToProps(state) {
  const formToPass = form(state);
  const orderReadOnlyFields = resolveReadOnlyFields({
    moduleId: STANDARD_MODULE_IDS.orders.id
  });
  const field = formToPass.fields.find(
    f => f.type === ORDER_DETAILS_BLOCK_TYPE
  );
  const customerBlockFields = field ? field.customer_block_fields : [];

  return {
    form: formToPass,
    eventId: formToPass.event_id,
    field,
    customerBlockFields,
    orderFields: fields(state, STANDARD_MODULE_IDS.orders.id).filter(
      f => !orderReadOnlyFields.includes(f.id)
    )
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleField,
      updateField,
      updateFields,
      getFields,
      refreshForm,
      updateFormField,
      showModal,
      hideModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controller);
