import React from "react";
import * as R from "ramda";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import { actions, getters } from "../model";
import { getIsEditing, getIsSaveValid } from "../selectors";
import { FIELD_IDS, rowCheckBoxData } from "../constants";
import css from "./styles.scss";
import RowCheckboxInput from "./RowCheckBoxInput";
import { Input } from "ui-kit/Form/View";
import Loading from "ui-kit/Loading";
import { MediumFilledButton } from "components/Base";

const decorate = R.compose(
  connect(
    state => ({
      loading: getters.modalLoading(state),
      data: getters.modalData(state),
      isEditing: getIsEditing(state),
      isValid: getIsSaveValid(state)
    }),
    {
      hideModal: () => actions.setShowAddWebhookModal(false),
      save: actions.save
    }
  ),
  CSSModules(css)
);

const Layout = ({ loading, hideModal, data, isEditing, save }) => (
  <MiniModalWrapper
    showModal={true}
    hideModal={hideModal}
    title={`${isEditing ? "Edit" : "Add"} Webhook`}
    width={500}
    height={370}
    stopPropagation
    style={{ maxWidth: "calc(100% - 10px)" }}
  >
    {loading ? (
      <div styleName="wrapper">
        <Loading />
      </div>
    ) : (
      <div styleName="wrapper">
        <div styleName="content">
          <div styleName="fieldWrapper">
            <div styleName="requiredLabel">Webhook URL</div>
            <Input
              fieldId={FIELD_IDS.URL}
              iniValue={R.prop(FIELD_IDS.URL, data)}
              width={1}
            />
          </div>

          <div styleName="fieldWrapper">
            <div styleName="label" style={{ marginBottom: 8 }}>
              Triggers
            </div>
            <>
              {R.map(
                ({ fieldId, label }) => (
                  <RowCheckboxInput
                    fieldId={fieldId}
                    label={label}
                    data={data}
                  />
                ),
                rowCheckBoxData
              )}
            </>
          </div>
        </div>
        <div styleName="footer">
          <MediumFilledButton bg="altB7" onClick={save}>
            {isEditing ? "Update" : "Save"}
          </MediumFilledButton>
        </div>
      </div>
    )}
  </MiniModalWrapper>
);

export default decorate(Layout);
