import React from "react";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { connect } from "react-redux";
import { actions, getters } from "../model";
import {
  getAccountRecordTypes,
  getContactRecordTypes,
  getAssignedToGroupTypesMapped
} from "../selectors";
import RecordCollapsible from "./RecordCollapsible";
import { TYPES } from "EventLight/FileRequests/AddAssignmentModal/constants";

const decorate = R.compose(
  connect(
    state => ({
      accountsCollapsed: getters.accountsCollapsed(state),
      contactsCollapsed: getters.contactsCollapsed(state),
      assignedToGroups: getters.assignedToGroups(state),
      assignedToPeople: getters.assignedToPeople(state),
      accountRecordTypes: getAccountRecordTypes(state),
      contactRecordTypes: getContactRecordTypes(state),
      assignedToGroupTypes: getAssignedToGroupTypesMapped(state)
    }),
    {
      setAccountsCollapsed: actions.setAccountsCollapsed,
      toggleAccountRecordType: actions.toggleAccountRecordType,
      selectAllAccountRecordTypes: actions.selectAllAccountRecordTypes,
      deselectAllAccountRecordTypes: actions.deselectAllAccountRecordTypes,
      setContactsCollapsed: actions.setContactsCollapsed,
      toggleContactRecordType: actions.toggleContactRecordType,
      selectAllContactRecordTypes: actions.selectAllContactRecordTypes,
      deselectAllContactRecordTypes: actions.deselectAllContactRecordTypes,
      showAccountAddAssignmentModal: actions.showAccountAddAssignmentModal,
      showContactAddAssignmentModal: actions.showContactAddAssignmentModal,
      removeAssignedGroupId: actions.removeAssignedGroupId,
      removeAssignedContactId: actions.removeAssignedContactId,
      openDueDateModal: actions.openDueDateModal
    }
  ),
  CSSModules(css)
);

const Assign = ({
  accountsCollapsed,
  setAccountsCollapsed,
  accountRecordTypes,
  toggleAccountRecordType,
  selectAllAccountRecordTypes,
  deselectAllAccountRecordTypes,
  contactsCollapsed,
  setContactsCollapsed,
  contactRecordTypes,
  selectAllContactRecordTypes,
  deselectAllContactRecordTypes,
  toggleContactRecordType,
  showAccountAddAssignmentModal,
  showContactAddAssignmentModal,
  assignedToGroups,
  assignedToPeople,
  removeAssignedGroupId,
  removeAssignedContactId,
  openDueDateModal
}) => (
  <div styleName="content">
    <div style={{ marginBottom: 8 }}>
      <RecordCollapsible
        title="Assign to Groups"
        recordCollapsed={accountsCollapsed}
        toggleRecordCollapsed={() => setAccountsCollapsed(!accountsCollapsed)}
        recordList={accountRecordTypes}
        selectAllRecords={selectAllAccountRecordTypes}
        deselectAllRecords={deselectAllAccountRecordTypes}
        toggleSelectedRecord={toggleAccountRecordType}
        showModal={showAccountAddAssignmentModal}
        btnText="Assign to group"
        selectedText="Specific groups with access to this form"
        selectedList={assignedToGroups}
        unassign={removeAssignedGroupId}
        openDueDateModal={openDueDateModal}
        type={TYPES.ACCOUNT}
      />
    </div>
    <RecordCollapsible
      title="Assign to People"
      recordCollapsed={contactsCollapsed}
      toggleRecordCollapsed={() => setContactsCollapsed(!contactsCollapsed)}
      recordList={contactRecordTypes}
      selectAllRecords={selectAllContactRecordTypes}
      deselectAllRecords={deselectAllContactRecordTypes}
      toggleSelectedRecord={toggleContactRecordType}
      showModal={showContactAddAssignmentModal}
      btnText="Assign to person"
      selectedText="Specific people with access to this form"
      selectedList={assignedToPeople}
      unassign={removeAssignedContactId}
      openDueDateModal={openDueDateModal}
      type={TYPES.CONTACT}
    />
  </div>
);

export default decorate(Assign);
