import * as R from "ramda";
import { createHandlers } from "redux-mvc";

export const model = createHandlers({
  iniState: {
    tableIds: []
  },
  reducers: {
    addTableId: ({ tableIds }, { payload: tableId }) => ({
      tableIds: R.uniq(R.append(tableId, tableIds))
    })
  },
  namespace: "PeopleTable"
});

const { actions, getters } = model;

export { actions, getters };

export default model;
