import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: false,
  searching: false,
  showAddModal: false,
  showEmptyState: false,
  data: [],
  details: {
    name: "",
    completion_method: "click",
    description: "",
    url: "",
    id: undefined
  }
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    save: R.identity,
    update: R.identity,
    updateField: (state, { payload: { field, value } }) => ({
      details: R.assoc(field, value, state.details)
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
