import React from "react";
import { get } from "lodash";
import { baseIcon, baseText, baseContainer } from "../_base";

const getUserId = value => get(value, ["meta", "userId"]);
const getUserName = (id, references) => get(references, ["users", id]);

const UserSubmission = ({ value, references }) => {
  const userName = getUserName(getUserId(value), references);
  return (
    <div style={baseContainer}>
      <i className="material-icons" style={baseIcon}>
        person_outline
      </i>
      <span title={userName} style={baseText}>
        {userName}
      </span>
    </div>
  );
};

UserSubmission.defaultProps = {
  references: {},
  value: {}
};

export default UserSubmission;
