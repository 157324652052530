import * as R from "ramda";
import React from "react";
import { all, fork, call, select, spawn, take, put } from "redux-saga/effects";
import { push } from "react-router-redux";

import { makeFuture } from "utils/General/sagas";
import { apiCall } from "App/Data/sagas";

import { actions as ActionComps } from "PortalHub/ActionComponents/model";
import { actions as TableActions } from "ui-kit/Table/model";
import { showModal } from "redux/modules/modal/actions";
import { registerError } from "redux/modules/errors/actions";

import { CARD_ACTIONS, SECTION_ACTIONS } from "../constants";
import { ROW_ACTIONS } from "ui-kit/Table/constants";
import { eventDetails as getEventDetails } from "redux/modules/portal/selectors";
import { portalUser as getPortalUser } from "redux/modules/portal/user/selectors";
import { user as getUser } from "redux/modules/user/selectors";

import DocumentRequestModal from "Portal/PortalDocuments/RequestModal";
import OrderModal from "Orders/OrderModal/View";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

const viewAllAlerts = function*() {
  const event = yield select(getEventDetails);
  yield put(push(`/hubs/${event.slug}/${event.uuid}/alerts`));
};

const viewAlert = function*({ data }) {
  const event = yield select(getEventDetails);
  yield put(push(`/hubs/${event.slug}/${event.uuid}/alerts/${data.id}`));
};

const viewPeopleReport = function*({ data }) {
  const event = yield select(getEventDetails);
  yield put(
    push(`/hubs/${event.slug}/${event.uuid}/people/${data.id}/reports`)
  );
};

const managePeople = function*({ data }) {
  const event = yield select(getEventDetails);
  yield put(push(`/hubs/${event.slug}/${event.uuid}/people/${data.id}`));
};

const submitDocument = function*({ data }) {
  const handleCreate = yield call(makeFuture);
  yield put(
    showModal({
      content: (
        <DocumentRequestModal
          view="admin"
          recordId={data.id}
          requestTypeId={data.meta.requestTypeId}
          accountId={data.meta.accountId}
          contactId={data.meta.contactId}
          onDone={handleCreate.done}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleCreate.onRealized);
};

const viewFile = function*({ data }) {
  yield call([window, window.open], data.file_url, "_blank");
};

const downloadFile = function*({ data }) {
  const file = yield call(fetch, data.file_url, { mode: "no-cors" });
  const fileBlob = yield call([file, file.blob]);
  const fileURL = URL.createObjectURL(fileBlob);

  const extension = R.last(R.split(".", data.file_url));
  const a = yield call([document, document.createElement], "a");
  a.href = fileURL;
  a.download = `${data.label}.${extension}`;

  yield call([document.body, document.body.appendChild], a);
  a.click();
  yield call([document.body, document.body.removeChild], a);

  URL.revokeObjectURL(fileURL);
};

const viewPage = function*({ data }) {
  const event = yield select(getEventDetails);
  yield put(push(`/hubs/${event.slug}/${event.uuid}/pages/${data.id}`));
};

const visitMediaHub = function*({}) {
  const event = yield select(getEventDetails);
  yield put(push(`/hubs/${event.slug}/${event.uuid}/media-hub`));
};

const addSubmission = function*({ data }) {
  const eventDetails = yield select(getEventDetails);
  const portalUser = yield select(getPortalUser);
  const user = yield select(getUser);

  try {
    const contactId = R.pathEq(["active_view", "type"], "user", portalUser)
      ? R.pathOr(undefined, ["active_view", "contact_id"], portalUser)
      : undefined;

    const accountId = R.pathEq(["active_view", "type"], "account", portalUser)
      ? R.pathOr(undefined, ["active_view", "id"], portalUser)
      : undefined;

    const { record } = yield call(apiCall, {
      method: "post",
      url: `/modules/${data.base_module_id}/records`,
      data: {
        moduleId: data.base_module_id,
        record: {
          isDraft: true
        }
      },
      qs: {
        eventId: eventDetails.id,
        userId: user.id
      }
    });

    const { submission } = yield call(apiCall, {
      method: "post",
      url: `/form/form/${data.id}/submissions`,
      data: {
        formId: data.id,
        eventId: eventDetails.id,
        userId: user.id,
        contactId,
        accountId,
        submissionRecordId: record.id
      },
      qs: {
        userId: user.id
      }
    });

    yield put(
      push(
        `/submissions/${eventDetails.slug}/${data.form_slug}/${submission.id}`
      )
    );
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred adding a submission"
          }
        ])
      )
    ]);
  }
};

const delegates = {
  [SECTION_ACTIONS.VIEW_ALL_ALERTS]: viewAllAlerts,
  [CARD_ACTIONS.VIEW_ALERT]: viewAlert,
  [CARD_ACTIONS.VIEW_PEOPLE_REPORT]: viewPeopleReport,
  [CARD_ACTIONS.MANAGE_PEOPLE]: managePeople,
  [CARD_ACTIONS.SUBMIT_DOCUMENT]: submitDocument,
  [CARD_ACTIONS.SUBMIT_FORM]: addSubmission,
  [CARD_ACTIONS.VIEW_FILE]: viewFile,
  [CARD_ACTIONS.DOWNLOAD_FILE]: downloadFile,
  [CARD_ACTIONS.VIEW_PAGE]: viewPage,
  [CARD_ACTIONS.VISIT_MEDIA_HUB]: visitMediaHub
};

const watchCardActions = function*() {
  for (;;) {
    const { payload: action } = yield take(ActionComps.executeAction.type);

    const delegate = R.prop(action.actionId, delegates);
    if (delegate) {
      yield spawn(delegate, action);
    }
  }
};

const openSubmissionModal = function*({ payload: { row } }) {
  yield put(
    showModal({
      content: <OrderModal portal submissionId={row.id} />,
      wrapper: ModalWrapper
    })
  );
};

const editRow = function*({ payload: { row } }) {
  yield put(push(`/submissions/${row.event_slug}/${row.form_slug}/${row.id}`));
};

const watchExecuteTableAction = function*() {
  for (;;) {
    const action = yield take(TableActions.executeAction.type);
    const delegate = R.prop(action.payload.actionId, {
      [ROW_ACTIONS.OPEN_RECORD]: openSubmissionModal,
      "row-openSubmissionModal": openSubmissionModal,
      "row-edit": editRow
    });

    if (delegate) {
      yield spawn(delegate, action);
    }
  }
};

const rootSaga = function*() {
  yield all([fork(watchCardActions), fork(watchExecuteTableAction)]);
};

export default rootSaga;
