import React from "react";
import { connect } from "react-redux";
import {
  Div,
  DarkTabs,
  MediumOutlineButton,
  SendIcon,
  DownloadIcon
} from "components/Base";

import LoadingOverlay from "ui-kit/LoadingOverlay";

import { getTabs, getActiveTab } from "Accounts/RequestsAndOrders/selectors";
import { getters, actions } from "../index";

const decorate = connect(
  (state, props) => ({
    activeComponent: getActiveTab(state, props),
    tabs: getTabs(state, props),
    isLoading: getters.isLoading(state, props)
  }),
  {
    exportItemSummaries: actions.exportItemSummaries
  }
);

const TabsWithButtons = ({
  onClickHeaderButton,
  headerText,
  headerIconColor,
  HeaderIcon,
  tabs,
  handlers,
  hideButtons,
  activeComponent,
  emptyComponent,
  exportItemSummaries,
  loading,
  isLoading: internalLoading
}) => (
  <Div
    my={4}
    bra={1}
    bc="neutral2"
    b={1}
    bg="white"
    style={{
      borderTop: `2px solid ${headerIconColor}`,
      position: "relative"
    }}
  >
    <Div bb={1} px={4} bc="neutral1">
      <Div pt={4} pb={2} display="row.flex-start.center">
        <Div flex={1} display="row.flex-start.center">
          <Div
            pill
            size={27}
            bg={headerIconColor}
            display="row.center.center"
            style={{
              marginRight: 10
            }}
          >
            <HeaderIcon size={16} color="white" />
          </Div>
          <Div
            fw={3}
            style={{
              fontSize: 20,
              color: "#393737"
            }}
          >
            {headerText}
          </Div>
        </Div>
        {!hideButtons && (
          <>
            <MediumOutlineButton
              LeftIcon={SendIcon}
              color="neutral6"
              mr={2}
              onClick={() => handlers.showSendEmailModal()}
            >
              Send Summary
            </MediumOutlineButton>
            <MediumOutlineButton
              color="neutral6"
              mr={2}
              isPadded={false}
              onClick={() => exportItemSummaries()}
            >
              <DownloadIcon color="neutral6" />
            </MediumOutlineButton>
          </>
        )}
        <MediumOutlineButton onClick={onClickHeaderButton}>
          Add
        </MediumOutlineButton>
      </Div>
      {tabs.length ? <DarkTabs tabs={tabs} /> : null}
    </Div>
    {(loading || internalLoading) && <LoadingOverlay />}
    {emptyComponent || activeComponent}
  </Div>
);

export default decorate(TabsWithButtons);
