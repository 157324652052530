import React from "react";
import * as R from "ramda";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getters, actions } from "SalesPortal/Home";
import {
  getOrderedItems,
  getTotalCartItems,
  getTotalAmount
} from "SalesPortal/ItemsModal/selectors";
import {
  getFields,
  isCheckoutValid,
  getIsPaymentEnabled
} from "SalesPortal/Home/selectors";
import CommonCheckout from "Orders/Checkout/View";

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      orderedItems: getOrderedItems(state),
      total: getTotalCartItems(state),
      totalAmount: getTotalAmount(state),
      saving: getters.saving(state, props),
      isPaymentEnabled: getIsPaymentEnabled(state, props),
      fields: getFields(state, props),
      isValid: isCheckoutValid(state)
    }),
    {
      onSubmit: actions.onSubmit,
      showTermsModal: () => actions.showTermsModal()
    }
  )
);

const Checkout = props => {
  const {
    params: { eventId, slug }
  } = props;
  return (
    <CommonCheckout
      {...props}
      redirectLinkLabel="Back to event"
      redirectLink={`/sales-portal/${slug}/${eventId}/confirmation`}
      backLink={`/sales-portal/${slug}/${eventId}`}
    />
  );
};

export default decorate(Checkout);
