import PropTypes from "prop-types";
import React, { Component } from "react";
import { EventbriteEventsPropTypes } from "components/Event/Settings/Credentials/Integrations/Ticketing/utils/PropTypes";
import CSSModules from "react-css-modules";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Body from "components/Global/Modal/Layout/ScrollableBody";
import Loading from "components/Global/Modal/Layout/Loading";
import css from "./styles.scss";

const ListItem = CSSModules(
  ({ event, onSelect }) => (
    <div styleName="listItem">
      <div styleName="listItemTitle">{event.name}</div>
      <div styleName="select" onClick={() => onSelect(event)}>
        select
      </div>
    </div>
  ),
  css
);

@CSSModules(css)
class SelectEvent extends Component {
  componentDidMount() {
    this.props.getEventbriteEvents({
      eventId: this.props.params.eventId
    });
  }

  render() {
    const {
      onSelect,
      hideModal,
      eventbriteEvents,
      isFetchingEvents
    } = this.props;
    return (
      <StyleWrapper
        heading="Select the event"
        hideModal={hideModal}
        width={550}
      >
        <Body style={{ maxHeight: 400, borderBottom: 0 }}>
          <div styleName="heading">available events</div>
          {isFetchingEvents ? (
            <Loading />
          ) : (
            eventbriteEvents.events.map(e => (
              <ListItem key={e.id} event={e} onSelect={onSelect} />
            ))
          )}
        </Body>
      </StyleWrapper>
    );
  }
}

SelectEvent.propTypes = {
  params: PropTypes.shape({ eventId: PropTypes.string }).isRequired,
  getEventbriteEvents: PropTypes.func.isRequired,
  eventbriteEvents: EventbriteEventsPropTypes.isRequired,
  isFetchingEvents: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default SelectEvent;
