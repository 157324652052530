import PropTypes from "prop-types";
import React, { Component } from "react";
import Popover from "@lennd/material-ui/Popover";
import CSSModules from "react-css-modules";
import css from "../styles.scss";

@CSSModules(css)
class ActionMenu extends Component {
  state = { showPopover: false };

  handleClick = e => {
    e.stopPropagation();
    this.setState({ showPopover: true });
  };

  onRequestClose = () => {
    this.setState({ showPopover: false });
  };

  handleClose = fn => () => {
    this.setState({ showPopover: false });
    if (typeof fn === "function") return fn();
  };

  render() {
    return (
      <div>
        <span
          role="button"
          tabIndex={-1}
          onClick={this.handleClick}
          ref={ref => {
            this.anchor = ref;
          }}
        >
          <div styleName="menuItem">
            {this.props.label}
            <i className="material-icons dropdown">arrow_drop_down</i>
          </div>
        </span>
        <Popover
          anchorEl={this.anchor}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          onClose={this.onRequestClose}
          open={this.state.showPopover}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
        >
          {this.props.children({ closeOnClick: this.handleClose })}
        </Popover>
      </div>
    );
  }
}

ActionMenu.propTypes = {
  children: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

export default ActionMenu;
