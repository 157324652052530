import { fork, takeEvery, all, put, call } from "redux-saga/effects";
import { getUserAccessLevels } from "redux/modules/user/access-levels/actions";
import { getEventUserPermissionProfile } from "redux/modules/permissions/user-permission-profile/actions";
import { getEvent } from "redux/modules/event/actions";
import { apiCall } from "App/Data/sagas";
import { actions } from "./model";
import { registerError } from "redux/modules/errors/actions";

const init = function*({ payload: { eventId, id } }) {
  yield put(getUserAccessLevels());
  yield put(getEventUserPermissionProfile(eventId, id));
  yield put(getEvent(eventId));
};

const bringAccess = function*({ payload: { eventId } }) {
  try {
    yield call(apiCall, {
      method: "post",
      url: `/admins/event-user/${eventId}`
    });
    yield call([location, location.reload]);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading event"
          }
        ])
      )
    ]);
  }
};

const watchBringAccess = function*() {
  yield takeEvery(actions.bringAccess.type, bringAccess);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([fork(watchInit), fork(watchBringAccess)]);
};

export default rootSaga;
