import { BEGIN, COMMIT, REVERT } from "redux-optimist";
import uuid from "node-uuid";
import {
  CREATE,
  CREATE_COMPLETE,
  BULK_CREATE,
  BULK_CREATE_COMPLETE,
  CREATE_DEFAULT,
  CREATE_DEFAULT_COMPLETE,
  CREATE_SUBFORM,
  CREATE_SUBFORM_COMPLETE,
  BULK_CREATE_SUBFORM,
  BULK_CREATE_SUBFORM_COMPLETE,
  BULK_CREATE_DEFAULT,
  BULK_CREATE_DEFAULT_COMPLETE,
  ERROR,
  CLEAR_ERROR,
  INVALIDATE
} from "./constants";
import api from "./api";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { eventId as getFormEventId } from "redux/modules/formsV2/submission/selectors";

export function addValue(data) {
  return async (dispatch, getState) => {
    const eventId = getFormEventId(getState()) || getEventId(getState());

    const transactionId = uuid.v4();
    dispatch({
      type: CREATE,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.post(getState().user.user.credentials, {
        eventId,
        ...data
      });
      dispatch({
        type: CREATE_COMPLETE,
        payload: { value: result.value },
        optimist: { type: COMMIT, id: transactionId }
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function bulkAddValue(data) {
  return async (dispatch, getState) => {
    const eventId = getFormEventId(getState()) || getEventId(getState());

    const transactionId = uuid.v4();
    dispatch({
      type: BULK_CREATE,
      payload: data
    });
    try {
      const result = await api.bulk(getState().user.user.credentials, {
        eventId,
        ...data
      });
      dispatch({
        type: BULK_CREATE_COMPLETE,
        payload: result.values
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function addSubformValue(data) {
  return async (dispatch, getState) => {
    const eventId = getFormEventId(getState()) || getEventId(getState());

    const transactionId = uuid.v4();
    dispatch({
      type: CREATE_SUBFORM,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.post(getState().user.user.credentials, {
        eventId,
        ...data
      });
      dispatch({
        type: CREATE_SUBFORM_COMPLETE,
        payload: { value: result.value },
        optimist: { type: COMMIT, id: transactionId }
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function addDefaultValue(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: CREATE_DEFAULT,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.post(getState().user.user.credentials, data);
      dispatch({
        type: CREATE_DEFAULT_COMPLETE,
        payload: { value: result.value },
        optimist: { type: COMMIT, id: transactionId }
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function bulkAddSubformValues(data) {
  return async (dispatch, getState) => {
    const eventId = getFormEventId(getState()) || getEventId(getState());

    const transactionId = uuid.v4();
    dispatch({
      type: BULK_CREATE_SUBFORM,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.bulk(getState().user.user.credentials, {
        eventId,
        ...data
      });
      dispatch({
        type: BULK_CREATE_SUBFORM_COMPLETE,
        payload: { value: result.value },
        optimist: { type: COMMIT, id: transactionId }
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function bulkAddDefaultValues(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: BULK_CREATE_DEFAULT,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.bulk(getState().user.user.credentials, data);
      dispatch({
        type: BULK_CREATE_DEFAULT_COMPLETE,
        payload: { value: result.value },
        optimist: { type: COMMIT, id: transactionId }
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function invalidate() {
  return {
    type: INVALIDATE
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR
  };
}
