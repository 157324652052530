import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDocumentTitle from "react-document-title";

export default class DocumentTitle extends Component {
  static propTypes = {
    children: PropTypes.object,
    title: PropTypes.string
  };

  render() {
    let title = this.props.title;

    if (!title) {
      title = "Lennd";
    } else {
      title = `Lennd - ${title}`;
    }

    return (
      <ReactDocumentTitle title={title}>
        {this.props.children}
      </ReactDocumentTitle>
    );
  }
}
