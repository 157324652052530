import {
  put,
  all,
  take,
  takeEvery,
  fork,
  select,
  call
} from "redux-saga/effects";
import * as R from "ramda";

import {
  FIELD_IDS,
  FORM_FIELD_IDS,
  FORM_ID,
  SETTINGS_FORM_ID,
  DATE_FORMAT,
  DATE_TIME_ZONE_FORMAT
} from "./constants";
import moment from "moment";

import { eventId as getEventId } from "redux/modules/event/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { getEditedField, getFeaturesToSave, getTypesToSave } from "./selectors";

import { actions as LayoutActions } from "EventLight/Layout/model";
import { actions, getters } from "./model";
import { registerError } from "redux/modules/errors/actions";
import { actions as FormActions } from "ui-kit/Form/model";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { getEvent } from "redux/modules/event/actions";
import { isConferenceTemplate as getIsConferenceTemplate } from "redux/modules/event/selectors";

import Api from "./api";

const FIELDS_THAT_USE_JSON = [
  FORM_FIELD_IDS.HUB.RIGHT_SIDEBAR_LINKS,
  FORM_FIELD_IDS.HUB.TICKETS_WITH_ACCESS_TO_GATED_CONTENT
];

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventDetails = yield select(getEventDetails);

  return {
    credentials,
    eventDetails
  };
};

const init = function*() {
  try {
    yield put(actions.setLoading(true));
    const { credentials, eventDetails } = yield call(getParams);

    const { payload } = yield call(Api.getDetails, {
      credentials,
      eventId: eventDetails.id
    });

    yield put(
      actions.setInitialData({
        ...payload,
        date_groups: R.propOr([], "date_groups", eventDetails),
        available_languages: payload.available_languages,
        available_currencies: payload.available_currencies,
        available_features: payload.available_features,
        available_types: payload.available_types,
        features: R.reduce((map, s) => {
          map[s.id] = s.selected;
          return map;
        }, {})(payload.available_features),
        types: R.reduce((map, s) => {
          map[s.id] = s.selected;
          return map;
        }, {})(payload.available_types),
        typeSettings: R.reduce((map, s) => {
          map[s.id] = s.settings;
          return map;
        }, {})(payload.available_types),
        settings:
          payload.settings && payload.settings.length
            ? R.reduce((map, s) => {
                map[s.key] = FIELDS_THAT_USE_JSON.includes(s.key)
                  ? s.json_value
                  : s.text_value;
                return map;
              }, {})(payload.settings)
            : {}
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting details"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const save = function*() {
  try {
    const { credentials, eventDetails } = yield call(getParams);

    const data = yield select(getters.data);
    const features = yield select(getFeaturesToSave);
    const types = yield select(getTypesToSave);
    const isConferenceTemplate = yield select(getIsConferenceTemplate);

    const toSave = isConferenceTemplate
      ? {
          name: data.name,
          slug: data.slug,
          logoImageUrl: data.logo_image_url,
          backgroundImageUrl: data.background_image_url,
          timezone: data.timezone,
          dateFrom: data.date_from,
          dateTo: data.date_to
        }
      : {
          name: data.name,
          type: data.type,
          registrationType: data.registration_type,
          slug: data.slug,
          scope: data.scope,
          logoImageUrl: data.logo_image_url,
          backgroundImageUrl: data.background_image_url,
          // highlightColor: data.highlight_color,
          timezone: data.timezone,
          currency: data.currency,
          language: data.language,
          dateFrom: data.date_from,
          dateTo: data.date_to,
          dateGroups: data.date_groups,
          features,
          types
        };

    yield call(Api.updateDetails, {
      credentials,
      eventId: eventDetails.id,
      data: toSave
    });
    yield put(getEvent(eventDetails.id));
    yield put(LayoutActions.refreshNavigation());

    yield put(showSnackbar({ message: "Details Updated" }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while saving details"
        }
      ])
    );
  }
};

const fieldUpdate = function*({ id, fieldId }) {
  const data = yield select(getters.data);

  const newValue = yield select(getEditedField, {
    instanceId: id,
    fieldId
  });
  const processedValue = R.propOr(newValue, fieldId, {
    [FIELD_IDS.REGISTRATION_TYPE]: R.path([0], newValue),
    [FIELD_IDS.SCOPE]: R.path([0], newValue),
    [FIELD_IDS.TYPE]: R.path([0], newValue),
    [FIELD_IDS.TIMEZONE]: R.path([0], newValue),
    [FIELD_IDS.CURRENCY]: R.path([0], newValue),
    [FIELD_IDS.LANGUAGE]: R.path([0], newValue),
    [FIELD_IDS.LOGO_IMAGE_URL]: R.path([0, "url"], newValue),
    [FIELD_IDS.BACKGROUND_IMAGE_URL]: R.path([0, "url"], newValue),
    [FIELD_IDS.DATE_FROM]: moment(newValue, DATE_FORMAT).format(
      DATE_TIME_ZONE_FORMAT
    ),
    [FIELD_IDS.DATE_TO]: moment(newValue, DATE_FORMAT).format(
      DATE_TIME_ZONE_FORMAT
    ),
    [FIELD_IDS.EVENT_DAYS]: R.prop("dayGroups", newValue)
  });

  if (fieldId === FIELD_IDS.DATE_FROM) {
    const start = moment(processedValue);
    let end = moment(data.date_to);

    if (start.isAfter(end)) {
      end = start
        .clone()
        .add(1, "hours")
        .utc()
        .format(DATE_TIME_ZONE_FORMAT);

      yield all([
        put(
          actions.updateField({
            field: FIELD_IDS.DATE_TO,
            value: end
          })
        ),
        put(FormActions.setFieldValue(end), {
          meta: {
            instanceId: FORM_ID,
            fieldId: FIELD_IDS.DATE_TO
          }
        })
      ]);
    }
  } else if (fieldId === FIELD_IDS.DATE_TO) {
    const end = moment(processedValue);
    let start = moment(data.date_from);

    if (end.isBefore(start)) {
      start = end
        .clone()
        .subtract(1, "hours")
        .utc()
        .format(DATE_TIME_ZONE_FORMAT);

      yield all([
        put(
          actions.updateField({
            field: FIELD_IDS.DATE_FROM,
            value: start
          })
        ),
        put(FormActions.setFieldValue(start), {
          meta: {
            instanceId: FORM_ID,
            fieldId: FIELD_IDS.DATE_FROM
          }
        })
      ]);
    }
  }

  yield put(actions.updateField({ field: fieldId, value: processedValue }));
};

const templateSettingsUpdate = function*({ id, fieldId }) {
  const newValue = yield select(getEditedField, {
    instanceId: id,
    fieldId
  });

  const processedValue = R.propOr(newValue, fieldId, {
    [FORM_FIELD_IDS.HUB.FONT_FAMILY]: R.path([0], newValue),
    [FORM_FIELD_IDS.HUB.INTERCOM_METHOD]: R.path([0], newValue),
    [FORM_FIELD_IDS.HUB.LEFT_SIDEBAR_LOGO_IMAGE_URL]: R.path(
      [0, "url"],
      newValue
    ),
    [FORM_FIELD_IDS.HUB.EVENT_LOGO_URL]: R.path([0, "url"], newValue),
    [FORM_FIELD_IDS.HUB.VIDEO_STREAM_PLACEHOLDER_IMAGE_URL]: R.path(
      [0, "url"],
      newValue
    ),
    [FORM_FIELD_IDS.HUB.RIGHT_SIDEBAR_LOGO_URL]: R.path([0, "url"], newValue),
    [FORM_FIELD_IDS.HUB.CONTENT_SECTION_1_TYPE]: R.path([0], newValue),
    [FORM_FIELD_IDS.HUB.CONTENT_SECTION_2_TYPE]: R.path([0], newValue),
    [FORM_FIELD_IDS.HUB.CONTENT_SECTION_3_TYPE]: R.path([0], newValue)
  });

  yield put(actions.updateSettings({ field: fieldId, value: processedValue }));
};

const onSelectItemsToShow = function*({ payload }) {
  yield put(actions.setShowSelectItemsModal(false));
  yield put(
    actions.updateSettings({
      field: FORM_FIELD_IDS.HUB.TICKETS_WITH_ACCESS_TO_GATED_CONTENT,
      value: payload
    })
  );
};

const getItemsToShow = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  try {
    const { payload } = yield call(Api.getItemTypes, {
      credentials,
      eventId
    });
    yield put(actions.setItemTypes(payload));
  } catch (e) {
    yield put(
      registerError([
        {
          system: e,
          user: "An error occurred fetching the item types"
        }
      ])
    );
  }
};

const watchUpdateFields = function*() {
  for (;;) {
    const {
      meta: { instanceId, fieldId }
    } = yield take(FormActions.setFieldValue.type);
    if (instanceId === FORM_ID) {
      yield call(fieldUpdate, {
        id: instanceId,
        fieldId
      });
    }
  }
};

const watchUpdateSettings = function*() {
  for (;;) {
    const {
      meta: { instanceId, fieldId }
    } = yield take(FormActions.setFieldValue.type);
    if (instanceId === SETTINGS_FORM_ID) {
      yield call(templateSettingsUpdate, {
        id: instanceId,
        fieldId
      });
    }
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchSave = function*() {
  yield takeEvery(actions.save.type, save);
};

const watchGetItemsToShow = function*() {
  yield takeEvery(actions.getItemsToShow.type, getItemsToShow);
};

const watchOnSelectItemsToShow = function*() {
  yield takeEvery(actions.onSelectItemsToShow.type, onSelectItemsToShow);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchSave),
    fork(watchUpdateFields),
    fork(watchUpdateSettings),
    fork(watchGetItemsToShow),
    fork(watchOnSelectItemsToShow)
  ]);
};

export default rootSaga;
