import React from "react";
import { connect } from "react-redux";
import { Stepper, Div } from "components/Base";
import { getters } from "../model";
import { STEPS } from "../constants";

const decorate = connect(state => ({
  activeIndex: getters.activeIndex(state)
}));

const Steps = ({ activeIndex }) => (
  <Div
    width={1}
    px={4}
    display="row.center.center"
    height={93}
    bb={1}
    bc="neutral1"
  >
    <Stepper steps={STEPS} width={1} activeStepIndex={activeIndex} />
  </Div>
);

export default decorate(Steps);
