import React, { Component } from "react";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import PersonModal from "Portal/PortalPeople/PersonModal";
import AddPersonModal from "Portal/PortalPeople/AddPersonModal";
import DeleteRecordsModal from "components/Global/CRM/Modals/DeleteConfirmation";
import InternalController from "./InternalController";
import isFormLocked from "components/Event/FormsV2/Utils/is-form-locked";
import ImportModal from "Modules/ImportModal/View";

import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

class PortalPeopleController extends Component {
  state = { loading: true };

  async componentDidMount() {
    await Promise.all([
      this.props.getSettings(this.props.eventDetails.id, this.props.accountId)
    ]);
    this.setState({ loading: false });
  }

  refreshViews = () => {
    if (this.props.fetchProfile) {
      this.props.fetchProfile();
    }
    this.props.getPeople(this.props.eventDetails.id, this.props.accountId);
  };

  showPersonModal = ({ id }) => {
    this.props.showModal({
      content: (
        <PersonModal
          moduleId={STANDARD_MODULE_IDS.contacts.id}
          accountId={this.props.accountId}
          recordId={id}
          isViewingAsAdmin={this.props.isViewingAsAdmin}
          hideModal={() => {
            // refresh people in case any changes were made
            this.refreshViews();
            this.props.hideModal();
          }}
        />
      ),
      wrapper: ModalWrapper,
      disableClickToClose: true
    });
  };

  showAddPersonModal = ({ typeId }) => {
    this.props.showModal({
      content: (
        <AddPersonModal
          accountId={this.props.accountId}
          typeId={typeId}
          onSave={this.handleAddPerson}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  handleAddPerson = async ({ typeId, isPrimary, role, values }) => {
    const createdContact = await this.props.addRecord({
      moduleId: STANDARD_MODULE_IDS.contacts.id,
      typeId,
      record: values,
      options: {
        eventId: this.props.eventDetails.id
      }
    });

    this.showPersonModal({ id: createdContact.id });

    await this.props.addRelationship({
      eventId: this.props.eventDetails.id,
      isPrimary,
      role,
      accountId: this.props.accountId,
      contactId: createdContact.id
    });

    this.refreshViews();
  };

  showImportModal = async ({ typeId }) => {
    this.props.showModal({
      content: (
        <ImportModal
          eventId={this.props.eventDetails.id}
          accountId={this.props.accountId}
          typeId={typeId}
          onDone={() => {
            this.props.getPeople(
              this.props.eventDetails.id,
              this.props.accountId
            );
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showRemovePersonModal = selectedContacts => {
    this.props.showModal({
      content: (
        <DeleteRecordsModal
          hideModal={this.props.hideModal}
          heading={`Remove ${selectedContacts.length} ${
            selectedContacts.length === 1 ? "person" : "people"
          }?`}
          message={
            <div>
              {`
                  Are you sure you want to remove ${
                    selectedContacts.length === 1
                      ? "this"
                      : `these ${selectedContacts.length}`
                  } ${selectedContacts.length === 1 ? "person" : "people"}?
                  `}
              <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                This cannot be undone and will remove any assigned items.
              </div>
            </div>
          }
          onConfirm={() => this.handleRemovePerson(selectedContacts)}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  handleRemovePerson = async contactIds => {
    await Promise.all(
      contactIds.map(({ contactId, contactUserId }) =>
        this.props.removePerson({
          eventId: this.props.eventDetails.id,
          accountId: this.props.accountId,
          contactId,
          contactUserId
        })
      )
    );

    return this.refreshViews();
  };

  render() {
    const actions = {
      onAddPerson: this.handleAddPerson,
      showImportModal: this.showImportModal,
      showPersonModal: this.showPersonModal,
      showAddPersonModal: this.showAddPersonModal,
      showRemovePersonModal: this.showRemovePersonModal
    };

    const {
      allPeople,
      people,
      eventDetails,
      isViewingAsAdmin,
      settings
    } = this.props;

    return (
      <InternalController
        {...{
          allPeople,
          people,
          eventDetails,
          isViewingAsAdmin,
          loading: this.state.loading,
          isLocked: !isViewingAsAdmin && isFormLocked(settings),
          ...actions
        }}
      />
    );
  }
}

export default PortalPeopleController;
