import HealthPassForm from "HealthPass/HealthPassForm/View";
import HealthPassStatus from "HealthPass/HealthPassStatus/View";

import { hot } from "App/Config/hot";

const Intake = {
  HealthPassForm,
  HealthPassStatus
};

export default hot(Intake);
