import React from "react";
import { createContext } from "redux-mvc";
import { connect } from "react-redux";
import * as R from "ramda";
import module, { actions } from "../index";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import Layout from "./Layout";
import rootSaga from "../sagas";
import { withRouter } from "react-router";
import { deleteField } from "redux/modules/modules/fields/actions";

import ModalColumnAdd from "Modules/AddEditColumnModal/View";
import ModalColumnEdit from "Modules/AddEditColumnModal/View";
import ModalColumnDelete from "components/Global/Table3/ModalColumnDelete";

module.setRootSaga(rootSaga);

const decorate = createContext({
  module: module,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(actions.init());
    }
  },
  handlers: {
    goToMasterSchedule() {
      this.props.router.push(
        `/event/${this.props.params.eventId}/schedule/master`
      );
    },
    showAddFieldModal(moduleId) {
      this.props.showModal({
        content: (
          <ModalColumnAdd
            orgId={this.props.params.orgId}
            eventId={this.props.params.eventId}
            moduleId={moduleId}
            onSave={() => {
              this.props.showSnackbar({ message: "Field added", action: "OK" });
              this.store.dispatch(actions.init(true));
            }}
          />
        )
      });
    },
    showEditFieldModal(moduleId, fieldId) {
      this.props.showModal({
        content: (
          <ModalColumnEdit
            orgId={this.props.params.orgId}
            eventId={this.props.params.eventId}
            moduleId={moduleId}
            fieldId={fieldId}
            onSave={() => {
              this.props.showSnackbar({
                message: "Field updated",
                action: "OK"
              });
              this.store.dispatch(actions.init(true));
            }}
          />
        )
      });
    },
    showDeleteFieldModal(moduleId, field) {
      this.props.showModal({
        content: (
          <ModalColumnDelete
            column={field}
            handleDeleteColumn={async () => {
              await this.props.deleteField({
                moduleId,
                field: {
                  id: field.id
                },
                options: {
                  orgId: this.props.params.orgId,
                  eventId: this.props.params.eventId
                }
              });

              this.props.showSnackbar({
                message: "Field deleted",
                action: "OK"
              });
              this.store.dispatch(actions.init(true));
            }}
          />
        )
      });
    }
  },
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: ["user", "event", "modal", "organization"]
  }
});

export default withRouter(
  connect(
    null,
    { hideModal, showModal, showSnackbar, deleteField }
  )(decorate(Layout))
);
