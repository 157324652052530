import { createSelector } from "reselect";

import * as R from "ramda";

import { getters } from "./index";

import { BULK_QUESTIONS_PAGE } from "./constants";

export const isSelectQuestionsPage = createSelector(
  getters.bulkQuestionsPage,
  bulkQuestionsPage =>
    bulkQuestionsPage === BULK_QUESTIONS_PAGE.SELECT_QUESTIONS
);

export const isAssignItemsPage = createSelector(
  getters.bulkQuestionsPage,
  bulkQuestionsPage => bulkQuestionsPage === BULK_QUESTIONS_PAGE.ASSIGN_ITEMS
);

export const getBulkQuestionsModalTitle = createSelector(
  getters.bulkQuestionsPage,
  bulkQuestionsPage => {
    if (bulkQuestionsPage === BULK_QUESTIONS_PAGE.ASSIGN_ITEMS) {
      return "Assign Selected Questions to Items";
    }
    return "Select Questions to Assign in Bulk";
  }
);

export const getVariants = createSelector(
  getters.variantIds,
  R.compose(
    R.flatten,
    R.values
  )
);

export const getSelectedVariants = createSelector(
  getters.itemTypes,
  getters.typeIds,
  getters.variantIds,
  (itemTypes, typeIds, variantIds) => {
    const variantIdsValues = R.compose(
      R.flatten,
      R.values
    )(variantIds);

    return R.compose(
      R.filter(variant => R.any(id => id === variant.id, variantIdsValues)),
      R.reduce((acc, group) => {
        const hasGroup = R.has(group.id, variantIds);
        if (hasGroup) {
          return [
            ...acc,
            ...R.map(
              variant => ({
                ...variant,
                groupId: group.id,
                typeId: group.typeId
              }),
              group.variants
            )
          ];
        }
        return acc;
      }, []),
      R.reduce((acc, type) => {
        const hasType = R.any(id => id === type.id, typeIds);
        if (hasType) {
          return [
            ...acc,
            ...R.map(group => ({ ...group, typeId: type.id }), type.groups)
          ];
        }
        return acc;
      }, [])
    )(itemTypes);
  }
);

export const getFilteredTypes = createSelector(
  getters.itemTypes,
  getters.variantsFilter,
  (itemTypes, variantsFilter) => {
    if (R.isEmpty(variantsFilter)) {
      return itemTypes;
    }
    return R.reduce(
      (acc, type) => {
        const groups = R.reduce(
          (accumulator, group) => {
            const variants = R.filter(
              variant =>
                R.contains(R.toLower(variantsFilter), R.toLower(variant.name)),
              group.variants
            );
            if (R.length(variants) < 1) {
              return accumulator;
            }
            return [
              ...accumulator,
              {
                ...group,
                variants
              }
            ];
          },
          [],
          type.groups
        );
        if (R.length(groups) < 1) {
          return acc;
        }
        return [
          ...acc,
          {
            ...type,
            groups
          }
        ];
      },
      [],
      itemTypes
    );
  }
);

export const getFilteredQuestions = createSelector(
  getters.questions,
  getters.questionsFilter,
  (questions, questionsFilter) => {
    if (R.isEmpty(questionsFilter)) {
      return questions;
    }
    return R.filter(
      field => R.contains(R.toLower(questionsFilter), R.toLower(field.name)),
      questions
    );
  }
);

export const getFilteredSets = createSelector(
  getters.sets,
  getters.questionsFilter,
  (sets, questionsFilter) => {
    if (R.isEmpty(questionsFilter)) {
      return sets;
    }
    return R.filter(
      field => R.contains(R.toLower(questionsFilter), R.toLower(field.name)),
      sets
    );
  }
);

export const getSelectedSets = createSelector(
  getters.sets,
  getters.setsIds,
  (sets, setsIds) =>
    R.filter(({ id }) => R.any(setId => setId === id, setsIds), sets)
);

export const getSelectedQuestions = createSelector(
  getters.questions,
  getters.itemQuestionsIds,
  (questions, itemQuestionsIds) =>
    R.filter(
      ({ id }) => R.any(setId => setId === id, itemQuestionsIds),
      questions
    )
);
