import React, { Component } from "react";
import * as R from "ramda";
import { EventCard } from "./EventCard";
import { EventList, Div, Text2 } from "components/Base";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import ViewRecord from "components/Global/Module/Modals/ViewRecord";

const CustomEventList = EventList(EventCard, "start");

class Controller extends Component {
  componentDidMount() {
    this.props.getActivities({
      eventId: this.props.eventId,
      recordId: this.props.recordId,
      source: this.props.source
    });
  }

  showRecordModal = (moduleId, recordId) => {
    this.props.showModal({
      content: (
        <ViewRecord
          moduleId={moduleId}
          recordId={recordId}
          onClose={() => {
            // This overrides the hideModal fn that is passed in.
            // You can provide any logic here that should happen when
            // the modal closes (ex: refetch activities)
            this.props.hideModal();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const {
      activities,
      showScheduleName,
      showActivityRecordModal,
      EmptyStateComponent
    } = this.props;

    const formattedActivities = R.map(activity => {
      return {
        name: activity.name,
        start: activity.start,
        end: activity.end,
        location: activity.location,
        color: activity.module_color,
        scheduleName: showScheduleName ? activity.module_name : null,
        goToSchedule: () =>
          this.props.push(
            `/event-light/${this.props.eventId}/schedules/${activity.module_id}`
          ),
        onClick: showActivityRecordModal
          ? () => this.showRecordModal(activity.module_id, activity.id)
          : undefined
      };
    }, activities);

    return R.isEmpty(formattedActivities) ? (
      EmptyStateComponent || (
        <Div py={4}>
          <Text2 bold>0 activities</Text2>
        </Div>
      )
    ) : (
      <CustomEventList
        events={formattedActivities}
        maxHeight={this.props.overflow ? 400 : undefined}
        pb={2}
        style={{ overflowY: this.props.overflow ? "scroll" : "initial" }}
      />
    );
  }
}

Controller.defaultProps = {
  overflow: true,
  showScheduleName: true,
  showActivityRecordModal: true,
  source: "profile"
};

export default Controller;
