import { createHandlers } from "redux-mvc";
import * as R from "ramda";

import {
  MANAGE_BY,
  MODES,
  STATUS,
  NAMESPACE,
  MAP_VIEW_TO_ROWS,
  SUMMARIES
} from "EventLight/Common/Manage/constants";

import { CREDENTIAL_TYPE_ID } from "utils/item-types";

const DEFAULT_PREFERENCES = {
  visible_fields: [],
  field_order: {},
  field_widths: {},
  quick_filters: [],
  sort_by: [],
  filters: {},
  grouped_by: null
};

const iniState = {
  preferences: DEFAULT_PREFERENCES,
  originalPreferences: DEFAULT_PREFERENCES,
  rows: [],
  columns: [],
  references: {},
  views: [],
  //
  searchTerm: "",
  view: null,
  mode: MODES.REVIEW,
  itemTypeView: CREDENTIAL_TYPE_ID,
  selectedTab: STATUS.ALL,
  allPassesRows: [],
  attendeeListRows: [],
  groupAffiliationRows: [],
  orderNumberRows: [],
  orderNumberRowsIntegration: [],
  categoryRows: [],
  itemTypeRows: [],
  pagination: {
    pageSize: 25,
    pages: 0,
    page: 0
  },
  tabStats: Object.values(STATUS).reduce(
    (acc, val) => ({ ...acc, [val]: 0 }),
    {}
  ),
  stats: { total: 0, issued: 0, approved: 0, pendingPickup: 0 },
  loading: true,
  loadingStats: false,
  showStatsModal: false,
  showPassModal: false,
  showMealsModal: false,
  showSourceModal: false,
  showUserModal: false,
  showFieldModal: false,
  selectedMeals: [],
  selectedSources: [],
  selectedRequester: [],
  selectedFieldFilters: {},
  selectedPasses: [],
  selectedInventoryItems: [],
  selectedBoothItems: [],
  selectedSponsorshipItems: [],

  summary: {
    type: "",
    id: "",
    moduleType: "",
    name: "",
    photoUrl: null,
    contacts: []
  },

  showNoResults: false,
  groupId: "",
  contactTypeId: "",
  orderId: "",
  itemBlockId: "",
  submissionId: "",
  lineItemIds: [],
  orderIds: [],
  variantIds: [],
  filtersOn: false
};

const mapModeToSelectedTab = {
  [MODES.REVIEW]: STATUS.ALL,
  [MODES.ISSUANCE]: STATUS.APPROVED,
  [MODES.PRINT]: STATUS.ALL
};

const getResultsKey = {
  [MANAGE_BY.ALL_PASSES]: "records",
  [MANAGE_BY.CATEGORY]: "item_groups",
  [MANAGE_BY.ITEM_TYPE]: "item_types",
  [MANAGE_BY.GROUP_AFFILIATION]: "accounts",
  [MANAGE_BY.ATTENDEE_LIST]: "contacts",
  [MANAGE_BY.ORDER_NUMBER]: "orders",
  [MANAGE_BY.INTEGRATION]: "orders"
};

const zeroIndexPage = R.compose(R.dec, R.pathOr(1, ["pagination", "page"]));

const handlers = createHandlers({
  iniState,
  reducers: {
    init: (
      state,
      {
        payload: {
          groupId,
          contactTypeId,
          orderId = "",
          itemBlockId = "",
          submissionId = "",
          lineItemIds = [],
          orderIds = [],
          variantIds = [],
          itemTypeView,
          mode,
          view,
          selectedTab
        }
      }
    ) => {
      return {
        mode,
        itemTypeView,
        groupId,
        contactTypeId,
        selectedAccount: "",
        orderId,
        itemBlockId,
        submissionId,
        lineItemIds,
        orderIds,
        variantIds,
        view,
        selectedTab: selectedTab || mapModeToSelectedTab[mode] || STATUS.ALL,
        summary: iniState.summary
      };
    },
    initPreferences: (_, { payload: { mode, preferences } }) => ({
      mode,
      preferences,
      originalPreferences: preferences,
      //
      selectedMeals: R.pathOr(
        [],
        ["quick_filters", "selectedMeals"]
      )(preferences),
      selectedSources: R.pathOr(
        [],
        ["quick_filters", "selectedSources"]
      )(preferences),
      selectedRequester: R.pathOr(
        [],
        ["quick_filters", "selectedRequester"]
      )(preferences),
      selectedPasses: R.pathOr(
        [],
        ["quick_filters", "selectedPasses"]
      )(preferences),
      selectedInventoryItems: R.pathOr(
        [],
        ["quick_filters", "selectedInventoryItems"]
      )(preferences),
      selectedBoothItems: R.pathOr(
        [],
        ["quick_filters", "selectedBoothItems"]
      )(preferences),
      selectedSponsorshipItems: R.pathOr(
        [],
        ["quick_filters", "selectedSponsorshipItems"]
      )(preferences)
    }),
    updateSelectViewQuickFilters: (_, { payload: preferences }) => ({
      selectedMeals: R.pathOr(
        [],
        ["quick_filters", "selectedMeals"]
      )(preferences),
      selectedSources: R.pathOr(
        [],
        ["quick_filters", "selectedSources"]
      )(preferences),
      selectedRequester: R.pathOr(
        [],
        ["quick_filters", "selectedRequester"]
      )(preferences),
      selectedPasses: R.pathOr(
        [],
        ["quick_filters", "selectedPasses"]
      )(preferences),
      selectedInventoryItems: R.pathOr(
        [],
        ["quick_filters", "selectedInventoryItems"]
      )(preferences),
      selectedBoothItems: R.pathOr(
        [],
        ["quick_filters", "selectedBoothItems"]
      )(preferences),
      selectedSponsorshipItems: R.pathOr(
        [],
        ["quick_filters", "selectedSponsorshipItems"]
      )(preferences)
    }),
    setOrderIdsAndVariantIds: (
      state,
      { payload: { orderIds = [], variantIds = [] } }
    ) => {
      return {
        orderIds,
        variantIds
      };
    },
    receiveList: ({ tabStats }, { payload }) => ({
      [MAP_VIEW_TO_ROWS[payload.view]]:
        payload[getResultsKey[payload.view]] || [],
      tabStats: {
        ...tabStats,
        ...payload.counters
      },
      pagination: {
        ...payload.pagination,
        page: zeroIndexPage(payload)
      },
      showNoResults: (payload[getResultsKey[payload.view]] || []).length === 0
    }),
    setPageSize: (state, { payload }) => ({
      pagination: {
        ...state.pagination,
        pageSize: payload
      }
    }),
    setCurrentPage: ({ pagination }, { payload }) => ({
      pagination: {
        ...pagination,
        page: payload
      }
    }),
    setView: ({ mode }, { payload: view }) => ({
      view,
      searchTerm: "",
      selectedTab: mapModeToSelectedTab[mode] || STATUS.ALL
    }),
    setMode: (state, { payload: mode }) => ({
      mode,
      selectedTab: mapModeToSelectedTab[mode] || STATUS.ALL
    }),
    setSelectedTab: (state, { payload: selectedTab }) => ({
      selectedTab
    }),
    setTypeFilters: R.always({
      showPassModal: false,
      showSourceModal: false,
      showMealsModal: false,
      showUserModal: false
    }),
    setSelectedFieldFilters: (state, { payload: selectedFieldFilters }) => ({
      selectedFieldFilters,
      showFieldModal: false
    }),
    setSelectedMealsFilters: (state, { payload: selectedMealsFilters }) => ({
      selectedMealsFilters,
      showMealsModal: false
    }),
    removeSelectedFieldFilter: (state, { payload: idxOfFilter }) => {
      return {
        selectedFieldFilters: {
          filters: {
            ...state.selectedFieldFilters.filters,
            filters: R.remove(
              idxOfFilter,
              1
            )(state.selectedFieldFilters.filters.filters)
          }
        }
      };
    },
    setSelectedSummary: (state, { payload: summary }) => ({
      summary: {
        ...state.summary,
        ...summary
      },
      view:
        R.prop(summary.type, {
          [SUMMARIES.ACCOUNT]: MANAGE_BY.ATTENDEE_LIST,
          [SUMMARIES.CATEGORY]: MANAGE_BY.ALL_PASSES,
          [SUMMARIES.ITEM_TYPE]: MANAGE_BY.ALL_PASSES
        }) || state.view
    }),
    emptySummary: R.always({
      selectedSummaryId: "",
      view: null
    }),
    clearFilters: R.always({
      selectedPasses: [],
      selectedBoothItems: [],
      selectedSponsorshipItems: [],
      selectedInventoryItems: [],
      selectedMeals: [],
      selectedSources: [],
      selectedRequester: [],
      selectedFieldFilters: {}
    }),
    updateVisibleFields: (
      { preferences },
      { payload: { visibleFields, fieldOrder } }
    ) => ({
      preferences: {
        ...preferences,
        visible_fields: visibleFields || preferences.visible_fields,
        field_order: fieldOrder || preferences.field_order
      }
    }),
    showStatsModal: () => ({
      showStatsModal: true
    }),
    addViewResponse: (state, { payload: newView }) => ({
      views: [...state.views, newView],
      preferences: newView,
      originalPreferences: newView
    }),
    saveViewChangesResponse: (state, { payload: newView }) => ({
      views: state.views.map(v => (v.id === newView.id ? newView : v)),
      preferences: newView,
      originalPreferences: newView
    }),
    revertViewChanges: state => ({
      preferences: state.originalPreferences
    }),
    selectActiveView: (state, { payload: activeView }) => ({
      preferences: activeView,
      originalPreferences: activeView,
      pagination: {
        ...state.pagination,
        page: 0
      }
    }),
    selectView: (state, { payload: view }) => {
      return {
        preferences: view,
        originalPreferences: view,
        pagination: {
          ...state.pagination,
          page: 0
        }
      };
    },
    updateViewName: (
      { preferences, originalPreferences, views },
      { payload: { name } }
    ) => ({
      preferences: {
        ...preferences,
        name
      },
      originalPreferences: {
        ...originalPreferences,
        name
      },
      views: views.map(v => ({
        ...v,
        name: v.id === preferences.id ? name : v.name
      }))
    }),
    initData: R.identity,
    updateViewFields: R.identity,
    fetchViews: R.identity,
    fetchData: R.identity,
    fetchStats: R.identity,
    fetchSummary: R.identity,
    viewContactProfile: R.identity,
    viewAccountProfile: R.identity,
    exportData: R.identity,
    importData: R.identity,
    printItems: R.identity,
    undoPrintItems: R.identity,
    redirectToAttendeeBreakDown: R.identity,
    approve: R.identity,
    deny: R.identity,
    hideColumn: R.identity,
    showSubscribeModal: R.identity,
    updateVariantZones: R.identity
  },
  namespace: NAMESPACE
});

export default handlers;
