import React from "react";
import SubNav from "./SubNav";
import * as R from "ramda";
import { connect } from "react-redux";
import { TAB_IDS } from "./constants";
import { withRouter } from "react-router";
import PageTitle from "OrgLight/Common/PageTitle";

import Manage from "./Manage/View";
import Types from "./Types/View";
import Fields from "./Fields/View";
import DocumentTemplates from "./DocumentTemplates/View";
import ReportTemplates from "./ReportTemplates/View";
import EmailTemplates from "./EmailTemplates/View";

import { getCurrentRoute, getTabs } from "./selectors";

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    currentRoute: getCurrentRoute(state, props),
    tabs: getTabs(state, props)
  }))
);

const Wrapper = ({ currentRoute, tabs }) => {
  const Page = R.prop(currentRoute)({
    [TAB_IDS.MANAGE]: Manage,
    [TAB_IDS.TYPES]: Types,
    [TAB_IDS.FIELDS]: Fields,
    [TAB_IDS.DOCUMENT_TEMPLATES]: DocumentTemplates,
    [TAB_IDS.REPORT_TEMPLATES]: ReportTemplates,
    [TAB_IDS.EMAIL_TEMPLATES]: EmailTemplates
  });

  return (
    <>
      <PageTitle titles={["People"]} />
      <SubNav tabs={tabs} selectedView={currentRoute} />
      <Page />
    </>
  );
};

export default decorate(Wrapper);
