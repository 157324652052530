import { RECEIVE } from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";
import { eventId } from "redux/modules/event/selectors";

export function getModuleManagers(moduleId) {
  return async (dispatch, getState) => {
    try {
      const result = await api.get(getState().user.user.credentials, {
        eventId: eventId(getState()),
        moduleId
      });
      dispatch({
        type: RECEIVE,
        payload: {
          moduleId,
          managers: result.payload.managers,
          roles: result.payload.manager_permission_profiles
        }
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching module managers"
          }
        ])
      );
    }
  };
}
