import React from "react";
import { connect } from "react-redux";
import { Div, Text2, Radio } from "components/Base";
import { getters, actions } from "Items/AddQuestionModal";
import { ADD_QUESTION } from "Items/AddQuestionModal/constants";
import NewQuestionSet from "./NewQuestionSet";
import ExistingQuestionSets from "./ExistingQuestionSets";

const decorate = connect(
  state => ({
    addQuestionPage: getters.addQuestion(state),
    questionId: getters.questionId(state)
  }),
  {
    setAddQuestion: actions.setAddQuestion
  }
);

const AddQuestion = ({ addQuestionPage, setAddQuestion, questionId }) => (
  <Div width={1} height={1} p={4}>
    <Text2 bold color="black">
      Add Question to:
    </Text2>
    <Div mt={2}>
      <Div
        display="row.flex-start.center"
        onClick={() => {
          setAddQuestion(ADD_QUESTION.NO_SET);
        }}
      >
        <Radio selected={addQuestionPage === ADD_QUESTION.NO_SET} />
        <Text2 ml={2} color="black" bold>
          Don't add to question set
        </Text2>
      </Div>
      <Div
        display="row.flex-start.center"
        mt={1}
        onClick={() => {
          setAddQuestion(ADD_QUESTION.EXISTING_SET);
        }}
      >
        <Radio selected={addQuestionPage === ADD_QUESTION.EXISTING_SET} />
        <Text2 ml={2} color="black" bold>
          Existing question sets
        </Text2>
      </Div>
      {questionId ? null : (
        <Div
          display="row.flex-start.center"
          mt={1}
          onClick={() => {
            setAddQuestion(ADD_QUESTION.NEW_SET);
          }}
        >
          <Radio selected={addQuestionPage === ADD_QUESTION.NEW_SET} />
          <Text2 ml={2} color="black" bold>
            Create a new question set
          </Text2>
        </Div>
      )}
    </Div>
    <Div height={287} width={1} py={3} style={{ overflow: "auto" }}>
      {addQuestionPage === ADD_QUESTION.NEW_SET && <NewQuestionSet />}
      {addQuestionPage === ADD_QUESTION.EXISTING_SET && (
        <ExistingQuestionSets />
      )}
    </Div>
  </Div>
);

export default decorate(AddQuestion);
