export const NAMESPACE = "PortalSettingsPage";

export const SIDEBAR_OPTIONS = {
  ALL: "all",
  DRAFT: "draft",
  PUBLISHED: "published",
  SCHEDULED: "scheduled",
  ARCHIVED: "archiveds"
};

export const STATUS_COLORS = {
  published: { light: "#1EBCA5", dark: "#179C89" },
  draft: { light: "#DDD6E4", dark: "#8B8295" },
  scheduled: { light: "#F4C172", dark: "#E29F36" }
};
