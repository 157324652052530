import React from "react";
import { Div, FontIcon } from "components/Base";
import { connect } from "react-redux";
import { actions } from "../../model";
import { currentVideo } from "../../selectors";

const decorator = connect(
  (state, props) => ({
    currentVideo: currentVideo(state, props)
  }),
  { selectVideo: actions.setFeaturedVideoId }
);

const NowPlaying = ({ children }) => (
  <Div
    display="row.center.center"
    style={{
      position: "absolute",
      bottom: 10,
      left: 10,
      height: 20,
      backgroundColor: "#F40A42",
      borderRadius: 3.06667,
      fontSize: 10,
      color: "#FFFFFF",
      fontWeight: 600,
      paddingLeft: 6,
      paddingRight: 6
    }}
  >
    {children}
  </Div>
);

export const VideoItem = ({
  video,
  selectVideo,
  currentVideo,
  style: userStyles = {}
}) => (
  <Div onClick={() => selectVideo(video.id)} style={userStyles}>
    <Div style={{ position: "relative" }}>
      {video.id === currentVideo.id ? (
        <NowPlaying>NOW PLAYING</NowPlaying>
      ) : (
        <>
          <Div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              opacity: 0.5,
              height: 100,
              backgroundColor: "grey"
            }}
          ></Div>
          <Div
            display="row.center.center"
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              height: 100
            }}
          >
            <FontIcon fs={8} color="#FFFFFF">
              play_arrow
            </FontIcon>
          </Div>
        </>
      )}

      <Div
        width={1}
        height={100}
        style={{
          backgroundImage: `url(${video.thumbnail_image_url})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          marginBottom: 7,
          border: video.id === currentVideo.id ? "3px solid #F40A42" : "none"
        }}
      />
    </Div>

    <Div style={{ fontWeight: 600, fontSize: 14, color: "#000000" }}>
      {video.title}
    </Div>
  </Div>
);

export default decorator(VideoItem);
