/* eslint-disable react/prop-types */

import React, { Fragment } from "react";
import { connect } from "react-redux";

import { Div, LoadingIcon, Text4, Text3, Text6 } from "components/Base";
import * as R from "ramda";
import Page from "Portal/Page";

import { getBackgroundImageUrl } from "redux/modules/portal/selectors";
import {
  getReportsTitle,
  getReportsDescription
} from "redux/modules/portal/settings/selectors";

import ItemAssetReport from "./ItemAssetReport";
import AssetReport from "./AssetReport";
import CredentialsReport from "./CredentialsReport";
import CateringReport from "./CateringReport";

const Link = ({ children, active, onClick }) => (
  <Div
    onClick={onClick}
    bg={active ? "white" : { default: undefined, hover: "neutral1" }}
    color="black"
    fw={3}
    py={2}
    px={4}
    mr={1}
    display="row.center.center"
    brt={2}
  >
    {children}
  </Div>
);

const Links = ({ links }) => (
  <Div display="row.flex-start.center">
    {links.map(link => (
      <Link key={link.name} {...link}>
        {link.name}
      </Link>
    ))}
  </Div>
);

const decorate = connect(state => ({
  title: getReportsTitle(state),
  description: getReportsDescription(state),
  backgroundImageUrl: getBackgroundImageUrl(state)
}));

const PortalReportsView = ({
  title,
  description,
  backgroundImageUrl,
  activeReport,
  links,
  eventDetails,
  portalUser,
  loading,
  isEmpty,
  canViewInventory
}) => (
  <Page
    instanceId="reports"
    title={title}
    description={description}
    backgroundImageUrl={backgroundImageUrl}
  >
    {loading ? (
      <Div brb={2} brr={2} p={4} bg="white" mb={4}>
        <Div mt={6} display="row.center.center" flex={1}>
          <LoadingIcon size={60} color="neutral4" />
        </Div>
      </Div>
    ) : isEmpty ? (
      <Div brb={2} brr={2} p={4} bg="white" mb={4}>
        <Div display="column.center.center" mt={6} flex={1}>
          <img
            src="https://d2dks4tzxs6xee.cloudfront.net/img/faces.png"
            width="180"
            height="100"
            alt="Setup"
          />
          <Text4 bold mt={4} mb={1} color="gray7">
            There's nothing to report yet.
          </Text4>
          <Text3
            maxWidth={320}
            style={{ textAlign: "center" }}
            color="gray7"
            mb={4}
          >
            After your requests are submitted, you'll be able to view the status
            of your requests here.
          </Text3>
        </Div>
      </Div>
    ) : (
      <Fragment>
        <Text6 bold color="#123059" mb={4}>
          Reports
        </Text6>
        <Links {...{ links }} />
        <Div>
          {R.prop(activeReport, {
            assets: canViewInventory ? (
              <ItemAssetReport
                {...{
                  showTitle: false,
                  activeReport,
                  eventDetails,
                  portalUser
                }}
              />
            ) : (
              <AssetReport
                {...{
                  showTitle: false,
                  activeReport,
                  eventDetails,
                  portalUser
                }}
              />
            ),
            credentials: (
              <CredentialsReport
                {...{
                  showTitle: false,
                  activeReport,
                  eventDetails,
                  portalUser
                }}
              />
            ),
            meals: (
              <CateringReport
                {...{
                  showTitle: false,
                  activeReport,
                  eventDetails,
                  portalUser
                }}
              />
            )
          })}
        </Div>
      </Fragment>
    )}
  </Page>
);

export default decorate(PortalReportsView);
