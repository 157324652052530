import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE, PERSON_SUBCARDS, CARD_TYPES } from "./constants";

const iniState = {
  loadingPermissionSet: true,
  loadingPermissionSets: false,
  loadingOnboard: false,
  loadingForms: false,
  loadingTasks: false,
  loadingDocumentRequests: false,
  loadingSharedFiles: false,
  loadingRecordTypes: false,
  loadingCommunity: false,
  loadingAppearance: false,
  loadingGuestList: false,
  loadingSellItems: false,
  loadingPersonDocuments: false,
  loadingPersonForms: false,
  loadingPages: false,
  loadingPersonAutoAssignItems: false,
  loadingPersonRequestItems: false,
  loadingAutoAssignItems: false,
  //
  onboardDetails: {
    intake_form: {
      fields: [],
      sections: []
    }
  },
  communityDetails: {
    community_configured: false
  },
  permissionSet: {
    title: null,
    message: null,
    hub_background_image_url: null
  },
  guestListSettings: {
    visible_fields: [],
    required_fields: [],
    field_order: {}
  },
  //
  showCommunitySettingsModal: false,
  showIntakeSettingsModal: false,
  showAssignModal: false,
  showUpdateAssignFormModal: false,
  showUpdateAssignTaskModal: false,
  showUpdateAssignDocumentRequestModal: false,
  showSelectRequiredFieldsModal: false,
  showPeopleTypesModal: false,
  showSelectRequestableItemsModal: false,
  showSelectAutoAssignItemsModal: false,
  showUpdatePersonItemModal: false,
  showUpdatePersonAutoAssignItemModal: false,
  showAppearanceSettingsModal: false,
  showGuestListSettingsModal: false,
  showSelectGuestListItemsModal: false,
  showPageModal: false,
  showOpenTaskModal: false,
  //
  subtypes: [],
  selectedPermissionSetId: "",
  permissionsSets: [],
  moduleId: "",
  assignedForms: [],
  assignedTasks: [],
  forms: [],
  tasks: [],
  selectedTypeId: "",
  selectedModalId: "",
  documentRequests: [],
  assignedDocumentRequests: [],
  assignModalType: "",
  assignedSharedFiles: [],
  selectedSharedFileId: "",
  uploadedFileData: null,
  recordTypes: [],
  contactFields: [],
  itemsTypes: [],
  selectedModalTypeId: "",
  collapsablePeopleCards: {},
  selectedModalPersonId: "",
  selectedModalPersonItemId: "",
  assignedPersonFormIds: {},
  selectedPersonSubCard: "",
  assignedPages: [],
  pages: [],
  assignedAutoItems: [],
  assignedSellItems: []
};

const reducers = {
  init: R.identity,
  getPermissionSets: R.identity,
  getOnboardDetails: R.identity,
  getForms: R.identity,
  getTasks: R.identity,
  getFileRequests: R.identity,
  getSharedFiles: R.identity,
  getStaffPersonnel: R.identity,
  getCommunityDetails: R.identity,
  getAppearanceDetails: R.identity,
  getGuestListDetails: R.identity,
  getPages: R.identity,
  getIntakeDetails: R.identity,
  getAutoAssignedItems: R.identity,
  //
  updateRequestableItems: R.identity,
  onSelectRequestablePersonItems: R.identity,
  onSelectRequestableGroupItems: R.identity,
  onSelectAutoAssignItems: R.identity,
  onSelectGuestListItems: R.identity,
  onSelectSharedFile: R.identity,
  onSelectPage: R.identity,
  //
  showSellItemsModal: R.identity,
  openUpdateSellItemModal: R.identity,
  removeSellItem: R.identity,
  addPermissionSet: R.identity,
  copyIntakeFormLink: R.identity,
  goToLearnLennd: R.identity,
  goToForms: R.identity,
  updateAssignedForms: R.identity,
  //
  goToTasks: R.identity,
  updateTaskAssigned: R.identity,
  deleteAssignedTask: R.identity,
  updateAssignedTasks: R.identity,
  //
  deleteAssginedTask: R.identity,
  deleteAssginedForm: R.identity,
  deleteAssignedDocumentRequest: R.identity,
  removeSelectedItem: R.identity,
  removeSelectedAutoAssignItem: R.identity,
  removePersonForm: R.identity,
  removePersonDocument: R.identity,
  removeAssinedSharedFile: R.identity,
  removeAssinedPage: R.identity,
  removeGroupAutoAssignItem: R.identity,
  //
  updateAssginedTask: R.identity,
  updateAssginedForm: R.identity,
  goToDocumentRequests: R.identity,
  updateAssignedList: R.identity,
  updateAssignedDocuments: R.identity,
  updateModalData: R.identity,
  updateDocumentModalData: R.identity,
  updateSharedFile: R.identity,
  updatePage: R.identity,
  //
  reorderDocuments: R.identity,
  reorderForms: R.identity,
  reorderTasks: R.identity,
  reorderSharedFiles: R.identity,
  reorderPersonDocuments: R.identity,
  reorderPersonForms: R.identity,
  reorderPages: R.identity,
  //
  showFilePicker: R.identity,
  downloadShareFile: R.identity,
  saveSharedFile: R.identity,
  openSelectRequiredFieldsModal: R.identity,
  updateEnableSelectFields: R.identity,
  updateEnabledPeopleTypes: R.identity,
  openContentModal: R.identity,
  updateCloseDate: R.identity,
  updatePersonItemData: R.identity,
  updatePersonAutoAssignItemData: R.identity,
  updateAppearanceSettings: R.identity,
  updateAssignedPersonForms: R.identity,
  updateAssignedPersonDocuments: R.identity,
  togglePersonForm: R.identity,
  togglePersonDocument: R.identity,
  updatePersonAssginedForm: R.identity,
  updatePersonAssginedDocumentRequest: R.identity,
  updateGuestListSettings: R.identity,
  updateAssignedPages: R.identity,
  updateAutoAssignedItems: R.identity,
  updateAssignItems: R.identity,
  updateGroupItemData: R.identity,
  updateItemData: R.identity,
  getSellableItems: R.identity,
  setInitialData: (
    state,
    {
      payload: {
        loadingPermissionSet,
        moduleId,
        selectedTypeId,
        selectedPermissionSetId,
        permissionSet
      }
    }
  ) => ({
    loadingPermissionSet,
    moduleId,
    selectedTypeId,
    selectedPermissionSetId,
    permissionSet
  }),
  openUpdateModal: (_, { payload: { id, assignModalType } }) => ({
    selectedModalId: id,
    showUpdateAssignFormModal: true,
    assignModalType
  }),
  openUpdatePersonModal: (
    _,
    { payload: { id, assignModalType, selectedModalPersonId } }
  ) => ({
    selectedModalId: id,
    showUpdateAssignFormModal: true,
    assignModalType,
    selectedModalPersonId,
    selectedPersonSubCard: assignModalType
  }),
  closeUpdateModal: () => ({
    selectedModalId: "",
    showUpdateAssignFormModal: false,
    assignModalType: "",
    selectedModalPersonId: "",
    selectedPersonSubCard: ""
  }),
  setInitialDocumentRequestsData: (
    _,
    { payload: { documentRequests, assignedDocumentRequests } }
  ) => ({
    documentRequests,
    assignedDocumentRequests: R.sort(
      (a, b) => a.order - b.order,
      assignedDocumentRequests
    )
  }),
  openAssignModal: (_, { payload: assignModalType }) => ({
    showAssignModal: true,
    assignModalType
  }),
  updatePersonDueDate: (state, { payload: { id, value } }) => ({
    recordTypes: R.map(
      record => ({
        ...record,
        dueDate: record.id === id ? value : record.dueDate
      }),
      state.recordTypes
    )
  }),
  setPersonnelInitialData: (
    state,
    {
      payload: {
        subtypes,
        contactFields,
        recordTypes,
        loadingRecordTypes,
        itemsTypes
      }
    }
  ) => ({
    subtypes,
    contactFields,
    recordTypes,
    loadingRecordTypes,
    itemsTypes,
    collapsablePeopleCards: R.reduce(
      (acc, person) => ({
        ...acc,
        [person.id]: R.propOr(true, [person.id], state.collapsablePeopleCards)
      }),
      {},
      recordTypes
    ),
    loadingPersonDocuments: false,
    loadingPersonForms: false,
    loadingPersonAutoAssignItems: false,
    loadingPersonRequestItems: false
  }),
  toggleCollapsablePersonCard: (state, { payload: id }) => ({
    collapsablePeopleCards: {
      ...state.collapsablePeopleCards,
      [id]: !R.propOr(false, [id], state.collapsablePeopleCards)
    }
  }),
  openSelectRequestableItemsModal: (_, { payload: id }) => ({
    showSelectRequestableItemsModal: true,
    selectedModalPersonId: id,
    selectedPersonSubCard: PERSON_SUBCARDS.REQUESTABLE,
    assignModalType: PERSON_SUBCARDS.REQUESTABLE
  }),
  openSelectAutoAssignItemsModal: (_, { payload: id }) => ({
    showSelectAutoAssignItemsModal: true,
    selectedModalPersonId: id,
    selectedPersonSubCard: PERSON_SUBCARDS.AUTO_ASSIGN,
    assignModalType: PERSON_SUBCARDS.AUTO_ASSIGN
  }),
  openSelectGroupAutoAssignItemsModal: () => ({
    showSelectAutoAssignItemsModal: true,
    assignModalType: CARD_TYPES.AUTO_ASSIGN
  }),
  openSellItemsModal: () => ({
    showSelectRequestableItemsModal: true,
    assignModalType: CARD_TYPES.REQUESTABLE,
    selectedModalPersonId: "",
    selectedPersonSubCard: ""
  }),
  openAssignPersonModal: (_, { payload: { assignModalType, id } }) => ({
    showAssignModal: true,
    assignModalType,
    selectedModalPersonId: id,
    selectedPersonSubCard: assignModalType
  }),
  openAddNewTaskModal: (_, { payload: assignModalType }) => ({
    showOpenTaskModal: true,
    selectedModalId: "",
    assignModalType
  }),
  openEditTaskModal: (_, { payload: { id } }) => ({
    showOpenTaskModal: true,
    assignModalType: CARD_TYPES.TASKS,
    selectedModalId: id
  }),
  openUpdatePersonItemModal: (
    _,
    { payload: { personId, itemId, subCard } }
  ) => ({
    selectedModalPersonId: personId,
    showUpdatePersonItemModal: true,
    selectedModalPersonItemId: itemId,
    selectedPersonSubCard: subCard,
    assignModalType: subCard
  }),
  openUpdateGroupItemModal: (_, { payload: { id, assignModalType } }) => ({
    showUpdatePersonItemModal: true,
    selectedModalId: id,
    selectedPersonSubCard: "",
    selectedModalPersonId: "",
    selectedModalPersonItemId: "",
    assignModalType
  }),
  setTasksData: (_, { payload: { tasks } }) => ({
    assignedTasks: R.sort((a, b) => a.order - b.order, tasks),
    tasks
  }),
  setInitialPagesData: (_, { payload: { pages, assignedPages } }) => ({
    pages,
    assignedPages: R.sort((a, b) => a.order - b.order, assignedPages)
  }),
  openAddPageModal: (_, { payload }) => ({
    showPageModal: true,
    selectedModalId: payload.page_id
  }),
  closeAddPageModal: () => ({
    showPageModal: false
  })
};

const model = createHandlers({ iniState, reducers, namespace: NAMESPACE });

const { actions, getters } = model;

export { actions, getters };

export default model;
