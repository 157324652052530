import React, { useState } from "react";

import Popover from "@lennd/material-ui/Popover";

import { noop } from "utils/General";

const MenuPopover = ({
  Label,
  onClose = noop,
  children,
  wrapperStyle = {},
  className = "",
  rightClick = false,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const labelProps = rightClick
    ? {
        onContextMenu: ev => {
          ev.preventDefault();
          setAnchorEl(ev.target);
          setOpen(!open);
        }
      }
    : {
        onClick: ev => {
          ev.preventDefault();
          setAnchorEl(ev.target);
          setOpen(!open);
        }
      };

  return (
    <div style={wrapperStyle} className={className}>
      {React.cloneElement(Label, labelProps)}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setOpen(false);
          onClose();
        }}
        {...props}
      >
        {children({
          open,
          closePopover: () => setOpen(false)
        })}
      </Popover>
    </div>
  );
};

export default MenuPopover;
