/* eslint-disable no-underscore-dangle */
import React from "react";

import * as R from "ramda";
import { put, all, fork, select, takeEvery, call } from "redux-saga/effects";
import { actions, getters } from "EventLight/ProductionSchedules/Schedule";
import { showModal } from "redux/modules/modal/actions";
import { makeFuture } from "utils/General/sagas";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import { push } from "react-router-redux";
import SchedulsApi from "Schedules/Schedule/api";

import { getters as AddActivityRowGetters } from "Schedules/AddActivityRow";

import { getters as ViewPickerGetters } from "ui-kit/ViewPicker";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import SubscribeModal from "Modules/SubscribeModal/View";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import SelectScheduleFieldsModal from "Schedules/SelectScheduleFieldsModal/View";

import { VIEWPICKER_INSTANCE_ID } from "EventLight/ProductionSchedules/Schedule/constants";
import { getCredentials } from "redux/modules/user/selectors";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const eventId = yield select(getEventId);

  return {
    credentials,
    orgId,
    eventId
  };
};

const showSubscribeModal = function*() {
  const moduleId = yield select(getters.moduleId);
  const activeViewId = yield select(ViewPickerGetters.activeViewId, {
    instanceId: VIEWPICKER_INSTANCE_ID
  });

  yield put(
    showModal({
      content: (
        <SubscribeModal
          type="schedule"
          moduleId={moduleId}
          viewId={activeViewId}
        />
      ),
      wrapper: ModalWrapper
    })
  );
};

const showAddRecordModal = function*({ moduleId, values }) {
  const handleCreate = makeFuture();
  yield put(
    showModal({
      content: (
        <AddRecordModal
          moduleId={moduleId}
          typeId={STANDARD_MODULES.schedules.id}
          values={values}
          onSave={handleCreate.done}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleCreate.onRealized);
};

const handleShowAddRecordModal = function*() {
  const moduleId = yield select(getters.moduleId);
  const values = yield select(AddActivityRowGetters.values);
  const activityRowModuleId = yield select(AddActivityRowGetters.moduleId);

  const record = Object.keys(values).reduce((map, fieldId) => {
    if (values[fieldId].value) {
      map[fieldId] = values[fieldId].value;
    }
    return map;
  }, {});

  yield call(showAddRecordModal, {
    moduleId:
      moduleId === "master"
        ? R.prop("value")(activityRowModuleId || {})
        : moduleId,
    values: record
  });

  yield put(actions.fetchData());
};

const showSelectFieldsModal = function*() {
  const handleCreate = makeFuture();
  const preferences = yield select(getters.preferences);
  const moduleId = yield select(getters.moduleId);

  yield put(
    showModal({
      content: (
        <SelectScheduleFieldsModal
          moduleId={moduleId}
          visibleFields={preferences.visible_fields}
          fieldOrder={preferences.field_order}
          onSave={handleCreate.done}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleCreate.onRealized);
};

const handleShowSelectFieldsModal = function*() {
  const fields = yield call(showSelectFieldsModal);

  if (fields) {
    yield put(actions.updateViewFields(fields));
  }
};

const watchShowSelectFieldsModal = function*() {
  yield takeEvery(
    actions.showSelectFieldsModal.type,
    handleShowSelectFieldsModal
  );
};

const watchShowSubscribeModal = function*() {
  yield takeEvery(actions.showSubscribeModal.type, showSubscribeModal);
};

const watchShowAddRecordModal = function*() {
  yield takeEvery(actions.showAddRecordModal.type, handleShowAddRecordModal);
};
const deleteSchedule = function*({ payload: moduleId }) {
  const { credentials, eventId } = yield call(getParams);
  yield call(SchedulsApi.deleteSchedule, {
    credentials,
    moduleId
  });

  yield put(push(`/event-light/${eventId}/schedules/master`));

  return true;
};

const watchDeleteScheduleConfirm = function*() {
  yield takeEvery(actions.deleteSchedule.type, deleteSchedule);
};

const rootSaga = function*() {
  yield all([
    fork(watchShowSubscribeModal),
    fork(watchShowAddRecordModal),
    fork(watchShowSelectFieldsModal),
    fork(watchDeleteScheduleConfirm)
  ]);
};

export default rootSaga;
