import React from "react";
import { connect } from "react-redux";
import { getters } from "EventLight/Agenda/AddSessionModal/model";

import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Loading from "ui-kit/Loading";
import MediaContentModal from "ui-kit/MediaContentModal/View";

import { CONTENT_MODAL_ID } from "../constants";

const decorate = connect(state => ({
  loading: getters.loading(state)
}));

const Layout = ({ hideModal, handlers, loading, sessionId }) => (
  <>
    <StyleWrapper
      bodyStyles={{ padding: 0 }}
      containerStyles={{ overflowY: "hidden" }} // 728
      heading={sessionId ? "Edit Session" : "Add Session"}
      hideModal={hideModal}
      width={957}
    >
      {/* <Header hideModal={hideModal} /> */}
      {loading ? <Loading /> : <Body />}
      <Footer handlers={handlers} />
      <MediaContentModal instanceId={CONTENT_MODAL_ID} />
    </StyleWrapper>
  </>
);

export default decorate(Layout);
