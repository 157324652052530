import { all, take, put, call, select } from "redux-saga/effects";
import { eventChannel } from "redux-saga";

import { actions, getters } from "./model";
import { actions as SnackbarActions } from "ui-kit/Snackbar/model";

import { STRATEGY, STATUS } from "./constants";
import { SEVERITY } from "ui-kit/Alert/constants";

import superagent from "superagent";

export const createEventConnectionChannel = () =>
  eventChannel(emit => {
    const success = () => emit(STATUS.CONNECTED);
    const fail = () => emit(STATUS.DISCONNECTED);
    window.addEventListener("online", success);
    window.addEventListener("offline", fail);

    return () => {
      window.removeEventListener("online", success);
      window.removeEventListener("offline", fail);
    };
  });

export const createPollConnectionChannel = ({ url, pingInterval }) =>
  eventChannel(emit => {
    const interval = setInterval(() => {
      superagent
        .get(url)
        .then(() => emit(STATUS.CONNECTED))
        .catch(() => {
          emit(STATUS.DISCONNECTED);
        });
    }, pingInterval);

    return () => clearInterval(interval);
  });

const detectDisconnection = function*() {
  const strategy = yield select(getters.checkStrategy);
  const createChannel =
    strategy === STRATEGY.POLLING
      ? createPollConnectionChannel
      : createEventConnectionChannel;

  const url = yield select(getters.pingUrl);
  const pingInterval = yield select(getters.pingInterval);

  const channel = yield call(createChannel, { url, pingInterval });

  for (;;) {
    const newStatus = yield take(channel);
    const oldStatus = yield select(getters.connectionStatus);

    if (newStatus === STATUS.DISCONNECTED && oldStatus === STATUS.CONNECTED) {
      yield all([
        put(actions.setConnectionStatus(STATUS.DISCONNECTED)),
        put(
          SnackbarActions.show({
            id: "disconnection",
            severity: SEVERITY.ERROR,
            autoHideDuration: false,
            message: "You are currently not connected to the internet.",
            buttonAction: { id: "ok", name: "Ok" }
          })
        )
      ]);
    }
    if (newStatus === STATUS.CONNECTED && oldStatus === STATUS.DISCONNECTED) {
      yield all([
        put(actions.setConnectionStatus(STATUS.CONNECTED)),
        put(
          SnackbarActions.show({
            id: "connected",
            severity: SEVERITY.SUCCESS,
            message: "You are back online."
          })
        )
      ]);
    }
  }
};

const rootSaga = function*() {
  const legacy = /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/.test(
    navigator.userAgent
  );

  yield put(
    actions.setCheckStrategy(legacy ? STRATEGY.POLLING : STRATEGY.EVENT)
  );
  const connected =
    typeof navigator.onLine === "boolean" ? navigator.onLine : true;

  yield put(
    actions.setConnectionStatus(
      connected ? STATUS.CONNECTED : STATUS.DISCONNECTED
    )
  );

  const url = yield select(getters.pingUrl);
  if (url) {
    yield call(detectDisconnection);
  }
};

export default rootSaga;
