import { createSelector } from "reselect";

import * as R from "ramda";
import * as FormSelectors from "ui-kit/Form/selectors";

import { getters } from "./model";

export const getEditedField = R.compose(
  field => R.prop("value", field),
  FormSelectors.getField
);

export const isSaveDisabled = createSelector(
  getters.phoneNumber,
  R.compose(
    R.isEmpty,
    R.trim
  )
);
