import PropTypes from "prop-types";
import React from "react";
import { get } from "lodash";
import autobind from "autobind-decorator";
import EditorBase from "../TableConnector";
import Formatter from "../../CellFormatters/LinkedRecords";
import UploadButton from "components/Atoms/UploadButton";
import ExpandButton from "./ExpandButton";
import Helpers from "utils/Global/Helpers";
import getValue from "utils/value-types/get-value/lookup";
import isEmpty from "utils/value-types/is-empty/lookup";
import uuid from "node-uuid";
import FileViewer from "components/Global/Modals/FileViewer";
import FileViewerWrapper from "components/Global/Modals/FileViewer/Wrapper";
import * as SYSTEM_FIELD_IDS from "utils/system-field-ids";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import LinkedRecordsModal from "components/Global/CRM/Modals/LinkedRecords";

import { Div, AddIcon } from "components/Base";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

const { ID } = SYSTEM_FIELD_IDS;

const wrapValue = ({ moduleId, records }) => ({
  type: "lookup",
  value: {
    moduleId,
    records: records || []
  }
});

@CSSModules(css)
class LinkedRecordsEditor extends EditorBase {
  downloadFile(url) {
    window.open(url, "_blank");
  }

  handleChange(state) {
    return super.handleChange(state, this.props.onCommit);
  }

  @autobind
  deleteFile(file) {
    const linkedRecords = getValue(this.state).records;
    this.handleChange({
      type: "file",
      value: {
        files: [...linkedRecords.filter(f => f.id !== file.id)]
      }
    });
  }

  @autobind
  getValue() {
    return { [this.props.column.key]: wrapValue(getValue(this.state)) };
  }

  @autobind
  onExpandClick(e) {
    e.stopPropagation();
    this.setState({
      expanded: !get(this.state, "expanded", false)
    });
  }

  @autobind
  showFileViewer(file, files) {
    this.setState({ expanded: false });
    this.props.rowMetaData.helpers.showModal({
      content: (
        <FileViewer
          onFileDelete={this.deleteFile}
          start={files.indexOf(file)}
          files={files}
          isReadOnly={this.isReadOnly()}
        />
      ),
      wrapper: FileViewerWrapper
    });
  }

  @autobind
  isReadOnly() {
    return get(this.props, "column.settings.isReadOnly", false);
  }

  showLinkedRecordsModal = () => {
    this.props.rowMetaData.helpers.showModal({
      content: (
        <LinkedRecordsModal
          moduleId={this.props.rowMetaData.meta.moduleId}
          fieldId={this.props.column.id}
          recordId={this.props.rowData.id}
          linkedModuleId={get(this.props, "column.settings.moduleId")}
          linkedFieldId={get(this.props, "column.settings.fieldId", ID)}
          linkedRecordIds={getValue(this.state).records}
          onDone={() => {
            if (this.props.rowMetaData.helpers.refreshRecords) {
              this.props.rowMetaData.helpers.refreshRecords();
            }
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const { rowMetaData } = this.props;
    const linkedRecords = getValue(this.state).records;
    const isReadOnly = this.isReadOnly();

    return (
      <div
        styleName={isReadOnly ? "containerReadOnly" : "container"}
        ref="anchorEl"
      >
        {!isReadOnly ? (
          <Div
            bg={{ default: "neutral2", hover: "neutral1" }}
            onClick={this.showLinkedRecordsModal}
            display="flex.center.center"
            bra={1}
            ml={1}
            mr={2}
            style={{
              width: 20,
              height: 22,
              flexShrink: 0
            }}
          >
            <AddIcon size={16} color="gray9" />
          </Div>
        ) : null}
        <div styleName="formatter">
          <Formatter
            canClickRecord
            value={this.state}
            dependentValues={rowMetaData}
          />
        </div>
        {/*
        // @TODO
        {linkedRecords.length ? (
          <ExpandButton
            expanded={get(this.state, 'expanded', false)}
            closeExpanded={() => this.setState({ expanded: false })}
            onExpandClick={this.onExpandClick}
            anchorEl={this.refs.anchorEl}
            showFilepicker={this.showFilepicker}
            onFileClick={file => this.showFileViewer(file, linkedRecords)}
            downloadFile={this.downloadFile}
            deleteFile={this.deleteFile}
            files={linkedRecords}
            isReadOnly={isReadOnly}
          />
        ) : (
          ''
        )}
        */}
      </div>
    );
  }
}

LinkedRecordsEditor.propTypes = {
  ...EditorBase.propTypes,
  rowMetaData: PropTypes.object.isRequired
};

export default LinkedRecordsEditor;
