import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import DocumentsToolbar from "components/Event/Module/Toolbar/Documents";
import ModuleToolbar from "components/Event/Module/Toolbar/Module";
import AccountsToolbar from "components/Event/Module/Toolbar/Accounts";
import AccountReportPeopleToolbar from "components/Event/Module/Toolbar/AccountReportPeople";
import ContactsToolbar from "components/Event/Module/Toolbar/Contacts";

import { REPORT_ACCOUNT_PEOPLE_ID } from "components/Event/Module/utils/views";

export default function resolveToolbar(props) {
  switch (props.moduleId) {
    case STANDARD_MODULE_IDS.accounts.id:
      if (props.reportId === REPORT_ACCOUNT_PEOPLE_ID) {
        return AccountReportPeopleToolbar;
      }
      return AccountsToolbar;
    case STANDARD_MODULE_IDS.contacts.id:
      return ContactsToolbar;
    case STANDARD_MODULE_IDS.documentRequests.id:
      return DocumentsToolbar;
    default:
      return ModuleToolbar;
  }
}
