/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  getItemTypes: ({ credentials, eventId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/items/types/event/${eventId}`,
        credentials,
        success,
        error
      });
    }),
  getPage: ({ credentials, pageId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/sites/page/${pageId}`,
        credentials,
        success,
        error
      });
    }),
  addPage: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/sites/pages`,
        data,
        credentials,
        success,
        error
      });
    }),
  updatePage: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/sites/page/${data.pageId}`,
        credentials,
        data,
        success,
        error
      });
    })
};
