import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Button from "components/Event/FormsV2/Overview/SubmissionSummary/Button";

const TableFooter = ({ addRequest }) => (
  <div styleName="footer">
    <Button onClick={addRequest}>
      <i className="material-icons">add</i>
      Add Request
    </Button>
  </div>
);

TableFooter.propTypes = {
  addRequest: PropTypes.func.isRequired
};

export default CSSModules(TableFooter, css);
