import * as R from "ramda";
import { createModule, createHandlers } from "redux-mvc";
import { NAMESPACE } from "ui-kit/Sidebar/constants";

const iniState = {
  openedMenues: [],
  collapsed: false
};

const flatBy = (key, partial, h1, ...rest) => {
  if (!h1) {
    return partial;
  }

  const newRest = h1[key] ? [...h1[key], ...rest] : rest;
  return flatBy(key, [...partial, h1], ...newRest);
};

const reducers = {
  toggleMenu: ({ openedMenues }, { payload: menuId }) => ({
    openedMenues: R.contains(menuId, openedMenues)
      ? R.without([menuId], openedMenues)
      : R.concat([menuId], openedMenues)
  }),
  toggleCollapse: ({ collapsed }) => ({ collapsed: !collapsed }),
  init: ({ openedMenues }, { payload: { links, routes, iniOpened } }) => ({
    openedMenues: R.concat(
      openedMenues,
      R.concat(
        iniOpened,
        R.map(
          R.prop("id"),
          R.filter(
            l =>
              l.links &&
              R.any(ar => R.any(R.equals(ar), routes), l.activeRoutes || []),
            flatBy("links", [], ...links)
          )
        )
      )
    )
  })
};

const handlers = createHandlers({ iniState, reducers, namespace: NAMESPACE });

const { actions, getters, reducer } = handlers;

export { actions, getters, reducer };

const module = createModule(handlers);

export default module;
