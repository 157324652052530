import React from "react";
import * as R from "ramda";
import {
  Div,
  Text1,
  Text2,
  SmallToggle,
  CollapsablePanelType2
} from "components/Base";
import { addS } from "utils/General";

const mapIndex = R.addIndex(R.map);

const Zones = ({
  zones,
  zonesSelectedLength,
  disableGroupZones,
  enableGroupZones
}) => (
  <div>
    <CollapsablePanelType2
      headingText="Zones"
      headingExtras={
        <Text1>
          {zonesSelectedLength} zone{addS(zonesSelectedLength)} selected
        </Text1>
      }
    >
      <Div>
        {mapIndex(
          ({ name, id, zones }, idx) => (
            <Div
              bc="neutral3"
              bt={idx > 0 ? 2 : 0}
              pt={2}
              pb={2}
              key={id}
              mb={2}
            >
              <Div display="row.space-between.center">
                <Div fw={3} fs={1} bold color="neutral6">
                  {R.toUpper(name)}
                </Div>
                <Div display="row.flex-start.center">
                  <Text1
                    onClick={() => enableGroupZones(id)}
                    color={{ default: "neutral7", hover: "primary7" }}
                    transition="fast"
                  >
                    All
                  </Text1>
                  <Text1
                    ml={2}
                    onClick={() => disableGroupZones(id)}
                    color={{ default: "neutral7", hover: "primary7" }}
                    transition="fast"
                  >
                    None
                  </Text1>
                </Div>
              </Div>
              {R.map(
                zone => (
                  <Div
                    display="row.space-between.center"
                    mt={2}
                    key={zone.id}
                    pl={2}
                  >
                    <Text2 bold>{zone.name}</Text2>
                    <SmallToggle
                      active={zone.selected}
                      onClick={zone.onToggle}
                    />
                  </Div>
                ),
                zones
              )}
            </Div>
          ),
          zones
        )}
      </Div>
    </CollapsablePanelType2>
  </div>
);

export default Zones;
