import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../model";

import {
  Div,
  Text3,
  Text5,
  BigFilledButton,
  DownloadIcon
} from "components/Base";

import Table, { TableWrapper } from "ui-kit/Table/View";
import Loading from "ui-kit/Loading";
import { INSTANCE_SUMMARY_T, INSTANCE_ALL_ITEMS_T } from "../constants";

const decorate = R.compose(
  connect(
    state => ({
      loading: getters.loading(state)
    }),
    {
      init: actions.init,
      onClickToPrint: actions.onClickToPrint
    }
  )
);

const Layout = ({ loading, init, onClickToPrint }) => {
  useEffect(() => {
    init();
  }, []);
  return (
    <Div p={4} bg="white" mb={4} width={1}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Div display="row.space-between.center" pb={3} width={1}>
            <Text5 bold>All Assets</Text5>
            <BigFilledButton
              className="no-print"
              disabled
              onClick={onClickToPrint}
              pill
              LeftIcon={DownloadIcon}
            >
              Print
            </BigFilledButton>
          </Div>
          <Div bra={1} bc="neutral2" ba={1} bl={3} p={3} pb={1} mb={10}>
            <Text3 bold pl={2} mb={2}>
              Summary
            </Text3>
            <div styleName="requestTable">
              <TableWrapper style={{ flex: 1 }} instanceId={INSTANCE_SUMMARY_T}>
                <Table instanceId={INSTANCE_SUMMARY_T} showCheckbox={false} />
              </TableWrapper>
            </div>
          </Div>
          <Div bra={1} bc="neutral2" ba={1} bl={1} p={3} pb={1} mb={5}>
            <div styleName="requestTable">
              <TableWrapper
                style={{ flex: 1 }}
                instanceId={INSTANCE_ALL_ITEMS_T}
              >
                <Table instanceId={INSTANCE_ALL_ITEMS_T} showCheckbox={false} />
              </TableWrapper>
            </div>
          </Div>
        </>
      )}
    </Div>
  );
};

export default decorate(Layout);
