import api from "./api";
import { RECEIVE } from "./constants";
// import { addReferences } from "redux/modules/entityReferences/actions";
import { registerError } from "redux/modules/errors/actions";

export function getReport({ reportId, options }) {
  return async (dispatch, getState) => {
    try {
      const data = await api.get(
        getState().user.user.credentials,
        reportId,
        options
      );
      // @NOTE: We may need this
      // dispatch(addReferences(result.records.references));
      dispatch({
        type: RECEIVE,
        payload: {
          reportId,
          report: data.payload
        }
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting report"
          }
        ])
      );
    }
  };
}
