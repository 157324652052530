import {
  REQUEST,
  RECEIVE_STATS,
  RECEIVE_LIST,
  RECEIVE_PAGINATION,
  TOGGLE_MENU,
  LANDED
} from "./constants";

import api from "./api";
import { registerError } from "redux/modules/errors/actions";
import { eventId } from "redux/modules/event/selectors";

export function getStats() {
  return async (dispatch, getState) => {
    try {
      const result = await api.getStats(eventId(getState()));
      dispatch({
        type: RECEIVE_STATS,
        payload: result.payload
      });
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching checkin stats"
          }
        ])
      );
    }
  };
}

export function search(data) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: REQUEST });

      const result = await api.search(eventId(getState()), data);
      dispatch({
        type: RECEIVE_LIST,
        payload: result.payload.results
      });
      dispatch({
        type: RECEIVE_PAGINATION,
        payload: result.payload.pagination || {}
      });
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching checkin list"
          }
        ])
      );
    }
  };
}

export function toggleMenu(menuId) {
  return {
    type: TOGGLE_MENU,
    payload: menuId
  };
}

export function landed(routes) {
  return {
    type: LANDED,
    payload: routes
  };
}
