import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

function getRecordLocation({ pageSize, pages, page, total }) {
  const firstRecord = pageSize * page + 1;
  const finalRecord = firstRecord + Math.min(total - firstRecord, pageSize - 1);
  return [firstRecord, finalRecord];
}

const PageLocation = ({ pageSize, page, pages, rowsText, total }) => {
  if (total > pageSize) {
    return (
      <div className={css.container}>
        {`${getRecordLocation({ pageSize, pages, page, total }).join(
          " — "
        )} of `}
        <span className={css.bold}>{`${total} ${rowsText}`}</span>
      </div>
    );
  }
  return (
    <div className={css.container}>
      <span className={css.bold}>{`${total} ${rowsText}`}</span>
    </div>
  );
};

PageLocation.propTypes = {
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  rowsText: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired
};

export default CSSModules(PageLocation, css);
