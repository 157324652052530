import * as R from "ramda";
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { noop } from "utils/General";

import { actions } from "ui-kit/Form/model";
import { getValue, getUserOptions } from "ui-kit/Form/selectors";
import { FIELD_TYPES } from "ui-kit/Form/constants";
import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";

import { showModal } from "redux/modules/modal/actions";
import { eventDetails } from "redux/modules/event/selectors";

import Avatar from "components/Atoms/Avatar";
import AddEventTeamMemberModal from "components/Global/Modals/AddEventTeamMemberModal";

const decorate = R.compose(
  WithFormInstanceConsumer({ fieldType: FIELD_TYPES.USER }),
  connect(
    (state, props) => ({
      value: getValue(state, props),
      options: getUserOptions(state, props),
      eventDetails: eventDetails(state, props)
    }),
    bindInstance({
      onChange: actions.setFieldValue,
      onBlur: actions.blurField,
      setIniValue: actions.setIniValue,
      getUsers: actions.getUsers
    })
  ),
  CSSModules(css)
);

export const UCUser = ({
  value = [],
  iniValue,
  fieldId,
  fieldType,
  multipleSelect = false,
  options = [],
  onChange = noop,
  onBlur = noop,
  setIniValue = noop,
  getUsers = noop,
  eventDetails
}) => {
  const inputEl = useRef(null);
  useEffect(() => {
    setIniValue(iniValue, {
      meta: { fieldType, fieldId, multipleSelect }
    });
    getUsers();

    if (inputEl.current) {
      inputEl.current.focus();
    }
  }, []);
  const [filter, setFilter] = useState("");

  const handleMemberAdded = (data, user) => {
    getUsers();

    if (user.user_id) {
      onChange(
        R.concat(value, [
          {
            type: "member",
            id: user.user_id,
            name: `${user.user_fname} ${user.user_lname}`,
            photoUrl: user.user_photo_url
          }
        ]),
        { meta: { fieldId, fieldType } }
      );
    } else if (user.id) {
      onChange(
        R.concat(value, [
          {
            type: "member",
            id: user.id,
            name: `${user.fname} ${user.lname}`,
            photoUrl: null
          }
        ]),
        { meta: { fieldId, fieldType } }
      );
    }
  };

  const showAddMemberModal = () =>
    showModal({
      content: (
        <AddEventTeamMemberModal
          addToEventId={eventDetails.id}
          onAdded={handleMemberAdded}
        />
      )
    });

  // vars
  const users = options;
  const disableAddPerson = true;
  const excludeUsers = [];

  const eventUsers = R.filter(u => u.is_event_user, users);

  let records = eventUsers;
  if (excludeUsers && excludeUsers.length) {
    records = R.filter(u => !excludeUsers.includes(u.user_id), records);
  }

  let filteredRecords;
  if (filter.length) {
    const filterString = filter.toLowerCase();
    filteredRecords = R.filter(
      r =>
        `${r.user_fname} ${r.user_lname}`.toLowerCase().indexOf(filterString) >
        -1,
      records
    );
  } else {
    filteredRecords = records;
  }

  return (
    <div styleName="pickerWrapper">
      <div styleName="pickerFilterWrapper">
        <i className={["material-icons", css.pickerFilterIcon].join(" ")}>
          &#xE8B6;
        </i>
        <input
          ref={inputEl}
          onChange={e => setFilter(e.target.value)}
          onBlur={() => onBlur(null, { meta: { fieldId, fieldType } })}
          placeholder="Start typing name"
          styleName="pickerFilterInput"
          type="text"
          value={filter}
        />
      </div>
      <div styleName="pickerRecordsWrapper">
        {filteredRecords.map(record => {
          const id = record.user_id;
          const type = "member";
          const name = record.user_fname
            ? `${record.user_fname} ${record.user_lname}`
            : record.user_email;
          const photoUrl = record.user_photo_url;

          const isSelected = R.any(v => v.id === id && v.type === type)(value);
          const splitName = name ? name.split(" ") : {};

          return (
            <div
              styleName="pickerRecord"
              key={id}
              onClick={() =>
                isSelected
                  ? onChange(
                      {
                        records: R.filter(
                          R.compose(
                            R.not,
                            R.propEq("id", id)
                          )
                        )(value)
                      },
                      {
                        meta: { fieldId, fieldType }
                      }
                    )
                  : onChange(
                      {
                        records: R.concat(value, [
                          {
                            id,
                            type,
                            name,
                            photoUrl
                          }
                        ])
                      },
                      { meta: { fieldId, fieldType } }
                    )
              }
              title={name}
            >
              <div styleName="pickerRecordAvatar">
                <Avatar
                  userId={id}
                  imageUrl={photoUrl}
                  firstName={splitName[0] || ""}
                  lastName={splitName[1] || ""}
                  size={30}
                  alt
                  border
                />
              </div>
              <div styleName="pickerRecordName">{name}</div>
              <div styleName="pickerRecordCheck">
                <i
                  className={[
                    "material-icons",
                    css.pickerRecordCheckIcon,
                    isSelected && css.pickerRecordCheckSelectedIcon
                  ].join(" ")}
                >
                  &#xE5CA;
                </i>
              </div>
            </div>
          );
        })}
        {!filteredRecords.length && (
          <div
            className={[css.record, css.recordWithName, css.emptyRecord].join(
              " "
            )}
          >
            <div styleName="name">No users to show!</div>
          </div>
        )}
      </div>
      {!disableAddPerson ? (
        <div
          key="add"
          styleName="pickerAddWrapper"
          onClick={showAddMemberModal}
        >
          <i className={["material-icons", css.pickerAddIcon].join(" ")}>
            &#xE145;
          </i>
          <span>Add a person</span>
        </div>
      ) : null}
    </div>
  );
};

export const User = decorate(UCUser);
