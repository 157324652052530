import React from "react";
import { connect } from "react-redux";
import { Div, MediumFilledButton } from "components/Base";
import { getters, actions } from "Portal/PortalHome/model";

const decorate = connect(
  state => ({
    dueDate: getters.dueDate(state)
  }),
  {
    toggleDueDate: actions.toggleDueDate
  }
);

const ToggleDueDate = ({ dueDate, toggleDueDate }) => (
  <Div pill bg="neutral2" p={1}>
    <MediumFilledButton
      pill
      color="gray8"
      bg={dueDate ? "neutral1" : "neutral2"}
      onClick={toggleDueDate}
    >
      Due Date
    </MediumFilledButton>
  </Div>
);

export default decorate(ToggleDueDate);
