import React, { Component } from "react";
import View from "./View";
import { CREDENTIAL_TYPE_ID, MEAL_TYPE_ID } from "utils/item-types";
import * as R from "ramda";
import moment from "moment";

const getDayColor = (dateGroups, day) => {
  const group = dateGroups.filter(g => g.days.includes(day));
  return group.length ? group[0].color : "#eee";
};

const constructState = props => {
  return {
    saving: false,
    loading: true,
    searchTerm: "",
    activeItemTypeId: CREDENTIAL_TYPE_ID,
    selectedVariants: props.selectedVariants || {}
  };
};

class Controller extends Component {
  constructor(props) {
    super(props);
    this.state = constructState(props);
  }

  async componentDidMount() {
    await this.props.getAllItemTypesByEvent(this.props.params.eventId);
    this.setState({ loading: false });
  }

  componentDidUpdate(oldProps) {
    if (oldProps.variants.length !== this.props.variants.length) {
      this.setState(constructState(this.props));
    }
  }

  handleSave = async () => {
    this.props.onDone(this.state.selectedVariants);
    this.props.hideModal();
  };

  onItemTypeSearchChange = searchTerm =>
    this.setState({
      searchTerm
    });

  onChangeItemType = activeItemTypeId => {
    this.setState({
      activeItemTypeId,
      searchTerm: ""
    });
  };

  bulkUpdateVariantQuantity = (ids, quantity) =>
    this.setState(state => {
      for (const id of ids) {
        if (!quantity || quantity <= 0) {
          delete state.selectedVariants[id];
        } else {
          state.selectedVariants = {
            ...state.selectedVariants,
            [id]: quantity <= 0 ? 0 : quantity
          };
        }
      }

      return state;
    });

  updateVariantQuantity = (id, quantity) =>
    this.setState(state => {
      if (!quantity || quantity <= 0) {
        delete state.selectedVariants[id];
        return state;
      }

      state.selectedVariants = {
        ...state.selectedVariants,
        [id]: quantity <= 0 ? 0 : quantity
      };
      return state;
    });

  render() {
    const {
      loading,
      searchTerm,
      activeItemTypeId,
      selectedVariants
    } = this.state;
    const { hideModal, eventDetails, itemGroups, types } = this.props;

    const hasItemGroups = Boolean(itemGroups.length);
    const hasSearchTerm = Boolean(searchTerm.length);
    const lowercaseSearchTerm = hasSearchTerm
      ? searchTerm.toLowerCase().trim()
      : "";

    const itemTypes = R.compose(
      R.map(type => ({
        id: type.id,
        name: type.name,
        active: activeItemTypeId === type.id,
        onClick: () => this.onChangeItemType(type.id)
      })),
      R.filter(R.propEq("id", CREDENTIAL_TYPE_ID))
    )(types);

    const passesWithHandlers = hasItemGroups
      ? R.compose(
          R.filter(g => g.items.length),
          groups =>
            hasSearchTerm
              ? R.map(group => ({
                  ...group,
                  items: group.items.filter(item =>
                    item.name.toLowerCase().includes(lowercaseSearchTerm)
                  )
                }))(groups)
              : groups,
          R.map(group => ({
            ...group,
            items: R.reduce((list, item) => {
              R.compose(
                R.forEach(variant => {
                  const variantId = variant.id;
                  list.push({
                    id: variantId,
                    name: variant.display_name,
                    color: item.background_color || "#ccc",
                    qty:
                      selectedVariants[variantId] >= 0
                        ? selectedVariants[variantId]
                        : 0,
                    description: item.description,
                    providerId: variant.provider_id,
                    updateVariantQuantity: qty =>
                      this.updateVariantQuantity(
                        variantId,
                        parseInt(qty, 10) || 0
                      )
                  });
                }),
                R.sortBy(R.prop("order"))
              )(item.variants);
              return list;
            }, [])(group.items)
          })),
          R.filter(g => g.type_id === CREDENTIAL_TYPE_ID)
        )(itemGroups)
      : [];

    const dayGroups = R.map(group => ({
      id: group.id,
      name: group.name,
      color: group.color
    }))(eventDetails.date_groups);

    const selectedCateringDays = R.pathOr(
      [],
      ["module_settings", "catering", "selected_days"]
    )(eventDetails);

    const mealDays = R.pathOr([], ["module_settings", "catering", "meal_days"])(
      eventDetails
    );

    const mealsWithHandlers = hasItemGroups
      ? R.compose(
          R.filter(meal => meal.validVariantIds.length),
          meals =>
            hasSearchTerm
              ? meals.filter(meal =>
                  meal.name.toLowerCase().includes(lowercaseSearchTerm)
                )
              : meals,
          R.map(({ id, name, variants }) => {
            const validVariantIds = R.compose(
              R.map(R.prop("id")),
              R.filter(v =>
                v.rules.find(
                  r =>
                    r.pattern === "is_valid_for" &&
                    selectedCateringDays.includes(r.value) &&
                    mealDays.filter(d => d.mealId === id && d.dayId === r.value)
                      .length
                )
              )
            )(variants);

            return {
              id,
              name,
              variants,
              validVariantIds,
              selectAllItems: () =>
                this.bulkUpdateVariantQuantity(validVariantIds, 1),
              deselectAllItems: () =>
                this.bulkUpdateVariantQuantity(validVariantIds, 0)
            };
          }),
          R.propOr([], "items"),
          R.head,
          R.filter(g => g.type_id === MEAL_TYPE_ID)
        )(itemGroups)
      : [];

    const mealsByDay = R.groupBy(R.prop("dayId"))(mealDays);

    const getMealItemVariantByMealDay = (itemId, day) => {
      if (!hasItemGroups) return null;

      const mealVariants = R.compose(
        R.prop("variants"),
        R.find(R.propEq("id", itemId))
      )(mealsWithHandlers);

      if (!mealVariants || !mealVariants.length) return null;

      return R.find(v =>
        Boolean(
          v.rules.find(r => r.pattern === "is_valid_for" && r.value === day)
        )
      )(mealVariants);
    };

    const mealDaysWithHandlers = R.compose(
      R.sortBy(R.prop("id")),
      R.map(day => {
        const dayInstance = moment(day);
        return {
          id: day,
          dayName: dayInstance.format("ddd"),
          dayNumber: dayInstance.format("D"),
          color: getDayColor(eventDetails.date_groups, day),
          meals: R.compose(
            R.reduce((mealMap, mealId) => {
              const variant = getMealItemVariantByMealDay(mealId, day);
              if (variant) {
                mealMap[mealId] = {
                  show: true,
                  qty:
                    selectedVariants[variant.id] >= 0
                      ? selectedVariants[variant.id]
                      : 0,
                  updateVariantQuantity: qty =>
                    this.updateVariantQuantity(
                      variant.id,
                      parseInt(qty, 10) || 0
                    )
                };
              }
              return mealMap;
            }, {}),
            R.map(R.prop("mealId")),
            R.propOr([], day)
          )(mealsByDay)
        };
      })
    )(selectedCateringDays);

    const itemTypeProps = {
      passes: {
        mode: "quantity",
        itemGroups: passesWithHandlers
      },
      meals: {
        mode: "quantity",
        dayGroups,
        meals: mealsWithHandlers,
        days: mealDaysWithHandlers
      }
    };

    return (
      <View
        {...{
          onSave: this.handleSave,
          onItemTypeSearchChange: this.onItemTypeSearchChange,
          //
          loading,
          hideModal,
          itemTypes,
          activeItemTypeId,
          itemTypeProps
        }}
      />
    );
  }
}

export default Controller;
