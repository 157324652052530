import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import autobind from "autobind-decorator";
import getValue from "utils/value-types/get-value/user";
import { filter, find, uniq } from "lodash";

import Avatar from "components/Atoms/Avatar";
import AddEventTeamMemberModal from "components/Global/Modals/AddEventTeamMemberModal";

@CSSModules(css)
class UserEditorPicker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSource: "users",
      filter: "",
      selectedRecords: this.formatValue(props.value).records || []
    };
  }

  componentWillMount() {
    this.props.getUsers(this.props.eventDetails.id);
  }

  componentDidMount() {
    setTimeout(this.focusInput.bind(this), 500);
  }

  @autobind
  getInputNode() {
    return this.input;
  }

  @autobind
  wrapValue(records) {
    return {
      type: "user",
      value:
        records && records.length
          ? {
              records: uniq(records, r => `${r.type}_${r.id}`)
            }
          : undefined
    };
  }

  @autobind
  updateRecords(selectedRecords) {
    this.props.onChange(this.wrapValue(selectedRecords));
    this.setState({ selectedRecords });
  }

  formatValue(value) {
    return getValue(value);
  }

  @autobind
  toggleRecord(data, toggle) {
    let records = this.state.selectedRecords;
    if (!toggle) {
      records = filter(
        records,
        r => !(r.id === data.id && r.type === data.type)
      );
    } else {
      records.push(data);
    }

    this.updateRecords(records);
  }

  @autobind
  showUsers() {
    this.setState(
      {
        activeSource: "users",
        filter: ""
      },
      this.focusInput
    );
  }

  focusInput() {
    if (this.input) {
      this.input.focus();
    }
  }

  @autobind
  handleFilterChange(e) {
    this.setState({
      filter: e.target.value
    });
  }

  @autobind
  showAddMemberModal() {
    this.props.showModal({
      content: (
        <AddEventTeamMemberModal
          addToEventId={this.props.eventDetails.id}
          onAdded={this.handleMemberAdded}
        />
      )
    });
  }

  @autobind
  handleMemberAdded(data, user) {
    this.props.getUsers(this.props.eventDetails.id);

    if (user.user_id) {
      this.toggleRecord(
        {
          type: "member",
          id: user.user_id,
          name: `${user.user_fname} ${user.user_lname}`,
          photoUrl: user.user_photo_url
        },
        true
      );
    } else if (user.id) {
      this.toggleRecord(
        {
          type: "member",
          id: user.id,
          name: `${user.fname} ${user.lname}`,
          photoUrl: null
        },
        true
      );
    }
  }

  render() {
    const { users, excludeUsers, isFetchingUsers } = this.props;

    const eventUsers = users.filter(u => u.is_event_user);

    let records = [];
    if (this.state.activeSource === "users") {
      records = eventUsers;
      if (excludeUsers && excludeUsers.length) {
        records = records.filter(u => !excludeUsers.includes(u.user_id));
      }
    }

    let filteredRecords;
    if (this.state.filter.length) {
      const filterString = this.state.filter.toLowerCase();
      if (this.state.activeSource === "users") {
        filteredRecords = filter(
          records,
          r =>
            `${r.user_fname} ${r.user_lname}`
              .toLowerCase()
              .indexOf(filterString) > -1
        );
      }
    } else {
      filteredRecords = records;
    }

    return (
      <div styleName="pickerWrapper">
        <div styleName="pickerFilterWrapper">
          <i className={["material-icons", css.pickerFilterIcon].join(" ")}>
            &#xE8B6;
          </i>
          <input
            ref={ref => (this.input = ref)}
            onChange={this.handleFilterChange}
            placeholder="Start typing name"
            styleName="pickerFilterInput"
            type="text"
            value={this.state.filter}
          />
        </div>
        <div styleName="pickerRecordsWrapper">
          {isFetchingUsers ? (
            <div styleName="pickerWrapper">Loading...</div>
          ) : (
            filteredRecords.map(record => {
              let id;
              let name;
              let photoUrl;
              let type;

              if (this.state.activeSource === "users") {
                type = "member";
                id = record.user_id;
                name = record.user_fname
                  ? `${record.user_fname} ${record.user_lname}`
                  : record.user_email;
                photoUrl = record.user_photo_url;
              }

              const isSelected =
                find(this.state.selectedRecords, { id, type }) || false;

              const splitName = name ? name.split(" ") : {};

              const selectRecord = () => {
                this.toggleRecord(
                  {
                    type,
                    id,
                    name,
                    photoUrl
                  },
                  !isSelected
                );
              };

              return (
                <div
                  styleName="pickerRecord"
                  key={`picker_${type}_${id}`}
                  onClick={selectRecord}
                >
                  <div styleName="pickerRecordAvatar">
                    <Avatar
                      userId={id}
                      imageUrl={photoUrl}
                      firstName={splitName[0] || ""}
                      lastName={splitName[1] || ""}
                      size={30}
                      alt
                      border
                    />
                  </div>
                  <div styleName="pickerRecordName">{name}</div>
                  <div styleName="pickerRecordCheck">
                    <i
                      className={[
                        "material-icons",
                        css.pickerRecordCheckIcon,
                        isSelected && css.pickerRecordCheckSelectedIcon
                      ].join(" ")}
                    >
                      &#xE5CA;
                    </i>
                  </div>
                </div>
              );
            })
          )}
          {!filteredRecords.length && (
            <div
              className={[css.record, css.recordWithName, css.emptyRecord].join(
                " "
              )}
            >
              <div styleName="name">No users to show!</div>
            </div>
          )}
        </div>
        {!this.props.disableAddPerson ? (
          <div
            key="add"
            styleName="pickerAddWrapper"
            onClick={this.showAddMemberModal}
          >
            <i className={["material-icons", css.pickerAddIcon].join(" ")}>
              &#xE145;
            </i>
            <span>Add a person</span>
          </div>
        ) : null}
      </div>
    );
  }
}

UserEditorPicker.defaultProps = {
  excludeUsers: []
};

UserEditorPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  styles: PropTypes.object,
  disableAddPerson: PropTypes.bool,
  scrollParent: PropTypes.object.isRequired,
  rowMetaData: PropTypes.object.isRequired,
  eventMembers: PropTypes.object.isRequired,
  eventDetails: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  getUsers: PropTypes.func.isRequired,
  isFetchingUsers: PropTypes.bool.isRequired,
  excludeUsers: PropTypes.arrayOf(PropTypes.number)
};

export default UserEditorPicker;
