import React, { Component } from "react";
import update from "immutability-helper";
import { debounce, get } from "lodash";
import * as R from "ramda";
import qs from "query-string";
import kanbanFieldFilter from "components/Event/Module/utils/kanban-field-filter";
import SubmissionApi from "redux/modules/formsV2/submission/api";
import { createContext } from "redux-mvc";

import { withRouter } from "react-router";

import ListView from "components/Global/CRM/TableViews/ListView";
import KanbanView from "components/Global/CRM/TableViews/KanbanView";
import GridView from "components/Global/CRM/TableViews/GridView";

import getColumns from "components/Global/CRM/TableViews/utils/get-fields";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import ModalColumnEdit from "Modules/AddEditColumnModal/View";
import ViewRecordModal from "components/Global/Module/Modals/ViewRecord";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { filterRecords } from "utils/value-types/filter";
import flattenFields from "components/Global/CRM/TableViews/utils/flatten-fields";
import AddFieldModal from "Modules/AddEditColumnModal/View";
import Container from "components/Global/Module/Container";
import Header from "components/Global/Module/Header";
import Separator from "components/Global/Module/Separator";
import Search from "components/Global/Module/Search";
import Sidebar from "components/Global/Module/Sidebar";
import ShowHideSwitch from "components/Global/Module/Sidebar/ShowHideSwitch";
import AddSubmissionAsModal from "components/Event/FormsV2/Modals/AddSubmissionAsModal";

import resolveViewPath from "components/Event/Module/utils/resolve-view-path";
import resolveNavigation from "./utils/resolveNavigation";
import resolveActions from "./utils/resolveActions";
import resolveQueryFilteredRecords from "./utils/resolveQueryFilteredRecords";
import resolveReadOnlyFields from "./utils/resolveReadOnlyFields";
import resolveShowRecordModalHandler from "./utils/resolveShowRecordModalHandler";
import resolveToolbar from "./utils/resolveToolbar";

import ManageView, { ManageProps, contextConfig } from "Items/Manage/View";
import manage from "Items/Manage";

const ManagePasses = R.compose(
  withRouter,
  ManageProps,
  createContext({ module: manage, ...contextConfig })
)(ManageView);

import { isPassesRoute } from "components/Event/Module/utils";

class ModuleRecords extends Component {
  componentDidMount() {
    if (this.props.location.query.recordId || this.props.context.recordId) {
      const recordId =
        this.props.location.query.recordId || this.props.context.recordId;
      resolveShowRecordModalHandler({
        moduleId: this.props.context.moduleId,
        meta: {
          recordId,
          showModal: this.props.showModal,
          hideModal: () => {
            this.props.router.push({
              pathname: this.props.viewIdUrl
            });
            this.onHideModal();
          }
        }
      });
    }

    this.fetchModule(this.props);
  }

  redirected = false;

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.context.moduleId !== this.props.context.moduleId) {
      // handle: module change
      this.fetchModule(nextProps);
      this.clearSearch();
    } else if (
      !nextProps.disableViews &&
      !nextProps.context.viewId &&
      nextProps.activeView.id &&
      !this.redirected &&
      !isPassesRoute(nextProps)
    ) {
      // handle: module landing with no view selected
      this.redirected = true;
      this.props.replace(
        `${this.props.baseUrl}${resolveViewPath({
          views: nextProps.views,
          viewId: nextProps.activeView.id,
          groupId: nextProps.params.groupId,
          filterByAccountRecordTypeId:
            nextProps.params.filterByAccountRecordTypeId,
          filterByContactRecordTypeId:
            nextProps.params.filterByContactRecordTypeId,
          search: this.props.location.search
        })}`
      );
      this.props.deselectAllRows();
    } else if (this.props.context.viewId !== nextProps.context.viewId) {
      // handle: view change
    }
  }

  componentWillUnmount() {
    this.clearSearch();
  }

  clearSearch = () => {
    if (this.props.clearSearch) {
      this.props.clearSearch(this.props.module.id);
    }
  };

  fetchModule = (props = this.props) =>
    Promise.all([
      this.props.deselectAllRows(props.context.moduleId),
      this.props.getModule(props.context.moduleId),
      this.props.getViews(props.context.moduleId),
      this.props.getRecordTypes(props.context.moduleId)
    ]).then(() => Promise.all([this.fetchRecords(props)]));

  onHideModal = () => {
    this.fetchRecords(this.props);
    this.props.hideModal();
  };

  selectView = viewId => {
    this.props.replace({
      pathname: this.props.viewUrl,
      query: {
        ...qs.parse(location.search),
        view: viewId
      }
    });
  };

  columnDefinition = column => {
    const c = { ...column };
    c.editable =
      typeof column.settings.editable !== "undefined"
        ? column.settings.editable
        : !resolveReadOnlyFields({
            moduleId: this.props.context.moduleId
          }).includes(c.id);
    return c;
  };

  getColumns = (columns = []) => {
    const columnDefinitions = columns.map(this.columnDefinition);
    return getColumns(columnDefinitions, {
      isFieldVisible: this.isFieldVisible,
      getFieldOrder: this.getFieldOrder,
      getFieldWidth: this.getFieldWidth,
      meta: {
        orgId: this.props.context.orgId,
        eventId: this.props.context.eventId,
        moduleId: this.props.context.moduleId,
        helpers: {
          showModal: this.props.showModal,
          router: this.props.router,
          hideModal: this.onHideModal
        }
      }
    });
  };

  getFieldOrder = c => {
    if (get(this.props, "activeView.field_order")) {
      return get(this.props.activeView.field_order, c.id, 1);
    }
    return 1;
  };

  getFieldWidth = c => {
    if (c.width) {
      // Handle hard coded fixed width (approvals, system fields)
      return c.width;
    }
    const fieldWidths = get(this.props, "activeView.field_widths");
    // @NOTE: The fallback field width (if undefined below) is handled in `get-column`,
    // so, no need to specify a fallback below unless you want to overide the default
    return get(fieldWidths, [c.id], 200);
  };

  getRows = () => {
    const {
      user,
      records,
      location,
      fields,
      activeView,
      references,
      forms
    } = this.props;

    const rows = [
      ...resolveQueryFilteredRecords({
        filterByEventId: get(activeView, "filter_by_event_id"),
        quickFilters: get(activeView, "quick_filters", []),
        forms,
        query: new URLSearchParams(location.search),
        records,
        meta: { user }
      })
    ];

    if (get(activeView, "filters.filters.length")) {
      return filterRecords(activeView.filters, flattenFields(fields), rows, {
        references
      });
    }

    return rows;
  };

  isFieldVisible = c => {
    const visibleFields = get(this.props, "activeView.visible_fields", []);
    if (visibleFields.length) {
      return visibleFields.includes(c.id);
    }
    return true;
  };

  fetchRecords = (props = this.props) =>
    props.getRecords(props.context.moduleId);

  showAddRecordModal = () => {
    const groupTypeParams = this.props.context.groupId
      ? {
          onlyRecordTypeId: this.props.context.groupId,
          onlyRecordTypeName: R.prop("name")(
            [...this.props.groupTypes, ...this.props.contactTypes].find(
              t => t.id === this.props.context.groupId
            )
          )
        }
      : {};

    this.props.showModal({
      content: (
        <AddRecordModal
          moduleId={this.props.module.id}
          onSave={() => this.fetchRecords()}
          {...groupTypeParams}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showAddSubmissionModal = () => {
    this.props.showModal({
      content: (
        <AddSubmissionAsModal
          onDone={this.addSubmission}
          hideModal={this.props.hideModal}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  addSubmission = async accountId => {
    // @NOTE: Currently we're taking the first form - We should probably expand this
    // to take in a form ID
    const form = this.props.forms[0];

    const submissionModuleRecord = await this.props.addRecord({
      moduleId: this.props.module.id,
      record: {
        isDraft: true
      },
      options: {
        eventId: this.props.context.eventId
      }
    });

    const submissionResult = await SubmissionApi.post(
      this.props.userCredentials,
      {
        eventId: this.props.context.eventId,
        formId: form.id,
        userId: this.props.user.id,
        accountId,
        suppressSubmissionStartedEmail: true,
        submissionRecordId: submissionModuleRecord.id
      }
    );

    window.location = `/submissions/${this.props.eventDetails.slug}/${form.slug}/${submissionResult.submission.id}?redirectToApplication=1`;
  };

  goToManageFields = () => {
    this.props.replace({
      pathname: this.props.editFieldsUrl
    });
  };

  countRows(rows, typeId) {
    return rows.reduce((a, b) => {
      if (b.type_id === typeId) {
        return a + 1;
      }
      return a;
    }, 0);
  }

  exportData = contentType => {
    const { exportSheet, module, fields } = this.props;

    return exportSheet(
      contentType,
      module.record_name_plural,
      this.getRows(),
      this.getColumns(fields)
    );
  };

  pluralOrSingular(count) {
    const { module } = this.props;
    return count !== 1 ? module.record_name_plural : module.record_name;
  }

  saveCell = ({ columnId, value, rowId }) => {
    this.props.addValue({
      fieldId: columnId,
      value,
      moduleId: this.props.context.moduleId,
      recordId: rowId
    });
  };

  getNavigationHelpers = () => {
    const { context, location, returnTo, router, activeView } = this.props;

    return {
      fetchList: this.fetchModule,
      getColumns: this.getColumns,
      location,
      params: context,
      returnTo,
      router,
      selectView: this.selectView,
      activeView
    };
  };

  gotoRecord = () => {
    // @todo for modules, in use for groups
  };

  showRecordModal = recordId => {
    this.props.showModal({
      content: (
        <ViewRecordModal
          moduleId={this.props.context.moduleId}
          recordId={recordId}
          onClose={() => {
            // This overrides the hideModal fn that is passed in.
            // You can provide any logic here that should happen when
            // the modal closes (ex: refetch activities)
            this.props.hideModal();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showAddFieldModal = index => {
    const { showModal } = this.props;
    const { moduleId, orgId, eventId } = this.props.context;
    const modal = (
      <AddFieldModal
        orgId={orgId}
        eventId={eventId}
        moduleId={moduleId}
        onSave={field => this.handleAddField(field, index)}
      />
    );
    showModal({ content: modal });
  };

  handleAddField = async (field, index) => {
    const { fields, showSnackbar } = this.props;

    const columns = this.getColumns(fields);
    const columnOrder = update(columns, {
      $splice: [[index, 0, field]]
    }).reduce((list, col, i) => {
      list[col.id] = i;
      return list;
    }, {});

    const visibleColumns = [
      ...columns.filter(c => c.visible).map(c => c.id),
      field.id
    ];

    await this.updateViewMeta({ visibleColumns, columnOrder });
    showSnackbar({ message: "Field added", action: "OK" });
    return this.fetchRecords();
  };

  onColumnResize = (id, width) => {
    this.updateViewMeta({
      fieldWidths: {
        ...this.props.activeView.field_widths,
        [id]: width
      }
    });
  };

  toggleColumnVisibility = (id, visible) => {
    const { fields } = this.props;
    const columns = this.getColumns(fields);

    const visibleColumns = columns
      .filter(c => (c.id === id ? visible : c.visible))
      .map(c => c.id);

    const columnOrder = get(this.props.activeView, "field_order");
    return this.updateViewMeta({
      visibleColumns,
      columnOrder
    });
  };

  updateViewMeta = async ({
    visibleColumns,
    columnOrder,
    rowOrder,
    sortBy,
    fieldWidths
  }) => {
    const { id } = this.props.activeView;
    const { orgId, eventId, moduleId } = this.props.context;
    const data = {
      orgId,
      eventId,
      moduleId,
      viewId: id,
      view: {},
      options: {
        orgId,
        eventId
      }
    };

    if (visibleColumns) {
      data.view.visibleFields = visibleColumns;
    }
    if (columnOrder) {
      data.view.fieldOrder = columnOrder;
    }
    if (rowOrder) {
      data.view.rowOrder = rowOrder;
    }
    if (sortBy) {
      data.view.sortBy = sortBy;
    }
    if (fieldWidths) {
      data.view.fieldWidths = fieldWidths;
    }

    this.props.updateView(data);
  };

  showEditFieldModal = field => {
    const { context, showModal } = this.props;
    const { moduleId, orgId, eventId } = context;
    const modal = (
      <ModalColumnEdit
        orgId={orgId}
        eventId={eventId}
        moduleId={moduleId}
        fieldId={field.id}
        onSave={() => this.handleEditField()}
      />
    );
    showModal({ content: modal });
  };

  handleEditField = () => {
    this.props.showSnackbar({ message: "Field updated", action: "OK" });
    return this.fetchRecords();
  };

  renderToolbar(showToolbar) {
    if (showToolbar) {
      const ActionsBar = resolveToolbar({
        moduleId: this.props.context.moduleId,
        viewId: this.props.context.viewId,
        reportId: this.props.activeView.report_id,
        records: this.props.records
      });

      return (
        <ActionsBar
          getColumns={this.getColumns}
          fetchRecords={this.fetchRecords}
          moduleId={this.props.context.moduleId}
          reportId={this.props.activeView.report_id}
          params={this.props.context}
          records={this.props.records}
        />
      );
    }
    return (
      <Search
        key={`${this.props.context.moduleId}_${
          this.props.context.viewId
        }_${JSON.stringify(this.props.location.query)}`}
        moduleId={this.props.context.moduleId}
        view={this.props.activeView.view_type}
        searching={this.props.isSearching}
      />
    );
  }

  renderListView(rows) {
    const {
      context,
      module,
      fields,
      references,
      isFetchingRecords
    } = this.props;

    const {
      contextMenuActions,
      contextMenuOrder
    } = this.getContextMenuActions();

    return (
      <ListView
        addRecord={this.showAddRecordModal}
        addSubmission={this.showAddSubmissionModal}
        contextMenuActions={contextMenuActions}
        contextMenuOrder={contextMenuOrder}
        fetchRecords={this.fetchRecords}
        fields={this.getColumns(fields)}
        key={this.props.context.moduleId}
        loading={isFetchingRecords}
        moduleId={this.props.context.moduleId}
        onColumnResize={debounce(this.onColumnResize, 500, { leading: false })}
        params={context}
        plural={module.record_name_plural}
        records={rows}
        references={references}
        singular={module.record_name}
      />
    );
  }

  sortRows = async (comparer, field, direction) => {
    await this.updateViewMeta({
      sortBy: [
        ...this.props.activeView.sort_by.filter(f => f.fieldId !== field.id),
        {
          fieldId: field.id,
          direction: direction.toUpperCase()
        }
      ]
    });

    return true;
  };

  getContextMenuActions = () => {
    const { readOnly } = this.props;

    let adminContextOptions = [];
    let adminMenuActions = {};
    if (!readOnly) {
      adminMenuActions = {
        editHeader: this.showEditFieldModal,
        addColumn: this.showAddFieldModal,
        sortRows: this.sortRows
      };
      adminContextOptions = [
        "separator",
        "insertLeft",
        "insertRight",
        "editField",
        "separator",
        "sortDesc",
        "sortAsc"
      ];
    }

    const contextMenuActions = {
      toggleColumnVisibility: this.toggleColumnVisibility,
      ...adminMenuActions
    };

    const contextMenuOrder = {
      headerMenu: ["hideColumn", "groupBy", ...adminContextOptions],
      rowMenu: []
    };

    return { contextMenuActions, contextMenuOrder };
  };

  renderGridView(rows) {
    const { context, fields, heightSizer, references, readOnly } = this.props;

    const {
      contextMenuActions,
      contextMenuOrder
    } = this.getContextMenuActions();

    return (
      <GridView
        addRecord={this.showAddRecordModal}
        addSubmission={this.showAddSubmissionModal}
        contextMenuActions={contextMenuActions}
        contextMenuOrder={contextMenuOrder}
        draggable={false}
        exportToSheet={this.exportData}
        fields={this.getColumns(fields)}
        heightSizer={heightSizer}
        params={context}
        moduleId={this.props.context.moduleId}
        records={rows}
        references={references}
        onColumnResize={this.onColumnResize}
        saveValue={this.saveCell}
        readOnly={readOnly}
        fetchRecords={this.fetchRecords}
      />
    );
  }

  renderKanbanView = rows => {
    const { context, references, fields = [], module, readOnly } = this.props;

    return (
      <KanbanView
        fields={this.getColumns(fields)}
        fetchRecords={this.fetchRecords}
        moduleId={context.moduleId || module.id}
        onCardClick={this.showRecordModal}
        params={context}
        plural={module.record_name_plural}
        readOnly={readOnly}
        records={rows}
        references={references}
        saveValue={this.saveCell}
        singular={module.record_name}
        viewBy={fields.find(kanbanFieldFilter) || {}}
      />
    );
  };

  renderBody = (view = "list", rows) => {
    switch (view) {
      case "grid":
        return this.renderGridView(rows);
      case "kanban":
        return this.renderKanbanView(rows);
      case "list":
      default:
        return this.renderListView(rows);
    }
  };

  actionHelpers = ({ context, module }) => ({
    exportCSV: () => this.exportData("csv"),
    exportXLSX: () => this.exportData("xlsx"),
    manageFields: this.goToManageFields,
    module,
    params: context,
    isForm: Boolean(this.props.forms.length),
    showAddRecordModal: this.showAddRecordModal,
    showAddSubmissionModal: this.showAddSubmissionModal,
    showModal: this.props.showModal,
    onDone: () => this.fetchRecords()
  });

  renderView = (sidebar, rows) => {
    const {
      activeView,
      sidebarControlId,
      selectedRows,
      showSidebarAction,
      module,
      context,
      hideHeader
    } = this.props;
    if (isPassesRoute(this.props)) {
      return <ManagePasses />;
    }

    if (activeView.view_type === "calendar") {
      return this.renderBody("calendar", rows);
    }

    return (
      <Container>
        {!hideHeader ? (
          <Header>
            {sidebar || showSidebarAction ? (
              <ShowHideSwitch moduleId={sidebarControlId || module.id} />
            ) : (
              ""
            )}
            {this.renderToolbar(
              activeView.view_type !== "kanban" && selectedRows.length
            )}
            <Separator />
            {!selectedRows.length
              ? resolveActions({
                  meta: this.actionHelpers(this.props),
                  moduleId: context.moduleId
                })
              : null}
          </Header>
        ) : (
          ""
        )}
        <div>{this.renderBody(activeView.view_type, rows)}</div>
      </Container>
    );
  };

  render() {
    const {
      context,
      module,
      sidebarControlId,
      hideSidebar,
      forms,
      groupTypes,
      contactTypes
    } = this.props;

    const rows = this.getRows();

    const sidebar = !hideSidebar
      ? resolveNavigation({
          moduleId: context.moduleId,
          forms,
          rows,
          groupTypes: groupTypes || [],
          contactTypes: contactTypes || [],
          ...this.getNavigationHelpers()
        })
      : undefined;

    return (
      <Sidebar moduleId={sidebarControlId || module.id} sidebar={sidebar}>
        {this.renderView(sidebar, rows)}
      </Sidebar>
    );
  }
}

ModuleRecords.defaultProps = {
  hideHeader: false,
  hideSidebar: false,
  isSearching: false
};

export default ModuleRecords;
