import { bindActionCreators } from "redux";
import { pathOr } from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Controller from "./Controller";
import { MEAL_TYPE_ID } from "utils/item-types";

import { getEvent } from "redux/modules/event/actions";
import {
  getMeal,
  getMealStats,
  scanMeal
} from "redux/modules/catering/checkin/meal/actions";
import { getItemGroupsByEventAndType } from "redux/modules/items/item-groups/actions";
import { getMeals } from "redux/modules/catering/checkin/meals/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";

import {
  countOfCheckins,
  countOfOverrides,
  latestScan,
  scans,
  isScanning
} from "redux/modules/catering/checkin/meal/selectors";
import { meals } from "redux/modules/catering/checkin/meals/selectors";
import { groups } from "redux/modules/items/item-groups/selectors";
import { user } from "redux/modules/user/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import { modals } from "redux/modules/modal/selectors";

const LIMIT = 20;

function mapStateToProps(state, { params }) {
  const itemGroupsToPass = groups(state).filter(
    g => g.type_id === MEAL_TYPE_ID
  );
  const itemsToPass = itemGroupsToPass.reduce((a, b) => [...a, ...b.items], []);
  const variantsToPass = itemsToPass.reduce(
    (a, b) => [
      ...a,
      ...b.variants.map(v => ({
        ...v,
        item: b
      }))
    ],
    []
  );
  const eventDetailsToPass = eventDetails(state);

  return {
    eventDetails: eventDetailsToPass,
    hasManagerOverride: pathOr(false, [
      "module_settings",
      "catering",
      "manager_override_is_active"
    ])(eventDetailsToPass),
    selectedDays: pathOr(
      [],
      ["module_settings", "catering", "selected_days"],
      eventDetailsToPass
    ),
    mealDays: pathOr(
      [],
      ["module_settings", "catering", "meal_days"],
      eventDetailsToPass
    ),
    backgroundUrl: eventDetailsToPass.background_image_url,
    checkinsCount: countOfCheckins(state, params.mealId),
    latestScan: latestScan(state, params.mealId),
    meals: meals(state),
    overridesCount: countOfOverrides(state, params.mealId),
    scans: scans(state, params.mealId).slice(0, LIMIT),
    limit: LIMIT,
    user: user(state),
    itemGroups: itemGroupsToPass,
    items: itemsToPass,
    modalIsActive: modals(state).length,
    variants: variantsToPass,
    scanning: isScanning(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEvent,
      getMeal,
      getMealStats,
      scanMeal,
      getMeals,
      getItemGroupsByEventAndType,
      showModal,
      hideModal
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
