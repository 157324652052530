import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE } from "./constants";

const iniState = {
  reports: [],
  runningReportIds: [],
  loading: false
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    init: R.identity,
    refetchReports: R.identity,
    goToModuleReports: R.identity,
    runReport: R.identity,
    editReport: R.identity,
    copyReport: R.identity,
    createReport: R.identity,
    deleteReport: R.identity,
    toggleRunningReport: (state, { payload: reportId }) => ({
      runningReportIds: state.runningReportIds.includes(reportId)
        ? state.runningReportIds.filter(id => id !== reportId)
        : [...state.runningReportIds, reportId]
    }),
    setInitialData: (state, { payload: { reports } }) => ({
      reports
    })
  }
});

export default handlers;
