import React, { Component } from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import {
  Div,
  SuperFilledButton,
  SuperOutlineButton,
  Dropdown
} from "components/Base";
import Body from "components/Global/Modal/Layout/ScrollableBody";
import { prop } from "ramda";

const formatActiveFilters = (activeFilters, filters) => {
  return filters.reduce((map, filter) => {
    if (activeFilters[filter.id]) {
      map[filter.id] = activeFilters[filter.id].map(id => {
        const option = filter.options.find(o => o.id === id);
        return {
          label: option.name,
          value: id
        };
      });
    }

    return map;
  }, {});
};

class ReportSettingsModal extends Component {
  state = {
    filters: formatActiveFilters(
      this.props.activeFilters,
      this.props.availableFilters
    )
  };

  onChange = (filterId, opts) => {
    this.setState(state => {
      state.filters[filterId] = opts;
      return state;
    });
  };

  handleSave = () => {
    const formatted = Object.keys(this.state.filters).reduce((map, id) => {
      const filter = this.props.availableFilters.find(f => f.id === id);

      let values = null;

      if (filter.type === "dropdown") {
        values = prop("value")(this.state.filters[id]);
        if (values) values = [values];
      } else if (filter.type === "tags") {
        values = this.state.filters[id].map(o => o.value);
      }

      if (values && values.length) {
        map[id] = values;
      }
      return map;
    }, {});

    this.props.onSave(formatted);
    this.props.hideModal();
  };

  resolveFilter = filter => {
    switch (filter.type) {
      case "dropdown":
        return (
          <Dropdown
            usePortal
            menuPortalTarget={document.body}
            name={filter.name}
            value={this.state.filters[filter.id]}
            options={filter.options.map(({ id, name }) => ({
              value: id,
              label: name
            }))}
            className="basic-multi-select"
            classNamePrefix="select"
            closeMenuOnSelect
            onChange={opts => this.onChange(filter.id, opts)}
            styles={{
              menuPortal: base => ({
                ...base,
                zIndex: 9999
              })
            }}
          />
        );
      case "tags":
        return (
          <Dropdown
            usePortal
            menuPortalTarget={document.body}
            name={filter.name}
            value={this.state.filters[filter.id]}
            isMulti
            options={filter.options.map(({ id, name }) => ({
              value: id,
              label: name
            }))}
            className="basic-multi-select"
            classNamePrefix="select"
            closeMenuOnSelect={false}
            onChange={opts => this.onChange(filter.id, opts)}
            styles={{
              menuPortal: base => ({
                ...base,
                zIndex: 9999
              })
            }}
          />
        );
    }
  };

  render() {
    const { availableFilters } = this.props;
    return (
      <StyleWrapper
        hideModal={this.props.hideModal}
        heading="Configure Report"
        bodyStyles={{ padding: 0 }}
        width={700}
      >
        <Body style={{ maxHeight: 400 }}>
          <Div px={6} py={5}>
            {availableFilters.map(filter => (
              <Div key={filter.id} display="column" mb={3}>
                <Div fw={3} mb={1}>
                  {filter.name}
                </Div>
                <Div>{this.resolveFilter(filter)}</Div>
              </Div>
            ))}
          </Div>
        </Body>

        <Div my={3} mx={6}>
          <SuperFilledButton
            bg="orange5"
            style={{
              cursor: "pointer"
            }}
            mr={3}
            onClick={this.handleSave}
          >
            <Div fw={3}>Save</Div>
          </SuperFilledButton>
          <SuperOutlineButton onClick={this.props.hideModal}>
            Cancel
          </SuperOutlineButton>
        </Div>
      </StyleWrapper>
    );
  }
}
export default ReportSettingsModal;
