import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "Forms/WizardModal";
import SelectMethod from "Forms/WizardModal/View/Inventory/SelectMethod";
import SelectFields from "Forms/WizardModal/View/Inventory/SelectFields";
import InventoryCategories from "Forms/WizardModal/View/Inventory/InventoryCategories";
import ReviewForm from "Forms/WizardModal/View/ReviewForm";
import { INVENTORY_PAGES } from "Forms/WizardModal/constants";
import { shouldEnableSaveForm } from "Forms/WizardModal/selectors";

const decorate = connect(
  state => ({
    selectedInventory: getters.selectedInventory(state),
    saveBtnEnabled: shouldEnableSaveForm(state)
  }),
  {
    reviewGoBack: () =>
      actions.setSelectedInventory(INVENTORY_PAGES.SELECT_FIELDS),
    saveForm: actions.saveInventoryForm
  }
);

const Inventory = ({
  selectedInventory,
  handlers,
  reviewGoBack,
  saveForm,
  saveBtnEnabled
}) => {
  if (selectedInventory === INVENTORY_PAGES.INVENTORY_CATEGORIES) {
    return <InventoryCategories handlers={handlers} />;
  } else if (selectedInventory === INVENTORY_PAGES.SELECT_FIELDS) {
    return <SelectFields handlers={handlers} />;
  } else if (selectedInventory === INVENTORY_PAGES.REVIEW) {
    return (
      <ReviewForm
        handlers={handlers}
        goBack={reviewGoBack}
        saveForm={saveForm}
        saveBtnEnabled={saveBtnEnabled}
      />
    );
  }
  return <SelectMethod handlers={handlers} />;
};

export default decorate(Inventory);
