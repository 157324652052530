import Helpers from "utils/Global/Helpers";

module.exports = {
  get: (credentials, type, eventId) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/dashboard?type=${type}&eventId=${eventId}`,
        credentials,
        data: {},
        success: result => {
          resolve(result);
        },
        error: err => {
          reject(err);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  getItemGroupsByEventAndType: (credentials, eventId, typeId) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/items/groups/event/${eventId}/type/${typeId}`,
        credentials,
        success,
        error
      });
    }),

  postWidget: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/dashboard/widgets`,
        credentials,
        data,
        success: result => {
          resolve(result.form);
        },
        error: err => {
          reject(err);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  post: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/dashboard`,
        credentials,
        data,
        success: result => {
          resolve(result.form);
        },
        error: err => {
          reject(err);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  put: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/dashboard/${data.dashboardId}`,
        credentials,
        data,
        success: result => {
          resolve(result.form);
        },
        error: err => {
          reject(err);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  delete: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/dashboard/${data.dashboardId}`,
        credentials,
        data,
        success: result => {
          resolve(result.form);
        },
        error: err => {
          reject(err);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
