import { createSelector } from "reselect";
import { getters } from "Schedules/SelectScheduleFieldsModal";
import * as R from "ramda";
import { TABS } from "./constants";

export const getCategoryList = createSelector(
  getters.data,
  getters.fieldsFilter,
  getters.selectedTab,
  (data, fieldsFilter, selectedTab) => {
    const dataTab = R.filter(
      ({ is_report_category }) =>
        selectedTab === TABS.REPORTED
          ? is_report_category === true
          : is_report_category === false,
      data
    );
    if (R.isEmpty(fieldsFilter)) {
      return dataTab;
    }

    return R.reduce(
      (acc, cat) => {
        const fields = R.filter(
          field => R.contains(R.toLower(fieldsFilter), R.toLower(field.name)),
          cat.fields
        );

        if (R.length(fields) < 1) {
          return acc;
        }

        return [
          ...acc,
          {
            ...cat,
            fields
          }
        ];
      },
      [],
      dataTab
    );
  }
);
