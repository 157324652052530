import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE, TABS, SORTING } from "./constants";

const iniState = {
  loading: true,
  data: {},
  filter: "",
  activeTab: TABS.ALL_FORMS,
  activeSorting: SORTING.PENDING
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    showFormWizardModal: R.identity,
    editForm: R.identity,
    viewPortalAs: R.identity,
    submitForm: R.identity,
    goToPortal: R.identity,
    copyPortalLink: R.identity,
    goToPortalSettings: R.identity
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
