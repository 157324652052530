import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { SuperFilledButton } from "components/Base";

const EmptyState = ({
  icon,
  title,
  description,
  action,
  onClick,
  style = {}
}) => {
  return (
    <div className={css.container} style={style}>
      {icon ? (
        <div className={css.icon}>
          <span className="material-icons-outlined">{icon}</span>
        </div>
      ) : null}
      <div className={css.title}>{title}</div>
      <div className={css.description}>{description}</div>
      {onClick ? (
        <div className={css.action}>
          <SuperFilledButton bg="altB5" color="white" onClick={onClick}>
            {action}
          </SuperFilledButton>
        </div>
      ) : null}
    </div>
  );
};

export default CSSModules(EmptyState, css);
