import React, { Component } from "react";
import * as R from "ramda";
import getValue from "utils/value-types/get-value/source";
import User from "./User";
import Submission from "./Submission";
import EventbriteOrder from "./EventbriteOrder";
import Unknown from "./Unknown";

import { getRow, getMeta, getHandlers } from "ui-kit/Datagrid/utils";

export default class SourceCellFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props.value, nextProps.value);
  }

  formatValue(props) {
    return getValue(props.value);
  }

  getFormatter(type) {
    switch (type) {
      case "user":
        return User;
      case "submission":
        return Submission;
      case "eventbrite-order":
        return EventbriteOrder;
      default:
        return Unknown;
    }
  }

  render() {
    const props = this.props;
    const value = this.formatValue(this.props);
    const Formatter = this.getFormatter(value.type);
    const references = getRow(props, "references");
    const eventId = getMeta(props, ["eventDetails", "id"]);
    const moduleId = getMeta(props, "moduleId");
    const handlers = getHandlers(props);

    return (
      <Formatter
        value={value}
        references={references}
        eventId={eventId}
        moduleId={moduleId}
        helpers={handlers}
      />
    );
  }
}
