/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  getPortalData: ({ credentials, eventId, accountId, scope }) =>
    new Promise((success, error) => {
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/portal/tasks/event/${eventId}${
          accountId ? `/accounts/${accountId}` : ""
        }?scope=${scope}`,
        credentials,
        success,
        error
      };
      Helpers.request(options);
    })
};
