import React, { Component } from "react";
import { connect } from "react-redux";
import EditName from "./EditName";
import { Div, DownFilledIcon } from "components/Base";
import * as R from "ramda";

import { getters, actions } from "ui-kit/ViewPicker";
import { bindInstance } from "redux-mvc";

const decorate = connect(
  (state, props) => ({
    activeViewId: getters.activeViewId(state, props),
    activeViewName: getters.activeViewName(state, props),
    editing: getters.showEditViewName(state, props)
  }),
  bindInstance({
    updateViewName: actions.updateViewName,
    deleteView: actions.deleteView,
    setShowEditViewName: actions.setShowEditViewName
  })
);

class ViewSelector extends Component {
  render() {
    const activeViewName =
      this.props.activeViewId === "default"
        ? "Select a view..."
        : R.propOr("Select a view...", "activeViewName")(this.props);

    return (
      <Div
        width={1}
        onClick={this.props.editing ? undefined : this.props.onClick}
      >
        {this.props.editing ? (
          <EditName
            name={activeViewName}
            save={name => {
              this.props.updateViewName({ name });
              this.props.setShowEditViewName(false);
            }}
            cancel={() => this.props.setShowEditViewName(false)}
          />
        ) : (
          <Div display="column">
            <Div
              color={this.props.white ? "primary7" : "white"}
              fs={4}
              fw={3}
              truncate
              style={
                this.props.small
                  ? {
                      fontSize: 13
                    }
                  : undefined
              }
            >
              {activeViewName}
            </Div>
            <Div
              display="row.flex-start.center"
              fs={0}
              fw={3}
              color={this.props.white ? "primary7" : "white"}
              uppercase
              style={{
                color: this.props.white ? "#cccccc" : "#D8C5EE"
              }}
            >
              Views
              <DownFilledIcon
                style={{
                  color: this.props.white ? "#cccccc" : "#D8C5EE"
                }}
              />
            </Div>
          </Div>
        )}
      </Div>
    );
  }
}

export default decorate(ViewSelector);
