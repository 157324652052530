import * as R from "ramda";
import { createSelector } from "reselect";

import { users } from "redux/modules/eventUsers/selectors";
import { forms } from "redux/modules/formsV2/forms/selectors";
import { groups } from "redux/modules/items/item-groups/selectors";

import {
  ALL_GROUPS,
  EVERYONE,
  MANAGE_BY,
  PASS_MODAL_ID,
  USER_MODAL_ID,
  SOURCE_MODAL_ID
} from "Passes/Common/constants";

import { getters } from "Passes/Confirmations";

import * as FiltersSelectors from "ui-kit/FiltersPanel/selectors";
import { getters as MiniItemsGetters } from "ui-kit/MiniItemsSelector";

import { joinNotNullBy } from "utils/General";

const prependNotEmpty = val => arr =>
  arr.length > 0 && !R.isEmpty(val) ? R.prepend(val, arr) : arr;

export const getAttendeeOptions = createSelector(
  (_, { attendeeTypes }) => attendeeTypes,
  prependNotEmpty({ id: EVERYONE, name: "Everyone", all: true })
);

export const getGroupOptions = createSelector(
  (_, { groupTypes }) => groupTypes,
  prependNotEmpty({ id: ALL_GROUPS, name: "All Groups", all: true })
);

export const getFilterOptions = createSelector(
  getAttendeeOptions,
  getGroupOptions,
  (attendeeOptions, groupOptions) => {
    return [
      {
        name: "Attendee Type",
        id: MANAGE_BY.ATTENDEE_LIST,
        options: attendeeOptions,
        allId: EVERYONE,
        iniSelected: [EVERYONE]
      },
      {
        name: "Group Type",
        id: MANAGE_BY.GROUP_AFFILIATION,
        options: groupOptions,
        allId: ALL_GROUPS,
        iniSelected: [ALL_GROUPS]
      }
    ];
  }
);

export const getSelectedCount = createSelector(getters.selectedRows, R.length);

export const getIsAllSelected = createSelector(
  getters.rows,
  getters.selectedRows,
  (rows, selectedRows) => R.length(rows) === R.length(selectedRows)
);

export const getTableRows = createSelector(
  getters.selectedRows,
  getters.rows,
  (_, props) => R.propOr({}, "handlers", props),
  (selectedRows, rows, handlers) =>
    R.map(r => {
      const hasContactName = [
        r.order_contact_first_name,
        r.order_contact_last_name
      ].filter(v => v && v.trim().length).length;
      return {
        id: r.order_id,
        attendee: {
          name: hasContactName
            ? joinNotNullBy(" ")([
                r.order_contact_first_name,
                r.order_contact_last_name
              ])
            : r.order_account_name,
          contactType: r.order_contact_type,
          groupName: hasContactName ? r.order_account_name : null
        },
        emailTo: r.order_contact_email,
        passTypes: R.map(R.propOr("", "name"), r.pass_types),
        order: [{ id: r.order_id, number: r.order_number }],
        status: r.confirmation_status,
        paymentStatus: r.payment_status,
        selected: R.contains(r.order_id, selectedRows),
        handlers
      };
    }, rows)
);

export const getFiltersOn = createSelector(
  state => MiniItemsGetters.selectedItems(state, { instanceId: USER_MODAL_ID }),
  state =>
    MiniItemsGetters.selectedItems(state, { instanceId: SOURCE_MODAL_ID }),
  state => MiniItemsGetters.selectedItems(state, { instanceId: PASS_MODAL_ID }),
  FiltersSelectors.getSelectedOptions,
  R.unapply(
    R.compose(
      R.not,
      R.isEmpty,
      R.without([EVERYONE, ALL_GROUPS]),
      R.reduce(R.concat, [])
    )
  )
);
const getFilterCount = (getter, iniVal = 0) =>
  createSelector(
    getter,
    R.compose(
      R.add(iniVal),
      R.length
    )
  );

export const getPassFiltersCount = createSelector(
  groups,
  R.reduce((count, group) => count + group.items.length, 0)
);
export const getSourceFiltersCount = getFilterCount(forms, 2);
export const getUserFiltersCount = getFilterCount(users);

export const getCurrentPage = R.compose(
  R.prop("page"),
  getters.pagination
);

export const getPageSize = R.compose(
  R.prop("pageSize"),
  getters.pagination
);
