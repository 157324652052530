import React from "react";
import * as R from "ramda";
import { get, uniq } from "lodash";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Base from "../Base";
import resolveTitle from "components/Event/Module/utils/resolveTitle";
import resolveRecordName from "components/Event/Module/utils/resolveRecordName";
import kanbanFieldFilter from "components/Event/Module/utils/kanban-field-filter";
import flattenFields from "components/Global/CRM/TableViews/utils/flatten-fields";
import CanUserDo from "components/Global/Security/CanUserDo";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import Container from "components/Global/Module/Sidebar/Container";
import ActionText from "components/Global/Module/Sidebar/ActionText";
import Well from "components/Global/Module/Sidebar/Well";
import SelectedWellItem from "components/Global/Module/Sidebar/Well/SelectedItem";
import SortItem from "components/Global/Module/Sidebar/Well/SortItem";
import GroupByPicker from "components/Global/Module/Sidebar/Popovers/GroupBy";
import FilterPicker from "components/Global/Module/Sidebar/Popovers/Filter";
import SortPicker from "components/Global/Module/Sidebar/Popovers/Sort";
import CustomCards from "components/Global/CRM/TableViews/KanbanView/CustomCards";
import QuickFilters from "components/Event/Module/Navigation/Common/QuickFilters";
import Views from "components/Event/Module/Navigation/Common/Views";
import SidebarTitle from "components/Event/Module/Navigation/Common/SidebarTitle";

import { createSelector } from "reselect";

import { Div } from "components/Base";
import Circle from "ui-kit/Circle";

import { getGroupUrl } from "Items/Manage/utils";

import { isPassesRoute } from "components/Event/Module/utils";
import Sidebar from "ui-kit/Sidebar/View";

const getManageUrl = createSelector(
  R.path(["params", "eventId"]),
  R.path(["params", "moduleId"]),
  getGroupUrl,
  (eventId, moduleId, groupUrl) => (view, base = "/passes", search = "") =>
    `/event/${eventId}/module/${moduleId}${groupUrl}${base}${view}${search}`
);

const getReturnUrl = createSelector(
  R.path(["location", "pathname"]),
  R.path(["location", "search"]),
  (path, search) => `returnTo=${path}${search}`
);

const getManageLinks = createSelector(
  getManageUrl,
  R.path(["params", "eventId"]),
  R.path(["params", "moduleId"]),
  R.path(["params", "groupId"]),
  R.prop("catalogItemsModules"),
  R.prop("canViewPasses"),
  R.prop("canViewCatering"),
  R.prop("canViewInventory"),
  R.prop("canManageAccounts"),
  R.prop("canManageContacts"),
  R.prop("enabledModuleIds"),
  R.prop("otherModules"),
  getReturnUrl,
  (
    getUrl,
    eventId,
    moduleId,
    groupId,
    catalogItemsModules,
    canViewPasses,
    canViewCatering,
    canViewInventory,
    canManageAccounts,
    canManageContacts,
    enabledModuleIds,
    otherModules,
    returnTo
  ) =>
    [
      {
        id: "manage",
        name: "Manage",
        activeRoutes: ["ModulePasses", "ModuleCatering"],
        enabled: canViewPasses || canViewCatering || canViewInventory,
        links: [
          {
            id: "ModulePasses",
            name: "Passes",
            enabled: canViewPasses,
            activeRoutes: ["ModulePasses"],
            links: [
              {
                id: "allRequests",
                to: getUrl("/manage/all-requests"),
                name: "Review",
                activeRoutes: ["ModulePasses"],
                activeParams: { viewId: "all-requests" }
              },
              {
                id: "issue",
                to: getUrl("/manage/fulfill"),
                name: "Issue",
                activeRoutes: ["ModulePasses"],
                activeParams: { viewId: "fulfill" }
              }
            ]
          },
          /* @NOTE add catering views*/
          {
            id: "assets",
            name: "Inventory",
            enabled: canViewInventory,
            links: R.map(module => ({
              id: module.id,
              name: module.name,
              to: `/event/${eventId}/module/${module.id}/${
                moduleId === STANDARD_MODULE_IDS.accounts.id
                  ? "group-filter"
                  : "contact-filter"
              }/${groupId}`
            }))(catalogItemsModules || [])
          },
          ...R.map(module => ({
            id: module.id,
            name: module.name,
            enabled: true,
            to: `/event/${eventId}/module/${module.id}/${
              moduleId === STANDARD_MODULE_IDS.accounts.id
                ? "group-filter"
                : "contact-filter"
            }/${groupId}`
          }))(otherModules || [])
          /*
        {
          id: "lodging",
          name: "Lodging"
        },
        {
          id: "production",
          name: "Production Schedule"
        }
        */
        ].filter(link => link.enabled)
      },
      {
        id: "settings",
        name: "Settings",
        enabled:
          moduleId === STANDARD_MODULE_IDS.accounts.id
            ? canManageAccounts
            : canManageContacts,
        links: [
          {
            id: "fields",
            name: "Custom Fields",
            enabled: true,
            to: `/event/${eventId}/settings/module/${moduleId}/types/${groupId}/record-layout?${returnTo}`
          },
          {
            id: "fields",
            name: "Portal Settings",
            enabled: moduleId === STANDARD_MODULE_IDS.accounts.id,
            to: `/event/${eventId}/settings/module/${moduleId}/types/${groupId}?${returnTo}`
          }
        ].filter(link => link.enabled)
      }
    ].filter(link => link.enabled)
);

import {
  VIEW_MY_RECORDS_ID,
  VIEW_PENDING_RECORDS_ID,
  VIEW_APPROVED_RECORDS_ID,
  VIEW_DENIED_RECORDS_ID
} from "components/Event/Module/utils/views";

const PrimaryLink = CSSModules(
  ({ name, active, onClick }) => (
    <div
      styleName={active ? "primaryLinkActive" : "primaryLink"}
      onClick={onClick}
      role="link"
      tabIndex="0"
    >
      {name}
    </div>
  ),
  css
);

const Pickers = ({
  type,
  active,
  activeView,
  fields,
  updateViewMeta,
  addSort
}) => {
  switch (type) {
    case "filter":
      return (
        <FilterPicker
          filters={get(activeView, "filters")}
          onChange={updateViewMeta}
          fields={fields.filter(f =>
            [
              "text",
              "number",
              "email",
              "textarea",
              "checkbox",
              "dropdown",
              "source"
            ].includes(f.type)
          )}
        >
          {active ? (
            <ActionText
              style={{
                padding: 0,
                margin: "10px 5px 0px",
                fontSize: 11
              }}
            >
              <i
                className="material-icons"
                style={{ marginRight: 1, fontSize: 11 }}
              >
                add
              </i>
              Add filter
            </ActionText>
          ) : (
            <ActionText>
              <i className="material-icons">add_circle</i>
              Add filter
            </ActionText>
          )}
        </FilterPicker>
      );
    case "sortBy":
      return (
        <SortPicker
          sortBy={get(activeView, "sort_by")}
          onChange={addSort}
          fields={fields}
        >
          {active ? (
            <ActionText
              style={{
                padding: 0,
                margin: "10px 5px 0px",
                fontSize: 11
              }}
            >
              <i
                className="material-icons"
                style={{ marginRight: 1, fontSize: 11 }}
              >
                add
              </i>
              Add sort by
            </ActionText>
          ) : (
            <ActionText>
              <i className="material-icons">sort</i>
              Sort by
            </ActionText>
          )}
        </SortPicker>
      );
    default:
      return null;
  }
};

const SelectedPicker = ({
  type,
  active,
  picker,
  fields,
  activeView,
  findField,
  removeFilter,
  removeSort,
  updateViewMeta,
  kanbanFieldFilter,
  handleKanbanFieldsChange
}) => {
  switch (type) {
    case "filter":
      return active ? (
        <Well>
          <ActionText
            style={{
              padding: 0,
              margin: 5,
              marginBottom: 10
            }}
          >
            <i className="material-icons">filter_list</i> Filters
          </ActionText>
          {activeView.filters.filters.map((filter, idx) => (
            <SelectedWellItem
              title={`${get(findField(filter.fieldId), "name")} ${
                filter.label
              }`}
              icon={
                <i
                  role="button"
                  tabIndex="0"
                  className="material-icons"
                  style={{ cursor: "pointer" }}
                  onClick={() => removeFilter(idx)}
                >
                  close
                </i>
              }
            />
          ))}
          {picker}
        </Well>
      ) : (
        picker
      );
    case "sortBy":
      return active ? (
        <Well>
          <ActionText
            style={{
              padding: 0,
              margin: 5,
              marginBottom: 10
            }}
          >
            <i className="material-icons">filter_list</i> Sort by
          </ActionText>
          {activeView.sort_by.map((sortCase, idx) => (
            <SortItem
              name={get(findField(sortCase.fieldId), "name")}
              direction={sortCase.direction}
              removeIcon={
                <i
                  role="button"
                  tabIndex="0"
                  className="material-icons"
                  style={{ cursor: "pointer" }}
                  onClick={() => removeSort(idx)}
                >
                  close
                </i>
              }
            />
          ))}
          {picker}
        </Well>
      ) : (
        picker
      );
    case "groupBy": {
      if (activeView.view_type === "list") {
        return null;
      } else if (activeView.view_type === "kanban") {
        return (
          <div>
            <div styleName="actionWrapper">
              <Well>
                <ActionText
                  style={{
                    padding: 0,
                    margin: 5,
                    marginBottom: 10
                  }}
                >
                  <i className="material-icons">group_work</i> Grouping by
                </ActionText>
                <SelectedWellItem
                  title={get(
                    findField(activeView.grouped_by),
                    "name",
                    "Choose a field..."
                  )}
                  icon={
                    <GroupByPicker
                      onChange={updateViewMeta}
                      fields={fields.filter(kanbanFieldFilter)}
                      selectedFieldId={get(activeView, "grouped_by")}
                    >
                      <i
                        className="material-icons"
                        style={{ cursor: "pointer" }}
                      >
                        edit
                      </i>
                    </GroupByPicker>
                  }
                />
              </Well>
            </div>
            <CustomCards
              fields={fields}
              updateFields={handleKanbanFieldsChange}
              selectedFields={activeView.card_fields}
              dropdownFields={fields.filter(kanbanFieldFilter)}
            >
              <ActionText>
                <i className="material-icons">view_stream</i>
                Customize Cards
              </ActionText>
            </CustomCards>
          </div>
        );
      } else if (active) {
        return (
          <div styleName="actionWrapper">
            <Well>
              <ActionText
                style={{
                  padding: 0,
                  margin: 5,
                  marginBottom: 10
                }}
              >
                <i className="material-icons">group_work</i> Grouping by
              </ActionText>
              <SelectedWellItem
                title={get(findField(activeView.grouped_by), "name")}
                icon={
                  <i
                    className="material-icons"
                    style={{ cursor: "pointer" }}
                    onClick={() => updateViewMeta({ groupedBy: null })}
                    role="button"
                    tabIndex="0"
                  >
                    close
                  </i>
                }
              />
            </Well>
          </div>
        );
      }

      return (
        <GroupByPicker onChange={updateViewMeta} fields={fields}>
          <ActionText>
            <i className="material-icons">group_work</i>
            Group by
          </ActionText>
        </GroupByPicker>
      );
    }
    default:
      return null;
  }
};

const PanelWrapper = ({ active, children }) => (
  <Div my={active ? 2 : 0}>{children}</Div>
);

const Panel = props => (
  <PanelWrapper active={props.active}>
    <SelectedPicker {...{ ...props, picker: <Pickers {...props} /> }} />
  </PanelWrapper>
);

@CSSModules(css)
class ModuleNav extends Base {
  componentDidMount() {
    const { params, deselectAllRows, groupTypes, contactTypes } = this.props;

    if (
      params.filterByAccountRecordTypeId ||
      (this.props.moduleId === STANDARD_MODULE_IDS.accounts.id &&
        params.groupId &&
        !groupTypes.length)
    ) {
      // handle: viewing record set filtered by account type OR
      // viewing accounts filtered by account type
      this.props.getRecordTypes({
        moduleId: STANDARD_MODULE_IDS.accounts.id,
        options: {
          eventId: params.eventId
        }
      });
    } else if (
      params.filterByContactRecordTypeId ||
      (this.props.moduleId === STANDARD_MODULE_IDS.contacts.id &&
        params.groupId &&
        !contactTypes.length)
    ) {
      // handle: viewing record set filtered by contact type OR
      // viewing contacts filtered by contact type
      this.props.getRecordTypes({
        moduleId: STANDARD_MODULE_IDS.contacts.id,
        options: {
          eventId: params.eventId
        }
      });
    }

    if (params.groupId) {
      this.props.getModulesWithCatalogItems(this.props.params.eventId);
    }
  }

  duplicateView = async () => {
    const isReport = this.props.activeView.view_type === "report";
    const view = await this.props.createView({
      moduleId: this.props.moduleId,
      name: `${this.props.activeView.name} copy`,
      viewType: this.props.activeView.view_type,
      reportId: this.props.activeView.report_id,
      visibleFields: isReport ? null : this.props.activeView.visible_fields,
      fieldOrder: isReport ? null : this.props.activeView.field_order,
      fieldWidths: isReport ? null : this.props.activeView.field_widths,
      isPublic: this.props.activeView.is_public,
      groupedBy: this.props.activeView.grouped_by,
      cardFields: this.props.activeView.card_fields,
      filters: this.props.activeView.filters,
      quickFilters: this.props.activeView.quick_filters,
      setAsActive: true,
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId
      }
    });

    await this.props.getViews({
      moduleId: this.props.moduleId,
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId,
        accountRecordTypeId: this.props.params.groupId
      }
    });

    this.selectView(view.id);
  };

  canMakePrivateOrDelete = viewId => {
    const otherViews = this.props.views.filter(v => v.id !== viewId);
    return otherViews.length && otherViews.filter(v => v.is_public).length;
  };

  deleteView = async () => {
    if (!this.canMakePrivateOrDelete(this.props.activeView.id)) {
      return this.props.showSnackbar({
        message: "Oops! You must have at least one public view",
        action: "OK"
      });
    }

    await this.props.deleteView({
      moduleId: this.props.moduleId,
      viewId: this.props.activeView.id,
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId
      }
    });

    await this.props.getViews({
      moduleId: this.props.moduleId,
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId,
        accountRecordTypeId: this.props.params.groupId
      }
    });

    return this.selectView(
      this.props.views.filter(v => v.id !== this.props.activeView.id)[0].id
    );
  };

  selectQuickFilter = ({ id }) => {
    const { deselectAllRows } = this.props;
    const quickFilters = this.props.activeView.quick_filters || [];

    if (id) {
      if (quickFilters.includes(id)) {
        deselectAllRows();
        return this.updateViewMeta({
          quickFilters: quickFilters.filter(f => f !== id)
        });
      }

      return this.updateViewMeta({
        quickFilters: uniq([...quickFilters, id])
      });
    }

    return this.updateViewMeta({
      quickFilters: quickFilters.filter(f => f !== VIEW_MY_RECORDS_ID)
    });
  };

  toggleIsPublic = isPublic => {
    if (!this.canMakePrivateOrDelete(this.props.activeView.id)) {
      return this.props.showSnackbar({
        message: "Oops! You must have at least one public view",
        action: "OK"
      });
    }

    this.updateViewMeta({ isPublic });
  };

  selectMyRecords = () => {
    this.selectQuickFilter({
      id: VIEW_MY_RECORDS_ID
    });
  };

  updateViewName = name => {
    this.updateViewMeta({ name });
  };

  parentSource = () => {
    if (
      this.props.routes.find(
        r => r.name && r.name.toLowerCase().includes("inventory")
      )
    ) {
      return true;
    }
    return false;
  };

  getPanels = () => {
    if (isPassesRoute(this.props)) {
      return <div styleName="separator" />;
    }

    const { activeView, fields } = this.props;
    const flattenedFields = flattenFields(fields);
    const findField = key => flattenedFields.find(f => f.id === key);

    const panels = [
      {
        type: "filter",
        active: get(activeView, "filters.filters.length"),
        enabled: true
      },
      {
        type: "sortBy",
        active: get(activeView, "sort_by.length"),
        enabled: true
      },
      {
        type: "groupBy",
        active: Boolean(activeView.grouped_by),
        enabled: !["report", "list"].includes(activeView.view_type)
      }
    ].filter(p => p.enabled);

    return (
      <React.Fragment>
        <div styleName="separator" />
        {panels.map(({ type, active }) => (
          <Panel
            {...{
              key: type,
              type,
              active,
              activeView,
              fields: flattenedFields,
              findField,
              kanbanFieldFilter,
              addSort: this.addSort,
              updateViewMeta: this.updateViewMeta,
              removeFilter: this.removeFilter,
              removeSort: this.removeSort,
              handleKanbanFieldsChange: this.handleKanbanFieldsChange
            }}
          />
        ))}
        {!["kanban", "calendar"].includes(activeView.view_type) ? (
          <ActionText onClick={this.showEditColumnsModal}>
            <i className="material-icons">view_column</i>
            Show/Hide Fields
          </ActionText>
        ) : null}
        <div styleName="separator" />
      </React.Fragment>
    );
  };

  render() {
    const {
      forms,
      recordTypes,
      records,
      moduleDetails,
      location,
      params,
      activeView,
      user,
      groupTypes,
      contactTypes
    } = this.props;
    const quickFilters = get(activeView, "quick_filters", []);

    const recordTypesWithHandlers = recordTypes
      .map(recordType => ({
        ...recordType,
        isSelected: quickFilters.includes(recordType.id),
        onClick: () => this.selectQuickFilter({ id: recordType.id }),
        countOfRecords: this.countRows(recordType.id, records)
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    const formsWithHandlers = forms
      .map(form => ({
        ...form,
        isSelected: quickFilters.includes(form.id),
        onClick: () => this.selectQuickFilter({ id: form.id }),
        countOfRecords: 0,
        canEdit: Boolean(form.form_users.find(u => u.user_id === user.id)),
        formEditPath: `/event/${params.eventId}/module/${params.moduleId}/form/${form.id}`
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    const group = R.find(
      group =>
        group.id === this.props.params.groupId ||
        group.id === this.props.params.filterByAccountRecordTypeId ||
        group.id === this.props.params.filterByContactRecordTypeId,
      [...groupTypes, ...contactTypes]
    );

    const title = resolveTitle({
      moduleId: moduleDetails.id,
      group,
      meta: { module: moduleDetails }
    });
    const recordNamePluralLowercase = resolveRecordName({
      moduleId: moduleDetails.id,
      plural: true
    }).toLowerCase();

    const canAddForm =
      ![
        STANDARD_MODULE_IDS.accounts.id,
        STANDARD_MODULE_IDS.contacts.id
      ].includes(this.props.moduleDetails.id) &&
      this.props.moduleDetails.type_id !== STANDARD_MODULE_IDS.formsv3.id;

    const leftIcon = group ? (
      <Circle
        size={32}
        bg={group.background_color}
        color={group.text_color}
        mr={2}
      >
        {group.icon ? (
          <i
            className="material-icons"
            style={{
              fontSize: 20
            }}
          >
            {group.icon}
          </i>
        ) : (
          ""
        )}
      </Circle>
    ) : null;

    return (
      <Container>
        <SidebarTitle
          {...{
            title,
            location,
            leftIcon,
            params,
            groupName: group ? group.name : null
          }}
        />
        <Views
          {...{
            moduleId: params.moduleId,
            groupId: params.groupId,
            viewId: params.viewId,
            activeViewName: isPassesRoute(this.props)
              ? "Select view..."
              : activeView.name,
            activeViewType: activeView.view_type,
            updateViewName: this.updateViewName,
            activeViewIsPublic: activeView.is_public,
            toggleIsPublic: this.toggleIsPublic,
            selectView: this.selectView,
            duplicateView: this.duplicateView,
            deleteView: this.deleteView,
            reports: this.getReports()
          }}
        />
        {this.props.params.groupId ? (
          <Div pt={1}>{/* <Sidebar links={reportLinks} /> */}</Div>
        ) : (
          <QuickFilters
            {...{
              moduleHasApproval: moduleDetails.has_approval,
              selectQuickFilter: this.selectQuickFilter,
              selectMyRecords: this.selectMyRecords,
              myRecordsIsSelected: quickFilters.includes(VIEW_MY_RECORDS_ID),
              allRecordsIsSelected: !quickFilters.includes(VIEW_MY_RECORDS_ID),
              recordNamePluralLowercase,
              countOfRecords: this.countRows(null, records),
              countOfMyRecords: this.countRows(VIEW_MY_RECORDS_ID, records),
              recordTypes: recordTypesWithHandlers,
              selectPending: () =>
                this.selectQuickFilter({
                  id: VIEW_PENDING_RECORDS_ID
                }),
              selectApproved: () =>
                this.selectQuickFilter({
                  id: VIEW_APPROVED_RECORDS_ID
                }),
              selectDenied: () =>
                this.selectQuickFilter({
                  id: VIEW_DENIED_RECORDS_ID
                }),
              pendingIsSelected: quickFilters.includes(VIEW_PENDING_RECORDS_ID),
              approvedIsSelected: quickFilters.includes(
                VIEW_APPROVED_RECORDS_ID
              ),
              deniedIsSelected: quickFilters.includes(VIEW_DENIED_RECORDS_ID),
              forms: formsWithHandlers
            }}
          />
        )}
        {this.getPanels()}
        {(this.props.params.groupId && (
          <React.Fragment>
            <Sidebar
              iniOpenedMenues={["manage"]}
              links={getManageLinks(this.props)}
            />
            {/* <div styleName="separator" /> */}
          </React.Fragment>
        )) ||
          null}

        {!this.props.params.groupId ? (
          <CanUserDo action={`${this.props.moduleId}_manage`}>
            <PrimaryLink
              name="Setup"
              active={false}
              onClick={this.goToModuleSetup}
            />
          </CanUserDo>
        ) : null}

        {this.canViewReports() ? (
          <PrimaryLink
            name="Reports"
            active={false}
            onClick={this.goToReports}
          />
        ) : null}

        {this.props.params.eventId && canAddForm ? (
          <CanUserDo action={`${this.props.moduleId}_manage`}>
            <PrimaryLink
              name="Create Form"
              active={false}
              onClick={this.showAddFormModal}
            />
          </CanUserDo>
        ) : null}
      </Container>
    );
  }
}

export default ModuleNav;
