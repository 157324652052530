import React from "react";
import { Div } from "components/Base";
import { connect } from "react-redux";
import { getters } from "../../model";

const decorate = connect((state, props) => ({
  session: getters.sessionDetails(state, props)
}));

const Questions = ({ session: { questions } }) => <Div>Questions</Div>;

export default decorate(Questions);
