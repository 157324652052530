import React from "react";
import EditorBase from "../TableConnector";
import Layout from "./Layout";

class FileEditor extends EditorBase {
  constructor(props) {
    super(props);

    this.state = {
      ...(this.state || {})
    };
  }

  render() {
    return (
      <Layout
        {...this.props}
        handleChange={this.handleChange}
        valueState={this.state}
      />
    );
  }
}

export default FileEditor;
