import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { Div } from "components/Base";
import moment from "moment";
import { groupBy } from "lodash";
import Counter from "./Counter";
import EmptyState from "./EmptyState";

const BACKGROUND_COLOR_1 = "white";
const BACKGROUND_COLOR_2 = "#fafafa";

const getDayColor = (dateGroups, day) => {
  const group = dateGroups.filter(g => g.days.includes(day));
  return group.length ? group[0].color : "#eee";
};

const EventDays = ({ eventDateGroups, datesPosition }) => (
  <Div display={`${datesPosition === "top" ? "row" : "column"}`}>
    {eventDateGroups.map(dateGroup => (
      <Div
        key={dateGroup.id}
        display="row.flex-start.center"
        mr={datesPosition === "top" ? 4 : 0}
      >
        <Div
          style={{
            backgroundColor:
              dateGroup.color === "#ffffff" ? "#ccc" : dateGroup.color
          }}
          width={5}
          height={12}
          mr={2}
        />
        <Div fw={3}>{dateGroup.name}</Div>
      </Div>
    ))}
  </Div>
);

const MealItems = ({
  activeType,
  cateringMealsPerDay,
  eventDateGroups,
  toggleOnly,
  datesPosition = "right"
}) => {
  const group = activeType.groups[0];
  const days = Object.keys(groupBy(cateringMealsPerDay, "dayId")).sort();

  if (!group) {
    return <EmptyState />;
  }

  return (
    <Div
      display={`${
        datesPosition === "top" ? "column" : "row"
      }.space-between.flex-start`}
    >
      {datesPosition === "top" ? (
        <Div mb={3}>
          <EventDays
            eventDateGroups={eventDateGroups}
            datesPosition={datesPosition}
          />
        </Div>
      ) : null}
      <table>
        <thead>
          <tr>
            <th />
            {group.items.map((item, itemIdx) => (
              <th key={item.id}>
                <Div
                  bg={itemIdx % 2 ? BACKGROUND_COLOR_1 : BACKGROUND_COLOR_2}
                  p={3}
                >
                  <Div fw={3} fs={4}>
                    {item.name}
                  </Div>
                  {/*
                  // @NOTE: Hiding until connected
                  <Div display="row.center.center">
                    <Div
                      fs={1}
                      fw={1}
                      onClick={() => {}}
                      underline
                      color="gray6"
                      mr={2}
                    >
                      All
                    </Div>
                    <Div
                      fs={1}
                      fw={1}
                      onClick={() => {}}
                      underline
                      color="gray6"
                    >
                      None
                    </Div>
                  </Div>
                  */}
                </Div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {days.map((day, dayIdx) => (
            <tr key={dayIdx}>
              <td>
                <Div
                  display="column.center.flex-start"
                  pr={4}
                  pl={2}
                  bl={3}
                  style={{ borderColor: getDayColor(eventDateGroups, day) }}
                >
                  <Div fs={1} fw={4} color="gray6" uppercase>
                    {moment(day).format("ddd")}
                  </Div>
                  <Div color="gray6" fw={2} fs={6}>
                    {moment(day).format("D")}
                  </Div>
                </Div>
              </td>
              {group.items.map((item, itemIdx) => {
                const mealDay = cateringMealsPerDay.find(
                  m => m.mealId === item.id && m.dayId === day
                );
                const variant = item.variants.find(v =>
                  Boolean(
                    v.rules.find(
                      r => r.pattern === "is_valid_for" && r.value === day
                    )
                  )
                );
                return (
                  <td
                    key={item.id}
                    style={{
                      backgroundColor:
                        itemIdx % 2 ? BACKGROUND_COLOR_1 : BACKGROUND_COLOR_2
                    }}
                  >
                    <Div p={3}>
                      {mealDay && variant ? (
                        <Counter
                          {...{
                            toggleOnly,
                            isToggled: variant.selected,
                            onToggle: variant.onToggle,
                            quantity: variant.quantity,
                            incrementQuantity: variant.incrementQuantity,
                            decrementQuantity: variant.decrementQuantity,
                            updateVariantQuantity: variant.updateVariantQuantity
                          }}
                        />
                      ) : null}
                    </Div>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      {datesPosition === "right" ? (
        <Div display="column">
          <EventDays
            eventDateGroups={eventDateGroups}
            datesPosition={datesPosition}
          />
        </Div>
      ) : null}
    </Div>
  );
};

export default MealItems;
