export const NAMESPACE = "ItemCatalog";

export const TABS = {
  ITEMS: "items",
  ITEMS_QUESTIONS: "itemsQuestions",
  ZONES: "zones"
};

export const SUB_TABS = {
  ITEMS: "items",
  APPROVERS: "approvers",
  ZONES: "zones",
  ACCESS_GRID: "accessGrid",
  ACTIONS: "actions",
  INVENTORY: "inventory"
};

export const SUB_TABS_ROUTES = {
  editEventSettingsCatalogItems: SUB_TABS.ITEMS,
  editEventSettingsCatalogApprovers: SUB_TABS.APPROVERS,
  editEventSettingsCatalogZones: SUB_TABS.ZONES,
  editEventSettingsCatalogAccessGrid: SUB_TABS.ACCESS_GRID,
  editEventSettingsCatalogActions: SUB_TABS.ACTIONS,
  editEventSettingsCatalogInventory: SUB_TABS.INVENTORY
};
