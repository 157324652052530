export const NAMESPACE = "UITimeTable";

export const EVENT_ACTIONS = {
  EDIT: `${NAMESPACE}/editEvent`,
  RESIZE_REQUEST: `${NAMESPACE}/resizeRequestEvent`,
  RESIZE_START: `${NAMESPACE}/resizeStartEvent`
};

export const DAY_GROUPS_IDS = {
  SHOW: "uuid-show-days",
  LOAD_IN: "uuid-load-in-days",
  LOAD_OUT: "uuid-load-out-days",
  OFF: "uuid-days-off"
};

export const CALENDAR_VIEW = {
  DAY: "listDay",
  MONTH: "dayGridMonth",
  FOUR_DAYS: "fourDays",
  RESOURCE: "resourceTimeGridDay",
  WEEK: "timeGridWeek"
};
