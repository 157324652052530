import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function addToGroupOrder(data) {
  return async (dispatch, getState) => {
    try {
      return await api.post(getState().user.user.credentials, data);
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding to group order"
          }
        ])
      );
    }
  };
}
