import React from "react";
import {
  Div,
  DragIcon,
  Text3,
  Span,
  CheckIcon,
  EditIcon,
  NotVisibleIcon
} from "components/Base";

const FieldCard = ({
  id,
  Icon,
  required,
  title,
  onHide,
  onEdit,
  canDrag,
  onToggle
}) => (
  <Div
    bra={1}
    shadow={2}
    className="hoverContainer"
    py={2}
    px={1}
    bg="white"
    mb={2}
    display="row.flex-start.center"
  >
    <DragIcon mr={2} color={canDrag ? undefined : "white"} />
    {Icon && <Icon sizeWFS={3} mr={1} />}
    <Text3 bold flex={1} truncate>
      {title}
      {required && (
        <Span pl={2} color="danger5">
          *
        </Span>
      )}
    </Text3>
    <Div className="showOnHover" display="row.flex-start.center">
      {onToggle && (
        <CheckIcon
          color={
            !required ? { default: "neutral5", hover: "neutral6" } : "primary7"
          }
          mr={2}
          tooltip="Toggle Required"
          onClick={() => onToggle({ required, fieldId: id })}
        />
      )}
      {onEdit ? <EditIcon tooltip="Edit Field" mr={2} onClick={onEdit} /> : ""}
      {onHide ? (
        <NotVisibleIcon mr={1} onClick={onHide} tooltip="Hide this field" />
      ) : (
        ""
      )}
    </Div>
  </Div>
);

export default FieldCard;
