import React from "react";
import { connect } from "react-redux";

import { getSelectedViewForRendering } from "../selectors";

import { SCHEDULE_VIEWS } from "../constants";

import AllowAccessUsers from "Schedules/AllowAccessUsersModal/View";
import ActivityDetailSidebar from "Schedules/ActivityDetailsSidebar/View";
import { Div } from "components/Base";

import Toolbar from "./NewToolbar";
import CreateScheduleModal from "./CreateScheduleModal";
import EditScheduleModal from "./EditScheduleModal";
import GridLayout from "./GridLayout";
import CalendarLayout from "./CalendarLayout";

const views = {
  [SCHEDULE_VIEWS.GRID]: GridLayout,
  [SCHEDULE_VIEWS.CALENDAR]: CalendarLayout
};

const decorate = connect((state, props) => ({
  selectedView: getSelectedViewForRendering(state, props)
}));

const Layout = ({ handlers, selectedView }) => {
  return (
    <>
      <Toolbar handlers={handlers} />
      <Div display="column">
        {React.createElement(views[selectedView] || "div", {
          handlers,
          bg: "white",
          display: "column.flex-start.stretch",
          flex: 1,
          pl: 2,
          pt: 2,
          pb: 2,
          pr: 4,
          style: { overflow: "hidden" }
        })}
      </Div>
      <AllowAccessUsers />
      <CreateScheduleModal />
      <EditScheduleModal />
      <ActivityDetailSidebar />
    </>
  );
};

export default decorate(Layout);
