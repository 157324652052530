import { createModule } from "redux-mvc";

import model from "./model";
import sagas from "./sagas";

const module = createModule({
  ...model,
  observedDomains: ["user", "event", "portal"]
});

module.setRootSaga(sagas);

export default module;
