import EditEventConstants from "../constants/EditEventConstants";
import { Dispatcher } from "flux";
import assign from "object-assign";

const PayloadSources = EditEventConstants.PayloadSources;

const AppDispatcher = assign(new Dispatcher(), {
  handleViewAction(action) {
    this.dispatch({
      source: PayloadSources.VIEW_ACTION,
      action
    });
  },

  handleServerAction(action) {
    this.dispatch({
      source: PayloadSources.SERVER_ACTION,
      action
    });
  }
});

module.exports = AppDispatcher;
