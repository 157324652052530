import * as R from "ramda";
import { createSelector } from "reselect";

import PropTypes from "prop-types";
import React, { Component } from "react";
import Field from "../Field";
import AddNewSection from "components/Event/FormsV2/AddNewSection";
import constructRowData from "components/Event/FormsV2/Utils/constructRowData";
import { get } from "lodash";
import reduceFieldOrder from "components/Event/FormsV2/Utils/reduce-field-order";
import {
  ORDER_ITEMS_BLOCK_TYPE,
  ORDER_CUSTOMER_BLOCK_TYPE,
  ORDER_FULFILLMENT_BLOCK_TYPE,
  ORDER_DETAILS_BLOCK_TYPE,
  PEOPLE_BLOCK_TYPE
} from "components/Event/FormsV2/Form/Views/V3_Edit/Shared/constants/block-types";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import FormHeader from "components/Event/FormsV2/Sections/FormHeader";
import FormGrid from "components/Event/FormsV2/Sections/FormGrid";
import FormInput from "components/Event/FormsV2/Sections/FormInput";
import FormNumber from "components/Event/FormsV2/Sections/FormNumber";
import FormDate from "components/Event/FormsV2/Sections/FormDate";
import FormTime from "components/Event/FormsV2/Sections/FormTime";
import FormContactReference from "components/Event/FormsV2/Sections/FormContactReference";
import FormAccountReference from "components/Event/FormsV2/Sections/FormAccountReference";
import FormSubmitterReference from "components/Event/FormsV2/Sections/FormSubmitterReference";
import FormCredentialOrderReference from "components/Event/FormsV2/Sections/FormCredentialOrderReference";
import FormReference from "components/Event/FormsV2/Sections/FormReference";
import FormDateTime from "components/Event/FormsV2/Sections/FormDateTime";
import FormTextarea from "components/Event/FormsV2/Sections/FormTextarea";
import FormCheckbox from "components/Event/FormsV2/Sections/FormCheckbox";
import FormDropdown from "components/Event/FormsV2/Sections/FormDropdown";
import FormPercent from "components/Event/FormsV2/Sections/FormPercent";
import FormCurrency from "components/Event/FormsV2/Sections/FormCurrency";
import FormRadios from "components/Event/FormsV2/Sections/FormRadios";
import FormImage from "components/Event/FormsV2/Sections/FormImage";
import FormSeparator from "components/Event/FormsV2/Sections/FormSeparator";
import FormFileUpload from "components/Event/FormsV2/Sections/FormFileUpload";
import FormCountries from "components/Event/FormsV2/Sections/FormCountries";
import FormOrderItems from "components/Event/FormsV2/Sections/FormOrderItems";
import FormOrderCustomer from "components/Event/FormsV2/Sections/FormOrderCustomer";
import FormOrderFulfillment from "components/Event/FormsV2/Sections/FormOrderFulfillment";
import FormOrderDetails from "components/Event/FormsV2/Sections/FormOrderDetails";
import FormPeople from "components/Event/FormsV2/Sections/FormPeople";
import FormPhone from "components/Event/FormsV2/Sections/FormPhone";
import FormPlaceholder from "components/Event/FormsV2/Sections/FormPlaceholder";
import FormCatalogItem from "components/Event/FormsV2/Sections/FormCatalogItem";
import FormCalculatedNumber from "components/Event/FormsV2/Sections/FormCalculatedNumber";
import FormCredentials from "components/Event/FormsV2/Sections/FormCredentials";
import FormCatering from "components/Event/FormsV2/Sections/FormCatering";
import FormEventDays from "components/Event/FormsV2/Sections/FormEventDays";
import FormDisabledCheck from "components/Event/FormsV2/Sections/FormDisabledCheck";
import FormSignature from "components/Event/FormsV2/Sections/FormSignature";

const getSubmission = createSelector(
  R.prop("form"),
  R.prop("field"),
  (form, field) => {
    const submissions = R.pathOr({}, ["subform", "form", "submissions"], field);
    return R.mergeAll([{ form }, R.head(R.values(submissions))]) || {};
  }
);

@CSSModules(css)
class Section extends Component {
  getFieldComponent(field, id, index) {
    const {
      form,
      isEditing,
      isFillingFormOut,
      isApprovingForm,
      isViewingApproval,
      isResponseLocked,
      canShowSimpleSideBar,
      allFields
    } = this.props;
    const values = this.props.submission ? this.props.submission.values : {};
    const disabled =
      !isFillingFormOut || isResponseLocked || get(field, "is_readonly");
    const relatedOrder = get(this.props.submission, ["related_order"]);
    const submissionId = get(this.props.submission, ["id"]);
    if (
      field.type === "reference" &&
      canShowSimpleSideBar &&
      isEditing &&
      field?.settings?.referenceFieldId
    ) {
      const fieldFounded = R.find(
        x => x?.settings?.referenceFieldId === field?.settings?.referenceFieldId
      )(allFields);

      if (fieldFounded) {
        field.type = fieldFounded.type;
      }
    }

    switch (field.type) {
      case "placeholder":
        return <FormPlaceholder field={field} />;
      case "section":
        return (
          <Section
            {...this.props}
            section={field}
            sectionId={field.id}
            field={field.section_fields}
            fields={[]}
          />
        );
      case "header":
        return (
          <FormHeader
            dispatch={this.props.dispatch}
            field={field}
            isEditing={this.props.isEditing}
            isFillingFormOut={isFillingFormOut}
          />
        );
      case "separator":
        return (
          <FormSeparator
            field={field}
            isEditing={this.props.isEditing}
            isResponseLocked={isResponseLocked}
            isApproving={isApprovingForm}
            isFillingFormOut={isFillingFormOut}
            updateFormValue={this.props.updateFormValue}
          />
        );
      case PEOPLE_BLOCK_TYPE:
        return (
          <FormPeople
            eventId={this.props.form.event_id}
            submissionId={R.prop("id")(this.props.submission)}
            submissionRecordId={R.prop("submission_record_id")(
              this.props.submission
            )}
            order={relatedOrder}
            field={field}
            block={R.path(["people_blocks", field.id])(this.props.submission)}
            parentSubmission={this.props.submission}
            //
            shouldDisableFields={disabled}
            eventDetails={this.props.eventDetails}
            // @NOTE: This currently breaks the people grid (mode: submission)
            // mode={this.props.mode}
            isEditing={this.props.isEditing}
            isPreviewing={this.props.isPreviewing}
            isResponseLocked={isResponseLocked}
            isApproving={isApprovingForm}
            isFillingFormOut={isFillingFormOut}
            register={this.props.registerValidator}
            unregister={this.props.unregisterValidator}
          />
        );
      case ORDER_ITEMS_BLOCK_TYPE:
        return (
          <FormOrderItems
            submissionId={submissionId}
            order={relatedOrder}
            eventDetails={this.props.eventDetails}
            field={field}
            isEditing={this.props.isEditing}
            isPreviewing={this.props.isPreviewing}
            isResponseLocked={isResponseLocked}
            isApproving={isApprovingForm}
            isFillingFormOut={isFillingFormOut}
            updateFormValue={this.props.updateFormValue}
            register={this.props.registerValidator}
            unregister={this.props.unregisterValidator}
          />
        );
      case ORDER_CUSTOMER_BLOCK_TYPE:
        return (
          <FormOrderCustomer
            field={field}
            order={relatedOrder}
            eventDetails={this.props.eventDetails}
            disabled={disabled}
            isEditing={this.props.isEditing}
            isResponseLocked={isResponseLocked}
            isApproving={isApprovingForm}
            isFillingFormOut={isFillingFormOut}
            updateFormValue={this.props.updateFormValue}
            register={this.props.registerValidator}
            unregister={this.props.unregisterValidator}
            canShowSimpleSideBar={canShowSimpleSideBar}
          />
        );
      case ORDER_FULFILLMENT_BLOCK_TYPE:
        return (
          <FormOrderFulfillment
            field={field}
            order={relatedOrder}
            eventDetails={this.props.eventDetails}
            disabled={disabled}
            isEditing={this.props.isEditing}
            isResponseLocked={isResponseLocked}
            isApproving={isApprovingForm}
            isFillingFormOut={isFillingFormOut}
            updateFormValue={this.props.updateFormValue}
            register={this.props.registerValidator}
            unregister={this.props.unregisterValidator}
            canShowSimpleSideBar={canShowSimpleSideBar}
          />
        );
      case ORDER_DETAILS_BLOCK_TYPE:
        return (
          <FormOrderDetails
            field={field}
            order={relatedOrder}
            eventDetails={this.props.eventDetails}
            disabled={disabled}
            isEditing={this.props.isEditing}
            isResponseLocked={isResponseLocked}
            isApproving={isApprovingForm}
            isFillingFormOut={isFillingFormOut}
            updateFormValue={this.props.updateFormValue}
            register={this.props.registerValidator}
            unregister={this.props.unregisterValidator}
            canShowSimpleSideBar={canShowSimpleSideBar}
          />
        );
      case "text":
      case "email":
        return (
          <FormDisabledCheck disabled={disabled}>
            <FormInput
              formValues={values}
              field={field}
              isEditing={this.props.isEditing}
              updateFormValue={this.props.updateFormValue}
              type={field.type === "email" ? "email" : "text"}
              disabled={disabled}
              register={this.props.registerValidator}
              unregister={this.props.unregisterValidator}
              canShowSimpleSideBar={canShowSimpleSideBar}
            />
          </FormDisabledCheck>
        );
      case "number":
        return (
          <FormDisabledCheck disabled={disabled}>
            <FormNumber
              formValues={values}
              field={field}
              isEditing={this.props.isEditing}
              updateFormValue={this.props.updateFormValue}
              disabled={disabled}
              register={this.props.registerValidator}
              unregister={this.props.unregisterValidator}
              canShowSimpleSideBar={canShowSimpleSideBar}
            />
          </FormDisabledCheck>
        );
      case "contact-reference":
        return (
          <FormContactReference
            field={field}
            isEditing={this.props.isEditing}
          />
        );
      case "account-reference":
        return (
          <FormAccountReference
            field={field}
            isEditing={this.props.isEditing}
          />
        );
      case "credential-order-reference":
        return (
          <FormCredentialOrderReference
            field={field}
            isEditing={this.props.isEditing}
          />
        );
      case "submitter-reference":
        return (
          <FormSubmitterReference
            field={field}
            isEditing={this.props.isEditing}
          />
        );
      case "checkbox":
        return (
          <FormDisabledCheck disabled={disabled}>
            <FormCheckbox
              formValues={values}
              field={field}
              isEditing={this.props.isEditing}
              updateFormValue={this.props.updateFormValue}
              disabled={disabled}
              register={this.props.registerValidator}
              unregister={this.props.unregisterValidator}
              canShowSimpleSideBar={canShowSimpleSideBar}
            />
          </FormDisabledCheck>
        );
      case "dropdown":
        return (
          <FormDisabledCheck disabled={disabled}>
            <FormDropdown
              formValues={values}
              field={field}
              isEditing={this.props.isEditing}
              updateFormValue={this.props.updateFormValue}
              disabled={disabled}
              register={this.props.registerValidator}
              unregister={this.props.unregisterValidator}
              canShowSimpleSideBar={canShowSimpleSideBar}
            />
          </FormDisabledCheck>
        );
      case "radio":
        return (
          <FormDisabledCheck disabled={disabled}>
            <FormRadios
              formValues={values}
              field={field}
              isEditing={this.props.isEditing}
              updateFormValue={this.props.updateFormValue}
              disabled={disabled}
              register={this.props.registerValidator}
              unregister={this.props.unregisterValidator}
              canShowSimpleSideBar={canShowSimpleSideBar}
            />
          </FormDisabledCheck>
        );
      case "image":
        return (
          <FormImage
            formValues={values}
            field={field}
            isEditing={this.props.isEditing}
            isResponseLocked={isResponseLocked}
            isApproving={isApprovingForm}
            isFillingFormOut={isFillingFormOut}
            dispatch={this.props.dispatch}
          />
        );
      case "textarea":
        return (
          <FormDisabledCheck disabled={disabled}>
            <FormTextarea
              formValues={values}
              field={field}
              isEditing={this.props.isEditing}
              updateFormValue={this.props.updateFormValue}
              disabled={disabled}
              register={this.props.registerValidator}
              unregister={this.props.unregisterValidator}
              canShowSimpleSideBar={canShowSimpleSideBar}
            />
          </FormDisabledCheck>
        );
      case "signature":
        return (
          <FormDisabledCheck disabled={disabled}>
            <FormSignature
              formValues={values}
              field={field}
              isEditing={this.props.isEditing}
              updateFormValue={this.props.updateFormValue}
              disabled={disabled}
              register={this.props.registerValidator}
              unregister={this.props.unregisterValidator}
              canShowSimpleSideBar={canShowSimpleSideBar}
            />
          </FormDisabledCheck>
        );
      case "file":
        return (
          <FormDisabledCheck disabled={disabled}>
            <FormFileUpload
              formValues={values}
              field={field}
              isEditing={this.props.isEditing}
              isFillingFormOut={isFillingFormOut}
              isResponseLocked={isResponseLocked}
              isApproving={isApprovingForm}
              updateFormValue={this.props.updateFormValue}
              showModal={this.props.showModal}
              disabled={disabled}
              register={this.props.registerValidator}
              unregister={this.props.unregisterValidator}
              canShowSimpleSideBar={canShowSimpleSideBar}
            />
          </FormDisabledCheck>
        );
      case "date":
        return (
          <FormDisabledCheck disabled={disabled}>
            <FormDate
              formValues={values}
              field={field}
              isEditing={this.props.isEditing}
              updateFormValue={this.props.updateFormValue}
              disabled={disabled}
              register={this.props.registerValidator}
              unregister={this.props.unregisterValidator}
              canShowSimpleSideBar={canShowSimpleSideBar}
            />
          </FormDisabledCheck>
        );
      case "time":
        return (
          <FormDisabledCheck disabled={disabled}>
            <FormTime
              formValues={values}
              field={field}
              isEditing={this.props.isEditing}
              updateFormValue={this.props.updateFormValue}
              disabled={disabled}
              register={this.props.registerValidator}
              unregister={this.props.unregisterValidator}
              canShowSimpleSideBar={canShowSimpleSideBar}
            />
          </FormDisabledCheck>
        );
      case "datetime":
        return (
          <FormDisabledCheck disabled={disabled}>
            <FormDateTime
              formValues={values}
              field={field}
              isEditing={this.props.isEditing}
              updateFormValue={this.props.updateFormValue}
              disabled={disabled}
              register={this.props.registerValidator}
              unregister={this.props.unregisterValidator}
              canShowSimpleSideBar={canShowSimpleSideBar}
            />
          </FormDisabledCheck>
        );
      case "reference":
        return (
          <FormReference
            formValues={values}
            field={field}
            eventId={this.props.form.event_id}
            isEditing={this.props.isEditing}
            isResponseLocked={isResponseLocked}
            isApproving={isApprovingForm}
            isFillingFormOut={isFillingFormOut}
            updateFormValue={this.props.updateFormValue}
            register={this.props.registerValidator}
            unregister={this.props.unregisterValidator}
            canShowSimpleSideBar={canShowSimpleSideBar}
          />
        );
      case "percent":
        return (
          <FormDisabledCheck disabled={disabled}>
            <FormPercent
              formValues={values}
              field={field}
              isEditing={this.props.isEditing}
              updateFormValue={this.props.updateFormValue}
              disabled={disabled}
              register={this.props.registerValidator}
              unregister={this.props.unregisterValidator}
              canShowSimpleSideBar={canShowSimpleSideBar}
            />
          </FormDisabledCheck>
        );
      case "catalog-item":
        return (
          <FormDisabledCheck disabled={disabled}>
            <FormCatalogItem
              formValues={values}
              field={field}
              isEditing={this.props.isEditing}
              updateFormValue={this.props.updateFormValue}
              disabled={disabled}
              register={this.props.registerValidator}
              unregister={this.props.unregisterValidator}
              canShowSimpleSideBar={canShowSimpleSideBar}
            />
          </FormDisabledCheck>
        );
      case "event-days":
        return (
          <FormDisabledCheck disabled={disabled}>
            <FormEventDays
              eventDetails={this.props.eventDetails}
              formValues={values}
              field={field}
              isEditing={this.props.isEditing}
              updateFormValue={this.props.updateFormValue}
              disabled={disabled}
              register={this.props.registerValidator}
              unregister={this.props.unregisterValidator}
              canShowSimpleSideBar={canShowSimpleSideBar}
            />
          </FormDisabledCheck>
        );
      case "catering":
        return (
          <FormDisabledCheck disabled={disabled}>
            <FormCatering
              eventDetails={this.props.eventDetails}
              formValues={values}
              fields={this.props.fields}
              field={field}
              isEditing={this.props.isEditing}
              updateFormValue={this.props.updateFormValue}
              disabled={disabled}
              register={this.props.registerValidator}
              unregister={this.props.unregisterValidator}
              canShowSimpleSideBar={canShowSimpleSideBar}
            />
          </FormDisabledCheck>
        );
      case "credentials":
        return (
          <FormDisabledCheck disabled={disabled}>
            <FormCredentials
              eventDetails={this.props.eventDetails}
              formValues={values}
              field={field}
              isEditing={this.props.isEditing}
              updateFormValue={this.props.updateFormValue}
              disabled={disabled}
              register={this.props.registerValidator}
              unregister={this.props.unregisterValidator}
              canShowSimpleSideBar={canShowSimpleSideBar}
            />
          </FormDisabledCheck>
        );
      case "currency":
        return (
          <FormDisabledCheck disabled={disabled}>
            <FormCurrency
              formValues={values}
              field={field}
              isEditing={this.props.isEditing}
              updateFormValue={this.props.updateFormValue}
              disabled={disabled}
              register={this.props.registerValidator}
              unregister={this.props.unregisterValidator}
              canShowSimpleSideBar={canShowSimpleSideBar}
            />
          </FormDisabledCheck>
        );
      case "calculated-number":
        return (
          <FormDisabledCheck disabled={disabled}>
            <FormCalculatedNumber
              formValues={values}
              field={field}
              isEditing={this.props.isEditing}
              disabled={disabled}
              fields={this.props.fields}
              canShowSimpleSideBar={canShowSimpleSideBar}
            />
          </FormDisabledCheck>
        );
      case "subform":
        const props = {
          showModal: this.props.showModal,
          hideModal: this.props.hideModal,
          eventId: parseInt(form.event_id, 10),
          formId: form.id || "",
          response: form.response ? form.response : null,
          responseId: form.response ? form.response.id : 0,
          recipientId: form.response ? form.response.recipient_id : 0,
          recipientCanViewChanges: true,
          formValues: values,
          parentForm: form,
          index,
          field,
          isEditing,
          isResponseLocked: this.props.isResponseLocked,
          isApprovingForm,
          isPreviewing: this.props.isPreviewing,
          isFillingFormOut,
          isViewingApproval,
          updateFormValue: this.props.updateFormValue,
          showEditColumnModal: this.showEditColumnModal,
          eventDetails: this.props.eventDetails,
          mode: this.props.mode,
          scrollTopId: this.props.scrollTopId
        };

        const mergeValues = s => ({
          ...s,
          ...s.values
        });

        const getRows = () => {
          if (this.props.isEditing || this.props.isPreviewing) {
            return Object.keys(field.subform.form.submissions).map(key =>
              mergeValues(field.subform.form.submissions[key])
            );
          } else if (values[field.id].value.submissions) {
            return Object.keys(values[field.id].value.submissions).map(key =>
              mergeValues(values[field.id].value.submissions[key])
            );
          }
          return [];
        };

        const submissionToUse =
          this.props.submission || getSubmission({ field, ...this.props });

        const addReviews = rows => {
          if (get(submissionToUse, "is_submitted")) {
            return rows.map(submission => ({
              ...submission,
              values: constructRowData(submission)
            }));
          }
          return rows;
        };

        return (
          <FormGrid
            ref={ref => this.props.registerRef(ref, `grid_${field.id}`)}
            dispatch={this.props.dispatch}
            field={field}
            form={field.subform.form}
            fieldRelationships={this.props.form.field_relationships || []}
            mappedFields={this.props.form.mapped_fields || []}
            submission={submissionToUse}
            preferences={this.props.preferences[field.subform.form.id] || {}}
            rows={addReviews(getRows())}
            getColumnModalFields={this.props.getColumnModalFields}
            register={this.props.registerValidator}
            unregister={this.props.unregisterValidator}
            {...props}
          />
        );
      case "phone":
        return (
          <FormDisabledCheck disabled={disabled}>
            <FormPhone
              formValues={values}
              field={field}
              isEditing={this.props.isEditing}
              updateFormValue={this.props.updateFormValue}
              disabled={disabled}
              register={this.props.registerValidator}
              unregister={this.props.unregisterValidator}
              canShowSimpleSideBar={canShowSimpleSideBar}
            />
          </FormDisabledCheck>
        );
      case "countries":
        return (
          <FormDisabledCheck disabled={disabled}>
            <FormCountries
              formValues={values}
              field={field}
              isEditing={this.props.isEditing}
              updateFormValue={this.props.updateFormValue}
              disabled={disabled}
              register={this.props.registerValidator}
              unregister={this.props.unregisterValidator}
              canShowSimpleSideBar={canShowSimpleSideBar}
            />
          </FormDisabledCheck>
        );
      default:
        return null;
    }
  }

  deleteField = async fieldId => {
    await this.props.deleteField({
      eventId: this.props.form.event_id,
      formId: this.props.form.id,
      fieldId
    });

    this.props.updateFieldOrder({
      formId: this.props.form.id,
      commit: true,
      fields: reduceFieldOrder(this.props.fields)
    });
    this.props.getModule({
      moduleId: this.props.form.base_module_id,
      options: {
        eventId: this.props.form.event_id
      }
    });
  };

  render() {
    return (
      <div styleName="container">
        {this.props.fields.map((field, index) => (
          <span key={`${field.id}`}>
            <Field
              {...this.props}
              field={field}
              index={index}
              deleteField={this.deleteField}
              showEditQuestionModal={this.props.showEditQuestionModal}
              showEditItemBlockModal={this.props.showEditItemBlockModal}
              hideOptions={field.type === "placeholder"}
              canShowSimpleSideBar={this.props.canShowSimpleSideBar}
            >
              {this.getFieldComponent(field, field.id, index)}
            </Field>
            {this.props.formVersion !== 3 &&
            this.props.isEditing &&
            field.type !== "placeholder" ? (
              <AddNewSection
                dispatch={this.props.dispatch}
                eventId={this.props.form.event_id}
                formId={this.props.form.id}
                index={index}
                menuLink={false}
                sectionId={this.props.sectionId}
                showAddQuestionModal={this.props.showAddQuestionModal}
                showModal={this.props.showModal}
              />
            ) : (
              ""
            )}
          </span>
        ))}
        {this.props.formVersion !== 3 &&
        this.props.isEditing &&
        this.props.fields.length === 0 ? (
          <AddNewSection
            dispatch={this.props.dispatch}
            eventId={this.props.form.event_id}
            formId={this.props.form.id}
            index={this.props.fields.length}
            menuLink={false}
            sectionId={this.props.sectionId}
            showAddQuestionModal={this.props.showAddQuestionModal}
            showModal={this.props.showModal}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

Section.propTypes = {
  dispatch: PropTypes.func.isRequired,
  eventDetails: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  getColumnModalFields: PropTypes.func,
  getFormRows: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  isApprovingForm: PropTypes.bool,
  isEditing: PropTypes.bool,
  isFillingFormOut: PropTypes.bool,
  isPreviewing: PropTypes.bool,
  isResponseLocked: PropTypes.bool,
  isViewingApproval: PropTypes.bool,
  preferences: PropTypes.object.isRequired,
  registerValidator: PropTypes.func,
  section: PropTypes.object,
  sectionId: PropTypes.string,
  showAddQuestionModal: PropTypes.func,
  showEditQuestionModal: PropTypes.func,
  showModal: PropTypes.func.isRequired,
  submission: PropTypes.object,
  unregisterValidator: PropTypes.func,
  updateFormValue: PropTypes.func,
  formVersion: PropTypes.number,
  deleteField: PropTypes.func.isRequired,
  updateFieldOrder: PropTypes.func.isRequired,
  getModule: PropTypes.func.isRequired
};

export default Section;
