import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const AccountCardActions = ({ children }) => (
  <div styleName="container">{children}</div>
);

AccountCardActions.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object])
};

export default CSSModules(AccountCardActions, css);
