import { call, put, select } from "redux-saga/effects";

import { registerError } from "redux/modules/errors/actions";
import { actions } from "./model";

import { getPathname } from "App/Router/selectors";
import { eventUUID as getPortalEventUUID } from "redux/modules/portal/selectors";

import { apiCall } from "App/Data/sagas";

const fetch = function*() {
  const eventUUID = yield select(getPortalEventUUID);

  // eslint-disable-next-line no-unused-vars
  const [_, alertId] = yield select(getPathname, {
    match: /\/alerts\/([a-z\d-]+)\/?/i
  });

  try {
    const { payload: alert } = yield call(apiCall, {
      method: "get",
      url: `/portal/event/${eventUUID}/alert/${alertId}`
    });

    yield put(actions.fetchResponse(alert));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while loading the alert"
        }
      ])
    );
  }
};

export default fetch;
