import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import { bindInstance } from "redux-mvc";

import { noop } from "utils/General";

import css from "./styles.scss";

import { getters, actions } from "../model";
import { getRowsIndex, hasDuplicates } from "../selectors";

import { Row } from "./Row";
import { Header, GroupHeader } from "./Header";
import { TableWrapper, GroupWrapper } from "./TableWrapper";

import { LIST_INDEX } from "../constants";

const decorate = connect(
  (state, props) => ({
    rows: getters.rows(state, props),
    rowIndex: getRowsIndex(state, props),
    activeIndex: getters.activeIndex(state, props),
    columns: getters.columns(state, props),
    columnIndex: getters.columnIndex(state, props),
    canShowDeDuplicate: hasDuplicates(state, props)
  }),
  bindInstance({
    executeAction: actions.executeAction
  })
);

const Table = ({
  rowIndex,
  rows,
  columnIndex,
  columns,
  instanceId,
  rowActions,
  columnActions,
  activeIndex,
  showCheckbox,
  showEmptyTable,
  showOpenRecord,
  executeAction,
  canShowDeDuplicate,
  moduleId
}) => {
  if (!showEmptyTable && R.length(R.keys(rows)) === 0) {
    return <div />;
  }

  const useRowActions = R.length(rowActions) > 0;

  if (activeIndex === LIST_INDEX) {
    const group = R.head(rowIndex);
    return (
      <table styleName="ui-table">
        <Header
          useRowActions={useRowActions}
          columns={columns}
          actions={columnActions}
          columnIndex={columnIndex}
          instanceId={instanceId}
          showCheckbox={showCheckbox}
          showOpenRecord={showOpenRecord}
          canShowDeDuplicate={canShowDeDuplicate}
          moduleId={moduleId}
        />
        <tbody>
          {R.map(
            rowId => (
              <Row
                key={rowId}
                row={R.prop(rowId, rows)}
                columnIndex={columnIndex}
                columns={columns}
                instanceId={instanceId}
                actions={rowActions}
                group={group}
                showCheckbox={showCheckbox}
                showOpenRecord={showOpenRecord}
                executeAction={executeAction}
                canShowDeDuplicate={canShowDeDuplicate}
                moduleId={moduleId}
              />
            ),
            group.list
          )}
        </tbody>
      </table>
    );
  }

  return R.prepend(
    <table styleName="ui-table" key="header">
      <Header
        useRowActions={useRowActions}
        columns={columns}
        actions={columnActions}
        columnIndex={columnIndex}
        instanceId={instanceId}
        showCheckbox={showCheckbox}
        showOpenRecord={showOpenRecord}
        canShowDeDuplicate={canShowDeDuplicate}
        moduleId={moduleId}
      />
    </table>,
    R.map(
      group => (
        <GroupWrapper style={{ marginTop: 8 }} key={group.id}>
          <table styleName="ui-table">
            <GroupHeader
              useRowActions={useRowActions}
              showCheckbox={showCheckbox}
              showOpenRecord={showOpenRecord}
              columns={columns}
              columnIndex={columnIndex}
              instanceId={instanceId}
              group={group}
              canShowDeDuplicate={canShowDeDuplicate}
              moduleId={moduleId}
            />
            <tbody>
              {R.map(
                rowId => (
                  <Row
                    key={rowId}
                    row={R.prop(rowId, rows)}
                    columnIndex={columnIndex}
                    columns={columns}
                    instanceId={instanceId}
                    actions={rowActions}
                    group={group}
                    showCheckbox={showCheckbox}
                    showOpenRecord={showOpenRecord}
                    executeAction={executeAction}
                    canShowDeDuplicate={canShowDeDuplicate}
                    moduleId={moduleId}
                  />
                ),
                group.list
              )}
            </tbody>
          </table>
        </GroupWrapper>
      ),
      rowIndex
    )
  );
};

Table.defaultProps = {
  rows: {},
  columns: {},
  rowIndex: [],
  columnIndex: [],
  columnWidths: {},
  rowActions: [],
  columnActions: [],
  showCheckbox: true,
  showOpenRecord: false,
  widthAuto: false,
  executeAction: noop
};

export { TableWrapper };

export default decorate(CSSModules(Table, css));
