import { createSelector } from "reselect";

import * as R from "ramda";

import { getters } from "Portal/CustomizeSectionsModal/model";

import { getLinksRecursive } from "./utils";

export const getLockedSections = createSelector(
  getters.sections,
  R.filter(R.propEq("locked", true))
);

export const getNotLockedSections = createSelector(
  getters.sections,
  R.filter(
    R.compose(
      R.not,
      R.propEq("locked", true)
    )
  )
);

export const getEnabledSections = createSelector(
  getNotLockedSections,
  getters.pages,
  getters.pagesOrder,
  (sections, pages, pagesOrder) => {
    const fields = [...sections, ...pages];
    return R.compose(
      R.map(fieldId => {
        const field = R.find(({ id }) => id === fieldId, fields);
        const type = R.propOr("", "type", field);
        return {
          ...field,
          isFolder: type === "folder",
          locked: false
        };
      }),
      R.propOr([], "root")
    )(pagesOrder);
  }
);

export const getSaveBtnDisabled = createSelector(
  getters.sections,
  R.compose(
    R.any(({ custom }) => R.isEmpty(custom)),
    R.filter(R.propEq("title", "custom"))
  )
);

export const getChildrenPages = createSelector(
  (_, props) => R.prop("parentId", props),
  getters.pages,
  getters.pagesOrder,
  (parentId, pages, pagesOrder) => {
    return R.compose(
      R.map(childId => R.find(R.propEq("id", childId), pages)),
      R.propOr([], parentId)
    )(pagesOrder);
  }
);

export const getLinks = createSelector(
  getLockedSections,
  getters.pagesOrder,
  getters.pages,
  (lockedSections, pagesOrder, pages) => {
    const rootChildren = R.propOr([], "root", pagesOrder);

    const updatedOrder = {
      ...pagesOrder,
      root: [...R.map(R.prop("id"), lockedSections), ...rootChildren]
    };

    return getLinksRecursive("root", updatedOrder, pages);
  }
);

export const getSections = createSelector(
  getters.sections,
  getters.isGroupPortal,
  (sections, isGroupPortal) => {
    if (isGroupPortal) {
      return sections;
    }

    return R.filter(R.prop("showForIndividualPortal"))(sections);
  }
);

export const getSectionsData = createSelector(
  getSections,
  getters.order,
  getters.enableCommunitySidebar,
  getLinks,
  (sections, order, enableCommunitySidebar, links) => {
    const getOrder = (section, fallbackOrder) => {
      if (section.locked) {
        return -1;
      }
      return R.propOr(fallbackOrder, section.id, order);
    };
    const updatedSections = sections.reduce((acc, section, idx) => {
      const mainFields = {
        [`${section.id}Title`]:
          section.title !== "custom" ? section.title : section.custom,
        [`${section.id}Description`]: section.description,
        [`show${section.name}Description`]: section.showDescription,
        [`${section.id}Order`]: getOrder(section, idx)
      };
      if (!section.locked) {
        return {
          ...acc,
          [`is${section.name}Enabled`]: section.isEnabled,
          ...mainFields
        };
      }
      return {
        ...acc,
        ...mainFields
      };
    }, {});

    return {
      ...updatedSections,
      enableCommunitySidebar,
      links
    };
  }
);

export const getIsSelectedPage = createSelector(
  (_, props) => R.prop("id", props),
  getters.pagesSelected,
  (id, pagesSelected) => {
    return R.any(selectedId => selectedId === id, pagesSelected);
  }
);
