import React from "react";
import { Div } from "components/Base";
import { connect } from "react-redux";
import { getters } from "Organizations/Home/model";
import MyTasksHeader from "./MyTasksHeader";
import MyTasksBody from "./MyTasksBody";
import Loading from "ui-kit/Loading";

const decorate = connect(state => ({
  loading: getters.loading(state)
}));

const MyTasks = ({ loading }) => (
  <Div
    width={1}
    bra={1}
    bg="white"
    mt={2}
    mb={4}
    style={{
      boxShadow:
        "0px 8px 20px rgba(0, 0, 0, 0.04), 0px 35px 54px rgba(0, 0, 0, 0.01)"
    }}
  >
    {loading ? (
      <Loading />
    ) : (
      <Div>
        <MyTasksHeader />
        <MyTasksBody />
      </Div>
    )}
  </Div>
);

export default decorate(MyTasks);
