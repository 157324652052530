import { createSelector } from "reselect";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import { eventDetails } from "redux/modules/event/selectors";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

const isModuleEnabled = (enabledModules, moduleId) =>
  enabledModules && Boolean(enabledModules.find(m => m.id === moduleId));

export const isCredentialsEnabled = createSelector(
  canEventUserDo,
  eventDetails,
  (canDo, event) =>
    isModuleEnabled(
      event.enabled_modules,
      STANDARD_MODULE_IDS.credentials.id
    ) && canDo(`${STANDARD_MODULE_IDS.credentials.id}_read`)
);
