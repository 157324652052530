import React from "react";
import * as R from "ramda";

import { connect } from "react-redux";
import {
  Div,
  ArrowIcon,
  Cards,
  FontIcon,
  KeyIcon,
  Text3,
  TinyOutlineBox,
  SmallOutlineButton,
  ExpandIcon,
  MediumShadedInput,
  SearchIcon,
  SmallFilledButton,
  MediumOutlineButton,
  MediumFilledButton
} from "components/Base";
import CollapsablePanel from "ui-kit/CollapsiblePanel";
import { noop } from "utils/General";
import { addS } from "utils/General";

import { getters } from "../model";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

const decorate = R.compose(
  connect(state => ({
    types: getters.types(state)
  })),
  CSSModules(css)
);

const FancyCell = CSSModules(
  ({ icon, iconColor, text, isDefault = false, ...props }) => (
    <Div display="row.flex-start.center" {...props}>
      {icon && (
        <FontIcon
          bg={iconColor || "neutral5"}
          color="white"
          pill
          p={1}
          mr={3}
          fs={3}
          flex={0}
        >
          {icon}
        </FontIcon>
      )}
      <Text3 bold mr={3} primary maxWidth={0.8}>
        {text}
      </Text3>
      {isDefault && <TinyOutlineBox mr={2}>Default</TinyOutlineBox>}
      <SmallOutlineButton
        m={2}
        pill
        styleName="childContainer"
        OnlyIcon={ExpandIcon}
        color="neutral7"
        raised
      />
    </Div>
  ),
  css
);

const Field = ({ name, onClick, typeIcon, isPrimaryField }) => {
  return (
    <div className={css.field}>
      <FancyCell
        text={
          <Div display="row.flex-start.center" width={1}>
            {isPrimaryField ? <KeyIcon fs={3} mr={2} color="warning5" /> : null}
            <FontIcon fs={2} mr={1} color="inherit">
              {typeIcon}
            </FontIcon>
            <Div truncate>{name}</Div>
          </Div>
        }
        onClick={onClick}
      />
    </div>
  );
};

const FieldsList = ({
  title,
  icon,
  fields = [],
  fieldsId,
  onReorder = noop,
  onSearch = noop,
  onAddFieldGroup = noop,
  onAddField = noop
}) => {
  const Fields = Cards(Field, fieldsId);

  return (
    <div styleName="column">
      <div styleName="title">
        <div styleName="titleIcon">
          <i className="material-icons">{icon}</i>
        </div>
        {title}
      </div>
      <div styleName="actions">
        <Div display="row.flex-start.center" width={1} pb={6}>
          <MediumShadedInput
            continuous
            LeftIcon={SearchIcon}
            flex={1}
            placeholder="Search fields"
            onChange={onSearch}
          />
          <MediumOutlineButton ml={4} onClick={onAddFieldGroup}>
            Create field group
          </MediumOutlineButton>
          <MediumFilledButton bg="altB5" ml={1} onClick={onAddField}>
            Create field
          </MediumFilledButton>
        </Div>
      </div>

      <div styleName="fieldGroups">
        {fields.map(f => {
          return (
            <CollapsablePanel
              heading={
                <div styleName="fieldGroupTitle">
                  <div styleName="fieldGroupName">{f.name}</div>
                  <div styleName="fieldGroupCount">
                    {f.countOfFields} field{addS(f.countOfFields)}
                  </div>
                </div>
              }
              right={false}
              shadow={1}
              mb={2}
              width={1}
              display="column.flex-start.stretch"
              bg="white"
              arrowAlignment="top"
              arrowIcon={ArrowIcon}
              arrowColor="black"
              bra={1}
              collapsedDefault
              styleName="fieldGroup"
            >
              <Fields draggable cards={f.fields} onReorder={onReorder} />
            </CollapsablePanel>
          );
        })}
      </div>
    </div>
  );
};

export default decorate(FieldsList);
