import React, { Component } from "react";
import toString from "utils/value-types/to-string/calculated-number";
import * as R from "ramda";

import { getMeta, getRow, getSettings } from "ui-kit/Datagrid/utils";

export default class CaluclatedNumberFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props.value, nextProps.value);
  }

  render() {
    const props = this.props;
    const columns = getMeta(props, "columns");
    const row = getRow(props);
    const settings = getSettings(props);

    const result = toString(null, {
      ...settings,
      values: row, // @TODO: check if values is expecting row
      fields: columns
    });

    return <div>{result}</div>;
  }
}
