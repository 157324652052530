import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import { actions } from "./index";
import { registerError } from "redux/modules/errors/actions";

import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { showSnackbar } from "redux/modules/snackbar/actions";

import Api from "./api";

const init = function*({
  payload: { type, moduleId, viewId, recordTypeId, itemTypeId, formId }
}) {
  try {
    const credentials = yield select(getCredentials);
    const eventId = yield select(getEventId);

    const { payload } = yield call(Api.post, credentials, {
      eventId,
      type,
      moduleId,
      viewId,
      recordTypeId,
      itemTypeId,
      formId
    });

    yield put(actions.setSubscriptionUrl(payload.url));
    yield put(actions.setSaving(false));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred adding subscription"
          }
        ])
      )
    ]);
  }
};

const copySubscriptionUrl = function*({ payload: ref }) {
  if (ref.current && ref.current.select) {
    ref.current.select();

    try {
      document.execCommand("copy");
      ref.current.blur();
      yield put(showSnackbar({ message: "Link Copied", action: "OK" }));
    } catch (err) {
      alert("Please press Ctrl/Cmd+C to copy.");
    }
  }
};

const clickInput = function*({ payload: { ref, event } }) {
  if (ref.current) {
    ref.current.setSelectionRange(0, event.target.value.length);
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchCopySubscriptionUrl = function*() {
  yield takeEvery(actions.copySubscriptionUrl.type, copySubscriptionUrl);
};

const watchClickInput = function*() {
  yield takeEvery(actions.clickInput.type, clickInput);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchCopySubscriptionUrl),
    fork(watchClickInput)
  ]);
};

export default rootSaga;
