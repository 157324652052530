import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";

import * as R from "ramda";

import { actions } from "./index";

import { registerError } from "redux/modules/errors/actions";
import { getCredentials } from "redux/modules/user/selectors";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { navigateTo } from "utils/General";
import api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const eventId = yield select(getEventId);

  return {
    credentials,
    orgId,
    eventId
  };
};

const init = function*() {
  try {
    yield put(actions.setLoading(true));
    const { credentials, eventId } = yield call(getParams);

    const { payload } = yield call(api.getDashboardItems, {
      credentials,
      eventId
    });

    yield put(actions.setInitialData({ data: payload }));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading inventory items"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const updatePlannedQty = function*({ payload: { inventoryQuantity, id } }) {
  try {
    const { credentials, eventId } = yield call(getParams);

    yield call(api.updateVariant, {
      credentials,
      data: {
        variant: {
          id,
          inventoryQuantity,
          trackInventory: true
        }
      }
    });

    const { payload } = yield call(api.getDashboardItems, {
      credentials,
      eventId
    });

    yield put(actions.setInitialData({ data: payload }));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error ocurred updating planned"
          }
        ])
      )
    ]);
  }
};

const refetchData = function*({ payload: { stats } }) {
  try {
    yield put(actions.setLoading(true));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error fetching data"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const updateCountPlanning = function*({ payload: { id } }) {
  try {
    yield put(actions.setLoading(true));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error updating data"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.refetchData({ stats: true }));
  }
};

const downloadReport = function*({ payload: url }) {
  const credentials = yield select(getCredentials);

  try {
    const result = yield call(api.export, {
      url,
      credentials
    });
    yield call(navigateTo, result.payload.url);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred exporting data"
        }
      ])
    );
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchUpdateCountPlanning = function*() {
  yield takeEvery(actions.updateCountPlanning.type, updateCountPlanning);
};

const watchDownloadReport = function*() {
  yield takeEvery(actions.downloadReport.type, downloadReport);
};

const wathcRefetchData = function*() {
  yield takeEvery(actions.refetchData.type, refetchData);
};

const watchUpdatePlannedQty = function*() {
  yield takeEvery(actions.updatePlannedQty.type, updatePlannedQty);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchUpdateCountPlanning),
    fork(watchDownloadReport),
    fork(wathcRefetchData),
    fork(watchUpdatePlannedQty)
  ]);
};

export default rootSaga;
