import React from "react";
import { connect } from "react-redux";

import { getters } from "Organizations/EditEventModal";

import {
  Div,
  SuperFilledButton,
  LoadingIcon,
  OpenInNewIcon
} from "components/Base";

import { hideModal } from "redux/modules/modal/actions";

const decorate = connect(
  state => ({
    savingLoading: getters.savingLoading(state),
    isDraft: getters.isDraft(state),
    hasAccess: getters.hasAccess(state),
    eventId: getters.eventId(state)
  }),
  {
    hideModal
  }
);

const Footer = ({ hideModal, savingLoading, hasAccess, isDraft, eventId }) => (
  <Div
    width={1}
    height={106}
    display="row.space-between.center"
    p={3}
    bc="neutral2"
    bt={1}
  >
    <Div display="row.flex-start.center">
      <SuperFilledButton
        ml={3}
        width={250}
        bg="altB5"
        onClick={hideModal}
        disabled={savingLoading}
      >
        Done
      </SuperFilledButton>
    </Div>
    {savingLoading ? (
      <LoadingIcon size={30} mr={2} />
    ) : hasAccess && isDraft === false ? (
      <Div
        onClick={() =>
          window.open(`/event-light/${eventId}/dashboard`, "_blank")
        }
        color={{
          default: "purple8",
          hover: "purple9"
        }}
        display="row.flex-start.center"
        fs={4}
        fw={3}
        mr={3}
      >
        <OpenInNewIcon mr={2} color="purple8" />
        Manage Event
      </Div>
    ) : null}
  </Div>
);

export default decorate(Footer);
