import SalesPortal from "SalesPortal/Home/View";
import EventInfo from "SalesPortal/Home/View/EventInfo";
import Cart from "SalesPortal/Home/View/Cart";
import Checkout from "SalesPortal/Home/View/Checkout";
import Confirmation from "SalesPortal/Home/View/Confirmation";

import { hot } from "App/Config/hot";

const Platform = { SalesPortal, EventInfo, Cart, Checkout, Confirmation };

export default hot(Platform);
