import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE, TABS } from "./constants";
const indexedMap = R.addIndex(R.map);

const iniState = {
  loading: false,
  activeTab: TABS.BASIC_DETAILS,
  venues: [],
  eventTags: [],
  sessionId: null,
  editing: false,
  isVideoContent: false,
  data: {
    is_public: true,
    speakers: [],
    sponsors: [],
    related_content: [],
    location: null,
    location_stage_id: null,
    location_room_id: null,
    location_booth_id: null,
    location_external_url: null,
    //
    title: "",
    starts_at: null,
    ends_at: null,
    description: "",
    venue_id: null,
    is_online_session: false,
    background_image_url: null,
    require_rsvp: false,
    rsvp_is_closed: false,
    rsvp_starts_at: null,
    rsvp_ends_at: null,
    rsvp_requires_approval: false,
    enable_capacity: null,
    capacity: null,
    enable_waitlist: false,
    waitlist_capacity: null,
    send_reminders: false,
    video_url: null,
    video_thumbnail_url: null,
    video_title: null,
    video_restrict_access: null,
    enable_question_queue: null,
    conference_url: null,
    conference_thumbnail_url: null,
    conference_title: null,
    conference_restrict_access: null,
    reveal_minutes_before: null,
    transcript_url: null,
    replay_url: null,
    play_recorded_video_content: null
  }
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    saveAsDraft: R.identity,
    nextPage: R.identity,
    previousPage: R.identity,
    uploadHeaderImg: R.identity,
    save: R.identity,
    showContentModal: R.identity,
    setInitialData: (
      { data },
      { payload: { venues, eventTags, sessionId, sessionData, editing } }
    ) => {
      return {
        editing,
        sessionId,
        venues,
        eventTags,
        data: sessionData
          ? {
              ...sessionData,
              tags: R.map(t => ({
                value: t.id,
                label: t.name
              }))(sessionData.tags),
              speakers: R.map(t => ({
                value: t.id,
                label: t.name
              }))(sessionData.speakers),
              sponsors: R.map(t => ({
                value: t.id,
                label: t.name
              }))(sessionData.sponsors),
              related_content: sessionData.related_content
            }
          : data
      };
    },
    reorderContent: (state, { payload: content }) => ({
      data: {
        ...state.data,
        related_content: indexedMap((c, order) => ({ ...c, order }), content)
      }
    }),
    addContent: (state, { payload: content }) => {
      const exists = state.data.related_content.some(c => c.id === content.id);

      if (exists) {
        return {};
      }

      return {
        data: {
          ...state.data,
          related_content: [...state.data.related_content, content]
        }
      };
    },
    addContentVideo: (state, { payload: content }) => ({
      data: {
        ...state.data,
        play_recorded_video_content: content
      }
    }),
    removeContentVideo: state => ({
      data: {
        ...state.data,
        play_recorded_video_content: null
      }
    }),
    removeContent: (state, { payload: contentId }) => ({
      data: {
        ...state.data,
        related_content: state.data.related_content.filter(
          c => c.id !== contentId
        )
      }
    }),
    updateField: (state, { payload: { field, value } }) => ({
      data: R.assoc(field, value, state.data)
    }),
    toggleOnlineSession: state => ({
      data: R.assoc(
        "is_online_session",
        R.not(R.pathOr(false, ["data", "is_online_session"], state)),
        state.data
      )
    }),
    toggleRequireRsvp: state => ({
      data: R.assoc(
        "require_rsvp",
        R.not(R.pathOr(false, ["data", "require_rsvp"], state)),
        state.data
      )
    }),
    toggleRemindAttendees: state => ({
      data: R.assoc(
        "send_reminders",
        R.not(R.pathOr(false, ["data", "send_reminders"], state)),
        state.data
      )
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
