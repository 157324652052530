import React, { PureComponent } from "react";
import { debounce } from "lodash";
import ViewRecordModal from "components/Global/Module/Modals/ViewRecord";
import LinkedRecordsModal from "components/Global/CRM/Modals/LinkedRecords";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import CreateOrderModal from "Orders/CreateOrderModal";
import ViewOrderModal from "Orders/OrderModal/View";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import Fuse from "fuse.js";
import { isReviewed } from "components/Global/Approvals/utils/approvals-helpers";
import {
  any,
  compose,
  reject,
  head,
  take,
  isNil,
  isEmpty,
  map,
  find,
  propEq,
  flatten,
  filter,
  pathOr
} from "ramda";

const searchWithFuse = (searchTerm, fuse, opt, matchWith, key) => {
  if (isNil(searchTerm) || isEmpty(searchTerm)) {
    return reject(r => isEmpty(r[key]))(opt);
  }
  return compose(
    reject(r => isEmpty(r[key])),
    map(o => ({
      ...o,
      [key]: compose(
        reject(isNil),
        map(l => find(propEq(matchWith, l.id), o[key]))
      )(fuse.search(searchTerm))
    }))
  )(opt);
};

function orderReportController(Comp, searchScope, searchMapper = i => i) {
  class OrderReports extends PureComponent {
    state = { searchTerm: undefined };

    componentDidMount() {
      this.getReport();
      this.search = debounce(this.handleSearchChange, 500);
      this.setupFuse(this.props.report);
    }

    componentDidUpdate(prevProps) {
      if (prevProps.report !== this.props.report) {
        this.setupFuse(this.props.report);
      }
    }

    componentWillUnmount() {
      this.search.cancel();
    }

    setupFuse = report => {
      this.fuse = new Fuse(
        compose(
          flatten,
          map(i => i[searchScope].map(l => searchMapper(l, i)))
        )(report),
        {
          threshold: 0.3,
          keys: ["accountName", "contactName", "assignedTo", "other"],
          shouldSort: true
        }
      );
    };

    handleSearchChange = searchTerm => {
      this.setState({ searchTerm });
    };

    getReport = async () => {
      return await this.props.getReport({
        reportType: this.props.reportType,
        options: {
          eventId: this.props.eventId,
          resourceIds: this.props.itemType
        }
      });
    };

    approveLineItem = async lineItemId => {
      await this.props.createReview({
        review: {
          lineItemId,
          response: "approve",
          userId: this.props.user.id
        }
      });

      return this.getReport();
    };

    rejectLineItem = async lineItemId => {
      await this.props.createReview({
        review: {
          lineItemId,
          response: "reject",
          userId: this.props.user.id
        }
      });

      return this.getReport();
    };

    removeApproval = async lineItemId => {
      await this.props.removeReview({
        review: {
          lineItemId,
          userId: this.props.user.id
        }
      });

      return this.getReport();
    };

    goToContact = contactId => {
      this.showRecordModal(STANDARD_MODULE_IDS.contacts.id, contactId);
    };

    goToAccount = accountId => {
      this.showRecordModal(STANDARD_MODULE_IDS.accounts.id, accountId);
    };

    showRecordModal = (moduleId, recordId, onClose) => {
      const { showModal, hideModal } = this.props;
      showModal({
        content: (
          <ViewRecordModal
            moduleId={moduleId}
            recordId={recordId}
            onClose={() => {
              if (onClose) {
                onClose();
              }
              hideModal();
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    };

    approveLineItems = lineItems => {
      return this.bulkReview(lineItems, "approve");
    };

    rejectLineItems = lineItems => {
      return this.bulkReview(lineItems, "reject");
    };

    bulkReview = async (lineItems, response) => {
      await this.props.createReviews({
        reviews: lineItems.map(li => ({
          lineItemId: li.id,
          manifestId: li.approval_manifest.id,
          userId: this.props.user.id,
          response
        }))
      });
      return this.getReport();
    };

    getRemainingApprovals(lineItems, userId) {
      return compose(
        filter(li => {
          const userApprover = find(
            propEq("user_id", userId),
            pathOr([], ["approval_manifest", "all_approvers"])(li)
          );
          return userApprover && !userApprover.reviewed_at && !isReviewed(li);
        })
      )(lineItems);
    }

    isUserApprover = lineItems => {
      compose(
        any(propEq("user_id", this.props.user.id)),
        flatten,
        map(pathOr([], ["approval_manifest", "all_approvers"]))
      )(lineItems);
    };

    approveRemaining = lineItems => {
      return this.bulkReview(
        this.getRemainingApprovals(lineItems, this.props.user.id),
        "approve"
      );
    };

    denyRemaining = lineItems => {
      return this.bulkReview(
        this.getRemainingApprovals(lineItems, this.props.user.id),
        "reject"
      );
    };

    addAssignments = async (lineItemIds, contactId) => {
      await Promise.all(
        lineItemIds.map(lineItemId =>
          this.props.assignLineItem({ lineItemId, contactId })
        )
      );
      return this.getReport();
    };

    removeAssignments = async assignmentIds => {
      await Promise.all(
        assignmentIds.map(id =>
          this.props.deleteLineItemAssignment({ assignmentId: id })
        )
      );
      return this.getReport();
    };

    removeAssignment = async assignmentId => {
      await this.props.deleteLineItemAssignment({ assignmentId });
      return this.getReport();
    };

    showLinkedRecordsModal = (countToAssign, availableIds) => {
      this.props.showModal({
        content: (
          <LinkedRecordsModal
            moduleId=""
            fieldId=""
            linkedModuleId={STANDARD_MODULE_IDS.contacts.id}
            linkedFieldId="full_name"
            linkedRecordIds={[]}
            onSave={compose(
              contactId =>
                this.addAssignments(
                  availableIds.splice(0, countToAssign),
                  contactId
                ),
              head,
              take(1),
              pathOr([], ["value", "records"])
            )}
          />
        ),
        wrapper: ModalWrapper
      });
    };

    showAddOrderModal = () => {
      this.props.showModal({
        content: <CreateOrderModal onDone={this.getReport} />,
        wrapper: ModalWrapper
      });
    };

    showViewOrderModal = orderId => {
      this.props.showModal({
        content: (
          <ViewOrderModal
            orderId={orderId}
            hideModal={() => {
              this.props.hideModal();
              return this.getReport();
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    };
    render() {
      // pull out props Comp doesn't need
      const {
        report,
        eventId,
        hideModal,
        showModal,
        createReview,
        createReviews,
        removeReview,
        removeReviews,
        showViewOrderModal,
        getReport,
        ...props
      } = this.props;
      const localProps = {
        approveLineItem: this.approveLineItem,
        approveLineItems: this.approveLineItems, // @TODO
        approveRemaining: this.approveRemaining,
        countOfRemainingReviews: lineItems =>
          this.getRemainingApprovals(lineItems, this.props.user.id).length,
        denyRemaining: this.denyRemaining,
        getReport: this.getReport,
        goToAccount: this.goToAccount,
        goToContact: this.goToContact,
        goToOrder: this.showViewOrderModal,
        isUserApprover: this.isUserApprover,
        onSearch: this.search,
        rejectLineItem: this.rejectLineItem,
        rejectLineItems: this.rejectLineItems, // @TODO
        removeApproval: this.removeApproval,
        removeAssignment: this.removeAssignment,
        removeAssignments: this.removeAssignments,
        showLinkedRecordsModal: this.showLinkedRecordsModal,
        searchTerm: this.state.searchTerm,
        showRecordModal: this.showRecordModal,
        showAddOrderModal: this.showAddOrderModal,
        onSearch: this.search
      };

      const filteredReport = searchWithFuse(
        this.state.searchTerm,
        this.fuse,
        report,
        "id",
        searchScope
      );

      return <Comp {...props} {...localProps} report={filteredReport} />;
    }
  }
  return OrderReports;
}

export default orderReportController;
