import React, { Component } from "react";
import View from "./View";
import { CREDENTIAL_TYPE_ID, MEAL_TYPE_ID } from "utils/item-types";
import * as R from "ramda";
import moment from "moment";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import ItemDetailsModal from "Orders/ItemDetailsModal/View";

const getDayColor = (dateGroups, day) => {
  const group = dateGroups.filter(g => g.days.includes(day));
  return group.length ? group[0].color : "#eee";
};

const constructState = props => {
  const selectedVariantPrices = {};

  // populate default prices
  props.variants.forEach(variant => {
    const priceId = variant.prices.length
      ? R.compose(
          price => (price ? price.id : null),
          R.find(R.propEq("is_default", true)),
          R.filter(R.prop("is_enabled"))
        )(variant.prices)
      : null;

    if (priceId) {
      selectedVariantPrices[variant.id] = priceId;
    }
  });

  return {
    saving: false,
    loading: true,
    searchTerm: "",
    activeItemTypeId: R.compose(
      R.head,
      R.filter(typeId => props.canUserAddItemType(typeId))
    )([CREDENTIAL_TYPE_ID, MEAL_TYPE_ID]),
    selectedVariants: {},
    selectedVariantPrices,
    selectedVariantQuestions: {}
  };
};

class Controller extends Component {
  constructor(props) {
    super(props);
    this.state = constructState(props);
  }

  async componentDidMount() {
    await this.props.getItemTypesByEvent(this.props.params.eventId);
    this.setState({ loading: false });
  }

  componentDidUpdate(oldProps) {
    if (oldProps.variants.length !== this.props.variants.length) {
      this.setState(constructState(this.props));
    }
  }

  get countOfSelectedVariants() {
    return Object.keys(this.state.selectedVariants).reduce(
      (a, id) => (a += this.state.selectedVariants[id]),
      0
    );
  }

  handleSave = async () => {
    if (this.state.saving) return false;

    this.setState({
      saving: true
    });

    const lineItems = R.compose(
      R.flatten,
      R.map(variantId => {
        let price = 0;
        const selectedPriceId = this.state.selectedVariantPrices[variantId];
        const quantity = this.state.selectedVariants[variantId];
        const questions = this.state.selectedVariantQuestions[variantId];

        if (selectedPriceId) {
          price = R.compose(
            R.propOr(0, "price"),
            R.find(R.propEq("id", this.state.selectedVariantPrices[variantId])),
            R.prop("prices"),
            R.find(R.propEq("id", variantId))
          )(this.props.variants);
        }

        return R.times(
          () => ({
            variantId,
            quantity: 1,
            currency: null,
            price,
            questions
          }),
          quantity
        );
      }),
      R.keys
    )(this.state.selectedVariants);

    await this.props.addLineItems({
      orderId: this.props.orderId,
      lineItems
    });

    if (this.props.onDone) {
      this.props.onDone();
    }

    this.props.hideModal();
  };

  onItemTypeSearchChange = searchTerm =>
    this.setState({
      searchTerm
    });

  onChangeItemType = activeItemTypeId => {
    this.setState({
      activeItemTypeId,
      searchTerm: ""
    });
  };

  onChangeShippingField = (fieldId, value) =>
    this.setState(state => {
      state[fieldId] = value;
      return state;
    });

  bulkUpdateVariantQuantity = (ids, quantity) =>
    this.setState(state => {
      for (const id of ids) {
        if (!quantity || quantity <= 0) {
          delete state.selectedVariants[id];
        } else {
          state.selectedVariants = {
            ...state.selectedVariants,
            [id]: quantity <= 0 ? 0 : quantity
          };
        }
      }

      return state;
    });

  updateVariantQuantity = (id, quantity) =>
    this.setState(state => {
      if (!quantity || quantity <= 0) {
        delete state.selectedVariants[id];
        return state;
      }

      state.selectedVariants = {
        ...state.selectedVariants,
        [id]: quantity <= 0 ? 0 : quantity
      };
      return state;
    });

  updateVariantPriceId = (id, priceId) =>
    this.setState(state => {
      state.selectedVariantPrices = {
        ...state.selectedVariantPrices,
        [id]: priceId
      };
      return state;
    });

  showItemDetailsModal = ({ itemId, variantId }) => {
    this.props.showModal({
      content: (
        <ItemDetailsModal
          itemId={itemId}
          variantId={variantId}
          onSave={this.handleSelectedItemDetails}
          showInternalQuestions={true}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  handleSelectedItemDetails = variantsWithDetails => {
    this.setState(state => {
      Object.keys(variantsWithDetails).forEach(variantId => {
        state.selectedVariants = {
          ...state.selectedVariants,
          [variantId]: variantsWithDetails[variantId].quantity
        };
        state.selectedVariantPrices = {
          ...state.selectedVariantPrices,
          [variantId]: variantsWithDetails[variantId].priceId
        };
        state.selectedVariantQuestions = {
          ...state.selectedVariantQuestions,
          [variantId]: variantsWithDetails[variantId].questions
        };
      });
      return state;
    });
  };

  render() {
    const {
      loading,
      saving,
      searchTerm,
      activeItemTypeId,
      selectedVariants,
      selectedVariantPrices
    } = this.state;
    const {
      canUserAddItemType,
      hideModal,
      eventDetails,
      itemGroups,
      types
    } = this.props;

    const hasItemGroups = Boolean(itemGroups.length);
    const hasSearchTerm = Boolean(searchTerm.length);
    const lowercaseSearchTerm = hasSearchTerm
      ? searchTerm.toLowerCase().trim()
      : "";

    let itemTypes = R.compose(
      R.map(type => ({
        id: type.id,
        name: type.name,
        active: activeItemTypeId === type.id,
        onClick: () => this.onChangeItemType(type.id)
      })),
      R.filter(type => canUserAddItemType(type.id))
    )(types);

    const getItemsForTypeId = typeId =>
      hasItemGroups
        ? R.compose(
            R.filter(g => g.items.length),
            groups =>
              hasSearchTerm
                ? R.map(group => ({
                    ...group,
                    items: group.items.filter(item =>
                      item.name.toLowerCase().includes(lowercaseSearchTerm)
                    )
                  }))(groups)
                : groups,
            R.map(group => ({
              ...group,
              items: R.reduce((list, item) => {
                const isSingleVariant = item.variants.length === 1;
                const variant = item.variants[0];
                const variantId = variant.id;
                list.push({
                  id: variantId,
                  name: isSingleVariant ? variant.display_name : item.name,
                  color: item.background_color || "#ccc",
                  qty:
                    selectedVariants[variantId] >= 0
                      ? selectedVariants[variantId]
                      : 0,
                  description: item.description,
                  providerId: variant.provider_id,
                  prices: variant.prices,
                  selectedPriceId: selectedVariantPrices[variantId],
                  showItemDetailsModal:
                    !isSingleVariant ||
                    (variant.questions && variant.questions.length)
                      ? () => {
                          this.showItemDetailsModal(
                            isSingleVariant
                              ? {
                                  variantId: variant.id
                                }
                              : {
                                  itemId: item.id
                                }
                          );
                        }
                      : undefined,
                  updateVariantPriceId: priceId =>
                    this.updateVariantPriceId(variantId, priceId),
                  updateVariantQuantity: qty =>
                    this.updateVariantQuantity(
                      variantId,
                      parseInt(qty, 10) || 0
                    )
                });
                return list;
              }, [])(group.items)
            })),
            R.filter(g => g.type_id === typeId)
          )(itemGroups)
        : [];

    const itemTypeProps = !hasItemGroups
      ? {}
      : R.reduce((map, type) => {
          if (type.id !== MEAL_TYPE_ID) {
            map[type.id] = {
              mode: "quantity",
              itemGroups: getItemsForTypeId(type.id)
            };
          }
          return map;
        }, {})(types);

    const dayGroups = R.map(group => ({
      id: group.id,
      name: group.name,
      color: group.color
    }))(eventDetails.date_groups);

    const selectedCateringDays = R.pathOr(
      [],
      ["module_settings", "catering", "selected_days"]
    )(eventDetails);

    const mealDays = R.pathOr([], ["module_settings", "catering", "meal_days"])(
      eventDetails
    );

    const mealsWithHandlers = hasItemGroups
      ? R.compose(
          R.filter(meal => meal.validVariantIds.length),
          meals =>
            hasSearchTerm
              ? meals.filter(meal =>
                  meal.name.toLowerCase().includes(lowercaseSearchTerm)
                )
              : meals,
          R.map(({ id, name, variants }) => {
            const validVariantIds = R.compose(
              R.map(R.prop("id")),
              R.filter(v =>
                v.rules.find(
                  r =>
                    r.pattern === "is_valid_for" &&
                    selectedCateringDays.includes(r.value) &&
                    mealDays.filter(d => d.mealId === id && d.dayId === r.value)
                      .length
                )
              )
            )(variants);

            return {
              id,
              name,
              variants,
              validVariantIds,
              selectAllItems: () =>
                this.bulkUpdateVariantQuantity(validVariantIds, 1),
              deselectAllItems: () =>
                this.bulkUpdateVariantQuantity(validVariantIds, 0)
            };
          }),
          R.propOr([], "items"),
          R.head,
          R.filter(g => g.type_id === MEAL_TYPE_ID)
        )(itemGroups)
      : [];

    const mealsByDay = R.groupBy(R.prop("dayId"))(mealDays);

    const getMealItemVariantByMealDay = (itemId, day) => {
      if (!hasItemGroups) return null;

      const mealVariants = R.compose(
        R.prop("variants"),
        R.find(R.propEq("id", itemId))
      )(mealsWithHandlers);

      if (!mealVariants || !mealVariants.length) return null;

      return R.find(v =>
        Boolean(
          v.rules.find(r => r.pattern === "is_valid_for" && r.value === day)
        )
      )(mealVariants);
    };

    const mealDaysWithHandlers = R.compose(
      R.sortBy(R.prop("id")),
      R.map(day => {
        const dayInstance = moment(day);
        return {
          id: day,
          dayName: dayInstance.format("ddd"),
          dayNumber: dayInstance.format("D"),
          color: getDayColor(eventDetails.date_groups, day),
          meals: R.compose(
            R.reduce((mealMap, mealId) => {
              const variant = getMealItemVariantByMealDay(mealId, day);
              if (variant) {
                mealMap[mealId] = {
                  show: true,
                  qty:
                    selectedVariants[variant.id] >= 0
                      ? selectedVariants[variant.id]
                      : 0,
                  updateVariantQuantity: qty =>
                    this.updateVariantQuantity(
                      variant.id,
                      parseInt(qty, 10) || 0
                    )
                };
              }
              return mealMap;
            }, {}),
            R.map(R.prop("mealId")),
            R.propOr([], day)
          )(mealsByDay)
        };
      })
    )(selectedCateringDays);

    itemTypeProps[MEAL_TYPE_ID] = {
      mode: "quantity",
      dayGroups,
      meals: mealsWithHandlers,
      days: mealDaysWithHandlers
    };

    itemTypes = itemTypes.filter(t => {
      if (t.id === MEAL_TYPE_ID) {
        return itemTypeProps[t.id].meals.length;
      }
      return R.compose(
        R.length,
        R.flatten,
        R.map(R.prop("items"))
      )(itemTypeProps[t.id].itemGroups);
    });

    const countOfSelectedItems = this.countOfSelectedVariants;

    const isValidStep = Boolean(countOfSelectedItems);

    return (
      <View
        {...{
          onSave: this.handleSave,
          onItemTypeSearchChange: this.onItemTypeSearchChange,
          //
          loading,
          saving,
          hideModal,
          itemTypes,
          activeItemTypeId,
          itemTypeProps,
          isValidStep,
          countOfSelectedItems
        }}
      />
    );
  }
}

export default Controller;
