import React from "react";
import { Div } from "components/Base";
/*
import {
  CONTENT_WIDTH,
  LEFT_COLUMN_WIDTH,
  RIGHT_COLUMN_WIDTH
} from "OrgLight/Dashboard/constants";
*/
// import AppsContainer from "./AppsContainer";
// import MyTasks from "./MyTasks";
import EventsList from "./EventsList";
// import Stats from "./Stats";

const Body = () => (
  <Div
    pt={5}
    pb={5}
    pl={5}
    pr={5}
    width={1}
    display="row.space-between.flex-start"
    style={{
      margin: "0 auto"
    }}
  >
    <Div width={1}>
      {/* <Stats /> */}
      <EventsList />
      {/* <MyTasks /> */}
    </Div>
    {/*
      <Div width={LEFT_COLUMN_WIDTH}>
        <AppsContainer />
      </Div>
      */}
  </Div>
);

export default Body;
