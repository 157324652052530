import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function exportReport({ reportId, options }) {
  return async (dispatch, getState) => {
    try {
      const data = await api.export(
        getState().user.user.credentials,
        reportId,
        options
      );
      return data;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred exporting report"
          }
        ])
      );
    }
  };
}

export function printReport({ reportId, options }) {
  return async (dispatch, getState) => {
    try {
      const data = await api.print(
        getState().user.user.credentials,
        reportId,
        options
      );
      return data;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred printing report"
          }
        ])
      );
    }
  };
}
