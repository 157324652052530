import React, { Component } from "react";

class StripeScriptLoader extends Component {
  state = { stripe: false };

  componentDidMount() {
    if (typeof Stripe === "undefined") {
      const s = document.createElement("script");
      s.src = "https://js.stripe.com/v3/";
      s.type = "text/javascript";
      // IE triggers this event when the file is loaded
      if (s.attachEvent) {
        s.attachEvent("onreadystatechange", () => {
          if (s.readyState === "complete" || s.readyState === "loaded")
            this.initStripe();
        });
      }

      // Other browsers trigger this one
      if (s.addEventListener)
        s.addEventListener("load", this.initStripe, false);
      this.instance.appendChild(s);
    } else {
      this.initStripe();
    }
  }

  initStripe = () => {
    this.setState({ stripe: typeof Stripe !== "undefined" });
  };

  render() {
    return (
      <span
        ref={ref => {
          this.instance = ref;
        }}
      >
        {!this.state.stripe ? "" : this.props.children}
      </span>
    );
  }
}

export default StripeScriptLoader;
