/* eslint-disable no-underscore-dangle */
import React, { Fragment, Component } from "react";
import { defaultProps, addS } from "utils/General";
import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { MODES, DEFAULT_PAGE_SIZE } from "../../../constants";
import { getters, actions } from "SendEmailModal";
import Loading from "ui-kit/Loading";
import { IFrame } from "./BuildIframe";
import PaginationBar from "./PaginationBar";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import {
  Div,
  OpenInNewIcon,
  ErrorIcon,
  CheckCircleIcon,
  SimpleTable,
  Radio,
  RefreshIcon,
  LoadingIcon
} from "components/Base";

import { getIsLightEvent } from "redux/modules/event/selectors";
import { getIsLightOrg } from "redux/modules/organization/selectors";
import { getTotalCount } from "../../../selectors";

const decorate = R.compose(
  withRouter,
  connect(
    state => ({
      isLightEvent: getIsLightEvent(state),
      isLightOrg: getIsLightOrg(state),
      loading: getters.generatingPreviews(state),
      preview: getters.preview(state),
      pagination: getters.pagination(state),
      totalCount: getTotalCount(state)
    }),
    {
      getPreview: actions.getPreview
    }
  )
);

const makeRecipientsTable = mode =>
  SimpleTable({
    columnProps: [
      [5 / 12, "left"],
      [6 / 12, "left"],
      [1 / 12, "right"]
    ],
    headerCellComps: [
      "Name",
      mode === MODES.SMS ? "Phone Number" : "Email",
      "Action"
    ],
    HeaderCellStyler: defaultProps({
      color: "gray7",
      px: 2,
      fs: 1
    })(Div),
    HeaderStyler: defaultProps({
      display: "row.flex-start.center",
      pb: 2,
      bc: "neutral1",
      bb: 1
    })(Div),
    RowStyler: ({ openPreview, goToProfile, ...props }) => (
      <Div
        {...{
          display: "row.flex-start.center",
          bg: { default: "white", hover: "neutral0" },
          transition: "fast",
          py: 2,
          bra: 0,
          onClick: openPreview || goToProfile,
          openPreview,
          goToProfile,
          ...props
        }}
      />
    ),
    TableStyler: defaultProps({
      mt: 0
    })(Div),
    rowCellComps: [
      ({ meta }) => (
        <Div fs={1} color="gray7" display="row.flex-start.center">
          {meta.name}
        </Div>
      ),
      ({ meta, goToProfile }) => (
        <Div
          fs={1}
          color="gray7"
          display="row.flex-start.center"
          style={{
            overflowWrap: "anywhere"
          }}
        >
          {goToProfile ? (
            <ErrorIcon
              color="red5"
              mr={
                mode === MODES.SMS
                  ? meta.phone && meta.phone.length
                    ? 1
                    : 0
                  : meta.email && meta.email.length
                  ? 1
                  : 0
              }
            />
          ) : null}
          {mode === MODES.SMS ? meta.phone : meta.email}
        </Div>
      ),

      ({ selected, openPreview }) => (
        <Div>
          {openPreview ? (
            <Radio selected={selected} size={20} />
          ) : (
            <Div display="row.flex-start.center">
              <Div underline color="gray7" fs={1} mr={1}>
                Resolve
              </Div>
              <OpenInNewIcon size={13} />
            </Div>
          )}
        </Div>
      )
    ]
  });

class Review extends Component {
  openPreview = record => {
    this.props.getPreview({
      parentRecordId: R.path(["meta", "recordIds", 0])(record),
      childRecordId: R.path(["meta", "id"])(record)
    });

    /*
    this.props.setPreview({
      id,
      subject: variables.subject,
      phone: variables.recipient_phone,
      email: variables.recipient_email,
      name: variables.recipient_name,
      content
    });
    */
  };

  goToProfile = id => {
    if (this.props.params.eventId) {
      // open: event
      if (this.props.isLightEvent) {
        window.open(
          `${window.__LENND_APP_URL__}/event-light/${this.props.params.eventId}/contact/${id}`,
          "_blank"
        );
        return true;
      }
      window.open(
        `${window.__LENND_APP_URL__}/event/${this.props.params.eventId}/contact/${id}`,
        "_blank"
      );
    } else {
      // open: org
      if (this.props.isLightOrg) {
        window.open(
          `${window.__LENND_APP_URL__}/org-light/${this.props.params.orgId}/contact/${id}`,
          "_blank"
        );
        return true;
      }
      window.open(
        `${window.__LENND_APP_URL__}/organization/${this.props.params.orgId}/contact/${id}`,
        "_blank"
      );
    }
    return true;
  };

  render() {
    const { recipients, mode, loading, moduleId } = this.props;

    const validFilter =
      mode === MODES.SMS
        ? r => r.meta.hasValidPhone
        : r => r.meta.hasValidEmail;
    const invalidFilter =
      mode === MODES.SMS
        ? r => !r.meta.hasValidPhone
        : r => !r.meta.hasValidEmail;

    const validRecipients = recipients.filter(validFilter).map(r => ({
      ...r,
      selected: this.props.preview
        ? this.props.preview.id === r.meta.id
        : false,
      openPreview: () => this.openPreview(r)
    }));

    const invalidRecipients = recipients.filter(invalidFilter).map(r => ({
      ...r,
      goToProfile: () => this.goToProfile(r.meta.id)
    }));

    const countOfValidRecipients = validRecipients.length;
    const countOfInvalidRecipients = invalidRecipients.length;

    const RecipientsTable = makeRecipientsTable(mode);

    const canViewNextPage =
      this.props.pagination.page < this.props.pagination.pages - 1;

    const notice =
      this.props.totalCount > DEFAULT_PAGE_SIZE ? (
        <Div
          bg="neutral2"
          mt={1}
          display="row.center.center"
          bra={1}
          fs={1}
          color="gray6"
          mb={5}
          py={1}
        >
          {this.props.totalCount}{" "}
          {moduleId === STANDARD_MODULE_IDS.orders.id
            ? "orders"
            : moduleId === STANDARD_MODULE_IDS.accounts.id
            ? "groups"
            : "people"}{" "}
          selected
          {canViewNextPage ? <> - Go to next page to view more</> : ""}
        </Div>
      ) : null;

    return (
      <Div display="row" height={1}>
        <Div
          width={410}
          height={1}
          br={1}
          p={5}
          bc="neutral0"
          style={{
            maxHeight: "100%",
            overflowY: "auto"
          }}
        >
          <Div fs={4} fw={4} color="purple9" mb={5}>
            Review &amp; Preview
          </Div>

          {countOfValidRecipients ? (
            <Div mb={9}>
              <Div
                display="row.flex-start.center"
                color="gray7"
                mb={this.props.totalCount > DEFAULT_PAGE_SIZE ? 0 : 5}
              >
                <CheckCircleIcon color="green5" mr={1} />
                <Div fw={4} mr={1}>
                  {countOfValidRecipients}{" "}
                  {countOfValidRecipients !== 1 ? "people" : "person"}
                </Div>
                on this page will receive{" "}
                {mode === MODES.SMS
                  ? countOfValidRecipients !== 1
                    ? "message"
                    : "a message"
                  : countOfValidRecipients !== 1
                  ? "email"
                  : "a email"}
                {addS(countOfValidRecipients)}
              </Div>
              {notice}
              <RecipientsTable rowsData={validRecipients} />
            </Div>
          ) : null}

          {countOfInvalidRecipients ? (
            <Div mb={9}>
              <Div
                display="row.flex-start.center"
                color="gray7"
                mb={this.props.totalCount > DEFAULT_PAGE_SIZE ? 0 : 5}
              >
                <ErrorIcon color="red5" mr={1} />
                <Div fw={4} mr={1}>
                  {countOfInvalidRecipients}{" "}
                  {countOfInvalidRecipients !== 1 ? "people" : "person"}
                </Div>
                {countOfInvalidRecipients !== 1 ? "have" : "has"}{" "}
                {mode === MODES.SMS ? "phone number" : "email"} issues to
                resolve
              </Div>
              {notice}
              <RecipientsTable rowsData={invalidRecipients} />
            </Div>
          ) : null}

          {countOfInvalidRecipients ? (
            <Div display="column.center.center" width={1}>
              <Div color="gray6" mb={2}>
                Resolved the {mode === MODES.SMS ? "phone number" : "email"}{" "}
                issues?
              </Div>
              <Div
                display="row.center.center"
                width={1}
                bra={1}
                p={3}
                bg={{
                  default: "neutral0",
                  hover: "neutral1"
                }}
                onClick={
                  this.props.refreshing
                    ? undefined
                    : () => this.props.refresh({ pageChange: true })
                }
              >
                {this.props.refreshing ? (
                  <LoadingIcon size={20} color="gray5" mr={2} />
                ) : (
                  <RefreshIcon size={20} color="gray5" mr={2} />
                )}
                <Div fw={3} fs={3} color="gray5">
                  {this.props.refreshing ? "Reloading" : "Reload this step"}
                </Div>
              </Div>
            </Div>
          ) : null}

          {!countOfValidRecipients && !countOfInvalidRecipients ? (
            <Div display="row.flex-start.center" mb={5} color="gray7">
              <ErrorIcon color="red5" mr={1} />
              There are no recipients that match your criteria
              {canViewNextPage
                ? " on this page. Go to next page to view more."
                : ""}
            </Div>
          ) : null}

          {this.props.totalCount > DEFAULT_PAGE_SIZE ? <PaginationBar /> : null}
        </Div>
        <Div flex={1} bg="neutral0">
          {loading ? (
            <Loading />
          ) : this.props.preview && this.props.preview.id ? (
            <Fragment>
              <Div
                display="column"
                bg="white"
                p={5}
                height={mode === MODES.EMAIL ? 80 : undefined}
                bb={1}
                bc="neutral0"
              >
                <Div display="row.flex-start.center" mb={1}>
                  <Div mr={1} color="gray5">
                    To:
                  </Div>
                  <Div fw={3} color="gray7">
                    {this.props.preview.name}{" "}
                    {mode === MODES.EMAIL
                      ? `<${this.props.preview.email}>`
                      : ` - ${this.props.preview.phone}`}
                  </Div>
                </Div>

                {mode === MODES.EMAIL ? (
                  <Div display="row.flex-start.center">
                    <Div mr={1} color="gray5">
                      Subject:
                    </Div>
                    <Div fw={3} color="gray7">
                      {this.props.preview.subject}
                    </Div>
                  </Div>
                ) : null}
              </Div>

              <Div
                p={5}
                height={320}
                style={{
                  overflowY: "auto"
                }}
              >
                <Div bra={1} bg="white" shadow={1}>
                  {this.props.preview ? (
                    mode === MODES.SMS ? (
                      <span
                        style={{
                          whiteSpace: "pre-line",
                          display: "block",
                          padding: "10px"
                        }}
                        dangerouslySetInnerHTML={{
                          __html: this.props.preview.content
                        }}
                      />
                    ) : (
                      <IFrame
                        style={{
                          width: "100%",
                          border: 0,
                          height: "280px"
                        }}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: this.props.preview.content
                          }}
                        />
                      </IFrame>
                    )
                  ) : null}
                </Div>
              </Div>
            </Fragment>
          ) : (
            <Div
              display="flex.center.center"
              fs={5}
              fw={2}
              color="gray7"
              width={1}
              height={1}
            >
              Select a recipient from the left sidebar to preview
            </Div>
          )}
        </Div>
      </Div>
    );
  }
}

/*
export default withState("preview", "setPreview", ({ recipients }) => {
  const firstRecord = R.compose(
    R.head,
    R.filter(r => Helpers.isValidEmail(r.meta.email))
  )(recipients);

  if (firstRecord) {
    return {
      id: firstRecord.meta.id,
      subject: firstRecord.variables.subject,
      email: firstRecord.variables.recipient_email,
      name: firstRecord.variables.recipient_name,
      content: firstRecord.preview
    };
  }

  return null;
})(
*/

export default decorate(Review);
