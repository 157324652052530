import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import factory from "./Controller";
import REPORT_TYPES from "redux/modules/orders/reports/reportTypes";
import { CREDENTIAL_TYPE_ID, MEAL_TYPE_ID } from "utils/item-types";

import { showModal, hideModal } from "redux/modules/modal/actions";
import { getReport } from "redux/modules/orders/reports/actions";
import {
  createReview,
  createReviews,
  removeReview,
  removeReviews
} from "redux/modules/approvalWorkflows/reviews/actions";

import { user } from "redux/modules/user/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import { report, isFetching } from "redux/modules/orders/reports/selectors";

import {
  assignLineItem,
  deleteLineItemAssignment
} from "redux/modules/orders/line-items/actions";

import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

const getItemType = pathname => {
  if (pathname.includes("credential") || pathname.includes("passes")) {
    return CREDENTIAL_TYPE_ID;
  }
  return MEAL_TYPE_ID;
};

const getReportType = (params, pathname = "") => {
  if (pathname.includes("account") || pathname.includes("group")) {
    return REPORT_TYPES.ACCOUNT_BY_ACCOUNT_ON_ORDER;
  }
  if (pathname.includes("type")) {
    return REPORT_TYPES.TYPE_BY_ITEM_TYPE;
  }
  if (pathname.includes("catering") && pathname.includes("meal")) {
    return REPORT_TYPES.VARIANT_OPTION;
  }
  if (params.groupBy === "nameOnOrder") {
    return REPORT_TYPES.PERSON_BY_NAME_ON_ORDER;
  } else if (params.groupBy === "pickupName") {
    return REPORT_TYPES.PERSON_BY_PICKUP_PERSON;
  }
  return REPORT_TYPES.PERSON_BY_ASSIGNED_TO;
};

function mapStateToProps(state, props) {
  const reportType = getReportType(props.params, props.location.pathname);

  return {
    cando: canEventUserDo(state),
    eventId: eventDetails(state).id,
    reportType,
    report: report(state, reportType),
    loading: isFetching(state, reportType),
    itemType: getItemType(props.location.pathname),
    user: user(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      assignLineItem,
      deleteLineItemAssignment,
      getReport,
      hideModal,
      showModal,
      createReview,
      createReviews,
      removeReview,
      removeReviews
    },
    dispatch
  );
}

export default (Child, ...options) =>
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(factory(Child, ...options))
  );
