import React from "react";
import { connect } from "react-redux";

import { BigOutlineButton, VisibleIcon } from "components/Base";
import Categories from "./Categories";
import SliderSidebarLayout from "ui-kit/SliderSidebar/View";

import { actions as sliderSidebarActions } from "ui-kit/SliderSidebar/model";
import { SIDEBAR_INSTANCE_ID } from "../constants";

const decorate = connect(
  null,
  {
    openCategories: () =>
      sliderSidebarActions.setIsDrawerOpen(true, {
        meta: {
          instanceId: SIDEBAR_INSTANCE_ID
        }
      })
  }
);

const Product = ({ openCategories }) => (
  <>
    <BigOutlineButton LeftIcon={VisibleIcon} mt={4} onClick={openCategories}>
      View Categories
    </BigOutlineButton>
    <SliderSidebarLayout
      position="left"
      width={350}
      instanceId={SIDEBAR_INSTANCE_ID}
      style={{
        borderRadius: 0
      }}
    >
      <Categories />
    </SliderSidebarLayout>
  </>
);

export default decorate(Product);
