/* eslint-disable consistent-return */

import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Dropdown from "./Dropdown";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import CanUserDo from "components/Global/Security/CanUserDo";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import AddModuleModal from "components/Event/Settings/Modals/AddModuleModal";
import SelectTemplateModal from "components/Global/Module/Modals/SelectTemplate";
import CloneModuleModal from "Modules/CloneModuleModal/View";
import AddScheduleModal from "Schedules/Create/View";

@CSSModules(css)
class AddButton extends Component {
  state = {
    showDropdown: false
  };

  toggleDropdown = e => {
    e.stopPropagation();
    if (this.state.showDropdown) {
      this.setState({
        showDropdown: false
      });
    } else {
      this.setState({
        showDropdown: true
      });
    }
  };

  showAddModuleModal = () => {
    this.props.showModal({
      content: (
        <AddModuleModal
          simple
          eventId={this.props.eventDetails.id}
          hideModal={this.props.hideModal}
          onSave={this.handleAddModule}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showAddScheduleModal = () => {
    this.props.showModal({
      content: <AddScheduleModal hideModal={this.props.hideModal} />,
      wrapper: ModalWrapper
    });
  };

  showCloneModuleModal = () => {
    let isCloning = false;

    this.props.showModal({
      content: (
        <CloneModuleModal
          organization={{ id: this.props.eventDetails.org_id }}
          isLenndAdmin={this.props.isLenndAdmin}
          allUserEvents={this.props.allUserEvents}
          isCloning={() => {
            isCloning = true;
          }}
          hideModal={() => {
            if (isCloning) return false;
            this.props.hideModal();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  handleAddModule = async data => {
    await this.props.addModule(data);
    await this.props.getEvent(this.props.eventDetails.id);
    this.props.hideModal();
  };

  showSelectTemplateModal = () => {
    this.props.showModal({
      content: (
        <SelectTemplateModal
          eventId={this.props.eventDetails.id}
          onSave={this.handleSelectTemplate}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  handleSelectTemplate = async template => {
    this.props.showSnackbar({
      message: "Adding template to your event..."
    });

    await this.props.copyModule({
      moduleIds: [template.module.id],
      copyToEventId: this.props.eventDetails.id
    });

    this.props.getEvent(this.props.eventDetails.id);

    this.props.showSnackbar({
      message: "Added!"
    });

    this.props.hideModal();
  };

  render() {
    const { showDropdown } = this.state;
    const { isLenndAdmin, canViewSchedules } = this.props;

    return (
      <CanUserDo action={`${STANDARD_MODULE_IDS.settings.id}_add_modules`}>
        <div
          className="large-menu-icon"
          onClick={this.toggleDropdown}
          style={{
            position: "relative"
          }}
        >
          <div className="circle" style={{ backgroundColor: "#ccc" }}>
            <i className="material-icons">add</i>
          </div>

          {showDropdown ? (
            <Dropdown
              canViewSchedules={canViewSchedules}
              isLenndAdmin={isLenndAdmin}
              outsideClickIgnoreClass="icon-add-button"
              close={() =>
                showDropdown && this.setState({ showDropdown: false })
              }
              showAddModuleModal={this.showAddModuleModal}
              showAddScheduleModal={this.showAddScheduleModal}
              showSelectTemplateModal={this.showSelectTemplateModal}
              showCloneModuleModal={this.showCloneModuleModal}
            />
          ) : null}
        </div>
      </CanUserDo>
    );
  }
}

AddButton.propTypes = {
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  addModule: PropTypes.func.isRequired,
  getEvent: PropTypes.func.isRequired,
  copyModule: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  eventDetails: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
};

export default AddButton;
