import { createSelector } from "reselect";
import * as R from "ramda";

import { getters } from "./model";
import * as FormSelectors from "ui-kit/Form/selectors";

export const getIsValid = createSelector(
  getters.data,
  data => {
    if (!data.name || !data.name.length || !data.slug || !data.slug.length) {
      return false;
    }

    return true;
  }
);

export const getEditedField = R.compose(
  field => R.prop("value", field),
  FormSelectors.getField
);
