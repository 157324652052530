import { createModule } from "redux-mvc";

import model from "./model";

import selectRowsModule from "ui-kit/SelectRows";
import tabsModule from "ui-kit/Tabs";
import formModule from "ui-kit/Form";
import selectItemsModule from "ui-kit/MiniItemsSelector";
import selectFieldsModule from "ui-kit/SelectFields";
import packageTable from "Sponsors/PackageTable";

import sagasModule from "./sagas";

const module = createModule({ ...model, optimist: true });

module.setRootSaga(sagasModule);
module.plugModule(selectRowsModule);
module.plugModule(tabsModule);
module.plugModule(formModule);
module.plugModule(packageTable);
module.plugModule(selectItemsModule);
module.plugModule(selectFieldsModule);

export default module;
