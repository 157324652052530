import React from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { noop } from "utils/General";

import { RightIcon, LeftIcon, Div, Text5 } from "components/Base";

import { getToolbarTitle } from "../selectors";
import { actions } from "../model";

const decorate = connect(
  (state, props) => ({
    title: getToolbarTitle(state, props)
  }),
  bindInstance({
    next: actions.next,
    prev: actions.prev,
    create: actions.eventCreate
  })
);

const PrevNext = ({
  title = "",
  next = noop,
  prev = noop,
  inverse = false,
  ...styleProps
}) => (
  <>
    <Div display="row.flex-start.center" {...styleProps}>
      <Div display="row.center.center" ml={2}>
        <Div
          display="row.center.center"
          bra={1}
          bg={{ default: "transparent", hover: inverse ? "gray3" : "primary7" }}
          onClick={prev}
        >
          <LeftIcon color={inverse ? "black" : "white"} size={30} />
        </Div>
        <Div
          display="row.center.center"
          bra={1}
          ml={1}
          bg={{ default: "transparent", hover: inverse ? "gray3" : "primary7" }}
          onClick={next}
        >
          <RightIcon color={inverse ? "black" : "white"} size={30} />
        </Div>
      </Div>
    </Div>
    <Text5 color={inverse ? "black" : "white"} flex={1} bold ml={2}>
      {title}
    </Text5>
  </>
);

export default decorate(PrevNext);
