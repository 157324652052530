import { connect } from "react-redux";
import { actions, getters } from "Forms/WizardModal";
import { getFilterScheduleFields } from "Forms/WizardModal/selectors";
import { PAGES } from "Forms/WizardModal/constants";
import SelectForm from "Forms/WizardModal/View/Common/SelectForm";

const title = "Create a brand new schedule";
const description =
  "A new schedule will be created where your form data will be collected.";
const buttonTxt = "CREATE SCHEDULE";
const placeholder = "Search existing schedules...";
const buttonBg = { default: "info7", hover: "info8" };
const buttonColor = "white";
const moduleTitle = "schedule";

const decorate = connect(
  state => ({
    moduleFields: getFilterScheduleFields(state),
    newModuleName: getters.newModuleName(state),
    createTitle: title,
    createDescription: description,
    createButton: buttonTxt,
    createButtonBg: buttonBg,
    createButtonColor: buttonColor,
    showModuleName: false,
    showRightIcon: true,
    placeholder,
    moduleTitle
  }),
  {
    back: () => actions.setSelectedPage(PAGES.SELECT_METHOD),
    selectId: actions.selectSchedule,
    setFilter: actions.setSchedulesFilter,
    setNewModuleName: actions.setNewModuleName,
    createAction: () => actions.showScheduleCreateModal()
  }
);

export default decorate(SelectForm);
