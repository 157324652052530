import PropTypes from "prop-types";
import React from "react";
import BaseTableView from "../BaseTableView";
import { get } from "lodash";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import TableSizer from "components/Global/ReactTable/TableSizer";
import HeightSizer from "components/Global/Table3/Sizers/Expand";
import kanbanFieldFilter from "components/Event/Module/utils/kanban-field-filter";

import SwimHeader from "./Swimlane/Header";
import Swimlanes from "./Swimlanes";
import resolvePrimaryField from "./utils/resolve-primary-field";
import Card from "./Card";
import DragCard from "./Card/DragCard";
import Swimlane from "./Swimlane/DropLane";
import Empty from "./Empty";

const localStorageId = moduleId => `kanban_settings_${moduleId}`;

@CSSModules(css)
class KanbanView extends BaseTableView {
  defaultCardFields = () => this.props.fields.slice(0, 3).map(({ id }) => id);

  fetchCustomCardFields = () =>
    JSON.parse(localStorage.getItem(localStorageId(this.props.moduleId))) || {
      fields: this.defaultCardFields()
    };

  handleCardDrop = ({ option, cardId }) =>
    this.props.saveValue({
      columnId: this.props.activeView.grouped_by,
      rowId: cardId,
      value: this.resolveTypeSchema(this.props.groupedByField.type, option)
    });

  resolveTypeSchema = (type, value) => {
    switch (type) {
      case "checkbox":
        return {
          type: "boolean",
          value: value.id
        };
      case "dropdown":
        return {
          type: "dropdown",
          value: {
            records: [value]
          }
        };
      default:
    }
  };

  getLaneOptions = type => {
    switch (type) {
      case "checkbox":
        return [
          {
            id: false,
            value: <i className="material-icons">check_box_outline_blank</i>
          },
          { id: true, value: <i className="material-icons">check_box</i> }
        ];
      case "dropdown":
      default:
        return get(this.props, "groupedByField.settings.options", []);
    }
  };

  swimLaneFilter = (column, row, value) => {
    switch (column.type) {
      case "checkbox":
        return Boolean(get(row.values, [column.id, "value"])) === value.id;
      case "dropdown":
      default:
        return (
          get(
            get(row.values, [column.id, "value", "records"], [{}])[0],
            "value",
            ""
          ) === value.value
        );
    }
  };

  onCreateField = async ({ name, type }) => {
    const { field } = await this.props.addField({
      moduleId: this.props.moduleId,
      field: {
        name,
        type
      },
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId
      }
    });

    await this.selectField(field.id);

    this.props.fetchRecords();
  };

  selectField = fieldId =>
    this.props.updateView({
      orgId: this.props.params.orgId,
      eventId: this.props.params.eventId,
      moduleId: this.props.moduleId,
      viewId: this.props.params.viewId,
      view: {
        groupedBy: fieldId
      },
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId
      }
    });

  render() {
    const {
      fields,
      onCardClick,
      plural,
      references,
      singular,
      readOnly,
      groupedByField,
      activeView
    } = this.props;

    if (!groupedByField) {
      const dropdownFields = fields.filter(kanbanFieldFilter);
      return (
        <Empty
          dropdownFields={dropdownFields}
          onCreateField={this.onCreateField}
          selectField={this.selectField}
        />
      );
    }
    const lanes = this.getLaneOptions(groupedByField.type);
    const rows = this.getRows(this.getVisibleRecords(), fields, references);
    const showBadges = lanes.length && lanes.some(l => l.backgroundColor);

    return (
      <HeightSizer computeHeight={height => height}>
        <TableSizer>
          <div styleName="contentContainer">
            <Swimlanes>
              {lanes.filter(Boolean).map(option => {
                const cards = rows
                  .filter(row =>
                    this.swimLaneFilter(groupedByField, row, option)
                  )
                  .map(row => {
                    const cardProps = {
                      key: row.id,
                      fields: fields.filter(({ id }) =>
                        activeView.card_fields.includes(id)
                      ),
                      onClick: onCardClick,
                      option,
                      primaryField: resolvePrimaryField(fields),
                      readOnly,
                      references,
                      row,
                      getRowMetaData: this.getRowMetaData
                    };
                    return readOnly ? (
                      <Card {...cardProps} />
                    ) : (
                      <DragCard {...cardProps} />
                    );
                  });
                return (
                  <Swimlane
                    key={option.id}
                    onDrop={this.handleCardDrop}
                    option={option}
                    Header={
                      <SwimHeader
                        iconColor={showBadges ? option.backgroundColor : null}
                        title={option.value}
                        singular={singular}
                        plural={plural}
                        count={cards.length}
                      />
                    }
                  >
                    {cards}
                  </Swimlane>
                );
              })}
            </Swimlanes>
          </div>
        </TableSizer>
      </HeightSizer>
    );
  }
}

KanbanView.defaultProps = {
  plural: "",
  singular: ""
};

KanbanView.propTypes = {
  fields: PropTypes.array.isRequired,
  moduleId: PropTypes.string.isRequired,
  onCardClick: PropTypes.func.isRequired,
  plural: PropTypes.string.isRequired,
  records: PropTypes.array.isRequired,
  saveValue: PropTypes.func.isRequired,
  singular: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired
};

export default KanbanView;
