export const NAMESPACE = "UITable";

export const ICON_WIDTH = 35;

export const TABLE_VIEW_ID = "ui-table";
export const CHECKBOX_COLUMN_ID = "select-checkbox";
export const LINK_COLUMN_ID = "link-column";
export const PRINT_COLUMN_ID = "print-column";
export const ACTIONS_COLUMN_ID = "actions-column";
export const GROUP_BY_COLUMN_ID = "grouping-column";
export const TREE_NODE_COLUMN_ID = "tree-node-column";

export const ACTION_TYPES = {
  ITEM: "item",
  HEADER: "header",
  SUBMENU: "submenu",
  SEPARATOR: "separator"
};

export const GROUPING_ACTIONS = "groupingActions";

export const LIST_INDEX = "list";

export const COLUMN_ACTIONS = {
  EDIT: "editColumn",
  HIDE: "hideColumn",
  INSERT_LEFT: "insertLeftColumn",
  INSERT_RIGHT: "insertRightColumn",
  GROUP_BY: "GroupByColumn"
};

export const ROW_ACTIONS = {
  OPEN_RECORD: "openRecordRow",
  TOGGLE_FOLDER_ROW: "toggleFolderRow",
  TOGGLE_CHECKBOX_ROW: "toggleCheckboxRow"
};

export const GROUP_ACTIONS = {
  EXPORT_CSV: "groupExportCSV",
  EXPORT_XLSX: "groupExportXLSX"
};
