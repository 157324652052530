import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { get, cloneDeep } from "lodash";
import {
  ButtonGroup,
  ButtonOutline,
  Submit
} from "components/Global/Modal/Layout/Buttons";
import UserPicker from "components/Global/StandAloneEditors/User";
import PropTypes from "prop-types";
import Checkbox from "rc-checkbox";
import Permissions from "../Permissions";
import { Menu } from "../ShareModal";
import helpers from "../utils";
import { withRouter } from "react-router";

const defaultState = props => ({
  owners: {
    type: "user",
    value: { records: [] }
  },
  addMessage: false,
  message: null,
  notify: props.allowNotifyingUsers ? true : false,
  role: "canedit"
});

@CSSModules(css)
class InviteManager extends Component {
  state = cloneDeep(defaultState(this.props));

  handleManagerSelect = owners => this.setState({ owners });

  hasSelectedOwners = () =>
    get(this.state.owners, "value.records", []).length > 0;

  toggleNotify = () =>
    this.setState(state => {
      state.notify = !state.notify;
      return state;
    });

  toggleMessage = () =>
    this.setState(state => {
      state.addMessage = !state.addMessage;
      return state;
    });

  handleMessage = e => this.setState({ message: e.target.value });

  handleCancel = () => this.setState(cloneDeep(defaultState(this.props)));

  handleSubmit = () => {
    const data = {
      ...this.state,
      userIds: get(this.state.owners, "value.records", []).map(({ id }) => id)
    };
    this.props.sendInvites(data);
    this.handleCancel();
  };

  handlePermissionChange = role => this.setState({ role });

  render() {
    const smallButtonStyles = {
      fontSize: 13,
      height: 35,
      padding: "0 15px"
    };

    const inviteActions = this.hasSelectedOwners() ? (
      <div>
        {this.props.allowNotifyingUsers ? (
          <div styleName="top">
            <label styleName="label">
              <Checkbox
                checked={this.state.notify}
                onChange={this.toggleNotify}
              />
              &nbsp;&nbsp;Notify people
            </label>
            {this.state.notify ? (
              <span>
                &nbsp;-&nbsp;
                <span styleName="addMessage" onClick={this.toggleMessage}>
                  {this.state.addMessage && this.state.notify
                    ? "Hide message"
                    : "Add message"}
                </span>
              </span>
            ) : (
              ""
            )}
          </div>
        ) : null}

        {this.state.addMessage && this.state.notify ? (
          <textarea
            rows="3"
            styleName="formElement"
            placeholder="Optional: Add a personal message"
            onChange={this.handleMessage}
            value={this.state.message}
          />
        ) : (
          ""
        )}
        <ButtonGroup>
          <Submit
            title={this.state.notify ? "Send" : "Add"}
            style={smallButtonStyles}
            onClick={this.handleSubmit}
          />
          <ButtonOutline
            title="Cancel"
            style={smallButtonStyles}
            onClick={this.handleCancel}
          />
        </ButtonGroup>
      </div>
    ) : (
      ""
    );

    return (
      <div>
        <div styleName="title">{this.props.title}:</div>
        <div styleName="inputGroup">
          <div styleName="inputWrapper">
            <UserPicker
              disableAddPerson
              hideGroups
              excludeUsers={this.props.owners.map(u => u.user_id)}
              onChange={this.handleManagerSelect}
              value={this.state.owners}
              rowMetaData={{
                meta: {
                  eventId: this.props.params.eventId
                },
                helpers: {}
              }}
            />
          </div>
          {this.props.showRoles ? (
            <Permissions onChange={this.handlePermissionChange}>
              <Menu selectedValue={helpers.formatRole(this.state.role)} />
            </Permissions>
          ) : null}
        </div>
        {inviteActions}
      </div>
    );
  }
}

InviteManager.propTypes = {
  params: PropTypes.shape({
    eventId: PropTypes.string
  }).isRequired,
  owners: PropTypes.array.isRequired,
  sendInvites: PropTypes.func.isRequired
};

export default withRouter(InviteManager);
