import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import autobind from "autobind-decorator";
import listensToClickOutside from "react-onclickoutside";
import { SmallMenuIcon } from "components/Global/NavIcons";

@listensToClickOutside
@CSSModules(css)
class EventOptionsDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDropdown: false
    };
  }

  handleClickOutside() {
    if (this.state.showDropdown) {
      this.setState({ showDropdown: false });
    }
  }

  @autobind
  toggleOptions() {
    this.setState({
      showDropdown: !this.state.showDropdown
    });
  }

  @autobind
  hideDropdown() {
    this.setState({ showDropdown: false });
  }

  @autobind
  goToSettings() {
    this.hideDropdown();
    this.props.router.push({
      pathname: `/event/${this.props.eventId}/settings`
    });
  }

  render() {
    let options;
    if (this.state.showDropdown) {
      options = (
        <div styleName="optionsContainer">
          <span className="atom-caret-up" styleName="optionsArrow" />
          <div styleName="optionsContainerTop">
            {this.props.modules.map(moduleDetails => (
              <span onClick={this.hideDropdown} key={moduleDetails.id}>
                <SmallMenuIcon
                  eventDetails={this.props.eventDetails}
                  params={this.props.params}
                  module={moduleDetails}
                  showLink
                  showLabel
                />
              </span>
            ))}
          </div>

          {this.props.showSettingsLink ? (
            <div styleName="optionsContainerBottom" onClick={this.goToSettings}>
              <i className="icon icon-settings" styleName="settingsIcon" />
              <div styleName="settingsLabel">Event Settings</div>
            </div>
          ) : null}
        </div>
      );
    }

    return (
      <div className="platform-header-event-options" styleName="container">
        <a className="nav-link nav-link-wrapper" onClick={this.toggleOptions}>
          <span className="nav-link-label">
            More
            <i className="material-icons" styleName="arrowIcon">
              &#xE313;
            </i>
          </span>
        </a>
        {options}
      </div>
    );
  }
}

EventOptionsDropdown.propTypes = {
  params: PropTypes.object.isRequired,
  eventId: PropTypes.number.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  modules: PropTypes.array.isRequired,
  eventDetails: PropTypes.object.isRequired,
  showSettingsLink: PropTypes.bool.isRequired
};

export default EventOptionsDropdown;
