import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "Modules/MergeTagTableModal";
import { Div, BigFilledButton, BigOutlineButton } from "components/Base";
import { PAGES } from "Modules/MergeTagTableModal/constants";

const decorate = connect(
  state => ({
    selectedPage: getters.selectedPage(state)
  }),
  {
    nextPage: () => actions.generateText(),
    backPage: () => actions.setSelectedPage(PAGES.CHOOSE_OPTIONS)
  }
);

const Footer = ({
  hideModal,
  nextPage,
  backPage,
  selectedPage,
  onCopyLink,
  textCopied
}) => (
  <Div
    width={1}
    height={72}
    bt={1}
    bc="neutral2"
    bg="white"
    display="row.flex-start.center"
    px={4}
  >
    {selectedPage === PAGES.CHOOSE_OPTIONS && (
      <BigFilledButton bg="altB5" onClick={nextPage}>
        Generate & Review
      </BigFilledButton>
    )}
    {selectedPage === PAGES.COPY_TEXT && (
      <BigFilledButton bg="altB5" onClick={onCopyLink}>
        {`${textCopied ? "Text copied" : "Copy Text"}`}
      </BigFilledButton>
    )}
    {selectedPage === PAGES.COPY_TEXT && (
      <BigOutlineButton onClick={backPage} ml={2}>
        Back
      </BigOutlineButton>
    )}
    {selectedPage === PAGES.CHOOSE_OPTIONS && (
      <BigOutlineButton ml={2} onClick={hideModal}>
        Cancel
      </BigOutlineButton>
    )}
  </Div>
);

export default decorate(Footer);
