import React from "react";
import * as R from "ramda";
import { Div, Text0, Text3 } from "components/Base";
import Circle from "ui-kit/Circle";

const getInitials = ({ first_name, last_name }) => {
  const first = R.toUpper(R.propOr("", 0)(first_name || ""));
  const last = R.toUpper(R.propOr("", 0)(last_name || ""));
  return `${first}${last}`;
};

const PersonCard = ({
  id,
  name,
  type_color,
  first_name,
  last_name,
  primary_contact_id,
  items,
  email,
  type_name,
  selectContact
}) => (
  <Div
    ba={1}
    bc={{ default: "neutral2", hover: "primary7" }}
    bra={1}
    display="row.space-between"
    p={2}
    pr={4}
    mt={2}
    /*
    onClick={() =>
      onSelectCustomer({
        contactId: id,
        accountId,
        contactName: name,
        accountName,
        contactEmail: email
      })
    }
    */
    onClick={() =>
      selectContact({
        contactId: id,
        contactName: name,
        contactEmail: email,
        filter: ""
      })
    }
    style={{ position: "relative", minHeight: "62px" }}
  >
    <Div display="row.flex-start" height={1}>
      <Circle style={{ backgroundColor: type_color }} size={28}>
        <Text3 bold color="white">
          {getInitials({ first_name, last_name })}
        </Text3>
      </Circle>
      <Div
        height={1}
        display="column.space-between"
        ml={2}
        style={{
          maxWidth: "210px"
        }}
      >
        <Text3 bold>{name}</Text3>
        <Text0 bold>{type_name}</Text0>
        {primary_contact_id && <Text0 bold>Primary Contact</Text0>}
        <Text0>{email || "-"}</Text0>
      </Div>
    </Div>
    <Div
      mt={1}
      style={{
        width: "145px"
      }}
    >
      {R.map(
        item => (
          <Div display="row.flex-start.center" mb={2}>
            <Text0 color="neutral6">{item.quantity}</Text0>
            <Text0 bold ml={2}>
              {item.name}
            </Text0>
          </Div>
        ),
        items
      )}
    </Div>
  </Div>
);

export default PersonCard;
