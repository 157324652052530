import React, { Component } from "react";
import autobind from "autobind-decorator";
import PropTypes from "prop-types";
import Radium from "radium";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

const styles = {
  bar: {
    height: 52,
    position: "fixed",
    width: "100%",
    padding: "10px 20px",
    top: 0,
    left: 0,
    backgroundColor: "#373737",
    boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.10)",
    zIndex: 70,
    color: "#fff",
    fontWeight: 600,
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  barBack: {
    marginRight: 15,
    cursor: "pointer"
  },
  barBackIcon: {},
  barBack2: {
    border: "1px solid #999",
    color: "#999999",
    borderRadius: 100,
    width: 30,
    height: 30,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 10,
    cursor: "pointer",
    ":hover": {
      color: "#ffffff",
      border: "1px solid #fff"
    }
  },
  barTitle: {
    fontWeight: "bold",
    fontSize: 16,
    color: "#FFFFFF",
    display: "none",
    "@media (min-width: 48em)": {
      display: "block"
    }
  },
  options: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  barDropdown: {
    marginLeft: 10
  },
  barButton: {
    marginLeft: 10,
    padding: "7px 10px",
    color: "#fff",
    backgroundColor: "red",
    borderRadius: 3,
    fontSize: 13
  },
  barSent: {
    marginLeft: 10,
    borderRadius: 3,
    fontWeight: "bold",
    fontSize: "8.39px",
    color: "#FFFFFF",
    letterSpacing: ".5",
    width: 101,
    height: 19,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "uppercase",
    backgroundColor: "#27ae60"
  },
  label: {
    marginLeft: 8
  },
  buttonLink: {
    padding: "0 30px 0 16px",
    border: 0,
    display: "flex",
    alignItems: "center"
  },
  buttonRight: {
    marginLeft: 15
  },
  buttonActive: {
    backgroundColor: "rgba(255,255,255,0.35)"
  },
  buttonIcon: {
    fontSize: 18
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 600,
    fontSize: 13,
    height: 29,
    padding: "0 20px",
    textTransform: "uppercase",
    color: "#FFFFFF",
    border: "1px solid rgba(255,255,255,0.35)",
    borderRadius: 40,
    cursor: "pointer",
    ":hover": {
      backgroundColor: "rgba(255,255,255,0.35)"
    }
  }
};

@Radium
class HeaderButton extends Component {
  static propTypes = {
    key: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
    active: PropTypes.bool,
    action: PropTypes.func,
    style: PropTypes.object
  };

  render() {
    let icon;
    if (this.props.icon) {
      icon = (
        <i className="material-icons" style={styles.buttonIcon}>
          {this.props.icon}
        </i>
      );
    }
    return (
      <div
        key={this.props.key}
        style={[
          styles.button,
          this.props.active && styles.buttonActive,
          this.props.style
        ]}
        onClick={this.props.action}
      >
        {icon}
        <div style={styles.label}>{this.props.label}</div>
      </div>
    );
  }
}

@Radium
export default class FormGridBarPreview extends Component {
  static propTypes = {
    router: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    isEditing: PropTypes.bool.isRequired,
    toggleEditing: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
  }

  @autobind
  exitForm() {
    this.goBack();
  }

  @autobind
  goBack() {
    this.props.router.push({
      pathname: `/event/${this.props.form.event_id}/module/${
        STANDARD_MODULE_IDS.formsv3.id
      }`
    });
  }

  render() {
    return (
      <div style={styles.bar}>
        {/*
        <div style={styles.barBack}>
          <i className="material-icons" style={styles.barBackIcon} onClick={this.goBack}>arrow_back</i>
        </div>
        */}
        <div style={styles.barTitle}>Your are in preview mode</div>
        {/*
        <div style={{flex: 1}}></div>
        <div style={styles.options}>
          <HeaderButton
            key="edit"
            icon="edit"
            label={this.props.isEditing ? 'Editing' : 'Edit'}
            active={this.props.isEditing}
            action={this.props.toggleEditing}
          />
          <HeaderButton
            key="exit"
            icon="close"
            label={this.props.isEditing ? 'Save and Exit' : 'Exit'}
            style={styles.buttonRight}
            action={this.exitForm}
          />
        </div>
        */}
      </div>
    );
  }
}
