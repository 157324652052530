import React, { useEffect } from "react";
import * as R from "ramda";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import { actions, getters } from "./model";
import css from "./styles.scss";
import { DropdownAsync } from "ui-kit/Form/View";
import { DROPDOWN_FIELD_ID } from "./constants";
import DatePicker from "components/Event/FormsV2/Modals/FormEditModal/Calendar";
import { Div, BigFilledButton, BigOutlineButton } from "components/Base";
import { getIsSaveBtnDisabled } from "./selectors";
import { TYPES } from "./constants";

const decorate = R.compose(
  connect(
    (state, props) => ({
      dueDate: getters.dueDate(state, props),
      isSaveBtnDisabled: getIsSaveBtnDisabled(state, props),
      label: props.type === TYPES.ACCOUNT ? "group" : "person"
    }),
    {
      hideAddAssignmentModal: actions.hideAddAssignmentModal,
      setDueDate: actions.setDueDate,
      setType: actions.setType,
      save: actions.save
    }
  ),
  CSSModules(css)
);

const AddAssignmentModal = ({
  hideAddAssignmentModal,
  dueDate,
  setDueDate,
  isSaveBtnDisabled,
  type,
  setType,
  save,
  label
}) => {
  useEffect(() => {
    setType(type);
  });
  return (
    <MiniModalWrapper
      showModal={true}
      hideModal={hideAddAssignmentModal}
      title={`Assign form to an ${label}`}
      width={390}
      height={302}
      stopPropagation
    >
      <div styleName="wrapper">
        <Div fw={3} color="gray7" mb={1} capitalize>
          {label}:
        </Div>
        <DropdownAsync
          fieldId={DROPDOWN_FIELD_ID}
          iniValue={null}
          multipleSelect={false}
          usePortal
          bg="white"
        />
        <Div mt={4} height={40}>
          <Div fw={3} color="gray7">
            Specific due date for this {label} (Optional):
          </Div>
          <DatePicker value={dueDate} onChange={setDueDate} />
        </Div>
        <Div mt={4} display="row.flex-start.center">
          <BigFilledButton
            bg="primary7"
            mr={2}
            disabled={isSaveBtnDisabled}
            onClick={save}
          >
            Save
          </BigFilledButton>
          <BigOutlineButton onClick={hideAddAssignmentModal}>
            Cancel
          </BigOutlineButton>
        </Div>
      </div>
    </MiniModalWrapper>
  );
};

export default decorate(AddAssignmentModal);
