import React from "react";
import * as R from "ramda";
import { Div, CloseIcon } from "components/Base";

const Header = ({ hideModal, title, typeName = "" }) => (
  <Div width={1} p={4} bg="indigo7" display="row.space-between">
    <Div display="column.flex-start.flex-start">
      <Div
        height={18}
        bg="indigo9"
        fs={0}
        color="white"
        bra={1}
        display="row.center.center"
        px={1}
        mt={1}
      >
        {R.toUpper(typeName)}
      </Div>
      <Div fs={6} fw={2} color="white">
        {title}
      </Div>
    </Div>
    <CloseIcon size={24} color="white" onClick={hideModal} />
  </Div>
);

export default Header;
