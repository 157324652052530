import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Popover from "@lennd/material-ui/Popover";

const NavItem = CSSModules(
  ({ icon, title, onClick }) => (
    <div styleName="navItem" onClick={onClick} tabIndex="0" role="button">
      <i className="material-icons" styleName="icon">
        {icon}
      </i>
      <span styleName="title">{title}</span>
    </div>
  ),
  css
);

const VisibilityNavItem = CSSModules(
  ({ icon, title, isSelected, onClick }) => (
    <div
      styleName={isSelected ? "navItemSelected" : "navItem"}
      onClick={onClick}
      tabIndex="0"
      role="button"
    >
      <i className="material-icons" styleName="icon">
        {icon}
      </i>
      <span styleName="title">{title}</span>
      <div styleName="check">
        {isSelected ? (
          <i className="material-icons">check</i>
        ) : (
          <span>&nbsp;</span>
        )}
      </div>
    </div>
  ),
  css
);

@CSSModules(css)
class Settings extends Component {
  state = { showPopover: false };

  onRequestClose = () => this.setState({ showPopover: false });

  handleClick = e => {
    e.stopPropagation();
    return this.setState({ showPopover: true });
  };

  handleAction = fn => e => {
    e.stopPropagation();
    this.onRequestClose();
    fn();
  };

  render() {
    const { children, isPublic } = this.props;

    const navItems = [
      {
        title: "Rename",
        icon: "edit",
        onClick: this.handleAction(this.props.showEditName)
      },
      {
        title: "Delete view",
        icon: "remove_circle_outline",
        onClick: this.handleAction(this.props.deleteView)
      },
      {
        title: "Duplicate view",
        icon: "content_copy",
        onClick: this.handleAction(this.props.duplicateView)
      }
    ];

    const visibilityNavItems = [
      {
        title: "Only me",
        icon: "person",
        onClick: () => this.props.toggleIsPublic(false),
        isSelected: !isPublic
      },
      {
        title: "Everyone",
        icon: "group",
        onClick: () => this.props.toggleIsPublic(true),
        isSelected: isPublic
      }
    ];

    return (
      <div>
        <span
          role="button"
          tabIndex="0"
          onClick={this.handleClick}
          ref={ref => {
            this.anchor = ref;
          }}
        >
          {children}
        </span>
        <Popover
          animated={false}
          anchorEl={this.anchor}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          onClose={this.onRequestClose}
          open={this.state.showPopover}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
        >
          <div styleName="container">
            {navItems.map(item => <NavItem key={item.title} {...item} />)}
            <div styleName="separator" />
            <div styleName="sectionTitle">Who can see?</div>
            {visibilityNavItems.map(item => (
              <VisibilityNavItem key={item.title} {...item} />
            ))}
          </div>
        </Popover>
      </div>
    );
  }
}

Settings.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onSelect: PropTypes.func.isRequired,
  toggleIsPublic: PropTypes.func.isRequired,
  showEditName: PropTypes.func.isRequired,
  isPublic: PropTypes.bool.isRequired,
  deleteView: PropTypes.func.isRequired,
  duplicateView: PropTypes.func.isRequired
};

export default Settings;
