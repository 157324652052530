import React from "react";
import PropTypes from "prop-types";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import Groups from "./Groups";
import IconGroup from "./IconGroup";
// import ApprovalsSetupChecklists from "./ApprovalsSetupChecklists";
import QuickActions from "./QuickActions";
import MyApprovalsSidebar from "Event/MyApprovals/View/Sidebar";
import TasksWidget from "Event/TasksWidget/View";

import { Div } from "components/Base";

const Main = props => {
  const {
    canDo,
    handlers,
    params: { eventId },
    router: { push }
  } = props;
  const goToAccountSettings = groupId => {
    push(
      `/event/${eventId}/settings/module/${STANDARD_MODULE_IDS.accounts.id}/types/${groupId}/portal`
    );
  };

  const goToContactSettings = groupId => {
    push(
      `/event/${eventId}/settings/module/${STANDARD_MODULE_IDS.contacts.id}/types/${groupId}`
    );
  };

  const canUserManageAccounts = canDo(
    `${STANDARD_MODULE_IDS.accounts.id}_manage`
  );
  const canUserAddAccounts = canDo(
    `${STANDARD_MODULE_IDS.accounts.id}_accounts_add`
  );
  const canUserManageContacts = canDo(
    `${STANDARD_MODULE_IDS.contacts.id}_manage`
  );
  const canUserAddContacts = canDo(`${STANDARD_MODULE_IDS.contacts.id}_create`);
  const canInviteUsers = canDo(
    `${STANDARD_MODULE_IDS.settings.id}_invite_users`
  );

  return (
    <Div style={{ maxWidth: 880, marginTop: -20 }} display="row">
      <Div display="column">
        <QuickActions
          showAddAccountModal={
            canUserAddAccounts ? handlers.showAddAccountModal : undefined
          }
          showAddContactModal={
            canUserAddContacts ? handlers.showAddContactModal : undefined
          }
          showCreateOrderModal={handlers.showCreateOrderModal}
          showAddFormModal={handlers.showAddFormModal}
          showAddEventTeamUserModal={
            canInviteUsers ? handlers.showAddEventTeamUserModal : undefined
          }
          showSubmitFormModal={handlers.showSubmitFormModal}
        />
        <MyApprovalsSidebar />
        <Groups
          showAddAccountModal={
            canUserAddAccounts ? handlers.showAddAccountModal : undefined
          }
          showAddContactModal={
            canUserAddContacts ? handlers.showAddContactModal : undefined
          }
          goToAccountSettings={
            canUserManageAccounts ? goToAccountSettings : undefined
          }
          goToContactSettings={
            canUserManageContacts ? goToContactSettings : undefined
          }
        />
      </Div>
      <Div display="column" flex={1} ml={4}>
        {/* <ApprovalsSetupChecklists /> */}
        <IconGroup {...props} />
        <TasksWidget />
      </Div>
    </Div>
  );
};

Main.propTypes = {
  params: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  activity: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchActivities: PropTypes.func.isRequired
};

export default Main;
