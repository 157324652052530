import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import * as R from "ramda";

import { actions, getters } from "Forms/WizardModal";
import { actions as scheduleActions } from "Schedules/Create";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_MODULE_FIELDS from "utils/standard-module-field-ids";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";
import {
  CATALOG_CATEGORIES,
  CATALOG_ITEMS
} from "utils/standard-module-field-ids";
import {
  CREDENTIAL_TYPE_ID,
  MEAL_TYPE_ID,
  BOOTH_TYPE_ID,
  SPONSORSHIP_TYPE_ID,
  INVENTORY_TYPE_ID
} from "utils/item-types";
import getLookupValue from "utils/value-types/get-value/lookup";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

import { registerError } from "redux/modules/errors/actions";

import FormWizardModalApi from "Forms/WizardModal/api";
import moduleInventoryApi from "redux/modules/inventory/modules/api";
import recordsApi from "redux/modules/modules/records/api";
import addValueApi from "redux/modules/modules/values/api";
import fieldApi from "redux/modules/modules/fields/api";
import itemTypesApi from "redux/modules/items/types/api";
import modulesApi from "redux/modules/modules/modules/api";
import moduleApi from "redux/modules/modules/module/api";
import recordTypeApi from "redux/modules/modules/recordTypes/api";
import itemBlocksApi from "redux/modules/items/item-blocks/api";
import itemsApi from "redux/modules/items/items/api";

import {
  getSelectedCategories,
  getItemAccountFields,
  getItemContactFields,
  getSelectedCustomModule,
  getApplicationsModuleId,
  getFieldsToShow
} from "Forms/WizardModal/selectors";
import { PAGES, APPLICATIONS_PAGES } from "Forms/WizardModal/constants";
import { getCredentials } from "redux/modules/user/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";

const mapWithIndex = R.addIndex(R.map);

const readOnlyFields = resolveReadOnlyFields({
  moduleId: STANDARD_MODULE_IDS.catalogCategories.id
});
const contactReadOnlyFields = resolveReadOnlyFields({
  moduleId: STANDARD_MODULE_IDS.contacts.id
});
const accountReadOnlyFields = resolveReadOnlyFields({
  moduleId: STANDARD_MODULE_IDS.contacts.id
});

const getParams = function*() {
  const eventId = yield select(getEventId);
  const userId = yield select(getUserId);
  const credentials = yield select(getCredentials);
  const moduleId = STANDARD_MODULE_IDS.catalogCategories.id;
  const eventDetails = yield select(getEventDetails);

  return {
    eventId,
    credentials,
    moduleId,
    userId,
    orgId: eventDetails.org_id,
    eventDetails
  };
};

const addRecordItem = function*({
  payload: { parentCategoryRecordId, value }
}) {
  const { credentials, eventId } = yield call(getParams);
  yield call(recordsApi.post, credentials, {
    eventId,
    moduleId: STANDARD_MODULE_IDS.catalogItems.id,
    record: {
      [STANDARD_MODULE_FIELDS.CATALOG_ITEMS.ITEM_NAME]: {
        type: "text",
        value
      },
      [STANDARD_MODULE_FIELDS.CATALOG_ITEMS.CATEGORY]: {
        type: "lookup",
        value: {
          moduleId: STANDARD_MODULE_IDS.catalogCategories.id,
          records: [parentCategoryRecordId]
        }
      }
    },
    options: {
      eventId
    }
  });
  yield put(actions.getInventoryCategories(true));
};

const addRecordItems = function*({
  payload: { parentCategoryRecordId, value }
}) {
  const { credentials, eventId } = yield call(getParams);

  yield call(recordsApi.bulkPost, credentials, {
    records: value.map(v => ({
      eventId,
      moduleId: STANDARD_MODULE_IDS.catalogItems.id,
      record: {
        [STANDARD_MODULE_FIELDS.CATALOG_ITEMS.ITEM_NAME]: {
          type: "text",
          value: v
        },
        [STANDARD_MODULE_FIELDS.CATALOG_ITEMS.CATEGORY]: {
          type: "lookup",
          value: {
            moduleId: STANDARD_MODULE_IDS.catalogCategories.id,
            records: [parentCategoryRecordId]
          }
        }
      }
    })),
    options: {
      eventId
    }
  });
  yield put(actions.getInventoryCategories(true));
};

const updateCategory = function*({ payload: { value, categoryRecordId } }) {
  try {
    const { eventId, credentials } = yield call(getParams);
    yield call(addValueApi.post, credentials, {
      eventId,
      fieldId: STANDARD_MODULE_FIELDS.CATALOG_CATEGORIES.NAME,
      moduleId: STANDARD_MODULE_IDS.catalogCategories.id,
      recordId: categoryRecordId,
      value: {
        type: "text",
        value
      }
    });

    yield put(actions.getInventoryCategories(true));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "Error updating category"
        }
      ])
    );
  }
};

const updateItem = function*({ payload: { value, itemRecordId } }) {
  try {
    const { eventId, credentials } = yield call(getParams);

    yield call(addValueApi.post, credentials, {
      eventId,
      fieldId: STANDARD_MODULE_FIELDS.CATALOG_ITEMS.ITEM_NAME,
      moduleId: STANDARD_MODULE_IDS.catalogItems.id,
      recordId: itemRecordId,
      value: {
        type: "text",
        value
      }
    });

    yield put(actions.getInventoryCategories(true));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "Error updating item"
        }
      ])
    );
  }
};

const deleteItem = function*({ payload: { itemRecordId } }) {
  const { credentials, eventId, orgId } = yield call(getParams);
  try {
    yield call(recordsApi.delete, credentials, {
      moduleId: STANDARD_MODULE_IDS.catalogItems.id,
      orgId,
      eventId,
      record: { id: itemRecordId },
      options: {
        orgId,
        eventId
      }
    });

    yield put(actions.getInventoryCategories(true));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "Error deleting item"
        }
      ])
    );
  }
};

const saveInventoryForm = function*() {
  const state = yield select(R.identity);
  const { eventId, userId, credentials } = yield call(getParams);
  const selectedInventoryCategories = getSelectedCategories(state);
  const requestType = yield select(getters.formMethod);
  const formName = yield select(getters.formName);
  const formTitle = yield select(getters.formTitle);
  const formCloseDate = yield select(getters.formCloseDate);
  const formIsLocked = yield select(getters.formIsLocked);
  const showOnEventHome = yield select(getters.showOnEventHome);
  const moduleColor = yield select(getters.moduleColor);
  const moduleIcon = yield select(getters.moduleIcon);
  const eventDetails = yield select(getEventDetails);

  const data = {
    event_id: eventId,
    user_id: userId,
    wizard_type: "inventory",
    request_type: requestType || "single",
    form_name: formName,
    form_title: formTitle,
    form_close_date: formCloseDate,
    form_is_locked: formIsLocked,
    selected_inventory_modules: R.map(m => ({
      id: m.id,
      fields_to_show: R.compose(
        R.map(f => ({
          id: f.id,
          required: f.required
        })),
        R.filter(R.prop("selected"))
      )(m.fields)
    }))(selectedInventoryCategories),
    show_on_event_home: showOnEventHome,
    module_color: moduleColor,
    module_icon: moduleIcon
  };

  yield put(actions.setSaving(true));
  try {
    const { payload } = yield call(FormWizardModalApi.postForm, {
      credentials,
      data
    });
    const moduleId = payload.modules_id[0];
    const formId = payload.forms_id[0];

    if (
      ["single", "bulk"].includes(requestType) &&
      selectedInventoryCategories.length > 1
    ) {
      if (eventDetails.is_light) {
        window.location = `/event-light/${eventId}/forms`;
      } else {
        window.location = `/event/${eventId}/forms-v2`;
      }
    } else {
      if (eventDetails.is_light) {
        window.location = `/event-light/${eventId}/forms/${formId}/builder`;
      } else {
        window.location = `/event/${eventId}/module/${moduleId}/form/${formId}`;
      }
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error creating form"
        }
      ])
    );
  }
};

const configureGroupRegistration = function*() {
  const { eventId } = yield call(getParams);
  window.location = `/event/${eventId}/settings/module/${STANDARD_MODULE_IDS.accounts.id}/types`;
};

const savePassesForm = function*() {
  const state = yield select(R.identity);
  const { eventId, userId, credentials, eventDetails } = yield call(getParams);
  const requestType = yield select(getters.passesType);
  const itemTypes = yield select(getters.itemTypes);
  const formName = yield select(getters.formName);
  const formTitle = yield select(getters.formTitle);
  const formCloseDate = yield select(getters.formCloseDate);
  const formIsLocked = yield select(getters.formIsLocked);
  const accountTypeId = yield select(getters.itemAccountTypeId);
  const contactTypeId = yield select(getters.itemContactTypeId);
  const selectedItemBlocks = yield select(getters.selectedItemBlocks);
  const recordTypes = yield select(getters.recordTypes);
  const selectedRecordType = recordTypes.find(r => r.selected);

  const toggledVariants = yield select(getters.toggledVariants);
  const selectedVariants = yield select(getters.selectedVariants);
  const selectedVariantPrices = yield select(getters.selectedVariantPrices);
  const showOnEventHome = yield select(getters.showOnEventHome);
  const moduleColor = yield select(getters.moduleColor);
  const moduleIcon = yield select(getters.moduleIcon);
  const accountSelectedTypeId = yield select(getters.accountSelectedTypeId);
  const contactSelectedTypeId = yield select(getters.contactSelectedTypeId);

  const variantsMap = R.reduce((map, type) => {
    if (
      toggledVariants[type.id] &&
      Object.keys(toggledVariants[type.id]).length
    ) {
      map[type.id] = R.compose(
        mapWithIndex((variantId, idx) => ({
          id: variantId,
          priceId: selectedVariantPrices[variantId] || null,
          limit: selectedVariants[variantId] || null,
          order: idx
        })),
        R.filter(variantId => toggledVariants[type.id][variantId]),
        R.keys
      )(toggledVariants[type.id]);
    }
    return map;
  }, {})(itemTypes.types);

  const accountFields = R.compose(
    mapWithIndex((field, idx) => ({
      id: field.id,
      required: field.required,
      order: idx
    })),
    R.filter(R.prop("selected"))
  )(getItemAccountFields(state));

  const contactFields = R.compose(
    mapWithIndex((field, idx) => ({
      id: field.id,
      required: field.required,
      order: idx
    })),
    R.filter(R.prop("selected"))
  )(getItemContactFields(state));

  const data = {
    event_id: eventId,
    user_id: userId,
    wizard_type: "passes",
    request_type: requestType,
    form_name: formName,
    form_title: formTitle,
    form_close_date: formCloseDate,
    form_is_locked: formIsLocked,

    record_type_id: selectedRecordType,
    account_type_id: accountTypeId,
    contact_type_id: contactTypeId,

    selected_item_blocks: selectedItemBlocks || [],
    selected_variants_map: variantsMap,
    account_fields: accountFields,
    contact_fields: contactFields,
    show_on_event_home: showOnEventHome,
    module_color: moduleColor,
    module_icon: moduleIcon,

    form_account_type_id: accountSelectedTypeId,
    form_contact_type_id: contactSelectedTypeId
  };

  yield put(actions.setSaving(true));
  try {
    const { payload } = yield call(FormWizardModalApi.postForm, {
      credentials,
      data
    });

    if (eventDetails.is_light) {
      window.location = `/event-light/${eventId}/forms/${payload.form_id}/builder`;
    } else {
      window.location = `/event/${eventId}/module/${payload.module_id}/form/${payload.form_id}`;
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error creating form"
        }
      ])
    );
  }
};

const saveModuleForm = function*() {
  const state = yield select(R.identity);
  const { eventId, userId, credentials, eventDetails } = yield call(getParams);
  const formName = yield select(getters.formName);
  const formTitle = yield select(getters.formTitle);
  const formCloseDate = yield select(getters.formCloseDate);
  const formIsLocked = yield select(getters.formIsLocked);
  const selectedModule = getSelectedCustomModule(state);
  const showOnEventHome = yield select(getters.showOnEventHome);
  const moduleColor = yield select(getters.moduleColor);
  const moduleIcon = yield select(getters.moduleIcon);
  const selectedPage = yield select(getters.selectedPage);
  const scheduleId = yield select(getters.scheduleId);
  const fieldsToShow = yield select(getFieldsToShow);

  const moduleId =
    selectedPage === PAGES.CUSTOM ? selectedModule.id : scheduleId;

  const data = {
    event_id: eventId,
    user_id: userId,
    wizard_type: "module",
    request_type: "single",
    module_id: moduleId,
    fields_to_show: fieldsToShow,
    form_name: formName,
    form_title: formTitle,
    form_close_date: formCloseDate,
    form_is_locked: formIsLocked,
    show_on_event_home: showOnEventHome,
    module_color: moduleColor,
    module_icon: moduleIcon
  };

  yield put(actions.setSaving(true));
  try {
    const { payload } = yield call(FormWizardModalApi.postForm, {
      credentials,
      data
    });
    if (eventDetails.is_light) {
      window.location = `/event-light/${eventId}/forms/${payload.form_id}/builder`;
    } else {
      window.location = `/event/${eventId}/module/${payload.module_id}/form/${payload.form_id}`;
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error creating form"
        }
      ])
    );
  }
};

const saveApplicationForm = function*() {
  const { eventId, userId, credentials, eventDetails } = yield call(getParams);
  const formName = yield select(getters.formName);
  const formTitle = yield select(getters.formTitle);
  const itemTypes = yield select(getters.itemTypes);
  const formCloseDate = yield select(getters.formCloseDate);
  const formIsLocked = yield select(getters.formIsLocked);
  const requestType = yield select(getters.requestType);
  const recordTypes = yield select(getters.recordTypes);
  const selectedRecordType = recordTypes.find(r => r.selected);
  const fields = yield select(getters.applicationsSelectFields);
  const selectedItemBlocks = yield select(getters.selectedItemBlocks);
  const toggledVariants = yield select(getters.toggledVariants);
  const selectedVariants = yield select(getters.selectedVariants);
  const selectedVariantPrices = yield select(getters.selectedVariantPrices);
  const showOnEventHome = yield select(getters.showOnEventHome);
  const moduleColor = yield select(getters.moduleColor);
  const moduleIcon = yield select(getters.moduleIcon);
  const accountTypeId = yield select(getters.itemAccountTypeId);
  const contactTypeId = yield select(getters.itemContactTypeId);
  const primaryContactTypeId = yield select(getters.primaryContactTypeId);
  const attendeeContactTypeId = yield select(getters.attendeeContactTypeId);

  const variantsMap = R.reduce((map, type) => {
    if (
      toggledVariants[type.id] &&
      Object.keys(toggledVariants[type.id]).length
    ) {
      map[type.id] = R.compose(
        mapWithIndex((variantId, idx) => ({
          id: variantId,
          priceId: selectedVariantPrices[variantId] || null,
          limit: selectedVariants[variantId] || null,
          order: idx
        })),
        R.filter(variantId => toggledVariants[type.id][variantId]),
        R.keys
      )(toggledVariants[type.id]);
    }
    return map;
  }, {})(itemTypes.types);

  const accountFields = R.compose(
    mapWithIndex((field, idx) => ({
      id: field.id,
      required: field.required,
      order: idx
    })),
    R.filter(R.prop("selected")),
    R.pathOr([], ["accountFields", "list"])
  )(fields);

  const isPrimaryContactSectionEnabled = R.pathOr(false, [
    "contactFields",
    "enabled"
  ])(fields);

  const primaryConactFields =
    isPrimaryContactSectionEnabled || requestType === "individual"
      ? R.compose(
          mapWithIndex((field, idx) => ({
            id: field.id,
            required: field.required,
            order: idx
          })),
          R.filter(R.prop("selected")),
          R.pathOr([], ["contactFields", "list"])
        )(fields)
      : [];

  const isAttendeeContactSectionEnabled = R.pathOr(false, [
    "attendeeFields",
    "enabled"
  ])(fields);

  const attendeeConactFields = isAttendeeContactSectionEnabled
    ? R.compose(
        mapWithIndex((field, idx) => ({
          id: field.id,
          required: field.required,
          order: idx
        })),
        R.filter(R.prop("selected")),
        R.pathOr([], ["attendeeFields", "list"])
      )(fields)
    : [];

  const data = {
    event_id: eventId,
    user_id: userId,
    wizard_type: "application",
    request_type: requestType,
    form_name: formName,
    form_title: formTitle,
    form_close_date: formCloseDate,
    form_is_locked: formIsLocked,
    account_fields: accountFields,

    record_type_id: selectedRecordType,
    account_type_id: accountTypeId,
    contact_type_id: contactTypeId,

    is_primary_contact_section_enabled: isPrimaryContactSectionEnabled,
    primary_contact_fields: primaryConactFields,

    is_attendee_contact_section_enabled: isAttendeeContactSectionEnabled,
    attendee_contact_fields: attendeeConactFields,

    selected_item_blocks: selectedItemBlocks || [],
    selected_variants_map: variantsMap,
    show_on_event_home: showOnEventHome,
    module_color: moduleColor,
    module_icon: moduleIcon,

    primary_contact_type_id: primaryContactTypeId,
    attendee_contact_type_id: attendeeContactTypeId
  };

  yield put(actions.setSaving(true));
  try {
    const { payload } = yield call(FormWizardModalApi.postForm, {
      credentials,
      data
    });
    if (eventDetails.is_light) {
      window.location = `/event-light/${eventId}/forms/${payload.form_id}/builder`;
    } else {
      window.location = `/event/${eventId}/module/${payload.module_id}/form/${payload.form_id}`;
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error creating form"
        }
      ])
    );
  }
};

const getCategoryFields = function*({ payload: id }) {
  const { eventId, credentials } = yield call(getParams);
  try {
    const { fields } = yield call(fieldApi.get, credentials, {
      moduleId: id,
      options: { eventId }
    });
    const mappedFields = R.filter(
      f => !readOnlyFields.includes(f.id),
      fields.fields
    );
    yield put(actions.addCategoryField({ mappedFields, categoryId: id }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error adding the field"
        }
      ])
    );
  }
};

const updateEditedField = function*({ payload: { categoryId, fieldId } }) {
  const { eventId, credentials } = yield call(getParams);
  try {
    const { fields } = yield call(fieldApi.get, credentials, {
      moduleId: categoryId,
      options: { eventId }
    });
    const mappedFields = R.filter(
      f => !readOnlyFields.includes(f.id),
      fields.fields
    );
    yield put(
      actions.updateCategoryField({ mappedFields, categoryId, fieldId })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error adding the field"
        }
      ])
    );
  }
};

const getInventoryCategories = function*({ payload: refresh }) {
  const { eventId, credentials } = yield call(getParams);

  if (!refresh) {
    yield put(actions.setLoading(true));
  }

  try {
    // get categories, modules with catalog items, and catalog items
    const modulesResponse = yield all([
      call(recordsApi.get, credentials, {
        moduleId: STANDARD_MODULE_IDS.catalogCategories.id,
        options: { eventId }
      }),
      call(recordsApi.get, credentials, {
        moduleId: STANDARD_MODULE_IDS.catalogItems.id,
        options: { eventId }
      }),
      call(moduleInventoryApi.get, credentials, eventId, "catalog-item")
    ]);

    const categoryRecords = R.propOr([], "records", modulesResponse[0].records);

    const itemRecords = R.propOr([], "records", modulesResponse[1].records);

    const categoryModules = R.propOr({}, "payload", modulesResponse[2]);

    // key items by category record id
    const groupedItemsByCategoryRecordId = R.compose(
      R.groupBy(R.prop("categoryRecordId")),
      R.map(itemRecord => ({
        id: itemRecord.id,
        name: R.pathOr(
          null,
          ["values", CATALOG_ITEMS.ITEM_NAME, "value"],
          itemRecord
        ),
        categoryRecordId: R.pathOr(
          null,
          ["records", 0],
          getLookupValue(
            R.pathOr(null, ["values", CATALOG_ITEMS.CATEGORY], itemRecord)
          )
        )
      }))
    )(itemRecords);

    // format categories
    const categoryRecordsByIdAndName = categoryRecords.map(categoryRecord => {
      const name = R.pathOr(null, ["values", CATALOG_CATEGORIES.NAME, "value"])(
        categoryRecord
      );
      return {
        id: categoryRecord.id,
        name: name,
        items: R.propOr([], categoryRecord.id)(groupedItemsByCategoryRecordId)
      };
    });

    // merge category modules and records
    const mergedCategoryModulesAndRecords = categoryModules.map(
      categoryModule => {
        // find the matching category record by name
        const categoryRecordMatchByName = categoryRecordsByIdAndName.find(
          record => record.name === categoryModule.name
        );

        // if no match, return null and we will filter these categories out
        if (!categoryRecordMatchByName) return null;

        return {
          id: categoryModule.id,
          name: categoryRecordMatchByName.name,
          categoryRecordId: categoryRecordMatchByName.id,
          items: categoryRecordMatchByName.items
        };
      }
    );
    const byName = R.ascend(R.prop("name"));
    const sortedCategories = R.compose(
      R.sort(byName),
      R.filter(cat => !R.isNil(cat))
    )(mergedCategoryModulesAndRecords);

    // get fields for each category
    const responses = yield all(
      sortedCategories.map(({ id }) =>
        call(fieldApi.get, credentials, {
          moduleId: id,
          options: { eventId }
        })
      )
    );

    // attach fields to category
    const mappedFields = R.compose(
      R.map(fields => R.filter(f => !readOnlyFields.includes(f.id), fields)),
      R.map(f => f.fields.fields)
    )(responses);
    const inventoryCategories = sortedCategories.map((category, index) => ({
      ...category,
      fields: mappedFields[index]
    }));

    yield put(actions.setDataAfterLoadCategories({ inventoryCategories }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred loading the categories"
        }
      ])
    );
  }
};

const getItemTypes = function*({ payload: { refresh, defaultTypeId } = {} }) {
  const { eventId, credentials } = yield call(getParams);

  if (!refresh) {
    yield put(actions.setLoading(true));
  }

  try {
    const [
      itemTypesResponse,
      accountFieldsResponse,
      contactFieldsResponse
    ] = yield all([
      call(itemTypesApi.getItemTypesByEvent, credentials, eventId),
      call(fieldApi.get, credentials, {
        moduleId: STANDARD_MODULE_IDS.accounts.id,
        options: { eventId }
      }),
      call(fieldApi.get, credentials, {
        moduleId: STANDARD_MODULE_IDS.contacts.id,
        options: { eventId }
      }),
      put(actions.getItemBlocks())
    ]);

    yield put(
      actions.addPassesContactsAccountsInitalState({
        contactFields: R.filter(
          f => !contactReadOnlyFields.includes(f.id),
          contactFieldsResponse.fields.fields
        ),
        accountFields: R.filter(
          f => !accountReadOnlyFields.includes(f.id),
          accountFieldsResponse.fields.fields
        )
      })
    );

    const { types, itemGroups, items, variants } = itemTypesResponse.payload
      .filter(t =>
        [
          CREDENTIAL_TYPE_ID,
          MEAL_TYPE_ID,
          BOOTH_TYPE_ID,
          SPONSORSHIP_TYPE_ID,
          INVENTORY_TYPE_ID
        ].includes(t.id)
      )
      .reduce(
        (list, type) => {
          list.types.push(type);
          type.groups.forEach(group => {
            list.itemGroups.push(group);
            group.items.forEach(item => {
              list.items.push(item);
              item.variants.forEach(variant => {
                list.variants.push({
                  ...variant,
                  type_id: group.type_id,
                  item
                });
              });
            });
          });
          return list;
        },
        {
          types: [],
          itemGroups: [],
          items: [],
          variants: []
        }
      );

    if (defaultTypeId) {
      yield put(actions.setActiveItemTypeId(defaultTypeId));
    }

    yield put(
      actions.setItemTypes({
        types,
        itemGroups,
        items,
        variants
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred loading item types"
        }
      ])
    );
  }
  yield put(actions.setLoading(false));
};

const getItemBlocks = function*() {
  const { eventId, credentials } = yield call(getParams);

  try {
    const itemBlocksResponse = yield call(
      itemBlocksApi.get,
      credentials,
      eventId
    );

    const itemBlocks = R.reduce((byType, block) => {
      if (byType[block.item_type_id]) {
        byType[block.item_type_id].push(block);
      } else {
        byType[block.item_type_id] = [block];
      }
      return byType;
    }, {})(itemBlocksResponse.payload);

    yield put(actions.setItemBlocks(itemBlocks));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred loading item blocks"
        }
      ])
    );
  }
};

const addItem = function*({ payload }) {
  const { credentials } = yield call(getParams);
  try {
    yield call(itemsApi.addItem, credentials, payload);
    yield put(actions.getItemTypes({ refresh: true }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error adding the item"
        }
      ])
    );
  }
};

const getModulesWithFields = function*() {
  const { eventId, credentials } = yield call(getParams);
  yield put(actions.setLoading(true));
  try {
    const response = yield call(modulesApi.get, credentials, eventId, {
      expand: "fields"
    });
    yield put(
      actions.setInitialModuleFields({
        moduleFields: R.compose(
          R.sortBy(m => m.name.toLowerCase()),
          R.filter(m => m.source === "custom"),
          R.propOr([], "modules")
        )(response)
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "There was an error getting module fields"
        }
      ])
    );
  }
  yield put(actions.setLoading(false));
};

const getSchedulesWithFields = function*() {
  const { eventId, credentials } = yield call(getParams);
  yield put(actions.setLoading(true));
  try {
    const response = yield call(modulesApi.get, credentials, eventId, {
      expand: "fields"
    });

    yield put(
      actions.setInitialScheduleFields({
        scheduleFields: R.compose(
          R.filter(m => m.type_id === STANDARD_MODULES.schedules.id),
          R.sortBy(m => m.name.toLowerCase()),
          R.filter(m => m.source === "custom"),
          R.propOr([], "modules")
        )(response),
        showCreateModal: false
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "There was an error getting schedule fields"
        }
      ])
    );
  }
  yield put(actions.setLoading(false));
};

const createNewModule = function*() {
  const name = yield select(getters.newModuleName);

  if (R.length(name) >= 2) {
    const { eventId, credentials } = yield call(getParams);
    yield put(actions.setLoading(true));
    const data = {
      color: "rgb(142,185,223)",
      eventId,
      hasApproval: true,
      icon: "list",
      internalName: R.compose(
        R.replace(/ /g, "-"),
        R.toLower
      )(name),
      name,
      recordName: "Record",
      recordNamePlural: "Records",
      recordPrefix: "MOD",
      showInNavigation: true,
      typeId: STANDARD_MODULE_IDS.formsv3.id
    };
    try {
      const response = yield call(moduleApi.post, credentials, data);
      const moduleId = R.propOr("", "id", response.module);
      const payload = yield call(modulesApi.get, credentials, eventId, {
        expand: "fields"
      });
      yield put(
        actions.addNewModule({
          moduleFields: R.compose(
            R.sortBy(m => m.name.toLowerCase()),
            R.filter(m => m.source === "custom"),
            R.propOr([], "modules")
          )(payload),
          moduleId,
          isblankForm: true
        })
      );
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error adding the field"
          }
        ])
      );
    }
    yield put(actions.setLoading(false));
  }
};

const getCustomModuleFields = function*({ payload: { moduleId, fieldId } }) {
  const { eventId, credentials } = yield call(getParams);
  try {
    const { fields } = yield call(fieldApi.get, credentials, {
      moduleId,
      options: { eventId }
    });
    const mappedFields = R.filter(
      f => !readOnlyFields.includes(f.id),
      fields.fields
    );
    if (R.isEmpty(fieldId)) {
      yield put(actions.addCustomModuleFields({ mappedFields, moduleId }));
    } else {
      yield put(
        actions.editCustomModuleFields({ mappedFields, moduleId, fieldId })
      );
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error adding the field"
        }
      ])
    );
  }
};

const getScheduleFields = function*({ payload: { moduleId } }) {
  const { eventId, credentials } = yield call(getParams);
  try {
    const { fields } = yield call(fieldApi.get, credentials, {
      moduleId,
      options: { eventId }
    });
    const mappedFields = R.filter(
      f => !readOnlyFields.includes(f.id),
      fields.fields
    );

    yield put(actions.updateScheduleFields({ fields: mappedFields }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error ocurred adding schedule field"
        }
      ])
    );
  }
};

const getRecordTypes = function*({
  payload: { requestType, goToApplications = true }
}) {
  const { eventId, credentials } = yield call(getParams);
  yield put(actions.setLoading(true));
  try {
    const [accountTypes, contactTypes] = yield all([
      yield call(recordTypeApi.get, credentials, {
        moduleId: STANDARD_MODULE_IDS.accounts.id,
        options: { eventId }
      }),
      yield call(recordTypeApi.get, credentials, {
        moduleId: STANDARD_MODULE_IDS.contacts.id,
        options: { eventId }
      })
    ]);
    const recordTypes =
      requestType === "individual" ? contactTypes : accountTypes;
    yield put(
      actions.goToApplicationsSelectAccounts({
        requestType,
        recordTypes: R.propOr([], "record_types", recordTypes),
        accountTypes: R.propOr([], "record_types", accountTypes),
        contactTypes: R.propOr([], "record_types", contactTypes),
        goToApplications
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error getting the record types"
        }
      ])
    );
  }
  yield put(actions.setLoading(false));
};

const refreshRecordTypes = function*() {
  const { eventId, credentials } = yield call(getParams);
  yield put(actions.setLoading(true));
  const requestType = yield select(getters.requestType);
  try {
    const moduleIdToUse =
      requestType === "individual"
        ? STANDARD_MODULE_IDS.contacts.id
        : STANDARD_MODULE_IDS.accounts.id;
    const payload = yield call(recordTypeApi.get, credentials, {
      moduleId: moduleIdToUse,
      options: { eventId }
    });

    yield put(
      actions.updateRecordTypes({
        recordTypes: R.propOr([], "record_types", payload)
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error getting the record types"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const getAccountsAndContactsFields = function*({
  payload: { recordTypeId, recordTypeName }
}) {
  const { eventId, credentials } = yield call(getParams);
  const selectedPage = yield select(getters.selectedPage);

  const formName = R.propOr(`${recordTypeName} Application`, selectedPage)({
    [PAGES.PASSES]: "Request Form"
  });
  yield all([
    put(actions.setLoading(true)),
    put(actions.setFormName(formName)),
    put(actions.setFormTitle(formName))
  ]);

  try {
    const [accountFields, contactFields] = yield all([
      call(fieldApi.get, credentials, {
        moduleId: STANDARD_MODULE_IDS.accounts.id,
        options: { eventId }
      }),
      call(fieldApi.get, credentials, {
        moduleId: STANDARD_MODULE_IDS.contacts.id,
        options: { eventId }
      })
    ]);
    yield put(
      actions.addApplicationsSelectFields({
        accountFields,
        contactFields,
        recordTypeId
      })
    );

    yield put(actions.resetTypeId());

    if (selectedPage === PAGES.APPLICATIONS) {
      yield put(
        actions.setSelectedApplications(APPLICATIONS_PAGES.SELECT_FIELDS)
      );
    }

    if (selectedPage === PAGES.PASSES) {
      yield put(actions.getItemTypes());
      yield put(actions.goToSelectPasses());
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error getting the fields"
        }
      ])
    );
  }
  yield put(actions.setLoading(false));
};

const getApplicationsGroupField = function*() {
  const moduleId = yield select(getApplicationsModuleId);
  const { eventId, credentials } = yield call(getParams);
  yield put(actions.setLoading(true));
  try {
    const { fields } = yield call(fieldApi.get, credentials, {
      moduleId,
      options: { eventId }
    });
    yield put(
      actions.addApplicationsField({
        fields: R.propOr([], "fields", fields),
        moduleId
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error getting the fields"
        }
      ])
    );
  }
  yield put(actions.setLoading(false));
};

const addRecordType = function*({ payload: data }) {
  const { eventId, credentials, orgId } = yield call(getParams);
  const requestType = yield select(getters.requestType);
  const moduleId =
    requestType === "group"
      ? STANDARD_MODULE_IDS.accounts.id
      : STANDARD_MODULE_IDS.contacts.id;

  try {
    const { layout } = yield call(FormWizardModalApi.addLayout, {
      credentials,
      data: {
        layout: { eventId, orgId },
        moduleId
      }
    });

    yield call(FormWizardModalApi.addRecordType, {
      credentials,
      data: {
        options: {
          eventId
        },
        moduleId,
        recordType: {
          ...data,
          layoutId: layout.id
        }
      }
    });

    yield put(actions.refreshRecordTypes());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error getting the fields"
        }
      ])
    );
  }
};

const saveNewSchedule = function*() {
  yield put(actions.setShowCreateModal(false));
  yield call(getSchedulesWithFields);
};

const showScheduleCreateModal = function*() {
  yield put(actions.setShowCreateModal(true));
  yield put(scheduleActions.setRedirectAfterSave(false));
};

const watchGetApplicationsGroupField = function*() {
  yield takeEvery(
    actions.getApplicationsGroupField.type,
    getApplicationsGroupField
  );
};

const watchAccountsAndContactsFields = function*() {
  yield takeEvery(
    actions.getAccountsAndContactsFields.type,
    getAccountsAndContactsFields
  );
};

const watchCategoriesFields = function*() {
  yield takeEvery(actions.getCategoryFields.type, getCategoryFields);
};

const watchUpdateField = function*() {
  yield takeEvery(actions.updateEditedField.type, updateEditedField);
};

const watchInventoryCategories = function*() {
  yield takeEvery(actions.getInventoryCategories.type, getInventoryCategories);
};

const watchSaveInventoryForm = function*() {
  yield takeEvery(actions.saveInventoryForm.type, saveInventoryForm);
};

const watchSavePassesForm = function*() {
  yield takeEvery(actions.savePassesForm.type, savePassesForm);
};

const watchSaveModuleForm = function*() {
  yield takeEvery(actions.saveModuleForm.type, saveModuleForm);
};

const watchSaveApplicationForm = function*() {
  yield takeEvery(actions.saveApplicationForm.type, saveApplicationForm);
};

const watchAddRecordItem = function*() {
  yield takeEvery(actions.addRecordItem.type, addRecordItem);
};

const watchAddRecordItems = function*() {
  yield takeEvery(actions.addRecordItems.type, addRecordItems);
};

const watchUpdateCategory = function*() {
  yield takeEvery(actions.updateCategory.type, updateCategory);
};

const watchUpdateItem = function*() {
  yield takeEvery(actions.updateItem.type, updateItem);
};

const watchDeleteItem = function*() {
  yield takeEvery(actions.deleteItem.type, deleteItem);
};

const watchItemTypes = function*() {
  yield takeEvery(actions.getItemTypes.type, getItemTypes);
};

const watchItemBlocks = function*() {
  yield takeEvery(actions.getItemBlocks.type, getItemBlocks);
};

const watchAddItem = function*() {
  yield takeEvery(actions.addItem.type, addItem);
};

const watchGetModuleFields = function*() {
  yield takeEvery(actions.getModulesWithFields.type, getModulesWithFields);
};

const watchCreateNewModule = function*() {
  yield takeEvery(actions.createNewModule.type, createNewModule);
};

const watchGetCustomModuleFields = function*() {
  yield takeEvery(actions.getCustomModuleFields.type, getCustomModuleFields);
};

const watchGetRecordTypes = function*() {
  yield takeEvery(actions.getRecordTypes.type, getRecordTypes);
};

const watchConfigureGroupRegistration = function*() {
  yield takeEvery(
    actions.configureGroupRegistration.type,
    configureGroupRegistration
  );
};

const watchAddRecordType = function*() {
  yield takeEvery(actions.addRecordType.type, addRecordType);
};

const watchRefreshRecordTypes = function*() {
  yield takeEvery(actions.refreshRecordTypes.type, refreshRecordTypes);
};

const watchGetSchedulesWithFields = function*() {
  yield takeEvery(actions.getSchedulesWithFields.type, getSchedulesWithFields);
};

const watchGetScheduleFields = function*() {
  yield takeEvery(actions.getScheduleFields.type, getScheduleFields);
};

const watchGetScheduleFieldsAfterSave = function*() {
  yield takeEvery(
    scheduleActions.createNewScheduleResponse.type,
    saveNewSchedule
  );
};

const watchShowScheduleCreateModal = function*() {
  yield takeEvery(
    actions.showScheduleCreateModal.type,
    showScheduleCreateModal
  );
};

const rootSaga = function*() {
  yield all([
    fork(watchInventoryCategories),
    fork(watchCategoriesFields),
    fork(watchUpdateField),
    fork(watchSaveInventoryForm),
    fork(watchSavePassesForm),
    fork(watchSaveModuleForm),
    fork(watchSaveApplicationForm),
    fork(watchAddRecordItem),
    fork(watchAddRecordItems),
    fork(watchUpdateCategory),
    fork(watchUpdateItem),
    fork(watchDeleteItem),
    fork(watchItemTypes),
    fork(watchItemBlocks),
    fork(watchAddItem),
    fork(watchGetModuleFields),
    fork(watchCreateNewModule),
    fork(watchGetCustomModuleFields),
    fork(watchGetRecordTypes),
    fork(watchConfigureGroupRegistration),
    fork(watchAccountsAndContactsFields),
    fork(watchGetApplicationsGroupField),
    fork(watchAddRecordType),
    fork(watchRefreshRecordTypes),
    fork(watchGetSchedulesWithFields),
    fork(watchGetScheduleFields),
    fork(watchShowScheduleCreateModal),
    fork(watchGetScheduleFieldsAfterSave)
  ]);
};

export default rootSaga;
