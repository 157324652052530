import React from "react";
import { Page, Title } from "EventLight/Common/FieldLayout";
import { BOOTH_TYPE_ID } from "utils/item-types";

import Catalog from "EventLight/Common/Items/Catalog/View";

const Layout = () => (
  <>
    <Page>
      <Title
        icon="storefront"
        title="Booths"
        description="Manage the types of booths that exhibitors can have (e.g. 10x20, Corner Booth, Virtual Booth)"
      />
      <Catalog typeId={BOOTH_TYPE_ID} />
    </Page>
  </>
);

export default Layout;
