import { createSelector } from "reselect";

import { getters } from "./model";

import * as R from "ramda";

export const getFilteredQuestions = createSelector(
  getters.questions,
  getters.questionsFilter,
  (questions, questionsFilter) => {
    if (R.isEmpty(questionsFilter)) {
      return questions;
    }
    return R.filter(
      field => R.contains(R.toLower(questionsFilter), R.toLower(field.name)),
      questions
    );
  }
);

export const getFilteredSets = createSelector(
  getters.sets,
  getters.questionsFilter,
  (sets, questionsFilter) => {
    if (R.isEmpty(questionsFilter)) {
      return sets;
    }
    return R.filter(
      field => R.contains(R.toLower(questionsFilter), R.toLower(field.name)),
      sets
    );
  }
);

export const getFilteredExistingSets = createSelector(
  getters.sets,
  getters.existingSetsFilter,
  (sets, existingSetsFilter) => {
    if (R.isEmpty(existingSetsFilter)) {
      return sets;
    }
    return R.filter(
      field => R.contains(R.toLower(existingSetsFilter), R.toLower(field.name)),
      sets
    );
  }
);

export const getIsAllSelectedQuestions = createSelector(
  getters.questions,
  getters.itemQuestionsIds,
  (questions, itemQuestionsIds) =>
    R.length(itemQuestionsIds) === R.length(questions)
);

export const getIsAllSelectedSets = createSelector(
  getters.sets,
  getters.setsIds,
  (sets, setsIds) => R.length(setsIds) === R.length(sets)
);
