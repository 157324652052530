import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { Div } from "components/Base";
import { MODES } from "../../../constants";

@CSSModules(css)
class Confirm extends Component {
  render() {
    // const validFilter =
    //   this.props.mode === MODES.SMS
    //     ? r => r.meta.hasValidPhone
    //     : r => r.meta.hasValidEmail;
    // const validRecipients = this.props.recipients.filter(validFilter);

    return (
      <div styleName="checkWrapper">
        <div styleName="checkIcon">
          <i className="material-icons">send</i>
        </div>
        <Div display="column.center">
          <Div
            display="row.center"
            fw={3}
            mt={3}
            style={{
              maxWidth: "400px",
              textAlign: "center",
              marginBottom: "30px"
            }}
          >
            {/*
            // @Note: Removing since we dont calculate valid vs invalid on client anymore
            Just to confirm - You're about to send {validRecipients.length}{" "}
            {this.props.mode === MODES.SMS
              ? validRecipients.length === 1
                ? "message"
                : "messages"
              : validRecipients.length === 1
              ? "email"
              : "emails"}
            .
            */}
            Just to confirm - You're about to send{" "}
            {this.props.mode === MODES.SMS ? "messages" : "emails"} to all valid
            recipients. You can return to the "Preview" step to re-check any
            valid or invalid recipients.
          </Div>
          <Div display="row.center" mb={3}>
            Ready to go? Hit "Send" below.
          </Div>
        </Div>
        <Div
          onClick={this.props.sending ? undefined : this.props.onConfirm}
          bra={1}
          p={3}
          fs={3}
          fw={3}
          color="white"
          bg="success7"
        >
          {this.props.sending ? (
            "Sending..."
          ) : (
            <span>
              Send {this.props.mode === MODES.SMS ? "messages" : "emails"}
            </span>
          )}
        </Div>
      </div>
    );
  }
}

export default Confirm;
