/* eslint-disable no-underscore-dangle */
import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import * as R from "ramda";
import moment from "moment";
import { actions, getters } from "../model";
import { CARD_TYPES, DATE_FORMAT, PERSON_SUBCARDS } from "../constants";
import { apiCall } from "App/Data/sagas";
import {
  eventId as getEventId,
  eventDetails as getEventDetails
} from "redux/modules/event/selectors";
import { registerError } from "redux/modules/errors/actions";

import staffPersonnelSagas from "./staffPersonnel";

import guestListSagas from "./guestLists";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { actions as ParentActions } from "../../../model";

export const formatDateTimezone = function*(value) {
  const eventDetails = yield select(getEventDetails);

  return value
    ? moment
        .tz(value, DATE_FORMAT, R.prop("timezone", eventDetails))
        .utc()
        .format()
    : null;
};

const init = function*({ payload: { moduleId, selectedTypeId } }) {
  try {
    yield put(actions.setLoadingGuestList(true));

    const eventId = yield select(getEventId);

    const { payload } = yield call(apiCall, {
      method: "get",
      url: `/hub/admin/event/${eventId}/type/${selectedTypeId}/permission-set`
    });

    if (R.isNil(payload) || R.isEmpty(payload)) {
      yield all([
        put(ParentActions.setIsSettingsModalVisible(false)),
        put(
          showSnackbar({
            message: "An error occurred while opening the settings modal"
          })
        )
      ]);
    }

    yield put(
      actions.setInitialData({
        loadingPermissionSet: false,
        moduleId,
        selectedTypeId,
        selectedPermissionSetId: R.prop("id", payload),
        permissionSet: payload,
        showGuestListSettingsModal: true
      })
    );

    if (payload) {
      yield all([
        put(actions.getStaffPersonnel({ moduleId })),
        put(actions.getGuestListDetails())
      ]);
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error loading data"
        }
      ])
    );
  }
};

const updateAssignItems = function*({ payload }) {
  const assignModalType = yield select(R.prop("assignModalType", getters));
  if (assignModalType === R.prop("AUTO_ASSIGN", CARD_TYPES)) {
    yield put(actions.updateAutoAssignedItems(payload));
  } else if (assignModalType === R.prop("AUTO_ASSIGN", PERSON_SUBCARDS)) {
    yield put(actions.onSelectAutoAssignItems(payload));
  }
};

const updateItemData = function*() {
  const assignModalType = yield select(R.prop("assignModalType", getters));

  if (assignModalType === R.prop("AUTO_ASSIGN", CARD_TYPES)) {
    yield put(actions.updateGroupItemData());
  } else if (
    assignModalType === R.prop("AUTO_ASSIGN", PERSON_SUBCARDS) ||
    assignModalType === R.prop("REQUESTABLE", PERSON_SUBCARDS)
  ) {
    yield put(actions.updatePersonItemData());
  } else if (assignModalType === R.prop("REQUESTABLE", CARD_TYPES)) {
    yield put(actions.openUpdateSellItemModal());
  }
};

const watchInit = function*() {
  yield takeEvery(R.path(["init", "type"], actions), init);
};

const watchUpdateAssignItems = function*() {
  yield takeEvery(
    R.path(["updateAssignItems", "type"], actions),
    updateAssignItems
  );
};

const watchUpdateItemData = function*() {
  yield takeEvery(R.path(["updateItemData", "type"], actions), updateItemData);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchUpdateAssignItems),
    fork(watchUpdateItemData),
    fork(staffPersonnelSagas),
    fork(guestListSagas)
  ]);
};

export default rootSaga;
