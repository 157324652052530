import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { Div, Text1 } from "components/Base";

const Label = ({
  children,
  description,
  required,
  readonly,
  isAdminField,
  style
}) => (
  <label styleName="label" style={style}>
    <Div display="row.flex-start.center">
      {children}

      {isAdminField ? (
        <i className={`material-icons ${css.adminIcon}`}>playlist_add_check</i>
      ) : (
        ""
      )}

      <span styleName="required">{required ? "*" : ""}</span>

      {readonly ? (
        <Div
          bra={1}
          color="neutral7"
          bg="neutral1"
          uppercase
          fs={1}
          ml={2}
          p={1}
        >
          Read-only
        </Div>
      ) : null}
    </Div>

    {description && <Text1 fs={1}>{description}</Text1>}
  </label>
);

Label.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  required: PropTypes.bool,
  isAdminField: PropTypes.bool,
  description: PropTypes.string
};

export default CSSModules(Label, css);
