import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { get } from "lodash";
import getValue from "utils/value-types/get-value/catering_dietary_restrictions";
import { Dropdown } from "components/Base";
import { ArrowRenderer } from "components/Global/Editors/utils/reactSelect";

@CSSModules(css)
class CateringDietaryRestrictionsEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: this.getRecords(props.value, props.options)
    };
  }

  componentWillUpdate(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        records: this.getRecords(nextProps.value, nextProps.options)
      });
    }
  }

  getRecords(value, options) {
    return get(this.formatValue(value), "records", []).map(id =>
      options.find(option => option.id === id)
    );
  }

  @autobind
  wrapValue(records) {
    return {
      type: "catering_dietary_restrictions",
      value:
        records && records.length
          ? {
              records: records || []
            }
          : undefined
    };
  }

  formatValue(value) {
    return getValue(value);
  }

  @autobind
  updateRecords(records) {
    this.props.onChange(
      this.wrapValue(
        records
          ? records.map(
              r => this.props.options.find(o => o.value === r.value).id
            )
          : records
      )
    );
    this.setState({ records });
  }

  render() {
    return (
      <Dropdown
        disabled={this.props.disabled}
        arrowRenderer={ArrowRenderer}
        autofocus={this.props.autofocus}
        clearable={false}
        ref="select"
        isMulti
        onChange={this.updateRecords}
        openAfterFocus={this.props.autofocus}
        options={this.props.options.map(option => ({
          label: option.value,
          ...option
        }))}
        placeholder="Select"
        searchable
        value={
          this.state.records
            ? this.state.records.map(record => record.value)
            : this.state.records
        }
        usePortal={this.props.usePortal}
        menuPlacement="auto"
      />
    );
  }
}

CateringDietaryRestrictionsEditor.propTypes = {
  value: PropTypes.object,
  scrollParent: PropTypes.object,
  options: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  autofocus: PropTypes.bool,
  disabled: PropTypes.bool
};

export default CateringDietaryRestrictionsEditor;
