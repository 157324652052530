import React from "react";
import { Text5, Div, MediumAvatar, SmallShadedBox } from "../../index";

const AvatarLabelContainer = ({
  title,
  titleProps = {},
  noAvatar = false,
  Icon,
  id,
  photoURL,
  headerContent = null,
  children,
  ...other
}) => {
  return (
    <Div bg={"white"} bra={3} py={2} px={3} shadow={1} {...other}>
      <Div mb={2} display={"row.flex-start.center"}>
        {Icon && <SmallShadedBox pill bigIcons OnlyIcon={Icon} />}
        {!noAvatar &&
          !Icon && <MediumAvatar photoURL={photoURL} id={id} text={title} />}
        <Text5 ml={2} color={"neutral7"} flex={1} bold {...titleProps}>
          {title}
        </Text5>
        {headerContent}
      </Div>
      {children}
    </Div>
  );
};

export { AvatarLabelContainer };
