export const FIELDS = [
  {
    id: "orderType",
    name: "Order Type",
    span: 3 / 16
  },
  {
    id: "types",
    name: "Item",
    span: 4 / 16
  },
  {
    id: "quantity",
    name: "Quantity",
    span: 2 / 16
  },
  {
    id: "details",
    name: "Details",
    span: 2 / 16
  },
  {
    id: "price",
    name: "Price",
    span: 2 / 16
  },
  {
    id: "status",
    name: "Status",
    span: 2 / 16,
    align: "center"
  },
  {
    id: "paid",
    name: "Paid?",
    span: 1 / 16,
    align: "right"
  }
];

export const FIELDS_FOR_CREDENTIALS_AND_CATERING = [
  {
    id: "orderType",
    name: "Order Type",
    span: 3 / 16
  },
  {
    id: "types",
    name: "Item",
    span: 4 / 16
  },
  {
    id: "quantity",
    name: "Quantity",
    span: 2 / 16
  },
  {
    id: "details",
    name: "Details",
    span: 2 / 16
  },
  {
    id: "price",
    name: "Price",
    span: 2 / 16
  },
  {
    id: "status",
    name: "Status",
    span: 3 / 16,
    align: "center"
  }
];

export const SUMMARY_BY_PASS_DEFAULT_TITLE = "Summary By Pass";
export const SUMMARY_BY_ASSET_DEFAULT_TITLE = "Summary By Asset";
export const SUMMARY_BY_CATERING_DEFAULT_TITLE = "Summary By Catering";
