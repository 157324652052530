import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const CardBody = ({ children }) => <div styleName="container">{children}</div>;

CardBody.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object])
};

export default CSSModules(CardBody, css);
