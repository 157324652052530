import { createHandlers } from "redux-mvc";
import * as R from "ramda";
import {
  NAMESPACE,
  PAGES,
  INVENTORY_PAGES,
  PASSES_PAGES,
  COLLECT_PASSES,
  CUSTOM_PAGES,
  APPLICATIONS_PAGES,
  SCHEDULES_PAGES,
  MODULE_FIELDS_TO_IGNORE,
  LOCKED_APPLICATION_ACCOUNT_FIELDS,
  VISIBLE_APPLICATION_ACCOUNT_FIELDS,
  LOCKED_APPLICATION_CONTACT_FIELDS,
  VISIBLE_APPLICATION_CONTACT_FIELDS
} from "Forms/WizardModal/constants";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";

import {
  CREDENTIAL_TYPE_ID,
  MEAL_TYPE_ID,
  BOOTH_TYPE_ID,
  SPONSORSHIP_TYPE_ID,
  INVENTORY_TYPE_ID
} from "utils/item-types";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_MODULE_FIELDS from "utils/standard-module-field-ids";
import { DEFAULT_ICON_GROUP } from "components/Global/OptionSelector/AccountsOption/IconSelector";

const mapCustomFields = ({ moduleId, lockedList = [], enabledList = [] }) =>
  R.compose(
    R.sort(R.descend(R.prop("locked"))),
    R.map(field => ({
      id: field.id,
      name: field.name,
      type: field.type,
      required: R.contains(field.id, lockedList),
      selected: R.contains(field.id, lockedList),
      enabled:
        R.contains(field.id, lockedList) || R.contains(field.id, enabledList),
      locked: R.contains(field.id, lockedList),
      moduleId
    })),
    R.filter(f => {
      const readOnlyFields = resolveReadOnlyFields({
        moduleId
      });
      return (
        !readOnlyFields.includes(f.id) &&
        !MODULE_FIELDS_TO_IGNORE.includes(f.id)
      );
    })
  );

const iniState = {
  selectedInventory: INVENTORY_PAGES.SELECT_METHOD,
  selectedPage: PAGES.SELECT_FORM,
  selectedPasses: PASSES_PAGES.MEAL_OR_NOT,
  selectedCustomModules: CUSTOM_PAGES.SELECT_FORM,
  selectedApplications: APPLICATIONS_PAGES.SELECT_METHOD,
  selectedSchedules: SCHEDULES_PAGES.SELECT_FORM,
  selectedForm: "",
  height: 576,
  inventoryCategories: [],
  loading: false,
  selectedCategoriesFilter: "",
  collectPasses: COLLECT_PASSES.NOT_MEAL,
  activeItemTypeId: CREDENTIAL_TYPE_ID,
  itemTypeSearch: "",
  selectedVariantPrices: {},
  selectedVariants: {},
  toggledVariants: {},
  passesType: "",
  itemBlocks: {
    [CREDENTIAL_TYPE_ID]: [],
    [MEAL_TYPE_ID]: [],
    [BOOTH_TYPE_ID]: [],
    [SPONSORSHIP_TYPE_ID]: [],
    [INVENTORY_TYPE_ID]: []
  },
  selectedItemBlocks: [],
  itemAccountFields: [],
  itemContactFields: [],
  itemAccountTypeId: null,
  itemContactTypeId: null,
  saving: false,
  formName: "New Form",
  formTitle: "New Form",
  formCloseDate: null,
  formIsLocked: false,
  itemTypes: {
    types: [],
    itemGroups: [],
    items: [],
    variants: []
  },
  formMethod: null,
  moduleFields: [],
  moduleSelectedFilter: "",
  newModuleName: "",
  requestType: "",
  recordTypes: [],
  collectFieldsEnabled: false,
  applicationsSelectFields: {
    accountFields: {},
    contactFields: {},
    attendeeFields: {}
  },
  collectFieldsList: [],
  collectFieldsFilter: "",
  collectFieldsGroupName: "",
  modalEnabled: false,
  searchFilter: "",
  modalDataList: [],
  modalDataId: "",
  showOnEventHome: true,
  moduleColor: "#f08c00",
  moduleIcon: DEFAULT_ICON_GROUP[0],
  accountSelectedTypeId: null,
  contactSelectedTypeId: null,
  primaryContactTypeId: null,
  attendeeContactTypeId: null,
  accountTypes: [],
  contactTypes: [],
  scheduleFields: [],
  scheduleId: "",
  schedulesFilter: "",
  scheduleFieldIds: [],
  isblankForm: false,
  showCreateModal: false
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    cancelInstance: R.always(iniState),
    getInventoryCategories: R.identity,
    getFields: R.identity,
    getCategoryFields: R.identity,
    updateEditedField: R.identity,
    savePassesForm: R.identity,
    saveModuleForm: R.identity,
    saveApplicationForm: R.identity,
    saveInventoryForm: R.identity,
    addRecordItem: R.identity,
    addRecordItems: R.identity,
    updateCategory: R.identity,
    updateItem: R.identity,
    deleteItem: R.identity,
    getItemTypes: R.identity,
    getItemBlocks: R.identity,
    addItem: R.identity,
    getModulesWithFields: R.identity,
    createNewModule: R.identity,
    getCustomModuleFields: R.identity,
    getRecordTypes: R.identity,
    configureGroupRegistration: R.identity,
    getAccountsAndContactsFields: R.identity,
    getApplicationsGroupField: R.identity,
    addRecordType: R.identity,
    refreshRecordTypes: R.identity,
    getSchedulesWithFields: R.identity,
    getScheduleFields: R.identity,
    showScheduleCreateModal: R.identity,

    setPage: (_, { payload: { page } }) => ({
      selectedForm: page,
      selectedPage: page
    }),

    showPassesModal: (state, { payload: { moduleId } }) => ({
      modalEnabled: true,
      modalDataList:
        moduleId === STANDARD_MODULE_IDS.accounts.id
          ? state.itemAccountFields
          : state.itemContactFields,
      modalDataId: moduleId
    }),

    hidePassesModal: (state, { payload: { save = false } }) => ({
      itemAccountFields:
        save === true && state.modalDataId === STANDARD_MODULE_IDS.accounts.id
          ? state.modalDataList
          : state.itemAccountFields,
      itemContactFields:
        save === true && state.modalDataId === STANDARD_MODULE_IDS.contacts.id
          ? state.modalDataList
          : state.itemContactFields,
      modalEnabled: false,
      modalDataList: [],
      modalDataId: ""
    }),

    toggleEnabledPassesField: (state, { payload: { fieldId } }) => {
      const fieldIndex = R.findIndex(
        R.propEq("id", fieldId),
        state.modalDataList
      );
      const currentField = state.modalDataList[fieldIndex];
      return {
        modalDataList:
          currentField.locked === false
            ? [
                ...state.modalDataList.slice(0, fieldIndex),
                {
                  ...currentField,
                  enabled: !state.modalDataList[fieldIndex].enabled
                },
                ...state.modalDataList.slice(fieldIndex + 1)
              ]
            : state.modalDataList
      };
    },

    disablePassesField: (state, { payload: { fieldId } }) => {
      const fieldIndex = R.findIndex(
        R.propEq("id", fieldId),
        state.modalDataList
      );
      const currentField = state.modalDataList[fieldIndex];
      return {
        modalDataList:
          currentField.locked === false
            ? [
                ...state.modalDataList.slice(0, fieldIndex),
                {
                  ...currentField,
                  enabled: false
                },
                ...state.modalDataList.slice(fieldIndex + 1)
              ]
            : state.modalDataList
      };
    },

    enableAllModalFields: state => ({
      modalDataList: R.map(
        field => ({ ...field, enabled: true }),
        state.modalDataList
      )
    }),

    disableAllModalFields: state => ({
      modalDataList: R.map(
        field => ({ ...field, enabled: field.locked === true }),
        state.modalDataList
      )
    }),

    addApplicationsField: (state, { payload: { fields, moduleId } }) => {
      const groupFields =
        state.applicationsSelectFields[state.collectFieldsGroupName];

      const filteredFields = mapCustomFields({ moduleId })(fields);
      const fieldsList = R.compose(
        R.sort(R.descend(R.prop("locked"))),
        R.map(field => {
          const oldField = R.find(
            R.propEq("id", field.id),
            R.propOr([], "list", groupFields)
          );
          return oldField || field;
        })
      )(filteredFields);

      return {
        applicationsSelectFields: {
          ...state.applicationsSelectFields,
          [state.collectFieldsGroupName]: {
            ...groupFields,
            list: fieldsList
          }
        },
        collectFieldsList: fieldsList
      };
    },

    addApplicationsSelectFields: (
      state,
      { payload: { accountFields, contactFields, recordTypeId } }
    ) => {
      const contactList = mapCustomFields({
        moduleId: STANDARD_MODULE_IDS.contacts.id,
        lockedList: LOCKED_APPLICATION_CONTACT_FIELDS,
        enabledList: VISIBLE_APPLICATION_CONTACT_FIELDS
      })(contactFields.fields.fields);

      const contactListOrdered = [
        R.find(
          R.propEq("id", STANDARD_MODULE_FIELDS.CONTACTS.FIRST_NAME),
          contactList
        ),
        R.find(
          R.propEq("id", STANDARD_MODULE_FIELDS.CONTACTS.LAST_NAME),
          contactList
        ),
        R.find(
          R.propEq("id", STANDARD_MODULE_FIELDS.CONTACTS.EMAIL),
          contactList
        ),
        ...contactList.slice(3)
      ];
      return {
        itemAccountTypeId: state.requestType === "group" ? recordTypeId : null,
        itemContactTypeId:
          state.requestType === "individual" ? recordTypeId : null,
        applicationsSelectFields: {
          accountFields: {
            enabled: true,
            id: STANDARD_MODULE_IDS.accounts.id,
            locked: true,
            list: mapCustomFields({
              moduleId: STANDARD_MODULE_IDS.accounts.id,
              lockedList: LOCKED_APPLICATION_ACCOUNT_FIELDS,
              enabledList: VISIBLE_APPLICATION_ACCOUNT_FIELDS
            })(accountFields.fields.fields)
          },
          contactFields: {
            enabled: false,
            locked: false,
            id: STANDARD_MODULE_IDS.contacts.id,
            list: contactListOrdered
          },
          attendeeFields: {
            enabled: false,
            locked: false,
            id: STANDARD_MODULE_IDS.contacts.id,
            list: contactListOrdered
          }
        },
        recordTypes: R.map(
          recordType => ({
            ...recordType,
            selected: recordType.id === recordTypeId
          }),
          state.recordTypes
        ),
        selectedApplications: APPLICATIONS_PAGES.SELECT_FIELDS
      };
    },

    toggleApplicationsSelectFields: (
      state,
      { payload: { fieldGroupName, fieldId } }
    ) => {
      const currentFields = state.applicationsSelectFields[fieldGroupName];
      const fieldIndex = R.findIndex(
        R.propEq("id", fieldId),
        R.propOr([], "list", currentFields)
      );
      const currentField = currentFields.list[fieldIndex];
      return {
        applicationsSelectFields:
          currentField.locked === false
            ? {
                ...state.applicationsSelectFields,
                [fieldGroupName]: {
                  ...currentFields,
                  list: [
                    ...currentFields.list.slice(0, fieldIndex),
                    {
                      ...currentField,
                      selected: !currentField.selected
                    },
                    ...currentFields.list.slice(fieldIndex + 1)
                  ]
                }
              }
            : state.applicationsSelectFields
      };
    },

    toggleRequiredApplicationsSelectFields: (
      state,
      { payload: { fieldGroupName, fieldId } }
    ) => {
      const currentFields = state.applicationsSelectFields[fieldGroupName];
      const fieldIndex = R.findIndex(
        R.propEq("id", fieldId),
        R.propOr([], "list", currentFields)
      );
      const currentField = currentFields.list[fieldIndex];
      return {
        applicationsSelectFields:
          currentField.locked === false
            ? {
                ...state.applicationsSelectFields,
                [fieldGroupName]: {
                  ...currentFields,
                  list: [
                    ...currentFields.list.slice(0, fieldIndex),
                    {
                      ...currentField,
                      selected:
                        currentField.required === false ||
                        currentField.selected,
                      required: !currentField.required
                    },
                    ...currentFields.list.slice(fieldIndex + 1)
                  ]
                }
              }
            : state.applicationsSelectFields
      };
    },

    toggleEnabledCollectField: (state, { payload: { fieldId } }) => {
      const fieldIndex = R.findIndex(
        R.propEq("id", fieldId),
        state.collectFieldsList
      );
      const currentField = state.collectFieldsList[fieldIndex];
      return {
        collectFieldsList:
          currentField.locked === false
            ? [
                ...state.collectFieldsList.slice(0, fieldIndex),
                {
                  ...currentField,
                  enabled: !state.collectFieldsList[fieldIndex].enabled
                },
                ...state.collectFieldsList.slice(fieldIndex + 1)
              ]
            : state.collectFieldsList
      };
    },

    disableCollectField: (state, { payload: { fieldId } }) => {
      const fieldIndex = R.findIndex(
        R.propEq("id", fieldId),
        state.collectFieldsList
      );
      const currentField = state.collectFieldsList[fieldIndex];
      return {
        collectFieldsList:
          currentField.locked === false
            ? [
                ...state.collectFieldsList.slice(0, fieldIndex),
                {
                  ...currentField,
                  enabled: false
                },
                ...state.collectFieldsList.slice(fieldIndex + 1)
              ]
            : state.collectFieldsList
      };
    },

    enableAllCollectFields: state => ({
      collectFieldsList: R.map(
        field => ({ ...field, enabled: true }),
        state.collectFieldsList
      )
    }),

    disableAllCollectFields: state => ({
      collectFieldsList: R.map(
        field => ({ ...field, enabled: field.locked === true }),
        state.collectFieldsList
      )
    }),

    toggleApplicationEnabledGroupField: (
      state,
      { payload: { fieldGroupName } }
    ) => {
      const currentGroup = state.applicationsSelectFields[fieldGroupName];
      return {
        applicationsSelectFields: {
          ...state.applicationsSelectFields,
          [fieldGroupName]: {
            ...currentGroup,
            enabled: !currentGroup.enabled
          }
        },
        primaryContactTypeId:
          fieldGroupName === "contactFields"
            ? null
            : state.primaryContactTypeId,
        attendeeContactTypeId:
          fieldGroupName === "attendeeFields"
            ? null
            : state.attendeeContactTypeId
      };
    },

    showCollectFieldsModal: (state, { payload: { fieldGroupName } }) => ({
      collectFieldsList: state.applicationsSelectFields[fieldGroupName].list,
      collectFieldsEnabled: true,
      collectFieldsGroupName: fieldGroupName
    }),

    hideCollectFieldsModal: (state, { payload: { save = false } }) => ({
      applicationsSelectFields:
        save === false
          ? state.applicationsSelectFields
          : {
              ...state.applicationsSelectFields,
              [state.collectFieldsGroupName]: {
                ...state.applicationsSelectFields[state.collectFieldsGroupName],
                list: R.map(
                  field => ({
                    ...field,
                    selected:
                      field.enabled === false && !field.locked
                        ? false
                        : field.selected,
                    required:
                      field.enabled === false && !field.locked
                        ? false
                        : field.required
                  }),
                  state.collectFieldsList
                )
              }
            },
      collectFieldsList: [],
      collectFieldsEnabled: false,
      collectFieldsGroupName: "",
      collectFieldsFilter: ""
    }),

    updateVariantPriceId: (state, { payload: { variantId, priceId } }) => {
      return {
        selectedVariantPrices: {
          ...state.selectedVariantPrices,
          [variantId]: priceId
        }
      };
    },

    updateVariantQuantity: (state, { payload: { variantId, quantity } }) => {
      if (!quantity || quantity <= 0) {
        return {
          selectedVariants: R.reduce((map, id) => {
            if (id !== variantId) {
              map[id] = state.selectedVariants[id];
            }
            return map;
          }, {})(R.keys(state.selectedVariants))
        };
      }

      return {
        selectedVariants: {
          ...state.selectedVariants,
          [variantId]: quantity <= 0 ? 0 : quantity
        }
      };
    },

    toggleVariant: (state, { payload: { variantId, toggled } }) => {
      return {
        toggledVariants: {
          ...state.toggledVariants,
          [state.activeItemTypeId]: {
            ...state.toggledVariants[state.activeItemTypeId],
            [variantId]: toggled
          }
        }
      };
    },

    toggleVariants: (state, { payload: { variantIds, toggled } }) => {
      return {
        toggledVariants: {
          ...state.toggledVariants,
          [state.activeItemTypeId]: {
            ...state.toggledVariants[state.activeItemTypeId],
            ...R.reduce((map, id) => {
              map[id] = toggled;
              return map;
            }, {})(variantIds)
          }
        }
      };
    },

    toggleItemBlock: (state, { payload: { itemBlockId } }) => {
      if (state.selectedItemBlocks.includes(itemBlockId)) {
        return {
          selectedItemBlocks: state.selectedItemBlocks.filter(
            id => id !== itemBlockId
          )
        };
      }

      return {
        selectedItemBlocks: [...state.selectedItemBlocks, itemBlockId]
      };
    },

    goToApplicationsSelectAccounts: (
      state,
      {
        payload: {
          requestType,
          recordTypes,
          goToApplications,
          accountTypes,
          contactTypes
        }
      }
    ) => ({
      requestType,
      recordTypes: R.map(
        ({ name, id }) => ({ name, id, selected: false }),
        recordTypes
      ),
      accountTypes: R.map(
        ({ name, id }) => ({ label: name, value: id }),
        accountTypes
      ),
      contactTypes: R.map(
        ({ name, id }) => ({ label: name, value: id }),
        contactTypes
      ),
      selectedPage: goToApplications ? PAGES.APPLICATIONS : PAGES.PASSES,
      selectedApplications:
        state.selectedPage === PAGES.APPLICATIONS || goToApplications
          ? APPLICATIONS_PAGES.SELECT_ACCOUNTS
          : state.selectedApplications,
      selectedPasses:
        (state.selectedPage === PAGES.PASSES ||
          state.selectedPage === PAGES.MEALS ||
          state.selectedPage === PAGES.NEW_INVENTORY) &&
        !goToApplications
          ? PASSES_PAGES.SELECT_ACCOUNTS
          : state.selectedPasses,
      passesType:
        (state.selectedPage === PAGES.PASSES ||
          state.selectedPage === PAGES.MEALS ||
          state.selectedPage === PAGES.NEW_INVENTORY) &&
        !goToApplications
          ? requestType
          : state.passesType,
      activeItemTypeId: R.propOr(CREDENTIAL_TYPE_ID, state.selectedPage)({
        [PAGES.MEALS]: MEAL_TYPE_ID,
        [PAGES.PASSES]: CREDENTIAL_TYPE_ID,
        [PAGES.NEW_INVENTORY]: INVENTORY_TYPE_ID
      })
    }),

    goToSelectType: (_, { payload: { hasMeal } }) => ({
      selectedPasses: PASSES_PAGES.SELECT_TYPE,
      collectPasses: hasMeal ? COLLECT_PASSES.MEAL : COLLECT_PASSES.NOT_MEAL
    }),

    goToSelectPasses: () => ({
      selectedPage: PAGES.PASSES,
      selectedPasses: PASSES_PAGES.SELECT_PASSES
    }),

    setDataAfterLoadCategories: (_, { payload: { inventoryCategories } }) => {
      const mappedCategories = R.map(
        category => ({
          ...category,
          editName: category.name,
          isEditingName: false,
          addItemName: "",
          fields: R.map(
            ({ id, name, type }) => ({
              id,
              name,
              type,
              selected: true,
              required: true
            }),
            category.fields
          ),
          items: R.map(
            ({ id, name }) => ({
              id,
              name,
              editName: name,
              isEditing: false,
              isRemoving: false
            }),
            category.items
          ),
          selected: false
        }),
        inventoryCategories
      );
      return {
        inventoryCategories: mappedCategories,
        selectedInventory: INVENTORY_PAGES.INVENTORY_CATEGORIES,
        loading: false
      };
    },

    populateInventoryFormDetails: state => {
      const selectedCategories = state.inventoryCategories.filter(
        c => c.selected
      );
      return {
        formName: selectedCategories[0].name,
        formTitle: selectedCategories[0].name
      };
    },

    toggleField: (state, { payload: { categoryId, fieldId, selected } }) => {
      const categoryIndex = R.findIndex(
        R.propEq("id", categoryId),
        R.propOr([], "inventoryCategories", state)
      );
      const category = state.inventoryCategories[categoryIndex];
      const fieldIndex = R.findIndex(
        R.propEq("id", fieldId),
        R.propOr([], "fields", category)
      );
      const field = category.fields[fieldIndex];
      return {
        inventoryCategories: [
          ...state.inventoryCategories.slice(0, categoryIndex),
          {
            ...category,
            fields: [
              ...category.fields.slice(0, fieldIndex),
              {
                ...field,
                selected: !selected,
                required: false
              },
              ...category.fields.slice(fieldIndex + 1)
            ]
          },
          ...state.inventoryCategories.slice(categoryIndex + 1)
        ]
      };
    },

    toggleItemField: (state, { payload: { moduleId, fieldId, selected } }) => {
      const property = R.prop(moduleId)({
        [STANDARD_MODULE_IDS.accounts.id]: "itemAccountFields",
        [STANDARD_MODULE_IDS.contacts.id]: "itemContactFields"
      });

      const fieldIndex = R.findIndex(
        R.propEq("id", fieldId),
        R.propOr([], property, state)
      );

      const field = state[property][fieldIndex];

      return {
        [property]: [
          ...state[property].slice(0, fieldIndex),
          {
            ...field,
            selected: !selected,
            required: false
          },
          ...state[property].slice(fieldIndex + 1)
        ]
      };
    },

    updateItemName: (state, { payload: { categoryId, itemId, editName } }) => {
      const categoryIndex = R.findIndex(
        R.propEq("id", categoryId),
        R.propOr([], "inventoryCategories", state)
      );
      const category = state.inventoryCategories[categoryIndex];
      const itemIndex = R.findIndex(
        R.propEq("id", itemId),
        R.propOr([], "items", category)
      );
      const item = category.items[itemIndex];
      return {
        inventoryCategories: [
          ...state.inventoryCategories.slice(0, categoryIndex),
          {
            ...category,
            items: [
              ...category.items.slice(0, itemIndex),
              {
                ...item,
                editName
              },
              ...category.items.slice(itemIndex + 1)
            ]
          },
          ...state.inventoryCategories.slice(categoryIndex + 1)
        ]
      };
    },

    toggleCategoryRemoveItem: (
      state,
      { payload: { categoryId, itemId, isRemoving } }
    ) => {
      const copyWithDisableditems = R.compose(
        R.map(category => ({
          ...category,
          items: R.map(item => ({
            ...item,
            isEditing: false,
            isRemoving: false
          }))(category.items)
        })),
        R.propOr([], "inventoryCategories")
      )(state);

      const categoryIndex = R.findIndex(
        R.propEq("id", categoryId),
        copyWithDisableditems
      );

      const category = copyWithDisableditems[categoryIndex];
      const itemIndex = R.findIndex(
        R.propEq("id", itemId),
        R.propOr([], "items", category)
      );
      const item = category.items[itemIndex];

      return {
        inventoryCategories: [
          ...copyWithDisableditems.slice(0, categoryIndex),
          {
            ...category,
            items: [
              ...category.items.slice(0, itemIndex),
              {
                ...item,
                isRemoving
              },
              ...category.items.slice(itemIndex + 1)
            ]
          },
          ...copyWithDisableditems.slice(categoryIndex + 1)
        ]
      };
    },

    enabledUpdateItemName: (state, { payload: { categoryId, itemId } }) => {
      const copyWithDisableditems = R.compose(
        R.map(category => ({
          ...category,
          items: R.map(item => ({
            ...item,
            isRemoving: false,
            isEditing: false
          }))(category.items)
        })),
        R.propOr([], "inventoryCategories")
      )(state);

      const categoryIndex = R.findIndex(
        R.propEq("id", categoryId),
        copyWithDisableditems
      );

      const category = copyWithDisableditems[categoryIndex];
      const itemIndex = R.findIndex(
        R.propEq("id", itemId),
        R.propOr([], "items", category)
      );
      const item = category.items[itemIndex];

      return {
        inventoryCategories: [
          ...copyWithDisableditems.slice(0, categoryIndex),
          {
            ...category,
            items: [
              ...category.items.slice(0, itemIndex),
              {
                ...item,
                isEditing: true
              },
              ...category.items.slice(itemIndex + 1)
            ]
          },
          ...copyWithDisableditems.slice(categoryIndex + 1)
        ]
      };
    },

    disableUpdateItemName: (state, { payload: { categoryId, itemId } }) => {
      const categoryIndex = R.findIndex(
        R.propEq("id", categoryId),
        R.propOr([], "inventoryCategories", state)
      );
      const category = state.inventoryCategories[categoryIndex];
      const itemIndex = R.findIndex(
        R.propEq("id", itemId),
        R.propOr([], "items", category)
      );
      const item = category.items[itemIndex];
      return {
        inventoryCategories: [
          ...state.inventoryCategories.slice(0, categoryIndex),
          {
            ...category,
            items: [
              ...category.items.slice(0, itemIndex),
              {
                ...item,
                isEditing: false,
                editName: item.name
              },
              ...category.items.slice(itemIndex + 1)
            ]
          },
          ...state.inventoryCategories.slice(categoryIndex + 1)
        ]
      };
    },

    addPassesContactsAccountsInitalState: (
      _,
      { payload: { accountFields, contactFields } }
    ) => {
      const readOnlyFields = resolveReadOnlyFields({
        moduleId: STANDARD_MODULE_IDS.catalogCategories.id
      });
      const handleFields = ({ fields, lockedList = [], enabledList = [] }) =>
        R.compose(
          R.sort(R.descend(R.prop("locked"))),
          R.map(field => ({
            ...field,
            required: R.contains(field.id, lockedList),
            selected: R.contains(field.id, lockedList),
            enabled:
              R.contains(field.id, lockedList) ||
              R.contains(field.id, enabledList),
            locked: R.contains(field.id, lockedList)
          })),
          R.filter(f => !readOnlyFields.includes(f.id))
        )(fields);
      const contactList = handleFields({
        fields: contactFields,
        lockedList: LOCKED_APPLICATION_CONTACT_FIELDS,
        enabledList: VISIBLE_APPLICATION_CONTACT_FIELDS
      });
      const contactListOrdered = [
        R.find(
          R.propEq("id", STANDARD_MODULE_FIELDS.CONTACTS.FIRST_NAME),
          contactList
        ),
        R.find(
          R.propEq("id", STANDARD_MODULE_FIELDS.CONTACTS.LAST_NAME),
          contactList
        ),
        R.find(
          R.propEq("id", STANDARD_MODULE_FIELDS.CONTACTS.EMAIL),
          contactList
        ),
        ...contactList.slice(3)
      ];
      return {
        itemContactFields: contactListOrdered,
        itemAccountFields: handleFields({
          fields: accountFields,
          lockedList: LOCKED_APPLICATION_ACCOUNT_FIELDS,
          enabledList: VISIBLE_APPLICATION_ACCOUNT_FIELDS
        })
      };
    },

    toggleItemFieldRequired: (
      state,
      { payload: { moduleId, fieldId, required } }
    ) => {
      const property = R.prop(moduleId)({
        [STANDARD_MODULE_IDS.accounts.id]: "itemAccountFields",
        [STANDARD_MODULE_IDS.contacts.id]: "itemContactFields"
      });

      const fieldIndex = R.findIndex(
        R.propEq("id", fieldId),
        R.propOr([], property, state)
      );

      const field = state[property][fieldIndex];

      return {
        [property]: [
          ...state[property].slice(0, fieldIndex),
          {
            ...field,
            selected: true,
            required: !required
          },
          ...state[property].slice(fieldIndex + 1)
        ]
      };
    },

    toggleRequired: (state, { payload: { categoryId, fieldId, required } }) => {
      const categoryIndex = R.findIndex(
        R.propEq("id", categoryId),
        R.propOr([], "inventoryCategories", state)
      );
      const category = state.inventoryCategories[categoryIndex];
      const fieldIndex = R.findIndex(
        R.propEq("id", fieldId),
        R.propOr([], "fields", category)
      );
      const field = category.fields[fieldIndex];

      return {
        inventoryCategories: [
          ...state.inventoryCategories.slice(0, categoryIndex),
          {
            ...category,
            fields: [
              ...category.fields.slice(0, fieldIndex),
              {
                ...field,
                selected: true,
                required: !required
              },
              ...category.fields.slice(fieldIndex + 1)
            ]
          },
          ...state.inventoryCategories.slice(categoryIndex + 1)
        ]
      };
    },

    toggleCategory: (state, { payload: { id } }) => {
      const categoryIndex = R.findIndex(
        R.propEq("id", id),
        state.inventoryCategories
      );
      const category = state.inventoryCategories[categoryIndex];
      return {
        inventoryCategories: [
          ...state.inventoryCategories.slice(0, categoryIndex),
          {
            ...category,
            selected: !category.selected
          },
          ...state.inventoryCategories.slice(categoryIndex + 1)
        ]
      };
    },

    toggleCategoryEditing: (state, { payload: { id } }) => {
      const categoryIndex = R.findIndex(
        R.propEq("id", id),
        state.inventoryCategories
      );
      const category = state.inventoryCategories[categoryIndex];
      return {
        inventoryCategories: [
          ...state.inventoryCategories.slice(0, categoryIndex),
          {
            ...category,
            isEditing: !category.isEditing
          },
          ...state.inventoryCategories.slice(categoryIndex + 1)
        ]
      };
    },

    toggleCategoryAddItem: (state, { payload: { id } }) => {
      const categoryIndex = R.findIndex(
        R.propEq("id", id),
        state.inventoryCategories
      );
      const category = state.inventoryCategories[categoryIndex];
      return {
        inventoryCategories: [
          ...state.inventoryCategories.slice(0, categoryIndex),
          {
            ...category,
            isAddingItem: !category.isAddingItem
          },
          ...state.inventoryCategories.slice(categoryIndex + 1)
        ]
      };
    },

    setAddItemName: (state, { payload: { id, addItemName } }) => {
      const categoryIndex = R.findIndex(
        R.propEq("id", id),
        state.inventoryCategories
      );
      const category = state.inventoryCategories[categoryIndex];
      return {
        inventoryCategories: [
          ...state.inventoryCategories.slice(0, categoryIndex),
          {
            ...category,
            addItemName
          },
          ...state.inventoryCategories.slice(categoryIndex + 1)
        ]
      };
    },

    cancelCategoryEditing: (state, { payload: { id } }) => {
      const categoryIndex = R.findIndex(
        R.propEq("id", id),
        state.inventoryCategories
      );
      const category = state.inventoryCategories[categoryIndex];
      return {
        inventoryCategories: [
          ...state.inventoryCategories.slice(0, categoryIndex),
          {
            ...category,
            editName: category.name,
            isEditing: false
          },
          ...state.inventoryCategories.slice(categoryIndex + 1)
        ]
      };
    },
    updateCategoryName: (state, { payload: { id, editName } }) => {
      const categoryIndex = R.findIndex(
        R.propEq("id", id),
        state.inventoryCategories
      );
      const category = state.inventoryCategories[categoryIndex];
      return {
        inventoryCategories: [
          ...state.inventoryCategories.slice(0, categoryIndex),
          {
            ...category,
            editName
          },
          ...state.inventoryCategories.slice(categoryIndex + 1)
        ]
      };
    },
    enableAllCategories: state => ({
      inventoryCategories: R.map(
        category => ({ ...category, selected: true }),
        state.inventoryCategories
      )
    }),
    disableAllCategories: state => ({
      inventoryCategories: R.map(
        category => ({ ...category, selected: false }),
        state.inventoryCategories
      )
    }),
    addCategoryField: (state, { payload: { categoryId, mappedFields } }) => {
      const categoryIndex = R.findIndex(
        R.propEq("id", categoryId),
        state.inventoryCategories
      );
      const category = state.inventoryCategories[categoryIndex];
      const newField = R.find(
        field => R.none(f => f.id === field.id, category.fields),
        mappedFields
      );
      return {
        inventoryCategories: [
          ...state.inventoryCategories.slice(0, categoryIndex),
          {
            ...category,
            fields: [
              ...category.fields,
              {
                ...newField,
                selected: false,
                required: false
              }
            ]
          },
          ...state.inventoryCategories.slice(categoryIndex + 1)
        ]
      };
    },
    updateCategoryField: (
      state,
      { payload: { categoryId, mappedFields, fieldId } }
    ) => {
      const categoryIndex = R.findIndex(
        R.propEq("id", categoryId),
        state.inventoryCategories
      );
      const category = state.inventoryCategories[categoryIndex];
      const fieldIndex = R.findIndex(R.propEq("id", fieldId), mappedFields);
      const oldField = R.find(R.propEq("id", fieldId), category.fields);
      return {
        inventoryCategories: [
          ...state.inventoryCategories.slice(0, categoryIndex),
          {
            ...category,
            fields: [
              ...mappedFields.slice(0, fieldIndex),
              {
                ...mappedFields[fieldIndex],
                selected: oldField.selected,
                required: oldField.required
              },
              ...mappedFields.slice(fieldIndex + 1)
            ]
          },
          ...state.inventoryCategories.slice(categoryIndex + 1)
        ]
      };
    },

    setInitialModuleFields: (_, { payload: { moduleFields } }) => {
      return {
        moduleFields: R.map(
          ({ id, name, fields }) => ({
            id,
            name,
            selected: false,
            fields: R.compose(
              R.map(field => ({
                id: field.id,
                name: field.name,
                type: field.type,
                required: false,
                selected: false
              })),
              R.filter(f => {
                const readOnlyFields = resolveReadOnlyFields({
                  moduleId: id
                });
                return (
                  !readOnlyFields.includes(f.id) &&
                  !MODULE_FIELDS_TO_IGNORE.includes(f.id)
                );
              })
            )(fields.fields)
          }),
          moduleFields
        ),
        selectedPage: PAGES.CUSTOM
      };
    },

    setInitialScheduleFields: (_, { payload: { scheduleFields } }) => {
      return {
        scheduleFields: R.map(
          ({ id, name, fields }) => ({
            id,
            name,
            fields: R.compose(
              R.map(field => ({
                id: field.id,
                name: field.name,
                type: field.type,
                required: false,
                selected: false
              }))
            )(fields.fields)
          }),
          scheduleFields
        ),
        selectedPage: PAGES.SCHEDULES,
        scheduleId: "",
        schedulesFilter: ""
      };
    },

    selectModule: (state, { payload: id }) => {
      const moduleFields = R.map(
        mod => ({ ...mod, selected: mod.id === id }),
        state.moduleFields
      );
      const module = state.moduleFields.find(m => m.id === id);
      return {
        selectedCustomModules: CUSTOM_PAGES.SELECT_QUESTIONS,
        moduleFields,
        formName: module.name,
        formTitle: module.name
      };
    },

    selectSchedule: (state, { payload: id }) => {
      return {
        scheduleId: id,
        selectedSchedules: SCHEDULES_PAGES.SELECT_QUESTIONS,
        scheduleFieldIds: []
      };
    },

    addNewModule: (_, { payload: { moduleFields, moduleId, isblankForm } }) => {
      const mappedModuleFields = R.map(
        ({ id, name, fields }) => ({
          id,
          name,
          selected: id === moduleId,
          fields: mapCustomFields({ moduleId })(fields.fields)
        }),
        moduleFields
      );
      const module = R.find(m => m.id === moduleId, moduleFields);
      return {
        moduleFields: mappedModuleFields,
        selectedCustomModules: CUSTOM_PAGES.SELECT_QUESTIONS,
        formName: module.name,
        formTitle: module.name,
        isblankForm
      };
    },

    toggleSelectedModuleField: (state, { payload: { fieldId, moduleId } }) => {
      const moduleIndex = R.findIndex(
        R.propEq("id", moduleId),
        state.moduleFields
      );
      const moduleSelected = state.moduleFields[moduleIndex];
      const fields = R.propOr([], "fields", moduleSelected);
      const fieldIndex = R.findIndex(R.propEq("id", fieldId), fields);
      const field = fields[fieldIndex];
      return {
        moduleFields: [
          ...state.moduleFields.slice(0, moduleIndex),
          {
            ...state.moduleFields[moduleIndex],
            fields: [
              ...fields.slice(0, fieldIndex),
              {
                ...field,
                selected: !fields[fieldIndex].selected
              },
              ...fields.slice(fieldIndex + 1)
            ]
          },
          ...state.moduleFields.slice(moduleIndex + 1)
        ]
      };
    },

    toggleSelectedScheduleId: (state, { payload: { fieldId, selected } }) => ({
      scheduleFieldIds: selected
        ? R.filter(({ id }) => id !== fieldId, state.scheduleFieldIds)
        : [...state.scheduleFieldIds, { id: fieldId, required: false }]
    }),

    selectedAllScheduleFields: state => ({
      scheduleFieldIds: R.compose(
        fieldIds => [...state.scheduleFieldIds, ...fieldIds],
        R.map(({ id }) => ({ id, required: false })),
        R.filter(({ id }) =>
          R.all(field => id !== field.id, state.scheduleFieldIds)
        ),
        R.propOr([], "fields"),
        R.find(R.propEq("id", state.scheduleId))
      )(state.scheduleFields)
    }),

    deselectedAllScheduleFields: () => ({
      scheduleFieldIds: []
    }),

    toggleRequiredScheduleField: (
      state,
      { payload: { fieldId, selected } }
    ) => {
      return {
        scheduleFieldIds: !selected
          ? [...state.scheduleFieldIds, { id: fieldId, required: true }]
          : R.map(
              field => ({
                ...field,
                required:
                  field.id === fieldId ? !field.required : field.required
              }),
              state.scheduleFieldIds
            )
      };
    },

    selectedAllModuleField: (state, { payload: { moduleId } }) => {
      const moduleIndex = R.findIndex(
        R.propEq("id", moduleId),
        state.moduleFields
      );
      const moduleSelected = state.moduleFields[moduleIndex];
      const fields = R.propOr([], "fields", moduleSelected);
      return {
        moduleFields: [
          ...state.moduleFields.slice(0, moduleIndex),
          {
            ...state.moduleFields[moduleIndex],
            fields: R.map(field => ({ ...field, selected: true }), fields)
          },
          ...state.moduleFields.slice(moduleIndex + 1)
        ]
      };
    },

    deselectedAllModuleField: (state, { payload: { moduleId } }) => {
      const moduleIndex = R.findIndex(
        R.propEq("id", moduleId),
        state.moduleFields
      );
      const moduleSelected = state.moduleFields[moduleIndex];
      const fields = R.propOr([], "fields", moduleSelected);
      return {
        moduleFields: [
          ...state.moduleFields.slice(0, moduleIndex),
          {
            ...state.moduleFields[moduleIndex],
            fields: R.map(
              field => ({ ...field, selected: false, required: false }),
              fields
            )
          },
          ...state.moduleFields.slice(moduleIndex + 1)
        ]
      };
    },

    toggleRequiredModuleField: (state, { payload: { fieldId, moduleId } }) => {
      const moduleIndex = R.findIndex(
        R.propEq("id", moduleId),
        state.moduleFields
      );
      const moduleSelected = state.moduleFields[moduleIndex];
      const fields = R.propOr([], "fields", moduleSelected);
      const fieldIndex = R.findIndex(R.propEq("id", fieldId), fields);
      const field = fields[fieldIndex];
      return {
        moduleFields: [
          ...state.moduleFields.slice(0, moduleIndex),
          {
            ...state.moduleFields[moduleIndex],
            fields: [
              ...fields.slice(0, fieldIndex),
              {
                ...field,
                required: !field.required,
                selected: field.required === false || field.required
              },
              ...fields.slice(fieldIndex + 1)
            ]
          },
          ...state.moduleFields.slice(moduleIndex + 1)
        ]
      };
    },

    addCustomModuleFields: (state, { payload: { mappedFields, moduleId } }) => {
      const filteredFields = mapCustomFields({ moduleId })(mappedFields);
      const moduleIndex = R.findIndex(
        R.propEq("id", moduleId),
        state.moduleFields
      );
      const moduleUpdate = state.moduleFields[moduleIndex];
      return {
        moduleFields: [
          ...state.moduleFields.slice(0, moduleIndex),
          {
            ...moduleUpdate,
            fields: R.map(field => {
              const oldField = R.find(
                R.propEq("id", field.id),
                moduleUpdate.fields
              );
              return oldField || field;
            }, filteredFields)
          },
          ...state.moduleFields.slice(moduleIndex + 1)
        ]
      };
    },

    editCustomModuleFields: (
      state,
      { payload: { mappedFields, moduleId, fieldId } }
    ) => {
      const filteredFields = mapCustomFields({ moduleId })(mappedFields);
      const moduleIndex = R.findIndex(
        R.propEq("id", moduleId),
        state.moduleFields
      );
      const moduleUpdate = state.moduleFields[moduleIndex];
      return {
        moduleFields: [
          ...state.moduleFields.slice(0, moduleIndex),
          {
            ...moduleUpdate,
            fields: R.map(field => {
              const oldField = R.find(
                R.propEq("id", field.id),
                moduleUpdate.fields
              );
              if (field.id !== fieldId) {
                return oldField;
              }
              return {
                ...field,
                selected: oldField.selected,
                required: oldField.required
              };
            }, filteredFields)
          },
          ...state.moduleFields.slice(moduleIndex + 1)
        ]
      };
    },

    selectRecordType: (state, { payload: { id } }) => ({
      recordTypes: R.map(
        record => ({ ...record, selected: record.id === id }),
        state.recordTypes
      ),
      selectedApplications: APPLICATIONS_PAGES.SELECT_FIELDS
    }),
    toggleOnEventHome: state => ({
      showOnEventHome: !state.showOnEventHome,
      moduleColor: state.showOnEventHome ? "" : "#f08c00",
      moduleIcon: state.showOnEventHome ? "" : DEFAULT_ICON_GROUP[0]
    }),
    updateRecordTypes: (state, { payload: { recordTypes } }) => ({
      recordTypes,
      accountTypes:
        state.requestType === "individual"
          ? state.accountTypes
          : R.map(({ name, id }) => ({ label: name, value: id }), recordTypes),
      contactTypes:
        state.requestType === "individual"
          ? R.map(({ name, id }) => ({ label: name, value: id }), recordTypes)
          : state.contactTypes
    }),
    resetTypeId: () => ({
      accountSelectedTypeId: null,
      contactSelectedTypeId: null,
      primaryContactTypeId: null,
      attendeeContactTypeId: null
    }),
    updateScheduleFields: (state, { payload: { fields } }) => {
      const scheduleIndex = R.findIndex(
        R.propEq("id", state.scheduleId),
        state.scheduleFields
      );
      const schedule = state.scheduleFields[scheduleIndex];
      return {
        scheduleFields:
          scheduleIndex >= 0
            ? R.update[
                (scheduleIndex, { ...schedule, fields }, state.scheduleFields)
              ]
            : state.scheduleFields
      };
    },
    backToSelectForm: () => ({
      selectedSchedules: SCHEDULES_PAGES.SELECT_FORM,
      selectedCustomModules: CUSTOM_PAGES.SELECT_FORM,
      isblankForm: false
    })
  }
});

export default handlers;
