import { REQUEST, RECEIVE, ERROR, CLEAR_ERROR } from "./constants";
import * as selectors from "./selectors";
import api from "./api";

export function fetchMessages(eventId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const portal = await api.get(getState().user.user.credentials, {
        eventId
      });
      dispatch({ type: RECEIVE, payload: portal.messages });
      return portal;
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function createMessage(message, eventId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const portal = await api
        .post(getState().user.user.credentials, { message, eventId })
        .then(() => api.get(getState().user.user.credentials, { eventId }));
      dispatch({ type: RECEIVE, payload: portal.messages });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function updateMessage(message, eventId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const portal = await api
        .put(getState().user.user.credentials, { message, eventId })
        .then(() => api.get(getState().user.user.credentials, { eventId }));
      dispatch({ type: RECEIVE, payload: portal.messages });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function deleteMessage(messageId, eventId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const portal = await api
        .delete(getState().user.user.credentials, { messageId, eventId })
        .then(() => api.get(getState().user.user.credentials, { eventId }));
      dispatch({ type: RECEIVE, payload: portal.messages });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR
  };
}
