import React from "react";
import { connect } from "react-redux";

import {
  getAttendeeOptions,
  getAttendeeLabel
} from "EventLight/Common/Manage/selectors";

import { ATTENDEE_FILTER_ID } from "Passes/Common/constants";

import { CheckboxFilter } from "ui-kit/FilterControls/View";

import { Text2 } from "components/Base";

const decorate = connect((state, props) => ({
  options: getAttendeeOptions(state, props),
  label: getAttendeeLabel(state, props)
}));

const AttendeeFilter = ({
  options = [],
  label = "Attendee",
  ...styleProps
}) => (
  <CheckboxFilter
    options={options}
    filtersOn
    instanceId={ATTENDEE_FILTER_ID}
    collapsedDefault
    multiSelect
    {...styleProps}
  >
    <Text2 bold>{label} Type</Text2>
  </CheckboxFilter>
);

export default decorate(AttendeeFilter);
