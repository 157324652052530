import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Action = ({ children }) => <div styleName="action">{children}</div>;

Action.propTypes = {
  children: PropTypes.object.isRequired
};

export default CSSModules(Action, css);
