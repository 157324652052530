import React from "react";
import ReturnToSearch from "../ReturnToSearch";
import Input from "components/Global/Modal/Layout/FormElements/Input";
import InputGroup from "components/Global/Modal/Layout/FormElements/InputGroup";
import InputSection from "components/Global/Modal/Layout/FormElements/InputSection";
import Label from "components/Global/Modal/Layout/FormElements/Label";
import FormActions from "../FormActions";
import ButtonGroup from "../ButtonGroup";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import TypePicker from "components/Global/StandAloneEditors/Types";
import * as R from "ramda";
import { Div } from "components/Base";

const NewContact = CSSModules(
  ({
    isOrg,
    fname,
    lname,
    email,
    returnToSearch,
    hasMessage,
    addMessage,
    isPrimary,
    sendNotification,
    giveLoginAccess,
    handleFormFieldChange,
    hideModal,
    updateMessage,
    newTemplate,
    message,
    templateTitle,
    updateTemplateTitle,
    toggleTemplate,
    contactRecordTypes,
    handleRecordTypeChange,
    recordTypeId
  }) => {
    const defaultRecordTypeId = R.compose(
      R.prop("id"),
      R.find(R.propEq("is_default", true))
    )(contactRecordTypes);

    const recordTypeName = R.compose(
      R.prop("name"),
      R.find(R.propEq("id", recordTypeId || defaultRecordTypeId))
    )(contactRecordTypes);

    return (
      <div>
        <ReturnToSearch returnToSearch={returnToSearch} />
        <InputSection>
          <InputGroup className={css.inputGroup}>
            <Label>
              First Name
              <span title="Required">*</span>
            </Label>
            <Input
              autofocus
              required
              type="text"
              name="fname"
              onChange={handleFormFieldChange}
              value={fname}
            />
          </InputGroup>
          <InputGroup className={css.inputGroup}>
            <Label>
              Last Name
              <span title="Required">*</span>
            </Label>
            <Input
              required
              type="text"
              name="lname"
              onChange={handleFormFieldChange}
              value={lname}
            />
          </InputGroup>
        </InputSection>
        <InputSection>
          <InputGroup className={css.inputGroup}>
            <Label>Email Address</Label>
            <Input
              type="email"
              name="email"
              onChange={handleFormFieldChange}
              value={email}
            />
          </InputGroup>
          <InputGroup className={css.inputGroup}>
            <Label>Phone Number</Label>
            <Input type="tel" name="phone" onChange={handleFormFieldChange} />
          </InputGroup>
        </InputSection>
        <InputSection>
          <InputGroup className={css.inputGroup}>
            <Label>Role with this group</Label>
            <Input name="role" onChange={handleFormFieldChange} />
          </InputGroup>
        </InputSection>

        {isOrg ? null : (
          <Div display="column" mb={4}>
            <Label>Record Type</Label>
            <TypePicker
              value={recordTypeName}
              list={[...contactRecordTypes]}
              onSelect={handleRecordTypeChange}
            />
          </Div>
        )}

        <FormActions
          isOrg={isOrg}
          handleFormFieldChange={handleFormFieldChange}
          newTemplate={newTemplate}
          message={message}
          templateTitle={templateTitle}
          hasMessage={hasMessage}
          addMessage={addMessage}
          updateMessage={updateMessage}
          updateTemplateTitle={updateTemplateTitle}
          toggleTemplate={toggleTemplate}
          isPrimary={isPrimary}
          sendNotification={sendNotification}
          giveLoginAccess={giveLoginAccess}
          giveLoginAccessDisabled={!email}
        />
        <ButtonGroup
          sendNotification={sendNotification}
          giveLoginAccess={giveLoginAccess}
          hideModal={hideModal}
        />
      </div>
    );
  },
  css
);

export default NewContact;
