import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  webhooks: [],
  showAddWebhookModal: false,
  webhookId: "",
  modalLoading: false,
  modalData: null
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    openWebhookModal: R.identity,
    refetchList: R.identity,
    save: R.identity,
    setModalFields: (
      _,
      { payload: { webhookId, showAddWebhookModal, modalData = null } }
    ) => ({
      webhookId,
      showAddWebhookModal,
      modalData
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
