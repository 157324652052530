import * as R from "ramda";
import { createHandlers, createModule } from "redux-mvc";
import { TYPES } from "EventLight/Integrations/FuzionMappingModal/constants";

const iniState = Object.freeze({
  loading: false,
  saving: false,
  error: null,
  toggledFieldId: null,
  searchTerm: "",
  activeTab: TYPES[0].value,
  fieldIndex: {},
  accountFields: {},
  accountRecordTypes: {},
  contactFields: {},
  contactRecordTypes: {},
  customLabels: {},
  isPreviewEnabled: false,
  requireProfileApproval: false,
  writeDataToLennd: false,
  profileHtml: null,
  useCustomProfileHtml: false,
  mapping: {
    exhibitor: {},
    booths: {}
  }
});

const handlers = createHandlers({
  iniState,
  namespace: "FuzionMappingModal",
  reducers: {
    init: R.identity,
    saveMapping: R.identity,
    setInitialData: (_, { payload: { fuzionEventId } }) => ({
      fuzionEventId
    }),
    toggleField: (_, { payload: toggledFieldId }) => ({
      toggledFieldId
    }),
    updateExhibitorValue: (state, { payload: { value, path } }) => ({
      mapping: {
        ...state.mapping,
        exhibitor: {
          ...state.mapping.exhibitor,
          field_mapping: {
            ...state.mapping.exhibitor.field_mapping,
            [path]: value
          }
        }
      }
    }),
    updateExhibitorRecordType: (state, { payload: { value, path } }) => ({
      mapping: {
        ...state.mapping,
        exhibitor: {
          ...state.mapping.exhibitor,
          record_type_mapping: {
            ...state.mapping.exhibitor.record_type_mapping,
            [path]: value
          }
        }
      }
    }),
    updateCustomLabel: (state, { payload: { value, path } }) => ({
      customLabels: {
        ...state.customLabels,
        [path]: value
      }
    })
  }
});

const module = createModule(handlers);

const { actions, getters } = module;

export { actions, getters };

export default module;
