/* eslint-disable react/prop-types */

import React from "react";
import * as R from "ramda";

import { Link } from "react-router";
import { Div } from "components/Base";

const LinkCard = ({ link = {}, eventDetails = {} }) => (
  <Div
    display="column"
    bg="white"
    px={3}
    py={3}
    bra={3}
    mb={3}
    shadow={{
      default: 0,
      hover: 2
    }}
    style={{
      cursor: "pointer",
      minHeight: "54px"
    }}
  >
    <Link
      to={R.prop(link.type)({
        media_hub: `/portals/${eventDetails.slug}/${eventDetails.uuid}/community`,
        reports: `/portals/${eventDetails.slug}/${eventDetails.uuid}/reports`
      })}
    >
      <Div display="row.flex-start.flex-start" width={1}>
        <Div width={26} height={26} ml={1}>
          <i className="material-icons" style={{ fontSize: 24 }}>
            {R.prop(link.type)({
              media_hub: "group",
              reports: "show_chart"
            })}
          </i>
        </Div>
        <Div display="column" flex={1} ml={2}>
          <Div fs={5} fw={3} color="black">
            {link.title}
          </Div>
          {link.description && link.description.length ? (
            <Div
              width={1}
              fs={3}
              mt={1}
              color="gray6"
              style={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word"
              }}
            >
              {link.description}
            </Div>
          ) : null}
        </Div>
      </Div>
    </Link>
  </Div>
);

export default LinkCard;
