/* eslint-disable no-underscore-dangle */
import { put, takeEvery, all, fork, call, select } from "redux-saga/effects";
import { registerError } from "redux/modules/errors/actions";
import { actions, getters } from "../model";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { apiCall } from "App/Data/sagas";
import { actions as communitySettingsModalActions } from "EventLight/Common/Types/View/CommunityHubSettingsModal/model";

const getCommunityDetails = function*() {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    yield put(actions.setLoadingCommunity(true));

    const { payload } = yield call(apiCall, {
      method: "get",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/community`
    });
    yield put(actions.setCommunityDetails(payload));
    yield put(actions.setLoadingCommunity(false));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred white loading community details"
        }
      ])
    );
  }
};

const hideCommunitySettingsModal = function*({ payload }) {
  if (!payload) {
    const showCommunitySettingsModal = yield select(
      getters.showCommunitySettingsModal
    );
    if (showCommunitySettingsModal) {
      yield put(actions.setShowCommunitySettingsModal(false));
    }

    yield put(communitySettingsModalActions.resetData());
    yield call(getCommunityDetails);
  }
};

const watchHideCommunitySettingsModal = function*() {
  yield takeEvery(
    [
      actions.setShowCommunitySettingsModal.type,
      communitySettingsModalActions.closeModal.type
    ],
    hideCommunitySettingsModal
  );
};

const watchGetCommunityDetails = function*() {
  yield takeEvery(actions.getCommunityDetails.type, getCommunityDetails);
};

const rootSaga = function*() {
  yield all([
    fork(watchGetCommunityDetails),
    fork(watchHideCommunitySettingsModal)
  ]);
};

export default rootSaga;
