import PropTypes from "prop-types";
import React from "react";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import DeleteConfirmation from "components/Global/CRM/Popovers/DeleteConfirmation";

const stopBubbling = e => e.stopPropagation();

const contextMenuItems = [
  /* TODO: Implement
  {
    value: 'approve',
    primaryText: 'Approve remaining',
    show: submission => true
  },
  {
    value: 'reject',
    primaryText: 'Reject remaining',
    show: submission => true
  },
  {
    value: 'email',
    primaryText: 'Send email',
    show: () => true
  },
  {
    value: "approve",
    primaryText: "Approve submission",
    show: ({ hasSubforms, singleSubmissionStatus }) =>
      hasSubforms === false &&
      !["approved", "rejected"].includes(singleSubmissionStatus)
  },
  {
    value: "reject",
    primaryText: "Reject submission",
    show: ({ hasSubforms, singleSubmissionStatus }) =>
      hasSubforms === false &&
      !["approved", "rejected"].includes(singleSubmissionStatus)
  },
    */
  {
    value: "approve_remaining",
    primaryText: "Approve remaining",
    show: ({ source, hasSubforms, countOfRemainingSubmissions }) =>
      source === "modal" && hasSubforms && countOfRemainingSubmissions
  },
  {
    value: "reject_remaining",
    primaryText: "Reject remaining",
    show: ({ source, hasSubforms, countOfRemainingSubmissions }) =>
      source === "modal" && hasSubforms && countOfRemainingSubmissions
  },
  {
    value: "unlock",
    primaryText: "Unlock submission",
    show: ({ submission, synced }) => submission.is_locked && !synced
  },
  {
    value: "lock",
    primaryText: "Lock submission",
    show: ({ submission, synced }) => !submission.is_locked && !synced
  },
  /* TODO: Implement
  {
    value: 'profile',
    primaryText: 'View contacts profile',
    show: () => true
  },
  */
  {
    value: "send_confirmation",
    primaryText: "Send confirmation",
    show: ({ submission, source, syncNeeded, status }) =>
      status === "done" &&
      source !== "modal" &&
      !submission.is_confirmation_sent &&
      !syncNeeded
  },
  {
    value: "resend_confirmation",
    primaryText: "Re-send confirmation",
    show: ({ submission, source }) =>
      source !== "modal" && submission.is_confirmation_sent
  },
  {
    value: "go_to_credentials",
    primaryText: "Go to orders",
    show: ({ synced }) => synced
  },
  {
    value: "delete",
    primaryText: "Delete",
    confirmation: "Are you sure you want to delete this submission?",
    show: ({ synced }) => !synced
  }
];
const SubmissionContextMenu = ({
  source,
  submission,
  hasSubforms,
  singleSubmissionStatus,
  countOfRemainingSubmissions,
  onMenuChange,
  syncNeeded,
  status,
  children
}) => (
  <IconMenu
    onMouseDown={stopBubbling}
    onChange={onMenuChange}
    iconButtonElement={children}
  >
    {contextMenuItems.reduce((items, item) => {
      if (
        item.show({
          source,
          submission,
          hasSubforms,
          syncNeeded,
          singleSubmissionStatus,
          countOfRemainingSubmissions,
          status
        })
      ) {
        items.push(
          item.confirmation ? (
            <DeleteConfirmation
              confirmLabel={item.confirmLabel}
              onConfirm={e => onMenuChange(e, item.value)}
              text={item.confirmation}
            >
              <MenuItem key={item.value} {...item} />
            </DeleteConfirmation>
          ) : (
            <MenuItem key={item.value} {...item} />
          )
        );
      }
      return items;
    }, [])}
  </IconMenu>
);

SubmissionContextMenu.propTypes = {
  status: PropTypes.string.isRequired,
  submission: PropTypes.object.isRequired,
  onMenuChange: PropTypes.func.isRequired,
  syncNeeded: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

export default SubmissionContextMenu;
