import React, { useState } from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { actions as SelectActions } from "ui-kit/SelectRows";
import { withProps, noop } from "utils/General";
import { CREDENTIAL_TYPE_ID, MEAL_TYPE_ID } from "utils/item-types";

import { getIsRowSelected } from "ui-kit/SelectRows/selectors";

import {
  Div,
  Text3,
  Text1,
  MenuItem,
  SmallCheckbox,
  EditIcon,
  OpenInNewIcon,
  StarIcon,
  KeyIcon,
  DeleteIcon,
  MediumAvatar,
  SendIcon
} from "components/Base";

import ActionsButton from "./ActionsButton";
import Passes from "./Passes";

const BulkMenuItem = withProps({
  width: 1
})(MenuItem);

const buttons = {
  viewDetails: ({ closeMenu, handlers, person }) => (
    <BulkMenuItem
      LeftIcon={EditIcon}
      onClick={() => {
        handlers.viewDetails(person.id);
        closeMenu();
      }}
    >
      View Details
    </BulkMenuItem>
  ),
  openProfile: ({ closeMenu, handlers, person }) => (
    <BulkMenuItem
      LeftIcon={OpenInNewIcon}
      onClick={() => {
        handlers.openProfile(person.id);
        closeMenu();
      }}
    >
      Open Profile
    </BulkMenuItem>
  ),
  changeRole: ({ closeMenu, handlers, person }) => (
    <BulkMenuItem
      LeftIcon={EditIcon}
      onClick={() => {
        handlers.changeRole({ contactId: person.id, role: person.role });
        closeMenu();
      }}
    >
      Change Role
    </BulkMenuItem>
  ),
  editType: ({ closeMenu, handlers, person }) => (
    <BulkMenuItem
      LeftIcon={EditIcon}
      onClick={() => {
        handlers.editType(person.id);
        closeMenu();
      }}
    >
      Edit Type
    </BulkMenuItem>
  ),
  managePrimary: ({ closeMenu, handlers, person }) => (
    <BulkMenuItem
      LeftIcon={StarIcon}
      onClick={() => {
        handlers.togglePrimary(person.id, !person.is_primary);
        closeMenu();
      }}
    >
      {person.is_primary ? "Remove as Primary" : "Add as Primary"}
    </BulkMenuItem>
  ),
  manageLogin: ({ closeMenu, handlers, person }) => (
    <BulkMenuItem
      LeftIcon={KeyIcon}
      onClick={() => {
        if (person.contact_user_id) {
          handlers.removeLogin(person.contact_user_id);
        } else {
          handlers.manageLogin(person.id);
        }
        closeMenu();
      }}
    >
      {person.contact_user_id ? "Remove Login" : "Manage Login"}
    </BulkMenuItem>
  ),
  loginDetails: ({ closeMenu, handlers, person }) =>
    person.contact_user_id ? (
      <BulkMenuItem
        LeftIcon={EditIcon}
        onClick={() => {
          handlers.loginDetails(person);
          closeMenu();
        }}
      >
        Login Details
      </BulkMenuItem>
    ) : null,
  sendEmail: ({ closeMenu, handlers, person }) =>
    person.email && person.email.length ? (
      <BulkMenuItem
        LeftIcon={SendIcon}
        onClick={() => {
          handlers.showSendEmailModal(person.id);
          closeMenu();
        }}
      >
        Send Email
      </BulkMenuItem>
    ) : null,
  removePerson: ({ closeMenu, handlers, person, showAdd }) =>
    !showAdd ? null : (
      <BulkMenuItem
        LeftIcon={DeleteIcon}
        onClick={() => {
          handlers.showRemovePersonModal([
            { contactId: person.id, contactUserId: person.contacat_user_id }
          ]);
          closeMenu();
        }}
      >
        Remove Person
      </BulkMenuItem>
    ),
  credentials: ({ closeMenu, handlers, person }) => (
    <BulkMenuItem
      onClick={() => {
        closeMenu();
        handlers.showAddItemsModal({
          contactId: person.id,
          typeId: CREDENTIAL_TYPE_ID
        });
      }}
    >
      Credentials
    </BulkMenuItem>
  ),
  meals: ({ closeMenu, handlers, person }) => (
    <BulkMenuItem
      onClick={() => {
        closeMenu();
        handlers.showAddItemsModal({
          contactId: person.id,
          typeId: MEAL_TYPE_ID
        });
      }}
    >
      Meals
    </BulkMenuItem>
  )
};

const decorate = connect(
  (state, props) => ({
    selected: getIsRowSelected(state, props)
  }),
  (dispatch, props) => ({
    toggleRow: id =>
      dispatch(
        SelectActions.toggleRow(id, { meta: { instanceId: props.instanceId } })
      )
  })
);

const actionButtons = {
  admin: [
    "viewDetails",
    "openProfile",
    "changeRole",
    "editType",
    "managePrimary",
    "manageLogin",
    "loginDetails",
    "sendEmail",
    "removePerson"
  ],
  default: ["viewDetails", "managePrimary", "removePerson"]
};

const addButtons = ["credentials", "meals"];

const PersonCard = ({
  person = {
    name: "",
    email: "",
    phone: "",
    id: ""
  },
  id,
  selected = false,
  toggleRow = noop,
  handlers = {},
  isAdminView = true,
  showAdd
}) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Div
      display="row.space-between.flex-start"
      bra={2}
      bc={selected ? "primary4" : "neutral4"}
      bg={selected ? "primary0" : "white"}
      px={2}
      mt={1}
      style={{ overflow: "visible" }}
    >
      <Div
        display="row.flex-start.flex-start"
        ml={2}
        my={2}
        style={{ minWidth: 205 }}
        onMouseEnter={() => !hovered && setHovered(true)}
        onMouseLeave={() => hovered && setHovered(false)}
      >
        <Div w={6} display="row.center.center" mr={1}>
          {/* @NOTE: Currently only enabling select on admin view */}
          {isAdminView && (selected || hovered) ? (
            <SmallCheckbox selected={selected} onClick={() => toggleRow(id)} />
          ) : (
            <MediumAvatar text={person.name} bg="neutral3" />
          )}
        </Div>
        <Div display="column" ml={2}>
          <Text3 bold width={1}>
            {person.name}
          </Text3>
          {person.email && person.email.length ? (
            <Text1>{person.email}</Text1>
          ) : null}
          {person.role && person.role.length ? (
            <Text1 mt={1}>{person.role}</Text1>
          ) : null}
          {person.is_primary || person.can_login ? (
            <Div display="row.flex-start.center" mt={1}>
              {person.is_primary ? <Text1>Primary Contact</Text1> : null}
              {person.is_primary && person.can_login ? (
                <Text1 mx={1}>&middot;</Text1>
              ) : null}
              {person.can_login ? <Text1>Can Login</Text1> : null}
            </Div>
          ) : null}
          <Text1
            underline
            mt={3}
            style={{ cursor: "pointer" }}
            onClick={() => handlers.viewDetails(person.id)}
          >
            View Details
          </Text1>
        </Div>
      </Div>
      <Passes
        ml={2}
        my={2}
        passes={person.passes}
        onRemove={showAdd && handlers.removeLineItem}
      />
      <Div ml={2} display="column.flex-start.stretch" my={2}>
        {/* @NOTE: Currently only showing add button on admin view */}
        {isAdminView ? (
          <ActionsButton
            title="Add"
            secondaryTheme={selected}
            buttons={R.map(
              name =>
                React.createElement(buttons[name] || null, {
                  key: name,
                  person,
                  handlers,
                  showAdd
                }),
              addButtons
            )}
          />
        ) : null}
        <ActionsButton
          title="Actions"
          mt={1}
          secondaryTheme={selected}
          buttons={R.map(
            name =>
              React.createElement(buttons[name] || null, {
                key: name,
                person,
                handlers,
                showAdd
              }),
            actionButtons[isAdminView ? "admin" : "default"]
          )}
        />
      </Div>
    </Div>
  );
};

export default decorate(PersonCard);
