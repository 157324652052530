import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SelectEvent from "./SelectEvent";

import EventbriteActions from "redux/modules/integrations/eventbrite/actions";

import * as EventbriteEventsSelectors from "redux/modules/integrations/eventbrite/events/selectors";

function mapStateToProps(state) {
  return {
    eventbriteEvents: EventbriteEventsSelectors.events(state),
    isFetchingEvents: EventbriteEventsSelectors.isFetching(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEventbriteEvents: EventbriteActions.getEvents
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectEvent);
