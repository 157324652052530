import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function toggleField(data) {
  return async (dispatch, getState) => {
    try {
      return await api.post(getState().user.user.credentials, data);
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred toggling customer field"
          }
        ])
      );
    }
  };
}

export function toggleFields(data) {
  return async (dispatch, getState) => {
    try {
      return await api.post(getState().user.user.credentials, {
        bulk: true,
        ...data
      });
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred toggling customer fields"
          }
        ])
      );
    }
  };
}

export function updateField(data) {
  return async (dispatch, getState) => {
    try {
      return await api.put(getState().user.user.credentials, data);
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating customer field"
          }
        ])
      );
    }
  };
}

export function updateFields(data) {
  return async (dispatch, getState) => {
    try {
      return await api.put(getState().user.user.credentials, {
        bulk: true,
        ...data
      });
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating customer fields"
          }
        ])
      );
    }
  };
}
