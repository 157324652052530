import { createModule } from "redux-mvc";

import model from "./model";
import sagas from "./sagas";

import paginationModule from "ui-kit/PaginationBar";

const allReplaysListList = createModule(model);

allReplaysListList.plugModule(paginationModule);

allReplaysListList.setRootSaga(sagas);

export default allReplaysListList;
