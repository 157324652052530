import { combineReducers } from "redux";
import Api from "utils/EventForms/WebAPIUtils";
import FormsApi from "redux/modules/forms/api";
import {
  get,
  find,
  filter,
  each,
  uniq,
  findIndex,
  clone,
  map,
  reduce
} from "lodash";
import uuid from "node-uuid";

import {
  addChangeToSubmission,
  updateApprovalNoteOnSubmission,
  toggleRowApprovalOnSubmission
} from "redux/modules/formRequests/helpers";

import { UPDATE as UPDATE_FORM_INFO } from "redux/modules/forms/constants";

// ------------------------------------
// Helpers
// ------------------------------------
const getRandomId = () => uuid.v4();

const getSourceType = type => {
  switch (type) {
    case "contact":
      return "form-grid-field-contact";
    case "inventory":
      return "form-grid-field-inventory";
    case "form-contact":
      return "form-contact";
    case "form-contact-reference":
      return "form-contact-reference";
    default:
      return null;
  }
};

const getDestinationType = type => {
  switch (type) {
    case "contact":
    case "form-contact":
    case "form-contact-reference":
      return "contact-list";
    case "inventory":
      return "inventory-category";
    default:
      return null;
  }
};

const removeDependentFields = (fields, fieldId) =>
  fields
    .filter(f => !(f.relatedTo === fieldId))
    .map(f => ({
      ...f,
      relatedTo: f.relatedTo === fieldId ? null : f.relatedTo
    }));

const saveSchema = (getState, data) => ({
  type: "SAVE_FORM_SCHEMA",
  payload: new Promise(resolve => {
    FormsApi.put({
      eventId: data.eventId,
      formId: data.formId,
      schema: getState().eventForms.inventoryFormSchema
    });

    resolve();
  })
});

const createFormGridColumn = data =>
  new Promise(resolve => {
    resolve({
      id: getRandomId(),
      width: 175,
      editable: true,
      type: data.type,
      name: data.name,
      description: data.description || "",
      settings: data.settings || {},
      schema: {}
    });
  });

const createSection = data =>
  new Promise(resolve => {
    let section;
    switch (data.type) {
      case "form-contact-info":
        section = {
          id: getRandomId(),
          type: "form-contact-info",
          weight: 0,
          schema: {
            label: data.name
          }
        };
        break;
      case "form-contact-reference":
        section = {
          id: getRandomId(),
          type: "form-contact-reference",
          weight: 0,
          schema: {
            label: data.name,
            destinationId: data.destinationId || null
          }
        };
        break;
      case "form-contact":
        section = {
          id: getRandomId(),
          type: "form-contact",
          weight: 0,
          schema: {
            label: data.name || "Contact Name",
            required: data.required || false,
            destinationId: data.destinationId || null
          }
        };
        break;
      case "form-input":
        section = {
          id: getRandomId(),
          type: "form-input",
          weight: 0,
          schema: {
            label: data.name || "Text input",
            required: data.required || false
          }
        };
        break;
      case "form-email":
        section = {
          id: getRandomId(),
          type: "form-email",
          weight: 0,
          schema: {
            label: data.name || "Email",
            required: data.required || false
          }
        };
        break;
      case "form-phone":
        section = {
          id: getRandomId(),
          type: "form-phone",
          weight: 0,
          schema: {
            phoneDisplayType: data.phoneDisplayType,
            label: data.name || "Phone",
            required: data.required || false
          }
        };
        break;
      case "form-credentials":
        section = {
          id: getRandomId(),
          type: "form-credentials",
          weight: 0,
          schema: {
            label: data.name || "Credentials",
            required: data.required || false,
            settings: data.settings || { availableCredentials: [] }
          }
        };
        break;
      case "form-countries":
        section = {
          id: getRandomId(),
          type: "form-countries",
          weight: 0,
          schema: {
            label: data.name || "Countries",
            required: data.required || false
          }
        };
        break;
      case "form-checkbox":
        section = {
          id: getRandomId(),
          type: "form-checkbox",
          weight: 0,
          schema: {
            label: data.name,
            required: data.required || false
          }
        };
        break;
      case "form-dropdown":
        section = {
          id: getRandomId(),
          type: "form-dropdown",
          weight: 0,
          schema: {
            label: data.name,
            options: data.options,
            allowMultipleSelect: data.allowMultipleSelect,
            required: data.required || false
          }
        };
        break;
      case "form-radio":
        section = {
          id: getRandomId(),
          type: "form-radio",
          weight: 0,
          schema: {
            label: data.name,
            options: data.options
          }
        };
        break;
      case "form-date":
        section = {
          id: getRandomId(),
          type: "form-date",
          weight: 0,
          schema: {
            label: data.name || "Date",
            displayType: data.displayType,
            required: data.required || false
          }
        };
        break;
      case "form-time":
        section = {
          id: getRandomId(),
          type: "form-time",
          weight: 0,
          schema: {
            label: data.name || "Time",
            required: data.required || false
          }
        };
        break;
      case "form-datetime":
        section = {
          id: getRandomId(),
          type: "form-datetime",
          weight: 0,
          schema: {
            label: data.name || "Datetime",
            useDateTimeTimezone: data.useDateTimeTimezone,
            required: data.required || false
          }
        };
        break;
      case "form-date-range":
        section = {
          id: getRandomId(),
          type: "form-date-range",
          weight: 0,
          schema: {
            label: data.name || "Date Range",
            fromLabel: data.fromLabel || "Date Range From",
            toLabel: data.toLabel || "Date Range To",
            required: data.required || false
          }
        };
        break;
      case "form-textarea":
        section = {
          id: getRandomId(),
          type: "form-textarea",
          weight: 0,
          schema: {
            label: data.name || "Textarea",
            required: data.required || false
          }
        };
        break;
      case "form-file-upload":
        section = {
          id: getRandomId(),
          type: "form-file-upload",
          weight: 0,
          schema: {
            label: data.name || "Upload File",
            required: data.required || false
          }
        };
        break;
      case "form-image":
        section = {
          id: getRandomId(),
          type: "form-image",
          weight: 0,
          schema: {
            url: data.imageUrl
          }
        };
        break;
      case "form-separator":
        section = {
          id: getRandomId(),
          type: "form-separator",
          weight: 0
        };
        break;
      case "section-header":
        section = {
          id: getRandomId(),
          type: "section-header",
          weight: 0,
          schema: {
            title: "",
            description: ""
          }
        };
        break;
      case "form-grid":
      case "form-grid-contacts":
      case "form-grid-schedule":
      case "form-grid-inventory":
        const defaultColumns = [];

        if (data.type === "form-grid-contacts") {
          defaultColumns.push(
            {
              id: getRandomId(),
              weight: 0,
              width: 175,
              editable: true,
              type: "contact-first-name",
              name: "First Name",
              relatedTo: null,
              settings: {
                canDelete: false
              },
              schema: {}
            },
            {
              id: getRandomId(),
              weight: 0,
              width: 175,
              editable: true,
              type: "contact-last-name",
              name: "Last Name",
              relatedTo: null,
              settings: {
                canDelete: false
              },
              schema: {}
            }
          );
        } else if (data.type === "form-grid-schedule") {
          defaultColumns.push(
            {
              id: getRandomId(),
              weight: 0,
              width: 175,
              editable: true,
              type: "text",
              name: "Activity Name",
              relatedTo: null,
              settings: {},
              schema: {}
            },
            {
              id: getRandomId(),
              weight: 1,
              width: 175,
              editable: true,
              type: "date",
              name: "Start Date",
              relatedTo: null,
              settings: {},
              schema: {}
            },
            {
              id: getRandomId(),
              weight: 2,
              width: 175,
              editable: true,
              type: "date",
              name: "End Date",
              relatedTo: null,
              settings: {},
              schema: {}
            }
          );
        }

        section = {
          id: getRandomId(),
          type: data.type,
          weight: 0,
          schema: {
            name: data.name,
            allowNewRows: data.allowNewRows || false,
            isHidden: data.isHidden || false,
            style: data.style || "card-and-table",
            headers: {
              0: {
                name: data.name,
                description: ""
              }
            },
            columns: defaultColumns,
            defaultRows: [
              {
                id: getRandomId(),
                defaultValues: {}
              }
            ],
            rowOrder: {}
          }
        };
        break;
      default:
        section = {};
    }
    resolve(section);
  });

// concat incoming parent and child fields and ensure unique records only
const getCombinedRelationshipFields = (columnId, parentFields, childFields) =>
  uniq(
    [].concat(
      parentFields.map(id => ({
        parentFieldId: id,
        childFieldId: columnId
      })),
      childFields.map(id => ({
        parentFieldId: columnId,
        childFieldId: id
      }))
    )
  );

// ------------------------------------
// Constants
// ------------------------------------
const REQUEST_EVENT_FORMS = "REQUEST_EVENT_FORMS";
const RECEIVE_EVENT_FORMS = "RECEIVE_EVENT_FORMS";
const TOGGLE_FORM_ERROR = "TOGGLE_FORM_ERROR";

const INVALIDATE_EVENT_FORM = "INVALIDATE_EVENT_FORM";
const REQUEST_EVENT_FORM = "REQUEST_EVENT_FORM";
const RECEIVE_EVENT_FORM = "RECEIVE_EVENT_FORM";
const REFRESH_FORM_APPROVALS = "REFRESH_FORM_APPROVALS";

const UPDATE_EVENT_FORM_VALUE = "UPDATE_EVENT_FORM_VALUE";
const UPDATE_EVENT_FORM_VALUES = "UPDATE_EVENT_FORM_VALUES";
const ADD_EVENT_FORM_TABLE_ROW = "ADD_EVENT_FORM_TABLE_ROW";
const REMOVE_EVENT_FORM_TABLE_ROWS = "REMOVE_EVENT_FORM_TABLE_ROWS";

const ADD_EVENT_FORM_TABLE_DEFAULT_ROW = "ADD_EVENT_FORM_TABLE_DEFAULT_ROW";
const REMOVE_EVENT_FORM_TABLE_DEFAULT_ROWS =
  "REMOVE_EVENT_FORM_TABLE_DEFAULT_ROWS";
const UPDATE_EVENT_FORM_TABLE_DEFAULT_DATA =
  "UPDATE_EVENT_FORM_TABLE_DEFAULT_DATA";
const BULK_UPDATE_EVENT_FORM_TABLE_DEFAULT_DATA =
  "BULK_UPDATE_EVENT_FORM_TABLE_DEFAULT_DATA";

const RECEIVE_EVENT_FORM_VALUES = "RECEIVE_EVENT_FORM_VALUES";

const PREPARE_FORM_REQUESTS = "PREPARE_FORM_REQUESTS";

const ADD_FORM_SECTION = "ADD_FORM_SECTION";
const DELETE_FORM_SECTION = "DELETE_FORM_SECTION";
const UPDATE_FORM_SECTION = "UPDATE_FORM_SECTION";
const MOVE_FORM_SECTION = "MOVE_FORM_SECTION";

const ADD_FORM_GRID_COLUMN = "ADD_FORM_GRID_COLUMN";
const UPDATE_FORM_GRID_COLUMN = "UPDATE_FORM_GRID_COLUMN";
const UPDATE_FORM_GRID_COLUMN_DESCRIPTION =
  "UPDATE_FORM_GRID_COLUMN_DESCRIPTION";
const DELETE_FORM_GRID_COLUMN = "DELETE_FORM_GRID_COLUMN";
const UPDATE_FORM_GRID_COLUMN_ORDER = "UPDATE_FORM_GRID_COLUMN_ORDER";
const UPDATE_FORM_GRID_DEFAULT_ROW_ORDER = "UPDATE_FORM_GRID_DEFAULT_ROW_ORDER";
const UPDATE_FORM_GRID_ROW_ORDER = "UPDATE_FORM_GRID_ROW_ORDER";

const ADD_FORM_GRID_COLUMN_HEADER = "ADD_FORM_GRID_COLUMN_HEADER";
const REMOVE_FORM_GRID_COLUMN_HEADER = "REMOVE_FORM_GRID_COLUMN_HEADER";
const UPDATE_FORM_GRID_COLUMN_HEADER = "UPDATE_FORM_GRID_COLUMN_HEADER";
const MOVE_FORM_GRID_COLUMN_HEADER = "MOVE_FORM_GRID_COLUMN_HEADER";

const RECORD_REQUESTS_RESPONSE_CHANGE = "RECORD_REQUESTS_RESPONSE_CHANGE";
const RECORD_REQUESTS_APPROVAL_NOTE_UPDATE =
  "RECORD_REQUESTS_APPROVAL_NOTE_UPDATE";
const RECORD_REQUESTS_APPROVE_ROW = "RECORD_REQUESTS_APPROVE_ROW";
const RECORD_REQUESTS_REJECT_ROW = "RECORD_REQUESTS_REJECT_ROW";

const RECORD_SUBMISSION_RESPONSE_CHANGE = "RECORD_SUBMISSION_RESPONSE_CHANGE";
const RECORD_SUBMISSION_APPROVAL_NOTE_UPDATE =
  "RECORD_SUBMISSION_APPROVAL_NOTE_UPDATE";
const RECORD_SUBMISSION_APPROVE_ROW = "RECORD_SUBMISSION_APPROVE_ROW";
const RECORD_SUBMISSION_REJECT_ROW = "RECORD_SUBMISSION_REJECT_ROW";

const SAVE_START = "SAVE_START";
const SAVE_END = "SAVE_END";

const combinedActionTypes = [
  "COMBINED_ACTION_START",
  "COMBINED_ACTION_SUCCESS",
  "COMBINED_ACTION_ERROR"
];

// ------------------------------------
// Actions
// ------------------------------------
const requestForm = data => ({
  type: data.refreshApprovals ? REFRESH_FORM_APPROVALS : REQUEST_EVENT_FORM,
  eventId: data.eventId,
  formId: data.formId
});

const receiveForm = (data, form) => ({
  type: RECEIVE_EVENT_FORM,
  eventId: data.eventId,
  formId: data.formId,
  form
});

const receiveFormValues = data => ({
  type: RECEIVE_EVENT_FORM_VALUES,
  formId: data.formId,
  values: data.values
});

const fetchForm = data => async (dispatch, getState) => {
  try {
    dispatch(requestForm(data));
    const form = await Api.fetchForm(
      getState().user.user.credentials,
      data.eventId,
      data.formId
    );
    dispatch(receiveForm(data, form));
  } catch (error) {
    dispatch({
      type: TOGGLE_FORM_ERROR,
      payload: { error: error.error || "An error occurred!" }
    });
  }
};

const fetchFormByRecipientHash = data => async (dispatch, getState) => {
  try {
    dispatch(requestForm(data));
    const form = await Api.fetchFormByRecipientHash(
      getState().user.user.credentials,
      data
    );
    dispatch(
      receiveForm({ ...data, eventId: form.event_id, formId: form.id }, form)
    );
    dispatch(
      receiveFormValues({
        formId: form.id,
        values: form.response.responses ? form.response.responses : {}
      })
    );

    if (data.recordViewAfterFetch) {
      Api.trackFormOpen(getState().user.user.credentials, data);
    }

    return null;
  } catch (error) {
    dispatch({
      type: TOGGLE_FORM_ERROR,
      payload: { error: error.error || "An error occurred!" }
    });
  }
};

const sendForm = data => (dispatch, getState) =>
  Api.sendForm(getState().user.user.credentials, data);

const sendApproval = data => (dispatch, getState) =>
  Api.updateResponse(getState().user.user.credentials, data);

const toggleSubmissionLock = data => (dispatch, getState) =>
  Api.updateResponse(getState().user.user.credentials, data);

/*
// @TODO: Not using this right now. May put back in future.
const trackFormOpen = (data) => {
  return (dispatch, getState) => {
    Api.updateResponse(getState().user.user.credentials, data)
      .then(() => {
        dispatch(fetchForm(data));
        return null;
      });
  };
};
*/

const convertRecipient = data => (dispatch, getState) =>
  Api.convertRecipient(getState().user.user.credentials, data);

const saveFormResponse = data => (dispatch, getState) =>
  Api.saveFormResponse(getState().user.user.credentials, data);

const deleteSubmission = data => (dispatch, getState) =>
  Api.deleteSubmission(getState().user.user.credentials, data);

const approveFormResponse = data => (dispatch, getState) =>
  Api.approveFormResponse(getState().user.user.credentials, data);

const updateFormValue = data => (dispatch, getState) => {
  const payload = [];
  dispatch({ type: SAVE_START });
  const updateValue = () => ({
    type: UPDATE_EVENT_FORM_VALUE,
    formId: data.formId,
    path: data.path,
    value: data.value
  });

  const save = () =>
    Api.saveFormResponse(getState().user.user.credentials, {
      formId: data.formId,
      eventId: data.eventId,
      responseId: data.responseId,
      responses: getState().eventForms.formValues[data.formId]
    }).then(() => dispatch({ type: SAVE_END }));

  // if we should sync inputted data, add to payload to process
  payload.push(updateValue);
  if (data.sync === true) {
    payload.push(save);
  }

  return dispatch({
    types: combinedActionTypes,
    sequence: true,
    payload
  });
};

const updateFormValues = (formId, values) => ({
  type: UPDATE_EVENT_FORM_VALUES,
  formId,
  values
});

const addFormTableRow = (data, columns = {}) => (dispatch, getState) => {
  const payload = [];
  const row = {
    id: getRandomId()
  };
  const values = reduce(
    columns,
    (result, value, column) => {
      result[`${data.path}.${row.id}.${column}`] = value;
      return result;
    },
    {}
  );

  const addRow = () => ({
    type: ADD_EVENT_FORM_TABLE_ROW,
    formId: data.formId,
    data,
    row,
    values
  });

  const save = () => {
    Api.saveFormResponse(getState().user.user.credentials, {
      formId: data.formId,
      eventId: data.eventId,
      responseId: data.responseId,
      responses: getState().eventForms.formValues[data.formId]
    }).then(() => ({
      type: RECEIVE_EVENT_FORM_VALUES,
      formId: data.formId,
      values: getState().eventForms.formValues[data.formId]
    }));
  };

  // if we should sync inputted data, add to payload to process
  payload.push(addRow);
  if (data.sync === true) {
    payload.push(save);
  }

  return Promise.all([
    dispatch({
      types: combinedActionTypes,
      sequence: true,
      payload
    })
  ]).then(() => row.id);
};

const removeFormTableRows = data => (dispatch, getState) => {
  const removeRows = () => ({
    type: REMOVE_EVENT_FORM_TABLE_ROWS,
    formId: data.formId,
    data
  });

  const save = () => {
    Api.saveFormResponse(getState().user.user.credentials, {
      formId: data.formId,
      eventId: data.eventId,
      responseId: data.responseId,
      responses: getState().eventForms.formValues[data.formId]
    }).then(() => ({
      type: RECEIVE_EVENT_FORM_VALUES,
      formId: data.formId,
      values: getState().eventForms.formValues[data.formId]
    }));
  };

  dispatch({
    types: combinedActionTypes,
    sequence: true,
    payload: [removeRows, save]
  });
};

const addFormTableDefaultRow = data => (dispatch, getState) => {
  const addDefaultRow = () => ({
    type: ADD_EVENT_FORM_TABLE_DEFAULT_ROW,
    data
  });

  dispatch({
    types: combinedActionTypes,
    sequence: true,
    payload: [addDefaultRow, saveSchema.bind(null, getState, data)]
  });
};

const removeFormTableDefaultRows = data => (dispatch, getState) => {
  const removeDefaultRows = () => ({
    type: REMOVE_EVENT_FORM_TABLE_DEFAULT_ROWS,
    data
  });

  dispatch({
    types: combinedActionTypes,
    sequence: true,
    payload: [removeDefaultRows, saveSchema.bind(null, getState, data)]
  });
};

const updateFormTableDefaultData = data => (dispatch, getState) => {
  const updateDefaultData = () => ({
    type: UPDATE_EVENT_FORM_TABLE_DEFAULT_DATA,
    data
  });

  dispatch({
    types: combinedActionTypes,
    sequence: true,
    payload: [updateDefaultData, saveSchema.bind(null, getState, data)]
  });
};

const bulkUpdateFormTableDefaultData = data => (dispatch, getState) => {
  const updateDefaultData = () => ({
    type: BULK_UPDATE_EVENT_FORM_TABLE_DEFAULT_DATA,
    data
  });

  dispatch({
    types: combinedActionTypes,
    sequence: true,
    payload: [updateDefaultData, saveSchema.bind(null, getState, data)]
  });
};

const fetchFormAndPrepareResults = data => (dispatch, getState) => {
  dispatch(requestForm(data));
  Api.fetchForm(getState().user.user.credentials, data.eventId, data.formId)
    .then(form => {
      dispatch(receiveForm(data, form));
      return form;
    })
    .then(form => {
      dispatch({
        type: PREPARE_FORM_REQUESTS,
        form
      });
      return null;
    });
};

const recordReponseChange = data => (dispatch, getState) => {
  if (data.via === "requests") {
    dispatch({
      type: RECORD_REQUESTS_RESPONSE_CHANGE,
      data
    });
  } else {
    dispatch({
      type: RECORD_SUBMISSION_RESPONSE_CHANGE,
      data
    });
  }
  Api.recordReponseChange(getState().user.user.credentials, data);
};

const approveRow = data => (dispatch, getState) => {
  Api.approveRow(getState().user.user.credentials, data).then(() => {
    if (data.via === "requests") {
      dispatch({
        type: RECORD_REQUESTS_APPROVE_ROW,
        data
      });
    } else {
      dispatch({
        type: RECORD_SUBMISSION_APPROVE_ROW,
        data
      });
    }
    return null;
  });
};

const bulkApproveRows = data => (dispatch, getState) =>
  Api.bulkApproveRows(getState().user.user.credentials, data).then(() => {
    if (data.via === "requests") {
      dispatch(
        fetchFormAndPrepareResults({
          ...data,
          refreshApprovals: true
        })
      );
    } else {
      dispatch(
        fetchFormByRecipientHash({
          ...data,
          refreshApprovals: true
        })
      );
    }
    return null;
  });

const unapproveRow = data => (dispatch, getState) => {
  Api.unapproveRow(getState().user.user.credentials, data).then(() => {
    if (data.via === "requests") {
      dispatch({
        type: RECORD_REQUESTS_REJECT_ROW,
        data
      });
    } else {
      dispatch({
        type: RECORD_SUBMISSION_REJECT_ROW,
        data
      });
    }
    return null;
  });
};

const updateApprovalNote = data => (dispatch, getState) => {
  if (data.via === "requests") {
    dispatch({
      type: RECORD_REQUESTS_APPROVAL_NOTE_UPDATE,
      data
    });
  } else {
    dispatch({
      type: RECORD_SUBMISSION_APPROVAL_NOTE_UPDATE,
      data
    });
  }
  Api.updateApprovalNote(getState().user.user.credentials, data);
};

const addFormSection = data => (dispatch, getState) => {
  // create section
  createSection(data)
    .then(section => {
      // check if we need to create connector
      if (["form-contact", "form-contact-reference"].includes(section.type)) {
        Api.createConnector(getState().user.user.credentials, {
          eventId: data.eventId,
          formId: data.formId,
          sourceType: getSourceType(section.type),
          sourceId: section.id,
          destinationType: getDestinationType(section.type),
          destinationId: section.schema.destinationId
        });
      }

      return dispatch({
        types: combinedActionTypes,
        sequence: true,
        payload: [
          () => ({
            type: ADD_FORM_SECTION,
            data: { ...data, section }
          }),
          saveSchema.bind(null, getState, data)
        ]
      });
    })
    .catch(err => {
      console.log("Error creating new section", err);
    });
};

const deleteFormSection = data => (dispatch, getState) => {
  const deleteSection = () => {
    const gridData = {
      sectionFieldIds: []
    };

    // get section
    const section = find(getState().eventForms.inventoryFormSchema.sections, {
      id: data.sectionId
    });

    // check if we need to remove connectors
    if (
      [
        "form-contact",
        "form-contact-reference",
        "form-grid",
        "form-grid-schedule",
        "form-grid-contacts",
        "form-grid-inventory"
      ].includes(section.type)
    ) {
      // if grid, remove connectors for all necessary fields, otherwise
      // remove connnector for single form contact field
      if (["form-contact", "form-contact-reference"].includes(section.type)) {
        Api.removeConnector(getState().user.user.credentials, {
          eventId: data.eventId,
          formId: data.formId,
          sourceId: section.id
        });
      } else {
        const connectorFields = section.schema.columns.filter(
          f => f.type === "inventory" || f.type === "contact"
        );
        Promise.all(
          connectorFields.map(f =>
            Api.removeConnector(getState().user.user.credentials, {
              eventId: data.eventId,
              formId: data.formId,
              sourceId: f.id
            })
          )
        );

        gridData.sectionFieldIds = section.schema.columns.map(f => f.id);
      }
    }

    return {
      type: DELETE_FORM_SECTION,
      data: {
        ...data,
        ...gridData
      }
    };
  };

  dispatch({
    types: combinedActionTypes,
    sequence: true,
    payload: [deleteSection, saveSchema.bind(null, getState, data)]
  });
};

const updateFormSection = data => (dispatch, getState) => {
  const updateSection = () => ({
    type: UPDATE_FORM_SECTION,
    data
  });

  const updateConnector = () => ({
    type: "UPDATE_FORM_CONNECTOR",
    payload: new Promise(resolve => {
      // check if we need to update connector
      if (["form-contact", "form-contact-reference"].includes(data.type)) {
        Api.updateConnector(getState().user.user.credentials, {
          eventId: data.eventId,
          formId: data.formId,
          sourceId: data.sectionId,
          destinationId: get(data, "values.destinationId")
        });
      }
      resolve();
    })
  });

  dispatch({
    types: combinedActionTypes,
    sequence: true,
    payload: [
      updateSection,
      updateConnector,
      saveSchema.bind(null, getState, data)
    ]
  });
};

const moveFormSection = data => (dispatch, getState) => {
  const move = () => ({
    type: MOVE_FORM_SECTION,
    data
  });

  dispatch({
    types: combinedActionTypes,
    sequence: true,
    payload: [move, saveSchema.bind(null, getState, data)]
  });
};

/**
 * form grid: columns
 */
const addFormGridColumnHeader = data => (dispatch, getState) => {
  const addColumnHeader = () => ({
    type: ADD_FORM_GRID_COLUMN_HEADER,
    data
  });
  dispatch({
    types: combinedActionTypes,
    sequence: true,
    payload: [addColumnHeader, saveSchema.bind(null, getState, data)]
  });
};

const removeFormGridColumnHeader = data => (dispatch, getState) => {
  const removeColumnHeader = () => ({
    type: REMOVE_FORM_GRID_COLUMN_HEADER,
    data
  });
  dispatch({
    types: combinedActionTypes,
    sequence: true,
    payload: [removeColumnHeader, saveSchema.bind(null, getState, data)]
  });
};

const updateFormGridColumnHeader = data => (dispatch, getState) => {
  const updateColumnHeader = () => ({
    type: UPDATE_FORM_GRID_COLUMN_HEADER,
    data
  });
  dispatch({
    types: combinedActionTypes,
    sequence: true,
    payload: [updateColumnHeader, saveSchema.bind(null, getState, data)]
  });
};

const moveFormGridColumnHeader = data => (dispatch, getState) => {
  const moveColumnHeader = () => ({
    type: MOVE_FORM_GRID_COLUMN_HEADER,
    data
  });
  dispatch({
    types: combinedActionTypes,
    sequence: true,
    payload: [moveColumnHeader, saveSchema.bind(null, getState, data)]
  });
};

const addFormGridColumn = data => (dispatch, getState) => {
  // create column
  createFormGridColumn(data)
    .then(column => {
      // check if we need to create connector
      if (column.type === "inventory" || column.type === "contact") {
        Api.createConnector(getState().user.user.credentials, {
          eventId: data.eventId,
          formId: data.formId,
          sourceType: getSourceType(column.type),
          sourceId: column.id,
          destinationType: getDestinationType(column.type),
          destinationId: column.settings.destinationId
        });
      }

      return dispatch({
        types: combinedActionTypes,
        sequence: true,
        payload: [
          () => ({
            type: ADD_FORM_GRID_COLUMN,
            data: {
              ...data,
              column
            }
          }),
          saveSchema.bind(null, getState, data)
        ]
      });
    })
    .catch(err => {
      console.log("Error creating new form grid column", err);
    });
};
const updateFormGridColumnDescription = data => (dispatch, getState) => {
  const updateColumn = () => ({
    type: UPDATE_FORM_GRID_COLUMN_DESCRIPTION,
    data
  });
  dispatch({
    types: combinedActionTypes,
    sequence: true,
    payload: [updateColumn, saveSchema.bind(null, getState, data)]
  });
};

const updateFormGridColumn = data => (dispatch, getState) => {
  const updateColumn = () => ({
    type: UPDATE_FORM_GRID_COLUMN,
    data
  });

  const updateConnector = () => ({
    type: "UPDATE_FORM_CONNECTOR",
    payload: new Promise(resolve => {
      // check if we need to update connector
      if (data.type === "inventory" || data.type === "contact") {
        Api.updateConnector(getState().user.user.credentials, {
          eventId: data.eventId,
          formId: data.formId,
          sourceType: getSourceType(data.type),
          sourceId: data.columnId,
          destinationType: getDestinationType(data.type),
          destinationId: data.settings.destinationId
        });
      }
      resolve();
    })
  });

  dispatch({
    types: combinedActionTypes,
    sequence: true,
    payload: [
      updateColumn,
      updateConnector,
      saveSchema.bind(null, getState, data)
    ]
  });
};

const deleteFormGridColumn = data => (dispatch, getState) => {
  const removeConnector = () => ({
    type: "REMOVE_FORM_CONNECTOR",
    payload: new Promise(resolve => {
      // get field
      const grid = find(getState().eventForms.inventoryFormSchema.sections, {
        id: data.gridId
      });
      const field = find(grid.schema.columns, { id: data.columnId });

      // check if we need to update connector
      if (field.type === "inventory" || field.type === "contact") {
        // update connector
        Api.removeConnector(getState().user.user.credentials, {
          eventId: data.eventId,
          formId: data.formId,
          sourceId: field.id
        });
      }
      resolve();
    })
  });

  const deleteColumn = () => ({
    type: DELETE_FORM_GRID_COLUMN,
    data
  });

  dispatch({
    types: combinedActionTypes,
    sequence: true,
    payload: [
      removeConnector,
      deleteColumn,
      saveSchema.bind(null, getState, data)
    ]
  });
};

const updateFormGridColumnOrder = data => (dispatch, getState) => {
  const updateOrder = () => ({
    type: UPDATE_FORM_GRID_COLUMN_ORDER,
    data
  });
  dispatch({
    types: combinedActionTypes,
    sequence: true,
    payload: [updateOrder, saveSchema.bind(null, getState, data)]
  });
};

const updateFormGridDefaultRowOrder = data => (dispatch, getState) => {
  const updateOrder = () => ({
    type: UPDATE_FORM_GRID_DEFAULT_ROW_ORDER,
    formId: data.formId,
    data
  });
  dispatch({
    types: combinedActionTypes,
    sequence: true,
    payload: [updateOrder, saveSchema.bind(null, getState, data)]
  });
};

const updateFormGridRowOrder = data => (dispatch, getState) => {
  const payload = [];

  const updateOrder = () => ({
    type: UPDATE_FORM_GRID_ROW_ORDER,
    formId: data.formId,
    data
  });

  const save = () => {
    Api.saveFormResponse(getState().user.user.credentials, {
      formId: data.formId,
      eventId: data.eventId,
      responseId: data.responseId,
      responses: getState().eventForms.formValues[data.formId]
    }).then(() => ({
      type: RECEIVE_EVENT_FORM_VALUES,
      formId: data.formId,
      values: getState().eventForms.formValues[data.formId]
    }));
  };

  payload.push(updateOrder);
  payload.push(save);

  return Promise.all([
    dispatch({
      types: combinedActionTypes,
      sequence: true,
      payload
    })
  ]);
};

export const actions = {
  fetchForm,
  fetchFormAndPrepareResults,
  sendForm,
  saveFormResponse,
  deleteSubmission,
  approveFormResponse,
  fetchFormByRecipientHash,
  convertRecipient,
  updateFormValue,
  updateFormValues,
  addFormTableRow,
  removeFormTableRows,
  addFormTableDefaultRow,
  removeFormTableDefaultRows,
  updateFormTableDefaultData,
  bulkUpdateFormTableDefaultData,
  approveRow,
  unapproveRow,
  bulkApproveRows,
  updateApprovalNote,
  recordReponseChange,
  sendApproval,
  addFormSection,
  deleteFormSection,
  updateFormSection,
  moveFormSection,
  addFormGridColumn,
  updateFormGridColumnDescription,
  updateFormGridColumn,
  deleteFormGridColumn,
  addFormGridColumnHeader,
  removeFormGridColumnHeader,
  updateFormGridColumnHeader,
  moveFormGridColumnHeader,
  updateFormGridColumnOrder,
  updateFormGridDefaultRowOrder,
  updateFormGridRowOrder,
  toggleSubmissionLock
};

// ------------------------------------
// Reducer
// ------------------------------------

// form
const initialFormState = {
  isFetching: false,
  isRefreshingApprovals: false,
  hasError: false,
  error: null,
  form: {}
};
const form = (state = initialFormState, action) => {
  switch (action.type) {
    case INVALIDATE_EVENT_FORM:
      return {
        ...state,
        form: {}
      };

    case REQUEST_EVENT_FORM:
      return {
        ...state,
        isFetching: true
      };

    case REFRESH_FORM_APPROVALS:
      return {
        ...state,
        isRefreshingApprovals: true
      };

    case RECEIVE_EVENT_FORM:
      return {
        ...state,
        isFetching: false,
        isRefreshingApprovals: false,
        form: action.form
      };

    case TOGGLE_FORM_ERROR:
      return {
        ...state,
        isFetching: false,
        hasError: true,
        error: action.payload.error,
        form: action.form
      };

    case UPDATE_FORM_INFO:
      return {
        ...state,
        isFetching: false,
        form: {
          ...state.form,
          title:
            typeof action.data.title !== "undefined"
              ? action.data.title
              : state.form.title,
          description:
            typeof action.data.description !== "undefined"
              ? action.data.description
              : state.form.description,
          background_image_url:
            typeof action.data.backgroundImageUrl !== "undefined"
              ? action.data.backgroundImageUrl
              : state.form.background_image_url
        }
      };
    case RECORD_SUBMISSION_RESPONSE_CHANGE:
      if (action.data.bulk) {
        action.data.values.forEach(v => {
          state.form.response = addChangeToSubmission(state.form.response, v);
        });
      } else {
        state.form.response = addChangeToSubmission(
          state.form.response,
          action.data
        );
      }
      return state;

    case RECORD_SUBMISSION_APPROVAL_NOTE_UPDATE:
      state.form.response = updateApprovalNoteOnSubmission(
        state.form.response,
        action.data
      );
      return state;

    case RECORD_SUBMISSION_APPROVE_ROW:
    case RECORD_SUBMISSION_REJECT_ROW:
      state.form.response = toggleRowApprovalOnSubmission(
        state.form.response,
        action.data
      );
      return state;

    default:
      return state;
  }
};
const saving = (state = { isSaving: false }, action) => {
  switch (action.type) {
    case SAVE_START:
      return {
        isSaving: true
      };
    case SAVE_END:
      return {
        isSaving: false
      };
    default:
      return state;
  }
};
const currentForm = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_EVENT_FORM:
    case RECEIVE_EVENT_FORM:
    case REQUEST_EVENT_FORM:
    case REFRESH_FORM_APPROVALS:
    case TOGGLE_FORM_ERROR:
    case UPDATE_FORM_INFO:
    case RECORD_SUBMISSION_RESPONSE_CHANGE:
    case RECORD_SUBMISSION_APPROVAL_NOTE_UPDATE:
    case RECORD_SUBMISSION_APPROVE_ROW:
    case RECORD_SUBMISSION_REJECT_ROW:
      return {
        ...state,
        ...form(state, action)
      };
    default:
      return state;
  }
};

// forms
const initialFormsState = {
  isFetching: false,
  forms: []
};
const forms = (state = initialFormsState, action) => {
  switch (action.type) {
    case REQUEST_EVENT_FORMS:
      return {
        ...state,
        isFetching: true
      };

    case RECEIVE_EVENT_FORMS:
      return {
        ...state,
        isFetching: false,
        forms: action.forms
      };

    default:
      return state;
  }
};
const formsByEvent = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_EVENT_FORMS:
    case REQUEST_EVENT_FORMS:
      return {
        ...state,
        [action.eventId]: forms(state[action.eventId], action)
      };
    default:
      return state;
  }
};

// schemas
const initialFormSchema = {
  sections: []
};
const inventoryFormSchemaSections = (state = [], action) => {
  let stateCopy;
  let sectionIndex;
  let rowIndex;
  let columnIndex;

  switch (action.type) {
    case ADD_FORM_GRID_COLUMN:
      stateCopy = clone(state);
      sectionIndex = findIndex(stateCopy, { id: action.data.gridId });

      let column = action.data.column;
      column.weight = stateCopy[sectionIndex].schema.columns.length;

      if (typeof action.data.addAtPosition !== "undefined") {
        if (stateCopy[sectionIndex].schema.columns[action.data.addAtPosition]) {
          column.weight =
            stateCopy[sectionIndex].schema.columns[
              action.data.addAtPosition
            ].weight;
        } else {
          column.weight = stateCopy[sectionIndex].schema.columns.length;
        }
        stateCopy[sectionIndex].schema.columns.splice(
          action.data.addAtPosition,
          0,
          column
        );
      } else {
        stateCopy[sectionIndex].schema.columns = stateCopy[
          sectionIndex
        ].schema.columns.concat([column]);
      }

      const additionalFields = [];

      // check if we need to add an inventory 'quantity' field
      if (
        action.data.type === "inventory" &&
        action.data.settings.addQuantityColumn
      ) {
        additionalFields.push({
          parentFieldIndex: findIndex(stateCopy[sectionIndex].schema.columns, {
            id: column.id
          }),
          field: {
            id: getRandomId(),
            weight: column.weight + 1,
            width: 175,
            editable: true,
            type: "quantity",
            name: `${action.data.name} Quantity`,
            relatedTo: column.id,
            settings: {},
            schema: {}
          }
        });
      }

      // check if we need to add an contact 'email' field
      if (action.data.type === "contact") {
        if (action.data.settings.addEmailField) {
          additionalFields.push({
            parentFieldIndex: findIndex(
              stateCopy[sectionIndex].schema.columns,
              { id: column.id }
            ),
            field: {
              id: getRandomId(),
              weight: column.weight + 1,
              width: 175,
              editable: true,
              type: "contact-email",
              name: "Email",
              relatedTo: column.id,
              settings: {},
              schema: {}
            }
          });
        }
        if (action.data.settings.addPhoneField) {
          additionalFields.push({
            parentFieldIndex: findIndex(
              stateCopy[sectionIndex].schema.columns,
              { id: column.id }
            ),
            field: {
              id: getRandomId(),
              weight: column.weight + 1,
              width: 175,
              editable: true,
              type: "contact-phone",
              name: "Phone Number",
              relatedTo: column.id,
              settings: {},
              schema: {}
            }
          });
        }
      }

      // check if we need to add a catering 'dietary restrictions' field
      if (action.data.type === "catering") {
        additionalFields.push({
          parentFieldIndex: findIndex(stateCopy[sectionIndex].schema.columns, {
            id: column.id
          }),
          field: {
            id: getRandomId(),
            weight: column.weight + 1,
            width: 175,
            editable: true,
            type: "catering-dietary-restrictions",
            name: "Dietary Restrictions",
            relatedTo: column.id,
            settings: {},
            schema: {}
          }
        });
      }

      if (additionalFields.length > 0) {
        additionalFields.forEach(f => {
          stateCopy[sectionIndex].schema.columns.splice(
            f.parentFieldIndex,
            0,
            f.field
          );
        });
      }
      return stateCopy;
    case UPDATE_FORM_GRID_COLUMN:
      stateCopy = clone(state);
      sectionIndex = findIndex(stateCopy, { id: action.data.gridId });
      columnIndex = findIndex(stateCopy[sectionIndex].schema.columns, {
        id: action.data.columnId
      });
      if (action.data.width) {
        stateCopy[sectionIndex].schema.columns[columnIndex].width =
          action.data.width;
      }
      if (action.data.type) {
        stateCopy[sectionIndex].schema.columns[columnIndex].type =
          action.data.type;
      }
      if (action.data.name) {
        stateCopy[sectionIndex].schema.columns[columnIndex].name =
          action.data.name;
      }
      if (action.data.description) {
        stateCopy[sectionIndex].schema.columns[columnIndex].description =
          action.data.description;
      } else {
        stateCopy[sectionIndex].schema.columns[columnIndex].description = "";
      }
      if (action.data.settings) {
        stateCopy[sectionIndex].schema.columns[columnIndex].settings =
          action.data.settings;
      }
      if (action.data.schema) {
        stateCopy[sectionIndex].schema.columns[columnIndex].schema =
          action.data.schema;
      }
      return stateCopy;

    case DELETE_FORM_GRID_COLUMN:
      stateCopy = clone(state);
      sectionIndex = findIndex(stateCopy, { id: action.data.gridId });
      column = find(stateCopy[sectionIndex].schema.columns, {
        id: action.data.columnId
      });

      // if inventory, contact or catering fields, remove any deps
      if (
        (column.type === "inventory" && column.settings.addQuantityColumn) ||
        column.type === "catering" ||
        column.type === "contact"
      ) {
        stateCopy[sectionIndex].schema.columns = removeDependentFields(
          stateCopy[sectionIndex].schema.columns,
          action.data.columnId
        );
      }

      stateCopy[sectionIndex].schema.columns = filter(
        stateCopy[sectionIndex].schema.columns,
        c => c.id !== action.data.columnId
      );
      return stateCopy;

    case UPDATE_FORM_GRID_COLUMN_ORDER:
      stateCopy = clone(state);
      sectionIndex = findIndex(stateCopy, { id: action.data.gridId });
      stateCopy[sectionIndex].schema.columns = map(
        stateCopy[sectionIndex].schema.columns,
        f => ({ ...f, weight: action.data.columns[f.id] })
      );
      return stateCopy;

    case UPDATE_FORM_GRID_DEFAULT_ROW_ORDER:
      stateCopy = clone(state);
      sectionIndex = findIndex(stateCopy, { id: action.data.gridId });
      stateCopy[sectionIndex].schema.rowOrder = action.data.rowOrder;
      return stateCopy;

    case MOVE_FORM_SECTION:
      stateCopy = clone(state);
      const countOfSections = stateCopy.length;
      let newIndex =
        action.data.direction === "up"
          ? action.data.currentIndex - 1
          : action.data.currentIndex + 1;
      if (newIndex === -1) {
        newIndex = countOfSections - 1;
      } else if (newIndex === countOfSections) {
        newIndex = 0;
      }
      stateCopy.splice(
        newIndex,
        0,
        stateCopy.splice(action.data.currentIndex, 1)[0]
      );
      return stateCopy;

    case ADD_FORM_GRID_COLUMN_HEADER:
      return map(state, section => {
        if (action.data.sectionId === section.id) {
          const sectionCopy = clone(section);
          sectionCopy.schema.headers[action.data.columnIndex] = {
            name: "Header",
            description: "Header description"
          };
          return sectionCopy;
        }
        return section;
      });

    case REMOVE_FORM_GRID_COLUMN_HEADER:
      return map(state, section => {
        if (action.data.sectionId === section.id) {
          const sectionCopy = clone(section);
          delete sectionCopy.schema.headers[action.data.columnIndex];
          return sectionCopy;
        }
        return section;
      });

    case UPDATE_FORM_GRID_COLUMN_HEADER:
      return map(state, section => {
        if (action.data.sectionId === section.id) {
          const sectionCopy = clone(section);
          sectionCopy.schema.headers[action.data.columnIndex] = {
            name: action.data.name || "",
            description: action.data.description || ""
          };
          return sectionCopy;
        }
        return section;
      });

    case MOVE_FORM_GRID_COLUMN_HEADER:
      return map(state, section => {
        if (action.data.sectionId === section.id) {
          const sectionCopy = clone(section);
          sectionCopy.schema.headers[action.data.toIndex] = clone(
            sectionCopy.schema.headers[action.data.columnIndex]
          );
          delete sectionCopy.schema.headers[action.data.columnIndex];
          return sectionCopy;
        }
        return section;
      });

    case UPDATE_FORM_SECTION:
      return map(state, section => {
        if (action.data.sectionId === section.id) {
          // loop through updated values and return updated section
          const sectionCopy = clone(section);
          each(action.data.values, v => {
            if (v.key === "type") {
              if (v.value !== section.type) {
                sectionCopy.type = v.value;
              }
            } else {
              sectionCopy.schema[v.key] = v.value;
            }
          });

          return sectionCopy;
        }
        return section;
      });

    case DELETE_FORM_SECTION:
      return filter(state, section => section.id !== action.data.sectionId);

    case ADD_FORM_SECTION:
      // add section in at the specified index
      stateCopy = clone(state);
      stateCopy.splice(
        action.data.index === -1 ? 0 : action.data.index,
        0,
        action.data.section
      );
      return [].concat(stateCopy);

    case ADD_EVENT_FORM_TABLE_DEFAULT_ROW:
      stateCopy = clone(state);
      sectionIndex = findIndex(stateCopy, { id: action.data.gridId });
      if (!stateCopy[sectionIndex].schema.defaultRows) {
        stateCopy[sectionIndex].schema.defaultRows = [];
      }
      stateCopy[sectionIndex].schema.defaultRows.push({
        id: getRandomId(),
        defaultValues: {}
      });
      return stateCopy;

    case REMOVE_EVENT_FORM_TABLE_DEFAULT_ROWS:
      stateCopy = clone(state);
      sectionIndex = findIndex(stateCopy, { id: action.data.gridId });
      if (!stateCopy[sectionIndex].schema.defaultRows) {
        stateCopy[sectionIndex].schema.defaultRows = [];
      }
      stateCopy[sectionIndex].schema.defaultRows = filter(
        stateCopy[sectionIndex].schema.defaultRows,
        row => action.data.rows.indexOf(row.id) === -1
      );
      return stateCopy;

    case UPDATE_EVENT_FORM_TABLE_DEFAULT_DATA:
      stateCopy = clone(state);
      sectionIndex = findIndex(stateCopy, { id: action.data.gridId });
      rowIndex = findIndex(stateCopy[sectionIndex].schema.defaultRows, {
        id: action.data.rowId
      });
      if (rowIndex !== -1) {
        stateCopy[sectionIndex].schema.defaultRows[rowIndex].defaultValues[
          action.data.columnId
        ] = action.data.value;
      }
      return stateCopy;

    case BULK_UPDATE_EVENT_FORM_TABLE_DEFAULT_DATA:
      stateCopy = clone(state);
      sectionIndex = findIndex(stateCopy, { id: action.data.gridId });

      each(action.data.values, value => {
        rowIndex = findIndex(stateCopy[sectionIndex].schema.defaultRows, {
          id: value.rowId
        });
        if (rowIndex !== -1) {
          stateCopy[sectionIndex].schema.defaultRows[rowIndex].defaultValues[
            value.columnId
          ] = value.value;
        }
      });
      return stateCopy;

    default:
      return state;
  }
};

const inventoryFormSchemaFieldRelationships = (state = [], action) => {
  switch (action.type) {
    case ADD_FORM_GRID_COLUMN:
      // add incoming relationships
      return uniq(
        state.concat(
          getCombinedRelationshipFields(
            action.data.column.id,
            action.data.parentFields,
            action.data.childFields
          )
        )
      );

    case UPDATE_FORM_GRID_COLUMN:
      // if updating, remove any records that are related to incoming column
      return uniq(
        state
          .filter(
            f =>
              f.parentFieldId !== action.data.columnId &&
              f.childFieldId !== action.data.columnId
          )
          .concat(
            getCombinedRelationshipFields(
              action.data.columnId,
              action.data.parentFields,
              action.data.childFields
            )
          )
      );

    case DELETE_FORM_GRID_COLUMN:
      // if removing, remove any relationships containing field thats being removed
      return state.filter(
        f =>
          f.parentFieldId !== action.data.columnId &&
          f.childFieldId !== action.data.columnId
      );

    case UPDATE_FORM_SECTION:
      // if updating, remove any records that are related to incoming section
      if (action.data.parentFields && action.data.childFields) {
        return uniq(
          state
            .filter(
              f =>
                f.parentFieldId !== action.data.sectionId &&
                f.childFieldId !== action.data.sectionId
            )
            .concat(
              getCombinedRelationshipFields(
                action.data.sectionId,
                action.data.parentFields,
                action.data.childFields
              )
            )
        );
      }
      return state;

    case DELETE_FORM_SECTION:
      // if form, loop through all columns and remove relationships
      return state.filter(
        f =>
          !action.data.sectionFieldIds.includes(f.parentFieldId) &&
          !action.data.sectionFieldIds.includes(f.childFieldId) &&
          action.data.sectionId !== f.childFieldId
      );

    default:
      return state;
  }
};

const inventoryFormSchemaFieldMapping = (state = [], action) => {
  switch (action.type) {
    case ADD_FORM_SECTION:
    case ADD_FORM_GRID_COLUMN:
      // add incoming mappings
      return uniq(state.concat(action.data.mappedFields));

    case UPDATE_FORM_GRID_COLUMN:
      // if updating, remove any records that are related to incoming column
      return uniq(
        state
          .filter(f => f.parentFieldId !== action.data.columnId)
          .concat(action.data.mappedFields)
      );

    case DELETE_FORM_GRID_COLUMN:
      // if removing, remove any mappings containing field thats being removed
      return state.filter(
        f =>
          f.parentFieldId !== action.data.columnId &&
          f.sourceFieldId !== action.data.columnId
      );

    case UPDATE_FORM_SECTION:
      // if updating, remove any records that are related to incoming section
      if (action.data.mappedFields) {
        return uniq(
          state
            .filter(f => f.parentFieldId !== action.data.sectionId)
            .concat(action.data.mappedFields)
        );
      }
      return state;

    case DELETE_FORM_SECTION:
      // if form, loop through all columns and remove relationships
      return state.filter(
        f =>
          f.parentFieldId !== action.data.sectionId &&
          f.sourceFieldId !== action.data.sectionId &&
          !action.data.sectionFieldIds.includes(f.parentFieldId) &&
          !action.data.sectionFieldIds.includes(f.sourceFieldId) &&
          action.data.sectionId !== f.sourceFieldId
      );

    default:
      return state;
  }
};

const inventoryFormSchema = (state = initialFormSchema, action) => {
  switch (action.type) {
    case RECEIVE_EVENT_FORM:
      return action.form.schema || initialFormSchema;
    case UPDATE_FORM_GRID_COLUMN_DESCRIPTION:
      const sections = [...state.sections];
      const sectionIndex = findIndex(sections, { id: action.data.gridId });
      const columnIndex = findIndex(sections[sectionIndex].schema.columns, {
        id: action.data.columnId
      });
      if (action.data.description) {
        sections[sectionIndex].schema.columns[columnIndex].description =
          action.data.description;
      } else {
        sections[sectionIndex].schema.columns[columnIndex].description = "";
      }
      return { ...state, sections };
    case ADD_FORM_GRID_COLUMN_HEADER:
    case REMOVE_FORM_GRID_COLUMN_HEADER:
    case UPDATE_FORM_GRID_COLUMN_HEADER:
    case MOVE_FORM_GRID_COLUMN_HEADER:
    case ADD_FORM_GRID_COLUMN:
    case UPDATE_FORM_GRID_COLUMN:
    case DELETE_FORM_GRID_COLUMN:
    case UPDATE_FORM_GRID_COLUMN_ORDER:
    case UPDATE_FORM_GRID_DEFAULT_ROW_ORDER:
    case UPDATE_FORM_SECTION:
    case MOVE_FORM_SECTION:
    case DELETE_FORM_SECTION:
    case UPDATE_EVENT_FORM_TABLE_DEFAULT_DATA:
    case BULK_UPDATE_EVENT_FORM_TABLE_DEFAULT_DATA:
    case ADD_EVENT_FORM_TABLE_DEFAULT_ROW:
    case REMOVE_EVENT_FORM_TABLE_DEFAULT_ROWS:
    case ADD_FORM_SECTION:
      return {
        ...state,
        sections: inventoryFormSchemaSections(state.sections, action),
        fieldRelationships: inventoryFormSchemaFieldRelationships(
          state.fieldRelationships,
          action
        ),
        fieldMapping: filter(
          inventoryFormSchemaFieldMapping(state.fieldMapping, action),
          item => item
        )
      };
    default:
      return state;
  }
};

// values
const values = (state = {}, action) => {
  switch (action.type) {
    case ADD_EVENT_FORM_TABLE_ROW:
      const rows = [...get(state, `${action.data.path}.rows`, [])];
      rows.splice(
        action.data.insertAfter
          ? findIndex(rows, { id: action.data.insertAfter }) + 1
          : rows.length,
        0,
        action.row
      );

      return {
        ...state,
        ...action.values,
        [action.data.path]: {
          ...state[action.data.path],
          rows
        }
      };

    case REMOVE_EVENT_FORM_TABLE_ROWS:
      return {
        ...state,
        [action.data.path]: {
          ...state[action.data.path],
          hiddenRows:
            state[action.data.path] && state[action.data.path].hiddenRows
              ? uniq(
                  [].concat(
                    state[action.data.path].hiddenRows,
                    action.data.rows
                  )
                )
              : action.data.rows
        }
      };

    case UPDATE_FORM_GRID_ROW_ORDER:
      return {
        ...state,
        [action.data.path]: {
          ...state[action.data.path],
          rowOrder: action.data.rowOrder
        }
      };

    case UPDATE_EVENT_FORM_VALUE:
      return {
        ...state,
        [action.path]: action.value
      };

    case UPDATE_EVENT_FORM_VALUES:
      const updatedValues = {};
      each(action.values, value => {
        updatedValues[value.path] = value.value;
      });
      return {
        ...state,
        ...updatedValues
      };

    default:
      return state;
  }
};
const formValues = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_EVENT_FORM_VALUES:
      return {
        ...state,
        [action.formId]: action.values
      };
    case ADD_EVENT_FORM_TABLE_ROW:
    case REMOVE_EVENT_FORM_TABLE_ROWS:
    case UPDATE_FORM_GRID_ROW_ORDER:
    case UPDATE_EVENT_FORM_VALUE:
    case UPDATE_EVENT_FORM_VALUES:
      return {
        ...state,
        [action.formId]: values(state[action.formId], action)
      };
    default:
      return state;
  }
};

// requests
const formRequests = (state = {}, action) => {
  let idx;
  switch (action.type) {
    case RECORD_REQUESTS_RESPONSE_CHANGE:
      idx = findIndex(state.responses, { id: action.data.responseId });
      if (idx !== -1) {
        state.responses[idx] = addChangeToSubmission(
          state.responses[idx],
          action.data
        );
      }
      return state;

    case RECORD_REQUESTS_APPROVAL_NOTE_UPDATE:
      idx = findIndex(state.responses, { id: action.data.responseId });
      if (idx !== -1) {
        state.responses[idx] = updateApprovalNoteOnSubmission(
          state.responses[idx],
          action.data
        );
      }
      return state;

    case RECORD_REQUESTS_APPROVE_ROW:
    case RECORD_REQUESTS_REJECT_ROW:
      idx = findIndex(state.responses, { id: action.data.responseId });
      if (idx !== -1) {
        state.responses[idx] = toggleRowApprovalOnSubmission(
          state.responses[idx],
          action.data
        );
      }
      return state;

    case PREPARE_FORM_REQUESTS:
      return {
        schema: action.form.schema,

        // filter out 'pending' (not submitted) responses
        responses: action.form.responses.filter(r => r.status !== "pending"),

        eventDetails: action.form.eventDetails
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  formsByEvent,
  currentForm,
  inventoryFormSchema,
  formValues,
  formRequests,
  saving
});

export default rootReducer;
