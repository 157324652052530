import * as R from "ramda";
import React, { useEffect, useRef } from "react";
import { findDOMNode } from "react-dom";
import { Div, TinyFilledBox } from "components/Base";

import { os } from "ui-kit/Theme";

const Header = h => (
  <Div
    key={h.id}
    br={1}
    bb={1}
    bc="#ddd"
    bg="#f9f9f9"
    pt={4}
    px={4}
    height={100}
    {...os(h, {
      width: h.width,
      flexShrink: 0
    })}
  >
    <Div display="column.flex-start.flex-start">
      <Div display="row.flex-start.center">
        <Div fs={6}>{h.name}</Div>
        {h.label && (
          <TinyFilledBox pill ml={2} color="neutral7" bg="neutral2" p={2}>
            {h.label}
          </TinyFilledBox>
        )}
      </Div>
      <Div fs={3}>{R.path(["settings", "description"], h)}</Div>
    </Div>
  </Div>
);

const Headers = ({ headers = [], followElm }) => {
  const slide = useRef();
  useEffect(() => {
    let node;
    let listener;

    const findNode = (count = 5) => {
      if (followElm && followElm.current) {
        const node = R.head(
          findDOMNode(followElm.current).getElementsByClassName(
            "react-grid-Canvas"
          )
        );
        if (node) {
          listener = () => {
            slide.current.style.left = `-${node.scrollLeft}px`;
          };
          node.addEventListener("scroll", listener);
        } else if (count > 0) {
          setTimeout(() => findNode(count - 1), 0);
        } else {
          setTimeout(() => findNode(count - 1), 100);
        }
      }
    };
    findNode();

    return () => {
      if (node && listener) {
        node.removeEventListener("scroll", listener);
      }
    };
  }, [followElm, slide]);

  return (
    <Div display="row.flex-start.stretch">
      <Header
        width={60}
        id="selectRow"
        color="transparent"
        name="selectRow"
        style={{ positon: "relative", zIndex: 8 }}
      />
      <Div style={{ position: "relative" }}>
        <div
          ref={slide}
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "stretch",
            justifyContent: "center",
            borderBottom: "solid 1px #ddd"
          }}
        >
          {headers.map((h, index) => (
            <Header {...h} key={index} />
          ))}
        </div>
      </Div>
    </Div>
  );
};

export default Headers;
