import React from "react";
import ReactSidebar from "react-sidebar";
import ScrollToTop from "components/Global/ScrollToTop";

const SidebarContainer = ({
  sidebar,
  moduleId,
  isVisible,
  showSidebar,
  children,
  contentBackgroundColor,
  sidebarClassName = null
}) => {
  if (sidebar) {
    const sidebarStyles = {
      root: { top: 45 },
      content: { overflowY: "auto" }
    };
    if (contentBackgroundColor) {
      sidebarStyles.root.backgroundColor = contentBackgroundColor;
    }
    return (
      <ReactSidebar
        docked={isVisible}
        onSetOpen={() => showSidebar(moduleId)}
        sidebar={sidebar}
        styles={sidebarStyles}
        transitions={false}
        sidebarClassName={sidebarClassName}
      >
        <ScrollToTop>{children}</ScrollToTop>
      </ReactSidebar>
    );
  }
  return <div>{children}</div>;
};

SidebarContainer.propTypes = {};

export default SidebarContainer;
