import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { FIELD_TYPES } from "Submission/Editor/constants";

import PeopleField from "Submission/PeopleBlockViewer/View";
import ItemField from "Submission/ItemBlockViewer/View";
import Subform from "Submission/Subform/View";
import Customer from "Submission/Customer";
import Fulfillment from "Submission/Fulfillment";
import OrderDetails from "Submission/OrderDetails";

import { actions as SubformActions } from "Submission/Subform";

import { Div } from "components/Base";

export const NEW_FIELD_TYPES = [
  FIELD_TYPES.people,
  FIELD_TYPES.item,
  FIELD_TYPES.subform,
  FIELD_TYPES.customer,
  FIELD_TYPES.fulfillment,
  FIELD_TYPES.details
];

const fieldResolver = {
  [FIELD_TYPES.people]: PeopleField,
  [FIELD_TYPES.item]: ItemField,
  [FIELD_TYPES.subform]: Subform,
  [FIELD_TYPES.customer]: Customer,
  [FIELD_TYPES.fulfillment]: Fulfillment,
  [FIELD_TYPES.details]: OrderDetails
};

const decorate = connect(
  null,
  {
    addTableId: SubformActions.addTableId
  }
);

const NewField = props => {
  const type = R.pathOr("", ["field", "type"], props);

  useEffect(() => {
    if (type === FIELD_TYPES.subform) {
      props.addTableId(props.field.id);
    }
  }, [props.field.id]);
  return (
    <Div px={8} py={2} style={{ overflowX: "auto" }}>
      {R.has(R.pathOr("", ["field", "type"], props), fieldResolver)
        ? React.createElement(R.prop(type, fieldResolver), {
            ...props,
            instanceId: props.field.id
          })
        : null}
    </Div>
  );
};

export default decorate(NewField);
