import { REQUEST, RECEIVE } from "./constants";
import { combineReducers } from "redux";

const groups = (state = [], action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload;
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE:
    default:
      return false;
  }
};

export default combineReducers({
  groups,
  fetching
});
