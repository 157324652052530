import React from "react";
import { connect } from "react-redux";
import { getters } from "Organizations/CloneEventModal/model";
import { PAGES } from "Organizations/CloneEventModal/constants";
import NewEvent from "./NewEvent";
import CreateNewEvent from "./CreateNewEvent";
import SelectEvent from "./SelectEvent";
import SelectContent from "./SelectContent";
import CloneOptions from "./CloneOptions";
import Confirmation from "./Confirmation";
import ContactSupport from "./ContactSupport";

const decorate = connect(state => ({
  selectedPage: getters.selectedPage(state)
}));

const pagesMap = {
  [PAGES.NEW_EVENT]: NewEvent,
  [PAGES.CREATE_NEW_EVENT]: CreateNewEvent,
  [PAGES.SELECT_EVENT]: SelectEvent,
  [PAGES.CLONE_OPTIONS]: CloneOptions,
  [PAGES.SELECT_CONTENT]: SelectContent,
  [PAGES.CONFIRMATION]: Confirmation,
  [PAGES.CONTACT_SUPPORT]: ContactSupport
};

const Body = ({ handlers = {}, selectedPage = [PAGES.NEW_EVENT] }) => {
  return React.createElement(
    pagesMap[selectedPage] || pagesMap[PAGES.NEW_EVENT],
    {
      handlers
    }
  );
};

export default decorate(Body);
