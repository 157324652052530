import React, { Component } from "react";
import * as R from "ramda";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { connect } from "react-redux";
import StatusCircle from "ui-kit/StatusCircle";
import Tooltip from "components/Global/Tooltip";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import BulkEditLineItemsModal from "Orders/BulkEditLineItemsModal";
import { bindInstance } from "redux-mvc";
import { parseComboId } from "utils/General";

import { showModal, hideModal } from "redux/modules/modal/actions";

import { noop } from "utils/General";
import { getValue, getSettings } from "../../utils";
import { actions } from "../../model";

const decorate = connect(
  null,
  bindInstance({
    showModal,
    hideModal,
    onDone: actions.updateData
  })
);

class Payment extends Component {
  showBulkEditModal = () => {
    const moduleId = getValue(this.props, "moduleId");
    const eventId = getValue(this.props, "eventId");
    this.props.showModal({
      content: (
        <BulkEditLineItemsModal
          where={
            moduleId === STANDARD_MODULE_IDS.accounts.id
              ? {
                  eventId,
                  orderAccountId: parseComboId(this.props.data.id)[0],
                  orderType: "group"
                }
              : {
                  eventId,
                  orderContactId: parseComboId(this.props.data.id)[0],
                  orderType: "individual"
                }
          }
          onDone={this.props.onDone}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const status = getValue(this.props, "status");
    const owed = getValue(this.props, "owed");
    const paid = getValue(this.props, "paid");
    const isFieldReadOnly = getSettings(this.props, "readOnly", false);

    const statusMap = {
      "no-payment-against-balance": {
        status: "rejected",
        tooltip: (
          <span>
            <b>No payments made</b>
            <br />${owed} due
          </span>
        )
      },
      "remaining-balance": {
        status: "pending",
        tooltip: (
          <span>
            <b>Has remaining balance</b>
            <br />${paid} of ${owed} paid
          </span>
        )
      },
      paid: {
        status: "approved",
        tooltip: (
          <span>
            <b>No pending balance</b>
            <br />${paid} of ${owed} paid
          </span>
        )
      },
      default: {
        status: "not-assigned",
        tooltip: "No payment necessary"
      }
    };

    return (
      <div
        onClick={isFieldReadOnly ? noop : this.showBulkEditModal}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: "100%",
          padding: 8,
          borderRadius: 4,
          cursor: isFieldReadOnly ? "default" : "pointer"
        }}
      >
        <Tooltip
          tooltip={R.pathOr(
            statusMap.default.tooltip,
            [status, "tooltip"],
            statusMap
          )}
          placement="bottom"
        >
          <span>
            <StatusCircle
              status={R.pathOr(
                statusMap.default.status,
                [status, "status"],
                statusMap
              )}
            />
          </span>
        </Tooltip>
      </div>
    );
  }
}

export default decorate(Payment);
