import { createHandlers, createModule } from "redux-mvc";
import * as R from "ramda";
import { NAMESPACE } from "./constants";

import searchBar from "ui-kit/SearchBar";

const iniState = {
  iniSelected: [],
  selectedFields: []
};

const reducers = {
  init: (_, { payload: iniSelected }) => ({
    iniSelected,
    selectedFields: iniSelected
  }),
  toggleField: ({ selectedFields }, { payload: fieldId }) => ({
    selectedFields: R.contains(fieldId, selectedFields)
      ? R.without([fieldId], selectedFields)
      : R.concat([fieldId], selectedFields)
  }),
  disableField: ({ selectedFields }, { payload: fieldId }) => ({
    selectedFields: R.without([fieldId], selectedFields)
  }),
  enableAllFields: (_, { payload: fields }) => ({
    selectedFields: R.map(R.prop("id"), fields)
  }),
  disableAllFields: R.always({ selectedFields: [] })
};

const handlers = createHandlers({ iniState, reducers, namespace: NAMESPACE });

const { actions, getters } = handlers;

export { actions, getters };

const module = createModule(handlers);

module.plugModule(searchBar);

export default module;
