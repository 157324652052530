import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "../model";

import { TABLE_INSTANCE_ID } from "../constants";

import { Div, BigFilledButton, AddCircleIcon, Text5 } from "components/Base";
import ShowNoResults from "ui-kit/ShowNoResults";

import Table, { TableWrapper } from "ui-kit/Table/View";

const decorate = connect(
  (state, props) => ({
    loading: getters.loading(state, props),
    showNoResults: getters.showNoResults(state, props)
  }),
  {
    openAddAlertModal: actions.openAddAlertModal
  }
);

const Body = ({
  openAddAlertModal,
  loading = false,
  showNoResults = false
}) => (
  <Div display="column" width={1}>
    <Div display="row.space-between.center" mb={4}>
      <Text5 bold color="neutral9">
        Alerts
      </Text5>
      <Div ml={5} display="row.flex-start.center">
        <BigFilledButton
          color="white"
          bg="altB5"
          LeftIcon={AddCircleIcon}
          onClick={openAddAlertModal}
        >
          New Alert
        </BigFilledButton>
      </Div>
    </Div>
    {showNoResults ? (
      <ShowNoResults flex={1} />
    ) : (
      <TableWrapper
        loading={loading}
        style={{ flex: 1 }}
        instanceId={TABLE_INSTANCE_ID}
      >
        <Table
          instanceId={TABLE_INSTANCE_ID}
          showCheckbox={false}
          showOpenRecord
        />
      </TableWrapper>
    )}
  </Div>
);

export default decorate(Body);
