import React from "react";
import * as R from "ramda";

import { Div, MediumOutlineButton } from "components/Base";
import CollapsablePanel from "ui-kit/CollapsiblePanel";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import { connect } from "react-redux";
import { actions, getters } from "../../model";
import { LoadingIcon } from "components/Base";
import NotConfigured from "../NotConfigured";
import Table from "../Table";
import { AUTO_ASSIGN_COLUMNS, CARD_TYPES } from "../../constants";

const decorate = R.compose(
  connect(
    (state, props) => ({
      community: getters.communityDetails(state, props),
      assignedRows: getters.assignedAutoItems(state, props),
      loading: getters.loadingAutoAssignItems(state, props)
    }),
    {
      openModal: actions.openSelectGroupAutoAssignItemsModal,
      goToLearnLennd: actions.goToLearnLennd,
      removeGroupAutoAssignItem: actions.removeGroupAutoAssignItem,
      openUpdateGroupItemModal: ({ id }) =>
        actions.openUpdateGroupItemModal({
          id,
          assignModalType: CARD_TYPES.AUTO_ASSIGN
        })
    }
  ),
  CSSModules(css)
);

const AutoAssignItems = ({
  goToLearnLennd,
  assignedRows,
  loading,
  openModal,
  removeGroupAutoAssignItem,
  openUpdateGroupItemModal
}) => (
  <CollapsablePanel
    heading={() => (
      <Div display="row.space-between.center" my={2} width={1} pr={4}>
        <div className={css.title}>Auto-Assign Items</div>
        <NotConfigured
          configured={!R.isEmpty(assignedRows)}
          loading={loading}
          text={`${R.length(assignedRows)} auto-assign item${
            R.length(assignedRows) !== 1 ? "s" : ""
          } Assigned`}
        />
      </Div>
    )}
    bg="white"
    arrowColor="gray7"
    bc="neutral3"
    right={false}
    bra={1}
    mb={3}
    p={3}
    collapsedDefault={true}
  >
    <div styleName="collapsable">
      <div styleName="description">
        Do you want to automatically assign items to this group?
      </div>
      <div styleName="row-btn">
        <MediumOutlineButton
          color="primary7"
          bc="primary7"
          LeftIcon={() => (
            <span
              class="material-icons"
              style={{ fontSize: 16, marginRight: 8, height: 16 }}
            >
              format_list_bulleted
            </span>
          )}
          width={200}
          onClick={openModal}
        >
          Select Items
        </MediumOutlineButton>
        <MediumOutlineButton
          color="primary7"
          bc="primary7"
          LeftIcon={() => (
            <span
              class="material-icons"
              style={{ fontSize: 16, marginRight: 8, height: 16 }}
            >
              import_contacts
            </span>
          )}
          ml={2}
          width={230}
          onClick={goToLearnLennd}
        >
          Learn about auto-assignment
        </MediumOutlineButton>
      </div>

      {loading ? (
        <div styleName="center">
          <LoadingIcon color="gray3" size={50} />
        </div>
      ) : (
        <Table
          rows={assignedRows}
          openUpdateModal={openUpdateGroupItemModal}
          deleteRow={removeGroupAutoAssignItem}
          showRequired={false}
          shouldSort={false}
          onReorder={() => {}}
          columns={AUTO_ASSIGN_COLUMNS}
          emptyText="You haven't selected any items to be auto-assigned."
        />
      )}
    </div>
  </CollapsablePanel>
);

export default decorate(AutoAssignItems);
