import React from "react";

import { connect } from "react-redux";

import { getters, actions } from "Sponsors/SelfSetup";

import {
  MediumOutlineInput,
  Div,
  Text3,
  MediumFilledButton,
  MediumOutlineButton
} from "components/Base";

import MiniModalWrapper from "Orders/Common/View/MiniModalWrapper";

const decorate = connect(
  (state, props) => ({
    categoryName: getters.newCategoryName(state),
    showModal: getters.addingCategory(state, props)
  }),
  {
    hideModal: () => actions.closeAddCategoryModal(),
    onDone: actions.setAddCategoryRequest,
    onClose: () => actions.closeAddCategoryModal(),
    setCategoryName: actions.setNewCategoryName
  }
);

const NewCategoryModal = ({
  onClose,
  onDone,
  categoryName,
  setCategoryName,
  showModal = false
}) => (
  <MiniModalWrapper
    showModal={showModal}
    title="Add Category"
    width={300}
    height={200}
    hideModal={onClose}
  >
    <Div p={3} width={1}>
      <Text3 bold>Category Name</Text3>
      <MediumOutlineInput
        value={categoryName}
        onChange={value => setCategoryName(value)}
        continuous
        width={1}
        my={2}
      />
      <Div display="row.space-between.center">
        <MediumOutlineButton onClick={onClose} width={100}>
          Cancel
        </MediumOutlineButton>
        <MediumFilledButton bg="primary7" width={100} onClick={onDone}>
          Save
        </MediumFilledButton>
      </Div>
    </Div>
  </MiniModalWrapper>
);

export default decorate(NewCategoryModal);
