import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Item from "components/Global/Module/Sidebar/Item";
import Title from "components/Global/Module/Sidebar/Title";
import Content from "components/Global/Module/Sidebar/Container";
import CanUserDo from "components/Global/Security/CanUserDo";

const PrimarySidebarScene = ({ onSceneChange, params = {}, active }) => (
  <Content>
    <Title>Inventory</Title>
    <Item
      active={active === "inventory"}
      linkTo={`/event/${params.eventId}/inventory`}
    >
      Overview
    </Item>
    <Item
      linkTo={`/event/${params.eventId}/inventory/needs`}
      onClick={() => onSceneChange("needs")}
      iconRight={<i className="material-icons">keyboard_arrow_right</i>}
      active={active === "needs"}
    >
      Needs
    </Item>
    <CanUserDo action={"read_catalog_items"}>
      <Item
        linkTo={`/event/${params.eventId}/settings/inventory/catalog`}
        iconRight={<i className="material-icons">keyboard_arrow_right</i>}
        onClick={() => onSceneChange("catalog")}
        active={active === "catalog"}
      >
        Catalog
      </Item>
    </CanUserDo>
  </Content>
);

export default CSSModules(PrimarySidebarScene, css);
