import React from "react";
import { Div } from "components/Base";
import Catalogs from "./Catalogs";
import ItemsSelectors from "./ItemsSelectors";
import RequestSummary from "Orders/CreateOrderModal/RequestSummary";
import AddZonesModal from "Orders/AddZonesModal/View";

const Layout = ({
  itemTypes,
  itemTypeProps,
  activeItemTypeId,
  onNextStep,
  subtotal,
  total,
  selectedVariants,
  onPreviousStep,
  isValidStep,
  removeItem,
  updateVariantZones
}) => (
  <>
    <AddZonesModal onSave={updateVariantZones} />
    <Div height={600} display="row.flex-start.stretch">
      <Catalogs itemTypes={itemTypes} />
      <ItemsSelectors
        itemTypeProps={itemTypeProps}
        activeItemTypeId={activeItemTypeId}
      />
      <RequestSummary
        onNextStep={onNextStep}
        onPreviousStep={onPreviousStep}
        total={total}
        subtotal={subtotal}
        selectedVariants={selectedVariants}
        itemTypes={itemTypes}
        nextBtnDisabled={!isValidStep}
        removeItem={removeItem}
      />
    </Div>
  </>
);

export default Layout;
