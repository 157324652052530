import {
  put,
  call,
  takeEvery,
  all,
  fork,
  select,
  take
} from "redux-saga/effects";
import * as R from "ramda";
import moment from "moment";
import uuid from "node-uuid";

import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";

import { BEGIN, COMMIT, REVERT } from "redux-optimist";

import { actions, getters } from "Schedules/Schedule";
import {
  actions as TableActions,
  getters as TableGetters
} from "ui-kit/Table/model";
import { actions as activityDetailsActions } from "Schedules/ActivityDetailsSidebar/model";
import { actions as ViewPickerActions } from "ui-kit/ViewPicker";
import { actions as SearchBarActions } from "ui-kit/SearchBar";
import { registerError } from "redux/modules/errors/actions";

import { getCredentials } from "redux/modules/user/selectors";
import {
  COLUMN_ACTIONS,
  GROUP_ACTIONS,
  ROW_ACTIONS as TABLE_ROW_ACTIONS
} from "ui-kit/Table/constants";
import { TABLE_INSTANCE_ID, ROW_ACTIONS } from "Schedules/Schedule/constants";

import ids from "utils/standard-module-field-ids";

import Api from "../api";
import { apiCall } from "App/Data/sagas";

import { getParams } from "./utils";
import { debounce } from "utils/General/sagas";

const readOnlyFields = resolveReadOnlyFields();
const resizeColumns = function*({ meta }) {
  const widths = yield select(TableGetters.columnWidths, {
    instanceId: meta.instanceId
  });
  const preferences = yield select(getters.preferences);
  const updatedFieldWidths = {
    ...preferences.field_widths,
    ...widths
  };

  yield put(actions.updateFieldWidths(updatedFieldWidths));
};

const watchResizeColumns = debounce(
  action =>
    action.type === TableActions.saveColumnWidth.type &&
    R.path(["meta", "instanceId"], action) === TABLE_INSTANCE_ID,
  resizeColumns,
  500
);

const untoggleRows = function*() {
  yield put(
    TableActions.clearSelectedRows(null, {
      meta: {
        instanceId: TABLE_INSTANCE_ID
      }
    })
  );
};

const watchUntoggleRows = function*() {
  yield takeEvery(
    [
      actions.setSelectedFieldFilters.type,
      actions.clearFilters.type,
      actions.removeSelectedFieldFilter.type,
      ViewPickerActions.revertViewChanges.type,
      ViewPickerActions.deleteView.type,
      ViewPickerActions.selectView.type,
      ViewPickerActions.addView.type,
      SearchBarActions.clearSearch.type
    ],
    untoggleRows
  );
};
const duplicateRow = function*({ payload: { row: oldRow, group }, meta }) {
  const moduleId = yield select(getters.moduleId);

  const transactionId = yield call([uuid, uuid.v4]);

  const newRow = R.assoc("id", transactionId, oldRow);

  yield put(
    TableActions.duplicateRow(
      { newRow, oldRow, group },
      {
        optimist: { type: BEGIN, id: transactionId },
        meta
      }
    )
  );

  try {
    const { payload } = yield call(apiCall, {
      method: "post",
      url: `/schedules/schedule/${moduleId}/activities/${oldRow.id}/clone`
    });
    yield put(
      TableActions.duplicateRowResponse(
        { ...payload, group },
        {
          optimist: { type: COMMIT, id: transactionId },
          meta: {
            instanceId: TABLE_INSTANCE_ID
          }
        }
      )
    );
  } catch (error) {
    yield all([
      put(
        TableActions.duplicateRowResponse(null, {
          optimist: { type: REVERT, id: transactionId },
          meta: {
            instanceId: TABLE_INSTANCE_ID
          }
        })
      ),
      put(
        registerError([
          {
            system: error,
            user: "An error occurred duplicating activity"
          }
        ])
      )
    ]);
  }
};

const insertRow = function*({
  payload: { row: oldRow, actionId: operation, group },
  meta
}) {
  const { credentials, eventId } = yield call(getParams);
  const moduleId = yield select(getters.moduleId);
  const state = yield select(R.identity);
  const columns = getters.columns(state);

  const transformFn = operation => ({
    value: {
      value: {
        iso: date =>
          moment(date)
            [operation === ROW_ACTIONS.INSERT_BEFORE ? "subtract" : "add"](
              30,
              "minutes"
            )
            .format()
      }
    }
  });

  const fn = R.cond([
    [
      R.both(R.has(ids.SCHEDULES.STARTS_AT), R.has(ids.SCHEDULES.ENDS_AT)),
      R.evolve({
        [ids.SCHEDULES.STARTS_AT]: transformFn(operation),
        [ids.SCHEDULES.ENDS_AT]: transformFn(operation)
      })
    ],
    [
      R.has(ids.SCHEDULES.STARTS_AT),
      R.evolve({
        [ids.SCHEDULES.STARTS_AT]: transformFn(operation)
      })
    ],
    [
      R.has(ids.SCHEDULES.ENDS_AT),
      R.evolve({
        [ids.SCHEDULES.ENDS_AT]: transformFn(operation)
      })
    ],
    [R.T, R.identity]
  ]);

  const values = R.compose(
    fn,
    R.mapObjIndexed((row, key) => ({
      id: key,
      ...row,
      value: {
        value: row.value,
        type: row.type
      }
    })),
    R.omit(readOnlyFields),
    R.pick(R.map(R.prop("id"), columns)),
    R.reject(R.anyPass([R.propEq("value", null), R.complement(R.has("value"))]))
  )(oldRow);

  const transactionId = yield call([uuid, uuid.v4]);

  const newRow = R.assoc("id", transactionId, oldRow);

  yield put(
    TableActions.duplicateRow(
      {
        newRow,
        oldRow,
        delta: operation === ROW_ACTIONS.INSERT_BEFORE ? 0 : 1,
        group
      },
      {
        optimist: { type: BEGIN, id: transactionId },
        meta
      }
    )
  );

  try {
    const { payload } = yield call(
      Api.addActivity,
      {
        credentials,
        moduleId
      },
      {
        eventId,
        moduleId,
        values
      }
    );

    yield put(
      TableActions.duplicateRowResponse(
        { ...payload, group },
        {
          optimist: { type: COMMIT, id: transactionId },
          meta
        }
      )
    );
  } catch (error) {
    yield all([
      put(
        TableActions.duplicateRowResponse(null, {
          optimist: { type: REVERT, id: transactionId },
          meta: {
            instanceId: TABLE_INSTANCE_ID
          }
        })
      ),
      put(
        registerError([
          {
            system: error,
            user: "An error occurred inserting activity"
          }
        ])
      )
    ]);
  }
};

const deleteRow = function*({ payload: { row, group }, meta }) {
  const { credentials } = yield call(getParams);
  const moduleId = yield select(getters.moduleId);

  const transactionId = yield call([uuid, uuid.v4]);

  yield put(
    TableActions.deleteRow(
      { row, group },
      {
        optimist: { type: BEGIN, id: transactionId },
        meta
      }
    )
  );

  try {
    yield call(Api.deleteActivity, credentials, {
      moduleId,
      record: {
        id: row.id
      }
    });

    yield put(
      TableActions.deleteRowResponse(null, {
        optimist: { type: COMMIT, id: transactionId },
        meta
      })
    );
  } catch (error) {
    yield all([
      put(
        TableActions.deleteRowResponse(null, {
          optimist: { type: REVERT, id: transactionId },
          meta
        })
      ),
      put(
        registerError([
          {
            system: error,
            user: "An error occurred deleting activity"
          }
        ])
      )
    ]);
  }
};

const watchSaveCell = function*() {
  for (;;) {
    const { meta, optimist, payload } = yield take(TableActions.save.type);
    if (meta.instanceId === TABLE_INSTANCE_ID) {
      const credentials = yield select(getCredentials);

      yield call(Api.addValues, {
        credentials,
        data: {
          fieldId: payload.column.id,
          recordId: payload.row.id,
          value: {
            ...R.propOr({}, payload.column.id, payload.row),
            type: payload.column.type,
            value: payload.value
          }
        }
      });

      yield put(
        TableActions.saveResponse(null, {
          optimist: { ...optimist, type: COMMIT },
          meta
        })
      );
    }
  }
};

const hideColumn = function*({ payload: { column } }) {
  const preferences = yield select(getters.preferences);

  yield put(
    actions.setPreferences({
      ...preferences,
      visible_fields: preferences.visible_fields.filter(
        fId => fId !== column.id
      )
    })
  );
};

const groupBy = function*({ payload: { column } }) {
  yield put(actions.setGroupedByField(column.id));
};

const exportGroup = function*({ payload: { group, actionId } }) {
  yield put(
    actions.exportData({
      contentType: R.prop(actionId, {
        [GROUP_ACTIONS.EXPORT_CSV]: "csv",
        [GROUP_ACTIONS.EXPORT_XLSX]: "xlsx"
      }),
      recordIds: group.list
    })
  );
};

const editActivity = function*({
  payload: {
    row: { id: activityId }
  }
}) {
  const scheduleId = yield select(getters.moduleId);
  yield put(
    activityDetailsActions.openActivity({
      activityId,
      scheduleId
    })
  );
};

const watchTableActions = function*() {
  for (;;) {
    const action = yield take(TableActions.executeAction.type);
    if (action.meta.instanceId === TABLE_INSTANCE_ID) {
      const delegate = R.prop(action.payload.actionId, {
        [ROW_ACTIONS.DUPLICATE]: duplicateRow,
        [ROW_ACTIONS.INSERT_AFTER]: insertRow,
        [ROW_ACTIONS.INSERT_BEFORE]: insertRow,
        [ROW_ACTIONS.INSERT_BEFORE]: insertRow,
        [ROW_ACTIONS.REMOVE]: deleteRow,
        [ROW_ACTIONS.OPEN_ACTIVITY]: editActivity,
        [TABLE_ROW_ACTIONS.OPEN_RECORD]: editActivity,
        [COLUMN_ACTIONS.HIDE]: hideColumn,
        [COLUMN_ACTIONS.GROUP_BY]: groupBy,
        [GROUP_ACTIONS.EXPORT_CSV]: exportGroup,
        [GROUP_ACTIONS.EXPORT_XLSX]: exportGroup
      });

      if (delegate) {
        yield fork(delegate, action);
      }
    }
  }
};

const rootSaga = function*() {
  yield all([
    fork(watchUntoggleRows),
    fork(watchResizeColumns),
    fork(watchTableActions),
    fork(watchSaveCell)
  ]);
};

export default rootSaga;
