import Home from "PortalHub/Home/View";
import People from "PortalHub/People/View";
import OrderRequestSelectItems from "PortalHub/CreateOrderRequest/SelectItems/View";
import Reports from "PortalHub/Reports/Home/View";
import Page from "PortalHub/Page/View";
import Alert from "PortalHub/Alert/View";
import Wrapper from "PortalHub/Wrapper/View";

import { hot } from "App/Config/hot";

const PortalHub = {
  Home,
  People,
  Reports,
  OrderRequestSelectItems,
  Page,
  Alert,
  Wrapper
};

export default hot(PortalHub);
