import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const AlertHeader = ({ children }) => <div styleName="header">{children}</div>;

AlertHeader.propTypes = {
  children: PropTypes.node.isRequired
};

export default CSSModules(AlertHeader, css);
