import { createModule } from "redux-mvc";

import model from "./model";
import rootSaga from "./sagas";

import formModule from "ui-kit/Form";
import fieldTypesModule from "ui-kit/FieldTypes";

const module = createModule({ ...model, optimist: true });

module.setRootSaga(rootSaga);

module.plugModule(formModule);
module.plugModule(fieldTypesModule);

export default module;
