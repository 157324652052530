import { put, take, takeEvery, all, fork, select } from "redux-saga/effects";
import * as R from "ramda";

import { debounce } from "utils/General/sagas";

import { actions, getters } from "Files/Manage";
import {
  actions as TableActions,
  getters as TableGetters
} from "ui-kit/Table/model";
import { getVisibleRows, getAllChildrenRow } from "Files/Manage/selectors";

import {
  ROW_ACTIONS as TABLE_ROW_ACTIONS,
  TREE_NODE_COLUMN_ID
} from "ui-kit/Table/constants";
import { actions as SearchBarActions } from "ui-kit/SearchBar";
import { actions as fileDetailsActions } from "Files/FileDetailsSidebar/model";

import {
  TABLE_INSTANCE_ID,
  ROW_ACTIONS,
  DEFAULT_TREE_NODE_WIDTH
} from "Files/Manage/constants";

const resizeColumns = function*({ meta }) {
  const widths = yield select(TableGetters.columnWidths, {
    instanceId: meta.instanceId
  });
  const preferences = yield select(getters.preferences);
  const updatedFieldWidths = {
    ...preferences.field_widths,
    ...widths
  };

  yield put(actions.updateFieldWidths(updatedFieldWidths));
};

const watchResizeColumns = debounce(
  action =>
    action.type === TableActions.saveColumnWidth.type &&
    R.path(["meta", "instanceId"], action) === TABLE_INSTANCE_ID,
  resizeColumns,
  500
);

const refreshRows = function*() {
  const fields = yield select(getters.columns);
  const newRows = yield select(getVisibleRows);
  yield put(
    TableActions.setData(
      {
        canEditCells: false,
        columns: fields,
        rows: newRows,
        columnWidths: DEFAULT_TREE_NODE_WIDTH
      },
      {
        meta: {
          instanceId: TABLE_INSTANCE_ID
        }
      }
    )
  );
};

const untoggleRows = function*() {
  yield put(actions.untoggleAllRows());
  yield fork(refreshRows);
};

const openRecord = function*({ payload: { row } }) {
  const id = R.prop("id", row);
  const rowData = R.path([TREE_NODE_COLUMN_ID, "value"], row);
  yield put(
    fileDetailsActions.openFile({
      fileId: id,
      fileSource: R.propOr("upload", "source", rowData)
    })
  );
};

const toggleRows = function*({ payload: { row } }) {
  const id = R.prop("id", row);
  yield put(actions.toggleRow(id));
  const rowData = R.path([TREE_NODE_COLUMN_ID, "value"], row);
  const isRowChecked = R.prop("isChecked", rowData);
  const allChildren = yield select(getAllChildrenRow, {
    rowId: id
  });

  yield all([
    put(
      actions.updateSelectedRows({
        rowIds: allChildren,
        isSelected: !isRowChecked
      })
    ),
    put(actions.updateParentRows(id))
  ]);
  yield fork(refreshRows);
};

const expandRows = function*({ payload: { row } }) {
  yield put(actions.expandRow(row.id));
  yield fork(refreshRows);
};
const watchTableActions = function*() {
  for (;;) {
    const action = yield take(TableActions.executeAction.type);
    const delegate = R.prop(action.payload.actionId, {
      [ROW_ACTIONS.VIEW_RECORD]: openRecord,
      [TABLE_ROW_ACTIONS.OPEN_RECORD]: openRecord,
      [TABLE_ROW_ACTIONS.TOGGLE_FOLDER_ROW]: expandRows,
      [TABLE_ROW_ACTIONS.TOGGLE_CHECKBOX_ROW]: toggleRows
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const watchUntoggleRows = function*() {
  yield takeEvery(
    [
      actions.setSelectedFieldFilters.type,
      actions.clearFilters.type,
      actions.removeSelectedFieldFilter.type,
      SearchBarActions.clearSearch.type,
      actions.clearSelectedRows.type
    ],
    untoggleRows
  );
};

const rootSaga = function*() {
  yield all([
    fork(watchTableActions),
    fork(watchUntoggleRows),
    fork(watchResizeColumns)
  ]);
};

export default rootSaga;
