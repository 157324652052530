import * as R from "ramda";
import {
  getParents,
  getChildren,
  getFolderOrder,
  getPagesOrder,
  removePagesOrder,
  convertLinksIntoPagesOrder,
  updateOrderRecursive
} from "./utils";
import { createHandlers } from "redux-mvc";

import { NAMESPACE, SECTIONS } from "./constants";

const iniState = {
  permissionSetId: "",
  loading: false,
  order: {},
  pages: [],
  sections: SECTIONS,
  pagesOrder: {},
  pagesSelected: [],
  isGroupPortal: true,
  enableCommunitySidebar: false,
  name: ""
};

const model = createHandlers({
  iniState,
  reducers: {
    cancelInstance: R.always(iniState),
    save: R.identity,
    init: R.identity,
    selectAllPages: R.identity,
    deselectAllPages: state => ({
      pagesSelected: [],
      pagesOrder: {
        root: R.filter(
          orderId => R.any(section => orderId === section.id, state.sections),
          R.propOr([], "root", state.pagesOrder)
        )
      }
    }),
    setInitialData: (
      state,
      { payload: { is_group_portal, settings, links, name, pages } }
    ) => {
      const updatedSections = R.map(section => {
        const mainFields = {
          title: !settings[`${section.id}_title`]
            ? section.name
            : settings[`${section.id}_title`] === section.name
            ? settings[`${section.id}_title`]
            : "custom",
          custom:
            settings[`${section.id}_title`] &&
            settings[`${section.id}_title`] !== section.name
              ? settings[`${section.id}_title`]
              : "",
          description: settings[`${section.id}_description`],
          showDescription: settings[`show_${section.id}_description`],
          sectionOrder: settings[`${section.id}_order`]
        };

        if (!section.locked) {
          return {
            ...section,
            ...mainFields,
            isEnabled: settings[`is_${section.id}_enabled`]
          };
        }

        return {
          ...section,
          ...mainFields
        };
      }, state.sections);

      return {
        isGroupPortal: is_group_portal,
        enableCommunitySidebar: settings.enable_community_sidebar,
        pages,
        name,
        sections: updatedSections,
        pagesSelected: R.compose(
          R.map(R.prop("id")),
          R.filter(R.propEq("content_type", "page"))
        )(links),
        pagesOrder: R.isEmpty(links)
          ? {
              root: R.compose(
                R.map(R.prop("id")),
                R.filter(({ isEnabled, locked }) => isEnabled && !locked)
              )(updatedSections)
            }
          : convertLinksIntoPagesOrder(links, updatedSections),
        order: R.reduce(
          (acc, section) => {
            if (section.sectionOrder > 0) {
              return {
                ...acc,
                [section.id]: section.sectionOrder
              };
            }
            return acc;
          },
          {},
          updatedSections
        )
      };
    },
    updateField: (state, { payload: { id, field, value } }) => {
      const fieldIndex = R.findIndex(R.propEq("id", id), state.sections);
      const updatedSection = R.assoc(field, value, state.sections[fieldIndex]);
      return {
        sections: R.update(fieldIndex, updatedSection, state.sections)
      };
    },
    updateTitle: (state, { payload: { id, value } }) => {
      const fieldIndex = R.findIndex(R.propEq("id", id), state.sections);
      const updatedSection = {
        ...state.sections[fieldIndex],
        title: value,
        custom: value === "custom" ? state.sections[fieldIndex].custom : ""
      };
      return {
        sections: R.update(fieldIndex, updatedSection, state.sections)
      };
    },
    toggleIsEnabled: (state, { payload: { id, isEnabled } }) => {
      const fieldIndex = R.findIndex(R.propEq("id", id), state.sections);
      const updatedSection = {
        ...state.sections[fieldIndex],
        isEnabled: !isEnabled
      };
      const updatedSections = R.update(
        fieldIndex,
        updatedSection,
        state.sections
      );
      const children = R.propOr([], "root", state.pagesOrder);
      return {
        sections: updatedSections,
        pagesOrder: {
          ...state.pagesOrder,
          root: isEnabled
            ? R.filter(childId => childId !== id, children)
            : [...children, id]
        },
        order: isEnabled
          ? R.compose(
              R.addIndex(R.reduce)(
                (acc, id, idx) => ({
                  ...acc,
                  [id]: idx + 1
                }),
                {}
              ),
              R.keys,
              R.dissoc(id)
            )(state.order)
          : R.assoc(
              id,
              R.compose(
                R.length,
                R.filter(({ locked, isEnabled }) => !locked && isEnabled)
              )(updatedSections),
              state.order
            )
      };
    },
    reorderEnabledSections: (_, { payload: orderedSections }) => ({
      order: R.addIndex(R.reduce)(
        (acc, section, idx) => ({
          ...acc,
          [section.id]: idx + 1
        }),
        {},
        orderedSections
      )
    }),
    togglePageId: (state, { payload: { selected, id, type } }) => {
      const children = getChildren(id, state.pages);
      const parents = getParents(id, state.pages);
      if (type !== "folder") {
        return {
          pagesSelected: selected
            ? R.filter(
                selectedId =>
                  R.all(childId => selectedId !== childId, [
                    ...children,
                    ...parents
                  ]),
                state.pagesSelected
              )
            : R.uniq([...state.pagesSelected, ...parents]),
          pagesOrder: !selected
            ? getPagesOrder(id, state.pages, state.pagesOrder)
            : removePagesOrder(id, state.pages, state.pagesOrder)
        };
      }

      return {
        pagesSelected: selected
          ? R.filter(
              selectedId =>
                R.all(childId => selectedId !== childId, [
                  ...children,
                  ...parents
                ]),
              state.pagesSelected
            )
          : R.uniq([
              ...state.pagesSelected,
              ...getParents(id, state.pages),
              ...children
            ]),
        pagesOrder: !selected
          ? getFolderOrder(id, state.pages, state.pagesOrder)
          : removePagesOrder(id, state.pages, state.pagesOrder)
      };
    },
    updatePagesOrder: (state, { payload: { id, orderedFields } }) => ({
      pagesOrder: R.assoc(
        id,
        R.map(({ id }) => id, orderedFields),
        state.pagesOrder
      )
    }),
    enableAllPages: (state, { payload: { treeOrder } }) => ({
      pagesOrder: updateOrderRecursive("root", state.pagesOrder, treeOrder),
      pagesSelected: R.map(R.prop("id"), state.pages)
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
