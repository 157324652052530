import React, { useState } from "react";
import { Div, RightIcon, LeftIcon } from "components/Base";
import MonthCalendar from "ui-kit/MonthCalendar";
import Circle from "ui-kit/Circle";
import { noop } from "utils/General";

import moment from "moment";

import * as R from "ramda";

const FIRST_MONTH = 0;
const LAST_MONTH = 11;

const getMonthEvents = (events, month, year) => {
  const currentMonthAndYear = moment()
    .set("month", month)
    .set("year", year);

  return R.filter(({ date_from, date_to }) => {
    if (date_from && date_to) {
      return (
        moment(date_from).isSame(currentMonthAndYear, "month") ||
        moment(date_to).isSame(currentMonthAndYear, "month")
      );
    }
    return false;
  }, events);
};

const SliderYearCalendar = ({
  year = moment().year(),
  onClick = noop,
  onEventClick = noop,
  events = [],
  showMonthAndYear = false
}) => {
  const [currentMonth, setCurrentMonth] = useState(moment().month());

  const moveRight = () => {
    setCurrentMonth(current => current + 1);
  };

  const moveLeft = () => {
    setCurrentMonth(current => current - 1);
  };

  const getRangeToRender = () =>
    currentMonth === LAST_MONTH ? currentMonth + 1 : currentMonth + 2;

  return (
    <Div style={{ position: "relative" }} width={1}>
      {currentMonth > FIRST_MONTH && (
        <Circle
          style={{
            position: "absolute",
            left: 2,
            top: 100,
            backgroundColor: "#ffffff",
            border: "2px solid #eaeaea"
          }}
          size={40}
        >
          <LeftIcon size={35} onClick={moveLeft} />
        </Circle>
      )}

      {currentMonth < LAST_MONTH && (
        <Circle
          size={40}
          style={{
            position: "absolute",
            right: 2,
            top: 100,
            backgroundColor: "#ffffff",
            border: "2px solid #eaeaea"
          }}
        >
          <RightIcon size={35} onClick={moveRight} />
        </Circle>
      )}

      <Div
        width={1}
        display="row.flex-start.flex-start"
        style={{ overflowX: "hidden" }}
      >
        {R.compose(
          R.map(month => (
            <Div key={month.toString()} mr={3}>
              <MonthCalendar
                year={year}
                customMonthRender={date =>
                  showMonthAndYear
                    ? date.format("MMMM YYYY")
                    : date.format("MMM")
                }
                showCurrentDay
                month={month}
                events={getMonthEvents(events, month, year)}
                onClick={onClick}
                onEventClick={onEventClick}
              />
            </Div>
          ))
        )(R.range(currentMonth, getRangeToRender()))}
      </Div>
    </Div>
  );
};

export default SliderYearCalendar;
