import React from "react";
import * as R from "ramda";
import { HalfSizeDotIcon, Div, MediumOutlineButton, Text3 } from "../../index";

const Type10Card = ({ frontText, backText, color, buttons }) => {
  return (
    <Div display="row.flex-start.center" py={3} bb={1} bc="neutral1">
      <Div display="row.flex-start.center" flex={1}>
        <HalfSizeDotIcon color={color} size={16} />
        <Text3 ml={1} bold>
          {frontText}
        </Text3>
        <Text3 ml={1}>{backText}</Text3>
      </Div>
      {R.map(
        ({ text, onClick }) => (
          <MediumOutlineButton ml={1} onClick={onClick}>
            {text}
          </MediumOutlineButton>
        ),
        buttons
      )}
    </Div>
  );
};

export { Type10Card };
