import PropTypes from "prop-types";
import React, { Component } from "react";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class RaisedButton extends Component {
  render() {
    let leftIcon, rightIcon;
    if (this.props.leftIcon) {
      leftIcon = (
        <i className="material-icons" styleName="iconLeft">
          {this.props.leftIcon}
        </i>
      );
    }
    if (this.props.rightIcon) {
      rightIcon = (
        <i className="material-icons" styleName="iconRight">
          {this.props.rightIcon}
        </i>
      );
    }

    const classNames = [css.container];
    if (this.props.active) {
      classNames.push(css.active);
    }
    if (this.props.className) {
      classNames.push(this.props.className);
    }

    return (
      <div
        style={this.props.style}
        className={classNames.join(" ")}
        onClick={this.props.action}
      >
        {leftIcon}
        {this.props.label}
        {rightIcon}
      </div>
    );
  }
}

RaisedButton.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  active: PropTypes.bool,
  style: PropTypes.object,
  leftIcon: PropTypes.string,
  rightIcon: PropTypes.string,
  action: PropTypes.func,
  className: PropTypes.string
};

export default RaisedButton;
