export const DEFAULT_CONTACT_LIST_ID = "036b8e11-4848-42b2-ba21-b0350921b0eb";
import * as STANDARD_MODULE_FIELDS from "@lennd/value-types/src/constants/standard-module-field-ids";

export const CONTACT_STANDARD_FIELD_IDS = {
  fname: STANDARD_MODULE_FIELDS.CONTACTS.FIRST_NAME,
  lname: STANDARD_MODULE_FIELDS.CONTACTS.LAST_NAME,
  email: STANDARD_MODULE_FIELDS.CONTACTS.EMAIL,
  website: STANDARD_MODULE_FIELDS.CONTACTS.WEBSITE,
  title: STANDARD_MODULE_FIELDS.CONTACTS.TITLE,
  address_street_line_1: STANDARD_MODULE_FIELDS.CONTACTS.ADDRESS_LINE_1,
  address_street_line_2: STANDARD_MODULE_FIELDS.CONTACTS.ADDRESS_LINE_2,
  address_city: STANDARD_MODULE_FIELDS.CONTACTS.ADDRESS_CITY,
  address_state: STANDARD_MODULE_FIELDS.CONTACTS.ADDRESS_STATE,
  avatar_url: STANDARD_MODULE_FIELDS.CONTACTS.AVATAR_URL,
  address_zip: STANDARD_MODULE_FIELDS.CONTACTS.ADDRESS_ZIP,
  catering_dietary_restrictions:
    STANDARD_MODULE_FIELDS.CONTACTS.CATERING_DIETARY_RESTRICTIONS,
  catering: "01549f52-109c-4a8f-b882-07f09247c172",
  address_country: STANDARD_MODULE_FIELDS.CONTACTS.ADDRESS_COUNTRY,
  home_phone: STANDARD_MODULE_FIELDS.CONTACTS.HOME_PHONE,
  mobile_phone: STANDARD_MODULE_FIELDS.CONTACTS.MOBILE_PHONE,
  credentials: "39a2d58a-2ebb-4712-b853-56a06f16dd03"
};
