import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters, actions } from "Portal/IntakeForm/model";
import {
  getLoginFields,
  getLoginValuesValid,
  getForgotFields,
  getForgotValuesValid
} from "Portal/IntakeForm/selectors";
import {
  Div,
  Text2,
  Text5,
  BigFilledButton,
  RightArrowIcon,
  MediumFilledButton
} from "components/Base";
import { Field } from "./InputFields";

const decorate = connect(
  state => ({
    loginValues: getters.loginValues(state),
    forgotValues: getters.forgotValues(state),
    loginFields: getLoginFields(state),
    forgotFields: getForgotFields(state),
    loginValid: getLoginValuesValid(state),
    forgotValid: getForgotValuesValid(state)
  }),
  {
    updateLoginValues: actions.updateLoginValues,
    updateForgotValues: actions.updateForgotValues,
    goToFormPage: actions.goToFormPage,
    login: actions.login,
    forgotPassword: actions.forgotPassword
  }
);

const LoginPage = ({
  loginFields,
  loginValues,
  updateLoginValues,
  loginValid,
  forgotFields,
  forgotValues,
  forgotValid,
  updateForgotValues,
  goToFormPage,
  login,
  forgotPassword
}) => (
  <Div display="row">
    <Div width={495}>
      <Text5 bold color="black" mb={2}>
        Log in Now
      </Text5>
      <Div width={1} bg="neutral0" bra={2} p={5}>
        {R.map(
          field => (
            <Field
              field={field}
              values={loginValues}
              updateValues={updateLoginValues}
            />
          ),
          loginFields
        )}
        <Div display="row.flex-end" mt={3}>
          <BigFilledButton
            color="white"
            RightIcon={RightArrowIcon}
            bg="indigo7"
            style={{ borderRadius: "20px" }}
            onClick={login}
            disabled={!loginValid}
          >
            Log In
          </BigFilledButton>
        </Div>
      </Div>
      <Text5 bold color="black" mb={2} mt={6}>
        Forgot Password
      </Text5>
      <Div width={1} bg="neutral0" bra={2} p={5}>
        {R.map(
          field => (
            <Field
              field={field}
              values={forgotValues}
              updateValues={updateForgotValues}
            />
          ),
          forgotFields
        )}
        <Div display="row.flex-end" mt={3}>
          <BigFilledButton
            color="white"
            RightIcon={RightArrowIcon}
            bg="indigo7"
            style={{ borderRadius: "20px" }}
            onClick={forgotPassword}
            disabled={!forgotValid}
          >
            Submit
          </BigFilledButton>
        </Div>
      </Div>
    </Div>
    <Div ml={3} pt={10} width={200}>
      <Text2 color="black" bold>
        Create an account
      </Text2>
      <Text2 mt={3}>
        If this is your first event with this organizer and you do not have an
        account yet, please click the button below to register your group.
      </Text2>
      <MediumFilledButton
        style={{ borderRadius: "20px" }}
        color="neutral7"
        RightIcon={RightArrowIcon}
        bg="neutral2"
        mt={4}
        onClick={goToFormPage}
      >
        Register
      </MediumFilledButton>
    </Div>
  </Div>
);

export default decorate(LoginPage);
