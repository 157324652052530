import React, { Component } from "react";
import { connect } from "react-redux";
import { debounce, sortBy } from "lodash";
import { orgId } from "redux/modules/organization/selectors";
import { eventId } from "redux/modules/event/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import Api from "utils/EventContacts/WebAPIUtils";
import {
  getContactLastName,
  getContactFirstName,
  getContactEmail
} from "components/Event/Accounts/Account/People/utils";
import { defaultProps } from "utils/General";

function mapStateToProps(state) {
  return {
    orgId: orgId(state),
    eventId: eventId(state),
    userId: state.user.user.id,
    userCredentials: getCredentials(state)
  };
}

const defaultState = { searchTerm: undefined, searching: false, results: [] };

class SearchContactController extends Component {
  state = defaultState;

  componentWillUnmount() {
    // this.search.cancel();
  }

  handleSearch = async term => {
    if (!term || !term.length) {
      return this.setState(defaultState);
    }
    if (term === this.state.searchTerm) return;

    this.setState({ searching: true });
    const contacts = await Api.searchContacts(
      this.props.userCredentials,
      this.props.orgId,
      this.props.eventId,
      term
    );
    this.setState({
      searchTerm: term,
      searching: false,
      results: sortBy(
        contacts.slice(0, this.props.limit).map(c => ({
          id: c.id,
          email: getContactEmail(c.values),
          fullName: `${getContactFirstName(c.values)} ${getContactLastName(
            c.values
          )}`.trim(),
          firstName: getContactFirstName(c.values),
          lastName: getContactLastName(c.values)
        })),
        "name"
      )
    });
  };

  search = debounce(this.handleSearch, 500);

  reset = () => this.setState(defaultState);

  render() {
    const { children } = this.props;

    if (typeof children === "function") {
      return children({
        clearSearch: this.reset,
        searchTerm: this.state.searchTerm,
        onSearch: this.search,
        isSearching: this.state.searching,
        results: this.state.results
      });
    }
  }
}

export default connect(mapStateToProps)(
  defaultProps({ limit: 25 })(SearchContactController)
);
