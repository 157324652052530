import * as AuthUrlActions from "redux/modules/integrations/eventbrite/authUrl/actions";
import * as EventsActions from "redux/modules/integrations/eventbrite/events/actions";
import * as TicketClassesActions from "redux/modules/integrations/eventbrite/ticketClasses/actions";
import * as DisconnectActions from "redux/modules/integrations/eventbrite/disconnect/actions";

export default {
  ...AuthUrlActions,
  ...EventsActions,
  ...TicketClassesActions,
  ...DisconnectActions
};
