import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";

import { eventDetails } from "redux/modules/event/selectors";
import { canUserEditItemType } from "redux/modules/permissions/user-permission-profile/selectors";
import { user } from "redux/modules/user/selectors";
import { orderSummaryByRecord } from "redux/modules/orders/orderSummaryByRecord/selectors";
import { ordersByRecord } from "redux/modules/orders/ordersByRecord/selectors";

import { preparedLineItems } from "redux/modules/orders/bulk-edit-line-items/selectors";
import {
  prepareLineItems,
  updateLineItems
} from "redux/modules/orders/bulk-edit-line-items/actions";
import {
  getOrderSummaryForAccount,
  getOrderSummaryForContact
} from "redux/modules/orders/orderSummaryByRecord/actions";
import {
  getOrdersForAccount,
  getOrdersForContact
} from "redux/modules/orders/ordersByRecord/actions";

function mapStateToProps(state, props) {
  const recordId = props.where.orderContactId || props.where.orderAccountId;
  return {
    contactId: props.where.orderContactId,
    accountId: props.where.orderAccountId,
    showOverview: props.showOverview !== false && Boolean(recordId),
    eventDetails: eventDetails(state),
    user: user(state),
    lineItems: preparedLineItems(state),
    canUserEditItemType: canUserEditItemType(state),
    summary: orderSummaryByRecord(state, recordId),
    orders: ordersByRecord(state, recordId)
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      prepareLineItems,
      updateLineItems,
      getOrders: props.where.orderContactId
        ? getOrdersForContact
        : props.where.orderAccountId
        ? getOrdersForAccount
        : undefined,
      getOrderSummary: props.where.orderContactId
        ? getOrderSummaryForContact
        : props.where.orderAccountId
        ? getOrderSummaryForAccount
        : undefined
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controller);
