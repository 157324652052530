import React, { Component } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import * as R from "ramda";

import * as STANDARD_MODULE_FIELDS from "@lennd/value-types/src/constants/standard-module-field-ids";

import { getIsEditing, getIsEditable, getIsReadOnly } from "../../selectors";
import { actions } from "../../model";
import { TREE_NODE_COLUMN_ID } from "../../constants";

import FieldEditor from "../Editor";
import { FieldFormatter } from "ui-kit/FieldTypes/View";

import TaskStatus from "./TaskStatus";
import TreeNode from "./TreeNode";

const getCell = ({ row, column }) => row[column.id];

const resolveFormatter = {
  [STANDARD_MODULE_FIELDS.SCHEDULES.COMPLETE]: TaskStatus,
  [TREE_NODE_COLUMN_ID]: TreeNode
};

const decorate = connect(
  (state, props) => ({
    isEditing: getIsEditing(state, props),
    isEditable: getIsEditable(state, props),
    isReadOnly: getIsReadOnly(state, props)
  }),
  bindInstance({
    edit: actions.edit,
    saveRequest: actions.saveRequest
  })
);

class Cell extends Component {
  onClick = event => {
    event.stopPropagation();
    this.props.edit({
      rowId: this.props.row.id,
      columnId: this.props.column.id
    });
    this.anchorEl = event.target;
  };

  onCancel = event => {
    event.stopPropagation();
    this.props.saveRequest({ cancel: true });
    this.anchorEl = null;
  };

  onDone = event => {
    event.stopPropagation();
    this.props.saveRequest();
    this.anchorEl = null;
  };

  onClear = event => {
    event.stopPropagation();
    this.props.saveRequest({ clear: true });
    this.anchorEl = null;
  };

  shouldComponentUpdate(next) {
    const cellDiff = R.pathOr(getCell, ["column", "cellDiff"], this.props);
    if (cellDiff(next) !== cellDiff(this.props)) {
      return true;
    }
    if (next.isEditable && next.isEditing !== this.props.isEditing) {
      return true;
    }
    return false;
  }

  render() {
    let {
      column,
      row,
      isEditing,
      isEditable,
      isReadOnly,
      instanceId
    } = this.props;

    const Formatter =
      R.prop("Cell", column) ||
      R.prop(column.id, resolveFormatter) ||
      FieldFormatter;

    if (isEditable && !isReadOnly) {
      return (
        <td
          className={`td editable td-${column.id} ${
            isEditing ? "editing" : ""
          }`}
          onClick={this.onClick}
        >
          <div className="cell">
            {<Formatter data={row} field={column} instanceId={instanceId} />}
            {/* LP-4455 NORMALIZE Formaters and editors to use field and data*/}
            {isEditing ? (
              <FieldEditor
                row={row}
                column={column}
                anchorEl={this.anchorEl}
                onClear={this.onClear}
                onCancel={this.onCancel}
                onDone={this.onDone}
                instanceId={instanceId}
              />
            ) : null}
          </div>
        </td>
      );
    }

    return (
      <td className={`td td-${column.id}`}>
        <div className="cell">
          <Formatter data={row} field={column} instanceId={instanceId} />
        </div>
      </td>
    );
  }
}

export default decorate(Cell);
