import AppDispatcher from "dispatcher/AppDispatcher";
import Constants from "constants/EventConstants";
import WebAPIUtils from "utils/Event/WebAPIUtils";

const ActionTypes = Constants.ActionTypes;

module.exports = {
  updateEvent: (credentials, eventId, data, cb) => {
    WebAPIUtils.updateEvent(credentials, eventId, data, () => {
      if (typeof cb === typeof Function) {
        cb();
      }
    });
  },

  deleteEvent: (eventId, cb) => {
    WebAPIUtils.deleteEvent(eventId, () => {
      cb();
    });
  },

  /**
   * Groups
   */
  receiveGroups: (credentials, eventId, cb) => {
    WebAPIUtils.receiveGroups(credentials, eventId, groups => {
      AppDispatcher.handleViewAction({
        type: ActionTypes.RECEIVE_EVENT_GROUPS,
        groups
      });

      if (typeof cb === typeof Function) {
        cb(groups);
      }
    });
  },

  createGroup: (credentials, data, cb) => {
    WebAPIUtils.createGroup(credentials, data, group => {
      AppDispatcher.handleViewAction({
        type: ActionTypes.CREATE_EVENT_GROUP,
        group
      });

      if (typeof cb === typeof Function) {
        cb(group);
      }
    });
  },

  updateGroup: (credentials, data, cb) => {
    WebAPIUtils.updateGroup(credentials, data, group => {
      AppDispatcher.handleViewAction({
        type: ActionTypes.UPDATE_EVENT_GROUP,
        group
      });

      if (typeof cb === typeof Function) {
        cb(group);
      }
    });
  },

  updateGroupMembers: (credentials, data, cb) => {
    WebAPIUtils.updateGroupMembers(credentials, data, () => {
      if (typeof cb === typeof Function) {
        cb();
      }
    });
  },

  deleteGroup: (credentials, data, cb) => {
    WebAPIUtils.deleteGroup(credentials, data, group => {
      AppDispatcher.handleViewAction({
        type: ActionTypes.DELETE_EVENT_GROUP,
        group
      });

      if (typeof cb === typeof Function) {
        cb();
      }
    });
  },

  /**
   * Invite user to event
   */

  inviteNewUserToOrg: (credentials, data, cb) => {
    WebAPIUtils.inviteNewUserToOrg(credentials, data, user => {
      if (typeof cb === typeof Function) {
        cb(user);
      }
    });
  },

  inviteNewUserToEvent: (credentials, data, cb) => {
    WebAPIUtils.inviteNewUserToEvent(credentials, data, user => {
      if (typeof cb === typeof Function) {
        cb(user);
      }
    });
  },

  inviteExistingUserToEvent: (credentials, data, cb) => {
    WebAPIUtils.inviteExistingUserToEvent(credentials, data, () => {
      if (typeof cb === typeof Function) {
        cb();
      }
    });
  },

  removeUserFromEvent: (credentials, eventId, userId, cb) => {
    WebAPIUtils.removeUserFromEvent(credentials, eventId, userId, () => {
      if (typeof cb === typeof Function) {
        cb();
      }
    });
  },

  updateUserFromEvent: (credentials, eventId, userId, data, cb) => {
    WebAPIUtils.updateUserFromEvent(credentials, eventId, userId, data, () => {
      if (typeof cb === typeof Function) {
        cb();
      }
    });
  },

  resendInvitation: (credentials, eventId, userId, cb) => {
    WebAPIUtils.resendInvitation(credentials, eventId, userId, () => {
      if (typeof cb === typeof Function) {
        cb();
      }
    });
  }
};
