import React from "react";
import { connect } from "react-redux";
import { addS } from "utils/General";

import { Div, BigFilledButton, SendIcon, Text3 } from "components/Base";

import SearchBar from "ui-kit/SearchBar/View";

import { getSelectedCount } from "Passes/Confirmations/selectors";
import { getters as DoubleSelectGetters } from "ui-kit/DoubleSelect";

import { VARIANTS } from "ui-kit/Theme/constants";

const decorate = connect(state => ({
  selectedCount: getSelectedCount(state),
  option: DoubleSelectGetters.selectedOption(state)
}));

const TopBar = ({ selectedCount, handlers, option }) => (
  <Div
    bg="white"
    display="row.space-between.center"
    style={{ flexShrink: 0, position: "relative" }}
    bb={1}
    bc="neutral3"
    height={80}
    width={1}
    px={4}
    pr={6}
  >
    <Div display="row.flex-start.center" flex={1} mr={4}>
      {selectedCount ? (
        <Text3 bold>
          {selectedCount} Selected Order
          {addS(selectedCount)}
        </Text3>
      ) : (
        <SearchBar
          variant={VARIANTS.SURFACE}
          flex={1}
          placeholder="Search by a group or person's name"
          style={{ maxWidth: 500 }}
        />
      )}
    </Div>
    {selectedCount ? (
      <BigFilledButton
        bg="altB5"
        LeftIcon={SendIcon}
        onClick={() => handlers.sendEmail()}
      >
        Send Email
        {addS(selectedCount)}
      </BigFilledButton>
    ) : null}
    {option === "unpaid" && selectedCount ? (
      <BigFilledButton
        bg="altB5"
        LeftIcon={SendIcon}
        onClick={() => handlers.sendInvoice()}
        ml={3}
      >
        Send Invoice
        {addS(selectedCount)}
      </BigFilledButton>
    ) : null}
  </Div>
);
export default decorate(TopBar);
