import { createModule, createHandlers } from "redux-mvc";

import peopleBlockTable from "Submission/PeopleBlockTable";
import subform from "Submission/Subform";

const handlers = createHandlers({
  iniState: {},
  namespace: "SubmissionDataGrid"
});

const submission = createModule(handlers);

submission.plugModule(peopleBlockTable);
submission.plugModule(subform);

export default submission;
