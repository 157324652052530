import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { createSelector } from "reselect";
import { SUMMARIES, MODES, MANAGE_BY } from "Items/Manage/constants";

import { getters } from "Items/Manage";
import { getShowSummary, getGroupBy } from "Items/Manage/selectors";
import * as FilterControlsSelectors from "ui-kit/FilterControls/selectors";

import SecondarySidebar from "ui-kit/SecondarySidebar";

import { Div } from "components/Base";

import { SortByFilter } from "ui-kit/FilterControls/View";

import {
  CREDENTIAL_TYPE_ID,
  MEAL_TYPE_ID,
  INVENTORY_TYPE_ID,
  BOOTH_TYPE_ID
} from "utils/item-types";

import { SHOW_MEALS_FILTER_ID } from "Passes/Common/constants";

import Meals from "./MealsFilter";
import Passes from "./PassesFilter";
import Inventory from "./InventoryFilter";
import Booths from "./BoothsFilter";
import Sponsorships from "./SponsorshipsFilter";
import SourcesAndSubmitters from "./SourcesAndSubmittersFilter";
import LookupPassId from "./LookupPassIdFilter";
import ShowHideFields from "./ShowHideFields";
import Fields from "./FieldFilter";
import GroupBy from "./GroupByFilter";
import ClearFilters from "./ClearFilters";
import SelectView from "./SelectView";
import ViewPicker from "./ViewPicker";
import Attendee from "./AttendeeFilter";
import Group from "./GroupFilter";
import ShowMeals from "./ShowMealsFilter";

import BackToAllRequests from "./BackToAllRequests";
import Summary from "./Summary";
import BackToAllGroups from "./BackToAllGroups";

// This sidebar only works with the default instance

const filters = {
  viewPicker: ({ instanceId }) => <ViewPicker mt={2} instanceId={instanceId} />,
  selectView: <SelectView mt={2} />,
  groupBy: <GroupBy mt={2} />,
  sortBy: <SortByFilter collapsedDefault mt={2} />,
  clearFilters: <ClearFilters mt={2} />,
  fields: <Fields mt={2} />,
  meals: <Meals mt={2} />,
  passes: ({ categoryId }) => <Passes categoryId={categoryId} mt={2} />,
  inventory: ({ categoryId }) => <Inventory categoryId={categoryId} mt={2} />,
  booths: <Booths mt={2} />,
  sponsorships: <Sponsorships mt={2} />,
  sourcesAndSubmitters: <SourcesAndSubmitters mt={2} />,
  attendee: <Attendee mt={2} />,
  group: <Group mt={2} />,
  showHideFields: ({ onShowHideFields, preferences, mode }) => (
    <ShowHideFields
      onClick={() => onShowHideFields(preferences, mode)}
      mt={2}
    />
  ),
  lookup: ({ onPassLookup }) => <LookupPassId onClick={onPassLookup} mt={2} />,
  showMeals: <ShowMeals mt={2} />,
  accountSummary: (
    <>
      <BackToAllRequests mt={2} />
      <Summary mt={2} />
    </>
  ),
  backToAllGroups: <BackToAllGroups mt={2} />
};

// @NOTE: I'll keep the selector here because it's very coupled to the filters map
const getLayout = createSelector(
  getters.itemTypeView,
  (state, props) => getGroupBy(state, props) !== MANAGE_BY.INTEGRATION,
  (state, props) =>
    FilterControlsSelectors.getOptions(state, {
      ...props,
      instanceId: SHOW_MEALS_FILTER_ID
    }),
  getShowSummary,
  getGroupBy,
  getters.summary,
  getters.mode,
  (_, props) =>
    R.path(["location", "query", "groupTypeId"], props) ||
    R.path(["location", "query", "personTypeId"], props),
  (
    itemTypeView,
    showGroupBy,
    showMeals,
    showSummary,
    groupBy,
    summary,
    mode,
    showGroup
  ) => {
    return R.propOr([], itemTypeView, {
      [CREDENTIAL_TYPE_ID]: [
        [
          showSummary && "accountSummary",
          showGroup && "backToAllGroups",
          "selectView",
          groupBy === MANAGE_BY.ALL_PASSES && "viewPicker",
          "lookup",
          groupBy === MANAGE_BY.ALL_PASSES && "showHideFields",
          showGroupBy &&
          mode !== MODES.PRINT &&
          summary.type !== SUMMARIES.ACCOUNT
            ? "groupBy"
            : null,
          "clearFilters",
          "attendee",
          "group",
          summary.type !== SUMMARIES.ITEM_TYPE ? "passes" : null,
          showMeals && summary.type !== SUMMARIES.ITEM_TYPE ? "meals" : null,
          "sourcesAndSubmitters",
          "fields",
          "showMeals"
        ]
      ].filter(o => o),
      [MEAL_TYPE_ID]: [
        [
          showSummary && "accountSummary",
          showGroup && "backToAllGroups",
          "selectView",
          groupBy === MANAGE_BY.ALL_PASSES && "viewPicker",
          groupBy === MANAGE_BY.ALL_PASSES && "showHideFields",
          !showSummary && "groupBy",
          "clearFilters",
          "attendee",
          "group",
          "meals",
          "sourcesAndSubmitters"
          // "fields"
        ]
      ],
      [INVENTORY_TYPE_ID]: [
        [
          showSummary && "accountSummary",
          showGroup && "backToAllGroups",
          "selectView",
          groupBy === MANAGE_BY.ALL_PASSES && "viewPicker",
          groupBy === MANAGE_BY.ALL_PASSES && "showHideFields",
          summary.type !== SUMMARIES.ACCOUNT ? "groupBy" : null,
          "clearFilters",
          "attendee",
          "group",
          summary.type !== SUMMARIES.ITEM_TYPE ? "inventory" : null,
          "sourcesAndSubmitters"
          // "fields"
        ],
        ["lookup"]
      ],
      [BOOTH_TYPE_ID]: [
        [
          showSummary && "accountSummary",
          showGroup && "backToAllGroups",
          "selectView",
          groupBy === MANAGE_BY.ALL_PASSES && "viewPicker",
          groupBy === MANAGE_BY.ALL_PASSES && "showHideFields",
          "groupBy",
          "clearFilters",
          "booths",
          "sponsorships",
          "sourcesAndSubmitters"
          // "fields"
        ]
      ]
    });
  }
);

const getCategoryId = createSelector(
  getters.summary,
  summary => (summary.type === SUMMARIES.CATEGORY ? summary.id : null)
);

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    preferences: getters.preferences(state, props),
    mode: getters.mode(state, props),
    layout: getLayout(state, props),
    categoryId: getCategoryId(state, props)
  }))
);

const Sidebar = ({
  preferences,
  layout,
  categoryId,
  mode,
  instanceId,
  handlers: { onPassLookup, onShowHideFields }
}) => (
  <SecondarySidebar display="column.flex-start.stretch">
    {layout.map((section, idx) => (
      <Div
        px={3}
        pb={2}
        display="column.flex-start.stretch"
        key={idx}
        bt={idx > 0 ? 1 : 0}
        bc="neutral1"
      >
        {R.map(
          filter =>
            typeof filters[filter] === "function"
              ? React.createElement(filters[filter], {
                  preferences,
                  onPassLookup,
                  onShowHideFields,
                  categoryId,
                  mode,
                  instanceId
                })
              : filters[filter],
          section
        )}
      </Div>
    ))}
  </SecondarySidebar>
);

export default decorate(Sidebar);
