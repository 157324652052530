import React from "react";
import { put, all, takeEvery, fork, select, call } from "redux-saga/effects";
import { apiCall } from "App/Data/sagas";
import { makeFuture } from "utils/General/sagas";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import moment from "moment";

import { eventId as getEventId } from "redux/modules/event/selectors";
import { eventId as getPortalEventId } from "redux/modules/portal/selectors";

import { actions, getters } from "./model";
import { registerError } from "redux/modules/errors/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal } from "redux/modules/modal/actions";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import AssignDocumentRequestsModal from "components/Global/CRM/Modals/Assign/AssignDocumentRequests";
import DocumentRequestModal from "Portal/PortalDocuments/RequestModal";

const getParams = function*() {
  const eventId = yield select(getEventId);
  const portalEventId = yield select(getPortalEventId);

  return {
    eventId: eventId || portalEventId
  };
};

const fetchData = function*() {
  try {
    const { eventId } = yield call(getParams);
    const recordId = yield select(getters.recordId);

    const { payload } = yield call(apiCall, {
      method: "get",
      url: `/file-requests/event/${eventId}/recipient/${recordId}/submissions`
    });

    yield put(actions.setRequests(payload));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting file requests"
        }
      ])
    );
  }
};

const init = function*({ payload: { mode, moduleId, recordId } }) {
  if (mode) {
    yield put(actions.setMode(mode));
  }
  yield put(actions.setModuleId(moduleId));
  yield put(actions.setRecordId(recordId));
  yield call(fetchData);
  yield put(actions.setLoading(false));
};

const showAssignDocumentsModal = function*({ moduleId, recordId }) {
  const handleAssign = makeFuture();
  yield put(
    showModal({
      content: (
        <AssignDocumentRequestsModal
          moduleId={moduleId}
          recordNameSingular={
            moduleId === STANDARD_MODULE_IDS.accounts.id ? "Group" : "Person"
          }
          recordNamePlural={
            moduleId === STANDARD_MODULE_IDS.accounts.id ? "Groups" : "People"
          }
          recordIds={[recordId]}
          onDone={handleAssign.done}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleAssign.onRealized);
};

const onAssignFileRequests = function*() {
  const moduleId = yield select(getters.moduleId);
  const recordId = yield select(getters.recordId);

  yield call(showAssignDocumentsModal, {
    moduleId,
    recordId
  });

  yield call(fetchData);
};

const showViewDocumentRequestModal = function*({
  requestTypeId,
  moduleId,
  recordId
}) {
  const mode = yield select(getters.mode);
  const { eventId } = yield call(getParams);
  const handleAssign = makeFuture();

  yield put(
    showModal({
      content: (
        <DocumentRequestModal
          view={mode}
          eventId={eventId}
          requestTypeId={requestTypeId}
          accountId={
            moduleId === STANDARD_MODULE_IDS.accounts.id ? recordId : null
          }
          contactId={
            moduleId === STANDARD_MODULE_IDS.contacts.id ? recordId : null
          }
          onDone={handleAssign.done}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleAssign.onRealized);
};

const onViewRequest = function*({ payload: { requestTypeId } }) {
  const moduleId = yield select(getters.moduleId);
  const recordId = yield select(getters.recordId);

  yield call(showViewDocumentRequestModal, {
    moduleId,
    recordId,
    requestTypeId
  });

  yield call(fetchData);
};

const updateDueDate = function*() {
  const { eventId } = yield call(getParams);
  const requestTypeId = yield select(getters.showAssigmentModal);
  const recordId = yield select(getters.recordId);
  const moduleId = yield select(getters.moduleId);
  const customCloseDate = yield select(getters.customCloseDate);
  const formattedCloseDate = customCloseDate
    ? moment(customCloseDate).format()
    : customCloseDate;

  yield call(apiCall, {
    method: "put",
    url:
      moduleId === STANDARD_MODULE_IDS.accounts.id
        ? `/file-requests/event/${eventId}/request/${requestTypeId}/submission/account/${recordId}/close-date`
        : `/file-requests/event/${eventId}/request/${requestTypeId}/submission/contact/${recordId}/close-date`,
    data: {
      closeDate: formattedCloseDate
    }
  });

  yield put(actions.setShowAssigmentModal(false));

  yield put(
    showSnackbar({
      message: "Due Date Updated"
    })
  );

  yield call(fetchData);
};

const onViewDueDate = function*({ payload: { requestTypeId } }) {
  yield put(actions.setShowAssigmentModal(requestTypeId));
};

const watchAssignFileRequests = function*() {
  yield takeEvery(actions.onAssignFileRequests.type, onAssignFileRequests);
};

const watchViewRequest = function*() {
  yield takeEvery(actions.onViewRequest.type, onViewRequest);
};

const watchViewDueDate = function*() {
  yield takeEvery(actions.onViewDueDate.type, onViewDueDate);
};

const watchUpdateDueDate = function*() {
  yield takeEvery(actions.updateDueDate.type, updateDueDate);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchAssignFileRequests),
    fork(watchViewRequest),
    fork(watchViewDueDate),
    fork(watchUpdateDueDate)
  ]);
};

export default rootSaga;
