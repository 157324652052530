import React from "react";
import * as R from "ramda";
import CollapsablePanel from "ui-kit/CollapsiblePanel";
import { Div, MediumOutlineButton } from "components/Base";
import NotConfigured from "./NotConfigured";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { LoadingIcon } from "components/Base";
import Table from "./Table";
import { noop } from "utils/General";

const decorate = CSSModules(css);

export const Card = decorate(
  ({
    goToLearn,
    assignedRows = [],
    goToManage = noop,
    openModal,
    loading = false,
    deleteRow = noop,
    openUpdateModal = noop,
    columnTitle = "",
    btnText = "",
    description = "",
    boldDescription = false,
    onReorder = noop,
    wrapperCardStyle,
    emptyText = "You haven't uploaded any files to this portal yet.",
    columns,
    shouldSort
  }) => (
    <div styleName="collapsable" style={wrapperCardStyle}>
      <div
        styleName="description"
        style={{
          fontWeight: boldDescription ? 600 : "normal"
        }}
      >
        {description}
      </div>
      <div styleName="row-btn">
        <MediumOutlineButton
          color="primary7"
          bc="primary7"
          LeftIcon={() => (
            <span
              class="material-icons"
              style={{ fontSize: 16, marginRight: 8, height: 16 }}
            >
              format_list_bulleted
            </span>
          )}
          width={200}
          onClick={openModal}
        >
          {btnText === "Task" ? `Add ${btnText}` : `Assign ${btnText}`}
        </MediumOutlineButton>
        {goToManage !== noop ? (
          <MediumOutlineButton
            color="primary7"
            bc="primary7"
            LeftIcon={() => (
              <span
                class="material-icons"
                style={{ fontSize: 16, marginRight: 8, height: 16 }}
              >
                settings
              </span>
            )}
            ml={2}
            width={200}
            onClick={goToManage}
          >
            Manage {btnText}
          </MediumOutlineButton>
        ) : null}
        <MediumOutlineButton
          color="primary7"
          bc="primary7"
          LeftIcon={() => (
            <span
              class="material-icons"
              style={{ fontSize: 16, marginRight: 8, height: 16 }}
            >
              import_contacts
            </span>
          )}
          ml={2}
          width={200}
          onClick={goToLearn}
        >
          Learn more
        </MediumOutlineButton>
      </div>

      {loading ? (
        <div styleName="center">
          <LoadingIcon color="gray3" size={50} />
        </div>
      ) : (
        <Table
          rows={assignedRows}
          firstColumn={columnTitle}
          openUpdateModal={openUpdateModal}
          deleteRow={deleteRow}
          onReorder={onReorder}
          emptyText={emptyText}
          columns={columns}
          shouldSort={shouldSort}
        />
      )}
    </div>
  )
);

const CollapsableCards = ({
  goToLearn,
  assignedRows = [],
  goToManage = noop,
  openModal,
  loading = false,
  deleteRow = noop,
  openUpdateModal = noop,
  columnTitle = "",
  title = "",
  btnText = "",
  description = "",
  assignedRowsText = "",
  onReorder = noop,
  wrapperCardStyle = {},
  emptyText,
  columns = [],
  shouldSort = true
}) => (
  <CollapsablePanel
    heading={() => (
      <Div display="row.space-between.center" my={2} width={1} pr={4}>
        <div className={css.title}>{title}</div>
        <NotConfigured
          configured={!R.isEmpty(assignedRows)}
          loading={loading}
          text={`${R.length(assignedRows)} ${assignedRowsText}${
            R.length(assignedRows) !== 1 ? "s" : ""
          } Assigned`}
        />
      </Div>
    )}
    bg="white"
    arrowColor="gray7"
    bc="neutral3"
    right={false}
    bra={1}
    mb={3}
    p={3}
    collapsedDefault={true}
  >
    <Card
      {...{
        goToLearn,
        assignedRows,
        goToManage,
        openModal,
        loading,
        deleteRow,
        openUpdateModal,
        columnTitle,
        btnText,
        description,
        onReorder,
        wrapperCardStyle,
        emptyText,
        columns,
        shouldSort
      }}
    />
  </CollapsablePanel>
);

export default CollapsableCards;
