import React from "react";
import {
  Div,
  Text2,
  Text3,
  Text5,
  BigShadedButton,
  DownloadIcon
} from "components/Base";

import { noop } from "utils/General";

const ImportBox = ({ children, onClick }) => (
  <Div
    bg={{
      default: "neutral0",
      hover: "neutral1"
    }}
    height={346}
    display="row.center.center"
    bra={2}
    p={6}
    onClick={onClick}
    flex={1}
    style={{ flexBasis: 0 }}
  >
    {children}
  </Div>
);

const ImportFile = ({
  showImport = true,
  showCopy = false,
  showDownload = false,
  importText = "Upload a file to import",
  importCaption = "Choose a file...",
  copyText = "Copy & Paste",
  copyCaption = "Copy & paste just the CSV columns & rows that you need!",
  downloadButtonText = "Download Sample CSV",
  goToCopyPaste = noop,
  uploadFile = noop,
  downloadTemplate = noop
}) => (
  <Div p={7} display="column">
    <Div display="row.space-between.center" flex={1}>
      {(showImport && (
        <ImportBox onClick={uploadFile}>
          <Div display="column.space-between.center" height={165}>
            <img src="https://d2dks4tzxs6xee.cloudfront.net/img/upload-icon.svg" />
            <Text5 bold>{importText}</Text5>
            <Text3 bold textAlign="center" mt={2} maxWidth={320}>
              {importCaption}
            </Text3>
          </Div>
        </ImportBox>
      )) ||
        null}

      {(showCopy && (
        <React.Fragment>
          <Div width={20} />
          <ImportBox onClick={goToCopyPaste}>
            <Div height={200} display="column.center.center">
              <img src="https://d2dks4tzxs6xee.cloudfront.net/img/upload-copy-paste-icon.svg" />
              <Text5 bold>{copyText}</Text5>
              <Text3 bold textAlign="center" mt={2} maxWidth={320}>
                {copyCaption}
              </Text3>
            </Div>
          </ImportBox>
        </React.Fragment>
      )) ||
        null}
    </Div>
    {(showDownload && (
      <Div
        bg="neutral0"
        display="row.space-between.center"
        height={71}
        flex={1}
        bra={2}
        mt={6}
        pl={8}
        pr={3}
      >
        <Div>
          <Text3 bold>Keep it clean, use our .csv template</Text3>
          <Text2>This is safest way to import new records</Text2>
        </Div>
        <BigShadedButton
          color="purple8"
          bg="neutral3"
          RightIcon={() => <DownloadIcon ml={3} />}
          onClick={downloadTemplate}
        >
          {downloadButtonText}
        </BigShadedButton>
      </Div>
    )) ||
      null}
  </Div>
);

export default ImportFile;
