import PropTypes from "prop-types";
import React, { Component } from "react";
import { get } from "lodash";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import AddFileModal from "EventLight/FileRequests/AddFileModal/View";
import ShareModal from "components/Global/CRM/Modals/Share";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import CanUserDo from "components/Global/Security/CanUserDo";

@CSSModules(css)
class DocumentRequestsActionsMenu extends Component {
  fetchDocumentRequestTypes = () => {
    this.props.getRecords({
      moduleId: STANDARD_MODULE_IDS.documentRequestTypes.id,
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId
      }
    });
  };

  getSelectedDocumentRequestTypeId = () =>
    get(this.props.location, "query.request_type_id");

  showEditRequestTypeModal = () =>
    this.props.showModal({
      content: (
        <AddFileModal
          requestId={this.getSelectedDocumentRequestTypeId()}
          onSave={() => {
            this.fetchDocumentRequestTypes();
          }}
        />
      ),
      wrapper: ModalWrapper
    });

  showShareRequestTypeModal = () => {
    const record = this.props.documentRequestTypes.find(
      t => t.id === this.getSelectedDocumentRequestTypeId()
    );

    this.props.showModal({
      content: (
        <ShareModal
          moduleId={STANDARD_MODULE_IDS.documentRequestTypes.id}
          recordId={record.id}
          onDone={this.fetchDocumentRequestTypes}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  isOwnerOfRequestType = requestTypeId => {
    const requestType = this.props.documentRequestTypes.find(
      t => t.id === requestTypeId
    );
    return (
      requestType &&
      Boolean(requestType.owners.find(o => o.id === this.props.user.id))
    );
  };

  wrapAction(action) {
    const { close } = this.props;
    return () => {
      action();
      close();
    };
  }

  render() {
    const { exportXLSX, exportCSV } = this.props;
    const selectedDocumentRequestTypeId = this.getSelectedDocumentRequestTypeId();

    return (
      <Menu>
        {this.isOwnerOfRequestType(selectedDocumentRequestTypeId) ? (
          <MenuItem
            leftIcon={<i className="material-icons">edit</i>}
            primaryText="Edit Request"
            onClick={this.wrapAction(this.showEditRequestTypeModal)}
          />
        ) : (
          ""
        )}
        {this.isOwnerOfRequestType(selectedDocumentRequestTypeId) ? (
          <MenuItem
            leftIcon={<i className="material-icons">share</i>}
            primaryText="Share Request"
            onClick={this.wrapAction(this.showShareRequestTypeModal)}
          />
        ) : (
          ""
        )}
        {exportXLSX ? (
          <CanUserDo
            action={`${STANDARD_MODULE_IDS.documentRequests.id}_export`}
          >
            <MenuItem
              leftIcon={<i className="material-icons">file_download</i>}
              primaryText="Export as XLSX"
              onClick={this.wrapAction(exportXLSX)}
            />
          </CanUserDo>
        ) : (
          ""
        )}
        {exportCSV ? (
          <CanUserDo
            action={`${STANDARD_MODULE_IDS.documentRequests.id}_export`}
          >
            <MenuItem
              leftIcon={<i className="material-icons">file_download</i>}
              primaryText="Export as CSV"
              onClick={this.wrapAction(exportCSV)}
            />
          </CanUserDo>
        ) : (
          ""
        )}
      </Menu>
    );
  }
}

DocumentRequestsActionsMenu.propTypes = {
  close: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  exportCSV: PropTypes.func,
  exportXLSX: PropTypes.func
};

export default DocumentRequestsActionsMenu;
