import PropTypes from "prop-types";
import React from "react";
import Badge from "components/Atoms/Badge";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const InReview = ({ percent }) => (
  <Badge styleName="badge">
    In Review
    {percent ? <span styleName="percent">{percent}</span> : ""}
  </Badge>
);

InReview.propTypes = {
  percent: PropTypes.string
};

export default CSSModules(InReview, css);
