import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Account from "./Account";

import * as ModalActions from "redux/modules/modal/actions";
import * as SnackbarActions from "redux/modules/snackbar/actions";

import * as UserSelectors from "redux/modules/user/selectors";

function mapStateToProps(state) {
  return {
    user: UserSelectors.user(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        ...SnackbarActions,
        ...ModalActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
