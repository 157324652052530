/* eslint-disable react/prop-types */

import React, { Component } from "react";
import { AdminView, PortalView } from "./View";
import * as R from "ramda";
import {
  FIELDS_FOR_CREDENTIALS_AND_CATERING,
  SUMMARY_BY_CATERING_DEFAULT_TITLE
} from "../constants";

class CateringReportController extends Component {
  render() {
    const { requests, view } = this.props;
    let View = PortalView;
    if (view === "admin") {
      View = AdminView;
    }

    return (
      <View
        {...{
          title: R.isEmpty(requests)
            ? SUMMARY_BY_CATERING_DEFAULT_TITLE
            : R.propOr(
                SUMMARY_BY_CATERING_DEFAULT_TITLE,
                "accountName",
                R.head(requests.assignments)
              ),
          showTitle: true,
          summaries: R.map(key => ({
            title: key,
            values: R.sortBy(R.prop("item_sort_order"))(requests.counts[key])
          }))(Object.keys(requests.counts)),
          fields: FIELDS_FOR_CREDENTIALS_AND_CATERING,
          values: requests.assignments
        }}
      />
    );
  }
}

export default CateringReportController;
