import * as R from "ramda";
import { createContext } from "redux-mvc";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import dashboard from "OrgLight/Common/Dashboard";
import DashboardView, {
  contextConfig,
  getDashboardProps
} from "OrgLight/Common/Dashboard/View";

const decorate = R.compose(
  getDashboardProps({
    moduleId: STANDARD_MODULE_IDS.accounts.id,
    showImportButton: false,
    showDataFeedButton: false
  }),
  createContext({ module: dashboard, ...contextConfig })
);

export default decorate(DashboardView);
