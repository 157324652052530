import Helpers from "utils/Global/Helpers";

module.exports = {
  post: data =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/activity`,
        data,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */

      Helpers.request(options);
    })
};
