import React from "react";
import * as R from "ramda";
import { Div } from "components/Base";
import Tooltip from "@lennd/material-ui/Tooltip";
import EventTooltip from "./EventTooltip";
import EventLines from "./EventLines";
import { noop } from "utils/General";

const RedDot = () => (
  <Div height={4} width={4} bg="red" style={{ borderRadius: "100%" }} />
);

const Day = ({
  day,
  showCurrentDay,
  events = [],
  currentDay = false,
  onClick = noop,
  onEventClick = noop,
  currentMonth
}) => {
  if (R.isEmpty(events)) {
    return (
      <Div
        display="row.flex-end.flex-start"
        onClick={() =>
          onClick({ day: currentMonth.clone().set("date", day), events })
        }
        height={1}
      >
        {currentDay && showCurrentDay ? (
          <Div style={{ marginRight: 2 }}>
            <RedDot />
          </Div>
        ) : null}
        <Div>{day}</Div>
      </Div>
    );
  }

  return (
    <Tooltip
      title={<EventTooltip events={events} onEventClick={onEventClick} />}
      leaveDelay={500}
      classes={{
        tooltipPlacementRight: "tooltip_custom",
        tooltipPlacementLeft: "tooltip_custom"
      }}
      placement="right"
    >
      <Div
        height={1}
        onClick={() =>
          onClick({ day: currentMonth.clone().set("date", day), events })
        }
      >
        <Div display="row.flex-end.center">
          <Div>
            {currentDay && showCurrentDay ? (
              <Div style={{ marginRight: 2 }}>
                <RedDot />
              </Div>
            ) : null}
          </Div>
          <Div>{day}</Div>
        </Div>
        {!R.isEmpty(events) && <EventLines events={events} />}
      </Div>
    </Tooltip>
  );
};

export default Day;
