import Helpers from "utils/Global/Helpers";

/* eslint no-underscore-dangle: "off" */

export default {
  createTemplateSection: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        credentials,
        data,
        error,
        method: "post",
        success,
        url: `${window.__LENND_API_BASE_URL__}/reports/template-sections`
      });
    }),
  deleteTemplate: ({ credentials, templateId }) =>
    new Promise((success, error) => {
      Helpers.request({
        credentials,
        data: {},
        error,
        method: "delete",
        success,
        url: `${window.__LENND_API_BASE_URL__}/reports/templates/${templateId}`
      });
    }),
  deleteTemplateSection: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        credentials,
        data,
        error,
        method: "delete",
        success,
        url: `${window.__LENND_API_BASE_URL__}/reports/template-sections/${data.sectionId}`
      });
    }),
  getRecordTypes: ({ credentials, moduleId, eventId }) =>
    new Promise((success, error) => {
      Helpers.request({
        credentials,
        error,
        method: "get",
        success,
        url: `${window.__LENND_API_BASE_URL__}/modules/${moduleId}/record-types?eventId=${eventId}`
      });
    }),
  getTemplate: ({ credentials, templateId }) =>
    new Promise((success, error) => {
      Helpers.request({
        credentials,
        error,
        method: "get",
        success,
        url: `${window.__LENND_API_BASE_URL__}/reports/templates/${templateId}`
      });
    }),
  updateTemplate: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        credentials,
        data,
        error,
        method: "put",
        success,
        url: `${window.__LENND_API_BASE_URL__}/reports/templates`
      });
    }),
  updateTemplateSection: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        credentials,
        data,
        error,
        method: "put",
        success,
        url: `${window.__LENND_API_BASE_URL__}/reports/template-sections`
      });
    })
};
