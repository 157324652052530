import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import { noop } from "utils/General";

import { actions } from "../index";

import CSSModules from "react-css-modules";
import css from "ui-kit/ViewPicker/View/styles.scss";
import { MediumFilledButton, FontIcon } from "components/Base";

import Tooltip from "components/Global/Tooltip";

const decorate = R.compose(
  connect(
    null,
    {
      showSubscribeModal: actions.showSubscribeModal
    }
  ),
  CSSModules(css)
);

const FeedButton = ({ showSubscribeModal = noop, ...styleProps }) => (
  <Tooltip tooltip="Subscribe to feed">
    <MediumFilledButton
      mr={2}
      onClick={showSubscribeModal}
      className={css.button2}
      {...styleProps}
    >
      <FontIcon fs={4} color="white">
        rss_feed
      </FontIcon>
    </MediumFilledButton>
  </Tooltip>
);

export default decorate(FeedButton);
