import React, { Component } from "react";
import * as R from "ramda";
import { cloneDeep, get, set } from "lodash";
import View from "./View";
import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";
import resolveEditor from "components/Global/StandAloneEditors/utils/resolveEditor";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import isValid from "utils/value-types/validations/is-valid";
import isValidEmail from "utils/value-types/validations/email/isEmail";
import autobind from "autobind-decorator";

import orderModel from "Orders/utils/order-model";

class Controller extends Component {
  constructor(props) {
    super(props);
    const { order } = this.props;

    const state = {
      order: cloneDeep(orderModel),
      errors: {},
      isValid: true
    };

    if (order) {
      state.order.customer.contact.id = order.customer.contact_id;
      state.order.customer.contact.values = order.customer.contact_values || {};
      state.order.customer.account.id = order.customer.account_id;
      state.order.customer.account.values = order.customer.account_values || {};
    }

    this.state = state;
  }

  componentDidMount() {
    if (typeof this.props.register === "function") {
      this.props.register(this.validate);
    }
  }

  componentWillUnmount() {
    if (typeof this.props.unregister === "function") {
      this.props.unregister(this.validate);
    }
  }

  @autobind
  async validate() {
    const isValid = await this.isValid();
    this.setState({
      isValid
    });
    return isValid;
  }

  @autobind
  async isValid() {
    const errors = {};
    const fields = this.props.field.customer_block_fields || [];
    const { order } = this.state;

    // iterate over required fields
    // any fields with invalid values, add errors for
    for (const field of fields) {
      if (field.is_required) {
        const value =
          field.module_id === STANDARD_MODULE_IDS.accounts.id
            ? R.path(["customer", "account", "values", field.field_id])(order)
            : R.path(["customer", "contact", "values", field.field_id])(order);

        const isValidValue = isValid(value, field.field.type);

        if (!isValidValue) {
          errors[field.id] = "This is a required question";
        }

        if (field.field.type === "email") {
          const validEmail = await isValidEmail(value);
          if (!validEmail) {
            errors[field.id] = "Please use a valid email address";
          }
        }
      }
    }

    this.setState({
      errors
    });

    return !Object.keys(errors).length;
  }

  getValue = data => {
    if (data) {
      const value = get(this.state.order, data.path);
      if (!value && value !== false) {
        return typeof data.defaultValue === "undefined"
          ? ""
          : data.defaultValue;
      }
      return value;
    }
    return this.state.order;
  };

  updateValue = async data => {
    // set value and update customer
    const order = { ...this.state.order };
    const { path, value } = data;
    set(order, path, value);
    this.setState({ order }, async () => {
      this.updateCustomer();

      // update valid status
      const isValid = await this.validate();
      this.setState({
        isValid
      });
    });
  };

  updateCustomer = () => {
    const { order } = this.state;

    const data = {
      contactRecordTypeId: this.props.field.settings.contactRecordTypeId,
      accountRecordTypeId: this.props.field.settings.accountRecordTypeId,
      markAsPrimary: this.props.field.settings.markAsPrimary,
      contactValues: order.customer.contact.values,
      accountValues: order.customer.account.values
    };

    if (this.props.order) {
      return this.props.updateCustomer({
        orderId: this.props.order.id,
        ...data
      });
    }
  };

  render() {
    const { field, eventDetails } = this.props;
    const { errors } = this.state;

    const getValue = (moduleId, fieldId) => {
      if (moduleId === STANDARD_MODULE_IDS.accounts.id) {
        return this.getValue({
          path: ["customer", "account", "values", fieldId]
        });
      } else if (moduleId === STANDARD_MODULE_IDS.contacts.id) {
        return this.getValue({
          path: ["customer", "contact", "values", fieldId]
        });
      }
    };

    const updateValue = (moduleId, fieldId, value) => {
      if (moduleId === STANDARD_MODULE_IDS.accounts.id) {
        return this.updateValue({
          path: ["customer", "account", "values", fieldId],
          value
        });
      } else if (moduleId === STANDARD_MODULE_IDS.contacts.id) {
        return this.updateValue({
          path: ["customer", "contact", "values", fieldId],
          value
        });
      }
    };

    const fieldsWithHandlers = R.compose(
      R.reduce(
        (fields, field) => {
          if (field.moduleId === STANDARD_MODULE_IDS.accounts.id) {
            fields.accountFields.push(field);
          } else if (field.moduleId === STANDARD_MODULE_IDS.contacts.id) {
            fields.contactFields.push(field);
          }

          return fields;
        },
        {
          contactFields: [],
          accountFields: []
        }
      ),
      R.map(f => {
        return {
          ...f.field,
          isEditing: this.props.isEditing,
          isValid: !Boolean(errors[f.id]),
          disabled: this.props.disabled,
          errors: errors[f.id] ? [errors[f.id]] : null,
          moduleId: f.module_id,
          name: f.alias || f.field.name,
          value: getValue(f.module_id, f.field_id),
          required: f.is_required,
          Editor: resolveEditor(f.field),
          editorProps: resolveEditorProps(f.field, eventDetails),
          onValueChange: value => updateValue(f.module_id, f.field_id, value)
        };
      })
    )(field.customer_block_fields ? field.customer_block_fields : []);

    return (
      <View
        {...{
          name: field.name,
          description: field.settings.description,
          contactTitle: field.settings.contactTitle || "Contact Details",
          accountTitle: field.settings.accountTitle || "Company/Group Details",
          fields: fieldsWithHandlers
        }}
      />
    );
  }
}

Controller.propTypes = {};

export default Controller;
