import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import External from "./External";

import { user } from "redux/modules/user/selectors";

import { getItemTypesByEvent } from "redux/modules/items/types/actions";

import { dashboardsByType } from "redux/modules/dashboards/selectors";

import * as WidgetActions from "redux/modules/dashboards/widgets/actions";
import * as DashboardActions from "redux/modules/dashboards/actions";
import * as ModalActions from "redux/modules/modal/actions";
import { getItemGroupsByEventAndType } from "redux/modules/items/item-groups/actions";
import { groups } from "redux/modules/items/item-groups/selectors";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";

function mapStateToProps(state) {
  const credentialItems = groups(state)
    .filter(g => g.type_id === CREDENTIAL_TYPE_ID)
    .reduce((a, b) => [...a, ...b.items], [])
    .reduce((list, item) => {
      const items = item.variants.map(v => ({
        id: v.id,
        textColor: item.text_color,
        isDisabled: false,
        source: item.source,
        backgroundColor: item.background_color,
        value: v.display_name
      }));

      return [...list, ...items];
    }, []);

  return {
    eventCredentials: credentialItems,
    user: user(state),
    dashboard: dashboardsByType(state, "event_credentials_v3")
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getItemTypesByEvent,
      getItemGroupsByEventAndType,
      ...ModalActions,
      ...WidgetActions,
      ...DashboardActions
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(External);
