import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../model";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Loading from "ui-kit/Loading";
import Title from "./Title";
import Body from "./Body";
import { withRouter } from "react-router";
import AddPersonModal from "PortalHub/AddPersonModal/View";
import { accountId } from "../selectors";

const decorate = R.compose(
  withRouter,
  connect(
    state => ({
      loading: getters.loading(state),
      isAddPersonModalOpened: getters.isAddPersonModalOpened(state),
      typeId: getters.recordTypeId(state),
      accountId: accountId(state)
    }),
    {
      init: actions.init
    }
  ),
  CSSModules(css)
);

const Layout = ({
  init,
  loading,
  isAddPersonModalOpened = false,
  typeId,
  accountId,
  params
}) => {
  useEffect(() => {
    init({
      recordTypeId: params.recordTypeId
    });
  }, []);
  return (
    <div styleName="wrapper">
      {loading ? (
        <Loading />
      ) : (
        <div styleName="content">
          <Title />
          <Body />
          {isAddPersonModalOpened ? (
            <AddPersonModal iniTypeId={typeId} accountId={accountId} />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default decorate(Layout);
