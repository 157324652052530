import * as R from "ramda";
import React from "react";
require("flatpickr/dist/themes/airbnb.css");

import moment from "moment";

import { noop } from "utils/General";

import Flatpickr from "react-flatpickr";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { setOtherZone, setLocalZone } from "utils/General";

const decorate = CSSModules(css);

export const DateTimeInput = ({
  onChange = noop,
  value = "",
  inputClassName = css.dateTimeInput,
  altFormat = "MMM D, YYYY @ h:mm a",
  timezone,
  enableTime = true,
  noCalendar = false
}) => (
  <div>
    <Flatpickr
      className={inputClassName}
      options={{
        altInput: true,
        altFormat: altFormat,
        enableTime,
        noCalendar,
        minuteIncrement: 1,

        formatDate(date, format) {
          return moment([
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds()
          ]).format(format);
        }
      }}
      value={
        R.path(["value", "iso"], value)
          ? setLocalZone(R.path(["value", "iso"], value), timezone)
          : null
      }
      onChange={val => {
        const value = moment(setOtherZone(val[0], timezone));
        onChange({
          type: "datetime",
          value: { iso: value.toISOString(), offset: value.utcOffset() }
        });
      }}
    />
  </div>
);

export default decorate(DateTimeInput);
