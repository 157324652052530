import React, { useEffect } from "react";
import { Div } from "components/Base";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import {
  sessionDates,
  getInterviewScheduleTitle,
  getInterviewScheduleSubtitle,
  getIntercomId,
  getInterviewReplaysUrl,
  getLeftSidebarSecondaryColor,
  getEnableRequestInterviewButton
} from "../selectors";
import * as R from "ramda";
import { PAGE_ACTIONS, INTERVIEW_DAYS_SELECTED_ID } from "../constants";
import Sessions from "./Sessions";

const mapIndexed = R.addIndex(R.map);

const decorator = connect(
  (state, props) => ({
    sessionDates: sessionDates(state, props),
    selectedSessionDate: getters.selectedSessionDate(state, props),
    interviewScheduleTitle: getInterviewScheduleTitle(state),
    interviewScheduleSubtitle: getInterviewScheduleSubtitle(state),
    intercomId: getIntercomId(state),
    interviewReplaysUrl: getInterviewReplaysUrl(state),
    secondaryColor: getLeftSidebarSecondaryColor(state),
    enableRequestInterviewButton: getEnableRequestInterviewButton(state)
  }),
  {
    setSelectedSessionDate: actions.setSelectedSessionDate,
    executeAction: actions.executeAction
  }
);

const RenderDayButtons = ({
  dates,
  selectedDate,
  setSelectedSessionDate,
  color,
  ...otherProps
}) => (
  <Div
    {...otherProps}
    style={{
      maxWidth: "100%",
      overflowX: "auto"
    }}
  >
    {mapIndexed((date, index) => {
      if (index === selectedDate) {
        return (
          <Div
            id={INTERVIEW_DAYS_SELECTED_ID}
            display="row.center.center"
            key={`${date}-${index}`}
            bra={1}
            bg={{
              default: color,
              hover: color
            }}
            color={{
              default: "#fff",
              hover: "#fff"
            }}
            width={68}
            height={36}
            mr={2}
            onClick={() => setSelectedSessionDate(index)}
            fs={2}
            uppercase
            style={{
              fontFamily: "'Roboto Condensed', sans-serif",
              letterSpacing: "0.4px",
              border: "1px solid #084289",
              flexShrink: 0
            }}
          >
            {date}
          </Div>
        );
      } else {
        return (
          <Div
            display="row.center.center"
            key={`${date}-${index}`}
            bra={1}
            bg={{
              default: "transparent",
              hover: color
            }}
            color={{
              default: "#7AB3FF",
              hover: "#fff"
            }}
            width={68}
            height={36}
            mr={2}
            onClick={() => setSelectedSessionDate(index)}
            fs={2}
            uppercase
            style={{
              fontFamily: "'Roboto Condensed', sans-serif",
              letterSpacing: "0.4px",
              border: "1px solid #084289",
              flexShrink: 0
            }}
          >
            {date}
          </Div>
        );
      }
    }, dates)}
  </Div>
);

const InterviewSchedule = ({
  sessionDates,
  selectedSessionDate,
  setSelectedSessionDate,
  executeAction,
  interviewReplaysUrl,
  interviewScheduleTitle,
  interviewScheduleSubtitle,
  intercomId,
  secondaryColor,
  enableRequestInterviewButton
}) => {
  const showWatchReplays = interviewReplaysUrl && interviewReplaysUrl.length;
  const showRequestInterview = enableRequestInterviewButton && intercomId;

  useEffect(() => {
    const selectedDayButton = document.getElementById(
      INTERVIEW_DAYS_SELECTED_ID
    );

    if (selectedDayButton) {
      try {
        selectedDayButton.scrollIntoView({ behavior: "smooth" });
      } catch (err) {
        // eslint-disable-next-line
        console.log(
          "[Media Hub] Scolling selected day button into view did not work"
        );
      }
    }
  }, []);

  return (
    <Div
      style={{
        padding: "43px 25px"
      }}
    >
      <Div
        fs={5}
        color="white"
        style={{
          fontFamily: "'Roboto', sans-serif",
          letterSpacing: "-0.26px",
          fontWeight: 500
        }}
      >
        {interviewScheduleTitle}
      </Div>
      {interviewScheduleSubtitle ? (
        <Div
          fs={2}
          color="white"
          mb={4}
          italics
          style={{
            fontFamily: "'Roboto', sans-serif",
            letterSpacing: "-0.15px"
          }}
        >
          {interviewScheduleSubtitle}
        </Div>
      ) : null}

      {showWatchReplays || showRequestInterview ? (
        <Div display="row.flex-start.center" mb={4}>
          {showRequestInterview ? (
            <>
              <Div
                uppercase
                color={{
                  default: "#7AB3FF",
                  hover: "#6ea1e4"
                }}
                fs={2}
                // onClick={() =>
                //   executeAction({ type: PAGE_ACTIONS.REQUEST_INTERVIEW })
                // }
                style={{
                  fontFamily: "'Roboto Condensed', sans-serif",
                  letterSpacing: "0.4px",
                  cursor: "pointer"
                }}
                className="launch-intercom launch-intercom-request-interview"
                id="request-interview"
              >
                request an interview
              </Div>
              {showWatchReplays ? (
                <Div bg="#043A7B" style={{ width: 1, height: 10 }} mx={4} />
              ) : null}
            </>
          ) : null}
          {showWatchReplays ? (
            <Div
              uppercase
              color={{
                default: "#7AB3FF",
                hover: "#6ea1e4"
              }}
              fs={2}
              onClick={() =>
                executeAction({ type: PAGE_ACTIONS.WATCH_INTERVIEW_REPLAY })
              }
              style={{
                fontFamily: "'Roboto Condensed', sans-serif",
                letterSpacing: "0.4px"
              }}
            >
              watch interview replays
            </Div>
          ) : null}
        </Div>
      ) : null}

      <RenderDayButtons
        mb={4}
        display="row.flex-start.center"
        dates={sessionDates}
        selectedDate={selectedSessionDate}
        setSelectedSessionDate={setSelectedSessionDate}
        color={secondaryColor}
      />
      <Sessions />
    </Div>
  );
};

export default decorator(InterviewSchedule);
