import { RECEIVE } from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function prepareLineItems(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.put(getState().user.user.credentials, data);
      dispatch({
        type: RECEIVE,
        payload: result.payload
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred preparing line items"
          }
        ])
      );
    }
  };
}

export function updateLineItems(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.put(getState().user.user.credentials, data);
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating line items"
          }
        ])
      );
    }
  };
}
