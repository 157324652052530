import React from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import { getSettings, getField } from "../../utils";

import { Div, FontIcon, Text1 } from "components/Base";
import styleSpects from "components/Base/Base/styleSpecs";
import MenuPopover from "ui-kit/MenuPopover";
import DeleteConfirmation from "ui-kit/DeleteConfirmation";

import { actions } from "../../model";

const ActionsBtn = ({ onClick = () => {} }) => (
  <Div
    onClick={onClick}
    display="row.space-between.center"
    p={1}
    bg={{
      hover: "neutral2",
      default: "neutral1"
    }}
    bra={1}
  >
    <FontIcon fs={2} color="primary7">
      flash_on
    </FontIcon>
    <Text1 color="primary7" bold>
      Actions
    </Text1>
    <FontIcon fs={2} color="neutral5">
      keyboard_arrow_down
    </FontIcon>
  </Div>
);

const MenuItem = ({ leftIcon = "", children, onClick = () => {} }) => (
  <Div
    fs={2}
    px={3}
    py={2}
    onClick={onClick}
    display="row.flex-start.center"
    bg={{
      hover: "neutral0",
      default: "white"
    }}
    color="black"
  >
    {leftIcon ? (
      <Div display="row.flex-start.center" width={25}>
        <i
          className="material-icons"
          style={{ color: "black", fontSize: styleSpects.fontSize[3] }}
        >
          {leftIcon}
        </i>
      </Div>
    ) : (
      <Div width={10} />
    )}
    {children}
  </Div>
);

const decorate = connect(
  null,
  bindInstance({
    execute: actions.executeAction
  })
);

const ActionsButtonCustom = props => {
  const { actions } = getSettings(props);
  const field = getField(props);
  const { execute, data } = props;
  return (
    <MenuPopover Label={<ActionsBtn />}>
      {({ closePopover }) => (
        <Div bra={3} bg="white" width={200} py={2}>
          {actions.map(action => {
            const onClick = () => {
              execute({
                row: data,
                column: field,
                actionId: action.id
              });
              closePopover();
            };
            const item = (
              <MenuItem
                key={action.id}
                leftIcon={action.leftIcon}
                onClick={onClick}
              >
                {action.name}
              </MenuItem>
            );
            if (action.confirm) {
              return (
                <DeleteConfirmation
                  text={action.confirm}
                  confirmLabel={action.confirmLabel}
                  onConfirm={onClick}
                >
                  {item}
                </DeleteConfirmation>
              );
            }
            return item;
          })}
        </Div>
      )}
    </MenuPopover>
  );
};

export default decorate(ActionsButtonCustom);
