/* eslint-disable consistent-return */
import { BEGIN, COMMIT, REVERT } from "redux-optimist";
import uuid from "node-uuid";
import {
  REQUEST,
  RECEIVE,
  CREATE,
  CREATE_COMPLETE,
  UPDATE,
  UPDATE_COMPLETE,
  CREATE_SUBFORM_DEFAULT,
  CREATE_SUBFORM_DEFAULT_COMPLETE,
  CREATE_SUBFORM,
  CREATE_SUBFORM_COMPLETE,
  CLONE_SUBFORM,
  CLONE_SUBFORM_COMPLETE,
  CLONE_SUBFORM_DEFAULT,
  CLONE_SUBFORM_DEFAULT_COMPLETE,
  BULK_DELETE_SUBFORM_DEFAULT,
  BULK_DELETE_SUBFORM,
  DELETE,
  ERROR,
  CLEAR_ERROR,
  INVALIDATE,
  REFRESH_RELATED_ORDER,
  REFRESH_PEOPLE_BLOCK_ORDER
} from "./constants";
import { RECEIVE as RECEIVE_EVENT } from "redux/modules/event/constants";
import { RECEIVE_USER } from "redux/modules/user/constants";
import { RECEIVE as RECEIVE_CART } from "redux/modules/formsV2/submission/cart/constants";
import * as selectors from "./selectors";
import api from "./api";
import orderApi from "redux/modules/orders/orders/api";
import { registerError } from "redux/modules/errors/actions";
import { get } from "lodash";
import { addReferences } from "redux/modules/entityReferences/actions";

export function createSubmission(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: CREATE,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.post(getState().user.user.credentials, data);
      dispatch({
        type: CREATE_COMPLETE,
        payload: { submission: result.submission },
        optimist: { type: COMMIT, id: transactionId }
      });
      return result.submission;
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function getSubmission(submissionId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const credentials = getState().user.user.credentials;
      const result = await api.get(credentials, submissionId);

      dispatch({ type: RECEIVE, payload: { submission: result } });

      // if getting submission for form that doesn't require login
      // and there is no current user, then persist user info from form submission data
      if (
        !get(result, ["submission", "form", "require_login"]) &&
        !getState().user.user.id
      ) {
        const collaborator = get(result, ["submission", "collaborators", 0]);

        if (collaborator) {
          dispatch({
            type: RECEIVE_USER,
            payload: {
              id: collaborator.user_id,
              credentials: {
                ...credentials,
                userId: collaborator.user_id
              }
            }
          });
        }
      }

      dispatch({ type: RECEIVE_EVENT, payload: result.submission.event });

      // if cart exists, populate cart
      if (get(result, ["submission", "related_order", "line_items"])) {
        dispatch({
          type: RECEIVE_CART,
          payload: {
            submissionId,
            cart: result.submission.related_order.line_items
          }
        });
      }

      // if references, add them
      if (get(result, ["submission", "module_record", "references"])) {
        dispatch(addReferences(result.submission.module_record.references));
      }
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting submission"
          }
        ])
      );
    }
  };
}

export function refreshRelatedOrder(orderId) {
  return async (dispatch, getState) => {
    try {
      const result = await orderApi.getOrder(
        getState().user.user.credentials,
        orderId
      );
      const order = result.payload[0];

      dispatch({
        type: REFRESH_RELATED_ORDER,
        payload: {
          order
        }
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred refreshing order"
          }
        ])
      );
    }
  };
}

export function refreshPeopleBlockOrder(data) {
  return async (dispatch, getState) => {
    try {
      const result = await orderApi.getOrder(
        getState().user.user.credentials,
        data.orderId
      );
      const order = result.payload[0];

      dispatch({
        type: REFRESH_PEOPLE_BLOCK_ORDER,
        payload: {
          contactId: data.contactId,
          blockFieldId: data.blockFieldId,
          order
        }
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred refreshing order"
          }
        ])
      );
    }
  };
}

export function updateSubmission(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: UPDATE,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.put(getState().user.user.credentials, data);
      dispatch({
        type: UPDATE_COMPLETE,
        payload: { submission: result.submission },
        optimist: { type: COMMIT, id: transactionId }
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function setSubmissionLocked(isLocked, submission) {
  return updateSubmission({
    ...submission,
    isLocked,
    is_locked: isLocked
  });
}

export function deleteSubmission(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: DELETE,
      payload: data
    });
    try {
      const result = await api.delete(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function deleteSubformDefaultSubmission(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: BULK_DELETE_SUBFORM_DEFAULT,
      payload: data
    });
    try {
      const result = await api.delete(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function deleteSubformSubmission(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: BULK_DELETE_SUBFORM,
      payload: data
    });
    try {
      const result = await api.delete(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function createSubformDefaultSubmission(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: CREATE_SUBFORM_DEFAULT,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.post(getState().user.user.credentials, data);
      dispatch({
        type: CREATE_SUBFORM_DEFAULT_COMPLETE,
        payload: {
          subformId: data.subformId,
          submission: result.submission
        },
        optimist: { type: COMMIT, id: transactionId }
      });
      return result.submission;
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function createSubformSubmission(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: CREATE_SUBFORM,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.post(getState().user.user.credentials, data);
      dispatch({
        type: CREATE_SUBFORM_COMPLETE,
        payload: {
          subformId: data.subformId,
          submission: result.submission
        },
        optimist: { type: COMMIT, id: transactionId }
      });
      return result.submission;
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function cloneSubformDefaultSubmissions(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: CLONE_SUBFORM_DEFAULT,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.clone(getState().user.user.credentials, data);
      dispatch({
        type: CLONE_SUBFORM_DEFAULT_COMPLETE,
        payload: {
          subformId: data.subformId,
          submissions: result.submissions
        },
        optimist: { type: COMMIT, id: transactionId }
      });
      return result.submission;
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function cloneSubformSubmissions(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: CLONE_SUBFORM,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.clone(getState().user.user.credentials, data);
      dispatch({
        type: CLONE_SUBFORM_COMPLETE,
        payload: {
          subformId: data.subformId,
          submissions: result.submissions
        },
        optimist: { type: COMMIT, id: transactionId }
      });
      return result.submission;
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function invalidate() {
  return {
    type: INVALIDATE
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR
  };
}
