import React, { Component } from "react";
import { connect } from "react-redux";
import { isEqual } from "lodash";

import Field from "Submission/Editor/View/Field";
import { Div } from "components/Base";
import { actions } from "Orders/OrderModal";

const decorate = connect(
  null,
  {
    addOrderFieldValue: actions.addOrderFieldValue
  }
);

class Fields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldBeingEdited: null
    };
  }

  onChange = ({ id }, value) => {
    const existingValue = this.props.fields.find(f => f.id === id);
    if (!isEqual(existingValue, value)) {
      this.props.addOrderFieldValue({
        fieldId: id,
        value
      });
    }
  };

  closeField = () => {
    this.setState({
      fieldBeingEdited: null
    });
  };

  editField = field => {
    if (!this.state.fieldBeingEdited) {
      this.setState({
        fieldBeingEdited: field.id
      });
    }
  };

  render() {
    const { fields, readOnly, details } = this.props;

    return (
      <Div width={1}>
        {fields.map(field => {
          const values = {
            [field.id]: field.value
          };
          return (
            <Field
              key={field.id}
              readOnly={readOnly}
              isEditing={field.id === this.state.fieldBeingEdited}
              eventDetails={details}
              editField={() => this.editField(field)}
              closeField={this.closeField}
              changeField={value => this.onChange(field, value)}
              value={values ? values[field.id] : undefined}
              values={values}
              fields={fields}
              field={field}
              metaData={this.props.getMetaData(fields, values, values, field)}
            />
          );
        })}
      </Div>
    );
  }
}

export default decorate(Fields);
