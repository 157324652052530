import PropTypes from "prop-types";
import React, { Component } from "react";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class ButtonAction extends Component {
  render() {
    const classNames = [css.container];
    if (this.props.blendIn) {
      classNames.push(css.blendIn);
    }
    if (this.props.pressed) {
      classNames.push(css.pressed);
    }
    if (this.props.className) {
      classNames.push(this.props.className);
    }
    return (
      <div
        key={this.props.type}
        style={this.props.style}
        className={classNames.join(" ")}
        onClick={this.props.action}
      >
        {this.props.icon}
        {this.props.label}
      </div>
    );
  }
}

ButtonAction.propTypes = {
  action: PropTypes.func,
  type: PropTypes.string.isRequired,
  style: PropTypes.object,
  icon: PropTypes.node,
  label: PropTypes.node,
  blendIn: PropTypes.bool,
  pressed: PropTypes.bool,
  className: PropTypes.string
};

export default ButtonAction;
