/* eslint-disable no-underscore-dangle */
import React from "react";

import {
  put,
  call,
  all,
  fork,
  select,
  take,
  takeEvery
} from "redux-saga/effects";
import { openInNewWindow } from "utils/General";
import { makeFuture } from "utils/General/sagas";
import * as R from "ramda";

import copy from "copy-to-clipboard";

import { actions, getters } from "OrgLight/Common/Dashboard";
import { getters as ViewPickerGetters } from "ui-kit/ViewPicker";
import { actions as TableActions } from "ui-kit/Table/model";
import { hideModal, showModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import Helpers from "utils/Global/Helpers";

import {
  ROW_ACTIONS as TABLE_ROW_ACTIONS,
  GROUP_ACTIONS
} from "ui-kit/Table/constants";
import { VIEWPICKER_INSTANCE_ID } from "OrgLight/Common/Dashboard/constants";
import {
  TABLE_INSTANCE_ID,
  ROW_ACTIONS
} from "OrgLight/Common/Dashboard/constants";

import { getCredentials } from "redux/modules/user/selectors";
import { getAllRecordReferences } from "App/Data/selectors";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import {
  getPeopleReportContactsAndAccounts,
  getContactsForLogin
} from "OrgLight/Common/Dashboard/selectors";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";
import * as SEND_TO_OPTION_TYPES from "SendEmailModal/utils/send-to-option-types";
import * as EMAIL_TEMPLATES from "utils/Emails/default-emails";

import addValueApi from "redux/modules/modules/values/api";
import recordApi from "redux/modules/modules/records/api";
import accountValuesApi from "redux/modules/accounts/values/api";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import SendEmail from "SendEmailModal/View";
import AssignAccountFormsModal from "components/Global/CRM/Modals/Assign/AssignForms/AssignAccountForms";
import AssignContactFormsModal from "components/Global/CRM/Modals/Assign/AssignForms/AssignContactForms";
import RunReportModal from "Reports/RunReportModal/View";
import SendDocument from "components/Event/Accounts/Modals/SendDocument";
import AssignDocumentRequestsModal from "components/Global/CRM/Modals/Assign/AssignDocumentRequests";
import AssignOwnersModal from "components/Global/Module/Modals/AssignOwners";
import ChangeRecordType from "components/Global/CRM/Modals/ChangeRecordType";
import DeleteConfirmation from "components/Global/CRM/Modals/DeleteConfirmation";
import DeleteOrRemoveConfirmation from "components/Global/CRM/Modals/DeleteOrRemoveConfirmation";
import BulkEdit from "components/Global/CRM/Modals/BulkEdit";
import CreateDocuments from "Modules/GenerateDocumentsModal/View";
import Retrieving from "components/Global/CRM/Modals/Retrieving";
import ViewRecordModal from "components/Global/Module/Modals/ViewRecord";
import SubscribeModal from "Modules/SubscribeModal/View";
import BulkLoginDetails from "components/Event/Accounts/Account/People/BulkLoginDetailsModal";
import ViewOrderModal from "Orders/OrderModal/View";

import accountApi from "redux/modules/accounts/profile/api";
import { registerError } from "redux/modules/errors/actions";

import { navigateTo } from "utils/General";

import { review } from "App/Data/sagas";

const UPLOAD_FIELD_ID = "d512aeba-ae22-4623-b64c-90f293e37f16";

const viewRecord = function*({ payload: { row } }) {
  const orgId = yield select(getOrgId);
  const moduleId = yield select(getters.moduleId);

  if (moduleId === STANDARD_MODULE_IDS.accounts.id) {
    window.location = `/org-light/${orgId}/account/${row.id}`;
  } else if (moduleId === STANDARD_MODULE_IDS.contacts.id) {
    window.location = `/org-light/${orgId}/contact/${row.id}`;
  }
};

const sendEmail = function*({
  payload: { row, selectedOptions, subject = null, message = null }
}) {
  const moduleId = yield select(getters.moduleId);

  yield put(
    showModal({
      content: (
        <SendEmail
          moduleId={moduleId}
          records={[row.id]}
          subject={subject}
          message={message}
          selectedOptions={
            moduleId === STANDARD_MODULE_IDS.contacts.id
              ? [STANDARD_FIELD_IDS.CONTACTS.EMAIL]
              : selectedOptions
              ? selectedOptions
              : moduleId === STANDARD_MODULE_IDS.accounts.id
              ? [SEND_TO_OPTION_TYPES.ACCOUNT_CONTACT_USERS]
              : null
          }
        />
      ),
      wrapper: ModalWrapper
    })
  );
};

const showBulkEditModal = function*({ row, orgId, references, fields }) {
  const handleEdit = makeFuture();

  yield put(
    showModal({
      content: (
        <BulkEdit
          selected={[row.id]}
          getMetaData={(rowData, column) => ({
            ...row,
            meta: {
              orgId,
              contactId: row.id,
              rowId: row.id,
              columnId: column.id,
              columnSettings: column.settings,
              references
            },
            helpers: {}
          })}
          columns={fields}
          onSave={handleEdit.done}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleEdit.onRealized);
};

const bulkEdit = function*({ payload: { row } }) {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const references = yield select(getAllRecordReferences);
  const fields = yield select(getters.columns);
  const moduleId = yield select(getters.moduleId);

  const result = yield call(showBulkEditModal, {
    row,
    orgId,
    references,
    fields
  });

  if (!result) {
    return false;
  }

  yield call(addValueApi.post, credentials, {
    fieldId: result.fieldId,
    recordId: row.id,
    moduleId,
    value: result.value
  });

  yield put(showSnackbar({ message: "Record updated", action: "OK" }));

  yield put(actions.fetchData());
};

const sendItemSummary = function*({ payload: { row, actionId } }) {
  const content = R.prop(actionId)({
    [ROW_ACTIONS.SEND_ALL_ITEMS]: EMAIL_TEMPLATES.ITEM_SUMMARY,
    [ROW_ACTIONS.SEND_ALL_PASSES]: EMAIL_TEMPLATES.CREDENTIALS_SUMMARY,
    [ROW_ACTIONS.SEND_ALL_MEALS]: EMAIL_TEMPLATES.CATERING_SUMMARY,
    [ROW_ACTIONS.SEND_ALL_PASSES_MEALS]:
      EMAIL_TEMPLATES.CREDENTIALS_AND_CATERING_SUMMARY,
    [ROW_ACTIONS.SEND_ALL_ASSETS]: EMAIL_TEMPLATES.INVENTORY_SUMMARY
  });

  yield call(sendEmail, {
    payload: {
      row,
      ...content
    }
  });
};

const sendLogin = function*({ payload: { row } }) {
  yield call(sendEmail, {
    payload: {
      row,
      ...EMAIL_TEMPLATES.PORTAL_LOGIN
    }
  });
};

const sendLink = function*({ payload: { row } }) {
  yield call(sendEmail, {
    payload: {
      row,
      selectedOptions: [SEND_TO_OPTION_TYPES.ACCOUNT_PRIMARY_CONTACTS],
      ...EMAIL_TEMPLATES.ASSIGNMENT_MANAGER
    }
  });
};

const showAssignDocumentsModal = function*({ moduleId, row }) {
  const handleAssign = makeFuture();
  yield put(
    showModal({
      content: (
        <AssignDocumentRequestsModal
          moduleId={moduleId}
          recordNameSingular={
            moduleId === STANDARD_MODULE_IDS.accounts.id ? "Group" : "Person"
          }
          recordNamePlural={
            moduleId === STANDARD_MODULE_IDS.accounts.id ? "Groups" : "People"
          }
          recordIds={[row.id]}
          onDone={handleAssign.done}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleAssign.onRealized);
};

const assignDocumentRequests = function*({ payload: { row } }) {
  const moduleId = yield select(getters.moduleId);

  yield call(showAssignDocumentsModal, {
    moduleId,
    row
  });

  yield put(actions.fetchData());
};

const showAssignFormsModal = function*({ moduleId, row }) {
  const handleAssign = makeFuture();

  const Modal =
    moduleId === STANDARD_MODULE_IDS.accounts.id
      ? AssignAccountFormsModal
      : AssignContactFormsModal;

  yield put(
    showModal({
      content: (
        <Modal
          moduleId={moduleId}
          recordNameSingular={
            moduleId === STANDARD_MODULE_IDS.accounts.id ? "Group" : "Person"
          }
          recordNamePlural={
            moduleId === STANDARD_MODULE_IDS.accounts.id ? "Groups" : "People"
          }
          recordIds={[row.id]}
          onDone={handleAssign.done}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleAssign.onRealized);
};

const assignForms = function*({ payload: { row } }) {
  const moduleId = yield select(getters.moduleId);

  yield call(showAssignFormsModal, {
    moduleId,
    row
  });

  yield put(actions.fetchData());
};

const showAssignOwnersModal = function*({ moduleId, row }) {
  const handleAssign = makeFuture();
  yield put(
    showModal({
      content: (
        <AssignOwnersModal
          moduleId={moduleId}
          recordNameSingular={
            moduleId === STANDARD_MODULE_IDS.accounts.id ? "Group" : "Person"
          }
          recordNamePlural={
            moduleId === STANDARD_MODULE_IDS.accounts.id ? "Groups" : "People"
          }
          recordIds={[row.id]}
          onDone={handleAssign.done}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleAssign.onRealized);
};

const assignOwners = function*({ payload: { row } }) {
  const moduleId = yield select(getters.moduleId);

  yield call(showAssignOwnersModal, {
    moduleId,
    row
  });

  yield put(actions.fetchData());
};

const showChangeRecordTypeModal = function*({ moduleId }) {
  const handleChange = makeFuture();
  yield put(
    showModal({
      content: (
        <ChangeRecordType moduleId={moduleId} update={handleChange.done} />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleChange.onRealized);
};

const changeRecordType = function*({ payload: { row } }) {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const moduleId = yield select(getters.moduleId);

  const data = yield call(showChangeRecordTypeModal, {
    moduleId
  });

  yield call(recordApi.put, credentials, {
    moduleId,
    recordId: row.id,
    type: data.type,
    options: {
      orgId
    }
  });

  yield put(showSnackbar({ message: "Record updated", action: "OK" }));

  yield put(actions.fetchData());
  yield put(
    TableActions.clearSelectedRows(null, {
      meta: {
        instanceId: TABLE_INSTANCE_ID
      }
    })
  );
};

const showGenerateDocumentsModal = function*({ orgId, row, moduleId }) {
  const handleGenerate = makeFuture();
  yield put(
    showModal({
      content: (
        <CreateDocuments
          onDone={handleGenerate.done}
          orgId={orgId}
          moduleId={moduleId}
          selected={[row.id]}
          showSaveAndSend={true}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleGenerate.onRealized);
};

const showSendDocumentsModal = function*({ recipients, documents }) {
  const handleSend = makeFuture();
  yield put(
    showModal({
      content: (
        <SendDocument
          onDone={handleSend.done}
          recipients={recipients}
          documents={documents}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleSend.onRealized);
};

// @TODO: Clean this up - move more to the backend and generalize
const generateDocument = function*({ payload: { row } }) {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const moduleId = yield select(getters.moduleId);

  const { documents, send } = yield call(showGenerateDocumentsModal, {
    orgId,
    row,
    moduleId
  });

  // Handle sending document notifications
  if (send) {
    yield put(
      showModal({
        content: (
          <Retrieving message="Please wait..." title="Retrieving Records" />
        ),
        wrapper: ModalWrapper
      })
    );

    const fetchedRecord = yield call(accountApi.get, credentials, {
      accountId: row.id,
      orgId
    });

    const recipients = [fetchedRecord]
      .map(({ account }) => account)
      .filter(Boolean)
      .map(a => ({
        name: R.pathOr("(No Name)", [
          "values",
          STANDARD_FIELD_IDS.ACCOUNTS.NAME,
          "value",
          "value"
        ])(a),
        users: a.users.filter(u => u.user_id && u.email)
      }));

    yield put(hideModal());

    const count = yield call(showSendDocumentsModal, {
      recipients,
      documents
    });

    yield put(
      showSnackbar({
        message: `${count} Document${count === 1 ? "" : "s"} Saved & Sent`,
        action: "OK"
      })
    );
  }
};

const runReport = function*({ payload: { row } }) {
  const moduleId = yield select(getters.moduleId);

  yield put(
    showModal({
      content: <RunReportModal moduleId={moduleId} recordIds={[row.id]} />,
      wrapper: ModalWrapper
    })
  );
};

const showUploadFilesModal = function*() {
  const handleUpload = makeFuture();

  Helpers.getFilepicker(
    {
      multiple: true,
      mimetypes: [],
      container: "modal",
      services: ["COMPUTER", "DROPBOX", "GOOGLE_DRIVE"]
    },
    { path: "event-files/" },
    handleUpload.done
  );

  return yield call(handleUpload.onRealized);
};

const uploadFiles = function*({ payload: { row } }) {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);

  const files = yield call(showUploadFilesModal);

  const moduleFiles = yield call(recordApi.bulkPost, credentials, {
    records: files.map(file => ({
      moduleId: STANDARD_MODULE_IDS.files.id,
      record: {
        [UPLOAD_FIELD_ID]: { type: "file", value: { files: [file] } }
      }
    })),
    options: {
      orgId
    }
  });

  const relatedRecordIds = moduleFiles.records.map(({ id }) => id);

  yield call(accountValuesApi.bulkRelate, credentials, {
    recordIds: [row.id],
    relatedRecordIds,
    relatedModuleId: STANDARD_MODULE_IDS.files.id
  });

  yield put(showSnackbar({ message: "Files added", action: "OK" }));

  yield put(actions.fetchData());
};

const showDeleteOrRemoveConfirmation = function*() {
  const handleDelete = makeFuture();
  yield put(
    showModal({
      content: (
        <DeleteOrRemoveConfirmation
          countOfSelected={1}
          heading={`Remove Record?`}
          onRemove={() => handleDelete.done(true)}
          onDelete={() => handleDelete.done(false)}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleDelete.onRealized);
};

const showDeleteConfirmation = function*() {
  const handleDelete = makeFuture();
  yield put(
    showModal({
      content: (
        <DeleteConfirmation
          heading="Delete Record?"
          message={
            <div>
              {`
                Are you sure you want to remove this record?
                `}
              <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                This cannot be undone.
              </div>
            </div>
          }
          onConfirm={() => handleDelete.done(false)}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleDelete.onRealized);
};

const deleteRecord = function*({ payload: { row } }) {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const moduleId = yield select(getters.moduleId);
  let removeOnly = false;

  if (
    !orgId &&
    [STANDARD_MODULE_IDS.accounts.id, STANDARD_MODULE_IDS.contacts.id].includes(
      moduleId
    )
  ) {
    removeOnly = yield call(showDeleteOrRemoveConfirmation);
  } else {
    yield call(showDeleteConfirmation);
  }

  yield call(recordApi.delete, credentials, {
    moduleId,
    orgId,
    record: { id: row.id },
    options: {
      orgId,
      removeOnly
    }
  });

  yield put(
    showSnackbar({
      message: `Record ${removeOnly ? "removed" : "deleted"}`,
      action: "OK"
    })
  );

  yield put(actions.fetchData());
  yield put(
    TableActions.clearSelectedRows(null, {
      meta: {
        instanceId: TABLE_INSTANCE_ID
      }
    })
  );
};

const showViewRecordModal = function*({ row }) {
  const moduleId = yield select(getters.moduleId);

  yield put(
    showModal({
      content: <ViewRecordModal moduleId={moduleId} recordId={row.id} />,
      wrapper: ModalWrapper
    })
  );
};

const showSubmissionModal = function*({ row }) {
  const handleClose = makeFuture();

  yield put(
    showModal({
      content: (
        <ViewOrderModal
          submissionId={row.related_submission_id}
          onDone={() => handleClose.done(true)}
        />
      ),
      wrapper: ModalWrapper
    })
  );

  return yield call(handleClose.onRealized);
};

const openRecord = function*({ payload: { row } }) {
  const moduleId = yield select(getters.moduleId);
  const orgId = yield select(getOrgId);

  if (moduleId === STANDARD_MODULE_IDS.accounts.id) {
    yield call(openInNewWindow, `/org-light/${orgId}/account/${row.id}`);
  } else if (moduleId === STANDARD_MODULE_IDS.contacts.id) {
    yield call(openInNewWindow, `/org-light/${orgId}/contact/${row.id}`);
  } else if (row.related_submission_id) {
    const result = yield call(showSubmissionModal, { row });
    if (result) {
      yield put(actions.fetchData());
    }
  } else {
    yield call(showViewRecordModal, { row });
  }
};

const exportGroup = function*({ payload: { group, actionId } }) {
  yield put(actions.setLoading(true));
  try {
    // @NOTE: replace by api call
    const result = yield call(R.always({ url: "./" }), {
      groupIds: group.list,
      type: R.prop(actionId, {
        [GROUP_ACTIONS.EXPORT_CSV]: "csv",
        [GROUP_ACTIONS.EXPORT_XLSX]: "xlsx"
      })
    });
    yield call(navigateTo, result.url);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred exporting the schedule group"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const showGiveLoginAccessModal = function*(
  contactsAndAccounts,
  shareOnly = false
) {
  const handleDone = makeFuture();

  yield put(
    showModal({
      content: (
        <BulkLoginDetails
          contacts={contactsAndAccounts}
          shareOnly={shareOnly}
          onDone={() => handleDone.done(true)}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleDone.onRealized);
};

const giveLoginAccess = function*() {
  const contactsAndAccounts = yield select(getPeopleReportContactsAndAccounts);

  const isComplete = yield call(showGiveLoginAccessModal, contactsAndAccounts);

  if (isComplete) {
    yield put(actions.fetchData());
  }
};

const giveContactLoginAccess = function*() {
  const contactsAndAccounts = yield select(getContactsForLogin);

  const isComplete = yield call(showGiveLoginAccessModal, contactsAndAccounts);

  if (isComplete) {
    yield put(actions.fetchData());
  }
};

const shareLoginDetails = function*() {
  const contactsAndAccounts = yield select(getPeopleReportContactsAndAccounts);

  const isComplete = yield call(
    showGiveLoginAccessModal,
    contactsAndAccounts,
    true
  );

  if (isComplete) {
    yield put(actions.fetchData());
  }
};

const shareContactLoginDetails = function*() {
  const contactsAndAccounts = yield select(getContactsForLogin);

  const isComplete = yield call(
    showGiveLoginAccessModal,
    contactsAndAccounts,
    true
  );

  if (isComplete) {
    yield put(actions.fetchData());
  }
};

const watchTableActions = function*() {
  for (;;) {
    const action = yield take(TableActions.executeAction.type);
    const delegate = R.prop(action.payload.actionId, {
      [ROW_ACTIONS.VIEW_RECORD]: viewRecord,
      [ROW_ACTIONS.SEND_EMAIL]: sendEmail,
      [ROW_ACTIONS.EDIT]: bulkEdit,
      [ROW_ACTIONS.SEND_ALL_ITEMS]: sendItemSummary,
      [ROW_ACTIONS.SEND_ALL_PASSES]: sendItemSummary,
      [ROW_ACTIONS.SEND_ALL_MEALS]: sendItemSummary,
      [ROW_ACTIONS.SEND_ALL_PASSES_MEALS]: sendItemSummary,
      [ROW_ACTIONS.SEND_ALL_ASSETS]: sendItemSummary,
      [ROW_ACTIONS.SEND_LOGIN]: sendLogin,
      [ROW_ACTIONS.SEND_LINK]: sendLink,
      [ROW_ACTIONS.DOCUMENT_REQUESTS]: assignDocumentRequests,
      [ROW_ACTIONS.ASSIGN_FORMS]: assignForms,
      [ROW_ACTIONS.ASSIGN_OWNERS]: assignOwners,
      [ROW_ACTIONS.CHANGE_GROUP]: changeRecordType,
      [ROW_ACTIONS.CHANGE_PERSON]: changeRecordType,
      [ROW_ACTIONS.GENERATE_DOCUMENT]: generateDocument,
      [ROW_ACTIONS.RUN_REPORT]: runReport,
      [ROW_ACTIONS.UPLOAD_FILES]: uploadFiles,
      [ROW_ACTIONS.DELETE_RECORDS]: deleteRecord,
      [TABLE_ROW_ACTIONS.OPEN_RECORD]: openRecord,
      [GROUP_ACTIONS.EXPORT_CSV]: exportGroup,
      [GROUP_ACTIONS.EXPORT_XLSX]: exportGroup,
      [ROW_ACTIONS.GIVE_LOGIN_ACCESS]: giveLoginAccess,
      [ROW_ACTIONS.SHARE_LOGIN_DETAILS]: shareLoginDetails,
      [ROW_ACTIONS.GIVE_CONTACT_LOGIN_ACCESS]: giveContactLoginAccess,
      [ROW_ACTIONS.SHARE_CONTACT_LOGIN_DETAILS]: shareContactLoginDetails
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const showSubscribeModal = function*() {
  const moduleId = yield select(getters.moduleId);
  const activeViewId = yield select(ViewPickerGetters.activeViewId, {
    instanceId: VIEWPICKER_INSTANCE_ID
  });
  const recordTypeId = yield select(getters.recordTypeId);

  yield put(
    showModal({
      content: (
        <SubscribeModal
          type="module"
          moduleId={moduleId}
          viewId={activeViewId}
          recordTypeId={recordTypeId}
        />
      ),
      wrapper: ModalWrapper
    })
  );
};

const watchReview = function*() {
  for (;;) {
    const { payload, type, meta } = yield take([
      actions.approve.type,
      actions.deny.type
    ]);
    const response = R.propOr(null, type, {
      [actions.approve.type]: "approve",
      [actions.deny.type]: "reject"
    });
    try {
      const { success } = yield call(review, {
        response,
        targetType: "recordId",
        recordIds: payload.recordIds
      });

      yield put(actions.fetchData({ useSilentRefresh: true }, { meta }));
      if (success) {
        yield put(showSnackbar({ message: "Review finished" }));
      }
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error occurred approving line items"
          }
        ])
      );
    }
  }
};

const watchShowSubscribeModal = function*() {
  yield takeEvery(actions.showSubscribeModal.type, showSubscribeModal);
};

const rootSaga = function*() {
  yield all([
    fork(watchTableActions),
    fork(watchShowSubscribeModal),
    fork(watchReview)
  ]);
};

export default rootSaga;
