import * as R from "ramda";
import { createModule, createHandlers } from "redux-mvc";

import focusModule from "ui-kit/Focus";
import filtersModule from "ui-kit/SelectFilter";

import { NAMESPACE } from "./constants";

const iniState = {
  searchTerm: ""
};

const reducers = {
  clearSearch: R.always({ searchTerm: "" })
};

const handlers = createHandlers({ iniState, reducers, namespace: NAMESPACE });

const { actions, getters } = handlers;

const module = createModule(handlers);

module.plugModule(focusModule);
module.plugModule(filtersModule);

export { actions, getters };

export default module;
