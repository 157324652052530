import React from "react";
import {
  Div,
  DownIcon,
  MediumOutlineButton,
  AddIcon,
  CloseIcon
} from "components/Base";
import CollapsablePanel from "ui-kit/CollapsiblePanel";
import { getters, actions } from "../model";
import { connect } from "react-redux";
import * as R from "ramda";
import Loading from "ui-kit/Loading";

const decorate = connect(
  (state, props) => ({
    selectedPeople: getters.selectedPeople(state, props),
    selectedPeopleLoading: getters.selectedPeopleLoading(state, props)
  }),
  {
    removeSelectedPerson: actions.removeSelectedPerson,
    openModal: () => actions.setIsSelectPeopleModalOpen(true)
  }
);

const SelectedPeople = ({
  selectedPeople,
  removeSelectedPerson,
  openModal,
  selectedPeopleLoading
}) => (
  <Div
    style={{
      background: "#FFFFFF",
      padding: 20,
      borderRadius: 4
    }}
    mb={2}
  >
    {!selectedPeopleLoading ? (
      <CollapsablePanel
        bg="transparent"
        right={true}
        arrowIcon={DownIcon}
        arrowColor="neutral7"
        heading={
          <Div
            style={{
              color: "#333333",
              fontSize: 18,
              marginRight: "auto"
            }}
            py={2}
            fw={4}
          >
            {`Creating order for ${R.length(selectedPeople)} ${
              R.length(selectedPeople) === 1 ? "person" : "people"
            }`}
          </Div>
        }
      >
        {R.map(
          ({ contact_id, contact_name }) => (
            <Div display="row.space-between.center" className="hoverContainer">
              <Div
                key={contact_id}
                color="#636363"
                py={2}
                fw={3}
                bg={{
                  default: "inherit",
                  hover: "#f5f5f5"
                }}
                px={2}
              >
                {contact_name}
              </Div>
              <CloseIcon
                className="showOnHover"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  removeSelectedPerson(contact_id);
                }}
              />
            </Div>
          ),
          selectedPeople
        )}
        <MediumOutlineButton LeftIcon={AddIcon} mt={2} onClick={openModal}>
          Add Person
        </MediumOutlineButton>
      </CollapsablePanel>
    ) : (
      <Loading size={24} />
    )}
  </Div>
);

export default decorate(SelectedPeople);
