import React, { Component } from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import ReactDOM from "react-dom";

/**
 * Prevent default behavior for event
 *
 * @param e
 * @returns {boolean}
 */
const cancelScrollEvent = e => {
  e.stopImmediatePropagation();
  e.preventDefault();
  e.returnValue = false;
  return false;
};

const addScrollEventListener = (elem, handler) => {
  elem.addEventListener("wheel", handler, false);
};

const removeScrollEventListener = (elem, handler) => {
  elem.removeEventListener("wheel", handler, false);
};

class ScrollLocked extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    style: PropTypes.object
  };

  componentDidMount() {
    this._scrollLock();
  }

  componentDidUpdate() {
    this._scrollLock();
  }

  componentWillUnmount() {
    this._scrollRelease();
  }

  render() {
    return (
      <div className={this.props.className} style={this.props.style}>
        {this.props.children}
      </div>
    );
  }

  _scrollLock() {
    const elem = ReactDOM.findDOMNode(this);
    if (elem) {
      addScrollEventListener(elem, this._onScrollHandler);
    }
  }

  _scrollRelease() {
    const elem = ReactDOM.findDOMNode(this);
    if (elem) {
      removeScrollEventListener(elem, this._onScrollHandler);
    }
  }

  _onScrollHandler(e) {
    const elem = ReactDOM.findDOMNode(this);
    const scrollTop = elem.scrollTop;
    const scrollHeight = elem.scrollHeight;
    const height = elem.clientHeight;
    const wheelDelta = e.deltaY;
    const isDeltaPositive = wheelDelta > 0;

    if (isDeltaPositive && wheelDelta > scrollHeight - height - scrollTop) {
      elem.scrollTop = scrollHeight;
      return cancelScrollEvent(e);
    } else if (!isDeltaPositive && -wheelDelta > scrollTop) {
      elem.scrollTop = 0;
      return cancelScrollEvent(e);
    }
  }
}

export default Radium(ScrollLocked);
