export const NAMESPACE = "EventLightTasks";

export const TABLE_INSTANCE_ID = "EventLightTasksTable";

export const FORM_ID = "EventLightTasksForm";

export const FIELD_IDS = {
  NAME: "name",
  DESCRIPTION: "description",
  METHOD: "completion_method",
  URL: "url"
};
