import PropTypes from "prop-types";
import React from "react";
import autobind from "autobind-decorator";
import Radios from "components/Global-2016/Forms/Radios";
import FormBaseInput from "../FormBaseInput";
import FormInputWrapper from "components/Event/FormsV2/Sections/FormInputWrapper";
import isRequiredValid from "utils/value-types/validations/text/required";
import getValue from "utils/value-types/get-value/text";
import { isEqual } from "lodash";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FormRadios extends FormBaseInput {
  constructor(props) {
    super(props);

    this.state = {
      value: getValue(props.formValues[props.field.id]),
      isShown: false
    };
  }

  wrapValue(value) {
    return {
      type: "text",
      value
    };
  }

  @autobind
  async isValid() {
    const errors = [];
    const { type, field } = this.props;

    // We need to mock this value as value types expect this schema
    const value = {
      type,
      value: this.state.value
    };

    if (field.is_required && !isRequiredValid(value)) {
      errors.push("This is a required question");
    }

    this.setState({
      errors
    });
    return !errors.length;
  }

  @autobind
  handleSave(e) {
    this.setState(
      {
        value: e.target.value
      },
      () => this.save(this.props.field.id, this.wrapValue(this.state.value))
    );
  }

  @autobind
  handleToogleShowEditMode() {
    this.setState({
      isShown: R.not(this.state.isShown)
    });
  }

  componentWillUpdate(nextProps) {
    if (
      !this.props.isEditing &&
      !isEqual(
        this.props.formValues[this.props.field.id],
        nextProps.formValues[this.props.field.id]
      )
    ) {
      this.setState({
        value: getValue(nextProps.formValues[this.props.field.id])
      });
    }
  }

  render() {
    let handleSave;
    const { connectDragSource, canShowSimpleSideBar } = this.props;
    const { isAdminField } = this.props.field.settings;
    const required = this.props.field.is_required;

    if (!this.props.isEditing) {
      handleSave = this.handleSave;
    }

    return (
      <FormInputWrapper
        isEditing={this.props.isEditing}
        isValid={this.state.isValid}
        errorMessages={this.state.errors}
        fieldData={this.props.field}
        instanceId={this.props.field.id}
        isShown={this.state.isShown}
        setIsShown={() => this.handleToogleShowEditMode()}
        connectDragSource={connectDragSource}
      >
        <Radios
          disabled={this.props.disabled}
          required={required}
          isAdminField={isAdminField}
          label={this.props.field.name}
          description={this.props.field.settings.description}
          selected={this.state.value}
          onChange={handleSave}
          options={this.props.field.settings.options.map(o => o.value)}
          id={this.props.field.id}
          instanceId={this.props.field.id}
          fieldData={this.props.field}
          isShown={this.state.isShown}
          canShowSimpleSideBar={canShowSimpleSideBar}
          readOnly={this.props.field.is_readonly}
          setIsShown={() => this.handleToogleShowEditMode()}
        />
      </FormInputWrapper>
    );
  }
}

FormRadios.propTypes = {
  formValues: PropTypes.object,
  field: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  updateFormValue: PropTypes.func.isRequired
};

export default FormRadios;
