import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import EmailTemplates from "./EmailTemplates";
import * as emailTemplateActions from "redux/modules/eventEmailTemplates/actions";
import * as emailTemplateSelectors from "redux/modules/eventEmailTemplates/selectors";

function mapStateToProps(state) {
  return {
    list: emailTemplateSelectors.list(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Object.assign({}, emailTemplateActions), dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailTemplates);
