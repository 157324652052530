import React from "react";
import { connect } from "react-redux";
import { actions } from "Orders/TicketsPasses";
import { isAddAnotherBtnDisabled } from "Orders/TicketsPasses/selectors";
import {
  Div,
  BigOutlineButton,
  BigFilledButton,
  AddCircleIcon,
  MoneyIcon,
  DownIcon
} from "components/Base";
import { withProps } from "utils/General";

const MoneyIconBlack = withProps({
  color: "black"
})(MoneyIcon);

const decorate = connect(
  state => ({
    isAddAnotherBtnDisabled: isAddAnotherBtnDisabled(state)
  }),
  {
    addPass: () => actions.updateAddPass(),
    showAssignModal: () => actions.showAssignModal(),
    showCategoryModal: () => actions.setCategoryModalVisible(true)
  }
);

const BottomButtons = ({
  addPass,
  showAssignModal,
  showCategoryModal,
  isAddAnotherBtnDisabled
}) => (
  <Div width={1} display="row">
    <BigOutlineButton
      LeftIcon={AddCircleIcon}
      onClick={addPass}
      disabled={isAddAnotherBtnDisabled}
    >
      Add Another
    </BigOutlineButton>
    <BigOutlineButton
      LeftIcon={AddCircleIcon}
      onClick={showCategoryModal}
      mx={2}
    >
      Add Category
    </BigOutlineButton>
    <BigFilledButton
      LeftIcon={MoneyIconBlack}
      bg="neutral1"
      color="primary7"
      onClick={showAssignModal}
      RightIcon={DownIcon}
    >
      Quick Assign Prices
    </BigFilledButton>
  </Div>
);

export default decorate(BottomButtons);
