import React from "react";
import { connect } from "react-redux";

import { Div } from "components/Base";

import { noop } from "utils/General";

import Sidebar from "Passes/Confirmations/View/Sidebar";
import Table from "Passes/Confirmations/View/Table";
import PaginationBar from "Passes/Confirmations/View/PaginationBar";
import MiniPassModal from "Passes/Confirmations/View/MiniPassModal";
import MiniSourceModal from "Passes/Confirmations/View/MiniSourceModal";
import MiniUserModal from "Passes/Confirmations/View/MiniUserModal";
import TopBar from "Passes/Confirmations/View/TopBar";

import LoadingOverlay from "ui-kit/LoadingOverlay";
import FiltersOn from "ui-kit/FiltersOn";

import { getters } from "Passes/Confirmations";
import { getFiltersOn } from "Passes/Confirmations/selectors";

import { actions as FiltersActions } from "ui-kit/FiltersPanel";
import { TABLE_VIEW_ID } from "Passes/Common/constants";

const decorate = connect(
  state => ({
    filtersOn: getFiltersOn(state),
    loading: getters.loading(state)
  }),
  {
    onClearFilters: () => FiltersActions.clearFilters()
  }
);

const Layout = ({
  groupTypes,
  attendeeTypes,
  filtersOn = false,
  onClearFilters = noop,
  loading = false,
  handlers = {}
}) => (
  <Div
    display="row.center.stretch"
    style={{ minHeight: "100%", height: "100%" }}
  >
    <Sidebar attendeeTypes={attendeeTypes} groupTypes={groupTypes} />
    <Div flex={1} display="column.flex-start">
      <TopBar handlers={handlers} />
      <FiltersOn
        on={filtersOn}
        onClearFilters={onClearFilters}
        caption="Only showing results that contain or match filters"
      />
      <Div
        style={{ overflowY: loading ? "hidden" : "auto", position: "relative" }}
        flex={1}
        id={TABLE_VIEW_ID}
      >
        <Table handlers={handlers} />
        {(loading && <LoadingOverlay />) || null}
      </Div>
      <PaginationBar />
    </Div>
    <MiniPassModal />
    <MiniSourceModal />
    <MiniUserModal />
  </Div>
);

export default decorate(Layout);
