import PropTypes from "prop-types";
import React, { Component } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import autobind from "autobind-decorator";
import { get, isEqual, sortBy } from "lodash";

import Field from "./Field";
import NewField, { NEW_FIELD_TYPES } from "./NewField";
import { MODES } from "ui-kit/Datagrid/constants";
import { FIELD_TYPES } from "Submission/Editor/constants";

import hiddenFields from "components/Event/FormsV2/Utils/field-blacklist";
import getFormVersion from "components/Event/FormsV2/Utils/get-form-version";
import getModuleFieldId from "components/Event/FormsV2/Utils/get-module-field-id";

import { actions } from "Submission/Editor";
import * as SubmissionSelectors from "Submission/Editor/selectors";
import * as UserSelectors from "redux/modules/user/selectors";

const HIDDEN_FIELDS = [
  ...hiddenFields.filter(
    id => !["order-items", "order-fulfillment", "order-customer"].includes(id)
  )
];

import { Div } from "components/Base";

const decorate = connect(
  state => ({
    user: UserSelectors.user(state),
    details: SubmissionSelectors.eventDetails(state),
    submission: SubmissionSelectors.submission(state),
    form: SubmissionSelectors.form(state)
  }),
  {
    addValue: actions.addValue
  }
);

class Fields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldBeingEdited: null
    };
  }

  onChange = ({ id }, value) => {
    if (!isEqual(this.props.submission.values[id], value)) {
      this.props.addValue({
        version: getFormVersion(this.props.form),
        submissionRecordId: this.props.submission.submission_record_id,
        moduleFieldId: getModuleFieldId(id, this.props.form.fields),
        submissionId: this.props.submission.id,
        fieldId: id,
        value,
        userId: this.props.user.id
      });
    }
  };

  getFieldsToShow(fields) {
    return sortBy(fields, "order").filter(f => !HIDDEN_FIELDS.includes(f.type));
  }

  @autobind
  closeField() {
    this.setState({
      fieldBeingEdited: null
    });
  }

  @autobind
  editField(field) {
    if (!this.state.fieldBeingEdited) {
      this.setState({
        fieldBeingEdited: field.id
      });
    }
  }

  render() {
    const { readOnly, details, submission } = this.props;
    const values = submission.values;
    const fieldsToShow = this.getFieldsToShow(
      get(this.props, "form.fields", [])
    );

    return (
      <Div width={1}>
        {fieldsToShow.map(field => {
          if (R.contains(field.type, NEW_FIELD_TYPES)) {
            return (
              <NewField
                key={field.id}
                mode={MODES.SUBMISSION_MODAL}
                submission={submission}
                getMetaData={this.props.getMetaData}
                field={field}
                readOnly={readOnly}
                prependApproval={true}
              />
            );
          }

          return (
            <Field
              key={field.id}
              readOnly={readOnly}
              isEditing={field.id === this.state.fieldBeingEdited}
              eventDetails={details}
              editField={() => this.editField(field)}
              closeField={this.closeField}
              changeField={value => this.onChange(field, value)}
              value={values ? values[field.id] : undefined}
              values={values}
              fields={this.props.form.fields}
              field={field}
              metaData={this.props.getMetaData(
                fieldsToShow,
                submission,
                values,
                {
                  ...field,
                  settings: {
                    suppressLinkToRecord: true
                  }
                }
              )}
            />
          );
        })}
      </Div>
    );
  }
}

Fields.propTypes = {
  details: PropTypes.object.isRequired,
  submission: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  showAdminFields: PropTypes.bool,
  readOnly: PropTypes.bool,
  addValue: PropTypes.func.isRequired,
  getMetaData: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default decorate(Fields);
