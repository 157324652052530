import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "./constants";

import { currencOptions } from "components/Global/Table3/ModalColumn/CurrencySettings";

import getColumnTypes from "components/Event/Settings/Module/utils/get-column-types";

const iniState = {
  loading: true,
  editing: false,
  orgId: "",
  eventId: "",
  moduleId: "",
  name: "",
  type: "text",
  description: "",
  fieldGroup: 0,
  field: null,
  parentFields: [],
  childFields: [],
  mappedFields: [],
  error: null,
  isAdminField: false,
  adminFieldIsAdminOnly: "false",
  readOnly: false,
  isRequired: false,
  textSettings: {},
  dropdownSettings: {},
  dateTimeSettings: {
    useDateTimeTimezone: false,
    format: "LLL"
  },
  dateSettings: {
    displayType: "calendar",
    showTimepicker: false,
    dateFormat: "MMMM Do, YYYY",
    timeFormat: "h:mm a"
  },
  percentSettings: {
    percentFormat: "0%"
  },
  calculatedNumberSettings: {
    calculatedNumberExpression: "",
    readOnly: true
  },
  currencySettings: {
    ...currencOptions.USD
  },
  cateringSettings: {
    allowMultiple: false
  },
  lookupSettings: {},
  referenceSettings: {},
  phoneSettings: {
    phoneDisplayType: "mask"
  },
  userSettings: {
    allowMultiple: true,
    source: ["team", "groups"]
  },
  fileSettings: {
    allowedFileTypes: [],
    allowMultiple: false
  },
  fieldId: "",
  oldFieldGroup: null,
  maxLength: null,
  fields: [],
  fieldGroups: []
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    handleSave: R.identity,
    updateFields: (_, { payload: { fields, fieldGroups } }) => ({
      fields,
      fieldGroups
    }),
    setInitialData: (
      _,
      { payload: { moduleId, orgId, eventId, fields, fieldGroups } }
    ) => {
      const fieldTypes = getColumnTypes(moduleId);

      const dropdownColumnType = R.find(
        field => field.id === "dropdown",
        fieldTypes
      );

      const textColumnType = R.find(field => field.id === "text", fieldTypes);
      return {
        editing: false,
        textSettings: {
          enableReadOnly: R.has("settings", dropdownColumnType)
            ? dropdownColumnType.settings.enableMultipleSelect
            : false,
          readOnly: false
        },
        dropdownSettings: {
          order: {},
          options: [],
          enableMultipleSelect: R.has("settings", textColumnType)
            ? textColumnType.settings.enableReadOnly
            : false,
          allowMultipleSelect: false
        },
        moduleId,
        orgId,
        eventId,
        fields,
        fieldGroups
      };
    },
    setInitialEditData: (
      _,
      { payload: { moduleId, orgId, eventId, fieldId, fields, fieldGroups } }
    ) => {
      const moduleField = fields.find(field => field.id === fieldId) || {};

      const oldFieldGroup = fieldGroups.find(group =>
        group.fields.some(f => f.field_id === moduleField.id)
      );

      const fieldType = R.path(["settings", "isReferenceField"], moduleField)
        ? "reference"
        : R.path(["type"], moduleField);

      const field = {
        ...moduleField,
        type: fieldType
      };

      const fieldTypes = getColumnTypes(moduleId);

      const textColumnType = R.find(({ id }) => id === "text", fieldTypes);

      const textSettings = {
        enableReadOnly: R.pathOr(
          false,
          ["textColumnType", "settings", "enableReadOnly"],
          textColumnType
        ),
        readOnly: false
      };

      const dateSettings = {
        displayType: "calendar",
        showTimepicker: false,
        dateFormat: "M/D/YYYY",
        timeFormat: "h:mm a"
      };

      const dateTimeSettings = {
        useDateTimeTimezone: true,
        format: "LLL"
      };

      const dropdownSettings = {
        options: [],
        enableMultipleSelect: R.compose(
          R.pathOr(false, ["settings", "enableMultipleSelect"]),
          R.find(({ id }) => id === "dropdown")
        )(fieldTypes),
        allowMultipleSelect: false
      };

      const options = R.pathOr([], ["settings", "options"], field);

      const phoneSettings = {
        phoneDisplayType: "international" // For backwards compatability
      };

      const fileSettings = {
        allowedFileTypes: [],
        allowMultiple: false
      };

      const cateringSettings = {
        allowMultiple: false
      };

      return {
        editing: true,
        calculatedNumberSettings:
          R.propEq("type", "calculated-number", field) &&
          R.has("settings", field)
            ? field.settings
            : {},
        cateringSettings:
          field.type === "catering" && field.settings
            ? { ...cateringSettings, ...field.settings }
            : cateringSettings,
        currencySettings:
          field.type === "currency" && field.settings ? field.settings : {},
        dateSettings:
          field.type === "date" && field.settings
            ? { ...dateSettings, ...field.settings }
            : dateSettings,
        dateTimeSettings:
          field.type === "datetime" && field.settings
            ? { ...dateTimeSettings, ...field.settings }
            : dateTimeSettings,
        description: R.pathOr("", ["settings", "description"], field),
        maxLength: R.pathOr("", ["settings", "maxLength"], field),
        dropdownSettings:
          field.type === "dropdown" && !R.isEmpty(options)
            ? {
                ...dropdownSettings,
                ...field.settings,
                options: R.filter(o => o.value, options)
              }
            : dropdownSettings,
        fieldGroup: R.propOr("", "id", oldFieldGroup),
        lookupSettings:
          field.type === "lookup" && field.settings ? field.settings : {},
        referenceSettings:
          field.type === "reference" && field.settings ? field.settings : {},
        name: field.name,
        percentSettings:
          field.type === "percent" && field.settings ? field.settings : {},
        phoneSettings:
          field.type === "phone" && field.settings
            ? { ...phoneSettings, ...field.settings }
            : phoneSettings,
        textSettings:
          field.type === "text" && field.settings
            ? { ...textSettings, ...field.settings }
            : textSettings,
        type: field.type,
        moduleId,
        orgId,
        eventId,
        fieldId,
        oldFieldGroup,
        fileSettings:
          field.type === "file" && field.settings
            ? { ...fileSettings, ...field.settings }
            : fileSettings,
        fields,
        fieldGroups
      };
    },
    setIds: (_, { payload: { orgId, eventId, moduleId } }) => ({
      orgId,
      eventId,
      moduleId
    }),
    updateSettings: (state, { payload: { key, settings } }) => ({
      [key]: {
        ...settings
      }
    }),
    handleField: (_, { payload: { field, value } }) => ({
      [field]: value,
      error: null
    }),
    handleTypeChange: (_, { payload: { type } }) => ({
      type,
      parentFields: [],
      childFields: [],
      error: null
    }),
    updateFileSettings: (state, { payload: { field, value } }) => ({
      fileSettings: {
        ...state.fileSettings,
        [field]: value
      }
    }),
    updateMaxLength: (state, { payload: value }) => {
      const numberMatch = value.match(/\d+/g) || [];
      return {
        maxLength: R.isEmpty(value) ? value : numberMatch.join("")
      };
    }
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
