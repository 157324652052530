import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { actions } from "ui-kit/Form/model";
import { getValue } from "ui-kit/Form/selectors";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";

import { Div, MediumFilledButton } from "components/Base";

import { noop } from "utils/General";

const decorate = R.compose(
  WithFormInstanceConsumer({ fieldType: FIELD_TYPES.IMAGE }),
  connect(
    (state, props) => ({
      value: getValue(state, props)
    }),
    bindInstance({
      setIniValue: actions.setIniValue,
      showFilePicker: actions.showFilePicker,
      setFieldValue: actions.setFieldValue
    })
  )
);

const ImageEditor = ({
  value,
  iniValue,
  setIniValue = noop,
  showFilePicker = noop,
  fieldType,
  fieldId,
  containerWidth = 680,
  containerProps = {},
  imageWidth = 198,
  imageHeight = 57,
  buttonColor = "neutral5",
  buttonBg = "neutral1",
  buttonWidth = 194,
  recommendedWidth = "1500",
  recommendedHeight = "500",
  setFieldValue
}) => {
  useEffect(() => {
    setIniValue(
      { only: iniValue },
      {
        meta: { fieldType, fieldId }
      }
    );
  }, []);

  return (
    <Div
      display="row.flex-start.center"
      width={containerWidth}
      {...containerProps}
    >
      <Div
        width={imageWidth}
        height={imageHeight}
        bg="neutral2"
        style={{
          flexShrink: 0,
          backgroundImage: R.path([0, "url"], value)
            ? `url("${R.path([0, "url"], value)}")`
            : "inherit",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
          backgroundSize: "cover"
        }}
      />
      <Div display="column.flex-start.flex-start" ml={2}>
        <MediumFilledButton
          width={buttonWidth}
          bg={buttonBg}
          color={buttonColor}
          mb={2}
          onClick={() =>
            showFilePicker(
              {
                multiple: false,
                accept: ["image/jpeg", "image/png"]
              },
              { meta: { fieldId, fieldType } }
            )
          }
        >
          Upload New
        </MediumFilledButton>
        <div style={{ fontSize: "13px", color: "rgba(65, 65, 65, 0.93)" }}>
          Recommended: {recommendedWidth} w x {recommendedHeight} h
        </div>
        {R.path([0, "url"], value) ? (
          <div
            onClick={() =>
              setFieldValue(
                { only: [] },
                {
                  meta: { fieldType, fieldId }
                }
              )
            }
            style={{
              width: "100%",
              fontSize: "13px",
              color: "#823dd1",
              cursor: "pointer",
              textDecoration: "underline"
            }}
          >
            Clear Value
          </div>
        ) : null}
      </Div>
    </Div>
  );
};

export const Image = decorate(ImageEditor);
