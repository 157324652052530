import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import IssuanceModalController from "./Controller";
import { showModal } from "redux/modules/modal/actions";

import {
  getRecordWithIssuance,
  invalidateRecordWithIssuance,
  revertCheckin,
  checkin
} from "redux/modules/passesCheckin/actions";
import { recordWithIssuance } from "redux/modules/passesCheckin/selectors";

function mapStateToProps(state, props) {
  const record = recordWithIssuance(
    state,
    `${props.recordId}_${props.variantId}`
  );

  return {
    showModal,
    issuanceType: record ? record.issuance_type : null,
    record
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      getRecordWithIssuance,
      checkin,
      revertCheckin,
      invalidateRecordWithIssuance: () =>
        invalidateRecordWithIssuance({
          recordId: props.recordId,
          variantId: props.variantId
        })
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IssuanceModalController);
