import React from "react";
import { Link } from "react-router";
import PropTypes from "prop-types";
import {
  Div,
  Text1,
  Popover,
  Text2,
  FontIcon,
  Text3,
  Text4,
  BigFilledInput,
  SearchIcon,
  BigFilledButton,
  AddIcon,
  SuperFilledButton,
  InfoIcon
} from "components/Base";
import Background from "components/Event/FormsV2/Sections/FormBackground";
import { withProps, defaultProps } from "utils/General";
import { initials } from "./utils";
import Avatar from "./ScalableAvatar";
import { SecondaryButton } from "./Buttons";
import PersonCard from "./PersonCard";
import FindPerson from "./Popovers/FindPerson";
import { Navbar, NavText, Navatar } from "./Navbar";

const TinyText = withProps({ style: { fontSize: 10, flexShrink: 0 } })(Div);

const getStatusBaseColor = (remaining, total) => {
  if (remaining === 0) return "danger";
  if (remaining <= Math.floor(total / 3)) return "warning";
  return "success";
};

const User = ({
  isAdmin,
  name,
  accountName,
  portalPath,
  portalLoginPath,
  adminPath
}) => {
  const AlignRightDiv = withProps({ display: "row.flex-end.center" })(Div);
  const Title = withProps({ bold: true })(NavText);
  const ReturnIcon = () => (
    <i
      className="material-icons"
      style={{
        color: "rgba(255, 255, 255, 0.35)",
        marginRight: 8,
        fontSize: 20
      }}
    >
      reply
    </i>
  );

  if (!name) {
    return (
      <AlignRightDiv>
        <Link to={{ pathname: portalLoginPath }}>
          <Title>Login</Title>
        </Link>
      </AlignRightDiv>
    );
  }

  if (isAdmin) {
    return (
      <AlignRightDiv>
        <ReturnIcon />
        <a href={adminPath} style={{ color: "white" }}>
          <NavText>Back to admin mode</NavText>
        </a>
        <NavText px={5}>{accountName}</NavText>
        <NavText>{name}</NavText>
        <Navatar content={initials(name)} />
      </AlignRightDiv>
    );
  }
  return (
    <AlignRightDiv>
      <ReturnIcon />
      <Link to={{ pathname: portalPath }} style={{ color: "white" }}>
        <NavText>Go to portal</NavText>
      </Link>
      <NavText px={5}>{accountName}</NavText>
      <NavText>{name}</NavText>
      <Navatar content={initials(name)} />
    </AlignRightDiv>
  );
};

const Well = defaultProps({ bg: "neutral0", bra: 2, p: 2 })(Div);

const NoPeople = ({ onActionClick, searchTerm, isLocked }) => (
  <Div display="column.center.center" py={4} mb={7}>
    {searchTerm.length ? (
      <Div py={2} display="inline-flex.center.center">
        <Text3>No results found for</Text3>
        <Text3 bold>
          &nbsp;
          {searchTerm}.
        </Text3>
      </Div>
    ) : (
      <Div display="column.center.center">
        <img
          src="https://d2dks4tzxs6xee.cloudfront.net/img/faces.png"
          width="180"
          height="100"
          alt="Setup"
        />
        <Text4 bold mt={4} mb={1} color="gray7">
          No people added yet.
        </Text4>
        <Text3
          maxWidth={250}
          style={{ textAlign: "center" }}
          color="gray7"
          mb={4}
        >
          Once you add a person, you'll be able to assign them things.
        </Text3>
      </Div>
    )}
    {!isLocked && !searchTerm.length ? (
      <SuperFilledButton
        color="white"
        bg="blue6"
        onClick={onActionClick}
        LeftIcon={AddIcon}
      >
        Add Person
      </SuperFilledButton>
    ) : null}
  </Div>
);
const CreateFrom = ({ onCopy, people }) => (
  <Popover
    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
    Label={({ onClick }) => (
      <SecondaryButton onClick={onClick} label="Create from..." />
    )}
  >
    {({ closePopover }) => (
      <FindPerson
        list={people}
        onSelect={id => {
          closePopover();
          onCopy(id);
        }}
      />
    )}
  </Popover>
);
const View = ({
  isEnabled,
  accountName,
  availableItemList,
  backgroundUrl,
  list,
  onAddPerson,
  onCreateFrom,
  onSearchChange,
  portalLoginPath,
  adminPath,
  portalPath,
  portalTitle,
  resourceTypeIds,
  searchTerm,
  user,
  isLocked,
  title,
  message
}) => (
  <Div display="column.flex-start.center" flex={1}>
    <Background backgroundUrl={backgroundUrl} style={{ height: 328 }} />
    <Navbar>
      <NavText fw={3} color="white">
        {portalTitle}
      </NavText>
      <User
        {...user}
        portalPath={portalPath}
        portalLoginPath={portalLoginPath}
        adminPath={adminPath}
        accountName={accountName}
      />
    </Navbar>
    <Div
      bg="white"
      brt={2}
      display="row.flex-start.stretch"
      flex={1}
      maxWidth={1386}
      pb={[3, 0]}
      shadow={1}
      width={0.9}
      style={{
        flexWrap: "wrap",
        zIndex: 1,
        marginTop: 250,
        position: "relative"
      }}
    >
      <Div flex={1} p={5} pr={3}>
        <Div pb={3} pl={1}>
          <Text2 color="gray6">{portalTitle} / Assignment Manager</Text2>
          <Div fw={4} style={{ fontSize: 36 }} color="gray9">
            {accountName}
          </Div>
          {(isEnabled || isLocked) && (
            <>
              {isLocked && (
                <Div
                  bg="warning3"
                  fw={3}
                  p={5}
                  bra={1}
                  mt={2}
                  mb={4}
                  display="row.center.center"
                  maxWidth={590}
                  width={1}
                >
                  <InfoIcon fs={5} size={20} mr={3} color="gray7" />
                  <Div color="gray7">
                    Your event admin has turned off the ability to add or update
                    people. Please reach out to them if you need to make any
                    changes.
                  </Div>
                </Div>
              )}
              {!isEnabled && (
                <Div
                  bg="warning3"
                  fw={3}
                  p={5}
                  bra={1}
                  mt={2}
                  mb={4}
                  display="row.center.center"
                  maxWidth={590}
                  width={1}
                >
                  <InfoIcon fs={5} size={20} mr={3} color="gray7" />
                  <Div color="gray7">
                    Your event admin has not enabled this guest list. Please
                    reach out to them for more information.
                  </Div>
                </Div>
              )}
            </>
          )}
        </Div>
        {isEnabled && (
          <Well>
            {(title && title.length) || (message && message.length) ? (
              <Div
                px={5}
                pt={5}
                pb={0}
                mt={0}
                mb={4}
                maxWidth={590}
                width={1}
                display="column"
              >
                {title && title.length ? (
                  <Div
                    fs={5}
                    fw={4}
                    color="gray7"
                    style={{
                      marginBottom: "-10px"
                    }}
                  >
                    {title}
                  </Div>
                ) : null}

                {message && message.length ? (
                  <Div color="gray7" className="lennd-wysiwyg-content">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: message
                      }}
                    />
                  </Div>
                ) : null}
              </Div>
            ) : null}
            <Div
              display={[
                "column.flex-start.flex-start",
                "column.flex-start.flex-start",
                "row.flex-start.center"
              ]}
            >
              {list.length || searchTerm.length ? (
                <Div display="row.space-between.center" flex={1}>
                  <Div display="row.flex-start.center">
                    {!isLocked ? (
                      <BigFilledButton
                        color="white"
                        bg="blue6"
                        onClick={onAddPerson}
                        LeftIcon={AddIcon}
                        mr={3}
                      >
                        <Div fw={3}>Add Person</Div>
                      </BigFilledButton>
                    ) : null}
                    <BigFilledInput
                      bg="neutral1"
                      color="gray6"
                      continuous
                      onChange={onSearchChange}
                      placeholder="Search"
                      RightIcon={SearchIcon}
                      rightIconColor="gray6"
                      style={{
                        width: 385,
                        color: "#727272"
                      }}
                    />
                  </Div>
                  {isLocked ? null : (
                    <CreateFrom onCopy={onCreateFrom} people={list} />
                  )}
                </Div>
              ) : isLocked ? null : (
                <SecondaryButton onClick={onAddPerson} label="Add Person" />
              )}
            </Div>
            <Div py={2}>
              {list.length ? (
                <Div pt={3}>
                  {list.map(person => (
                    <PersonCard
                      key={person.id}
                      color={person.color}
                      daysOnSite={person.daysOnSite}
                      email={person.email}
                      id={person.id}
                      initials={person.initials}
                      list={person.assignedItems}
                      name={person.name}
                      onAssign={person.onChange}
                      onCopy={person.onCopy}
                      onDuplicate={person.onDuplicate}
                      onEdit={person.onEdit}
                      onRemove={person.onRemove}
                      isRemoving={person.isRemoving}
                      people={list.filter(p => p.id !== person.id)}
                      rawAssignments={person.rawAssignments}
                      rawMember={person.rawMember}
                      resourceTypeIds={resourceTypeIds}
                      disabled={isLocked}
                    />
                  ))}
                </Div>
              ) : (
                <Div display="row.center.center" flex={1}>
                  <NoPeople
                    onActionClick={onAddPerson}
                    searchTerm={searchTerm}
                    isLocked={isLocked}
                  />
                </Div>
              )}
            </Div>
            {!isLocked && list.length ? (
              <Div display="row.space-between.center">
                <SecondaryButton onClick={onAddPerson} label="Add Person" />
                <CreateFrom onCopy={onCreateFrom} people={list} />
              </Div>
            ) : null}
          </Well>
        )}
      </Div>
      {isEnabled && (
        <Well
          width={345}
          style={{ flexDirection: "column" }}
          p={5}
          ml={3}
          mr={[2, 0]}
        >
          {availableItemList.map(({ label, icon, items, color }) => (
            <Div key={label} pb={4}>
              <Div display="row.flex-start.center" pb={3}>
                <Avatar
                  size={27}
                  style={{ backgroundColor: color }}
                  mr={2}
                  content={
                    <FontIcon
                      style={{ fontSize: 16, color: "rgba(255,255,255,0.6" }}
                    >
                      {icon}
                    </FontIcon>
                  }
                />
                <Div
                  fw={3}
                  color="gray9"
                  style={{
                    textTransform: "uppercase",
                    fontSize: 12,
                    letterSpacing: ".5px"
                  }}
                >
                  {label}
                </Div>
              </Div>
              {!items.length ? <Text1>No available items</Text1> : null}

              <Div mb={3}>
                {items.map(section => (
                  <Div mb={2} key={section.id}>
                    {section.list.map(listItem => (
                      <Div
                        key={listItem.label}
                        bg="gray2"
                        bra={1}
                        mb={1}
                        p={2}
                        display="row.flex-start.center"
                        style={{
                          backgroundColor: "#F0F0F0"
                        }}
                      >
                        <Div
                          pill
                          mr={2}
                          style={{
                            backgroundColor: listItem.color,
                            flexShrink: 0,
                            height: 8,
                            width: 8
                          }}
                        />
                        <Div
                          fw={3}
                          fs={1}
                          flex={1}
                          color="gray7"
                          style={{
                            fontSize: 13
                          }}
                        >
                          {listItem.label}
                          {listItem.sublabel ? ` / ${listItem.sublabel}` : null}
                        </Div>
                        <TinyText
                          fw={3}
                          bg={`${getStatusBaseColor(
                            listItem.remaining,
                            listItem.total
                          )}1`}
                          color={`${getStatusBaseColor(
                            listItem.remaining,
                            listItem.total
                          )}7`}
                          bra={1}
                          py={1 / 2}
                          px={2}
                        >
                          {listItem.remaining} of {listItem.total}
                        </TinyText>
                      </Div>
                    ))}
                  </Div>
                ))}
              </Div>
            </Div>
          ))}
        </Well>
      )}
    </Div>
  </Div>
);

View.propTypes = {
  accountName: PropTypes.string,
  backgroundUrl: PropTypes.string,
  portalTitle: PropTypes.string,
  user: PropTypes.shape({
    isAdmin: PropTypes.bool,
    name: PropTypes.string
  }).isRequired
};

export default View;
