import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions } from "../model";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import moment from "moment";

import CollapsablePanel from "ui-kit/CollapsiblePanel";
import ProgressBar from "ui-kit/ProgressBar";
import { Text3, Text2, Div, DownIcon, FontIcon } from "components/Base";

import { ActionItem } from "PortalHub/ActionComponents/View";

import { CAPTION_STATES } from "PortalHub/ActionComponents/constants";
import { CARD_ACTIONS } from "../constants";
import { noop, withProps } from "utils/General";

const mapActionItemStatus = ({ status, required, pastDue }) => {
  if (required && R.contains(status, ["not_submitted", "incomplete"])) {
    if (pastDue) {
      return "alert_error";
    }
    return "alert";
  }
  if (R.contains(status, ["approved", "pending", "complete"])) {
    return "done";
  }
  return "not_required";
};

const documentButtons = [
  { label: "Submit", id: CARD_ACTIONS.SUBMIT_DOCUMENT, primary: true }
];

const decorate = R.compose(
  connect(
    null,
    {
      openDocument: actions.openDocument
    }
  ),
  CSSModules(css)
);

const ClosedIcon = withProps({
  transition: "fast",
  style: {
    transform: "rotate(-90deg)"
  }
})(DownIcon);

const arrowProps = {
  sizeWFS: 6,
  ml: 2,
  color: "neutral7"
};

const DocumentContainer = ({ data, openDocument = noop }) => {
  const percentage =
    (R.propOr(0, "submitted_documents", data) /
      R.propOr(1, "total_documents", data)) *
    100;
  return (
    <CollapsablePanel
      isArrowVisible={false}
      collapsedDefault
      arrowColor="gray7"
      bg="white"
      heading={collapsed => (
        <Div width={1} display="row.space-between.center">
          <Text3 display="row.flex-start.center" color="neutral7">
            {R.prop("title", data)}
            {collapsed ? (
              <ClosedIcon {...arrowProps} />
            ) : (
              <DownIcon {...arrowProps} />
            )}
          </Text3>
          <Div display="column.flex-start.stretch" width={0.4}>
            <Text2>{`${percentage}% of Documents Submitted`}</Text2>
            <ProgressBar progress={percentage / 100} mt={1} />
          </Div>
        </Div>
      )}
      width={1}
      mt={4}
      mb={4}
    >
      <div styleName="content">
        {R.map(
          document => (
            <>
              <ActionItem
                key={document.id}
                title={document.title}
                caption={`${
                  document.status === "not_submitted"
                    ? "Not submitted"
                    : "Submitted"
                } • Due ${
                  moment().isBefore(document.due_raw) ? "in" : ""
                } ${moment(document.due_raw).fromNow()}`}
                captionState={
                  document.required
                    ? CAPTION_STATES.REQUIRED
                    : CAPTION_STATES.OPTIONAL
                }
                icon={
                  document.status === "not_submitted" &&
                  moment().isAfter(document.due_raw) &&
                  !document.required
                    ? "lock"
                    : ""
                }
                status={mapActionItemStatus({
                  status: document.status,
                  required: document.required,
                  pastDue: moment().isAfter(document.due_raw)
                })}
                expandable
                buttons={documentButtons}
                id={document.id}
                data={document}
              >
                <div styleName="actions-card">
                  <Text3 bold>Due Date: </Text3>
                  <Text3>
                    {moment(document.due_raw).format("MMMM D, YYYY @ hha z")}
                  </Text3>
                  {document.description ? (
                    <>
                      <Text3 bold>Description: </Text3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: document.description
                        }}
                      />
                    </>
                  ) : null}
                  {document.file_url ? (
                    <>
                      <Text3 bold>Attatchment: </Text3>
                      <FontIcon
                        color="black"
                        fs={7}
                        onClick={() =>
                          openDocument({ url: R.prop("file_url", document) })
                        }
                      >
                        picture_as_pdf
                      </FontIcon>
                    </>
                  ) : null}
                </div>
              </ActionItem>
            </>
          ),
          R.prop(["data"], data)
        )}
      </div>
    </CollapsablePanel>
  );
};

export default decorate(DocumentContainer);
