import React from "react";
import { Div, SuperFilledButton, CheckIcon } from "components/Base";

const Success = ({ onSuccessDone }) => (
  <Div
    display="column.center.center"
    p={7}
    style={{ margin: "0 auto", maxWidth: 450 }}
  >
    <Div display="row.center.center" pill ba={3} bc="altA5" p={3} mt={3} mb={3}>
      <CheckIcon size={50} color="altA5" />
    </Div>
    <Div fs={5} fw={3} mb={2} style={{ textAlign: "center" }}>
      Your form has been created!
    </Div>
    <Div style={{ textAlign: "center" }} mb={4}>
      Get started by adding fields to your form, previewing it and then sending
      it out.
    </Div>
    <SuperFilledButton onClick={onSuccessDone} bg="altB5" mb={4}>
      Next: Review &amp; edit your form
    </SuperFilledButton>
  </Div>
);

export default Success;
