import { useEffect, useState } from "react";
import * as R from "ramda";
import moment from "moment";
import uuid from "node-uuid";

import { CALENDAR_VIEW } from "../constants";

import { Calendar } from "@fullcalendar/core";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";

import { BEGIN } from "redux-optimist";

import { noop } from "utils/General";

const mapEvent = event => ({
  id: R.path(["_def", "publicId"], event),
  resourceId: R.path(["_def", "resourceIds", 0], event),
  start: R.prop("start", event),
  end: R.prop("end", event),
  meta: event
});

export const useCalendar = ({
  selectedRange = CALENDAR_VIEW.MONTH,
  resources = [],
  events = [],
  currentDate,
  timezone,
  width = 0,
  height = "auto",
  allowEdit = true,
  eventEdit = noop,
  eventResizeStart = noop,
  eventResizeRequest = noop,
  eventDragStart = noop,
  eventDropRequest = noop,
  eventCreate = noop,
  init = noop,
  calendarRef,
  wrapperRef
}) => {
  const [overflow, setOverflow] = useState(false);

  useEffect(() => {
    if (!calendarRef.current || !wrapperRef.current) {
      return noop;
    }

    const today = moment().tz(timezone);
    const calendar = new Calendar(calendarRef.current, {
      defaultDate: moment(currentDate || today).format(),
      plugins: [
        listPlugin,
        dayGridPlugin,
        timeGridPlugin,
        resourceTimeGridPlugin,
        interactionPlugin,
        momentTimezonePlugin
      ],
      nowIndicator: true,
      selectable: allowEdit,
      allDaySlot: false,
      editable: allowEdit,
      eventStartEditable: allowEdit,
      eventDurationEditable: allowEdit,
      eventResourceEditable: allowEdit,
      defaultView: selectedRange,
      resources,
      events,
      timeZone: timezone,
      height,
      header: false,
      scrollTime: "06:00:00",
      schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
      columnHeaderHtml: date => {
        if (selectedRange === CALENDAR_VIEW.WEEK) {
          const day = moment(date).tz(timezone);
          return `<div class="custom-week-header">
                      <div class="text">${day.format("ddd")}</div>
                      <div class="text">${day.format("D")}</div>
                  </div>`;
        }
        return moment(date)
          .tz(timezone)
          .format("ddd");
      },
      resourceRender: info => {
        if (info.resource.title === "Unassigned") {
          var unassigned = document.createElement("div");
          unassigned.setAttribute("class", "resource-unassigned");
          unassigned.innerHTML = "Unassigned";
          info.el.innerHTML = "";
          info.el.appendChild(unassigned);
        }
        return "resource";
      },
      eventClick: ({ event }) => eventEdit(mapEvent(event)),
      eventResizeStart: ({ event }) => eventResizeStart(mapEvent(event)),
      eventResize: ({ event }) =>
        eventResizeRequest(mapEvent(event), {
          optimist: { type: BEGIN, id: uuid.v4() }
        }),
      eventDragStart: ({ event }) => eventDragStart(mapEvent(event)),
      eventDrop: ({ event }) =>
        eventDropRequest(mapEvent(event), {
          optimist: { type: BEGIN, id: uuid.v4() }
        }),
      select: ({ start, end, resource }) =>
        eventCreate({
          start,
          end,
          //eslint-disable-next-line no-underscore-dangle
          resource: (resource && resource._resource) || {}
        })
    });

    calendar.render();
    setOverflow(width > R.path(["current", "clientWidth"], wrapperRef));
    init({ calendar, today, selectedRange, timezone });

    return () => calendar.destroy();
  }, [resources, events]);

  return { overflow };
};
