import PropTypes from "prop-types";
import React from "react";
import autobind from "autobind-decorator";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

const DayGroup = ({
  disabled,
  groupId,
  color,
  name,
  selectAllDaysInGroup,
  deselectAllDaysInGroup
}) => (
  <div styleName="container">
    <div styleName="colorBox" style={{ backgroundColor: color }} />
    <div styleName="name">{name}</div>
    <div styleName="separator" />
    {!disabled ? (
      <div
        styleName="optionLeft"
        onClick={selectAllDaysInGroup.bind(this, groupId)}
      >
        All
      </div>
    ) : null}
    {!disabled ? (
      <div
        styleName="option"
        onClick={deselectAllDaysInGroup.bind(this, groupId)}
      >
        None
      </div>
    ) : null}
  </div>
);

DayGroup.propTypes = {
  groupId: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  selectAllDaysInGroup: PropTypes.func.isRequired,
  deselectAllDaysInGroup: PropTypes.func.isRequired
};

export default CSSModules(DayGroup, css);
