// Forms V2
import Forms from "components/Event/FormsV2/FormsHandler";
import FormOverview from "components/Event/FormsV2/Overview";
import Form from "components/Event/FormsV2/Form";
import FormEdit from "components/Event/FormsV2/Form/Views/Edit";
import FormPreview from "components/Event/FormsV2/Form/Views/Preview";
import FormSubmission from "components/Event/FormsV2/Form/Views/Submission";
import FormSubmissionLanding from "components/Event/FormsV2/SubmissionLanding";
import FormSubmissionLandingCallback from "components/Event/FormsV2/SubmissionLanding/Callback";

import V3FormEdit from "components/Event/FormsV2/Form/Views/V3_Edit";
import V3FormPreview from "components/Event/FormsV2/Form/Views/V3_Preview";

import FormsList from "Forms/List/View";
import FormResults from "Forms/Results2/View";

import { hot } from "App/Config/hot";

const FormV2 = {
  Forms,
  FormsList,
  FormOverview,
  Form,
  FormEdit,
  FormPreview,
  FormSubmission,
  FormSubmissionLanding,
  FormSubmissionLandingCallback,
  V3FormPreview,
  V3FormEdit,
  FormResults
};

export default hot(FormV2);
