import {
  put,
  all,
  take,
  takeEvery,
  fork,
  select,
  call
} from "redux-saga/effects";
import * as R from "ramda";

import { FIELD_IDS, FORM_ID } from "./constants";

import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { renderDropZone } from "utils/General/sagas";

import { actions, getters } from "./model";
import { registerError } from "redux/modules/errors/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import Helpers from "utils/Global/Helpers";

import { apiCall } from "App/Data/sagas";

import { getEditedField } from "./selectors";
import { actions as FormActions } from "ui-kit/Form/model";

const processUpload = function*({ file }) {
  const data = yield select(getters.data);

  const formatted = {
    ...data,
    uploaded: true,
    url: `https://content-cdn.lennd.com/${file[0].key}`,
    is_external: false,
    title: file[0].filename,
    key: file[0].key,
    filename: file[0].filename,
    mimetype: file[0].mimetype,
    size: file[0].size,
    payload: file[0],
    thumbnail_url: Helpers.isImage(file[0].mimetype)
      ? `https://content-cdn.lennd.com/${file[0].key}`
      : data.thumbnail_url
  };

  yield put(actions.setData(formatted));

  yield all(
    [
      put(
        FormActions.setFieldValue(formatted.title, {
          meta: {
            instanceId: FORM_ID,
            fieldId: FIELD_IDS.TITLE
          }
        })
      ),
      Helpers.isImage(file[0].mimetype)
        ? put(
            FormActions.setFieldValue(
              [
                {
                  url: `https://content-cdn.lennd.com/${file[0].key}`
                }
              ],
              {
                meta: {
                  instanceId: FORM_ID,
                  fieldId: FIELD_IDS.THUMBNAIL_URL
                }
              }
            )
          )
        : null
    ].filter(v => v)
  );
};

/*
const onHideAddContentModal = function*({ payload }) {
  if (!payload) {
    yield put(actions.setData({}));

    yield put(
      FormActions.clearValues(null, {
        meta: {
          instanceId: FORM_ID
        }
      })
    );
  } else {
    const data = yield select(getters.data);
    if (!data.id) {
      for (;;) {
        const file = yield call(renderDropZone, {
          multiple: false,
          fromSources: ["local_file_system"],
          container: "dropzone-container",
          accept: ["image/*", "video/*", "application/*"],
          storeTo: {
            path: "content/"
          }
        });

        yield call(processUpload, { file });

        document.getElementById("dropzone-container").innerHTML = "";
      }
    }
  }
};
*/

const init = function*({ payload: { id } = {} }) {
  try {
    const eventDetails = yield select(getEventDetails);

    if (id) {
      const { payload } = yield call(apiCall, {
        method: "get",
        url: `/virtual/event/${eventDetails.id}/content/${id}`
      });
      // payload
      yield put(
        actions.setInitialData({
          data: payload
        })
      );
    } else {
      yield put(
        actions.setInitialData({
          data: {}
        })
      );

      for (;;) {
        const file = yield call(renderDropZone, {
          multiple: false,
          fromSources: ["local_file_system"],
          container: "dropzone-container",
          accept: ["image/*", "video/*", "application/*"],
          storeTo: {
            path: "content/"
          }
        });

        yield call(processUpload, { file });

        document.getElementById("dropzone-container").innerHTML = "";
      }
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting content"
        }
      ])
    );
  }
};

const save = function*() {
  try {
    const eventDetails = yield select(getEventDetails);
    const data = yield select(getters.data);

    if (data.id) {
      // handle: put
      const toSave = {
        id: data.id,
        url: data.url,
        title: data.title,
        label: data.label,
        order: data.order,
        visible: data.visible,
        thumbnailUrl: data.thumbnail_url,
        variantIds: [],
        tags:
          data.tags && data.tags.length
            ? R.map(R.prop("value"))(data.tags)
            : [],
        description: data.description
      };

      if (!Helpers.isValidHTTPSURL(data.url)) {
        yield put(
          registerError([
            {
              system: "Please enter a valid HTTPS URL",
              user: "Please enter a valid HTTPS URL"
            }
          ])
        );
        return false;
      }

      yield call(apiCall, {
        method: "put",
        url: `/virtual/event/${eventDetails.id}/content`,
        data: {
          content: [toSave]
        }
      });

      yield put(showSnackbar({ message: "Content Updated" }));
    } else {
      // handle: post
      const toSave = {
        url: data.url,
        isExternal: false,
        title: data.title,
        label: data.label,
        key: data.key,
        filename: data.filename,
        mimetype: data.mimetype,
        size: data.size,
        payload: data.payload,
        order: data.order,
        visible: true, // data.visible,
        thumbnailUrl: data.thumbnail_url,
        variantIds: []
      };

      if (!Helpers.isValidHTTPSURL(data.url)) {
        yield put(
          registerError([
            {
              system: "Please enter a valid HTTPS URL",
              user: "Please enter a valid HTTPS URL"
            }
          ])
        );
        return false;
      }

      yield call(apiCall, {
        method: "post",
        url: `/virtual/event/${eventDetails.id}/content`,
        data: toSave
      });

      yield put(showSnackbar({ message: "Content Added" }));
    }

    yield put(actions.closeContentModal());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while saving content"
        }
      ])
    );
  }

  return true;
};

const onHideContentModal = function*() {
  yield put(
    FormActions.clearValues(null, {
      meta: {
        instanceId: FORM_ID
      }
    })
  );
};

const fieldUpdate = function*({ id, fieldId }) {
  const newValue = yield select(getEditedField, {
    instanceId: id,
    fieldId
  });

  const processedValue = R.propOr(newValue, fieldId, {
    [FIELD_IDS.THUMBNAIL_URL]: R.path([0, "url"], newValue),
    [FIELD_IDS.ORDER]: isNaN(newValue) ? null : newValue
  });

  yield put(actions.updateField({ field: fieldId, value: processedValue }));
};

const watchShowContentModal = function*() {
  yield takeEvery(actions.showContentModal.type, init);
};

const watchHideContentModal = function*() {
  yield takeEvery(actions.closeContentModal.type, onHideContentModal);
};

const watchSave = function*() {
  yield takeEvery(actions.save.type, save);
};

const watchUpdateFields = function*() {
  for (;;) {
    const {
      meta: { instanceId, fieldId }
    } = yield take(FormActions.setFieldValue.type);
    if (instanceId === FORM_ID) {
      yield call(fieldUpdate, {
        id: instanceId,
        fieldId
      });
    }
  }
};

const rootSaga = function*() {
  yield all([
    fork(watchShowContentModal),
    fork(watchHideContentModal),
    fork(watchUpdateFields),
    fork(watchSave)
  ]);
};

export default rootSaga;
