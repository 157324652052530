import * as R from "ramda";
import { createHandlers } from "redux-mvc";

const model = createHandlers({
  iniState: {
    expandedCards: []
  },
  reducers: {
    executeAction: R.identity,
    toggleExpandCard: ({ expandedCards }, { payload: cardId }) => ({
      expandedCards: R.symmetricDifference(expandedCards, [cardId])
    })
  },
  namespace: "HubActionComps"
});

const { actions, getters } = model;

export { actions, getters };

export default model;
