import PropTypes from "prop-types";
import React from "react";

const titleStyle = {
  textTransform: "uppercase",
  color: "#5D5D5D",
  fontSize: "9px",
  fontFamily: "arial"
};

const Title = ({ children }) => <p style={titleStyle}>{children}</p>;
Title.propTypes = {
  children: PropTypes.string.isRequired
};

export default Title;
