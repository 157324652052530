import * as R from "ramda";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { getValue } from "../../utils";

import OrderModal from "Orders/OrderModal/View";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

import * as DataSelectors from "App/Data/selectors";

import { showModal, hideModal } from "redux/modules/modal/actions";
import { actions } from "../../model";

const decorate = connect(
  state => ({
    references: DataSelectors.getAllRecordReferencesById(state) // @NOTE are we fetching the references?
  }),
  bindInstance({
    showModal,
    hideModal,
    onDone: actions.updateData
  })
);

class SourceSubmission extends Component {
  showSubmissionSummaryModal = () => {
    const submissionId = getValue(this.props, ["meta", "submissionId", ""]);
    this.props.showModal({
      content: (
        <OrderModal hideModal={this.props.onDone} submissionId={submissionId} />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const formId = getValue(this.props, ["meta", "formId"], "");
    const formName = R.pathOr("", [formId, "name"], this.props.references);

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          cursor: "pointer"
        }}
      >
        <i
          className="material-icons"
          style={{
            fontSize: 18,
            color: "#BFBFBF",
            marginRight: 2,
            transform: "rotateZ(135deg)"
          }}
        >
          link
        </i>
        <div
          style={{
            lineHeight: "34px",
            color: "#2F2D2D",
            fontSize: 15
          }}
          title={formName}
          onClick={this.showSubmissionSummaryModal}
        >
          {formName}
        </div>
      </div>
    );
  }
}

export default decorate(SourceSubmission);
