import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { withProps } from "utils/General";

import { getters, actions } from "Schedules/Schedule";

import {
  availableFieldsToGroupBy,
  quantityFields,
  getGroupedByField
} from "Schedules/Schedule/selectors";
import { QTY_FIELDS_GROUP_BY } from "Schedules/Schedule/constants";

import css from "../styles.scss";

import {
  Div,
  Text2,
  PopMenu,
  PopMenuCard,
  AddIcon,
  ThreeCirclesIcon,
  CheckIcon,
  UpIcon,
  CancelIcon,
  MediumFilledButton,
  MediumClearButton
} from "components/Base";

const EmptyIcon = withProps({
  px: 2
})(Div);

const OnIcon = () => (
  <Div bra={1} display="row.flex-end.center">
    <Text2
      bold
      uppercase
      p={1}
      bra={1}
      style={{
        color: "#359C49",
        backgroundColor: "#D5F9D9"
      }}
    >
      on
    </Text2>
  </Div>
);

const GroupByFieldFooter = ({ onClick, isExpanded }) => {
  const label = isExpanded ? "Less..." : "More...";
  const fieldFooterProps = {
    LeftIcon: isExpanded ? UpIcon : EmptyIcon
  };
  return (
    <MediumClearButton
      onClick={onClick}
      width={1}
      leftIconColor="neutral6"
      leftIconProps={{ mr: 2 }}
      {...fieldFooterProps}
    >
      <Text2 width={1} display="row.flex-start.center" flex={1} bold>
        {label}
      </Text2>
    </MediumClearButton>
  );
};

const GroupByFieldHeader = ({ children, anySelected }) => (
  <MediumClearButton
    width={1}
    isPadded={false}
    bg="transparent"
    LeftIcon={ThreeCirclesIcon}
    leftIconProps={{ mr: 2 }}
    leftIconColor="neutral6"
    RightIcon={anySelected ? OnIcon : null}
  >
    <Text2
      display="row.flex-start.center"
      color="gray9"
      width={1}
      flex={1}
      bold
      uppercase
      style={{
        letterSpacing: "0.75px"
      }}
    >
      {children}
    </Text2>
  </MediumClearButton>
);

const GroupByFieldItem = ({ children, isSelected, ...props }) => {
  const FieldItemComponent = isSelected
    ? MediumFilledButton
    : MediumClearButton;
  const fieldItemProps = {
    color: isSelected ? "altA3" : "gray9",
    bg: isSelected ? "altA1" : "neutral3",
    LeftIcon: isSelected ? CheckIcon : EmptyIcon,
    RightIcon: isSelected ? CancelIcon : EmptyIcon
  };
  return (
    <FieldItemComponent
      {...props}
      width={1}
      display="row.flex-start.center"
      leftIconProps={{ mr: 2 }}
      rightIconColor="neutral6"
      {...fieldItemProps}
    >
      <Text2 width={1} display="row.flex-start.center" flex={1} bold>
        {children}
      </Text2>
    </FieldItemComponent>
  );
};

const decorate = connect(
  (state, props) => ({
    fields: availableFieldsToGroupBy(state, props),
    groupedByField: getGroupedByField(state, props),
    showAllGroupByFields: getters.showAllGroupByFields(state, props),
    quantityFields: quantityFields(state, props)
  }),
  {
    showMoreFields: actions.toggleShowAllGroupByFields
  }
);

const GroupByFieldButton = ({
  groupedByField,
  isGroupByFieldSelected,
  fields,
  showAllGroupByFields,
  showMoreFields,
  selectFieldToGroupBy,
  quantityFields,
  label,
  Button
}) => {
  return (
    <PopMenu
      Label={({ onClick }) => (
        <Button
          onClick={onClick}
          height={32}
          color="white"
          className={isGroupByFieldSelected ? css.button7 : css.button6}
        >
          <AddIcon
            color="white"
            mr={1}
            style={{
              marginLeft: "-4px"
            }}
          />
          {label}
        </Button>
      )}
    >
      {({ closeMenu }) => (
        <PopMenuCard position="bottom" align="left" zIndex={20}>
          <Div width={225} p={2}>
            <GroupByFieldHeader anySelected={isGroupByFieldSelected}>
              group by
            </GroupByFieldHeader>
            {R.map(
              field => (
                <GroupByFieldItem
                  key={field.id}
                  isSelected={groupedByField === field.id}
                  onClick={() => {
                    closeMenu();
                    selectFieldToGroupBy(field.id);
                  }}
                >
                  {field.name}
                </GroupByFieldItem>
              ),
              fields
            )}
            {quantityFields > QTY_FIELDS_GROUP_BY && (
              <GroupByFieldFooter
                onClick={() => showMoreFields()}
                isExpanded={showAllGroupByFields}
              />
            )}
          </Div>
        </PopMenuCard>
      )}
    </PopMenu>
  );
};

export default decorate(GroupByFieldButton);
