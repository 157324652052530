import { BEGIN, COMMIT, REVERT } from "redux-optimist";
import uuid from "node-uuid";
import { CREATE, CREATE_COMPLETE } from "./constants";
import { ERROR as SUBMISSION_ERROR } from "../constants";
import api from "./api";

export function sendConfirmation(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: CREATE,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.post(getState().user.user.credentials, data);
      dispatch({
        type: CREATE_COMPLETE,
        payload: {
          submissionId: data.submissionId,
          confirmationId: result.confirmationId
        },
        optimist: { type: COMMIT, id: transactionId }
      });
      return result;
    } catch (error) {
      dispatch({
        type: SUBMISSION_ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}
