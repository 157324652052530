import React from "react";

import DefaultEditor from "./DefaultEditor";
import Countries from "./Countries";
import DateTime from "./DateTime";
import EventDays from "./EventDays";
import Lookup from "./Lookup";
import CatalogItem from "./CatalogItem";

const resolveEditor = {
  countries: Countries,
  date: DateTime,
  time: DateTime,
  datetime: DateTime,
  "event-days": EventDays,
  lookup: Lookup,
  "catalog-item": CatalogItem
};

const EditorField = ({ field, data, instanceId = "", "data-cy": dataCy }) =>
  React.createElement(resolveEditor[field.type] || DefaultEditor, {
    data,
    field,
    instanceId,
    "data-cy": dataCy
  });

export default EditorField;
