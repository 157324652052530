import React from "react";
import { Page, Title } from "EventLight/Common/FieldLayout";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import EmailTemplates from "OrgLight/Common/EmailTemplates/View";

const Layout = () => (
  <>
    <Page>
      <Title
        icon="group"
        title="Email Templates"
        description="Manage your email templates"
      />
      <EmailTemplates moduleId={STANDARD_MODULE_IDS.contacts.id} />
    </Page>
  </>
);

export default Layout;
