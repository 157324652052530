import { bindActionCreators } from "redux";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_MODULE_FIELDS from "@lennd/value-types/src/constants/standard-module-field-ids";
import { connect } from "react-redux";
import * as R from "ramda";
import { PeopleSetup, PeopleBlock, PeopleLoading } from "./View";
import { BagIcon } from "components/Base";
import { withState, withProps } from "utils/General";

import React, { Component } from "react";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import AddItemBlockModal from "components/Event/Settings/Credentials/Modals/AddItemBlock";
import ItemBlockModal from "components/Event/Settings/Credentials/Modals/ItemBlock";
import ModalColumnEdit from "Modules/AddEditColumnModal/View";

// import { ORDER_ITEMS_BLOCK_TYPE } from "components/Event/FormsV2/Form/Views/V3_Edit/Sidebar/constants/block-types";
import {
  // addItemBlock,
  // deleteItemBlock,
  getItemBlocks
  // updateItemBlock
} from "redux/modules/items/item-blocks/actions";
import { itemBlocks } from "redux/modules/items/item-blocks/selectors";
import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";
import { getModule } from "redux/modules/modules/module/actions";
import { refreshForm } from "redux/modules/formsV2/form/actions";
import { recordTypes } from "redux/modules/modules/recordTypes/selectors";
import { fieldGroupFields } from "redux/modules/modules/module/selectors";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import AddFieldModal from "Modules/AddEditColumnModal/View";
import { form } from "redux/modules/formsV2/form/selectors";
import {
  peopleBlock,
  peopleBlockFields,
  peopleBlockItemBlocks
} from "redux/modules/formsV2/people-blocks/selectors";
import {
  addField,
  addItemBlock,
  deleteItemBlock,
  createPeopleBlock,
  deleteField,
  getPeopleBlock,
  updateFields,
  updateItemBlocks,
  updatePeopleBlock
} from "redux/modules/formsV2/people-blocks/actions";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";
import { fieldTypeIcon } from "components/Global/Table3/FieldTypeIcons/resolve-field-type-icon";
import {
  CREDENTIAL_TYPE_ID,
  MEAL_TYPE_ID,
  INVENTORY_TYPE_ID,
  BOOTH_TYPE_ID,
  SPONSORSHIP_TYPE_ID,
  REGISTRATION_TYPE_ID
} from "utils/item-types";
const ITEM_BLOCK_TYPES = {
  [CREDENTIAL_TYPE_ID]: {
    name: "Passes",
    type: "credentials"
  },
  [MEAL_TYPE_ID]: {
    name: "Meals",
    type: "catering"
  },
  [INVENTORY_TYPE_ID]: {
    name: "Inventory",
    type: "inventory"
  },
  [BOOTH_TYPE_ID]: {
    name: "Booths",
    type: "booth"
  },
  [SPONSORSHIP_TYPE_ID]: {
    name: "Sponsorships",
    type: "sponsorship"
  },
  [REGISTRATION_TYPE_ID]: {
    name: "Registrations",
    type: "registration"
  }
};

const mapWithIndex = R.addIndex(R.map);

function mapStateToProps(state, props) {
  const formToPass = form(state);
  return {
    form: formToPass,
    eventId: formToPass.event_id,
    peopleBlock: peopleBlock(state, props.id),
    itemBlocks: itemBlocks(state),
    peopleBlockFields: peopleBlockFields(state, props.id),
    peopleBlockItemBlocks: peopleBlockItemBlocks(state, props.id),
    contactTypes: recordTypes(state, STANDARD_MODULES.contacts.id),
    contactFields: fieldGroupFields(state, STANDARD_MODULES.contacts.id).filter(
      f =>
        ![
          STANDARD_MODULE_FIELDS.CONTACTS.FIRST_NAME,
          STANDARD_MODULE_FIELDS.CONTACTS.LAST_NAME,
          ...resolveReadOnlyFields({
            moduleId: STANDARD_MODULES.contacts.id
          })
        ].includes(f.id)
    )
  };
}

const commonWrapper = func => eventId =>
  func({ moduleId: STANDARD_MODULES.contacts.id, options: { eventId } });
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal,
      hideModal,
      getItemBlocks,
      deleteItemBlock,
      addItemBlock,
      getPeopleBlock,
      deleteField,
      updateFields,
      updateItemBlocks,
      addField,
      createPeopleBlock,
      updatePeopleBlock,
      showSnackbar,
      refreshForm,
      getContactFields: commonWrapper(getModule),
      getContactTypes: commonWrapper(getRecordTypes)
    },
    dispatch
  );
}

class PeopleBlockController extends Component {
  async componentDidMount() {
    await Promise.all([
      this.props.getContactTypes(this.props.eventId),
      this.props.getContactFields(this.props.eventId),
      this.getItemBlocks()
    ]);
    if (this.props.id) {
      await this.getPeopleBlock();
    }
    this.props.setLoading(false);
    return true;
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.id && this.props.id) {
      this.getPeopleBlock();
    }
  }
  getPeopleBlock = () => {
    return this.props.getPeopleBlock(this.props.id);
  };
  getItemBlocks = () => {
    return this.props.getItemBlocks(this.props.eventId);
  };
  addFieldToBlock = async fieldId => {
    await this.props.addField({
      fieldId,
      blockId: this.props.id,
      order: this.getLastOrderPosition()
    });
    this.props.showSnackbar({ message: "Field added!" });
    this.getPeopleBlock();
    this.reloadForm();
  };
  removeFieldFromBlock = async fieldId => {
    await this.props.deleteField({ fieldId, blockId: this.props.id });
    this.getPeopleBlock();
    this.reloadForm();
  };
  addItemBlock = async itemBlockId => {
    await this.props.addItemBlock({
      itemBlockId,
      blockId: this.props.id,
      order: this.getLastOrderPosition()
    });
    this.props.showSnackbar({ message: "Block added!" });
    this.getPeopleBlock();
    this.reloadForm();
  };
  removeItemBlock = async itemBlockId => {
    await this.props.deleteItemBlock({ itemBlockId, blockId: this.props.id });
    this.getPeopleBlock();
    this.reloadForm();
  };
  handleEditField = fieldId => {
    this.props.showModal({
      content: (
        <ModalColumnEdit
          eventId={this.props.eventId}
          moduleId={STANDARD_MODULES.contacts.id}
          fieldId={fieldId}
          onSave={() => {
            this.getPeopleBlock();
            this.reloadForm();
          }}
        />
      )
    });
  };
  toggleItemBlock = async ({ required, fieldId }) => {
    const requiredFields = R.propOr(
      [],
      "required_fields",
      this.props.peopleBlock
    );
    const data = {
      blockId: R.propOr("", "id", this.props.peopleBlock),
      requiredFields: required
        ? R.filter(id => id !== fieldId, requiredFields)
        : [...requiredFields, fieldId]
    };
    await this.props.updatePeopleBlock(data);

    this.getPeopleBlock();
    this.reloadForm();
  };
  reloadForm = () => this.props.refreshForm(this.props.form.id);

  handleCreateField = () => {
    this.props.showModal({
      content: (
        <AddFieldModal
          eventId={this.props.eventId}
          moduleId={STANDARD_MODULES.contacts.id}
          onSave={() => {
            this.props.getContactFields(this.props.eventId);
          }}
        />
      )
    });
  };

  getLastOrderPosition() {
    return (
      this.props.peopleBlockFields.length +
      this.props.peopleBlockItemBlocks.length
    );
  }
  decorateSettingsForRequest = settings => {
    return {
      allowMultiple: R.path(["limit", "enabled"], settings),
      collectRole: R.path(["role", "enabled"], settings),
      collectRoleMethod: R.path(["role", "type"], settings),
      collectRoleValue: R.path(["role", "value"], settings),
      roleFieldAlias: settings.roleFieldAlias,
      collectionMode: settings.collectionMode,
      defaultPersonTypeId: settings.defaultRecordType,
      markAsPrimaryContact: settings.markAsPrimary,
      enableLookup: settings.lookupEnabled,
      isRequired: settings.isRequired,
      eventId: this.props.eventId,
      formFieldId: this.props.formFieldId,
      maxAmount: R.path(["limit", "value"], settings),
      name: settings.title,
      rowLabel: settings.rowLabel
    };
  };

  saveNewPeopleBlock = async settings => {
    await this.props.updatePeopleBlock({
      blockId: this.props.blockId,
      ...this.decorateSettingsForRequest(settings)
    });
    this.props.onCreate(this.props.blockId);
  };

  updateFieldOrder = async sortedFields => {
    const reduceIndex = R.addIndex(R.reduce);
    const fieldOrder = reduceIndex(
      (acc, field, index) => ({
        ...acc,
        [field.id]: index
      }),
      {},
      sortedFields
    );

    const data = {
      blockId: R.propOr("", "id", this.props.peopleBlock),
      fieldOrder
    };
    await this.props.updatePeopleBlock(data);
    this.getPeopleBlock();
    this.reloadForm();
  };

  handleSettingsChange = async setting => {
    if (!setting.limit || setting.limit.value !== "custom") {
      await this.props.updatePeopleBlock({
        blockId: this.props.id,
        ...this.decorateSettingsForRequest(setting)
      });
      await this.getPeopleBlock();
      this.reloadForm();
    }
    return true;
  };

  handleCreateNewItemBlock = () => {
    this.props.showModal({
      content: <AddItemBlockModal onDone={this.getItemBlocks} />,
      wrapper: ModalWrapper
    });
  };

  handleEditItemBlock = blockId => {
    this.props.showModal({
      content: (
        <ItemBlockModal
          hideModal={() => {
            this.getItemBlocks();
            this.reloadForm();
            this.props.hideModal();
          }}
          itemBlockId={blockId}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const {
      peopleBlock,
      setScene,
      isNew,
      itemBlocks,
      sceneId,
      contactFields,
      peopleBlockFields,
      peopleBlockItemBlocks,
      loading
    } = this.props;
    const sharedProps = {
      loading: loading || !this.props.contactTypes.length,
      onCancel: this.props.returnTo,
      onReturn: this.props.returnTo,
      peopleTypes: this.props.contactTypes.map(t => ({
        value: t.id,
        label: t.name
      }))
    };
    if (sharedProps.loading) {
      return <PeopleLoading />;
    }

    if (!this.props.id) {
      return (
        <PeopleSetup
          showFlashMsg
          onAdd={this.saveNewPeopleBlock}
          {...sharedProps}
        />
      );
    }

    const fieldOrder = R.propOr({}, "field_order", this.props.peopleBlock);
    const requiredFields = R.propOr(
      [],
      "required_fields",
      this.props.peopleBlock
    );

    const nameField = {
      id: "name",
      title: "First & Last Name",
      required: true,
      onHide: undefined,
      onEdit: undefined,
      order: -1,
      canDrag: false
    };

    const roleField = {
      id: "role",
      title:
        peopleBlock.role_field_alias && peopleBlock.role_field_alias.length
          ? peopleBlock.role_field_alias
          : "Role",
      onHide: undefined,
      onEdit: undefined,
      Icon: undefined,
      order: typeof fieldOrder.role === undefined ? 99 : fieldOrder.role,
      canDrag: true,
      required: R.any(id => id === "role", requiredFields),
      onToggle: this.toggleItemBlock
    };

    const peopleBlockFieldsmap = R.map(
      field => ({
        id: field.id,
        title: field.name,
        onHide: () => this.removeFieldFromBlock(field.id),
        onEdit: () => this.handleEditField(field.id),
        order: fieldOrder[field.id],
        canDrag: true,
        onToggle: this.toggleItemBlock,
        required: R.any(id => id === field.id, requiredFields)
      }),
      peopleBlockFields
    );

    const peopleBlockItemBlocksMap = mapWithIndex(
      (block, idx) => ({
        id: block.id,
        title: block.name,
        onHide: () => this.removeItemBlock(block.id),
        onEdit: () => this.handleEditItemBlock(block.id),
        onToggle: this.toggleItemBlock,
        required: R.any(id => id === block.id, requiredFields),
        Icon: withProps({ tooltip: "Item Block" })(BagIcon),
        order:
          typeof fieldOrder[block.id] === undefined
            ? 100 + idx
            : fieldOrder[block.id],
        canDrag: true
      }),
      peopleBlockItemBlocks
    );

    const selectedFields = R.sortBy(R.prop("order"))([
      nameField,
      ...peopleBlockFieldsmap,
      ...(peopleBlock.collect_role &&
      peopleBlock.collect_role_method === "collect_for_each"
        ? [roleField]
        : []),
      ...peopleBlockItemBlocksMap
    ]);
    const selectedFieldIds = R.map(R.prop("id"))(selectedFields);

    const fields = R.compose(
      R.map(field => ({
        id: field.id,
        icon: fieldTypeIcon(field.type),
        name: field.name,
        onAdd: () => this.addFieldToBlock(field.id)
      })),
      R.filter(field => !selectedFieldIds.includes(field.id))
    )(contactFields);

    const tabsWithHandlers = [
      ["fields", "Fields"],
      ["settings", "Settings"]
    ].map(([key, title]) => ({
      key,
      title,
      isActive: sceneId === key,
      onClick: () => setScene(key)
    }));

    const activeItemBlockIds = peopleBlockItemBlocks.map(b => b.id);
    const itemBlocksWithHandlers = R.compose(
      R.map(block => ({
        id: block.id,
        name: block.name,
        onAdd: () => this.addItemBlock(block.id),
        message: (
          <span>
            Type: {ITEM_BLOCK_TYPES[block.item_type_id].name} <br /> # of items:{" "}
            {block.items.length}
            <br />
            {block.limit ? `Limit: ${block.limit}` : ""}
          </span>
        ),
        onEdit: activeItemBlockIds.includes(block.id) ? () => {} : undefined
      })),
      R.filter(field => !selectedFieldIds.includes(field.id))
      // R.filter(block => {
      //   if (!ITEM_BLOCK_TYPES[block.item_type_id]) return false;
      //   if (!canAddOrderForm) {
      //     return ![ORDER_ITEMS_BLOCK_TYPE].includes(block.id);
      //   }
      //   return true;
      // })
    )(itemBlocks);

    const blockSettings = {
      title: peopleBlock.name,
      limit: {
        enabled: peopleBlock.allow_multiple,
        value: peopleBlock.max_amount
      },
      collectionMode: peopleBlock.collection_mode,
      lookupEnabled: peopleBlock.enable_lookup,
      markAsPrimary: peopleBlock.mark_as_primary_contact,
      isRequired: peopleBlock.is_required,
      role: {
        enabled: peopleBlock.collect_role,
        type: peopleBlock.collect_role_method,
        value: peopleBlock.collect_role_value
      },
      roleFieldAlias: peopleBlock.role_field_alias,
      defaultRecordType: peopleBlock.default_person_type_id,
      rowLabel: peopleBlock.row_label
    };
    return (
      <PeopleBlock
        showFlashMsg={isNew}
        itemBlocks={itemBlocksWithHandlers}
        settings={blockSettings}
        onSettingsChange={this.handleSettingsChange}
        tabs={tabsWithHandlers}
        onFieldReorder={this.updateFieldOrder}
        onCreateField={this.handleCreateField}
        onCreateItemBlock={this.handleCreateNewItemBlock}
        gotoItems={() => setScene("items")}
        selectedFields={selectedFields}
        fields={fields}
        {...sharedProps}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  R.compose(
    withState("sceneId", "setScene", "fields"),
    withState("loading", "setLoading", true)
  )(PeopleBlockController)
);
