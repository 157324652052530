import Immutable from "immutable";
import {
  RECEIVE_ORDERS,
  REQUEST_ORDERS,
  RECEIVE_ORDER,
  REQUEST_ORDER,
  INVALIDATE_ORDER
} from "./constants";
import { ERROR } from "redux/modules/errors/constants";
import { combineReducers } from "redux";

const orders = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_ORDERS:
      return Immutable.fromJS(state)
        .setIn(["byTypeId", action.payload.typeId], action.payload.orders)
        .toJS();
    default:
      return state;
  }
};

const fetchingOrders = (state = false, action) => {
  switch (action.type) {
    case REQUEST_ORDERS:
      return true;
    case RECEIVE_ORDERS:
      return false;
    default:
      return state;
  }
};

const order = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_ORDER:
      return Immutable.fromJS(state)
        .setIn(["byId", action.payload.orderId], action.payload.order)
        .toJS();
    case INVALIDATE_ORDER:
      return Immutable.fromJS(state)
        .deleteIn(["byId", action.payload.orderId])
        .toJS();
    default:
      return state;
  }
};

const fetchingOrder = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ORDER:
      return Immutable.fromJS(state)
        .setIn(["byId", action.payload.orderId], true)
        .toJS();
    case RECEIVE_ORDER:
      return Immutable.fromJS(state)
        .setIn(["byId", action.payload.orderId], false)
        .toJS();
    case ERROR:
      return {};
    default:
      return state;
  }
};

export default combineReducers({
  orders,
  fetchingOrders,
  order,
  fetchingOrder
});
