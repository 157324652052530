import * as R from "ramda";
import React, { useMemo } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import CSSModules from "react-css-modules";

import { noop } from "utils/General";

import { actions, getters } from "./model";

import { getActiveSnackbar } from "./selectors";

import { SEVERITY } from "ui-kit/Alert/constants";
import Alert from "ui-kit/Alert/View";
import { filterNilOrEmpty } from "ui-kit/utils";

import css from "./styles.scss";

const decorate = R.compose(
  connect(
    (state, props) => ({
      snackbar: getActiveSnackbar(state, props),
      show: getters.show(state, props)
    }),
    bindInstance({
      close: actions.hide
    })
  ),
  CSSModules(css)
);

const Snackbar = ({
  zIndex = 200,
  show,
  snackbar = {
    message: "",
    buttonAction: {},
    severity: SEVERITY.INFO
  },
  close = noop
}) => {
  const style = useMemo(
    () =>
      filterNilOrEmpty({
        zIndex,
        bottom: show ? 20 : -100
      }),
    [zIndex, show]
  );
  return (
    <div style={style} styleName="snackbar">
      <Alert
        severity={snackbar.severity}
        buttonAction={snackbar.buttonAction}
        data={snackbar.buttonAction?.data}
        close={close}
      >
        {snackbar.message}
      </Alert>
    </div>
  );
};

export default decorate(Snackbar);
