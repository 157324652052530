/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import React from "react";

import { put, call, all, fork, select, takeEvery } from "redux-saga/effects";
import { makeFuture } from "utils/General/sagas";

import { actions } from "Files/Manage";
import { showModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";

import { actions as fileDetailsSidebarActions } from "Files/FileDetailsSidebar/model";
import Helpers from "utils/Global/Helpers";

import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import SendEmailModal from "SendEmailModal/View";

import { SEND_FILE_BY_EMAIL } from "utils/Emails/default-emails";

const UPLOAD_FIELD_ID = "d512aeba-ae22-4623-b64c-90f293e37f16";

const showUploadFilesModal = function*() {
  const handleUpload = makeFuture();

  Helpers.getFilepicker(
    {
      multiple: true,
      mimetypes: [],
      container: "modal",
      services: ["COMPUTER", "DROPBOX", "GOOGLE_DRIVE"]
    },
    { path: "event-files/" },
    handleUpload.done
  );

  return yield call(handleUpload.onRealized);
};

// eslint-disable-next-line no-unused-vars
const uploadFiles = function*({ payload: { row } }) {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const eventId = yield select(getEventId);

  const files = yield call(showUploadFilesModal);

  const moduleFiles = yield call(recordApi.bulkPost, credentials, {
    records: files.map(file => ({
      moduleId: STANDARD_MODULE_IDS.files.id,
      record: {
        [UPLOAD_FIELD_ID]: { type: "file", value: { files: [file] } }
      }
    })),
    options: {
      orgId,
      eventId
    }
  });

  const relatedRecordIds = moduleFiles.records.map(({ id }) => id);

  yield call(accountValuesApi.bulkRelate, credentials, {
    recordIds: [row.id],
    relatedRecordIds,
    relatedModuleId: STANDARD_MODULE_IDS.files.id
  });

  yield put(showSnackbar({ message: "Files added", action: "OK" }));

  yield put(actions.fetchData());
};

const showSendEmailModal = function*({ payload: attachment }) {
  const { subject, message } = {
    SEND_FILE_BY_EMAIL
  };
  // TODO: define subject and message inside utils/Emails/default-emails
  const recipients = null;
  yield put(
    showModal({
      content: (
        <SendEmailModal
          moduleId={STANDARD_MODULE_IDS.files.id}
          attachments={[attachment]}
          selectedOptions={recipients ? [recipients] : []}
          subject={subject}
          message={message}
        />
      ),
      wrapper: ModalWrapper
    })
  );
};

const attachFileToRecords = function*() {
  // PENDING: logic for attach file to record
};

const showOnPortal = function*() {
  // PENDING: logic for show file on portal
};

const watchOnSendViaEmail = function*() {
  yield takeEvery(
    fileDetailsSidebarActions.sendViaEmail.type,
    showSendEmailModal
  );
};

const watchAttachFileToRecords = function*() {
  yield takeEvery(
    fileDetailsSidebarActions.attachToRecords.type,
    attachFileToRecords
  );
};

const watchShowOnPortal = function*() {
  yield takeEvery(fileDetailsSidebarActions.showOnPortal.type, showOnPortal);
};

const rootSaga = function*() {
  yield all([
    fork(watchOnSendViaEmail),
    fork(watchAttachFileToRecords),
    fork(watchShowOnPortal)
  ]);
};

export default rootSaga;
