import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import PageTitle from "OrgLight/Common/PageTitle";

import Loading from "ui-kit/Loading";
import { Page, Title } from "EventLight/Common/FieldLayout";
import Body from "./Body";

const decorate = connect(
  state => ({
    loading: getters.loading(state)
  }),
  {
    init: actions.init
  }
);

const Layout = ({ loading, init }) => {
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <PageTitle titles={["Billing"]} />
      <Page>
        <Title
          icon="receipt"
          title="Billing"
          description="Manage your account’s billing details."
        />

        {loading ? <Loading /> : <Body />}
      </Page>
    </>
  );
};

export default decorate(Layout);
