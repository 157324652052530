import React from "react";
import { Div } from "components/Base";
import { connect } from "react-redux";
import { getters } from "Event/TasksWidget/model";
import Header from "./Header";
import Body from "./Body";
import Loading from "ui-kit/Loading";
import ActivityDetailSidebar from "Schedules/ActivityDetailsSidebar/View";

const decorate = connect(state => ({
  loading: getters.loading(state)
}));

const Layout = ({ loading }) => (
  <Div width={1} bra={1} shadow={1} bg="white" mt={4}>
    {loading ? (
      <Loading />
    ) : (
      <Div>
        <Header />
        <Body />
      </Div>
    )}

    <ActivityDetailSidebar />
  </Div>
);

export default decorate(Layout);
