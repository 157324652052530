import inventory from "./inventory";
import catering from "./catering";
import credentials from "./credentials";
import catalogItem from "./catalog-item";
import combine from "./combine";
import { get } from "lodash";
import getFieldTypeForForms from "utils/Forms/get-field-type";
import getApprovalStatus from "utils/Forms/get-approval-status";

export const defaultHandlers = {
  getFieldType: getFieldTypeForForms,
  getStatus: getApprovalStatus
};

const subformProcessor = (
  value,
  values,
  field,
  fields,
  eventDays,
  queue,
  handlers
) => {
  const submissions = get(value, "value.submissions", {});
  Object.keys(submissions).forEach(key =>
    processSubmission(
      get(submissions[key], "values", {}),
      get(field, "subform.form.fields", []),
      eventDays,
      handlers.getStatus(submissions[key]),
      queue,
      handlers
    )
  );
};

const processors = {
  catering: (value, values, field, fields, eventDays) =>
    catering(value, eventDays),
  credentials: value => credentials(value),
  inventory: (value, values, field, fields) =>
    inventory(value, values, field, fields),
  "catalog-item": value => catalogItem(value),
  // Forms v2
  subform: subformProcessor,
  // Forms v3
  lookup: subformProcessor
};

function processSubmission(
  values,
  fields,
  eventDays,
  status,
  parentQueue,
  handlers
) {
  return fields.reduce((queue, field) => {
    const type = handlers.getFieldType(field);
    if (type in processors && values[field.id]) {
      const result = processors[type](
        values[field.id],
        values,
        field,
        fields,
        eventDays,
        queue,
        handlers
      );
      queue[type] = queue[type] || [];
      queue[type].push({ requested: result });
      if (status === "approved") {
        queue[type].push({ approved: result });
      } else if (status === "rejected") {
        queue[type].push({ rejected: result });
      }
    }
    return queue;
  }, parentQueue);
}

function getEventDays(event) {
  return get(event, "module_settings.catering.selected_days", []);
}

export function countSummary(values, fields, eventDetails, handlers) {
  const result = processSubmission(
    values,
    fields,
    getEventDays(eventDetails),
    null,
    {},
    { ...defaultHandlers, ...handlers }
  );
  const success = Object.keys(result).reduce((sums, type) => {
    sums[type] = combine(...result[type]);
    return sums;
  }, {});
  return success;
}

function parseResults(result, results) {
  Object.keys(result).forEach(key => {
    if (!results[key]) {
      results[key] = {};
    }
    Object.keys(result[key]).forEach(category => {
      if (!results[key][category]) {
        results[key][category] = {};
      }
      if (result[key][category]) {
        Object.keys(result[key][category]).forEach(itemName => {
          if (!results[key][category][itemName]) {
            results[key][category][itemName] = 0;
          }
          results[key][category][itemName] += result[key][category][itemName];
        });
      }
    });
  });
  return results;
}

export default function countSummaries(
  submissions,
  fields,
  eventDetails,
  handlers,
  results = {},
  index = 0
) {
  if (index >= submissions.length) {
    return results;
  }
  const result = countSummary(
    submissions[index].values || {},
    fields,
    getEventDays(eventDetails),
    handlers
  );
  return countSummaries(
    submissions,
    fields,
    eventDetails,
    handlers,
    parseResults(result, results),
    ++index
  );
}
