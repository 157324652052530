import React from "react";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import CollapsablePanel from "ui-kit/CollapsiblePanel";
import { Div, MediumFilledButton, AddIcon } from "components/Base";
import Checkbox from "material-ui/Checkbox";
import formatCloseDate from "components/Event/Module/Form/utils/format-close-date";
import moment from "moment";

const Group = ({
  name,
  close_date: closeDate,
  onUnassign = () => {},
  onClickDueDate = () => {}
}) => (
  <Div display="row.space-between.center" bb={1} bc="neutral1" px={2} py={3}>
    <Div display="row.flex-start.center" fs={1} fw={3} color="gray7">
      {name}
    </Div>
    <Div fs={1} display="row.flex-start.center">
      <Div
        onClick={onClickDueDate}
        color={
          closeDate && moment().isAfter(moment(closeDate))
            ? {
                default: "red6",
                hover: "red8"
              }
            : closeDate
            ? {
                default: "green6",
                hover: "green9"
              }
            : {
                default: "gray5",
                hover: "purple9"
              }
        }
      >
        {closeDate ? formatCloseDate(closeDate) : "Add due date"}
      </Div>
      <Div
        style={{
          marginLeft: 3,
          marginRight: 3
        }}
      >
        &middot;
      </Div>
      <Div
        onClick={onUnassign}
        color={{
          default: "gray5",
          hover: "purple9"
        }}
      >
        Unassign
      </Div>
    </Div>
  </Div>
);

const RecordCollapsible = ({
  recordCollapsed,
  toggleRecordCollapsed,
  recordList,
  toggleSelectedRecord,
  selectAllRecords,
  deselectAllRecords,
  title = "",
  showModal,
  btnText,
  selectedList = [],
  selectedText = "",
  unassign = () => {},
  openDueDateModal = () => {},
  type
}) => (
  <CollapsablePanel
    bg="white"
    right={false}
    bra={1}
    bc="neutral1"
    opened={recordCollapsed}
    arrowColor="gray7"
    onClick={toggleRecordCollapsed}
    heading={
      <Div
        display="row.flex-start.center"
        color="black"
        height={40}
        fw={3}
        fs={3}
      >
        {title}
      </Div>
    }
  >
    <div styleName="assignContent">
      <div styleName="item">
        <div styleName="select" onClick={selectAllRecords}>
          Add all
        </div>
        <span
          class="material-icons"
          style={{
            color: "#8d64d1",
            fontSize: 8,
            marginRight: 4,
            marginLeft: 4
          }}
        >
          fiber_manual_record
        </span>
        <div styleName="select" onClick={deselectAllRecords}>
          Remove all
        </div>
      </div>
      {R.map(
        ({ id, name, selected }) => (
          <div styleName="item">
            <Checkbox
              key={id}
              checked={selected}
              iconStyle={{ marginRight: 8 }}
              labelStyle={{
                fontWeight: 600,
                fontSize: 18,
                color: "#555",
                width: 300
              }}
              onCheck={() => toggleSelectedRecord({ id, selected })}
              label={name}
            />
          </div>
        ),
        recordList
      )}
      <Div fw={4} fs={2} color="gray7" mt={8}>
        {selectedText}
        {selectedList.length ? ` (${selectedList.length})` : null}:
      </Div>
      <Div>
        {selectedList.map(group => (
          <Group
            key={group.id}
            {...group}
            onUnassign={() => unassign(group.id)}
            onClickDueDate={() =>
              openDueDateModal({
                id: group.id,
                customCloseDate: group.close_date,
                type
              })
            }
          />
        ))}
      </Div>
      <div styleName="item">
        <MediumFilledButton
          onClick={showModal}
          color="purple8"
          bg="neutral1"
          mt={2}
          LeftIcon={AddIcon}
        >
          {btnText}
        </MediumFilledButton>
      </div>
    </div>
  </CollapsablePanel>
);

export default CSSModules(css)(RecordCollapsible);
