import Helpers from "utils/Global/Helpers";

export default {
  get: ({ credentials, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/orders/line-items/questions`,
        credentials,
        data,
        success,
        error
      });
    }),
  put: ({ credentials, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/orders/line-items/questions`,
        credentials,
        data,
        success,
        error
      });
    })
};
