import React from "react";
import { FieldEditor } from "ui-kit/FieldTypes/View";

import Row from "./Row";
import Label from "./Label";

export const Field = ({ field }) => (
  <Row>
    <Label field={field} />
    <div>
      <FieldEditor
        field={field}
        data={{
          [field.id]: {
            type: field.type
          }
        }}
      />
    </div>
  </Row>
);

export default Field;
