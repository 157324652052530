import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import moment from "moment";
import { actions, getters } from "../index";
import CollapsablePanel from "ui-kit/CollapsiblePanel";
import { Div, Text1 } from "components/Base";
import { addS } from "utils/General";
import { TypesTable } from "./Tables";
import GroupMorePopover from "./GroupMorePopover";
import createDateRanges from "components/Global/Editors/EventDaysEditor/utils/create-date-ranges";

const getTrackingMethodName = method => {
  switch (method) {
    case "barcode":
      return "Barcode";
    case "rfid":
      return "RFID";
    case "number_range":
      return "Number Range";
    default:
      return "None";
  }
};

const decorate = connect(
  state => ({
    typeId: getters.typeId(state)
  }),
  {
    deleteItem: actions.deleteItem,
    reorderItems: actions.reorderItems,
    moveItemUp: actions.moveItemUp,
    moveItemDown: actions.moveItemDown,
    moveCategoryUp: actions.moveCategoryUp,
    moveCategoryDown: actions.moveCategoryDown
  }
);

const CollapsableGroup = ({
  group,
  handlers,
  typeId,
  groupsLength,
  deleteItem,
  reorderItems,
  moveItemUp,
  moveItemDown,
  moveCategoryUp,
  moveCategoryDown
}) => (
  <CollapsablePanel
    bg="transparent"
    arrowColor="neutral7"
    mt={2}
    right={false}
    heading={() => (
      <Div display="row.space-between.center" width={1} pr={2} py={1}>
        <Div display="row.flex-start.center">
          <Div color="neutral7" fs={4} fw={4} mr={2}>
            {group.name}
          </Div>
          <GroupMorePopover
            options={[
              [
                "Edit",
                () => {
                  handlers.updateCategory(group);
                }
              ],
              [
                "Delete",
                () => {
                  handlers.deleteCategory(group);
                }
              ],
              group.order > 0
                ? [
                    "Move Up",
                    () => {
                      moveCategoryUp({ currentPosition: group.order });
                    }
                  ]
                : [],
              group.order < groupsLength - 1
                ? [
                    "Move Down",
                    () => {
                      moveCategoryDown({ currentPosition: group.order });
                    }
                  ]
                : []
            ]}
          />
        </Div>
        <Text1>{`${R.length(group.items)} type${addS(
          R.length(group.items)
        )}`}</Text1>
      </Div>
    )}
  >
    <TypesTable
      types={R.map(
        item => ({
          ...item,
          onCredentialClick: () =>
            handlers.addUpdateItem({
              typeId,
              id: item.id
            }),
          onEdit: () =>
            handlers.addUpdateItem({
              typeId,
              id: item.id
            }),
          onClone: () =>
            handlers.addUpdateItem({
              typeId,
              id: item.id,
              clone: true
            }),
          onDelete: () => {
            deleteItem({ itemId: item.id });
          },
          moveCredentialUp:
            item.order > 0
              ? () => moveItemUp({ order: item.order, groupId: group.id })
              : null,
          moveCredentialDown:
            item.order < R.length(group.items) - 1
              ? () => moveItemDown({ order: item.order, groupId: group.id })
              : null,
          quantity: R.propOr(false, "track_inventory", item.variants[0])
            ? R.propOr("—", "inventory_quantity", item.variants[0])
            : "—",
          type: R.propOr(false, "track_inventory", item.variants[0])
            ? getTrackingMethodName(
                R.propOr(false, "tracking_method", item.variants[0])
              )
            : "—",
          dates: R.propOr(null, "rules", item.variants[0])
            ? createDateRanges(
                R.map(
                  rule => moment(rule.value).format("YYYY-MM-DD"),
                  R.propOr(null, "rules", item.variants[0])
                )
              )
            : null
        }),
        group.items
      )}
      dragTypeId={"TypesTable" + group.id}
      onReorder={newOrder => {
        reorderItems(newOrder);
      }}
    />
  </CollapsablePanel>
);

export default decorate(CollapsableGroup);
