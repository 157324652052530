import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE, PAGES, DEFAULT_DAY_GROUPS } from "./constants";

const iniState = {
  isLenndAdmin: false,
  canClone: false,
  selectedPage: PAGES.NEW_EVENT,
  organization: {},
  allUserEvents: [],
  eventsFilter: "",
  selectedEvent: {},
  newEventName: "",
  newEventStartDate: new Date(),
  newEventEndDate: new Date(),
  dayGroups: R.clone(DEFAULT_DAY_GROUPS),
  selectedDays: [],
  contentType: "everything",
  processing: false,
  isSubmitting: false,
  loading: false,
  includeAllRecordData: false,
  includeAllOrders: false,
  includeAllFormSubmissions: false,
  eventOptions: {},
  includeAllRecords: [],
  excludedContent: {
    users: [],
    modules: [],
    forms: [],
    document_requests: []
  },
  isCreatingNewEvent: false
};

const model = createHandlers({
  iniState,
  reducers: {
    cancelInstance: R.always(iniState),
    init: R.identity,
    createEvent: R.identity,
    clearNewPageFormFields: R.identity,
    cloneEvent: R.identity,
    getEventOptions: R.identity,
    selectEvent: R.identity,
    showImportEventsModal: R.identity,
    submitCloneRequestToContactSupport: R.identity,
    toggleExcludedContent: (state, { payload: { type, id } }) => {
      if (state.excludedContent[type].includes(id)) {
        return {
          excludedContent: {
            ...state.excludedContent,
            [type]: state.excludedContent[type].filter(typeId => typeId !== id)
          }
        };
      }

      return {
        excludedContent: {
          ...state.excludedContent,
          [type]: [...state.excludedContent[type], id]
        }
      };
    },
    toggleChildren: (state, { payload: { id, type, selected } }) => {
      const item = state.eventOptions[type].find(item => item.id === id);
      const allIds = R.reduce((map, row) => {
        if (map[row.type]) {
          map[row.type] = [...map[row.type], row.id];
        } else {
          map[row.type] = [row.id];
        }

        return map;
      }, {})([item, ...item.related]);

      if (selected) {
        return {
          excludedContent: R.compose(
            R.reduce((map, key) => {
              if (allIds[key]) {
                map[key] = state.excludedContent[key].filter(
                  id => !allIds[key].includes(id)
                );
              } else {
                map[key] = state.excludedContent[key];
              }
              return map;
            }, {}),
            R.keys
          )(state.excludedContent)
        };
      }

      return {
        excludedContent: R.compose(
          R.reduce((map, key) => {
            if (allIds[key]) {
              map[key] = R.uniq([
                ...allIds[key],
                ...state.excludedContent[key]
              ]);
            } else {
              map[key] = state.excludedContent[key];
            }
            return map;
          }, {}),
          R.keys
        )(state.excludedContent)
      };
    },
    toggleIncludeAllRecords: (state, { payload: { id } }) => {
      if (state.includeAllRecords.includes(id)) {
        return {
          includeAllRecords: state.includeAllRecords.filter(
            typeId => typeId !== id
          )
        };
      }

      return {
        includeAllRecords: [...state.includeAllRecords, id]
      };
    },
    toggleAllContentType: (state, { payload: { type, selected } }) => {
      const allIds = R.compose(
        R.reduce((map, row) => {
          if (map[row.type]) {
            map[row.type] = [...map[row.type], row.id];
          } else {
            map[row.type] = [row.id];
          }

          row.related.forEach(subrow => {
            if (map[subrow.type]) {
              map[subrow.type] = [...map[subrow.type], subrow.id];
            } else {
              map[subrow.type] = [subrow.id];
            }
          });

          return map;
        }, {})
      )(state.eventOptions[type]);

      if (selected) {
        return {
          excludedContent: R.compose(
            R.reduce((map, key) => {
              if (allIds[key]) {
                map[key] = state.excludedContent[key].filter(
                  id => !allIds[key].includes(id)
                );
              } else {
                map[key] = state.excludedContent[key];
              }
              return map;
            }, {}),
            R.keys
          )(state.excludedContent)
        };
      }

      return {
        excludedContent: R.compose(
          R.reduce((map, key) => {
            if (allIds[key]) {
              map[key] = R.uniq([
                ...allIds[key],
                ...state.excludedContent[key]
              ]);
            } else {
              map[key] = state.excludedContent[key];
            }
            return map;
          }, {}),
          R.keys
        )(state.excludedContent)
      };
    },
    setNewEventStartDate: (state, { payload: date }) => ({
      newEventStartDate: date,
      newEventEndDate:
        state.newEventEndDate < date ? date : state.newEventEndDate,
      selectedDays: [],
      dayGroups: R.clone(DEFAULT_DAY_GROUPS)
    }),
    setNewEventEndDate: (state, { payload: date }) => ({
      newEventStartDate:
        state.newEventStartDate > date ? date : state.newEventStartDate,
      newEventEndDate: date,
      selectedDays: [],
      dayGroups: R.clone(DEFAULT_DAY_GROUPS)
    }),
    selectDayGroup: (state, { payload: id }) => {
      if (state.selectedDays.length === 0) {
        return {};
      }

      const dayGroupsToReturn = state.dayGroups;

      // remove selected days from existing groups
      state.dayGroups.forEach((group, idx) => {
        dayGroupsToReturn[idx].days = R.difference(
          state.dayGroups[idx].days,
          state.selectedDays
        );
      });

      // if selecting 'day off', all done
      if (id === 0) {
        return {
          selectedDays: []
        };
      }

      // add selected days to group
      const idx = R.findIndex(R.propEq("id", id))(state.dayGroups);
      dayGroupsToReturn[idx].days = R.uniq(
        [].concat(state.dayGroups[idx].days, state.selectedDays)
      );

      return {
        dayGroups: dayGroupsToReturn,
        selectedDays: []
      };
    },
    selectDayGroupDay: (state, { payload: ydm }) => {
      if (state.selectedDays.includes(ydm)) {
        return {
          selectedDays: R.without([ydm], state.selectedDays)
        };
      } else {
        return {
          selectedDays: [...state.selectedDays, ydm]
        };
      }
    }
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
