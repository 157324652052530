import * as STANDARD_MODULE_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

export const NAMESPACE = "EventLightHealthPassQuestions";

export const FORM_ID = "EventLightHealthPassQuestionsForm";
export const FIELDS_MODAL_INSTANCE_ID = "SelectFieldsModal";
export const DATE_FORMAT = "M/D [at] h:mma";

export const REQUIRED_ERROR_MESSAGE =
  "First Name, Last Name and Email fields must be visible and required.";

export const FIELD_IDS = {
  BACKGROUND_IMAGE_URL: "background_image_url",
  WELCOME_TITLE: "welcome_title",
  WELCOME_MESSAGE: "welcome_message",
  TESTING_BEGINS_AT: "testing_begins_at",
  TESTING_ENDS_AT: "testing_ends_at",
  EXPIRES_AFTER_NUM_HOURS: "expires_after_num_hours",
  FREQUENCY: "frequency",
  APPROVED_MESSAGE: "approved_message",
  DENIED_MESSAGE: "denied_message",
  REMINDER_MESSAGE: "reminder_message",
  SEND_AT_TIME: "send_at_time",
  NOTIFY_USER_IDS: "notify_user_ids"
};

export const DEFAULT_FIELD_ORDER = {
  [STANDARD_MODULE_FIELD_IDS.HEALTH_PASS.PROXIMITY]: -4,
  [STANDARD_MODULE_FIELD_IDS.HEALTH_PASS.CURRENTLY_ILL]: -3,
  [STANDARD_MODULE_FIELD_IDS.HEALTH_PASS.SYMPTOMS]: -2
};

export const FREQUENCY_OPTIONS = [
  {
    id: "once",
    label: "Only has to fill out once"
  },
  {
    id: "recurring",
    label: "Must fill out every day"
  }
];

export const NUM_HOURS_OPTIONS = [
  {
    id: 12,
    label: "12 Hours"
  },
  {
    id: 24,
    label: "24 Hours"
  }
];

export const SEND_AT_TIME_OPTIONS = [
  {
    id: "00:00",
    label: "12:00 AM"
  },
  {
    id: "01:00",
    label: "1:00 AM"
  },
  {
    id: "02:00",
    label: "2:00 AM"
  },
  {
    id: "03:00",
    label: "3:00 AM"
  },
  {
    id: "04:00",
    label: "4:00 AM"
  },
  {
    id: "05:00",
    label: "5:00 AM"
  },
  {
    id: "06:00",
    label: "6:00 AM"
  },
  {
    id: "07:00",
    label: "7:00 AM"
  },
  {
    id: "08:00",
    label: "8:00 AM"
  },
  {
    id: "09:00",
    label: "9:00 AM"
  },
  {
    id: "10:00",
    label: "10:00 AM"
  },
  {
    id: "11:00",
    label: "11:00 AM"
  },
  {
    id: "12:00",
    label: "12:00 PM"
  },
  {
    id: "13:00",
    label: "1:00 PM"
  },
  {
    id: "14:00",
    label: "2:00 PM"
  },
  {
    id: "15:00",
    label: "3:00 PM"
  },
  {
    id: "16:00",
    label: "4:00 PM"
  },
  {
    id: "17:00",
    label: "5:00 PM"
  },
  {
    id: "18:00",
    label: "6:00 PM"
  },
  {
    id: "19:00",
    label: "7:00 PM"
  },
  {
    id: "20:00",
    label: "8:00 PM"
  },
  {
    id: "21:00",
    label: "9:00 PM"
  },
  {
    id: "22:00",
    label: "10:00 PM"
  },
  {
    id: "23:00",
    label: "11:00 PM"
  }
];
