import React from "react";
import { connect } from "react-redux";
import { getters } from "Portal/Settings/AddPageModal/model";
import * as R from "ramda";
import { Div } from "components/Base";
import CSSModules from "react-css-modules";
import css from "./style.scss";
import { FORM_ID, FIELD_IDS } from "../constants";
import { WithFormInstanceProvider } from "ui-kit/Form/View";
import { Input, AdvancedWYSIWYG } from "ui-kit/Form/View";

import { PanelTitle, FieldWrapper, Label } from "EventLight/Common/FieldLayout";

const decorate = R.compose(
  WithFormInstanceProvider(R.always(FORM_ID)),
  connect((state, props) => ({
    data: getters.settings(state, props)
  }))
);

const Custom = ({ data }) => (
  <Div width={1} style={{ padding: 40 }}>
    <Div width={1}>
      {/* page settings */}
      <Div bg="white" bra={1} p={5} display="column" mb={4}>
        <PanelTitle>Page Settings</PanelTitle>

        <FieldWrapper>
          <Label>Title</Label>
          <Input
            fieldId={FIELD_IDS.CUSTOM.PAGE_TITLE}
            width={1}
            iniValue={R.prop(FIELD_IDS.CUSTOM.PAGE_TITLE)(data)}
            size="big"
            inputType="outline"
          />
        </FieldWrapper>

        <FieldWrapper>
          <Label>Subtitle</Label>
          <Input
            fieldId={FIELD_IDS.CUSTOM.PAGE_SUBTITLE}
            width={1}
            iniValue={R.prop(FIELD_IDS.CUSTOM.PAGE_SUBTITLE)(data)}
            size="big"
            inputType="outline"
          />
        </FieldWrapper>

        <FieldWrapper>
          <Label>Page Content</Label>
          <AdvancedWYSIWYG
            style={{
              width: "100%",
              resize: "none"
            }}
            fieldId={FIELD_IDS.CUSTOM.PAGE_CONTENT}
            width={1}
            mb={2}
            color="neutral9"
            bg="neutral0"
            bc={{ default: "neutral2", hover: "purple5" }}
            iniValue={R.prop(FIELD_IDS.CUSTOM.PAGE_CONTENT)(data)}
            placeholder="Content..."
          />
        </FieldWrapper>
      </Div>
    </Div>
  </Div>
);

export default R.compose(
  decorate,
  CSSModules(css)
)(Custom);
