import * as R from "ramda";

import { createHandlers } from "redux-mvc";

const iniState = {
  isModalOpened: false,
  loading: true,
  error: null,
  typeId: null,
  values: {},
  eventDetails: null,
  types: []
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.always({ isModalOpened: true }),
    hideModal: R.always(iniState),
    handleSave: R.identity,
    loadData: R.identity,
    onSaveFinished: R.always(iniState), // this actions will be listened from the outside to get the result
    setInitialData: (_, { payload: data }) => ({
      ...data,
      loading: false
    }),
    updateValue: ({ values }, { payload: { fieldId, value } }) => ({
      error: null,
      values: R.mergeAll([values, { [fieldId]: value }])
    }),
    updateType: (_, { payload: { value } }) => ({
      typeId: value,
      error: null,
      values: {}
    })
  },
  namespace: "AddPersonModal"
});

const { actions, getters } = model;

export { actions, getters };

export default model;
