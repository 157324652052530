import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import getValue from "utils/value-types/get-value/number";
import numeral from "numeral";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const getMask = format => {
  const decimalLimit = (format.replace("%", "").split(".")[1] || "").length;
  return createNumberMask({
    prefix: "",
    suffix: "%",
    allowDecimal: Boolean(decimalLimit),
    decimalLimit,
    allowNegative: true
  });
};

@CSSModules(css)
class FormPercent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.formatValue(props.value)
    };
  }

  componentWillUpdate(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        value: this.formatValue(nextProps.value)
      });
    }
  }

  formatValue(value) {
    return getValue(value);
  }

  wrapValue(value) {
    return {
      type: "percent",
      value: value ? numeral(value).value() * 100 : value
    };
  }

  @autobind
  onChange(e) {
    this.setState({
      value: e.target.value
    });
    if (this.props.continuous) {
      this.props.onChange(this.wrapValue(e.target.value));
    }
  }

  @autobind
  onBlur() {
    this.props.onChange(this.wrapValue(this.state.value));
  }

  render() {
    const {
      disabled,
      placeholder,
      required,
      format,
      autocomplete
    } = this.props;

    return (
      <MaskedInput
        autocomplete={autocomplete}
        disabled={disabled}
        required={required}
        value={this.state.value}
        placeholder={placeholder}
        onChange={this.onChange}
        onBlur={this.onBlur}
        placeholderChar={"\u2000"}
        mask={getMask(format)}
        styleName="input"
        type="text"
      />
    );
  }
}

FormPercent.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  format: PropTypes.string.isRequired
};

export default FormPercent;
