import React from "react";

import {
  put,
  call,
  take,
  takeEvery,
  all,
  fork,
  select,
  delay
} from "redux-saga/effects";
import * as R from "ramda";
import { actions, getters } from "Organizations/CloneEventModal/model";
import { getNewEventFields } from "Organizations/CloneEventModal/selectors";
import {
  actions as FormActions,
  getters as FormGetters
} from "ui-kit/Form/model";
import { getCredentials } from "redux/modules/user/selectors";
import { navigateTo } from "utils/General";
import { registerError } from "redux/modules/errors/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import api from "Organizations/CloneEventModal/api";
import { PAGES, FIELD_IDS, NAMESPACE } from "./constants";
import moment from "moment";
import ImportModal from "Modules/ImportModal/View";
import { hideModal, showModal } from "redux/modules/modal/actions";
import { makeFuture } from "utils/General/sagas";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";

const getFutureDate = (start, end, years) => {
  const dStart = moment(start);
  const dStartFuture = dStart.clone().add(years || 1, "years");
  const dowDiff = dStartFuture.day() - dStart.day();

  const daysSub =
    Math.abs(dowDiff) < 4
      ? dowDiff
      : dowDiff +
        7 *
          -Math.sign(
            dowDiff,
            //daysAdd - this was not defined in stack overflow response
            // eslint-disable-next-line no-use-before-define
            daysSub
          );
  dStartFuture.subtract(daysSub, "days");
  const days = moment(end).diff(dStart, "days");
  const dEndFuture = dStartFuture.clone().add(days, "days");
  return {
    start: dStartFuture.format("YYYY-MM-DD"),
    end: dEndFuture.format("YYYY-MM-DD")
  };
};

const getEventOptions = function*({ payload: { event } }) {
  const credentials = yield select(getCredentials);
  const isLoading = yield select(getters.loading);

  if (isLoading) return false;

  // calculate date
  const thisYear = moment(new Date()).format("YYYY");
  const oldStart = moment(new Date(event.date_from));
  const oldEnd = moment(new Date(event.date_to));
  const oldYear = oldStart.format("YYYY");
  let eventDates = {};

  if (thisYear === oldYear) {
    eventDates = {
      start: oldStart.format("YYYY-MM-DD"),
      end: oldEnd.format("YYYY-MM-DD")
    };
  } else {
    eventDates = getFutureDate(
      event.date_from,
      event.date_to,
      parseInt(thisYear, 10) - parseInt(oldYear, 10)
    );
  }

  yield all([
    put(actions.setSelectedEvent(event)),
    put(
      actions.setExcludedContent({
        users: [],
        modules: [],
        forms: [],
        document_requests: []
      })
    ),
    put(actions.setIncludeAllRecords([])),
    put(actions.setNewEventStartDate(eventDates.start)),
    put(actions.setNewEventEndDate(eventDates.end)),
    put(actions.setNewEventName(`${event.name} (copy)`)),
    put(actions.setLoading(true))
  ]);

  try {
    const result = yield call(api.eventOptions, credentials, {
      eventId: event.id
    });

    yield all([
      put(actions.setEventOptions(result.payload)),
      put(actions.setSelectedPage(PAGES.CLONE_OPTIONS)),
      put(actions.setLoading(false))
    ]);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred getting event options"
          }
        ])
      ),
      put(actions.setLoading(false, {}, true))
    ]);
  }

  return null;
};

const submitCloneRequestToContactSupport = function*({ payload }) {
  yield put(actions.setIsSubmitting(true));

  const userId = yield select(getUserId);
  const credentials = yield select(getCredentials);
  const data = {
    user: userId,
    additionalDetails: payload.additionalInformation
  };

  try {
    const { success } = yield call(
      api.submitCloneRequestToContactSupport,
      credentials,
      data,
      payload.eventId
    );

    yield all([
      put(
        showSnackbar({
          message: success
            ? "Your request has been submitted"
            : "An error occurred when trying to contact support"
        })
      ),
      put(hideModal())
    ]);
  } catch (error) {
    yield put(
      showSnackbar({
        message: "An error occurred when trying to contact support"
      })
    );
  } finally {
    yield put(actions.setIsSubmitting(false));
  }
};

const cloneEvent = function*() {
  const credentials = yield select(getCredentials);
  const selectedEvent = yield select(getters.selectedEvent);
  const organization = yield select(getters.organization);
  const newEventName = yield select(getters.newEventName);
  const newEventStartDate = yield select(getters.newEventStartDate);
  const newEventEndDate = yield select(getters.newEventEndDate);
  const dayGroups = yield select(getters.dayGroups);
  const contentType = yield select(getters.contentType);
  const excludedContent = yield select(getters.excludedContent);
  const includeAllRecordData = yield select(getters.includeAllRecordData);
  const includeAllOrders = yield select(getters.includeAllOrders);
  const includeAllFormSubmissions = yield select(
    getters.includeAllFormSubmissions
  );
  const includeAllRecords = yield select(getters.includeAllRecords);

  yield all([
    put(actions.setSelectedPage(PAGES.CONFIRMATION)),
    put(actions.setProcessing(true))
  ]);

  try {
    const initialResult = yield call(api.cloneEvent, credentials, {
      eventSourceId: selectedEvent.id,
      cloneToOrgId: organization.id,
      newEventName,
      newEventStartDate,
      newEventEndDate,
      dayGroups,
      contentType,
      excludedContent,
      includeAllRecordData,
      includeAllFormSubmissions,
      includeAllOrders,
      includeAllRecords
    });

    yield delay(500);

    let progressResult = yield call(api.getCloneStatus, credentials, {
      jobId: initialResult.payload.jobId
    });

    while (["pending", "processing"].includes(progressResult.payload.status)) {
      yield delay(3000);
      progressResult = yield call(api.getCloneStatus, credentials, {
        jobId: initialResult.payload.jobId
      });
    }

    yield call(
      navigateTo,
      `/event/${progressResult.payload.payload.newEventId}/home`
    );
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred cloning event"
          }
        ])
      ),
      put(actions.setProcessing(false, {}, true))
    ]);
  }
};

const init = function*({
  payload: {
    organization,
    isLenndAdmin,
    canClone,
    startDate,
    endDate,
    eventIdToClone
  }
}) {
  try {
    const credentials = yield select(getCredentials);
    const userId = yield select(getUserId);
    const { payload: allUserEvents } = yield call(
      api.getAllUserEvents,
      credentials,
      userId
    );

    yield put(actions.setAllUserEvents(allUserEvents));

    if (organization) {
      yield put(actions.setOrganization(organization));
    }
    if (isLenndAdmin) {
      yield put(actions.setIsLenndAdmin(isLenndAdmin));
    }
    if (canClone) {
      yield put(actions.setCanClone(canClone));
    }
    if (startDate) {
      yield put(actions.setNewEventStartDate(new Date(startDate)));
    }
    if (endDate) {
      yield put(actions.setNewEventEndDate(new Date(endDate)));
    }
    if (eventIdToClone && allUserEvents) {
      const event = allUserEvents.find(e => e.id === eventIdToClone);
      if (event) {
        yield call(getEventOptions, { payload: { event } });
      }
    }
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred getting options"
          }
        ])
      ),
      put(actions.setLoading(false, {}, true))
    ]);
  }
};

const showImportModal = function*() {
  const handleAddEvent = yield call(makeFuture);

  yield put(
    showModal({
      content: (
        <ImportModal
          moduleId={STANDARD_MODULE_IDS.events.id}
          onDone={() => handleAddEvent.done(true)}
        />
      ),
      wrapper: ModalWrapper
    })
  );

  return yield call(handleAddEvent.onRealized);
};

const importEvents = function*() {
  const orgId = yield select(getOrgId);

  const isDone = yield call(showImportModal);

  if (isDone) {
    yield put(hideModal());
    window.location = `/organization/${orgId}/events`;
  }
};

const createEvent = function*() {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const newEventFormFields = yield select(getNewEventFields);

  const payload = {
    orgId,
    name: newEventFormFields.eventName,
    startDate: newEventFormFields.dateFrom.toISOString(),
    endDate: newEventFormFields.dateTo.toISOString(),
    dateGroups: newEventFormFields.selectedDates.dayGroups,
    timezone: R.head(newEventFormFields.timezone),
    currency: R.head(newEventFormFields.currency),
    sandbox: newEventFormFields.isSandbox
  };

  try {
    yield put(actions.setIsCreatingNewEvent(true));
    const { success } = yield call(api.addEvent, credentials, payload);

    if (success) {
      setTimeout(() => window.location.reload(), 3000);

      yield all([
        put(hideModal()),
        put(actions.clearNewPageFormFields()),
        put(showSnackbar({ message: "New event created" }))
      ]);
    }
  } catch (error) {
    put(
      registerError([
        {
          system: error,
          user: "An error occurred creating the event"
        }
      ])
    );
  } finally {
    yield put(actions.setIsCreatingNewEvent(false));
  }
};

const onChangeFormFields = function*({ meta: { instanceId, fieldId } }) {
  const formFields = yield select(FormGetters.fields, {
    instanceId
  });
  const formFieldsData = R.pluck("value", formFields);

  const newValue = formFieldsData[fieldId];

  /*
    If the changed field is one of the dates, make sure
    the end date is not set after the start date.
  */
  if (
    fieldId === FIELD_IDS.DATE_FROM &&
    moment(formFieldsData[FIELD_IDS.DATE_TO])
      .startOf("day")
      .isBefore(newValue)
  ) {
    yield put(
      FormActions.setFieldValue(newValue, {
        meta: {
          instanceId,
          fieldId: FIELD_IDS.DATE_TO,
          avoidEffectsPropagation: true
        }
      })
    );
  }
  if (
    fieldId === FIELD_IDS.DATE_TO &&
    moment(newValue)
      .startOf("day")
      .isBefore(formFieldsData[FIELD_IDS.DATE_FROM])
  ) {
    yield put(
      FormActions.setFieldValue(newValue, {
        meta: {
          instanceId,
          fieldId: FIELD_IDS.DATE_FROM,
          avoidEffectsPropagation: true
        }
      })
    );
  }
};

const watchCreateEvent = function*() {
  yield takeEvery(actions.createEvent.type, createEvent);
};

const watchOnChangeFormFields = function*() {
  for (;;) {
    const {
      meta: { fieldId, instanceId, avoidEffectsPropagation },
      payload
    } = yield take(FormActions.setFieldValue.type);
    if (R.not(avoidEffectsPropagation)) {
      yield call(onChangeFormFields, {
        meta: {
          instanceId,
          fieldId,
          payload
        }
      });
    }
  }
};

const clearNewPageFormFields = function*() {
  yield put(FormActions.setFields({}, { meta: { instanceId: NAMESPACE } }));
};

const watchClearNewPageFormFields = function*() {
  yield takeEvery(actions.clearNewPageFormFields.type, clearNewPageFormFields);
};

const watchCloneEvent = function*() {
  yield takeEvery(actions.cloneEvent.type, cloneEvent);
};

const watchSelectEvent = function*() {
  yield takeEvery(actions.selectEvent.type, getEventOptions);
};

const watchShowImportEventsModal = function*() {
  yield takeEvery(actions.showImportEventsModal.type, importEvents);
};

const watchSubmitCloneRequestToContactSupport = function*() {
  yield takeEvery(
    actions.submitCloneRequestToContactSupport.type,
    submitCloneRequestToContactSupport
  );
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([
    fork(watchCloneEvent),
    fork(watchSelectEvent),
    fork(watchSubmitCloneRequestToContactSupport),
    fork(watchInit),
    fork(watchOnChangeFormFields),
    fork(watchCreateEvent),
    fork(watchShowImportEventsModal),
    fork(watchClearNewPageFormFields)
  ]);
};

export default rootSaga;
