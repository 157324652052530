import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { Popover } from "components/Base";

const SubmissionMenu = ({ children }) => (
  <div styleName="menu">
    <Popover
      Label={({ onClick }) => (
        <i className="material-icons" onClick={onClick}>
          more_vert
        </i>
      )}
    >
      {() => children}
    </Popover>
  </div>
);

SubmissionMenu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.node
  ]).isRequired,
  onChange: PropTypes.func.isRequired
};

export default CSSModules(SubmissionMenu, css);
