import CateringEditor from "ui-kit/Datagrid/View/Editors/CateringEditor";
import CheckboxEditor from "ui-kit/Datagrid/View/Editors/CheckboxEditor";
import CountriesEditor from "ui-kit/Datagrid/View/Editors/CountriesEditor";
import CredentialEditor from "ui-kit/Datagrid/View/Editors/CredentialEditor";
import ItemBlockEditor from "ui-kit/Datagrid/View/Editors/ItemBlockEditor";
import DateEditor from "ui-kit/Datagrid/View/Editors/DateEditor";
import DateTimeEditor from "ui-kit/Datagrid/View/Editors/DateTimeEditor";
import DependencyEditor from "ui-kit/Datagrid/View/Editors/DependencyEditor";
import DietaryRestrictionsEditor from "ui-kit/Datagrid/View/Editors/CateringDietaryRestrictionsEditor";
import DropDownEditor from "ui-kit/Datagrid/View/Editors/DropdownEditor";
import EventDaysEditor from "ui-kit/Datagrid/View/Editors/EventDaysEditor";
import FileEditor from "ui-kit/Datagrid/View/Editors/FileEditor";
import MultipleSelectEditor from "ui-kit/Datagrid/View/Editors/MultipleSelectEditor";
import NumberEditor from "ui-kit/Datagrid/View/Editors/NumberEditor";
import PhoneEditor from "ui-kit/Datagrid/View/Editors/PhoneEditor";
import RadioEditor from "ui-kit/Datagrid/View/Editors/RadioEditor";
import TextEditor from "ui-kit/Datagrid/View/Editors/TextEditor";
import TimeEditor from "ui-kit/Datagrid/View/Editors/TimeEditor";
import UserEditor from "ui-kit/Datagrid/View/Editors/UserEditor";
import ReferenceEditor from "ui-kit/Datagrid/View/Editors/ReferenceEditor";
import PercentEditor from "ui-kit/Datagrid/View/Editors/PercentEditor";
import CurrencyEditor from "ui-kit/Datagrid/View/Editors/CurrencyEditor";
import LinkedRecordsEditor from "ui-kit/Datagrid/View/Editors/LinkedRecordsEditor";
import CatalogItemEditor from "ui-kit/Datagrid/View/Editors/CatalogItemEditor";

export default function resolveEditor(column) {
  if (
    column.type === "dropdown" &&
    column.settings &&
    column.settings.allowMultipleSelect &&
    column.editable !== false
  ) {
    return MultipleSelectEditor;
  } else if (column.type === "dropdown" && column.editable !== false) {
    return DropDownEditor;
  } else if (
    (column.type === "checkbox" || column.type === "boolean") &&
    column.editable !== false
  ) {
    return CheckboxEditor;
  } else if (column.type === "date" && column.editable !== false) {
    return DateEditor;
  } else if (column.type === "datetime" && column.editable !== false) {
    return DateTimeEditor;
  } else if (column.type === "time") {
    return TimeEditor;
  } else if (column.type === "dependency" && column.editable !== false) {
    return DependencyEditor;
  } else if (column.type === "credentials" && column.editable !== false) {
    return CredentialEditor;
  } else if (column.type === "item-block" && column.editable !== false) {
    return ItemBlockEditor;
  } else if (column.type === "event-days" && column.editable !== false) {
    return EventDaysEditor;
  } else if (column.type === "catering" && column.editable !== false) {
    return CateringEditor;
  } else if (
    column.type === "catering-dietary-restrictions" &&
    column.editable !== false
  ) {
    return DietaryRestrictionsEditor;
  } else if (
    (column.type === "number" || column.type === "quantity") &&
    column.editable !== false
  ) {
    return NumberEditor;
  } else if (column.type === "percent" && column.editable !== false) {
    return PercentEditor;
  } else if (column.type === "currency" && column.editable !== false) {
    return CurrencyEditor;
  } else if (column.type === "user" && column.editable !== false) {
    return UserEditor;
  } else if (column.type === "phone" && column.editable !== false) {
    return PhoneEditor;
  } else if (column.type === "file" && column.editable !== false) {
    return FileEditor;
  } else if (column.type === "countries" && column.editable !== false) {
    return CountriesEditor;
  } else if (column.type === "radio" && column.editable !== false) {
    return RadioEditor;
  } else if (column.type === "reference" && column.editable !== false) {
    return ReferenceEditor;
  } else if (column.type === "lookup" && column.editable !== false) {
    return LinkedRecordsEditor;
  } else if (column.type === "catalog-item" && column.editable !== false) {
    return CatalogItemEditor;
  } else if (column.type !== "static" && column.editable !== false) {
    return TextEditor;
  }
  return undefined;
}
