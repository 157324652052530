import React from "react";
import * as R from "ramda";

import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

import { Route, IndexRoute, IndexRedirect } from "react-router";
import CoreLayout from "layouts/CoreLayout";

import DefaultNotFound from "components/Platform/NotFound";

import userView from "components/Global/Security/AuthenticatedRoute";

const makeChunk = (root, components) =>
  R.reduce(
    (acc, name) => ({
      ...acc,
      [name]: location =>
        root(location).then(component => component.default[name])
    }),
    {},
    components
  );

const canUserView = (f, ...args) => location =>
  f(location).then(component => userView(component, ...args));

const Platform = makeChunk(
  () => import(/* webpackChunkName: "platform" */ "./Platform"),
  ["PlatformView", "HomeView"]
);

const Intake = makeChunk(
  () => import(/* webpackChunkName: "intake" */ "./Intake"),
  ["IntakeForm", "IntakeLogin", "IntakeCallback"]
);

const HealthPass = makeChunk(
  () => import(/* webpackChunkName: "healthpass" */ "./HealthPass"),
  ["HealthPassForm", "HealthPassStatus"]
);

const Inventory = makeChunk(
  () => import(/* webpackChunkName: "inventory" */ "./Inventory"),
  [
    "Container",
    "NeedsByStatus",
    "NeedsOverview",
    "Home",
    "Catalog",
    "Manage",
    "ManageContainer",
    "Dashboard"
  ]
);

const Auth = makeChunk(() => import(/* webpackChunkName: "auth" */ "./Auth"), [
  "SignupStart",
  "SignupComplete",
  "SignupResend",
  "SignupVerificationConfirm",
  "LoginView",
  "CallbackView",
  "LogoutView",
  "LogoutCallbackView",
  "ForgotPasswordHandler",
  "InvoiceHandler",
  "JoinVirtualUserHandler",
  "LoggedOutView",
  "SignupOnboard"
]);

const Portal = makeChunk(
  () => import(/* webpackChunkName: "portal" */ "./Portal"),
  [
    "Intake",
    "LoggedInView",
    "LoggedOutView",
    "Portal",
    "ManagePortals",
    "PortalHome",
    "PortalSidebar",
    "PortalForms",
    "PortalPreviewProfile",
    "PortalReports",
    "PortalDocuments",
    "PortalPeople",
    "PortalMessages",
    "PortalCommunity",
    "PortalUpdates",
    "PortalSettings",
    "PortalProfile",
    "PortalSwitch",
    "PortalLogin",
    "PortalLoginCallback",
    "GroupManager",
    "PortalForm",
    "NewPortalPeople",
    "PortalPage",
    "PortalSession",
    "PortalPOSPeople",
    "PortalPOSPeopleRequests"
  ]
);

const Passes = makeChunk(
  () => import(/* webpackChunkName: "passes" */ "./Passes"),
  [
    "Container",
    "Dashboard",
    "ManageAll",
    "ManageBy",
    "Confirmations",
    "ManageTypes",
    "ManageAssignee",
    "ManageGuestList",
    "ManageApplication",
    "ManageGroups",
    "ManageSources",
    "ActionsSendConfirmations",
    "Reports",
    "Checkin",
    "Setup",
    "SetupOverview",
    "SetupTypes",
    "SetupApprovers",
    "SetupConfirmations",
    "SetupCollect",
    "SetupCollectGuestLists",
    "SetupCollectRequestForms",
    "SetupCollectApplications",
    "SetupIntegrations"
  ]
);

const Orders = makeChunk(
  () => import(/* webpackChunkName: "passes" */ "./Orders"),
  ["Container", "List"]
);

const FormV2 = makeChunk(
  () => import(/* webpackChunkName: "formV2" */ "./FormV2"),
  [
    "Forms",
    "FormsList",
    "FormOverview",
    "Form",
    "FormEdit",
    "FormPreview",
    "FormSubmission",
    "FormSubmissionLanding",
    "FormSubmissionLandingCallback",
    "V3FormPreview",
    "V3FormEdit",
    "FormResults"
  ]
);

const Event = makeChunk(
  () => import(/* webpackChunkName: "event" */ "./Event"),
  [
    "EventHandler",
    "EventHomeHandler",
    "EventHomeContent",
    "EventHomeMyApprovals",
    "EventHomeSetupChecklists",
    "SettingsHandler",
    "SettingsSettingsHandler",
    "SettingsUsersHandler",
    "SettingsCredentials",
    "SettingsCatering",
    "SettingsInventoryCatalog",
    "SettingsEmailTemplates",
    "SettingsDocumentTemplates",
    "SettingsFormTemplates",
    "SettingsPortal",
    "SettingsLocations",
    "SettingsCatalog",
    "SettingsOrders",
    "SettingsSchedules",
    "SettingsApprovalWorkflows",
    "SettingsIntegrationFuzion",
    "EventSettingsModule",
    "Reports",
    "Report",
    "Files",
    "ContactProfileContainer",
    "Locations",
    "SettingsSalesPortal",
    "SettingsIntegrations",
    "RefactorSettingsCatalog",
    "SettingsCatalogQuestions",
    "SettingsCatalogZones",
    "RefactorCatalogItems",
    "ItemCatalogApprovers",
    "AccessGrid",
    "Actions",
    "AccountsQuickSetup",
    "MyTasks",
    "PortalSettingsPage",
    "EventActivities",
    "ItemCatalogInventory"
  ]
);

// new light platform

const OrgLight = makeChunk(
  () => import(/* webpackChunkName: "orglight" */ "./OrgLight"),
  [
    "OrgLightGate",
    "OrgLightLayout",
    "OrgLightDashboard",
    "OrgLightEvents",
    "OrgLightTeam",
    "OrgLightDetails",
    "OrgLightIntegrations",
    "OrgLightGroups",
    "OrgLightPeople",
    "OrgLightBilling"
  ]
);

const EventLight = makeChunk(
  () => import(/* webpackChunkName: "eventlight" */ "./EventLight"),
  [
    "EventLightGate",
    "EventLightLayout",
    "EventLightDashboard",
    "EventLightDetails",
    "EventLightContent",
    "EventLightExhibitors",
    "EventLightCRM",
    "EventLightCustomModule",
    "EventLightReports",
    "EventLightTeam",
    "EventLightAgenda",
    "EventLightForms",
    "EventLightForm",
    "EventLightPortals",
    "EventLightProductionSchedules",
    "EventLightAlerts",
    "EventLightEmailsWrapper",
    "EventLightEmailsHistory",
    "EventLightPasses",
    "EventLightInventory",
    "EventLightBooths",
    "EventLightCatering",
    "EventLightExpo",
    "EventLightIntegrations",
    "EventLightIntegrationsFuzion",
    "EventLightFileRequests",
    "EventLightFileRequest",
    "EventLightHealthPass",
    "EventLightGuestLists",
    "EventLightSettings",
    "EventLightTasks"
  ]
);

// new light platform

const Organization = makeChunk(
  () => import(/* webpackChunkName: "organization" */ "./Organization"),
  [
    "Handler",
    "Home",
    "SettingsHandler",
    "GeneralSettings",
    "UsersSettings",
    "SettingsModule",
    "ContactProfile",
    "AccountProfile",
    "Main",
    "Events",
    "Files"
  ]
);

const Module = makeChunk(
  () => import(/* webpackChunkName: "module" */ "./Module"),
  [
    "Records",
    "Form",
    "FormSettings",
    "FormShare",
    "RecordView",
    "AccountsReports",
    "ModuleDashboard",
    "ModulePortalSettings",
    "Reports"
  ]
);

const Schedule = makeChunk(
  () => import(/* webpackChunkName: "schedule" */ "./Schedule"),
  ["Schedule"]
);

const Catering = makeChunk(
  () => import(/* webpackChunkName: "catering" */ "./Catering"),
  ["Dashboard", "Main", "Manage", "Checkin"]
);

const Booths = makeChunk(
  () => import(/* webpackChunkName: "booths" */ "./Booths"),
  ["Main", "Container", "SelfSetup", "Manage"]
);

const Account = makeChunk(
  () => import(/* webpackChunkName: "account" */ "./Account"),
  ["SettingsSettingsHandler", "SettingsHandler", "Profile"]
);

const SalesPortal = makeChunk(
  () => import(/* webpackChunkName: "sales" */ "./SalesPortal"),
  ["SalesPortal", "EventInfo", "Cart", "Checkout", "Confirmation"]
);

const PortalHub = makeChunk(
  () => import(/* webpackChunkName: "hub" */ "./PortalHub"),
  [
    "Home",
    "People",
    "Wrapper",
    "Reports",
    "OrderRequestSelectItems",
    "Page",
    "Alert"
  ]
);

export default (
  <Route component={CoreLayout}>
    <Route
      name="SignupVerificationConfirm"
      path="/account/confirm/:verificationHash"
      getComponent={Auth.SignupVerificationConfirm}
    />
    <Route
      name="SignupComplete"
      path="/signup/complete"
      getComponent={Auth.SignupComplete}
    />
    <Route
      name="SignupResend"
      path="/signup/resend"
      getComponent={Auth.SignupResend}
    />
    <Route name="Signup" path="/signup" getComponent={Auth.SignupStart} />
    <Route
      name="Signup"
      path="/signup/:template"
      getComponent={Auth.SignupStart}
    />
    <Route
      name="SignupVerificationOnboard"
      path="/account/onboard"
      getComponent={Auth.SignupOnboard}
    />
    <Route name="LoggedOutView" getComponent={Auth.LoggedOutView}>
      <Route name="LoginView" getComponent={Auth.LoginView} path="/" />
      <Route
        name="LoginView"
        getComponent={Auth.LoginView}
        path="/auth/login"
      />
      <Route name="LoginView" getComponent={Auth.LoginView} path="/login" />
      <Route name="LogoutView" getComponent={Auth.LogoutView} path="/logout" />
      <Route
        name="LogoutCallbackView"
        getComponent={Auth.LogoutCallbackView}
        path="/logout/callback"
      />
      <Route
        name="CallbackView"
        getComponent={Auth.CallbackView}
        path="/auth/callback"
      />
      <Route
        name="forgotPassword"
        path="/forgot"
        getComponent={Auth.ForgotPasswordHandler}
      />
      <Route
        name="joinVirtualUser"
        path="/join/vu/:virtualUserHash"
        getComponent={Auth.JoinVirtualUserHandler}
      />

      <Route
        name="Invoice"
        path="/invoice/:invoiceId"
        getComponent={Auth.InvoiceHandler}
      />

      {/* Forms V2 - Global */}
      <Route
        name="formsV2_FormSubmissionLanding"
        path="/form-link/:formLinkId"
        getComponent={FormV2.FormSubmissionLanding}
      />
      <Route
        name="formsV2_FormSubmissionLanding"
        path="/forms/:eventSlug/:formSlug/:formId"
        getComponent={FormV2.FormSubmissionLanding}
      />
      <Route
        name="formsV2_FormSubmissionLandingCallback"
        path="/submission-callback"
        getComponent={FormV2.FormSubmissionLandingCallback}
      />
      <Route
        name="formsV2_FormOpenSubmission"
        path="/open-submissions/:eventSlug/:formSlug/:submissionId"
        getComponent={FormV2.FormSubmission}
      />
      <Route
        name="formsV2_FormOpenSubmissionConfirmation"
        path="/open-submissions/:eventSlug/:formSlug/:submissionId/confirmation"
        getComponent={FormV2.FormSubmission}
      />

      {/**  */}
      <Route
        name="intakeForm"
        path="/intake/:eventSlug/:intakeId"
        getComponent={Intake.IntakeForm}
      />
      <Route
        name="intakeCallback"
        path="/intake-callback"
        getComponent={Intake.IntakeForm}
      />
      <Route
        name="intakeLogin"
        path="/intake/:eventSlug/:intakeId/logon"
        getComponent={Intake.IntakeForm}
      />

      {/**  */}
      <Route
        name="healthPassForm"
        path="/health-pass/:linkId"
        getComponent={HealthPass.HealthPassForm}
      />
      <Route
        name="healthPassStatus"
        path="/health-pass/:linkId/status"
        getComponent={HealthPass.HealthPassStatus}
      />
    </Route>

    <Route name="PortalV2_LoggedOutView" getComponent={Portal.LoggedOutView}>
      <Route
        name="portalV2_GroupManager"
        path="/assignment-manager/:eventSlug/:eventUUID/:accountId"
        getComponent={Portal.GroupManager}
      />
      <Route name="portalV2_Portal" getComponent={Portal.Portal}>
        <Route
          name="portalV2_PortalLogin"
          path="/portal-login/:portalSlug/:portalId"
          getComponent={Portal.PortalLogin}
        />
        <Route
          name="portalV2_PortalLoginCallback"
          path="/portal-login-callback"
          getComponent={Portal.PortalLoginCallback}
        />
        {/*
        <Route
          name="portalV2_Intake"
          path="/intake/:portalSlug/:portalId/:typeSlug/:typeId"
          getComponent={Portal.Intake}
        />
        */}
      </Route>
    </Route>

    <Route name="PortalV2_LoggedInView" getComponent={Portal.LoggedInView}>
      <Route name="portal_hub" getComponent={PortalHub.Wrapper}>
        <Route
          name="HubPortalHome"
          path="/hubs/:portalSlug/:portalId"
          getComponent={PortalHub.Home}
        />
        <Route
          name="HubPortalPage"
          path="/hubs/:portalSlug/:portalId/pages/:pageId"
          getComponent={PortalHub.Page}
        />
        <Route
          name="HubPortalAlert"
          path="/hubs/:portalSlug/:portalId/alerts/:alertId"
          getComponent={PortalHub.Alert}
        />
        <Route
          name="HubPortal_PeopleByPeopleType"
          path="/hubs/:portalSlug/:portalId/people/:recordTypeId"
          getComponent={PortalHub.People}
        />
        <Route
          name="HubPortal_RequestOrderSelectItems"
          path="/hubs/:portalSlug/:portalId/people/:recordTypeId/requests(/cart)(/checkout)(/confirmation)"
          getComponent={PortalHub.OrderRequestSelectItems}
        />
        <Route
          name="HubPortal_PortalReports"
          getComponent={PortalHub.Reports}
          path="/hubs/:portalSlug/:portalId/people/:recordTypeId/reports(/:report)"
        />
      </Route>
      <Route name="portalV2_Portal" getComponent={Portal.Portal}>
        <Route
          name="portalV2_PortalSwitch"
          getComponent={Portal.PortalSwitch}
          path="/portal-switch/:portalSlug/:portalId"
        />
        <Route
          name="portalV2_PortalSidebar"
          getComponent={Portal.PortalSidebar}
        >
          <Route
            name="portalV2_PortalLanding"
            getComponent={Portal.PortalHome}
            path="/portals/:portalSlug/:portalId"
          />

          <Route
            name="HubPortalAlert"
            path="/portals/:portalSlug/:portalId/alerts/:alertId"
            getComponent={PortalHub.Alert}
          />
          <Route
            name="HubPortal_PeopleByPeopleType"
            path="/portals/:portalSlug/:portalId/people/:recordTypeId"
            getComponent={PortalHub.People}
          />
          <Route
            name="HubPortal_RequestOrderSelectItems"
            path="/portals/:portalSlug/:portalId/people/:recordTypeId/requests(/cart)(/checkout)(/confirmation)"
            getComponent={PortalHub.OrderRequestSelectItems}
          />
          <Route
            name="HubPortal_PortalReports"
            getComponent={PortalHub.Reports}
            path="/portals/:portalSlug/:portalId/people/:recordTypeId/reports(/:report)"
          />

          <Route
            name="portalV2_PortalForms"
            getComponent={Portal.PortalForms}
            path="/portals/:portalSlug/:portalId/forms"
          />
          <Route
            name="portalV2_PortalPreviewProfile"
            getComponent={Portal.PortalPreviewProfile}
            path="/portals/:portalSlug/:portalId/profile"
          />
          <Route
            name="portalV2_PortalForms"
            getComponent={Portal.PortalForm}
            path="/portals/:portalSlug/:portalId/form/:formId"
          />
          <Route
            name="portalV2_PortalReports"
            getComponent={Portal.PortalReports}
            path="/portals/:portalSlug/:portalId/reports"
          />
          <Route
            name="portalV2_PortalReportsReport"
            getComponent={Portal.PortalReports}
            path="/portals/:portalSlug/:portalId/reports/:report"
          />
          <Route
            name="portalV2_PortalDocuments"
            getComponent={Portal.PortalDocuments}
            path="/portals/:portalSlug/:portalId/documents"
          />

          <Route
            name="portalV2_PortalPeople"
            getComponent={Portal.PortalPeople}
            path="/portals/:portalSlug/:portalId/people"
          />

          <Route
            name="portalV2_PortalDocumentsRequestModal"
            getComponent={Portal.PortalDocuments}
            path="/portals/:portalSlug/:portalId/documents/request/:requestId"
          />
          <Route
            name="portalV2_PortalMessages"
            getComponent={Portal.PortalMessages}
            path="/portals/:portalSlug/:portalId/messages"
          />
          <Route
            name="portalV2_PortalUpdates"
            getComponent={Portal.PortalUpdates}
            path="/portals/:portalSlug/:portalId/updates"
          />
          <Route
            name="portalV2_PortalSettings"
            getComponent={Portal.PortalSettings}
            path="/portals/:portalSlug/:portalId/settings"
          />
          <Route
            name="portalV2_PortalProfile"
            getComponent={Portal.PortalProfile}
            path="/portals/:portalSlug/:portalId/profile"
          />
          <Route
            name="portalV2_PortalHome_With_Submission"
            path="/portals/:portalSlug/:portalId/summary/:submissionId"
            getComponent={Portal.PortalForms}
          />
          <Route
            name="portalV2_PortalMessages_With_Submission"
            path="/portals/:portalSlug/:portalId/messages/summary/:submissionId"
            getComponent={Portal.PortalMessages}
          />
          <Route
            name="portalV2_PortalCommunity"
            path="/portals/:portalSlug/:portalId/community(/alerts/:alertId)(/alerts)(/people)(/videos)(/overview-and-resources)(/sections/:sectionId)(/cart)(/checkout)(/confirmation)"
            getComponent={Portal.PortalCommunity}
          />
          <Route
            name="portalV2_PortalPage"
            path="/portals/:portalSlug/:portalId/pages/:pageId(/alerts/:alertId)(/alerts)(/people)(/videos)(/overview-and-resources)(/sections/:sectionId)(/cart)(/checkout)(/confirmation)"
            getComponent={Portal.PortalPage}
          />
          <Route
            name="portalV2_PortalAlert"
            path="/portals/:portalSlug/:portalId/alerts/:alertId"
            getComponent={PortalHub.Alert}
          />
          <Route
            name="portalV2_PortalSession"
            path="/portals/:portalSlug/:portalId/session/:sessionId"
            getComponent={Portal.PortalSession}
          />
        </Route>
      </Route>
      <Route
        name="formsV2_Submission"
        path="/submissions/:eventSlug/:formSlug/:submissionId"
        getComponent={FormV2.FormSubmission}
      />
      <Route
        name="formsV2_SubmissionConfirmation"
        path="/submissions/:eventSlug/:formSlug/:submissionId/confirmation"
        getComponent={FormV2.FormSubmission}
      />
    </Route>

    <Route
      name="SalesPortal"
      path="/sales-portal/:slug/:eventId"
      getComponent={SalesPortal.SalesPortal}
    >
      <IndexRoute name="EventInfo" getComponent={SalesPortal.EventInfo} />
      <Route name="Cart" getComponent={SalesPortal.Cart} path="cart" />
      <Route
        name="Checkout"
        getComponent={SalesPortal.Checkout}
        path="checkout"
      />
      <Route
        name="Confirmation"
        getComponent={SalesPortal.Confirmation}
        path="confirmation"
      />
    </Route>

    <Route
      name="OrgLightGate"
      path="/org-light/:orgId"
      getComponent={OrgLight.OrgLightGate}
    >
      <Route
        name="OrgLightDashboard"
        path="dashboard"
        getComponent={OrgLight.OrgLightDashboard}
      />
      <Route
        name="OrgLightDetails"
        path="details"
        getComponent={OrgLight.OrgLightDetails}
      />

      <Route
        name="OrgLightEvents"
        path="events"
        getComponent={OrgLight.OrgLightEvents}
      />
      <Route
        name="OrgLightBilling"
        path="billing"
        getComponent={OrgLight.OrgLightBilling}
      />
      <Route
        name="OrgLightTeam"
        path="team"
        getComponent={OrgLight.OrgLightTeam}
      />
      <Route
        name="OrgLightPermissions"
        path="team/permissions"
        getComponent={OrgLight.OrgLightTeam}
      />
      <Route
        name="OrgLightIntegrations"
        path="integrations"
        getComponent={OrgLight.OrgLightIntegrations}
      />

      <Route
        getComponent={canUserView(
          Organization.ContactProfile,
          STANDARD_MODULES.contacts.id
        )}
      >
        <Route path="contact/:recordId" name="OrgLightContactProfile" />
        <Route
          path="contact/:recordId/activity"
          name="OrgLightContactActivity"
        />
      </Route>

      <Route
        getComponent={canUserView(
          Organization.AccountProfile,
          STANDARD_MODULES.accounts.id
        )}
      >
        <Route path="account/:recordId" name="OrgLightAccountProfile" />
        <Route
          path="account/:recordId/activity"
          name="OrgLightAccountActivity"
        />
      </Route>

      {/** */}

      <Route
        name="OrgLightGroupsManage"
        path="groups/manage/:moduleId(/type/:recordTypeId)(/people/:contactRecordTypeId)"
        getComponent={OrgLight.OrgLightGroups}
      />

      <Route
        name="OrgLightGroupsSettingsFields"
        path="groups/settings/fields"
        getComponent={OrgLight.OrgLightGroups}
      />

      <Route
        name="OrgLightGroupsSettingsTypes"
        path="groups/settings/types(/:recordTypeId)"
        getComponent={OrgLight.OrgLightGroups}
      />

      <Route
        name="OrgLightGroupsSettingsLayout"
        path="groups/settings/types(/:recordTypeId)/layout"
        getComponent={OrgLight.OrgLightGroups}
      />

      <Route
        name="OrgLightGroupsSettingsEmailTemplates"
        path="groups/settings/email-templates"
        getComponent={OrgLight.OrgLightGroups}
      />

      {/** */}

      <Route
        name="OrgLightPeopleManage"
        path="people/manage/:moduleId(/type/:recordTypeId)(/people/:contactRecordTypeId)"
        getComponent={OrgLight.OrgLightPeople}
      />

      <Route
        name="OrgLightPeopleSettingsFields"
        path="people/settings/fields"
        getComponent={OrgLight.OrgLightPeople}
      />

      <Route
        name="OrgLightPeopleSettingsTypes"
        path="people/settings/types(/:recordTypeId)"
        getComponent={OrgLight.OrgLightPeople}
      />

      <Route
        name="OrgLightPeopleSettingsLayout"
        path="people/settings/types(/:recordTypeId)/layout"
        getComponent={OrgLight.OrgLightPeople}
      />

      <Route
        name="OrgLightPeopleSettingsEmailTemplates"
        path="people/settings/email-templates"
        getComponent={OrgLight.OrgLightPeople}
      />

      {/** */}
    </Route>

    <Route
      name="eventLightGate"
      path="/event-light/:eventId"
      getComponent={EventLight.EventLightGate}
    >
      <Route
        name="EventLightDashboard"
        path="dashboard"
        getComponent={EventLight.EventLightDashboard}
      />
      <Route
        name="EventLightDetails"
        path="details"
        getComponent={EventLight.EventLightDetails}
      />

      {/* */}

      <Route
        name="EventLightExpoRequests"
        path="expo/requests/:viewId(/category/:categoryId)(/account/:accountId)(/item-type/:itemTypeId)"
        getComponent={canUserView(
          EventLight.EventLightExpo,
          STANDARD_MODULES.booths.id,
          "read"
        )}
      />
      <Route
        name="EventLightExpoExhibitors"
        path="expo/exhibitors"
        getComponent={canUserView(
          EventLight.EventLightExpo,
          STANDARD_MODULES.booths.id,
          "read"
        )}
      />
      <Route
        name="EventLightExpoSponsors"
        path="expo/sponsors"
        getComponent={canUserView(
          EventLight.EventLightExpo,
          STANDARD_MODULES.booths.id,
          "read"
        )}
      />
      <Route
        name="EventLightExpoSales"
        path="expo/sales"
        getComponent={canUserView(
          EventLight.EventLightExpo,
          STANDARD_MODULES.booths.id,
          "read"
        )}
      >
        <IndexRedirect to="packages" />
        <Route
          name="EventLightExpoSales"
          path=":stepId"
          getComponent={canUserView(
            EventLight.EventLightExpo,
            STANDARD_MODULES.booths.id,
            "read"
          )}
        />
      </Route>
      <Route
        name="EventLightExpoSettingsBooths"
        path="expo/settings/booths"
        getComponent={canUserView(
          EventLight.EventLightExpo,
          STANDARD_MODULES.booths.id,
          "manage"
        )}
      />
      <Route
        name="EventLightExpoSettingsSponsorships"
        path="expo/settings/sponsorships"
        getComponent={canUserView(
          EventLight.EventLightExpo,
          STANDARD_MODULES.booths.id,
          "manage"
        )}
      />
      <Route
        name="EventLightExpoSettingsApprovers"
        path="expo/settings/approvers"
        getComponent={canUserView(
          EventLight.EventLightExpo,
          STANDARD_MODULES.booths.id,
          "manage"
        )}
      />

      <Route
        name="EventLightExpoSendEmails"
        path="expo/send-emails"
        getComponent={canUserView(
          EventLight.EventLightExpo,
          STANDARD_MODULES.booths.id,
          "read"
        )}
      />

      {/* */}

      <Route
        name="EventLightContent"
        path="content"
        getComponent={EventLight.EventLightContent}
      />

      {/** */}

      <Route
        name="EventLightHealthPassManage"
        path="health-pass/manage"
        getComponent={canUserView(
          EventLight.EventLightHealthPass,
          STANDARD_MODULES.healthPass.id,
          "read"
        )}
      />
      <Route
        name="EventLightHealthPassQuestions"
        path="health-pass/settings/questionnaire"
        getComponent={canUserView(
          EventLight.EventLightHealthPass,
          STANDARD_MODULES.healthPass.id,
          "read"
        )}
      />
      <Route
        name="EventLightHealthPassEmails"
        path="health-pass/settings/emails"
        getComponent={canUserView(
          EventLight.EventLightHealthPass,
          STANDARD_MODULES.healthPass.id,
          "read"
        )}
      />
      <Route
        name="EventLightHealthPassAssign"
        path="health-pass/settings/assign"
        getComponent={canUserView(
          EventLight.EventLightHealthPass,
          STANDARD_MODULES.healthPass.id,
          "read"
        )}
      />

      {/** */}

      <Route
        name="EventLightCustomModuleManage"
        path="custom/:moduleId/manage(/type/:recordTypeId)"
        getComponent={canUserView(
          EventLight.EventLightCustomModule,
          null, // inherits params.moduleId
          "read"
        )}
      />

      <Route
        name="EventLightCustomModuleSettingsApprovers"
        path="custom/:moduleId/settings/approvers"
        getComponent={canUserView(
          EventLight.EventLightCustomModule,
          null, // inherits params.moduleId
          "manage"
        )}
      />

      <Route
        name="EventLightCustomModuleSettingsFields"
        path="custom/:moduleId/settings/fields"
        getComponent={canUserView(
          EventLight.EventLightCustomModule,
          null, // inherits params.moduleId
          "manage"
        )}
      />

      {/** */}

      <Route
        name="EventLightCRMManage"
        path="crm/manage/:moduleId(/type/:recordTypeId)(/people/:contactRecordTypeId)"
        getComponent={canUserView(
          EventLight.EventLightCRM,
          null, // inherits params.moduleId
          "read"
        )}
      />
      <Route
        name="EventLightCRMHistory"
        path="crm/history/:historyType"
        getComponent={EventLight.EventLightCRM}
      />
      <Route
        name="EventLightCRMSettingsFields"
        path="crm/settings/fields"
        getComponent={EventLight.EventLightCRM}
      />
      <Route
        name="EventLightCRMSettingsTypes"
        path="crm/settings/:moduleId/types(/:typeId)"
        getComponent={canUserView(
          EventLight.EventLightCRM,
          null, // inherits params.moduleId
          "manage"
        )}
      />
      <Route
        name="EventLightCRMSettingsTypesPortal"
        path="crm/settings/:moduleId/types(/:typeId)/portal"
        getComponent={canUserView(
          EventLight.EventLightCRM,
          null, // inherits params.moduleId
          "manage"
        )}
      />
      <Route
        name="EventLightCRMSettingsTypesGuestList"
        path="crm/settings/:moduleId/types(/:typeId)/guest-list"
        getComponent={canUserView(
          EventLight.EventLightCRM,
          null, // inherits params.moduleId
          "manage"
        )}
      />
      <Route
        name="EventLightCRMSettingsTypesLayout"
        path="crm/settings/:moduleId/types(/:typeId)/layout"
        getComponent={canUserView(
          EventLight.EventLightCRM,
          null, // inherits params.moduleId
          "manage"
        )}
      />
      <Route
        name="EventLightCRMSettingsTypesAddRecordLayout"
        path="crm/settings/:moduleId/types(/:typeId)/add-record-layout"
        getComponent={canUserView(
          EventLight.EventLightCRM,
          null, // inherits params.moduleId
          "manage"
        )}
      />

      {/** */}

      <Route
        name="EventLightReports"
        path="Reports"
        getComponent={EventLight.EventLightReports}
      />
      <Route
        name="EventLightTeam"
        path="team"
        getComponent={canUserView(
          EventLight.EventLightTeam,
          STANDARD_MODULES.settings.id,
          "manage_users"
        )}
      />
      <Route
        name="EventLightPermissions"
        path="team/permissions"
        getComponent={canUserView(
          EventLight.EventLightTeam,
          STANDARD_MODULES.settings.id,
          "manage_permissions"
        )}
      />

      {/* */}

      <Route
        name="EventLightIntegrations"
        path="integrations"
        getComponent={EventLight.EventLightIntegrations}
      />
      <Route
        name="EventLightIntegrationsFuzion"
        path="integrations/fuzion"
        getComponent={EventLight.EventLightIntegrationsFuzion}
      />

      {/* */}
      <Route
        name="EventLightAgenda"
        path="agenda"
        getComponent={EventLight.EventLightAgenda}
      />

      <Route
        name="EventLightAgendaSpeakers"
        path="agenda/speakers"
        getComponent={EventLight.EventLightAgenda}
      />

      <Route
        name="EventLightAgendaSettings"
        path="agenda/settings"
        getComponent={EventLight.EventLightAgenda}
      />
      {/* */}

      <Route
        name="EventLightFileRequests"
        path="file-requests"
        getComponent={canUserView(
          EventLight.EventLightFileRequests,
          STANDARD_MODULES.documentRequests.id,
          "read"
        )}
      />
      <Route
        name="EventLightFileRequestResults"
        path="file-requests/:fileRequestId/results"
        getComponent={canUserView(
          EventLight.EventLightFileRequest,
          STANDARD_MODULES.documentRequests.id,
          "read"
        )}
      />

      <Route
        name="EventLightForms"
        path="forms"
        getComponent={canUserView(
          EventLight.EventLightForms,
          STANDARD_MODULES.formsv3.id,
          "read"
        )}
      />
      <Route
        name="EventLightFormsResults"
        path="forms/:formId/results"
        getComponent={canUserView(
          EventLight.EventLightForm,
          STANDARD_MODULES.formsv3.id,
          "read"
        )}
      />

      <Route name="ModuleForm" getComponent={canUserView(Module.Form)}>
        <Route
          name="ModuleFormSettings"
          path="forms/:viewId/settings"
          getComponent={Module.FormSettings}
        />
        <Route
          name="ModuleFormSettingsCommunication"
          path="forms/:viewId/settings/communication"
          getComponent={Module.FormSettings}
        />
        <Route
          name="ModuleFormSettingsApprovers"
          path="forms/:viewId/settings/approvers"
          getComponent={Module.FormSettings}
        />
        <Route
          name="ModuleFormSettingsAdvanced"
          path="forms/:viewId/settings/advanced"
          getComponent={Module.FormSettings}
        />
        <Route
          name="ModuleFormShare"
          path="forms/:viewId/settings/share"
          getComponent={Module.FormShare}
        />
        <Route
          name="ModuleFormShareAssign"
          path="forms/:viewId/settings/share/assign"
          getComponent={Module.FormShare}
        />
        <Route name="formsV2_Form" getComponent={FormV2.Form}>
          <Route
            name="formsV3_Edit"
            path="forms/:viewId/builder"
            getComponent={FormV2.V3FormEdit}
          />
        </Route>
      </Route>

      {/* */}

      <Route
        name="EventLightPortals"
        path="portals"
        getComponent={canUserView(
          EventLight.EventLightPortals,
          STANDARD_MODULES.portal.id,
          "read"
        )}
      />
      <Route
        name="EventLightPortalsPages"
        path="portals/pages"
        getComponent={canUserView(
          EventLight.EventLightPortals,
          STANDARD_MODULES.portal.id,
          "read"
        )}
      />

      <Route
        name="EventLightProductionSchedules"
        path="schedules/:scheduleId"
        getComponent={canUserView(
          EventLight.EventLightProductionSchedules,
          STANDARD_MODULES.schedules.id,
          "read"
        )}
      />
      <Route
        name="EventLightAlerts"
        path="alerts"
        getComponent={EventLight.EventLightAlerts}
      />

      <Route
        name="EventLightTasks"
        path="tasks"
        getComponent={EventLight.EventLightTasks}
      />

      {/* Emails */}

      <Route
        name="EventLightEmails"
        path="emails"
        getComponent={EventLight.EventLightEmailsWrapper}
      >
        <Route
          name="EventLightEmailsHistory"
          path="history/:historyType"
          getComponent={EventLight.EventLightEmailsHistory}
        />
      </Route>

      {/* */}
      <Route
        name="EventLightInventoryDashboard"
        path="inventory"
        getComponent={canUserView(
          EventLight.EventLightInventory,
          STANDARD_MODULES.inventory.id,
          "read"
        )}
      />
      <Route
        name="EventLightInventorySendEmails"
        path="inventory/send-emails"
        getComponent={canUserView(
          EventLight.EventLightInventory,
          STANDARD_MODULES.inventory.id,
          "read"
        )}
      />
      <Route
        name="EventLightInventoryRequests"
        path="inventory/requests/:viewId(/category/:categoryId)(/account/:accountId)(/item-type/:itemTypeId)"
        getComponent={canUserView(
          EventLight.EventLightInventory,
          STANDARD_MODULES.inventory.id,
          "read"
        )}
      />
      <Route
        name="EventLightInventorySettings"
        path="inventory/settings"
        getComponent={canUserView(
          EventLight.EventLightInventory,
          STANDARD_MODULES.inventory.id,
          "manage"
        )}
      />
      <Route
        name="EventLightInventorySettingsApprovers"
        path="inventory/settings/approvers"
        getComponent={canUserView(
          EventLight.EventLightInventory,
          STANDARD_MODULES.inventory.id,
          "manage"
        )}
      />
      <Route
        name="EventLightInventorySettingsQuestions"
        path="inventory/settings/questions"
        getComponent={canUserView(
          EventLight.EventLightInventory,
          STANDARD_MODULES.inventory.id,
          "manage"
        )}
      />

      {/* */}
      <Route
        name="EventLightPassesDashboard"
        path="passes"
        getComponent={canUserView(
          EventLight.EventLightPasses,
          STANDARD_MODULES.credentials.id,
          "read"
        )}
      />
      <Route
        name="EventLightPassesSendEmails"
        path="passes/send-emails"
        getComponent={canUserView(
          EventLight.EventLightPasses,
          STANDARD_MODULES.credentials.id,
          "read"
        )}
      />
      <Route
        name="EventLightPassesRequests"
        path="passes/requests/:viewId(/category/:categoryId)(/account/:accountId)(/item-type/:itemTypeId)"
        getComponent={canUserView(
          EventLight.EventLightPasses,
          STANDARD_MODULES.credentials.id,
          "read"
        )}
      />
      <Route
        name="EventLightPassesSettings"
        path="passes/settings"
        getComponent={canUserView(
          EventLight.EventLightPasses,
          STANDARD_MODULES.credentials.id,
          "manage"
        )}
      />
      <Route
        name="EventLightPassesSettingsZones"
        path="passes/settings/zones"
        getComponent={canUserView(
          EventLight.EventLightPasses,
          STANDARD_MODULES.credentials.id,
          "manage"
        )}
      />
      <Route
        name="EventLightPassesSettingsInventory"
        path="passes/settings/inventory"
        getComponent={canUserView(
          EventLight.EventLightPasses,
          STANDARD_MODULES.credentials.id,
          "manage"
        )}
      />
      <Route
        name="EventLightPassesSettingsZoneGrid"
        path="passes/settings/zone-grid"
        getComponent={canUserView(
          EventLight.EventLightPasses,
          STANDARD_MODULES.credentials.id,
          "manage"
        )}
      />
      <Route
        name="EventLightPassesSettingsApprovers"
        path="passes/settings/approvers"
        getComponent={canUserView(
          EventLight.EventLightPasses,
          STANDARD_MODULES.credentials.id,
          "manage"
        )}
      />
      <Route
        name="EventLightPassesSettingsQuestions"
        path="passes/settings/questions"
        getComponent={canUserView(
          EventLight.EventLightPasses,
          STANDARD_MODULES.credentials.id,
          "manage"
        )}
      />
      <Route
        name="EventLightPassesSettingsActions"
        path="passes/settings/actions"
        getComponent={canUserView(
          EventLight.EventLightPasses,
          STANDARD_MODULES.credentials.id,
          "manage"
        )}
      />
      <Route
        name="EventLightPassesSettingsDesigns"
        path="passes/settings/design"
        getComponent={canUserView(
          EventLight.EventLightPasses,
          STANDARD_MODULES.credentials.id,
          "manage"
        )}
      />
      <Route
        name="EventLightPassesSettingsIntegrations"
        path="passes/settings/integrations"
        getComponent={canUserView(
          EventLight.EventLightPasses,
          STANDARD_MODULES.credentials.id,
          "manage"
        )}
      />

      {/* Catering */}
      <Route
        name="EventLightCateringDashboard"
        path="catering"
        getComponent={canUserView(
          EventLight.EventLightCatering,
          STANDARD_MODULES.catering.id,
          "read"
        )}
      />
      <Route
        name="EventLightCateringSendEmails"
        path="catering/send-emails"
        getComponent={canUserView(
          EventLight.EventLightCatering,
          STANDARD_MODULES.catering.id,
          "read"
        )}
      />
      <Route
        name="EventLightCateringRequests"
        path="catering/requests/:viewId(/category/:categoryId)(/account/:accountId)(/item-type/:itemTypeId)"
        getComponent={canUserView(
          EventLight.EventLightCatering,
          STANDARD_MODULES.catering.id,
          "read"
        )}
      />
      <Route
        name="EventLightCateringSettingsMeals"
        path="catering/settings"
        getComponent={canUserView(
          EventLight.EventLightCatering,
          STANDARD_MODULES.catering.id,
          "manage"
        )}
      />
      <Route
        name="EventLightCateringSettingsApprovers"
        path="catering/settings/approvers"
        getComponent={canUserView(
          EventLight.EventLightCatering,
          STANDARD_MODULES.catering.id,
          "manage"
        )}
      />
      <Route
        name="EventLightCateringSettingsOverride"
        path="catering/settings/override"
        getComponent={canUserView(
          EventLight.EventLightCatering,
          STANDARD_MODULES.catering.id,
          "manage"
        )}
      />
      {/* */}
      <Route
        name="EventLightBoothsDashboard"
        path="booths"
        getComponent={canUserView(
          EventLight.EventLightBooths,
          STANDARD_MODULES.booths.id,
          "read"
        )}
      />
      <Route
        name="EventLightBoothsRequests"
        path="booths/requests/:viewId(/category/:categoryId)(/account/:accountId)(/item-type/:itemTypeId)"
        getComponent={canUserView(
          EventLight.EventLightBooths,
          STANDARD_MODULES.booths.id,
          "read"
        )}
      />
      <Route
        name="EventLightBoothsSettings"
        path="booths/settings"
        getComponent={canUserView(
          EventLight.EventLightBooths,
          STANDARD_MODULES.booths.id,
          "manage"
        )}
      />

      <Route
        name="EventLightGuestLists"
        path="guest-lists(/:recordTypeId)"
        getComponent={EventLight.EventLightGuestLists}
      />

      {/* */}

      <Route
        name="EventLightSettingsEmailTemplates"
        path="settings/email-templates"
        getComponent={canUserView(
          EventLight.EventLightSettings,
          STANDARD_MODULES.settings.id,
          "manage_email_templates"
        )}
      />
      <Route
        name="EventLightSettingsDocumentTemplates"
        path="settings/document-templates"
        getComponent={EventLight.EventLightSettings}
      />
      <Route
        name="EventLightSettingsInvoiceSettings"
        path="settings/invoice-settings"
        getComponent={EventLight.EventLightSettings}
      />
      <Route
        name="EventLightSettingsApprovalWorkflows"
        path="settings/approval-workflows"
        getComponent={EventLight.EventLightSettings}
      />
      <Route
        name="EventLightSettingsWebhooks"
        path="settings/webhooks"
        getComponent={EventLight.EventLightSettings}
      />

      {/* */}

      <Route getComponent={Event.ContactProfileContainer}>
        <Route name="EventLightContactProfile" path="contact/:recordId" />
        <Route
          name="EventLightContactActivity"
          path="contact/:recordId/activity"
        />
      </Route>

      <Route
        getComponent={canUserView(
          Account.Profile,
          STANDARD_MODULES.accounts.id
        )}
      >
        <Route path="account/:recordId" name="EventLightAccountProfile" />
        <Route
          path="account/:recordId/activity"
          name="EventLightAccountActivity"
        />
        <Route path="account/:recordId/people" name="EventLightAccountPeople" />
        <Route
          path="account/:recordId/catering"
          name="EventLightAccountCatering"
        />
        <Route
          path="account/:recordId/credentials"
          name="EventLightAccountCredentials"
        />
        <Route
          path="account/:recordId/inventory"
          name="EventLightAccountInventory"
        />
      </Route>
    </Route>

    <Route name="PlatformView" getComponent={Platform.PlatformView}>
      <Route name="HomeView" getComponent={Platform.HomeView} path="/home" />

      <Route name="accountSettings" getComponent={Account.SettingsHandler}>
        <Route
          name="accountSettingsSettings"
          path="/account/settings"
          getComponent={Account.SettingsSettingsHandler}
        />
      </Route>

      <Route
        name="organization"
        getComponent={canUserView(Organization.Handler)}
      >
        <Route
          name="Events"
          getComponent={Organization.Events}
          path="/organization/:orgId/events"
        />

        <Route
          name="Files"
          getComponent={Organization.Files}
          path="/organization/:orgId/files"
        />

        <Route
          name="organizationHome"
          path="/organization/:orgId/home"
          getComponent={canUserView(Organization.Home)}
        />

        <Route
          name="organizationSettings"
          getComponent={Organization.SettingsHandler}
        >
          <Route
            name="organizationSettingsGeneral"
            path="/organization/:orgId/settings"
            getComponent={canUserView(
              Organization.GeneralSettings,
              STANDARD_MODULES.orgSettings.id
            )}
          />
          <Route
            name="organizationSettingsUsers"
            path="/organization/:orgId/settings/users"
            getComponent={canUserView(
              Organization.UsersSettings,
              STANDARD_MODULES.orgSettings.id
            )}
          />
          <Route
            name="organizationSettingsPermissions"
            path="/organization/:orgId/settings/permissions"
            getComponent={canUserView(
              Organization.UsersSettings,
              STANDARD_MODULES.orgSettings.id,
              "manage_permissions"
            )}
          />
          <Route
            name="organizationSettingsModule"
            path="/organization/:orgId/settings/module/:moduleId"
            getComponent={canUserView(
              Organization.SettingsModule,
              null,
              "manage"
            )}
          />
        </Route>

        <Route
          name="OrgModuleRecords"
          path="/organization/:orgId/module/:moduleId(/:view)"
          getComponent={canUserView(Module.Records)}
        />
        <Route
          name="OrgModuleRecords"
          path="/organization/:orgId/module/:moduleId/view/:viewId"
          getComponent={canUserView(Module.Records)}
        />
        <Route
          name="OrgModuleReport"
          path="/organization/:orgId/module/:moduleId/report/:viewId"
          getComponent={canUserView(Module.AccountsReports)}
        />
        <Route
          name="OrgModuleRecordsRecordModal"
          path="/organization/:orgId/module/:moduleId/record/:recordId"
          getComponent={canUserView(Module.Records)}
        />
        <Route name="OrgModuleForm" getComponent={canUserView(Module.Form)}>
          <Route name="orgFormsV2_Form" getComponent={FormV2.Form}>
            <Route
              name="orgFormsV3_Edit"
              path="/organization/:orgId/module/:moduleId/form/:viewId"
              getComponent={FormV2.V3FormEdit}
            />
          </Route>
        </Route>
        <Route
          name="orgContactProfileBase"
          getComponent={canUserView(
            Organization.ContactProfile,
            STANDARD_MODULES.contacts.id
          )}
        >
          <Route
            path="/organization/:orgId/contact/:recordId"
            name="OrgContactProfile"
          />
          <Route
            path="/organization/:orgId/contact/:recordId/activity"
            name="OrgContactProfileActivities"
          />
        </Route>
        <Route
          name="accountProfileBase"
          getComponent={canUserView(
            Organization.AccountProfile,
            STANDARD_MODULES.accounts.id
          )}
        >
          <Route
            path="/organization/:orgId/account/:recordId"
            name="orgAccountProfileOverview"
          />
          <Route
            path="/organization/:orgId/account/:recordId/activity"
            name="orgAccountProfileActivities"
          />
        </Route>
        <Route
          name="OrgModuleRecordView"
          getComponent={canUserView(Module.RecordView)}
          path="/organization/:orgId/module/:moduleId/records/:recordId"
        />
      </Route>

      <Route
        name="editEventProfile"
        getComponent={canUserView(Event.EventHandler)}
      >
        <Route name="eventHome" getComponent={Event.EventHomeHandler}>
          <Route
            name="eventHomeContent"
            path="/event/:eventId/home"
            getComponent={Event.EventHomeContent}
          />

          <Route
            name="eventSetupChecklists"
            path="/event/:eventId/setup-checklists"
            getComponent={Event.EventHomeSetupChecklists}
          />
          <Route
            name="eventMyApprovals"
            path="/event/:eventId/my-approvals"
            getComponent={Event.EventHomeMyApprovals}
          />
        </Route>
        <Route
          name="eventMyTasks"
          path="/event/:eventId/my-tasks"
          getComponent={Event.MyTasks}
        />

        <Route
          name="Files"
          getComponent={Event.Files}
          path="/event/:eventId/files"
        />

        <Route
          name="eventActivities"
          path="/event/:eventId/activity"
          getComponent={Event.EventActivities}
        />

        <Route name="editEventSettings" getComponent={Event.SettingsHandler}>
          <Route
            name="editEventSettingsSettings"
            path="/event/:eventId/settings"
            getComponent={Event.SettingsSettingsHandler}
          />
          <Route
            name="editEventSettingsEventDays"
            path="/event/:eventId/settings/event-days"
            getComponent={Event.SettingsSettingsHandler}
          />
          <Route
            name="editEventSettingsUsers"
            path="/event/:eventId/settings/users"
            getComponent={canUserView(
              Event.SettingsUsersHandler,
              STANDARD_MODULES.settings.id
            )}
          />
          <Route
            name="editEventSettingsPermissions"
            path="/event/:eventId/settings/permissions"
            getComponent={canUserView(
              Event.SettingsUsersHandler,
              STANDARD_MODULES.settings.id,
              "manage_permissions"
            )}
          />
          <Route
            name="editEventSettingsOrderFields"
            path="/event/:eventId/settings/orders/fields"
            getComponent={canUserView(
              Event.SettingsOrders,
              STANDARD_MODULES.orders.id
            )}
          />
          <Route
            name="editEventSettingsScheduleFields"
            path="/event/:eventId/settings/schedules/fields"
            getComponent={Event.SettingsSchedules}
          />
          <Route
            name="editEventSettingsOrderPayments"
            path="/event/:eventId/settings/orders/payments"
            getComponent={canUserView(
              Event.SettingsOrders,
              STANDARD_MODULES.orders.id
            )}
          />
          <Route
            name="editEventSettingsIntegrationFuzion"
            path="/event/:eventId/settings/integrations/fuzion"
            getComponent={Event.SettingsIntegrationFuzion}
          />
          <Route
            name="editEventSettingsApprovalWorkflows"
            path="/event/:eventId/settings/approval-workflows"
            getComponent={Event.SettingsApprovalWorkflows}
          />
          <Route
            name="editEventSettingsCredentials"
            path="/event/:eventId/settings/credentials"
            getComponent={canUserView(
              Event.SettingsCredentials,
              STANDARD_MODULES.credentials.id,
              "manage"
            )}
          />
          <Route
            name="editEventSettingsCredentials"
            path="/event/:eventId/settings/credentials/types"
            getComponent={canUserView(
              Event.SettingsCredentials,
              STANDARD_MODULES.credentials.id,
              "manage"
            )}
          />
          <Route
            name="editEventSettingsCredentialsApprovalWorkflows"
            path="/event/:eventId/settings/credentials/approval-workflows"
            getComponent={canUserView(
              Event.SettingsCredentials,
              STANDARD_MODULES.credentials.id,
              "manage"
            )}
          />
          <Route
            name="editEventSettingsCatering"
            path="/event/:eventId/settings/catering"
            getComponent={canUserView(
              Event.SettingsCatering,
              STANDARD_MODULES.catering.id,
              "manage"
            )}
          />
          <Route
            name="editEventSettingsCateringManagerOverride"
            path="/event/:eventId/settings/catering/manager-override"
            getComponent={canUserView(
              Event.SettingsCatering,
              STANDARD_MODULES.catering.id,
              "manage"
            )}
          />
          <Route
            name="editEventSettingsCateringApprovalWorkflows"
            path="/event/:eventId/settings/catering/approval-workflows"
            getComponent={canUserView(
              Event.SettingsCatering,
              STANDARD_MODULES.catering.id,
              "manage"
            )}
          />
          <Route
            name="editEventSettingsBoothApprovalWorkflows"
            path="/event/:eventId/settings/booths/approval-workflows"
            getComponent={canUserView(
              Event.SettingsCatering,
              STANDARD_MODULES.booths.id,
              "manage"
            )}
          />
          <Route
            name="editEventSettingsInventoryCatalog"
            path="/event/:eventId/settings/inventory/catalog"
            getComponent={canUserView(
              Event.SettingsInventoryCatalog,
              STANDARD_MODULES.inventory.id,
              "manage"
            )}
          />
          <Route
            name="editEventSettingsEmailTemplates"
            path="/event/:eventId/settings/email-templates"
            getComponent={canUserView(
              Event.SettingsEmailTemplates,
              STANDARD_MODULES.settings.id,
              "manage_email_templates"
            )}
          />
          <Route
            name="editEventSettingsDocumentTemplates"
            path="/event/:eventId/settings/document-templates"
            getComponent={Event.SettingsDocumentTemplates}
          />
          <Route
            name="editEventSettingsFormTemplates"
            path="/event/:eventId/settings/forms"
            getComponent={Event.SettingsFormTemplates}
          />
          <Route
            name="editEventSettingsPortal"
            path="/event/:eventId/settings/portal"
            getComponent={Event.SettingsPortal}
          />
          <Route
            name="editEventSettingsSalesPortal"
            path="/event/:eventId/settings/sales-portal"
            getComponent={Event.SettingsSalesPortal}
          />
          <Route
            name="settingsIntegrations"
            path="/event/:eventId/settings/integrations"
            getComponent={Event.SettingsIntegrations}
          />
          <Route
            name="editEventSettingsLocations"
            path="/event/:eventId/settings/locations"
            getComponent={Event.SettingsLocations}
          />
          <Route
            name="editEventSettingsCatalogQuestions"
            getComponent={Event.RefactorSettingsCatalog}
          >
            <Route
              path="/event/:eventId/settings/catalog/questions"
              getComponent={Event.SettingsCatalogQuestions}
            />
          </Route>
          <Route
            name="editEventSettingsCatalogZones"
            getComponent={Event.RefactorSettingsCatalog}
          >
            <Route
              path="/event/:eventId/settings/catalog/type/:typeId/zones"
              getComponent={Event.SettingsCatalogZones}
            />
          </Route>
          <Route
            name="editEventSettingsCatalogItems"
            getComponent={Event.RefactorSettingsCatalog}
          >
            <Route
              path="/event/:eventId/settings/catalog/type/:typeId/items"
              getComponent={Event.RefactorCatalogItems}
            />
          </Route>
          <Route
            name="editEventSettingsCatalogInventory"
            getComponent={Event.RefactorSettingsCatalog}
          >
            <Route
              path="/event/:eventId/settings/catalog/type/:typeId/inventory"
              getComponent={Event.ItemCatalogInventory}
            />
          </Route>
          <Route
            name="editEventSettingsCatalogApprovers"
            getComponent={Event.RefactorSettingsCatalog}
          >
            <Route
              path="/event/:eventId/settings/catalog/type/:typeId/approvers"
              getComponent={Event.ItemCatalogApprovers}
            />
          </Route>
          <Route
            name="editEventSettingsCatalogAccessGrid"
            getComponent={Event.RefactorSettingsCatalog}
          >
            <Route
              path="/event/:eventId/settings/catalog/type/:typeId/access-grid"
              getComponent={Event.AccessGrid}
            />
          </Route>
          <Route
            name="editEventSettingsCatalogActions"
            getComponent={Event.RefactorSettingsCatalog}
          >
            <Route
              path="/event/:eventId/settings/catalog/type/:typeId/actions"
              getComponent={Event.Actions}
            />
          </Route>
          <Route
            name="editEventSettingsCatalogActuals"
            path="/event/:eventId/settings/catalog/type/:typeId/actuals"
            getComponent={Event.SettingsCatalog}
          />
          <Route
            name="editEventSettingsCatalogBlocks"
            path="/event/:eventId/settings/catalog/type/:typeId/item-blocks"
            getComponent={Event.SettingsCatalog}
          />
          <Route
            name="eventSettingsModule"
            path="/event/:eventId/settings/module/:moduleId"
            getComponent={canUserView(
              Event.EventSettingsModule,
              null,
              "manage"
            )}
          />

          <Route
            name="eventSettingsModuleApprovalWorkflows"
            path="/event/:eventId/settings/module/:moduleId/approval-workflows"
            getComponent={canUserView(
              Event.EventSettingsModule,
              null,
              "manage"
            )}
          />
          <Route
            name="eventSettingsModule"
            path="/event/:eventId/settings/module/:moduleId/fields"
            getComponent={canUserView(
              Event.EventSettingsModule,
              null,
              "manage"
            )}
          />
          <Route
            name="eventSettingsModuleTypes"
            path="/event/:eventId/settings/module/:moduleId/types"
            getComponent={canUserView(
              Event.EventSettingsModule,
              null,
              "manage"
            )}
          />
          <Route
            name="eventSettingsModuleTypesTypeId"
            path="/event/:eventId/settings/module/:moduleId/types/:typeId"
            getComponent={canUserView(
              Event.EventSettingsModule,
              null,
              "manage"
            )}
          />
          <Route
            name="eventSettingsModuleTypesPortal"
            path="/event/:eventId/settings/module/:moduleId/types/:typeId/portal"
            getComponent={canUserView(
              Event.EventSettingsModule,
              null,
              "manage"
            )}
          />
          <Route
            name="eventSettingsModuleTypesGuestList"
            path="/event/:eventId/settings/module/:moduleId/types/:typeId/guest-list"
            getComponent={canUserView(
              Event.EventSettingsModule,
              null,
              "manage"
            )}
          />
          <Route
            name="eventSettingsModuleTypesRecordLayout"
            path="/event/:eventId/settings/module/:moduleId/types/:typeId/record-layout"
            getComponent={canUserView(
              Event.EventSettingsModule,
              null,
              "manage"
            )}
          />
          <Route
            name="eventSettingsModuleTypesAddRecordLayout"
            path="/event/:eventId/settings/module/:moduleId/types/:typeId/add-record-layout"
            getComponent={canUserView(
              Event.EventSettingsModule,
              null,
              "manage"
            )}
          />
          <Route
            name="eventSettingsModuleTypesCardLayout"
            path="/event/:eventId/settings/module/:moduleId/types/:typeId/card-layout"
            getComponent={canUserView(
              Event.EventSettingsModule,
              null,
              "manage"
            )}
          />
          <Route
            name="eventSettingsModuleTypesReportLayout"
            path="/event/:eventId/settings/module/:moduleId/types/:typeId/report-layout"
            getComponent={canUserView(
              Event.EventSettingsModule,
              null,
              "manage"
            )}
          />
          <Route
            name="eventSettingsModuleSettings"
            path="/event/:eventId/settings/module/:moduleId/settings"
            getComponent={canUserView(
              Event.EventSettingsModule,
              null,
              "manage"
            )}
          />
          <Route
            name="eventSettingsModuleDocumentTemplates"
            path="/event/:eventId/settings/module/:moduleId/document-templates"
            getComponent={canUserView(
              Event.EventSettingsModule,
              null,
              "manage"
            )}
          />
          <Route
            name="eventSettingsModuleReportTemplates"
            path="/event/:eventId/settings/module/:moduleId/report-templates"
            getComponent={canUserView(
              Event.EventSettingsModule,
              null,
              "manage"
            )}
          />
          <Route
            name="eventSettingsModuleQuickSetup"
            path="/event/:eventId/settings/module/:moduleId/quick-setup"
            getComponent={canUserView(
              Event.EventSettingsModule,
              null,
              "manage"
            )}
          />
        </Route>

        {/* Forms V2 - Event */}
        <Route
          name="formResults"
          path="/event/:eventId/forms/:formId/results"
          getComponent={FormV2.FormResults}
        />
        <Route
          name="formsV2_FormOverview"
          path="/event/:eventId/forms-v2/:formId/overview"
          getComponent={FormV2.FormOverview}
        />
        <Route
          name="formsV2_FormOverview_With_Submission"
          path="/event/:eventId/forms-v2/:formId/overview/:submissionId"
          getComponent={FormV2.FormOverview}
        />

        <Route name="formsV2_Form" getComponent={FormV2.Form}>
          <Route
            name="formsV2_Edit"
            path="/event/:eventId/forms-v2/:formId/edit"
            getComponent={FormV2.FormEdit}
          />
          <Route
            name="formsV2_Preview"
            path="/event/:eventId/forms-v2/:formId/preview"
            getComponent={FormV2.FormPreview}
          />
          <Route
            name="formsV3_Edit"
            path="/event/:eventId/forms-v3/:formId/edit"
            getComponent={FormV2.V3FormEdit}
          />
          <Route
            name="formsV3_Preview"
            path="/event/:eventId/forms-v3/:formId/preview"
            getComponent={FormV2.V3FormPreview}
          />
        </Route>

        <Route
          name="PortalPages"
          path="/event/:eventId/portals/pages"
          getComponent={Portal.ManagePortals}
        />

        <Route
          name="formsV2_FormsList"
          path="/event/:eventId/forms-v2"
          getComponent={FormV2.FormsList}
        />
        <Route
          name="PortalList"
          path="/event/:eventId/portals"
          getComponent={Portal.ManagePortals}
        />
        <Route
          name="PortalPages"
          path="/event/:eventId/portals/pages"
          getComponent={Portal.ManagePortals}
        />

        <Route
          name="PortalAlerts"
          path="/event/:eventId/portals/alerts"
          getComponent={Portal.ManagePortals}
        />
        <Route
          name="PortalContent"
          path="/event/:eventId/portals/content"
          getComponent={Portal.ManagePortals}
        />

        <Route
          name="contactProfileBase"
          getComponent={canUserView(
            Event.ContactProfileContainer,
            STANDARD_MODULES.contacts.id
          )}
        >
          <Route
            name="contactProfile"
            path="/event/:eventId/contact/:recordId"
          />
          <Route
            name="contactProfileActivities"
            path="/event/:eventId/contact/:recordId/activity"
          />
        </Route>
        <Route
          name="eventLocations"
          getComponent={Event.Locations}
          path="/event/:eventId/locations"
        />
        <Route
          name="eventLocations"
          getComponent={Event.Locations}
          path="/event/:eventId/locations/:parentRecordId"
        />
        <Route
          name="eventLocations"
          getComponent={Event.Locations}
          path="/event/:eventId/locations"
        />
        <Route
          name="eventLocations"
          getComponent={Event.Locations}
          path="/event/:eventId/locations/:parentRecordId"
        />
        <Route
          name="eventLocations"
          getComponent={Event.Locations}
          path="/event/:eventId/locations"
        />
        <Route
          name="eventLocations"
          getComponent={Event.Locations}
          path="/event/:eventId/locations/:parentRecordId"
        />
        <Route
          name="EventReports"
          getComponent={Module.Reports}
          path="/event/:eventId/reports"
        />

        <Route
          name="EventReport"
          getComponent={Event.Report}
          path="/event/:eventId/reports/:reportId"
        />

        <Route
          name="ManageInventoryContainer"
          getComponent={canUserView(
            Inventory.ManageContainer,
            STANDARD_MODULES.inventory.id
          )}
        >
          <Route
            name="eventInventoryDashboard"
            getComponent={Inventory.Dashboard}
            path="/event/:eventId/inventory/dashboard"
          />
          <Route
            name="ManageInventory"
            getComponent={Inventory.Manage}
            path="/event/:eventId/inventory/manage/:viewId(/category/:categoryId)(/account/:accountId)(/item-type/:itemTypeId)"
          />
          <Route
            name="InventoryConfirmation"
            getComponent={Passes.Confirmations}
            path="/event/:eventId/inventory/confirm"
          />
        </Route>
        <Route
          name="eventInventoryContainer"
          getComponent={canUserView(
            Inventory.Container,
            STANDARD_MODULES.inventory.id
          )}
        >
          <Route
            name="eventInventoryHomeMain"
            getComponent={Inventory.Home}
            path="/event/:eventId/inventory"
          />
          <Route
            name="eventInventoryNeedsOverview"
            getComponent={Inventory.NeedsOverview}
            path="/event/:eventId/inventory/needs"
          />
          <Route
            name="eventInventoryNeedsByStatus"
            getComponent={Inventory.NeedsByStatus}
            path="/event/:eventId/inventory/needs/status/:view"
          />
          <Route
            name="eventInventoryCatalog"
            getComponent={Inventory.Catalog}
            path="/event/:eventId/inventory/catalog"
          />
          <Route
            name="eventInventoryCatalog"
            getComponent={Inventory.Catalog}
            path="/event/:eventId/inventory/catalog/view/:viewId"
          />
          <Route
            name="eventInventoryCatalog"
            getComponent={Inventory.Catalog}
            path="/event/:eventId/inventory/catalog/:parentRecordId/view/:viewId"
          />
        </Route>

        <Route name="OrdersContainer" getComponent={Orders.Container}>
          <Route
            name="OrdersList"
            getComponent={Orders.List}
            path="/event/:eventId/orders/:view"
          />
        </Route>
        <Route
          name="CateringContainer"
          getComponent={canUserView(
            Catering.Main,
            STANDARD_MODULES.catering.id
          )}
        >
          <Route
            name="CateringDashboard"
            getComponent={Catering.Dashboard}
            path="/event/:eventId/catering/dashboard"
          />
          <Route
            name="CateringManageBy"
            getComponent={Catering.Manage}
            path="/event/:eventId/catering/manage/:viewId(/category/:categoryId)(/account/:accountId)(/item-type/:itemTypeId)"
          />
          <Route
            name="CateringConfirmation"
            getComponent={Passes.Confirmations}
            path="/event/:eventId/catering/confirm"
          />
        </Route>

        <Route
          name="BoothsGroupList"
          getComponent={Booths.GroupList}
          path="/event/:eventId/booths/group-list"
        />

        <Route
          name="BoothsContainer"
          getComponent={canUserView(Booths.Main, STANDARD_MODULES.booths.id)}
        >
          <Route
            name="BoothsManageBy"
            getComponent={Booths.Manage}
            path="/event/:eventId/booths/manage/:viewId"
          />

          <Route
            name="BoothsConfirmation"
            getComponent={Passes.Confirmations}
            path="/event/:eventId/booths/confirm"
          />

          <Route
            name="boothSelfSetup"
            getComponent={Booths.Container}
            path="/event/:eventId/booths/setup"
          >
            <IndexRedirect to="packages" />
            <Route
              name="boothSelfSetupSettings"
              path=":stepId"
              getComponent={Booths.SelfSetup}
            />
          </Route>
        </Route>
        <Route
          name="PassesContainer"
          getComponent={canUserView(
            Passes.Container,
            STANDARD_MODULES.credentials.id
          )}
        >
          <Route
            name="PassesDashboard"
            getComponent={Passes.Dashboard}
            path="/event/:eventId/passes/dashboard"
          />
          <Route
            name="PassesManageOrders"
            getComponent={Passes.ManageAll}
            path="/event/:eventId/passes/manage/all-orders"
          />
          <Route
            name="PassesManageBy"
            getComponent={Passes.ManageBy}
            path="/event/:eventId/passes/manage/:viewId(/category/:categoryId)(/account/:accountId)(/item-type/:itemTypeId)"
          />
          <Route
            name="PassesConfirmation"
            getComponent={Passes.Confirmations}
            path="/event/:eventId/passes/confirm"
          />
          <Route
            name="PassesManageBySource"
            getComponent={Passes.ManageSources}
            path="/event/:eventId/passes/manage/sources/:sourceId"
          />
          <Route
            name="PassesManageByPassType"
            getComponent={Passes.ManageTypes}
            path="/event/:eventId/passes/manage/by-pass-type"
          />
          <Route
            name="PassesManageByGroup"
            getComponent={Passes.ManageGroups}
            path="/event/:eventId/passes/manage/by-group"
          />
          <Route
            name="PassesManageByAssignee"
            getComponent={Passes.ManageAssignee}
            path="/event/:eventId/passes/manage/by-assignee"
          />
          <Route
            name="PassesGuestList"
            getComponent={Passes.ManageGuestList}
            path="/event/:eventId/passes/guest-list/:recordTypeId"
          />
          <Route
            name="PassesApplication"
            getComponent={Passes.ManageApplication}
            path="/event/:eventId/passes/manage/applications/:applicationFormId"
          />
          <Route
            name="PassesActionsSendConfirmations"
            getComponent={Passes.ActionsSendConfirmations}
            path="/event/:eventId/passes/actions/send-confirmations"
          />
          <Route
            name="PassesReports"
            getComponent={Passes.Reports}
            path="/event/:eventId/passes/reports"
          />
          <Route
            name="PassesCheckin"
            getComponent={canUserView(
              Passes.Checkin,
              STANDARD_MODULES.credentials.id,
              "checkin"
            )}
            path="/event/:eventId/passes/checkin(/:recordTypeId)"
          />
          <Route name="PassesSetup" getComponent={Passes.Setup}>
            <Route
              name="PassesSetupOverview"
              getComponent={Passes.SetupOverview}
              path="/event/:eventId/passes/setup"
            />
            <Route
              name="PassesSetupTypes"
              getComponent={Passes.SetupTypes}
              path="/event/:eventId/passes/setup/types"
            />
            <Route
              name="PassesSetupApprovers"
              getComponent={Passes.SetupApprovers}
              path="/event/:eventId/passes/setup/approvers"
            />
            <Route
              name="PassesSetupApproversAdvanced"
              getComponent={Passes.SetupApprovers}
              path="/event/:eventId/passes/setup/approvers/advanced"
            />
            <Route
              name="PassesSetupConfirmations"
              getComponent={Passes.SetupConfirmations}
              path="/event/:eventId/passes/setup/confirmations"
            />
            <Route
              name="PassesSetupCollect"
              getComponent={Passes.SetupCollect}
              path="/event/:eventId/passes/setup/collect"
            />
            <Route
              name="PassesSetupCollectGuestLists"
              getComponent={Passes.SetupCollectGuestLists}
              path="/event/:eventId/passes/setup/collect/guest-lists"
            />
            <Route
              name="PassesSetupCollectRequestForms"
              getComponent={Passes.SetupCollectRequestForms}
              path="/event/:eventId/passes/setup/collect/request-forms"
            />
            <Route
              name="PassesSetupCollectApplications"
              getComponent={Passes.SetupCollectApplications}
              path="/event/:eventId/passes/setup/collect/applications"
            />
            <Route
              name="PassesSetupIntegrations"
              getComponent={Passes.SetupIntegrations}
              path="/event/:eventId/passes/setup/integrations"
            />
          </Route>
        </Route>

        <Route
          name="eventCateringCheckin"
          getComponent={canUserView(
            Catering.Checkin,
            STANDARD_MODULES.catering.id,
            "checkin"
          )}
          path="/event/:eventId/catering/checkin(/:mealId)"
        />

        <Route
          name="Schedule"
          getComponent={Schedule.Schedule}
          path="/event/:eventId/schedule/:moduleId"
        />

        <Route
          name="ModuleDashboard"
          getComponent={Module.ModuleDashboard}
          path="/event/:eventId/module/:moduleId/dashboard(/type/:recordTypeId)(/people/:contactRecordTypeId)"
        />

        <Route
          name="ModulePortalSettings"
          getComponent={Module.ModulePortalSettings}
          path="/event/:eventId/module/:moduleId/dashboard/type/:recordTypeId/settings"
        />

        <Route
          name="ModuleRecords"
          path="/event/:eventId/module/:moduleId(/:view)"
          getComponent={canUserView(Module.Records)}
        />
        {/* @NOTE: We use this route for Account module filtered by an account record type ID */}
        <Route
          name="ModuleRecords"
          path="/event/:eventId/module/:moduleId(/group/:groupId)(/view/:viewId)"
          getComponent={canUserView(Module.Records)}
        />
        {/* @NOTE: We use this route for custom modules filtered by an account record type ID */}
        <Route
          name="ModuleRecords"
          path="/event/:eventId/module/:moduleId(/group-filter/:filterByAccountRecordTypeId)(/view/:viewId)"
          getComponent={canUserView(Module.Records)}
        />
        <Route
          name="ModuleRecords"
          path="/event/:eventId/module/:moduleId(/contact-filter/:filterByContactRecordTypeId)(/view/:viewId)"
          getComponent={canUserView(Module.Records)}
        />
        <Route
          name="ModuleCatering"
          path="/event/:eventId/module/:moduleId/group/:groupId/catering/manage/:viewId"
          getComponent={canUserView(Module.Records)}
        />
        <Route
          name="ModulePasses"
          path="/event/:eventId/module/:moduleId/group/:groupId/passes/manage/:viewId"
          getComponent={canUserView(Module.Records)}
        />
        <Route
          name="ModuleCatering"
          path="/event/:eventId/module/:moduleId/contact/:groupId/catering/manage/:viewId"
          getComponent={canUserView(Module.Records)}
        />
        <Route
          name="ModulePasses"
          path="/event/:eventId/module/:moduleId/contact/:groupId/passes/manage/:viewId"
          getComponent={canUserView(Module.Records)}
        />
        <Route
          name="ModuleReport"
          path="/event/:eventId/module/:moduleId/report/:viewId"
          getComponent={canUserView(Module.AccountsReports)}
        />
        <Route
          name="ModuleReport"
          path="/event/:eventId/module/:moduleId(/group/:groupId)/report/:viewId"
          getComponent={canUserView(Module.AccountsReports)}
        />
        <Route
          name="ModuleRecordsRecordModal"
          path="/event/:eventId/module/:moduleId/record/:recordId"
          getComponent={canUserView(Module.Records)}
        />

        {/* */}

        <Route name="ModuleForm" getComponent={canUserView(Module.Form)}>
          <Route
            name="ModuleFormSettings"
            path="/event/:eventId/module/:moduleId/form/:viewId/settings"
            getComponent={Module.FormSettings}
          />
          <Route
            name="ModuleFormSettingsCommunication"
            path="/event/:eventId/module/:moduleId/form/:viewId/settings/communication"
            getComponent={Module.FormSettings}
          />
          <Route
            name="ModuleFormSettingsAdvanced"
            path="/event/:eventId/module/:moduleId/form/:viewId/settings/advanced"
            getComponent={Module.FormSettings}
          />
          <Route
            name="ModuleFormShare"
            path="/event/:eventId/module/:moduleId/form/:viewId/share"
            getComponent={Module.FormShare}
          />
          <Route
            name="ModuleFormShareAssign"
            path="/event/:eventId/module/:moduleId/form/:viewId/share/assign"
            getComponent={Module.FormShare}
          />
          <Route name="formsV2_Form" getComponent={FormV2.Form}>
            <Route
              name="formsV3_Edit"
              path="/event/:eventId/module/:moduleId/form/:viewId"
              getComponent={FormV2.V3FormEdit}
            />
            <Route
              name="accountFormEdit"
              path="/event/:eventId/accounts/form/:viewId"
              getComponent={FormV2.V3FormEdit}
            />
            <Route
              name="contactFormEdit"
              path="/event/:eventId/contacts/form/:viewId"
              getComponent={FormV2.V3FormEdit}
            />
          </Route>
        </Route>

        <Route
          name="accountProfileBase"
          getComponent={canUserView(
            Account.Profile,
            STANDARD_MODULES.accounts.id
          )}
        >
          <Route
            path="/event/:eventId/account/:recordId"
            name="accountProfile"
          />
          <Route
            path="/event/:eventId/account/:recordId/activity"
            name="accountProfileActivities"
          />
          <Route
            path="/event/:eventId/account/:recordId/people"
            name="accountProfilePeople"
          />
          <Route
            path="/event/:eventId/account/:recordId/catering"
            name="accountProfileCatering"
          />
          <Route
            path="/event/:eventId/account/:recordId/credentials"
            name="accountProfileCredentials"
          />
          <Route
            path="/event/:eventId/account/:recordId/inventory"
            name="accountProfileInventory"
          />
        </Route>
        <Route
          name="moduleRecordView"
          getComponent={canUserView(Module.RecordView)}
          path="/event/:eventId/module/:moduleId/records/:recordId"
        />
      </Route>
      <Route name="platformNotFound" path="*" component={DefaultNotFound} />
    </Route>
  </Route>
);
