import React from "react";
import { compose } from "ramda";
import {
  Div,
  Text2,
  FontIcon,
  Popover,
  MediumInsetInput
} from "components/Base";
import Avatar from "../ScalableAvatar";
import { withProps, mapProps, defaultProps } from "utils/General";
import FindPerson from "../Popovers/FindPerson";
import Meals from "../Popovers/Meals";
import Credentials from "../Popovers/Credentials";
import { CREDENTIAL_TYPE_ID, MEAL_TYPE_ID, LABELS } from "utils/item-types";

const RESOURCE_PICKERS = {
  [CREDENTIAL_TYPE_ID]: Credentials,
  [MEAL_TYPE_ID]: Meals
};

const menuActionProps = defaultProps({
  px: 3,
  py: 2,
  fw: 3,
  transistion: "fast",
  display: "row.flex-start.center",
  flex: 1,
  width: 1,
  bg: { default: "white", hover: "gray1" }
});

const MenuAction = compose(
  menuActionProps,
  mapProps(({ disabled, ...props }) => {
    if (disabled) {
      return {
        ...props,
        color: "neutral3",
        bg: "white",
        onClick: undefined
      };
    }
    return props;
  }),
  withProps({ fs: 2 })
)(Div);
const MinorMenuAction = compose(menuActionProps, withProps({ fs: 1 }))(Div);
const MenuBase = defaultProps({ width: 165, bg: "white" })(Div);
const MenuSection = defaultProps({
  p: 1,
  display: "column.flex-start.flex-start"
})(Div);
const MenuAvatar = defaultProps({ size: 20, mr: 1 })(Avatar);
const MenuPopover = withProps({
  innerSpanStyle: { width: "100%" },
  wrapperProps: { width: 1 },
  anchorOrigin: { horizontal: "left", vertical: "bottom" }
})(Popover);

const AssignMenu = ({
  assignments,
  eventDays,
  onAssign,
  onCopy,
  people,
  person,
  resourceTypeIds
}) => (
  <MenuBase>
    <MenuSection bb={1} bc="gray4">
      {resourceTypeIds.map(typeId => {
        const { color, label, icon } = LABELS[typeId];
        const Picker = RESOURCE_PICKERS[typeId];

        return (
          <MenuPopover
            key={typeId}
            Label={({ onClick }) => (
              <MenuAction
                onClick={e => {
                  onClick(e);
                }}
              >
                <MenuAvatar
                  style={{ backgroundColor: color }}
                  content={
                    <FontIcon
                      fs={2}
                      style={{ color: "rgba(255, 255, 255, 0.6)" }}
                    >
                      {icon}
                    </FontIcon>
                  }
                />
                {label}
              </MenuAction>
            )}
          >
            {({ closePopover }) => (
              <Picker
                onClose={closePopover}
                onSave={onAssign}
                member={person}
                eventDays={eventDays}
                assignments={assignments}
              />
            )}
          </MenuPopover>
        );
      })}
    </MenuSection>
    {Boolean(people.length) && (
      <MenuSection>
        {[
          ...resourceTypeIds.map(typeId => {
            const { label } = LABELS[typeId];
            return (
              <MenuPopover
                key={typeId}
                Label={({ onClick }) => (
                  <MinorMenuAction onClick={onClick}>
                    Copy {label} from...
                  </MinorMenuAction>
                )}
              >
                {() => <FindPerson list={people} onSelect={onCopy(typeId)} />}
              </MenuPopover>
            );
          }),
          <MenuPopover
            key="all"
            Label={({ onClick }) => (
              <MinorMenuAction onClick={onClick}>
                Copy all from...
              </MinorMenuAction>
            )}
          >
            {() => <FindPerson list={people} onSelect={onCopy()} />}
          </MenuPopover>
        ]}
      </MenuSection>
    )}
  </MenuBase>
);

const MoreMenu = ({ onDuplicate, onEdit, onRemove, isRemoving }) => {
  return (
    <MenuBase>
      <MenuSection bb={1} bc="gray4">
        <MenuAction onClick={onEdit}>Edit details</MenuAction>
        <MenuAction disabled={isRemoving || !onRemove} onClick={onRemove}>
          {isRemoving ? "Removing..." : "Remove"}
        </MenuAction>
      </MenuSection>
      <MenuSection>
        <MinorMenuAction onClick={onDuplicate}>
          Duplicate person
        </MinorMenuAction>
      </MenuSection>
    </MenuBase>
  );
};

const SearchableListMenu = ({ list = [], searchTerm, onChange, onSelect }) => (
  <MenuBase width={225}>
    <Div display="row.flex-start.center" bb={1} bc="gray1" flex={1} pr={2}>
      <MediumInsetInput
        continuous
        ba={0}
        bg="transparent"
        bc="transparent"
        color="neutral7"
        flex={1}
        fs={3}
        pl={3}
        py={3}
        mb={0}
        value={searchTerm}
        placeholder="Search..."
        autoFocus
        onChange={onChange}
        style={{ boxShadow: "none" }}
      />
      <FontIcon pl={3} color="neutral5">
        search
      </FontIcon>
    </Div>
    <MenuSection style={{ overflow: "auto", maxHeight: 250 }}>
      {list.length ? (
        list.map(({ name = "", initials, color, id }) => (
          <MenuAction key={id} onClick={() => onSelect(id)}>
            <Avatar
              size={20}
              content={initials}
              mr={1}
              color="white"
              style={{ backgroundColor: color }}
            />
            {name}
          </MenuAction>
        ))
      ) : (
        <Div display="row.center.center" py={2}>
          <Text2>None found.</Text2>
        </Div>
      )}
    </MenuSection>
  </MenuBase>
);

export {
  MoreMenu,
  AssignMenu,
  SearchableListMenu,
  MenuSection,
  MenuBase,
  MenuAction,
  MinorMenuAction
};
