import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";

import { actions } from "./model";

import { registerError } from "redux/modules/errors/actions";

import { eventId as getEventId } from "redux/modules/event/selectors";

import { getCredentials } from "redux/modules/user/selectors";

import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  return {
    credentials,
    eventId
  };
};

const init = function*() {
  try {
    yield put(actions.setLoading(true));

    const { credentials, eventId } = yield call(getParams);

    const [{ payload: sets }, { payload: questions }] = yield all([
      call(Api.getQuestionSets, {
        credentials,
        eventId
      }),
      call(Api.getQuestions, {
        credentials,
        eventId
      })
    ]);

    yield put(actions.setInitialData({ questions, sets }));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading questions"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const refetchSets = function*() {
  try {
    yield put(actions.setLoading(true));

    const { credentials, eventId } = yield call(getParams);

    const { payload: sets } = yield call(Api.getQuestionSets, {
      credentials,
      eventId
    });

    yield put(actions.setSets(sets));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading questions"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const refetchQuestions = function*() {
  try {
    yield put(actions.setLoading(true));

    const { credentials, eventId } = yield call(getParams);

    const { payload: questions } = yield call(Api.getQuestions, {
      credentials,
      eventId
    });

    yield put(actions.setQuestions(questions));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading questions"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const deleteQuestion = function*({ payload: questionId }) {
  try {
    const { credentials } = yield call(getParams);

    yield call(Api.deleteQuestion, {
      credentials,
      data: {
        questionId
      }
    });

    yield put(actions.refetchSets());
    yield put(actions.refetchQuestions());
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error deleting a question"
          }
        ])
      )
    ]);
  }
};

const deleteQuestionSet = function*({ payload: questionSetId }) {
  try {
    const { credentials } = yield call(getParams);

    yield call(Api.deleteQuestionSet, {
      credentials,
      data: {
        questionSetId
      }
    });

    yield put(actions.refetchSets());
    yield put(actions.refetchQuestions());
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error deleting a question set"
          }
        ])
      )
    ]);
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchDeleteQuestion = function*() {
  yield takeEvery(actions.deleteQuestion.type, deleteQuestion);
};

const watchDeleteQuestionSet = function*() {
  yield takeEvery(actions.deleteQuestionSet.type, deleteQuestionSet);
};

const watchRefetchSets = function*() {
  yield takeEvery(actions.refetchSets.type, refetchSets);
};

const watchRefetchQuestions = function*() {
  yield takeEvery(actions.refetchQuestions.type, refetchQuestions);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchRefetchSets),
    fork(watchRefetchQuestions),
    fork(watchDeleteQuestion),
    fork(watchDeleteQuestionSet)
  ]);
};

export default rootSaga;
