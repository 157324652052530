import React from "react";

import { connect } from "react-redux";

import * as R from "ramda";

import {
  getSelectedSets,
  getSelectedQuestions
} from "Items/BulkQuestionItemsModal/selectors";

import { actions } from "Items/BulkQuestionItemsModal";

import { Div, Text1, Text2, Text3, CloseIcon } from "components/Base";

const decorate = connect(
  state => ({
    sets: getSelectedSets(state),
    questions: getSelectedQuestions(state)
  }),
  {
    removeAllQuestionsAndSets: () => actions.removeAllQuestionsAndSets(),
    toggleItemQuestion: actions.toggleItemQuestion,
    toggleSet: actions.toggleSet
  }
);

const SelectedQuestions = ({
  sets,
  questions,
  removeAllQuestionsAndSets,
  toggleItemQuestion,
  toggleSet
}) => (
  <Div width={1} height={1} style={{ overflowY: "auto" }} p={3}>
    <Div display="row.space-between.center">
      <Text3 bold color="black">
        Selected Questions
      </Text3>
      <Text1 color="primary7" underline onClick={removeAllQuestionsAndSets}>
        Remove All
      </Text1>
    </Div>
    <Div width={1} mt={1}>
      {R.map(
        ({ name, id, questions }) => (
          <Div bra={1} bg="altB1" width={1} p={2} mt={2} key={id}>
            <Div display="row.space-between.center">
              <Div display="row.flex-start.center">
                <Text2 ml={2} color="black" bold>
                  {name}
                </Text2>
              </Div>
              <CloseIcon onClick={() => toggleSet({ id, isEnabled: true })} />
            </Div>
            {R.length(questions) > 0 && (
              <Div width={1} px={7} mt={2}>
                {R.map(
                  question => (
                    <Text1 key={question.id}>{question.name}</Text1>
                  ),
                  questions
                )}
              </Div>
            )}
          </Div>
        ),
        sets
      )}
      {R.map(
        ({ name, id }) => (
          <Div bra={1} bg="altB1" width={1} p={2} mt={2} key={id}>
            <Div display="row.space-between.center">
              <Div display="row.flex-start.center">
                <Text2 ml={2} color="black" bold>
                  {name || "-"}
                </Text2>
              </Div>
              <CloseIcon
                onClick={() => toggleItemQuestion({ id, isEnabled: true })}
              />
            </Div>
          </Div>
        ),
        questions
      )}
    </Div>
  </Div>
);

export default decorate(SelectedQuestions);
