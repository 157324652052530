import React, { Component } from "react";
import { get, sortBy, isEqual } from "lodash";
import getValue from "utils/value-types/get-value/event-days";
import dateFormatter from "./utils";
import { Div } from "components/Base";

class EventDays extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  render() {
    const { containerStyle = {}, dayStyle = {} } = this.props;
    let days = getValue(this.props.value);
    const dayGroups = get(
      this.props.dependentValues,
      "meta.eventDetails.date_groups",
      []
    );
    // sort dates
    if (days.length > 0) {
      days = sortBy(days, d => d);
    }
    const formattedDates = dateFormatter(days, dayGroups);
    const countOfDates = formattedDates.length;

    return (
      <Div
        display="row.flex-start.center"
        minHeight={23}
        style={{
          ...containerStyle,
          userSelect: "none",
          overflow: "hidden"
        }}
      >
        {formattedDates.map(({ label, color }, idx) => (
          <Div
            key={`${label}_${color}`}
            display="row.flex-start.center"
            height={20}
            style={{
              flexShrink: 0,
              marginRight: countOfDates - 1 === idx ? 0 : 4,
              fontSize: 12,
              borderRadius: 2,
              color: "#000",
              padding: "0 5px",
              backgroundColor: `rgba(${color}, 0.15)`,
              ...dayStyle
            }}
          >
            {label}
          </Div>
        ))}
      </Div>
    );
  }
}

export default EventDays;
