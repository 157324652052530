/* eslint-disable react/prop-types */

import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../../model";
import {
  CUSTOMIZE_APPEARANCE_MODAL_DATA,
  APPEARANCE_FORM_ID
} from "../../constants";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import { Input, Image, Wysiwyg, Toggle } from "ui-kit/Form/View";
import { BigFilledButton } from "components/Base";
import { actions as FormActions } from "ui-kit/Form/model";
import { WithFormInstanceProvider } from "ui-kit/Form/View";

const decorate = R.compose(
  WithFormInstanceProvider(R.always(APPEARANCE_FORM_ID)),
  connect(
    state => ({
      permissionSet: getters.permissionSet(state)
    }),
    {
      hideUpdateModal: () => actions.setShowAppearanceSettingsModal(false),
      updateModalData: actions.updateAppearanceSettings,
      clearValues: FormActions.clearValues
    }
  ),
  CSSModules(css)
);

const UpdateAppearanceModal = ({
  hideUpdateModal,
  permissionSet,
  updateModalData,
  clearValues
}) => {
  useEffect(
    () => () =>
      clearValues(null, {
        meta: {
          instanceId: APPEARANCE_FORM_ID
        }
      }),
    []
  );

  return (
    <MiniModalWrapper
      showModal
      hideModal={hideUpdateModal}
      title="Update Appearance"
      display="column.flex-start.stretch"
      width={700}
      height={540}
    >
      <div styleName="edit-modal">
        <div styleName="body">
          <div styleName="fieldWrapper">
            <div styleName="label">Title</div>
            <Input
              fieldId={CUSTOMIZE_APPEARANCE_MODAL_DATA.TITLE}
              iniValue={R.prop(
                CUSTOMIZE_APPEARANCE_MODAL_DATA.TITLE,
                permissionSet
              )}
              size="big"
              width={1}
            />
          </div>
          <div styleName="fieldWrapper">
            <div styleName="label">Description</div>
            <Wysiwyg
              fieldId={CUSTOMIZE_APPEARANCE_MODAL_DATA.MESSAGE}
              width={"100%"}
              iniValue={R.prop(
                CUSTOMIZE_APPEARANCE_MODAL_DATA.MESSAGE,
                permissionSet
              )}
            />
          </div>
          <div styleName="fieldWrapper">
            <div styleName="label">Background Image</div>
            <div styleName="description">
              Optional. By default we use your event's background image.
            </div>
            <Image
              fieldId={CUSTOMIZE_APPEARANCE_MODAL_DATA.BACKGROUND_IMAGE_URL}
              containerWidth="auto"
              containerProps={{
                display: "row.flex-start.flex-start",
                flex: 1
              }}
              imageWidth={198}
              imageHeight={57}
              recommendedWidth={"1500"}
              recommendedHeight={"500"}
              iniValue={
                R.prop(
                  CUSTOMIZE_APPEARANCE_MODAL_DATA.BACKGROUND_IMAGE_URL,
                  permissionSet
                )
                  ? [
                      {
                        url: R.prop(
                          CUSTOMIZE_APPEARANCE_MODAL_DATA.BACKGROUND_IMAGE_URL,
                          permissionSet
                        )
                      }
                    ]
                  : null
              }
            />
          </div>
          <div styleName="fieldWrapper">
            <div styleName="label">Show Alerts</div>
            <Toggle
              size="medium"
              fieldId={CUSTOMIZE_APPEARANCE_MODAL_DATA.ENABLE_ALERTS}
              iniValue={["true", true].includes(
                R.prop(
                  CUSTOMIZE_APPEARANCE_MODAL_DATA.ENABLE_ALERTS,
                  permissionSet
                )
              )}
              bg="green6"
            />
          </div>
          <div styleName="fieldWrapper">
            <div styleName="label">Show link to "Show Reports"</div>
            <Toggle
              size="medium"
              fieldId={CUSTOMIZE_APPEARANCE_MODAL_DATA.ENABLE_REPORTS}
              iniValue={["true", true].includes(
                R.prop(
                  CUSTOMIZE_APPEARANCE_MODAL_DATA.ENABLE_REPORTS,
                  permissionSet
                )
              )}
              bg="green6"
            />
          </div>
        </div>
        <div styleName="footer">
          <BigFilledButton bg="primary7" onClick={updateModalData}>
            Save
          </BigFilledButton>
        </div>
      </div>
    </MiniModalWrapper>
  );
};

export default decorate(UpdateAppearanceModal);
