import Helpers from "utils/Global/Helpers";

module.exports = {
  post: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/form/templates/${data.template}`,
        credentials,
        data,
        success: resolve,
        error: reject
      };
      Helpers.request(options);
    })
};
