import React from "react";
import { Div, SmallCheckbox, Text2, Text0 } from "components/Base";

const Field = ({ name, type, source, selected, toggleVisible }) => (
  <Div
    width={1}
    bra={1}
    p={1}
    shadow={1}
    mb={2}
    bg={{
      default: "white",
      hover: "neutral1"
    }}
    display="row.flex-start.center"
    onClick={toggleVisible}
  >
    <SmallCheckbox selected={selected} />
    <Div display="row.space-between.center" flex={1} pl={2}>
      <Div>
        <Text2 bold color="black">
          {name}
        </Text2>
        <Div display="row.flex-start.center">
          <Text0 color="neutral6" bold>
            {type} &middot; {source}
          </Text0>
        </Div>
      </Div>
    </Div>
  </Div>
);

export default Field;
