import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import {
  NAMESPACE,
  LOGIN_FIELDS,
  FORGOT_FIELDS
} from "HealthPass/HealthPassForm/constants";

const iniState = {
  loading: true,
  notFound: false,
  saving: false,
  searching: false,
  sessionId: null,
  error: null,
  userExists: false,
  isVirtualUser: false,
  portalUserExists: false,
  activeIndex: 0,
  formData: {},
  termsAccepted: false,
  questionsValues: {},
  contactValues: {},
  loginValues: {},
  loginFields: LOGIN_FIELDS,
  forgotValues: {},
  forgotFields: FORGOT_FIELDS,
  linkId: "",
  selectedAccountId: null,
  selectedContactId: null,
  availableGroups: [],
  userEmail: null,
  isLoggedIn: false,
  eventUserGroups: []
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    nextPage: R.identity,
    backPage: R.identity,
    goToLoginPage: R.identity,
    goToFormPage: R.identity,
    login: R.identity,
    forgotPassword: R.identity,
    saveValues: R.identity,
    handleCallback: R.identity,
    handleLogin: R.identity,
    handleLogout: R.identity,
    checkUser: R.identity,
    setUserData: (
      _,
      { payload: { userExists, isVirtualUser, portalUserExists } }
    ) => ({
      userExists,
      isVirtualUser,
      portalUserExists
    }),
    seedFormData: (_, { payload: { userEmail, formPayload, isLoggedIn } }) => ({
      userEmail: userEmail ? userEmail.toLowerCase().trim() : userEmail,
      formData: formPayload,
      isLoggedIn
    }),
    toggleTermsAccepted: state => ({
      termsAccepted: !state.termsAccepted
    }),
    updateQuestionsValues: (state, { payload: { id, value } }) => ({
      questionsValues: R.assoc(id, value, state.questionsValues)
    }),
    updateContactValues: (state, { payload: { id, value } }) => ({
      contactValues: R.assoc(id, value, state.contactValues)
    }),
    updateLoginValues: (state, { payload: { id, value } }) => ({
      loginValues: R.assoc(id, value, state.loginValues)
    }),
    updateForgotValues: (state, { payload: { id, value } }) => ({
      forgotValues: R.assoc(id, value, state.forgotValues)
    }),
    updateSelectedAccountId: (state, { payload: selectedAccountId }) => {
      const account = selectedAccountId
        ? R.find(a => a.id === selectedAccountId)(state.eventUserGroups)
        : null;
      const selectedContact =
        account && state.userEmail
          ? R.find(c =>
              c.email ? c.email.toLowerCase().trim() === state.userEmail : false
            )(account.contacts)
          : null;

      return {
        selectedAccountId,
        selectedContactId: selectedContact ? selectedContact.id : null,
        contactValues: selectedContact ? selectedContact.values : {},
        questionsValues: {}
      };
    },
    updateSelectedContactId: (state, { payload: selectedContactId }) => {
      return {
        selectedContactId,
        contactValues:
          selectedContactId && state.selectedAccountId
            ? R.compose(
                R.prop("values"),
                R.find(c => c.id === selectedContactId),
                R.propOr([], "contacts"),
                R.find(a => a.id === state.selectedAccountId)
              )(state.eventUserGroups)
            : {}
      };
    }
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
