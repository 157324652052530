import { createHandlers } from "redux-mvc";
import * as R from "ramda";
import {
  NAMESPACE,
  CATEGORIES,
  DATA_LIST,
  QUICK_ASSIGN_PAGES
} from "./constants";

const iniState = {
  data: [],
  tempData: [],
  pricesTemp: Array.from({ length: 5 }, (_, i) => ({
    id: i,
    name: "",
    amount: 0,
    tempId: new Date().getTime() + i
  })),
  categoriesList: CATEGORIES,
  credentialsFilter: "",
  pricesListTemp: [],
  profilesListTemp: [],
  selectedPass: {},
  selectedProfileId: "",
  profileLimitTemp: 0,
  profilePricesTemp: [],
  updateAllTempProfiles: false,
  copyFromProfileId: "",
  copyToProfileId: "",
  assignItemsCredentials: [],
  itemsFilter: "",
  itemPricesTemp: [],
  quickAssignModalVisible: false,
  selectedQuickAssignPage: QUICK_ASSIGN_PAGES.PRICES,
  onBlurEnabled: false,
  passesFilter: "",
  categoryModalVisible: false,
  categoryName: ""
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    updateRemovePass: R.identity,
    updateAddPass: R.identity,
    updateItemName: R.identity,
    updateItemCategory: R.identity,
    updateNextPriceAction: R.identity,
    updateItemProfileLimit: R.identity,
    updateClearProfileAllAssignedItems: R.identity,
    copyItemProfileSettings: R.identity,
    updateItemLimitPricesProfiles: R.identity,
    reorderItems: R.identity,
    addCategory: R.identity,
    updateItemPricesList: R.identity,
    cancelInstance: () => iniState,
    init: (
      state,
      { payload: { data, categoriesList = CATEGORIES, onBlurEnabled = false } }
    ) => {
      return {
        data,
        categoriesList,
        pricesListTemp: R.map(
          ({ sort_order, prices }) => ({
            sort_order,
            prices,
            tempId: `common${sort_order}`
          }),
          data
        ),
        profilesListTemp: R.map(
          ({ sort_order, who_has_access }) => ({
            sort_order,
            who_has_access
          }),
          data
        ),
        onBlurEnabled
      };
    },
    setInitialData: (state, { payload: { profiles } }) => {
      if (R.isEmpty(state.data)) {
        const mappedProfiles = R.map(
          profile => ({
            ...profile,
            selected: false,
            prices: [],
            limit: 0
          }),
          profiles
        );
        const mappedData = DATA_LIST.map(({ name, category }, i) => ({
          sort_order: i,
          name: name,
          category,
          prices: [],
          who_has_access: mappedProfiles
        }));
        return {
          data: mappedData,
          pricesListTemp: R.map(
            ({ sort_order, prices }) => ({
              sort_order,
              prices,
              tempId: `common${sort_order}`
            }),
            mappedData
          ),
          profilesListTemp: R.map(
            ({ sort_order, who_has_access }) => ({
              sort_order,
              who_has_access
            }),
            mappedData
          )
        };
      }
      return {
        data: state.data
      };
    },
    addPass: state => {
      const newData = [
        ...state.data,
        {
          sort_order: R.isEmpty(state.data)
            ? 1
            : R.last(state.data).sort_order + 1,
          name: "New Pass",
          locked: false,
          category:
            R.length(state.categoriesList) > 0 ? state.categoriesList[0] : null,
          prices: [],
          who_has_access: []
        }
      ];
      return {
        data: newData,
        pricesListTemp: R.map(
          ({ sort_order, prices, who_has_access }) => ({
            sort_order,
            prices,
            tempId: `common${sort_order}`,
            who_has_access
          }),
          newData
        ),
        profilesListTemp: R.map(
          ({ sort_order, who_has_access }) => ({
            sort_order,
            who_has_access
          }),
          newData
        )
      };
    },
    removePass: (state, { payload: { id } }) => {
      const newData = R.filter(
        ({ sort_order }) => id !== sort_order,
        state.data
      );
      return {
        data: newData,
        pricesListTemp: R.map(
          ({ sort_order, prices }) => ({
            sort_order,
            prices,
            tempId: `common${sort_order}`
          }),
          newData
        ),
        profilesListTemp: R.map(
          ({ sort_order, who_has_access }) => ({
            sort_order,
            who_has_access
          }),
          newData
        )
      };
    },
    selectPass: (state, { payload: { id } }) => ({
      selectedPass: R.find(R.propEq("sort_order", id), state.data)
    }),
    setPassName: (state, { payload: { id, name } }) => {
      const passIndex = R.findIndex(R.propEq("sort_order", id), state.data);
      const pass = state.data[passIndex];
      return {
        data: [
          ...state.data.slice(0, passIndex),
          {
            ...pass,
            name
          },
          ...state.data.slice(passIndex + 1)
        ]
      };
    },
    setPassCategory: (state, { payload: { passId, categoryId } }) => {
      const passIndex = R.findIndex(R.propEq("sort_order", passId), state.data);
      const pass = state.data[passIndex];
      return {
        data: [
          ...state.data.slice(0, passIndex),
          {
            ...pass,
            category: R.find(
              R.propEq("tempId", categoryId),
              state.categoriesList
            )
          },
          ...state.data.slice(passIndex + 1)
        ]
      };
    },
    updateWhoHasAccess: (state, { payload: { whoHasAccess, id } }) => {
      const passIndex = R.findIndex(R.propEq("sort_order", id), state.data);
      const pass = state.data[passIndex];
      return {
        data: [
          ...state.data.slice(0, passIndex),
          {
            ...pass,
            who_has_access: whoHasAccess
          },
          ...state.data.slice(passIndex + 1)
        ]
      };
    },
    mapTempData: state => ({
      tempData: R.map(
        d => ({ sort_order: d.sort_order, name: d.name, selected: false }),
        state.data
      )
    }),
    setInitialPrices: () => ({
      pricesTemp: Array.from({ length: 5 }, (_, i) => {
        const date = new Date();
        return {
          tempId: date.getTime() + i,
          name: "",
          amount: 0
        };
      })
    }),
    updatePriceTable: (state, { payload: { tempId, data } }) => {
      const priceIndex = R.findIndex(
        R.propEq("tempId", tempId),
        state.pricesTemp
      );
      const price = state.pricesTemp[priceIndex];
      return {
        pricesTemp: [
          ...state.pricesTemp.slice(0, priceIndex),
          {
            ...price,
            ...data
          },
          ...state.pricesTemp.slice(priceIndex + 1)
        ]
      };
    },
    enableAllcredentials: state => ({
      tempData: R.map(temp => ({ ...temp, selected: true }), state.tempData)
    }),
    disableAllcredentials: state => ({
      tempData: R.map(temp => ({ ...temp, selected: false }), state.tempData)
    }),
    toggleCredential: (state, { payload: { id } }) => {
      const credIndex = R.findIndex(R.propEq("sort_order", id), state.tempData);
      const credential = state.tempData[credIndex];

      return {
        tempData: [
          ...state.tempData.slice(0, credIndex),
          {
            ...credential,
            selected: !credential.selected
          },
          ...state.tempData.slice(credIndex + 1)
        ]
      };
    },
    addPricesToCredentials: state => {
      return {
        data: R.map(item => {
          const isSelected =
            R.findIndex(
              ({ id, selected }) => item.id === id && selected === true,
              state.tempData
            ) !== -1;
          if (isSelected) {
            return {
              ...item,
              prices: [
                ...item.prices,
                ...R.map(
                  ({ name, amount, tempId }) => ({ name, amount, tempId }),
                  R.filter(
                    price => price.amount > 0 && price.name.length >= 2,
                    state.pricesTemp
                  )
                )
              ]
            };
          }
          return item;
        }, state.data)
      };
    },
    resetPriceItem: (state, { payload: { id } }) => {
      const resetItem = R.find(R.propEq("sort_order", id), state.data);
      return {
        pricesListTemp: R.map(item => {
          if (item.sort_order !== id) {
            return item;
          }
          return {
            ...item,
            prices: resetItem.prices
          };
        }, state.pricesListTemp)
      };
    },
    updateSelectedPrices: (state, { payload: { id } }) => {
      const newItem = R.find(R.propEq("sort_order", id), state.pricesListTemp);

      return {
        data: R.map(item => {
          if (item.sort_order !== id) {
            return item;
          }
          return {
            ...item,
            who_has_access: R.map(
              profile => ({
                ...profile,
                prices: R.filter(price => {
                  return R.any(
                    R.propEq("tempId", price.tempId),
                    newItem.prices
                  );
                }, profile.prices)
              }),
              item.who_has_access
            )
          };
        }, state.data)
      };
    },
    updateSelectedPrice: (state, { payload: { id, tempId, value, key } }) => {
      return {
        pricesListTemp: R.map(item => {
          if (item.sort_order !== id) {
            return item;
          }
          return {
            ...item,
            prices: R.map(price => {
              if (price.tempId !== tempId) {
                return price;
              }
              return {
                ...price,
                [key]: value
              };
            }, item.prices)
          };
        }, state.pricesListTemp)
      };
    },
    addPriceRow: (state, { payload: { id } }) => ({
      pricesListTemp: R.map(item => {
        if (item.sort_order !== id) {
          return item;
        }
        const date = new Date();
        return {
          ...item,
          prices: [
            ...item.prices,
            {
              tempId: date.getTime(),
              name: "",
              amount: 0
            }
          ]
        };
      }, state.pricesListTemp)
    }),
    removePriceRow: (state, { payload: { id, tempId } }) => ({
      pricesListTemp: R.map(item => {
        if (item.sort_order !== id) {
          return item;
        }
        return {
          ...item,
          prices: R.filter(price => price.tempId !== tempId, item.prices)
        };
      }, state.pricesListTemp)
    }),
    toggleProfileTemp: (state, { payload: { profileId } }) => ({
      selectedPass: {
        ...state.selectedPass,
        who_has_access: R.map(
          profile => ({
            ...profile,
            selected:
              profile.id === profileId ? !profile.selected : profile.selected,
            limit: profile.id !== profileId ? profile.limit : 0,
            prices: profile.id !== profileId ? profile.prices : []
          }),
          state.selectedPass.who_has_access
        )
      }
    }),
    updateProfileLimitTemp: (state, { payload: { limit } }) => ({
      profileLimitTemp: limit
    }),
    enableAllProfileTemp: state => ({
      selectedPass: {
        ...state.selectedPass,
        who_has_access: R.map(
          profile => ({
            ...profile,
            selected: true
          }),
          state.selectedPass.who_has_access
        )
      }
    }),
    disableAllProfileTemp: state => ({
      selectedPass: {
        ...state.selectedPass,
        who_has_access: R.map(
          profile => ({
            ...profile,
            selected: false,
            prices: [],
            limit: 0
          }),
          state.selectedPass.who_has_access
        )
      }
    }),
    updateProfiles: state => {
      const passIndex = R.findIndex(
        R.propEq("sort_order", state.selectedPass.sort_order),
        state.data
      );
      const pass = state.data[passIndex];
      return {
        data: [
          ...state.data.slice(0, passIndex),
          {
            ...pass,
            who_has_access: R.propOr([], "who_has_access", state.selectedPass)
          },
          ...state.data.slice(passIndex + 1)
        ]
      };
    },
    updateProfileLimit: state => {
      const { updateAllTempProfiles, data } = state;
      const passIndex = R.findIndex(
        R.propEq("sort_order", state.selectedPass.sort_order),
        data
      );
      const pass = state.data[passIndex];
      const selectedPass = {
        ...state.selectedPass,
        who_has_access: R.map(profile => {
          if (
            profile.id === state.selectedProfileId ||
            (updateAllTempProfiles && profile.selected)
          ) {
            return {
              ...profile,
              limit: state.profileLimitTemp,
              selected: profile.prices.length > 0 || state.profileLimitTemp > 0
            };
          }
          return profile;
        }, state.selectedPass.who_has_access)
      };
      return {
        data: [
          ...state.data.slice(0, passIndex),
          {
            ...pass,
            who_has_access: R.propOr([], "who_has_access", selectedPass)
          },
          ...state.data.slice(passIndex + 1)
        ],
        selectedPass
      };
    },
    updateProfilePrices: state => {
      const { updateAllTempProfiles, data, profilePricesTemp } = state;

      const passIndex = R.findIndex(
        R.propEq("sort_order", state.selectedPass.sort_order),
        data
      );
      const pass = state.data[passIndex];
      const selectedPass = {
        ...state.selectedPass,
        who_has_access: R.map(profile => {
          if (
            profile.id === state.selectedProfileId ||
            (updateAllTempProfiles && profile.selected)
          ) {
            const updatedPrices = R.filter(
              R.propEq("selected", true),
              profilePricesTemp
            );
            return {
              ...profile,
              prices: updatedPrices,
              selected: updatedPrices.length > 0 || profile.limit > 0
            };
          }
          return profile;
        }, state.selectedPass.who_has_access)
      };
      return {
        selectedPass
      };
    },
    showLimitPopOver: (
      state,
      { payload: { profileId, updateAllTempProfiles } }
    ) => ({
      updateAllTempProfiles,
      selectedProfileId: profileId,
      profileLimitTemp: R.compose(
        R.propOr(0, "limit"),
        R.find(R.propEq("id", profileId)),
        R.propOr([], "who_has_access")
      )(state.selectedPass),
      profilePricesTemp:
        updateAllTempProfiles === false
          ? R.map(
              price => ({
                ...price,
                selected: R.compose(
                  R.any(R.propEq("tempId", price.tempId)),
                  R.propOr([], "prices"),
                  R.find(R.propEq("id", profileId)),
                  R.propOr([], "who_has_access")
                )(state.selectedPass)
              }),
              R.propOr([], "prices", state.selectedPass)
            )
          : state.selectedPass.prices
    }),
    toggleProfilePrice: (state, { payload: { tempId } }) => ({
      profilePricesTemp: R.map(price => {
        if (price.tempId !== tempId) {
          return price;
        }
        return {
          ...price,
          selected: !price.selected
        };
      }, state.profilePricesTemp)
    }),
    disableProfilePrices: state => ({
      profilePricesTemp: R.map(
        price => ({
          ...price,
          selected: false
        }),
        state.profilePricesTemp
      )
    }),
    updateLimitPricesProfiles: (
      state,
      { payload: { profileId, assignItemsCredentials } }
    ) => ({
      data: R.map(item => {
        const updatedItem = R.find(
          ({ sort_order }) => item.sort_order === sort_order,
          assignItemsCredentials
        );
        if (R.isNil(updatedItem)) {
          return item;
        }

        return {
          ...item,
          who_has_access: R.map(profile => {
            if (profile.id !== profileId) {
              return profile;
            }
            const prices = R.filter(
              R.propEq("selected", true),
              updatedItem.prices
            );
            return {
              ...profile,
              prices,
              limit: updatedItem.limit,
              selected: updatedItem.limit > 0 || prices.length > 0
            };
          }, item.who_has_access)
        };
      }, state.data)
    }),
    clearProfileAllAssignedItems: (state, { payload: { profileId } }) => ({
      data: R.map(
        item => ({
          ...item,
          who_has_access: R.map(
            profile => ({
              ...profile,
              selected: profileId === profile.id ? false : profile.selected,
              prices: profileId === profile.id ? [] : profile.prices,
              limit: profileId === profile.id ? 0 : profile.limit
            }),
            item.who_has_access
          )
        }),
        state.data
      )
    }),
    copyProfileSettings: state => {
      const updateProfiles = profiles => {
        const copyFromProfileIndex = R.findIndex(
          R.propEq("id", state.copyFromProfileId),
          profiles
        );
        const copyToProfileIndex = R.findIndex(
          R.propEq("id", state.copyToProfileId),
          profiles
        );
        const copyFromProfile = profiles[copyFromProfileIndex];
        const copyToProfile = profiles[copyToProfileIndex];

        const updatedProfiles = [
          ...profiles.slice(0, copyToProfileIndex),
          {
            ...copyToProfile,
            prices: copyFromProfile.prices,
            limit: copyFromProfile.limit,
            selected: copyFromProfile.selected
          },
          ...profiles.slice(copyToProfileIndex + 1)
        ];

        return updatedProfiles;
      };
      return {
        data: R.map(
          item => ({
            ...item,
            who_has_access: updateProfiles(item.who_has_access)
          }),
          state.data
        )
      };
    },
    setProfilesId: (
      _,
      { payload: { copyFromProfileId, copyToProfileId } }
    ) => ({
      copyFromProfileId,
      copyToProfileId
    }),
    resetAssignItems: (state, { payload: { assignItemsCredentials } }) => ({
      assignItemsCredentials: R.map(
        ({ name, sort_order, prices }) => ({
          name,
          sort_order,
          prices: R.map(price => ({ ...price, selected: false }), prices),
          limit: 0,
          selected: false
        }),
        assignItemsCredentials
      )
    }),
    toggleAssignedItem: (state, { payload: { id } }) => {
      const credIndex = R.findIndex(
        R.propEq("sort_order", id),
        state.assignItemsCredentials
      );
      const credential = state.assignItemsCredentials[credIndex];

      return {
        assignItemsCredentials: [
          ...state.assignItemsCredentials.slice(0, credIndex),
          {
            ...credential,
            selected: !credential.selected,
            prices: credential.selected === true ? [] : credential.prices,
            limit: credential.selected === true ? 0 : credential.limit
          },
          ...state.assignItemsCredentials.slice(credIndex + 1)
        ]
      };
    },
    enableAllItems: state => ({
      assignItemsCredentials: R.map(
        item => ({
          ...item,
          selected: true
        }),
        state.assignItemsCredentials
      )
    }),
    disableAllItems: state => ({
      assignItemsCredentials: R.map(
        item => ({
          ...item,
          selected: false,
          prices: [],
          limit: 0
        }),
        state.assignItemsCredentials
      )
    }),
    updateItemLimit: (state, { payload: { id, limit } }) => {
      const credIndex = R.findIndex(
        R.propEq("sort_order", id),
        state.assignItemsCredentials
      );
      const credential = state.assignItemsCredentials[credIndex];

      return {
        assignItemsCredentials: R.update(
          credIndex,
          { ...credential, limit, selected: limit > 0 },
          state.assignItemsCredentials
        )
      };
    },
    resetItemPricesTemp: (state, { payload: { id } }) => {
      const item = R.find(
        R.propEq("sort_order", id),
        state.assignItemsCredentials
      );
      const whoHasAccess = R.propOr([], "who_has_access", item);
      return {
        itemPricesTemp: R.compose(
          R.map(price => ({
            ...price,
            selected: R.any(R.propEq("tempId", price.tempId), whoHasAccess)
          })),
          R.propOr([], "prices")
        )(item)
      };
    },
    updateItemPrices: (state, { payload: { id } }) => {
      const itemIndex = R.findIndex(
        R.propEq("sort_order", id),
        state.assignItemsCredentials
      );
      const item = state.assignItemsCredentials[itemIndex];

      const updatePrices = R.filter(
        R.propEq("selected", true),
        state.itemPricesTemp
      );

      return {
        assignItemsCredentials: [
          ...state.assignItemsCredentials.slice(0, itemIndex),
          {
            ...item,
            prices: updatePrices,
            selected: updatePrices.length > 0
          },
          ...state.assignItemsCredentials.slice(itemIndex + 1)
        ]
      };
    },
    updatePricesList: (state, { payload: { id } }) => {
      const itemIndex = R.findIndex(R.propEq("sort_order", id), state.data);
      const item = state.data[itemIndex];
      const newPrices = R.propOr(
        [],
        "prices",
        R.find(R.propEq("sort_order", id), state.pricesListTemp)
      );

      return {
        data: R.update(
          itemIndex,
          {
            ...item,
            prices: newPrices
          },
          state.data
        )
      };
    },
    toggleItemPrice: (state, { payload: { tempId } }) => ({
      itemPricesTemp: R.map(
        price => ({
          ...price,
          selected: price.tempId === tempId ? !price.selected : price.selected
        }),
        state.itemPricesTemp
      )
    }),
    disableItemPrices: state => ({
      itemPricesTemp: R.map(
        price => ({
          ...price,
          selected: false
        }),
        state.itemPricesTemp
      )
    }),
    showAssignModal: state => ({
      selectedQuickAssignPage: QUICK_ASSIGN_PAGES.PRICES,
      quickAssignModalVisible: true,
      pricesTemp: Array.from({ length: 5 }, (_, i) => {
        const date = new Date();
        return {
          tempId: date.getTime() + i,
          name: "",
          amount: 0
        };
      }),
      tempData: R.map(
        d => ({ sort_order: d.sort_order, name: d.name, selected: false }),
        state.data
      )
    }),
    quickAssignPreviousAction: state => ({
      selectedQuickAssignPage: QUICK_ASSIGN_PAGES.PRICES,
      quickAssignModalVisible:
        state.selectedQuickAssignPage !== QUICK_ASSIGN_PAGES.PRICES
    }),
    quickAssignNextAction: state => ({
      quickAssignModalVisible:
        state.selectedQuickAssignPage !== QUICK_ASSIGN_PAGES.CREDENTIALS,
      selectedQuickAssignPage: QUICK_ASSIGN_PAGES.CREDENTIALS,
      data:
        state.selectedQuickAssignPage === QUICK_ASSIGN_PAGES.CREDENTIALS
          ? R.map(item => {
              const isSelected =
                R.findIndex(
                  ({ sort_order, selected }) =>
                    item.sort_order === sort_order && selected === true,
                  state.tempData
                ) !== -1;

              if (isSelected) {
                return {
                  ...item,
                  prices: [
                    ...item.prices,
                    ...R.map(
                      ({ name, amount, tempId }) => ({ name, amount, tempId }),
                      R.filter(
                        price => price.amount > 0 && price.name.length >= 2,
                        state.pricesTemp
                      )
                    )
                  ]
                };
              }
              return item;
            }, state.data)
          : state.data
    }),
    hideCategoryModal: () => ({
      categoryModalVisible: false,
      categoryName: ""
    })
  }
});

export default handlers;
