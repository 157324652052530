export const TABS = {
  SUMMARY: "summary",
  MANAGE: "manage",
  ATTENDEES: "attendees",
  MESSAGES: "messages"
};

export const MESSAGE_TABS = {
  NOTE: "note",
  MESSAGE: "message"
};

export const STATUS = {
  // TODO: fix repeated statuses
  ALL: "all",
  PENDING: "pending",
  PENDING_MY_REVIEW: "pending-my-review",
  PENDING_REVIEW: "pending-review",
  PENDING_PICKUP: "pending-pickup",
  APPROVED: "approved",
  FULFILLED: "fulfilled",
  REJECTED: "rejected",
  NO_STATUS: "no-status",
  CONFIRMATION_NOT_SENT: "confirmation-not-sent",
  NOT_YET_ISSUED: "not-yet-issued",
  COMPLETED: "completed",
  DENIED: "denied"
};

export const EMPTY_INVOICE = {
  requested: "0.00",
  approved: "0.00",
  fees: "0.00",
  due: "0.00",
  status: ""
};

export const ORDER_TYPES = {
  INDIVIDUAL: "individual",
  GROUP: "group"
};

export const MESSAGE_TYPES = {
  NOTE: "note",
  ALERT: "alert",
  ALL: ""
};

export const NAMESPACE = "OrderModal";
