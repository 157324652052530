import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE, PAGES } from "./constants";

const iniState = {
  loading: true,
  page: PAGES.EVENT,
  typeOfEvents: [],
  verificationHash: ""
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    nextBtnActions: R.identity,
    toggleEvent: (state, { payload: { id, selected } }) => ({
      typeOfEvents: selected
        ? R.filter(typeEvent => typeEvent !== id, state.typeOfEvents)
        : [...state.typeOfEvents, id]
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
