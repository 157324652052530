import React from "react";

import {
  Div,
  NumberSelector,
  SmallOutlineButton,
  AddIcon
} from "components/Base";
import PriceSelector from "Orders/CreateOrderModal/PriceSelector";

import { Limit } from "components/Event/FormsV2/Sections/FormOrderItems/View";

import { connect } from "react-redux";
import { getters, actions } from "../index";
import { getVariants } from "../selectors";
import { formatAmountForEvent } from "redux/modules/event/selectors";

const decorate = connect(
  state => ({
    variants: getVariants(state),
    limit: getters.limit(state),
    remaining: getters.remaining(state),
    disabled: getters.disabled(state),
    color: getters.color(state),
    selectedVariantPrices: getters.selectedVariantPrices(state),
    formatAmountForEvent: formatAmountForEvent(state)
  }),
  {
    updateVariantQuantity: actions.updateVariantQuantity,
    updateVariantPrice: actions.updateVariantPrice
  }
);

const Row = ({
  name,
  subname,
  price,
  prices,
  selectedPriceId,
  quantity,
  updateVariantQuantity,
  updateVariantPrice,
  max,
  itemMax,
  disabled,
  mode,
  showItemModal,
  hideAddButton,
  color,
  formatAmountForEvent
}) => {
  const meta = [];

  if (subname) {
    meta.push(subname);
  }
  if (itemMax !== undefined) {
    meta.push(`${itemMax} remaining`);
  }

  return (
    <Div
      display="row.flex-start.center"
      bg="white"
      b={1}
      bc="gray2"
      bra={1}
      pl={3}
      height={50}
      mb={2}
    >
      <Div display="row.space-between.center" flex={1}>
        <Div>
          <Div
            fs={4}
            fw={3}
            style={{
              lineHeight: "18px"
            }}
          >
            {name}
          </Div>
          {meta.length ? (
            <Div fs={1} color="neutral7">
              {meta.join(" · ")}
            </Div>
          ) : null}
        </Div>
        <Div flex={1} />
        <Div display="row.flex-start.center">
          {price ? (
            <Div mr={4} fw={3}>
              {formatAmountForEvent(price)}
            </Div>
          ) : null}
          {prices && prices.length ? (
            <PriceSelector
              prices={prices}
              selectedPriceId={selectedPriceId}
              onChange={updateVariantPrice}
            />
          ) : null}
          <Div width={15} />
          <Div pr={quantity && !disabled && !hideAddButton ? 0 : 3}>
            {mode === "single" ? (
              <NumberSelector
                value={quantity}
                onChangeValue={updateVariantQuantity}
                maxValue={itemMax !== undefined ? itemMax : max}
              />
            ) : (
              <SmallOutlineButton
                type="button"
                disabled={disabled}
                width={88}
                OnlyIcon={AddIcon}
                onClick={showItemModal}
                color={disabled ? "gray6" : color}
                bg="white"
              />
            )}
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

const VariantList = ({
  remaining,
  limit,
  disabled,
  variants,
  selectedVariantPrices,
  updateVariantQuantity,
  updateVariantPrice,
  formatAmountForEvent
}) => {
  return (
    <>
      <Div fw={4} color="gray7" uppercase mb={3}>
        Select Options
      </Div>
      <Div>
        {remaining && remaining !== undefined ? (
          <Limit remaining={remaining} limit={limit} />
        ) : null}
        <Div>
          {variants.map(variant => (
            <Row
              key={variant.id}
              formatAmountForEvent={formatAmountForEvent}
              selectedPriceId={selectedVariantPrices[variant.id]}
              updateVariantQuantity={qty =>
                updateVariantQuantity({ variantId: variant.id, quantity: qty })
              }
              updateVariantPrice={priceId =>
                updateVariantPrice({ variantId: variant.id, priceId })
              }
              disabled={disabled}
              {...variant}
            />
          ))}
        </Div>
      </Div>
    </>
  );
};

export default decorate(VariantList);
