import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Card from "components/Global/CRM/Card";
import * as R from "ramda";
import EmptyState, {
  ButtonWrapper
} from "components/Global/CRM/Card/EmptyState";
import Header from "components/Global/CRM/Card/Header";
import IconTitle from "components/Global/CRM/Card/IconTitle";
import {
  OutlineButton,
  HelpButton,
  SolidButton
} from "components/Global/CRM/Card/Buttons";
import HelpArticleUrls from "utils/help-article-urls";
import Actions from "components/Global/CRM/Card/Actions";
import Title from "components/Global/CRM/Card/Title";
import Body from "components/Global/CRM/Card/Body";
import Form from "./Form";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import SendSummaryConfirmations from "components/Event/FormsV2/Overview/SendSummaryConfirmations";
import AssignFormsModal from "components/Global/CRM/Modals/Assign/AssignForms/AssignContactForms";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import EditContactFormAssignment from "components/Event/Module/Form/Modals/EditContactFormAssignment";
import OrderModal from "Orders/OrderModal/View";
import { connect } from "react-redux";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

const COLOR = STANDARD_MODULE_IDS.forms.color;
const ICON = STANDARD_MODULE_IDS.forms.icon;

const decorate = connect((state, props) => ({
  canDo: canEventUserDo(state, props)
}));

@CSSModules(css)
class ContactForms extends Component {
  fetchContact = this.props.fetchContact;

  showSubmissionSummaryModal = (isFormManager, { id }) => {
    const submissionSummaryOptions = isFormManager
      ? {
          submissionId: id,
          hideModal: () => {
            this.fetchContact();
            this.props.hideModal();
          }
        }
      : {
          submissionId: id,
          readOnly: true,
          hideModal: () => {
            this.fetchContact();
            this.props.hideModal();
          }
        };

    const Modal = <OrderModal {...submissionSummaryOptions} />;
    this.props.showModal({ content: Modal, wrapper: ModalWrapper });
  };

  setSubmissionLocked = (isLocked, submission) =>
    this.props
      .setSubmissionLocked(isLocked, submission)
      .then(() => this.props.showSnackbar({ message: "Submission updated" }))
      .then(this.fetchContact);

  showSendSubmissionConfirmationModal = (submission, form) => {
    const modal = (
      <SendSummaryConfirmations
        form={form}
        eventDetails={this.props.eventDetails}
        hasChanges={false}
        submission={submission}
        fields={form.fields || []}
        params={this.props.params}
        handleApprovalSent={this.handleApprovalSent}
      />
    );

    this.props.showModal({ content: modal, wrapper: ModalWrapper });
  };

  handleApprovalSent = count => {
    this.props.showSnackbar({
      message: `${count} confirmation ${count > 1 ? "emails" : "email"} sent`,
      action: "OK"
    });
  };

  handleDeleteSubmission = (formId, submissionId) =>
    this.props.deleteSubmission({ submissionId }).then(this.fetchContact);

  handleAddSubmission = async form => {
    const { user, eventDetails } = this.props;
    const contactId = this.props.params.recordId;
    let submissionResult;

    // @NOTE: If we're filling out an intake form, we want to use
    // this account or contact's record ID instead of creating a new record
    let submissionModuleRecord = {};

    if (form.type === "intake") {
      // @NOTE: In the future, we'll want to add a condition here to pass in
      // either the account's or contact's ID based on portal type. Currently
      // we assume that intake forms are only used on account portals.
      submissionModuleRecord = {
        id: contactId
      };
    } else {
      submissionModuleRecord = await this.props.addRecord({
        moduleId: form.base_module_id,
        record: {
          isDraft: true
        },
        options: {
          eventId: form.event_id
        }
      });
    }

    submissionResult = await this.props.createSubmission({
      formId: form.id,
      eventId: eventDetails.id,
      userId: user.id,
      contactId,
      submissionRecordId: submissionModuleRecord.id
    });

    window.location = `/submissions/${eventDetails.slug}/${form.slug}/${submissionResult.id}?redirectToContact=${contactId}`;
  };

  assignForms = formIds => {
    const { eventId, recordId } = this.props.params;
    const existingForms = this.props.forms.map(({ id }) => id);

    const formsToAdd = formIds
      .filter(id => !existingForms.includes(id))
      .map(formId =>
        this.props.addAccountForm({ eventId, accountId: recordId, formId })
      );

    const formsToRemove = this.props.forms
      .filter(form => !formIds.includes(form.id))
      .map(({ account_form_id }) =>
        this.props.deleteAccountForm({
          accountFormId: account_form_id,
          accountId: recordId
        })
      );

    Promise.all([...formsToRemove, ...formsToAdd])
      .then(this.fetchContact)
      .then(() => {
        if (formsToAdd.length || formsToRemove.length) {
          this.props.showSnackbar({
            message: "Updated forms assigned to this group"
          });
        }
      });
  };

  showAssignFormsModal = () => {
    this.props.showModal({
      content: (
        <AssignFormsModal
          moduleId={STANDARD_MODULE_IDS.contacts.id}
          recordNameSingular="Person"
          recordNamePlural="People"
          recordIds={[this.props.params.recordId]}
          onDone={this.props.fetchContact}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  onClickEditCloseDate = form => {
    this.props.showModal({
      content: (
        <EditContactFormAssignment
          eventId={this.props.params.eventId}
          name={this.props.contactName}
          closeDate={form.close_date}
          customCloseDate={form.custom_close_date}
          onSave={data =>
            this.onUpdateGroupAssignment({
              formId: form.id,
              ...data
            })
          }
        />
      ),
      wrapper: ModalWrapper
    });
  };

  onRemoveAssignmentFromGroup = async formId => {
    await this.props.removeContactFormAssignment({
      formId,
      contactIds: [this.props.params.recordId]
    });

    this.props.showSnackbar({ message: "Form Unassigned", action: "OK" });

    return this.props.fetchContact();
  };

  onUpdateGroupAssignment = async ({ formId, closeDate }) => {
    await this.props.updateContactFormAssignment({
      eventId: this.props.params.eventId,
      formId,
      contactId: this.props.params.recordId,
      closeDate
    });

    this.props.showSnackbar({ message: "Assignment Updated", action: "OK" });

    return this.props.fetchContact();
  };

  isEventAdmin = this.props.canDo(
    `${STANDARD_MODULE_IDS.settings.id}_manage_all_module_data`
  );

  render() {
    const { eventDetails, forms } = this.props;

    const body =
      forms && forms.length ? (
        R.compose(
          R.map(f => {
            const isFormManager = this.isEventAdmin;
            return (
              <Form
                key={f.id}
                addSubmission={this.handleAddSubmission}
                deleteSubmission={this.handleDeleteSubmission}
                eventDetails={eventDetails}
                form={f}
                showSubmissionSummary={this.showSubmissionSummaryModal.bind(
                  null,
                  isFormManager
                )}
                setSubmissionLocked={this.setSubmissionLocked}
                sendConfirmation={this.showSendSubmissionConfirmationModal}
                isFormManager={isFormManager}
                isAssignedToType={!f.account_form_id}
                onClickEditCloseDate={() => this.onClickEditCloseDate(f)}
                onUnassign={
                  f.account_form_id
                    ? () => this.onRemoveAssignmentFromGroup(f.id)
                    : undefined
                }
              />
            );
          }),
          R.sortBy(
            R.compose(
              name => (name ? R.toLower(name) : name),
              R.propOr("", "name")
            )
          )
        )(forms)
      ) : (
        <EmptyState
          icon={
            <img
              src="//d2dks4tzxs6xee.cloudfront.net/img/inbox_icon.png"
              alt=""
            />
          }
        >
          <div>
            Forms are ways to collect simple or complex information from a group
            or individual.
          </div>
          <ButtonWrapper>
            <SolidButton onClick={this.showAssignFormsModal}>
              <i className="material-icons">add</i>
              Assign Forms
            </SolidButton>
          </ButtonWrapper>
        </EmptyState>
      );

    return (
      <Card color={COLOR}>
        <Header>
          <Title>
            <IconTitle title="Forms" color={COLOR}>
              <i className={`material-icons ${css.icon}`}>{ICON}</i>
            </IconTitle>
            <Actions>
              <OutlineButton onClick={this.showAssignFormsModal}>
                Assign Forms
              </OutlineButton>
              <HelpButton url={HelpArticleUrls.MANAGE_ACCOUNT_FORMS}>
                Learn about managing account forms
              </HelpButton>
            </Actions>
          </Title>
        </Header>
        <Body>{body}</Body>
      </Card>
    );
  }
}

ContactForms.propTypes = {
  accountName: PropTypes.string,
  addAccountForm: PropTypes.func.isRequired,
  deleteAccountForm: PropTypes.func.isRequired,
  deleteSubmission: PropTypes.func.isRequired,
  forms: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchContact: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  params: PropTypes.shape({
    recordId: PropTypes.string,
    eventId: PropTypes.string
  }),
  setSubmissionLocked: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  eventDetails: PropTypes.object.isRequired,
  getCredentials: PropTypes.func.isRequired
};

export default decorate(ContactForms);
