import * as R from "ramda";

import { put, all, select, call, fork, take } from "redux-saga/effects";

import { PAYMENT_FIELDS } from "Sponsors/SelfSetup/constants";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import { actions as FormActions } from "ui-kit/Form/model";

import * as FormSelectors from "ui-kit/Form/selectors";

import { updateEventShowcaseDetails } from "./eventShowcase";

const followFieldUpdate = function*({ id, fieldId, fieldType }) {
  if (
    ![PAYMENT_FIELDS.ACCEPT_PAYMENT].includes(fieldId) &&
    ![FIELD_TYPES.DATE, FIELD_TYPES.DROPDOWN].includes(fieldType)
  ) {
    for (;;) {
      const {
        meta: { instanceId }
      } = yield take(FormActions.blurField.type);
      if (instanceId === id) {
        break;
      }
    }
  }

  let newValue = yield select(FormSelectors.getFieldValue, {
    instanceId: id,
    fieldId
  });

  if ([PAYMENT_FIELDS.ACCEPT_PAYMENT].includes(fieldId)) {
    yield fork(updateEventShowcaseDetails, {
      payload: { fieldId, value: newValue, refresh: false }
    });
  }
};

const watchUpdateFields = function*() {
  for (;;) {
    const {
      meta: { instanceId, fieldId }
    } = yield take(FormActions.setFieldValue.type);
    if ([PAYMENT_FIELDS.ACCEPT_PAYMENT].includes(fieldId)) {
      yield call(followFieldUpdate, {
        id: instanceId,
        fieldId
      });
    }
  }
};

//

const rootSaga = function*() {
  yield all([fork(watchUpdateFields)]);
};

export default rootSaga;
