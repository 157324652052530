import React from "react";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import css from "ui-kit/ViewPicker/View/styles.scss";
import { connect } from "react-redux";
import Tooltip from "components/Global/Tooltip";
import ViewPicker from "ui-kit/ViewPicker/View";
import { bindInstance } from "redux-mvc";

import {
  Div,
  Popover,
  MenuCard,
  MenuItem,
  ReplyIcon,
  AddIcon,
  MoreIcon,
  FontIcon,
  EditIcon,
  DeleteIcon
} from "components/Base";

import { actions, getters } from "ui-kit/ViewPicker";

const decorate = R.compose(
  connect(
    (state, props) => ({
      activeViewId: getters.activeViewId(state, props),
      editing: getters.showEditViewName(state, props)
    }),
    bindInstance({
      saveViewChanges: actions.saveViewChanges,
      revertViewChanges: actions.revertViewChanges,
      addView: actions.addView,
      deleteView: actions.deleteView,
      setShowEditViewName: actions.setShowEditViewName
    })
  )
);

const Button = ({ children, onClick, white, ...styleProps }) => {
  return (
    <Div
      height={1}
      flex={1}
      fs={2}
      fw={3}
      bra={1}
      display="row.flex-start.center"
      px={3}
      py={3}
      bg={{
        default: white ? "white" : "primary7",
        hover: white ? "neutral0" : "primary6"
      }}
      onClick={onClick}
      {...styleProps}
    >
      {children}
    </Div>
  );
};

const SaveChanges = ({
  small,
  white,
  saveViewChanges,
  collapsed,
  vertical,
  showOptions,
  hideMenu
}) => {
  return (
    <Tooltip
      tooltip={
        <span>
          <b>Save all changes</b>
          <br />
          made to this view
        </span>
      }
    >
      <Button
        height={small || vertical ? 32 : undefined}
        className={white ? undefined : css.button3}
        onClick={() => saveViewChanges()}
        white={white}
        display={vertical ? "row.center.center" : "row.flex-start.center"}
      >
        <FontIcon color={white ? "primary7" : "white"} mr={1} fs={4}>
          autorenew
        </FontIcon>
        <span>
          {collapsed || (showOptions && vertical && !hideMenu)
            ? "Save"
            : "Save View"}
        </span>
      </Button>
    </Tooltip>
  );
};

const RevertChanges = ({
  small,
  white,
  vertical,
  revertViewChanges,
  showOptions,
  hasViewChanged,
  collapsed
}) => {
  return (
    <Tooltip tooltip="Reset view changes">
      <Button
        display={vertical ? "row.center.center" : "row.flex-start.center"}
        height={small || vertical ? 32 : undefined}
        onClick={() => revertViewChanges()}
        ml={vertical ? 1 : 2}
        px={vertical ? 1 : 2}
        className={white ? undefined : css.button4}
        white={white}
      >
        <ReplyIcon
          color={white ? "primary7" : "white"}
          style={
            collapsed || hasViewChanged
              ? {
                  margin: "0 3px"
                }
              : undefined
          }
        />
      </Button>
    </Tooltip>
  );
};

const SaveAsNewView = ({
  small,
  addView,
  white,
  vertical,
  collapsed,
  hasViewChanged
}) => {
  return (
    <Tooltip
      tooltip={
        <span>
          <b>Save changes</b>
          <br />
          as new view
        </span>
      }
    >
      <Button
        display={vertical ? "row.center.center" : "row.flex-start.center"}
        height={small || vertical ? 32 : undefined}
        onClick={() => addView()}
        ml={vertical ? 1 : 2}
        px={vertical ? 1 : 2}
        className={white ? undefined : css.button4}
        white={white}
      >
        <FontIcon
          color={white ? "primary7" : "white"}
          mr={1}
          fs={3}
          style={
            collapsed || hasViewChanged
              ? {
                  margin: "0 3px"
                }
              : undefined
          }
        >
          file_copy
        </FontIcon>
      </Button>
    </Tooltip>
  );
};

const MoreMenu = ({
  showOptions,
  collapsed,
  hasViewChanged,
  small,
  white,
  setShowEditViewName,
  revertViewChanges,
  activeViewId,
  deleteView,
  addView,
  vertical
}) => {
  return (
    <Popover
      wrapperProps={{
        width: 1,
        flex: 1,
        display: showOptions ? "row.center.center" : "row.flex-start.center"
      }}
      innerSpanStyle={{
        display: "block",
        width: "100%"
      }}
      Label={({ onClick }) => (
        <Button
          onClick={onClick}
          ml={showOptions || collapsed ? (vertical ? 1 : 2) : 0}
          px={vertical ? 1 : 2}
          height={small || vertical ? 32 : undefined}
          display={showOptions ? "row.center.center" : "row.flex-start.center"}
          className={
            white
              ? undefined
              : collapsed || hasViewChanged
              ? css.button4
              : css.button3
          }
          white={white}
        >
          <MoreIcon
            mr={collapsed || hasViewChanged ? 0 : 1}
            color={white ? "primary7" : "white"}
            style={
              collapsed || hasViewChanged
                ? {
                    margin: "0 3px"
                  }
                : {
                    marginRight: "7px",
                    marginLeft: "3px"
                  }
            }
          />
          {collapsed || hasViewChanged ? null : "Edit View"}
        </Button>
      )}
    >
      {({ closePopover }) => (
        <MenuCard>
          <MenuItem
            LeftIcon={EditIcon}
            onClick={() => {
              setShowEditViewName(true);
              closePopover();
            }}
          >
            Rename
          </MenuItem>
          {small && showOptions ? (
            <MenuItem
              LeftIcon={ReplyIcon}
              onClick={() => {
                revertViewChanges();
                closePopover();
              }}
            >
              Revert changes
            </MenuItem>
          ) : null}
          {small && showOptions && activeViewId !== "default" ? (
            <MenuItem
              LeftIcon={AddIcon}
              onClick={() => {
                addView();
                closePopover();
              }}
            >
              Save changes as new view
            </MenuItem>
          ) : null}
          <MenuItem
            LeftIcon={DeleteIcon}
            onClick={() => {
              deleteView();
              closePopover();
            }}
          >
            Delete View
          </MenuItem>
        </MenuCard>
      )}
    </Popover>
  );
};

const SaveView = ({
  instanceId,
  hasViewChanged,
  saveViewChanges,
  addView,
  revertViewChanges,
  deleteView,
  setShowEditViewName,
  activeViewId,
  collapsed,
  editing,
  small = false,
  onClick,
  white = false,
  vertical = false,
  ...styleProps
}) => {
  const showOptions = !editing && hasViewChanged;
  const hideMenu =
    (editing && collapsed) || activeViewId === "default" || !activeViewId;

  if (activeViewId === "default" && !collapsed && !hasViewChanged) {
    return null;
  }

  return (
    <Div
      style={
        vertical
          ? undefined
          : {
              backgroundColor: white ? "#fff" : "#7A38C3"
            }
      }
      bra={vertical ? 0 : 1}
      px={vertical ? 0 : small ? 1 : 2}
      py={vertical ? 0 : 2}
      mr={vertical ? 0 : 2}
      display={vertical ? "column" : "row.flex-start.center"}
      mt={vertical ? 1 : 0}
      {...styleProps}
    >
      {collapsed ? (
        <ViewPicker
          instanceId={instanceId}
          onClick={onClick}
          small={small}
          toolbar={true}
        />
      ) : null}

      <Div
        display="row.flex-start.center"
        color={white ? "primary7" : "white"}
        bra={1}
        width={1}
        height={vertical ? undefined : 35}
      >
        {showOptions ? (
          <SaveChanges
            {...{
              small,
              white,
              saveViewChanges,
              collapsed,
              vertical,
              showOptions,
              hideMenu
            }}
          />
        ) : null}

        {showOptions && !small ? (
          <RevertChanges
            {...{
              small,
              vertical,
              white,
              revertViewChanges,
              collapsed,
              hasViewChanged
            }}
          />
        ) : null}

        {showOptions && activeViewId !== "default" && !small ? (
          <SaveAsNewView
            {...{
              small,
              addView,
              white,
              vertical,
              collapsed,
              hasViewChanged
            }}
          />
        ) : null}

        {hideMenu ? null : (
          <MoreMenu
            {...{
              showOptions,
              collapsed,
              hasViewChanged,
              small,
              white,
              setShowEditViewName,
              revertViewChanges,
              activeViewId,
              deleteView,
              addView,
              vertical
            }}
          />
        )}
      </Div>
    </Div>
  );
};

export default CSSModules(decorate(SaveView), css);
