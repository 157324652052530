import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import { Div } from "components/Base";

import { getAlerts } from "../selectors";
import { actions } from "../model";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { TASK_ACTIONS } from "../constants";

const decorate = R.compose(
  connect(
    state => ({
      alerts: getAlerts(state)
    }),
    {
      executeAction: actions.executeAction
    }
  ),
  CSSModules(css)
);

export const Alert = ({ executeAction, ...alert }) => {
  const { id, name, date_label } = alert;

  return (
    <Div
      display="column"
      bg="white"
      px={3}
      py={3}
      bra={3}
      mb={3}
      shadow={{
        default: 0,
        hover: 2
      }}
      style={{
        cursor: "pointer",
        minHeight: "54px"
      }}
    >
      <Div
        display="column"
        width={1}
        onClick={() => executeAction({ type: TASK_ACTIONS.GO_TO_ALERT, id })}
      >
        <Div display="row.flex-start.flex-start" width={1}>
          <Div width={26} height={26} ml={1}>
            <img
              src="https://d2dks4tzxs6xee.cloudfront.net/img/portal-icon-document.png"
              width="26"
              height="26"
            />
          </Div>
          <Div display="column" flex={1} ml={2}>
            <Div fs={5} fw={3} color="black">
              {name}
            </Div>
            <Div
              width={1}
              fs={3}
              mt={1}
              color="gray6"
              style={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word"
              }}
            >
              {date_label}
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

const Alerts = ({ alerts, executeAction }) => {
  if (!alerts.length) {
    return null;
  }

  return (
    <Div
      bg="neutral1"
      style={{
        borderRadius: 10,
        padding: "10px 20px",
        marginBottom: "40px"
      }}
    >
      <Div display="row.flex-start.center" mb={4}>
        <Div
          className={css.tab}
          fw={4}
          style={{
            fontSize: "30px"
          }}
        >
          Alerts
        </Div>
      </Div>
      <Div>
        {alerts.map(alert => (
          <Alert key={alert.id} executeAction={executeAction} {...alert} />
        ))}
      </Div>
    </Div>
  );
};

export default decorate(Alerts);
