import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Div } from "components/Base";
import { actions } from "Orders/ImportModal";
import { WIZARD_PAGE } from "Orders/ImportModal/constants";
import { hideModal } from "redux/modules/modal/actions";
import {
  getInvalidFields,
  getCountOfRowsWithErrors,
  getFieldsWithErrors,
  getConfirmationCounters,
  isThereAnyValidRow
} from "Orders/ImportModal/selectors";

import ImportCsvInvalid from "ui-kit/ImportCSVInvalid";

const decorate = connect(
  state => ({
    invalidFields: getInvalidFields(state),
    countOfRowsWithErrors: getCountOfRowsWithErrors(state),
    isThereAnyValidRow: isThereAnyValidRow(state),
    fields: getFieldsWithErrors(state),
    counters: getConfirmationCounters(state)
  }),
  {
    hideModal,
    back: () => actions.setWizardPage(WIZARD_PAGE.BULK_IMPORT),
    uploadFile: () => actions.uploadFile()
  }
);

const InvalidRows = ({
  countOfRowsWithErrors = 0,
  isThereAnyValidRow = false,
  invalidFields = {},
  fields = [],
  counters = {}
}) => (
  <Div display="column" flex={1} height={1}>
    <ImportCsvInvalid
      {...invalidFields}
      fields={fields}
      isThereAnyValidRow={isThereAnyValidRow}
      countOfRowsWithErrors={countOfRowsWithErrors}
      counters={counters}
    />
  </Div>
);

InvalidRows.propTypes = {
  countOfRowsWithErrors: PropTypes.number.isRequired,
  isThereAnyValidRow: PropTypes.bool.isRequired,
  invalidFields: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  counters: PropTypes.object.isRequired
};

export default decorate(InvalidRows);
