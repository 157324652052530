import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as SubmissionSelectors from "redux/modules/formsV2/submission/selectors";
import * as SubmissionActivitySelectors from "redux/modules/formsV2/submission/activity/selectors";
import * as UserSelectors from "redux/modules/user/selectors";
import * as SubmissionCommentSelectors from "redux/modules/formsV2/submission/comments/selectors";
import { getActivity } from "redux/modules/formsV2/submission/activity/actions";
import {
  getComments,
  createComment
} from "redux/modules/formsV2/submission/comments/actions";
import Feed from "./Feed";

function mapStateToProps(state) {
  return {
    activity: SubmissionActivitySelectors.activity(state),
    comments: SubmissionCommentSelectors.comments(state),
    isFetchingActivity: SubmissionActivitySelectors.isFetching(state),
    isFetchingComments: SubmissionCommentSelectors.isFetching(state),
    submission: SubmissionSelectors.submission(state),
    user: UserSelectors.user(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createComment,
      getActivity,
      getComments
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Feed);
