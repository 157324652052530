import { connect } from "react-redux";
import { actions } from "Forms/WizardModal";
import { SCHEDULES_PAGES } from "Forms/WizardModal/constants";
import {
  getSelectedSchedule,
  isScheduleReviewBtnDisabled
} from "Forms/WizardModal/selectors";
import SelectQuestions from "Forms/WizardModal/View/Common/SelectQuestions";

const source = "schedule";

const decorate = connect(
  state => ({
    moduleSelected: getSelectedSchedule(state),
    isButtonDisabled: isScheduleReviewBtnDisabled(state),
    source
  }),
  {
    back: () => actions.backToSelectForm(),
    toggleSelectedModuleField: actions.toggleSelectedScheduleId,
    toggleRequiredModuleField: actions.toggleRequiredScheduleField,
    goToReview: () => actions.setSelectedSchedules(SCHEDULES_PAGES.REVIEW),
    selectedAllModuleField: actions.selectedAllScheduleFields,
    deselectedAllModuleField: actions.deselectedAllScheduleFields
  }
);

export default decorate(SelectQuestions);
