export const NAMESPACE = "PortalCustomizeSectionsModal";

export const SECTIONS = [
  {
    id: "dashboard",
    name: "Home",
    title: "Home",
    description: "",
    showDescription: true,
    custom: "",
    showForIndividualPortal: true,
    isEnabled: true,
    locked: true
  },
  {
    id: "people",
    name: "People",
    title: "People",
    description: "",
    showDescription: true,
    custom: "",
    showForIndividualPortal: false,
    isEnabled: true,
    locked: false
  },
  {
    id: "reports",
    name: "Reports",
    title: "Reports",
    description: "",
    showDescription: true,
    custom: "",
    showForIndividualPortal: false,
    isEnabled: true,
    locked: false
  }
  /*
    {
    id: "forms",
    name: "Forms",
    title: "Forms",
    description: "",
    showDescription: true,
    custom: "",
    showForIndividualPortal: true,
    isEnabled: true,
    locked: false
  },
  {
    id: "documents",
    name: "Documents",
    title: "Documents",
    description: "",
    showDescription: true,
    custom: "",
    showForIndividualPortal: true,
    isEnabled: true,
    locked: false
  },
  {
    id: "updates",
    name: "Updates",
    title: "Updates",
    description: "",
    showDescription: true,
    custom: "",
    showForIndividualPortal: true,
    isEnabled: true,
    locked: false
  }
  */
];

export const SETTINGS = {
  id: "965b3792-34dd-4b8a-b535-1d2cd0c15624",
  permission_set_id: "6e311fed-a36c-4742-9b2e-058fd07a4028",
  dashboard_title: "Dashboard",
  dashboard_description: "",
  show_dashboard_description: true,
  is_forms_enabled: true,
  forms_title: "Forms",
  forms_description: "",
  show_forms_description: true,
  forms_order: 1,
  is_people_enabled: false,
  people_title: "People",
  people_description: "",
  show_people_description: true,
  people_order: 2,
  is_schedules_enabled: true,
  schedules_title: null,
  schedules_description: null,
  show_schedules_description: true,
  schedules_order: 3,
  is_reports_enabled: false,
  reports_title: "Reportz",
  reports_description: "<p>Reportsz</p>",
  show_reports_description: true,
  reports_order: 3,
  is_documents_enabled: true,
  documents_title: "Documents",
  documents_description: "",
  show_documents_description: true,
  documents_order: 2,
  is_updates_enabled: true,
  updates_title: "Updates",
  updates_description: "",
  show_updates_description: true,
  updates_order: 3,
  is_messages_enabled: true,
  messages_title: null,
  messages_description: null,
  show_messages_description: true,
  messages_order: 7,
  created_at: "2020-06-16T16:42:14.953Z",
  updated_at: "2020-06-16T16:42:15.389Z"
};
