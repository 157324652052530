import Helpers from "utils/Global/Helpers";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";

export default {
  getEvent: ({ credentials, id, orgId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/organizations/${orgId}/events/${id}`,
        credentials,
        success,
        error
      });
    }),
  updateEvent: ({ credentials, id, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/event/${id}`,
        credentials,
        data,
        success,
        error
      });
    }),
  getUserProfiles: ({ credentials, id, orgId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/organizations/${orgId}/event-user-profiles?eventId=${id}`,
        credentials,
        success,
        error
      });
    }),
  getCategories: ({ credentials, id }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/items/groups/event/${id}/type/${CREDENTIAL_TYPE_ID}`,
        credentials,
        success,
        error
      });
    }),
  deleteItemRow: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        data,
        url: `${window.__LENND_API_BASE_URL__}/items/items`,
        credentials,
        success,
        error
      });
    }),
  addItemRow: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        data,
        url: `${window.__LENND_API_BASE_URL__}/items/items`,
        credentials,
        success,
        error
      });
    }),
  updateItemRow: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        data,
        url: `${window.__LENND_API_BASE_URL__}/items/items/bulk-update`,
        credentials,
        success,
        error
      });
    }),
  updateVariantPrices: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        data,
        url: `${window.__LENND_API_BASE_URL__}/items/variants`,
        credentials,
        success,
        error
      });
    }),
  updateUserProfileVariantPrices: ({ credentials, data, orgId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        data,
        url: `${
          window.__LENND_API_BASE_URL__
        }/organizations/${orgId}/user-profiles`,
        credentials,
        success,
        error
      });
    }),
  addItemGroup: ({ credentials, data }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/items/groups`,
        credentials,
        data,
        success,
        error
      });
    })
};
