import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "Items/SelectOrderManagementFieldsModal";
import {
  getReportingTitle,
  getTabs
} from "Items/SelectOrderManagementFieldsModal/selectors";
import { Div } from "components/Base";

const decorate = connect(
  state => ({
    selectedTab: getters.selectedTab(state),
    reportingTitle: getReportingTitle(state),
    tabs: getTabs(state)
  }),
  {
    setSelectedTab: actions.setSelectedTab
  }
);

const Tabs = ({ selectedTab, setSelectedTab, tabs }) => (
  <Div display="row.flex-start.center" bb={1} bc="neutral1" mt={3} px={4}>
    {tabs.map(tab => (
      <Div
        pb={1}
        fs={2}
        fw={3}
        color={selectedTab === tab.id ? "primary7" : "black"}
        bb={2}
        mr={3}
        bc={selectedTab === tab.id ? "primary7" : "transparent"}
        onClick={() => setSelectedTab(tab.id)}
      >
        {tab.name}
      </Div>
    ))}
  </Div>
);

export default decorate(Tabs);
