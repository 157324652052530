import { createSelector } from "reselect";
import * as R from "ramda";
import { isEventFeatureEnabled } from "redux/modules/event/selectors";
import { TAB_IDS } from "./constants";
import { FEATURES } from "utils/event-types-and-feature-constants";

export const getCurrentRoute = createSelector(
  (_, props) => props.routes,
  isEventFeatureEnabled,
  (routes, isEnabled) => {
    if (R.any(R.propEq("name", "EventLightPortals"))(routes)) {
      return isEnabled(FEATURES.PORTALS) ? TAB_IDS.PORTALS : null;
    } else if (R.any(R.propEq("name", "EventLightPortalsPages"))(routes)) {
      return isEnabled(FEATURES.PAGES) ? TAB_IDS.PAGES : null;
    }
    return null;
  }
);

export const getLinks = createSelector(
  (_, props) => props.params,
  getCurrentRoute,
  isEventFeatureEnabled,
  (params, currentRoute, isEnabled) => {
    return [
      {
        key: TAB_IDS.PORTALS,
        label: "Portals",
        active: currentRoute === TAB_IDS.PORTALS,
        to: `/event-light/${params.eventId}/portals`,
        visible: true
      },
      {
        key: TAB_IDS.PAGES,
        label: "Pages",
        active: currentRoute === TAB_IDS.PAGES,
        to: `/event-light/${params.eventId}/portals/pages`,
        visible: isEnabled(FEATURES.PAGES)
      }
    ].filter(l => l.visible);
  }
);
