import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import { PAYMENT_FORM_ID, PAYMENT_FIELDS } from "Sponsors/SelfSetup/constants";

import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";
import { WithFormInstanceProvider, Row, Column } from "ui-kit/Form/View";

import { getPaymentPercent, getPaymentFee } from "Sponsors/SelfSetup/selectors";

import { Text4, Text3, Text2, Span, BigFilledButton } from "components/Base";
import { QuestionCard } from "Sponsors/Common/View";

import CollectDeposit from "./CollectDeposit";
import ApplicationFee from "./ApplicationFee";

const decorate = R.compose(
  WithFormInstanceProvider(R.always(PAYMENT_FORM_ID)),
  WithThemeConsumer({ variant: VARIANTS.BACKGROUND }),
  connect(state => ({
    paymentPercent: getPaymentPercent(state),
    paymentFee: getPaymentFee(state)
  }))
);

const upfront = [
  { id: "upfront", label: "Upfront" },
  { id: "invoice", label: "Invoice" }
];
const processingFees = [
  { id: "passAlong", label: "Pass Along Fees To Applicants" },
  { id: "incurFee", label: "Incur Fee" },
  { id: "splitFee", label: "Split Fee" }
];
const Questions = ({ Th = defaultTh, paymentPercent, paymentFee }) => (
  <>
    {/*
    <CollectDeposit />
    <ApplicationFee />
    <QuestionCard
      mt={3}
      question={
        <Text3>
          When do you want to <Span fw={3}>collect credit card</Span>{" "}
          information?
        </Text3>
      }
      options={upfront}
      fieldId={PAYMENT_FIELDS.CREDIT_INFO}
    />
    */}
    <Text4 mt={3} bold>
      Connect with Stripe to securely collect & process credit card payments
    </Text4>
    <QuestionCard
      mt={3}
      question={
        <>
          <Text3>
            Please connect with Stripe to securely collect & process credit
            cards information
          </Text3>
          <Row
            {...Th(["bg"], {
              variant: Th.VARIANTS.BACKGROUND,
              p: 3,
              ba: 1,
              mt: 2,
              bc: "neutral3",
              bra: 1
            })}
            width={1}
          >
            <BigFilledButton
              {...Th(["bg", "color"], {
                active: true,
                variant: Th.VARIANTS.BACKGROUND
              })}
            >
              Connect with Stripe
            </BigFilledButton>
            <Column ml={3}>
              <Text3 bold>Processing Fee</Text3>
              <Text2>
                {paymentPercent}% + ${paymentFee} per transaction
              </Text2>
            </Column>
          </Row>
          <Text2 mt={2}>
            How do you want to handle the{" "}
            <Span fw={3}> credit card processing fees</Span>?
          </Text2>
        </>
      }
      options={processingFees}
      fieldId={PAYMENT_FIELDS.PROCESSING_FEE}
    />
  </>
);

export default decorate(Questions);
