import { createModule } from "redux-mvc";

import model from "./model";
import rootSaga from "./sagas";

const module = createModule(model);
module.setRootSaga(rootSaga);

const { actions, getters } = model;

export { actions, getters };

export default module;
