import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Reports from "./Reports";

import * as EventSelectors from "redux/modules/event/selectors";
import * as snackbarActions from "redux/modules/snackbar/actions";
import * as modalActions from "redux/modules/modal/actions";

function mapStateToProps(state) {
  return {
    eventDetails: EventSelectors.eventDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...modalActions,
      ...snackbarActions
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
