import { combineReducers } from "redux";
import widgets from "./widgets/reducer";
import { RECEIVE, REQUEST, ERROR, CLEAR_ERROR, INVALIDATE } from "./constants";

const dashboards = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return { ...state, [action.payload.type]: action.payload.dashboard };
    case INVALIDATE:
      return {};
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case ERROR:
      return action.payload;
    case CLEAR_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  dashboards,
  widgets,
  error,
  fetching
});
