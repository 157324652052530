import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Checkbox from "material-ui/Checkbox";

const Item = ({
  name,
  showCheckbox,
  onClick,
  selected,
  style,
  icon,
  faded
}) => (
  <div styleName="user" onClick={onClick} style={style}>
    {showCheckbox ? (
      <div styleName="checkboxWrapper">
        <Checkbox checked={selected} />
      </div>
    ) : null}
    <div
      styleName="name"
      style={{
        opacity: faded ? ".3" : 1
      }}
    >
      {name}
    </div>
    {icon}
  </div>
);

Item.propTypes = {
  name: PropTypes.string.isRequired,
  style: PropTypes.object,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  showCheckbox: PropTypes.bool,
  icon: PropTypes.object,
  faded: PropTypes.bool
};

export default CSSModules(Item, css);
