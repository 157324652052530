import React, { Component } from "react";
import * as R from "ramda";
import Fuse from "fuse.js";
import resolveRecordName from "components/Event/Module/utils/resolveRecordName";
import resolveFieldTypeName from "components/Global/Table3/FieldTypeIcons/resolve-field-type-name";
import resolveFieldTypeIconName from "components/Global/Table3/FieldTypeIcons/resolve-field-type-icon-name";
import View from "./View";

const mapIndexed = R.addIndex(R.map);

const searchWithFuse = (searchTerm, fuse, opt, matchWith) => {
  if (R.isNil(searchTerm) || R.isEmpty(searchTerm)) {
    return opt;
  }
  return R.compose(
    R.reject(R.isNil),
    R.map(o => R.find(R.propEq(matchWith, o.id), opt))
  )(fuse.search(searchTerm));
};

class Internal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fieldsSearchTerm: ""
    };
  }

  componentDidUpdate() {
    this.fieldsFuse = new Fuse(this.props.fields, {
      threshold: 0.3,
      keys: ["name", "type"],
      shouldSort: true
    });
  }

  onFieldsSearch = searchTerm =>
    this.setState({ fieldsSearchTerm: searchTerm });

  render() {
    const {
      contentOnly,

      module,
      fields,
      starredFields,
      fieldGroups,
      loading,

      updatePath,

      showCreateFieldGroupModal,
      showFieldGroupModal,
      showDeleteFieldGroupModal,

      showCreateFieldModal,
      showUpdateFieldModal,
      showDeleteFieldModal,

      addPrimary,
      removePrimary,

      handleUpdateModule,
      showDeleteModuleModal,
      showEmptyValueError
    } = this.props;

    const { fieldsSearchTerm } = this.state;

    const isDefaultFieldGroup = id =>
      R.propEq("source", "standard")(
        R.find(R.propEq("id", id))(fieldGroups || []) || {}
      );

    const isStarred = id =>
      !R.isNil(R.find(R.propEq("field_id", id))(starredFields || []));

    const isPrimaryField = field =>
      R.pathOr(false, ["is_primary_field"], field);

    const isLocked = id =>
      R.propEq("source", "standard")(
        R.find(R.propEq("id", id))(fields || []) || {}
      );

    const fieldGroupsWithHandlers = mapIndexed(fieldGroup => {
      const fields = searchWithFuse(
        fieldsSearchTerm,
        this.fieldsFuse,
        fieldGroup.fields,
        "field_id"
      );
      return {
        id: fieldGroup.id,
        name: fieldGroup.name,
        onEdit: () => showFieldGroupModal(fieldGroup),
        onDelete: () => showDeleteFieldGroupModal(fieldGroup),
        isDefault: isDefaultFieldGroup(fieldGroup.id),
        forceCollapse: fieldsSearchTerm.length && !fields.length,
        collapsedDefault: !fields.length,
        fields: R.map(field => {
          const fieldType = R.path(
            ["field", "settings", "isReferenceField"],
            field
          )
            ? "reference"
            : R.pathOr("", ["field", "type"], field);

          return {
            id: field.field.id,
            name: R.pathOr("", ["field", "name"], field),
            isPrimaryField: isPrimaryField(field.field),
            canTogglePrimaryField:
              ["text"].includes(fieldType) &&
              R.pathOr("", ["field", "source"], field) !== "standard",
            typeName: resolveFieldTypeName(fieldType),
            typeIcon: resolveFieldTypeIconName(fieldType),
            locked: isLocked(R.pathOr("", ["field", "id"], field)),
            onClickName: () => showUpdateFieldModal(field.field, fieldGroup),
            onClickExpand: () => showUpdateFieldModal(field.field, fieldGroup),
            onTogglePrimaryField: () =>
              isPrimaryField(field.field)
                ? removePrimary(field.field)
                : addPrimary(field.field),
            onEdit: () => showUpdateFieldModal(field.field, fieldGroup),
            onDelete: () => showDeleteFieldModal(field.field)
          };
        }, fields || [])
      };
    }, fieldGroups || []);

    const isDefaultModule = R.any(R.equals(module.internal_name))([
      "accounts",
      "contacts"
    ]);

    const modulePath = this.props.params.orgId
      ? ["/organization", this.props.params.orgId, "module", module.id].join(
          "/"
        )
      : [
          "/event",
          this.props.params.eventId,
          "module",
          module.id,
          "dashboard"
        ].join("/");

    return (
      <View
        {...{
          moduleId: module.id,
          isOrg: Boolean(this.props.params.orgId),
          contentOnly,
          loading,
          recordTypesEnabled: module.enable_record_types,
          isDefaultModule,
          goToName: resolveRecordName({
            moduleId: module.id,
            plural: true
          }).toLowerCase(),
          goToModule: () => this.props.router.push(modulePath),
          activeTabValue: this.props.route.name,
          setTabValue: updatePath,

          onCreateFieldGroup: showCreateFieldGroupModal,
          onFieldsSearch: this.onFieldsSearch,
          onCreateField: showCreateFieldModal,
          updateModuleInfo: handleUpdateModule,

          showEmptyValueError,
          moduleInfo: module,
          onDeleteModule: showDeleteModuleModal,
          fieldGroups: fieldGroupsWithHandlers
        }}
      />
    );
  }
}

export default Internal;
