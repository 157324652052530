import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { getFiles, getFilesTotal } from "../selectors";

import { ActionSection, ActionItem } from "PortalHub/ActionComponents/View";
import SlimPagination from "ui-kit/PaginationBar/View/Slim";

import Helpers from "utils/Global/Helpers";

import { CARD_ACTIONS, SECTION_ACTIONS } from "../constants";

const pageSizeOptions = [5, 10, 15];

const sectionButtons = [
  { label: "View All", id: SECTION_ACTIONS.VIEW_ALL_FILES, icon: "visibleIcon" }
];

const fileButtons = [
  { label: "Download", id: CARD_ACTIONS.DOWNLOAD_FILE, icon: "download" },
  { label: "Open", id: CARD_ACTIONS.VIEW_FILE, rightIcon: "openInNewIcon" }
];

const mapMime = mime => {
  if (Helpers.isPDF(mime)) {
    return "picture_as_pdf";
  }
  if (Helpers.isImage(mime)) {
    return "image";
  }
  if (Helpers.isSpreadsheet(mime)) {
    return "table_view";
  }
  if (Helpers.isPowerpoint(mime)) {
    return "slideshow";
  }
  if (Helpers.isWordDocument(mime)) {
    return "description";
  }
  if (Helpers.isZipFile(mime)) {
    return "archive";
  }
  if (Helpers.isAudio(mime)) {
    return "library_music";
  }
  if (Helpers.isVideo(mime)) {
    return "video_library";
  }
  if (Helpers.isIlustrator(mime)) {
    return "video_library";
  }
  if (Helpers.isPhotoshop(mime)) {
    return "video_library";
  }
  return "archive";
};

const decorate = connect((state, props) => ({
  files: getFiles(state, props),
  total: getFilesTotal(state, props)
}));

const FilesSection = ({ files = [], total }) => {
  if (total < 1) {
    return null;
  }
  return (
    <ActionSection title="Files" buttons={sectionButtons}>
      {R.map(
        file => (
          <ActionItem
            key={file.id}
            icon={mapMime(file.mimetype)}
            title={file.label}
            caption={file.description}
            buttons={fileButtons}
            data={file}
          />
        ),
        files
      )}
      <SlimPagination
        total={total}
        pageSizeOptions={pageSizeOptions}
        rowsText="files"
        instanceId="files"
        bg="transparent"
      />
    </ActionSection>
  );
};

export default decorate(FilesSection);
