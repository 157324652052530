import constructSearchObject from "utils/Search/construct-searchable-object";
import formatFields from "./utils/format-fields";
import formatRows from "./utils/format-rows";

const exportSheet = async ({
  showSnackbar,
  details,
  module,
  references,
  exportSpreadsheetArray,
  contentType,
  records,
  fields
}) => {
  const visibleFields = formatFields(fields).filter(f => f.visible);

  showSnackbar({ message: "Exporting...", action: "OK" });

  const sheet = formatRows(records, fields, {
    eventDetails: details
  }).reduce(
    (accumulatedSheet, row) => {
      const valueObject = constructSearchObject(
        visibleFields,
        row.values,
        row.id,
        details,
        {
          references
        }
      );
      accumulatedSheet.push(visibleFields.map(col => valueObject[col.id]));
      return accumulatedSheet;
    },
    [visibleFields.map(col => col.name)]
  );

  try {
    const result = await exportSpreadsheetArray({
      contentType,
      source: {
        type: module.record_name_plural
      },
      sheet
    });
    const { url } = result;

    if (!url) throw new Error();
    /* eslint-disable no-underscore-dangle */
    window.location = url;
    /* eslint-enable no-underscore-dangle */
  } catch (e) {
    showSnackbar({ message: "Oops, there was an error.", action: "OK" });
  }
};

export default exportSheet;
