import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SidebarManager from "./SidebarManager";
import { withRouter } from "react-router";
import can from "components/Global/Security";

import { getModulesWithCatalogItems } from "redux/modules/inventory/modules/actions";

import { modules } from "redux/modules/inventory/modules/selectors";
import { userPermissionProfile } from "redux/modules/permissions/user-permission-profile/selectors";

function mapStateToProps(state, props) {
  return {
    sidebarModules: modules(state).filter(m =>
      can(
        userPermissionProfile(
          state,
          props.params.eventId || props.params.orgId,
          state.user.user.id
        )
      ).do(`${m.id}_read`)
    )
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getModulesWithCatalogItems
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SidebarManager)
);
