import React from "react";
import { Div } from "components/Base";
import { connect } from "react-redux";
import Video from "./Video";

import { currentVideo } from "../../selectors";

const decorator = connect((state, props) => ({
  currentVideo: currentVideo(state, props)
}));

export const CurrentVideo = ({ currentVideo = {} }) => (
  <Div width={1}>
    <Video url={currentVideo.video_url} />
  </Div>
);

export default decorator(CurrentVideo);
