import * as R from "ramda";

export const settings = state => R.pathOr({}, ["portal", "settings"])(state);

export const sections = state =>
  R.pathOr([], ["portal", "settings", "sections"])(state);

export const getDashboardTitle = R.compose(
  R.prop("title"),
  R.find(R.propEq("id", "dashboard")),
  sections
);

export const getDashboardDescription = R.compose(
  R.prop("description"),
  R.find(R.propEq("id", "dashboard")),
  sections
);

export const getFormsTitle = R.compose(
  R.prop("title"),
  R.find(R.propEq("id", "forms")),
  sections
);

export const getFormsDescription = R.compose(
  R.prop("description"),
  R.find(R.propEq("id", "forms")),
  sections
);

export const getPeopleTitle = R.compose(
  R.prop("title"),
  R.find(R.propEq("id", "people")),
  sections
);

export const getPeopleDescription = R.compose(
  R.prop("description"),
  R.find(R.propEq("id", "people")),
  sections
);

export const getReportsTitle = R.compose(
  R.prop("title"),
  R.find(R.propEq("id", "reports")),
  sections
);

export const getReportsDescription = R.compose(
  R.prop("description"),
  R.find(R.propEq("id", "reports")),
  sections
);

export const getDocumentsTitle = R.compose(
  R.prop("title"),
  R.find(R.propEq("id", "documents")),
  sections
);

export const getDocumentsDescription = R.compose(
  R.prop("description"),
  R.find(R.propEq("id", "documents")),
  sections
);

export const getUpdatesTitle = R.compose(
  R.prop("title"),
  R.find(R.propEq("id", "updates")),
  sections
);

export const getUpdatesDescription = R.compose(
  R.prop("description"),
  R.find(R.propEq("id", "updates")),
  sections
);

export const getMessagesTitle = R.compose(
  R.prop("title"),
  R.find(R.propEq("id", "messages")),
  sections
);

export const getMessagesDescription = R.compose(
  R.prop("description"),
  R.find(R.propEq("id", "messages")),
  sections
);
