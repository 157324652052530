import React, { Component } from "react";
import moment from "moment";
import getValue from "utils/value-types/get-value/date";
import { EDITOR_FORMAT_MAP } from "components/Global/Table3/ModalColumn/DateEditorSettings";
import { get, isEqual } from "lodash";
import { Div } from "components/Base";

const FALLBACK_FORMAT = "dddd, MMMM D, YYYY";

class DateFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  render() {
    const { value, dependentValues } = this.props;
    const date = getValue(value);

    let dateFormat = get(dependentValues, "meta.columnSettings.dateFormat");
    if (!dateFormat) {
      const displayType = get(
        dependentValues,
        "meta.columnSettings.displayType"
      );
      if (displayType && displayType in EDITOR_FORMAT_MAP) {
        dateFormat = EDITOR_FORMAT_MAP[displayType];
      } else {
        dateFormat = FALLBACK_FORMAT;
      }
    }

    const text = date ? moment(date).format(dateFormat) : "";

    return (
      <Div
        truncate
        title={text}
        style={{
          userSelect: "none",
          lineHeight: "34px"
        }}
      >
        {text}
      </Div>
    );
  }
}

export default DateFormatter;
