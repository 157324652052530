import * as R from "ramda";
import { makeInstanceSelector } from "redux-mvc";
import { liftToArr } from "utils/General";

import { getters } from "./model";
import { LIST_INDEX } from "./constants";

import * as FormSelectors from "ui-kit/Form/selectors";

import * as STANDARD_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

const NON_EDITABLE_FIELD_IDS = [
  // module fields
  STANDARD_FIELD_IDS.MODULE.ID,
  STANDARD_FIELD_IDS.MODULE.TYPE,
  STANDARD_FIELD_IDS.MODULE.APPROVAL,
  STANDARD_FIELD_IDS.MODULE.OWNERS,
  STANDARD_FIELD_IDS.MODULE.SOURCE,
  STANDARD_FIELD_IDS.MODULE.CREATED_BY,
  STANDARD_FIELD_IDS.MODULE.LAST_MODIFIED_BY,
  STANDARD_FIELD_IDS.MODULE.CREATED_AT,
  STANDARD_FIELD_IDS.MODULE.LAST_MODIFIED_AT,
  STANDARD_FIELD_IDS.MODULE.LAST_SENT_AT,
  STANDARD_FIELD_IDS.MODULE.FULL_NAME,
  STANDARD_FIELD_IDS.MODULE.ACCOUNTS_LIST,
  STANDARD_FIELD_IDS.MODULE.EVENT_LIST,
  STANDARD_FIELD_IDS.MODULE.SUBMITTING_ACCOUNT,
  STANDARD_FIELD_IDS.MODULE.SUBMITTING_CONTACT,
  STANDARD_FIELD_IDS.MODULE.ACCOUNT_CONTACT,
  STANDARD_FIELD_IDS.MODULE.PRIMARY_CONTACT,
  STANDARD_FIELD_IDS.MODULE.ACCOUNT_LOGIN_USER,
  STANDARD_FIELD_IDS.MODULE.CONTACT_ROLE,
  STANDARD_FIELD_IDS.ACCOUNTS.PROFILE_APPROVAL,
  "has-portal-access",
  "synced-to-fuzion",
  "fuzion-exhibitor-id",
  "count-of-emails-sent",
  "email-last-sent",
  "last-login-at",
  "is-login-user",
  "is-primary-contact",
  "related-contact-name",
  "related-contact-type",
  "related-contact-email",
  // schedule fields
  STANDARD_FIELD_IDS.SCHEDULES.SCHEDULE,
  STANDARD_FIELD_IDS.SCHEDULES.COMPLETE,
  // order fields
  "order_payment_status",
  "order_payment_price",
  "item-details",
  "order_numbers",
  "item",
  "quantity",
  "order_name",
  "order_actions",
  "issued_id"
];

const getInstance = (_, props) => R.prop("instanceId", props);

export const getIsEditing = (state, props) =>
  R.equals(getters.editing(state, props), {
    rowId: R.path(["row", "id"], props),
    columnId: R.path(["column", "id"], props)
  });

export const hasDuplicates = (state, props = {}) =>
  props.canShowDeDuplicate &&
  R.values(getters.rows(state, props)).some(r => r.hasDuplicate);

export const getToggled = (state, props = {}) =>
  R.contains(props.rowId, getters.toggledRows(state, props));

export const getIsEditable = (state, props) => {
  const { column = {} } = props;

  const canEditCells = getters.canEditCells(state, props);
  const editableFieldsWhiteList = getters.editableFieldsWhiteList(state, props);
  const editableFieldsBlackList = getters.editableFieldsBlackList(state, props);

  // editors that are always editable
  if (["attendee-photo"].includes(column.type)) {
    return true;
  }

  if (!canEditCells) {
    return false;
  }
  if (R.contains(column.id, NON_EDITABLE_FIELD_IDS)) {
    return false;
  }
  if (R.pathEq(["settings", "editable"], false)(column)) {
    return false;
  }
  // implemented/allowed editors
  if (
    ![
      "text",
      "email",
      "phone",
      "countries",
      "date",
      "datetime",
      "time",
      "textarea",
      "checkbox",
      "currency",
      "dropdown",
      "number",
      "percent",
      "event-days",
      "lookup",
      "file",
      "catalog-item",
      "attendee-photo",
      "user"
    ].includes(column.type)
  ) {
    return false;
  }

  if (R.length(editableFieldsBlackList) > 0) {
    if (R.contains(column.id, editableFieldsBlackList)) {
      return false;
    }
  }

  if (R.length(editableFieldsWhiteList) > 0) {
    if (R.contains(column.id, editableFieldsWhiteList)) {
      return true;
    } else {
      return false;
    }
  }

  return true;
};

export const getIsReadOnly = (_, { column = {} }) =>
  R.pathEq(["settings", "readOnly"], true)(column);

export const getRowsIndex = makeInstanceSelector(
  getters.activeIndex,
  getters.rowIndex,
  R.propOr([])
)(getInstance);

export const getRowById = (state, props) =>
  R.prop(props.id, getters.rows(state, props)) || {};

export const getColumnById = (state, props) =>
  R.prop(props.id, getters.columns(state, props)) || {};

export const getEditingField = R.compose(
  field => ({
    type: R.path(["meta", "fieldType"], field),
    value: field.value
  }),
  FormSelectors.getField
);

export const getAllIds = (state, props) =>
  R.path(["group", "list"], props) ||
  R.pathOr([], [LIST_INDEX, 0, "list"], getters.rowIndex(state, props));

export const getAllToggled = makeInstanceSelector(
  getters.toggledRows,
  getAllIds,
  (toggledRows, allIds) =>
    R.length(R.intersection(toggledRows, allIds)) === R.length(allIds)
)(getInstance);

export const getGroupBy = makeInstanceSelector(
  getters.groupBy,
  R.compose(R.filter(R.compose(R.not, R.isEmpty)), liftToArr)
)(getInstance);

export const getTableWidth = makeInstanceSelector(
  getters.columns,
  getters.columnWidths,
  (columns, columnWidths) => {
    return R.compose(
      R.sum,
      R.map(columnId => R.propOr(200, columnId)(columnWidths)),
      R.keys
    )(columns);
  }
)(getInstance);
