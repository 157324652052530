import { createModule } from "redux-mvc";

import formModule from "ui-kit/Form";
import miniItemsSelector from "ui-kit/MiniItemsSelector";
import tabsModule from "ui-kit/Tabs";

import model from "./model";

import sagasModule from "./sagas";

const module = createModule({
  ...model,
  observedDomains: ["user", "event", "permissions", "organization", "@flopflip"]
});

module.setRootSaga(sagasModule);
module.plugModule(formModule);
module.plugModule(miniItemsSelector);
module.plugModule(tabsModule);

export default module;
