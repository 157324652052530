import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import jQuery from "jquery";
import Button from "../Button";
import DuplicateButton from "components/Event/FormsV2/Sections/FormGridManageRecipientRows/DuplicateButton";
import Table from "components/Event/FormsV2/Overview/SubmissionSummary/RequestTable/Table";
import RowSizer from "components/Global/Table3/Sizers/Rows";
import { get } from "lodash";
import isRowNotEmpty from "utils/EventForms/FormGrid/isRowNotEmpty";
import ReactDOM from "react-dom";
import { DEFAULT_APPROVAL_FIELD_APPROVE_REJECT } from "components/Event/FormsV2/constants";
import { DEFAULT_APPROVAL_FIELD_APPROVE_REJECT as DEFAULT_APPROVAL_FIELD_APPROVE_REJECT_V3 } from "components/Event/FormsV3/constants";
import resolveFormatter from "components/Global/Table3/CellFormatters/utils/resolveFormatterFormsV2";
import resolveCellClass from "components/Global/Table3/CellFormatters/utils/resolveCellClass";
import resolveEditor from "components/Global/Table3/CellEditors/utils/resolveEditor";
import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";
import getNotEmptyRows from "components/Event/FormsV2/Overview/SubmissionSummary/utils/get-not-empty-rows";
import AdminHeader from "components/Global/Table3/HeaderFormatters/Admin";
import RequiredHeader from "components/Global/Table3/HeaderFormatters/Required";
import Header from "components/Global/Table3/HeaderFormatters/Header";
import isAdminField from "components/Event/FormsV2/Utils/isAdminField";
import isRequiredField from "components/Event/FormsV2/Utils/isRequiredField";
import getFieldsGroupedBySection from "components/Event/FormsV2/Utils/getFieldsGroupedBySection";
import AlertPopover from "components/Global/Alerts/Popover";
import AlertLockedRow from "components/Event/FormsV2/Alerts/LockedRowEdit";
import AlertFirstRowApproved from "components/Event/FormsV2/Alerts/FirstRowApproved";
import AlertUndoApproval from "components/Event/FormsV2/Alerts/UndoApproval";
import getFormVersion from "components/Event/FormsV2/Utils/get-form-version";
import isRowPendingV2 from "../utils/is-row-pending-forms-v2";
import isRowPendingV3 from "../utils/is-row-pending-forms-v3";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const getApprovalColumns = version => {
  switch (version) {
    case 3:
      return [DEFAULT_APPROVAL_FIELD_APPROVE_REJECT_V3];
    default:
      return [DEFAULT_APPROVAL_FIELD_APPROVE_REJECT];
  }
};

const filterColumns = (submissionState, showAdminFields) => field =>
  showAdminFields ||
  !isAdminField(field) ||
  (isAdminField(field) &&
    !field.settings.adminFieldIsAdminOnly &&
    ["approved", "rejected", "reviewed"].includes(submissionState));

const getColumns = (
  showAdminFields,
  readOnly,
  columns,
  fieldWidths,
  getRowMetaData,
  eventDetails,
  submissionState,
  approvalColumns
) => {
  const scrollParent = jQuery(".table-scroll-container")[0];
  const columnFilter = filterColumns(submissionState, showAdminFields);

  return [...approvalColumns, ...columns].filter(columnFilter).map(column => {
    const isAdminFieldCheck = isAdminField(column);
    const isRequiredFieldCheck = isRequiredField(column);
    let headerRenderer;

    if (column.headerRenderer) {
      headerRenderer = <column.headerRenderer />;
    } else if (isAdminFieldCheck || isRequiredFieldCheck) {
      const types = [];
      let title = column.name;

      if (isAdminFieldCheck) {
        types.push(<AdminHeader {...column.settings} key="admin" />);
      }
      if (isRequiredFieldCheck) {
        title = <RequiredHeader>{title}</RequiredHeader>;
      }
      headerRenderer = <Header types={types}>{title}</Header>;
    }
    const result = {
      key: column.id,
      id: column.id,
      name: column.name,
      type: column.type,
      width: fieldWidths[column.id] || 200,
      cellClass: "",
      editable:
        !readOnly &&
        column.type !== "static" &&
        !get(column, "schema.locked", false),
      resizable: true,
      getRowMetaData: (rowData, field) =>
        getRowMetaData(columns, rowData, field),
      headerRenderer,
      settings: column.settings
    };

    if (!readOnly) {
      const Editor = resolveEditor(column);
      if (Editor) {
        const editorProps = {
          scrollParent,
          ...resolveEditorProps(column, eventDetails)
        };
        result.editor = <Editor {...editorProps} />;
      }
    }

    // formatters
    result.formatter = resolveFormatter(column);
    result.cellClass = resolveCellClass(column);

    // instance specific
    if (column.id === -1) {
      result.cellClass += " react-grid-Cell-title";
    }

    return result;
  });
};

@CSSModules(css)
class RequestTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: Boolean(props.values.length),
      popoverOpen: false,
      popoverAnchorEl: null,
      popoverContent: null,
      selectedRows: []
    };
  }

  componentDidMount() {
    this.setState({
      popoverAnchorEl: ReactDOM.findDOMNode(this.tableRef)
    });
  }

  @autobind
  toggleOpen() {
    this.setState({
      open: !this.state.open
    });
  }

  @autobind
  onRowSelect(rows) {
    this.setState({ selectedRows: rows });
  }

  @autobind
  getRowMetaData(columns, rowData, field) {
    const metaData = this.props.getMetaData(columns, rowData, field);
    metaData.meta.subformId = this.props.form.id;
    metaData.meta.isRowEmpty = !isRowNotEmpty(rowData, columns);
    metaData.helpers.showAdminChangeDialog = this.showAdminChangeDialog;
    metaData.helpers.showAdminUndoDialog = this.showAdminUndoDialog;
    return metaData;
  }

  @autobind
  showRejectedChangeDialog() {
    // Best try at getting the cell that was edited
    const cell = jQuery(".editor-main")
      .parents(".react-grid-Cell")
      .get(0);
    const popoverContent = <AlertLockedRow onAccept={this.onRequestClose} />;
    this.setState({
      popoverAnchorEl:
        cell || document.activeElement || this.state.popoverAnchorEl,
      popoverOpen: true,
      popoverContent
    });
  }

  @autobind
  showAdminChangeDialog(submissionId, onConfirm) {
    // show notice if admin trying to change value while submission is unlocked AND
    // no other rows are approved
    if (!this.props.isSubmissionLocked && !this.props.isSubmissionInReview) {
      const onAccept = shouldLock => {
        if (shouldLock) {
          this.props.setLock(true);
        }
        onConfirm();
        this.onRequestClose();
      };
      const popoverContent = (
        <AlertFirstRowApproved
          onCancel={this.onRequestClose}
          onAccept={onAccept}
        />
      );

      this.setState({
        popoverAnchorEl: document.activeElement || this.state.popoverAnchorEl,
        popoverOpen: true,
        popoverContent
      });
    } else {
      onConfirm();
    }
  }

  @autobind
  showAdminUndoDialog(submissionId, onConfirm) {
    const onAccept = () => {
      onConfirm();
      this.onRequestClose();
    };
    this.setState({
      popoverAnchorEl: document.activeElement || this.state.popoverAnchorEl,
      popoverOpen: true,
      popoverContent: (
        <AlertUndoApproval onCancel={this.onRequestClose} onAccept={onAccept} />
      )
    });
  }

  @autobind
  onRequestClose() {
    this.setState({ popoverOpen: false });
  }

  @autobind
  validateUpdate(columns, rows, e) {
    const version = this.getFormVersion();
    if (version === 3) {
      return this.saveCell(e);
    }

    const field = find(columns, { id: e.columnId });
    const row = rows.find(r => r.id === e.rowId);
    const isReviewed = !this.isRowPending(row);

    // show notice if trying to update approved / rejected row
    // @NOTE: Shouldn't fire if approval notes OR admin field
    if (isReviewed && !get(field, "settings.isAdminField")) {
      return this.showRejectedChangeDialog();
    }
    return this.saveCell(e);
  }

  @autobind
  saveCell({ columnId, rowId, value }) {
    this.props.saveCell({
      fieldId: columnId,
      submissionId: rowId,
      value
    });
  }

  @autobind
  duplicateSelectedRows(quantity) {
    this.props.cloneSubformSubmissions(
      this.state.selectedRows.map(row => row.id),
      quantity
    );
  }

  @autobind
  sortEmptyRowsToBottom(rows) {
    // @TODO: Figure out a way to move this out of the mapping here out
    // of the render path. Expensive.
    return rows
      .map(r => ({
        ...r,
        isEmpty: !isRowNotEmpty(r, this.props.fields)
      }))
      .sort((a, b) => (a.isEmpty === b.isEmpty ? 0 : a.isEmpty ? 1 : -1));
  }

  @autobind
  getFormVersion() {
    return getFormVersion(get(this.props.form, "subform.form", {}));
  }

  @autobind
  isRowPending(r) {
    const version = this.getFormVersion();
    switch (version) {
      case 3:
        return isRowPendingV3(r);
      default:
        return isRowPendingV2(r);
    }
  }

  @autobind
  renderFooter(reviews) {
    if (this.state.selectedRows.length) {
      return (
        <div styleName="footer">
          <Button onClick={() => this.props.bulkCreateReviews(reviews)}>
            <i className="material-icons" styleName="buttonIcon">
              done
            </i>
            {`Approve ${reviews.length} selected`}
          </Button>
          <DuplicateButton
            selectedRowsCount={this.state.selectedRows.length}
            duplicate={this.duplicateSelectedRows}
            button={
              <Button>
                <i className={`material-icons ${css.buttonIcon}`}>
                  control_point_duplicate
                </i>
                {`Duplicate ${
                  this.state.selectedRows.length !== 1 ? "rows" : "row"
                }`}
              </Button>
            }
          />
        </div>
      );
    }
    return (
      <div styleName="footer">
        {reviews.length ? (
          <Button onClick={() => this.props.bulkCreateReviews(reviews)}>
            <i className="material-icons" styleName="buttonIcon">
              done
            </i>
            {`Approve ${reviews.length} remaining`}
          </Button>
        ) : null}
        <Button onClick={() => this.props.createSubformSubmission()}>
          <i className="material-icons" styleName="buttonIcon">
            &#xE145;
          </i>
          Add Request
        </Button>
      </div>
    );
  }

  render() {
    const {
      eventDetails,
      filteredSubmissions,
      form,
      preferences,
      readOnly,
      showAdminFields,
      submissionState,
      title,
      values
    } = this.props;

    const columns = getColumns(
      showAdminFields,
      readOnly,
      getFieldsGroupedBySection(
        get(form, "subform.form.fields_grouped_by_section")
      ),
      get(preferences, "field_widths", {}),
      this.getRowMetaData,
      eventDetails,
      submissionState,
      getApprovalColumns(this.getFormVersion())
    );

    const rows = filteredSubmissions
      ? this.sortEmptyRowsToBottom(values).filter(r =>
          filteredSubmissions.includes(r.id)
        )
      : this.sortEmptyRowsToBottom(values);

    const transformRowToReview = row => ({
      submissionId: row.id,
      status: "approve"
    });

    const reviews = this.state.selectedRows.length
      ? getNotEmptyRows(this.state.selectedRows, this.props.fields).map(
          transformRowToReview
        )
      : getNotEmptyRows(rows, this.props.fields)
          .filter(this.isRowPending)
          .map(transformRowToReview);

    const table = (
      <div
        className="data-grid-no-lines react-grid-Lennd-Form-Wrapper"
        data-grid-id={form.id}
      >
        <div ref={c => (this.tableRef = c)} styleName="table">
          <AlertPopover
            open={this.state.popoverOpen}
            anchorEl={this.state.popoverAnchorEl}
            onClose={this.onRequestClose}
          >
            {this.state.popoverContent}
          </AlertPopover>
          <RowSizer rows={rows}>
            <Table
              form={form}
              fields={columns}
              saveCell={this.validateUpdate.bind(this, columns, values)}
              onRowSelect={this.onRowSelect}
              rows={rows}
              selectedRows={this.state.selectedRows}
            />
          </RowSizer>
        </div>
        {!readOnly ? this.renderFooter(reviews) : null}
      </div>
    );
    return (
      <div styleName="container" ref="container">
        <div onClick={this.toggleOpen} styleName="header">
          <i
            className="material-icons"
            styleName={
              this.state.open ? "toggleArrowOpen" : "toggleArrowClosed"
            }
          >
            arrow_drop_down
          </i>
          <div styleName="label">
            {`${title} (${getNotEmptyRows(rows, this.props.fields).length})`}
          </div>
        </div>
        {this.state.open ? table : ""}
      </div>
    );
  }
}

RequestTable.propTypes = {
  bulkCreateReviews: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  cloneSubformSubmissions: PropTypes.func.isRequired,
  createSubformSubmission: PropTypes.func.isRequired,
  eventDetails: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  filteredSubmissions: PropTypes.array,
  form: PropTypes.object.isRequired,
  getMetaData: PropTypes.func.isRequired,
  isSubmissionInReview: PropTypes.bool.isRequired,
  isSubmissionLocked: PropTypes.bool.isRequired,
  preferences: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  saveCell: PropTypes.func.isRequired,
  setLock: PropTypes.func.isRequired,
  showAdminFields: PropTypes.bool,
  showModal: PropTypes.func.isRequired,
  submission: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  values: PropTypes.array.isRequired
};

export default RequestTable;
