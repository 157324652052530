import * as R from "ramda";
import { createContext } from "redux-mvc";

import module from "../index";
import Layout from "./Layout";

const decorate = createContext({
  module,
  options: {
    observedDomains: [
      "user",
      "event",
      "organization",
      "permissions",
      "formsV2",
      "eventUsers",
      "items",
      "modules",
      "routing"
    ],
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    persist: false
  }
});

export default decorate(Layout);
