import React, { Component } from "react";
import * as R from "ramda";
import { NONE } from "utils/system-field-values";
import { Div } from "components/Base";
import moment from "moment";

import { getCellValue, getRow } from "ui-kit/Datagrid/utils";

const Wrapper = ({ children }) => (
  <Div
    display="row.flex-start.center"
    minHeight={23}
    style={{
      overflow: "hidden",
      userSelect: "none"
    }}
  >
    {children}
  </Div>
);

const Meal = ({ children }) => (
  <Div
    display="row.flex-start.center"
    mr={2}
    style={{
      flexBasis: "auto",
      flexShrink: 0,
      borderRadius: 25,
      backgroundColor: "#EAEAEA",
      lineHeight: "normal",
      whiteSpace: "nowrap"
    }}
  >
    {children}
  </Div>
);

const Title = ({ children }) => (
  <Div
    style={{
      padding: "3px 6px 3px 8px",
      fontSize: 12
    }}
  >
    {children}
  </Div>
);

class Catering extends Component {
  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props.value, nextProps.value);
  }

  render() {
    const props = this.props;
    const meals = getCellValue(props, "meals", []);

    if (R.any(m => m.id && m.id === NONE.id && m.checked, meals)) {
      return (
        <Wrapper>
          <Meal>
            <Title>{NONE.value}</Title>
          </Meal>
        </Wrapper>
      );
    }

    const row = getRow(props);
    const daysOnSite = R.find(
      R.compose(
        R.equals("event-days"),
        R.prop("type")
      ),
      R.values(row)
    );

    return (
      <Wrapper>
        {meals.map(meal => {
          let dates;

          // if we have a days-on-site field to reference, only show
          // selected meal days that match the date ranges of the days on site
          if (daysOnSite) {
            dates = R.propOr([], "dates", meal).filter(({ date }) => {
              let isWithinSelectedDaysOnSite = false;
              R.propOr([], "value", daysOnSite).forEach(d => {
                const contains = moment(d.start)
                  .twix(d.end)
                  .contains(date);
                if (contains) {
                  isWithinSelectedDaysOnSite = true;
                }
              });
              return isWithinSelectedDaysOnSite;
            });
          } else {
            dates = meal.dates;
          }

          if (!dates.length) {
            return "";
          }
          return (
            <Meal key={meal.id}>
              <Title>{meal.name}</Title>
              <Div
                style={{
                  background: "rgba(#E4E3E4, 0.75)",
                  borderRadius: 50,
                  fontSize: 13,
                  padding: "3px 9px",
                  alignSelf: "stretch",
                  fontWeight: "bold"
                }}
              >
                {R.sumBy(R.map(R.prop("quantity"), dates))}
              </Div>
            </Meal>
          );
        })}
      </Wrapper>
    );
  }
}

export default Catering;
