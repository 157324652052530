import React, { Fragment } from "react";
import { connect } from "react-redux";

import * as selectors from "Submission/Editor/selectors";
import {
  getShowAcceptPayment,
  getShowTransactions
} from "Orders/OrderModal/selectors";

import { getters } from "Orders/OrderModal";

import Invoice from "Orders/OrderModal/View/Invoice";
import EmailConfirmation from "Orders/OrderModal/View/EmailConfirmation";
import EmailInvoice from "Orders/OrderModal/View/EmailInvoice";
import AcceptPayment from "Orders/OrderModal/View/AcceptPayment";
import Transactions from "Orders/OrderModal/View/Transactions";
import Passes from "Orders/OrderModal/View/Passes";
import EnableOrder from "Orders/OrderModal/View/EnableOrder";
import SubmissionStatusbar from "Orders/OrderModal/View/SubmissionStatusbar";
import SubmissionFields from "Orders/OrderModal/View/SubmissionFields";
import OrderFields from "Orders/OrderModal/View/OrderFields";
import Integrations from "Orders/OrderModal/View/Integrations";

const decorate = connect(state => ({
  hasRelatedOrder: selectors.hasRelatedOrder(state),
  showAcceptPayment: getShowAcceptPayment(state),
  showTransactions: getShowTransactions(state),
  restricted: getters.isPortalUser(state)
}));

const SummaryView = ({
  hasRelatedOrder,
  showAcceptPayment,
  showTransactions,
  restricted = false,
  handlers = {}
}) =>
  hasRelatedOrder ? (
    <Fragment>
      <SubmissionStatusbar handlers={handlers} />
      <Passes handlers={handlers} />
      <SubmissionFields handlers={handlers} />
      <OrderFields handlers={handlers} />
      {restricted || !showAcceptPayment ? null : <Invoice />}
      {restricted || !showAcceptPayment ? null : (
        <AcceptPayment handlers={handlers} />
      )}
      {restricted ? null : showAcceptPayment ? (
        <EmailInvoice handlers={handlers} />
      ) : (
        <EmailConfirmation handlers={handlers} />
      )}
      {!restricted && <Integrations handlers={handlers} />}
      {!showTransactions ? null : <Transactions handlers={handlers} />}
    </Fragment>
  ) : (
    <Fragment>
      <SubmissionStatusbar handlers={handlers} />
      <SubmissionFields handlers={handlers} />
      {!restricted && <EnableOrder handlers={handlers} />}
    </Fragment>
  );

export default decorate(SummaryView);
