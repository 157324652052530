export const NAMESPACE = "EmailsHistory";

export const TABLE_INSTANCE_ID = "manage-items-table";

export const STATUS = {
  ALL: "all",
  ERROR: "error"
};

export const TABLE_VIEW_ID = "order-mgm-table-view";

export const TAB_IDS = {
  EMAILS: "email",
  SMS: "sms"
};
