import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Badge = ({ className, children }) => (
  <div className={className} styleName="container">
    {children}
  </div>
);

Badge.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

export default CSSModules(Badge, css);
