import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import {
  getSelectedAccount,
  getContactsOutsideAccount,
  getContactsInsideAccount
} from "../selectors";
import {
  Div,
  Text0,
  Text2,
  Text3,
  SearchIcon,
  LeftIcon
} from "components/Base";
import PersonCard from "./PersonCard";
import Circle from "ui-kit/Circle";
import SearchBar from "ui-kit/SearchBar/View";
import { withProps } from "utils/General";
import Loading from "ui-kit/Loading";
import { addS } from "utils/General";
import EmptyCard from "./EmptyCard";

const SearchIconPurple = withProps({
  color: "primary7"
})(SearchIcon);

const decorate = connect(
  state => ({
    selectedContacts: getContactsInsideAccount(state),
    selectedAccount: getSelectedAccount(state),
    loading: getters.loading(state),
    contactsOutsideAccount: getContactsOutsideAccount(state)
  }),
  {
    backToResults: actions.backToResults
  }
);

const Account = ({ name, type_name }) => (
  <Div display="row.flex-start.center" height={1} mb={2}>
    <Circle bg="altB5" size={28}>
      <Text3 bold color="white">
        {R.toUpper(R.propOr("", 0)(name || ""))}
      </Text3>
    </Circle>
    <Div height={1} display="column.space-between" ml={2}>
      <Text3 bold>{name}</Text3>
      <Text0>{type_name}</Text0>
    </Div>
  </Div>
);

const SelectedPeople = ({
  selectedContacts,
  onSelectCustomer,
  selectedAccount,
  backToResults,
  handlers,
  loading,
  contactsOutsideAccount
}) => {
  const contactsOutsideAccountLength = R.length(contactsOutsideAccount);
  return (
    <Div p={8} pt={4}>
      <Div display="row.flex-start.center" mb={2} onClick={backToResults}>
        <LeftIcon color="primary7" />
        <Text2 ml={1} color="primary7">
          back to results
        </Text2>
      </Div>
      <Account {...selectedAccount} />
      <SearchBar
        RightIcon={SearchIconPurple}
        width={1}
        placeholder="Who is the person responsible for this?"
        placeholderWithDots={false}
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          {R.map(
            contact => (
              <PersonCard
                {...contact}
                onSelectCustomer={onSelectCustomer}
                accountId={selectedAccount.id}
                accountName={selectedAccount.name}
                selectContact={({ contactId, contactName, contactEmail }) =>
                  onSelectCustomer({
                    accountId: selectedAccount.id,
                    accountName: selectedAccount.name,
                    contactId,
                    contactName,
                    contactEmail
                  })
                }
              />
            ),
            selectedContacts
          )}
          {contactsOutsideAccountLength > 0 ? (
            <Text2 mt={2}>
              {contactsOutsideAccountLength} result
              {addS(contactsOutsideAccountLength)} outside of{" "}
              {selectedAccount.name}
            </Text2>
          ) : null}
          {R.map(
            contact => (
              <PersonCard
                {...contact}
                selectContact={({ contactId, contactName, contactEmail }) =>
                  onSelectCustomer({
                    accountId: selectedAccount.id,
                    accountName: selectedAccount.name,
                    contactId,
                    contactName,
                    contactEmail
                  })
                }
              />
            ),
            contactsOutsideAccount
          )}
          <EmptyCard
            label="person"
            onClick={() =>
              onSelectCustomer({
                accountId: selectedAccount.id,
                accountName: selectedAccount.name,
                contactId: null,
                contactName: null,
                contactEmail: null
              })
            }
          />

          <Div
            bra={1}
            bg="neutral0"
            p={4}
            mt={5}
            display="column.flex-start.center"
            textAlign="center"
            height={113}
          >
            <Div>Can't find the person you're looking for?</Div>
            <Div display="row.flex-start.center" mt={4}>
              <Div
                width={148}
                height={38}
                bra={1}
                ba={1}
                bg={{
                  default: "neutral0",
                  hover: "neutral1"
                }}
                bc="primary7"
                color="primary7"
                display="row.center.center"
                onClick={() => {
                  handlers.showAddContactModal({
                    accountId: selectedAccount.id,
                    accountName: selectedAccount.name,
                    isLastStep: true
                  });
                }}
              >
                Add New Person
              </Div>
            </Div>
          </Div>
        </>
      )}
    </Div>
  );
};

export default decorate(SelectedPeople);
