import React, { PureComponent } from "react";
import { Div } from "components/Base";
import * as R from "ramda";

const Wrapper = ({ children }) => (
  <Div
    display="row.flex-start.center"
    minHeight={23}
    style={{
      overflow: "hidden",
      userSelect: "none"
    }}
  >
    {children}
  </Div>
);

const Title = ({ children }) => (
  <Div
    style={{
      padding: "3px 6px 3px 8px",
      fontSize: 12
    }}
  >
    {children}
  </Div>
);

class ItemBlockList extends PureComponent {
  render() {
    // @NOTE: Commenting out until re-implemented
    return (
      <Wrapper>
        {R.compose(
          groupedItems =>
            R.map(itemKey => {
              const item = R.path([itemKey, 0])(groupedItems);

              let quantity = R.compose(
                R.sum,
                R.map(R.prop("quantity"))
              )(groupedItems[itemKey]);

              if (item.price) {
                quantity = `${quantity} ($${item.price})`;
              }
              return (
                <Div
                  key={item.id}
                  display="row.flex-start.center"
                  mr={2}
                  style={{
                    flexDirection: "row",
                    flexBasis: "auto",
                    flexShrink: 0,
                    borderRadius: 25,
                    fontSize: 1,
                    color: "#494949",
                    padding: "3px 8px",
                    lineHeight: "normal",
                    whiteSpace: "nowrap",
                    backgroundColor: item.color || "#E7E7E7",
                    border:
                      item.color === "#FFFFFF" ? "1px solid #E7E7E7" : "none"
                  }}
                >
                  <Title>{item.value}</Title>
                  <Div
                    style={{
                      background: "rgba(0,0,0,0.1)",
                      borderRadius: 50,
                      fontSize: 13,
                      padding: "3px 9px",
                      alignSelf: "stretch",
                      fontWeight: "bold"
                    }}
                  >
                    {quantity}
                  </Div>
                </Div>
              );
            })(R.keys(groupedItems)),
          R.groupBy(item => `${item.id}_${item.price}`)
        )(this.props.items)}
      </Wrapper>
    );
  }
}

export default ItemBlockList;
