import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Buttons from "./Buttons";

import { showModal, hideModal } from "redux/modules/modal/actions";
import * as ModuleRecordsActions from "redux/modules/modules/records/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...ModuleRecordsActions,
      showSnackbar,
      showModal,
      hideModal
    },
    dispatch
  );
}

export default connect(
  null,
  mapDispatchToProps
)(Buttons);
