import PropTypes from "prop-types";
import React from "react";
import { get } from "lodash";
import Formatter from "../../Table3/CellFormatters/CateringDietaryRestrictions";
import Editor from "../../Editors/CateringDietaryRestrictionsEditor";
import SelectBase from "../SelectBase";

const CateringDietaryRestrictionsSelect = ({
  onChange,
  value,
  options,
  disabled,
  usePortal
}) => (
  <Editor
    onChange={onChange}
    options={options}
    value={value}
    disabled={disabled}
    usePortal={usePortal}
  />
);

CateringDietaryRestrictionsSelect.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default CateringDietaryRestrictionsSelect;
