import React from "react";
import BaseController from "components/Global/Module/Modals/AddRecord/Layouts/Base";
import { connect } from "react-redux";
import { references } from "redux/modules/entityReferences/selectors";

import FormElements from "components/Global/Modal/Layout/FormElements";
import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";
import resolveEditor from "components/Global/StandAloneEditors/utils/resolveEditor";
import getValue from "utils/value-types/get-value";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_MODULE_FIELDS from "utils/standard-module-field-ids";

const { Label, InputSection, InputGroup } = FormElements;
const CATEGORY_NAME_FIELD_ID = STANDARD_MODULE_FIELDS.CATALOG_CATEGORIES.NAME;
const NAME_FIELD_ID = STANDARD_MODULE_FIELDS.CATALOG_ITEMS.ITEM_NAME;
const CATEGORY_FIELD_ID = STANDARD_MODULE_FIELDS.CATALOG_ITEMS.CATEGORY;
const CATALOG_ITEM_FIELDS = [
  {
    id: CATEGORY_FIELD_ID,
    name: "Category",
    type: "lookup",
    settings: {
      moduleId: STANDARD_MODULE_IDS.catalogCategories.id,
      fieldId: CATEGORY_NAME_FIELD_ID
    }
  },
  {
    id: NAME_FIELD_ID,
    name: "Name",
    type: "text",
    settings: {}
  }
];

class AddRecordModal extends BaseController {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      values: { ...props.values }
    };
  }

  validateForm = () => {
    const name = getValue(this.state.values[NAME_FIELD_ID], "text");
    const category = getValue(this.state.values[CATEGORY_FIELD_ID], "lookup");

    if (!name || !name.length || !category || !category.records.length) {
      return false;
    }

    return true;
  };

  handleSave = () =>
    new Promise((resolve, reject) => {
      if (this.validateForm()) {
        this.props
          .addRecord({
            orgId: this.props.orgId,
            eventId: this.props.eventId,
            moduleId: this.props.moduleId,
            record: this.state.values,
            options: {
              orgId: this.props.orgId,
              eventId: this.props.eventId
            }
          })
          .then(record => {
            resolve(record);
          });
      } else {
        return reject();
      }
    });

  renderFields = fields => {
    return (
      <div>
        {fields.map(field => {
          const Editor = resolveEditor(field);
          return (
            <InputSection key={field.id}>
              <InputGroup>
                <Label>{field.name}</Label>
                <Editor
                  rowMetaData={this.getRowMetaData({
                    row: this.state.values,
                    field,
                    fields
                  })}
                  value={this.state.values[field.id]}
                  onChange={val => this.saveFieldValue(field.id, val)}
                  {...resolveEditorProps(field, this.props.eventDetails)}
                />
              </InputGroup>
            </InputSection>
          );
        })}
      </div>
    );
  };

  getFieldsToShow = () => this.props.moduleFields;
}

function mapStateToProps(state) {
  return {
    references: references(state),
    moduleDetails: STANDARD_MODULE_IDS.catalogItems,
    moduleFields: CATALOG_ITEM_FIELDS
  };
}

export default connect(mapStateToProps)(AddRecordModal);
