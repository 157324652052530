import * as R from "ramda";
import { createHandlers, createModule } from "redux-mvc";

import {
  NAMESPACE,
  MODE_SINGLE_SELECT,
  MODE_MULTIPLE_SELECT,
  MODE_PRICE_SELECT
} from "./constants";

import { toString } from "utils/General";

import searchBar from "ui-kit/SearchBar";

const iniState = {
  selectedItems: [],
  selectedItemsCount: {},
  selectedItemsPrice: {},
  selectedGroups: [],
  selectedTab: "",
  mode: MODE_SINGLE_SELECT,
  enableReorder: false,
  itemOrder: {},
  loading: false,
  clearSearch: false
};

const reducers = {
  init: (
    _,
    {
      payload: { selectedItems, selectedTab, mode, enableReorder, clearSearch }
    }
  ) => ({
    selectedItems:
      mode === MODE_SINGLE_SELECT ? selectedItems : R.keys(selectedItems),
    selectedItemsCount: mode === MODE_MULTIPLE_SELECT ? selectedItems : {},
    selectedItemsPrice:
      mode === MODE_PRICE_SELECT
        ? R.keys(selectedItems).reduce((map, id) => {
            map[id] = selectedItems[id].priceId || selectedItems[id].price;
            return map;
          }, {})
        : {},
    itemOrder:
      mode === MODE_SINGLE_SELECT
        ? selectedItems.reduce((map, id, idx) => {
            map[id] = idx;
            return map;
          }, {})
        : R.keys(selectedItems).reduce((map, id, idx) => {
            map[id] = idx;
            return map;
          }, {}),
    selectedTab,
    mode,
    enableReorder,
    clearSearch
  }),
  toggleItem: (
    { selectedItems, selectedItemsPrice, itemOrder },
    { payload: { id: itemId, priceId } }
  ) =>
    R.contains(toString(itemId), selectedItems)
      ? {
          selectedItems: R.without([toString(itemId)], selectedItems),
          itemOrder: R.compose(
            R.reduce((map, key) => {
              map[key] = itemOrder[key];
              return map;
            }, {}),
            R.filter(key => key !== itemId),
            R.keys
          )(itemOrder)
        }
      : {
          selectedItems: R.uniq(R.concat(selectedItems, [toString(itemId)])),
          selectedItemsPrice: {
            ...selectedItemsPrice,
            [itemId]: priceId
          },
          itemOrder: {
            ...itemOrder,
            [itemId]: Object.keys(itemOrder).length + 1
          }
        },
  setItemCount: (
    { selectedItemsCount },
    { payload: { id: itemId, count } }
  ) => ({
    selectedItemsCount: R.assoc(itemId, count, selectedItemsCount)
  }),
  setItemPrice: (
    { selectedItemsPrice },
    { payload: { id: itemId, priceId } }
  ) => ({
    selectedItemsPrice: R.assoc(itemId, priceId, selectedItemsPrice)
  }),
  deselectField: ({ selectedItems }, { payload: itemId }) => ({
    selectedItems: R.without([toString(itemId)], selectedItems)
  }),
  selectGroup: ({ selectedItems }, { payload: group }) => {
    const selected = R.uniq(
      R.concat(
        selectedItems,
        R.map(
          R.compose(
            toString,
            item => item.id
          ),
          group.items
        )
      )
    );

    return {
      selectedItems: selected,
      itemOrder: selected.reduce((map, itemId, idx) => {
        map[itemId] = idx;
        return map;
      }, {})
    };
  },
  deselectGroup: ({ selectedItems }, { payload: group }) => {
    const selected = R.without(
      R.map(
        R.compose(
          toString,
          item => item.id
        ),
        group.items
      ),
      selectedItems
    );
    return {
      selectedItems: selected,
      itemOrder: selected.reduce((map, itemId, idx) => {
        map[itemId] = idx;
        return map;
      }, {})
    };
  },
  selectAll: ({ selectedItems }, { payload: groups }) => ({
    selectedItems: R.concat(
      R.flatten(
        R.map(
          group =>
            R.map(
              R.compose(
                toString,
                item => item.id
              ),
              group.items
            ),
          groups
        )
      ),
      selectedItems
    )
  }),
  clearAll: ({ selectedItems }, { payload: groups }) => ({
    selectedItems: R.without(
      R.flatten(
        R.map(
          group =>
            R.map(
              R.compose(
                toString,
                item => item.id
              ),
              group.items
            ),
          groups
        )
      ),
      selectedItems
    )
  }),
  erase: R.always({ selectedItems: [] }),
  handleReorder: (state, { payload: reorderedItems }) => {
    return {
      itemOrder: reorderedItems.reduce((map, item, idx) => {
        map[item.id] = idx;
        return map;
      }, {})
    };
  }
};

const handlers = createHandlers({ iniState, reducers, namespace: NAMESPACE });

const { actions, getters } = handlers;

const module = createModule(handlers);

module.plugModule(searchBar);

export { actions, getters };

export default module;
