import React from "react";
import * as R from "ramda";
import { getSettings, getValue } from "../../utils";

import { HalfSizeDotIcon } from "components/Base";

const StatusWithDot = props => {
  const { statusColors } = getSettings(props);
  const value = getValue(props);
  return (
    <div
      style={{
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "flex",
        alignItems: "center"
      }}
    >
      <HalfSizeDotIcon
        color={R.pathOr("#f6f0ff", [value, "light"], statusColors)}
      />
      <div
        style={{
          color: R.pathOr("#adadad", [value, "dark"], statusColors),
          fontWeight: 600
        }}
      >
        {value}
      </div>
    </div>
  );
};

export default StatusWithDot;
