import * as R from "ramda";
import { connect } from "react-redux";

import { USER_MODAL_ID } from "Passes/Common/constants";
import TypeSelectButton from "ui-kit/TypeSelectButton";

import { getUserFiltersCount } from "../selectors";

import * as MiniItemsSelectors from "ui-kit/MiniItemsSelector/selectors";

import { actions } from "../model";

const decorate = R.compose(
  connect(
    (state, props) => ({
      selectedCount: MiniItemsSelectors.getSelectedCount(state, {
        instanceId: USER_MODAL_ID
      }),
      count: getUserFiltersCount(state, props),
      title: "Submitted By"
    }),
    {
      showModal: () => actions.setShowUserModal(true)
    }
  )
);

export default decorate(TypeSelectButton);
