import React from "react";
import Loading from "ui-kit/Loading";
import CSSModules from "react-css-modules";
import css from "./style.scss";

const ModalLoading = () => (
  <div styleName="modalWrapper">
    <div className="modal-body" styleName="modalBody">
      <div className="modal-body-wrapper">
        <Loading />
      </div>
    </div>
  </div>
);

export default CSSModules(css)(ModalLoading);
