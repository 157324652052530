import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import CanUserDo from "components/Global/Security/CanUserDo";

class EmptyRows extends PureComponent {
  clearSearch = () => this.props.clearSearch(this.props.moduleId);
  render() {
    const { addRecord, moduleId, searchIsActive } = this.props;
    if (searchIsActive) {
      return (
        <div styleName="container">
          <h3>No records match your search.</h3>
          <div styleName="button" onClick={this.clearSearch}>
            Clear search
          </div>
        </div>
      );
    }
    return (
      <div styleName="container">
        <h3>No records to show.</h3>
        <CanUserDo action={`${moduleId}_create`}>
          <div styleName="button" onClick={addRecord}>
            Add Record
          </div>
        </CanUserDo>
      </div>
    );
  }
}

EmptyRows.propTypes = {
  addRecord: PropTypes.func.isRequired,
  moduleId: PropTypes.string.isRequired,
  searchIsActive: PropTypes.bool
};

export default CSSModules(EmptyRows, css);
