import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Panel from "./Panel";
import { withRouter } from "react-router";

import * as ModalActions from "redux/modules/modal/actions";
import { eventDetails } from "redux/modules/event/selectors";
import { getModuleCounts } from "redux/modules/inventory/modules/counts/actions";
import { getRecords } from "redux/modules/modules/records/actions";
import {
  createReview,
  createReviews,
  removeReview,
  removeReviews
} from "redux/modules/approvalWorkflows/reviews/actions";
import { fields } from "redux/modules/modules/records/selectors";
import exportFactory from "components/Global/CRM/TableViews/HelperComponents/export-factory";
import selectedRowFactory from "components/Global/CRM/TableViews/HelperComponents/selected-row-factory";
import cachedStatusFilterRowFactory from "components/Event/InventoryV2/Needs/ByStatus/Panel/HelperComponents/status-filter-cached-row-factory";
import searchFactory from "components/Global/CRM/TableViews/HelperComponents/SearchFactory";
import Table from "components/Event/InventoryV2/ModuleTable";

function mapStateToProps(state, props) {
  return {
    eventDetails: eventDetails(state),
    eventId: eventDetails(state).id,
    fields: fields(state, props.parent.id),
    Table
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createReview,
      createReviews,
      removeReview,
      removeReviews,
      getModuleCounts,
      getRecords,
      ...ModalActions
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    cachedStatusFilterRowFactory(
      selectedRowFactory(exportFactory(searchFactory(Panel)))
    )
  )
);
