import * as R from "ramda";
import React from "react";
import moment from "moment";

import { getValue, getField, getSettings } from "../../utils";
import { DateTimeInput } from "ui-kit/Form/View";

import { noop, setLocalZoneWithoutUTC } from "utils/General";

const getInputProps = (props = {}) => {
  const settings = getSettings(props);
  return R.filter(
    R.compose(
      R.not,
      R.isNil
    ),
    {
      instanceId: props.instanceId,
      autoFocus: true,
      iniValue:
        R.propOr(noop, props.type, {
          datetime: () => {
            const iso = R.path(["value", "iso"], props);
            if (iso) {
              return settings.timezone
                ? setLocalZoneWithoutUTC(iso, settings.timezone)
                : iso;
            }
            return props.value;
          },
          time: () => props.value,
          date: () => moment(props.value, "YYYY-MM-DD").format()
        })() ||
        moment()
          .utc()
          .format(),
      fieldId: props.id,
      timezone: R.prop(props.type, {
        datetime: settings.timezone
      }),
      dateFormat: R.prop(props.type, {
        date: "Y-m-d",
        datetime: "YYYY-MM-DD\\\\THH:mm:ssZ",
        time: "h:i K"
      }),
      altFormat: R.prop(props.type, {
        date: "MMMM Do, YYYY", // settings.dateFormat,
        datetime: "MMM D, YYYY @ h:mm a", // "M d, Y @ h:i K", // settings.format,
        time: "h:mm a"
      }),
      noCalendar: props.type === "time",
      enableTime: props.type === "datetime" || props.type === "time",
      inputClassName: "ui-kit-fieldtypes-datetime-input"
    }
  );
};

const DateTime = props => {
  const value = getValue(props, R.identity, "");
  const field = getField(props);

  const dateProps = getInputProps({
    ...props,
    ...field,
    value
  });

  return <DateTimeInput {...dateProps} />;
};

export default DateTime;
