import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { getPeople, getTotal } from "Accounts/PeopleTypePanel/selectors";

import PersonCard from "./PersonCard";
import Panel from "./Panel";

import PaginationBar from "ui-kit/PaginationBar/View/Slim";
import { Div } from "components/Base";

import module from "../index";
import sagas from "../sagas";

module.setRootSaga(sagas);

const decorate = connect((state, props) => ({
  people: getPeople(state, props),
  total: getTotal(state, props)
}));

const pageOptions = [5, 10, 15, 25, 50];

const PeopleTypePanel = ({
  group,
  people = [],
  handlers,
  instanceId,
  isAdminView = true,
  total = 0,
  showAdd
}) => (
  <Panel
    id={group.id}
    title={group.name}
    color={group.background_color}
    handlers={handlers}
    instanceId={instanceId}
    group={group}
    showAdd={showAdd}
    isAdminView={isAdminView}
  >
    <Div display="column.flex-start.stretch" pb={2}>
      {R.map(
        person => (
          <PersonCard
            key={person.id}
            id={person.id}
            person={person}
            instanceId={instanceId}
            handlers={handlers}
            isAdminView={isAdminView}
            showAdd={showAdd}
          />
        ),
        people
      )}
      <PaginationBar
        instanceId={instanceId}
        pageSizeOptions={pageOptions}
        total={total}
        px={2}
        rowsText={total === 1 ? "person" : "people"}
      />
    </Div>
  </Panel>
);

export default decorate(PeopleTypePanel);
