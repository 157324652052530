import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import { NAMESPACE, FILTER_MODE } from "./constants";

const iniState = {
  categories: [],
  countOfExistingRequests: 0,
  selectedCategory: null,
  filterMode: FILTER_MODE.BY_CATEGORY,
  highlightColor: "",
  fields: {},
  paymentsEnabled: false,
  title: "",
  subtitle: "",
  recordTypeId: null,
  confirmationPageMessage: null,
  selectedPeople: [],
  isSelectPeopleModalOpen: false,
  peopleList: [],
  selectedPeopleLoading: false,
  loading: false,
  requestCreatedId: null
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    executeAction: R.identity,
    handleAddItemModal: R.identity,
    updateSelectedPeople: R.identity,
    updateSelectedPeopleAndItems: R.identity,
    setInitialData: (
      _,
      {
        payload: {
          title,
          subtitle,
          highlight_color: highlightColor,
          categories,
          payments_enabled: paymentsEnabled,
          recordTypeId,
          confirmation_page_message: confirmationPageMessage,
          ...fields
        }
      }
    ) => ({
      title,
      subtitle,
      highlightColor,
      categories,
      paymentsEnabled,
      selectedCategory: R.pathOr(null, [0, "name"], categories),
      fields,
      recordTypeId,
      confirmationPageMessage
    }),
    removeSelectedPerson: (state, { payload: id }) => ({
      selectedPeople: R.filter(
        person => person.contact_id !== id,
        state.selectedPeople
      )
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
