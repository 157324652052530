import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import EmptyState from "components/Event/FormsV2/Overview/SwimLanes/SwimLane/EmptyState";

const Swimlane = ({ Header, children, option }) => (
  <div styleName="container">
    <div styleName="innerContainer">
      {Header}
      <div styleName="submissionsContainer">
        {children.length ? (
          children
        ) : (
          <EmptyState
            caption={
              <span styleName="caption">
                Nothing in{" "}
                {typeof option.value === "string"
                  ? option.value.toLowerCase()
                  : option.value}
              </span>
            }
          />
        )}
      </div>
    </div>
  </div>
);

Swimlane.propTypes = {
  option: PropTypes.shape({
    value: PropTypes.string
  }).isRequired
};

export default CSSModules(Swimlane, css);
