import * as R from "ramda";
import React, { useRef } from "react";
import CSSModules from "react-css-modules";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { getEvents } from "../selectors";
import { actions, getters } from "../model";

import LoadingOverlay from "ui-kit/LoadingOverlay";
import styles from "./styles.scss";

import { useCalendar } from "../utils";

import { CALENDAR_VIEW } from "../constants";

const decorate = R.compose(
  connect(
    (state, props) => ({
      currentDate: getters.currentDate(state, props),
      events: getEvents(state, props)
    }),
    bindInstance({
      init: actions.init,
      eventCreate: actions.eventCreate,
      eventEdit: actions.eventEdit,
      eventResizeStart: actions.eventResizeStart,
      eventResizeRequest: actions.eventResizeRequest,
      eventDragStart: actions.eventDragStart,
      eventDropRequest: actions.eventDropRequest
    })
  ),
  CSSModules(styles)
);

const WeekLayout = ({ currentDate = "", loading, ...props }) => {
  const wrapperRef = useRef();
  const calendarRef = useRef();

  useCalendar({
    ...props,
    selectedRange: CALENDAR_VIEW.WEEK,
    currentDate,
    wrapperRef,
    calendarRef
  });

  const minWidth = 7 * 200 + 73;

  return (
    <div styleName="calendar" ref={wrapperRef}>
      <div ref={calendarRef} style={{ minWidth }} />
      {loading && <LoadingOverlay />}
    </div>
  );
};

export default decorate(WeekLayout);
