import React, { Component } from "react";
import flattenFields from "components/Global/CRM/TableViews/utils/flatten-fields";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { map } from "ramda";
import ReportSettingsModal from "components/Event/Reports/Modals/ReportSettingsModal";
import View from "./View";

const makeDefaultState = () => ({
  sorted: [],
  page: 0,
  pageSize: 10,
  expanded: {},
  resized: [],
  filtered: [],
  loading: true
});

class Controller extends Component {
  constructor() {
    super();
    this.state = makeDefaultState();
  }

  async componentDidMount() {
    await this.fetchReport(this.props);
    this.setState({ loading: false });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.params.reportId !== this.props.params.reportId ||
      prevProps.location.query.filters !== this.props.location.query.filters
    ) {
      // handle: report change
      this.fetchReport(this.props);
    }
  }

  getReportOptions = (props = this.props) => {
    const options = {
      eventId: props.params.eventId
    };

    if (props.location.query.filters) {
      options.filters = props.location.query.filters;
    }

    return options;
  };

  fetchReport = async (props = this.props) => {
    const options = this.getReportOptions(props);

    return await this.props.getReport({
      reportId: props.params.reportId,
      options
    });
  };

  exportNestedReport = () => {
    const options = this.getReportOptions(this.props);

    return this.props.exportNestedReport({
      reportId: this.props.params.reportId,
      options
    });
  };

  printNestedReport = () => {
    const options = this.getReportOptions(this.props);

    return this.props.printNestedReport({
      reportId: this.props.params.reportId,
      options
    });
  };

  openPreview = content => {
    if (content) {
      const preview = window.open();
      jQuery(preview.document.head).append(
        "<style>.hide-in-preview { display: none !important; }</style>"
      );
      jQuery(preview.document.body).html(content);
    }
  };

  exportData = contentType => {
    const { exportSheet, fields, rows } = this.props;

    return exportSheet(
      contentType,
      "records",
      rows,
      map(f => ({
        ...f,
        name: f.export_name || f.name
      }))(flattenFields(fields))
    );
  };

  goToReports = () => {
    const { report } = this.props;
    let pathname = `/event/${this.props.params.eventId}/reports`;
    switch (report.type) {
      case "catering":
        pathname = `/event/${this.props.params.eventId}/catering/manage/all-requests`;
        break;
      case "credentials":
        pathname = `/event/${this.props.params.eventId}/passes/manage/all-requests`;
        break;
    }

    return this.props.router.push({
      pathname
    });
  };

  getReturnToTitle = () => {
    const { report } = this.props;
    let title = "Return to reports";
    switch (report.type) {
      case "catering":
        title = "Return to catering";
        break;
      case "credentials":
        title = "Return to passes";
        break;
    }

    return title;
  };

  showSettingsModal = () => {
    this.props.showModal({
      content: (
        <ReportSettingsModal
          availableFilters={this.props.report.available_filters}
          activeFilters={this.props.report.active_filters}
          onSave={filters => this.updateFilters(filters)}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  updateFilters = filters => {
    return this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/reports/${this.props.params.reportId}`,
      query: {
        filters: JSON.stringify(filters)
      }
    });
  };

  render() {
    const { report, fields, rows } = this.props;
    const {
      loading,
      sorted,
      page,
      pageSize,
      expanded,
      resized,
      filtered
    } = this.state;
    return (
      <View
        {...{
          reportName: report.name,
          returnToTitle: this.getReturnToTitle(),
          fields,
          rows,
          sorted,
          page,
          pageSize,
          // expanded,
          // resized,
          filtered,
          loading,
          noDataText: loading ? "" : "No report results",
          goToReports: this.goToReports,
          isExpandable: report.is_expandable,
          showSettingsModal: report.available_filters
            ? this.showSettingsModal
            : undefined,
          exportCSV: report.is_expandable
            ? undefined
            : () => this.exportData("csv"),
          exportXLSX: () =>
            report.is_expandable
              ? this.exportNestedReport()
              : this.exportData("xlsx"),
          print: report.is_expandable
            ? () => this.printNestedReport()
            : undefined,
          onSortedChange: sorted => this.setState({ sorted }),
          onPageChange: page => this.setState({ page }),
          onPageSizeChange: (pageSize, page) =>
            this.setState({ page, pageSize }),
          // onExpandedChange: expanded => this.setState({ expanded }),
          // onResizedChange: resized => this.setState({ resized }),
          onFilteredChange: filtered => this.setState({ filtered })
        }}
      />
    );
  }
}

export default Controller;
