import * as R from "ramda";

import { createHandlers } from "redux-mvc";

const model = createHandlers({
  iniState: {
    isSettingsModalVisible: false,
    loading: true,
    list: [],
    pageSize: 5,
    page: 0,
    recordTypes: [],
    settingsModalProps: {}
  },
  reducers: {
    fetchGuestLists: R.identity
  },
  namespace: "EventLightGuestLists"
});

const { actions, getters } = model;

export { actions, getters };

export default model;
