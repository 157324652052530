import React from "react";
import { connect } from "react-redux";
import { actions } from "../model";
import { getFilteredColumns } from "../selectors";
import * as R from "ramda";
import { Div, Text2, QuarterSizeDotIcon } from "components/Base";
import Field from "./Field";

const decorate = connect(
  state => ({
    columns: getFilteredColumns(state)
  }),
  {
    toggleVisible: actions.toggleVisible,
    removeAll: actions.removeAll,
    selectAll: actions.selectAll
  }
);

const Fields = ({ columns, toggleVisible, removeAll, selectAll }) => (
  <Div width={457} height={1} style={{ overflow: "auto" }} p={2}>
    <Div display="row.flex-start.center" pb={3} pt={1} px={2}>
      <Text2 color="primary7" underline onClick={() => selectAll()}>
        Select All
      </Text2>
      <QuarterSizeDotIcon color="primary7" />
      <Text2 color="primary7" underline onClick={() => removeAll()}>
        Remove All
      </Text2>
    </Div>
    {R.map(
      field => (
        <Field
          key={field.id}
          {...field}
          toggleVisible={() =>
            toggleVisible({ id: field.id, selected: field.selected })
          }
        />
      ),
      columns
    )}
  </Div>
);

export default decorate(Fields);
