import React from "react";
import * as R from "ramda";
import { getters, actions } from "../model";
import { connect } from "react-redux";
import {
  Div,
  CloseIcon,
  SmallFilledBox,
  SmallTextButton
} from "components/Base";
import Panel from "./Panel";
import { PAGE_ACTIONS } from "../constants";

const decorator = connect(
  (state, props) => ({
    activeAlerts: getters.activeAlerts(state, props),
    alertVisible: getters.alertVisible(state, props)
  }),
  {
    executeAction: actions.executeAction,
    hideAlerts: () => actions.setAlertVisible(false)
  }
);

const getColorByType = ({ type }) => {
  if (type === "breaking") {
    return {
      bg: "#FFF9E5",
      button: "#EE8B16",
      viewAll: "#DA7E12"
    };
  } else if (type === "update") {
    return {
      bg: "#D3E4FB",
      button: "#0A6EF4",
      viewAll: "#0A6EF4"
    };
  } else {
    return {
      bg: "#D3E4FB",
      button: "#0A6EF4",
      viewAll: "#0A6EF4"
    };
  }
};

const ActiveAlerts = ({
  activeAlerts,
  executeAction,
  hideAlerts,
  alertVisible
}) => {
  if (R.isEmpty(activeAlerts) || !alertVisible) {
    return null;
  }

  const alert = activeAlerts[0];
  const { bg, button, viewAll } = getColorByType(alert);

  return (
    <Panel
      styleProps={{ marginBottom: 12, backgroundColor: bg }}
      display="row.space-between.center"
    >
      <Div display="row.flex-start.center">
        <Div>
          <SmallFilledBox bg={button} style={{ marginRight: 14 }}>
            BREAKING
          </SmallFilledBox>
        </Div>
        <Div fs={1} color="black">
          {alert.content}
        </Div>
      </Div>

      <Div display="row.flex-start.center">
        <Div fs={1} color="black">
          {alert.date}
        </Div>
        <SmallTextButton
          bold={true}
          color={viewAll}
          fs={2}
          onClick={() => executeAction({ type: PAGE_ACTIONS.OPEN_ALERT })}
        >
          View all alerts
        </SmallTextButton>
        <CloseIcon color="#333333" size={24} onClick={hideAlerts} />
      </Div>
    </Panel>
  );
};

export default decorator(ActiveAlerts);
