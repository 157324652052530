import React from "react";
import { Div } from "components/Base";
import * as R from "ramda";
import { withRouter } from "react-router";
import css from "./styles.scss";
import CSSModules from "react-css-modules";
import { connect } from "react-redux";

import { getContentSection } from "Portal/PortalPagePGA/PortalPagePGA/selectors";

const decorator = R.compose(
  CSSModules(css),
  withRouter,
  connect((state, props) => ({
    section: getContentSection(state, props)
  }))
);

const Body = ({ section, ...otherProps }) => (
  <Div
    display="column.flex-start.flex-start"
    {...otherProps}
    style={{
      maxWidth: "615px",
      margin: "0px auto",
      marginTop: "53px"
    }}
  >
    <Div
      style={{
        color: "#222222",
        fontFamily: "Roboto",
        fontSize: "24px",
        fontWeight: "bold",
        letterSpacing: 0,
        marginBottom: "32px"
      }}
    >
      {section.label}
    </Div>
    <Div
      style={{
        color: "#222222",
        fontFamily: "Roboto",
        fontSize: "16px",
        letterSpacing: 0,
        lineHeight: "30px",
        whiteSpace: "pre-wrap"
      }}
    >
      <span
        className="lennd-wysiwyg-content"
        dangerouslySetInnerHTML={{
          __html: section.content
        }}
      />
    </Div>
  </Div>
);

export default decorator(Body);
