import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ModalActions from "redux/modules/modal/actions";
import autobind from "autobind-decorator";
import FileCellFormatter from "components/Global/Table3/CellFormatters/File";
import FileViewer from "components/Global/Modals/FileViewer";
import FileViewerWrapper from "components/Global/Modals/FileViewer/Wrapper";

class FileCellWithViewerFormatter extends Component {
  @autobind
  showFileViewer(file, files) {
    this.props.showModal({
      content: (
        <FileViewer
          onFileDelete={this.deleteFile}
          start={files.indexOf(file)}
          files={files}
          isReadOnly
        />
      ),
      wrapper: FileViewerWrapper
    });
  }

  render() {
    return (
      <FileCellFormatter
        {...this.props}
        mode="edit"
        onFileClick={this.showFileViewer}
      />
    );
  }
}

FileCellWithViewerFormatter.propTypes = {
  showModal: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ModalActions, dispatch);
}

export default connect(null, mapDispatchToProps)(FileCellWithViewerFormatter);
