import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Controller from "./Controller";
import * as R from "ramda";

import { showModal, hideModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import * as EventUserActions from "redux/modules/eventUsers/actions";
import { getEvents } from "redux/modules/events/actions";

import * as UserSelectors from "redux/modules/user/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import * as EventUserSelectors from "redux/modules/eventUsers/selectors";

function mapStateToProps(state) {
  return {
    eventDetails: eventDetails(state),
    fetchingUsers: EventUserSelectors.isFetching(state),
    users: R.compose(
      R.sortBy(r => (r && r.user_lname ? r.user_lname.toLowerCase() : null)),
      R.filter(
        R.compose(
          R.equals(true),
          R.prop("is_event_user")
        )
      )
    )(EventUserSelectors.users(state)),
    user: UserSelectors.user(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal,
      hideModal,
      showSnackbar,
      getEvents,
      ...EventUserActions
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
