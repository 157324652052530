import * as R from "ramda";

export const defaultInstanceId = "default";

export const bindInstance = (actions, instanceKey = ["instanceId"]) => {
  let cache = [];
  return (dispatch, props) => {
    let index = R.findIndex(([d]) => dispatch === d, cache);

    const instanceId =
      (typeof instanceKey === "function"
        ? instanceKey(props)
        : R.path(instanceKey, props)) || defaultInstanceId;

    const oldActions = cache[index];
    if (index) {
      if (cache.length === 100) {
        cache = [cache.slice(0, index), cache.slice(index + 1), oldActions];
        index = cache.length - 1;
      }
      const perInstance = R.path([1, instanceId], oldActions);
      if (perInstance) {
        return perInstance;
      }
    }

    let newActions = R.map(
      // eslint-disable-next-line consistent-return
      action => (payload, props = {}, error = false) => {
        const ac = action(
          payload,
          {
            ...props,
            meta: {
              ...(props.meta || {}),
              instanceId: instanceId
            }
          },
          error
        );

        if (typeof ac !== "undefined") {
          return dispatch(ac);
        }
      },
      actions
    );

    const perInstance = (oldActions && oldActions[1]) || {};

    cache[index > -1 ? index : 0] = [
      dispatch,
      { ...perInstance, [instanceId]: newActions }
    ];

    if (cache.length === 100) {
      cache = cache.shift();
    }

    return newActions;
  };
};
