import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Group from "components/Event/Settings/EventSettings/EventDays/MarkDaysAs/Group";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class MarkDaysAs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      adding: false
    };
  }

  @autobind
  toggleAdding() {
    this.setState({
      adding: !this.state.adding
    });
  }

  render() {
    const { groups, selectGroup } = this.props;
    return (
      <div styleName="container">
        <div styleName="markDaysAsDropdown">
          <div styleName="top">
            <div styleName="title">
              {selectGroup ? "Mark day(s) as..." : "Select one or more days..."}
            </div>
          </div>
          <div styleName="groups">
            {groups.map(group => (
              <Group
                key={group.id}
                id={group.id}
                name={group.name}
                color={group.color}
                onClick={
                  selectGroup
                    ? () => {
                        selectGroup(group.id);
                      }
                    : undefined
                }
              />
            ))}
          </div>
          {/*
            {!this.state.adding ?
              <div styleName="bottom" onClick={this.toggleAdding}>
                <div styleName="addNewLink">+ Create New</div>
              </div> :
              <div styleName="bottomAdding" onClick={this.toggleAdding}>
                <div styleName="addNewLink">+ Create New</div>
              </div>
            }
            */}
        </div>
      </div>
    );
  }
}

MarkDaysAs.propTypes = {
  groups: PropTypes.array.isRequired,
  selectGroup: PropTypes.func.isRequired
};

export default MarkDaysAs;
