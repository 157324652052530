import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import {
  getSelectedTasks,
  getEmptyTaskText
} from "Organizations/Home/selectors";
import { Div, BigFilledButton } from "components/Base";
import Task from "./Task";

import { actions } from "Organizations/Home/model";

const decorate = connect(
  state => ({
    tasks: getSelectedTasks(state),
    emptyText: getEmptyTaskText(state)
  }),
  {
    goToMyTasks: actions.goToMyTasks,
    goToSchedule: actions.goToSchedule
  }
);

const TasksBody = ({ tasks, emptyText, goToMyTasks, goToSchedule }) => (
  <Div px={3} pb={2} style={{ position: "relative" }}>
    {R.length(tasks) ? (
      <Div py={2}>
        {R.map(
          task => (
            <Task
              key={task.id}
              task={task}
              goToSchedule={() =>
                goToSchedule({
                  eventId: task.event_id,
                  scheduleId: task.schedule_id
                })
              }
            />
          ),
          tasks
        )}
      </Div>
    ) : (
      <Div p={4} color="gray7" fw={2} width={1}>
        {emptyText}
      </Div>
    )}
    {/*
    {R.length(tasks) ? (
      <BigFilledButton
        color="primary7"
        bg="neutral0"
        width={1}
        onClick={goToMyTasks}
      >
        View all tasks
      </BigFilledButton>
    ) : null}
    */}
  </Div>
);

export default decorate(TasksBody);
