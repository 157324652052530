import React from "react";
import EditorBase from "./TableConnector";
import Editor from "components/Global/Editors/CateringDietaryRestrictionsEditor";
import Portal from "./CellPortal";
import BeforeClose from "./BeforeClose";

export default class DietaryRestrictionsEditor extends EditorBase {
  handleClose = () => this.props.onCommit();

  render() {
    return (
      <Portal
        ref="editor"
        onClose={this.handleClose}
        editor={
          <div style={{ width: this.props.column.width - 2 }}>
            <BeforeClose do={this.handleClose}>
              <Editor
                {...this.props}
                value={this.state}
                onChange={this.handleChange}
                autofocus
              />
            </BeforeClose>
          </div>
        }
      />
    );
  }
}
