import { prop, pathOr, map, any } from "ramda";
import PATTERNS from "utils/value-types/filter/patterns";

const { IS_EMPTY, IS_NOT_EMPTY, CONTAINS, DOES_NOT_CONTAIN } = PATTERNS;

module.exports = ({ pattern, recordValue, filterValue }) => {
  const byValue = map(prop("value"));
  const values = byValue(pathOr([], ["value", "records"], recordValue));
  const filterValues = byValue(pathOr([], ["value"], filterValue));

  switch (pattern) {
    case IS_EMPTY.pattern:
      return !values.length;
    case IS_NOT_EMPTY.pattern:
      return Boolean(values.length);
    case CONTAINS.pattern:
      return any(v => filterValues.includes(v))(values);
    case DOES_NOT_CONTAIN.pattern:
      return !any(v => filterValues.includes(v))(values);
    default:
      return false;
  }
};
