import Helpers from "utils/Global/Helpers";

module.exports = {
  get: (credentials, eventId) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/inventory/event/${eventId}/reports/category-overview`,
        credentials,
        success,
        error
      });
    })
  /* eslint-enable no-underscore-dangle */
};
