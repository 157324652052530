import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { Link as RouterLink, withRouter } from "react-router";
import CSSModules from "react-css-modules";

import { actions, getters } from "../../model";
import {
  eventId as getEventId,
  eventDetails as getEventDetails
} from "redux/modules/event/selectors";
import { user as getUser } from "redux/modules/user/selectors";
import { getLinks, getIsOrgUser } from "../../selectors";

import css from "../styles.scss";

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      collapsed: getters.collapsed(state),
      eventId: getEventId(state),
      eventDetails: getEventDetails(state),
      user: getUser(state),
      links: getLinks(state, props),
      isOrgUser: getIsOrgUser(state, props)
    }),
    {
      toggleOpenLink: actions.toggleOpenLink,
      onToggleCollapsed: actions.toggleCollapse,
      goBack: actions.goBack
    }
  ),
  CSSModules(css)
);

const Link = ({ title, icon, selected = false, to, isSublink, type }) =>
  type === "title" ? (
    <Title title={title} />
  ) : type === "separator" ? (
    <Separator />
  ) : (
    <RouterLink
      className={
        isSublink
          ? selected
            ? css.sidebarSublinkSelected
            : css.sidebarSublink
          : selected
          ? css.sidebarLinkSelected
          : css.sidebarLink
      }
      to={to}
    >
      {icon ? <span className="material-icons-outlined">{icon}</span> : null}
      {title}
    </RouterLink>
  );

const LinkDropdown = ({
  title,
  icon,
  selected = false,
  open,
  options = [],
  onToggle
}) => (
  <>
    <div
      className={
        selected ? css.sidebarLinkDropdownSelected : css.sidebarLinkDropdown
      }
      onClick={onToggle}
    >
      <div className={css.left}>
        <span className="material-icons-outlined">{icon}</span>
        {title}
      </div>
      <span className={["material-icons-outlined", css.arrow].join(" ")}>
        {open ? "keyboard_arrow_down" : "keyboard_arrow_right"}
      </span>
    </div>
    {open ? (
      <div className={css.subLinksWrapper}>
        {options.map(link => {
          return (
            <Link
              key={link.id}
              icon={link.icon}
              title={link.name}
              selected={link.selected}
              to={link.to}
              type={link.type}
              isSublink
            />
          );
        })}
      </div>
    ) : null}
  </>
);

const Separator = () => <div className={css.separator} />;

const Title = ({ title }) => <div className={css.title}>{title}</div>;

const Sidebar = ({
  links,
  eventId,
  eventDetails,
  toggleOpenLink,
  isOrgUser
}) => {
  if (eventDetails.scope !== "live") {
    return null;
  }

  return (
    <div className={css.sidebar}>
      <div className={css.topSection}>
        {isOrgUser ? (
          <RouterLink
            className={css.backWrapper}
            to={`/org-light/${eventDetails.org_id}/dashboard`}
          >
            <span className="material-icons-outlined">keyboard_backspace</span>
            <span className={css.backText}>Back to organization</span>
          </RouterLink>
        ) : null}

        <div className={css.linksWrapper}>
          {links.map(link => {
            if (link.type === "title") {
              return <Title title={link.name} />;
            }

            if (link.type === "separator") {
              return <Separator />;
            }

            if (link.options && link.options.length) {
              return (
                <LinkDropdown
                  icon={link.id}
                  icon={link.icon}
                  title={link.name}
                  selected={link.selected}
                  open={link.open}
                  options={link.options}
                  onToggle={() => toggleOpenLink(link.id)}
                />
              );
            }

            return (
              <Link
                key={link.id}
                icon={link.icon}
                title={link.name}
                selected={link.selected}
                to={link.to}
              />
            );
          })}
        </div>
      </div>

      <RouterLink
        className={css.inviteWrapper}
        to={`/event-light/${eventId}/team`}
      >
        <span className="material-icons-outlined">group_add</span>
        Invite your team
      </RouterLink>
    </div>
  );
};

export default decorate(Sidebar);
