import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import { get } from "lodash";
import { OutlineButton } from "components/Global/CRM/Card/Buttons";
import SendDocumentRequest from "components/Global/Modals/SendDocumentRequest";
import AssignDocumentRequestsModal from "components/Global/CRM/Modals/Assign/AssignDocumentRequests";
import Retrieving from "components/Global/CRM/Modals/Retrieving";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import getValue from "utils/value-types/get-value";
import accountApi from "redux/modules/accounts/profile/api";
import ACCOUNT_FIELDS from "components/Event/Accounts/constants";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

class DocumentRequestActionButton extends Component {
  @autobind
  showAssignDocumentRequestsModal() {
    this.props.meta.showModal({
      content: (
        <AssignDocumentRequestsModal
          moduleId={STANDARD_MODULE_IDS.accounts.id}
          recordNameSingular="Group"
          recordNamePlural="Groups"
          recordIds={[this.props.meta.record.id]}
          onDone={() => {
            this.props.meta.onCreate();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  }

  /*
  @autobind
  async showSendDocumentRequestModal () {
    const { meta } = this.props;
    const {
      record,
      module,
      onCreate,
      showSnackbar,
      showModal,
      hideModal,
      user
    } = meta;
    showModal({
      content: (
        <Retrieving
          message="Please wait..."
          title="Retrieving Groups"
        />
      ),
      wrapper: ModalWrapper
    });

    const accounts = [{
      id: record.id,
      // NOTE: Since the modal is generalized, the caller *must*
      // pass the formatted name as a string rather than passing the entity
      name: getValue(get(record.values, [ACCOUNT_FIELDS.name]), 'text')
    }];

    const fetchedAccounts = await Promise.all(
      accounts.map(
        account => accountApi.get(user.credentials, {
          accountId: account.id,
          eventId: module.event_id
        })
      )
    );

    const recipients = fetchedAccounts.map(({ account }) => account)
      .filter(Boolean)
      .map(a => ({
        name: getValue(get(a.values, [ACCOUNT_FIELDS.name, 'value']), 'text'),
        users: a.users.filter(u => u.user_id && u.email)
      }));

    hideModal();

    showModal({
      content: (
        <SendDocumentRequest
          onDone={(count, action) => {
            onCreate();
            showSnackbar({
              message: `${count} File Request${count === 1 ? '' : 's'} ${action === 'send' ? 'Sent' : 'Assigned'}`,
              action: 'OK'
            });
          }}
          scope="accounts"
          selected={[record.id]}
          recipients={recipients}
        />
      ),
      wrapper: ModalWrapper
    });
  }
  */

  render() {
    return (
      <OutlineButton onClick={this.showAssignDocumentRequestsModal}>
        Assign Request
      </OutlineButton>
    );
  }
}

DocumentRequestActionButton.propTypes = {
  meta: PropTypes.object.isRequired
};

export default DocumentRequestActionButton;
