import React from "react";
import * as R from "ramda";
import {
  Popover,
  TextAreaInput,
  Div,
  AddIcon,
  EditIcon,
  Text1,
  Text2,
  MediumFilledButton,
  MediumOutlineButton
} from "components/Base";
import { addS } from "utils/General";

const charactersLeft = tempDescription => {
  const textLength = R.length(R.propOr("", "text", tempDescription));

  return `${500 - textLength} character${addS(500 - textLength)} left`;
};

const DescriptionPopover = ({
  id,
  description,
  setTempDescription,
  tempDescription,
  updateDescription
}) => (
  <Div display="row.flex-start.center">
    <Popover
      Label={({ onClick }) => (
        <Div
          onClick={ev => {
            setTempDescription({ id, text: description });
            onClick(ev);
          }}
          mr={4}
        >
          {R.isEmpty(description) ? (
            <Div display="row.flex-start.center">
              <AddIcon color="primary7" />
              <Text1 color="primary7">Add help text</Text1>
            </Div>
          ) : (
            <Div display="row.flex-start.center">
              <Div
                width={141}
                bg="neutral0"
                color="neutral6"
                py={1}
                px={2}
                bra={1}
                truncate
                fs={1}
                fw={3}
              >
                {description}
              </Div>
              <EditIcon
                ml={2}
                color={{ default: "neutral5", hover: "primary7" }}
              />
            </Div>
          )}
        </Div>
      )}
    >
      {({ closePopover }) => (
        <Div width={300} bra={1} p={2} shadow={1}>
          <Div display="row.space-between.center" mb={1}>
            <Text2 bold color="black">
              Help Text
            </Text2>
            <Text2 color="neutral6">Field Description</Text2>
          </Div>
          <TextAreaInput
            bg="neutral0"
            color="neutral6"
            value={R.propOr("", "text", tempDescription)}
            onChange={value => {
              setTempDescription({ id, text: value });
            }}
            style={{ resize: "vertical" }}
            width={1}
            maxLength="500"
            continuous
          />
          <Div display="row.space-between.center">
            <Div display="row.flex-start.center">
              <MediumFilledButton
                color="white"
                bg="altB5"
                onClick={() => {
                  closePopover();
                  updateDescription();
                }}
              >
                Save
              </MediumFilledButton>
              <MediumOutlineButton ml={1} onClick={closePopover}>
                Cancel
              </MediumOutlineButton>
            </Div>
            <Text2 bold>{charactersLeft(tempDescription)}</Text2>
          </Div>
        </Div>
      )}
    </Popover>
  </Div>
);

export default DescriptionPopover;
