import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { getters } from "../model";

import { ActionSection } from "PortalHub/ActionComponents/View";

import PeopleType from "./PeopleTypeCard";
import DocumentType from "./DocumentTypeCard";
import FormType from "./FormTypeCard";

const decorate = connect((state, props) => ({
  tasks: getters.tasks(state, props),
  progress: getters.progress(state, props)
}));

const TasksSection = ({ tasks = [], progress = 0 }) => {
  return (
    <ActionSection
      title="Tasks"
      progress={progress}
      progressCaption="of Required Tasks Complete"
    >
      {R.map(
        task =>
          React.createElement(
            R.propOr("div", task.type, {
              people: PeopleType,
              document_request: DocumentType,
              form: FormType
            }),
            { task, key: task.id }
          ),
        tasks
      )}
    </ActionSection>
  );
};

export default decorate(TasksSection);
