export const NAMESPACE = "SettingsWebhooks";

export const TABLE_INSTANCE_ID = "EventLightSettingsWebhooks";

export const ACTIONS_COL_ID = "actions-button-custom";

export const ROW_ACTIONS = {
  EDIT: "edit",
  DELETE: "delete"
};

export const FIELD_IDS = {
  URL: "url",
  CREATE_ORDER: "createOrder",
  UPDATE_ORDER: "updateOrder",
  DELETE_ORDER: "cancelOrder",
  ADD_LINE_ITEM: "addLineItem",
  UPDATE_LINE_ITEM: "updateLineItem",
  DELETE_LINE_ITEM: "deleteLineItem",
  CREATE_EVENT_REGISTRATION: "createEventRegistration",
  CREATE_ISSUANCE: "createIssuance",
  DELETE_ISSUANCE: "deleteIssuance"
};

export const rowCheckBoxData = [
  {
    fieldId: FIELD_IDS.CREATE_ORDER,
    label: "Order created"
  },
  {
    fieldId: FIELD_IDS.UPDATE_ORDER,
    label: "Order updated"
  },
  {
    fieldId: FIELD_IDS.DELETE_ORDER,
    label: "Order cancelled"
  },
  {
    fieldId: FIELD_IDS.CREATE_ISSUANCE,
    label: "Issuance created"
  },
  {
    fieldId: FIELD_IDS.DELETE_ISSUANCE,
    label: "Issuance deleted"
  }
];
