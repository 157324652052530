import { CONTACT_STANDARD_FIELD_IDS } from "components/Event/Contacts/constants";
import ACCOUNT_FIELD_IDS from "components/Event/Accounts/constants";
import AccountNameFormatter from "ui-kit/Datagrid/View/Formatters/AccountNameFormatter";
import ApprovalFormatter from "ui-kit/Datagrid/View/Formatters/Approval";
import CalculatedNumberFormatter from "ui-kit/Datagrid/View/Formatters/CalculatedNumber";
import CatalogItemFormatter from "ui-kit/Datagrid/View/Formatters/CatalogItem";
import CateringCellFormatter from "ui-kit/Datagrid/View/Formatters/Catering";
import CheckboxCellFormatter from "ui-kit/Datagrid/View/Formatters/Checkbox";
import ContactNameFormatter from "ui-kit/Datagrid/View/Formatters/ContactName";
import CountriesCellFormatter from "ui-kit/Datagrid/View/Formatters/Countries";
import CredentialCellFormatter from "ui-kit/Datagrid/View/Formatters/Credential";
import CurrencyFormatter from "ui-kit/Datagrid/View/Formatters/Currency";
import DateCellFormatter from "ui-kit/Datagrid/View/Formatters/Date";
import DateTimeCellFormatter from "ui-kit/Datagrid/View/Formatters/DateTime";
import DietaryRestrictionsCellFormatter from "ui-kit/Datagrid/View/Formatters/CateringDietaryRestrictions";
import EventDaysCellFormatter from "ui-kit/Datagrid/View/Formatters/EventDays";
import FileCellFormatter from "ui-kit/Datagrid/View/Formatters/File";
import FormRowApprovalCellFormatter from "ui-kit/Datagrid/View/Formatters/FormV2RowApproval";
import FormV3RowApprovalCellFormatter from "ui-kit/Datagrid/View/Formatters/FormV3RowApproval";
import FormRowHasChangesCellFormatter from "ui-kit/Datagrid/View/Formatters/FormRowHasChanges";
import ItemBlockCellFormatter from "ui-kit/Datagrid/View/Formatters/ItemBlock";
import LinkedRecordsFormatter from "ui-kit/Datagrid/View/Formatters/LinkedRecords";
import MultipleSelectCellFormatter from "ui-kit/Datagrid/View/Formatters/MultipleSelect";
import OrderSourceFormatter from "ui-kit/Datagrid/View/Formatters/Source";
import OpenRecordFormatter from "ui-kit/Datagrid/View/Formatters/OpenRecord";
import PercentFormatter from "ui-kit/Datagrid/View/Formatters/Percent";
import ReferenceFormatter from "ui-kit/Datagrid/View/Formatters/Reference";
import TextAreaCellFormatter from "ui-kit/Datagrid/View/Formatters/TextArea";
import TextCellFormatter from "ui-kit/Datagrid/View/Formatters/Text";
import TimeCellFormatter from "ui-kit/Datagrid/View/Formatters/Time";
import UserCellFormatter from "ui-kit/Datagrid/View/Formatters/User";
import EventListFormatter from "ui-kit/Datagrid/View/Formatters/EventList";
import AccountListFormatter from "ui-kit/Datagrid/View/Formatters/AccountList";
import ViewSubmissionFormatter from "ui-kit/Datagrid/View/Formatters/ViewSubmission";

export default function resolveFormatter(column) {
  if (column.type === "action-approve-form-v3-row") {
    return FormV3RowApprovalCellFormatter;
  } else if (column.type === "action-approve-form-row") {
    return FormRowApprovalCellFormatter;
  } else if (column.type === "view-submission") {
    return ViewSubmissionFormatter;
  } else if (column.type === "open-record") {
    return OpenRecordFormatter;
  } else if (column.id === ACCOUNT_FIELD_IDS.name) {
    return AccountNameFormatter;
  } else if (
    column.id === CONTACT_STANDARD_FIELD_IDS.fname ||
    column.id === CONTACT_STANDARD_FIELD_IDS.lname
  ) {
    return ContactNameFormatter;
  } else if (column.type === "checkbox" || column.type === "boolean") {
    return CheckboxCellFormatter;
  } else if (column.type === "dropdown" || column.type === "dependency") {
    return MultipleSelectCellFormatter;
  } else if (column.type === "credentials") {
    return CredentialCellFormatter;
  } else if (column.type === "item-block") {
    return ItemBlockCellFormatter;
  } else if (column.type === "catering") {
    return CateringCellFormatter;
  } else if (column.type === "catering-dietary-restrictions") {
    return DietaryRestrictionsCellFormatter;
  } else if (column.type === "event-days") {
    return EventDaysCellFormatter;
  } else if (column.type === "date") {
    return DateCellFormatter;
  } else if (column.type === "datetime") {
    return DateTimeCellFormatter;
  } else if (column.type === "time") {
    return TimeCellFormatter;
  } else if (column.type === "user") {
    return UserCellFormatter;
  } else if (column.type === "file") {
    return FileCellFormatter;
  } else if (column.type === "countries") {
    return CountriesCellFormatter;
  } else if (column.type === "has-changes") {
    return FormRowHasChangesCellFormatter;
  } else if (column.type === "action-approve-form-row") {
    return FormRowApprovalCellFormatter;
  } else if (column.type === "reference") {
    return ReferenceFormatter;
  } else if (column.type === "source") {
    return OrderSourceFormatter;
  } else if (column.type === "percent") {
    return PercentFormatter;
  } else if (column.type === "calculated-number") {
    return CalculatedNumberFormatter;
  } else if (column.type === "currency") {
    return CurrencyFormatter;
  } else if (column.type === "lookup") {
    return LinkedRecordsFormatter;
  } else if (column.type === "catalog-item") {
    return CatalogItemFormatter;
  } else if (column.type === "textarea") {
    return TextAreaCellFormatter;
  } else if (column.type === "approval") {
    return ApprovalFormatter;
  } else if (column.type === "event-list") {
    return EventListFormatter;
  } else if (column.type === "account-list") {
    return AccountListFormatter;
  }

  return TextCellFormatter;
}
