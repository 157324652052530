import React from "react";
import { put, all, takeEvery, fork, select, call } from "redux-saga/effects";
import { push } from "react-router-redux";
import * as R from "ramda";
import { actions, getters } from "./model";
import { registerError } from "redux/modules/errors/actions";
import { DATA } from "./mockData";
import { actions as TableActions } from "ui-kit/Table/model";
import {
  actions as PaginationActions,
  getters as PaginationGetters
} from "ui-kit/PaginationBar";
import {
  actions as SearchbarActions,
  getters as SearchbarGetters
} from "ui-kit/SearchBar";
import { apiCall } from "App/Data/sagas";
import { eventDetails as getEventDetails } from "redux/modules/portal/selectors";
import { portalUser as getPortalUser } from "redux/modules/portal/user/selectors";
import AddPersonModal from "Portal/PortalPeople/AddPersonModal";
import { showModal } from "redux/modules/modal/actions";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { makeFuture } from "utils/General/sagas";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { userId as getUserId } from "redux/modules/user/selectors";
const getParams = function*() {
  const eventDetails = yield select(getEventDetails);
  const portalUser = yield select(getPortalUser);
  const isViewingAsAccount = R.pathEq(["active_view", "type"], "account")(
    portalUser
  );
  const accountId = isViewingAsAccount
    ? R.path(["active_view", "id"], portalUser)
    : null;
  const userId = yield select(getUserId);
  const recordTypeId = yield select(getters.recordTypeId);

  return {
    eventId: eventDetails.id,
    eventSlug: eventDetails.slug,
    eventUUID: eventDetails.uuid,
    accountId,
    userId,
    recordTypeId
  };
};

const init = function*(opts) {
  try {
    if (actions.init.type === opts.type) {
      yield put(actions.setRecordTypeId(opts.payload.recordTypeId));
    }

    yield put(actions.setLoading(true));
    const search = yield select(SearchbarGetters.searchTerm);
    const { eventId, accountId, recordTypeId } = yield call(getParams);

    if (
      [
        SearchbarActions.clearSearch.type,
        SearchbarActions.setSearchTerm.type,
        PaginationActions.setPageSize.type,
        actions.refetchData.type
      ].includes(opts.type)
    ) {
      yield put(
        PaginationActions.setPagination({
          ...DATA.pagination,
          page: 0
        })
      );
    }

    const page = yield select(PaginationGetters.page);

    const { payload: data } = yield call(apiCall, {
      method: "get",
      url: `/portal/event/${eventId}/account/${accountId}/people/${recordTypeId}`,
      qs: {
        page: page + 1,
        pageSize: 10,
        ...(search ? { search } : {})
      }
    });

    yield put(actions.setInitialData({ data: data }));
    const people = R.propOr([], "people", data);

    for (const person of people) {
      const firstId = R.pathOr("", ["sections", 0, "id"], person);
      yield put(
        TableActions.setData(
          {
            columns: R.pathOr({}, ["section_columns", firstId], data),
            rows: R.pathOr([], ["sections", 0, "rows"], person),
            canEditCells: false
          },
          {
            meta: {
              instanceId: person.id
            }
          }
        )
      );
    }
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred geting Portal POS"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const selectSectionTab = function*({ payload: { personId, sectionId, rows } }) {
  const data = yield select(getters.data);

  yield put(actions.updateSelectedTab({ personId, sectionId }));
  yield put(
    TableActions.setData(
      {
        columns: R.pathOr({}, ["section_columns", sectionId], data),
        rows,
        canEditCells: false
      },
      {
        meta: {
          instanceId: personId
        }
      }
    )
  );
};

const handleSelectedPeopleIds = function*() {
  const selectedPeopleIds = yield select(getters.selectedPeopleIds);

  const { eventId, eventSlug, eventUUID, accountId, recordTypeId } = yield call(
    getParams
  );

  yield call(apiCall, {
    method: "put",
    url: accountId
      ? `/orders/event/${eventId}/account/${accountId}/cart`
      : `/orders/event/${eventId}/cart`,
    data: {
      customers: R.map(
        contactId => ({ contactId, accountId }),
        selectedPeopleIds
      )
    }
  });

  yield put(
    push(`/hubs/${eventSlug}/${eventUUID}/people/${recordTypeId}/requests`)
  );
};

const submitRequest = function*({ payload: contactId }) {
  const { eventId, eventSlug, eventUUID, accountId, recordTypeId } = yield call(
    getParams
  );

  yield call(apiCall, {
    method: "put",
    url: accountId
      ? `/orders/event/${eventId}/account/${accountId}/cart`
      : `/orders/event/${eventId}/cart`,
    data: {
      customers: [{ contactId, accountId }]
    }
  });

  yield put(
    push(`/hubs/${eventSlug}/${eventUUID}/people/${recordTypeId}/requests`)
  );
};

const showAddPersonModal = function*() {
  const { accountId, eventId, userId } = yield call(getParams);
  const handleIssuance = makeFuture();

  yield put(
    showModal({
      content: (
        <AddPersonModal
          accountId={accountId}
          onSave={data => {
            handleIssuance.done({ type: "done", data });
          }}
        />
      ),
      wrapper: ModalWrapper
    })
  );

  const result = yield call(handleIssuance.onRealized);

  if (result.type === "done") {
    const { record } = yield call(apiCall, {
      method: "post",
      url: `/modules/${STANDARD_MODULE_IDS.contacts.id}/records`,
      data: result.data,
      qs: {
        eventId,
        userId
      }
    });

    yield call(apiCall, {
      method: "post",
      url: `/account/${accountId}/contact-relationships`,
      data: {
        eventId,
        isPrimary: record.isPrimary,
        role: record.role,
        accountId,
        contactId: record.id
      },
      qs: { userId }
    });

    yield put(actions.refetchData());
  }
};

const watchSetSelectedPeopleIds = function*() {
  yield takeEvery(
    actions.handleSelectedPeopleIds.type,
    handleSelectedPeopleIds
  );
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchSearch = function*() {
  yield takeEvery(
    [
      PaginationActions.setPage.type,
      PaginationActions.setPageSize.type,
      SearchbarActions.clearSearch.type,
      SearchbarActions.setSearchTerm.type,
      actions.refetchData.type
    ],
    init
  );
};

const watchSelectSectionTab = function*() {
  yield takeEvery(actions.selectSectionTab.type, selectSectionTab);
};

const watchShowAddPersonModal = function*() {
  yield takeEvery(actions.showAddPersonModal.type, showAddPersonModal);
};

const watchSubmitRequest = function*() {
  yield takeEvery(actions.submitRequest.type, submitRequest);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchSelectSectionTab),
    fork(watchSearch),
    fork(watchSetSelectedPeopleIds),
    fork(watchShowAddPersonModal),
    fork(watchSubmitRequest)
  ]);
};

export default rootSaga;
