import Helpers from "utils/Global/Helpers";

module.exports = {
  post: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/approval-workflows/spaces/${
          data.spaceId
        }/workflows/${data.workflowId}/triggers`,
        data,
        credentials,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  put: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/approval-workflows/spaces/${
          data.spaceId
        }/workflows/${data.workflowId}/triggers`,
        data,
        credentials,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  delete: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/approval-workflows/spaces/${
          data.spaceId
        }/workflows/${data.workflowId}/triggers`,
        data,
        credentials,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};

// Add Trigger
// POST
// this.props.addTrigger({
// spaceId: SPACE ID,
//     "workflowId": "56a57315-4e09-4a7d-95d5-a76ea8af2494",
//     "type": "orderItem", // TYPE here -- whatever it is on the UI side
//     "order": 1,
//     "isAnd": true
// });

// Update Trigger
// PUT
// this.props.updateTrigger({
// spaceId: SPACE ID,
// workflowId: WORKFLOW ID,
// "trigger": {
//         "id": "c50021a4-8848-4437-9e8b-7487deb69a20",
//         "order": 1,
//         "isAnd": false
//     }
// });

// Bulk Update Triggers
// PUT
// this.props.updateTrigger({
// spaceId: SPACE ID,
// workflowId: WORKFLOW ID,
// bulk: true,
// "triggers": [{
// "id": "c50021a4-8848-4437-9e8b-7487deb69a20",
// "order": 1
// }, {
// "id": "c50021a4-8848-4437-9e8b-7487deb69a20",
// "order": 2
// }]
// });

// Delete Trigger
// DELETE
// this.props.deleteTrigger({
// spaceId: SPACE ID,
// workflowId: WORKFLOW ID,
// "triggerId": "c50021a4-8848-4437-9e8b-7487deb69a20"
// });
