import Helpers from "utils/Global/Helpers";
import getQuery from "redux/modules/modules/utils/get-query";

module.exports = {
  get: (credentials, eventId, options = {}) =>
    new Promise((success, error) => {
      const query = getQuery(options);

      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/modules/event/${eventId}${query || ""}`,
        credentials,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */
};
