import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import { withRouter } from "react-router";

import { Div, Text5, Text2, BigOutlineButton } from "components/Base";

import { CONTAINER_HEIGHT } from "EventLight/Integrations/Integrations/constants";

import { isCredentialsEnabled } from "EventLight/Integrations/Integrations/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

const decorate = R.compose(
  withRouter,
  connect(state => ({
    isCredentialsEnabled: isCredentialsEnabled(state),
    eventDetails: eventDetails(state),
    canViewTicketingIntegrations: selectFeatureFlag(
      flags.CAN_VIEW_TICKETING_INTEGRATIONS.NAME
    )(state)
  }))
);

const Rfid = ({ push, eventId }) => (
  <Div
    bg="white"
    height={CONTAINER_HEIGHT}
    bra={1}
    p={6}
    display="column.space-between"
    shadow={1}
    width={0.32}
  >
    <Div>
      <Text5 color="black" mb={1} bold>
        RFID & Ticketing Integrations
      </Text5>
      <Text2>
        Connect with your ticketing & access control providers for an end-to-end
        solution.
      </Text2>
    </Div>

    <Div>
      <Div display="row.flex-start.center" mb={3} height={50}>
        <Div display="row.center.center">
          <img
            src="https://d2dks4tzxs6xee.cloudfront.net/img/evb.png"
            width={35}
            height={35}
          />
        </Div>
        <Div ml={3} display="row.center.center">
          <img
            src="https://d2dks4tzxs6xee.cloudfront.net/img/fgt.png"
            width={35}
            height={35}
          />
        </Div>
        <Div ml={3} display="row.center.center">
          <img
            src="https://d2dks4tzxs6xee.cloudfront.net/img/elevate.png"
            width={35}
            height={35}
          />
        </Div>
        <Div ml={3} display="row.center.center">
          <img
            src="https://d2dks4tzxs6xee.cloudfront.net/img/itx.png"
            width={35}
            height={35}
          />
        </Div>
      </Div>
      <BigOutlineButton
        onClick={() =>
          push(`/event-light/${eventId}/passes/settings/integrations`)
        }
        width={120}
        color="neutral5"
      >
        View All
      </BigOutlineButton>
    </Div>
  </Div>
);

const Fuzion = ({ push, eventId }) => (
  <Div
    bg="white"
    height={CONTAINER_HEIGHT}
    bra={1}
    p={6}
    display="column.space-between"
    shadow={1}
    width={0.32}
  >
    <Div>
      <Text5 color="black" mb={1} bold>
        Freeman Fuzion
      </Text5>
      <Text2>The integration with Fuzion allows you to</Text2>
      <Text2>connect your data across all points of the</Text2>
      <Text2>event tech ecosystem.</Text2>
    </Div>
    <Div>
      <Div display="row.flex-start.center" height={50} mb={3}>
        <img
          src="https://d2dks4tzxs6xee.cloudfront.net/img/integrations-freeman.png"
          height={40}
        />
      </Div>
      <BigOutlineButton
        onClick={() => push(`/event-light/${eventId}/integrations/fuzion`)}
        color="neutral5"
      >
        Setup
      </BigOutlineButton>
    </Div>
  </Div>
);

const Stripe = ({ push, orgId, ...props }) => (
  <Div
    bg="white"
    height={CONTAINER_HEIGHT}
    bra={1}
    p={6}
    display="column.space-between"
    shadow={1}
    width={0.32}
    {...props}
  >
    <Div>
      <Text5 color="black" mb={1} bold>
        Payment Processors
      </Text5>
      <Text2>
        Connect with your ticketing & access control providers for an end-to-end
        solution.
      </Text2>
    </Div>
    <Div>
      <Div mb={3} display="row.flex-start.center" height={50}>
        <img
          src="https://d2dks4tzxs6xee.cloudfront.net/img/stripe.png"
          height={40}
        />
      </Div>
      <BigOutlineButton
        onClick={() => push(`/org-light/${orgId}/billing`)}
        width={153}
        color="neutral5"
      >
        Setup Payments
      </BigOutlineButton>
    </Div>
  </Div>
);

const Layout = ({
  router,
  params,
  eventDetails,
  isCredentialsEnabled,
  canViewTicketingIntegrations
}) => {
  const showRFID = isCredentialsEnabled && canViewTicketingIntegrations;

  return (
    <Div width={1} height={1}>
      <Div style={{ height: "calc(100% - 86px)" }}>
        <Div
          px={6}
          width={1}
          style={{
            maxWidth: 1170
          }}
          height={1}
        >
          <Div style={{ height: "calc(100% - 86px)" }}>
            <Div
              pb={6}
              px={6}
              width={1}
              style={{
                maxWidth: 1170
              }}
              height={1}
            >
              <Div
                display={
                  showRFID
                    ? "row.space-between.center"
                    : "row.flex-start.center"
                }
              >
                {showRFID && (
                  <Rfid push={router.push} eventId={params.eventId} />
                )}
                <Stripe
                  push={router.push}
                  orgId={eventDetails.org_id}
                  mr={showRFID ? 0 : 4}
                />
                <Fuzion push={router.push} eventId={params.eventId} />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default decorate(Layout);
