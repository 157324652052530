import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  // dashboard: virtual & hybrid
  dateLabel: null,
  registrationUrl: null,
  isPublished: false,
  stats: {},
  ticketStats: {},
  backstageUrl: null,
  streamKey: null,
  rtmpUrl: null,
  selectedStageId: null,
  stages: [],
  registrations: {
    columns: [],
    rows: []
  },
  // dashboard: live
  groupTypes: {
    account_record_types: [],
    contact_record_types: []
  },
  // dashboard: conference
  sponsorTypeId: null,
  exhibitorTypeId: null,
  speakerTypeId: null,
  countOfSpeakers: 0,
  countOfExhibitors: 0,
  countOfSponsors: 0
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    save: R.identity,
    openRegistrationPage: R.identity,
    copyValue: R.identity,
    viewAttendees: R.identity,
    viewEventDetails: R.identity,
    setInitialData: (
      _,
      {
        payload: {
          sponsor_type_id,
          exhibitor_type_id,
          speaker_type_id,
          count_of_speakers,
          count_of_exhibitors,
          count_of_sponsors
        }
      }
    ) => ({
      sponsorTypeId: sponsor_type_id,
      exhibitorTypeId: exhibitor_type_id,
      speakerTypeId: speaker_type_id,
      countOfSpeakers: count_of_speakers,
      countOfExhibitors: count_of_exhibitors,
      countOfSponsors: count_of_sponsors
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
