import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import External from "./External";

import {
  locations,
  locationAreas
} from "@lennd/value-types/src/constants/standard-modules";

import { deselectAllRows } from "redux/modules/modules/records/settings/actions";
import { getModule } from "redux/modules/modules/module/actions";
import { getRecords, addRecord } from "redux/modules/modules/records/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";

import { moduleDetails, fields } from "redux/modules/modules/module/selectors";
import { records } from "redux/modules/modules/records/selectors";

function mapStateToProps(state) {
  return {
    locationModule: moduleDetails(state, locations.id),
    locationFields: fields(state, locations.id),
    locationRecords: records(state, locations.id),
    locationAreaModule: moduleDetails(state, locationAreas.id),
    locationAreaFields: fields(state, locationAreas.id),
    locationAreaRecords: records(state, locationAreas.id)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deselectAllRows: () => deselectAllRows(locationAreas.id),
      getModule,
      getRecords,
      addRecord,
      showModal,
      hideModal
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(External)
);
