import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import MaskedInput from "react-text-mask";
import getValue from "utils/value-types/get-value/number";
import numeral from "numeral";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import getCurrencyMask from "components/Global/Editors/Currency/get-currency-mask";

@CSSModules(css)
class FormCurrency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.formatValue(props.value)
    };
  }

  componentWillUpdate(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        value: this.formatValue(nextProps.value)
      });
    }
  }

  formatValue(value) {
    return getValue(value);
  }

  wrapValue(value) {
    return {
      type: "currency",
      value: typeof value === "number" ? numeral(value).value() : value
    };
  }

  @autobind
  onChange(e) {
    this.setState({
      value: e.target.value
    });
    if (this.props.continuous) {
      this.props.onChange(this.wrapValue(e.target.value));
    }
  }

  @autobind
  onBlur() {
    this.props.onChange(this.wrapValue(this.state.value));
  }

  render() {
    const {
      disabled,
      placeholder,
      required,
      currencyPrefix,
      currencySuffix,
      currencyFormat,
      autocomplete
    } = this.props;

    return (
      <MaskedInput
        autocomplete={autocomplete}
        disabled={disabled}
        required={required}
        value={this.state.value}
        placeholder={placeholder}
        onChange={this.onChange}
        onBlur={this.onBlur}
        placeholderChar={"\u2000"}
        mask={getCurrencyMask(currencyFormat || "", {
          prefix: currencyPrefix,
          suffix: currencySuffix
        })}
        styleName="input"
        type="text"
      />
    );
  }
}

FormCurrency.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  currencyPrefix: PropTypes.string.isRequired,
  currencySuffix: PropTypes.string.isRequired,
  currencyFormat: PropTypes.string.isRequired
};

export default FormCurrency;
