import { createContext } from "redux-mvc";
import module from "../index";
import Layout from "./Layout";

const decorate = createContext({
  module,
  options: {
    persist: false
  }
});

export default decorate(Layout);
