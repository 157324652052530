import React, { useMemo } from "react";
import moment from "moment";
import * as R from "ramda";
import { Div } from "components/Base";
import CSSModules from "react-css-modules";
import Day from "./Day";
import { noop } from "utils/General";

import css from "./styles.scss";

const getEventsByDay = (day, date, events) =>
  R.filter(({ date_from, date_to }) => {
    const range = moment().range(
      moment(date_from).startOf("day"),
      moment(date_to).endOf("day")
    );
    const today = moment(date).set("date", day);
    return range.contains(today);
  }, events);

const isCurrentDay = (day, date) => {
  const today = moment();
  const currentDate = date.clone().set("date", day);
  return currentDate.isSame(today, "day");
};

const mapIndexed = R.addIndex(R.map);

export const Calendar = ({
  month = moment().month(),
  year = moment().year(),
  events = [],
  showCurrentDay = true,
  DayComponent = Day,
  customMonthRender,
  customWeekdayNames = ["s", "m", "t", "w", "t", "f", "s"],
  className,
  style: userStyles = {},
  onClick = noop,
  onEventClick = noop
}) => {
  const currentMonth = moment()
    .set("month", month)
    .set("year", year);

  const getWeekdays = () =>
    customWeekdayNames ? customWeekdayNames : moment.weekdaysShort();

  const getFirstDayOfMonth = () =>
    currentMonth
      .clone()
      .startOf("month")
      .format("d");

  const getNumberOfdaysInMonth = () => currentMonth.daysInMonth();

  const getBlankSpaces = () =>
    R.compose(
      mapIndexed((_, index) => (
        <td
          key={`empty-${index}`}
          className="calendar_day calendar_day-empty"
        ></td>
      ))
    )(R.range(0, parseInt(getFirstDayOfMonth())));

  const getDaysInMonth = () =>
    R.compose(
      R.map(day => (
        <td key={day} className="calendar_day">
          <Div className="calendar_day_container">
            <DayComponent
              day={day}
              showCurrentDay={showCurrentDay}
              currentDay={isCurrentDay(day, currentMonth)}
              events={getEventsByDay(day, currentMonth, events)}
              onClick={onClick}
              onEventClick={onEventClick}
              currentMonth={currentMonth}
            />
          </Div>
        </td>
      ))
    )(R.range(1, getNumberOfdaysInMonth() + 1));

  const getDaysList = () => R.concat(getBlankSpaces(), getDaysInMonth());

  const getMonthName = () =>
    customMonthRender
      ? customMonthRender(currentMonth)
      : currentMonth.format("MMMM");

  const getTableHeader = () =>
    mapIndexed(
      (day, index) => (
        <th key={index} className="calendar_weekday">
          <Div className="calendar_weekday_container">{day}</Div>
        </th>
      ),
      getWeekdays()
    );

  const getTableBody = () =>
    R.compose(mapIndexed((tableRow, index) => <tr key={index}>{tableRow}</tr>))(
      R.splitEvery(7, getDaysList())
    );

  const tableBody = useMemo(() => getTableBody(), [month, year, events]);
  const tableHeader = useMemo(() => getTableHeader(), [month]);

  const classNames = ["calendar_container", className].join(" ").trim();

  return (
    <Div className={classNames} style={userStyles}>
      <table className="calendar_table">
        <thead className="calendar_header">
          <tr className="calendar_month">
            <th colSpan={7}>
              <Div className="calendar_month_container">{getMonthName()}</Div>
            </th>
          </tr>
          <tr className="calendar_weekdays">{tableHeader}</tr>
        </thead>
        <tbody className="calendar_body">{tableBody}</tbody>
      </table>
    </Div>
  );
};

export default CSSModules(Calendar, css);
