import Helpers from "utils/Global/Helpers";

/* eslint no-underscore-dangle: "off" */

export default {
  getLink: ({ credentials, linkId }) =>
    new Promise((success, error) => {
      Helpers.request({
        skipUserId: true,
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/health-pass/link/${linkId}`,
        credentials,
        success,
        error
      });
    }),
  saveSubmission: ({ credentials, linkId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        skipUserId: true,
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/health-pass/link/${linkId}/submission`,
        credentials,
        data,
        success,
        error
      });
    })
};
