/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  updateCompletionStatus: ({ credentials, sessionId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/sessions/session/${sessionId}/checkin`,
        data,
        credentials,
        success,
        error
      });
    })
};
