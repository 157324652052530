import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const EnvironmentTag = () => {
  if (window.__ENV__ !== "production") {
    return <div styleName="tag">{window.__ENV__}</div>;
  }
  return <span />;
};

export default CSSModules(EnvironmentTag, css);
