import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import css from "ui-kit/ViewPicker/View/styles.scss";

import {
  Div,
  Text1,
  Text2,
  AddIcon,
  FontIcon,
  CloseIcon
} from "components/Base";
import { noop } from "utils/General";
import SortPicker from "components/Global/Module/Sidebar/Popovers/Sort";

import { Panel } from "ui-kit/FilterControls/View";

import { getFieldFilters } from "Organizations/Events/selectors";

import { actions } from "Organizations/Events";

import { getSortBy } from "Organizations/Events/selectors";

const decorate = R.compose(
  connect(
    state => ({
      fields: getFieldFilters(state),
      sortBy: getSortBy(state)
    }),
    {
      setFilters: actions.setSelectedSortBy,
      removeFilter: actions.removeSelectedSortBy
    }
  )
);

const Sorter = ({ id = "", name = "", direction = "", onRemove = noop }) => (
  <Div
    display="row.flex-start.flex-start"
    width={1}
    fs={1}
    mb={1}
    bra={1}
    p={2}
    style={{
      backgroundColor: "#9A68D2"
    }}
  >
    <Text1 color="white" flex={1} bold>
      {name}
    </Text1>
    <Text1 color="white" bold>
      {direction}
    </Text1>
    <CloseIcon
      size={15}
      ml={1}
      style={{
        color: "#B997E0",
        flexShrink: 0
      }}
      onClick={e => {
        e.stopPropagation();
        onRemove(id);
      }}
    />
  </Div>
);

const FieldSelect = ({
  fields,
  setFilters,
  sortBy,
  removeFilter,
  ...styleProps
}) => {
  return (
    <Panel
      label={
        <Div display="row.flex-start.center" mb={1}>
          <FontIcon fs={4} mr={2} color="purple4">
            sort_by_alpha
          </FontIcon>
          <Text2 color="white" bold>
            Sort By
          </Text2>
        </Div>
      }
      collapsedDefault
      filtersOn={sortBy && sortBy.length}
      bodyProps={{ ml: 3, mr: 3 }}
      className={css.button2}
      {...styleProps}
    >
      {sortBy && sortBy.length ? (
        <Sorter {...R.head(sortBy)} m={1} onRemove={removeFilter} />
      ) : null}
      {R.map(
        sort => (
          <Div display="row.flex-start.center" ml={1}>
            <Text2 color="white" mr={2} ml={1}>
              then
            </Text2>
            <Sorter
              {...sort}
              onRemove={removeFilter}
              key={sort.fieldId}
              flex={1}
            />
          </Div>
        ),
        R.tail(sortBy)
      )}

      <Div display="column.flex-start.flex-start">
        <SortPicker sortBy={sortBy} onChange={setFilters} fields={fields}>
          <Div
            display="row"
            onClick={noop}
            mt={sortBy && sortBy.length ? 2 : 0}
          >
            <Div fs={2} fw={3} color="white">
              Add Sort By
            </Div>
            <AddIcon color="purple4" ml={1} />
          </Div>
        </SortPicker>
      </Div>
    </Panel>
  );
};

export default CSSModules(decorate(FieldSelect), css);
