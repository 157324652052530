import React, { Component } from "react";
import View from "./View";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

class Controller extends Component {
  goToUsers = () =>
    this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/settings/users`
    });

  goToPermissions = () =>
    this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/settings/permissions`
    });

  render() {
    const { route, cando } = this.props;
    const tabs = [
      ["Users", this.goToUsers, route.name === "editEventSettingsUsers"],
      cando(`${STANDARD_MODULES.settings.id}_manage_permissions`) && [
        "Permissions",
        this.goToPermissions,
        route.name === "editEventSettingsPermissions"
      ]
    ].filter(Boolean);

    return (
      <View
        {...{
          tabs,
          activeTabValue: route.name
        }}
      />
    );
  }
}

export default Controller;
