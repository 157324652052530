import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const DEFAULT_PAGINATION = {
  pageSize: 10,
  total: 0,
  page: 0
};

const iniState = {
  loading: true,
  refreshing: false,
  variantsTracked: [],
  total: 0,
  pagination: DEFAULT_PAGINATION,
  showAddInventoryModal: false
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    showAddInventoryModal: R.identity,
    refreshAfterSaving: R.identity
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
