import React from "react";

import { Div } from "components/Base";

const ProgressBar = ({ progress, "data-cy": dataCy, ...styleProps }) => (
  <Div flex={1} {...styleProps} data-cy={dataCy}>
    <Div style={{ position: "relative" }} width={1}>
      <Div width={1} bg="neutral1" bra={2} color="transparent" fs={1}>
        a
      </Div>
      <Div width={1} p={1} style={{ position: "absolute", left: 0, top: 0 }}>
        {progress > 0 ? (
          <Div
            style={{
              width: `${progress * 100}%`,
              maxWidth: "100%"
            }}
            bg="success5"
            height={7}
            brl={2}
            brr={Math.min(progress, 1) === 1 ? 2 : undefined}
            color="transparent"
            fs={1}
          >
            a
          </Div>
        ) : null}
      </Div>
    </Div>
  </Div>
);

export default ProgressBar;
