import React from "react";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import ModuleActions from "components/Event/Module/Actions/Module";
import DocumentRequestsActions from "components/Event/Module/Actions/DocumentRequests";
import FilesActions from "components/Event/Module/Actions/Files";
import FormActions from "components/Event/Module/Actions/Form";

export default function resolveActions(props) {
  switch (props.moduleId) {
    case STANDARD_MODULE_IDS.documentRequests.id:
      return <DocumentRequestsActions {...props} />;
    case STANDARD_MODULE_IDS.files.id:
      return <FilesActions {...props} />;
    case STANDARD_MODULE_IDS.formsv3.id:
      return <FormActions {...props} />;
    default:
      return <ModuleActions {...props} />;
  }
}
