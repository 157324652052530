import PropTypes from "prop-types";
import React, { Component, cloneElement } from "react";
import ReactDOM from "react-dom";
import autobind from "autobind-decorator";
import { debounce } from "lodash";

class HeightSizer extends Component {
  constructor(props) {
    super(props);
    this.handleResizeDebounced = debounce(this.handleResize, 500);

    this.state = {
      height: 500
    };
  }

  @autobind
  handleResize() {
    const node = ReactDOM.findDOMNode(this);
    if (node) {
      this.setState({
        height: window.innerHeight - node.getBoundingClientRect().top
      });
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResizeDebounced);

    this.handleResize();
  }

  componentWillUnmount() {
    this.handleResizeDebounced.cancel();
    window.removeEventListener("resize", this.handleResizeDebounced);
  }

  render() {
    const { children, computeHeight } = this.props;
    return cloneElement(children, {
      height: computeHeight(this.state.height)
    });
  }
}

HeightSizer.defaultProps = {
  computeHeight: height => height
};

HeightSizer.propTypes = {
  computeHeight: PropTypes.func,
  children: PropTypes.node
};

export default HeightSizer;
