import {
  put,
  call,
  takeEvery,
  all,
  fork,
  select,
  take
} from "redux-saga/effects";
import * as R from "ramda";
import update from "immutability-helper";

import { debounce } from "utils/General/sagas";

import { actions, getters } from "EventLight/FileRequests/Results";
import {
  actions as TableActions,
  getters as TableGetters
} from "ui-kit/Table/model";
import * as snackbarActions from "redux/modules/snackbar/actions";

import { actions as ViewPickerActions } from "ui-kit/ViewPicker";

import { actions as SearchBarActions } from "ui-kit/SearchBar";

import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { TABLE_INSTANCE_ID } from "EventLight/FileRequests/Results/constants";

import { COMMIT } from "redux-optimist";

import addValueApi from "redux/modules/modules/values/api";

const resizeColumns = function*({ meta }) {
  const widths = yield select(TableGetters.columnWidths, {
    instanceId: meta.instanceId
  });
  const preferences = yield select(getters.preferences);
  const updatedFieldWidths = {
    ...preferences.field_widths,
    ...widths
  };

  yield put(actions.updateFieldWidths(updatedFieldWidths));
};

const watchResizeColumns = debounce(
  action =>
    action.type === TableActions.saveColumnWidth.type &&
    R.path(["meta", "instanceId"], action) === TABLE_INSTANCE_ID,
  resizeColumns,
  500
);

const untoggleRows = function*() {
  yield put(
    TableActions.clearSelectedRows(null, {
      meta: {
        instanceId: TABLE_INSTANCE_ID
      }
    })
  );
};

const watchUntoggleRows = function*() {
  yield takeEvery(
    [
      actions.setSelectedFieldFilters.type,
      actions.clearFilters.type,
      actions.removeSelectedFieldFilter.type,
      actions.setSelectedTab.type,
      ViewPickerActions.revertViewChanges.type,
      ViewPickerActions.deleteView.type,
      ViewPickerActions.selectView.type,
      ViewPickerActions.addView.type,
      SearchBarActions.clearSearch.type
    ],
    untoggleRows
  );
};

const hideColumn = function*({ payload: field }) {
  const state = yield select(R.identity);
  const preferences = getters.preferences(state);
  const columns = getters.columns(state);

  yield all([
    put(
      TableActions.setColumns(columns.filter(c => c.id !== field.id), {
        meta: {
          instanceId: TABLE_INSTANCE_ID
        }
      })
    ),
    put(actions.setColumns(columns.filter(c => c.id !== field.id))),
    put(
      actions.setPreferences({
        ...preferences,
        visible_fields: preferences.visible_fields.filter(
          fId => fId !== field.id
        )
      })
    )
  ]);
};

const groupBy = function*({ payload: field }) {
  yield put(actions.setGroupedByField(field.id));
};

const insertField = function*({
  payload: { originalField, newField, position }
}) {
  const state = yield select(R.identity);
  const columns = getters.columns(state);
  const index = R.findIndex(R.propEq("id", originalField.id))(columns);

  const updatedFields = update(columns, {
    $splice: [
      [Math.max(0, position === "left" ? index - 1 : index + 1), 0, newField]
    ]
  });

  yield put(
    actions.updateVisibleFields({
      visibleFields: updatedFields.map(f => f.id),
      fieldOrder: updatedFields.reduce((map, f, idx) => {
        map[f.id] = idx;
        return map;
      }, {})
    })
  );

  yield all([
    put(
      snackbarActions.showSnackbar({
        message: "Field Added",
        action: "OK"
      })
    ),
    put(actions.fetchData())
  ]);
};

const watchSaveCell = function*() {
  for (;;) {
    const { meta, optimist, payload } = yield take(TableActions.save.type);
    if (meta.instanceId === TABLE_INSTANCE_ID) {
      const credentials = yield select(getCredentials);
      const eventId = yield select(getEventId);
      const moduleId = yield select(getters.moduleId);
      let fieldId = payload.column.id;
      let recordId = payload.row.submission_record_id;

      if (payload.column.settings.isReferenceField) {
        fieldId = payload.column.settings.referenceFieldId;
        recordId = R.path([
          payload.column.settings.lookupFieldId,
          "value",
          "records",
          0
        ])(payload.row);
      }

      yield call(addValueApi.post, credentials, {
        eventId,
        fieldId,
        recordId,
        moduleId,
        value: {
          type: payload.column.type,
          ...R.propOr({}, payload.column.id, payload.row),
          value: payload.value
        }
      });

      yield put(
        TableActions.saveResponse(null, {
          optimist: { ...optimist, type: COMMIT },
          meta
        })
      );
    }
  }
};

const watchHideColumn = function*() {
  yield takeEvery(actions.hideColumn.type, hideColumn);
};

const watchGroupBy = function*() {
  yield takeEvery(actions.groupBy.type, groupBy);
};

const watchInsertField = function*() {
  yield takeEvery(actions.insertField.type, insertField);
};

const rootSaga = function*() {
  yield all([
    fork(watchUntoggleRows),
    fork(watchResizeColumns),
    fork(watchHideColumn),
    fork(watchGroupBy),
    fork(watchInsertField),
    fork(watchSaveCell)
  ]);
};

export default rootSaga;
