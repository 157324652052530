import React from "react";
import { withRouter } from "react-router";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters } from "SalesPortal/Home";
import CommonConfirmation from "Common/Confirmation";

const decorate = R.compose(
  withRouter,
  connect(state => ({
    salesData: getters.salesData(state)
  }))
);

const Confirmation = ({
  salesData: { name: eventName, event_slug, event_uuid, event_confirmation }
}) => (
  <CommonConfirmation
    redirectButtonLabel="Return to event"
    description={`Your request has been received for ${eventName}.`}
    confirmationMessage={event_confirmation}
    redirectLink={`/sales-portal/${event_slug}/${event_uuid}`}
  />
);

export default decorate(Confirmation);
