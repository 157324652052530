import React from "react";
import { connect } from "react-redux";
import { actions } from "Portal/Settings/AddPageModal/model";
import { getIsNextBtnDisabled } from "Portal/Settings/AddPageModal/selectors";
import { Div, BigFilledButton } from "components/Base";

const decorate = connect(
  state => ({
    isNextBtnDisabled: getIsNextBtnDisabled(state)
  }),
  {
    nextPage: actions.nextPage
  }
);

const Footer = ({ nextPage, isNextBtnDisabled }) => (
  <Div
    height={80}
    bg="white"
    bt={1}
    bc="neutral2"
    display="row.space-between.center"
    style={{ padding: "0px 34px", flexShrink: 0 }}
  >
    <Div display="row.flex-start.center">
      <BigFilledButton
        width={322}
        bg="altB5"
        color="white"
        onClick={nextPage}
        disabled={isNextBtnDisabled}
      >
        Save Page
      </BigFilledButton>
    </Div>
  </Div>
);

export default decorate(Footer);
