import React from "react";
import { Div, BigOutlineButton, FontIcon } from "components/Base";
import {
  SectionOverviewTemplate,
  SectionOverviewContainer
} from "Passes/SetupCommon";

const RequestFormsOverview = ({ resources, loading, showTemplatesModal }) => (
  <SectionOverviewContainer breadcrumbs={["collect & manage", "request forms"]}>
    <SectionOverviewTemplate
      loading={loading}
      resources={resources}
      title="Request Forms"
      whatSubText="Allocate passes, collect names, save your box office team hours of work"
      whatSectionContent={
        <Div
          bg="neutral1"
          bra={1}
          width={640}
          height={300}
          display="row.center.center"
        >
          <FontIcon fs={7} color="neutral3">
            insert_photo
          </FontIcon>
        </Div>
      }
      doSectionContent={
        <Div display="row">
          <Div
            bg="neutral1"
            bra={1}
            width={300}
            height={200}
            mr={4}
            display="row.center.center"
          >
            <FontIcon fs={7} color="neutral3">
              play_arrow
            </FontIcon>
          </Div>
          <Div display="column">
            <Div fw={3} fs={4} mb={1} mt={2} color="gray7">
              Build forms to collect requests
            </Div>
            <Div mb={3}>
              Quickly set up forms with specific pass types to collect requests
            </Div>
            <Div display="row">
              <BigOutlineButton onClick={() => {}}>
                Watch tutorial
              </BigOutlineButton>
            </Div>
          </Div>
        </Div>
      }
      steps={[
        {
          title: "Add a form",
          onClick: showTemplatesModal,
          subtitle:
            "Import your groups, add one by one, or invite groups to register & request their allocations"
        },
        {
          title: "Share form",
          subtitle: "Choose the available passes & amounts for each group"
        },
        {
          title: "Manage responses",
          subtitle:
            "Copy & paste your guest list links, send from Lennd, and stay on top of th"
        }
      ]}
    />
  </SectionOverviewContainer>
);

export default RequestFormsOverview;
