import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
export default class NotFound extends Component {
  render() {
    return (
      <div styleName="container">
        <div styleName="image">
          <img
            src="http://d2dks4tzxs6xee.cloudfront.net/img/empty-state-form.jpg"
            width="400"
            height="404"
            alt="Hmmm, we can't find that form."
          />
        </div>
        <div styleName="title">
          <strong>Hmmm, we can't find that form.</strong>
        </div>
        <div styleName="big">
          It's likely that it was removed by the event administrator.
        </div>
        <div styleName="big">
          The best thing to do is email your event contact and ask them to
          resend an updated form.
        </div>
      </div>
    );
  }
}
