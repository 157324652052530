export const VIEW_MY_RECORDS_ID = "87a75afb-f6a9-4466-a5d9-7ff4286e042d";
export const VIEW_PENDING_RECORDS_ID = "aba92e48-6535-4449-95fe-3902d848ef89";
export const VIEW_APPROVED_RECORDS_ID = "da740a95-a9bb-4a91-9a27-a459ae315f02";
export const VIEW_DENIED_RECORDS_ID = "846bd88d-7cd7-485f-b3f9-c3e29d8a325f";

export const REPORT_ACCOUNT_ASSETS_ID = "8a55f2b6-483b-4eaa-bddd-fc0c0b5ee19a";
export const REPORT_ACCOUNT_DOCUMENTS_ID =
  "b42c6991-9ef9-4125-a55a-56213e69bc56";
export const REPORT_ACCOUNT_FORMS_ID = "8647d858-d48b-44d6-9acc-0ebd75753ed3";
export const REPORT_ACCOUNT_PEOPLE_ID = "2437d858-d48b-44d6-9acc-0ebd75753ed5";
export const REPORT_ACCOUNT_CREDENTIALS_ID =
  "9a22f2b6-483b-4eaa-bddd-fc0c0b5ee19b";
export const REPORT_ACCOUNT_CATERING_ID =
  "4a24f2b6-383b-4eaa-aedd-fc0c0b5ee19a";
