import React from "react";
import {
  Div,
  Text2,
  MediumFilledButton,
  RightArrowIcon
} from "components/Base";
import { connect } from "react-redux";
import { getters, actions } from "Portal/IntakeForm/model";
import { getUserName } from "Portal/IntakeForm/selectors";

const decorate = connect(
  state => ({
    isLoggedIn: getters.isLoggedIn(state),
    userName: getUserName(state)
  }),
  {
    goToLoginPage: actions.goToLoginPage,
    handleLogout: actions.handleLogout
  }
);

const WelcomeLoginSection = ({
  isLoggedIn,
  goToLoginPage,
  handlers,
  handleLogout,
  userName
}) =>
  isLoggedIn ? (
    <Div display="column">
      <Div fw={3} color="black">
        You are logged in as {userName}.
      </Div>
      <Div fs={1}>
        Not you?{" "}
        <span
          onClick={() => {
            handlers.setShowAlert(false);
            handleLogout();
          }}
          style={{
            textDecoration: "underline",
            cursor: "pointer"
          }}
        >
          Log out
        </span>
        .
      </Div>
    </Div>
  ) : (
    <Div display="row.space-between.center">
      <Div mr={3}>
        <Text2 color="black" bold mb={1}>
          Already registered?
        </Text2>
        <Text2
          style={{
            maxWidth: "390px"
          }}
        >
          If you have previously registered, please try logging in to confirm or
          change your information.
        </Text2>
      </Div>
      <MediumFilledButton
        style={{ borderRadius: "20px" }}
        color="neutral7"
        RightIcon={RightArrowIcon}
        bg="neutral2"
        mt={4}
        onClick={() => {
          handlers.setShowAlert(false);
          goToLoginPage();
        }}
      >
        Log in Now
      </MediumFilledButton>
    </Div>
  );

export default decorate(WelcomeLoginSection);
