import React, { Component } from "react";
import * as R from "ramda";

import { Div } from "components/Base";

import ItemBlockList from "./List";
import ItemBlockQuantity from "./QuantityInput";
import ErrorMark from "../ErrorMark";

import { getSettings, getCellValue } from "ui-kit/Datagrid/utils";
import { EMPTY_ERRORS } from "ui-kit/Validator/constants";

const errorStyle = {
  display: "flex",
  flexDirection: "row-reverse",
  alignItems: "center",
  justifyContent: "space-between"
};

class ItemBlock extends Component {
  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props.value, nextProps.value);
  }

  render() {
    const { valid, description } = R.propOr(
      EMPTY_ERRORS,
      "error",
      this.props.value
    );

    const selectionType = getSettings(this.props, "selection_type");
    const items = getCellValue(this.props, "value", []);

    const body =
      selectionType === "quantity-only-input" ? (
        <ItemBlockQuantity quantity={R.path(["0", "quantity"], items)} />
      ) : (
        <ItemBlockList items={items} />
      );

    if (!valid) {
      return (
        <Div style={errorStyle}>
          <ErrorMark valid={valid} description={description} />
          {body}
        </Div>
      );
    }
    return body;
  }
}

export default ItemBlock;
