import { REQUEST, RECEIVE } from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getMeals({ eventId }) {
  return async dispatch => {
    try {
      dispatch({
        type: REQUEST
      });
      const result = await api.get(eventId);
      dispatch({
        type: RECEIVE,
        payload: result.payload
      });
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching meal list"
          }
        ])
      );
    }
  };
}
