import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const SidebarLink = CSSModules(
  ({ active, children, onClick }) => (
    <div
      styleName={active ? "sidebarLinkActive" : "sidebarLink"}
      onClick={onClick}
      role="link"
      tabIndex="0"
    >
      {children}
    </div>
  ),
  css
);

export default SidebarLink;
