import { createSelector } from "reselect";
import * as R from "ramda";
import { getters } from "./model";
import { MAX_LIMIT_OF_CHARACTERS } from "./constants";
import { makeInstanceSelector } from "redux-mvc";

const getKey = (_, props) => R.prop("instanceId", props);

export const getDescription = makeInstanceSelector(
  (_, props) => R.propOr("", "description")(props),
  getters.viewMore,
  (description, viewMore) =>
    R.length(description) > MAX_LIMIT_OF_CHARACTERS && !viewMore
      ? description.slice(0, MAX_LIMIT_OF_CHARACTERS) + "..."
      : description
)(getKey);

export const shouldShowReadMoreButton = createSelector(
  (_, props) => R.propOr("", "description")(props),
  description => R.length(description) > MAX_LIMIT_OF_CHARACTERS
);

export const textForViewMore = makeInstanceSelector(
  shouldShowReadMoreButton,
  getters.viewMore,
  (showButton, viewMore) => {
    if (showButton) {
      return viewMore ? "View less" : "View more";
    }
    return "";
  }
)(getKey);
