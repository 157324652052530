import React, { useEffect, useRef } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters, actions } from "Portal/PortalPreviewProfile/model";
import { getContent } from "Portal/PortalPreviewProfile/selectors";
import { Div } from "components/Base";

const decorate = connect(
  state => ({
    mode: getters.mode(state),
    content: getContent(state)
  }),
  {
    setMode: actions.setMode,
    goToForms: actions.goToForms,
    approveProfile: actions.approveProfile
  }
);

function DangerouslySetHtmlContent(props) {
  const { html, ...rest } = props;
  const divRef = useRef(null);

  useEffect(() => {
    if (!html) return;

    const slotHtml = document.createRange().createContextualFragment(html); // Create a 'tiny' document and parse the html string
    divRef.current.innerHTML = ""; // Clear the container
    divRef.current.appendChild(slotHtml); // Append the new content
  }, [html]);

  return <div {...rest} ref={divRef}></div>;
}

const Profile = ({ setMode, mode, content }) => (
  <Div display="column.flex-start.flex-start">
    {/* todo: toggle between exhibitor listing and profile - wait for freemans approval here */}
    {/* todo: add bar: approve profile, edit profile, change theme */}
    <DangerouslySetHtmlContent
      style={{
        display: "block",
        width: "100%"
      }}
      html={content}
    />
  </Div>
);

export default decorate(Profile);
