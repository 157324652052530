import React from "react";
import { Div } from "components/Base";
import Header from "./Header";
import Body from "./Body";
import AddBulkItemsModal from "./AddBulkItemsModal";

const Layout = ({ children, handlers }) => (
  <Div>
    <AddBulkItemsModal />
    <Header />
    <Body children={children} handlers={handlers} />
  </Div>
);

export default Layout;
