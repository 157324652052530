import React from "react";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import ModuleActionButton from "components/Global/CRM/RelatedModule/ActionButtons/Module";
import DocumentRequestActionButton from "components/Global/CRM/RelatedModule/ActionButtons/DocumentRequests";
import FileActionButton from "components/Global/CRM/RelatedModule/ActionButtons/Files";

export default function resolveActionButton(props) {
  switch (props.moduleId) {
    case STANDARD_MODULE_IDS.documentRequests.id:
      return <DocumentRequestActionButton {...props} />;
    case STANDARD_MODULE_IDS.files.id:
      return <FileActionButton {...props} />;
    default:
      return <ModuleActionButton {...props} />;
  }
}
