import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Checkbox from "material-ui/Checkbox";
import Avatar from "components/Atoms/Avatar";

const User = ({
  user,
  showEmail,
  showCheckbox,
  onClick,
  selected,
  style,
  icon,
  faded
}) => (
  <div styleName="user" onClick={onClick} style={style}>
    {showCheckbox ? (
      <div styleName="checkboxWrapper">
        <Checkbox checked={selected} />
      </div>
    ) : null}
    <Avatar
      alt
      size={25}
      style={{
        borderRadius: 5,
        marginRight: 10,
        backgroundColor: "#eee",
        opacity: faded ? ".3" : 1
      }}
      userId={user.user_id}
      imageUrl={user.user_photo_url}
      firstName={user.user_fname}
      lastName={user.user_lname}
    />
    <div
      styleName="name"
      style={{
        opacity: faded ? ".3" : 1
      }}
    >
      {user.user_fname} {user.user_lname}
      {showEmail ? ` (${user.user_email})` : null}
    </div>
    {icon}
  </div>
);

User.propTypes = {
  user: PropTypes.object.isRequired,
  style: PropTypes.object,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  showCheckbox: PropTypes.bool,
  showEmail: PropTypes.bool,
  icon: PropTypes.object,
  faded: PropTypes.bool
};

export default CSSModules(User, css);
