import React from "react";
import Tooltip from "components/Global/Tooltip";
import {
  Div,
  MediumShadedButton,
  InfoIcon,
  Text1,
  Text3,
  CheckIcon
} from "../../index";

const Type12Card = ({ name, subname, tooltip, onAction, actionText }) => (
  <Div
    my={2}
    px={2}
    py={1}
    bra={1}
    bg="white"
    shadow={2}
    display="row.flex-start.center"
    className="hoverContainer"
  >
    <CheckIcon sizeWFS={4} mr={2} color="neutral4" />
    <Div flex={1}>
      <Div display="row.flex-start.center">
        <Text3 pr={1} bold>
          {name}
        </Text3>
        {tooltip ? (
          <span className="showOnHover">
            <Tooltip placement="top" tooltip={tooltip}>
              <span style={{ display: "flex", cursor: "pointer" }}>
                <InfoIcon />
              </span>
            </Tooltip>
          </span>
        ) : (
          ""
        )}
      </Div>
      {subname ? <Text1 color="neutral4">{subname}</Text1> : ""}
    </Div>
    {onAction ? (
      <MediumShadedButton color="primary6" onClick={onAction}>
        {actionText}
      </MediumShadedButton>
    ) : (
      undefined
    )}
  </Div>
);

export { Type12Card };
