import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions } from "../model";
import { getPeople, getPagination } from "../selectors";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import CollapsablePanel from "ui-kit/CollapsiblePanel";
import AvatarCard from "ui-kit/AvatarCard";
import { PopoverMenu } from "components/Base";
import Table from "ui-kit/Table/View";
import PaginationBar from "ui-kit/PaginationBar/View/Slim";
import Checkbox from "material-ui/Checkbox";

const decorate = R.compose(
  connect(
    state => ({
      people: getPeople(state),
      pagination: getPagination(state)
    }),
    {
      download: actions.download,
      showOpenEmailsModal: actions.showOpenEmailsModal,
      showAddStaffModal: actions.showAddStaffModal,
      toggleCollapsibleId: actions.toggleCollapsibleId,
      toggleCollapsibleClick: actions.toggleCollapsibleClick,
      duplicatePerson: actions.duplicatePerson,
      removePerson: actions.removePerson,
      selectSectionTab: actions.selectSectionTab,
      toggleSelectedPeopleIds: actions.toggleSelectedPeopleIds,
      submitRequest: actions.submitRequest
    }
  ),
  CSSModules(css)
);

const DownIcon = ({ onClick, style = {} }) => (
  <span class="material-icons" onClick={onClick} style={style}>
    keyboard_arrow_down
  </span>
);

const PeopleCards = ({
  people,
  toggleCollapsibleId,
  toggleCollapsibleClick,
  duplicatePerson,
  removePerson,
  selectSectionTab,
  pagination,
  toggleSelectedPeopleIds,
  submitRequest
}) => (
  <div styleName="peopleCards">
    {R.map(
      person => (
        <div styleName="card">
          <CollapsablePanel
            key={person.id}
            bg="white"
            p={2}
            arrowColor="gray7"
            mb={2}
            width={1}
            opened={person.opened}
            onClick={toggleCollapsibleClick}
            heading={
              <div styleName="cardHeader">
                <div styleName="row">
                  <div
                    styleName="avatar"
                    style={person.selected ? { display: "none" } : {}}
                  >
                    <div styleName="avatar-mobile">
                      <AvatarCard
                        avatar={{
                          photoUrl: person.photo_url,
                          name: person.name
                        }}
                        avatarProps={{
                          singleInitial: false
                        }}
                        size="small"
                      />
                    </div>
                    <div styleName="avatar-desktop">
                      <AvatarCard
                        avatar={{
                          photoUrl: person.photo_url,
                          name: person.name
                        }}
                        avatarProps={{
                          singleInitial: false
                        }}
                        size="medium"
                      />
                    </div>
                  </div>
                  <div
                    styleName="checkbox"
                    style={person.selected ? { display: "block" } : {}}
                  >
                    <div styleName="avatar-mobile">
                      <Checkbox
                        checked={person.selected}
                        iconStyle={{ marginRight: 1, width: 22 }}
                        onCheck={() => {
                          toggleSelectedPeopleIds({
                            id: person.id,
                            selected: person.selected
                          });
                        }}
                      />
                    </div>
                    <div styleName="avatar-desktop">
                      <Checkbox
                        checked={person.selected}
                        iconStyle={{ marginRight: 6, marginLeft: 2 }}
                        onCheck={() => {
                          toggleSelectedPeopleIds({
                            id: person.id,
                            selected: person.selected
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div styleName="cardName">{person.name}</div>
                  <span
                    class="material-icons"
                    style={{ fontSize: 16, marginLeft: 4 }}
                  >
                    launch
                  </span>
                  <PopoverMenu
                    Label={CSSModules(css)(({ onClick }) => (
                      <div styleName="moreOptionsCard">
                        <span class="material-icons" onClick={onClick}>
                          more_horiz
                        </span>
                      </div>
                    ))}
                    menuItems={[
                      [
                        "Duplicate Person",
                        () => {
                          duplicatePerson();
                        }
                      ],
                      [
                        "Delete Person",
                        () => {
                          removePerson();
                        }
                      ]
                    ]}
                  />
                </div>
                <div
                  styleName="submitRequest"
                  onClick={() => submitRequest(person.id)}
                >
                  Submit Request
                </div>
              </div>
            }
            arrowIcon={() => (
              <DownIcon
                onClick={() => toggleCollapsibleId(person.id)}
                style={!person.opened ? { transform: "rotate(-90deg)" } : {}}
              />
            )}
          >
            <div styleName="cardContent">
              <div styleName="tabs">
                {R.map(
                  ({ id, name, selected, rows }) => (
                    <div
                      key={id}
                      styleName="tab"
                      style={selected ? { borderBottomColor: "#8d64d1" } : {}}
                      onClick={() =>
                        selectSectionTab({
                          personId: person.id,
                          sectionId: id,
                          rows
                        })
                      }
                    >
                      {name}
                    </div>
                  ),
                  R.propOr([], "sections", person)
                )}
              </div>
              <div style={{ flex: 1, overflow: "auto" }}>
                <Table instanceId={person.id} showCheckbox={false} />
              </div>
            </div>
          </CollapsablePanel>
        </div>
      ),
      people
    )}
    <PaginationBar
      style={{
        maxWidth: "100%",
        color: "#333333",
        fontSize: "16px",
        letterSpacing: "0px",
        backgroundColor: "inherit"
      }}
      total={pagination.total}
      showPageSizeOptions={false}
      showLabel={false}
    />
  </div>
);

export default decorate(PeopleCards);
