import * as R from "ramda";

export const getLocation = () => window.location;

export const getQuery = R.compose(
  R.propOr({}, "query"),
  getLocation
);

export const getPathname = (state, props = {}) =>
  R.compose(
    props.match ? R.match(props.match) : R.identity,
    R.propOr("", "pathname"),
    getLocation
  )(state, props);

export const getSearch = R.compose(
  R.propOr("", "search"),
  getLocation
);
