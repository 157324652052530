import React from "react";
import { Div, LoadingIcon, Text5 } from "components/Base";

const Loading = () => (
  <Div
    display="row.center.center"
    flex={1}
    style={{
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,0.3)",
      zIndex: 5
    }}
  >
    <Div
      display="column.center.center"
      width={398}
      height={164}
      bra={1}
      bg="white"
      p={5}
    >
      <Div display="column.center.center">
        <Text5 bold mb={5}>
          Loading...
        </Text5>
        <LoadingIcon size={50} color="neutral4" />
      </Div>
    </Div>
  </Div>
);

export default Loading;
