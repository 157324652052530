import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { Div } from "components/Base";

import { actions, getters } from "Modules/Dashboard";

import Header from "Modules/Dashboard/View/Sidebar/Header";
import ViewPicker from "ui-kit/ViewPicker/View";
import FieldFilter from "Modules/Dashboard/View/Sidebar/FieldFilter";
import SortBy from "Modules/Dashboard/View/Sidebar/SortBy";
import SidebarLink from "Modules/Dashboard/View/Sidebar/SidebarLink";
import SelectFields from "Modules/Dashboard/View/Sidebar/SelectFields";

import { getManageLinks } from "Modules/Dashboard/selectors";

import { VIEWPICKER_INSTANCE_ID } from "Modules/Dashboard/constants";
import RefreshDashboards from "./RefreshDashboards";

const decorate = connect(
  (state, props) => ({
    manageLinks: getManageLinks(state, props),
    preferences: getters.preferences(state)
  }),
  { onToggleCollapsed: () => actions.toggleCollapse() }
);

const getLinks = (link, level = 0) =>
  link.links ? (
    <SidebarLink key={link.id} {...link} level={level}>
      {R.compose(
        R.map(l => getLinks(l, level + 1)),
        R.filter(l => l)
      )(link.links)}
    </SidebarLink>
  ) : (
    <SidebarLink key={link.id} {...link} level={level} />
  );

const Sidebar = ({ manageLinks, preferences, handlers }) => {
  return (
    <Div
      width={250}
      py={5}
      pl={4}
      pr={3}
      style={{
        backgroundColor: "#813CD2",
        minHeight: "100%"
      }}
      display="column.flex-start.stretch"
    >
      <Header />
      <ViewPicker instanceId={VIEWPICKER_INSTANCE_ID} mb={2} />
      <SelectFields
        onClick={() => handlers.showSelectFieldsModal(preferences)}
        mb={2}
      />
      <SortBy mb={2} />
      <FieldFilter mb={2} />
      <RefreshDashboards />

      {R.compose(
        R.map(l => getLinks(l, 0)),
        R.filter(l => l)
      )(manageLinks)}

      <Div flex={1} />
      {/* footer */}
    </Div>
  );
};

export default decorate(Sidebar);
