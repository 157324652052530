export default ({ all, field }) => [
  ...all,
  Object.assign({}, field, {
    type: "text"
  }),
  {
    id: `${field.id}_qty`,
    name: `QTY (${field.name})`,
    type: "number",
    visible: true
  }
];
