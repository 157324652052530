import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../index";
import { getFilteredMergeTags } from "../selectors";
import { withProps } from "utils/General";
import {
  Div,
  Text1,
  Text2,
  Text3,
  Dropdown,
  MediumFilledInput,
  MediumOutlineButton,
  SearchIcon,
  UpIcon,
  RightIcon
} from "components/Base";
import Loading from "ui-kit/Loading";
import ClickToCopy from "components/Global/ClickToCopy";

const Tag = withProps({
  bg: {
    default: "transparent",
    hover: "gray2"
  },
  display: "row",
  bc: "neutral4",
  bb: 1,
  mt: 3,
  py: 1,
  fs: 2,
  style: {
    wordBreak: "break-all",
    cursor: "pointer"
  }
})(Div);

const decorate = connect(
  state => ({
    selectedModuleId: getters.selectedMergeModuleId(state),
    modules: getters.modules(state),
    mergeData: getters.mergeData(state),
    selectedModule: getters.selectedModule(state),
    mergeTagsLoading: getters.mergeTagsLoading(state),
    viewMoreTagsIds: getters.viewMoreTagsIds(state),
    hasModuleId: getters.hasModuleId(state),
    tagsFilter: getters.tagsFilter(state),
    mergeTags: getFilteredMergeTags(state)
  }),
  {
    fetchMergeData: actions.fetchMergeData,
    showMergeTagModal: actions.showMergeTagModal,
    toggleViewMoreTagsIds: actions.toggleViewMoreTagsIds,
    setTagsFilter: actions.setTagsFilter
  }
);

const MergeTags = ({
  selectedModuleId,
  modules,
  fetchMergeData,
  mergeData,
  selectedModule,
  showMergeTagModal,
  mergeTagsLoading,
  viewMoreTagsIds,
  toggleViewMoreTagsIds,
  hasModuleId,
  tagsFilter,
  setTagsFilter,
  mergeTags
}) => {
  return (
    <Div ml={3} width={418}>
      <Div
        height={40}
        mb={4}
        fs={1}
        color="black"
        display="column.center"
        px={2}
      >
        Please Note: .DOCX is the only supported template type
      </Div>
      <Div bra={1} bg="neutral1" p={4}>
        {mergeTagsLoading ? (
          <Loading />
        ) : (
          <Div>
            <Text3 bold color="black" mb={2}>
              Merge Tags & Tables for:
            </Text3>
            {!hasModuleId && R.isNil(selectedModule) && (
              <Dropdown
                options={modules.map(({ id, name }) => ({
                  label: name,
                  value: id
                }))}
                selected={selectedModuleId}
                onChange={({ value }) => fetchMergeData(value)}
              />
            )}
            <MediumFilledInput
              bg="neutral2"
              RightIcon={SearchIcon}
              placeholder="Search merge tags & related records"
              mt={2}
              width={1}
              color="neutral6"
              continuous
              value={tagsFilter}
              onChange={value => setTagsFilter(value)}
            />
            <Div>
              {R.map(mergeTag => {
                const isExpanded = R.any(
                  tagId => tagId === mergeTag.id,
                  viewMoreTagsIds
                );
                return (
                  <Div>
                    {R.length(R.propOr([], "merge_tags", mergeData)) > 1 && (
                      <Text2 color="black" bold key={mergeTag.id} mt={3}>
                        {mergeTag.name}
                      </Text2>
                    )}
                    <Div>
                      {R.compose(
                        R.map(({ tag, id }) => (
                          <ClickToCopy
                            key={id}
                            text={`{{${tag}}}`}
                            textToCopy={`{{${tag}}}`}
                            inside={true}
                            display="tooltip"
                            Component={Tag}
                          />
                        )),
                        R.slice(0, 5)
                      )(mergeTag.templateTags)}
                      {isExpanded && (
                        <Div>
                          {R.compose(
                            R.map(({ tag, id }) => (
                              <ClickToCopy
                                key={id}
                                text={`{{${tag}}}`}
                                textToCopy={`{{${tag}}}`}
                                inside={true}
                                display="tooltip"
                                Component={Tag}
                              />
                            )),
                            R.slice(5, -1)
                          )(mergeTag.templateTags)}
                        </Div>
                      )}
                      {R.length(mergeTag.templateTags) > 5 && (
                        <Div
                          display="row.flex-start.center"
                          mt={2}
                          onClick={() =>
                            toggleViewMoreTagsIds({
                              id: mergeTag.id,
                              isExpanded
                            })
                          }
                        >
                          {isExpanded ? (
                            <UpIcon color="primary7" size={16} />
                          ) : (
                            <RightIcon color="primary7" size={16} />
                          )}
                          <Text1 color="primary7" underline ml={2}>
                            {isExpanded ? "View Less" : "View All Merge Tags"}
                          </Text1>
                        </Div>
                      )}
                    </Div>
                  </Div>
                );
              }, mergeTags)}
            </Div>
            <Div mt={3}>
              {R.map(
                relatedModule => (
                  <Div
                    py={1}
                    px={2}
                    display="row.space-between.center"
                    bra={1}
                    bg="neutral2"
                    mt={2}
                    key={relatedModule.id}
                  >
                    <Div display="column">
                      <Text2 bold color="black" truncate>
                        {relatedModule.name}
                      </Text2>
                      <Text1 color="gray6" truncate>
                        {relatedModule.meta.lookup_field.name}
                      </Text1>
                    </Div>
                    <MediumOutlineButton
                      onClick={() =>
                        showMergeTagModal({
                          selectedRelatedModule: relatedModule
                        })
                      }
                    >
                      Generate Table
                    </MediumOutlineButton>
                  </Div>
                ),
                R.propOr([], "related_modules", mergeData)
              )}
            </Div>
          </Div>
        )}
      </Div>
    </Div>
  );
};

export default decorate(MergeTags);
