import React from "react";
import { noop } from "utils/General";

import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import MiniItemsSelector from "ui-kit/MiniItemsSelector/View";

const MiniItemModal = ({
  showModal,
  itemGroups,
  setFilters = noop,
  hideModal = noop,
  instanceId = "miniItemModal",
  mode,
  title = "Select items"
}) => (
  <MiniModalWrapper showModal={showModal} hideModal={hideModal} title={title}>
    <MiniItemsSelector
      mode={mode}
      buttonLabel="Save"
      itemGroups={itemGroups}
      onDone={setFilters}
      maxHeight={400}
      instanceId={instanceId}
      iniClearSearch
    />
  </MiniModalWrapper>
);

export default MiniItemModal;
