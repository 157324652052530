import { get } from "lodash";

export default function(value, meta) {
  let items = [];
  if ("availableCatalogItems" in meta) {
    items = meta.availableCatalogItems;
  } else if ("availableCatalogItems" in meta.references) {
    items = meta.references.availableCatalogItems;
  }
  return value.records
    .map(({ id, quantity }) => {
      const name = get(items.find(c => c.id === id), "value", "No Value");
      return `${name} (${quantity})`;
    })
    .join(", ");
}
