import React from "react";
import Editor from "../../Editors/CheckboxEditor";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const CheckBoxEditor = ({ ...props }) => (
  <Editor {...props} className={["styled", css.checkbox].join(" ")} />
);

export default CSSModules(CheckBoxEditor, css);
