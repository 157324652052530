import React from "react";
import * as R from "ramda";
import { Div } from "components/Base";
import resolveEditor from "components/Global/StandAloneEditors/utils/resolveEditor";
import Label from "components/Global-2016/Forms/Label";

const InputGroup = ({ children, style = {} }) => (
  <Div display="column" mb={5} width={[1, 1, 1 / 2]} style={style}>
    {children}
  </Div>
);

const Field = ({ rowId, field, value }) => {
  const Editor = resolveEditor(field);
  return (
    <InputGroup
      style={
        R.propEq("type", "checkbox", field)
          ? {
              flexDirection: "row-reverse",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%"
            }
          : { width: "100%" }
      }
    >
      <Label
        required={field.isRequired}
        description={field.description}
        style={
          R.propEq("type", "checkbox", field)
            ? {
                marginBottom: 0
              }
            : {}
        }
      >
        {field.name}
      </Label>
      {R.propEq("type", "checkbox", field) ? (
        <div style={{ width: 37 }}>
          <Editor
            onChange={async value => {
              if (field.type === "item-block") {
                await field.onValueChange(rowId, value);
                field.validateFormData();
              } else {
                field.onValueChange(rowId, value);
              }
            }}
            value={value}
            disabled={field.disabled}
            autocomplete={`autocomplete_${field.id}`} // @NOTE: Use random autocomplete ID so browsers dont have a value cached for it
            {...field.editorProps}
          />
        </div>
      ) : (
        <Editor
          onChange={async value => {
            if (field.type === "item-block") {
              await field.onValueChange(rowId, value);
              field.validateFormData();
            } else {
              field.onValueChange(rowId, value);
            }
          }}
          value={value}
          disabled={field.disabled}
          autocomplete={`autocomplete_${field.id}`} // @NOTE: Use random autocomplete ID so browsers dont have a value cached for it
          {...field.editorProps}
        />
      )}
    </InputGroup>
  );
};

export { InputGroup, Label, Field };
