import React from "react";

import MiniModalWrapper from "ui-kit/MiniModalWrapper";

import FormList from "./FormList";

import FilteringAndSorting from "./FilteringAndSorting";

const Layout = ({ hideModal, handlers }) => (
  <MiniModalWrapper
    title="Select a form to submit"
    hideModal={hideModal}
    width={743}
    height={550}
    bg="neutral0"
    showModal
    isPortal={false}
    stopPropagation
  >
    <FilteringAndSorting />
    <FormList handlers={handlers} />
  </MiniModalWrapper>
);

export default Layout;
