import Records from "components/Event/Module";
import Form from "components/Event/Module/Form";
import FormSettings from "components/Event/Module/Form/Settings";
import FormShare from "components/Event/Module/Form/Share";
import RecordView from "components/Event/Records/Record";
import AccountsReports from "components/Event/Accounts/Reports";
import Reports from "Reports/Reports/View";
import ModuleDashboard from "Modules/Dashboard/View";
import ModulePortalSettings from "Modules/PortalSettings/View";

import { hot } from "App/Config/hot";

const Module = {
  Records,
  Form,
  FormSettings,
  FormShare,
  RecordView,
  AccountsReports,
  Reports,
  ModuleDashboard,
  ModulePortalSettings
};

export default hot(Module);
