import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Submission from "./Submission";

import { hideModal, showModal } from "redux/modules/modal/actions";
import { getRecords } from "redux/modules/modules/records/actions";

function mapDispatchToProps(dispatch, ownProps) {
  const actions = bindActionCreators(
    {
      showModal,
      hideModal,
      getRecords
    },
    dispatch
  );

  // Check to see if we're overriding `hideModal` with a custom handler
  if (ownProps.helpers.hideModal) {
    actions.hideModal = ownProps.helpers.hideModal;
  }

  return actions;
}

export default connect(
  null,
  mapDispatchToProps
)(Submission);
