import React, { Component } from "react";

import SignupActions from "actions/Global/SignupActions";
import View from "./View";
import Helpers from "utils/Global/Helpers";
import { LOGIN_RETURN_TO_KEY } from "views/Auth/LoginView";

const KEYS = {
  password: {
    message: "Please enter a valid password.",
    validator: "isValidPassword"
  },
  confirmPassword: {
    message: "Please enter the same password in both inputs.",
    validator: "isValidConfirmationPassword"
  }
};

class Controller extends Component {
  constructor(props) {
    super(props);
    const { location } = props;

    this.state = {
      error: null,
      processing: false,
      password: "",
      confirmPassword: "",
      verificationHash: props.params.verificationHash,
      verificationLink: null,
      validVerificationLink: true,
      consumedVerificationLink: false,
      user: null
    };
  }

  componentDidMount() {
    SignupActions.getVerificationLink(this.state.verificationHash, response => {
      this.handleGetVerificationLink(response);
    });
  }

  handleGetVerificationLink = response => {
    if (response && response.success === true) {
      this.setState({
        user: response.payload.user,
        validVerificationLink: true,
        consumedVerificationLink:
          response.payload.verificationLink &&
          response.payload.verificationLink.status === "consumed"
      });
    } else {
      this.setState({ validVerificationLink: false });
      throw new Error(
        `Account Verification Error: ${JSON.stringify(response)}`
      );
    }
  };

  handleVerificationResult = async response => {
    if (response.success) {
      // set redirect
      localStorage.setItem(LOGIN_RETURN_TO_KEY, "/account/onboard");

      // login and redirect to callback
      window.webAuth.login(
        {
          username: this.state.user.email,
          password: this.state.password,
          realm: window.__AUTH0_REALM__,
          // eslint-disable-next-line no-underscore-dangle
          redirectUri: `${window.__LENND_APP_URL__}/auth/callback`
        },
        err => {
          if (err) {
            console.error(
              `[Virtual User] Error logging in`,
              err.error_description
            );
            this.props.showSnackbar({
              message: err.error_description
            });
          } else {
            window.location = "/account/onboard";
          }
        }
      );
      /*
      this.props.router.push({
        pathname: "/login",
        query: {
          email: this.state.user.email,
          returnTo: response.payload.organization_id
            ? `/organization/${response.payload.organization_id}/home`
            : undefined,
          message:
            "Your password has been set. Please login with your credentials to continue."
        }
      });
      */
    } else {
      throw new Error(
        `Account Verification Error: ${JSON.stringify(response)}`
      );
    }
  };

  onLoginClick = e => {
    window.location = "/login";
  };

  onForgotPasswordClick = e => {
    window.location = "/forgot";
  };

  onFieldChange = e =>
    this.setState({ [e.target.name]: e.target.value, error: null });

  isValidValue = v => Boolean(v);
  isValidPassword = v => Helpers.isValidPassword(v);
  isValidConfirmationPassword = v =>
    Helpers.isValidPassword(v) && v === this.state.password;
  validateForm = () => {
    const keysToCheck = ["password", "confirmPassword"];

    // validate: we have valid values
    for (let i = 0; i < keysToCheck.length; i++) {
      const key = keysToCheck[i];
      if (!this[KEYS[key].validator](this.state[key])) {
        this.setState({
          processing: false,
          error: KEYS[keysToCheck[i]].message
        });
        return false;
      }
    }

    this.setState({ error: null });

    return true;
  };

  onSubmit = async e => {
    e.preventDefault();
    if (this.state.processing || !this.validateForm()) return false;

    this.setState({ processing: true });

    SignupActions.verifyEmail(
      this.state.verificationHash,
      this.state.password,
      response => {
        this.handleVerificationResult(response);
      }
    );
  };

  render() {
    const {
      error,
      processing,
      password,
      confirmPassword,
      validVerificationLink,
      consumedVerificationLink,
      user
    } = this.state;

    const verificationEmail = user ? user.email : null;
    const resendLink = `${window.__LENND_APP_URL__}/signup/resend`;

    return (
      <View
        {...{
          onSubmit: this.onSubmit,
          onFieldChange: this.onFieldChange,
          onLoginClick: this.onLoginClick,
          onForgotPasswordClick: this.onForgotPasswordClick,
          password,
          confirmPassword,
          verificationEmail,
          processing,
          validVerificationLink,
          consumedVerificationLink,
          resendLink,
          error
        }}
      />
    );
  }
}

export default Controller;
