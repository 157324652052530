import React from "react";
import * as R from "ramda";
import { Div } from "components/Base";
import { withRouter } from "react-router";

import { connect } from "react-redux";
import { getFiltersOn, getRowActions } from "../selectors";
import { actions, getters } from "../model";
import { TABLE_INSTANCE_ID } from "../constants";

import PaginationBar from "./PaginationBar";
import Toolbar from "./GridToolbar";
import Tabs from "./Tabs";
import FiltersOn from "ui-kit/FiltersOn";

import ShowNoResults from "ui-kit/ShowNoResults";

import Table, { TableWrapper } from "ui-kit/Table/View";

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      filtersOn: getFiltersOn(state, props),
      loading: getters.loading(state, props),
      showNoResults: getters.showNoResults(state, props),
      preferences: getters.preferences(state, props),
      rowActions: getRowActions(state, props)
    }),
    {
      onClearFilters: actions.clearFilters
    }
  )
);

const GridLayout = ({
  handlers,
  filtersOn,
  onClearFilters,
  rowActions = [],
  loading = false,
  showNoResults = false
}) => (
  <Div
    flex={1}
    display="column.flex-start.stretch"
    height={1}
    style={{
      padding: "0 20px",
      height: "calc(100vh - 120px)"
    }}
  >
    <Toolbar handlers={handlers} />
    <Tabs />
    <FiltersOn
      on={filtersOn}
      onClearFilters={onClearFilters}
      caption="Only showing results that contain or match filters"
    />
    {showNoResults ? (
      <ShowNoResults flex={1} />
    ) : (
      <TableWrapper loading={loading} style={{ flex: 1 }}>
        <Table
          instanceId={TABLE_INSTANCE_ID}
          rowActions={rowActions}
          showOpenRecord
        />
      </TableWrapper>
    )}
    <PaginationBar />
  </Div>
);

export default decorate(GridLayout);
