import React from "react";
import { Div } from "components/Base";
import { connect } from "react-redux";
import { getters } from "../model";

const decorator = connect((state, props) => ({
  title: getters.title(state, props)
}));

export const Header = ({ title }) => (
  <Div
    fw={4}
    color="#123059"
    style={{
      marginBottom: "30px",
      fontSize: "30px"
    }}
  >
    {title}
  </Div>
);

export default decorator(Header);
