import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import CircularProgress from "material-ui/CircularProgress";
import { sortBy } from "lodash";

const ReportSummaryTable = ({
  menu,
  fetching,
  title,
  fields = [],
  rows = [],
  footer,
  emptyState
}) => {
  const sortedFields = sortBy(fields, ["order"]);
  const fieldToSortBy = sortedFields.find(f => f.order === 0) || fields[0];

  let rowsToShow = rows;
  if (fieldToSortBy.id && fieldToSortBy.valueGetter) {
    rowsToShow = sortBy(rows, row => {
      const val = fieldToSortBy.valueGetter(row[fieldToSortBy.id]) || "";
      return val.toLowerCase();
    });
  }

  const rowsList = rowsToShow.map((row, i) => (
    <tr key={`row_${i}`}>
      {sortedFields.map(field => {
        const formatted =
          typeof field.formatter === "function" ? (
            <field.formatter
              key={field.id}
              {...{ field, value: row[field.id] }}
            />
          ) : (
            row[field.id]
          );

        return <td key={`row_${i}_cell_${field.id}`}>{formatted}</td>;
      })}
    </tr>
  ));

  return (
    <div>
      {title || menu ? (
        <div styleName="top">
          {title ? <div styleName="title">{title}</div> : ""}
          {fetching ? (
            <CircularProgress
              style={{ right: "4px", top: "-14px" }}
              size={17.85}
              mode="indeterminate"
            />
          ) : (
            <div styleName="fetchingPlaceholder" />
          )}
          {menu ? <div styleName="menuWrapper">{menu}</div> : ""}
        </div>
      ) : (
        ""
      )}
      {rowsList.length ? (
        <table styleName="table">
          <thead>
            <tr>
              {sortedFields.map(f => (
                <td key={f.id} styleName="header" style={f.style}>
                  {f.label}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>{rowsList}</tbody>
        </table>
      ) : (
        <div styleName="emptyState">{emptyState}</div>
      )}
      {footer}
    </div>
  );
};

ReportSummaryTable.defaultProps = {
  emptyState: "No records yet"
};

ReportSummaryTable.propTypes = {
  emptyState: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.node,
    PropTypes.element
  ]),
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      formatter: PropTypes.func,
      order: PropTypes.number,
      style: PropTypes.object
    })
  ).isRequired,
  footer: PropTypes.any,
  menu: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  title: PropTypes.string
};

export default CSSModules(ReportSummaryTable, css);
