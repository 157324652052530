import { bindActionCreators } from "redux";
import * as STANDARD_MODULE_FIELDS from "@lennd/value-types/src/constants/standard-module-field-ids";
import { connect } from "react-redux";
import * as R from "ramda";
import { SubformBlock } from "./View";
import { withState } from "utils/General";
import { fieldTypeIcon } from "components/Global/Table3/FieldTypeIcons/resolve-field-type-icon";

import React, { Component } from "react";
import AddEditFieldModal from "Modules/AddEditColumnModal/View";

import {
  addField as addFormField,
  addSubformField,
  deleteSubformField,
  updateField as updateFormField,
  updateSubformField,
  bulkUpdateSubformFields
} from "redux/modules/formsV2/form/fields/actions";
import {
  addSection,
  deleteSection,
  updateFieldSectionRelation
} from "redux/modules/formsV2/form/fields/sections/actions";

import { getModules } from "redux/modules/modules/modules/actions";
import { refreshForm } from "redux/modules/formsV2/form/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import {
  addField as addModuleField,
  getFields
} from "redux/modules/modules/fields/actions";

import { modules } from "redux/modules/modules/modules/selectors";
import { sortedFormFields } from "redux/modules/formsV2/form/selectors";
import { form, fields } from "redux/modules/formsV2/form/selectors";
import { fields as moduleFields } from "redux/modules/modules/fields/selectors";

import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";

const getSubformModuleId = R.path(["subform", "form", "base_module_id"]);
const getSubformFormId = R.path(["subform", "form", "id"]);

function mapStateToProps(state, props) {
  const formToPass = form(state);
  const formFieldsToPass = fields(state) || [];
  const formField = props.formFieldId
    ? formFieldsToPass.find(f => f.id === props.formFieldId)
    : null;

  const moduleId = getSubformModuleId(formField || {});
  const readOnlyFields = resolveReadOnlyFields({
    moduleId
  });

  const moduleFieldsToPass = moduleFields(state, moduleId).filter(
    f => !readOnlyFields.includes(f.id)
  );

  return {
    formField,
    sortedFormFields: sortedFormFields(state),
    formFields: formFieldsToPass,
    modules: modules(state).filter(
      m => m.id !== formToPass.base_mdoule_id && m.source === "custom"
    ),
    form: formToPass,
    eventId: formToPass.event_id,
    moduleFields: moduleFieldsToPass
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addModuleField,
      addFormField,
      getModules,
      showModal,
      hideModal,
      showSnackbar,
      refreshForm,
      updateFormField,
      updateSubformField,
      bulkUpdateSubformFields,
      addSection,
      deleteSection,
      updateFieldSectionRelation,
      getFields,
      addSubformField,
      deleteSubformField
    },
    dispatch
  );
}

class SubformBlockController extends Component {
  async componentDidMount() {
    if (!this.props.formFieldId) {
      await this.props.getModules(this.props.eventId);
    } else {
      await this.getModuleFields();
    }
    this.props.setLoading(false);
  }

  componentDidUpdate(oldProps) {
    if (!this.props.formField && oldProps.formField) {
      // handle case where subform is deleted - go back to main screen
      this.props.returnTo();
    } else if (oldProps.formFieldId !== this.props.formFieldId) {
      this.props.setScene("editSubform");
      this.getModuleFields();
    }
  }

  getModuleFields = () =>
    this.props.getFields({
      moduleId: getSubformModuleId(this.props.formField),
      options: {
        eventId: this.props.eventId
      }
    });

  reloadForm = () => this.props.refreshForm(this.props.form.id);

  createSubformBlock = async () => {
    this.props.setSaving(true);

    // create module field
    const { field } = await this.props.addModuleField({
      moduleId: this.props.moduleId,
      field: {
        name: "Bulk Form",
        type: "lookup",
        settings: {
          moduleId: this.props.form.base_module_id,
          fieldId: STANDARD_MODULE_FIELDS.MODULE.ID
        }
      },
      options: {
        eventId: this.props.form.event_id
      }
    });

    // form field
    const result = await this.props.addFormField({
      version: 3,
      eventId: this.props.form.event_id,
      formId: this.props.form.id,
      name: this.props.fieldName,
      type: "subform",
      settings: { allowNewRows: true },
      order: this.props.sortedFormFields.length + 1,
      moduleFieldId: field.id,
      baseModuleId: this.props.moduleId
    });

    await this.reloadForm();

    const selectedModuleName = R.compose(
      R.prop("name"),
      R.find(R.propEq("id", this.props.moduleId))
    )(this.props.modules);

    this.props.onCreate(result.field.id, selectedModuleName);
  };

  decorateSettingsForRequest = settings => {
    return {
      settings: {
        allowNewRows: R.path(["limit", "enabled"], settings),
        style: settings.collectionMode,
        maxAmount: R.path(["limit", "value"], settings)
      }
    };
  };

  handleSettingsChange = async settings => {
    await this.props.updateFormField({
      fieldId: this.props.formField.id,
      ...this.decorateSettingsForRequest(settings)
    });
    return true;
  };

  onFieldReorder = async data => {
    const sectionIndicies = [];
    data.forEach((field, idx) => {
      if (field.type === "section") {
        sectionIndicies.push(idx);
      }
    });

    await Promise.all(
      sectionIndicies.map(async (thisIdx, idx) => {
        // get idx of next section index
        const nextSectionIdx = sectionIndicies[idx + 1] || data.length;
        const fields = data.slice(thisIdx, nextSectionIdx);

        await Promise.all(
          fields.map(field =>
            this.props.updateFieldSectionRelation({
              formId: this.props.form.id,
              subformId: this.props.formField.id,
              toSectionId: data[thisIdx].id,
              fromSectionId: data[thisIdx].id,
              field: { id: field.id }
            })
          )
        );

        await this.props.bulkUpdateSubformFields({
          formId: this.props.form.id,
          subformId: this.props.formField.id,
          sectionId: data[thisIdx].id,
          fields: fields.map((f, order) => ({ fieldId: f.id, order }))
        });

        return true;
      })
    );

    this.reloadForm();
  };

  showAddFieldModal = () => {
    this.props.showModal({
      content: (
        <AddEditFieldModal
          eventId={this.props.eventId}
          moduleId={getSubformModuleId(this.props.formField)}
          onSave={() => {
            this.getModuleFields();
          }}
        />
      )
    });
  };

  showEditFieldModal = fieldId => {
    this.props.showModal({
      content: (
        <AddEditFieldModal
          eventId={this.props.eventId}
          moduleId={getSubformModuleId(this.props.formField)}
          fieldId={fieldId}
          onSave={() => {
            this.reloadForm();
          }}
        />
      )
    });
  };

  addSection = async order => {
    await this.props.addSection({
      formId: getSubformFormId(this.props.formField),
      subformFieldId: this.props.formField.id,
      type: "section",
      name: "New section",
      settings: {
        description: "This is my new section"
      },
      order
    });

    this.reloadForm();
  };

  removeSection = async (fieldId, moveToFieldId) => {
    await this.props.deleteSection({
      formId: getSubformFormId(this.props.formField),
      subformId: this.props.formField.id,
      fieldId,
      moveFieldsToSectionId: moveToFieldId
    });

    this.reloadForm();
  };

  addFieldToForm = async field => {
    const sections = R.compose(
      R.sortBy(R.prop("order")),
      R.pathOr([], ["subform", "form", "fields_grouped_by_section"])
    )(this.props.formField);
    const allFields = R.pathOr([], ["subform", "form", "fields"])(
      this.props.formField
    );
    const lastSection = sections[sections.length - 1];

    await this.props.addSubformField({
      version: 3,
      eventId: this.props.eventId,
      formId: getSubformFormId(this.props.formField),
      name: field.name,
      type: "forms-v3-reference-field",
      order: allFields.length,
      moduleFieldId: field.id,
      subformId: this.props.formField.id,
      sectionId: lastSection.id
    });

    this.reloadForm();
  };

  removeFieldFromFrom = async (sectionId, fieldId) => {
    await this.props.deleteSubformField({
      formId: this.props.form.id,
      subformId: this.props.formField.id,
      sectionId,
      fieldId
    });

    this.reloadForm();
  };

  toggleRequired = (sectionId, fieldId, isRequired) => {
    this.props.updateSubformField({
      isRequired,
      formId: this.props.form.id,
      subformId: this.props.formField.id,
      sectionId,
      fieldId
    });
  };

  render() {
    const {
      sceneId,
      moduleId,
      setModuleId,
      loading,
      saving,
      modules,
      fieldName,
      setFieldName,
      selectedModuleName,
      formField,
      moduleFields
    } = this.props;

    const modulesWithHandlers = R.compose(
      R.map(m => ({
        id: m.id,
        name: m.name,
        selected: m.id === moduleId,
        onClick: () => setModuleId(m.id)
      })),
      R.sortBy(m => m.name.toLowerCase()),
      R.filter(R.propEq("source", "custom"))
    )(modules);

    const blockSettings = {
      limit: {
        enabled: R.pathOr(false, ["settings", "allowNewRows"])(formField),
        value: R.pathOr(0, ["settings", "maxAmount"])(formField)
      },
      collectionMode: R.pathOr("card-and-table", ["settings", "style"])(
        formField
      )
    };

    let previousSectionFieldId = null;
    const selectedFieldIds = [];

    const fields = R.compose(
      R.reduce((list, field) => {
        list.push({
          id: field.id,
          type: field.type,
          title: field.name,
          required: field.is_required,
          description: R.path(["settings", "description"])(field),

          onTitleChange: value => {
            this.props.updateSubformField({
              formId: field.form_id,
              subformId: formField.id,
              sectionId: null,
              fieldId: field.id,
              name: value,
              settings: field.settings
            });
          },

          onDescriptionChange: value => {
            this.props.updateSubformField({
              formId: field.form_id,
              subformId: formField.id,
              sectionId: null,
              fieldId: field.id,
              name: field.name,
              settings: {
                description: value
              }
            });
          },

          onHide: () => this.removeSection(field.id, previousSectionFieldId),
          onEdit: () => {}
        });

        previousSectionFieldId = field.id;

        list = R.concat(
          list,
          R.compose(
            R.map(sectionField => {
              selectedFieldIds.push(sectionField.module_field.id);
              return {
                id: sectionField.id,
                type: sectionField.module_field.type,
                title: sectionField.module_field.name,
                required: sectionField.is_required,
                description: R.path(["settings", "description"])(sectionField),
                onToggleRequired: () =>
                  this.toggleRequired(
                    field.id,
                    sectionField.id,
                    !sectionField.is_required
                  ),
                onHide: () =>
                  this.removeFieldFromFrom(field.id, sectionField.id),
                onEdit: () =>
                  this.showEditFieldModal(sectionField.module_field.id)
              };
            }),
            R.sortBy(R.prop("order")),
            R.propOr([], "fields")
          )(field)
        );

        return list;
      }, []),
      R.sortBy(R.prop("order")),
      R.pathOr([], ["subform", "form", "fields_grouped_by_section"])
    )(formField);

    const headerField = R.head(fields.slice(0, 1) || []);

    const otherFields = R.compose(
      R.map(field => ({
        id: field.id,
        icon: fieldTypeIcon(field.type),
        name: field.name,
        onAdd: () => this.addFieldToForm(field)
      })),
      R.filter(field => !selectedFieldIds.includes(field.id))
    )(moduleFields);

    return (
      <SubformBlock
        {...{
          loading,
          saving,
          sceneId,
          modules: modulesWithHandlers,
          onCancel: this.props.returnTo,
          onReturn: this.props.returnTo,
          fieldName,
          isValid: Boolean(moduleId),
          onChangeFieldName: value => setFieldName(value),
          selectedModuleName,
          createSubformBlock: this.createSubformBlock,
          //
          settings: blockSettings,
          onSettingsChange: this.handleSettingsChange,
          headerField,
          fields: fields.slice(1, fields.length) || [],
          otherFields,
          onAddField: this.showAddFieldModal,
          onAddSection: () =>
            this.addSection(
              R.pathOr(0, ["subform", "form", "fields", "length"])(formField)
            ),
          onFieldReorder: orderedFields =>
            this.onFieldReorder([headerField, ...orderedFields])
        }}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  R.compose(
    withState("moduleId", "setModuleId"),
    withState("fieldName", "setFieldName", ""),
    withState("sceneId", "setScene", props =>
      props.formFieldId ? "editSubform" : "selectModule"
    ),
    withState("loading", "setLoading", true),
    withState("saving", "setSaving", false)
  )(SubformBlockController)
);
