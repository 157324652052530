import React, { Component } from "react";
import {
  Div,
  Text3,
  TextAreaInput,
  BigInsetInput,
  SmallToggle
} from "components/Base";

const InputLabel = ({ children }) => (
  <Text3 bold mb={1}>
    {children}
  </Text3>
);

const ToggleGroup = ({ children }) => (
  <InputGroup>
    <Div display="row.space-between.flex-start" mt={6}>
      {children}
    </Div>
  </InputGroup>
);

const InputSubtext = ({ children }) => (
  <Div color="gray7" fs={1}>
    {children}
  </Div>
);

const InputGroup = ({ children }) => <Div mb={3}>{children}</Div>;

class Settings extends Component {
  handleFieldChange = (value, e) =>
    this.props.handleFieldChange({ name: e.target.name, value });

  handleDropdownChange = name => ({ value }) =>
    this.props.handleFieldChange({ name, value });

  handleToggleChange = name => ({ value }) =>
    this.props.handleFieldChange({ name, value });

  render() {
    const { itemBlock } = this.props;
    return (
      <Div display="column" px={4} pb={3}>
        <Div py={1}>
          <Text3 bold pb={1}>
            Block Name
          </Text3>
          <BigInsetInput
            name="name"
            width={1}
            fs={3}
            flex={1}
            onChange={this.handleFieldChange}
            continuous
            value={itemBlock.name}
          />
        </Div>

        <Div py={1}>
          <Text3 bold>Description</Text3>
          <TextAreaInput
            name="description"
            width={1}
            fs={3}
            autoSize
            onChange={this.handleFieldChange}
            continuous
            value={itemBlock.description}
          />
        </Div>

        {/*
        // @NOTE: Temp. hiding until function is in place
        <Div py={1} pb={4}>
          <Text3 bold pb={1}>
            Display Options
          </Text3>
          <Dropdown
            selected={itemBlock.displayType}
            onChange={this.handleDropdownChange("displayType")}
            options={ORDER_ITEMS_DISPLAY_TYPES}
          />
        </Div>

        <Div py={1} pb={4}>
          <Text3 bold pb={1}>
            Select Type
          </Text3>
          <Dropdown
            selected={itemBlock.selectionType}
            onChange={this.handleDropdownChange("selectionType")}
            options={ORDER_ITEMS_SELECTION_TYPES}
          />
        </Div>

        <ToggleGroup>
          <Div>
            <InputLabel>Show quantity input</InputLabel>
            <InputSubtext>
              If enabled, allows user to specify a quantity
            </InputSubtext>
          </Div>
          <Div>
            <SmallToggle
              active={itemBlock.enableQuantityInput}
              onClick={() => {
                this.props.handleFieldChange({
                  name: "enableQuantityInput",
                  value: !itemBlock.enableQuantityInput
                });
              }}
            />
          </Div>
        </ToggleGroup>
        */}

        <ToggleGroup>
          <Div>
            <InputLabel>Enforce item limit</InputLabel>
            <InputSubtext>
              If enabled, once the total # of items from this block is hit, no
              more items will be able to be added to cart
            </InputSubtext>
          </Div>
          <Div>
            <SmallToggle
              active={itemBlock.enableItemLimit}
              onClick={() => {
                this.props.handleFieldChange({
                  name: "enableItemLimit",
                  value: !itemBlock.enableItemLimit
                });
                if (itemBlock.enableItemLimit) {
                  this.props.handleFieldChange({
                    name: "limit",
                    value: null
                  });
                }
              }}
            />
          </Div>
        </ToggleGroup>

        {itemBlock.enableItemLimit ? (
          <Div py={1} pb={4}>
            <BigInsetInput
              name="inventoryQuantity"
              placeholder="Enter limit..."
              width={1}
              fs={3}
              flex={1}
              style={{
                width: 200
              }}
              type="number"
              min="0"
              onChange={value => {
                this.props.handleFieldChange({
                  name: "limit",
                  value
                });
              }}
              continuous
              value={itemBlock.limit}
              disabled={!itemBlock.enableItemLimit}
              bg={itemBlock.enableItemLimit ? undefined : "transparent"}
            />
          </Div>
        ) : null}
      </Div>
    );
  }
}

export default Settings;
