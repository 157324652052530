import React from "react";

import { connect } from "react-redux";

import { Div } from "components/Base";

import { HEIGHTS } from "Items/BulkQuestionItemsModal/constants";

import {
  isSelectQuestionsPage,
  isAssignItemsPage
} from "Items/BulkQuestionItemsModal/selectors";

import BulkQuestions from "./BulkQuestions";

import AssignItems from "./AssignItems";

const bodyHeight = 661 - (HEIGHTS.HEADER + HEIGHTS.FOOTER);

const decorate = connect(state => ({
  selectQuestionsPage: isSelectQuestionsPage(state),
  assignItemsPage: isAssignItemsPage(state)
}));

const Body = ({ selectQuestionsPage, assignItemsPage, handlers }) => (
  <Div width={1} height={bodyHeight}>
    {selectQuestionsPage && <BulkQuestions handlers={handlers} />}
    {assignItemsPage && <AssignItems />}
  </Div>
);

export default decorate(Body);
