import * as R from "ramda";
import cx from "classnames";

import React, { useRef } from "react";

import ToggleAll from "./ToggleAll";
import Actions from "./Actions";
import DefaultTh from "./DefaultTh";

import { MediumOutlineButton } from "components/Base";

import { GROUP_ACTIONS, ACTION_TYPES, ICON_WIDTH } from "../constants";

const getColumnUtilsStyles = (...params) => {
  const howManyIcons = params.filter(c => c).length;
  const utilsWidth = ICON_WIDTH * howManyIcons;

  return {
    minWidth: utilsWidth,
    maxWidth: utilsWidth,
    width: utilsWidth
  };
};

export const Header = React.memo(
  ({
    useRowActions,
    columns,
    actions,
    columnIndex,
    instanceId,
    showCheckbox,
    showOpenRecord,
    canShowDeDuplicate
  }) => {
    const headRef = useRef();

    return (
      <thead ref={headRef}>
        <tr>
          {useRowActions || showCheckbox || showOpenRecord ? (
            <th
              key="actions"
              className="th action"
              style={getColumnUtilsStyles(
                showCheckbox,
                showOpenRecord,
                useRowActions,
                canShowDeDuplicate
              )}
            >
              <div className="utils">
                {showCheckbox && <ToggleAll instanceId={instanceId} />}
              </div>
            </th>
          ) : (
            <th
              className={cx("th action", {
                shortened: true
              })}
              key="actions"
            >
              <div className="utils">&nbsp;</div>
            </th>
          )}
          {R.map(columnId => {
            const name = R.pathOr("", [columnId, "name"], columns);
            const column = R.prop(columnId, columns);
            return React.createElement(
              DefaultTh,
              {
                column,
                key: columnId,
                className: `th th-${columnId}`,
                title: R.path([columnId, "name"], columns),
                instanceId: instanceId,
                headRef
              },
              R.length(actions) > 0 ? (
                <Actions
                  column={column}
                  actions={actions}
                  instanceId={instanceId}
                  rightClick
                >
                  <div className="column-action">{name}</div>
                </Actions>
              ) : (
                name
              )
            );
          }, columnIndex)}
        </tr>
      </thead>
    );
  }
);

Header.defaultProps = {
  useRowActions: true,
  showCheckbox: true,
  showOpenRecord: true,
  columns: {},
  columnActions: [],
  columnIndex: [],
  instanceId: ""
};

const ACTIONS = [
  {
    id: GROUP_ACTIONS.EXPORT_CSV,
    name: ".CSV",
    type: ACTION_TYPES.ITEM
  },
  {
    id: GROUP_ACTIONS.EXPORT_XLSX,
    name: ".XLSX",
    type: ACTION_TYPES.ITEM
  }
];

export const GroupHeader = React.memo(
  ({
    useRowActions,
    columns,
    columnIndex,
    group,
    showCheckbox,
    showOpenRecord,
    canShowDeDuplicate,
    instanceId
  }) => (
    <thead>
      <tr>
        <th className="th group" colspan={R.length(columnIndex) + 1}>
          <div className="utils">
            {showCheckbox && (
              <ToggleAll instanceId={instanceId} group={group} />
            )}
            <div className="title">
              {group.title}
              <div className="counter">{R.length(group.list)}</div>
            </div>
            <div style={{ flex: 1, color: "transparent" }}>a</div>
            <Actions group={group} actions={ACTIONS} instanceId={instanceId}>
              <MediumOutlineButton mr={4}>Export...</MediumOutlineButton>
            </Actions>
          </div>
        </th>
      </tr>
      <tr className="collapse">
        {R.prepend(
          <th
            className="th action"
            style={getColumnUtilsStyles(
              showCheckbox,
              showOpenRecord,
              useRowActions,
              canShowDeDuplicate
            )}
            key="actions"
          >
            <div className="utils">
              {showOpenRecord && (
                <i className="material-icons open-record">open_in_new</i>
              )}
              {useRowActions && (
                <div className="action-wrapper">
                  <i className="material-icons">flash_on</i>
                </div>
              )}
            </div>
          </th>,
          R.map(
            columnId =>
              React.createElement(
                R.path([columnId, "Header"], columns) || DefaultTh,
                {
                  column: R.prop(columnId, columns),
                  key: columnId,
                  className: `th th-${columnId}`
                },
                R.path([columnId, "name"], columns)
              ),
            columnIndex
          )
        )}
      </tr>
    </thead>
  )
);

GroupHeader.defaultProps = {
  useRowActions: true,
  showCheckbox: true,
  showOpenRecord: true,
  columns: {},
  columnIndex: [],
  group: {},
  instanceId: ""
};
