import React from "react";
import { connect } from "react-redux";
import { Div, SuperClearInput, SearchIcon } from "components/Base";
import { withProps } from "utils/General";
import CardPasses from "./CardPasses";
import BottomButtons from "./BottomButtons";
import { PASSES_COLUMNS_WIDTH } from "Orders/TicketsPasses/constants";
import QuickAssignPricesModal from "./QuickAssignPricesModal";
import NewCategoryModal from "./NewCategoryModal";
import { actions, getters } from "Orders/TicketsPasses";

const SearchIconGrey = withProps({
  color: "neutral6"
})(SearchIcon);

const decorate = connect(
  state => ({
    passesFilter: getters.passesFilter(state)
  }),
  {
    setPassesFilter: actions.setPassesFilter
  }
);

const Layout = ({ passesFilter, setPassesFilter }) => (
  <Div>
    <QuickAssignPricesModal />
    <NewCategoryModal />
    <SuperClearInput
      LeftIcon={SearchIconGrey}
      width={1}
      placeholder="Search pass names..."
      value={passesFilter}
      onChange={value => setPassesFilter(value)}
      continuous
    />
    <Div width={1} bt={1} bc="neutral2" p={4}>
      <Div width={1} display="row.space-between" mb={2}>
        <Div width={PASSES_COLUMNS_WIDTH.NAME} fs={2} fw={3} color="neutral5">
          PASS NAME
        </Div>
        <Div
          width={PASSES_COLUMNS_WIDTH.CATEGORY}
          fs={2}
          fw={3}
          color="neutral5"
        >
          CATEGORY
        </Div>
        <Div width={PASSES_COLUMNS_WIDTH.PRICE} fs={2} fw={3} color="neutral5">
          PRICE
        </Div>
        <Div
          width={PASSES_COLUMNS_WIDTH.WHO_HAS_ACCESS}
          fs={2}
          fw={3}
          color="neutral5"
        >
          WHO HAS ACCESS
        </Div>
        <Div
          width={PASSES_COLUMNS_WIDTH.ACTIONS}
          fs={2}
          fw={3}
          color="neutral5"
          display="row.flex-end"
        >
          ACTIONS
        </Div>
      </Div>
      <CardPasses />
      <BottomButtons />
    </Div>
  </Div>
);

export default decorate(Layout);
