import createNumberMask from "text-mask-addons/dist/createNumberMask";

const getMask = (format, { prefix, suffix }) => {
  const decimalLimit = (format.split(".")[1] || "").replace(/[\D]/, "").length;
  return createNumberMask({
    prefix,
    suffix,
    allowDecimal: Boolean(decimalLimit),
    decimalLimit,
    allowNegative: true
  });
};

export default getMask;
