import React from "react";
import { put, takeEvery, all, fork, select, call } from "redux-saga/effects";
import * as R from "ramda";

import { actions } from "./model";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { registerError } from "redux/modules/errors/actions";

import {
  getters as SelectItemsGetters,
  actions as SelectItemsActions
} from "PortalHub/CreateOrderRequest/SelectItems/model";
import { userId as getUserId } from "redux/modules/user/selectors";
import { portalUser as getPortalUser } from "redux/modules/portal/user/selectors";

import ItemDetailsModal from "Orders/ItemDetailsModal/View";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { push } from "react-router-redux";

import { actions as SearchBarActions } from "ui-kit/SearchBar";

import { makeFuture } from "utils/General/sagas";

const showModifyItemdModal = function*({ item }) {
  const handleIssuance = makeFuture();
  const color = yield select(SelectItemsGetters.highlightColor);

  yield put(
    showModal({
      content: (
        <ItemDetailsModal
          buttonLabel="Update Cart"
          itemId={item.id}
          variantId={item.id}
          selectedQuantity={item.quantity}
          selectedPriceId={item.priceId}
          questions={item.questions}
          color={color}
          disableChangingPrice={true}
          onSave={newItem =>
            handleIssuance.done({ type: "done", item: newItem, oldItem: item })
          }
          showInternalQuestions={false}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleIssuance.onRealized);
};

const handleModifyItemModal = function*({
  payload: {
    item: { category, categoryId, name, ...item }
  }
}) {
  const result = yield call(showModifyItemdModal, { item });

  if (result.type === "done") {
    yield all([
      put(hideModal),
      put(
        actions.modifyItem({
          item: result.item,
          category,
          categoryId,
          name,
          oldItem: result.oldItem
        })
      )
    ]);
  }
};

const submitForm = function*({ payload: { total, items, params } }) {
  const userId = yield select(getUserId);
  const portalUser = yield select(getPortalUser);
  const isViewingAsAccount = R.pathEq(["active_view", "type"], "account")(
    portalUser
  );
  const accountId = isViewingAsAccount
    ? R.path(["active_view", "id"], portalUser)
    : null;

  // eslint-disable-next-line no-unused-vars
  const data = {
    total: total,
    submittingUserId: userId,
    submittingGroupId: accountId,
    lineItems: items
  };

  try {
    const order_number = 111;
    /*
    TODO: update this endpoint to complete the checkout:
    const {
      payload: { order_number }
    } = yield call(apiCall, {
        method: "post",
        url: `/sites/site/portal/page/checkout/${pageId}`,
        data
      });
    */

    yield all([
      put(SelectItemsActions.setRequestCreatedId(order_number)),
      put(actions.clearItemsInCart()),
      put(
        push(
          `/hubs/${params.portalSlug}/${params.portalId}/people/${params.recordTypeId}/requests/confirmation`
        )
      )
    ]);
  } catch (error) {
    yield all([
      put(actions.setSaving(false)),
      put(
        registerError([
          {
            system: error,
            user: "An error occurred processing your order"
          }
        ])
      )
    ]);
  }
};

const checkout = function*({ payload: { params } }) {
  yield all([
    put(SearchBarActions.setSearchTerm("")),
    put(
      push(
        `/hubs/${params.portalSlug}/${params.portalId}/people/${params.recordTypeId}/requests/cart`
      )
    )
  ]);
};

const watchSubmitForm = function*() {
  yield takeEvery(actions.submitForm.type, submitForm);
};

const watchHandleModifyItemModal = function*() {
  yield takeEvery(actions.handleModifyItemModal.type, handleModifyItemModal);
};

const watchCheckout = function*() {
  yield takeEvery(actions.checkout.type, checkout);
};

const rootSaga = function*() {
  yield all([
    fork(watchHandleModifyItemModal),
    fork(watchCheckout),
    fork(watchSubmitForm)
  ]);
};

export default rootSaga;
