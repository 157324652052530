import MoreInfoModal from "components/Global/CRM/Modals/MoreInfoModal";
import { contact } from "redux/modules/contacts/profile/selectors";
import { connect } from "react-redux";
import { eventDetails } from "redux/modules/event/selectors";

const mapStateToProps = (state, ownProps) => ({
  data: contact(state, ownProps.contactId),
  eventDetails: eventDetails(state)
});

export default connect(mapStateToProps)(MoreInfoModal);
