import React from "react";

import NotFound from "components/Event/FormsV2/NotFound";
import PasswordField from "components/Global/Inputs/Password";
import { get } from "lodash";
import PasswordChecklist from "react-password-checklist";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

const View = CSSModules(
  ({
    portal,
    eventDetails,
    isFetching,
    errorMessages,
    handleSubmit,
    search,
    onTryAgain,
    onEmailChange,
    onFirstNameChange,
    onLastNameChange,
    onPasswordChange,
    processing,
    userExists,
    isVirtualUser,
    searchingUsers,
    firstName,
    lastName,
    email,
    password,
    error,
    location,
    isValid
  }) => {
    if (errorMessages.length || (!isFetching && !portal.id)) {
      return <NotFound />;
    }
    const allowNewUsers = portal.event.enable_portal_signup;

    // @TODO: Remove the padding that is set on the logoContainer since
    // we are now using an image URL from the db
    const actionButton = (
      <button
        type="submit"
        styleName={!isValid ? "disabledButton" : "button"}
        disabled={!isValid || processing}
      >
        {processing ? (
          <div styleName="buttonInner">
            <i className="fa fa-circle-o-notch" styleName="buttonLoadingIcon" />
            {userExists === true && isVirtualUser === false ? (
              <span>Logging in...</span>
            ) : (
              <span>Processing...</span>
            )}
          </div>
        ) : (
          <div styleName="buttonInner">
            {userExists === true && isVirtualUser === false ? (
              <span>Continue to portal</span>
            ) : (
              <span>Continue to portal</span>
            )}
          </div>
        )}
      </button>
    );

    return (
      <div styleName="wrapper">
        <div
          styleName="background"
          style={{
            backgroundImage: `url(${get(portal, "background_image_url")})`
          }}
        />
        <div styleName="container">
          <div styleName="bodyTop">
            <div styleName="logoContainer">
              <img
                styleName="logoImage"
                src={eventDetails.logo_image_url}
                alt=""
              />
            </div>
            <div styleName="bodyContent">
              <div styleName="bodyContentInner">
                <div styleName="details">
                  <div styleName="superText">Welcome to</div>
                  <div>{portal.title}</div>
                </div>
                <div styleName="form">
                  <form
                    styleName="formWrapper"
                    method="post"
                    onSubmit={handleSubmit}
                  >
                    <div styleName="fieldWrapper">
                      <div styleName="inputWrapper">
                        <div styleName="inputGroup">
                          <div styleName="inputLabel">Email</div>
                          <input
                            autoFocus
                            name="email"
                            onChange={onEmailChange}
                            placeholder="your_email_address@email.com"
                            required
                            styleName="input"
                            type="email"
                            value={email}
                            disabled={processing}
                          />
                          {searchingUsers ? (
                            <i
                              className="fa fa-circle-o-notch"
                              styleName="processingIcon"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        {userExists === true ||
                        (userExists === false && allowNewUsers) ? (
                          <div styleName="inputGroup">
                            <div styleName="inputLabel">Password</div>
                            <PasswordField
                              className={css.input}
                              disabled={processing}
                              name="password"
                              onChange={onPasswordChange}
                              placeholder={
                                userExists === false || isVirtualUser === true
                                  ? "Create your password"
                                  : "Enter your password"
                              }
                              required
                              value={password}
                            />

                            {userExists === false || isVirtualUser === true ? (
                              <div
                                style={{
                                  marginTop: "5px",
                                  marginBottom: "10px",
                                  fontSize: "14px",
                                  color: "#222"
                                }}
                              >
                                <PasswordChecklist
                                  iconSize={12}
                                  rules={[
                                    "length",
                                    "specialChar",
                                    "number",
                                    "capital"
                                  ]}
                                  minLength={8}
                                  value={password}
                                />
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          ""
                        )}
                        {userExists === false ? (
                          allowNewUsers ? (
                            <div>
                              <div styleName="inputGroup">
                                <div styleName="inputLabel">First Name</div>
                                <input
                                  name="firstName"
                                  onChange={onFirstNameChange}
                                  required
                                  styleName="input"
                                  type="text"
                                  value={firstName}
                                  disabled={processing}
                                />
                              </div>
                              <div styleName="inputGroup">
                                <div styleName="inputLabel">Last Name</div>
                                <input
                                  name="lastName"
                                  onChange={onLastNameChange}
                                  required
                                  styleName="input"
                                  type="text"
                                  value={lastName}
                                  disabled={processing}
                                />
                              </div>
                            </div>
                          ) : (
                            <div styleName="message">
                              Sorry, we could not find a record for this email.
                              Contact your event administrator if you feel this
                              is a mistake.
                              <br />
                              <a
                                styleName="actionText"
                                onClick={onTryAgain}
                                tabIndex="0"
                              >
                                Try Again
                              </a>
                            </div>
                          )
                        ) : (
                          ""
                        )}
                      </div>
                      {userExists === null ? (
                        <div styleName="nextStepButton" onClick={search}>
                          Next
                        </div>
                      ) : (
                        ""
                      )}
                      {userExists === true ||
                      (userExists === false && allowNewUsers) ? (
                        <div styleName="actions">
                          <div>{actionButton}</div>
                          {userExists === true ? (
                            <div>
                              <a
                                href={`/forgot?email=${email}`}
                                styleName="actionForgotPassword"
                                rel="noopener noreferrer"
                                target="_blank"
                              >
                                Forgot your password?
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {error ? <div styleName="error">{error}</div> : ""}
                      {location.query.message ? (
                        <div styleName="message">{location.query.message}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          styleName="branding"
          href={`https://www.lennd.com/?source=portal_login&portal_id=${portal.id}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <img
            src="https://d2dks4tzxs6xee.cloudfront.net/img/powered-by-lennd.svg"
            styleName="brandingImg"
            alt=""
          />
        </a>
      </div>
    );
  },
  css
);

export default View;
