import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function createFulfillment(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post(getState().user.user.credentials, data);
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding fulfillment"
          }
        ])
      );
    }
  };
}

export function cancelFulfillment(fulfillmentId) {
  return async (dispatch, getState) => {
    try {
      return await api.delete(getState().user.user.credentials, fulfillmentId);
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred cancelling fulfillment"
          }
        ])
      );
    }
  };
}
