/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { AddIcon, Text4, SuperFilledButton, Div } from "components/Base";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Loading from "ui-kit/Loading";

import { getIntakeForm } from "../selectors";
import { getters as recordTypeModalGetters } from "ui-kit/RecordTypeModal/model";
import { actions, getters } from "../model";
import { isConferenceTemplate } from "redux/modules/event/selectors";

import { MEDIA_CONTENT_INSTANCE_ID } from "ui-kit/MediaContentModal/constants";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

import AutoAssignItems from "./Panels/AutoAssignItems";
import SellItems from "./Panels/SellItems";
import Override from "./Panels/Override";
import GuestLists from "./Panels/GuestLists";
import Appearance from "./Panels/Appearance";
import Community from "./Panels/Community";
import CollectDocuments from "./Panels/CollectDocuments";
import ShareFiles from "./Panels/ShareFiles";
import StaffPersonnel from "./Panels/StaffPersonnel";
import Onboard from "./Panels/Onboard";
import Forms from "./Panels/Forms";
import AssignPages from "./Panels/AssignPages";
import CustomTasks from "./Panels/CustomTasks";

import EditRowModal from "./Modals/EditRowModal";
import TaskModal from "./Modals/TaskModal";
import IntakeSettingsModal from "Portal/IntakeSettingsModal/View";
import AssignModal from "./Modals/AssignModal";
import RecordTypeModal from "ui-kit/RecordTypeModal/View";
import SelectRequiredFieldsModal from "Modules/SelectRequiredFieldsModalRefactored/View";
import SelectPeopleTypesModal from "./Modals/SelectPeopleTypesModal";
import MediaContentModal from "ui-kit/MediaContentModal/View";
import SelectRequestableItemsModal from "./Modals/SelectRequestableItemsModal";
import SelectAutoAssignItemsModal from "./Modals/SelectAutoAssignItemsModal";
import SelectGuestListItemsModal from "./Modals/SelectGuestListItemsModal";
import CommunityHubSettingsModal from "EventLight/Common/Types/View/CommunityHubSettingsModal/View";
import UpdatePersonItemModal from "./Modals/UpdatePersonItemModal";
import UpdatePersonAutoAssignItemModal from "./Modals/UpdatePersonAutoAssignItemModal";
import UpdateAppearanceSettingsModal from "./Modals/UpdateAppearanceSettingsModal";
import UpdateGuestListSettingsModal from "./Modals/UpdateGuestListSettingsModal";
import AddContentModal from "EventLight/Content/AddContentModal/View";
import PageModal from "./Modals/PageModal";

import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

const decorate = R.compose(
  connect(
    state => ({
      isConferenceTemplate: isConferenceTemplate(state),
      selectedPermissionSetId: getters.selectedPermissionSetId(state),
      loadingPermissionSet: getters.loadingPermissionSet(state),
      showIntakeSettingsModal: getters.showIntakeSettingsModal(state),
      showAssignModal: getters.showAssignModal(state),
      intakeForm: getIntakeForm(state),
      showSelectRequiredFieldsModal: getters.showSelectRequiredFieldsModal(
        state
      ),
      showPeopleTypesModal: getters.showPeopleTypesModal(state),
      showRecordTypeModal: recordTypeModalGetters.showModal(state),
      showSelectRequestableItemsModal: getters.showSelectRequestableItemsModal(
        state
      ),
      showSelectAutoAssignItemsModal: getters.showSelectAutoAssignItemsModal(
        state
      ),
      showSelectGuestListItemsModal: getters.showSelectGuestListItemsModal(
        state
      ),
      showCommunitySettingsModal: getters.showCommunitySettingsModal(state),
      showUpdatePersonItemModal: getters.showUpdatePersonItemModal(state),
      showUpdatePersonAutoAssignItemModal: getters.showUpdatePersonAutoAssignItemModal(
        state
      ),
      showAppearanceSettingsModal: getters.showAppearanceSettingsModal(state),
      showGuestListSettingsModal: getters.showGuestListSettingsModal(state),
      showOpenTaskModal: getters.showOpenTaskModal(state),
      canViewMediaHub: selectFeatureFlag(flags.CAN_VIEW_MEDIA_HUB.NAME)(state)
    }),
    {
      init: actions.init,
      getIntakeDetails: actions.getIntakeDetails,
      addPermissionSet: actions.addPermissionSet,
      hideIntakeSettingsModal: () => actions.setShowIntakeSettingsModal(false),
      hideCommunitySettingsModal: () =>
        actions.setShowCommunitySettingsModal(false)
    }
  ),
  CSSModules(css)
);

const Layout = ({
  init,
  loadingPermissionSet,
  selectedPermissionSetId,
  moduleId,
  intakeForm,
  getIntakeDetails,
  selectedTypeName,
  selectedTypeId,
  addPermissionSet,
  canViewMediaHub,
  isConferenceTemplate,
  //
  showIntakeSettingsModal,
  showAssignModal,
  showSelectRequiredFieldsModal,
  showPeopleTypesModal,
  showRecordTypeModal,
  showSelectRequestableItemsModal,
  showSelectAutoAssignItemsModal,
  showSelectGuestListItemsModal,
  showCommunitySettingsModal,
  showUpdatePersonItemModal,
  showUpdatePersonAutoAssignItemModal,
  showAppearanceSettingsModal,
  showGuestListSettingsModal,
  showOpenTaskModal,
  //
  hideIntakeSettingsModal,
  hideCommunitySettingsModal
}) => {
  useEffect(() => {
    init({ selectedPermissionSetId, moduleId, selectedTypeId });
  }, []);

  if (loadingPermissionSet) {
    return (
      <div styleName="wrapper">
        <Loading />
      </div>
    );
  }

  if (!selectedPermissionSetId) {
    return (
      <Div flex={1} display="column.center.center" py={7}>
        <Text4 bold>No portal has been configured yet</Text4>
        <SuperFilledButton
          mt={4}
          bg="primary5"
          onClick={addPermissionSet}
          LeftIcon={AddIcon}
        >
          Configure Portal
        </SuperFilledButton>
      </Div>
    );
  }

  return (
    <div styleName="wrapper">
      {showRecordTypeModal ? <RecordTypeModal /> : null}
      {showPeopleTypesModal ? <SelectPeopleTypesModal /> : null}
      {showSelectRequiredFieldsModal ? (
        <SelectRequiredFieldsModal width={750} height={700} />
      ) : null}
      {showSelectRequestableItemsModal ? <SelectRequestableItemsModal /> : null}
      {showSelectAutoAssignItemsModal ? <SelectAutoAssignItemsModal /> : null}
      {showSelectGuestListItemsModal ? <SelectGuestListItemsModal /> : null}
      {showUpdatePersonItemModal ? <UpdatePersonItemModal /> : null}
      {showUpdatePersonAutoAssignItemModal ? (
        <UpdatePersonAutoAssignItemModal />
      ) : null}
      {showAppearanceSettingsModal ? <UpdateAppearanceSettingsModal /> : null}
      {showGuestListSettingsModal ? <UpdateGuestListSettingsModal /> : null}
      <MediaContentModal instanceId={MEDIA_CONTENT_INSTANCE_ID} />
      <EditRowModal />
      {showOpenTaskModal ? <TaskModal /> : null}
      {showAssignModal && <AssignModal />}
      {showIntakeSettingsModal && (
        <IntakeSettingsModal
          hideModal={hideIntakeSettingsModal}
          permissionSetId={selectedPermissionSetId}
          fetchPermissionSets={getIntakeDetails}
          intakeForm={intakeForm}
          selectedTypeName={selectedTypeName}
        />
      )}
      {showCommunitySettingsModal ? (
        <CommunityHubSettingsModal
          recordTypeId={selectedTypeId}
          hideModal={hideCommunitySettingsModal}
        />
      ) : null}
      <PageModal />
      <Onboard />
      <CustomTasks />
      <Forms />
      <CollectDocuments />
      {moduleId !== STANDARD_MODULES.contacts.id && !isConferenceTemplate ? (
        <StaffPersonnel
          {...{
            moduleId,
            selectedTypeId,
            selectedPermissionSetId
          }}
        />
      ) : null}
      {moduleId !== STANDARD_MODULES.contacts.id && !isConferenceTemplate ? (
        <GuestLists />
      ) : null}
      {/* <AutoAssignItems /> */}
      {/* <SellItems /> */}
      <ShareFiles />
      <AssignPages />
      <Appearance />
      {/* <Override /> */}
      {canViewMediaHub ? <Community /> : null}
      <AddContentModal />
    </div>
  );
};

export default decorate(Layout);
