import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as flags from "utils/feature-flags";
import OrganizationHeader from "./OrganizationHeader";

import { hideModal, showModal } from "redux/modules/modal/actions";
import { organization } from "redux/modules/organization/selectors";
import { selectFeatureFlag } from "@flopflip/react-redux";

function mapStateToProps(state) {
  return {
    organization: organization(state),
    isLenndAdmin: selectFeatureFlag(flags.IS_LENND_ADMIN.NAME)(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal,
      hideModal
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationHeader);
