import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { actions } from "../model";
import { getTimezones, getIniTimezone } from "../selectors";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { Input, DateTimeInput, Dropdown } from "ui-kit/Form/View";
import { FIELDS, DATE_FORMAT } from "../constants";
import NextButton from "./NextButton";
import { Div } from "components/Base";
import { Label } from "EventLight/Common/FieldLayout";
import { getters as FormGetters } from "ui-kit/Form/model";

const decorate = R.compose(
  connect(
    state => ({
      timezones: getTimezones(state),
      fields: FormGetters.fields(state),
      iniTimezone: getIniTimezone(state)
    }),
    {
      toggleEvent: actions.toggleEvent
    }
  ),
  CSSModules(css)
);

const YourEvent = ({ timezones, fields, iniTimezone }) => (
  <div styleName="pageWrapper">
    <div styleName="title">Let's create your first event</div>
    {/* <div styleName="label">Explore Lennd's features in your test event.</div> */}
    <div styleName="fieldWrapper">
      <div styleName="label">What is your event's name?</div>
      <Input
        fieldId={FIELDS.EVENT_NAME}
        iniValue=""
        placeholder="Event Name"
        style={{ width: "100%" }}
        size="big"
      />
    </div>
    <div styleName="fieldWrapper" style={{ display: "flex" }}>
      <Div display="column" flex={1}>
        <Label tooltip="The date and time that your event starts at">
          Starts At
        </Label>
        <DateTimeInput
          fieldId={FIELDS.EVENT_START_AT}
          dateFormat={DATE_FORMAT}
          timezone={R.pathOr("", [FIELDS.EVENT_TIMEZONE, "value", 0], fields)}
          minDate="today"
        />
      </Div>
      <Div ml={5} display="column" flex={1}>
        <Label tooltip="The date and time that your event ends at">
          Ends At
        </Label>
        <DateTimeInput
          fieldId={FIELDS.EVENT_END_AT}
          dateFormat={DATE_FORMAT}
          timezone={R.pathOr("", [FIELDS.EVENT_TIMEZONE, "value", 0], fields)}
          minDate="today"
        />
      </Div>
    </div>
    <div styleName="fieldWrapper" style={{ marginBottom: "30px" }}>
      <Label>Timezone</Label>
      <Dropdown
        fieldId={FIELDS.EVENT_TIMEZONE}
        width="100%"
        options={timezones}
        size="big"
        usePortal
        iniValue={iniTimezone}
        clearable={false}
      />
    </div>
    {/*
    <div styleName="fieldWrapper">
      <div styleName="label">Add some collaborators? (optional)</div>
      <Input
        fieldId={FIELDS.COLLABORATOR_EMAIL_1}
        iniValue=""
        placeholder="Ex. ben@gmail.com"
        style={{ width: "100%" }}
        type="email"
        size="big"
      />
      <Input
        fieldId={FIELDS.COLLABORATOR_EMAIL_2}
        iniValue=""
        placeholder="Ex. ben@gmail.com"
        style={{ width: "100%" }}
        type="email"
        mt={2}
        size="big"
      />
      <Input
        fieldId={FIELDS.COLLABORATOR_EMAIL_3}
        iniValue=""
        placeholder="Ex. ben@gmail.com"
        style={{ width: "100%" }}
        type="email"
        mt={2}
        size="big"
      />
    </div>
    */}

    <NextButton />
  </div>
);

export default decorate(YourEvent);
