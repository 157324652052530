import Panel from "components/Global/Module/Management/Panel";

class ModalPanel extends Panel {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
  }

  componentWillReceiveProps(props) {
    // override parent method
  }

  getCount() {
    return undefined;
  }

  goToModule = () => {
    const { router, eventId, parent } = this.props;
    return router.push({
      pathname: `/event/${eventId}/module/${parent.id}/grid`,
      query: {
        returnTo: `/event/${eventId}/inventory/needs`
      }
    });
  };
}

export default ModalPanel;
