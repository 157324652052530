import * as R from "ramda";
import { createHandlers, createModule } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  values: {},
  moduleId: ""
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    onSaveActivity: R.identity,
    selectModuleId: R.identity,
    init: R.identity,
    onChange: (state, action) => ({
      values: {
        ...state.values,
        [action.fieldId]: {
          ...state.values[action.fieldId],
          value: action.payload
        }
      }
    })
  }
});

const module = createModule(handlers);

const { actions, getters } = module;

export { actions, getters };

export default module;
