import { CONTACT_STANDARD_FIELD_IDS } from "components/Event/Contacts/constants";

export default [
  {
    id: CONTACT_STANDARD_FIELD_IDS.email,
    name: "Email",
    mappableFieldTypes: ["text", "email", "form-email", "contact-email"]
  },
  {
    id: CONTACT_STANDARD_FIELD_IDS.mobile_phone,
    name: "Mobile Phone",
    mappableFieldTypes: ["text", "phone"]
  },
  {
    id: CONTACT_STANDARD_FIELD_IDS.home_phone,
    name: "Home Phone",
    mappableFieldTypes: ["text", "phone"]
  },
  {
    id: CONTACT_STANDARD_FIELD_IDS.website,
    name: "Website",
    mappableFieldTypes: ["text"]
  },
  {
    id: CONTACT_STANDARD_FIELD_IDS.title,
    name: "Title",
    mappableFieldTypes: ["text"]
  },
  {
    id: CONTACT_STANDARD_FIELD_IDS.address_street_line_1,
    name: "Address Street",
    mappableFieldTypes: ["text"]
  },
  {
    id: CONTACT_STANDARD_FIELD_IDS.address_street_line_2,
    name: "Address Street (Line 2)",
    mappableFieldTypes: ["text"]
  },
  {
    id: CONTACT_STANDARD_FIELD_IDS.address_city,
    name: "Address City",
    mappableFieldTypes: ["text"]
  },
  {
    id: CONTACT_STANDARD_FIELD_IDS.address_state,
    name: "Address State",
    mappableFieldTypes: ["text"]
  },
  {
    id: CONTACT_STANDARD_FIELD_IDS.address_zip,
    name: "Address Zip",
    mappableFieldTypes: ["text"]
  },
  {
    id: CONTACT_STANDARD_FIELD_IDS.address_country,
    name: "Address Country",
    mappableFieldTypes: ["text", "countries"]
  },
  {
    id: CONTACT_STANDARD_FIELD_IDS.avatar_url,
    name: "Avatar URL",
    mappableFieldTypes: ["text"]
  },
  {
    id: CONTACT_STANDARD_FIELD_IDS.catering,
    name: "Catering",
    mappableFieldTypes: ["catering"]
  },
  {
    id: CONTACT_STANDARD_FIELD_IDS.catering_dietary_restrictions,
    name: "Catering Dietary Restrictions",
    mappableFieldTypes: ["catering-dietary-restrictions"]
  }
];
