import React from "react";

import { Div, Type4Card, Cards, SmallCheckbox } from "components/Base";
import ActionText from "components/Global/Module/Sidebar/ActionText";
import Well from "components/Global/Module/Sidebar/Well";
import SortItem from "components/Global/Module/Sidebar/Well/SortItem";
import SortPicker from "components/Global/Module/Sidebar/Popovers/Sort";

const noop = () => {};

const FieldCards = Cards(Type4Card, "RELATED_MODULE_FIELD");

const Field = ({ name, isVisible, onToggle }) => (
  <Div
    display="row.flex-start.center"
    p={1}
    bg={{ hover: "neutral0", default: "white" }}
    bc="neutral1"
    b={1}
    bra={1}
    mb={1}
    onClick={onToggle}
  >
    <SmallCheckbox mr={1} selected={isVisible} onClick={noop} />
    <Div fs={2} fw={3} color="gray7">
      {name}
    </Div>
  </Div>
);

const Scroller = ({ children, maxHeight }) => (
  <Div
    maxHeight={maxHeight}
    px={4}
    style={{
      overflowY: "auto"
    }}
  >
    {children}
  </Div>
);

const Column = ({ children, ...props }) => (
  <Div display="column" {...props}>
    {children}
  </Div>
);

const Title = ({ children }) => (
  <Div fw={3} mt={2} mb={2} color="gray7" px={4}>
    {children}
  </Div>
);

const VisibleFields = ({
  visibleFields,
  onFieldsReorder,
  emptyMessage,
  maxHeight,
  bg,
  //
  onAddSort,
  onRemoveSort,
  sortBy,
  allFields,
  //
  ...props
}) => (
  <Column
    bg={bg}
    style={
      bg
        ? undefined
        : {
            backgroundColor: "#F5F3F8"
          }
    }
    {...props}
  >
    <Title>Selected fields to show:</Title>
    <Scroller maxHeight={maxHeight}>
      {onAddSort ? (
        <Well
          style={{
            backgroundColor: "#642fa4",
            opacity: 1
          }}
        >
          <ActionText
            style={{
              padding: 0,
              margin: 5,
              marginBottom: 10
            }}
          >
            <i className="material-icons">filter_list</i> Sort records by
          </ActionText>
          {sortBy.map((sortCase, idx) => (
            <SortItem
              name={sortCase.name}
              direction={sortCase.direction}
              removeIcon={
                <i
                  role="button"
                  tabIndex="0"
                  className="material-icons"
                  style={{ cursor: "pointer" }}
                  onClick={() => onRemoveSort(idx)}
                >
                  close
                </i>
              }
            />
          ))}
          <SortPicker sortBy={sortBy} onChange={onAddSort} fields={allFields}>
            {sortBy.length ? (
              <ActionText
                style={{
                  padding: 0,
                  margin: "10px 5px 0px",
                  fontSize: 11
                }}
              >
                <i
                  className="material-icons"
                  style={{ marginRight: 1, fontSize: 11 }}
                >
                  add
                </i>Add sort by
              </ActionText>
            ) : (
              <ActionText>
                <i className="material-icons">add</i>Add sort by
              </ActionText>
            )}
          </SortPicker>
        </Well>
      ) : null}

      {visibleFields.length ? (
        <FieldCards cards={visibleFields} onReorder={onFieldsReorder} />
      ) : (
        <Div m={4}>{emptyMessage || "No fields selected"}</Div>
      )}
    </Scroller>
  </Column>
);

const AvailableFields = ({ allFields, emptyMessage, maxHeight, ...props }) => (
  <Column flex={1} px={2} {...props}>
    <Title>Available fields:</Title>
    <Scroller maxHeight={maxHeight}>
      {allFields.length ? (
        allFields.map(field => <Field key={field.id} {...field} />)
      ) : (
        <Div m={4}>{emptyMessage || "No fields available"}</Div>
      )}
    </Scroller>
  </Column>
);

export { VisibleFields, AvailableFields };
