import React from "react";
import {
  Div,
  MediumFilledButton,
  MoreIcon,
  AlarmIcon,
  PopoverMenu
} from "components/Base";
import { connect } from "react-redux";
import { actions, getters } from "../model";

const decorator = connect(
  (state, props) => ({
    remindersEnabled: getters.remindersEnabled(state, props)
  }),
  {
    disableReminders: () => actions.setRemindersEnabled(false),
    enableReminders: () => actions.setRemindersEnabled(true)
  }
);

export const ReminderdButton = ({
  remindersEnabled,
  enableReminders,
  disableReminders,
  style: userStyles = {}
}) => (
  <Div style={userStyles}>
    <PopoverMenu
      Label={({ onClick }) => (
        <MediumFilledButton
          bg="#f3f4f5"
          color="black"
          LeftIcon={AlarmIcon}
          RightIcon={MoreIcon}
          onClick={onClick}
        >
          Reminders {remindersEnabled ? "enabled" : "disabled"}
        </MediumFilledButton>
      )}
      menuItems={[
        ["Enable Reminders", enableReminders],
        ["Disable Reminders", disableReminders]
      ].filter(i => i)}
    />
  </Div>
);

export default decorator(ReminderdButton);
