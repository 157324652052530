import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import CircularProgress from "material-ui/CircularProgress";

@CSSModules(css)
class ActionButton extends Component {
  state = { loading: false };

  onClick = () => {
    if (this.state.loading) return true;
    this.setState({ loading: true });
    this.props.onClick();
  };

  render() {
    const { useTimer = true, icon = "add" } = this.props;

    return this.state.loading ? (
      <div styleName="actionButtonLoading">
        <CircularProgress
          style={{ marginRight: 12 }}
          size={15}
          color="white"
          mode="indeterminate"
        />
        <span styleName="hideForSmall">Loading</span>
      </div>
    ) : (
      <div
        role="link"
        tabIndex="0"
        styleName="actionButton"
        onClick={useTimer ? this.onClick : this.props.onClick}
      >
        {icon ? <i className="material-icons">{icon}</i> : null}
        <span styleName="hideForSmall">{this.props.label}</span>
      </div>
    );
  }
}

ActionButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

export default ActionButton;
