import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters, actions } from "EventLight/Agenda/SelectScheduleFieldsModal";
import {
  Div,
  Cards,
  DragIcon,
  CloseIcon,
  Text1,
  Text2,
  Text3,
  SegmentedLineIcon
} from "components/Base";
import { addS } from "utils/General";

const decorate = connect(
  state => ({
    selectedFields: getters.selectedFields(state)
  }),
  {
    disableSelectedField: actions.disableSelectedField,
    reorderFields: actions.reorderFields,
    removeAllSelectedFields: actions.removeAllSelectedFields
  }
);

const Field = ({
  id,
  disableSelectedField,
  name,
  is_auto_enabled,
  is_report_field
}) => (
  <Div bra={1} bg="white" shadow={1} width={1} p={2} mt={1}>
    <Div display="row.space-between.center" key={id}>
      <Div display="row.flex-start.center">
        <DragIcon />
        {is_report_field && <SegmentedLineIcon ml={2} color="warning5" />}
        <Text2 ml={2} color="black" bold>
          {name || "-"}
        </Text2>
      </Div>
      {!is_auto_enabled ? (
        <CloseIcon onClick={() => disableSelectedField({ fieldId: id })} />
      ) : null}
    </Div>
  </Div>
);

const FieldCards = Cards(Field, "FIELD");

const ShowFields = ({
  selectedFields,
  disableSelectedField,
  reorderFields,
  removeAllSelectedFields
}) => (
  <Div
    flex={1}
    bg="neutral0"
    p={4}
    height={530}
    style={{
      overflowY: "auto"
    }}
  >
    <Div display="row.space-between.center" mb={2}>
      <Text3 bold color="black">{`Showing ${R.length(
        selectedFields
      )} field${addS(R.length(selectedFields))}`}</Text3>
      <Text1
        color="primary7"
        underline
        onClick={() => removeAllSelectedFields()}
      >
        Remove all
      </Text1>
    </Div>
    <FieldCards
      cards={selectedFields}
      disableSelectedField={disableSelectedField}
      onReorder={orderedFields => reorderFields(orderedFields)}
    />
  </Div>
);

export default decorate(ShowFields);
