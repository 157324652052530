import React from "react";
import { connect } from "react-redux";
import { getters } from "Modules/ImportModal";
import { Div, Text3, DarkCloseButton } from "components/Base";
import { getHeaderTitle } from "Modules/ImportModal/selectors";

const decorate = connect(state => ({
  selectedPage: getters.selectedPage(state),
  headerTitle: getHeaderTitle(state)
}));

const Header = ({ handlers: { hideModal }, headerTitle }) => (
  <Div
    pl={7}
    pr={3}
    bb={1}
    bc="neutral2"
    color="neutral4"
    height={67}
    display="row.space-between.center"
    style={{
      flexShrink: 0
    }}
  >
    <Text3 color="gray7" bold>
      {headerTitle}
    </Text3>
    <DarkCloseButton size={30} onClick={hideModal} />
  </Div>
);

export default decorate(Header);
