import React, { Component } from "react";
import View from "./View";

class Controller extends Component {
  goToUsers = () =>
    this.props.router.push({
      pathname: `/organization/${this.props.params.orgId}/settings/users`
    });

  goToPermissions = () =>
    this.props.router.push({
      pathname: `/organization/${this.props.params.orgId}/settings/permissions`
    });

  render() {
    const { route } = this.props;
    const tabs = [
      [
        "organizationSettingsUsers",
        "Users",
        this.goToUsers,
        route.name === "organizationSettingsUsers"
      ],
      [
        "organizationSettingsPermissions",
        "Permissions",
        this.goToPermissions,
        route.name === "organizationSettingsPermissions"
      ]
    ];

    return (
      <View
        {...{
          tabs,
          activeTabValue: route.name
        }}
      />
    );
  }
}

export default Controller;
