import React, { useEffect } from "react";
import * as R from "ramda";
import { bindInstance } from "redux-mvc";

import { connect } from "react-redux";
import { noop } from "utils/General";

import { actions, getters } from "ui-kit/Tabs";

import { DarkTab, Div, MediumTextButton } from "components/Base";

const decorate = connect(
  (state, props) => ({
    selectedTab: getters.selectedTab(state, props)
  }),
  bindInstance({
    onSelectTab: actions.setSelectedTab,
    setIniSelected: actions.setIniSelected
  })
);

const Tabs = ({
  tabs,
  searchTerm = "",
  onClearSearch = noop,
  selectedTab = "",
  iniSelected = "",
  onSelectTab = noop,
  setIniSelected = noop,
  tabStyle = {},
  ...styleProps
}) => {
  useEffect(() => {
    setIniSelected({ iniSelected, tabs });
  }, [tabs]);
  return (
    <Div display="row.flex-start.flex-end" {...styleProps}>
      {searchTerm.length
        ? [
            <DarkTab mr={4} px={3} active activeColor="neutral8">
              Results for '{searchTerm}'
            </DarkTab>,
            <MediumTextButton color="primary5" onClick={() => onClearSearch()}>
              CLEAR SEARCH
            </MediumTextButton>
          ]
        : tabs.map(t => (
            <DarkTab
              {...tabStyle}
              key={t.id}
              active={t.id === selectedTab}
              onClick={t.id === selectedTab ? noop : () => onSelectTab(t.id)}
              data-cy={`ui-tab-${t.title}`}
            >
              {t.title}
              {R.isNil(t.count) ? "" : ` (${t.count})`}
            </DarkTab>
          ))}
    </Div>
  );
};

export default decorate(Tabs);
