import React from "react";
import { connect } from "react-redux";
import {
  getQuestionsData,
  getQuestionsDataFields,
  getAvailableAccounts
} from "HealthPass/HealthPassForm/selectors";
import { getters, actions } from "HealthPass/HealthPassForm/model";
import InputFields from "./InputFields";

const decorate = connect(
  state => ({
    isLoggedIn: getters.isLoggedIn(state),
    data: getQuestionsData(state),
    termsAccepted: getters.termsAccepted(state),
    questionsValues: getters.questionsValues(state),
    selectedAccountId: getters.selectedAccountId(state),
    questionsDataFields: getQuestionsDataFields(state),
    availableAccounts: getAvailableAccounts(state)
  }),
  {
    updateQuestionsValues: actions.updateQuestionsValues,
    updateSelectedAccountId: actions.updateSelectedAccountId
  }
);

const QuestionsPage = ({
  data,
  updateQuestionsValues,
  questionsValues,
  questionsDataFields
}) => (
  <>
    <InputFields
      data={data}
      values={questionsValues}
      updateValues={updateQuestionsValues}
      fields={questionsDataFields}
    />
  </>
);

export default decorate(QuestionsPage);
