import * as R from "ramda";

export const iniState = {
  packageItems: {},
  packageItemsLoading: false,
  itemPackages: [],
  selectingItems: ""
};

export const reducers = {
  setPackageItemsRequest: R.identity,
  setPackageItemsResponse: R.always({ selectingItems: "" }),
  closeSelectItemsModal: R.always({ selectingItems: "" })
};
