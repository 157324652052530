import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters, actions } from "Event/AddBulkItemsModal/model";
import { getGroupsOptions } from "Event/AddBulkItemsModal/selectors";
import { getters as itemCatalogItemsGetters } from "Event/ItemCatalog";
import { Div, Text3, TextAreaInput, Dropdown } from "components/Base";
import { COLORS } from "Event/AddBulkItemsModal/constants";

const zonesPlaceholder = `Add zones separate by line breaks. Separte zone name and code by commas.
Ex:
VIP Section,VIP
Backstage,BAK
Press Box,PRE`;

const OptionComponent = ({ color }) => (
  <Div
    height={22}
    display="row.center.center"
    style={{ cursor: "pointer" }}
    transition="fast"
  >
    <Div
      bra={1}
      bg={color}
      color="white"
      fw={3}
      height={1}
      width={1}
      display="row.center.center"
    >
      Color
    </Div>
  </Div>
);

const decorate = connect(
  state => ({
    typeName: getters.typeName(state),
    items: getters.items(state),
    groupsOptions: getGroupsOptions(state),
    groupId: getters.groupId(state),
    selectedColor: getters.selectedColor(state),
    isZones: itemCatalogItemsGetters.isZones(state)
  }),
  {
    setItems: actions.setItems,
    setGroupId: actions.setGroupId,
    setSelectedColor: actions.setSelectedColor
  }
);

const Body = ({
  typeName,
  setItems,
  items,
  groupId,
  groupsOptions,
  setGroupId,
  selectedColor,
  setSelectedColor,
  isZones
}) => {
  return (
    <Div
      height={370}
      width={1}
      bg="white"
      py={3}
      px={5}
      display="column.space-between"
      style={{ overflow: "visible" }}
    >
      <Div display="row.space-between.center">
        <Div flex={1}>
          <Text3 bold color="black" mb={1}>
            {isZones ? "Zone" : "Item"} Category*
          </Text3>
          <Dropdown
            usePortal
            onChange={({ value }) => setGroupId(value)}
            value={groupId}
            options={groupsOptions}
            isClearable={false}
            isSearchable
          />
        </Div>
        <Div width={150} ml={4}>
          <Text3 bold color="black" mb={1}>
            Color
          </Text3>
          <Div width={1}>
            <Dropdown
              styles={{
                valueContainer: (provided, state) => ({
                  ...provided,
                  backgroundColor: R.pathOr(
                    "black",
                    ["selectProps", "value", "value"],
                    state
                  ),
                  borderRadius: 2,
                  marginLeft: 4,
                  marginRight: 4,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                })
              }}
              onChange={({ value }) => setSelectedColor(value)}
              value={selectedColor}
              options={COLORS}
              isClearable={false}
              formatOptionLabel={OptionComponent}
              isSearchable={false}
            />
          </Div>
        </Div>
      </Div>
      <Div>
        <Text3 bold color="black">
          {isZones ? "Zones" : typeName}
        </Text3>
        <TextAreaInput
          width={1}
          value={items}
          onChange={setItems}
          placeholder={
            isZones
              ? zonesPlaceholder
              : "Add options separated by line breaks..."
          }
          style={{ resize: "none" }}
          height={230}
          mt={1}
          continuous
        />
      </Div>
    </Div>
  );
};

export default decorate(Body);
