export const NAMESPACE = "EventLightCommonTypesSettings";

export const FORMS_MODAL_SELECTOR_INSTANCE = "AssignForms";

export const FORMS_INSTANCE_ID = "formsInstanceId";

export const TASKS_INSTANCE_ID = "tasksInstanceId";

export const ASSIGNED_FORM_INSTANCE_ID = "assignedFormInstanceId";

export const PERSON_FORMS_INSTANCE_ID = "personFormsInstanceId";

export const ASSIGNED_DOCUMENT_INSTANCE_ID = "assignedDocumentInstanceId";

export const PERSON_DOCUMENTS_INSTANCE_ID = "personDocumentsInstanceId";

export const SHARE_FILE_ID = "shareFileInstanceId";

export const FORMS_REQUIRED = "formsRequired";

export const MEDIA_CONTENT_ID = "shareFileContent";

export const SELECT_REQUESTABLE_ITEMS_MODAL_ID = "selectRequestableItems";

export const SELECT_AUTO_ASSIGN_ITEMS_MODAL_ID = "selectAutoAssignItems";

export const SELECT_GUEST_LIST_ITEMS_MODAL_ID = "selectGuestListItems";

export const APPEARANCE_FORM_ID = "appearanceFormId";

export const GUEST_LIST_FORM_ID = "guestListFormId";

export const REQUEST_ITEM_SETTINGS_MODAL_FORM_ID =
  "requestItemSettingsModalFormId";

export const AUTO_ASSIGN_ITEM_SETTINGS_MODAL_FORM_ID =
  "autoAssignItemSettingsModalFormId";

export const EDIT_MODAL_DATA = {
  TITLE: "title",
  NAME: "name",
  ALIAS: "alias",
  REQUIRED: "required",
  DUE_DATE: "close_date",
  DESCRIPTION: "description",
  ORDER: "order",
  MAX_QUANTITY: "max_quantity",
  STATUS: "status",
  PRICE: "price",
  URL: "url",
  METHOD: "completion_method",
  DUEAT: "due_at"
};

export const CUSTOMIZE_APPEARANCE_MODAL_DATA = {
  TITLE: "title",
  MESSAGE: "message",
  BACKGROUND_IMAGE_URL: "hub_background_image_url",
  ENABLE_REPORTS: "enable_reports",
  ENABLE_ALERTS: "enable_alerts"
};

export const GUEST_LIST_SETTINGS_MODAL_DATA = {
  CONTACT_RECORD_TYPE_ID: "contact_record_type_id",
  IS_ENABLED: "is_enabled",
  IS_LOCKED: "is_locked",
  CLOSE_DATE: "close_date",
  VISIBLE_FIELDS: "visible_fields",
  REQUIRED_FIELDS: "required_fields",
  FIELD_ORDER: "field_order",
  VARIANT_LIMITS: "variant_limits",
  TITLE: "title",
  DESCRIPTION: "description"
};

export const DATE_FORMAT = "LLL";

export const GUEST_LIST_DATE_FORMAT = "LLL";

export const CARD_TYPES = {
  FORMS: "Forms",
  DOCUMENTS: "Document Requests",
  PERSON_FORMS: "personForms",
  PERSON_DOCUMENTS: "personDocuments",
  PAGES: "pages",
  AUTO_ASSIGN: "groupsAutoAssign",
  REQUESTABLE: "groupsRequestableItem",
  TASKS: "Tasks"
};

export const MAP_CARD_TYPES_ID = {
  [CARD_TYPES.FORMS]: "id",
  [CARD_TYPES.DOCUMENTS]: "id",
  [CARD_TYPES.PERSON_FORMS]: "id",
  [CARD_TYPES.PERSON_DOCUMENTS]: "id",
  [CARD_TYPES.PAGES]: "page_id",
  [CARD_TYPES.TASKS]: "id"
};

export const PERSON_SUBCARDS = {
  REQUESTABLE: "requestable-item",
  AUTO_ASSIGN: "auto-assign",
  FORMS: CARD_TYPES.PERSON_FORMS,
  DOCUMENTS: CARD_TYPES.PERSON_DOCUMENTS
};

export const STATUSES_COLORS = {
  pending: "#DCA57B",
  approved: "#457C70"
};

export const DUE_DATE = "dueDate";

export const TOGGLE = "toggle";

export const AUTO_ASSIGN_COLUMNS = [
  {
    name: "Item",
    value: "name",
    style: { flex: 1.4 },
    type: "text",
    showTooltip: true
  },
  {
    name: "Qty",
    value: "quantity",
    style: { flex: 0.5 },
    type: "text"
  },
  {
    name: "Type",
    value: "type_name",
    style: { flex: 1.2 },
    type: "text"
  },
  {
    name: "Status",
    value: "status",
    style: { flex: 0.8 },
    type: "status"
  },
  {
    name: "Actions",
    value: "actions",
    style: { width: 80, textAlign: "center" },
    type: "actions"
  }
];

export const EXPERIENCE_URL = {
  onboarding_users: "en/articles/4752699-importing-groups-and-people",
  assign_tasks: "en/articles/6106278-how-to-set-up-custom-tasks",
  collect_form_submissions: "en/articles/4745074-how-to-build-a-form",
  collect_documents: "en/articles/3769938-manage-your-file-requests",
  collect_guest_lists: "en/articles/4893450-creating-allocations",
  media_hub: "en/articles/6015598-configuring-your-media-hub"
};
