import { BEGIN, COMMIT, REVERT } from "redux-optimist";
import uuid from "node-uuid";
import { REQUEST, RECEIVE, CREATE, CREATE_COMPLETE, ERROR } from "./constants";
import * as selectors from "./selectors";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function createOrder(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: CREATE,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.post(getState().user.user.credentials, data);
      dispatch({
        type: CREATE_COMPLETE,
        payload: {
          ...data,
          eventbrite_order: result.eventbrite_order
        },
        optimist: { type: COMMIT, id: transactionId }
      });
      return result.order;
    } catch (error) {
      dispatch({
        type: ERROR,
        optimist: { type: REVERT, id: transactionId }
      });
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred creating Eventbrite order"
          }
        ])
      );
    }
  };
}

export function getOrders(data) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return false;
    try {
      dispatch({ type: REQUEST });
      const result = await api.get(getState().user.user.credentials, data);
      dispatch({ type: RECEIVE, payload: { orders: result.orders } });
      return result.orders;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting Eventbrite orders"
          }
        ])
      );
    }
  };
}
