export const EMPTY_PREFERENCES = {};

export const EMPTY_MENU = [];

export const EMPTY_SUBMISSION = {
  id: "",
  form_id: "",
  parent_submission_id: null,
  is_default: false,
  is_submitted: true,
  is_confirmation_sent: null,
  is_locked: true,
  is_deleted: null,
  submission_record_id: "",
  created_at: "",
  updated_at: "",
  values: {},
  related_order: {
    line_items: [],
    approval_status: "",
    fulfillment_status: "",
    integrations: []
  },
  collaborators: [],
  people_blocks: {},
  preferences: {},
  review: {},
  comments: [],
  module_record: {
    module: {},
    lookup_modules: [],
    related_modules: [],
    record: {},
    references: {},
    preferences: {},
    fields: {}
  },
  form: {
    id: "",
    org_id: "",
    event_id: "",
    type: "",
    scope: null,
    name: "",
    title: "",
    slug: "",
    internal_description: "",
    description: "",
    background_image_url: null,
    is_locked: true,
    show_share_link: false,
    lock_on_submission: false,
    require_login: true,
    close_date: "",
    is_subform: false,
    base_module_id: "",
    form_record_id: "",
    confirmation_message: null,
    is_deleted: null,
    created_at: "",
    updated_at: "",
    fields: [],
    form_users: [],
    preferences: {},
    fields_grouped_by_section: [],
    colaborator: {
      fields: []
    },
    event: {}
  }
};
