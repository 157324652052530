import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import ModuleSummaries from "components/Event/FormsV2/Overview/ModuleSummaries";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class Summary extends Component {
  render() {
    const { readOnly, counts } = this.props;
    return (
      <div styleName="container">
        <ModuleSummaries
          showBudget={!readOnly}
          showApprovals
          classNames={{
            fieldCountValue: css.fieldCountValue,
            fieldCount: css.fieldCount,
            fieldCountLabel: css.fieldCountLabel,
            fieldLabel: css.fieldLabel
          }}
          counts={counts}
        />
      </div>
    );
  }
}

Summary.propTypes = {
  styles: PropTypes.object,
  counts: PropTypes.array.isRequired,
  readOnly: PropTypes.bool
};

export default Summary;
