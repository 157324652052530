import React, { Fragment } from "react";
import { withRouter } from "react-router";
import MediaQuery from "react-responsive";
import { Div, CheckIcon, BigFilledButton } from "components/Base";
import Circle from "ui-kit/Circle";

const Small = props => <MediaQuery {...props} maxWidth={1023} />;
const Big = props => <MediaQuery {...props} minWidth={1024} />;

const Header = ({ mobile = false }) => (
  <Div
    height={75}
    display="row.center.center"
    width={1}
    bb={2}
    bc="neutral1"
    style={{ position: "relative" }}
  >
    <Div display="row.flex-start.center">
      <Div fs={[4, 4, 6]} bold ml={mobile ? 0 : 2} color="black">
        Confirmation
      </Div>
    </Div>
  </Div>
);

const Info = ({
  mobile = false,
  description,
  redirectLink,
  confirmationMessage,
  redirectButtonLabel = "View your requests",
  navigate
}) => (
  <Div
    display="column.flex-start.center"
    style={{
      overflowX: "hidden",
      height: mobile ? "calc(100% - 75px)" : "auto"
    }}
    width={mobile ? 1 : 1024}
    bg="white"
    bc="neutral1"
    br={1}
    bb={1}
    p={6}
  >
    <Circle
      size={90}
      display="row.center.center"
      bc="altA6"
      ba={3}
      bg="transparent"
      mt={5}
    >
      <CheckIcon size={50} color="altA6" />
    </Circle>
    <Div fs={1} color="neutral5" py={5} mb={3}>
      REQUEST SUBMITTED
    </Div>
    <Div
      fw={3}
      width={mobile ? 1 : 714}
      color="black"
      fs={5}
      textAlign="center"
    >
      <Div>{description}</Div>
      {confirmationMessage && confirmationMessage.length ? (
        <Div fs={3} fw={2} className="lennd-wysiwyg-content">
          <span
            dangerouslySetInnerHTML={{
              __html: confirmationMessage
            }}
          />
        </Div>
      ) : null}
    </Div>
    <BigFilledButton
      bg="warning2"
      width={252}
      color="black"
      mt={5}
      mb={3}
      onClick={() => navigate(redirectLink)}
    >
      {redirectButtonLabel}
    </BigFilledButton>
  </Div>
);

const Content = props => {
  return (
    <Fragment>
      <Small>
        <Div
          width={1}
          height={1}
          display="column"
          style={{ overflow: "hidden", position: "absolute" }}
        >
          <Header mobile />
          <Info mobile {...props} />
        </Div>
      </Small>
      <Big>
        <Div
          width={1}
          height={1}
          display="column"
          style={{ overflow: "hidden" }}
        >
          <Header />
          <Info {...props} />
        </Div>
      </Big>
    </Fragment>
  );
};

const Confirmation = ({
  description,
  router,
  redirectLink,
  redirectButtonLabel,
  confirmationMessage
}) => {
  const contentProps = {
    description,
    redirectLink,
    redirectButtonLabel,
    confirmationMessage,
    navigate: router.push
  };
  return (
    <Fragment>
      <MediaQuery minWidth={1025} maxWidth={1039}>
        <Div
          bg="white"
          display="column"
          bra={1}
          mb={3}
          style={{ flexShrink: 0 }}
        >
          <Content {...contentProps} />
        </Div>
      </MediaQuery>
      <MediaQuery minWidth={1040}>
        <Div
          bg="white"
          display="column"
          bra={1}
          mb={3}
          style={{
            flexShrink: 0,
            marginTop: "125px",
            boxShadow:
              "0px 7.43943px 17.3587px rgba(0, 0, 0, 0.0423), 0px 32.2375px 49.5962px rgba(0, 0, 0, 0.01)"
          }}
        >
          <Content {...contentProps} />
        </Div>
      </MediaQuery>
      <MediaQuery maxWidth={1024}>
        <Div
          width={1}
          height={1}
          bg="white"
          display="column"
          bra={0}
          mb={3}
          style={{ flexShrink: 0 }}
        >
          <Content {...contentProps} />
        </Div>
      </MediaQuery>
    </Fragment>
  );
};

export default withRouter(Confirmation);
