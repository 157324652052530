import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import moment from "moment";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const formats = ["MMMM Do, YYYY", "MMMM Do", "M/D/YYYY", "YYYY-MM-DD"];

@CSSModules(css)
export default class DateFormatSettings extends Component {
  @autobind
  onDateFormatChange(e) {
    this.props.updateDateSettings({
      ...this.props.settings,
      dateFormat: e.target.value
    });
  }

  render() {
    return (
      <div>
        <div className="input-wrapper">
          <label>Date Format</label>
          <select
            onChange={this.onDateFormatChange}
            value={this.props.settings.dateFormat}
            styleName="input"
          >
            {formats.map(format => (
              <option key={format} value={format}>
                {moment().format(format)}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}

DateFormatSettings.propTypes = {
  settings: PropTypes.object.isRequired,
  updateDateSettings: PropTypes.func.isRequired
};
