import * as R from "ramda";
import { createSelector } from "reselect";

import { getters } from "EventLight/Integrations/FuzionMappingModal";

import { LABELS } from "EventLight/Integrations/FuzionMappingModal/constants";

const getExhibitorFieldType = path => {
  if (R.startsWith("third_parties[].contact")(path)) {
    return LABELS.PERSON;
  }
  if (R.startsWith("third_parties[]")(path)) {
    return LABELS.GROUP;
  }
  if (R.startsWith("contacts[]")(path)) {
    return LABELS.PERSON;
  }
  return LABELS.GROUP;
};

const formatExhibitorField = ({
  field,
  values,
  toggledFieldId,
  fieldIndex,
  searchTerm
}) => {
  if (R.is(Array)(field)) {
    return {
      type: "list",
      path: `list_${R.path([0, "path"])(field)}`,
      name: null,
      moduleType: null,
      value: null,
      fields: R.map(f =>
        formatExhibitorField({
          field: f,
          values,
          toggledFieldId,
          fieldIndex,
          searchTerm
        })
      )(field)
    };
  }

  return {
    type: "field",
    path: field.path,
    name: field.name,
    display:
      searchTerm && searchTerm.length
        ? field.name.toLowerCase().includes(searchTerm)
        : true,
    moduleType: getExhibitorFieldType(field.path),
    mappedToFieldName: fieldIndex[values[field.path]],
    isToggled: toggledFieldId === field.path,
    value: values[field.path] || null,
    fields: []
  };
};

export const getExhibitorFields = createSelector(
  getters.mapping,
  getters.toggledFieldId,
  getters.fieldIndex,
  getters.searchTerm,
  (mapping, toggledFieldId, fieldIndex, searchTerm) => {
    const values = R.compose(
      R.propOr({}, "field_mapping"),
      R.prop("exhibitor")
    )(mapping);

    const formatted = R.compose(
      R.map(field =>
        formatExhibitorField({
          field,
          values,
          toggledFieldId,
          fieldIndex,
          searchTerm: searchTerm.toLowerCase()
        })
      ),
      R.propOr([], "fuzion_fields"),
      R.prop("exhibitor")
    )(mapping);

    return formatted;
  }
);

export const getExhibitorRecordTypes = createSelector(
  getters.mapping,
  mapping => {
    const values = R.compose(
      R.propOr({}, "record_type_mapping"),
      R.prop("exhibitor")
    )(mapping);

    return [
      {
        path: "exhibitor",
        name: "Exhibitor",
        type: LABELS.GROUP,
        value: values.exhibitor
      },
      {
        path: "exhibitor.contacts[]",
        name: "Exhibitor - Contact",
        type: LABELS.PERSON,
        value: values["exhibitor.contacts[]"]
      },
      {
        path: "exhibitor.contacts_inbound[]",
        name: "Exhibitor - Contact (Syncing in to Lennd)",
        type: LABELS.PERSON,
        value: values["exhibitor.contacts_inbound[]"]
      },
      {
        path: "exhibitor.third_parties[]",
        name: "Exhibitor - Third Parties",
        type: LABELS.GROUP,
        value: values["exhibitor.third_parties[]"]
      },
      {
        path: "exhibitor.third_parties[].contact",
        name: "Exhibitor - Third Parties - Contact",
        type: LABELS.PERSON,
        value: values["exhibitor.third_parties[].contact"]
      }
    ];
  }
);

const formatRecords = records =>
  R.map(id => ({
    label: records[id],
    value: id
  }))(R.keys(records));

export const getAccountFields = createSelector(
  getters.accountFields,
  formatRecords
);

export const getAccountRecordTypes = createSelector(
  getters.accountRecordTypes,
  formatRecords
);

export const getContactFields = createSelector(
  getters.contactFields,
  formatRecords
);

export const getContactRecordTypes = createSelector(
  getters.contactRecordTypes,
  formatRecords
);
