import PropTypes from "prop-types";
import React, { Component } from "react";
import Select from "components/Global/ReactSelect/TetherSelect";
import getLookupValueLabel from "utils/get-lookup-value-label";

class Picker extends Component {
  componentWillReceiveProps(props) {
    if (this.props.moduleId !== props.moduleId) {
      this.select.setState({ options: [] });
    }
  }

  getRecords = async query => {
    const { search, params, moduleId, primaryFieldId, noTether } = this.props;
    const { records } = await search(
      params.eventId,
      moduleId,
      query,
      primaryFieldId
    );
    const options = records.map(r => ({
      label: getLookupValueLabel(r, primaryFieldId),
      id: r.id,
      value: r
    }));
    return { options };
  };

  render() {
    return (
      <Select.Async
        cache={false}
        ref={c => {
          this.select = c;
          this.props.selectRef(c);
        }}
        autoFocus
        children={props => <Select {...props} />} // required to use TetherSelect component
        escapeClearsValue={false}
        loadOptions={this.getRecords}
        minimumInput={1}
        noTether={
          this.props.noTether === undefined ? true : this.props.noTether
        }
        onChange={this.props.selectRecord}
        openOnFocus
        placeholder="Search..."
        value={this.props.selectedRecordId}
        valueKey="id"
        isMulti={this.props.multi}
      />
    );
  }
}

Picker.defaultProps = {
  multi: true
};

Picker.propTypes = {
  moduleId: PropTypes.string.isRequired,
  noTether: PropTypes.bool,
  params: PropTypes.shape({
    eventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired,
  primaryFieldId: PropTypes.string.isRequired,
  search: PropTypes.func.isRequired,
  selectRecord: PropTypes.func.isRequired,
  selectRef: PropTypes.func.isRequired,
  selectedRecordId: PropTypes.string.isRequired
};

export default Picker;
