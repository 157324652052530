import * as R from "ramda";

import React from "react";

import { indexedColors } from "components/Base/Base/colorSpecs";

const Circle = ({
  size,
  onClick,
  children,
  bg = "neutral3",
  style,
  className
}) => (
  <div
    display="row.center.center"
    className={className}
    onClick={onClick}
    style={{
      backgroundColor: indexedColors[bg] || indexedColors.neutral3,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      ...style,
      width: size,
      height: size,
      borderRadius: "50%",
      cursor: onClick ? "pointer" : undefined
    }}
  >
    {children}
  </div>
);

const circles = {
  done: ({ size, style, className }) => (
    <Circle
      size={size}
      style={{
        backgroundColor: "white",
        border: `solid 4px ${indexedColors.success7}`,
        flexShrink: 0,
        ...style
      }}
      className={className}
    >
      <i
        className="material-icons"
        style={{
          fontSize: 18,
          color: indexedColors.success7,
          fontWeight: "bold"
        }}
      >
        check
      </i>
    </Circle>
  ),
  alert_error: ({ size, style, className }) => (
    <Circle
      size={size}
      style={{
        backgroundColor: indexedColors.danger6,
        flexShrink: 0,
        ...style
      }}
      className={className}
    >
      <i className="material-icons" style={{ fontSize: 18, color: "white" }}>
        priority_high
      </i>
    </Circle>
  ),
  alert: ({ size, style, className }) => (
    <Circle
      size={size}
      style={{
        backgroundColor: indexedColors.orange6,
        flexShrink: 0,
        ...style
      }}
      className={className}
    >
      <i className="material-icons" style={{ fontSize: 18, color: "white" }}>
        priority_high
      </i>
    </Circle>
  ),
  not_required: ({ size, style, className }) => (
    <Circle
      size={size}
      style={{
        backgroundColor: "white",
        border: `solid 4px ${indexedColors.gray7}`,
        flexShrink: 0,
        display: "block",
        ...style
      }}
      className={className}
    >
      <div
        style={{
          width: 26,
          height: 4,
          transform: "translate(-2px, 10px) rotateZ(-45deg)",
          backgroundColor: indexedColors.gray7
        }}
      ></div>
    </Circle>
  )
};

const StatusCircle = ({ status, size = 25, onClick, ...props }) =>
  R.propOr(circles.not_required, status, circles)({ size, onClick, ...props });

export default StatusCircle;
