import React, { Component } from "react";
import File from "./File";
import getValue from "utils/value-types/get-value/file";
import * as R from "ramda";
import { Div } from "components/Base";
import { isEqual } from "lodash";
import FileViewer from "components/Global/Modals/FileViewer";
import FileViewerWrapper from "components/Global/Modals/FileViewer/Wrapper";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { connect } from "react-redux";

const decorate = connect(
  null,
  {
    showModal,
    hideModal
  }
);

class FileCellFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  formatValue(props) {
    return getValue(props.value);
  }

  showFileViewer = (file, files) => {
    this.props.showModal({
      content: (
        <FileViewer
          start={files.indexOf(file)}
          files={files}
          isReadOnly={R.pathOr(false, [
            "dependentValues",
            "columnSettings",
            "isReadOnly"
          ])(this.props)}
        />
      ),
      wrapper: FileViewerWrapper
    });
  };

  render() {
    const { onFileClick } = this.props;
    const value = this.formatValue(this.props);
    const onClick = onFileClick || this.showFileViewer;

    return (
      <Div
        display="row.flex-start.center"
        style={{
          overflow: "hidden"
        }}
      >
        {value.map((file, index) => (
          <File
            onClick={() => onClick(file, value)}
            key={index}
            isClickable
            id={file.id}
            mimetype={file.mimetype}
            url={file.url}
            filename={file.filename}
          />
        ))}
      </Div>
    );
  }
}

export default decorate(FileCellFormatter);
