import * as R from "ramda";
import { withRouter } from "react-router";
import Layout from "./Layout";
import { createContext } from "redux-mvc";
import module from "Modules/AddEditColumnModal";

const decorate = createContext({
  module,
  options: {
    persist: false
  }
});

Layout.displayName = "OptionsModal";

export default R.compose(
  withRouter,
  decorate
)(Layout);
