import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function addContactTypeFormAssignment(data) {
  return async (dispatch, getState) => {
    try {
      const { payload } = await api.post(
        getState().user.user.credentials,
        data
      );
      return payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding contact type form assignment"
          }
        ])
      );
    }
  };
}

export function removeContactTypeFormAssignment(data) {
  return async (dispatch, getState) => {
    try {
      const { payload } = await api.delete(
        getState().user.user.credentials,
        data
      );
      return payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred removing contact type form assignment"
          }
        ])
      );
    }
  };
}
