import React, { Component } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import moment from "moment";

import { actions } from "Orders/Table";
import { getAllSelected, getIsRowSelected } from "Orders/Table/selectors";

import { withProps } from "utils/General";

import {
  Div,
  Text0,
  Text1,
  Text2,
  Text6,
  ControlledDataTable,
  SmallOutlineButton,
  PopoverMenu,
  FontIcon,
  BigAvatar
} from "components/Base";
import { StatusTag } from "utils/status-comps";
import CheckBox from "material-ui/Checkbox";

import toCurrencyString from "utils/value-types/to-string/currency";
import CanUserDo from "components/Global/Security/CanUserDo";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

const Header = withProps({
  py: 2,
  onClick: e => {
    e.preventDefault();
    e.stopPropagation();
  }
})(Div);

const decorateActions = connect(state => ({ canDo: canEventUserDo(state) }));

const Actions = ({
  showOrderModal,
  showDeleteOrder,
  archiveOrder,
  unarchiveOrder,
  isArchived,
  orderId,
  canDo
}) => {
  const menuItems = [];
  if (canDo(`${STANDARD_MODULE_IDS.orders}_read`)) {
    menuItems.push(["View", () => showOrderModal({ orderId })]);
  }
  if (canDo(`${STANDARD_MODULE_IDS.orders.id}_update`)) {
    if (isArchived) {
      menuItems.push(["Unarchive", () => unarchiveOrder({ orderId })]);
    } else {
      menuItems.push(["Archive", () => archiveOrder({ orderId })]);
    }
  }
  if (showDeleteOrder && canDo(`${STANDARD_MODULE_IDS.orders.id}_delete`)) {
    menuItems.push(["Delete", () => showDeleteOrder({ orderId })]);
  }
  return (
    <Div display="row.flex-end.center" width={1} pr={2}>
      <CanUserDo action={`${STANDARD_MODULE_IDS.orders}_read`}>
        <SmallOutlineButton
          onClick={() => showOrderModal({ orderId })}
          mr={1}
          style={{
            height: 26
          }}
        >
          <Div fs={2}> Open </Div>
        </SmallOutlineButton>
      </CanUserDo>
      {menuItems.length ? (
        <PopoverMenu
          Label={({ onClick }) => (
            <SmallOutlineButton onClick={onClick} py={1}>
              <FontIcon fs={3}> more_horiz </FontIcon>{" "}
            </SmallOutlineButton>
          )}
          menuItems={menuItems}
        />
      ) : (
        ""
      )}
    </Div>
  );
};

const DecoratedActions = decorateActions(Actions);

const StripeActions = ({ showOrderModal, orderNumber }) => {
  return (
    <Div display="row.flex-end" width={1}>
      <SmallOutlineButton
        onClick={() => showOrderModal({ orderNumber })}
        mr={1}
        style={{
          height: 26
        }}
      >
        <Div fs={2}> Open </Div>
      </SmallOutlineButton>
    </Div>
  );
};

const HeaderCheckbox = connect(
  state => ({ selected: getAllSelected(state) }),
  {
    onClick: () => actions.toggleSelectAll()
  }
)(({ selected, onClick }) => (
  <Div
    py={2}
    pl={2}
    onClick={e => {
      e.stopPropagation();
      onClick(e);
    }}
  >
    <CheckBox checked={selected} />
  </Div>
));

const CellCheckbox = connect(
  (state, props) => ({
    selected: getIsRowSelected(state, props)
  }),
  {
    selectRow: actions.selectRow
  }
)(({ selected, selectRow, original }) => (
  <Div pl={2} onClick={() => selectRow(original.id)}>
    <CheckBox checked={selected} />
  </Div>
));

const ORDER_COLUMNS = {
  select: {
    id: "select",
    Header: <HeaderCheckbox />,
    accessor: "selected",
    width: 50,
    Cell: props => <CellCheckbox {...props} />
  },
  order: {
    id: "order",
    Header: <Header pl={2}>Order</Header>,
    accessor: "orderNumber",
    width: 80,
    Cell: ({ value, original }) => (
      <Text2
        onClick={() => original.handlers.showOrderModal({ orderNumber: value })}
        color={"primary7"}
        bold
        pl={2}
      >
        #{value}
      </Text2>
    )
  },
  contact: {
    id: "contact",
    Header: <Header>Order Contact</Header>,
    accessor: "contact",
    Cell: ({ value, original }) => (
      <Text2
        underline={
          original.showContactModal ? { default: false, hover: true } : false
        }
        bold
        onClick={() => original.handlers.showContactModal(value.id)}
      >
        {value.name}
      </Text2>
    )
  },
  account: {
    id: "account",
    Header: <Header>Account</Header>,
    accessor: "account",
    Cell: ({ value, original }) => (
      <Text2
        underline={
          original.showAccountModal ? { default: false, hover: true } : false
        }
        bold
        onClick={() => original.handlers.showAccountModal(value.id)}
      >
        {value.name}
      </Text2>
    ),
    settings: {
      moduleId: "575edc78-bfb4-4efa-8225-0fa0a93a1ff0",
      fieldId: "name"
    }
  },
  approval: {
    id: "approvalStatus",
    Header: <Header>Approval Status</Header>,
    accessor: "approvalStatus",
    Cell: ({ value }) => (
      <Div>
        <StatusTag status={value} />
      </Div>
    )
  },
  fulfillment: {
    id: "fulfillmentStatus",
    Header: <Header>Fulfillment Status</Header>,
    accessor: "fulfillmentStatus",
    Cell: ({ value }) => (
      <Div>{value ? <StatusTag status={value} /> : null}</Div>
    )
  },
  payment: {
    id: "paymentStatus",
    Header: <Header>Payment Status</Header>,
    accessor: "paymentStatus",
    width: 120,
    Cell: ({ value }) =>
      R.or(R.isEmpty(value), R.isNil(value)) ? (
        "-"
      ) : (
        <Text2 color="neutral7" pl={2}>
          {value}
        </Text2>
      )
  },
  total: {
    id: "total",
    Header: <Header>Total</Header>,
    accessor: "total",
    width: 60,
    Cell: ({ value }) => (
      <Text2 color="neutral7" bold pl={2}>
        {toCurrencyString(value)}
      </Text2>
    )
  },
  actions: {
    id: "actions",
    name: "Actions",
    width: 140,
    Header: (
      <Header display="row.flex-end" pr={2}>
        Actions
      </Header>
    ),
    accessor: R.prop("handlers"),
    Cell: ({ value, original }) => {
      return (
        <DecoratedActions
          {...value}
          isArchived={original.isArchived}
          orderId={original.orderId}
        />
      );
    }
  }
};

const INVOICE_COLUMNS = {
  invoice: {
    id: "invoice",
    Header: <Header pl={2}>Invoice</Header>,
    accessor: "invoiceNumber",
    width: 80,
    Cell: ({ value, original }) => (
      <Text2
        onClick={() => original.handlers.showOrderModal({ orderNumber: value })}
        color={"primary7"}
        bold
        pl={2}
      >
        #{value}
      </Text2>
    )
  },
  customer: {
    Header: <Header pl={2}>Customer/Contact</Header>,
    sortable: true,
    accessor: "customer",
    minWidth: 120,
    Cell: ({ value }) => (
      <Div display="row.flex-start.center" pl={2}>
        <BigAvatar
          photoURL={value.photoUrl}
          bg="neutral3"
          text={value.name || ""}
          singleInitial
        />
        <Div ml={2}>
          <Text2 bold width={1}>
            {value.name}
          </Text2>
          <Div display="row.flex-start.center">
            <Text0 uppercase>
              {[value.contactType, value.groupName]
                .filter(v => v && v.length)
                .join(" · ")}
            </Text0>
          </Div>
        </Div>
      </Div>
    )
  },
  createdAt: {
    id: "createdAt",
    Header: <Header>Created At</Header>,
    accessor: "created",
    width: 200,
    Cell: ({ value }) => (
      <Text1>
        {moment(new Date(value.createdAt)).format("MM/DD/YYYY")}
        {R.isEmpty(value.by) ? "" : " by "}
        {value.by}
      </Text1>
    )
  },
  dueDate: {
    id: "dueDate",
    width: 100,
    Header: <Header>Due Date</Header>,
    accessor: "dueDate",
    Cell: ({ value }) => (
      <Text2>{moment(new Date(value)).format("MMM D YYYY")}</Text2>
    )
  },
  notes: {
    id: "notes",
    Header: <Header>Notes/Messages</Header>,
    accessor: "notes",
    width: 200,
    Cell: ({ value }) => (
      <Text1>
        {R.slice(0, 35, value)}
        {R.length(value) > 35 ? "..." : ""}
      </Text1>
    )
  }
};

const TRANSACTION_COLUMNS = {
  amount: {
    ...ORDER_COLUMNS.total,
    width: 70,
    id: "amount",
    Header: <Header pl={2}>Amount</Header>
  },
  status: {
    ...ORDER_COLUMNS.payment,
    id: "status",
    Header: <Header>Status</Header>
  },
  transactionId: {
    id: "transactionId",
    Header: <Header>Stripe Transaction ID</Header>,
    accessor: "transactionId",
    width: 250,
    Cell: ({ value }) => <Text1 bold>{value}</Text1>
  },
  transactedAt: {
    id: "transactedAt",
    width: 150,
    Header: <Header>Transacted At</Header>,
    accessor: "transactedAt",
    Cell: ({ value }) => (
      <Text2>{moment(new Date(value)).format("h:mm a MM/DD/YYYY")}</Text2>
    )
  },
  stripeActions: {
    id: "stripeActions",
    name: "Actions",
    width: 140,
    Header: (
      <Header display="row.flex-end" pr={2}>
        Actions
      </Header>
    ),
    accessor: R.prop("handlers"),
    Cell: ({ value, original }) => (
      <StripeActions
        {...value}
        status={original.paymentStatus}
        orderNumber={original.invoiceNumber}
        transactionId={original.transactionId}
      />
    )
  }
};

export const COLUMNS = R.mergeAll([
  ORDER_COLUMNS,
  INVOICE_COLUMNS,
  TRANSACTION_COLUMNS
]);

const defaultColumns = R.props(
  [
    "select",
    "order",
    "contact",
    "account",
    "approval",
    "payment",
    "fulfillment",
    "total",
    "actions"
  ],
  COLUMNS
);

const decorate = connect(
  null,
  {
    setRows: actions.setRows
  }
);

class OrdersTable extends Component {
  constructor(props) {
    super(props);

    this.props.setRows(props.rows);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.rows !== this.props.rows) {
      this.props.setRows(nextProps.rows);
    }
  }

  render() {
    const { columns = defaultColumns, rows = [] } = this.props;
    return (
      <ControlledDataTable
        columns={columns}
        rows={rows}
        EmptyState={
          <Div display="column.center.center">
            <Text6 bold>There are no items yet.</Text6>
          </Div>
        }
        PaginationBarComp={() => <div />}
      />
    );
  }
}

export default decorate(OrdersTable);
