import * as R from "ramda";
import { createSelector } from "reselect";
import { getters } from "Portal/PortalPreviewProfile/model";
import { TABS } from "Portal/PortalPreviewProfile/constants";

export const getContent = createSelector(
  getters.mode,
  getters.lightMode,
  getters.darkMode,
  (mode, lightMode, darkMode) => {
    return R.propOr(lightMode, mode)({
      [TABS.LIGHT_MODE]: lightMode,
      [TABS.DARK_MODE]: darkMode
    });
  }
);

export const hasForm = createSelector(
  getters.editProfileFormId,
  formId => Boolean(formId)
);
