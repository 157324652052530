import React from "react";
import { connect } from "react-redux";
import { Div } from "components/Base";

import Body from "./Body";
import SliderSidebarLayout from "ui-kit/SliderSidebar/View";
import { getters as sliderSidebarGetters } from "ui-kit/SliderSidebar/model";
import Loading from "./Row/EmptyState";

import { getters } from "../model";

const decorate = connect(
  (state, props) => ({
    loading: getters.loading(state, props),
    isDrawerOpen: sliderSidebarGetters.isDrawerOpen(state, {
      instanceId: props.instanceId
    })
  }),
  null
);

const Notification = ({ instanceId, loading, isDrawerOpen }) => (
  <SliderSidebarLayout position="right" instanceId={instanceId}>
    {isDrawerOpen &&
      (loading ? (
        <Div display="row.center.center" height={1}>
          <Loading>Loading notifications...</Loading>
        </Div>
      ) : (
        <Body instanceId={instanceId} />
      ))}
  </SliderSidebarLayout>
);

export default decorate(Notification);
