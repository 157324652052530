import React from "react";
import {
  Div,
  Text5,
  BigTextButton,
  PopoverMenu,
  DownFilledIcon,
  SettingsIcon
} from "components/Base";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import { getters, actions } from "../model";
import { getNotificationListFiltered } from "../selectors";
import * as R from "ramda";

const decorate = connect(
  (state, props) => ({
    notifications: getNotificationListFiltered(state, props),
    countOfVisibleNotifications: getters.countOfVisibleNotifications(
      state,
      props
    )
  }),
  bindInstance({
    markAsRead: actions.markAsRead,
    markAsArchive: actions.markAsArchive
  })
);

const Header = ({
  handleClose,
  markAsRead,
  markAsArchive,
  notifications,
  countOfVisibleNotifications,
  ...styleProps
}) => (
  <Div
    display="row.space-between.center"
    height={65}
    style={{
      flexShrink: 0
    }}
    {...styleProps}
  >
    <Div display="row.flex-start.center">
      <Text5 bold ml={3} color="gray7">
        Notifications
      </Text5>
    </Div>
    <Div display="row.flex-start.center">
      {countOfVisibleNotifications ? (
        <PopoverMenu
          Label={({ onClick }) => (
            <BigTextButton
              disabled={R.isEmpty(notifications)}
              RightIcon={DownFilledIcon}
              pill
              color="gray8"
              onClick={onClick}
            >
              Actions
            </BigTextButton>
          )}
          menuItems={[
            ["Mark all as read", () => markAsRead()],
            ["Archive all", () => markAsArchive()]
          ]}
        />
      ) : null}
      {/* <SettingsIcon color="#292929" size={20} onClick={() => null} /> */}
      <BigTextButton
        isPadded={false}
        onClick={handleClose}
        bold
        color="#7700d0"
      >
        Done
      </BigTextButton>
    </Div>
  </Div>
);

export default decorate(Header);
