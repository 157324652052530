import PropTypes from "prop-types";
import React from "react";

const LenndTab = ({ active = false, children, ...props }) => (
  <div {...props}>{children}</div>
);

LenndTab.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.string,
  onActive: PropTypes.func
};

export default LenndTab;
