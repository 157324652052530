import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import SliderSidebarLayout from "ui-kit/SliderSidebar/View";
import Body from "./Body";
import ViewPicker from "ui-kit/ViewPicker/View";

import { getters as sliderSidebarGetters } from "ui-kit/SliderSidebar/model";
import { actions } from "Schedules/ScheduleViewPicker";

import viewPickerModule from "Schedules/ScheduleViewPicker";

import rootSaga from "Schedules/ScheduleViewPicker/sagas";

import { VIEWPICKER_INSTANCE_ID } from "Schedules/Schedule/constants";

viewPickerModule.setRootSaga(rootSaga);

const ScheduleViews = ({
  isDrawerOpen,
  handleOpen,
  handlers,
  collapsed,
  ...buttonStylingProps
}) => (
  <>
    {!collapsed ? (
      <ViewPicker
        onClick={handleOpen}
        instanceId={VIEWPICKER_INSTANCE_ID}
        {...buttonStylingProps}
      />
    ) : null}
    <SliderSidebarLayout position="left" instanceId={VIEWPICKER_INSTANCE_ID}>
      {isDrawerOpen && <Body handlers={handlers} />}
    </SliderSidebarLayout>
  </>
);

const decorate = R.compose(
  connect(
    (state, props) => ({
      isDrawerOpen: sliderSidebarGetters.isDrawerOpen(state, {
        ...props,
        instanceId: VIEWPICKER_INSTANCE_ID
      })
    }),
    {
      handleOpen: () =>
        actions.setIsDrawerOpen(true, {
          meta: { instanceId: VIEWPICKER_INSTANCE_ID }
        })
    }
  )
);

export default decorate(ScheduleViews);
