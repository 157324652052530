import { createSelector } from "reselect";
import { getters } from "./index";
import * as R from "ramda";
import resolveFieldTypeName from "components/Global/Table3/FieldTypeIcons/resolve-field-type-name";
import resolveFieldTypeIconName from "components/Global/Table3/FieldTypeIcons/resolve-field-type-icon-name";

export const getFieldGroups = createSelector(
  (_, props) => props.handlers,
  getters.fieldGroups,
  getters.filter,
  (handlers, fieldGroups, filter) => {
    const filterBy = filter && filter.length ? filter.toLowerCase() : null;

    return fieldGroups.map(fg => {
      const fields = fg.fields.map(f => ({
        ...f,
        typeName: resolveFieldTypeName(R.propOr("", "type", f)),
        typeIcon: resolveFieldTypeIconName(R.propOr("", "type", f)),
        isDefault: f.source === "standard",
        locked: f.source === "standard",
        isPrimaryField: f.is_primary_field,
        onTogglePrimaryField: () => {},
        onDelete: () => handlers.showDeleteFieldModal(fg.id, f),
        onEdit: () => handlers.showEditFieldModal(fg.id, f.id),
        onClickName: () => handlers.showEditFieldModal(fg.id, f.id)
      }));

      return {
        ...fg,
        onAddField: () => handlers.showAddFieldModal(fg.id),
        fields: filterBy
          ? fields.filter(f => f.name.toLowerCase().includes(filterBy))
          : fields
      };
    });
  }
);
