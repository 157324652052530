import PropTypes from "prop-types";
import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import { chunk } from "lodash";

import Body from "components/Global/Modal/Layout/ScrollableBody";
import Loading from "components/Global/Modal/Layout/Loading";
import Footer from "./Footer";
import ValidateLoginDetails from "./Scenes/ValidateLoginDetails";
import ValidateActivation from "./Scenes/ValidateActivation";
import Send from "./Scenes/Send";
import { hideModal } from "redux/modules/modal/actions";

import PortalUserApi from "redux/modules/portal/users/api";

import { connect } from "react-redux";
import { getCredentials } from "redux/modules/user/selectors";

const decorate = connect(
  state => ({
    credentials: getCredentials(state)
  }),
  {
    hideModal
  }
);

class LoginDetailsModal extends Component {
  state = {
    copied: false,
    message: "",
    loading: true,
    saving: false,
    sending: false,
    activeStepIndex: 0,
    result: {
      valid: [],
      invalid_emails: [],
      already_has_access: []
    }
  };

  componentDidMount() {
    this.props
      .createPortalUsersFromContacts({
        eventId: this.props.eventDetails.id,
        contacts: this.props.contacts
      })
      .then(result => {
        this.setState({
          loading: false,
          result
        });
      });
  }

  onMessageChange = e => this.setState({ message: e.target.value });

  getScene = () => {
    if (this.state.activeStepIndex === 0 && this.props.shareOnly) {
      return (
        <ValidateLoginDetails
          result={this.state.result}
          removeAccess={this.removeAccess}
          goToContactProfile={this.goToContactProfile}
          goToAccountProfile={this.goToAccountProfile}
        />
      );
    } else if (this.state.activeStepIndex === 0) {
      return (
        <ValidateActivation
          result={this.state.result}
          removeAccess={this.removeAccess}
          goToContactProfile={this.goToContactProfile}
          goToAccountProfile={this.goToAccountProfile}
        />
      );
    } else if (this.state.activeStepIndex === 1) {
      return (
        <Send
          showConfirmation={!this.props.shareOnly}
          sending={this.state.sending}
          records={this.getRecords()}
          message={this.state.message}
          onMessageChange={this.onMessageChange}
          handleSendEmail={this.handleSendEmail}
          hideModal={this.props.hideModal}
          handleCopy={this.handleCopy}
          copied={this.state.copied}
          registerTextareaRef={ref => {
            this.textarea = ref;
          }}
          url={`${window.__LENND_APP_URL__}/portal-login/${this.props.eventDetails.slug}/${this.props.eventDetails.uuid}`}
        />
      );
    }

    return null;
  };

  getRecords = () =>
    this.props.shareOnly
      ? this.state.result.already_has_access
      : this.state.result.valid;

  handleSendEmail = async () => {
    this.setState({
      sending: true
    });
    const records = this.getRecords();
    await this.sendNotificationEmails();
    this.props.showSnackbar({
      message: `Email${records.length !== 1 ? "s" : ""} sent`
    });
    if (this.props.onDone) {
      this.props.onDone();
    }
    this.props.hideModal();
  };

  removeAccess = async userId => {
    await PortalUserApi.delete(this.props.credentials, {
      eventId: this.props.eventDetails.id,
      userId
    });

    // refresh underlying data
    this.props.onDone();

    const result = await this.props.createPortalUsersFromContacts({
      eventId: this.props.eventDetails.id,
      contacts: this.props.contacts
    });

    this.setState({
      loading: false,
      result
    });
  };

  goToContactProfile = contactId => {
    this.props.router.push({
      pathname: `/event/${this.props.eventDetails.id}/contact/${contactId}`
    });
    this.props.hideModal();
  };

  goToAccountProfile = accountId => {
    this.props.router.push({
      pathname: `/event/${this.props.eventDetails.id}/account/${accountId}`
    });
    this.props.hideModal();
  };

  isCurrentStateValid = () => {
    if (this.state.activeStepIndex === 0 && this.props.shareOnly) {
      return this.state.result.already_has_access.length;
    } else if (this.state.activeStepIndex === 0) {
      return this.state.result.valid.length;
    }
    return true;
  };

  handleCopy = () => {
    this.textarea.selectText();
    this.setState({ copied: true }, () =>
      setTimeout(() => this.setState({ copied: false }), 2500)
    );
  };

  handleMessageChange = ({ target }) =>
    this.setState({ message: target.value });

  handleNextStep = () => {
    if (this.isCurrentStateValid()) {
      findDOMNode(this.body).scrollTop = 0;
      this.setState(state => {
        state.activeStepIndex += 1;
        return state;
      });
    }
  };

  handleBackStep = () =>
    this.setState(state => {
      state.activeStepIndex = Math.max(state.activeStepIndex - 1, 0);
      return state;
    });

  giveAccess = async () => {
    this.setState({ saving: true });
    const chunks = chunk(this.props.contacts, 25);

    for (const chunk of chunks) {
      await this.props.createPortalUsersFromContacts({
        eventId: this.props.eventDetails.id,
        contacts: chunk,
        save: true
      });
    }

    this.setState({ activeStepIndex: 1, saving: false });
    if (this.props.onDone) {
      this.props.onDone();
    }
  };

  sendNotificationEmails = () => {
    const { eventDetails, shareOnly } = this.props;
    const message = this.state.message.length ? this.state.message : null;
    const records = this.getRecords();

    // @FIX SEND EMAIL
    return this.props.sendEmails(
      eventDetails.id,
      records.map(r => {
        const variables = {
          message,
          event_name: eventDetails.name,
          event_slug: eventDetails.slug,
          portal_id: eventDetails.uuid,
          account_name: r.account,
          user_email: r.email
        };
        return {
          eventId: eventDetails.id,
          templateName: "notification-account-invite",
          recipients: [{ email: r.email }],
          variables: Object.keys(variables).map(key => ({
            name: key,
            content: variables[key]
          }))
        };
      })
    );
  };

  render() {
    const { hideModal, shareOnly } = this.props;

    return (
      <StyleWrapper
        bodyStyles={{ padding: 0 }}
        hideModal={hideModal}
        heading={shareOnly ? "Share login details" : "Give login access"}
        width={500}
      >
        <Body ref={c => (this.body = c)} style={{ height: 400 }}>
          {this.state.loading ? (
            <div styleName="body">
              <Loading />
            </div>
          ) : (
            this.getScene()
          )}
        </Body>
        <div styleName="footer">
          <Footer
            shareOnly={this.props.shareOnly}
            processing={this.state.loading}
            saving={this.state.saving}
            sending={this.state.sending}
            isCurrentStateValid={this.isCurrentStateValid}
            activeStepIndex={this.state.activeStepIndex}
            credentialCount={this.credentialCount}
            handleNextStep={this.handleNextStep}
            handleBackStep={this.handleBackStep}
            hideModal={this.props.hideModal}
            save={this.giveAccess}
            onDone={this.onDone}
          />
        </div>
      </StyleWrapper>
    );
  }
}

LoginDetailsModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  eventDetails: PropTypes.shape({
    id: PropTypes.number,
    uuid: PropTypes.string,
    slug: PropTypes.string
  }).isRequired,
  contacts: PropTypes.arrayOf(
    PropTypes.shape({
      contactId: PropTypes.string,
      accountId: PropTypes.string
    })
  ).isRequired,
  createPortalUsersFromContacts: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  sendEmails: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  shareOnly: PropTypes.bool
};

export default decorate(CSSModules(LoginDetailsModal, css));
