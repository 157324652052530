import React from "react";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import Body from "./Body";
import Footer from "./Footer";

const Layout = ({ hideModal, onSave }) => {
  return (
    <MiniModalWrapper
      title="Select fields"
      hideModal={hideModal}
      width={743}
      height={661}
      bg="neutral0"
      showModal
      isPortal={false}
      stopPropagation
    >
      <Body />
      <Footer hideModal={hideModal} onSave={onSave} />
    </MiniModalWrapper>
  );
};

export default Layout;
