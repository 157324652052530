import React from "react";

import VimeoEmbed from "./VimeoEmbed";
import YoutubeEmbed from "./YoutubeEmbed";

const getPlayerBySource = url => {
  if (url.includes("youtube")) {
    return <YoutubeEmbed url={url} />;
  } else if (url.includes("vimeo")) {
    return <VimeoEmbed url={url} />;
  }
  return null;
};

export const Video = ({ url }) => getPlayerBySource(url);

export default Video;
