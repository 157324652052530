import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PrimaryContacts from "./PrimaryContacts";
import { updateRelationship } from "redux/modules/accounts/contactRelationship/actions";

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateRelationship
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(PrimaryContacts);
