import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import moment from "moment";
import { compose } from "ramda";
import {
  Div,
  Text2,
  Text3,
  BigTextBox,
  ErrorIcon,
  MoreIcon,
  Span,
  Popover,
  MediumOutlineButton,
  MediumFilledButton,
  MultiSelect,
  SmallClearButton,
  AddIcon,
  PopoverMenu,
  AvatarLabel
} from "components/Base";
import UserPicker from "components/Global/StandAloneEditors/User";
import EditTemplateModal from "components/Event/Settings/Modals/EditTemplateModal";
import ShareModal from "components/Global/CRM/Modals/Share";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import * as R from "ramda";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

const STANDARD_INVOICE_RECORD_ID = "10ecc167-7f40-4d4f-b3d3-19f8bc40fc50";

const SectionTitle = ({ children }) => (
  <Div fw={3} fs={4} color="gray7" mb={3} bb={1} bc="neutral2" pb={2}>
    {children}
  </Div>
);

const SectionBody = ({ children }) => (
  <Div
    style={{
      marginBottom: 50
    }}
  >
    {children}
  </Div>
);

const FieldWrapper = ({ children }) => <Div mb={5}>{children}</Div>;
const Label = ({ children }) => (
  <Div fw={3} color="gray7" mb={2}>
    {children}
  </Div>
);
const Field = ({ children }) => <Div>{children}</Div>;

const Well = ({ children }) => (
  <Div bg="gray1" bra={2} p={3}>
    {children}
  </Div>
);

class Payments extends Component {
  state = { loading: true, notifyUsers: [] };

  async componentDidMount() {
    await Promise.all([
      this.props.getAuthStatus(this.props.eventId),
      this.props.getEmailTemplates(null, this.props.eventId),
      this.fetchNotifications()
    ]);

    this.setState({ loading: false });
  }

  disconnectPaymentsAuth = async () => {
    await this.props.disconnectPaymentsAuth(this.props.status.id);
    return this.props.getAuthStatus(this.props.eventId);
  };

  goToAuthUrl = () => {
    this.props.goToAuthUrl({ eventId: this.props.eventId });
  };

  onNotifyUsersChange = notifyUsers => this.setState({ notifyUsers });

  fetchNotifications = () =>
    this.props.getNotifications({
      moduleId: STANDARD_MODULE_IDS.orders.id,
      recordIds: [STANDARD_INVOICE_RECORD_ID],
      options: {
        eventId: this.props.eventId
      }
    });

  showNewTemplateModal = () => {
    const emptyTemplate = {
      type: "invoice",
      title: "",
      content_formatted: "",
      content_plaintext: "",
      attachments: []
    };

    this.props.showModal({
      content: (
        <EditTemplateModal
          done={this.createTemplate}
          template={emptyTemplate}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showEditTemplateModal = template => {
    this.props.showModal({
      content: (
        <EditTemplateModal done={this.editTemplate} template={template} />
      ),
      wrapper: ModalWrapper
    });
  };

  showNotificationseModal = () => {
    this.props.showModal({
      content: (
        <ShareModal
          moduleId={STANDARD_MODULE_IDS.orders.id}
          recordId={STANDARD_INVOICE_RECORD_ID}
          onDone={this.fetchNotifications}
          //
          title="Users to Notify"
          showRoles={false}
          allowNotifyingUsers={false}
          selectedLabel="Will receive payment notifications"
          emptyLabel="No users are selected to receive payment notifications."
        />
      ),
      wrapper: ModalWrapper
    });
  };

  editTemplate = template => {
    this.props.updateEmailTemplate(this.props.eventId, template);
  };

  deleteTemplate = id => {
    this.props.deleteEmailTemplate(this.props.eventId, id);
  };

  createTemplate = template => {
    this.props.createEmailTemplate(this.props.eventId, template);
  };

  viewDashboard = () => {
    window.open(this.props.status.dashboard_url, "_blank");
  };

  render() {
    const { status, users, templates, notifications } = this.props;
    const { loading, notifyUsers } = this.state;
    let createdBy, createdOn;

    const isActivated = Boolean(status.id);

    if (isActivated) {
      createdBy = `${status.user.first_name} ${status.user.last_name} (${status.user.email})`;
      createdOn = moment(status.created_at).format(
        "dddd, MMMM Do YYYY, h:mm:ss a"
      );
    }

    const invoiceTemplates = R.filter(t => t.type === "invoice")(templates);

    return (
      <Fragment>
        <Div px={8} pt={8} maxWidth={550}>
          <SectionTitle>Payment Settings</SectionTitle>
          <SectionBody>
            <Div>
              Connect with Stripe to enable secure online payments for you
              event. It takes 1 minute to get set up and start receiving
              payments online.
            </Div>

            {loading ? (
              <div />
            ) : isActivated ? (
              <Div py={5}>
                Your account was connected by <Span fw={3}>{createdBy}</Span> on{" "}
                <Span fw={3}>{createdOn}</Span>
                <Div display="row.flex-start.center" my={4}>
                  <MediumFilledButton
                    bg="orange5"
                    color="white"
                    onClick={this.viewDashboard}
                    mr={3}
                  >
                    View Stripe Dashboard
                  </MediumFilledButton>

                  <Popover
                    Label={({ onClick }) => (
                      <MediumOutlineButton
                        bc="danger5"
                        color="danger5"
                        onClick={onClick}
                      >
                        Disconnect Stripe
                      </MediumOutlineButton>
                    )}
                    labelProps={{ buttonTitle: "Click Me" }}
                    wrapperProps={{ display: "row.flex-start.flex-start" }}
                  >
                    {({ closePopover }) => (
                      <Div px={5} py={3} width={300}>
                        <BigTextBox color="danger5" LeftIcon={ErrorIcon}>
                          Warning
                        </BigTextBox>
                        <Text2 px={1} pb={4}>
                          Disconnecting stripe will require any pending
                          transactions to be resolved manually and you will no
                          longer be able to accept online payments.
                        </Text2>
                        <Div display="row.flex-start.center">
                          <MediumFilledButton
                            uppercase
                            onClick={compose(
                              closePopover,
                              this.disconnectPaymentsAuth
                            )}
                            bg="danger5"
                            mr={2}
                          >
                            Confirm
                          </MediumFilledButton>
                          <MediumOutlineButton uppercase onClick={closePopover}>
                            Cancel
                          </MediumOutlineButton>
                        </Div>
                      </Div>
                    )}
                  </Popover>
                </Div>
              </Div>
            ) : (
              <div>
                <Div py={5}>
                  To set up your account, click on the button below.
                </Div>
                <Div>
                  <img
                    src="//d2dks4tzxs6xee.cloudfront.net/img/stripe-blue-on-light@2x.png"
                    alt="Connect with Stripe"
                    onClick={this.goToAuthUrl}
                    width={210}
                    height={36}
                    style={{
                      cursor: "pointer"
                    }}
                  />
                </Div>
              </div>
            )}
          </SectionBody>
        </Div>

        <Div px={8} pt={5} pb={8} maxWidth={900}>
          <SectionTitle>Invoice Settings</SectionTitle>

          <SectionBody>
            <Div display="row.flex-start.flex-start">
              <Div display="column" flex={1} mr={6}>
                <FieldWrapper>
                  <Label>Invoice Templates</Label>
                  <Well>
                    {invoiceTemplates.map(template => (
                      <Div
                        key={template.id}
                        mb={2}
                        bg={{
                          default: "white",
                          hover: "neutral0"
                        }}
                        bra={2}
                        p={3}
                        shadow={1}
                        onClick={() => this.showEditTemplateModal(template)}
                      >
                        <Div display="row.flex-start.center">
                          <Div fw={3} flex={1}>
                            {template.title}
                          </Div>
                          <PopoverMenu
                            Label={({ onClick }) => (
                              <SmallClearButton
                                ml={2}
                                OnlyIcon={MoreIcon}
                                onClick={onClick}
                              />
                            )}
                            menuItems={[
                              ["Delete", () => this.deleteTemplate(template.id)]
                            ]}
                          />
                        </Div>
                        <Div color="gray5" fs={2}>
                          Last updated{" "}
                          {moment(new Date(template.updated_at)).fromNow()}
                        </Div>
                      </Div>
                    ))}
                    <Div
                      color="purple8"
                      display="row.flex-start.center"
                      onClick={this.showNewTemplateModal}
                      mt={invoiceTemplates.length ? 4 : 0}
                    >
                      <AddIcon mr={2} color="purple8" />
                      <Div fw={3}>
                        {invoiceTemplates.length
                          ? "Create another template"
                          : "Create template"}
                      </Div>
                    </Div>
                  </Well>
                </FieldWrapper>
              </Div>

              <Div display="column" flex={1}>
                {/*
                // @NOTE: Hiding until implemented
                <FieldWrapper>
                  <Label>Pay to Company</Label>
                  <Well>
                    <Div
                      bg={{
                        default: "white",
                        hover: "neutral0"
                      }}
                      color="purple8"
                      display="row.flex-start.center"
                      onClick={this.showNewTemplateModal}
                      bra={2}
                      p={3}
                      shadow={1}
                    >
                      <Div
                        pill
                        bg="neutral1"
                        size={32}
                        display="row.center.center"
                        mr={2}
                      >
                        <AddIcon color="purple8" />
                      </Div>
                      <Div fw={3}>Pay to Company Details</Div>
                    </Div>
                  </Well>
                </FieldWrapper>
                */}

                <FieldWrapper>
                  <Label>Payment Notifications</Label>
                  <Well>
                    <Div fw={3} color="gray7" mb={2}>
                      Users to Notify:
                    </Div>

                    {notifications.map(record => {
                      return (
                        <Div key={record.id}>
                          <AvatarLabel
                            py={2}
                            frontText={`${record.user.fname} ${record.user.lname}`}
                            backText={record.user.email}
                            photoURL={record.user.photo_url}
                          />
                        </Div>
                      );
                    })}

                    <Div
                      color="purple8"
                      display="row.flex-start.center"
                      onClick={this.showNotificationseModal}
                      mt={3}
                    >
                      <AddIcon mr={2} color="purple8" />
                      <Div fw={3}>Manage notifications</Div>
                    </Div>
                  </Well>
                </FieldWrapper>
              </Div>
            </Div>
          </SectionBody>
        </Div>
      </Fragment>
    );
  }
}

Payments.propTypes = {
  goToAuthUrl: PropTypes.func.isRequired,
  disconnectPaymentsAuth: PropTypes.func.isRequired,
  getAuthStatus: PropTypes.func.isRequired,
  eventId: PropTypes.number,
  status: PropTypes.shape({
    id: PropTypes.string,
    created_at: PropTypes.string,
    user: PropTypes.object
  })
};

export default Payments;
