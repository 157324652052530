import React from "react";
import * as R from "ramda";
import { withProps } from "utils/General";
import Tooltip from "components/Global/Tooltip";
import {
  DownFilledIcon,
  CheckIcon,
  SearchIcon,
  ErrorIcon,
  SmallShadedBox,
  MediumOutlineButton,
  MediumTextButton,
  BigOutlineInput,
  SuperFilledButton,
  MediumShadedButton,
  PopoverMenu,
  DarkTab,
  Div,
  Text2,
  Text6,
  LoadingIcon,
  SendIcon,
  AddIcon,
  MediumFilledButton,
  DataTable,
  SmallCheckbox
} from "components/Base";
import { Title, Breadcrumbs } from "Passes/Common";
// import { StatusTag } from "utils/status-comps";
import { LABELS } from "utils/item-types";

const Header = withProps({ py: 3 })(Div);

const makeItemColumn = id => {
  const { label } = LABELS[id] || "Item";
  return {
    id,
    Header: (
      <Header>
        {label} Allocated
        <Tooltip
          tooltip={
            <span>
              Allocations are <strong>approved</strong> pass items.
              <br />
              <br />
              Only {label.toLowerCase()} that are{" "}
              <strong>enabled for guest list</strong> assignment will be
              included in allocation counts.
            </span>
          }
        >
          <span>
            <ErrorIcon sizeWFS={1} ml={2} />
          </span>
        </Tooltip>
      </Header>
    ),
    accessor: d => d.allocations[id],
    sortMethod: (a, b) => {
      if (a.allocated === 0) return -1;
      return a.consumed - b.consumed;
    },
    Cell: ({ value = {}, original }) => {
      if (!value.allocatedCount && !value.pendingRequestCount) {
        if (original.onAddAllocation(id)) {
          return (
            <Text2 color="primary5" onClick={original.onAddAllocation(id)} bold>
              + Add {label}
            </Text2>
          );
        }
        return <span>&mdash;</span>;
      }
      const isComplete =
        value.allocatedCount > 0 &&
        value.allocatedCount === value.assignedCount;
      const remaining = Math.max(value.allocatedCount - value.assignedCount, 0);
      return (
        <Div
          display="row.flex-start.center"
          onClick={() => original.onShowSummary(id)}
        >
          <Tooltip
            tooltip={
              isComplete ? (
                <span>
                  <strong>All passes</strong> assigned to people <br />
                  *some items may be excluded
                </span>
              ) : (
                <span>
                  <strong>{value.assignedCount} passes</strong> assigned to
                  people <br />
                  {remaining && value.allocatedCount > 0 ? (
                    <span>
                      <strong>{remaining} passes</strong> left to assign
                    </span>
                  ) : (
                    <span>
                      <strong>{value.pendingRequestCount} requests </strong>
                      pending review
                    </span>
                  )}
                  <br />
                  *some items may be excluded
                </span>
              )
            }
          >
            <span>
              <SmallShadedBox
                pr={3}
                LeftIcon={
                  isComplete
                    ? withProps({ title: "All assigned" })(CheckIcon)
                    : undefined
                }
                color={isComplete ? "success8" : undefined}
              >
                {value.assignedCount} of&nbsp;
                <strong>{value.allocatedCount}</strong>
              </SmallShadedBox>
            </span>
          </Tooltip>
        </Div>
      );
    }
  };
};
const COLUMNS = [
  {
    Header: ({ column: { isAllSelected, onSelectAll } }) => (
      <Div pl={2}>
        <SmallCheckbox selected={isAllSelected} onClick={onSelectAll} />
      </Div>
    ),
    accessor: "checked",
    width: 50,
    sortable: false,
    Cell: ({ value, original }) => (
      <Div pl={2}>
        <SmallCheckbox selected={value} onClick={original.onSelect} />
      </Div>
    )
  },
  {
    Header: <Header>Group Name</Header>,
    accessor: "name",
    Cell: ({ value }) => <Text2 bold>{value}</Text2>
  },
  {
    Header: <Header>Primary Contact</Header>,
    accessor: "contacts",
    Cell: ({ value, original }) => {
      if (value && value.length) {
        return (
          <Text2
            title={value.join(", ")}
            onClick={original.onAddContact}
            color="primary5"
          >
            {value[0]} {value.length > 1 ? `+ ${value.length - 1}` : ""}
          </Text2>
        );
      }
      if (original.onAddContact) {
        return (
          <Text2 bold onClick={original.onAddContact} color="primary5">
            + Add Person
          </Text2>
        );
      }
    }
  },
  {
    Header: <Header>Pending Requests</Header>,
    accessor: "pendingRequestsCount",
    Cell: ({ value, original: { onReviewPendingRequests } }) => {
      if (value > 0) {
        return (
          <Div display="row.flex-start.center">
            <Text2 pb={1}>{value} pending</Text2>
            <MediumTextButton
              color="primary5"
              onClick={onReviewPendingRequests}
            >
              Review
            </MediumTextButton>
          </Div>
        );
      }
      return <Text2>&mdash;</Text2>;
    }
  },
  {
    Header: "Actions",
    accessor: "actions",
    sortable: false,
    Cell: ({
      original: {
        onSendEmail,
        onEditAllocation,
        onCopyShareLink,
        onViewGroupProfile,
        onViewGuestList,
        onSendEmailLink
      }
    }) => (
      <Div display="row">
        <PopoverMenu
          Label={({ onClick }) => (
            <MediumOutlineButton
              RightIcon={DownFilledIcon}
              mr={2}
              onClick={onClick}
            >
              Share
            </MediumOutlineButton>
          )}
          menuItems={[
            ["Send Link", onSendEmailLink],
            ["Send Email", onSendEmail],
            ["Copy Share Link", onCopyShareLink]
          ]}
        />
        <PopoverMenu
          Label={({ onClick }) => (
            <MediumOutlineButton onClick={onClick}>More...</MediumOutlineButton>
          )}
          menuItems={[
            ["Open Group Profile", onViewGroupProfile],
            ["Edit Allocations", onEditAllocation],
            ["Go to Guest List", onViewGuestList]
          ].filter(([title, action]) => Boolean(action))}
        />
      </Div>
    )
  }
];

const Card = ({ title, subtitle, actions, ...props }) => (
  <Div
    width={215}
    height={123}
    display="column.flex-start.space-between"
    bra={2}
    bg="white"
    shadow={1}
    pl={4}
    pr={1}
    pt={3}
    pb={1}
    {...props}
    style={{
      border: "1px solid #eee"
    }}
  >
    <Div flex={1}>
      {typeof title === "string" ? (
        <Div
          fw={4}
          color="gray7"
          style={{
            fontSize: "34px"
          }}
        >
          {title}
        </Div>
      ) : (
        title
      )}
      <Text2>{subtitle}</Text2>
    </Div>
    <Div display="row.space-between.center">
      {actions.map(({ color, onClick, label }) => (
        <MediumTextButton key={label} color={color} onClick={onClick}>
          {label}
        </MediumTextButton>
      ))}
    </Div>
  </Div>
);

const View = ({
  onSelectAll,
  onAddGroup,
  onSendLinks,
  // onInviteGroups,
  onSendEmails,
  // onImportGroups,
  title,
  breadcrumbs,
  recordTypeId,
  tableRows,
  loading,
  isAllSelected,
  countOfSelected,
  filterText,
  cards = [],
  tabs = [],
  onSearch = () => {},
  searchTerm,
  itemTypeIds
}) => {
  COLUMNS[0].onSelectAll = onSelectAll;
  COLUMNS[0].isAllSelected = isAllSelected;

  let columns = R.insertAll(3, itemTypeIds.map(makeItemColumn))(COLUMNS);

  return (
    <Div bg="neutral0" height={1}>
      <Div pt={6} px={6} bg="white" bb="1" bc="neutral3">
        <Div pb={3}>
          <Breadcrumbs>{breadcrumbs}</Breadcrumbs>
          <Title>{title}</Title>
        </Div>
        <Div pb={5} display="row.flex-start.stretch">
          {cards.map(card => (
            <Card
              key={`${card.title}-${card.subtitle}`}
              mr={3}
              title={card.title}
              subtitle={card.subtitle}
              actions={card.actions}
            />
          ))}
        </Div>
        <Div display="row.flex-start.center">
          <DarkTab
            key={tabs[0][0]}
            activeColor="neutral7"
            color="primary8"
            active={tabs[0][2]}
            onClick={tabs[0][1]}
          >
            {tabs[0][0]}
          </DarkTab>
          <Text2 pr={3} pb={1 / 2}>
            Filters:
          </Text2>
          {tabs.slice(1).map(([title, onClick, isActive]) => (
            <DarkTab
              key={title}
              activeColor="neutral7"
              color="primary8"
              active={isActive}
              onClick={onClick}
            >
              {title}
            </DarkTab>
          ))}
        </Div>
      </Div>
      <Div p={6}>
        <Div pb={3} display="row.flex-start.center">
          {countOfSelected ? (
            <Div py={1} display="row">
              <MediumFilledButton
                LeftIcon={SendIcon}
                bg="altB5"
                onClick={onSendLinks}
                mr={1}
              >
                Send Links
              </MediumFilledButton>
              <MediumShadedButton onClick={onSendEmails}>
                Send Emails
              </MediumShadedButton>
            </Div>
          ) : (
            [
              <BigOutlineInput
                bg="white"
                placeholder="Search groups..."
                LeftIcon={SearchIcon}
                continuous
                onChange={onSearch}
                flex={1}
              />,
              <Div flex={1} />,

              onAddGroup ? (
                <MediumFilledButton
                  bg="altB5"
                  onClick={onAddGroup}
                  LeftIcon={AddIcon}
                >
                  Add Group
                </MediumFilledButton>
              ) : null
            ]
          )}
        </Div>

        <Div bra={1} bc="neutral2" b={1}>
          <DataTable
            EmptyState={
              loading ? (
                <Div py={20} display="column.center.center">
                  <LoadingIcon size={40} />
                </Div>
              ) : searchTerm ? (
                <Div py={20} display="column.center.center">
                  <Text6 bold>No results for "{searchTerm}"</Text6>
                </Div>
              ) : filterText ? (
                <Div py={20} display="column.center.center">
                  <Text6 bold>{filterText}</Text6>
                </Div>
              ) : (
                <Div py={20} display="column.center.center">
                  <Text6 pb={4} bold>
                    No groups added yet
                  </Text6>
                  <Div display="row.flex-start-center">
                    <SuperFilledButton
                      mr={2}
                      bg="altB5"
                      onClick={onAddGroup}
                      LeftIcon={AddIcon}
                    >
                      Add a group
                    </SuperFilledButton>
                  </Div>
                </Div>
              )
            }
            columns={columns}
            rows={tableRows}
            id={recordTypeId}
          />
        </Div>
      </Div>
    </Div>
  );
};

export default View;
