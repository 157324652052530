import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import * as R from "ramda";
import { connect } from "react-redux";
import AddContactToAccount from "./AddContactToAccount";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

import { createEmailTemplate } from "redux/modules/eventEmailTemplates/actions";
import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";

import { recordTypes } from "redux/modules/modules/recordTypes/selectors";

import { eventDetails as getEventDetails } from "redux/modules/event/selectors";

function mapStateToProps(state) {
  return {
    contactRecordTypes: recordTypes(state, STANDARD_MODULES.contacts.id) || [],
    orgId: R.compose(
      R.propOr("", "org_id"),
      getEventDetails
    )(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createEmailTemplate,
      getRecordTypes
    },
    dispatch
  );
}

export default R.compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withRouter
)(AddContactToAccount);
