import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "Modules/ImportModal";
import { PAGES } from "Modules/ImportModal/constants";
import {
  Div,
  Text2,
  TextAreaInput,
  TinyToggle,
  Text1,
  Text6,
  SuperOutlineButton,
  SuperFilledButton
} from "components/Base";

const decorate = connect(
  state => ({
    pasteText: getters.pasteText(state)
  }),
  {
    goToImportFile: () => actions.setSelectedPage(PAGES.IMPORT_FILE),
    gotToMapCsv: () => actions.setSelectedPage(PAGES.MAP_CSV),
    setPasteText: text => actions.setPasteText(text)
  }
);

const PasteTable = ({
  goToImportFile,
  gotToMapCsv,
  pasteText,
  setPasteText
}) => (
  <Div display="column">
    <Div display="row.flex-start.stretch" height={433} bb={1} bc="neutral2">
      <Div flex={1} p={5} pb={0} display="column">
        <Text2 bold>Paste Data Table:</Text2>
        <TextAreaInput
          height={320}
          mt={2}
          continuous
          style={{ resize: "none" }}
          placeholder="Paste table data here..."
          value={pasteText}
          onChange={ev => {
            setPasteText(ev);
          }}
        />
        <Div height={50} display="row.flex-start.center">
          <Div display="row">
            <TinyToggle active onClick={() => {}} />
            <Text1 ml={2}>First row of CSV file is headers</Text1>
          </Div>
        </Div>
      </Div>
      <Div bg="neutral0" width={570} display="row.center.center">
        <Text6 bold>Copy & Paste Your Data</Text6>
      </Div>
    </Div>
    <Div display="row" p={3}>
      <SuperOutlineButton onClick={goToImportFile}>Back</SuperOutlineButton>
      <SuperFilledButton
        ml={3}
        width={314}
        bg="neutral1"
        disabled
        onClick={gotToMapCsv}
      >
        Next...
      </SuperFilledButton>
    </Div>
  </Div>
);

export default decorate(PasteTable);
