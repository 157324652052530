import PropTypes from "prop-types";
import React, { Component } from "react";
import FilePreviewer from "../FilePreviewer";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { PopMenuCard, PopMenu, ExpandIcon } from "components/Base";

import Circle from "ui-kit/Circle";

@CSSModules(css)
class ExpandButton extends Component {
  render() {
    return (
      <PopMenu
        Label={({ onClick }) => (
          <Circle size={25} bg="white" onClick={onClick} ml={1}>
            <ExpandIcon />
          </Circle>
        )}
      >
        {({ closeMenu }) => (
          <PopMenuCard position="bottom" align="right">
            <FilePreviewer
              showFilepicker={() => {
                closeMenu();
                this.props.showFilepicker();
              }}
              onFileClick={this.props.onFileClick}
              deleteFile={this.props.deleteFile}
              downloadFile={this.props.downloadFile}
              files={this.props.files}
              isReadOnly={this.props.isReadOnly}
            />
          </PopMenuCard>
        )}
      </PopMenu>
    );
  }
}

ExpandButton.propTypes = {
  closeExpanded: PropTypes.func.isRequired,
  onExpandClick: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  files: PropTypes.array.isRequired,
  showFilepicker: PropTypes.func.isRequired,
  onFileClick: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool
};

export default ExpandButton;
