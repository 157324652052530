import React from "react";
import EditorBase from "./TableConnector";
import Editor from "components/Global/Editors/Currency";

import { getSettings, ep } from "ui-kit/Datagrid/utils";

export default class CurrencyEditor extends EditorBase {
  render() {
    const props = ep(this.props);
    const editorProps = getSettings(props);

    return (
      <Editor
        {...editorProps}
        ref={c => (this.focusRef = c)}
        value={this.state}
        onChange={this.handleChange}
        continuous
      />
    );
  }
}
