import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import { debounce } from "lodash";

import { noop } from "utils/General";

import { actions } from "ui-kit/Form/model";
import { getValue } from "ui-kit/Form/selectors";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import {
  TinyOutlineInput,
  SmallOutlineInput,
  MediumOutlineInput,
  BigOutlineInput,
  SuperOutlineInput,
  TinyInsetInput,
  SmallInsetInput,
  MediumInsetInput,
  BigInsetInput,
  SuperInsetInput
} from "components/Base";

import { WithThemeConsumer, defaultTh, wsp, VARIANTS } from "ui-kit/Theme";
import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";

const decorate = R.compose(
  WithFormInstanceConsumer({ fieldType: FIELD_TYPES.CURRENCY }),
  WithThemeConsumer({ variant: VARIANTS.BACKGROUND }),
  connect(
    (state, props) => ({
      value: getValue(state, props)
    }),
    bindInstance({
      setIniValue: actions.setIniValue,
      onChange: actions.setFieldValue,
      onFocus: actions.focusField,
      onBlur: actions.blurField
    })
  )
);

const inputs = {
  outline: {
    tiny: TinyOutlineInput,
    small: SmallOutlineInput,
    medium: MediumOutlineInput,
    big: BigOutlineInput,
    super: SuperOutlineInput
  },
  inset: {
    tiny: TinyInsetInput,
    small: SmallInsetInput,
    medium: MediumInsetInput,
    big: BigInsetInput,
    super: SuperInsetInput
  }
};

export const UCCurrencyInput = ({
  value = "",
  iniValue,
  Th = defaultTh,
  fieldId,
  fieldType,
  size = "medium",
  inputType = "inset",
  onChange = noop,
  setIniValue = noop,
  onFocus = noop,
  onBlur = noop,
  ...props
}) => {
  useEffect(() => {
    setIniValue(iniValue, {
      meta: { fieldType, fieldId }
    });
  }, []);
  return React.createElement(inputs[inputType][size] || MediumOutlineInput, {
    value,
    onChange: debounce(
      val =>
        onChange(val, {
          meta: { fieldType, fieldId }
        }),
      500
    ),
    continuous: true,
    onFocus: () => onFocus(null, { meta: { fieldId, fieldType } }),
    onBlur: () => onBlur(null, { meta: { fieldId, fieldType } }),
    ...Th(["bg", "color", "bc"], props),
    ...wsp(props)
  });
};

export const CurrencyInput = decorate(UCCurrencyInput);
