import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import constructPagination from "../../utils/construct-page-numbers";

@CSSModules(css)
class ReactTablePagination extends Component {
  renderPageNumber = number => {
    if (typeof number !== "number") {
      return (
        <div key={number} className={css.ellipsis}>
          {number}
        </div>
      );
    }
    const { changePage, page } = this.props;
    return (
      <div
        key={number}
        onClick={() => changePage(number - 1)}
        className={number === page + 1 ? css.pageActive : css.page}
      >
        {number}
      </div>
    );
  };

  render() {
    const {
      // Computed
      pages,
      // Props
      page,
      canPrevious,
      canNext
    } = this.props;

    if (pages <= 1) {
      return <div />;
    }

    return (
      <div className={css.container}>
        {canPrevious ? (
          <div
            onClick={() => {
              if (!canPrevious) return;
              this.props.changePage(page - 1);
            }}
            disabled={!canPrevious}
            className={css.arrow}
          >
            <i className="material-icons">keyboard_arrow_left</i>
          </div>
        ) : (
          ""
        )}
        <div className={css.pageGroup}>
          {constructPagination({ pages, page }).map(number =>
            this.renderPageNumber(number)
          )}
        </div>
        {canNext ? (
          <div
            onClick={() => {
              if (!canNext) return;
              this.props.changePage(page + 1);
            }}
            disabled={!canNext}
            className={css.arrow}
          >
            <i className="material-icons">keyboard_arrow_right</i>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default ReactTablePagination;
