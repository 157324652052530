export const NAMESPACE = "HubReports";

export const REPORT_TYPES = {
  ASSETS: "assets",
  MEALS: "meals",
  CREDENTIALS: "credentials"
};

export const FIELDS = [
  {
    id: "indiviudal",
    name: "Assigned To",
    span: 3 / 16
  },
  {
    id: "types",
    name: "Item",
    span: 4 / 16
  },
  {
    id: "quantity",
    name: "Quantity",
    span: 2 / 16
  },
  {
    id: "details",
    name: "Details",
    span: 2 / 16
  },
  {
    id: "price",
    name: "Price",
    span: 2 / 16
  },
  {
    id: "status",
    name: "Status",
    span: 2 / 16
  },
  {
    id: "paid",
    name: "Paid?",
    span: 1 / 16,
    align: "right"
  }
];
