import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import styles from "./styles.scss";

const Panel = ({ children }) => <div styleName="container">{children}</div>;

Panel.propTypes = {
  children: PropTypes.node
};

export default CSSModules(Panel, styles);
