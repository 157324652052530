export const NAMESPACE = "UIMediaContentModal";
export const MEDIA_CONTENT_INSTANCE_ID = "UIMediaContentInstance";

export const ROW_ACTIONS = {
  EDIT_RECORD: "edit-record",
  OPEN_RECORD: "open-record",
  SELECT_RECORD: "select-record",
  DELETE_RECORD: "delete-record"
};

export const DEFAULT_PAGINATION = {
  pageSize: 15,
  pages: 0,
  page: 0,
  total: 0
};

export const PAGE_OPTIONS = [15, 45, 75];
