import React from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { Div, Text2, PeopleIcon } from "components/Base";

import { Panel } from "ui-kit/FilterControls/View";

import {
  getPeopleLinks,
  getIsPeopleSectionActive
} from "EventLight/Common/Dashboard/selectors";
import { actions } from "EventLight/Common/Dashboard";

const decorate = connect(
  (state, props) => ({
    links: getPeopleLinks(state, props),
    isPeopleSectionActive: getIsPeopleSectionActive(state, props)
  }),
  bindInstance({
    updatePeopleType: actions.updatePeopleType
  })
);

const People = ({
  links,
  updatePeopleType,
  isPeopleSectionActive,
  ...styleProps
}) => {
  return !links.length ? null : (
    <Panel
      label={
        <Div display="row.flex-start.center" mb={1}>
          <PeopleIcon fs={4} mr={2} />
          <Text2 bold>People</Text2>
        </Div>
      }
      collapsedDefault={false}
      filtersOn={isPeopleSectionActive}
      bodyProps={{ ml: 3, mr: 2 }}
      {...styleProps}
    >
      <Div display="column.flex-start.flex-start">
        {links.map(l => (
          <Div
            py={1}
            key={l.id}
            fs={2}
            onClick={
              l.isRouteActive
                ? () => updatePeopleType(null)
                : () => updatePeopleType(l.id)
            }
            width={1}
            fw={l.isRouteActive ? 3 : 2}
            color={
              l.isRouteActive
                ? "primary7"
                : {
                    default: "inherit",
                    hover: "black"
                  }
            }
          >
            {l.name}
          </Div>
        ))}
      </Div>
    </Panel>
  );
};

export default decorate(People);
