import React from "react";

import { Div } from "components/Base";

import Tabs from "./VariantTabs";

const Header = ({ groups, instanceId }) => (
  <Div height={60}>
    <Tabs instanceId={instanceId} groups={groups} />
  </Div>
);

export default Header;
