import PropTypes from "prop-types";
import React from "react";
import Container from "components/Global/Alerts/Container";
import Header from "components/Global/Alerts/Header";
import Separator from "components/Global/Alerts/Separator";
import PrimaryAction from "components/Global/Alerts/PrimaryAction";
import SecondaryAction from "components/Global/Alerts/SecondaryAction";
import Buttons from "components/Global/Alerts/ButtonContainer";

const UndoApproval = ({ onAccept, onCancel }) => (
  <Container>
    <Header>Heads up,</Header>
    <div>
      Undoing this approval will <strong>not</strong> remove the records that
      were created from the previous approval.
    </div>
    <Separator />
    <Buttons>
      <PrimaryAction onClick={onAccept}>Okay</PrimaryAction>
      <SecondaryAction onClick={onCancel}>Cancel</SecondaryAction>
    </Buttons>
  </Container>
);

UndoApproval.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default UndoApproval;
