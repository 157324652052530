import { createModule } from "redux-mvc";
import handlers from "Event/ItemCatalogItems/handlers";

const { actions, getters } = handlers;

export { actions, getters };

const module = createModule(handlers);

export default module;
