import * as R from "ramda";
import React from "react";

import {
  Div,
  Text1,
  Text3,
  Text4,
  Text6,
  BigFilledButton,
  BigOutlineButton
} from "components/Base";

import { WithThemeConsumer, defaultTh, os, wsp, VARIANTS } from "ui-kit/Theme";
import CollapsablePanel from "ui-kit/CollapsiblePanel";

import { ToggleOption, Wysiwyg, Box, Row, Column } from "ui-kit/Form/View";
import Circle from "ui-kit/Circle";

import { noop } from "utils/General";

const at = WithThemeConsumer();

export const PageTitle = at(({ children, Th = defaultTh, ...styleProps }) => (
  <Div {...Th(["color"], { bold: true, ml: 2, fw: 4, fs: 7, ...styleProps })}>
    {children}
  </Div>
));

export const PageSubTitle = at(
  ({ children, Th = defaultTh, ...styleProps }) => (
    <Text6 {...Th(["color"], { bold: true, ...styleProps })}>{children}</Text6>
  )
);

export const StepDescription = ({ children, ...styleProps }) => (
  <Box
    display="column.flex-start.stretch"
    variant={VARIANTS.SURFACE}
    {...os(styleProps)}
  >
    {children}
  </Box>
);

export const FAQsPanel = at(({ questions, Th = defaultTh, ...styleProps }) => (
  <CollapsablePanel
    heading={
      <Div display="row.flex-start.center">
        <Circle
          {...Th(["bg"], {
            variant: Th.VARIANTS.SURFACE,
            size: 22,
            fw: 3
          })}
        >
          ?
        </Circle>
        <Text4 bold ml={2}>
          FAQs
        </Text4>
      </Div>
    }
    bg="transparent"
    {...os(styleProps)}
  >
    <Div
      {...Th(["bg"], {
        variant: Th.VARIANTS.SURFACE,
        mt: 3,
        pt: 3,
        bra: 1
      })}
    >
      {R.map(
        question => (
          <Div
            display="column.flex.start.stretch"
            key={question.id}
            ml={6}
            mr={20}
            pb={3}
            mb={3}
            bb={question.isLast ? 0 : 1}
          >
            <Text3 bold>{question.title}</Text3>
            <Text3>{question.text}</Text3>
          </Div>
        ),
        questions
      )}
    </Div>
  </CollapsablePanel>
));

export const PackageContainer = ({
  title = "",
  caption = "",
  children,
  ...styleProps
}) => (
  <>
    <Row display="row.flex-start.center" mb={3} mt={5} {...os(styleProps)}>
      <PageSubTitle>{title}</PageSubTitle>
      {caption && (
        <Text1 underline ml={3} style={{ cursor: "pointer" }}>
          {caption}
        </Text1>
      )}
    </Row>
    {children}
  </>
);

export const Panel = at(
  ({ Th = defaultTh, children, accentBorder, ...styleProps }) => (
    <Div
      {...Th(["bc", "bg"], {
        bl: accentBorder ? null : 1,
        br: 1,
        bt: 1,
        bb: 1,
        display: "row.flex-start.stretch",
        ...styleProps
      })}
    >
      {accentBorder && (
        <Div bg={accentBorder} color="transparent" width={3}>
          a
        </Div>
      )}
      {children}
    </Div>
  )
);

export const ItemCard = at(
  ({
    title = "",
    caption = "",
    primaryText = "",
    primaryAction = noop,
    footer = "",
    variant = VARIANTS.BACKGROUND,
    Th = defaultTh,
    ...styleProps
  }) => (
    <Column
      {...Th(["bc", "bg"], {
        br: 1,
        bt: 1,
        bb: 1,
        bl: variant === VARIANTS.BACKGROUND ? 1 : null,
        display: "column.flex-start.stretch",
        ...styleProps,
        variant: Th.VARIANTS.BACKGROUND
      })}
    >
      <Row display="row.flex-start.stretch">
        <Div
          {...Th(["bg"], {
            variant: Th.VARIANTS.PRIMARY,
            w: 1,
            bg: variant === VARIANTS.BACKGROUND ? "transparent" : null,
            color: "transparent"
          })}
        >
          a
        </Div>
        <Column p={4} flex={1}>
          <Text4 bold mb={2}>
            {title}
          </Text4>
          <Row>{caption}</Row>
        </Column>
        <Column display="column.center.center" mr={3}>
          {variant === VARIANTS.PRIMARY ? (
            <BigFilledButton {...Th(["bg", "color"])} onClick={primaryAction}>
              {primaryText}
            </BigFilledButton>
          ) : (
            <BigOutlineButton {...Th(["color"])} onClick={primaryAction}>
              {primaryText}
            </BigOutlineButton>
          )}
        </Column>
      </Row>
      {footer ? (
        <Row {...Th(["bc"], { mx: 4, my: 2, bt: 1, pt: 1, pl: 2 })}>
          {footer}
        </Row>
      ) : null}
    </Column>
  )
);

export const QuestionCard = at(
  ({
    question = "",
    options = [],
    fieldId,
    hint = "",
    footer = "",
    Th = defaultTh,
    ...styleProps
  }) => (
    <Column
      {...Th(["bg"], {
        variant: Th.VARIANTS.SURFACE,
        p: 3,
        bra: 1,
        ...styleProps
      })}
    >
      {question}
      <Row>
        <ToggleOption
          options={options}
          fieldId={fieldId}
          bg="neutral3"
          mt={2}
        />
        <Text3>{hint}</Text3>
      </Row>
      {footer}
    </Column>
  )
);

export const EmailPanel = at(
  ({ title = "", fieldId, Th = defaultTh, ...styleProps }) => (
    <CollapsablePanel
      right={false}
      heading={collapsed => (
        <Row width={1} display="row.space-between.center" m={3}>
          <Text3 bold>{title}</Text3>
          {collapsed ? (
            <BigOutlineButton>Edit</BigOutlineButton>
          ) : (
            <BigFilledButton {...Th(["bg"], { variant: Th.VARIANTS.PRIMARY })}>
              Done
            </BigFilledButton>
          )}
        </Row>
      )}
      collapsedDefault
      {...Th(["bg", "bc"], styleProps)}
      {...wsp(styleProps)}
    >
      <Box
        direction="column"
        {...Th(["bg"], { p: 3, mx: 3, mb: 3, variant: Th.VARIANTS.SURFACE })}
      >
        <Div {...Th(["bg"], { width: 1 })}>
          <Wysiwyg width={1} fieldId={fieldId} />
        </Div>
      </Box>
    </CollapsablePanel>
  )
);
