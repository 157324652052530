/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  getSalesPortal: ({ credentials, eventId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/event/${eventId}/event-showcase`,
        credentials,
        success,
        error
      });
    })
};
