import { createHandlers } from "redux-mvc";
import * as R from "ramda";
const model = createHandlers({
  iniState: {
    isEditing: false,
    newText: null
  },
  reducers: {
    setEditing: ({ isEditing }) => ({
      isEditing: R.not(isEditing)
    })
  },
  namespace: "EditTextSimpleForm"
});

const { getters, actions } = model;

export { getters, actions };

export default model;
