import Helpers from "utils/Global/Helpers";
import getQuery from "redux/modules/modules/utils/get-query";

module.exports = {
  get: (credentials, { moduleId, recordId, options }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery(options);

      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/modules/${moduleId}/record/${recordId}${query || ""}`,
        credentials,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */,

  delete: (credentials, { moduleId, record, options }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery(options);

      Helpers.request({
        method: "delete",
        url: `${
          window.__LENND_API_BASE_URL__
        }/modules/${moduleId}/record${query || ""}`,
        credentials,
        data: record,
        success,
        error
      });
    })
  /* eslint-enable no-underscore-dangle */
};
