import { REQUEST, RECEIVE } from "./constants";
import * as selectors from "./selectors";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getNotes(data) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({
        type: REQUEST,
        payload: {
          moduleId: data.moduleId,
          recordId: data.recordId
        }
      });
      const result = await api.get(getState().user.user.credentials, data);
      dispatch({
        type: RECEIVE,
        payload: {
          moduleId: data.moduleId,
          recordId: data.recordId,
          notes: result.notes
        }
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching record notes"
          }
        ])
      );
    }
  };
}

export function addNote(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding note"
          }
        ])
      );
    }
  };
}

export function updateNote(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.put(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating note"
          }
        ])
      );
    }
  };
}

export function deleteNote(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.delete(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting note"
          }
        ])
      );
    }
  };
}
