import * as R from "ramda";
import { createModule, createHandlers } from "redux-mvc";

const iniState = {
  modified: false,
  questions: {},
  editing: {},
  lineItemIds: [],
  showModal: false,
  loading: false,
  fullQuestions: false,
  clickedOn: new Date()
};

const handlers = createHandlers({
  iniState,
  reducers: {
    questionsRequest: (_, { payload: lineItemIds }) => ({
      lineItemIds,
      loading: true,
      editing: {},
      fullQuestions: false
    }),
    questionsResponse: (_, { payload, error }) =>
      error
        ? { loading: false }
        : {
            modified: false,
            questions: R.indexBy(R.prop("id"), payload),
            loading: false
          },
    setIsEditing: (
      { editing, questions, fullQuestions, clickedOn },
      { payload: id }
    ) => ({
      clickedOn: fullQuestions ? clickedOn : new Date(),
      editing: {
        ...editing,
        [id]: R.pathOr({}, [id, "value"], questions)
      }
    }),
    cancelEditing: (
      { editing, fullQuestions, clickedOn },
      { payload: id }
    ) => ({
      clickedOn: fullQuestions ? clickedOn : new Date(),
      editing: R.omit([id], editing)
    }),
    saveRequest: R.identity,
    save: ({ questions, editing }, { payload: id }) => ({
      questions: {
        ...questions,
        [id]: {
          ...R.propOr({}, id, questions),
          value: R.prop(id, editing)
        }
      }
    }),
    saveResponse: (state, { payload: id, error }) =>
      error
        ? state
        : {
            editing: R.omit([id], state.editing)
          },
    editQuestion: ({ editing }, { payload: { id, value } }) => ({
      editing: R.assoc(id, value, editing)
    }),
    hideModal: R.always({ showModal: false }),
    showModal: R.always({ showModal: true, editing: {} }),
    hideMenu: () => ({
      questions: {},
      editing: {},
      fullQuestions: false
    }),
    showFullQuestions: R.always({ fullQuestions: true, clickedOn: new Date() })
  },
  namespace: "QuestionsDetails"
});

const { actions, getters } = handlers;

const module = createModule({ ...handlers, optimist: true });

export { actions, getters };

export default module;
