import PropTypes from "prop-types";
import React, { Component } from "react";
import { get, cloneDeep } from "lodash";
import List from "./List";
import QuantityInput from "./QuantityInput";
import getValue from "utils/value-types/get-value/credentials";
import { NONE } from "utils/system-field-values";

class CredentialEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCredentials: cloneDeep(
        this.formatValue(props.value).records || []
      )
    };
  }

  wrapValue = records => ({
    type: "credentials",
    value:
      records && records.length
        ? {
            records: records.map(({ id, quantity }) => ({ id, quantity }))
          }
        : undefined
  });

  formatValue(value) {
    return getValue(value);
  }

  updateQuantity = (credential, value) => {
    let newState = [...this.pureCredentials()];
    const quantity = this.formatQuantity(value);

    if (this.credentialExists(credential, newState)) {
      newState = this.updateCredential(credential, quantity, newState);
    } else {
      newState = this.addCredential(credential, quantity, newState);
    }

    this.props.onChange(this.wrapValue(newState));
    this.setState({ selectedCredentials: newState });
  };

  updateQuantityOnlyInput = (credential, value) => {
    const quantity = this.formatQuantity(value);
    const newState = this.addCredential(credential, quantity, []);

    this.props.onChange(this.wrapValue(newState));
    this.setState({ selectedCredentials: newState });
  };

  pureCredentials = () =>
    this.state.selectedCredentials.filter(c => c.id !== NONE.id);

  clearNoneValue = () => {
    this.props.onChange(this.wrapValue(this.pureCredentials()));
    this.setState({ selectedCredentials: this.pureCredentials() });
  };

  setNoneValue = () => {
    const noneValue = { id: NONE.id, quantity: 1, value: NONE.value };
    this.props.onChange(this.wrapValue([noneValue]));
    this.setState({ selectedCredentials: [noneValue] });
  };

  handleSelectNone = val => {
    if (val) {
      return this.setNoneValue();
    }
    return this.clearNoneValue();
  };

  updateSingleCredential = credential => {
    const newState = [];
    if (credential) {
      newState.push({ ...credential, quantity: 1 });
    }
    this.props.onChange(this.wrapValue(newState));
    this.setState({ selectedCredentials: newState });
  };

  formatQuantity = quantity => {
    const quantityAsInt = parseInt(quantity, 10);
    return isFinite(quantityAsInt) && quantityAsInt > 0 ? quantityAsInt : 0;
  };

  credentialExists = (credential, credentials) =>
    !!this.getCredential(credential, credentials);

  getCredential = (credential, credentials) =>
    credentials.find(cred => cred.id === credential.id);

  updateCredential(credential, quantity, credentials) {
    if (quantity === 0) {
      return this.removeCredential(credential, credentials);
    }
    credentials[
      credentials.indexOf(this.getCredential(credential, credentials))
    ].quantity = quantity;
    return credentials;
  }

  addCredential = (credential, quantity, credentials) => {
    if (quantity === 0) {
      return credentials;
    }
    credentials.push({ quantity, ...credential });
    return credentials;
  };

  removeCredential(credential, credentials) {
    credentials.splice(
      credentials.indexOf(this.getCredential(credential, credentials)),
      1
    );
    return credentials;
  }

  render() {
    const { close, options, disabled, column, className } = this.props;
    const selectType = get(column, "settings.selectType", "quantity");
    if (selectType === "quantity-only-input") {
      return (
        <QuantityInput
          className={className}
          column={column}
          disabled={disabled}
          selectedCredentials={this.state.selectedCredentials}
          updateQuantity={this.updateQuantityOnlyInput}
        />
      );
    }

    // Currently 'single', 'multiple', 'quantity'
    return (
      <List
        onClose={close}
        onSelectNone={this.handleSelectNone}
        customWidth={get(column, "settings.customWidth")}
        selectType={selectType}
        options={options}
        disabled={disabled}
        selectedCredentials={this.state.selectedCredentials}
        updateSingleCredential={this.updateSingleCredential}
        updateQuantity={this.updateQuantity}
      />
    );
  }
}

CredentialEditor.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  column: PropTypes.object,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  close: PropTypes.func
};

export default CredentialEditor;
