import { createStructuredSelector, createSelector } from "reselect";
import * as R from "ramda";

import { getters } from "ui-kit/PaginationBar";

const getTotal = (_, props) => R.propOr(0, "total", props);

export const getPages = createSelector(
  getters.pageSize,
  getTotal,
  (pageSize, total) => (pageSize > 0 ? Math.ceil(total / pageSize) : 0)
);

export const getPage = createSelector(
  getters.page,
  getters.pageSize,
  getTotal,
  (page, pageSize, total) => (pageSize > total ? 0 : page)
);

export const getPagination = createStructuredSelector({
  pageSize: getters.pageSize,
  page: getPage,
  pages: getPages,
  total: getTotal
});

export const getCanPrevious = createSelector(
  getters.page,
  page => page > 0
);

export const getCanNext = createSelector(
  getters.page,
  getPages,
  (page, pages) => page < pages - 1
);
