import * as R from "ramda";
import { all, takeEvery, fork, put, call, select } from "redux-saga/effects";
import { actions, getters } from "./model";
import { makeFuture } from "utils/General/sagas";
import Helpers from "utils/Global/Helpers";
import { registerError } from "redux/modules/errors/actions";
import { apiCall } from "App/Data/sagas";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { noop } from "utils/General";
import { hideModal } from "redux/modules/modal/actions";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import { userId as getUserId } from "redux/modules/user/selectors";
import {
  actions as addAssignmentModalActions,
  getters as addAssignmentModalGetters
} from "EventLight/FileRequests/AddAssignmentModal/model";
import { actions as formActions } from "ui-kit/Form/model";
import { getters as formGetters } from "ui-kit/Form/model";
import { DROPDOWN_FIELD_ID } from "EventLight/FileRequests/AddAssignmentModal/constants";
import { TYPES } from "EventLight/FileRequests/AddAssignmentModal/constants";
import moment from "moment";

const ACCOUNT_ID = STANDARD_MODULES.accounts.id;
const CONTACT_ID = STANDARD_MODULES.contacts.id;

const init = function*({ payload: { requestId } }) {
  yield put(actions.setRequestId(requestId));
  const eventId = yield select(getEventId);
  const userId = yield select(getUserId);

  try {
    if (!R.isEmpty(requestId)) {
      const [
        { payload: initialData },
        { payload: eventUsers },
        { record_types: accountRecordTypes },
        { record_types: contactRecordTypes }
      ] = yield all([
        call(apiCall, {
          method: "get",
          url: `/file-requests/event/${eventId}/request/${requestId}`
        }),
        call(apiCall, {
          method: "get",
          url: `/event-users/${eventId}/users`,
          qs: { onlyEventUsers: true }
        }),
        call(apiCall, {
          method: "get",
          url: `/modules/${ACCOUNT_ID}/record-types`,
          qs: { eventId, userId }
        }),
        call(apiCall, {
          method: "get",
          url: `/modules/${CONTACT_ID}/record-types`,
          qs: { eventId, userId }
        })
      ]);

      yield all([
        put(actions.setInitialData(initialData)),
        put(actions.setEventUsers(eventUsers)),
        put(actions.setRecords({ accountRecordTypes, contactRecordTypes }))
      ]);
    } else {
      const [
        { payload: eventUsers },
        { record_types: accountRecordTypes },
        { record_types: contactRecordTypes }
      ] = yield all([
        call(apiCall, {
          method: "get",
          url: `/event-users/${eventId}/users`,
          qs: { onlyEventUsers: true }
        }),
        call(apiCall, {
          method: "get",
          url: `/modules/${ACCOUNT_ID}/record-types`,
          qs: { eventId, userId }
        }),
        call(apiCall, {
          method: "get",
          url: `/modules/${CONTACT_ID}/record-types`,
          qs: { eventId, userId }
        })
      ]);

      yield all([
        put(actions.setEventUsers(eventUsers)),
        put(actions.setRecords({ accountRecordTypes, contactRecordTypes }))
      ]);
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting file requests"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const showUploadFilesModal = function*() {
  const handleUpload = makeFuture();

  Helpers.getFilepicker(
    {
      multiple: true,
      mimetypes: [],
      container: "modal",
      services: ["COMPUTER", "DROPBOX", "GOOGLE_DRIVE"]
    },
    { path: "event-files/" },
    handleUpload.done
  );

  const files = yield call(handleUpload.onRealized);
  yield put(actions.updateFiles(files));
};

const save = function*({ payload: { onSave = noop } }) {
  const name = yield select(getters.name);
  const dueDate = yield select(getters.dueDate);
  const sampleDocuments = yield select(getters.files);
  const criteria = yield select(getters.criteria);
  const enableReminders = yield select(getters.enableReminders);
  const managers = yield select(getters.managers);
  const assignedToGroupTypes = yield select(getters.assignedToGroupTypes);
  const assignedToPeopleTypes = yield select(getters.assignedToPeopleTypes);
  const assignedToPeople = yield select(getters.assignedToPeople);
  const assignedToGroups = yield select(getters.assignedToGroups);

  const data = {
    name,
    dueDate,
    enableReminders,
    criteria,
    sampleDocuments,
    managers,
    assignedToGroupTypes,
    assignedToPeopleTypes,
    assignedToPeople,
    assignedToGroups
  };

  const requestId = yield select(getters.requestId);

  try {
    yield put(actions.setSaving(true));
    const eventId = yield select(getEventId);
    yield call(apiCall, {
      method: R.isEmpty(requestId) ? "post" : "put",
      url: `/file-requests/event/${eventId}${
        R.isEmpty(requestId) ? "" : `/request/${requestId}`
      }`,
      data
    });

    const message = `Request type ${
      R.isEmpty(requestId) ? "created" : "updated"
    }`;

    yield all([
      put(
        showSnackbar({
          message,
          action: "OK"
        })
      ),
      call(
        onSave
      ) /* this callback is due to legacy use with showModal action */,
      put(hideModal()),
      put(actions.onDone()),
      put(actions.resetData())
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: `An error occurred while ${
            R.isEmpty(requestId) ? "adding" : "updating"
          } file request`
        }
      ])
    );
  } finally {
    yield put(actions.setSaving(false));
  }
};

const hideAddAssignmentModal = function*() {
  yield all([
    put(formActions.clearValues()),
    put(actions.setShowAddAssignmentModal(false))
  ]);
};

const addAssignedToGroupsOrPeople = function*() {
  const fields = yield select(formGetters.fields);
  const dueDate = yield select(addAssignmentModalGetters.dueDate);
  const type = yield select(addAssignmentModalGetters.type);

  const typeField = R.path([DROPDOWN_FIELD_ID, "value", 0], fields);

  const objToSave = {
    id: typeField.value,
    name: typeField.label,
    close_date: dueDate
  };

  if (type === TYPES.ACCOUNT) {
    yield put(actions.updateAssignedToGroups(objToSave));
  } else if (type === TYPES.CONTACT) {
    yield put(actions.updateAssignedToPeople(objToSave));
  }

  yield call(hideAddAssignmentModal);
};

const updateDueDate = function*() {
  const type = yield select(getters.type);
  const assignedToGroups = yield select(getters.assignedToGroups);
  const assignedToPeople = yield select(getters.assignedToPeople);
  const editDueDateModalId = yield select(getters.editDueDateModalId);
  const customCloseDate = yield select(getters.customCloseDate);
  const formattedCloseDate = customCloseDate
    ? moment(customCloseDate).format()
    : customCloseDate;

  if (type === TYPES.ACCOUNT) {
    yield put(
      actions.setAssignedToGroups(
        R.map(
          group => ({
            ...group,
            close_date:
              group.id === editDueDateModalId
                ? formattedCloseDate
                : group.close_date
          }),
          assignedToGroups
        )
      )
    );
  } else if (type === TYPES.CONTACT) {
    yield put(
      actions.setAssignedToPeople(
        R.map(
          contact => ({
            ...contact,
            close_date:
              contact.id === editDueDateModalId
                ? formattedCloseDate
                : contact.close_date
          }),
          assignedToPeople
        )
      )
    );
  }

  yield put(actions.setEditDueDateModalId(""));
};

const hideCreateModal = function*() {
  yield all([put(hideModal()), put(actions.resetData())]);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchSave = function*() {
  yield takeEvery(actions.save.type, save);
};

const watchShowUploadFilesModal = function*() {
  yield takeEvery(actions.showUploadFilesModal.type, showUploadFilesModal);
};

const watchHideCreateModal = function*() {
  yield takeEvery(actions.hideCreateModal.type, hideCreateModal);
};

const watchUpdateDueDate = function*() {
  yield takeEvery(actions.updateDueDate.type, updateDueDate);
};

const watchHideAddAssignmentModal = function*() {
  yield takeEvery(
    addAssignmentModalActions.hideAddAssignmentModal.type,
    hideAddAssignmentModal
  );
};

const watchHideAddAssignedToGroupTypes = function*() {
  yield takeEvery(
    addAssignmentModalActions.save.type,
    addAssignedToGroupsOrPeople
  );
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchShowUploadFilesModal),
    fork(watchSave),
    fork(watchHideCreateModal),
    fork(watchHideAddAssignmentModal),
    fork(watchHideAddAssignedToGroupTypes),
    fork(watchUpdateDueDate)
  ]);
};

export default rootSaga;
