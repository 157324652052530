import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Avatar from "../BlockAvatar";
import Label from "../NameEmail";

const AvatarEmailName = ({ fullName, email }) => (
  <div styleName="container">
    <div styleName="avatarWrapper">
      <Avatar fullName={fullName} email={email} />
    </div>
    <Label fullName={fullName} email={email} />
  </div>
);

AvatarEmailName.propTypes = {
  email: PropTypes.string,
  fullName: PropTypes.string
};

export default CSSModules(AvatarEmailName, css);
