import PropTypes from "prop-types";
import React from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import { FIELDS } from "components/Event/InventoryV2/Needs/Overview/Overview";
import ReportSummary from "components/Global/Reports/Summary/Table";
import buildReportRows from "components/Event/InventoryV2/Needs/Overview/utils/build-report-rows";

const InventorySummaryModal = ({ hideModal, module, records }) => (
  <StyleWrapper
    bodyStyles={{ paddingBottom: 20 }}
    width={500}
    heading="Summary"
    hideModal={hideModal}
  >
    <ReportSummary
      fields={FIELDS}
      rows={buildReportRows(module, records)}
      title={module.name}
    />
  </StyleWrapper>
);

InventorySummaryModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  module: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  records: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default InventorySummaryModal;
