import React, { Component } from "react";
import * as R from "ramda";
import { get } from "lodash";
import autobind from "autobind-decorator";
import Formatter from "ui-kit/Datagrid/View/Formatters/File";
import ExpandButton from "./ExpandButton";
import Helpers from "utils/Global/Helpers";
import getValue from "utils/value-types/get-value/file";
import isEmpty from "utils/value-types/is-empty/file";
import uuid from "node-uuid";
import FileViewer from "components/Global/Modals/FileViewer";
import FileViewerWrapper from "components/Global/Modals/FileViewer/Wrapper";
import { Div, AttachIcon, LeftArrowIcon } from "components/Base";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { showModal } from "redux/modules/modal/actions";
import { connect } from "react-redux";
import { ACCEPT_UPLOAD_FILE } from "utils/file-types-upload";

const decorate = connect(
  null,
  {
    showModal
  }
);

@CSSModules(css)
class FileEditor extends Component {
  constructor(props) {
    super(props);

    this.anchorEl = React.createRef();

    this.state = {
      expanded: false
    };
  }

  downloadFile(url) {
    window.open(url, "_blank");
  }

  @autobind
  deleteFile(file) {
    const existingFiles = getValue(this.props.valueState);
    this.props.handleChange(
      {
        type: "file",
        value: {
          files: [...existingFiles.filter(f => f.id !== file.id)]
        }
      },
      this.props.onCommit
    );
  }

  @autobind
  onExpandClick(e) {
    e.stopPropagation();
    this.setState({
      expanded: !get(this.state, "expanded", false)
    });
  }

  @autobind
  showFilepicker() {
    const multiple = R.pathOr(
      false,
      ["settings", "allowMultiple"],
      this.props.column
    );

    this.showingPicker = true;
    const callback = files => {
      if (files.length) {
        const filteredFiles = files.filter(f => typeof f.url !== "undefined");
        if (filteredFiles.length) {
          const existingFiles = multiple ? getValue(this.props.valueState) : [];
          this.props.handleChange(
            {
              type: "file",
              value: {
                files: [
                  ...existingFiles,
                  ...filteredFiles.map(f => ({
                    ...f,
                    filepickerId: f.id,
                    id: uuid.v4(),
                    uploadedAt: new Date()
                  }))
                ]
              }
            },
            this.props.onCommit
          );
        }
      } else {
        this.showingPicker = false;
      }
    };

    const accept = R.compose(
      R.flatten,
      R.map(({ value }) => ACCEPT_UPLOAD_FILE[value]),
      R.pathOr([], ["settings", "allowedFileTypes"])
    )(this.props.column);

    const options = {
      multiple,
      accept,
      fromSources: ["local_file_system", "dropbox"]
    };

    const path = { path: "event-files/" };

    Helpers.getFilepicker(options, path, callback);
  }

  @autobind
  showFileViewer(file, files) {
    this.setState({ expanded: false });

    this.props.showModal({
      content: (
        <FileViewer
          onFileDelete={this.deleteFile}
          start={files.indexOf(file)}
          files={files}
          isReadOnly={this.isReadOnly()}
        />
      ),
      wrapper: FileViewerWrapper
    });
  }

  @autobind
  isReadOnly() {
    return get(this.props, "column.settings.isReadOnly", false);
  }

  render() {
    const { rowData } = this.props;
    const existingFiles = getValue(this.props.valueState);
    const hasFiles = !isEmpty(this.state);
    const isReadOnly = this.isReadOnly();

    return (
      <div
        styleName={isReadOnly ? "containerReadOnly" : "container"}
        ref={this.anchorEl}
      >
        {!isReadOnly ? (
          <Div
            display="row.flex-start.center"
            bg="white"
            bc="gray4"
            ba={1}
            ml={2}
            shadow={{
              default: 1,
              hover: 2
            }}
            bra={1}
            mr={3}
            style={{
              cursor: "pointer",
              userSelect: "none",
              flexShrink: 0
            }}
            height={24}
            onClick={this.showFilepicker}
          >
            <AttachIcon color="gray5" />

            {!hasFiles ? (
              <>
                <Div ml={1} fs={0} fw={3} color="blue6">
                  Upload a file
                </Div>
                <LeftArrowIcon
                  ml={1}
                  mr={1}
                  size={13}
                  color="blue6"
                  style={{
                    transform: "rotate(135deg)"
                  }}
                />
              </>
            ) : null}
          </Div>
        ) : null}

        <div styleName="formatter">
          <Formatter
            mode="edit"
            onFileClick={file => this.showFileViewer(file, existingFiles)}
            value={this.props.valueState}
            row={rowData}
          />
        </div>
        {existingFiles.length ? (
          <ExpandButton
            expanded={get(this.state, "expanded", false)}
            closeExpanded={() => this.setState({ expanded: false })}
            onExpandClick={this.onExpandClick}
            anchorEl={this.anchorEl.current}
            showFilepicker={this.showFilepicker}
            onFileClick={file => this.showFileViewer(file, existingFiles)}
            downloadFile={this.downloadFile}
            deleteFile={this.deleteFile}
            files={existingFiles}
            isReadOnly={isReadOnly}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default decorate(FileEditor);
