import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters } from "Orders/OrderModal";
import { actions as approvalsActions } from "ApproversLabel";

import {
  getOrderId,
  getPasses,
  getLineItemIds,
  getPendingLineItemIds
} from "Orders/OrderModal/selectors";

import {
  Div,
  Text1,
  DownFilledIcon,
  AddIcon,
  BigOutlineButton,
  BigFilledButton,
  MenuItem,
  CheckCircleIcon,
  CancelIcon,
  PopMenu,
  PopMenuCard
} from "components/Base";

import { withProps } from "utils/General";

import ArrayLayout from "ui-kit/ArrayLayout";
import createPassesSimpleTable, { COLUMNS } from "ui-kit/PassesSimpleTable";

const checkCircleIconGreen = withProps({ color: "altA4" })(CheckCircleIcon);
const cancelIconRed = withProps({ color: "danger4" })(CancelIcon);

const BulkMenuItem = withProps({
  py: 1,
  bb: 1,
  bc: "neutral3",
  width: 1
})(MenuItem);

const size = [1 / 12, "center"];

const columns = [
  {
    ...COLUMNS.passType,
    header: () => <Text1 bold>ITEM NAME</Text1>,
    size: [5 / 12, "left"]
  },
  COLUMNS.requested,
  COLUMNS.rejected,
  COLUMNS.approved,
  {
    ...COLUMNS.issued,
    size
  },
  {
    ...COLUMNS.received,
    size
  },
  {
    ...COLUMNS.total,
    size
  }
];

const Table = createPassesSimpleTable({ columns, style: { bb: 0 } });

const decorate = connect(
  (state, props) => ({
    orderId: getOrderId(state),
    passes: getPasses(state, { handlers: props.handlers }),
    lineItemIds: getLineItemIds(state),
    pendingLineItemIds: getPendingLineItemIds(state),
    restricted: getters.isPortalUser(state)
  }),
  {
    approveRemaining: approvalsActions.onApprove,
    denyRemaining: approvalsActions.onDeny
  }
);

const Passes = ({
  orderId,
  passes = [],
  pendingLineItemIds,
  approveRemaining,
  denyRemaining,
  handlers,
  restricted = false
}) => {
  const buttons = [
    [
      <PopMenu
        key="review"
        width={1}
        Label={({ onClick }) => (
          <BigFilledButton
            onClick={onClick}
            RightIcon={DownFilledIcon}
            key="reviewOrder"
            bg="primary7"
            style={{
              flexShrink: 0
            }}
          >
            Review Order
          </BigFilledButton>
        )}
      >
        {({ closeMenu }) => (
          <PopMenuCard position="bottom" align="right">
            {pendingLineItemIds.length ? (
              <BulkMenuItem
                LeftIcon={checkCircleIconGreen}
                onClick={() => {
                  handlers.flagAsModified();
                  approveRemaining({
                    type: "lineItem",
                    recordIds: pendingLineItemIds
                  });
                  closeMenu();
                }}
              >
                Approve {pendingLineItemIds.length} Remaining
              </BulkMenuItem>
            ) : null}
            {pendingLineItemIds.length ? (
              <BulkMenuItem
                LeftIcon={cancelIconRed}
                onClick={() => {
                  handlers.flagAsModified();
                  denyRemaining({
                    type: "lineItem",
                    recordIds: pendingLineItemIds
                  });
                  closeMenu();
                }}
              >
                Deny {pendingLineItemIds.length} Remaining
              </BulkMenuItem>
            ) : null}
            <BulkMenuItem
              LeftIcon={cancelIconRed}
              onClick={() => {
                handlers.cancelOrder(orderId);
                closeMenu();
              }}
            >
              Cancel Order
            </BulkMenuItem>
          </PopMenuCard>
        )}
      </PopMenu>,
      <BigOutlineButton
        mx={1}
        onClick={() => handlers.bulkEdit({ orderId })}
        key="bulkEdit"
        style={{
          flexShrink: 0
        }}
      >
        Bulk Edit
      </BigOutlineButton>,
      <BigOutlineButton
        onClick={() => handlers.addItems(orderId)}
        LeftIcon={withProps({ size: 18 })(AddIcon)}
        key="addItems"
        style={{
          flexShrink: 0
        }}
      >
        Add items to order
      </BigOutlineButton>
    ]
  ];

  return R.isEmpty(passes) ? null : (
    <Div
      bg="white"
      bc="neutral3"
      bb={1}
      bl={1}
      br={1}
      style={{ overflow: "visible" }}
    >
      <React.Fragment>
        <Div px={8} pt={3}>
          <Table rowsData={passes} />
        </Div>
        {restricted ? null : (
          <ArrayLayout components={buttons} mx={8} mt={3} pb={3} />
        )}
      </React.Fragment>
    </Div>
  );
};

export default decorate(Passes);
