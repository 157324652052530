import PropTypes from "prop-types";
import React, { Component } from "react";
import ClassNames from "classnames";
import autobind from "autobind-decorator";
import listensToClickOutside from "react-onclickoutside";

@listensToClickOutside
export default class OptionsDropdown extends Component {
  static propTypes = {
    topLinks: PropTypes.array,
    bottomLinks: PropTypes.array,
    bare: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.object,
    innerStyles: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }

  handleClickOutside(event) {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    }
  }

  render() {
    let classes = {
      "options-dropdown": true,
      bare: !!this.props.bare,
      open: this.state.isOpen
    };
    classes[`options-dropdown-${this.props.id}`] = true;
    classes = ClassNames(classes);

    let bottomLinks;
    if (this.props.bottomLinks) {
      bottomLinks = (
        <div className="options-dropdown-wrapper">
          {this.props.bottomLinks.map(item => (
            <div
              key={item.name}
              className="options-dropdown-item"
              onClick={item.click}
            >
              {item.name}
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className={classes} onClick={this._toggleOptions}>
        <span className="options-dropdown-label">
          {this.props.label ? (
            this.props.label
          ) : (
            <i className="icon icon-dots" />
          )}
        </span>
        <div className="options-dropdown-inner" style={this.props.innerStyles}>
          <div className="options-dropdown-arrow" />
          <div className="options-dropdown-wrapper">
            {this.props.topLinks.map(item => (
              <div
                key={item.name}
                className="options-dropdown-item"
                onClick={item.click}
              >
                {item.name}
              </div>
            ))}
          </div>
          {bottomLinks}
        </div>
      </div>
    );
  }

  @autobind
  _toggleOptions(event) {
    event.stopPropagation();
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
}
