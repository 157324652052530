import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../../model";
import { getUpdateModalData } from "../../selectors";
import { EDIT_MODAL_DATA, DATE_FORMAT_TZ } from "../../constants";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import { Toggle, Input, DateInput } from "ui-kit/Form/View";
import { BigFilledButton } from "components/Base";

const decorate = R.compose(
  connect(
    (state, props) => ({
      showUpdateAssignFormModal: getters.showUpdateAssignFormModal(
        state,
        props
      ),
      data: getUpdateModalData(state, props)
    }),
    {
      hideUpdateModal: () => actions.setShowUpdateAssignFormModal(false),
      updateModalData: actions.updateModalData
    }
  ),
  CSSModules(css)
);

const EditRowModal = ({
  showUpdateAssignFormModal,
  hideUpdateModal,
  data,
  updateModalData
}) =>
  showUpdateAssignFormModal ? (
    <MiniModalWrapper
      showModal
      hideModal={hideUpdateModal}
      title={`Edit ${R.prop(EDIT_MODAL_DATA.NAME, data)}`}
      display="column.flex-start.stretch"
      width={450}
      height={400}
    >
      <div styleName="edit-modal">
        <div styleName="body">
          <div styleName="fieldWrapper">
            <div styleName="label">Alias</div>
            <Input
              fieldId={EDIT_MODAL_DATA.ALIAS}
              iniValue={R.prop(EDIT_MODAL_DATA.ALIAS, data) || ""}
              size="big"
              width={1}
            />
          </div>
          <div styleName="fieldWrapper">
            <div styleName="label">Required</div>
            <Toggle
              size="small"
              fieldId={EDIT_MODAL_DATA.REQUIRED}
              iniValue={R.prop(EDIT_MODAL_DATA.REQUIRED, data)}
              bg="green6"
            />
          </div>
          <div styleName="fieldWrapper">
            <div styleName="label">Due Date</div>
            <DateInput
              fieldId={EDIT_MODAL_DATA.DUE_DATE}
              iniValue={R.prop(EDIT_MODAL_DATA.DUE_DATE, data) || ""}
              format={DATE_FORMAT_TZ}
              size="big"
              inputType="outline"
              showTime
              showCalendar
              showClearBtn
              style={{ width: 350 }}
            />
          </div>
        </div>
        <div styleName="footer">
          <BigFilledButton bg="primary7" onClick={updateModalData}>
            Update
          </BigFilledButton>
        </div>
      </div>
    </MiniModalWrapper>
  ) : null;

export default decorate(EditRowModal);
