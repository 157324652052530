import * as R from "ramda";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { createStructuredSelector } from "reselect";

import { Text4, Div } from "components/Base";

import Tabs from "ui-kit/Tabs/View";
import Label from "ui-kit/FormLabel";
import CollapsablePanel from "ui-kit/CollapsiblePanel";

import ItemBlocks from "./ItemBlocks";
import ManageView from "./ManageView";
import PeopleTable from "Submission/PeopleBlockTable/View";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import BulkEditLineItemsModal from "Orders/BulkEditLineItemsModal";

import { getters as TabGetters } from "ui-kit/Tabs";
import { actions as PeopleBlockTableActions } from "Submission/PeopleBlockTable";

import { actions } from "../model";

import { showModal } from "redux/modules/modal/actions";

import { getTabs, getIsManageView } from "../selectors";

import { withProps } from "utils/General";

const views = {
  summary: ItemBlocks,
  people: PeopleTable
};

const getParams = createStructuredSelector({
  submissionId: R.path(["submission", "id"]),
  fieldId: R.path(["field", "id"])
});

const decorate = R.compose(
  withProps(props => ({ ...props, params: getParams(props) })),
  connect(
    (state, props) => ({
      selectedTab: TabGetters.selectedTab(state, props),
      tabs: getTabs(state, props),
      isManageView: getIsManageView(state, props)
    }),
    bindInstance({
      addTableId: id => PeopleBlockTableActions.addTableId(id),
      bulkEditDone: actions.bulkEditDone,
      showModal
    })
  )
);

class PeopleBlockViewer extends Component {
  constructor(props) {
    super(props);

    props.addTableId(props.field.id);
    this.handlers = {
      bulkEdit: ({ orderId, lineItemIds }) => {
        const where = {};

        if (orderId) {
          where.orderId = orderId;
        } else if (!R.isEmpty(lineItemIds)) {
          where.lineItemId = lineItemIds;
        }

        this.props.showModal({
          content: (
            <BulkEditLineItemsModal
              where={where}
              hideModal={() =>
                this.props.bulkEditDone(
                  R.path(["params", "submissionId"], this.props)
                )
              }
            />
          ),
          wrapper: ModalWrapper
        });
      }
    };
  }

  render() {
    const {
      required = false,
      readOnly = true,
      selectedTab = "",
      isManageView = false,
      tabs = [],
      updateRows = false,
      field,
      submission,
      instanceId,
      params
    } = this.props;

    return (
      <CollapsablePanel
        heading={
          <Label required={required}>
            <Text4 bold my={2}>
              {R.pathOr("", ["people_block", "block", "name"], field)}
            </Text4>
          </Label>
        }
        right={false}
        bg="transparent"
        bc="neutral2"
        ba={1}
        bra={2}
      >
        <Div>
          <Tabs
            tabs={tabs}
            bb={1}
            pl={4}
            bc="neutral2"
            iniSelected={0}
            instanceId={instanceId}
          />
          {React.createElement(
            isManageView ? ManageView : views[selectedTab] || views.summary,
            {
              saveAsDraft: false,
              updateRows,
              params,
              instanceId,
              submission,
              field,
              showApproval: false,
              mx: 3,
              mt: 2,
              handlers: this.handlers,
              readOnly,
              itemBlockId: selectedTab === "overview" ? null : selectedTab
            }
          )}
        </Div>
      </CollapsablePanel>
    );
  }
}

export default decorate(PeopleBlockViewer);
