import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";

import { eventDetails } from "redux/modules/event/selectors";
import { types as itemTypes } from "redux/modules/items/types/selectors";

import { getItemTypesByEvent } from "redux/modules/items/types/actions";

function mapStateToProps(state, props) {
  const { types, groups, items, variants } = itemTypes(state).reduce(
    (list, type) => {
      list.types.push(type);
      type.groups.forEach(group => {
        list.groups.push(group);
        group.items.forEach(item => {
          list.items.push(item);
          item.variants.forEach(variant => {
            list.variants.push({
              ...variant,
              item
            });
          });
        });
      });
      return list;
    },
    {
      types: [],
      groups: [],
      items: [],
      variants: []
    }
  );

  const typesToPass = types.filter(type => {
    if (!type.groups) return false;
    const countOfItems = type.groups.reduce((count, group) => {
      if (group.items) {
        count += group.items.length;
      }
      return count;
    }, 0);
    return countOfItems > 0;
  });

  const selectedVariant = variants.find(v => v.id === props.variantId);
  const activeTypeId = selectedVariant
    ? selectedVariant.item.type_id
    : typesToPass.length
      ? typesToPass[0].id
      : null;

  return {
    eventDetails: eventDetails(state),
    types: typesToPass,
    groups,
    items,
    variants,
    selectedVariant,
    activeTypeId
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getItemTypesByEvent
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controller);
