import Immutable from "immutable";
import { RECEIVE } from "./constants";

const resources = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn(["byType", action.payload.resourceType], action.payload.data)
        .toJS();
    default:
      return state;
  }
};

export default resources;
