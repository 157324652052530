import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getters } from "Modules/ImportModal";
import { Div } from "components/Base";
import Header from "./Header";
import Body from "./ModalBody";
import Loading from "./Loading";
import { getLayoutWidth } from "Modules/ImportModal/selectors";

const decorate = connect(state => ({
  loading: getters.loading(state),
  width: getLayoutWidth(state)
}));

const Frame = ({ children, width }) => (
  <Div m={5} style={{ overflow: "none" }} display="row.center.center">
    <Div
      display="column.flex-start"
      bra={1}
      shadow={2}
      bg="white"
      height={576}
      width={width}
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxHeight: "calc(100% - 100px)"
      }}
      onMouseDown={e => e.stopPropagation()}
    >
      {children}
    </Div>
  </Div>
);

const DecoratedFrame = decorate(Frame);

const Layout = props => {
  const { loading, handlers } = props;
  return (
    <DecoratedFrame>
      {loading && <Loading />}
      <Header handlers={handlers} />
      <Div
        height={500}
        style={{
          overflowY: "auto"
        }}
      >
        <Body handlers={handlers} />
      </Div>
    </DecoratedFrame>
  );
};

Layout.propTypes = {
  loading: PropTypes.bool
};

export default decorate(Layout);
