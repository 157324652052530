import React from "react";
// import { Collapse } from "react-collapse";
import { withStateHandlers } from "../utils";

const Collapse = ({ children, isOpened }) => (isOpened ? children : null);

const collapsableHandler = withStateHandlers(
  ({ collapsedDefault = false }) => ({
    collapsed: collapsedDefault
  }),
  {
    toggleCollapsed: ({ collapsed }) => () => ({
      collapsed: !collapsed
    })
  }
);

const Collapsable = ({ children, collapsed, ...other }) => (
  <Collapse isOpened={!collapsed} {...other}>
    {children}
  </Collapse>
);

export { Collapsable, collapsableHandler };
