import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../model";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import {
  BigOutlineButton,
  DownloadIcon,
  SendIcon,
  AddPersonIcon
} from "components/Base";
import { getIsPeopleSelected } from "../selectors";
import SearchBar from "ui-kit/SearchBar/View";

const decorate = R.compose(
  connect(
    state => ({
      data: getters.data(state),
      selectedPeopleIds: getters.selectedPeopleIds(state),
      isPeopleSelected: getIsPeopleSelected(state)
    }),
    {
      download: actions.download,
      showOpenEmailsModal: actions.showOpenEmailsModal,
      showAddStaffModal: actions.showAddStaffModal,
      clearSelectedPeopleIds: actions.clearSelectedPeopleIds,
      handleSelectedPeopleIds: actions.handleSelectedPeopleIds,
      showAddPersonModal: actions.showAddPersonModal
    }
  ),
  CSSModules(css)
);

const Header = ({
  data,
  download,
  showOpenEmailsModal,
  showAddStaffModal,
  selectedPeopleIds,
  clearSelectedPeopleIds,
  isPeopleSelected,
  handleSelectedPeopleIds,
  showAddPersonModal
}) => (
  <div styleName="header">
    <div styleName="title">{R.propOr("", "page_title", data)}</div>
    <div
      dangerouslySetInnerHTML={{
        __html: R.propOr("", "page_description", data)
      }}
    />
    <div
      styleName="buttons"
      style={
        isPeopleSelected
          ? {
              flexDirection: "row",
              alignItems: "center"
            }
          : {}
      }
    >
      {!R.isEmpty(selectedPeopleIds) ? (
        <div styleName="peopleSelected">
          {R.length(selectedPeopleIds)} selected
        </div>
      ) : null}
      <div styleName="row">
        {R.isEmpty(selectedPeopleIds) ? (
          <BigOutlineButton onClick={download} LeftIcon={DownloadIcon}>
            Download
          </BigOutlineButton>
        ) : null}
        <BigOutlineButton
          onClick={showOpenEmailsModal}
          LeftIcon={SendIcon}
          ml={1}
        >
          Send Emails
        </BigOutlineButton>
        {!R.isEmpty(selectedPeopleIds) ? (
          <BigOutlineButton onClick={handleSelectedPeopleIds} ml={1}>
            Submit Requests
          </BigOutlineButton>
        ) : null}
      </div>
      <div styleName="buttons-right">
        {/* {R.isEmpty(selectedPeopleIds) ? ( */}
        <BigOutlineButton
          onClick={showAddStaffModal}
          LeftIcon={AddPersonIcon}
          mr={1}
          onClick={showAddPersonModal}
        >
          Add Staff
        </BigOutlineButton>
        {/* ) : null} */}

        {R.isEmpty(selectedPeopleIds) ? (
          <SearchBar flex={1} placeholder="Search by name or email" />
        ) : null}
      </div>
      {!R.isEmpty(selectedPeopleIds) ? (
        <div styleName="clearSelected" onClick={clearSelectedPeopleIds}>
          Clear selected
        </div>
      ) : null}
    </div>
  </div>
);

export default decorate(Header);
