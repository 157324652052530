import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getters, actions } from "../model";

import Loading from "ui-kit/Loading";
import { Page, Title } from "EventLight/Common/FieldLayout";
import Body from "./Body";
import AddAssignmentModal from "EventLight/HealthPass/AddAssignmentModal/View";

const decorate = connect(
  state => ({
    loading: getters.loading(state),
    showAddAssignmentModal: getters.showAddAssignmentModal(state)
  }),
  {
    init: actions.init
  }
);

const Layout = ({ loading, showAddAssignmentModal, init }) => {
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Page>
        <Title
          icon="health_and_safety"
          title="Assign"
          description="Select individuals or types that should fill out questionnaires"
        />

        {loading ? <Loading /> : <Body />}
      </Page>
      {showAddAssignmentModal ? <AddAssignmentModal /> : null}
    </>
  );
};

export default decorate(Layout);
