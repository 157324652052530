import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";
import { withRouter } from "react-router";

import { getEvent } from "redux/modules/event/actions";
import { getCredentials } from "redux/modules/user/selectors";

import { eventDetails } from "redux/modules/event/selectors";

function mapStateToProps(state) {
  return {
    details: eventDetails(state),
    userCredentials: getCredentials(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getEvent }, dispatch);
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
