import { createSelector } from "reselect";

import * as R from "ramda";

import { getters } from "Portal/PortalSession/model";
import { getters as TabsGetters } from "ui-kit/Tabs";

import {
  SESSION_DETAILS_TYPES,
  CONFERENCE_TABS_INSTANCE_ID
} from "./constants";

export const sections = createSelector(
  getters.footerSections,
  R.indexBy(R.prop("name"))
);

export const getLeftSidebarSections = createSelector(
  getters.leftSidebarSections,
  R.map(({ name }) => ({
    id: R.toLower(name),
    title: name
  }))
);

export const parsedSections = createSelector(
  getters.leftSidebarSections,
  R.compose(
    R.map(
      R.cond([[R.propEq("type", "sessions"), R.identity], [R.T, R.identity]])
    ),
    R.sortBy(R.prop("id"))
  )
);

export const currentSection = createSelector(
  parsedSections,
  getters.leftSidebarSections,
  (selectedTab, sections) => {
    if (R.length(sections) === 1) {
      return sections[0];
    }

    return (
      R.find(session => selectedTab === R.toLower(session.name), sections) || {}
    );
  }
);

export const sessionTabs = createSelector(
  getters.sessionDetails,
  sessionDetails => {
    const tabs = [{ title: "Details", id: "details" }];
    const optionalTabs = SESSION_DETAILS_TYPES.filter(({ id }) =>
      sessionDetails.hasOwnProperty(id)
    ).map(type => ({
      ...type,
      count: sessionDetails[type.id].length
    }));
    return tabs.concat(optionalTabs);
  }
);

export const selectedSessionTabs = createSelector(
  state =>
    TabsGetters.selectedTab(state, {
      instanceId: CONFERENCE_TABS_INSTANCE_ID
    }),
  R.identity
);
