import {
  put,
  all,
  take,
  takeEvery,
  fork,
  select,
  call
} from "redux-saga/effects";
import * as R from "ramda";
import Helpers from "utils/Global/Helpers";

import {
  TABS,
  FIELD_IDS,
  SESSION_FORM_ID,
  DATE_FORMAT,
  LOCATION_TYPE_IDS,
  CONTENT_MODAL_ID
} from "EventLight/Agenda/AddSessionModal/constants";
import moment from "moment-timezone";
import { CONTENT_VIDEO } from "./constants";

import { getCredentials } from "redux/modules/user/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { getEditedField } from "./selectors";

import { actions, getters } from "EventLight/Agenda/AddSessionModal/model";
import { registerError } from "redux/modules/errors/actions";
import { hideModal } from "redux/modules/modal/actions";
import { actions as MediaContentActions } from "ui-kit/MediaContentModal/model";
import { actions as FormActions } from "ui-kit/Form/model";

import { setOtherZone, setLocalZone } from "utils/General";

import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventDetails = yield select(getEventDetails);

  return {
    credentials,
    eventDetails
  };
};

const init = function*({ payload: { sessionId } }) {
  try {
    yield put(actions.setLoading(true));
    const { credentials } = yield call(getParams);

    let sessionData = null;

    if (sessionId) {
      const { payload } = yield call(Api.getSession, {
        credentials,
        sessionId
      });

      sessionData = payload;
    }

    yield put(
      actions.setInitialData({
        editing: Boolean(sessionId),
        sessionId,
        sessionData,
        venues: [],
        eventTags: []
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while loading venues"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const saveAsDraft = function*() {};

const getFilePicker = () =>
  new Promise(resolve => {
    const options = {
      multiple: false,
      accept: [
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/svg+xml",
        "image/png"
      ]
    };

    const path = { path: "session-modal/" };

    Helpers.getFilepicker(options, path, resolve);
  });

const uploadHeaderImg = function*() {
  const files = yield call(getFilePicker);
  const url = files[0].url;

  yield put(actions.updateField({ field: "background_image_url", value: url }));
};

const save = function*({ payload: { onDone } = {} }) {
  try {
    const editing = yield select(getters.editing);
    const sessionId = yield select(getters.sessionId);

    const { credentials, eventDetails } = yield call(getParams);

    const data = yield select(getters.data);

    const toSave = {
      sessionId,
      eventId: eventDetails.id,
      title: data.title,
      startsAt: data.starts_at,
      endsAt: data.ends_at,
      description: data.description,
      venueId: data.venue_id,
      isOnlineSession: data.is_online_session,
      backgroundImageUrl: data.background_image_url,
      isPublic: data.is_public,
      requireRsvp: data.require_rsvp,
      rsvpIsClosed: data.rsvp_is_closed,
      rsvpStartsAt: data.rsvp_starts_at,
      rsvpEndsAt: data.rsvp_ends_at,
      rsvpRequiresApproval: data.rsvp_requires_approval,
      enableCapacity: data.enable_capacity,
      capacity: data.capacity,
      enableWaitlist: data.enable_waitlist,
      waitlistCapacity: data.waitlist_capacity,
      sendReminders: data.send_reminders,
      videoUrl: data.video_url,
      videoThumbnailUrl: data.video_thumbnail_url,
      videoTitle: data.video_title,
      videoRestrictAccess: data.video_restrict_access,
      enableQuestionQueue: data.enable_question_queue,
      conferenceUrl: data.conference_url,
      conferenceThumbnailUrl: data.conference_thumbnail_url,
      conferenceTitle: data.conference_title,
      conferenceRestrictAccess: data.conference_restrict_access,
      revealMinutesBefore: data.reveal_minutes_before,
      transcriptUrl: data.transcript_url,
      replayUrl: data.replay_url,
      //
      is_public: data.is_public,
      location: data.location,
      locationStageId:
        data.location === LOCATION_TYPE_IDS.STAGE && data.location_stage_id
          ? R.path([0, "value"])(data.location_stage_id)
          : null,
      locationRoomId:
        data.location === LOCATION_TYPE_IDS.ROOM && data.location_room_id
          ? R.path([0, "value"])(data.location_room_id)
          : null,
      locationBoothId:
        data.location === LOCATION_TYPE_IDS.BOOTH && data.location_booth_id
          ? R.path([0, "value"])(data.location_booth_id)
          : null,
      locationExternalUrl:
        data.location === LOCATION_TYPE_IDS.EXTERNAL &&
        data.location_external_url
          ? data.location_external_url
          : null,
      tags:
        data.tags && data.tags.length ? R.map(R.prop("value"))(data.tags) : [],
      sponsors:
        data.sponsors && data.sponsors.length
          ? R.map(R.prop("value"))(data.sponsors)
          : [],
      speakers:
        data.speakers && data.speakers.length
          ? R.map(R.prop("value"))(data.speakers)
          : [],
      relatedContent:
        data.related_content && data.related_content.length
          ? R.map(R.prop("id"))(data.related_content)
          : [],
      playRecordedVideoContentId: R.propEq(
        FIELD_IDS.LOCATION,
        LOCATION_TYPE_IDS.STAGE,
        data
      )
        ? R.pathOr(null, ["play_recorded_video_content", "id"], data)
        : null
    };

    if (editing) {
      yield call(Api.updateSession, {
        credentials,
        data: toSave
      });
    } else {
      yield call(Api.addSession, {
        credentials,
        data: toSave
      });
    }

    if (onDone) {
      onDone();
    }

    yield put(hideModal());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while saving session"
        }
      ])
    );
  }
};

const nextPage = function*({ payload: { onDone } }) {
  const activeTab = yield select(getters.activeTab);

  if (activeTab === TABS.BASIC_DETAILS) {
    // yield put(actions.setActiveTab(TABS.ATTENDEE_SETTINGS));
    // yield put(actions.setActiveTab(TABS.CONTENT));
    yield put(actions.save({ onDone }));
  } else if (activeTab === TABS.ATTENDEE_SETTINGS) {
    yield put(actions.setActiveTab(TABS.CONTENT));
  } else {
    yield put(actions.save({ onDone }));
  }
};

const previousPage = function*() {
  const activeTab = yield select(getters.activeTab);

  if (activeTab === TABS.CONTENT) {
    // yield put(actions.setActiveTab(TABS.ATTENDEE_SETTINGS));
    yield put(actions.setActiveTab(TABS.BASIC_DETAILS));
  } else if (activeTab === TABS.ATTENDEE_SETTINGS) {
    yield put(actions.setActiveTab(TABS.BASIC_DETAILS));
  }
};

const fieldUpdate = function*({ id, fieldId }) {
  const eventDetails = yield select(getEventDetails);
  const data = yield select(getters.data);

  const newValue = yield select(getEditedField, {
    instanceId: id,
    fieldId
  });

  const processedValue = R.propOr(newValue, fieldId, {
    [FIELD_IDS.LOCATION]: R.path([0], newValue),
    // [FIELD_IDS.STAGE]: R.path([0, "value"], newValue),
    // [FIELD_IDS.BOOTH]: R.path([0, "value"], newValue),
    // [FIELD_IDS.ROOM]: R.path([0, "value"], newValue),
    [FIELD_IDS.BACKGROUND_IMAGE_URL]: R.path([0, "url"], newValue),
    [FIELD_IDS.STARTS_AT]: moment
      .tz(
        moment(
          setOtherZone(newValue, R.prop("timezone", eventDetails))
        ).toDate(),
        DATE_FORMAT,
        R.prop("timezone", eventDetails)
      )
      .utc()
      .format(),
    [FIELD_IDS.ENDS_AT]: moment
      .tz(
        moment(
          setOtherZone(newValue, R.prop("timezone", eventDetails))
        ).toDate(),
        DATE_FORMAT,
        R.prop("timezone", eventDetails)
      )
      .utc()
      .format()
  });

  if (fieldId === FIELD_IDS.STARTS_AT) {
    const start = moment(processedValue);
    let end = moment(data.ends_at);
    if (R.isNil(data.ends_at) || start.isAfter(end)) {
      end = start
        .clone()
        .add(1, "hours")
        .utc()
        .format();

      yield all([
        put(
          actions.updateField({
            field: FIELD_IDS.ENDS_AT,
            value: end
          })
        ),
        put(
          FormActions.setFieldValue(
            setLocalZone(end, R.prop("timezone", eventDetails)),
            {
              meta: {
                instanceId: SESSION_FORM_ID,
                fieldId: FIELD_IDS.ENDS_AT
              }
            }
          )
        )
      ]);
    }
  } else if (fieldId === FIELD_IDS.ENDS_AT) {
    const end = moment(processedValue);
    let start = moment(data.starts_at);
    if (R.isNil(data.starts_at) || end.isBefore(start)) {
      start = end
        .clone()
        .subtract(1, "hours")
        .utc()
        .format();

      yield all([
        put(
          actions.updateField({
            field: FIELD_IDS.STARTS_AT,
            value: start
          })
        ),
        put(
          FormActions.setFieldValue(
            setLocalZone(start, R.prop("timezone", eventDetails)),
            {
              meta: {
                instanceId: SESSION_FORM_ID,
                fieldId: FIELD_IDS.STARTS_AT
              }
            }
          )
        )
      ]);
    }
  }

  yield put(actions.updateField({ field: fieldId, value: processedValue }));
};

const watchUpdateFields = function*() {
  for (;;) {
    const {
      meta: { instanceId, fieldId }
    } = yield take(FormActions.setFieldValue.type);
    if (instanceId === SESSION_FORM_ID) {
      yield call(fieldUpdate, {
        id: instanceId,
        fieldId
      });
    }
  }
};

const showContentModal = function*({ payload = "" }) {
  yield all([
    put(actions.setIsVideoContent(payload === CONTENT_VIDEO)),
    put(
      MediaContentActions.setShowModal(true, {
        meta: { instanceId: CONTENT_MODAL_ID }
      })
    )
  ]);
};

const watchShowContentModal = function*() {
  yield takeEvery(actions.showContentModal.type, showContentModal);
};

const watchHandleDraftCancel = function*() {
  yield takeEvery(actions.saveAsDraft.type, saveAsDraft);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchSave = function*() {
  yield takeEvery(actions.save.type, save);
};

const watchNextPage = function*() {
  yield takeEvery(actions.nextPage.type, nextPage);
};

const watchPreviousPage = function*() {
  yield takeEvery(actions.previousPage.type, previousPage);
};

const watchUploadHeaderImg = function*() {
  yield takeEvery(actions.uploadHeaderImg.type, uploadHeaderImg);
};

const watchSelectMedia = function*() {
  for (;;) {
    const {
      payload,
      meta: { instanceId }
    } = yield take(MediaContentActions.selectRecord.type);
    if (instanceId === CONTENT_MODAL_ID) {
      const isVideoContent = yield select(getters.isVideoContent);

      if (isVideoContent) {
        yield put(actions.addContentVideo(payload));
      } else {
        yield put(actions.addContent(payload));
      }
    }
  }
};

const rootSaga = function*() {
  yield all([
    fork(watchHandleDraftCancel),
    fork(watchInit),
    fork(watchSave),
    fork(watchNextPage),
    fork(watchPreviousPage),
    fork(watchUpdateFields),
    fork(watchUploadHeaderImg),
    fork(watchSelectMedia),
    fork(watchShowContentModal)
  ]);
};

export default rootSaga;
