import React from "react";
import { connect } from "react-redux";

import { actions } from "../model";
import { getItemBlocksCount } from "../selectors";

import Table, { TableWrapper } from "ui-kit/Table/View";
import { Div, ListIcon } from "components/Base";

import { noop } from "utils/General";

const ItemBlocksTable = () => (
  <TableWrapper>
    <Table showOpenRecord={true} showCheckbox={false} />
  </TableWrapper>
);

const decorate = connect(
  (state, props) => ({
    count: getItemBlocksCount(state, props)
  }),
  { showAddItemBlockModal: actions.addItemBlock }
);

const ItemBlocks = ({ count = 0, showAddItemBlockModal = noop }) => (
  <Div mb={5}>
    <Div display="row.flex-start.center" mb={3}>
      <Div
        display="column.center.center"
        pill
        bg="gray1"
        width={34}
        height={34}
        mr={2}
      >
        <ListIcon color="gray4" size={23} />
      </Div>
      <Div fs={3} fw={4}>
        Item Blocks
      </Div>
    </Div>
    <Div my={3} bra={1} shadow={1} bg="white">
      {count > 0 ? (
        <ItemBlocksTable />
      ) : (
        <Div p={4} color="gray7" fs={2}>
          You haven't added any item blocks.
        </Div>
      )}
      <Div bc="gray1" bt={1} px={4} py={3} display="row.space-between.center">
        <Div onClick={showAddItemBlockModal} fs={2} color="purple8" fw={3}>
          + Add
        </Div>
      </Div>
    </Div>
  </Div>
);

export default decorate(ItemBlocks);
