import React, { Component } from "react";
import View from "./View";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";
import * as R from "ramda";
import {
  DO_NOT_MAP,
  MAP_TO_EXISTING,
  NEW_ITEM,
  SELECT_ITEM
} from "./constants";

const mapIntItemsToLenndItems = intItems => {
  const items = {};
  intItems.forEach(item => {
    if (items[item.id]) {
      items[item.id].lenndItemIds = [
        ...items[item.id].lenndItemIds,
        ...item.lenndItemIds
      ];
    } else {
      items[item.id] = {
        method: MAP_TO_EXISTING,
        lenndItemIds: item.lenndItemIds
      };
    }
  });
  return items;
};

const mapLenndToIntItems = intItems => {
  const items = {};
  intItems.forEach(item => {
    item.lenndItemIds.forEach(lenndItemId => {
      items[lenndItemId] = item.id;
    });
  });
  return items;
};

class EditIntegration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "not-imported",
      searchTerm: "",
      saving: false,
      loading: true,
      intToLenndItemsMap: mapIntItemsToLenndItems(props.ticketTypes),
      lenndToIntItemsMap: mapLenndToIntItems(props.ticketTypes)
    };
  }

  async componentDidMount() {
    await Promise.all([
      this.props.getTicketTypes(
        this.props.providerConfiguration,
        this.props.params.eventId
      ),
      this.props.getTicketTypeMapping(
        this.props.providerConfiguration,
        this.props.params.eventId
      ),
      this.props.getItemGroupsByEventAndType(
        this.props.params.eventId,
        CREDENTIAL_TYPE_ID
      )
    ]);

    this.setState({ loading: false });
  }

  componentDidUpdate(oldProps) {
    if (
      !R.equals(oldProps.ticketTypes, this.props.ticketTypes) ||
      !R.equals(oldProps.ticketTypeMapping, this.props.ticketTypeMapping)
    ) {
      this.setState({
        intToLenndItemsMap: mapIntItemsToLenndItems(
          this.props.ticketTypes,
          this.props.ticketTypeMapping
        ),
        lenndToIntItemsMap: mapLenndToIntItems(
          this.props.ticketTypes,
          this.props.ticketTypeMapping
        )
      });
    }
  }

  handleSave = async () => {
    this.setState({
      saving: true
    });

    const providerId = this.props.providerConfiguration.provider.id;
    const providerItemGroupName = `${
      this.props.providerConfiguration.provider.name
    }`;

    // get item group to add new items to
    let providerItemGroup = this.props.itemGroups.find(
      group => group.name === providerItemGroupName
    );
    if (!providerItemGroup) {
      const result = await this.props.addItemGroup({
        eventId: this.props.eventDetails.id,
        typeId: CREDENTIAL_TYPE_ID,
        order:
          this.props.itemGroups[this.props.itemGroups.length - 1].order + 1,
        name: providerItemGroupName
      });
      providerItemGroup = result.payload;
    }

    let ticketMap = [];
    let lenndItemsToUpdate = [];

    if (this.state.activeTab === "not-imported") {
      const mappedLenndItemIds = [];

      // iterate over ticket types
      for (const ticketTypeId of R.keys(this.state.intToLenndItemsMap)) {
        if (
          (this.state.intToLenndItemsMap[ticketTypeId] &&
            this.state.intToLenndItemsMap[ticketTypeId].lenndItemIds &&
            this.state.intToLenndItemsMap[ticketTypeId].lenndItemIds.length,
          this.state.intToLenndItemsMap[ticketTypeId].method ===
            MAP_TO_EXISTING)
        ) {
          // handle mapping ticket type to existing lennd item
          this.state.intToLenndItemsMap[ticketTypeId].lenndItemIds.forEach(
            lenndItemId => {
              ticketMap.push({
                ticket_type_id: ticketTypeId,
                lennd_id: lenndItemId,
                provider_id: providerId
              });
              mappedLenndItemIds.push(lenndItemId);
            }
          );
        } else if (
          this.state.intToLenndItemsMap[ticketTypeId].method === NEW_ITEM
        ) {
          // handle creating new lennd item and mapping ticket type to it
          const ticketType = this.props.ticketTypes.find(
            ticket => ticket.id === ticketTypeId
          );
          const createLenndItemResult = await this.props.addItem({
            item: {
              typeId: CREDENTIAL_TYPE_ID,
              groupId: providerItemGroup.id,
              name: ticketType.name,
              providerId: providerId
            }
          });
          const createdVariantId = createLenndItemResult.payload.variants[0].id;
          ticketMap.push({
            ticket_type_id: ticketTypeId,
            lennd_id: createdVariantId,
            provider_id: providerId
          });
          mappedLenndItemIds.push(createdVariantId);
        } else {
          // handle marking ticket type as not mapped
          ticketMap.push({
            ticket_type_id: ticketTypeId,
            lennd_id: null,
            provider_id: providerId
          });
        }
      }

      lenndItemsToUpdate = R.map(item => ({
        id: item.id,
        providerId: mappedLenndItemIds.includes(item.id) ? providerId : null
      }))(this.props.items);
    } else {
      // iterate over lennd items and populate mapped item
      for (const lenndItemId of R.keys(this.state.lenndToIntItemsMap)) {
        if (this.state.lenndToIntItemsMap[lenndItemId]) {
          ticketMap.push({
            ticket_type_id: this.state.lenndToIntItemsMap[lenndItemId],
            lennd_id: lenndItemId,
            provider_id: providerId
          });
        }
      }

      lenndItemsToUpdate = R.map(item => ({
        id: item.id,
        providerId: Boolean(this.state.lenndToIntItemsMap[item.id])
          ? providerId
          : null
      }))(this.props.items);

      // @TODO: This needs to be completed after multiple lennd passes are able to be
      // mapped to one integration item

      // we need to account for the lennd items that were originally mapped, but were
      // unmapped during this session
      for (const existingMappedItem of this.props.ticketTypeMapping) {
        if (
          !this.state.lenndToIntItemsMap[existingMappedItem.lenndTicketTypeId]
        ) {
          lenndItemsToUpdate.push({
            id: existingMappedItem.lenndTicketTypeId,
            providerId: null
          });

          ticketMap.push({
            ticket_type_id: null,
            lennd_id: existingMappedItem.lenndTicketTypeId,
            provider_id: providerId
          });
        }
      }
    }

    // save updated ticket type mapping to integration
    await this.props.syncTicketTypes(
      this.props.providerConfiguration,
      this.props.eventDetails.id,
      ticketMap
    );

    // save updated lennd item mapping
    await this.props.bulkUpdateVariants({
      eventId: this.props.params.eventId,
      bulk: true,
      variants: lenndItemsToUpdate
    });

    this.props.showSnackbar({
      message: "Your updates have been saved",
      action: "OK"
    });

    this.props.hideModal();
  };

  goToTab = id =>
    this.setState({
      activeTab: id
    });

  onSearch = searchTerm =>
    this.setState({
      searchTerm
    });

  updateIntToLenndItemsMap = (ticketTypeId, mapping) => {
    this.setState(state => {
      state.intToLenndItemsMap[ticketTypeId] = {
        method: MAP_TO_EXISTING,
        lenndItemIds: []
      };

      R.keys(mapping).forEach(lenndItemId => {
        if (mapping[lenndItemId]) {
          state.intToLenndItemsMap[ticketTypeId].lenndItemIds.push(lenndItemId);
        }
      });

      return state;
    });
  };

  updateIntToLenndItemsMethod = (ticketTypeId, method) => {
    this.setState(state => {
      state.intToLenndItemsMap[ticketTypeId] = {
        ...state.intToLenndItemsMap[ticketTypeId],
        method: method
      };

      return state;
    });
  };

  updateLenndToIntItemsMap = (lenndItemId, mapping) => {
    this.setState(state => {
      state.lenndToIntItemsMap[lenndItemId] = null;
      R.keys(mapping).forEach(ticketTypeId => {
        if (mapping[ticketTypeId]) {
          state.lenndToIntItemsMap[lenndItemId] = ticketTypeId;
        }
      });
      return state;
    });
  };

  render() {
    const {
      hideModal,
      providerConfiguration,
      ticketTypes,
      ticketTypeMapping,
      itemGroups
    } = this.props;
    const {
      activeTab,
      searchTerm,
      saving,
      loading,
      intToLenndItemsMap,
      lenndToIntItemsMap
    } = this.state;

    const tabs = [
      {
        id: "not-imported",
        name: "Not Imported",
        onClick: () => this.goToTab("not-imported"),
        active: activeTab === "not-imported"
      },
      {
        id: "update-mapping",
        name: "Update Mapping",
        onClick: () => this.goToTab("update-mapping"),
        active: activeTab === "update-mapping"
      }
    ];

    const ticketTypeHasLenndItemIds = ticketTypeId =>
      intToLenndItemsMap[ticketTypeId] &&
      intToLenndItemsMap[ticketTypeId].lenndItemIds &&
      intToLenndItemsMap[ticketTypeId].lenndItemIds.length;

    const isLenndItemIdReferencedByOtherTicketType = (
      thisTicketTypeId,
      thisLenndItemId
    ) => {
      for (const ticketTypeId of R.keys(intToLenndItemsMap)) {
        if (
          ticketTypeId !== thisTicketTypeId &&
          intToLenndItemsMap[ticketTypeId].lenndItemIds &&
          intToLenndItemsMap[ticketTypeId].lenndItemIds.length
        ) {
          for (const lenndItemId of intToLenndItemsMap[ticketTypeId]
            .lenndItemIds) {
            if (thisLenndItemId === lenndItemId) {
              return true;
            }
          }
        }
      }
      return false;
    };

    const getItemGroupsForTicketType = ticketTypeId =>
      R.map(group => ({
        ...group,
        items: R.map(({ id, name }) => {
          return {
            id,
            name,
            qty:
              ticketTypeHasLenndItemIds(ticketTypeId) &&
              intToLenndItemsMap[ticketTypeId].lenndItemIds.includes(id)
                ? 1
                : 0,
            // disable if item is already referenced by another ticket type
            disabled: isLenndItemIdReferencedByOtherTicketType(ticketTypeId, id)
          };
        })(group.items)
      }))(itemGroups);

    const getTicketTypesForLenndItem = lenndItemId => [
      {
        id: "types",
        name: `${providerConfiguration.provider.name} Items`,
        items: R.map(({ id, name }) => ({
          id,
          name,
          qty: lenndToIntItemsMap[lenndItemId] === id ? 1 : 0
        }))(ticketTypes)
      }
    ];

    const ticketTypesToPass =
      activeTab === "not-imported"
        ? R.filter(t => !t.lenndItemIds.length)(ticketTypes)
        : ticketTypes;

    let ticketTypesWithHandlers = R.map(ticketType => {
      const hasLenndItems = ticketTypeHasLenndItemIds(ticketType.id);
      const method = intToLenndItemsMap[ticketType.id]
        ? intToLenndItemsMap[ticketType.id].method
        : null;
      return {
        ...ticketType,
        isSelected:
          (method === MAP_TO_EXISTING && hasLenndItems) || method === NEW_ITEM,
        method:
          method === NEW_ITEM ? NEW_ITEM : hasLenndItems ? method : DO_NOT_MAP,
        countOfSelectedItems: hasLenndItems
          ? intToLenndItemsMap[ticketType.id].lenndItemIds.length
          : 0,
        getItemGroups: () => getItemGroupsForTicketType(ticketType.id),
        onUpdateMethod: method =>
          this.updateIntToLenndItemsMethod(ticketType.id, method),
        onUpdateMapping: mapping =>
          this.updateIntToLenndItemsMap(ticketType.id, mapping),
        allowedMethods: [DO_NOT_MAP, NEW_ITEM, MAP_TO_EXISTING]
      };
    })(ticketTypesToPass);

    let itemGroupsWithHandlers = R.compose(
      R.filter(g => g.items.length),
      R.map(group => ({
        ...group,
        items: R.map(({ id, name, background_color }) => {
          const isSelected = Boolean(lenndToIntItemsMap[id]);
          const selectedTicketType = isSelected
            ? ticketTypes.find(t => t.id === lenndToIntItemsMap[id])
            : null;
          return {
            id,
            name,
            color: background_color || "#ccc",
            method: SELECT_ITEM,
            isSelected,
            selectedTicketTypeName: selectedTicketType
              ? selectedTicketType.name
              : null,
            onUpdateMapping: mapping =>
              this.updateLenndToIntItemsMap(id, mapping),
            getItemGroups: () => getTicketTypesForLenndItem(id),
            allowedMethods: [SELECT_ITEM]
          };
        })(group.items)
      }))
    )(itemGroups);

    const countOfItemsToBeMapped = R.compose(
      R.length,
      R.filter(R.prop("isSelected"))
    )(ticketTypesWithHandlers);

    // filter down based on search if > 1 character entered
    if (searchTerm.length > 1) {
      if (activeTab === "not-imported") {
        ticketTypesWithHandlers = R.filter(ticketType =>
          ticketType.name.toLowerCase().includes(searchTerm)
        )(ticketTypesWithHandlers);
      } else {
        itemGroupsWithHandlers = R.compose(
          R.filter(g => g.items.length),
          R.map(group => ({
            ...group,
            items: R.filter(item =>
              item.name.toLowerCase().includes(searchTerm)
            )(group.items)
          }))
        )(itemGroupsWithHandlers);
      }
    }

    return (
      <View
        {...{
          onSave: this.handleSave,
          onCancel: hideModal,
          onSearch: this.onSearch,
          //
          hideModal,
          ticketTypes: ticketTypesWithHandlers,
          tabs,
          searchTerm,
          hasSearchTerm: Boolean(searchTerm.length),
          integrationName: providerConfiguration.provider.name,
          activeTab,
          saving,
          loading,
          itemGroups: itemGroupsWithHandlers,
          countOfItemsToBeMapped,
          goToMappedItems: () => this.goToTab("update-mapping")
        }}
      />
    );
  }
}

export default EditIntegration;
