import mustache from "mustache";
import { Parser } from "expr-eval";
import getValue from "../get-value";

const getShallowValues = (row, fields) =>
  fields.reduce((values, field) => {
    values[field.id] = getValue(row[field.id], field.type) || 0;
    return values;
  }, {});

const verifyAllFieldsAreAvailable = (fieldIds, rowValues) =>
  fieldIds.forEach(id => {
    if (!rowValues.hasOwnProperty(id)) {
      throw new Error("Field not found");
    }
  });

export default function(value, { calculatedNumberExpression, values, fields }) {
  // Gets a has of { [fieldId]: value }
  // This just uses `getValue` and not `toString` which is fine for numbers
  // but if we ever need complex values, we'll need to modify
  const rowValues = getShallowValues(values, fields);
  const expression = calculatedNumberExpression.replace(/\[.*?\]/g, "");
  try {
    const variables = mustache
      .parse(expression)
      .filter(v => v[0] === "name")
      .map(v => v[1]);
    // `calculatedNumberExpression` is a string with field ids as mustache variables
    // So we turn `{{fieldid}} + 5`, where the field value is 2 into:
    // `2 + 5`
    const code = mustache.render(expression, rowValues);
    // Make sure we have all fields
    verifyAllFieldsAreAvailable(variables, rowValues);
    // Then execute the expression. Could use eval here, but that could get hairy
    // on the server.
    return Parser.evaluate(code);
  } catch (e) {
    return "#Error";
  }
}
