import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { get, debounce, sortBy } from "lodash";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import SearchContacts from "./SearchContacts";
import SelectedContact from "./SelectedContact";
import NewContact from "./NewContact";
import Api from "utils/EventContacts/WebAPIUtils";
import {
  getContactFirstName,
  getContactLastName,
  getContactEmail
} from "../utils";
import { withRouter } from "react-router";
import * as R from "ramda";

import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

import Helpers from "utils/Global/Helpers";
import fullnameToFirstLast from "utils/Contacts/fullname-to-first-last";

import { connect } from "react-redux";
import { getCredentials } from "redux/modules/user/selectors";

const decorate = R.compose(
  withRouter,
  connect(state => ({
    userCredentials: getCredentials(state)
  }))
);

const DEFAULT_FORM_VALUES = {
  giveLoginAccess: false,
  isPrimary: false,
  sendNotification: false
};
const defaultState = {
  message: "",
  newTemplate: false,
  templateTitle: "",
  hasMessage: false,
  createContact: false,
  formValues: DEFAULT_FORM_VALUES,
  selectedContact: null,
  searchTerm: "",
  searching: false,
  results: [],
  recordTypeId: null
};

@CSSModules(css)
class AddContactToAccount extends Component {
  constructor(props) {
    super(props);
    this.search = debounce(this.search, 500);
    this.state = {
      ...defaultState,
      ...this.props.initialState
    };
  }

  componentDidMount() {
    this.props.getRecordTypes({
      moduleId: STANDARD_MODULES.contacts.id,
      options: {
        orgId: this.props.orgId,
        eventId: this.props.params.eventId
      }
    });
  }

  componentWillUnmount() {
    this.search.cancel();
  }

  getContacts = e => {
    const term = e.target.value;
    this.setState({ searching: true });
    return this.search(term);
  };

  async search(term) {
    const contacts = await Api.searchContacts(
      this.props.userCredentials,
      this.props.orgId,
      null,
      term
    );
    const existingContactIds = this.props.existingContacts.map(
      c => c.contact_id
    );
    this.setState({
      searchTerm: term,
      searching: false,
      results: sortBy(
        contacts.slice(0, 25).map(c => ({
          id: c.id,
          email: getContactEmail(c.values),
          is_selected: existingContactIds.includes(c.id),
          name: `${getContactFirstName(c.values)} ${getContactLastName(
            c.values
          )}`,
          user_fname: getContactFirstName(c.values),
          user_lname: getContactLastName(c.values),
          user_email: getContactEmail(c.values)
        })),
        "name"
      )
    });
  }

  selectContact = selectedContact => this.setState({ selectedContact });
  updateMessage = (message, messagePlainText) =>
    this.setState({ message, messagePlainText });
  updateTemplateTitle = templateTitle => this.setState({ templateTitle });
  toggleTemplate = () =>
    this.setState(({ newTemplate }) => ({ newTemplate: !newTemplate }));

  returnToSearch = () => {
    this.setState({ ...defaultState });
  };

  addMessage = e => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ hasMessage: true });
  };

  addContact = () => {
    const formValues = {};
    if (Helpers.isValidEmail(this.state.searchTerm)) {
      formValues.email = this.state.searchTerm;
    } else {
      const { fname, lname } = fullnameToFirstLast(this.state.searchTerm);
      formValues.fname = fname;
      formValues.lname = lname;
    }
    this.setState({ createContact: true, formValues });
  };

  handleFormFieldChange = ({ target }) => {
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    return this.setState(state => ({
      ...state,
      error: "",
      formValues: { ...state.formValues, [name]: value }
    }));
  };

  handleRecordTypeChange = ({ id }) => {
    return this.setState({
      recordTypeId: id
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const formErrors = this.validateForm();
    const { message, selectedContact } = this.state;
    if (formErrors.length) {
      return this.setState({
        error: `${formErrors.join(", ")} ${
          formErrors.length > 1 ? "are" : "is"
        } required`
      });
    }
    const data = {
      contactId: get(this.state, "selectedContact.id"),
      email: get(selectedContact, "email"),
      customMessage: message,
      recordTypeId: this.state.recordTypeId,
      ...this.state.formValues
    };
    if (data.sendNotification) {
      this.saveTemplate();
    }
    this.props.onSave(data);
    this.props.hideModal();

    return null;
  };

  saveTemplate = () => {
    if (this.state.newTemplate && this.state.messagePlainText) {
      const newTemplate = {
        contentFormatted: this.state.message,
        contentPlaintext: this.state.messagePlainText,
        title: this.state.templateTitle
      };
      return this.props.createEmailTemplate(
        this.props.params.eventId,
        newTemplate
      );
    }
    return null;
  };

  validateForm() {
    if (this.state.createContact) {
      return [
        { key: "fname", label: "First Name" },
        { key: "lname", label: "Last Name" }
      ].reduce((errors, { key, label }) => {
        if (!this.state.formValues[key]) {
          errors.push(label);
        }
        return errors;
      }, []);
    }
    return [];
  }

  renderBody = () => {
    const isOrg = !this.props.params.eventId;

    if (this.state.selectedContact) {
      return (
        <SelectedContact
          isOrg={isOrg}
          newTemplate={this.state.newTemplate}
          message={this.state.message}
          templateTitle={this.state.templateTitle}
          hasMessage={this.state.hasMessage}
          addMessage={this.addMessage}
          returnToSearch={this.returnToSearch}
          handleFormFieldChange={this.handleFormFieldChange}
          isPrimary={this.state.formValues.isPrimary}
          sendNotification={this.state.formValues.sendNotification}
          giveLoginAccess={this.state.formValues.giveLoginAccess}
          selectedContact={this.state.selectedContact}
          hideModal={this.props.hideModal}
          updateMessage={this.updateMessage}
          updateTemplateTitle={this.updateTemplateTitle}
          toggleTemplate={this.toggleTemplate}
        />
      );
    }
    if (this.state.createContact) {
      return (
        <NewContact
          isOrg={isOrg}
          newTemplate={this.state.newTemplate}
          message={this.state.message}
          templateTitle={this.state.templateTitle}
          hasMessage={this.state.hasMessage}
          addMessage={this.addMessage}
          returnToSearch={this.returnToSearch}
          handleFormFieldChange={this.handleFormFieldChange}
          fname={this.state.formValues.fname}
          lname={this.state.formValues.lname}
          email={this.state.formValues.email}
          isPrimary={this.state.formValues.isPrimary}
          sendNotification={this.state.formValues.sendNotification}
          giveLoginAccess={this.state.formValues.giveLoginAccess}
          selectedContact={this.state.selectedContact}
          hideModal={this.props.hideModal}
          updateMessage={this.updateMessage}
          updateTemplateTitle={this.updateTemplateTitle}
          toggleTemplate={this.toggleTemplate}
          contactRecordTypes={this.props.contactRecordTypes}
          recordTypeId={this.state.recordTypeId}
          handleRecordTypeChange={this.handleRecordTypeChange}
        />
      );
    }
    return (
      <SearchContacts
        addContact={this.addContact}
        selectContact={this.selectContact}
        getContacts={this.getContacts}
        searchTerm={this.state.searchTerm}
        searching={this.state.searching}
        results={this.state.results}
      />
    );
  };

  render() {
    const { error } = this.state;
    return (
      <StyleWrapper
        width={500}
        hideModal={this.props.hideModal}
        heading="Add person to group"
      >
        <form onSubmit={this.handleSubmit}>
          {this.renderBody()}
          {error ? <div styleName="error">{error}</div> : ""}
        </form>
      </StyleWrapper>
    );
  }
}

AddContactToAccount.propTypes = {
  existingContacts: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  createEmailTemplate: PropTypes.func.isRequired,
  initialState: PropTypes.shape({
    formValues: PropTypes.shape({
      isPrimary: PropTypes.bool,
      giveLoginAccess: PropTypes.bool
    })
  }),
  params: PropTypes.shape({
    eventId: PropTypes.string
  }).isRequired
};

export default decorate(AddContactToAccount);
