import React, { Component } from "react";
import * as R from "ramda";
import getValue from "utils/value-types/get-value/number";
import toString from "utils/value-types/to-string/currency";
import { Div } from "components/Base";

import { getSettings } from "ui-kit/Datagrid/utils";

export default class CurrencyCellFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props.value, nextProps.value);
  }

  render() {
    const props = this.props;
    const settings = getSettings(props);
    const val = getValue(this.props.value);
    const formatted = val ? toString(val, settings) : "";

    return (
      <Div
        truncate
        style={{
          lineHeight: "34px"
        }}
        title={formatted}
      >
        {formatted}
      </Div>
    );
  }
}
