import PropTypes from "prop-types";
import React, { Component } from "react";
import Message from "components/Global/Email/Message";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Group from "./Group";
import {
  Submit,
  ButtonOutline,
  ButtonGroup
} from "components/Global/Modal/Layout/Buttons";

@CSSModules(css)
class SendDocumentRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRecipients: [],
      message: "",
      newTemplate: false,
      templateTitle: ""
    };
  }

  updateMessage = (message, messagePlainText) =>
    this.setState({ message, messagePlainText });
  updateTemplateTitle = templateTitle => this.setState({ templateTitle });
  toggleTemplate = () =>
    this.setState(({ newTemplate }) => ({ newTemplate: !newTemplate }));

  toggleRecipient = (val, email, account) => {
    if (val) {
      return this.setState(state => ({
        selectedRecipients: [...state.selectedRecipients, { email, account }]
      }));
    }

    return this.setState(state => ({
      selectedRecipients: state.selectedRecipients.filter(
        r => r.email !== email && r.account !== account
      )
    }));
  };

  handleSubmit = async e => {
    e.preventDefault();
    const emails = await this.send();
    this.props.hideModal();
    this.props.onDone(this.props.requests.length, "send");
  };

  send() {
    const { message, selectedRecipients } = this.state;
    const { eventDetails, template } = this.props;
    return Promise.all(
      selectedRecipients.map(({ account, email }) => {
        const variables = {
          message,
          event_name: eventDetails.name,
          event_slug: eventDetails.slug,
          portal_id: eventDetails.uuid,
          account_name: account,
          user_email: email,
          request_type_name: template.title
        };

        // @FIX SEND EMAIL
        return this.props.sendEmail({
          eventId: eventDetails.id,
          templateName: "notification-new-document-request",
          recipients: [{ email }],
          variables: Object.keys(variables).map(key => ({
            name: key,
            content: variables[key]
          }))
        });
      })
    );
  }

  render() {
    const { recipients, hideModal } = this.props;
    const { message, newTemplate, templateTitle } = this.state;

    return (
      <StyleWrapper
        hideModal={hideModal}
        heading="Send File Request"
        width={500}
        containerStyles={{ overflowY: "visible" }}
      >
        <form onSubmit={this.handleSubmit}>
          {recipients.map(r => (
            <Group
              toggleRecipient={this.toggleRecipient}
              selectedRecipients={this.state.selectedRecipients}
              {...r}
            />
          ))}
          <br />
          <div styleName="message">Message</div>
          <Message
            updateMessage={this.updateMessage}
            updateTemplateTitle={this.updateTemplateTitle}
            toggleTemplate={this.toggleTemplate}
            newTemplate={newTemplate}
            message={message}
            templateTitle={templateTitle}
          />

          <ButtonGroup>
            <Submit key="action" title="Send" />
            <ButtonOutline key="cancel" title="Cancel" onClick={hideModal} />
          </ButtonGroup>
        </form>
      </StyleWrapper>
    );
  }
}

SendDocumentRequest.propTypes = {
  onDone: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
  eventDetails: PropTypes.object.isRequired,
  recipients: PropTypes.arrayOf({
    name: PropTypes.string.isRequired,
    users: PropTypes.arrayOf({
      email: PropTypes.string
    }).isRequired
  }).isRequired,
  template: PropTypes.object.isRequired,
  addRecord: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  requests: PropTypes.array.isRequired,
  selectedTemplate: PropTypes.object.isRequired
};

export default SendDocumentRequest;
