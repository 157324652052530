import * as R from "ramda";

import {
  CREDENTIAL_TYPE_ID,
  MEAL_TYPE_ID,
  ASSETS_TYPE_ID,
  SPONSORSHIP_TYPE_ID,
  BOOTH_TYPE_ID
} from "utils/item-types";

export const STEPS = {
  OPPORTUNITIES: "packages",
  ITEMS: "items",
  ADD_ITEMS: "add-items",
  DETAILS: "details",
  SPACES: "spaces",
  APPLICATION: "application",
  NOTIFICATIONS: "notifications",
  PAYMENT: "payment",
  DOCUMENTS: "documents",
  FINISHED: "finished"
};

export const COMPLETED_INSTANCE = "CompletedSteps";

export const ADDRESS_FORM_ID = "addressForm";
export const ADDRESS_FIELDS = {
  NAME: "venue_name",
  ADD_1: "venue_address_line_1",
  ADD_2: "venue_address_line_2",
  CITY: "venue_address_city",
  STATE: "venue_address_state",
  ZIP: "venue_address_zip",
  WEBSITE: "website_url"
};

export const SETTINGS_FORM_ID = "settingsForm";
export const SETTINGS_FIELDS = {
  NAME: "name",
  ATTENDEES: "number_of_attendees",
  DATE: "date_from",
  END_DATE: "date_to",
  HEADER: "background_image_url",
  DESCRIPTION: "description",
  DETAILS: "notes",
  TERMS: "terms",
  CONFIRMATION: "confirmation",
  PRIMARY_BUTTON_TEXT: "primary_button_text",
  PRIMARY_BUTTON_COLOR: "primary_button_color",
  PRIMARY_BUTTON_OPENS_TO: "primary_button_opens_to",
  SECONDARY_BUTTON_TEXT: "secondary_button_text",
  SECONDARY_BUTTON_OPENS_TO: "secondary_button_opens_to"
};

export const PACKAGE_FIELDS = {
  NAME: "name",
  PRICE: "price",
  DESCRIPTION: "description"
};

export const ITEM_FIELDS = {
  NAME: "name",
  CATEGORY: "group_id",
  DESCRIPTION: "description"
};

export const BOOTH_FIELDS = {
  NAME: "name",
  PRICE: PACKAGE_FIELDS.PRICE,
  LIMIT: "limit"
};

export const EMAIL_FORM_ID = "emailForm";
export const EMAIL_FIELDS = {
  CONFIRMATION: "confirmation",
  APPROVED: "approved",
  DENIED: "denied",
  PAYMENT: "payment",
  NOTIFY_ADMINS: "notifyAdmins"
};

export const APPLICATION_FORM_ID = "applicationForm";
export const APPLICATION_FIELDS = {
  REQUIRE: "require",
  INCLUDE: "include"
};

export const PAYMENT_FORM_ID = "paymentForm";
export const PAYMENT_FIELDS = {
  ACCEPT_PAYMENT: "accept_payment",
  COLLECT_DEPOSIT: "collectDeposit",
  COLLECT_TYPE: "collectType",
  COLLECT_WHEN: "collectWhen",
  COLLECT_PRICE: "collectPrice",
  APPLICATION_FEE: "applicationFee",
  APPLICATION_PRICE: "applicationPrice",
  CREDIT_INFO: "creditInfo",
  PROCESSING_FEE: "processingFee"
};

export const DOCUMENTS_FORM_ID = "documentsForm";
export const DOCUMENTS_FIELDS = {
  HEADER: "header",
  BODY: "body"
};

export const ITEM_TYPES = {
  BOOTHS: "booths",
  SPONSORSHIP: "sponsorship",
  EQUIPMENT: "equipment",
  PASSES: "passes",
  MEALS: "meals"
};

export const DATETIME_FORMAT = "MMM Do YYYY, hh:mm A";

export const TYPE_ORDER = typeId =>
  R.propOr(10, typeId)({
    [BOOTH_TYPE_ID]: 0,
    [SPONSORSHIP_TYPE_ID]: 1,
    [ASSETS_TYPE_ID]: 3,
    [CREDENTIAL_TYPE_ID]: 4,
    [MEAL_TYPE_ID]: 5
  });
