import React, { Component } from "react";
import autobind from "autobind-decorator";
import { get } from "lodash";
import Wysiwyg from "components/Global/Wysiwyg";
import Input from "components/Global-2016/Forms/Input";
import PropTypes from "prop-types";
import Checkbox from "rc-checkbox";
import InsertTemplate from "./InsertTemplate";
import Recipients from "./Recipients";
import getSubmissionStatus from "components/Event/FormsV2/Overview/utils/getSubmissionStatus";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import {
  ButtonGroup,
  ButtonOutline,
  Submit
} from "components/Global/Modal/Layout/Buttons";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class SendSummaryConfirmations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customMessage: false,
      message: "",
      newTemplate: false,
      selectedRecipients: {},
      title: `Your ${props.form.title || props.form.name} details for ${
        props.eventDetails.name
      } have been reviewed`
    };
  }

  componentWillMount() {
    this.props.getEmailTemplates(null, this.props.eventDetails.id);
  }

  @autobind
  onClick(e) {
    e.preventDefault();
    if (this.state.newTemplate && this.state.contentPlaintext) {
      const newTemplate = {
        contentFormatted: this.state.message,
        contentPlaintext: this.state.contentPlaintext,
        title: this.state.newTemplateTitle
      };
      this.props.createEmailTemplate(this.props.params.eventId, newTemplate);
    }
    this.props
      .sendConfirmation({
        eventId: this.props.params.eventId,
        formId: this.props.params.formId,
        submissionId: this.props.submission.id,
        message: this.state.message.length ? this.state.message : "",
        title: this.state.title,
        recipients: Object.keys(this.state.selectedRecipients).reduce(
          (a, key) => {
            const { email, preview } = this.state.selectedRecipients[key];
            a.push({ email, preview: preview() });
            return a;
          },
          []
        )
      })
      .then(() => {
        this.props.handleApprovalSent(
          Object.keys(this.state.selectedRecipients).length
        );
        this.props.hideModal();
      });
  }

  @autobind
  getStatus() {
    const status = getSubmissionStatus(
      this.props.submission,
      this.props.fields
    );
    return status.includes("rejected") ? "rejected" : "approved";
  }

  @autobind
  handleMessageChange(formattedText, plainText) {
    this.setState({
      message: formattedText,
      contentPlaintext: plainText
    });
  }

  @autobind
  toggleCheckbox() {
    this.setState({ newTemplate: !this.state.newTemplate });
  }

  @autobind
  templateTitleChange(event) {
    this.setState({ newTemplateTitle: event.target.value });
  }

  @autobind
  handleTemplateSelect(id) {
    const text = this.props.emailTemplates.find(template => template.id === id);
    this.handleMessageChange(text.content_formatted, text.content_plaintext);
  }

  @autobind
  toggleRecipient(val, email, preview) {
    if (val) {
      return this.setState(state => ({
        selectedRecipients: {
          ...state.selectedRecipients,
          [email]: { email, preview }
        }
      }));
    }
    return this.setState(state => {
      delete state.selectedRecipients[email];
      return { selectedRecipients: state.selectedRecipients };
    });
  }

  @autobind
  addCustomMessage() {
    this.setState({ customMessage: true });
  }

  render() {
    const {
      submission,
      form,
      eventDetails,
      emailTemplates,
      params,
      hideModal
    } = this.props;
    const countOfSelected = Object.keys(this.state.selectedRecipients).length;

    return (
      <StyleWrapper
        heading="Send confirmations"
        hideModal={hideModal}
        width={760}
      >
        <Recipients
          form={form}
          selectedRecipients={this.state.selectedRecipients}
          submission={submission}
          toggleRecipient={this.toggleRecipient}
          message={this.state.message}
          eventDetails={eventDetails}
          title={this.state.title}
        />

        <div styleName="heading">
          {countOfSelected} people will be sent confirmations
        </div>

        <form onSubmit={this.onClick}>
          {this.state.customMessage ? (
            <div>
              <div>
                <InsertTemplate
                  onTemplateSelect={this.handleTemplateSelect}
                  templates={emailTemplates}
                  eventId={params.eventId}
                />
              </div>
              <div styleName="inputWrapper">
                <Wysiwyg
                  text={this.state.message}
                  onChange={this.handleMessageChange}
                  height={200}
                />
                <label styleName="saveTemplate">
                  <Checkbox
                    className={css.checkbox}
                    onChange={this.toggleCheckbox}
                    checked={this.state.newTemplate}
                  />
                  <span styleName="checkboxLabel">
                    Save response as template
                  </span>
                </label>
              </div>

              {this.state.newTemplate ? (
                <div styleName="well">
                  <Input
                    onChange={this.templateTitleChange}
                    label="Template Name"
                    containerStyles={css.inputGroup}
                    required
                  />
                </div>
              ) : (
                ""
              )}

              {this.state.error ? (
                <div styleName="error">{this.state.error}</div>
              ) : null}
            </div>
          ) : (
            <div styleName="addMessageButton" onClick={this.addCustomMessage}>
              <i className="material-icons">add</i>
              Add custom message to emails
            </div>
          )}
          <ButtonGroup>
            <Submit
              title={`Send ${countOfSelected || ""} Emails`}
              disabled={!countOfSelected}
            />
            <ButtonOutline title="Cancel" onClick={hideModal} />
          </ButtonGroup>
        </form>
      </StyleWrapper>
    );
  }
}

SendSummaryConfirmations.propTypes = {
  params: PropTypes.object.isRequired,
  submission: PropTypes.object.isRequired,
  createEmailTemplate: PropTypes.func.isRequired,
  sendConfirmation: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  handleApprovalSent: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  eventDetails: PropTypes.object.isRequired,
  styles: PropTypes.object,
  getEmailTemplates: PropTypes.func.isRequired,
  emailTemplates: PropTypes.array.isRequired
};

export default SendSummaryConfirmations;
