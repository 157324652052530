import * as R from "ramda";

import React from "react";
import Formatter from "ui-kit/Datagrid/View/Formatters/ItemBlock";
import Editor from "./Menu";

import EditorBase from "../TableConnector";

import { PopMenuCard, PopMenu, Div, DownIcon } from "components/Base";

import { getMeta, ep } from "ui-kit/Datagrid/utils";

class ItemBlockSelect extends EditorBase {
  render() {
    const editorProps = ep(this.props);

    const { value, disabled } = this.props;
    const columnId = R.prop("columnId", value);
    const column = getMeta(editorProps, ["columns", columnId]);
    const settings = R.prop("settings", column);

    // Add dropdown wrapper
    return (
      <PopMenu
        Label={({ onClick }) => (
          <Div
            onClick={onClick}
            height={1}
            width={1}
            display="row-reverse.space-between.center"
            bg="white"
            p={1}
            bra={2}
            ba={2}
            bc="#65afe9"
          >
            <Formatter {...editorProps} />
            <Div ml={1} style={{ flexShrink: 0 }} display="row.center.center">
              <DownIcon ml={1} sizeWFS={6} />
            </Div>
          </Div>
        )}
      >
        {({ closeMenu }) => (
          <PopMenuCard position="bottom" align="right">
            <Editor
              column={column}
              onChange={this.handleChange}
              onClose={() => {
                closeMenu();
                this.props.onCommit();
              }}
              value={value}
              disabled={disabled}
              className="styled"
              {...{
                limit: R.prop("limit", settings),
                options: R.compose(
                  R.map(variant => ({
                    id: variant.variant_id || variant.variant.id,
                    value: variant.variant.display_name,
                    color: variant.variant.item.background_color,
                    limit: variant.limit,
                    price: R.pathOr(0, ["price", "price"])(variant)
                  })),
                  R.sortBy(R.prop("order"))
                )(R.propOr([], "variants", settings))
              }}
            />
          </PopMenuCard>
        )}
      </PopMenu>
    );
  }
}

export default ItemBlockSelect;
