export const PORTAL_LOGIN = {
  subject: "Get started with your event portal",
  message:
    "<p>Hi {{{recipient.first_name}}},</p><p>Here is your link to login to the portal for {{{name}}}.</p><p>Your Link: {{{portal_login_link}}}</p><p>If you have any questions - please feel free to respond to this email.</p>"
};

export const ASSIGNMENT_MANAGER = {
  subject: "Get started with assigning your passes",
  message:
    "<p>Hi {{{recipient.first_name}}},</p><p>The Guest List / Pass Assignment website for {{{name}}} has been created - and you can now use this link to add your guests or attendees as well as choose the pass types they should have.</p><p>Your Link: {{{assignment_manager_link}}}</p><p>This link will be accessible by anyone you share it with - and they will be able to make changes - so please be wise with who you share it with.</p><p>We can't wait to have you with us.</p>"
};

export const ITEM_SUMMARY = {
  subject: "Your Approved Overview",
  message:
    "<p>Hi {{{recipient.first_name}}},</p><p>Thanks for taking the time to submit your requests via Lennd. We've reviewed and approved your requests. Please take the time to review what you were approved for and see what may have changed from your original request.</p><p>{{{orders_summary}}}</p><p>IF YOU HAVE ANY QUESTIONS OR CONCERNS, PLEASE REACH OUT TO YOUR POINT OF CONTACT AT THE EVENT.</p>"
};

export const CREDENTIALS_SUMMARY = {
  subject: "Your Approved Credentials Overview",
  message:
    "<p>Hi {{{recipient.first_name}}},</p><p>Thanks for taking the time to submit your requests via Lennd. We've reviewed and approved your requests. Please take the time to review what you were approved for and see what may have changed from your original request.</p><p>{{{passes_summary}}}</p><p>IF YOU HAVE ANY QUESTIONS OR CONCERNS, PLEASE REACH OUT TO YOUR POINT OF CONTACT AT THE EVENT.</p>"
};

export const INVENTORY_SUMMARY = {
  subject: "Your Approved Asset & Equipment Overview",
  message:
    "<p>Hi {{{recipient.first_name}}},</p><p>Thanks for taking the time to submit your requests via Lennd. We've reviewed and approved your requests. Please take the time to review what you were approved for and see what may have changed from your original request.</p><p>{{{inventory_summary}}}</p><p>IF YOU HAVE ANY QUESTIONS OR CONCERNS, PLEASE REACH OUT TO YOUR POINT OF CONTACT AT THE EVENT.</p>"
};

export const CATERING_SUMMARY = {
  subject: "Your Approved Catering Overview",
  message:
    "<p>Hi {{{recipient.first_name}}},</p><p>Thanks for taking the time to submit your requests via Lennd. We've reviewed and approved your requests. Please take the time to review what you were approved for and see what may have changed from your original request.</p><p>{{{catering_summary}}}</p><p>IF YOU HAVE ANY QUESTIONS OR CONCERNS, PLEASE REACH OUT TO YOUR POINT OF CONTACT AT THE EVENT.</p>"
};

export const BOOTHS_SUMMARY = {
  subject: "Your Approved Booths Overview",
  message:
    "<p>Hi {{{recipient.first_name}}},</p><p>Thanks for taking the time to submit your requests via Lennd. We've reviewed and approved your requests. Please take the time to review what you were approved for and see what may have changed from your original request.</p><p>{{{booths_summary}}}</p><p>IF YOU HAVE ANY QUESTIONS OR CONCERNS, PLEASE REACH OUT TO YOUR POINT OF CONTACT AT THE EVENT.</p>"
};

export const SPONSORSHIPS_SUMMARY = {
  subject: "Your Approved Sponsorships Overview",
  message:
    "<p>Hi {{{recipient.first_name}}},</p><p>Thanks for taking the time to submit your requests via Lennd. We've reviewed and approved your requests. Please take the time to review what you were approved for and see what may have changed from your original request.</p><p>{{{sponsorships_summary}}}</p><p>IF YOU HAVE ANY QUESTIONS OR CONCERNS, PLEASE REACH OUT TO YOUR POINT OF CONTACT AT THE EVENT.</p>"
};

export const CREDENTIALS_AND_CATERING_SUMMARY = {
  subject: "Your Approved Credentials and Catering Overview",
  message:
    "<p>Hi {{{recipient.first_name}}},</p><p>Thanks for taking the time to submit your requests via Lennd. We've reviewed and approved your requests. Please take the time to review what you were approved for and see what may have changed from your original request.</p><p>{{{passes_summary}}}</p><p>{{{catering_summary}}}</p><p>IF YOU HAVE ANY QUESTIONS OR CONCERNS, PLEASE REACH OUT TO YOUR POINT OF CONTACT AT THE EVENT.</p>"
};

export const SEND_FILE_BY_EMAIL = {
  subject: "",
  message: ""
};
