import * as R from "ramda";

import { createSelector } from "reselect";

import { getters } from "Files/Manage";

import { ACTION_TYPES, TREE_NODE_COLUMN_ID } from "ui-kit/Table/constants";

import { eventDetails } from "redux/modules/event/selectors";
import { canUserDo } from "redux/modules/permissions/user-permission-profile/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import {
  ROW_ACTIONS,
  FIELD_ID_TO_UPDATE,
  CUSTOM_TREE_NODE_COLUMN
} from "../constants";
import { COLUMN_ACTIONS } from "ui-kit/Table/constants";

const getCanUserDo = R.memoizeWith(getEventId, state =>
  canUserDo(state, getEventId(state))
);

export const getToggledRows = createSelector(
  getters.toggledRows,
  R.identity
);

export const getCountOfToggledRows = createSelector(
  getToggledRows,
  R.length
);

export const getAllChildrenRow = createSelector(
  (state, props) => R.prop("rowId", props),
  getters.rowsById,
  (rowId, rowsById) => {
    const rowData = rowsById[rowId];
    let allChildren = R.propOr([], "children", rowData);
    let stackChildren = [...allChildren];
    while (stackChildren.length !== 0) {
      const nextChildRowId = stackChildren.shift();
      const nextChildRow = rowsById[nextChildRowId];
      if (!R.propEq("children", undefined, nextChildRow)) {
        const nextChildren = R.prop("children", nextChildRow);
        allChildren = R.concat(allChildren, nextChildren);
        stackChildren = R.concat(stackChildren, nextChildren);
      }
    }
    return allChildren;
  }
);

const rowMapper = (record, level, isExpanded, isChecked = false, fields) => ({
  id: R.prop("id", record),
  ...R.reduce(
    (acc, { id }) => ({
      ...acc,
      [id]: R.equals(id, TREE_NODE_COLUMN_ID)
        ? { value: { ...record, level, isExpanded, isChecked } }
        : { value: R.propOr("--", id, record) }
    }),
    {},
    fields
  )
});

export const getParentsRows = createSelector(
  getters.rows,
  getters.rowsById,
  getters.rootRowIds,
  (rows, rowsById, rootRowIds) =>
    R.compose(
      R.reduce((acc, row) => {
        const parents = [];
        let nextParent = R.prop("parent", row);
        while (nextParent !== null) {
          parents.push(nextParent);
          nextParent = R.propOr(null, "parent", rowsById[nextParent]);
        }
        return {
          ...acc,
          [R.prop("id", row)]: parents
        };
      }, {}),
      R.filter(row => !R.contains(R.prop("id", row), rootRowIds))
    )(rows)
);

export const getVisibleRows = createSelector(
  getters.rows,
  getters.columns,
  getters.rowsById,
  getters.rootRowIds,
  getParentsRows,
  getters.expandedRowIds,
  getters.toggledRows,
  (
    rows,
    fields,
    rowsById,
    rootRowIds,
    parentsRows,
    expandedRowIds,
    toggledRows
  ) => {
    const newExpandedRows = R.compose(
      R.flatten,
      R.map(expandedId => R.pathOr([], [expandedId, "children"], rowsById))
    )(expandedRowIds);
    const visibleRows = [...rootRowIds, ...newExpandedRows];
    return R.compose(
      R.map(row =>
        rowMapper(
          row,
          R.length(R.propOr([], row.id, parentsRows)),
          expandedRowIds.includes(row.id),
          toggledRows.includes(row.id),
          fields
        )
      ),
      R.filter(row => R.contains(row.id, visibleRows))
    )(rows);
  }
);

export const getColumns = createSelector(
  (state, props) => R.propOr([], "fields", props),
  fields =>
    R.map(
      field =>
        R.propEq("id", FIELD_ID_TO_UPDATE, field)
          ? {
              ...field,
              ...CUSTOM_TREE_NODE_COLUMN
            }
          : field,
      fields
    )
);

// TODO: do we have more actions than open a file?
export const getRowActions = createSelector(
  getters.moduleId,
  eventDetails,
  getters.rows,
  getters.references,
  getCanUserDo,
  (moduleId, eventDetails, rows, references, canDo) => {
    if (moduleId === STANDARD_MODULE_IDS.accounts.id) {
      const canUpdate = canDo(`${STANDARD_MODULE_IDS.accounts.id}_update`);
      return [
        {
          id: ROW_ACTIONS.VIEW_RECORD,
          type: ACTION_TYPES.ITEM,
          name: "View Group",
          leftIcon: "open_in_new",
          visible: true
          // onClick: () =>
          //   handlers.openInNewWindow(
          //     `/event/${eventDetails.id}/account/${rowId}`
          //   )
        },
        {
          id: ROW_ACTIONS.SEND_EMAIL,
          type: ACTION_TYPES.ITEM,
          name: "Send Email",
          leftIcon: "send",
          visible: true
          // onClick: () =>
          //   handlers.showSendEmailModal([rowId], {
          //     selectedOptions: [ACCOUNT_PRIMARY_CONTACTS]
          //   })
        },
        {
          id: ROW_ACTIONS.EDIT,
          type: ACTION_TYPES.ITEM,
          name: "Edit",
          leftIcon: "edit",
          visible: canUpdate
          // onClick: () =>
          //   handlers.showBulkEditModal({
          //     toggledRows: [rowId],
          //     fields,
          //     references
          //   })
        },
        {
          type: ACTION_TYPES.SUBMENU,
          name: "Send Item Summary",
          leftIcon: "list",
          visible: true,
          submenu: [
            {
              id: ROW_ACTIONS.SEND_ALL_ITEMS,
              type: ACTION_TYPES.ITEM,
              name: "All Items (Everything)",
              visible: true
              // onClick: () =>
              //   handlers.showSendEmailModal(
              //     [rowId],
              //     EMAIL_TEMPLATES.ITEM_SUMMARY
              //   )
            },
            {
              id: ROW_ACTIONS.SEND_ALL_PASSES,
              type: ACTION_TYPES.ITEM,
              name: "All Passes",
              visible: true
              // onClick: () =>
              //   handlers.showSendEmailModal(
              //     [rowId],
              //     EMAIL_TEMPLATES.CREDENTIALS_SUMMARY
              //   )
            },
            {
              id: ROW_ACTIONS.SEND_ALL_MEALS,
              type: ACTION_TYPES.ITEM,
              name: "All Meals",
              visible: true
              // onClick: () =>
              //   handlers.showSendEmailModal(
              //     [rowId],
              //     EMAIL_TEMPLATES.CATERING_SUMMARY
              //   )
            },
            {
              id: ROW_ACTIONS.SEND_ALL_PASSES_MEALS,
              type: ACTION_TYPES.ITEM,
              name: "All Passes and Meals",
              visible: true
              // onClick: () =>
              //   handlers.showSendEmailModal(
              //     [rowId],
              //     EMAIL_TEMPLATES.CREDENTIALS_AND_CATERING_SUMMARY
              //   )
            },
            {
              id: ROW_ACTIONS.SEND_ALL_ASSETS,
              type: ACTION_TYPES.ITEM,
              name: "All Assets & Equipment",
              visible: true
              // onClick: () =>
              //   handlers.showSendEmailModal(
              //     [rowId],
              //     EMAIL_TEMPLATES.INVENTORY_SUMMARY
              //   )
            }
          ]
        },
        {
          type: ACTION_TYPES.HEADER,
          name: "Portal",
          visible: true
        },
        {
          id: ROW_ACTIONS.SEND_LOGIN,
          type: ACTION_TYPES.ITEM,
          name: "Send Login Link",
          visible: true
          // onClick: () =>
          //   handlers.showSendEmailModal([rowId], EMAIL_TEMPLATES.PORTAL_LOGIN)
        },
        {
          id: ROW_ACTIONS.COPY_LOGIN,
          type: ACTION_TYPES.ITEM,
          name: "Copy Login Link",
          visible: true
          // onClick: () =>
          //   handlers.copyToClipboard(
          //     `${window.__LENND_APP_URL__}/portal-login/${eventDetails.slug}/${eventDetails.uuid}`
          //   )
        },
        {
          type: ACTION_TYPES.HEADER,
          name: "Assignment Manager",
          visible: true
        },

        {
          id: ROW_ACTIONS.SEND_LINK,
          type: ACTION_TYPES.ITEM,
          name: "Send Link",
          visible: true
          // onClick: () =>
          //   handlers.showSendEmailModal([rowId], {
          //     selectedOptions: [ACCOUNT_PRIMARY_CONTACTS],
          //     ...EMAIL_TEMPLATES.ASSIGNMENT_MANAGER
          //   })
        },
        {
          id: ROW_ACTIONS.COPY_LINK,
          type: ACTION_TYPES.ITEM,
          name: "Copy Link",
          visible: true
          // onClick: () =>
          //   handlers.copyToClipboard(
          //     `${window.__LENND_APP_URL__}/assignment-manager/${eventDetails.slug}/${eventDetails.uuid}/${rowId}`
          // )
        },
        {
          id: ROW_ACTIONS.OPEN_LINK,
          type: ACTION_TYPES.ITEM,
          name: "Open Link",
          visible: true
          // onClick: () =>
          //   handlers.openInNewWindow(
          //     `${window.__LENND_APP_URL__}/assignment-manager/${eventDetails.slug}/${eventDetails.uuid}/${rowId}`
          //   )
        },
        {
          type: ACTION_TYPES.HEADER,
          name: null,
          visible: true
        },
        {
          type: ACTION_TYPES.SUBMENU,
          name: "More Actions",
          visible: true,
          submenu: [
            {
              type: ACTION_TYPES.HEADER,
              name: "Assign",
              visible: canUpdate
            },
            {
              id: ROW_ACTIONS.ASSIGN_FORMS,
              type: ACTION_TYPES.ITEM,
              name: "Forms",
              visible: canUpdate
              // onClick: () => handlers.showAssignFormsModal([rowId])
            },
            {
              id: ROW_ACTIONS.DOCUMENT_REQUESTS,
              type: ACTION_TYPES.ITEM,
              name: "File Requests",
              visible: canUpdate
              // onClick: () => handlers.showAssignDocumentRequestsModal([rowId])
            },
            {
              id: ROW_ACTIONS.ASSIGN_OWNERS,
              type: ACTION_TYPES.ITEM,
              name: "Record Owners",
              visible: canUpdate
              // onClick: () => handlers.showAssignOwnersModal([rowId])
            },
            {
              type: ACTION_TYPES.HEADER,
              name: "Other",
              visible: true
            },
            {
              id: ROW_ACTIONS.CHANGE_GROUP,
              type: ACTION_TYPES.ITEM,
              name: "Change Group Type",
              visible: canUpdate
              // onClick: () => handlers.showChangeRecordTypesModal([rowId])
            },
            {
              id: ROW_ACTIONS.GENERATE_DOCUMENT,
              type: ACTION_TYPES.ITEM,
              name: "Generate a Document",
              visible: true
              // onClick: () =>
              //   handlers.showGenerateDocumentsModal([
              //     R.compose(
              //       row => ({
              //         id: row.id,
              //         name:
              //           moduleId === STANDARD_MODULE_IDS.accounts.id
              //             ? R.pathOr("(No Name)", [
              //                 "values",
              //                 STANDARD_FIELD_IDS.ACCOUNTS.NAME,
              //                 "value"
              //               ])(row)
              //             : "(No Name)"
              //       }),
              //       R.find(row => row.id === rowId)
              //     )(rows)
              //   ])
            },
            {
              id: ROW_ACTIONS.RUN_REPORT,
              type: ACTION_TYPES.ITEM,
              name: "Run Report",
              visible: true
              // onClick: () => handlers.showRunReportModal([rowId])
            },
            {
              id: ROW_ACTIONS.UPLOAD_FILES,
              type: ACTION_TYPES.ITEM,
              name: "Upload Files",
              visible: canUpdate
              // onClick: () => handlers.showUploadFilesModal([rowId])
            },
            {
              id: ROW_ACTIONS.DELETE_RECORDS,
              type: ACTION_TYPES.ITEM,
              name: "Delete",
              visible: canUpdate
              // onClick: () => handlers.showDeleteRecordsModal([rowId])
            }
          ].filter(a => a.visible)
        }
      ].filter(a => a.visible);
    } else if (moduleId === STANDARD_MODULE_IDS.contacts.id) {
      const canUpdate = canDo(`${STANDARD_MODULE_IDS.contacts.id}_update`);
      return [
        {
          id: ROW_ACTIONS.VIEW_RECORD,
          type: ACTION_TYPES.ITEM,
          name: "View Person",
          leftIcon: "open_in_new",
          visible: true
          // onClick: () =>
          //   handlers.openInNewWindow(
          //     `/event/${eventDetails.id}/contact/${rowId}`
          //   )
        },
        {
          id: ROW_ACTIONS.SEND_EMAIL,
          type: ACTION_TYPES.ITEM,
          name: "Send Email",
          leftIcon: "send",
          visible: true
          // onClick: () =>
          //   handlers.showSendEmailModal([rowId], {
          //     selectedOptions: [EMAIL_FIELD]
          //   })
        },
        {
          id: ROW_ACTIONS.EDIT,
          type: ACTION_TYPES.ITEM,
          name: "Edit",
          leftIcon: "edit",
          visible: canUpdate
          // onClick: () =>
          //   handlers.showBulkEditModal({
          //     toggledRows: [rowId],
          //     fields,
          //     references
          //   })
        },
        {
          type: ACTION_TYPES.SUBMENU,
          name: "Send Item Summary",
          leftIcon: "list",
          visible: true,
          submenu: [
            {
              id: ROW_ACTIONS.SEND_ALL_ITEMS,
              type: ACTION_TYPES.ITEM,
              name: "All Items (Everything)",
              visible: true
              // onClick: () =>
              //   handlers.showSendEmailModal(
              //     [rowId],
              //     EMAIL_TEMPLATES.ITEM_SUMMARY
              //   )
            },
            {
              id: ROW_ACTIONS.SEND_ALL_PASSES,
              type: ACTION_TYPES.ITEM,
              name: "All Passes",
              visible: true
              // onClick: () =>
              //   handlers.showSendEmailModal(
              //     [rowId],
              //     EMAIL_TEMPLATES.CREDENTIALS_SUMMARY
              //   )
            },
            {
              id: ROW_ACTIONS.SEND_ALL_MEALS,
              type: ACTION_TYPES.ITEM,
              name: "All Meals",
              visible: true
              // onClick: () =>
              //   handlers.showSendEmailModal(
              //     [rowId],
              //     EMAIL_TEMPLATES.CATERING_SUMMARY
              //   )
            },
            {
              id: ROW_ACTIONS.SEND_ALL_PASSES_MEALS,
              type: ACTION_TYPES.ITEM,
              name: "All Passes and Meals",
              visible: true
              // onClick: () =>
              //   handlers.showSendEmailModal(
              //     [rowId],
              //     EMAIL_TEMPLATES.CREDENTIALS_AND_CATERING_SUMMARY
              //   )
            },
            {
              id: ROW_ACTIONS.SEND_ALL_ASSETS,
              type: ACTION_TYPES.ITEM,
              name: "All Assets & Equipment",
              visible: true
              // onClick: () =>
              //   handlers.showSendEmailModal(
              //     [rowId],
              //     EMAIL_TEMPLATES.INVENTORY_SUMMARY
              //   )
            }
          ]
        },
        {
          id: ROW_ACTIONS.RUN_REPORT,
          type: ACTION_TYPES.ITEM,
          name: "Run Report",
          leftIcon: "show_chart",
          visible: true
          // onClick: () => handlers.showRunReportModal([rowId])
        },
        {
          type: ACTION_TYPES.HEADER,
          name: "Other",
          visible: canUpdate
        },
        {
          id: ROW_ACTIONS.ASSIGN_OWNERS,
          type: ACTION_TYPES.ITEM,
          name: "Assign Owners",
          visible: canUpdate
          // onClick: () => handlers.showAssignOwnersModal([rowId])
        },
        {
          id: ROW_ACTIONS.CHANGE_PERSON,
          type: ACTION_TYPES.ITEM,
          name: "Change Person Type",
          visible: canUpdate
          // onClick: () => handlers.showChangeRecordTypesModal([rowId])
        },
        {
          id: ROW_ACTIONS.UPLOAD_FILES,
          type: ACTION_TYPES.ITEM,
          name: "Upload Files",
          visible: canUpdate
          // onClick: () => handlers.showUploadFilesModal([rowId])
        },
        {
          id: ROW_ACTIONS.DELETE_RECORDS,
          type: ACTION_TYPES.ITEM,
          name: "Delete",
          visible: canUpdate
          // onClick: () => handlers.showDeleteRecordsModal([rowId])
        }
      ].filter(a => a.visible);
    }
    return [];
  }
);

export const getColumnActions = R.always([
  {
    id: COLUMN_ACTIONS.EDIT,
    type: ACTION_TYPES.ITEM,
    name: "Edit Column",
    leftIcon: "edit"
  },
  {
    id: COLUMN_ACTIONS.HIDE,
    type: ACTION_TYPES.ITEM,
    name: "Hide Column",
    leftIcon: "remove_red_eye"
  },
  {
    type: "separator"
  },
  {
    id: COLUMN_ACTIONS.INSERT_LEFT,
    type: ACTION_TYPES.ITEM,
    name: "Insert Left",
    leftIcon: "arrow_back"
  },
  {
    id: COLUMN_ACTIONS.INSERT_RIGHT,
    type: ACTION_TYPES.ITEM,
    name: "Insert Right",
    leftIcon: "arrow_forward"
  }
]);
