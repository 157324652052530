import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { get } from "lodash";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import {
  ButtonGroup,
  Submit,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";
import IconSelector from "components/Global/OptionSelector/AccountsOption/IconSelector";
import IconSelectorLabel from "components/Global/OptionSelector/AccountsOption/IconSelectorLabel";
import ColorPicker from "components/Global/ColorPicker";
import FormElements from "components/Global/Modal/Layout/FormElements";
import DeleteModuleModal from "../DeleteModule";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import MoreInfo from "components/Global/Modal/Layout/MoreInfo";
import Toggle from "material-ui/Toggle";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import formatInternalName from "utils/format-internal-name";
import getRandomBaseColor from "utils/get-random-base-color";

const { Select, Label, Input, InputSection, InputGroup } = FormElements;

const ICON_GROUP = [
  "attach_money",
  "airplanemode_active",
  "map",
  "location_on",
  "receipt",
  "people",
  "music_note",
  "stars",
  "local_shipping",
  "mic",
  "business",
  "home",
  "build",
  "business_center",
  "restaurant",
  "extension",
  "alarm",
  "event_seat",
  "explore",
  "timeline",
  "local_dining",
  "power",
  "airport_shuttle",
  "list"
];

const formatRecordPrefix = value =>
  value
    .toUpperCase()
    .replace(/\W+/g, "")
    .substring(0, 5);

const defaultState = props => {
  if (props.module) {
    return {
      color: props.module.color || getRandomBaseColor(),
      icon: props.module.icon || "business",
      internalName: props.module.internal_name,
      name: props.module.name || "",
      recordName: props.module.record_name || "Record",
      recordNamePlural: props.module.record_name_plural || "Records",
      recordPrefix: props.module.record_prefix || "REC",
      enableRecordTypes: props.module.enable_record_types || false,
      enableRecordTypeIcons: props.module.enable_record_type_icons || false,
      showInNavigation:
        props.module.show_in_navigation ||
        props.module.showInNavigation ||
        false,
      hasApproval: props.module.has_approval || false,
      typeId: props.module.typeId
    };
  }

  return {
    name: "",
    color: getRandomBaseColor(),
    icon: "business",
    recordName: "Record",
    recordNamePlural: "Records",
    recordPrefix: "REC",
    showInNavigation: !!props.simple,
    typeId: null
  };
};

@CSSModules(css)
class CreateModuleModal extends Component {
  state = defaultState(this.props);

  onNameBlur = e => {
    this.setState({
      internalName: formatInternalName(e.target.value || "")
    });
  };

  onInternalNameBlur = () => {
    this.setState({
      internalName: formatInternalName(this.state.internalName)
    });
  };

  onRecordPrefixBlur = () => {
    this.setState({
      recordPrefix: formatRecordPrefix(this.state.recordPrefix)
    });
  };

  handleFieldValueChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleColorSelect = ({ backgroundColor }) =>
    this.setState({ color: backgroundColor });
  handleIconSelect = icon => this.setState({ icon });

  validateForm = () =>
    this.state.color &&
    this.state.icon &&
    this.state.name.length &&
    this.state.recordName &&
    this.state.recordNamePlural &&
    this.state.recordPrefix;

  handleSubmit = e => {
    e.preventDefault();
    const data = {
      ...this.state,
      internalName:
        !this.state.internalName || !this.state.internalName.length
          ? formatInternalName(this.state.name)
          : this.state.internalName,
      eventId: this.props.eventId
    };
    this.props.onSave(data);
    this.props.hideModal();
  };

  isNewModule = !get(this.props.module, "id");

  getTypeName = () => {
    switch (this.state.typeId) {
      case STANDARD_MODULE_IDS.schedules.id:
        return "schedule";
      default:
        return "module";
    }
  };

  showDeleteModuleModal = () => {
    this.props.showModal({
      content: (
        <DeleteModuleModal
          hideModal={this.props.hideModal}
          onDelete={this.handleDeleteModule}
          moduleId={this.props.module.id}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  handleDeleteModule = () => {
    this.props.hideModal();
    this.props.onDelete(this.props.module.id);
  };

  handleShowInNavigationToggle = (e, val) =>
    this.setState({ showInNavigation: val });

  handleModuleTypeIconsToggle = (e, val) =>
    this.setState({ enableRecordTypeIcons: val });

  handleHasApprovalToggle = (e, val) => this.setState({ hasApproval: val });

  handleModuleTypeToggle = (e, val) =>
    this.setState({
      enableRecordTypes: val,
      enableRecordTypeIcons: false
    });

  render() {
    const typeName = this.getTypeName();
    return (
      <StyleWrapper
        hideModal={this.props.hideModal}
        heading={this.isNewModule ? `Add ${typeName}` : `Edit ${typeName}`}
        width={410}
      >
        <form onSubmit={this.handleSubmit}>
          <InputSection>
            <InputGroup>
              <Label htmlFor="name">Name</Label>
              <Input
                type="text"
                value={this.state.name}
                name="name"
                onChange={this.handleFieldValueChange}
                onBlur={this.onNameBlur}
                maxLength="255"
                autoFocus
              />
              <div styleName="colorIcon">
                <ColorPicker
                  onSelect={this.handleColorSelect}
                  selected={{ backgroundColor: this.state.color }}
                />
              </div>
            </InputGroup>
            <InputGroup
              style={{
                flex: "inherit",
                marginLeft: 7,
                width: 70
              }}
            >
              <Label>Icon</Label>
              <IconSelector
                iconGroup={ICON_GROUP}
                selectIcon={this.handleIconSelect}
              >
                <IconSelectorLabel icon={this.state.icon} />
              </IconSelector>
            </InputGroup>
          </InputSection>

          <InputSection>
            <InputGroup>
              <MoreInfo
                title="Advanced settings"
                expandedTitle="Hide advanced settings"
              >
                <InputSection>
                  <InputGroup>
                    <Label htmlFor="internalName">Internal Name</Label>
                    <Input
                      type="text"
                      value={this.state.internalName}
                      name="internalName"
                      onChange={this.handleFieldValueChange}
                      onBlur={this.onInternalNameBlur}
                      maxLength="255"
                    />
                  </InputGroup>
                </InputSection>

                <div styleName="heading">Records</div>

                <InputSection>
                  <InputGroup>
                    <Label htmlFor="recordName">
                      Record name in the singular
                    </Label>
                    <Input
                      type="text"
                      value={this.state.recordName}
                      name="recordName"
                      onChange={this.handleFieldValueChange}
                      maxLength="255"
                    />
                  </InputGroup>
                </InputSection>
                <InputSection>
                  <InputGroup>
                    <Label htmlFor="recordNamePlural">
                      Record name in the plural
                    </Label>
                    <Input
                      type="text"
                      value={this.state.recordNamePlural}
                      name="recordNamePlural"
                      onChange={this.handleFieldValueChange}
                      maxLength="255"
                    />
                  </InputGroup>
                </InputSection>
                <InputSection>
                  <InputGroup>
                    <Label htmlFor="recordPrefix">Record Prefix</Label>
                    <Input
                      type="text"
                      value={this.state.recordPrefix}
                      name="recordPrefix"
                      onChange={this.handleFieldValueChange}
                      onBlur={this.onRecordPrefixBlur}
                      maxLength="5"
                    />
                  </InputGroup>
                </InputSection>

                <div styleName="heading">Module</div>
                <InputSection>
                  <InputGroup>
                    <Label htmlFor="recordPrefix">Module Type</Label>
                    <Select
                      value={this.state.typeId}
                      onChange={this.handleFieldValueChange}
                      name="typeId"
                    >
                      <option value={null}>(None)</option>
                      <option value={STANDARD_MODULE_IDS.schedules.id}>
                        Schedule
                      </option>
                    </Select>
                  </InputGroup>
                </InputSection>
                <Toggle
                  label="Show in navigation"
                  labelStyle={{ fontWeight: "normal" }}
                  onToggle={this.handleShowInNavigationToggle}
                  style={{ marginBottom: 7 }}
                  toggled={this.state.showInNavigation}
                />
                <Toggle
                  label="Has approval"
                  labelStyle={{ fontWeight: "normal" }}
                  onToggle={this.handleHasApprovalToggle}
                  style={{ marginBottom: 7 }}
                  toggled={this.state.hasApproval}
                />
                <Toggle
                  label="Enable record types"
                  labelStyle={{ fontWeight: "normal" }}
                  onToggle={this.handleModuleTypeToggle}
                  style={{ marginBottom: 7 }}
                  toggled={this.state.enableRecordTypes}
                />
                <Toggle
                  disabled={!this.state.enableRecordTypes}
                  inputStyle={{
                    cursor: this.state.enableRecordTypes
                      ? "default"
                      : "not-allowed"
                  }}
                  label="Record types use icons and colors"
                  labelStyle={{ fontWeight: "normal" }}
                  onToggle={this.handleModuleTypeIconsToggle}
                  style={{ marginBottom: 10 }}
                  toggled={this.state.enableRecordTypeIcons}
                />
              </MoreInfo>
            </InputGroup>
          </InputSection>
          <div styleName="buttons">
            <ButtonGroup>
              <Submit title="Save" disabled={!this.validateForm()} />
              <ButtonOutline title="Cancel" onClick={this.props.hideModal} />
            </ButtonGroup>
            {this.isNewModule ? (
              ""
            ) : (
              <div
                role="button"
                tabIndex="0"
                styleName="delete"
                onClick={this.showDeleteModuleModal}
              >
                Delete
                <i className="material-icons">delete_forever</i>
              </div>
            )}
          </div>
        </form>
      </StyleWrapper>
    );
  }
}

CreateModuleModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  showModal: PropTypes.func,
  eventId: PropTypes.number.isRequired,
  module: PropTypes.shape({
    id: PropTypes.string
  })
};

export default CreateModuleModal;
