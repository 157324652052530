import React from "react";
import { Div, DownIcon } from "components/Base";
import CollapsablePanel from "ui-kit/CollapsiblePanel";
import { getters, actions } from "../model";
import { connect } from "react-redux";
import * as R from "ramda";
import { getParentCategory } from "../selectors";

const indexedMap = R.addIndex(R.map);

const countAllGroups = R.compose(
  R.reduce(R.add, 0),
  R.map(
    R.compose(
      R.length,
      R.prop("items")
    )
  ),
  R.propOr([], "groups")
);

const decorate = connect(
  (state, props) => ({
    categories: getters.categories(state, props),
    selectedCategory: getters.selectedCategory(state, props),
    parentCategory: getParentCategory(state, props),
    highlightColor: getters.highlightColor(state, props)
  }),
  {
    selectCategory: actions.setSelectedCategory
  }
);

const Categories = ({
  categories,
  selectedCategory,
  selectCategory,
  parentCategory,
  highlightColor
}) => (
  <Div
    style={{
      background: "#FFFFFF",
      padding: 20,
      borderRadius: 4
    }}
  >
    <Div
      style={{
        color: "#333333",
        fontSize: 18,
        marginBottom: 15
      }}
      fw={4}
    >
      Categories
    </Div>
    {indexedMap(
      (category, index) => (
        <CollapsablePanel
          bg="transparent"
          right={true}
          arrowIcon={DownIcon}
          arrowColor="neutral7"
          key={category.id}
          heading={
            <Div
              style={{
                color: "#333333",
                fontSize: 18,
                marginRight: "auto"
              }}
              py={2}
              fw={parentCategory === category.name ? 4 : 3}
            >
              {category.name}
            </Div>
          }
          style={{
            borderBottom:
              categories.length - 1 === index
                ? "none"
                : "1px solid rgba(0, 0, 0, 0.16)"
          }}
        >
          <Div>
            <Div
              color={
                selectedCategory === category.name ? highlightColor : "#636363"
              }
              onClick={() => selectCategory(category.name)}
              py={2}
              fw={3}
              bg={{
                default: "inherit",
                hover: "#f5f5f5"
              }}
              px={2}
            >
              Show All ({countAllGroups(category)})
            </Div>
            {R.map(
              item => (
                <Div
                  key={item.id}
                  bg={{
                    default: "inherit",
                    hover: "#f5f5f5"
                  }}
                  px={2}
                  color={
                    selectedCategory === item.name ? highlightColor : "#636363"
                  }
                  onClick={() => selectCategory(item.name)}
                  py={2}
                  fw={3}
                >
                  {item.name} ({item.items.length})
                </Div>
              ),
              category.groups
            )}
          </Div>
        </CollapsablePanel>
      ),
      categories
    )}
  </Div>
);

export default decorate(Categories);
