import PropTypes from "prop-types";
import React, { Component } from "react";
import { DragSource, DropTarget } from "react-dnd";
import { findDOMNode } from "react-dom";
import DragIcon from "components/Global/Icons/DragHandle";
import DropdownOption from "./";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index
    };
  },
  endDrag(props, monitor) {
    if (monitor.didDrop() && typeof props.onDragEnd === "function") {
      return props.onDragEnd(monitor.getDropResult());
    }
  }
};

const cardTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;
    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.moveCard(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

@DropTarget(props => props.dndId, cardTarget, connect => ({
  connectDropTarget: connect.dropTarget()
}))
@DragSource(props => props.dndId, cardSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
}))
@CSSModules(css)
class DraggableDropdownOption extends Component {
  render() {
    const {
      isDragging,
      connectDragSource,
      connectDropTarget,
      children
    } = this.props;
    return connectDragSource(
      connectDropTarget(
        <div
          styleName="dragContainer"
          style={{ opacity: isDragging ? 0.5 : 1 }}
        >
          <div styleName="dragIcon">
            <DragIcon />
          </div>
          <div styleName="dragInput">{children}</div>
        </div>
      )
    );
  }
}

DraggableDropdownOption.propTypes = {
  dndId: PropTypes.string.isRequired,
  onDragEnd: PropTypes.func.isRequired,
  moveCard: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired
};

export default DraggableDropdownOption;
