import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";

export default class RemoveEventTeamMemberModal extends Component {
  constructor(props) {
    super(props);
  }

  @autobind
  _onClick() {
    const data = {
      userId: this.props.userId
    };
    this.props.handleRemoveMember(data);
    this.props.hideModal();
  }

  render() {
    return (
      <div>
        <div className="modal-header">
          <div className="modal-title">Remove member?</div>
        </div>

        <div className="modal-body">
          <div className="modal-body-wrapper">
            <div>
              Are you sure you want to remove{" "}
              <strong>{this.props.userName}</strong> from this event?
            </div>
            <br />
            <div className="actions-wrapper">
              <a
                className="action-cancel small clear"
                onClick={this.props.hideModal}
              >
                Cancel
              </a>
              <span className="action-positive small" onClick={this._onClick}>
                Remove
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
