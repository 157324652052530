import React from "react";
import SubNav from "./SubNav";
import PageTitle from "./PageTitle";

import { Div } from "components/Base";

const Wrapper = ({
  titles = [],
  tabs = [],
  subtabs = [],
  children,
  ...otherProps
}) => (
  <Div
    display="column.flex-start.stretch"
    style={{ overflow: "hidden" }}
    height={1}
    width={1}
    {...otherProps}
  >
    <PageTitle titles={titles} />
    <SubNav tabs={tabs} subtabs={subtabs} />
    {children}
  </Div>
);

export default Wrapper;
