import * as R from "ramda";
import React from "react";
import CSSModules from "react-css-modules";
import { connect } from "react-redux";
import { createContext } from "redux-mvc";

import module from "./index";
import { getPage } from "./selectors";

import styles from "./styles.scss";

const decorate = R.compose(
  createContext({
    module,
    options: {
      persist: false
    }
  }),
  connect((state, props) => ({
    page: getPage(state, props)
  })),
  CSSModules(styles)
);

const Layout = ({ page }) => (
  <section styleName="grid">
    <h1 styleName="title">{page.title}</h1>
    <h2 styleName="subtitle">{page.subtitle}</h2>
    <div dangerouslySetInnerHTML={{ __html: page.content }} />
  </section>
);

export default decorate(Layout);
