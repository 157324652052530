import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const FormSubTitle = ({ children }) => (
  <div styleName="subTitle">{children}</div>
);

export const Green = CSSModules(
  ({ children }) => <span styleName="green">{children}</span>,
  css
);
export const Red = CSSModules(
  ({ children }) => <span styleName="red">{children}</span>,
  css
);
export const Gold = CSSModules(
  ({ children }) => <span styleName="gold">{children}</span>,
  css
);

FormSubTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.node
  ]).isRequired
};

export default CSSModules(FormSubTitle, css);
