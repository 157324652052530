import { connect } from "react-redux";
import Dates from "./Dates";

import { eventDetails } from "redux/modules/event/selectors";

function mapStateToProps(state) {
  return {
    dayGroups: eventDetails(state).date_groups,
    startDate: eventDetails(state).date_from,
    endDate: eventDetails(state).date_to
  };
}

export default connect(mapStateToProps)(Dates);
