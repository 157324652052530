import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { Div } from "components/Base";
import { actions } from "../../model";

import Tooltip from "components/Global/Tooltip";
import Notifications from "Notifications/View";
import HeaderAvatar from "components/Global/Headers/HeaderAvatar";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import { Link } from "react-router";

import {
  eventDetails as getEventDetails,
  isConferenceTemplate
} from "redux/modules/event/selectors";
import { user as getUser } from "redux/modules/user/selectors";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import {
  getEventInitial,
  getLogoImageUrl,
  getEventDates,
  getEventVenue,
  getShowPublishButton
} from "../../selectors";

const decorate = R.compose(
  connect(
    (state, props) => ({
      eventDetails: getEventDetails(state, props),
      isConferenceTemplate: isConferenceTemplate(state, props),
      user: getUser(state, props),
      eventInitial: getEventInitial(state),
      logoImageUrl: getLogoImageUrl(state),
      eventDates: getEventDates(state),
      venue: getEventVenue(state),
      showPublishButton: getShowPublishButton(state),
      canUpdateSettings: canEventUserDo(state)(
        `${STANDARD_MODULE_IDS.settings.id}_update`
      )
    }),
    {
      onToggleCollapsed: actions.toggleCollapse,
      showLogoFilepicker: actions.showLogoFilepicker,
      publishEvent: actions.publishEvent
    }
  ),
  CSSModules(css)
);

const Header = ({
  eventDetails,
  onToggleCollapsed,
  eventInitial,
  logoImageUrl,
  showLogoFilepicker,
  eventDates,
  isConferenceTemplate,
  canUpdateSettings
}) => {
  return (
    <div className={css.header}>
      <Div display="row.flex-start.center">
        <div className={css.menuIcon} onClick={onToggleCollapsed}>
          <span className="material-icons-outlined">menu</span>
        </div>

        <Tooltip tooltip="View all my organizations" placement="bottom">
          <Link
            to="/home"
            style={{
              display: "flex"
            }}
          >
            <img
              src="https://d2dks4tzxs6xee.cloudfront.net/img/LenndIcon.png"
              height={30}
            />
          </Link>
        </Tooltip>
        <Div display="row.flex-start.center" ml={2}>
          <Div fs={5} fw={4} color="black" mr={2}>
            +
          </Div>

          {logoImageUrl ? (
            <Div
              width={30}
              height={30}
              bra={1}
              bg="gray1"
              display="row.center.center"
              mr={2}
              style={{
                backgroundImage: `url(${logoImageUrl})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                border: "1px solid #eee"
              }}
            />
          ) : canUpdateSettings ? (
            <Tooltip tooltip="Upload event logo" placement="bottom">
              <Div
                width={30}
                height={30}
                bra={1}
                bg={{
                  default: "gray1",
                  hover: "gray2"
                }}
                display="row.center.center"
                mr={2}
                onClick={showLogoFilepicker}
              >
                <Div fw={3} color="#ccc" fs={3}>
                  {eventInitial}
                </Div>
              </Div>
            </Tooltip>
          ) : (
            <Div
              width={30}
              height={30}
              bra={1}
              bg={{
                default: "gray1",
                hover: "gray2"
              }}
              display="row.center.center"
              mr={2}
            >
              <Div fw={3} color="#ccc" fs={3}>
                {eventInitial}
              </Div>
            </Div>
          )}
          <Div display="column">
            <Div
              fs={3}
              fw={4}
              color="black"
              style={{
                fontSize: "18px"
              }}
            >
              {eventDetails.name}
            </Div>
            {eventDates ? (
              <div className={css.eventMeta}>
                {eventDates ? (
                  <span className="material-icons-outlined">
                    calendar_today
                  </span>
                ) : null}
                {eventDates}
                {/*
              <div className={css.eventMetaSeparator}>&middot;</div>
              <span className="material-icons-outlined">place</span>
              {venue}
              */}
              </div>
            ) : null}
          </Div>
        </Div>
      </Div>

      <Div display="row.flex-end.center">
        {/*
        {showPublishButton ? (
          <Tooltip
            tooltip="After publishing the event will be visible to visitors."
            placement="bottom"
          >
            <span>
              <MediumFilledButton
                bg="#641efc"
                color="white"
                onClick={publishEvent}
                mr={5}
                data-cy="event-publish-button"
              >
                Publish
              </MediumFilledButton>
            </span>
          </Tooltip>
        ) : null}

        {["hybrid", "virtual"].includes(eventDetails.scope) ? (
          <Link
            target="_blank"
            to={`/register/${eventDetails.slug}/${eventDetails.uuid}`}
          >
            <Div color="black" fw={3}>
              {eventDetails.scope === "virtual" ? "Preview" : "Registration"}
            </Div>
          </Link>
        ) : null}
        */}

        <Link
          target="_blank"
          to={`/portals/${eventDetails.slug}/${eventDetails.uuid}`}
          style={
            isConferenceTemplate
              ? {
                  marginRight: "25px"
                }
              : undefined
          }
        >
          <Div color="black" fw={3}>
            My Portal
          </Div>
        </Link>

        {!isConferenceTemplate ? (
          <div
            styleName="notificationsWrapper"
            style={{
              marginLeft: "30px"
            }}
          >
            <Notifications
              params={{}}
              eventId={parseInt(eventDetails.id, 10)}
            />
          </div>
        ) : null}

        <div
          styleName="help"
          onClick={() => {
            window.open("https://learn.lennd.com/", "_blank");
          }}
        >
          <div styleName="content">?</div>
        </div>

        <HeaderAvatar />
      </Div>
    </div>
  );
};

export default decorate(Header);
