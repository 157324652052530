import * as R from "ramda";
import {
  put,
  take,
  all,
  fork,
  call,
  select,
  takeEvery,
  cancelled
} from "redux-saga/effects";
import { eventChannel } from "redux-saga";
import { registerError } from "redux/modules/errors/actions";

import { getters, actions } from "./model";

import { makeFuture } from "utils/General/sagas";
import Helpers from "utils/Global/Helpers";

import videojs from "video.js";

const UPLOAD_EVENT_TYPES = {
  UPLOADED: "video-uploaded",
  UPLOAD_PROGRESS: "uploading-progress",
  UPLOAD_ERROR: "uploading-error"
};

const makeVideoEventChan = (videoPlayer, event) =>
  eventChannel(emitter => {
    videoPlayer.on(event, emitter);

    return () => videoPlayer.off(event, emitter);
  });

function* watchOnFinishRecord(videoPlayer) {
  const videoEventChan = yield call(
    makeVideoEventChan,
    videoPlayer,
    "finishRecord"
  );
  try {
    for (;;) {
      yield take(videoEventChan);
      // the recordedData object contains the stream data that will be uploaded to filestack
      const blobVideo = videoPlayer.recordedData;
      yield put(actions.setVideo(blobVideo));
      videoPlayer.record().stopDevice();
    }
  } finally {
    if (yield cancelled()) {
      videoEventChan.close();
    }
  }
}

function* watchOnVideoError(videoPlayer) {
  const videoEventChan = yield call(
    makeVideoEventChan,
    videoPlayer,
    "deviceError"
  );
  try {
    for (;;) {
      const event = yield take(videoEventChan);
      yield call(
        // eslint-disable-next-line no-console
        console.log,
        "Video Recorder - device error:",
        videoPlayer.deviceErrorCode,
        event
      );
      if (R.prop("type", event) === "deviceError") {
        yield put(
          registerError([
            {
              system: event,
              user: "Please allow access to Microphone and Camera"
            }
          ])
        );
      }
    }
  } finally {
    if (yield cancelled()) {
      videoEventChan.close();
    }
  }
}

function* subscribeToVideoEvents(videoPlayer) {
  yield fork(watchOnFinishRecord, videoPlayer);
  yield fork(watchOnVideoError, videoPlayer);
}

const subscribeToVideoUploader = function*(video) {
  return eventChannel(emit => {
    const actions = {};
    const filestackClient = window.filestack.init(
      // eslint-disable-next-line no-underscore-dangle
      window.__FILEPICKER_API_KEY__
    );

    const onProgress = evt =>
      emit({
        actionType: UPLOAD_EVENT_TYPES.UPLOAD_PROGRESS,
        payload: evt.totalPercent
      });
    const onDone = res =>
      emit({ actionType: UPLOAD_EVENT_TYPES.UPLOADED, payload: res });
    const onError = error =>
      emit({ actionType: UPLOAD_EVENT_TYPES.UPLOAD_ERROR, payload: error });

    filestackClient
      .upload(video, { onProgress }, { filename: video.name }, actions)
      .then(onDone)
      .catch(onError);

    return () => {
      actions.cancel(); // if we close the channel, we need to cancel the upload
    };
  });
};

const processUpload = function*({ actionType, payload }) {
  if (actionType === UPLOAD_EVENT_TYPES.UPLOAD_ERROR) {
    yield put(
      registerError([
        {
          system: payload,
          user: "An error occurred while uploading the video, please try again"
        }
      ])
    );
  }
  if (actionType === UPLOAD_EVENT_TYPES.UPLOAD_PROGRESS) {
    yield put(actions.setLoadingProgress(payload));
  }
  if (actionType === UPLOAD_EVENT_TYPES.UPLOADED) {
    yield put(actions.setUploadedUrl(R.prop("url", payload)));
  }
};

const setupVideoElement = function*(id) {
  const videoContainer = document.getElementById("video-container");
  const videoEl = document.createElement("video");
  const wrapper = document.createElement("div");
  wrapper.setAttribute("data-vjs-player", true);
  videoEl.id = id;
  videoEl.setAttribute("playsInline", true);
  videoEl.className = "video-js vjs-default-skin vjs-hidden";
  wrapper.appendChild(videoEl);
  videoContainer.appendChild(wrapper);
};

const watchInitVideo = function*() {
  let videoPlayer = null;
  let videoTasks = null;

  for (;;) {
    const videoAction = yield take([
      actions.initVideo.type,
      actions.clearVideo.type,
      actions.setUploadedUrl.type,
      actions.setImportedUrl.type,
      actions.cancelInstance.type
    ]);
    if (videoTasks) {
      yield videoTasks.cancel();
    }
    if (videoPlayer) {
      const isRecordEnabled = videoPlayer.usingPlugin("record");
      if (isRecordEnabled) {
        videoPlayer.record().destroy();
      }
      videoPlayer.dispose();
      videoPlayer = null;
    }
    if (videoAction.type === actions.initVideo.type) {
      const payload = R.prop("payload", videoAction);
      const fieldId = R.prop("fieldId", payload);
      yield call(setupVideoElement, fieldId);
      // eslint-disable-next-line no-loop-func
      videoPlayer = videojs(fieldId, R.prop("videoProps", payload), () =>
        videoPlayer.show()
      );
      videoTasks = yield fork(subscribeToVideoEvents, videoPlayer);
    }
  }
};

const watchUploadVideo = function*() {
  let filestackChannel;
  for (;;) {
    yield take(actions.uploadVideo.type);
    if (filestackChannel) {
      filestackChannel.close();
    }
    const video = yield select(getters.video);
    if (video) {
      try {
        filestackChannel = yield call(subscribeToVideoUploader, video);
        yield takeEvery(filestackChannel, processUpload);
      } catch (error) {
        yield put(
          registerError([
            {
              system: error,
              user:
                "An error occurred while uploading the video, please try again"
            }
          ])
        );
      }
    }
  }
};

const watchOpenFilepicker = function*() {
  for (;;) {
    yield take(actions.importVideo.type);
    const handleUpload = makeFuture();

    Helpers.getFilepicker(
      {
        multiple: false,
        accept: ["video/mp4", "video/webm"],
        fromSources: ["local_file_system", "googledrive"]
      },
      {},
      handleUpload.done
    );
    const files = yield call(handleUpload.onRealized);
    const newPath = R.compose(
      R.head,
      R.map(R.prop("url"))
    )(files);
    yield put(actions.setImportedUrl(newPath));
  }
};

const rootSaga = function*() {
  yield all([
    fork(watchInitVideo),
    fork(watchUploadVideo),
    fork(watchOpenFilepicker)
  ]);
};

export default rootSaga;
