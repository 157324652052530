import { createModule } from "redux-mvc";

import model from "./model";

import sagasModule from "Modules/AddEditColumnModal/sagas";

const module = createModule({
  ...model,
  observedDomains: [
    "user",
    "event",
    "permissions",
    "organization",
    "portal",
    "records",
    "submission",
    "fields",
    "modules"
  ]
});

module.setRootSaga(sagasModule);

export default module;
