/* eslint-disable no-underscore-dangle */
import Helpers from "../Global/Helpers";

module.exports = {
  getEventDetails: (credentials, eventId, via, cb) => {
    const options = {
      method: "get",
      url: `${window.__LENND_API_URL__}/event/${eventId}`,
      data: {},
      credentials,
      success: result => {
        cb(result.payload);
      }
    };
    Helpers.request(options);
  },

  getEventLocation: (eventId, cb) => {
    const options = {
      method: "get",
      url: `/api/professional/event/${eventId}/location`,
      data: {},
      success: result => {
        cb(result.location);
      }
    };
    Helpers.request(options);
  },

  getEventFeatures: (eventId, cb) => {
    const options = {
      method: "get",
      url: `/api/professional/event/${eventId}/features`,
      data: {},
      success: features => {
        cb(features);
      }
    };
    Helpers.request(options);
  },

  getEventPhotos: (eventId, via, sortBy, cb) => {
    let params = via ? `&via=${via}` : "";
    params += sortBy ? `&sortBy=${sortBy}` : "";
    const options = {
      method: "get",
      url: `/api/professional/event/${eventId}/photos?viaActive=1${params}`,
      data: {},
      success: photos => {
        cb(photos);
      }
    };
    Helpers.request(options);
  },

  getEventVideos: (eventId, cb) => {
    const options = {
      method: "get",
      url: `/api/professional/event/${eventId}/videos`,
      data: {},
      success: videos => {
        cb(videos);
      }
    };
    Helpers.request(options);
  },

  getEventTeam: (credentials, eventId, cb) => {
    const options = {
      method: "get",
      url: `${window.__LENND_API_URL__}/event/${eventId}/team`,
      data: {},
      credentials,
      success: result => {
        cb(result.payload.team);
      }
    };
    Helpers.request(options);
  },

  prepareDownloadPhotos: (eventId, photos, width, cb) => {
    const options = {
      method: "post",
      url: "/professional/events/photostream/ajax-prep-download",
      data: {
        eventId,
        photos,
        width
      },
      success: result => {
        cb(result.id);
      }
    };
    Helpers.request(options);
  },

  uploadPhotos: (eventId, photos, cb) => {
    const options = {
      method: "post",
      url: `/api/professional/event/${eventId}/photos`,
      data: {
        photos
      },
      success: result => {
        cb(result);
      }
    };
    Helpers.request(options);
  },

  getEventRoles: cb => {
    const options = {
      method: "get",
      url: "/api/options/event/roles",
      data: {},
      success: roles => {
        cb(roles);
      }
    };
    Helpers.request(options);
  },

  updateEventStatus: (eventId, isDraft, cb) => {
    const options = {
      method: "put",
      url: `/api/professional/event/${eventId}/status`,
      data: {
        isDraft
      },
      success: () => {
        cb();
      }
    };
    Helpers.request(options);
  },

  updateEvent: (credentials, eventId, data, cb) => {
    const options = {
      method: "put",
      url: `${window.__LENND_API_URL__}/event/${eventId}`,
      data,
      credentials,
      success: () => {
        cb();
      }
    };
    Helpers.request(options);
  },

  deletePhotos: (eventId, photos, cb) => {
    const options = {
      method: "delete",
      url: `/api/professional/event/${eventId}/photos`,
      data: {
        photos
      },
      success: () => {
        cb();
      }
    };
    Helpers.request(options);
  },

  sortPhotos: (eventId, setId, photos, cb) => {
    const options = {
      method: "put",
      url: `/api/professional/event/${eventId}/photos/sort`,
      data: {
        setId,
        photos
      },
      success: () => {
        cb();
      }
    };
    Helpers.request(options);
  },

  updateEventCoverPhoto: (eventId, photoId, cb) => {
    const options = {
      method: "put",
      url: `/api/professional/event/${eventId}/cover`,
      data: {
        photoId
      },
      success: () => {
        cb();
      }
    };
    Helpers.request(options);
  },

  updatePhotoPermissions: (eventId, connectionId, permissionId, cb) => {
    const options = {
      method: "put",
      url: `/api/professional/event/${eventId}/team/permissions`,
      data: {
        permissionType: "photo",
        permissionId,
        connectionId
      },
      success: () => {
        cb();
      }
    };
    Helpers.request(options);
  },

  deleteEvent: (eventId, cb) => {
    const options = {
      method: "delete",
      url: `/api/professional/event/${eventId}/details`,
      data: {},
      success: () => {
        cb();
      }
    };
    Helpers.request(options);
  },

  inviteNewUserToOrg: (credentials, data, cb) => {
    const options = {
      method: "post",
      url: `${window.__LENND_API_BASE_URL__}/organizations/${data.addToOrgId}/users/invite`,
      data,
      credentials,
      success: result => {
        cb(result.payload.user);
      }
    };
    Helpers.request(options);
  },

  inviteNewUserToEvent: (credentials, data, cb) => {
    const options = {
      method: "post",
      url: `${window.__LENND_API_BASE_URL__}/event/${data.addToEventId}/users`,
      data,
      credentials,
      success: result => {
        cb(result.payload.user);
      }
    };
    Helpers.request(options);
  },

  inviteExistingUserToEvent: (credentials, data, cb) => {
    const options = {
      method: "post",
      url: `${window.__LENND_API_URL__}/event/${data.addToEventId}/team`,
      data,
      credentials,
      success: () => {
        cb();
      }
    };
    Helpers.request(options);
  },

  removeUserFromEvent: (credentials, eventId, userId, cb) => {
    const options = {
      method: "delete",
      url: `${window.__LENND_API_URL__}/event/${eventId}/team`,
      data: {
        userId
      },
      credentials,
      success: () => {
        cb();
      }
    };
    Helpers.request(options);
  },

  updateUserFromEvent: (credentials, eventId, userId, data, cb) => {
    const options = {
      method: "put",
      url: `${window.__LENND_API_URL__}/event/${eventId}/team`,
      data: {
        userId,
        data
      },
      credentials,
      success: () => {
        cb();
      }
    };
    Helpers.request(options);
  },

  resendInvitation: (credentials, eventId, userId, cb) => {
    const options = {
      method: "put",
      url: `${window.__LENND_API_BASE_URL__}/user/invite`,
      data: {
        eventId,
        userId
      },
      credentials,
      success: () => {
        cb();
      }
    };
    Helpers.request(options);
  },

  completeOnboarding: (credentials, eventId, userId, type, cb) => {
    const options = {
      method: "put",
      url: `${window.__LENND_API_URL__}/event/${eventId}/team/onboard`,
      data: {
        eventId,
        userId,
        type
      },
      credentials,
      success: () => {
        cb();
      }
    };
    Helpers.request(options);
  },

  /**
   * members
   */
  fetchMembers: (credentials, eventId) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "get",
        url: `${window.__LENND_API_URL__}/event/${eventId}/team`,
        data: {},
        credentials,
        success: result => {
          resolve(result.payload.team);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  updateMemberPhone: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "put",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/team/phone-number`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  /**
   * groups
   */
  fetchGroups: (credentials, eventId) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "get",
        url: `${window.__LENND_API_URL__}/event/${eventId}/groups`,
        data: {},
        credentials,
        success: result => {
          resolve(result.groups);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  addGroup: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "post",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/groups`,
        data,
        credentials,
        success: result => {
          resolve(result.group);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  updateGroup: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "put",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/groups/${data.groupId}`,
        data,
        credentials,
        success: result => {
          resolve(result.group);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  updateGroupMembers: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "put",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/groups/${data.groupId}/members`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  deleteGroup: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "delete",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/groups/${data.groupId}`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  /**
   * contacts
   */
  fetchContacts: (credentials, eventId) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "get",
        url: `${window.__LENND_API_URL__}/event/${eventId}/contacts`,
        data: {},
        credentials,
        success: result => {
          resolve(result.contacts);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  addContact: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "post",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/contacts`,
        data,
        credentials,
        success: result => {
          resolve(result.contact);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  updateContact: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "put",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/contacts/${data.contactId}`,
        data,
        credentials,
        success: result => {
          resolve(result.contact);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  updateContactMembers: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "put",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/contacts/${data.contactId}/members`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  /**
   * credentials
   */
  fetchCredentials: (credentials, eventId) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "get",
        url: `${window.__LENND_API_URL__}/event/${eventId}/credentials`,
        data: {},
        credentials,
        success: result => {
          resolve(result.credentials);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  addCredential: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "post",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/credentials`,
        data,
        credentials,
        success: result => {
          resolve(result.credential);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  updateCredential: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "put",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/credentials/${data.credentialId}`,
        data,
        credentials,
        success: result => {
          resolve(result.credential);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  addCredentialMember: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "post",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/credentials/${data.credentialId}/members`,
        data,
        credentials,
        success: result => {
          resolve(result.credential);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  removeCredentialMember: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "delete",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/credentials/${data.credentialId}/members`,
        data,
        credentials,
        success: result => {
          resolve(result.credential);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  updateCredentialMembers: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "put",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/credentials/${data.credentialId}/members`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  deleteCredential: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "delete",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/credentials/${data.credentialId}`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    })
};
