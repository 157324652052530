import React, { Component } from "react";
import getValue from "utils/value-types/get-value/source";
import User from "./User";
import Submission from "./Submission";
import EventbriteOrder from "./EventbriteOrder";
import Unknown from "./Unknown";
import { get, isEqual } from "lodash";

export default class SourceCellFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  formatValue(props) {
    return getValue(props.value);
  }

  getFormatter(type) {
    switch (type) {
      case "user":
        return User;
      case "submission":
        return Submission;
      case "eventbrite-order":
        return EventbriteOrder;
      default:
        return Unknown;
    }
  }

  render() {
    const value = this.formatValue(this.props);
    const Formatter = this.getFormatter(value.type);
    const references = get(
      this.props.dependentValues,
      ["meta", "references"],
      {}
    );
    const eventId = get(this.props.dependentValues, ["meta", "eventId"]);
    const moduleId = get(this.props.dependentValues, ["meta", "moduleId"]);
    const helpers = get(this.props.dependentValues, ["helpers"], {});

    return (
      <Formatter
        value={value}
        references={references}
        eventId={eventId}
        moduleId={moduleId}
        helpers={helpers}
      />
    );
  }
}
