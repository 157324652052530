import React from "react";
import { Div, Text4 } from "components/Base";
import SelectBox from "./SelectBox";
import InputRow from "Forms/WizardModal/View/Common/LayoutMock/InputRow";

const SelectMultiCategory = () => (
  <SelectBox
    method="multi-bulk"
    title="Multiple Asset Request Grids"
    description="Collect multiple categories of asset requests on one form."
  >
    <Div width={1} display="column">
      <Text4 bold color="neutral6" ml={1} mb={3}>
        GOLF CARTS
      </Text4>
      <InputRow />
      <Text4 bold color="neutral6" ml={1} mb={3} mt={3}>
        HEAVY EQUIPAMENT
      </Text4>
      <InputRow />
    </Div>
  </SelectBox>
);

export default SelectMultiCategory;
