import React from "react";
import { connect } from "react-redux";
import { getters } from "Catering/Dashboard";
import Dashboard from "./Dashboard";
import Loading from "./Loading";

const decorate = connect(state => ({
  loading: getters.loading(state)
}));

const Layout = ({ loading }) => (loading ? <Loading /> : <Dashboard />);

export default decorate(Layout);
