import React from "react";
import * as R from "ramda";

import { Div, MediumOutlineButton } from "components/Base";
import CollapsablePanel from "ui-kit/CollapsiblePanel";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import { connect } from "react-redux";
import { actions, getters } from "../../model";
import { CARD_TYPES } from "../../constants";
import NotConfigured from "../NotConfigured";
import { noop } from "utils/General";
import Table from "../Table";

const decorate = R.compose(
  connect(
    (state, props) => ({
      assignedRows: getters.assignedSellItems(state, props),
      loadingSellItems: getters.loadingSellItems(state)
    }),
    {
      init: actions.init,
      openCommunitySettingsModal: () =>
        actions.setShowCommunitySettingsModal(true),
      goToLearnLennd: actions.goToLearnLennd,
      showSellItemsModal: actions.openSellItemsModal,
      removeSellItem: actions.removeSellItem,
      openUpdateModal: ({ id }) =>
        actions.openUpdateGroupItemModal({
          id,
          assignModalType: CARD_TYPES.REQUESTABLE
        })
    }
  ),
  CSSModules(css)
);

const SellItems = ({
  assignedRows,
  goToLearnLennd,
  loadingSellItems,
  showSellItemsModal,
  removeSellItem,
  openUpdateModal
}) => (
  <Div flex={1} display="column.flex-start.stretch">
    <CollapsablePanel
      heading={() => (
        <Div display="row.space-between.center" my={2} width={1} pr={4}>
          <div className={css.title}>Sell Items</div>
          <NotConfigured
            configured={!R.isEmpty(assignedRows)}
            loading={loadingSellItems}
            text={`${R.length(assignedRows)} Sellable Item${
              R.length(assignedRows) !== 1 ? "s" : ""
            } assigned`}
          />
        </Div>
      )}
      bg="white"
      arrowColor="gray7"
      bc="neutral3"
      right={false}
      bra={1}
      mb={3}
      p={3}
      collapsedDefault={true}
    >
      <div styleName="wrapper">
        <div styleName="boldDescription">
          Select what items you would like to make available for purchase via
          this portal
        </div>
        <div
          styleName="row-btn"
          style={{
            marginBottom: "15px"
          }}
        >
          <MediumOutlineButton
            color="primary7"
            bc="primary7"
            LeftIcon={() => (
              <span
                class="material-icons"
                style={{ fontSize: 16, marginRight: 8, height: 16 }}
              >
                format_list_bulleted
              </span>
            )}
            width={200}
            onClick={() => showSellItemsModal()}
          >
            Select items
          </MediumOutlineButton>
          <MediumOutlineButton
            color="primary7"
            bc="primary7"
            LeftIcon={() => (
              <span
                class="material-icons"
                style={{ fontSize: 16, marginRight: 8, height: 16 }}
              >
                import_contacts
              </span>
            )}
            ml={2}
            onClick={goToLearnLennd}
          >
            Learn about selling items
          </MediumOutlineButton>
        </div>
        <Table
          rows={assignedRows}
          openUpdateModal={openUpdateModal}
          deleteRow={removeSellItem}
          showRequired={false}
          onReorder={noop}
          shouldSort={false}
          columns={[
            {
              name: "Item",
              value: "name",
              style: { flex: 1 },
              type: "text",
              showTooltip: true
            },
            {
              name: "Type",
              value: "type_name",
              style: { flex: 1.2 },
              type: "text"
            },
            {
              name: "Price",
              value: "price",
              style: { flex: 0.7 },
              type: "price"
            },
            {
              name: "Max Qty.",
              value: "max_quantity",
              style: { flex: 1 },
              type: "text"
            },
            {
              name: "Status",
              value: "status",
              style: { flex: 0.8 },
              type: "status"
            },
            {
              name: "Actions",
              value: "actions",
              style: { width: 80, textAlign: "center" },
              type: "actions"
            }
          ]}
          emptyText="You haven't made any items available to purchase yet."
        />
      </div>
    </CollapsablePanel>
  </Div>
);

export default decorate(SellItems);
