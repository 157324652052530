import React, { Component } from "react";
import getApprovalValue from "utils/value-types/get-value/approval";
import ApproversLabel from "components/Global/Approvals/ApproversLabel";
import { parseApproval } from "components/Global/Approvals/utils/approvals-helpers";
import { isEqual } from "lodash";

class ApprovalCellFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  render() {
    const { value, dependentValues } = this.props;
    return (
      <ApproversLabel
        {...{
          ...parseApproval({
            record: {
              approval_manifest: getApprovalValue(value)
            },
            userId: dependentValues.meta.userId
          }),
          approveLineItems: dependentValues.helpers.approveRecord,
          rejectLineItems: dependentValues.helpers.rejectRecord,
          removeApproval: dependentValues.helpers.removeReview,
          showMenu: true
        }}
      />
    );
  }
}

export default ApprovalCellFormatter;
