import React, { Component } from "react";
import * as PortalSelectors from "redux/modules/portal/selectors";
import { withRouter } from "react-router";

import PortalUsersApi from "redux/modules/portal/users/api";
import ActivityApi from "redux/modules/activity/api";
import ActivityContstants from "redux/modules/activity/constants";

import { connect } from "react-redux";
import { getCredentials } from "redux/modules/user/selectors";
import PlatformLoading from "components/Platform/PlatformLoading";

const decorate = connect(state => ({
  userCredentials: getCredentials(state),
  eventDetails: PortalSelectors.eventDetails(state)
}));

class PortalLoginCallbackController extends Component {
  componentDidMount() {
    setTimeout(async () => {
      if (this.props.location.query && this.props.location.query.create) {
        await PortalUsersApi.post(this.props.userCredentials, {
          eventId: this.props.eventDetails.id,
          userId: this.props.userCredentials.userId
        });
      }

      await ActivityApi.post({
        eventId: this.props.eventDetails.id,
        actorId: this.props.userCredentials.userId,
        type: ActivityContstants.LOGIN
      });

      // @NOTE: Keeping this in case we want to use switcher instead.
      window.location =
        this.props.location.query && this.props.location.query.returnTo
          ? this.props.location.query.returnTo
          : `/portal-switch/${this.props.eventDetails.slug}/${this.props.eventDetails.uuid}`;
    }, 500);
  }

  render() {
    return <PlatformLoading message="Logging in..." />;
  }
}

export default withRouter(decorate(PortalLoginCallbackController));
