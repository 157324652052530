export default [
  "separator",
  "section",
  "image",
  "header",
  "contact-reference",
  "submitter-reference",
  "account-reference",
  "credential-order-reference",
  "order-items",
  "order-fulfillment",
  "order-customer"
];
