import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Item from "components/Global/Module/Sidebar/Item";
import Title from "components/Global/Module/Sidebar/Title";
import Separator from "components/Global/Module/Sidebar/Separator";
import SubTitle from "components/Global/Module/Sidebar/SubTitle";
import SubItem from "components/Global/Module/Sidebar/SubItem";
import { LOCATIONS } from "utils/standard-module-field-ids";
import getValue from "utils/value-types/get-value";
import Content from "components/Global/Module/Sidebar/Container";

const Sidebar = ({
  locations,
  showAddLocationModal,
  showEditLocationModal,
  getAllLocationsPath,
  isViewingAllLocations
}) => (
  <Content>
    <Title>Locations</Title>
    <Item active={isViewingAllLocations} linkTo={getAllLocationsPath}>
      All Areas
    </Item>
    <Separator />
    <SubTitle>By Location</SubTitle>
    {locations.map(location => (
      <SubItem
        key={location.id}
        active={location.active}
        linkProps={{
          pathname: location.path
        }}
      >
        {getValue(location.values[LOCATIONS.NAME], "text")}
      </SubItem>
    ))}
    <Separator />
    <Item onClick={showAddLocationModal}>
      <span styleName="settings">
        <i className="material-icons">add</i>Add location
      </span>
    </Item>
    {!isViewingAllLocations ? (
      <Item onClick={showEditLocationModal}>
        <span styleName="settings">
          <i className="material-icons">settings</i>Edit location
        </span>
      </Item>
    ) : (
      ""
    )}
  </Content>
);

export default CSSModules(Sidebar, css);
