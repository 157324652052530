import React, { Component } from "react";
import PropTypes from "prop-types";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { TextAreaInput } from "components/Base";

@CSSModules(css)
class BulkAddDropdownOptions extends Component {
  handleSave = () => {
    this.props.onSave(this.getValue());
  };

  getValue = () =>
    this.input
      .getValue()
      .split("\n")
      .filter(v => v && v.length);

  render() {
    return (
      <div styleName="container">
        <TextAreaInput
          autoFocus
          onChange={() => {}} // no op
          inputRef={r => (this.input = r)}
          placeholder="Add options separated by line breaks"
          width={1}
          mb={0}
          rows={4}
          fs={2}
        />
        <div styleName="actions">
          <button styleName="button" onClick={this.handleSave}>
            <i className="material-icons">add</i>
            Add
          </button>
          <button styleName="buttonCancel" onClick={this.props.onCancel}>
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

BulkAddDropdownOptions.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default BulkAddDropdownOptions;
