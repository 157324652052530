import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { TAB_IDS } from "./constants";

import { getTabs, getCurrentRoute } from "./selectors";
import PageTitle from "EventLight/Common/PageTitle";
import SubNav from "./SubNav";

import Dashboard from "./Dashboard/View";
import Requests from "./Requests/View";
import Meals from "./Meals/View";
import Approvers from "./Approvers/View";
import SendEmails from "EventLight/Common/SendEmail/View";
import Override from "./Override/View";

import { Div } from "components/Base";

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    currentRoute: getCurrentRoute(state, props),
    tabs: getTabs(state, props)
  }))
);

const Pages = {
  [TAB_IDS.DASHBOARD]: Dashboard,
  [TAB_IDS.REQUESTS]: Requests,
  [TAB_IDS.OVERRIDE]: Override,
  [TAB_IDS.MEALS]: Meals,
  [TAB_IDS.APPROVERS]: Approvers,
  [TAB_IDS.SEND_EMAILS]: SendEmails
};

const Wrapper = ({ currentRoute = "", tabs = [] }) => {
  return (
    <Div
      display="column.flex-start.stretch"
      height={1}
      style={{ maxHeight: "100%" }}
    >
      <PageTitle titles={["Catering"]} />
      <SubNav tabs={tabs} selectedView={currentRoute} />
      {React.createElement(R.propOr("div", currentRoute, Pages))}
    </Div>
  );
};

export default decorate(Wrapper);
