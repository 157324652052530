import React from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import {
  ButtonOutline,
  ButtonGroup
} from "components/Global/Modal/Layout/Buttons";
import { Div } from "components/Base";
import { addS } from "utils/General";

import { connect } from "react-redux";
import { showModal, hideModal } from "redux/modules/modal/actions";

const DeleteOrRemoveConfirmationModal = ({
  heading,
  hideModal,
  onDelete,
  onRemove,
  countOfSelected
}) => (
  <StyleWrapper width={500} heading={heading} hideModal={hideModal}>
    <Div>
      <Div
        bra={2}
        bc="warning6"
        b={2}
        p={3}
        mb={4}
        bg={{
          default: "white",
          hover: "warning6"
        }}
        color={{
          default: "gray7",
          hover: "white"
        }}
        onClick={() => {
          onRemove();
          hideModal();
        }}
        display="column"
      >
        <Div fw={3} fs={1} uppercase mb={1}>
          Remove from this event
        </Div>
        I want to remove the selected record
        {addS(countOfSelected)} from this event only, but still leave the record
        {addS(countOfSelected)} accessible to my other events
      </Div>

      <Div
        bra={2}
        bc="red9"
        b={2}
        p={3}
        bg={{
          default: "white",
          hover: "red9"
        }}
        color={{
          default: "gray7",
          hover: "white"
        }}
        onClick={() => {
          onDelete();
          hideModal();
        }}
        display="column"
      >
        <Div fw={3} fs={1} uppercase mb={1}>
          Remove from all events
        </Div>
        I want to permanently delete the selected record
        {addS(countOfSelected)} and understand it will also remove the record
        {addS(countOfSelected)} from all of my events
      </Div>
    </Div>
    <ButtonGroup>
      <ButtonOutline onClick={hideModal} title="Cancel" />
    </ButtonGroup>
  </StyleWrapper>
);

export default connect(
  null,
  {
    showModal,
    hideModal
  }
)(DeleteOrRemoveConfirmationModal);
