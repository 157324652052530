import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import PageTitle from "OrgLight/Common/PageTitle";

import Loading from "ui-kit/Loading";
import { Page, Title } from "EventLight/Common/FieldLayout";
import Body from "./Body";
import { withRouter } from "react-router";
import * as R from "ramda";

const decorate = R.compose(
  withRouter,
  connect(
    state => ({
      loading: getters.loading(state)
    }),
    {
      init: actions.init
    }
  )
);

const Layout = ({ loading, init, location }) => {
  useEffect(() => {
    init({
      query: location.query
    });
  }, []);

  return (
    <>
      <PageTitle titles={["Integrations"]} />
      <Page>
        <Title
          icon="sync_alt"
          title="Integrations"
          description="Connect with your other event systems"
        />

        {loading ? <Loading /> : <Body />}
      </Page>
    </>
  );
};

export default decorate(Layout);
