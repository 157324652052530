import { createSelector } from "reselect";
import * as R from "ramda";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { TAB_IDS } from "./constants";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

const isActiveRoute = (routes, route) => {
  if (Array.isArray(route)) {
    return R.compose(
      Boolean,
      R.length,
      R.intersection(route),
      R.map(R.prop("name"))
    )(routes);
  }

  return routes.some(r => r.name === route);
};

const getRouteNames = createSelector(
  (_, props) => props.routes,
  R.map(R.propOr("", "name"))
);

const mapTabId = {
  EventLightPassesDashboard: TAB_IDS.DASHBOARD,
  EventLightPassesRequests: TAB_IDS.REQUESTS,
  EventLightPassesSettings: TAB_IDS.CATALOG,
  EventLightPassesSettingsZones: TAB_IDS.ZONES,
  EventLightPassesSettingsZoneGrid: TAB_IDS.ZONE_GRID,
  EventLightPassesSettingsQuestions: TAB_IDS.QUESTIONS,
  EventLightPassesSettingsApprovers: TAB_IDS.APPROVERS,
  EventLightPassesSettingsActions: TAB_IDS.ACTIONS,
  EventLightPassesSettingsDesigns: TAB_IDS.DESIGNS,
  EventLightPassesSettingsIntegrations: TAB_IDS.INTEGRATIONS,
  EventLightPassesSendEmails: TAB_IDS.SEND_EMAILS,
  EventLightPassesSettingsInventory: TAB_IDS.INVENTORY
};

export const getCurrentRoute = createSelector(
  getRouteNames,
  R.reduce((id, route) => {
    const tabId = R.prop(route, mapTabId);
    if (tabId) {
      return R.reduced(tabId);
    }
    return id;
  }, "")
);

export const getTabs = createSelector(
  (_, props) => props.routes,
  (_, props) => props.params,
  getEventDetails,
  getCurrentRoute,
  selectFeatureFlag(flags.CAN_VIEW_TICKETING_INTEGRATIONS.NAME),
  selectFeatureFlag(flags.CAN_USE_CREDENTIAL_DESIGNS.NAME),
  (
    routes,
    params,
    eventDetails,
    currentRoute,
    canViewTicketingIntegrations,
    canUseCredentialDesigns
  ) => {
    if (
      R.contains(currentRoute, [
        TAB_IDS.CATALOG,
        TAB_IDS.ZONES,
        TAB_IDS.INVENTORY,
        TAB_IDS.ZONE_GRID,
        TAB_IDS.QUESTIONS,
        TAB_IDS.APPROVERS,
        TAB_IDS.ACTIONS,
        TAB_IDS.DESIGNS,
        TAB_IDS.INTEGRATIONS
      ])
    ) {
      return [
        {
          key: "passes",
          label: "Passes",
          active: isActiveRoute(routes, "EventLightPassesSettings"),
          to: `/event-light/${eventDetails.id}/passes/settings`
        },
        {
          key: "inventory",
          label: "Inventory",
          active: isActiveRoute(routes, "EventLightPassesSettingsInventory"),
          to: `/event-light/${eventDetails.id}/passes/settings/inventory`
        },
        {
          key: "zones",
          label: "Zones",
          active: isActiveRoute(routes, "EventLightPassesSettingsZones"),
          to: `/event-light/${eventDetails.id}/passes/settings/zones`
        },
        {
          key: "zone-grid",
          label: "Zone Grid",
          active: isActiveRoute(routes, "EventLightPassesSettingsZoneGrid"),
          to: `/event-light/${eventDetails.id}/passes/settings/zone-grid`
        },
        {
          key: "approvers",
          label: "Approvers",
          active: isActiveRoute(routes, "EventLightPassesSettingsApprovers"),
          to: `/event-light/${eventDetails.id}/passes/settings/approvers`
        },
        {
          key: "actions",
          label: "Actions",
          active: isActiveRoute(routes, "EventLightPassesSettingsActions"),
          to: `/event-light/${eventDetails.id}/passes/settings/actions`
        },
        canUseCredentialDesigns
          ? {
              key: "design",
              label: "Design",
              active: isActiveRoute(routes, "EventLightPassesSettingsDesigns"),
              to: `/event-light/${eventDetails.id}/passes/settings/design`
            }
          : undefined
      ].filter(v => v);
    }

    if ([TAB_IDS.REQUESTS].includes(currentRoute)) {
      return [
        {
          key: "all-requests",
          label: "All Requests",
          active:
            isActiveRoute(routes, "EventLightPassesRequests") &&
            params.viewId === "all-requests",
          to: `/event-light/${eventDetails.id}/passes/requests/all-requests`
        },
        {
          key: "my-approvals",
          label: "My Approvals",
          active:
            isActiveRoute(routes, "EventLightPassesRequests") &&
            params.viewId === "my-approvals",
          to: `/event-light/${eventDetails.id}/passes/requests/my-approvals`
        },
        {
          key: "issue",
          label: "Issue & Fulfill",
          active:
            isActiveRoute(routes, "EventLightPassesRequests") &&
            params.viewId === "issue",
          to: `/event-light/${eventDetails.id}/passes/requests/issue`
        },
        {
          key: "print",
          label: "Print",
          active:
            isActiveRoute(routes, "EventLightPassesRequests") &&
            params.viewId === "print",
          to: `/event-light/${eventDetails.id}/passes/requests/print`
        },
        canViewTicketingIntegrations
          ? {
              key: "integration-orders",
              label: "Integration Orders",
              active:
                isActiveRoute(routes, "EventLightPassesRequests") &&
                params.viewId === "integration-orders",
              to: `/event-light/${eventDetails.id}/passes/requests/integration-orders`
            }
          : undefined
      ].filter(v => v);
    }

    return [];
  }
);
