import React from "react";

import * as R from "ramda";

import {
  Div,
  Text1,
  Text3,
  CheckIcon,
  DownIcon,
  MediumAvatar
} from "components/Base";

import { addS } from "utils/General";

import AssignItemsPopOver from "Orders/TicketsPasses/View/AssignItemsPopOver";
import ProfileSettingsPopOver from "Orders/TicketsPasses/View/ProfileSettingsPopOver";

const isSelected = items => items.length > 0;

const formatItems = ({ items }) => {
  const itemsLength = items.length;

  const byAmount = R.ascend(R.prop("amount"));

  const sortedItems = R.compose(
    R.map(R.prop("amount")),
    R.sort(byAmount),
    R.flatten,
    R.map(R.propOr([], "prices"))
  )(items);

  if (R.isEmpty(sortedItems)) {
    return `${itemsLength} item${addS(itemsLength)}`;
  } else if (sortedItems.length === 1) {
    return `${itemsLength} item${addS(itemsLength)} . $${sortedItems[0]}`;
  }

  const areEquals = sortedItems[0] === sortedItems.slice(-1)[0];

  return `${itemsLength} item${addS(itemsLength)} . $${sortedItems[0]}${
    !areEquals ? ` - $${sortedItems.slice(-1)[0]}` : ""
  }`;
};

const ProfileRow = ({ name, users, items, id, prices }) => (
  <Div
    bg="white"
    shadow={1}
    bra={1}
    display="row.space-between.center"
    style={{ overflow: "visible" }}
    p={2}
    mb={1}
    height={50}
  >
    <Div display="row.flex-start.center">
      {/* <Div
        height={30}
        width={30}
        bg={isSelected(items) ? "primary7" : "neutral0"}
        bc="neutral2"
        ba={isSelected(items) ? 0 : 2}
        bra={2}
        mr={2}
        display="row.center.center"
      >
        {isSelected(items) && <CheckIcon color="white" size={24} />}
      </Div> */}
      <MediumAvatar bg="neutral3" text={name} singleInitial />
      <Div ml={2}>
        <Text3 color="black" bold>
          {name}
        </Text3>
        {users.length > 0 && (
          <Text1 color="neutral6">{`${users.length} user${addS(
            users.length
          )}`}</Text1>
        )}
      </Div>
    </Div>
    <Div display="row.flex-start.center">
      {items.length < 1 ? (
        <AssignItemsPopOver profileId={id} prices={prices} />
      ) : (
        <Div
          bra={1}
          p={2}
          bc="neutral1"
          bg="neutral1"
          ba={1}
          height={32}
          width={300}
          display="row.flex-start.center"
        >
          <Div flex={1} display="row.center.center">
            <Text1 bold color="primary7">
              {formatItems({ items })}
            </Text1>
          </Div>
          <DownIcon color="black" width={20} />
        </Div>
      )}
      <ProfileSettingsPopOver profileId={id} isSelected={isSelected(items)} />
    </Div>
  </Div>
);

export default ProfileRow;
