import React from "react";
import { put, takeEvery, all, fork, call, select } from "redux-saga/effects";
import { actions } from "./model";
import { registerError } from "redux/modules/errors/actions";
import { getCredentials } from "redux/modules/user/selectors";
import Api from "./api";
import { showModal } from "redux/modules/modal/actions";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import { makeFuture } from "utils/General/sagas";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";
import * as STANDARD_MODULE_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

const refreshData = function*() {
  try {
    const eventId = yield select(getEventId);
    const userId = yield select(getUserId);
    const credentials = yield select(getCredentials);

    const { payload: data } = yield call(Api.getTasks, {
      eventId,
      userId,
      credentials
    });

    yield put(actions.setInitialData({ data }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred loading tasks"
        }
      ])
    );
  }
};

const init = function*() {
  yield put(actions.setLoading(true));
  yield call(refreshData);
  yield put(actions.setLoading(false));
};

const showAddRecordModal = function*() {
  const handleCreate = yield call(makeFuture);
  yield put(
    showModal({
      content: (
        <AddRecordModal
          typeId={STANDARD_MODULES.schedules.id}
          moduleId="master"
          onSave={handleCreate.done}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleCreate.onRealized);
};

const handleShowAddRecordModal = function*() {
  yield call(showAddRecordModal);

  yield call(refreshData);
};

const openSidebar = function*() {};

const toggleTask = function*({ payload: { recordId, toggled } }) {
  try {
    const credentials = yield select(getCredentials);

    yield call(Api.addValues, {
      credentials,
      data: {
        fieldId: STANDARD_MODULE_FIELD_IDS.SCHEDULES.COMPLETE,
        recordId,
        value: {
          type: "boolean",
          value: toggled
        }
      }
    });

    yield call(refreshData);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred saving task"
        }
      ])
    );
  }
};

const watchBackHome = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchAddRecordModal = function*() {
  yield takeEvery(
    actions.handleShowAddRecordModal.type,
    handleShowAddRecordModal
  );
};

const watchOpenSidebar = function*() {
  yield takeEvery(actions.openSidebar.type, openSidebar);
};

const watchToggleTask = function*() {
  yield takeEvery(actions.toggleTask.type, toggleTask);
};

const rootSaga = function*() {
  yield all([
    fork(watchBackHome),
    fork(watchAddRecordModal),
    fork(watchOpenSidebar),
    fork(watchToggleTask)
  ]);
};

export default rootSaga;
