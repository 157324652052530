export const TAB_IDS = {
  DASHBOARD: "dashboard",
  REQUESTS: "requests",
  CATALOG: "catalog",
  ZONES: "zones",
  ZONE_GRID: "zone-grid",
  ACTIONS: "actions",
  APPROVERS: "approvers",
  QUESTIONS: "questions",
  INTEGRATIONS: "integrations",
  INVENTORY: "inventory",
  SEND_EMAILS: "send-emails",
  GUEST_LISTS: "guest-lists",
  DESIGNS: "designs"
};
