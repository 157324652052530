import React from "react";
import { Div } from "components/Base";
import Header from "./Header";
import Body from "./Body";
import ConnectModal from "./ConnectModal";
import MappingModal from "./MappingModal";

const Layout = ({ handlers }) => (
  <Div>
    <ConnectModal />
    <MappingModal />
    <Header />
    <Body handlers={handlers} />
  </Div>
);

export default Layout;
