import * as R from "ramda";

import React, { Component } from "react";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import StatusCircle from "ui-kit/StatusCircle";
import Tooltip from "components/Global/Tooltip";

import { getValue, getSettings } from "../../utils";

const decorate = connect(
  null,
  bindInstance({
    showModal,
    hideModal
  })
);

class HealthPass extends Component {
  render() {
    const status = getValue(this.props, "status", "");
    const settings = getSettings(this.props);
    const isFieldReadOnly = getSettings(this.props, "readOnly", false);

    const statusMap = {
      "not-submitted": {
        status: "not-submitted",
        tooltip: "Enrolled with No Submission"
      },
      approved: {
        status: "approved",
        tooltip: `${settings.latest ? "Latest Submission " : ""}Passed`
      },
      denied: {
        status: "rejected",
        tooltip: `${settings.latest ? "Latest Submission " : ""}Failed`
      },
      expired: {
        status: "pending",
        tooltip: `${settings.latest ? "Latest Submission " : ""}Expired`
      },
      default: {
        status: "not-assigned",
        tooltip: "Not Enrolled"
      }
    };

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: "100%",
          padding: 8,
          borderRadius: 4,
          cursor: isFieldReadOnly ? "default" : "pointer"
        }}
      >
        <Tooltip
          tooltip={R.pathOr(
            statusMap.default.tooltip,
            [status, "tooltip"],
            statusMap
          )}
          placement="bottom"
        >
          <span>
            <StatusCircle
              status={R.pathOr(
                statusMap.default.status,
                [status, "status"],
                statusMap
              )}
            />
          </span>
        </Tooltip>
      </div>
    );
  }
}

export default decorate(HealthPass);
