import React from "react";
import { Div, ListIcon } from "components/Base";
import ItemBlocksTable from "../Tables/ItemBlocks";

const ItemBlocks = ({ blocks, goToItemBlocksDoc, showAddItemBlockModal }) => (
  <Div mb={5}>
    <Div display="row.flex-start.center" mb={3}>
      <Div
        display="column.center.center"
        pill
        bg="gray1"
        width={34}
        height={34}
        mr={2}
      >
        <ListIcon color="gray4" size={23} />
      </Div>
      <Div fs={3} fw={4}>
        Item Blocks
      </Div>
    </Div>
    <Div my={3} bra={1} shadow={1} bg="white">
      {blocks.length ? (
        <ItemBlocksTable blocks={blocks} />
      ) : (
        <Div p={4} color="gray7" fs={2}>
          You haven't added any item blocks.
        </Div>
      )}
      <Div bc="gray1" bt={1} px={4} py={3} display="row.space-between.center">
        <Div onClick={showAddItemBlockModal} fs={2} color="purple8" fw={3}>
          + Add
        </Div>
        <Div onClick={goToItemBlocksDoc} fs={1} underline color="gray6">
          What are item blocks?
        </Div>
      </Div>
    </Div>
  </Div>
);

export default ItemBlocks;
