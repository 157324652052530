import React from "react";
import { connect } from "react-redux";
import {
  Div,
  CollapsablePanelType3,
  MediumFilledButton,
  CancelIcon
} from "components/Base";
import SearchBar from "ui-kit/SearchBar/View";
import * as R from "ramda";
import EmptyState from "components/PortalV2/Home/EmptyState";

import {
  NAMESPACE,
  PEOPLE_FILTERS,
  CONTACTS_TABLE_INSTANCE_ID
} from "Portal/PortalPeople/constants";
import { getters, actions } from "Portal/PortalPeople/model";
import {
  userList,
  currentEventName,
  getShowEmptyState
} from "Portal/PortalPeople/selectors";
import FilterBy from "./FilterBy";
import UsersTable from "./UsersTable";
import Actions from "./Actions";

const decorate = connect(
  (state, props) => ({
    userList: userList(state, props),
    selectedIds: getters.selectedIds(state, props),
    eventId: getters.eventId(state, props),
    currentEventName: currentEventName(state, props),
    filterby: getters.filterby(state, props),
    showEmptyState: getShowEmptyState(state, props)
  }),
  {
    selectFilter: actions.selectFilter,
    selectAllCategory: actions.selectAllCategory
  }
);

const PreviousEvent = ({
  userList,
  selectedIds,
  eventId,
  selectFilter,
  currentEventName,
  filterby,
  selectAllCategory,
  showEmptyState
}) => {
  if (showEmptyState) {
    return (
      <Div>
        <EmptyState title="There are no other events available" />
      </Div>
    );
  }

  return (
    <Div>
      <Div display="row.space-between.center">
        <Div display="row.flex-start.center">
          <FilterBy />
          {eventId && (
            <Div pill bg="neutral2" ml={2} p={1}>
              <MediumFilledButton
                pill
                bg="neutral2"
                color="gray8"
                onClick={() => selectFilter({ type: PEOPLE_FILTERS.ALL })}
                LeftIcon={CancelIcon}
              >
                {currentEventName}
              </MediumFilledButton>
            </Div>
          )}
        </Div>

        <Div ml={2} width={1}>
          <SearchBar
            pill
            filters={[]}
            flex={1}
            placeholder="Search"
            bg="neutral2"
            instanceId={NAMESPACE}
          />
        </Div>
      </Div>

      {R.length(selectedIds) > 0 && (
        <Div mt={3} mb={2} display="row.flex-end.center">
          <Actions />
        </Div>
      )}

      <Div>
        {filterby === PEOPLE_FILTERS.BY_EVENT ? (
          userList.types.map(type => (
            <CollapsablePanelType3
              key={type.id}
              headingText={type.name}
              borderColor="transparent"
              actionButtonText="Select all"
              onActionButtonClick={() => selectAllCategory(type.id)}
              backgroundColor="transparent"
            >
              <UsersTable instanceId={type.id} />
            </CollapsablePanelType3>
          ))
        ) : (
          <Div mt={3}>
            <UsersTable instanceId={CONTACTS_TABLE_INSTANCE_ID} />
          </Div>
        )}
      </Div>
    </Div>
  );
};

export default decorate(PreviousEvent);
