import * as R from "ramda";
import { makeInstanceSelector } from "redux-mvc";
import { createSelector } from "reselect";

import { getters } from "Sponsors/PackageTable";
import { VIEWS } from "Sponsors/PackageTable/constants";

export const getRowManifest = makeInstanceSelector(
  (_, props) => R.path(["item", "id"], props),
  getters.view,
  getters.expanded,
  (itemId, view, expanded) => {
    if (view === VIEWS.CREATE_PACKAGES) {
      if (!R.contains(itemId, expanded)) {
        return [["packageName", "price", "actions"]];
      }
      return [["packageName", "price", "actions"], ["description"]];
    }
    if (view === VIEWS.CREATE_ITEMS) {
      if (!R.contains(itemId, expanded)) {
        return [["itemName", "category", "actions"]];
      }
      return [["itemName", "category", "actions"], ["description"]];
    }
    if (view === VIEWS.APPLICATION) {
      return [["include", "fieldName", "require", "fieldActions"]];
    }
    return [["packageName", "price", "actions"]];
  }
)(R.path(["item", "id"]));

const headers = {
  [VIEWS.CREATE_PACKAGES]: ["packageName", "price", "actions"],
  [VIEWS.CREATE_ITEMS]: ["itemName", "category", "actions"],
  [VIEWS.APPLICATION]: ["include", "fieldName", "require", "fieldActions"]
};

export const getColumns = createSelector(
  (_, props) => R.prop("view", props),
  R.propOr(["itemName", "limit", "price", "actions"], R.__, headers)
);
