import React, { Fragment } from "react";
import {
  BigNumberSelector,
  DataTable,
  Div,
  LoadingIcon,
  SmallCheckbox,
  SuperFilledButton,
  SuperOutlineButton,
  SearchIcon,
  SmallOutlineButton,
  Text2,
  Text3,
  Text4
} from "components/Base";
import { withProps } from "utils/General";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import * as R from "ramda";
import FocusedInput from "Passes/Checkin/IssuanceModal/FocusedInput";

const BulkIssuanceModal = ({
  count,
  totalAvailable,
  setCount,
  onIssueAndFulfill,
  onIssue,
  onFulfill,
  onUndoIssuance,
  onUndoPickup,
  onUndoIssuanceAndPickup,
  status
}) => (
  <Div py={6} px={4} display="column.center.center">
    <Text2 bold uppercase pb={2} color="neutral5" display="row.center.center">
      How many are you{" "}
      {(() => {
        switch (status) {
          case "pending-pickup":
            return "fulfilling";
          case "undo-issuance":
          case "undo-fulfillment":
          case "undo-pickup":
            return "undoing";
          default:
            return "issuing";
        }
      })()}
    </Text2>
    <Div display="row.center.center" pb={6}>
      <BigNumberSelector
        onChangeValue={val =>
          setCount(Math.max(Math.min(val, totalAvailable), 1))
        }
        maxValue={totalAvailable}
        minValue={0}
        value={count}
      />
      <Text4 pl={4}>/ {totalAvailable}</Text4>
    </Div>
    {(() => {
      switch (status) {
        case "pending-pickup": {
          return (
            <Div display="row" width={1}>
              <SuperFilledButton
                onClick={() => onFulfill(count)}
                bg="altB5"
                flex={1}
              >
                Mark as Picked Up
              </SuperFilledButton>
            </Div>
          );
        }
        case "undo-fulfillment": {
          return (
            <Fragment>
              <Div display="row" width={1}>
                <SuperFilledButton
                  onClick={() => onUndoIssuanceAndPickup(count)}
                  bg="altB5"
                  flex={1}
                >
                  Undo Pick-Up & Return Items
                </SuperFilledButton>
              </Div>
              <Div display="row" pt={2} width={1}>
                <SuperOutlineButton
                  onClick={() => onUndoPickup(count)}
                  flex={1}
                >
                  Undo Pick-Up Only
                </SuperOutlineButton>
              </Div>
            </Fragment>
          );
        }
        case "undo-pickup": {
          return (
            <Div display="row" width={1}>
              <SuperFilledButton
                onClick={() => onUndoPickup(count)}
                bg="altB5"
                flex={1}
              >
                Undo Pick-Up
              </SuperFilledButton>
            </Div>
          );
        }
        case "undo-issuance": {
          return (
            <Div display="row" width={1}>
              <SuperFilledButton
                onClick={() => onUndoIssuance(count)}
                bg="altB5"
                flex={1}
              >
                Undo
              </SuperFilledButton>
            </Div>
          );
        }
        default: {
          return (
            <Fragment>
              <Div display="row" width={1}>
                <SuperFilledButton
                  onClick={() => onIssueAndFulfill(count)}
                  bg="altB5"
                  flex={1}
                >
                  Issue & Pick Up
                </SuperFilledButton>
              </Div>
              <Div display="row" pt={2} width={1}>
                <SuperOutlineButton onClick={() => onIssue(count)} flex={1}>
                  Mark as Issued Only
                </SuperOutlineButton>
              </Div>
            </Fragment>
          );
        }
      }
    })()}
  </Div>
);

const Stat = ({ label, count }) => (
  <Div display="column.center.center" ml={3}>
    <Div uppercase fw={3} fs={1} mb={1} color="gray7">
      {label}
    </Div>
    <Div fw={3} color="gray7">
      {count}
    </Div>
  </Div>
);

const BARCODE_TABLE_COLUMNS = [
  {
    Header: <Div pl={4}>Barcode / UID</Div>,
    accessor: "uid",
    sortable: true,
    width: 200,
    Cell: ({ value }) => {
      return <Div pl={4}>{value}</Div>;
    }
  },
  {
    Header: <Div>Time</Div>,
    accessor: "time",
    sortable: true,
    Cell: ({ value }) => {
      return <Div>{value}</Div>;
    }
  },
  {
    Header: <Div>Actions</Div>,
    accessor: "actions",
    sortable: true,
    Cell: ({ original }) => {
      return (
        <SmallOutlineButton onClick={original.onDetach}>
          Detach
        </SmallOutlineButton>
      );
    }
  }
];

const checkBoxField = {
  Header: <Div />,
  accessor: "isSelected",
  sortable: true,
  Cell: ({ value, original }) => {
    return <SmallCheckbox onClick={original.onSelect} selected={value} />;
  },
  width: 50
};

const BarcodeInput = props => (
  <FocusedInput {...props}>
    {({ inputProps }) => (
      <Div p="2" bra="3" b="2" display="row.flex-start.center" bc="primary5">
        <input
          {...inputProps}
          placeholder="scan or type barcode and press enter…"
        />
        <SearchIcon sizeWFS={6} ml={2} />
      </Div>
    )}
  </FocusedInput>
);

const Footer = withProps({
  width: 1,
  px: 4,
  py: 3,
  display: "row.flex-start.center",
  bt: 1,
  bc: "neutral1"
})(Div);
const Table = props => (
  <Div width={1} bg="neutral0">
    {props.rows.length ? <DataTable {...props} /> : ""}
  </Div>
);

const BarcodeIssuanceModal = ({
  onBarcodeFulfill,
  hideModal,
  onUndoBarcodePickup,
  onUndoBarcodeIssuance,
  status,
  itemName,
  itemColor,
  countOfApproved,
  countOfIssued,
  countOfRemaining,
  onScan,
  onDoneIssuing,
  onMarkAllAsPickedUp,
  onPartialPickup,
  onSelectAll,
  tableRows,
  error
}) => (
  <Div display="column.center.center">
    {(() => {
      switch (status) {
        case "pending-pickup": {
          return (
            <Fragment>
              <Div width={1} px={4} py={3}>
                <BarcodeInput onSubmit={onScan} />
                {error ? (
                  <Div
                    bra="3"
                    bg="warning4"
                    p={3}
                    mt={2}
                    display="row.center.center"
                  >
                    {error}
                  </Div>
                ) : null}
              </Div>
              <Table
                columns={[checkBoxField, ...BARCODE_TABLE_COLUMNS]}
                rows={tableRows}
                EmptyState={<Div>Nothing yet</Div>}
              />
              <Footer>
                <Div flex={1}>
                  <Div onClick={onSelectAll}>
                    <Text2 underline bold>
                      Select All
                    </Text2>
                  </Div>
                  <Text3 bold color="primary5">
                    {tableRows.filter(r => r.isSelected).length} of{" "}
                    {tableRows.length} items selected
                  </Text3>
                </Div>
                <SuperOutlineButton mr={2} onClick={hideModal}>
                  Cancel
                </SuperOutlineButton>
                <SuperFilledButton
                  onClick={() => onBarcodeFulfill()}
                  bg="altB5"
                  disabled={!tableRows.filter(r => r.isSelected).length}
                >
                  Mark as Picked Up
                </SuperFilledButton>
              </Footer>
            </Fragment>
          );
        }
        case "undo-fulfillment":
        case "undo-pickup": {
          return (
            <Fragment>
              <Div width={1} px={4} py={3}>
                <BarcodeInput onSubmit={onScan} />
                {error ? (
                  <Div
                    bra="3"
                    bg="warning4"
                    p={3}
                    mt={2}
                    display="row.center.center"
                  >
                    {error}
                  </Div>
                ) : null}
              </Div>
              <Table
                columns={[checkBoxField, ...BARCODE_TABLE_COLUMNS]}
                rows={tableRows}
                EmptyState={<Div>Nothing yet</Div>}
              />
              <Footer>
                <Div flex={1}>
                  <Div onClick={onSelectAll}>
                    <Text2 underline bold>
                      Select All
                    </Text2>
                  </Div>
                  <Text3 bold color="primary5">
                    {tableRows.filter(r => r.isSelected).length} of{" "}
                    {tableRows.length} items selected
                  </Text3>
                </Div>
                <SuperOutlineButton mr={2} onClick={hideModal}>
                  Cancel
                </SuperOutlineButton>
                <SuperFilledButton
                  onClick={() => onUndoBarcodePickup()}
                  bg="altB5"
                  disabled={!tableRows.filter(r => r.isSelected).length}
                >
                  Undo Pick-Up
                </SuperFilledButton>
              </Footer>
            </Fragment>
          );
        }
        case "undo-issuance": {
          return (
            <Fragment>
              <Div width={1} px={4} py={3}>
                <BarcodeInput onSubmit={onScan} />
                {error ? (
                  <Div
                    bra="3"
                    bg="warning4"
                    p={3}
                    mt={2}
                    display="row.center.center"
                  >
                    {error}
                  </Div>
                ) : null}
              </Div>
              <Table
                columns={[checkBoxField, ...BARCODE_TABLE_COLUMNS]}
                rows={tableRows}
                EmptyState={<Div>Nothing yet</Div>}
              />
              <Footer>
                <Div flex={1}>
                  <Div onClick={onSelectAll}>
                    <Text2 underline bold>
                      Select All
                    </Text2>
                  </Div>
                  <Text3 bold color="primary5">
                    {tableRows.filter(r => r.isSelected).length} of{" "}
                    {tableRows.length} items selected
                  </Text3>
                </Div>
                <SuperOutlineButton mr={2} onClick={hideModal}>
                  Cancel
                </SuperOutlineButton>
                <SuperFilledButton
                  onClick={() => onUndoBarcodeIssuance()}
                  bg="altB5"
                  disabled={!tableRows.filter(r => r.isSelected).length}
                >
                  Undo
                </SuperFilledButton>
              </Footer>
            </Fragment>
          );
        }
        default: {
          return (
            <Fragment>
              <Div
                display="row.space-between.center"
                width={1}
                bg="neutral0"
                py={4}
                px={8}
              >
                <Div display="row.flex-start.center">
                  <Div
                    pill
                    style={{
                      backgroundColor: itemColor
                    }}
                    width={10}
                    height={10}
                    mr={2}
                  />
                  <Div fw={3} fs={5} color="gray7">
                    {itemName}
                  </Div>
                </Div>

                <Div display="row">
                  <Stat label="Approved" count={countOfApproved} />
                  <Stat label="Issued" count={countOfIssued} />
                  <Stat label="Remain" count={countOfRemaining} />
                </Div>
              </Div>

              <Div py={4} px={8} width={1}>
                <BarcodeInput onSubmit={onScan} />
              </Div>

              <Table
                columns={BARCODE_TABLE_COLUMNS}
                rows={tableRows}
                EmptyState={<Div>Nothing yet</Div>}
              />

              <Footer>
                <SuperFilledButton
                  disabled={!tableRows.length}
                  onClick={onMarkAllAsPickedUp}
                  bg="altB5"
                >
                  Mark All as Picked Up
                </SuperFilledButton>
                <SuperOutlineButton onClick={onDoneIssuing} ml={2}>
                  Done Issuing
                </SuperOutlineButton>
                <Div flex={1} display="row.flex-end">
                  <SuperOutlineButton
                    disabled={!tableRows.length}
                    onClick={onPartialPickup}
                  >
                    Partial Pickup
                  </SuperOutlineButton>
                </Div>
              </Footer>
            </Fragment>
          );
        }
      }
    })()}
  </Div>
);

const View = props => (
  <StyleWrapper
    width={600}
    bodyStyles={{ padding: 0 }}
    heading={(() => {
      if (props.loading) return "";
      switch (props.status) {
        case "undo-issuance":
        case "undo-fulfillment":
        case "undo-pick":
          return `Undo Issuance for ${props.recordName}`;
        case "pending-pickup":
          return `Picking-Up items for ${props.recordName}`;
        default:
          return `Issuing items to ${props.recordName}`;
      }
    })()}
  >
    {props.loading ? (
      <Div py={20} display="column.center.center">
        <LoadingIcon size={40} />
      </Div>
    ) : (
      R.prop(props.issuanceType, {
        bulk: <BulkIssuanceModal {...props} />,
        barcode: <BarcodeIssuanceModal {...props} />
      })
    )}
  </StyleWrapper>
);

export default View;
