import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Label from "components/Global-2016/Forms/Label";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const currencies = {
  USD: {
    currencyPrefix: "$",
    currencySuffix: "",
    currencyFormat: "0,0.00"
  }
};
export const currencOptions = Object.keys(currencies).reduce((list, k) => {
  list[k] = {
    ...currencies[k],
    name: k
  };
  return list;
}, {});

@CSSModules(css)
class PercentSettings extends Component {
  @autobind
  onChange(e) {
    this.props.onChange(currencOptions[e.target.value]);
  }

  render() {
    const { currencyName } = this.props.value;
    return (
      <div>
        <div className="input-wrapper">
          <Label>Format</Label>
          <select
            key="type"
            styleName="select"
            onChange={this.onChange}
            value={currencyName}
          >
            {Object.keys(currencOptions).map(key => (
              <option value={key} key={key}>
                {key}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}

PercentSettings.propTypes = {
  value: PropTypes.shape({
    currencyName: PropTypes.string.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default PercentSettings;
