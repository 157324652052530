import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import { SidebarWrapper } from "ui-kit/Sidebar/View";
import PassesSidebar from "Catering/Main/View/Sidebar";

import * as R from "ramda";

import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

import CreateOrderModal from "Orders/CreateOrderModal";
import ImportOrdersModal from "Orders/ImportModal/View";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";

import { eventDetails } from "redux/modules/event/selectors";
import { user } from "redux/modules/user/selectors";
import { sourcesByTypeId } from "redux/modules/orders/sources/selectors";
import { applications } from "redux/modules/applications/selectors";
import { recordTypes } from "redux/modules/modules/recordTypes/selectors";
import { canUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";

import { getLinks, getSetupLink } from "../selectors";

const decorate = R.compose(
  connect(
    (state, props) => ({
      eventDetails: eventDetails(state),
      groupRecordTypes: recordTypes(state, STANDARD_MODULES.accounts.id),
      user: user(state),
      sources: sourcesByTypeId(state, CREDENTIAL_TYPE_ID),
      applications: applications(state),
      cando: canUserDo(state, props.params.orgId || props.params.eventId)
    }),
    {
      getRecordTypes
    }
  ),
  withRouter
);

class View extends Component {
  componentDidMount() {
    // get: account record types
    if (!this.props.groupRecordTypes.length) {
      this.props.getRecordTypes({
        moduleId: STANDARD_MODULES.accounts.id,
        options: {
          eventId: this.props.params.eventId
        }
      });
    }
  }

  showCreateOrderModal = () => {
    this.props.showModal({
      content: <CreateOrderModal hideModal={this.props.hideModal} />,
      wrapper: ModalWrapper
    });
  };

  showImportOrdersModal = () => {
    this.props.showModal({
      content: <ImportOrdersModal />,
      wrapper: ModalWrapper
    });
  };

  render() {
    const { children } = this.props;

    return (
      <SidebarWrapper
        instanceId="catering"
        sidebar={
          <PassesSidebar
            links={getLinks({
              ...this.props,
              showCreateOrderModal: this.showCreateOrderModal,
              showImportOrdersModal: this.showImportOrdersModal,
              showNewRoutes: true
            })}
            setupLink={getSetupLink(this.props)}
          />
        }
      >
        {children}
      </SidebarWrapper>
    );
  }
}

export default decorate(View);
