import { createModule } from "redux-mvc";

import model from "./model";

import sagasModule from "./sagas";
import searchBarModule from "ui-kit/SearchBar";
import cartModule from "PortalHub/CreateOrderRequest/Cart";
import formModule from "ui-kit/Form";
import sliderSidebar from "ui-kit/SliderSidebar";

const module = createModule({
  ...model,
  observedDomains: ["user", "event", "modal", "portal"]
});

module.plugModule(searchBarModule);
module.plugModule(cartModule);
module.plugModule(formModule);
module.plugModule(sliderSidebar);

module.setRootSaga(sagasModule);

export default module;
