import { createContext } from "redux-mvc";
import module from "../index";

import { actions } from "../model";
import Layout from "./Layout";

const decorate = createContext({
  module,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(actions.init());
    }
  },
  options: {
    persist: true
  }
});

export default decorate(Layout);
