import React from "react";
import { Div, Text2, AttachIcon } from "components/Base";
import TemplateOptionsPopover from "./TemplateOptionsPopover";

const TemplateDocument = ({
  template,
  handlers: { showDeleteConfirmation }
}) => {
  return (
    <Div
      key={template.id}
      width={199}
      height={240}
      bg="white"
      ml={3}
      mb={3}
      bra={1}
      display="column"
      style={{ position: "relative" }}
      ba={1}
      bc="neutral2"
      className="hoverContainer"
    >
      <Div width={199} height={240} bra={1}>
        <img src={template.image_url} width={197} height={240} />
      </Div>
      <Div
        width={1}
        height={1}
        style={{ position: "absolute", top: 0, left: 0 }}
      >
        <Div
          width={1}
          style={{
            height: "calc(100% - 82px)",
            backgroundColor: "rgba(128,128,128,0.1)"
          }}
          className="showOnHover"
          display="column.flex-end"
          bb={1}
          bc="neutral2"
        />
        <Div
          style={{ position: "absolute", bottom: 0, left: 0 }}
          p={3}
          width={1}
          height={82}
          bg="white"
        >
          <Div display="row.flex-start.center" mb={2} px={1}>
            <AttachIcon mr={1} />
            <Div truncate flex={1}>
              <Text2 color="black" truncate>
                {template.title}
              </Text2>
            </Div>
          </Div>
          <TemplateOptionsPopover
            showDeleteConfirmation={() => showDeleteConfirmation(template.id)}
            templateUrl={template.template_url}
            template={template}
          />
        </Div>
      </Div>
    </Div>
  );
};

export default TemplateDocument;
