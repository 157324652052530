import { bindActionCreators } from "redux";
import Modal from "./Modal";
import { connect } from "react-redux";

import * as modalActions from "redux/modules/modal/actions";
import * as modalSelectors from "redux/modules/modal/selectors";

import * as snackbarActions from "redux/modules/snackbar/actions";

function mapStateToProps(state) {
  return {
    modals: modalSelectors.modals(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        ...modalActions,
        ...snackbarActions
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
