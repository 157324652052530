import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DescriptionHeader from "./DescriptionHeader";

import { toggleEditFieldDescription } from "redux/modules/formsV2/form/fields/actions";
import * as Selectors from "redux/modules/formsV2/form/fields/selectors";

function mapStateToProps(state) {
  return {
    // @NOTE: In order to get editing descripton working, we need to move it into the Submission module
    updatingFieldDescription: false // Selectors.updatingFieldDescription(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleEditFieldDescription
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DescriptionHeader);
