import PropTypes from "prop-types";
import React, { Component } from "react";
import { get } from "lodash";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import getValue from "utils/value-types/get-value";
import toString from "utils/value-types/to-string";
import FileViewer from "components/Global/Modals/FileViewer";
import FileViewerWrapper from "components/Global/Modals/FileViewer/Wrapper";
import Feed from "components/Global/Module/Feed";
import FileCard from "../Shared/FileCard";
import Title from "../Shared/Title";
import Well from "../Shared/Well";
import MenuItem from "material-ui/MenuItem";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import FileUploadEditor from "../PortalView/FileUpload";

import {
  DOCUMENT_REQUEST_TYPES,
  DOCUMENT_REQUEST_REVISIONS
} from "utils/standard-module-field-ids";

const { FILES, REVISION_STATUS } = DOCUMENT_REQUEST_REVISIONS;

const { CRITERIA } = DOCUMENT_REQUEST_TYPES;

@CSSModules(css)
class AdminView extends Component {
  state = { files: [] };

  showFileViewer = (file, files) => {
    this.props.showModal({
      content: (
        <FileViewer start={files.indexOf(file)} files={files} isReadOnly />
      ),
      wrapper: FileViewerWrapper
    });
  };

  getFileCardMenuOptions = (isCurrent, status) => {
    const options = [];
    if (this.props.readOnly) return options;
    if (isCurrent && ["Approved", "Denied"].includes(status)) {
      options.push(
        <MenuItem value="revert-to-pending" primaryText="Revert to pending" />
      );
    }
    if (isCurrent && ["Pending"].includes(status)) {
      options.push(
        <MenuItem value="approve" primaryText="Approve" />,
        <MenuItem value="deny" primaryText="Deny" />
      );
    }
    return options;
  };

  renderRevision = (revision, showStatus, isCurrent) => {
    const { onRevisionMenuChange } = this.props;
    const files = getValue(get(revision.values, [FILES]), "file");
    const status = toString(
      getValue(get(revision.values, [REVISION_STATUS]), "dropdown"),
      "dropdown"
    );
    return (
      <FileCard
        files={files}
        showFileViewer={this.showFileViewer}
        showStatus={showStatus}
        status={status}
        isCurrent={isCurrent}
        onRevisionMenuChange={onRevisionMenuChange}
        menuOptions={this.getFileCardMenuOptions(isCurrent, status)}
      />
    );
  };

  handleFileUpload = ({ value: { files } }) => {
    this.props.markAsModified();
    this.setState({ files });
  };

  handleClearFiles = () => {
    this.props.markAsModified();
    this.setState({ files: [] });
  };

  createRevision = () => {
    this.props.markAsModified();
    this.props
      .addRecord({
        moduleId: STANDARD_MODULE_IDS.documentRequestRevisions.id,
        record: {
          requestRecordId: this.props.record.id,
          [FILES]: {
            type: "file",
            value: {
              files: this.state.files
            }
          },
          [REVISION_STATUS]: {
            type: "dropdown",
            value: { records: [{ value: "Pending" }] }
          }
        },
        options: {
          eventId: this.props.eventDetails.id
        }
      })
      .then(() => {
        this.handleClearFiles();
        this.props.fetchRecord();
      });
  };

  renderCurrentState = () => {
    const { latestRevision, status, readOnly } = this.props;

    if (status === "Not Submitted" || !this.props.revisions.length) {
      return (
        <Well>
          <Title style={{ paddingBottom: 0 }}>
            No files have been submitted yet
          </Title>
        </Well>
      );
    }

    if (status === "Denied") {
      return (
        <Well>
          <Title className={css.titleDenied}>
            <i className={["material-icons", css.titleIcon].join(" ")}>close</i>
            <span>Denied</span>
          </Title>
          {this.renderRevision(latestRevision, false, true)}
        </Well>
      );
    }

    if (status === "Approved") {
      return (
        <Well>
          <Title className={css.titleApproved}>
            <i className={["material-icons", css.titleIcon].join(" ")}>check</i>
            <span>Approved</span>
          </Title>
          {this.renderRevision(latestRevision, false, true)}
        </Well>
      );
    }

    return (
      <Well>
        <Title>Waiting on review</Title>
        {this.renderRevision(latestRevision, false, true)}
        {!readOnly ? (
          <div styleName="buttonGroup">
            <div
              styleName="buttonConfirm"
              onClick={() => this.props.updateRevisionStatus("Approved")}
            >
              <i className="material-icons">check</i>
            </div>
            <div
              styleName="buttonReject"
              onClick={() => this.props.updateRevisionStatus("Denied")}
            >
              <i className="material-icons">close</i>
            </div>
          </div>
        ) : null}
      </Well>
    );
  };

  render() {
    const { eventDetails, record, requestType, revisions, status } = this.props;

    const criteria = getValue(get(requestType.values, [CRITERIA]), "text");

    return (
      <div styleName="container">
        <div styleName="body">
          {this.renderCurrentState()}

          <Well>
            {status !== "Approved" ? (
              <span>
                <div styleName="heading">Files</div>
                <FileUploadEditor
                  allowDownload
                  value={{ value: { files: this.state.files } }}
                  placeholder="Add files to create a new revision"
                  onChange={this.handleFileUpload}
                  maxSize={26214400} // 25MB
                />
                {this.state.files.length ? (
                  <div styleName="buttonGroup">
                    <div styleName="buttonSave" onClick={this.createRevision}>
                      Submit
                    </div>
                    <div styleName="button" onClick={this.handleClearFiles}>
                      Cancel
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </span>
            ) : null}
            {status === "Not Submitted" || !revisions.length ? null : (
              <div styleName="section">
                <Title>Version History</Title>
                {revisions.map((r, idx) =>
                  this.renderRevision(r, true, idx === 0)
                )}
              </div>
            )}
          </Well>

          <div styleName="card">
            <Title>Criteria</Title>
            <div>
              {criteria && criteria.length ? criteria : "No criteria specified"}
            </div>
          </div>
        </div>
        <Feed
          eventId={eventDetails.id}
          moduleId={STANDARD_MODULE_IDS.documentRequests.id}
          recordId={record.id}
          whitelist={["messages"]}
        />
      </div>
    );
  }
}

AdminView.propTypes = {
  eventDetails: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired,
  revisions: PropTypes.array.isRequired,
  latestRevision: PropTypes.object,
  requestType: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  updateRevisionStatus: PropTypes.func.isRequired,
  onRevisionMenuChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired
};

export default AdminView;
