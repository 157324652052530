import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "Forms/WizardModal";
import FieldsModal from "Forms/WizardModal/View/Common/FieldsModal";

const decorate = connect(
  state => ({
    fieldsList: getters.modalDataList(state),
    collectFieldsGroupName: getters.modalDataId(state),
    moduleId: getters.modalDataId(state)
  }),
  {
    hideModal: actions.hidePassesModal,
    toggleEnabled: actions.toggleEnabledPassesField,
    disableField: actions.disablePassesField,
    enableAll: () => actions.enableAllModalFields(),
    disableAll: () => actions.disableAllModalFields(),
    setSearchFilter: actions.setSearchFilter
  }
);

const PassesFieldsModal = ({
  hideModal,
  fieldsList,
  handlers: { showCustomAddFieldModal: showAddFieldModal },
  toggleEnabled,
  disableField,
  enableAll,
  disableAll,
  setSearchFilter,
  moduleId
}) => (
  <FieldsModal
    hideModal={hideModal}
    fieldsList={fieldsList}
    toggleEnabled={toggleEnabled}
    disableField={disableField}
    enableAll={enableAll}
    disableAll={disableAll}
    setSearchFilter={setSearchFilter}
    showAddFieldModal={() => showAddFieldModal({ moduleId, source: "passes" })}
    moduleId={moduleId}
  />
);

export default decorate(PassesFieldsModal);
