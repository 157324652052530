import { REQUEST, RECEIVE, ERROR, CLEAR_ERROR, INVALIDATE } from "./constants";
import { addReferences } from "redux/modules/entityReferences/actions";
import * as selectors from "./selectors";
import api from "./api";

export function getAccounts(data) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const result = await api.get(data);
      dispatch(addReferences(result.result.references));
      dispatch({ type: RECEIVE, payload: { result } });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function addAccount(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post(data);
      return result;
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function invalidate() {
  return {
    type: INVALIDATE
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR
  };
}
