import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";
import uuid from "node-uuid";

import { actions } from "EventLight/Expo/Sales/model";
import { getItems } from "EventLight/Expo/Sales/selectors";
import { VIEWS } from "Sponsors/PackageTable/constants";

import { AddCircleIcon, BigOutlineButton } from "components/Base";

import { Row } from "ui-kit/Form/View";
import PackageTable from "Sponsors/PackageTable/View";

import { getters as tabGetters } from "ui-kit/Tabs";
import { WithThemeConsumer, VARIANTS } from "ui-kit/Theme";

const decorate = R.compose(
  WithThemeConsumer({ variant: VARIANTS.SURFACE }),
  connect(
    (state, props) => ({
      selectedTab: tabGetters.selectedTab(state, props),
      items: getItems(state, props)
    }),
    {
      addItem: ({ typeId }) =>
        actions.addItemRequest({ id: uuid.v4(), type: typeId }),
      deleteItem: id => actions.deleteItemRequest(id),
      showAddCategoryModal: () => actions.setAddingCategory(true)
    }
  )
);
const Items = ({
  items = {},
  addItem,
  selectedTab,
  deleteItem,
  showAddCategoryModal
}) => (
  <>
    <PackageTable
      view={VIEWS.CREATE_ITEMS}
      items={items}
      handlers={{
        onDelete: deleteItem
      }}
    />
    <Row width={1} mt={2}>
      <BigOutlineButton
        LeftIcon={AddCircleIcon}
        onClick={() => addItem({ typeId: selectedTab })}
      >
        Add Item
      </BigOutlineButton>
      {/* NOTE: Add multiple button */}
      <BigOutlineButton
        ml={1}
        LeftIcon={AddCircleIcon}
        onClick={showAddCategoryModal}
      >
        Create New Category
      </BigOutlineButton>
      {/* NOTE: Add copy from  button */}
    </Row>
  </>
);

export default decorate(Items);
