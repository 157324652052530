import { connect } from "react-redux";
import * as R from "ramda";

import { actions } from "Modules/ImportModal";

import ImportCopyButtons from "ui-kit/ImportCopyButtons";

const decorate = connect(
  R.always({
    showCopy: false,
    showImport: true,
    showDownload: true
  }),
  {
    downloadTemplate: () => actions.downloadUrl(),
    uploadFile: () => actions.uploadFile()
  }
);

export default decorate(ImportCopyButtons);
