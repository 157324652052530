import React from "react";
import * as R from "ramda";

import { actions, getters } from "../model";
import { getIsValid } from "../selectors";
import { FIELD_IDS, FORM_ID } from "../constants";
import { connect } from "react-redux";
import { Label, Divider } from "EventLight/Common/FieldLayout";

import {
  WithFormInstanceProvider,
  Input,
  Image,
  CheckboxInput,
  TextAreaInput
} from "ui-kit/Form/View";

import { Div, BigFilledButton } from "components/Base";
import Helpers from "utils/Global/Helpers";

import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import Loading from "ui-kit/Loading";

import {
  eventId as getEventId,
  isLiveOrHybridEvent
} from "redux/modules/event/selectors";

const decorate = R.compose(
  WithFormInstanceProvider(R.always(FORM_ID)),
  connect(
    state => ({
      data: getters.data(state),
      showModal: getters.showContentModal(state),
      loading: getters.loading(state),
      isValid: getIsValid(state),
      eventId: getEventId(state),
      isLiveOrHybridEvent: isLiveOrHybridEvent(state)
    }),
    {
      hideModal: () => actions.closeContentModal(false),
      save: actions.save
    }
  )
);

const WithModal = ({
  showModal,
  hideModal,
  data,
  isValid,
  save,
  loading,
  isLiveOrHybridEvent
}) => {
  const isEditing = Boolean(data.id);
  const isImage = data.mimetype ? Helpers.isImage(data.mimetype) : false;
  const hideUpload = isEditing || data.uploaded;

  return (
    <MiniModalWrapper
      showModal={showModal}
      hideModal={hideModal}
      title={isEditing ? "Update Content" : "Add Content"}
    >
      {loading ? (
        <Div bg="white" p={8}>
          <Loading />
        </Div>
      ) : (
        <Div
          bg="white"
          p={8}
          style={{
            overflowY: "auto"
          }}
        >
          <Div
            style={{
              marginBottom: 20,
              cursor: "pointer",
              display: hideUpload ? "none" : "inherit"
            }}
            id="dropzone-container"
          ></Div>

          <Div
            display="row.flex-start.center"
            style={{
              marginBottom: 20,
              display: hideUpload ? "none" : "flex"
            }}
          >
            <Input
              fieldId={FIELD_IDS.URL}
              width={1}
              iniValue={R.prop(FIELD_IDS.URL)(data)}
              size="big"
              inputType="outline"
              placeholder="Or link to an external file (e.g. YouTube, Google Doc) by pasting the URL here"
            />
          </Div>
          <Divider
            style={{
              display: hideUpload ? "none" : "inherit"
            }}
          />

          {isEditing || data.uploaded ? (
            <Div
              display="column"
              mb={5}
              width={1}
              style={{
                wordBreak: "break-all"
              }}
            >
              <Label>URL</Label>
              {data.uploaded || (isEditing && !data.is_external) ? (
                data.url
              ) : (
                <Input
                  fieldId={FIELD_IDS.URL}
                  width={1}
                  iniValue={R.prop(FIELD_IDS.URL)(data)}
                  size="big"
                  inputType="outline"
                />
              )}
            </Div>
          ) : null}

          <Div display="column" mb={5} width={1}>
            <Label>Title</Label>
            <Input
              fieldId={FIELD_IDS.TITLE}
              width={1}
              iniValue={R.prop(FIELD_IDS.TITLE)(data)}
              size="big"
              inputType="outline"
            />
          </Div>

          <Div display="column" mb={5} width={1}>
            <Label>Description</Label>
            <TextAreaInput
              fieldId={FIELD_IDS.DESCRIPTION}
              width={1}
              iniValue={R.prop(FIELD_IDS.DESCRIPTION)(data)}
              size="big"
              inputType="outline"
            />
          </Div>

          {/*
        <Div display="column" mb={5} width={1}>
          <Label>Label</Label>
          <Input
            fieldId={FIELD_IDS.LABEL}
            width={1}
            iniValue={R.prop(FIELD_IDS.LABEL)(data)}
            size="big"
            inputType="outline"
          />
        </Div>
          */}

          {!isImage ? (
            <Div display="column" mb={5} width={1}>
              <Label>Thumbnail Image</Label>
              <Image
                fieldId={FIELD_IDS.THUMBNAIL_URL}
                containerWidth="auto"
                containerProps={{
                  display: "row.flex-start.flex-start",
                  flex: 1
                }}
                imageWidth={123}
                imageHeight={70}
                recommendedWidth={"1280"}
                recommendedHeight={"720"}
                iniValue={
                  R.prop(FIELD_IDS.THUMBNAIL_URL, data)
                    ? [
                        {
                          url: R.prop(FIELD_IDS.THUMBNAIL_URL, data)
                        }
                      ]
                    : null
                }
              />
            </Div>
          ) : null}

          <Div display="column" mb={5} width={1}>
            <Label tooltip="If checked, this file will be visible in the Content section of your event">
              Visible
            </Label>
            <CheckboxInput
              fieldId={FIELD_IDS.VISIBLE}
              iniValue={R.prop(FIELD_IDS.VISIBLE)(data)}
            />
          </Div>

          <Div display="column" mb={5} width={1}>
            <Label>Order</Label>
            <Input
              fieldId={FIELD_IDS.ORDER}
              width={"100px"}
              iniValue={R.prop(FIELD_IDS.ORDER)(data)}
              size="big"
              type="number"
              inputType="outline"
            />
          </Div>

          {isLiveOrHybridEvent && data.created_at_formatted ? (
            <Div display="column" mb={5} width={1}>
              <Label>Content added at:</Label>
              <Div fs={2}>{data.created_at_formatted}</Div>
            </Div>
          ) : null}

          <Divider />

          <BigFilledButton
            bg="altB5"
            color="white"
            disabled={!isValid}
            onClick={save}
          >
            {isEditing ? "Update Content" : "Add Content"}
          </BigFilledButton>
        </Div>
      )}
    </MiniModalWrapper>
  );
};

export default decorate(WithModal);
