import { get } from "lodash";

export default function(value, details) {
  return value.records
    .map(
      id =>
        get(details, "module_settings.catering.dietary_restrictions", []).find(
          r => r.id === id
        ).value
    )
    .join(", ");
}
