import {
  put,
  all,
  take,
  takeEvery,
  fork,
  select,
  call
} from "redux-saga/effects";

import * as STANDARD_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

import * as R from "ramda";
import moment from "moment";

import { getters, actions } from "./model";
import { actions as sliderSidebarActions } from "ui-kit/SliderSidebar/model";
import { actions as ActionsPopoverActions } from "ui-kit/ActionsPopover/model";

import { registerError } from "redux/modules/errors/actions";
import * as snackbarActions from "redux/modules/snackbar/actions";

import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { fieldIds, getFieldValueById, getEditedField } from "./selectors";

import { setLocalZone } from "utils/General";

import Api from "./api";
import {
  ACTIVITY_NAME,
  SIDEBAR_INSTANCE_ID,
  ACTIVITY_DETAILS_FORM_ID,
  SCHEDULING_EXTRA_FIELD_IDS,
  DATE_TIME_FIELD_MATCH,
  DATE_TIME_FORMAT,
  DATE_FORMAT,
  TIME_FORMAT,
  ACTIVITY_STARTS_AT_ID,
  ACTIVITY_ENDS_AT_ID,
  CORRESPONDING_FIELD_ID,
  ACTIONS
} from "./constants";

import { getResult } from "ui-kit/FieldTypes/utils";

const dateOptionFields = [
  SCHEDULING_EXTRA_FIELD_IDS.STARTS_AT_DATE,
  SCHEDULING_EXTRA_FIELD_IDS.STARTS_AT_TIME,
  SCHEDULING_EXTRA_FIELD_IDS.ENDS_AT_DATE,
  SCHEDULING_EXTRA_FIELD_IDS.ENDS_AT_TIME
];

const getFormattedField = function*({ fieldId, value }) {
  if (fieldId === ACTIVITY_NAME) {
    return {
      fieldId,
      value: {
        type: "text",
        value
      }
    };
  }
  return { fieldId, value };
};

const extractDateAndTime = function*({ fieldId, fields, value }) {
  const dateTimeIso = R.pathOr(null, ["value", "iso"], value);
  const fieldSettings = R.prop(
    "settings",
    R.find(R.propEq("id", fieldId))(fields)
  );
  const dateTimeValue = fieldSettings.timezone
    ? moment(dateTimeIso).tz(fieldSettings.timezone)
    : dateTimeIso;
  return {
    date: moment(dateTimeValue).format(DATE_FORMAT),
    time: moment(dateTimeValue).format(TIME_FORMAT)
  };
};

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  return {
    credentials,
    eventId
  };
};

const openAndGetActivity = function*({
  payload: { activityId = "", scheduleId }
}) {
  yield put(actions.setLoading(true));
  yield put(
    sliderSidebarActions.setIsDrawerOpen(true, {
      meta: { instanceId: SIDEBAR_INSTANCE_ID }
    })
  );
  const { credentials, eventId } = yield call(getParams);
  try {
    // eslint-disable-next-line no-unused-vars
    const {
      payload: { fields, values, history, ...activity }
    } = yield call(Api.getActivity, {
      credentials,
      eventId,
      scheduleId,
      activityId
    });
    const { date: startsAtDate, time: startsAtTime } = yield call(
      extractDateAndTime,
      {
        fieldId: ACTIVITY_STARTS_AT_ID,
        fields,
        value: R.prop(ACTIVITY_STARTS_AT_ID, values)
      }
    );
    const { date: endsAtDate, time: endsAtTime } = yield call(
      extractDateAndTime,
      {
        fieldId: ACTIVITY_ENDS_AT_ID,
        fields,
        value: R.prop(ACTIVITY_ENDS_AT_ID, values)
      }
    );
    yield put(
      actions.setInitialData({
        activity,
        fields,
        values,
        history,
        activityId,
        scheduleId,
        startsAtDate,
        startsAtTime,
        endsAtDate,
        endsAtTime
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while fetching activity"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const checkIsNewValue = function*({ newValue, fieldId, type }) {
  const oldFieldValue = yield select(getFieldValueById, {
    fieldId
  });
  const oldValue = R.propOr(oldFieldValue, type, {
    datetime: R.prop("iso", oldFieldValue)
  });
  const value = R.propOr(newValue, type, {
    datetime: R.prop("iso", newValue)
  });
  return oldValue !== value;
};

const updateDateOptionFields = function*({ fieldId, value }) {
  const fields = yield select(getters.fields);
  const { dateFieldId, timeFieldId } = R.prop(fieldId, {
    [ACTIVITY_STARTS_AT_ID]: {
      dateFieldId: SCHEDULING_EXTRA_FIELD_IDS.STARTS_AT_DATE,
      timeFieldId: SCHEDULING_EXTRA_FIELD_IDS.STARTS_AT_TIME
    },
    [ACTIVITY_ENDS_AT_ID]: {
      dateFieldId: SCHEDULING_EXTRA_FIELD_IDS.ENDS_AT_DATE,
      timeFieldId: SCHEDULING_EXTRA_FIELD_IDS.ENDS_AT_TIME
    }
  });
  const { date, time } = yield call(extractDateAndTime, {
    fieldId,
    fields,
    value
  });
  yield put(actions.updateOptionsField({ fieldId: dateFieldId, value: date }));
  yield put(actions.updateOptionsField({ fieldId: timeFieldId, value: time }));
};

const saveUpdatedValue = function*({ fieldId, value }) {
  const activityFields = yield select(fieldIds);
  const activityId = yield select(getters.activityId);
  const fields = yield select(getters.fieldsById);

  let valueToSave = null;
  let fieldIdToSave = fieldId;
  if (R.contains(fieldId, activityFields)) {
    const fieldValues = yield select(getters.values);
    const fieldSettings = R.prop(fieldId, fields);
    const formattedValue = getResult({
      field: fieldSettings,
      data: fieldValues,
      newField: { fieldId, value }
    });

    if (fieldId === STANDARD_FIELD_IDS.SCHEDULES.STARTS_AT) {
      const endValue = fieldValues[STANDARD_FIELD_IDS.SCHEDULES.ENDS_AT];
      if (moment(new Date(formattedValue.iso)).isAfter(endValue.value.iso)) {
        const newEndTime = new Date(formattedValue.iso);
        newEndTime.setHours(newEndTime.getHours() + 1);
        yield call(saveUpdatedValue, {
          fieldId: STANDARD_FIELD_IDS.SCHEDULES.ENDS_AT,
          value: setLocalZone(newEndTime, fieldSettings.settings.timezone)
        });
      }
    } else if (fieldId === STANDARD_FIELD_IDS.SCHEDULES.ENDS_AT) {
      const startValue = fieldValues[STANDARD_FIELD_IDS.SCHEDULES.STARTS_AT];
      if (moment(new Date(formattedValue.iso)).isBefore(startValue.value.iso)) {
        const newStartTime = new Date(formattedValue.iso);
        newStartTime.setHours(newStartTime.getHours() - 1);
        yield call(saveUpdatedValue, {
          fieldId: STANDARD_FIELD_IDS.SCHEDULES.STARTS_AT,
          value: setLocalZone(newStartTime, fieldSettings.settings.timezone)
        });
      }
    }

    const isNewValue = yield call(checkIsNewValue, {
      newValue: formattedValue,
      fieldId,
      type: R.propOr(null, "type", fieldValues[fieldId])
    });
    if (isNewValue) {
      valueToSave = {
        type: R.propOr(null, "type", fieldValues[fieldId]),
        value: formattedValue
      };
    } else {
      yield put(actions.edit(null));
    }
  } else {
    // eslint-disable-next-line no-unused-vars
    const { fieldId: fieldToSave, value: formattedValueToSave } = yield call(
      getFormattedField,
      { fieldId, value }
    );
    fieldIdToSave = fieldToSave;
    valueToSave = formattedValueToSave;
  }
  if (valueToSave) {
    yield put(actions.updateValue({ fieldId, value: valueToSave }));
    if ([ACTIVITY_STARTS_AT_ID, ACTIVITY_ENDS_AT_ID].includes(fieldId)) {
      yield fork(updateDateOptionFields, {
        fieldId,
        value: valueToSave
      });
    }
    try {
      const { credentials } = yield call(getParams);
      yield call(Api.updateFieldValues, {
        credentials,
        data: {
          fieldId: fieldIdToSave,
          recordId: activityId,
          value: valueToSave
        }
      });
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error occurred saving fields of activity"
          }
        ])
      );
    }
    yield put(actions.markAsEdited());
  }
};

const updateStartEndDates = function*({ fieldId: optionsFieldId, value }) {
  const matchedField = DATE_TIME_FIELD_MATCH[optionsFieldId];
  const matchedFieldValue = yield select(getters[matchedField]);

  const fullDateTime = moment(
    `${
      optionsFieldId.includes("Date")
        ? moment(value).format(DATE_FORMAT)
        : moment(value).format(TIME_FORMAT)
    } ${matchedFieldValue}`,
    `${DATE_TIME_FORMAT[optionsFieldId]} ${DATE_TIME_FORMAT[matchedField]}`
  );

  yield fork(saveUpdatedValue, {
    fieldId: CORRESPONDING_FIELD_ID[optionsFieldId],
    value: fullDateTime.toISOString()
  });
};

const followFieldUpdate = function*({ fieldId }) {
  const newValue = yield select(getEditedField, {
    instanceId: ACTIVITY_DETAILS_FORM_ID,
    fieldId
  });
  let delegateFn = saveUpdatedValue;
  if (dateOptionFields.includes(fieldId)) {
    delegateFn = updateStartEndDates;
  }
  yield fork(delegateFn, { fieldId, value: newValue });
};

const closeAndRefresh = function*() {
  const isActivityEdited = yield select(getters.activityEdited);
  if (isActivityEdited) {
    yield put(actions.refreshData());
  }
  yield put(
    sliderSidebarActions.setIsDrawerOpen(false, {
      meta: { instanceId: SIDEBAR_INSTANCE_ID }
    })
  );
};

const watchOnSavePopoverEditor = function*() {
  for (;;) {
    const {
      payload: { fieldId }
    } = yield take(actions.onSavePopoverEditor.type);
    yield call(followFieldUpdate, {
      fieldId
    });
  }
};

const deleteActivity = function*() {
  const activityId = yield select(getters.activityId);
  if (!R.isEmpty(activityId)) {
    try {
      const { credentials } = yield call(getParams);
      const scheduleId = yield select(getters.scheduleId);

      yield call(Api.deleteActivity, credentials, {
        moduleId: scheduleId,
        record: {
          id: activityId
        }
      });

      yield put(
        snackbarActions.showSnackbar({
          message: "Activity Deleted"
        })
      );

      yield put(actions.setLoading(true));
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error occurred while deleting activity"
          }
        ])
      );
    } finally {
      yield put(actions.setLoading(false));
      yield put(
        sliderSidebarActions.setIsDrawerOpen(false, {
          meta: { instanceId: SIDEBAR_INSTANCE_ID }
        })
      );
      yield put(actions.refreshData());
    }
  }
};

const cloneActivity = function*() {
  // PENDING: call API with this ActivityId, and then call openActivity(newActivityId, ScheduleId)
};

const saveCompletedTask = function*({ payload: isCompleted }) {
  yield fork(saveUpdatedValue, {
    fieldId: SCHEDULING_EXTRA_FIELD_IDS.IS_COMPLETED,
    value: {
      type: "boolean",
      value: isCompleted
    }
  });
};

const watchMarkCompleted = function*() {
  yield takeEvery(actions.markCompleted.type, saveCompletedTask);
};

const watchExecuteAction = function*() {
  for (;;) {
    const action = yield take(ActionsPopoverActions.executeAction.type);
    const delegate = R.prop(action.payload.actionId, {
      [ACTIONS.DELETE]: deleteActivity,
      [ACTIONS.CLONE]: cloneActivity
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const watchOnCloseEditor = function*() {
  for (;;) {
    const action = yield take([actions.closeEditor.type, actions.onEdit.type]);
    const fieldId = yield select(getters.editingFieldId);
    if (fieldId) {
      yield call(followFieldUpdate, {
        fieldId
      });
    }
    if (action.type === actions.onEdit.type) {
      yield put(actions.edit(action.payload));
    }
  }
};

const watchCloseSidebar = function*() {
  yield takeEvery(
    [actions.closeSidebar.type, sliderSidebarActions.close.type],
    closeAndRefresh
  );
};

const watchOpenSidebar = function*() {
  yield takeEvery(actions.openActivity.type, openAndGetActivity);
};

const rootSaga = function*() {
  yield all([
    fork(watchOpenSidebar),
    fork(watchOnSavePopoverEditor),
    fork(watchOnCloseEditor),
    fork(watchCloseSidebar),
    fork(watchExecuteAction),
    fork(watchMarkCompleted)
  ]);
};

export default rootSaga;
