import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "Orders/OrderModal";
import { actions as submissionActions } from "Submission/Editor";

import {
  getOrderId,
  getStatuses,
  getIntegrationStatuses,
  getLineItemIds,
  getPendingLineItemIds
} from "Orders/OrderModal/selectors";

import * as selectors from "Submission/Editor/selectors";

import {
  Div,
  Text4,
  DownFilledIcon,
  AddIcon,
  BigOutlineButton,
  BigFilledButton,
  RightIcon,
  MenuItem,
  PopMenu,
  PopMenuCard,
  LockIcon,
  LockOpenIcon,
  DeleteIcon,
  EditIcon
} from "components/Base";

import { withProps } from "utils/General";

import ApproversLabel from "ApproversLabel/View";

import Statuses from "ui-kit/Statuses";
import ArrayLayout from "ui-kit/ArrayLayout";

const BulkMenuItem = withProps({
  py: 1,
  bb: 1,
  bc: "neutral3",
  width: 1
})(MenuItem);

const decorate = connect(
  state => ({
    orderId: getOrderId(state),
    orderStatuses: getStatuses(state),
    orderIntegrationStatuses: getIntegrationStatuses(state),
    lineItemIds: getLineItemIds(state),
    pendingLineItemIds: getPendingLineItemIds(state),
    submissionStatuses: selectors.getStatuses(state),
    isLocked: selectors.isLocked(state),
    submissionId: selectors.getSubmissionId(state),
    submissionApprovalStatus: selectors.getApprovalStatus(state),
    submissionApprovalManifestId: selectors.getApprovalManifestId(state),
    submissionRecordId: selectors.getSubmissionRecordId(state),
    hasRelatedOrder: selectors.hasRelatedOrder(state),
    isPortalUser: getters.isPortalUser(state),
    restricted: getters.isPortalUser(state)
  }),
  {
    approveRemaining: actions.onApprove,
    denyRemaining: actions.onDeny,
    updateSubmission: submissionActions.updateSubmission,
    goToSubmission: actions.viewSubmission
  }
);

const SubmissionStatusbar = ({
  orderId,
  submissionStatuses = [],
  orderStatuses = [],
  orderIntegrationStatuses = [],
  isPortalUser = false,
  restricted = false,
  handlers,
  isLocked,
  submissionId,
  updateSubmission,
  submissionApprovalStatus,
  submissionApprovalManifestId,
  submissionRecordId,
  goToSubmission,
  hasRelatedOrder
}) => {
  const header = [];

  header.push([
    <Div display="column">
      <Text4 bold mr={3}>
        Status:
      </Text4>
      <Statuses display="row" statuses={submissionStatuses} />
    </Div>,
    null
  ]);

  if (hasRelatedOrder) {
    header.push([
      <Div display="column">
        <Text4 bold mr={3}>
          Order Status:
        </Text4>
        <Statuses
          display="row"
          statuses={orderStatuses}
          integrationStatuses={orderIntegrationStatuses}
        />
      </Div>,
      null
    ]);
  }

  if (!restricted) {
    header.push([
      <ApproversLabel
        key="label"
        {...{
          // data
          type: "record",
          status: submissionApprovalStatus,
          manifestId: submissionApprovalManifestId,
          recordIds: [submissionRecordId],
          // handlers
          onEdit: () => {},
          handlers,
          // style
          containerProps: {
            display: "row.flex-start.center"
          }
        }}
      />,
      <PopMenu
        key="actions"
        width={1}
        Label={({ onClick }) => (
          <BigOutlineButton
            onClick={onClick}
            RightIcon={DownFilledIcon}
            key="reviewOrder"
            ml={2}
          >
            Actions
          </BigOutlineButton>
        )}
      >
        {({ closeMenu }) => (
          <PopMenuCard position="bottom" align="right">
            <BulkMenuItem
              key="lock"
              LeftIcon={isLocked ? LockOpenIcon : LockIcon}
              onClick={() => {
                handlers.flagAsModified();
                updateSubmission({ id: submissionId, isLocked: !isLocked });
                closeMenu();
              }}
            >
              {isLocked ? "Unlock Submission" : "Lock Submission"}
            </BulkMenuItem>
            <BulkMenuItem
              key="delete"
              LeftIcon={DeleteIcon}
              onClick={() => {
                handlers.deleteSubmission(submissionId);
                closeMenu();
              }}
            >
              Delete Submission
            </BulkMenuItem>

            {hasRelatedOrder ? (
              <BulkMenuItem
                key="add-items"
                LeftIcon={AddIcon}
                onClick={() => {
                  handlers.addItems(orderId);
                  closeMenu();
                }}
              >
                Add items to order
              </BulkMenuItem>
            ) : null}
            {hasRelatedOrder ? (
              <BulkMenuItem
                key="bulk-edit"
                LeftIcon={EditIcon}
                onClick={() => {
                  handlers.bulkEdit({ orderId });
                  closeMenu();
                }}
              >
                Bulk edit order
              </BulkMenuItem>
            ) : null}
          </PopMenuCard>
        )}
      </PopMenu>
    ]);
  }

  if (isPortalUser) {
    header.push(
      <BigFilledButton
        onClick={() => goToSubmission(submissionId)}
        RightIcon={RightIcon}
        key="goToSubmission"
        bg="altB5"
        ml={2}
      >
        Go to Submission
      </BigFilledButton>
    );
  }

  return (
    <Div
      bg="white"
      bc="neutral3"
      bb={1}
      bl={1}
      br={1}
      bt={1}
      style={{ overflow: "visible" }}
    >
      <ArrayLayout components={header} py={3} px={8} />
    </Div>
  );
};

export default decorate(SubmissionStatusbar);
