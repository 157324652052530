import can from "components/Global/Security";

export default (userPermissionProfile, modules, hideSettings) => {
  const canDo = can(userPermissionProfile).do;
  const visibleModules = modules.filter(
    m =>
      (m.source && canDo(`${m.id}_read`)) ||
      // whitelist portal
      m.internal_name === "portal"
  );

  return [
    ...visibleModules,
    ...modules.filter(
      m => m.internal_name === "settings" && canDo(`${m.id}_read`)
    )
  ];
};
