import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Tooltip from "components/Global/CRM/Tooltip";

const ToggleLabelWithTooltip = ({ children, tooltip }) => {
  if (tooltip.length) {
    return (
      <div styleName="container">
        <div styleName="label">{children}</div>
        <Tooltip message={tooltip} />
      </div>
    );
  }
  return (
    <div styleName="container">
      <div styleName="label">{children}</div>
    </div>
  );
};

ToggleLabelWithTooltip.propTypes = {
  tooltip: PropTypes.string
};

export default CSSModules(ToggleLabelWithTooltip, css);
