import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { getters } from "../model";

import { Div } from "components/Base";

import FieldResolver from "./FieldResolver";

const decorate = connect((state, props) => ({
  fields: getters.fields(state, props),
  values: getters.values(state, props)
}));

const FieldsContainer = ({ fields, values }) => {
  return (
    <Div>
      {R.map(
        field => (
          <FieldResolver key={field.id} field={field} data={values} />
        ),
        fields
      )}
    </Div>
  );
};

export default decorate(FieldsContainer);
