import React, { Component } from "react";
import * as R from "ramda";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import StatusCircle from "ui-kit/StatusCircle";
import Tooltip from "components/Global/Tooltip";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import PreviewProfileModal from "Portal/PortalPreviewProfile/View";

import { getValue } from "../../utils";
import { actions } from "../../model";
import { parseComboId } from "utils/General";

const decorate = connect(
  null,
  bindInstance({
    showModal,
    hideModal,
    onDone: actions.updateData
  })
);

class ProfileApproval extends Component {
  showPreviewProfileModal = () => {
    this.props.showModal({
      content: (
        <PreviewProfileModal
          useModal
          accountId={parseComboId(this.props.data.id)[0]}
          redirectToDashboardRecordTypeId={R.path(
            ["data", "type", "id"],
            this.props
          )}
          onDone={() => {
            this.props.onDone();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const approved = getValue(this.props);
    return (
      <div
        onClick={this.showPreviewProfileModal}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: "100%",
          padding: 8,
          borderRadius: 4,
          cursor: "pointer"
        }}
      >
        <Tooltip
          tooltip={approved ? "Approved" : "Not Yet Approved"}
          placement="bottom"
        >
          <span>
            <StatusCircle status={approved ? "approved" : "not-submitted"} />
          </span>
        </Tooltip>
      </div>
    );
  }
}

export default decorate(ProfileApproval);
