import Helpers from "utils/Global/Helpers";

module.exports = {
  getItemBlock: (credentials, itemBlockId) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/items/item-blocks/${itemBlockId}`,
        credentials,
        success,
        error
      });
    })
  /* eslint-enable no-underscore-dangle */
};
