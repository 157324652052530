import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import ReactSwipe from "react-swipe";
import MediaQuery from "react-responsive";
import Helpers from "utils/Global/Helpers";

const SwimLanes = ({ children }) => (
  <MediaQuery maxWidth={750}>
    {matches => {
      if (matches && Helpers.isTouchDevice()) {
        return (
          <ReactSwipe
            style={{
              container: {
                overflow: "hidden",
                visibility: "hidden",
                position: "relative",
                width: "100vw"
              },
              wrapper: {
                height: "60vh",
                overflow: "hidden",
                position: "relative"
              },
              child: {
                float: "left",
                width: "80%",
                height: "100%",
                position: "relative",
                transitionProperty: "transform"
              }
            }}
            swipeOptions={{ continuous: false }}
          >
            {children}
          </ReactSwipe>
        );
      }
      return <div className={css.swimLanes}>{children}</div>;
    }}
  </MediaQuery>
);
SwimLanes.propTypes = {};

export default CSSModules(SwimLanes, css);
