import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Back = ({ ...props }) => (
  <div styleName="back" {...props}>
    <i className={["material-icons", css.backIcon].join(" ")}>
      keyboard_backspace
    </i>
    Back
  </div>
);

export default CSSModules(Back, css);
