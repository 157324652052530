import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const NameEmailTag = ({ fullName, email }) => {
  if (fullName) {
    return (
      <div styleName="label">{`${fullName} ${email ? `(${email})` : ""}`}</div>
    );
  }
  return <div styleName="label">{email}</div>;
};

NameEmailTag.propTypes = {
  fullName: PropTypes.string,
  email: PropTypes.string
};

export default CSSModules(NameEmailTag, css);
