import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Type = CSSModules(
  ({ name, icon, background_color, text_color, onSelect }) => (
    <div styleName="listItem" onClick={onSelect}>
      <div
        styleName="icon"
        style={{ backgroundColor: background_color, color: text_color }}
      >
        {icon && <i className="material-icons">{icon}</i>}
      </div>
      <div styleName="label">{name}</div>
    </div>
  ),
  css
);

class TypesEditor extends Component {
  render() {
    const { list, onSelect, close } = this.props;
    return (
      <div styleName="container">
        {list.map(type => (
          <Type
            key={type.id}
            {...type}
            onSelect={() => {
              onSelect(type);
              close();
            }}
          />
        ))}
      </div>
    );
  }
}

TypesEditor.propTypes = {
  onSelect: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired
};

export default CSSModules(TypesEditor, css);
