import React from "react";
import * as R from "ramda";
import { useState } from "react";
import { get } from "lodash";
import * as FieldActions from "redux/modules/formsV2/form/fields/actions";
import Input from "components/Global-2016/Forms/Input";
import Wysiwyg from "components/Global/Wysiwyg";
import {
  formBuilderModules as modules,
  formBuilderFormats as formats
} from "utils/Quill/Options";
import { noop } from "utils/General";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const FormHeader = React.memo(
  CSSModules(css)(({ dispatch = noop, isEditing = false, field = null }) => {
    const [text, setText] = useState(
      R.pathOr("", ["settings", "description"], field)
    );

    const onBlurTitle = e => {
      dispatch(
        FieldActions.updateField({
          fieldId: field.id,
          settings: {
            title: e.target.value,
            description: field.settings.description ?? ""
          }
        })
      );
    };

    const handleTextChange = text => {
      setText(text);
    };

    const saveText = () => {
      dispatch(
        FieldActions.updateField({
          fieldId: field.id,
          settings: {
            title: field.settings.title ?? "",
            description: text
          }
        })
      );
    };

    let description = null;
    if (!isEditing && get(field.settings, "description.length")) {
      description = (
        <div styleName="description">
          <span
            className="lennd-wysiwyg-content"
            dangerouslySetInnerHTML={{
              __html: field.settings.description
            }}
          />
        </div>
      );
    }

    return (
      <div styleName={isEditing ? "containerHover" : "container"}>
        {isEditing && (
          <Input
            disabled={!isEditing}
            placeholder={isEditing ? "Section title" : ""}
            onBlur={onBlurTitle}
            defaultValue={field.settings.title}
            label="Section Title"
          />
        )}
        {!isEditing && <div styleName="title">{field.settings.title}</div>}

        {isEditing && (
          <div style={{ backgroundColor: "white" }}>
            <Wysiwyg
              formats={formats}
              id={field.id}
              modules={modules}
              onBlur={saveText}
              onChange={handleTextChange}
              placeholder="Text goes here..."
              text={text}
            />
          </div>
        )}

        {description}
      </div>
    );
  })
);

export default FormHeader;
