import * as constants from "./constants";
import { registerError } from "redux/modules/errors/actions";
import * as selectors from "./selectors";
import api from "./api";
import * as R from "ramda";

const getTicketingProviders = function(data) {
  return async (dispatch, getState) => {
    if (selectors.isFetchingProviders(getState())) return false;
    try {
      dispatch({ type: constants.TICKETING_PROVIDERS_REQUEST });
      const response = await api.get(getState().user.user.credentials, data);
      dispatch({
        type: constants.TICKETING_PROVIDERS_RESPONSE,
        payload: response.body.providers
      });
      return response.body.providers;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred while getting ticketing providers"
          }
        ])
      );
    }
  };
};

const getTicketingProviderConfigurations = function(eventId) {
  return async (dispatch, getState) => {
    if (selectors.isFetchingConfigurations(getState())) return false;
    try {
      dispatch({ type: constants.TICKETING_CONFIGURATIONS_REQUEST });
      const response = await api.eventConfigurations(
        getState().user.user.credentials,
        eventId
      );

      dispatch({
        type: constants.TICKETING_CONFIGURATIONS_RESPONSE,
        payload: R.map(c => ({
          ...c,
          // Originally was pinging API w/ userid to get details. Remove on 1/29/21.
          createdBy: {}
        }))(response.body.providerConfigurations)
      });
      return response.body.providerConfigurations;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user:
              "An error occurred while getting ticketing provider configurations for this event"
          }
        ])
      );
    }
  };
};

const configureProviderForEvent = function(configProps) {
  return async (dispatch, getState) => {
    if (selectors.isConfiguringProvider(getState())) return false;
    try {
      dispatch({ type: constants.TICKETING_CONFIGURING_REQUEST });
      const response = await api.configureProviderForEvent(
        getState().user.user.credentials,
        configProps
      );
      dispatch({
        type: constants.TICKETING_CONFIGURING_RESPONSE,
        payload: response.body.providerConfiguration
      });
      return response.body.providerConfiguration;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user:
              "An error occurred while configuring ticketing provider configurations for this event"
          }
        ])
      );
    }
  };
};

const removeProviderFromEvent = function(providerConfiguration, eventId) {
  return async (dispatch, getState) => {
    if (selectors.isRemovingProvider(getState())) return false;
    try {
      dispatch({ type: constants.TICKETING_REMOVING_REQUEST });
      const response = await api.removeProviderFromEvent(
        getState().user.user.credentials,
        providerConfiguration,
        eventId
      );
      dispatch({
        type: constants.TICKETING_REMOVING_RESPONSE,
        payload: response.body
      });
      return response.body;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: `An error occurred while removing ticketing provider configuration ${providerConfiguration.id} for this event`
          }
        ])
      );
    }
  };
};

const getTicketTypes = function(providerConfiguration, eventId) {
  return async (dispatch, getState) => {
    if (selectors.isFetchingTicketTypes(getState())) return false;
    try {
      dispatch({ type: constants.TICKETING_TYPES_REQUEST });

      let response = null;
      let hasError = false;

      // first attempt to get the ticket types w/ refresh
      try {
        response = await api.getTicketTypes(
          getState().user.user.credentials,
          providerConfiguration.provider.id,
          eventId,
          true
        );
      } catch (e) {
        console.error(
          "Error attempting to fetch ticket types w/ refresh. Will attempt without..."
        );
        hasError = true;
      }

      // attempt to get the ticket types w/o refresh as a fallback
      if (hasError) {
        response = await api.getTicketTypes(
          getState().user.user.credentials,
          providerConfiguration.provider.id,
          eventId,
          false
        );
      }

      dispatch({
        type: constants.TICKETING_TYPES_RESPONSE,
        payload: response.body.ticketTypes
      });
      return response.body.ticketTypes;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: `An error occurred while getting ticket types for ticketing provider ${providerConfiguration.provider.id}`
          }
        ])
      );
    }
  };
};

const getTicketTypeMapping = function(providerConfiguration, eventId) {
  return async (dispatch, getState) => {
    if (selectors.isFetchingTicketTypes(getState())) return false;
    try {
      const response = await api.getTicketTypeMapping(
        getState().user.user.credentials,
        providerConfiguration.provider.id,
        eventId
      );
      dispatch({
        type: constants.TICKETING_TYPES_MAPPING_RESPONSE,
        payload: response.body.ticketTypeMappings
      });
      return response.body.ticketTypeMappings;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: `An error occurred while getting ticket type mapping for ticketing provider ${providerConfiguration.provider.id}`
          }
        ])
      );
    }
  };
};

const syncTicketTypes = function(
  providerConfiguration,
  eventId,
  ticketMapping
) {
  return async (dispatch, getState) => {
    if (selectors.isSyncingTicketTypes(getState())) return false;
    try {
      dispatch({ type: constants.TICKETING_SYNC_TYPES_REQUEST });
      const response = await api.syncTicketTypes(
        getState().user.user.credentials,
        providerConfiguration,
        eventId,
        ticketMapping
      );
      dispatch({
        type: constants.TICKETING_SYNC_TYPES_RESPONSE,
        payload: response.body
      });
      return response.body;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: `An error occurred while syncing ticket types with ${providerConfiguration.provider.name}`
          }
        ])
      );
    }
  };
};

const getAuth1stStep = function(provider) {
  return async (dispatch, getState) => {
    try {
      const result = await api.getAuth1stStep(
        getState().user.user.credentials,
        provider.id
      );
      return result.body;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: `An error occurred while handling auth 1st step with ${provider.name}`
          }
        ])
      );
    }
  };
};

const getAuth2ndStep = function(provider, data) {
  return async (dispatch, getState) => {
    let result;
    try {
      result = await api.getAuth2ndStep(
        getState().user.user.credentials,
        provider.id,
        data
      );
      return result;
    } catch (error) {
      return error;
    }
  };
};

const getAuth2ndStepHack = function(provider, data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.getAuth2ndStepHack(
        getState().user.user.credentials,
        provider.id,
        data
      );
      return result;
    } catch (error) {
      // @NOTE: We return the error to the UI to render
      return JSON.parse(error.error.response.text).error;
    }
  };
};

const getProviderConfigurationSettings = function(provider, data, page) {
  return async (dispatch, getState) => {
    try {
      const result = await api.getProviderConfigurationSettings(
        getState().user.user.credentials,
        provider.id,
        data,
        page
      );
      return result;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: `An error occurred while handling configuration settings with ${provider.name}`
          }
        ])
      );
    }
  };
};

const updateProviderConfigurationSettings = function(provider, data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.updateProviderConfigurationSettings(
        getState().user.user.credentials,
        provider.id,
        data
      );
      return result;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: `An error occurred while updating configuration settings with ${provider.name}`
          }
        ])
      );
    }
  };
};

module.exports = {
  getTicketingProviders: getTicketingProviders,
  getTicketingProviderConfigurations: getTicketingProviderConfigurations,
  configureProviderForEvent: configureProviderForEvent,
  getTicketTypes: getTicketTypes,
  getTicketTypeMapping: getTicketTypeMapping,
  removeProviderFromEvent: removeProviderFromEvent,
  syncTicketTypes: syncTicketTypes,
  getAuth1stStep: getAuth1stStep,
  getAuth2ndStep: getAuth2ndStep,
  getAuth2ndStepHack: getAuth2ndStepHack,
  getProviderConfigurationSettings: getProviderConfigurationSettings,
  updateProviderConfigurationSettings: updateProviderConfigurationSettings
};
