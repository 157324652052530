import React, { Component } from "react";
import DocumentTitle from "components/Global/DocumentTitle";
import { Link } from "react-router";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Checkbox from "material-ui/Checkbox";
import ViewWrapper from "../ViewWrapper";
import PasswordField from "components/Global/Inputs/Password";
import autobind from "autobind-decorator";

export const LOGIN_RETURN_TO_KEY = "loginReturnTo";

@CSSModules(css)
class LoginHandler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      complete: false,
      email: props.location.query.email || "",
      password: "",
      message: props.location.query.message,
      processing: false
    };
  }

  componentDidMount() {
    this.setLoginReturnToUrl();
  }

  setLoginReturnToUrl = () => {
    if (this.props.location.query && this.props.location.query.returnTo) {
      localStorage.setItem(
        LOGIN_RETURN_TO_KEY,
        this.props.location.query.returnTo
      );
    } else {
      // Ensure there is nothing left over that is stored
      localStorage.removeItem(LOGIN_RETURN_TO_KEY);
    }
  };

  @autobind
  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.setState({
      message: null,
      processing: true
    });

    window.webAuth.login(
      {
        username: this.state.email,
        password: this.state.password,
        // eslint-disable-next-line no-underscore-dangle
        realm: window.__AUTH0_REALM__,
        // eslint-disable-next-line no-underscore-dangle
        redirectUri: `${window.__LENND_APP_URL__}/auth/callback`
      },
      err => {
        if (err) {
          // eslint-disable-next-line no-console
          console.error(`[Login Page] Error logging in`, err.error_description);
          this.setState({
            processing: false,
            message: err.error_description
          });
        } else {
          this.setState({
            processing: true
          });
        }
      }
    );
  };

  render() {
    const { location } = this.props;

    return (
      <DocumentTitle title="Login">
        <ViewWrapper>
          <form method="post" onSubmit={this.handleSubmit}>
            <div styleName="formBody">
              <input
                type="hidden"
                name="returnTo"
                value={location.query.returnTo || ""}
              />

              <div styleName="title">Sign in</div>

              <div styleName="inputWrapper">
                <input
                  className="styled"
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={this.state.email}
                  required
                  autoFocus={!location.query.email}
                  onChange={e => this.setState({ email: e.target.value })}
                />
              </div>
              <div styleName="inputWrapper">
                <PasswordField
                  className="styled"
                  name="password"
                  placeholder="Password"
                  required
                  autoFocus={location.query.email}
                  onChange={e => this.setState({ password: e.target.value })}
                />
              </div>
              <div styleName="inputWrapper">
                <div styleName="checkboxWrapper">
                  <Checkbox
                    label="Remember me"
                    name="remember_me"
                    labelStyle={{ fontSize: 13, fontWeight: "normal" }}
                    iconStyle={{ marginRight: 5 }}
                  />
                </div>
                <Link to="/forgot" styleName="forgotPassword">
                  Forgot your password?
                </Link>
              </div>

              {this.state.message ? (
                <div styleName="message" data-cy="login-error-message">
                  {this.state.message}
                </div>
              ) : null}
              <button styleName="buttonWrapper" type="submit">
                <span styleName="button">
                  {this.state.processing ? "Signing in..." : "Sign in"}
                  {this.state.processing ? null : (
                    <i className="material-icons" styleName="icon">
                      arrow_forward
                    </i>
                  )}
                </span>
              </button>
            </div>
            {/*
          // @NOTE: Hide until we open signups
          <div styleName="formFooter">
            New to Lennd?{" "}
            <Link to="/signup" styleName="signupLink">
              Sign Up Here
            </Link>.
          </div>
          */}
          </form>
        </ViewWrapper>
      </DocumentTitle>
    );
  }
}

export default LoginHandler;
