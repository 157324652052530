import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters } from "Modules/AddEditColumnModal/model";
import FileSettings from "./FileSettings";
import DropdownSettings from "./DropdownSettings";
import DateSettings from "./DateSettings";
import DateTimeSettings from "./DateTimeSettings";
import PercentSettings from "./PercentSettings";
import CurrencySettings from "./CurrencySettings";
import LookupSettings from "./LookupSettings";
import ReferenceSettings from "./ReferenceSettings";
import CalculatedNumberSettings from "./CalculatedNumberSettings";
import PhoneSettings from "./PhoneSettings";
import TextSettings from "./TextSettings";
import CateringSettings from "./CateringSettings";

const mapType = {
  dropdown: DropdownSettings,
  date: DateSettings,
  datetime: DateTimeSettings,
  percent: PercentSettings,
  currency: CurrencySettings,
  lookup: LookupSettings,
  reference: ReferenceSettings,
  "calculated-number": CalculatedNumberSettings,
  phone: PhoneSettings,
  text: TextSettings,
  catering: CateringSettings,
  file: FileSettings
};

const decorate = connect((state, props) => ({
  type: getters.type(state, props)
}));

const Options = props =>
  React.createElement(R.prop(props.type, mapType) || "div", props);

export default decorate(Options);
