import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { actions, getters } from "../model";
import { getIsValid } from "../selectors";
import { FIELD_IDS, FORM_ID } from "../constants";

import { Div, BigFilledButton } from "components/Base";
import { Label, Divider } from "EventLight/Common/FieldLayout";
import { WithFormInstanceProvider } from "ui-kit/Form/View";

import { Input, TextAreaInput, Dropdown } from "ui-kit/Form/View";

import MiniModalWrapper from "ui-kit/MiniModalWrapper";

const METHOD_OPTIONS = [
  { id: "click", label: "When the user clicks on the task" },
  { id: "manual", label: "When the user manually marks the task as complete" }
];

const decorate = R.compose(
  WithFormInstanceProvider(R.always(FORM_ID)),
  connect(
    state => ({
      data: getters.details(state),
      showModal: getters.showAddModal(state),
      isValid: getIsValid(state)
    }),
    {
      hideModal: () => actions.setShowAddModal(false),
      save: actions.save,
      update: actions.update
    }
  )
);

const WithModal = ({ showModal, hideModal, data, isValid, save, update }) => {
  return (
    <MiniModalWrapper
      showModal={showModal}
      hideModal={hideModal}
      title={data.id ? "Update Task" : "Add Task"}
    >
      <Div
        bg="white"
        p={8}
        style={{
          overflowY: "auto"
        }}
      >
        <Div display="column" mb={5} width={1}>
          <Label>Task</Label>
          <Input
            fieldId={FIELD_IDS.NAME}
            width={1}
            iniValue={R.prop(FIELD_IDS.NAME)(data)}
            size="big"
            inputType="outline"
          />
        </Div>

        <Div display="column" mb={5} width={1}>
          <Label>Description</Label>
          <TextAreaInput
            fieldId={FIELD_IDS.DESCRIPTION}
            width={1}
            iniValue={R.prop(FIELD_IDS.DESCRIPTION)(data)}
          />
        </Div>

        <Div display="column" mb={5} width={1}>
          <Label>On click, go to URL (Optional)</Label>
          <Input
            fieldId={FIELD_IDS.URL}
            width={1}
            iniValue={R.prop(FIELD_IDS.URL)(data)}
            size="big"
            inputType="outline"
          />
        </Div>

        <Div display="column" mb={5} width={1}>
          <Label>Mark task as complete...</Label>
          <Dropdown
            fieldId={FIELD_IDS.METHOD}
            iniValue={R.prop(FIELD_IDS.METHOD)(data)}
            width="100%"
            clearable={false}
            options={METHOD_OPTIONS}
            placeholder="Select method..."
            usePortal
          />
        </Div>

        <Divider />

        <BigFilledButton
          bg="altB5"
          color="white"
          disabled={!isValid}
          onClick={data.id ? update : save}
        >
          {data.id ? "Update Task" : "Add Task"}
        </BigFilledButton>
      </Div>
    </MiniModalWrapper>
  );
};

export default decorate(WithModal);
