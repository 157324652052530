import { REQUEST, RECEIVE, INVALIDATE } from "./constants";
import * as selectors from "./selectors";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";
import REPORT_TYPES from "redux/modules/orders/reports/reportTypes";

const REPORT_API_MAP = {
  [REPORT_TYPES.PERSON_BY_ASSIGNED_TO]: api.getReportByPersonAssignedTo,
  [REPORT_TYPES.PERSON_BY_NAME_ON_ORDER]: api.getReportByPersonNameOnOrder,
  [REPORT_TYPES.PERSON_BY_PICKUP_PERSON]: api.getReportByPickupPerson,
  [REPORT_TYPES.ACCOUNT_BY_ACCOUNT_ON_ORDER]: api.getReportByAccountOnOrder,
  [REPORT_TYPES.TYPE_BY_ITEM_TYPE]: api.getReportByItemType,
  [REPORT_TYPES.VARIANT_OPTION]: api.getReportByVariantOption
};

export function getReport({ reportType, options }) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState(), reportType)) return;
    try {
      dispatch({
        type: REQUEST,
        payload: {
          reportType: reportType
        }
      });
      const result = await REPORT_API_MAP[reportType](
        getState().user.user.credentials,
        { reportType, options }
      );
      dispatch({
        type: RECEIVE,
        payload: {
          reportType: reportType,
          report: result.payload
        }
      });
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching report"
          }
        ])
      );
    }
  };
}

export function invalidateReport(reportType) {
  return {
    type: INVALIDATE,
    payload: {
      reportType
    }
  };
}
