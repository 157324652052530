import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE, TABS, SORTING } from "./constants";

const iniState = {
  loading: true,
  showEmptyState: false,
  data: {},
  filter: "",
  activeTab: TABS.ALL_FORMS,
  activeSorting: SORTING.PENDING,
  showSimpleFormModal: false,
  scopeSelected: "",
  isSaving: false
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    editForm: R.identity,
    goToResults: R.identity,
    submitForm: R.identity,
    goToPortal: R.identity,
    copyPortalLink: R.identity,
    goToPortalSettings: R.identity,
    showFormModal: R.identity,
    selectFormScope: R.identity,
    saveBuildForm: R.identity,
    hideSimpleFormModal: () => ({
      scopeSelected: "",
      showSimpleFormModal: false,
      isSaving: false
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
