import PropTypes from "prop-types";
import React, { Component } from "react";
import Switch from "react-toggle-switch";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import styles from "./styles.scss";

class Group extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: props.on
    };
  }

  @autobind
  toggleShow(event) {
    // We don't get the event from toggle-switch to prevent propagation
    // so we need to filter out via class name.
    if (event.target.className.includes("switch")) {
      return;
    }
    this.setState({
      show: !this.state.show
    });
  }

  @autobind
  onToggle() {
    // Expand the group when enabled
    if (!this.props.on) {
      this.setState({
        show: true
      });
    }
    this.props.onClick();
  }

  render() {
    const { permissionGroup, children, on, enabled } = this.props;
    return (
      <section>
        <h1 onClick={this.toggleShow} styleName="header">
          <span styleName={on ? "groupTitle" : "groupTitleDisabled"}>
            {permissionGroup.name}
          </span>
          <Switch
            className="permission-switch"
            value={permissionGroup.key}
            on={on}
            enabled={enabled}
            onClick={this.onToggle}
          />
        </h1>
        {this.state.show ? <div styleName="content">{children}</div> : ""}
      </section>
    );
  }
}

Group.propTypes = {
  permissionGroup: PropTypes.object.isRequired,
  children: PropTypes.array,
  enabled: PropTypes.bool,
  on: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

export default CSSModules(Group, styles);
