import accountReferenceSchema from "./account-reference";
import credentialOrderReferenceSchema from "./credential-order-reference";
import cateringDietaryRestrictionsSchema from "./catering-dietary-restrictions";
import cateringSchema from "./catering";
import checkboxSchema from "./checkbox";
import contactReferenceSchema from "./contact-reference";
import submitterReferenceSchema from "./submitter-reference";
import contactSchema from "./contact";
import countriesSchema from "./countries";
import credentialsSchema from "./credentials";
import dateSchema from "./date";
import datetimeSchema from "./datetime";
import dropdownSchema from "./dropdown";
import emailSchema from "./email";
import eventDaysSchema from "./event-days";
import fileSchema from "./file";
import inventorySchema from "./inventory";
import lookupSchema from "./lookup";
import numberSchema from "./number";
import phoneSchema from "./phone";
import radioSchema from "./radio";
import scheduleActivitySchema from "./schedule-activity";
import textareaSchema from "./textarea";
import textSchema from "./text";
import timeSchema from "./time";
import userSchema from "./user";
import referenceSchema from "./reference";
import percentSchema from "./percent";
import currencySchema from "./currency";
import calculatedNumberSchema from "./calculated-number";
import dependencySchema from "./dependency";
import catalogItemSchema from "./catalog-item";
import signatureSchema from "./signature";

export const accountReference = accountReferenceSchema;
export const credentialOrderReference = credentialOrderReferenceSchema;
export const catering = cateringSchema;
export const cateringDietaryRestrictions = cateringDietaryRestrictionsSchema;
export const checkbox = checkboxSchema;
export const contact = contactSchema;
export const contactReference = contactReferenceSchema;
export const submitterReference = submitterReferenceSchema;
export const reference = referenceSchema;
export const countries = countriesSchema;
export const credentials = credentialsSchema;
export const date = dateSchema;
export const datetime = datetimeSchema;
export const dropdown = dropdownSchema;
export const email = emailSchema;
export const eventDays = eventDaysSchema;
export const file = fileSchema;
export const inventory = inventorySchema;
export const number = numberSchema;
export const phone = phoneSchema;
export const radio = radioSchema;
export const scheduleActivity = scheduleActivitySchema;
export const text = textSchema;
export const textarea = textareaSchema;
export const time = timeSchema;
export const user = userSchema;
export const percent = percentSchema;
export const currency = currencySchema;
export const calculatedNumber = calculatedNumberSchema;
export const lookup = lookupSchema;
export const dependency = dependencySchema;
export const catalogItem = catalogItemSchema;
export const signature = signatureSchema;
