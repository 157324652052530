import Helpers from "utils/Global/Helpers";

module.exports = {
  get: data =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/account/list/${data.eventId}`,
        data: {},
        success: result => {
          resolve(result);
        },
        error: err => {
          reject(err);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  post: data =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/account/list/${data.eventId}`,
        data,
        success: result => {
          resolve(result);
        },
        error: err => {
          reject(err);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  search: (orgId, eventId, query, credentials) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: orgId
          ? `${
              window.__LENND_API_BASE_URL__
            }/account/org-list/${orgId}/search?q=${query}`
          : `${
              window.__LENND_API_BASE_URL__
            }/account/list/${eventId}/search?q=${query}`,
        credentials,
        success: result => {
          resolve(result);
        },
        error: err => {
          reject(err);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
