import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as R from "ramda";
import { actions, getters } from "Event/ItemCatalog";
import { Div } from "components/Base";
import { TABS } from "Event/ItemCatalog/constants";

const decorate = connect(
  state => ({
    activeTab: getters.activeTab(state),
    typeId: getters.typeId(state)
  }),
  {
    setActiveTab: actions.setActiveTab
  }
);

const Header = ({
  activeTab,
  setActiveTab,
  router: { push },
  params: { eventId },
  typeId
}) => (
  <Div width={1} bg="white" pt={7} pl={8} bb={1} bc="neutral3">
    <Div fs={6}>Item Catalog</Div>
    <Div display="row.flex-start.center" mt={3}>
      <Div
        pb={2}
        bb={2}
        bc={activeTab === TABS.ITEMS ? "primary8" : "transparent"}
        fw={3}
        color={activeTab === TABS.ITEMS ? "primary8" : "neutral6"}
        px={2}
        transition="fast"
        onClick={() => {
          setActiveTab(TABS.ITEMS);
          push(`/event/${eventId}/settings/catalog/type/${typeId}/items`);
        }}
      >
        Items
      </Div>
      <Div
        pb={2}
        bb={2}
        bc={activeTab === TABS.ITEMS_QUESTIONS ? "primary8" : "transparent"}
        fw={3}
        color={activeTab === TABS.ITEMS_QUESTIONS ? "primary8" : "neutral6"}
        px={2}
        ml={4}
        transition="fast"
        onClick={() => {
          setActiveTab(TABS.ITEMS_QUESTIONS);
          push(`/event/${eventId}/settings/catalog/questions`);
        }}
      >
        Items Questions
      </Div>
    </Div>
  </Div>
);

export default R.compose(
  withRouter,
  decorate
)(Header);
