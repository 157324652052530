import React from "react";

import { connect } from "react-redux";
import { getReportGroups } from "../selectors";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import {
  Div,
  PopoverMenu,
  makeTable,
  MediumOutlineButton,
  Text1,
  Text3,
  MoreIcon,
  MediumFilledButton
} from "components/Base";
import moment from "moment";
import { withProps } from "utils/General";

import { getters, actions } from "../index";
import PageTitle from "EventLight/Common/PageTitle";
import Loading from "ui-kit/Loading";
import { Page, Title } from "EventLight/Common/FieldLayout";
import Snackbar from "ui-kit/Snackbar/View";

const decorate = connect(
  state => ({
    reportGroups: getReportGroups(state),
    loading: getters.loading(state)
  }),
  {
    runReport: actions.runReport,
    editReport: actions.editReport,
    deleteReport: actions.deleteReport,
    createReport: actions.createReport
  }
);

const Group = ({ children }) => (
  <Div bra={1} bg="white" p={5} mb={3} shadow={1}>
    {children}
  </Div>
);
const GroupTitle = ({ children, canAddReport, addReport }) => (
  <Div display="row.flex-start.center" fs={4} color="gray7" fw={3} mb={5}>
    {children}
    {!canAddReport ? null : (
      <Div
        color={{
          default: "primary7",
          hover: "primary5"
        }}
        ml={4}
        onClick={addReport}
        fs={2}
      >
        Add Report
      </Div>
    )}
  </Div>
);

const Empty = ({ children }) => (
  <Div display="row.flex-start.center">{children}</Div>
);

const RowText = ({ text = "—" }) => <Text3 color="gray7">{text}</Text3>;

const headerCell = text => () => (
  <Text1 color="gray7" bold uppercase>
    {text}
  </Text1>
);

const HeaderStyler = withProps({
  display: "row.flex-start.center",
  pb: 2,
  bc: "gray3",
  bb: 1
})(Div);

const RowStyler = withProps({
  display: "row.flex-start.center",
  bg: {
    default: "white",
    hover: "gray1"
  },
  py: 1
})(Div);

const tableProps = {
  TableStyler: Div,
  HeaderStyler,
  RowStyler,
  HeaderCellStyler: Div,
  RowCellStyler: Div,
  columnProps: [
    { width: 2 / 5 },
    { width: 1 / 5 },
    { width: 1 / 5 },
    { width: 1 / 5 }
  ],
  headerCellComps: [
    headerCell("Report Name"),
    headerCell("Created by"),
    headerCell("Last run"),
    headerCell("Actions")
  ],
  rowCellComps: [
    ({ name }) => (
      <Div display="row.flex-start.center">
        <Text3 ml={1} color="neutral7" bold>
          {name}
        </Text3>
      </Div>
    ),
    ({ created_by }) => (
      <RowText text={[created_by.first_name, created_by.last_name].join(" ")} />
    ),
    ({ last_run_at }) => (
      <RowText text={last_run_at ? moment(last_run_at).fromNow() : null} />
    ),
    ({
      showEditReportModal,
      showDeleteReportModal,
      runReport,
      running,
      is_standard_report
    }) => (
      <Div display="row.flex-start.center">
        <PopoverMenu
          Label={({ onClick }) => (
            <MediumFilledButton
              onClick={running ? undefined : onClick}
              bg={running ? "yellow0" : "neutral0"}
              color={running ? "gray7" : "primary7"}
            >
              {running ? "Preparing..." : "Run Report"}
            </MediumFilledButton>
          )}
          menuItems={[
            ["PDF", () => runReport("pdf")],
            ["XLSX", () => runReport("xlsx")],
            ["HTML", () => runReport("html")]
          ].filter(i => i)}
        />
        {is_standard_report ? null : (
          <PopoverMenu
            Label={({ onClick }) => (
              <MediumOutlineButton onClick={onClick} ml={3}>
                <MoreIcon size={16} />
              </MediumOutlineButton>
            )}
            menuItems={[
              ["Edit Report", () => showEditReportModal()],
              ["Delete Report", () => showDeleteReportModal()]
            ]}
          />
        )}
      </Div>
    )
  ]
};

const ReportTable = makeTable({
  ...tableProps
});

const StandardReportTable = makeTable({
  ...tableProps,
  columnProps: [{ width: 4 / 5 }, { width: 1 / 5 }],
  headerCellComps: [headerCell("Report Name"), headerCell("Actions")],
  rowCellComps: [
    ({ name }) => (
      <Div display="row.flex-start.center">
        <Text3 ml={1} color="neutral7" bold>
          {name}
        </Text3>
      </Div>
    ),
    ({
      runReport,
      showEditReportModal,
      showDeleteReportModal,
      running,
      is_standard_report,
      name
    }) => (
      <Div display="row.flex-start.center">
        <PopoverMenu
          Label={({ onClick }) => (
            <MediumFilledButton
              onClick={running ? undefined : onClick}
              bg={running ? "yellow0" : "neutral0"}
              color={running ? "gray7" : "primary7"}
            >
              {running ? "Preparing..." : "Run Report"}
            </MediumFilledButton>
          )}
          menuItems={[
            name.toLowerCase() !== "scan report"
              ? ["XLSX", () => runReport("xlsx")]
              : ["CSV", () => runReport("csv")]
          ].filter(i => i)}
        />
        {is_standard_report ? null : (
          <PopoverMenu
            Label={({ onClick }) => (
              <MediumOutlineButton onClick={onClick} ml={3}>
                <MoreIcon size={16} />
              </MediumOutlineButton>
            )}
            menuItems={[
              ["Edit Report", () => showEditReportModal()],
              ["Delete Report", () => showDeleteReportModal()]
            ]}
          />
        )}
      </Div>
    )
  ]
});

const Layout = ({
  reportGroups,
  runReport,
  createReport,
  editReport,
  deleteReport,
  loading
}) => (
  <>
    <PageTitle titles={["Reports"]} />
    <Page>
      <Title
        icon="show_chart"
        title="Reports"
        description="Create and manage reports for your event"
      />

      {loading ? (
        <Loading />
      ) : (
        <Div pb={5} px={7}>
          {reportGroups.map(group => {
            const Table = group.is_standard ? StandardReportTable : ReportTable;
            return (
              <Group>
                <GroupTitle
                  addReport={() => createReport({ moduleId: group.id })}
                  canAddReport={
                    group.is_standard &&
                    [
                      STANDARD_MODULE_IDS.accounts.id,
                      STANDARD_MODULE_IDS.contacts.id
                    ].includes(group.id)
                      ? true
                      : !group.is_standard
                  }
                >
                  {group.name} Reports ({group.reports.length})
                </GroupTitle>
                {group.reports.length ? (
                  <Table
                    rowsData={group.reports.map(r => ({
                      ...r,
                      runReport: format =>
                        runReport({
                          reportId: r.id,
                          runningId: r.running_id,
                          meta: r.meta ? r.meta : {},
                          format
                        }),
                      showEditReportModal: () => editReport({ reportId: r.id }),
                      showDeleteReportModal: () =>
                        deleteReport({ reportId: r.id })
                    }))}
                  />
                ) : (
                  <Empty>No reports have been created yet</Empty>
                )}
                <Snackbar />
              </Group>
            );
          })}
        </Div>
      )}
    </Page>
  </>
);

export default decorate(Layout);
