import React from "react";
import { connect } from "react-redux";
import { actions } from "../model";
import { Div, BigFilledButton } from "components/Base";

const decorate = connect(
  null,
  {
    saveSettings: actions.saveSettings
  }
);

const Footer = ({ saveSettings }) => (
  <Div
    height={80}
    bg="white"
    bt={1}
    bc="neutral2"
    display="row.space-between.center"
    style={{ padding: "0px 34px", flexShrink: 0 }}
  >
    <Div display="row.flex-start.center">
      <BigFilledButton bg="altB5" color="white" onClick={saveSettings}>
        Save Settings
      </BigFilledButton>
    </Div>
  </Div>
);

export default decorate(Footer);
