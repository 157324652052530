import * as R from "ramda";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Section from "./Section";

import {
  updateFieldOrder,
  deleteField
} from "redux/modules/formsV2/form/fields/actions";
import { getModule } from "redux/modules/modules/module/actions";
import { createContext } from "redux-mvc";
import * as flags from "utils/feature-flags";
import { selectFeatureFlag } from "@flopflip/react-redux";

import { contextConfig } from "Submission/Subform/View";
import module from "../TableModule";

import rootSaga from "../TableModule/sagas";
import * as ModuleSelectors from "redux/modules/modules/module/selectors";

module.setRootSaga(rootSaga);

const decorate = R.compose(
  connect(
    state => ({
      canShowSimpleSideBar: selectFeatureFlag(flags.SIMPLE_FORMS.NAME)(state),
      allFields: ModuleSelectors.fieldGroupFields(
        state,
        state?.formsV2?.form?.form?.base_module_id
      )
    }),
    dispatch => ({
      ...bindActionCreators(
        {
          updateFieldOrder,
          deleteField,
          getModule
        },
        dispatch
      ),
      dispatch
    })
  ),
  createContext({
    ...contextConfig,
    module,
    options: {
      ...contextConfig.options,
      dispatchToGlobal: R.compose(
        R.not,
        R.test(new RegExp(R.join("|", module.modules))),
        R.prop("type")
      ),
      observedDomains: [
        ...contextConfig.options.observedDomains,
        "formsV2",
        "@flopflip"
      ]
    }
  })
);

export default decorate(Section);
