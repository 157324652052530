import React from "react";

import { connect } from "react-redux";

import { getters } from "Orders/TicketsPasses";

import QuickAssignPricesTable from "./QuickAssignPricesTable";

import { Div } from "components/Base";

import QuickAssignPricesCredentials from "./QuickAssignPricesCredentials";

import { QUICK_ASSIGN_PAGES } from "Orders/TicketsPasses/constants";

const decorate = connect(state => ({
  selectedQuickAssignPage: getters.selectedQuickAssignPage(state)
}));

const Content = ({ selectedQuickAssignPage }) => {
  if (selectedQuickAssignPage === QUICK_ASSIGN_PAGES.CREDENTIALS) {
    return <QuickAssignPricesCredentials />;
  }
  return <QuickAssignPricesTable />;
};

const DecoratedContent = decorate(Content);

const QuickAssignPricesBody = () => (
  <Div width={1} p={6} bg="white" style={{ overflow: "auto" }} height={387}>
    <DecoratedContent />
  </Div>
);

export default QuickAssignPricesBody;
