import PropTypes from "prop-types";
import React, { Component, cloneElement } from "react";
import Tooltip from "components/Global/Tooltip";

// This component is useful for adding a tooltip when text is truncated.  In the
// child component, call the tooltipValue() prop with the tooltip message you want
// to display ex: `this.props.tooltipValue('This is a message')`. Pass the tooltipRef
// prop to the truncated element ref attribute ex `<div ref={this.props.tooltipRef}`

class TooltipFactory extends Component {
  state = {
    tooltip: false
  };

  componentDidMount() {
    if (this.text && this.text.scrollWidth > this.text.offsetWidth) {
      this.setState({ tooltip: true });
    }
  }

  render() {
    const { children } = this.props;

    const child = cloneElement(children, {
      tooltipRef: ref => {
        this.text = ref;
      },
      tooltipValue: value => {
        this.toolTipText = value;
      }
    });

    if (this.state.tooltip && this.toolTipText) {
      return (
        <Tooltip tooltip={this.toolTipText} placement="top">
          {child}
        </Tooltip>
      );
    }
    return child;
  }
}

TooltipFactory.propTypes = {
  children: PropTypes.node.isRequired
};

export default Child => props => (
  <TooltipFactory {...props}>
    <Child {...props} />
  </TooltipFactory>
);
