import Helpers from "utils/Global/Helpers";
import getQuery from "redux/modules/modules/utils/get-query";

module.exports = {
  get: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery(data.options);

      Helpers.request({
        method: "get",
        url: data.recordTypeId
          ? `${window.__LENND_API_BASE_URL__}/modules/${
              data.moduleId
            }/record-types/${data.recordTypeId}/layout/${
              data.layoutType
            }/visible-fields${query || ""}`
          : `${window.__LENND_API_BASE_URL__}/modules/${
              data.moduleId
            }/record-types/layout/${data.layoutType}/visible-fields${query ||
              ""}`,
        credentials,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */,

  post: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery(data.options);

      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/modules/${
          data.moduleId
        }/record-types/${data.recordTypeId}/layout/${
          data.layoutType
        }/visible-fields${query || ""}`,
        credentials,
        data,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */,

  put: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery(data.options);

      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/modules/${
          data.moduleId
        }/record-types/${data.recordTypeId}/layout/${
          data.layoutType
        }/visible-fields${query || ""}`,
        credentials,
        data,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */,

  delete: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery(data.options);

      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/modules/${
          data.moduleId
        }/record-types/${data.recordTypeId}/layout/${
          data.layoutType
        }/visible-fields${query || ""}`,
        credentials,
        data,
        success,
        error
      });
    })
  /* eslint-enable no-underscore-dangle */
};
