/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  get: ({ credentials, moduleId, eventId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules/event/${eventId}/${moduleId}/managers`,
        credentials,
        success,
        error
      });
    }),
  getModuleApprovers: ({ credentials, eventId, moduleId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/approval-workflows/default-approvers/event/${eventId}/module/${moduleId}`,
        credentials,
        success,
        error
      });
    }),
  getItemTypeApprovers: ({ credentials, eventId, typeId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/approval-workflows/default-approvers/event/${eventId}/type/${typeId}`,
        credentials,
        success,
        error
      });
    }),
  updateApprovers: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/approval-workflows/spaces/${data.spaceId}/default-approvers`,
        data,
        credentials,
        success,
        error
      });
    })
};
