import React, { Component } from "react";
import ClassNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import HeaderAvatar from "../HeaderAvatar";
import HeaderTitle from "../HeaderTitle";
import HeaderHelp from "../HeaderHelp";
import Notifications from "Notifications/View";
import EventOptionsDropdown from "../EventOptionsDropdown";

import getVisibleModules from "components/Event/utils/get-visible-modules";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import QuickActionsToolbar from "Toolbar/QuickActionsNavigation/View";

@CSSModules(css)
class EventHeader extends Component {
  renderLink(moduleDetails) {
    const isLinkActive = names =>
      this.props.routes.some(({ name }) => names.includes(name));

    switch (moduleDetails.internal_name) {
      case "sessions":
        return (
          <Link
            key="sessions"
            to={`/event/${this.props.eventDetails.id}/sessions`}
            className={ClassNames({
              "nav-link": true,
              "nav-link-wrapper": true,
              active: isLinkActive(["manageSessions"])
            })}
            activeClassName="active"
          >
            <span className="nav-link-label">Sessions</span>
          </Link>
        );
      case "virtual":
        return (
          <Link
            key="virtual"
            to={`/event/${this.props.eventDetails.id}/virtual/dashboard`}
            className={ClassNames({
              "nav-link": true,
              "nav-link-wrapper": true,
              active: isLinkActive(["VirtualWrapper"])
            })}
            activeClassName="active"
          >
            <span className="nav-link-label">Virtual</span>
          </Link>
        );
      case "schedules":
        return (
          <Link
            key="schedule"
            to={`/event/${this.props.eventDetails.id}/schedule/master`}
            className={ClassNames({
              "nav-link": true,
              "nav-link-wrapper": true,
              active: isLinkActive(["Schedule"])
            })}
            activeClassName="active"
          >
            <span className="nav-link-label">Schedule</span>
          </Link>
        );
      case "accounts":
        return (
          <Link
            key="accounts"
            to={`/event/${this.props.eventDetails.id}/module/${STANDARD_MODULE_IDS.accounts.id}/dashboard`}
            className={ClassNames({
              "nav-link": true,
              "nav-link-wrapper": true,
              active:
                (isLinkActive(["ModuleRecords", "ModuleDashboard"]) &&
                  this.props.params.moduleId ===
                    STANDARD_MODULE_IDS.accounts.id) ||
                isLinkActive([
                  "accountsHomeList",
                  "accountProfileOverview",
                  "accountProfileActivity",
                  "accountsHomeReports"
                ])
            })}
            activeClassName="active"
          >
            <span className="nav-link-label">Groups</span>
          </Link>
        );
      case "inventory":
        return (
          <Link
            key="inventory"
            to={
              this.props.canViewInventory
                ? `/event/${this.props.eventDetails.id}/inventory/manage/all-requests`
                : `/event/${this.props.eventDetails.id}/inventory`
            }
            className={ClassNames({
              "nav-link": true,
              "nav-link-wrapper": true,
              active: isLinkActive([
                "ManageInventory",
                "eventInventoryOverview",
                "eventInventoryHomeMain",
                "eventInventoryNeedsOverview",
                "eventInventoryNeedsByStatus",
                "eventInventoryNeeds",
                "eventInventoryCatalog"
              ])
            })}
            activeClassName="active"
          >
            <span className="nav-link-label">Inventory</span>
          </Link>
        );
      case "booths":
        return (
          <Link
            key="booths"
            to={`/event/${this.props.eventDetails.id}/booths/manage/all-requests`}
            className={ClassNames({
              "nav-link": true,
              "nav-link-wrapper": true,
              active: isLinkActive(["BoothsManageBy"])
            })}
            activeClassName="active"
          >
            <span className="nav-link-label">Booths</span>
          </Link>
        );
      case "contacts":
        return (
          <Link
            key="contacts"
            to={`/event/${this.props.eventDetails.id}/module/${STANDARD_MODULE_IDS.contacts.id}/dashboard`}
            className={ClassNames({
              "nav-link": true,
              "nav-link-wrapper": true,
              active:
                (isLinkActive(["ModuleRecords", "ModuleDashboard"]) &&
                  this.props.params.moduleId ===
                    STANDARD_MODULE_IDS.contacts.id) ||
                isLinkActive(["editEventContacts", "editEventContactsList"])
            })}
            activeClassName="active"
          >
            <span className="nav-link-label">People</span>
          </Link>
        );
      case "credentials":
        return (
          <Link
            key="credentials"
            to={`/event/${this.props.params.eventId}/passes/manage/all-requests`}
            className={ClassNames({
              "nav-link": true,
              "nav-link-wrapper": true,
              active: isLinkActive([
                "PassesContainer",
                "PassesDashboard",
                "PassesManageOrders",
                "PassesManageBy",
                "PassesManageBySource",
                "PassesApplication",
                "PassesActionsSendConfirmations",
                "PassesReports",
                "PassesCheckin",
                "PassesSetup",
                "PassesSetupOverview",
                "PassesSetupTypes",
                "PassesSetupApprovers",
                "PassesSetupApproversAdvanced",
                "PassesSetupConfirmations",
                "PassesSetupCollect",
                "PassesSetupCollectGuestLists",
                "PassesSetupCollectRequestForms",
                "PassesSetupCollectApplications",
                "PassesSetupIntegrations"
              ])
            })}
            activeClassName="active"
          >
            <span className="nav-link-label">Passes</span>
          </Link>
        );
      case "catering":
        return (
          <Link
            key="catering"
            to={`/event/${this.props.params.eventId}/catering/manage/all-requests`}
            className={ClassNames({
              "nav-link": true,
              "nav-link-wrapper": true,
              active: isLinkActive([
                "CateringContainer",
                "CateringDashboard",
                "CateringManageBy",
                "CateringV3ReportsMeals"
              ])
            })}
            activeClassName="active"
          >
            <span className="nav-link-label">Catering</span>
          </Link>
        );
      case "orders":
        return (
          <Link
            key="orders"
            to={`/event/${this.props.params.eventId}/orders/orders`}
            className={ClassNames({
              "nav-link": true,
              "nav-link-wrapper": true,
              active: isLinkActive(["OrdersContainer", "OrdersList"])
            })}
            activeClassName="active"
          >
            <span className="nav-link-label">Orders</span>
          </Link>
        );
      case "users":
        return (
          <Link
            key="users"
            to={`/event/${this.props.eventDetails.id}/settings/users`}
            className={ClassNames({
              "nav-link": true,
              "nav-link-wrapper": true,
              active: false
            })}
            activeClassName="active"
          >
            <span className="nav-link-label">Users</span>
          </Link>
        );

      case "settings":
        return (
          <Link
            key="settings"
            to={`/event/${this.props.eventDetails.id}/settings`}
            className={ClassNames({
              "nav-link": true,
              "nav-link-wrapper": true,
              active: isLinkActive(["editEventSettings"])
            })}
            activeClassName="active"
          >
            <span className="nav-link-label">Settings</span>
          </Link>
        );
      case "reports":
        return (
          <Link
            key="reports"
            to={`/event/${this.props.eventDetails.id}/reports`}
            className={ClassNames({
              "nav-link": true,
              "nav-link-wrapper": true,
              active: isLinkActive(["EventReports"])
            })}
            activeClassName="active"
          >
            <span className="nav-link-label">Reports</span>
          </Link>
        );
      case "formsv3":
      case "forms":
        return (
          <Link
            key="forms"
            to={`/event/${this.props.eventDetails.id}/forms-v2`}
            className={ClassNames({
              "nav-link": true,
              "nav-link-wrapper": true,
              active: isLinkActive([
                "formsV2_Forms",
                "formsV2_FormOverview",
                "formsV2_FormOverview_With_Submission",
                "formsV2_Requests"
              ])
            })}
            activeClassName="active"
          >
            <span className="nav-link-label">Forms</span>
          </Link>
        );
      case "portal":
        return (
          <Link
            key="portals"
            to={`/event/${this.props.eventDetails.id}/portals`}
            className={ClassNames({
              "nav-link": true,
              "nav-link-wrapper": true,
              active: isLinkActive(["PortalList", "PortalPages"])
            })}
            activeClassName="active"
          >
            <span className="nav-link-label">Portals</span>
          </Link>
        );
      default:
        return (
          <Link
            key={moduleDetails.id}
            to={`/event/${this.props.eventDetails.id}/module/${moduleDetails.id}/dashboard`}
            className={ClassNames({
              "nav-link": true,
              "nav-link-wrapper": true,
              active: this.props.params.moduleId === moduleDetails.id
            })}
            activeClassName="active"
          >
            <span className="nav-link-label">{moduleDetails.name}</span>
          </Link>
        );
    }
  }

  renderDropdown() {
    const visibleModules = getVisibleModules(
      this.props.userPermissionProfile,
      this.props.eventDetails.enabled_modules
    );
    const visibleModulesWithoutSettings = visibleModules.filter(
      m => m.internal_name !== "settings"
    );
    if (visibleModulesWithoutSettings.length > 5) {
      const dropdownModules = visibleModulesWithoutSettings.slice(5);
      return (
        <EventOptionsDropdown
          {...this.props}
          eventDetails={this.props.eventDetails}
          modules={dropdownModules}
          showSettingsLink={Boolean(
            visibleModules.find(m => m.internal_name === "settings")
          )}
          eventId={this.props.eventDetails.id}
        />
      );
    }
    return null;
  }

  render() {
    if (
      this.props.isFetchingEventDetails &&
      !("id" in this.props.eventDetails)
    ) {
      return (
        <div styleName="container">
          <div styleName="sep" />
          <div styleName="flex">
            <Notifications
              params={this.props.params}
              eventId={parseInt(this.props.params.eventId, 10)}
            />

            <HeaderHelp
              eventId={this.props.eventDetails.id}
              showModal={this.props.showModal}
              hideModal={this.props.hideModal}
            />

            <div styleName="avatar">
              <HeaderAvatar />
            </div>
          </div>
        </div>
      );
    }

    let modules = getVisibleModules(
      this.props.userPermissionProfile,
      this.props.eventDetails.enabled_modules
    );

    modules =
      modules[5] && modules[5].internal_name === "settings"
        ? modules.slice(0, 6)
        : modules.slice(0, 5);

    return (
      <div styleName="container">
        <div styleName="flex">
          <Link
            styleName="logo"
            to={`/event-light/${this.props.params.eventId}/dashboard`}
            title="Events"
          >
            <i className="material-icons" styleName="logoIcon">
              &#xE5C3;
            </i>
          </Link>
          <HeaderTitle
            isEvent
            user={this.props.user}
            location={this.props.location}
            eventId={this.props.eventDetails.id}
            myEvents={this.props.myEvents}
            name={this.props.eventDetails.name}
            showModal={this.props.showModal}
            hideModal={this.props.hideModal}
          />

          <div styleName="links">
            {modules.map(moduleDetails => this.renderLink(moduleDetails))}
            {!this.props.canViewQuickActions ? this.renderDropdown() : null}
          </div>
          {this.props.canViewQuickActions ? <QuickActionsToolbar /> : null}
        </div>
        <div styleName="flex">
          <Notifications
            params={this.props.params}
            eventId={parseInt(this.props.params.eventId, 10)}
          />

          <HeaderHelp
            eventId={this.props.eventDetails.id}
            showModal={this.props.showModal}
            hideModal={this.props.hideModal}
          />

          <div styleName="avatar">
            <HeaderAvatar />
          </div>
        </div>
      </div>
    );
  }
}

EventHeader.propTypes = {
  params: PropTypes.object.isRequired,
  routes: PropTypes.array.isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  myEvents: PropTypes.array.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  eventDetails: PropTypes.object.isRequired,
  isFetchingEventDetails: PropTypes.bool.isRequired,
  userPermissionProfile: PropTypes.shape({
    id: PropTypes.string,
    permission: PropTypes.array,
    permissionList: PropTypes.array
  }).isRequired,
  user: PropTypes.object.isRequired
};

export default EventHeader;
