import React from "react";
import { takeEvery, all, fork, put, call, select } from "redux-saga/effects";
import { actions } from "./model";
import Api from "./api";
import { registerError } from "redux/modules/errors/actions";
import { getCredentials } from "redux/modules/user/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import AddEventModal from "Organizations/CloneEventModal/View";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { makeFuture } from "utils/General/sagas";
import * as R from "ramda";
import { actions as ActivityDetailsActions } from "Schedules/ActivityDetailsSidebar/model";

import { push } from "react-router-redux";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_MODULE_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

import { hashStrPickEl } from "utils/General";
import { getNamedColors } from "components/Base";

import * as EventsSelectors from "redux/modules/events/selectors";
import { organization } from "redux/modules/organization/selectors";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

const avatarColorSet = getNamedColors(
  ["purple", "blue", "teal", "yellow", "orange", "red"],
  [6, 8]
);
const colorBuilder = hashStrPickEl(avatarColorSet);

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const userId = yield select(getUserId);

  return {
    credentials,
    orgId,
    userId
  };
};

const fetchEvents = function*() {
  try {
    const { orgId, credentials } = yield call(getParams);

    const { payload: events } = yield call(Api.getEvents, {
      orgId,
      credentials
    });

    yield put(
      actions.setEventList(
        R.map(e => ({
          ...e,
          color: colorBuilder(e.name)
        }))(events)
      )
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred loading events"
        }
      ])
    );
  }
};

const fetchStats = function*() {
  try {
    const { orgId, credentials } = yield call(getParams);

    const { payload: stats } = yield call(Api.getStats, {
      orgId,
      credentials
    });

    yield put(actions.setStats(stats));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred loading stats"
        }
      ])
    );
  }
};

const fetchTasks = function*() {
  try {
    const { userId, orgId, credentials } = yield call(getParams);

    const { payload: tasks } = yield call(Api.getTasks, {
      orgId,
      userId,
      credentials
    });

    yield put(actions.setTasks(tasks));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred loading tasks"
        }
      ])
    );
  }
};

const init = function*() {
  yield all([
    put(actions.setLoadingEvents(true)),
    put(actions.setLoading(true))
  ]);

  yield all([call(fetchTasks), call(fetchEvents), call(fetchStats)]);

  yield all([
    put(actions.setLoadingEvents(false)),
    put(actions.setLoading(false))
  ]);
};

const showAddEventModal = function*({ day, eventIdToClone }) {
  const handleAddEvent = yield call(makeFuture);

  const org = yield select(organization);
  const allUserEvents = yield select(EventsSelectors.events);
  const isLenndAdmin = yield select(
    selectFeatureFlag(flags.IS_LENND_ADMIN.NAME)
  );
  const canClone = yield select(selectFeatureFlag(flags.CAN_CLONE.NAME));
  let isCloning = false;

  yield put(
    showModal({
      content: (
        <AddEventModal
          eventIdToClone={eventIdToClone}
          startDate={day}
          endDate={day}
          canClone={canClone}
          //
          addEvent={() =>
            handleAddEvent.done({
              createEvent: true,
              shouldHideModal: true
            })
          }
          organization={org}
          allUserEvents={allUserEvents}
          isLenndAdmin={isLenndAdmin}
          isCloning={() => {
            isCloning = true;
          }}
          hideModal={() => {
            if (isCloning) return false;

            return handleAddEvent.done({
              createEvent: false,
              shouldHideModal: true
            });
          }}
        />
      ),
      wrapper: ModalWrapper
    })
  );

  return yield call(handleAddEvent.onRealized);
};

const handleAddEventModal = function*({ payload: { day, eventIdToClone } }) {
  const orgId = yield select(getOrgId);
  const credentials = yield select(getCredentials);

  const { createEvent, shouldHideModal } = yield call(showAddEventModal, {
    day,
    eventIdToClone
  });

  if (shouldHideModal) {
    yield put(hideModal());
  }

  if (createEvent) {
    yield put(showSnackbar({ message: "Creating event..." }));

    const { payload: event } = yield call(Api.addEvent, credentials, {
      orgId
    });
    window.location = `/event-light/${event.id}/dashboard`;
  }
};

const cloneEvent = function*({ payload: id }) {
  yield call(handleAddEventModal, {
    payload: {
      eventIdToClone: id
    }
  });
};

const deleteEvent = function*({ payload: id }) {
  try {
    const { orgId, credentials } = yield call(getParams);

    yield call(Api.deleteEvent, {
      orgId,
      eventId: id,
      credentials
    });

    yield put(showSnackbar({ message: "Event deleted" }));

    yield call(fetchEvents);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred deleting event"
        }
      ])
    );
  }
};

const editEvent = function*({ payload: { id } }) {
  yield put(push(`/event-light/${id}/details`));
  /*
  if (isDraft) {
    window.location = `/event/${id}/setup`;
  } else {
    window.location = `/event/${id}/settings`;
  }
  */
};

const viewEvent = function*({ payload: { id } }) {
  window.location = `/event-light/${id}/dashboard`;
  /*
  if (isDraft) {
    window.location = `/event/${id}/setup`;
  } else {
    window.location = `/event/${id}/settings`;
  }
  */
};

const goToMyTasks = function*() {
  const orgId = yield select(getOrgId);

  yield put(push(`/organization/${orgId}/my-tasks`));
};

const goToSchedule = function*({ payload: { eventId, scheduleId } }) {
  window.location = `/event/${eventId}/schedule/${scheduleId}`;
};

const showAddRecordModal = function*() {
  const handleCreate = yield call(makeFuture);
  yield put(
    showModal({
      content: (
        <AddRecordModal
          typeId={STANDARD_MODULES.schedules.id}
          moduleId="master"
          onSave={handleCreate.done}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleCreate.onRealized);
};

const handleShowAddRecordModal = function*() {
  yield call(showAddRecordModal);
  yield call(fetchTasks);
};

const toggleTask = function*({ payload: { task, toggled } }) {
  try {
    const credentials = yield select(getCredentials);

    yield call(Api.addValues, {
      credentials,
      data: {
        fieldId: STANDARD_MODULE_FIELD_IDS.SCHEDULES.COMPLETE,
        recordId: task.id,
        value: {
          type: "boolean",
          value: toggled
        }
      }
    });
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred saving task"
        }
      ])
    );
  }
};

const viewTask = function*({ payload: task }) {
  yield put(
    ActivityDetailsActions.openActivity({
      activityId: task.id,
      scheduleId: task.schedule_id
    })
  );
};

const goToAllEvents = function*() {
  const orgId = yield select(getOrgId);

  yield put(push(`/org-light/${orgId}/events`));
};

const goToAllEventsCalendar = function*() {
  const orgId = yield select(getOrgId);

  yield put(push(`/org-light/${orgId}/events?calendar=1`));
};

const goToEvent = function*({ payload: { id } }) {
  yield put(push(`/event-light/${id}/dashboard`));
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchHandleAddEventModal = function*() {
  yield takeEvery(actions.handleAddEventModal.type, handleAddEventModal);
};

const watchDeleteEvent = function*() {
  yield takeEvery(actions.deleteEvent.type, deleteEvent);
};

const watchCloneEvent = function*() {
  yield takeEvery(actions.cloneEvent.type, cloneEvent);
};

const watchEditEvent = function*() {
  yield takeEvery(actions.editEvent.type, editEvent);
};

const watchViewEvent = function*() {
  yield takeEvery(actions.viewEvent.type, viewEvent);
};

const watchGoToMyTasks = function*() {
  yield takeEvery(actions.goToMyTasks.type, goToMyTasks);
};

const watchGoToSchedule = function*() {
  yield takeEvery(actions.goToSchedule.type, goToSchedule);
};

const watchAddRecordModal = function*() {
  yield takeEvery(
    actions.handleShowAddRecordModal.type,
    handleShowAddRecordModal
  );
};

const watchToggleTask = function*() {
  yield takeEvery(actions.toggleTask.type, toggleTask);
};

const watchGoToAllEvents = function*() {
  yield takeEvery(actions.goToAllEvents.type, goToAllEvents);
};

const watchGoToAllEventsCalendar = function*() {
  yield takeEvery(actions.goToAllEventsCalendar.type, goToAllEventsCalendar);
};

const watchViewTask = function*() {
  yield takeEvery(actions.viewTask.type, viewTask);
};

const watchGoToEvent = function*() {
  yield takeEvery(actions.goToEvent.type, goToEvent);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchHandleAddEventModal),
    fork(watchDeleteEvent),
    fork(watchCloneEvent),
    fork(watchEditEvent),
    fork(watchViewEvent),
    fork(watchGoToMyTasks),
    fork(watchGoToSchedule),
    fork(watchAddRecordModal),
    fork(watchToggleTask),
    fork(watchGoToAllEvents),
    fork(watchGoToAllEventsCalendar),
    fork(watchViewTask),
    fork(watchGoToEvent)
  ]);
};

export default rootSaga;
