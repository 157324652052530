import { createModule } from "redux-mvc";

import model from "./model";

import table from "ui-kit/Table";

import sagasModule from "Portal/ViewPortalAsModal/sagas";

const module = createModule(model);

module.plugModule(table);
module.setRootSaga(sagasModule);

export default module;
