import * as R from "ramda";
import React from "react";

import {
  Input,
  TextAreaInput,
  PhoneInput,
  CheckboxInput,
  Dropdown,
  Lookup,
  File,
  Toggle,
  AttendeePhoto,
  ColorPicker,
  User
} from "ui-kit/Form/View";

import { noop } from "utils/General";

import { getValue, getField, getSettings } from "../../utils";

import { VARIANTS } from "ui-kit/Theme";

import { MoneyIcon } from "components/Base";

import { ACCEPT_UPLOAD_FILE } from "utils/file-types-upload";

const resolveEditor = {
  textarea: TextAreaInput,
  phone: PhoneInput,
  checkbox: CheckboxInput,
  dropdown: Dropdown,
  lookup: Lookup,
  file: File,
  "attendee-photo": AttendeePhoto,
  toggle: Toggle,
  color: ColorPicker,
  user: User
};

const getInputProps = (props = {}) => {
  const settings = getSettings(props);
  return R.filter(
    R.compose(
      R.not,
      R.isNil
    ),
    {
      "data-cy": props["data-cy"],
      instanceId: props.instanceId,
      iniValue: R.propOr(R.always(props.value), props.type, {
        textarea: () => props.value || "",
        "attendee-photo": () => ({
          url: R.prop("photoUrl", props.value) || ""
        }),
        file: () => R.propOr([], "files", props.value),
        currency: () => parseFloat(R.replace(/\$/g, "", props.value)),
        dropdown: () =>
          settings.allowMultipleSelect
            ? R.map(
                ({ id: value }) => ({ value }),
                R.pathOr([], ["value", "records"], props)
              )
            : { value: R.pathOr("", ["value", "records", 0, "id"], props) }
      })(),
      multipleSelect: R.prop(props.type, {
        dropdown: settings.allowMultipleSelect,
        user: settings.multi
      }),
      options: R.propOr(noop, props.type, {
        dropdown: () =>
          R.map(
            ({ id, value: label, textColor, backgroundColor }) => ({
              id,
              label,
              textColor,
              backgroundColor
            }),
            settings.options || []
          ),
        file: () => ({
          multiple: R.propOr(false, "allowMultiple", settings),
          accept: R.compose(
            R.ifElse(R.isEmpty, () => undefined, R.identity),
            R.flatten,
            R.map(({ value }) => R.propOr("", value, ACCEPT_UPLOAD_FILE)),
            R.propOr([], "allowedFileTypes")
          )(settings)
        })
      })(),
      fieldId: props.id,
      variant: R.propOr(VARIANTS.BACKGROUND, props.type, {
        toggle: VARIANTS.PRIMARY
      }),
      type: R.prop(props.type, {
        email: "email",
        number: "number",
        currency: "number",
        percent: "number",
        password: "password",
        phone: settings.phoneDisplayType
      }),
      LeftIcon: R.prop(props.type, {
        currency: MoneyIcon
      }),
      disabled: props.disabled,
      autoFocus: props.autoFocus,
      small: R.prop(props.type, {
        file: props.small
      }),
      autocomplete: R.prop(props.type, {
        text: `autocomplete_${props.id}`
      }),
      withoutPopover: R.prop(props.type, {
        file: true
      }),
      style: R.prop(props.type, {
        textarea: { width: "100%", resize: "vertical" }
      }),
      maxRows: R.prop(props.type, {
        textarea: 20
      }),
      minRows: R.prop(props.type, {
        textarea: 4
      }),
      maxLength: R.prop(props.type, {
        textarea: R.pathOr("", ["field", "settings", "maxLength"], props),
        phone: R.pathOr("", ["field", "settings", "maxLength"], props),
        text: R.pathOr("", ["field", "settings", "maxLength"], props)
      }),
      usePortal: R.prop(props.type, {
        dropdown: true
      }),
      width: R.prop(props.type, {
        dropdown: 234
      })
    }
  );
};

const DefaultEditor = props => {
  const value = getValue(props, R.identity, "");
  const field = getField(props);

  return React.createElement(
    resolveEditor[field.type] || Input,
    getInputProps({
      ...props,
      ...field,
      value
    })
  );
};

export default DefaultEditor;
