import React from "react";
import { CloseIcon, Div } from "components/Base";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

export const BaseHeader = ({ hideModal, heading, ...props }) => (
  <Div
    py={4}
    px={5}
    bg="neutral8"
    display="row.space-between.center"
    {...props}
    style={{
      flexShrink: 0
    }}
  >
    <Div fw={4} fs={5} flex={1} bold color="white">
      {heading}
    </Div>
    {hideModal && <CloseIcon size={35} color="white" onClick={hideModal} />}
  </Div>
);

export const DefaultHeader = CSSModules(
  ({ heading, hideModal }) => (
    <div
      styleName="header"
      style={{
        flexShrink: 0
      }}
    >
      <div styleName="title">{heading}</div>
      {hideModal && (
        <div onClick={hideModal}>
          <i className="material-icons">close</i>
        </div>
      )}
    </div>
  ),
  css
);
