import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../model";

import {
  Div,
  Text3,
  Text5,
  BigFilledButton,
  DownloadIcon
} from "components/Base";

import Table, { TableWrapper } from "ui-kit/Table/View";
import Loading from "ui-kit/Loading";

import { getMealSummaryValues } from "../selectors";
import { INSTANCE_ALL_ITEMS_TABLE } from "../constants";

const decorate = R.compose(
  connect(
    state => ({
      loading: getters.loading(state),
      mealsData: getMealSummaryValues(state)
    }),
    {
      init: actions.init,
      onClickToPrint: actions.onClickToPrint
    }
  )
);

const Layout = ({ loading, mealsData, init, onClickToPrint }) => {
  useEffect(() => {
    init();
  }, []);
  return (
    <Div p={4} bg="white" mb={4} width={1}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Div display="row.space-between.center" pb={3} width={1}>
            <Text5 bold>Meals Report</Text5>
            <BigFilledButton
              className="no-print"
              disabled
              onClick={onClickToPrint}
              pill
              LeftIcon={DownloadIcon}
            >
              Print
            </BigFilledButton>
          </Div>
          {R.map(
            meals => (
              <Div
                display="column.flex-start.stretch"
                width={1}
                bc="neutral2"
                bb={2}
                py={5}
                mb={4}
              >
                <Text5 bold mb={4}>
                  {meals.title}
                </Text5>
                <Div bra={1} bc="neutral2" ba={1} bl={3} p={3} pb={1} mb={5}>
                  <Text3 bold pl={2} mb={2}>
                    Summary
                  </Text3>
                  <div styleName="requestTable">
                    <TableWrapper style={{ flex: 1 }} instanceId={meals.id}>
                      <Table instanceId={meals.id} showCheckbox={false} />
                    </TableWrapper>
                  </div>
                </Div>
                <Div bra={1} bc="neutral2" ba={1} bl={1} p={3} pb={1} mb={5}>
                  <div styleName="requestTable">
                    <TableWrapper
                      style={{ flex: 1 }}
                      instanceId={`${INSTANCE_ALL_ITEMS_TABLE}-${meals.id}`}
                    >
                      <Table
                        instanceId={`${INSTANCE_ALL_ITEMS_TABLE}-${meals.id}`}
                        showCheckbox={false}
                      />
                    </TableWrapper>
                  </div>
                </Div>
              </Div>
            ),
            mealsData
          )}
        </>
      )}
    </Div>
  );
};

export default decorate(Layout);
