import PropTypes from "prop-types";
import React, { Component } from "react";
import Icon from "./Icon";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class UploadButton extends Component {
  render() {
    return (
      <div styleName="container" onClick={this.props.onClick}>
        <Icon />
        {this.props.children}
      </div>
    );
  }
}

UploadButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired
};

export default UploadButton;
