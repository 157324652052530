import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { noop } from "utils/General";

import { actions } from "ui-kit/Form/model";
import { getValue, getDropdownOptions } from "ui-kit/Form/selectors";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";
import { wsp } from "ui-kit/Theme";

import AsyncSelect from "@lennd/react-select/lib/Async";

const decorate = R.compose(
  WithFormInstanceConsumer({ fieldType: FIELD_TYPES.DROPDOWN_ASYNC }),
  connect(
    (state, props) => ({
      value: getValue(state, props),
      options: getDropdownOptions(state, props)
    }),
    bindInstance({
      onChange: actions.setFieldValue,
      setIniValue: actions.setIniValue,
      onBlur: actions.blurField,
      loadAsyncOptions: actions.dataRequest
    })
  )
);

export const UCDropdownAsync = ({
  value = "",
  iniValue,
  fieldId,
  fieldType,
  multipleSelect = false,
  usePortal,
  loadAsyncOptions = noop,
  width,
  onChange = noop,
  setIniValue = noop,
  onBlur = noop,
  bg = "white",
  placeholder = "Type to search values...",
  ...props
}) => {
  useEffect(() => {
    setIniValue(iniValue, {
      meta: { fieldType, fieldId, multipleSelect }
    });
  }, []);

  const styles = {
    control: provided => ({
      ...provided,
      backgroundColor: bg
    })
  };
  if (usePortal) {
    styles.menu = provided => ({ ...provided, zIndex: 9999 });

    styles.menuPortal = styles => ({
      ...styles,
      zIndex: 9999
    });
  }

  if (width) {
    styles.container = provided => ({
      ...provided,
      width
    });
  }
  return (
    <AsyncSelect
      isClearable
      cacheOptions
      defaultOptions
      isMulti={multipleSelect}
      placeholder={placeholder}
      loadOptions={(searchValue, callback) =>
        loadAsyncOptions(searchValue, {
          meta: {
            fieldId,
            callback
          }
        })
      }
      menuPortalTarget={usePortal ? document.body : undefined}
      onChange={val =>
        onChange(val, {
          meta: { fieldType, fieldId, multipleSelect }
        })
      }
      onBlur={() => onBlur(null, { meta: { fieldId, fieldType } })}
      value={value}
      styles={styles}
      {...wsp(props)}
    />
  );
};

export const DropdownAsync = decorate(UCDropdownAsync);
