/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  getRecordings: ({ credentials, eventId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/virtual/event/${eventId}/recordings`,
        credentials,
        success,
        error
      });
    }),
  getRecordingUrl: ({ credentials, url, qs = {} }) =>
    new Promise((success, error) => {
      Helpers.request({
        skipUserId: true,
        method: "get",
        url,
        qs,
        credentials,
        success,
        error
      });
    })
};
