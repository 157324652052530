import React from "react";
import { connect } from "react-redux";

import ImportCopyButtons from "ui-kit/ImportCopyButtons";

import { actions } from "Orders/ImportModal";

import { WIZARD_PAGE } from "Orders/ImportModal/constants";

import { noop } from "utils/General";

const decorate = connect(
  null,
  {
    uploadFile: () => actions.uploadFile(),
    goToGenerateTemplate: () => actions.setWizardPage(WIZARD_PAGE.CUSTOMIZE)
  }
);

const ImportPage = ({ uploadFile = noop, goToGenerateTemplate = noop }) => (
  <ImportCopyButtons
    showImport
    showCopy
    importText="Upload a file to import"
    importCaption="Choose a file..."
    copyText="Generate Import Template"
    copyCaption="This will allow you to customize the template you need to import."
    uploadFile={uploadFile}
    goToCopyPaste={goToGenerateTemplate}
  />
);

export default decorate(ImportPage);
