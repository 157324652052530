import * as R from "ramda";
import React from "react";
import moment from "moment";

import { getValue, getSettings } from "../../utils";

const Date = props => {
  const value = getValue(props);
  const format = getSettings(props, "dateFormat", "MM/DD/YY hh:mm a");

  if (R.isNil(value) || R.isEmpty(value)) {
    return "";
  }

  const date = moment(value);

  if (moment.isMoment(date)) {
    return <div>{moment(value).format(format)}</div>;
  }
  return <div>{value}</div>;
};

export default Date;
