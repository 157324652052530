import React from "react";
import * as R from "ramda";
import { Div } from "components/Base";

const EventLines = ({ events = [] }) => (
  <Div display="column.flex-start.flex-start">
    {R.map(
      ({ id, color }) => (
        <Div
          key={id}
          bg={color}
          style={{ height: 2, marginBottom: 1 }}
          width={1}
        />
      ),
      events
    )}
  </Div>
);

export default EventLines;
