import Immutable from "immutable";
import { combineReducers } from "redux";
import { RECEIVE, REQUEST } from "./constants";
import {
  CREATE as CREATE_APPROVAL,
  BULK_CREATE as BULK_CREATE_APPROVAL
} from "redux/modules/approvalWorkflows/reviews/constants";
import {
  CREATE as CREATE_VALUE,
  BULK_CREATE as BULK_CREATE_VALUE
} from "redux/modules/modules/values/constants";
import { ERROR } from "redux/modules/errors/constants";
import settings from "./settings/reducer";
import * as SYSTEM_FIELD_IDS from "utils/system-field-ids";
import * as R from "ramda";
import formatApprovalManifestForRecord from "utils/format-approval-manifest-for-record";

const updateValue = (
  state,
  immutableState,
  { moduleId, recordId, fieldId, value }
) => {
  if (!state[moduleId]) return immutableState;

  const indexOfRecord = state[moduleId].records.findIndex(
    r => r.id === recordId
  );

  if (indexOfRecord === -1) {
    return immutableState;
  }

  return immutableState.setIn(
    [moduleId, "records", indexOfRecord, "values", fieldId],
    value
  );
};

const updateValues = (state, immutableState, moduleId, values) =>
  values.reduce(
    (map, { recordId, fieldId, value }) =>
      updateValue(state, map, {
        moduleId,
        recordId,
        fieldId,
        value
      }),
    immutableState
  );

const updateApprovalValue = (
  state,
  immutableState,
  { moduleId, recordId, fieldId, userId, status }
) => {
  if (!state[moduleId]) return immutableState;

  const indexOfRecord = state[moduleId].records.findIndex(
    r => r.id === recordId
  );

  if (indexOfRecord === -1) return immutableState;

  const manifest = state[moduleId].records[indexOfRecord].values[fieldId].value;

  if (!manifest) return immutableState;

  const approverIndex = R.findIndex(R.propEq("user_id", userId))(
    manifest.all_approvers
  );

  if (approverIndex === -1) return immutableState;

  const formatted = formatApprovalManifestForRecord({
    approval_manifest: {
      id: manifest.id,
      approvers: R.compose(
        R.set(R.lensPath([approverIndex, "reviewed_at"]), new Date()),
        R.set(R.lensPath([approverIndex, "review"]), status)
      )(manifest.all_approvers)
    }
  });

  return immutableState.setIn(
    [moduleId, "records", indexOfRecord, "values", fieldId],
    {
      type: "approval",
      value: formatted
    }
  );
};

const updateApprovalValues = (state, immutableState, moduleId, values) =>
  values.reduce(
    (map, { recordId, fieldId, userId, status }) =>
      updateApprovalValue(state, map, {
        moduleId,
        recordId,
        fieldId,
        userId,
        status
      }),
    immutableState
  );

const records = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn([action.payload.moduleId], action.payload.records)
        .toJS();

    case CREATE_VALUE: {
      return updateValue(state, Immutable.fromJS(state), {
        moduleId: action.payload.moduleId,
        recordId: action.payload.recordId,
        fieldId: action.payload.fieldId,
        value: action.payload.value
      }).toJS();
    }

    case BULK_CREATE_VALUE: {
      return updateValues(
        state,
        Immutable.fromJS(state),
        action.payload.moduleId,
        action.payload.values
      ).toJS();
    }

    case CREATE_APPROVAL: {
      return updateApprovalValue(state, Immutable.fromJS(state), {
        moduleId: action.payload.moduleId,
        recordId: action.payload.recordId,
        fieldId: SYSTEM_FIELD_IDS.APPROVAL,
        userId: action.payload.userId,
        status: action.payload.status
      }).toJS();
    }

    case BULK_CREATE_APPROVAL: {
      return updateApprovalValues(
        state,
        Immutable.fromJS(state),
        action.payload.moduleId,
        action.payload.reviews.map(({ userId, recordId, response }) => ({
          recordId,
          fieldId: SYSTEM_FIELD_IDS.APPROVAL,
          userId,
          status: response
        }))
      ).toJS();
    }

    default:
      return state;
  }
};

const fetching = (state = {}, action) => {
  switch (action.type) {
    case REQUEST:
      return Immutable.fromJS(state)
        .setIn([action.payload.moduleId], true)
        .toJS();
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn([action.payload.moduleId], false)
        .toJS();
    case ERROR:
      return {};
    default:
      return state;
  }
};

export default combineReducers({
  records,
  settings,
  fetching
});
