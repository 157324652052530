import React from "react";
import { Div, PopoverMenu } from "components/Base";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import * as R from "ramda";
import css from "Portal/PortalPagePGA/PortalPagePGA/View/styles.scss";
import CSSModules from "react-css-modules";
import { eventId as getEventId } from "redux/modules/portal/selectors";

const decorator = connect(
  (state, props) => ({
    players: getters.players(state, props),
    sortBy: getters.sortBy(state, props),
    player: getters.player(state, props),
    eventId: getEventId(state)
  }),
  {
    setPlayer: actions.setPlayer,
    setSortBy: actions.setSortBy
  }
);

const Filters = ({
  eventId,
  players,
  sortBy,
  setPlayer,
  player,
  setSortBy
}) => (
  <Div
    display="row.flex-start.center"
    style={{
      marginBottom: "32px"
    }}
  >
    {[
      2652,
      2941,
      3136,
      3219,
      3361,
      3362,
      3207,
      3328,
      3327,
      // 3494,
      // 3495,
      3503,
      3552
    ].includes(eventId) ? (
      <PopoverMenu
        Label={({ onClick }) => (
          <Div
            display="row.center.center"
            mr={3}
            bg={{
              default: "inherit",
              hover: "#E2E2E2"
            }}
            color={{
              default: "#1789EB",
              hover: "#222222"
            }}
            onClick={onClick}
            uppercase
            style={{
              height: "37px",
              minWidth: "117px",
              padding: "0 19px",
              border: "1px solid #E6E6E6",
              borderRadius: "6px",
              fontFamily: "Roboto Condensed",
              letterSpacing: "0.4px",
              fontSize: "14px"
            }}
          >
            {player === "all_players" ? "all players" : player}
          </Div>
        )}
        menuItems={[
          ["View all players", () => setPlayer("all_players")],
          ...R.map(player => [player, () => setPlayer(player)], players)
        ]}
      />
    ) : null}
    <PopoverMenu
      Label={({ onClick }) => (
        <Div
          display="row.center.center"
          mr={3}
          bg={{
            default: "inherit",
            hover: "#E2E2E2"
          }}
          color={{
            default: "#1789EB",
            hover: "#222222"
          }}
          onClick={onClick}
          uppercase
          style={{
            height: "37px",
            minWidth: "88px",
            padding: "0 19px",
            border: "1px solid #E6E6E6",
            borderRadius: "6px",
            fontFamily: "Roboto Condensed",
            letterSpacing: "0.4px",
            fontSize: "14px"
          }}
        >
          {sortBy}
        </Div>
      )}
      menuItems={[
        ["Show newest videos first", () => setSortBy("newest")],
        ["Show oldest videos first", () => setSortBy("oldest")]
      ]}
    />
  </Div>
);

export default CSSModules(decorator(Filters), css);
