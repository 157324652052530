const FILE_FIELDS = [
  {
    id: "ea87516b-d6ca-40fc-9b17-e93b128f587b",
    name: "Title",
    internal_name: "title",
    type: "text",
    settings: {},
    source: "standard",
    is_primary_field: true,
    order: 0
  },
  {
    id: "d87fd0b2-a84e-4f70-92be-864b457115dc",
    name: "Description",
    internal_name: "description",
    type: "textarea",
    settings: {},
    source: "standard",
    order: 1
  },
  {
    id: "8fc10190-5c45-42de-8c9b-6a9c2e003b36",
    name: "Filename",
    internal_name: "filename",
    type: "text",
    settings: {},
    source: "standard",
    order: 2
  },
  {
    id: "f26fe10c-993d-485f-aa85-110dd748a66a",
    name: "URL",
    internal_name: "url",
    type: "text",
    settings: {},
    source: "standard",
    order: 3
  },
  {
    id: "250b390a-8307-4130-bb12-3df6e8ea0af3",
    name: "File Type",
    internal_name: "mimetype",
    type: "text",
    settings: {},
    source: "standard",
    order: 4
  }
];

const DOCUMENT_REQUEST_FIELDS = [
  {
    id: "622bc45b-1785-458f-b0cb-de40c9cde5f4",
    name: "Name",
    internal_name: "request_name",
    type: "text",
    settings: {
      defaultWidth: 300
    },
    source: "standard",
    is_primary_field: true,
    order: 0
  },
  {
    id: "511bc45b-1785-458f-b0cb-de40c9cde5f5",
    name: "Status",
    internal_name: "request_revision_status",
    type: "dropdown",
    settings: {
      allowMultipleSelect: false,
      options: [
        {
          value: "Not Submitted",
          backgroundColor: "rgb(146,146,146)",
          textColor: "#FFFFFF"
        },
        {
          id: "ee2361c6-f366-4731-8f9a-23b0f45f4a43",
          value: "Pending",
          backgroundColor: "rgb(231,135,64)",
          textColor: "#FFFFFF"
        },
        {
          id: "a9693eca-935a-419d-b681-da317fddb003",
          value: "Approved",
          backgroundColor: "rgb(78,182,136)",
          textColor: "#FFFFFF"
        },
        {
          id: "67e6098a-9e2b-4672-8202-18f8968699ca",
          value: "Denied",
          backgroundColor: "rgb(219,87,75)",
          textColor: "#FFFFFF"
        }
      ]
    },
    source: "standard",
    order: 1
  },
  {
    id: "681bc45b-1785-458f-b0cb-de40c9cde5f5",
    name: "Request Type",
    internal_name: "request_type_name",
    type: "text",
    settings: {},
    source: "standard",
    order: 2
  },
  {
    id: "updated_at",
    key: "updated_at",
    name: "Last updated",
    source: "standard",
    settings: {},
    type: "date",
    order: 3
  },
  {
    id: "created_at",
    key: "created_at",
    name: "Created At",
    source: "standard",
    settings: {},
    type: "date",
    order: 4
  }
];

export { FILE_FIELDS, DOCUMENT_REQUEST_FIELDS };
