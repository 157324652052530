import * as R from "ramda";
import isEmpty from "utils/value-types/is-empty";

export const getSettings = (props, key, def) => {
  const {
    row: { meta }
  } = props;

  const columnId = R.path(["value", "columnId"], props);
  const column = R.isNil(columnId)
    ? R.propOr({}, "column", props)
    : R.pathOr({}, ["columns", columnId], meta);

  const settings = R.propOr({}, "settings", column);
  if (typeof key === "undefined") {
    return settings;
  }

  if (typeof def === "undefined") {
    if (Array.isArray(key)) {
      return R.path(key, settings);
    }
    return R.prop(key, settings);
  }
  if (Array.isArray(key)) {
    return R.pathOr(def, key, settings);
  }
  return R.propOr(def, key, settings);
};

const makeGet = getter => (props, key, def) => {
  const val = getter(props);

  if (typeof key === "undefined" || key === R.identity) {
    if (typeof def !== "undefined") {
      return val || def;
    }
    return val;
  }

  if (typeof def === "undefined") {
    if (Array.isArray(key)) {
      return R.path(key, val);
    }
    return R.prop(key, val);
  }
  if (Array.isArray(key)) {
    return R.pathOr(def, key, val);
  }
  return R.propOr(def, key, val);
};

export const getMeta = makeGet(R.path(["row", "meta"]));
export const getCellValue = makeGet(R.path(["value", "value"]));
export const getCellType = makeGet(R.path(["value", "type"]));
export const getHandlers = makeGet(R.path(["row", "handlers"]));
export const getRow = makeGet(R.prop("row"));

export const ep = props => ({ ...props, row: props.rowData });

export const getRowMetadata = getRow;

const isRowEmpty = (row, columns) => {
  return R.all(
    field => isEmpty(row[field.id], field.type),
    R.values(R.pick(R.keys(row), columns))
  );
};

export const getIsRowEmpty = props =>
  isRowEmpty(props.row, R.path(["row", "meta", "columns"], props));

export const getIsRowDraft = props =>
  R.pathOr(false, ["row", "is_draft"], props);
