import React from "react";
import { connect } from "react-redux";

import { getters } from "Orders/OrderModal";
import {
  getOrderNumber,
  getOrderLabel,
  getIsSubmission,
  getParentOrderNumber,
  getParentOrderId
} from "Orders/OrderModal/selectors";

import * as TableSelectors from "ui-kit/Datagrid/selectors";

import {
  getSubmissionRecordFriendlyId,
  getFormName
} from "Submission/Editor/selectors";

import Body from "Orders/OrderModal/View/Body";
import OrderSidebar from "Orders/OrderModal/View/OrderSidebar";
import SubmissionSidebar from "Orders/OrderModal/View/SubmissionSidebar";

import { noop } from "utils/General";

import {
  LeftIcon,
  Div,
  Text5,
  DarkCloseButton,
  LoadingIcon,
  QuarterLeftTemplate,
  Fill
} from "components/Base";

const Frame = ({ children }) => (
  <Div m={5} display={"row.center.center"}>
    <Div
      display="row.flex-start.stretch"
      bra={2}
      shadow={2}
      bg="white"
      style={{
        height: 750,
        maxWidth: 1200,
        width: "100%",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxHeight: "calc(100% - 100px)"
      }}
      onMouseDown={e => e.stopPropagation()}
    >
      {children}
    </Div>
  </Div>
);

const templatePadding = {
  px: 5,
  py: 3
};

const RecordView = ({
  title,
  loading = false,
  reloading = false,
  hideModal = noop,
  leftContent,
  middleContent
}) => {
  if (loading) {
    return (
      <Frame>
        <Div display="row.center.center" flex={1} bg="neutral1">
          <LoadingIcon size={60} color="neutral4" />
        </Div>
      </Frame>
    );
  }
  return (
    <Frame>
      <QuarterLeftTemplate
        wrapperProps={{ flex: 1 }}
        leftProps={{
          br: 1,
          bg: "neutral0",
          bc: "neutral3",
          style: {
            overflowY: "auto"
          }
        }}
        leftChildren={[title, leftContent]}
        rightChildren={[
          <Fill key={2} style={{ position: "relative" }} height={1} width={1}>
            {reloading ? (
              <LoadingIcon
                size={30}
                color="neutral4"
                style={{ position: "absolute", top: 10, right: 10 }}
              />
            ) : (
              <DarkCloseButton
                size={30}
                onClick={hideModal}
                style={{ position: "absolute", top: 10, right: 10 }}
              />
            )}
            {middleContent}
          </Fill>
        ]}
      />
    </Frame>
  );
};

const Title = ({ title, parentOrderLink, orderLabel }) => (
  <Div
    height={130}
    display="column.flex-end.flex-start"
    bg="primary8"
    style={{ flexShrink: 0 }}
    {...templatePadding}
  >
    {parentOrderLink}
    <Div
      bg="primary5"
      display="row.center.center"
      color="white"
      p={1}
      fs={1}
      fw={3}
      bra={1}
      mb={2}
      bold
      uppercase
    >
      {orderLabel}
    </Div>
    <Text5 mb={3} bold display="row.flex-start.center" color="white" bold>
      {title}
    </Text5>
  </Div>
);

const decorate = connect(state => ({
  loading: getters.loading(state),
  reloading: getters.reloading(state),
  reloadingTable: TableSelectors.getLoading(state),
  orderNumber: getOrderNumber(state),
  parentOrderNumber: getParentOrderNumber(state),
  parentOrderId: getParentOrderId(state),
  orderLabel: getOrderLabel(state),
  isSubmission: getIsSubmission(state),
  submissionRecordId: getSubmissionRecordFriendlyId(state),
  formName: getFormName(state)
}));

const Layout = ({
  handlers,
  orderNumber,
  loading,
  reloading,
  reloadingTable,
  orderLabel,
  isSubmission,
  submissionRecordId,
  parentOrderNumber,
  parentOrderId,
  formName
}) => {
  if (isSubmission) {
    return (
      <RecordView
        title={
          <Title
            parentOrderLink={
              parentOrderId ? (
                <Div
                  display="row.flex-start.center"
                  fs={1}
                  color="white"
                  mb={4}
                  onClick={() => handlers.showOrderModal(parentOrderId)}
                >
                  <LeftIcon color="white" fs={2} />
                  Order #{parentOrderNumber}
                </Div>
              ) : null
            }
            orderLabel={formName}
            title={`Submission #${submissionRecordId}`}
          />
        }
        middleContent={<Body handlers={handlers} />}
        leftContent={<SubmissionSidebar handlers={handlers} />}
        loading={loading}
        reloading={reloading || reloadingTable}
        hideModal={handlers.hideModal}
      />
    );
  }

  return (
    <RecordView
      title={
        <Title
          parentOrderLink={
            parentOrderId ? (
              <Div
                display="row.flex-start.center"
                fs={1}
                color="white"
                mb={4}
                onClick={() => handlers.showOrderModal(parentOrderId)}
              >
                <LeftIcon color="white" fs={2} />
                Order #{parentOrderNumber}
              </Div>
            ) : null
          }
          orderLabel={orderLabel}
          title={`Order #${orderNumber}`}
        />
      }
      middleContent={<Body handlers={handlers} />}
      leftContent={<OrderSidebar handlers={handlers} />}
      loading={loading}
      reloading={reloading}
      hideModal={handlers.hideModal}
    />
  );
};

export default decorate(Layout);
