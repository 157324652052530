import React from "react";
import { connect } from "react-redux";
import { Dropdown } from "components/Base";
import { withProps } from "utils/General";

import {
  getAccountFields,
  getAccountRecordTypes,
  getContactFields,
  getContactRecordTypes
} from "Integrations/FuzionMappingModal/selectors";

const decorate = connect(state => ({
  accountFields: getAccountFields(state),
  accountRecordTypes: getAccountRecordTypes(state),
  contactFields: getContactFields(state),
  contactRecordTypes: getContactRecordTypes(state)
}));

const RecordsDropdown = withProps({
  usePortal: true
})(Dropdown);

const AccountFieldDropdown = decorate(
  ({ accountFields, path, value, updateValue }) => {
    return (
      <RecordsDropdown
        width={250}
        options={[
          {
            value: null,
            label: "(Do not map)"
          },
          ...accountFields
        ]}
        selected={value}
        onChange={({ value }) => updateValue({ path, value })}
      />
    );
  }
);

const AccountRecordTypeDropdown = decorate(
  ({ accountRecordTypes, path, value, updateValue }) => {
    return (
      <RecordsDropdown
        width={190}
        options={[
          {
            value: null,
            label: "(Use Default)"
          },
          ...accountRecordTypes
        ]}
        selected={value}
        onChange={({ value }) => updateValue({ path, value })}
      />
    );
  }
);

const ContactFieldDropdown = decorate(
  ({ contactFields, path, value, updateValue }) => {
    return (
      <RecordsDropdown
        width={250}
        options={[
          {
            value: null,
            label: "(Do not map)"
          },
          ...contactFields
        ]}
        selected={value}
        onChange={({ value }) => updateValue({ path, value })}
      />
    );
  }
);

const ContactRecordTypeDropdown = decorate(
  ({ contactRecordTypes, path, value, updateValue }) => {
    return (
      <RecordsDropdown
        width={190}
        options={[
          {
            value: null,
            label: "(Use Default)"
          },
          ...contactRecordTypes
        ]}
        selected={value}
        onChange={({ value }) => updateValue({ path, value })}
      />
    );
  }
);

export {
  AccountFieldDropdown,
  AccountRecordTypeDropdown,
  ContactFieldDropdown,
  ContactRecordTypeDropdown
};
