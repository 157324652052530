import React, { Component } from "react";
import File from "./File";
import getValue from "utils/value-types/get-value/file";
import { Div } from "components/Base";
import * as R from "ramda";

export default class FileCellFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props.value, nextProps.value);
  }

  formatValue(props) {
    return getValue(props.value);
  }

  render() {
    const { onFileClick } = this.props; // @NOTE: how the onFileClick prop gets here
    const value = this.formatValue(this.props);
    const filesAreClickable = this.props.mode === "edit"; // @NOTE: how the mode prop gets here

    return (
      <Div
        display="row.flex-start.center"
        style={{
          overflow: "hidden"
        }}
      >
        {value.map((file, index) => (
          <File
            onClick={() => onFileClick(file, value)}
            key={index}
            isClickable={filesAreClickable}
            id={file.id}
            mimetype={file.mimetype}
            url={file.url}
            filename={file.filename}
          />
        ))}
      </Div>
    );
  }
}
