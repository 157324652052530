import React from "react";
import { connect } from "react-redux";

import { Div } from "components/Base";

import { actions, getters } from "Organizations/Events";

import Header from "Organizations/Events/View/Sidebar/Header";
import FieldFilter from "Organizations/Events/View/Sidebar/FieldFilter";
import SortBy from "Organizations/Events/View/Sidebar/SortBy";

import { getManageLinks } from "Organizations/Events/selectors";

import ToggleCalendarView from "./ToggleCalendarView";

const decorate = connect(
  (state, props) => ({
    manageLinks: getManageLinks(state, props),
    preferences: getters.preferences(state)
  }),
  { onToggleCollapsed: () => actions.toggleCollapse() }
);

const Sidebar = () => {
  return (
    <Div
      width={250}
      py={5}
      pl={4}
      pr={3}
      style={{
        backgroundColor: "#813CD2",
        minHeight: "100%"
      }}
      display="column.flex-start.stretch"
    >
      <Header />
      <ToggleCalendarView mb={2} />
      <SortBy mb={2} />
      <FieldFilter mb={2} />
      <Div flex={1} />
    </Div>
  );
};

export default decorate(Sidebar);
