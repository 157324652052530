import React from "react";
import { Div } from "components/Base";
import SelectFields from "./SelectFields";
import ShowFields from "./ShowFields";

const Body = ({ handlers }) => (
  <Div display="row.flex-start.center" flex={1}>
    <SelectFields handlers={handlers} />
    <ShowFields />
  </Div>
);

export default Body;
