import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getters, actions } from "Portal/Settings/AddFolderModal/model";
import { Div, Text2, BigOutlineInput } from "components/Base";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";

const decorate = connect(
  state => ({
    name: getters.name(state)
  }),
  {
    setName: actions.setName,
    saveFolder: actions.saveFolder,
    init: actions.init
  }
);

const AddFolderModal = ({ hideModal, name, setName, id, init, folderName }) => {
  useEffect(() => {
    init({ id, name: folderName });
  }, []);

  return (
    <MiniModalWrapper
      showModal
      hideModal={hideModal}
      title={`${id ? "Edit" : "Create"} Folder`}
      style={{ width: 320, height: 215 }}
      isPortal={false}
      bg="white"
      stopPropagation
    >
      <Div py={4} px={6} display="column.space-between" height={1}>
        <div>
          <Text2 color="black" bold mb={1}>
            Folder Name
          </Text2>
          <BigOutlineInput
            width={1}
            value={name}
            onChange={value => setName(value)}
            continuous
          />
        </div>
        {/* <Div display="row.space-between.center">
          <BigFilledButton
            bg="altB5"
            color="white"
            width={170}
            onClick={saveFolder}
            disabled={name.length < 2}
          >
            Create Folder
          </BigFilledButton>
          <BigOutlineButton onClick={hideModal}>Cancel</BigOutlineButton>
        </Div> */}
      </Div>
    </MiniModalWrapper>
  );
};

export default decorate(AddFolderModal);
