import * as R from "ramda";
import moment from "moment";

import countries from "components/Global/Editors/Countries/countries";
import constructName from "components/Global/Editors/Reference/utils/construct-name";
import constructPlaceholder from "components/Global/Editors/Reference/utils/construct-placeholder";
import isPerson from "components/Global/Editors/Reference/utils/is-person";

import { EDITOR_FORMAT_MAP } from "components/Global/Table3/ModalColumn/DateEditorSettings";

import { getSettings, getMeta, getCellValue } from "./getters";

const valueValue = R.pathOr("", ["value", "value"]);

const valueGetters = {
  countries: R.compose(
    R.propOr("", R.__, countries), //eslint-disable-line no-underscore-dangle
    valueValue
  ),
  approval: R.compose(
    status => (status === "rejected" ? "denied" : status),
    R.propOr("", "status"),
    valueValue
  ),
  dropdown: R.compose(
    R.map(R.propOr("", "value")),
    R.propOr("", "records"),
    valueValue
  ),
  dependency: R.compose(
    R.map(R.propOr("", "value")),
    R.propOr("", "records"),
    valueValue
  ),
  date: params => {
    const FALLBACK_FORMAT = "dddd, MMMM D, YYYY";
    let dateFormat = getSettings(params, "dateFormat");

    if (!dateFormat) {
      const displayType = getSettings(params, "displayType");
      if (displayType && displayType in EDITOR_FORMAT_MAP) {
        dateFormat = EDITOR_FORMAT_MAP[displayType];
      } else {
        dateFormat = FALLBACK_FORMAT;
      }
    }

    const date = getCellValue(params);
    return date ? moment(date).format(dateFormat) : "";
  },
  credentials: params => {
    const type = getSettings(params, "selectType");
    const records = getCellValue(params, "records", []);

    if (type === "quantity-only-input") {
      return R.pathOr("", ["0", "quantity"], records);
    }

    const credentials = R.indexBy(
      R.prop("id"),
      getMeta(
        params,
        ["eventDetails", "module_settings", "credentials", "credentials"],
        []
      )
    );
    return R.map(
      record => R.pathOr("", [record.id, "value"], credentials),
      records
    );
  },
  "item-block": params => {
    const type = getSettings(params, "selection-type");
    const items = getCellValue(params, "records", []);

    if (type === "quantity-only-input") {
      return R.pathOr("", ["0", "quantity"], items);
    }

    return R.map(R.prop("value"), items);
  },
  catering: R.compose(
    R.map(R.prop("name")),
    R.propOr([], "meals"),
    valueValue
  ),
  "catering-dietary-restrictions": params => {
    const restrictions = R.indexBy(
      R.prop("id"),
      getMeta(
        params,
        ["eventDetails", "module_settings", "catering", "dietary_restrictions"],
        []
      )
    );
    const records = getCellValue(params, "records", []);

    return R.map(
      record => R.pathOr("", [record.id, "value"], restrictions),
      records
    );
  },
  user: R.compose(
    R.map(R.propOr("", "name")),
    R.propOr([], "records"),
    valueValue
  ),
  file: R.compose(
    R.map(R.propOr("", "filename")),
    R.propOr([], "files"),
    valueValue
  ),
  "has-changes": R.compose(
    R.ifElse(R.identity, "yes", "no"),
    valueValue
  ),
  "action-approve-form-row": valueValue,
  reference: R.compose(
    R.ifElse(
      R.isNil,
      R.ifElse(
        isPerson,
        v => constructName(v.first, v.last),
        v => constructPlaceholder(v.placeholder, v.count)
      )
    ),
    valueValue
  ),
  textarea: valueValue,
  "catalog-item": params => {
    const options = R.indexBy(
      R.prop("id"),
      getSettings(params, "availableCatalogItems", [])
    );
    const records = getCellValue(params, "records", []);

    return R.map(
      record => R.pathOr("", [record.id, "value"], options),
      records
    );
  },
  "event-list": R.compose(
    R.map(R.propOr("", "name")),
    R.pathOr([], ["value", "value"])
  ),
  "account-list": R.compose(
    R.map(R.propOr("", "name")),
    R.pathOr([], ["value", "value"])
  ),
  text: valueValue,
  default: R.always("")
};

const getRawCellValue = params => {
  const getter =
    valueGetters[R.pathOr("default", ["value", "type"], params)] ||
    valueGetters.default;

  return getter(params);
};

export default getRawCellValue;
