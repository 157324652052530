import { put, all, takeEvery, fork, take, call } from "redux-saga/effects";
import { actions } from "Portal/PortalPage/model";
import * as R from "ramda";
import { PAGE_ACTIONS } from "./constants";

const init = function*({ payload: pageData }) {
  yield put(
    actions.setInitialData({
      ...pageData
    })
  );
};

const openLink = function*({ payload: { url } }) {
  yield call(window.open, url, "_blank");
};

const openAlert = function*() {
  // @TODO: When you click on it, should go to a page {PORTAL_URL}/alerts (not developed yet)
};

const openSession = function*() {
  // @TODO: When you click on it, open session
};

const watchPageActions = function*() {
  for (;;) {
    const action = yield take(actions.executeAction.type);
    const delegate = R.prop(action.payload.type, {
      [PAGE_ACTIONS.OPEN_LINK]: openLink,
      [PAGE_ACTIONS.OPEN_ALERT]: openAlert,
      [PAGE_ACTIONS.OPEN_SESSION]: openSession
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([fork(watchInit), fork(watchPageActions)]);
};

export default rootSaga;
