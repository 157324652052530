import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import { PackageContainer } from "Sponsors/Common/View";
import { Text3, Text2 } from "components/Base";

import PackageCard from "./PackageCard";

import { getVisiblePackages } from "EventLight/Expo/Sales/selectors";

const decorate = connect(
  (state, props) => ({
    visiblePackages: getVisiblePackages(state, props)
  }),
  {}
);

const VisiblePackages = ({ visiblePackages }) => (
  <PackageContainer
    title="Visible Packages"
    // caption={<Text2 underline>View Example</Text2>}
    description=""
  >
    {/*<Text3 mb={2}>
      If you want to keep some of your packages as internal options - remove
      them as visible options on your public sales page.
</Text3>*/}
    {R.map(
      pack => (
        <PackageCard {...pack} key={pack.id} mb={2} />
      ),
      visiblePackages
    )}
    {/* // @TODO: Create Faqs component */}
  </PackageContainer>
);

export default decorate(VisiblePackages);
