import { combineReducers } from "redux";
import {
  TOGGLE_EDIT_FIELD_DESCRIPTION,
  SELECT_FORM_FIELD,
  DESELECT_FORM_FIELD
} from "./constants";

const updatingFieldDescription = (state = {}, action) => {
  switch (action.type) {
    case TOGGLE_EDIT_FIELD_DESCRIPTION:
      return {
        fieldId: action.payload.fieldId
      };
    default:
      return state;
  }
};

const selectedFormField = (state = null, action) => {
  switch (action.type) {
    case SELECT_FORM_FIELD:
      return action.payload.fieldId;
    case DESELECT_FORM_FIELD:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  updatingFieldDescription,
  selectedFormField
});
