import React from "react";
import { Div, Text4 } from "components/Base";

const SelectBox = ({ id, name, onClick }) => (
  <Div
    width={1}
    ba={2}
    bc={{ default: "neutral2", hover: "info6" }}
    bg="white"
    bra={2}
    transition="fast"
    display="row.space-between.center"
    style={{ flexShrink: 0 }}
    px={4}
    mb={1}
    height={56}
    key={id}
    onClick={onClick}
  >
    <Div display="row.flex-start.center">
      <Text4 bold ml={3}>
        {name}
      </Text4>
    </Div>
    <Div
      bra={1}
      bg={{ default: "neutral2", hover: "info6" }}
      color={{
        default: "gray7",
        hover: "white"
      }}
      px={4}
      py={2}
      style={{ cursor: "pointer" }}
      fs={2}
      fw={3}
      transition="fast"
      textAlign="center"
      width={110}
      ml={2}
    >
      SELECT
    </Div>
  </Div>
);

export default SelectBox;
