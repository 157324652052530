import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Div } from "components/Base";
import Inventory from "Forms/WizardModal/View/Inventory";
import Passes from "Forms/WizardModal/View/Passes";
import Meals from "Forms/WizardModal/View/Meals";
import NewInventory from "Forms/WizardModal/View/NewInventory";
import CustomModule from "Forms/WizardModal/View/CustomModule";
import Applications from "Forms/WizardModal/View/Applications";
import Schedules from "Forms/WizardModal/View/Schedules";
import SelectForm from "./SelectForm";
import Loading from "./Loading";
import { getters } from "Forms/WizardModal";
import { PAGES } from "Forms/WizardModal/constants";
import { getLayoutHeight } from "Forms/WizardModal/selectors";
import AddScheduleModal from "./CreateScheduleModal";

const decorate = connect(state => ({
  selectedPage: getters.selectedPage(state),
  selectedInventory: getters.selectedInventory(state),
  height: getLayoutHeight(state),
  loading: getters.loading(state),
  showCreateModal: getters.showCreateModal(state)
}));

const Frame = ({ children, height, showCreateModal }) => (
  <Div>
    {showCreateModal && <AddScheduleModal />}
    <Div m={5} style={{ overflow: "none" }} display="row.center.center">
      <Div
        display="column.flex-start"
        bra={1}
        shadow={2}
        bg="white"
        height={height}
        width={900}
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          maxHeight: "calc(100% - 100px)"
        }}
        onMouseDown={e => e.stopPropagation()}
      >
        {children}
      </Div>
    </Div>
  </Div>
);

const ActivePage = ({ selectedPage, handlers }) => {
  if (selectedPage === PAGES.INVENTORY) {
    return <Inventory handlers={handlers} />;
  } else if (selectedPage === PAGES.PASSES) {
    return <Passes handlers={handlers} />;
  } else if (selectedPage === PAGES.MEALS) {
    return <Meals handlers={handlers} />;
  } else if (selectedPage === PAGES.NEW_INVENTORY) {
    return <NewInventory handlers={handlers} />;
  } else if (selectedPage === PAGES.CUSTOM) {
    return <CustomModule handlers={handlers} />;
  } else if (selectedPage === PAGES.APPLICATIONS) {
    return <Applications handlers={handlers} />;
  } else if (selectedPage === PAGES.SCHEDULES) {
    return <Schedules handlers={handlers} />;
  }
  return <SelectForm handlers={handlers} />;
};

const Layout = ({
  handlers,
  selectedPage,
  height,
  loading,
  showCreateModal
}) => {
  return (
    <Frame height={height} showCreateModal={showCreateModal}>
      {loading ? (
        <Loading />
      ) : (
        <ActivePage handlers={handlers} selectedPage={selectedPage} />
      )}
    </Frame>
  );
};

Layout.propTypes = {
  loading: PropTypes.bool
};

export default decorate(Layout);
