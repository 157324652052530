import { createModule } from "redux-mvc";

import model from "./model";
import sagas from "./sagas";

const allNewsAndAlertsDetail = createModule(model);

allNewsAndAlertsDetail.setRootSaga(sagas);

export default allNewsAndAlertsDetail;
