import * as R from "ramda";
import React, { Fragment, cloneElement } from "react";
import { Link, withRouter } from "react-router";
import { withState } from "utils/General";
import { connect } from "react-redux";
import { getters } from "EventLight/Dashboard/model";

import Panel from "ui-kit/Panel";
import { addS } from "utils/General";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import {
  DownFilledIcon,
  MediumFilledButton,
  PopMenu,
  PopMenuCard,
  SegmentedLineIcon,
  Text2,
  BagIcon,
  PeopleIcon,
  Div,
  Text1,
  Text4,
  LoadingIcon,
  MediumFilledBox,
  DarkTabs
} from "components/Base";

import { withProps } from "utils/General";

const decorate = connect(state => ({
  loading: getters.loading(state),
  groupTypes: getters.groupTypes(state)
}));

const BulkMenuItem = ({ children, LeftIcon, ...props }) => (
  <Div
    {...props}
    m={1}
    style={{ minWidth: 180 }}
    bg={{ default: "transparent", hover: "neutral1" }}
    bra={1}
    px={2}
    py={2}
    display="row.flex-start"
  >
    {LeftIcon ? <LeftIcon mr={2} /> : null}
    <Text2 bold>{children}</Text2>
  </Div>
);

const GraphIcon = withProps({ color: "red" })(SegmentedLineIcon);

const Actions = ({ label, active, setActive, showAddModal, goToSettings }) => (
  <PopMenu
    className="showOnHover"
    style={{
      position: "absolute",
      top: "10px",
      bottom: "0px",
      right: "10px"
    }}
    onCloseMenu={() => {
      setActive(false);
    }}
    Label={({ onClick }) => (
      <Div
        display="row.space-between.center"
        bg={{
          default: "white",
          hover: "neutral1"
        }}
        bra={1}
        fw={3}
        fs={2}
        px={2}
        py={2}
        height={30}
        color="gray7"
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          setActive(!active);
          onClick();
        }}
      >
        Actions
        <DownFilledIcon ml={1} size={20} color="gray7" />
      </Div>
    )}
  >
    {({ closeMenu }) => {
      return (
        <PopMenuCard position="bottom" align="left">
          {showAddModal ? (
            <BulkMenuItem
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                showAddModal();
                closeMenu();
              }}
            >
              Add {label}
            </BulkMenuItem>
          ) : null}

          {goToSettings ? (
            <BulkMenuItem
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                goToSettings();
                closeMenu();
              }}
            >
              Settings
            </BulkMenuItem>
          ) : null}
          {/*
          <BulkMenuItem onClick={() => closeMenu()}>
            Invite New Groups
          </BulkMenuItem>
          <BulkMenuItem onClick={() => closeMenu()}>
            Send Login Details
          </BulkMenuItem>
          <BulkMenuItem onClick={() => closeMenu()}>Assets Report</BulkMenuItem>
          <BulkMenuItem onClick={() => closeMenu()}>
            Form Status Report
          </BulkMenuItem>
          <BulkMenuItem onClick={() => closeMenu()}>
            Document Report
          </BulkMenuItem>
          <BulkMenuItem onClick={() => closeMenu()}>People Report</BulkMenuItem>
          <Text1 color="neutral4" bold ml={3}>
            JUMP TO REPORT
          </Text1>
          <BulkMenuItem LeftIcon={GraphIcon} onClick={() => closeMenu()}>
            Assets Report
          </BulkMenuItem>
          <BulkMenuItem LeftIcon={GraphIcon} onClick={() => closeMenu()}>
            Form Status Report
          </BulkMenuItem>
          <BulkMenuItem LeftIcon={GraphIcon} onClick={() => closeMenu()}>
            Document Report
          </BulkMenuItem>
          <BulkMenuItem LeftIcon={GraphIcon} onClick={() => closeMenu()}>
            People Report
          </BulkMenuItem>
          */}
        </PopMenuCard>
      );
    }}
  </PopMenu>
);

const GroupCard = withState("active", "setActive", false)(
  ({ to = "", children, active, setActive }) => {
    const content = (
      <Div
        display="row.flex-start.center"
        p={2}
        bra={2}
        style={{ overflow: "visible", position: "relative" }}
        bg={{ default: "transparent", hover: "neutral0" }}
        className={active ? undefined : "hoverContainer"}
      >
        {children({
          active,
          setActive
        })}
      </Div>
    );
    if (to !== "") {
      return <Link to={to}>{content}</Link>;
    } else {
      return content;
    }
  }
);

const Groups = withState("viewing", "setViewing", props =>
  !props.groupTypes.account_record_types.length &&
  props.groupTypes.contact_record_types.length
    ? "contacts"
    : "accounts"
)(props => {
  const {
    showAddAccountModal,
    showAddContactModal,
    goToAccountSettings,
    goToContactSettings,
    params: { eventId },
    loading
  } = props;

  if (
    !loading &&
    !props.groupTypes.account_record_types.length &&
    !props.groupTypes.contact_record_types.length &&
    !props.groupTypes.can_view_all_accounts &&
    !props.groupTypes.can_view_all_contacts
  ) {
    return null;
  }

  let viewing = props.viewing;

  if (
    !props.groupTypes.account_record_types.length &&
    props.groupTypes.contact_record_types.length
  ) {
    viewing = "contacts";
  } else if (
    props.groupTypes.account_record_types.length &&
    !props.groupTypes.contact_record_types.length
  ) {
    viewing = "accounts";
  }

  const VIEW_TABS = [
    {
      label: "Groups",
      active: viewing === "accounts",
      onClick: () => props.setViewing("accounts"),
      value: "accounts",
      visible: Boolean(props.groupTypes.account_record_types.length),
      mr: 3
    },
    {
      label: "People",
      active: viewing === "contacts",
      onClick: () => props.setViewing("contacts"),
      value: Boolean(props.groupTypes.account_record_types.length),
      visible: Boolean(props.groupTypes.contact_record_types.length),
      mr: 0
    }
  ].filter(tab => tab.visible);

  const isViewingAccounts = viewing === "accounts";

  return (
    <Panel width={350} shadow={1} style={{ overflow: "visible" }}>
      {props.groupTypes.can_view_all_accounts ||
      props.groupTypes.can_view_all_contacts ? (
        <Div p={2}>
          {props.groupTypes.can_view_all_accounts ? (
            <GroupCard
              to={`/event-light/${eventId}/crm/manage/${STANDARD_MODULE_IDS.accounts.id}`}
            >
              {() => (
                <Fragment>
                  <Div bra={2} bg="altB5" size={35} display="row.center.center">
                    <BagIcon color="white" size={20} />
                  </Div>
                  <Div display="column" pl={3} flex={1}>
                    <Text4 bold>All Groups</Text4>
                    <Div display="row.flex-start.center" color="gray5" fs={1}>
                      {props.groupTypes.count_of_all_accounts} group
                      {addS(props.groupTypes.count_of_all_accounts)}
                    </Div>
                  </Div>
                </Fragment>
              )}
            </GroupCard>
          ) : null}

          {props.groupTypes.can_view_all_contacts ? (
            <GroupCard
              to={`/event-light/${eventId}/crm/manage/${STANDARD_MODULE_IDS.contacts.id}`}
            >
              {() => (
                <Fragment>
                  <Div bra={2} bg="info3" size={35} display="row.center.center">
                    <PeopleIcon color="white" size={20} />
                  </Div>
                  <Div display="column" pl={3} flex={1}>
                    <Text4 bold>All People</Text4>
                    <Div display="row.flex-start.center" color="gray5" fs={1}>
                      {props.groupTypes.count_of_all_contacts}{" "}
                      {props.groupTypes.count_of_all_contacts !== 1
                        ? "people"
                        : "person"}
                    </Div>
                  </Div>
                </Fragment>
              )}
            </GroupCard>
          ) : null}
        </Div>
      ) : null}

      {props.groupTypes.account_record_types.length ||
      props.groupTypes.contact_record_types.length ? (
        <Fragment>
          <Div display="row.flex-start.center" pl={4}>
            <Div fs={2} color="gray7" mr={2} pb={1}>
              Manage:
            </Div>
            <DarkTabs tabs={VIEW_TABS} />
          </Div>
          <Div bc="neutral2" bb={1} width={1} />
        </Fragment>
      ) : null}

      <Div p={2}>
        {!props.groupTypes.account_record_types.length &&
        !props.groupTypes.contact_record_types.length &&
        loading ? (
          <Div display="row.center.center" p={5}>
            <LoadingIcon size={30} color="gray4" />
          </Div>
        ) : null}
        {R.map(
          group => (
            <GroupCard
              key={group.id}
              to={
                isViewingAccounts
                  ? `/event-light/${eventId}/crm/manage/${STANDARD_MODULE_IDS.accounts.id}/type/${group.id}`
                  : `/event-light/${eventId}/crm/manage/${STANDARD_MODULE_IDS.contacts.id}/type/${group.id}`
              }
            >
              {({ active, setActive }) => (
                <Fragment>
                  {group.icon ? (
                    <i
                      className="material-icons"
                      style={{
                        width: 12,
                        color: "#C6B9B9"
                      }}
                    >
                      {group.icon}
                    </i>
                  ) : (
                    ""
                  )}
                  <Div
                    display="column.flex-start.flex-start"
                    pl={3}
                    flex={1}
                    ml={3}
                  >
                    <Text4 bold>{group.name}</Text4>
                    <Div display="row.flex-start.center" color="gray5" fs={1}>
                      {isViewingAccounts
                        ? `${group.count_of_accounts} group${addS(
                            group.count_of_accounts
                          )} ·${" "}`
                        : null}
                      {group.count_of_contacts}{" "}
                      {group.count_of_contacts !== 1 ? "people" : "person"}
                    </Div>
                  </Div>
                  {showAddAccountModal ||
                  showAddContactModal ||
                  goToAccountSettings ||
                  goToContactSettings ? (
                    <Actions
                      {...{
                        active,
                        setActive,
                        label: isViewingAccounts ? "Account" : "Contact",
                        showAddModal: isViewingAccounts
                          ? showAddAccountModal
                            ? () => showAddAccountModal(group.id, group.name)
                            : undefined
                          : showAddContactModal
                          ? () => showAddContactModal(group.id, group.name)
                          : undefined,
                        goToSettings: isViewingAccounts
                          ? goToAccountSettings
                            ? () => goToAccountSettings(group.id)
                            : undefined
                          : goToContactSettings
                          ? () => goToContactSettings(group.id)
                          : undefined
                      }}
                    />
                  ) : null}
                </Fragment>
              )}
            </GroupCard>
          ),
          R.prop(viewing)({
            accounts: props.groupTypes.account_record_types,
            contacts: props.groupTypes.contact_record_types
          })
        )}
      </Div>
    </Panel>
  );
});

export default withRouter(decorate(Groups));
