import * as R from "ramda";
import { fork, put, takeEvery } from "redux-saga/effects";

import { actions } from "ui-kit/Datagrid";
import {
  CREATE_SUBFORM_COMPLETE,
  CLONE_SUBFORM_COMPLETE,
  BULK_DELETE_SUBFORM
} from "redux/modules/formsV2/submission/constants";
import { CREATE_SUBFORM as SUBFORM_VALUE_CREATE } from "redux/modules/formsV2/submission/values/constants";
import { MODES } from "ui-kit/Datagrid/constants";

const dispatchToGlobal = function*(action) {
  // @NOTE: Bail if in preview mode
  if (
    action.payload &&
    [MODES.EDIT, MODES.MODAL].includes(action.payload.mode)
  ) {
    return;
  }

  // @NOTE: Currently short-circuiting if dragging or copying and pasting
  if (R.path(["meta", "suppressReduxForwarding"])(action)) {
    return;
  }

  switch (action.type) {
    case actions.addRowResponse.type:
      yield put({
        type: CREATE_SUBFORM_COMPLETE,
        payload: {
          subformId: action.meta.instanceId,
          submission: action.payload.submission
        }
      });
      return;
    case actions.duplicateRowResponse.type:
      yield put({
        type: CLONE_SUBFORM_COMPLETE,
        payload: {
          subformId: action.meta.instanceId,
          submissions: action.payload.submissions
        }
      });
      return;
    case actions.removeRowResponse.type:
      yield put({
        type: BULK_DELETE_SUBFORM,
        payload: {
          subformId: action.meta.instanceId,
          submissionIds: action.payload.selectedRowIds
        }
      });
      return;
    case actions.updateCellResponse.type: {
      yield put({
        type: SUBFORM_VALUE_CREATE,
        payload: {
          subformFieldId: action.meta.instanceId,
          submissionId: action.payload.submissionId,
          fieldId: action.payload.fieldId,
          value: action.payload.value
        }
      });
      return;
    }
    default:
      return;
  }
};

const watchDispatchToGlobal = function*() {
  yield takeEvery([actions.addRowResponse], dispatchToGlobal);
};

const rootSaga = function*() {
  yield fork(watchDispatchToGlobal);
};

export default rootSaga;
