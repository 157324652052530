/* eslint-disable no-underscore-dangle */
import {
  all,
  call,
  fork,
  put,
  select,
  spawn,
  takeEvery
} from "redux-saga/effects";
import { registerError } from "redux/modules/errors/actions";
import { actions, getters } from "../model";
import { actions as ParentActions } from "../../../model";
import {
  eventDetails as getEventDetails,
  eventId as getEventId
} from "redux/modules/event/selectors";
import { apiCall } from "App/Data/sagas";
import { getters as FormGetters } from "ui-kit/Form/model";
import * as R from "ramda";
import { GUEST_LIST_DATE_FORMAT, GUEST_LIST_FORM_ID } from "../constants";
import {
  actions as selectRequiredFieldsActions,
  getters as selectRequiredFieldsGetters
} from "Modules/SelectRequiredFieldsModalRefactored/model";
import {
  getGuestListNotDraggableFieldIds,
  getMappedSelectGuestListRequiredFields
} from "../selectors";
import moment from "moment-timezone";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { getRecordTypes } from "../../../sagas";

const getGuestListDetails = function*() {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(R.prop("selectedTypeId", getters));

    yield put(actions.prepareOpenModalReducer());

    const { payload } = yield call(apiCall, {
      method: "get",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/guest-list`
    });

    yield put(actions.openModalReducer(payload));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred white loading guest list settings"
        }
      ])
    );
  }
};

const updateRecordTypes = function*() {
  const updatedRecordTypes = yield call(getRecordTypes);
  yield put(ParentActions.setRecordTypes(updatedRecordTypes));
};

const updateGuestListSettings = function*() {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(R.prop("selectedTypeId", getters));
    const eventDetails = yield select(getEventDetails);

    const fields = yield select(R.prop("fields", FormGetters), {
      instanceId: GUEST_LIST_FORM_ID
    });
    const disable = !R.pathOr(false, ["is_enabled", "value"], fields);
    const contactRecordTypeId = R.path(
      ["contact_record_type_id", "value", 0],
      fields
    );
    const isEnabled = R.pathOr(false, ["is_enabled", "value"], fields);
    const isLocked = R.pathOr(false, ["is_locked", "value"], fields);
    const description = R.pathOr(null, ["description", "value"], fields);
    const closeDate = R.pathOr(null, ["close_date", "value"], fields);
    const formattedCloseDate =
      closeDate && closeDate.length
        ? moment
            .tz(
              new Date(closeDate),
              GUEST_LIST_DATE_FORMAT,
              R.prop("timezone", eventDetails)
            )
            .utc()
            .format()
        : null;

    const guestListSettings = yield select(
      R.prop("guestListSettings", getters)
    );

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/guest-list`,
      data: {
        disable,
        contactRecordTypeId,
        isEnabled,
        isLocked,
        closeDate: formattedCloseDate,
        visibleFields: R.prop("visible_fields", guestListSettings),
        requiredFields: R.prop("required_fields", guestListSettings),
        fieldOrder: R.prop("field_order", guestListSettings),
        variantLimits: R.prop("variant_limits", guestListSettings),
        title: null,
        description
      }
    });
    yield all([
      spawn(updateRecordTypes),
      put(actions.closeModalReducer()),
      put(ParentActions.setIsSettingsModalVisible(false)),
      put(showSnackbar({ message: "Settings Updated" })),
      put(ParentActions.fetchGuestLists())
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while updating guest list settings"
        }
      ])
    );
  }
};

const onSelectGuestListItems = function*({ payload }) {
  const guestListSettings = yield select(R.prop("guestListSettings", getters));
  yield all([
    put(
      actions.setGuestListSettings({
        ...guestListSettings,
        variant_limits: R.compose(
          R.reduce((map, id) => {
            map[id] = R.prop(id)(payload);
            return map;
          }, {}),
          R.filter(id => R.prop(id)(payload)),
          R.keys
        )(payload)
      })
    ),
    put(actions.setShowSelectGuestListItemsModal(false))
  ]);
};

const openSelectRequiredFieldsModal = function*() {
  const showModal = yield select(R.prop("showGuestListSettingsModal", getters));
  if (showModal) {
    const guestListSettings = yield select(
      R.prop("guestListSettings", getters)
    );
    const columns = yield select(getMappedSelectGuestListRequiredFields);
    const notDraggableFieldIds = yield select(getGuestListNotDraggableFieldIds);

    yield all([
      put(
        selectRequiredFieldsActions.setInitialData({
          columns,
          notDraggableFieldIds,
          visibleFields: R.propOr([], "visible_fields", guestListSettings),
          requiredFields: R.propOr([], "required_fields", guestListSettings),
          fieldOrder: R.propOr([], "field_order", guestListSettings)
        })
      ),
      put(actions.setShowSelectRequiredFieldsModal(true))
    ]);
  }
};

const updateSelectedFields = function*() {
  const showModal = yield select(R.prop("showGuestListSettingsModal", getters));
  if (showModal) {
    const guestListSettings = yield select(
      R.prop("guestListSettings", getters)
    );
    const visibleFields = yield select(
      R.prop("visibleFields", selectRequiredFieldsGetters)
    );
    const requiredFields = yield select(
      R.prop("requiredFields", selectRequiredFieldsGetters)
    );
    const fieldOrder = yield select(
      R.prop("fieldOrder", selectRequiredFieldsGetters)
    );
    yield put(
      actions.setGuestListSettings({
        ...guestListSettings,
        visible_fields: visibleFields,
        required_fields: requiredFields,
        field_order: fieldOrder
      })
    );
    yield put(actions.setShowSelectRequiredFieldsModal(false));
  }
};

const watchUpdateGuestListSettings = function*() {
  yield takeEvery(
    R.path(["updateGuestListSettings", "type"], actions),
    updateGuestListSettings
  );
};
const watchGetGuestListDetails = function*() {
  yield takeEvery(
    R.path(["getGuestListDetails", "type"], actions),
    getGuestListDetails
  );
};

const watchUpdateSelectedFields = function*() {
  yield takeEvery(
    R.path(["save", "type"], selectRequiredFieldsActions),
    updateSelectedFields
  );
};
const watchOpenSelectRequiredFieldsModal = function*() {
  yield takeEvery(
    R.path(["openSelectRequiredFieldsModal", "type"], actions),
    openSelectRequiredFieldsModal
  );
};
const watchOnSelectGuestListItems = function*() {
  yield takeEvery(
    R.path(["onSelectGuestListItems", "type"], actions),
    onSelectGuestListItems
  );
};

const rootSaga = function*() {
  yield all([
    fork(watchGetGuestListDetails),
    fork(watchUpdateGuestListSettings),
    fork(watchUpdateSelectedFields),
    fork(watchOpenSelectRequiredFieldsModal),
    fork(watchOnSelectGuestListItems)
  ]);
};

export default rootSaga;
