import PropTypes from "prop-types";
import React, { Component } from "react";
import moment from "moment";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import $ from "jquery";

@CSSModules(css)
class MessageModal extends Component {
  componentDidMount() {
    // show embedded video at full width of modal while maintaining aspect ratio
    $(".ql-video")
      .css({
        width: "100%",
        position: "absolute",
        height: "100%",
        top: 0,
        left: 0
      })
      .wrap(
        '<div style="position:relative;padding-bottom:56.25%;padding-top:25px"></div>'
      );
  }
  render() {
    const { title, updated_at, content_formatted } = this.props;
    return (
      <div styleName="container">
        <div styleName="bodyWrapper" onMouseDown={e => e.stopPropagation()}>
          <div styleName="body">
            <div styleName="header">
              Posted {moment(updated_at).fromNow()}
              <i
                className="material-icons"
                styleName="icon"
                onClick={() => this.props.hideModal()}
              >
                close
              </i>
            </div>
            <div styleName="content">
              <div styleName="title">{title}</div>
              <div styleName="messageBody">
                <span
                  className="lennd-wysiwyg-content"
                  dangerouslySetInnerHTML={{ __html: content_formatted }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MessageModal.propTypes = {
  content_formatted: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  updated_at: PropTypes.string.isRequired
};

export default MessageModal;
