import React from "react";
import { Div, LoadingIcon, Text0, RemoveIcon } from "components/Base";
import { COLUMNS_SIZE } from "Inventory/Dashboard/constants";
import Circle from "ui-kit/Circle";

const CategoryRow = ({ typeWidthBar = 120 }) => (
  <Div display="row.space-between.center" p={3} className="hoverContainer">
    <Div
      width={COLUMNS_SIZE.TYPE}
      style={{ position: "relative" }}
      display="row.flex-start.center"
    >
      <Circle bg="neutral1" size={8} />
      <Div bg="neutral1" width={typeWidthBar} ml={2} bra={3} height={8} />
    </Div>
    <Div width={COLUMNS_SIZE.PENDING} display="row.flex-end" pr={1}>
      <RemoveIcon color="neutral2" />
    </Div>
    <Div width={COLUMNS_SIZE.APPROVED} display="row.flex-end" pr={1}>
      <RemoveIcon color="neutral2" />
    </Div>
    <Div width={COLUMNS_SIZE.PLANNED} display="row.flex-end" pr={1}>
      <RemoveIcon color="neutral2" />
    </Div>
    <Div width={COLUMNS_SIZE.REMAINNG} display="row.flex-end" pr={1}>
      <RemoveIcon color="neutral2" />
    </Div>
    <Div width={COLUMNS_SIZE.PROGRESS} display="row.flex-start" ml={3}>
      <Div bg="neutral1" width={1} bra={3} height={8} />
    </Div>
  </Div>
);

const CategoryCardLoading = () => {
  return (
    <Div width={834} bg="white" bra={2} shadow={1} p={2} mb={2}>
      <Div width={1} height={50} display="row.flex-start.center">
        <Div display="row.flex-start.center" height={1} pl={2}>
          <Div height={14} width={150} bra={3} bg="neutral2" mr={1} />
          <LoadingIcon color="primary7" />
        </Div>
      </Div>
      <Div>
        <Div display="row.space-between.center" px={3}>
          <Div width={COLUMNS_SIZE.TYPE}>
            <Text0 color="neutral4">TYPE</Text0>
          </Div>
          <Div width={COLUMNS_SIZE.PENDING} display="row.flex-end">
            <Text0 color="neutral4">PENDING</Text0>
          </Div>
          <Div width={COLUMNS_SIZE.APPROVED} display="row.flex-end">
            <Text0 color="neutral4">APPROVED</Text0>
          </Div>
          <Div width={COLUMNS_SIZE.PLANNED} display="row.flex-end">
            <Text0 color="neutral4">PLANNED</Text0>
          </Div>
          <Div width={COLUMNS_SIZE.REMAINNG} display="row.flex-end">
            <Text0 color="neutral4">REMAINNG</Text0>
          </Div>
          <Div width={COLUMNS_SIZE.PROGRESS} display="row.flex-start" ml={3}>
            <Text0 color="neutral4">PROGRESS</Text0>
          </Div>
        </Div>
        <CategoryRow />
        <CategoryRow typeWidthBar={100} />
        <CategoryRow />
        <CategoryRow typeWidthBar={90} />
      </Div>
    </Div>
  );
};

export default CategoryCardLoading;
