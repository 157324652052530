import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import { getPeople, getPagination } from "../selectors";
import { AVAILABLE_SECTIONS } from "../constants";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import CollapsablePanel from "ui-kit/CollapsiblePanel";
import AvatarCard from "ui-kit/AvatarCard";
import {
  PopoverMenu,
  SuperFilledButton,
  MediumOutlineButton,
  EditIcon,
  AddCircleIcon
} from "components/Base";
import PaginationBar from "ui-kit/PaginationBar/View/Slim";
import Checkbox from "material-ui/Checkbox";

import Toolbar from "./Toolbar";
import DocumentContainer from "./DocumentContainer";
import RequestsContainer from "./RequestsContainer";
import { FieldFormatter } from "ui-kit/FieldTypes/View";

const decorate = R.compose(
  connect(
    state => ({
      people: getPeople(state),
      pagination: getPagination(state),
      fieldsOfSections: getters.fieldsOfSections(state)
    }),
    {
      toggleCollapsibleId: actions.toggleCollapsibleId,
      toggleCollapsibleClick: actions.toggleCollapsibleClick,
      duplicatePerson: actions.duplicatePerson,
      removePerson: actions.removePerson,
      editInfo: actions.editInfo,
      addRequest: actions.submitRequest,
      editRequest: actions.editRequest,
      toggleSelectedPeopleIds: actions.toggleSelectedPeopleIds
    }
  ),
  CSSModules(css)
);

const DownIcon = ({ onClick, style = {} }) => (
  <span className="material-icons" onClick={onClick} style={style}>
    keyboard_arrow_down
  </span>
);

const EmptyState = CSSModules(
  ({
    icon,
    title,
    description,
    actionText,
    onClick,
    style = {},
    titleStyle = {}
  }) => (
    <div className={css.emptyStateContainer} style={style}>
      {icon ? (
        <div className={css.icon}>
          <span className="material-icons-outlined">{icon}</span>
        </div>
      ) : null}
      <div
        className={css.title}
        style={
          !description
            ? {
                marginBottom: 0,
                ...titleStyle
              }
            : titleStyle
        }
      >
        {title}
      </div>
      {description && description.length ? (
        <div className={css.description}>{description}</div>
      ) : null}
      {onClick ? (
        <div className={css.action}>
          <SuperFilledButton bg="altB5" color="white" onClick={onClick}>
            {actionText}
          </SuperFilledButton>
        </div>
      ) : null}
    </div>
  ),
  css
);

const PeopleCards = ({
  people,
  fieldsOfSections = {},
  toggleCollapsibleId,
  toggleCollapsibleClick,
  duplicatePerson,
  removePerson,
  pagination,
  toggleSelectedPeopleIds,
  editInfo,
  addRequest,
  editRequest
}) => (
  <div styleName="peopleCardsContainer">
    <Toolbar />
    {R.isEmpty(people) ? (
      <EmptyState
        icon="group"
        title="No people have been added yet"
        description="Manage the different types of people that are involved with your event"
        style={{
          margin: "30px",
          flex: 1
        }}
      />
    ) : (
      <div styleName="peopleCards">
        {R.map(
          person => (
            <div key={person.id}>
              <CollapsablePanel
                bg="white"
                p={2}
                arrowColor="gray7"
                mb={2}
                width={1}
                opened={person.opened}
                onClick={toggleCollapsibleClick}
                heading={
                  <div styleName="cardHeader">
                    <div styleName="row">
                      <div
                        styleName="avatar"
                        style={person.selected ? { display: "none" } : {}}
                      >
                        <AvatarCard
                          avatar={{
                            photoUrl: person.photo_url,
                            name: person.name
                          }}
                          avatarProps={{
                            singleInitial: false
                          }}
                          size="medium"
                        />
                      </div>
                      <div
                        styleName="checkbox"
                        style={person.selected ? { display: "block" } : {}}
                      >
                        <Checkbox
                          checked={person.selected}
                          iconStyle={{ marginRight: 6, marginLeft: 2 }}
                          onCheck={() => toggleSelectedPeopleIds(person.id)}
                        />
                      </div>
                      <div styleName="cardName">{person.name}</div>
                    </div>
                    <div styleName="cardButtons">
                      <MediumOutlineButton
                        LeftIcon={EditIcon}
                        mr={2}
                        color="primary7"
                        onClick={editInfo}
                      >
                        Edit Info
                      </MediumOutlineButton>
                      <MediumOutlineButton
                        LeftIcon={EditIcon}
                        mr={2}
                        color="primary7"
                        onClick={editRequest}
                      >
                        Edit Requests
                      </MediumOutlineButton>
                      <MediumOutlineButton
                        LeftIcon={AddCircleIcon}
                        mr={2}
                        color="primary7"
                        onClick={() => addRequest(person.id)}
                      >
                        Add Request
                      </MediumOutlineButton>
                      <PopoverMenu
                        Label={CSSModules(css)(({ onClick }) => (
                          <div styleName="moreOptionsCard">
                            <span className="material-icons" onClick={onClick}>
                              more_horiz
                            </span>
                          </div>
                        ))}
                        menuItems={[
                          ["Duplicate Person", duplicatePerson],
                          ["Delete Person", removePerson]
                        ]}
                      />
                    </div>
                  </div>
                }
                arrowIcon={() => (
                  <DownIcon
                    onClick={() => toggleCollapsibleId(person.id)}
                    style={
                      !person.opened ? { transform: "rotate(-90deg)" } : {}
                    }
                  />
                )}
              >
                <div styleName="cardContent">
                  {R.prop(AVAILABLE_SECTIONS.DETAILS, person.sections) ? (
                    <CollapsablePanel
                      arrowColor="gray7"
                      bg="white"
                      heading={R.path(
                        ["sections", AVAILABLE_SECTIONS.DETAILS, "title"],
                        person
                      )}
                      width={1}
                      mt={4}
                      mb={4}
                    >
                      <div styleName="content">
                        {R.map(
                          field => (
                            <div styleName="detailsField" key={field.id}>
                              <span styleName="detailsFieldLabel">
                                {field.name}
                              </span>
                              <span styleName="detailsFieldValue">
                                <FieldFormatter
                                  field={field}
                                  data={R.path(
                                    [
                                      "sections",
                                      AVAILABLE_SECTIONS.DETAILS,
                                      "data"
                                    ],
                                    person
                                  )}
                                />
                              </span>
                            </div>
                          ),
                          R.prop(AVAILABLE_SECTIONS.DETAILS, fieldsOfSections)
                        )}
                      </div>
                    </CollapsablePanel>
                  ) : null}
                  {R.prop(AVAILABLE_SECTIONS.DOCUMENTS, person.sections) ? (
                    <DocumentContainer
                      data={R.prop(
                        AVAILABLE_SECTIONS.DOCUMENTS,
                        person.sections
                      )}
                    />
                  ) : null}
                  {R.prop(AVAILABLE_SECTIONS.FORMS, person.sections) ? (
                    <CollapsablePanel
                      collapsedDefault
                      arrowColor="gray7"
                      bg="white"
                      heading="Forms"
                      width={1}
                      mt={4}
                      mb={4}
                    >
                      <div styleName="content"></div>
                    </CollapsablePanel>
                  ) : null}
                  {R.prop(AVAILABLE_SECTIONS.REQUESTS, person.sections) ? (
                    <RequestsContainer
                      personId={person.id}
                      title={R.path(
                        ["sections", AVAILABLE_SECTIONS.REQUESTS, "title"],
                        person
                      )}
                      requestTypes={R.path(
                        ["sections", AVAILABLE_SECTIONS.REQUESTS, "types"],
                        person
                      )}
                    />
                  ) : null}
                </div>
              </CollapsablePanel>
            </div>
          ),
          people
        )}
      </div>
    )}

    <PaginationBar
      style={{
        maxWidth: "100%",
        color: "#333333",
        fontSize: "16px",
        letterSpacing: "0px",
        backgroundColor: "inherit"
      }}
      total={pagination.total}
      showPageSizeOptions={false}
      showLabel={false}
    />
  </div>
);

export default decorate(PeopleCards);
