import React from "react";
import { connect } from "react-redux";
import uuid from "node-uuid";
import { BEGIN } from "redux-optimist";

import { actions, getters } from "../model";
import { getManagerOverrideIsActive } from "../selectors";

import PasswordField from "components/Global/Inputs/Password";

import {
  AddIcon,
  Div,
  LockIcon,
  MediumFilledButton,
  BigTextButton,
  Text4
} from "components/Base";

import { noop } from "utils/General";

const decorate = connect(
  (state, props) => ({
    isEnabled: getManagerOverrideIsActive(state, props),
    passwordFieldIsActive: getters.passwordFieldIsActive(state, props),
    password: getters.password(state, props)
  }),
  {
    onPasswordChange: actions.setPassword,
    onSave: () =>
      actions.savePassword(null, {
        optimist: { type: BEGIN, transactionId: uuid.v4() }
      }),
    onCancel: actions.cancelPassword,
    onEdit: actions.editPassword,
    onDisable: () =>
      actions.disablePassword(null, {
        optimist: { type: BEGIN, transactionId: uuid.v4() }
      })
  }
);

const ManagerOverride = ({
  isEnabled = false,
  passwordFieldIsActive = false,
  password = "",
  onPasswordChange = noop,
  onSave = noop,
  onEdit = noop,
  onCancel = noop,
  onDisable = noop
}) => (
  <Div p={10}>
    <Div bg="white" shadow={1} maxWidth={500} px={10} py={5}>
      <LockIcon size={40} mb={4} />
      <Text4 bold>Set Manager Override Code</Text4>
      <Div py={3}>
        Share this with trusted Catering Check In Staffers to enable them to
        assign criticial meal access on the fly so that workers & VIPs can stay
        happy & keep moving.
      </Div>
      <Div px={4} py={3} bg="neutral0" display="column" bra={1}>
        {passwordFieldIsActive ? (
          <Div display="row.flex-start.center">
            <PasswordField
              name="password"
              onChange={e => onPasswordChange(e.target.value)}
              placeholder="Enter password"
              value={password}
              style={{
                border: "1px solid #E3E3E3",
                borderRadius: 4,
                padding: 10,
                width: "100%"
              }}
            />
            <MediumFilledButton
              ml={2}
              bg="primary5"
              onClick={onSave}
              disabled={password === ""}
            >
              Save
            </MediumFilledButton>
            <MediumFilledButton ml={2} onClick={onCancel}>
              Cancel
            </MediumFilledButton>
          </Div>
        ) : isEnabled ? (
          <Div display="row.flex-start.center">
            <PasswordField
              name="passwordPlaceholder"
              disabled
              value="*********"
              style={{
                border: "1px solid #E3E3E3",
                backgroundColor: "#f7f7f8",
                borderRadius: 4,
                padding: 10,
                width: "100%"
              }}
            />
            <MediumFilledButton ml={2} bg="primary5" onClick={onEdit}>
              Change
            </MediumFilledButton>
            <MediumFilledButton ml={2} onClick={onDisable}>
              Disable
            </MediumFilledButton>
          </Div>
        ) : (
          <BigTextButton color="primary5" LeftIcon={AddIcon} onClick={onEdit}>
            Set Code
          </BigTextButton>
        )}
      </Div>
    </Div>
  </Div>
);
export default decorate(ManagerOverride);
