import React, { Component } from "react";
import getValue from "utils/value-types/get-value/text";
import { isEqual } from "lodash";

export default class SignatureFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  render() {
    const { style = {} } = this.props;
    const value = getValue(this.props.value);

    if (!value) {
      return null;
    }
    return (
      <div style={{ ...style, display: "flex", alignItems: "center" }}>
        <img src={value} height={35} />
      </div>
    );
  }
}
