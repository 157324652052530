import * as R from "ramda";
import React from "react";

import { EMAIL_FIELDS } from "Sponsors/SelfSetup/constants";

import { PackageContainer } from "Sponsors/Common/View";

import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";
import { Toggle, Row, Column } from "ui-kit/Form/View";
import AvatarCard from "ui-kit/AvatarCard";
import Circle from "ui-kit/Circle";

import { Text3, Text2, DownIcon, CloseIcon } from "components/Base";

const decorate = WithThemeConsumer({ variant: VARIANTS.BACKGROUND });

const mockAdmins = [
  { name: "Ruth Carlton (you)", notifications: "Text & Email", id: "1" },
  { name: "Steve Witmer", notifications: "Text Only", id: "2" }
];

const AdminNotifications = ({ Th = defaultTh, admins = mockAdmins }) => (
  <PackageContainer title="Admin Notifications">
    <Row>
      <Toggle fieldId={EMAIL_FIELDS.NOTIFY_ADMINS} />
      <Text3 {...Th(["color"], { ml: 3, bold: true })}>
        Notify admins on submission of applications
      </Text3>
    </Row>
    <Column mt={3} width={1} display="column.flex-start.stretch">
      {R.map(
        admin => (
          <Row
            {...Th(["bg"], {
              variant: VARIANTS.SURFACE,
              px: 3,
              py: 2,
              mb: 2,
              bra: 1
            })}
            key={admin.id}
            display="row.space-between.center"
          >
            <AvatarCard avatar={admin}>
              <Column ml={3}>
                <Text3 bold {...Th(["color"])}>
                  {admin.name}
                </Text3>
                <Text2 {...Th(["color"])}>{admin.notifications}</Text2>
              </Column>
            </AvatarCard>
            <Row>
              <Row mr={4}>
                <Text2 {...Th(["color"])}>All Submissions</Text2>
                <DownIcon {...Th(["color"], { sizeWFS: 5 })} />
              </Row>
              <Circle p={1}>
                <CloseIcon sizeWFS={3} />
              </Circle>
            </Row>
          </Row>
        ),
        admins
      )}
    </Column>
    <Text3
      {...Th(["color"], {
        ml: 3,
        mt: 3,
        bold: true,
        active: true,
        variant: Th.VARIANTS.SURFACE
      })}
    >
      + Add Another
    </Text3>
  </PackageContainer>
);

export default decorate(AdminNotifications);
