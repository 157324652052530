import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Editor from "./Editor";
import { withRouter } from "react-router";
import { search } from "redux/modules/modules/records/actions";
import { references } from "redux/modules/entityReferences/selectors";
import { addReference } from "redux/modules/entityReferences/actions";

function mapStateToProps(state) {
  return {
    references: references(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addReference,
      search
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Editor));
