import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Row from "../Row";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class Rows extends PureComponent {
  render() {
    const {
      title,
      events,
      toggleStarredEvent,
      goToOrg,
      goToEvent,
      activeEventId
    } = this.props;
    return (
      <div>
        {goToOrg ? (
          <div styleName="linkTitle" onClick={goToOrg}>
            <i className="material-icons">group</i>
            {title}
          </div>
        ) : (
          <div styleName="title">{title}</div>
        )}
        {events.map(item => (
          <Row
            key={item.id}
            item={item}
            toggleStarredEvent={toggleStarredEvent}
            goToEvent={goToEvent}
            isActive={item.id === activeEventId}
          />
        ))}
      </div>
    );
  }
}

Rows.propTypes = {
  toggleStarredEvent: PropTypes.func.isRequired,
  goToEvent: PropTypes.func.isRequired,
  goToOrg: PropTypes.func,
  events: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  activeEventId: PropTypes.number
};

export default Rows;
