import { connect } from "react-redux";
import Controller from "./Controller";

import { assetRequests } from "redux/modules/portal/reports/selectors";

function mapStateToProps(state) {
  return {
    requests: assetRequests(state)
  };
}

export default connect(mapStateToProps)(Controller);
