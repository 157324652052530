import * as R from "ramda";

import { createSelector } from "reselect";

import { getters } from "./model";

export const isFileImage = createSelector(
  getters.mimetype,
  R.contains("image")
);

export const getMetaData = createSelector(
  getters.fileSource,
  getters.addedBy,
  getters.size,
  getters.modified,
  (source, addedBy, size, modified) => [
    [{ label: "source", value: source }, { label: "added by", value: addedBy }],
    [
      { label: "size", value: size },
      { label: "last modified", value: modified }
    ]
  ]
);

export const getFileAttachmentData = createSelector(
  getters.name,
  getters.mimetype,
  getters.url,
  getters.size,
  getters.fileId,
  (filename, mimetype, size, url, id) => ({
    filename,
    mimetype,
    size,
    url,
    uploadId: id
  })
);
