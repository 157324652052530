import * as R from "ramda";
import React from "react";

import { noop } from "utils/General";

import {
  PACKAGE_FIELDS,
  ITEM_FIELDS,
  BOOTH_FIELDS,
  APPLICATION_FIELDS
} from "Sponsors/PackageTable/constants";

import SelectPrice from "ui-kit/SelectPrice/View";
import DeleteConfirmation from "ui-kit/DeleteConfirmation";

import {
  Text1,
  Text2,
  Text3,
  DeleteIcon,
  SettingsIcon,
  Div,
  EditIcon
} from "components/Base";

import { Input, TextAreaInput, Toggle, Dropdown, Row, Column } from "ui-kit/Form/View";
import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";

const Title = WithThemeConsumer({ variant: VARIANTS.SURFACE })(
  ({ Th = defaultTh, children }) => (
    <Text2 {...Th(["color"], { fw: 2 })}>{children}</Text2>
  )
);

const columnsDef = {
  addPrice: {
    header: noop,
    cell: ({ Th = defaultTh, item, handlers: { onSetPrices = noop } }) => {

      return (
      <Column ml={2} width={269} mt={2}>
        <SelectPrice
          iniPrices={item.prices}
          instanceId={item.id}
          onCloseMenu={prices => onSetPrices({ id: item.id, prices })}
        >
          {onClick => (
            <Text2
              {...Th(["color"], { bold: true, active: true, mb: 2 })}
              onClick={onClick}
              data-cy="addPrice"
            >
              {item.prices && item.prices.length ? "+ Add Another Price" : "+ Add Price"}
            </Text2>
          )}
        </SelectPrice>
      </Column>
    );
          }
  },
  include: {
    header: () => (
      <Column width={70} ml={1}>
        <Title>INCLUDE</Title>
      </Column>
    ),
    cell: ({ item }) => (
      <Div width={70} ml={1} display="column.center.center">
        <Toggle
          size="tiny"
          instanceId={item.id}
          fieldId={APPLICATION_FIELDS.INCLUDE}
          disabled={item.autoEnabled}
        />
      </Div>
    )
  },
  require: {
    header: () => (
      <Column width={60} ml={1}>
        <Title>REQUIRE</Title>
      </Column>
    ),
    cell: ({ item }) => (
      <Div width={60} ml={1} display="column.center.center">
        <Toggle
          size="tiny"
          instanceId={item.id}
          fieldId={APPLICATION_FIELDS.REQUIRE}
          disabled={item.autoEnabled}
        />
      </Div>
    )
  },
  fieldName: {
    header: () => (
      <Column flex={1} ml={1}>
        <Title>FIELD NAME</Title>
      </Column>
    ),
    cell: ({ item, Th = defaultTh }) => (
      <Column flex={1} ml={1}>
        <Text3 {...Th(["color"])}>{item.name}</Text3>
        {item.caption && <Text1>{item.caption}</Text1>}
      </Column>
    )
  },
  itemName: {
    header: () => (
      <Column flex={1} ml={1}>
        <Title>ITEM NAME*</Title>
      </Column>
    ),
    cell: ({ item }) => (
      <Column flex={1} ml={1}>
        <Input
          instanceId={item.id}
          fieldId={BOOTH_FIELDS.NAME}
          width={1}
          placeholder="Name..."
          name="name"
        />
      </Column>
    )
  },
  packageName: {
    header: () => (
      <Column flex={1} ml={1}>
        <Title>PACKAGE NAME*</Title>
      </Column>
    ),
    cell: ({ item }) => (
      <Column flex={1} ml={1}>
        <Input
          instanceId={item.id}
          fieldId={PACKAGE_FIELDS.NAME}
          width={1}
          placeholder="Name..."
          name="name"
        />
      </Column>
    )
  },
  description: {
    header: () => (
      <Column flex={1} ml={1}>
        <Title>DESCRIPTION</Title>
      </Column>
    ),
    cell: ({ item }) => (
      <Column flex={1} ml={1}>
        <Div fs={1} uppercase color="gray6" mb={1}>Description</Div>
        <TextAreaInput
          instanceId={item.id}
          fieldId={PACKAGE_FIELDS.DESCRIPTION}
          width={1}
          placeholder="Add a description..."
          name="description"
        />
      </Column>
    )
  },
  limit: {
    header: () => (
      <Column ml={1} width={100}>
        <Title>TOTAL LIMIT</Title>
      </Column>
    ),
    cell: ({ item }) => (
      <Column ml={1} width={100}>
        <Input instanceId={item.id} fieldId={BOOTH_FIELDS.LIMIT} width={1} />
      </Column>
    )
  },
  category: {
    header: () => (
      <Column ml={1} width={200}>
        <Title>CATEGORY</Title>
      </Column>
    ),
    cell: ({ item }) => (
      <Column ml={1} width={200}>
        <Dropdown
          instanceId={item.id}
          fieldId={ITEM_FIELDS.CATEGORY}
          width="100%"
          clearable={false}
          options={R.propOr([], "groups", item)}
          placeholder="Select category..."
        />
      </Column>
    )
  },
  actions: {
    header: () => (
      <Column ml={1} width={65}>
        <Title>ACTIONS</Title>
      </Column>
    ),
    cell: ({
      item,
      Th = defaultTh,
      handlers: { onDelete = noop, onSettings = noop }
    }) => (
      <Row display="row.flex-start.center" ml={1} width={65}>
        <DeleteConfirmation
          onConfirm={() => onDelete(item.id)}
          text="Are you sure you want to delete this item?"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}
          data-cy="deleteRowGroup"
        >
          <DeleteIcon {...Th(["bg"], { sizeWFS: 5, active: true })} />
        </DeleteConfirmation>
        <SettingsIcon
          {...Th(["bg"], { ml: 2, sizeWFS: 5, active: true })}
          onClick={() => onSettings(item.id)}
          data-cy="settingsRowGroup"
        />
      </Row>
    )
  },
  fieldActions: {
    header: () => (
      <Column ml={1} width={65}>
        <Title>ACTIONS</Title>
      </Column>
    ),
    cell: ({
      item,
      Th = defaultTh,
      handlers: { onDeleteField = noop, onEditField = noop },
    }) => {
      return (
      <Row display="row.flex-start.center" ml={1} width={65}>
        {item.source === 'custom' ? (
          <>
        <DeleteConfirmation
          onConfirm={() => onDeleteField({ moduleId: item.module_id, fieldId: item.id })}
          text="Are you sure you want to delete this field?"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
          }}
          data-cy="deleteRowGroup"
        >
          <DeleteIcon {...Th(["bg"], { sizeWFS: 5, active: true })} />
        </DeleteConfirmation>
        <EditIcon
          {...Th(["bg"], { ml: 2, sizeWFS: 5, active: true })}
          onClick={() => onEditField(item.module_id, item.id)}
          data-cy="settingsRowGroup"
        />
        </>
        ) : null}
      </Row>
    );
        }
  }
};

export default columnsDef;
