import * as R from "ramda";
import React from "react";
import PropTypes from "prop-types";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import * as flags from "utils/feature-flags";
import { selectFeatureFlag } from "@flopflip/react-redux";
import { connect } from "react-redux";
import SimpleFormInputWrapper from "../SimpleFormInputWrapper/view";
import { noop } from "utils/General";
const decorate = R.compose(
  connect(
    state => ({
      canShowSimpleSideBar: selectFeatureFlag(flags.SIMPLE_FORMS.NAME)(state)
    }),
    {}
  ),
  CSSModules(css)
);
const FormInputWrapper = ({
  children = null,
  disabled = false,
  isEditing = false,
  isValid = true,
  errorMessages = [],
  canShowSimpleSideBar = false,
  fieldData = null,
  isShown = false,
  setIsShown = noop,
  connectDragSource = noop,
  isChildren = false
}) => {
  const messages =
    errorMessages && errorMessages.length
      ? errorMessages.map(message => (
          <li styleName="errorText" key={message}>
            {message}
          </li>
        ))
      : [];
  return !canShowSimpleSideBar || isChildren || !isEditing ? (
    <div
      data-section-error={!isValid}
      styleName={
        isValid
          ? disabled
            ? "containerDisabled"
            : "container"
          : "containerInvalid"
      }
    >
      {!isValid && (
        <i className="material-icons" styleName="icon">
          error
        </i>
      )}
      {children}
      {!isValid && messages.length ? (
        <ul styleName="errorList">{messages}</ul>
      ) : (
        ""
      )}
    </div>
  ) : (
    <SimpleFormInputWrapper
      fieldData={fieldData}
      instanceId={fieldData ? fieldData.id : null}
      children={children}
      isShown={isShown}
      setIsShown={setIsShown}
      connectDragSource={connectDragSource}
    />
  );
};

FormInputWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  isEditing: PropTypes.bool,
  isValid: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessages: PropTypes.array,
  canShowSimpleSideBar: PropTypes.bool,
  fieldData: PropTypes.object
};

export default decorate(FormInputWrapper);
