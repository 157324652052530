import React from "react";
import { Div, LoadingIcon } from "components/Base";

const LoadingOverlay = ({ transparent = false, style = {}, ...styleProps }) => (
  <Div
    style={{
      position: "absolute",
      width: "100%",
      height: "100%",
      top: 0,
      zIndex: 10,
      ...style
    }}
    bg={transparent ? "transparent" : "rgba(255, 255, 255, 0.74)"}
    display="row.center.center"
    {...styleProps}
    data-cy="loading-overlay"
  >
    <LoadingIcon size={80} color="#cccccc" />
  </Div>
);

export default LoadingOverlay;
