import React from "react";
import { Div } from "components/Base";
import { connect } from "react-redux";

import { getSidebarTitle } from "EventLight/Common/Dashboard/selectors";

const decorate = connect((state, props) => ({
  title: getSidebarTitle(state, props)
}));

const Title = ({ title }) => {
  return title ? (
    <Div
      fs={5}
      color="black"
      fw={3}
      style={{
        padding: "10px 5px 5px 5px;"
      }}
    >
      {title}
    </Div>
  ) : null;
};

export default decorate(Title);
