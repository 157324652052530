import { withRouter } from "react-router";
import { connect } from "react-redux";
import Controller from "./Controller";
import { canUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

function mapStateToProps(state, props) {
  return {
    cando: canUserDo(state, props.params.orgId || props.params.eventId)
  };
}

export default connect(mapStateToProps)(withRouter(Controller));
