import { combineReducers } from "redux";
import collaborators from "../permissionCollaborators/reducer";
import {
  UPDATE_ROLE_PERMISSIONS,
  ADD_OR_UPDATE_ROLE,
  DELETE_ROLE,
  FETCH_RECEIVE,
  FETCH_REQUEST,
  RECEIVE_ROLES,
  SAVE_RECEIVE,
  SAVE_REQUEST,
  CLEAR_LAST_SAVE,
  ERROR,
  CLEAR_ERROR
} from "./constants";

const list = (state = {}, action) => {
  let result;
  switch (action.type) {
    case RECEIVE_ROLES:
      return action.payload.roles.reduce((map, role) => {
        map[role.id] = role;
        return map;
      }, {});
    case UPDATE_ROLE_PERMISSIONS:
      result = { ...state };
      result[action.payload.role] = {
        ...result[action.payload.role],
        permissions: action.payload.permissions
      };
      return result;
    case ADD_OR_UPDATE_ROLE:
      /**
       * Payload should be an object
       * { [role.id]: role }
       */
      return {
        ...state,
        ...action.payload
      };
    case DELETE_ROLE:
      result = { ...state };
      delete result[action.payload.id];
      return result;
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_REQUEST:
      return true;
    case FETCH_RECEIVE:
      return false;
    default:
      return state;
  }
};

const saving = (
  state = {
    isSaving: false,
    saved: null
  },
  action
) => {
  switch (action.type) {
    case SAVE_REQUEST:
      return {
        isSaving: true
      };
    case SAVE_RECEIVE:
      return {
        isSaving: false,
        saved: Date.now()
      };
    case CLEAR_LAST_SAVE:
      return {
        saved: null
      };
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case ERROR:
      return action.payload;
    case CLEAR_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  collaborators,
  list,
  saving,
  fetching,
  error
});
