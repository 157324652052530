const reportIds = {
  assets: "8a55f2b6-483b-4eaa-bddd-fc0c0b5ee19a",
  documents: "b42c6991-9ef9-4125-a55a-56213e69bc56",
  forms: "8647d858-d48b-44d6-9acc-0ebd75753ed3",
  people: "2437d858-d48b-44d6-9acc-0ebd75753ed5",
  credentials: "9a22f2b6-483b-4eaa-bddd-fc0c0b5ee19b",
  catering: "4a24f2b6-383b-4eaa-aedd-fc0c0b5ee19a"
};

export default function resolveReportViewId(reportName) {
  return reportIds[reportName];
}
