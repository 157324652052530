import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { getters } from "../model";

import { ActionSection, ActionItem } from "PortalHub/ActionComponents/View";

import { CARD_ACTIONS } from "../constants";

const mediaButtons = [
  {
    label: "Visit Media Hub",
    id: CARD_ACTIONS.VISIT_MEDIA_HUB,
    rightIcon: "rightIcon"
  }
];

const decorate = connect((state, props) => ({
  mediaHub: getters.mediaHub(state, props)
}));

const MediaHub = ({ mediaHub = {}, showDivider = true }) => {
  if (!mediaHub || R.isEmpty(mediaHub)) {
    return null;
  }
  return (
    <ActionSection title="Media Hub" showDivider={showDivider}>
      <ActionItem
        icon="photo_camera"
        title={mediaHub.label}
        buttons={mediaButtons}
        data={mediaHub}
      />
    </ActionSection>
  );
};

export default decorate(MediaHub);
