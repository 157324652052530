import * as R from "ramda";

import { select, take, all, fork, call, put } from "redux-saga/effects";
import { defaultInstanceId } from "redux-mvc";
import moment from "moment";

import { actions, getters } from "./model";
import { getFirstEventDate, getLoadInDate } from "./selectors";

import { COMMIT, REVERT } from "redux-optimist";

const init = function*(instances) {
  for (;;) {
    const { payload, meta } = yield take(actions.init.type);
    instances[meta.instanceId || defaultInstanceId] = payload.calendar;
  }
};

const nextPrev = function*(instances) {
  for (;;) {
    const { type, meta } = yield take([actions.next.type, actions.prev.type]);

    const calendar = instances[meta.instanceId];
    if (calendar) {
      yield call([
        calendar,
        type === actions.next.type ? calendar.next : calendar.prev
      ]);
    }
  }
};

const setDate = function*(instances) {
  for (;;) {
    const { payload, meta } = yield take([
      actions.setCurrentDate.type,
      actions.setEventDate.type,
      actions.jump.type
    ]);

    const calendar = instances[meta.instanceId];
    if (calendar) {
      yield call([calendar, calendar.gotoDate], moment(payload).toISOString());
    }
  }
};

const watchEventResize = function*(instances) {
  for (;;) {
    const { payload: oldEvent } = yield take(actions.eventResizeStart.type);
    const { payload, optimist, meta } = yield take(
      actions.eventResizeResponse.type
    );

    if (optimist.type === REVERT) {
      const calendar = instances[meta.instanceId];
      if (calendar) {
        const event = yield call(
          [calendar, calendar.getEventById],
          oldEvent.id
        );
        yield call([event, event.setEnd], oldEvent.end);
      }
    } else {
      yield put(actions.addUpdatedEvent(payload, { meta }));
    }
  }
};

const watchEventDrag = function*(instances) {
  for (;;) {
    const { payload: newEvent, optimist, meta } = yield take(
      actions.eventDropResponse.type
    );

    if (optimist.type === COMMIT) {
      const calendar = instances[meta.instanceId];
      if (calendar) {
        const event = yield call(
          [calendar, calendar.getEventById],
          newEvent.id
        );
        yield call([event, event.setProp], "resourceId", newEvent.resourceId);
      }
      yield put(actions.addUpdatedEvent(newEvent, { meta }));
    }
  }
};

const watchSetData = function*() {
  const { meta } = yield take(actions.setResources.type);

  const currentDate = yield select(getters.currentDate, {
    instanceId: meta.instanceId
  });
  const firstDate = yield select(getFirstEventDate, {
    instanceId: meta.instanceId
  });
  const loadInDate = yield select(getLoadInDate, {
    instanceId: meta.instanceId
  });
  const timezone = yield select(getters.timezone, {
    instanceId: meta.instanceId
  });

  yield put(
    actions.setEventDate(
      moment(firstDate || loadInDate || currentDate).tz(timezone),
      { meta }
    )
  );
};

const rootSaga = function*() {
  const instances = {};
  yield all([
    fork(init, instances),
    fork(nextPrev, instances),
    fork(setDate, instances),
    fork(watchEventResize, instances),
    fork(watchEventDrag, instances),
    fork(watchSetData)
  ]);
};

export default rootSaga;
