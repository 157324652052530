import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import CircularProgress from "material-ui/CircularProgress";

const Loading = () => (
  <div styleName="progress">
    <CircularProgress color="#ccc" />
  </div>
);

export default CSSModules(Loading, css);
