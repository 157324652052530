import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Sidebar from "./Sidebar";
import * as R from "ramda";
import { withRouter } from "react-router";

import * as PortalSelectors from "redux/modules/portal/selectors";
import * as UserSelectors from "redux/modules/user/selectors";
import * as snackbarActions from "redux/modules/snackbar/actions";
import * as modalActions from "redux/modules/modal/actions";
import * as PortalUserSelectors from "redux/modules/portal/user/selectors";
import { settings } from "redux/modules/portal/settings/selectors";

import * as flags from "utils/feature-flags";
import { selectFeatureFlag } from "@flopflip/react-redux";

function mapStateToProps(state) {
  return {
    accounts: PortalUserSelectors.accounts(state),
    eventDetails: PortalSelectors.eventDetails(state),
    portal: PortalSelectors.portal(state),
    portalUser: PortalUserSelectors.portalUser(state),
    user: UserSelectors.user(state),
    sections: R.propOr([], "sections")(settings(state)),
    settings: R.propOr({}, "settings")(settings(state)),
    flagCanViewPeopleTabOnPortals: selectFeatureFlag(
      flags.CAN_VIEW_PEOPLE_TAB_ON_PORTALS.NAME
    )(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...modalActions,
      ...snackbarActions
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Sidebar)
);
