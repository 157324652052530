import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../../model";
import { TASKS_INSTANCE_ID, CARD_TYPES, EXPERIENCE_URL } from "../../constants";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import { WithFormInstanceProvider } from "ui-kit/Form/View";
import CollapsableCard from "../CollapsableCard";

const columns = [
  {
    name: "Name",
    value: "name",
    style: { flex: 1.2 },
    type: "text",
    showTooltip: true
  },
  {
    name: "Alias",
    value: "alias",
    style: { flex: 1 },
    type: "text",
    showTooltip: true
  },
  {
    name: "Due Date",
    value: "close_date_formatted",
    style: { flex: 1.4, fontSize: 15 },
    type: "text",
    showTooltip: true
  },
  {
    name: "Required",
    value: "required",
    type: "required",
    style: { width: 80, textAlign: "center" }
  },
  {
    name: "Actions",
    value: "actions",
    style: { width: 80, textAlign: "center" },
    type: "actions"
  }
];

const decorate = R.compose(
  WithFormInstanceProvider(R.always(TASKS_INSTANCE_ID)),
  connect(
    state => ({
      assignedTasks: getters.assignedTasks(state),
      loadingTasks: getters.loadingTasks(state)
    }),
    {
      goToLearnLennd: actions.goToLearnLennd,
      openModal: () => actions.openAssignModal(CARD_TYPES.TASKS),
      goToTasks: actions.goToTasks,
      deleteAssginedTask: actions.deleteAssginedTask,
      openUpdateModal: ({ id }) =>
        actions.openUpdateModal({ assignModalType: CARD_TYPES.TASKS, id }),
      reorderTasks: actions.reorderTasks
    }
  ),
  CSSModules(css)
);

const Tasks = ({
  goToLearnLennd,
  assignedTasks,
  goToTasks,
  openModal,
  loadingTasks,
  deleteAssginedTask,
  openUpdateModal,
  reorderTasks
}) => (
  <CollapsableCard
    title="Assign Custom Tasks"
    description="Assign tasks for users to complete out on this portal."
    btnText="tasks"
    goToLearn={() =>
      goToLearnLennd({ expectedExperienceUrl: EXPERIENCE_URL.assign_tasks })
    }
    loading={loadingTasks}
    openModal={openModal}
    goToManage={goToTasks}
    deleteRow={deleteAssginedTask}
    openUpdateModal={openUpdateModal}
    assignedRows={assignedTasks}
    assignedRowsText="Task"
    onReorder={reorderTasks}
    emptyText="You haven't assigned any tasks to this portal yet."
    columns={columns}
  />
);

export default decorate(Tasks);
