import Layout from "./Layout";
import { createContext } from "redux-mvc";
import module from "Portal/POSPeople";

const decorate = createContext({
  module,
  options: {
    persist: false
  }
});

export default decorate(Layout);
