import PropTypes from "prop-types";
import React, { Component } from "react";
import File from "components/Atoms/File";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FilePreviewer extends Component {
  render() {
    return (
      <div className={["ignore-react-onclickoutside", css.container].join(" ")}>
        {!this.props.isReadOnly ? (
          <div styleName="upload" onClick={this.props.showFilepicker}>
            <div styleName="uploadIcon">
              <i className="material-icons" styleName="plus">
                add
              </i>
              <i className="material-icons" styleName="clip">
                attach_file
              </i>
            </div>
          </div>
        ) : null}

        {this.props.files.map(file => (
          <File
            key={file.id}
            file={file}
            onClick={f => this.props.onFileClick(f)}
            downloadFile={this.props.downloadFile}
            deleteFile={this.props.deleteFile}
            isReadOnly={this.props.isReadOnly}
          />
        ))}
      </div>
    );
  }
}

FilePreviewer.propTypes = {
  files: PropTypes.array.isRequired,
  showFilepicker: PropTypes.func.isRequired,
  onFileClick: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool
};

export default FilePreviewer;
