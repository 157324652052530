import React from "react";
import * as R from "ramda";

import { Div, MediumOutlineButton } from "components/Base";
import CollapsablePanel from "ui-kit/CollapsiblePanel";
import CSSModules from "react-css-modules";
import css from "../onboard.scss";
import { connect } from "react-redux";
import { getIntakeForm } from "../../selectors";
import { actions, getters } from "../../model";
import { LoadingIcon } from "components/Base";
import NotConfigured from "../NotConfigured";
import { EXPERIENCE_URL } from "../../constants";

const decorate = R.compose(
  connect(
    (state, props) => ({
      intakeForm: getIntakeForm(state, props),
      loadingOnboard: getters.loadingOnboard(state)
    }),
    {
      init: actions.init,
      openIntakeSettingsModal: () => actions.setShowIntakeSettingsModal(true),
      copyIntakeFormLink: actions.copyIntakeFormLink,
      goToLearnLennd: actions.goToLearnLennd
    }
  ),
  CSSModules(css)
);

const Onboard = ({
  intakeForm,
  openIntakeSettingsModal,
  copyIntakeFormLink,
  goToLearnLennd,
  loadingOnboard
}) => (
  <Div flex={1} display="column.flex-start.stretch">
    <CollapsablePanel
      heading={() => <div className={css.title}>Onboard Users</div>}
      bg="white"
      arrowColor="gray7"
      bc="neutral3"
      right={false}
      bra={1}
      mb={3}
      p={3}
      collapsedDefault={false}
    >
      <div styleName="wrapper">
        {/*
        <div styleName="row">
          <div>
            <div styleName="header">
              <span class="material-icons" style={{ color: "#3C7669" }}>
                domain_verification
              </span>
              <div styleName="name">Application</div>
              <div styleName="configured">
                <span class="material-icons">do_disturb</span>
                Not configured
              </div>
            </div>
            <div styleName="description">
              Have users start by filling out an application. Submissions must
              be approved before users can access their portal.
            </div>
          </div>
          <div styleName="actions">
            <MediumOutlineButton
              color="primary7"
              bc="primary7"
              LeftIcon={() => (
                <span
                  class="material-icons"
                  style={{ fontSize: 12, marginRight: 8, height: 12 }}
                >
                  border_color
                </span>
              )}
              width={200}
            >
              Set up application
            </MediumOutlineButton>
            <>
              <MediumOutlineButton
                color="primary7"
                bc="primary7"
                LeftIcon={() => (
                  <span
                    class="material-icons"
                    style={{ fontSize: 12, marginRight: 8, height: 12 }}
                  >
                    border_color
                  </span>
                )}
                mr={2}
                width={82}
              >
                Edit
              </MediumOutlineButton>
              <MediumOutlineButton
                color="primary7"
                bc="primary7"
                LeftIcon={() => (
                  <span
                    class="material-icons"
                    style={{ fontSize: 16, marginRight: 8, height: 16 }}
                  >
                    link
                  </span>
                )}
              >
                Copy Link
              </MediumOutlineButton>
            </>
          </div>
        </div>
        */}

        <div styleName="row">
          <div>
            <div styleName="header">
              <span class="material-icons" style={{ color: "#DB874A" }}>
                feed
              </span>
              <div styleName="name">Intake Form</div>
              <NotConfigured
                configured={intakeForm.is_enabled}
                loading={loadingOnboard}
              />
            </div>
            <div styleName="description">
              Have users fill out a form and agree to your conditions to receive
              immediate access to their portal.
            </div>
          </div>
          {loadingOnboard ? (
            <div styleName="loading-wrapper">
              <LoadingIcon color="gray3" size={32} />
            </div>
          ) : (
            <div styleName="actions">
              {!intakeForm.is_enabled ? (
                <MediumOutlineButton
                  color="primary7"
                  bc="primary7"
                  LeftIcon={() => (
                    <span
                      class="material-icons"
                      style={{ fontSize: 12, marginRight: 8, height: 12 }}
                    >
                      border_color
                    </span>
                  )}
                  width={200}
                  onClick={openIntakeSettingsModal}
                >
                  Set up intake form
                </MediumOutlineButton>
              ) : (
                <>
                  <MediumOutlineButton
                    color="primary7"
                    bc="primary7"
                    LeftIcon={() => (
                      <span
                        class="material-icons"
                        style={{ fontSize: 12, marginRight: 8, height: 12 }}
                      >
                        border_color
                      </span>
                    )}
                    mr={2}
                    onClick={openIntakeSettingsModal}
                    width={82}
                  >
                    Edit
                  </MediumOutlineButton>
                  <MediumOutlineButton
                    color="primary7"
                    bc="primary7"
                    LeftIcon={() => (
                      <span
                        class="material-icons"
                        style={{ fontSize: 16, marginRight: 8, height: 16 }}
                      >
                        link
                      </span>
                    )}
                    onClick={copyIntakeFormLink}
                  >
                    Copy Link
                  </MediumOutlineButton>
                </>
              )}
            </div>
          )}
        </div>
        <div styleName="row">
          <div>
            <div styleName="header">
              <span class="material-icons" style={{ color: "#605DEC" }}>
                upload
              </span>
              <div styleName="name">Import</div>
            </div>
            <div styleName="description">
              Import a spreadsheet and then send users an email with
              instructions on how to access their portal
            </div>
          </div>
          <div styleName="actions">
            <MediumOutlineButton
              color="primary7"
              bc="primary7"
              LeftIcon={() => (
                <span
                  class="material-icons"
                  style={{ fontSize: 16, marginRight: 8, height: 16 }}
                >
                  import_contacts
                </span>
              )}
              width={200}
              onClick={() =>
                goToLearnLennd({
                  expectedExperienceUrl: EXPERIENCE_URL.onboarding_users
                })
              }
            >
              Learn about importing
            </MediumOutlineButton>
          </div>
        </div>
      </div>
    </CollapsablePanel>
  </Div>
);

export default decorate(Onboard);
