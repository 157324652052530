import api from "./api";
import { REQUEST, RECEIVE, INVALIDATE } from "./constants";
import { isFetching } from "./selectors";
import { registerError } from "redux/modules/errors/actions";

export function getActivities({ eventId, recordId, source }) {
  return async (dispatch, getState) => {
    if (isFetching(getState(), recordId)) return null;
    try {
      dispatch({
        type: REQUEST,
        payload: {
          recordId
        }
      });
      const result = await api.get(getState().user.user.credentials, {
        recordId,
        options: {
          eventId,
          source
        }
      });
      dispatch({
        type: RECEIVE,
        payload: {
          recordId,
          activities: result.payload
        }
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting activities"
          }
        ])
      );
    }
  };
}

export function invalidateActivities(recordId) {
  return {
    type: INVALIDATE,
    payload: {
      recordId
    }
  };
}
