export const NAMESPACE = "ManageAlerts";
export const TABLE_INSTANCE_ID = "ManageAlertsTable";

export const WIDTH = "825px";

export const STATUS = {
  OPEN: "open",
  CLOSED: "closed"
};

export const ROW_ACTIONS = {
  EDIT: "edit",
  DELETE: "delete"
};

export const ACTIONS_COL_ID = "actions-button-custom";

const STATUS_COLORS = {
  Published: { light: "#1EBCA5", dark: "#179C89" },
  Draft: { light: "#DDD6E4", dark: "#8B8295" },
  Scheduled: { light: "#F4C172", dark: "#E29F36" }
};

export const FIELDS = [
  {
    id: "name",
    name: "Alert Name",
    type: "text"
  },
  {
    id: "channels",
    name: "Channels",
    type: "text"
  },
  /* {
    id: "send_at",
    name: "Sent At",
    type: "text"
  }, */
  {
    id: "status",
    name: "Status",
    type: "status",
    settings: {
      statusColors: STATUS_COLORS
    }
  },
  {
    id: "sent_at",
    name: "Sent At",
    type: "text"
  },
  {
    id: ACTIONS_COL_ID,
    type: ACTIONS_COL_ID,
    name: "Actions",
    settings: {
      actions: [
        {
          id: ROW_ACTIONS.EDIT,
          name: "Edit Alert",
          leftIcon: "edit"
        },
        {
          id: ROW_ACTIONS.DELETE,
          name: "Delete",
          confirm: "Are you sure you want to delete this alert?"
        }
      ]
    }
  }
];

export const DEFAULT_COLUMN_WIDTHS = {
  name: 250,
  channels: 150,
  sent_at: 150,
  status: 150,
  [ACTIONS_COL_ID]: 100
};
