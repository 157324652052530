import moment from "moment-timezone";
import Helpers from "utils/Global/Helpers";

// @NOTE: Guessing at user's local timezone to render
// URL: https://github.com/moment/moment/issues/162#issuecomment-288825858
const DEFAULT_DISPLAY_FORMAT = "ddd. MMM D, YYYY - h:mmA zz";

export default (datetime, displayFormat) => {
  moment.locale(Helpers.getLocale());
  return moment(datetime)
    .tz(moment.tz.guess())
    .format(displayFormat || DEFAULT_DISPLAY_FORMAT);
};
