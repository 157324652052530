import React from "react";
import { put, takeEvery, all, fork, call, select } from "redux-saga/effects";
import { actions, getters } from "./model";
import { makeFuture } from "utils/General/sagas";
import { showModal, hideModal } from "redux/modules/modal/actions";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { registerError } from "redux/modules/errors/actions";
import { DATA } from "./mockData";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import { eventId as getEventId } from "redux/modules/event/selectors";
import RecordTypeModal from "components/Event/Settings/Module/Modals/RecordType";
import ChangeRecordTypeModal from "components/Global/CRM/Modals/ChangeRecordType";
import { showSnackbar } from "redux/modules/snackbar/actions";
import Api from "./api";
import { getCredentials } from "redux/modules/user/selectors";

const init = function*({ payload: { moduleId } }) {
  try {
    yield put(actions.setLoading(true));
    yield put(actions.setInitialData({ data: DATA, moduleId }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error loading data table"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const showAddRecordModal = function*({ typeId, typeName, moduleId }) {
  const handleIssuance = makeFuture();
  yield put(
    showModal({
      content: (
        <AddRecordModal
          moduleId={moduleId}
          onSave={() => {
            handleIssuance.done({ type: "done" });
          }}
          onlyRecordTypeId={typeId}
          onlyRecordTypeName={typeName}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleIssuance.onRealized);
};

const showEditRecordTypeModal = function*({ typeId, moduleId }) {
  const handleIssuance = makeFuture();
  yield put(
    showModal({
      content: (
        <ChangeRecordTypeModal
          moduleId={moduleId}
          typeId={typeId}
          update={() => {
            handleIssuance.done({ type: "done" });
          }}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleIssuance.onRealized);
};

const handleEditRecordTypeModal = function*({ payload: { typeId } }) {
  const moduleId = yield select(getters.moduleId);

  const result = yield call(showEditRecordTypeModal, {
    typeId,
    moduleId
  });

  if (result.type === "done") {
    yield all([put(actions.init({ moduleId })), put(hideModal)]);
  }
};

const handleAddRecordModal = function*({ payload: { typeId, typeName } }) {
  const moduleId = yield select(getters.moduleId);
  const result = yield call(showAddRecordModal, { typeId, typeName, moduleId });

  if (result.type === "done") {
    yield all([put(actions.init({ moduleId })), put(hideModal)]);
  }
};

const showRecordTypeModal = function*() {
  const moduleId = yield select(getters.moduleId);
  const eventId = yield select(getEventId);
  const handleIssuance = makeFuture();

  yield put(
    showModal({
      content: (
        <RecordTypeModal
          onSave={data => handleIssuance.done({ type: "done", data })}
          moduleId={moduleId}
          eventId={eventId}
        />
      ),
      wrapper: ModalWrapper
    })
  );

  return yield call(handleIssuance.onRealized);
};

const handleRecordTypeModal = function*() {
  const moduleId = yield select(getters.moduleId);
  const result = yield call(showRecordTypeModal);
  const eventId = yield select(getEventId);
  const credentials = yield select(getCredentials);

  if (result.type === "done") {
    try {
      const { layout } = yield call(Api.addLayout, {
        credentials,
        data: {
          moduleId,
          layout: {
            eventId
          }
        }
      });

      yield call(Api.addRecordType, {
        credentials,
        data: {
          moduleId,
          recordType: {
            ...result.data,
            layoutId: layout.id
          },
          options: {
            eventId
          }
        }
      });

      yield all([
        put(actions.init({ moduleId })),
        put(hideModal),
        put(showSnackbar({ message: "Type added", action: "OK" }))
      ]);
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error ocurred adding type"
          }
        ])
      );
    }
  }
};

const setManager = function*() {};

const setApprover = function*() {};

const enablePeopleTypes = function*() {};

const enableItemCatalogAccess = function*() {};

const assignForms = function*() {};

const assignDocRequest = function*() {};

const assignTask = function*() {};

const copyInviteLink = function*() {};

const viewLink = function*() {};

const editLink = function*() {};

const uploadFiles = function*() {};

const allocateMeals = function*() {};

const allocatePass = function*() {};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchHandleAddRecordModal = function*() {
  yield takeEvery(actions.handleAddRecordModal.type, handleAddRecordModal);
};

const watchHandleRecordTypeModal = function*() {
  yield takeEvery(actions.handleRecordTypeModal.type, handleRecordTypeModal);
};

const watchSetManager = function*() {
  yield takeEvery(actions.setManager.type, setManager);
};

const watchSetApprover = function*() {
  yield takeEvery(actions.setApprover.type, setApprover);
};

const watchEnablePeopleTypes = function*() {
  yield takeEvery(actions.enablePeopleTypes.type, enablePeopleTypes);
};

const watchEnableItemCatalogAccess = function*() {
  yield takeEvery(
    actions.enableItemCatalogAccess.type,
    enableItemCatalogAccess
  );
};

const watchAssignForms = function*() {
  yield takeEvery(actions.assignForms.type, assignForms);
};

const watchAssignDocRequest = function*() {
  yield takeEvery(actions.assignDocRequest.type, assignDocRequest);
};

const watchAssignTask = function*() {
  yield takeEvery(actions.assignTask.type, assignTask);
};

const watchCopyInviteLink = function*() {
  yield takeEvery(actions.copyInviteLink.type, copyInviteLink);
};

const watchViewLink = function*() {
  yield takeEvery(actions.viewLink.type, viewLink);
};

const watchEditLink = function*() {
  yield takeEvery(actions.editLink.type, editLink);
};

const watchUploadFiles = function*() {
  yield takeEvery(actions.uploadFiles.type, uploadFiles);
};

const watchAllocateMeals = function*() {
  yield takeEvery(actions.allocateMeals.type, allocateMeals);
};

const watchAllocatePass = function*() {
  yield takeEvery(actions.allocatePass.type, allocatePass);
};

const watchHandleEditRecordTypeModal = function*() {
  yield takeEvery(
    actions.handleEditRecordTypeModal.type,
    handleEditRecordTypeModal
  );
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchHandleAddRecordModal),
    fork(watchHandleRecordTypeModal),
    fork(watchHandleEditRecordTypeModal),
    fork(watchSetManager),
    fork(watchSetApprover),
    fork(watchEnablePeopleTypes),
    fork(watchEnableItemCatalogAccess),
    fork(watchAssignForms),
    fork(watchAssignDocRequest),
    fork(watchAssignTask),
    fork(watchCopyInviteLink),
    fork(watchViewLink),
    fork(watchEditLink),
    fork(watchUploadFiles),
    fork(watchAllocateMeals),
    fork(watchAllocatePass)
  ]);
};

export default rootSaga;
