import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Textarea from "react-textarea-autosize";
import * as FormActions from "redux/modules/formsV2/form/actions";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

const styles = {
  description: {
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 0,
    background: "none",
    width: "100%",
    fontSize: 18,
    color: "#5D5D5D",
    resize: "none",
    padding: 0
  }
};

@CSSModules(css)
class FormInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title || "",
      description: props.description || ""
    };
  }

  @autobind
  handleTitleChange(event) {
    this.setState({ title: event.target.value });
  }

  @autobind
  handleDescriptionChange(event) {
    this.setState({ description: event.target.value });
  }

  @autobind
  onBlur() {
    const { eventId, formId } = this.props;
    const { title, description } = this.state;

    this.props.dispatch(
      FormActions.updateForm({
        formId,
        title,
        description
      })
    );
  }

  componentWillUpdate(nextProps) {
    if (
      nextProps.title !== this.props.title ||
      nextProps.description !== this.props.description
    ) {
      this.setState({
        title: nextProps.title,
        description: nextProps.description
      });
    }
  }

  render() {
    const { title, description } = this.state;
    return (
      <div>
        <input
          ref="titleInput"
          type="text"
          placeholder="Your form title"
          styleName="title"
          onBlur={this.onBlur}
          onChange={this.handleTitleChange}
          value={title}
        />
        <Textarea
          placeholder="Description of your form"
          style={styles.description}
          onBlur={this.onBlur}
          onChange={this.handleDescriptionChange}
          value={description}
        />
      </div>
    );
  }
}

FormInfo.propTypes = {
  eventId: PropTypes.number.isRequired,
  formId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default FormInfo;
