import React, { Component } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import styleSpects from "components/Base/Base/styleSpecs";
import { getValue } from "../../utils";

import { ROW_ACTIONS_ITEMS } from "Portal/PortalPeople/constants";

import { actions } from "../../model";

const decorate = connect(
  null,
  bindInstance({
    execute: actions.executeAction
  })
);

class SubmissionActions extends Component {
  addToEvent = () => {
    const { data: row, field: column } = this.props;

    this.props.execute({
      actionId: ROW_ACTIONS_ITEMS.ADD_TO_EVENT,
      row,
      column
    });
  };

  render() {
    const isEventMember = getValue(this.props, "is_event_member");

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          fontSize: "14px"
        }}
      >
        {isEventMember ? (
          <div
            style={{
              height: "25px",
              borderRadius: "6px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              backgroundColor: "#F3F5FE",
              padding: "0 9px 0 5px"
            }}
          >
            <i
              className="material-icons"
              style={{
                color: "#302E2D",
                fontSize: "16px"
              }}
            >
              check
            </i>
            <div
              style={{
                marginLeft: "4px",
                fontWeight: 600,
                fontSize: "11px",
                color: "#302E2D"
              }}
            >
              Added to event
            </div>
          </div>
        ) : (
          <div
            onClick={this.addToEvent}
            className="underline-on-hover"
            style={{
              color: styleSpects.color.primary9
            }}
          >
            Add to event
          </div>
        )}
      </div>
    );
  }
}

export default decorate(SubmissionActions);
