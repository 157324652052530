import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { showModal } from "redux/modules/modal/actions";

import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router";
import {
  MediumShadedButton,
  Div,
  Text3,
  BigTextButton,
  LeftArrowIcon
} from "components/Base";
import * as R from "ramda";
import AddNoteForm from "components/Global/CRM/AddNoteForm";
import Note from "components/Global/CRM/Note";
import ViewMoreModal from "components/Global/CRM/Modals/ViewMoreModal";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

class ProfileView extends Component {
  showNotesModal = notes =>
    this.props.showModal({
      content: <ViewMoreModal data={notes} heading="Notes" />,
      wrapper: ModalWrapper
    });

  render() {
    const {
      centerContent,
      currentUser,
      leftContent,
      notes,
      onAddNote,
      returnToPath,
      returnToText,
      rightContent,
      showModal,
      tabs,
      showNotes
    } = this.props;

    const noteComps = R.compose(
      R.map(n => (
        <Div key={n.created_at} bg="white" mb={1} ba={1} bra={1} bc="neutral3">
          <Note
            authorUrl={n.authorUrl}
            note={n.note}
            currentUser={currentUser}
            showModal={showModal}
            onUpdate={n.onUpdate}
            onDelete={n.onDelete}
          />
        </Div>
      )),
      R.sort((a, b) => Date.parse(a.created_at) - Date.parse(b.created_at))
    )(notes || []);

    return (
      <Div
        // height={1}
        pl={15}
        pr={10}
        pb={10}
        style={{
          minHeight: "100%",
          backgroundColor: "#ffffff",
          backgroundSize: "auto",
          backgroundImage:
            "url(https://d2dks4tzxs6xee.cloudfront.net/img/default-bg-image.png)"
        }}
      >
        <Div
          bg="primary7"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%"
          }}
        />
        {returnToPath ? (
          <Div pt={4} pb={2}>
            <Link to={{ pathname: returnToPath }}>
              <BigTextButton
                color="primary7"
                onClick={() => {}}
                LeftIcon={LeftArrowIcon}
                isPadded={false}
              >
                {returnToText}
              </BigTextButton>
            </Link>
          </Div>
        ) : (
          <Div pt={4} pb={2} height={25}></Div>
        )}
        <Div maxWidth={1350} display="row.flex-start.flex-start">
          <Div
            width={385}
            style={{
              flexShrink: 0
            }}
          >
            {R.compose(
              R.map(a => <Div mb={3}>{a}</Div>),
              R.filter(Boolean)
            )(leftContent || [])}
          </Div>
          <Div flex={1} width={0.52} mx={5}>
            {tabs && tabs.length > 1 ? (
              <Div mb={2} display="row.flex-start.center">
                {R.map(
                  ({ name, onClick, active }) => (
                    <Div
                      key={name}
                      style={
                        active
                          ? {
                              backgroundColor: "rgb(149, 72, 232);"
                            }
                          : null
                      }
                      onClick={active ? undefined : onClick}
                      mr={2}
                      bra={1}
                      fw={3}
                      px={2}
                      py={1}
                      color={
                        active
                          ? "white"
                          : {
                              default: "primary7",
                              hover: "primary9"
                            }
                      }
                    >
                      {name}
                    </Div>
                  ),
                  tabs
                )}
              </Div>
            ) : null}

            {showNotes ? (
              <Fragment>
                <AddNoteForm
                  {...{
                    addNote: onAddNote,
                    currentUser: currentUser
                  }}
                />
                {notes && notes.length ? (
                  <Div width={1}>
                    <Text3 uppercase bold pt={4} pb={2}>
                      recent notes ({notes.length})
                    </Text3>
                    {noteComps.slice(0, 3)}
                    {notes.length > 3 ? (
                      <MediumShadedButton
                        onClick={() => this.showNotesModal(noteComps)}
                        mt={2}
                      >
                        View More ({notes.length})
                      </MediumShadedButton>
                    ) : (
                      ""
                    )}
                  </Div>
                ) : (
                  ""
                )}
              </Fragment>
            ) : null}

            {R.compose(R.map(a => <Div mt={3}>{a}</Div>, R.filter(Boolean)))(
              centerContent || []
            )}
          </Div>
          {Boolean(rightContent && rightContent.length) && (
            <Div
              width={0.18}
              style={{
                flexShrink: 0
              }}
            >
              {R.compose(
                R.map(a => <Div mb={3}>{a}</Div>),
                R.filter(Boolean)
              )(rightContent)}
            </Div>
          )}
        </Div>
      </Div>
    );
  }
}

ProfileView.propTypes = {
  notes: PropTypes.shape({
    note: PropTypes.shape({
      id: PropTypes.string
    }),
    authorUrl: PropTypes.string,
    title: PropTypes.string,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func
  })
};

function mapStateToProps(state) {
  return {
    currentUser: state.user.user
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileView);
