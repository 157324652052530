import React from "react";
import * as R from "ramda";

import { connect } from "react-redux";
import { Div, BigFilledButton } from "components/Base";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";

import { actions, getters } from "../model";

import {
  FloatingSaveBar,
  Panel,
  PanelTitle,
  FieldWrapper,
  Label
} from "EventLight/Common/FieldLayout";

import { FIELD_IDS } from "../constants";

import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { json } from "@codemirror/lang-json";

const decorate = R.compose(
  connect(
    state => ({
      data: getters.data(state),
      eventDetails: getEventDetails(state)
    }),
    {
      save: actions.save,
      updateValue: actions.updateValue
    }
  )
);

const Body = ({ data, save, updateValue }) => {
  return (
    <Div width={1}>
      <Panel>
        <PanelTitle>Design</PanelTitle>

        <FieldWrapper>
          <Label>Individual Pass HTML Template</Label>
          <Div fs={2} color="gray7" mb={3}>
            This is HTML for the actual pass design. Each generated pass will
            use this template.
          </Div>
          <CodeMirror
            value={data.design_html}
            height="600px"
            extensions={[javascript()]}
            onChange={value => {
              updateValue({ id: FIELD_IDS.DESIGN_HTML, value });
            }}
          />
        </FieldWrapper>

        <FieldWrapper>
          <Label>Wrapper HTML Template</Label>
          <Div fs={2} color="gray7" mb={3}>
            This is HTML for the actual pass design. Each generated pass will
            use this template.
          </Div>
          <CodeMirror
            value={data.wrapper_html}
            height="300px"
            extensions={[javascript()]}
            onChange={value => {
              updateValue({ id: FIELD_IDS.WRAPPER_HTML, value });
            }}
          />
        </FieldWrapper>
      </Panel>

      <Panel>
        <PanelTitle>Settings</PanelTitle>

        <FieldWrapper>
          <Label>Settings JSON</Label>
          <Div fs={2} color="gray7" mb={3}>
            This is JSON object of settings that helps influence the output of
            the design.
          </Div>
          <CodeMirror
            value={data.settings_json}
            height="600px"
            extensions={[json()]}
            onChange={value => {
              updateValue({ id: FIELD_IDS.SETTINGS_JSON, value });
            }}
          />
        </FieldWrapper>
      </Panel>

      <FloatingSaveBar>
        <BigFilledButton bg="altB5" color="white" onClick={save} mr={4}>
          Save
        </BigFilledButton>
      </FloatingSaveBar>
    </Div>
  );
};

export default decorate(Body);
