import React from "react";
import { Div } from "components/Base";
import { connect } from "react-redux";
import { getters } from "../../model";

const decorate = connect((state, props) => ({
  session: getters.sessionDetails(state, props)
}));

const RelatedContent = ({ session: { related_content } }) => (
  <Div>RelatedContent</Div>
);

export default decorate(RelatedContent);
