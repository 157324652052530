import PropTypes from "prop-types";
import React, { Component } from "react";
import TextArea from "react-textarea-autosize";
import getTemplate from "components/Global/Module/Feed/Utils/get-template";
import getBodyTemplate from "components/Global/Module/Feed/Utils/get-body-template";
import getColor from "components/Global/Module/Feed/Utils/get-color";
import Tab from "components/Global/CRM/Tabs/Tab";
import Tabs from "components/Global/CRM/Tabs";
import AddNoteForm from "components/Global/CRM/AddNoteForm";
import Note from "components/Global/CRM/Note";
import Panel from "components/Global-2016/Panel";
import CSSModules from "react-css-modules";
import Item from "./Item";
import Avatar from "./Item/Avatar";
import Message from "./Item/Message";
import Body from "./Item/Body";
import Action from "./Item/Action";
import Timestamp from "./Item/Timestamp";
import css from "./styles.scss";

class Feed extends Component {
  constructor(props) {
    super(props);
    // this.input = React.createRef();
    this.commentBody = React.createRef();
  }

  componentDidMount() {
    this.fetchFirstScene();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.messages.length < this.props.messages.length) {
      this.scrollToLatestComment();
    } else if (oldProps.recordId !== this.props.recordId) {
      this.fetchFirstScene();
    }
  }

  fetchFirstScene() {
    // @NOTE: Don't attempt fetch if we don't have a record ID
    if (!this.props.recordId) return null;

    if (this.props.whitelist.includes("messages")) {
      return this.fetchMessages();
    } else if (this.props.whitelist.includes("notes")) {
      return this.fetchNotes();
    } else if (this.props.whitelist.includes("activity")) {
      return this.fetchActivity();
    }
  }

  scrollToLatestComment = () => {
    if (this.props.whitelist.includes("messages") && this.commentBody.current) {
      this.commentBody.current.scrollTop = this.commentBody.current.scrollHeight;
    }
  };

  fetchActivity = () => {
    const { moduleId, recordId, eventId } = this.props;
    return this.props.getActivity({
      moduleId,
      recordId,
      options: {
        eventId
      }
    });
  };

  fetchMessages = () => {
    const { moduleId, recordId, eventId } = this.props;
    return this.props
      .getMessages({
        moduleId,
        recordId,
        options: {
          eventId
        }
      })
      .then(this.scrollToLatestComment);
  };

  sendMessage = async ({ keyCode, target }) => {
    const { moduleId, recordId, eventId } = this.props;
    if (keyCode === 13 && target.value.length) {
      await this.props.addMessage({
        moduleId,
        recordId,
        message: {
          content: target.value
        },
        options: {
          eventId
        }
      });
      // @NOTE: Not using createRef because TextArea requires a function to be passed
      this.input.value = "";
      this.fetchMessages();
    }
  };

  fetchNotes = () => {
    const { moduleId, recordId, eventId } = this.props;
    return this.props.getNotes({
      moduleId,
      recordId,
      options: {
        eventId
      }
    });
  };

  addNote = note => {
    const { user, moduleId, recordId, eventId } = this.props;

    return this.props
      .addNote({
        moduleId,
        recordId,
        note,
        options: {
          eventId
        }
      })
      .then(() => this.fetchNotes());
  };

  deleteNote = noteId => {
    const { moduleId, recordId } = this.props;

    return this.props
      .deleteNote({
        moduleId,
        recordId,
        noteId
      })
      .then(() => this.fetchNotes());
  };

  updateNote = (noteId, note) => {
    const { moduleId, recordId } = this.props;

    return this.props
      .updateNote({
        moduleId,
        recordId,
        noteId,
        note
      })
      .then(() => this.fetchNotes());
  };

  render() {
    const {
      activity,
      className,
      eventId,
      isFetchingActivity,
      isFetchingMessages,
      isFetchingNotes,
      messages,
      name,
      notes,
      placeholder,
      user,
      showModal,
      hideModal,
      whitelist,
      style
    } = this.props;

    const noteProps = {
      currentUser: user,
      disabled: false,
      hideModal,
      params: { eventId },
      showModal,
      title: name
    };

    return (
      <div
        className={[css.container, className].filter(c => c).join(" ")}
        style={style}
      >
        <Tabs>
          {whitelist.includes("messages") ? (
            <Tab label="Messages" onActive={this.fetchMessages}>
              <div>
                <div styleName="items" ref={this.commentBody}>
                  {isFetchingMessages && !messages.length ? (
                    <div styleName="loading">Loading messages...</div>
                  ) : (
                    messages.map(item => (
                      <Item key={`${item.user.id}_${item.created_at}`}>
                        <Avatar color={getColor(item.user, user)} />
                        <Message>
                          <Action>{getTemplate(item, user)}</Action>
                          <Body>{getBodyTemplate(item)}</Body>
                          <Timestamp stamp={item.created_at} />
                        </Message>
                      </Item>
                    ))
                  )}
                </div>
                <div styleName="sendMessage">
                  <Avatar style={{ fontSize: 24, height: 35, width: 35 }} />
                  <TextArea
                    type="text"
                    placeholder={placeholder || "Send a message..."}
                    onKeyDown={this.sendMessage}
                    ref={ref => {
                      this.input = ref;
                    }}
                  />
                </div>
              </div>
            </Tab>
          ) : null}

          {whitelist.includes("notes") ? (
            <Tab label="Notes" onActive={this.fetchNotes}>
              <AddNoteForm
                currentUser={user}
                addNote={this.addNote}
                title={`this ${name.toLowerCase()}`}
              />
              <div styleName="noteList">
                {isFetchingNotes && !notes.length ? (
                  <div styleName="loading">Loading notes...</div>
                ) : (
                  notes
                    .sort((a, b) => b.created_at - a.created_at)
                    .map(note => (
                      <Panel style={css.panel} key={note.id}>
                        <Note
                          key={note.id}
                          note={note}
                          onUpdate={updatedNote =>
                            this.updateNote(note.id, updatedNote)
                          }
                          onDelete={() => this.deleteNote(note.id)}
                          {...noteProps}
                        />
                      </Panel>
                    ))
                )}
              </div>
            </Tab>
          ) : null}

          {whitelist.includes("activity") ? (
            <Tab label="Activity" onActive={this.fetchActivity}>
              <div styleName="items">
                {isFetchingActivity && !activity.length ? (
                  <div styleName="loading">Loading activity...</div>
                ) : (
                  activity.map(item => (
                    <Item key={`${item.id}_${item.timestamp}`}>
                      <Avatar color={getColor(item.user, user)} />
                      <Message>
                        <Action>{getTemplate(item, user)}</Action>
                        <Timestamp stamp={item.timestamp} />
                      </Message>
                    </Item>
                  ))
                )}
              </div>
            </Tab>
          ) : null}
        </Tabs>
      </div>
    );
  }
}

Feed.defaultProps = {
  whitelist: ["messages", "comments", "activity"]
};

const userPropShape = PropTypes.shape({
  id: PropTypes.number,
  email: PropTypes.string,
  fname: PropTypes.string,
  lname: PropTypes.string
});

Feed.propTypes = {
  activity: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      timestamp: PropTypes.string,
      user: userPropShape
    })
  ).isRequired,
  addMessage: PropTypes.func.isRequired,
  addNote: PropTypes.func.isRequired,
  className: PropTypes.string,
  eventId: PropTypes.string.isRequired,
  getActivity: PropTypes.func.isRequired,
  getMessages: PropTypes.func.isRequired,
  getNotes: PropTypes.func.isRequired,
  isFetchingActivity: PropTypes.bool.isRequired,
  isFetchingMessages: PropTypes.bool.isRequired,
  isFetchingNotes: PropTypes.bool.isRequired,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
      timestamp: PropTypes.string,
      user: userPropShape
    })
  ).isRequired,
  moduleId: PropTypes.string.isRequired,
  notes: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  recordId: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  whitelist: PropTypes.arrayOf(
    PropTypes.oneOf(["messages", "comments", "activity"])
  )
};

export default CSSModules(Feed, css);
