import React, { Component } from "react";
import * as R from "ramda";
import View from "./View";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";

const getRoutesToCheck = link => {
  if (link.links) {
    return R.reduce(
      (list, child) => {
        const toAdd = getRoutesToCheck(child);
        return [...list, ...toAdd];
      },
      link.routeName ? [link.routeName] : []
    )(link.links);
  }
  return Array.isArray(link.routeName) ? link.routeName : [link.routeName];
};

const formatLinks = (routes, links) => {
  const formatLink = link => {
    const routesToCheck = getRoutesToCheck(link);
    return {
      ...link,
      links: link.links ? R.map(formatLink)(link.links) : null,
      isActive: routes.some(r => routesToCheck.includes(r.name))
    };
  };

  return R.map(formatLink)(links);
};

class Controller extends Component {
  goTo = path => {
    this.props.router.push({
      pathname: path
    });
  };

  goToNewWindow = path => {
    window.open(path, "_blank");
  };

  goToView = view => {
    this.props.router.push({
      pathname: `/event/${this.props.eventDetails.id}/passes${view}`
    });
  };

  goToForm = formId => {
    this.props.router.push(
      `/event/${this.props.eventDetails.id}/module/${STANDARD_MODULE_IDS.orders.id}/form/${formId}`
    );
  };

  goToTypes = () => {
    this.props.router.push(
      `/event/${this.props.eventDetails.id}/settings/catalog/type/${CREDENTIAL_TYPE_ID}/items`
    );
  };

  goToApprovers = () => {
    this.props.router.push(
      `/event/${this.props.eventDetails.id}/settings/catalog/type/${CREDENTIAL_TYPE_ID}/approvers`
    );
  };

  render() {
    const { routes, children } = this.props;

    const LINKS = [
      {
        id: "overview",
        routeName: "PassesSetupOverview",
        onClick: () => this.goToView("/setup"),
        name: "Overview",
        links: null
      },
      {
        id: "types",
        routeName: "PassesSetupTypes",
        onClick: () => this.goToTypes(),
        name: "Pass Types",
        links: null
      },
      {
        id: "approvers",
        routeName: "PassesSetupApprovers",
        onClick: () => this.goToApprovers(),
        name: "Managers & Approvers",
        show: this.props.canViewPasses,
        links: [
          {
            id: "approvers-advanced",
            routeName: "PassesSetupApproversAdvanced",
            onClick: null,
            name: null,
            links: null
          }
        ]
      },
      /*
      // @NOTE: Hiding until we have it
      {
        id: "confirmations",
        routeName: "PassesSetupConfirmations",
        onClick: () => this.goToView("/setup/confirmations"),
        name: "Confirmations",
        links: null
      },
      */
      {
        id: "collect",
        routeName: "PassesSetupCollect",
        onClick: () => this.goToView("/setup/collect"),
        name: "Collect & Manage",
        links: [
          {
            id: "request-forms-setup",
            routeName: "PassesSetupCollectRequestForms",
            onClick: () => this.goToView("/setup/collect/request-forms"),
            name: "Request Forms",
            links: null,
            show: true
          },
          {
            id: "guest-lists-setup",
            routeName: "PassesSetupCollectGuestLists",
            onClick: () => this.goToView("/setup/collect/guest-lists"),
            name: "Allocations",
            links: null,
            show: true
          },
          {
            id: "applications-setup",
            routeName: "PassesSetupCollectApplications",
            onClick: () => this.goToView("/setup/collect/applications"),
            name: "Applications",
            links: null,
            show: true
          }
        ]
      },
      {
        id: "integrations",
        routeName: "PassesSetupIntegrations",
        onClick: () => this.goToView("/setup/integrations"),
        name: "Ticketing & RFID Integrations",
        links: null
      }
    ].filter(l => l.show !== false);

    const formattedLinks = formatLinks(routes, LINKS);
    return (
      <View
        {...{
          children,
          links: formattedLinks
        }}
      />
    );
  }
}

export default Controller;
