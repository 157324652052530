import * as R from "ramda";
import React from "react";

import {
  ClockIcon,
  CheckIcon,
  CloseIcon,
  SmallFilledBox
} from "components/Base";
import { getValue } from "../../utils";

import { withProps } from "utils/General";

const ApprovedIcon = withProps({ title: "Approved", color: "#2f9e44" })(
  CheckIcon
);
const PendingIcon = withProps({ title: "Pending", color: "#e67700" })(
  ClockIcon
);
const DeniedIcon = withProps({ title: "Denied", color: "#e03131" })(CloseIcon);

const getStatusProps = status =>
  R.prop(status, {
    approved: { LeftIcon: ApprovedIcon, bg: "#d3f9d8", color: "2f9e44" },
    rejected: { LeftIcon: DeniedIcon, bg: "#ffe3e3", color: "#e03131" },
    pending: { LeftIcon: PendingIcon, bg: "#fff3bf", color: "#e67700" }
  });

const OrderStatus = props => {
  const value = getValue(props);

  if (R.isNil(value) || R.isEmpty(value)) {
    return " - ";
  }

  return React.createElement(
    SmallFilledBox,
    {
      pill: true,
      isPadded: true,
      ...getStatusProps(value)
    },
    value
  );
};

export default OrderStatus;
