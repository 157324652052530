import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import { MEAL_TYPE_ID } from "utils/item-types";
import View from "./View";

const buildExistingMealState = (item, clone) => ({
  name: clone ? `Copy of ${item.name}` : item.name || "",
  description: item.description,
  color: item.background_color || ""
});

class InternalHandler extends Component {
  constructor(props) {
    super(props);
    if (props.mode === "create") {
      // state: create
      this.state = {
        activeTab: "overview",
        loading: false,
        name: "",
        description: "",
        color: ""
      };
    } else {
      // state: update or clone
      this.state = {
        activeTab: "overview",
        loading: true,
        ...buildExistingMealState(props.meal, props.clone)
      };
    }
  }

  componentDidMount() {
    const { mode } = this.props;
    if (["update", "clone"].includes(mode)) {
      this.props.getMeal().then(() => {
        this.setState({ loading: false });
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.meal, nextProps.meal)) {
      this.setState({
        ...buildExistingMealState(nextProps.meal, nextProps.clone)
      });
    }
  }

  setTab = tab => this.setState({ activeTab: tab });

  handleColorSelect = color => {
    this.setState({ color });
  };

  handleFieldChange = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  canSave = () => this.state.name.length;

  handleSave = () => {
    this.props.saveMeal({
      item: {
        id:
          this.props.meal && this.props.mode !== "clone"
            ? this.props.meal.id
            : null,
        typeId: MEAL_TYPE_ID,
        name: this.state.name,
        description: this.state.description,
        backgroundColor: this.state.color
      }
    });
  };

  render() {
    const { hideModal, mode } = this.props;
    const { activeTab, loading } = this.state;

    return (
      <View
        {...{
          hideModal,
          mode,
          activeTab,
          loading,
          meal: this.state,
          handleFieldChange: this.handleFieldChange,
          handleColorSelect: this.handleColorSelect,
          canSave: this.canSave(),
          handleSave: this.handleSave,
          tabs: [
            {
              id: "overview",
              name: "Overview",
              active: activeTab === "overview",
              onClick: () => this.setTab("overview")
            }
          ]
        }}
      />
    );
  }
}

InternalHandler.defaultProps = {
  mealId: null,
  meal: {},
  clone: false
};

InternalHandler.propTypes = {
  mode: PropTypes.string.isRequired,
  getMeal: PropTypes.func.isRequired,
  saveMeal: PropTypes.func.isRequired,
  mealId: PropTypes.string,
  hideModal: PropTypes.func.isRequired,
  clone: PropTypes.bool
};

export default InternalHandler;
