import PropTypes from "prop-types";
import React, { Component } from "react";
import Body from "../Layout/Body";
import Content from "../Layout/Content";
import { catalogItems } from "@lennd/value-types/src/constants/standard-modules";
import Table from "components/Event/InventoryV2/ModuleTable";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";

class InventoryCatalog extends Component {
  componentWillUpdate(nextProps) {
    if (this.props.params.parentRecordId !== nextProps.params.parentRecordId) {
      this.props.deselectAllRows();
    }
  }

  filterRecords = records => {
    const { parentRecordId } = this.props.params;
    if (parentRecordId) {
      return records.filter(record =>
        Object.keys(record.values).find(
          id =>
            record.values[id].type === "lookup" &&
            record.values[id].value.records.includes(parentRecordId)
        )
      );
    }
    return records;
  };

  render() {
    const { params, records, fields } = this.props;
    const readOnlyFields = resolveReadOnlyFields({ moduleId: catalogItems.id });

    return (
      <Content>
        <Body type="tall">
          <Table
            fields={fields.filter(f => !readOnlyFields.includes(f.id))}
            context={{
              ...params,
              moduleId: catalogItems.id,
              view: "list"
            }}
            records={this.filterRecords(records)}
          />
        </Body>
      </Content>
    );
  }
}

InventoryCatalog.propTypes = {
  deselectAllRows: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  getModule: PropTypes.func.isRequired,
  getRecords: PropTypes.func.isRequired,
  params: PropTypes.shape({
    parentRecordId: PropTypes.string,
    eventId: PropTypes.string.isRequired
  }).isRequired,
  records: PropTypes.arrayOf(
    PropTypes.shape({
      values: PropTypes.object
    })
  ).isRequired
};

export default InventoryCatalog;
