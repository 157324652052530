import * as R from "ramda";
import { put, select, call, take, all, fork } from "redux-saga/effects";

import { BEGIN, COMMIT, REVERT } from "redux-optimist";
import uuid from "node-uuid";

import { addValue } from "redux/modules/modules/values/actions";
import { addToGroupOrder } from "redux/modules/portal/groupOrder/actions";
import { addRecord, deleteRecord } from "redux/modules/modules/records/actions";
import { addRelationship } from "redux/modules/accounts/contactRelationship/actions";

import {
  getters as TableGetters,
  actions as TableActions
} from "ui-kit/Datagrid";
import * as TableSelectors from "ui-kit/Datagrid/selectors";

import { getters } from "Accounts/PeopleTable/model";
import {
  getNewRowsValues,
  getSelectedCellIds
} from "Accounts/PeopleTable/selectors";

import { registerError } from "redux/modules/errors/actions";

import { actions as ValidatorActions } from "ui-kit/Validator";
import * as ValidatorSelectors from "ui-kit/Validator/selectors";

import { getCellId } from "ui-kit/Datagrid/utils";

import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

const removeRow = function*({ meta: actionMeta }) {
  const tableProps = { instanceId: actionMeta.instanceId };
  const selectedRows = yield select(TableSelectors.getSelectedRows, tableProps);
  const selectedRowIds = R.map(R.prop("id"), selectedRows);

  if (R.isEmpty(selectedRowIds)) {
    yield put(TableActions.setLoading(false, { meta: actionMeta }));
  } else {
    const transactionId = yield call([uuid, uuid.v4]);
    const clearIds = yield select(getSelectedCellIds, {
      instanceId: actionMeta.instanceId
    });

    yield put(
      TableActions.removeRow(null, {
        optimist: { type: BEGIN, id: transactionId },
        meta: actionMeta
      })
    );

    try {
      // wip until sagas are implemented
      const res = yield put(
        deleteRecord({
          moduleId: STANDARD_MODULE_IDS.contacts.id,
          records: R.map(R.prop("contact_id"), selectedRows),
          bulk: true
        })
      );

      yield call(() => res);
      yield put(
        TableActions.removeRowResponse(
          null,
          {
            optimist: { type: COMMIT, id: transactionId },
            meta: actionMeta
          },
          true
        )
      );
      yield put(
        ValidatorActions.clear(clearIds, {
          meta: actionMeta
        })
      );
    } catch (error) {
      yield put(
        TableActions.removeRowResponse(
          { error: error.error || "Could not delete the rows." },
          {
            optimist: { type: REVERT, id: transactionId },
            meta: actionMeta
          },
          true
        )
      );
    }
  }
};

const watchRemove = function*() {
  for (;;) {
    const action = yield take(TableActions.removeRowRequest.type);

    const tableIds = yield select(getters.tableIds);
    if (R.contains(action.meta.instanceId, tableIds)) {
      yield call(removeRow, action);
    }
  }
};

const addRow = function*({ meta: actionMeta }) {
  const transactionId = yield call([uuid, uuid.v4]);
  const meta = yield select(TableGetters.meta, {
    instanceId: actionMeta.instanceId
  });

  yield put(
    TableActions.addRow(transactionId, {
      optimist: { type: BEGIN, id: transactionId },
      meta: actionMeta
    })
  );

  try {
    const record = yield put(
      addRecord({
        moduleId: STANDARD_MODULE_IDS.contacts.id,
        typeId: meta.contactTypeId,
        record: {},
        options: {
          eventId: meta.eventDetails.id
        }
      })
    );

    const createdRecord = yield call(() => record);

    const relationship = yield put(
      addRelationship({
        eventId: meta.eventDetails.id,
        isPrimary: false,
        role: null,
        accountId: meta.accountId,
        contactId: createdRecord.id
      })
    );

    const { relationship: createdRelationship } = yield call(
      () => relationship
    );

    // @TODO: Gonzalo - Allow values to be added to new rows
    yield put(
      TableActions.addRowResponse(
        { submission: createdRelationship, tId: transactionId },
        {
          optimist: { type: COMMIT, id: transactionId },
          meta: actionMeta
        }
      )
    );
  } catch (error) {
    yield all([
      put(
        TableActions.addRowResponse(
          { error: error.error || "Could not add person." },
          {
            optimist: { type: REVERT, id: transactionId },
            meta: actionMeta
          },
          true
        )
      ),
      put(
        registerError([
          {
            system: error,
            user: "An error occurred adding person"
          }
        ])
      )
    ]);
  }
};

const watchAdd = function*() {
  for (;;) {
    const action = yield take(TableActions.addRowRequest.type);

    const tableIds = yield select(getters.tableIds);
    if (R.contains(action.meta.instanceId, tableIds)) {
      yield call(addRow, action);
    }
  }
};

const updateCell = function*({ payload, meta: actionMeta }) {
  const { action, fromRowData: row, cellKey, updated } = payload;

  // prevent updating a temporary row

  const transactionId = yield call([uuid, uuid.v4]);

  // currently bailing if trying to drag or copy + paste values
  if (!["CELL_DRAG", "COPY_PASTE"].includes(action)) {
    yield put(
      TableActions.updateCell(payload, {
        optimist: { type: BEGIN, id: transactionId },
        meta: actionMeta
      })
    );

    const meta = yield select(TableGetters.meta, {
      instanceId: actionMeta.instanceId
    });

    const data = {
      eventId: meta.eventDetails.id,
      value: R.pick(["type", "value"], R.propOr({}, cellKey, updated)),
      fieldId: cellKey,
      recordId: row.contact_id,
      moduleId: STANDARD_MODULE_IDS.contacts.id
    };

    try {
      const res = yield put(addValue(data));
      yield call(R.always(res));
      yield put(
        TableActions.updateCellResponse(
          null,
          {
            optimist: { type: COMMIT, id: transactionId },
            meta: actionMeta
          },
          true
        )
      );
    } catch (error) {
      yield put(
        TableActions.updateCellResponse(
          { error: error.error || "Could not update the cell." },
          {
            optimist: { type: REVERT, id: transactionId },
            meta: actionMeta
          },
          true
        )
      );
    }
  }
};

const updateItemBlock = function*({ payload, meta: actionMeta }) {
  const mode = yield select(TableGetters.mode, {
    instanceId: actionMeta.instanceId
  });

  const tableProps = { instanceId: actionMeta.instanceId };
  const { fromRowData: row, cellKey, updated, action } = payload;

  // prevent updating a temporary row
  const meta = yield select(TableGetters.meta, tableProps);

  const transactionId = yield call([uuid, uuid.v4]);

  // currently bailing if trying to drag or copy + paste values
  if (!["CELL_DRAG", "COPY_PASTE"].includes(action)) {
    yield put(
      TableActions.updateCell(payload, {
        optimist: { type: BEGIN, id: transactionId },
        meta: actionMeta
      })
    );

    const items = R.path([cellKey, "value", "value"], updated);
    const removedItems = R.map(
      id => ({ id, quantity: 0 }),
      R.path([cellKey, "removedItems"], updated)
    );

    const contactId = R.prop("contact_id", row) || R.propOr("", "id", row);
    const data = {
      eventId: meta.eventDetails.id,
      accountId: meta.accountId,
      contactId,
      blockId: cellKey,
      variants: R.map(
        ({ id, quantity }) => ({
          id,
          quantity
        }),
        R.concat(items, removedItems)
      )
    };

    try {
      yield put(addToGroupOrder(data));
      yield put(
        TableActions.updateCellResponse(null, {
          optimist: { type: COMMIT, id: transactionId },
          payload: {
            submissionId: row.id,
            fieldId: cellKey,
            value: {
              ...R.propOr({}, cellKey, updated),
              value: { type: "item-block", value: items }
            },
            mode
          },
          meta: actionMeta
        })
      );
    } catch (error) {
      yield put(
        TableActions.updateCellResponse(
          { error: error.error || "Could not update the cell." },
          {
            optimist: { type: REVERT, id: transactionId },
            meta: actionMeta
          },
          true
        )
      );
    }
  }
};

const filterValidatorResponse = ({ instanceId, cellId }) => vAction =>
  vAction.type === ValidatorActions.validationResponse.type &&
  R.path(["meta", "instanceId"], vAction) === instanceId &&
  R.path(["payload", "id"], vAction) === cellId;

const watchUpdateCell = function*() {
  for (;;) {
    const action = yield take(TableActions.updateCellRequest.type);
    const instanceId = action.meta.instanceId;

    const tableIds = yield select(getters.tableIds);
    if (R.contains(instanceId, tableIds)) {
      const {
        cellKey,
        updated,
        fromRowId: rowId,
        fromRowData: row
      } = action.payload;

      const isTemporary = yield select(TableSelectors.isTemporaryRow, {
        instanceId,
        rowId
      });

      if (!isTemporary) {
        const cellId = getCellId({ columnId: cellKey, rowId });
        const column = R.path(["meta", "columns", cellKey], row);
        // eslint-disable-next-line no-unused-vars
        const { error, ...cell } = R.prop(cellKey, updated);

        yield put(
          ValidatorActions.validationRequest(
            {
              ...cell,
              id: cellId,
              type: R.prop("type", column),
              key: R.prop("name", column),
              required: R.prop("required", column)
            },
            { meta: action.meta }
          )
        );
        yield take(filterValidatorResponse({ instanceId, cellId }));

        const { valid } = yield select(ValidatorSelectors.getError, {
          id: cellId,
          instanceId
        });

        if (valid) {
          yield put(ValidatorActions.clear([rowId], { meta: action.meta }));
          if (R.path([cellKey, "type"], updated) === "item-block") {
            yield call(updateItemBlock, action);
          } else {
            yield call(updateCell, action);
          }
        } else {
          yield put(
            TableActions.updateCell(action.payload, {
              meta: action.meta
            })
          );
        }
      }
    }
  }
};

const watchResize = function*() {
  for (;;) {
    const action = yield take(TableActions.columnResizeRequest.type);

    const tableIds = yield select(getters.tableIds);
    if (R.contains(action.meta.instanceId, tableIds)) {
      yield put(
        TableActions.columnResize(action.payload, { meta: action.meta })
      );
    }
  }
};

const filterBulkValidatorResponse = ({ instanceId, id }) => vAction =>
  vAction.type === ValidatorActions.bulkValidationResponse.type &&
  R.path(["meta", "instanceId"], vAction) === instanceId &&
  R.path(["payload", "id"], vAction) === id;

const watchSaveRequest = function*() {
  for (;;) {
    const action = yield take(TableActions.saveRequest.type);
    const instanceId = action.meta.instanceId;

    const tableIds = yield select(getters.tableIds);
    if (R.contains(instanceId, tableIds)) {
      const transactionId = yield call([uuid, uuid.v4]);
      const values = yield select(getNewRowsValues, {
        instanceId: instanceId
      });
      yield put(
        ValidatorActions.bulkValidationRequest(
          { id: transactionId, values },
          {
            meta: action.meta
          }
        )
      );
      yield take(
        filterBulkValidatorResponse({ instanceId, id: transactionId })
      );
      const isValid = yield select(TableSelectors.getIsValid, { instanceId });
      if (isValid) {
        yield put(TableActions.save());
      }
    }
  }
};

const rootSaga = function*() {
  yield all([
    fork(watchUpdateCell),
    fork(watchAdd),
    fork(watchRemove),
    fork(watchResize),
    fork(watchSaveRequest)
  ]);
};

export default rootSaga;
