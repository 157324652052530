import React, { Fragment, Component } from "react";
import * as R from "ramda";
import MediaQuery from "react-responsive";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { addS, noop } from "utils/General";

import {
  Div,
  MoneyIcon,
  MediumFilledButton,
  BigFilledButton,
  LeftIcon,
  Span
} from "components/Base";

import { StripeProvider, Elements } from "react-stripe-elements";
import StripeScriptLoader from "Orders/PaymentModal/StripeScriptLoader";

import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";
import resolveEditor from "components/Global/StandAloneEditors/utils/resolveEditor";

import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement
} from "react-stripe-elements";

import FormElements from "components/Global/Modal/Layout/FormElements";
const { Input } = FormElements;

const DEFAULT_STRIPE_MESSAGE =
  "There was an issue with the payment information you provided. Please try again.";

const Small = props => <MediaQuery {...props} maxWidth={1023} />;
const Big = props => <MediaQuery {...props} minWidth={1024} />;

const Header = ({
  total,
  navigate,
  backLink,
  mobile = false,
  redirectLinkLabel = "Edit Cart"
}) => (
  <Div
    height={75}
    display="row.center.center"
    width={1}
    bb={2}
    bc="neutral1"
    style={{ position: "relative" }}
  >
    <Div
      style={{ position: "absolute", left: 0 }}
      onClick={() => navigate(backLink)}
      p={mobile ? 2 : 3}
      display={"row.flex-start.center"}
    >
      <LeftIcon size={mobile ? 20 : 24} />
      <Div fw={3} fs={mobile ? 1 : 3}>
        {redirectLinkLabel}
      </Div>
    </Div>
    <Div display="row.flex-start.center">
      <MoneyIcon size={mobile ? 14 : 24} color="neutral4" />
      <Div fs={[2, 4, 6]} bold ml={mobile ? 0 : 2} color="black">
        Checkout
      </Div>
      <Div
        bra={1}
        width={89}
        height={29}
        display="row.center.center"
        bg="altB0"
        color="altB4"
        ml={2}
        fw={4}
        fs={2}
      >
        {`${total} item${addS(total)}`}
      </Div>
    </Div>
  </Div>
);

const Footer = ({
  showTermsModal,
  isPaymentEnabled,
  error,
  width = 1,
  mobile = false,
  isValid,
  saving,
  onSubmit
}) => (
  <Div height={82} width={1} display="row" bg="white">
    <Div
      display={
        mobile ? "column.space-between.center" : "row.space-between.center"
      }
      width={width}
      px={mobile ? 4 : 6}
      py={mobile ? 2 : 6}
    >
      <Div display={mobile ? "column.flex-start.center" : "column"}>
        {isPaymentEnabled ? (
          <Div color="black" fs={mobile ? 1 : 3} fw={2}>
            Payment will be processed immediately.
          </Div>
        ) : null}
        <Div fs={0}>
          By submitting this request, you agree to the{" "}
          <Span underline color="primary8" onClick={showTermsModal}>
            terms & conditions
          </Span>
          .
        </Div>
      </Div>
      {error && error.length ? (
        <Div bg="danger3" color="gray7" p={3} bra={1} mt={2} mx={5}>
          {error}
        </Div>
      ) : null}
      {mobile ? (
        <MediumFilledButton
          bg="warning5"
          width={238}
          disabled={saving || !isValid}
          onClick={saving || !isValid ? noop : onSubmit}
        >
          {saving ? "Submitting..." : "Submit"}
        </MediumFilledButton>
      ) : (
        <BigFilledButton
          bg="warning5"
          width={238}
          disabled={saving || !isValid}
          onClick={saving || !isValid ? noop : onSubmit}
        >
          {saving ? "Submitting..." : "Submit"}
        </BigFilledButton>
      )}
    </Div>
  </Div>
);

const Label = ({ children, valid, required }) => (
  <Div
    color={valid ? "neutral7" : "red7"}
    display="row.flex-start.center"
    fs={2}
    mt={3}
    mb={1}
  >
    {children}
    {required ? <Div color="red7">*</Div> : null}
  </Div>
);

const Field = ({ field }) => {
  const Editor = resolveEditor(field);
  const maxLength = R.pathOr("", ["settings", "maxLength"], field);
  return (
    <Div className={field.valid ? "lennd-valid" : "lennd-invalid"}>
      <Label valid={field.valid} required={field.is_required}>
        {field.name}
      </Label>
      {field.settings &&
      field.settings.description &&
      field.settings.description.length ? (
        <Div fs={2} color="gray6" mb={1}>
          {field.settings.description}
        </Div>
      ) : null}
      <Editor
        onChange={field.onChange}
        value={field.value}
        autocomplete={`autocomplete_${field.id}`}
        maxLength={maxLength}
        {...resolveEditorProps(field, {})}
      />
      {!R.isEmpty(maxLength) ? (
        <div
          style={{
            fontSize: 12,
            padding: "4px 0 0 4px",
            color: "#aaa",
            fontWeight: 600
          }}
        >
          {R.length(R.propOr("", "value", field.value))}/{maxLength} Character
          {R.length(R.propOr("", "value", field.value)) !== 1 ? "s" : ""}
        </div>
      ) : null}
    </Div>
  );
};

const Payment = CSSModules(
  ({ fields }) => (
    <Div styleName="container">
      <Div>
        <Label valid={fields.payment.cardNumber.value}>Card Number</Label>
        <CardNumberElement onChange={fields.payment.cardNumber.onChange} />
      </Div>
      <Div>
        <Label valid={fields.payment.name.valid}>Name on card</Label>
        <Input
          onChange={e => fields.payment.name.onChange(e.target.value)}
          type="text"
          value={fields.payment.name.value}
        />
      </Div>
      <Div>
        <Label valid={fields.payment.expires.value}>Expires</Label>
        <CardExpiryElement onChange={fields.payment.expires.onChange} />
      </Div>
      <Div>
        <Label valid={fields.payment.cvc.value}>CVC</Label>
        <CardCVCElement onChange={fields.payment.cvc.onChange} />
      </Div>
      <Div>
        <Label valid={fields.payment.zip.value}>Zip Code</Label>
        <PostalCodeElement onChange={fields.payment.zip.onChange} />
      </Div>
    </Div>
  ),
  css
);

const Info = ({
  fields,
  orderedItems,
  totalAmount,
  mobile = false,
  isPaymentEnabled
}) => {
  return (
    <Div
      display={mobile ? "column" : "row"}
      style={{
        overflowY: "auto",
        overflowX: "hidden",
        height: mobile ? "calc(100% - 157px)" : "auto"
      }}
    >
      <Div
        width={mobile ? 1 : 704}
        bg="white"
        bc="neutral1"
        br={1}
        bb={1}
        p={6}
      >
        <Div width={mobile ? 1 : 380}>
          <Div color="black" fw={3} mb={1}>
            Contact Information
          </Div>
          {fields.contact.map(field => {
            return <Field key={field.id} field={field} />;
          })}

          <Div color="black" fw={3} mt={6} mb={1}>
            Company / Group Information
          </Div>
          {fields.account.map(field => {
            return <Field key={field.id} field={field} />;
          })}

          {fields.order.length ? (
            <>
              <Div color="black" fw={3} mt={6} mb={1}>
                Other / Group Information
              </Div>
              {fields.order.map(field => {
                return <Field key={field.id} field={field} />;
              })}
            </>
          ) : null}

          {isPaymentEnabled ? (
            <>
              <Div color="black" fw={3} mt={6} mb={1}>
                Payment Information
              </Div>
              <Payment fields={fields} />
            </>
          ) : null}
        </Div>

        {/*
      <Div width={mobile ? 1 : 490} mt={6}>
        <Div display="row.space-between.center" mb={2}>
          <Div color="black" fw={3}>
            Payment Information
          </Div>
          <Div display="row.flex-start.center">
            <LockIcon size={10} color="black" />
            <Div ml={1} fs={0}>
              Secure payment
            </Div>
          </Div>
        </Div>
        <Div
          width={1}
          style={{ backgroundColor: "rgba(249, 247, 250, 0.45)" }}
          p={2}
        >
          <Div fs={2} color="neutral7" mt={3} mb={1}>
            Credit Card Number *
          </Div>
          <BigOutlineInput
            // RightIcon={SearchIconGrey}
            width={mobile ? 1 : 418}
            bg="white"
            continous
            value={payment.cardNumber}
            onChange={value => {
              updatePayment({ value, name: "cardNumber" });
            }}
          />

          <Div
            width={mobile ? 1 : 418}
            display={mobile ? "row" : "row.space-between"}
          >
            <Div width={108}>
              <Div fs={2} color="neutral7" mt={3} mb={1}>
                CVV Code*
              </Div>
              <BigOutlineInput
                RightIcon={CreditCardIconGrey}
                width={108}
                bg="white"
                continous
                value={payment.cvv}
                onChange={value => {
                  updatePayment({ value, name: "cvv" });
                }}
              />
            </Div>
            <Div width={108} ml={mobile ? 3 : 0}>
              <Div fs={2} color="neutral7" mt={3} mb={1}>
                Expiration Date
              </Div>
              <BigOutlineInput
                width={108}
                bg="white"
                continous
                value={payment.expirationDate}
                onChange={value => {
                  updatePayment({ value, name: "expirationDate" });
                }}
              />
            </Div>
            {mobile === false && (
              <Div width={169}>
                <Div fs={2} color="neutral7" mt={3} mb={1}>
                  Zip Code
                </Div>
                <BigOutlineInput
                  width={1}
                  bg="white"
                  continous
                  value={payment.zipCode}
                  onChange={value => {
                    updatePayment({ value, name: "zipCode" });
                  }}
                />
              </Div>
            )}
          </Div>
          {mobile === true && (
            <Div width={169}>
              <Div fs={2} color="neutral7" mt={3} mb={1}>
                Zip Code
              </Div>
              <BigOutlineInput width={169} bg="white" />
            </Div>
          )}

        </Div>


        <Div color="black" fw={3} mt={3} mb={2}>
          Billing Information
        </Div>
        <RadioButton
          checked={shipping === SHIPPING.DIFFERENT}
          value={SHIPPING.DIFFERENT}
          onClick={() => {
            setShipping(SHIPPING.DIFFERENT);
          }}
          label={<Div color="black">Enter a different shipping address</Div>}
        />
        <RadioButton
          checked={shipping === SHIPPING.SAME}
          value={SHIPPING.SAME}
          onClick={() => {
            setShipping(SHIPPING.SAME);
          }}
          label={<Div color="black">Same as shipping</Div>}
        />

      </Div>
       */}
      </Div>
      <Div width={mobile ? 1 : 320} bg="neutral0" py={4} px={6}>
        <Div fw={3} fs={2}>
          Order Summary
        </Div>
        <Div bb={1} bc="neutral4" py={3}>
          {R.map(
            item => (
              <Div key={item.id} mt={2} display="row.space-between">
                <Div fs={1}>{`${item.quantity} x ${item.name}`}</Div>
                <Div fs={1} color="black">{`$${item.price}`}</Div>
              </Div>
            ),
            orderedItems
          )}
        </Div>
        <Div bb={1} bc="neutral4" py={3}>
          <Div display="row.space-between">
            <Div fs={1}>Subtotal</Div>
            <Div fs={1} color="black">{`$${totalAmount}`}</Div>
          </Div>
          {/*
        <Div display="row.space-between" mt={2}>
          <Div fs={1}>Fees</Div>
          <Div fs={1} color="black">
            $0
          </Div>
        </Div>
        */}
        </Div>
        <Div display="row.space-between" mt={4}>
          <Div fs={3} fw={3} color="black">
            Total
          </Div>
          <Div fs={4} fw={3} color="altB9">{`$${totalAmount}`}</Div>
        </Div>
      </Div>
    </Div>
  );
};

const Content = ({
  redirectLinkLabel,
  showTermsModal,
  isPaymentEnabled,
  fields,
  orderedItems,
  total,
  totalAmount,
  isValid,
  router,
  backLink,
  shipping,
  setShipping,
  contact,
  payment,
  updateContact,
  updatePayment,
  redirectLink,
  onSubmit,
  error,
  saving
}) => {
  const listInfoProps = {
    isPaymentEnabled,
    fields,
    shipping,
    orderedItems,
    totalAmount,
    setShipping,
    contact,
    payment,
    updateContact,
    updatePayment,
    navigate: router.push
  };
  const headerProps = {
    redirectLinkLabel,
    total,
    navigate: router.push,
    backLink
  };
  const footerProps = {
    showTermsModal,
    isPaymentEnabled,
    error,
    onSubmit,
    saving,
    navigate: router.push,
    redirectLink,
    isValid
  };
  return (
    <Fragment>
      <Small>
        <Div
          width={1}
          display="column"
          style={{
            overflow: "auto",
            position: "absolute",
            left: 0,
            top: 54,
            height: "calc(100% - 54px)"
          }}
        >
          <Header {...headerProps} redirectLinkLabel="Back" mobile />
          <Info {...listInfoProps} mobile />
          <Footer mobile {...footerProps} />
        </Div>
      </Small>
      <Big>
        <Div
          width={1}
          height={1}
          display="column"
          style={{ overflow: "hidden" }}
        >
          <Header {...headerProps} />
          <Info {...listInfoProps} />
          <Footer width={704} {...footerProps} />
        </Div>
      </Big>
    </Fragment>
  );
};

class CheckoutBody extends Component {
  state = { error: null };

  // eslint-disable-next-line consistent-return
  onSubmit = async () => {
    if (this.props.isPaymentEnabled) {
      // Within the context of `Elements`, this call to createToken knows which Element to
      // tokenize, since there's only one in this group.
      const { token, error } = await this.props.stripe.createToken({
        name: this.props.fields.payment.name.value
      });

      if (error) {
        // eslint-disable-next-line no-console
        console.error("Caught: Stripe Payment Modal Error", error);
        return this.setState({
          error: error.message || DEFAULT_STRIPE_MESSAGE
        });
      } else if (!token || !token.id) {
        // eslint-disable-next-line no-console
        console.error(
          "Caught: Stripe Payment Modal - No error or token returned"
        );
        return this.setState({
          error: DEFAULT_STRIPE_MESSAGE
        });
      }
      this.props.onSubmit({
        redirectLink: this.props.redirectLink,
        stripeTokenId: token.id
      });
    } else {
      this.props.onSubmit({
        redirectLink: this.props.redirectLink,
        stripeTokenId: null
      });
    }
  };

  render() {
    const props = {
      ...this.props,
      error: this.state.error,
      onSubmit: this.onSubmit
    };
    return (
      <Fragment>
        <MediaQuery minWidth={1025} maxWidth={1039}>
          <Div
            bg="white"
            display="column"
            bra={1}
            mb={3}
            style={{ flexShrink: 0 }}
          >
            <Content {...props} />
          </Div>
        </MediaQuery>
        <MediaQuery minWidth={1040}>
          <Div
            bg="white"
            display="column"
            bra={1}
            mb={3}
            style={{
              flexShrink: 0,
              marginTop: "125px",
              boxShadow:
                "0px 7.43943px 17.3587px rgba(0, 0, 0, 0.0423), 0px 32.2375px 49.5962px rgba(0, 0, 0, 0.01)"
            }}
          >
            <Content {...props} />
          </Div>
        </MediaQuery>
        <MediaQuery maxWidth={1024}>
          <Div
            width={1}
            height={1}
            bg="white"
            display="column"
            bra={0}
            mb={3}
            style={{ flexShrink: 0 }}
          >
            <Content {...props} />
          </Div>
        </MediaQuery>
      </Fragment>
    );
  }
}

const CheckoutDecoratedWithPayment = injectStripe(CheckoutBody);

// eslint-disable-next-line no-underscore-dangle
const stripeApiKey = window.__STRIPE_API_KEY__;
const Checkout = props => (
  <StripeScriptLoader>
    <StripeProvider apiKey={stripeApiKey}>
      <Elements>
        <CheckoutDecoratedWithPayment {...props} />
      </Elements>
    </StripeProvider>
  </StripeScriptLoader>
);

export default Checkout;
