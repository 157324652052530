import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as R from "ramda";

import { actions } from "Schedules/ScheduleViewPicker";

import { withProps } from "utils/General";

import { Div, Text3, MenuItem, Popover } from "components/Base";
import DeleteConfirmation from "components/Global/CRM/Popovers/DeleteConfirmation";

import { BUTTONS_TYPE } from "Schedules/ScheduleViewPicker/constants";
import { VIEWPICKER_INSTANCE_ID } from "Schedules/Schedule/constants";

const DeleteTextRed = withProps({ color: "danger8", fw: 3, fs: 2 })(Div);
const TitleText = withProps({
  color: "neutral3",
  bold: true,
  uppercase: true,
  m: 2
})(Text3);
const PopMenuItem = withProps({
  py: 1,
  bb: 1,
  bc: "neutral3",
  width: 1
})(MenuItem);
const PopMenuContainer = withProps({
  style: {
    padding: 0
  },
  bra: 1,
  p: 1,
  bg: "white",
  shadow: 2,
  width: 170,
  px: 2
})(Div);

const buttons = {
  editSettings: ({ handlers, meta }) => (
    <PopMenuItem
      onClick={e => {
        e.stopPropagation();
        handlers.editSettings(meta.id);
        handlers.closeMenu();
        handlers.closeSidebar();
      }}
    >
      Edit Settings
    </PopMenuItem>
  ),
  share: ({ handlers, meta }) => (
    <PopMenuItem
      onClick={e => {
        e.stopPropagation();
        handlers.shareSchedule(meta.id);
        handlers.closeMenu();
      }}
    >
      Share
    </PopMenuItem>
  ),
  clone: ({ handlers, meta }) => (
    <PopMenuItem
      onClick={e => {
        e.stopPropagation();
        handlers.cloneSchedule(meta.id);
        handlers.closeMenu();
        handlers.closeSidebar();
      }}
    >
      Clone
    </PopMenuItem>
  ),
  users: ({ handlers, meta }) => (
    <PopMenuItem
      onClick={e => {
        e.stopPropagation();
        handlers.editUsers(meta.id);
        handlers.closeMenu();
      }}
    >
      Users ({meta.scheduleUsersCount || 0})
    </PopMenuItem>
  ),
  delete: ({ handlers, meta }) => (
    <DeleteConfirmation
      onConfirm={() => {
        handlers.deleteSchedule(meta.id);
        handlers.closeMenu();
      }}
      text="Are you sure you want to delete this schedule?"
    >
      <PopMenuItem>
        <DeleteTextRed>Delete</DeleteTextRed>
      </PopMenuItem>
    </DeleteConfirmation>
  )
};

const buttonsByType = {
  scheduleActions: [
    "editSettings",
    // "users", "share", "clone",
    "delete"
  ]
};

const getMenuItemByType = ({ buttonType }) =>
  R.propOr(BUTTONS_TYPE.SCHEDULES, buttonType)(buttonsByType);

const buttonsContainer = {
  scheduleActions: ({ buttonType, handlers, meta }) => (
    <PopMenuContainer>
      {getMenuItemByType({ buttonType }).map(buttonName => {
        const Elm = buttons[buttonName];
        return <Elm key={buttonName} handlers={handlers} meta={meta} />;
      })}
    </PopMenuContainer>
  ),
  viewOfSchedules: ({ handlers, meta }) => (
    <PopMenuContainer>
      <TitleText>views</TitleText>
      {R.map(
        ({ id, name }) => (
          <PopMenuItem
            onClick={e => {
              e.stopPropagation();
              handlers.selectViewOfSchedule({
                moduleId: meta.scheduleId,
                viewId: id
              });
              handlers.closeMenu();
              handlers.closeSidebar();
            }}
          >
            {name}
          </PopMenuItem>
        ),
        R.propOr([], "views", meta)
      )}
    </PopMenuContainer>
  )
};

const decorate = connect(
  null,
  {
    selectViewOfSchedule: actions.selectViewOfSchedule,
    editSettings: actions.editSettings,
    shareSchedule: actions.shareSchedule,
    cloneSchedule: actions.cloneSchedule,
    editUsers: actions.editUsers,
    deleteSchedule: actions.deleteSchedule,
    closeSidebar: () =>
      actions.setIsDrawerOpen(false, {
        meta: { instanceId: VIEWPICKER_INSTANCE_ID }
      })
  }
);

const ActionsButton = ({
  label: Label,
  buttonType = BUTTONS_TYPE.SCHEDULES,
  meta = {},
  selectViewOfSchedule,
  editSettings,
  shareSchedule,
  cloneSchedule,
  editUsers,
  deleteSchedule,
  closeSidebar
}) => (
  <Popover
    innerSpanStyle={{
      width: "100%"
    }}
    Label={Label}
  >
    {({ closePopover }) => {
      const ContainerWithButtons = buttonsContainer[buttonType];
      return (
        <ContainerWithButtons
          buttonType={buttonType}
          handlers={{
            selectViewOfSchedule,
            editSettings,
            shareSchedule,
            cloneSchedule,
            editUsers,
            deleteSchedule,
            closeMenu: closePopover,
            closeSidebar
          }}
          meta={meta}
        />
      );
    }}
  </Popover>
);

ActionsButton.propTypes = {
  label: PropTypes.func.isRequired,
  buttonType: PropTypes.string,
  meta: PropTypes.shape(),
  selectViewOfSchedule: PropTypes.func,
  editSettings: PropTypes.func,
  shareSchedule: PropTypes.func,
  cloneSchedule: PropTypes.func,
  editUser: PropTypes.funcs,
  deleteSchedule: PropTypes.func
};

export default decorate(ActionsButton);
