import PropTypes from "prop-types";
import React, { Component } from "react";
import { get } from "lodash";
import { Dropdown } from "components/Base";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { ArrowRenderer } from "components/Global/Editors/utils/reactSelect";

@CSSModules(css)
class QuantityDropdown extends Component {
  onChange = data => {
    const { updateQuantity, id } = this.props;
    updateQuantity({ id }, data.value || 0);
  };

  renderOption = option => <span>{option.label}</span>;

  render() {
    const {
      autofocus,
      disabled,
      quantityOptions,
      selectedOptions
    } = this.props;
    const quantity = get(selectedOptions, ["0", "quantity"]);

    return (
      <Dropdown
        arrowRenderer={ArrowRenderer}
        autofocus={autofocus}
        clearable={false}
        disabled={disabled}
        onChange={this.onChange}
        openAfterFocus={autofocus}
        optionRenderer={this.renderOption}
        options={quantityOptions.map(({ value }) => ({
          label: value,
          value
        }))}
        placeholder="Select..."
        searchable
        usePortal={this.props.usePortal}
        value={quantity}
      />
    );
  }
}

QuantityDropdown.propTypes = {
  updateQuantity: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  selectedOptions: PropTypes.object.isRequired,
  quantityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number
    })
  ).isRequired,
  id: PropTypes.string.isRequired,
  autofocus: PropTypes.bool
};

export default QuantityDropdown;
