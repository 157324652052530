import React from "react";
import * as R from "ramda";
import { Link } from "react-router";

import {
  Popover,
  DownFilledIcon,
  Div,
  Text3,
  FontIcon,
  Text4
} from "components/Base";
import { noop } from "utils/General";

const SelectView = ({
  views = [],
  selectedView = {
    name: "Review",
    id: "allRequests",
    icon: "check_circle_outline"
  },
  selectItem = noop,
  ...styleProps
}) => {
  return (
    <Popover
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      targetOrigin={{ horizontal: "right", vertical: "top" }}
      wrapperProps={{
        width: 1,
        display: "row",
        bg: "neutral1",
        bra: 1,
        ...styleProps
      }}
      innerSpanStyle={{ flex: 1 }}
      paperProps={{
        style: {
          borderRadius: 5
        }
      }}
      Label={({ onClick }) => (
        <Div
          height={45}
          bg="white"
          bra={1}
          shadow={{ default: "1", hover: "4" }}
          onClick={onClick}
          display="row.flex-start.center"
          transition="fast"
          px={2}
          m={1}
        >
          <FontIcon
            icon={R.prop("icon", selectedView)}
            color="primary7"
            mr={2}
          />
          <Div display="row.space-between.center" flex={1}>
            <Text4 fw={3} color="primary7">
              {R.prop("name", selectedView)}
            </Text4>
            <DownFilledIcon size={24} color="primary7" />
          </Div>
        </Div>
      )}
    >
      {({ closePopover }) => (
        <Div width={208}>
          {R.map(mode => {
            const content = (
              <Div
                key={mode.id}
                width={1}
                py={3}
                display="row.flex-start.center"
                px={4}
                bg={{ default: "white", hover: "neutral0" }}
                onClick={() => {
                  selectItem(mode.id);
                  closePopover();
                }}
              >
                <FontIcon icon={mode.icon} color="black" />
                <Text3 bold color="black" ml={2}>
                  {mode.name}
                </Text3>
              </Div>
            );
            return mode.to ? <Link to={mode.to}>{content}</Link> : content;
          }, views)}
        </Div>
      )}
    </Popover>
  );
};

export default SelectView;
