import React from "react";
import EditorBase from "./TableConnector";
import Editor from "components/Global/Editors/MultipleSelectEditor";
import BeforeClose from "./BeforeClose";

export default class MultipleSelectEditor extends EditorBase {
  handleClose = () => this.props.onCommit();

  render() {
    return (
      <div style={{ width: this.props.column.width - 2 }}>
        <BeforeClose do={this.handleClose}>
          <Editor
            {...this.props}
            noTether
            value={this.state}
            onChange={this.handleChange}
            autofocus
          />
        </BeforeClose>
      </div>
    );
  }
}
