import React from "react";
import { connect } from "react-redux";
import { Div, LeftIcon, RightIcon, MediumTextButton } from "components/Base";
import { getters, actions } from "../model";
import { isPrevYearButtonEnabled } from "../selectors";

const decorator = connect(
  (state, props) => ({
    currentYear: getters.currentYear(state, props),
    isPrevYearButtonEnabled: isPrevYearButtonEnabled(state, props)
  }),
  {
    decrementYear: () => actions.changeYear(-1),
    incrementYear: () => actions.changeYear(1)
  }
);

const YearSelector = ({
  currentYear,
  decrementYear,
  incrementYear,
  isPrevYearButtonEnabled
}) => (
  <Div pill bg="neutral0" p={1} display="row.space-between.center">
    <MediumTextButton
      color="neutral7"
      OnlyIcon={LeftIcon}
      onClick={decrementYear}
      disabled={isPrevYearButtonEnabled}
    />

    <Div fs={2} fw={3} color="neutral7">
      {currentYear}
    </Div>

    <MediumTextButton
      color="neutral7"
      OnlyIcon={RightIcon}
      onClick={incrementYear}
    />
  </Div>
);

export default decorator(YearSelector);
