import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const IconBadge = CSSModules(
  ({ icon, color, children }) => (
    <div styleName="status" style={{ color }}>
      <div styleName="iconWrapper" style={{ backgroundColor: color }}>
        <i className="material-icons">{icon}</i>
      </div>
      {children}
    </div>
  ),
  css
);

export const Incomplete = CSSModules(
  () => <div styleName="incompleteStatus">incomplete/not submitted</div>,
  css
);
export const Sent = CSSModules(() => <div styleName="sent">Sent</div>, css);
export const NeedsReview = CSSModules(
  () => <div styleName="needsReview">Needs review</div>,
  css
);
export const NotSubmitted = CSSModules(
  () => <div styleName="notSubmitted">not submitted</div>,
  css
);

export const Submitted = () => (
  <IconBadge color="rgba(233, 130, 46, 0.82)" icon="access_time">
    Submitted
  </IconBadge>
);
export const InReview = () => (
  <IconBadge color="rgba(233, 130, 46, 0.82)" icon="access_time">
    In Review
  </IconBadge>
);
export const Rejected = () => (
  <IconBadge color="#EC4A5D" icon="close">
    Denied
  </IconBadge>
);
export const Reviewed = () => (
  <IconBadge color="#813CD2" icon="edit">
    Reviewed
  </IconBadge>
);
export const Approved = () => (
  <IconBadge color="#11CC7A" icon="check">
    Approved
  </IconBadge>
);

export const BadgeGroup = CSSModules(
  ({ children }) => <div styleName="badgeGroup">{children}</div>,
  css
);
