import PropTypes from "prop-types";
import React from "react";
import { get } from "lodash";
import SelectBase from "../SelectBase";
import Formatter from "../../Table3/CellFormatters/Credential";
import Editor from "../../Editors/CredentialEditor";

const CredentialSelect = ({
  onChange,
  options,
  value,
  rowMetaData,
  column,
  disabled
}) => {
  const type = get(column, "settings.selectType");
  const editor = (
    <Editor
      column={column}
      onChange={onChange}
      options={options}
      value={value}
      disabled={disabled}
      className="styled"
    />
  );

  if (type === "quantity-only-input") {
    return editor;
  }

  // Add dropdown wrapper
  return (
    <SelectBase
      formatter={
        get(value, "value.records.length") ? (
          <Formatter dependentValues={rowMetaData} value={value} />
        ) : null
      }
      editor={editor}
    />
  );
};
CredentialSelect.propTypes = {
  value: PropTypes.object,
  rowMetaData: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default CredentialSelect;
