import * as R from "ramda";

export const iniState = {
  importConfirmationCounters: {}
};

export const reducers = {
  downloadSkippedReport: R.identity,
  downloadErrorsReport: R.identity
};
