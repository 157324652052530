import React, { Component } from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import View from "./View";
import formatVariantTitle from "components/Event/Credentials/Modals/ViewOrder/utils/format-variant-title";

const buildState = itemBlock => ({
  name: itemBlock.name || "",
  description: itemBlock.description || "",
  displayType: itemBlock.display_type,
  selectionType: itemBlock.selection_type,
  enableQuantityInput: itemBlock.enable_quantity_input,
  enableItemLimit: itemBlock.enable_item_limit,
  limit: itemBlock.limit
});

class InternalHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      loading: true,
      activeTab: "settings",
      ...buildState(props.itemBlock)
    };
  }

  componentDidMount() {
    this.props.getBlock().then(() => {
      this.setState({ loading: false });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.itemBlock, nextProps.itemBlock)) {
      this.setState({
        ...buildState(nextProps.itemBlock)
      });
    }
  }

  setTab = tab => this.setState({ activeTab: tab });

  handleFieldChange = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  canSave = () => this.state.name.length;

  handleSave = async () => {
    const {
      name,
      description,
      displayType,
      selectionType,
      enableQuantityInput,
      enableItemLimit,
      limit
    } = this.state;

    this.setState({ saving: true });

    await this.props.updateItemBlock({
      blockId: this.props.itemBlockId,
      block: {
        id: this.props.itemBlockId,
        name,
        description,
        displayType,
        selectionType,
        enableQuantityInput,
        enableItemLimit,
        limit
      }
    });

    if (this.props.onDone) {
      this.props.onDone();
    }
    this.props.hideModal();
  };

  cancel = () => {
    if (typeof this.props.onCancel === "function") {
      this.props.onCancel();
    }
    this.props.hideModal();
  };

  render() {
    const {
      itemBlock,
      items,
      showItemsModal,
      reorderItems,
      handleDelete
    } = this.props;
    const { activeTab, loading, saving } = this.state;

    const blockItems = items.map(item => ({
      id: item.id,
      order: item.order,
      price: item.price ? item.price.price : null,
      limit: item.limit,
      name: item.variant.display_name,
      subname: formatVariantTitle(item.variant),
      color: item.variant.item.background_color
    }));

    return (
      <View
        {...{
          hideModal: this.cancel,
          activeTab,
          saving,
          loading: loading || !itemBlock.id,
          itemBlock: this.state,
          items: blockItems,
          handleFieldChange: this.handleFieldChange,
          canSave: this.canSave(),
          handleSave: this.handleSave,
          handleDelete,
          showItemsModal,
          reorderItems,
          tabs: [
            {
              id: "settings",
              name: "Block Settings",
              active: activeTab === "settings",
              onClick: () => this.setTab("settings")
            },
            {
              id: "items",
              name: "Item List",
              active: activeTab === "items",
              onClick: () => this.setTab("items")
            }
          ]
        }}
      />
    );
  }
}

InternalHandler.defaultProps = {
  itemBlockId: null,
  itemBlock: {},
  items: []
};

InternalHandler.propTypes = {
  mode: PropTypes.string.isRequired,
  getBlock: PropTypes.func.isRequired,
  itemBlock: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    display_type: PropTypes.string,
    selection_type: PropTypes.string,
    enable_quantity_input: PropTypes.bool,
    enable_item_limit: PropTypes.bool
  }),
  itemBlockId: PropTypes.string,
  hideModal: PropTypes.func.isRequired
};

export default InternalHandler;
