import { groupBy, get } from "lodash";
import isSubmittedCheck from "components/Event/FormsV2/Overview/utils/isSubmitted";
import isRowNotEmpty from "utils/EventForms/FormGrid/isRowNotEmpty";
import isConfirmationSentCheck from "components/Event/FormsV2/Overview/utils/isConfirmationSent";
import isReviewed from "components/Event/FormsV2/Utils/isReviewed";
import orderIsEventbrite from "components/Event/Credentials/Utils/eventbrite/order-is-eventbrite";
import eventbriteLastSynced from "components/Event/Credentials/Utils/eventbrite/last-synced";
import getStatus from "utils/Forms/get-approval-status";

const groupBySubmitted = submissions =>
  groupBy(submissions, s => isSubmittedCheck(s));

const groupByHasApprovals = (submissions, fields) => {
  const subforms = fields.filter(field => field.type === "subform");
  if (!subforms.length) {
    return groupBy(submissions, submission =>
      isReviewed(getStatus(submission))
    );
  }
  return groupBy(submissions, submission =>
    subforms.some(field => {
      const subFormSubmissions = get(
        submission,
        `values.${field.id}.value.submissions`,
        {}
      );
      return Object.keys(subFormSubmissions).some(key =>
        isReviewed(getStatus(get(subFormSubmissions, key)))
      );
    })
  );
};

const groupByIsReviewed = (submissions, fields) => {
  const subforms = fields.filter(field => field.type === "subform");
  if (!subforms.length) {
    return groupBy(submissions, submission =>
      isReviewed(getStatus(submission))
    );
  }
  return groupBy(submissions, submission =>
    subforms.every(field => {
      const subFormSubmissions = get(
        submission,
        `values.${field.id}.value.submissions`,
        {}
      );

      return Object.keys(subFormSubmissions)
        .filter(key =>
          isRowNotEmpty(
            get(subFormSubmissions[key], "values", {}),
            get(field, "subform.form.fields", [])
          )
        )
        .every(key => isReviewed(getStatus(get(subFormSubmissions, key))));
    })
  );
};

const groupBySynced = (submissions, { orders, submissionToOrderMap }) =>
  groupBy(submissions, s => {
    const orderId = submissionToOrderMap[s.id];
    const order = orders[orderId];
    const isEventBrite = orderIsEventbrite(order);
    if (isEventBrite) {
      return Boolean(eventbriteLastSynced(order));
    }
    // Don't need to sync non-eventbrite orders but need pass them on if they
    // have confirmations sent
    return isConfirmationSentCheck(s);
  });

const groupByHasSentConfirmation = submissions =>
  groupBy(submissions, s => isConfirmationSentCheck(s));

export default (
  submissions,
  fields,
  { hasIntegration, orders, submissionToOrderMap } = { hasIntegration: false }
) => {
  const isSubmitted = groupBySubmitted(submissions, fields);
  const hasApprovals = groupByHasApprovals(isSubmitted.true, fields);
  const isReviewed = groupByIsReviewed(hasApprovals.true, fields);
  let synced;
  let confirmationSent;
  if (hasIntegration) {
    synced = groupBySynced(isReviewed.true, { orders, submissionToOrderMap });
    confirmationSent = groupByHasSentConfirmation(synced.true, fields);
  } else {
    confirmationSent = groupByHasSentConfirmation(isReviewed.true, fields);
  }
  return {
    notSubmitted: isSubmitted.false || [],
    needsReview: hasApprovals.false || [],
    inReview: isReviewed.false || [],
    done: (hasIntegration ? synced.false : confirmationSent.false) || [],
    synced: confirmationSent.false || [],
    sent: confirmationSent.true || []
  };
};
