import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Documents from "./Documents";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import { portalUser } from "redux/modules/portal/user/selectors";
import { records } from "redux/modules/modules/records/selectors";
import { accounts, eventDetails } from "redux/modules/portal/selectors";
import * as RelatedRecordsSelectors from "redux/modules/modules/relatedRecords/selectors";
import { settings } from "redux/modules/portal/settings/selectors";

import { showModal } from "redux/modules/modal/actions";
import { getPortal } from "redux/modules/portal/actions";
import { getRecords } from "redux/modules/modules/records/actions";
import { getRelatedRecords } from "redux/modules/modules/relatedRecords/actions";

function mapStateToProps(state, props) {
  const recordId =
    props.portalUser.active_view.type === "user"
      ? props.portalUser.active_view.contact_id
      : props.portalUser.active_view.id;

  return {
    documentRequests: records(state, STANDARD_MODULE_IDS.documentRequests.id),
    documents: RelatedRecordsSelectors.records(
      state,
      recordId,
      STANDARD_MODULE_IDS.files.id
    ),
    eventDetails: eventDetails(state),
    portalUser: portalUser(state),
    accounts: accounts(state),
    settings: settings(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal,
      getRecords,
      getRelatedRecords,
      getPortal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Documents);
