import * as R from "ramda";
import { withRouter } from "react-router";
import Layout from "./Layout";
import { createContext } from "redux-mvc";
import module from "Portal/PortalPageWrapper";

const decorate = createContext({
  module,
  options: {
    observedDomains: ["user", "event", "permissions", "organization", "portal"],
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    persist: false
  }
});

export default R.compose(
  withRouter,
  decorate
)(Layout);
