import React from "react";
import { Div, InfoIcon } from "components/Base";
import PeoplePanels from "Accounts/PeoplePanels/View";

const PortalView = ({ isEnabled = false }) => {
  return (
    <Div
      display="row.flex-start.flex-start"
      style={{
        maxWidth: 800
      }}
    >
      <Div flex={1} pr={[0, 0, 6]}>
        {!isEnabled && (
          <Div
            bg="warning3"
            fw={3}
            p={7}
            bra={1}
            mt={2}
            mb={4}
            display="row.center.center"
          >
            <InfoIcon fs={5} size={20} mr={3} color="gray7" />
            <Div color="gray7">
              Your event admin has turned off the ability to add or update
              people. Please reach out to them if you need to make any changes.
            </Div>
          </Div>
        )}
        <PeoplePanels isAdminView={false} showAdd={isEnabled} />
      </Div>
    </Div>
  );
};
const AdminView = () => <PeoplePanels isAdminView={true} showAdd={true} />;

export { AdminView, PortalView };
