import * as R from "ramda";
import { createSelector } from "reselect";
import { getters } from "./model";

export const getItemGroups = createSelector(
  getters.zoneGroups,
  R.map(zoneGroup => ({
    id: R.prop("id", zoneGroup),
    name: R.prop("name", zoneGroup),
    items: R.map(
      zone => ({
        id: R.prop("id", zone),
        name: `${R.prop("code", zone)} - ${R.prop("name", zone)}`
      }),
      R.prop("zones", zoneGroup)
    )
  }))
);

export const getAllItems = createSelector(
  getters.zoneGroups,
  R.compose(
    R.indexBy(R.prop("id")),
    R.flatten,
    R.map(zoneGroup => [
      ...R.map(
        R.applySpec({
          id: R.prop("id"),
          name: R.prop("name"),
          code: R.prop("code")
        }),
        R.prop("zones", zoneGroup)
      )
    ])
  )
);
