import React from "react";
import SubNav from "./SubNav";
import * as R from "ramda";
import { connect } from "react-redux";
import { TAB_IDS } from "./constants";
import { withRouter } from "react-router";
import PageTitle from "EventLight/Common/PageTitle";

import { getCurrentRoute, getTabs } from "./selectors";

import Sales from "./Sales/View";
import Booths from "./Booths/View";
import Sponsorships from "./Sponsorships/View";
import Approvers from "./Approvers/View";
import SendEmails from "EventLight/Common/SendEmail/View";
import Requests from "./Requests/View";

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    currentRoute: getCurrentRoute(state, props),
    tabs: getTabs(state, props)
  }))
);

const Wrapper = ({ currentRoute, tabs }) => {
  const Page = R.prop(currentRoute)({
    [TAB_IDS.SALES]: Sales,
    [TAB_IDS.BOOTHS]: Booths,
    [TAB_IDS.SPONSORSHIPS]: Sponsorships,
    [TAB_IDS.APPROVERS]: Approvers,
    [TAB_IDS.SEND_EMAILS]: SendEmails,
    [TAB_IDS.REQUESTS]: Requests
  });

  return (
    <>
      <PageTitle titles={["Expo"]} />
      <SubNav tabs={tabs} selectedView={currentRoute} />
      <Page />
    </>
  );
};

export default decorate(Wrapper);
