import React from "react";

import { connect } from "react-redux";

import { Div, SettingsIcon, PopMenu, PopMenuCard } from "components/Base";

import { getters, actions } from "Orders/TicketsPasses";

import CopyFromPopOver from "Orders/TicketsPasses/View/CopyFromPopOver";

import CopyToPopOver from "Orders/TicketsPasses/View/CopyToPopOver";

import { getProfilePricesNone } from "Orders/TicketsPasses/selectors";

const decorate = connect(
  state => ({
    pricesTemp: getters.itemPricesTemp(state),
    isProfilePricesNone: getProfilePricesNone(state)
  }),
  {
    clearProfileAllAssignedItems: actions.updateClearProfileAllAssignedItems
  }
);

const ProfileSettingsPopOver = ({
  clearProfileAllAssignedItems,
  profileId,
  isSelected
}) => {
  return (
    <PopMenu
      Label={({ onClick }) => (
        <Div
          height={32}
          width={32}
          bg={{ default: "white", hover: "neutral1" }}
          bra={2}
          mr={2}
          display="row.center.center"
          transition="fast"
          style={{ cursor: "pointer" }}
          ml={2}
          onClick={() => {
            onClick();
          }}
        >
          <SettingsIcon size={24} color="black" />
        </Div>
      )}
    >
      {({ closeMenu }) => (
        <PopMenuCard position="bottom" align="right">
          <Div p={3} mb={1} width={250}>
            <CopyToPopOver
              profileId={profileId}
              closeParent={closeMenu}
              isSelected={isSelected}
            />
            <CopyFromPopOver profileId={profileId} closeParent={closeMenu} />
            <Div
              width={1}
              p={2}
              fw={3}
              color="black"
              onClick={() => {
                clearProfileAllAssignedItems({ profileId });
                closeMenu();
              }}
              bg={{ default: "white", hover: "neutral0" }}
            >
              Clear all assigned items
            </Div>
          </Div>
        </PopMenuCard>
      )}
    </PopMenu>
  );
};

export default decorate(ProfileSettingsPopOver);
