import { createContext } from "redux-mvc";

import * as R from "ramda";

import module, { actions } from "../index";

import Layout from "./Layout";

import rootSaga from "../sagas";

module.setRootSaga(rootSaga);

const decorate = createContext({
  module: module,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(
        actions.init({
          moduleId: this.props.moduleId
        })
      );
    }
  },
  handlers: {
    onDone(selectedModules) {
      this.props.hideModal();
      this.props.onDone(selectedModules);
    }
  },
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: ["user", "event", "modal", "organization"]
  }
});

export default decorate(Layout);
