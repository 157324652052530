import * as R from "ramda";
import React from "react";
import CSSModules from "react-css-modules";

import Description from "PortalHub/Description/View";
import AlertsSection from "./AlertsSection";
import TasksSection from "./TasksSection";
import FilesSection from "./FilesSection";
import PagesSection from "./PagesSection";
import MediaHubSection from "./MediaHubSection";

import styles from "./styles.scss";

import { connect } from "react-redux";
import { getters } from "../model";

const decorate = R.compose(
  connect((state, props) => ({
    title: getters.title(state, props),
    description: getters.description(state, props),
    backgroundImageUrl: getters.backgroundImageUrl(state, props)
  })),
  CSSModules(styles)
);

const Layout = ({ title, description, backgroundImageUrl }) => (
  <div styleName="wrapper">
    <div
      style={{
        backgroundImage: backgroundImageUrl
          ? `url(${backgroundImageUrl})`
          : undefined
      }}
      styleName="header"
    />
    <div styleName="body" style={{}}>
      {title && title.length ? (
        <div styleName="title">
          <Description
            instanceId="home"
            title={title}
            description={description}
          />
        </div>
      ) : null}

      <section className={styles.grid}>
        <h1 className={styles.title}>{title} Portal</h1>
        <div dangerouslySetInnerHTML={{ __html: description }} />
        <div className={styles.divider}>a</div>
        <AlertsSection />
        <TasksSection />
        <FilesSection />
        <PagesSection />
        <MediaHubSection showDivider={false} />
      </section>
    </div>
  </div>
);

export default decorate(Layout);
