import PropTypes from "prop-types";
import React from "react";
import ButtonAction from "components/Global/Table3/ButtonAction";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const AddRequest = ({ action, label }) => (
  <div styleName="container">
    <ButtonAction
      className={css.button}
      type="add-entry"
      icon={<i className={["material-icons", css.buttonIcon].join(" ")}>add</i>}
      label={
        <span className={css.buttonTitle}>
          {label ? `ADD ${label}` : "ADD ANOTHER"}
        </span>
      }
      action={action}
    />
  </div>
);

AddRequest.propTypes = {
  action: PropTypes.func.isRequired
};

export default CSSModules(AddRequest, css);
