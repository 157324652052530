import React from "react";
import { connect } from "react-redux";
import { Div, FontIcon, Text6 } from "components/Base";
import { getters } from "HealthPass/HealthPassStatus/model";
import NextButton from "./NextButton";

const decorate = connect(state => ({
  status: getters.status(state),
  name: getters.name(state),
  group: getters.group(state),
  date: getters.date(state),
  expired: getters.expired(state),
  expiresAt: getters.expiresAt(state),
  confirmationMessage: getters.confirmationMessage(state)
}));

const StatusPage = ({
  status,
  name,
  group,
  expired,
  expiresAt,
  date,
  confirmationMessage
}) => (
  <Div>
    <Div mb={6}>
      <Div
        bra={3}
        bg={expired ? "#ccc" : status === "approved" ? "success5" : "danger7"}
        display="column.center.center"
        style={{
          padding: "35px 20px"
        }}
      >
        {group && group.length ? (
          <Div
            color="white"
            style={{
              fontSize: "30px",
              marginBottom: "20px",
              fontWeight: "bold"
            }}
          >
            {group}
          </Div>
        ) : null}
        {name && name.length ? (
          <Div
            color="white"
            style={{
              marginBottom: "10px",
              fontSize: "60px",
              textTransform: "uppercase",
              fontWeight: "bold"
            }}
          >
            {name}
          </Div>
        ) : null}

        {!expired && status === "approved" ? (
          <FontIcon
            color="white"
            style={{
              fontSize: "180px"
            }}
          >
            check_circle_outline
          </FontIcon>
        ) : (
          <FontIcon
            color="white"
            style={{
              fontSize: "180px"
            }}
          >
            highlight_off
          </FontIcon>
        )}
        <Div
          color="black"
          style={{
            fontSize: "35px",
            marginTop: "10px",
            fontWeight: "bold",
            textTransform: "uppercase"
          }}
        >
          {expired ? "Expired" : date}
        </Div>
        {!expired && expiresAt ? (
          <Div
            color="black"
            style={{
              marginTop: "5px"
            }}
          >
            Expires at {expiresAt}
          </Div>
        ) : null}
      </Div>

      {/*
      {R.propOr(false, "show_description", data) && (
        <Div
          color="gray8"
          fs={3}
          className="lennd-wysiwyg-content portal-message-text"
        >
          <span
            dangerouslySetInnerHTML={{
              __html: R.propOr("", "description", data)
            }}
          />
        </Div>
      )}
      */}
    </Div>

    <Div
      width={1}
      style={{ height: "1px", backgroundColor: "#ccc", margin: "20px 0" }}
    />
    <Text6 bold color="black">
      Status: {status === "approved" ? "Passed" : "Not Passed"}
    </Text6>

    {confirmationMessage && confirmationMessage.length ? (
      <Div
        color="gray8"
        fs={3}
        className="lennd-wysiwyg-content portal-message-text"
        my={5}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: confirmationMessage
          }}
        />
      </Div>
    ) : (
      <Div height={50} />
    )}

    {expired ? (
      <Div display="row.space-between.center">
        <NextButton />
      </Div>
    ) : null}
  </Div>
);

export default decorate(StatusPage);
