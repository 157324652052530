import { REQUEST, RECEIVE } from "./constants";
import * as selectors from "./selectors";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getTemplates(data) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({
        type: REQUEST,
        payload: {}
      });
      const result = await api.get(getState().user.user.credentials, data);
      dispatch({
        type: RECEIVE,
        payload: {
          templates: result.templates
        }
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching module templates"
          }
        ])
      );
    }
  };
}
