import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  PostalCodeElement
} from "react-stripe-elements";
import FormElements from "components/Global/Modal/Layout/FormElements";

const { InputGroup, Label, Input } = FormElements;

const CreditCard = CSSModules(
  ({
    name,
    updateName,
    updateCardNumber,
    updateExpirationDate,
    updateCVC,
    updateZipCode
  }) => (
    <div styleName="container">
      <div>
        <InputGroup>
          <Label>
            <div styleName="label">Card number</div>
          </Label>
          <CardNumberElement onChange={updateCardNumber} />
        </InputGroup>
      </div>
      <div styleName="groups">
        <InputGroup className={css.group}>
          <Label>
            <div styleName="label">Name on card</div>
          </Label>
          <Input onChange={updateName} type="text" value={name} />
        </InputGroup>
        <InputGroup className={css.group}>
          <Label>
            <div styleName="label">Expires</div>
          </Label>
          <CardExpiryElement onChange={updateExpirationDate} />
        </InputGroup>
        <InputGroup className={css.group}>
          <Label>
            <div styleName="label">CVC</div>
          </Label>
          <CardCVCElement onChange={updateCVC} />
        </InputGroup>
        <InputGroup className={css.group}>
          <Label>
            <div styleName="label">Zip Code</div>
          </Label>
          <PostalCodeElement onChange={updateZipCode} />
        </InputGroup>
      </div>
    </div>
  ),
  css
);

export default CreditCard;
