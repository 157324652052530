import PropTypes from "prop-types";
import React from "react";
import * as R from "ramda";

import { Div, OpenInNewIcon } from "components/Base";

import BaseTableView from "../BaseTableView";
import { get } from "lodash";
import Table3 from "components/Global/Table3/Table3";
import resolveFormatter from "components/Global/Table3/CellFormatters/utils/resolveFormatter";
import resolveCellClass from "components/Global/Table3/CellFormatters/utils/resolveCellClass";
import resolveEditor from "components/Global/Table3/CellEditors/utils/resolveEditor";
import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";
import Immutable from "immutable";
import Table3Helpers from "components/Global/Table3/Utilities/Helpers";
import jQuery from "jquery";
import EmptyRowsView from "../HelperComponents/EmptyRows";
import * as ReactDataGridPlugins from "react-data-grid-addons";
import { findBy } from "utils/General";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import toString from "utils/value-types/to-string";
import getValue from "utils/value-types/get-value";

const isNilOrEmpty = value =>
  R.isNil(value) || value === "undefined" || value === "";
const noValueString = "(no value)";

const {
  Data: { Selectors }
} = ReactDataGridPlugins;

const convertToTable3Row = rows =>
  rows.map(row => ({
    ...row,
    ...row.values
  }));

const OpenRecordHeader = () => (
  <Div display="row.center.center">
    <OpenInNewIcon />
  </Div>
);

class GridView extends BaseTableView {
  constructor(props) {
    super(props);
    this.state = {
      expandedRows: {}
    };
  }
  getColumns(columns) {
    const scrollParent = jQuery(".table-scroll-container")[0];
    const columnsToPass = columns.map(column => {
      const result = {
        ...column,
        key: column.id,
        cellClass: "",
        resizable: true,
        getRowMetaData: this.getRowMetaData
      };

      const Editor = resolveEditor(column);
      if (Editor) {
        const editorProps = {
          scrollParent,
          ...resolveEditorProps(column, this.props.eventDetails)
        };
        result.editor = <Editor {...editorProps} />;
      }

      // formatters
      result.formatter = resolveFormatter(column); // TODO: Allow override of formatter
      result.cellClass = resolveCellClass(column);

      // instance specific
      if (column.id === -1) {
        result.cellClass += " react-grid-Cell-title";
      }

      return result;
    });

    // @NOTE: Currently we don't add for accounts or contacts
    if (
      ![
        STANDARD_MODULE_IDS.accounts.id,
        STANDARD_MODULE_IDS.contacts.id,
        STANDARD_MODULE_IDS.documentRequests.id
      ].includes(this.props.moduleId)
    ) {
      columnsToPass.unshift({
        id: "open-record",
        name: "Open",
        type: "open-record",
        headerRenderer: <OpenRecordHeader />,
        settings: {},
        key: "open-record",
        cellClass: "",
        resizable: false,
        visible: true,
        locked: true,
        getRowMetaData: this.getRowMetaData,
        width: 40,
        formatter: resolveFormatter({ type: "open-record" })
      });
    }

    return columnsToPass;
  }

  toggleRowSelect = rows => {
    this.props.setSelectedRows(rows.map(r => r.id).filter(Boolean));
  };

  selectGroupByField = groupedBy => {
    this.props.updateView({
      orgId: this.props.params.orgId,
      eventId: this.props.params.eventId,
      moduleId: this.props.moduleId,
      viewId: this.props.params.viewId,
      view: {
        groupedBy
      },
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId
      }
    });
  };

  onColumnResize = (fieldId, width) => {
    if (this.props.onColumnResize) {
      this.props.onColumnResize(fieldId, width);
    }
  };

  onCellCopyPaste = ({ cellKey, value, rowIdx }) => {
    const { fields, references } = this.props;
    const destinationColumnId = cellKey;
    const destinationColumn = this.props.fields.find(
      f => f.id === destinationColumnId
    );
    const dataType = value.type || "text";

    if (!Table3Helpers.canAcceptPaste(destinationColumn, dataType)) {
      return;
    }

    const rows = convertToTable3Row(
      this.getRows(this.getVisibleRecords(), fields, references)
    );
    this.props.saveValue({
      rowId: rows[rowIdx].id,
      value,
      columnId: destinationColumnId
    });
  };

  onCellsDragged = (e, cells) => {
    // @NOTE: We filter out null'd rowIds that may show up if dragging over a grouped header
    cells.filter(c => c.rowId).forEach(cell => this.props.saveValue(cell));
  };

  onRowSort = rowOrder => this.saveRowOrder(rowOrder);

  onRowExpandToggle = ({ columnGroupName, name, shouldExpand }) => {
    this.setState({
      expandedRows: R.set(
        R.lensPath([columnGroupName, name, "isExpanded"]),
        shouldExpand,
        this.state.expandedRows
      )
    });
  };

  render() {
    const {
      addRecord,
      additionalContextMenus,
      contextMenuActions,
      contextMenuOrder,
      draggable,
      eventDetails,
      fields,
      Footer,
      heightSizer,
      references,
      saveValue,
      selectedRows,
      readOnly,
      moduleId
    } = this.props;

    const rows = convertToTable3Row(
      this.getRows(this.getVisibleRecords(), fields, references)
    );
    const columns = this.getColumns(this.props.fields).filter(c => c.visible);
    const selectedHydratedRows = rows.filter(r => selectedRows.includes(r.id));
    const groupBy = this.props.activeView.grouped_by;
    const groupByName = R.propOr(
      "group",
      "name",
      findBy("id", groupBy)(fields)
    );
    const groupByField = findBy("id", groupBy)(fields);
    const availableCatalogItems = R.path(
      ["settings", "availableCatalogItems"],
      groupByField
    );

    const formattedRows = rows.map(row => {
      const value = R.path([groupBy], row);

      const fieldId =
        groupByField !== undefined && groupByField.type === "lookup"
          ? R.path(["settings", "fieldId"], groupByField)
          : R.prop("id", groupByField);

      const meta = {
        availableCatalogItems,
        references,
        fieldId,
        hideQuantity: true
      };

      let formattedValue = isNilOrEmpty(value)
        ? noValueString
        : toString(getValue(value, value.type), value.type, eventDetails, meta);

      if (isNilOrEmpty(formattedValue)) {
        formattedValue = noValueString;
      }

      return {
        [groupByName]: formattedValue,
        ...row
      };
    });

    const groupedRows = R.groupBy(R.prop(groupByName), formattedRows);

    return (
      <div
        className="table-scroll-container show-drag-handle"
        style={{
          flex: 1,
          position: "relative",
          backgroundColor: "#FAFAFA",
          overflow: "auto",
          height: "100%"
        }}
      >
        <Table3
          disableSortableTable
          fields={this.props.fields}
          moduleId={moduleId}
          groupBy={this.props.activeView.grouped_by}
          columns={new Immutable.List(columns)}
          contextMenuMeta={{ eventDetails, references }}
          draggable={draggable}
          enableCellSelect={!readOnly}
          EmptyRowsView={() => (
            <EmptyRowsView addRecord={addRecord} moduleId={moduleId} />
          )}
          Footer={Footer}
          heightSizer={heightSizer}
          onColumnResize={this.onColumnResize}
          onCellCopyPaste={this.onCellCopyPaste}
          onCellsDragged={this.onCellsDragged}
          onRowSelect={this.toggleRowSelect}
          onRowSort={this.onRowSort}
          onRowExpandToggle={this.onRowExpandToggle}
          groupedRows={groupedRows}
          rows={
            !R.isNil(groupBy)
              ? Selectors.getRows({
                  rows: formattedRows,
                  groupBy: [{ key: groupByName, name: "Sort Name" }],
                  expandedRows: this.state.expandedRows
                })
              : rows
          }
          saveCell={saveValue}
          selectedRows={selectedHydratedRows}
          contextMenus={Table3Helpers.defaultContextMenus(
            this.props.fields,
            contextMenuActions,
            {
              headerMenus: {
                groupBy: {
                  type: "row",
                  title: "Group By",
                  icon: "group_work",
                  allow: info => {
                    const columnType = get(
                      columns.find(c => c.id === info.column),
                      ["type"]
                    );
                    return (
                      columnType &&
                      !["open-record", "file"].includes(columnType)
                    );
                  },
                  action: info => this.selectGroupByField(info.column)
                },
                ...additionalContextMenus.headerMenus
              },
              rowMenus: {
                ...additionalContextMenus.rowMenus
              }
            },
            contextMenuOrder
          )}
        />
      </div>
    );
  }
}

GridView.defaultProps = {
  additionalContextMenus: {},
  contextMenuActions: {},
  customMenus: {},
  draggable: false
};

GridView.propTypes = {
  additionalContextMenus: PropTypes.object,
  addRecord: PropTypes.func.isRequired,
  contextMenuActions: PropTypes.object,
  contextMenuOrder: PropTypes.array.isRequired,
  draggable: PropTypes.bool.isRequired,
  eventDetails: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  Footer: PropTypes.element,
  heightSizer: PropTypes.element,
  references: PropTypes.object.isRequired,
  saveValue: PropTypes.func.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired
};

export default GridView;
