import React from "react";
import { Div, MediumFilledButton } from "components/Base";
import { connect } from "react-redux";
import { actions } from "../model";
import ReactPlayer from "react-player";
import { selectedCourse as getSelectedCourse } from "../selectors";
import { PAGE_ACTIONS } from "../constants";

const decorate = connect(
  (state, props) => ({
    selectedCourse: getSelectedCourse(state, props)
  }),
  {
    toggleCourseCompletionStatus: (id, completed) =>
      actions.executeAction({
        type: PAGE_ACTIONS.MARK_AS_COMPLETED,
        id,
        completed
      })
  }
);

const CurrentCourse = ({ selectedCourse, toggleCourseCompletionStatus }) => (
  <Div>
    {selectedCourse.video_url && (
      <div
        className="player-wrapper"
        style={{
          marginBottom: 20,
          backgroundColor: "#222",
          position: "relative",
          paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */
        }}
      >
        <ReactPlayer
          className="react-player"
          url={selectedCourse.video_url}
          width="100%"
          height="100%"
          pip={false}
          controls={true}
          style={{
            position: "absolute",
            top: 0,
            left: 0
          }}
        />
      </div>
    )}

    <Div fw={4} fs={6} color="#123059">
      {selectedCourse.name}
    </Div>
    <Div>
      <span
        dangerouslySetInnerHTML={{
          __html: selectedCourse.content
        }}
      />
    </Div>
    <Div>
      <MediumFilledButton
        onClick={() =>
          toggleCourseCompletionStatus(
            selectedCourse.id,
            selectedCourse.completed
          )
        }
        bg={selectedCourse.completed ? "success4" : "info5"}
      >
        {selectedCourse.completed ? "Completed" : "Mark as Complete"}
      </MediumFilledButton>
    </Div>
  </Div>
);

export default decorate(CurrentCourse);
