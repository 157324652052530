import ALL_TIMEZONES from "utils/timezones";
import { CalendarIcon, CheckBoxIcon } from "components/Base";

export const ALLOW_ACCESS_INSTANCE = "AllowAccessInstanceId";

export const DEFAULT_SELECTED_USER_PROFILES = {
  userIds: [],
  profileIds: []
};

export const START_DATE = 1569355200;
export const END_DATE = 1569365200;
export const DATE_FORMATS = [
  { value: "D-MMM", label: "D-MMM" },
  { value: "YYYY-MM-DD", label: "YYYY-MM-DD" }
];
export const TIME_FORMATS = [{ value: "hh:mm:ss A", label: "hh:mm:ss A" }];

export const DEFAULT_TIME_FORMAT = TIME_FORMATS[0].value;
export const DEFAULT_DATE_FORMAT = DATE_FORMATS[0].value;

export const DATE_TIME_OPTIONS = [
  { value: "date", label: "Date" },
  { value: "time", label: "Time" },
  { value: "end_time", label: "End Time" },
  { value: "end_date", label: "End Date" },
  { value: "duration", label: "Duration" },
  { value: "start_datetime", label: "Start Date/Time" },
  { value: "end_datetime", label: "End Date/Time" },
  { value: "day_of_the_week", label: "Day of the Week" }
];

export const DEFAULT_DATE_TIME_OPTIONS = [
  "date",
  "time",
  "end_time",
  "end_date"
];

export const DATE_TIME_TABLE_CONFIG = {
  headers: ["Date", "Time", "End Time", "End Date", "Duration"],
  rowProps: ["date", "time", "endTime", "endDate", "duration"]
};

export const TIME_ZONES = [
  {
    value: "event",
    label: "Use event timezone"
  },
  ...ALL_TIMEZONES
];

export const DEFAULT_TIME_ZONE = "event";

export const SCHEDULE_TYPE = {
  SCHEDULE: "SCHEDULE",
  PROJECT: "PROJECT"
};

export const TYPES_OF_SCHEDULES = [
  {
    id: SCHEDULE_TYPE.SCHEDULE,
    name: "Schedule",
    description: "Activity Start Dates, Times, etc",
    Icon: CalendarIcon
  },
  {
    id: SCHEDULE_TYPE.PROJECT,
    name: "Project",
    description: "Task Completion, Due Dates & More",
    Icon: CheckBoxIcon
  }
];
