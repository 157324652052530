import * as R from "ramda";

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { getOrderNumberRows } from "Items/Manage/selectors";
import { getters } from "Items/Manage";

import CardByOrder from "Items/Manage/View/CardByOrder";
import { Div } from "components/Base";

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    rows: getOrderNumberRows(state, props),
    mode: getters.mode(state, props),
    parentOrderId: getters.orderId(state, props)
  }))
);

const OrderListView = ({
  rows,
  handlers,
  mode,
  m = 3,
  parentOrderId = "",
  instanceId = ""
}) => (
  <Div m={m}>
    {rows.map(
      ({ order, passes, allCredentialLineItemIds, allMealLineItemIds }) => (
        <CardByOrder
          instanceId={instanceId}
          key={order.customer_contact_id}
          order={order}
          passes={passes}
          allCredentialLineItemIds={allCredentialLineItemIds}
          allMealLineItemIds={allMealLineItemIds}
          mode={mode}
          handlers={handlers}
          parentOrderId={parentOrderId}
        />
      )
    )}
  </Div>
);

export default decorate(OrderListView);
