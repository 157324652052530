export const FEATURES = {
  AGENDA: "agenda",
  BOOTHS: "booths",
  CATERING: "catering",
  CONTENT: "content",
  ALERTS: "alerts",
  TASKS: "tasks",
  EMAILS: "emails",
  INVOICES: "invoices",
  INVENTORY: "inventory",
  FORMS: "forms",
  PAGES: "pages",
  PASSES: "passes",
  PORTALS: "portals",
  EXPO: "expo",
  FILE_REQUESTS: "file_requests",
  SCHEDULE: "schedule",
  HEALTH_PASS: "health_pass",
  GUEST_LISTS: "guest_lists"
};
export const TYPES = {};
