import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "Modules/MergeTagTableModal";
import { Div, Text4 } from "components/Base";

const decorate = connect(
  state => ({
    templateText: getters.templateText(state)
  }),
  {
    setTemplateText: actions.setTemplateText
  }
);

const CopyText = ({ templateText, setTemplateText, setInputRef }) => (
  <Div p={4} height={1} bg="white">
    <Text4 bold color="black" mb={2}>
      Copy this into your .docx template
    </Text4>
    <Div width={1} flex={1} style={{ overflow: "hidden" }}>
      {/*
      <textarea
        ref={inputRef => setInputRef(inputRef)}
        style={{
          height: 358,
          resize: "none",
          width: "100%",
          borderRadius: "3px",
          borderColor: "#e7e6ea"
        }}
        value={templateText}
        onChange={ev => setTemplateText(ev.target.value)}
      />
      */}
      <Div
        p={5}
        bc="gray3"
        b={1}
        bra={1}
        color="black"
        style={{
          height: 358,
          width: "100%",
          overflow: "auto"
        }}
      >
        <span
          ref={inputRef => setInputRef(inputRef)}
          dangerouslySetInnerHTML={{
            __html: templateText
          }}
        />
      </Div>
    </Div>
  </Div>
);

export default decorate(CopyText);
