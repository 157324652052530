import { createModule } from "redux-mvc";

import formModule from "ui-kit/Form";
import model from "Organizations/CloneEventModal/model";
import sagas from "Organizations/CloneEventModal/sagas";

const module = createModule(model);
module.plugModule(formModule);

module.setRootSaga(sagas);

export default module;
