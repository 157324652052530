import React, { Component } from "react";
import toString from "utils/value-types/to-string/calculated-number";
import { isEqual } from "lodash";

export default class CaluclatedNumberFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  render() {
    const result = toString(null, {
      ...this.props.dependentValues.meta.columnSettings,
      values: this.props.dependentValues,
      fields: this.props.dependentValues.meta.columns
    });

    return <div>{result}</div>;
  }
}
