import React from "react";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { connect } from "react-redux";
import { getEventUsers } from "../selectors";
import { actions } from "../model";
import { SmallToggle } from "components/Base";
import AvatarCard from "ui-kit/AvatarCard";
import Tooltip from "ui-kit/Tooltip";

const decorate = R.compose(
  connect(
    state => ({
      users: getEventUsers(state)
    }),
    {
      toggleManagers: actions.toggleManagers
    }
  ),
  CSSModules(css)
);

const ManagersTable = ({ users, toggleManagers }) => (
  <div styleName="managersTable">
    <div styleName="header">
      <div styleName="name">Name</div>
      <div styleName="manager">Manager</div>
    </div>
    {R.map(
      ({
        isManager,
        user_fname,
        user_lname,
        user_id,
        user_photo_url,
        user_email,
        disable
      }) => (
        <div styleName="bodyRow">
          <div styleName="name">
            <div styleName="row">
              <AvatarCard
                avatar={{
                  photoUrl: user_photo_url,
                  name: `${user_fname} ${user_lname}`
                }}
                size="medium"
                avatarProps={{
                  singleInitial: false
                }}
              />
              <div styleName="column" style={{ marginLeft: 8 }}>
                <div style={{ fontWeight: "bold" }}>
                  {user_fname} {user_lname}
                </div>
                <div style={{ fontSize: 14 }}>{user_email}</div>
              </div>
            </div>
          </div>
          <div styleName="manager">
            {disable ? (
              <SmallToggle active={isManager} onClick={() => {}} />
            ) : (
              <SmallToggle
                active={isManager}
                onClick={() => toggleManagers({ isManager, user_id })}
              />
            )}
          </div>
        </div>
      ),
      users
    )}
  </div>
);

export default decorate(ManagersTable);
