import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Catalog from "./Catalog";

import { catalogItems } from "@lennd/value-types/src/constants/standard-modules";
import { deselectAllRows } from "redux/modules/modules/records/settings/actions";
import { fields } from "redux/modules/modules/module/selectors";
import { getModule } from "redux/modules/modules/module/actions";
import { getRecords } from "redux/modules/modules/records/actions";
import { records } from "redux/modules/modules/records/selectors";

function mapStateToProps(state) {
  return {
    fields: fields(state, catalogItems.id),
    records: records(state, catalogItems.id)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      deselectAllRows: () => deselectAllRows(catalogItems.id),
      getModule,
      getRecords
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Catalog);
