import PropTypes from "prop-types";
import React from "react";
import EditorBase from "./TableConnector";
import Date from "components/Global/Editors/DateEditor";

import { getRowMetadata, ep } from "ui-kit/Datagrid/utils";

export default class DateEditor extends EditorBase {
  render() {
    const rowMetaData = getRowMetadata(ep(this.props));

    return (
      <Date
        rowMetaData={rowMetaData}
        ref={c => (this.focusRef = c)}
        displayType={this.props.column.settings.displayType}
        value={this.state}
        onChange={this.handleChange}
        continuous
      />
    );
  }
}

DateEditor.propTypes = {
  ...EditorBase.propTypes,
  showTodayAsDefault: PropTypes.bool
};
