import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Panel from "./Panel";
import * as ModalActions from "redux/modules/modal/actions";
import * as EventSelectors from "redux/modules/event/selectors";
import { getRecords } from "redux/modules/modules/records/actions";
import { isManifestApproved } from "components/Global/Approvals/utils/approvals-helpers";
import { withRouter } from "react-router";
import { APPROVAL } from "utils/system-field-ids";

import {
  createReview,
  createReviews,
  removeReview,
  removeReviews
} from "redux/modules/approvalWorkflows/reviews/actions";
import exportFactory from "components/Global/CRM/TableViews/HelperComponents/export-factory";
import selectedRowFactory from "components/Global/CRM/TableViews/HelperComponents/selected-row-factory";
import { getAccountInventoryReport } from "redux/modules/inventory/reports/account-overview/actions";
import { getModuleCounts } from "redux/modules/inventory/modules/counts/actions";
import { records } from "redux/modules/modules/records/selectors";
import Table from "components/Event/InventoryV2/ModuleTable";

function mapStateToProps(state, props) {
  const requesterField = props.fields.find(
    f => f.internal_name === "requester_company"
  );
  const filteredRecords = records(state, props.parent.id).filter(
    r =>
      r.values[requesterField.id] &&
      r.values[requesterField.id].value.records[0] === props.activeAccountId
  );
  return {
    eventId: EventSelectors.eventDetails(state).id,
    records: filteredRecords,
    status: filteredRecords.every(r =>
      isManifestApproved(r.values[APPROVAL].value)
    )
      ? "approved"
      : "pending",
    Table
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createReview,
      createReviews,
      removeReview,
      removeReviews,
      getModuleCounts,
      getAccountInventoryReport,
      getRecords,
      ...ModalActions
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    selectedRowFactory(exportFactory(Panel))
  )
);
