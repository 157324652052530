import * as R from "ramda";
import { connect } from "react-redux";

import { getField } from "ui-kit/Form/selectors";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";

const decorate = R.compose(
  WithFormInstanceConsumer({
    fieldType: FIELD_TYPES.VALUE
  }),
  connect((state, props) => ({
    field: getField(state, props)
  }))
);

export const UCField = ({ field = "", children }) =>
  typeof children === "function" && !R.isEmpty(field) ? children(field) : "";

export const Field = decorate(UCField);
