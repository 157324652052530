import React, { Component } from "react";
import * as R from "ramda";

import { blackOrWhiteByContrast } from "utils/General";
import rgb from "hex-rgb";
import { Div } from "components/Base";

import { getRow, getCellValue } from "ui-kit/Datagrid/utils";
import { EMPTY_ERRORS } from "ui-kit/Validator/constants";

import ErrorMark from "./ErrorMark";

const errorStyle = {
  display: "flex",
  flexDirection: "row-reverse",
  alignItems: "center",
  justifyContent: "space-between"
};

class TextCellFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props.value, nextProps.value);
  }

  render() {
    const { valid, description } = R.propOr(
      EMPTY_ERRORS,
      "error",
      this.props.value
    );
    const value = getCellValue(this.props);
    const valueColor = getRow(this.props, "valueColor"); // @TODO check where value color is comming from
    let style = {
      lineHeight: "34px"
    };
    if (valueColor) {
      style = {
        ...style,
        background: valueColor,
        color: blackOrWhiteByContrast(...rgb(valueColor)),
        borderRadius: 4,
        fontWeight: 600,
        fontSize: 12,
        padding: "0 6px",
        lineHeight: "2em",
        marginRight: 4
      };
    }

    return (
      <Div
        truncate
        style={{
          ...style,
          ...this.props.style,
          ...((!valid && errorStyle) || {})
        }}
        title={value}
      >
        <ErrorMark valid={valid} description={description} />
        {value}
      </Div>
    );
  }
}

export default TextCellFormatter;
