import { createModule } from "redux-mvc";

import model from "Portal/PortalPeople/model";
import tabsModule from "Portal/Tabs";
import searchBarModule from "ui-kit/SearchBar";
import tableModule from "ui-kit/Table";

import sagasModule from "Portal/PortalPeople/sagas";

const module = createModule(model);
module.plugModule(tabsModule);
module.plugModule(searchBarModule);
module.plugModule(tableModule);

module.setRootSaga(sagasModule);

export default module;
