import * as R from "ramda";

import React from "react";
import { connect } from "react-redux";

import { actions } from "../model";
import { getMeals } from "../selectors";

import { withProps, noop } from "utils/General";

import {
  Div,
  makeTable,
  Text3,
  SmallOutlineButton,
  ExpandIcon,
  ThreeQuarterSizeSquareIcon,
  PopoverMenu,
  FontIcon
} from "components/Base";

import {
  HeaderStyler,
  RowStyler
} from "components/Event/Settings/Layout/Tables/stylers";

import CSSModules from "react-css-modules";
import css from "components/Event/Settings/Layout/Tables/styles.css";

const TableStyler = withProps({
  bra: 1
})(Div);

const MealCell = CSSModules(
  ({ value, color, onMealClick }) => (
    <Div display="row.flex-start.center" onClick={onMealClick}>
      <ThreeQuarterSizeSquareIcon
        color={color}
        size={12}
        mr={2}
        style={{ minWidth: 12 }}
      />
      <Text3 bold mr={3} primary>
        {value}
      </Text3>
      <SmallOutlineButton
        m={2}
        pill
        styleName="childContainer"
        OnlyIcon={ExpandIcon}
        color="neutral7"
      />
    </Div>
  ),
  css
);

const decorateActions = connect(
  null,
  {
    onEdit: actions.editMeal,
    onClone: actions.cloneMeal,
    onDelete: actions.deleteMeal,
    moveMealUp: actions.moveMealUp,
    moveMealDown: actions.moveMealDown
  }
);

const Actions = decorateActions(
  ({
    id,
    first = false,
    last = false,
    onEdit = noop,
    onClone = noop,
    onDelete = noop,
    moveMealUp = noop,
    moveMealDown = noop
  }) => (
    <PopoverMenu
      Label={({ onClick }) => (
        <SmallOutlineButton onClick={onClick} py={1}>
          <FontIcon fs={3}>more_horiz</FontIcon>
        </SmallOutlineButton>
      )}
      menuItems={[
        ["Edit", () => onEdit(id)],
        ["Clone", () => onClone(id)],
        ["Delete", () => onDelete(id)],
        !first && ["Move up", () => moveMealUp(id)],
        !last && ["Move down", () => moveMealDown(id)]
      ].filter(R.identity)}
    />
  )
);

const MealsTable = makeTable({
  TableStyler,
  HeaderStyler,
  RowStyler,
  HeaderCellStyler: Div,
  RowCellStyler: Div,
  columnProps: [{ width: 2 / 3 }, { width: 1 / 3 }],
  headerCellComps: [
    () => <Text3 bold>Name</Text3>,
    () => <Text3 bold>Actions</Text3>
  ],
  rowCellComps: [
    ({ value, color, onMealClick }) => (
      <MealCell value={value} color={color} onMealClick={onMealClick} />
    ),
    ({ id, first, last }) => <Actions id={id} first={first} last={last} />
  ]
});

const decorate = connect(
  (state, props) => ({
    meals: getMeals(state, props)
  }),
  {
    showAddMealModal: () => actions.addMeal()
  }
);

const Meals = ({ meals = [], showAddMealModal = noop }) => (
  <Div p={5}>
    <Div
      bra="1"
      style={{
        width: 300,
        backgroundColor: "#FAFAFA",
        boxShadow:
          "0px 1px 5px 0px rgba(0,0,0,0.10), 0px 6px 12px 0px rgba(0,0,0,0.17)"
      }}
    >
      <MealsTable rowsData={meals} />
      <Text3 onClick={showAddMealModal} primary bold px={4} py={3}>
        + Add meal
      </Text3>
    </Div>
  </Div>
);

export default decorate(Meals);
