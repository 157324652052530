import { REQUEST, RECEIVE } from "./constants";
import { ERROR as SUBMISSION_ERROR } from "../constants";
import api from "./api";

export function getComments(submissionId) {
  return async dispatch => {
    dispatch({ type: REQUEST });
    try {
      const result = await api.get(submissionId);
      dispatch({
        type: RECEIVE,
        payload: result.comments
      });
    } catch (error) {
      dispatch({
        type: SUBMISSION_ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function createComment(data) {
  return async (dispatch, getState) => {
    try {
      return await api.post(getState().user.user.credentials, data);
    } catch (error) {
      dispatch({
        type: SUBMISSION_ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}
