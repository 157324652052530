import { registerError } from "redux/modules/errors/actions";
import api from "./api";

export function addPermissionSet({ eventId, name, types, itemBlocks }) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post(getState().user.user.credentials, {
        eventId,
        name,
        types,
        itemBlocks
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding permission set"
          }
        ])
      );
    }
  };
}
export function deletePermissionSet(permissionSetId) {
  return async (dispatch, getState) => {
    try {
      return await api.delete(
        getState().user.user.credentials,
        permissionSetId
      );
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred removing permission set"
          }
        ])
      );
    }
  };
}

export function updatePermissionSet({ values, permissionSetId }) {
  return async (dispatch, getState) => {
    try {
      return await api.put(getState().user.user.credentials, {
        permissionSetId,
        values
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating permission set"
          }
        ])
      );
    }
  };
}
