import api from "Submission/Editor/api";
import { registerError } from "redux/modules/errors/actions";
import { getCredentials } from "redux/modules/user/selectors";

import { NAMESPACE } from "Submission/Editor/constants";

const addType = type => f => {
  f.type = type;
  return f;
};

export const deleteSubmission = data =>
  addType(NAMESPACE)(async (dispatch, getState) => {
    const credentials = getCredentials(getState());
    try {
      await api.deleteSubmission(credentials, data);
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting submission"
          }
        ])
      );
    }
  });
