import React from "react";
import { connect } from "react-redux";
import { actions } from "Portal/Settings/Page/model";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import css from "./style.scss";
import TreeTable from "ui-kit/TreeTable/View";
import { Div, BigOutlineButton, AddCircleIcon } from "components/Base";
import Row from "./Row";
import DropPlace from "./DropPlace";

const decorate = connect(
  null,
  {
    openAddPageModal: actions.openAddPageModal,
    openAddFolderModal: actions.openAddFolderModal
  }
);

const Table = ({
  openAddPageModal
  // openAddFolderModal
}) => (
  <div styleName="tree">
    <div styleName="header">
      <div styleName="name" style={{ paddingLeft: 22 }}>
        Page Name
      </div>
      <div styleName="modified">Modified</div>
      {/* <div styleName="visible">Visible To</div> */}
      <div styleName="status">Status</div>
      <div styleName="actions">Actions</div>
    </div>
    <TreeTable rowComponent={Row} dropComponent={DropPlace} />
    <div styleName="footer">
      <BigOutlineButton
        width={136}
        LeftIcon={AddCircleIcon}
        onClick={() => openAddPageModal({ id: null })}
      >
        <Div fs={3} fw={3} color="black">
          New Page
        </Div>
      </BigOutlineButton>
      {/* <BigOutlineButton
        ml={2}
        width={156}
        LeftIcon={() => (
          <FontIcon fs={5} color="black">
            folder_open
          </FontIcon>
        )}
      >
        <Div
          fs={3}
          fw={3}
          color="black"
          ml={2}
          onClick={() => openAddFolderModal({ id: null, folderName: "" })}
        >
          Create Folder
        </Div>
        </BigOutlineButton> */}
    </div>
  </div>
);

export default R.compose(
  decorate,
  CSSModules(css)
)(Table);
