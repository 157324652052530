import { put, all, takeEvery, fork, select, call } from "redux-saga/effects";
import { actions } from "HealthPass/HealthPassStatus/model";
import { getCredentials } from "redux/modules/user/selectors";

import Api from "HealthPass/HealthPassStatus/api";

const init = function*({ payload: { linkId } }) {
  const credentials = yield select(getCredentials);

  try {
    yield put(actions.setLinkId(linkId));

    const { payload } = yield call(Api.getLink, {
      credentials,
      linkId
    });

    yield put(
      actions.seedFormData({
        formPayload: payload
      })
    );
    yield put(actions.setLoading(false));
  } catch (e) {
    yield put(actions.setNotFound(true));
    yield put(actions.setLoading(false));
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([fork(watchInit)]);
};

export default rootSaga;
