import getValue from "../get-value/catalog-item";
import toString from "../to-string/catalog-item";
import sortEmpty from "./sort-empty";

export default {
  ASC: sortEmpty("catalog-item", (a, b, meta) =>
    toString(getValue(a), meta)
      .toLowerCase()
      .localeCompare(toString(getValue(b), meta).toLowerCase())
  ),
  DESC: sortEmpty("catalog-item", (a, b, meta) =>
    toString(getValue(b), meta)
      .toLowerCase()
      .localeCompare(toString(getValue(a), meta).toLowerCase())
  )
};
