import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FormAccountReference extends Component {
  render() {
    if (!this.props.isEditing) {
      return null;
    }

    return (
      <div styleName="container">
        <div styleName="fieldWrapper">
          <i className="material-icons" styleName="icon">
            assignment
          </i>
          <div styleName="wrapper">
            Account Submitter Reference -&nbsp;{this.props.field.name}
          </div>
        </div>
      </div>
    );
  }
}

FormAccountReference.propTypes = {
  isEditing: PropTypes.bool,
  field: PropTypes.object.isRequired
};

export default FormAccountReference;
