import React, { Component } from "react";
import { get } from "lodash";
import * as R from "ramda";
import isValid from "utils/value-types/validations/is-valid";

import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Body from "components/Global/Modal/Layout/ScrollableBody";
import {
  ButtonGroup,
  Submit,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";
import getMetaData from "utils/value-types/get-meta-data";
import resolveRecordName from "components/Event/Module/utils/resolveRecordName";
import { Div } from "components/Base";
import { Loading } from "components/Global/Module/Modals/AddRecord/Layouts/Common";
import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";
import resolveEditor from "components/Global/StandAloneEditors/utils/resolveEditor";
import FormElements from "components/Global/Modal/Layout/FormElements";
const { Label } = FormElements;

class AddRecordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      type: props.onlyRecordTypeId || null,
      values: { ...props.values },
      scene: 0,
      createdRecord: null
    };
  }

  getRecordType = id => {
    return this.props.recordTypes.find(t => t.id === id);
  };

  getRecordTypeName = id => {
    const type = this.props.recordTypes.find(t => t.id === id);
    return type && type.name ? type.name : type;
  };

  validateForm = () => {
    const requiredFields = R.compose(R.filter(R.prop("isRequired")))(
      this.getFieldsToShow()
    );

    if (requiredFields.length) {
      for (const field of requiredFields) {
        const isValidValue = isValid(this.state.values[field.id], field.type);
        if (!isValidValue) {
          return false;
        }
      }
    }

    return true;
  };

  saveFieldValue = (id, val) => {
    // don't save empty values, but do save zero values
    this.setState(state => {
      if (val && (val.value || typeof val.value === "number")) {
        state.values[id] = val;
        return state;
      }
      delete state.values[id];
      return state;
    });
  };

  resetState = valuesToPersist => {
    this.setState(
      state => {
        state.scene = 0;
        state.createdRecord = null;
        Object.keys(state).forEach(id => delete state.values[id]);
        return state;
      },
      () => {
        this.setState({ values: { ...this.props.values, ...valuesToPersist } });
      }
    );
  };

  // eslint-disable-next-line consistent-return
  saveAndKeepOpen = async () => {
    const record = await this.handleSave();
    // call onSave to trigger specified action even though
    // we're keeping modal open. example use case: the caller
    // needs to refetch records when new record is added.
    if (this.props.onSave) {
      return this.props.onSave(record);
    }
  };

  resetAndKeepOpen = async () => {
    // fields to persist
    const fieldIds = R.compose(
      R.map(R.prop("id")),
      R.filter(f => ["lookup"].includes(f.type))
    )(this.props.moduleFields);

    this.resetState(R.pick(fieldIds, this.state.values));
  };

  saveAndClose = async () => {
    const record = await this.handleSave();
    if (this.props.onSave) {
      this.props.onSave(record);
    }
    this.props.hideModal();
  };

  getRowMetaData = ({ row, field, fields }) =>
    getMetaData({
      row,
      rowId: 0,
      field,
      fields,
      orgDetails: this.props.orgDetails,
      orgId: get(this.props.orgDetails, "id"),
      eventDetails: this.props.eventDetails,
      eventId: get(this.props.eventDetails, "id"),
      moduleId: this.props.moduleId,
      references: this.props.references
    });

  getFieldEditor = ({ field, fields }) => {
    const Editor = resolveEditor(field);
    let disabled;

    if (field.settings && field.settings.isReferenceField) {
      const referenceField = this.props.moduleFields.find(
        f => f.id === field.settings.lookupFieldId
      );
      disabled = (
        <Div mt={2} bg="neutral0" p={3} fs={2} bra={1}>
          This field references the record you select for "{referenceField.name}
          "
        </Div>
      );
    }

    return (
      <Div>
        <Label>
          <Div display="row.space-between.center" mb={1}>
            <Div fw={3} color="gray7">
              {field.name}
            </Div>
            {field.isRequired ? (
              <Div color="gray5" fs={1}>
                * required
              </Div>
            ) : null}
          </Div>
        </Label>
        {disabled || (
          <Editor
            rowMetaData={this.getRowMetaData({
              row: this.state.values,
              field,
              fields
            })}
            value={this.state.values[field.id]}
            onChange={val => this.saveFieldValue(field.id, val)}
            {...resolveEditorProps(field, this.props.eventDetails)}
          />
        )}
      </Div>
    );
  };

  onCancel = () => {
    this.props.hideModal();
    if (typeof this.props.onCancel === "function") {
      this.props.onCancel();
    }
  };

  render() {
    const { loading } = this.state;
    const { moduleDetails, onlyRecordTypeId, onlyRecordTypeName } = this.props;

    const recordName = resolveRecordName({
      moduleId: this.props.moduleId,
      plural: false
    }).toLowerCase();

    const fieldsToShow = this.getFieldsToShow();

    return (
      <StyleWrapper
        data-cy="add-record-modal"
        bodyStyles={{ padding: 0 }}
        containerStyles={{ overflowY: "hidden" }}
        heading={
          <Div display="column">
            <span>Add {recordName}</span>
            <span
              style={{
                fontSize: 13,
                color: "rgba(255, 255, 255, 0.8)"
              }}
            >
              {onlyRecordTypeId ? onlyRecordTypeName : moduleDetails.name}
            </span>
          </Div>
        }
        hideModal={this.onCancel}
        width={475}
      >
        <Body style={{ padding: "20px 30px 10px 30px" }}>
          {loading ? <Loading /> : this.renderFields(fieldsToShow)}
        </Body>
        <Div
          display="row.space-between.center"
          style={{
            padding: "0 30px"
          }}
        >
          <ButtonGroup>
            <Submit
              title="Save"
              onClick={this.saveAndClose}
              disabled={!this.validateForm()}
              key="save"
            />
            <ButtonOutline
              key="cancel"
              title="Cancel"
              onClick={this.onCancel}
            />
          </ButtonGroup>
          <Div
            style={{
              color: "#52495b",
              fontSize: 16,
              opacity: 0.49,
              padding: "5px 0",
              textAlign: "center",
              textDecoration: "underline",
              cursor: this.validateForm() ? "pointer" : "not-allowed"
            }}
            onClick={this.validateForm() ? this.saveAndKeepOpen : null}
          >
            Save and add another
          </Div>
        </Div>
      </StyleWrapper>
    );
  }
}

AddRecordModal.defaultProps = {
  values: {}
};

export default AddRecordModal;
