/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  getPortalData: ({ credentials, sessionId }) =>
    new Promise((success, error) => {
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/sessions/portal/${sessionId}`,
        credentials,
        success,
        error
      };
      Helpers.request(options);
    })
};
