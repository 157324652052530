import React from "react";
import DocumentTitle from "components/Global/DocumentTitle";
import CSSModules from "react-css-modules";
import css from "../shared/styles.scss";

const View = CSSModules(
  ({ onSubmit, onFieldChange, email, error, processing }) => (
    <DocumentTitle title="Re-send confirmation message">
      <div styleName="container">
        {/* logo */}
        <div styleName="logoWrapper">
          <img
            src="https://d2dks4tzxs6xee.cloudfront.net/img/Lennd-white-login.png"
            width="154px"
          />
        </div>

        <div styleName="content">
          <div styleName="wrapper">
            <div styleName="left">
              {/* form */}
              <form onSubmit={onSubmit}>
                <div>
                  <div styleName="title">Re-send your confirmation message</div>

                  {/* field: email */}
                  <div styleName="inputWrapper">
                    <input
                      className="styled"
                      type="email"
                      name="email"
                      placeholder="Work email"
                      required
                      onChange={onFieldChange}
                      value={email}
                    />
                  </div>

                  {/* message */}
                  {error && <div styleName="message">{error}</div>}
                </div>

                {/* button */}
                {processing ? (
                  <button styleName="buttonWrapper">
                    <span styleName="button">Sending Confirmation...</span>
                  </button>
                ) : (
                  <button styleName="buttonWrapper" type="submit">
                    <span styleName="button">Send Confirmation Message</span>
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </DocumentTitle>
  ),
  css
);

export default View;
