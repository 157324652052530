import { RECEIVE, RECEIVE_ALL, REQUEST } from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getItemTypesByEvent(eventId) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: REQUEST
      });
      const result = await api.getItemTypesByEvent(
        getState().user.user.credentials,
        eventId
      );
      dispatch({
        type: RECEIVE,
        payload: result.payload
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching item types"
          }
        ])
      );
    }
  };
}

export function getAllItemTypesByEvent(eventId) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: REQUEST
      });
      const result = await api.getItemTypesByEvent(
        getState().user.user.credentials,
        eventId,
        true // passing showAll true so we are give everything
      );
      dispatch({
        type: RECEIVE_ALL,
        payload: result.payload
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching item types"
          }
        ])
      );
    }
  };
}

export function addItemType(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.addItemType(
        getState().user.user.credentials,
        data
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding item type"
          }
        ])
      );
    }
  };
}

export function updateItemType(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.updateItemType(
        getState().user.user.credentials,
        data
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating item type"
          }
        ])
      );
    }
  };
}

export function deleteItemType(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.deleteItemType(
        getState().user.user.credentials,
        data
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting item type"
          }
        ])
      );
    }
  };
}
