import React from "react";
import * as R from "ramda";
import { CheckIcon, Div, Text1 } from "../index";

const DefaultLabel = ({
  isComplete,
  isActive,
  primaryColor,
  secondaryColor,
  title
}) => (
  <Text1
    textAlign="center"
    color={isActive ? primaryColor : isComplete ? undefined : secondaryColor}
    pb={2}
    bold
  >
    {title}
  </Text1>
);
const DefaultStep = ({
  isActive,
  isComplete,
  isIncomplete,
  secondaryColor,
  primaryColor,
  onClick
}) => (
  <Div
    bg={isActive ? "white" : isComplete ? primaryColor : "transparent"}
    shadow={isActive ? 2 : 0}
    display="row.center.center"
    size={25}
    pill
    onClick={isIncomplete ? undefined : onClick}
  >
    {isComplete ? (
      <CheckIcon size={18} color="white" />
    ) : isActive ? (
      <Div size={15} pill bg={primaryColor} />
    ) : (
      <Div size={15} bg={secondaryColor} pill />
    )}
  </Div>
);

const Stepper = ({
  primaryColor = "primary5",
  secondaryColor = "neutral3",
  activeStepIndex = 0,
  Label = DefaultLabel,
  Step = DefaultStep,
  steps,
  onClick,
  ...props
}) => (
  <Div display="row.flex-start.center" {...props}>
    {steps.map((title, idx) => (
      <Div key={idx} width={1 / steps.length}>
        <Label
          isComplete={activeStepIndex > idx}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          isActive={activeStepIndex === idx}
          title={title}
        />
        <Div display="row.center.center" style={{ position: "relative" }}>
          <span style={{ zIndex: 1 }}>
            <Step
              isActive={activeStepIndex === idx}
              isComplete={activeStepIndex > idx}
              isIncomplete={activeStepIndex < idx}
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              onClick={onClick ? () => onClick(idx) : undefined}
            />
          </span>
          <Div
            width={idx === steps.length - 1 || idx === 0 ? 1 / 2 : 1}
            height={3}
            bg={activeStepIndex >= idx ? primaryColor : secondaryColor}
            style={{
              position: "absolute",
              bottom: "calc(50% - 3px)",
              transform: "translateY(-50%)",
              right: idx === steps.length - 1 ? "50%" : 0
            }}
          />
        </Div>
      </Div>
    ))}
  </Div>
);

export { Stepper };
