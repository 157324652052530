import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const FormHeader = ({ children, ...props }) => (
  <div styleName="container" {...props}>
    {children}
  </div>
);

FormHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.node
  ]).isRequired
};

export default CSSModules(FormHeader, css);
