import React from "react";
import { Div, AddIcon } from "components/Base";
import RecordCardList from "components/Global/CRM/RecordCardList";

const SelectRecordsButton = ({ linkedModuleName, onClick, ...props }) => (
  <Div
    onClick={onClick}
    display="inline-flex.flex-start.center"
    bg={{
      default: "gray0",
      hover: "gray1"
    }}
    style={{
      flex: "0 0 auto"
    }}
    px={3}
    py={1}
    bra={1}
    {...props}
  >
    <AddIcon mr={1} color="gray5" size={18} />
    <Div color="gray7" fw={3} fs={2}>
      Link to a record{linkedModuleName ? ` from ${linkedModuleName}}` : null}
    </Div>
  </Div>
);

const View = ({
  showLinkedRecordsModal,
  linkedModuleId,
  linkedFieldId,
  linkedRecordIds,
  linkedModuleName
}) => (
  <Div>
    <SelectRecordsButton
      linkedModuleName={linkedModuleName}
      onClick={showLinkedRecordsModal}
      mb={3}
    />
    <RecordCardList
      {...{
        linkedModuleId,
        linkedFieldId,
        linkedRecordIds
      }}
    />
  </Div>
);

export default View;

/*
  <div>
    <div styleName="wrapper">
      {!disabled ? (
        <div styleName="attach">
          <UploadButton onClick={showFilepicker} />
        </div>
      ) : (
        ''
      )}
      {!records.length ? (
        <div styleName="empty">{get(column, ['settings', 'placeholder']) || placeholder}</div>
      ) : (
        ''
      )}
      {records.map(file => (
        <File
          key={file.id}
          id={file.id}
          allowDownload={allowDownload}
          mimetype={file.mimetype}
          disabled={disabled}
          url={file.url}
          filename={file.filename}
          deleteFile={deleteFile}
        />
      ))}
    </div>
  </div>
    */
