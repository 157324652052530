import * as R from "ramda";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import peopleBlock from "Submission/PeopleBlockTable";
import peopleBlockSagas from "Submission/PeopleBlockTable/sagas";

import {
  getColumns,
  getRows,
  getMetaData,
  getEnabledButtons
} from "Submission/PeopleBlockTable/selectors";

import { withProps } from "utils/General";

import { actions as TableActions } from "ui-kit/Datagrid";
import Table from "ui-kit/Datagrid/View";

peopleBlock.setRootSaga(peopleBlockSagas);

export const contextConfig = {
  module: peopleBlock,
  options: {
    observedDomains: ["user", "event", "organization", "portal", "@flopflip"],
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", peopleBlock.modules))),
      R.prop("type")
    )
  }
};

const getHandlers = R.once(props => ({
  importRows: props.showImportModal
}));

const decorate = R.compose(
  connect(
    (state, props) => ({
      columns: getColumns(state, props),
      rows: getRows(state, props),
      meta: getMetaData(state, props),
      enabledButtons: getEnabledButtons(state, props)
    }),
    bindInstance(
      {
        makeReview: TableActions.makeReviewRequest,
        requestEdit: TableActions.requestEdit
      },
      ["field", "id"]
    )
  ),
  withProps(props => ({
    fieldId: props.field.id,
    instanceId: props.field.id,
    handlers: getHandlers(props)
  }))
);

export default decorate(Table);
