import { createSelector } from "reselect";
import * as R from "ramda";
import { getters } from "./index";

export const getMappedColumns = createSelector(
  getters.columns,
  getters.requiredFields,
  getters.visibleFields,
  getters.fieldOrder,
  (columns, requiredFields, visibleFields, fieldOrder) => {
    const mappedColumns = R.map(
      field => ({
        ...field,
        selected: R.any(fieldId => fieldId === field.id, visibleFields),
        required: R.any(fieldId => fieldId === field.id, requiredFields),
        order: R.propOr(0, field.id, fieldOrder)
      }),
      columns
    );
    return mappedColumns;
  }
);

export const getFilteredColumns = createSelector(
  getMappedColumns,
  getters.filter,
  (columns, filter) => {
    const sortedColumns = R.compose(
      R.sortBy(f => f.name),
      R.filter(R.propEq("draggable", true))
    )(columns);
    if (R.isEmpty(filter)) {
      return sortedColumns;
    }
    return R.filter(
      field => R.contains(R.toLower(filter), R.toLower(field.name)),
      sortedColumns
    );
  }
);

export const getVisibleColumns = createSelector(
  getMappedColumns,
  R.compose(
    R.sortBy(R.prop("order")),
    R.filter(R.propEq("selected", true))
  )
);

export const getNonSortableSelectedFields = createSelector(
  getVisibleColumns,
  R.filter(R.propEq("draggable", false))
);

export const getSortableSelectedFields = createSelector(
  getVisibleColumns,
  R.filter(R.propEq("draggable", true))
);
