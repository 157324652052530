import React from "react";
import { Div } from "components/Base";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import * as R from "ramda";
import { withRouter } from "react-router";
import css from "./styles.scss";
import CSSModules from "react-css-modules";
import moment from "moment";

import {
  getVideosTitle,
  getVideosSubtitle,
  getVideosViewAllLabel
} from "../selectors";

const decorator = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      videoReplays: getters.videoReplays(state, props),
      videosTitle: getVideosTitle(state),
      videosSubtitle: getVideosSubtitle(state),
      videosViewAllLabel: getVideosViewAllLabel(state)
    }),
    {
      showVideoModal: actions.showVideoModal
    }
  )
);

const EmptyListPlaceholder = ({ children }) => (
  <Div display="column.center.center" bg="gray1" height={100} width={1} bra={2}>
    {children}
  </Div>
);

const VideoReplays = ({
  videosTitle,
  videosSubtitle,
  videosViewAllLabel = "view all replays",
  videoReplays,
  showVideoModal,
  router,
  ...otherprops
}) => (
  <Div {...otherprops}>
    <Div display="row.space-between.center">
      <Div
        display="column"
        style={{
          marginBottom: "20px"
        }}
      >
        <Div
          color="#323232"
          style={{
            fontSize: "24px",
            fontFamily: "'Roboto', sans-serif",
            letterSpacing: "-0.26px",
            fontWeight: 500
          }}
        >
          {videosTitle}
        </Div>
        {videosSubtitle ? (
          <Div
            display="row"
            fs={2}
            color="gray5"
            mt={1}
            italics
            style={{
              fontFamily: "'Roboto', sans-serif",
              letterSpacing: "-0.15px"
            }}
          >
            {videosSubtitle}
          </Div>
        ) : null}
      </Div>
      {videoReplays.length ? (
        <Div
          onClick={() => router.push(`${router.location.pathname}/videos`)}
          uppercase
          color={{
            default: "#1789EB",
            hover: "#097adc"
          }}
          style={{
            fontFamily: "'Roboto Condensed', sans-serif",
            fontSize: "14px",
            letterSpacing: "0.4px"
          }}
        >
          {videosViewAllLabel}
        </Div>
      ) : null}
    </Div>
    <Div>
      {R.isEmpty(videoReplays) ? (
        <EmptyListPlaceholder>
          <Div
            mb={1}
            style={{
              color: "#666666",
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: "bold"
            }}
          >
            No Video Replays
          </Div>
          <Div
            fs={2}
            style={{
              color: "#666666",
              fontFamily: "Roboto",
              fontSize: "14px"
            }}
          >
            Video Replays will be added once the broadcast begins.
          </Div>
        </EmptyListPlaceholder>
      ) : (
        R.map(
          video => (
            <Div
              key={video.id}
              display="row.space-between.stretch"
              bra={1}
              bg="white"
              onClick={() => showVideoModal(video)}
              className={css.newsitem}
              style={{
                marginBottom: "8px"
              }}
            >
              <Div>
                <Div
                  brl={1}
                  width={123}
                  height={70}
                  display="row.center.center"
                  style={{
                    backgroundImage: `url(${video.thumbnail_image_url})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    backgroundSize: "cover"
                  }}
                >
                  <img
                    src="https://d2dks4tzxs6xee.cloudfront.net/img/pga/play-ico.svg"
                    alt="Play Icon"
                    width={30}
                    height={30}
                  />
                </Div>
              </Div>
              <Div display="row.space-between.center" width={1} px={6}>
                <Div>
                  <Div
                    uppercase
                    style={{
                      color: "#666666",
                      fontFamily: "'Roboto Condensed', sans-serif",
                      fontSize: "12px",
                      letterSpacing: 0
                    }}
                  >
                    {video.label}
                  </Div>
                  <Div
                    style={{
                      color: "#222222",
                      fontFamily: "'Roboto', sans-serif",
                      fontSize: "16px",
                      fontWeight: "bold",
                      letterSpacing: 0
                    }}
                  >
                    {video.name}
                  </Div>
                </Div>
                <Div
                  uppercase
                  style={{
                    color: "#666666",
                    fontFamily: "'Roboto Condensed', sans-serif",
                    fontSize: "12px",
                    letterSpacing: 0
                  }}
                >
                  {moment(video.date).fromNow()}
                </Div>
              </Div>
            </Div>
          ),
          videoReplays
        )
      )}
    </Div>
  </Div>
);

export default CSSModules(decorator(VideoReplays), css);
