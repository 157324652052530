import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import Tab from "components/Global-2016/Tab";

@CSSModules(css)
class Tabs extends Component {
  render() {
    const { meta, style, tabs, secondaryTabs } = this.props;
    const tabGroup = tabs.map(tab => (
      <Tab
        key={tab.name}
        name={tab.name}
        action={tab.url}
        isActive={meta.pageKey.toLowerCase() === tab.name.toLowerCase()}
        style={tab.style}
        icon={tab.icon}
      />
    ));
    const secondaryTabGroup = secondaryTabs
      ? secondaryTabs.map(tab => (
          <Tab
            key={tab.name}
            name={tab.name}
            action={tab.url}
            isActive={meta.pageKey.toLowerCase() === tab.name.toLowerCase()}
            style={tab.style}
            icon={tab.icon}
          />
        ))
      : [];

    return (
      <div className={`${style} ${css.bar}`}>
        <div styleName="tabsWrapper">
          <div styleName="tabs">{tabGroup}</div>
          <div style={{ flex: 1 }} />
          {secondaryTabGroup.length ? (
            <div styleName="tabs">{secondaryTabGroup}</div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

Tabs.propTypes = {
  meta: PropTypes.object.isRequired,
  style: PropTypes.object,
  tabs: PropTypes.array.isRequired,
  secondaryTabs: PropTypes.array
};

// Example Props
// const tabsProps = {
//   meta: {
//    pageKey: 'Overview'
//   },
//   tabs: [{
//     name: 'Overview',
//     url: `/event/${params.eventId}/contact/${params.contactId}`
//   }, {
//     name: 'Activity',
//     url: `/event/${params.eventId}/contact/${params.contactId}/activity`,
//     icon: (<i className="material-icons" style={{ marginRight: 5, fontSize: 20 }}>history</i>),
//     style: {flexDirection: 'row-reverse'}
//   }],
//   style: styles.tabBg,
// };

export default Tabs;
