import PropTypes from "prop-types";
import React, { Component } from "react";
import { get, first, cloneDeep } from "lodash";
import List from "components/Global/Editors/Common/List";
import QuantityDropdown from "components/Global/Editors/Common/QuantityDropdown";
import QuantityInput from "components/Global/Editors/Common/QuantityInput";
import getValue from "utils/value-types/get-value/catalog-item";
import autobind from "autobind-decorator";

class CatalogItemEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOptions: cloneDeep(this.formatValue(props.value).records || [])
    };
  }

  @autobind
  wrapValue(records) {
    return {
      type: "catalog-item",
      value:
        records && records.length
          ? {
              records: records.map(({ id, quantity }) => ({ id, quantity }))
            }
          : undefined
    };
  }

  formatValue(value) {
    return getValue(value);
  }

  @autobind
  updateQuantity(option, value) {
    let newState = [...this.state.selectedOptions];
    const quantity = this.formatQuantity(value);

    if (this.optionExists(option, newState)) {
      newState = this.updateOption(option, quantity, newState);
    } else {
      newState = this.addOption(option, quantity, newState);
    }

    this.props.onChange(this.wrapValue(newState));
    this.setState({ selectedOptions: newState });
  }

  updateQuantityOnlyInput = (option, value) => {
    const quantity = this.formatQuantity(value);
    const newState = this.addOption(option, quantity, []);

    this.props.onChange(this.wrapValue(newState));
    this.setState({ selectedOptions: newState });
  };

  @autobind
  updateSingleQuantityOption(option, value) {
    const newState = [];
    const quantity = this.formatQuantity(value);

    if (option) {
      newState.push({ ...option, quantity: 1 });
    }

    this.props.onChange(this.wrapValue(newState));
    this.setState({ selectedOptions: newState });
  }

  @autobind
  updateSingleOption(option) {
    const newState = [];
    if (option) {
      newState.push({ ...option, quantity: 1 });
    }
    this.props.onChange(this.wrapValue(newState));
    this.setState({ selectedOptions: newState });
  }

  formatQuantity(quantity) {
    const quantityAsInt = parseInt(quantity, 10);
    return isFinite(quantityAsInt) && quantityAsInt > 0 ? quantityAsInt : 0;
  }

  optionExists(option, options) {
    return !!this.getOption(option, options);
  }

  getOption(option, options) {
    return options.find(cred => cred.id === option.id);
  }

  updateOption(option, quantity, options) {
    if (quantity === 0) {
      return this.removeOption(option, options);
    }
    options[
      options.indexOf(this.getOption(option, options))
    ].quantity = quantity;
    return options;
  }

  addOption(option, quantity, options) {
    if (quantity === 0) {
      return options;
    }
    options.push({ quantity, ...option });
    return options;
  }

  removeOption(option, options) {
    options.splice(options.indexOf(this.getOption(option, options)), 1);
    return options;
  }

  render() {
    const {
      disabled,
      options,
      shouldLimitToAvailableQuantities,
      quantityOptions,
      selectType,
      customWidth,
      className
    } = this.props;

    if (selectType === "quantity-only-input") {
      if (shouldLimitToAvailableQuantities) {
        return (
          <QuantityDropdown
            className={className}
            id={get(first(options), "id")}
            disabled={disabled}
            quantityOptions={quantityOptions}
            selectedOptions={this.state.selectedOptions}
            updateQuantity={this.updateQuantityOnlyInput}
          />
        );
      }

      return (
        <QuantityInput
          className={className}
          id={get(first(options), "id")}
          disabled={disabled}
          selectedOptions={this.state.selectedOptions}
          updateQuantity={this.updateQuantityOnlyInput}
        />
      );
    }

    // Currently 'single', 'single-quantity', 'multiple', 'quantity'
    return (
      <List
        customWidth={customWidth}
        selectType={selectType}
        options={options}
        disabled={disabled}
        emptyMessage="No available items"
        selectedOptions={this.state.selectedOptions}
        shouldLimitToAvailableQuantities={shouldLimitToAvailableQuantities}
        quantityOptions={quantityOptions}
        updateSingleOption={this.updateSingleOption}
        updateSingleQuantityOption={this.updateSingleQuantityOption}
        updateQuantity={this.updateQuantity}
      />
    );
  }
}

CatalogItemEditor.propTypes = {
  className: PropTypes.string,
  selectType: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  quantityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  customWidth: PropTypes.string,
  disabled: PropTypes.bool,
  shouldLimitToAvailableQuantities: PropTypes.bool
};

export default CatalogItemEditor;
