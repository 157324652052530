import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as ReferenceSelectors from "redux/modules/formsV2/references/selectors";
import * as UserSelectors from "redux/modules/user/selectors";
import * as ReferenceValueSelectors from "redux/modules/formsV2/references/values/selectors";
import {
  addReferenceDangerous,
  getReferences
} from "redux/modules/formsV2/references/actions";
import Reference from "./Reference";

const mapStateToProps = state => ({
  isFetching: ReferenceSelectors.isFetching(state),
  references: ReferenceSelectors.references(state),
  values: ReferenceValueSelectors.values(state),
  user: UserSelectors.user(state)
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addReferenceDangerous,
      getReferences
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(Reference);
