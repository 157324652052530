import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import { HEADER_TITLES } from "Organizations/EditEventModal/constants";
import { Div, Text4, CloseIcon } from "components/Base";

import { getters, actions } from "Organizations/EditEventModal";
import { hideModal } from "redux/modules/modal/actions";

import { noop } from "utils/General";

const decorate = connect(
  state => ({
    selectedPage: getters.selectedPage(state)
  }),
  {
    setSelectedPage: actions.setSelectedPage,
    hideModal
  }
);

const Header = ({ hideModal = noop, selectedPage, setSelectedPage }) => (
  <Div
    color="neutral4"
    style={{
      flexShrink: 0
    }}
    bc="neutral2"
    bb={1}
    width={1}
  >
    <Div
      width={1}
      display="row.space-between.center"
      height={60}
      bc="neutral2"
      bb={1}
      px={7}
    >
      <Text4 bold>Edit Event</Text4>
      <CloseIcon size={24} onClick={hideModal} />
    </Div>
    {/*
    <Div display="row.space-between.center" height={40} px={20}>
      {R.map(
        ({ name, page }) => (
          <Div
            key={page}
            color={selectedPage === page ? "black" : "primary7"}
            fw={3}
            height={1}
            bb={selectedPage === page ? 2 : 0}
            bc="primary7"
            display="row.center.center"
            onClick={() => {
              setSelectedPage(page);
            }}
          >
            {name}
          </Div>
        ),
        HEADER_TITLES
      )}
    </Div>
    */}
  </Div>
);

export default decorate(Header);
