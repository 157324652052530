import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import TicketingWrapper from "./TicketingWrapper";
import { withRouter } from "react-router";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { hideModal, showModal } from "redux/modules/modal/actions";
import * as EventActions from "redux/modules/event/actions";
import TicketingProviderActions from "redux/modules/ticketing/providers/actions";
import * as IntegrationActions from "redux/modules/credentials/integrations/actions";
import { getItemGroupsByEventAndType } from "redux/modules/items/item-groups/actions";
import { deleteItemProviderId } from "redux/modules/items/items/actions";

import * as IntegrationSelectors from "redux/modules/credentials/integrations/selectors";
import * as TicketingProviderSelectors from "redux/modules/ticketing/providers/selectors";
import * as UserSelectors from "redux/modules/user/selectors";

function mapStateToProps(state) {
  return {
    user: UserSelectors.user(state),
    isConfiguringProvider: TicketingProviderSelectors.isConfiguringProvider(
      state
    ),
    isRemovingProvider: TicketingProviderSelectors.isRemovingProvider(state),
    providerEvents: TicketingProviderSelectors.providerEvents(state),
    ticketTypes: TicketingProviderSelectors.ticketTypes(state),
    integrations: IntegrationSelectors.integrations(state),
    providerConfigurations: TicketingProviderSelectors.ticketingProviders(
      state
    ),
    ticketingProviderConfigurations: TicketingProviderSelectors.ticketingProviderConfigurations(
      state
    )
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSnackbar,
      showModal,
      hideModal,
      getItemGroupsByEventAndType,
      ...IntegrationActions,
      fetchDetails: EventActions.getEvent,
      getTicketingProviderConfigurations:
        TicketingProviderActions.getTicketingProviderConfigurations,
      configureProviderForEvent:
        TicketingProviderActions.configureProviderForEvent,
      getTicketTypes: TicketingProviderActions.getTicketTypes,
      removeProviderFromEvent: TicketingProviderActions.removeProviderFromEvent,
      syncTicketTypes: TicketingProviderActions.syncTicketTypes,
      getAuth1stStep: TicketingProviderActions.getAuth1stStep,
      deleteItemProviderId
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TicketingWrapper)
);
