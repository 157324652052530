import React, { useEffect } from "react";
import { bindInstance } from "redux-mvc";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { connect } from "react-redux";
import { noop, displayTimezone } from "utils/General";

import { actions, getters } from "ui-kit/Tabs";

import { DarkTab, Div, MediumTextButton } from "components/Base";

const decorate = connect(
  (state, props) => ({
    selectedTab: getters.selectedTab(state, props)
  }),
  bindInstance({
    onSelectTab: actions.setSelectedTab,
    setIniSelected: actions.setIniSelected
  })
);

const Tabs = ({
  tabs,
  searchTerm = "",
  onClearSearch = noop,
  selectedTab = "",
  iniSelected = "",
  onSelectTab = noop,
  setIniSelected = noop,
  ...styleProps
}) => {
  useEffect(() => {
    setIniSelected({ iniSelected, tabs });
  }, []);
  return (
    <Div display="row.space-between">
      <Div display="row.flex-start.center" {...styleProps}>
        {searchTerm.length
          ? [
              <DarkTab mr={4} px={3} active activeColor="neutral8">
                Results for '{searchTerm}'
              </DarkTab>,
              <MediumTextButton
                color="primary5"
                onClick={() => onClearSearch()}
              >
                CLEAR SEARCH
              </MediumTextButton>
            ]
          : tabs.map(({ id, title, render, style = {} }, index) =>
              typeof render === "function" ? (
                render({
                  className: id === selectedTab ? css.activeTab : css.tab,
                  onClick: id === selectedTab ? noop : () => onSelectTab(id),
                  title,
                  id,
                  selected: id === selectedTab
                })
              ) : (
                <Div
                  className={id === selectedTab ? css.activeTab : css.tab}
                  key={id}
                  fw={4}
                  onClick={id === selectedTab ? noop : () => onSelectTab(id)}
                  mr={tabs.length - 1 === index ? 0 : 5}
                  style={{
                    fontSize: "30px",
                    ...style
                  }}
                >
                  {title}
                </Div>
              )
            )}
      </Div>
      <Div>{displayTimezone()}</Div>
    </Div>
  );
};

export default CSSModules(decorate(Tabs), css);
