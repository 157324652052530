import CustomTabButton from "Portal/PortalPeople/View/CustomTabButton";

export const NAMESPACE = "NewPortalPeople";

export const MAX_LIMIT_OF_CHARACTERS = 500;

export const FILTER = {
  THIS_EVENT: "this_event",
  SUMMARY: "summary",
  PREVIOUS_EVENTS: "previous_events"
};

export const TABS = [
  { id: FILTER.THIS_EVENT, title: "People" },
  // { id: FILTER.SUMMARY, title: "Summary" },
  {
    id: FILTER.PREVIOUS_EVENTS,
    title: "Add from another event",
    render: CustomTabButton
  }
];

export const PEOPLE_FILTERS = {
  ALL: "all",
  BY_EVENT: "by_event"
};

export const COLUMN_WIDTHS = {
  name: 150,
  email: 170,
  "event-list": 140
};

export const CONTACTS_TABLE_INSTANCE_ID = "CONTACTS_TABLE_INSTANCE_ID";

export const ROW_ACTIONS_ITEMS = {
  ADD_TO_EVENT: "row-addToEvent"
};

export const COLUMN_HEADERS = [
  {
    id: "name",
    name: "Name",
    type: "text",
    settings: {}
  },
  {
    id: "email",
    name: "Email",
    type: "text",
    settings: {}
  },
  {
    id: "event-list",
    name: "Other Events",
    type: "event-list",
    settings: {}
  },
  {
    id: "actions",
    name: "Actions",
    type: "portal-people-actions",
    settings: {}
  }
];
