import React, { Component } from "react";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import { ROW_ACTIONS } from "Modules/Dashboard/constants";

import StatusCircle from "ui-kit/StatusCircle";
import Tooltip from "components/Global/Tooltip";
import { LoadingIcon } from "components/Base";

import { getValue } from "../../utils";
import { actions } from "../../model";

const decorate = connect(
  null,
  bindInstance({
    showModal,
    hideModal,
    execute: actions.executeAction
  })
);

class SyncedToFuzion extends Component {
  state = {
    attempting: false
  };

  attemptSync = () => {
    this.setState({
      attempting: true
    });

    this.props.execute({
      actionId: ROW_ACTIONS.SYNC_TO_FUZION,
      row: this.props.data
    });

    setTimeout(() => {
      this.setState({
        attempting: false
      });
    }, 3000);
  };

  render() {
    const synced = getValue(this.props);
    const { attempting } = this.state;

    return (
      <div
        onClick={attempting ? undefined : this.attemptSync}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: "100%",
          padding: 8,
          borderRadius: 4,
          cursor: "pointer"
        }}
      >
        {attempting ? (
          <LoadingIcon size={20} />
        ) : (
          <Tooltip
            tooltip={
              synced
                ? "Synced · Click to resync"
                : "Not Synced · Click to attempt sync"
            }
            placement="bottom"
          >
            <span>
              <StatusCircle status={synced ? "approved" : "not-submitted"} />
            </span>
          </Tooltip>
        )}
      </div>
    );
  }
}

export default decorate(SyncedToFuzion);
