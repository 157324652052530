import { createModule, createHandlers } from "redux-mvc";

const iniState = {
  activeFilterId: ""
};

const handlers = createHandlers({ iniState, namespace: "UIFilters" });

const { actions, getters } = handlers;

const module = createModule(handlers);

export { actions, getters };

export default module;
