import React from "react";
import {
  Div,
  HalfSizeDotIcon,
  NumberSelector,
  RightIcon,
  makeTable,
  SmallOutlineButton,
  AddIcon
} from "components/Base";
import { addS, withProps } from "utils/General";
import Counter from "Orders/ItemPickers/Counter";

import { connect } from "react-redux";
import { formatAmountForEvent } from "redux/modules/event/selectors";

const decorate = connect(state => ({
  formatAmountForEvent: formatAmountForEvent(state)
}));

const Wrapper = ({ isValid, children }) => (
  <div data-section-error={!isValid}>{children}</div>
);

const Errors = ({ isValid, messages, addItemsToCart }) => {
  const hasMultipleErrors = messages.length > 1;
  return !isValid && messages.length ? (
    <ul
      style={{
        background: "#FFE9E9",
        borderRadius: 4,
        padding: 20,
        position: "relative",
        marginTop: 20,
        ...(hasMultipleErrors
          ? {
              listStyleType: "disc",
              marginLeft: 16
            }
          : {})
      }}
    >
      <i
        className="material-icons"
        style={{
          position: "absolute",
          right: "-9px",
          top: "-9px",
          fontSize: 20,
          color: "#ea4358"
        }}
      >
        error
      </i>
      {messages.map((Message, idx) => (
        <li
          key={idx}
          style={{
            color: "#ea4358",
            fontSize: 14,
            fontWeight: 600,
            marginLeft: hasMultipleErrors ? 16 : 0
          }}
        >
          <Message addItemsToCart={addItemsToCart} />
        </li>
      ))}
    </ul>
  ) : null;
};

const AddItemsButton = ({ totalQuantity, addItemsToCart }) => (
  <Div
    display="row.center.center"
    fs={3}
    onClick={addItemsToCart}
    bg={{ default: "primary7", hover: "primary8" }}
    p={3}
    bra={1}
    width={1}
    color="white"
    fw={3}
    mt={3}
  >
    <Div>
      Add {totalQuantity} item
      {addS(totalQuantity)}
    </Div>
    <RightIcon size={20} ml={2} color="white" />
  </Div>
);

const EventDays = ({ eventDateGroups, datesPosition }) => (
  <Div display={`${datesPosition === "top" ? "row" : "column"}`}>
    {eventDateGroups.map(dateGroup => (
      <Div
        key={dateGroup.id}
        display="row.flex-start.center"
        mr={datesPosition === "top" ? 4 : 0}
      >
        <Div
          style={{
            backgroundColor:
              dateGroup.color === "#ffffff" ? "#ccc" : dateGroup.color
          }}
          width={5}
          height={12}
          mr={2}
        />
        <Div fw={3}>{dateGroup.name}</Div>
      </Div>
    ))}
  </Div>
);

const RowUndecorated = ({
  name,
  subname,
  color,
  price,
  quantity,
  updateVariantQuantity,
  max,
  itemMax,
  addToCart,
  disabled,
  mode,
  showItemModal,
  hideAddButton,
  formatAmountForEvent
}) => {
  const meta = [];

  if (subname) {
    meta.push(subname);
  }
  if (itemMax !== undefined) {
    meta.push(`${itemMax} remaining`);
  }

  return (
    <Div
      display="row.flex-start.center"
      bg="white"
      b={1}
      bc="gray2"
      bra={1}
      pl={3}
      mb={2}
    >
      <HalfSizeDotIcon
        color="neutral5"
        mr={2}
        style={{
          color: color || "#ccc",
          flexShrink: 0
        }}
      />
      <Div display="row.space-between.center" flex={1}>
        <Div>
          <Div
            fs={3}
            fw={3}
            mt={2}
            pb={1}
            pr={4}
            style={{
              lineHeight: "18px"
            }}
          >
            {name}
          </Div>
          {meta.length ? (
            <Div fs={1} mb={2} color="neutral7">
              {meta.join(" · ")}
            </Div>
          ) : null}
        </Div>
        <Div flex={1} />
        <Div display="row.flex-start.center">
          {price ? (
            <Div mr={4} fw={3}>
              {formatAmountForEvent(price)}
            </Div>
          ) : null}
          <Div pr={quantity && !disabled && !hideAddButton ? 0 : 3}>
            {mode === "single" ? (
              <NumberSelector
                disabled={disabled}
                value={quantity}
                onChangeValue={updateVariantQuantity}
                maxValue={itemMax !== undefined ? itemMax : max}
              />
            ) : (
              <SmallOutlineButton
                type="button"
                disabled={disabled}
                width={80}
                OnlyIcon={AddIcon}
                onClick={showItemModal}
                color={disabled ? "gray6" : "primary8"}
                bg="white"
              />
            )}
          </Div>
        </Div>
      </Div>
      {!hideAddButton && quantity && !disabled ? (
        <Div
          display="column.center.center"
          onClick={addToCart}
          bg={{ default: "primary7", hover: "primary8" }}
          color="white"
          ml={2}
          fw={3}
          fs={1}
          px={2}
          brr={1}
          style={{
            height: "100%",
            flexShrink: 0
          }}
        >
          Add
        </Div>
      ) : null}
    </Div>
  );
};

const Row = decorate(RowUndecorated);

const Rows = ({ disabled, items }) => (
  <Div>
    {items.map(item => (
      <Row key={item.id} disabled={disabled} {...item} />
    ))}
  </Div>
);

const Name = ({ children, ...props }) => (
  <Div mb={1} style={{ color: "#454447", fontSize: 27 }} {...props}>
    {children}
  </Div>
);

const Description = ({ children }) => (
  <Div
    mb={3}
    style={{ color: "#454447", fontSize: 14, whiteSpace: "pre-wrap" }}
  >
    {children}
  </Div>
);

const Limit = ({ remaining, limit }) => (
  <Div mb={3}>
    {remaining}/{limit} remaining
  </Div>
);

const headerCell = (text, color, small) => () => (
  <Div bg={color} p={small ? 1 : 3}>
    <Div fw={3} fs={small ? 1 : 4} textAlign="center">
      {text}
    </Div>
  </Div>
);

const HeaderStyler = withProps({
  display: "row.flex-start.flex-end"
})(Div);

const RowStyler = withProps({
  display: "row.flex-start.center"
})(Div);

const CateringTable = ({
  totalQuantity,
  cateringColumns,
  cateringRows,
  eventDateGroups
}) => {
  const countOfColumns = cateringColumns.length + 1;
  const columnProps = [
    { width: 0.75 / countOfColumns },
    ...cateringColumns.map(() => ({
      width: 1 / countOfColumns
    }))
  ];
  const headerCellComps = [
    headerCell(""),
    ...cateringColumns.map(c => headerCell(c.name, c.color, c.small))
  ];
  const rowCellComps = [
    ({ dayColor, dayName, dayNumber, small }) => (
      <Div
        display="column.center.flex-start"
        pr={4}
        pl={2}
        bl={3}
        style={{ borderColor: dayColor }}
      >
        <Div fs={1} fw={4} color="gray6" uppercase>
          {dayName}
        </Div>
        <Div color="gray6" fw={2} fs={small ? 4 : 6}>
          {dayNumber}
        </Div>
      </Div>
    ),
    ...cateringColumns.map(c => props => {
      const variant = props[c.id];
      return (
        <Div
          p={c.small ? 1 : 3}
          bg={variant.bg}
          textAlign="center"
          style={{
            minHeight: 59
          }}
        >
          {variant.id ? (
            <Counter
              {...{
                small: c.small,
                inputOnly: variant.inputOnly,
                toggleOnly: variant.toggleOnly,
                isToggled: variant.selected,
                onToggle: variant.onToggle,
                quantity: variant.quantity,
                incrementQuantity: variant.incrementQuantity,
                decrementQuantity: variant.decrementQuantity,
                updateVariantQuantity: variant.updateVariantQuantity,
                disabled: variant.disabled,
                totalQuantity
              }}
            />
          ) : null}
        </Div>
      );
    })
  ];

  const Table = makeTable({
    TableStyler: Div,
    HeaderStyler,
    RowStyler,
    HeaderCellStyler: Div,
    RowCellStyler: Div,
    columnProps,
    headerCellComps,
    rowCellComps
  });

  return (
    <Div>
      <Div mb={3}>
        <EventDays eventDateGroups={eventDateGroups} datesPosition="top" />
      </Div>
      <Table rowsData={cateringRows} />
    </Div>
  );
};

// Views
const ListView = ({
  name,
  description,
  items,
  limit = {},
  totalQuantity,
  addItemsToCart,
  disabled,
  isValid,
  messages
}) => (
  <Wrapper isValid={isValid}>
    {name ? <Name mb={description ? 1 : 3}>{name}</Name> : null}
    {description ? <Description>{description}</Description> : null}
    {limit ? <Limit {...limit} /> : null}
    <Rows disabled={disabled} items={items} />
    {totalQuantity ? (
      <AddItemsButton
        totalQuantity={totalQuantity}
        addItemsToCart={addItemsToCart}
      />
    ) : null}
    <Errors
      isValid={isValid}
      messages={messages}
      addItemsToCart={addItemsToCart}
    />
  </Wrapper>
);

const CateringView = ({
  name,
  description,
  limit = {},
  totalQuantity,
  addItemsToCart,
  cateringColumns,
  cateringRows,
  eventDateGroups,
  isValid,
  messages
}) => (
  <Wrapper isValid={isValid}>
    {name ? <Name mb={description ? 1 : 3}>{name}</Name> : null}
    {description ? <Description>{description}</Description> : null}
    {limit ? <Limit {...limit} /> : null}
    <CateringTable
      cateringColumns={cateringColumns}
      cateringRows={cateringRows}
      totalQuantity={totalQuantity}
      eventDateGroups={eventDateGroups}
    />
    {totalQuantity ? (
      <AddItemsButton
        totalQuantity={totalQuantity}
        addItemsToCart={addItemsToCart}
      />
    ) : null}
    <Errors
      isValid={isValid}
      messages={messages}
      addItemsToCart={addItemsToCart}
    />
  </Wrapper>
);

export { ListView, CateringView, Limit, Rows };
