import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import { BigOutlineButton, BigFilledButton } from "components/Base";
import { noop } from "utils/General";
import * as FormSelectors from "redux/modules/formsV2/form/selectors";
import { Div, DragIcon, Cards } from "components/Base";

const decorate = R.compose(
  connect(
    state => ({
      formFields: FormSelectors.fields(state)
    }),
    {}
  ),
  CSSModules(css)
);

const Field = ({ name, settings }) => (
  <Div
    display="row.flex-start.center"
    p={2}
    mb={2}
    bra={1}
    b={1}
    bc="neutral2"
    bg="white"
  >
    <DragIcon size={20} mr={3} style={{ cursor: "move" }} />

    <Div fw={3} fs={2} color="gray9" truncate>
      {name ?? (settings.description || settings.title)}
    </Div>
  </Div>
);

const OrderFields = Cards(Field, "FORM_ORDER_FIELDS");

const ReorderFormFieldsModal = ({
  hideReorderFormFieldsModal = noop,
  formFields = [],
  onReorderFormFields = noop,
  isSaving = false,
  saveOrder = noop
}) => {
  return (
    <MiniModalWrapper
      hideModal={hideReorderFormFieldsModal}
      title={"Reorder Fields"}
      display="column.flex-start.stretch"
      width={600}
      height={450}
      showModal
      stopPropagation={true}
    >
      <div styleName="simple-form-modal">
        <div styleName="body">
          <OrderFields
            cards={formFields}
            onReorder={onReorderFormFields}
          ></OrderFields>
        </div>
        <div styleName="footer">
          <BigFilledButton
            bg="altB5"
            onClick={() => {
              saveOrder();
            }}
          >
            {isSaving ? "Saving…" : "Save"}
          </BigFilledButton>
          <BigOutlineButton
            ml={2}
            onClick={() => {
              hideReorderFormFieldsModal();
            }}
          >
            Cancel
          </BigOutlineButton>
        </div>
      </div>
    </MiniModalWrapper>
  );
};
export default decorate(ReorderFormFieldsModal);
