import Helpers from "utils/Global/Helpers";

/* eslint no-underscore-dangle: "off" */

export default {
  search: (credentials, orgId, eventId, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: orgId
          ? `${window.__LENND_API_BASE_URL__}/files/files/organization/${orgId}`
          : `${window.__LENND_API_BASE_URL__}/files/files/event/${eventId}`,
        qs: data,
        credentials,
        success,
        error
      });
    }),
  export: ({ credentials, orgId, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: orgId
          ? `${window.__LENND_API_BASE_URL__}/files/files/organization/${orgId}/export`
          : `${window.__LENND_API_BASE_URL__}/files/files/event/${eventId}/export`,
        qs: data,
        credentials,
        success,
        error
      });
    }),

  deleteFiles: ({ credentials, data }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */

      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/files/files`,
        credentials,
        data,
        success,
        error
      });
    }),

  addFile: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/files/files`,
        credentials,
        data,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
