import { connect } from "react-redux";
import Controller from "./Controller";
import { selectFeatureFlag } from "@flopflip/react-redux";
import { withRouter } from "react-router";

import { CAN_VIEW_PAYMENTS } from "utils/feature-flags";

function mapStateToProps(state) {
  return {
    canViewPayments: selectFeatureFlag(CAN_VIEW_PAYMENTS.NAME)(state)
  };
}

export default withRouter(connect(mapStateToProps)(Controller));
