import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import { noop } from "utils/General";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";

import { getters, actions } from "Items/Manage";
import StatsBar from "./StatsBar";

import { Div } from "components/Base";

const decorate = connect(
  state => ({
    showModal: getters.showStatsModal(state)
  }),
  {
    hideModal: () => actions.setShowStatsModal(false)
  }
);

const StatsModal = ({ showModal = false, hideModal = noop }) => (
  <MiniModalWrapper
    showModal={showModal}
    hideModal={hideModal}
    title="Quick Summary"
    height={180}
  >
    <Div
      p={4}
      bg="white"
      brb={2}
      width={1}
      height={1}
      style={{
        overflowY: "auto"
      }}
    >
      <StatsBar />
    </Div>
  </MiniModalWrapper>
);

export default decorate(StatsModal);
