import PropTypes from "prop-types";
import React, { Component } from "react";
import moment from "moment";

import { ActionLink } from "./Actions";
import { Incomplete } from "./Badges";
import Body from "./Body";
import Container from "./Container";
import Footer from "./Footer";
import Menu from "./Menu";
import TimeAgo from "./TimeAgo";
import Locked from "./Locked";
import Messages from "./Messages";

class PortalSubmission extends Component {
  showSubmissionSummary = () =>
    this.props.showSubmissionSummary({
      id: this.props.submission.id,
      form_id: this.props.form.id
    });

  deleteSubmission = () =>
    this.props.deleteSubmission(this.props.form.id, this.props.submission.id);

  getContext = (submission, form) => {
    const context = {
      verb: "Last updated",
      badges: <Incomplete />,
      actions: [
        <ActionLink to={`${this.props.formPath}/${form.id}`}>
          Continue
          <i className="material-icons">keyboard_arrow_right</i>
        </ActionLink>
      ],
      timeAgo: submission.updated_at
    };

    return context;
  };

  render() {
    const { form, submission } = this.props;

    const context = this.getContext(submission, form);

    return (
      <Container>
        <div style={{ position: "relative" }}>
          <Locked locked={submission.is_locked} />
          <TimeAgo>
            {context.verb}&nbsp;{moment(context.timeAgo).fromNow()}
          </TimeAgo>
          {context.badges}
        </div>
        <Body {...this.props} />
        <Footer>
          <Messages
            countOfMessages={submission.count_of_messages}
            countOfUnreadMessages={submission.count_of_unread_messages}
            onClick={this.showSubmissionSummary}
          />
          {context.actions}
          {context.menuItems && context.menuItems.filter(i => i).length ? (
            <Menu>{context.menuItems}</Menu>
          ) : (
            ""
          )}
        </Footer>
      </Container>
    );
  }
}

PortalSubmission.propTypes = {
  deleteSubmission: PropTypes.func.isRequired,
  form: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  formPath: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  showSubmissionSummary: PropTypes.func.isRequired,
  submission: PropTypes.shape({
    comment_count: PropTypes.number,
    id: PropTypes.string,
    is_locked: PropTypes.bool,
    updated_at: PropTypes.string
  }).isRequired
};

export default PortalSubmission;
