import getValue from "utils/value-types/get-value";
import valueToString from "utils/value-types/to-string";

export default function constructSearchObject(
  fields,
  values,
  id,
  eventDetails,
  meta
) {
  return fields.reduce(
    (searchObject, field) => {
      searchObject[field.id] = valueToString(
        getValue(values[field.id], field.type),
        field.type,
        eventDetails,
        {
          ...field.settings,
          ...meta
        }
      );

      return searchObject;
    },
    { id }
  );
}
