import React, { PureComponent } from "react";
import * as R from "ramda";
import moment from "moment";
import CustomerInvoiceView from "./View";
import PaymentModal from "Orders/PaymentModal";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import numeral from "numeral";

const noop = () => {};
const formatPrice = price => numeral(price).format("$0,0.00");

export default class CustomerInvoiceController extends PureComponent {
  state = {
    loading: true
  };

  async componentDidMount() {
    await this.props.getInvoice(this.props.params.invoiceId);

    this.setState({
      loading: false
    });
  }

  showPaymentModal = chargeAmount => {
    this.props.showModal({
      content: (
        <PaymentModal
          amount={chargeAmount}
          transactionParams={{
            orderId: this.props.invoice.id,
            amount: chargeAmount,
            method: "stripe",
            source: "invoice",
            sendConfirmation: true,
            requireShippingInformation: false
          }}
          onDone={() => {
            this.props.getInvoice(this.props.params.invoiceId);
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  onPrint = () => {
    window.print();
  };

  goToHome = () => {
    window.open("https://www.lennd.com/", "_blank");
  };

  render() {
    const { loading } = this.state;
    const { invoice } = this.props;

    let props = {};
    if (!loading && invoice.id) {
      const lastTransactionAt = invoice.transactions.length
        ? R.compose(
            R.prop("created_at"),
            R.head,
            R.prop("transactions")
          )(invoice)
        : null;

      props = {
        number: invoice.number,
        orderNumber: `#${invoice.number}`,
        paymentsEnabled: invoice.payments_enabled,

        invoiceDate: invoice.invoice
          ? moment(new Date(invoice.invoice.created_at)).format("M/D/YYYY")
          : null,
        dueDate: invoice.invoice_due_date
          ? moment(new Date(invoice.invoice_due_date)).format("M/D/YYYY")
          : null,

        isPaid: invoice.is_paid,
        lastTransactionAt: lastTransactionAt
          ? moment(lastTransactionAt).format("M/DD/YYYY [at] h:mma")
          : null,

        backgroundUrl: this.props.invoice.event.background_image_url,
        logoUrl: this.props.invoice.event.logo_image_url,
        due: invoice.total_due,
        dueRaw: invoice.total_due_raw,
        total: invoice.total,
        invoiceStatusDueDate: invoice.is_paid
          ? "Paid"
          : `Unpaid${
              invoice.invoice_due_date
                ? ` - Due ${moment(
                    new Date(invoice.invoice_due_date)
                  ).fromNow()}`
                : ""
            }`,
        onPay: () => this.showPaymentModal(invoice.total_due_raw),
        onDownload: noop,
        onPrint: this.onPrint,
        title: invoice.event.name,
        currency: invoice.event.currency,

        addressStreet: "2035 Financial Way",
        addressCity: "Glendora",
        addressState: "CA",
        addressZip: "91741",
        phone: "626-914-4761",

        customerAccountName: invoice.customer.account_name,
        customerContactName: [
          invoice.customer.first_name,
          invoice.customer.last_name
        ]
          .filter(v => v && v.length)
          .join(" "),
        customerAddressStreet: [
          invoice.shipping_address_line_1,
          invoice.shipping_address_line_2
        ]
          .filter(v => v && v.length)
          .join(" "),
        customerAddressCity: invoice.shipping_city,
        customerAddressState: invoice.shipping_state,
        customerAddressZip: invoice.shipping_zip,
        customerAddressLocation: [
          invoice.shipping_city,
          invoice.shipping_state,
          invoice.shipping_zip
        ]
          .filter(v => v && v.length)
          .join(" "),

        totalPaymentsApplied: invoice.total_payments_applied,

        lineItems: R.map(line_item => ({
          quantity: line_item.quantity,
          item: {
            title: line_item.name,
            caption: line_item.subname
          },
          unitPrice: line_item.price ? formatPrice(line_item.price) : "-",
          totalPrice: line_item.total ? formatPrice(line_item.total) : "-"
        }))(invoice.line_items),

        terms: invoice.invoice_notes,
        transactions: invoice.transactions || [],
        goToHome: this.goToHome
      };
    }

    return (
      <CustomerInvoiceView
        {...{
          loading,
          ...props
        }}
      />
    );
  }
}
