import PropTypes from "prop-types";
import React, { Component } from "react";
import getValue from "utils/value-types/get-value/number";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
export default class NumberEditor extends Component {
  formatValue(value) {
    return getValue(value);
  }

  wrapValue(value) {
    return {
      type: "number",
      value
    };
  }

  @autobind
  onChange(e) {
    this.props.onChange(this.wrapValue(e.target.value));
  }

  render() {
    return (
      <input
        {...this.props}
        type="number"
        className={["form-control", css.input, this.props.className].join(" ")}
        onChange={this.onChange}
        value={this.formatValue(this.props.value)}
      />
    );
  }
}

NumberEditor.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.object
};
