import React from "react";
import {
  put,
  select,
  call,
  takeEvery,
  all,
  fork,
  take,
  delay
} from "redux-saga/effects";
import * as R from "ramda";
import { defaultInstanceId } from "redux-mvc";
import { showSnackbar } from "redux/modules/snackbar/actions";

import { actions as ApprovalsActions } from "ApproversLabel";
import { actions as TableActions } from "ui-kit/Table/model";
import { actions as ViewPickerActions } from "ui-kit/ViewPicker";
import { actions as FilterControlsActions } from "ui-kit/FilterControls";

import { eventId as getEventId } from "redux/modules/event/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";
import { eventId as getPortalEventId } from "redux/modules/portal/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { registerError } from "redux/modules/errors/actions";
import { showModal } from "redux/modules/modal/actions";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import ImportOrdersModal from "Orders/ImportModal/View";

import { actions, getters } from "EventLight/Common/Manage"; // circular reference otherwise
import {
  getCurrentPage,
  getPageSize,
  getGroupBy,
  getIsMyApprovals
} from "EventLight/Common/Manage/selectors";

import {
  actions as QuestionsActions,
  getters as QuestionsGetters
} from "Items/QuestionsDetails";

import {
  TABLE_INSTANCE_ID,
  TABLE_VIEW_ID,
  VIEWPICKER_INSTANCE_ID,
  SUMMARIES,
  MODES
} from "EventLight/Common/Manage/constants";
import api from "EventLight/Common/Manage/api";

import {
  CREATED_VIA_PORTAL,
  CREATED_VIA_GLM,
  ATTENDEE_FILTER_ID,
  GROUP_FILTER_ID,
  GROUP_BY_FILTER_ID,
  SHOW_MEALS_FILTER_ID
} from "Passes/Common/constants";

import {
  actions as SearchBarActions,
  getters as SearchBarGetters
} from "ui-kit/SearchBar";
import { getters as FilterControlsGetters } from "ui-kit/FilterControls";
import * as FilterControlsSelectors from "ui-kit/FilterControls/selectors";

import {
  MEAL_TYPE_ID,
  BOOTH_TYPE_ID,
  SPONSORSHIP_TYPE_ID
} from "utils/item-types";

const appendOrderId = orderId => data => {
  if (R.isEmpty(orderId) || R.isNil(orderId)) {
    return data;
  }

  return {
    ...data,
    parentOrderId: orderId
  };
};

export const loading = function*(scrollTop = true, action = {}) {
  yield put(actions.setLoading(true, { meta: action.meta }));
  if (scrollTop) {
    const elm = yield call([document, document.getElementById], TABLE_VIEW_ID);
    if (elm) {
      elm.scrollTop = 0;
    }

    const elms = yield call(
      [document, document.getElementsByClassName],
      TABLE_VIEW_ID
    );
    Array.prototype.forEach.call(elms, elm => {
      elm.scrollTop = 0;
    });
  }
  yield take(
    act =>
      act.type === actions.receiveList.type &&
      R.path(["meta", "instanceId"], act) ===
        R.path(["meta", "instanceId"], action)
  );
  yield put(actions.setLoading(false, { meta: action.meta }));
};

const notResetPagination = [
  actions.setMode.type,
  actions.setCurrentPage.type,
  actions.fetchData.type,
  ApprovalsActions.reviewDone.type
];

const shouldUseSilentRefresh = [ApprovalsActions.reviewDone.type];

export const getItemTypes = (state, props) => {
  const itemTypeIdBasedOnView = getters.itemTypeView(state, props);
  const itemTypeIds = [itemTypeIdBasedOnView];

  // @NOTE: If viewing "Booths", include "Sponsor" type as well
  if (itemTypeIdBasedOnView === BOOTH_TYPE_ID) {
    itemTypeIds.push(SPONSORSHIP_TYPE_ID);
  }

  if (
    FilterControlsSelectors.getOptions(state, {
      instanceId: SHOW_MEALS_FILTER_ID
    })
  ) {
    return R.concat(itemTypeIds, [MEAL_TYPE_ID]);
  }
  return itemTypeIds;
};

const appendStatus = ({ status, searchTerm, mode }) => data => {
  // @NOTE: If in issuance mode, still append approval status to query when searching
  if (![MODES.ISSUANCE, MODES.PRINT].includes(mode) && searchTerm !== "") {
    return data;
  }

  return {
    ...data,
    status
  };
};

const appendSummary = ({ id, type }) => data => {
  if (id === "") {
    return data;
  }

  const key = R.prop(type, {
    [SUMMARIES.ACCOUNT]: "customerAccountId",
    [SUMMARIES.CATEGORY]: "itemGroupIds",
    [SUMMARIES.ITEM_TYPE]: "itemIds"
  });

  return {
    ...data,
    [key]: type === SUMMARIES.ACCOUNT ? id : [id]
  };
};

const getSearchParams = (state, props, actionType) => {
  const appendDynamically = R.compose(
    appendStatus({
      status: getters.selectedTab(state, props),
      searchTerm: SearchBarGetters.searchTerm(state, props),
      mode: getters.mode(state, props)
    }),
    appendOrderId(getters.orderId(state, props)),
    appendSummary(getters.summary(state, props))
  );

  const resetPagination = R.not(
    R.any(R.equals(actionType), notResetPagination)
  );

  const fields = R.prop("filters", getters.selectedFieldFilters(state, props));

  const preferences = getters.preferences(state, props);
  const sourceIds = getters.selectedSources(state, props);
  const userIds = getters.selectedRequester(state, props);
  const passTypeIds = getters.selectedPasses(state, props);
  const mealIds = getters.selectedMeals(state, props);
  const inventoryIds = getters.selectedInventoryItems(state, props);
  const boothIds = getters.selectedBoothItems(state, props);
  const sponsorshipIds = getters.selectedSponsorshipItems(state, props);
  const variantIds = getters.variantIds(state, props);

  return appendDynamically({
    preferences: JSON.stringify(preferences),
    accountRecordTypes: R.filter(
      R.length,
      R.append(
        getters.groupId(state, props),
        R.keys(
          FilterControlsGetters.selectedOptions(state, {
            instanceId: GROUP_FILTER_ID
          })
        )
      )
    ),
    contactRecordTypes: R.filter(
      R.length,
      R.append(
        getters.contactTypeId(state, props),
        R.keys(
          FilterControlsGetters.selectedOptions(state, {
            instanceId: ATTENDEE_FILTER_ID
          })
        )
      )
    ),
    search: SearchBarGetters.searchTerm(state, props), // string
    grouping: getGroupBy(state, props), // 'all-passes' | 'group-affiliation' | 'order' | 'attendee' | 'pass-type'
    variantIds: [
      ...passTypeIds,
      ...mealIds,
      ...inventoryIds,
      ...boothIds,
      ...sponsorshipIds,
      ...variantIds
    ],
    sourceFormIds: R.without([CREATED_VIA_GLM, CREATED_VIA_PORTAL], sourceIds),
    sourceUserIds: userIds,
    fields: JSON.stringify(fields),
    [CREATED_VIA_PORTAL]: R.contains(CREATED_VIA_PORTAL, sourceIds),
    [CREATED_VIA_GLM]: R.contains(CREATED_VIA_GLM, sourceIds),
    mode: getters.mode(state, props), // review | issuance
    page: resetPagination ? 1 : getCurrentPage(state, props) + 1, // integer 1 based index
    pageSize: getPageSize(state, props), // integer
    itemTypeIds: getItemTypes(state, props),
    myApprovals: getIsMyApprovals(state, props),
    myRequests: false
  });
};

export const search = function*({ type, payload = {}, meta = {} }) {
  const silentRefresh =
    (payload && payload.silent) ||
    R.any(R.equals(type), shouldUseSilentRefresh);

  const instanceId =
    meta.instanceId && meta.instanceId !== TABLE_INSTANCE_ID
      ? meta.instanceId
      : null;

  yield put(
    actions.setShowNoResults(false, {
      meta: {
        instanceId
      }
    })
  );
  const credentials = yield select(getCredentials);
  const userId = yield select(getUserId);
  const eventId = yield select(getEventId);
  const portalEventId = yield select(getPortalEventId, {
    instanceId
  });
  const orgId = yield select(getOrgId, { instanceId });
  const itemBlockId = yield select(getters.itemBlockId, {
    instanceId
  });
  const submissionId = yield select(getters.submissionId, {
    instanceId
  });
  const lineItemIds = yield select(getters.lineItemIds, {
    instanceId
  });
  const orderIds = yield select(getters.orderIds, {
    instanceId
  });

  const state = yield select(R.identity);

  const data = getSearchParams(state, { instanceId }, type);

  if (!silentRefresh) {
    yield fork(loading, true, { meta });
  }

  try {
    const result = yield call(
      api.search,
      credentials,
      userId,
      eventId || portalEventId,
      orgId,
      itemBlockId,
      submissionId,
      lineItemIds,
      orderIds,
      data
    );

    // populate: group by
    if (result.payload.preferences && result.payload.preferences.grouped_by) {
      yield put(
        FilterControlsActions.setSelectedOptions(
          { [result.payload.preferences.grouped_by]: 1 },
          {
            options: {},
            meta: { instanceId: GROUP_BY_FILTER_ID }
          }
        )
      );
    }

    yield all(
      [
        result.payload.preferences
          ? put(
              TableActions.setData(
                {
                  canEditCells: false,
                  columns: result.payload.fields,
                  rows: result.payload.records,
                  columnWidths: result.payload.preferences.field_widths,
                  allRowIds: result.payload.all_record_ids,
                  editableFieldsWhiteList: ["attendee-photo"]
                },
                {
                  meta: {
                    instanceId
                  }
                }
              )
            )
          : null,
        result.payload.preferences
          ? put(
              ViewPickerActions.setActiveView(
                {
                  activeViewId: result.payload.preferences.id,
                  activeViewName: result.payload.preferences.name
                },
                {
                  meta: {
                    instanceId: VIEWPICKER_INSTANCE_ID
                  }
                }
              )
            )
          : null,
        put(
          actions.receiveList(
            {
              ...result.payload,
              view: getGroupBy(state, { instanceId })
            },
            { meta }
          )
        )
      ].filter(a => a)
    );
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred fetching passes list"
          }
        ])
      ),
      put(actions.setLoading(false, { meta }, true))
    ]);
  }
};

const watchSearch = function*() {
  yield takeEvery(SearchBarActions.setSearchTerm.type, search);
};

const exportData = function*({ type, payload: contentType, meta }) {
  const credentials = yield select(getCredentials);
  const state = yield select(R.identity);
  const eventIdToUse = yield select(getEventId);
  const portalEventId = yield select(getPortalEventId);
  const orgIdToUse = yield select(getOrgId);

  const data = getSearchParams(state, type);

  yield fork(loading, true, { meta });

  yield put(showSnackbar({ message: "Preparing export..." }));

  try {
    const initialResult = yield call(api.export, {
      ...data,
      eventId: eventIdToUse || portalEventId,
      orgId: orgIdToUse,
      credentials,
      contentType
    });

    yield delay(200);

    let progressResult = yield call(api.getStatus, credentials, {
      jobId: initialResult.payload.jobId
    });

    while (["pending", "processing"].includes(progressResult.payload.status)) {
      yield delay(1000);
      progressResult = yield call(api.getStatus, credentials, {
        jobId: initialResult.payload.jobId
      });
    }

    if (progressResult.payload.status === "error") {
      yield all([
        put(
          registerError([
            {
              system: "An error occurred exporting",
              user: "An error occurred exporting"
            }
          ])
        )
      ]);
      return false;
    }

    yield all([
      call([window, window.open], progressResult.payload.payload.url, "_blank"),
      put(actions.setLoading(false, { meta }))
    ]);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred exporting passes list"
          }
        ])
      ),
      put(actions.setLoading(false, { meta }, true))
    ]);
  }

  return true;
};

const importData = function*() {
  yield put(
    showModal({
      content: <ImportOrdersModal />,
      wrapper: ModalWrapper
    })
  );
};

const watchExport = function*() {
  yield takeEvery(actions.exportData.type, exportData);
};

const watchImport = function*() {
  yield takeEvery(actions.importData.type, importData);
};

const watchUpdateQuestions = function*() {
  for (;;) {
    const action = yield take([
      QuestionsActions.hideMenu.type,
      QuestionsActions.hideModal.type
    ]);
    if (action.type === QuestionsActions.hideMenu.type) {
      const showingModal = yield select(QuestionsGetters.showModal);
      if (!showingModal && action.payload === true) {
        yield call(search, action);
      }
    } else if (action.payload === true) {
      yield call(search, action);
    }
  }
};

const watchClearSearch = function*() {
  for (;;) {
    const action = yield take(SearchBarActions.clearSearch.type);
    const instanceId = R.path(["meta", "instanceId"], action);
    if (R.isNil(instanceId) || instanceId === defaultInstanceId) {
      yield call(search, action);
    }
  }
};

const watch = function*() {
  yield takeEvery(
    [
      actions.setMode.type,
      actions.setView.type,
      actions.setCurrentPage.type,
      actions.setPageSize.type,
      actions.setTypeFilters.type,
      actions.setSelectedFieldFilters.type,
      actions.setSelectedMeals.type,
      actions.setSelectedPasses.type,
      actions.setSelectedInventoryItems.type,
      actions.setSelectedBoothItems.type,
      actions.setSelectedSponsorshipItems.type,
      actions.setSelectedSources.type,
      actions.setSelectedRequester.type,
      actions.clearFilters.type,
      actions.removeSelectedFieldFilter.type,
      actions.setSelectedTab.type,
      actions.fetchData.type,
      ApprovalsActions.reviewDone.type,
      actions.setOrderIdsAndVariantIds.type,
      TableActions.refreshRecords.type
    ],
    search
  );
};

const rootSaga = function*() {
  yield all([
    fork(watchSearch),
    fork(watchExport),
    fork(watchImport),
    fork(watchClearSearch),
    fork(watchUpdateQuestions),
    fork(watch)
  ]);
};

export default rootSaga;
