import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actions } from "../model";

import { Page, Title } from "EventLight/Common/FieldLayout";
import Body from "./Body";
import { noop } from "utils/General";

const decorate = connect(null, {
  initMeals: actions.init
});

const Layout = ({ initMeals = noop }) => {
  useEffect(() => {
    initMeals();
  }, []);
  return (
    <>
      <Page>
        <Title
          icon="restaurant"
          title="Meals"
          description="Manage your meals, dietary restrictions and the days you're offering catering"
        />
        <Body />
      </Page>
    </>
  );
};

export default decorate(Layout);
