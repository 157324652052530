/*
 * @NOTE: Deprecating in favor of src/Submission/Editor
 */

import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import { get, isEqual, sortBy } from "lodash";
import Field from "../Field";
import Summaries from "../Summaries";

import hiddenFields from "components/Event/FormsV2/Utils/field-blacklist";
import getFormVersion from "components/Event/FormsV2/Utils/get-form-version";
import getModuleFieldId from "components/Event/FormsV2/Utils/get-module-field-id";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class Fields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldBeingEdited: null
    };
  }

  onChange = ({ id }, value) => {
    if (!isEqual(this.props.submission.values[id], value)) {
      this.props.addValue({
        version: getFormVersion(this.props.form),
        submissionRecordId: this.props.submission.submission_record_id,
        moduleFieldId: getModuleFieldId(id, this.props.form.fields),
        submissionId: this.props.submission.id,
        fieldId: id,
        value,
        userId: this.props.user.id
      });
    }
  };

  getFieldsToShow(fields) {
    return sortBy(fields, "order").filter(f => !hiddenFields.includes(f.type));
  }

  @autobind
  closeField() {
    this.setState({
      fieldBeingEdited: null
    });
  }

  @autobind
  editField(field) {
    if (!this.state.fieldBeingEdited) {
      this.setState({
        fieldBeingEdited: field.id
      });
    }
  }

  render() {
    const { readOnly, details, submission } = this.props;
    const values = submission.values;
    const fieldsToShow = this.getFieldsToShow(
      get(this.props, "form.fields", [])
    );

    return (
      <div styleName={readOnly ? "formFullWidth" : "form"}>
        {fieldsToShow.map(field => (
          <Field
            key={field.id}
            readOnly={readOnly}
            isEditing={field.id === this.state.fieldBeingEdited}
            eventDetails={details}
            editField={() => this.editField(field)}
            closeField={this.closeField}
            changeField={value => this.onChange(field, value)}
            value={values ? values[field.id] : undefined}
            values={values}
            fields={this.props.form.fields}
            field={field}
            metaData={this.props.getMetaData(
              fieldsToShow,
              submission,
              values,
              field
            )}
          />
        ))}
        <Summaries />
      </div>
    );
  }
}

Fields.propTypes = {
  details: PropTypes.object.isRequired,
  submission: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  showAdminFields: PropTypes.bool,
  readOnly: PropTypes.bool,
  addValue: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  getMetaData: PropTypes.func.isRequired
};

export default CSSModules(Fields, css);
