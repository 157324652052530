import React, { Component } from "react";
import Fuse from "fuse.js";
import * as R from "ramda";
import moveItemInArray from "utils/move-item-in-array";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import AddItemBlockModal from "components/Event/Settings/Credentials/Modals/AddItemBlock";
import ItemBlockModal from "components/Event/Settings/Credentials/Modals/ItemBlock";
import CredentialTypeModal from "components/Event/Settings/Catalog/Modals/ItemType";
import CredentialGroupModal from "components/Event/Settings/Module/Modals/FieldGroup";
import DeleteCredentialGroupModal from "components/Event/Settings/Credentials/Modals/DeleteCredentialGroup";
import HelpArticleUrls from "utils/help-article-urls";
import View from "./View";
import CircularProgress from "material-ui/CircularProgress";
import moment from "moment";
import createDateRanges from "components/Global/Editors/EventDaysEditor/utils/create-date-ranges";

import { CREDENTIAL_TYPE_ID } from "utils/item-types";

const mapIndexed = R.addIndex(R.map);

const searchWithFuse = (searchTerm, fuse, opt, matchWith) => {
  if (R.isNil(searchTerm) || R.isEmpty(searchTerm)) {
    return opt;
  }
  return R.compose(
    R.reject(R.isNil),
    R.map(o => R.find(R.propEq(matchWith, o.id), opt))
  )(fuse.search(searchTerm));
};

const getTrackingMethodName = method => {
  switch (method) {
    case "barcode":
      return "Barcode";
    case "rfid":
      return "RFID";
    case "number_range":
      return "Number Range";
    default:
      return "None";
  }
};

class Controller extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      searchTerm: ""
    };
  }

  async componentDidMount() {
    await Promise.all([
      this.props.getItemGroupsByEventAndType(
        this.props.eventDetails.id,
        CREDENTIAL_TYPE_ID
      ),
      this.props.getItemBlocksByEventAndType(
        this.props.eventDetails.id,
        CREDENTIAL_TYPE_ID
      )
    ]);

    this.credentialsFuse = new Fuse(this.props.items, {
      threshold: 0.3,
      keys: ["name"],
      shouldSort: true
    });

    this.setState({ loading: false });
  }

  componentDidUpdate(oldProps) {
    if (oldProps.items.length !== this.props.items.length) {
      this.credentialsFuse = new Fuse(oldProps.items, {
        threshold: 0.3,
        keys: ["name"],
        shouldSort: true
      });
    }
  }

  /* item blocks */
  getItemBlocks = () => {
    this.props.getItemBlocksByEventAndType(
      this.props.eventDetails.id,
      CREDENTIAL_TYPE_ID
    );
  };

  /* credential groups */
  getCredentialGroups = () => {
    this.props.getItemGroupsByEventAndType(
      this.props.eventDetails.id,
      CREDENTIAL_TYPE_ID
    );
  };

  addCredentialGroup = data => {
    this.props
      .addItemGroup({
        eventId: this.props.eventDetails.id,
        typeId: CREDENTIAL_TYPE_ID,
        order: !R.isNil(
          R.path(
            ["itemGroups", this.props.itemGroups.length - 1, "order"],
            this.props
          )
        )
          ? this.props.itemGroups[this.props.itemGroups.length - 1].order + 1
          : 0,
        ...data
      })
      .then(() => {
        this.props.showSnackbar({ message: "Group added", action: "OK" });
        return this.getCredentialGroups();
      });
  };

  updateCredentialGroup = group => {
    this.props
      .updateItemGroup({
        groupId: group.id,
        group
      })
      .then(() => {
        this.props.showSnackbar({ message: "Group updated", action: "OK" });
        return this.getCredentialGroups();
      });
  };

  deleteCredentialGroup = data => {
    this.props
      .deleteItemGroup({
        groupId: data.groupId,
        replaceWithGroupId: data.replaceWithGroupId
      })
      .then(() => {
        this.props.showSnackbar({ message: "Group deleted", action: "OK" });
        return this.getCredentialGroups();
      });
  };

  moveGroupUp = currentPosition => {
    if (currentPosition === 0) return;
    this.props
      .bulkUpdateItemGroups({
        bulk: true,
        groups: moveItemInArray(
          this.props.itemGroups,
          currentPosition,
          currentPosition - 1
        ).map((g, order) => ({
          id: g.id,
          order
        }))
      })
      .then(() => this.getCredentialGroups());
  };

  moveGroupDown = currentPosition => {
    if (currentPosition === this.props.itemGroups.length - 1) return;
    this.props
      .bulkUpdateItemGroups({
        eventId: this.props.eventDetails.id,
        bulk: true,
        groups: moveItemInArray(
          this.props.itemGroups,
          currentPosition,
          currentPosition + 1
        ).map((g, order) => ({
          id: g.id,
          order
        }))
      })
      .then(() => this.getCredentialGroups());
  };

  /* credentials */

  reorderCredentials = (groupId, newOrder) => {
    this.props
      .bulkUpdateItems({
        eventId: this.props.eventDetails.id,
        bulk: true,
        items: newOrder.map(({ id }, i) => ({ id, order: i }))
      })
      .then(() => this.getCredentialGroups());
  };

  addCredential = data => {
    this.props.addItem(data).then(() => {
      this.props.showSnackbar({ message: "Credential added", action: "OK" });
      return this.getCredentialGroups();
    });
  };

  updateCredential = data => {
    this.props.updateItem(data).then(() => {
      this.props.showSnackbar({ message: "Credential updated", action: "OK" });
      return this.getCredentialGroups();
    });
  };

  deleteCredential = itemId => {
    this.props
      .deleteItem({
        itemId
      })
      .then(() => {
        this.props.showSnackbar({
          message: "Credential deleted",
          action: "OK"
        });
        return this.getCredentialGroups();
      });
  };

  moveCredentialUp = (groupId, currentPosition) => {
    const credentials = this.props.itemGroups.find(g => g.id === groupId).items;
    if (currentPosition === 0) return;
    this.props
      .bulkUpdateItems({
        eventId: this.props.eventDetails.id,
        bulk: true,
        items: moveItemInArray(
          credentials,
          currentPosition,
          currentPosition - 1
        ).map((g, order) => ({
          id: g.id,
          order
        }))
      })
      .then(() => this.getCredentialGroups());
  };

  moveCredentialDown = (groupId, currentPosition) => {
    const credentials = this.props.itemGroups.find(g => g.id === groupId).items;
    if (currentPosition === credentials.length - 1) return;
    this.props
      .bulkUpdateItems({
        eventId: this.props.eventDetails.id,
        bulk: true,
        items: moveItemInArray(
          credentials,
          currentPosition,
          currentPosition + 1
        ).map((g, order) => ({
          id: g.id,
          order
        }))
      })
      .then(() => this.getCredentialGroups());
  };

  /* modals */
  showAddCredentialGroupModal = () => {
    this.props.showModal({
      content: (
        <CredentialGroupModal
          onSave={this.addCredentialGroup}
          hideModal={this.props.hideModal}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showUpdateCredentialGroupModal = group => {
    this.props.showModal({
      content: (
        <CredentialGroupModal
          data={{
            ...group
          }}
          onSave={this.updateCredentialGroup}
          hideModal={this.props.hideModal}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showDeleteCredentialGroupModal = group => {
    this.props.showModal({
      content: (
        <DeleteCredentialGroupModal
          name={group.name}
          handleDelete={() =>
            this.deleteCredentialGroup({
              groupId: group.id,
              replaceWithGroupId: this.props.itemGroups.filter(
                g => g.id !== group.id
              )[0].id
            })
          }
          hideModal={this.props.hideModal}
        />
      )
    });
  };

  showCredentialModal = (id, clone = false) => {
    this.props.showModal({
      content: (
        <CredentialTypeModal
          onDone={id && !clone ? this.updateCredential : this.addCredential}
          credentialId={id}
          clone={clone}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showCloneCredentialModal = id => this.showCredentialModal(id, true);

  showAddItemBlockModal = () => {
    this.props.showModal({
      content: (
        <AddItemBlockModal
          onDone={this.getItemBlocks}
          selectedTypeId={CREDENTIAL_TYPE_ID}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showItemBlockModal = id => {
    this.props.showModal({
      content: <ItemBlockModal onDone={this.getItemBlocks} itemBlockId={id} />,
      wrapper: ModalWrapper
    });
  };

  goToItemBlocksDoc = () => {
    window.open(HelpArticleUrls.ITEM_BLOCKS, "_blank");
  };

  onSearch = searchTerm => this.setState({ searchTerm });

  render() {
    const {
      eventDetails,
      itemGroups,
      itemBlocks,
      showAddItemBlockModal,
      showItemBlockModal
    } = this.props;

    if (this.state.loading) {
      return <CircularProgress color="#ccc" />;
    }

    const { searchTerm } = this.state;

    const credentialGroupsWithHandlers = mapIndexed((group, index) => {
      const credentials =
        searchWithFuse(searchTerm, this.credentialsFuse, group.items, "id") ||
        [];
      return {
        ...group,
        onEdit: () => this.showUpdateCredentialGroupModal(group),
        onDelete:
          itemGroups.length > 1
            ? () => this.showDeleteCredentialGroupModal(group)
            : null,
        moveGroupUp: index === 0 ? undefined : () => this.moveGroupUp(index),
        moveGroupDown:
          index === this.props.itemGroups.length - 1
            ? undefined
            : () => this.moveGroupDown(index),
        countOfCredentials: credentials.length,
        reorderCredentials: newOrder =>
          this.reorderCredentials(group.id, newOrder),
        forceCollapse: searchTerm.length && !credentials.length,
        collapsedDefault: !credentials.length,
        credentials: mapIndexed((credential, cIndex) => {
          const variant = credential.variants[0];
          return {
            id: credential.id,
            name: credential.name,
            color: credential.background_color,
            source: credential.source,
            quantity: variant.track_inventory
              ? variant.inventory_quantity || "—"
              : "—",
            type: variant.track_inventory
              ? getTrackingMethodName(variant.tracking_method)
              : "—",
            numZones: 0,
            dateGroups: eventDetails.date_groups,
            dates: variant.rules
              ? createDateRanges(
                  variant.rules.map(rule =>
                    moment(rule.value).format("YYYY-MM-DD")
                  )
                )
              : null,
            onCredentialClick: () => this.showCredentialModal(credential.id),
            onEdit: () => this.showCredentialModal(credential.id),
            onClone: () => this.showCloneCredentialModal(credential.id),
            onDelete: () => this.deleteCredential(credential.id),
            moveCredentialUp:
              cIndex === 0 || searchTerm.length
                ? undefined
                : () => this.moveCredentialUp(group.id, cIndex),
            moveCredentialDown:
              cIndex === credentials.length - 1 || searchTerm.length
                ? undefined
                : () => this.moveCredentialDown(group.id, cIndex)
          };
        }, credentials)
      };
    }, itemGroups);

    const blocks = mapIndexed((block, index) => {
      return {
        ...block,
        count: block.items.length,
        onClick: () => showItemBlockModal(block.id)
      };
    }, itemBlocks);

    const viewProps = {
      credentialGroups: credentialGroupsWithHandlers,
      loading: this.state.loading,
      onSearch: this.onSearch,
      showAddCredentialGroupModal: this.showAddCredentialGroupModal,
      showCredentialModal: this.showCredentialModal,
      showAddItemBlockModal,
      goToItemBlocksDoc: this.goToItemBlocksDoc,
      blocks
    };
    return <View {...viewProps} />;
  }
}

export default Controller;
