import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import {
  Div,
  SuperOutlineButton,
  AddIcon,
  OpenInNewIcon,
  BigFilledButton,
  SuperFilledButton,
  RightArrowIcon,
  DarkTab,
  BigFilledInput,
  SearchIcon,
  CloseIcon
} from "components/Base";
import { PASSES_PAGES } from "Forms/WizardModal/constants";
import { withProps, addS } from "utils/General";
import { CREDENTIAL_TYPE_ID, MEAL_TYPE_ID } from "utils/item-types";
import { actions, getters } from "Forms/WizardModal";
import {
  getItemTypes,
  getItemsForActiveItemType,
  getMeals,
  getSelectedItemBlocks,
  getUnselectedItemBlocks,
  getPassesTitle,
  getItemTypeItemName
} from "Forms/WizardModal/selectors";
import Header from "Forms/WizardModal/View/Header";
import PassSelector from "Orders/CreateOrderModal/PassSelector";

const Tab = withProps({
  fs: 3,
  mr: 3
})(DarkTab);

const decorate = connect(
  state => ({
    itemTypes: getItemTypes(state),
    mealProps: getMeals(state),
    itemTypeProps: getItemsForActiveItemType(state),
    activeItemTypeId: getters.activeItemTypeId(state),
    selectedItemBlocks: getSelectedItemBlocks(state),
    unselectedItemBlocks: getUnselectedItemBlocks(state),
    itemTypeSearch: getters.itemTypeSearch(state),
    title: getPassesTitle(state),
    itemTypeItemName: getItemTypeItemName(state)
  }),
  {
    back: () => actions.setSelectedPasses(PASSES_PAGES.SELECT_ACCOUNTS),
    next: () => actions.setSelectedPasses(PASSES_PAGES.SELECT_FIELDS),
    setActiveItemTypeId: actions.setActiveItemTypeId,
    updateVariantPriceId: actions.updateVariantPriceId,
    updateVariantQuantity: actions.updateVariantQuantity,
    toggleVariant: actions.toggleVariant,
    toggleVariants: actions.toggleVariants,
    toggleItemBlock: actions.toggleItemBlock,
    onItemTypeSearchChange: v =>
      actions.setItemTypeSearch(v && v.length ? v : "")
  }
);

const ItemBlock = ({
  id,
  name,
  count,
  isDefault,
  selected,
  viewItemBlock,
  toggleItemBlock
}) => {
  return (
    <Div
      bra={1}
      bg="white"
      b={1}
      p={3}
      bc={selected ? "orange4" : "gray3"}
      mb={2}
      style={{
        flexShrink: 0
      }}
    >
      <Div display="row.flex-start.center">
        <Div display="column" flex={1} mr={1}>
          <Div fw={3} color="gray7">
            {name}
          </Div>
          <Div uppercase fs={1} fw={3} color="gray5">
            {count} Item
            {addS(count)}
          </Div>
        </Div>
        <Div display="column">
          {isDefault ? (
            <Div color="gray7">
              <em>Editing</em>
            </Div>
          ) : (
            <Div display="row.flex-start.center">
              <Div>
                <OpenInNewIcon
                  color={{
                    default: "gray6",
                    hover: "gray8"
                  }}
                  mr={2}
                  size={14}
                  onClick={() => viewItemBlock(id)}
                />
              </Div>
              <Div
                bg={{
                  default: "gray1",
                  hover: "gray2"
                }}
                display="row.center.center"
                width={31}
                height={38}
                bra={1}
                onClick={() => toggleItemBlock({ itemBlockId: id })}
                style={{
                  flexShrink: 0
                }}
              >
                {selected ? (
                  <CloseIcon color="gray8" size={16} />
                ) : (
                  <AddIcon color="gray8" size={16} />
                )}
              </Div>
            </Div>
          )}
        </Div>
      </Div>
    </Div>
  );
};

const SelectItems = ({
  handlers,
  goBack,
  back,
  goToNextPage,
  next,
  itemTypes,
  onItemTypeSearchChange,
  itemTypeSearch,
  activeItemTypeId,
  mealProps,
  itemTypeProps,
  setActiveItemTypeId,
  updateVariantPriceId,
  updateVariantQuantity,
  toggleVariant,
  toggleVariants,
  selectedItemBlocks,
  unselectedItemBlocks,
  toggleItemBlock,
  itemTypeItemName,
  title,
  steps
}) => {
  const getTabHandler = id => () => {
    onItemTypeSearchChange("");
    setActiveItemTypeId(id);
  };

  const itemTypePropsToUse = R.propOr(itemTypeProps, activeItemTypeId)({
    [MEAL_TYPE_ID]: mealProps
  });

  const itemsWithHandlers = {
    ...itemTypePropsToUse,
    itemGroups: R.map(group => ({
      ...group,
      selectAll: () =>
        toggleVariants({
          variantIds: group.variantIds,
          toggled: true
        }),
      deselectAll: () =>
        toggleVariants({
          variantIds: group.variantIds,
          toggled: false
        }),
      items: R.map(item => ({
        ...item,
        updateVariantPriceId: priceId =>
          updateVariantPriceId({ variantId: item.id, priceId }),
        updateVariantQuantity: qty =>
          updateVariantQuantity({
            variantId: item.id,
            quantity: parseInt(qty, 10) || 0
          }),
        toggleVariant: () =>
          toggleVariant({
            variantId: item.id,
            toggled: !item.selected
          })
      }))(group.items)
    }))(itemTypePropsToUse.itemGroups)
  };

  return (
    <Div width={1} display="column.flex-start" height={1}>
      <Header
        steps={steps}
        handlers={handlers}
        steps={["Step 1", "Step 2", "Step 3", "Step 4"]}
      />
      <Div
        bg="neutral0"
        display="column.flex-start.center"
        py={5}
        flex={1}
        height={1}
        style={{ overflowY: "auto" }}
      >
        <Div fs={6} fw={4} color="black">
          {title}
        </Div>

        <Div
          width={1}
          mt={5}
          style={{
            height: "calc(100% - 76px)"
          }}
        >
          <Div display="row.flex-start.center" px={10} pt={3} bb={1} bc="gray2">
            {R.map(type => (
              <Tab onClick={getTabHandler(type.id)} active={type.active}>
                {type.name}
              </Tab>
            ))(itemTypes)}
          </Div>

          <Div display="row.flex-start.flex-start" height={1}>
            <Div display="column" flex={1} px={5} height={1}>
              <Div display="row.flex-start.center" mt={4} mb={4}>
                <BigFilledInput
                  bg="neutral1"
                  color="gray6"
                  continuous
                  onChange={onItemTypeSearchChange}
                  placeholder="Search items..."
                  LeftIcon={SearchIcon}
                  rightIconColor="gray6"
                  width={1}
                  value={itemTypeSearch}
                />

                {activeItemTypeId !== MEAL_TYPE_ID ? (
                  <BigFilledButton
                    ml={3}
                    bg="altB5"
                    onClick={() => handlers.showAddItemModal(activeItemTypeId)}
                    disabled={false}
                    width={133}
                    display="row.center.center"
                  >
                    Add Item
                  </BigFilledButton>
                ) : null}
              </Div>

              <Div
                display="column"
                flex={1}
                height={1}
                px={2}
                style={{
                  overflowY: "auto"
                }}
              >
                <PassSelector maxWidth={152} {...itemsWithHandlers} />
              </Div>
            </Div>
            <Div
              display="column"
              bg="neutral1"
              width={300}
              flex={1}
              height={1}
              p={4}
              style={{
                overflowY: "auto"
              }}
            >
              <Div mb={4} display="column" style={{ flexShrink: 0 }}>
                <Div color="gray7" fw={4} mb={2} style={{ flexShrink: 0 }}>
                  Selected to add to form:
                </Div>
                {!selectedItemBlocks.length ? (
                  <Div color="gray6" fs={2} mb={3}>
                    You haven’t selected any items or item blocks to make
                    available on your form yet.
                  </Div>
                ) : null}
                {selectedItemBlocks.map(itemBlock => (
                  <ItemBlock
                    key={itemBlock.id}
                    {...itemBlock}
                    viewItemBlock={handlers.showItemBlockModal}
                    toggleItemBlock={toggleItemBlock}
                  />
                ))}
              </Div>

              {unselectedItemBlocks.length ? (
                <Div display="column" style={{ flexShrink: 0 }}>
                  <Div color="gray7" fw={4} mb={2} style={{ flexShrink: 0 }}>
                    Your existing sets of {itemTypeItemName}:
                  </Div>
                  {unselectedItemBlocks.map(itemBlock => (
                    <ItemBlock
                      key={itemBlock.id}
                      {...itemBlock}
                      viewItemBlock={handlers.showItemBlockModal}
                      toggleItemBlock={toggleItemBlock}
                    />
                  ))}
                </Div>
              ) : null}
            </Div>
          </Div>
        </Div>
      </Div>

      <Div
        display="row.space-between.center"
        width={1}
        height={90}
        bt={1}
        bc="neutral2"
        bg="white"
        px={7}
        style={{ flexShrink: 0 }}
      >
        <SuperOutlineButton onClick={goBack || back}>Back</SuperOutlineButton>
        <SuperFilledButton
          ml={3}
          bg="altB5"
          onClick={goToNextPage || next}
          disabled={false}
          RightIcon={RightArrowIcon}
          width={133}
          display="row.space-between.center"
        >
          Next
        </SuperFilledButton>
      </Div>
    </Div>
  );
};

export default decorate(SelectItems);
