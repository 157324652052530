import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const AlertContainer = ({ children, width = "auto" }) => (
  <div styleName="container" style={{ width: width }}>
    {children}
  </div>
);

AlertContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export default CSSModules(AlertContainer, css);
