import { put, takeEvery, all, fork, select, call } from "redux-saga/effects";
import * as R from "ramda";
import { actions, getters } from "Portal/PortalPagePGA/AllReplaysList/model";

import { registerError } from "redux/modules/errors/actions";
import {
  getters as PaginationGetters,
  actions as PaginationActions
} from "ui-kit/PaginationBar";

import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/portal/selectors";

import { relatedAccountId } from "redux/modules/portal/user/selectors";

import api from "./api";

const getSearchParams = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  const page = yield select(PaginationGetters.page);
  const pageSize = yield select(PaginationGetters.pageSize);
  const player = yield select(getters.player);
  const sortBy = yield select(getters.sortBy);
  const accountId = yield select(relatedAccountId);

  return {
    filters: {
      accountId,
      eventId,
      page: page + 1,
      pageSize,
      player,
      sortBy
    },
    credentials
  };
};

const search = function*(opts = {}) {
  yield put(actions.setLoading(true));
  const data = yield call(getSearchParams);

  try {
    const result = yield call(api.getReplaysList, data);
    const pagination = R.pathOr({}, ["payload", "pagination"], result);
    yield all([put(actions.setInitialData({ ...result.payload }))]);

    if (
      [
        PaginationActions.setPageSize.type,
        actions.setPlayer.type,
        actions.setSortBy.type
      ].includes(opts.type)
    ) {
      yield put(
        PaginationActions.setPagination({
          ...pagination,
          page: 0
        })
      );
    } else {
      yield put(
        PaginationActions.setPagination({
          ...pagination,
          page: pagination.page - 1
        })
      );
    }

    // scroll to top
    const topPos = document.getElementById("pga-top").offsetTop;
    document.getElementById("pga-wrapper").scrollTop = topPos;
  } catch (error) {
    yield all([
      put(
        registerError([
          { system: error, user: "An error occurred fetching the replays list" }
        ])
      )
    ]);
  }
};

const init = function*() {
  yield all([
    put(PaginationActions.setPageSize(15)),
    put(PaginationActions.setPage(0)),
    put(actions.reset())
  ]);

  yield call(search);
  yield put(actions.setLoading(false));
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchSearch = function*() {
  yield takeEvery(
    [
      PaginationActions.setPage.type,
      PaginationActions.setPageSize.type,
      actions.setPlayer.type,
      actions.setSortBy.type
    ],
    search
  );
};

const rootSaga = function*() {
  yield all([fork(watchInit), fork(watchSearch)]);
};

export default rootSaga;
