import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { noop } from "utils/General";

import { actions } from "ui-kit/Form/model";
import { getValue } from "ui-kit/Form/selectors";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";
import { WithThemeConsumer, defaultTh, VARIANTS, os } from "ui-kit/Theme";

import CheckBox from "material-ui/Checkbox";
import { Div, Text2, SmallOutlineInput } from "components/Base";

import QuantityDropdown from "components/Global/Editors/Common/List/QuantityDropdown";

const Quantity = ({
  option,
  limitQuantity = false,
  value = {},
  disabled = false
}) =>
  limitQuantity ? (
    <QuantityDropdown
      onChange={noop}
      option={option}
      quantity={R.prop(option.id, value) || 0}
      quantityOptions={this.props.quantityOptions}
      disabled={disabled}
    />
  ) : (
    <SmallOutlineInput
      onChange={noop}
      value={R.prop(option.id, value) || 0}
      disabled={disabled}
      type="number"
      min="0"
      autoFocus
      width={45}
      style={{ visibility: R.has(option.id, value) ? "visible" : "hidden" }}
    />
  );

const decorate = R.compose(
  WithFormInstanceConsumer({ fieldType: FIELD_TYPES.CATALOG }),
  WithThemeConsumer({ variant: VARIANTS.SURFACE }),
  connect(
    (state, props) => ({
      value: getValue(state, props)
    }),
    bindInstance({
      onChange: actions.setFieldValue,
      setIniValue: actions.setIniValue
    })
  )
);

const List = ({
  options = [],
  customWidth,
  showQuantity = false,
  emptyMessage = "",
  limitQuantities = false,
  quantityOptions,
  disabled = false,
  multiple = true,
  fieldId,
  value = {},
  iniValue = {},
  onChange = noop,
  setIniValue = noop,
  fieldType,
  Th = defaultTh,
  ...styleProps
}) => {
  useEffect(() => {
    setIniValue(
      { only: iniValue },
      {
        meta: { fieldType, fieldId }
      }
    );
  }, []);

  return (
    <Div
      flex={1}
      style={{
        overflow: "hidden",
        width: customWidth
      }}
      fs={3}
    >
      {R.isEmpty(options) ? (
        <Div p={3}>{emptyMessage}</Div>
      ) : (
        R.map(
          option => (
            <Div display="row.flex-start.center" pb={1} {...os(styleProps)}>
              <CheckBox
                checked={R.has(option.id, value)}
                onClick={() =>
                  onChange(
                    { toggle: { id: option.id, single: !multiple } },
                    {
                      meta: { fieldType, fieldId }
                    }
                  )
                }
                style={{ width: "auto" }}
              />
              <Text2
                {...Th(["color"], {
                  active: R.contains(option.id, value),
                  bold: true
                })}
                onClick={() =>
                  onChange(
                    { toggle: { id: option.id, single: !multiple } },
                    {
                      meta: { fieldType, fieldId }
                    }
                  )
                }
              >
                {option.label}
              </Text2>
              <Div flex={1} color="transparent">
                spacer
              </Div>
              {showQuantity ? (
                <Quantity
                  option={option}
                  value={value}
                  disabled={disabled}
                  quantityOptions={quantityOptions}
                  limitQuantities={limitQuantities}
                />
              ) : null}
            </Div>
          ),
          options
        )
      )}
    </Div>
  );
};

export const CatalogItem = decorate(List);
