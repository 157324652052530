import PropTypes from "prop-types";
import * as R from "ramda";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Popover from "@lennd/material-ui/Popover";
import { ArrowRenderer } from "components/Global/Editors/utils/reactSelect";
import Patterns from "./Patterns";
import PATTERNS from "utils/value-types/filter/patterns";
import { Dropdown } from "components/Base";

@CSSModules(css)
class FilterPicker extends Component {
  state = {
    showPopover: false,
    selectedFieldId: this.props.selectedFieldId,
    pattern: null,
    query: ""
  };

  onRequestClose = () =>
    this.setState({
      showPopover: false,
      selectedFieldId: null,
      pattern: null,
      query: ""
    });

  handleClick = e => {
    e.stopPropagation();
    return this.setState({ showPopover: true });
  };

  handleAdd = () => {
    const patternsByName = Object.keys(PATTERNS).reduce((byName, pattern) => {
      byName[PATTERNS[pattern].pattern] = PATTERNS[pattern];
      return byName;
    }, {});

    const fieldType = this.props.fields.find(
      f => f.id === this.state.selectedFieldId
    ).type;

    if (
      !this.state.pattern ||
      (patternsByName[this.state.pattern].requiresFilterValue &&
        (!this.state.query || !this.state.query.length))
    ) {
      this.onRequestClose();
      return;
    }

    const filters = Object.assign(
      {},
      {
        filters: [],
        expression: this.props.defaultExpression
          ? this.props.defaultExpression
          : "and"
      },
      this.props.filters
    );

    let content = {
      value: {
        type: "text",
        value: this.state.query
      },
      label: `${this.state.pattern.split("_").join(" ")} ${
        R.is(Array, this.state.query)
          ? this.state.query.map(q => q.label).join(", ")
          : this.state.query
      }`
    };

    if (fieldType === "dropdown") {
      content = {
        value: {
          type: "dropdown",
          value: R.map(({ value, label }) => ({
            id: value,
            value: label
          }))(this.state.query)
        },
        label: `${this.state.pattern.split("_").join(" ")} ${R.compose(
          R.join(", "),
          R.map(R.prop("label"))
        )(this.state.query)}`
      };
    }

    filters.filters.push({
      fieldId: this.state.selectedFieldId,
      pattern: this.state.pattern,
      ...content
    });

    this.props.onChange({
      filters
    });
    this.onRequestClose();
  };

  handleQueryChange = e => this.setState({ query: e.target.value });

  handlePatternChange = (e, pattern) => this.setState({ pattern, query: "" });

  handleSelectChange = selected => {
    this.setState({
      selectedFieldId: selected ? selected.value : selected
    });
  };

  render() {
    const { selectedFieldId, query, pattern } = this.state;
    const { fields } = this.props;
    return (
      <div>
        <span
          role="link"
          tabIndex="0"
          onClick={this.handleClick}
          ref={ref => {
            this.anchor = ref;
          }}
        >
          {this.props.children}
        </span>
        <Popover
          anchorEl={this.anchor}
          anchorOrigin={{ horizontal: "left", vertical: "top" }}
          onClose={this.onRequestClose}
          open={this.state.showPopover}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          key={`${selectedFieldId}_${pattern}`}
        >
          <div styleName="container">
            <div styleName="heading">Add {this.props.label || "filter"}</div>
            <Dropdown
              arrowRenderer={ArrowRenderer}
              onChange={this.handleSelectChange}
              options={fields.map(field => ({
                label: field.name,
                value: field.id
              }))}
              placeholder="Select a field..."
              searchable
              simpleValue
              value={selectedFieldId}
              usePortal
              menuPlacement="auto"
            />
            {selectedFieldId ? (
              <div styleName="patternWrapper">
                <Patterns
                  {...{
                    field: fields.find(f => f.id === selectedFieldId),
                    pattern,
                    handlePatternChange: this.handlePatternChange,
                    query,
                    handleQueryChange: this.handleQueryChange,
                    css
                  }}
                />
              </div>
            ) : null}
            <div styleName="buttons">
              <div
                role="link"
                tabIndex="0"
                styleName="save"
                onClick={this.handleAdd}
              >
                Add {this.props.label || "filter"}
              </div>
              <div
                role="link"
                tabIndex="0"
                styleName="cancel"
                onClick={this.onRequestClose}
              >
                Cancel
              </div>
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}

FilterPicker.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedFieldId: PropTypes.string
};

export default FilterPicker;
