import React, { Component } from "react";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import * as R from "ramda";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import Field from "Submission/Editor/View/Field";
import { actions } from "Submission/Editor";
import * as SubmissionSelectors from "Submission/Editor/selectors";
import * as UserSelectors from "redux/modules/user/selectors";

import { Div } from "components/Base";

const decorate = connect(
  state => ({
    user: UserSelectors.user(state),
    details: SubmissionSelectors.eventDetails(state),
    submission: SubmissionSelectors.submission(state),
    form: SubmissionSelectors.form(state),
    customerBlockValues: SubmissionSelectors.customerBlockValues(state),
    customerBlockAccountValues: SubmissionSelectors.customerBlockAccountValues(
      state
    ),
    customerBlockContactValues: SubmissionSelectors.customerBlockContactValues(
      state
    )
  }),
  {
    addValue: actions.addCustomerBlockValue
  }
);

const SectionFields = ({
  title,
  fields,
  readOnly,
  details,
  values,
  fieldsToShow,
  submission,
  state,
  closeField,
  editField,
  getMetaData,
  ...props
}) => (
  <Div {...props}>
    <Div fw={3} color="gray7">
      {title}
    </Div>
    {fields.map(field => {
      return (
        <Field
          key={field.id}
          readOnly={readOnly}
          isEditing={field.id === state.fieldBeingEdited}
          eventDetails={details}
          editField={() => editField(field)}
          closeField={closeField}
          changeField={field.changeField}
          value={field.value}
          values={values}
          fields={fieldsToShow}
          field={field}
          metaData={getMetaData(fieldsToShow, submission, values, field)}
        />
      );
    })}
  </Div>
);

class CustomerField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldBeingEdited: null
    };
  }

  onChange = ({ field: { id }, moduleId, value }) => {
    if (!isEqual(this.props.customerBlockValues[id], value)) {
      this.props.addValue({
        // submission props
        submissionRecordId: this.props.submission.submission_record_id,
        moduleFieldId: this.props.field.id,
        submissionId: this.props.submission.id,
        fieldId: id,
        value,
        userId: this.props.user.id,
        // block props
        orderId: this.props.submission.related_order.id,
        contactRecordTypeId: this.props.field.settings.contactRecordTypeId,
        accountRecordTypeId: this.props.field.settings.accountRecordTypeId,
        markAsPrimary: this.props.field.settings.markAsPrimary,
        contactValues:
          moduleId === STANDARD_MODULE_IDS.contacts.id
            ? {
                ...this.props.customerBlockContactValues,
                [id]: value
              }
            : this.props.customerBlockContactValues,
        accountValues:
          moduleId === STANDARD_MODULE_IDS.accounts.id
            ? {
                ...this.props.customerBlockAccountValues,
                [id]: value
              }
            : this.props.customerBlockAccountValues
      });
    }
  };

  closeField = () => {
    this.setState({
      fieldBeingEdited: null
    });
  };

  editField = field => {
    if (!this.state.fieldBeingEdited) {
      this.setState({
        fieldBeingEdited: field.id
      });
    }
  };

  render() {
    const { readOnly, details, submission, customerBlockValues } = this.props;
    const fieldsToShow = R.sortBy(R.prop("order"))(
      this.props.field.customer_block_fields
    );

    const fieldsWithHandlers = R.compose(
      R.reduce(
        (fields, field) => {
          if (field.moduleId === STANDARD_MODULE_IDS.accounts.id) {
            fields.accountFields.push(field);
          } else if (field.moduleId === STANDARD_MODULE_IDS.contacts.id) {
            fields.contactFields.push(field);
          }

          return fields;
        },
        {
          contactFields: [],
          accountFields: []
        }
      ),
      R.map(f => {
        return {
          ...f.field,
          disabled: this.props.disabled,
          moduleId: f.module_id,
          name: f.alias || f.field.name,
          value: customerBlockValues[f.field_id],
          changeField: value =>
            this.onChange({
              field: f.field,
              moduleId: f.module_id,
              value
            })
        };
      })
    )(fieldsToShow);

    const contactTitle =
      this.props.field.settings.contactTitle || "Contact Details";
    const accountTitle =
      this.props.field.settings.accountTitle || "Company/Group Details";

    const sectionProps = {
      readOnly,
      details,
      values: customerBlockValues,
      submission,
      state: this.state,
      closeField: this.closeField,
      editField: this.editField,
      getMetaData: this.props.getMetaData
    };

    return (
      <Div width={1} bra={2} bc="gray2" b={1} p={5}>
        <Div fw={3} fs={4} color="gray7" mb={2}>
          {this.props.field.name}
        </Div>
        {fieldsWithHandlers.contactFields.length ? (
          <SectionFields
            {...{
              title: contactTitle,
              fields: fieldsWithHandlers.contactFields,
              fieldsToShow: fieldsWithHandlers.contactFields,
              ...sectionProps
            }}
            title={contactTitle}
            fields={fieldsWithHandlers.contactFields}
            mb={fieldsWithHandlers.accountFields.length ? 6 : 0}
          />
        ) : null}
        {fieldsWithHandlers.accountFields.length ? (
          <SectionFields
            {...{
              title: accountTitle,
              fields: fieldsWithHandlers.accountFields,
              fieldsToShow: fieldsWithHandlers.accountFields,
              ...sectionProps
            }}
            title={accountTitle}
            fields={fieldsWithHandlers.accountFields}
          />
        ) : null}
      </Div>
    );
  }
}

export default decorate(CustomerField);
