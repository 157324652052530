import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";

import { addOrder } from "redux/modules/orders/orders/actions";
import { addToGroupOrder } from "redux/modules/portal/groupOrder/actions";

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addOrder,
      addToGroupOrder
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Controller);
