import * as R from "ramda";
import { createSelector } from "reselect";
import { TABS } from "./constants";
import { getters } from "./model";

export const getSelectedTasks = createSelector(
  getters.data,
  getters.selectedTab,
  (data, selectedTab) =>
    R.compose(
      R.slice(0, 10),
      R.propOr([], selectedTab)
    )(data)
);

export const getDueLength = createSelector(
  getters.data,
  R.compose(
    R.length,
    R.propOr([], "due")
  )
);

export const getCompletedLength = createSelector(
  getters.data,
  R.compose(
    R.length,
    R.propOr([], "completed")
  )
);

export const getAssignedLength = createSelector(
  getters.data,
  R.compose(
    R.length,
    R.propOr([], "assigned_to_me")
  )
);

export const getEmptyTaskText = createSelector(
  getters.selectedTab,
  selectedTab => {
    if (selectedTab === TABS.ASSIGNED_TO_ME) {
      return "You have no assigned tasks";
    }

    if (selectedTab === TABS.DUE) {
      return "You have nothing due at this time";
    }

    return "You haven't completed any tasks yet";
  }
);
