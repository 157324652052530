import Helpers from "utils/Global/Helpers";

module.exports = {
  getZoneGroups: (credentials, eventId) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/items/zones/groups/event/${eventId}`,
        credentials,
        success,
        error
      });
    })
};
