import React from "react";
import * as R from "ramda";
import moment from "moment";
import { connect } from "react-redux";

import { getAlerts, getAlertsTotal } from "../selectors";

import { CAPTION_STATES } from "PortalHub/ActionComponents/constants";
import { CARD_ACTIONS, SECTION_ACTIONS } from "../constants";

import { ActionItem, ActionSection } from "PortalHub/ActionComponents/View";
import SlimPagination from "ui-kit/PaginationBar/View/Slim";

const viewAlertButtons = [
  { label: "View", id: CARD_ACTIONS.VIEW_ALERT, rightIcon: "rightIcon" }
];

const pageSizeOptions = [5, 10, 15];

const mapCategory = {
  warning: CAPTION_STATES.EMPTY,
  breaking: CAPTION_STATES.BREAKING
};

const getCategory = R.compose(
  R.prop(R.__, mapCategory), // eslint-disable-line no-underscore-dangle
  R.toLower,
  R.propOr("", "type")
);

const decorate = connect((state, props) => ({
  alerts: getAlerts(state, props),
  total: getAlertsTotal(state, props)
}));

const AlertsSection = ({ alerts = [], total }) => {
  if (total < 1) {
    return null;
  }
  return (
    <ActionSection
      title="Alerts"
      buttons={[
        {
          label: "View All",
          id: SECTION_ACTIONS.VIEW_ALL_ALERTS,
          icon: "visibleIcon"
        }
      ]}
    >
      {R.map(
        alert => (
          <ActionItem
            key={alert.id}
            icon="notifications"
            title={alert.label}
            caption={moment(alert.sent_at).fromNow()}
            captionState={getCategory(alert)}
            buttons={viewAlertButtons}
            data={alert}
          />
        ),
        alerts
      )}
      <SlimPagination
        total={total}
        pageSizeOptions={pageSizeOptions}
        rowsText="alerts"
        instanceId="alerts"
        bg="transparent"
      />
    </ActionSection>
  );
};

export default decorate(AlertsSection);
