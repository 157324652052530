import PropTypes from "prop-types";
import React, { Component } from "react";
import { get } from "lodash";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import autobind from "autobind-decorator";
import TetherComponent from "components/Global/Table3/ContextMenu/Tether";
import ContextMenuItems from "components/Global/Table3/ContextMenu/ContextMenuItems";
import jQuery from "jquery";

@CSSModules(css)
class ContextMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      targetNode: null,
      menu: null,
      x: 0,
      y: 0
    };
  }

  componentDidMount() {
    document.addEventListener("mouseover", this.onHeaderHover);
    document.addEventListener("contextmenu", this.onContextMenu);
    document.addEventListener("click", this.onLeftClick);

    if (this.props.scrollParent) {
      this.props.scrollParent.addEventListener("scroll", this.handleScroll);
    } else {
      window.addEventListener("scroll", this.handleScroll);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mouseover", this.onHeaderHover);
    document.removeEventListener("contextmenu", this.onContextMenu, false);
    document.removeEventListener("click", this.onLeftClick, false);

    if (this.props.scrollParent) {
      this.props.scrollParent.removeEventListener("scroll", this.handleScroll);
    } else {
      window.removeEventListener("scroll", this.handleScroll);
    }
  }

  targetIsHeaderCell($target) {
    return (
      !$target.hasClass("header-description-icon") &&
      !$target.parents(".header-description-icon").length &&
      ($target.hasClass("react-grid-HeaderCell") ||
        $target.parents(".react-grid-HeaderCell").length)
    );
  }

  targetIsSelectCell($target) {
    return (
      $target.hasClass("react-grid-Cell-row-select") ||
      $target.parents(".react-grid-Cell-row-select").length
    );
  }

  targetIsGridCell($target) {
    return (
      $target.hasClass("react-grid-Cell") ||
      $target.parents(".react-grid-Cell").length
    );
  }

  showContextMenu(e, info, menu) {
    if (
      this.props.parentId &&
      !jQuery(e.target).parents(`.${this.props.parentId}`).length
    ) {
      return;
    }
    this.setState({
      active: true,
      targetNode: e.target,
      x: e.clientX + 10,
      y: e.clientY + 5,
      menu,
      info
    });

    if (this.props.onShow) {
      this.props.onShow(e, info);
    }
  }

  getHeaderCellInfo($target) {
    const info = {
      column: null
    };
    if ($target.hasClass("react-grid-HeaderCell")) {
      info.column = $target.attr("data-column");
    } else {
      info.column = $target
        .parents(".react-grid-HeaderCell")
        .attr("data-column");
    }
    if ($target.parents(".react-grid-Lennd-Form-Wrapper")) {
      const $formGridWrapper = $target.parents(
        ".react-grid-Lennd-Form-Wrapper"
      );
      info.gridId = $formGridWrapper.attr("data-grid-id");
    }
    return info;
  }

  getSelectCellInfo($target) {
    const info = {
      row: null
    };
    if ($target.hasClass("react-grid-Cell-row-select")) {
      info.row = $target.attr("data-row");
    } else {
      const $el = $target.parents(".react-grid-Cell-row-select");
      info.row = $el.attr("data-row");
    }
    return info;
  }

  getGridCellInfo($target) {
    const info = {
      column: null,
      row: null
    };

    const $el = $target.parents(".react-grid-Row");
    info.row = $el.attr("data-row");

    return info;
  }

  @autobind
  onLeftClick(e) {
    const $target = jQuery(e.target);
    if (this.state.active) {
      this.resetState();
    }
    if (
      this.targetIsHeaderCell($target) &&
      this.props.contextMenus.headerCellMenu
    ) {
      const info = this.getHeaderCellInfo($target);
      if (info.column === "select-row") return false;
      e.preventDefault();
      this.showContextMenu(e, info, "headerCellMenu");
      return false;
    }
    if (
      this.targetIsSelectCell($target) &&
      this.props.contextMenus.selectCellMenu
    ) {
      e.preventDefault();
      this.showContextMenu(
        e,
        this.getSelectCellInfo($target),
        "selectCellMenu"
      );
      return false;
    }
  }

  @autobind
  onContextMenu(e) {
    const $target = jQuery(e.target);
    if (this.state.active) {
      this.resetState();
    }
    if (
      this.targetIsHeaderCell($target) &&
      this.props.contextMenus.headerCellMenu
    ) {
      const info = this.getHeaderCellInfo($target);
      if (info.column === "select-row") return false;
      e.preventDefault();
      this.showContextMenu(e, info, "headerCellMenu");
      return false;
    }

    if (
      this.targetIsSelectCell($target) &&
      this.props.contextMenus.selectCellMenu
    ) {
      e.preventDefault();
      this.showContextMenu(
        e,
        this.getSelectCellInfo($target),
        "selectCellMenu"
      );
      return false;
    }

    if (
      this.targetIsGridCell($target) &&
      this.props.contextMenus.gridCellMenu
    ) {
      e.preventDefault();
      this.showContextMenu(e, this.getGridCellInfo($target), "gridCellMenu");
      return false;
    }
  }

  @autobind
  onHeaderHover(e) {
    const $target = jQuery(e.target);
    // Bail if we already have the class
    if ($target.hasClass("react-grid-HeaderCell-has-context-menu")) return;
    if (this.targetIsHeaderCell($target)) {
      const info = this.getHeaderCellInfo($target);
      if (info.column === "select-row") return;
      const menuItems = get(this.props, "contextMenus.headerCellMenu");

      const hasMenu = menuItems.some(
        item => (item.allow ? item.allow(info) : true)
      );

      if (hasMenu) {
        $target.addClass("react-grid-HeaderCell-has-context-menu");
      }
    }
  }

  @autobind
  onClick(e) {
    const isMenu = e.target.getAttribute("data-context");
    if (isMenu !== "true") {
      this.resetState();
    }
  }

  @autobind
  resetState() {
    this.setState({
      active: false,
      targetNode: null,
      menu: null
    });
  }

  @autobind
  handleScroll() {
    const ref = this.refs["tethered-component"];
    if (ref) {
      ref.position();
    }
  }

  renderMenu(contextMenu = []) {
    const menu = contextMenu
      .filter(item => (item.allow ? item.allow(this.state.info) : true))
      .reduce((a, b, idx) => {
        // remove duplicate separators
        if (
          idx === 0 ||
          JSON.stringify(a[a.length - 1]) !== JSON.stringify(b)
        ) {
          a.push(b);
        }
        return a;
      }, []);

    // strip out separator if it is last item
    if (menu.length && menu[menu.length - 1].type === "separator") {
      menu.pop();
    }
    return menu;
  }

  render() {
    if (!this.state.active) {
      return null;
    }
    const {
      onShow,
      scrollParent,
      parentId,
      contextMenus,
      ...rest
    } = this.props;

    // build menu items, remove duplicate separators
    const menuItems = this.renderMenu(contextMenus[this.state.menu]); // || [];

    // if no items to show, don't show
    if (!menuItems.length) {
      return null;
    }
    const menu = (
      <div
        ref="menu"
        data-context="true"
        onClick={this.resetState}
        className={css.contextMenu}
        {...rest}
      >
        <ContextMenuItems
          hideMenu={this.resetState}
          menu={menuItems}
          info={this.state.info}
        />
      </div>
    );

    return (
      <TetherComponent
        ref="tethered-component"
        elementComponent={menu}
        targetNode={this.state.targetNode}
        attachment="top left"
        classPrefix="context-menu-tether"
        targetAttachment="bottom left"
        constraints={
          this.props.scrollParent
            ? [
                {
                  to: this.props.scrollParent,
                  attachment: "together"
                }
              ]
            : null
        }
      />
    );
  }
}

ContextMenu.propTypes = {
  onShow: PropTypes.func,
  contextMenus: PropTypes.object,
  scrollParent: PropTypes.object,
  styles: PropTypes.object,
  parentId: PropTypes.string
};

export default ContextMenu;
