import { createSelector } from "reselect";
import * as R from "ramda";

import { getters } from "ui-kit/SelectFields";
import { getters as SearchBarGetters } from "ui-kit/SearchBar";

export const getSelectedFields = createSelector(
  getters.selectedFields,
  (_, props) => R.propOr([], "fields", props),
  (selectedIds, fields) =>
    R.uniq(
      R.concat(
        R.filter(field => field.required, fields),
        R.filter(field => R.contains(field.id, selectedIds), fields)
      )
    )
);

export const getFields = createSelector(
  (_, props) => R.propOr([], "fields", props),
  SearchBarGetters.searchTerm,
  (fields, searchTerm) =>
    R.call(
      searchTerm
        ? R.filter(field =>
            R.contains(R.toLower(searchTerm), R.toLower(field.name || ""))
          )
        : R.identity,
      R.concat(
        R.filter(field => field.required, fields),
        R.filter(field => !field.required, fields)
      )
    )
);

export const getRemovedFieldIds = createSelector(
  getters.iniSelected,
  getters.selectedFields,
  (iniSelectedIds, selectedIds) => R.difference(iniSelectedIds, selectedIds)
);
