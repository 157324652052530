import {
  put,
  call,
  takeEvery,
  all,
  fork,
  select,
  delay
} from "redux-saga/effects";
import { actions, getters } from "Modules/CloneModuleModal";
import { getCredentials } from "redux/modules/user/selectors";
import { navigateTo } from "utils/General";
import { registerError } from "redux/modules/errors/actions";
import api from "Modules/CloneModuleModal/api";
import { PAGES } from "./constants";
import { eventId } from "redux/modules/event/selectors";

const getEventOptions = function*({ payload: { event } }) {
  const credentials = yield select(getCredentials);
  const isLoading = yield select(getters.loading);

  if (isLoading) return false;

  yield all([
    put(actions.setSelectedEvent(event)),
    put(
      actions.setExcludedContent({
        users: [],
        modules: [],
        forms: [],
        document_requests: []
      })
    ),
    put(actions.setIncludeAllRecords([]))
  ]);

  try {
    const result = yield call(api.eventOptions, credentials, {
      eventId: event.id
    });

    yield all([
      put(actions.setEventOptions(result.payload)),
      put(actions.setSelectedPage(PAGES.SELECT_CONTENT)),
      put(actions.setLoading(false))
    ]);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred getting event options"
          }
        ])
      ),
      put(actions.setLoading(false, {}, true))
    ]);
  }
};

const cloneData = function*() {
  const credentials = yield select(getCredentials);
  const selectedEvent = yield select(getters.selectedEvent);
  const excludedContent = yield select(getters.excludedContent);
  const includeAllRecords = yield select(getters.includeAllRecords);
  const eventIdToUse = yield select(eventId);

  yield all([
    put(actions.setSelectedPage(PAGES.CONFIRMATION)),
    put(actions.setProcessing(true))
  ]);

  try {
    const initialResult = yield call(api.cloneData, credentials, {
      eventSourceId: selectedEvent.id,
      copyToEventId: eventIdToUse,
      moduleIds: excludedContent.modules,
      includeAllRecords
    });

    yield delay(500);

    let progressResult = yield call(api.getCloneStatus, credentials, {
      jobId: initialResult.payload.jobId
    });

    while (["pending", "processing"].includes(progressResult.payload.status)) {
      yield delay(3000);
      progressResult = yield call(api.getCloneStatus, credentials, {
        jobId: initialResult.payload.jobId
      });
    }

    yield call(navigateTo, `/event/${eventIdToUse}/home`);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred cloning event"
          }
        ])
      ),
      put(actions.setProcessing(false, {}, true))
    ]);
  }
};

const watchCloneData = function*() {
  yield takeEvery(actions.cloneData.type, cloneData);
};

const watchSelectEvent = function*() {
  yield takeEvery(actions.selectEvent.type, getEventOptions);
};

const rootSaga = function*() {
  yield all([fork(watchCloneData), fork(watchSelectEvent)]);
};

export default rootSaga;
