import getFieldTypeForForms from "utils/Forms/get-field-type";
import getFieldSettingsForForms from "utils/Forms/get-field-settings";

export default fields => ({
  // handle catalog fields outside of subforms
  ...fields
    .filter(f => getFieldTypeForForms(f) === "catalog-item")
    .reduce((items, field) => {
      getFieldSettingsForForms(field).availableCatalogItems.forEach(i => {
        items[i.id] = i.value;
      });
      return items;
    }, {}),
  // handle catalog fields in subforms
  ...fields.filter(f => f.type === "subform").reduce((items, subformField) => {
    subformField.subform.form.fields
      .filter(f => getFieldTypeForForms(f) === "catalog-item")
      .forEach(f => {
        getFieldSettingsForForms(f).availableCatalogItems.forEach(i => {
          items[i.id] = i.value;
        });
      });
    return items;
  }, {})
});
