import React from "react";

import { connect } from "react-redux";

import * as R from "ramda";

import { actions } from "Items/BulkQuestionItemsModal";

import { getSelectedVariants } from "../selectors";

import { Div, Text2, Text3, CloseIcon, Text1 } from "components/Base";

const decorate = connect(
  state => ({
    selectedVariants: getSelectedVariants(state)
  }),
  {
    removeVariant: actions.removeVariant
  }
);

const SelectedVariants = ({ selectedVariants, removeVariant }) => {
  return (
    <Div width={1} height={1} style={{ overflowY: "auto" }} p={3}>
      <Div display="row.space-between.center">
        <Text3 bold color="black">
          Selected Items ({selectedVariants.length})
        </Text3>
      </Div>
      <Div width={1} mt={1}>
        {R.map(
          ({ id, name, typeId, groupId }) => (
            <Div bra={1} bg="altB1" width={1} p={2} mt={2} key={id}>
              <Div display="row.space-between.center">
                <Div display="row.flex-start.center">
                  <Text2 color="black" bold>
                    {name}
                  </Text2>
                </Div>
                <CloseIcon
                  onClick={() =>
                    removeVariant({ variantId: id, typeId, groupId })
                  }
                />
              </Div>
            </Div>
          ),
          selectedVariants
        )}
      </Div>
    </Div>
  );
};

export default decorate(SelectedVariants);
