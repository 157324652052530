import {
  put,
  all,
  takeEvery,
  fork,
  take,
  call,
  select
} from "redux-saga/effects";
import { actions } from "./model";
import { getCredentials } from "redux/modules/user/selectors";
import { registerError } from "redux/modules/errors/actions";

import * as R from "ramda";
import { PAGE_ACTIONS } from "./constants";
import api from "./api";

const init = function*({ payload: pageData }) {
  yield put(
    actions.setInitialData({
      ...pageData
    })
  );
};

const markAsCompleted = function*({ payload: { id: sessionId, completed } }) {
  const credentials = yield select(getCredentials);
  try {
    yield call(api.updateCompletionStatus, {
      credentials,
      sessionId,
      data: {
        checkedIn: R.not(completed)
      }
    });
    yield put(actions.updateCompletedStatus(sessionId));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error updating course status"
        }
      ])
    );
  }
};

const watchPageActions = function*() {
  for (;;) {
    const action = yield take(actions.executeAction.type);
    const delegate = R.prop(action.payload.type, {
      [PAGE_ACTIONS.MARK_AS_COMPLETED]: markAsCompleted
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([fork(watchInit), fork(watchPageActions)]);
};

export default rootSaga;
