/* eslint-disable no-underscore-dangle */
import React from "react";

import * as R from "ramda";
import { put, all, fork, select, takeEvery, call } from "redux-saga/effects";
import { actions, getters } from "Schedules/Schedule";
import { showModal } from "redux/modules/modal/actions";
import { makeFuture } from "utils/General/sagas";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

import { getters as AddActivityRowGetters } from "Schedules/AddActivityRow";

import { getters as ViewPickerGetters } from "ui-kit/ViewPicker";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import SubscribeModal from "Modules/SubscribeModal/View";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";

import { VIEWPICKER_INSTANCE_ID } from "Schedules/Schedule/constants";

const showSubscribeModal = function*() {
  const moduleId = yield select(getters.moduleId);
  const activeViewId = yield select(ViewPickerGetters.activeViewId, {
    instanceId: VIEWPICKER_INSTANCE_ID
  });

  yield put(
    showModal({
      content: (
        <SubscribeModal
          type="schedule"
          moduleId={moduleId}
          viewId={activeViewId}
        />
      ),
      wrapper: ModalWrapper
    })
  );
};

const showAddRecordModal = function*({ moduleId, values }) {
  const handleCreate = makeFuture();
  yield put(
    showModal({
      content: (
        <AddRecordModal
          moduleId={moduleId}
          typeId={STANDARD_MODULES.schedules.id}
          values={values}
          onSave={handleCreate.done}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleCreate.onRealized);
};

const handleShowAddRecordModal = function*() {
  const moduleId = yield select(getters.moduleId);
  const values = yield select(AddActivityRowGetters.values);
  const activityRowModuleId = yield select(AddActivityRowGetters.moduleId);

  const record = Object.keys(values).reduce((map, fieldId) => {
    if (values[fieldId].value) {
      map[fieldId] = values[fieldId].value;
    }
    return map;
  }, {});

  yield call(showAddRecordModal, {
    moduleId:
      moduleId === "master"
        ? R.prop("value")(activityRowModuleId || {})
        : moduleId,
    values: record
  });

  yield put(actions.fetchData());
};

const watchShowSubscribeModal = function*() {
  yield takeEvery(actions.showSubscribeModal.type, showSubscribeModal);
};

const watchShowAddRecordModal = function*() {
  yield takeEvery(actions.showAddRecordModal.type, handleShowAddRecordModal);
};

const rootSaga = function*() {
  yield all([fork(watchShowSubscribeModal), fork(watchShowAddRecordModal)]);
};

export default rootSaga;
