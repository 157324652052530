import React from "react";
import CircularProgress from "material-ui/CircularProgress";
import { Div } from "components/Base";

const Loading = () => (
  <Div height={310} display="row.center.center">
    <CircularProgress color="#ccc" />
  </Div>
);

export { Loading };
