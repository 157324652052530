import { createModule } from "redux-mvc";

import model from "./model";
import table from "ui-kit/Table";
import createAlerts from "Portal/Alerts/Create";

import rootSaga from "./sagas";

const module = createModule(model);

module.plugModule(table);
module.plugModule(createAlerts);

module.setRootSaga(rootSaga);

export default module;
