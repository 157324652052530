export const COUNT_APPROVALS_CHECKLISTS = {
  count_of_my_approvals: 10,
  count_of_setup_checklists: 3
};

export const APPROVALS_PAYLOAD = {
  count_of_pending_approvals: 10,
  pending_approval: [
    {
      name: "Passes & Credentials",
      count_of_pending_review: 100,
      count_of_reviewed: 200,
      count_of_all_approvals: 300,
      url: "/event/2183/passes/manage/all-passes",
      children: []
    },
    {
      name: "Passes & Credentials",
      count_of_pending_review: 197,
      count_of_reviewed: 236,
      count_of_all_approvals: 433,
      url: "/event/2183/passes/manage/all-passes",
      children: [
        {
          name: "Golf Carts",
          count_of_pending_review: 110,
          count_of_reviewed: 300,
          count_of_all_approvals: 410,
          url: "/event/2183/passes/manage/all-passes"
        },
        {
          name: "Tables & Chairs",
          count_of_pending_review: 98,
          count_of_reviewed: 102,
          count_of_all_approvals: 200,
          url: "/event/2183/passes/manage/all-passes"
        }
      ]
    }
  ],
  reviewed: [
    {
      name: "Meals",
      count_of_pending_review: 0,
      count_of_reviewed: 200,
      count_of_all_approvals: 200,
      url: "/event/2183/passes/manage/all-passes",
      children: []
    }
  ]
};

export const SETUP_CHECKLISTS = {
  pending_setup: [
    {
      name: "Exhibitor & Booth Sales",
      count_of_remaining_steps: 3,
      count_of_completed_steps: 3,
      count_of_all_steps: 6,
      url: "/event/2183/passes/setup"
    }
  ],
  reviewed: [
    {
      name: "Media Applications",
      count_of_remaining_steps: 0,
      count_of_completed_steps: 10,
      count_of_all_steps: 10,
      url: "/event/2183/passes/setup"
    }
  ]
};
