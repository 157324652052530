export const NAMESPACE = "SalesPortalItemsModal";

export const HEADER = {
  MOBILE: 60,
  TABLET: 80
};

export const SHIPPING = {
  SAME: "same",
  DIFFERENT: "different"
};
