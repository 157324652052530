import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const CardIconTitle = ({ title, color, children }) => (
  <div styleName="container">
    <span styleName="icon" style={{ backgroundColor: color }}>
      {children}
    </span>
    <span styleName="title">{title}</span>
  </div>
);

CardIconTitle.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.object]).isRequired
};

export default CSSModules(CardIconTitle, css);
