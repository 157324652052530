import AppDispatcher from "dispatcher/AppDispatcher";
import Constants from "constants/GlobalConstants";
import WebAPIUtils from "utils/Global/WebApiUtils";

const ActionTypes = Constants.ActionTypes;

module.exports = {
  setInitialLoggedInProfile: (profile, cb) => {
    AppDispatcher.handleViewAction({
      type: ActionTypes.RECEIVE_LOGGED_IN_PROFILE,
      profile
    });
    cb();
  },

  receiveLoggedInProfile: (credentials, cb) => {
    WebAPIUtils.getLoggedInProfile(credentials, profile => {
      AppDispatcher.handleViewAction({
        type: ActionTypes.RECEIVE_LOGGED_IN_PROFILE,
        profile
      });

      if (typeof cb === typeof Function) {
        cb();
      }
    });
  },

  receiveUserNotifications: (credentials, cb) => {
    WebAPIUtils.receiveUserNotifications(credentials, notifications => {
      AppDispatcher.handleViewAction({
        type: ActionTypes.RECEIVE_USER_NOTIFICATIONS,
        notifications
      });

      if (typeof cb === typeof Function) {
        cb(notifications);
      }
    });
  },

  updateUser: (credentials, details, cb) => {
    WebAPIUtils.updateUser(credentials, details, result => {
      module.exports.receiveLoggedInProfile();

      if (typeof cb === typeof Function) {
        cb(result);
      }
    });
  }
};
