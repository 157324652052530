import { createSelector } from "reselect";
import { getters } from "Items/SelectOrderManagementFieldsModal";
import * as R from "ramda";

export const getTabs = createSelector(getters.tabs, tabs => {
  return R.map(tab => ({
    id: tab.id,
    name: tab.name
  }))(tabs);
});

export const getCategoryList = createSelector(
  getters.tabs,
  getters.fieldsFilter,
  getters.selectedTab,
  (tabs, fieldsFilter, selectedTab) => {
    const currentTab = tabs.find(tab => R.prop("id", tab) === selectedTab);

    if (!currentTab) {
      return [];
    }

    return R.reduce(
      (emptyList, category) => {
        const filteredFields = R.filter(
          field =>
            !R.isNil(R.prop("name", field)) &&
            R.contains(
              R.toLower(fieldsFilter),
              R.toLower(R.prop("name", field))
            ),
          R.prop("fields", category)
        );

        if (R.length(filteredFields) < 1) {
          return emptyList;
        }

        return [
          ...emptyList,
          {
            ...category,
            filteredFields
          }
        ];
      },
      [],
      R.prop("groups", currentTab)
    );
  }
);

export const getReportingTitle = createSelector(
  getters.data,
  getters.selectedFields,
  (data, selectedFields) => {
    const reportCategoryList = R.filter(
      ({ is_report_category }) => is_report_category === true,
      data
    );

    const categoryListLength = R.length(reportCategoryList);

    if (categoryListLength < 1) {
      return "Reporting Fields";
    }

    const totalFieldsList = R.reduce(
      (acc, category) => [...acc, ...category.fields],
      [],
      reportCategoryList
    );

    const reportedFieldsList = R.filter(
      ({ is_report_field }) => is_report_field === true,
      selectedFields
    );

    return `Reporting Fields (${R.length(reportedFieldsList)} of ${R.length(
      totalFieldsList
    )})`;
  }
);
