import React from "react";

import { Div, Text3, Text1, Text0 } from "components/Base";

const Container = ({
  children,
  title = "",
  Icon = null,
  subtitle = "",
  moreInfo = ""
}) => (
  <Div
    display="column"
    width={1}
    p={4}
    bg="white"
    bra={1}
    ba={1}
    shadow={1}
    bc="neutral1"
    mb={4}
  >
    <Div display="row.space-between.center" mb={4}>
      <Div display="row.flex-start.center">
        {Icon && <Icon color="neutral9" size={24} />}
        <Text3 bold color="neutral9" ml={Icon ? 2 : 0}>
          {title}
        </Text3>
      </Div>
      <Div display="column.flex-start.flex-end">
        {subtitle && (
          <Text1 bold color="primary8">
            {subtitle}
          </Text1>
        )}
        {moreInfo && <Text0 color="neutral6">{moreInfo}</Text0>}
      </Div>
    </Div>
    {children}
  </Div>
);

export default Container;
