import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import PageTitle from "EventLight/Common/PageTitle";

import { BigFilledButton } from "components/Base";
import Loading from "ui-kit/Loading";
import { Page, Title } from "EventLight/Common/FieldLayout";
import Body from "./Body";
import AddContentModal from "EventLight/Content/AddContentModal/View";
import { actions as contentModalActions } from "EventLight/Content/AddContentModal/model";

const decorate = connect(
  state => ({
    loading: getters.loading(state)
  }),
  {
    init: actions.init,
    showAddContentModal: () => contentModalActions.showContentModal({})
  }
);

const Layout = ({ loading, showAddContentModal, init }) => {
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <PageTitle titles={["Content"]} />
      <Page>
        <Title
          icon="ondemand_video"
          title="Content"
          description="Manage content that is available to your attendees"
          buttons={
            <BigFilledButton bg="altB5" ml={2} onClick={showAddContentModal}>
              Add Content
            </BigFilledButton>
          }
        />

        {loading ? <Loading /> : <Body />}
      </Page>
      <AddContentModal />
    </>
  );
};

export default decorate(Layout);
