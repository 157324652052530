import PropTypes from "prop-types";
import React, { Component, cloneElement } from "react";
import {
  isManifestPending,
  isManifestApproved
} from "components/Global/Approvals/utils/approvals-helpers";
import getApprovalValue from "utils/value-types/get-value/approval";
import { uniq } from "lodash";
import { APPROVAL } from "utils/system-field-ids";

const filterRecords = (allRecords, status, cache) => {
  if (status === "pending") {
    return allRecords.filter(
      record =>
        cache.includes(record.id) ||
        isManifestPending(getApprovalValue(record.values[APPROVAL]))
    );
  }
  if (status === "approved") {
    return allRecords.filter(
      record =>
        cache.includes(record.id) ||
        isManifestApproved(getApprovalValue(record.values[APPROVAL]))
    );
  }
  return allRecords;
};

/**
 * This is a component that takes in status and records, filters records that
 * _aren't_ that status, but caches the ids so if in the future, any rows that
 * have a status change continue to appear in this list.
 */
class CachedRowsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cachedMatchingIds: this.buildCacheIds(
        props.records,
        props.status,
        props.cachedMatchingIds
      )
    };
  }

  componentWillReceiveProps(props) {
    if (props.status !== this.props.status) {
      this.setState({
        cachedMatchingIds: this.buildCacheIds(
          props.records,
          props.status,
          props.cachedMatchingIds
        )
      });
    } else if (props.records !== this.props.records) {
      // If we records have changed, add them to the cache
      this.setState({
        cachedMatchingIds: uniq([
          ...this.state.cachedMatchingIds,
          ...this.buildCacheIds(
            props.records,
            props.status,
            this.state.cachedMatchingIds
          )
        ])
      });
    }
  }

  buildCacheIds(records, status, cache) {
    return filterRecords(records, status, cache).map(({ id }) => id);
  }

  render() {
    const { status, children, records } = this.props;
    return cloneElement(children, {
      records: filterRecords(records, status, this.state.cachedMatchingIds)
    });
  }
}

CachedRowsContainer.defaultProps = {
  cachedMatchingIds: [],
  records: []
};

CachedRowsContainer.propTypes = {
  cachedMatchingIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  records: PropTypes.arrayOf(PropTypes.object).isRequired,
  status: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default Child => props => (
  <CachedRowsContainer {...props}>
    <Child {...props} />
  </CachedRowsContainer>
);
