import React from "react";
import { Div } from "components/Base";
import * as R from "ramda";

export const Section = ({ field }) => (
  <Div className="lennd-valid" mb={4} mt={7}>
    <Div display="row.flex-start" color="black" fw={3} mb={1} fs={4}>
      {field.name}
    </Div>
    {R.path(["description", "length"], field) ? (
      <Div
        fs={2}
        color="gray8"
        mb={1}
        style={{
          whiteSpace: "pre-line"
        }}
      >
        {field.description}
      </Div>
    ) : null}
  </Div>
);

export default Section;
