import React from "react";

import {
  Div,
  CheckIcon,
  Text1,
  Text2,
  TinyToggle,
  EditIcon
} from "components/Base";

const FieldItem = ({
  name,
  description = "Catalog Item field",
  selected = false,
  required = false,
  fieldId = "",
  categoryId = "",
  moduleId = "",
  header = false,
  toggleField,
  toggleRequired,
  handlers: { showEditFieldModal }
}) => (
  <Div
    bra={1}
    width={1}
    bg="white"
    ba={1}
    bc="neutral2"
    shadow={1}
    px={4}
    display="row.space-between.center"
    height={50}
    mb={1}
    style={{ flexShrink: 0 }}
    className="hoverContainer"
  >
    <Div display="row.flex-start.center">
      <Div width={50}>
        {header ? (
          <CheckIcon size={24} color="altA6" />
        ) : (
          <TinyToggle
            active={selected}
            onClick={() => {
              toggleField({ fieldId, categoryId, moduleId, selected });
            }}
          />
        )}
      </Div>
      <Div display="column">
        <Div display="row">
          <Text2 bold>{name}</Text2>
          {header && <Text2 color="danger7">*</Text2>}
        </Div>
        <Text1 color="neutral5">{description}</Text1>
      </Div>
    </Div>
    <Div display="row.flex-start.center">
      <Div className="showOnHover">
        <EditIcon
          size={24}
          mr={2}
          onClick={() =>
            showEditFieldModal(
              categoryId || moduleId,
              fieldId,
              categoryId ? "inventory" : "passes"
            )
          }
          color={{ default: "neutral4", hover: "primary5" }}
        />
      </Div>
      {header ? (
        <CheckIcon size={24} color="altA6" />
      ) : (
        <TinyToggle
          active={required}
          onClick={() => {
            toggleRequired({
              fieldId,
              categoryId,
              moduleId,
              selected,
              required
            });
          }}
        />
      )}
    </Div>
  </Div>
);

export default FieldItem;
