import * as R from "ramda";
import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import { actions } from "./model";
import { getTableFields } from "./selectors";
import { registerError } from "redux/modules/errors/actions";
import { apiCall } from "App/Data/sagas";
import { getCredentials } from "redux/modules/user/selectors";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";
import itemTypesApi from "redux/modules/items/types/api";
import { actions as TableActions } from "ui-kit/Table/model";
import {
  TABLE_INSTANCE_ID,
  VARIANTS_TRACKED,
  DROPDOWN_STATUS,
  DROPDOWN_STATUS_VALUES,
  ALL_ITEMS_OPTION,
  DEFAULT_COLUMN_WIDTHS
} from "./constants";
import {
  getters as PaginationGetters,
  actions as PaginationActions
} from "ui-kit/PaginationBar";
import { eventId as getEventId } from "redux/modules/event/selectors";
import {
  actions as SearchBarActions,
  getters as SearchBarGetters
} from "ui-kit/SearchBar";
import {
  actions as DropdownActions,
  getters as DropdownGetters
} from "ui-kit/Form/model";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const page = yield select(PaginationGetters.page);
  const pageSize = yield select(PaginationGetters.pageSize);
  const search = yield select(SearchBarGetters.searchTerm);

  return {
    credentials,
    page: page + 1,
    pageSize,
    search
  };
};

const init = function*() {
  try {
    yield put(actions.setLoading(true));
    const eventId = yield select(getEventId);
    const { credentials, page, pageSize } = yield call(getParams);

    const [{ payload: items }, { payload }] = yield all([
      call(itemTypesApi.getItemTypesByEvent, credentials, eventId),
      call(apiCall, {
        method: "get",
        url: `/items/event/${eventId}/type/${CREDENTIAL_TYPE_ID}/inventory`,
        qs: { page: page + 1, pageSize }
      })
    ]);

    const columns = yield select(getTableFields);
    const rows = R.map(
      ({
        id,
        item_group_name,
        assigned_to,
        uid,
        variant_name,
        order_id,
        order_number
      }) => ({
        id,
        inventory_id: { type: "text", value: uid },
        category: { type: "text", value: item_group_name },
        variant_name: { type: "text", value: variant_name },
        assigned_to: { type: "text", value: assigned_to || "-" },
        order_number: {
          type: "order-numbers",
          value: order_number ? [{ id: order_id, number: order_number }] : null
        }
      }),
      payload.results
    );

    const variantsTracked = R.compose(
      items => [
        {
          id: ALL_ITEMS_OPTION,
          label: "Show all items"
        },
        ...items
      ],
      R.map(item => ({
        id: R.pathOr("", ["variants", 0, "id"], item),
        label: R.pathOr("", ["variants", 0, "display_name"], item)
      })),
      R.filter(R.pathOr(false, ["variants", 0, "track_inventory"])),
      R.flatten,
      R.map(R.propOr([], "items")),
      R.propOr([], "groups"),
      R.find(({ id }) => id === CREDENTIAL_TYPE_ID)
    )(items);

    yield all([
      put(actions.setVariantsTracked(variantsTracked)),
      put(actions.setPagination(payload.pagination)),
      put(
        TableActions.setData(
          {
            columns,
            rows,
            columnWidths: DEFAULT_COLUMN_WIDTHS,
            canEditCells: false
          },
          {
            meta: {
              instanceId: TABLE_INSTANCE_ID
            }
          }
        )
      )
    ]);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading inventory"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const search = function*({ type }) {
  try {
    yield put(actions.setRefreshing(true));
    const eventId = yield select(getEventId);

    const page = yield select(PaginationGetters.page);
    const pageSize = yield select(PaginationGetters.pageSize);
    const search = yield select(SearchBarGetters.searchTerm);
    const formFields = yield select(DropdownGetters.fields);
    const variantId = R.pathOr("", [VARIANTS_TRACKED, "value", 0], formFields);
    const filter = R.pathOr("", [DROPDOWN_STATUS, "value", 0], formFields);

    const { payload } = yield call(apiCall, {
      method: "get",
      url: `/items/event/${eventId}/type/${CREDENTIAL_TYPE_ID}/inventory`,
      qs: {
        page: page + 1,
        pageSize,
        ...(!R.isEmpty(search) ? { search } : {}),
        ...(!R.isEmpty(variantId) && variantId !== ALL_ITEMS_OPTION
          ? { variantId }
          : {}),
        ...(R.isEmpty(filter) || filter === DROPDOWN_STATUS_VALUES.ALL_INVENTORY
          ? {}
          : { filter })
      }
    });

    const columns = yield select(getTableFields);
    const rows = R.map(
      ({
        id,
        item_group_name,
        assigned_to,
        uid,
        variant_name,
        order_id,
        order_number
      }) => ({
        id,
        inventory_id: { type: "text", value: uid },
        category: { type: "text", value: item_group_name },
        variant_name: { type: "text", value: variant_name },
        assigned_to: { type: "text", value: assigned_to || "-" },
        order_number: {
          type: "order-numbers",
          value: order_number ? [{ id: order_id, number: order_number }] : null
        }
      }),
      payload.results
    );

    yield all([
      put(actions.setPagination(payload.pagination)),
      put(
        TableActions.setData(
          {
            columns,
            rows,
            columnWidths: DEFAULT_COLUMN_WIDTHS,
            canEditCells: false
          },
          {
            meta: {
              instanceId: TABLE_INSTANCE_ID
            }
          }
        )
      )
    ]);

    // set pagination
    const pagination = payload.pagination;
    if (
      [
        PaginationActions.setPageSize.type,
        SearchBarActions.clearSearch.type,
        SearchBarActions.setSearchTerm.type,
        DropdownActions.setFieldValue.type
      ].includes(type)
    ) {
      yield put(
        PaginationActions.setPagination({
          ...pagination,
          page: 0
        })
      );
    } else {
      yield put(
        PaginationActions.setPagination({
          ...pagination,
          page: pagination.page - 1
        })
      );
    }
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading inventory"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setRefreshing(false));
  }
};

const resetPagination = function*() {
  yield put(PaginationActions.setPagination({ page: 0, pageSize: 10 }));
};

const refreshAfterSaving = function*() {
  yield all([
    call(resetPagination),
    put(actions.setShowAddInventoryModal(false))
  ]);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchRefreshAfterSaving = function*() {
  yield takeEvery(actions.refreshAfterSaving.type, refreshAfterSaving);
};

const watchResetPagination = function*() {
  yield takeEvery(
    [DropdownActions.setFieldValue.type, SearchBarActions.setSearchTerm.type],
    resetPagination
  );
};

const watchSearch = function*() {
  yield takeEvery(
    [
      SearchBarActions.clearSearch.type,
      SearchBarActions.setSearchTerm.type,
      PaginationActions.setPage.type,
      PaginationActions.setPageSize.type,
      DropdownActions.setFieldValue.type
    ],
    search
  );
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchRefreshAfterSaving),
    // fork(watchResetPagination),
    fork(watchSearch)
  ]);
};

export default rootSaga;
