import * as R from "ramda";
import moment from "moment-timezone";

export const mapEvents = ({ events, timezone }) =>
  R.map(
    event => ({
      id: R.path(["calendar", "id"], event),
      title: R.path(["calendar", "name"], event),
      backgroundColor: R.path(["calendar", "color"], event),
      borderColor: R.path(["calendar", "color"], event),
      thumbnailUrl: R.path(["calendar", "image_url"], event),
      start: moment
        .tz(R.path(["calendar", "date_from"], event), "YYYY-MM-DD", timezone)
        .utc()
        .format(),
      end: R.path(["calendar", "date_to"], event),
      place: R.pathOr("", ["calendar", "venue"], event),
      tags: R.map(R.prop("value"), R.pathOr([], ["calendar", "tags"], event))
    }),
    events
  );
