import React from "react";

import { connect } from "react-redux";

import { actions, getters } from "Items/EditQuestionSetQuestionsModal";
import { capitalizeFirst } from "utils/General";

import { getQuestions } from "Items/EditQuestionSetQuestionsModal/selectors";

import {
  Div,
  BigClearInput,
  SearchIcon,
  Text0,
  SmallCheckbox,
  Text2
} from "components/Base";

import SelectedQuestions from "./SelectedQuestions";

const decorate = connect(
  state => ({
    questionsFilter: getters.questionsFilter(state),
    questions: getQuestions(state)
  }),
  {
    setQuestionsFilter: actions.setQuestionsFilter,
    toggleQuestion: actions.toggleQuestion
  }
);

const Questions = ({
  questionsFilter,
  setQuestionsFilter,
  questions,
  toggleQuestion
}) => (
  <Div width={1} height={1}>
    <Div
      height={60}
      width={1}
      bb={1}
      bc="neutral1"
      display="row.flex-start.center"
      px={2}
    >
      <BigClearInput
        LeftIcon={SearchIcon}
        onChange={value => setQuestionsFilter(value)}
        width={1}
        continuous
        value={questionsFilter}
        placeholder="Filter questions..."
      />
    </Div>
    <Div
      display="row.flex-start.center"
      width={1}
      style={{ height: "calc(100% - 60px)" }}
    >
      <Div
        width={467}
        height={1}
        bg="neutral0"
        p={2}
        style={{ overflow: "auto" }}
      >
        {questionsFilter && questionsFilter.length && !questions.length ? (
          <Div p={5}>No questions matched your search.</Div>
        ) : !questions.length ? (
          <Div p={5}>No questions have been added yet.</Div>
        ) : null}
        {questions.map(q => (
          <Div
            width={1}
            bra={1}
            p={1}
            shadow={1}
            mb={2}
            bg={{
              default: "white",
              hover: "neutral1"
            }}
            display="row.flex-start.center"
            onClick={() => toggleQuestion(q.id)}
          >
            <SmallCheckbox selected={q.selected} />
            <Div display="row.space-between.center" flex={1} pl={2}>
              <Div>
                <Text2 bold color="black">
                  {q.name}
                </Text2>
                <Div display="row.flex-start.center">
                  <Text0 color="neutral6" bold>
                    {`${capitalizeFirst(q.type)} `}
                  </Text0>
                  {q.is_internal ? (
                    <Text0 color="neutral6" bold ml={1}>
                      &middot; internal
                    </Text0>
                  ) : null}
                </Div>
              </Div>
              {/*
              <Text1 bold color="neutral4">
                Contains Price Modifiers
              </Text1>
              */}
            </Div>
          </Div>
        ))}
      </Div>
      <Div width={276} height={1}>
        <SelectedQuestions />
      </Div>
    </Div>
  </Div>
);

export default decorate(Questions);
