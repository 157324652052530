import React from "react";
import { mapProps, withProps, withState, withHandlers } from "utils/General";
import * as R from "ramda";
import {
  searchHandler,
  Div,
  FontIcon,
  Span,
  Dropdown,
  BigFilledButton,
  LeftArrowIcon,
  PeopleIcon,
  RightArrowIcon,
  SmallToggle,
  AddIcon,
  DragIcon,
  Text2,
  NotVisibleIcon,
  EditIcon,
  Type13Card,
  Text4,
  SearchIcon,
  BigShadedInput,
  MediumClearButton,
  Cards,
  Radio,
  LoadingIcon,
  AddListIcon,
  CloseIcon,
  CheckIcon
} from "components/Base";
import {
  Sidebar,
  Loading
} from "components/Event/FormsV2/Form/Views/V3_Edit/Shared/Shared";

const LIMITS = [0, 1, 2, 3, 4, 5, 10];
const mergeState = key => ({ updateSettings, ...props }) => value => {
  updateSettings({ ...props, [key]: value });
};

const commonActions = withHandlers({
  setLimit: mergeState("limit"),
  setCollectionMode: mergeState("collectionMode")
});

const defaultStateMapper = props => ({
  limit: props.limit || { enabled: false, value: 0 },
  collectionMode: props.collectionMode || "form",
  lookupEnabled: props.lookupEnabled || false,
  markAsPrimary: props.markAsPrimary || false,
  role: props.role || {
    enabled: false,
    type: "collect_for_each",
    value: undefined
  },
  defaultRecordType: undefined,
  ...props
});

const setupExistingBlockState = R.compose(
  withState(
    "customLimit",
    "setCustomLimit",
    props =>
      R.path(["limit", "value"], props) &&
      !LIMITS.includes(R.path(["limit", "value"], props))
  ),
  mapProps(defaultStateMapper),
  commonActions
);

const Toolbar = withProps({
  px: 3,
  height: 50,
  display: "row.flex-start.center",
  bg: "white",
  bb: 1,
  bc: "neutral3"
})(Div);

const HeaderField = ({
  title,
  description,
  onHide,
  onTitleChange,
  onDescriptionChange,
  noDrag
}) => (
  <Div
    bra={1}
    shadow={2}
    py={2}
    px={noDrag ? 3 : 1}
    pr={3}
    bg="white"
    mb={2}
    display="row.flex-start.center"
    className="hoverContainer"
  >
    {noDrag ? null : <DragIcon mr={2} />}
    <Div display="column" flex={1}>
      <Div
        display="row.space-between.center"
        fw={3}
        color="gray7"
        flex={1}
        mb={2}
      >
        Sub Form Heading
        {noDrag ? null : (
          <CloseIcon
            tooltip="Remove Section"
            color={{
              default: "gray6",
              hover: "gray7"
            }}
            className="showOnHover"
            size={20}
            onClick={onHide}
          />
        )}
      </Div>
      <Div mb={2}>
        <input
          placeholder="Add a title..."
          defaultValue={title}
          onBlur={e => onTitleChange(e.target.value)}
          style={{
            width: "100%",
            padding: 5,
            borderRadius: "3px",
            background: "#FFFFFF",
            border: "1.2px solid rgba(0, 0, 0, 0.12)",
            color: "#000",
            fontWeight: 600,
            fontSize: 14
          }}
        />
      </Div>
      <Div>
        <textarea
          placeholder="Add a description..."
          defaultValue={description}
          onBlur={e => onDescriptionChange(e.target.value)}
          style={{
            height: 50,
            width: "100%",
            borderRadius: "3px",
            background: "#FFFFFF",
            border: "1.2px solid rgba(0, 0, 0, 0.12)",
            color: "#000",
            fontSize: 12,
            padding: 5
          }}
        />
      </Div>
    </Div>
  </Div>
);

const FieldCard = ({
  required,
  title,
  onHide,
  onEdit,
  onToggleRequired,
  type,
  ...props
}) => {
  if (type === "section") {
    return (
      <HeaderField {...{ required, title, onHide, onEdit, type, ...props }} />
    );
  }

  return (
    <Div
      bra={1}
      shadow={2}
      className="hoverContainer"
      py={2}
      px={1}
      bg="white"
      mb={2}
      display="row.flex-start.center"
    >
      <DragIcon mr={2} />
      <Div fw={3} fs={3} color="gray7" flex={1}>
        {title}
        {required && (
          <Span pl={2} color="danger5">
            *
          </Span>
        )}
      </Div>
      <Div className="showOnHover" display="row.flex-start.center">
        {onToggleRequired ? (
          <CheckIcon
            tooltip="Toggle Required"
            mr={2}
            onClick={onToggleRequired}
          />
        ) : (
          ""
        )}
        {onEdit ? (
          <EditIcon tooltip="Edit Field" mr={2} onClick={onEdit} />
        ) : (
          ""
        )}
        {onHide ? (
          <NotVisibleIcon mr={1} onClick={onHide} tooltip="Hide this field" />
        ) : (
          ""
        )}
      </Div>
    </Div>
  );
};

const SortableFields = Cards(FieldCard);

export const PeopleLoading = ({}) => (
  <Sidebar>
    <Toolbar>
      <LeftArrowIcon sizeWFS={4} mr={3} onClick={() => {}} />
      <PeopleIcon sizeWFS={4} mr={2} />
      <Div width={100} height={15} bg="gray1" />
    </Toolbar>
    <Loading />
  </Sidebar>
);

const ModuleRow = ({ name, onClick, selected }) => (
  <Div
    bg="white"
    shadow={{
      default: 1,
      hover: 2
    }}
    bra={1}
    p={2}
    mb={2}
    onClick={onClick}
    display="row.flex-start.center"
  >
    <Radio selected={selected} mr={2} size={20} />
    <Div truncate color="gray7" fs={2} fw={3}>
      {name}
    </Div>
  </Div>
);

const SelectModule = searchHandler("modules", "modules")(
  ({ modules, onSearch, loading, saving, isValid, createSubformBlock }) => (
    <Div>
      <Div color="gray7" fw={3} fs={4}>
        What data do you want to collect?
      </Div>

      <BigShadedInput
        width={1}
        onChange={onSearch}
        continuous
        RightIcon={SearchIcon}
        mt={4}
        mb={2}
        placeholder="Search your available modules..."
      />

      {loading ? (
        <Div display="row.center.center" mt={5}>
          <LoadingIcon size={50} />
        </Div>
      ) : (
        R.map(m => <ModuleRow key={m.id} {...m} />)(modules)
      )}

      <BigFilledButton
        onClick={createSubformBlock}
        bg={isValid ? "altB5" : "neutral1"}
        mt={3}
      >
        {saving ? "Saving..." : "Add to form"}
        {saving ? null : <RightArrowIcon ml={4} color="white" />}
      </BigFilledButton>
    </Div>
  )
);

const EditSubform = ({
  moduleName,
  limit,
  setLimit,
  collectionMode,
  setCollectionMode,
  headerField,
  fields,
  onAddField,
  onAddSection,
  onFieldReorder,
  otherFields
}) => (
  <Div>
    <Div mb={5} mt={3}>
      <Text2 bold pb={2}>
        Collection Mode
      </Text2>
      <Dropdown
        value={collectionMode}
        onChange={({ value }) => setCollectionMode(value)}
        usePortal
        options={[
          {
            label: "Form & Table Mode",
            value: "card-and-table"
          },
          {
            label: "Form Only",
            value: "card"
          },
          {
            label: "Table Only",
            value: "table"
          }
        ]}
      />
    </Div>

    <Div display="row.space-between.center" mb={limit.enabled ? 2 : 5}>
      <Text2 bold>Allow more than one to be added</Text2>
      <SmallToggle
        active={limit.enabled}
        onClick={() =>
          setLimit({
            enabled: !limit.enabled,
            value: limit.enabled ? 0 : limit.value
          })
        }
      />
    </Div>

    {/*
    // @NOTE: Hiding until implemented - limits on # of rows
    {limit.enabled ? (
      <Div mt={1} display="row.flex-start.center" mb={5}>
        <Div width={1 / 2}>
          <Dropdown
            value={customLimit ? "custom" : limit.value}
            style={{ minWidth: "inherit" }}
            usePortal
            onChange={({ value }) => {
              if (value === "custom") {
                return setCustomLimit(true);
              }
              setCustomLimit(false);
              setLimit({
                enabled: true,
                value
              });
            }}
            options={[
              {
                label: "No Limit",
                value: 0
              },
              ...LIMITS.slice(1).map(val => ({
                label: `Limit of ${val}`,
                value: val
              })),
              {
                label: "Custom Limit",
                value: "custom"
              }
            ]}
          />
        </Div>
        {customLimit ? (
          <BigInsetInput
            value={limit.value}
            onChange={value => setLimit({ enabled: true, value: value || 1 })}
            width={1 / 2}
            ml={2}
          />
        ) : null}
      </Div>
    ) : null}
    */}

    <Div py={4}>
      <Text4 bold mb={3}>
        Showing {fields.length} Fields
      </Text4>
      <HeaderField noDrag {...headerField} />
      <SortableFields cards={fields} onReorder={onFieldReorder} />
    </Div>

    <Div>
      <Div
        display="row.flex-start.center"
        fw={3}
        mb={2}
        color={{
          default: "gray6",
          hover: "gray7"
        }}
        onClick={onAddField}
      >
        <AddIcon size={20} mr={1} /> Create new field in {moduleName}
      </Div>
      <Div
        display="row.flex-start.center"
        fw={3}
        color={{
          default: "gray6",
          hover: "gray7"
        }}
        onClick={onAddSection}
      >
        <AddIcon size={20} mr={1} /> Add a header
      </Div>

      <Div py={4} mt={3}>
        {otherFields.map(field => (
          <Type13Card
            key={field.id}
            name={field.name}
            Icon={withProps({
              children: field.icon,
              color: "neutral5",
              tooltip: "Add Field",
              fs: 5
            })(FontIcon)}
            onAdd={field.onAdd}
          />
        ))}
      </Div>
    </Div>
  </Div>
);

export const SubformBlock = ({
  onSettingsChange,
  settings,
  onReturn,
  onCancel,
  sceneId,
  modules,
  loading,
  createSubformBlock,
  saving,
  selectedModuleName,
  isValid,
  headerField,
  fields,
  onFieldReorder,
  onAddField,
  onAddSection,
  otherFields
}) => {
  const WrappedSetup = setupExistingBlockState(EditSubform);

  return (
    <Sidebar>
      <Toolbar>
        <LeftArrowIcon sizeWFS={4} mr={3} onClick={onReturn} />
        <AddListIcon sizeWFS={4} mr={2} />
        <Div fs={4} fw={3} color="gray7" flex={1}>
          {selectedModuleName || "Create Subform"}
        </Div>
        <MediumClearButton onClick={onCancel} color="primary5">
          Cancel
        </MediumClearButton>
      </Toolbar>

      <Div py={4} px={6}>
        {R.prop(sceneId)({
          selectModule: (
            <SelectModule
              {...{
                modules,
                loading,
                saving,
                createSubformBlock,
                isValid
              }}
            />
          ),
          editSubform: (
            <WrappedSetup
              headerField={headerField}
              fields={fields}
              onFieldReorder={onFieldReorder}
              updateSettings={onSettingsChange}
              moduleName={selectedModuleName}
              onAddField={onAddField}
              onAddSection={onAddSection}
              otherFields={otherFields}
              {...settings}
            />
          )
        })}
      </Div>
    </Sidebar>
  );
};
