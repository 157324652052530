import * as R from "ramda";
import { createHandlers, createModule } from "redux-mvc";

import { NAMESPACE } from "./constants";

const iniState = {
  selectedOptions: {},
  allIds: {}
};

const reducers = {
  init: (_, { payload: sections }) => {
    return {
      selectedOptions: R.reduce(
        (acc, section) => ({
          ...acc,
          [section.id]: section.iniSelected
        }),
        {},
        sections
      ),
      allIds: R.reduce(
        (acc, section) => ({
          ...acc,
          [section.id]: [section.allId]
        }),
        {},
        sections
      )
    };
  },
  toggleFilter: (
    { selectedOptions },
    {
      payload: id,
      meta: {
        option: { all },
        section: { id: parentId, allId = "" }
      }
    }
  ) => {
    if (all) {
      return {
        selectedOptions: R.assoc(parentId, [id], selectedOptions)
      };
    }
    const without = R.without(
      [id, allId],
      R.propOr([], parentId, selectedOptions)
    );

    return {
      selectedOptions: R.contains(id, R.propOr([], parentId, selectedOptions))
        ? {
            ...selectedOptions,
            [parentId]: R.isEmpty(without) ? [allId] : without
          }
        : {
            ...selectedOptions,
            [parentId]: R.uniq(
              R.concat(
                [id],
                R.without([allId], R.propOr([], parentId, selectedOptions))
              )
            )
          }
    };
  },
  clearFilters: ({ allIds }) => ({
    selectedOptions: allIds
  })
};

const handlers = createHandlers({ iniState, reducers, namespace: NAMESPACE });

const { actions, getters } = handlers;

const module = createModule(handlers);

export { actions, getters };

export default module;
