import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import constructPagination from "../../utils/construct-page-numbers";

@CSSModules(css)
class Pagination extends Component {
  renderPageNumber = number => {
    if (typeof number !== "number") {
      return <div className={css.ellipsis}>{number}</div>;
    }
    const { onPageChange, page } = this.props;
    return (
      <div
        onClick={() => onPageChange(number - 1)}
        className={number === page + 1 ? css.pageActive : css.page}
      >
        {number}
      </div>
    );
  };

  render() {
    const { canNext, canPrevious, page, pages, onPageChange } = this.props;
    if (pages <= 1) {
      return <div />;
    }
    return (
      <div className={css.container}>
        {canPrevious ? (
          <div onClick={() => onPageChange(page - 1)} className={css.button}>
            Prev
          </div>
        ) : (
          ""
        )}
        <div className={css.pageGroup}>
          {constructPagination({ pages, page }).map(number =>
            this.renderPageNumber(number)
          )}
        </div>
        {canNext ? (
          <div onClick={() => onPageChange(page + 1)} className={css.button}>
            Next
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

Pagination.propTypes = {};

export default Pagination;
