import React from "react";
import { connect } from "react-redux";
import { Div, Text2, Text3, CloseIcon, BigAvatar } from "components/Base";
import { getNotAccessUsers, getManagerRoles } from "../selectors";
import { UsersTable } from "./Tables";
import { withProps } from "utils/General";

const decorate = connect(state => ({
  notAccessUsers: getNotAccessUsers(state),
  managerRoles: getManagerRoles(state)
}));

const Well = withProps({
  width: 1,
  bra: 2,
  bg: "gray0",
  p: 4,
  display: "row.flex-start.center"
})(Div);

const NotAccess = ({ notAccessUsers, managerRoles, handlers }) => (
  <Div mt={2}>
    {notAccessUsers.length ? (
      <Div>
        <Well mt={4}>
          <BigAvatar Icon={CloseIcon} bg="danger5" />
          <Div ml={4}>
            <Text3 bold>These users do NOT have access</Text3>
            <Text2 bold>
              To grant them access, change their Permission Profile to one with
              access: {managerRoles}
            </Text2>
            <Text2>
              OR - create a new permission profile in: Settings > Users >
              Permissions
            </Text2>
          </Div>
        </Well>
        <UsersTable
          rowsData={notAccessUsers.map(m => ({
            ...m,
            onEdit: () => handlers.showEditUserModal(m)
          }))}
        />
      </Div>
    ) : null}
  </Div>
);

export default decorate(NotAccess);
