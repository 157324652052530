import React from "react";
import { connect } from "react-redux";

import { getDietaryRestrictions } from "../selectors";
import { actions } from "../model";

import { noop } from "utils/General";
import uuid from "node-uuid";

import OptionSelector from "components/Global/OptionSelector";
import DefaultHeader from "components/Global/OptionSelector/DefaultHeader";
import { Div } from "components/Base";

const decorate = connect(
  (state, props) => ({
    dietaryRestrictions: getDietaryRestrictions(state, props)
  }),
  {
    removeOption: actions.removeDietaryRestriction,
    updateOption: actions.updateDietaryRestriction
  }
);

const DietaryRestrictions = ({
  dietaryRestrictions = [],
  updateOption = noop,
  removeOption = noop
}) => (
  <Div p={5}>
    <OptionSelector
      options={dietaryRestrictions}
      placeholder="Add option..."
      handleUpdateOption={(_, option) =>
        updateOption({ ...option, id: option.id || uuid.v4() })
      }
      Header={<DefaultHeader title="Dietary restrictions" />}
      handleRemoveOption={(_, option) => removeOption(option.id)}
    />
  </Div>
);

export default decorate(DietaryRestrictions);
