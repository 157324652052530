import { put, takeEvery, all, fork, select, call } from "redux-saga/effects";
import { actions } from "Portal/PortalPagePGA/AllNewsAndAlertsList/model";

import { getCredentials } from "redux/modules/user/selectors";
import { registerError } from "redux/modules/errors/actions";
import { eventId as getEventId } from "redux/modules/portal/selectors";

import api from "Portal/PortalPagePGA/AllNewsAndAlertsList/api";

const init = function*() {
  try {
    yield put(actions.setLoading(true));

    const credentials = yield select(getCredentials);
    const eventId = yield select(getEventId);

    const { payload } = yield call(api.getAlerts, { credentials, eventId });

    yield put(actions.setInitialData(payload));
  } catch (error) {
    yield all([
      put(
        registerError([
          { system: error, user: "An error occurred fetching alerts" }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([fork(watchInit)]);
};

export default rootSaga;
