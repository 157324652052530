import PropTypes from "prop-types";
import React, { Component } from "react";
import TextArea from "react-textarea-autosize";
import getTemplate from "components/Event/FormsV2/Overview/SubmissionSummary/Feed/Utils/get-template";
import getBodyTemplate from "components/Event/FormsV2/Overview/SubmissionSummary/Feed/Utils/get-body-template";
import getColor from "components/Event/FormsV2/Overview/SubmissionSummary/Feed/Utils/get-color";
import Tab from "components/Global/CRM/Tabs/Tab";
import Tabs from "components/Global/CRM/Tabs";
import CSSModules from "react-css-modules";
import Item from "./Item";
import Avatar from "./Item/Avatar";
import Message from "./Item/Message";
import Body from "./Item/Body";
import Action from "./Item/Action";
import Timestamp from "./Item/Timestamp";
import css from "./styles.scss";

class Feed extends Component {
  componentWillMount() {
    this.fetchComments();
  }

  componentDidMount() {
    this.commentBody.scrollTop = this.commentBody.scrollHeight;
  }

  componentDidUpdate(oldProps) {
    if (oldProps.comments.length < this.props.comments.length) {
      this.commentBody.scrollTop = this.commentBody.scrollHeight;
    }
  }

  fetchActivity = () => this.props.getActivity(this.props.submissionId);
  fetchComments = () => this.props.getComments(this.props.submissionId);

  sendMessage = ({ keyCode, target }) => {
    if (keyCode === 13 && target.value.length) {
      const data = {
        comment: target.value,
        submissionId: this.props.submissionId
      };
      this.props
        .createComment(data)
        .then(() => {
          this.input.value = "";
        })
        .then(() => this.fetchComments());
    }
  };

  render() {
    const {
      activity,
      comments,
      commentsOnly,
      messagesFor,
      isFetchingActivity,
      isFetchingComments,
      user
    } = this.props;

    return (
      <div styleName="container">
        <Tabs>
          <Tab label="Messages" onActive={this.fetchComments}>
            <div>
              <div
                styleName="items"
                ref={ref => {
                  this.commentBody = ref;
                }}
              >
                {isFetchingComments && !comments.length ? (
                  <div styleName="loading">Loading messages...</div>
                ) : (
                  comments.map(item => (
                    <Item key={`${item.user.id}_${item.created_at}`}>
                      <Avatar color={getColor(item.user, user)} />
                      <Message>
                        <Action>{getTemplate(item, user)}</Action>
                        <Body>{getBodyTemplate(item)}</Body>
                        <Timestamp stamp={item.created_at} />
                      </Message>
                    </Item>
                  ))
                )}
              </div>
              <div styleName="sendMessage">
                <Avatar style={{ fontSize: 24, height: 35, width: 35 }} />
                <TextArea
                  type="text"
                  placeholder={`Send message to ${messagesFor}`}
                  onKeyDown={this.sendMessage}
                  ref={ref => {
                    this.input = ref;
                  }}
                />
              </div>
            </div>
          </Tab>
          {!commentsOnly ? (
            <Tab label="Activity" onActive={this.fetchActivity}>
              <div styleName="items">
                {isFetchingActivity && !activity.length ? (
                  <div styleName="loading">Loading activity...</div>
                ) : (
                  activity.map(item => (
                    <Item key={`${item.type}_${item.timestamp}`}>
                      <Avatar color={getColor(item.user, user)} />
                      <Message>
                        <Action>{getTemplate(item, user)}</Action>
                        <Timestamp stamp={item.timestamp} />
                      </Message>
                    </Item>
                  ))
                )}
              </div>
            </Tab>
          ) : null}
        </Tabs>
      </div>
    );
  }
}

const userPropShape = PropTypes.shape({
  id: PropTypes.number,
  email: PropTypes.string,
  fname: PropTypes.string,
  lname: PropTypes.string
});

Feed.propTypes = {
  activity: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      timestamp: PropTypes.string,
      user: userPropShape
    })
  ).isRequired,
  comments: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
      timestamp: PropTypes.string,
      user: userPropShape
    })
  ).isRequired,
  messagesFor: PropTypes.string.isRequired,
  commentsOnly: PropTypes.bool,
  createComment: PropTypes.func.isRequired,
  getActivity: PropTypes.func.isRequired,
  getComments: PropTypes.func.isRequired,
  isFetchingActivity: PropTypes.bool.isRequired,
  isFetchingComments: PropTypes.bool.isRequired,
  submissionId: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number
  }).isRequired
};

export default CSSModules(Feed, css);
