import React, { Component } from "react";
import { get } from "lodash";
import { Text2, Text5, Div } from "components/Base";
import { withProps, mapProps } from "utils/General";
import * as R from "ramda";
import About from "components/Global/CRM/About";
import RecordOwners from "components/Global/CRM/RecordOwners";
import CredentialOrders from "./Credentials";
import Contact from "./Contact";
import RelatedAccounts from "./RelatedAccounts";
import CanUserDo from "components/Global/Security/CanUserDo";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { CONTACTS } from "utils/standard-module-field-ids";
import ProfileView from "components/Global/ProfileView";
import RelatedModule from "components/Global/CRM/RelatedModule";
import { FILE_FIELDS } from "components/Event/Accounts/Account/constants";
import Forms from "./Forms";
import FileRequests from "EventLight/CRMProfile/FileRequests/View";

import { connect } from "react-redux";
import Activities from "Schedules/ScheduleProfileWidget";
import AccountActivityLogs from "Profile/Activities/View";

import { isEventFeatureEnabled } from "redux/modules/event/selectors";
import { FEATURES } from "utils/event-types-and-feature-constants";

const decorate = connect(state => ({
  canViewSchedules: isEventFeatureEnabled(state)(FEATURES.SCHEDULE)
}));

const ActionsPanel = withProps({ shadow: 2, bra: 1, p: 5, bg: "white" })(Div);
const ActionsLink = mapProps(({ disabled, onClick, ...rest }) => ({
  ...rest,
  onClick: disabled ? null : onClick,
  color: disabled ? "neutral4" : "primary5",
  mb: 3,
  bold: true
}))(Text2);
const moduleId = STANDARD_MODULE_IDS.contacts.id;

const ActionsTitle = withProps({ bold: true, mb: 3 })(Text5);

class ContactProfile extends Component {
  getReturnToUrl = () => {
    const { viaForm, viaAccount, viaDashboard } = this.props.location.query;
    const { eventId } = this.props.params;
    const isLight = this.props.eventDetails.is_light;

    if (typeof viaDashboard !== "undefined" && viaDashboard.length) {
      return isLight
        ? `/event-light/${eventId}/crm/manage/${moduleId}/type/${viaDashboard}`
        : `/event/${eventId}/module/${moduleId}/dashboard/type/${viaDashboard}`;
    } else if (typeof viaAccount !== "undefined") {
      return isLight
        ? `/event-light/${eventId}/account/${viaAccount}`
        : `/event/${eventId}/account/${viaAccount}`;
    } else if (typeof viaForm !== "undefined") {
      return isLight
        ? `/event-light/${eventId}/forms/${viaForm}/results`
        : `/event/${eventId}/forms-v2/${viaForm}/overview`;
    }

    return null;

    /*
    return isLight
      ? `/event-light/${eventId}/crm/manage/${moduleId}`
      : `/event/${eventId}/module/${moduleId}/dashboard`;
    */
  };

  getReturnToText = () => {
    const { viaForm, viaAccount, viaDashboard } = this.props.location.query;

    if (viaForm) return "Back to Form";
    if (viaAccount) return "Back to Group";
    if (viaDashboard) return "Back to Dashboard";
    return null;
  };

  render() {
    const {
      getFieldValue,
      dispatch,
      hideModal,
      eventDetails,
      router,
      params,
      showModal,
      name,
      showSnackbar,
      contact,
      addNote,
      showSendEmailModal,
      runReport,
      updateField,
      fetchProfile,
      showViewAllPropertiesModal,
      showAddRelationshipModal,
      showEditRelationshipModal,
      updateNote,
      deleteNote,
      isEnabledModule,
      showViewMoreModal,
      onRemoveRelationship,
      showChangeRecordTypeModal,
      showDeleteContactModal,
      goToView,
      activeView,
      readOnly,
      cando
    } = this.props;
    const { recordId, eventId } = params;
    const showDocumentRequests = eventDetails.enabled_modules.some(
      m => m.id === STANDARD_MODULE_IDS.documentRequests.id
    );

    const contactProps = {
      dispatch,
      hideModal,
      router,
      params,
      showModal,
      showSnackbar,
      showDeleteContactModal,
      showChangeRecordTypeModal
    };

    const aboutProps = {
      data: contact,
      eventDetails: R.pathOr(eventDetails, ["details"], this.props),
      name,
      updateField,
      readOnly,
      viewAllProperties: showViewAllPropertiesModal,
      showStarredFields: true
    };

    const cardProps = {
      contactName: name,
      contactEmail: getFieldValue(CONTACTS.EMAIL),
      account: contact.accounts ? R.head(contact.accounts) : null,
      params
    };
    let leftContent = [<Contact />, <About />];

    if (!R.isEmpty(contact)) {
      leftContent = [
        <Contact
          key="contact"
          isUser={Boolean(contact.userId)}
          data={contact.values}
          readOnly={readOnly}
          updateField={updateField}
          {...contactProps}
        />,
        <About key="about" {...aboutProps} />,
        <RelatedAccounts
          key="relAccounts"
          readOnly={readOnly}
          canViewAccounts={cando(`${STANDARD_MODULE_IDS.accounts.id}_read`)}
          addRelationship={showAddRelationshipModal}
          editRelationship={showEditRelationshipModal}
          accounts={contact.accounts || []}
          params={params}
          removeRelationship={onRemoveRelationship}
          viewMoreModal={showViewMoreModal}
        />,
        <RecordOwners
          key="owners"
          readOnly={readOnly}
          title="Record Owners"
          recordId={recordId}
          moduleId={moduleId}
          recordNameSingular="Person"
          recordNamePlural="People"
          onOwnersChange={fetchProfile}
          owners={get(
            this.props.contact,
            ["values", "owners", "value", "records"],
            []
          )}
        />
      ];
    }
    const tabs = [
      {
        name: "Overview",
        onClick: () => goToView(""),
        active: activeView === "overview"
      }
      /*
      {
        name: "Activity",
        onClick: () => goToView("/activity"),
        active: activeView === "activity"
      }
      */
    ];

    return (
      <ProfileView
        leftContent={leftContent}
        centerContent={R.prop(activeView, {
          overview: [
            <Forms
              key="forms"
              forms={contact.forms}
              fetchContact={fetchProfile}
              {...cardProps}
            />,
            showDocumentRequests ? (
              <FileRequests
                key="file-requests"
                mode="admin"
                moduleId={moduleId}
                recordId={recordId}
              />
            ) : null,

            <CredentialOrders key="cOrders" {...cardProps} />,
            this.props.canViewSchedules && (
              <Activities key="activities" recordId={recordId} />
            ),
            isEnabledModule(STANDARD_MODULE_IDS.files.id) && (
              <RelatedModule
                module={{
                  ...STANDARD_MODULE_IDS.files,
                  event_id: eventId,
                  preferences: {
                    visible_fields: FILE_FIELDS.map(f => f.id),
                    field_order: FILE_FIELDS.reduce((map, f, idx) => {
                      map[f.id] = idx;
                      return map;
                    }, {})
                  },
                  fields: {
                    fields: FILE_FIELDS
                  }
                }}
                record={{
                  id: recordId,
                  name,
                  valueName: "contactId",
                  moduleId: { moduleId },
                  moduleInternalName: "contacts",
                  values: []
                }}
              />
            ),

            ...R.pathOr([], ["related_modules"], contact).map(moduleDetails => (
              <RelatedModule
                key={`${moduleDetails.id}_${moduleDetails.lookup_field.id}`}
                module={moduleDetails}
                record={{
                  id: recordId,
                  name,
                  valueName: "contactId",
                  moduleId,
                  moduleInternalName: "contacts",
                  values: [
                    {
                      fieldId: moduleDetails.lookup_field.id,
                      value: {
                        type: "lookup",
                        value: {
                          moduleId,
                          records: [recordId]
                        }
                      }
                    }
                  ]
                }}
              />
            ))
          ].filter(s => s),
          activity: (
            <AccountActivityLogs contactId={recordId} eventId={eventId} />
          )
        })}
        rightContent={R.propOr(
          [
            <CanUserDo action={`${moduleId}_update`}>
              <ActionsPanel>
                <ActionsTitle>Actions</ActionsTitle>
                <ActionsLink
                  onClick={showSendEmailModal}
                  disabled={
                    R.isEmpty(getFieldValue(CONTACTS.EMAIL)) ||
                    !getFieldValue(CONTACTS.EMAIL)
                  }
                >
                  Send email
                </ActionsLink>
                <ActionsLink onClick={runReport}>Run report</ActionsLink>
              </ActionsPanel>
            </CanUserDo>
          ],
          activeView,
          {
            activity: []
          }
        )}
        showNotes={activeView === "overview"}
        notes={R.pathOr([], ["notes"], contact).map(n => ({
          note: n,
          authorUrl: `/event/${params.eventId}/contact/${n.author_contact_id}`,
          title: name || "contact",
          onUpdate: note => updateNote(note, n.id),
          onDelete: () => deleteNote(n.id)
        }))}
        returnToText={this.getReturnToText()}
        returnToPath={this.getReturnToUrl()}
        tabs={tabs}
        onAddNote={addNote}
      />
    );
  }
}

export default decorate(ContactProfile);
