import React from "react";
import { Div, DarkTab } from "components/Base";
const noop = () => {};

const Tabs = ({ tabs }) => {
  return (
    <Div
      bb={1}
      bc="gray2"
      mb={8}
      style={{
        padding: "35px 135px 0 135px"
      }}
    >
      {tabs.map(({ tabName, onClick, isActive }) => {
        return (
          <DarkTab
            key={tabName}
            px={2}
            fs={3}
            active={isActive}
            onClick={isActive ? noop : onClick}
          >
            {tabName}
          </DarkTab>
        );
      })}
    </Div>
  );
};

const Content = ({ children }) => (
  <Div
    style={{
      padding: "0 135px 50px 135px"
    }}
  >
    {children}
  </Div>
);

const Column = ({ children }) => (
  <Div display="column" width={540}>
    {children}
  </Div>
);

const Label = ({ children, ...props }) => (
  <Div fw={3} color="gray7" {...props}>
    {children}
  </Div>
);

const Input = ({ value, onChange, onBlur, ...props }) => (
  <Div bra={1} b={1} bc="neutral2" color="gray7" {...props}>
    <input
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      style={{
        border: 0,
        background: "none",
        backgroundColor: "#fff",
        width: "100%",
        height: 42,
        padding: "0px 12px"
      }}
    />
  </Div>
);

export { Tabs, Content, Column, Label, Input };
