import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import TypePicker from "components/Global/StandAloneEditors/Types";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import {
  ButtonGroup,
  Submit,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";
import FormElements from "components/Global/Modal/Layout/FormElements";

const { Label, Input, InputGroup } = FormElements;

@CSSModules(css)
class AddAccountModal extends Component {
  state = {
    name: null,
    type: null
  };

  addAccount = (e, options = {}) => {
    if (e) e.preventDefault();

    if (this.validateForm()) {
      this.props.addAccount({
        type: this.state.type,
        name: {
          type: "text",
          value: this.state.name
        }
      });
    }

    if (!options.keepOpen) {
      return this.props.hideModal();
    }
    this.setState({ name: null }, () => {
      this.input.focus();
    });
  };

  handleNameChange = e => {
    this.setState({ name: e.target.value });
  };

  handleTypeSelect = ({ id }) => {
    this.setState({ type: id || null });
  };

  validateForm = () => Boolean(this.state.name && this.state.type);

  accountName = () => {
    const type = this.props.accountTypes.find(t => t.id === this.state.type);
    return type && type.name ? type.name : type;
  };

  render() {
    return (
      <StyleWrapper
        hideModal={this.props.hideModal}
        heading="Create Group"
        width={410}
      >
        <span>
          <form onSubmit={this.addAccount}>
            <InputGroup>
              <Label>Name</Label>
              <Input
                type="text"
                ref={ref => {
                  this.input = ref;
                }}
                value={this.state.name}
                onChange={this.handleNameChange}
                autoFocus
              />
            </InputGroup>
            <InputGroup>
              <Label>Group Type</Label>
              <TypePicker
                value={this.accountName(this.state.type)}
                list={this.props.accountTypes}
                onSelect={this.handleTypeSelect}
              />
            </InputGroup>
            <ButtonGroup>
              <Submit title="Add Group" disabled={!this.validateForm()} />
              <ButtonOutline title="Cancel" onClick={this.props.hideModal} />
            </ButtonGroup>
          </form>
          <div
            key={this.state}
            styleName="actionLink"
            onClick={this.addAccount.bind(this, null, { keepOpen: true })}
            style={{ cursor: this.validateForm() ? "pointer" : "not-allowed" }}
          >
            Create and add another
          </div>
        </span>
      </StyleWrapper>
    );
  }
}

AddAccountModal.propTypes = {
  accountTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  ).isRequired,
  addAccount: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  params: PropTypes.shape({
    eventId: PropTypes.string
  }).isRequired
};

export default AddAccountModal;
