import * as R from "ramda";

const makeSum = filter => key =>
  R.compose(
    R.sum,
    R.compose(
      R.map(R.prop(key)),
      R.filter(filter)
    )
  );

export const sumApproved = makeSum(R.propEq("approval_status", "approved"))(
  "quantity"
);

export const sumRejected = makeSum(R.propEq("approval_status", "rejected"))(
  "quantity"
);

export const sumPending = makeSum(R.propEq("approval_status", "pending"))(
  "quantity"
);

export const sumRequested = makeSum(R.T)("quantity");
export const sumPrice = makeSum(R.T)("price");
