import React from "react";
import * as R from "ramda";
import {
  Div,
  Text1,
  TinyTextButton,
  AddIcon,
  TinyAndOrToggle,
  PopoverMenu,
  SmallFilledButton,
  Cards
} from "components/Base";
import { toClass } from "utils/General";

import { ApprovalsCard } from "./ApprovalsCard";

const AddTriggerInlineButton = toClass(({ onClick }) => (
  <TinyTextButton LeftIcon={AddIcon} color={"neutral7"} onClick={onClick}>
    Add Trigger
  </TinyTextButton>
));

const AddTriggerButton = toClass(({ onClick }) => (
  <SmallFilledButton
    mt={2}
    onClick={onClick}
    LeftIcon={AddIcon}
    bg={"primary7"}
  >
    Add Trigger
  </SmallFilledButton>
));

const AndOrSwitch = ({ isAnd, onClick, triggerMenuItems }) => {
  return (
    <Div py={1} display={"row.space-between.center"}>
      {isAnd ? (
        <Div flex={1} display={"row.center.center"}>
          <TinyAndOrToggle isAnd={isAnd} onClick={onClick} />
        </Div>
      ) : (
        <Div flex={1} display={"row.center.center"}>
          <Div height={2} bb={2} mx={2} flex={1} bc={"primary4"} />
          <TinyAndOrToggle isAnd={isAnd} onClick={onClick} />
          <Div height={2} bb={2} mx={2} flex={1} bc={"primary4"} />
        </Div>
      )}
      <PopoverMenu
        Label={AddTriggerInlineButton}
        menuItems={triggerMenuItems}
      />
    </Div>
  );
};

const TriggerCard = ({
  rows,
  title,
  quanta,
  menuItems,
  editTrigger,
  isLast = false,
  isAnd = false,
  toggleAndOr,
  triggerMenuItems
}) => {
  return (
    <Div>
      <ApprovalsCard
        rows={rows}
        title={title}
        quanta={quanta}
        editSelection={editTrigger}
        menuItems={menuItems}
      />
      {!isLast && (
        <AndOrSwitch
          isAnd={isAnd}
          onClick={toggleAndOr}
          triggerMenuItems={triggerMenuItems}
        />
      )}
    </Div>
  );
};

const TriggerCards = Cards(TriggerCard, "TRIGGER_CARDS");

const TriggersView = ({ triggers, reorderTriggers, addTriggerMenuItems }) => {
  return (
    <Div>
      <TriggerCards cards={triggers} onReorder={reorderTriggers} />
      <PopoverMenu
        wrapperProps={{ display: "row.flex-start.center" }}
        Label={AddTriggerButton}
        menuItems={addTriggerMenuItems}
      />
    </Div>
  );
};

const NoTriggersView = ({ menuItems }) => (
  <Div display={"column.flex-start.center"}>
    <Text1 bold>
      {
        "Items matching the criteria you specify here will be passed to the approval process below."
      }
    </Text1>
    <PopoverMenu Label={AddTriggerButton} menuItems={menuItems} />
  </Div>
);

export { TriggersView, NoTriggersView };
