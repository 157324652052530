import { createSelector } from "reselect";
import * as R from "ramda";

import { getters } from "./index";

export const getModules = createSelector(
  getters.selectedModuleIds,
  getters.modules,
  getters.modulesFilter,
  (selectedModuleIds, modules, filter) => {
    const filterToUse =
      filter && filter.length ? filter.toLowerCase().trim() : null;
    const results = modules.map(q => ({
      ...q,
      selected: selectedModuleIds.some(
        m => m.id === q.id && m.fieldId === q.lookup_field.id
      )
    }));
    if (filterToUse) {
      return R.sortBy(f => f.name.toLowerCase())(
        results.filter(q => q.name.toLowerCase().includes(filterToUse))
      );
    }
    return R.sortBy(f => f.name.toLowerCase())(results);
  }
);

export const getSelectedModules = createSelector(
  getters.selectedModuleIds,
  getters.modules,
  (selectedModuleIds, modules) => {
    return selectedModuleIds
      .map((m, idx) => ({
        ...(modules.find(
          q => q.id === m.id && q.lookup_field.id === m.fieldId
        ) || {}),
        order: idx
      }))
      .filter(q => q.id);
  }
);
