import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../model";
import UploadButton from "components/Atoms/UploadButton";
import File from "components/Event/FormsV2/Sections/FormFileUpload/File";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { noop } from "utils/General";

const decorate = R.compose(
  connect(
    state => ({
      files: getters.files(state)
    }),
    {
      showFilepicker: actions.showUploadFilesModal,
      deleteFile: actions.deleteFile
    }
  ),
  CSSModules(css)
);

const FileUpload = ({
  disabled = false,
  showFilepicker = noop,
  files = [],
  allowDownload = false,
  deleteFile = noop,
  column = {},
  placeholder = ""
}) => (
  <div styleName="fileUpload">
    {!disabled ? (
      <div styleName="attach">
        <UploadButton onClick={showFilepicker} />
      </div>
    ) : (
      ""
    )}
    {!files.length ? (
      <div styleName="empty">
        {R.pathOr(placeholder, ["settings", "placeholder"], column)}
      </div>
    ) : (
      ""
    )}
    {files.map((file, idx) => (
      <File
        key={file.id}
        id={file.id}
        allowDownload={allowDownload}
        mimetype={file.mimetype}
        disabled={disabled}
        url={file.url}
        filename={file.filename}
        deleteFile={() => deleteFile(idx)}
      />
    ))}
  </div>
);

export default decorate(FileUpload);
