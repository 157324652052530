import * as R from "ramda";
import { createHandlers } from "redux-mvc";

const model = createHandlers({
  iniState: {},
  reducers: {
    bulkEditDone: R.identity,
    approve: R.identity,
    deny: R.identity,
    reviewResponse: R.identity
  },
  namespace: "ItemBlockViewer"
});

const { actions, getters } = model;

export { actions, getters };

export default model;
