import React from "react";
import * as R from "ramda";
import { debounce } from "lodash";
import {
  Div,
  Text3,
  Text4,
  DownFilledIcon,
  Dropdown,
  BigOutlineInput
} from "components/Base";
import CollapsablePanel from "ui-kit/CollapsiblePanel";
import Wysiwyg from "components/Global/Wysiwyg";
import { noop } from "utils/General";

const SectionCard = ({
  name = "",
  description = "",
  updateField = noop,
  title = "",
  custom = "",
  updateTitle = noop,
  id = 0
}) => {
  const debounceOnChange = debounce(updateField, 250);

  return (
    <CollapsablePanel
      right={false}
      arrowIcon={DownFilledIcon}
      arrowColor="black"
      heading={
        <Div display="row.space-between.center" flex={1}>
          <Div display="row.flex-start.center">
            <Text4 color="black" bold>
              {custom || name}
            </Text4>
            {!R.isEmpty(custom) ? <Text4 ml={1}>({name})</Text4> : null}
          </Div>
          {/*
          <Div display="row.flex-start.center">
            {locked ? <LockIcon mr={2} color="black" /> : null}
            <SmallToggle
              active={isEnabled}
              onClick={ev => {
                ev.stopPropagation();
                if (!locked) {
                  toggleIsEnabled({
                    id,
                    isEnabled
                  });
                }
              }}
            />
          </Div>
            */}
        </Div>
      }
      bg="white"
      expandedBg="white"
      p={4}
      shadow={1}
      mb={3}
    >
      <Div pl={8} pt={3}>
        <Div display="row.space-between.center" mb={3}>
          <Text3 color="black" bold>
            Page Title
          </Text3>
          <Dropdown
            options={[
              {
                value: R.toLower(name),
                label: name
              },
              {
                value: "custom",
                label: "Custom"
              }
            ]}
            selected={title}
            onChange={({ value }) => updateTitle({ id, value })}
            width={500}
          />
        </Div>
        {title === "custom" ? (
          <Div display="row.space-between.center" mb={3}>
            <Text3 color="black" bold>
              Custom Title
            </Text3>
            <Div width={500}>
              <BigOutlineInput
                width={1}
                value={custom}
                onChange={value => updateField({ id, field: "custom", value })}
              />
            </Div>
          </Div>
        ) : null}
        <Div display="row.space-between.center" mb={1}>
          <Text3 color="black" bold>
            Welcome Message
          </Text3>
        </Div>
        <Wysiwyg
          id={id}
          text={description}
          onChange={value =>
            debounceOnChange({ id, field: "description", value })
          }
        />
      </Div>
    </CollapsablePanel>
  );
};

export default SectionCard;
