import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  isOpen: false,
  itemId: null,
  zoneGroups: [],
  defaultSelectedZones: []
};

const model = createHandlers({
  iniState,
  reducers: {
    openModal: R.identity,
    openZonesPicker: R.always({ isOpen: true }),
    closeModal: R.always({ isOpen: false }),
    initializeData: (
      _,
      { payload: { zoneGroups, itemId, defaultSelected } }
    ) => ({
      zoneGroups,
      itemId,
      defaultSelectedZones: defaultSelected
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
