import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Records from "./Records";
import can from "components/Global/Security";
import flattenFields from "components/Global/CRM/TableViews/utils/flatten-fields";
import sortRows from "components/Event/Module/utils/sort-rows";
import { isViewingAsOrg } from "utils/is-viewing-as-context";

import { addField, updateField } from "redux/modules/modules/fields/actions";
import {
  addFieldGroupField,
  deleteFieldGroupField,
  updateFieldGroupField
} from "redux/modules/modules/fieldGroupFields/actions";
import { addRecord, getRecords } from "redux/modules/modules/records/actions";
import { addValue, addValues } from "redux/modules/modules/values/actions";
import {
  deselectAllRows,
  clearSearch
} from "redux/modules/modules/records/settings/actions";
import { getDefaultLayout } from "redux/modules/modules/layout/actions";
import { getViews, updateView } from "redux/modules/modules/views/actions";
import { getModule } from "redux/modules/modules/module/actions";
import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";
import { hideModal, showModal } from "redux/modules/modal/actions";

import { orgDetails } from "redux/modules/organization/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import {
  moduleDetails,
  forms,
  preferences,
  fieldGroups
} from "redux/modules/modules/module/selectors";
import { views, activeView } from "redux/modules/modules/views/selectors";
import {
  records,
  fields,
  fetching
} from "redux/modules/modules/records/selectors";
import { references } from "redux/modules/entityReferences/selectors";
import {
  searchTerm,
  selectedRows,
  tableLayoutOptions
} from "redux/modules/modules/records/settings/selectors";
import { user, getCredentials } from "redux/modules/user/selectors";
import { userPermissionProfile } from "redux/modules/permissions/user-permission-profile/selectors";

import exportFactory from "components/Global/CRM/TableViews/HelperComponents/export-factory";
import searchFactory from "components/Global/CRM/TableViews/HelperComponents/SearchFactory";

import { replace } from "react-router-redux";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { recordTypes } from "redux/modules/modules/recordTypes/selectors";

import { getModulesWithCatalogItems } from "redux/modules/inventory/modules/actions";

const getContext = ({ params = {}, context = {} }) => ({
  ...params,
  ...context
});

function mapStateToProps(state, props) {
  const context = getContext(props);
  const activeViewToPass = activeView(
    state,
    context.moduleId,
    context.viewId,
    context.groupId
  );
  const fieldsToPass = fields(state, context.moduleId);
  const eventDetailsToPass = eventDetails(state);
  const referencesToPass = references(state);
  const sortedRecords = sortRows(
    records(state, context.moduleId),
    flattenFields(fieldsToPass),
    activeViewToPass,
    {
      eventDetails: eventDetailsToPass,
      references: referencesToPass
    }
  );

  // build contextual props based on org vs event
  let contextProps = {};
  if (isViewingAsOrg(context)) {
    contextProps = {
      baseUrl: `/organization/${context.orgId}/module/${context.moduleId}`,
      editFieldsUrl: `/organization/${context.orgId}/settings/module/${context.moduleId}`,
      moduleUrl: `/organization/${context.orgId}/module/${context.moduleId}`,
      viewUrl: `/organization/${context.orgId}/module/${context.moduleId}${
        context.view ? `/${context.view}` : ""
      }`,
      viewIdUrl: `/organization/${context.orgId}/module/${context.moduleId}${
        context.viewId ? `/view/${context.viewId}` : ""
      }`
    };
  } else {
    contextProps = {
      baseUrl: `/event/${context.eventId}/module/${context.moduleId}`,
      editFieldsUrl: `/event/${context.eventId}/settings/module/${context.moduleId}`,
      moduleUrl: `/event/${context.eventId}/module/${context.moduleId}/dashboard`,
      viewUrl: `/event/${context.eventId}/module/${context.moduleId}/dashboard`,
      viewIdUrl: `/event/${context.eventId}/module/${context.moduleId}/dashboard`
    };
  }

  return {
    context,
    ...contextProps,
    orgDetails: orgDetails(state),
    eventDetails: eventDetailsToPass,
    views: views(state, context.moduleId),
    activeView: activeViewToPass,
    fields: fieldsToPass,
    fieldGroups: fieldGroups(state, context.moduleId),
    forms: forms(state, context.moduleId),
    isFetchingRecords: fetching(state, context.moduleId),
    module: moduleDetails(state, context.moduleId),
    moduleSettings: tableLayoutOptions(state, context.moduleId),
    preferences: preferences(state, context.moduleId),
    readOnly: !can(
      userPermissionProfile(state, context.eventId, state.user.user.id)
    ).do(`${context.moduleId}_update`),
    records: sortedRecords,
    references: referencesToPass,
    selectedRows: selectedRows(state, context.moduleId),
    searchTerm: searchTerm(state, context.moduleId),
    user: user(state),
    userCredentials: getCredentials(state),
    groupTypes: recordTypes(state, STANDARD_MODULE_IDS.accounts.id) || [],
    contactTypes: recordTypes(state, STANDARD_MODULE_IDS.contacts.id) || []
  };
}

function mapDispatchToProps(dispatch, props) {
  const context = getContext(props);

  // build contextual actions based on org vs event
  let actions = {};
  if (isViewingAsOrg(context)) {
    const orgOpts = {
      options: {
        orgId: context.orgId
      }
    };
    actions = {
      getModule: moduleId => getModule({ moduleId, ...orgOpts }),
      getViews: moduleId => getViews({ moduleId, ...orgOpts }),
      getRecordTypes: moduleId => getRecordTypes({ moduleId, ...orgOpts }),
      getRecords: moduleId => getRecords({ moduleId, ...orgOpts })
    };
  } else {
    const eventOpts = {
      options: {
        eventId: context.eventId,
        accountRecordTypeId: context.groupId, // for views
        recordTypeId: context.groupId, // for records
        filterByAccountRecordTypeId: context.filterByAccountRecordTypeId, //
        filterByContactRecordTypeId: context.filterByContactRecordTypeId //
      }
    };
    actions = {
      getModule: moduleId => getModule({ moduleId, ...eventOpts }),
      getViews: moduleId => getViews({ moduleId, ...eventOpts }),
      getRecordTypes: moduleId => getRecordTypes({ moduleId, ...eventOpts }),
      getRecords: moduleId => getRecords({ moduleId, ...eventOpts })
    };
  }

  return bindActionCreators(
    {
      ...actions,
      showModal,
      hideModal,
      addField,
      addFieldGroupField,
      addRecord,
      addValue,
      addValues,
      deleteFieldGroupField,
      clearSearch,
      getDefaultLayout,
      updateField,
      updateFieldGroupField,
      updateView,
      deselectAllRows: () => deselectAllRows(context.moduleId),
      // showSidebar: () => showSidebar(context.moduleId),// TODO
      // hideSidebar: () => hideSidebar(context.moduleId)// TODO,
      replace,
      getModulesWithCatalogItems
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(searchFactory(exportFactory(Records))));
