import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE, PAGES } from "./constants";

const iniState = {
  selectedPage: PAGES.EVENT_DETAILS,
  loading: false,
  savingLoading: false,
  eventId: 0,
  isDraft: null,
  hasAccess: null,
  orgId: ""
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    getInitialData: R.identity,
    uploadLogoImg: R.identity,
    uploadHeaderImg: R.identity,
    cancelInstance: () => iniState
  }
});

export default handlers;
