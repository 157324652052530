export const SECTION_TYPES = [
  // {
  //  name: 'Section',
  //  type: 'section',
  //  icon: 'crop_free'
  // },
  {
    name: "Header",
    type: "header",
    icon: "short_text"
  },
  {
    name: "Question",
    type: "question",
    icon: "text_fields"
  },
  {
    name: "Requests Form",
    type: "subform",
    icon: "grid_on"
  },
  {
    name: "Image",
    type: "image",
    icon: "image"
  },
  {
    name: "Separator",
    type: "separator",
    icon: "line_style"
  }
];

export const CONTACT_MAPPABLE_FIELDS = [
  {
    id: "email",
    name: "Email",
    mappableFieldTypes: ["text", "email"]
  },
  {
    id: "mobile_phone",
    name: "Mobile Phone",
    mappableFieldTypes: ["text", "phone"]
  },
  {
    id: "home_phone",
    name: "Home Phone",
    mappableFieldTypes: ["text", "phone"]
  },
  {
    id: "website",
    name: "Website",
    mappableFieldTypes: ["text"]
  },
  {
    id: "title",
    name: "Title",
    mappableFieldTypes: ["text"]
  },
  {
    id: "address_street_line_1",
    name: "Address Street",
    mappableFieldTypes: ["text"]
  },
  {
    id: "address_street_line_2",
    name: "Address Street (Line 2)",
    mappableFieldTypes: ["text"]
  },
  {
    id: "address_city",
    name: "Address City",
    mappableFieldTypes: ["text"]
  },
  {
    id: "address_state",
    name: "Address State",
    mappableFieldTypes: ["text"]
  },
  {
    id: "address_zip",
    name: "Address Zip",
    mappableFieldTypes: ["text"]
  },
  {
    id: "address_country",
    name: "Address Country",
    mappableFieldTypes: ["text", "countries"]
  },
  {
    id: "avatar_url",
    name: "Avatar URL",
    mappableFieldTypes: ["text"]
  },
  {
    id: "catering",
    name: "Catering",
    mappableFieldTypes: ["catering"]
  },
  {
    id: "catering_dietary_restrictions",
    name: "Catering Dietary Restrictions",
    mappableFieldTypes: ["catering-dietary-restrictions"]
  }
];

export const QUESTION_TYPES = [
  {
    id: "text",
    name: "Single line text",
    settings: {
      mustBeChildField: false,
      canBeChildField: true,
      canBeParentField: false,
      canBeChildOfFieldTypes: ["all"]
    }
  },
  {
    id: "email",
    name: "Email",
    settings: {
      mustBeChildField: false,
      canBeChildField: true,
      canBeParentField: false,
      canBeChildOfFieldTypes: ["all"]
    }
  },
  {
    id: "phone",
    name: "Phone",
    settings: {
      mustBeChildField: false,
      canBeChildField: true,
      canBeParentField: false,
      canBeChildOfFieldTypes: ["all"]
    }
  },
  {
    id: "credentials",
    name: "Passes",
    settings: {
      mustBeChildField: false,
      canBeChildField: true,
      canBeParentField: false,
      canBeChildOfFieldTypes: ["contact"]
    }
  },
  {
    id: "countries",
    name: "Countries",
    settings: {
      mustBeChildField: false,
      canBeChildField: true,
      canBeParentField: false,
      canBeChildOfFieldTypes: ["all"]
    }
  },
  {
    id: "checkbox",
    name: "Checkbox",
    settings: {
      mustBeChildField: false,
      canBeChildField: true,
      canBeParentField: false,
      canBeChildOfFieldTypes: ["all"]
    }
  },
  {
    id: "radio",
    name: "Radio",
    settings: {
      mustBeChildField: false,
      canBeChildField: true,
      canBeParentField: false,
      canBeChildOfFieldTypes: ["all"]
    }
  },
  {
    id: "dropdown",
    name: "Dropdown",
    settings: {
      mustBeChildField: false,
      canBeChildField: true,
      canBeParentField: false,
      canBeChildOfFieldTypes: ["all"]
    }
  },
  {
    id: "textarea",
    name: "Multi-line text",
    settings: {
      mustBeChildField: false,
      canBeChildField: true,
      canBeParentField: false,
      canBeChildOfFieldTypes: ["all"]
    }
  },
  {
    id: "date",
    name: "Date",
    settings: {
      mustBeChildField: false,
      canBeChildField: true,
      canBeParentField: false,
      canBeChildOfFieldTypes: ["all"]
    }
  },
  {
    id: "time",
    name: "Time",
    settings: {
      mustBeChildField: false,
      canBeChildField: true,
      canBeParentField: false,
      canBeChildOfFieldTypes: ["all"]
    }
  },
  {
    id: "datetime",
    name: "Datetime",
    settings: {
      mustBeChildField: false,
      canBeChildField: true,
      canBeParentField: false,
      canBeChildOfFieldTypes: ["all"]
    }
  },
  {
    id: "file-upload",
    name: "File Upload",
    settings: {
      mustBeChildField: false,
      canBeChildField: true,
      canBeParentField: false,
      canBeChildOfFieldTypes: ["all"]
    }
  },
  {
    id: "form-contact-info",
    name: "Contact Reference - Recipient",
    settings: {
      mappableFields: []
        .concat(
          [
            {
              id: "full_name",
              name: "Full Name",
              mappableFieldTypes: ["text"]
            },
            {
              id: "fname",
              name: "First Name",
              mappableFieldTypes: ["text"]
            },
            {
              id: "lname",
              name: "Last Name",
              mappableFieldTypes: ["text"]
            }
          ],
          exports.CONTACT_MAPPABLE_FIELDS
        )
        .filter(f => f.id !== "email")
    }
  },
  {
    id: "form-contact-reference",
    name: "Contact Reference - New Contact",
    settings: {
      mustBeChildField: false,
      canBeChildField: false,
      canBeParentField: true,
      canBeChildOfFieldTypes: [],
      mappableFields: [].concat(
        [
          {
            id: "full_name",
            name: "Full Name",
            mappableFieldTypes: ["text"]
          },
          {
            id: "fname",
            name: "First Name",
            mappableFieldTypes: ["text"]
          },
          {
            id: "lname",
            name: "Last Name",
            mappableFieldTypes: ["text"]
          }
        ],
        exports.CONTACT_MAPPABLE_FIELDS
      )
    }
  },
  {
    id: "form-submitter-reference",
    name: "Submitter Reference",
    settings: {
      mustBeChildField: false,
      canBeChildField: false,
      canBeParentField: true,
      canBeChildOfFieldTypes: [],
      mappableFields: []
    }
  }
];

export const GRID_COLUMN_TYPES = [
  {
    id: "text",
    name: "Text",
    settings: {
      mustBeChildField: false,
      enableReadOnly: true,
      canBeChildField: true,
      canBeParentField: false,
      canBeChildOfFieldTypes: ["all"]
    }
  },
  {
    id: "number",
    name: "Number",
    settings: {
      mustBeChildField: false,
      canBeChildField: true,
      canBeParentField: false,
      canBeChildOfFieldTypes: ["all"]
    }
  },
  {
    id: "dropdown",
    name: "Dropdown",
    settings: {
      mustBeChildField: false,
      enableMultipleSelect: true,
      canBeChildField: true,
      canBeParentField: false,
      canBeChildOfFieldTypes: ["all"]
    }
  },
  {
    id: "date",
    name: "Date",
    settings: {
      mustBeChildField: false,
      canBeChildField: true,
      canBeParentField: false,
      canBeChildOfFieldTypes: ["all"]
    }
  },
  {
    id: "time",
    name: "Time",
    settings: {
      mustBeChildField: false,
      canBeChildField: true,
      canBeParentField: false,
      canBeChildOfFieldTypes: ["all"]
    }
  },
  {
    id: "checkbox",
    name: "Checkbox",
    settings: {
      mustBeChildField: false,
      canBeChildField: true,
      canBeParentField: false,
      canBeChildOfFieldTypes: ["all"]
    }
  },
  {
    id: "dependency",
    name: "Dependency",
    settings: {
      mustBeChildField: false,
      canBeChildField: true,
      canBeParentField: false,
      canBeChildOfFieldTypes: ["all"]
    }
  },
  {
    id: "inventory",
    name: "Inventory",
    settings: {
      mustBeChildField: false,
      canBeChildField: true,
      canBeParentField: true,
      canBeChildOfFieldTypes: ["contact"],
      mappableFields: []
    }
  },
  {
    id: "contact",
    name: "Contact",
    settings: {
      source: "form",
      mustBeChildField: false,
      canBeChildField: true,
      canBeParentField: true,
      canBeChildOfFieldTypes: ["contact", "inventory"],
      mappableFields: exports.CONTACT_MAPPABLE_FIELDS
    }
  },
  {
    id: "catering",
    name: "Catering",
    settings: {
      mustBeChildField: true,
      canBeChildField: true,
      canBeParentField: false,
      canBeChildOfFieldTypes: ["contact"]
    }
  },
  {
    id: "credentials",
    name: "Passes",
    settings: {
      mustBeChildField: true,
      canBeChildField: true,
      canBeParentField: false,
      canBeChildOfFieldTypes: ["contact"]
    }
  },
  {
    id: "event-days",
    name: "Event Dates",
    settings: {
      mustBeChildField: false,
      canBeChildField: true,
      canBeParentField: false,
      canBeChildOfFieldTypes: ["contact", "inventory"]
    }
  }
];

export const DEFAULT_APPROVAL_FIELD_APPROVE_REJECT = {
  id: "review",
  type: "action-approve-form-row",
  name: "Approval Status",
  key: "approve-reject",
  visible: true,
  editable: false,
  dontWatch: true,
  schema: {
    locked: true
  },
  width: 150,
  settings: {
    width: 150,
    readOnly: true
  }
};

export const DEFAULT_APPROVAL_FIELD_STATUS = {
  id: "review",
  type: "approval-status",
  name: "Status",
  key: "approval-status",
  visible: true,
  editable: false,
  dontWatch: true,
  schema: {},
  settings: {
    width: 100
  }
};

export const DEFAULT_APPROVAL_FIELD_HAS_CHANGES = {
  id: "795f3968-649b-48ed-82be-8786f130cb97",
  type: "has-changes",
  name: "Changes?",
  key: "has-changes",
  visible: true,
  editable: false,
  dontWatch: true,
  schema: {},
  settings: {
    width: 125
  }
};

export const DEFAULT_APPROVAL_FIELD_APPROVAL_NOTES = {
  id: "430639f1-adc1-4ae4-8506-0ad3f5c8a91a",
  type: "text",
  name: "Approval Notes",
  key: "approval-notes",
  visible: true,
  editable: true,
  dontWatch: true,
  schema: {},
  settings: {
    width: 185
  }
};

export const DEFAULT_APPROVAL_FIELD_VIEW_SUBMISSION = {
  id: "e91c882b-a0f5-4878-9adb-725418f6b059",
  type: "view-submission",
  name: "Submission",
  key: "view-submission",
  visible: true,
  editable: false,
  dontWatch: true,
  schema: {
    locked: true
  },
  settings: {
    width: 150,
    readOnly: true
  }
};
