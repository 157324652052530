import React from "react";

import { connect } from "react-redux";

import * as R from "ramda";

import { getters, actions } from "Items/AddQuestionModal";

import { getFilteredExistingSets } from "Items/AddQuestionModal/selectors";

import { addS } from "utils/General";

import {
  Div,
  BigOutlineInput,
  SearchIcon,
  SmallCheckbox,
  Text0,
  Text2,
  EigthSizeDotIcon
} from "components/Base";

const decorate = connect(
  state => ({
    existingSetsFilter: getters.existingSetsFilter(state),
    filteredSets: getFilteredExistingSets(state)
  }),
  {
    setExistingSetsFilter: actions.setExistingSetsFilter,
    toggleQuestionSet: actions.toggleQuestionSet
  }
);

const ExistingQuestionSets = ({
  existingSetsFilter,
  setExistingSetsFilter,
  filteredSets,
  toggleQuestionSet
}) => (
  <Div width={1}>
    <BigOutlineInput
      width={1}
      value={existingSetsFilter}
      onChange={value => setExistingSetsFilter(value)}
      continuous
      color="primary7"
      RightIcon={SearchIcon}
      placeholder="Filter question sets..."
    />
    {R.map(
      ({ name, count_of_questions, count_of_items, id, selected }) => (
        <Div
          width={1}
          bra={1}
          ba={1}
          bc={
            selected
              ? "primary7"
              : {
                  default: "neutral1",
                  hover: "neutral3"
                }
          }
          p={1}
          display="row"
          mt={1}
          key={id}
          onClick={() => {
            toggleQuestionSet(id);
          }}
        >
          <SmallCheckbox selected={selected} />
          <Div pl={2} flex={1}>
            <Text2 bold color="black">
              {name}
            </Text2>
            <Div display="row.flex-start.center" mt={1}>
              <Text0>{`${count_of_questions} question${addS(
                count_of_questions
              )}`}</Text0>
              <EigthSizeDotIcon />
              <Text0>{`Used on ${count_of_items} item${addS(
                count_of_items
              )}`}</Text0>
            </Div>
          </Div>
        </Div>
      ),
      filteredSets
    )}
  </Div>
);

export default decorate(ExistingQuestionSets);
