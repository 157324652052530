import { createSelector } from "reselect";
import * as R from "ramda";
import { noop } from "utils/General";

import { getters } from "./";

const findBy = (key, value) => R.find(R.propEq(key, value));

export const getTabs = createSelector(
  getters.activeTabValue,
  (_, props) => R.propOr([], "tabs", props),
  (activeTabValue, tabs) =>
    R.map(
      ({ onClick = noop, value, ...other }) => ({
        onClick: () => {
          if (activeTabValue === value) {
            noop();
          } else {
            onClick();
          }
        },
        active: activeTabValue === value,
        value,
        ...other
      }),
      tabs
    )
);

export const getActiveTab = createSelector(
  getters.activeTabValue,
  (_, props) => R.propOr([], "tabs", props),
  (activeTabValue, tabs) =>
    R.prop("component", findBy("value", activeTabValue)(tabs))
);
