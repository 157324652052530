import { compose } from "ramda";
import { createModule, createContext } from "redux-mvc";
import { withRouter } from "react-router";
import model from "./model";
import sagasModule from "./sagas";
import Layout from "./Layout";

const module = createModule({
  ...model,
  observedDomains: [
    "user",
    "modal",
    "event",
    "permissions",
    "organization",
    "portal",
    "@flopflip",
    "routing",
    "Data",
    "accounts",
    "modules"
  ]
});

module.setRootSaga(sagasModule);

const decorate = compose(
  withRouter,
  createContext({
    module,
    options: {
      persist: false
    }
  })
);

export default decorate(Layout);
