import React from "react";
import { Div, Text2 } from "components/Base";
import Avatar from "components/PortalV2/GroupManager/ScalableAvatar";
import { withProps } from "utils/General";

const Navbar = ({ children }) => (
  <Div
    display="row.space-between.center"
    py={2}
    px={5}
    position={["absolute", "fixed"]}
    width={1}
    style={{
      zIndex: 2,
      top: 0,
      left: 0,
      backgroundColor: "rgba(0, 0, 0, 0.4)"
    }}
  >
    {children}
  </Div>
);

const NavText = withProps({ color: "white" })(Text2);
const Navatar = withProps({ size: 21, ml: 2 })(Avatar);

export { Navbar, NavText, Navatar };
