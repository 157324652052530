import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "Portal/Settings/Page/model";
import Body from "./Body";
import AddFolderModal from "Portal/Settings/AddFolderModal/View";
import AddPageModal from "Portal/Settings/AddPageModal/View";
import { WIDTH } from "Portal/List/constants";
import { Div } from "components/Base";

const decorate = connect(
  state => ({
    showAddPageModal: getters.showAddPageModal(state),
    showAddFolderModal: getters.showAddFolderModal(state),
    selectedFolderId: getters.selectedFolderId(state),
    selectedPageId: getters.selectedPageId(state),
    folderName: getters.folderName(state)
  }),
  {
    closeAddPageModal: actions.closeAddPageModal,
    closeAddFolderModal: actions.closeAddFolderModal
  }
);

const Layout = ({
  showAddPageModal,
  closeAddPageModal,
  showAddFolderModal,
  closeAddFolderModal,
  selectedFolderId,
  selectedPageId,
  folderName
}) => (
  <div>
    {showAddPageModal ? (
      <AddPageModal hideModal={closeAddPageModal} id={selectedPageId} />
    ) : null}
    {showAddFolderModal ? (
      <AddFolderModal
        hideModal={closeAddFolderModal}
        id={selectedFolderId}
        folderName={folderName}
      />
    ) : null}

    <Div width={1} display="row.center.flex-start" mt={3} mb={5}>
      <Div
        width={1}
        display="column"
        style={{
          maxWidth: WIDTH
        }}
      >
        <Body />
      </Div>
    </Div>
  </div>
);

export default decorate(Layout);
