import {
  put,
  call,
  takeEvery,
  all,
  fork,
  select,
  take
} from "redux-saga/effects";
import * as R from "ramda";
import { defaultInstanceId } from "redux-mvc";

import { navigateTo } from "utils/General";

import { actions as DataActions } from "App/Data/model";
import { actions, getters } from "Organizations/Events";
import { actions as TableActions } from "ui-kit/Table/model";
import { actions as ApprovalsActions } from "ApproversLabel";

import {
  actions as ViewPickerActions,
  getters as ViewPickerGetters
} from "ui-kit/ViewPicker";

import {
  actions as SearchBarActions,
  getters as SearchBarGetters
} from "ui-kit/SearchBar";

import { actions as TimeTableActions } from "ui-kit/TimeTable/model";

import { getCurrentPage, getPageSize } from "Organizations/Events/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { getUserTimezone } from "redux/modules/user/selectors";
import { getSearch } from "App/Router/selectors";
import { registerError } from "redux/modules/errors/actions";
import {
  VIEWPICKER_INSTANCE_ID,
  EVENT_VIEWS,
  TABLE_INSTANCE_ID
} from "../constants";
import { GROUP_BY_COLUMN_ID } from "ui-kit/Table/constants";

import Api from "../api";

import tableSagas from "./table";
import actionsSagas from "./actions";

import { mapEvents } from "../utils";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);

  return {
    credentials,
    orgId
  };
};

const notResetPagination = [
  actions.setCurrentPage.type,
  actions.fetchData.type
];

const init = function*() {
  try {
    const { credentials, orgId } = yield call(getParams);

    let preferences = {};

    const [preferencesPayload] = yield all([
      call(Api.getActiveView, {
        credentials,
        orgId
      })
    ]);
    preferences = preferencesPayload.payload;

    yield all([
      put(
        ViewPickerActions.setActiveView(
          {
            activeViewId: preferences.id,
            activeViewName: preferences.name
          },
          {
            meta: {
              instanceId: VIEWPICKER_INSTANCE_ID
            }
          }
        )
      ),
      put(
        actions.setInitialData({
          preferences
        })
      )
    ]);

    const search = yield select(getSearch);

    yield all([
      put(actions.fetchData()),
      put(actions.fetchViews()),
      put(
        actions.setSelectedView(
          R.contains("calendar=1", search)
            ? EVENT_VIEWS.CALENDAR
            : EVENT_VIEWS.GRID
        )
      )
    ]);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred loading initializing view"
          }
        ])
      )
    ]);
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const getSearchParams = (state, actionType) => {
  const resetPagination = R.not(
    R.any(R.equals(actionType), notResetPagination)
  );

  const preferences = getters.preferences(state);
  const params = {
    view: getters.selectedTab(state),
    search: SearchBarGetters.searchTerm(state),
    preferences: JSON.stringify(preferences),
    page: resetPagination ? 1 : getCurrentPage(state) + 1,
    pageSize: getPageSize(state)
  };

  const selectedView = getters.selectedView(state);
  if (selectedView === EVENT_VIEWS.CALENDAR) {
    params.view = "all";
    params.mode = "calendar";
  }

  return params;
};

export const search = function*({ type, meta }) {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const selectedView = yield select(getters.selectedView);

  const state = yield select(R.identity);
  const data = getSearchParams(state, type);

  yield put(actions.setShowNoResults(false));
  yield put(actions.setLoading(true));

  try {
    const result = yield call(Api.search, credentials, orgId, data);

    if (selectedView === EVENT_VIEWS.CALENDAR) {
      const timezone = yield select(getUserTimezone);
      const records = mapEvents({ events: result.payload.records, timezone });
      yield all([
        put(TimeTableActions.setResources([{ id: "fakeResource", records }])),
        put(actions.receiveList(result.payload))
      ]);
    } else {
      const isGroupedBy = R.compose(
        R.not,
        R.isNil,
        R.prop("grouped_by")
      )(result.payload);

      const rows = isGroupedBy // fake group by column
        ? R.flatten(
            R.map(
              group =>
                R.map(
                  row => ({
                    ...row,
                    [GROUP_BY_COLUMN_ID]: R.propOr("", "title", group)
                  }),
                  group.records
                ),
              result.payload.records
            )
          )
        : result.payload.records;

      yield all([
        put(
          TableActions.setData(
            {
              canEditCells: false,
              columns: result.payload.fields,
              rows,
              columnWidths: result.payload.preferences.field_widths,
              groupBy: isGroupedBy ? GROUP_BY_COLUMN_ID : ""
            },
            {
              meta: {
                instanceId: TABLE_INSTANCE_ID
              }
            }
          )
        ),
        put(actions.receiveList(result.payload)),
        put(DataActions.addReferences(result.payload.references)),
        put(actions.setShowNoResults(R.length(rows) === 0))
      ]);
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred fetching data"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false, { meta }));
  }
};

const watchSearch = function*() {
  yield takeEvery(
    action =>
      action.type === SearchBarActions.setSearchTerm.type &&
      R.path(["meta", "instanceId"], action) === defaultInstanceId,
    search
  );
};

const watchClearSearch = function*() {
  for (;;) {
    const action = yield take(SearchBarActions.clearSearch.type);
    if (R.path(["meta", "instanceId"], action) === defaultInstanceId) {
      yield call(search, action);
    }
  }
};

const exportData = function*({ type, payload: contentType, meta }) {
  const credentials = yield select(getCredentials);
  const state = yield select(R.identity);
  const orgIdToUse = yield select(getOrgId);

  const data = getSearchParams(state, type);

  yield put(actions.setLoading(true, { meta }));
  try {
    const result = yield call(Api.export, {
      ...data,
      orgId: orgIdToUse,
      credentials,
      contentType
    });
    yield all([
      call(navigateTo, result.payload.url),
      put(actions.setLoading(false, { meta }))
    ]);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred exporting data"
          }
        ])
      ),
      put(actions.setLoading(false, { meta }))
    ]);
  }
};

const watchExport = function*() {
  yield takeEvery(actions.exportData.type, exportData);
};

const fetchViews = function*() {
  const credentials = yield select(getCredentials);
  const orgIdToUse = yield select(getOrgId);

  try {
    const { payload: views } = yield call(Api.getViews, {
      credentials,
      orgId: orgIdToUse
    });
    yield put(
      ViewPickerActions.setViews(views, {
        meta: {
          instanceId: VIEWPICKER_INSTANCE_ID
        }
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred gettings views"
        }
      ])
    );
  }
};

const watchFetchViews = function*() {
  yield takeEvery(actions.fetchViews.type, fetchViews);
};

const addView = function*({ payload: newViewData = {} }) {
  const credentials = yield select(getCredentials);
  const state = yield select(R.identity);
  const orgIdToUse = yield select(getOrgId);
  const preferences = getters.preferences(state);

  try {
    const { payload } = yield call(Api.addView, {
      credentials,
      orgId: orgIdToUse,
      name: newViewData.name
        ? newViewData.name
        : preferences.name && preferences.name.length
        ? preferences.id === "default"
          ? preferences.name
          : `${preferences.name} (Copy)`
        : "New View",
      visibleFields: preferences.visible_fields,
      fieldOrder: preferences.field_order,
      sortBy: preferences.sort_by,
      filters: preferences.filters,
      fieldWidths: preferences.field_widths,
      quickFilters: preferences.quick_filters,
      groupedBy: preferences.grouped_by
    });

    yield all([
      put(actions.addViewResponse(payload)),
      put(
        ViewPickerActions.addNewActiveView(payload, {
          meta: {
            instanceId: VIEWPICKER_INSTANCE_ID
          }
        })
      )
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred adding view"
        }
      ])
    );
  }
};

const watchAddView = function*() {
  yield takeEvery(ViewPickerActions.addView.type, addView);
};

const updateView = function*({ payload: data }) {
  const credentials = yield select(getCredentials);
  const state = yield select(R.identity);
  const orgIdToUse = yield select(getOrgId);
  const preferences = yield getters.preferences(state);

  try {
    yield call(Api.updateView, {
      credentials,
      orgId: orgIdToUse,
      viewId: preferences.id,
      ...data
    });
    yield put(actions.fetchViews());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating view"
        }
      ])
    );
  }
};

const watchUpdateView = function*() {
  yield takeEvery(
    [ViewPickerActions.updateView.type, ViewPickerActions.updateViewName.type],
    updateView
  );
};

const updateViewName = function*({ payload }) {
  yield put(actions.updateViewName(payload));
};

const watchUpdateViewName = function*() {
  yield takeEvery(ViewPickerActions.updateViewName.type, updateViewName);
};

const revertViewChanges = function*({ payload }) {
  yield put(actions.revertViewChanges(payload));
};

const watchRevertViewChanges = function*() {
  yield takeEvery(ViewPickerActions.revertViewChanges.type, revertViewChanges);
};

const saveViewChanges = function*() {
  const credentials = yield select(getCredentials);
  const state = yield select(R.identity);
  const orgIdToUse = yield select(getOrgId);
  const preferences = yield getters.preferences(state);

  try {
    if (preferences.id === "default") {
      const { payload } = yield call(Api.addView, {
        credentials,
        orgId: orgIdToUse,
        name: preferences.name,
        visibleFields: preferences.visible_fields,
        fieldOrder: preferences.field_order,
        sortBy: preferences.sort_by,
        filters: preferences.filters,
        fieldWidths: preferences.field_widths,
        quickFilters: preferences.quick_filters,
        groupedBy: preferences.grouped_by
      });

      yield all([
        put(
          ViewPickerActions.addNewActiveView(payload, {
            meta: {
              instanceId: VIEWPICKER_INSTANCE_ID
            }
          })
        ),
        put(actions.addViewResponse(payload))
      ]);
    } else {
      yield put(actions.saveViewChangesResponse(preferences));

      yield call(Api.updateView, {
        credentials,
        orgId: orgIdToUse,
        viewId: preferences.id,
        visibleFields: preferences.visible_fields,
        fieldOrder: preferences.field_order,
        sortBy: preferences.sort_by,
        filters: preferences.filters,
        fieldWidths: preferences.field_widths,
        quickFilters: preferences.quick_filters,
        groupedBy: preferences.grouped_by
      });
      yield put(actions.fetchViews());
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred saving view changes"
        }
      ])
    );
  }
};

const watchSaveViewChanges = function*() {
  yield takeEvery(ViewPickerActions.saveViewChanges.type, saveViewChanges);
};

const deleteView = function*({ payload: data }) {
  const credentials = yield select(getCredentials);
  const state = yield select(R.identity);
  const orgIdToUse = yield select(getOrgId);
  const preferences = yield getters.preferences(state);

  try {
    yield call(Api.deleteView, {
      credentials,
      orgId: orgIdToUse,
      viewId: preferences.id,
      ...data
    });

    const [activeViewResult] = yield all([
      call(Api.getActiveView, {
        credentials,
        orgId: orgIdToUse
      }),
      put(actions.fetchViews())
    ]);

    yield put(actions.selectActiveView(activeViewResult.payload));

    yield put(actions.fetchData());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred deleting view"
        }
      ])
    );
  }
};

const watchDeleteView = function*() {
  yield takeEvery(ViewPickerActions.deleteView.type, deleteView);
};

const selectView = function*({ payload: viewId }) {
  const credentials = yield select(getCredentials);
  const state = yield select(R.identity);
  const orgIdToUse = yield select(getOrgId);

  const views = ViewPickerGetters.views(state, {
    instanceId: VIEWPICKER_INSTANCE_ID
  });
  const view = views.find(v => v.id === viewId);
  yield put(actions.selectView(view));

  try {
    yield call(Api.selectView, {
      credentials,
      orgId: orgIdToUse,
      viewId
    });

    yield put(actions.fetchData());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred selecting view"
        }
      ])
    );
  }
};

const watchSelectView = function*() {
  yield takeEvery(ViewPickerActions.selectView.type, selectView);
};

const updateViewFields = function*({ payload: fields }) {
  yield put(
    actions.updateVisibleFields({
      visibleFields: fields.map(f => f.id),
      fieldOrder: fields.reduce((map, f, idx) => {
        map[f.id] = idx;
        return map;
      }, {})
    })
  );

  yield put(actions.fetchData());
};

const watchUpdateViewFields = function*() {
  yield takeEvery(actions.updateViewFields.type, updateViewFields);
};

const watch = function*() {
  yield takeEvery(
    [
      actions.setGroupedByField.type,
      actions.setCurrentPage.type,
      actions.setPageSize.type,
      actions.setSelectedFieldFilters.type,
      actions.setSelectedSortBy.type,
      actions.removeSelectedSortBy.type,
      actions.clearFilters.type,
      actions.removeSelectedFieldFilter.type,
      actions.setSelectedTab.type,
      actions.setSelectedView.type,
      ViewPickerActions.revertViewChanges.type,
      actions.fetchData.type,
      TableActions.refreshRecords.type,
      ApprovalsActions.reviewDone.type
    ],
    search
  );
};

const watchRefreshRecords = function*() {
  yield takeEvery(
    action => action.type === TableActions.refreshRecords.type,
    search
  );
};

const rootSaga = function*() {
  yield all([
    fork(tableSagas),
    fork(actionsSagas),
    fork(watchInit),
    fork(watchUpdateViewFields),
    fork(watchSearch),
    fork(watchRefreshRecords),
    fork(watchExport),
    fork(watchClearSearch),
    fork(watchFetchViews),
    fork(watchAddView),
    fork(watchDeleteView),
    fork(watchUpdateView),
    fork(watchUpdateViewName),
    fork(watchRevertViewChanges),
    fork(watchSelectView),
    fork(watchSaveViewChanges),
    fork(watch)
  ]);
};

export default rootSaga;
