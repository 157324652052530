import React from "react";
import Statuses from "ui-kit/Statuses";

import { noop } from "utils/General";

import { Modal, Text2, Text5, Text4, Div, Span } from "components/Base";

import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";

export const Paper = ({ children, ...props }) => (
  <Div
    shadow={2}
    mb={2}
    width={1}
    display="column.flex-start.stretch"
    bg="white"
    style={{ overflow: "initial" }}
    bra={2}
    {...props}
  >
    {children}
  </Div>
);

export const Header = ({
  title,
  caption = null,
  statuses,
  integrationStatuses
}) => (
  <Div display="row.space-between.center" p={3}>
    <Div display="row.flex-start.center">
      <Text4 bold>{title}</Text4>
      {caption}
    </Div>
    {(statuses && statuses.length) ||
    (integrationStatuses && integrationStatuses.length) ? (
      <Statuses
        display="row"
        statuses={statuses}
        integrationStatuses={integrationStatuses}
      />
    ) : null}
  </Div>
);

export const Summary = ({ title, caption = "", body, ...props }) => (
  <Div display="column.flex-start.flex-start" {...props}>
    <Div display="column.flex-start.flex-start">
      <Text5 bold>{title}</Text5>
      <Text2 bold>{caption}</Text2>
    </Div>
    {body}
  </Div>
);

export const ContactTypes = ({ contacts = [], ...props }) => (
  <Div display="column.flex-start.flex-start" {...props}>
    {contacts.map(contact => (
      <Text2 key={contact.contact_type}>
        {contact.contact_type}
        {": "}
        <Span fs={2} fw={3}>
          {contact.contact_type_count}
        </Span>
      </Text2>
    ))}
  </Div>
);

export const MiniModalWrapper = ({
  children,
  showModal = false,
  hideModal = noop,
  title = ""
}) => (
  <Modal isPortal background="translucent" isModalShowing={showModal}>
    <Div width={1}>
      <StyleWrapper
        heading={title}
        hideModal={hideModal}
        width={600}
        height={800}
        bodyStyles={{ padding: 0 }}
      >
        {children}
      </StyleWrapper>
    </Div>
  </Modal>
);
