import React from "react";
import { connect } from "react-redux";
import {
  Div,
  BigFilledButton,
  BigOutlineButton,
  SuperFilledButton,
  SuperOutlineButton,
  LoadingIcon,
  CheckIcon
} from "components/Base";
import MediaQuery from "react-responsive";
import { hasForm } from "../selectors";
import { withProps } from "utils/General";
import Tooltip from "components/Global/Tooltip";

const Checkmark = withProps({
  color: "white",
  size: 20,
  mr: 2
})(CheckIcon);

const ApprovedCheckmark = withProps({
  color: "altA6",
  size: 22,
  mr: 2
})(CheckIcon);

const Mobile = props => <MediaQuery {...props} maxWidth={820} />;
const Default = props => <MediaQuery {...props} minWidth={821} />;

import { actions, getters } from "Portal/PortalPreviewProfile/model";

const decorate = connect(
  state => ({
    hasForm: hasForm(state),
    creatingSubmission: getters.creatingSubmission(state),
    isApproved: getters.isApproved(state)
  }),
  {
    goToSubmission: actions.goToSubmission,
    approveProfile: actions.approveProfile
  }
);

const title = "Preview your profile";
const description =
  "This is a preview of how others will see your profile on the website. When you're happy with how your profile looks, click the approve button to publish it. You can still make changes after you profile is published.";

const Header = ({
  handlers,
  hasForm,
  goToSubmission,
  creatingSubmission,
  isApproved,
  approveProfile
}) => (
  <Div
    display="row.space-between.center"
    py={5}
    bg="neutral1"
    bra={1}
    style={{
      paddingLeft: "80px",
      paddingRight: "80px",
      // position: "sticky",
      // top: 0,
      borderBottom: "1px solid #ddd",
      width: "100%"
    }}
  >
    <Mobile>
      <Div
        display="column"
        style={{
          width: "100%",
          margin: "0px auto"
        }}
      >
        <Div
          display="column"
          style={{
            maxWidth: "620px"
          }}
          mb={3}
        >
          <Div fw={3} color="black" fs={4} mb={1}>
            {title}
          </Div>
          <Div fs={2} mb={3}>
            {description}
          </Div>
        </Div>
        <Div display="row.flex-start.center">
          {isApproved ? (
            <Tooltip tooltip="Your profile is approved and published">
              <Div display="row.flex-start.center" color="altA6" fw={4} mr={7}>
                <ApprovedCheckmark />
                Approved
              </Div>
            </Tooltip>
          ) : (
            <BigFilledButton
              bg="altA6"
              mr={3}
              onClick={() => {
                handlers.flagAsModified();
                approveProfile();
              }}
            >
              Approve
            </BigFilledButton>
          )}

          {hasForm ? (
            <BigOutlineButton
              mr={1}
              onClick={creatingSubmission ? () => {} : goToSubmission}
            >
              {creatingSubmission ? <LoadingIcon /> : "Edit Profile"}
            </BigOutlineButton>
          ) : null}
        </Div>
      </Div>
    </Mobile>
    <Default>
      <Div
        display="row.space-between.center"
        style={{
          width: "100%",
          maxWidth: "1325px",
          margin: "0px auto"
        }}
      >
        <Div
          display="column"
          style={{
            maxWidth: "620px"
          }}
        >
          <Div fw={3} color="black" fs={4} mb={1}>
            {title}
          </Div>
          <Div fs={3} mb={3}>
            {description}
          </Div>
        </Div>
        <Div display="row.flex-start.center" ml={10}>
          {isApproved ? (
            <Tooltip tooltip="Your profile is approved and published">
              <Div
                display="row.flex-start.center"
                color="altA6"
                fs={4}
                fw={4}
                mr={7}
              >
                <ApprovedCheckmark />
                Approved
              </Div>
            </Tooltip>
          ) : (
            <SuperFilledButton
              LeftIcon={Checkmark}
              bg="altA6"
              mr={3}
              onClick={() => {
                handlers.flagAsModified();
                approveProfile();
              }}
            >
              Approve
            </SuperFilledButton>
          )}
          {hasForm ? (
            <SuperOutlineButton
              mr={1}
              onClick={creatingSubmission ? () => {} : goToSubmission}
            >
              {creatingSubmission ? <LoadingIcon /> : "Edit Profile"}
            </SuperOutlineButton>
          ) : null}
        </Div>
      </Div>
    </Default>
  </Div>
);

export default decorate(Header);
