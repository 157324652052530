import React from "react";
import {
  Div,
  Text1,
  Text2,
  Text3,
  DragIcon,
  CloseIcon,
  ErrorIcon,
  Cards
} from "components/Base";

import { actions } from "Items/EditQuestionSetQuestionsModal";

import { connect } from "react-redux";

import { getSelectedQuestions } from "Items/EditQuestionSetQuestionsModal/selectors";

const decorate = connect(
  state => ({
    questions: getSelectedQuestions(state)
  }),
  {
    updateSelectedQuestionOrder: actions.updateSelectedQuestionOrder,
    toggleQuestion: actions.toggleQuestion,
    toggleRequiredQuestion: actions.toggleRequiredQuestion,
    removeAllSelectedQuestions: actions.removeAllSelectedQuestions
  }
);

const Question = ({ toggleRequiredQuestion, toggleQuestion, ...q }) => (
  <Div bra={1} bg="altB1" width={1} p={2} mt={2}>
    <Div display="row.space-between.center">
      <Div
        display="row.flex-start.center"
        style={{
          width: "calc(100% - 40px)"
        }}
      >
        <DragIcon />
        <Text2
          ml={2}
          color="black"
          bold
          style={{
            boxSizing: "border-box",
            width: "calc(100% - 30px)",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
        >
          {q.name}
        </Text2>
      </Div>
      <Div display="row.flex-start.center">
        <ErrorIcon
          mr={2}
          color={{
            default: q.required ? "danger7" : "gray4",
            hover: "danger7"
          }}
          onClick={() => toggleRequiredQuestion(q.id)}
          tooltip="Toggle if field is required"
        />
        <CloseIcon
          color={{
            default: "gray4",
            hover: "gray5"
          }}
          onClick={() => toggleQuestion(q.id)}
        />
      </Div>
    </Div>
  </Div>
);

const QuestionRows = Cards(Question, "QUESTION_SET_QUESTIONS");

const SelectedQuestions = ({
  questions,
  updateSelectedQuestionOrder,
  removeAllSelectedQuestions,
  toggleQuestion,
  toggleRequiredQuestion
}) => (
  <Div width={1} height={1} style={{ overflowY: "auto" }} p={3}>
    <Div display="row.space-between.center">
      <Text3 bold color="black">
        Selected Questions
      </Text3>
      <Text1 color="primary7" underline onClick={removeAllSelectedQuestions}>
        Remove All
      </Text1>
    </Div>
    <Div width={1} mt={1}>
      <QuestionRows
        cards={questions}
        onReorder={updateSelectedQuestionOrder}
        toggleQuestion={toggleQuestion}
        toggleRequiredQuestion={toggleRequiredQuestion}
      />
    </Div>
  </Div>
);

export default decorate(SelectedQuestions);
