import { put, takeEvery, select } from "redux-saga/effects";
import { actions } from "./model";
import { actions as TableActions } from "ui-kit/Table/model";

import {
  getSummaryValues,
  getAllAssignmentsRows,
  getSummaryFields,
  getAllFields
} from "./selectors";

import {
  SUMMARY_FIELDS_WIDTHS,
  ALL_FIELDS_WIDTHS,
  INSTANCE_SUMMARY_TABLE,
  INSTANCE_ALL_ITEMS_TABLE
} from "./constants";

const init = function*() {
  yield put(actions.setLoading(true));
  const rows = yield select(getSummaryValues);
  const allFieldsRows = yield select(getAllAssignmentsRows);
  const summaryFields = yield select(getSummaryFields);
  const allFields = yield select(getAllFields);

  yield put(
    TableActions.setData(
      {
        columns: summaryFields,
        rows: rows,
        columnWidths: SUMMARY_FIELDS_WIDTHS,
        canEditCells: false
      },
      {
        meta: {
          instanceId: INSTANCE_SUMMARY_TABLE
        }
      }
    )
  );

  yield put(
    TableActions.setData(
      {
        columns: allFields,
        rows: allFieldsRows,
        columnWidths: ALL_FIELDS_WIDTHS,
        canEditCells: false
      },
      {
        meta: {
          instanceId: INSTANCE_ALL_ITEMS_TABLE
        }
      }
    )
  );

  yield put(actions.setLoading(false));
};

const rootSaga = function*() {
  yield takeEvery(actions.init.type, init);
};

export default rootSaga;
