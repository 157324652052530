import React from "react";

import { connect } from "react-redux";

import { getters } from "../index";

import { HEIGHTS } from "../constants";

import { getSelectedModules } from "../selectors";

import { Div, BigFilledButton, BigOutlineButton } from "components/Base";

const decorate = connect(state => ({
  selectedModuleIds: getters.selectedModuleIds(state),
  selectedModules: getSelectedModules(state)
}));

const Footer = ({ hideModal, handlers, selectedModules }) => (
  <Div
    width={1}
    bt={1}
    bc="neutral1"
    display="row.space-between.center"
    px={4}
    height={HEIGHTS.FOOTER}
  >
    <BigFilledButton
      bg="altB4"
      color="white"
      onClick={() => handlers.onDone(selectedModules)}
    >
      Done
    </BigFilledButton>

    <BigOutlineButton width={121} onClick={hideModal}>
      Cancel
    </BigOutlineButton>
  </Div>
);

export default decorate(Footer);
