import PropTypes from "prop-types";
import Panel from "components/Global/Module/Management/Panel";
import { accounts } from "@lennd/value-types/src/constants/standard-modules";

class AssetReportPanel extends Panel {
  getDefaultRecordValues = () => {
    const { activeAccountId, fields } = this.props;
    const requesterField = fields.find(
      f => f.internal_name === "requester_company"
    );
    if (requesterField) {
      return {
        [requesterField.id]: {
          type: "lookup",
          value: {
            moduleId: accounts.id,
            records: [activeAccountId]
          }
        }
      };
    }
    return {};
  };

  goToModule = () => {
    const { router, eventId, parent } = this.props;
    return router.push({
      pathname: `/event/${eventId}/module/${parent.id}/dashboard`,
      query: {
        returnTo: `/event/${eventId}/module/${parent.id}/reports/assets`
      }
    });
  };
}

AssetReportPanel.propTypes = {
  activeAccountId: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      internal_name: PropTypes.string
    })
  )
};

export default AssetReportPanel;
