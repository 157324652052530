const ORDER_ITEMS_BLOCK_TYPE = "order-items";
const ORDER_CUSTOMER_BLOCK_TYPE = "order-customer";
const ORDER_FULFILLMENT_BLOCK_TYPE = "order-fulfillment";
const ORDER_DETAILS_BLOCK_TYPE = "order-details";
const PEOPLE_BLOCK_TYPE = "people";

export {
  ORDER_ITEMS_BLOCK_TYPE,
  ORDER_CUSTOMER_BLOCK_TYPE,
  ORDER_FULFILLMENT_BLOCK_TYPE,
  ORDER_DETAILS_BLOCK_TYPE,
  PEOPLE_BLOCK_TYPE
};
