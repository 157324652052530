import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import { getSummaryTabs, getSummaryActiveContent } from "../selectors";

import { SUMMARY_TAB_IDS } from "../constants";
import { DueDates, Summary as SummaryContent } from "./SummaryActiveContent";

import {
  BigOutlineButton,
  SegmentedLineIcon,
  Text3,
  FontIcon
} from "components/Base";
import { noop } from "utils/General";

const decorate = R.compose(
  connect(
    state => ({
      tabs: getSummaryTabs(state),
      activeContentRows: getSummaryActiveContent(state),
      activeTab: getters.activeSummaryTab(state)
    }),
    {
      viewAllReports: actions.goToViewAllReports,
      changeTab: actions.setActiveSummaryTab
    }
  ),
  CSSModules(css)
);

const Summary = ({
  viewAllReports = noop,
  tabs = [],
  activeContentRows = [],
  activeTab = null,
  changeTab = noop
}) => (
  <div styleName="summary">
    <BigOutlineButton
      LeftIcon={SegmentedLineIcon}
      mb={4}
      onClick={viewAllReports}
    >
      View All Reports
    </BigOutlineButton>

    <div styleName="summaryContainer">
      <div styleName="tabs">
        {R.map(
          ({ id, name, icon, isActive }) => (
            <div
              key={id}
              styleName="tab"
              style={
                isActive
                  ? { borderBottomColor: "#8d64d1", color: "#8d64d1" }
                  : {}
              }
              onClick={() => changeTab(id)}
            >
              <FontIcon color={isActive ? "#8d64d1" : "black"} fs={4} mr={2}>
                {icon}
              </FontIcon>
              <Text3 color={isActive ? "#8d64d1" : "black"}>{name}</Text3>
            </div>
          ),
          tabs
        )}
      </div>
      {React.createElement(
        R.propOr("div", activeTab, {
          [SUMMARY_TAB_IDS.DUE_DATES]: DueDates,
          [SUMMARY_TAB_IDS.SUMMARY]: SummaryContent
        }),
        {
          activeContentRows
        }
      )}
    </div>
  </div>
);

export default decorate(Summary);
