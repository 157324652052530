import React from "react";

import { THEMES, VARIANTS } from "ui-kit/Theme/constants";
import { makeThemeFn } from "ui-kit/Theme/utils";

import { getDisplayName } from "utils/General";

export const ThemeContext = React.createContext({
  theme: THEMES.PRIMARY,
  variants: VARIANTS.PRIMARY
});

export const WithThemeProvider = ({
  theme = THEMES.PRIMARY,
  variant = VARIANTS.PRIMARY
} = {}) => Component => {
  class WithThemeProvider extends React.Component {
    render() {
      const props = this.props;
      return (
        <ThemeContext.Provider
          value={{
            variant: props.variant || variant,
            theme: props.theme || theme
          }}
        >
          <Component {...props} />
        </ThemeContext.Provider>
      );
    }
  }

  WithThemeProvider.displayName = `WithThemeProvider(${getDisplayName(
    Component
  )})`;
  return WithThemeProvider;
};

export const WithThemeConsumer = ({ variant } = {}) => Component => {
  class WithThemeConsumer extends React.Component {
    render() {
      const props = this.props;
      if (!variant && !props.variant) {
        return (
          <ThemeContext.Consumer>
            {({ theme, variant }) => (
              <Component
                {...props}
                Th={makeThemeFn(variant || VARIANTS.PRIMARY, theme)}
              />
            )}
          </ThemeContext.Consumer>
        );
      }
      return (
        <ThemeContext.Consumer>
          {({ theme, variant: va }) => (
            <ThemeContext.Provider
              value={{ theme, variant: props.variant || variant || va }}
            >
              <Component
                {...props}
                Th={makeThemeFn(props.variant || variant || va, theme)}
              />
            </ThemeContext.Provider>
          )}
        </ThemeContext.Consumer>
      );
    }
  }

  WithThemeConsumer.displayName = `WithThemeConsumer(${getDisplayName(
    Component
  )})`;
  return WithThemeConsumer;
};
