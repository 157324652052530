import PropTypes from "prop-types";
import React, { Component } from "react";
import Logo from "components/Global/Buttons/Logo";

class SiteHeader extends Component {
  static propTypes = {
    headerClasses: PropTypes.string,
    mobileLinks: PropTypes.object,
    slogan: PropTypes.object,
    primaryLinks: PropTypes.object,
    search: PropTypes.object,
    headerLinks: PropTypes.object
  };

  componentDidMount() {
    /*
    let $body = $('body');
    let $appWrapper = $('.app-wrapper');
    let $menuItem = $('.menu-click');
    let $mobileNav = $('.base-header-mobile-nav');

    // Oh No!!!! JQuery!! This should be replaced with a react property
    $menuItem.click((event) => {
      $body.toggleClass('mobile-nav-open');
      return false;
    });

    // Since we are getting rid of app-wrapper, this should be replace with something a bit more reacty
    $appWrapper.click((event) => {
      if ($body.hasClass('mobile-nav-open')) {
        $body.removeClass('mobile-nav-open');
        return false;
      }
    });

    // This makes sure that the menu closes after clicking on a link in the mobile nav
    $mobileNav.click((event) => {
      $body.removeClass('mobile-nav-open');
    });
    */
  }

  render() {
    return (
      <div className="header-wrapper">
        <div className="base-header-mobile-nav">
          <div className="logo">
            <Logo />
          </div>

          {this.props.mobileLinks}
        </div>

        <div className={this.props.headerClasses}>
          <div className="base-header-logo">
            <Logo />
          </div>

          {this.props.mobileLinks ? (
            <div className="base-header-mobile-toggle">
              <a className="menu-click">
                <i className="fa fa-bars" />
                <span> Menu</span>
              </a>
            </div>
          ) : (
            ""
          )}

          {this.props.slogan}

          {this.props.primaryLinks}

          {this.props.search}

          {this.props.headerLinks}
        </div>
      </div>
    );
  }
}

export default SiteHeader;
