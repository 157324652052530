import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Button = ({ handleClick, label }) => (
  <div styleName="button" onClick={handleClick}>
    {label}
  </div>
);

Button.propTypes = {
  label: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string
  ]).isRequired,
  handleClick: PropTypes.func
};
export default CSSModules(Button, css);
