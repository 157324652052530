import React, { Component } from "react";
import { connect } from "react-redux";
import { actions } from "SalesPortal/TypesModal";
import salesPortalTypesModal from "SalesPortal/TypesModal";
import salesPortalTypesModalSagas from "SalesPortal/TypesModal/sagas";
import Layout from "./Layout";

salesPortalTypesModal.setRootSaga(salesPortalTypesModalSagas);

const decorate = connect(
  null,
  {
    getItemTypes: actions.getItemTypes
  }
);

class SalesPortalTypesModal extends Component {
  static defaultProps = {
    onDone: () => {}
  };

  componentDidMount() {
    this.props.getItemTypes();
  }

  render() {
    const handlers = {
      hideModal: this.props.hideModal,
      onDone: this.props.onDone
    };
    return <Layout handlers={handlers} />;
  }
}

export default decorate(SalesPortalTypesModal);
