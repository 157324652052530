import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import styles from "./styles.scss";

const Page = ({ children }) => <div styleName="container">{children}</div>;

Page.propTypes = {
  children: PropTypes.node
};

export default CSSModules(Page, styles);
