import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Locked = ({ locked = false }) => {
  if (locked) {
    return (
      <i className="material-icons" styleName="locked">
        locked
      </i>
    );
  }
  return "";
};

Locked.propTypes = {
  locked: PropTypes.bool
};

export default CSSModules(Locked, css);
