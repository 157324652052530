import React, { Component } from "react";
import View from "./View";
import * as R from "ramda";

class Controller extends Component {
  state = { loading: true };

  async componentDidMount() {
    await Promise.all([
      this.props.getFields({
        moduleId: this.props.relatedModuleId,
        options: {
          orgId: this.props.orgId,
          eventId: this.props.eventId
        }
      }),
      this.fetchVisibleFields()
    ]);

    this.setState({ loading: false });
  }

  fetchVisibleFields = () =>
    this.props.getRelatedModuleVisibleFields({
      orgId: this.props.orgId,
      eventId: this.props.eventId,
      layoutId: this.props.layoutId,
      moduleId: this.props.moduleId,
      relatedModuleId: this.props.relatedModuleId,
      options: {
        orgId: this.props.orgId,
        eventId: this.props.eventId,
        relatedFieldId: this.props.relatedFieldId
      }
    });

  onFieldsReorder = async fields => {
    await this.props.updateRelatedModuleVisibleFields({
      orgId: this.props.orgId,
      eventId: this.props.eventId,
      layoutId: this.props.layoutId,
      moduleId: this.props.moduleId,
      relatedModuleId: this.props.relatedModuleId,
      relatedFieldId: this.props.relatedFieldId,
      fieldIds: fields.map(field => field.id),
      options: {
        orgId: this.props.orgId,
        eventId: this.props.eventId
      }
    });

    return this.fetchVisibleFields();
  };

  onFieldToggle = async (fieldId, toggle) => {
    if (toggle) {
      await this.props.addRelatedModuleVisibleFields({
        orgId: this.props.orgId,
        eventId: this.props.eventId,
        layoutId: this.props.layoutId,
        moduleId: this.props.moduleId,
        relatedModuleId: this.props.relatedModuleId,
        relatedFieldId: this.props.relatedFieldId,
        fieldIds: [fieldId],
        options: {
          orgId: this.props.orgId,
          eventId: this.props.eventId
        }
      });
    } else {
      await this.props.removeRelatedModuleVisibleFields({
        orgId: this.props.orgId,
        eventId: this.props.eventId,
        layoutId: this.props.layoutId,
        moduleId: this.props.moduleId,
        relatedModuleId: this.props.relatedModuleId,
        relatedFieldId: this.props.relatedFieldId,
        fieldIds: [fieldId],
        options: {
          orgId: this.props.orgId,
          eventId: this.props.eventId
        }
      });
    }

    return this.fetchVisibleFields();
  };

  onAddSort = item => {
    this.onSortByUpdate([
      ...this.props.sortBy.filter(f => f.fieldId !== item.fieldId),
      item
    ]);
  };

  onRemoveSort = idx => {
    this.onSortByUpdate(this.props.sortBy.filter((f, i) => i !== idx));
  };

  onSortByUpdate = sortBy => {
    this.props.updateVisibleRelatedModule({
      eventId: this.props.eventId,
      layoutId: this.props.layoutId,
      moduleId: this.props.moduleId,
      bulk: true,
      relatedModules: [
        {
          id: this.props.relatedModuleId,
          fieldId: this.props.relatedFieldId,
          sortBy
        }
      ],
      options: {
        eventId: this.props.eventId
      }
    });
  };

  render() {
    const { hideModal, fields, sortBy } = this.props;
    const { loading } = this.state;

    const allFields = R.map(f => ({
      ...f,
      onRemove: () => this.onFieldToggle(f.id, false),
      onToggle: () => this.onFieldToggle(f.id, !f.isVisible)
    }))(fields);

    const sortedFields = R.sortBy(f => f.name.toLowerCase())(allFields);
    const visibleFields = R.compose(
      R.sortBy(R.prop("order")),
      R.filter(R.prop("isVisible"))
    )(allFields);

    return (
      <View
        {...{
          loading,
          hideModal,
          sortBy: sortBy
            ? sortBy.map(c => {
                const field = sortedFields
                  ? sortedFields.find(f => f.id === c.fieldId)
                  : null;
                return {
                  ...c,
                  name: field ? field.name : "(Deleted Field)"
                };
              })
            : [],
          visibleFields,
          allFields: sortedFields,
          onFieldsReorder: this.onFieldsReorder,
          onAddSort: this.onAddSort,
          onRemoveSort: this.onRemoveSort
        }}
      />
    );
  }
}

export default Controller;
