import { createSelector } from "reselect";
import { orgDetails } from "redux/modules/organization/selectors";
import * as R from "ramda";
import { getters } from "../model";

export const getOrgInitial = createSelector(
  orgDetails,
  orgDetails => (orgDetails.name ? orgDetails.name[0] : "")
);

export const getLogoImageUrl = createSelector(
  orgDetails,
  orgDetails => orgDetails.logo_image_url
);

const isActiveRoute = (routes, params, route, routeParams) => {
  const paramsMatch = routeParams ? R.whereEq(routeParams)(params) : true;

  if (Array.isArray(route)) {
    return (
      R.compose(
        Boolean,
        R.length,
        R.intersection(route),
        R.map(R.prop("name"))
      )(routes) && paramsMatch
    );
  }

  return routes.some(r => r.name === route) && paramsMatch;
};

export const getLinks = createSelector(
  getters.navigation,
  (_, props) => props.routes,
  (_, props) => props.params,
  getters.openLinks,
  (navigation, routes, params, openLinks) => {
    const links = navigation.links || [];

    // replace undefined values with null, so we can match more accurately
    let paramsWithStubs = R.map(v => (typeof v === "undefined" ? null : v))({
      recordTypeId: null,
      //
      ...params
    });

    return R.map(l => {
      return {
        ...l,
        selected: isActiveRoute(routes, paramsWithStubs, l.routes, l.params),
        open: openLinks.includes(l.id),
        options: R.map(o => {
          return {
            ...o,
            selected: isActiveRoute(routes, paramsWithStubs, o.routes, o.params)
          };
        })(l.options || [])
      };
    })(links);
  }
);
