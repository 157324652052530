import React from "react";
import { connect } from "react-redux";

import { Div, FontIcon } from "components/Base";

import Circle from "ui-kit/Circle";
import CollapseButton from "Modules/Dashboard/View/Sidebar/CollapseButton";

import { getters } from "Modules/Dashboard";

const decorate = connect(state => ({
  recordType: getters.recordType(state),
  module: getters.module(state)
}));

const Header = ({ recordType, module }) => (
  <Div display="row.flex-start.center" width={1} mb={5}>
    {recordType.id ? (
      <Div display="row.space-between.flex-start" width={1}>
        <Div display="row.flex-start.center">
          <Circle
            size={32}
            style={{
              backgroundColor: recordType.background_color,
              flexShrink: 0
            }}
          >
            <FontIcon
              fs={3}
              style={{
                color: recordType.text_color
              }}
            >
              {recordType.icon}
            </FontIcon>
          </Circle>
          <Div color="white" fw={3} fs={5} ml={2} mr={2}>
            {recordType.name}
          </Div>
        </Div>
        <CollapseButton />
      </Div>
    ) : module.id ? (
      <Div display="row.space-between.center" width={1}>
        <Div display="row.flex-start.center">
          <Circle
            size={32}
            style={{
              backgroundColor: module.color,
              flexShrink: 0
            }}
          >
            <FontIcon
              fs={3}
              style={{
                color: "white"
              }}
            >
              {module.icon}
            </FontIcon>
          </Circle>
          <Div color="white" fw={3} fs={5} ml={2} mr={2}>
            {module.name}
          </Div>
        </Div>
        <CollapseButton />
      </Div>
    ) : (
      <Div height={32} width={1}></Div>
    )}
  </Div>
);

export default decorate(Header);
