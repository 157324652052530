import React from "react";
import { connect } from "react-redux";

import { getters, actions } from "Orders/ImportModal";

import {
  getActiveFieldsToSelect,
  getImportSettings,
  getIniEnabledCustomFields
} from "Orders/ImportModal/selectors";

import { MATCH_OPTION } from "Orders/ImportModal/constants";

import {
  Div,
  CloseIcon,
  SmallToggle,
  Text1,
  Text4,
  Dropdown,
  Radio
} from "components/Base";

import MiniModalWrapper from "Orders/Common/View/MiniModalWrapper";

const decorate = connect(
  state => ({
    showModal: getters.handleDuplicatesModal(state),
    fields: getActiveFieldsToSelect(state),
    iniSelectedFields: getIniEnabledCustomFields(state),
    importSettings: getImportSettings(state)
  }),
  {
    hideModal: () => actions.setHandleDuplicatesModal(false),
    onDone: actions.setEnabledCustomFields,
    onClose: () => actions.setShowSelectFieldsModal(false),
    toggleMatchSetting: actions.toggleMatchSetting,
    toggleAllowImportSetting: actions.toggleAllowImportSetting,
    toggleUpateMatchingSetting: actions.toggleUpateMatchingSetting
  }
);

const HandleDuplicatesModal = ({
  showModal = false,
  hideModal,
  importSettings: {
    allowImportToCreateRecords,
    ifMoreThanOneMatchFound,
    updateMatchRecordValues
  },
  toggleMatchSetting,
  toggleAllowImportSetting,
  toggleUpateMatchingSetting
}) => (
  <MiniModalWrapper showModal={showModal} hideHeader>
    <Div p={5} pt={2}>
      <Div display="row.flex-end" mb={2}>
        <CloseIcon size={20} onClick={hideModal} />
      </Div>
      <Div display="row.space-between.center">
        <Text4 bold>Allow Import to Create New Records</Text4>
        <SmallToggle
          active={allowImportToCreateRecords}
          onClick={() => toggleAllowImportSetting()}
        />
      </Div>
      <Div color="neutral6" fs={2} fw={1} width={294}>
        We will warn you if any imported values do not match existing records
      </Div>
      <Div width={325} mt={4}>
        <Text4 bold mb={1}>
          Duplicate/Matching Validation
        </Text4>
        <Dropdown
          placeholder="Search & select fields to validate against"
          options={[]}
        />
      </Div>
      <Div width={294} my={4}>
        <Text4 bold mb={1}>
          If more than 1 match found:
        </Text4>
        <Div display="row.space-between.center">
          <Div display="row.flex-start.center">
            <Radio
              selected={ifMoreThanOneMatchFound === MATCH_OPTION.FIRST_MATCH}
              onClick={() =>
                toggleMatchSetting({
                  ifMoreThanOneMatchFound: MATCH_OPTION.FIRST_MATCH
                })
              }
            />
            <Text1 ml={1} bold>
              Use first match
            </Text1>
          </Div>
          <Div display="row.flex-start.center">
            <Radio
              selected={ifMoreThanOneMatchFound === MATCH_OPTION.WARN_ME}
              onClick={() =>
                toggleMatchSetting({
                  ifMoreThanOneMatchFound: MATCH_OPTION.WARN_ME
                })
              }
            />
            <Text1 ml={1} bold>
              Warn Me
            </Text1>
          </Div>
          <Div display="row.flex-start.center">
            <Radio
              selected={ifMoreThanOneMatchFound === MATCH_OPTION.SKIP_IMPORT}
              onClick={() =>
                toggleMatchSetting({
                  ifMoreThanOneMatchFound: MATCH_OPTION.SKIP_IMPORT
                })
              }
            />
            <Text1 ml={1} bold>
              Skip Import
            </Text1>
          </Div>
        </Div>
      </Div>
      <Div display="row.space-between.center" pt={4} bt={1} bc="neutral2">
        <Text4 bold>Update any matching record values</Text4>
        <SmallToggle
          active={updateMatchRecordValues}
          onClick={() => toggleUpateMatchingSetting()}
        />
      </Div>
      <Div color="neutral6" fs={2} fw={1} width={294}>
        We will warn you if any imported values do not match existing records
      </Div>
    </Div>
  </MiniModalWrapper>
);

export default decorate(HandleDuplicatesModal);
