import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import { NAMESPACE, TABS, GROUP_BY, CART_TABS } from "./constants";

const iniState = {
  activeSubmissionTab: {},
  addLoading: {},
  alerts: [],
  background_image_url: "",
  community_hub_description: null,
  community_hub_title: null,
  count_of_schedule_items: 0,
  description: "",
  dueDate: false,
  enable_community_hub: false,
  files: [],
  filters: GROUP_BY,
  links: [],
  loading: true,
  pages: [],
  peopleCount: 0,
  percent_complete: "",
  preferences: {
    field_widths: {}
  },
  recordId: null,
  scope: "home",
  sessions: [
    {
      id: 1,
      status: "pending",
      name: "The Cloud & You: Personalizing your customer's experience",
      open_label: "Pending",
      due_label: "Closes in 6 days",
      is_past_due: false
    },
    {
      id: 2,
      status: "approved",
      name: "Health Tech in 2025",
      open_label: "Approved",
      due_label: null,
      is_past_due: false
    }
  ],
  showDocumentRequestModal: false,
  submissionId: "",
  submissionsFilter: {},
  tabs: TABS,
  tasks: [],
  title: ""
};

const model = createHandlers({
  iniState,
  reducers: {
    init: (_, { payload }) => {
      return {
        scope: payload.scope || "home"
      };
    },
    addSubmission: R.identity,
    closeModal: () => ({
      showDocumentRequestModal: false,
      recordId: null
    }),
    executeAction: R.identity,
    selectFilters: (state, { payload }) => ({
      filters: R.map(
        filter => ({
          ...filter,
          selected: R.equals(filter.id, payload)
            ? R.not(filter.selected)
            : R.F()
        }),
        state.filters
      )
    }),
    setInitialData: (
      _,
      {
        payload: {
          alerts,
          background_image_url,
          community_hub_description,
          community_hub_title,
          count_of_schedule_items,
          description,
          enable_community_hub,
          files,
          links,
          pages,
          peopleCount,
          percent_complete,
          preferences,
          tableDataList,
          tasks,
          title
        }
      }
    ) => ({
      activeSubmissionTab: R.reduce(
        (acc, submission) => ({ ...acc, [submission.id]: CART_TABS.ALL }),
        {},
        tableDataList
      ),
      addLoading: R.reduce(
        (acc, submission) => ({ ...acc, [submission.id]: false }),
        {},
        tableDataList
      ),
      alerts,
      background_image_url,
      community_hub_description,
      community_hub_title,
      count_of_schedule_items,
      files,
      description,
      enable_community_hub,
      links,
      loading: false,
      pages,
      peopleCount,
      percent_complete,
      preferences,
      submissionsFilter: R.reduce(
        (acc, submission) => ({ ...acc, [submission.id]: "" }),
        {},
        tableDataList
      ),
      tasks,
      title
    }),
    setSubmissionsFilterData: R.identity,
    setSubmissionsTabFilter: R.identity,
    toggleDueDate: ({ dueDate }) => ({
      dueDate: R.not(dueDate)
    }),
    updateActiveSubmissionTab: (state, { payload: { id, value } }) => ({
      activeSubmissionTab: R.assoc(id, value, state.activeSubmissionTab)
    }),
    updateAddLoading: (state, { payload: { id, value } }) => ({
      addLoading: R.assoc(id, value, state.addLoading)
    }),
    updateSubmissionsFilter: (state, { payload: { id, value } }) => ({
      submissionsFilter: R.assoc(id, value, state.submissionsFilter)
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
