import React, { useEffect } from "react";
import { Div } from "components/Base";
import { connect } from "react-redux";
import { actions } from "../model";
import * as R from "ramda";
import { withRouter } from "react-router";

import {
  getSocialTitle,
  getSocialSubtitle,
  getSocialFeedId,
  getSocialFeedContainerId
} from "../selectors";

const decorator = R.compose(
  withRouter,
  connect(
    state => ({
      socialTitle: getSocialTitle(state),
      socialSubtitle: getSocialSubtitle(state),
      socialFeedId: getSocialFeedId(state),
      socialFeedContainerId: getSocialFeedContainerId(state)
    }),
    {
      showVideoModal: actions.showVideoModal
    }
  )
);

const SocialFeed = ({
  socialTitle,
  socialSubtitle,
  socialFeedId,
  socialFeedContainerId = "curator-feed-default-feed-layout",
  ...otherprops
}) => {
  useEffect(() => {
    if (socialFeedId) {
      (function() {
        var i,
          e,
          d = document,
          s = "script";
        i = d.createElement("script");
        i.async = 1;
        i.src = `https://cdn.curator.io/published/${socialFeedId}.js`;
        e = d.getElementsByTagName(s)[0];
        e.parentNode.insertBefore(i, e);
      })();
    }
  }, []);

  if (!socialFeedId) {
    return null;
  }

  return (
    <Div {...otherprops}>
      <Div display="row.space-between.center">
        <Div
          display="column"
          style={{
            marginBottom: "20px"
          }}
        >
          <Div
            color="#323232"
            style={{
              fontSize: "24px",
              fontFamily: "'Roboto', sans-serif",
              letterSpacing: "-0.26px",
              fontWeight: 500
            }}
          >
            {socialTitle}
          </Div>
          {socialSubtitle ? (
            <Div
              display="row"
              fs={2}
              color="gray5"
              mt={1}
              italics
              style={{
                fontFamily: "'Roboto', sans-serif",
                letterSpacing: "-0.15px"
              }}
            >
              {socialSubtitle}
            </Div>
          ) : null}
        </Div>
      </Div>
      <Div>
        <div id={socialFeedContainerId}>
          <a
            href="https://curator.io"
            target="_blank"
            className="crt-logo crt-tag"
            style={{
              display: "none"
            }}
          >
            Powered by Curator.io
          </a>
        </div>
      </Div>
    </Div>
  );
};

export default decorator(SocialFeed);
