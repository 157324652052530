/* eslint-disable no-underscore-dangle */
import { createSelector } from "reselect";
import * as R from "ramda";

import { cateringRequests } from "redux/modules/portal/reports/selectors";

const mapIndexed = R.addIndex(R.map);

const getGroupedAssignments = createSelector(
  cateringRequests,
  R.compose(
    R.groupBy(R.prop("groupedById")),
    R.map(assignmentRow => ({
      ...assignmentRow,
      groupedById: R.compose(
        R.last,
        R.split(" - ")
      )(assignmentRow.type)
    })),
    R.propOr([], "assignments")
  )
);

export const getAllAssignmentsRows = createSelector(
  getGroupedAssignments,
  R.compose(
    R.mapObjIndexed((assignmentRows, id) => ({
      id,
      rows: mapIndexed(
        (assignmentRow, idx) => ({
          id: `${id}-${idx}`,
          contactName: {
            type: "text",
            value: R.prop("contactName", assignmentRow)
          },
          type: {
            type: "text",
            value: R.prop("type", assignmentRow)
          },
          quantity: {
            type: "text",
            value: R.prop("quantity", assignmentRow)
          },
          details: {
            type: "text",
            value: R.prop("details", assignmentRow)
          },
          status: {
            type: "text",
            value: R.prop("status", assignmentRow)
          }
        }),
        assignmentRows
      )
    }))
  )
);

export const getMealSummaryValues = createSelector(
  cateringRequests,
  R.compose(
    summaryRequests =>
      R.compose(
        R.map(key => ({
          title: key,
          id: key,
          rows: R.compose(
            R.map(mealSummaryByDay => ({
              id: R.prop("id", mealSummaryByDay),
              name: {
                type: "text",
                value: R.prop("name", mealSummaryByDay)
              },
              requested: {
                type: "text",
                value: R.prop("requested", mealSummaryByDay)
              },
              approved: {
                type: "text",
                value: R.prop("approved", mealSummaryByDay)
              },
              rejected: {
                type: "text",
                value: R.prop("rejected", mealSummaryByDay)
              },
              pending: {
                type: "text",
                value: R.prop("pending", mealSummaryByDay)
              }
            })),
            R.sortBy(R.prop("item_sort_order"))
          )(summaryRequests[key])
        })),
        R.keys
      )(summaryRequests),
    R.propOr([], "counts")
  )
);

export const getSummaryFields = R.always([
  {
    id: "name",
    type: "text",
    name: "Item"
  },
  {
    id: "requested",
    type: "text",
    name: "Requested"
  },
  {
    id: "pending",
    type: "text",
    name: "Pending 🕑"
  },
  {
    id: "rejected",
    type: "text",
    name: "Rejected 🚫"
  },
  {
    id: "approved",
    type: "text",
    name: "Approved ✅"
  }
]);

export const getAllFields = R.always([
  {
    id: "contactName",
    type: "text",
    name: "Assigned To"
  },
  {
    id: "type",
    type: "text",
    name: "Item"
  },
  {
    id: "quantity",
    type: "text",
    name: "Quantity"
  },
  {
    id: "details",
    type: "text",
    name: "Details"
  },
  {
    id: "status",
    type: "order-status",
    name: "Status"
  }
]);
