import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "Schedules/SelectScheduleFieldsModal";
import { TABS } from "Schedules/SelectScheduleFieldsModal/constants";
import { Div } from "components/Base";

const decorate = connect(
  state => ({
    selectedTab: getters.selectedTab(state)
  }),
  {
    setSelectedTab: actions.setSelectedTab
  }
);

const Tabs = ({ selectedTab, setSelectedTab }) => (
  <Div display="row.flex-start.center" bb={1} bc="neutral1" mt={3} px={4}>
    <Div
      pb={1}
      fs={2}
      fw={3}
      color={selectedTab === TABS.ALL ? "primary7" : "black"}
      bb={2}
      bc={selectedTab === TABS.ALL ? "primary7" : "transparent"}
      onClick={() => setSelectedTab(TABS.ALL)}
    >
      Schedule Fields
    </Div>
  </Div>
);

export default decorate(Tabs);
