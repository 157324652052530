import { bindActionCreators } from "redux";
import { uniq, pathOr } from "ramda";
import { connect } from "react-redux";
import Controller from "./Controller";

import { hideModal } from "redux/modules/modal/actions";
import { itemBlocks } from "redux/modules/items/item-blocks/selectors";
import { getItemBlocks } from "redux/modules/items/item-blocks/actions";
import { eventDetails } from "redux/modules/event/selectors";
import { permissionSet } from "redux/modules/accounts/permissionSets/permissionSet/selectors";

function mapStateToProps(state, { permissionSetId, contactRecordTypeId }) {
  return {
    itemBlocks: itemBlocks(state),
    existingAssignmentIds: uniq(
      pathOr([], ["item_blocks"], permissionSet(state, permissionSetId))
        .filter(i => i.record_type_id === contactRecordTypeId)
        .map(i => i.block_id)
    ),
    eventId: eventDetails(state).id
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getItemBlocks,
      hideModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controller);
