import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import FormElements from "components/Global/Modal/Layout/FormElements";

const { InputGroup, Label, Input } = FormElements;

const Address = CSSModules(
  ({
    fname,
    lname,
    addressLine1,
    addressLine2,
    addressCity,
    addressState,
    addressCountry,
    phone,
    updateFname,
    updateLname,
    updateAddressLine1,
    updateAddressLine2,
    updateAddressCity,
    updateAddressState,
    updateAddressCountry,
    //
    // useBillingAddress,
    requireShippingInformation,
    //
    updateShippingAddressLine1,
    updateShippingAddressLine2,
    updateShippingAddressCity,
    updateShippingAddressState,
    updateShippingAddressZip,
    updateShippingAddressCountry,
    shippingAddressLine1,
    shippingAddressLine2,
    shippingAddressCity,
    shippingAddressState,
    shippingAddressZip,
    shippingAddressCountry,
    //
    updatePhone
  }) => (
    <>
      <div styleName="container">
        <div styleName="heading">Billing Information</div>
        <div styleName="info">
          To prevent this order from being flagged as fraudulent, please provide
          as much billing information as possible.
        </div>
        <div styleName="groups">
          <InputGroup className={css.group}>
            <Label>
              <div styleName="label">First Name</div>
            </Label>
            <Input onChange={updateFname} type="text" value={fname} />
          </InputGroup>
          <InputGroup className={css.group}>
            <Label>
              <div styleName="label">Last Name</div>
            </Label>
            <Input onChange={updateLname} type="text" value={lname} />
          </InputGroup>
        </div>
        <div styleName="groups">
          <InputGroup className={css.group}>
            <Label>
              <div styleName="label">Address</div>
            </Label>
            <Input
              onChange={updateAddressLine1}
              type="text"
              value={addressLine1}
            />
          </InputGroup>
          <InputGroup className={css.group}>
            <Label>
              <div styleName="label">Address Line 2</div>
            </Label>
            <Input
              onChange={updateAddressLine2}
              type="text"
              value={addressLine2}
            />
          </InputGroup>
          <InputGroup className={css.group}>
            <Label>
              <div styleName="label">City</div>
            </Label>
            <Input
              onChange={updateAddressCity}
              type="text"
              value={addressCity}
            />
          </InputGroup>
        </div>
        <div styleName="groups">
          <InputGroup className={css.group}>
            <Label>
              <div styleName="label">State</div>
            </Label>
            <Input
              onChange={updateAddressState}
              type="text"
              value={addressState}
            />
          </InputGroup>
          <InputGroup className={css.group}>
            <Label>
              <div styleName="label">Country</div>
            </Label>
            <Input
              onChange={updateAddressCountry}
              type="text"
              value={addressCountry}
            />
          </InputGroup>
          <InputGroup className={css.group}>
            <Label>
              <div styleName="label">Phone</div>
            </Label>
            <Input onChange={updatePhone} type="text" value={phone} />
          </InputGroup>
        </div>
      </div>
      {requireShippingInformation ? (
        <div styleName="shippingContainer">
          <div styleName="heading">Shipping Information (Required)</div>
          <div styleName="groups">
            <InputGroup className={css.group}>
              <Label>
                <div styleName="label">Address</div>
              </Label>
              <Input
                onChange={updateShippingAddressLine1}
                type="text"
                value={shippingAddressLine1}
              />
            </InputGroup>
            <InputGroup className={css.group}>
              <Label>
                <div styleName="label">Address Line 2</div>
              </Label>
              <Input
                onChange={updateShippingAddressLine2}
                type="text"
                value={shippingAddressLine2}
              />
            </InputGroup>
            <InputGroup className={css.group}>
              <Label>
                <div styleName="label">City</div>
              </Label>
              <Input
                onChange={updateShippingAddressCity}
                type="text"
                value={shippingAddressCity}
              />
            </InputGroup>
          </div>
          <div styleName="groups">
            <InputGroup className={css.group}>
              <Label>
                <div styleName="label">State</div>
              </Label>
              <Input
                onChange={updateShippingAddressState}
                type="text"
                value={shippingAddressState}
              />
            </InputGroup>
            <InputGroup className={css.group}>
              <Label>
                <div styleName="label">Postal Code</div>
              </Label>
              <Input
                onChange={updateShippingAddressZip}
                type="text"
                value={shippingAddressZip}
              />
            </InputGroup>
            <InputGroup className={css.group}>
              <Label>
                <div styleName="label">Country</div>
              </Label>
              <Input
                onChange={updateShippingAddressCountry}
                type="text"
                value={shippingAddressCountry}
              />
            </InputGroup>
          </div>
        </div>
      ) : null}
    </>
  ),
  css
);

export default Address;
