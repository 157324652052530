import * as STANDARD_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

export const NAMESPACE = "Schedule";

export const SCHEDULE_INSTANCE_ID = "schedule";
export const TABLE_INSTANCE_ID = "schedule";
export const VIEWPICKER_INSTANCE_ID = "schedule";
export const TABLE_VIEW_ID = "schedule-table";
export const SCHEDULES_FILTER_INSTANCE_ID = "schedules-filter";

export const DEFAULT_SELECTED_USER_PROFILES = {
  users: [],
  profiles: []
};

export const NORMAL_BUTTON = "single-button";
export const WITH_POPOVER_BUTTON = "with-popover-button";

export const QTY_FIELDS_GROUP_BY = 5;

const GROUP_BY_LIST_KEY = "none";
const GROUP_BY_DAY_KEY = "byDay";
const GROUP_BY_FIELD_KEY = "byField";
export const GROUP_BY_DATE_VALUE = STANDARD_FIELD_IDS.SCHEDULES.STARTS_AT;

export const GROUP_BY_BUTTONS = [
  {
    value: null,
    type: NORMAL_BUTTON,
    label: "List",
    id: GROUP_BY_LIST_KEY
  },
  {
    value: GROUP_BY_DATE_VALUE,
    type: NORMAL_BUTTON,
    label: "By Day",
    id: GROUP_BY_DAY_KEY
  },
  {
    value: "byField",
    type: WITH_POPOVER_BUTTON,
    label: "Group By",
    id: GROUP_BY_FIELD_KEY
  }
];

export const ROW_ACTIONS = {
  DUPLICATE: "duplicateRow",
  INSERT_BEFORE: "insertRowBefore",
  INSERT_AFTER: "insertRowAfter",
  REMOVE: "removeRow",
  OPEN_ACTIVITY: "openActivity"
};

export const SCHEDULE_VIEWS = {
  GRID: "grid",
  CALENDAR: "calendar"
};

export const ACTIVITY_FIELD_ID = "5e52bb94-e24e-4109-9a49-1efbc11d5697";
