import * as R from "ramda";
import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "./constants";

const iniState = {};

const reducers = {
  init: R.identity,
  bringAccess: R.identity
};

const model = createHandlers({ iniState, reducers, namespace: NAMESPACE });

const { actions, getters } = model;

export { actions, getters };

export default model;
