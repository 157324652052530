import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import subform from "Submission/Subform";
import sagas from "Submission/Subform/sagas";
import * as SubForm from "Submission/Subform/selectors";

import { withProps } from "utils/General";

import CollapsiblePanel from "ui-kit/CollapsiblePanel";

import { Text3, Div } from "components/Base";

import TableSearch from "ui-kit/Datagrid/View/Search";

import {
  actions as TableActions,
  getters as TableGetters
} from "ui-kit/Datagrid";
import Table from "ui-kit/Datagrid/View";

import { bindInstance } from "redux-mvc";

import { wsp, os } from "ui-kit/Theme";

subform.setRootSaga(sagas);

export const contextConfig = {
  module: subform,
  options: {
    observedDomains: [
      "user",
      "event",
      "organization",
      "portal",
      "formsV2",
      "@flopflip"
    ],
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", subform.modules))),
      R.prop("type")
    )
  }
};

const getHandlers = R.once(props => ({
  makeReview: props.makeReview,
  requestEdit: props.requestEdit
}));

const decorate = R.compose(
  connect(
    (state, props) => ({
      columns: SubForm.getColumns(state, props),
      headers: SubForm.getHeaders(state, props),
      rows: SubForm.getRows(state, props),
      fieldName: SubForm.getFieldName(state, props),
      meta: SubForm.getMetaData(state, props),
      menu: SubForm.getMenu(state, props),
      enabledButtons: SubForm.getEnabledButtons(state, props),
      updateRows: !TableGetters.loading(state, props)
    }),
    bindInstance(
      {
        makeReview: TableActions.makeReviewRequest,
        requestEdit: TableActions.requestEdit
      },
      ["field", "id"]
    )
  ),
  withProps(props => ({
    fieldId: props.field.id,
    handlers: getHandlers(props)
  }))
);

const SubformTable = decorate(Table);

const SubformView = connect((state, props) => ({
  fieldName: SubForm.getFieldName(state, props)
}))(props => (
  <CollapsiblePanel
    heading={
      <Div display="row.space-between.center" width={1}>
        <Text3 my={2} ml={2}>
          {props.fieldName}
        </Text3>
        <TableSearch my={2} width={300} instanceId={props.field.id} />
      </Div>
    }
    right={false}
    bg="transparent"
    bc="neutral2"
    ba={1}
    bra={2}
    {...os(props)}
  >
    <SubformTable prependApprovalColumn={true} {...wsp(props)} />
  </CollapsiblePanel>
));

export { SubformTable };

export default decorate(SubformView);
