import PropTypes from "prop-types";
import * as R from "ramda";
import React from "react";
import autobind from "autobind-decorator";
import { isEqual } from "lodash";
import getValue from "utils/value-types/get-value/file";
import uuid from "node-uuid";
import FormBaseInput from "../FormBaseInput";
import FormInputWrapper from "components/Event/FormsV2/Sections/FormInputWrapper";

import UploadButton from "components/Atoms/UploadButton";
import File from "components/Event/FormsV2/Sections/FormFileUpload/File";
import Label from "components/Global-2016/Forms/Label";
import LabelSimpleForm from "components/Event/FormsV2/Sections/LabelSimpleForm/view";
import isRequiredValid from "utils/value-types/validations/file/required";

import Helpers from "utils/Global/Helpers";
import { ACCEPT_UPLOAD_FILE } from "utils/file-types-upload";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FormFile extends FormBaseInput {
  constructor(props) {
    super(props);

    this.state = {
      value: props.formValues[props.field.id] || null
    };
  }

  handleSave(value) {
    this.setState(
      {
        value
      },
      () => this.save(this.props.field.id, this.state.value)
    );
  }

  @autobind
  handleChange(e) {
    this.setState({
      value: e.target.value
    });
  }

  @autobind
  async isValid() {
    const errors = [];
    const required = this.props.field.is_required;
    const value = this.state.value;

    if (required && !isRequiredValid(value)) {
      errors.push("This is a required question");
    }
    this.setState({
      errors
    });
    return !errors.length;
  }

  @autobind
  getFiles() {
    return getValue(this.state.value);
  }

  @autobind
  deleteFile(id) {
    const existingFiles = this.getFiles();
    this.handleSave({
      type: "file",
      value: {
        files: [...existingFiles.filter(f => f.id !== id)]
      }
    });
  }

  @autobind
  handleToogleShowEditMode() {
    this.setState({
      isShown: R.not(this.state.isShown)
    });
  }

  showFilepicker = () => {
    const multiple = R.pathOr(
      false,
      ["settings", "allowMultiple"],
      this.props.field
    );

    const callback = files => {
      if (files.length) {
        const filteredFiles = files.filter(f => typeof f.url !== "undefined");
        if (filteredFiles.length) {
          const existingFiles = multiple ? this.getFiles() : [];
          this.handleSave({
            type: "file",
            value: {
              files: [
                ...existingFiles,
                ...filteredFiles.map(f => ({
                  ...f,
                  filepickerId: f.id,
                  id: uuid.v4(),
                  uploadedAt: new Date()
                }))
              ]
            }
          });
        }
      }
    };

    // const multiple = R.pathOr(true, ["settings", "multiple"])(this.props.field);
    const accept = R.compose(
      R.flatten,
      R.map(({ value }) => ACCEPT_UPLOAD_FILE[value]),
      R.pathOr([], ["settings", "allowedFileTypes"])
    )(this.props.field);

    const options = {
      multiple,
      accept,
      fromSources: ["local_file_system", "dropbox"]
    };

    const path = { path: "event-files/" };

    Helpers.getFilepicker(options, path, callback);
  };

  componentWillUpdate(nextProps) {
    if (
      !this.props.isEditing &&
      !isEqual(
        this.props.formValues[this.props.field.id],
        nextProps.formValues[this.props.field.id]
      )
    ) {
      this.setState({
        value: nextProps.formValues[this.props.field.id]
      });
    }
  }

  render() {
    const { isApproving } = this.props;
    const {
      name,
      settings: { description, isAdminField }
    } = this.props.field;
    const { connectDragSource, canShowSimpleSideBar } = this.props;
    const required = this.props.field.is_required;
    const readonly = this.props.field.is_readonly;
    const multiple = R.pathOr(true, ["settings", "multiple"])(this.props.field);
    const files = this.getFiles();

    return (
      <FormInputWrapper
        isEditing={this.props.isEditing}
        isValid={this.state.isValid}
        errorMessages={this.state.errors}
        fieldData={this.props.field}
        instanceId={this.props.field.id}
        isShown={this.state.isShown}
        setIsShown={() => this.handleToogleShowEditMode()}
        connectDragSource={connectDragSource}
      >
        {name ? (
          !canShowSimpleSideBar ? (
            <Label
              isAdminField={isAdminField}
              required={required}
              description={description}
              readonly={readonly}
            >
              {name}
            </Label>
          ) : (
            <LabelSimpleForm
              isAdminField={isAdminField}
              required={required}
              description={description}
              readonly={readonly}
              instanceId={this.props.field.id}
              fieldData={this.props.field}
              isShown={this.state.isShown}
              setIsShown={() => this.handleToogleShowEditMode()}
            >
              {name}
            </LabelSimpleForm>
          )
        ) : null}
        <div styleName="wrapper">
          {!this.props.disabled &&
          (multiple || (!multiple && !files.length)) ? (
            <div styleName="attach">
              <UploadButton
                onClick={
                  this.props.isFillingFormOut ? this.showFilepicker : undefined
                }
              />
            </div>
          ) : (
            ""
          )}
          {!files.length ? (
            <div styleName="empty">
              Add file{multiple ? "s" : ""} to this submission
            </div>
          ) : (
            ""
          )}
          {files.map(file => (
            <File
              key={file.id}
              id={file.id}
              allowDownload={isApproving}
              mimetype={file.mimetype}
              disabled={this.props.disabled}
              url={file.url}
              filename={file.filename}
              deleteFile={this.deleteFile}
            />
          ))}
        </div>
      </FormInputWrapper>
    );
  }
}

FormFile.propTypes = {
  formValues: PropTypes.object,
  field: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isApproving: PropTypes.bool.isRequired,
  isFillingFormOut: PropTypes.bool.isRequired,
  updateFormValue: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default FormFile;
