import React, { Component } from "react";
import PropTypes from "prop-types";
import * as R from "ramda";

import {
  Div,
  DarkTab,
  MediumFilledButton,
  SendIcon,
  Text2
} from "components/Base";

import { withProps, noop } from "utils/General";

const SendIconNote = withProps({ size: 16, mr: 3 })(SendIcon);

class CreateMessage extends Component {
  static propTypes = { modes: PropTypes.array, primaryAction: PropTypes.func };

  onKeyDown = e => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      this.onSend();
    }
  };

  onSend = () => {
    const { message = "", onCreate = noop } = this.props;

    onCreate(message);
  };

  render() {
    const {
      tabs,
      message = "",
      onChangeTab = noop,
      onChangeMessage = noop,
      placeholder,
      buttonLabel = "Send",
      buttonStyleProps = {},
      processing,
      ...styleProps
    } = this.props;

    return (
      <Div
        mx={8}
        my={3}
        bra={2}
        ba={1}
        bc="neutral4"
        display="column.flex-start.stretch"
        {...styleProps}
      >
        <Div px={3} pt={2} onKeyDown={this.onKeyDown}>
          {R.map(
            tab => (
              <DarkTab
                mr={4}
                px={2}
                active={tab.selected}
                key={tab.id}
                onClick={() => onChangeTab(tab.id)}
                barColor={tab.color}
                activeColor={tab.color}
              >
                {tab.title}
              </DarkTab>
            ),
            tabs
          )}
        </Div>
        <textarea
          style={{
            border: "none",
            minHeight: 50,
            margin: 12,
            padding: 0,
            resize: "vertical"
          }}
          placeholder={placeholder}
          onChange={R.compose(
            onChangeMessage,
            R.pathOr("", ["target", "value"])
          )}
          onKeyDown={this.onKeyDown}
        >
          {message}
        </textarea>
        <Div
          style={{ alignSelf: "flex-end" }}
          display="row.center.center"
          mb={3}
          mr={3}
        >
          <Text2>Shift + Enter to send</Text2>
          <MediumFilledButton
            disabled={processing || message.length ? false : true}
            onClick={this.onSend}
            bg="neutral2"
            color="neutral6"
            LeftIcon={SendIconNote}
            ml={3}
            {...buttonStyleProps}
          >
            {buttonLabel}
          </MediumFilledButton>
        </Div>
      </Div>
    );
  }
}

export default CreateMessage;
