import { get, find, map, filter, each } from "lodash";
import moment from "moment";
import {
  DEFAULT_APPROVAL_FIELD_STATUS,
  DEFAULT_APPROVAL_FIELD_APPROVE_REJECT,
  DEFAULT_APPROVAL_FIELD_HAS_CHANGES,
  DEFAULT_APPROVAL_FIELD_APPROVAL_NOTES
} from "components/Event/FormsV2/constants";

import { CONTACT_STANDARD_FIELD_IDS } from "components/Event/Contacts/constants";

export default function getRows({
  sections,
  schema,
  gridId,
  formValues,
  response,
  isApprovingForm,
  isViewingApproval,
  isFillingFormOut,
  recipientCanViewChanges
}) {
  const formMap = Object.keys(formValues).reduce((gridMap, key) => {
    if (key.indexOf(gridId) >= 0) {
      const keys = key.split(".");
      if (keys.length === 3) {
        const row = (gridMap[keys[1]] = gridMap[keys[1]] || {});
        row[keys[2]] = formValues[key];
      }
    }
    return gridMap;
  }, {});

  // rows
  const appendDefaultRows = rows => {
    if (schema.defaultRows) {
      return [].concat(rows, schema.defaultRows);
    }
    return rows;
  };

  const appendRecipientRows = rows => {
    if (formValues[gridId] && formValues[gridId].rows) {
      return [].concat(rows, formValues[gridId].rows);
    }
    return rows;
  };

  const hideHiddenRows = rows => {
    if (formValues[gridId] && formValues[gridId].hiddenRows) {
      return filter(
        rows,
        r => formValues[gridId].hiddenRows.indexOf(r.id) === -1
      );
    }
    return rows;
  };

  // values
  const populateDefaultValues = (row, values) => {
    if (row.defaultValues) {
      return Object.assign({}, values, row.defaultValues);
    }
    return values;
  };

  const populateApprovalValues = (row, values) => {
    const approvalRecord = find(get(response, "approvals", []), {
      row_id: row.id
    });
    const changes = find(get(response, "changes", []), { row_id: row.id });

    const approvalValues = Object.assign({}, values, {
      [DEFAULT_APPROVAL_FIELD_STATUS.id]: {
        type: "text",
        value: approvalRecord ? approvalRecord.status : "Pending"
      },
      [DEFAULT_APPROVAL_FIELD_HAS_CHANGES.id]: {
        type: "boolean",
        value: !!(sectionsWithChanges.length || changes)
      },
      [DEFAULT_APPROVAL_FIELD_APPROVE_REJECT.id]: {
        type: "text",
        value: approvalRecord ? approvalRecord.status : "pending"
      },
      [DEFAULT_APPROVAL_FIELD_APPROVAL_NOTES.id]: {
        type: "text",
        value: approvalRecord ? approvalRecord.note : ""
      }
    });

    return approvalValues;
  };

  const populateRecipientValues = (row, values) =>
    Object.assign({}, values, formMap[row.id]);

  const populateChangeValues = (row, values) => {
    const valuesCopy = Object.assign({}, values);

    each(get(response, "changes", []), change => {
      // break the path up into an array
      const pathArr = change.path.split(".");

      // if we're not on current row, exit
      if (pathArr[1] !== row.id) {
        return;
      }

      // format value
      if (change.new_value.value === "true") {
        valuesCopy[pathArr[2]] = true;
      } else if (change.new_value.value === "false") {
        valuesCopy[pathArr[2]] = false;
      } else {
        valuesCopy[pathArr[2]] = change.new_value.value;
      }

      // mark as having changes so we don't have to re-check later
      if (valuesCopy[pathArr[2]]) {
        valuesCopy[pathArr[2]].hasChanges = true;
      }
    });

    return valuesCopy;
  };

  const populateSectionValues = () => {
    const inputRow = {};

    if (!sections) {
      return inputRow;
    }

    const fname = get(
      find(response.contact_values, { id: CONTACT_STANDARD_FIELD_IDS.fname }),
      "value.value"
    );
    const lname = get(
      find(response.contact_values, { id: CONTACT_STANDARD_FIELD_IDS.lname }),
      "value.value"
    );
    const email = get(
      find(response.contact_values, { id: CONTACT_STANDARD_FIELD_IDS.email }),
      "value.value"
    );

    inputRow["sent-to"] = {
      type: "text",
      value: fname ? `${fname || ""} ${lname || ""}` : email
    };

    each(
      sections.filter(
        s =>
          ![
            "form-grid",
            "form-grid-schedule",
            "form-grid-contacts",
            "form-grid-inventory"
          ].includes(s.type)
      ),
      section => {
        let value = response.responses[section.id];

        const changes = find(get(response, "changes", []), c =>
          c.path.includes(section.id)
        );
        if (changes) {
          value = changes.new_value.value.value;
          sectionsWithChanges.push(section.id);
        }

        switch (section.type) {
          case "form-date":
            inputRow[section.id] = value;
            break;

          case "form-date-range":
            inputRow[`${section.id}_from`] =
              value && value.from
                ? {
                    type: "text",
                    value: moment(new Date(value.from)).format("M/D/YYYY"),
                    hasChanges: !!changes
                  }
                : {
                    type: "text",
                    value: ""
                  };
            inputRow[`${section.id}_to`] =
              value && value.to
                ? {
                    type: "text",
                    value: moment(new Date(value.to)).format("M/D/YYYY"),
                    hasChanges: !!changes
                  }
                : {
                    type: "text",
                    value: ""
                  };
            break;

          default:
            if (value) {
              if (typeof value.type !== "undefined") {
                inputRow[section.id] = value;
              } else {
                inputRow[section.id] = {
                  type: "text",
                  value,
                  hasChanges: !!changes
                };
              }
            } else {
              inputRow[section.id] = {
                type: "text",
                value: undefined
              };
            }
        }
      }
    );

    return inputRow;
  };

  const populateRowValues = row => {
    // populate default values
    let rowValues = populateDefaultValues(row, { ...sectionValues });

    // if in approval mode, populate approval values
    if ((isApprovingForm || isViewingApproval) && response) {
      rowValues = populateApprovalValues(row, rowValues);
    }

    // if filling form out, populate recipient values
    if (isApprovingForm || isFillingFormOut) {
      rowValues = populateRecipientValues(row, rowValues);
    }

    // if in approval mode, populate change values
    if (
      (isApprovingForm || isViewingApproval || recipientCanViewChanges) &&
      response
    ) {
      rowValues = populateChangeValues(row, rowValues);
    }

    return Object.assign(
      {
        id: row.id,
        gridId,
        path: `${gridId}.${row.id}`,
        selected: row.selected || false
      },
      rowValues
    );
  };

  // set initial values
  let sectionsWithChanges = [];
  let sectionValues = populateSectionValues();

  if (isFillingFormOut || isApprovingForm || isViewingApproval) {
    return map(
      hideHiddenRows(appendRecipientRows(appendDefaultRows([]))),
      populateRowValues
    );
  }
  return map(appendDefaultRows([]), populateRowValues);
}
