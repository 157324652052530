import React from "react";
import * as R from "ramda";
import {
  Div,
  Text1,
  Text2,
  SmallToggle,
  CollapsablePanelType2
} from "components/Base";
import { addS } from "utils/General";

const mapIndex = R.addIndex(R.map);

const Items = ({
  itemGroups,
  itemsSelectedLength,
  disableGroupItems,
  enableGroupItems
}) => (
  <div>
    <CollapsablePanelType2
      headingText="Passes"
      headingExtras={
        <Text1>
          {itemsSelectedLength} item{addS(itemsSelectedLength)} selected
        </Text1>
      }
    >
      <Div>
        {mapIndex(
          ({ name, id, items }, idx) => (
            <Div
              bc="neutral3"
              bt={idx > 0 ? 2 : 0}
              pt={2}
              pb={2}
              key={id}
              mb={2}
            >
              <Div display="row.space-between.center">
                <Div fw={3} fs={1} bold color="neutral6">
                  {R.toUpper(name)}
                </Div>
                <Div display="row.flex-start.center">
                  <Text1
                    onClick={() => enableGroupItems(id)}
                    color={{ default: "neutral7", hover: "primary7" }}
                    transition="fast"
                  >
                    All
                  </Text1>
                  <Text1
                    ml={2}
                    onClick={() => disableGroupItems(id)}
                    color={{ default: "neutral7", hover: "primary7" }}
                    transition="fast"
                  >
                    None
                  </Text1>
                </Div>
              </Div>
              {R.map(
                item => (
                  <Div
                    display="row.space-between.center"
                    mt={2}
                    key={item.id}
                    pl={2}
                  >
                    <Text2 bold>{item.name}</Text2>
                    <SmallToggle
                      active={item.selected}
                      onClick={item.onToggle}
                    />
                  </Div>
                ),
                items
              )}
            </Div>
          ),
          itemGroups
        )}
      </Div>
    </CollapsablePanelType2>
  </div>
);

export default Items;
