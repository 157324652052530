import {
  put,
  all,
  take,
  takeEvery,
  fork,
  select,
  call
} from "redux-saga/effects";
import { push } from "react-router-redux";
import * as R from "ramda";
import { actions, getters } from "./model";
import {
  canViewInventory,
  hasAssetsReport,
  hasCredentialsReport,
  hasCateringReport
} from "./selectors";

import { eventDetails as getEventDetails } from "redux/modules/portal/selectors";
import { portalUser as getPortalUser } from "redux/modules/portal/user/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";

import {
  getAssetReport,
  getItemAssetReport,
  getCateringReport,
  getCredentialsReport
} from "redux/modules/portal/reports/actions";
import { getSettings } from "redux/modules/portal/settings/actions";
import { REPORT_TYPES, NAMESPACE } from "./constants";

const getParams = function*() {
  const eventDetails = yield select(getEventDetails);
  const portalUser = yield select(getPortalUser);
  const isViewingAsAccount = R.pathEq(["active_view", "type"], "account")(
    portalUser
  );
  const accountId = isViewingAsAccount
    ? R.path(["active_view", "id"], portalUser)
    : null;
  const userId = yield select(getUserId);
  const reportType = yield select(getters.activeReport);
  const recordTypeId = yield select(getters.recordTypeId);
  return {
    eventId: eventDetails.id,
    eventSlug: eventDetails.slug,
    eventUUID: eventDetails.uuid,
    accountId,
    userId,
    reportType,
    recordTypeId,
    isViewingAsAccount
  };
};

const fetchAllData = function*() {
  const { eventId, isViewingAsAccount, accountId } = yield call(getParams);
  yield put(getSettings(eventId, accountId));
  yield take(`${NAMESPACE}/GlobalUpdate`);
  if (isViewingAsAccount) {
    yield put(getCredentialsReport({ eventId, accountId }));
    yield take(`${NAMESPACE}/GlobalUpdate`);
    yield put(getCateringReport({ eventId, accountId }));
    yield take(`${NAMESPACE}/GlobalUpdate`);
    const userCanViewInventory = yield select(canViewInventory);
    if (userCanViewInventory) {
      yield put(getItemAssetReport({ eventId, accountId }));
    } else {
      yield put(getAssetReport({ eventId, accountId }));
    }
    yield take(`${NAMESPACE}/GlobalUpdate`);
  }
};

const init = function*({ payload: { report, recordTypeId } }) {
  yield put(actions.setLoading(true));
  yield call(fetchAllData);

  let activeReport = report;
  if (R.isNil(report)) {
    const hasAssets = yield select(hasAssetsReport);
    if (hasAssets) {
      activeReport = REPORT_TYPES.ASSETS;
    } else {
      const hasCredentials = yield select(hasCredentialsReport);
      if (hasCredentials) {
        activeReport = REPORT_TYPES.CREDENTIALS;
      } else {
        const hasCaterings = yield select(hasCateringReport);
        if (hasCaterings) {
          activeReport = REPORT_TYPES.MEALS;
        }
      }
    }
  }
  yield all([
    put(actions.setActiveReport(activeReport)),
    put(actions.setRecordTypeId(recordTypeId))
  ]);
  yield put(actions.setLoading(false));
};

const goToReport = function*({ payload: newReport }) {
  const { eventSlug, eventUUID, recordTypeId } = yield call(getParams);

  yield all([
    put(
      push(
        `/hubs/${eventSlug}/${eventUUID}/people/${recordTypeId}/reports/${newReport}`
      )
    ),
    put(actions.setActiveReport(newReport))
  ]);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchGoToReports = function*() {
  yield takeEvery(actions.goToReport.type, goToReport);
};

const rootSaga = function*() {
  yield all([fork(watchInit), fork(watchGoToReports)]);
};

export default rootSaga;
