import { createModule } from "redux-mvc";

import model from "./model";
import sagas from "./sagas";

import formModule from "ui-kit/Form";

const notificationModal = createModule(model);

notificationModal.plugModule(formModule);

notificationModal.setRootSaga(sagas);

export default notificationModal;
