import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import getValue from "utils/value-types/get-value/datetime";
import moment from "moment";
import { get, isNil } from "lodash";
import Helpers from "utils/Global/Helpers";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { setOtherZone, setLocalZone } from "utils/General";
import * as R from "ramda";

import Flatpickr from "react-flatpickr";

// @NOTE: This loads moment locale for the date picker
import momentLocalizer from "react-widgets-moment";

const DATE_FORMAT = "LL";
const TIME_FORMAT = "LT";
const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

@CSSModules(css)
class FormDateTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.formatValue(props.value)
    };
  }

  componentWillUpdate(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        value: this.formatValue(nextProps.value)
      });
    }
  }

  componentWillMount() {
    moment.locale(Helpers.getLocale());
    momentLocalizer(moment);
  }

  formatValue(value) {
    const unwrappedValue = getValue(value);
    if (!unwrappedValue || !moment(unwrappedValue).isValid()) {
      return null;
    }
    if (!get(this.props, "column.settings.useDateTimeTimezone", false)) {
      const offset = get(value, "value.offset");
      if (!isNil(offset)) {
        // Need to convert it to a string then back to date because it's a new Moment
        return new Date(
          moment(unwrappedValue)
            .utcOffset(offset)
            .format()
        );
      }
    }
    return moment(unwrappedValue).toDate();
  }

  wrapValue(value) {
    const parsedValue = this.parse(value);
    return {
      type: "datetime",
      value: parsedValue
        ? {
            iso: parsedValue.toISOString(),
            offset: parsedValue.utcOffset()
          }
        : undefined
    };
  }

  @autobind
  getTimezone() {
    const id = R.path(["value", "columnId"], this.props);
    const settings = R.path(["rowMetaData", "meta", "columns", id], this.props);
    return R.path(["settings", "timezone"], settings);
  }

  @autobind
  onChange(value) {
    const timezone = this.getTimezone();
    const val = moment(setOtherZone(value, timezone));
    this.props.onChange(this.wrapValue(val.toDate()));
  }

  @autobind
  getValue() {
    const timezone = this.getTimezone();
    return this.state.value ? setLocalZone(this.state.value, timezone) : null;
  }

  parse(d) {
    if (!d) {
      return null;
    }

    if (moment.isMoment(d)) {
      return d;
    }

    let date;
    moment.locale(Helpers.getLocale());

    // Try to use the date constructor
    date = moment(new Date(d));
    if (date.isValid()) {
      return date;
    }

    // Case where date constructor can't handle it, attempt to parse with the foramt
    date = moment(d, DATETIME_FORMAT);
    if (date.isValid()) {
      return date;
    }

    return null;
  }

  render() {
    const { disabled, placeholder } = this.props;
    return (
      <div
        onBlur={event => {
          if (
            event.target.className.includes("dateTimeInput") ||
            event.target.className.includes("flatpickr-day")
          ) {
            event.stopPropagation();
          }
        }}
      >
        <Flatpickr
          className={css.dateTimeInput}
          disabled={disabled}
          options={{
            altInput: true,
            enableTime: true,
            noCalendar: false,
            minuteIncrement: 5,
            static: true
          }}
          value={this.getValue()}
          onChange={val => this.onChange(val[0])}
          onClose={val => this.onChange(val[0])}
          placeholder={placeholder || moment().format(DATETIME_FORMAT)}
        />
      </div>
    );
  }
}

FormDateTime.propTypes = {
  column: PropTypes.object.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object
};

export default FormDateTime;
