import { createHandlers } from "redux-mvc";
import * as R from "ramda";
import {
  NAMESPACE,
  LAYOUT_OPTIONS,
  PAGES,
  AVAILABLE_TO_OPTIONS,
  FIELD_IDS,
  DEFAULT_SIDEBAR_LINKS
} from "./constants";

const iniState = {
  loading: true,
  activePage: PAGES.BASIC_DETAILS,
  recordTypeId: null,
  showAddVideoLink: false,
  showSelectItemsModal: false,
  isLink: false,
  itemTypes: [],
  inheritFromOptions: [],
  inheritMediaHubFromRecordTypeId: null,
  data: {
    title: "",
    folder: "",
    parent_page_id: null,
    upload_header_img: false,
    header_img: null,
    assign_group: false,
    assign_individual: false,
    layout: LAYOUT_OPTIONS.MEDIA_CENTER,
    available_to: AVAILABLE_TO_OPTIONS.EVERYONE_CAN_ACCESS,
    featuredContent: [],
    content: null
  },
  settings: {
    // custom
    page_title: null,
    page_subtitle: null,
    page_content: null,

    // checkout

    // media center
    enable_community_hub: false,
    enable_sidebar_video: false,
    sidebar_video_url: null,
    sidebar_video_title: null,
    intercom_id: null,
    intercom_method: "client",
    google_tag_manager_id: null,
    interview_schedule_title: null,
    interview_schedule_subtitle: null,
    watch_interview_replays_url: null,
    enable_request_interview_button: null,
    left_sidebar_logo_image_url: null,
    event_logo_url: null,
    event_title: null,
    event_subtitle: null,
    alerts_title: null,
    alerts_subtitle: null,
    alerts_view_all_label: null,
    videos_title: null,
    videos_subtitle: null,
    videos_view_all_label: null,
    content_section_1_label: null,
    content_section_1_content: null,
    content_section_2_label: null,
    content_section_2_content: null,
    content_section_3_label: null,
    content_section_3_content: null,
    left_sidebar_color: null,
    link_color: null,
    left_sidebar_secondary_color: null,
    font_family: "roboto",
    right_sidebar_links: DEFAULT_SIDEBAR_LINKS
  }
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    uploadHeaderImg: R.identity,
    saveSettings: R.identity,
    closeModal: R.identity,
    getItemsToShow: R.identity,
    onSelectItemsToShow: R.identity,

    setInitData: (state, { payload: { settings, ...data } }) => {
      return {
        inheritFromOptions: data.inherit_from_options,
        inheritMediaHubFromRecordTypeId:
          data.inherit_media_hub_from_record_type_id,
        recordTypeId: data.recordTypeId,
        data: {
          ...state.data,
          ...data
        },
        settings: {
          ...state.settings,
          ...settings,
          [FIELD_IDS.MEDIA_CENTER.RIGHT_SIDEBAR_LINKS]:
            settings[FIELD_IDS.MEDIA_CENTER.RIGHT_SIDEBAR_LINKS] &&
            FIELD_IDS.MEDIA_CENTER.RIGHT_SIDEBAR_LINKS.length
              ? settings[FIELD_IDS.MEDIA_CENTER.RIGHT_SIDEBAR_LINKS]
              : DEFAULT_SIDEBAR_LINKS
        }
      };
    },
    updateSettings: (state, { payload: { field, value } }) => ({
      settings: R.assoc(field, value, state.settings)
    }),

    updateData: (state, { payload: { id, value } }) => ({
      data: {
        ...state.data,
        [id]: value
      }
    }),

    onUpdateRightSidebarValue: (
      state,
      { payload: { linkId, fieldId, value } }
    ) => {
      const idx = state.settings[
        FIELD_IDS.MEDIA_CENTER.RIGHT_SIDEBAR_LINKS
      ].findIndex(l => l.id === linkId);
      const updatedLinks =
        state.settings[FIELD_IDS.MEDIA_CENTER.RIGHT_SIDEBAR_LINKS];
      updatedLinks[idx] = {
        ...updatedLinks[idx],
        [fieldId]: value
      };

      return {
        settings: {
          ...state.settings,
          [FIELD_IDS.MEDIA_CENTER.RIGHT_SIDEBAR_LINKS]: updatedLinks
        }
      };
    },

    onReorderRightSidebarLinks: (state, { payload: links }) => {
      return {
        settings: {
          ...state.settings,
          [FIELD_IDS.MEDIA_CENTER.RIGHT_SIDEBAR_LINKS]: links.map(
            (link, idx) => ({
              ...link,
              order: idx
            })
          )
        }
      };
    },

    resetData: R.always(iniState)
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
