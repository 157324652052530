import * as R from "ramda";
import { findDOMNode } from "react-dom";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { withProps } from "recompose";
import { toClass } from "utils/General";
import { bindInstance, defaultInstanceId } from "redux-mvc";
import { noop } from "utils/General";

import { actions, getters } from "Sponsors/PackageTable";

import { getColumns } from "Sponsors/PackageTable/selectors";
import { VIEWS } from "Sponsors/PackageTable/constants";

import { Div } from "components/Base";

import { Row } from "ui-kit/Form/View";
import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";

import RowGroup from "./RowGroup";
import columnsDef from "./ColumnDef";

import { DragSource, DropTarget } from "react-dnd";

const cardSource = {
  beginDrag(props) {
    props.onDrag(props.id);
    return {
      id: props.id,
      position: props.position,
      index: props.index
    };
  }
};

// see React dnd docs for comments
const cardTarget = {
  drop({ onDrop = noop, index, up, id }) {
    onDrop({ index, up, id });
  }
};

const DropTargetWrapper = DropTarget("Row", cardTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver()
}));

const DroppableSpace = DropTargetWrapper(({ connectDropTarget, isOver }) => {
  return (
    <div
      style={{
        width: "100%",
        height: isOver ? 20 : 10,
        transition: "height 300ms ease"
      }}
      ref={instance => {
        connectDropTarget(findDOMNode(instance));
      }}
    />
  );
});

const DragSourceWrapper = DragSource("Row", cardSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
}));

const RowGroupClass = toClass(RowGroup);

const DraggableRowGroup = R.compose(DragSourceWrapper)(
  ({ connectDragSource, ...props }) => (
    <RowGroupClass
      ref={instance => {
        connectDragSource(findDOMNode(instance));
      }}
      {...props}
    />
  )
);

const renderTopDropSpace = (index, positions, items) => {
  if (index === 0) {
    return true;
  }
  return R.pathOr(false, [positions[index - 1], "autoEnabled"], items);
};

const decorate = R.compose(
  WithThemeConsumer({ variant: VARIANTS.SURFACE }),
  connect(
    (state, props) => ({
      columns: getColumns(state, props),
      order: getters.order(state, props)
    }),
    bindInstance({
      init: actions.init,
      toggleExpand: actions.toggleExpand,
      activate: actions.activate,
      onDrag: actions.setDraggingId,
      onDrop: actions.switchIndexes,
      updateOrder: actions.updateOrder
    })
  ),
  withProps(props => ({
    handlers: {
      ...props.handlers,
      onAddDescription: column => props.activate({ column, id: "description" }),
      onSettings: id => props.toggleExpand(id)
    }
  }))
);

const PackageTable = ({
  items = {},
  columns = [],
  handlers = {},
  iniOrder = [],
  order = [],
  drag = false,
  instanceId = defaultInstanceId,
  init = noop,
  onDrag = noop,
  onDrop = noop,
  updateOrder = noop,
  view = VIEWS.CREATE_PACKAGES,
  Th = defaultTh
}) => {
  useEffect(() => {
    init({
      view,
      iniOrder
    });
  }, []);

  useEffect(() => {
    updateOrder(R.keys(items));
  }, [items]);

  const itemsList = useMemo(() => R.values(items), [items]);

  if (!drag) {
    return (
      <>
        <Row width={1} pr={2} mt={3}>
          {R.map(
            column =>
              React.createElement(
                R.pathOr(Div, [column, "header"], columnsDef),
                {
                  key: column,
                  Th
                }
              ),
            columns
          )}
        </Row>
        {R.map(
          item => (
            <RowGroup
              item={item}
              drag={drag}
              handlers={handlers}
              mt={2}
              key={item.id}
              instanceId={instanceId}
            />
          ),
          itemsList
        )}
      </>
    );
  }
  return (
    <>
      <Row width={1} pr={2} mt={3} pl={drag ? 5 : 0}>
        {R.map(
          column =>
            React.createElement(R.pathOr(Div, [column, "header"], columnsDef), {
              key: column,
              Th
            }),
          columns
        )}
      </Row>
      {order
        .filter(position => !R.isNil(items[position]))
        .map((position, index, positions) => {
          const autoEnabled = R.pathOr(false, [position, "autoEnabled"], items);

          if (autoEnabled) {
            return (
              <RowGroup
                mt={2}
                item={items[position]}
                autoEnabled={autoEnabled}
                handlers={handlers}
                key={items[position].id}
                instanceId={instanceId}
              />
            );
          }

          return (
            <>
              {(renderTopDropSpace(index, positions, items) && (
                <DroppableSpace
                  index={index}
                  id={items[position].id}
                  key={R.pathOr("head", [position, "id"], items) + "dropHead"}
                  onDrop={onDrop}
                  up
                />
              )) ||
                null}
              <DraggableRowGroup
                index={index}
                id={items[position].id}
                key={items[position].id}
                item={items[position]}
                drag={drag}
                handlers={handlers}
                instanceId={instanceId}
                onDrag={onDrag}
              />
              <DroppableSpace
                index={index}
                id={items[position].id}
                key={R.pathOr("tail", [position, "id"], items) + "dropTail"}
                onDrop={onDrop}
              />
            </>
          );
        })}
    </>
  );
};

export default decorate(PackageTable);
