export const NAMESPACE = "IntakeForm";

export const WELCOME_COLORS = {
  0: "indigo7",
  1: "neutral5",
  2: "neutral5"
};

export const GROUP_COLORS = {
  0: "neutral5",
  1: "indigo7",
  2: "neutral5"
};

export const CONTACT_COLORS = {
  0: "neutral5",
  1: "neutral5",
  2: "indigo7"
};

export const LOGIN_FIELDS = [
  {
    id: "first_name",
    name: "First Name",
    type: "text",
    required: true,
    settings: {}
  },

  {
    id: "email",
    name: "Email",
    type: "email",
    required: true,
    settings: {}
  },
  {
    id: "password",
    name: "Password",
    type: "password",
    required: true,
    settings: {}
  }
];

export const FORGOT_FIELDS = [
  {
    id: "first_name",
    name: "First Name",
    type: "text",
    required: true,
    settings: {}
  },

  {
    id: "email",
    name: "Email",
    type: "email",
    required: true,
    settings: {}
  }
];
