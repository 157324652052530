import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class DropdownBuilder extends Component {
  @autobind
  updateOption(index, event) {
    const options = this.props.optionsList;
    options[index] = event.target.value;

    if (options[options.length - 1] !== "") {
      options.push("");
    }
    this.updateOptionList(options);
  }

  @autobind
  updateOptionList(options) {
    this.props.updateList(options, this.props.allowMultipleSelect);
  }

  @autobind
  removeOption(index) {
    const options = this.props.optionsList;
    options.splice(index, 1);

    if (options.length === 0) {
      options.push("");
    }
    this.updateOptionList(options);
  }

  @autobind
  toggleMultipleSelect() {
    this.props.updateList(
      this.props.optionsList,
      !this.props.allowMultipleSelect
    );
  }

  render() {
    const options = this.props.optionsList.map((option, index) => {
      const remove =
        this.props.optionsList.length === index + 1 ? (
          ""
        ) : (
          <i
            className="material-icons"
            styleName="remove"
            onClick={this.removeOption.bind(null, index)}
          >
            &#xE5CD;
          </i>
        );
      return (
        <div key={index} styleName="inputWrapper">
          <input
            styleName="input"
            type="text"
            value={option}
            placeholder="New option..."
            onChange={this.updateOption.bind(null, index)}
          />
          {remove}
        </div>
      );
    });
    return (
      <div>
        {options}
        {this.props.promptMultipleSelect && (
          <div styleName="inputWrapper">
            <label styleName="checkboxWrapper">
              <input
                type="checkbox"
                onChange={this.handleMultpleSelectChange}
                checked={this.props.allowMultipleSelect}
              />
              <div
                onClick={this.toggleMultipleSelect}
                styleName="checkboxLabel"
              >
                Allow Multiple Select?
              </div>
            </label>
          </div>
        )}
      </div>
    );
  }
}

DropdownBuilder.propTypes = {
  updateList: PropTypes.func.isRequired,
  optionsList: PropTypes.array.isRequired,
  allowMultipleSelect: PropTypes.bool,
  promptMultipleSelect: PropTypes.bool
};

export default DropdownBuilder;
