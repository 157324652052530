import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Popover from "@lennd/material-ui/Popover";
import Checkbox from "material-ui/Checkbox";
import Toggle from "material-ui/Toggle";
import hiddenFields from "components/Event/FormsV2/Utils/field-blacklist";
import { sortBy } from "lodash";

@CSSModules(css)
class CustomizeCardPopover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopover: false
    };
  }

  @autobind
  handleClick(e) {
    e.stopPropagation();
    this.setState({ showPopover: true });
  }

  @autobind
  onRequestClose() {
    this.setState({ showPopover: false });
  }

  @autobind
  handleCheck(id, e, value) {
    if (value === false) {
      this.props.updateFields([
        ...this.props.selectedFields.filter(f => f !== id)
      ]);
    } else {
      this.props.updateFields([...this.props.selectedFields, id]);
    }
  }

  @autobind
  handleSummaryToggle(e, val) {
    this.props.toggleSummaries(val);
  }

  @autobind
  hideAll() {
    this.props.updateFields([]);
  }

  render() {
    const { fields, selectedFields } = this.props;

    const fieldsList = sortBy(
      fields.filter(f => !hiddenFields.includes(f.type)),
      ["name"]
    ).map(f => (
      <Checkbox
        checked={selectedFields.includes(f.id)}
        iconStyle={{ marginRight: 8 }}
        labelStyle={{
          fontWeight: 600,
          fontSize: 12,
          color: "#545454",
          textTransform: "uppercase"
        }}
        onCheck={this.handleCheck.bind(null, f.id)}
        label={f.name}
      />
    ));

    return (
      <div ref={ref => (this.anchor = ref)}>
        <span onClick={this.handleClick}>{this.props.children}</span>
        <Popover
          anchorEl={this.anchor}
          anchorOrigin={{ horizontal: "left", vertical: "top" }}
          onClose={this.onRequestClose}
          open={this.state.showPopover}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
        >
          <div styleName="container">
            <div styleName="heading">
              <div styleName="title">Edit cards</div>
              <div>
                <Toggle
                  label="Show submission summary"
                  labelPosition="right"
                  labelStyle={{
                    fontSize: 13,
                    fontWeight: 400,
                    color: "#545454"
                  }}
                  onToggle={this.handleSummaryToggle}
                  toggled={this.props.showSummaries}
                />
              </div>
            </div>
            <div styleName="list">
              <div styleName="listHeading">
                <div>available fields</div>
                <div styleName="hideAll" onClick={this.hideAll}>
                  Hide all
                </div>
              </div>
              {fieldsList}
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}

CustomizeCardPopover.propTypes = {
  children: PropTypes.node,
  fields: PropTypes.array.isRequired,
  showSummaries: PropTypes.bool,
  selectedFields: PropTypes.array.isRequired,
  toggleSummaries: PropTypes.func.isRequired
};

export default CustomizeCardPopover;
