import PropTypes from "prop-types";
import React from "react";
import EditorBase from "./TableConnector";
import Cell from "./CellBase";
import Formatter from "ui-kit/Datagrid/View/Formatters/EventDays";
import Editor from "components/Global/Editors/EventDaysEditor";

import { getMeta, ep } from "ui-kit/Datagrid/utils";

export default class EventDaysEditor extends EditorBase {
  render() {
    const props = ep(this.props);
    const { row } = props;

    const event = getMeta(props, "eventDetails");

    const editorProps = {
      startDate: event.date_from,
      endDate: event.date_to,
      dayGroups: event.date_groups
    };

    return (
      <Cell
        ref="editor"
        formatter={<Formatter value={this.state} row={row} />}
        onClose={this.props.onCommit}
        editor={
          <Editor
            {...editorProps}
            value={this.state}
            onChange={this.handleChange}
          />
        }
      />
    );
  }
}

EventDaysEditor.propTypes = {
  ...EditorBase.propTypes,
  rowMetaData: PropTypes.object.isRequired
};
