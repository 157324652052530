import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const SidebarSubItem = ({
  linkTo,
  active = false,
  iconLeft,
  children,
  ...props
}) => {
  if (linkTo) {
    return (
      <Link
        to={linkTo}
        styleName={active ? "subItemActive" : "subItem"}
        {...props}
      >
        {iconLeft}
        {children}
      </Link>
    );
  }
  return (
    <div styleName={active ? "subItemActive" : "subItem"} {...props}>
      {iconLeft}
      {children}
    </div>
  );
};

export default CSSModules(SidebarSubItem, css);
