import React from "react";
import { Div } from "components/Base";
import * as R from "ramda";
import Product from "./Product";
import { connect } from "react-redux";
import { actions } from "../model";

const decorate = connect(
  null,
  {
    handleAddItemModal: actions.handleAddItemModal
  }
);

const ProductsHeader = ({ name, required, description }) => (
  <Div
    display="column"
    style={{
      marginBottom: 18
    }}
  >
    <Div display="row.flex-start.center">
      <Div
        style={{
          fontWeight: "bold",
          fontSize: "26px",
          lineHeight: "21px",
          color: "#333333"
        }}
      >
        {name}
      </Div>
      {required ? (
        <Div
          ml={5}
          uppercase
          color="gray6"
          fs={2}
          style={{
            letterSpacing: ".3px",
            lineHeight: "11px"
          }}
        >
          Must select one item
        </Div>
      ) : null}
    </Div>
    {description?.length ? (
      <Div
        fs={2}
        color="gray7"
        mt={1}
        style={{
          maxWidth: "690px"
        }}
      >
        {description}
      </Div>
    ) : null}
  </Div>
);

const CategoryProducts = ({ category, handleAddItemModal }) => {
  if (category.items.length === 0) return null;

  return (
    <Div style={{ marginBottom: 28 }}>
      <ProductsHeader
        name={category.name}
        required={category.required}
        description={category.description}
      />
      <Div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap"
        }}
      >
        {R.map(item => {
          return (
            <Product
              key={item.id}
              type_id={item.type_id}
              photo_url={item.photo_url}
              name={item.name}
              onClick={() =>
                handleAddItemModal({
                  item: item.id,
                  categoryId: category.id,
                  category: category.name,
                  name: item.name
                })
              }
              price_label={item.price_label}
            />
          );
        }, category.items)}
      </Div>
    </Div>
  );
};

export default decorate(CategoryProducts);
