import Helpers from "utils/Global/Helpers";
import getQuery from "redux/modules/modules/utils/get-query";

module.exports = {
  get: (credentials, { moduleId, layoutId, options }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery(options);

      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/modules/${moduleId}/layout/${layoutId}${query || ""}`,
        credentials,
        success,
        error
      });
    })
  /* eslint-enable no-underscore-dangle */
};
