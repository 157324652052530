import React from "react";
import * as R from "ramda";
import {
  Div,
  FontIcon,
  SearchIcon,
  BigFilledButton,
  BigShadedInput,
  Text2,
  Text3,
  PopoverMenu,
  TinyOutlineButton,
  makeTable,
  SmallAvatar
} from "components/Base";
import Tooltip from "components/Global/Tooltip";
import CopyToClipboard from "react-copy-to-clipboard";
import { withProps, noop } from "utils/General";
import CanUserDo from "components/Global/Security/CanUserDo";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

import CSSModules from "react-css-modules";
import css from "components/Event/Settings/Module/tableStyles.scss";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import { showModal, hideModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { actions } from "../model";
import { getUsers } from "../selectors";

import { user as getUser } from "redux/modules/user/selectors";
import { orgDetails as getOrgDetails } from "redux/modules/organization/selectors";

import RemoveOrganizationUserModal from "components/Organization/Settings/Users/Modals/RemoveOrganizationUser";
import AddOrganizationUserModal from "components/Organization/Settings/Users/Modals/AddOrganizationUser";
import OrganizationUserModal from "components/Organization/Settings/Users/Modals/OrganizationUser";

import {
  removeUser,
  resendInvite
} from "redux/modules/organization/users/actions";

const decorate = R.compose(
  withRouter,
  connect(
    state => ({
      orgDetails: getOrgDetails(state),
      users: getUsers(state),
      user: getUser(state)
    }),
    {
      showModal,
      hideModal,
      showSnackbar,
      removeUser,
      resendInvite,
      getUsers: actions.getUsers,
      setSearchTerm: actions.setSearchTerm
    }
  )
);

const TableStyler = withProps({
  my: 3,
  bra: 1,
  shadow: 1
})(Div);

const HeaderStyler = withProps({
  display: "row.flex-start.center",
  bg: "neutral1",
  py: 3,
  pl: 4
})(Div);

const RowStyler = CSSModules(
  withProps({
    display: "row.flex-start.center",
    bc: "neutral2",
    bg: "white",
    bt: 1,
    pl: 4,
    pt: 2,
    pb: 2,
    styleName: "parentContainer"
  })(Div),
  css
);

const UsersTable = makeTable({
  TableStyler,
  HeaderStyler,
  RowStyler,
  HeaderCellStyler: Div,
  RowCellStyler: Div,
  columnProps: [
    { width: 4 / 12 },
    { width: 3 / 12 },
    { width: 3 / 12 },
    { width: 2 / 12 }
  ],
  headerCellComps: [
    () => <Text3 bold>Name</Text3>,
    () => <Text3 bold>Role</Text3>,
    () => <Text3 bold>Permissions</Text3>,
    () => <Text3 bold>Actions</Text3>
  ],
  rowCellComps: [
    ({ name, email, photoUrl, onView, isPending, inviteSentAt }) => (
      <Div display="row.flex-start.center" onClick={onView}>
        <SmallAvatar photoURL={photoUrl} text={name.toUpperCase()} />
        <Div display="column.center.flex-start" width={1} mr={4} ml={2}>
          <Div display="row.flex-start.center">
            <Text3 bold primary truncate>
              {name}
            </Text3>
            {isPending ? (
              <Tooltip tooltip={`Invite sent ${inviteSentAt}`}>
                <FontIcon ml={1} fs={2} color="gray6">
                  hourglass_empty
                </FontIcon>
              </Tooltip>
            ) : null}
          </Div>
          <Div fs={1} color="gray5">
            {email}
          </Div>
        </Div>
      </Div>
    ),
    ({ role }) => <Text2>{role}</Text2>,
    ({ permissionRole }) => <Text2>{permissionRole}</Text2>,
    ({ onView, onRemove, onResend, onCopy, locked, isPending, inviteLink }) => (
      <CanUserDo
        any={[
          `${STANDARD_MODULES.settings.id}_manage_users`,
          `${STANDARD_MODULES.orgSettings.id}_manage_users`
        ]}
      >
        <PopoverMenu
          Label={({ onClick }) => (
            <TinyOutlineButton onClick={onClick} py={1}>
              <FontIcon fs={3}>more_horiz</FontIcon>
            </TinyOutlineButton>
          )}
          menuItems={
            locked
              ? [["Edit", onView]]
              : [
                  ["Edit", onView],
                  ["Remove", onRemove],
                  isPending ? ["Resend invite", onResend] : null,
                  isPending && onCopy
                    ? [
                        <CopyToClipboard
                          key="copy"
                          text={inviteLink}
                          onCopy={onCopy}
                        >
                          <span>Copy invite URL</span>
                        </CopyToClipboard>,
                        noop
                      ]
                    : null
                ].filter(o => o)
          }
        />
      </CanUserDo>
    )
  ]
});

class Body extends React.Component {
  onSearch = searchTerm => {
    if (searchTerm !== this.props.searchTerm) {
      this.props.setSearchTerm(searchTerm);
    }
  };

  showEditInfoModal = record => {
    const modal = (
      <OrganizationUserModal
        title={`${record.user_fname} ${record.user_lname}`}
        hideModal={this.props.hideModal}
        orgId={this.props.params.orgId}
        record={record}
        orgName={this.props.orgDetails.name}
        onUpdated={this.handleInfoUpdated}
        showPermissions
      />
    );

    this.props.showModal({ content: modal });
  };

  handleInfoUpdated = () => {
    this.props.getUsers(this.props.params.orgId);

    this.props.showSnackbar({ message: "User updated", action: "OK" });
  };

  resendInvitation = async user => {
    await this.props.resendInvite({
      orgId: this.props.params.orgId,
      userId: user.user_id
    });

    this.props.getUsers(this.props.params.orgId);
    this.props.showSnackbar({
      message: `Invitation resent to ${user.user_email}`,
      action: "OK"
    });
    return true;
  };

  handleRemoveMember = async data => {
    await this.props.removeUser({
      orgId: this.props.params.orgId,
      userId: data.userId
    });

    if (data.userId === this.props.user.id) {
      // removed self - redirect to home
      window.location = "/home";
    } else {
      this.props.getUsers(this.props.params.orgId);
      this.props.showSnackbar({ message: "User removed", action: "OK" });
    }
    return true;
  };

  showRemoveFromOrgModal = (userName, userId, user) => {
    const modal = (
      <RemoveOrganizationUserModal
        user={user}
        userName={userName}
        userId={userId}
        hideModal={this.props.hideModal}
        handleRemoveMember={this.handleRemoveMember}
      />
    );

    this.props.showModal({ content: modal });
  };

  showAddOrganizationUserModal = () => {
    const modal = (
      <AddOrganizationUserModal
        hideModal={this.props.hideModal}
        addToOrgId={this.props.params.orgId}
        onAdded={this.handleUserAdded}
      />
    );

    this.props.showModal({ content: modal });
  };

  handleUserAdded = (data, user, action) => {
    this.props.getUsers(this.props.params.orgId);

    this.props.showSnackbar({
      message: action === "added" ? "User added" : "User invited",
      action: "OK"
    });
  };

  onCopy = () => {
    this.props.showSnackbar({
      message: "Copied",
      action: "OK"
    });
  };

  render() {
    const usersToUse = R.map(user => ({
      ...user,
      onCopy: this.onCopy,
      onView: () => this.showEditInfoModal(user),
      onRemove: () =>
        this.showRemoveFromOrgModal(user.name, user.user_id, user),
      onResend: () => this.resendInvitation(user)
    }))(this.props.users);

    return (
      <Div display="row.flex-start.flex-start">
        <Div flex={1}>
          <CanUserDo
            any={[
              `${STANDARD_MODULES.settings}_invite_users`,
              `${STANDARD_MODULES.orgSettings}_invite_users`
            ]}
          >
            <Div display="row.flex-start.center" pb={4}>
              <BigShadedInput
                continuous
                LeftIcon={SearchIcon}
                flex={1}
                placeholder="Search users"
                onChange={this.onSearch}
              />
              <BigFilledButton
                bg="altB5"
                ml={1}
                onClick={this.showAddOrganizationUserModal}
              >
                Invite user
              </BigFilledButton>
            </Div>
          </CanUserDo>
          <UsersTable headerData={{}} rowsData={usersToUse} />
        </Div>
      </Div>
    );
  }
}

export default decorate(Body);
