import * as R from "ramda";
import { connect } from "react-redux";
import Popover from "./Popover";
import { groupSettings } from "redux/modules/portal/groupManager/selectors";

import { resources } from "redux/modules/portal/groupManager/resources/selectors";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";

function mapStateToProps(state) {
  return {
    credentials: resources(state).filter(r => r.type_id === CREDENTIAL_TYPE_ID),
    variantLimits: R.propOr({}, "variant_limits")(groupSettings(state))
  };
}

export default connect(mapStateToProps)(Popover);
