import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";
import { Div } from "components/Base";
import { getters } from "Portal/IntakeForm/model";
import Logo from "Portal/IntakeForm/View/Logo";
import Steps from "Portal/IntakeForm/View/Steps";
import WelcomePage from "Portal/IntakeForm/View/WelcomePage";
import GroupPage from "Portal/IntakeForm/View/GroupPage";
import ContactPage from "Portal/IntakeForm/View/ContactPage";
import LoginPage from "Portal/IntakeForm/View/LoginPage";
import Loading from "ui-kit/Loading";
import PlatformLoading from "components/Platform/PlatformLoading";
import MediaQuery from "react-responsive";
import WelcomeLoginSection from "./WelcomeLoginSection";
import PoweredbyLennd from "components/PortalV2/PoweredbyLennd";

import { getPageTitle } from "Portal/IntakeForm/selectors";

const Small = props => <MediaQuery {...props} maxWidth={420} />;
const Big = props => <MediaQuery {...props} minWidth={421} />;
const Mobile = props => <MediaQuery {...props} maxWidth={820} />;
const Default = props => <MediaQuery {...props} minWidth={821} />;

const decorate = connect(state => ({
  isLoggedIn: getters.isLoggedIn(state),
  activeIndex: getters.activeIndex(state),
  formData: getters.formData(state),
  loading: getters.loading(state),
  pageTitle: getPageTitle(state)
}));

const Pages = ({ handlers, mobile, activeIndex, loading, route }) => {
  return !loading ? (
    <>
      {route.name !== "intakeLogin" ? (
        <>
          <Logo />
          <Steps mobile={mobile} />
          {activeIndex === 0 && <WelcomePage mobile={mobile} />}
          {activeIndex === 1 && <GroupPage handlers={handlers} />}
          {activeIndex === 2 && <ContactPage handlers={handlers} />}
        </>
      ) : (
        <LoginPage />
      )}
    </>
  ) : (
    <Loading height={400} />
  );
};

const Layout = ({
  isLoggedIn,
  handlers,
  activeIndex,
  formData,
  route,
  loading,
  pageTitle
}) => {
  if (route.name === "intakeCallback") {
    return <PlatformLoading message="Logging in..." />;
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Div bg="white">
        <Small>
          <Div
            style={{
              backgroundImage: R.propOr("", "background_image_url", formData)
                ? `url("${R.propOr("", "background_image_url", formData)}")`
                : "inherit",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              position: "fixed"
            }}
            height={330}
            width={1}
          />
        </Small>
        <Big>
          <Div
            style={{
              backgroundImage: R.propOr("", "background_image_url", formData)
                ? `url("${R.propOr("", "background_image_url", formData)}")`
                : "inherit",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
              position: "fixed"
            }}
            width={1}
            height={440}
          />
        </Big>
        <Mobile>
          <Div
            display="column.flex-start.center"
            width={1}
            style={{ position: "absolute", top: 135, left: 0 }}
          >
            <Div
              bg="white"
              width={1}
              mb={4}
              p={4}
              style={{
                borderRadius: "6px",
                boxShadow:
                  "0 9px 17px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 0 rgba(0, 0, 0, 0.17)"
              }}
            >
              <Pages
                handlers={handlers}
                mobile
                activeIndex={activeIndex}
                loading={loading}
                route={route}
              />
            </Div>

            {isLoggedIn ? null : (
              <Div
                width={1}
                mb={6}
                p={4}
                bg="neutral0"
                style={{
                  borderRadius: "6px"
                }}
              >
                <WelcomeLoginSection handlers={handlers} />
              </Div>
            )}
            {activeIndex !== 0 && (
              <PoweredbyLennd
                style={{
                  margin: "20px 0"
                }}
              />
            )}
          </Div>
        </Mobile>
        <Default>
          <Div
            style={{ position: "absolute", top: 135, left: 0 }}
            display="column.flex-start.center"
            width={1}
          >
            <Div
              bg="white"
              width={820}
              mb={4}
              pt={10}
              pb={8}
              px={14}
              style={{
                borderRadius: "6px",
                boxShadow:
                  "0 9px 17px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 0 rgba(0, 0, 0, 0.17)"
              }}
            >
              <Pages
                handlers={handlers}
                activeIndex={activeIndex}
                loading={loading}
                route={route}
              />
            </Div>
            {activeIndex === 0 && (
              <Div
                width={820}
                mb={6}
                py={4}
                px={14}
                bg="neutral0"
                style={{
                  borderRadius: "6px"
                }}
              >
                <WelcomeLoginSection handlers={handlers} />
              </Div>
            )}
            {activeIndex !== 0 && (
              <PoweredbyLennd
                style={{
                  margin: "20px 0"
                }}
              />
            )}
          </Div>
        </Default>
      </Div>
    </>
  );
};

export default R.compose(
  withRouter,
  decorate
)(Layout);
