import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const STATUS_TITLES = {
  approved: "Approved",
  paid: "Paid",
  fulfilled: "Fulfilled",
  pending: "Pending",
  not_fulfilled: "Not Fulfilled",
  pending_names: "Pending Names",
  partial_payment: "Partial Payment",
  partial_review: "Partial Review",
  partially_fulfilled: "Partially Fulfilled",
  denied: "Denied",
  free_order: "Free Order",
  authorized: "Authorized"
};

const Circle = CSSModules(({ fill, color }) => {
  if (fill === "empty") {
    return <div styleName="circleBorder" style={{ borderColor: color }} />;
  } else if (fill === "half") {
    return (
      <div styleName="circleBorder" style={{ borderColor: color }}>
        <div styleName="halfCircle" style={{ backgroundColor: color }} />
      </div>
    );
  }
  return <div styleName="circleFilled" />;
}, css);

const OrderStatus = CSSModules(({ value }) => {
  const status = value.value;
  const title = STATUS_TITLES[status];
  let badgeColor;
  let textColor;
  let circleColor;
  let circle;

  if (["approved", "paid", "fulfilled"].includes(status)) {
    badgeColor = "#ECECEC";
    circle = <Circle fill="filled" />;
  } else if (["pending", "not_fulfilled", "pending_names"].includes(status)) {
    badgeColor = "#FEE990";
    circleColor = "#736D52";
    textColor = "#433F35";
    circle = <Circle fill="empty" color={circleColor} />;
  } else if (
    ["partial_payment", "partial_review", "partially_fulfilled"].includes(
      status
    )
  ) {
    badgeColor = "#FDD4AF";
    circleColor = "#CF8340";
    textColor = "#433F35";
    circle = <Circle fill="half" color={circleColor} />;
  } else if (["denied"].includes(status)) {
    badgeColor = "#F0D1D1";
    circleColor = "#B22C2C";
    textColor = "#B22C2C";
    circle = <Circle fill="filled" color={circleColor} />;
  } else if (["free_order"].includes(status)) {
    badgeColor = "#FFFFFF";
    circleColor = "#E8E8E8";
    textColor = "#666666";
    circle = <Circle fill="filled" color={circleColor} />;
  } else if (["authorized"].includes(status)) {
    badgeColor = "#ECECEC";
    circleColor = "#6E6E6E";
    textColor = "#595959";
    circle = <Circle fill="empty" color={circleColor} />;
  }

  return (
    <div styleName="badge" style={{ backgroundColor: badgeColor }}>
      {circle}
      <span styleName="label" style={{ color: textColor }}>
        {title}
      </span>
    </div>
  );
}, css);

OrderStatus.propTypes = {
  value: PropTypes.shape({
    value: PropTypes.oneOf([
      "approved",
      "paid",
      "fulfilled",
      "pending",
      "not_fulfilled",
      "pending_names",
      "partial_payment",
      "partial_review",
      "partially_fulfilled",
      "denied",
      "free_order",
      "authorized"
    ])
  }).isRequired
};

export default OrderStatus;
