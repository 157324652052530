import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Controller from "./Controller";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { addRecord } from "redux/modules/modules/records/actions";
import { getSubmissions } from "redux/modules/formsV2/submissions/actions";

import { records } from "redux/modules/modules/records/selectors";
import { eventId, eventDetails } from "redux/modules/event/selectors";
import { user, getCredentials } from "redux/modules/user/selectors";
import { applications } from "redux/modules/applications/selectors";
import { references } from "redux/modules/entityReferences/selectors";
import { submissions } from "redux/modules/formsV2/submissions/selectors";

function mapStateToProps(state, props) {
  const applicationsToPass = applications(state);
  const eventDetailsToPass = eventDetails(state);
  const formMeta =
    applicationsToPass.find(f => f.id === props.params.applicationFormId) || {};

  return {
    eventId: eventId(state),
    eventSlug: eventDetailsToPass.slug,
    userId: user(state).id,
    userCredentials: getCredentials(state),
    formMeta,
    eventDetails: eventDetailsToPass,
    records: records(state, props.params.applicationFormId),
    references: references(state),
    submissions: submissions(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal,
      hideModal,
      showSnackbar,
      addRecord,
      getSubmissions
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
