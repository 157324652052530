import React from "react";
import * as R from "ramda";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import { actions, getters } from "../model";
import css from "./styles.scss";
import { getEditGroupOrPerson } from "../selectors";
import formatCloseDate from "components/Event/Module/Form/utils/format-close-date";
import DatePicker from "components/Event/FormsV2/Modals/FormEditModal/Calendar";
import { Div, BigOutlineButton, BigFilledButton } from "components/Base";

const decorate = R.compose(
  connect(
    state => ({
      groupOrPerson: getEditGroupOrPerson(state),
      customCloseDate: getters.customCloseDate(state)
    }),
    {
      setCustomCloseDate: actions.setCustomCloseDate,
      setEditDueDateModalId: actions.setEditDueDateModalId,
      updateDueDate: actions.updateDueDate
    }
  ),
  CSSModules(css)
);

const EditCloseDateModal = ({
  groupOrPerson: { name, close_date: closeDate },
  hideCreateModal,
  requestId = "",
  customCloseDate,
  setCustomCloseDate,
  setEditDueDateModalId,
  updateDueDate
}) => {
  return (
    <MiniModalWrapper
      showModal={true}
      hideModal={hideCreateModal}
      title={`${R.isEmpty(requestId) ? "Create new" : "Edit"} file request`}
      width={390}
      height={675}
      stopPropagation
      isPortal={true}
    >
      <Div bg="white" p={5}>
        <Div mb={4}>
          <Div fw={3} color="gray7" mb={1}>
            Name:
          </Div>
          <Div>{name}</Div>
        </Div>

        <Div mb={4}>
          <Div fw={3} color="gray7">
            Specify a due date:
          </Div>
          <Div fs={1} color="neutral6" mb={2}>
            Optional. If no due date is specified, the request's due date{" "}
            {closeDate ? `(${formatCloseDate(closeDate)})` : null} will be
            applied.
          </Div>
          <DatePicker value={customCloseDate} onChange={setCustomCloseDate} />
        </Div>

        <Div display="row.flex-start.center">
          <BigFilledButton bg="primary7" mr={2} onClick={updateDueDate}>
            Save
          </BigFilledButton>
          <BigOutlineButton onClick={() => setEditDueDateModalId("")}>
            Cancel
          </BigOutlineButton>
        </Div>
      </Div>
    </MiniModalWrapper>
  );
};

export default decorate(EditCloseDateModal);
