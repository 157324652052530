import React from "react";
import { connect } from "react-redux";

import { actions } from "../model";
import { FILTERS, ACTIVITY_INSTANCE_ID } from "../constants";

import { Div, Text3 } from "components/Base";

import { getFilterUser, getFilterByActivityType } from "../selectors";

import { VARIANTS } from "ui-kit/Theme/constants";
import UISearchBar from "ui-kit/SearchBar/View";
import FilterBy from "./FilterBy";

const decorate = connect(
  (state, props) => ({
    users: getFilterUser(state, props),
    activityTypes: getFilterByActivityType(state, props)
  }),
  {
    setFilter: actions.setFilter
  }
);

const Toolbar = ({ users, activityTypes }) => (
  <Div
    height={68}
    width={1}
    bra={2}
    bg="white"
    display="row.space-between.center"
    px={5}
    style={{
      boxShadow:
        "rgba(0, 0, 0, 0.04) 0px 8px 20px, rgba(0, 0, 0, 0.01) 0px 35px 54px"
    }}
  >
    <Div display="row.flex-start.center">
      <Text3>Filter by</Text3>
      <FilterBy
        type={FILTERS.BY_ACTIVITY_TYPE}
        elements={activityTypes}
        name="Activity type"
        ml={3}
      />
      <FilterBy
        type={FILTERS.BY_USERS}
        elements={users}
        ml={2}
        name="By User"
      />
    </Div>
    <Div display="row.flex-start.center">
      <UISearchBar
        instanceId={ACTIVITY_INSTANCE_ID}
        variant={VARIANTS.SURFACE}
        flex={1}
        mr={2}
        placeholder="Search activity"
        size="medium"
        style={{
          maxWidth: 445
        }}
      />
    </Div>
  </Div>
);

export default decorate(Toolbar);
