import { createSelector } from "reselect";

import * as R from "ramda";

import { getters } from "./model";

export const selectedCourse = createSelector(
  getters.courses,
  getters.currentCourseId,
  (courses, currentCourseId) =>
    R.find(R.propEq("id", currentCourseId), courses) || {}
);
