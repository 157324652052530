import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Form from "./Form";

import {
  updateFieldOrder,
  dropFieldAndUpdateFieldOrder,
  addSubformField,
  updateSubformField,
  deleteSubformField,
  bulkUpdateSubformFields,
  toggleEditFieldDescription,
  addField as addFormField
} from "redux/modules/formsV2/form/fields/actions";
import { getItemBlocks } from "redux/modules/items/item-blocks/actions";
import * as FormActions from "redux/modules/formsV2/form/actions";
import {
  toggleField,
  toggleFields
} from "redux/modules/formsV2/form/fields/customerBlockFields/actions";
import { addField as addModuleField } from "redux/modules/modules/fields/actions";

import * as ModalActions from "redux/modules/modal/actions";
import * as SnackbarActions from "redux/modules/snackbar/actions";

import * as Selectors from "redux/modules/formsV2/form/selectors";
import * as flags from "utils/feature-flags";
import { selectFeatureFlag } from "@flopflip/react-redux";

function mapStateToProps(state) {
  return {
    form: Selectors.form(state),
    sortedFormFields: Selectors.sortedFormFields(state),
    eventDetails: Selectors.eventDetails(state),
    preferences: Selectors.preferences(state),
    isFetching: Selectors.isFetching(state),
    errorMessages: [state.formsV2.form.error].filter(e => e),
    canShowSimpleSideBar: selectFeatureFlag(flags.SIMPLE_FORMS.NAME)(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        ...FormActions,
        ...ModalActions,
        ...SnackbarActions,
        addModuleField,
        toggleField,
        toggleFields,
        getItemBlocks,
        addFormField,
        updateFieldOrder,
        dropFieldAndUpdateFieldOrder,
        addSubformField,
        updateSubformField,
        deleteSubformField,
        bulkUpdateSubformFields,
        toggleEditFieldDescription
      },
      dispatch
    ),
    dispatch
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form);
