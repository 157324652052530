import React from "react";
import { Div, FontIcon, DownFilledIcon, Text4, Popover } from "components/Base";
import Circle from "ui-kit/Circle";

const MenuItem = ({ onClick, children }) => (
  <Div
    bg={{ default: "white", hover: "neutral0" }}
    transition="fast"
    onClick={onClick}
    p={3}
    fw={3}
    color="black"
  >
    {children}
  </Div>
);

const QuickActions = ({
  showCreateOrderModal,
  showAddFormModal,
  showAddEventTeamUserModal,
  showAddAccountModal,
  showAddContactModal,
  showSubmitFormModal
}) => (
  <Popover
    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    transformOrigin={{ horizontal: "right", vertical: "top " }}
    PaperProps={{
      style: {
        borderRadius: 4
      }
    }}
    Label={({ onClick }) => (
      <Div
        bg="white"
        bra={1}
        width={350}
        height={64}
        mt={2}
        display="row.flex-start.center"
        style={{
          cursor: "pointer",
          boxShadow:
            "0px 8px 20px rgba(0, 0, 0, 0.04), 0px 35px 54px rgba(0, 0, 0, 0.01)"
        }}
        p={1}
        onClick={onClick}
      >
        <Div
          height={1}
          bg={{ default: "white", hover: "neutral0" }}
          bra={1}
          transition="fast"
          width={1}
          display="row.space-between.center"
          pl={3}
          pr={4}
        >
          <Div display="row.flex-start.center">
            <Circle size={40} bg="neutral1">
              <FontIcon size={24}>flash_on</FontIcon>
            </Circle>
            <Text4 ml={3} bold>
              Quick Actions
            </Text4>
          </Div>
          <DownFilledIcon size={20} />
        </Div>
      </Div>
    )}
  >
    {({ closePopover }) => {
      return (
        <Div bg="white" width={245}>
          {showCreateOrderModal ? (
            <MenuItem
              onClick={() => {
                closePopover();
                showCreateOrderModal();
              }}
            >
              Create order
            </MenuItem>
          ) : null}
          {/*
          {showCreateOrderModal ? (
            <MenuItem
              onClick={() => {
                closePopover();
                showSubmitFormModal();
              }}
            >
              Submit Form
            </MenuItem>
          ) : null}
          */}
          {showAddAccountModal ? (
            <MenuItem
              onClick={() => {
                closePopover();
                showAddAccountModal();
              }}
            >
              Add group
            </MenuItem>
          ) : null}
          {showAddContactModal ? (
            <MenuItem
              onClick={() => {
                closePopover();
                showAddContactModal();
              }}
            >
              Add person
            </MenuItem>
          ) : null}
          {/*
          {showAddFormModal ? (
            <MenuItem
              onClick={() => {
                closePopover();
                showAddFormModal();
              }}
            >
              Create new form
            </MenuItem>
          ) : null}
            */}
          {showAddEventTeamUserModal ? (
            <MenuItem
              onClick={() => {
                closePopover();
                showAddEventTeamUserModal();
              }}
            >
              Invite new user
            </MenuItem>
          ) : null}
        </Div>
      );
    }}
  </Popover>
);

export default QuickActions;
