import React from "react";
import FileEditorFactory from "./Factory";
import View from "./View";

const FileUploadEditor = props => (
  <FileEditorFactory {...props}>
    <View />
  </FileEditorFactory>
);

export default FileUploadEditor;
