import React from "react";
import formCloseDateHasExpired from "components/Event/FormsV2/Utils/form-close-date-has-expired";
import moment from "moment";

export default form => {
  if (form.custom_close_date) {
    if (formCloseDateHasExpired(form.custom_close_date)) {
      return (
        <span>
          Closed on {moment(form.custom_close_date).format("MMMM Do")}
        </span>
      );
    } else {
      return (
        <span>
          Closes on {moment(form.custom_close_date).format("MMMM Do")}
        </span>
      );
    }
  }

  if (form.close_date) {
    if (formCloseDateHasExpired(form.close_date)) {
      return <span>Closed on {moment(form.close_date).format("MMMM Do")}</span>;
    } else {
      return <span>Closes on {moment(form.close_date).format("MMMM Do")}</span>;
    }
  }

  return null;
};
