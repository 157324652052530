import { get, find } from "lodash";
import getValue from "utils/value-types/get-value";
import toString from "utils/value-types/to-string";
import * as SYSTEM_FIELD_IDS from "utils/system-field-ids";

const { ID } = SYSTEM_FIELD_IDS;

export default function(value, { references, fieldId }) {
  const { moduleId, records } = value || {}; // protects against value: null
  const moduleDetails = get(references, [moduleId], {});
  const primaryField = find(get(moduleDetails, "fields.fields", []), {
    id: fieldId
  });

  if (!primaryField || !records || !records.length) {
    return "";
  }

  return records
    .map(recordId => {
      const record = get(moduleDetails, ["records", recordId], {});

      // if we're handling the record ID, return the friendly version
      if (primaryField.id === ID) {
        return `${record.record_prefix}-${record.friendly_id}`;
      }

      return toString(
        getValue(get(record, ["values", primaryField.id]), primaryField.type),
        primaryField.type
      );
    })
    .join(", ");
}
