import { createModule } from "redux-mvc";

import model from "./model";

import sagasModule from "./sagas";
import addFileModalModule from "EventLight/FileRequests/AddFileModal";

const module = createModule(model);

module.plugModule(addFileModalModule);

module.setRootSaga(sagasModule);

export default module;
