import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE } from "./constants";

const iniState = {
  questions: [],
  sets: [],
  questionsFilter: "",
  existingSetsFilter: "",
  loading: false,
  selectedQuestionIds: [],
  itemQuestionsIds: [],
  setsIds: [],
  questionSetData: { id: -1, name: "" }
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    applySetItems: R.identity,
    init: R.identity,
    refetchSets: R.identity,
    refetchQuestions: R.identity,
    deleteQuestionSet: R.identity,
    deleteQuestion: R.identity,
    setInitialData: (state, { payload: { questions, sets } }) => ({
      questions,
      sets
    }),
    toggleItemQuestion: (state, { payload: { isEnabled, id } }) => ({
      itemQuestionsIds: isEnabled
        ? R.filter(questionId => questionId !== id, state.itemQuestionsIds)
        : [...state.itemQuestionsIds, id]
    }),
    toggleAllQuestions: state => ({
      itemQuestionsIds:
        R.length(state.itemQuestionsIds) === R.length(state.questions)
          ? []
          : R.map(R.prop("id"), state.questions)
    }),
    toggleSet: (state, { payload: { isEnabled, id } }) => ({
      setsIds: isEnabled
        ? R.filter(setId => setId !== id, state.setsIds)
        : [...state.setsIds, id]
    }),
    toggleAllSets: state => ({
      setsIds:
        R.length(state.setsIds) === R.length(state.sets)
          ? []
          : R.map(R.prop("id"), state.sets)
    })
  }
});

export default handlers;
