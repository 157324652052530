import { put, all, takeEvery, fork, select, call } from "redux-saga/effects";
import * as R from "ramda";
import * as STANDARD_MODULE_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import { actions, getters } from "Portal/PortalPreviewProfile/model";
import { registerError } from "redux/modules/errors/actions";
import { push } from "react-router-redux";

import Api from "./api";
import SubmissionApi from "redux/modules/formsV2/submission/api";
import RecordsApi from "redux/modules/modules/records/api";
import AddValueApi from "redux/modules/modules/values/api";

import { portalUser as getPortalUser } from "redux/modules/portal/user/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import { user as getUser } from "redux/modules/user/selectors";

import { eventId as getEventId } from "redux/modules/event/selectors";
import { eventId as getPortalEventId } from "redux/modules/portal/selectors";

import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { eventDetails as getPortalEventDetails } from "redux/modules/portal/selectors";

const init = function*({
  payload: { incomingAccountId, redirectToDashboardRecordTypeId }
}) {
  try {
    const credentials = yield select(getCredentials);
    const portalUser = yield select(getPortalUser);

    let eventId = yield select(getEventId);
    if (!eventId) {
      eventId = yield select(getPortalEventId);
    }

    let accountIdToUse = incomingAccountId;
    if (!accountIdToUse) {
      const isViewingAsAccount = R.pathEq(["active_view", "type"], "account")(
        portalUser
      );
      accountIdToUse = isViewingAsAccount
        ? R.path(["active_view", "id"], portalUser)
        : null;
    }

    const { payload } = yield call(Api.getProfile, {
      credentials,
      accountId: accountIdToUse,
      eventId
    });

    yield all([
      put(
        actions.setInitialData({
          ...payload,
          accountId: accountIdToUse,
          redirectToDashboardRecordTypeId
        })
      )
    ]);
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred geting profile"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const approveProfile = function*() {
  try {
    const credentials = yield select(getCredentials);

    let eventId = yield select(getEventId);
    if (!eventId) {
      eventId = yield select(getPortalEventId);
    }

    const accountId = yield select(getters.accountId);

    yield call(AddValueApi.post, credentials, {
      eventId,
      fieldId: STANDARD_MODULE_FIELD_IDS.ACCOUNTS.PROFILE_APPROVAL,
      recordId: accountId,
      moduleId: STANDARD_MODULE_IDS.accounts.id,
      value: {
        type: "boolean",
        value: true
      }
    });

    yield put(actions.setIsApproved(true));
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred approving profile"
          }
        ])
      )
    ]);
  }
};

const goToSubmission = function*() {
  let eventDetails = yield select(getEventDetails);
  if (!eventDetails || !eventDetails.id) {
    eventDetails = yield select(getPortalEventDetails);
  }

  const user = yield select(getUser);
  const credentials = yield select(getCredentials);

  const accountId = yield select(getters.accountId);
  const formId = yield select(getters.editProfileFormId);
  const formSlug = yield select(getters.editProfileFormSlug);
  const formBaseModuleId = yield select(getters.editProfileFormBaseModuleId);
  const submissionId = yield select(getters.editProfileSubmissionId);
  const redirectToDashboardRecordTypeId = yield select(
    getters.redirectToDashboardRecordTypeId
  );

  if (formId && !submissionId) {
    try {
      yield put(actions.setCreatingSubmission(true));

      const { record } = yield call(RecordsApi.post, credentials, {
        moduleId: formBaseModuleId,
        record: {
          isDraft: true
        },
        options: {
          eventId: eventDetails.id
        }
      });

      const { submission } = yield call(SubmissionApi.post, credentials, {
        formId,
        eventId: eventDetails.id,
        userId: user.id,
        accountId,
        submissionRecordId: record.id
      });

      if (redirectToDashboardRecordTypeId) {
        yield put(
          push(
            `/submissions/${eventDetails.slug}/${formSlug}/${submission.id}?redirectToDashboard=1&moduleId=${STANDARD_MODULE_IDS.accounts.id}&recordTypeId=${redirectToDashboardRecordTypeId}`
          )
        );
      } else {
        yield put(
          push(`/submissions/${eventDetails.slug}/${formSlug}/${submission.id}`)
        );
      }
    } catch (e) {
      yield all([
        put(
          registerError([
            {
              system: e,
              user: "An error occurred adding a submission"
            }
          ])
        )
      ]);
    } finally {
      yield put(actions.setCreatingSubmission(false));
    }
  } else if (formId && submissionId) {
    if (redirectToDashboardRecordTypeId) {
      window.location = `/submissions/${eventDetails.slug}/${formSlug}/${submissionId}?redirectToDashboard=1&moduleId=${STANDARD_MODULE_IDS.accounts.id}&recordTypeId=${redirectToDashboardRecordTypeId}`;
    } else {
      window.location = `/submissions/${eventDetails.slug}/${formSlug}/${submissionId}`;
    }
  }
};

const goToForms = function*() {
  let eventDetails = yield select(getEventDetails);
  if (!eventDetails || !eventDetails.id) {
    eventDetails = yield select(getPortalEventDetails);
  }
  yield put(push(`/portals/${eventDetails.slug}/${eventDetails.uuid}`));
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchGoToForms = function*() {
  yield takeEvery(actions.goToForms.type, goToForms);
};

const watchApproveProfile = function*() {
  yield takeEvery(actions.approveProfile.type, approveProfile);
};

const watchGoToSubmission = function*() {
  yield takeEvery(actions.goToSubmission.type, goToSubmission);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchGoToForms),
    fork(watchApproveProfile),
    fork(watchGoToSubmission)
  ]);
};

export default rootSaga;
