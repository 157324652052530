import { createHandlers } from "redux-mvc";

const model = createHandlers({
  iniState: {
    loading: true,
    page: {}
  },
  reducers: {
    fetchResponse: (_, { payload: page }) => ({
      loading: false,
      page
    })
  },
  namespace: "HubPage"
});

const { actions, getters } = model;

export { actions, getters };

export default model;
