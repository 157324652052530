import * as R from "ramda";
import { enableBatching } from "redux-batched-actions";

import createSagaMiddleware from "redux-saga";
import decorateOptimist from "redux-optimist";
import { createStore, applyMiddleware } from "redux";
import { bridge } from "./middleware";
import { composeEnhancers } from "./createStore";
import { makeReducer } from "./reducer-utils";

import { noop } from "utils/General";

const makeSetter = key => (instance, { [key]: val, contextInstance }) => {
  if (contextInstance) {
    if (!instance[contextInstance]) {
      instance[contextInstance] = { [key]: val };
    }
    instance[contextInstance][key] = val;
  }
  instance[key] = val;
  return instance;
};

const makeGetter = (key, def) => (instance, { contextInstance }) => {
  if (!contextInstance) {
    return instance[key] || def;
  }
  return R.pathOr(def, [contextInstance, key], instance);
};

const setStore = makeSetter("store");
const getStore = makeGetter("store");

const setSagaMiddleware = makeSetter("sagaMiddleware");
const getSagaMiddleware = makeGetter("sagaMiddleware");

const setSagaTasks = makeSetter("sagaTasks");
const getSagaTasks = makeGetter("sagaTasks", []);

const setRunningInstances = makeSetter("runningInstances");
const getRunningInstances = makeGetter("runningInstances", []);

export const createModule = ({
  iniState,
  reducer,
  namespace,
  optimist = false,
  batch = false,
  ...rest
}) => {
  const l = {
    runBridge: noop,
    stopBridge: noop,
    sagaMiddleware: undefined,
    namespace
  };

  const module = {
    ...rest,
    reducer,
    iniState,
    namespace,
    modules: [namespace],
    all: [],
    optimist,
    batch,
    plugModule(r) {
      this.modules = R.uniq(R.concat(this.modules, r.modules));
      this.all = R.uniqBy(R.prop("namespace"), R.concat(this.all, r.all));
      this.iniState = R.merge(this.iniState, r.iniState);
      this.optimist = this.optimist || r.optimist;
      this.batch = this.batch || r.batch;

      this.reducer = R.reduce(
        (reducer, f) => (f ? f(reducer) : reducer),
        makeReducer(
          R.mergeAll(R.map(R.prop("handlers"), this.all)),
          this.iniState,
          this.namespace
        ),
        [this.optimist && decorateOptimist, this.batch && enableBatching]
      );
    },
    createStore({
      globalStore,
      middleware = [],
      dispatchToGlobal,
      observedDomains = [],
      persist = true,
      contextInstance
    }) {
      if (persist && getStore(this, { contextInstance })) {
        return {
          store: getStore(this, { contextInstance }),
          runBridge: l.runBridge,
          stopBridge: l.stopBridge
        };
      }

      const moduleObservedDomains = R.uniq(
        R.concat(
          observedDomains,
          R.flatten(R.map(R.propOr([], "observedDomains"), this.all))
        )
      );

      const moduleMiddleware = [];

      if (globalStore) {
        const { middleware: bridgeMiddleware, unsubscribe, subscribe } = bridge(
          moduleObservedDomains,
          dispatchToGlobal,
          this
        )(globalStore);

        l.stopBridge = unsubscribe;
        l.runBridge = subscribe;

        moduleMiddleware.push(bridgeMiddleware);
      } else {
        l.stopBridge = noop;
        l.runBridge = noop;
      }

      const sagaMiddleware = createSagaMiddleware();

      setSagaMiddleware(this, {
        sagaMiddleware,
        contextInstance
      });

      moduleMiddleware.push(sagaMiddleware);

      const store = createStore(
        this.reducer,
        this.iniState, // very important otherwise bridge wont work
        composeEnhancers({
          name: namespace,
          serialize: {
            replacer: (key, value) => {
              if (
                value &&
                R.has("dispatchConfig", value) &&
                R.has("_targetInst", value)
              ) {
                return "[EVENT]";
              }
              return value;
            }
          }
        })(applyMiddleware(...moduleMiddleware, ...middleware))
      );

      setStore(this, { contextInstance, store });

      return {
        store,
        runBridge: l.runBridge,
        stopBridge: l.stopBridge
      };
    },

    run({ contextInstance, transactionId } = {}) {
      const runningInstances = getRunningInstances(this, { contextInstance });
      setRunningInstances(this, {
        contextInstance,
        runningInstances: [...runningInstances, transactionId]
      });
      getSagaTasks(this, { contextInstance }).forEach(task => task.cancel());
      const sagas = R.filter(
        R.compose(
          R.not,
          R.isNil
        ),
        R.map(R.prop("sagas"), this.all)
      );
      l.runBridge();

      const sagaMiddleware = getSagaMiddleware(this, { contextInstance });

      setSagaTasks(this, {
        contextInstance,
        sagaTasks: sagas.map(saga => sagaMiddleware.run(saga))
      });
    },

    cancel({ contextInstance, transactionId } = {}) {
      const runningInstances = R.without(
        [transactionId],
        getRunningInstances(this, { contextInstance })
      );

      if (R.isEmpty(runningInstances)) {
        const store = getStore(this, { contextInstance });
        this.modules.forEach(ns =>
          store.dispatch({
            type: `${ns}/cancelInstance`,
            namespace: ns
          })
        );
        l.stopBridge();
        getSagaTasks(this, { contextInstance }).forEach(task => task.cancel());
      }

      setRunningInstances(this, { contextInstance, runningInstances });
    },

    setRootSaga(rootSaga) {
      this.sagas = rootSaga;
    }
  };

  module.all = [module];

  return module;
};
