import React, { Component } from "react";
import { BaseHeader } from "components/Global/Modal/Layout/Headers";
import { CONTACTS } from "utils/standard-module-field-ids";
import {
  Div,
  MediumTextButton,
  Text2,
  ReplyIcon,
  BoxGroup,
  MediumOutlineButton,
  BigFilledBox,
  BigFilledButton,
  BigOutlineBox
} from "components/Base";
import Api from "utils/EventContacts/WebAPIUtils";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import * as R from "ramda";
import { withState } from "utils/General";
import { search } from "redux/modules/accounts/list/api";

import { connect } from "react-redux";
import { user, getCredentials } from "redux/modules/user/selectors";

const decorate = connect(state => ({
  userId: user(state).id,
  userCredentials: getCredentials(state)
}));

class CateringCheckinSearch extends Component {
  constructor(props) {
    super(props);
    this.contactInput = React.createRef();
    this.accountInput = React.createRef();
    this.state = {
      searchingContacts: false,
      searchingAccounts: false
    };
  }

  componentDidMount() {
    if (this.contactInput.current) {
      this.contactInput.current.focus();
    }
  }

  handleSearch = search => async () => {
    if (!this.props.searchTerm) {
      return false;
    }

    this.props.onSearch(true);
    const results = await search();
    this.props.onSearch(false);

    return this.props.onResultsChange(results);
  };

  searchAccounts = async () => {
    this.setState({ searchingAccounts: true });
    const { accounts } = await search(
      this.props.orgId,
      this.props.eventId,
      this.props.searchTerm,
      this.props.userCredentials
    );
    this.setState({ searchingAccounts: false });
    return accounts;
  };

  searchContacts = async () => {
    this.setState({ searchingContacts: true });
    const contacts = await Api.searchContacts(
      this.props.userCredentials,
      null,
      this.props.eventId,
      this.props.searchTerm
    );
    this.setState({ searchingContacts: false });
    return contacts.map(c => ({
      id: c.id,
      name: [c.values[CONTACTS.FIRST_NAME], c.values[CONTACTS.LAST_NAME]]
        .join(" ")
        .trim()
    }));
  };

  handleScan = id => {
    this.props.onScan(id, this.props.activeInput);
    this.props.hideModal();
  };

  handleFocus = e => {
    this.props.onActiveInputFocus(e.target.name);
  };

  reset = () => this.props.onResultsChange(undefined);

  render() {
    const { activeInput, onSearchChange, results } = this.props;
    if (results) {
      return (
        <StyleWrapper
          hideModal={this.props.hideModal}
          heading="Search Results"
          Header={BaseHeader}
          bodyStyles={{ padding: 0 }}
        >
          <Div bg="neutral1" p={4}>
            <Div pb={2} display="row.space-between.center">
              <MediumTextButton
                color="info5"
                onClick={this.reset}
                LeftIcon={ReplyIcon}
              >
                Search Again...
              </MediumTextButton>
              <Text2 bold>{results.length} results</Text2>
            </Div>
            <Div
              style={{
                maxHeight: 250,
                overflowY: "auto"
              }}
            >
              {results.map(r => (
                <BigFilledBox
                  mb={2}
                  key={r.id}
                  bg="white"
                  display="row.space-between.center"
                >
                  <Text2 bold>{r.name}</Text2>
                  <MediumOutlineButton onClick={() => this.handleScan(r.id)}>
                    Scan
                  </MediumOutlineButton>
                </BigFilledBox>
              ))}
            </Div>
          </Div>
        </StyleWrapper>
      );
    }
    return (
      <StyleWrapper
        hideModal={this.props.hideModal}
        heading="Search to scan..."
        Header={BaseHeader}
      >
        <Div>
          <Text2 pb={1} bold>
            Name of person
          </Text2>
          <form
            method="post"
            onSubmit={e => {
              e.preventDefault();
              this.handleSearch(this.searchContacts)();
            }}
          >
            <BoxGroup display="row.flex-start.center">
              <BigOutlineBox flex={1} p={0}>
                <input
                  name="contact"
                  onFocus={this.handleFocus}
                  onChange={e => onSearchChange(e.target.value)}
                  ref={this.contactInput}
                  style={{
                    flex: 1,
                    border: 0
                  }}
                />
              </BigOutlineBox>
              <BigFilledButton
                onClick={this.handleSearch(this.searchContacts)}
                bg={activeInput === "contact" ? "altB5" : "neutral4"}
              >
                {this.state.searchingContacts ? "Searching..." : "Search"}
              </BigFilledButton>
            </BoxGroup>
          </form>
        </Div>

        <Div display="row.center.center" py={3}>
          <Div h={1 / 4} bg="neutral4" flex={1} />
          <Text2 bold uppercase mx={2}>
            or
          </Text2>
          <Div h={1 / 4} bg="neutral4" flex={1} />
        </Div>

        <Div pb={3}>
          <Text2 pb={1} bold>
            Name of Group, Company, or Department
          </Text2>
          <form
            method="post"
            onSubmit={e => {
              e.preventDefault();
              this.handleSearch(this.searchAccounts)();
            }}
          >
            <BoxGroup display="row.flex-start.center">
              <BigOutlineBox flex={1} p={0}>
                <input
                  name="account"
                  onFocus={this.handleFocus}
                  onChange={e => onSearchChange(e.target.value)}
                  ref={this.accountInput}
                  style={{
                    flex: 1,
                    border: 0
                  }}
                />
              </BigOutlineBox>
              <BigFilledButton
                onClick={this.handleSearch(this.searchAccounts)}
                bg={activeInput === "account" ? "altB5" : "neutral4"}
              >
                {this.state.searchingAccounts ? "Searching..." : "Search"}
              </BigFilledButton>
            </BoxGroup>
          </form>
        </Div>
      </StyleWrapper>
    );
  }
}

CateringCheckinSearch.propTypes = {};

export default R.compose(
  decorate,
  withState("results", "onResultsChange"),
  withState("searching", "onSearch", false),
  withState("activeInput", "onActiveInputFocus"),
  withState("searchTerm", "onSearchChange")
)(CateringCheckinSearch);
