import React from "react";

import { createContext } from "redux-mvc";

import { connect } from "react-redux";

import * as R from "ramda";

import module, { actions } from "../index";
import { addS } from "utils/General";

import Layout from "./Layout";

import rootSaga from "../sagas";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import RunReportModal from "Reports/RunReportModal/View";
import DeleteConfirmation from "components/Global/CRM/Modals/DeleteConfirmation";
import BulkEdit from "components/Global/CRM/Modals/BulkEdit";
import SendEmail from "SendEmailModal/View";
import SelectScheduleFieldsModal from "Schedules/SelectScheduleFieldsModal/View";
import ImportModal from "Modules/ImportModal/View";
import AddScheduleModal from "Schedules/Create/View";

import { hideModal, showModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { addValues } from "redux/modules/modules/values/actions";
import {
  addRecords,
  deleteRecord,
  updateType
} from "redux/modules/modules/records/actions";
import { bulkRelateRecords } from "redux/modules/accounts/values/actions";

import { eventDetails } from "redux/modules/event/selectors";

import { withRouter } from "react-router";

import { TABLE_INSTANCE_ID } from "Schedules/Schedule/constants";
import { actions as TableActions } from "ui-kit/Table/model";

module.setRootSaga(rootSaga);

const decorate = createContext({
  module: module,
  handlers: {
    goToSetup() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/settings/schedules/fields`
      });
    },
    showAddScheduleModal() {
      this.props.showModal({
        content: <AddScheduleModal />,
        wrapper: ModalWrapper
      });
    },
    showSelectFieldsModal(preferences) {
      this.props.showModal({
        content: (
          <SelectScheduleFieldsModal
            moduleId={this.props.params.moduleId}
            visibleFields={preferences.visible_fields}
            fieldOrder={preferences.field_order}
            onSave={fields => {
              this.store.dispatch(actions.updateViewFields(fields));
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showAddRecordModal({ moduleId }) {
      this.props.showModal({
        content: (
          <AddRecordModal
            moduleId={moduleId}
            onSave={() => {
              this.store.dispatch(actions.fetchData());
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    refreshRecords() {
      this.store.dispatch(actions.fetchData());
    },
    groupBy(column) {
      this.store.dispatch(actions.groupBy(column));
    },
    showImportModal() {
      this.props.showModal({
        content: (
          <ImportModal
            moduleId={this.props.params.moduleId}
            onDone={() => {
              this.store.dispatch(actions.fetchData());
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showBulkEditModal({ toggledRows = [], fields = [], references = {} }) {
      this.props.showModal({
        content: (
          <BulkEdit
            selected={toggledRows}
            getMetaData={(rowData, column) => ({
              ...rowData,
              meta: {
                // userId: this.props.user.id,
                orgId: this.props.params.orgId,
                eventId: this.props.params.eventId,
                contactId: rowData.id,
                rowId: rowData.id,
                columnId: column.id,
                columnSettings: column.settings,
                eventDetails: this.props.eventDetails,
                references
              },
              helpers: {
                showModal: this.props.showModal,
                addReference: this.props.addReference
              }
            })}
            columns={fields}
            onSave={async data => {
              await this.props.addValues({
                moduleId: this.props.params.moduleIds,
                values: toggledRows.map(recordId => ({ recordId, ...data }))
              });

              this.props.showSnackbar({
                message: "Records updated",
                action: "OK"
              });
              this.store.dispatch(actions.fetchData());
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showRunReportModal(toggledRows) {
      this.props.showModal({
        content: (
          <RunReportModal
            moduleId={this.props.params.moduleId}
            recordIds={toggledRows}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showSendEmailModal(
      toggledRows,
      { selectedOptions, subject, message } = {}
    ) {
      this.props.showModal({
        content: (
          <SendEmail
            moduleId={this.props.params.moduleId}
            records={toggledRows}
            selectedOptions={selectedOptions}
            subject={subject}
            message={message}
            onDone={() => {}}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    openInNewWindow(url) {
      window.open(url, "_blank");
    },
    showDeleteRecordsModal(toggledRows) {
      const deleteRecords = async () => {
        await Promise.all(
          toggledRows.map(recordId =>
            this.props.deleteRecord({
              moduleId: this.props.params.moduleId,
              orgId: this.props.params.orgId,
              eventId: this.props.params.eventId,
              record: { id: recordId },
              options: {
                orgId: this.props.params.orgId,
                eventId: this.props.params.eventId
              }
            })
          )
        );
        this.props.showSnackbar({
          message: `Record${addS(toggledRows.length)} deleted`,
          action: "OK"
        });
        this.store.dispatch(
          TableActions.clearSelectedRows(null, {
            meta: {
              instanceId: TABLE_INSTANCE_ID
            }
          })
        );
        this.store.dispatch(actions.fetchData());
      };
      this.props.showModal({
        content: (
          <DeleteConfirmation
            hideModal={this.props.hideModal}
            heading={`Delete Record${addS(toggledRows.length)}?`}
            message={
              <div>
                {`
                  Are you sure you want to remove
                  ${
                    toggledRows.length === 1
                      ? "this"
                      : `these ${toggledRows.length}`
                  }
                  record${addS(toggledRows.length)}?
                  `}
                <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                  This cannot be undone.
                </div>
              </div>
            }
            onConfirm={() => deleteRecords()}
          />
        ),
        wrapper: ModalWrapper
      });
    }

    /*
    openRecord({ rowId }) {
      this.store.dispatch(
        actions.openActivityDetailsModal({ activityId: rowId })
      );
    }
    */
  },
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: [
      "user",
      "event",
      "modal",
      "organization",
      "permissions",
      "@flopflip",
      "entityReferences",
      "Data",
      "routing"
    ]
  }
});

export default R.compose(
  connect(
    state => ({
      eventDetails: eventDetails(state)
    }),
    {
      showModal,
      hideModal,
      showSnackbar,
      addValues,
      addRecords,
      bulkRelateRecords,
      updateType,
      deleteRecord
    }
  ),
  withRouter,
  decorate
)(Layout);
