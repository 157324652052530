import * as R from "ramda";
import { createSelector } from "reselect";
import { getters } from "./model";

import * as FormSelectors from "ui-kit/Form/selectors";

export const getIsValid = createSelector(
  getters.details,
  details => {
    return (
      details.name &&
      details.name.length &&
      details.completion_method &&
      details.completion_method.length
    );
  }
);

export const getEditedField = R.compose(
  field => R.prop("value", field),
  FormSelectors.getField
);
