import React, { Component } from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import Helpers from "utils/Global/Helpers";

const styles = {
  photo: {
    backgroundColor: "rgba(255,255,255,0.15)",
    color: "rgba(255,255,255,0.4)",
    textAlign: "center",
    fontWeight: "600",
    fontSize: 13
  }
};

class Avatar extends Component {
  static propTypes = {
    size: PropTypes.number,
    border: PropTypes.bool,
    alt: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    showOnline: PropTypes.bool,
    userId: PropTypes.number,
    style: PropTypes.object,
    imageUrl: PropTypes.string,
    textStyle: PropTypes.object
  };

  render() {
    const size = this.props.size ? this.props.size : 40;

    const sizeStyle = {
      borderRadius: size / 5,
      width: size,
      height: size,
      lineHeight: `${size}px`
    };

    let borderStyle;
    if (this.props.border) {
      borderStyle = {
        border: "1px solid #b7b7b7"
      };
    }

    let altColor;
    if (this.props.alt) {
      altColor = {
        color: "#CCCCCC"
      };
    }

    let photo;
    if (this.props.imageUrl) {
      photo = (
        <div
          style={[
            sizeStyle,
            styles.photo,
            altColor,
            borderStyle,
            {
              backgroundImage: `url(${
                this.props.imageUrl
              }/convert?w=${size}&h=${size}&fit=crop)`
            }
          ]}
        />
      );
    } else {
      photo = (
        <div
          style={[
            sizeStyle,
            styles.photo,
            altColor,
            borderStyle,
            this.props.textStyle && this.props.textStyle
          ]}
        >
          {Helpers.buildUserAcronym(this.props.firstName, this.props.lastName)}
        </div>
      );
    }

    let statusStyle;
    if (this.props.showOnline) {
      statusStyle = {
        borderRadius: 5,
        backgroundClip: "padding-box",
        backgroundColor: "#00cd81",
        width: 10,
        height: 10,
        position: "absolute",
        left: -3,
        top: -3
      };
    }

    return (
      <div
        style={[
          {
            position: "relative",
            width: size,
            height: size
          },
          this.props.style && this.props.style
        ]}
      >
        <span style={statusStyle} />
        {photo}
      </div>
    );
  }
}

export default Radium(Avatar);
