import React from "react";
import MiniModalWrapper from "Orders/Common/View/MiniModalWrapper";
import { SelectAndCreateVariants } from "ui-kit/MiniItemsSelector/View";
import { connect } from "react-redux";
import { noop } from "utils/General";
import { MODE_MULTIPLE_SELECT } from "ui-kit/MiniItemsSelector/constants";

import { actions } from "../../model";
import { getItemsTypes, getSelectedGuestListItems } from "../../selectors";
import { SELECT_GUEST_LIST_ITEMS_MODAL_ID } from "../../constants";

const decorate = connect(
  state => ({
    itemsTypes: getItemsTypes(state),
    selectedItemIds: getSelectedGuestListItems(state)
  }),
  {
    hideModal: () => actions.setShowSelectGuestListItemsModal(false),
    onDone: actions.onSelectGuestListItems
  }
);

const SelectGuestListItemsModal = ({
  itemsTypes = [],
  selectedItemIds = [],
  hideModal = noop,
  onDone = noop
}) => {
  return (
    <MiniModalWrapper
      showModal={true}
      title="Set Item Limits"
      hideModal={hideModal}
      width={750}
      height={850}
      zIndex={100}
    >
      {React.createElement(SelectAndCreateVariants, {
        instanceId: SELECT_GUEST_LIST_ITEMS_MODAL_ID,
        fields: itemsTypes,
        iniSelected: selectedItemIds,
        iniClearSearch: true,
        onCancel: hideModal,
        onDone: onDone,
        mode: MODE_MULTIPLE_SELECT
      })}
    </MiniModalWrapper>
  );
};

export default decorate(SelectGuestListItemsModal);
