import React from "react";
import { DarkTab } from "components/Base";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import * as R from "ramda";
import { Link } from "react-router";
import Support from "EventLight/Common/Support";

const SubNavWrapperUndecorated = ({
  children,
  tabs = [],
  supportId = null
}) => {
  return (
    <>
      <div className={css.subnav}>
        <div className={css.subNavInner}>
          <div className={css.subNavMainTabs}>{children}</div>

          <Support id={supportId} />
        </div>
      </div>
      {tabs.length ? (
        <div className={css.subnavTabs}>
          <div className={css.subNavTabsInner}>
            {R.map(({ key, label, active, to }) => {
              return (
                <Link to={to}>
                  <DarkTab
                    key={key}
                    px={0}
                    fs={3}
                    active={active}
                    onClick={() => {}}
                  >
                    {label}
                  </DarkTab>
                </Link>
              );
            })(tabs)}
          </div>
        </div>
      ) : null}
    </>
  );
};

export const SubNavWrapper = R.compose(CSSModules(css))(
  SubNavWrapperUndecorated
);

export const SubNavLink = CSSModules(
  ({ name, active, onClick, ...otherProps }) => {
    return (
      <DarkTab
        fs={5}
        active={active}
        onClick={onClick}
        style={{
          paddingBottom: "8px",
          height: "40px"
        }}
        color="gray4"
        {...otherProps}
      >
        {name}
      </DarkTab>
    );
  },
  css
);
