import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import moment from "moment";

import { NAMESPACE, STATUS } from "./constants";

const iniState = {
  eventName: "",
  status: STATUS.CLOSED,
  startDate: moment().format(),
  endDate: "",
  logoImageUrl: "",
  backgroundImageUrl: "",
  onBlurEnabled: false
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    cancelInstance: R.always(iniState),
    updateEventName: R.identity,
    updateEventStatus: R.identity,
    updateEventStartDate: R.identity,
    updateEventEndDate: R.identity,
    setInitialData: (
      state,
      {
        payload: {
          eventName,
          logoImageUrl,
          backgroundImageUrl,
          status,
          startDate,
          endDate,
          onBlurEnabled = false
        }
      }
    ) => ({
      eventName,
      logoImageUrl,
      backgroundImageUrl,
      status,
      startDate: R.isNil(startDate) ? state.startDate : startDate,
      endDate: R.isNil(endDate) ? state.endDate : endDate,
      onBlurEnabled
    })
  }
});

export default handlers;
