import React from "react";
import { Stepper, Step, StepLabel, StepContent } from "material-ui/Stepper";
import { sortBy } from "lodash";
import Heading from "components/Event/Settings/Heading";
import Body from "components/Event/Settings/Body";
import Page from "components/Event/Settings/Page";
import EventDaySelector from "components/Global/EventDaySelector";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import OptionSelector from "components/Global/OptionSelector";
import DefaultHeader from "components/Global/OptionSelector/DefaultHeader";
import MealMatrixSelector from "components/Event/Settings/Catering/MealMatrixSelector";
import MealsTable from "./MealsTable";
import ItemBlocks from "components/Event/Settings/Credentials/Types/ItemBlocks";
import * as R from "ramda";
import PasswordField from "components/Global/Inputs/Password";
import ApprovalWorkflows from "../ApprovalWorkflows";

import {
  AddIcon,
  BigInsetInput,
  BigOutlineButton,
  BigTextButton,
  DarkTab,
  Div,
  FontIcon,
  LockIcon,
  MediumFilledButton,
  Text1,
  Text3,
  Text4,
  Text6
} from "components/Base";

import { withState, withProps } from "utils/General";

import CSSModules from "react-css-modules";
import css from "components/Event/Settings/Layout/Tables/styles.css";

const TextButton = withProps({ color: "primary5" })(BigTextButton);
const ManagerOverride = R.compose(
  withState("passwordFieldIsActive", "togglePassword", false),
  withState("password", "onPasswordChange")
)(
  ({
    isEnabled,
    togglePassword,
    passwordFieldIsActive,
    onPasswordChange,
    password,
    onSave
  }) => (
    <Div p={10}>
      <Div bg="white" shadow={1} maxWidth={500} px={10} py={5}>
        <LockIcon size={40} mb={4} />
        <Text4 bold>Set Manager Override Code</Text4>
        <Div py={3}>
          Share this with trusted Catering Check In Staffers to enable them to
          assign criticial meal access on the fly so that workers & VIPS can
          stay happy & keep moving.
        </Div>
        <Div px={4} py={3} bg="neutral0" display="column" bra={1}>
          {passwordFieldIsActive ? (
            <Div display="row.flex-start.center">
              <PasswordField
                name="password"
                onChange={e => onPasswordChange(e.target.value)}
                placeholder={"Enter password"}
                value={password}
                style={{
                  border: "1px solid #E3E3E3",
                  borderRadius: 4,
                  padding: 10,
                  width: "100%"
                }}
              />
              <MediumFilledButton
                ml={2}
                bg="primary5"
                onClick={() => {
                  onSave(password);
                  onPasswordChange("");
                  togglePassword(false);
                }}
                disabled={!password || !password.length}
              >
                Save
              </MediumFilledButton>
              <MediumFilledButton
                ml={2}
                onClick={() => {
                  togglePassword(false);
                  onPasswordChange("");
                }}
              >
                Cancel
              </MediumFilledButton>
            </Div>
          ) : isEnabled ? (
            <Div display="row.flex-start.center">
              <PasswordField
                name="passwordPlaceholder"
                disabled
                value="*********"
                style={{
                  border: "1px solid #E3E3E3",
                  backgroundColor: "#f7f7f8",
                  borderRadius: 4,
                  padding: 10,
                  width: "100%"
                }}
              />
              <MediumFilledButton
                ml={2}
                bg="primary5"
                onClick={() => togglePassword(true)}
              >
                Change
              </MediumFilledButton>
              <MediumFilledButton
                ml={2}
                onClick={() => {
                  onPasswordChange(undefined);
                  onSave("");
                }}
              >
                Disable
              </MediumFilledButton>
            </Div>
          ) : (
            <TextButton LeftIcon={AddIcon} onClick={() => togglePassword(true)}>
              Set Code
            </TextButton>
          )}
        </Div>
      </Div>
    </Div>
  )
);
const Days = ({
  saving,
  eventDetails,
  dayMap,
  selectedDays,
  handleDayClick,
  handleGroupSelectAll,
  handleGroupDeselectAll,
  handleNext
}) => (
  <Step>
    <StepLabel>Select days with catering</StepLabel>
    <StepContent>
      <Div p={5}>
        <EventDaySelector
          startDate={eventDetails.date_from}
          endDate={eventDetails.date_to}
          dayGroups={eventDetails.date_groups}
          dayMap={dayMap}
          showCountOfSelectedDays
          countOfSelectedDays={selectedDays.length}
          handleDayClick={handleDayClick}
          handleGroupSelectAll={handleGroupSelectAll}
          handleGroupDeselectAll={handleGroupDeselectAll}
        />
      </Div>
      <Div p={4}>
        <RaisedButton
          label="Next"
          disableTouchRipple
          disableFocusRipple
          primary
          onClick={handleNext}
          disabled={selectedDays.length === 0 || saving}
        />
      </Div>
    </StepContent>
  </Step>
);

const Meals = ({ showAddMealModal, saving, meals, handleNext, handlePrev }) => (
  <Step>
    <StepLabel>Add available meals</StepLabel>
    <StepContent>
      <Div p={5}>
        <Div
          bra="1"
          style={{
            width: 300,
            backgroundColor: "#FAFAFA",
            boxShadow:
              "0px 1px 5px 0px rgba(0,0,0,0.10), 0px 6px 12px 0px rgba(0,0,0,0.17)"
          }}
        >
          <MealsTable meals={meals} />
          <Text3 onClick={showAddMealModal} primary bold px={4} py={3}>
            + Add meal
          </Text3>
        </Div>
      </Div>
      <Div p={4}>
        <FlatButton
          label="Back"
          disableTouchRipple
          disableFocusRipple
          onClick={handlePrev}
          disabled={saving}
        />
        <RaisedButton
          label="Next"
          disableTouchRipple
          disableFocusRipple
          primary
          onClick={handleNext}
          disabled={meals.length === 0 || saving}
          style={{ marginLeft: 12 }}
        />
      </Div>
    </StepContent>
  </Step>
);

const MealsPerDay = ({
  saving,
  meals,
  mealDays,
  selectedDays,
  handleMealDayChange,
  isMealDaySelected,
  handleNext,
  handlePrev
}) => (
  <Step>
    <StepLabel>Review &amp; adjust meals per day</StepLabel>
    <StepContent>
      <Div p={5}>
        <MealMatrixSelector
          meals={meals}
          days={sortBy(selectedDays, d => d)}
          isMealDaySelected={isMealDaySelected}
          countOfMealDaysSelected={mealDays.length}
          handleChange={handleMealDayChange}
        />
      </Div>
      <Div p={4}>
        <FlatButton
          label="Back"
          disableTouchRipple
          disableFocusRipple
          onClick={handlePrev}
          disabled={saving}
        />
        <RaisedButton
          label="Next"
          disableTouchRipple
          disableFocusRipple
          primary
          onClick={handleNext}
          disabled={mealDays.length === 0 || saving}
          style={{ marginLeft: 12 }}
        />
      </Div>
    </StepContent>
  </Step>
);

const DietaryRestrictions = ({
  saving,
  dietaryRestrictions,
  handleUpdateOption,
  handleRemoveOption,
  handleNext,
  handlePrev
}) => (
  <Step>
    <StepLabel>Edit dietary restriction / food preference options</StepLabel>
    <StepContent>
      <Div p={5}>
        <OptionSelector
          options={dietaryRestrictions}
          placeholder="Add option..."
          handleUpdateOption={(index, option) => {
            handleUpdateOption("dietaryRestrictions", index, option);
          }}
          Header={<DefaultHeader title="Dietary restrictions" />}
          handleRemoveOption={index => {
            handleRemoveOption("dietaryRestrictions", index);
          }}
        />
      </Div>
      <Div p={4}>
        <FlatButton
          label="Back"
          disableTouchRipple
          disableFocusRipple
          onClick={handlePrev}
          disabled={saving}
        />
        <RaisedButton
          label="Finish"
          disableTouchRipple
          disableFocusRipple
          primary
          onClick={handleNext}
          style={{ marginLeft: 12 }}
          disabled={saving}
        />
      </Div>
    </StepContent>
  </Step>
);

const MealsWorkflow = ({
  showAddMealModal,
  saving,
  loading,
  cateringPath,
  stepIndex,
  eventDetails,
  dayMap,
  selectedDays,
  meals,
  mealDays,
  dietaryRestrictions,
  handleDayClick,
  handleGroupSelectAll,
  handleGroupDeselectAll,
  handleNext,
  handlePrev,
  handleUpdateOption,
  handleRemoveOption,
  handleMealDayChange,
  isMealDaySelected,
  finished,
  goBackToStart,
  blocks,
  goToItemBlocksDoc,
  showAddItemBlockModal
}) => (
  <Div display="row" px={8}>
    <Div
      width={2 / 3}
      pr={3}
      py={4}
      br={1}
      bc="gray3"
      style={{ maxWidth: 800 }}
    >
      <Div mt={5} ml={5}>
        {loading ? (
          <Div />
        ) : (
          <Stepper activeStep={stepIndex} orientation="vertical">
            {Days({
              saving,
              eventDetails,
              dayMap,
              selectedDays,
              handleDayClick,
              handleGroupSelectAll,
              handleGroupDeselectAll,
              handleNext
            })}
            {Meals({
              showAddMealModal,
              saving,
              meals,
              handleNext,
              handlePrev
            })}
            {MealsPerDay({
              saving,
              meals,
              handleNext,
              handlePrev,
              mealDays,
              selectedDays,
              handleMealDayChange,
              isMealDaySelected
            })}
            {DietaryRestrictions({
              saving,
              dietaryRestrictions,
              handleUpdateOption,
              handleRemoveOption,
              handleNext,
              handlePrev
            })}
          </Stepper>
        )}

        {finished && (
          <Div p={5}>
            <Text3 bold mb={3}>
              Your settings have been saved.
            </Text3>
            <RaisedButton
              label="Done"
              disableTouchRipple
              disableFocusRipple
              primary
              onClick={goBackToStart}
            />
          </Div>
        )}
      </Div>
    </Div>
    <Div py={8} flex={1} pl={6} style={{ maxWidth: 350 }}>
      <ItemBlocks {...{ blocks, goToItemBlocksDoc, showAddItemBlockModal }} />
    </Div>
  </Div>
);

const View = CSSModules(
  ({
    showAddMealModal,
    saving,
    loading,
    cateringPath,
    stepIndex,
    eventDetails,
    dayMap,
    selectedDays,
    meals,
    mealDays,
    dietaryRestrictions,
    handleDayClick,
    handleGroupSelectAll,
    handleGroupDeselectAll,
    handleNext,
    handlePrev,
    handleUpdateOption,
    handleRemoveOption,
    handleMealDayChange,
    isMealDaySelected,
    onManagerOverrideSave,
    managerOverrideIsActive,
    finished,
    goBackToStart,
    blocks,
    goToItemBlocksDoc,
    showAddItemBlockModal,
    goToCatering,
    activeTabValue,
    tabs,
    setTabValue
  }) => (
    <Div style={{ minHeight: "100%" }}>
      <Div bb={1} bc="gray3" bg="white" display="row.flex-start.flex-end">
        <Div flex={1}>
          <Text6 pl={8} pt={6}>
            Catering
          </Text6>

          <Div pt={3} pl={8}>
            {R.map(([routeName, tabName, onClick]) => {
              const active = R.equals(activeTabValue)(routeName);
              return (
                <DarkTab
                  key={tabName}
                  px={2}
                  fs={3}
                  active={active}
                  onClick={!active ? onClick : undefined}
                >
                  {tabName}
                </DarkTab>
              );
            })(tabs)}
          </Div>
        </Div>
        <Div
          display="row.flex-start.center"
          style={{ alignSelf: "center" }}
          pr={4}
        >
          <BigOutlineButton onClick={goToCatering}>
            Go to catering
            <FontIcon color="inherit">keyboard_arrow_right</FontIcon>
          </BigOutlineButton>
        </Div>
      </Div>
      <Div style={{ minHeight: "100%" }} pb={14}>
        {R.prop(activeTabValue, {
          editEventSettingsCatering: (
            <MealsWorkflow
              {...{
                showAddMealModal,
                saving,
                loading,
                cateringPath,
                stepIndex,
                eventDetails,
                dayMap,
                selectedDays,
                meals,
                mealDays,
                dietaryRestrictions,
                handleDayClick,
                handleGroupSelectAll,
                handleGroupDeselectAll,
                handleNext,
                handlePrev,
                handleUpdateOption,
                handleRemoveOption,
                handleMealDayChange,
                isMealDaySelected,
                finished,
                goBackToStart,
                blocks,
                goToItemBlocksDoc,
                showAddItemBlockModal
              }}
            />
          ),
          editEventSettingsCateringApprovalWorkflows: <ApprovalWorkflows />,
          editEventSettingsCateringManagerOverride: (
            <ManagerOverride
              onSave={onManagerOverrideSave}
              isEnabled={managerOverrideIsActive}
            />
          )
        })}
      </Div>
    </Div>
  ),
  css
);

export default View;
