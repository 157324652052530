import PropTypes from "prop-types";
import React, { Component } from "react";
import CanUserDo from "components/Global/Security/CanUserDo";
import CSSModules from "react-css-modules";
import css from "../styles.scss";

const Menu = CSSModules(
  ({ children }) => <div styleName="menu">{children}</div>,
  css
);
const MenuItem = CSSModules(
  ({ onClick, children }) => (
    <div role="link" tabIndex="0" styleName="menuItem" onClick={onClick}>
      {children}
    </div>
  ),
  css
);

@CSSModules(css)
class ModuleActionsMenu extends Component {
  wrapAction(action) {
    const { close } = this.props;
    return () => {
      action();
      close();
    };
  }

  render() {
    const { module, exportXLSX, exportCSV } = this.props;

    return (
      <Menu>
        {exportXLSX ? (
          <CanUserDo action={`${module.id}_export`}>
            <MenuItem onClick={this.wrapAction(exportXLSX)}>
              <i className="material-icons" styleName="menuItemIcon">
                file_download
              </i>
              <span>Export .XLSX</span>
            </MenuItem>
          </CanUserDo>
        ) : (
          ""
        )}
        {exportCSV ? (
          <CanUserDo action={`${module.id}_export`}>
            <MenuItem onClick={this.wrapAction(exportCSV)}>
              <i className="material-icons" styleName="menuItemIcon">
                file_download
              </i>
              <span>Export .CSV</span>
            </MenuItem>
          </CanUserDo>
        ) : (
          ""
        )}
      </Menu>
    );
  }
}

ModuleActionsMenu.propTypes = {
  module: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  close: PropTypes.func.isRequired,
  exportCSV: PropTypes.func,
  exportXLSX: PropTypes.func
};

export default ModuleActionsMenu;
