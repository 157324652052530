/* eslint-disable no-underscore-dangle */

import * as R from "ramda";

import { createSelector } from "reselect";

import { getters } from "OrgLight/Common/Dashboard";
import { getters as TableGetters } from "ui-kit/Table/model";

import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

import { STATUS, TABLE_INSTANCE_ID } from "../constants";
import { parseComboId } from "utils/General";

const contactTabs = [
  {
    title: "Show All",
    id: STATUS.ALL
  }
  /*
  {
    title: "Unpaid",
    id: STATUS.UNPAID
  },
  {
    title: "Paid",
    id: STATUS.PAID
  }
  */
];

const accountTabs = [
  {
    title: "Show All",
    id: STATUS.ALL
  },
  {
    title: "No Primary Contacts",
    id: STATUS.NO_PRIMARY_CONTACTS
  }
  /*
  {
    title: "Unpaid",
    id: STATUS.UNPAID
  },
  {
    title: "Paid",
    id: STATUS.PAID
  }
  */
];

const moduleTabs = [
  {
    title: "Show All",
    id: STATUS.ALL
  },
  {
    title: "Pending",
    id: STATUS.PENDING
  },
  {
    title: "Approved",
    id: STATUS.APPROVED
  },
  {
    title: "Denied",
    id: STATUS.DENIED
  }
];

const filteredPeopleTabs = [
  {
    title: "Show All",
    id: STATUS.ALL
  }
  /*
  {
    title: "Primary Contacts",
    id: STATUS.PRIMARY_CONTACTS
  },
  {
    title: "Login Users",
    id: STATUS.LOGIN_USERS
  }
  */
];

// pagination
export const getPageSize = R.compose(
  R.prop("pageSize"),
  getters.pagination
);

export const getCurrentPage = R.compose(
  R.prop("page"),
  getters.pagination
);

// filters: fields
export const getFieldFilters = createSelector(
  getters.columns,
  getters.moduleId,
  getters.preferences,
  (fields, moduleId, preferences) => {
    const readOnlyFields = resolveReadOnlyFields({
      for: "filter",
      moduleId
    });
    return R.compose(
      R.sortBy(f => preferences.sort_by[f.id]),
      R.filter(
        f =>
          !readOnlyFields.includes(f.id) &&
          preferences.visible_fields.includes(f.id)
      )
    )(fields);
  }
);
export const getFieldFiltersSelectedCount = createSelector(
  getters.preferences,
  R.pathOr(0, ["filters", "filters", "filters", "length"])
);

export const getSelectedFieldFilters = createSelector(
  getFieldFilters,
  getters.preferences,
  (fields, preferences) => {
    const filters = R.pathOr({}, ["filters", "filters"])(preferences);
    if (filters.filters) {
      filters.filters.map(filter => {
        filter.title = R.compose(
          R.prop("name"),
          R.find(R.propEq("id", filter.fieldId))
        )(fields);
        return filter;
      });
    }
    return filters;
  }
);

// filters: bar
const getItemFiltersOn = createSelector(
  state => getters.preferences(state).filters,
  (...args) =>
    R.any(
      R.compose(
        R.not,
        val => (typeof val === "boolean" ? !val : R.isEmpty(val))
      ),
      args
    )
);

export const getFiltersOn = createSelector(
  getItemFiltersOn,
  (...args) => R.any(R.identity, args)
);

export const getPeopleFilterOn = createSelector(
  getters.preferences,
  preferences => Boolean(preferences.contact_record_type_id)
);

export const getPeopleFilterMessage = createSelector(
  getters.preferences,
  getters.peopleRecordTypes,
  (preferences, peopleRecordTypes) => {
    if (preferences.contact_record_type_id === "all") {
      return "Viewing all related people";
    }
    const type = R.find(R.propEq("id", preferences.contact_record_type_id))(
      peopleRecordTypes
    );
    if (type) {
      return `Viewing related people of type ${type.name}`;
    }
    return "Viewing filtered list of people related to groups";
  }
);

// filters: sort by
export const getSortBy = createSelector(
  getters.columns,
  state => getters.preferences(state).sort_by,
  (fields, sortBy) =>
    R.compose(
      R.filter(R.prop("name")),
      R.map(s => ({
        ...s,
        name: R.compose(
          R.prop("name"),
          R.find(R.propEq("id", s.fieldId))
        )(fields)
      }))
    )(sortBy)
);

// tabs
export const getTabs = createSelector(
  getters.tabStats,
  getters.moduleId,
  getters.preferences,
  getters.module,
  (stats, moduleId, preferences, module) => {
    let tabs = preferences.contact_record_type_id
      ? filteredPeopleTabs
      : moduleId === STANDARD_MODULE_IDS.accounts.id
      ? accountTabs
      : moduleId === STANDARD_MODULE_IDS.contacts.id
      ? contactTabs
      : moduleTabs;

    if (!module.has_approval) {
      tabs = tabs.filter(
        t => ![STATUS.PENDING, STATUS.APPROVED, STATUS.DENIED].includes(t.id)
      );
    }

    return tabs.map(tab => ({
      ...tab,
      title: R.isNil(stats[tab.id])
        ? tab.title
        : `${tab.title} (${stats[tab.id]})`
    }));
  }
);

// sidebar: links
export const isActive = (
  _,
  {
    routes = [],
    params = {},
    activeRoutes = [],
    activeParams = {},
    isRouteActive = false
  }
) => {
  if (isRouteActive) {
    return isRouteActive;
  }
  if (R.any(r => R.any(R.equals(r.name), activeRoutes), routes)) {
    if (activeParams) {
      return R.all(
        R.equals(true),
        R.map(([key, val]) => {
          if (val === null) {
            return !R.has(key, params) || R.isNil(R.prop(key, params));
          }
          return params[key] === val;
        }, Object.entries(activeParams))
      );
    }
    return true;
  }
  return false;
};

export const isOpened = (state, props) =>
  R.contains(props.id, getters.openedMenues(state, props));

// sidebar: navigation
// eslint-disable-next-line no-unused-vars

export const getToggledRows = createSelector(
  state =>
    TableGetters.toggledRows(state, {
      instanceId: TABLE_INSTANCE_ID
    }),
  R.identity
);

export const getCountOfToggledRows = createSelector(
  getToggledRows,
  R.length
);

export const getFieldsForEditModal = createSelector(
  getters.columns,
  getters.moduleId,
  getters.preferences,
  (fields, moduleId, preferences) => {
    const readOnlyFields = resolveReadOnlyFields({
      moduleId
    });
    return R.compose(
      R.sortBy(f => preferences.field_order[f.id]),
      R.filter(
        f =>
          preferences.visible_fields.includes(f.id) &&
          ![
            "form",
            "document-request",
            "item-group",
            "payment",
            "primary-contacts",
            "email-last-sent",
            "last-login-at",
            "count-of-emails-sent",
            "allocated-passes",
            "allocated-meals",
            "signature"
          ].includes(f.type) &&
          ![
            STANDARD_FIELD_IDS.ACCOUNTS.NAME,
            "synced-to-fuzion",
            "fuzion-exhibitor-id"
          ].includes(f.id) &&
          !readOnlyFields.includes(f.id)
      )
    )(fields);
  }
);

export const getToggledRowsWithName = createSelector(
  getters.rows,
  getToggledRows,
  getters.moduleId,
  (rows, toggledRows, moduleId) =>
    R.compose(
      R.map(row => ({
        id: row.id,
        name:
          moduleId === STANDARD_MODULE_IDS.accounts.id
            ? R.pathOr("(No Name)", [
                "values",
                STANDARD_FIELD_IDS.ACCOUNTS.NAME,
                "value"
              ])(row)
            : "(No Name)"
      })),
      R.filter(row => toggledRows.includes(row.id))
    )(rows)
);

export const getActiveViewId = createSelector(
  getters.preferences,
  R.prop("id")
);

export const getHasViewChanged = createSelector(
  getters.preferences,
  getters.originalPreferences,
  (preferences, originalPreferences) =>
    !R.equals(preferences, originalPreferences)
);

export const getSearchbarPlaceholder = createSelector(
  getters.moduleId,
  moduleId =>
    R.propOr("Search", moduleId)({
      [STANDARD_MODULE_IDS.accounts.id]: "Search by name",
      [STANDARD_MODULE_IDS.contacts.id]: "Search by name or email"
    })
);

export const getGroupedByField = createSelector(
  getters.preferences,
  R.propOr(null, "grouped_by")
);

export const availableFieldsToGroupBy = createSelector(
  getters.columns,
  fields =>
    R.compose(
      R.sortBy(
        R.compose(
          R.toLower,
          R.prop("name")
        )
      )
    )(fields)
);

export const selectedGroupByFieldName = createSelector(
  getters.columns,
  getGroupedByField,
  (fields, selectedFieldId) =>
    !R.isEmpty(selectedFieldId)
      ? R.propOr("", "name", R.find(R.propEq("id", selectedFieldId))(fields))
      : ""
);

export const getIsViewingFilteredPeopleType = createSelector(
  getters.preferences,
  preferences => Boolean(preferences.contact_record_type_id)
);

export const getIsApprovalsEnabled = createSelector(
  getters.module,
  R.prop("has_approval")
);

export const getPeopleReportSelectedRows = createSelector(
  state =>
    TableGetters.toggledRows(state, {
      instanceId: TABLE_INSTANCE_ID
    }),
  R.map(id => parseComboId(id)[1])
);

export const getPeopleReportContactsAndAccounts = createSelector(
  state =>
    TableGetters.toggledRows(state, {
      instanceId: TABLE_INSTANCE_ID
    }),
  R.map(id => ({
    accountId: parseComboId(id)[0],
    contactId: parseComboId(id)[1]
  }))
);

export const getContactsForLogin = createSelector(
  state =>
    TableGetters.toggledRows(state, {
      instanceId: TABLE_INSTANCE_ID
    }),
  R.map(id => ({
    contactId: id
  }))
);

export * from "./tableActions";
