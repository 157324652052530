import * as R from "ramda";
import { createModule, createHandlers } from "redux-mvc";
import { NAMESPACE } from "ui-kit/ViewPicker/constants";

const iniState = {
  views: [],
  activeViewId: null,
  activeViewName: null,
  showEditViewName: false
};

const reducers = {
  addView: R.identity,
  updateView: R.identity,
  deleteView: R.identity,
  saveViewChanges: R.identity,
  revertViewChanges: R.identity,
  init: (state, { payload: { views, activeViewId, activeViewName } }) => ({
    views: views || state.views,
    activeViewId: activeViewId || state.activeViewId,
    activeViewName: activeViewName || state.activeViewName
  }),
  selectView: ({ views }, { payload: viewId }) => {
    const view = views.find(v => v.id === viewId);
    return {
      activeViewId: view.id,
      activeViewName: view.name
    };
  },
  setActiveView: (_, { payload: { activeViewId, activeViewName } }) => ({
    activeViewId,
    activeViewName
  }),
  addNewActiveView: ({ views }, { payload: newView }) => ({
    activeViewId: newView.id,
    activeViewName: newView.name,
    views: [...views, newView]
  }),
  updateViewName: ({ views, activeViewId }, { payload: { name } }) => ({
    activeViewName: name,
    views: views.map(v => ({
      ...v,
      name: v.id === activeViewId ? name : v.name
    }))
  }),
  updateViewPreferences: ({ views }, { payload }) => ({
    views: views.map(v => (v.id === payload.id ? payload : v))
  })
};

const handlers = createHandlers({ iniState, reducers, namespace: NAMESPACE });

const { actions, getters, reducer } = handlers;

export { actions, getters, reducer };

const module = createModule(handlers);

export default module;
