import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Panel from "components/Global-2016/Panel";
import { Collapsable, collapsableHandler } from "components/Base";

@CSSModules(css)
class CollapsablePanel extends Component {
  render() {
    return (
      <Panel style={css.panel}>
        <div styleName="heading" onClick={this.props.toggleCollapsed}>
          <i className="material-icons">
            {this.props.collapsed
              ? "keyboard_arrow_right"
              : "keyboard_arrow_down"}
          </i>
          {this.props.title}
        </div>
        <Collapsable collapsed={this.props.collapsed}>
          {this.props.children}
        </Collapsable>
      </Panel>
    );
  }
}

CollapsablePanel.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  collapsedDefault: PropTypes.bool
};

CollapsablePanel.defaultProps = {
  collapsedDefault: false
};

export default collapsableHandler(CollapsablePanel);
