import { RECEIVE, REQUEST } from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getItemBlock(itemBlockId) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: REQUEST,
        payload: {
          itemBlockId
        }
      });
      const result = await api.getItemBlock(
        getState().user.user.credentials,
        itemBlockId
      );
      dispatch({
        type: RECEIVE,
        payload: {
          itemBlockId,
          itemBlock: result.payload[0]
        }
      });
      return result.payload[0];
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching item block"
          }
        ])
      );
    }
  };
}
