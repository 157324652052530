import { createModule } from "redux-mvc";

import model from "./model";
import sagas from "./sagas";

const cart = createModule(model);

cart.setRootSaga(sagas);

export default cart;
