import { get } from "lodash";

export const isFetching = (state, recordId) =>
  get(state, ["modules", "recordView", "fetching", "byId", recordId]);

export const record = (state, recordId) =>
  get(
    state,
    ["modules", "recordView", "record", "byId", recordId, "record"],
    {}
  );

export const moduleDetails = (state, recordId) =>
  get(
    state,
    ["modules", "recordView", "record", "byId", recordId, "module"],
    {}
  );

export const relatedModules = (state, recordId) =>
  get(
    state,
    ["modules", "recordView", "record", "byId", recordId, "related_modules"],
    []
  );

export const fields = (state, recordId) =>
  get(
    state,
    ["modules", "recordView", "record", "byId", recordId, "fields", "fields"],
    []
  );

export const starredFields = (state, recordId) =>
  get(
    state,
    [
      "modules",
      "recordView",
      "record",
      "byId",
      recordId,
      "fields",
      "starred_fields"
    ],
    []
  );

export const fieldGroups = (state, recordId) =>
  get(
    state,
    [
      "modules",
      "recordView",
      "record",
      "byId",
      recordId,
      "fields",
      "field_groups"
    ],
    []
  );
