import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Item from "components/Global/Module/Sidebar/Item";
import Title from "components/Global/Module/Sidebar/Title";
import Separator from "components/Global/Module/Sidebar/Separator";
import SubTitle from "components/Global/Module/Sidebar/SubTitle";
import SubItem from "components/Global/Module/Sidebar/SubItem";
import {
  catalogCategories,
  catalogItems
} from "@lennd/value-types/src/constants/standard-modules";
import { CATALOG_CATEGORIES } from "utils/standard-module-field-ids";
import getValue from "utils/value-types/get-value";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import EditRecordModal from "components/Global/Module/Modals/EditRecord";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import Content from "components/Global/Module/Sidebar/Container";

class CatalogSidebarScene extends Component {
  componentDidMount() {
    const { eventId } = this.props.params;
    this.props.getModule({
      moduleId: catalogCategories.id,
      options: { eventId }
    });
    this.fetchRecords();
  }

  fetchRecords = () => {
    const { eventId } = this.props.params;
    this.props.getRecords({
      moduleId: catalogCategories.id,
      options: { eventId }
    });
  };

  showAddRecordModal = () => {
    this.props.showModal({
      content: (
        <AddRecordModal
          moduleId={catalogCategories.id}
          onSave={this.fetchRecords}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showEditRecordModal = () => {
    const { parentRecordId } = this.props.params;
    const record = this.props.records.find(r => r.id === parentRecordId);
    this.props.showModal({
      content: (
        <EditRecordModal
          record={record}
          values={record.values}
          hideModal={() => {
            this.props.hideModal();
            this.fetchRecords();
          }}
          moduleId={this.props.module.id}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const { active, onSceneChange, params, module, records } = this.props;
    return (
      <Content>
        <Title onClick={onSceneChange} style={{ cursor: "pointer" }}>
          <i className="material-icons">keyboard_arrow_left</i>
          Catalog
        </Title>
        <Item
          active={active === "catalog"}
          linkTo={`/event/${params.eventId}/inventory/catalog`}
        >
          All Items
        </Item>
        <Separator />
        <SubTitle>By Category</SubTitle>
        {records.map(record => (
          <SubItem
            key={record.id}
            active={params.parentRecordId === record.id}
            linkProps={{
              pathname: `/event/${params.eventId}/inventory/catalog/${
                record.id
              }/view/${params.viewId}`
            }}
          >
            {getValue(record.values[CATALOG_CATEGORIES.NAME], "text")}
          </SubItem>
        ))}
        <Separator />
        <Item onClick={this.showAddRecordModal}>
          <span styleName="settings">
            <i className="material-icons">add</i>
            Add category
          </span>
        </Item>
        {params.parentRecordId ? (
          <Item onClick={this.showEditRecordModal}>
            <span styleName="settings">
              <i className="material-icons">settings</i>
              Edit category
            </span>
          </Item>
        ) : (
          ""
        )}
      </Content>
    );
  }
}

CatalogSidebarScene.propTypes = {
  params: PropTypes.shape({
    parentRecordId: PropTypes.string,
    eventId: PropTypes.string
  }).isRequired,
  module: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default CSSModules(CatalogSidebarScene, css);
