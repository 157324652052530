import PropTypes from "prop-types";
import React, { Component } from "react";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import ChangeNames from "./ChangeNames";

@CSSModules(css)
class FormStatusNotice extends Component {
  render() {
    const { isFormLocked, submission, goToPortal, goToSubmission } = this.props;

    let className = [];
    let message;
    let icon;
    if (submission.is_confirmation_sent) {
      // @TODO: Add this condition if add on the 'has_changes' property
      // @TODO: Add handler for rejected state
      /*
      if (submission.has_changes) {
        // approved w/ changes
        icon = 'check';
        className = 'approvedWithChanges';
        message = <div>Your submission has been approved with changes.</div>;
      } else {
    ` */
      // approved
      icon = "check";
      className = [css.approved];
      message = (
        <div styleName="messageWrapper">
          <div>Your submission has been reviewed.</div>
          <div styleName="separator" />
        </div>
      );
      // }
    } else if (isFormLocked) {
      icon = "lock";
      className = [css.locked];
      message = (
        <div styleName="messageWrapper">
          <div>This form has been locked.</div>
          {goToPortal ? (
            <div>
              <div styleName="separator" />
              <div styleName="link" onClick={goToPortal}>
                View your submissions
              </div>
            </div>
          ) : null}
        </div>
      );
    } else if (submission.is_submitted) {
      if (submission.is_locked) {
        icon = "lock";
        className = [css.locked];
        message = (
          <div styleName="messageWrapper">
            <div>
              Your submission has been <u>locked</u> for review.
            </div>
            {goToPortal ? (
              <div>
                <div styleName="separator" />
                <div styleName="link" onClick={goToPortal}>
                  View your submissions
                </div>
              </div>
            ) : null}
          </div>
        );
      } else {
        // still editable
        icon = "edit";
        className = [css.open];
        message = (
          <div styleName="messageWrapper">
            <div>Your submission is still open and editable.</div>
          </div>
        );
      }
    }

    if (!message) {
      return null;
    }

    return (
      <div className={className.join(" ")}>
        <i className="material-icons" styleName="icon">
          {icon}
        </i>
        <div styleName="message">{message}</div>
      </div>
    );
  }
}

FormStatusNotice.propTypes = {
  submission: PropTypes.object.isRequired,
  goToPortal: PropTypes.func.isRequired,
  goToSubmission: PropTypes.func.isRequired
};

export default FormStatusNotice;
