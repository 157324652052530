import React from "react";
import { connect } from "react-redux";

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

import { getters } from "../model";

import PrevNext from "./PrevNext";
import DateRangeSelector from "./DateRangeSelector";
import EventDatesCalendar from "./EventDatesCalendar";

import { CALENDAR_VIEW } from "../constants";

import DayLayout from "./DayLayout";
import WeekLayout from "./WeekLayout";
import MonthLayout from "./MonthLayout";
import ResourceLayout from "./ResourceLayout";

const resolveLayout = {
  [CALENDAR_VIEW.DAY]: DayLayout,
  [CALENDAR_VIEW.WEEK]: WeekLayout,
  [CALENDAR_VIEW.MONTH]: MonthLayout,
  [CALENDAR_VIEW.RESOURCE]: ResourceLayout
};

const decorate = connect((state, props) => ({
  selectedRange: getters.selectedRange(state, props)
}));

const TimeTable = props =>
  React.createElement(resolveLayout[props.selectedRange] || "div", props);

export { PrevNext, DateRangeSelector, EventDatesCalendar };

export default decorate(TimeTable);
