import React from "react";
import { connect } from "react-redux";
import { getters } from "../model";
import AddFileModal from "EventLight/FileRequests/AddFileModal/View/Layout";

const decorate = connect(state => ({
  showAddFileModal: getters.showAddFileModal(state),
  requestId: getters.requestId(state)
}));

const AddFileModalWrapper = ({ showAddFileModal, requestId }) =>
  showAddFileModal ? <AddFileModal requestId={requestId} /> : null;

export default decorate(AddFileModalWrapper);
