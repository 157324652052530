import getValue from "utils/value-types/get-value";
import toString from "utils/value-types/to-string";
import { find, get } from "lodash";

function findRelated(quantityFieldId, fields) {
  return find(fields, { id: quantityFieldId });
}

function getQuantityFieldId(field) {
  return get(field, "settings.quantityFieldId");
}

function getQuantityFromRelated(field, fields, values) {
  const quantityField = findRelated(getQuantityFieldId(field), fields);
  if (quantityField) {
    const value = values[quantityField.id];
    if (value) {
      return getValue(value, value.type);
    }
    return 0; // Quantity field with no value. Assume 0.
  }
  return 1; // Assume one if no quantity field is available
}

function getName(value, field) {
  switch (get(field, "settings.inventoryType")) {
    case "checkbox":
    case "quantity":
      return field.name;
    case "text":
    case "dropdown":
    default:
      return toString(getValue(value, value.type), value.type);
  }
}

function getQuantity(value, field) {
  switch (get(field, "settings.inventoryType")) {
    case "checkbox":
    case "quantity":
      return Number(getValue(value, value.type));
    case "text":
    case "dropdown":
    default:
      return 1;
  }
}

function sanitizeName(name) {
  return name.replace(" (qty)", "");
}

function hasQuantityColumn(field) {
  return get(field, "settings.addQuantityColumn");
}

function isInventoryList(field) {
  return get(field, "settings.inventoryType") === "list";
}

export default function(value, values, field, fields) {
  // Inventory list (multiple records)
  if (isInventoryList(field)) {
    const { records } = getValue(value, value.type);
    return records.reduce((a, record) => {
      if (!a[sanitizeName(record.value)]) {
        a[sanitizeName(record.value)] = 0;
      }
      a[sanitizeName(record.value)] += Number(record.quantity);
      return a;
    }, {});
  }

  const name = getName(value, field);

  if (hasQuantityColumn(field)) {
    // Multi-item and text with `addQuantityColumn` true
    return {
      [sanitizeName(name)]: Number(
        getQuantityFromRelated(field, fields, values)
      )
    };
  }

  // Checkbox, text, multi-item, quantity only
  return {
    [sanitizeName(name)]: getQuantity(value, field)
  };
}
