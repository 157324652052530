import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters, actions } from "../../../model";

import { Div, Text5, CloseIcon, BigFilledButton } from "components/Base";
import { Input, Toggle } from "ui-kit/Form/View";
import { FieldWrapper, Label } from "EventLight/Common/FieldLayout";

import { WithFormInstanceProvider } from "ui-kit/Form/View";
import { PARDOT_FORM_ID, FIELD_IDS } from "../../../constants";

import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import { noop } from "utils/General";

import CSSModules from "react-css-modules";
import css from "../../styles.scss";

const Header = ({ title = "", hideModal = noop }) => (
  <Div
    style={{
      flexShrink: 0
    }}
    display="row.space-between.center"
    bg="white"
    p={4}
    bb={1}
    bc="neutral2"
    brt={1}
  >
    <Text5 bold color="neutral8">
      {title}
    </Text5>

    <Div onClick={hideModal}>
      <CloseIcon color="neutral6" sizeWFS={6} />
    </Div>
  </Div>
);

const decorate = R.compose(
  WithFormInstanceProvider(R.always(PARDOT_FORM_ID)),
  CSSModules(css),
  connect(
    (state, props) => ({
      showModal: getters.isPardotModalOpen(state, props),
      data: getters.data(state),
      error: getters.error(state)
    }),
    {
      hideModal: () => actions.setIsPardotModalOpen(false),
      savePardotSettings: actions.savePardotSettings
    }
  )
);

const Modal = ({
  showModal,
  hideModal = noop,
  savePardotSettings = noop,
  data,
  error
}) => (
  <MiniModalWrapper
    hideModal={hideModal}
    isPortal={false}
    showModal={showModal}
    showHeader={false}
    style={{
      width: 450,
      backgroundColor: "white",
      height: "auto",
      maxHeight: "500px"
    }}
    bg="neutral0"
  >
    <Header title="Connect with Pardot" hideModal={hideModal} />
    <Div
      width={1}
      height={1}
      p={4}
      display="column.flex-start.stretch"
      bb={1}
      bg="neutral0"
      bc="neutral1"
      shadow={1}
      style={{ overflowY: "auto", flex: "1 1 auto" }}
    >
      <Div width={1} display="column.flex-start.stretch" mt={4}>
        <FieldWrapper>
          <Label>Pardot Business Unit ID</Label>
          <Input
            fieldId={FIELD_IDS.PARDOT.BUSINESS_UNIT_ID}
            width={1}
            iniValue={R.prop(FIELD_IDS.PARDOT.BUSINESS_UNIT_ID, data)}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Label tooltip="Depending on how your Pardot instance is setup, Fondi needs to connect to your account differently">
            Is "Allow Multiple Prospects with the Same Email Address" enabled on
            your Pardot account?
          </Label>
          <Toggle
            fieldId={FIELD_IDS.PARDOT.ALLOW_DUPLICATE_EMAIL}
            iniValue={R.prop(FIELD_IDS.PARDOT.ALLOW_DUPLICATE_EMAIL, data)}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Label tooltip="Toggle on if you want Fondi to write to a same record based on email. Otherwise, Fondi will create a new record.">
            If a person already exists in Pardot, do you want Fondi to
            overrwrite the record with info from Fondi?
          </Label>
          <Toggle
            fieldId={FIELD_IDS.PARDOT.OVERWRITE_PARDOT_DATA}
            iniValue={R.prop(FIELD_IDS.PARDOT.OVERWRITE_PARDOT_DATA, data)}
          />
        </FieldWrapper>
      </Div>
    </Div>
    <Div
      bg="white"
      width={1}
      display="column.flex-start.stretch"
      p={4}
      bt={1}
      bc="neutral2"
    >
      {error && error.length ? (
        <Div
          bra={1}
          bg="danger3"
          p={1}
          mb={1}
          fs={1}
          color="black"
          display="row.center.center"
        >
          {error}
        </Div>
      ) : null}
      <BigFilledButton
        bg="altB5"
        color="white"
        width={1}
        display="row.center.center"
        onClick={savePardotSettings}
      >
        Connect
      </BigFilledButton>
    </Div>
  </MiniModalWrapper>
);

export default decorate(Modal);
