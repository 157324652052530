import moment from "moment";
import datetimeValueToMoment from "utils/value-types/helpers/datetime-value-to-moment";

export default function resolveSubtitle({ dueDate, status }) {
  if (status === "Not Submitted") {
    const formattedDueDate = datetimeValueToMoment(dueDate);
    if (
      dueDate &&
      moment().isBefore(formattedDueDate) &&
      status !== "submitted"
    ) {
      return [{ label: `Due ${formattedDueDate.fromNow()}`, icon: "update" }];
    }
    return [{ label: "Not Submitted" }];
  }
  if (status === "Pending") {
    return [
      { label: "Submitted - " },
      { label: "Pending Feedback", icon: "warning" }
    ];
  }
  if (status === "Approved") {
    return [
      { label: "Submitted - " },
      { label: "Approved", icon: "check_circle" }
    ];
  }
  if (status === "Denied") {
    return [
      { label: "Submitted - " },
      { label: "Denied", icon: "not_interested" }
    ];
  }
  return [{ label: "Submitted" }];
}
