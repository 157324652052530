import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Panel = ({ title, description, children }) => (
  <div styleName="panel">
    {title ? <div styleName="title">{title}</div> : null}
    {description ? <div styleName="description">{description}</div> : null}
    {children}
  </div>
);

Panel.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.object.isRequired
};

export default CSSModules(Panel, css);
