import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getters, actions } from "../index";
import {
  getCountOfToggledRows,
  getFieldsForEditModal,
  getToggledRowsWithName,
  getSearchbarPlaceholder,
  getToggledRows,
  getHasViewChanged,
  getToggledRowsWithRecordIds
} from "../selectors";

import { withProps, addS } from "utils/General";

import {
  TABLE_INSTANCE_ID,
  VIEWPICKER_INSTANCE_ID
} from "Forms/Results2/constants";
import { actions as TableActions } from "ui-kit/Table/model";

import CSSModules from "react-css-modules";
import css from "ui-kit/ViewPicker/View/styles.scss";
import Tooltip from "components/Global/Tooltip";
import SelectiveApproversLabel from "SelectiveApproversLabel/View";

import {
  Div,
  FontIcon,
  UploadIcon,
  DownloadIcon,
  EditIcon,
  MediumFilledButton,
  BigFilledButton,
  DownFilledIcon,
  PopMenu,
  PopMenuCard,
  MenuItem,
  AddIcon,
  RightIcon
} from "components/Base";

import { VARIANTS } from "ui-kit/Theme/constants";
import UISearchBar from "ui-kit/SearchBar/View";
import CanUserDo from "components/Global/Security/CanUserDo";
import SaveView from "ui-kit/ViewPicker/View/SaveView";

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      recordType: getters.recordType(state),
      moduleId: getters.moduleId(state),
      countOfToggledRows: getCountOfToggledRows(state),
      toggledRows: getToggledRows(state),
      toggledRowsWithName: getToggledRowsWithName(state),
      fields: getFieldsForEditModal(state, props),
      references: getters.references(state),
      placeholder: getSearchbarPlaceholder(state),
      hasViewChanged: getHasViewChanged(state),
      toggledRowsWithRecordIds: getToggledRowsWithRecordIds(state),
      collapsed: getters.collapsed(state)
    }),
    {
      onExport: actions.exportData,
      showSubscribeModal: actions.showSubscribeModal,
      showAddSubmissionModal: actions.showAddSubmissionModal,
      showDeleteSubmissionModal: actions.showDeleteSubmissionModal,
      showBulkEditModal: actions.showBulkEditModal,
      onApprove: actions.approve,
      onDeny: actions.deny,
      clearSelectedRows: () =>
        TableActions.clearSelectedRows(null, {
          meta: {
            instanceId: TABLE_INSTANCE_ID
          }
        })
    }
  )
);

const Button = ({ LeftIcon, RightIcon, children, onClick }) => (
  <MediumFilledButton
    className={css.button1}
    color="white"
    hover={false}
    onClick={onClick}
    mr={2}
    LeftIcon={LeftIcon}
    RightIcon={RightIcon}
  >
    {children}
  </MediumFilledButton>
);

const FormToolbar = ({
  moduleId,
  countOfToggledRows,
  toggledRows,
  toggledRowsWithRecordIds,
  clearSelectedRows,
  showDeleteSubmissionModal,
  fields,
  references,
  showBulkEditModal,
  onApprove,
  onDeny
}) => (
  <Div display="row.flex-start.center">
    <Div fw={4} mr={4} fs={4} color="white">
      {countOfToggledRows} Selected
    </Div>

    <SelectiveApproversLabel
      {...{
        type: "record",
        recordIds: toggledRowsWithRecordIds,
        onApprove: onApprove,
        onDeny: onDeny,
        Button: Button
      }}
    />

    <CanUserDo action={`${moduleId}_update`}>
      <Button
        LeftIcon={withProps({
          color: "purple4"
        })(EditIcon)}
        onClick={() => showBulkEditModal({ toggledRows, fields, references })}
      >
        Edit
      </Button>

      <CanUserDo action={`${moduleId}_delete`}>
        <Button
          onClick={() => {
            showDeleteSubmissionModal(toggledRows);
          }}
        >
          Delete
        </Button>
      </CanUserDo>
    </CanUserDo>
    <Div ml={2} fw={4} color="white" onClick={clearSelectedRows}>
      Clear selected
    </Div>
  </Div>
);

const Toolbar = ({
  onExport,
  handlers,
  recordType,
  moduleId,
  countOfToggledRows,
  toggledRows,
  toggledRowsWithRecordIds,
  clearSelectedRows,
  fields,
  references,
  placeholder,
  showAddSubmissionModal,
  showDeleteSubmissionModal,
  showBulkEditModal,
  hasViewChanged,
  collapsed,
  showSubscribeModal,
  onApprove,
  onDeny
}) => (
  <Div
    display="row.space-between.center"
    width={1}
    height={75}
    px={3}
    style={{ position: "relative", backgroundColor: "#813CD2", flexShrink: 0 }}
  >
    {!countOfToggledRows ? (
      <>
        <Div display="row.flex-start.center" flex={1}>
          {/*
          <SaveView
            instanceId={VIEWPICKER_INSTANCE_ID}
            hasViewChanged={hasViewChanged}
            collapsed={collapsed}
          />
          */}
          <UISearchBar
            variant={VARIANTS.SURFACE}
            flex={1}
            mr={2}
            placeholder={placeholder}
            height={43}
            style={{
              maxWidth: 445
            }}
          />
        </Div>

        <Div display="row.flex-start.center">
          <CanUserDo any={[`${moduleId}_manage`, `${moduleId}_export`]}>
            <Tooltip tooltip="Subscribe to feed">
              <span>
                <BigFilledButton
                  mr={2}
                  onClick={showSubscribeModal}
                  className={css.button1}
                >
                  <FontIcon fs={4} color="white">
                    rss_feed
                  </FontIcon>
                </BigFilledButton>
              </span>
            </Tooltip>

            <PopMenu
              Label={({ onClick }) => (
                <BigFilledButton
                  className={css.button1}
                  hover={false}
                  color="white"
                  RightIcon={withProps({
                    color: "purple4"
                  })(DownFilledIcon)}
                  onClick={onClick}
                >
                  Options
                </BigFilledButton>
              )}
            >
              {({ closeMenu }) => (
                <PopMenuCard position="bottom" align="right" zIndex={20}>
                  {/*
                  <CanUserDo
                    action={
                      moduleId === STANDARD_MODULES.accounts.id
                        ? `${moduleId}_accounts_add`
                        : `${moduleId}_create`
                    }
                  >
                    <MenuItem
                      LeftIcon={UploadIcon}
                      onClick={() => {
                        handlers.showImportModal();
                        closeMenu();
                      }}
                    >
                      Import .CSV
                    </MenuItem>
                  </CanUserDo>
                    */}
                  <CanUserDo action={`${moduleId}_export`}>
                    {/* <Div width={1} style={{ height: "1px" }} bg="gray3" /> */}
                    <MenuItem
                      LeftIcon={DownloadIcon}
                      onClick={() => {
                        onExport("csv");
                        closeMenu();
                      }}
                    >
                      Export .CSV
                    </MenuItem>
                    <MenuItem
                      LeftIcon={DownloadIcon}
                      onClick={() => {
                        onExport("xlsx");
                        closeMenu();
                      }}
                    >
                      Export .XLSX
                    </MenuItem>
                  </CanUserDo>
                </PopMenuCard>
              )}
            </PopMenu>
          </CanUserDo>

          <CanUserDo action={`${moduleId}_create`}>
            <BigFilledButton
              bg="orange5"
              color="white"
              ml={2}
              onClick={showAddSubmissionModal}
              LeftIcon={AddIcon}
            >
              Add
            </BigFilledButton>
          </CanUserDo>
        </Div>
      </>
    ) : (
      <FormToolbar
        {...{
          moduleId,
          countOfToggledRows,
          toggledRows,
          toggledRowsWithRecordIds,
          clearSelectedRows,
          showDeleteSubmissionModal,
          fields,
          references,
          showBulkEditModal,
          handlers,
          onApprove,
          onDeny
        }}
      />
    )}
  </Div>
);

export default CSSModules(decorate(Toolbar), css);
