import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { getters, actions } from "ui-kit/FilterControls";

import { MODE_SINGLE_SELECT } from "ui-kit/MiniItemsSelector/constants";

import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import MiniItemsSelector from "ui-kit/MiniItemsSelector/View";
import { wsp, os } from "ui-kit/Theme";

import { Div, Text2, EditIcon, CancelIcon } from "components/Base";
import { noop } from "utils/General";

import { Panel } from "./Panel";
import { Box } from "./Box";

const filterCountMessage = (selected, total, word = "") =>
  selected === 0 ? `All ${word}` : `${selected} of ${total} ${R.toLower(word)}`;

const countItemGroups = R.reduce(
  (count, group) => count + R.length(R.prop("items", group)),
  0
);

const decorate = connect(
  (state, props) => ({
    showModal: getters.showModal(state, props)
  }),
  bindInstance({
    hideModal: actions.hideModal,
    onEdit: actions.showModal,
    onDone: actions.applyFilters,
    onClear: (e, ...params) => {
      e.stopPropagation();
      return actions.clear(null, ...params);
    }
  })
);

const Body = decorate(
  ({
    itemGroups = [],
    iniSelected = [],
    showModal = false,
    instanceId,
    onEdit = noop,
    onClear = noop,
    onDone = noop,
    hideModal = noop,
    modalTitle = "",
    selectedText = "",
    ...styleProps
  }) => {
    return (
      <>
        <Box onClick={onEdit} {...styleProps}>
          <Div display="row.flex-start.center">
            <Text2 bold color="primary7">
              {filterCountMessage(
                R.length(iniSelected),
                countItemGroups(itemGroups),
                selectedText
              )}
            </Text2>
            <EditIcon color="primary7" ml={2} />
          </Div>
          {R.length(iniSelected) > 0 ? <CancelIcon onClick={onClear} /> : null}
        </Box>
        <MiniModalWrapper
          showModal={showModal}
          hideModal={hideModal}
          title={modalTitle}
          style={{ maxHeight: 500 }}
        >
          <MiniItemsSelector
            mode={MODE_SINGLE_SELECT}
            buttonLabel="Save"
            itemGroups={itemGroups}
            iniSelected={iniSelected}
            onDone={onDone}
            instanceId={instanceId}
            iniClearSearch
          />
        </MiniModalWrapper>
      </>
    );
  }
);

const ItemFilter = ({ children: label, collapsedDefault, ...props }) => (
  <Panel
    label={label}
    filtersOn={R.length(props.iniSelected)}
    collapsedDefault={collapsedDefault}
    {...os(props)}
  >
    <Body {...wsp(props)} />
  </Panel>
);

const MultipleItemFilter = ({
  children: label,
  collapsedDefault,
  filters = [],
  ...styleProps
}) => (
  <Panel
    label={label}
    collapsedDefault={collapsedDefault}
    filtersOn={R.length(R.flatten(R.map(R.propOr([], "iniSelected"), filters)))}
    {...styleProps}
  >
    {R.map(
      filter => (
        <Body {...filter} key={filter.instanceId} mb={1} />
      ),
      filters
    )}
  </Panel>
);

export { ItemFilter, MultipleItemFilter };
