import * as R from "ramda";
import { createSelector } from "reselect";
import {
  TABS,
  SORTING,
  ROW_ACTIONS
} from "EventLight/Portals/Portals/constants";
import { ACTION_TYPES } from "ui-kit/PopoverMenu/constants";
import { getters } from "EventLight/Portals/Portals/model";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";

const sortingForms = (forms, activeSorting) => {
  if (activeSorting === SORTING.PENDING) {
    const sorting = R.descend(R.prop(activeSorting));

    return R.sort(sorting, forms);
  }
  const sorting = R.ascend(R.prop(activeSorting));
  return R.sort(sorting, forms);
};

export const getGroupPortals = createSelector(
  getters.data,
  data => {
    return R.propOr([], "group_portals", data);
  }
);

export const getIndividualPortals = createSelector(
  getters.data,
  data => {
    return R.propOr([], "individual_portals", data);
  }
);

export const getSalesPortals = createSelector(
  getters.data,
  data => {
    return R.propOr([], "sales_portals", data);
  }
);

export const getCustomSites = createSelector(
  getters.data,
  data => {
    return R.propOr([], "custom_sites", data);
  }
);

export const getAllForms = createSelector(
  getters.data,
  getters.activeTab,
  getters.filter,
  getters.activeSorting,
  (data, activeTab, filter, activeSorting) => {
    const allForms = R.propOr([], "all_forms_items", data);
    const allFormsTab =
      activeTab === TABS.ALL_FORMS
        ? allForms
        : R.filter(R.propEq("status", activeTab), allForms);

    const orderedForms = sortingForms(allFormsTab, activeSorting);

    if (R.isEmpty(filter)) {
      return orderedForms;
    }

    return R.filter(
      form => R.contains(R.toLower(filter), R.toLower(form.name)),
      orderedForms
    );
  }
);

export const getSortingLabel = createSelector(
  getters.activeSorting,
  activeSorting => {
    if (activeSorting === SORTING.ABC) {
      return "ABC";
    }
    if (activeSorting === SORTING.CREATED_AT) {
      return "Created Date";
    }
    return "Most Pending";
  }
);

export const getRowActions = R.always([
  {
    type: ACTION_TYPES.HEADER,
    name: "EDIT SETTINGS"
  },
  {
    id: ROW_ACTIONS.BASIC_SETTINGS,
    type: ACTION_TYPES.ITEM,
    name: "Basic settings",
    leftIcon: "settings"
  },
  {
    id: ROW_ACTIONS.CONFIRMATION_MESSAGE,
    type: ACTION_TYPES.ITEM,
    name: "Confirmation message",
    leftIcon: "message"
  },
  {
    type: ACTION_TYPES.HEADER,
    name: "SHARE / ASSIGN"
  },
  {
    id: ROW_ACTIONS.COPY_LINK,
    type: ACTION_TYPES.ITEM,
    name: "Copy share link",
    leftIcon: "link"
  },
  {
    id: ROW_ACTIONS.SHARE_LINK,
    type: ACTION_TYPES.ITEM,
    name: "Send share link",
    leftIcon: "send"
  },
  {
    id: ROW_ACTIONS.DELETE,
    type: ACTION_TYPES.ITEM,
    name: "Delete form",
    leftIcon: "delete",
    confirm: true
  }
]);

export const getPortalLink = createSelector(
  getEventDetails,
  eventDetails => {
    // eslint-disable-next-line no-underscore-dangle
    return `${window.__LENND_APP_URL__}/portal-login/${eventDetails.slug}/${eventDetails.uuid}`;
  }
);
