import React, { Component } from "react";
import * as R from "ramda";
import createContactFields from "components/Event/Contacts/Utils/create-contact-fields";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import { CONTACTS } from "utils/standard-module-field-ids";
import { CONTACT_STANDARD_FIELD_IDS } from "components/Event/Contacts/constants";
import {
  Div,
  Text1,
  StarIcon,
  FontIcon,
  Text2,
  Text4,
  MediumAvatar,
  BigOutlineButton,
  LeftIcon,
  HalfSizeDotIcon,
  BigInsetInput,
  SmallShadedButton,
  BigTextInput,
  SearchIcon,
  LoadingIcon,
  SmallCheckbox,
  PopoverMenu,
  SmallClearButton,
  MoreIcon,
  MediumFilledButton,
  MediumOutlineButton,
  Modal as BaseModal,
  modalHandler
} from "components/Base";
import Avatar from "components/PortalV2/GroupManager/ScalableAvatar";
import AccountProfileController from "components/Global/Controllers/AccountProfile";
import { withHandlers, withState } from "utils/General";
import { Modal } from "./AddRecordModal";
import SearchContacts from "components/Global/Controllers/SearchContact";
import { withRouter } from "react-router";
import Helpers from "utils/Global/Helpers";
import TypePicker from "components/Global/StandAloneEditors/Types";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { addRelationship } from "redux/modules/accounts/contactRelationship/actions";
import { createPortalUserFromContact } from "redux/modules/user/actions";
import { addRecord } from "redux/modules/modules/records/actions";
import { addValue } from "redux/modules/modules/values/actions";
import { track } from "redux/modules/analytics/actions";
import { hideModal } from "redux/modules/modal/actions";
import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";

import { eventDetails } from "redux/modules/event/selectors";
import { orgDetails } from "redux/modules/organization/selectors";
import { recordTypes } from "redux/modules/modules/recordTypes/selectors";

function mapStateToProps(state) {
  return {
    orgDetails: orgDetails(state),
    eventDetails: eventDetails(state),
    contactRecordTypes: recordTypes(state, STANDARD_MODULES.contacts.id)
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      addRecord,
      addRelationship,
      addValue,
      createPortalUserFromContact,
      hideModal: props.hideModal || hideModal,
      track,
      getRecordTypes
    },
    dispatch
  );
}

const AddNewContactModal = modalHandler()(
  R.compose(
    withState("isPrimary", "setIsPrimary", true),
    withState("hasLoginAccess", "setHasLoginAccess", props =>
      props.isViewingAsOrg ? false : true
    ),
    withState("type", "setType", null),
    withState("values", "setValues", {}),
    withHandlers({
      setValue: ({ values, setValues }) => (name, value) => {
        setValues({ ...values, [name]: value });
      }
    })
  )(
    ({
      showModal,
      closeModal,
      values: { role, fname, lname, email, phone },
      setValue,
      isModalShowing,
      onCreateContact,
      setIsPrimary,
      isPrimary,
      hasLoginAccess,
      setHasLoginAccess,
      type,
      setType,
      contactRecordTypes,
      isViewingAsOrg
    }) => {
      const defaultRecordTypeId = R.compose(
        R.prop("id"),
        R.find(R.propEq("is_default", true))
      )(contactRecordTypes);
      const recordTypeName = R.compose(
        R.prop("name"),
        R.find(R.propEq("id", type || defaultRecordTypeId))
      )(contactRecordTypes);
      return (
        <div>
          <Text2 onClick={showModal} bold color="purple8">
            + Create a new contact
          </Text2>
          <BaseModal background="translucent" isModalShowing={isModalShowing}>
            <Div bg="white" width={450} bra={1} py={5} px={10}>
              <Div
                display="row.flex-start.center"
                color="primary8"
                onClick={closeModal}
                fw={3}
                fs={2}
                mb={5}
              >
                <LeftIcon color="primary8" size={20} mr={1} />
                <span>Back to results</span>
              </Div>
              <Div display="row">
                <Div flex={1} pr={2}>
                  <Text2>First Name*</Text2>
                  <BigInsetInput
                    onChange={val => setValue("fname", val)}
                    mb={3}
                    display="row.flex-start.center"
                  />
                </Div>
                <Div flex={1}>
                  <Text2>Last Name*</Text2>
                  <BigInsetInput
                    onChange={val => setValue("lname", val)}
                    mb={3}
                    display="row.flex-start.center"
                  />
                </Div>
              </Div>
              <Div py={2} display="row">
                <Div flex={1} pr={2}>
                  <Text2>Email Address*</Text2>
                  <BigInsetInput
                    onChange={val => setValue("email", val)}
                    mb={3}
                    display="row.flex-start.center"
                  />
                </Div>
                <Div flex={1}>
                  <Text2>Phone Number</Text2>
                  <BigInsetInput
                    onChange={val => setValue("phone", val)}
                    mb={3}
                    display="row.flex-start.center"
                  />
                </Div>
              </Div>
              <Text2>Role with this group</Text2>
              <BigInsetInput
                onChange={val => setValue("role", val)}
                mb={3}
                display="row.flex-start.center"
              />

              {isViewingAsOrg ? null : (
                <Div display="column" mb={4}>
                  <Text2>Record Type</Text2>
                  <TypePicker
                    value={recordTypeName}
                    list={[...contactRecordTypes]}
                    onSelect={({ id }) => setType(id)}
                  />
                </Div>
              )}

              <Div
                display="row.flex-start.center"
                onClick={() => setIsPrimary(!isPrimary)}
                mb={isViewingAsOrg ? 3 : 0}
              >
                <SmallCheckbox onClick={() => {}} selected={isPrimary} mr={2} />
                <Div fw={3} fs={1} color="gray7">
                  Mark as primary person
                </Div>
              </Div>

              {isViewingAsOrg ? null : (
                <Div
                  display="row.flex-start.center"
                  onClick={() => setHasLoginAccess(!hasLoginAccess)}
                  mb={3}
                >
                  <SmallCheckbox
                    onClick={() => {}}
                    selected={hasLoginAccess}
                    mr={2}
                  />
                  <Div fw={3} fs={1} color="gray7">
                    Give login access to this group's portal
                  </Div>
                </Div>
              )}

              <Div display="row.flex-start.center">
                <MediumFilledButton
                  mr={2}
                  bg="altB5"
                  onClick={() => {
                    onCreateContact({
                      role,
                      fname,
                      lname,
                      email,
                      phone,
                      isPrimary,
                      hasLoginAccess,
                      recordTypeId: type
                    });
                    closeModal();
                  }}
                  disabled={
                    !fname || !lname || !email || !Helpers.isValidEmail(email)
                  }
                >
                  Add
                </MediumFilledButton>
                <MediumOutlineButton onClick={closeModal}>
                  Cancel
                </MediumOutlineButton>
              </Div>
            </Div>
          </BaseModal>
        </div>
      );
    }
  )
);

const SelectContactModal = modalHandler()(
  R.compose(
    withState("newEmail", "setNewEmail"),
    withState("role", "setRole"),
    withState("isPrimary", "setIsPrimary", true),
    withState("hasLoginAccess", "setHasLoginAccess", props =>
      props.isViewingAsOrg ? false : true
    )
  )(
    ({
      showModal,
      closeModal,
      role,
      setRole,
      isModalShowing,
      onAdd,
      id,
      name,
      newEmail,
      setNewEmail,
      email,
      setIsPrimary,
      isPrimary,
      setHasLoginAccess,
      hasLoginAccess,
      isViewingAsOrg
    }) => (
      <div>
        <SmallShadedButton onClick={showModal}>Select</SmallShadedButton>
        <BaseModal background="translucent" isModalShowing={isModalShowing}>
          <Div bg="white" width={450} bra={1} py={5} px={10}>
            <Div pb={4} display="row.flex-start.center">
              <MediumAvatar text={name} />
              <Text2 bold ml={2}>
                {name}
              </Text2>
            </Div>

            {!email ? (
              <Div pb={2}>
                <Div pb={1} display="row.space-between.flex-end">
                  <Text2>Email</Text2>
                  <Text2 italic color="neutral4">
                    * Required
                  </Text2>
                </Div>
                <BigInsetInput
                  onChange={val => setNewEmail(val)}
                  continuous
                  mb={3}
                  display="row.flex-start.center"
                />
              </Div>
            ) : (
              ""
            )}

            <Div pb={1} display="row.space-between.flex-end">
              <Text2>Role with this group</Text2>
              <Text2 italic color="neutral4">
                Optional
              </Text2>
            </Div>

            <BigInsetInput
              continuous
              onChange={val => setRole(val)}
              mb={3}
              display="row.flex-start.center"
            />

            <Div
              display="row.flex-start.center"
              onClick={() => setIsPrimary(!isPrimary)}
              mb={isViewingAsOrg ? 3 : 0}
            >
              <SmallCheckbox onClick={() => {}} selected={isPrimary} mr={2} />
              <Div fw={3} fs={1} color="gray7">
                Mark as primary person
              </Div>
            </Div>

            {isViewingAsOrg ? null : (
              <Div
                display="row.flex-start.center"
                onClick={() => setHasLoginAccess(!hasLoginAccess)}
                mb={3}
              >
                <SmallCheckbox
                  onClick={() => {}}
                  selected={hasLoginAccess}
                  mr={2}
                />
                <Div fw={3} fs={1} color="gray7">
                  Give login access to this group's portal
                </Div>
              </Div>
            )}

            <Div display="row.flex-start.center">
              <MediumFilledButton
                mr={2}
                bg="altB5"
                disabled={!email ? !newEmail : false}
                onClick={() => {
                  onAdd({ id, role, newEmail, isPrimary, hasLoginAccess });
                  closeModal();
                }}
              >
                Add contact
              </MediumFilledButton>
              <MediumOutlineButton onClick={closeModal}>
                Cancel
              </MediumOutlineButton>
            </Div>
          </Div>
        </BaseModal>
      </div>
    )
  )
);

function primaryContacts(Comp) {
  class PrimaryContacts extends Component {
    state = { loading: false };
    modified = false;

    componentDidMount() {
      this.props.getRecordTypes({
        moduleId: STANDARD_MODULES.contacts.id,
        options: {
          orgId: this.props.orgDetails.id,
          eventId: this.props.eventDetails.id
        }
      });
    }

    componentWillUnmount() {
      if (this.modified && this.props.onDone) {
        this.props.onDone();
      }
    }

    goToContactProfile = (contactId, newWindow) => {
      const url =
        this.props.orgDetails && this.props.orgDetails.id
          ? `/org-light/${this.props.orgDetails.id}/contact/${contactId}`
          : `/event-light/${this.props.eventDetails.id}/contact/${contactId}`;
      if (newWindow) {
        window.open(url, "_blank");
      } else {
        this.props.router.push({
          pathname: url
        });
      }
    };

    handleAddContact = async ({
      id,
      role,
      fname,
      lname,
      email,
      newEmail,
      isPrimary,
      hasLoginAccess,
      recordTypeId
    }) => {
      let idToUse = id;
      this.modified = true;
      this.setState({ loading: true });

      const orgId = this.props.orgDetails.id;
      const eventId = this.props.eventDetails.id;

      if (
        this.props.account.related_contacts.find(c => c.contact_id === idToUse)
      ) {
        this.setState({ loading: false });
        return false;
      }

      if (!idToUse) {
        if (!fname || !lname) {
          this.setState({ loading: false });
          return false;
        }
        // If we need to create a contact, create it
        const createdContact = await this.props.addRecord({
          moduleId: STANDARD_MODULES.contacts.id,
          typeId: recordTypeId,
          record: createContactFields({ fname, lname, email }).reduce(
            (a, b) => {
              a[b.fieldId] = b.value;
              return a;
            },
            {}
          ),
          options: {
            orgId,
            eventId
          }
        });

        // Update the id
        idToUse = createdContact.id;
      }

      // if contact does not have an email, createPortalUserFromContact will fail.
      if (newEmail) {
        await this.props.addValue({
          fieldId: CONTACTS.EMAIL,
          value: { type: "text", value: newEmail },
          moduleId: STANDARD_MODULES.contacts.id,
          recordId: idToUse
        });
      }

      // Associate the contact with the account
      await this.props.addRelationship({
        orgId,
        eventId,
        isPrimary,
        role,
        accountId: this.props.groupId,
        contactId: idToUse
      });

      this.props.onShowAddAnother(false);

      if (hasLoginAccess) {
        await this.props.createPortalUserFromContact({
          accountId: this.props.groupId,
          contactId: idToUse,
          eventId
        });
      }

      if (eventId) {
        this.props.track({
          event: "lennd_glm_admin_add_primary_contact",
          properties: {
            eventId,
            accountId: this.props.groupId,
            contactId: idToUse
          }
        });
      }

      if (this.props.onAddContact) {
        this.props.onAddContact();
      }

      await this.props.fetchProfile();
      this.setState({ loading: false });

      return null;
    };

    handleEditRole = async row => {
      this.props.showEditContactModal({
        relationshipId: row.id,
        role: row.role
      });
    };

    handleRemoveContact = async row => {
      this.modified = true;
      this.setState({ loading: true });
      await this.props.removeRelatedContact(row);
      this.setState({ loading: false });
    };

    handleTogglePrimaryContact = async (relationshipId, isPrimary) => {
      this.setState({ loading: true });
      this.modified = true;
      await this.props.updateRelatedContact({
        relationshipId,
        isPrimary
      });
      this.setState({ loading: false });
    };

    render() {
      const isViewingAsOrg = Boolean(
        this.props.orgDetails && this.props.orgDetails.id
      );

      const relatedContacts = R.map(c => {
        const name = [
          R.compose(
            v => (v ? R.path(["value", "value"])(v) : null),
            R.find(R.propEq("field_id", CONTACT_STANDARD_FIELD_IDS.fname)),
            R.prop("values")
          )(c),
          R.compose(
            v => (v ? R.path(["value", "value"])(v) : null),
            R.find(R.propEq("field_id", CONTACT_STANDARD_FIELD_IDS.lname)),
            R.prop("values")
          )(c)
        ]
          .filter(v => v && v.length)
          .join(" ");

        const email = R.compose(
          v => (v ? R.path(["value", "value"])(v) : null),
          R.find(R.propEq("field_id", CONTACT_STANDARD_FIELD_IDS.email)),
          R.prop("values")
        )(c);

        return {
          name,
          email,
          contactId: c.contact_id,
          canLogin: Boolean(c.can_login),
          isPrimary: c.is_primary,
          recordTypeId: c.type.id,
          recordTypeName: isViewingAsOrg ? null : c.type.name,
          ...c
        };
      })(
        this.props.account && this.props.account.related_contacts
          ? this.props.account.related_contacts
          : []
      );

      return (
        <Comp
          {...{
            hideModal: this.props.hideModal,
            loading: this.state.loading,
            onAddContact: this.handleAddContact,
            onEditRole: this.handleEditRole,
            onRemoveContact: this.handleRemoveContact,
            onToggleLoginAccess: this.props.onToggleLoginAccess,
            onTogglePrimaryContact: this.handleTogglePrimaryContact,
            groupName: this.props.groupName,
            contacts: this.props.contacts,
            recordTypeColor: this.props.recordTypeColor,
            recordTypeIcon: this.props.recordTypeIcon,
            showAddAnother: this.props.showAddAnother,
            onShowAddAnother: this.props.onShowAddAnother,
            goToContactProfile: this.goToContactProfile,
            showLoginDetailsModal: this.props.showLoginDetailsModal,
            relatedContacts: relatedContacts,
            showChangeContactRecordTypeModal: this.props
              .showChangeContactRecordTypeModal,
            contactRecordTypes: this.props.contactRecordTypes,
            isViewingAsOrg
          }}
        />
      );
    }
  }
  return withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(
      R.compose(
        withState("contacts", "setContacts", props => props.contacts || []),
        withState("showAddAnother", "onShowAddAnother", true)
      )(PrimaryContacts)
    )
  );
}

const View = ({
  groupName,
  onAddContact,
  onRemoveContact,
  recordTypeColor,
  recordTypeIcon,
  showAddAnother,
  onShowAddAnother,
  onEditRole,
  onTogglePrimaryContact,
  onToggleLoginAccess,
  showLoginDetailsModal,
  goToContactProfile,
  showChangeContactRecordTypeModal,
  relatedContacts,
  contactRecordTypes,
  loading,
  isViewingAsOrg
}) => (
  <SearchContacts limit={15}>
    {({ isSearching, results, searchTerm, onSearch, clearSearch }) => {
      const contactIds = R.map(R.prop("contactId"))(relatedContacts);
      const cleanResults = R.reject(c => contactIds.includes(c.id))(results);
      return (
        <Div width={1}>
          <Div display="row.space-between.center" mb={5}>
            <Div display="row.flex-start.center" flex={1}>
              <Avatar
                size={29}
                style={{ backgroundColor: recordTypeColor }}
                mr={2}
                content={
                  <FontIcon
                    style={{
                      fontSize: 16,
                      color: "rgba(255,255,255,0.6"
                    }}
                  >
                    {recordTypeIcon}
                  </FontIcon>
                }
              />
              <Div fw={3} fs={6} color="gray7">
                {groupName}
              </Div>
            </Div>
            {loading ? <LoadingIcon size={30} color="neutral4" mr={2} /> : null}
          </Div>

          <Div bg="gray0" width={1} p={6} bra={1} mb={3}>
            {Boolean(relatedContacts.length) && (
              <Div bb={1} pb={2} bc="neutral3" mb={3}>
                <Text2 pb={1} bold>
                  Added Contacts:
                </Text2>
                {relatedContacts.map(c => (
                  <Div key={c.id} py={1} display="row.space-between.center">
                    <Div display="row.flex-start.center">
                      {c.canLogin ? (
                        <HalfSizeDotIcon
                          color={"primary7"}
                          size={16}
                          tooltip="Has portal login access"
                        />
                      ) : null}
                      {c.isPrimary ? (
                        <StarIcon
                          color="yellow5"
                          size={16}
                          tooltip="Primary contact"
                        />
                      ) : null}
                      <Text2
                        ml={1}
                        bold
                        color="primary8"
                        onClick={() => goToContactProfile(c.contactId, true)}
                      >
                        {c.name}
                      </Text2>
                      <Text1 ml={2} color="gray6">
                        {[c.recordTypeName, c.email]
                          .filter(v => v && v.length)
                          .join(" · ")}
                      </Text1>
                    </Div>
                    <Div display="row.flex-end.center">
                      <Text1>{c.role}</Text1>

                      <PopoverMenu
                        Label={({ onClick }) => (
                          <SmallClearButton
                            ml={2}
                            OnlyIcon={MoreIcon}
                            onClick={onClick}
                          />
                        )}
                        menuItems={[
                          [
                            "Open Record",
                            () => goToContactProfile(c.contactId, true)
                          ],
                          ["Change Role", () => onEditRole(c)],
                          [
                            c.isPrimary
                              ? "Remove as primary"
                              : "Mark as primary",
                            () => onTogglePrimaryContact(c.id, !c.isPrimary)
                          ],
                          isViewingAsOrg
                            ? null
                            : c.canLogin || (c.email && c.email.length)
                            ? [
                                c.canLogin
                                  ? "Remove login access"
                                  : "Give login access",
                                () => onToggleLoginAccess(c)
                              ]
                            : null,
                          isViewingAsOrg
                            ? null
                            : c.canLogin
                            ? ["Login Details", () => showLoginDetailsModal(c)]
                            : null,
                          isViewingAsOrg
                            ? null
                            : [
                                "Change Type",
                                () =>
                                  showChangeContactRecordTypeModal(
                                    c.contactId,
                                    c.recordTypeId
                                  )
                              ],
                          ["Remove Person", () => onRemoveContact(c)]
                        ].filter(o => o)}
                      />
                    </Div>
                  </Div>
                ))}
              </Div>
            )}

            {relatedContacts.length && !showAddAnother ? (
              <Div mt={2}>
                <Text2
                  bold
                  color="purple8"
                  onClick={() => onShowAddAnother(true)}
                >
                  + Add another
                </Text2>
              </Div>
            ) : (
              <div>
                <Text4 mb={2} bold>
                  Add {relatedContacts.length ? "another" : "a primary"} contact
                  at <strong>{groupName}</strong>
                </Text4>
                <Div>
                  <Div width={1} shadow={1} bg="white" bra={1}>
                    <BigTextInput
                      RightIcon={isSearching ? LoadingIcon : SearchIcon}
                      onChange={onSearch}
                      placeholder="Search email or name..."
                      value={searchTerm}
                      continuous
                      display="row.flex-start.center"
                      style={{
                        border: "1px solid #eee"
                      }}
                    />
                  </Div>
                  {searchTerm ? (
                    <Text1 bold mt={4}>
                      Results:
                    </Text1>
                  ) : (
                    ""
                  )}
                  {cleanResults.length || searchTerm ? (
                    <Div bg="neutral0" bra={1} p={3} fs={3}>
                      {!cleanResults.length && searchTerm ? (
                        <Div textAlign="center" width={1}>
                          No results for '{searchTerm}'
                        </Div>
                      ) : (
                        cleanResults.map(
                          ({ id, email, fullName, firstName, lastName }) => (
                            <Div
                              key={id}
                              display="row.flex-start.center"
                              mt={2}
                            >
                              <Text2 bold flex={1}>
                                {fullName.length
                                  ? `${fullName} ${email ? `(${email})` : ""}`
                                  : email}
                              </Text2>
                              <SelectContactModal
                                id={id}
                                email={email}
                                isViewingAsOrg={isViewingAsOrg}
                                name={
                                  fullName.length
                                    ? `${fullName} ${email ? `(${email})` : ""}`
                                    : email
                                }
                                onAdd={({
                                  role,
                                  newEmail,
                                  isPrimary,
                                  hasLoginAccess
                                }) => {
                                  onAddContact({
                                    id,
                                    lname: lastName,
                                    fname: firstName,
                                    role,
                                    newEmail,
                                    isPrimary,
                                    hasLoginAccess
                                  });
                                  clearSearch();
                                }}
                              />
                            </Div>
                          )
                        )
                      )}
                    </Div>
                  ) : null}
                  <Div px={4}>
                    <Text2 pt={6} pb={4}>
                      Can't find who you are looking for?
                    </Text2>
                    <AddNewContactModal
                      isViewingAsOrg={isViewingAsOrg}
                      contactRecordTypes={contactRecordTypes}
                      onCreateContact={data => {
                        onAddContact(data);
                        clearSearch();
                      }}
                    />
                  </Div>
                </Div>
              </div>
            )}
          </Div>
        </Div>
      );
    }}
  </SearchContacts>
);

const ModalView = props => {
  return (
    <Modal
      hideModal={props.hideModal}
      heading="Add Primary Contacts"
      footer={[
        <Div flex={1} />,
        <BigOutlineButton onClick={props.hideModal}>Done</BigOutlineButton>
      ]}
      body={<View {...props} />}
    />
  );
};

export const PrimaryContactsView = AccountProfileController(
  primaryContacts(View)
);
export const PrimaryContactsModal = AccountProfileController(
  primaryContacts(ModalView)
);

export default PrimaryContactsView;
