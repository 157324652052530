import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import DocumentPreview from "./DocumentPreview";
import { addRecord } from "redux/modules/modules/records/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { associateDocumentToModuleRecord } from "redux/modules/modules/documents/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSnackbar,
      associateDocumentToModuleRecord,
      addRecord,
      showModal,
      hideModal
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(DocumentPreview);
