import { createModule } from "redux-mvc";
import searchBar from "ui-kit/SearchBar";
import table from "ui-kit/Table";

import model from "./model";
import sagas from "./sagas";

const module = createModule(model);
module.setRootSaga(sagas);

module.plugModule(searchBar);
module.plugModule(table);

export default module;
