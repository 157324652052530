import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";
import datetimeValueToMoment from "utils/value-types/helpers/datetime-value-to-moment";
import { TABS } from "./constants";
import { TYPES } from "EventLight/FileRequests/AddAssignmentModal/constants";

const iniState = {
  loading: true,
  saving: false,
  name: "",
  dueDate: null,
  enableReminders: false,
  criteria: "",
  requestId: "",
  files: [],
  activeTab: TABS.DETAILS,
  eventUsers: [],
  managers: [],
  accountRecordTypes: [],
  contactRecordTypes: [],
  accountsCollapsed: false,
  contactsCollapsed: false,
  assignedToGroupTypes: [],
  assignedToPeopleTypes: [],
  assignedToGroups: [],
  assignedToPeople: [],
  showAddAssignmentModal: false,
  type: TYPES.ACCOUNT,
  editDueDateModalId: "",
  customCloseDate: null
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    showUploadFilesModal: R.identity,
    save: R.identity,
    onDone: R.identity,
    hideCreateModal: R.identity,
    addAssignedToGroupTypes: R.identity,
    updateDueDate: R.identity,
    setInitialData: (_, { payload }) => ({
      name: R.pathOr("", ["name", "value"], payload),
      criteria: R.pathOr("", ["criteria", "value"], payload),
      enableReminders: R.pathOr(false, ["enable_reminders", "value"], payload),
      files: R.pathOr([], ["sample_documents", "value", "files"], payload),
      dueDate: datetimeValueToMoment(R.propOr({}, "due_date", payload)),
      managers: R.propOr([], "managers", payload),
      assignedToGroupTypes: R.propOr([], "assigned_to_group_types", payload),
      assignedToPeopleTypes: R.propOr([], "assigned_to_people_types", payload),
      assignedToGroups: R.propOr([], "assigned_to_groups", payload),
      assignedToPeople: R.propOr([], "assigned_to_people", payload)
    }),
    toggleEnableReminders: ({ enableReminders }) => ({
      enableReminders: !enableReminders
    }),
    deleteFile: ({ files }, { payload: idx }) => ({
      files: [...files.slice(0, idx), ...files.slice(idx + 1)]
    }),
    resetData: R.always(iniState),
    toggleManagers: (state, { payload: { user_id, isManager } }) => ({
      managers: isManager
        ? R.filter(id => id !== user_id, state.managers)
        : [...state.managers, user_id]
    }),
    updateFiles: (state, { payload: files }) => ({
      files: [...state.files, ...files]
    }),
    setRecords: (
      _,
      { payload: { accountRecordTypes, contactRecordTypes } }
    ) => ({
      accountRecordTypes,
      contactRecordTypes
    }),
    toggleContactRecordType: (state, { payload: { id, selected } }) => ({
      assignedToPeopleTypes: selected
        ? R.filter(contactId => contactId !== id, state.assignedToPeopleTypes)
        : [...state.assignedToPeopleTypes, id]
    }),
    toggleAccountRecordType: (state, { payload: { id, selected } }) => ({
      assignedToGroupTypes: selected
        ? R.filter(contactId => contactId !== id, state.assignedToGroupTypes)
        : [...state.assignedToGroupTypes, id]
    }),
    selectAllAccountRecordTypes: state => ({
      assignedToGroupTypes: R.map(R.prop("id"), state.accountRecordTypes)
    }),
    deselectAllAccountRecordTypes: () => ({
      assignedToGroupTypes: []
    }),
    selectAllContactRecordTypes: state => ({
      assignedToPeopleTypes: R.map(R.prop("id"), state.contactRecordTypes)
    }),
    deselectAllContactRecordTypes: () => ({
      assignedToPeopleTypes: []
    }),
    showAccountAddAssignmentModal: () => ({
      showAddAssignmentModal: true,
      type: TYPES.ACCOUNT
    }),
    showContactAddAssignmentModal: () => ({
      showAddAssignmentModal: true,
      type: TYPES.CONTACT
    }),
    updateAssignedToGroups: (state, { payload: group }) => ({
      assignedToGroups: R.uniqBy(R.prop("id"))([
        ...state.assignedToGroups,
        group
      ])
    }),
    removeAssignedGroupId: (state, { payload: groupId }) => ({
      assignedToGroups: R.filter(
        ({ id }) => id !== groupId,
        state.assignedToGroups
      )
    }),
    updateAssignedToPeople: (state, { payload: contact }) => ({
      assignedToPeople: R.uniqBy(R.prop("id"))([
        ...state.assignedToPeople,
        contact
      ])
    }),
    removeAssignedContactId: (state, { payload: contactId }) => ({
      assignedToPeople: R.filter(
        ({ id }) => id !== contactId,
        state.assignedToPeople
      )
    }),
    openDueDateModal: (state, { payload: { id, customCloseDate, type } }) => ({
      editDueDateModalId: id,
      customCloseDate,
      type
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
