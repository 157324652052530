import * as R from "ramda";
import { baseColors } from "components/Base/Base/colorSpecs.js";

export const NAMESPACE = "AddBulkItemsModal";

const hues = ["yellow", "orange", "red", "green", "blue", "purple", "gray"];
const shades = [8, 5, 3];

export const COLORS = R.reduce(
  (acc, hue) => [
    ...acc,
    ...R.map(
      shade => ({
        label: "Color",
        value: baseColors[hue][shade],
        color: `${hue}${shade}`
      }),
      shades
    )
  ],
  [],
  hues
);
