import * as R from "ramda";
import { select, put, call } from "redux-saga/effects";

import { getZoneCategoriesByEventId } from "../selectors";

import { actions } from "../model";

import { apiCall } from "./utils";

export const fetchZoneCategoriesByEventId = function*({
  eventId,
  updateData = false
}) {
  const oldZones = yield select(getZoneCategoriesByEventId, { eventId });
  if (!updateData && !R.isEmpty(oldZones)) {
    return oldZones;
  }
  try {
    // get support articles
    const result = yield call(apiCall, {
      method: "get",
      url: `/items/zones/groups/event/${eventId}`
    });

    yield put(
      actions.zoneCategoriesResponse({
        zones: result.payload,
        eventId
      })
    );

    return result.payload;
  } catch (error) {
    yield put(actions.error(error, { meta: {} }, true));
    throw error;
  }
};
