import React from "react";
import * as R from "ramda";
import Description from "Portal/Description";
import CSSModules from "react-css-modules";

import * as flags from "utils/feature-flags";
import { selectFeatureFlag } from "@flopflip/react-redux";
import { connect } from "react-redux";

import css from "./page.scss";

const decorate = R.compose(
  connect(
    state => ({
      flagCanViewPeopleTabOnPortals: selectFeatureFlag(
        flags.CAN_VIEW_PEOPLE_TAB_ON_PORTALS.NAME
      )(state)
    }),
    {}
  ),
  CSSModules(css)
);

const showBlock = (flagCanViewPeopleTabOnPortals, instanceId) => {
  if (instanceId === "people") {
    return flagCanViewPeopleTabOnPortals;
  } else {
    return true;
  }
};

const Page = ({
  instanceId,
  children,
  title,
  description,
  backgroundImageUrl,
  style: userStyles = {},
  flagCanViewPeopleTabOnPortals
}) => (
  <div styleName="wrapper">
    <div
      style={{
        backgroundImage: backgroundImageUrl
          ? `url(${backgroundImageUrl})`
          : undefined
      }}
      styleName="header"
    />
    <div styleName="body" style={userStyles}>
      {title && title.length ? (
        <div styleName="title">
          <Description
            instanceId={instanceId}
            title={title}
            description={description}
          />
        </div>
      ) : null}
      {showBlock(flagCanViewPeopleTabOnPortals, instanceId) && children}
    </div>
  </div>
);

export default decorate(Page);
