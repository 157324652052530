import { createSelector } from "reselect";
import { getters } from "Modules/ImportModal";
import * as R from "ramda";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { PAGES } from "Modules/ImportModal/constants";
import { DEFAULT_VALUE, FIELD_IDS } from "Modules/ImportModal/constants";

const flat = R.compose(
  R.flatten,
  R.map(R.propOr([], "fields"))
);

const mapIndexed = R.addIndex(R.map);

export const getTimeRemaining = createSelector(
  getters.pendingSeconds,
  pendingSeconds => {
    if (R.isNil(pendingSeconds)) {
      return "This can take a few minutes...";
    } else {
      const hours = Math.floor(pendingSeconds / 3600) % 24;
      const minutes = Math.floor(pendingSeconds / 60) % 60;
      const seconds =
        pendingSeconds < 60 ? pendingSeconds : pendingSeconds % 60;
      return `${hours > 0 ? `${hours} hour${hours > 1 ? "s" : ""}` : ""}${
        minutes > 0
          ? ` ${hours > 0 ? "and" : ""} ${minutes} minute${
              minutes > 1 ? "s" : ""
            }`
          : ""
      }${
        seconds > 0
          ? ` ${hours > 0 || minutes > 0 ? "and" : ""} ${seconds} second${
              seconds > 1 ? "s" : ""
            }`
          : ""
      } remaining`;
    }
  }
);

export const getHeaderTitle = createSelector(
  getters.selectedPage,
  selectedPage => {
    if (selectedPage === PAGES.INVALID_ROWS) {
      return "Review Import";
    } else if (selectedPage === PAGES.SUMMARY_SCENE) {
      return "Import Confirmation";
    }
    return "Import";
  }
);

export const getLayoutWidth = createSelector(
  getters.selectedPage,
  selectedPage => {
    if (selectedPage === PAGES.IMPORT_FILE) {
      return 1019;
    } else if (selectedPage === PAGES.SUMMARY_SCENE) {
      return 1131;
    }
    return 1125;
  }
);

export const getFields = createSelector(
  getters.fieldGroups,
  flat
);

export const getFilterEnabledGroups = createSelector(
  getters.enabledFieldsGroup,
  getters.selectedFieldsFilter,
  (enabledFieldsGroup, selectedFieldsFilter) => {
    if (R.isEmpty(selectedFieldsFilter)) {
      return enabledFieldsGroup;
    }
    return R.filter(
      field =>
        R.contains(R.toLower(selectedFieldsFilter), R.toLower(field.name)),
      enabledFieldsGroup
    );
  }
);

export const getMessages = createSelector(
  getters.fieldGroups,
  getters.mapObject,
  (fieldGroups, mapObject) => {
    return R.compose(
      R.flatten,
      mapIndexed((fieldGroup, index) =>
        R.compose(
          R.map(field => ({
            id: field.id,
            fieldName: R.find(f => R.equals(f.id, field.id))(
              R.propOr([], "fields")(mapObject.fields_groups[index])
            ).name,
            groupName: fieldGroup.name
          })),
          R.filter(field =>
            R.or(
              R.isEmpty(R.propOr("", "column")(field)),
              R.and(
                R.equals(R.propOr("", "column")(field), DEFAULT_VALUE),
                R.isEmpty(R.propOr("", "default_value")(field))
              )
            )
          ),
          R.propOr([], "fields")
        )(fieldGroup)
      )
    )(fieldGroups);
  }
);

const setPreviewRecords = (row, moduleId, fieldGroups, mapObject) => {
  const { fields: enabledFields } = R.find(R.propEq("module_id", moduleId))(
    fieldGroups
  );

  const numberElements = () => {
    if (R.equals(moduleId, STANDARD_MODULE_IDS.accounts.id)) {
      return 5;
    } else if (R.equals(moduleId, STANDARD_MODULE_IDS.contacts.id)) {
      return 6;
    }
    return 4;
  };

  const fieldsToShow = R.compose(
    R.filter(field => R.find(R.propEq("id", field.id))(enabledFields)),
    R.prop("fields"),
    R.find(R.propEq("id", moduleId))
  )(mapObject.fields_groups);

  if (!fieldsToShow.length) {
    return [];
  }

  const mappedRecordsPreview = R.compose(
    R.map(fieldToShow => {
      const enabled = R.find(R.propEq("id", fieldToShow.id))(enabledFields);
      const columnMapped = R.propOr("", "column")(enabled);

      if (columnMapped === "") {
        return {
          name: fieldToShow.name,
          value: ""
        };
      } else if (columnMapped === DEFAULT_VALUE) {
        const defaultMapped = R.propOr("", "default_value")(enabled);
        return {
          name: fieldToShow.name,
          id: fieldToShow.id,
          value: defaultMapped
        };
      }
      const importedColumnsIndex = R.findIndex(
        column => column === columnMapped
      )(mapObject.imported_columns);
      return {
        name: fieldToShow.name,
        id: fieldToShow.id,
        value: row[importedColumnsIndex]
      };
    }),
    R.slice(0, numberElements())
  )(fieldsToShow);

  return mappedRecordsPreview;
};

export const getRecords = createSelector(
  getters.mapObject,
  getters.moduleId,
  getters.fieldGroups,
  (mapObject, moduleId, fieldGroups) =>
    R.compose(
      R.filter(row => row.name.length || row.metaValues.length),
      R.map(row => {
        const previewedRecords = setPreviewRecords(
          row,
          moduleId,
          fieldGroups,
          mapObject
        );

        let nameField = "";
        let metaValues = previewedRecords;
        if (R.equals(moduleId, STANDARD_MODULE_IDS.accounts.id)) {
          nameField = R.propOr("", "value")(previewedRecords[0]);
        } else if (R.equals(moduleId, STANDARD_MODULE_IDS.contacts.id)) {
          if (previewedRecords[0].id === FIELD_IDS.FULL_NAME) {
            nameField = R.propOr("", "value")(previewedRecords[0]);
          } else {
            nameField = [
              R.propOr("", "value")(previewedRecords[0]),
              R.propOr("", "value")(previewedRecords[1])
            ]
              .filter(v => v && v.length)
              .join(" ");
          }
        }

        if (R.equals(moduleId, STANDARD_MODULE_IDS.accounts.id)) {
          metaValues = previewedRecords.slice(1, 5);
        } else if (R.equals(moduleId, STANDARD_MODULE_IDS.contacts.id)) {
          if (previewedRecords[0].id === FIELD_IDS.FULL_NAME) {
            metaValues = previewedRecords.slice(1, 5);
          } else {
            metaValues = previewedRecords.slice(2, 6);
          }
        }

        return {
          name: nameField,
          metaValues,
          id: row
        };
      })
    )(
      R.compose(
        R.slice(0, 10),
        R.propOr([], "first_lines")
      )(mapObject)
    )
);

export const handleGetCountOfRowsWithErrors = uploadCsvErrors =>
  R.compose(
    R.length,
    R.uniq,
    R.flatten,
    R.map(R.prop("rows")),
    R.propOr([], "summaryErrors")
  )(uploadCsvErrors);

export const getCountOfRowsWithErrors = createSelector(
  getters.uploadCsvErrors,
  handleGetCountOfRowsWithErrors
);

export const getConfirmationCounters = createSelector(
  getters.importConfirmationCounters,
  counters => ({
    rowsProcessed: counters.rows_number,
    accountsCreated: counters.accounts_created,
    existingAccounts: counters.existing_accounts,
    contactsCreated: counters.contacts_created,
    recordsCreated: counters.custom_records_created,
    peopleCreated: counters.people_portal_created
  })
);
