import { createSelector } from "reselect";

import * as R from "ramda";

import { getters } from "Portal/PortalHome/model";
import { getters as SearchBarGetters } from "ui-kit/SearchBar";
import { TASKS_ORDER, CART_REQUESTS_ID } from "Portal/PortalHome/constants";
import { ACTION_TYPES } from "ui-kit/Table/constants";

import { ROW_ACTIONS_ITEMS, FILTER } from "Portal/PortalHome/constants";
import { isEventFeatureEnabled } from "redux/modules/portal/selectors";
import { FEATURES } from "utils/event-types-and-feature-constants";

export const getRowActions = R.always([
  {
    id: ROW_ACTIONS_ITEMS.OPEN_SUBMISSION_MODAL,
    type: ACTION_TYPES.ITEM,
    name: "Open Submission Modal",
    leftIcon: "open_in_new"
  },
  {
    id: ROW_ACTIONS_ITEMS.EDIT_ROW,
    type: ACTION_TYPES.ITEM,
    name: "Edit",
    leftIcon: "edit"
  },
  {
    id: ROW_ACTIONS_ITEMS.DELETE_ROW,
    type: ACTION_TYPES.ITEM,
    name: "Delete",
    leftIcon: "delete"
  }
]);

export const tasksSelector = createSelector(getters.tasks, tasks =>
  TASKS_ORDER.map(({ text, id }) => ({
    title: text,
    id: id,
    data: tasks[id] || []
  }))
);

export const filteredTasks = createSelector(
  tasksSelector,
  SearchBarGetters.searchTerm,
  getters.dueDate,
  getters.filters,
  (tasks, searchTerm, dueDate, filters) => {
    const activeFilter = R.find(f => f.selected, filters);
    const filteredTasks = R.compose(
      R.map(task => ({
        ...task,
        data: R.reject(subtask => {
          if (activeFilter || (activeFilter && activeFilter.id === "all")) {
            const { id } = activeFilter;

            const isPending =
              (subtask.status === "not_submitted" ||
                subtask.status === "incomplete") &&
              id === "done";

            const isDone = subtask.status === "complete" && id === "pending";

            return isPending || isDone;
          }
          return false;
        }, task.data)
      })),
      R.map(task => ({
        ...task,
        data: R.filter(
          subtask => R.toLower(subtask.name).includes(R.toLower(searchTerm)),
          task.data
        )
      }))
    )(tasks);

    if (!dueDate) {
      return [
        {
          id: "unique",
          data: filteredTasks.reduce((acc, curr) => acc.concat(curr.data), [])
        }
      ];
    }
    return filteredTasks;
  }
);

export const getSubmissionsTableData = createSelector(
  getters.tasks,
  R.compose(R.find(R.propEq("id", CART_REQUESTS_ID)), payload => [
    ...R.propOr([], "past_due", payload),
    ...R.propOr([], "due_next_7_days", payload),
    ...R.propOr([], "due_this_month", payload)
  ])
);

export const getSubmissionsInstanceIds = createSelector(
  getters.tasks,
  R.compose(R.map(R.prop("id")), payload => [
    ...R.propOr([], "past_due", payload),
    ...R.propOr([], "due_next_7_days", payload),
    ...R.propOr([], "due_this_month", payload)
  ])
);

export const getCountOfAllSections = createSelector(
  getters.tasks,
  R.compose(R.length, payload => [
    ...R.propOr([], "past_due", payload),
    ...R.propOr([], "due_next_7_days", payload),
    ...R.propOr([], "due_this_month", payload)
  ])
);

export const filteredTabs = createSelector(
  getters.tabs,
  getters.count_of_schedule_items,
  state => isEventFeatureEnabled(state)(FEATURES.SCHEDULE),
  (tabs, countOfScheduleItems, isSchedulesEnabled) => {
    if (!isSchedulesEnabled || !countOfScheduleItems) {
      return R.filter(t => t.id !== FILTER.SCHEDULE)(tabs);
    }

    return tabs;
  }
);

export const getFiles = createSelector(getters.files, files => files);

export const getSessions = createSelector(
  getters.sessions,
  sessions => sessions
);

export const getPages = createSelector(getters.pages, pages => pages);

export const getAlerts = createSelector(getters.alerts, alerts => alerts);

export const getReportsLink = createSelector(getters.links, links =>
  links.find(link => link.type === "reports")
);

export const getLinks = createSelector(getters.links, links =>
  links.filter(link => link.type !== "reports")
);
