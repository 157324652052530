import React from "react";
import { Div } from "components/Base";

export const Row = ({ children, isRead, last, onClick }) => (
  <Div
    display="row"
    className="hoverContainer"
    bg={{
      default: isRead ? "#fff" : "#fbf7fd",
      hover: isRead ? "#fafafa" : "#f4eef7"
    }}
    style={{ padding: 12, borderBottom: last ? "none" : "1px solid #eaeaea" }}
    onClick={onClick}
  >
    {children}
  </Div>
);

export default Row;
