import React from "react";
import * as R from "ramda";
import { MoreIcon, TinyOutlineButton, PopoverMenu } from "components/Base";

const GroupMorePopover = ({ options }) => (
  <PopoverMenu
    Label={({ onClick }) => (
      <TinyOutlineButton
        onClick={ev => {
          onClick(ev);
          ev.stopPropagation();
        }}
      >
        <MoreIcon />
      </TinyOutlineButton>
    )}
    menuItems={R.filter(option => !R.isEmpty(option), options)}
  />
);

export default GroupMorePopover;
