import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { getItemTypeRows } from "EventLight/Common/Manage/selectors";
import { getters } from "EventLight/Common/Manage";

import CardByItem from "EventLight/Common/Manage/View/CardByItem";
import { Div } from "components/Base";

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    rows: getItemTypeRows(state, props),
    mode: getters.mode(state),
    parentOrderId: getters.orderId(state)
  }))
);

const ItemTypeListView = ({
  rows = [],
  handlers = {},
  mode,
  location,
  instanceId = "",
  ...styleProps
}) => (
  <Div m={3} {...styleProps}>
    {R.map(
      ({
        itemType,
        items,
        allLineItemIds,
        allCredentialLineItemIds,
        allMealLineItemIds
      }) => (
        <CardByItem
          instanceId={instanceId}
          key={itemType.id}
          itemType={itemType}
          items={items}
          mode={mode}
          handlers={handlers}
          expandUrl={`${location.pathname}/item-type/${itemType.id}`}
          allLineItemIds={allLineItemIds}
          allCredentialLineItemIds={allCredentialLineItemIds}
          allMealLineItemIds={allMealLineItemIds}
        />
      ),
      rows
    )}
  </Div>
);

export default decorate(ItemTypeListView);
