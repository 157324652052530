import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Controller from "./Controller";

import { addItemsToCart } from "redux/modules/formsV2/submission/cart/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";

import { cart } from "redux/modules/formsV2/submission/cart/selectors";

function mapStateToProps(state, props) {
  return {
    cart: props.submissionId ? cart(state, props.submissionId) : null
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addItemsToCart,
      showModal,
      hideModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controller);
