import React, { Component } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";

import { Div } from "components/Base";

import uuid from "node-uuid";

const getDisplayName = WrappedComponent => {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
};

const notBind = ["constructor", "componentWillUnmount"];

export const createContext = ({
  module,
  options,
  lifeCycle = {},
  handlers = {},
  contextInstance = Symbol("default context instance")
}) => (WrappedComponent = <Div />) => {
  class WithReduxMVCContext extends Component {
    static contextTypes = {
      store: PropTypes.shape({
        subscribe: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        getState: PropTypes.func.isRequired
      })
    };

    constructor(props, context) {
      super(props);

      this.transactionId = uuid.v4();

      this.handlers = Object.entries(handlers).reduce(
        (handlers, [key, f]) => ({
          ...handlers,
          [key]: f.bind(this)
        }),
        {}
      );

      Object.entries(lifeCycle).forEach(([key, f]) => {
        if (!notBind.includes(key)) {
          this[key] = f.bind(this);
        }
      });

      const { store } = module.createStore({
        globalStore: context.store,
        ...options,
        contextInstance
      });

      this.store = store;

      if (lifeCycle.constructor) {
        lifeCycle.constructor.call(this, props, context);
      }
      module.run({ contextInstance, transactionId: this.transactionId });
    }

    componentWillUnmount() {
      module.cancel({ contextInstance, transactionId: this.transactionId });
      if (lifeCycle.componentWillUnmount) {
        lifeCycle.componentWillUnmount.call(this);
      }
    }

    render() {
      return (
        <Provider store={this.store}>
          <WrappedComponent {...this.props} handlers={this.handlers} />
        </Provider>
      );
    }
  }

  WithReduxMVCContext.displayName = `WithReduxMVCContext(${getDisplayName(
    WrappedComponent
  )})`;

  return WithReduxMVCContext;
};
