import React from "react";
import { connect } from "react-redux";

import { getGroupOptions } from "Items/Manage/selectors";

import { GROUP_FILTER_ID } from "Passes/Common/constants";

import { CheckboxFilter } from "ui-kit/FilterControls/View";

import { Text2 } from "components/Base";

const decorate = connect((state, props) => ({
  options: getGroupOptions(state, props)
}));

const GroupFilter = ({ options = [], ...styleProps }) => (
  <CheckboxFilter
    options={options}
    filtersOn
    instanceId={GROUP_FILTER_ID}
    collapsedDefault
    multiSelect
    {...styleProps}
  >
    <Text2 bold>Group Type</Text2>
  </CheckboxFilter>
);

export default decorate(GroupFilter);
