import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import { actions, getters } from "Modules/AddEditColumnModal/model";
import Catering from "components/Global/Table3/ModalColumn/CateringSettings";
import { noop } from "utils/General";
import css from "./style.scss";

const decorate = R.compose(
  connect(
    (state, props) => ({
      cateringSettings: getters.cateringSettings(state, props)
    }),
    {
      setCateringSettings: actions.setCateringSettings
    }
  ),
  CSSModules(css)
);

const CateringSettings = ({ cateringSettings, setCateringSettings = noop }) => (
  <div styleName="dropdownOptionsWrapper">
    <Catering onChange={setCateringSettings} settings={cateringSettings} />
  </div>
);

export default decorate(CateringSettings);
