import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";

import { Text2 } from "components/Base";

import resolveFormatter from "components/Global/Table3/CellFormatters/utils/resolveFormatter";
import resolveSectionEditor from "./resolveSectionEditor";
import ButtonGroup from "components/Global/Buttons/CancelSaveButtonGroup";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import Label from "ui-kit/FormLabel";

const READ_ONLY_FIELD_TYPES = ["signature"];

class SubmissionSummaryField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  @autobind
  onChange(id, value) {
    this.setState({
      value
    });
  }

  @autobind
  handleSubmit(e) {
    e.preventDefault();
    this.props.changeField(this.state.value);
    this.props.closeField();
  }

  preventEnter(e) {
    // TODO: Disabling savin on enter until we can normalize the save trigger on inputs
    if (e.which === 13) {
      e.preventDefault();
      return false;
    }
    return e;
  }

  render() {
    const {
      isEditing,
      values,
      field,
      metaData,
      editField,
      closeField,
      eventDetails,
      fields
    } = this.props;

    const {
      name,
      settings: { isAdminField },
      is_required: required,
      is_readonly: readonly
    } = field;

    const isReadOnly =
      this.props.readOnly || READ_ONLY_FIELD_TYPES.includes(field.type);

    if (isEditing) {
      const Section = resolveSectionEditor(field);
      if (Section) {
        const sectionProps = {
          eventDetails,
          eventId: eventDetails.id,
          formValues: values,
          field,
          isEditing: false,
          isResponseLocked: false,
          isApproving: true,
          isFillingFormOut: true,
          updateFormValue: this.onChange,
          type: field.type,
          fields
        };
        return (
          <div styleName="fieldEdit">
            <form
              onSubmit={this.handleSubmit}
              onKeyPress={this.preventEnter}
              className="form-page"
            >
              <div>
                <Section ref={c => (this.field = c)} {...sectionProps} />
              </div>
              <div styleName="editorActions">
                <ButtonGroup handleCancel={closeField} />
              </div>
            </form>
          </div>
        );
      }
    }
    const Formatter = resolveFormatter(field);

    // @NOTE: Adding this because we don't want to truncate text fields
    // in this view
    const otherProps = {
      truncate: field.type === "text" ? false : undefined,
      wrap: field.type === "dropdown" ? "true" : undefined
    };

    return (
      <div
        onClick={isReadOnly ? undefined : editField}
        styleName={isReadOnly ? "field" : "clickableField"}
      >
        <Label
          isAdminField={isAdminField}
          required={required}
          readonly={readonly}
        >
          <Text2>{name}</Text2>
        </Label>
        <div styleName="fieldValue">
          <Formatter
            value={this.props.value}
            dependentValues={metaData}
            {...otherProps}
          />
        </div>
      </div>
    );
  }
}

SubmissionSummaryField.propTypes = {
  editField: PropTypes.func.isRequired,
  closeField: PropTypes.func.isRequired,
  changeField: PropTypes.func.isRequired,
  eventDetails: PropTypes.object.isRequired,
  metaData: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  fields: PropTypes.array.isRequired
};

export default CSSModules(SubmissionSummaryField, css);
