import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "Organizations/EditEventModal";
import Loading from "./Loading";
import EventDetails from "Organizations/EventDetails/View";
import TicketsPasses from "Orders/TicketsPasses/View/Layout";
import WhoHasAccess from "Orders/TicketsPasses/View/WhoHasAccess";
import { PAGES } from "Organizations/EditEventModal/constants";
import { Div } from "components/Base";

const decorate = connect(
  state => ({
    selectedPage: getters.selectedPage(state),
    loading: getters.loading(state)
  }),
  {
    uploadLogoImg: () => actions.uploadLogoImg(),
    uploadHeaderImg: () => actions.uploadHeaderImg()
  }
);

const Content = ({ selectedPage, loading, uploadLogoImg, uploadHeaderImg }) => {
  if (loading) {
    return <Loading />;
  } else if (selectedPage === PAGES.EVENT_DETAILS) {
    return (
      <EventDetails
        uploadLogoImg={uploadLogoImg}
        uploadHeaderImg={uploadHeaderImg}
      />
    );
  } else if (selectedPage === PAGES.TICKETS_PASSES) {
    return <TicketsPasses />;
  } else if (selectedPage === PAGES.WHO_HAS_ACCESS) {
    return <WhoHasAccess />;
  }
  return null;
};

const ContentDecorate = decorate(Content);

const Body = () => (
  <Div width={1} bg="neutral0" height={487} style={{ overflow: "auto" }}>
    <ContentDecorate />
  </Div>
);

export default Body;
