import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import { Link } from "react-router";
import css from "./styles.scss";

export const AccountUserIcon = CSSModules(
  () => (
    <div styleName="memberIcon">
      <i className="material-icons">person</i>
    </div>
  ),
  css
);

const AccountUser = ({ user_fname, user_lname, email, ...props }) => (
  <div styleName="teamMember" {...props}>
    <AccountUserIcon />
    {user_fname && user_lname ? `${user_fname} ${user_lname}` : email}
  </div>
);

export default CSSModules(AccountUser, css);
