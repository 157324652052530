import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "./model";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import {
  ButtonGroup,
  ButtonOutline,
  Submit
} from "components/Global/Modal/Layout/Buttons";
import FormElements from "components/Global/Modal/Layout/FormElements";

import ColorPicker from "components/Global/ColorPicker";
import IconSelector from "components/Global/OptionSelector/AccountsOption/IconSelector";
import IconSelectorLabel from "components/Global/OptionSelector/AccountsOption/IconSelectorLabel";

const { Input, Label, InputGroup } = FormElements;

const decorate = R.compose(
  connect(
    state => ({
      name: getters.name(state),
      textColor: getters.textColor(state),
      backgroundColor: getters.backgroundColor(state),
      icon: getters.icon(state)
    }),
    {
      save: actions.save,
      hideModal: () => actions.setShowModal(false),
      handleColorChange: actions.handleColorChange,
      setIcon: actions.setIcon,
      setName: actions.setName
    }
  ),
  CSSModules(css)
);

const RecordTypeModal = ({
  save,
  name,
  textColor,
  backgroundColor,
  icon,
  hideModal,
  handleColorChange,
  setIcon,
  setName
}) => {
  return (
    <MiniModalWrapper
      showModal
      hideModal={hideModal}
      title="Add type"
      width={410}
      height={240}
    >
      <div styleName="wrapper">
        <InputGroup>
          <Label>Name</Label>
          <div styleName="container">
            <div styleName="inputWrapper">
              <Input
                className={css.inputWithActions}
                type="text"
                value={name}
                onChange={ev => setName(ev.target.value)}
              />
              <div styleName="colorIcon">
                <ColorPicker
                  selected={{
                    textColor,
                    backgroundColor
                  }}
                  onSelect={handleColorChange}
                />
              </div>
            </div>
            <div styleName="inputWrapper">
              <IconSelector selectIcon={setIcon}>
                <IconSelectorLabel icon={icon} />
              </IconSelector>
            </div>
          </div>
        </InputGroup>

        <ButtonGroup>
          <Submit title="Save" disabled={!name.length} onClick={save} />
          <ButtonOutline title="Cancel" onClick={hideModal} />
        </ButtonGroup>
      </div>
    </MiniModalWrapper>
  );
};

export default decorate(RecordTypeModal);
