import React from "react";
import { LoadingIcon, Div } from "components/Base";

const Loading = () => (
  <Div width={1} height={1} display="row.center.center">
    <LoadingIcon size={40} color="gray6" />
  </Div>
);

export default Loading;
