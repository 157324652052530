import api from "./api";

export function logAction(data) {
  return (dispatch, getState) =>
    api
      .post(getState().user.user.credentials, {
        ...data,
        userId: getState().user.user.credentials.userId
      })
      .catch(() => {
        // swallow error
        console.error("Error: Log action failed");
      });
}
