import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import autobind from "autobind-decorator";
import moment from "moment";

import Panel from "components/Global-2016/Panel";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";

class TemplatePanel extends Component {
  @autobind
  onMenuSelect(event, val) {
    event.stopPropagation();
    switch (val) {
      case "edit":
        this.props.editTemplate();
        break;
      case "delete":
        this.props.deleteTemplate();
        break;
      case "duplicate":
        this.props.duplicateTemplate();
        break;
      default:
    }
  }

  render() {
    const { template } = this.props;
    const notice =
      template.created_at === template.updated_at ? (
        <span>Created {moment(template.created_at).fromNow()}</span>
      ) : (
        <span>Updated {moment(template.updated_at).fromNow()}</span>
      );
    return (
      <Panel style={css.panel}>
        <div styleName="body" onClick={() => this.props.editTemplate()}>
          <div styleName="top">
            <span styleName="title">{template.title}</span>
            {/* <span styleName="summary">{template.sent_when}</span> */}
          </div>
          <div styleName="bottom">{notice}</div>
        </div>
        <div styleName="menu">
          <IconMenu
            onChange={this.onMenuSelect}
            iconButtonElement={
              <i className={[css.icon, "material-icons"].join(" ")}>
                more_vert
              </i>
            }
          >
            <MenuItem value="edit" primaryText="Open" />
            <MenuItem value="duplicate" primaryText="Duplicate" />
            <MenuItem value="delete" primaryText="Delete" />
          </IconMenu>
        </div>
      </Panel>
    );
  }
}

export default CSSModules(TemplatePanel, css);
