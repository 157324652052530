import { createSelector } from "reselect";
import { getFieldValue } from "ui-kit/Form/selectors";
import { FORM_INSTANCE_ID, FORM_RADIO_ID } from "./constants";

import * as R from "ramda";

import { getters } from "./model";

export const getFieldsValidated = createSelector(
  getters.fields,
  getters.invalidIds,
  (state, props) =>
    getFieldValue(state, {
      ...props,
      instanceId: FORM_INSTANCE_ID,
      fieldId: FORM_RADIO_ID
    }),
  getters.total,
  (fields, invalidIds, address, total) => {
    let drop = false;
    return R.compose(
      R.map(field => ({
        ...field,
        isValid: !R.any(invalidId => field.id === invalidId, invalidIds)
      })),
      R.reject(field => {
        if (address === "same" || total === 0) {
          if (field.type === "section" && field.name === "Billing fields") {
            drop = true;
            return true;
          }
          if (drop && field.type !== "section") {
            return true;
          } else if (drop && field.type === "section") {
            drop = false;
            return false;
          }
        }
        return false;
      })
    )(fields);
  }
);
