import * as R from "ramda";
import { createHandlers, createModule } from "redux-mvc";

const iniState = {
  selectedRows: [],
  rows: []
};

export const reducers = {
  toggleRow: ({ selectedRows }, { payload: id }) => ({
    selectedRows: R.contains(id, selectedRows)
      ? R.without([id], selectedRows)
      : R.concat([id], selectedRows)
  }),
  toggleSelectAll: ({ selectedRows }, { payload: rows }) =>
    R.equals(R.length(rows), R.length(selectedRows))
      ? { selectedRows: [] }
      : {
          selectedRows: R.map(R.prop("id"), rows)
        },
  clearRows: ({ selectedRows }, { payload: rows }) => ({
    selectedRows: R.without(rows, selectedRows)
  })
};

const handlers = createHandlers({
  iniState,
  reducers,
  namespace: "UISelectRows"
});

const { actions, getters } = handlers;

export { actions, getters };

const module = createModule(handlers);

export default module;
