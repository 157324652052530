import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as R from "ramda";
import { getters, actions } from "Event/Zones/model";
import { getZoneGroups } from "Event/Zones/selectors";
import {
  Div,
  BigFilledInput,
  BigOutlineButton,
  SearchIcon
} from "components/Base";
import CollapsableGroup from "./CollapsableGroup";
import AddItemPopover from "./AddItemPopover";

const decorate = connect(
  state => ({
    typesFilter: getters.typesFilter(state),
    zoneGroups: getZoneGroups(state)
  }),
  {
    setTypesFilter: actions.setTypesFilter
  }
);

const Layout = ({ typesFilter, setTypesFilter, zoneGroups, handlers }) => {
  return (
    <Div
      width={800}
      p={3}
      pl={6}
      style={{
        paddingBottom: 100
      }}
    >
      <Div flex={1} display="row.flex-start.center">
        <BigFilledInput
          width={1}
          color="neutral5"
          bg="neutral1"
          placeholder="Search Zones"
          LeftIcon={SearchIcon}
          value={typesFilter}
          onChange={value => {
            setTypesFilter(value);
          }}
          continuous
        />
        <BigOutlineButton
          onClick={() => handlers.addCategory({ isZone: true })}
          ml={2}
        >
          Add Category
        </BigOutlineButton>
        <AddItemPopover addItem={() => handlers.addUpdateZoneItem({})} />
      </Div>
      <Div>
        {R.map(
          group => (
            <CollapsableGroup
              group={group}
              handlers={handlers}
              groupsLength={R.length(zoneGroups)}
            />
          ),
          zoneGroups
        )}
      </Div>
    </Div>
  );
};

export default R.compose(
  withRouter,
  decorate
)(Layout);
