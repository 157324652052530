import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";

import { actions, getters } from "Items/AddQuestionModal";

import { registerError } from "redux/modules/errors/actions";

import { eventId as getEventId } from "redux/modules/event/selectors";
import { getCredentials } from "redux/modules/user/selectors";

import { ADD_QUESTION } from "Items/AddQuestionModal/constants";

import Api from "Items/AddQuestionModal/api";

const init = function*({ payload: { questionId, showQuestionSets } = {} }) {
  try {
    yield put(actions.setLoading(true));
    const credentials = yield select(getCredentials);
    const eventId = yield select(getEventId);

    const { payload } = yield call(Api.getQuestionSets, credentials, eventId);

    if (questionId) {
      const { payload: existingQuestion } = yield call(
        Api.getQuestion,
        credentials,
        questionId
      );
      yield put(actions.setInitialQuestion(existingQuestion));
    }

    yield put(actions.setInitialData({ sets: payload, showQuestionSets }));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading question sets"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const save = function*({ payload: { onDone, addAnother } }) {
  try {
    yield put(actions.setSaving(true));
    const credentials = yield select(getCredentials);
    const eventId = yield select(getEventId);

    const questionId = yield select(getters.questionId);
    const name = yield select(getters.questionName);
    const type = yield select(getters.questionType);
    const description = yield select(getters.questionDescription);
    const questionTypeSettings = yield select(getters.questionTypeSettings);
    const settings = questionTypeSettings[type];
    const selectedQuestionSetIds = yield select(getters.selectedQuestionSetIds);
    const questionSetName = yield select(getters.questionSetName);
    const questionSetMode = yield select(getters.addQuestion);
    const saveFn = questionId ? Api.updateQuestion : Api.addQuestion;
    const isInternal = yield select(getters.isInternal);

    const fnPayload = questionId
      ? {
          itemQuestion: {
            id: questionId,
            name,
            type,
            isInternal,
            settings: settings
              ? {
                  ...settings,
                  description
                }
              : { description },
            questionSetIds:
              questionSetMode === ADD_QUESTION.EXISTING_SET
                ? selectedQuestionSetIds
                : []
          }
        }
      : {
          eventId,
          name,
          type,
          isInternal,
          settings: settings
            ? {
                ...settings,
                description
              }
            : { description },
          questionSetIds:
            questionSetMode === ADD_QUESTION.EXISTING_SET
              ? selectedQuestionSetIds
              : [],
          questionSetName:
            questionSetMode === ADD_QUESTION.NEW_SET ? questionSetName : null
        };

    const { payload } = yield call(saveFn, credentials, fnPayload);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error adding question"
          }
        ])
      )
    ]);
  } finally {
    if (addAnother) {
      yield put(actions.init());
      yield put(actions.resetState());
    }
    yield put(actions.setSaving(false));
    yield call(onDone);
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchSave = function*() {
  yield takeEvery(actions.save.type, save);
};

const rootSaga = function*() {
  yield all([fork(watchInit), fork(watchSave)]);
};

export default rootSaga;
