import PropTypes from "prop-types";
import React, { Component } from "react";
import Toggle from "material-ui/Toggle";
import moment from "moment";
import DatePicker from "./Calendar";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import {
  ButtonOutline,
  ButtonOrange,
  ButtonGroup
} from "components/Global/Modal/Layout/Buttons";
import FormElements from "components/Global/Modal/Layout/FormElements";
import Label from "./Label";
import Wysiwyg from "components/Global/Wysiwyg";
import Body from "components/Global/Modal/Layout/ScrollableBody";

const { Input, InputGroup } = FormElements;

@CSSModules(css)
class FormEditModal extends Component {
  state = {
    name: this.props.form.name,
    closeDate: this.props.form.close_date
      ? moment(new Date(this.props.form.close_date))
      : null,
    responsesRequireApproval: !!this.props.form.responses_require_approval,
    showShareLink: !!this.props.form.show_share_link,
    lockOnSubmission: !!this.props.form.lock_on_submission,
    isLocked: !!this.props.form.is_locked,
    requireLogin: !!this.props.form.require_login,
    confirmationMessage: this.props.form.confirmation_message,
    error: null
  };

  handleCloseDateChange = date => {
    this.setState({ closeDate: date });
  };

  handleShareFormLinkChange = (e, val) => this.setState({ showShareLink: val });

  handleLockOnSubmissionChange = (e, val) =>
    this.setState({ lockOnSubmission: val });

  handleNameChange = event =>
    this.setState({ error: null, name: event.target.value });

  onRequireApprovalChange = () =>
    this.setState({
      error: null,
      responsesRequireApproval: !this.state.responsesRequireApproval
    });

  handleRequireLoginChange = (e, val) => this.setState({ requireLogin: val });

  handleAcceptingSubmissionsChange = (e, val) =>
    this.setState({ isLocked: !val });

  handleConfirmationMessageChange = confirmationMessage => {
    this.setState({ confirmationMessage });
  };

  onSave = () => {
    if (!this.state.name) {
      this.setState({
        error: "Please enter the form's name"
      });
    } else {
      const { error, ...rest } = this.state;
      const data = {
        eventId: this.props.eventId,
        formId: this.props.form.id,
        ...rest
      };
      this.props.handleEditForm(data);
      this.props.hideModal();
    }
  };

  handleBulkEdit = (isLocked = false) =>
    this.props.bulkEdit({ formId: this.props.form.id, isLocked });

  render() {
    const buttonGroup = (
      <ButtonGroup>
        <div styleName="button" onClick={() => this.handleBulkEdit(true)}>
          <i className="material-icons">lock</i>
          Lock all existing submissions
        </div>
        <div styleName="button" onClick={() => this.handleBulkEdit(false)}>
          <i className="material-icons">lock_open</i>
          Unlock all
        </div>
      </ButtonGroup>
    );

    return (
      <StyleWrapper
        bodyStyles={{ padding: 0 }}
        heading="Update form settings"
        hideModal={this.props.hideModal}
        width={479}
      >
        <Body style={{ height: 450 }}>
          <div styleName="headerSection">
            <InputGroup>
              <div styleName="label">Form Name:</div>
              <Input
                type="text"
                value={this.state.name}
                placeholder="ex: Asset Requests, Transportation Details"
                required="required"
                onChange={this.handleNameChange}
              />
              <div styleName="caption">
                This is the name you will use to internally manage the form, but
                it may be different from the public form name you create via the
                form builder.
              </div>
            </InputGroup>
          </div>

          {/*
            // @NOTE: Temporarily remove ability to toggle requiring approval -- making it required by default
            <div styleName="inputWrapper" style={styles.checkboxWrapper}>
            <input type='checkbox' checked={this.state.responsesRequireApproval} onClick={this.onRequireApprovalChange} id='checkbox' />
            <label style={[styles.checkboxLabel]} htmlFor='checkbox'>Responses require approval</label>
            </div>
          */}
          <div styleName="section">
            <div styleName="heading">Permissions & Lock Settings</div>
            <div styleName="inputGroup">
              <Label tooltip="On the due date, the accepting submissions toggle will be set to off automatically.">
                Due Date
              </Label>
              <DatePicker
                value={this.state.closeDate}
                onChange={this.handleCloseDateChange}
              />
            </div>
            <div styleName="inputGroup">
              <Label tooltip="Requires the user to set a password before filling out a form. This will also give the user access to their own portal.">
                Require login to fill out form
              </Label>
              <Toggle
                defaultToggled={this.state.requireLogin}
                onToggle={this.handleRequireLoginChange}
                style={{ width: "auto" }}
              />
            </div>
            <div styleName="inputGroup">
              <Label tooltip="">Accepting submissions</Label>
              <Toggle
                defaultToggled={!this.state.isLocked}
                onToggle={this.handleAcceptingSubmissionsChange}
                style={{ width: "auto" }}
              />
            </div>
            {!this.state.isLocked ? (
              <div styleName="wellEnabled">
                This form <span styleName="bold">&nbsp;is accepting&nbsp;</span>{" "}
                new submissions
                {buttonGroup}
              </div>
            ) : (
              <div styleName="wellDisabled">
                <div styleName="flex">
                  <i className="material-icons">add_circle</i>
                  This form{" "}
                  <span styleName="bold">&nbsp;is not accepting&nbsp;</span> new
                  submissions
                </div>
                {buttonGroup}
              </div>
            )}

            <div styleName="inputGroup">
              <Label tooltip="Will auto-lock submissions when submitted so that no changes can be made without an admin unlocking the submission first.">
                Lock submissions when submitted
              </Label>
              <Toggle
                defaultToggled={this.state.lockOnSubmission}
                onToggle={this.handleLockOnSubmissionChange}
                style={{ width: "auto" }}
              />
            </div>
          </div>
          <div styleName="section">
            <div styleName="heading">Share settings</div>

            <div styleName="inputGroup">
              <Label tooltip="Will allow recipients to copy & paste the form share link & give to someone else who needs to fill out the form.">
                Show shareable form link on each form submisssion
              </Label>
              <Toggle
                defaultToggled={this.state.showShareLink}
                onToggle={this.handleShareFormLinkChange}
                style={{ width: "auto" }}
              />
            </div>
          </div>

          <div styleName="section">
            <div styleName="heading">Confirmation message</div>
            <div style={{ backgroundColor: "white" }}>
              <Wysiwyg
                text={this.state.confirmationMessage}
                placeholder="The user will see this confirmation after submitting the form..."
                onChange={this.handleConfirmationMessageChange}
              />
            </div>
          </div>

          {this.state.error ? (
            <div className="error">{this.state.error}</div>
          ) : null}
        </Body>
        <div styleName="footer">
          <ButtonGroup>
            <ButtonOrange title="Save Changes" onClick={this.onSave} />
            <ButtonOutline title="Cancel" onClick={this.props.hideModal} />
          </ButtonGroup>
        </div>
      </StyleWrapper>
    );
  }
}

FormEditModal.propTypes = {
  form: PropTypes.object.isRequired,
  handleEditForm: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  hideModal: PropTypes.func.isRequired,
  bulkEdit: PropTypes.func.isRequired
};

export default FormEditModal;
