import * as R from "ramda";
import { createSelector } from "reselect";
import moment from "moment";

import { getters } from "EventLight/Expo/Sales/model";
import * as FormSelectors from "ui-kit/Form/selectors";
import {
  SETTINGS_FORM_ID,
  SETTINGS_FIELDS,
  DATETIME_FORMAT
} from "EventLight/Expo/Sales/constants";

export const getIsEventShowcasePublished = createSelector(
  state => getters.eventShowcase(state),
  R.prop("is_published")
);

export const getEventShowcaseUrl = createSelector(
  state => getters.eventShowcase(state),
  showcase =>
    `${window.__LENND_APP_URL__}/sales-portal/${showcase.slug}/${showcase.event.uuid}`
);

export const getEventId = createSelector(
  state => getters.eventShowcase(state),
  showcase => showcase.event.id
);

export const getShowAddEndDate = createSelector(
  getters.showAddEndDate,
  (state, props) =>
    FormSelectors.getFieldValue(state, {
      ...props,
      fieldId: SETTINGS_FIELDS.DATE,
      instanceId: SETTINGS_FORM_ID
    }),
  (state, props) =>
    FormSelectors.getFieldValue(state, {
      ...props,
      fieldId: SETTINGS_FIELDS.END_DATE,
      instanceId: SETTINGS_FORM_ID
    }),
  (showAddEndDate, date, endDate) => {
    if (!showAddEndDate) {
      return false;
    }
    const eDate = moment(endDate, DATETIME_FORMAT);
    const dDate = moment(date, DATETIME_FORMAT);
    return !moment.isMoment(eDate) || moment(dDate).isSame(eDate);
  }
);

export const getFloormapImageUrl = createSelector(
  state => getters.eventShowcase(state),
  R.prop("floormap_image_url")
);

export const getFiles = createSelector(
  state => getters.eventShowcase(state),
  R.propOr([], "files")
);

export const getLastIndexOfFile = createSelector(
  state => getters.eventShowcase(state),
  R.compose(
    R.length,
    R.propOr([], "files")
  )
);

export const getPhotos = createSelector(
  state => getters.eventShowcase(state),
  R.propOr([], "photos")
);

export const getLastIndexOfPhoto = createSelector(
  state => getters.eventShowcase(state),
  R.compose(
    R.length,
    R.propOr([], "photos")
  )
);
