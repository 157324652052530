import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  groupFields: [],
  peopleFields: [],
  showAddContentModal: false,
  showSelectPassesModal: false,
  types: [],
  loadingItemTypes: false,
  itemTypes: [],
  data: {},
  groupSearch: null,
  peopleSearch: null
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    setInitialData: (_, { payload: { groupFields, peopleFields } }) => ({
      groupFields,
      peopleFields
    }),
    showAddFieldModal: R.identity,
    showEditFieldModal: R.identity,
    showDeleteFieldModal: R.identity,
    showAddFieldGroupModal: R.identity,
    showEditFieldGroupModal: R.identity,
    showDeleteFieldGroupModal: R.identity
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
