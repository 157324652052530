import React, { Component } from "react";
import { connect } from "react-redux";

import { eventDetails } from "redux/modules/event/selectors";
import {
  getOrderFields,
  getRelatedModuleRecordId
} from "Orders/OrderModal/selectors";

import { getters } from "Orders/OrderModal";

import Fields from "./Fields";
import { Div, Text4 } from "components/Base";

const decorate = connect(state => ({
  details: eventDetails(state),
  orderFields: getOrderFields(state),
  recordId: getRelatedModuleRecordId(state),
  restricted: getters.isPortalUser(state)
}));

class OrderFields extends Component {
  getMetaData = (fields, record, rowData = {}, field) => {
    const { details, restricted } = this.props;

    const commonMeta = {
      readOnly: restricted,
      columnId: field.id,
      columns: fields,
      columnSettings: field.settings,
      eventDetails: details,
      eventId: details.id,
      isApprovingForm: true,
      rowId: rowData.id,
      via: "requests"
    };

    return {
      ...rowData,
      meta: commonMeta
    };
  };

  render() {
    const { restricted = false, orderFields, details, recordId } = this.props;

    // @NOTE: We don't show order fields for portal view (restricted)
    if (!orderFields.length || restricted) return null;

    return (
      <Div
        bg="white"
        bc="neutral3"
        bb={1}
        bl={1}
        br={1}
        brb={1}
        py={3}
        style={{ overflow: "visible" }}
      >
        <Text4 bold ml={8} my={3}>
          Order Details
        </Text4>
        <Fields
          {...{
            readOnly: restricted,
            showAdminFields: false,
            getMetaData: this.getMetaData,
            details,
            recordId,
            fields: orderFields
          }}
        />
      </Div>
    );
  }
}

export default decorate(OrderFields);
