import React from "react";
import { connect } from "react-redux";
import { createPortal } from "react-dom";
import can from "./";
import { isEmpty } from "ramda";

import { userPermissionProfile as permissionProfile } from "redux/modules/permissions/user-permission-profile/selectors";
import { withRouter } from "react-router";
import {
  userEvents,
  userOrgs
} from "redux/modules/user/access-levels/selectors";
import { Div } from "components/Base";

export default function(RouteComponent, moduleIdParam, action) {
  function mapStateToProps(state, props) {
    return {
      isUserEvent: userEvents(state).includes(
        parseInt(props.params.eventId, 10)
      ),
      isUserOrg: userOrgs(state).includes(props.params.orgId),
      userPermissionProfile: permissionProfile(
        state,
        props.params.eventId || props.params.orgId,
        state.user.user.id
      ),
      moduleId: moduleIdParam || props.params.moduleId
    };
  }

  class AuthenticatedRoute extends React.Component {
    canView = ({ userPermissionProfile }) => {
      const hasPermission = can(userPermissionProfile).view(
        this.props.moduleId,
        action
      );

      if (this.props.moduleId) {
        return hasPermission && this.hasAuth();
      }
      return this.hasAuth();
    };

    hasAuth = () => {
      return this.props.isUserEvent || this.props.isUserOrg;
    };

    render() {
      const { moduleId, userPermissionProfile, ...props } = this.props;

      // render component if still fetching access levels to prevent screen flash
      if (
        isEmpty(userPermissionProfile) ||
        this.canView({ userPermissionProfile })
      ) {
        return <RouteComponent {...props} />;
      }

      let message = "Something went wrong";

      // assuming an event, TODO update for orgs + events
      if (!this.hasAuth()) {
        message = "You don't have access to this page!";
      } else if (moduleId || action) {
        message = "You don't have permission to view this section!";
      }

      return createPortal(
        [
          <Div
            key="wrapper"
            display="column.center.center"
            height={1}
            width={1}
            bg="white"
            style={{ position: "absolute", zIndex: 2, top: 0, left: 0 }}
          >
            <Div fs={7} fw={3}>
              uh Oh!
            </Div>
            <Div fs={5} py={4}>
              {message}
            </Div>
          </Div>
        ],
        document.getElementById("root")
      );
    }
  }
  return connect(mapStateToProps)(withRouter(AuthenticatedRoute));
}
