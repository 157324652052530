import React from "react";
import { connect } from "react-redux";
import { actions } from "Forms/List/model";
import {
  Div,
  MediumFilledButton,
  FontIcon,
  OpenInNewIcon,
  SettingsIcon
} from "components/Base";
import { WIDTH } from "Forms/List/constants";
import { getPortalLink } from "Forms/List/selectors";
import { withProps } from "utils/General";

const CopyIconColor = withProps({
  color: "neutral5",
  fs: 3,
  children: "file_copy"
})(FontIcon);

const OpenIconColor = withProps({
  color: "neutral5"
})(OpenInNewIcon);

const SettingsIconColor = withProps({
  color: "neutral5"
})(SettingsIcon);

const decorate = connect(
  state => ({
    portalLink: getPortalLink(state)
  }),
  {
    goToPortal: actions.goToPortal,
    copyPortalLink: actions.copyPortalLink,
    goToPortalSettings: actions.goToPortalSettings
  }
);

const PortalBar = ({
  goToPortal,
  copyPortalLink,
  goToPortalSettings,
  portalLink
}) => (
  <Div
    width={1}
    display="row.center.flex-start"
    py={2}
    bg="neutral1"
    style={{
      boxShadow: "inset 0px 8px 8px -10px #CCC"
    }}
  >
    <Div
      width={1}
      style={{
        maxWidth: WIDTH
      }}
    >
      <Div display="row.flex-start.center">
        <Div
          color="black"
          fs={1}
          mr={2}
          width={113}
          style={{
            flexShrink: 0
          }}
        >
          Portal Login URL:
        </Div>
        <Div fs={1} color="gray6" truncate>
          {portalLink}
        </Div>
        <MediumFilledButton
          ml={2}
          bg="white"
          color="gray7"
          onClick={copyPortalLink}
          LeftIcon={CopyIconColor}
        >
          Copy Link
        </MediumFilledButton>
        <MediumFilledButton
          ml={2}
          bg="white"
          color="gray7"
          onClick={goToPortal}
          LeftIcon={OpenIconColor}
        >
          Open
        </MediumFilledButton>
        <MediumFilledButton
          ml={2}
          bg="neutral1"
          color="gray7"
          onClick={goToPortalSettings}
          LeftIcon={SettingsIconColor}
        >
          Settings
        </MediumFilledButton>
      </Div>
    </Div>
  </Div>
);

export default decorate(PortalBar);
