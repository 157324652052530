import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Sidebar from "./Sidebar";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { withRouter } from "react-router";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";
import getEnabledModules from "components/Event/utils/get-enabled-modules";
import * as R from "ramda";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { hideModal, showModal } from "redux/modules/modal/actions";
import {
  getViews,
  createView,
  updateView,
  deleteView,
  setActiveView
} from "redux/modules/modules/views/actions";
import { updateViewMeta } from "redux/modules/modules/views/meta/actions";
import {
  deselectAllRows,
  updateTableLayout
} from "redux/modules/modules/records/settings/actions";
import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";
import { getModulesWithCatalogItems } from "redux/modules/inventory/modules/actions";

import { recordTypes } from "redux/modules/modules/recordTypes/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import { views, activeView } from "redux/modules/modules/views/selectors";
import { user } from "redux/modules/user/selectors";
import { tableLayoutOptions } from "redux/modules/modules/records/settings/selectors";

import { userPermissionProfile } from "redux/modules/permissions/user-permission-profile/selectors";
import {
  records,
  fields,
  preferences
} from "redux/modules/modules/records/selectors";
import { canUserDo } from "redux/modules/permissions/user-permission-profile/selectors";
import { modules } from "redux/modules/inventory/modules/selectors";

const getContext = ({ params = {}, context = {} }) => ({
  ...params,
  ...context,
  moduleId: STANDARD_MODULE_IDS.contacts.id
});

function mapStateToProps(state, props) {
  const context = getContext({
    params: props.params
  });
  const userToPass = user(state);

  const enabledModules = props.params.eventId
    ? getEnabledModules(
        userPermissionProfile(state, props.params.eventId, userToPass.id),
        eventDetails(state).enabled_modules
      )
    : [];

  const enabledModuleIds = enabledModules.map(m => m.id);
  const catalogItemsModulesToPass = modules(state) || [];

  const catalogItemsModules = R.compose(
    R.sortBy(m => m.name.toLowerCase()),
    R.filter(m => enabledModuleIds.includes(m.id))
  )(catalogItemsModulesToPass);

  const catalogItemsModulesIds = catalogItemsModules.map(m => m.id);

  const otherModules = R.compose(
    R.sortBy(m => m.name.toLowerCase()),
    R.filter(
      m =>
        m.source === "custom" &&
        m.show_in_navigation &&
        !catalogItemsModulesIds.includes(m.id)
    )
  )(enabledModules);

  return {
    eventDetails: eventDetails(state),
    fields: fields(state, context.moduleId),
    views: views(state, context.moduleId),
    activeView: activeView(
      state,
      context.moduleId,
      context.viewId,
      context.groupId
    ),
    moduleDetails: {
      id: context.moduleId,
      record_name_plural: "People",
      record_name: "Person"
    },
    moduleSettings: tableLayoutOptions(state, context.moduleId),
    preferences: preferences(state, context.moduleId),
    records: records(state, context.moduleId),
    recordTypes: recordTypes(state, context.moduleId),
    user: userToPass,
    params: context,
    canViewPasses: enabledModules.some(
      m => m.id === STANDARD_MODULE_IDS.credentials.id
    ),
    canViewCatering: enabledModules.some(
      m => m.id === STANDARD_MODULE_IDS.catering.id
    ),
    canViewInventory: enabledModules.some(
      m => m.id === STANDARD_MODULE_IDS.inventory.id
    ),
    canManageContacts: canUserDo(state, props.params.eventId)(
      `${STANDARD_MODULE_IDS.contacts.id}_update`
    ),
    enabledModuleIds,
    catalogItemsModules,
    otherModules
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      deselectAllRows: () => deselectAllRows(props.params.moduleId),
      hideModal,
      showModal,
      showSnackbar,
      updateTableLayout,
      getViews,
      updateView,
      createView,
      deleteView,
      setActiveView,
      updateViewMeta,
      getRecordTypes,
      getModulesWithCatalogItems
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Sidebar)
);
