import React from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { actions } from "EventLight/Common/Manage";
import { getAttendeeLabel } from "EventLight/Common/Manage/selectors";

import { Div, MediumOutlineButton } from "components/Base";

import { Paper, Summary, ContactTypes } from "./Common";
import createPassesSimpleTable, { COLUMNS } from "ui-kit/PassesSimpleTable";

import ArrayLayout from "ui-kit/ArrayLayout";
import BulkActionsButton from "./BulkActionsButton";

const columns = [
  COLUMNS.passType,
  COLUMNS.requested,
  COLUMNS.rejected,
  COLUMNS.approved,
  COLUMNS.price,
  COLUMNS.issued,
  COLUMNS.received
];

const Table = createPassesSimpleTable({ columns });

const decorate = connect(
  (state, props) => ({
    attendeeLabel: getAttendeeLabel(state, props)
  }),
  bindInstance({
    viewProfile: actions.viewAccountProfile,
    redirectToAttendeeBreakDown: actions.redirectToAttendeeBreakDown
  })
);

const ByAccount = ({
  account = {},
  passes,
  allCredentialLineItemIds,
  allMealLineItemIds,
  allLineItemIds,
  mode,
  viewProfile,
  expandUrl = "",
  handlers,
  attendeeLabel,
  instanceId = "",
  redirectToAttendeeBreakDown
}) => {
  const buttons = [
    [
      <MediumOutlineButton
        onClick={() => redirectToAttendeeBreakDown({ url: expandUrl })}
      >
        {mode === "issuance"
          ? `Check-In ${attendeeLabel === "Person" ? "People" : "Attendees"}`
          : `View ${attendeeLabel} Breakdown`}
      </MediumOutlineButton>,
      <BulkActionsButton
        cardType="byAccount"
        mode={mode}
        meta={{
          allLineItemIds,
          allCredentialLineItemIds,
          allMealLineItemIds
        }}
        handlers={{
          ...handlers,
          viewProfile: () => viewProfile(account.id),
          sendEmail: () =>
            handlers.sendEmail({
              type: "account",
              recordId: account.id
            })
        }}
      />
    ]
  ];

  return (
    <Paper display="row.flex-start.stretch" py={0}>
      <Summary
        title={account.name}
        caption={account.type}
        statuses={account.statuses}
        body={<ContactTypes contacts={account.contacts} my={4} />}
        bg="neutral0"
        w={60}
        p={3}
        brl={2}
      />
      <Div display="column.space-between.stretch" flex={1} p={2}>
        <Table rowsData={passes} instanceId={instanceId} />
        <ArrayLayout components={buttons} />
      </Div>
    </Paper>
  );
};

ByAccount.defaultProps = {
  handlers: {},
  mode: "issuance"
};

export default decorate(ByAccount);
