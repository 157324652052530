import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import Loading from "ui-kit/Loading";
import FileIcon from "ui-kit/FileIcon";
import {
  Div,
  MediumOutlineButton,
  CloseIcon,
  Text1,
  Text5,
  SendIcon,
  AttachIcon,
  VisibleIcon,
  MediumTextButton
} from "components/Base";

import { getters, actions } from "../model";

import { isFileImage, getMetaData, getFileAttachmentData } from "../selectors";

const decorate = connect(
  (state, props) => ({
    loading: getters.loading(state, props),
    fileId: getters.fileId(state, props),
    isImage: isFileImage(state, props),
    fileUrl: getters.url(state, props),
    fileMimeType: getters.mimetype(state, props),
    fileName: getters.name(state, props),
    fileMetadata: getMetaData(state, props),
    attachmentData: getFileAttachmentData(state, props)
  }),
  {
    handleClose: actions.closeSidebar,
    openInBrowser: actions.onOpen,
    download: actions.onDownload,
    sendViaEmail: actions.sendViaEmail,
    attachToRecords: actions.attachToRecords,
    showOnPortal: actions.showOnPortal
  }
);

const Body = ({
  loading,
  isImage,
  fileId,
  fileUrl,
  fileMimeType = "image/png",
  fileName = "",
  fileMetadata,
  handleClose,
  openInBrowser,
  download,
  sendViaEmail,
  attachToRecords,
  showOnPortal,
  attachmentData
}) => (
  <Div
    height={1}
    style={{
      position: "relative"
    }}
  >
    <Div
      width={1}
      display="row.space-between.flex-start"
      px={4}
      py={4}
      flexShrink={0}
    >
      <Div display="row.center.center" width={1}>
        {!loading && isImage ? (
          <Div
            width={1}
            height={160}
            bra={1}
            style={{
              position: "relative",
              flexShrink: 0,
              backgroundImage: `url('${fileUrl}')`,
              backgroundSize: "contain",
              backgroundPosition: "top center",
              backgroundRepeat: "no-repeat"
            }}
          />
        ) : (
          <FileIcon bra={1} size={100} mimetype={fileMimeType} />
        )}
      </Div>
      <Div display="row.flex-end.flex-start">
        <CloseIcon
          color="black"
          size={30}
          ml={4}
          onClick={() => handleClose()}
        />
      </Div>
    </Div>
    {loading && <Loading />}

    {!loading && (
      <Div px={4} display="column.flex-start.flex-start" width={1}>
        <Text5 p={2} bra={1} bg="neutral1" bold width={1}>
          {fileName}
        </Text5>
        <Div my={2} display="row.flex-start.flex-start" px={2}>
          <MediumOutlineButton onClick={openInBrowser}>
            Open
          </MediumOutlineButton>
          <MediumOutlineButton ml={2} onClick={download}>
            Download
          </MediumOutlineButton>
        </Div>
        <Div
          display="row.flex-start.flex-start"
          px={2}
          my={2}
          width={1}
          bb={1}
          bc="neutral3"
        >
          {R.addIndex(R.map)(
            (column, idx) => (
              <Div
                key={idx}
                mb={4}
                pl={idx % 2 === 0 ? 0 : 4}
                display="column.flex-start.flex-start"
                width={0.5}
              >
                {R.map(
                  row => (
                    <Div
                      key={row.label}
                      width={1}
                      pt={2}
                      display="column.flex-start.flex-start"
                    >
                      <Text1 uppercase width={1}>
                        {row.label}
                      </Text1>
                      <Text1 width={1}>{row.value}</Text1>
                    </Div>
                  ),
                  column
                )}
              </Div>
            ),
            fileMetadata
          )}
        </Div>
        <Div my={2} display="column.flex-start.flex-start">
          <MediumTextButton
            onClick={() => sendViaEmail(attachmentData)}
            m={1}
            bold
            isPadded={false}
            color="neutral8"
            LeftIcon={SendIcon}
          >
            Send Via Email To...
          </MediumTextButton>
          <MediumTextButton
            onClick={() => attachToRecords(fileId)}
            m={1}
            bold
            isPadded={false}
            color="neutral8"
            LeftIcon={AttachIcon}
            disabled
          >
            Attach to Records...
          </MediumTextButton>
          <MediumTextButton
            onClick={() => showOnPortal(fileId)}
            m={1}
            bold
            isPadded={false}
            color="neutral8"
            LeftIcon={VisibleIcon}
            disabled
          >
            Show on Portals...
          </MediumTextButton>
        </Div>
      </Div>
    )}
  </Div>
);

export default decorate(Body);
