import { RECEIVE, RECEIVE_BLOCK, REQUEST } from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getPeopleBlocks(formId) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: REQUEST
      });
      const result = await api.get(getState().user.user.credentials, formId);
      dispatch({
        type: RECEIVE,
        payload: result.payload
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching people blocks"
          }
        ])
      );
    }
  };
}

export function getPeopleBlock(blockId) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: REQUEST
      });
      const result = await api.getById(
        getState().user.user.credentials,
        blockId
      );
      dispatch({
        type: RECEIVE_BLOCK,
        payload: result.payload
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching people block"
          }
        ])
      );
    }
  };
}

export function createPeopleBlock(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post(getState().user.user.credentials, data);
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding people block"
          }
        ])
      );
    }
  };
}

export function updatePeopleBlock(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.put(
        getState().user.user.credentials,
        data.blockId,
        data
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating people block"
          }
        ])
      );
    }
  };
}
export function updateItemBlocks({ itemBlocks, blockId }) {
  return async (dispatch, getState) => {
    try {
      const result = await api.updateItemBlocks(
        getState().user.user.credentials,
        { itemBlocks, blockId }
      );
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating item blocks"
          }
        ])
      );
    }
  };
}

export function addItemBlock({ order, itemBlockId, blockId }) {
  return async (dispatch, getState) => {
    try {
      const result = await api.addItemBlock(getState().user.user.credentials, {
        order,
        itemBlockId,
        blockId
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding item block to people block"
          }
        ])
      );
    }
  };
}

export function deleteItemBlock({ itemBlockId, blockId }) {
  return async (dispatch, getState) => {
    try {
      const result = await api.deleteItemBlock(
        getState().user.user.credentials,
        { itemBlockId, blockId }
      );
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred removing item block"
          }
        ])
      );
    }
  };
}
export function addField(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.addField(getState().user.user.credentials, data);
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding field to people block"
          }
        ])
      );
    }
  };
}

export function updateFields({ fields, blockId }) {
  return async (dispatch, getState) => {
    try {
      const result = await api.updateFields(getState().user.user.credentials, {
        fields,
        blockId
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding field to people block"
          }
        ])
      );
    }
  };
}

export function deleteField({ fieldId, blockId }) {
  return async (dispatch, getState) => {
    try {
      const result = await api.deleteField(getState().user.user.credentials, {
        fieldId,
        blockId
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred removing field from people block"
          }
        ])
      );
    }
  };
}

// export function bulkUpdateItemBlocks(data) {
//   return async (dispatch, getState) => {
//     try {
//       const result = await api.bulkUpdateItemBlocks(
//         getState().user.user.credentials,
//         data
//       );
//       return result;
//     } catch (error) {
//       dispatch(
//         registerError([
//           {
//             system: error,
//             user: "An error occurred bulk updating item blocks"
//           }
//         ])
//       );
//     }
//   };
// }

// export function deleteItemBlock(data) {
//   return async (dispatch, getState) => {
//     try {
//       const result = await api.deleteItemBlock(
//         getState().user.user.credentials,
//         data
//       );
//       return result;
//     } catch (error) {
//       dispatch(
//         registerError([
//           {
//             system: error,
//             user: "An error occurred deleting item block"
//           }
//         ])
//       );
//     }
//   };
// }
