import React, { Component } from "react";
import { find, get, isEqual } from "lodash";
import getValue from "utils/value-types/get-value/multiple_select";
import { Div } from "components/Base";

class MultipleSelectCellFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  formatValue(props) {
    return getValue(props.value);
  }

  render() {
    const options = get(
      this.props.dependentValues,
      "meta.columnSettings.options",
      []
    );

    const value = this.formatValue(this.props);
    const records = get(value, ["records"], [])
      .filter(v => v)
      .sort((a, b) =>
        a.value.toLowerCase().localeCompare(b.value.toLowerCase())
      );

    return (
      <Div
        display="row.flex-start.center"
        style={{
          userSelect: "none",
          overflow: "hidden",
          flexWrap: this.props.wrap ? "wrap" : undefined
        }}
      >
        {records.map((record, index) => {
          if (record.value) {
            const selectedOption = find(options, { value: record.value });
            return (
              <Div
                key={index}
                display="row.flex-start.center"
                mr={2}
                style={{
                  borderRadius: 25,
                  fontSize: 12,
                  padding: "4px 8px",
                  lineHeight: "normal",
                  whiteSpace: "nowrap",
                  flexBasis: "auto",
                  flexShrink: 0,
                  backgroundColor:
                    get(selectedOption, "backgroundColor") || "#EBEBEB",
                  color: get(selectedOption, "textColor") || "#494949"
                }}
              >
                {record.value}
              </Div>
            );
          }
        })}
      </Div>
    );
  }
}

export default MultipleSelectCellFormatter;
