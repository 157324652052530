import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import AddNewPerson from "./AddNewPerson";
import ContactName from "../ContactName";

const SearchContacts = CSSModules(
  ({
    addContact,
    selectContact,
    results,
    getContacts,
    searching,
    searchTerm
  }) => (
    <div>
      <div styleName="inputWrapper">
        <input
          autoFocus
          onChange={getContacts}
          placeholder="Search by email address or full name"
          styleName="input"
          type="text"
        />
        {searching ? (
          <i className="material-icons spin">refresh</i>
        ) : (
          <i className="material-icons">search</i>
        )}
      </div>
      {results.length ? (
        <div>
          <div styleName="label">Results:</div>
          <div styleName="listWrapper">
            <div styleName="list">
              {results.map(result => (
                <div styleName="result">
                  <ContactName {...result} />
                  {result.is_selected ? (
                    <div styleName="resultButton">
                      <i className="material-icons">check</i>
                    </div>
                  ) : (
                    <div
                      styleName="select"
                      onClick={() => selectContact(result)}
                    >
                      select
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
          <AddNewPerson addContact={addContact} />
        </div>
      ) : searchTerm ? (
        <AddNewPerson
          addContact={addContact}
          message={
            <div>
              There are no results for{" "}
              <span styleName="bold">{searchTerm}</span>.
            </div>
          }
        />
      ) : (
        <AddNewPerson addContact={addContact} />
      )}
    </div>
  ),
  css
);

export default SearchContacts;
