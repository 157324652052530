import React from "react";
import { connect } from "react-redux";

import { actions } from "Orders/ImportModal";

import {
  getFieldsBySection,
  getEnabledFieldsBySection
} from "Orders/ImportModal/selectors";
import { VARIANTS_GROUP_ID } from "Orders/ImportModal/constants";

import MiniModalWrapper from "Orders/Common/View/MiniModalWrapper";

import { SelectAndCreateVariants } from "ui-kit/MiniItemsSelector/View";

import { noop } from "utils/General";

const decorate = connect(
  state => ({
    fields: getFieldsBySection(state, { sectionId: VARIANTS_GROUP_ID }),
    iniSelectedFields: getEnabledFieldsBySection(state, {
      sectionId: VARIANTS_GROUP_ID
    })
  }),
  {
    hideModal: () => actions.closeSelectItemsModal(),
    onDone: actions.confirmSelectItemsModal,
    onClose: () => actions.closeSelectItemsModal()
  }
);

const SelectItemsModal = ({
  fields = [],
  iniSelectedFields = [],
  onClose = noop,
  onDone = noop
}) => (
  <MiniModalWrapper
    showModal
    title="Choose items available to import"
    hideModal={() => onClose()}
    width={865}
    style={{
      height: "95%"
    }}
  >
    {React.createElement(SelectAndCreateVariants, {
      instanceId: "importOrdersItemSelector",
      fields: fields,
      iniSelected: iniSelectedFields,
      iniClearSearch: true,
      onCancel: onClose,
      onDone: onDone
    })}
  </MiniModalWrapper>
);

export default decorate(SelectItemsModal);
