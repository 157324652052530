import React from "react";
import { connect } from "react-redux";

import ModalWrapper from "ui-kit/MiniModalWrapper";
import Body from "./Body";

import { actions, getters } from "../model";

const decorate = connect(
  (state, props) => ({
    isModalVisible: getters.isModalVisible(state, props)
  }),
  {
    hideModal: actions.hideModal
  }
);

const Layout = ({
  isModalVisible,
  title = "Enable Notifications",
  hideModal
}) => (
  <ModalWrapper
    isPortal
    showModal={isModalVisible}
    title={title}
    hideModal={hideModal}
    bg="white"
    height={250}
    showHeader={false}
    style={{
      maxWidth: "95%"
    }}
  >
    {(isModalVisible && <Body />) || null}
  </ModalWrapper>
);

export default decorate(Layout);
