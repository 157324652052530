import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  form,
  fields,
  sortedFormFields
} from "redux/modules/formsV2/form/selectors";
import {
  addField as addFormField,
  deleteField as deleteFormField,
  updateFieldOrder
} from "redux/modules/formsV2/form/fields/actions";
import { toggleFields } from "redux/modules/formsV2/form/fields/customerBlockFields/actions";
import { eventId } from "redux/modules/event/selectors";

function mapStateToProps(state) {
  return {
    eventId: eventId(state),
    userId: state.user.user.id,
    form: form(state),
    formFields: fields(state),
    sortedFormFields: sortedFormFields(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { addFormField, deleteFormField, toggleFields, updateFieldOrder },
    dispatch
  );
}

function CreateContactController(Child) {
  return class Controller extends Component {
    render() {
      const { ...props } = this.props;
      return <Child {...props} />;
    }
  };
}

export default Child =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateContactController(Child));
