import React from "react";

import { connect } from "react-redux";

import { getters, actions } from "Orders/TicketsPasses";

import * as R from "ramda";

import {
  isDisabledAssignItemsBtn,
  getFilteredAssignItems
} from "Orders/TicketsPasses/selectors";

import PriceSelectPopOver from "Orders/TicketsPasses/View/ProfilePricePopOver";

import {
  Div,
  DownIcon,
  PopMenu,
  PopMenuCard,
  Text1,
  MediumFilledInput,
  SearchIcon,
  Text2,
  SmallCheckbox,
  BigFilledButton,
  BigOutlineButton,
  NumberSelector
} from "components/Base";

const decorate = connect(
  state => ({
    assignItemsCredentials: getFilteredAssignItems(state),
    ticketsPassesData: getters.data(state),
    saveBtnDisabled: isDisabledAssignItemsBtn(state),
    itemsFilter: getters.itemsFilter
  }),
  {
    resetAssignItems: actions.resetAssignItems,
    toggleAssignedItem: actions.toggleAssignedItem,
    updateItemLimit: actions.updateItemLimit,
    enableAllItems: () => actions.enableAllItems(),
    disableAllItems: () => actions.disableAllItems(),
    updateLimitPricesProfiles: actions.updateItemLimitPricesProfiles,
    setItemsFilter: actions.setItemsFilter
  }
);

const AssignItemsPopOver = ({
  resetAssignItems,
  assignItemsCredentials,
  ticketsPassesData,
  toggleAssignedItem,
  updateItemLimit,
  enableAllItems,
  disableAllItems,
  saveBtnDisabled,
  updateLimitPricesProfiles,
  profileId,
  itemsFilter,
  setItemsFilter
}) => (
  <PopMenu
    Label={({ onClick }) => (
      <Div
        bra={1}
        p={2}
        bc={{ default: "neutral1", hover: "primary7" }}
        ba={1}
        height={32}
        width={300}
        style={{ cursor: "pointer" }}
        display="row.flex-start.center"
        onClick={() => {
          resetAssignItems({ assignItemsCredentials: ticketsPassesData });
          onClick();
        }}
      >
        <Div flex={1} display="row.center.center">
          <Text1 bold color="primary7">
            Assign Items
          </Text1>
        </Div>
        <DownIcon color="black" width={20} />
      </Div>
    )}
  >
    {({ closeMenu }) => (
      <PopMenuCard position="bottom" align="right">
        <Div
          width={380}
          bg="white"
          bra={1}
          shadow={1}
          style={{ overflow: "visible" }}
        >
          <Div p={1}>
            <MediumFilledInput
              bg="neutral0"
              LeftIcon={SearchIcon}
              width={1}
              placeholder="Search..."
              color="neutral7"
              continuous
              value={itemsFilter}
              onChange={value => setItemsFilter(value)}
            />
          </Div>
          <Div bg="neutral0" p={3}>
            <Div display="row.space-between.center" mb={1}>
              <Text2 bold color="neutral5">
                Working Credentials
              </Text2>
              <Div display="row.flex-start.center">
                <Text2 bold color="neutral5" mr={3}>
                  Select:
                </Text2>
                <Div
                  fw={3}
                  fs={2}
                  color="primary7"
                  underline
                  mr={2}
                  onClick={enableAllItems}
                >
                  All
                </Div>
                <Div
                  fw={3}
                  fs={2}
                  color="primary7"
                  underline
                  onClick={disableAllItems}
                >
                  None
                </Div>
              </Div>
            </Div>
            <Div width={1}>
              {R.map(
                ({
                  sort_order: id,
                  name,
                  selected,
                  limit,
                  prices: pricesCredentials
                }) => (
                  <Div
                    key={id}
                    mb={1}
                    shadow={1}
                    bra={1}
                    bg="white"
                    p={2}
                    display="row.space-between.center"
                    width={1}
                    style={{ overflow: "visible" }}
                  >
                    <Div display="row.flex-start.center">
                      <SmallCheckbox
                        selected={selected}
                        onClick={() => {
                          toggleAssignedItem({ id });
                        }}
                      />
                      <Text2 black bold ml={2}>
                        {name}
                      </Text2>
                    </Div>
                    <Div display="row.flex-start.center">
                      <PriceSelectPopOver prices={pricesCredentials} id={id} />
                      <NumberSelector
                        value={limit}
                        minValue={0}
                        onChangeValue={limit => {
                          updateItemLimit({ id, limit });
                        }}
                        WrapperProps={{
                          height: 30
                        }}
                      />
                    </Div>
                  </Div>
                ),
                assignItemsCredentials
              )}
            </Div>
          </Div>
          <Div display="row" width={1} mt={2} p={2}>
            <BigFilledButton
              bg="altB5"
              mr={2}
              flex={1}
              disabled={saveBtnDisabled}
              onClick={() => {
                updateLimitPricesProfiles({
                  profileId,
                  assignItemsCredentials
                });
                closeMenu();
              }}
            >
              Save
            </BigFilledButton>
            <BigOutlineButton onClick={closeMenu}>Cancel</BigOutlineButton>
          </Div>
        </Div>
      </PopMenuCard>
    )}
  </PopMenu>
);

export default decorate(AssignItemsPopOver);
