import { createHandlers } from "redux-mvc";

import * as R from "ramda";

const iniState = {
  loading: true,
  collapsed: false,
  navigation: {
    is_org_user: false,
    links: []
  },
  openLinks: []
};

const model = createHandlers({
  iniState,
  namespace: "EventLightLayout",
  reducers: {
    cancelInstance: R.always(iniState),
    init: R.identity,
    publishEvent: R.identity,
    refreshNavigation: R.identity,
    goBack: R.identity,
    goToPreview: R.identity,
    showLogoFilepicker: R.identity,
    toggleCollapse: ({ collapsed }) => ({ collapsed: !collapsed }),
    toggleOpenLink: ({ openLinks }, { payload: linkId }) => ({
      openLinks: openLinks.includes(linkId)
        ? openLinks.filter(id => id !== linkId)
        : [...openLinks, linkId]
    })
  }
});
const { actions, getters } = model;

export { actions, getters };

export default model;
