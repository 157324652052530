import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const BlockAvatar = ({ fullName, email }) => (
  <div styleName="badge">{`${fullName}${email}`.replace(" ", "")[0]}</div>
);

BlockAvatar.propTypes = {
  fullName: PropTypes.string,
  email: PropTypes.string
};

export default CSSModules(BlockAvatar, css);
