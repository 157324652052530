import React from "react";

import { createContext } from "redux-mvc";
import { connect } from "react-redux";

import * as R from "ramda";

import module, { actions } from "../index";
import { showModal, hideModal } from "redux/modules/modal/actions";

import Layout from "./Layout";

import EditQuestionSetQuestionsModal from "Items/EditQuestionSetQuestionsModal/View";
import AssignQuestionsItemsModal from "Items/AssignQuestionsItemsModal/View";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";

import rootSaga from "../sagas";

module.setRootSaga(rootSaga);

const decorate = R.compose(
  connect(
    null,
    {
      hideModal,
      showModal
    }
  ),
  createContext({
    module: module,
    lifeCycle: {
      componentDidMount() {
        this.store.dispatch(
          actions.getInitialData({ variantIds: this.props.variantIds || [] })
        );
      }
    },
    handlers: {
      showEditQuestionSetQuestionsModal(setId) {
        this.props.showModal({
          content: (
            <EditQuestionSetQuestionsModal
              setId={setId}
              onDone={() => this.props.fetchItem()}
            />
          ),
          wrapper: ModalWrapper
        });
      },
      showAssignQuestionsModal() {
        this.props.showModal({
          content: (
            <AssignQuestionsItemsModal
              variantIds={this.props.variantIds}
              onDone={() => this.props.fetchItem()}
            />
          ),
          wrapper: ModalWrapper
        });
      }
    },
    options: {
      dispatchToGlobal: R.compose(
        R.not,
        R.test(new RegExp(R.join("|", module.modules))),
        R.prop("type")
      ),
      observedDomains: ["user", "event", "modal", "organization"]
    }
  })
);

export default decorate(Layout);
