import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import {
  getSelecteEvents,
  getEmptyEventsText
} from "Organizations/Home/selectors";
import { actions } from "Organizations/Home/model";
import { Div, Text3, RightArrowIcon } from "components/Base";
import Event from "./Event";

const decorate = connect(
  state => ({
    selectedEvents: getSelecteEvents(state),
    emptyEventsText: getEmptyEventsText(state)
  }),
  {
    viewEvent: actions.viewEvent,
    deleteEvent: actions.deleteEvent,
    cloneEvent: actions.cloneEvent,
    editEvent: actions.editEvent,
    goToAllEvents: actions.goToAllEvents
  }
);

const UpcomingBody = ({
  selectedEvents,
  deleteEvent,
  cloneEvent,
  editEvent,
  viewEvent,
  emptyEventsText,
  goToAllEvents
}) => (
  <Div mb={2}>
    {R.isEmpty(selectedEvents) ? (
      <Div p={4} color="black" fw={2} width={1}>
        {emptyEventsText}
      </Div>
    ) : (
      <Div p={2}>
        {R.map(
          event => (
            <Event
              key={event.id}
              {...event}
              viewEvent={viewEvent}
              deleteEvent={deleteEvent}
              cloneEvent={cloneEvent}
              editEvent={editEvent}
            />
          ),
          selectedEvents
        )}
      </Div>
    )}
    <Div
      px={4}
      display="row.flex-start.center"
      height={50}
      bt={1}
      bc="neutral2"
      onClick={goToAllEvents}
    >
      <Text3 color="primary8" bold mr={2}>
        Manage all events
      </Text3>
      <RightArrowIcon color="neutral7" />
    </Div>
  </Div>
);

export default decorate(UpcomingBody);
