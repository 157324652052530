import { combineReducers } from "redux";
import _ from "lodash";

// ------------------------------------
// Constants
// ------------------------------------
const INVALIDATE_SIDEBAR = "INVALIDATE_SIDEBAR";
const REQUEST_SIDEBAR = "REQUEST_SIDEBAR";
const RECEIVE_SIDEBAR = "RECEIVE_SIDEBAR";

// ------------------------------------
// Actions
// ------------------------------------
export const invalidateSidebar = (view, context) => ({
  type: INVALIDATE_SIDEBAR,
  view,
  context
});

export const receiveSidebar = (view, context) => dispatch => {
  dispatch({
    type: RECEIVE_SIDEBAR,
    view,
    context,
    receivedAt: Date.now()
  });
};

export const actions = {
  receiveSidebar,
  invalidateSidebar
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isFetching: false,
  didInvalidate: false,
  lastUpdated: null,
  view: null,
  context: null
};

const sidebar = (state = initialState, action) => {
  switch (action.type) {
    case INVALIDATE_SIDEBAR:
      return _.extend({}, state, {
        didInvalidate: true
      });

    case REQUEST_SIDEBAR:
      return _.extend({}, state, {
        isFetching: true,
        didInvalidate: false
      });

    case RECEIVE_SIDEBAR:
      return _.extend({}, state, {
        isFetching: false,
        didInvalidate: false,
        view: action.view,
        context: action.context,
        lastUpdated: action.receivedAt
      });

    default:
      return state;
  }
};

const currentView = (state = null, action) => {
  switch (action.type) {
    case INVALIDATE_SIDEBAR:
      return null;
    case RECEIVE_SIDEBAR:
    case REQUEST_SIDEBAR:
      return action.view;
    default:
      return state;
  }
};

const sidebarByView = (state = { eventContact: {} }, action) => {
  switch (action.type) {
    case INVALIDATE_SIDEBAR:
    case RECEIVE_SIDEBAR:
    case REQUEST_SIDEBAR:
      return _.extend({}, state, {
        [action.view]: sidebar(state[action.view], action)
      });
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  currentView,
  sidebarByView
});

export default rootReducer;
