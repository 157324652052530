import * as R from "ramda";
import { createSelector } from "reselect";

import { eventDetails as getEventDetails } from "redux/modules/portal/selectors";
import {
  portalUser as getPortalUser,
  accounts
} from "redux/modules/portal/user/selectors";

export const getLogoImgUrl = R.compose(
  R.prop("logo_img_url"),
  getEventDetails
);

export const getEventInitial = R.compose(
  eventDetails => (eventDetails.name ? eventDetails.name[0] : ""),
  getEventDetails
);

export const getPortalAccount = createSelector(
  getPortalUser,
  accounts,
  (user, accounts) => R.find(R.propEq("id", user?.active_view?.id), accounts)
);

export const getIsLoading = createSelector(
  getPortalUser,
  getEventDetails,
  (portalUser, eventDetails) => R.isEmpty(portalUser) || R.isEmpty(eventDetails)
);

const getBasePath = createSelector(
  getEventDetails,
  eventDetails => {
    return `/hubs/${eventDetails.slug}/${eventDetails.uuid}`;
  }
);

const mapBreadcrumbs = (params, basePath) =>
  R.map(
    R.propOr(
      {},
      R.__, // eslint-disable-line no-underscore-dangle
      R.indexBy(R.prop("id"), [
        {
          link: `${basePath}/`,
          label: "Home",
          id: "HubPortalHome"
        },
        {
          link: `${basePath}/pages/${R.prop("pageId", params)}`,
          label: "Page",
          id: "HubPortalPage"
        },
        {
          link: `${basePath}/alerts/${R.prop("alertId", params)}`,
          label: "Alert",
          id: "HubPortalAlert"
        },
        {
          link: `${basePath}/people/${R.prop("recordTypeId", params)}`,
          label: "Personnel Requests",
          id: "HubPortal_PeopleByPeopleType"
        },
        {
          link: `${basePath}/people/${R.prop("recordTypeId", params)}/requests`,
          label: "Add Request",
          id: "HubPortal_RequestOrderSelectItems"
        },
        {
          link: `${basePath}/people/${R.prop("recordTypeId", params)}/reports`,
          label: "Reports",
          id: "HubPortal_PortalReports"
        }
      ])
    )
  );

const allHubRouteNames = [
  "HubPortalHome",
  "HubPortalAlert",
  "HubPortalPage",
  "HubPortal_PeopleByPeopleType",
  "HubPortal_RequestOrderSelectItems",
  "HubPortal_PortalReports"
];

const getActiveRoute = createSelector(
  (_, props) => props.routes,
  R.compose(
    R.take(1),
    R.intersection(allHubRouteNames),
    R.map(R.prop("name"))
  )
);

// eslint-disable-next-line no-underscore-dangle
const getBreadcrumbIdsOfRoute = R.propOr([], R.__, {
  HubPortalHome: ["HubPortalHome"],
  HubPortalPage: ["HubPortalHome", "HubPortalPage"],
  HubPortalAlert: ["HubPortalHome", "HubPortalAlert"],
  HubPortal_PeopleByPeopleType: [
    "HubPortalHome",
    "HubPortal_PeopleByPeopleType"
  ],
  HubPortal_RequestOrderSelectItems: [
    "HubPortalHome",
    "HubPortal_PeopleByPeopleType",
    "HubPortal_RequestOrderSelectItems"
  ],
  HubPortal_PortalReports: [
    "HubPortalHome",
    "HubPortal_PeopleByPeopleType",
    "HubPortal_PortalReports"
  ]
});

export const getBreadcrumbs = createSelector(
  getBasePath,
  getActiveRoute,
  (_, props) => props.params,
  (basePath, routes, params) =>
    R.compose(
      mapBreadcrumbs(params, basePath),
      getBreadcrumbIdsOfRoute
    )(routes)
);

export const isBreadcrumbsVisible = createSelector(
  (_, props) => props.routes,
  R.compose(
    R.not,
    R.any(R.propEq("name", "HubPortalHome"))
  )
);
