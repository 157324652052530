module.exports = {
  MODULE: {
    ID: "fa87516b-d6ca-40fc-9b17-e93b128f587b",
    TYPE: "da36516b-d6ca-40fc-9b17-e93b128f587b",
    SOURCE: "cb266790-66cc-473f-aa01-85c6c480aa33",
    SUBMITTER_ACCOUNT_FIELD: "7465636f-ce0a-4b02-8d45-3351b90a855f",
    SUBMITTER_CONTACT_FIELD: "f1a468ae-a610-4897-a20e-2d1ebed45ff8"
  },
  ORDERS: {
    /*
    CUSTOMER_ACCOUNT_LOOKUP: "45aabf80-a4e8-4571-a456-36036c0e2c3d",
    CUSTOMER_CONTACT_LOOKUP: "91202e0c-87d4-4c9c-af99-c67d3fbecaa9",
    CUSTOMER_ACCOUNT_NAME: "b3511c69-5d0d-4c36-b567-17f0a32fff7d",
    CUSTOMER_FIRST_NAME: "ab6166fd-a840-4a0a-b283-6d6629c93962",
    CUSTOMER_LAST_NAME: "1891b471-fcfe-43c6-85fc-10a7f6c5841a",
    CUSTOMER_EMAIL: "2521b471-edfe-43c6-85fc-10a7f6c5852c",
    CUSTOMER_PHONE: "293afe7d-98c6-42ed-b120-bc8690bf96b5",

    BILLING_ADDRESS_FIRST_NAME: "585572de-a481-4aab-9338-2b7d5130b309",
    BILLING_ADDRESS_LAST_NAME: "a05357d3-ec65-4c28-ba2c-2ef3aeb65d40",
    BILLING_ADDRESS_LINE_1: "f995ce38-1a5d-4347-8b18-15f5d00dbf7f",
    BILLING_ADDRESS_LINE_2: "5fbabf99-2061-4367-995c-51fa22a5037a",
    BILLING_ADDRESS_CITY: "9871aa73-2c2a-4883-a158-5522df6866da",
    BILLING_ADDRESS_STATE: "d4f4af19-4341-45fe-bcb8-2350824da070",
    BILLING_ADDRESS_ZIP: "57639970-1117-4f3c-afb4-9e2ccb01c93b",
    BILLING_ADDRESS_COUNTRY: "4f33ad12-aa0c-47cf-be1e-dc22fb166e9a",

    SHIPPING_ADDRESS_FIRST_NAME: "69574172-4a00-443d-a75e-8eb89f69cc5d",
    SHIPPING_ADDRESS_LAST_NAME: "1d63657c-a540-4720-a26e-b6218f2d2a17",
    SHIPPING_ADDRESS_LINE_1: "21cf88c1-ae35-4f77-9461-ac368e811224",
    SHIPPING_ADDRESS_LINE_2: "3eec5c24-a2f8-46fa-8cbf-2755cc6a9351",
    SHIPPING_ADDRESS_CITY: "afd14157-392c-4860-adb8-57c7a5b41491",
    SHIPPING_ADDRESS_STATE: "5b8ab808-ddbf-45ee-b46b-2615b43ca116",
    SHIPPING_ADDRESS_ZIP: "c6908d7d-bb3e-4f7e-bfda-8016af367388",
    SHIPPING_ADDRESS_COUNTRY: "53892770-51b9-48dc-bd09-8b8cf789a00b",

    CANCEL_REASON: "ad37b95e-71b9-475d-9ef8-60b93ed14d5b",
    CANCELLED_AT: "444623e0-e8c6-4bb2-b9bd-27cd9c3d5d0e",
    ARCHIVED_AT: "bc45d5f4-da49-44cd-9cd8-96a616e165de",
    CURRENCY: "8b97a3a3-f0fd-4079-93c6-ca336b1edc57",
    FINANCIAL_STATUS: "dc8951fc-64e7-4207-895b-554ecbdf1deb"
    */
  },
  ACCOUNTS: {
    NAME: "e8f3dac1-cce5-42be-bab1-0a88e8c03a72",
    WEBSITE: "0a93676a-6093-4078-a9f7-e2455dcbf982",
    ADDRESS_LINE_1: "6a0fa06c-8685-48f2-8347-e420f87086aa",
    ADDRESS_LINE_2: "4bf1f90b-5059-4e65-aec3-b8a997d2823b",
    ADDRESS_CITY: "2767c901-4a73-4909-81b4-ae853c499efd",
    ADDRESS_STATE: "f8ee941b-307f-49f5-ac6a-07baaa92de2a",
    ADDRESS_ZIP: "68d917cb-5e1c-40db-bed1-b0719aab6bed",
    ADDRESS_COUNTRY: "f396dbc4-7d93-4c24-8240-6e35a852c66e",
    ATTACHMENTS: "97d6b7fc-c059-4c49-8801-88758f5760cc",
    PHONE: "bae778db-289d-4890-a5b7-4d3e2150bd71",
    DESCRIPTION: "e8b47247-d758-4a56-95d5-7d4acfc87522"
  },
  CONTACTS: {
    ADDRESS_CITY: "4da66db6-a49a-469f-a5d5-d89c178fd731",
    ADDRESS_COUNTRY: "a5f215fb-e308-48b6-a475-936cbad45ae9",
    ADDRESS_LINE_1: "e2164897-3d8c-4907-9bfd-dfc83f0100e0",
    ADDRESS_LINE_2: "9063e65d-974c-47bb-9af4-b9a5ea9ce9e4",
    ADDRESS_STATE: "3c21f7b2-ac59-406c-83c3-29586673199d",
    ADDRESS_ZIP: "59d503dd-e650-48ea-864d-0b354e44e0e6",
    AVATAR_URL: "b47a7f7f-06f6-4faa-b07b-79de812ccb4f",
    EMAIL: "f80dea54-5ea0-413c-b82a-f8f39222ed9c",
    EVENT_DAYS: "c1920e7f-bd30-4af2-81c9-5842cb4e9805",
    FIRST_NAME: "7608ee49-290c-4028-b4f9-32e231e65091",
    HOME_PHONE: "c57f8f27-bdc0-4d93-8fa9-5d69ee37a102",
    LAST_NAME: "aab68ab0-402c-434f-aecb-6d17fe582725",
    MOBILE_PHONE: "eecf8c79-7942-4d25-b3ef-cd83b77fe691",
    TITLE: "0a3be0a1-82a2-49bf-a39c-43d81c90cd7d",
    WEBSITE: "97d9af93-dbd3-4c1c-8946-e3432039eaed"
  },
  SCHEDULES: {
    NAME: "5e52bb94-e24e-4109-9a49-1efbc11d5697",
    STARTS_AT: "1251d7b5-07d7-4d3b-9058-3188ff6b445f",
    ENDS_AT: "2d35d161-b6f4-45e8-915a-d421b3d33c68",
    ASSIGNED_TO: "b38fedb5-babd-4fca-9df5-dc8bf2dbf895",
    LOCATION: "32a4e923-893d-4c26-bd58-492e6ce9512d"
  },
  LOCATIONS: {
    NAME: "55b7fb9e-509a-4b83-b0f2-0d36146f62f7",
    DESCRIPTION: "bc9ce007-5b40-476a-9c79-2ab3ff3616b6"
  },
  LOCATION_AREAS: {
    LOCATION: "4859d913-0fdc-49e1-8f23-fc7bcddb3cfa",
    NAME: "3d97f429-ff66-4e13-96ee-03531827b1ed",
    DESCRIPTION: "10053cae-edff-4523-aa68-712b97ce30db"
  },
  APPLICATIONS: {
    APPLICANT_ACCOUNT: "6e52bb94-e24e-4109-9a49-1efbc11d5697",
    APPLICANT_CONTACT: "2251d7b5-07d7-4d3b-9058-3188ff6b445f"
  },
  FILES: {
    MIMETYPE: "250b390a-8307-4130-bb12-3df6e8ea0af3",
    FILENAME: "8fc10190-5c45-42de-8c9b-6a9c2e003b36",
    DESCRIPTION: "d87fd0b2-a84e-4f70-92be-864b457115dc",
    TITLE: "ea87516b-d6ca-40fc-9b17-e93b128f587b",
    URL: "f26fe10c-993d-485f-aa85-110dd748a66a",
    SIZE: "fb902676-4395-4e99-9548-dcf7413456af"
  },
  DOCUMENT_REQUEST_TYPES: {
    NAME: "9276c086-c54d-4014-ae5b-d9e8344451d5",
    ASSIGNMENT_SCOPE: "74aa2afe-d01b-4f29-8d38-e0b97127839c",
    SAMPLE_DOCUMENTS: "5b3a5a22-c2f6-4540-8321-941edb5dd776",
    DUE_DATE: "0d208b09-8789-4675-8bfa-ab1209050985",
    CRITERIA: "a273445b-fc82-49ca-9959-e0898d4c7560"
  },
  DOCUMENT_REQUESTS: {
    REQUEST_NAME: "622bc45b-1785-458f-b0cb-de40c9cde5f4",
    REQUEST_REVISION_STATUS: "511bc45b-1785-458f-b0cb-de40c9cde5f5",
    REQUEST_TYPE_NAME: "681bc45b-1785-458f-b0cb-de40c9cde5f5",
    RECIPIENT_NAME: "a006b32a-f525-4c6a-bc77-5a80fac2d5ed"
  },
  DOCUMENT_REQUEST_REVISIONS: {
    REVISION_STATUS: "d673b653-dd0b-4c91-9c09-1ccd05ac4dc2",
    FILES: "0c1c0f53-b569-44c8-948e-a7ef5484b8cb"
  },
  FORMSV3: {
    MODULE: "f27e89ba-4f64-4446-81a6-18c03d9150be",
    NAME: "b90299dd-f26c-40d5-9279-f2ab7682c8bd",
    CLOSE_DATE: "45761507-40c3-41ff-bcec-68b23b482a2c",
    RESPONSES_REQUIRE_APPROVAL: "9445d63a-6dd8-429b-8cec-d9a354a194eb",
    SHOW_SHARE_LINK: "aea05399-b687-4959-a551-c670badb11dd",
    LOCK_ON_SUBMISSION: "c21d8693-d799-458e-87d0-6a3cbaaa5e4a",
    IS_LOCKED: "54735af8-7045-41d8-b22b-f254daa36ee6"
  },
  CATALOG_CATEGORIES: {
    NAME: "94ea651b-f921-4054-9a0f-a364990a3efa"
  },
  CATALOG_ITEMS: {
    TRACK_BY: "a9b914f9-aaa9-49a6-89d9-0441c6772e63",
    IMAGE: "b13a3dd4-779d-4b5b-b3f2-fabdd2c47145",
    CATEGORY: "7deca04e-8948-4388-acb1-440f58b8a5f7",
    ITEM_NAME: "25fbaafc-d2f0-487b-9ed5-555ea37fb4d1",
    UNIT_OF_MEASURE: "7c9e25af-e169-4771-99d9-d82fda49ad7a"
  },
  SUBMISSIONS: {}
};
