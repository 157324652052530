import React from "react";

import * as R from "ramda";

import { connect } from "react-redux";

import { MediumFilledButton, PopMenu, PopMenuCard } from "components/Base";

import { actions, getters } from "Orders/TicketsPasses";

import {
  getProfileNoneSelected,
  getSelectedPrices
} from "Orders/TicketsPasses/selectors";

import LimitPopOver from "Orders/TicketsPasses/View/LimitPopOver";

const decorate = connect(
  state => ({
    noneSelected: getProfileNoneSelected(state),
    prices: getters.profilePricesTemp(state)
  }),
  {
    showLimitPopOver: actions.showLimitPopOver
  }
);

const ProfileMultiLimitsPopOver = ({ showLimitPopOver, prices }) => {
  return (
    <PopMenu
      Label={({ onClick }) => (
        <MediumFilledButton
          bg="primary7"
          onClick={() => {
            showLimitPopOver({ profileId: "", updateAllTempProfiles: true });
            onClick();
          }}
        >
          Update Asignment...
        </MediumFilledButton>
      )}
    >
      {({ closeMenu }) => (
        <PopMenuCard position="bottom" align="right">
          <LimitPopOver handlers={{ prices, closeMenu }} />
        </PopMenuCard>
      )}
    </PopMenu>
  );
};

export default decorate(ProfileMultiLimitsPopOver);
