import { combineReducers } from "redux";
import { SHOW, HIDE } from "./constants";

const snackbar = (state = null, action) => {
  switch (action.type) {
    case SHOW:
      return action.payload;
    case HIDE:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  snackbar
});
