import React from "react";
import { connect } from "react-redux";
import { Div, Text3, DarkCloseButton } from "components/Base";
import { getHeaderTitle } from "Organizations/CloneEventModal/selectors";
import { getters } from "Organizations/CloneEventModal/model";

const decorate = connect(state => ({
  headerTitle: getHeaderTitle(state),
  processing: getters.processing(state)
}));

const Header = ({ handlers: { hideModal }, processing, headerTitle }) => (
  <Div
    pl={7}
    pr={3}
    bb={1}
    bc="neutral2"
    color="neutral4"
    height={64}
    display="row.space-between.center"
    style={{
      flexShrink: 0
    }}
  >
    <Text3 color="gray7" bold>
      {headerTitle}
    </Text3>
    {processing ? null : <DarkCloseButton size={30} onClick={hideModal} />}
  </Div>
);

export default decorate(Header);
