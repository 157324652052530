import React from "react";
import {
  Div,
  Text2,
  MediumFilledButton,
  RightArrowIcon
} from "components/Base";
import { connect } from "react-redux";

import { getUserName } from "Portal/IntakeForm/selectors";
import { getters, actions } from "Portal/IntakeForm/model";

const decorate = connect(
  state => ({
    isLoggedIn: getters.isLoggedIn(state),
    userName: getUserName(state)
  }),
  {
    goToLoginPage: actions.goToLoginPage,
    handleLogout: actions.handleLogout
  }
);

const LoginSection = ({
  mobile,
  handlers,
  goToLoginPage,
  isLoggedIn,
  userName,
  handleLogout
}) =>
  mobile ? null : (
    <Div ml={3} pt={4} width={200}>
      {isLoggedIn ? (
        <Div display="column" bg="neutral1" bra={2} p={3} mb={3}>
          <Div fs={2} fw={3} color="gray7" mb={2}>
            You are logged in as {userName}.
          </Div>
          <Div fs={1}>
            Not you?{" "}
            <span
              onClick={() => {
                handlers.setShowAlert(false);
                handleLogout();
              }}
              style={{
                textDecoration: "underline",
                cursor: "pointer"
              }}
            >
              Log out
            </span>
            .
          </Div>
        </Div>
      ) : (
        <>
          <Div display="column">
            <Text2 color="black" bold>
              Already registered?
            </Text2>
            <Text2>
              If you have previously registered, please try logging in to
              confirm or change your information.
            </Text2>
          </Div>
          <MediumFilledButton
            style={{ borderRadius: "20px" }}
            color="neutral7"
            RightIcon={RightArrowIcon}
            bg="neutral2"
            mt={4}
            onClick={() => {
              handlers.setShowAlert(true);
              goToLoginPage();
            }}
          >
            Log in Now
          </MediumFilledButton>
        </>
      )}
    </Div>
  );

export default decorate(LoginSection);
