import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const ModuleUISearch = ({
  searching = false,
  value,
  placeholder,
  onChange
}) => {
  let input;

  const onSearch = e => {
    e.preventDefault();
    onChange(input.value);
  };

  const onClear = () => {
    input.value = "";
    onChange("");
  };

  return (
    <form styleName="container" onSubmit={onSearch}>
      <div styleName="inputWrapper">
        <i className="material-icons left">search</i>
        {searching ? (
          <i className="material-icons rotate right">refresh</i>
        ) : value ? (
          <i
            className="material-icons right clickable"
            onClick={onClear}
            role="button"
            tabIndex="0"
          >
            close
          </i>
        ) : null}
        <input
          ref={c => (input = c)}
          type="text"
          defaultValue={value}
          placeholder={placeholder}
          maxLength={32}
        />
      </div>
      <button styleName="button" type="submit">
        Search
      </button>
    </form>
  );
};

ModuleUISearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  searching: PropTypes.bool,
  value: PropTypes.string
};

export default CSSModules(ModuleUISearch, css);
