import Helpers from "utils/Global/Helpers";

module.exports = {
  get: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: data.orgId
          ? `${window.__LENND_API_BASE_URL__}/organizations/${data.orgId}/email-merge-tags`
          : `${window.__LENND_API_BASE_URL__}/event/${data.eventId}/email-merge-tags`,
        credentials,
        qs: { moduleId: data.moduleId },
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */

      Helpers.request(options);
    }),

  post: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/event/${data.eventId}/email-merge-tags`,
        data,
        credentials,
        qs: { ...data.options, moduleId: data.moduleId },
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */

      Helpers.request(options);
    })
};
