import PropTypes from "prop-types";
import React from "react";
import Panel from "./Panel";

const modulesWithCatalogItemCategory = (modules, categoryId) =>
  modules.filter(({ fields }) =>
    fields.fields.some(field => field.settings.categoryId === categoryId)
  );

const ItemDetails = ({ details, categoryId, activeAccountId }) => {
  const categoryDetails = modulesWithCatalogItemCategory(details, categoryId);
  return (
    <div>
      {categoryDetails.map(({ module, fields }) => (
        <Panel
          key={module.id}
          activeAccountId={activeAccountId}
          defaultOpen
          fields={fields.fields}
          parent={module}
        />
      ))}
    </div>
  );
};

ItemDetails.propTypes = {
  activeAccountId: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  details: PropTypes.arrayOf(
    PropTypes.shape({
      fields: PropTypes.shape({
        fields: PropTypes.array
      })
    })
  ).isRequired
};

export default ItemDetails;
