import { createContext } from "redux-mvc";
import * as R from "ramda";
import module, { actions } from "../index";
import Layout from "./Layout";
import rootSaga from "../sagas";

module.setRootSaga(rootSaga);

const decorate = createContext({
  module: module,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(
        actions.init({
          type: this.props.type,
          moduleId: this.props.moduleId,
          viewId: this.props.viewId,
          recordTypeId: this.props.recordTypeId,
          itemTypeId: this.props.itemTypeId,
          formId: this.props.formId
        })
      );
    }
  },
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: [
      "user",
      "event",
      "modal",
      "organization",
      "permissions",
      "userAccessLevels",
      "@flopflip"
    ]
  }
});

export default decorate(Layout);
