import React from "react";
import { connect } from "react-redux";
import { BigFilledButton, RightArrowIcon } from "components/Base";
import { actions, getters } from "Portal/IntakeForm/model";
import {
  getNextButtonText,
  getNextButtonDisabled
} from "Portal/IntakeForm/selectors";

const decorate = connect(
  state => ({
    text: getNextButtonText(state),
    disabled: getNextButtonDisabled(state),
    saving: getters.saving(state),
    searching: getters.searching(state)
  }),
  {
    nextPage: actions.nextPage
  }
);

const NextButton = ({
  disableShowAlert,
  handlers,
  nextPage,
  text,
  disabled,
  saving,
  searching
}) => (
  <BigFilledButton
    color="white"
    RightIcon={saving ? undefined : RightArrowIcon}
    bg="indigo7"
    style={{ borderRadius: "20px" }}
    onClick={
      saving || searching
        ? undefined
        : () => {
            if (disableShowAlert) {
              handlers.setShowAlert(false);
            }
            nextPage();
          }
    }
    disabled={disabled}
  >
    {saving ? "Completing..." : text}
  </BigFilledButton>
);

export default decorate(NextButton);
