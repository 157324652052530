import React, { useEffect } from "react";
import ReviewedCard from "./ReviewedCard";
import PendingCard from "./PendingCard";
import { connect } from "react-redux";
import { actions } from "../../model";

const decorate = connect(
  null,
  {
    init: actions.init
  }
);

const MyApprovals = ({ init }) => {
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <PendingCard />
      <ReviewedCard />
    </>
  );
};

export default decorate(MyApprovals);
