import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Step = CSSModules(({ label, active, index, stepCount }) => {
  const isFirst = index === 0;
  const isLast = index === stepCount - 1;
  const activeState = active ? "Active" : "";
  return (
    <div styleName={`step${activeState}`}>
      <div styleName="label">{label}</div>
      <div styleName="progress">
        <div styleName="centerIcon" />
        <div
          styleName="connector"
          style={{
            width: isFirst || isLast ? "50%" : "100%",
            right: isLast ? "50%" : 0
          }}
        />
      </div>
    </div>
  );
}, css);

const Stepper = ({ activeStepIndex = 0, steps = [] }) => {
  const stepList = steps.map((step, i) => (
    <Step
      label={step}
      key={step}
      active={activeStepIndex >= i}
      index={i}
      stepCount={steps.length}
    />
  ));
  return <div styleName="container">{stepList}</div>;
};

Stepper.propTypes = {
  activeStepIndex: PropTypes.number,
  steps: PropTypes.array
};

export default CSSModules(Stepper, css);
