import React, { Component } from "react";
import * as R from "ramda";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import View from "./View";

import { LABELS } from "utils/item-types";
import { MEAL_TYPE_ID, CREDENTIAL_TYPE_ID } from "utils/item-types";

import Items from "./Items";
import Actuals from "./Actuals";
import Blocks from "./Blocks";
import Questions from "Items/Questions/View";

const getView = route =>
  R.propOr("editEventSettingsCatalogItems", route)({
    editEventSettingsCatalogItems: Items,
    editEventSettingsCatalogActuals: Actuals,
    editEventSettingsCatalogBlocks: Blocks,
    editEventSettingsCatalogQuestions: Questions
  });

class Controller extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activeRoute: this.props.route.name
    };
  }

  async componentDidMount() {
    await this.props.getItemTypesByEvent(this.props.eventDetails.id);

    this.setState({ loading: false });
  }

  componentDidUpdate(oldProps) {
    if (oldProps.route.name !== this.props.route.name) {
      this.setState({
        activeRoute: this.props.route.name
      });
    }
  }

  goToPath = path => {
    this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/settings${path}`
    });
  };

  render() {
    const tabs = [
      {
        key: "editEventSettingsCatalogItems",
        visible: true,
        label: "Items",
        onClick: () =>
          this.goToPath(`/catalog/type/${CREDENTIAL_TYPE_ID}/items`)
      },
      {
        key: "editEventSettingsCatalogQuestions",
        visible: true,
        label: "Item Questions",
        onClick: () => this.goToPath(`/catalog/questions`)
      }
      /*
      {
        key: "editEventSettingsCatalogActuals",
        visible: true,
        label: "All Items",
        onClick: () => this.goToPath(`/catalog/${this.props.typeId}/actuals`)
      },
      ,
      {
        key: "editEventSettingsCatalogBlocks",
        visible: true,
        label: "All Items",
        onClick: () => this.goToPath(`/catalog/${this.props.typeId}/blocks`)
      }
      */
    ];

    const tabsWithHandlers = tabs.map(t => ({
      ...t,
      active: t.key === this.props.route.name
    }));

    const ActiveTabComponent = getView(this.props.route.name);

    const viewProps = R.propOr(
      "editEventSettingsCatalogItems",
      this.props.route.name
    )({
      editEventSettingsCatalogItems: {
        test: "test"
      }
    });

    const itemTypesWithHandlers = R.compose(
      R.map(type => {
        const meta = LABELS[type.id];
        return {
          id: type.id,
          name: type.name,
          color: meta.color,
          icon: meta.icon,
          active: this.props.params.typeId === type.id,
          countOfCategories: R.compose(
            R.length,
            R.prop("groups")
          )(type),
          countOfItems: R.compose(
            R.length,
            R.flatten,
            R.map(R.prop("items")),
            R.prop("groups")
          )(type),
          onClick: () => this.goToPath(`/catalog/type/${type.id}/items`)
        };
      }),
      R.filter(type => {
        if (!this.props.isLenndAdmin) {
          return [CREDENTIAL_TYPE_ID, MEAL_TYPE_ID].includes(type.id);
        }
        return true;
      })
    )(this.props.itemTypes || []);

    const defaultViewProps = {
      loading: this.state.loading,
      tabs: tabsWithHandlers,
      ActiveTabComponent,
      viewProps,
      itemTypes: itemTypesWithHandlers,
      showSidebar: Boolean(this.props.params.typeId)
    };

    return <View {...defaultViewProps} />;
  }
}

export default Controller;
