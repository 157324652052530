import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import { actions, getters } from "Modules/AddEditColumnModal/model";
import Lookup from "components/Global/Table3/ModalColumn/LookupSettings";
import { noop } from "utils/General";
import css from "./style.scss";

const decorate = R.compose(
  connect(
    (state, props) => ({
      lookupSettings: getters.lookupSettings(state, props),
      orgId: getters.orgId(state, props),
      eventId: getters.eventId(state, props)
    }),
    {
      setLookupSettings: actions.setLookupSettings
    }
  ),
  CSSModules(css)
);

const LookupSettings = ({
  lookupSettings,
  setLookupSettings = noop,
  orgId,
  eventId
}) => (
  <div styleName="dropdownOptionsWrapper">
    <Lookup
      orgId={orgId}
      eventId={eventId}
      value={lookupSettings}
      onChange={setLookupSettings}
    />
  </div>
);

export default decorate(LookupSettings);
