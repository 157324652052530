import Immutable from "immutable";
import { combineReducers } from "redux";
import { RECEIVE, REQUEST, INVALIDATE } from "./constants";
import { ERROR } from "redux/modules/errors/constants";

const ordersByRecord = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn(["byId", action.payload.recordId], action.payload.orders)
        .toJS();
    case INVALIDATE:
      return Immutable.fromJS(state)
        .deleteIn(["byId", action.payload.recordId])
        .toJS();
    default:
      return state;
  }
};

const fetching = (state = {}, action) => {
  switch (action.type) {
    case REQUEST:
      return Immutable.fromJS(state)
        .setIn(["byId", action.payload.recordId], true)
        .toJS();
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn(["byId", action.payload.recordId], false)
        .toJS();
    case ERROR:
      return {};
    default:
      return state;
  }
};

export default combineReducers({
  ordersByRecord,
  fetching
});
