import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { getPages, getPagesTotal } from "../selectors";

import { ActionSection, ActionItem } from "PortalHub/ActionComponents/View";
import SlimPagination from "ui-kit/PaginationBar/View/Slim";

import { CARD_ACTIONS, SECTION_ACTIONS } from "../constants";

const pageSizeOptions = [5, 10, 15];

const sectionButtons = [
  { label: "View All", id: SECTION_ACTIONS.VIEW_ALL_PAGES, icon: "visibleIcon" }
];

const fileButtons = [
  { label: "Print", id: CARD_ACTIONS.PRINT_PAGE, icon: "printIcon" },
  { label: "Visit", id: CARD_ACTIONS.VIEW_PAGE, rightIcon: "rightIcon" }
];

const decorate = connect((state, props) => ({
  pages: getPages(state, props),
  total: getPagesTotal(state, props)
}));

const PagesSection = ({ pages = [], total }) => {
  if (total < 1) {
    return null;
  }
  return (
    <ActionSection title="Pages" buttons={sectionButtons}>
      {R.map(
        page => (
          <ActionItem
            key={page.id}
            icon="info"
            title={page.label}
            caption={page.description}
            buttons={fileButtons}
            data={page}
          />
        ),
        pages
      )}
      <SlimPagination
        total={total}
        pageSizeOptions={pageSizeOptions}
        rowsText="pages"
        instanceId="pages"
        bg="transparent"
      />
    </ActionSection>
  );
};

export default decorate(PagesSection);
