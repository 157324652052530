import React from "react";
import { connect } from "react-redux";

import { noop } from "utils/General";

import { getters, actions } from "Orders/List";

import { getSelectionActive } from "Orders/Table/selectors";
import { getPlaceholder } from "Orders/List/selectors";

import {
  Div,
  SearchIcon,
  SendIcon,
  AddIcon,
  MediumOutlineInput,
  MediumFilledButton,
  MediumShadedButton
} from "components/Base";

import CanUserDo from "components/Global/Security/CanUserDo";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

const decorate = connect(
  state => ({
    searchTerm: getters.searchTerm(state),
    showActions: getSelectionActive(state),
    searchPlaceholder: getPlaceholder(state)
  }),
  {
    onSearch: actions.setSearchTerm
  }
);

const SearchBar = ({
  searchTerm = "",
  onSearch = noop,
  handlers = {},
  searchPlaceholder = "Search by order number...",
  showActions = false,
  sendLinks = noop,
  sendEmails = noop,
  ...styleProps
}) => (
  <Div
    display="row.space-between.center"
    style={{ flexShrink: 0 }}
    {...styleProps}
  >
    {showActions ? (
      <Div display="row">
        <MediumFilledButton
          LeftIcon={SendIcon}
          bg="altB5"
          onClick={sendLinks}
          mr={1}
        >
          Send Links
        </MediumFilledButton>
        <MediumShadedButton onClick={sendEmails}>
          Send Emails
        </MediumShadedButton>
      </Div>
    ) : (
      <MediumOutlineInput
        LeftIcon={SearchIcon}
        value={searchTerm}
        continuous
        placeholder={searchPlaceholder}
        onChange={txt => onSearch(txt)}
        style={{ width: "100%", maxWidth: 400 }}
        ba={2}
        bg="white"
      />
    )}
    <CanUserDo any={[`${STANDARD_MODULE_IDS.orders.id}_add`]}>
      <MediumFilledButton
        bg="altB5"
        onClick={handlers.showAddOrderModal}
        LeftIcon={AddIcon}
      >
        Add Order
      </MediumFilledButton>
    </CanUserDo>
  </Div>
);

export default decorate(SearchBar);
