import React from "react";
import { Div, DragIcon, HalfSizeDotIcon, Cards } from "components/Base";

import { connect } from "react-redux";
import { formatAmountForEvent } from "redux/modules/event/selectors";

const decorate = connect(state => ({
  formatAmountForEvent: formatAmountForEvent(state)
}));

const Item = ({ color, name, subname, limit, price, formatAmountForEvent }) => {
  const meta = [];

  if (subname) {
    meta.push(subname);
  }
  if (price) {
    meta.push(formatAmountForEvent(price));
  }
  if (limit) {
    meta.push(`Limit: ${limit}`);
  }

  return (
    <Div
      display="row.flex-start.center"
      py={2}
      pl={2}
      pr={3}
      bg="white"
      bc="neutral2"
      b={1}
      mb={2}
      truncate
      style={{
        width: "100%",
        cursor: "pointer"
      }}
    >
      <DragIcon size={20} mr={3} style={{ flexShrink: 0 }} />
      <HalfSizeDotIcon
        size={16}
        style={{ color: color, flexShrink: 0 }}
        mr={2}
      />
      <Div display="column" pr={2}>
        <Div fw={3} fs={3} truncate style={{ maxWidth: 340 }}>
          {name}
        </Div>
        {meta.length ? (
          <Div color="gray7" fs={1}>
            {meta.join(" · ")}
          </Div>
        ) : null}
      </Div>
    </Div>
  );
};

const decoratedItem = decorate(Item);

const ItemList = Cards(decoratedItem, "ITEM_BLOCK_ITEMS");

const Items = ({ items, showItemsModal, reorderItems }) => (
  <Div display="column" px={4} pb={3}>
    <Div
      display="row.center.center"
      color="white"
      bg={{ default: "purple6", hover: "purple7" }}
      bra="1"
      px={1}
      py={2}
      mb={3}
      onClick={showItemsModal}
    >
      Edit / Add Items
    </Div>
    <Div>
      <ItemList cards={items} onReorder={reorderItems} />
    </Div>
  </Div>
);

export default Items;
