import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { Link } from "react-router";
import PillButton from "components/Global/CRM/PillButton";

import { getValue, getSettings } from "../../utils";

import { eventDetails } from "redux/modules/event/selectors";
import { orgDetails } from "redux/modules/organization/selectors";

const decorate = connect(state => ({
  eventDetails: eventDetails(state),
  orgDetails: orgDetails(state)
}));

const AccountName = props => {
  const id = getValue(props, "id") || props.data.id;
  const name = getValue(props, "id")
    ? getValue(props, "name")
    : getValue(props);
  const dashboardId = R.pathOr("", ["data", "type", "id"], props);
  const returnTo = window.location.pathname.includes("/type/")
    ? getValue(props, "returnTo") || `viaDashboard=${dashboardId}`
    : "";

  const big = getSettings(props, "big", false);
  const showButton = getSettings(props, "showButton", false);
  const suppressLinkToRecord = getSettings(
    props,
    "suppressLinkToRecord",
    false
  );
  let path =
    props.orgDetails && props.orgDetails.id
      ? `/organization/${props.orgDetails.id}/account/${id}`
      : `/event/${props.eventDetails.id}/account/${id}?${
          returnTo ? returnTo : ""
        }`;

  // hack to link to light profile
  if (window.location.pathname.includes("-light")) {
    path =
      props.orgDetails && props.orgDetails.id
        ? `/org-light/${props.orgDetails.id}/account/${id}`
        : `/event-light/${props.eventDetails.id}/account/${id}?${
            returnTo ? returnTo : ""
          }`;
  }

  return (
    <div
      className="hoverContainer"
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%"
      }}
    >
      {!suppressLinkToRecord ? (
        <Link
          to={path}
          style={{
            display: "block",
            width: "100%"
          }}
        >
          <div
            title={name}
            className="underline-on-hover"
            style={{
              fontSize: big ? "18px" : "16px",
              fontWeight: 600,
              color: "rgb(130, 61, 209)",
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            {name}
          </div>
        </Link>
      ) : (
        <div
          title={name}
          style={{
            fontSize: big ? "18px" : "16px",
            fontWeight: 600,
            color: "#000",
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
        >
          {name}
        </div>
      )}
      {showButton ? (
        <div
          className="showOnHover"
          style={{
            position: "absolute",
            right: 3,
            top: "50%",
            transform: "translateY(-50%)"
          }}
        >
          <PillButton title="View Group" linkTo={path} />
        </div>
      ) : null}
    </div>
  );
};

export default decorate(AccountName);
