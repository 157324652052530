import { REQUEST, RECEIVE, INVALIDATE } from "./constants";
import * as selectors from "./selectors";
import api from "./api";
import { addReferences } from "redux/modules/entityReferences/actions";
import { registerError } from "redux/modules/errors/actions";

export function getRecord(data) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState(), data.recordId)) return;
    try {
      dispatch({
        type: REQUEST,
        payload: {
          recordId: data.recordId
        }
      });
      const result = await api.get(getState().user.user.credentials, data);
      dispatch(addReferences(result.record.references));
      dispatch({
        type: RECEIVE,
        payload: {
          recordId: data.recordId,
          record: result.record
        }
      });
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching record"
          }
        ])
      );
    }
  };
}

export function deleteRecord(data) {
  return async (dispatch, getState) => {
    try {
      return await api.delete(getState().user.user.credentials, data);
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred removing record"
          }
        ])
      );
    }
  };
}

export function invalidateRecord(recordId) {
  return {
    type: INVALIDATE,
    payload: {
      recordId
    }
  };
}
