import PropTypes from "prop-types";
import React from "react";
import { OutlineButton } from "components/Global/CRM/Card/Buttons";
import CSSModules from "react-css-modules";
import css from "../styles.scss";

const ModuleActionButton = CSSModules(
  props => (
    <OutlineButton onClick={props.meta.showAddRecordModal}>
      <i className={["material-icons", css.icon].join(" ")}>file_upload</i>
      <span>Upload</span>
    </OutlineButton>
  ),
  css
);

ModuleActionButton.propTypes = {
  meta: PropTypes.object.isRequired
};

export default ModuleActionButton;
