import React from "react";
import { connect } from "react-redux";

import { Div, Text3, MediumTextButton } from "components/Base";
import Tabs from "ui-kit/Tabs/View";

import { getters, actions } from "Schedules/ScheduleViewPicker";
import { getSorters } from "Schedules/ScheduleViewPicker/selectors";

import { noop } from "utils/General";

const decorate = connect(
  (state, props) => ({
    tabs: getters.tabs(state, props),
    sorters: getSorters(state, props),
    sortBy: getters.sortBy(state, props)
  }),
  {
    updateSort: actions.setSortBy
  }
);

const ViewPickerTabs = ({
  tabs,
  sorters,
  sortBy,
  updateSort,
  ...styleProps
}) => (
  <Div display="row.space-between.center" bb={1} bc="neutral3" {...styleProps}>
    <Tabs tabs={tabs} />
    <Div display="row.flex-start.center">
      <Text3>Sort by:</Text3>
      {sorters.map(sort => (
        <MediumTextButton
          key={sort.id}
          isPadded={false}
          onClick={sort.id === sortBy.id ? noop : () => updateSort(sort)}
          ml={2}
          bold
          color={sort.id === sortBy.id ? "purple6" : "neutral9"}
        >
          {sort.title}
        </MediumTextButton>
      ))}
    </Div>
  </Div>
);

export default decorate(ViewPickerTabs);
