import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const defaultIcon = (
  <img src="//d2dks4tzxs6xee.cloudfront.net/img/inbox_icon.png" alt="" />
);

export const ButtonWrapper = ({ children }) => (
  <div className={css.buttonWrapper}>{children}</div>
);

const CardEmptyState = ({ icon = defaultIcon, children, center = false }) =>
  center ? (
    <div styleName="emptyStateCenter">
      <div styleName="iconCenter">{icon}</div>
      <div styleName="body">{children}</div>
    </div>
  ) : (
    <div styleName="emptyState">
      <div styleName="icon">{icon}</div>
      <div styleName="body">{children}</div>
    </div>
  );

CardEmptyState.propTypes = {
  icon: PropTypes.element.isRequired
};

export default CSSModules(CardEmptyState, css);
