import React from "react";

import { connect } from "react-redux";
import { Div } from "components/Base";

import { actions, getters } from "../model";

import Table, { TableWrapper } from "ui-kit/Table/View";
import { TABLE_INSTANCE_ID } from "../constants";
import EmptyState from "EventLight/Common/EmptyState";
import Loading from "ui-kit/Loading";

const decorate = connect(
  state => ({
    showEmptyState: getters.showEmptyState(state),
    data: getters.data(state),
    searching: getters.searching(state)
  }),
  {
    showAddModal: () => actions.setShowAddModal(true)
  }
);

const Body = ({ data, showAddModal, showEmptyState, searching = false }) => (
  <>
    {showEmptyState && !data.length ? (
      <EmptyState
        icon="task_alt"
        title="You haven't added any tasks yet"
        action="Add Task"
        onClick={showAddModal}
      />
    ) : (
      <>
        {searching ? (
          <Loading />
        ) : (
          <Div width={1}>
            <TableWrapper
              instanceId={TABLE_INSTANCE_ID}
              style={{ flex: 1, overflowY: "inherit" }}
              innerStyle={{ overflowY: "inherit" }}
            >
              <Table instanceId={TABLE_INSTANCE_ID} showCheckbox={false} />
            </TableWrapper>
          </Div>
        )}
      </>
    )}
  </>
);

export default decorate(Body);
