import PropTypes from "prop-types";
import React, { Component } from "react";
import Needs from "./Scenes/Needs";
import Catalog from "./Scenes/Catalog";
import Primary from "./Scenes/Primary";

class SidebarManager extends Component {
  static resolveActiveState() {
    const path = window.location.pathname.split("/");
    return path[path.length - 1];
  }

  state = { scene: this.props.scene };

  componentDidMount() {
    this.props.getModulesWithCatalogItems(this.props.params.eventId);
  }

  componentWillReceiveNewProps(newProps) {
    if (newProps.scene !== this.state.scene) {
      this.setState({ scene: newProps.scene });
    }
  }

  switchScene = (scene = "") => {
    this.setState({ scene });
  };

  render() {
    const { params, sidebarModules, router } = this.props;

    switch (this.state.scene) {
      case "needs":
        return (
          <Needs
            active={SidebarManager.resolveActiveState()}
            router={router}
            modules={sidebarModules}
            onSceneChange={this.switchScene}
            params={params}
          />
        );
      case "catalog":
        return (
          <Catalog
            active={SidebarManager.resolveActiveState()}
            params={params}
            onSceneChange={this.switchScene}
          />
        );
      default:
        return (
          <Primary
            active={SidebarManager.resolveActiveState()}
            params={params}
            onSceneChange={this.switchScene}
          />
        );
    }
  }
}

SidebarManager.propTypes = {
  getModulesWithCatalogItems: PropTypes.func.isRequired,
  params: PropTypes.shape({
    eventId: PropTypes.string
  }).isRequired,
  router: PropTypes.object.isRequired,
  scene: PropTypes.oneOf(["needs", "catalog"]).isRequired,
  sidebarModules: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default SidebarManager;
