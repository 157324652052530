import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import uuid from "node-uuid";
import moment from "moment";
import DayPicker, { DateUtils } from "react-day-picker";
import {
  findIndex,
  cloneDeep,
  filter,
  has,
  without,
  find,
  uniq,
  difference
} from "lodash";
import { Step, Stepper, StepLabel, StepContent } from "material-ui/Stepper";
import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import DaySelector from "components/Event/Settings/EventSettings/EventDays/DaySelector";
import MarkDaysAs from "components/Event/Settings/EventSettings/EventDays/MarkDaysAs";
import ManageEventActions from "actions/Event/DetailsActions";
import Body from "components/Event/Settings/Body";
import Page from "components/Event/Settings/Page";

import CSSModules from "react-css-modules";
import css from "./styles.scss";
import "react-day-picker/lib/style.css";

const defaultDayGroups = [
  {
    id: "uuid-load-in-days",
    name: "Load In Days",
    color: "#E19517",
    days: []
  },
  {
    id: "uuid-show-days",
    name: "Show Days",
    color: "#01C07C",
    days: []
  },
  {
    id: "uuid-load-out-days",
    name: "Load Out Days",
    color: "#D0021B",
    days: []
  },
  {
    id: 0,
    name: "Day Off",
    color: "#ffffff",
    days: []
  }
];

@CSSModules(css)
class DaysOnsite extends Component {
  constructor(props) {
    super(props);
    const settings = cloneDeep(this.getSettings(props.details));

    this.state = {
      open: false,
      anchorEl: null,
      finished: false,
      stepIndex: 0,
      selectedDays: [],
      settings,
      enteredTo: settings.to
    };
  }

  getSettings(details = {}) {
    return {
      from: details.date_from
        ? moment(details.date_from.substring(0, 10))
            .utc()
            .toDate()
        : null,
      to: details.date_to
        ? moment(details.date_to.substring(0, 10))
            .utc()
            .toDate()
        : null,
      dayGroups:
        details.date_groups.length === 0
          ? defaultDayGroups
          : details.date_groups
    };
  }

  isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  };

  handleDayClick = day => {
    /*
    const range = DateUtils.addDayToRange(day, this.state.settings);
    this.setState({
      settings: {
        ...range,
        dayGroups: this.state.settings.dayGroups
      }
    });
    */
    const { from, to } = this.state.settings;

    if (from && to && day >= from && day <= to) {
      this.handleResetClick();
      return;
    }
    if (this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        settings: {
          ...this.state.settings,
          from: day,
          to: null
        },
        enteredTo: null
      });
    } else {
      this.setState({
        enteredTo: day,
        settings: {
          ...this.state.settings,
          to: day
        }
      });
    }
  };

  handleDayMouseEnter = day => {
    const { from, to } = this.state.settings;
    if (!this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day
      });
    }
  };

  handleResetClick = () => {
    this.setState({
      settings: {
        from: null,
        to: null,
        dayGroups: this.state.settings.dayGroups
      }
    });
  };

  handleNext = () => {
    const { params } = this.props;
    const { stepIndex, settings } = this.state;
    const { from, to, dayGroups } = settings;

    const dateFrom = moment(from).startOf("day");

    const dateTo = moment(to).startOf("day");

    // filter through day groups to ensure that all days match our updated range
    const updatedDayGroups = dayGroups.reduce((list, group) => {
      list.push({
        ...group,
        days: group.days.filter(d => {
          const day = moment(d).startOf("day");
          return day.isSameOrAfter(dateFrom) && day.isSameOrBefore(dateTo);
        })
      });
      return list;
    }, []);

    // go to next scene
    this.setState({
      stepIndex: stepIndex + 1,
      finished: stepIndex >= 1
    });

    // save settings after .5 sec delay in order not to disrupt animation
    setTimeout(() => {
      ManageEventActions.updateEvent(
        this.props.userCredentials,
        params.eventId,
        {
          date_from: dateFrom.format(),
          date_to: dateTo.format(),
          date_groups: updatedDayGroups
        },
        () => {
          this.props.getEvent(params.eventId);
        }
      );
    }, 500);
  };

  @autobind
  handlePrev() {
    const { stepIndex } = this.state;
    if (stepIndex > 0) {
      this.setState({ stepIndex: stepIndex - 1 });
    }
  }

  renderStepActions(step) {
    const { stepIndex } = this.state;
    return (
      <div styleName="buttonWrapper">
        <RaisedButton
          label={stepIndex === 1 ? "Finish" : "Next"}
          disableTouchRipple
          disableFocusRipple
          primary
          onClick={this.handleNext}
          style={{ marginRight: 12 }}
        />
        {step > 0 && (
          <FlatButton
            label="Back"
            disabled={stepIndex === 0}
            disableTouchRipple
            disableFocusRipple
            onClick={this.handlePrev}
          />
        )}
      </div>
    );
  }

  @autobind
  handleDayGroupDayClick(ydm) {
    this.setState(state => {
      if (this.isDaySelected(ydm)) {
        state.selectedDays = without(state.selectedDays, ydm);
      } else {
        state.selectedDays.push(ydm);
      }
      return state;
    });
  }

  @autobind
  isDaySelected(ydm) {
    return this.state.selectedDays.includes(ydm);
  }

  @autobind
  buildDayMap(startDate, endDate, dayGroups) {
    const map = {};
    dayGroups.forEach(group => {
      group.days.forEach(day => {
        map[day] = {
          color: group.color,
          isSelected: this.isDaySelected(day)
        };
      });
    });
    for (
      let m = moment(startDate);
      m.isBefore(endDate) || m.isSame(endDate);
      m.add(1, "days")
    ) {
      const day = m.format("YYYY-MM-DD");
      if (!has(map, day)) {
        map[day] = {
          color: "#333333",
          isSelected: this.isDaySelected(day)
        };
      }
    }
    return map;
  }

  @autobind
  handleUpdateOption(key, index, option) {
    this.setState(state => {
      if (index === -1) {
        if (option.value.length > 0) {
          state.settings[key].push({
            ...option,
            id: uuid.v4()
          });
        }
      } else {
        state.settings[key][index] = option;
      }
      return state;
    });
  }

  @autobind
  handleRemoveOption(key, index) {
    this.setState(state => {
      if (key === "meals") {
        state.settings.mealDays = state.settings.mealDays.filter(
          m => m.mealId !== state.settings[key][index].id
        );
      }
      state.settings[key] = state.settings[key].filter(
        r => r.id !== state.settings[key][index].id
      );
      return state;
    });
  }

  @autobind
  handleMealDayChange(mealId, dayId) {
    this.setState(state => {
      if (find(state.settings.mealDays, { mealId, dayId })) {
        state.settings.mealDays = filter(state.settings.mealDays, record => {
          if (record.mealId === mealId && record.dayId === dayId) {
            return false;
          }
          return true;
        });
      } else {
        state.settings.mealDays.push({ mealId, dayId });
      }
    });
  }

  @autobind
  isMealDaySelected(mealId, dayId) {
    if (find(this.state.settings.mealDays, { mealId, dayId })) {
      return true;
    }
    return false;
  }

  @autobind
  handleMarkDaysAreaClose() {
    this.setState({
      open: false,
      anchorEl: null
    });
  }

  @autobind
  handleMarkDaysAreaClick(event) {
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget
    });
  }

  @autobind
  selectGroup(id) {
    this.setState(state => {
      if (state.selectedDays.length === 0) {
        return state;
      }

      // remove selected days from existing groups
      state.settings.dayGroups.forEach((group, idx) => {
        state.settings.dayGroups[idx].days = difference(
          state.settings.dayGroups[idx].days,
          state.selectedDays
        );
      });

      // if selecting 'day off', all done
      if (id === 0) {
        state.selectedDays = [];
        return state;
      }

      // add selected days to group
      const idx = findIndex(state.settings.dayGroups, { id });
      state.settings.dayGroups[idx].days = uniq(
        [].concat(state.settings.dayGroups[idx].days, state.selectedDays)
      );

      // reset selected days
      state.selectedDays = [];

      return state;
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.details !== nextProps.details) {
      const settings = cloneDeep(this.getSettings(nextProps.details));
      if (settings.from && this.refs.daypicker) {
        this.refs.daypicker.showMonth(settings.from);
      }
      this.setState({ settings });
    }
  }

  render() {
    const { enteredTo, finished, stepIndex, settings } = this.state;
    const { from, to, dayGroups } = settings;

    // daypicker settings
    const modifiers = { start: from, end: enteredTo };
    const disabledDays = { before: from };
    const selectedDays = [from, { from, to: enteredTo }];

    return (
      <Page>
        <Body>
          <div styleName="container">
            <Stepper activeStep={stepIndex} orientation="vertical">
              <Step>
                <StepLabel>
                  Configure your full range of event days (Ex: Load in, Load
                  out, Day of)
                </StepLabel>
                <StepContent>
                  <div styleName="stepContent">
                    <div className="RangeExample">
                      {!from &&
                        !to && (
                          <p>
                            Please select the <strong>first day</strong>.
                          </p>
                        )}
                      {from &&
                        !to && (
                          <p>
                            Please select the <strong>last day</strong>.
                          </p>
                        )}
                      {from &&
                        to && (
                          <p>
                            You chose from {moment(from).format("L")} to{" "}
                            {moment(to).format("L")}.{" "}
                            <a href="#" onClick={this.handleResetClick}>
                              Reset
                            </a>
                          </p>
                        )}
                      <DayPicker
                        className="Range"
                        numberOfMonths={2}
                        month={from}
                        fromMonth={from}
                        selectedDays={selectedDays}
                        disabledDays={disabledDays}
                        modifiers={modifiers}
                        onDayClick={this.handleDayClick}
                        onDayMouseEnter={this.handleDayMouseEnter}
                      />
                    </div>
                  </div>
                  <div styleName="buttonWrapper">
                    <RaisedButton
                      label="Next"
                      disableTouchRipple
                      disableFocusRipple
                      primary
                      onClick={this.handleNext}
                      disabled={!(from && to)}
                    />
                  </div>
                </StepContent>
              </Step>
              <Step>
                <StepLabel>Assign & manage date groups</StepLabel>
                <StepContent>
                  <div styleName="stepContent">
                    <div styleName="titleContainer">
                      <div styleName="title">Manage Dates</div>
                      <div styleName="subtitle">
                        Select event dates to mark their purpose.{" "}
                        <span styleName="strong">All days must be marked.</span>
                      </div>
                    </div>
                    <div styleName="daySelectorContainer">
                      <div styleName="daySelector">
                        <DaySelector
                          startDate={from}
                          endDate={to}
                          dayMap={this.buildDayMap(from, to, dayGroups)}
                          handleDayClick={this.handleDayGroupDayClick}
                        />
                      </div>
                      <div styleName="markDaysAsDropdownContainer">
                        <MarkDaysAs
                          groups={this.state.settings.dayGroups}
                          selectGroup={this.selectGroup}
                        />
                      </div>
                    </div>
                  </div>
                  {this.renderStepActions(1)}
                </StepContent>
              </Step>
            </Stepper>
            {finished && (
              <p style={{ margin: "20px 0", textAlign: "center" }}>
                Your settings have been saved.{" "}
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ stepIndex: 0, finished: false });
                  }}
                >
                  Click here
                </a>{" "}
                to edit days-on settings.
              </p>
            )}
          </div>
        </Body>
      </Page>
    );
  }
}

DaysOnsite.propTypes = {
  getEvent: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  saveCateringSettings: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired
};

export default DaysOnsite;
