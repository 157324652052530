import React from "react";
import { connect } from "react-redux";

import {
  Div,
  CheckIcon,
  CloseIcon,
  SmallShadedBox,
  LoadingIcon
} from "components/Base";
import { withProps, noop } from "utils/General";
import { addS } from "utils/General";

import {
  getLoadingApproval,
  getResponse
} from "SelectiveApproversLabel/selectors";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

const ApproveIcon = withProps({
  color: "green6",
  pill: true,
  bg: "green1",
  OnlyIcon: CheckIcon
})(SmallShadedBox);

const DenyIcon = withProps({
  color: "red6",
  pill: true,
  bg: "red1",
  OnlyIcon: CloseIcon
})(SmallShadedBox);

const LoadingOptionsIcon = withProps({
  color: "gray5"
})(LoadingIcon);

const MenuItem = ({ action, LeftIcon, label, RightIcon, isLast }) => (
  <Div
    bb={isLast ? 0 : 1}
    bc="gray0"
    onClick={action}
    width={1}
    display="row.flex-start.center"
    py={2}
    px={2}
    fw={3}
    fs={2}
    color="gray7"
    height={45}
    bg={{
      default: "white",
      hover: "neutral0"
    }}
  >
    {LeftIcon ? (
      <Div
        width={24}
        height={24}
        display="row.center.center"
        mr={2}
        style={{
          flexShrink: 0
        }}
      >
        <LeftIcon />
      </Div>
    ) : null}
    <Div flex={1} display="row.flex-start.center">
      {label}
    </Div>
    {RightIcon ? <RightIcon ml={1} /> : null}
  </Div>
);

const mapStateToProps = (state, props) => ({
  response: getResponse(state, { recordIds: props.recordIds }),
  loading: getLoadingApproval(state, { recordIds: props.recordIds }),
  canDo: canEventUserDo(state)
});

const decorate = connect(mapStateToProps);

const ApproversMenu = props => {
  const {
    type,
    recordIds,
    loading = true,
    response,
    closeMenu = noop,
    onApprove = noop,
    onDeny = noop
  } = props;
  const items = [];

  const approvalActionWrapper = fn => () => {
    fn({ type, recordIds });
    closeMenu();
  };

  if (loading) {
    items.push({
      key: "loading",
      label: (
        <Div display="row.center.center" width={1}>
          <LoadingOptionsIcon />
        </Div>
      )
    });
  } else if (response && !response.length) {
    items.push({
      key: "undo",
      label: "You're not an approver for these items"
    });
  } else if (response && response.length) {
    items.push({
      key: "approve",
      label: `Approve ${response.length} item${addS(response.length)}`,
      action: approvalActionWrapper(onApprove),
      LeftIcon: ApproveIcon
    });
    items.push({
      key: "deny",
      label: `Deny ${response.length} item${addS(response.length)}`,
      action: approvalActionWrapper(onDeny),
      LeftIcon: DenyIcon
    });
  }

  return (
    <Div width={190}>
      {items.map(({ Item, key, ...props }, idx) => {
        const isLast = idx === items.length - 1;
        return Item ? (
          <Item key={key} isLast={isLast} />
        ) : (
          <MenuItem key={key} isLast={isLast} {...props} />
        );
      })}
    </Div>
  );
};

export default decorate(ApproversMenu);
