import React, { Component } from "react";
import * as R from "ramda";
import { toClass, findBy, isEmptyOrNil } from "utils/General";
import {
  Div,
  Text3,
  PopoverMenu,
  HorzInfoList,
  MoreVertIcon,
  MediumOutlineButton,
  AddIcon
} from "components/Base";
import toString from "utils/value-types/to-string";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import ViewRecord from "components/Global/Module/Modals/ViewRecord";

const RelatedRecordCard = ({
  title = "",
  info = [],
  moreMenuItems = [],
  onClick,
  ...props
}) => (
  <Div onClick={onClick} bg="white" shadow={2} bra={1} p={2} {...props}>
    <Div display="row.flex-start.center">
      <Text3 flex={1} bold color="primary7">
        {title}
      </Text3>
      {!isEmptyOrNil(moreMenuItems) && (
        <PopoverMenu Label={MoreButton} menuItems={moreMenuItems} />
      )}
    </Div>
    <HorzInfoList info={info} mt={2} />
  </Div>
);

const MoreButton = toClass(({ onClick }) => (
  <MoreVertIcon size={16} onClick={onClick} />
));

class View extends Component {
  componentDidMount() {
    this.getRelatedRecords(this.props);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.relatedModule.id !== this.props.relatedModule.id) {
      this.getRelatedRecords(nextProps);
    }
  }
  getRelatedRecords(props) {
    this.props.getRelatedRecords({
      recordId: props.recordId,
      moduleId: props.relatedModule.id,
      options: {
        eventId: props.eventId,
        valueId: props.recordId,
        valueModuleId: props.moduleId
      }
    });
  }
  showRecordModal = (moduleId, recordId) => {
    this.props.showModal({
      content: (
        <ViewRecord
          moduleId={moduleId}
          recordId={recordId}
          hideModal={() => {
            // This overrides the hideModal fn that is passed in.
            // You can provide any logic here that should happen when
            // the modal closes (ex: refetch activities)
            this.props.hideModal();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };
  render() {
    const {
      relatedModule,
      Icon,
      onClickCreate,
      moreMenuItems = [],
      records,
      references,
      eventDetails,
      ...other
    } = this.props;

    const topThreeFields = R.compose(
      R.take(3),
      R.map(({ field_id }) => ({
        id: field_id,
        name: R.compose(
          R.prop("name"),
          findBy("id", field_id),
          R.pathOr([], ["fields", "fields"])
        )(relatedModule)
      })),
      R.pathOr([], ["fields", "starred_fields"])
    )(relatedModule);

    const topThreeFieldIds = R.map(R.prop("id"))(topThreeFields);

    return (
      <Div p={3} mb={2} bg="neutral0">
        <Div display="row.flex-start.center">
          {Icon && <Icon size={20} />}
          <Text3 bold flex={1}>
            {relatedModule.name}
          </Text3>
          <MediumOutlineButton
            bg="white"
            onClick={() =>
              onClickCreate(records, () => this.getRelatedRecords(this.props))
            }
            LeftIcon={AddIcon}
          >
            Create
          </MediumOutlineButton>
          {!isEmptyOrNil(moreMenuItems) && (
            <PopoverMenu Label={MoreButton} menuItems={moreMenuItems} />
          )}
        </Div>
        <Div>
          {R.addIndex(R.map)((record, i) => {
            const info = R.compose(
              R.map(([k, { value, type }]) => ({
                key: R.compose(R.prop("name"), findBy("id", k))(topThreeFields),
                value: toString(value, type, eventDetails, {
                  fieldId: k,
                  references
                })
              })),
              R.filter(([k]) => R.contains(k, topThreeFieldIds)),
              R.toPairs
            )(record.values);
            return (
              <RelatedRecordCard
                key={i}
                mt={2}
                title={`${record.record_prefix}-${record.friendly_id}`}
                info={info}
                onClick={() =>
                  this.showRecordModal(relatedModule.id, record.id)
                }
              />
            );
          }, records)}
        </Div>
      </Div>
    );
  }
}

export default View;
