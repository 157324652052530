import * as R from "ramda";

import { createSelector } from "reselect";

import { getters } from "EventLight/Agenda/Manage";

import { ACTION_TYPES } from "ui-kit/Table/constants";

import { eventDetails } from "redux/modules/event/selectors";
import { canUserDo } from "redux/modules/permissions/user-permission-profile/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import { ROW_ACTIONS } from "../constants";
import { COLUMN_ACTIONS } from "ui-kit/Table/constants";

const getCanUserDo = R.memoizeWith(getEventId, state =>
  canUserDo(state, getEventId(state))
);

export const getRowActions = createSelector(
  getters.moduleId,
  eventDetails,
  getters.rows,
  getters.references,
  getCanUserDo,
  (moduleId, eventDetails, rows, references, canDo) => {
    const canUpdate = canDo(`${STANDARD_MODULE_IDS.sessions.id}_update`);
    return [
      {
        id: ROW_ACTIONS.EDIT,
        type: ACTION_TYPES.ITEM,
        name: "Edit",
        leftIcon: "edit",
        visible: canUpdate
      },
      {
        id: ROW_ACTIONS.DUPLICATE,
        type: ACTION_TYPES.ITEM,
        name: "Duplicate",
        leftIcon: "content_copy",
        visible: canUpdate
      }
    ].filter(a => a.visible);
  }
);

export const getColumnActions = R.always([
  /*
  {
    id: COLUMN_ACTIONS.EDIT,
    type: ACTION_TYPES.ITEM,
    name: "Edit Column",
    leftIcon: "edit"
  },
  */
  {
    id: COLUMN_ACTIONS.HIDE,
    type: ACTION_TYPES.ITEM,
    name: "Hide Column",
    leftIcon: "remove_red_eye"
  }
  /*
  {
    type: "separator"
  },
  {
    id: COLUMN_ACTIONS.INSERT_LEFT,
    type: ACTION_TYPES.ITEM,
    name: "Insert Left",
    leftIcon: "arrow_back"
  },
  {
    id: COLUMN_ACTIONS.INSERT_RIGHT,
    type: ACTION_TYPES.ITEM,
    name: "Insert Right",
    leftIcon: "arrow_forward"
  }
  */
]);
