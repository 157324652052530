import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { noop } from "utils/General";
import InputGroup from "components/Global-2016/Forms/InputGroup";
import Label from "components/Global-2016/Forms/Label";
import LabelSimpleForm from "components/Event/FormsV2/Sections/LabelSimpleForm/view";

const Input = ({
  containerStyles,
  disabled,
  description,
  label,
  onBlur,
  onChange,
  placeholder,
  required,
  showRequiredMark = true,
  isAdminField,
  type,
  value,
  labelStyle = {},
  canShowSimpleSideBar = false,
  readOnly = false,
  instanceId = null,
  fieldData = null,
  isShown = false,
  setIsShown = noop,
  ...props
}) => (
  <InputGroup styles={containerStyles}>
    {label && label.length ? (
      <>
        {!canShowSimpleSideBar ? (
          <Label
            required={showRequiredMark && required}
            isAdminField={isAdminField}
            description={description}
            style={labelStyle}
          >
            {label}
          </Label>
        ) : (
          <LabelSimpleForm
            isAdminField={isAdminField}
            required={showRequiredMark && required}
            description={description}
            readonly={readOnly}
            instanceId={instanceId}
            fieldData={fieldData}
            isShown={isShown}
            setIsShown={setIsShown}
          >
            {label}
          </LabelSimpleForm>
        )}
      </>
    ) : null}
    <input
      styleName="input"
      type={type || "text"}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      required={required}
      autocomplete={`autocomplete_${type}`} // @NOTE: Use random autocomplete ID so browsers dont have a value cached for it
      {...props}
    />
  </InputGroup>
);

Input.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  isAdminField: PropTypes.bool,
  containerStyles: PropTypes.object,
  type: PropTypes.string,
  autoComplete: PropTypes.string
};

export default CSSModules(Input, css);
