import * as R from "ramda";

export const iniState = {
  fieldsByModule: {},
  visibleFieldsByModule: {},
  fieldOrderByModule: {},
  selectingQuestions: "",
  creatingQuestion: "",
  selectingItemsToShow: "",
  eventShowcaseVariantIds: []
};

export const reducers = {
  getModuleFields: R.identity,
  addEventShowcaseModuleField: R.identity,
  updateEventShowcaseModuleField: R.identity,
  deleteEventShowcaseModuleField: R.identity,
  reorderEventShowcaseModuleFields: R.identity,
  addEventShowcaseVariant: R.identity,
  updateEventShowcaseVariant: R.identity,
  deleteEventShowcaseVariant: R.identity,
  reorderEventShowcaseVariants: R.identity,
  deleteField: R.identity,
  updateFieldDetails: R.identity,
  closeSelectQuestionsModal: R.always({ selectingQuestions: "" }),

  setItemsToShowRequest: R.identity,
  setItemsToShowResponse: R.always({ selectingItemsToShow: "" }),
  closeItemsToShowModal: R.always({ selectingItemsToShow: "" }),

  setVisibleFields: (
    { selectingQuestions, visibleFieldsByModule },
    { payload: visible }
  ) => ({
    visibleFieldsByModule: R.assoc(
      selectingQuestions,
      visible,
      visibleFieldsByModule
    )
  }),
  addQuestionField: (
    { fieldsByModule, visibleFieldsByModule },
    { payload: newField }
  ) => ({
    fieldsByModule: R.assoc(
      newField.module_id,
      R.append(newField, R.propOr([], newField.module_id, fieldsByModule)),
      fieldsByModule
    ),
    visibleFieldsByModule: R.assoc(
      newField.module_id,
      R.append(
        newField.id,
        R.propOr([], newField.module_id, visibleFieldsByModule)
      ),
      visibleFieldsByModule
    )
  })
};
