import React from "react";
import { Div } from "components/Base";
import SelectBox from "./SelectBox";
import InputFieldStatic from "Forms/WizardModal/View/Common/LayoutMock/InputFieldStatic";
import TitleBox from "Forms/WizardModal/View/Common/LayoutMock/TitleBox";
import Button from "Forms/WizardModal/View/Common/LayoutMock/Button";

const SelectOneRequest = () => (
  <SelectBox
    method="single"
    title="Simple Request Form"
    description="Collect asset requests for a single category of equipment or assets in a simple form."
  >
    <Div width={230} display="column.flex-start">
      <Div mb={3}>
        <TitleBox />
        <InputFieldStatic width={230} downIcon />
      </Div>
      <Div mb={3}>
        <TitleBox />
        <InputFieldStatic width={230} />
      </Div>
      <Div mb={3}>
        <Div display="row">
          <TitleBox width={40} />
          <TitleBox width={30} styleProps={{ ml: 1 }} />
        </Div>
        <InputFieldStatic width={230} />
      </Div>
      <Button title="Submit" width={76} />
    </Div>
  </SelectBox>
);

export default SelectOneRequest;
