export const NAMESPACE = "VirtualRecordings";

export const TABLE_INSTANCE_ID = "VirtualRecordingsTable";

export const FORM_ID = "VirtualEventRecordings";

export const FIELD_IDS = {
  TITLE: "title",
  THUMBNAIL_URL: "video_thumbnail_url",
  URL: "video_url",
  VISIBLE: "visible",
  ORDER: "order",
  LABEL: "label"
};

export const DRAGGABLE_IDS = {
  GROUP: "MANAGE_GROUP_FIELDS",
  PEOPLE: "MANAGE_PEOPLE_FIELDS"
};
