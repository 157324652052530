import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../model";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Loading from "ui-kit/Loading";
import Header from "./Header";
import Body from "./Body";
import { withRouter } from "react-router";

const decorate = R.compose(
  withRouter,
  connect(
    state => ({
      loading: getters.loading(state)
    }),
    {
      init: actions.init
    }
  ),
  CSSModules(css)
);

const Layout = ({ init, loading, params }) => {
  useEffect(() => {
    init({
      recordTypeId: params.recordTypeId
    });
  }, []);
  return (
    <div styleName="wrapper">
      {loading ? (
        <Loading />
      ) : (
        <div styleName="content">
          <Header />
          <Body />
        </div>
      )}
    </div>
  );
};

export default decorate(Layout);
