import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  data: {
    name: "",
    slug: "",
    background_image_url: null,
    logo_image_url: null
  }
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    save: R.identity,
    getItemsToShow: R.identity,
    onSelectItemsToShow: R.identity,
    setInitialData: (state, { payload: details }) => ({
      data: details
    }),
    updateField: (state, { payload: { field, value } }) => ({
      data: R.assoc(field, value, state.data)
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
