export const NAMESPACE = "SubscribeModal";

export const MODULE_TABS = [
  {
    id: "json",
    label: "JSON"
  },
  {
    id: "xml",
    label: "XML"
  }
];

export const SCHEDULE_TABS = [
  {
    id: "calendar",
    label: "Calendar"
  },
  {
    id: "json",
    label: "JSON"
  },
  {
    id: "xml",
    label: "XML"
  }
];
