import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const AccountNameFormatter = CSSModules(
  ({ type }) => (
    <div
      styleName="accountIcon"
      style={{ backgroundColor: type.background_color, color: type.text_color }}
    >
      {type.icon ? <i className="material-icons">{type.icon}</i> : ""}
    </div>
  ),
  css
);

export default AccountNameFormatter;
