import React, { Component } from "react";
import * as R from "ramda";
import About from "components/Global/CRM/About";
import View from "components/Global/ProfileView";
import Account from "components/Event/Accounts/Account/ActionTag";
import PrimaryContacts from "components/Event/Accounts/Account/PrimaryContacts";
import EventsTable from "../ContactProfile/EventsTable";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import People from "components/Event/Accounts/Account/People";
import AccountActivities from "Profile/Activities/View";
import RelatedModule from "components/Global/CRM/RelatedModule";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { FILE_FIELDS } from "components/Event/Accounts/Account/constants";

class Controller extends Component {
  getBasePath = () =>
    window.location.pathname.includes("org-light")
      ? `/org-light/${this.props.params.orgId}`
      : `/organization/${this.props.params.orgId}`;

  render() {
    const {
      account,
      addNote,
      deleteAccount,
      deleteNote,
      fetchProfile,
      hideModal,
      name,
      params,
      showModal,
      showViewAllPropertiesModal,
      onRemoveFromEvent,
      updateField,
      updateNote,
      readOnly,
      goToView,
      activeView
    } = this.props;
    const { recordId, orgId } = params;
    const primaryContacts = R.compose(
      R.filter(R.prop("is_primary")),
      R.pathOr([], ["related_contacts"])
    )(account);

    let leftContent = [<Account />, <About />];
    if (!R.isEmpty(account)) {
      leftContent = [
        <Account
          key="contact"
          hideModal={hideModal}
          name={name}
          onDelete={deleteAccount}
          readOnly={readOnly}
          showChangeRecordTypeModal={undefined}
          showModal={showModal}
          // type={account.type || {}}
          type={{}}
        />,
        <About
          key="about"
          {...{
            data: account,
            eventDetails: {},
            name,
            readOnly,
            showStarredFields: true,
            updateField,
            viewAllProperties: showViewAllPropertiesModal
          }}
        />,
        primaryContacts.length ? (
          <PrimaryContacts
            key="primaryContacts"
            accountId={account.id}
            orgId={orgId}
            basePath={`/org-light/${orgId}`}
            contacts={primaryContacts}
            getAccount={fetchProfile}
            readOnly={readOnly}
          />
        ) : null
      ].filter(Boolean);
    }

    const viewProps = {
      leftContent,
      centerContent: R.prop(activeView, {
        overview: [
          <EventsTable
            key="events"
            events={
              account.events
                ? account.events.map(e => ({
                    id: e.id,
                    type: e.record_type.name,
                    name: e.name,
                    onRemove: () => onRemoveFromEvent(e.id)
                  }))
                : []
            }
          />,
          <People
            key="people"
            users={account.users}
            contacts={account.related_contacts}
            accountName={name}
            getAccount={fetchProfile}
            params={params}
          />,
          <RelatedModule
            module={{
              ...STANDARD_MODULE_IDS.files,
              event_id: params.eventId,
              preferences: {
                visible_fields: FILE_FIELDS.map(f => f.id),
                field_order: FILE_FIELDS.reduce((map, f, idx) => {
                  map[f.id] = idx;
                  return map;
                }, {})
              },
              fields: {
                fields: FILE_FIELDS
              }
            }}
            record={{
              id: recordId,
              name,
              valueName: "accountId",
              moduleId: STANDARD_MODULE_IDS.accounts.id,
              moduleInternalName: "accounts",
              values: []
            }}
          />
        ],
        activity: <AccountActivities accountId={recordId} orgId={orgId} />
      }),
      rightContent: [],
      tabs: [
        {
          name: "Overview",
          onClick: () => goToView(""),
          active: activeView === "overview"
        }
        /*
        {
          name: "Activity",
          onClick: () => goToView("/activity"),
          active: activeView === "activity"
        }
        */
      ],
      showNotes: activeView === "overview",
      notes:
        account.notes && account.notes.length
          ? account.notes.map(n => ({
              note: n,
              authorUrl: `${this.getBasePath()}/contact/${n.author_contact_id}`,
              title: name || "account",
              onUpdate: note => updateNote(note, n.id),
              onDelete: () => deleteNote(n.id)
            }))
          : [],
      onAddNote: addNote,
      returnToPath: window.location.pathname.includes("org-light")
        ? `/org-light/${orgId}/groups/manage/${STANDARD_MODULES.accounts.id}`
        : `/organization/${orgId}/module/${STANDARD_MODULES.accounts.id}`,
      returnToText: "Back to Groups"
    };

    return <View {...viewProps} />;
  }
}

Controller.propTypes = {};

export default Controller;
