import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";
import { withRouter } from "react-router";

import TicketingProviderActions from "redux/modules/ticketing/providers/actions";

import { user } from "redux/modules/user/selectors";
import { eventDetails } from "redux/modules/event/selectors";

function mapStateToProps(state) {
  return {
    user: user(state),
    eventDetails: eventDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getProviderConfigurationSettings:
        TicketingProviderActions.getProviderConfigurationSettings,
      updateProviderConfigurationSettings:
        TicketingProviderActions.updateProviderConfigurationSettings
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
