import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Search from "./Search";
import { searchTerm } from "redux/modules/modules/records/settings/selectors";
import { updateSearch } from "redux/modules/modules/records/settings/actions";

function mapStateToProps(state, props) {
  return {
    placeholder: "Search...",
    value: searchTerm(state, props.moduleId)
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      onChange: query =>
        updateSearch({ moduleId: props.moduleId, searchTerm: query })
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);
