import * as R from "ramda";
import {
  put,
  takeEvery,
  all,
  fork,
  take,
  select,
  call
} from "redux-saga/effects";
import { actions, getters } from "./model";

import { registerError } from "redux/modules/errors/actions";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import { showSnackbar } from "redux/modules/snackbar/actions";

import Api from "./api";

import { LABELS } from "utils/item-types";

const getParams = function*() {
  const eventDetails = yield select(getEventDetails);
  const credentials = yield select(getCredentials);
  const recordTypeId = yield select(getters.recordTypeId);

  return { eventDetails, credentials, recordTypeId };
};

const loadPortalDetails = function*() {
  try {
    const params = yield call(getParams);
    const { payload } = yield call(Api.getPermissionSet, params.credentials, {
      eventId: params.eventDetails.id,
      recordTypeId: params.recordTypeId
    });

    if (!payload) {
      return yield put(actions.setHasPortal(false));
    }

    const itemBlocks = payload.all_item_blocks;
    const permissionSet = payload.permission_set;

    yield put(
      actions.setData({
        hasPortal: true,
        selectedTypeName: payload.name,
        permissionProfileId: permissionSet.id,
        isAccountPortal: payload.is_account_portal,
        people: payload.people_types.map(type => {
          const currentType =
            R.find(R.propEq("record_type_id", type.id))(
              R.pathOr([], ["types"], permissionSet)
            ) || {};

          return {
            active: currentType.is_enabled,
            countOfFieldsSelected:
              currentType && currentType.visible_fields
                ? currentType.visible_fields.length
                : 0,
            availableItems: itemBlocks.length
              ? permissionSet.item_blocks
                  .filter(i => i.record_type_id === type.id)
                  .map(i => {
                    const block = itemBlocks.find(b => b.id === i.block_id);
                    return {
                      id: i.id,
                      name: block.name,
                      quantity: block.items.length,
                      type: LABELS[block.item_type_id].label
                    };
                  })
              : [],
            backgroundColor: type.background_color || "lightgrey",
            canRequestItems: currentType.can_request_items,
            icon: type.icon,
            id: type.id,
            name: type.name,

            textColor: type.text_color || "black"
          };
        }),
        intakeForm: permissionSet.intake_form,
        intakeFormUrl:
          permissionSet.intake_form && permissionSet.intake_form.id
            ? `${window.__LENND_APP_URL__}/intake/${params.eventDetails.slug}/${permissionSet.intake_form.id}`
            : null,
        closeDate: permissionSet.close_date,
        isOpen: !permissionSet.is_locked,
        isLocked: permissionSet.is_locked
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while loading portal details"
        }
      ])
    );
  }
};

const addPermissionSet = function*() {
  try {
    yield put(actions.setAdding(true));
    const params = yield call(getParams);
    yield call(Api.addPermissionSet, params.credentials, {
      eventId: params.eventDetails.id,
      recordTypeId: params.recordTypeId
    });

    yield call(loadPortalDetails);
    yield put(actions.setAdding(false));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred adding portal"
        }
      ])
    );
  }
};

const init = function*({ payload: { recordTypeId } }) {
  yield put(actions.setRecordTypeId(recordTypeId));
  yield call(loadPortalDetails);
  yield put(actions.setLoading(false));
};

const watchAddPermissionSet = function*() {
  yield takeEvery(actions.addPermissionSet.type, addPermissionSet);
};

const watchRefreshData = function*() {
  yield takeEvery(actions.refreshData.type, loadPortalDetails);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchRefreshData),
    fork(watchAddPermissionSet)
  ]);
};

export default rootSaga;
