import React from "react";
import CSSModules from "react-css-modules";

import css from "./styles.scss";

export const ErrorMark = CSSModules(
  ({ valid = true, description = "", ...styleProps }) =>
    valid ? null : (
      <div styleName="wrapper" {...styleProps}>
        <div styleName="tooltip-text">{description}</div>
        <span styleName="icon" className="material-icons">
          error
        </span>
      </div>
    ),
  css
);
