import React from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import {
  Div,
  BigFilledButton,
  Span,
  Dropdown,
  LoadingIcon
} from "components/Base";

const MapFormatter = ({ value, onChange, values }) => {
  const toIterateOn = value.keys.values || value.keys;
  return toIterateOn.map(key => {
    return (
      <Div key={key} display="row.flex-start.center" width={1} mb={1}>
        <Div color="gray7" width={100}>
          {key.label}
        </Div>

        <Div ml={3} width={200}>
          <Dropdown
            selected={values[key.value]}
            onChange={value => {
              onChange(key.value, value.value);
            }}
            options={value.selectValue.values.map(sValue => ({
              value: sValue.value,
              label: sValue.label
            }))}
          />
        </Div>
      </Div>
    );
  });
};

const View = ({
  saving,
  loading,
  hideModal,
  fields,
  onSave,
  contactSupport
}) => (
  <StyleWrapper
    heading="Integration Settings"
    hideModal={hideModal}
    width={550}
  >
    <Div mb={3}>
      {loading ? (
        <Div display="row.center.center" my={5}>
          <LoadingIcon size={30} />
        </Div>
      ) : (
        <Div>
          {fields.map(group => (
            <Div key={group.name} mb={4}>
              <Div fw={3} bc="gray1" color="gray7" bb={1} pb={2} mb={2}>
                {group.name}
              </Div>
              {group.fields.map(field => (
                <Div key={field.id} mb={2}>
                  <Div mb={1} fw={3} color="gray7">
                    {field.label}
                  </Div>
                  <MapFormatter {...field} />
                </Div>
              ))}
            </Div>
          ))}
        </Div>
      )}

      <Div display="row.space-between.center" bt={1} bc="gray2" pt={3} mt={5}>
        <BigFilledButton
          bg="altB5"
          color="white"
          onClick={onSave}
          disabled={saving || loading}
        >
          {saving ? "Saving..." : "Save"}
        </BigFilledButton>
        <Div fs={2} color="gray5">
          Need help? You can{" "}
          <Span onClick={contactSupport} underline>
            contact us
          </Span>{" "}
          at anytime.
        </Div>
      </Div>
    </Div>
  </StyleWrapper>
);

export default View;
