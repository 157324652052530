import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";
import { withRouter } from "react-router";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { hideModal, showModal } from "redux/modules/modal/actions";
import {
  getItemGroupsByEventAndType,
  addItemGroup,
  updateItemGroup,
  bulkUpdateItemGroups,
  deleteItemGroup
} from "redux/modules/items/item-groups/actions";
import {
  getItemBlocksByEventAndType,
  addItemBlock
} from "redux/modules/items/item-blocks/actions";
import {
  addItem,
  updateItem,
  bulkUpdateItems,
  deleteItem
} from "redux/modules/items/items/actions";

import { eventDetails } from "redux/modules/event/selectors";
import { groups } from "redux/modules/items/item-groups/selectors";
import { itemBlocks } from "redux/modules/items/item-blocks/selectors";

function mapStateToProps(state) {
  const itemGroupsToPass = groups(state).filter(
    g => g.type_id === CREDENTIAL_TYPE_ID
  );
  const itemBlocksToPass = itemBlocks(state).filter(
    g => g.item_type_id === CREDENTIAL_TYPE_ID
  );

  return {
    eventDetails: eventDetails(state),
    items: itemGroupsToPass.reduce((a, b) => [...a, ...b.items], []),
    itemGroups: itemGroupsToPass,
    itemBlocks: itemBlocksToPass
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getItemBlocksByEventAndType,
      addItemBlock,
      getItemGroupsByEventAndType,
      addItemGroup,
      updateItemGroup,
      bulkUpdateItemGroups,
      deleteItemGroup,
      addItem,
      updateItem,
      bulkUpdateItems,
      deleteItem,
      showSnackbar,
      showModal,
      hideModal
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
