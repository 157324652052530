import React from "react";

import { connect } from "react-redux";

import { Div, Span, MoreVertIcon, PopoverMenu } from "components/Base";
import moment from "moment";

import { getters, actions } from "../index";

const decorate = connect(
  state => ({
    templates: getters.templates(state)
  }),
  {
    createTemplate: actions.createTemplate,
    copyTemplate: actions.copyTemplate,
    goToReports: actions.goToReports
  }
);

const MoreVertButton = ({ onClick }) => (
  <MoreVertIcon onClick={onClick} ml={2} size={20} />
);

const Layout = ({
  templates,
  createTemplate,
  copyTemplate,
  goToReports,
  handlers
}) => (
  <Div
    width={800}
    height={1}
    p={3}
    pl={6}
    style={{
      paddingBottom: 100
    }}
  >
    {!templates.length ? (
      <Div
        display="row.flex-start.center"
        p={6}
        mt={3}
        bg="neutral1"
        fs={3}
        color="gray7"
      >
        No templates have been created.{" "}
        <Span
          color="primary7"
          fw={3}
          mx={1}
          onClick={() =>
            createTemplate({
              onDone: handlers.showEditReportReportTemplateModal
            })
          }
        >
          Click here
        </Span>{" "}
        to create one.
      </Div>
    ) : (
      templates.map(templateGroup => (
        <Div display="column" p={6} mt={3} bg="neutral1" fs={3} color="gray7">
          {templateGroup.reports.map(template => (
            <Div
              display="column"
              mb={3}
              shadow={{
                default: 1,
                hover: 2
              }}
              bra={1}
              bg="white"
              p={3}
              onClick={() =>
                handlers.showEditReportReportTemplateModal(template.id)
              }
            >
              <Div display="row.space-between" mb={2}>
                <Div fw={3} color="gray7" fs={3}>
                  {template.name}
                </Div>
                <Div>
                  <PopoverMenu
                    Label={MoreVertButton}
                    menuItems={[
                      [
                        "Open",
                        () =>
                          handlers.showEditReportReportTemplateModal(
                            template.id
                          )
                      ],
                      ["Duplicate", () => copyTemplate(template.id)],
                      [
                        "Delete",
                        () =>
                          handlers.showDeleteReportTemplateModal(template.id)
                      ]
                    ]}
                  />
                </Div>
              </Div>
              <Div fs={2} color="gray5">
                Created by{" "}
                {[
                  template.created_by.first_name,
                  template.created_by.last_name
                ].join(" ")}{" "}
                &middot;{" "}
                {template.created_at === template.updated_at ? (
                  <span>Created {moment(template.created_at).fromNow()}</span>
                ) : (
                  <span>Updated {moment(template.updated_at).fromNow()}</span>
                )}
              </Div>
            </Div>
          ))}
          <Div display="row.flex-start.center" mt={3}>
            <Div
              color={{
                default: "primary7",
                hover: "primary5"
              }}
              fw={4}
              mr={5}
              onClick={() =>
                createTemplate({
                  onDone: handlers.showEditReportReportTemplateModal
                })
              }
            >
              Create new template
            </Div>
            <Div
              color={{
                default: "primary7",
                hover: "primary5"
              }}
              fw={4}
              onClick={goToReports}
            >
              Go to reports
            </Div>
          </Div>
        </Div>
      ))
    )}
  </Div>
);

export default decorate(Layout);
