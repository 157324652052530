/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";
import getQuery from "redux/modules/modules/utils/get-query";

export default {
  getItemTypes: ({ credentials, eventId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/items/types/event/${eventId}`,
        credentials,
        success,
        error
      });
    }),
  getPackages: ({ credentials, eventId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/items/items/event/${eventId}?packages=true`,
        credentials,
        success,
        error
      });
    }),
  createPackage: ({ credentials, item }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/items/items`,
        credentials,
        data: { item },
        success,
        error
      });
    }),
  deletePackage: ({ credentials, itemId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/items/items`,
        credentials,
        data: { itemId },
        success,
        error
      });
    }),
  updatePackage: ({ credentials, item }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/items/items/${item.id}`,
        credentials,
        data: { item },
        success,
        error
      });
    }),
  //
  getEventShowcase: ({ credentials, eventId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/event/${eventId}/event-showcase`,
        credentials,
        success,
        error
      });
    }),
  updateEventShowcase: ({ credentials, eventId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/event/${eventId}/event-showcase`,
        credentials,
        data,
        success,
        error
      });
    }),
  //
  addEventShowcasePhoto: ({ credentials, eventId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/event/${eventId}/event-showcase-photos`,
        credentials,
        data,
        success,
        error
      });
    }),
  updateEventShowcasePhoto: ({ credentials, eventId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/event/${eventId}/event-showcase-photos`,
        credentials,
        data,
        success,
        error
      });
    }),
  deleteEventShowcasePhoto: ({ credentials, eventId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/event/${eventId}/event-showcase-photos`,
        credentials,
        data,
        success,
        error
      });
    }),
  //
  addEventShowcaseFile: ({ credentials, eventId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/event/${eventId}/event-showcase-files`,
        credentials,
        data,
        success,
        error
      });
    }),
  updateEventShowcaseFile: ({ credentials, eventId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/event/${eventId}/event-showcase-files`,
        credentials,
        data,
        success,
        error
      });
    }),
  deleteEventShowcaseFile: ({ credentials, eventId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/event/${eventId}/event-showcase-files`,
        credentials,
        data,
        success,
        error
      });
    }),
  //
  addEventShowcaseField: ({ credentials, eventId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/event/${eventId}/event-showcase-fields`,
        credentials,
        data,
        success,
        error
      });
    }),
  updateEventShowcaseField: ({ credentials, eventId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/event/${eventId}/event-showcase-fields`,
        credentials,
        data,
        success,
        error
      });
    }),
  deleteEventShowcaseField: ({ credentials, eventId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/event/${eventId}/event-showcase-fields`,
        credentials,
        data,
        success,
        error
      });
    }),
  //
  addEventShowcaseVariant: ({ credentials, eventId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/event/${eventId}/event-showcase-variants`,
        credentials,
        data,
        success,
        error
      });
    }),
  updateEventShowcaseVariant: ({ credentials, eventId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/event/${eventId}/event-showcase-variants`,
        credentials,
        data,
        success,
        error
      });
    }),
  deleteEventShowcaseVariant: ({ credentials, eventId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/event/${eventId}/event-showcase-variants`,
        credentials,
        data,
        success,
        error
      });
    }),
  //
  getModuleFields: ({ credentials, moduleId, eventId }) =>
    new Promise((success, error) => {
      const query = getQuery({ eventId });

      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/modules/${moduleId}/fields${query || ""}`,
        credentials,
        success,
        error
      });
    }),
  updatePackageItems: ({ credentials, data }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/items/item-package-variants`,
        credentials,
        data,
        success,
        error
      });
    }),
  addItemGroup: ({ credentials, data }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/items/groups`,
        credentials,
        data,
        success,
        error
      });
    }),
  deleteModuleField: ({ credentials, data }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery(data.options);

      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/modules/${
          data.moduleId
        }/fields${query || ""}`,
        credentials,
        data,
        success,
        error
      });
    })
  /* eslint-enable no-underscore-dangle */
};
