import * as R from "ramda";
import { createHandlers } from "redux-mvc";

import { NAMESPACE, WIZARD_PAGE } from "Orders/ImportModal/constants";
import * as Customize from "./customizeDownload";
import * as SelectItems from "./selectItems";
import * as ReviewImport from "./reviewImport";
import * as ImportConfirmation from "./confirmImport";

const iniState = {
  wizardPage: WIZARD_PAGE.IMPORT,
  moduleId: 0,
  fieldsMetadata: [],
  ...SelectItems.iniState,
  ...Customize.iniState,
  ...ReviewImport.iniState,
  ...ImportConfirmation.iniState
};

const handlers = createHandlers({
  iniState,
  reducers: {
    ...SelectItems.reducers,
    ...Customize.reducers,
    ...ReviewImport.reducers,
    ...ImportConfirmation.reducers,
    cancel: ({ fieldsMetadata }) => ({
      ...iniState,
      fieldsMetadata
    }),
    uploadResolved: R.identity
  },
  namespace: NAMESPACE
});

export default handlers;
