import PropTypes from "prop-types";
import React from "react";
import {
  Submit,
  ButtonOutline,
  ButtonGroup
} from "components/Global/Modal/Layout/Buttons";

const ActionButtonGroup = ({
  giveLoginAccess,
  sendNotification,
  hideModal
}) => (
  <ButtonGroup>
    <Submit
      key="action"
      title={giveLoginAccess && sendNotification ? "Invite" : "Add"}
    />
    <ButtonOutline key="cancel" title="Cancel" onClick={hideModal} />
  </ButtonGroup>
);

ActionButtonGroup.propTypes = {
  hideModal: PropTypes.func.isRequired,
  sendNotification: PropTypes.bool.isRequired,
  giveLoginAccess: PropTypes.bool.isRequired
};

export default ActionButtonGroup;
