import React from "react";
import { connect } from "react-redux";

import { getters } from "Items/BulkQuestionItemsModal";

import Loading from "ui-kit/Loading";

import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";

import Modal from "components/Global/Modal/Layout/StyleWrapper";
import { Div } from "components/Base";

const decorate = connect(state => ({
  loading: getters.loading(state)
}));

const Layout = ({ hideModal, loading, handlers }) => {
  return (
    <Modal width={743} hideHeader bodyStyles={{ padding: 0 }}>
      {loading ? (
        <Loading />
      ) : (
        <Div width={1} height={1}>
          <Header hideModal={hideModal} />
          <Body handlers={handlers} />
          <Footer hideModal={hideModal} />
        </Div>
      )}
    </Modal>
  );
};

export default decorate(Layout);
