import React from "react";
import * as R from "ramda";

import {
  Div,
  Text3,
  Text6,
  DarkTab,
  FontIcon,
  BigOutlineButton,
  BigFilledButton,
  CollapsablePanelType1,
  Text5,
  BigShadedInput,
  SearchIcon
} from "components/Base";

import CSSModules from "react-css-modules";
import css from "components/Event/Settings/Layout/Tables/styles.css";

import ItemsTable from "./Tables/Items";

const addS = thing => (thing === 1 ? "" : "s");

const Locations = ({
  locations,
  onSearch,
  showAddLocationAreaModal,
  showAddLocationModal
}) => (
  <Div display="row" px={8}>
    <Div
      width={2 / 3}
      pr={3}
      py={4}
      br={1}
      bc="gray3"
      style={{ maxWidth: 800 }}
    >
      <Div display="row.flex-start.center" pb={2}>
        <BigShadedInput
          mr={3}
          flex={1}
          placeholder="Search items"
          onChange={onSearch}
          continuous
          LeftIcon={SearchIcon}
        />
        <BigOutlineButton mr={1} onClick={showAddLocationModal}>
          Create location
        </BigOutlineButton>
        <BigFilledButton
          bg="altB5"
          color="white"
          onClick={() => showAddLocationAreaModal()}
        >
          Add area
        </BigFilledButton>
      </Div>
      <Div pt={4}>
        {locations.map(
          ({
            id,
            name,
            countOfItems,
            onAddItem,
            onEdit,
            onDelete,
            items,
            moveGroupUp,
            moveGroupDown,
            collapsedDefault,
            forceCollapse
          }) => (
            <CollapsablePanelType1
              key={`${id}_${countOfItems}`}
              headingText={name}
              subText={`${countOfItems} item${addS(countOfItems)}`}
              menuItems={[
                ["Add item", onAddItem],
                ["Edit", onEdit],
                onDelete ? ["Delete", onDelete] : undefined
              ].filter(i => i)}
              forceCollapse={forceCollapse}
              collapsedDefault={collapsedDefault}
            >
              <ItemsTable items={items} />
            </CollapsablePanelType1>
          )
        )}
      </Div>
    </Div>
  </Div>
);

const View = CSSModules(
  ({
    tabs,
    activeTabValue,
    locations,
    onSearch,
    showAddLocationAreaModal,
    showAddLocationModal
  }) => (
    <Div style={{ minHeight: "100%" }}>
      <Div bb={1} bc="gray3" bg="white" display="row.flex-start.flex-end">
        <Div flex={1}>
          <Text6 pl={8} pt={6}>
            Locations
          </Text6>

          <Div pt={3} pl={8}>
            {R.map(([routeName, tabName]) => {
              const active = R.equals(activeTabValue)(routeName);
              return (
                <DarkTab
                  key={tabName}
                  px={2}
                  fs={3}
                  active={active}
                  onClick={!active ? () => setTabValue(routeName) : undefined}
                >
                  {tabName}
                </DarkTab>
              );
            })(tabs)}
          </Div>
        </Div>
      </Div>
      <Div style={{ minHeight: "100%" }} pb={14}>
        {R.prop(activeTabValue, {
          editEventSettingsLocations: (
            <Locations
              {...{
                locations,
                onSearch,
                showAddLocationModal,
                showAddLocationAreaModal
              }}
            />
          )
        })}
      </Div>
    </Div>
  ),
  css
);

export default View;
