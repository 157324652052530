import React from "react";
import SubNav from "./SubNav";
import { createSelector } from "reselect";
import * as R from "ramda";
import { connect } from "react-redux";
import { TAB_IDS } from "./constants";
import { withRouter } from "react-router";
import PageTitle from "EventLight/Common/PageTitle";

// import Dashboard from "./Dashboard/View";
import Requests from "./Requests/View";
// import Settings from "./Settings/View";

export const getCurrentRoute = createSelector(
  (_, props) => props.routes,
  routes => {
    if (R.any(R.propEq("name", "EventLightBoothsDashboard"))(routes)) {
      return TAB_IDS.DASHBOARD;
    } else if (R.any(R.propEq("name", "EventLightBoothsRequests"))(routes)) {
      return TAB_IDS.REQUESTS;
    } else if (R.any(R.propEq("name", "EventLightBoothsSettings"))(routes)) {
      return TAB_IDS.SETTINGS;
    }
    return null;
  }
);

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    currentRoute: getCurrentRoute(state, props)
  }))
);

const Wrapper = ({ currentRoute }) => {
  const Page = R.prop(currentRoute)({
    // [TAB_IDS.DASHBOARD]: Dashboard,
    [TAB_IDS.REQUESTS]: Requests
    // [TAB_IDS.SETTINGS]: Settings
  });

  return (
    <>
      <PageTitle titles={["Booths"]} />
      <SubNav selectedView={currentRoute} />
      <Page />
    </>
  );
};

export default decorate(Wrapper);
