import React from "react";
import Select from "@lennd/react-select";
import * as R from "ramda";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import {
  getFileOptions,
  getSelectedFileOptions,
  getIsAllowMultiUpload
} from "Modules/AddEditColumnModal/selectors";
import { actions } from "Modules/AddEditColumnModal/model";
import css from "./style.scss";
import Checkbox from "material-ui/Checkbox";
import { InfoIcon } from "components/Base";

const decorate = R.compose(
  connect(
    (state, props) => ({
      options: getFileOptions(state, props),
      selectedOptions: getSelectedFileOptions(state, props),
      allowMultiUpload: getIsAllowMultiUpload(state, props)
    }),
    {
      updateFileSettings: actions.updateFileSettings
    }
  ),
  CSSModules(css)
);

const FileSettings = ({
  options,
  selectedOptions,
  updateFileSettings,
  allowMultiUpload
}) => (
  <div styleName="dropdownOptionsWrapper">
    <div className="input-wrapper">
      <label styleName="formLabel">Accepted File Types (Optional)</label>
      <Select
        isMulti={true}
        onChange={value => {
          updateFileSettings({ field: "allowedFileTypes", value });
        }}
        value={selectedOptions}
        options={options}
      />
      <div
        styleName="fileCheckbox"
        onClick={() =>
          updateFileSettings({
            field: "allowMultiple",
            value: !allowMultiUpload
          })
        }
      >
        <Checkbox checked={allowMultiUpload} style={{ width: 30, zIndex: 0 }} />
        <div>Accept multiple files</div>
        <InfoIcon
          ml={1}
          tooltip="Allow users to upload more than one file to this field"
          color="gray5"
          size={16}
        />
      </div>
    </div>
  </div>
);

export default decorate(FileSettings);
