import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { getters, actions } from "Orders/TicketsPasses";
import {
  Div,
  MediumOutlineInput,
  DragIcon,
  DeleteIcon,
  Cards,
  Dropdown
} from "components/Base";
import { withState } from "utils/General";
import UserProfilePopOver from "./UserProfilePopOver";
import { PASSES_COLUMNS_WIDTH } from "Orders/TicketsPasses/constants";
import PriceSelectorPopOver from "./PriceSelectorPopOver";
import DeleteConfirmation from "components/Global/CRM/Popovers/DeleteConfirmation";
import { getFilterPass } from "Orders/TicketsPasses/selectors";

const withWhoHasAccess = withState("whoHasAccess", "setWhoHasAccess", []);

const decorate = R.compose(
  withWhoHasAccess,
  connect(
    state => ({
      data: getFilterPass(state),
      categoriesList: getters.categoriesList(state),
      onBlurEnabled: getters.onBlurEnabled(state)
    }),
    {
      setPassName: actions.updateItemName,
      updateWhoHasAccess: actions.updateWhoHasAccess,
      removePass: actions.updateRemovePass,
      setPassCategory: actions.updateItemCategory,
      reorderItems: actions.reorderItems
    }
  )
);

const PassesRow = ({
  sort_order: id,
  name,
  setPassName,
  category,
  prices,
  who_has_access,
  setWhoHasAccess,
  whoHasAccess,
  updateWhoHasAccess,
  removePass,
  setPassCategory,
  categoriesList,
  onBlurEnabled
}) => (
  <Div
    width={1}
    display="row.space-between.center"
    bg="white"
    bra={1}
    shadow={1}
    height={54}
    px={2}
    style={{ overflow: "visible" }}
    mt={1}
  >
    <Div width={PASSES_COLUMNS_WIDTH.NAME} display="row.space-between.center">
      <DragIcon size={20} />
      <MediumOutlineInput
        value={name}
        continuous={!onBlurEnabled}
        onChange={value => {
          setPassName({ id, name: value });
        }}
        width={316}
      />
    </Div>
    <Div width={PASSES_COLUMNS_WIDTH.CATEGORY}>
      <Dropdown
        options={R.map(
          ({ tempId, name }) => ({ value: tempId, label: name }),
          categoriesList
        )}
        selected={R.propOr("", "tempId", category)}
        onChange={({ value }) => {
          setPassCategory({ passId: id, categoryId: value });
        }}
      />
    </Div>
    <PriceSelectorPopOver id={id} prices={prices} />
    <UserProfilePopOver
      who_has_access={who_has_access}
      whoHasAccess={whoHasAccess}
      setWhoHasAccess={setWhoHasAccess}
      id={id}
      updateWhoHasAccess={updateWhoHasAccess}
    />
    <Div width={PASSES_COLUMNS_WIDTH.ACTIONS} display="row.flex-end.center">
      <Div width={50} display="row.space-between.center">
        <DeleteConfirmation
          onConfirm={() => {
            removePass({ id });
          }}
          text="Are you sure you want to delete this item?"
        >
          <DeleteIcon
            size={20}
            color={{ default: "neutral4", hover: "primary7" }}
            transition="fast"
            style={{ cursor: "pointer" }}
          />
        </DeleteConfirmation>
      </Div>
    </Div>
  </Div>
);

const CardPassesRows = Cards(PassesRow, "PASSES_CARD_TYPE");

const CardPasses = props => (
  <Div width={1} mb={3}>
    <CardPassesRows
      cards={props.data}
      onReorder={orderedItems => {
        props.reorderItems({ orderedItems });
      }}
      {...props}
    />
  </Div>
);

export default decorate(CardPasses);
