import { put, all, takeEvery, fork, call } from "redux-saga/effects";
import Helpers from "utils/Global/Helpers";
import { actions } from "Portal/Settings/AddVideoLinkModal/model";

const getFilePicker = () =>
  new Promise(resolve => {
    const options = {
      multiple: false,
      accept: [
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/svg+xml",
        "image/png"
      ]
    };

    const path = { path: "portal-page-link/" };

    Helpers.getFilepicker(options, path, resolve);
  });

const uploadThumbnailImage = function*() {
  const files = yield call(getFilePicker);
  const url = files[0].url;

  yield put(actions.setThumbnailImageUrl(url));
};

const watchUploadThumbnailImage = function*() {
  yield takeEvery(actions.uploadThumbnailImage.type, uploadThumbnailImage);
};

const rootSaga = function*() {
  yield all([fork(watchUploadThumbnailImage)]);
};

export default rootSaga;
