import React, { Component } from "react";
import Divider from "material-ui/Divider";
import CSSModules from "react-css-modules";
import css from "../styles.scss";

const Menu = CSSModules(
  ({ children }) => <div styleName="menu">{children}</div>,
  css
);
const MenuItem = CSSModules(
  ({ onClick, children }) => (
    <div role="link" tabIndex="0" styleName="menuItem" onClick={onClick}>
      {children}
    </div>
  ),
  css
);

@CSSModules(css)
class ReportMenu extends Component {
  wrapAction(action) {
    const { close } = this.props;
    return () => {
      action();
      close();
    };
  }

  render() {
    const { exportXLSX, exportCSV, print } = this.props;

    return (
      <Menu>
        {exportXLSX ? (
          <MenuItem onClick={this.wrapAction(exportXLSX)}>
            <i className="material-icons" styleName="menuItemIcon">
              file_download
            </i>
            <span>Export .XLSX</span>
          </MenuItem>
        ) : null}

        {exportCSV ? (
          <MenuItem onClick={this.wrapAction(exportCSV)}>
            <i className="material-icons" styleName="menuItemIcon">
              file_download
            </i>
            <span>Export .CSV</span>
          </MenuItem>
        ) : null}

        {print ? (
          <MenuItem onClick={this.wrapAction(print)}>
            <i className="material-icons" styleName="menuItemIcon">
              print
            </i>
            <span>Print</span>
          </MenuItem>
        ) : null}
        {/*
        <Divider />
        <MenuItem onClick={this.wrapAction(manageFields)}>
          <i className="material-icons" styleName="menuItemIcon">
            settings
          </i>
          <span>Settings</span>
        </MenuItem>
        */}
      </Menu>
    );
  }
}

export default ReportMenu;
