import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { Div } from "components/Base";
import Header from "./Header";
import { withRouter } from "react-router";
import { TABS } from "Portal/Manage/constants";
import { createSelector } from "reselect";

import List from "Portal/List/View";
import Pages from "Portal/Settings/Page/View";
import Alerts from "Portal/Alerts/Manage/View";
import Content from "Portal/Content/View";

export const getCurrentRoute = createSelector(
  (_, props) => props.routes,
  routes => {
    if (R.any(R.propEq("name", "PortalList"))(routes)) {
      return TABS.PORTALS;
    } else if (R.any(R.propEq("name", "PortalPages"))(routes)) {
      return TABS.PAGES;
    } else if (R.any(R.propEq("name", "PortalAlerts"))(routes)) {
      return TABS.ALERTS;
    } else if (R.any(R.propEq("name", "PortalContent"))(routes)) {
      return TABS.CONTENT;
    }
    return null;
  }
);

const decorate = connect((state, props) => ({
  currentRoute: getCurrentRoute(state, props)
}));

const Layout = ({ currentRoute }) => (
  <Div>
    <Header currentRoute={currentRoute} />
    {R.prop(currentRoute)({
      [TABS.PORTALS]: <List />,
      [TABS.PAGES]: <Pages />,
      [TABS.ALERTS]: <Alerts />,
      [TABS.CONTENT]: <Content />
    })}
  </Div>
);

export default R.compose(
  withRouter,
  decorate
)(Layout);
