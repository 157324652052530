import React from "react";
import * as R from "ramda";
import { Div } from "components/Base";
import { withRouter } from "react-router";

import { connect } from "react-redux";
import { getFiltersOn, getColumnActions, getRowActions } from "../selectors";
import { actions, getters } from "Files/Manage";
import { TABLE_INSTANCE_ID } from "Files/Manage/constants";

import Toolbar from "./Toolbar";
import FiltersOn from "ui-kit/FiltersOn";

import ShowNoResults from "ui-kit/ShowNoResults";

import Table, { TableWrapper } from "ui-kit/Table/View";

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      filtersOn: getFiltersOn(state, props),
      loading: getters.loading(state, props),
      showNoResults: getters.showNoResults(state, props),
      preferences: getters.preferences(state, props),
      columnActions: getColumnActions(state, props),
      rowActions: getRowActions(state, props)
    }),
    {
      onClearFilters: actions.clearFilters
    }
  )
);

const Body = ({
  handlers,
  filtersOn,
  onClearFilters,
  rowActions = [],
  loading = false,
  showNoResults = false
}) => (
  <Div flex={1} display="column.flex-start.stretch" height={1}>
    <Toolbar handlers={handlers} />
    <FiltersOn
      on={filtersOn}
      onClearFilters={onClearFilters}
      caption="Only showing results that contain or match filters"
    />
    {showNoResults ? (
      <ShowNoResults flex={1} />
    ) : (
      <TableWrapper
        loading={loading}
        style={{ flex: 1 }}
        instanceId={TABLE_INSTANCE_ID}
      >
        <Table
          instanceId={TABLE_INSTANCE_ID}
          rowActions={rowActions}
          showCheckbox={false}
        />
      </TableWrapper>
    )}
  </Div>
);

export default decorate(Body);
