import React, { Component } from "react";
import * as R from "ramda";
import { Div } from "components/Base";
import ModalContainer from "../ModalContainer";
import { approvalsDict } from "../approvalsDict";
import { isEmptyOrNil } from "utils/General";

const lifecycle = Comp => {
  class Wrapper extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true
      };
    }
    componentDidMount() {
      const fetcher = approvalsDict[this.props.type].fetcher;
      this.props[fetcher]().then(() => {
        this.setState({ loading: false });
      });
    }
    render() {
      return <Comp {...this.props} {...this.state} />;
    }
  }
  return Wrapper;
};

const View = ({
  type,
  blockId,
  users,
  contacts,
  accounts,
  itemTypes,
  forms,
  hideModal,
  updateDefaultApprovers,
  workflow,
  addBlock,
  updateBlockSelection,
  deleteBlock,
  updateTriggerSelection,
  addTrigger,
  deleteTrigger,
  selection = [],
  order
}) => {
  const Editor = approvalsDict[type].Editor;
  const sectorType = approvalsDict[type].sectorType;
  const onSave = newSelection => {
    hideModal();
    if (sectorType === "trigger") {
      if (blockId === "new") {
        if (!isEmptyOrNil(newSelection)) {
          return addTrigger(newSelection, order, type);
        }
        return null;
      } else if (R.isEmpty(newSelection)) {
        return deleteTrigger(blockId);
      } else if (!isEmptyOrNil(newSelection)) {
        return updateTriggerSelection(
          blockId,
          newSelection.map(id => ({ id, type }))
        );
      }
    } else if (sectorType === "approver") {
      if (blockId === "default") {
        return updateDefaultApprovers(newSelection);
      } else if (blockId === "new") {
        if (!isEmptyOrNil(newSelection)) {
          return addBlock(newSelection, order);
        }
        return null;
      } else if (R.isEmpty(newSelection)) {
        return deleteBlock(blockId);
      } else if (!isEmptyOrNil(newSelection)) {
        return updateBlockSelection(blockId, newSelection);
      }
    }
    return null;
  };

  return (
    <Div width={1}>
      <Editor
        users={users}
        contacts={contacts}
        accounts={accounts}
        itemTypes={itemTypes}
        forms={forms}
        onSave={onSave}
        onCancel={hideModal}
        initialSelection={selection}
      />
    </Div>
  );
};

export default R.compose(lifecycle, ModalContainer)(View);
