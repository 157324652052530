import React from "react";
import { connect } from "react-redux";
import { getters } from "Portal/List/model";
import { Div } from "components/Base";
import PortalBar from "./PortalBar";
import Body from "./Body";
import Loading from "ui-kit/Loading";

const decorate = connect(state => ({
  loading: getters.loading(state)
}));

const Layout = ({ loading }) =>
  !loading ? (
    <Div>
      <PortalBar />
      <Body />
    </Div>
  ) : (
    <Loading />
  );

export default decorate(Layout);
