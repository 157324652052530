import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import FormElements from "components/Global/Modal/Layout/FormElements";

const { Input } = FormElements;

const ActiveState = CSSModules(
  ({
    inputOnly,
    toggleOnly,
    isToggled,
    onToggle,
    quantity,
    incrementQuantity,
    decrementQuantity,
    updateVariantQuantity,
    disabled
  }) => {
    if (toggleOnly) {
      return (
        <div styleName="counter">
          <div
            styleName="initialIncrement"
            onClick={onToggle}
            role="button"
            tabIndex="0"
          >
            <i className="material-icons">done</i>
          </div>
        </div>
      );
    }
    if (inputOnly) {
      return (
        <div styleName="counterInputOnly">
          <Input
            type="number"
            placeholder="0"
            defaultValue={quantity !== 0 ? quantity : null}
            disabled={disabled}
            onBlur={e => {
              if (
                (isNaN(parseInt(e.target.value, 10)) && !quantity) ||
                parseInt(e.target.value, 10) === quantity
              )
                return;
              return updateVariantQuantity(e.target.value);
            }}
          />
        </div>
      );
    }

    return (
      <div styleName="counter">
        <div
          styleName="decrement"
          onClick={decrementQuantity}
          role="button"
          tabIndex="0"
        >
          <i className="material-icons">remove</i>
        </div>
        <Input
          type="number"
          value={quantity}
          onChange={e => updateVariantQuantity(e.target.value)}
        />
        <div
          styleName="increment"
          onClick={incrementQuantity}
          role="button"
          tabIndex="0"
        >
          <i className="material-icons">add</i>
        </div>
      </div>
    );
  },
  css
);

const Counter = ({
  toggleOnly,
  inputOnly,
  isToggled,
  onToggle,
  quantity,
  incrementQuantity,
  decrementQuantity,
  updateVariantQuantity,
  disabled
}) => {
  if (inputOnly || quantity || isToggled) {
    return (
      <ActiveState
        {...{
          toggleOnly,
          inputOnly,
          isToggled,
          onToggle,
          quantity,
          incrementQuantity,
          decrementQuantity,
          updateVariantQuantity,
          disabled
        }}
      />
    );
  }
  return (
    <div styleName="counter">
      <div
        styleName={disabled ? "initialIncrementDisabled" : "initialIncrement"}
        onClick={
          disabled ? undefined : toggleOnly ? onToggle : incrementQuantity
        }
        role="button"
        tabIndex="0"
      >
        <i className="material-icons">add</i>
      </div>
    </div>
  );
};

export default CSSModules(Counter, css);
