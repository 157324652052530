import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";

import { actions } from "./index";

import { registerError } from "redux/modules/errors/actions";

import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { addRecord } from "redux/modules/modules/records/actions";
import { userId as getUserId } from "redux/modules/user/selectors";

import Api from "./api";
import SubmissionApi from "redux/modules/formsV2/submission/api";
import RecordApi from "redux/modules/modules/records/api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  const eventDetails = yield select(getEventDetails);
  const userId = yield select(getUserId);

  return {
    credentials,
    eventId,
    eventDetails,
    userId
  };
};

const init = function*() {
  try {
    yield put(actions.setLoading(true));

    const { credentials, eventId } = yield call(getParams);

    const { forms } = yield call(Api.get, {
      credentials,
      eventId
    });
    yield put(actions.setForms(forms));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading submit forms"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const addSubmission = function*({
  payload: { baseModuleId, formId, accountId, slug }
}) {
  yield put(actions.setSaving(formId));

  try {
    const { eventId, credentials, eventDetails, userId } = yield call(
      getParams
    );

    const { record } = yield call(RecordApi.post, credentials, {
      moduleId: baseModuleId,
      record: {
        isDraft: true
      },
      options: {
        eventId
      }
    });

    const submissionResult = yield call(SubmissionApi.post, credentials, {
      eventId,
      formId,
      userId,
      accountId,
      suppressSubmissionStartedEmail: true,
      submissionRecordId: record.id
    });

    window.location = `/submissions/${eventDetails.slug}/${slug}/${submissionResult.submission.id}?redirectToSource=1`;
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error adding submission form"
          }
        ])
      )
    ]);
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchAddSubmission = function*() {
  yield takeEvery(actions.addSubmission.type, addSubmission);
};

const rootSaga = function*() {
  yield all([fork(watchInit), fork(watchAddSubmission)]);
};

export default rootSaga;
