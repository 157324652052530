import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";
import { withRouter } from "react-router";
import * as flags from "utils/feature-flags";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { hideModal, showModal } from "redux/modules/modal/actions";
import { getItemGroupsByEventAndType } from "redux/modules/items/item-groups/actions";
import { getItemTypesByEvent } from "redux/modules/items/types/actions";
import { types } from "redux/modules/items/types/selectors";
import { selectFeatureFlag } from "@flopflip/react-redux";

import { eventDetails } from "redux/modules/event/selectors";

function mapStateToProps(state) {
  return {
    eventDetails: eventDetails(state),
    itemTypes: types(state),
    isLenndAdmin: selectFeatureFlag(flags.IS_LENND_ADMIN.NAME)(state),
    canViewInventory: selectFeatureFlag(flags.CAN_VIEW_INVENTORY.NAME)(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSnackbar,
      showModal,
      hideModal,
      getItemGroupsByEventAndType,
      getItemTypesByEvent
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
