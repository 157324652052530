import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { noop } from "utils/General";

const InputGroup = ({ children, styles, style, onClick = noop }) => (
  <div
    className={[css.inputGroup, styles].join(" ")}
    style={style}
    onClick={onClick}
  >
    {children}
  </div>
);

InputGroup.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.object
};

export default CSSModules(InputGroup, css);
