import React from "react";
import { connect } from "react-redux";
import { actions } from "Forms/WizardModal";
import {
  Div,
  collapsableHandler,
  Collapsable,
  DownFilledIcon,
  EditIcon,
  MediumFilledButton,
  MediumFilledInput,
  CheckIcon
} from "components/Base";
import ItemList from "./ItemList";

const decorate = connect(
  null,
  {
    toggleCategory: actions.toggleCategory,
    toggleCategoryEditing: actions.toggleCategoryEditing,
    updateCategoryName: actions.updateCategoryName,
    cancelCategoryEditing: actions.cancelCategoryEditing,
    updateCategory: ({ value, categoryRecordId }) =>
      actions.updateCategory({ value, categoryRecordId })
  }
);

const Category = collapsableHandler(
  ({
    toggleCollapsed,
    collapsed,
    name,
    selected,
    toggleCategory,
    id,
    items,
    categoryRecordId,
    editName,
    isEditing,
    isAddingItem,
    toggleCategoryEditing,
    updateCategoryName,
    cancelCategoryEditing,
    updateCategory,
    addItemName
  }) => (
    <Div
      width={1}
      ba={2}
      bc={{ default: !selected ? "neutral2" : "info6", hover: "info6" }}
      bg="white"
      bra={2}
      transition="fast"
      display="column"
      style={{ flexShrink: 0 }}
      px={4}
      mb={1}
    >
      <Div
        display="row.space-between.center"
        height={60}
        className="hoverContainer"
        onClick={toggleCollapsed}
      >
        <Div display="row.flex-start.center">
          <Div
            display="row.center.center"
            width={25}
            height={25}
            bg={!selected ? "neutral0" : "info6"}
            bc={{ default: !selected ? "neutral2" : "info6", hover: "info6" }}
            bra={1}
            ba={2}
            transition="fast"
            onClick={e => {
              e.stopPropagation();
              toggleCategory({ id });
            }}
          >
            {selected ? <CheckIcon color="white" size={18} /> : null}
          </Div>

          <DownFilledIcon
            color="black"
            size={24}
            transition="fast"
            style={{ transform: `rotate(${!collapsed ? "0" : "-90"}deg)` }}
          />

          {!isEditing ? (
            <Div display="row.flex-start.center">
              <Div fs={4} fw={4} color="gray7">
                {name}
              </Div>
              <Div
                width={31}
                height={34}
                bra={1}
                display="row.center.center"
                className="showOnHover"
                transition="fast"
                ml={3}
                onClick={e => {
                  e.stopPropagation();
                  updateCategoryName({ editName: name, id });
                  toggleCategoryEditing({ id });
                }}
                bg={{
                  default: "neutral2",
                  hover: "purple8"
                }}
                color={{
                  default: "gray7",
                  hover: "white"
                }}
              >
                <EditIcon
                  size={16}
                  style={{
                    color: "inherit"
                  }}
                />
              </Div>
            </Div>
          ) : (
            <Div
              display="row.flex-start.center"
              onClick={e => e.stopPropagation()}
            >
              <MediumFilledInput
                continuous
                value={editName}
                bg="neutral2"
                color="gray7"
                fw={2}
                placeholder="Enter category name..."
                onChange={text => {
                  updateCategoryName({ editName: text, id });
                }}
              />
              <MediumFilledButton
                bg="purple8"
                color="white"
                onClick={e => {
                  e.stopPropagation();
                  updateCategory({ value: editName, categoryRecordId });
                  toggleCategoryEditing({ id });
                }}
                ml={3}
                disabled={editName.length < 2}
              >
                Save
              </MediumFilledButton>
              <MediumFilledButton
                bg="neutral2"
                color="gray7"
                onClick={e => {
                  e.stopPropagation();
                  cancelCategoryEditing({ id });
                }}
                ml={1}
              >
                Cancel
              </MediumFilledButton>
            </Div>
          )}
        </Div>
        <Div display="row.flex-start.center">
          <Div
            bra={1}
            bg={{ default: !selected ? "neutral2" : "info6", hover: "info6" }}
            color={{
              default: !selected ? "gray7" : "white",
              hover: "white"
            }}
            px={4}
            py={2}
            style={{ cursor: "pointer" }}
            fs={2}
            fw={3}
            transition="fast"
            textAlign="center"
            width={110}
            onClick={e => {
              e.stopPropagation();
              toggleCategory({ id });
            }}
            ml={2}
          >
            {`SELECT${selected ? "ED" : ""}`}
          </Div>
        </Div>
      </Div>
      <Collapsable collapsed={collapsed}>
        <ItemList
          items={items}
          categoryRecordId={categoryRecordId}
          addItemName={addItemName}
          isAddingItem={isAddingItem}
          categoryId={id}
        />
      </Collapsable>
    </Div>
  )
);

const CategoryItem = props => <Category {...props} collapsedDefault={true} />;

export default decorate(CategoryItem);
