import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "Forms/WizardModal";
import {
  Popover,
  IconGrid,
  VertColorGrid,
  Div,
  FontIcon,
  MediumFilledButton
} from "components/Base";
import { DEFAULT_ICON_GROUP } from "components/Global/OptionSelector/AccountsOption/IconSelector";

const decorate = connect(
  state => ({
    moduleColor: getters.moduleColor(state),
    moduleIcon: getters.moduleIcon(state),
    title: getters.formName(state)
  }),
  {
    setModuleColor: actions.setModuleColor,
    setModuleIcon: actions.setModuleIcon
  }
);

const SelectColorIcon = ({
  moduleColor,
  moduleIcon,
  setModuleIcon,
  setModuleColor,
  title
}) => (
  <Div display="row.flex-start.center">
    <Popover
      anchorOrigin={{ horizontal: "center", vertical: "center" }}
      Label={({ onClick }) => (
        <Div
          width={210}
          height={172}
          bra={1}
          ba={1}
          bc="neutral1"
          bg="white"
          mt={2}
          display="column.space-between.center"
          py={1}
          px={2}
          shadow={1}
        >
          <Div display="column.flex-start.center">
            <Div
              bra={3}
              p={2}
              display="row.center.center"
              style={{ backgroundColor: moduleColor }}
              width={70}
              height={70}
              mt={5}
            >
              <FontIcon color="white" fs={6} icon={moduleIcon} />
            </Div>
            <Div mt={2} truncate width={150} textAlign="center">
              {title}
            </Div>
          </Div>
          <MediumFilledButton
            color="primary7"
            onClick={onClick}
            mt={1}
            bg="neutral1"
            width={1}
          >
            EDIT COLOR & ICON
          </MediumFilledButton>
        </Div>
      )}
    >
      {() => (
        <Div bg="white" display="row.flex-start.flex-start">
          <Div width={110}>
            <IconGrid
              onSelectIcon={icon => setModuleIcon(icon)}
              selectedIcon={moduleIcon}
              icons={DEFAULT_ICON_GROUP}
            />
          </Div>
          <VertColorGrid
            selectedColor={moduleColor}
            onSelectColor={color => {
              setModuleColor(color);
            }}
          />
        </Div>
      )}
    </Popover>
  </Div>
);

export default decorate(SelectColorIcon);
