import Container from "Passes/Main/View";
import Dashboard from "Passes/Dashboard";
import ManageAll from "Passes/ManageAll";
import ManageBy from "Passes/Manage/View";
import ManageTypes from "Passes/ManageTypes";
import ManageAssignee from "Passes/ManagePeople";
import ManageGuestList from "Passes/GuestLists";
import ManageApplication from "Passes/Applications";
import ManageGroups from "Passes/Accounts";
import ManageSources from "Passes/Sources";
import ActionsSendConfirmations from "Passes/SendConfirmations";
import Reports from "Passes/Reports";
import Checkin from "Passes/Checkin";

import Setup from "Passes/Setup";
import SetupOverview from "Passes/SetupOverview";
import SetupTypes from "Passes/SetupTypes";
import SetupApprovers from "Passes/SetupApprovers";
import SetupConfirmations from "Passes/SetupConfirmations";
import SetupIntegrations from "Passes/SetupIntegrations";

import SetupCollect from "Passes/SetupCollect";
import SetupCollectGuestLists from "Passes/SetupCollectGuestLists";
import SetupCollectRequestForms from "Passes/SetupCollectRequestForms";
import SetupCollectApplications from "Passes/SetupCollectApplications";

import Confirmations from "Passes/Confirmations/View";

import { hot } from "App/Config/hot";

const Passes = {
  Container,
  Dashboard,
  ManageAll,
  ManageBy,
  Confirmations,
  ManageTypes,
  ManageAssignee,
  ManageGuestList,
  ManageApplication,
  ManageGroups,
  ManageSources,
  ActionsSendConfirmations,
  Reports,
  Checkin,
  Setup,
  SetupOverview,
  SetupTypes,
  SetupApprovers,
  SetupConfirmations,
  SetupCollect,
  SetupCollectGuestLists,
  SetupCollectRequestForms,
  SetupCollectApplications,
  SetupIntegrations
};

export default hot(Passes);
