import numeral from "numeral";

export default function(
  value,
  { currencyPrefix = "$", currencyFormat = "0,0.00", currencySuffix = "" } = {}
) {
  return `${currencyPrefix}${numeral(value).format(
    currencyFormat
  )}${currencySuffix}`;
}
