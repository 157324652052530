import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { hideModal } from "redux/modules/modal/actions";
import CSSModules from "react-css-modules";
import { actions, getters } from "../model";
import css from "./styles.scss";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import { ADD_OPTIONS } from "../constants";
import { getSelectedItem } from "../selectors";

const decorate = R.compose(
  connect(
    state => ({
      addOption: getters.addOption(state),
      selectedItem: getSelectedItem(state)
    }),
    {
      hideModal,
      setAddOption: actions.setAddOption,
      init: actions.init
    }
  ),
  CSSModules(css)
);

const RadioOptions = ({ addOption, setAddOption }) => (
  <div styleName="radioWrapper">
    <RadioButtonGroup
      onChange={(event, id) => setAddOption(id)}
      name="import"
      valueSelected={addOption}
    >
      <RadioButton
        value={ADD_OPTIONS.IMPORT}
        label={
          <div styleName="radioText">
            <div styleName="title">Copy and paste a list of values</div>
            <div styleName="description">
              Numeric or Alphanumeric barcodes/serial number
            </div>
          </div>
        }
        iconStyle={{ alignSelf: "center" }}
        labelStyle={{ height: 30 }}
      />
    </RadioButtonGroup>
    <RadioButtonGroup
      onChange={(event, id) => setAddOption(id)}
      name="import"
      valueSelected={addOption}
    >
      <RadioButton
        value={ADD_OPTIONS.GENERATE}
        label={
          <div styleName="radioText">
            <div styleName="title">Generate a number range</div>
            <div styleName="description">
              Numeric sequences. ie: 0001 to 0500, or 1000001 to 100500
            </div>
          </div>
        }
        iconStyle={{ alignSelf: "center" }}
        labelStyle={{ height: 30 }}
      />
    </RadioButtonGroup>
  </div>
);

export default decorate(RadioOptions);
