import * as R from "ramda";
import { batchActions } from "redux-batched-actions";

import { takeEvery, put } from "redux-saga/effects";

import { actions } from "ui-kit/FilterControls";
import { actions as MiniItemsActions } from "ui-kit/MiniItemsSelector";

const clearAll = function*({ payload: instances }) {
  yield put(
    batchActions(
      R.flatten(
        R.map(
          instanceId => [
            actions.clear(null, { meta: { instanceId } }),
            MiniItemsActions.erase(null, { meta: { instanceId } })
          ],
          instances
        )
      )
    )
  );
};

const watchBulkClearAll = function*() {
  yield takeEvery(actions.clearAll.type, clearAll);
};

export default watchBulkClearAll;
