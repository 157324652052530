import React from "react";

import { createContext } from "redux-mvc";

import { connect } from "react-redux";

import * as R from "ramda";

import module, { actions } from "../index";
import { addS } from "utils/General";
import copy from "copy-to-clipboard";

import Layout from "./Layout";

import rootSaga from "../sagas/index";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import AssignFormsModal from "components/Global/CRM/Modals/Assign/AssignForms/AssignAccountForms";
import RunReportModal from "Reports/RunReportModal/View";
import SendDocument from "components/Event/Accounts/Modals/SendDocument";
import AssignDocumentRequestsModal from "components/Global/CRM/Modals/Assign/AssignDocumentRequests";
import AssignOwnersModal from "components/Global/Module/Modals/AssignOwners";
import ChangeRecordType from "components/Global/CRM/Modals/ChangeRecordType";
import DeleteConfirmation from "components/Global/CRM/Modals/DeleteConfirmation";
import DeleteOrRemoveConfirmation from "components/Global/CRM/Modals/DeleteOrRemoveConfirmation";
import BulkEdit from "components/Global/CRM/Modals/BulkEdit";
import SendEmail from "SendEmailModal/View";
import CreateDocuments from "Modules/GenerateDocumentsModal/View";
import Retrieving from "components/Global/CRM/Modals/Retrieving";
import ViewRecordModal from "components/Global/Module/Modals/ViewRecord";

import accountApi from "redux/modules/accounts/profile/api";

import { hideModal, showModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { addValues } from "redux/modules/modules/values/actions";
import {
  addRecords,
  deleteRecord,
  updateType
} from "redux/modules/modules/records/actions";
import { bulkRelateRecords } from "redux/modules/accounts/values/actions";

import { orgDetails } from "redux/modules/organization/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import { getCredentials } from "redux/modules/user/selectors";

import { withRouter } from "react-router";
import Helpers from "utils/Global/Helpers";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

import * as SEND_TO_OPTION_TYPES from "SendEmailModal/utils/send-to-option-types";

import { TABLE_INSTANCE_ID } from "Organizations/Events/constants";
import { actions as TableActions } from "ui-kit/Table/model";

const UPLOAD_FIELD_ID = "d512aeba-ae22-4623-b64c-90f293e37f16";

import SelectDashboardFieldsModal from "Modules/SelectDashboardFieldsModal/View";
import ImportModal from "Modules/ImportModal/View";

module.setRootSaga(rootSaga);

const decorate = createContext({
  module: module,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(actions.init());
    }
  },
  handlers: {
    showSelectFieldsModal(preferences) {
      this.props.showModal({
        content: (
          <SelectDashboardFieldsModal
            moduleId={this.props.params.moduleId}
            recordTypeId={this.props.params.recordTypeId}
            visibleFields={preferences.visible_fields}
            fieldOrder={preferences.field_order}
            onSave={fields => {
              this.store.dispatch(actions.updateViewFields(fields));
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showAddRecordModal({ recordType = {} }) {
      this.props.showModal({
        content: (
          <AddRecordModal
            moduleId={this.props.params.moduleId}
            onSave={() => {
              this.store.dispatch(actions.fetchData());
            }}
            onlyRecordTypeId={recordType.id}
            onlyRecordTypeName={recordType.name}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    refreshRecords(useSilentRefresh) {
      this.store.dispatch(actions.fetchData(useSilentRefresh));
    },
    showImportModal() {
      this.props.showModal({
        content: (
          <ImportModal
            moduleId={STANDARD_MODULE_IDS.events.id}
            onDone={() => {
              this.store.dispatch(actions.fetchData());
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showAssignFormsModal(toggledRows) {
      this.props.showModal({
        content: (
          <AssignFormsModal
            moduleId={this.props.params.moduleId}
            recordNameSingular={
              this.props.params.moduleId === STANDARD_MODULE_IDS.accounts.id
                ? "Group"
                : "Person"
            }
            recordNamePlural={
              this.props.params.moduleId === STANDARD_MODULE_IDS.accounts.id
                ? "Groups"
                : "People"
            }
            recordIds={toggledRows}
            onDone={() => {
              this.store.dispatch(actions.fetchData());
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    showAssignDocumentRequestsModal(toggledRows) {
      this.props.showModal({
        content: (
          <AssignDocumentRequestsModal
            moduleId={this.props.params.moduleId}
            recordNameSingular={
              this.props.params.moduleId === STANDARD_MODULE_IDS.accounts.id
                ? "Group"
                : "Person"
            }
            recordNamePlural={
              this.props.params.moduleId === STANDARD_MODULE_IDS.accounts.id
                ? "Groups"
                : "People"
            }
            recordIds={toggledRows}
            onDone={() => {
              this.store.dispatch(actions.fetchData());
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    showAssignOwnersModal(toggledRows) {
      this.props.showModal({
        content: (
          <AssignOwnersModal
            moduleId={this.props.params.moduleId}
            recordNameSingular={
              this.props.params.moduleId === STANDARD_MODULE_IDS.accounts.id
                ? "Group"
                : "Person"
            }
            recordNamePlural={
              this.props.params.moduleId === STANDARD_MODULE_IDS.accounts.id
                ? "Groups"
                : "People"
            }
            recordIds={toggledRows}
            onDone={() => {
              this.props.showSnackbar({
                message: "Owners assigned"
              });
              this.store.dispatch(actions.fetchData());
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    showChangeRecordTypesModal(toggledRows) {
      this.props.showModal({
        content: (
          <ChangeRecordType
            moduleId={this.props.params.moduleId}
            update={async data => {
              await Promise.all(
                toggledRows.map(recordId =>
                  this.props.updateType({
                    moduleId: this.props.params.moduleId,
                    recordId,
                    type: data.type,
                    options: {
                      orgId: this.props.params.orgId,
                      eventId: this.props.params.eventId
                    }
                  })
                )
              );

              this.props.showSnackbar({
                message: `Record${addS(toggledRows.length)} updated`,
                action: "OK"
              });
              this.store.dispatch(
                TableActions.clearSelectedRows(null, {
                  meta: {
                    instanceId: TABLE_INSTANCE_ID
                  }
                })
              );
              this.store.dispatch(actions.fetchData());
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    // @TODO: Clean this up - move more to the backend and generalize
    showGenerateDocumentsModal(toggledRowsWithName) {
      this.props.showModal({
        content: (
          <CreateDocuments
            onDone={async ({ documents, send }) => {
              // Handle sending document notifications
              if (send) {
                this.props.showModal({
                  content: (
                    <Retrieving
                      message="Please wait..."
                      title="Retrieving Records"
                    />
                  ),
                  wrapper: ModalWrapper
                });

                const fetchedRecords = await Promise.all(
                  toggledRowsWithName.map(record =>
                    accountApi.get(this.props.userCredentials, {
                      accountId: record.id,
                      orgId: this.props.orgDetails.id,
                      eventId: this.props.eventDetails.id
                    })
                  )
                );

                const recipients = fetchedRecords
                  .map(({ account }) => account)
                  .filter(Boolean)
                  .map(a => ({
                    name: R.pathOr("(No Name)", [
                      "values",
                      STANDARD_FIELD_IDS.ACCOUNTS.NAME,
                      "value",
                      "value"
                    ])(a),
                    users: a.users.filter(u => u.user_id && u.email)
                  }));

                this.props.hideModal();

                this.props.showModal({
                  content: (
                    <SendDocument
                      onDone={count => {
                        this.props.showSnackbar({
                          message: `${count} Document${
                            count === 1 ? "" : "s"
                          } Saved & Sent`,
                          action: "OK"
                        });
                      }}
                      recipients={recipients}
                      documents={documents}
                    />
                  ),
                  wrapper: ModalWrapper
                });
              }
            }}
            orgId={this.props.params.orgId}
            eventId={this.props.params.eventId}
            moduleId={this.props.params.moduleId}
            selected={toggledRowsWithName.map(r => r.id)}
            showSaveAndSend={true}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    async showUploadFilesModal(toggledRows) {
      const handleFileUpload = async files => {
        const moduleFiles = await this.props.addRecords({
          records: files.map(file => ({
            moduleId: STANDARD_MODULE_IDS.files.id,
            record: {
              [UPLOAD_FIELD_ID]: { type: "file", value: { files: [file] } }
            }
          })),
          options: {
            orgId: this.props.params.orgId,
            eventId: this.props.params.eventId
          }
        });
        const relatedRecordIds = moduleFiles.map(({ id }) => id);
        await this.props.bulkRelateRecords({
          recordIds: toggledRows,
          relatedRecordIds,
          relatedModuleId: STANDARD_MODULE_IDS.files.id
        });
        this.props.showSnackbar({ message: "Files added" });
        this.store.dispatch(actions.fetchData());
      };

      const callback = files => {
        if (files.length) {
          const filteredFiles = files.filter(f => Boolean(f.url));
          if (filteredFiles.length) {
            handleFileUpload(files);
          }
        }
      };

      const options = {
        multiple: true,
        mimetypes: [],
        container: "modal",
        services: ["COMPUTER", "DROPBOX", "GOOGLE_DRIVE"]
      };

      const path = { path: "event-files/" };

      Helpers.getFilepicker(options, path, callback);
    },

    showBulkEditModal({ toggledRows = [], fields = [], references = {} }) {
      this.props.showModal({
        content: (
          <BulkEdit
            selected={toggledRows}
            getMetaData={(rowData, column) => ({
              ...rowData,
              meta: {
                // userId: this.props.user.id,
                orgId: this.props.params.orgId,
                eventId: this.props.params.eventId,
                contactId: rowData.id,
                rowId: rowData.id,
                columnId: column.id,
                columnSettings: column.settings,
                eventDetails: this.props.eventDetails,
                references
              },
              helpers: {
                showModal: this.props.showModal,
                addReference: this.props.addReference
              }
            })}
            columns={fields}
            onSave={async data => {
              await this.props.addValues({
                moduleId: this.props.params.moduleIds,
                values: toggledRows.map(recordId => ({ recordId, ...data }))
              });

              this.props.showSnackbar({
                message: "Records updated",
                action: "OK"
              });
              this.store.dispatch(actions.fetchData());
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    showRunReportModal(toggledRows) {
      this.props.showModal({
        content: (
          <RunReportModal
            moduleId={this.props.params.moduleId}
            recordIds={toggledRows}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    showSendEmailModal(
      toggledRows,
      { selectedOptions, subject = null, message = null } = {}
    ) {
      this.props.showModal({
        content: (
          <SendEmail
            moduleId={this.props.params.moduleId}
            records={toggledRows}
            selectedOptions={
              selectedOptions ||
              this.props.params.moduleId === STANDARD_MODULE_IDS.accounts.id
                ? [SEND_TO_OPTION_TYPES.ACCOUNT_PRIMARY_CONTACTS]
                : null
            }
            subject={subject}
            message={message}
            onDone={() => {}}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    openInNewWindow(url) {
      window.open(url, "_blank");
    },

    copyToClipboard(toCopy) {
      copy(toCopy);
      this.props.showSnackbar({
        message: "Copied"
      });
    },

    openRecord({ rowId }) {
      if (this.props.params.moduleId === STANDARD_MODULE_IDS.accounts.id) {
        window.open(
          `/event-light/${this.props.params.eventId}/account/${rowId}`,
          "_blank"
        );
      } else if (
        this.props.params.moduleId === STANDARD_MODULE_IDS.contacts.id
      ) {
        window.open(
          `/event-light/${this.props.params.eventId}/contact/${rowId}`,
          "_blank"
        );
      } else {
        this.props.showModal({
          content: (
            <ViewRecordModal
              moduleId={this.props.params.moduleId}
              recordId={rowId}
            />
          ),
          wrapper: ModalWrapper
        });
      }
    },

    showDeleteRecordsModal(toggledRows) {
      const deleteRecords = async removeOnly => {
        await Promise.all(
          toggledRows.map(recordId =>
            this.props.deleteRecord({
              moduleId: this.props.params.moduleId,
              orgId: this.props.params.orgId,
              eventId: this.props.params.eventId,
              record: { id: recordId },
              options: {
                orgId: this.props.params.orgId,
                eventId: this.props.params.eventId,
                removeOnly
              }
            })
          )
        );

        this.props.showSnackbar({
          message: `Record${addS(toggledRows.length)} ${
            removeOnly ? "removed" : "deleted"
          }`,
          action: "OK"
        });
        this.store.dispatch(
          TableActions.clearSelectedRows(null, {
            meta: {
              instanceId: TABLE_INSTANCE_ID
            }
          })
        );
        this.store.dispatch(actions.fetchData());
      };

      if (
        !this.props.params.orgId &&
        [
          STANDARD_MODULE_IDS.accounts.id,
          STANDARD_MODULE_IDS.contacts.id
        ].includes(this.props.params.moduleId)
      ) {
        this.props.showModal({
          content: (
            <DeleteOrRemoveConfirmation
              hideModal={this.props.hideModal}
              countOfSelected={toggledRows.length}
              heading={`Remove Record${addS(toggledRows.length)}?`}
              onRemove={() => deleteRecords(true)}
              onDelete={() => deleteRecords(false)}
            />
          ),
          wrapper: ModalWrapper
        });
      } else {
        this.props.showModal({
          content: (
            <DeleteConfirmation
              hideModal={this.props.hideModal}
              heading={`Delete Record${addS(toggledRows.length)}?`}
              message={
                <div>
                  {`
                    Are you sure you want to remove
                    ${
                      toggledRows.length === 1
                        ? "this"
                        : `these ${toggledRows.length}`
                    }
                    record${addS(toggledRows.length)}?
                    `}
                  <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                    This cannot be undone.
                  </div>
                </div>
              }
              onConfirm={() => deleteRecords(false)}
            />
          ),
          wrapper: ModalWrapper
        });
      }
    }
  },
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: [
      "user",
      "event",
      "events",
      "modal",
      "organization",
      "permissions",
      "modules", // needed for accessing fetched records in assignment modal
      "@flopflip",
      "entityReferences",
      "Data",
      "routing"
    ]
  }
});

export default R.compose(
  connect(
    state => ({
      orgDetails: orgDetails(state),
      eventDetails: eventDetails(state),
      userCredentials: getCredentials(state)
    }),
    {
      showModal,
      hideModal,
      showSnackbar,
      addValues,
      addRecords,
      bulkRelateRecords,
      updateType,
      deleteRecord
    }
  ),
  withRouter,
  decorate
)(Layout);
