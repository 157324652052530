/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  findOrCreateSubmission: ({
    credentials,
    eventId,
    requestTypeId,
    accountId,
    contactId
  }) =>
    new Promise((success, error) => {
      const options = {
        method: "post",
        url: `${
          window.__LENND_API_BASE_URL__
        }/file-requests/event/${eventId}/request/${requestTypeId}/submission/${
          accountId ? `account/${accountId}` : `contact/${contactId}`
        }`,
        credentials,
        success,
        error
      };
      Helpers.request(options);
    })
};
