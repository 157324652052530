import React, { useEffect, useMemo } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";

import { actions } from "ui-kit/FilterControls";
import { getOptions, getFiltersOn } from "ui-kit/FilterControls/selectors";

import { RadioButton } from "material-ui/RadioButton";
import CheckBox from "material-ui/Checkbox";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import LenndMuiTheme from "components/Global/Themes/LenndMuiTheme";

import { Div, Text2, TinyFilledButton, getColor } from "components/Base";
import { noop } from "utils/General";

import { TYPES } from "ui-kit/FilterControls/constants";

import { Panel } from "./Panel";

const getMaterialUiTheme = borderColor => {
  const checkboxStyle = !R.isNil(borderColor)
    ? {
        checkbox: {
          boxColor: getColor(borderColor)
        }
      }
    : {};
  const muiTheme = getMuiTheme({
    ...LenndMuiTheme,
    ...checkboxStyle
  });
  return muiTheme;
};

const decorate = R.compose(
  connect(
    (state, props) => ({
      options: getOptions(state, { ...props, type: TYPES.CHECKBOX }),
      filtersOn: getFiltersOn(state, props)
    }),
    bindInstance({
      init: actions.init,
      toggleOption: actions.toggleOption
    })
  ),
  WithThemeConsumer({ variant: VARIANTS.BACKGROUND })
);

const CheckboxFilter = decorate(
  ({
    options = [],
    filtersOn = false,
    children: label,
    multiSelect = false,
    init = noop,
    toggleOption = noop,
    Th = defaultTh,
    collapsedDefault,
    variant,
    ...styleProps
  }) => {
    useEffect(() => {
      init({ multiSelect });
    }, []);

    const borderColor = R.isNil(variant) ? null : Th.FEATURES.bc;
    const muiTheme = useMemo(() => getMaterialUiTheme(borderColor), [
      borderColor
    ]);

    // in order to set different colors on material-ui checkbox we need to do it with the MuiThemeProvider.
    const EnhancedCheckbox = props => (
      <MuiThemeProvider muiTheme={muiTheme}>
        <CheckBox
          checked={props.checked}
          style={{ width: "auto" }}
          iconStyle={{
            alignSelf: "center",
            width: 20,
            height: 20,
            marginRight: 6
          }}
        />
      </MuiThemeProvider>
    );
    return (
      <Panel
        filtersOn={filtersOn}
        label={label}
        collapsedDefault={collapsedDefault}
        bodyProps={{ ml: 3 }}
        {...styleProps}
      >
        {R.map(
          option =>
            option.all ? (
              <Div mb={1} key={option.id}>
                <RadioButton
                  value={option.id}
                  checked={option.selected}
                  onClick={() =>
                    toggleOption(option.id, {
                      meta: { option }
                    })
                  }
                  label={
                    <Text2 {...Th(["color"], { active: option.selected })}>
                      {option.name}
                    </Text2>
                  }
                  iconStyle={{
                    alignSelf: "center",
                    width: 20,
                    height: 20,
                    marginRight: 6
                  }}
                  labelStyle={{ marginBottom: 0, lineHeight: "20px" }}
                />
              </Div>
            ) : (
              <Div
                key={option.id}
                display="row.flex-start.center"
                mb={1}
                onClick={() =>
                  toggleOption(option.id, {
                    meta: { option }
                  })
                }
              >
                <EnhancedCheckbox checked={option.selected} />
                <Div display="row.space-between.center" width={1}>
                  <Text2 {...Th(["color"], { active: option.selected })}>
                    {option.name}
                  </Text2>
                  {!R.isNil(option.info) && (
                    <TinyFilledButton color="white" bg="primary6" mx={3}>
                      {option.info}
                    </TinyFilledButton>
                  )}
                </Div>
              </Div>
            ),
          options
        )}
      </Panel>
    );
  }
);

export { CheckboxFilter };
