import React from "react";
import { EVENTBRITE_LOGO_SRC } from "components/Event/Settings/Credentials/constants";

export default ({ credential, size }) => {
  switch (credential.source) {
    case "eventbrite":
      return (
        <span>
          <img
            src={EVENTBRITE_LOGO_SRC}
            width={size || 10}
            height={size || 10}
            alt="Eventbrite"
            style={{ marginRight: 3 }}
          />
        </span>
      );
    default:
      return null;
  }
};
