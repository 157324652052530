import React from "react";
import thunkMiddleware from "redux-thunk";
import { connect } from "react-redux";
import * as R from "ramda";
import { withRouter } from "react-router";

import View from "./Page";

import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";
import { getItemGroupsByEventAndType } from "redux/modules/items/item-groups/actions";
import { getAllItemTypesByEvent } from "redux/modules/items/types/actions";
import { checkin, revertCheckin } from "redux/modules/passesCheckin/actions";
import { getFields } from "redux/modules/modules/fields/actions";

import { recordTypes } from "redux/modules/modules/recordTypes/selectors";

import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as SEND_TO_OPTION_TYPES from "SendEmailModal/utils/send-to-option-types";

import manageBy, { actions } from "Items/Manage";
import { actions as FilterControlsActions } from "ui-kit/FilterControls";
import { GROUP_FILTER_ID, ATTENDEE_FILTER_ID } from "Passes/Common/constants";

// view order modal
import ViewOrderModal from "Orders/OrderModal/View";
import CreateOrderModal from "Orders/CreateOrderModal";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

// summary modal
import SummaryModal from "Passes/GuestLists/SummaryModal";
import BulkEditLineItemsModal from "Orders/BulkEditLineItemsModal";
import IssuanceModal from "Passes/Checkin/IssuanceModal";
import SendEmailModal from "SendEmailModal/View";
import SelectOrderManagementFieldsModal from "Items/SelectOrderManagementFieldsModal/View";

import { showModal, hideModal } from "redux/modules/modal/actions";

import manageBySagas from "Items/Manage/sagas";

import { getForms } from "redux/modules/formsV2/forms/actions";

import { getUsers as fetchUsers } from "redux/modules/eventUsers/actions";

import FindItemInventoryModal from "components/Global/Modals/FindItemInventory";
import { batchActions } from "redux-batched-actions";

import { CREDENTIAL_TYPE_ID } from "utils/item-types";
import { MANAGE_BY, MODES, STATUS, SUMMARIES } from "Items/Manage/constants";
import { getActiveItemTypeId } from "Items/Manage/utils";

manageBy.setRootSaga(manageBySagas);

const getMode = (path = "") => {
  if (
    R.any(route => path.includes(route))([
      "/all-requests",
      "/my-approvals",
      "/my-requests",
      MANAGE_BY.INTEGRATION
    ])
  ) {
    return MODES.REVIEW;
  } else if (R.any(route => path.includes(route))(["/print"])) {
    return MODES.PRINT;
  }
  return MODES.ISSUANCE;
};

const getSelectedTab = (path = "") => {
  if (path.includes("/my-approvals")) {
    return STATUS.PENDING_REVIEW;
  }
  if (path.includes(MANAGE_BY.INTEGRATION)) {
    return STATUS.ALL;
  }
  return null;
};

const getView = (path = "") => {
  if (path.includes(MANAGE_BY.INTEGRATION)) {
    return MANAGE_BY.INTEGRATION;
  }
  return undefined;
};

const getSummaryId = props => {
  if (props.params.categoryId) {
    return props.params.categoryId;
  }
  if (props.params.accountId) {
    return props.params.accountId;
  }
  if (props.params.itemTypeId) {
    return props.params.itemTypeId;
  }
  return "";
};

const getSummaryType = props => {
  if (props.params.categoryId) {
    return SUMMARIES.CATEGORY;
  }
  if (props.params.accountId) {
    return SUMMARIES.ACCOUNT;
  }
  if (props.params.itemTypeId) {
    return SUMMARIES.ITEM_TYPE;
  }
  return "";
};

const contextConfig = {
  module: manageBy,
  options: {
    middleware: [thunkMiddleware],
    observedDomains: [
      "user",
      "event",
      "permissions",
      "formsV2",
      "eventUsers",
      "items",
      "routing",
      "modules",
      "organization",
      "ticketing",
      "@flopflip"
    ],
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", [...manageBy.modules, "portal"]))),
      R.prop("type")
    )
  },
  lifeCycle: {
    constructor(props) {
      const pathname = R.path(["location", "pathname"], props);

      this.store.dispatch(
        batchActions(
          [
            actions.init({
              // groupTypeId: props.location.query.groupTypeId,
              // contactTypeId: props.location.query.personTypeId,
              itemTypeView: getActiveItemTypeId(pathname),
              mode: getMode(pathname),
              view: getView(pathname),
              selectedTab: getSelectedTab(pathname)
            }),
            actions.setSelectedSummary({
              id: getSummaryId(props),
              type: getSummaryType(props)
            }),
            props.location.query.groupTypeId
              ? FilterControlsActions.toggleOption(
                  props.location.query.groupTypeId,
                  {
                    meta: {
                      instanceId: GROUP_FILTER_ID,
                      option: { id: props.location.query.groupTypeId }
                    }
                  }
                )
              : null,
            props.location.query.personTypeId
              ? FilterControlsActions.toggleOption(
                  props.location.query.personTypeId,
                  {
                    meta: {
                      instanceId: ATTENDEE_FILTER_ID,
                      option: { id: props.location.query.personTypeId }
                    }
                  }
                )
              : null
          ].filter(a => a)
        )
      );
    },
    componentDidMount() {
      this.store.dispatch(actions.initData());

      if (!this.props.groupTypes.length) {
        this.props.getRecordTypes({
          moduleId: STANDARD_MODULE_IDS.accounts.id,
          options: {
            orgId: this.props.params.orgId,
            eventId: this.props.params.eventId
          }
        });
      }

      if (!this.props.attendeeTypes.length) {
        this.props.getRecordTypes({
          moduleId: STANDARD_MODULE_IDS.contacts.id,
          options: {
            orgId: this.props.params.orgId,
            eventId: this.props.params.eventId
          }
        });
      }
    }
  },
  handlers: {
    refetchData({ stats } = { stats: false }) {
      this.store.dispatch(
        actions.fetchData(null, { meta: this.props.instanceId })
      );
      if (stats) {
        this.store.dispatch(
          actions.fetchStats(null, { meta: this.props.instanceId })
        );
      }
    },

    hideAndFetchData({ stats } = { stats: false }) {
      this.props.hideModal();
      this.store.dispatch(
        actions.fetchData(null, { meta: this.props.instanceId })
      );
      if (stats) {
        this.store.dispatch(
          actions.fetchStats(null, { meta: this.props.instanceId })
        );
      }
    },

    viewOrder(number, id) {
      this.props.showModal({
        content: (
          <ViewOrderModal
            orderNumber={id ? undefined : number}
            orderId={id ? id : undefined}
            onDone={this.handlers.refetchData}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    bulkEdit({ orderId, lineItemId }) {
      const where = {};

      if (orderId) {
        where.orderId = orderId;
      } else if (lineItemId) {
        where.lineItemId = lineItemId;
      }

      this.props.showModal({
        content: (
          <BulkEditLineItemsModal
            where={where}
            onDone={() => {
              this.handlers.refetchData({ stats: false });
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    viewSummary(accountId, accountName) {
      this.props.showModal({
        content: (
          <SummaryModal
            itemTypeId={CREDENTIAL_TYPE_ID}
            onClose={this.handlers.refetchData}
            groupId={accountId}
            groupName={accountName}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    createOrder() {
      this.props.showModal({
        content: (
          <CreateOrderModal
            onDone={() => this.handlers.refetchData({ stats: false })}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    sendConfirmation({ orderId }) {
      this.props.showModal({
        content: (
          <SendEmailModal
            emailType="confirmation"
            moduleId={STANDARD_MODULE_IDS.orders.id}
            selectedOptions={[SEND_TO_OPTION_TYPES.ORDER_CUSTOMER]}
            sourceOrderId={orderId}
            records={[orderId]}
            onDone={() => {
              this.handlers.refetchData({ stats: false });
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    sendEmail({ type, recordId }) {
      this.props.showModal({
        content: (
          <SendEmailModal
            moduleId={
              type === "contact"
                ? STANDARD_MODULE_IDS.contacts.id
                : STANDARD_MODULE_IDS.accounts.id
            }
            records={[recordId]}
            selectedOptions={[
              type === "contact"
                ? SEND_TO_OPTION_TYPES.EMAIL_FIELD
                : SEND_TO_OPTION_TYPES.ACCOUNT_PRIMARY_CONTACTS
            ]}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    async handleCheckin(
      actions,
      quantity,
      recordId,
      orderType,
      customerAccountId,
      customerContactId,
      variantId
    ) {
      await this.props.checkin({
        actions,
        recordId,
        orderType,
        customerAccountId,
        customerContactId,
        variantId,
        options: { quantity }
      });
    },

    async fulfillItems(...params) {
      return this.handlers.handleCheckin(["fulfill"], ...params);
    },
    async issueCheckinItems(...params) {
      return this.handlers.handleCheckin(["issue"], ...params);
    },
    async issueAndFulfill(...params) {
      return this.handlers.handleCheckin(["issue", "fulfill"], ...params);
    },

    async handleRevertCheckin(
      quantity,
      recordId,
      orderType,
      customerAccountId,
      customerContactId,
      variantId,
      action,
      fulfillmentId
    ) {
      await this.props.revertCheckin({
        variantId,
        recordId,
        orderType,
        customerAccountId,
        customerContactId,
        action,
        options: { quantity, fulfillmentId }
      });
    },

    issueItems({
      recordId,
      variantId,
      orderType,
      customerAccountId,
      customerContactId,
      fulfillmentId,
      status,
      itemName,
      recordName,
      quantity
    }) {
      const undo = undoStatus => count =>
        this.handlers.handleRevertCheckin(
          count,
          recordId,
          orderType,
          customerAccountId,
          customerContactId,
          variantId,
          undoStatus,
          fulfillmentId
        );
      this.props.showModal({
        content: (
          <IssuanceModal
            status={status}
            onIssue={count =>
              this.handlers.issueCheckinItems(
                count,
                recordId,
                orderType,
                customerAccountId,
                customerContactId,
                variantId
              )
            }
            onFulfill={count =>
              this.handlers.fulfillItems(
                count,
                recordId,
                orderType,
                customerAccountId,
                customerContactId,
                variantId
              )
            }
            onIssueAndFulfill={count =>
              this.handlers.issueAndFulfill(
                count,
                recordId,
                orderType,
                customerAccountId,
                customerContactId,
                variantId
              )
            }
            onUndoIssuance={undo("issuance")}
            onUndoPickup={undo("fulfillment")}
            onUndoIssuanceAndPickup={undo("fulfillment-issuance")}
            itemName={itemName}
            recordName={recordName}
            totalAvailable={parseInt(quantity, 10)}
            //
            orderType={orderType}
            customerAccountId={customerAccountId}
            customerContactId={customerContactId}
            recordId={recordId}
            variantId={variantId}
            fulfillmentId={fulfillmentId}
            onDone={() => this.handlers.hideAndFetchData({ stats: false })}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    printItems({ lineItemIds }) {
      this.store.dispatch(actions.printItems({ lineItemIds }));
    },

    undoPrintItems({ lineItemIds }) {
      this.store.dispatch(actions.undoPrintItems({ lineItemIds }));
    },

    onPassLookup() {
      this.props.showModal({
        content: <FindItemInventoryModal />,
        wrapper: ModalWrapper
      });
    },

    onShowHideFields(preferences, mode) {
      this.props.showModal({
        content: (
          <SelectOrderManagementFieldsModal
            itemTypeIds={[
              getActiveItemTypeId(R.path(["location", "pathname"], this.props))
            ]}
            visibleFields={preferences.visible_fields}
            fieldOrder={preferences.field_order}
            mode={mode}
            onSave={fields => {
              this.store.dispatch(actions.updateViewFields(fields));
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    }
  }
};

const ManageProps = R.compose(
  withRouter,
  connect(
    state => ({
      attendeeTypes: recordTypes(state, STANDARD_MODULE_IDS.contacts.id),
      groupTypes: recordTypes(state, STANDARD_MODULE_IDS.accounts.id)
    }),
    {
      getRecordTypes,
      getItemGroupsByEventAndType,
      getAllItemTypesByEvent,
      checkin,
      revertCheckin,
      showModal,
      hideModal,
      getForms,
      fetchUsers,
      getFields
    }
  )
);

export default View;

export { ManageProps, contextConfig };
