import React from "react";
import { MEDIA_MAX_WIDTH } from "./constants";
import MediaQuery from "react-responsive";

const Small = props => (
  <MediaQuery {...props} maxWidth={MEDIA_MAX_WIDTH.small} />
);
const Medium = props => (
  <MediaQuery
    {...props}
    minWidth={MEDIA_MAX_WIDTH.small + 1}
    maxWidth={MEDIA_MAX_WIDTH.medium}
  />
);
const Big = props => (
  <MediaQuery {...props} minWidth={MEDIA_MAX_WIDTH.medium + 1} />
);

export { Small, Medium, Big };
