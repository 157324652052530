import React from "react";

import { Div, UploadIcon, AddIcon } from "components/Base";

import { Cards } from "./Cards";

import ButtonAction from "components/Global/Table3/ButtonAction";

const AddButton = ({ children, ...props }) => (
  <Div
    display="row.center.center"
    uppercase
    p={3}
    bg="white"
    shadow={{
      default: 1,
      hover: 2
    }}
    bra={1}
    bc="neutral1"
    b={1}
    flex={1}
    color="primary7"
    {...props}
  >
    {children}
  </Div>
);

const CardPagination = ({
  countOfRows,
  numberOfCardsToShow,
  cardPaginationStart,
  goToPreviousCardPaginationSet,
  goToNextCardPaginationSet,
  position = "top"
}) => {
  return countOfRows > numberOfCardsToShow ? (
    <div
      style={{
        [position === "top" ? "marginBottom" : "marginTop"]: 10,
        display: "flex",
        justifyContent: "space-between"
      }}
    >
      {cardPaginationStart > 0 ? (
        <ButtonAction
          type="back"
          icon={
            <i className={["material-icons"].join(" ")}>keyboard_arrow_left</i>
          }
          label={<span>Back</span>}
          action={goToPreviousCardPaginationSet}
        />
      ) : (
        ""
      )}
      {countOfRows > cardPaginationStart + numberOfCardsToShow ? (
        <ButtonAction
          type="next"
          icon={
            <i className={["material-icons"].join(" ")}>keyboard_arrow_right</i>
          }
          label={<span>Next</span>}
          action={goToNextCardPaginationSet}
        />
      ) : (
        ""
      )}
    </div>
  ) : null;
};

const CardView = ({
  // data
  rows,
  fields,
  rowLabels,
  // settings
  allowNewRows,
  isFillingFormOut,
  isResponseLocked,
  isPreviewing,
  // pagination
  countOfRows,
  numberOfCardsToShow,
  cardPaginationStart,
  goToPreviousCardPaginationSet,
  goToNextCardPaginationSet,
  // actions
  addRow,
  showImportModal
}) => (
  <Div>
    <CardPagination
      {...{
        countOfRows,
        numberOfCardsToShow,
        cardPaginationStart,
        goToPreviousCardPaginationSet,
        goToNextCardPaginationSet,
        position: "top"
      }}
    />
    <Cards label={rowLabels.singular} rows={rows} fields={fields} />
    {(allowNewRows && isFillingFormOut && !isResponseLocked) ||
    (isPreviewing && allowNewRows) ? (
      <Div
        display="row.flex-start.center"
        style={{
          display: "flex",
          margin: "0 -15px"
        }}
      >
        <AddButton onClick={isPreviewing ? undefined : addRow}>
          <AddIcon />
          <Div ml={2} fw={3}>
            Add {rowLabels.singular ? rowLabels.singular : "Another"}
          </Div>
        </AddButton>
        {showImportModal ? (
          <AddButton
            ml={4}
            onClick={isPreviewing ? undefined : showImportModal}
          >
            <UploadIcon />
            <Div ml={2} fw={3}>
              Import People
            </Div>
          </AddButton>
        ) : null}
      </Div>
    ) : null}
    <CardPagination
      {...{
        countOfRows,
        numberOfCardsToShow,
        cardPaginationStart,
        goToPreviousCardPaginationSet,
        goToNextCardPaginationSet,
        position: "bottom"
      }}
    />
  </Div>
);

export default CardView;
