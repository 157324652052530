import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { noop } from "utils/General";

import { actions } from "ui-kit/Form/model";
import { getValue } from "ui-kit/Form/selectors";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";
import { WithThemeConsumer, VARIANTS } from "ui-kit/Theme";

import CheckBox from "material-ui/Checkbox";

const decorate = R.compose(
  WithFormInstanceConsumer({ fieldType: FIELD_TYPES.CHECKBOX }),
  WithThemeConsumer({ variant: VARIANTS.SURFACE }),
  connect(
    (state, props) => ({
      value: getValue(state, props)
    }),
    bindInstance({
      setIniValue: actions.setIniValue,
      onChange: actions.setFieldValue
    })
  )
);

export const CheckboxInput = decorate(
  ({
    value = false,
    iniValue,
    fieldId,
    fieldType,
    onChange = noop,
    setIniValue = noop,
    style = {},
    iconStyle,
    inputStyle,
    disabled = false
  }) => {
    useEffect(() => {
      setIniValue(iniValue, {
        meta: { fieldType, fieldId }
      });
    }, []);
    return (
      <CheckBox
        checked={value}
        onClick={() =>
          onChange(!value, {
            meta: { fieldType, fieldId }
          })
        }
        disabled={disabled}
        style={{ width: "auto", ...style }}
        iconStyle={iconStyle}
        inputStyle={inputStyle}
      />
    );
  }
);
