import { connect } from "react-redux";
import { withRouter } from "react-router";
import { createContext } from "redux-mvc";
import * as R from "ramda";
import module from "../index";
import { actions } from "../model";
import Layout from "./Layout";
import { showModal, hideModal } from "redux/modules/modal/actions";

const decorate = createContext({
  module,
  lifeCycle: {
    componentDidMount() {
      const moduleId = R.pathOr("", ["params", "moduleId"], this.props);
      this.store.dispatch(actions.init({ moduleId }));
    }
  },
  handlers: {},
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: [
      "user",
      "event",
      "modal",
      "organization",
      "permissions",
      "userAccessLevels"
    ],
    persist: true
  }
});

export default R.compose(
  connect(
    null,
    { showModal, hideModal }
  ),
  withRouter,
  decorate
)(Layout);
