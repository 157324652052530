import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import DocumentTitle from "components/Global/DocumentTitle";
import AccountSettingsForm from "components/Account/Options/Form";
import Heading from "components/Event/Settings/Heading";
import Body from "components/Event/Settings/Body";
import Page from "components/Event/Settings/Page";

@CSSModules(css)
class Options extends Component {
  render() {
    return (
      <DocumentTitle title="Account - Settings">
        <Page>
          <Heading title="Account Settings" />
          <Body>
            <div styleName="container">
              <AccountSettingsForm
                user={this.props.user}
                eventId={this.props.params.eventId}
                showSnackbar={this.props.showSnackbar}
                updateUser={this.props.updateUser}
              />
            </div>
          </Body>
        </Page>
      </DocumentTitle>
    );
  }
}

export default Options;
