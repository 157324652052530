import React, { Component } from "react";
import BaseLayoutHandler from "components/Event/Settings/Module/Types/Layouts";
import { Div } from "components/Base";
import {
  VisibleFields,
  AvailableFields
} from "components/Event/Settings/Module/Types/Layouts/Base";
import Loading from "components/Event/Settings/Module/Loading";

const View = ({ loading, allFields, visibleFields, onFieldsReorder }) => {
  return loading ? (
    <Loading />
  ) : (
    <Div>
      <Div display="row" pb={3} pt={5} px={6}>
        <VisibleFields
          bg="gray1"
          width={280}
          mr={2}
          visibleFields={visibleFields}
          onFieldsReorder={onFieldsReorder}
          emptyMessage="No fields selected. By default, all fields will show."
        />
        <AvailableFields flex={1} px={2} allFields={allFields} />
      </Div>
    </Div>
  );
};

class Controller extends Component {
  render() {
    // Pass through props from BaseLayoutControler
    return <View {...this.props} />;
  }
}

export default BaseLayoutHandler(Controller);
