import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

export const ArrowClosedIcon = CSSModules(
  () => (
    <i className="material-icons" styleName="arrowIcon">
      keyboard_arrow_right
    </i>
  ),
  css
);

export const ArrowOpenIcon = CSSModules(
  () => (
    <i className="material-icons" styleName="arrowIcon">
      keyboard_arrow_down
    </i>
  ),
  css
);

export const AddIcon = CSSModules(
  () => (
    <i className="material-icons" styleName="addIcon">
      add
    </i>
  ),
  css
);

export const EditIcon = CSSModules(
  () => (
    <i className="material-icons" styleName="addIcon">
      edit
    </i>
  ),
  css
);

export const ShareIcon = CSSModules(
  () => (
    <i className="material-icons" styleName="addIcon">
      people
    </i>
  ),
  css
);
