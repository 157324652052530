import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as R from "ramda";

const matchIdNameActions = R.curry((dict, actions, moduleId, props) => {
  const key = R.propOr("default", moduleId, dict);
  return actions[key] ? actions[key](props) : actions.default(props);
});

const moduleIdNameDict = R.compose(
  R.fromPairs,
  R.map(([k, v]) => [v.id, k]),
  R.toPairs
)(STANDARD_MODULE_IDS);

const matchModules = matchIdNameActions(moduleIdNameDict);

export { matchModules };
