import React from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Body from "components/Global/Modal/Layout/ScrollableBody";
import Loading from "components/Global/Modal/Layout/Loading";
import { ButtonGroup, Submit } from "components/Global/Modal/Layout/Buttons";
import Items from "Orders/ItemPickers";
import { Div } from "components/Base";

const View = ({
  activeType,
  cateringMealsPerDay,
  eventDateGroups,
  hideModal,
  loading,
  saving,
  onSave,
  isValid,
  selectedItemQty,
  selectedItemName,
  replaceWithItemName
}) => {
  return (
    <StyleWrapper
      bodyStyles={{ padding: 0 }}
      hideModal={hideModal}
      heading={
        selectedItemName
          ? `Changing ${selectedItemQty} ${selectedItemName} to...`
          : ""
      }
      width={500}
    >
      <Body style={{ backgroundColor: "#FEFEFE", height: 400 }}>
        {loading || !activeType ? (
          <Loading />
        ) : (
          <Div px={5} py={5}>
            <Items
              activeType={activeType}
              cateringMealsPerDay={cateringMealsPerDay}
              eventDateGroups={eventDateGroups}
              method="radio"
            />
          </Div>
        )}
      </Body>
      <Div display="row.flex-start.center">
        <ButtonGroup
          style={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "13px 16px"
          }}
        >
          <Div display="column" width={175}>
            <Div
              fs={2}
              style={{
                textDecoration: "line-through"
              }}
              truncate
            >
              {selectedItemQty} x {selectedItemName}
            </Div>

            <Div fs={3} truncate>
              {replaceWithItemName
                ? `${selectedItemQty} x ${replaceWithItemName}`
                : "Select an item"}
            </Div>
          </Div>
          <Submit
            title={saving ? "Saving..." : "Save"}
            onClick={onSave}
            disabled={!isValid}
            style={{
              width: 277
            }}
          />
        </ButtonGroup>
      </Div>
    </StyleWrapper>
  );
};

export default View;
