import PropTypes from "prop-types";
import React, { Component } from "react";
import { find, uniq, debounce, merge } from "lodash";
import getMetaData from "utils/value-types/get-meta-data";
import constructSearchObject from "utils/Search/construct-searchable-object";
import { sort as stableSort } from "timsort";
import searchTable from "utils/Search/table-search";
import { filterRecords } from "utils/value-types/filter";
import getRowSortFunction from "./utils/get-row-sort";
import flattenFields from "./utils/flatten-fields";

class BaseTableView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filtered: null,
      hiddenColumns: [],
      isSearching: false,
      sortByDirection: "DESC",
      sortByField: "created_at",
      pagination: {
        page: 0,
        perPage: 50
      },
      filters: null
    };

    this.search = debounce(this.search, 500);
  }

  shouldComponentUpdate(newProps, newState) {
    return this.props !== newProps || this.state !== newState;
  }
  componentWillUnmount() {
    this.search.cancel();
  }

  getRowMetaData = (rowData, column) => {
    const { moduleId } = this.props;
    return merge(
      getMetaData({
        row: rowData.values,
        rowId: rowData.id,
        field: column,
        fields: this.props.fields,
        userId: this.props.user.id,
        orgDetails: this.props.orgDetails,
        eventDetails: this.props.eventDetails,
        orgId: this.props.orgDetails.id,
        eventId: this.props.eventDetails.id,
        references: this.props.references
      }),
      {
        meta: {
          moduleId
        },
        helpers: {
          showModal: this.props.showModal,
          router: this.props.router,
          addReference: this.props.addReference,
          approveRecord: () => this.approveRecord(rowData.id),
          rejectRecord: () => this.rejectRecord(rowData.id),
          removeReview: () => this.removeReview(rowData.id),
          refreshRecords: () => this.props.fetchRecords()
        }
      }
    );
  };

  getRows = (rows, fields, references) => {
    stableSort(
      rows,
      getRowSortFunction({
        sortByField: find(fields, { id: this.state.sortByField }),
        sortByDirection: this.state.sortByDirection,
        references,
        eventDetails: this.props.eventDetails
      })
    );

    return rows;
  };

  getRowOrderToSave = (
    rows,
    fields,
    references,
    sortByFieldId,
    sortByDirection
  ) => {
    stableSort(
      rows,
      getRowSortFunction({
        sortByField: find(fields, { id: sortByFieldId }),
        sortByDirection: sortByDirection,
        references,
        eventDetails: this.props.eventDetails
      })
    );

    return rows.reduce((a, b, c) => {
      a[b.id] = c;
      return a;
    }, {});
  };

  saveRowOrder = rowOrder =>
    this.props.updateView({
      orgId: this.props.params.orgId,
      eventId: this.props.params.eventId,
      moduleId: this.props.moduleId,
      viewId: this.props.params.viewId,
      view: {
        rowOrder
      },
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId
      }
    });

  getVisibleRecords = () => {
    let records = this.props.records;
    if (this.state.filtered) {
      records = this.props.records.filter(({ id }) =>
        this.state.filtered.includes(id)
      );
    }
    if (this.state.filters) {
      records = filterRecords(
        this.state.filters,
        flattenFields(this.props.fields),
        records,
        {
          references: this.props.references
        }
      );
    }
    return records;
  };

  handleSearchChange = term => {
    const fields = flattenFields(this.props.fields);
    const fieldsToSearch = fields.map(({ id }) => id);

    const searchObjects = this.props.records.map(record =>
      constructSearchObject(
        fields,
        record.values,
        record.id,
        this.props.eventDetails,
        {
          references: this.props.references
        }
      )
    );
    return this.search(fieldsToSearch, searchObjects, term);
  };

  search = async (keys, searchObjects, term) => {
    if (!term) {
      // Bail out if we don't have a search term
      return this.setState({
        filtered: null
      });
    }

    this.setState({ isSearching: true });

    const filtered = await searchTable(keys, searchObjects, term);
    this.setState({
      filtered: uniq(filtered.map(r => r.id)),
      isSearching: false
    });
    return filtered;
  };

  updateFilters = ({ filters, expression }) => {
    this.setState({
      filters: {
        filters,
        expression
      }
    });
  };

  approveRecord = recordId => {
    return this.props.createReview({
      review: {
        moduleId: this.props.moduleId,
        recordId,
        response: "approve",
        userId: this.props.user.id
      }
    });
  };

  rejectRecord = recordId => {
    return this.props.createReview({
      review: {
        moduleId: this.props.moduleId,
        recordId,
        response: "reject",
        userId: this.props.user.id
      }
    });
  };

  removeReview = recordId => {
    this.props.removeReview({
      review: {
        moduleId: this.props.moduleId,
        recordId,
        userId: this.props.user.id
      }
    });
  };
}

BaseTableView.propTypes = {
  addReference: PropTypes.func.isRequired,
  eventDetails: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  moduleId: PropTypes.string.isRequired,
  records: PropTypes.array.isRequired,
  references: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired
};

export default BaseTableView;
