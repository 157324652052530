import { createModule } from "redux-mvc";

import model from "./model";

import sagasModule from "./sagas";

import addAssignmentModalModule from "EventLight/HealthPass/AddAssignmentModal";

const module = createModule({
  ...model,
  observedDomains: ["user", "event", "permissions", "organization"]
});

module.plugModule(addAssignmentModalModule);
module.setRootSaga(sagasModule);

export default module;
