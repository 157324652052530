import React from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import ScrollableModalBody from "components/Global/Modal/Layout/ScrollableBody";

import {
  Div,
  BigFilledButton,
  BigOutlineButton,
  Text3,
  BigInsetInput
} from "components/Base";
import DatePicker from "react-datepicker";

require("react-datepicker/dist/react-datepicker.css");

const View = ({
  hideModal,
  canSave,
  handleSave,
  name,
  startDate,
  endDate,
  handleNameChange,
  handleStartDateChange,
  handleEndDateChange
}) => (
  <StyleWrapper
    heading="Add new event"
    hideModal={hideModal}
    bodyStyles={{ padding: 0 }}
    width={501}
  >
    <Div display="column">
      <Div
        px={6}
        py={4}
        flex={1}
        style={{ backgroundColor: "rgba(224, 224, 224, 0.19)" }}
        bb={1}
        bc="gray2"
      >
        <Div display="row">
          <Div display="column" flex={1} pr={5}>
            <Div py={1} pb={3}>
              <Text3 bold pb={1}>
                Event Name
              </Text3>
              <BigInsetInput
                name="name"
                width={1}
                fs={3}
                flex={1}
                onChange={handleNameChange}
                continuous
                value={name}
              />
            </Div>
            <Div py={1} pb={3}>
              <Text3 bold>Start Date</Text3>
              <Div pb={1} fs={1}>
                Ex: The first day of load-in or the start of the event
              </Div>
              <DatePicker
                className="styled"
                dateFormat="M/D/YYYY"
                placeholderText="Start Date"
                selected={startDate}
                onChange={handleStartDateChange}
              />
            </Div>
            <Div py={1} pb={3}>
              <Text3 bold>End Date</Text3>
              <Div pb={1} fs={1}>
                Ex: The last day of load-out or the end of the event
              </Div>
              <DatePicker
                className="styled"
                dateFormat="M/D/YYYY"
                placeholderText="End Date"
                selected={endDate}
                onChange={handleEndDateChange}
              />
            </Div>
          </Div>
        </Div>
      </Div>
      <Div display="flex-start" py={3} px={5}>
        <BigFilledButton
          bg={canSave ? "altB5" : "neutral1"}
          mr={1}
          onClick={canSave ? handleSave : null}
        >
          Add Event
        </BigFilledButton>
        <BigOutlineButton onClick={hideModal}>Cancel</BigOutlineButton>
      </Div>
    </Div>
  </StyleWrapper>
);

export default View;
