import PropTypes from "prop-types";
import React from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import {
  ButtonGroup,
  Submit,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";

const CloseWarn = ({ count, onDone, hideModal }) => (
  <StyleWrapper
    hideModal={hideModal}
    heading="Are you sure?"
    width={450}
    containerStyles={{ overflowY: "visible" }}
  >
    {`Are you sure you don’t want to save ${count} document${
      count === 1 ? "" : "s"
    }?`}
    <ButtonGroup>
      <Submit
        type="submit"
        title={"Yes, don't save"}
        onClick={() => {
          hideModal();
          onDone();
        }}
      />
      <ButtonOutline onClick={hideModal} title="Cancel" />
    </ButtonGroup>
  </StyleWrapper>
);

CloseWarn.propTypes = {
  count: PropTypes.number.isRequired,
  onDone: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default CloseWarn;
