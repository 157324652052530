import { REQUEST, RECEIVE, ERROR, CLEAR_ERROR, INVALIDATE } from "./constants";
import * as selectors from "./selectors";
import api from "./api";

export function getAccount({ orgId, eventId, accountId }) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) {
      return null;
    }
    try {
      dispatch({ type: REQUEST });
      const account = await api.get(getState().user.user.credentials, {
        orgId,
        eventId,
        accountId
      });
      dispatch({ type: RECEIVE, payload: { account } });
      return account;
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
      return null;
    }
  };
}

export function getAccountAsPortal(data) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const account = await api.getAsPortal(
        getState().user.user.credentials,
        data
      );
      dispatch({ type: RECEIVE, payload: { account } });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function invalidateAccount(recordId) {
  return {
    type: INVALIDATE,
    payload: {
      recordId
    }
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR
  };
}
