import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import {
  Div,
  SmallOutlineButton,
  DownIcon,
  PopMenu,
  PopMenuCard,
  MediumOutlineButton,
  MediumFilledButton,
  CheckIcon
} from "components/Base";
import { getters, actions } from "Orders/TicketsPasses";

import { getItemPricesTempNone } from "Orders/TicketsPasses/selectors";

import { addS } from "utils/General";

const decorate = connect(
  state => ({
    pricesTemp: getters.itemPricesTemp(state),
    isProfilePricesNone: getItemPricesTempNone(state)
  }),
  {
    resetItemPricesTemp: actions.resetItemPricesTemp,
    toggleItemPrice: actions.toggleItemPrice,
    updateItemPrices: actions.updateItemPrices,
    disableItemPrices: actions.disableItemPrices
  }
);

const ProfilePricePopOver = ({
  prices,
  id,
  pricesTemp,
  resetItemPricesTemp,
  isProfilePricesNone,
  toggleItemPrice,
  updateItemPrices,
  disableItemPrices
}) => {
  return (
    <PopMenu
      Label={({ onClick }) => (
        <SmallOutlineButton
          RightIcon={DownIcon}
          onClick={() => {
            resetItemPricesTemp({ id });
            onClick();
          }}
          mr={2}
        >
          {R.all(R.propEq("selected", false), prices)
            ? "None"
            : `${R.length(prices)} price${addS(R.length(prices))}`}
        </SmallOutlineButton>
      )}
    >
      {({ closeMenu }) => (
        <PopMenuCard position="bottom" align="right">
          <Div p={3} mb={1} width={220}>
            <Div>
              {R.map(
                price => (
                  <Div
                    display="row.flex-start.stretch"
                    key={price.tempId}
                    onClick={() => {
                      toggleItemPrice({ tempId: price.tempId });
                    }}
                    bg={{ default: "white", hover: "neutral1" }}
                    transition="fast"
                  >
                    <Div width={30}>
                      {price.selected && (
                        <CheckIcon color="primary7" size={24} />
                      )}
                    </Div>
                    <Div>
                      <Div fw={3} color="black">
                        {price.name}
                      </Div>
                      <Div fs={1}>{`$${price.amount}`}</Div>
                    </Div>
                  </Div>
                ),
                pricesTemp
              )}
              <Div
                display="row.flex-start.stretch"
                onClick={() => {
                  disableItemPrices();
                }}
                p={1}
                height={30}
                bg={{ default: "white", hover: "neutral1" }}
                transition="fast"
              >
                <Div width={30}>
                  {isProfilePricesNone && (
                    <CheckIcon color="primary7" size={24} />
                  )}
                </Div>
                <Div>
                  <Div fw={3} color="black">
                    None
                  </Div>
                </Div>
              </Div>
            </Div>
            <Div display="row.space-between.center" mt={2}>
              <MediumOutlineButton
                onClick={() => {
                  closeMenu();
                }}
              >
                Cancel
              </MediumOutlineButton>
              <MediumFilledButton
                bg="primary7"
                onClick={() => {
                  updateItemPrices({ id });
                  closeMenu();
                }}
              >
                Assign Prices
              </MediumFilledButton>
            </Div>
          </Div>
        </PopMenuCard>
      )}
    </PopMenu>
  );
};

export default decorate(ProfilePricePopOver);
