import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Name from "./Name";
import tooltipFactory from "components/Global/Factories/TruncateTextTooltip";

import { showModal } from "redux/modules/modal/actions";

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(tooltipFactory(Name));
