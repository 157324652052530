import Helpers from "utils/Global/Helpers";

export default {
  get: (credentials, formId) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/form/people-blocks/list-by-form/${formId}`,
        credentials,
        success,
        error
      });
    }),
  getById: (credentials, blockId) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/form/people-blocks/${blockId}`,
        qs: {
          expand: ["fields", "itemBlocks"]
        },
        credentials,
        success,
        error
      });
    }),
  addItemBlock: (credentials, { order, itemBlockId, blockId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${
          window.__LENND_API_BASE_URL__
        }/form/people-blocks/${blockId}/item-blocks`,
        data: { order, itemBlockId, blockId },
        credentials,
        success,
        error
      });
    }),
  deleteItemBlock: (credentials, { itemBlockId, blockId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${
          window.__LENND_API_BASE_URL__
        }/form/people-blocks/${blockId}/item-blocks`,
        data: { itemBlockId, blockId },
        credentials,
        success,
        error
      });
    }),

  updateItemBlocks: (credentials, { itemBlocks, blockId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${
          window.__LENND_API_BASE_URL__
        }/form/people-blocks/${blockId}/item-blocks`,
        data: { itemBlocks, blockId },
        credentials,
        success,
        error
      });
    }),

  addField: (credentials, { order, fieldId, blockId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${
          window.__LENND_API_BASE_URL__
        }/form/people-blocks/${blockId}/fields`,
        data: { order, fieldId, blockId },
        credentials,
        success,
        error
      });
    }),

  updateFields: (credentials, { fields, blockId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${
          window.__LENND_API_BASE_URL__
        }/form/people-blocks/${blockId}/fields`,
        data: { fields, blockId },
        credentials,
        success,
        error
      });
    }),
  deleteField: (credentials, { fieldId, blockId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${
          window.__LENND_API_BASE_URL__
        }/form/people-blocks/${blockId}/fields`,
        credentials,
        data: { fieldId, blockId },
        success,
        error
      });
    }),

  post: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/form/people-blocks`,
        credentials,
        data,
        success,
        error
      });
    }),
  /* eslint-enable no-underscore-dangle */

  put: (credentials, blockId, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/form/people-blocks/${blockId}`,
        credentials,
        data,
        success,
        error
      });
    })
  /* eslint-enable no-underscore-dangle */

  // bulkUpdateItemBlocks: (credentials, data) =>
  //   new Promise((success, error) => {
  //     /* eslint-disable no-underscore-dangle */
  //     Helpers.request({
  //       method: "put",
  //       url: `${window.__LENND_API_BASE_URL__}/items/item-blocks/bulk-update`,
  //       credentials,
  //       data,
  //       success,
  //       error
  //     });
  //   }),
  // /* eslint-enable no-underscore-dangle */

  // deleteItemBlock: (credentials, data) =>
  //   new Promise((success, error) => {
  //     /* eslint-disable no-underscore-dangle */
  //     Helpers.request({
  //       method: "delete",
  //       url: `${window.__LENND_API_BASE_URL__}/items/item-blocks/${
  //         data.blockId
  //       }`,
  //       credentials,
  //       data,
  //       success,
  //       error
  //     });
  //   })
  // /* eslint-enable no-underscore-dangle */
};
