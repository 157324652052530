import { createContext } from "redux-mvc";
import * as R from "ramda";

import Layout from "./Layout";

import module from "Notifications";
import rootSaga from "Notifications/sagas";

module.setRootSaga(rootSaga);

const decorate = createContext({
  module: module,
  options: {
    persist: false,
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: [
      "user",
      "event",
      "organization",
      "accounts",
      "portal",
      "formsV2",
      "Data",
      "permissions",
      "ticketing",
      "@flopflip",
      "routing",
      "modal"
    ]
  }
});

export default decorate(Layout);
