import React, { Component } from "react";
import getValue from "utils/value-types/get-value/event-list";
import { Div, Text3, Text0 } from "components/Base";
import { isEqual } from "lodash";

export default class OrderItemFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  render() {
    const value = getValue(this.props.value);
    return (
      <Div display="column">
        <Text3
          bold
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden"
          }}
        >
          {value.name}
        </Text3>
        {value.statuses && value.statuses.length ? (
          <Div display="row.flex-start.center">
            {value.statuses.map(status => (
              <Text0 uppercase key={status} key={status} pr={2}>
                {status}
              </Text0>
            ))}
          </Div>
        ) : null}
      </Div>
    );
  }
}
