import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { actions, getters } from "Modules/ImportModal";
import { PAGES, DEFAULT_VALUE, FIELD_IDS } from "Modules/ImportModal/constants";
import {
  Div,
  Text3,
  Text4,
  Text2,
  Text1,
  Text6,
  Text0,
  SuperOutlineButton,
  SuperFilledButton,
  ErrorIcon,
  TinyToggle,
  Dropdown,
  LeftArrowIcon,
  DownIcon,
  MediumInsetInput,
  CloseIcon,
  InfoIcon
} from "components/Base";
import { addS } from "utils/General";
import Tooltip from "components/Global/Tooltip";
import SelectMoreModal from "./SelectMoreModal";
import PreviewRecords from "./PreviewRecords";
import { getMessages } from "Modules/ImportModal/selectors";

const decorate = connect(
  state => ({
    fieldGroups: getters.fieldGroups(state),
    mapObject: getters.mapObject(state),
    selectMoreModal: getters.selectMoreModal(state),
    messageList: getMessages(state),
    enabledGroups: getters.enabledGroups(state)
  }),
  {
    saveCsvData: () => actions.saveCsvData(),
    goToimportFile: () => actions.setSelectedPage(PAGES.IMPORT_FILE),
    showSelectMoreModal: actions.showSelectMoreModal,
    toggleFieldGroups: actions.toggleFieldGroups,
    setFieldGroupValue: actions.setFieldGroupValue,
    toggleGroupOptions: actions.toggleGroupOptions,
    removeDefaultValue: actions.removeDefaultValue,
    addDefaultValue: actions.addDefaultValue,
    toggleGroup: actions.toggleGroup
  }
);

const fieldStatus = field => {
  if (field.required) {
    return "*required";
  } else if (field.recommended) {
    return "*recommended";
  }
  return "";
};

const formatMessage = ({ groupName, fieldName }) =>
  `Map the "${groupName} - ${fieldName}" field to a CSV column`;

const MapCsv = ({
  goToimportFile,
  messageList,
  fieldGroups,
  toggleFieldGroups,
  setFieldGroupValue,
  toggleGroupOptions,
  mapObject,
  selectMoreModal,
  showSelectMoreModal,
  saveCsvData,
  removeDefaultValue,
  addDefaultValue,
  enabledGroups,
  toggleGroup
}) => {
  return (
    <Div display="column" flex={1} height={1}>
      {selectMoreModal && <SelectMoreModal />}

      <Div
        flex={1}
        height={1}
        style={{
          overflowY: "auto"
        }}
      >
        <Div display="row.flex-start.stretch" flex={1} height={1}>
          <Div
            flex={1}
            px={7}
            display="column"
            style={{ overflowY: "auto" }}
            height={1}
          >
            {mapObject.fields_groups.map((group, index) => (
              <Div key={group.name} mt={5}>
                <Div
                  display="row.space-between.center"
                  bb={1}
                  bc="neutral2"
                  height={30}
                  pb={3}
                >
                  <Div display="row.flex-start.center">
                    {enabledGroups[index].locked === false && (
                      <TinyToggle
                        active={enabledGroups[index].enabled === true}
                        onClick={() => {
                          toggleGroup({
                            groupId: group.id,
                            groupName: group.name,
                            index
                          });
                        }}
                        mr={2}
                      />
                    )}
                    <Text4 bold mr={1}>
                      {group.name}
                    </Text4>
                    {!R.isEmpty(R.prop("description")(group)) && (
                      <Tooltip placement="top" tooltip={group.description}>
                        <span style={{ display: "flex", cursor: "pointer" }}>
                          <InfoIcon />
                        </span>
                      </Tooltip>
                    )}
                  </Div>
                  <Div width={198}>
                    <Text4 bold>Column Headers</Text4>
                  </Div>
                </Div>
                {R.compose(
                  R.map(field => (
                    <Div
                      display="row.space-between.center"
                      key={field.id}
                      py={1}
                      bb={1}
                      bc="neutral3"
                      style={{
                        minHeight: 50
                      }}
                    >
                      <Div display="row">
                        <TinyToggle
                          active={R.find(R.propEq("id", field.id))(
                            fieldGroups[index].fields
                          )}
                          onClick={() => {
                            if (field.supported === true) {
                              toggleFieldGroups({
                                index,
                                id: field.id,
                                groupId: group.id,
                                groupName: group.name,
                                type: field.type,
                                fieldName: field.name,
                                required: field.required,
                                supported: field.supported
                              });
                            }
                          }}
                        />
                        <Text2 bold ml={2}>
                          {field.name}
                        </Text2>
                      </Div>
                      {R.and(
                        R.find(R.propEq("id", field.id))(
                          fieldGroups[index].fields
                        ),
                        R.propEq("supported", true, field)
                      ) && (
                        <Div display="row.flex-start.center" ml={2}>
                          <Text1 color="neutral5" bold>
                            {fieldStatus(field)}
                          </Text1>
                          <LeftArrowIcon
                            size={20}
                            color="black"
                            mr={1}
                            ml={1}
                          />
                          {R.find(R.propEq("id", field.id))(
                            fieldGroups[index].fields
                          ).column === DEFAULT_VALUE ? (
                            <Div display="row.flex-start.center">
                              <MediumInsetInput
                                value={
                                  R.find(R.propEq("id", field.id))(
                                    fieldGroups[index].fields
                                  ).default_value
                                }
                                onChange={defaultValue =>
                                  addDefaultValue({
                                    index,
                                    id: field.id,
                                    groupName: group.name,
                                    fieldName: field.name,
                                    defaultValue,
                                    type: field.type,
                                    required: field.required
                                  })
                                }
                                continuous
                              />
                              <CloseIcon
                                size={20}
                                ml={3}
                                onClick={() =>
                                  removeDefaultValue({
                                    index,
                                    id: field.id,
                                    groupName: group.name,
                                    fieldName: field.name,
                                    type: field.type
                                  })
                                }
                              />
                            </Div>
                          ) : (
                            <Dropdown
                              usePortal
                              width="220px"
                              selected={
                                R.find(R.propEq("id", field.id))(
                                  fieldGroups[index].fields
                                ).column
                              }
                              placeholder="Choose a CSV column"
                              options={[
                                ...R.map(
                                  option => ({
                                    value: option,
                                    label: option
                                  }),
                                  mapObject.imported_columns
                                ),
                                {
                                  value: DEFAULT_VALUE,
                                  label: "Enter a default value"
                                }
                              ]}
                              onChange={event => {
                                const value = R.propOr("", "value")(event);
                                setFieldGroupValue({
                                  index,
                                  id: field.id,
                                  groupName: group.name,
                                  fieldName: field.name,
                                  value,
                                  type: field.type,
                                  required: field.required
                                });
                              }}
                            />
                          )}
                        </Div>
                      )}
                      {R.propEq("supported", false, field) && (
                        <Div
                          width={270}
                          display="row.flex-end.center"
                          height={40}
                          pl={3}
                        >
                          <ErrorIcon mr={1} />
                          <Text0
                            ml={1}
                          >{`"${field.name}" fields are not currently supported`}</Text0>
                        </Div>
                      )}
                    </Div>
                  )),
                  R.filter(
                    field =>
                      field.enabled ||
                      field.required ||
                      field.id === FIELD_IDS.FULL_NAME
                  )
                )(group.fields)}
                {group.options.length > 0 && (
                  <Div display="row.space-between" py={3} bb={1} bc="neutral3">
                    <Text1>{R.replace("Fields", "Options", group.name)}</Text1>
                    {group.options.map((option, optionIndex) => (
                      <Div display="row.flex-start.center">
                        <TinyToggle
                          active={
                            fieldGroups[index].options[optionIndex]?.name ===
                            true
                          }
                          onClick={() =>
                            toggleGroupOptions({ index, optionIndex })
                          }
                        />
                        <Text0 ml={2}>{option.name}</Text0>
                      </Div>
                    ))}
                  </Div>
                )}
                <Div
                  height={30}
                  mt={2}
                  mb={5}
                  display="row.flex-start.center"
                  onClick={() => {
                    showSelectMoreModal({ index });
                  }}
                >
                  <DownIcon color="neutral7" />
                  <Text2 color="neutral7" ml={1}>
                    {`More ${group.name} fields...`}
                  </Text2>
                </Div>
              </Div>
            ))}
          </Div>
          <Div
            bg="neutral0"
            width={570}
            p={3}
            style={{ overflowY: "auto", flexShrink: 0 }}
          >
            {messageList.length > 0 ? (
              <Div
                p={5}
                display="column.center.center"
                style={{ height: "100%" }}
                textAlign="center"
              >
                <Text6 bold>
                  {formatMessage(R.takeLast(1, messageList)[0])}
                </Text6>
              </Div>
            ) : (
              <PreviewRecords />
            )}
          </Div>
        </Div>
      </Div>
      <Div
        display="row.space-between.center"
        p={3}
        pl={5}
        bt={1}
        bc="neutral2"
        style={{
          flexShrink: 0
        }}
      >
        <Div display="row.flex-start.center">
          {messageList.length > 0 && <ErrorIcon mr={1} color="warning6" />}

          <Text3 bold>
            {messageList.length > 0
              ? formatMessage(R.takeLast(1, messageList)[0])
              : `${mapObject.records} row${addS(
                  mapObject.records.length
                )} will be processed`}
          </Text3>
        </Div>
        <Div display="row">
          <SuperOutlineButton onClick={goToimportFile}>
            Cancel
          </SuperOutlineButton>
          <SuperFilledButton
            ml={3}
            width={164}
            bg={
              R.or(
                R.length(messageList) > 0,
                R.all(R.propEq("fields", []))(fieldGroups)
              )
                ? "neutral1"
                : "altB5"
            }
            disabled={R.or(
              R.length(messageList) > 0,
              R.all(R.propEq("fields", []))(fieldGroups)
            )}
            onClick={() => saveCsvData()}
          >
            Import
          </SuperFilledButton>
        </Div>
      </Div>
    </Div>
  );
};

export default decorate(MapCsv);
