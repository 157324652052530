import React from "react";
import * as R from "ramda";
import PropTypes from "prop-types";
import EditorBase from "./TableConnector";
import Cell from "./CellBase";
import Editor from "components/Global/Editors/CredentialEditor";
import Formatter from "ui-kit/Datagrid/View/Formatters/Credential";

import { getSettings, getMeta, ep } from "ui-kit/Datagrid/utils";

export default class CredentialEditor extends EditorBase {
  render() {
    const { rowData, column } = this.props;
    const type = getSettings(ep(this.props), "selectType");
    const availableCredentials = getSettings(
      ep(this.props),
      "availableCredentials",
      []
    );
    const order = getSettings(ep(this.props), "order");

    const credentials = R.filter(
      c => availableCredentials.includes(c.id),
      getMeta(
        ep(this.props),
        ["eventDetails", "module_settings", "credentials", "credentials"],
        []
      )
    );

    const options = R.sortBy(c => order[c.id], credentials);

    const editor = (
      <Editor
        options={options}
        column={column}
        className="editor-main"
        value={this.state}
        onChange={this.handleChange}
      />
    );

    if (type === "quantity-only-input") {
      return editor;
    }

    return (
      <Cell
        ref="editor"
        formatter={<Formatter value={this.state} row={rowData} />}
        editor={editor}
      />
    );
  }
}

CredentialEditor.propTypes = {
  ...EditorBase.propTypes,
  rowMetaData: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired
};
