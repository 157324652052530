import PropTypes from "prop-types";
import React, { Component } from "react";

import { DragSource } from "react-dnd";
import { ItemTypes } from "./constants/constants";

function DraggableField(Child) {
  const fieldSource = {
    beginDrag(props) {
      props.onDragStart({
        type: "placeholder"
      });

      return {
        index: props.index,
        id: props.id,
        field: props.field,
        viaSidebar: true
      };
    },

    endDrag(props, monitor) {
      if (monitor.didDrop()) {
        props.onClick(monitor.getItem().index);
      }
      props.onDrop();
    }
  };

  @DragSource(ItemTypes.FIELD, fieldSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }))

  //@DragSource doesn´t admit functional components
  class Field extends Component {
    render() {
      const { isDragging, connectDragSource, ...props } = this.props;
      return connectDragSource(
        <div
          style={{
            cursor: "move",
            userSelect: "none",
            width: "100%",
            height: "100"
          }}
        >
          <Child isDragging={isDragging} {...props} />
        </div>
      );
    }
  }

  Field.propTypes = {
    isDragging: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired
  };

  return Field;
}
export default DraggableField;
