import React, { Fragment } from "react";
import { connect } from "react-redux";
import { actions, getters } from "Forms/WizardModal";
import SelectType from "./SelectType";
import SelectPasses from "./SelectPasses";
import SelectFields from "./SelectFields";
import SelectRecordTypes from "./SelectRecordTypes";
import ReviewForm from "Forms/WizardModal/View/ReviewForm";
import PassesFieldsModal from "./PassesFieldsModal";
import { shouldEnableSavePassesForm } from "Forms/WizardModal/selectors";
import { PASSES_PAGES } from "Forms/WizardModal/constants";

const decorate = connect(
  state => ({
    selectedPasses: getters.selectedPasses(state),
    saveBtnEnabled: shouldEnableSavePassesForm(state),
    modalEnabled: getters.modalEnabled(state)
  }),
  {
    reviewGoBack: () => actions.setSelectedPasses(PASSES_PAGES.SELECT_FIELDS),
    saveForm: actions.savePassesForm
  }
);

const Pages = ({
  handlers,
  selectedPasses,
  reviewGoBack,
  saveForm,
  saveBtnEnabled
}) => {
  if (selectedPasses === PASSES_PAGES.SELECT_PASSES) {
    return <SelectPasses handlers={handlers} />;
  } else if (selectedPasses === PASSES_PAGES.SELECT_FIELDS) {
    return <SelectFields handlers={handlers} />;
  } else if (selectedPasses === PASSES_PAGES.SELECT_ACCOUNTS) {
    return <SelectRecordTypes handlers={handlers} />;
  } else if (selectedPasses === PASSES_PAGES.REVIEW) {
    return (
      <ReviewForm
        handlers={handlers}
        goBack={reviewGoBack}
        saveForm={saveForm}
        saveBtnEnabled={saveBtnEnabled}
        steps={["Step 1", "Step 2", "Step 3", "Step 4"]}
      />
    );
  }
  return <SelectType handlers={handlers} />;
};

const Passes = ({
  handlers,
  selectedPasses,
  reviewGoBack,
  saveForm,
  saveBtnEnabled,
  modalEnabled
}) => {
  return (
    <Fragment>
      {modalEnabled && <PassesFieldsModal handlers={handlers} />}
      <Pages
        handlers={handlers}
        selectedPasses={selectedPasses}
        reviewGoBack={reviewGoBack}
        saveForm={saveForm}
        saveBtnEnabled={saveBtnEnabled}
      />
    </Fragment>
  );
};

export default decorate(Passes);
