import React, { useState } from "react";
import { Div, Popover, DownIcon } from "components/Base";
import Menu from "./Menu";
import { actions } from "Toolbar/QuickActionsNavigation";
import { connect } from "react-redux";

const decorate = connect(
  null,
  {
    openMenu: actions.openMenu
  }
);

const Layout = ({ handlers, canDo, openMenu }) => {
  const [hovered, setHovered] = useState(false);
  const [btnActive, setBtnActive] = useState(false);
  return (
    <Popover
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      PaperProps={{
        style: {
          borderRadius: 5,
          overflow: "visible"
        }
      }}
      Label={({ onClick }) => (
        <Div
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={e => {
            onClick(e);
          }}
          height={44}
          display="row.center.center"
          py={1}
          style={{
            marginLeft: "-8px"
          }}
        >
          <Div
            height={1}
            fw={3}
            display="row.flex-start.center"
            style={{
              color: hovered ? "white" : "#BFACD6",
              backgroundColor: btnActive
                ? "rgba(131, 79, 192, 0.5)"
                : "transparent",
              cursor: "pointer"
            }}
            transition="fast"
            bg={btnActive ? "primary7" : "transparent"}
            bra={2}
            px={2}
          >
            More
            <DownIcon
              ml={1}
              size={24}
              mt={1}
              style={{
                color: hovered ? "white" : "#BFACD6"
              }}
              transition="fast"
            />
          </Div>
        </Div>
      )}
    >
      {({ closePopover }) => (
        <Menu
          handlers={handlers}
          canDo={canDo}
          closePopover={closePopover}
          setBtnActive={cond => {
            setBtnActive(cond);
            setHovered(cond);
          }}
        />
      )}
    </Popover>
  );
};

export default decorate(Layout);
