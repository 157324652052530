import api from "./api";
import { REQUEST, RECEIVE, SET_ACTIVE, UPDATE } from "./constants";
import { registerError } from "redux/modules/errors/actions";

export function setActiveView(data) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: SET_ACTIVE,
        payload: {
          moduleId: data.moduleId,
          viewId: data.viewId
        }
      });
      return await api.putActive(getState().user.user.credentials, data);
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred setting active view"
          }
        ])
      );
    }
  };
}

export function getViews(data) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: REQUEST,
        payload: {
          moduleId: data.moduleId
        }
      });
      const result = await api.get(getState().user.user.credentials, data);
      dispatch({
        type: RECEIVE,
        payload: {
          moduleId: data.moduleId,
          views: result.payload
        }
      });
      return result.payload;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting views"
          }
        ])
      );
    }
  };
}

export function createView(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post(getState().user.user.credentials, data);
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred creating view"
          }
        ])
      );
    }
  };
}

export function updateView(data) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE,
        payload: {
          moduleId: data.moduleId,
          viewId: data.viewId,
          view: data.view
        }
      });
      return await api.put(getState().user.user.credentials, data);
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating view"
          }
        ])
      );
    }
  };
}

export function deleteView(data) {
  return async (dispatch, getState) => {
    try {
      return await api.delete(getState().user.user.credentials, data);
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting view"
          }
        ])
      );
    }
  };
}
