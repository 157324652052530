import Helpers from "utils/Global/Helpers";

/* eslint no-underscore-dangle: "off" */

export default {
  getOrder: ({ credentials, orderId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/orders/orders/${orderId}/summary`,
        credentials,
        success,
        error
      });
    }),

  cancelOrder: (credentials, orderId) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/orders/orders/${orderId}`,
        credentials,
        success,
        error
      });
    }),

  syncOrders: (credentials, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/orders/sync`,
        credentials,
        data,
        success,
        error
      });
    }),

  getFeed: (credentials, { eventId, orderId, recordId }) =>
    new Promise((success, error) => {
      const qs = { eventId };
      if (orderId) {
        qs.orderId = orderId;
      }
      if (recordId) {
        qs.recordId = recordId;
      }

      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/feed`,
        credentials,
        qs,
        success,
        error
      });
    }),

  addNote: (credentials, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/feed/notes`,
        credentials,
        data,
        success,
        error
      });
    }),

  updateNote: (credentials, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/feed/notes/${data.noteId}`,
        credentials,
        data,
        success,
        error
      });
    }),

  deleteNote: (credentials, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/feed/notes/${data.noteId}`,
        credentials,
        data,
        success,
        error
      });
    }),

  addMessage: (credentials, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/feed/messages`,
        credentials,
        data,
        success,
        error
      });
    }),

  updateMessage: (credentials, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/feed/messages/${data.messageId}`,
        credentials,
        data,
        success,
        error
      });
    }),

  deleteMessage: (credentials, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/feed/messages/${data.messageId}`,
        credentials,
        data,
        success,
        error
      });
    }),

  getActiveTicketingIntegrations: (credentials, eventId) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/integrations/ticketing/provider-configurations/events/${eventId}`,
        credentials,
        success,
        error
      });
    }),

  updateOrderCustomer: (credentials, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/orders/orders/${
          data.orderId
        }/customer`,
        credentials,
        data,
        success,
        error
      });
    }),

  addTransaction: (credentials, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/orders/invoices/order/${
          data.orderId
        }/transactions`,
        credentials,
        data,
        success,
        error
      });
    }),

  addManualTransaction: (credentials, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/orders/invoices/order/${
          data.orderId
        }/transactions/manual`,
        credentials,
        data,
        success,
        error
      });
    })
};
