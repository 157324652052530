import React from "react";
import ManagersTable from "./ManagersTable";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Managers = () => (
  <div styleName="content">
    <ManagersTable />
  </div>
);

export default CSSModules(css)(Managers);
