import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import ActionButton from "components/Event/Credentials/Manage/ActionButton";
import ActionsButtonMenu from "./ActionsButtonMenu";
import css from "./styles.scss";

const ActionsMenu = ({ children, ...props }) => (
  <ActionsButtonMenu {...props} menu={children}>
    <ActionButton>
      <span className={css.buttonLabel}>
        Actions <i className="material-icons">arrow_drop_down</i>
      </span>
    </ActionButton>
  </ActionsButtonMenu>
);

ActionsMenu.PropTypes = {
  children: PropTypes.node.isRequired
};

export default CSSModules(ActionsMenu, css);
