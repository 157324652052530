import React, { Component } from "react";
import getValue from "utils/value-types/get-value/time";
import { isEqual } from "lodash";
import { Div } from "components/Base";

export default class TimeFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  render() {
    const time = getValue(this.props.value);

    // let text;
    // let timeFormat = (
    //   this.props.dependentValues.meta.columnSettings &&
    //   this.props.dependentValues.meta.columnSettings.timeFormat)
    //     ? this.props.dependentValues.meta.columnSettings.timeFormat
    //     : 'h:mm a';

    // if (timeFormat && dateToFormat) {
    //   text = moment(new Date(dateToFormat)).format(timeFormat) || '';
    // }

    return (
      <Div
        truncate
        style={{
          userSelect: "none",
          lineHeight: "34px"
        }}
      >
        {time}
      </Div>
    );
  }
}
