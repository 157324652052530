import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function addAccountFormAssignment(data) {
  return async (dispatch, getState) => {
    try {
      const { payload } = await api.post(
        getState().user.user.credentials,
        data
      );
      return payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding account form assignment"
          }
        ])
      );
    }
  };
}

export function updateAccountFormAssignment(data) {
  return async (dispatch, getState) => {
    try {
      const { payload } = await api.put(getState().user.user.credentials, data);
      return payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating account form assignment"
          }
        ])
      );
    }
  };
}

export function removeAccountFormAssignment(data) {
  return async (dispatch, getState) => {
    try {
      const { payload } = await api.delete(
        getState().user.user.credentials,
        data
      );
      return payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred removing account form assignment"
          }
        ])
      );
    }
  };
}
