import { bindActionCreators } from "redux";
import PortalUserView from "./PortalUserView";
import { connect } from "react-redux";

import { getLoggedInUserDetails } from "redux/modules/user/actions";

import * as UserSelectors from "redux/modules/user/selectors";

import * as ModalActions from "redux/modules/modal/actions";

import * as SnackbarActions from "redux/modules/snackbar/actions";
import * as SnackbarSelectors from "redux/modules/snackbar/selectors";

function mapStateToProps(state) {
  return {
    snackbar: SnackbarSelectors.snackbar(state),
    user: UserSelectors.user(state),
    sidebar: state.sidebar
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...ModalActions,
      ...SnackbarActions,
      getLoggedInUserDetails
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PortalUserView);
