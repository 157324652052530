import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Back from "../Common/Back";
import PrimaryAction from "../Common/PrimaryAction";
import SecondaryAction from "../Common/SecondaryAction";
import ButtonContainer from "../Common/ButtonContainer";
import Label from "../Common/Label";
import Form from "../Common/Form";
import Input from "../Common/Input";
import ErrorMessage from "../Common/Error";
import Container from "../Common/Container";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const isValid = placeholder => Boolean(placeholder);
const onSubmit = event => event.preventDefault();

@CSSModules(css)
export default class Placeholder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholder: props.search || "",
      processing: false
    };
  }

  @autobind
  changeState(field, event) {
    this.setState({
      [field]: event.target.value
    });
  }

  @autobind
  async createPlaceholder() {
    const { placeholder } = this.state;
    if (!isValid(placeholder)) return;

    this.setState({
      processing: true
    });

    try {
      await this.props.createGroup(placeholder, 1);
    } catch (e) {
      this.setState({
        processing: false,
        error: "Something went wrong! Please try again."
      });
    }
  }

  render() {
    const { changeScene, close } = this.props;
    const { placeholder, error } = this.state;

    return (
      <Container>
        <Back onClick={() => changeScene("list", "back")} />
        <Form onSubmit={onSubmit}>
          <Label>Role/job title of individual</Label>
          <Input
            styleName="input"
            value={placeholder}
            onChange={this.changeState.bind(null, "placeholder")}
            type="text"
          />
          <br />
          <br />
          <ButtonContainer>
            <PrimaryAction
              disabled={!isValid(placeholder)}
              processing={this.state.processing}
              onClick={this.createPlaceholder}
            >
              Add Placeholder
            </PrimaryAction>
            <SecondaryAction onClick={close}>Cancel</SecondaryAction>
          </ButtonContainer>
          {error ? <ErrorMessage>{error}</ErrorMessage> : ""}
        </Form>
      </Container>
    );
  }
}

Placeholder.propTypes = {
  search: PropTypes.string.isRequired,
  createGroup: PropTypes.func.isRequired,
  changeScene: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
};
