import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function addVariant(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.addVariant(
        getState().user.user.credentials,
        data
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding variant"
          }
        ])
      );
    }
  };
}

export function updateVariant(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.updateVariant(
        getState().user.user.credentials,
        data
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating variant"
          }
        ])
      );
    }
  };
}

export function deleteVariant(variantId) {
  return async (dispatch, getState) => {
    try {
      const result = await api.deleteVariant(
        getState().user.user.credentials,
        variantId
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting variant"
          }
        ])
      );
    }
  };
}

export function bulkUpdateVariants(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.bulkUpdateVariants(
        getState().user.user.credentials,
        data
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred bulk updating variants"
          }
        ])
      );
    }
  };
}
