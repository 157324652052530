import { combineReducers } from "redux";
import {
  SAVE_RECEIVE,
  SAVE_REQUEST,
  CLEAR_LAST_SAVE,
  ERROR,
  CLEAR_ERROR
} from "./constants";

const saving = (
  state = {
    isSaving: false,
    saved: null
  },
  action
) => {
  switch (action.type) {
    case SAVE_REQUEST:
      return {
        isSaving: true
      };
    case SAVE_RECEIVE:
      return {
        isSaving: false,
        saved: Date.now()
      };
    case CLEAR_LAST_SAVE:
      return {
        saved: null
      };
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case ERROR:
      return action.payload;
    case CLEAR_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  saving,
  error
});
