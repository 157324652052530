import React, { Component } from "react";
import * as R from "ramda";
import View from "./View";
import AddSubmissionAsModal from "components/Event/FormsV2/Modals/AddSubmissionAsModal";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import SubmissionApi from "redux/modules/formsV2/submission/api";
import getValue from "utils/value-types/get-value";
import toString from "utils/value-types/to-string";
import * as STANDARD_MODULE_FIELD_IDS from "utils/standard-module-field-ids";
import SubmissionSummary from "components/Event/FormsV2/Overview/SubmissionSummary";
import getApprovalValue from "utils/value-types/get-value/approval";
import OrderModal from "Orders/OrderModal/View";

class Controller extends Component {
  state = {
    loading: true
  };

  async componentDidMount() {
    await this.fetchRecords();

    this.setState({
      loading: false
    });
  }

  componentDidUpdate(oldProps) {
    if (
      this.props.params.applicationFormId !== oldProps.params.applicationFormId
    ) {
      this.fetchRecords();
    }
  }

  fetchRecords = () => {
    const { applicationFormId } = this.props.params;
    return this.props.getSubmissions(applicationFormId);
  };

  goto(pathname) {
    return this.props.router.push({ pathname });
  }

  goToEditForm = () => {
    this.goto(
      `/event/${this.props.eventId}/module/${
        this.props.formMeta.base_module_id
      }/form/${this.props.formMeta.id}`
    );
  };

  goToShareForm = () => {
    this.goto(
      `/event/${this.props.eventId}/module/${
        this.props.formMeta.base_module_id
      }/form/${this.props.formMeta.id}/share`
    );
  };

  goToSubmitForm = () => {
    this.props.showModal({
      content: (
        <AddSubmissionAsModal
          onDone={this.addSubmission}
          hideModal={this.props.hideModal}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  addSubmission = async accountId => {
    const { userId, eventId, formMeta, eventSlug } = this.props;

    const submissionModuleRecord = await this.props.addRecord({
      moduleId: this.props.formMeta.base_module_id,
      record: {
        isDraft: true
      },
      options: {
        eventId
      }
    });

    const submissionResult = await SubmissionApi.post(
      this.props.userCredentials,
      {
        eventId,
        formId: this.props.formMeta.id,
        userId,
        accountId,
        suppressSubmissionStartedEmail: true,
        submissionRecordId: submissionModuleRecord.id
      }
    );

    window.location = `/submissions/${eventSlug}/${formMeta.slug}/${
      submissionResult.submission.id
    }?redirectToApplication=1`;
  };

  showSubmissionSummary = submissionId => {
    const modal = (
      <OrderModal
        submissionId={submissionId}
        hideModal={() => {
          this.props.hideModal();
          this.fetchRecords();
        }}
      />
    );
    this.props.showModal({ content: modal, wrapper: ModalWrapper });
  };

  render() {
    const {
      userId,
      formMeta,
      submissions,
      eventDetails,
      references
    } = this.props;

    let formUserProps = {};
    if (
      formMeta.form_users &&
      formMeta.form_users.some(u => u.user_id === userId)
    ) {
      formUserProps = {
        onShareForm: this.goToShareForm,
        onEditForm: this.goToEditForm
      };
    }

    const tableRows = R.compose(
      R.sortBy(
        R.compose(
          R.toLower,
          R.prop("name")
        )
      ),
      R.addIndex(R.map)((submissionId, i) => {
        /*
        const accountName = toString(
          getValue(
            submissions[submissionId].values[
              STANDARD_MODULE_FIELD_IDS.APPLICATIONS.APPLICANT_ACCOUNT
            ],
            "lookup"
          ),
          "lookup",
          eventDetails,
          {
            references,
            fieldId: STANDARD_MODULE_FIELD_IDS.ACCOUNTS.NAME
          }
        );
        */

        return {
          name: submissions[submissionId].collaborators[0].account_name,
          // approval: getApprovalValue(values[approval])
          approval: {},
          submittedAt: submissions[submissionId].created_at,
          onClick: () => this.showSubmissionSummary(submissionId),
          isLocked: true,
          meta: {
            userId,
            approveLineItems: () => {},
            rejectLineItems: () => {},
            removeApproval: () => {}
          }
        };
      })
    )(R.keys(submissions));

    return (
      <View
        {...{
          onSubmitForm: this.goToSubmitForm,
          formName: this.props.formMeta.name,
          formId: formMeta.id,
          tableRows,
          title: formMeta.name,
          ...formUserProps
        }}
      />
    );
  }
}

export default Controller;
