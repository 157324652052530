import React from "react";
import PropTypes from "prop-types";
import InventorySidebar from "./Sidebar";
import Sidebar from "components/Global/Module/Sidebar";

const resolveScene = path => {
  const chunks = path.split("/");
  return ["catalog", "needs"].find(scene => chunks.includes(scene));
};

const Container = ({ children, params, location }) => {
  if (params.moduleId) return <span>{children}</span>;
  return (
    <Sidebar
      moduleId="inventory"
      sidebar={
        <InventorySidebar
          params={params}
          scene={resolveScene(location.pathname)}
        />
      }
    >
      {children}
    </Sidebar>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  params: PropTypes.shape({ moduleId: PropTypes.string }).isRequired
};

export default Container;
