import React from "react";
import { ErrorIcon } from "components/Base";
import PillButton from "components/Global/CRM/PillButton";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { connect } from "react-redux";

const decorate = connect(
  state => ({
    eventId: getEventId(state)
  }),
  {}
);
const Indicator = ({ eventId, row, moduleId }) =>
  row.id && (
    <div
      className="hoverContainer"
      style={{
        width: 26,
        height: 26,
        padding: 5,
        paddingLeft: 0
      }}
    >
      <ErrorIcon color="purple9" className="hideOnHover" />
      <div
        className="showOnHover"
        style={{
          position: "absolute",
          width: 125,
          zIndex: 9
        }}
      >
        <PillButton
          linkTo={{
            pathname: `/event-light/${eventId}/crm/settings/manage-duplicates`,
            state: { conflictIds: [row.conflictId], moduleId }
          }}
          title={<span>View duplicates</span>}
        />
      </div>
    </div>
  );

export default decorate(Indicator);
