import React, { Component } from "react";
import * as R from "ramda";
import { isEqual } from "lodash";
import { withProps } from "utils/General";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { Div, SmallShadedBox, CheckIcon } from "components/Base";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import EditWrapper from "components/Global/Table3/CellFormatters/EditWrapper";
import Tooltip from "components/Global/Tooltip";
import { AllocationsModal } from "Passes/GuestLists/Items";
import SummaryModal from "Passes/GuestLists/SummaryModal";

import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";
import { CREDENTIAL_TYPE_ID, MEAL_TYPE_ID } from "utils/item-types";

import { showModal, hideModal } from "redux/modules/modal/actions";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

const decorate = connect(
  (state, props) => {
    let columnId = props.dependentValues.meta.columnId;
    const itemTypeId = R.prop(columnId)({
      "allocated-passes": CREDENTIAL_TYPE_ID,
      "allocated-meals": MEAL_TYPE_ID
    });
    const moduleId = R.prop(columnId)({
      "allocated-passes": STANDARD_MODULE_IDS.credentials.id,
      "allocated-meals": STANDARD_MODULE_IDS.catering.id
    });
    const label = R.prop(columnId)({
      "allocated-passes": "passes",
      "allocated-meals": "meals"
    });
    const canAddItems = canEventUserDo(state)(`${moduleId}_add_line_items`);

    // get name
    let name = null;
    const thisRecord = props.dependentValues.meta.row;
    if (props.params.moduleId === STANDARD_MODULE_IDS.accounts.id) {
      name = R.pathOr("(No Name)", [STANDARD_FIELD_IDS.ACCOUNTS.NAME, "value"])(
        thisRecord
      );
    } else if (props.params.moduleId === STANDARD_MODULE_IDS.contacts.id) {
      name = [
        R.path([STANDARD_FIELD_IDS.CONTACTS.FIRST_NAME, "value"])(thisRecord),
        R.path([STANDARD_FIELD_IDS.CONTACTS.LAST_NAME, "value"])(thisRecord)
      ]
        .filter(v => v)
        .join(" ");
    }

    return {
      label,
      itemTypeId,
      canAddItems,
      name
    };
  },
  {
    showModal,
    hideModal
  }
);

class AllocatedItemsFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  showAddItemsModal = () => {
    this.props.showModal({
      content: (
        <AllocationsModal
          itemTypeId={this.props.itemTypeId}
          groupId={this.props.dependentValues.meta.rowId}
          groupName={this.props.name}
          onSave={() => {
            this.props.dependentValues.helpers.refreshRecords(true);
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showSummaryModal = () => {
    this.props.showModal({
      content: (
        <SummaryModal
          itemTypeId={this.props.itemTypeId}
          groupId={this.props.dependentValues.meta.rowId}
          groupName={this.props.name}
          onClose={() => {
            this.props.dependentValues.helpers.refreshRecords(true);
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    let allocatedCount =
      this.props.value && this.props.value.value
        ? this.props.value.value.allocatedCount
        : null;

    let pendingRequestCount =
      this.props.value && this.props.value.value
        ? this.props.value.value.pendingRequestCount
        : null;

    let assignedCount =
      this.props.value && this.props.value.value
        ? this.props.value.value.assignedCount
        : null;

    let content = null;
    let handler = null;

    if (!allocatedCount && !pendingRequestCount) {
      if (this.props.canAddItems) {
        handler = this.showAddItemsModal;
        content = (
          <Div color="purple8" fw={3} fs={3}>
            + Allocate {this.props.label}
          </Div>
        );
      } else {
        content = <span>&mdash;</span>;
      }
    } else {
      const isComplete = allocatedCount > 0 && allocatedCount === assignedCount;
      const remaining = Math.max(allocatedCount - assignedCount, 0);
      handler = this.showSummaryModal;

      content = (
        <Div display="row.flex-start.center">
          <Tooltip
            tooltip={
              isComplete ? (
                <span>
                  <strong>All {this.props.label}</strong> assigned to people{" "}
                  <br />
                  *some items may be excluded
                </span>
              ) : (
                <span>
                  <strong>
                    {assignedCount} {this.props.label}
                  </strong>{" "}
                  assigned to people <br />
                  {remaining && allocatedCount > 0 ? (
                    <span>
                      <strong>
                        {remaining} {this.props.label}
                      </strong>{" "}
                      left to assign
                    </span>
                  ) : (
                    <span>
                      <strong>{pendingRequestCount} requests </strong>
                      pending review
                    </span>
                  )}
                  <br />
                  *some items may be excluded
                </span>
              )
            }
          >
            <span>
              <SmallShadedBox
                pr={3}
                LeftIcon={
                  isComplete
                    ? withProps({ title: "All assigned" })(CheckIcon)
                    : undefined
                }
                color={isComplete ? "success8" : undefined}
              >
                {assignedCount} of&nbsp;
                <strong>{allocatedCount}</strong>
              </SmallShadedBox>
            </span>
          </Tooltip>
        </Div>
      );
    }

    return <EditWrapper onClick={handler}>{content}</EditWrapper>;
  }
}

export default withRouter(decorate(AllocatedItemsFormatter));
