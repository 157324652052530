import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import {
  Div,
  Text1,
  EigthSizeDotIcon,
  MediumFilledButton
} from "components/Base";
import { actions } from "EventLight/Portals/Portals/model";
import { STATUS } from "EventLight/Portals/Portals/constants";
import Tooltip from "components/Global/Tooltip";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

const decorate = connect(
  state => ({
    canDo: canEventUserDo(state)
  }),
  {
    editForm: actions.editForm,
    submitForm: actions.submitForm,
    viewPortalAs: actions.viewPortalAs,
    goToPortalSettings: actions.goToPortalSettings
  }
);

const PortalRow = ({ row, goToPortalSettings, label, labelPlural, canDo }) => (
  <Div
    bra={1}
    bg="white"
    shadow={{
      default: 1,
      hover: 2
    }}
    display="row.space-between.center"
    className="hoverContainer"
    p={4}
    mb={2}
  >
    <Div display="row.flex-start.center">
      {row.count_of_pending ? (
        <Tooltip tooltip="Pending Submissions">
          <span>
            <Div
              bra={1}
              bg="neutral1"
              p={1}
              mr={2}
              width={25}
              display="row.center.center"
              style={{
                backgroundColor: "#EEE6F8"
              }}
            >
              <Div fs={0} color="primary8" fw={3}>
                {row.count_of_pending}
              </Div>
            </Div>
          </span>
        </Tooltip>
      ) : null}
      <Div fw={3} fs={4} color="black">
        {row.name}
      </Div>
    </Div>
    <Div display="row.flex-start.center" style={{ position: "relative" }}>
      <Div
        display="row.flex-end.center"
        style={{ position: "absolute", top: 0, right: 0 }}
        bg="white"
        height={1}
        width={300}
        className="showOnHover"
      >
        {/*
        <MediumFilledButton
          mr={2}
          bg="primary8"
          color="white"
          onClick={() => viewPortalAs(row)}
        >
          View as...
        </MediumFilledButton>

        <OptionsPopover data={row} />
        */}
        {canDo(`${row.module_id}_manage`) ? (
          <MediumFilledButton
            ml={2}
            bg="neutral1"
            color="neutral7"
            onClick={() => goToPortalSettings(row)}
          >
            Settings
          </MediumFilledButton>
        ) : null}
        {/*
        <MediumFilledButton
          ml={2}
          bg="neutral1"
          color="neutral7"
          onClick={() => submitForm({ data: row })}
          LeftIcon={AddIcon}
        >
          Submit
        </MediumFilledButton>
        */}
      </Div>
      <Text1>
        {row.total} {row.total !== 1 ? labelPlural : label}
      </Text1>
      {row.label ? (
        <>
          <EigthSizeDotIcon />
          <Text1>{row.label}</Text1>
        </>
      ) : null}
      <Div
        bra={1}
        py={1}
        px={2}
        bg={row.status === STATUS.OPEN ? "teal0" : "danger0"}
        ml={2}
      >
        <Div
          fs={0}
          fw={3}
          color={row.status === STATUS.OPEN ? "teal7" : "danger7"}
        >
          {R.toUpper(row.status)}
        </Div>
      </Div>
    </Div>
  </Div>
);

export default decorate(PortalRow);
