import PropTypes from "prop-types";
import React, { Component } from "react";
import RaisedButton from "components/Atoms/RaisedButton";
import {
  isManifestApproved,
  isManifestRejected
} from "components/Global/Approvals/utils/approvals-helpers";

import getApprovalValue from "utils/value-types/get-value/approval";
import ApproversLabel from "components/Global/Approvals/ApproversLabel";
import { parseApproval } from "components/Global/Approvals/utils/approvals-helpers";
import { Div } from "components/Base";

class ApprovalCellFormatter extends Component {
  reviewRecord = response => {
    const payload = {
      review: {
        // record
        moduleId: this.props.dependentValues.meta.moduleId,
        recordId: this.props.dependentValues.meta.submissionRecordId,
        userId: this.props.user.id,
        // submission
        rowId: this.props.dependentValues.meta.rowId,
        submissionRecordId: this.props.dependentValues.meta.submissionRecordId,
        subformId: this.props.dependentValues.meta.subformId,
        submissionId: this.props.dependentValues.meta.submissionId,
        response
      }
    };

    if (response) {
      return this.props.createReview(payload);
    } else {
      return this.props.removeReview(payload);
    }
  };

  isDenied(value) {
    return isManifestRejected(getApprovalValue(value));
  }

  isApproved(value) {
    return isManifestApproved(getApprovalValue(value));
  }

  getReadOnlyValue = value => {
    if (this.isApproved(value)) {
      return "Approved";
    }
    if (this.isDenied(value)) {
      return "Denied";
    }
    return "Pending";
  };

  render() {
    const { value } = this.props;

    if (this.props.dependentValues.meta.isRowEmpty) {
      return (
        <Div display="row.center.center" fw={3} color="gray3" uppercase>
          Empty
        </Div>
      );
    }

    if (this.props.dependentValues.meta.readOnly) {
      return (
        <Div display="row.center.center" fw={3} color="gray3" uppercase>
          {this.getReadOnlyValue(value)}
        </Div>
      );
    }

    return (
      <ApproversLabel
        {...{
          ...parseApproval({
            record: {
              approval_manifest: getApprovalValue(value)
            },
            userId: this.props.user.id
          }),
          approveLineItems: () => this.reviewRecord("approve"),
          rejectLineItems: () => this.reviewRecord("reject"),
          removeApproval: () => this.reviewRecord(null),
          showMenu: true
        }}
      />
    );
  }
}

export default ApprovalCellFormatter;
