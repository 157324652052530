import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Label from "components/Global-2016/Forms/Label";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class PhoneEditorSettings extends Component {
  @autobind
  onChange(e) {
    this.props.onChange({
      ...this.props.value,
      phoneDisplayType: e.target.value
    });
  }

  render() {
    const { disabled, value: { phoneDisplayType } } = this.props;
    return (
      <div>
        <div className="input-wrapper">
          <Label>Phone Input Type</Label>
          <label styleName="radioWrapper">
            <input
              disabled={disabled}
              onChange={this.onChange}
              checked={phoneDisplayType === "mask"}
              name="phone-input"
              value="mask"
              type="radio"
            />
            <div styleName="radioLabel">US Only</div>
          </label>
          <label styleName="radioWrapper">
            <input
              disabled={disabled}
              onChange={this.onChange}
              checked={phoneDisplayType === "international"}
              name="phone-input"
              type="radio"
              value="international"
            />
            <div styleName="radioLabel">International</div>
          </label>
        </div>
      </div>
    );
  }
}

PhoneEditorSettings.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default PhoneEditorSettings;
