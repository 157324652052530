import * as R from "ramda";

import PropTypes from "prop-types";
import React, { Component } from "react";
import View from "./View";
import { paginatorResults, paginatorHandler } from "components/Base";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_MODULE_FIELD_IDS from "utils/standard-module-field-ids";

const getFilteredRecords = (filter, records) => {
  const formattedRecords = Object.keys(records).map(k => records[k]);
  if (!filter) return formattedRecords;

  const formattedFilter = filter.trim().toLowerCase();
  return formattedRecords.filter(i => i.search.indexOf(formattedFilter) > -1);
};

class Controller extends Component {
  constructor(props) {
    super(props);

    // if module records are already loaded, we can go ahead and seed the incoming
    // linked record ids. otherwise, we'll handle it after the records
    // load incomponentWillReceiveProps
    const toAdd = {};
    if (Object.keys(props.records).length && props.linkedRecordIds) {
      props.linkedRecordIds.forEach(id => {
        if (props.records[id]) {
          toAdd[id] = props.records[id];
        }
      });
    }

    this.state = {
      loading: true,
      filter: "",
      toAdd,
      toRemove: {},
      addedRecordId: null
    };
  }

  componentDidMount() {
    this.getRecords().then(() => {
      this.setState({ loading: false });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.addedRecordId) {
      this.formatPreselectedLinkedRecords({
        selectionMode: nextProps.selectionMode,
        linkedRecordIds: [this.state.addedRecordId],
        records: nextProps.records
      });
    } else if (
      Object.keys(this.props.records).length !==
      Object.keys(nextProps.records).length
    ) {
      this.formatPreselectedLinkedRecords(nextProps);
    }
  }

  onFilterChange = filter => {
    this.setState({ filter }, () => {
      this.props.goToPage(1);
    });
  };

  onRecordSelect = record => {
    // handle: multiple select
    if (this.props.selectionMode === "multiple") {
      return this.setState(state => {
        if (state.toAdd[record.id]) {
          delete state.toAdd[record.id];
        } else {
          state.toAdd[record.id] = record;
        }
        return state;
      });
    }

    // handle: single select
    return this.setState(state => {
      if (state.toAdd[record.id]) {
        delete state.toAdd[record.id];
      } else {
        state.toAdd = { [record.id]: record };
      }
      return state;
    });
  };

  onExistingRecordSelect = record => {
    this.setState(state => {
      if (state.toRemove[record.id]) {
        delete state.toRemove[record.id];
      } else {
        state.toRemove[record.id] = record;
      }
      return state;
    });
  };

  getRecords = async () =>
    this.props.getRecords({
      moduleId: this.props.linkedModuleId,
      options: { eventId: this.props.eventDetails.id }
    });

  formatPreselectedLinkedRecords = props => {
    if (props.linkedRecordIds) {
      this.setState(state => {
        // if single select, reset toAdd
        if (props.selectionMode !== "multiple") {
          state.toAdd = {};
        }

        props.linkedRecordIds.forEach(id => {
          if (props.records[id]) {
            state.toAdd[id] = props.records[id];
            if (id === state.addedRecordId) {
              state.addedRecordId = null;
            }
          }
        });
        return state;
      });
    }
  };

  isSelected = record => Boolean(this.state.toAdd[record.id]);

  isExistingRecordSelected = record => Boolean(this.state.toRemove[record.id]);

  showAddRecordModal = () => {
    const { linkedModuleId } = this.props;
    const defaultValues = {};
    if (linkedModuleId === STANDARD_MODULE_IDS.accounts.id) {
      defaultValues[STANDARD_MODULE_FIELD_IDS.ACCOUNTS.NAME] = {
        type: "text",
        value: this.state.filter
      };
    } else if (linkedModuleId === STANDARD_MODULE_IDS.contacts.id) {
      defaultValues[STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME] = {
        type: "text",
        value: this.state.filter
      };
    }

    this.props.showModal({
      content: (
        <AddRecordModal
          moduleId={linkedModuleId}
          onSave={record => {
            this.setState(
              {
                addedRecordId: record.id,
                filter: null
              },
              () => {
                this.getRecords();
              }
            );
          }}
          values={defaultValues}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  saveAndClose = async () => {
    this.setState({ saving: true });
    const {
      fieldId,
      moduleId,
      recordId,
      linkedModuleId,
      addReferences,
      addValue,
      onSave,
      onDone,
      hideModal,
      records
    } = this.props;
    const recordIds = Object.keys(this.state.toAdd);

    // build + add references
    const references = {
      [linkedModuleId]: {
        records: R.indexBy(
          R.prop("id"),
          // eslint-disable-next-line no-underscore-dangle
          R.map(R.prop(R.__, records), recordIds)
        )
      }
    };

    await addReferences(references);

    // save
    if (onSave) {
      onSave(
        {
          type: "lookup",
          value: {
            moduleId: linkedModuleId,
            records: recordIds
          }
        },
        references
      );
    } else {
      await addValue({
        fieldId,
        moduleId,
        recordId,
        value: {
          type: "lookup",
          value: {
            moduleId: linkedModuleId,
            records: recordIds
          }
        }
      });
    }

    // close
    if (onDone) {
      onDone();
    }

    hideModal();
  };

  render() {
    const { loading, saving, filter, toAdd } = this.state;
    const {
      selectionMode,
      selectedRecords,
      recordIds,
      recordNamePlural,
      recordNameSingular,
      hideModal,
      records,
      //
      currentPage,
      goToPage,
      onSelectResultsPerPage,
      resultsPerPage
    } = this.props;

    const filtered = getFilteredRecords(filter, records).map(r => ({
      ...r,
      selectRecord: () => this.onRecordSelect(r),
      isSelected: this.isSelected(r)
    }));

    const results = paginatorResults({
      records: filtered,
      resultsPerPage,
      currentPage
    });

    return (
      <View
        {...{
          loading,
          saving,
          selectionMode,
          recordIds,
          recordNamePlural,
          recordNameSingular,
          filter,
          results,
          toAdd,
          selectedRecords,
          records,
          onFilterChange: this.onFilterChange,
          hideModal,
          onRecordSelect: this.onRecordSelect,
          isExistingRecordSelected: this.isExistingRecordSelected,
          onExistingRecordSelect: this.onExistingRecordSelect,
          showAddRecordModal: this.showAddRecordModal,
          saveAndClose: this.saveAndClose,
          countOfResults: filtered.length,
          currentPage,
          goToPage,
          onSelectResultsPerPage,
          resultsPerPage
        }}
      />
    );
  }
}

Controller.defaultProps = {
  linkedRecordIds: []
};

Controller.propTypes = {
  records: PropTypes.object.isRequired,
  eventDetails: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  moduleId: PropTypes.string.isRequired,
  fieldId: PropTypes.string.isRequired,
  recordId: PropTypes.string.isRequired,
  linkedModuleId: PropTypes.string.isRequired,
  linkedFieldId: PropTypes.string.isRequired,
  linkedRecordIds: PropTypes.arrayOf(PropTypes.string),
  onSave: PropTypes.func,
  onDone: PropTypes.func
};

export default paginatorHandler(Controller);
