import React from "react";

import { Div } from "components/Base";

import Filters from "./Filters";
import SelectView from "./SelectView";

const Sidebar = ({ attendeeTypes, groupTypes }) => (
  <Div
    bg="white"
    w={58}
    display="column.flex-start.stretch"
    shadow={2}
    style={{
      position: "relative",
      zIndex: 2
    }}
  >
    <Div flex={1} style={{ overflowY: "auto" }}>
      <Div
        bb={1}
        bc="neutral3"
        px={4}
        pt={2}
        mb={2}
        display="column.center.stretch"
        height={80}
      >
        <Div fs={5} fw={3} pt={3} pb={4}>
          Send Emails
        </Div>
      </Div>
      <SelectView mx={4} />
      <Filters
        attendeeTypes={attendeeTypes}
        groupTypes={groupTypes}
        mx={4}
        my={2}
      />
    </Div>
  </Div>
);

export default Sidebar;
