import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Table3Helpers from "components/Global/Table3/Utilities/Helpers";

export default function sortableTable(Table) {
  return class Sortable extends Component {
    constructor(props) {
      super(props);
      this.state = {
        rowOrder: null
      };
    }

    getContextMenus(columns) {
      return Table3Helpers.defaultContextMenus(
        columns,
        { sortRows: this.onRowSort },
        {},
        { rowMenu: [], headerMenu: ["separator", "sortDesc", "sortAsc"] },
        this.props.contextMenuMeta || {
          eventDetails: this.props.eventDetails,
          references: this.props.references
        }
      );
    }

    /**
     * Callback after sorting, returns { rowId: idx, ... }
     */
    @autobind
    handleRowSort(rowOrder) {
      if (this.props.onRowSort) {
        this.props.onRowSort(rowOrder);
      }
    }

    @autobind
    onRowSort(comparer) {
      // sort is a mutator, copy rows to prevent mutation
      const sortedRows = this.props.rows.slice();
      const rowOrder = sortedRows.sort(comparer).reduce((rs, row, index) => {
        rs[row.id] = index;
        return rs;
      }, {});

      this.setState({ rowOrder }, () => {
        this.handleRowSort(rowOrder);
      });
    }

    sortRows(rows, rowOrder) {
      if (rowOrder) {
        const sortedRows = rows.slice();
        return sortedRows.sort((a, b) => rowOrder[a.id] - rowOrder[b.id]);
      }
      return rows;
    }

    @autobind
    getRowOrder() {
      return this.props.roworder || this.state.rowOrder;
    }

    mapIndices({ cellKey, rowIdx, fromRow, toRow, value }) {
      const { rows } = this.props;
      const rowOrder = this.getRowOrder();

      const rowId = Object.keys(rowOrder).find(o => rowOrder[o] === rowIdx);
      const originalFromRowId = Object.keys(rowOrder).find(
        o => rowOrder[o] === fromRow
      );
      const originalToRowId = Object.keys(rowOrder).find(
        o => rowOrder[o] === toRow
      );

      return {
        fromRow: rows.indexOf(rows.find(({ id }) => id === originalFromRowId)),
        rowIdx: rows.indexOf(rows.find(({ id }) => id === rowId)),
        toRow: rows.indexOf(rows.find(({ id }) => id === originalToRowId))
      };
    }

    @autobind
    onCellCopyPaste(data) {
      if (!this.getRowOrder()) {
        return this.props.onCellCopyPaste(data);
      }
      this.props.onCellCopyPaste({ ...data, ...this.mapIndices(data) });
    }

    render() {
      const {
        rows,
        contextMenus,
        columns,
        rowOrder,
        disableSortableTable
      } = this.props;

      // bail if contextMenus null - allows handling of contextmenus outside of Table3
      if (!contextMenus || disableSortableTable) {
        return <Table {...this.props} />;
      }

      // allow handling row order outside of sortableTable - act as a passthrough,
      // inject context menus and hit onRowSort callback to update order on the parent
      const sortedRows = this.sortRows(rows, rowOrder || this.state.rowOrder);

      // inject context sorting
      let contextWithSort = this.getContextMenus(columns.toArray());
      if (contextMenus && contextMenus.headerCellMenu) {
        contextWithSort = {
          ...contextMenus,
          headerCellMenu: [
            ...contextMenus.headerCellMenu,
            ...contextWithSort.headerCellMenu
          ]
        };
      }

      const sortableProps = {
        rows: sortedRows,
        contextMenus: contextWithSort,
        onCellCopyPaste: this.onCellCopyPaste
      };

      return <Table ref="grid" {...this.props} {...sortableProps} />;
    }
  };
}
