/*
 * @TODO: refactor, extract into it's own module
 *
 * copied and adapted from "components/Event/FormsV2/Overview/SubmissionSummary"
 *
 */

import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as R from "ramda";

import getFormVersion from "components/Event/FormsV2/Utils/get-form-version";

import * as userSelectors from "redux/modules/user/selectors";
import * as selectors from "Submission/Editor/selectors";

import Fields from "./Fields";

import { Text4 } from "components/Base";

import submissionEditor from "Submission/Editor";
import submissionSagas from "Submission/Editor/sagas";

submissionEditor.setRootSaga(submissionSagas);

const decorate = connect(state => ({
  user: userSelectors.user(state),
  form: selectors.form(state),
  details: selectors.eventDetails(state),
  submissionProp: selectors.submission(state),
  // readOnly: userSelectors.getReadOnly(state, { form: selectors.form(state) }),
  showAdminFields: userSelectors.getShowAdminFields(state, {
    form: selectors.form(state)
  })
}));

class SubmissionSummary extends Component {
  static propTypes = {
    readOnly: PropTypes.bool,
    details: PropTypes.object.isRequired,
    form: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    showAdminFields: PropTypes.bool
  };

  static defaultProps = { readOnly: false };

  getMetaData = (fields, submission, rowData = {}, field, subform) => {
    const version = getFormVersion(submission.form);

    const { readOnly, form, details, submissionProp } = this.props;

    const commonMeta = {
      readOnly,
      changes: R.propOr([], "changes", submission),
      columnId: field.id,
      columns: fields,
      submission,
      columnSettings: {
        ...field.settings,
        suppressLinkToRecord: true
      },
      contactId: submission.contact_id,
      eventDetails: details,
      eventId: details.id,
      formId: form.id,
      isApprovingForm: true,
      isResponseLocked: submission.is_locked,
      rowId: rowData.id,
      via: "requests",
      viewingContactVia: { viaFormRequests: form.id }
    };

    switch (version) {
      case 3: {
        return {
          ...rowData,
          meta: {
            ...commonMeta,
            subformId: R.prop("id", subform),
            submissionRecordId: R.path(
              [
                "values",
                R.prop("id", subform),
                "value",
                "submissions",
                rowData.id,
                "submission_record_id"
              ],
              submissionProp
            ),
            submissionId: submission.id,
            moduleId: R.path(["settings", "moduleId"], subform)
          }
        };
      }
      default:
        return {
          ...rowData,
          meta: commonMeta
        };
    }
  };

  render() {
    const { readOnly, showAdminFields, form } = this.props;

    return (
      <Fragment>
        <Text4 bold ml={8} my={3}>
          Submission
          {form && form.name ? `: ${form.name}` : " Data:"}
        </Text4>
        <Fields
          {...{
            readOnly,
            showAdminFields,
            getMetaData: this.getMetaData
          }}
        />
      </Fragment>
    );
  }
}

export default decorate(SubmissionSummary);
