/* eslint-disable react/prop-types */

import React, { useState } from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { getters, actions } from "Orders/ImportModal";
import {
  Div,
  Text0,
  Text1,
  Text2,
  Text4,
  TinyToggle,
  InfoIcon,
  LeftArrowIcon,
  MediumInsetInput,
  CloseIcon,
  Dropdown,
  ErrorIcon,
  SettingsIcon,
  MediumFilledButton
} from "components/Base";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import Tooltip from "components/Global/Tooltip";
import {
  DEFAULT_VALUE,
  DEFAULT_VALUE_FIELD_WIDTH,
  FIELD_IDS
} from "Orders/ImportModal/constants";
import {
  isContactsModule,
  isContactsOrAccountsModule
} from "Orders/ImportModal/selectors/customizeDownload";
import SelectMoreModal from "./SelectMoreModal";
import SelectItemsModal from "./SelectItemsModal";
import HandleDuplicatesModal from "./HandleDuplicatesModal";

import { noop } from "utils/General";

const RowLayout = ({ onClick = noop, border, children, hover }) => (
  <Div
    display="row.flex-start.center"
    onClick={onClick}
    bb={border ? 1 : 0}
    bc="neutral2"
    p={2}
    bg={hover ? { default: "transparent", hover: "neutral2" } : "transparent"}
  >
    {children}
  </Div>
);

const ButtonRow = ({
  primaryAction = noop,
  icon,
  border = false,
  name = ""
}) => (
  <RowLayout border={border}>
    <MediumFilledButton
      bg="gray1"
      color="gray6"
      LeftIcon={icon}
      onClick={() => primaryAction()}
    >
      {name}
    </MediumFilledButton>
  </RowLayout>
);

const fieldStatus = field => {
  if (field.required) {
    return "*required";
  } else if (field.recommended) {
    return "*recommended";
  }
  return "";
};

const decorate = connect(
  state => ({
    importedColumns: getters.importedColumns(state),
    fieldsGroups: getters.fieldsGroups(state),
    selectMoreModal: getters.selectMoreModal(state),
    selectItemsModal: getters.selectItemsModal(state),
    handleDuplicatesModal: getters.handleDuplicatesModal(state),
    isContactsImport: isContactsModule(state),
    isContactsOrAccountsModule: isContactsOrAccountsModule(state)
  }),
  {
    toggleGroup: actions.toggleGroup,
    toggleField: actions.toggleField,
    setColumnValue: actions.setColumnValue,
    setDefaultValue: actions.setDefaultValue,
    resetDefaultValue: actions.resetDefaultValue,
    showModal: actions.showModal,
    showHandleDuplicatesModal: actions.showHandleDuplicatesModal,
    setSelectedRecordTypeId: actions.setSelectedRecordTypeId
  }
);

const groupFieldsSorting = (groupA, groupB) => {
  if (groupA.name < groupB.name) {
    return -1;
  }
  if (groupA.name > groupB.name) {
    return 1;
  }
  return 0;
};

const FieldGroups = ({
  isContactsOrAccountsModule,
  fieldsGroups,
  importedColumns,
  toggleGroup,
  toggleField,
  setColumnValue,
  setDefaultValue,
  resetDefaultValue,
  selectMoreModal,
  showModal,
  selectItemsModal,
  handleDuplicatesModal,
  setSelectedRecordTypeId,
  isContactsImport = false
}) => {
  const [importingPersonFields, setImportingPersonFields] = useState(true);

  const optionsWithDefaultValue = importedColumns.map(option => ({
    value: option,
    label: option
  }));

  if (isContactsOrAccountsModule) {
    optionsWithDefaultValue.push({
      value: DEFAULT_VALUE,
      label: "Enter a default value"
    });
  }

  const shouldLimitAccountFieldsForContactsImport = moduleId =>
    !(moduleId === STANDARD_MODULE_IDS.accounts.id && isContactsImport);

  const sortedGroupFields = fieldsGroups?.sort(groupFieldsSorting);

  const drawFields = group => {
    return R.compose(
      R.map(field => (
        <Div
          display="row.space-between.center"
          key={field.id}
          py={1}
          bb={1}
          bc="neutral3"
          style={{
            minHeight: 50
          }}
        >
          <Div display="row">
            <TinyToggle
              active={field.selected === true}
              onClick={() => {
                toggleField({
                  groupId: group.id,
                  fieldId: field.id,
                  fieldSelected: field.selected,
                  fieldRequired: field.required,
                  groupLocked: group.locked
                });
              }}
            />
            <Text2 bold ml={2}>
              {field.name}
            </Text2>
          </Div>
          {R.and(
            R.propEq("selected", true, field),
            R.propEq("supported", true, field)
          ) && (
            <Div display="row.flex-start.center" ml={2}>
              <Text1 color="neutral5" bold>
                {fieldStatus(field)}
              </Text1>
              <LeftArrowIcon size={20} color="black" mr={1} ml={1} />
              {field.column === DEFAULT_VALUE ? (
                <Div display="row.flex-start.center">
                  <MediumInsetInput
                    // 188 is the exact size that fits
                    // alongside the  CloseIcon
                    width={DEFAULT_VALUE_FIELD_WIDTH}
                    // Normalize appearance with the rest of the fields
                    style={{
                      boxShadow: "none",
                      fontSize: "16px",
                      fontWeight: "normal"
                    }}
                    color={"neutral8"}
                    value={field.defaultValue}
                    onChange={defaultValue => {
                      setDefaultValue({
                        groupId: group.id,
                        fieldId: field.id,
                        defaultValue
                      });
                    }}
                    continuous
                  />
                  <CloseIcon
                    size={20}
                    ml={3}
                    onClick={() => {
                      resetDefaultValue({
                        groupId: group.id,
                        fieldId: field.id
                      });
                    }}
                  />
                </Div>
              ) : (
                <Dropdown
                  selected={field.column}
                  placeholder="Choose a CSV column"
                  width="220px"
                  options={optionsWithDefaultValue}
                  onChange={event => {
                    const value = R.propOr("", "value", event);
                    setColumnValue({
                      value,
                      groupId: group.id,
                      fieldId: field.id
                    });
                  }}
                />
              )}
            </Div>
          )}
          {R.propEq("supported", false, field) && (
            <Div width={270} display="row.flex-end.center" height={40} pl={3}>
              <ErrorIcon mr={1} />
              <Text0
                ml={1}
              >{`"${field.name}" fields are not currently supported`}</Text0>
            </Div>
          )}
        </Div>
      )),
      R.filter(
        field =>
          field.enabled || field.required || field.id === FIELD_IDS.FULL_NAME
      )
    )(group.fields);
  };

  const drawShowHideButton = group => (
    <Div mt={2} mb={5} display="row">
      <ButtonRow
        name={`Show/Hide ${group.name} ${
          R.contains("fields", R.toLower(group.name)) ||
          R.contains("details", R.toLower(group.name)) ||
          R.contains("zones", R.toLower(group.name)) ||
          R.contains("items", R.toLower(group.name))
            ? ""
            : "fields"
        }`}
        primaryAction={() =>
          showModal({
            groupId: group.id,
            showItemModal: group.showItemModal
          })
        }
        icon={SettingsIcon}
      />
      {/*
            // @TODO: Import Settings
            {(group.id === STANDARD_MODULE_IDS.contacts.id ||
              group.id === STANDARD_MODULE_IDS.accounts.id) && (
              <ButtonRow
                name="Import settings"
                primaryAction={() => {
                  showHandleDuplicatesModal({ groupId: group.id });
                }}
                icon={SettingsIcon}
              />
            )}
            */}
    </Div>
  );

  const drawOptions = (group = {}, index) => {
    return (
      R.length(R.propOr([], "options", group)) > 0 && (
        <Div display="row.space-between" py={3} bb={1} bc="neutral3">
          <Text1>{R.replace("Fields", "Options", group.name)}</Text1>
          {group.options.map((option, optionIndex) => (
            <Div display="row.flex-start.center">
              <TinyToggle
                active={
                  fieldsGroups[index]?.options[optionIndex]?.name === true
                }
                onClick={() => {}}
              />
              <Text0 ml={2}>{option.name}</Text0>
            </Div>
          ))}
        </Div>
      )
    );
  };

  const drawRecordTypes = group => {
    return group.recordTypes ? (
      <Div
        display="row.space-between.center"
        py={1}
        bb={1}
        bc="neutral3"
        style={{
          minHeight: 50
        }}
      >
        <Div display="row">
          <Text2 bold ml={2}>
            Record Type
          </Text2>
        </Div>

        <Div display="row.flex-start.center" ml={2}>
          <LeftArrowIcon size={20} color="black" mr={1} ml={1} />

          <Dropdown
            selected={group.selectedRecordTypeId}
            placeholder="Select a record type"
            width="220px"
            usePortal
            options={group.recordTypes.map(option => ({
              value: option.id,
              label: option.name
            }))}
            onChange={event => {
              const value = R.propOr("", "value", event);
              setSelectedRecordTypeId({
                groupId: group.id,
                recordTypeId: value
              });
            }}
          />
        </Div>
      </Div>
    ) : null;
  };

  const drawSectionHeader = group => {
    return (
      <Div
        display="row.space-between.center"
        bb={1}
        bc="neutral2"
        height={30}
        pb={3}
      >
        <Div display="row.flex-start.center">
          {group.name.toLowerCase() === "person fields" && (
            <TinyToggle
              mr={3}
              active={group.selected}
              onClick={() => {
                setImportingPersonFields(!importingPersonFields);
                toggleGroup({ id: group.id });
              }}
            />
          )}
          <Text4 bold mr={1}>
            {group.name}
          </Text4>
          {group.description && group.description.length ? (
            <Tooltip placement="top" tooltip={group.description}>
              <span style={{ display: "flex", cursor: "pointer" }}>
                <InfoIcon />
              </span>
            </Tooltip>
          ) : null}
        </Div>
        <Div width={198}>
          <Text4 bold>Column Headers</Text4>
        </Div>
      </Div>
    );
  };

  return (
    <Div display="row.flex-start.stretch" flex={1} width={1} px={4} pb={4}>
      {selectMoreModal && <SelectMoreModal />}
      {selectItemsModal && <SelectItemsModal />}
      {handleDuplicatesModal && <HandleDuplicatesModal />}
      <Div px={2} display="column" width={1}>
        {sortedGroupFields.map((group, index) => (
          <Div key={group.name} mt={5} width={1}>
            {drawSectionHeader(group)}
            {group.name.toLowerCase() === "person fields" &&
            !importingPersonFields ? null : (
              <>
                {drawRecordTypes(group)}
                {drawFields(group)}
                {drawOptions(group, index)}
                {shouldLimitAccountFieldsForContactsImport(group.id) &&
                  drawShowHideButton(group)}
              </>
            )}
          </Div>
        ))}
      </Div>
    </Div>
  );
};

export default decorate(FieldGroups);
