import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../model";
import { Div, Text1, Text2 } from "components/Base";
import { addS } from "utils/General";
import GroupCard from "./GroupCard";
import EmptyCard from "./EmptyCard";

const decorate = connect(
  state => ({
    accounts: getters.accounts(state)
  }),
  {
    selectAccount: actions.selectAccount
  }
);

const GroupCards = ({ accounts, selectAccount, showEmpty }) => (
  <Div mb={3}>
    {R.length(accounts) > 0 ? (
      <Text1 bold mb={2} color="neutral6">
        {R.length(accounts)} group result{addS(R.length(accounts))}
      </Text1>
    ) : (
      <Text2>No group results</Text2>
    )}
    {R.map(
      account => (
        <GroupCard {...account} selectAccount={selectAccount} />
      ),
      accounts
    )}
    {showEmpty && (
      <EmptyCard label="person" onClick={() => selectAccount({})} />
    )}
  </Div>
);

export default decorate(GroupCards);
