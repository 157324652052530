import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions } from "../../model";
import { getUpdateModalData, getIsTaskSaveBtnDisabled } from "../../selectors";
import { EDIT_MODAL_DATA, DATE_FORMAT } from "../../constants";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import {
  Toggle,
  Input,
  DateInput,
  TextAreaInput,
  Dropdown
} from "ui-kit/Form/View";
import { BigFilledButton } from "components/Base";
const decorate = R.compose(
  connect(
    (state, props) => ({
      data: getUpdateModalData(state, props),
      isTaskSaveBtnDisabled: getIsTaskSaveBtnDisabled(state, props)
    }),
    {
      hideTaskModal: () => actions.setShowOpenTaskModal(false),
      saveModalData: actions.updateTaskAssigned
    }
  ),
  CSSModules(css)
);

const methodOptions = [
  { id: "click", label: "Task is Clicked" },
  { id: "manual", label: "Manual" }
];

const taskModal = ({
  hideTaskModal = true,
  data = null,
  saveModalData = false,
  isTaskSaveBtnDisabled
}) => (
  <MiniModalWrapper
    showModal
    hideModal={hideTaskModal}
    title={R.length(R.keys(data)) ? "Edit Task" : "Add Task"}
    display="column.flex-start.stretch"
    width={450}
    height={600}
  >
    <div styleName="edit-modal">
      <div styleName="body">
        <div styleName="fieldWrapper">
          <div styleName="label">
            Task <span style={{ color: "red" }}>*</span>
          </div>
          <Input
            fieldId={EDIT_MODAL_DATA.NAME}
            iniValue={R.prop(EDIT_MODAL_DATA.NAME, data) || ""}
            size="big"
            width={1}
          />
        </div>
        <div styleName="fieldWrapper">
          <div styleName="label">Description</div>
          <TextAreaInput
            fieldId={EDIT_MODAL_DATA.DESCRIPTION}
            width={1}
            iniValue={R.prop(EDIT_MODAL_DATA.DESCRIPTION)(data) || ""}
          />
        </div>
        <div styleName="fieldWrapper">
          <div styleName="label">Required</div>
          <Toggle
            size="small"
            fieldId={EDIT_MODAL_DATA.REQUIRED}
            iniValue={R.prop(EDIT_MODAL_DATA.REQUIRED, data)}
            bg="green6"
            className="toogle-required"
          />
        </div>
        <div styleName="fieldWrapper">
          <div styleName="label">Due Date</div>
          <DateInput
            fieldId={EDIT_MODAL_DATA.DUEAT}
            iniValue={R.prop(EDIT_MODAL_DATA.DUEAT, data) || ""}
            format={DATE_FORMAT}
            size="big"
            inputType="outline"
            showTime
            showCalendar
            showClearBtn
          />
        </div>
        <div styleName="fieldWrapper">
          <div styleName="label">On click, go to URL (Optional)</div>
          <Input
            fieldId={EDIT_MODAL_DATA.URL}
            iniValue={R.prop(EDIT_MODAL_DATA.URL, data) || ""}
            size="big"
            width={1}
          />
        </div>
        <div styleName="fieldWrapper">
          <div styleName="label">
            Mark as complete when <span style={{ color: "red" }}>*</span>
          </div>
          <Dropdown
            fieldId={EDIT_MODAL_DATA.METHOD}
            iniValue={R.prop(EDIT_MODAL_DATA.METHOD, data) || null}
            width="100%"
            clearable={false}
            options={methodOptions}
            placeholder="Select method..."
            usePortal
          />
        </div>
      </div>
      <div styleName="footer">
        <BigFilledButton
          bg="primary7"
          disabled={isTaskSaveBtnDisabled}
          onClick={saveModalData}
        >
          Save
        </BigFilledButton>
      </div>
    </div>
  </MiniModalWrapper>
);
export default decorate(taskModal);
