import React from "react";
import { Div } from "components/Base";
import { connect } from "react-redux";

import { noop } from "utils/General";

import { actions, getters } from "Modules/Dashboard";
import { RightIcon, LeftIcon } from "components/Base";
import CollapseButton from "Modules/Dashboard/View/Sidebar/CollapseButton";

const decorate = connect(
  state => ({
    collapsed: getters.collapsed(state) // no props, use the default instance
  }),
  { onToggleCollapsed: () => actions.toggleCollapse() } // no bind, use the default instance
);

const SidebarWrapper = ({
  sidebar,
  collapsed = true,
  onToggleCollapsed = noop,
  children,
  ...styleProps
}) => (
  <Div height={1} width={1} display="row.flex-start.stretch">
    <Div
      height={1}
      width={collapsed ? 50 : 250}
      style={{
        position: "relative",
        backgroundColor: "#813CD2",
        overflow: "auto",
        flexShrink: 0
      }}
    >
      {collapsed ? null : sidebar}
      {collapsed ? (
        <Div
          width={1}
          display="row.center.center"
          style={{
            marginTop: 15
          }}
        >
          <CollapseButton />
        </Div>
      ) : null}
    </Div>
    <Div style={{ flex: "1 1 auto", overflowY: "auto" }} {...styleProps}>
      {children}
    </Div>
  </Div>
);

export default decorate(SidebarWrapper);
