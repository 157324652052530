import PropTypes from "prop-types";
import React, { Component } from "react";
import resolveEditor from "components/Global/StandAloneEditors/utils/resolveEditor";
import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";

export default class SingleEditor extends Component {
  render() {
    const { metaData, onChange, field, value } = this.props;
    const eventDetails = metaData.meta.eventDetails;

    const Editor = resolveEditor(field);
    const editorProps = {
      column: field,
      value,
      rowMetaData: metaData,
      ...resolveEditorProps(field, eventDetails)
    };

    return (
      <Editor
        ref={c => {
          this.editor = c;
        }}
        onChange={onChange}
        {...editorProps}
      />
    );
  }
}

SingleEditor.propTypes = {
  onChange: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  metaData: PropTypes.shape({
    eventDetails: PropTypes.object
  }).isRequired,
  value: PropTypes.object
};
