import React from "react";
import { compose, filter, prop, length, map, groupBy, values } from "ramda";
import {
  Div,
  MediumOutlineButton,
  Text1,
  Text4,
  Popover,
  DownFilledIcon
} from "components/Base";
import Avatar from "./ScalableAvatar";
import { MoreMenu, AssignMenu } from "./Menus";
import GroupedItems from "./GroupedItems";
import { withProps } from "utils/General";

const wrapAction = func => cb => (...args) => {
  cb(...args);
  func();
};

const DownIcon = withProps({
  color: "gray7"
})(DownFilledIcon);

const PersonCard = ({
  color,
  daysOnSite,
  email,
  id,
  initials,
  list = [],
  name,
  onAssign,
  // onChange,
  onCopy,
  onDuplicate,
  onEdit,
  onRemove,
  isRemoving,
  people = [],
  rawAssignments,
  rawMember,
  resourceTypeIds,
  // updatedAt,
  disabled
}) => {
  const Actions = ({ inline }) => (
    <Div
      display={[
        "row.flex-start.center",
        "rpw.flex-start.center",
        !inline ? "column.flex-start.flex-end" : "row.flex-start.center"
      ]}
      flex={1}
    >
      <Popover
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        Label={({ onClick }) => (
          <MediumOutlineButton
            onClick={onClick}
            mr={[1, 1, inline ? 1 : 0]}
            RightIcon={DownIcon}
          >
            <Div
              display="row.space-between.center"
              py={1}
              pl={1}
              width={inline ? 182 : 142}
            >
              <Div fw={3}>Assign</Div>
            </Div>
          </MediumOutlineButton>
        )}
      >
        {() => (
          <AssignMenu
            personId={id}
            people={people}
            onAssign={onAssign}
            onCopy={onCopy}
            person={rawMember}
            resourceTypeIds={resourceTypeIds}
            eventDays={rawMember.contact.days_on_site}
            assignments={map(
              a => ({
                id: a[0].id,
                quantity: length(a),
                issued: compose(length, filter(prop("is_issued")))(a),
                typeId: a[0].item.type.id
              }),
              values(groupBy(a => a.id, rawAssignments))
            )}
          />
        )}
      </Popover>
      <Popover
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
        Label={({ onClick }) => (
          <MediumOutlineButton
            onClick={onClick}
            RightIcon={DownIcon}
            mt={inline ? 0 : 1}
          >
            <Div
              display="row.space-between.center"
              py={1}
              pl={1}
              width={inline ? 102 : 142}
            >
              More
            </Div>
          </MediumOutlineButton>
        )}
      >
        {({ closePopover }) => (
          <MoreMenu
            people={people}
            onEdit={wrapAction(closePopover)(onEdit)}
            onDuplicate={wrapAction(closePopover)(onDuplicate)}
            onRemove={onRemove}
            isRemoving={isRemoving}
          />
        )}
      </Popover>
    </Div>
  );
  return (
    <Div
      mb={1}
      bra={1}
      bg="white"
      display={
        list.length ? "row.flex-start.flex-start" : "row.flex-start.center"
      }
      style={{ flexWrap: "wrap" }}
      shadow={1}
      p={2}
    >
      <Div
        flex={1}
        width={300}
        maxWidth={300}
        display="row.flex-start.flex-start"
      >
        <Avatar
          size={30}
          content={initials}
          style={{ backgroundColor: color }}
          mr={2}
          color="white"
        />
        <Div flex={1}>
          <Text4 bold>{name}</Text4>
          {email && email.length ? <Text1 color="gray6">{email}</Text1> : null}
          {daysOnSite && daysOnSite.length ? (
            <Text1 color="gray6">{daysOnSite}</Text1>
          ) : null}
          {disabled ? null : (
            <Text1 underline mt={3} onClick={onEdit}>
              View Details
            </Text1>
          )}
        </Div>
      </Div>
      <Div
        flex={1}
        display="row.flex-start.flex-start"
        style={{ flexWrap: "wrap" }}
      >
        {list.length ? (
          <Div
            flex={1}
            display="column.flex-start"
            maxWidth={338}
            py={[2, 2, 0]}
          >
            {list.map(l => (
              <GroupedItems
                key={l.id}
                {...l}
                // Force disabled on person cards so that
                // items must be removed via popovefs
                // disabled={disabled}
                disabled
              />
            ))}
          </Div>
        ) : (
          ""
        )}
        {disabled ? null : <Actions inline={!list.length} />}
      </Div>
    </Div>
  );
};

export default PersonCard;
