import { RECEIVE } from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";
import getQuery from "redux/modules/modules/utils/get-query";

export function getModules(eventId, options) {
  return async (dispatch, getState) => {
    try {
      const result = await api.get(
        getState().user.user.credentials,
        eventId,
        options
      );
      dispatch({
        type: RECEIVE,
        payload: result.modules
      });
      return result.modules;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching modules"
          }
        ])
      );
    }
  };
}

export function getModulesWithFields(eventId) {
  return getModules(eventId, { expand: "fields" });
}
