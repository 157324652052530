import PropTypes from "prop-types";
import React from "react";
import SidebarBase from "../Module/Sidebar";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import {
  REPORT_ACCOUNT_ASSETS_ID,
  REPORT_ACCOUNT_DOCUMENTS_ID,
  REPORT_ACCOUNT_FORMS_ID,
  REPORT_ACCOUNT_PEOPLE_ID,
  REPORT_ACCOUNT_CREDENTIALS_ID,
  REPORT_ACCOUNT_CATERING_ID
} from "components/Event/Module/utils/views";

const isModuleEnabled = (enabledModules, moduleId) =>
  enabledModules && Boolean(enabledModules.find(m => m.id === moduleId));

const REPORTS = [
  {
    id: REPORT_ACCOUNT_FORMS_ID,
    name: "Forms",
    key: "forms",
    enabled: () => false
  },
  {
    id: REPORT_ACCOUNT_DOCUMENTS_ID,
    name: "File Requests",
    key: "documents",
    enabled: enabledModules =>
      isModuleEnabled(enabledModules, STANDARD_MODULE_IDS.documentRequests.id)
  },
  {
    id: REPORT_ACCOUNT_PEOPLE_ID,
    name: "People",
    key: "people",
    enabled: () => true
  },
  {
    id: REPORT_ACCOUNT_ASSETS_ID,
    name: "Assets",
    key: "assets",
    enabled: enabledModules =>
      isModuleEnabled(enabledModules, STANDARD_MODULE_IDS.inventory.id)
  },
  {
    id: REPORT_ACCOUNT_CREDENTIALS_ID,
    name: "Passes",
    key: "credentials",
    enabled: () => false
  },
  {
    id: REPORT_ACCOUNT_CATERING_ID,
    name: "Catering",
    key: "catering",
    enabled: () => false
  }
];

class AccountsNav extends SidebarBase {
  getReports = () =>
    REPORTS.filter(r => r.enabled(this.props.eventDetails.enabled_modules));
}

AccountsNav.propTypes = {
  fetchList: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default AccountsNav;
