import React from "react";
import {
  SuperOutlineButton,
  SuperFilledButton,
  Div,
  CheckCircleIcon,
  OpenInNewIcon,
  LoadingIcon
} from "components/Base";
import * as R from "ramda";
import { withProps } from "utils/General";

import Modal from "components/Global/Modal/Layout/StyleWrapper";

import Customer from "./Customer/View";
import SelectItems from "./SelectItems/View";
import Details from "./Details/View";

import { ToggleFeature } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

const Frame = withProps({
  height: 500
})(Div);

const getEntityType = isAllocation => (isAllocation ? "Allocation" : "Order");

const Confirmation = ({ orderNumber, onManageOrder, hideModal }) => {
  return (
    <Frame display="column.center.center">
      <Div display="column.center.center">
        <CheckCircleIcon color="green5" size={80} mb={5} />
        <Div uppercase color="gray5" fw={3} mb={1}>
          Order Created
        </Div>
        <Div fw={4} color="gray7" fs={7} mb={3}>
          Order #{orderNumber}
        </Div>
        <SuperOutlineButton
          LeftIcon={OpenInNewIcon}
          onClick={onManageOrder}
          width={185}
        >
          Manage Order
        </SuperOutlineButton>
        <SuperFilledButton mt={2} bg="altB5" onClick={hideModal} width={185}>
          DONE
        </SuperFilledButton>
      </Div>
    </Frame>
  );
};

const setWidth = activeStepIndex => {
  if (activeStepIndex === 0) {
    return 500;
  }
  if (activeStepIndex === 1 || activeStepIndex === 2) {
    return 1242;
  }
  return 915;
};

const HeaderTextComponent = ({ activeStepIndex, isAllocation }) => {
  if (activeStepIndex === 0) {
    return <Div>Create {getEntityType(isAllocation)}</Div>;
  }
  return (
    <Div display="row.flex-start.center">
      <Div
        fw={activeStepIndex > 0 ? 3 : 1}
        style={{ opacity: activeStepIndex > 0 ? 1 : 0.5 }}
      >
        Add Items
      </Div>
      <Div mx={3} fw={1} style={{ opacity: 0.5 }}>
        /
      </Div>
      <Div
        fw={activeStepIndex > 1 ? 3 : 1}
        style={{ opacity: activeStepIndex > 1 ? 1 : 0.5 }}
      >
        Order Details
      </Div>
      <Div mx={3} fw={1} style={{ opacity: 0.5 }}>
        /
      </Div>
      <Div
        fw={activeStepIndex > 2 ? 3 : 1}
        style={{ opacity: activeStepIndex > 2 ? 1 : 0.5 }}
      >
        Confirmation
      </Div>
    </Div>
  );
};

const View = ({
  onItemTypeSearchChange,
  onChangeOrderType,
  onNextStep,
  onSelectCustomer,
  onPreviousStep,
  onSave,
  onManageOrder,
  onChangeCustomerAccount,
  onChangeCustomerContact,
  onChangeCustomerContactEmail,
  onCreateCustomerAccount,
  onCreateCustomerContact,
  onSearchAccounts,
  onSearchContacts,
  toggleIsAllocation,
  toggleRequireAssignment,
  //
  loading,
  saving,
  steps,
  activeStepIndex,
  itemTypes,
  activeItemTypeId,
  itemTypeProps,
  isValidStep,
  countOfSelectedItems,
  orderNumber,
  selectedVariants,
  orderType,
  customerAccount,
  customerContact,
  customerContactEmail,
  fulfillmentMethod,
  fulfillmentMethods,
  assignmentOptions,
  requireAssignment,
  orderFields,
  pickupFields,
  shippingFields,
  subtotal,
  total,
  isAllocation,
  removeItem,
  updateVariantZones,
  hideModal
}) => {
  return (
    <Modal
      width={setWidth(activeStepIndex)}
      heading={
        <Div display="row.space-between.center" width={1}>
          <HeaderTextComponent
            activeStepIndex={activeStepIndex}
            isAllocation={isAllocation}
          />
          <ToggleFeature
            flag={flags.IS_LENND_ADMIN.NAME}
            untoggledComponent={Div}
            toggledComponent={withProps({
              fw: 2,
              fs: 2,
              color: "white",
              onClick: toggleIsAllocation,
              children: <span>Create an {getEntityType(!isAllocation)}</span>
            })(Div)}
          />
        </Div>
      }
      bodyStyles={{ padding: 0 }}
    >
      <Div>
        {loading ? (
          <Div py={20} display="row.center.center">
            <LoadingIcon size={60} color="neutral4" />
          </Div>
        ) : (
          R.prop(steps[activeStepIndex], {
            [steps[0]]: (
              <Customer
                customerAccount={customerAccount}
                customerContact={customerContact}
                onSelectCustomer={onSelectCustomer}
              />
            ),
            [steps[1]]: (
              <SelectItems
                {...{
                  onPreviousStep,
                  onNextStep,
                  onItemTypeSearchChange,
                  //
                  itemTypes,
                  activeItemTypeId,
                  itemTypeProps,
                  isValidStep,
                  countOfSelectedItems,
                  subtotal,
                  total,
                  selectedVariants,
                  removeItem,
                  updateVariantZones
                }}
              />
            ),
            [steps[2]]: (
              <Details
                {...{
                  onSave,
                  onPreviousStep,
                  onChangeOrderType,
                  onChangeCustomerAccount,
                  onChangeCustomerContact,
                  onChangeCustomerContactEmail,
                  onCreateCustomerAccount,
                  onCreateCustomerContact,
                  onSearchAccounts,
                  onSearchContacts,
                  toggleRequireAssignment,
                  //
                  saving,
                  isValidStep,
                  isAllocation,
                  orderType,
                  customerAccount,
                  customerContact,
                  customerContactEmail,
                  selectedVariants,
                  fulfillmentMethod,
                  fulfillmentMethods,
                  assignmentOptions,
                  requireAssignment,
                  orderFields,
                  pickupFields,
                  shippingFields,
                  subtotal,
                  total,
                  collapsedDefault: true,
                  removeItem,
                  itemTypes
                }}
              />
            ),
            [steps[3]]: (
              <Confirmation
                {...{
                  onManageOrder,
                  orderNumber,
                  hideModal
                }}
              />
            )
          })
        )}
      </Div>
    </Modal>
  );
};

export default View;
