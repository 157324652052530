import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Helpers from "utils/Global/Helpers";
import TextArea from "react-textarea-autosize";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import Panel from "components/Global-2016/Panel";

@CSSModules(css)
export default class AddNoteForm extends Component {
  @autobind
  addNote(e) {
    e.preventDefault();
    const trimmedNote = this.textArea.value.trim();

    if (!trimmedNote.length) {
      return;
    }

    this.props.addNote(trimmedNote).then(() => this.form.reset());
    // e.target.reset();
  }

  render() {
    const { currentUser } = this.props;

    const image = currentUser.photo_url ? (
      <div
        styleName="userImage"
        style={{ backgroundImage: `url(${currentUser.photo_url})` }}
      />
    ) : (
      <div styleName="userImage">
        {Helpers.buildUserAcronym(currentUser.fname, currentUser.lname)}
      </div>
    );
    return (
      <Panel style={css.panel}>
        <form
          styleName="form"
          onSubmit={this.addNote}
          ref={ref => (this.form = ref)}
        >
          <TextArea
            ref={el => (this.textArea = el)}
            styleName="textarea"
            placeholder="Type here to leave a note..."
          />
          {image}
          <div styleName="buttonContainer">
            <button type="submit" styleName="button">
              add note
            </button>
          </div>
        </form>
      </Panel>
    );
  }
}

AddNoteForm.propTypes = {
  currentUser: PropTypes.object.isRequired,
  addNote: PropTypes.func.isRequired,
  styles: PropTypes.object.isRequired
};
