import { CONTACT_STANDARD_FIELD_IDS } from "components/Event/Contacts/constants";

export default ({ fname, lname, email, phone }) => [
  {
    fieldId: CONTACT_STANDARD_FIELD_IDS.fname,
    value: {
      type: "text",
      value: fname
    }
  },
  {
    fieldId: CONTACT_STANDARD_FIELD_IDS.lname,
    value: {
      type: "text",
      value: lname
    }
  },
  {
    fieldId: CONTACT_STANDARD_FIELD_IDS.email,
    value: {
      type: "text",
      value: email
    }
  },
  {
    fieldId: CONTACT_STANDARD_FIELD_IDS.mobile_phone,
    value: {
      type: "phone",
      value: phone
    }
  }
];
