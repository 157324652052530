import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { actions, getters } from "Forms/WizardModal";
import {
  Div,
  SuperOutlineButton,
  SuperFilledButton,
  RightArrowIcon,
  AddCircleIcon,
  BigFilledButton,
  Text1,
  Text2,
  Text4,
  PopoverMenu,
  LoadingIcon
} from "components/Base";
import { INVENTORY_PAGES } from "Forms/WizardModal/constants";
import Header from "Forms/WizardModal/View/Header";
import FieldItem from "Forms/WizardModal/View/Common/FieldItem";
import {
  getInventoryFormsLanguage,
  shouldEnableSaveForm,
  getSelectedCategories
} from "Forms/WizardModal/selectors";
import resolveFieldTypeName from "components/Global/Table3/FieldTypeIcons/resolve-field-type-name";

const decorate = connect(
  state => ({
    saveBtnEnabled: shouldEnableSaveForm(state),
    inventoryCategories: getSelectedCategories(state),
    language: getInventoryFormsLanguage(state),
    saving: getters.saving(state)
  }),
  {
    back: () =>
      actions.setSelectedInventory(INVENTORY_PAGES.INVENTORY_CATEGORIES),
    goToReview: () => actions.setSelectedInventory(INVENTORY_PAGES.REVIEW),
    getFields: () => actions.getFields(),
    saveForm: () => actions.saveInventoryForm(),
    toggleField: actions.toggleField,
    toggleRequired: actions.toggleRequired
  }
);

const SelectFields = ({
  back,
  handlers,
  saveForm,
  inventoryCategories,
  goToReview,
  saveBtnEnabled,
  language,
  saving,
  toggleField,
  toggleRequired
}) => {
  return (
    <Div width={1} display="column.space-between" height={1} bg="neutral0">
      <Header handlers={handlers} />
      <Div
        bg="neutral0"
        display="column.flex-start.center"
        flex={1}
        p={5}
        pt={0}
        style={{ overflow: "auto" }}
      >
        <Div my={5} display="column.center.center" style={{ flexShrink: 0 }}>
          <Div fs={6} fw={4} color="black">
            {language.header}
          </Div>
          {language.subheader ? (
            <Text2 color="black" mt={2}>
              {language.subheader}
            </Text2>
          ) : null}
        </Div>

        {R.compose(
          R.map(({ name, id, categoryRecordId, fields }) => (
            <Div
              key={id}
              mb={4}
              bg="neutral1"
              width={600}
              bra={2}
              p={3}
              display="column"
              style={{ flexShrink: 0 }}
            >
              <Text4 color="gray7" bold ml={1}>
                {name}
              </Text4>
              <Div display="row.space-between" mt={2} mb={1} px={1}>
                <Text1 color="neutral6">Include Question</Text1>
                <Text1 color="neutral6">*Require</Text1>
              </Div>
              <Div display="column" width={1}>
                {R.map(
                  field => (
                    <FieldItem
                      key={field.id}
                      name={field.name}
                      fieldId={field.id}
                      categoryId={id}
                      selected={field.selected}
                      required={field.required}
                      handlers={handlers}
                      toggleField={toggleField}
                      toggleRequired={toggleRequired}
                      description={resolveFieldTypeName(field.type)}
                    />
                  ),
                  fields
                )}
                <Div display="row.space-between" width={1} mt={1}>
                  <PopoverMenu
                    Label={({ onClick }) => (
                      <BigFilledButton
                        LeftIcon={AddCircleIcon}
                        bg="neutral2"
                        color="neutral7"
                        onClick={onClick}
                      >
                        Add question
                      </BigFilledButton>
                    )}
                    menuItems={[
                      [
                        "Add new question",
                        () =>
                          handlers.showAddFieldModal({
                            moduleId: id
                          })
                      ],
                      [
                        "Add new item request question",
                        () =>
                          handlers.showAddFieldModal({
                            moduleId: id,
                            categoryRecordId,
                            type: "catalog-item"
                          })
                      ]
                    ]}
                  />
                  {/*
                    <BigFilledButton
                      LeftIcon={AssignmentIcon}
                      bg="neutral2"
                      color="neutral7"
                    >
                      Copy question from...
                    </BigFilledButton>
                    */}
                </Div>
              </Div>
            </Div>
          )),
          R.filter(R.propEq("selected", true))
        )(inventoryCategories)}
      </Div>

      <Div
        display="row.space-between.center"
        width={1}
        height={90}
        bt={1}
        bc="neutral2"
        bg="white"
        px={7}
        style={{ flexShrink: 0 }}
      >
        {saving ? (
          <Div />
        ) : (
          <SuperOutlineButton onClick={back}>Back</SuperOutlineButton>
        )}

        {saving ? (
          <SuperFilledButton ml={3} bg="altB5" RightIcon={LoadingIcon}>
            Saving
          </SuperFilledButton>
        ) : (
          <SuperFilledButton
            ml={3}
            bg="altB5"
            onClick={
              language.onClickCreateForm ? () => saveForm() : () => goToReview()
            }
            RightIcon={language.onClickCreateForm ? undefined : RightArrowIcon}
            disabled={!saveBtnEnabled}
          >
            {language.submit}
          </SuperFilledButton>
        )}
      </Div>
    </Div>
  );
};

export default decorate(SelectFields);
