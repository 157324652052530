import * as R from "ramda";
import { createContext } from "redux-mvc";

import manage from "EventLight/Common/Manage";
import ManageView, {
  contextConfig,
  ManageProps
} from "EventLight/Common/Manage/View";

const decorate = R.compose(
  ManageProps,
  createContext({ module: manage, ...contextConfig })
);

export default decorate(ManageView);
