import React from "react";
import PropTypes from "prop-types";
import { getStyle } from "./utils";
import getValue from "utils/value-types/get-value/text";
import { Div } from "components/Base";

const TextCellFormatter = ({ truncate = true, value = "", ...otherProps }) => (
  <Div truncate={truncate} style={getStyle(otherProps)} title={getValue(value)}>
    {getValue(value)}
  </Div>
);

TextCellFormatter.propTypes = {
  truncate: PropTypes.bool,
  value: PropTypes.string
};

export default TextCellFormatter;
