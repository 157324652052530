/* eslint-disable no-underscore-dangle */

import * as R from "ramda";

import { createSelector } from "reselect";

import { getters } from "./model";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import resolveFieldTypeName from "components/Global/Table3/FieldTypeIcons/resolve-field-type-name";
import resolveFieldTypeIconName from "components/Global/Table3/FieldTypeIcons/resolve-field-type-icon-name";

const isPrimaryField = field => R.pathOr(false, ["is_primary_field"], field);
const isLocked = field => R.propEq("source", "standard")(field);

const formatFields = (fields, search) => {
  const hasSearch = search && search.length;
  const term = hasSearch ? search.toLowerCase().trim() : null;
  return R.compose(
    R.filter(fg => (hasSearch ? fg.countOfFields : true)),
    R.map(fg => {
      const fields = R.compose(
        R.filter(fgf =>
          hasSearch ? fgf.name.toLowerCase().includes(term) : true
        ),
        R.map(fgf => ({
          ...fgf.field,
          isPrimaryField: isPrimaryField(fgf.field),
          canTogglePrimaryField:
            ["text"].includes(fgf.field.type) &&
            R.pathOr("", ["field", "source"], fgf.field) !== "standard",
          typeName: resolveFieldTypeName(fgf.field.type),
          typeIcon: resolveFieldTypeIconName(fgf.field.type),
          locked: isLocked(fgf.field)
        }))
      )(fg.fields);

      return {
        ...fg,
        countOfFields: fields.length,
        fields
      };
    })
  )(fields);
};

// filters: fields
export const getGroupFields = createSelector(
  getters.groupFields,
  getters.groupSearch,
  (fields, search) => {
    return formatFields(fields, search);
  }
);

export const getPeopleFields = createSelector(
  getters.peopleFields,
  getters.peopleSearch,
  (fields, search) => {
    return formatFields(fields, search);
  }
);
