import { createModule, createHandlers } from "redux-mvc";

const iniState = {
  isActive: false
};

const handlers = createHandlers({ iniState, namespace: "UIFocus" });

const { actions, getters } = handlers;

const module = createModule(handlers);

export { actions, getters };

export default module;
