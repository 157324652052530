import { createSelector } from "reselect";
import { getters } from "SendEmailModal";
import { MODES } from "./constants";
import * as R from "ramda";

export const getIsCurrentStateValid = createSelector(
  getters.mode,
  getters.activeStepIndex,
  getters.message,
  getters.subject,
  getters.selectedOptions,
  getters.messagePreviews,
  (
    mode,
    activeStepIndex,
    message,
    subject,
    selectedOptions
    // messagePreviews
  ) => {
    switch (activeStepIndex) {
      case 0:
        if (mode === MODES.SMS) {
          return message.length && selectedOptions;
        }
        return message.length && subject.length && selectedOptions;
      // @NOTE: Removing since we don't validate valid vs invalid on client anymore
      // case 1:
      //   if (mode === MODES.SMS) {
      //     return Boolean(
      //       messagePreviews.filter(r => r.meta.hasValidPhone).length
      //     );
      //   }
      //   return Boolean(
      //     messagePreviews.filter(r => r.meta.hasValidEmail).length
      //   );

      default:
        return true;
    }
  }
);

// pagination
export const getPageSize = R.compose(R.prop("pageSize"), getters.pagination);

export const getCurrentPage = R.compose(R.prop("page"), getters.pagination);

export const getTotalCount = R.compose(R.prop("total"), getters.pagination);
