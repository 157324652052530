import React from "react";
import {
  Div,
  Text0,
  FontIcon,
  SmallOutlineInput,
  CheckIcon,
  CloseIcon
} from "components/Base";
import Pill from "ui-kit/Pill";
import Helpers from "utils/Global/Helpers";

export const Paper = ({ children, ...props }) => (
  <Div
    shadow={2}
    mb={2}
    width={1}
    display="column.flex-start.stretch"
    bg="white"
    style={{ overflow: "initial" }}
    bra={2}
    {...props}
  >
    {children}
  </Div>
);

export const SubmitterCard = ({ children, avatar = {}, ...props }) => (
  <Div display="row.flex-start.center" {...props}>
    <Div
      pill
      size={40}
      display="row.center.center"
      style={{
        backgroundColor: avatar.recordTypeBackgroundColor
      }}
    >
      <FontIcon
        style={{
          color: avatar.recordTypeColor
        }}
      >
        {avatar.recordTypeIcon}
      </FontIcon>
    </Div>

    {children}
  </Div>
);

export const NumberPill = ({ children = 0, alert, ...styleProps }) => (
  <Pill
    left
    right
    height={14}
    bg={alert ? "danger7" : "neutral3"}
    {...styleProps}
  >
    <Text0 bold color={alert ? "white" : undefined}>
      {children}
    </Text0>
  </Pill>
);

export const EditableValue = ({
  toggled,
  placeholder,
  updatedValue,
  setUpdatedValue,
  saveUpdatedValue,
  setToggle,
  originalValue,
  validateEmail,
  readOnly,
  ...props
}) => {
  const isValid =
    toggled && validateEmail ? Helpers.isValidEmail(updatedValue) : true;

  if (readOnly) {
    return originalValue && originalValue.length ? (
      <Div display="row.flex-start.center" {...props}>
        {originalValue}
      </Div>
    ) : null;
  }

  return toggled ? (
    <Div display="row.flex-start.center" {...props}>
      <SmallOutlineInput
        continuous
        placeholder={placeholder}
        value={updatedValue}
        onChange={setUpdatedValue}
      />
      <Div
        ml={2}
        b={1}
        bc={isValid ? "altA5" : "gray5"}
        bra={1}
        display="row.center.center"
        onClick={
          isValid
            ? () => {
                saveUpdatedValue(updatedValue);
                setToggle(false);
              }
            : undefined
        }
      >
        <CheckIcon color={isValid ? "altA5" : "gray5"} />
      </Div>
      <Div
        ml={1}
        b={1}
        bc="gray5"
        bra={1}
        display="row.center.center"
        onClick={() => setToggle(false)}
      >
        <CloseIcon />
      </Div>
    </Div>
  ) : (
    <Div
      fs={1}
      underline={readOnly ? false : true}
      color={
        readOnly
          ? "gray6"
          : {
              default: "gray6",
              hover: "gray7"
            }
      }
      onClick={
        readOnly
          ? undefined
          : () => {
              setUpdatedValue(originalValue);
              setToggle(true);
            }
      }
      {...props}
    >
      {originalValue && originalValue.length ? originalValue : placeholder}
    </Div>
  );
};
