import * as R from "ramda";
import { createSelector } from "reselect";

import { getters } from "./index";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

export const getReportGroups = createSelector(
  canEventUserDo,
  getters.runningReportIds,
  getters.reports,
  (canDo, runningReportIds, reports) => {
    const groups = R.compose(
      R.map(rg => ({
        ...rg,
        reports: R.map(r => {
          const runningId = `${r.id}_${R.path(["meta", "scopeId"])(r)}`;
          return {
            ...r,
            running_id: `${runningId}`,
            running: runningReportIds.includes(runningId)
          };
        })(rg.reports)
      })),
      R.filter(rg => canDo(`${rg.id}_read`))
    )(reports);

    return groups;
  }
);
