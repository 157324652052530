import React from "react";
import {
  Div,
  SmallOutlineButton,
  MoreIcon,
  PopoverMenu,
  HalfSizeDotIcon,
  Cards,
  DragIcon,
  EditIcon,
  MediumOutlineInput,
  CloseIcon,
  CheckIcon
} from "components/Base";
import { withState, compose } from "utils/General";
import DeleteConfirmation from "components/Atoms/DeleteConfirmation";

const VariantCard = compose(
  withState("editedName", "setName", ({ editableName }) => editableName),
  withState("isEditingName", "toggleEditingName", false)
)(
  ({
    name,
    color,
    days,
    prices,
    disabled,
    onEdit,
    saveUpdatedName,
    editedName,
    setName,
    isEditingName,
    toggleEditingName,
    editableName,
    onDelete,
    countOfVariants
  }) => (
    <Div
      display="row.space-between.center"
      shadow={1}
      bra={1}
      p={3}
      mb={2}
      bg="white"
      className="hoverContainer"
    >
      <Div display="row.flex-start.center">
        {disabled ? null : (
          <DragIcon
            mr={1}
            style={{
              flexShrink: 0
            }}
          />
        )}
        <Div display="row.flex-start.center" mr={2}>
          <HalfSizeDotIcon
            size={15}
            style={{
              color: color || "#ccc",
              flexShrink: 0
            }}
          />
        </Div>
        <Div display="column">
          {isEditingName ? (
            <Div display="row.flex-start.center">
              <MediumOutlineInput
                value={editedName}
                onChange={v => setName(v)}
                continuous
              />

              <Div
                ml={2}
                b={1}
                bc={
                  editedName.length || countOfVariants === 1 ? "altA5" : "gray5"
                }
                bra={1}
                display="row.center.center"
                onClick={() => {
                  saveUpdatedName(editedName);
                  toggleEditingName(false);
                }}
              >
                <CheckIcon
                  color={
                    editedName.length || countOfVariants === 1
                      ? "altA5"
                      : "gray5"
                  }
                />
              </Div>

              <Div
                ml={1}
                b={1}
                bc="gray5"
                bra={1}
                display="row.center.center"
                onClick={() => {
                  toggleEditingName(false);
                  setName(editableName);
                }}
              >
                <CloseIcon />
              </Div>
            </Div>
          ) : (
            <Div display="row.flex-start.center" color="gray7" fw={3}>
              {name}
              <EditIcon
                className="showOnHover"
                size={15}
                ml={1}
                color={{
                  hover: "gray6",
                  default: "gray5"
                }}
                onClick={() => toggleEditingName(true)}
              />
            </Div>
          )}
          {days || prices ? (
            <Div display="row.flex-start.center" color="gray7" fs={1}>
              {prices ? (
                <Div color="yellow9" mr={2}>
                  {prices}
                </Div>
              ) : null}
              <Div>{days}</Div>
            </Div>
          ) : null}
        </Div>
      </Div>
      {disabled ? null : (
        <PopoverMenu
          menuItems={[
            ["Edit Variant", onEdit],
            onDelete
              ? [
                  <DeleteConfirmation
                    button={<div>Delete Variant</div>}
                    handleDelete={onDelete}
                    message="Are you sure you want to delete this variant? This cannot be undone."
                    title="Delete variant?"
                  />,
                  () => {}
                ]
              : null
          ].filter(o => o)}
          Label={({ onClick }) => (
            <SmallOutlineButton onClick={onClick}>
              <MoreIcon color="gray5" fs={4} />
            </SmallOutlineButton>
          )}
        />
      )}
    </Div>
  )
);

const VariantList = Cards(VariantCard, "ITEM_VARIANTS");

const VariantMethod = ({ name, description, onClick }) => (
  <Div
    display="column.center.center"
    bra={1}
    p={3}
    mb={2}
    bc="neutral2"
    b={1}
    color={{
      default: "gray6",
      hover: "gray7"
    }}
    onClick={onClick}
  >
    <Div fs={3} fw={3}>
      {name}
    </Div>
    <Div fs={2}>{description}</Div>
  </Div>
);

const Variants = ({ variants, variantMethods, reorderVariants }) => (
  <Div>
    <VariantList cards={variants} onReorder={reorderVariants} />

    <Div>
      {variantMethods.map(method => (
        <VariantMethod key={method.id} {...method} />
      ))}
    </Div>
  </Div>
);

export default withState("priceLabel", "updateLabel", "")(Variants);
