/* eslint-disable no-underscore-dangle */
import { put, takeEvery, all, fork, call, select } from "redux-saga/effects";
import * as R from "ramda";
import { apiCall } from "App/Data/sagas";
import { registerError } from "redux/modules/errors/actions";
import { actions, getters } from "../model";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { contacts } from "@lennd/value-types/src/constants/standard-modules";
import { userId as getUserId } from "redux/modules/user/selectors";
import { ASSIGNED_DOCUMENT_REQUESTS, ASSIGNED_FORMS } from "../mockData";
import {
  REQUEST_ITEM_SETTINGS_MODAL_FORM_ID,
  AUTO_ASSIGN_ITEM_SETTINGS_MODAL_FORM_ID,
  PERSON_SUBCARDS,
  EDIT_MODAL_DATA
} from "../constants";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import {
  actions as selectRequiredFieldsActions,
  getters as selectRequiredFieldsGetters
} from "Modules/SelectRequiredFieldsModalRefactored/model";
import {
  getMappedSelectRequiredFields,
  getNotDraggableFieldIds,
  getEnabledPeopleTypes,
  getPersonSubtypeData,
  getUpdatePersonData
} from "../selectors";
import { getters as MiniItemsGetters } from "ui-kit/MiniItemsSelector";
import {
  getters as recordTypeModalGetters,
  actions as recordTypeModalActions
} from "ui-kit/RecordTypeModal/model";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { getters as FormGetters } from "ui-kit/Form/model";
import { formatDateTimezone } from "./index";
import { getDataOrdered } from "../utils";

const getStaffPersonnel = function*() {
  const eventId = yield select(getEventId);
  const recordTypeId = yield select(getters.selectedTypeId);
  const userId = yield select(getUserId);

  yield put(actions.setLoadingRecordTypes(true));

  try {
    const [
      { payload: subtypes },
      payload,
      { fields },
      { payload: itemsTypes }
    ] = yield all([
      call(apiCall, {
        method: "get",
        url: `/hub/admin/event/${eventId}/type/${recordTypeId}/subtypes`
      }),
      call(apiCall, {
        method: "get",
        url: `/modules/${contacts.id}/record-types`,
        qs: { eventId, orgId: undefined, userId }
      }),
      call(apiCall, {
        method: "get",
        url: `/modules/${contacts.id}/fields`,
        qs: { eventId }
      }),
      call(apiCall, {
        method: "get",
        url: `/items/types/event/${eventId}`,
        qs: { userId }
      })
    ]);

    const contactReadOnlyFields = resolveReadOnlyFields({
      moduleId: STANDARD_MODULES.contacts.id
    });

    const contactFields = R.compose(
      R.filter(f => !contactReadOnlyFields.includes(f.id)),
      R.propOr([], "fields")
    )(fields);

    yield put(
      actions.setPersonnelInitialData({
        subtypes,
        contactFields,
        recordTypes: R.compose(
          R.map(record => ({
            ...record,
            dueDate: null,
            collectDocuments: ASSIGNED_DOCUMENT_REQUESTS,
            collectSubmissions: ASSIGNED_FORMS
          })),
          R.propOr([], "record_types")
        )(payload),
        loadingRecordTypes: false,
        itemsTypes
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while loading staff and personnel"
        }
      ])
    );
  }
};

const openSelectRequiredFieldsModal = function*({ payload: { id } }) {
  if (id) {
    const columns = yield select(getMappedSelectRequiredFields);
    const notDraggableFieldIds = yield select(getNotDraggableFieldIds);
    const peopleTypes = yield select(getEnabledPeopleTypes);
    const currentType = R.find(R.propEq("id", id), peopleTypes);

    yield all([
      put(
        selectRequiredFieldsActions.setInitialData({
          columns,
          notDraggableFieldIds,
          visibleFields: R.propOr([], "visibleFields", currentType),
          requiredFields: R.propOr([], "requiredFields", currentType),
          fieldOrder: R.propOr([], "fieldOrder", currentType)
        })
      ),
      put(actions.setShowSelectRequiredFieldsModal(true)),
      put(actions.setSelectedModalTypeId(id))
    ]);
  }
};

const hideSelectRequiredFieldsModal = function*() {
  yield put(actions.setShowSelectRequiredFieldsModal(false));
};

const updateSelectedFields = function*() {
  const typeId = yield select(getters.selectedModalTypeId);
  if (typeId) {
    const permissionSetId = yield select(getters.selectedPermissionSetId);
    const visibleFields = yield select(
      selectRequiredFieldsGetters.visibleFields
    );
    const requiredFields = yield select(
      selectRequiredFieldsGetters.requiredFields
    );
    const fieldOrder = yield select(selectRequiredFieldsGetters.fieldOrder);

    try {
      yield call(apiCall, {
        method: "put",
        url: `/account/permission-sets/${permissionSetId}/record-types`,
        data: {
          typeId,
          values: {
            visible_fields: visibleFields,
            field_order: fieldOrder,
            required_fields: requiredFields
          }
        }
      });

      yield all([
        put(actions.getStaffPersonnel()),
        put(actions.setShowSelectRequiredFieldsModal(false))
      ]);
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error occurred while selecting fields"
          }
        ])
      );
    }
  }
};

const updateEnableSelectFields = function*({ payload: { isEnabled, typeId } }) {
  const permissionSetId = yield select(getters.selectedPermissionSetId);

  try {
    yield call(apiCall, {
      method: "put",
      url: `/account/permission-sets/${permissionSetId}/record-types`,
      data: {
        typeId,
        values: {
          is_enabled: isEnabled
        }
      }
    });

    yield all([
      put(actions.getStaffPersonnel()),
      put(actions.setShowSelectRequiredFieldsModal(false))
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while updating enable fields"
        }
      ])
    );
  }
};

const updateEnabledPeopleTypes = function*() {
  const subRecordTypeIds = yield select(MiniItemsGetters.selectedItems);
  const recordTypeId = yield select(getters.selectedTypeId);

  try {
    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/${recordTypeId}/subtypes`,
      data: {
        subRecordTypeIds
      }
    });

    yield all([
      put(actions.getStaffPersonnel()),
      put(actions.setShowPeopleTypesModal(false))
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while updating enabled people types"
        }
      ])
    );
  }
};

const addRecordType = function*() {
  try {
    const moduleId = STANDARD_MODULES.contacts.id;
    const name = yield select(recordTypeModalGetters.name);
    const icon = yield select(recordTypeModalGetters.icon);
    const textColor = yield select(recordTypeModalGetters.textColor);
    const backgroundColor = yield select(
      recordTypeModalGetters.backgroundColor
    );
    const eventId = yield select(getEventId);
    const userId = yield select(getUserId);

    const data = {
      name,
      backgroundColor,
      textColor,
      icon
    };

    const { layout } = yield call(apiCall, {
      method: "post",
      url: `/modules/${moduleId}/layouts`,
      data: { layout: { eventId } },
      qs: { userId }
    });

    yield call(apiCall, {
      method: "post",
      url: `/modules/${moduleId}/record-types`,
      data: {
        recordType: { ...data, layoutId: layout.id }
      },
      qs: { eventId, userId }
    });

    yield all([
      put(showSnackbar({ message: "People type added", action: "OK" })),
      put(recordTypeModalActions.setShowModal(false)),
      put(actions.getStaffPersonnel())
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while adding people type"
        }
      ])
    );
  }
};

const updateCloseDate = function*({ payload: { typeId, val: closeDate } }) {
  try {
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/${recordTypeId}/subtype/${typeId}/details`,
      data: {
        closeDate
      }
    });

    yield put(actions.getStaffPersonnel());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while updating close date"
        }
      ])
    );
  }
};

const onSelectRequestablePersonItems = function*({ payload }) {
  try {
    const variantIds = R.compose(R.filter(id => R.path([id, "qty"])(payload)))(
      R.keys(payload)
    );
    const recordTypeId = yield select(getters.selectedTypeId);
    const subRecordTypeId = yield select(getters.selectedModalPersonId);

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/${recordTypeId}/subtype/${subRecordTypeId}/requestable-items`,
      data: {
        items: R.map(vId => ({
          variantId: vId,
          maxQuantity: null,
          status: "approved",
          price: null
        }))(variantIds)
      }
    });

    yield all([
      put(actions.getStaffPersonnel()),
      put(actions.setShowSelectRequestableItemsModal(false))
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while saving settings"
        }
      ])
    );
  }
};

const onSelectAutoAssignItems = function*({ payload }) {
  try {
    const selectedItems = yield select(getPersonSubtypeData);

    const variantIds = R.compose(
      R.filter(id => R.path([id, "qty"])(payload)),
      R.keys
    )(payload);
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);
    const subRecordTypeId = yield select(getters.selectedModalPersonId);

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/subtype/${subRecordTypeId}/auto-assignment`,
      data: {
        items: R.map(vId => {
          const selectedItem = R.find(R.propEq("id", vId), selectedItems);
          return {
            variantId: vId,
            quantity: `${R.propOr("1", "quantity", selectedItem)}`,
            status: R.propOr("pending", "status", selectedItem)
          };
        }, variantIds)
      }
    });

    yield all([
      put(actions.getStaffPersonnel()),
      put(actions.setShowSelectAutoAssignItemsModal(false))
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while saving settings"
        }
      ])
    );
  }
};

const removeSelectedItem = function*({ payload: { subtypeId, variantId } }) {
  try {
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "delete",
      url: `/hub/admin/${recordTypeId}/subtype/${subtypeId}/requestable-item/${variantId}`
    });

    yield put(actions.getStaffPersonnel());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while deleting item"
        }
      ])
    );
  }
};

const removeSelectedAutoAssignItem = function*({
  payload: { subtypeId, variantId }
}) {
  try {
    const recordTypeId = yield select(getters.selectedTypeId);
    const eventId = yield select(getEventId);

    yield call(apiCall, {
      method: "delete",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/subtype/${subtypeId}/auto-assignment/variant/${variantId}`
    });

    yield put(actions.getStaffPersonnel());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while deleting item"
        }
      ])
    );
  }
};

const updatePersonItemData = function*() {
  const fields = yield select(FormGetters.fields, {
    instanceId: REQUEST_ITEM_SETTINGS_MODAL_FORM_ID
  });
  const maxQuantity = R.pathOr(0, ["max_quantity", "value"], fields) || 0;
  const status = R.pathOr("pending", ["status", "value", 0], fields);

  try {
    const recordTypeId = yield select(getters.selectedTypeId);
    const subtypeId = yield select(getters.selectedModalPersonId);
    const variantId = yield select(getters.selectedModalPersonItemId);
    const personSubCard = yield select(getters.selectedPersonSubCard);
    const eventId = yield select(getEventId);

    if (personSubCard === PERSON_SUBCARDS.AUTO_ASSIGN) {
      yield put(actions.setLoadingPersonAutoAssignItems(true));
    }

    if (personSubCard === PERSON_SUBCARDS.REQUESTABLE) {
      yield put(actions.setLoadingPersonRequestItems(true));
    }

    const url =
      personSubCard === PERSON_SUBCARDS.REQUESTABLE
        ? `/hub/admin/${recordTypeId}/subtype/${subtypeId}/requestable-item/${variantId}`
        : `/hub/admin/event/${eventId}/type/${recordTypeId}/subtype/${subtypeId}/auto-assignment/variant/${variantId}`;

    yield call(apiCall, {
      method: "put",
      url,
      data:
        personSubCard === PERSON_SUBCARDS.REQUESTABLE
          ? {
              maxQuantity: maxQuantity < 0 ? "0" : maxQuantity.toString(),
              status
            }
          : {
              quantity: maxQuantity < 1 ? "1" : maxQuantity.toString(),
              status
            }
    });

    yield all([
      put(actions.setShowUpdatePersonItemModal(false)),
      put(actions.getStaffPersonnel())
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while updating item"
        }
      ])
    );
  }
};

const updatePersonAutoAssignItemData = function*() {
  const fields = yield select(FormGetters.fields, {
    instanceId: AUTO_ASSIGN_ITEM_SETTINGS_MODAL_FORM_ID
  });
  const maxQuantity = R.pathOr(null, ["max_quantity", "value"], fields);
  const status = R.pathOr("pending", ["status", "value", 0], fields);

  try {
    const recordTypeId = yield select(getters.selectedTypeId);
    const subtypeId = yield select(getters.selectedModalPersonId);
    const variantId = yield select(getters.selectedModalPersonItemId);

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/${recordTypeId}/subtype/${subtypeId}/auto-assigned-item/${variantId}`,
      data: {
        maxQuantity:
          maxQuantity.toString() !== "0" ? maxQuantity.toString() : null,
        status
      }
    });

    yield all([
      put(actions.setShowUpdatePersonAutoAssignItemModal(false)),
      put(actions.getStaffPersonnel())
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while updating auto-assigned item"
        }
      ])
    );
  }
};

const updateAssignedPersonForms = function*() {
  try {
    const recordTypeId = yield select(getters.selectedTypeId);
    const subtypeId = yield select(getters.selectedModalPersonId);
    const assignedIds = yield select(MiniItemsGetters.selectedItems);
    const peopleTypes = yield select(getEnabledPeopleTypes);
    const currentType = R.find(R.propEq("id", subtypeId), peopleTypes);
    const assignedItems = R.propOr([], "assignedForms", currentType);

    const items = getDataOrdered({
      assignedIds,
      assignedItems
    });

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/${recordTypeId}/subtype/${subtypeId}/forms`,
      data: {
        items
      }
    });

    yield all([
      put(actions.setShowAssignModal(false)),
      put(actions.getStaffPersonnel())
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while saving preferences"
        }
      ])
    );
  }
};

const updateAssignedPersonDocuments = function*() {
  try {
    const recordTypeId = yield select(getters.selectedTypeId);
    const subtypeId = yield select(getters.selectedModalPersonId);
    const assignedIds = yield select(MiniItemsGetters.selectedItems);
    const peopleTypes = yield select(getEnabledPeopleTypes);
    const currentType = R.find(R.propEq("id", subtypeId), peopleTypes);
    const assignedItems = R.propOr([], "assignedDocumentRequests", currentType);

    const items = getDataOrdered({
      assignedIds,
      assignedItems
    });

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/type/${recordTypeId}/subtype/${subtypeId}/document-requests`,
      data: { items }
    });

    yield all([
      put(actions.setShowAssignModal(false)),
      put(actions.getStaffPersonnel())
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while saving preferences"
        }
      ])
    );
  }
};

const removePersonForm = function*({ payload: { subtypeId, recordId } }) {
  try {
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "delete",
      url: `/hub/admin/${recordTypeId}/subtype/${subtypeId}/form/${recordId}`
    });

    yield put(actions.getStaffPersonnel());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while deleting record"
        }
      ])
    );
  }
};

const removePersonDocument = function*({ payload: { subtypeId, recordId } }) {
  try {
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "delete",
      url: `/hub/admin/type/${recordTypeId}/subtype/${subtypeId}/document-request/${recordId}`
    });

    yield put(actions.getStaffPersonnel());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while deleting record"
        }
      ])
    );
  }
};

export const togglePersonForm = function*({ subtypeId, variantId, required }) {
  try {
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/${recordTypeId}/subtype/${subtypeId}/form/${variantId}`,
      data: {
        required
      }
    });

    yield put(actions.getStaffPersonnel());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error ocurred updating assigned form"
        }
      ])
    );
  }
};

const updatePersonAssginedForm = function*() {
  try {
    yield put(actions.setLoadingPersonForms(true));
    const recordTypeId = yield select(getters.selectedTypeId);
    const subtypeId = yield select(getters.selectedModalPersonId);
    const recordId = yield select(getters.selectedModalId);
    const fields = yield select(FormGetters.fields);
    const closeDate =
      R.pathOr(null, [EDIT_MODAL_DATA.DUE_DATE, "value"], fields) || null;
    const formData = yield select(getUpdatePersonData);
    const formattedDate = yield call(formatDateTimezone, closeDate);

    const data = {
      alias: R.pathOr("", [EDIT_MODAL_DATA.ALIAS, "value"], fields),
      [EDIT_MODAL_DATA.REQUIRED]: R.pathOr(
        false,
        [EDIT_MODAL_DATA.REQUIRED, "value"],
        fields
      ),
      closeDate: formattedDate,
      order: R.propOr(0, "order", formData)
    };

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/${recordTypeId}/subtype/${subtypeId}/form/${recordId}`,
      data
    });

    yield put(actions.getStaffPersonnel());
    yield put(actions.closeUpdateModal());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while deleting record"
        }
      ])
    );
  }
};

const updatePersonAssginedDocumentRequest = function*() {
  try {
    yield put(actions.setLoadingPersonDocuments(true));
    const recordTypeId = yield select(getters.selectedTypeId);
    const subtypeId = yield select(getters.selectedModalPersonId);
    const recordId = yield select(getters.selectedModalId);
    const fields = yield select(FormGetters.fields);
    const closeDate =
      R.pathOr(null, [EDIT_MODAL_DATA.DUE_DATE, "value"], fields) || null;
    const documentData = yield select(getUpdatePersonData);
    const formattedDate = yield call(formatDateTimezone, closeDate);

    const data = {
      alias: R.pathOr("", [EDIT_MODAL_DATA.ALIAS, "value"], fields),
      [EDIT_MODAL_DATA.REQUIRED]: R.pathOr(
        false,
        [EDIT_MODAL_DATA.REQUIRED, "value"],
        fields
      ),
      closeDate: formattedDate,
      order: R.propOr(0, "order", documentData)
    };

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/type/${recordTypeId}/subtype/${subtypeId}/document-request/${recordId}`,
      data
    });

    yield put(actions.getStaffPersonnel());
    yield put(actions.closeUpdateModal());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while deleting record"
        }
      ])
    );
  }
};

const reorderPersonForms = function*({ payload: { subtypeId, orderedItems } }) {
  try {
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/${recordTypeId}/subtype/${subtypeId}/forms`,
      data: {
        items: orderedItems.map(({ id }, order) => ({ id, order }))
      }
    });

    yield put(actions.getStaffPersonnel());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while saving preferences"
        }
      ])
    );
  }
};

const reorderPersonDocuments = function*({
  payload: { subtypeId, orderedItems }
}) {
  try {
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/type/${recordTypeId}/subtype/${subtypeId}/document-requests`,
      data: {
        items: orderedItems.map(({ id }, order) => ({
          id,
          order
        }))
      }
    });

    yield put(actions.getStaffPersonnel());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while saving preferences"
        }
      ])
    );
  }
};

const watchGetStaffPersonnel = function*() {
  yield takeEvery(actions.getStaffPersonnel.type, getStaffPersonnel);
};

const watchUpdateEnabledPeopleTypes = function*() {
  yield takeEvery(
    actions.updateEnabledPeopleTypes.type,
    updateEnabledPeopleTypes
  );
};

const watchUpdateEnableSelectFields = function*() {
  yield takeEvery(
    actions.updateEnableSelectFields.type,
    updateEnableSelectFields
  );
};

const watchOpenSelectRequiredFieldsModal = function*() {
  yield takeEvery(
    actions.openSelectRequiredFieldsModal.type,
    openSelectRequiredFieldsModal
  );
};

const watchHideRequiredFieldsModal = function*() {
  yield takeEvery(
    selectRequiredFieldsActions.hideModal.type,
    hideSelectRequiredFieldsModal
  );
};

const watchUpdateSelectedFields = function*() {
  yield takeEvery(selectRequiredFieldsActions.save.type, updateSelectedFields);
};

const watchAddRecordType = function*() {
  yield takeEvery(recordTypeModalActions.save.type, addRecordType);
};

const watchUpdateCloseDate = function*() {
  yield takeEvery(actions.updateCloseDate.type, updateCloseDate);
};

const watchOnSelectRequestableItems = function*() {
  yield takeEvery(
    actions.onSelectRequestablePersonItems.type,
    onSelectRequestablePersonItems
  );
};

const watchOnSelectAutoAssignItems = function*() {
  yield takeEvery(
    actions.onSelectAutoAssignItems.type,
    onSelectAutoAssignItems
  );
};

const watchRemoveSelectedItem = function*() {
  yield takeEvery(actions.removeSelectedItem.type, removeSelectedItem);
};

const watchUpdatePersonItemData = function*() {
  yield takeEvery(actions.updatePersonItemData.type, updatePersonItemData);
};

const watchUpdatePersonAutoAssignItemData = function*() {
  yield takeEvery(
    actions.updatePersonAutoAssignItemData.type,
    updatePersonAutoAssignItemData
  );
};

const watchUpdateAssignedPersonForms = function*() {
  yield takeEvery(
    actions.updateAssignedPersonForms.type,
    updateAssignedPersonForms
  );
};

const watchUpdateAssignedPersonDocuments = function*() {
  yield takeEvery(
    actions.updateAssignedPersonDocuments.type,
    updateAssignedPersonDocuments
  );
};

const watchRemoveSelectedAutoAssignItem = function*() {
  yield takeEvery(
    actions.removeSelectedAutoAssignItem.type,
    removeSelectedAutoAssignItem
  );
};

const watchTogglePersonForm = function*() {
  yield takeEvery(actions.togglePersonForm.type, togglePersonForm);
};

const watchRemovePersonForm = function*() {
  yield takeEvery(actions.removePersonForm.type, removePersonForm);
};

const watchRemovePersonDocument = function*() {
  yield takeEvery(actions.removePersonDocument.type, removePersonDocument);
};

const watchUpdatePersonAssginedForm = function*() {
  yield takeEvery(
    actions.updatePersonAssginedForm.type,
    updatePersonAssginedForm
  );
};

const watchUpdatePersonAssginedDocumentRequest = function*() {
  yield takeEvery(
    actions.updatePersonAssginedDocumentRequest.type,
    updatePersonAssginedDocumentRequest
  );
};

const watchReorderPersonForms = function*() {
  yield takeEvery(actions.reorderPersonForms.type, reorderPersonForms);
};

const watchReorderPersonDocuments = function*() {
  yield takeEvery(actions.reorderPersonDocuments.type, reorderPersonDocuments);
};

const rootSaga = function*() {
  yield all([
    fork(watchGetStaffPersonnel),
    fork(watchHideRequiredFieldsModal),
    fork(watchOpenSelectRequiredFieldsModal),
    fork(watchUpdateSelectedFields),
    fork(watchUpdateEnableSelectFields),
    fork(watchUpdateEnabledPeopleTypes),
    fork(watchAddRecordType),
    fork(watchUpdateCloseDate),
    fork(watchOnSelectRequestableItems),
    fork(watchOnSelectAutoAssignItems),
    fork(watchRemoveSelectedItem),
    fork(watchUpdatePersonItemData),
    fork(watchUpdatePersonAutoAssignItemData),
    fork(watchUpdateAssignedPersonForms),
    fork(watchUpdateAssignedPersonDocuments),
    fork(watchRemoveSelectedAutoAssignItem),
    fork(watchTogglePersonForm),
    fork(watchRemovePersonForm),
    fork(watchRemovePersonDocument),
    fork(watchUpdatePersonAssginedForm),
    fork(watchUpdatePersonAssginedDocumentRequest),
    fork(watchReorderPersonForms),
    fork(watchReorderPersonDocuments)
  ]);
};

export default rootSaga;
