import PropTypes from "prop-types";
import React, { Component } from "react";
import Helpers from "utils/Global/Helpers";
import { get } from "lodash";

import PortalUsersApi from "redux/modules/portal/users/api";
import SubmissionApi from "redux/modules/formsV2/submission/api";
import UserApi from "redux/modules/user/api";

import View from "./View";

const LoginException = function(value) {
  this.value = value;
  this.message = "Unknown Submission Login Error";
  this.toString = () => `${this.message}: ${JSON.stringify(this.value)}`;
};

class Controller extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      searching: false,
      searchingUsers: false,
      processing: false,
      email: props.location.query.email ? props.location.query.email : "",
      password: "",
      firstName: "",
      lastName: "",
      userExists: null,
      isVirtualUser: false,
      portalUserExists: null
    };
  }

  createUser = () => {
    const { email, password, firstName, lastName } = this.state;
    return UserApi.post(
      { userId: 0 },
      {
        email,
        password,
        firstName,
        lastName,
        eventId: this.props.eventDetails.id
      }
    ).then(result => ({ userId: result.payload.user.id }));
  };

  convertVirtualUser = () => {
    const { email, password } = this.state;
    return UserApi.convert({ userId: 0 }, { email, password }).then(result => ({
      userId: result.payload.user.id
    }));
  };

  loginUser = () => {
    const { email, password } = this.state;
    // @DEPRECATED
    // return UserApi.login({ email, password }).then(result => ({
    //   userId: result.user.id
    // }));
  };

  createPortalUser = userId =>
    PortalUsersApi.post(
      { userId },
      {
        eventId: this.props.eventDetails.id,
        userId
      }
    );

  getPortalUser = userId => {
    const { form, eventDetails } = this.props;
    return PortalUsersApi.get(
      { userId },
      {
        eventId: eventDetails.uuid,
        formId: form.id,
        userId
      }
    );
  };

  addUserForm = userId => {
    this.props.addAssignedForms({
      eventId: this.props.eventDetails.id,
      recordIds: [userId],
      formIds: [this.props.form.id]
    });
  };

  createSubmission = async (userId, contactId) => {
    const {
      form,
      intakeDetails,
      eventDetails,
      isAccountIntakeForm,
      isContactIntakeForm
    } = this.props;

    if (isContactIntakeForm && !contactId) {
      throw new Error("Error: No contact found");
    }

    const submissionModuleRecord = isAccountIntakeForm
      ? await this.props.addRecord({
          moduleId: intakeDetails.module_id,
          typeId: intakeDetails.id,
          /*
      // @NOTE: Saving as non-draft for now until we know how to handle draft accounts / contacts
      record: {
        isDraft: true
      },
      */
          options: {
            eventId: eventDetails.id,
            userId
          }
        })
      : {
          id: contactId
        };

    // if account intake, add user as "user" of account and set active view to account
    if (isAccountIntakeForm) {
      await Promise.all([
        this.props.addAccountUser({
          eventId: eventDetails.id,
          userId: userId,
          accountId: submissionModuleRecord.id
        }),
        this.props.updatePortalUser({
          eventId: eventDetails.id,
          userId: userId,
          activeView: submissionModuleRecord.id
        }),
        this.props.addAccountAssignedForms({
          eventId: this.props.eventDetails.id,
          recordIds: [submissionModuleRecord.id],
          formIds: [this.props.form.id]
        })
      ]);
    } else {
      await this.props.addContactAssignedForms({
        eventId: this.props.eventDetails.id,
        recordIds: [submissionModuleRecord.id],
        formIds: [this.props.form.id]
      });
    }

    let submissionResult = await SubmissionApi.post(
      { userId },
      {
        eventId: eventDetails.id,
        formId: form.id,
        userId,
        accountId: isAccountIntakeForm ? submissionModuleRecord.id : undefined,
        submissionRecordId: submissionModuleRecord.id
      }
    );

    return submissionResult;
  };

  showError = error => {
    const message = get(error, "error");

    if (!message) {
      Rollbar.log("Submission Login Error", new LoginException(error));
    }

    this.setState({
      processing: false,
      error: message || "Oops. We encountered an error. Please try again soon."
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.processing) {
      return false;
    }
    if (this.state.userExists === null) {
      this.search();
      return false;
    }
    if (this.state.userExists === false) {
      const errors = this.validate();
      if (errors.length) {
        return this.setState({
          error: `${errors.join(", ")} ${
            errors.length > 1 ? "are" : "is"
          } required.`
        });
      }
      // handle: user does not exist
      return this.handleRegisterNewUser();
    } else if (
      this.state.userExists === true &&
      this.state.isVirtualUser === false &&
      this.state.portalUserExists === false
    ) {
      // handle: user exists, is not a virtual user, is not a portal user
      return this.handleRegisterExistingUser();
    } else if (
      this.state.userExists === true &&
      this.state.isVirtualUser === true &&
      this.state.portalUserExists === true
    ) {
      // handle: user exists, is a virtual user, is a portal user
      return this.handleConvertVirtualUser();
    } else if (
      this.state.userExists === true &&
      this.state.isVirtualUser === true &&
      this.state.portalUserExists === false
    ) {
      // handle: user exists, is a virtual user, is a not portal user
      return this.handleRegisterVirtualUser();
    } else if (this.state.userExists === true) {
      return this.handleLogin();
    }
  };

  handleRegisterNewUser = async () => {
    try {
      await this.createUser();
      const { userId } = await this.loginUser();
      const portalUserResult = await this.createPortalUser(userId);
      const submissionResult = await this.createSubmission(
        userId,
        portalUserResult.user_contact_id
      );
      return this.handleRegisterCompletion(submissionResult);
    } catch (e) {
      this.showError(e);
    }
  };

  handleRegisterVirtualUser = async () => {
    try {
      await this.convertVirtualUser();
      const { userId } = await this.loginUser();
      const portalUserResult = await this.createPortalUser(userId);
      const submissionResult = await this.createSubmission(
        userId,
        portalUserResult.user_contact_id
      );
      return this.handleRegisterCompletion(submissionResult);
    } catch (e) {
      this.showError(e);
    }
  };

  handleConvertVirtualUser = async () => {
    try {
      await this.convertVirtualUser();
      const { userId } = await this.loginUser();

      // @NOTE: This path needs to be tested. Originally no portal user was being fetched
      // if its a virtual user
      const portalUserResult = await this.getPortalUser(userId);
      const submissionResult = await this.createSubmission(
        userId,
        portalUserResult.user_contact_id
      );
      return this.handleRegisterCompletion(submissionResult);
    } catch (e) {
      this.showError(e);
    }
  };

  handleRegisterExistingUser = async () => {
    try {
      const { userId } = await this.loginUser();
      const portalUserResult = await this.createPortalUser(userId);
      const submissionResult = await this.createSubmission(
        userId,
        portalUserResult.user_contact_id
      );
      return this.handleRegisterCompletion(submissionResult);
    } catch (e) {
      this.showError(e);
    }
  };

  handleRegisterCompletion = submissionResult => {
    const { form, eventDetails } = this.props;

    window.location = `/submissions/${eventDetails.slug}/${form.slug}/${submissionResult.submission.id}`;

    return null;
  };

  handleLogin = async () => {
    const { form, eventDetails } = this.props;

    this.setState({ processing: true });

    try {
      const { userId } = await this.loginUser();
      const portalUserResult = await this.getPortalUser(userId);
      const submissionResult = await this.createSubmission(
        userId,
        portalUserResult.user_contact_id
      );

      if (portalUserResult.portal_user) {
        window.location = `/submissions/${eventDetails.slug}/${form.slug}/${submissionResult.submission.id}`;
      } else {
        throw new Error("Error: No portal user returned");
      }
      return null;
    } catch (e) {
      this.showError(e);
    }
  };

  search = () => {
    const { email } = this.state;
    if (this.state.email.length && Helpers.isValidEmail(this.state.email)) {
      this.setState({ searchingUsers: true }, () => {
        PortalUsersApi.searchUsers(
          { userId: 0 },
          {
            eventId: this.props.eventDetails.id,
            email
          }
        )
          .then(
            ({ payload: { userExists, isVirtualUser, portalUserExists } }) => {
              this.setState({
                error: null,
                searchingUsers: false,
                userExists,
                isVirtualUser,
                portalUserExists
              });
            }
          )
          .catch(error => this.setState({ searchingUsers: false, error }));
      });
    } else {
      this.setState({ error: "Please enter a valid email address." });
    }
  };

  onFirstNameChange = e => {
    this.setState({
      error: null,
      firstName: e.target.value
    });
  };

  onLastNameChange = e => {
    this.setState({
      error: null,
      lastName: e.target.value
    });
  };

  onEmailChange = e => {
    if (this.state.email === e.target.value) return;
    this.setState({
      userExists: null,
      isVirtualUser: null,
      portalUserExists: null,
      error: null,
      email: e.target.value
    });
  };

  onPasswordChange = e => {
    this.setState({
      error: null,
      password: e.target.value
    });
  };

  validate = () => {
    const fieldsToValidate = [
      {
        field: "Password",
        property: "password"
      },
      {
        field: "First Name",
        property: "firstName"
      },
      {
        field: "Last Name",
        property: "lastName"
      }
    ];

    return fieldsToValidate.reduce((errors, { field, property }) => {
      if (!this.state[property]) {
        errors.push(field);
      }
      return errors;
    }, []);
  };

  render() {
    const {
      portal,
      eventDetails,
      isFetching,
      errorMessages,
      intakeDetails
    } = this.props;
    const {
      error,
      searching,
      searchingUsers,
      processing,
      email,
      password,
      firstName,
      lastName,
      userExists,
      isVirtualUser,
      portalUserExists
    } = this.state;

    return (
      <View
        {...{
          portal,
          eventDetails,
          isFetching,
          errorMessages,
          intakeDetails,
          form: get(intakeDetails, "permission_set.intake_form", {}),

          error,
          searching,
          searchingUsers,
          processing,
          email,
          password,
          firstName,
          lastName,
          userExists,
          isVirtualUser,
          portalUserExists,

          handleSubmit: this.handleSubmit,
          onEmailChange: this.onEmailChange,
          onFirstNameChange: this.onFirstNameChange,
          onLastNameChange: this.onLastNameChange,
          onPasswordChange: this.onPasswordChange,
          search: this.search
        }}
      />
    );
  }
}

Controller.propTypes = {
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  getForm: PropTypes.func.isRequired,
  errorMessages: PropTypes.array,
  addRecord: PropTypes.func.isRequired,
  addAssignedForms: PropTypes.func.isRequired
};

export default Controller;
