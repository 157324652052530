import * as R from "ramda";
import { makeInstanceSelector } from "redux-mvc";

import { user } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { eventDetails as getPortalEventDetails } from "redux/modules/portal/selectors";

import { getters as TableGetters } from "ui-kit/Datagrid";
import * as TableSelectors from "ui-kit/Datagrid/selectors";
import { getCellId } from "ui-kit/Datagrid/utils";

import { BUTTONS } from "ui-kit/Datagrid/constants";

// this selectors are not ment to work with instances
const getKey = (_, props) => R.pathOr("", ["group", "id"], props);
const getGroup = (_, { group }) => group;

export const getColumns = makeInstanceSelector(
  getGroup,
  (state, props) =>
    TableGetters.colWidths(state, {
      ...props,
      instanceId: getKey(state, props)
    }),
  (group, widths) =>
    R.concat(
      R.map(
        field => ({
          ...field,
          key: field.id,
          editable: true,
          required: field.required,
          resizable: true,
          width: widths[field.id] || 200,
          settings: {
            ...field.settings,
            suppressLinkToRecord: true
          }
        }),
        group.fields
      ),
      R.map(
        itemBlock => ({
          id: itemBlock.id,
          key: itemBlock.id,
          name: itemBlock.name,
          type: "item-block",
          editable: true,
          required: false,
          resizable: true,
          width: widths[itemBlock.id] || 200,
          settings: {
            variants: R.map(
              item => ({
                ...item,
                id: item.variant.id
              }),
              itemBlock.items
            )
          }
        }),
        group.item_blocks
      )
    )
)(getKey);

export const getMetaData = makeInstanceSelector(
  (_, { readOnly }) => readOnly,
  getGroup,
  (_, { accountId }) => accountId,
  getColumns,
  getEventId,
  getEventDetails,
  getPortalEventDetails,
  user,
  (
    readOnly,
    contactType,
    accountId,
    columns,
    eventId,
    eventDetails,
    portalEventDetails,
    user
  ) => ({
    readOnly,
    eventDetails: R.isNil(eventId) ? portalEventDetails : eventDetails,
    contactTypeId: contactType.id,
    accountId,
    columns: R.indexBy(R.prop("id"), columns),
    user
  })
)(getKey);

const getColumnValue = (column, values) => {
  const value = R.prop(column.key, values);
  if (!value) {
    return {};
  }
  if (column.type === "item-block") {
    if (Array.isArray(R.path(["value", "value"], value))) {
      return value;
    } else {
      return {
        type: "item-block",
        value: value
      };
    }
  }
  return value;
};

export const getRows = makeInstanceSelector(
  getGroup,
  getColumns,
  getMetaData,
  (group, columns, meta) => {
    return R.map(
      contact => ({
        ...contact,
        id: contact.id,
        key: contact.id,
        meta,
        ...R.indexBy(
          R.prop("columnId"),
          R.map(
            column => ({
              columnId: column.id,
              key: `${contact.id}_${column.id}`,
              ...getColumnValue(column, contact.values)
            }),
            columns
          )
        )
      }),
      R.propOr([], "contacts", group)
    );
  }
)(getKey);

export const getMenu = R.always({});
export const getHeaders = makeInstanceSelector(
  getGroup,
  getColumns,
  (group, columns) => [
    {
      name: group.name,
      label: !group.limit && "No limit",
      id: group.id,
      width: R.sum(R.map(R.prop("width"), columns))
    }
  ]
)(getKey);

const showAddButton = makeInstanceSelector(getGroup, group => {
  if (!group.limit) {
    return true;
  }
  return R.length(group.contacts) < group.limit;
})(getKey);

export const getEnabledButtons = makeInstanceSelector(
  showAddButton,
  showAddButton =>
    R.filter(R.identity, [
      showAddButton && {
        ...BUTTONS.ADD_ROW,
        label: "Add Person"
      },
      BUTTONS.IMPORT,
      BUTTONS.REMOVE_ROW
    ])
)(getKey);

export const getRightButtons = R.always([BUTTONS.SAVE]);

export const getNewRowsValues = makeInstanceSelector(
  TableSelectors.getNewRows,
  R.compose(
    R.prop("columns"),
    TableGetters.meta
  ),
  (newRows, columns) =>
    R.flatten(
      R.map(
        row =>
          R.map(
            column => ({
              ...(row[column.id] || {}),
              id: getCellId({ columnId: column.id, rowId: row.id }),
              required: R.prop("required", column),
              key: R.prop("name", column)
            }),
            R.values(columns)
          ),
        newRows
      )
    )
)(getKey);

export const getSelectedCellIds = makeInstanceSelector(
  TableSelectors.getSelectedRowIds,
  R.compose(
    R.prop("columns"),
    TableGetters.meta
  ),
  (newRows, columns) =>
    R.flatten(
      R.map(
        rowId =>
          R.map(
            column => getCellId({ columnId: column.id, rowId }),
            R.values(columns)
          ),
        newRows
      )
    )
)(getKey);
