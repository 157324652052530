import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CollapsablePanel from "components/Global/CRM/CollapsablePanel";
import Tooltip from "components/Global/CRM/Tooltip";
import User from "./User";
import Button from "components/Global/CRM/ActionButton";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import AssignOwnersModal from "components/Global/Module/Modals/AssignOwners";
import { sortBy } from "lodash";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class RecordOwners extends Component {
  @autobind
  async removeOwner(userId) {
    await this.props.deleteOwners({
      moduleId: this.props.moduleId,
      recordIds: [this.props.recordId],
      userIds: [userId],
      options: {
        orgId: this.props.orgDetails.id,
        eventId: this.props.eventDetails.id
      }
    });
    this.props.showSnackbar({ message: "Owner removed" });
    this.props.onOwnersChange();
  }

  showAssignOwnersModal = () => {
    const {
      moduleId,
      recordId,
      onOwnersChange,
      recordNameSingular,
      recordNamePlural
    } = this.props;
    this.props.showModal({
      content: (
        <AssignOwnersModal
          moduleId={moduleId}
          recordNameSingular={recordNameSingular}
          recordNamePlural={recordNamePlural}
          recordIds={[recordId]}
          onDone={onOwnersChange}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const { owners, title, readOnly } = this.props;

    const list = sortBy(owners, u => u.name).map(owner => {
      return (
        <User
          key={owner.id}
          onRemove={() => this.removeOwner(owner.id)}
          user={{
            user_id: owner.id,
            user_photo_url: owner.photoUrl,
            user_fname: owner.firstName,
            user_lname: owner.lastName,
            user_email: owner.email
          }}
          readOnly={readOnly}
        />
      );
    });

    return (
      <CollapsablePanel
        title={
          <div className={css.primaryContacts}>
            {title}
            <div className={css.tooltip}>
              <Tooltip
                message="Owners are users that are responsible for this record"
                placement="bottom"
              />
            </div>
          </div>
        }
      >
        <div>
          <div>
            {list}
            {!readOnly && (
              <div styleName="buttonWrapper">
                <Button
                  title={`Manage ${title}`}
                  onClick={this.showAssignOwnersModal}
                />
              </div>
            )}
          </div>
        </div>
      </CollapsablePanel>
    );
  }
}

RecordOwners.propTypes = {
  title: PropTypes.string.isRequired,
  users: PropTypes.array.isRequired,
  owners: PropTypes.array.isRequired,
  recordId: PropTypes.string.isRequired,
  onOwnersChange: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  deleteOwners: PropTypes.func.isRequired,
  eventDetails: PropTypes.object.isRequired,
  moduleId: PropTypes.string.isRequired,
  recordNameSingular: PropTypes.string.isRequired,
  recordNamePlural: PropTypes.string.isRequired
};

export default RecordOwners;
