import { get, find } from "lodash";

export const isFetching = state => state.modules.layouts.fetching;
export const layouts = (state, moduleId) =>
  get(state, `modules.layouts.layouts[${moduleId}]`, []);
export const selectedLayoutId = (state, moduleId, layoutId) => {
  if (layoutId) {
    return layoutId;
  }
  return get(
    find(get(state, `modules.layouts.layouts[${moduleId}]`, []), {
      is_default: true
    }),
    "id",
    null
  );
};
