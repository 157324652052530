import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { getCustomSites } from "Portal/List/selectors";
import { Div, Text5 } from "components/Base";
import WebsiteRow from "./WebsiteRow";

const decorate = connect(state => ({
  list: getCustomSites(state)
}));

const CustomSites = ({ list }) => (
  <Div mt={5}>
    <Div display="row.flex-start.center" pl={1}>
      <Text5 bold color="black">
        Event Websites
      </Text5>
      <Text5 ml={1} color="black">
        ({R.length(list)})
      </Text5>
    </Div>
    <Div mt={2}>
      {R.map(
        row => (
          <WebsiteRow row={row} />
        ),
        list
      )}
    </Div>
  </Div>
);

export default decorate(CustomSites);
