export default {
  CONTAINS: {
    pattern: "contains",
    requiresFilterValue: true
  },
  DOES_NOT_CONTAIN: {
    pattern: "does_not_contain",
    requiresFilterValue: true
  },
  IS: {
    pattern: "is",
    requiresFilterValue: true
  },
  IS_NOT: {
    pattern: "is_not",
    requiresFilterValue: true
  },
  IS_CHECKED: {
    pattern: "is_checked",
    requiresFilterValue: false
  },
  IS_NOT_CHECKED: {
    pattern: "is_not_checked",
    requiresFilterValue: false
  },
  IS_EMPTY: {
    pattern: "is_empty",
    requiresFilterValue: false
  },
  IS_NOT_EMPTY: {
    pattern: "is_not_empty",
    requiresFilterValue: false
  }
};
