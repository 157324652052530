import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import * as R from "ramda";
import { withRouter, Link } from "react-router";

const decorate = R.compose(
  withRouter,
  CSSModules(css)
);

const Summary = ({ params }) => (
  <div styleName="summary">
    <div styleName="title">Summary</div>
    <Link
      to={`/hubs/${params.portalSlug}/${params.portalUUID}/people/${params.recordTypeId}/reports`}
      className={css.submitRequest}
    >
      View All Reports
    </Link>
    <div styleName="description">
      Due dates, request totals (quantity + price) and allocations show up
      here...
    </div>
  </div>
);

export default decorate(Summary);
