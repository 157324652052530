import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import resolveFormatter from "components/Global/Table3/CellFormatters/utils/resolveFormatter";
import resolveSectionEditor from "./resolveSectionEditor";
import ButtonGroup from "components/Global/Buttons/CancelSaveButtonGroup";
import Label from "components/Global-2016/Forms/Label";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

class SubmissionSummaryField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  @autobind
  onChange(id, value) {
    this.setState({
      value
    });
  }

  @autobind
  handleSubmit(e) {
    e.preventDefault();
    this.props.changeField(this.state.value);
    this.props.closeField();
  }

  preventEnter(e) {
    // TODO: Disabling savin on enter until we can normalize the save trigger on inputs
    if (e.which === 13) {
      e.preventDefault();
      return false;
    }
  }

  render() {
    const {
      isEditing,
      values,
      field,
      metaData,
      editField,
      closeField,
      eventDetails,
      fields
    } = this.props;

    const {
      name,
      settings: { isAdminField }
    } = field;
    const required = field.is_required;
    const readonly = field.is_readonly;

    if (isEditing) {
      const Section = resolveSectionEditor(field);
      if (Section) {
        const sectionProps = {
          eventDetails,
          eventId: eventDetails.id,
          formValues: values,
          field,
          isEditing: false,
          isResponseLocked: false,
          isApproving: true,
          isFillingFormOut: true,
          updateFormValue: this.onChange,
          type: field.type,
          fields
        };
        return (
          <div styleName="fieldEdit">
            <form
              onSubmit={this.handleSubmit}
              onKeyPress={this.preventEnter}
              className="form-page"
            >
              <div>
                <Section ref={c => (this.field = c)} {...sectionProps} />
              </div>
              <div styleName="editorActions">
                <ButtonGroup handleCancel={closeField} />
              </div>
            </form>
          </div>
        );
      }
    } else {
      const Formatter = resolveFormatter(field);
      return (
        <div
          onClick={this.props.readOnly ? undefined : editField}
          styleName="field"
        >
          <Label
            isAdminField={isAdminField}
            required={required}
            readonly={readonly}
          >
            {name}
          </Label>
          <div styleName="fieldValue">
            <Formatter value={this.props.value} dependentValues={metaData} />
          </div>
        </div>
      );
    }
  }
}

SubmissionSummaryField.propTypes = {
  editField: PropTypes.func.isRequired,
  closeField: PropTypes.func.isRequired,
  changeField: PropTypes.func.isRequired,
  eventDetails: PropTypes.object.isRequired,
  metaData: PropTypes.object.isRequired,
  field: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  fields: PropTypes.array.isRequired
};

export default CSSModules(SubmissionSummaryField, css);
