/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getters, actions } from "Portal/PortalHome/model";
import * as R from "ramda";
import Activities from "./Activities";
import Alerts from "./Alerts";
import Community from "./Community";
import CSSModules from "react-css-modules";
import Files from "./Files";
import Page from "Portal/Page";
import Pages from "./Pages";
import Reports from "./Reports";
import css from "./styles.scss";

const decorate = R.compose(
  connect(
    state => ({
      title: getters.title(state),
      description: getters.description(state),
      backgroundImageUrl: getters.background_image_url(state)
    }),
    { init: actions.init }
  ),
  CSSModules(css)
);

const Layout = ({ init, scope, title, description, backgroundImageUrl }) => {
  useEffect(() => {
    init({
      scope
    });
  }, [init]);

  return (
    <Page
      instanceId="home"
      title={title}
      description={description}
      backgroundImageUrl={backgroundImageUrl}
    >
      <Reports />
      <Community />
      {/* <Sessions /> */}
      <Alerts />
      <Activities />
      <Files />
      <Pages />
    </Page>
  );
};

export default R.compose(decorate)(Layout);
