import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import InputGroup from "components/Global-2016/Forms/InputGroup";
import Label from "components/Global-2016/Forms/Label";
import { ArrowRenderer } from "components/Global/Editors/utils/reactSelect";
import { noop } from "utils/General";
import { Dropdown as BaseDropdown } from "components/Base";
import LabelSimpleForm from "components/Event/FormsV2/Sections/LabelSimpleForm/view";

const Dropdown = ({
  label = null,
  description = null,
  placeholder = null,
  disabled = false,
  readOnly = false,
  onChange = noop,
  onBlur = noop,
  required = false,
  isAdminField = false,
  containerStyles = null,
  options = [],
  selected = [],
  isMulti = false,
  instanceId = null,
  fieldData = null,
  isShown = false,
  canShowSimpleSideBar = false,
  setIsShown = noop
}) => {
  const values = isMulti ? selected : selected[0];

  return (
    <InputGroup styles={containerStyles}>
      {label ? (
        !canShowSimpleSideBar ? (
          <Label
            isAdminField={isAdminField}
            required={required}
            description={description}
            readonly={readOnly}
          >
            {label}
          </Label>
        ) : (
          <LabelSimpleForm
            isAdminField={isAdminField}
            required={required}
            description={description}
            readonly={readOnly}
            instanceId={instanceId}
            fieldData={fieldData}
            isShown={isShown}
            setIsShown={setIsShown}
          >
            {label}
          </LabelSimpleForm>
        )
      ) : null}

      <BaseDropdown
        arrowRenderer={ArrowRenderer}
        clearable
        disabled={disabled}
        isDisabled={disabled}
        isMulti={isMulti}
        onBlur={onBlur}
        onChange={onChange}
        options={options.map(option => ({ label: option, value: option }))}
        placeholder={placeholder || "Select..."}
        required={required}
        searchable
        simpleValue
        value={values}
        usePortal
      />
    </InputGroup>
  );
};

export default CSSModules(Dropdown, css);
