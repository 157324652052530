import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as R from "ramda";
import { withProps } from "utils/General";
import { bindInstance } from "redux-mvc";

import {
  Div,
  AddIcon,
  MediumFilledButton,
  DownFilledIcon,
  DownloadIcon,
  MenuItem,
  PopMenu,
  PopMenuCard,
  BigFilledButton,
  MediumOutlineButton,
  FontIcon,
  PrintIcon,
  ReplyIcon
} from "components/Base";

import { actions } from "Items/Manage";
import SelectiveApproversLabel from "SelectiveApproversLabel/View";
import { actions as TableActions } from "ui-kit/Table/model";

import CSSModules from "react-css-modules";
import css from "ui-kit/ViewPicker/View/styles.scss";

const SummaryIcon = withProps({
  children: "bar_chart"
})(FontIcon);

const ReviewButton = withProps({
  bg: "primary7",
  color: "white"
})(MediumFilledButton);

const FeedIcon = withProps({
  children: "rss_feed",
  fs: 4
})(FontIcon);

import {
  getCountOfToggledRows,
  getToggledRowIds,
  isPrintMode,
  canShowSubscribeOption
} from "Items/Manage/selectors";

import SearchBar from "./SearchBar";

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      countOfToggledRows: getCountOfToggledRows(state, props),
      selectedRowIds: getToggledRowIds(state, props),
      isPrintMode: isPrintMode(state, props),
      canShowSubscribeOption: canShowSubscribeOption(state, props)
    }),
    bindInstance({
      printItems: actions.printItems,
      undoPrintItems: actions.undoPrintItems,
      onExport: actions.exportData,
      onSubscribe: actions.showSubscribeModal,
      showStatsModal: actions.showStatsModal,
      clearSelectedRows: TableActions.clearSelectedRows,
      onApprove: actions.approve,
      onDeny: actions.deny
    })
  )
);

const Toolbar = ({
  selectedRowIds,
  showStatsModal,
  countOfToggledRows,
  clearSelectedRows,
  handlers,
  onExport,
  onSubscribe,
  printItems,
  isPrintMode,
  onApprove,
  onDeny,
  instanceId = "",
  canShowSubscribeOption
}) => {
  return (
    <Div display="row.flex-start.center" height={75}>
      {!countOfToggledRows ? (
        <>
          <SearchBar instanceId={instanceId} />
          <BigFilledButton
            color="gray7"
            bg="neutral0"
            mr={2}
            LeftIcon={SummaryIcon}
            onClick={showStatsModal}
          >
            Quick Summary
          </BigFilledButton>
          <PopMenu
            Label={({ onClick }) => (
              <BigFilledButton
                color="gray7"
                bg="neutral0"
                RightIcon={DownFilledIcon}
                onClick={onClick}
              >
                Options
              </BigFilledButton>
            )}
          >
            {({ closeMenu }) => (
              <PopMenuCard position="bottom" align="right">
                <Div width={1} style={{ height: "1px" }} bg="gray3" />
                <MenuItem
                  LeftIcon={DownloadIcon}
                  onClick={() => {
                    onExport("csv");
                    closeMenu();
                  }}
                >
                  Export .CSV
                </MenuItem>
                <MenuItem
                  LeftIcon={DownloadIcon}
                  onClick={() => {
                    onExport("xlsx");
                    closeMenu();
                  }}
                >
                  Export .XLSX
                </MenuItem>
                {canShowSubscribeOption ? (
                  <MenuItem
                    LeftIcon={FeedIcon}
                    onClick={() => {
                      onSubscribe();
                      closeMenu();
                    }}
                  >
                    Subscribe
                  </MenuItem>
                ) : null}
              </PopMenuCard>
            )}
          </PopMenu>
          <BigFilledButton
            width={45}
            bg="orange5"
            color="white"
            ml={2}
            onClick={handlers.createOrder}
          >
            <AddIcon color="white" size={25} />
          </BigFilledButton>
        </>
      ) : (
        <Div display="row.flex-start.center">
          <Div fw={4} mr={4} fs={4} color="gray7">
            {countOfToggledRows} Selected
          </Div>
          {isPrintMode ? (
            <MediumFilledButton
              bg="primary7"
              color="white"
              mr={2}
              onClick={() => printItems({ lineItemIds: selectedRowIds })}
              LeftIcon={PrintIcon}
            >
              Print
            </MediumFilledButton>
          ) : (
            <SelectiveApproversLabel
              {...{
                type: "lineItem",
                recordIds: selectedRowIds,
                onApprove: onApprove,
                onDeny: onDeny,
                Button: ReviewButton
              }}
            />
          )}
          <MediumOutlineButton
            LeftIcon={ReplyIcon}
            ml={2}
            fw={4}
            color="gray7"
            leftIconColor="gray7"
            onClick={clearSelectedRows}
          >
            Clear selection
          </MediumOutlineButton>
        </Div>
      )}
    </Div>
  );
};

export default CSSModules(decorate(Toolbar), css);
