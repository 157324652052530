import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import { getTabs } from "EventLight/Passes/selectors";
import { withRouter } from "react-router";
import * as R from "ramda";

import Loading from "ui-kit/Loading";
import Body from "./Body";

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      loading: getters.loading(state),
      tabs: getTabs(state, props)
    }),
    {
      init: actions.init,
      showItemModal: actions.showItemModal,
      showAddGroupModal: actions.showAddGroupModal
    }
  )
);

const Layout = ({ loading, typeId, init }) => {
  useEffect(() => {
    init({ typeId });
  }, []);

  return <>{loading ? <Loading /> : <Body />}</>;
};

export default decorate(Layout);
