import React, { Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { actions } from "Orders/OrderModal";
import {
  getOrderId,
  getInvoice,
  getCanAcceptPayment
} from "Orders/OrderModal/selectors";

import { Div, Text1, Text4, SendIcon, BigOutlineButton } from "components/Base";

import { StatusTag } from "utils/status-comps";

import Panel from "ui-kit/Panel";

const decorate = connect(
  state => ({
    orderId: getOrderId(state),
    invoice: getInvoice(state),
    canAcceptPayment: getCanAcceptPayment(state)
  }),
  {
    viewInvoice: actions.viewInvoice
  }
);

const EmailConfirmation = ({
  handlers,
  orderId,
  invoice,
  canAcceptPayment,
  viewInvoice
}) => {
  return (
    <Panel
      display="row.space-between.center"
      px={8}
      py={3}
      bg={!canAcceptPayment ? "neutral0" : "white"}
    >
      <Div display="row.flex-start.center">
        <SendIcon size={26} mr={2} />
        <Text4 bold color={!canAcceptPayment ? "neutral5" : "gray7"}>
          Invoice:
        </Text4>
        <StatusTag status={invoice.lastSent ? "sent" : "not_sent"} ml={2} />
      </Div>
      <Div display="row.flex-end.center">
        <Div display="column.flex-start.flex-end">
          {invoice.lastSent ? (
            <Fragment>
              <Text1>
                Invoice last sent {moment(new Date(invoice.lastSent)).fromNow()}{" "}
                {invoice.by && invoice.by.length ? "by " : ""}
                {invoice.by}
              </Text1>
              <Div display="row.flex-start.center">
                <Text1 underline onClick={() => viewInvoice(orderId)}>
                  View Invoice
                </Text1>
                <Text1 mx={2}>&middot;</Text1>
                <Text1 underline onClick={() => handlers.send(orderId)}>
                  Send Email
                </Text1>
              </Div>
            </Fragment>
          ) : (
            <Text1 underline onClick={() => handlers.send(orderId)}>
              Send Email
            </Text1>
          )}
        </Div>
        <BigOutlineButton
          ml={4}
          onClick={() => handlers.emailInvoice(orderId)}
          disabled={!canAcceptPayment}
        >
          {invoice.lastSent ? "Resend Invoice" : "Email Invoice"}
        </BigOutlineButton>
      </Div>
    </Panel>
  );
};

export default decorate(EmailConfirmation);
