import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { noop } from "utils/General";

import { actions } from "ui-kit/Form/model";
import { getValue } from "ui-kit/Form/selectors";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import ReactQuill from "react-quill";
import {
  modules as defaultModules,
  formats as defaultFormats
} from "utils/Quill/Options";

require("react-quill/dist/quill.snow.css");

import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";

const decorate = R.compose(
  WithFormInstanceConsumer({ fieldType: FIELD_TYPES.VALUE }),
  connect(
    (state, props) => ({
      value: getValue(state, props)
    }),
    bindInstance({
      onChange: actions.setFieldValueRequest,
      onFocus: actions.focusField,
      onBlur: actions.blurField,
      setIniValue: actions.setIniValue
    })
  )
);

export const Wysiwyg = decorate(
  ({
    value = "",
    iniValue,
    onChange = noop,
    onFocus = noop,
    onBlur = noop,
    setIniValue = noop,
    fieldId,
    fieldType,
    modules = defaultModules,
    formats = defaultFormats
  }) => {
    useEffect(() => {
      setIniValue(iniValue, {
        meta: { fieldType, fieldId }
      });
    }, []);

    return (
      <ReactQuill
        style={{ width: "100%" }}
        {...{
          value,
          modules,
          formats,
          onChange: val => onChange(val, { meta: { fieldId, fieldType } }),
          onFocus: () => onFocus(null, { meta: { fieldId, fieldType } }),
          onBlur: (range, source, editor) => {
            setTimeout(() => {
              let fixRange = editor.getSelection();
              if (fixRange) {
                // paste event or none real blur event - ignore
              } else {
                onBlur(null, { meta: { fieldId, fieldType } });
              }
            }, 50); // random time
          }
        }}
      />
    );
  }
);
