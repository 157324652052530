import PropTypes from "prop-types";
import React from "react";
import SidebarBase from "../Module/Sidebar";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

class ContactsNav extends SidebarBase {}

ContactsNav.propTypes = {
  fetchList: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func
  }).isRequired
};

export default ContactsNav;
