import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";
import * as R from "ramda";

const iniState = {
  data: {
    name: "Lucas Oil NHRA",
    attendees: 60000,
    date_from: "2019-07-01",
    date_to: "2019-07-01",
    description: "",
    terms: "",
    variants_to_show: [],
    forms_to_show: ["UUID1", "UUID2", "UUID3"],
    venue_name: "",
    venue_address_line_1: "",
    venue_address_line_2: "",
    venue_address_city: "",
    venue_address_state: "",
    venue_address_zip: "",
    images: [],
    floor_map: {
      url: ""
    }
  },
  saving: false
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    uploadFile: R.identity,
    updateDataField: (state, { payload: { name, value } }) => ({
      data: {
        ...state.data,
        [name]: value
      }
    }),
    addImage: (state, { payload: { url } }) => ({
      data: {
        ...state.data,
        images: [
          ...state.data.images,
          {
            id: url,
            url
          }
        ]
      }
    }),
    removeImage: (state, { payload: { url } }) => ({
      data: {
        ...state.data,
        images: R.filter(img => img.url !== url, state.data.images)
      }
    }),
    updateFloorImage: (state, { payload: { url } }) => ({
      data: {
        ...state.data,
        floor_map: { url }
      }
    })
  }
});

export default handlers;
