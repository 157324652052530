/* eslint-disable no-unused-vars */
import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../../model";
import CollapsablePanel from "ui-kit/CollapsiblePanel";
import {
  Text5,
  Div,
  MediumOutlineButton,
  InfoIcon,
  LoadingIcon
} from "components/Base";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import { noop } from "utils/General";
import Table from "../Table";
import { WithFormInstanceProvider, Toggle } from "ui-kit/Form/View";
import {
  TOGGLE,
  CARD_TYPES,
  PERSON_SUBCARDS,
  PERSON_FORMS_INSTANCE_ID,
  PERSON_DOCUMENTS_INSTANCE_ID,
  AUTO_ASSIGN_COLUMNS
} from "../../constants";
import { Card } from "../CollapsableCard";
import DatePicker from "components/Event/FormsV2/Modals/FormEditModal/Calendar";

const documentsColumns = [
  {
    name: "Name",
    value: "name",
    style: { flex: 1.2 },
    type: "text",
    showTooltip: true
  },
  {
    name: "Alias",
    value: "alias",
    style: { flex: 1 },
    type: "text",
    showTooltip: true
  },
  {
    name: "Due Date",
    value: "close_date_formatted",
    style: { flex: 1.4, fontSize: 15 },
    type: "text",
    showTooltip: true
  },
  {
    name: "Required",
    value: "required",
    type: "required",
    style: { width: 80, textAlign: "center" }
  },
  {
    name: "Actions",
    value: "actions",
    style: { width: 80, textAlign: "center" },
    type: "actions"
  }
];

const formsColumns = [
  {
    name: "Name",
    value: "name",
    style: { flex: 1.2 },
    type: "text",
    showTooltip: true
  },
  {
    name: "Alias",
    value: "alias",
    style: { flex: 1 },
    type: "text",
    showTooltip: true
  },
  {
    name: "Due Date",
    value: "close_date_formatted",
    style: { flex: 1.4, fontSize: 15 },
    type: "text",
    showTooltip: true
  },
  {
    name: "Required",
    value: "required",
    type: "required",
    style: { width: 80, textAlign: "center" }
  },
  {
    name: "Actions",
    value: "actions",
    style: { width: 80, textAlign: "center" },
    type: "actions"
  }
];

const requestItemsColumns = [
  {
    name: "Item",
    value: "name",
    style: { flex: 1.4 },
    type: "text",
    showTooltip: true
  },
  {
    name: "Max Qty",
    value: "max_quantity",
    style: { flex: 0.5 },
    type: "text"
  },
  {
    name: "Type",
    value: "type_name",
    style: { flex: 1.2 },
    type: "text"
  },
  {
    name: "Status",
    value: "status",
    style: { flex: 0.8 },
    type: "status"
  },
  {
    name: "Actions",
    value: "actions",
    style: { width: 80, textAlign: "center" },
    type: "actions"
  }
];

const CardInstance = WithFormInstanceProvider()(props => <Card {...props} />);

const decorate = R.compose(
  WithFormInstanceProvider(),
  connect(
    state => ({
      loadingPersonDocuments: getters.loadingPersonDocuments(state),
      loadingPersonForms: getters.loadingPersonForms(state),
      loadingPersonAutoAssignItems: getters.loadingPersonAutoAssignItems(state),
      loadingPersonRequestItems: getters.loadingPersonRequestItems(state)
    }),
    {
      openUpdateModal: actions.openShareFileModal,
      reorderSharedFiles: actions.reorderSharedFiles,
      goToLearnLennd: actions.goToLearnLennd,
      goToDocumentRequests: actions.goToDocumentRequests,
      openSelectRequiredFieldsModal: actions.openSelectRequiredFieldsModal,
      updateCloseDate: actions.updateCloseDate,
      showRequestableItemsModal: actions.openSelectRequestableItemsModal,
      showAutoAssignItemsModal: actions.openSelectAutoAssignItemsModal,
      toggleCollapsablePersonCard: actions.toggleCollapsablePersonCard,
      removeSelectedItem: actions.removeSelectedItem,
      removeSelectedAutoAssignItem: actions.removeSelectedAutoAssignItem,
      openUpdatePersonItemModal: actions.openUpdatePersonItemModal,
      openAssignFormsModal: id =>
        actions.openAssignPersonModal({
          assignModalType: CARD_TYPES.PERSON_FORMS,
          id
        }),
      openAssignDocumentsModal: id =>
        actions.openAssignPersonModal({
          assignModalType: CARD_TYPES.PERSON_DOCUMENTS,
          id
        }),
      removePersonForm: actions.removePersonForm,
      removePersonDocument: actions.removePersonDocument,
      openUpdateDocumentModal: ({ id, selectedModalPersonId }) =>
        actions.openUpdatePersonModal({
          assignModalType: CARD_TYPES.PERSON_DOCUMENTS,
          id,
          selectedModalPersonId
        }),
      openUpdateFormModal: ({ id, selectedModalPersonId }) =>
        actions.openUpdatePersonModal({
          assignModalType: CARD_TYPES.PERSON_FORMS,
          id,
          selectedModalPersonId
        }),
      reorderPersonForms: actions.reorderPersonForms,
      reorderPersonDocuments: actions.reorderPersonDocuments
    }
  ),
  CSSModules(css)
);

const CollapsablePerson = ({
  id,
  name,
  countOfFieldsSelected,
  closeDate,
  autoAssignItems,
  items,
  isDueDateClose,
  goToLearnLennd,
  goToDocumentRequests,
  openSelectRequiredFieldsModal,
  isEnabled = false,
  updateCloseDate,
  showRequestableItemsModal,
  showAutoAssignItemsModal,
  collapsed,
  toggleCollapsablePersonCard,
  removeSelectedItem,
  openUpdatePersonItemModal,
  openAssignFormsModal,
  openAssignDocumentsModal,
  removeSelectedAutoAssignItem,
  instanceId,
  removePersonForm,
  removePersonDocument,
  openUpdateDocumentModal,
  openUpdateFormModal,
  assignedForms = [],
  assignedDocumentRequests = [],
  loadingPersonDocuments,
  reorderPersonForms,
  loadingPersonForms,
  reorderPersonDocuments,
  loadingPersonAutoAssignItems,
  loadingPersonRequestItems
}) => {
  return (
    <CollapsablePanel
      heading={() => (
        <Div display="row.space-between.center" my={2} width={1} pr={4}>
          <Text5 fw={4}>{name}</Text5>
          <div
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <Toggle
              size="small"
              fieldId={TOGGLE}
              iniValue={isEnabled}
              bg="green6"
            />
          </div>
        </Div>
      )}
      bg="white"
      arrowColor="gray7"
      bc="neutral3"
      right={false}
      bra={1}
      mb={3}
      p={3}
      collapsed={collapsed}
      toggleCollapsed={() => toggleCollapsablePersonCard(id)}
      collapsedDefault={true}
    >
      <div styleName="collapsable">
        <div styleName="boldDescription">
          What information do you want to collect for each person?
        </div>
        <div
          styleName="option"
          onClick={() => openSelectRequiredFieldsModal({ id })}
        >
          {countOfFieldsSelected} field
          {countOfFieldsSelected !== 1 ? "s" : ""} selected
          <span class="material-icons" style={{ fontSize: 14, marginLeft: 4 }}>
            edit
          </span>
        </div>

        <div styleName="separator" />

        <div styleName="boldDescription">
          When do you want to stop allowing name additions and changes?
          <InfoIcon
            ml={1}
            tooltip="If set, no additions or changes will be allowed after specified date"
            size={14}
          />
        </div>

        <div styleName="option">
          <DatePicker
            value={closeDate}
            onChange={() => {}}
            onSave={val => updateCloseDate({ typeId: id, val })}
          />
          <div
            styleName="row"
            style={{
              color: isDueDateClose ? "#e03131" : "#2f9e44",
              marginLeft: 8
            }}
          >
            {isDueDateClose ? (
              <span class="material-icons" style={{ fontSize: 16 }}>
                lock
              </span>
            ) : (
              <span class="material-icons" style={{ fontSize: 16 }}>
                lock_open
              </span>
            )}
            <span style={{ marginLeft: 4, fontSize: 14, fontWeight: 600 }}>
              Status: {isDueDateClose ? "Closed" : "Open"}
            </span>
          </div>
        </div>

        {/*
        <div styleName="separator" />

        <div styleName="boldDescription">
          Do you want to automatically assign items to these people?
        </div>
        <div styleName="row-btn">
          <MediumOutlineButton
            color="primary7"
            bc="primary7"
            LeftIcon={() => (
              <span
                class="material-icons"
                style={{ fontSize: 16, marginRight: 8, height: 16 }}
              >
                format_list_bulleted
              </span>
            )}
            width={200}
            onClick={() => showAutoAssignItemsModal(id)}
          >
            Select items
          </MediumOutlineButton>
          <MediumOutlineButton
            color="primary7"
            bc="primary7"
            LeftIcon={() => (
              <span
                class="material-icons"
                style={{ fontSize: 16, marginRight: 8, height: 16 }}
              >
                import_contacts
              </span>
            )}
            ml={2}
            onClick={goToLearnLennd}
          >
            Learn about auto-assignment
          </MediumOutlineButton>
        </div>
        {loadingPersonAutoAssignItems ? (
          <div styleName="center">
            <LoadingIcon color="gray3" size={50} />
          </div>
        ) : (
          <Table
            rows={autoAssignItems}
            openUpdateModal={({ id: itemId }) =>
              openUpdatePersonItemModal({
                personId: id,
                itemId,
                subCard: PERSON_SUBCARDS.AUTO_ASSIGN
              })
            }
            deleteRow={variantId =>
              removeSelectedAutoAssignItem({ variantId, subtypeId: id })
            }
            showRequired={false}
            shouldSort={false}
            onReorder={noop}
            columns={AUTO_ASSIGN_COLUMNS}
            emptyText="You haven't selected any items to be auto-assigned."
          />
        )}
        */}

        <div styleName="separator" />

        <div styleName="boldDescription">
          Do you want to allow portal users to request items for these people?
        </div>
        <div styleName="row-btn">
          <MediumOutlineButton
            color="primary7"
            bc="primary7"
            LeftIcon={() => (
              <span
                class="material-icons"
                style={{ fontSize: 16, marginRight: 8, height: 16 }}
              >
                format_list_bulleted
              </span>
            )}
            width={200}
            onClick={() => showRequestableItemsModal(id)}
          >
            Select items
          </MediumOutlineButton>
          <MediumOutlineButton
            color="primary7"
            bc="primary7"
            LeftIcon={() => (
              <span
                class="material-icons"
                style={{ fontSize: 16, marginRight: 8, height: 16 }}
              >
                import_contacts
              </span>
            )}
            ml={2}
            onClick={goToLearnLennd}
          >
            Learn about item requests
          </MediumOutlineButton>
        </div>
        {loadingPersonRequestItems ? (
          <div styleName="center">
            <LoadingIcon color="gray3" size={50} />
          </div>
        ) : (
          <Table
            rows={items}
            firstColumn="Item"
            secondColumn="Qty"
            thirdColumn="Type"
            openUpdateModal={({ id: itemId }) =>
              openUpdatePersonItemModal({
                personId: id,
                itemId,
                subCard: PERSON_SUBCARDS.REQUESTABLE
              })
            }
            deleteRow={variantId =>
              removeSelectedItem({
                variantId,
                subtypeId: id
              })
            }
            showRequired={false}
            onReorder={noop}
            shouldSort={false}
            columns={requestItemsColumns}
            emptyText="You haven't made any items available to request yet."
          />
        )}

        {/*
        <div styleName="separator" />

        <CardInstance
          description="Do you want to collect form submissions for these people?"
          boldDescription
          btnText="forms"
          goToLearn={goToLearnLennd}
          loading={loadingPersonForms}
          openModal={() => openAssignFormsModal(id)}
          goToManage={goToDocumentRequests}
          deleteRow={recordId =>
            removePersonForm({
              recordId,
              subtypeId: id
            })
          }
          openUpdateModal={({ id: itemId }) =>
            openUpdateFormModal({ id: itemId, selectedModalPersonId: id })
          }
          assignedRows={assignedForms}
          columnTitle="Forms"
          assignedRowsText="Form"
          onReorder={orderedItems =>
            reorderPersonForms({ subtypeId: id, orderedItems })
          }
          wrapperCardStyle={{ margin: 0, marginTop: 14 }}
          emptyText="You haven't uploaded any forms to this portal yet."
          instanceId={`${PERSON_FORMS_INSTANCE_ID}/${instanceId}`}
          columns={formsColumns}
        />
        */}

        {/*
        <div styleName="separator" />

        <CardInstance
          description="Do you want to collect documents for these people?"
          boldDescription
          btnText="documents"
          goToLearn={goToLearnLennd}
          loading={loadingPersonDocuments}
          openModal={() => openAssignDocumentsModal(id)}
          goToManage={goToDocumentRequests}
          deleteRow={recordId =>
            removePersonDocument({
              recordId,
              subtypeId: id
            })
          }
          openUpdateModal={({ id: itemId }) =>
            openUpdateDocumentModal({ id: itemId, selectedModalPersonId: id })
          }
          assignedRows={assignedDocumentRequests}
          columnTitle="Document"
          assignedRowsText="Document"
          onReorder={orderedItems =>
            reorderPersonDocuments({ orderedItems, subtypeId: id })
          }
          wrapperCardStyle={{ margin: 0, marginTop: 14 }}
          emptyText="You haven't uploaded any documents to this portal yet."
          instanceId={`${PERSON_DOCUMENTS_INSTANCE_ID}/${instanceId}`}
          columns={documentsColumns}
        />
        */}
      </div>
    </CollapsablePanel>
  );
};

export default decorate(CollapsablePerson);
