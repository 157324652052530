import moment from "moment";

export default days =>
  days.sort().reduce((ranges, day) => {
    const currentDay = moment.utc(day);
    const last = ranges.pop();

    if (
      last &&
      moment
        .utc(last.end)
        .add(1, "day")
        .isSame(currentDay, "day")
    ) {
      // Part of previous range
      last.end = currentDay.endOf("day").toISOString();
      ranges.push(last);
      return ranges;
    }

    // New range
    if (last) {
      ranges.push(last); // Add back last range
    }
    // Create a new one
    ranges.push({
      start: currentDay.startOf("day").toISOString(),
      end: currentDay.endOf("day").toISOString()
    });

    return ranges;
  }, []);
