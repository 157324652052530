import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "EventLight/Agenda/SelectScheduleFieldsModal";
import {
  Div,
  Text1,
  Text4,
  MediumFilledInput,
  SearchIcon
} from "components/Base";
import Tabs from "./Tabs";
import FieldsList from "./FieldsList";

const decorate = connect(
  state => ({
    fieldsFilter: getters.fieldsFilter(state)
  }),
  {
    setFieldsFilter: actions.setFieldsFilter,
    resetToDefaults: actions.resetToDefaults
  }
);

const SelectFields = ({
  handlers,
  fieldsFilter,
  setFieldsFilter,
  resetToDefaults
}) => (
  <Div
    width={400}
    height={530}
    bc="neutral1"
    br={1}
    pt={4}
    pb={4}
    style={{
      flexShrink: 0,
      overflowY: "Auto"
    }}
  >
    <Div px={4}>
      <Div display="row.space-between.center">
        <Text4 bold color="black">
          Select Fields to Show/Hide
        </Text4>
        <Text1 color="primary7" underline onClick={() => resetToDefaults()}>
          Select Defaults
        </Text1>
      </Div>
      <MediumFilledInput
        RightIcon={SearchIcon}
        mt={3}
        bg="neutral0"
        color="neutral6"
        width={1}
        value={fieldsFilter}
        onChange={value => setFieldsFilter(value)}
        continuous
        placeholder="Search..."
      />
    </Div>
    <Tabs />
    <FieldsList handlers={handlers} />
  </Div>
);

export default decorate(SelectFields);
