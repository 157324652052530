import React, { Component } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { actions, getters as ItemManageGetters } from "Items/Manage";

import { getOrderId } from "Orders/OrderModal/selectors";

import { Div, BigFilledButton } from "components/Base";

import { contextConfig } from "Items/Manage/View";

import ModeToggle from "Items/Manage/View/ModeToggle";
import Tabs from "Items/Manage/View/Tabs";
import SearchBar from "Items/Manage/View/SearchBar";
import OrderListView from "Items/Manage/View/OrderListView";
import PaginationBar from "Items/Manage/View/PaginationBar";
import BulkEditLineItemsModal from "Orders/BulkEditLineItemsModal";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import SendEmailModal from "SendEmailModal/View";
import IssuanceModal from "Passes/Checkin/IssuanceModal";
import ViewOrderModal from "Orders/OrderModal/View";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as SEND_TO_OPTION_TYPES from "SendEmailModal/utils/send-to-option-types";

import { MANAGE_BY, TABLE_VIEW_ID } from "Items/Manage/constants";
import { ALL_ITEM_TYPE_IDS } from "utils/item-types";

// view order modal

import { showModal, hideModal } from "redux/modules/modal/actions";

import LoadingOverlay from "ui-kit/LoadingOverlay";
import ShowNoResults from "ui-kit/ShowNoResults";

const decorate = connect(
  (state, props) => ({
    loading: ItemManageGetters.loading(state, props),
    orderId: getOrderId(state),
    showNoResults: ItemManageGetters.showNoResults(state, props)
  }),
  bindInstance({
    init: actions.init,
    showModal,
    hideModal,
    fetchData: actions.fetchData,
    stats: actions.fetchStats
  })
);

class ManageView extends Component {
  constructor(props) {
    super();
    props.init({
      itemTypeView: ALL_ITEM_TYPE_IDS,
      orderId: props.orderId,
      view: MANAGE_BY.ORDER_NUMBER
    });
    props.fetchData();

    this.handlers = Object.entries({
      ...contextConfig.handlers,

      viewOrder: (number, id) => {
        this.props.showModal({
          content: (
            <ViewOrderModal
              orderNumber={id ? undefined : number}
              orderId={id ? id : undefined}
              onDone={() => {
                this.props.fetchData();
              }}
            />
          ),
          wrapper: ModalWrapper
        });
      },

      issueItems: async ({
        recordId,
        variantId,
        orderType,
        customerAccountId,
        customerContactId,
        fulfillmentId,
        status,
        itemName,
        recordName,
        quantity
      }) => {
        const handleCheckin = actions => async (
          quantity,
          recordId,
          orderType,
          customerAccountId,
          customerContactId,
          variantId
        ) => {
          await this.props.checkin({
            actions,
            recordId,
            orderType,
            customerAccountId,
            customerContactId,
            variantId,
            options: { quantity }
          });
        };

        const handleRevertCheckin = async (
          quantity,
          recordId,
          orderType,
          customerAccountId,
          customerContactId,
          variantId,
          action,
          fulfillmentId
        ) => {
          await this.props.revertCheckin({
            variantId,
            recordId,
            orderType,
            customerAccountId,
            customerContactId,
            action,
            options: { quantity, fulfillmentId }
          });
        };

        const fulfillItems = handleCheckin(["fulfill"]);
        const issueItems = handleCheckin(["issue"]);
        const issueAndFulfill = handleCheckin(["issue", "fulfill"]);

        const undo = undoStatus => count =>
          handleRevertCheckin(
            count,
            recordId,
            orderType,
            customerAccountId,
            customerContactId,
            variantId,
            undoStatus,
            fulfillmentId
          );

        this.props.showModal({
          content: (
            <IssuanceModal
              status={status}
              onIssue={count =>
                issueItems(
                  count,
                  recordId,
                  orderType,
                  customerAccountId,
                  customerContactId,
                  variantId
                )
              }
              onFulfill={count =>
                fulfillItems(
                  count,
                  recordId,
                  orderType,
                  customerAccountId,
                  customerContactId,
                  variantId
                )
              }
              onIssueAndFulfill={count =>
                issueAndFulfill(
                  count,
                  recordId,
                  orderType,
                  customerAccountId,
                  customerContactId,
                  variantId
                )
              }
              onUndoIssuance={undo("issuance")}
              onUndoPickup={undo("fulfillment")}
              onUndoIssuanceAndPickup={undo("fulfillment-issuance")}
              itemName={itemName}
              recordName={recordName}
              totalAvailable={parseInt(quantity, 10)}
              //
              orderType={orderType}
              customerAccountId={customerAccountId}
              customerContactId={customerContactId}
              recordId={recordId}
              variantId={variantId}
              fulfillmentId={fulfillmentId}
              onDone={() => {
                this.props.fetchData();
              }}
            />
          ),
          wrapper: ModalWrapper
        });
      },

      refetchData: ({ stats } = { stats: false }) => {
        this.props.fetchData();
        if (stats) {
          this.props.stats();
        }
      },

      hideAndFetchData: ({ stats } = { stats: false }) => {
        this.props.hideModal();
        this.props.fetchData();
        if (stats) {
          this.props.stats();
        }
      },

      bulkEdit: ({ orderId, lineItemId }) => {
        const where = {};

        if (orderId) {
          where.orderId = orderId;
        } else if (lineItemId) {
          where.lineItemId = lineItemId;
        }

        this.props.showModal({
          content: (
            <BulkEditLineItemsModal
              where={where}
              onDone={() => {
                this.props.fetchData();
              }}
            />
          ),
          wrapper: ModalWrapper
        });
      },

      sendConfirmation: ({ orderId }) => {
        this.props.showModal({
          content: (
            <SendEmailModal
              emailType="confirmation"
              moduleId={STANDARD_MODULE_IDS.orders.id}
              selectedOptions={[SEND_TO_OPTION_TYPES.ORDER_CUSTOMER]}
              sourceOrderId={orderId}
              records={[orderId]}
              onDone={() => {
                this.props.fetchData();
              }}
            />
          ),
          wrapper: ModalWrapper
        });
      }
    }).reduce(
      (handlers, [key, f]) => ({
        ...handlers,
        [key]: f
      }),
      {}
    );
  }

  render() {
    const {
      showAddAttendee = false,
      loading = false,
      showNoResults = false,
      instanceId = ""
    } = this.props;

    return (
      <Div flex={1} bg="white" bc="neutral3" bs={1} display="column" height={1}>
        <Div display="row.flex-start.center" px={4} pt={4} pb={2}>
          <ModeToggle instanceId={instanceId} size="big" mr={2} mb={0} />
          <SearchBar instanceId={instanceId} showExport={false} />
          {(showAddAttendee && (
            <BigFilledButton ml={2} bg="primary5" height={1}>
              Add Attendee
            </BigFilledButton>
          )) ||
            false}
        </Div>
        <Tabs instanceId={instanceId} bb={1} bc="neutral3" mx={4} my={2} />
        <Div
          style={{
            overflowY: loading ? "hidden" : "auto",
            position: "relative"
          }}
          flex={1}
          mt={2}
          px={4}
          py={2}
          className={TABLE_VIEW_ID}
        >
          {showNoResults ? (
            <ShowNoResults instanceId={instanceId} mt={15} />
          ) : (
            <OrderListView
              instanceId={instanceId}
              m={0}
              handlers={this.handlers}
            />
          )}
          {loading && <LoadingOverlay />}
        </Div>
        <PaginationBar instanceId={instanceId} />
      </Div>
    );
  }
}

export default decorate(ManageView);
