import React from "react";
import FormElements from "components/Global/Modal/Layout/FormElements";

const { InputGroup, Label, Input } = FormElements;

import { connect } from "react-redux";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { getEventCurrency } from "redux/modules/event/selectors";

const decorate = R.compose(
  connect(state => ({
    currency: getEventCurrency(state)
  })),
  CSSModules(css)
);

const CreditCard = ({
  amount,
  updateAmount,
  reference,
  updateReference,
  currency
}) => (
  <div styleName="container">
    <InputGroup>
      <Label>
        <div styleName="label">Amount ({currency})</div>
      </Label>
      <Input onChange={updateAmount} type="number" min={0} value={amount} />
    </InputGroup>
    <InputGroup className={css.group}>
      <Label>
        <div styleName="label">Reference (e.g. Cash or Check #1023)</div>
      </Label>
      <Input onChange={updateReference} type="text" value={reference} />
    </InputGroup>
  </div>
);

export default decorate(CreditCard);
