import PropTypes from "prop-types";
import React, { Component } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import autobind from "autobind-decorator";
import { Div } from "components/Base";
import Tooltip from "@material-ui/core/Tooltip";

class ClickToCopy extends Component {
  constructor(props) {
    super(props);

    this.timeout = null;

    this.state = {
      value: "",
      copied: false
    };
  }

  @autobind
  onCopy() {
    this.setState({
      copied: true
    });

    clearTimeout(this.timeout);
    this.timeout = null;
    this.timeout = setTimeout(this.hideTooltip, 3000);
  }

  @autobind
  hideTooltip() {
    clearTimeout(this.timeout);
    this.setState({
      copied: false
    });
  }

  render() {
    let content;
    const { wrapperStyle } = this.props;

    if (this.state.copied) {
      if (this.props.display === "tooltip") {
        const tooltip = "Copied!";
        content = (
          <Tooltip placement="bottom" title={tooltip}>
            {this.props.text}
          </Tooltip>
        );
      } else {
        content = <span style={wrapperStyle}>Copied</span>;
      }
    } else {
      content = <span style={wrapperStyle}>{this.props.text}</span>;
    }

    if (this.props.children && typeof this.props.children === "function") {
      return this.props.children({ label: content, onClick: this.onCopy });
    }

    const Comp = this.props.Component || Div;

    return this.props.inside ? (
      <CopyToClipboard text={this.props.textToCopy} onCopy={this.onCopy}>
        <Comp className={this.props.className}>{content}</Comp>
      </CopyToClipboard>
    ) : (
      <Comp className={this.props.className}>
        <CopyToClipboard text={this.props.textToCopy} onCopy={this.onCopy}>
          {content}
        </CopyToClipboard>
      </Comp>
    );
  }
}

ClickToCopy.defaultProps = {
  display: "replace",
  wrapperStyle: {}
};

ClickToCopy.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  textToCopy: PropTypes.string.isRequired,
  display: PropTypes.oneOf(["replace", "tooltip"]),
  wrapperStyle: PropTypes.object
};

export default ClickToCopy;
