import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "EventLight/Agenda/SelectScheduleFieldsModal";
import { getReportingTitle } from "EventLight/Agenda/SelectScheduleFieldsModal/selectors";
import { TABS } from "EventLight/Agenda/SelectScheduleFieldsModal/constants";
import { Div } from "components/Base";

const decorate = connect(
  state => ({
    selectedTab: getters.selectedTab(state),
    reportingTitle: getReportingTitle(state)
  }),
  {
    setSelectedTab: actions.setSelectedTab
  }
);

const Tabs = ({ selectedTab, setSelectedTab, reportingTitle }) => (
  <Div display="row.flex-start.center" bb={1} bc="neutral1" mt={3} px={4}>
    <Div
      pb={1}
      fs={2}
      fw={3}
      color={selectedTab === TABS.ALL ? "primary7" : "black"}
      bb={2}
      bc={selectedTab === TABS.ALL ? "primary7" : "transparent"}
      onClick={() => setSelectedTab(TABS.ALL)}
    >
      Group Fields
    </Div>
    <Div
      pb={1}
      fs={2}
      fw={3}
      color={selectedTab === TABS.REPORTED ? "primary7" : "black"}
      bb={2}
      bc={selectedTab === TABS.REPORTED ? "primary7" : "transparent"}
      ml={3}
      onClick={() => setSelectedTab(TABS.REPORTED)}
    >
      {reportingTitle}
    </Div>
  </Div>
);

export default decorate(Tabs);
