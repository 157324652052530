import * as R from "ramda";
import { createSelector } from "reselect";

import { getters } from "EventLight/Expo/Sales/model";
import * as FormSelectors from "ui-kit/Form/selectors";

import { SPONSORSHIP_TYPE_ID, BOOTH_TYPE_ID } from "utils/item-types";
import { PACKAGE_FIELDS } from "EventLight/Expo/Sales/constants";

export const getPackages = createSelector(
  getters.packages,
  packages =>
    R.map(
      pkg => ({
        ...pkg,
        pricesDropdown: R.map(
          ({ label, value, id }) => ({
            // label: `${label}: $ ${value}`,
            label: `$${value}`,
            value,
            id
          }),
          pkg.prices || []
        ),
        price: R.prop("id", R.find(R.prop("isDefault"), pkg.prices || []))
      }),
      R.values(packages)
    )
);

export const getExhibitorPackages = createSelector(
  getPackages,
  R.filter(
    R.compose(
      R.equals(BOOTH_TYPE_ID),
      R.prop("type")
    )
  )
);

export const getSponsorPackages = createSelector(
  getPackages,
  R.filter(
    R.compose(
      R.equals(SPONSORSHIP_TYPE_ID),
      R.prop("type")
    )
  )
);

export const getPackageType = (state, props) =>
  R.path([props.id, "type"], getters.packages(state, props));

export const getPackage = (state, props) =>
  R.prop(R.prop("id", props), getters.packages(state, props));

const toNumber = val => {
  const num = Number(val);
  if (!isNaN(num)) {
    return num;
  }
  return val;
};

export const getUpdatedPrices = createSelector(
  getPackage,
  (state, props) =>
    FormSelectors.getFieldValue(state, {
      instanceId: R.prop("id", props),
      fieldId: PACKAGE_FIELDS.PRICE
    }),
  (pkg, selectedPrice) => {
    if (!pkg) {
      return {};
    }

    const variant = R.head(R.propOr([], "variants", pkg)) || {};

    const { prices, id } = pkg; // eslint-disable-line no-unused-vars

    return {
      id: id,
      variants: [
        {
          ...variant,
          prices: R.map(
            ({ label, value, id }) => ({
              id,
              isDefault: id === selectedPrice,
              name: label,
              price: toNumber(value)
            }),
            pkg.prices || []
          )
        }
      ]
    };
  }
);

export const getUpdatedFormFields = (state, props) =>
  FormSelectors.getFormFields(state, {
    ...props,
    instanceId: R.prop("id", props),
    fields: [PACKAGE_FIELDS.NAME, PACKAGE_FIELDS.DESCRIPTION]
  });

export const getIsExhibitorItem = (state, props) =>
  R.any(item => item.id === props.itemId, getExhibitorPackages(state, props));
