import { put, select, call, takeEvery, all, fork } from "redux-saga/effects";

import { getCredentials } from "redux/modules/user/selectors";
import { registerError } from "redux/modules/errors/actions";

import { actions } from "SelectiveApproversLabel";
import approvalsApi from "SelectiveApproversLabel/api";

// Approvals
const fetchRecordsIsApproverFor = function*({ payload }) {
  const credentials = yield select(getCredentials);

  try {
    const result = yield call(approvalsApi.getRecordsIsApproverFor, {
      credentials,
      recordType: payload.type,
      recordIds: payload.recordIds
    });
    yield put(
      actions.handleResponse({
        result: result.payload,
        recordIds: payload.recordIds
      })
    );
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred fetching records is approver for"
          }
        ])
      ),
      put(
        actions.setLoadingApproval(
          {
            id: payload.manifestId,
            recordIds: payload.recordIds,
            status: false
          },
          {},
          true
        )
      )
    ]);
  }
};

const watchFetch = function*() {
  yield takeEvery(
    actions.fetchRecordsIsApproverFor.type,
    fetchRecordsIsApproverFor
  );
};

const rootSaga = function*() {
  yield all([fork(watchFetch)]);
};

export default rootSaga;
