export const NAMESPACE = "EditEventModal";

export const PAGES = {
  EVENT_DETAILS: "eventDetails",
  TICKETS_PASSES: "ticketsPasses",
  WHO_HAS_ACCESS: "whoHasAccess",
  ALLOCATIONS_GUESTS: "allocationsGuests"
};

export const HEADER_TITLES = [
  { name: "Event Details", page: PAGES.EVENT_DETAILS },
  { name: "Tickets & Passes", page: PAGES.TICKETS_PASSES },
  { name: "Who Has Access", page: PAGES.WHO_HAS_ACCESS }
  // { name: "Allocations & Guest Lists", page: PAGES.ALLOCATIONS_GUESTS }
];
