import {
  checkbox,
  countries,
  currency,
  date,
  datetime,
  dropdown,
  email,
  eventDays,
  file,
  number,
  percent,
  phone,
  text,
  textarea,
  time,
  signature
} from "utils/value-types/schema";

export const ItemTypes = {
  FIELD: "field"
};

export const systemFormFields = {
  checkbox,
  countries,
  currency,
  date,
  datetime,
  dropdown,
  email,
  eventDays,
  file,
  number,
  percent,
  phone,
  text,
  textarea,
  time,
  signature
};
