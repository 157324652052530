import { REQUEST, RECEIVE } from "./constants";
import { registerError } from "redux/modules/errors/actions";
import * as selectors from "./selectors";
import api from "./api";

export function getIntegrations(data) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return false;
    try {
      dispatch({ type: REQUEST });
      const response = await api.get(getState().user.user.credentials, data);
      dispatch({ type: RECEIVE, payload: response.integrations });
      return response.integrations;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting credential integrations"
          }
        ])
      );
    }
  };
}
