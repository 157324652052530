import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import css from "./style.scss";
import { getters } from "Modules/AddEditColumnModal/model";
import { hideModal } from "redux/modules/modal/actions";
import resolveFieldTypeName from "components/Global/Table3/FieldTypeIcons/resolve-field-type-name";

const decorate = R.compose(
  connect(
    (state, props) => ({
      type: getters.type(state, props),
      name: getters.name(state, props)
    }),
    {
      hideModal
    }
  ),
  CSSModules(css)
);
const StandardField = ({ hideModal, type, name }) => (
  <div styleName="modalWrapper">
    <div className="modal-body" styleName="modalBody">
      <div className="modal-body-wrapper">
        <div className="input-wrapper">
          <label styleName="formLabel">
            <span styleName="formLabelText">Field Name</span>
          </label>
          <div>{name}</div>
        </div>
        <div className="input-wrapper">
          <label styleName="formLabel">
            <span styleName="formLabelText">Field Type</span>
          </label>
          <div>{resolveFieldTypeName(type)}</div>
        </div>

        <div styleName="standardField">
          This is a system field provided by LENND and cannot be edited.
        </div>

        <div styleName="actionCancel" onClick={hideModal}>
          Done
        </div>
      </div>
    </div>
  </div>
);

export default decorate(StandardField);
