import React from "react";
import * as R from "ramda";
import {
  SimpleTable,
  Div,
  SmallAvatar,
  TogglableStar,
  Text2,
  AvatarLabelContainer,
  Text1,
  AddIcon,
  SmallShadedBox
} from "components/Base";

import {
  defaultProps,
  isEmptyOrNil,
  scheduleDayFormatter
} from "utils/General";

import CSSModules from "react-css-modules";
import css from "./OpacityTrigger.css";

const EventTable = SimpleTable({
  columnProps: [[4 / 10, "left"], [3 / 10, "right"], [3 / 10, "right"]],
  headerCellComps: ["Event Name", "Organization", "Event Dates"],
  RowStyler: CSSModules(
    ({ goToEvent, ...props }) => (
      <Div
        {...{
          display: "row.flex-start.center",
          bg: { default: "white", hover: "neutral0" },
          transition: "fast",
          py: 2,
          bra: 0,
          onClick: goToEvent,
          styleName: "parentContainer",
          ...props
        }}
      />
    ),
    css
  ),
  TableStyler: defaultProps({
    mt: 0
  })(Div),
  rowCellComps: [
    CSSModules(
      ({ name, id, togglePinned, starred, isDraft }) => (
        <Div display={"row.flex-start.center"}>
          <SmallAvatar id={id} square longInitials text={name} />
          <Text2 ml={2} bold color={"neutral7"}>
            {name}
          </Text2>

          {isDraft ? (
            <Div
              bra={1}
              ml={2}
              bg="neutral1"
              fw={3}
              px={2}
              py={1}
              color="gray7"
              uppercase
              fs={0}
            >
              Draft
            </Div>
          ) : null}

          {togglePinned ? (
            <TogglableStar
              styleName={"childContainer"}
              size={16}
              ml={2}
              onClick={e => {
                e.stopPropagation();
                togglePinned();
              }}
              active={starred}
            />
          ) : null}
        </Div>
      ),
      css
    ),
    ({ organizationPhotoURL, organizationName, organizationId }) => (
      <Div>
        <SmallAvatar
          id={organizationId}
          photoURL={organizationPhotoURL}
          text={organizationName}
        />
      </Div>
    ),

    ({ start, end }) => (
      <Text2 bold color={"neutral7"}>
        {start && end ? scheduleDayFormatter(start, end) : ""}
      </Text2>
    )
  ]
});

const EventListCard = ({
  name,
  photoURL,
  Icon,
  titleMessage,
  events,
  noAvatar,
  addEvent
}) => {
  return isEmptyOrNil(events) ? null : (
    <AvatarLabelContainer
      mb={4}
      title={name}
      noAvatar={noAvatar}
      photoURL={photoURL}
      Icon={Icon}
      headerContent={
        <Text1 bold color={"neutral6"}>
          {titleMessage}
        </Text1>
      }
    >
      <Div mt={2}>
        <EventTable rowsData={events} />
        <Div p={2} display="row">
          {addEvent && (
            <Div display={"row.flex-start.center"} onClick={addEvent}>
              <SmallShadedBox bigIcons OnlyIcon={AddIcon} />
              <Text2 ml={2} color={"neutral6"} bold>
                Add Event
              </Text2>
            </Div>
          )}
        </Div>
      </Div>
    </AvatarLabelContainer>
  );
};

export default EventListCard;
