import {
  put,
  call,
  takeEvery,
  all,
  fork,
  select,
  delay
} from "redux-saga/effects";

import { actions } from "./model";

import { userId } from "redux/modules/user/selectors";
import { eventId } from "redux/modules/event/selectors";
import { getCredentials } from "redux/modules/user/selectors";

import CateringDashboardApi from "EventLight/Catering/Dashboard/api";

import { registerError } from "redux/modules/errors/actions";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const id = yield select(userId);
  const event = yield select(eventId);

  return {
    userId: id,
    eventId: event,
    credentials
  };
};

const getCateringDashboardData = function*() {
  const params = yield call(getParams);
  yield put(actions.setLoading(true));
  try {
    const { payload } = yield call(
      CateringDashboardApi.getCateringDashboard,
      params
    );
    yield put(actions.setInitialData({ data: payload }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred importing the catering data"
        }
      ])
    );
  }
  yield put(actions.setLoading(false));
};

const downloadReportDays = function*({ payload: url }) {
  const credentials = yield select(getCredentials);

  try {
    const initialResult = yield call(CateringDashboardApi.export, {
      url,
      credentials
    });

    yield delay(200);

    let progressResult = yield call(
      CateringDashboardApi.getStatus,
      credentials,
      {
        jobId: initialResult.payload.jobId
      }
    );

    while (["pending", "processing"].includes(progressResult.payload.status)) {
      yield delay(1000);
      progressResult = yield call(CateringDashboardApi.getStatus, credentials, {
        jobId: initialResult.payload.jobId
      });
    }

    if (progressResult.payload.status === "error") {
      yield all([
        put(
          registerError([
            {
              system: "An error occurred exporting",
              user: "An error occurred exporting"
            }
          ])
        )
      ]);
      return false;
    }

    yield call(
      [window, window.open],
      progressResult.payload.payload.url,
      "_blank"
    );
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred exporting meals"
          }
        ])
      ),
      put(actions.setLoading(false, {}, true))
    ]);
  }

  return true;
};

const watchGetCateringDashboardData = function*() {
  yield takeEvery(
    actions.getCateringDashboardData.type,
    getCateringDashboardData
  );
};

const watchDownloadReportDays = function*() {
  yield takeEvery(actions.downloadReportDays.type, downloadReportDays);
};

const rootSaga = function*() {
  yield all([
    fork(watchGetCateringDashboardData),
    fork(watchDownloadReportDays)
  ]);
};

export default rootSaga;
