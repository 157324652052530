import { BEGIN, COMMIT, REVERT } from "redux-optimist";
import uuid from "node-uuid";
import {
  CREATE,
  CREATE_COMPLETE,
  UPDATE,
  UPDATE_COMPLETE,
  DELETE,
  DELETE_COMPLETE,
  UPDATE_SECTION_FIELD_RELATION,
  UPDATE_SECTION_FIELD_RELATION_COMPLETE
} from "./constants";
import {
  UPDATE_COMPLETE as FIELD_UPDATE_COMPLETE,
  DELETE as FIELD_DELETE,
  ERROR as FIELD_ERROR
} from "../constants";
import api from "../api";
import sectionApi from "./api";

export function addSection(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: CREATE,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.post(getState().user.user.credentials, data);
      dispatch({
        type: FIELD_UPDATE_COMPLETE,
        payload: { field: result.field },
        optimist: { type: COMMIT, id: transactionId }
      });
    } catch (error) {
      dispatch({
        type: FIELD_ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function updateFieldSectionRelation(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    let result;
    /*
    dispatch({
      type: UPDATE_SECTION_FIELD_RELATION,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    */
    try {
      result = await sectionApi.put(getState().user.user.credentials, data);
      /*
      dispatch({
        type: UPDATE_SECTION_FIELD_RELATION_COMPLETE,
        optimist: { type: COMMIT, id: transactionId }
      });
      */
    } catch (error) {
      result = error;
      dispatch({
        type: FIELD_ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
    return result;
  };
}
/*
export function updateField (data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: UPDATE,
      payload: data,
      optimist: {type: BEGIN, id: transactionId}
    });
    try {
      const result = await api.put(getState().user.user.credentials, data);
      dispatch({
        type: UPDATE_COMPLETE,
        payload: { field: result.field },
        optimist: {type: COMMIT, id: transactionId}
      });
    } catch (error) {
      dispatch({
        type: FIELD_ERROR,
        payload: { error: error.error || 'An error occurred!' },
        optimist: {type: REVERT, id: transactionId}
      });
    }
  };
}
*/

export function deleteSection(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: DELETE,
      payload: {
        formId: data.formId,
        subformId: data.subformId,
        fieldId: data.fieldId,
        moveFieldsToSectionId: data.moveFieldsToSectionId
      }
    });
    return await api.delete(getState().user.user.credentials, data);
  };
}
