import * as R from "ramda";
import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "Event/MyApprovals/constants";

const iniState = {
  myApprovals: {
    item_types: [],
    modules: [],
    document_requests: [],
    total_pending: 0,
    pending_count_map: {}
  },
  loading: true,
  initLoaded: false
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
