import {
  RECEIVE_ORDER,
  REQUEST_ORDER,
  RECEIVE_ORDERS,
  REQUEST_ORDERS
} from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getOrderIdByOrderNumber(eventId, orderNumber) {
  return async (dispatch, getState) => {
    try {
      const result = await api.getOrderIdByOrderNumber(
        getState().user.user.credentials,
        { eventId, orderNumber }
      );
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching order ID by order number"
          }
        ])
      );
    }
  };
}

export function getOrdersByEvent(eventId) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: REQUEST_ORDERS
      });
      const result = await api.getOrdersByEvent(
        getState().user.user.credentials,
        { eventId }
      );
      dispatch({
        type: RECEIVE_ORDERS,
        payload: result.payload
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching orders"
          }
        ])
      );
    }
  };
}

export function getOrdersByEventAndType(eventId, typeId, sourceId) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: REQUEST_ORDERS,
        payload: {
          typeId
        }
      });
      const result = await api.getOrdersByEvent(
        getState().user.user.credentials,
        {
          eventId,
          typeId,
          sourceId
        }
      );
      dispatch({
        type: RECEIVE_ORDERS,
        payload: {
          typeId,
          orders: result.payload
        }
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching orders"
          }
        ])
      );
    }
  };
}

export function getOrder(orderId) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: REQUEST_ORDER,
        payload: {
          orderId
        }
      });
      const result = await api.getOrder(
        getState().user.user.credentials,
        orderId
      );
      dispatch({
        type: RECEIVE_ORDER,
        payload: {
          orderId,
          order: result.payload[0]
        }
      });
      return result.payload[0];
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching order"
          }
        ])
      );
    }
  };
}

export function addOrder(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.addOrder(getState().user.user.credentials, data);
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding order"
          }
        ])
      );
    }
  };
}

export function updateOrder(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.updateOrder(
        getState().user.user.credentials,
        data
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating order"
          }
        ])
      );
    }
  };
}

export function bulkUpdateOrders(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.bulkUpdateOrders(
        getState().user.user.credentials,
        data
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred bulk updating orders"
          }
        ])
      );
    }
  };
}

export function deleteOrder(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.deleteOrder(
        getState().user.user.credentials,
        data
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting order"
          }
        ])
      );
    }
  };
}
