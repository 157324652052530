import React, { Component } from "react";
import * as R from "ramda";
import { withState } from "utils/General";
import View from "./View";
import moment from "moment";

class IssuanceModalController extends Component {
  state = { loading: true, count: 0, status: this.props.status, error: null };

  async componentDidMount() {
    // @TODO: Fetch by data by variant
    await this.refreshView(false);

    this.modified = false;

    this.setState({
      loading: false,
      error: null
    });
  }

  componentDidUpdate(oldProps) {
    if (oldProps.record.record_id !== this.props.record.record_id) {
      this.setState({
        count: this.getDefaultCount(this.props)
      });
    }
  }

  componentWillUnmount() {
    this.props.invalidateRecordWithIssuance();
    if (this.modified && this.props.onDone) {
      this.props.onDone();
    }
  }

  getDefaultCount = props => {
    let count = 0;
    if (!props.record.counts) return count;
    switch (this.state.status) {
      case "undo-issuance":
        return props.record.counts.issued + props.record.counts.fulfilled;
      case "undo-fulfillment":
        return props.record.counts.fulfilled;
      case "undo-pick":
        return props.record.counts.fulfilled;
      case "pending-pickup":
        return props.record.counts.issued - props.record.counts.fulfilled;
      default:
        return props.record.counts.approved - props.record.counts.issued;
    }
  };

  setCount = count => this.setState({ count });

  refreshView = (modified = true) => {
    this.modified = modified;

    this.props.getRecordWithIssuance({
      recordId: this.props.recordId,
      variantId: this.props.variantId,
      fulfillmentId: this.props.fulfillmentId,
      orderType: this.props.orderType,
      customerAccountId: this.props.customerAccountId,
      customerContactId: this.props.customerContactId
    });
  };

  handleCheckin = actions => async ({
    recordId,
    variantId,
    quantity,
    barcodes,
    force
  }) => {
    await this.props.checkin({
      actions,
      recordId,
      variantId,
      orderType: this.props.orderType,
      customerAccountId: this.props.customerAccountId,
      customerContactId: this.props.customerContactId,
      options: { quantity, barcodes, force }
    });
    return this.refreshView();
  };

  handleScan = async (input = "") => {
    this.setState({ error: null });
    const trimmedInput = input.trim();

    if (!trimmedInput.length) return null;

    // @NOTE: If we have any status, we assume we're doing something else besides issuing
    // with the scanned input
    if (this.state.status) {
      const item = R.find(
        R.pathEq(["issuance", "orderLineItemInventory", "uid"], trimmedInput)
      )(this.props.record.line_items);

      // @NOTE: If UID does not match an existing line item inventory, then show error
      if (!item) {
        return this.setState({
          error: "No item matches that barcode in this view"
        });
      }

      return this.props.setSelectedRows(
        R.uniq([...this.props.selectedRows, item.issuance.id])
      );
    } else {
      await this.handleCheckin(["issue"])({
        recordId: this.props.recordId,
        variantId: this.props.variantId,
        barcodes: [trimmedInput]
      });
      return this.refreshView();
    }
  };

  handleSelectAll = rows => {
    this.props.setSelectedRows(rows.map(r => r.id));
  };

  fulfillBarcodeItems = args =>
    this.handleCheckin(["fulfill"])({
      ...args,
      barcodes: this.props.selectedRows.map(
        issuanceId =>
          this.props.record.line_items
            .filter(li => li.issuance)
            .find(li => li.issuance.id === issuanceId).issuance
            .orderLineItemInventory.uid
      )
    });

  issueItems = this.handleCheckin(["issue"]);
  issueAndFulfill = this.handleCheckin(["issue", "fulfill"]);
  fulfillItems = this.handleCheckin(["fulfill"]);

  handleRevertCheckin = async ({
    quantity,
    recordId,
    variantId,
    action,
    fulfillmentId,
    issuanceIds
  }) => {
    if (issuanceIds) {
      this.props.setSelectedRows(
        R.without(issuanceIds, this.props.selectedRows)
      );
    }

    await this.props.revertCheckin({
      variantId,
      recordId,
      orderType: this.props.orderType,
      customerAccountId: this.props.customerAccountId,
      customerContactId: this.props.customerContactId,
      action,
      options: { quantity, fulfillmentId, issuanceIds }
    });
    return this.refreshView();
  };

  toggleRow = id => {
    if (this.props.selectedRows.includes(id)) {
      return this.props.setSelectedRows(
        R.without([id], this.props.selectedRows)
      );
    }
    return this.props.setSelectedRows([...this.props.selectedRows, id]);
  };

  render() {
    const { loading, count, status, error } = this.state;
    const {
      record,
      recordId,
      variantId,
      fulfillmentId,
      issuanceType
    } = this.props;

    const undo = undoStatus => value =>
      this.handleRevertCheckin({
        recordId,
        variantId,
        action: undoStatus,
        fulfillmentId,
        ...value
      });

    const tableRows = R.compose(
      R.map(li => {
        const barcodeProps = li.issuance.orderLineItemInventory
          ? {
              uid: li.issuance.orderLineItemInventory.uid,
              onDetach: () =>
                undo("issuance")({
                  issuanceIds: [li.issuance.id]
                }),
              isSelected: this.props.selectedRows.includes(li.issuance.id),
              onSelect: () => this.toggleRow(li.issuance.id)
            }
          : {};
        return {
          id: li.issuance.id,
          uid: "(Bulk)",
          time: moment(new Date(li.issuance.created_at)).fromNow(),
          actions: 123,
          ...barcodeProps
        };
      }),
      lines =>
        lines.sort(
          (a, b) =>
            Date.parse(R.path(["issuance", "created_at"])(b)) -
            Date.parse(R.path(["issuance", "created_at"])(a))
        ),
      R.filter(li => {
        switch (status) {
          case "undo-fulfillment":
          case "undo-pickup":
            return li.issuance && li.issuance.fulfillment_id;
          case "pending-pickup":
          case "undo-issuance":
          default:
            return li.issuance && !li.issuance.fulfillment_id;
        }
      })
    )(record.line_items || []);

    return (
      <View
        {...{
          loading: loading || !record.record_id,
          error,
          status,
          itemName: record.item ? record.item.name : "",
          itemColor: record.item ? record.item.background_color : "#000",
          recordName: record.record_name,
          setCount: this.setCount,
          count,
          issuanceType,
          totalAvailable: this.getDefaultCount(this.props),
          countOfApproved: this.props.record.counts
            ? this.props.record.counts.approved
            : 0,
          countOfIssued: this.props.record.counts
            ? this.props.record.counts.issued
            : 0,
          countOfRemaining: this.props.record.counts
            ? this.props.record.counts.approved -
              this.props.record.counts.issued
            : 0,
          onIssueAndFulfill: async count => {
            await this.issueAndFulfill({
              quantity: count,
              recordId,
              variantId
            });
            this.props.hideModal();
          },
          onIssue: async count => {
            await this.issueItems({ quantity: count, recordId, variantId });
            this.props.hideModal();
          },
          onFulfill: async count => {
            await this.fulfillItems({ quantity: count, recordId, variantId });
            this.props.hideModal();
          },
          onBarcodeFulfill: async () => {
            await this.fulfillBarcodeItems({ recordId, variantId });
            this.props.hideModal();
          },
          hideModal: this.props.hideModal,
          onUndoIssuance: async count => {
            await undo("issuance")({ quantity: count });
            this.props.hideModal();
          },
          onUndoBarcodeIssuance: async () => {
            await undo("issuance")({
              issuanceIds: this.props.selectedRows
            });
            this.props.hideModal();
          },
          onUndoPickup: async count => {
            await undo("fulfillment")({ quantity: count });
            this.props.hideModal();
          },
          onUndoBarcodePickup: async () => {
            await undo("fulfillment")({
              issuanceIds: this.props.selectedRows
            });
            this.props.hideModal();
          },
          onUndoIssuanceAndPickup: async count => {
            await undo("fulfillment-issuance")({ quantity: count });
            this.props.hideModal();
          },
          onScan: this.handleScan,
          onDoneIssuing: () => this.props.hideModal(),
          onMarkAllAsPickedUp: async () => {
            await this.handleCheckin(["fulfill"])({
              recordId: this.props.recordId,
              variantId: this.props.variantId,
              barcodes: tableRows.map(r => r.uid).filter(uid => uid)
            });
            this.props.hideModal();
          },
          onPartialPickup: () =>
            this.setState({
              status: "pending-pickup"
            }),
          onSelectAll: () => this.handleSelectAll(tableRows),
          tableRows: tableRows
        }}
      />
    );
  }
}

export default withState("selectedRows", "setSelectedRows", [])(
  IssuanceModalController
);
