import React, { PureComponent } from "react";
import ClassNames from "classnames";
import PropTypes from "prop-types";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class Row extends PureComponent {
  render() {
    const { item, isActive, goToEvent, toggleStarredEvent } = this.props;

    const itemClasses = ClassNames({
      "platform-sidebar-menu-events-list-item": true,
      active: isActive,
      "has-unread": item.status === "pending"
    });

    return (
      <div
        key={item.id}
        className={itemClasses}
        onClick={e => goToEvent(item, e)}
        role="link"
        tabIndex="0"
      >
        <div className="platform-sidebar-menu-events-list-item-name">
          <span className="platform-sidebar-menu-events-list-item-unread" />
          <span styleName="name">{item.name}</span>
        </div>
        <div
          role="button"
          tabIndex="0"
          styleName={item.starred ? "selected" : "star"}
          onClick={e =>
            toggleStarredEvent(
              {
                eventId: item.id,
                starred: !item.starred
              },
              e
            )
          }
        >
          {item.starred ? (
            <i className="material-icons">&#xE838;</i>
          ) : (
            <i className="material-icons">&#xE83A;</i>
          )}
        </div>
      </div>
    );
  }
}

Row.propTypes = {
  toggleStarredEvent: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  item: PropTypes.shape({
    id: PropTypes.number,
    starred: PropTypes.bool,
    name: PropTypes.name,
    status: PropTypes.string
  }).isRequired,
  goToEvent: PropTypes.func.isRequired
};

export default Row;
