import PropTypes from "prop-types";
import React, { Component } from "react";
import AssetReport from "./AssetReport";

class AssetReportManager extends Component {
  state = {
    activeAccountId: this.props.accountId,
    activeCategoryId: this.props.categoryId
  };

  componentDidMount() {
    this.fetchAccountReport();
  }

  handleAccountChange = ({ value }) =>
    this.setState({ activeAccountId: value.recordId }, () =>
      this.fetchAccountReport(value.recordId).then(({ counts = {} }) => {
        this.setState({
          activeCategoryId: Object.keys(counts).sort((a, b) =>
            counts[a].name
              .toLowerCase()
              .localeCompare(counts[b].name.toLowerCase())
          )[0]
        });
      })
    );

  handleCategoryChange = categoryId => () =>
    this.setState({ activeCategoryId: categoryId });

  fetchAccountReport = (accountId = this.state.activeAccountId) =>
    this.props.getAccountInventoryReport({
      eventId: this.props.details.id,
      accountId
    });

  render() {
    const { details, getReport, isFetching, ...props } = this.props;
    if (!getReport(this.state.activeAccountId)) {
      return <div />;
    }
    return (
      <AssetReport
        fetchReport={this.fetchAccountReport}
        isFetching={isFetching(this.state.activeAccountId)}
        onAccountChange={this.handleAccountChange}
        onCategoryChange={this.handleCategoryChange}
        report={getReport(this.state.activeAccountId)}
        {...this.state}
        {...props}
      />
    );
  }
}

AssetReportManager.propTypes = {
  accountId: PropTypes.string.isRequired,
  categoryId: PropTypes.string.isRequired,
  details: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  getReport: PropTypes.func.isRequired,
  isFetching: PropTypes.func.isRequired
};

export default AssetReportManager;
