import React from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Body from "components/Global/Modal/Layout/ScrollableBody";
import Loading from "components/Global/Modal/Layout/Loading";
import Items from "Orders/ItemPickers";
import Footer from "./Footer";
import { Div } from "components/Base";

const View = ({
  mode,
  hideModal,
  loading,
  setBodyRef,
  types,
  activeType,
  activeStep,
  search,
  updateSearch,
  cateringMealsPerDay,
  eventDateGroups
}) => {
  return (
    <StyleWrapper
      bodyStyles={{ padding: 0 }}
      hideModal={hideModal}
      heading={mode === "update" ? "Update Items" : "Add Items"}
      width={915}
    >
      <Body
        ref={setBodyRef}
        style={{ backgroundColor: "#FEFEFE", height: 400 }}
      >
        {loading || !activeType ? (
          <Loading />
        ) : (
          <Items
            search={search}
            updateSearch={updateSearch}
            types={types}
            activeType={activeType}
            cateringMealsPerDay={cateringMealsPerDay}
            eventDateGroups={eventDateGroups}
          />
        )}
      </Body>
      <Div display="row.flex-start.center">
        <Footer mode={mode} activeStep={activeStep} />
      </Div>
    </StyleWrapper>
  );
};

export default View;
