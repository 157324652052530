import Helpers from "utils/Global/Helpers";

/* eslint no-underscore-dangle: "off" */

export default {
  getForm: ({ credentials, intakeId, query }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/portal/intake/${intakeId}`,
        credentials,
        qs: query,
        success,
        error
      });
    }),
  getEventUserGroups: ({ credentials, eventId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/portal/event/${eventId}/users/accounts`,
        credentials,
        success,
        error
      });
    }),
  createSession: (credentials, intakeFormId, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/portal/intake/${intakeFormId}/session`,
        credentials,
        data,
        success,
        error
      });
    }),
  convertSession: (credentials, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/portal/intake/${data.intakeFormId}/session`,
        credentials,
        data,
        success,
        error
      });
    })
};
