/* eslint-disable no-underscore-dangle */
import { put, takeEvery, all, fork, call, select } from "redux-saga/effects";
import { registerError } from "redux/modules/errors/actions";
import { actions, getters } from "../model";
import {
  eventId as getEventId,
  eventDetails as getEventDetails
} from "redux/modules/event/selectors";
import { apiCall } from "App/Data/sagas";
import { getters as FormGetters } from "ui-kit/Form/model";
import * as R from "ramda";
import { GUEST_LIST_FORM_ID, GUEST_LIST_DATE_FORMAT } from "../constants";
import {
  actions as selectRequiredFieldsActions,
  getters as selectRequiredFieldsGetters
} from "Modules/SelectRequiredFieldsModalRefactored/model";
import {
  getMappedSelectGuestListRequiredFields,
  getGuestListNotDraggableFieldIds
} from "../selectors";
import moment from "moment-timezone";
import { showSnackbar } from "redux/modules/snackbar/actions";

const getGuestListDetails = function*() {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    yield put(actions.setLoadingGuestList(true));

    const { payload } = yield call(apiCall, {
      method: "get",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/guest-list`
    });

    yield put(actions.setGuestListSettings(payload));
    yield put(actions.setLoadingGuestList(false));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred white loading guest list settings"
        }
      ])
    );
  }
};

const updateGuestListSettings = function*() {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);
    const eventDetails = yield select(getEventDetails);

    const fields = yield select(FormGetters.fields, {
      instanceId: GUEST_LIST_FORM_ID
    });
    const disable = !R.pathOr(false, ["is_enabled", "value"], fields);
    const contactRecordTypeId = R.path(
      ["contact_record_type_id", "value", 0],
      fields
    );
    const isEnabled = R.pathOr(false, ["is_enabled", "value"], fields);
    const isLocked = R.pathOr(false, ["is_locked", "value"], fields);
    const description = R.pathOr(null, ["description", "value"], fields);
    const closeDate = R.pathOr(null, ["close_date", "value"], fields);
    const formattedCloseDate =
      closeDate && closeDate.length
        ? moment
            .tz(
              new Date(closeDate),
              GUEST_LIST_DATE_FORMAT,
              R.prop("timezone", eventDetails)
            )
            .utc()
            .format()
        : null;

    const guestListSettings = yield select(getters.guestListSettings);

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/guest-list`,
      data: {
        disable,
        contactRecordTypeId,
        isEnabled,
        isLocked,
        closeDate: formattedCloseDate,
        visibleFields: guestListSettings.visible_fields,
        requiredFields: guestListSettings.required_fields,
        fieldOrder: guestListSettings.field_order,
        variantLimits: guestListSettings.variant_limits,
        title: null,
        description
      }
    });

    yield all([
      put(actions.setShowGuestListSettingsModal(false)),
      put(actions.getGuestListDetails()),
      put(showSnackbar({ message: "Settings Updated" }))
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while updating item"
        }
      ])
    );
  }
};

const onSelectGuestListItems = function*({ payload }) {
  const guestListSettings = yield select(getters.guestListSettings);
  yield all([
    put(
      actions.setGuestListSettings({
        ...guestListSettings,
        variant_limits: R.compose(
          R.reduce((map, id) => {
            map[id] = R.prop(id)(payload);
            return map;
          }, {}),
          R.filter(id => R.prop(id)(payload)),
          R.keys
        )(payload)
      })
    ),
    put(actions.setShowSelectGuestListItemsModal(false))
  ]);
};

const openSelectRequiredFieldsModal = function*() {
  const showModal = yield select(getters.showGuestListSettingsModal);
  if (showModal) {
    const guestListSettings = yield select(getters.guestListSettings);
    const columns = yield select(getMappedSelectGuestListRequiredFields);
    const notDraggableFieldIds = yield select(getGuestListNotDraggableFieldIds);

    yield all([
      put(
        selectRequiredFieldsActions.setInitialData({
          columns,
          notDraggableFieldIds,
          visibleFields: R.propOr([], "visible_fields", guestListSettings),
          requiredFields: R.propOr([], "required_fields", guestListSettings),
          fieldOrder: R.propOr([], "field_order", guestListSettings)
        })
      ),
      put(actions.setShowSelectRequiredFieldsModal(true))
    ]);
  }
};

const hideSelectRequiredFieldsModal = function*() {
  yield put(actions.setShowSelectRequiredFieldsModal(false));
};

const updateSelectedFields = function*() {
  const showModal = yield select(getters.showGuestListSettingsModal);
  if (showModal) {
    const guestListSettings = yield select(getters.guestListSettings);
    const visibleFields = yield select(
      selectRequiredFieldsGetters.visibleFields
    );
    const requiredFields = yield select(
      selectRequiredFieldsGetters.requiredFields
    );
    const fieldOrder = yield select(selectRequiredFieldsGetters.fieldOrder);
    yield put(
      actions.setGuestListSettings({
        ...guestListSettings,
        visible_fields: visibleFields,
        required_fields: requiredFields,
        field_order: fieldOrder
      })
    );
    yield put(actions.setShowSelectRequiredFieldsModal(false));
  }
};

const watchUpdateGuestListSettings = function*() {
  yield takeEvery(
    actions.updateGuestListSettings.type,
    updateGuestListSettings
  );
};
const watchGetGuestListDetails = function*() {
  yield takeEvery(actions.getGuestListDetails.type, getGuestListDetails);
};
const watchHideRequiredFieldsModal = function*() {
  yield takeEvery(
    selectRequiredFieldsActions.hideModal.type,
    hideSelectRequiredFieldsModal
  );
};
const watchUpdateSelectedFields = function*() {
  yield takeEvery(selectRequiredFieldsActions.save.type, updateSelectedFields);
};
const watchOpenSelectRequiredFieldsModal = function*() {
  yield takeEvery(
    actions.openSelectRequiredFieldsModal.type,
    openSelectRequiredFieldsModal
  );
};
const watchOnSelectGuestListItems = function*() {
  yield takeEvery(actions.onSelectGuestListItems.type, onSelectGuestListItems);
};

const rootSaga = function*() {
  yield all([
    fork(watchGetGuestListDetails),
    fork(watchUpdateGuestListSettings),
    fork(watchHideRequiredFieldsModal),
    fork(watchUpdateSelectedFields),
    fork(watchOpenSelectRequiredFieldsModal),
    fork(watchOnSelectGuestListItems)
  ]);
};

export default rootSaga;
