import { combineReducers } from "redux";

import {
  RECEIVE_ASSETS_REPORT,
  RECEIVE_CREDENTIALS_REPORT,
  RECEIVE_CATERING_REPORT
} from "./constants";

const assetsReport = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_ASSETS_REPORT:
      return action.payload;
    default:
      return state;
  }
};

const credentialsReport = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_CREDENTIALS_REPORT:
      return action.payload;
    default:
      return state;
  }
};

const cateringReport = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_CATERING_REPORT:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  assets: assetsReport,
  credentials: credentialsReport,
  catering: cateringReport
});
