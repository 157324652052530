import { createSelector } from "reselect";
import * as R from "ramda";
import { getters } from "./model";

export const getPeople = createSelector(
  getters.data,
  getters.collapsableIds,
  getters.selectedTabs,
  getters.selectedPeopleIds,
  (data, collapsableIds, selectedTabs, selectedPeopleIds) =>
    R.map(
      person => ({
        ...person,
        selected: R.any(id => id === person.id, selectedPeopleIds),
        opened: collapsableIds[person.id],
        sections: R.map(
          section => ({
            ...section,
            selected: R.propOr("", person.id, selectedTabs) === section.id
          }),
          R.propOr([], "sections", person)
        )
      }),
      R.propOr([], "people", data)
    )
);

export const getPagination = createSelector(
  getters.data,
  R.propOr(
    {
      page: 1,
      offset: 10,
      total: 0
    },
    "pagination"
  )
);

export const getIsPeopleSelected = createSelector(
  getters.selectedPeopleIds,
  selectedPeopleIds => !R.isEmpty(selectedPeopleIds)
);
