import * as R from "ramda";
import moment from "moment";

import {
  ALL_GROUPS,
  EVERYONE,
  STATUS,
  MANAGE_BY,
  MODES,
  SUMMARIES
} from "EventLight/Common/Manage/constants";

import { GROUP_FILTER_ID, ATTENDEE_FILTER_ID } from "Passes/Common/constants";

import {
  CREDENTIAL_TYPE_ID,
  MEAL_TYPE_ID,
  INVENTORY_TYPE_ID,
  BOOTH_TYPE_ID,
  SPONSORSHIP_TYPE_ID,
  LODGING_TYPE_ID
} from "utils/item-types";

import { makeInstanceSelector } from "redux-mvc";

import { getters } from "EventLight/Common/Manage";
import { getters as TableGetters } from "ui-kit/Table/model";

import { getPathname } from "App/Router/selectors";
import { users } from "redux/modules/eventUsers/selectors";
import { forms } from "redux/modules/formsV2/forms/selectors";
import { allTypes as itemTypes } from "redux/modules/items/types/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import { ticketingProviderConfigurations } from "redux/modules/ticketing/providers/selectors";

import * as FilterControlsSelectors from "ui-kit/FilterControls/selectors";

import { recordTypes } from "redux/modules/modules/recordTypes/selectors";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

import {
  prependNotEmpty,
  formatName,
  getStatuses,
  orderFields,
  handlersSelector,
  showCheckinForUnpaidOrders,
  getGroupBy,
  getKey
} from "./utils";

export const getAttendeeLabel = makeInstanceSelector(
  getters.itemTypeView,
  itemTypeView =>
    R.propOr(
      "Attendee",
      itemTypeView
    )({
      [INVENTORY_TYPE_ID]: "Person"
    })
)(getKey);

export const getAttendeeOptions = makeInstanceSelector(
  getters.contactTypeId,
  state => recordTypes(state, STANDARD_MODULE_IDS.contacts.id),
  (contactTypeId, attendeeTypes) =>
    contactTypeId
      ? R.map(
          g => ({ all: true, ...g }),
          R.filter(g => g.id === contactTypeId, attendeeTypes)
        )
      : prependNotEmpty({ id: EVERYONE, name: "Everyone", all: true })(
          attendeeTypes
        )
)(getKey);

export const getGroupOptions = makeInstanceSelector(
  getters.groupId,
  state => recordTypes(state, STANDARD_MODULE_IDS.accounts.id),
  (groupId, groupTypes) =>
    groupId
      ? R.map(
          g => ({ all: true, ...g }),
          R.filter(g => g.id === groupId, groupTypes)
        )
      : prependNotEmpty({ id: ALL_GROUPS, name: "All Groups", all: true })(
          groupTypes
        )
)(getKey);

const getFilterCount = (getter, iniVal = 0) =>
  makeInstanceSelector(getter, R.compose(R.add(iniVal), R.length))(getKey);
export const getFieldFiltersCount = getFilterCount(orderFields);
export const getFieldFiltersSelectedCount = makeInstanceSelector(
  getters.selectedFieldFilters,
  R.pathOr(0, ["filters", "filters", "length"])
)(getKey);
// select view selectors

export const getSelectedTabTotal = makeInstanceSelector(
  getters.tabStats,
  getters.selectedTab,
  (stats, tab) => stats[tab] || 0
)(getKey);

export const isPrintMode = makeInstanceSelector(
  getters.mode,
  R.equals(MODES.PRINT)
)(getKey);
// all passes selectors
export const getAllPassesHandlers = makeInstanceSelector(
  handlersSelector,
  isPrintMode,
  (handlers, isPrintMode) => {
    if (isPrintMode) {
      return {
        ...handlers,
        enableSelectRows: true,
        printItems: handlers.printItems,
        undoPrintItems: handlers.undoPrintItems,
        refreshRecords: handlers.refetchData
      };
    }
    return {
      ...handlers,
      enableSelectRows: true,
      refreshRecords: handlers.refetchData
    };
  }
)(getKey);

export const getAllPassesRows = makeInstanceSelector(
  getters.allPassesRows,
  handlersSelector,
  (_, props) => R.pathOr("", ["params", "eventId"], props),
  (rows, handlers, eventId) =>
    rows.map(r => {
      const contactName = [r.first_name, r.last_name]
        .filter(v => v && v.length)
        .join(" ");
      const checkinRecordId =
        r.order_type === "group"
          ? r.customer_account_id
          : r.customer_contact_id;
      const checkinRecordName =
        r.order_type === "group" ? r.customer_account_name : contactName;

      return {
        id: r.customer_account_id,
        orderId: R.uniqBy(o => o.id, r.order_numbers),
        details: r.question_values_preview
          ? R.replace(/,\s/g, " · ", r.question_values_preview)
          : null,
        enableViewOrder: true,
        actions: {},
        quantity: r.count,
        passType: {
          name: r.pass_type,
          statuses: getStatuses(r.issued, r.picked_up)
        },
        handlers,
        approval: {
          manifestId: r.approval_manifest_id,
          status: r.approval_status,
          recordIds: r.line_item_ids
        },
        checkin: {
          isPending: r.approval_status === "pending",
          needsPayment: showCheckinForUnpaidOrders(eventId)
            ? false
            : r.payment_status === "unpaid",
          enabled:
            r.order_type === "group"
              ? Boolean(r.customer_account_id)
              : Boolean(r.customer_contact_id),
          title: r.picked_up
            ? `${moment(r.fulfillment_created_at).format("h:mma M/D")} - ${
                r.fulfillment_created_by_user
              }`
            : null,
          isIssued: r.issued,
          isPickedUp: r.picked_up,
          checkin: () =>
            handlers.issueItems({
              orderType: r.order_type,
              customerAccountId: r.customer_account_id,
              customerContactId: r.customer_contact_id,
              recordId: checkinRecordId,
              variantId: r.variant_id,
              fulfillmentId: r.fulfillment_id,
              status: r.issued && "pending-pickup",
              itemName: r.pass_type,
              recordName: checkinRecordName,
              quantity: r.count
            }),
          undoCheckin: () =>
            handlers.issueItems({
              orderType: r.order_type,
              customerAccountId: r.customer_account_id,
              customerContactId: r.customer_contact_id,
              recordId: checkinRecordId,
              variantId: r.variant_id,
              fulfillmentId: r.fulfillment_id,
              status: r.picked_up
                ? "undo-fulfillment"
                : r.issued
                ? "undo-issuance"
                : undefined,
              itemName: r.pass_type,
              recordName: checkinRecordName,
              quantity: r.count
            })
        },
        attendee: {
          name:
            r.order_type === "group"
              ? formatName(r.customer_account_name, r.customer_account_id)
              : formatName(contactName, r.customer_contact_id),
          groupName:
            r.order_type === "group"
              ? formatName(contactName, r.customer_contact_id)
              : formatName(r.customer_account_name, r.customer_account_id),
          contactType: r.customer_account_type
        }
      };
    })
)(getKey);

export const getAttendeeListRows = makeInstanceSelector(
  getters.attendeeListRows,
  handlersSelector,
  (_, props) => R.pathOr("", ["params", "eventId"], props),
  (rows, handlers, eventId) =>
    R.map(
      contact => ({
        person: {
          name: [contact.first_name, contact.last_name]
            .filter(v => v)
            .join(" "),
          accountName: contact.account_name,
          accountType: contact.customer_account_type,
          accountId: contact.account_id || "",
          email: contact.mail || "",
          statuses: contact.statuses || [],
          id: contact.customer_contact_id
        },
        passes: R.map(r => {
          const contactName = [r.first_name, r.last_name]
            .filter(v => v && v.length)
            .join(" ");
          const checkinRecordId =
            r.order_type === "group"
              ? r.customer_account_id
              : r.customer_contact_id;
          const checkinRecordName =
            r.order_type === "group" ? r.customer_account_name : contactName;
          return {
            quantity: r.quantity,
            details: r.question_values_preview
              ? R.replace(/,\s/g, " · ", r.question_values_preview)
              : null,
            passType: {
              color: r.bg_color,
              name: r.pass_type_name,
              statuses: getStatuses(r.issued, r.picked_up)
            },
            attendee: {
              name: formatName(contactName, contact.customer_contact_id),
              contactType: contact.customer_account_type,
              groupName: formatName(
                contact.account_name,
                contact.customer_account_id
              )
            },
            orderId: R.uniqBy(o => o.id)(r.order_numbers),
            enableViewOrder: true,
            handlers,
            approval: {
              manifestId: r.approval_manifest_id,
              status: r.approval_status,
              recordIds: r.line_item_ids
            },
            checkin: {
              isPending: r.approval_status === "pending",
              needsPayment: showCheckinForUnpaidOrders(eventId)
                ? false
                : r.payment_status === "unpaid",
              enabled:
                r.order_type === "group"
                  ? Boolean(r.customer_account_id)
                  : Boolean(r.customer_contact_id),
              title: r.picked_up
                ? `${moment(r.fulfillment_created_at).format("h:mma M/D")} - ${
                    r.fulfillment_created_by_user
                  }`
                : null,
              isIssued: r.issued,
              isPickedUp: r.picked_up,
              checkin: () =>
                handlers.issueItems({
                  orderType: r.order_type,
                  customerAccountId: r.customer_account_id,
                  customerContactId: r.customer_contact_id,
                  recordId: checkinRecordId,
                  variantId: r.variant_id,
                  fulfillmentId: r.fulfillment_id,
                  status: r.issued && "pending-pickup",
                  itemName: r.pass_type,
                  recordName: checkinRecordName,
                  quantity: r.count
                }),
              undoCheckin: () =>
                handlers.issueItems({
                  orderType: r.order_type,
                  customerAccountId: r.customer_account_id,
                  customerContactId: r.customer_contact_id,
                  recordId: checkinRecordId,
                  variantId: r.variant_id,
                  fulfillmentId: r.fulfillment_id,
                  status: r.picked_up
                    ? "undo-fulfillment"
                    : r.issued
                    ? "undo-issuance"
                    : undefined,
                  itemName: r.pass_type,
                  recordName: checkinRecordName,
                  quantity: r.count
                })
            }
          };
        }, contact.detail),
        allLineItemIds: contact.line_item_ids,
        allCredentialLineItemIds: R.compose(
          R.uniq,
          R.flatten,
          R.map(R.prop("pending_line_item_ids"))
        )(contact.detail),
        allMealLineItemIds: R.compose(
          R.uniq,
          R.flatten,
          R.map(R.prop("pending_line_item_ids"))
        )(contact.detail)
      }),
      rows
    )
)(getKey);

export const getGroupAffiliationRows = makeInstanceSelector(
  getters.groupAffiliationRows,
  rows =>
    R.map(
      card => ({
        account: {
          name: card.customer_account_name,
          type: card.customer_account_type,
          statuses: card.statuses || [],
          contacts: card.contacts,
          id: card.customer_account_id
        },
        passes: R.map(
          r => ({
            passType: {
              color: r.bg_color,
              name: r.pass_type_name
            },
            summary: {
              requested: r.requested,
              rejected: r.rejected,
              approved: r.approved,
              price: r.price,
              issued: Number(r.issued),
              received: r.picked_up
            }
          }),
          card.detail
        ),
        allLineItemIds: card.line_item_ids,
        allCredentialLineItemIds: R.compose(
          R.uniq,
          R.flatten,
          R.map(detail => {
            if (detail.type_id === CREDENTIAL_TYPE_ID) {
              return detail.pending_line_item_ids;
            }
            return [];
          })
        )(card.detail),
        allMealLineItemIds: R.compose(
          R.uniq,
          R.flatten,
          R.map(detail => {
            if (detail.type_id === MEAL_TYPE_ID) {
              return detail.pending_line_item_ids;
            }
            return [];
          })
        )(card.detail)
      }),
      rows
    )
)(getKey);

export const getGroupByOptions = makeInstanceSelector(
  getters.itemTypeView,
  getters.summary,
  getAttendeeLabel,
  (itemType, summary, attendeeLabel = "Attendee") =>
    R.propOr(
      [
        { name: attendeeLabel, id: MANAGE_BY.ATTENDEE_LIST },
        {
          name: "Group",
          id: MANAGE_BY.GROUP_AFFILIATION,
          selected: false
        }
        // @NOTE: Hiding due to perf reasons
        // { name: "Order", id: MANAGE_BY.ORDER_NUMBER }
      ],
      itemType,
      {
        [CREDENTIAL_TYPE_ID]: [
          { name: attendeeLabel, id: MANAGE_BY.ATTENDEE_LIST },
          {
            name: "Group",
            id: MANAGE_BY.GROUP_AFFILIATION,
            selected: false
          },
          // @NOTE: Hiding due to perf reasons
          // { name: "Order", id: MANAGE_BY.ORDER_NUMBER },
          ![SUMMARIES.CATEGORY, SUMMARIES.ITEM_TYPE].includes(summary.type) && {
            name: "Item Category",
            id: MANAGE_BY.CATEGORY
          },
          summary.type !== SUMMARIES.ITEM_TYPE && {
            name: "Item Type",
            id: MANAGE_BY.ITEM_TYPE
          }
        ].filter(o => o),
        [MEAL_TYPE_ID]: [
          { name: attendeeLabel, id: MANAGE_BY.ATTENDEE_LIST },
          {
            name: "Group",
            id: MANAGE_BY.GROUP_AFFILIATION,
            selected: false
          }
          // @NOTE: Hiding due to perf reasons
          // { name: "Order", id: MANAGE_BY.ORDER_NUMBER }
        ],
        [BOOTH_TYPE_ID]: [
          {
            name: "Group",
            id: MANAGE_BY.GROUP_AFFILIATION,
            selected: false
          },
          { name: "Order", id: MANAGE_BY.ORDER_NUMBER }
        ],
        [INVENTORY_TYPE_ID]: [
          { name: attendeeLabel, id: MANAGE_BY.ATTENDEE_LIST },
          {
            name: "Group",
            id: MANAGE_BY.GROUP_AFFILIATION,
            selected: false
          },
          // @NOTE: Hiding due to perf reasons
          // { name: "Order", id: MANAGE_BY.ORDER_NUMBER },
          ![SUMMARIES.CATEGORY, SUMMARIES.ITEM_TYPE].includes(summary.type) && {
            name: "Item Category",
            id: MANAGE_BY.CATEGORY
          },
          summary.type !== SUMMARIES.ITEM_TYPE && {
            name: "Item Type",
            id: MANAGE_BY.ITEM_TYPE
          }
        ].filter(o => o),
        [LODGING_TYPE_ID]: [
          { name: attendeeLabel, id: MANAGE_BY.ATTENDEE_LIST },
          {
            name: "Group",
            id: MANAGE_BY.GROUP_AFFILIATION,
            selected: false
          },
          { name: "Order", id: MANAGE_BY.ORDER_NUMBER },
          ![SUMMARIES.CATEGORY, SUMMARIES.ITEM_TYPE].includes(summary.type) && {
            name: "Property",
            id: MANAGE_BY.CATEGORY
          },
          summary.type !== SUMMARIES.ITEM_TYPE && {
            name: "Room Type",
            id: MANAGE_BY.ITEM_TYPE
          }
        ].filter(o => o)
      }
    )
)(getKey);

export const getOrderNumberRows = makeInstanceSelector(
  getGroupBy,
  getters.orderNumberRows,
  getters.orderNumberRowsIntegration,
  handlersSelector,
  getters.orderId,
  (_, props) => R.pathOr("", ["params", "eventId"], props),
  (view, orderRows, integrationOrderRows, handlers, parentOrderId, eventId) =>
    R.map(
      order => ({
        order: {
          id: order.order_id,
          number: order.order_number,
          statuses: order.statuses || [],
          integrationStatuses: order.integration_statuses || [],
          type: order.is_allocation
            ? "allocation"
            : `${order.order_type} order`,
          collectNames: order.require_assignment,
          title:
            order.order_type === "group"
              ? order.customer_account_name
              : [order.first_name, order.last_name]
                  .filter(v => v && v.length)
                  .join(" "),
          caption:
            order.order_type === "group"
              ? [order.first_name, order.last_name]
                  .filter(v => v && v.length)
                  .join(" ")
              : order.customer_account_name
        },
        passes: R.map(r => {
          const contactName = [r.first_name, r.last_name]
            .filter(v => v && v.length)
            .join(" ");
          const checkinRecordId =
            r.order_type === "group"
              ? r.customer_account_id
              : r.customer_contact_id;
          const checkinRecordName =
            r.order_type === "group" ? r.customer_account_name : contactName;

          return {
            quantity: r.quantity,
            attendee: {
              name:
                r.order_type === "group"
                  ? formatName(r.customer_account_name, r.customer_account_id)
                  : formatName(contactName, r.customer_contact_id),
              groupName:
                r.order_type === "group"
                  ? formatName(contactName, r.customer_contact_id)
                  : formatName(r.customer_account_name, r.customer_account_id),
              contactType: r.customer_account_type || ""
            },
            passType: {
              name: r.pass_type_name,
              statuses: getStatuses(r.issued, r.picked_up)
            },
            orderId: [{ id: order.order_id, number: order.order_number }],
            details: r.question_values_preview
              ? R.replace(/,\s/g, " · ", r.question_values_preview)
              : null,
            order: order.order_number,
            enableViewOrder:
              R.isNil(parentOrderId) ||
              R.isEmpty(parentOrderId) ||
              parentOrderId !== order.order_id,
            handlers,
            approval: {
              manifestId: r.approval_manifest_id,
              status: r.approval_status,
              recordIds: r.line_item_ids
            },
            checkin: {
              isPending: r.approval_status === "pending",
              needsPayment: showCheckinForUnpaidOrders(eventId)
                ? false
                : order.payment_status === "unpaid",
              enabled:
                r.order_type === "group"
                  ? Boolean(r.customer_account_id)
                  : Boolean(r.customer_contact_id),
              title: r.picked_up
                ? `${moment(r.fulfillment_created_at).format("h:mma M/D")} - ${
                    r.fulfillment_created_by_user
                  }`
                : null,
              isIssued: r.issued,
              isPickedUp: r.picked_up,
              checkin: () =>
                handlers.issueItems({
                  orderType: r.order_type,
                  customerAccountId: r.customer_account_id,
                  customerContactId: r.customer_contact_id,
                  recordId: checkinRecordId,
                  variantId: r.variant_id,
                  fulfillmentId: r.fulfillment_id,
                  status: r.issued && "pending-pickup",
                  itemName: r.pass_type,
                  recordName: checkinRecordName,
                  quantity: r.count
                }),
              undoCheckin: () =>
                handlers.issueItems({
                  orderType: r.order_type,
                  customerAccountId: r.customer_account_id,
                  customerContactId: r.customer_contact_id,
                  recordId: checkinRecordId,
                  variantId: r.variant_id,
                  fulfillmentId: r.fulfillment_id,
                  status: r.picked_up
                    ? "undo-fulfillment"
                    : r.issued
                    ? "undo-issuance"
                    : undefined,
                  itemName: r.pass_type,
                  recordName: checkinRecordName,
                  quantity: r.count
                })
            }
          };
        }, order.detail),
        allLineItemIds: R.compose(
          R.uniq,
          R.flatten,
          R.map(R.prop("pending_line_item_ids"))
        )(order.detail),
        allCredentialLineItemIds: R.compose(
          R.uniq,
          R.flatten,
          R.map(detail => {
            if (detail.type_id === CREDENTIAL_TYPE_ID) {
              return detail.pending_line_item_ids;
            }
            return [];
          })
        )(order.detail),
        allMealLineItemIds: R.compose(
          R.uniq,
          R.flatten,
          R.map(detail => {
            if (detail.type_id === MEAL_TYPE_ID) {
              return detail.pending_line_item_ids;
            }
            return [];
          })
        )(order.detail)
      }),
      view === MANAGE_BY.ORDER_NUMBER ? orderRows : integrationOrderRows
    )
)(getKey);

// pagination

export const getPageSize = R.compose(R.prop("pageSize"), getters.pagination);

export const getCurrentPage = R.compose(R.prop("page"), getters.pagination);

export const getShowSummary = makeInstanceSelector(
  getters.summary,
  R.compose(R.not, R.isEmpty, R.prop("id"))
)(getKey);

export const getSourceFilters = makeInstanceSelector(forms, formTypes => [
  {
    id: "forms",
    name: "FORMS",
    items: R.sortBy(
      R.prop("name"),
      R.filter(R.compose(R.not, R.isEmpty, R.prop("name")), formTypes)
    )
  }
  // @NOTE: add created via portal and created via GLM
])(getKey);

const getName = (...components) =>
  R.join(
    " ",
    R.filter(R.compose(R.not, R.either(R.isNil, R.isEmpty)), components)
  );

export const getUserFilters = makeInstanceSelector(users, userTypes => {
  const format = R.compose(
    R.sortBy(R.prop("name")),
    R.map(user => ({
      ...user,
      id: user.user_id,
      name: `${getName(user.user_fname, user.user_lname)} (${user.user_email})`
    }))
  );

  return [
    {
      id: "admins",
      name: "EVENT ADMINS",
      items: format(userTypes.filter(u => u.is_event_user))
    },
    {
      id: "portal",
      name: "PORTAL USERS",
      items: format(userTypes.filter(u => !u.is_event_user))
    }
  ];
})(getKey);

export const getSelectedFieldFilters = makeInstanceSelector(
  getters.selectedFieldFilters,
  filters => R.pathOr({}, ["filters"])(filters)
)(getKey);

export const getFieldFilters = makeInstanceSelector(orderFields, fields => {
  return fields.filter(
    f => ["dropdown"].includes(f.type) && !f.settings.allowMultipleSelect
  );
})(getKey);

export const getAllGroupsUrl = makeInstanceSelector(
  (_, props) => R.pathOr("", ["params", "eventId"], props),
  (_, props) => R.path(["location", "query", "groupTypeId"], props),
  (_, props) => R.path(["location", "query", "personTypeId"], props),
  (eventId, groupTypeId, personTypeId) =>
    `/event-light/${eventId}/crm/manage/${
      groupTypeId
        ? STANDARD_MODULE_IDS.accounts.id
        : STANDARD_MODULE_IDS.contacts.id
    }/type/${groupTypeId || personTypeId}`
)(getKey);

export const getGroupName = makeInstanceSelector(
  (_, props) =>
    R.path(["location", "query", "groupTypeId"], props) ||
    R.path(["location", "query", "personTypeId"], props),
  state => recordTypes(state, STANDARD_MODULE_IDS.accounts.id),
  state => recordTypes(state, STANDARD_MODULE_IDS.contacts.id),
  (recordTypeId, attendeeTypes, groupTypes) =>
    R.propOr(
      "",
      "name",
      R.find(
        type => type.id === recordTypeId,
        R.concat(attendeeTypes, groupTypes)
      )
    )
)(getKey);

export const getIsMyApprovals = () =>
  R.contains("/my-approvals")(window.location.href);

export const getViewsToSelect = makeInstanceSelector(
  getPathname,
  ticketingProviderConfigurations,
  selectFeatureFlag(flags.CAN_VIEW_PRINTING.NAME),
  (pathname, integrations, canViewPrinting) =>
    [
      {
        id: "dashboard",
        name: "Dashboard",
        icon: "dashboard",
        to: R.replace(/\/manage\/.+$/, "/dashboard", pathname),
        visible: !pathname.includes("/expo")
      },
      {
        id: "my-approvals",
        name: "My Approvals",
        icon: "check_circle_outline",
        to: R.replace(/\/manage\/.+$/, "/manage/my-approvals", pathname),
        visible: true
      },
      {
        id: "all-requests",
        name: "All Requests",
        icon: "list",
        to: R.replace(/\/manage\/.+$/, "/manage/all-requests", pathname),
        visible: true
      },
      {
        id: "fulfill",
        name: "Issue",
        icon: "add_shopping_cart",
        to: R.replace(/\/manage\/.+$/, "/manage/fulfill", pathname),
        visible: !pathname.includes("/expo")
      },
      {
        id: "print",
        name: "Print",
        icon: "print",
        to: R.replace(/\/manage\/.+$/, "/manage/print", pathname),
        visible: pathname.includes("/passes") && canViewPrinting
      },
      {
        id: "integration-orders",
        name: "Integration",
        icon: "refresh",
        to: R.replace(/\/manage\/.+$/, "/manage/integration-orders", pathname),
        visible: pathname.includes("/passes") && integrations.length
      }
      /*
    {
      id: "inventory",
      name: "Inventory",
      icon: "signal_cellular_alt",
      to: R.replace(/\/manage\/.+$/, "/manage/inventory", pathname),
      visible: true
    }
    */
    ].filter(item => item.visible)
)(getKey);

export const getSelectedView = makeInstanceSelector(
  getViewsToSelect,
  (_, props) => R.path(["location", "pathname"], props),
  (views, pathname) =>
    R.find(
      R.compose(
        R.contains(R.__, pathname), //eslint-disable-line no-underscore-dangle
        R.prop("id")
      ),
      views
    )
)(getKey);

const issuanceTabs = [
  {
    title: "Approved",
    id: STATUS.APPROVED
  },
  {
    title: "Confirmation Not Sent",
    id: STATUS.CONFIRMATION_NOT_SENT
  },
  {
    title: "Not Yet Issued",
    id: STATUS.NOT_YET_ISSUED
  },
  {
    title: "Pending Pickup",
    id: STATUS.PENDING_PICKUP
  },
  {
    title: "Completed",
    id: STATUS.COMPLETED
  }
];

const printTabs = [
  {
    title: "Show All",
    id: STATUS.ALL
  },
  {
    title: "Pending Photo",
    id: STATUS.PENDING_PHOTO
  },
  {
    title: "Printed",
    id: STATUS.PRINTED
  },
  {
    title: "Not Printed",
    id: STATUS.NOT_PRINTED
  },
  {
    title: "Pending Pickup",
    id: STATUS.PENDING_PICKUP
  },
  {
    title: "Completed",
    id: STATUS.COMPLETED
  }
];

const reviewTabs = [
  {
    title: "Show All",
    id: STATUS.ALL
  },
  {
    title: "Pending Review",
    id: STATUS.PENDING_REVIEW
  },
  /*
  // @TODO: Add when we have advanced approvals
  {
    title: "Pending My Review",
    id: STATUS.PENDING_MY_REVIEW
  },
  */
  {
    title: "Approved",
    id: STATUS.APPROVED
  },
  {
    title: "Denied",
    id: STATUS.DENIED
  },
  {
    title: "Unpaid",
    id: STATUS.UNPAID
  }
];

const myApprovalsTabs = [
  {
    title: "Pending My Review",
    id: STATUS.PENDING_REVIEW
  },
  {
    title: "Approved",
    id: STATUS.APPROVED
  },
  {
    title: "Denied",
    id: STATUS.DENIED
  }
];

const integrationTabs = [
  {
    title: "Show All",
    id: STATUS.ALL
  },
  {
    title: "Approved & Not Synced",
    id: STATUS.APPROVED_NOT_SYNCED
  },
  {
    title: "Synced",
    id: STATUS.SYNCED
  },
  {
    title: "Issues",
    id: STATUS.INTEGRATION_ERROR
  }
];

export const getTabs = makeInstanceSelector(
  getters.tabStats,
  getGroupBy,
  getters.mode,
  getIsMyApprovals,
  (stats, view, mode, isMyApprovals) => {
    const tabs = isMyApprovals
      ? myApprovalsTabs
      : view === MANAGE_BY.INTEGRATION
      ? integrationTabs
      : mode === MODES.ISSUANCE
      ? issuanceTabs
      : mode === MODES.PRINT
      ? printTabs
      : reviewTabs;

    return tabs.map(tab => ({
      ...tab,
      title: R.isNil(stats[tab.id])
        ? tab.title
        : `${tab.title} (${stats[tab.id]})`
    }));
  }
)(getKey);

export const getSearchPlaceholder = makeInstanceSelector(getGroupBy, view => {
  if (view === MANAGE_BY.GROUP_AFFILIATION) {
    return "Search by group name";
  }
  if (view === MANAGE_BY.ORDER_NUMBER) {
    return "Search by name, email, group affiliation or order number";
  }
  if (view === MANAGE_BY.INTEGRATION) {
    return "Search by order number";
  }
  if (view === MANAGE_BY.ATTENDEE_LIST) {
    return "Search by first name, last name or email";
  }
  return "Search by name, email or group affiliation";
})(getKey);

export const getMealGroups = makeInstanceSelector(
  itemTypes,
  eventDetails,
  (itemTypes, eventDetails) => {
    const settingsSelectedDays = R.pathOr(
      [],
      ["module_settings", "catering", "selected_days"]
    )(eventDetails);
    const settingsMealDays = R.pathOr(
      [],
      ["module_settings", "catering", "meal_days"]
    )(eventDetails);
    const mealItems = itemTypes.length
      ? R.compose(
          R.propOr([], "items"),
          R.head,
          R.propOr([], "groups"),
          R.head,
          R.filter(R.propEq("id", MEAL_TYPE_ID))
        )(itemTypes)
      : [];

    const mealDays = R.compose(
      R.uniq,
      R.filter(dayId => settingsSelectedDays.includes(dayId)),
      R.map(R.prop("dayId")),
      R.pathOr([], ["module_settings", "catering", "meal_days"])
    )(eventDetails);
    const selectedMeals = mealItems.filter(m => {
      const variants = m.variants.filter(v => {
        const rules = v.rules.filter(r => mealDays.includes(r.value));
        return rules.length > 0;
      });
      return variants.length > 0;
    });
    return mealDays.map(day => {
      const name = moment(day, "YYYY-MM-DD").format("dddd, MMMM DD");
      const mealsIds = settingsMealDays
        .filter(m => m.dayId === day)
        .map(m => m.mealId);

      const meals = R.compose(
        R.map(m =>
          R.compose(
            R.map(v => ({
              id: v.id,
              name: m.name,
              display_name: v.display_name
            })),
            R.filter(v => {
              const rules = v.rules.filter(r => r.value === day);
              return rules.length > 0;
            }),
            R.prop("variants")
          )(m)
        ),
        R.sortBy(R.prop("order")),
        R.filter(m => mealsIds.includes(m.id))
      )(selectedMeals);

      return { id: day, name, items: R.flatten(meals) };
    });
  }
)(getKey);

export const getPassesGroups = makeInstanceSelector(
  itemTypes,
  (_, props) => R.prop("categoryId", props),
  (itemTypes, categoryId) =>
    R.compose(
      R.map(group => {
        return {
          ...group,
          items: R.compose(
            R.flatten,
            R.map(item => {
              return item.variants.map(v => ({
                id: v.id,
                name: v.display_name
              }));
            })
          )(group.items)
        };
      }),
      categoryId ? R.filter(R.propEq("id", categoryId)) : R.identity,
      R.propOr([], "groups"),
      R.head,
      R.filter(R.propEq("id", CREDENTIAL_TYPE_ID))
    )(itemTypes)
)(getKey);

export const getInventoryGroups = makeInstanceSelector(
  itemTypes,
  (_, props) => R.prop("categoryId")(props),
  (itemTypes, categoryId) =>
    R.compose(
      R.map(group => {
        return {
          ...group,
          items: R.compose(
            R.flatten,
            R.map(item => {
              return item.variants.map(v => ({
                id: v.id,
                name: v.display_name
              }));
            })
          )(group.items)
        };
      }),
      categoryId ? R.filter(R.propEq("id", categoryId)) : R.identity,
      R.propOr([], "groups"),
      R.head,
      R.filter(R.propEq("id", INVENTORY_TYPE_ID))
    )(itemTypes)
)(getKey);

export const getBoothGroups = makeInstanceSelector(
  itemTypes,
  R.compose(
    R.map(group => {
      return {
        ...group,
        items: R.compose(
          R.flatten,
          R.map(item => {
            return item.variants.map(v => ({
              id: v.id,
              name: v.display_name
            }));
          })
        )(group.items)
      };
    }),
    R.propOr([], "groups"),
    R.head,
    R.filter(R.propEq("id", BOOTH_TYPE_ID))
  )
)(getKey);

export const getSponsorshipGroups = makeInstanceSelector(
  itemTypes,
  R.compose(
    R.map(group => {
      return {
        ...group,
        items: R.compose(
          R.flatten,
          R.map(item => {
            return item.variants.map(v => ({
              id: v.id,
              name: v.display_name
            }));
          })
        )(group.items)
      };
    }),
    R.propOr([], "groups"),
    R.head,
    R.filter(R.propEq("id", SPONSORSHIP_TYPE_ID))
  )
)(getKey);

export const getIniSelectedItems = (state, props) => {
  const itemTypeView = getters.itemTypeView(state);
  if (itemTypeView === MEAL_TYPE_ID) {
    return getters.selectedMeals(state, props);
  }
  return getters.selectedPasses(state, props);
};

const getItemFiltersOn = makeInstanceSelector(
  getters.selectedSources,
  getters.selectedRequester,
  getters.selectedMeals,
  getters.selectedFieldFilters,
  getters.selectedPasses,
  getters.selectedInventoryItems,
  getters.selectedBoothItems,
  getters.selectedSponsorshipItems,
  (state, props = {}) =>
    props.ignoreApprovalsFilter ? false : getIsMyApprovals(state),
  (...args) =>
    R.any(
      R.compose(R.not, val =>
        typeof val === "boolean" ? !val : R.isEmpty(val)
      ),
      args
    )
)(getKey);

export const getFiltersOn = makeInstanceSelector(
  FilterControlsSelectors.makeGetAllFiltersOn([
    GROUP_FILTER_ID,
    ATTENDEE_FILTER_ID
  ]),
  getItemFiltersOn,
  (...args) => R.any(R.identity, args)
)(getKey);

export const getFiltersCaption = makeInstanceSelector(
  getIsMyApprovals,
  isMyApprovals =>
    isMyApprovals
      ? "Only showing requests that you are an approver for"
      : "Only showing results that contain or match filters"
)(getKey);

export const showFiltersClearButton = makeInstanceSelector(
  getIsMyApprovals,
  isMyApprovals => (isMyApprovals ? false : true)
)(getKey);

export const showFiltersClearButtonInSidebar = makeInstanceSelector(
  state => getItemFiltersOn(state, { ignoreApprovalsFilter: true }),
  show => show
)(getKey);

// toggled rows
export const getToggledRows = makeInstanceSelector(
  TableGetters.toggledRows,
  R.identity
)(getKey);

export const getToggledRowIds = makeInstanceSelector(getToggledRows, rows => {
  return rows.reduce((list, id) => {
    return [...list, ...id.split("_")];
  }, []);
})(getKey);

export const getCountOfToggledRows = makeInstanceSelector(
  getToggledRows,
  getToggledRowIds,
  isPrintMode,
  (initialToggledRows, processedRowIds, isPrintMode) =>
    isPrintMode ? R.length(initialToggledRows) : R.length(processedRowIds)
)(getKey);

export const getHasViewChanged = makeInstanceSelector(
  getters.preferences,
  getters.originalPreferences,
  (preferences, originalPreferences) =>
    !R.equals(preferences, originalPreferences)
)(getKey);

export const canShowSubscribeOption = makeInstanceSelector(
  getGroupBy,
  view => view === MANAGE_BY.ALL_PASSES
)(getKey);

export const getMode = makeInstanceSelector(getPathname, pathname => {
  if (pathname.includes("/print")) {
    return MODES.PRINT;
  } else if (pathname.includes("/issue")) {
    return MODES.ISSUANCE;
  }
  return MODES.REVIEW;
})(getKey);

export * from "./byCategory";
export * from "./byItemType";

export { getGroupBy } from "./utils";
