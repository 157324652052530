import React from "react";
import EditorBase from "./TableConnector";
import Text from "components/Global/Editors/TextEditor";

export default class TextEditor extends EditorBase {
  render() {
    return (
      <Text
        className="editor-main"
        value={this.state}
        onChange={this.handleChange}
      />
    );
  }
}
