import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import {
  getApplicationFeeOptions,
  getShowApplicationPanel
} from "Sponsors/SelfSetup/selectors";

import { PAYMENT_FORM_ID, PAYMENT_FIELDS } from "Sponsors/SelfSetup/constants";

import { WithThemeConsumer, VARIANTS } from "ui-kit/Theme";
import {
  WithFormInstanceProvider,
  Row,
  Column,
  Input,
  RadioList
} from "ui-kit/Form/View";

import { Text3, Text2, Span, MoneyIcon } from "components/Base";
import { QuestionCard } from "Sponsors/Common/View";

const applyFee = [
  { id: "againstOrder", label: "Apply fee against order total" },
  { id: "againstTotal", label: "Add fee into order total" }
];

const decorate = R.compose(
  connect((state, props) => ({
    options: getApplicationFeeOptions(state, props),
    showPanel: getShowApplicationPanel(state, props)
  })),
  WithFormInstanceProvider(R.always(PAYMENT_FORM_ID)),
  WithThemeConsumer({ variant: VARIANTS.BACKGROUND })
);

const ApplicationFee = ({ options = [], showPanel = false }) => (
  <QuestionCard
    mt={3}
    question={
      <Text3>
        Add on your own <Span fw={3}>application fee</Span> to cover time spent
        reviewing submissions:
      </Text3>
    }
    options={options}
    fieldId={PAYMENT_FIELDS.APPLICATION_FEE}
    footer={
      showPanel ? (
        <Column width={1}>
          <Text2 my={2}>Aplication Amount</Text2>
          <Row>
            <Input
              fieldId={PAYMENT_FIELDS.APPLICATION_PRICE}
              LeftIcon={MoneyIcon}
            />
            <RadioList
              fieldId={PAYMENT_FIELDS.APPLICATION_APPLY_FEE}
              options={applyFee}
              ml={3}
            />
          </Row>
        </Column>
      ) : null
    }
  />
);

export default decorate(ApplicationFee);
