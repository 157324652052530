import React, { useRef, useEffect } from "react";
import * as R from "ramda";
import SignatureCanvas from "react-signature-canvas";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Signature = ({ onEnd, base64Value, disabled }) => {
  const canvasRef = useRef();
  const showImageVersion = disabled && base64Value && base64Value.length;

  useEffect(() => {
    if (!showImageVersion && !R.isEmpty(base64Value)) {
      canvasRef.current.fromDataURL(base64Value);
    }
  }, []);

  useEffect(() => {
    if (!showImageVersion) {
      if (!disabled) {
        canvasRef.current.on();
      } else {
        canvasRef.current.off();
      }
    }
  }, [disabled]);
  return (
    <div>
      <div style={{ border: `solid 1px #${!disabled ? "333" : "d4d4d4"}` }}>
        {showImageVersion ? <img src={base64Value} /> : null}
        {showImageVersion ? null : (
          <SignatureCanvas
            ref={canvasRef}
            onEnd={() => {
              const imgBase64 = canvasRef.current.toDataURL();
              onEnd(imgBase64);
            }}
            dotSize={1}
            throttle={16}
            canvasProps={{
              width: 265,
              height: 100,
              display:
                disabled && base64Value && base64Value.length
                  ? "none"
                  : undefined
            }}
          />
        )}
      </div>
      {!disabled ? (
        <div
          styleName="clear"
          onClick={() => {
            canvasRef.current.clear();
            onEnd("");
          }}
        >
          clear
        </div>
      ) : null}
    </div>
  );
};

export default CSSModules(css)(Signature);
