import React from "react";
import { SubNavWrapper, SubNavLink } from "EventLight/Common/SubNav";
import { TAB_IDS } from "./constants";
import { withRouter } from "react-router";
import { isLiveOrHybridEvent } from "redux/modules/event/selectors";
import { connect } from "react-redux";
import * as R from "ramda";

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    isLiveOrHybridEvent: isLiveOrHybridEvent(state, props)
  }))
);

const SubNav = ({
  selectedView,
  router,
  params,
  tabs,
  isLiveOrHybridEvent
}) => (
  <SubNavWrapper tabs={tabs} supportId="expo">
    {isLiveOrHybridEvent ? (
      <SubNavLink
        name="Requests"
        active={selectedView === TAB_IDS.REQUESTS}
        onClick={() =>
          router.push({
            pathname: `/event-light/${params.eventId}/expo/requests/all-requests`
          })
        }
      />
    ) : null}

    {isLiveOrHybridEvent ? (
      <SubNavLink
        name="Sales"
        active={selectedView === TAB_IDS.SALES}
        onClick={() =>
          router.push({
            pathname: `/event-light/${params.eventId}/expo/sales`
          })
        }
      />
    ) : null}

    {isLiveOrHybridEvent ? (
      <SubNavLink
        name="Send Emails"
        active={selectedView === TAB_IDS.SEND_EMAILS}
        onClick={() =>
          router.push({
            pathname: `/event-light/${params.eventId}/expo/send-emails`
          })
        }
      />
    ) : null}

    {isLiveOrHybridEvent ? (
      <SubNavLink
        name="Settings"
        active={[
          TAB_IDS.BOOTHS,
          TAB_IDS.SPONSORSHIPS,
          TAB_IDS.APPROVERS,
          TAB_IDS.VIRTUAL_BOOTHS
        ].includes(selectedView)}
        onClick={() =>
          router.push({
            pathname: `/event-light/${params.eventId}/expo/settings/booths`
          })
        }
      />
    ) : null}
  </SubNavWrapper>
);

export default decorate(SubNav);
