import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AssetReportManager from "./AssetReportManager";

import { hideModal } from "redux/modules/modal/actions";
import { getAccountInventoryReport } from "redux/modules/inventory/reports/account-overview/actions";
import {
  isFetching,
  accountOverviewReport
} from "redux/modules/inventory/reports/account-overview/selectors";
import { eventDetails } from "redux/modules/event/selectors";

function mapStateToProps(state) {
  return {
    details: eventDetails(state),
    getReport: accountId => accountOverviewReport(state, accountId),
    isFetching: accountId => isFetching(state, accountId)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAccountInventoryReport,
      hideModal
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetReportManager);
