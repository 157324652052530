import React, { Component } from "react";
import * as R from "ramda";
import Payments from "./Payments";
import { withState } from "utils/General";
import Module from "components/Event/Settings/Module";
import View from "./View";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

class Controller extends Component {
  goToPath = path => {
    this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/settings${path}`
    });
    this.props.setTabValue("editEventSettingsCredentialsApprovalWorkflows");
  };

  render() {
    const { canViewPayments, activeTabValue, setTabValue } = this.props;

    const tabs = [
      {
        key: "editEventSettingsOrderFields",
        visible: true,
        label: "All Fields",
        onClick: () => this.goToPath("/orders/fields"),
        comp: (
          <Module
            {...{
              contentOnly: true,
              route: {
                name: "eventSettingsModule"
              },
              context: {
                ...this.props.params,
                moduleId: STANDARD_MODULE_IDS.orders.id
              }
            }}
          />
        )
      },
      {
        key: "editEventSettingsOrderPayments",
        visible: canViewPayments,
        label: "Payments",
        onClick: () => this.goToPath("/orders/payments"),
        path: "/orders/payments",
        comp: <Payments />
      }
    ];

    return (
      <View
        {...{
          tabs,
          activeTabValue,
          setTabValue
        }}
      />
    );
  }
}

export default withState(
  "activeTabValue",
  "setTabValue",
  props => props.route.name
)(Controller);
