import React from "react";

import { connect } from "react-redux";

import { getters } from "Items/AssignQuestionsItemsModal";

import { Div } from "components/Base";

import { HEIGHTS } from "Items/AssignQuestionsItemsModal/constants";

import AssignItems from "./AssignItems";

import Loading from "ui-kit/Loading";

const bodyHeight = 661 - (HEIGHTS.HEADER + HEIGHTS.FOOTER);

const decorate = connect(state => ({
  loading: getters.loading(state)
}));

const Body = ({ loading, handlers }) => (
  <Div width={1} height={bodyHeight}>
    {loading ? (
      <Loading width={1} height={1} />
    ) : (
      <AssignItems handlers={handlers} />
    )}
  </Div>
);

export default decorate(Body);
