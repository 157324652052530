import React from "react";

import { connect } from "react-redux";

import * as R from "ramda";

import {
  Div,
  PopMenu,
  PopMenuCard,
  MediumFilledButton,
  MediumOutlineButton,
  Text2
} from "components/Base";

import { RadioButton } from "material-ui/RadioButton";

import {
  getProfilePricesNone,
  getSelectedProfiles
} from "Orders/TicketsPasses/selectors";

import { actions, getters } from "Orders/TicketsPasses";

const decorate = connect(
  state => ({
    isProfilePricesNone: getProfilePricesNone(state),
    selectedProfiles: getSelectedProfiles(state),
    copyFromProfileId: getters.copyFromProfileId(state)
  }),
  {
    clearProfileAllAssignedItems: actions.clearProfileAllAssignedItems,
    copyProfileSettings: actions.copyItemProfileSettings,
    setCopyFromProfileId: actions.setCopyFromProfileId,
    setProfilesId: actions.setProfilesId
  }
);

const CopyFromPopOver = ({
  selectedProfiles,
  profileId,
  copyFromProfileId,
  setCopyFromProfileId,
  copyProfileSettings,
  closeParent,
  setProfilesId
}) => {
  return (
    <PopMenu
      Label={({ onClick }) => (
        <Div
          width={1}
          p={2}
          fw={3}
          color="black"
          bg={{ default: "white", hover: "neutral0" }}
          onClick={() => {
            setProfilesId({
              copyFromProfileId: "",
              copyToProfileId: profileId
            });
            onClick();
          }}
          style={{ overflow: "visible" }}
        >
          Copy Settings from...
        </Div>
      )}
    >
      {({ closeMenu }) => (
        <PopMenuCard position="bottom" align="right">
          <Div p={3} mb={1} width={250}>
            <Text2 bold ml={2}>
              Profiles
            </Text2>
            <Div>
              {R.map(
                ({ id, name }) => (
                  <Div
                    key={id}
                    width={1}
                    p={2}
                    fw={3}
                    color="neutral7"
                    bg={{
                      default: "white",
                      hover: "neutral0"
                    }}
                    display="row.flex-start.center"
                  >
                    <Div
                      width={30}
                      onClick={() => {
                        setCopyFromProfileId(id);
                      }}
                    >
                      <RadioButton checked={copyFromProfileId === id} />
                    </Div>
                    <Text2 bold color="black">
                      {name}
                    </Text2>
                  </Div>
                ),
                selectedProfiles
              )}
            </Div>
            <Div display="row.space-between.center" mt={2} px={2}>
              <MediumOutlineButton
                mr={2}
                onClick={() => {
                  closeMenu();
                }}
              >
                Cancel
              </MediumOutlineButton>
              <MediumFilledButton
                flex={1}
                bg="primary7"
                onClick={() => {
                  copyProfileSettings();
                  closeMenu();
                  closeParent();
                }}
              >
                Copy Settings
              </MediumFilledButton>
            </Div>
          </Div>
        </PopMenuCard>
      )}
    </PopMenu>
  );
};

export default decorate(CopyFromPopOver);
