import Helpers from "utils/Global/Helpers";

module.exports = {
  get: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      let url = `${window.__LENND_API_BASE_URL__}/form/messages/event/${
        data.eventId
      }`;
      if (data.accountId) {
        url = `${url}?accountId=${data.accountId}`;
      }
      const options = {
        method: "get",
        url,
        credentials,
        data: {},
        success: result => {
          resolve(result);
        },
        error: err => {
          reject(err);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
