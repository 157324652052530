import React from "react";
import { actions, getters } from "../model";

import { connect } from "react-redux";
import * as R from "ramda";
import {
  Span,
  Div,
  Text2,
  MediumFilledButton,
  MediumOutlineButton,
  ErrorIcon,
  BigTextBox,
  Popover
} from "components/Base";
import { WithFormInstanceProvider } from "ui-kit/Form/View";
import { FORM_ID } from "../constants";

import { user as getUser } from "redux/modules/user/selectors";
import {
  getCustomerId,
  getSubscriptionId
} from "redux/modules/organization/selectors";
import { getIsValidBillingEmail } from "../selectors";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

const decorate = R.compose(
  WithFormInstanceProvider(R.always(FORM_ID)),
  connect(
    state => ({
      data: getters.data(state),
      token: getters.token(state),
      user: getUser(state),
      stripeStatus: getters.stripeStatus(state),
      customerId: getCustomerId(state),
      subscriptionId: getSubscriptionId(state),
      isValidBillingEmail: getIsValidBillingEmail(state)
    }),
    {
      goToStripeAuthUrl: actions.goToStripeAuthUrl,
      viewStripeDashboard: actions.viewStripeDashboard,
      disconnectPaymentsAuth: actions.disconnectPaymentsAuth,
      saveBillingDetails: actions.saveBillingDetails
    }
  ),
  CSSModules(css)
);

const SectionTitle = ({ children }) => (
  <Div fw={3} fs={4} color="black" mb={3} bb={1} bc="neutral2" pb={2}>
    {children}
  </Div>
);

const SectionBody = ({ children }) => <Div>{children}</Div>;

const Stripe = ({
  isActivated,
  createdBy,
  createdOn,
  goToStripeAuthUrl,
  viewStripeDashboard,
  disconnectPaymentsAuth
}) => (
  <div className={css.wrapper}>
    <SectionTitle>Send invoices and collect payment</SectionTitle>
    <SectionBody>
      <Div>
        Connect to Stripe in order to accept payment within your events.
      </Div>

      {isActivated ? (
        <Div py={5}>
          Your account was connected by <Span fw={3}>{createdBy}</Span> on{" "}
          <Span fw={3}>{createdOn}</Span>
          <Div display="row.flex-start.center" my={4}>
            <MediumFilledButton
              bg="orange5"
              color="white"
              onClick={viewStripeDashboard}
              mr={3}
            >
              View Stripe Dashboard
            </MediumFilledButton>

            <Popover
              Label={({ onClick }) => (
                <MediumOutlineButton
                  bc="danger5"
                  color="danger5"
                  onClick={onClick}
                >
                  Disconnect Stripe
                </MediumOutlineButton>
              )}
              labelProps={{ buttonTitle: "Click Me" }}
              wrapperProps={{ display: "row.flex-start.flex-start" }}
            >
              {({ closePopover }) => (
                <Div px={5} py={3} width={300}>
                  <BigTextBox color="danger5" LeftIcon={ErrorIcon}>
                    Warning
                  </BigTextBox>
                  <Text2 px={1} pb={4}>
                    Disconnecting stripe will require any pending transactions
                    to be resolved manually and you will no longer be able to
                    accept online payments.
                  </Text2>
                  <Div display="row.flex-start.center">
                    <MediumFilledButton
                      uppercase
                      onClick={R.compose(
                        closePopover,
                        disconnectPaymentsAuth
                      )}
                      bg="danger5"
                      mr={2}
                    >
                      Confirm
                    </MediumFilledButton>
                    <MediumOutlineButton uppercase onClick={closePopover}>
                      Cancel
                    </MediumOutlineButton>
                  </Div>
                </Div>
              )}
            </Popover>
          </Div>
        </Div>
      ) : (
        <div>
          <Div py={5}>To set up your account, click on the button below.</Div>
          <Div>
            <img
              src="//d2dks4tzxs6xee.cloudfront.net/img/stripe-blue-on-light@2x.png"
              alt="Connect with Stripe"
              onClick={goToStripeAuthUrl}
              width={210}
              height={36}
              style={{
                cursor: "pointer"
              }}
            />
          </Div>
        </div>
      )}
    </SectionBody>
  </div>
);

const Body = ({
  goToStripeAuthUrl,
  viewStripeDashboard,
  disconnectPaymentsAuth,
  stripeStatus = {}
}) => {
  return (
    <Div display="column.flex-start.stretch" mb={5} width={1}>
      <div className={css.stripeWrapper}>
        <Stripe
          {...{
            ...stripeStatus,
            goToStripeAuthUrl,
            viewStripeDashboard,
            disconnectPaymentsAuth
          }}
        />
      </div>
    </Div>
  );
};

export default decorate(Body);
