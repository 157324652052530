import { createHandlers } from "redux-mvc";
import * as R from "ramda";

const iniState = {};

const reducers = {
  updateData: R.identity,
  executeAction: R.identity
};

const model = createHandlers({
  iniState,
  reducers,
  namespace: "UIFieldTypes"
});

const { actions, getters } = model;

export { actions, getters };

export default model;
