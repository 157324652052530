import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";
import * as R from "ramda";

const iniState = {
  loading: false,
  isDrawerOpen: false
};

const model = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    openSliderSidebar: R.always({ isDrawerOpen: true }),
    close: R.always({ isDrawerOpen: false })
  }
});

const { actions, getters } = model;

export { actions, getters };

export default model;
