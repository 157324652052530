import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { BUTTONS } from "../constants";

import {
  MediumOutlineButton,
  Div,
  CheckIcon,
  Text2,
  CloseIcon,
  SmallShadedBox
} from "components/Base";
import { os } from "ui-kit/Theme";
import SelectiveApproversLabel from "SelectiveApproversLabel/View";

import { noop, withProps } from "utils/General";

import { actions as TabActions } from "ui-kit/Tabs";
import { actions } from "../model";

const ApproveIcon = withProps({
  color: "green6",
  pill: true,
  bg: "green1",
  OnlyIcon: CheckIcon
})(SmallShadedBox);

const DenyIcon = withProps({
  color: "red6",
  pill: true,
  bg: "red1",
  OnlyIcon: CloseIcon
})(SmallShadedBox);

const buttons = {
  [BUTTONS.EDIT]: ({ handlers, block }) => (
    <MediumOutlineButton
      mr={1}
      onClick={() => handlers.bulkEdit({ lineItemIds: block.lineItemIds })}
    >
      Edit Request
    </MediumOutlineButton>
  ),
  [BUTTONS.VIEW_DETAILS]: ({ onViewDetails = noop, block }) => (
    <MediumOutlineButton mr={1} onClick={() => onViewDetails(block.id)}>
      View Full Details
    </MediumOutlineButton>
  ),
  [BUTTONS.REVIEW]: ({ handlers, block }) => (
    <SelectiveApproversLabel
      {...{
        type: "lineItem",
        recordIds: block.lineItemIds,
        onApprove: handlers.onApprove,
        onDeny: handlers.onDeny
      }}
    />
  ),
  [BUTTONS.APPROVE]: ({ handlers, block }) => (
    <MediumOutlineButton
      mr={1}
      onClick={() => handlers.onApprove(block.pendingLineItemIds)}
      LeftIcon={ApproveIcon}
    >
      Approve Rem.
    </MediumOutlineButton>
  ),
  [BUTTONS.DENY]: ({ handlers, block }) => (
    <MediumOutlineButton
      mr={1}
      onClick={() => handlers.onDeny(block.pendingLineItemIds)}
      LeftIcon={DenyIcon}
    >
      Deny Rem.
    </MediumOutlineButton>
  ),
  [BUTTONS.REVIEW_COMPLETED]: () => (
    <Div display="row.flex-start.center" mr={1} p={2}>
      <CheckIcon color="altA4" />
      <Text2 bold ml={2}>
        Review Completed
      </Text2>
    </Div>
  )
};

const getButtonIds = R.memoizeWith(
  block => `${block.pendingReview} ${R.length(block.variants)}`,
  block => {
    if (R.length(block.variants) === 0) {
      return [];
    }
    return block.pendingReview
      ? [BUTTONS.VIEW_DETAILS, BUTTONS.REVIEW]
      : [BUTTONS.VIEW_DETAILS, BUTTONS.REVIEW_COMPLETED];
  }
);

const decorate = connect(
  null,
  bindInstance({
    onViewDetails: TabActions.setSelectedTab,
    onApprove: actions.approve,
    onDeny: actions.deny
  })
);

const BlockFooter = ({
  handlers,
  block,
  onViewDetails = noop,
  onApprove = noop,
  onDeny = noop,
  ...styleProps
}) => {
  const buttonIds = getButtonIds(block);
  return (
    <Div display="row.flex-start.center" {...os(styleProps)}>
      {R.map(
        buttonId =>
          React.createElement(buttons[buttonId], {
            handlers: { ...handlers, onApprove, onDeny },
            block,
            onViewDetails
          }),
        buttonIds
      )}
    </Div>
  );
};

export default decorate(BlockFooter);
