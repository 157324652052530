import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import { getters, actions } from "../model";
import {
  getCriteriaTabs,
  getAllSelectedItems,
  getSelectedItemsOfTab,
  getFilteredItems,
  getCriteriaItemIds,
  getSelectedCriteriaTab,
  getRecipientsCount,
  getRecipientsWithSMSCount
} from "../selectors";
import { FIELDS_IDS } from "../constants";

import {
  Text0,
  Text1,
  Text2,
  PeopleIcon,
  DownFilledIcon,
  CloseIcon,
  Div
} from "components/Base";
import Checkbox from "material-ui/Checkbox";

import { Toggle } from "ui-kit/Form/View";
import Tabs from "ui-kit/Tabs/View";
import SearchBar from "ui-kit/SearchBar/View";
import CollapsablePanel from "ui-kit/CollapsiblePanel";
import { VARIANTS } from "ui-kit/Theme";

import Container from "./Container";

const decorate = connect(
  (state, props) => ({
    data: getters.data(state, props),
    tabs: getCriteriaTabs(state, props),
    selectedTab: getSelectedCriteriaTab(state, props),
    allSelectedItemsByCriteria: getAllSelectedItems(state, props),
    selectedItemsOfTab: getSelectedItemsOfTab(state, props),
    items: getFilteredItems(state, props),
    itemIds: getCriteriaItemIds(state, props),
    recipientsCount: getRecipientsCount(state, props),
    smsRecipientsCount: getRecipientsWithSMSCount(state, props)
  }),
  {
    selectAllItems: actions.selectAllItems,
    toggleItem: actions.toggleItem,
    clearSelectedItems: actions.clearSelectedItems
  }
);

const CriteriaPicker = ({
  data,
  selectAllItems,
  toggleItem,
  selectedItemsOfTab,
  allSelectedItemsByCriteria,
  selectedTab,
  items,
  itemIds,
  tabs,
  recipientsCount,
  smsRecipientsCount
}) => (
  <Container
    title="Who should receive this message?"
    // subtitle={`${recipientsCount} unique recipients`}
    // moreInfo={`${smsRecipientsCount} with SMS enabled`}
    Icon={PeopleIcon}
  >
    <Tabs tabs={tabs} width={1} bb={1} bc="neutral1" />
    <Div display="column" width={1} p={2}>
      <SearchBar variant={VARIANTS.SURFACE} flex={1} mb={4} width={1} />
      <Div display="row.space-between.center" width={1} mb={4}>
        {/*
        <Div display="row.flex-start.center">
          <Toggle
            size="tiny"
            fieldId={FIELDS_IDS.SEND_TO.PORTAL_LOGIN_USERS}
            bg="green6"
            iniValue={R.prop(FIELDS_IDS.SEND_TO.PORTAL_LOGIN_USERS, data)}
          />
          <Text1 color="neutral9" ml={1}>
            Portal Login Users
          </Text1>
          <Toggle
            size="tiny"
            fieldId={FIELDS_IDS.SEND_TO.PRIMARY_CONTACTS}
            bg="green6"
            iniValue={R.prop(FIELDS_IDS.SEND_TO.PRIMARY_CONTACTS, data)}
            ml={4}
          />
          <Text1 color="neutral9" ml={1}>
            Primary Contacts
          </Text1>
        </Div>
        */}

        <Text1
          disabled={R.isEmpty(items)}
          onClick={() =>
            selectAllItems({
              itemIds,
              selectedTab
            })
          }
          color="purple8"
          underline
        >
          Select all
        </Text1>
      </Div>
      {R.isEmpty(items) ? (
        <Text2 color="neutral9" mb={4} bold>
          There are no items.
        </Text2>
      ) : (
        R.map(
          item => (
            <Div
              display="row.flex-start.center"
              width={1}
              mb={2}
              bb={1}
              bc="neutral0"
              key={item.id}
              onClick={() =>
                toggleItem({
                  itemId: item.id,
                  selectedTab
                })
              }
            >
              <Checkbox
                checked={selectedItemsOfTab.includes(item.id)}
                iconStyle={{ marginRight: 8 }}
                label={
                  <Text1 color="neutral9" ml={1} bold>
                    {item.name}
                  </Text1>
                }
              />
            </Div>
          ),
          items
        )
      )}
    </Div>
    <CollapsablePanel
      heading={
        <Div display="row.flex-start.center" py={4}>
          <Div color="black" fs={4} fw={4}>
            {`${R.length(allSelectedItemsByCriteria)} selected criteria`}
          </Div>
        </Div>
      }
      collapsedDefault
      bg="neutral0"
      px={4}
      right={false}
      arrowIcon={DownFilledIcon}
      arrowColor="neutral7"
    >
      {R.isEmpty(allSelectedItemsByCriteria) ? (
        <Text2 color="neutral9" mb={4} bold>
          There are no selections yet.
        </Text2>
      ) : (
        R.map(
          criteria => (
            <>
              <Text2 color="neutral9" my={2} bold key={criteria.id}>
                {criteria.name}
              </Text2>
              {R.map(
                item => (
                  <Div
                    display="row.space-between.center"
                    width={1}
                    mb={2}
                    bg="neutral1"
                    key={item.id}
                    p={2}
                    bra={1}
                  >
                    <Text1 color="neutral9" bold>
                      {item.name}
                    </Text1>
                    <Div display="row.flex-end.center">
                      <Text0
                        color="neutral7"
                        bg="neutral2"
                        bra={1}
                        px={1}
                        uppercase
                      >
                        {`${R.propOr(
                          "0",
                          "count_of_recipients",
                          item
                        )} recipients`}
                      </Text0>

                      <CloseIcon
                        color="neutral8"
                        ml={2}
                        sizeWFS={3}
                        onClick={() =>
                          toggleItem({
                            itemId: item.id,
                            selectedTab: criteria.id
                          })
                        }
                      />
                    </Div>
                  </Div>
                ),
                criteria.values
              )}
            </>
          ),
          allSelectedItemsByCriteria
        )
      )}
    </CollapsablePanel>
  </Container>
);

export default decorate(CriteriaPicker);
