import moment from "moment";

const datesInRange = ({ start, end }) => {
  const startM = moment(start)
    .utc()
    .startOf("day");
  const endM = moment(end)
    .utc()
    .endOf("day");
  const dates = [];
  const dateM = startM;

  while (dateM.isSameOrBefore(endM, "day")) {
    dates.push(dateM.format("YYYY-MM-DD"));
    dateM.add(1, "day");
  }
  return dates;
};

const datesInRanges = (ranges = []) =>
  ranges.reduce((dates, range) => [...dates, ...datesInRange(range)], []);

export { datesInRange, datesInRanges };
