import PropTypes from "prop-types";
import React, { Component, cloneElement } from "react";
import ReactDOM from "react-dom";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import { get } from "lodash";
import Panel from "components/Global-2016/Panel";
import { DEFAULT_APPROVAL_FIELD_APPROVE_REJECT } from "components/Event/FormsV2/constants";
import css from "./styles.scss";

@CSSModules(css)
class InlineCard extends Component {
  constructor(props) {
    super(props);
    this.state = { collapsed: props.defaultCollapsed, height: 0 };
  }

  componentDidMount() {
    this.setHeight();
  }

  componentDidUpdate() {
    if (this.getHeight() !== this.state.height && this.state.height !== 0)
      this.setHeight();
  }

  @autobind
  setHeight() {
    this.setState({ height: this.getHeight() });
  }

  getHeight() {
    return ReactDOM.findDOMNode(this.edit).getBoundingClientRect().height + 15;
  }

  handleCollapse = () => this.setState({ collapsed: !this.state.collapsed });

  @autobind
  panelStyle({ locked, invalidRows, row }) {
    if (locked) {
      if (invalidRows.includes(row.id) && this.state.collapsed) {
        return css.invalidContainerNoShadow;
      } else if (this.state.collapsed) {
        return css.containerNoShadow;
      }
      return css.containerNoShadowOverflow;
    }
    if (invalidRows.includes(row.id) && this.state.collapsed) {
      return css.invalidContainer;
    } else if (this.state.collapsed) {
      return css.container;
    }
    return css.containerOverflow;
  }

  @autobind
  getCardHeight({ submission, row }) {
    if (
      get(submission, "is_submitted") &&
      ["approved", "rejected"].includes(
        get(row.values[DEFAULT_APPROVAL_FIELD_APPROVE_REJECT.id], "value")
      )
    ) {
      return 111;
    }
    return 78;
  }

  @autobind
  shouldDisableRow(row) {
    return (
      ["rejected"].includes(
        get(row.values, `${DEFAULT_APPROVAL_FIELD_APPROVE_REJECT.id}.value`)
      ) ||
      this.props.isPreviewing ||
      this.props.submission.is_locked
    );
  }

  showInvalidWrapper() {
    // if single card, no need to show invalid wrapper
    return (
      this.props.invalidRows.includes(this.props.row.id) &&
      !(
        !this.props.allowNewRows &&
        this.props.field.settings.style === "card" &&
        this.props.rows.length === 1
      )
    );
  }

  render() {
    const { collapsed, height } = this.state;

    return (
      <div
        styleName={this.showInvalidWrapper() ? "containerWrapperInvalid" : ""}
      >
        <Panel style={this.panelStyle(this.props)}>
          <div
            styleName="wrapper"
            style={
              !collapsed
                ? { height, visibility: "visible" }
                : { overflow: "hidden" }
            }
          >
            <div ref={ref => (this.edit = ref)}>
              {cloneElement(this.props.expandedElement, {
                collapsed,
                onCollapse: this.handleCollapse
              })}
            </div>
          </div>
          <div
            styleName="cardWrapper"
            style={
              collapsed
                ? {
                    height: this.getCardHeight(this.props)
                  }
                : {
                    padding: 0,
                    opacity: 0,
                    visibility: "hidden"
                  }
            }
            onClick={this.handleCollapse}
          >
            <div>
              {cloneElement(this.props.collapsedElement, {
                collapsed,
                onCollapse: this.handleCollapse
              })}
            </div>
          </div>
        </Panel>
      </div>
    );
  }
}

InlineCard.propTypes = {
  row: PropTypes.shape({
    values: PropTypes.object
  }).isRequired,
  invalidRows: PropTypes.array,
  defaultCollapsed: PropTypes.bool,
  submission: PropTypes.shape({
    is_locked: PropTypes.bool
  }),
  isPreviewing: PropTypes.bool,
  allowNewRows: PropTypes.bool,
  collapsedElement: PropTypes.node.isRequired,
  expandedElement: PropTypes.node.isRequired
};

export default InlineCard;
