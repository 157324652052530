import React from "react";
import HeightSizer from "components/Global/Table3/Sizers/Expand";
import TableSizer from "components/Global/ReactTable/TableSizer";
import Container from "components/Global/Module/Container";
import Header from "components/Global/Module/Header";
import Separator from "components/Global/Module/Separator";
import Table from "components/Global/ReactTable/Table/index";
import Actions from "components/Event/Module/Actions/Report";
import { Div, LeftArrowIcon, Text1 } from "components/Base";
import { get } from "lodash";

import Card from "components/Global/CRM/Card";
import IconTitle from "components/Global/CRM/Card/IconTitle";
import CardHeader from "components/Global/CRM/Card/Header";
import Title from "components/Global/CRM/Card/Title";

import PaginationManager from "components/Global/ReactTable/Pagination/Manager";
import PaginationBar from "components/Global/ReactTable/Pagination/Muted";
import ReactTable from "react-table";

const NestedEmptyState = () => (
  <Div px={4} py={3} bc="neutral1" bt={1} fw={3} color="gray7">
    0 records
  </Div>
);

const View = ({
  reportName,
  returnToTitle,
  isExpandable,
  loading,
  noDataText,
  fields,
  rows,
  sorted,
  page,
  pageSize,
  expanded,
  resized,
  filtered,
  exportCSV,
  exportXLSX,
  print,
  showSettingsModal,
  goToReports,
  onSortedChange,
  onPageChange,
  onPageSizeChange,
  onExpandedChange,
  onResizedChange,
  onFilteredChange
}) => (
  <Container>
    <Header>
      <Div display="column.center.flex-start" ml={3}>
        <Div color="white" fw={3}>
          {reportName}
        </Div>
        <Div display="row.flex-start.center" fs={2} onClick={goToReports}>
          <LeftArrowIcon color="white" sizeWFS={2} mr={2} />
          {returnToTitle}
        </Div>
      </Div>
      <Separator />
      <Actions
        {...{
          showSettingsModal,
          exportCSV,
          exportXLSX,
          print
        }}
      />
    </Header>
    <div>
      <HeightSizer
        computeHeight={height => height - 62 /* 62 = height of pagination */}
      >
        <TableSizer>
          <PaginationManager>
            <Table
              className={`ReactTable--Lennd ${
                isExpandable ? "ReactTable--Lennd-Expandable" : ""
              }`}
              resizable
              filterable={false}
              sortable={false}
              loading={loading}
              noDataText={noDataText}
              data={rows}
              columns={fields}
              // Controlled props
              sorted={sorted}
              page={page}
              pageSize={pageSize}
              expanded={expanded}
              resized={resized}
              filtered={filtered}
              // Callbacks
              onSortedChange={onSortedChange}
              onPageChange={onPageChange}
              onPageSizeChange={onPageSizeChange}
              onExpandedChange={onExpandedChange}
              onResizedChange={onResizedChange}
              onFilteredChange={onFilteredChange}
              // Subcomponents
              getTrProps={
                isExpandable
                  ? (state, rowInfo, col, instance) => {
                      const { expanded } = state;
                      const path = get(rowInfo, ["nestingPath", 0]);
                      const isExpanded = expanded[path];
                      const diff = { [path]: isExpanded ? false : true };

                      return {
                        style: {
                          backgroundColor: isExpanded ? "#fafafa" : "inherit"
                        },
                        onClick: e => {
                          instance.setState({
                            expanded: {
                              ...expanded,
                              ...diff
                            }
                          });
                        }
                      };
                    }
                  : undefined
              }
              SubComponent={
                isExpandable
                  ? row => {
                      return (
                        <div
                          style={{
                            padding: 20
                          }}
                        >
                          {row.original.related_modules.map(relatedModule => {
                            const countOfRecords = relatedModule.rows.length;
                            return (
                              <Div key={relatedModule.id}>
                                <Card color={relatedModule.color}>
                                  <CardHeader>
                                    <Title>
                                      <IconTitle
                                        title={
                                          <Div display="row.flex-start.center">
                                            <Div display="column">
                                              <span>{relatedModule.name}</span>
                                              {relatedModule.lookup_field_name ? (
                                                <Text1 color="neutral8">
                                                  Field:{" "}
                                                  {
                                                    relatedModule.lookup_field_name
                                                  }
                                                </Text1>
                                              ) : null}
                                            </Div>
                                          </Div>
                                        }
                                        color={relatedModule.color}
                                      >
                                        <i
                                          className="material-icons"
                                          style={{
                                            color: "#fff",
                                            fontSize: 16
                                          }}
                                        >
                                          {relatedModule.icon}
                                        </i>
                                      </IconTitle>
                                    </Title>
                                  </CardHeader>
                                  <div>
                                    {countOfRecords ? (
                                      <PaginationManager
                                        pageSize={
                                          countOfRecords < 5
                                            ? countOfRecords || 2
                                            : 5
                                        }
                                      >
                                        <ReactTable
                                          resizable
                                          className="ReactTable-record ReactTable--Lennd-Embedded"
                                          noDataText=""
                                          columns={relatedModule.fields.map(
                                            field => ({
                                              id: field.id,
                                              Header: field.name,
                                              name: field.name,
                                              export_name: field.export_name,
                                              accessor: d => d[field.id],
                                              width: field.width || 230
                                            })
                                          )}
                                          data={relatedModule.rows}
                                          showPageSizeOptions={false}
                                          rowsText={
                                            countOfRecords === 1
                                              ? "record"
                                              : "records"
                                          }
                                          PaginationComponent={PaginationBar}
                                        />
                                      </PaginationManager>
                                    ) : (
                                      <NestedEmptyState />
                                    )}
                                  </div>
                                </Card>
                              </Div>
                            );
                          })}
                        </div>
                      );
                    }
                  : undefined
              }
            />
          </PaginationManager>
        </TableSizer>
      </HeightSizer>
    </div>
  </Container>
);

export default View;
