import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SwitchAccounts from "./SwitchAccounts";

import * as PortalSelectors from "redux/modules/portal/selectors";
import * as PortalUserActions from "redux/modules/portal/user/actions";
import * as PortalUserSelectors from "redux/modules/portal/user/selectors";

function mapStateToProps(state) {
  return {
    portal: PortalSelectors.portal(state),
    portalUser: PortalUserSelectors.portalUser(state),
    accounts: PortalUserSelectors.accounts(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...PortalUserActions
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitchAccounts);
