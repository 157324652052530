import React from "react";
import { AddIcon, Text4, SuperFilledButton, Div } from "components/Base";
import { connect } from "react-redux";
import { getters, actions } from "../model";

const decorate = connect(
  state => ({
    adding: getters.adding(state)
  }),
  {
    addPermissionSet: actions.addPermissionSet
  }
);

const Empty = ({ addPermissionSet, adding }) => (
  <Div flex={1} display="column.center.center" py={7}>
    <Text4 bold>No portal has been created.</Text4>
    <SuperFilledButton
      mt={4}
      bg="primary5"
      onClick={adding ? undefined : addPermissionSet}
      LeftIcon={adding ? undefined : AddIcon}
    >
      {adding ? "Creating portal..." : "Create Portal"}
    </SuperFilledButton>
  </Div>
);

export default decorate(Empty);
