import Main from "Sponsors/Main/View";
import Manage from "Sponsors/Manage/View";
import Container from "Sponsors/Container/View";
import SelfSetup from "Sponsors/SelfSetup/View";

import { hot } from "App/Config/hot";

const Booths = {
  Main,
  Manage,
  Container,
  SelfSetup
};

export default hot(Booths);
