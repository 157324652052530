export const CARD_ACTIONS = {
  VIEW_ALERT: "cardViewAlert",
  VIEW_PEOPLE_REPORT: "cardViewPeopleReport",
  MANAGE_PEOPLE: "cardManagePeople",
  SUBMIT_DOCUMENT: "cardSubmitDocument",
  SUBMIT_FORM: "cardSubmitForm",
  VIEW_FILE: "cardViewFile",
  DOWNLOAD_FILE: "cardDownloadFile",
  VIEW_PAGE: "cardViewPage",
  PRINT_PAGE: "cardPrintPage",
  VISIT_MEDIA_HUB: "cardVisitMediaHub"
};

export const SECTION_ACTIONS = {
  VIEW_ALL_ALERTS: "sectionViewAllAlerts",
  VIEW_ALL_FILES: "sectionViewAllFiles",
  VIEW_ALL_PAGES: "sectionViewAllPages"
};
