import { createSelector } from "reselect";
import * as R from "ramda";

import { getters } from "./index";

export const getIsValid = createSelector(
  getters.title,
  getters.selectedModuleId,
  getters.templateUrl,
  (title, selectedModuleId, templateUrl) => {
    return Boolean(
      title &&
        title.length &&
        selectedModuleId &&
        selectedModuleId.length &&
        templateUrl &&
        templateUrl.length
    );
  }
);

export const uploadBtnText = createSelector(
  getters.template,
  template => (R.isNil(template) ? "Save" : "Update")
);
