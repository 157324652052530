/* eslint-disable no-underscore-dangle */
import React from "react";
import {
  Div,
  Text3,
  Text6,
  BigFilledButton,
  BigTextButton,
  LeftArrowIcon
} from "components/Base";

const BaseErrorHandler = ({ error, children }) => {
  if (window.__ENV__.includes("dev")) {
    const Redbox = require("redbox-react").default;
    return <Redbox error={error} editorScheme="vscode" />;
  }
  return children;
};

const base = key => new RegExp(`\/${key}\/(.*?)\/`, "g");

// eslint-disable-next-line no-unused-vars
export const GlobalError = ({ error, componentStack, ...props }) => {
  let homeUrl;
  let homeAction;
  const path = window.location.pathname;
  const origin = window.location.origin;

  if (path.search(base("event-light")) !== -1) {
    const exec = base("event-light").exec(path);
    homeUrl = `${origin}/event-light/${exec[1]}/dashboard`;
    homeAction = "Event Home";
  } else if (path.search(base("org-light")) !== -1) {
    const exec = base("org-light").exec(path);
    homeUrl = `${origin}/org-light/${exec[1]}/dashboard`;
    homeAction = "Organization Home";
  } else if (path.search(base("portals")) !== -1) {
    const pathUnits = path.split("/");
    homeUrl = `${origin}/${pathUnits[1]}/${pathUnits[2]}/${pathUnits[3]}`;
    homeAction = "Portal Home";
  } else {
    homeUrl = origin;
    homeAction = "Home";
  }

  return (
    <BaseErrorHandler error={error}>
      <Div width={1} px={20} py={30} display="column.center.center">
        <Text6 bold py={6}>
          Oh no!
        </Text6>
        <Text3 bold>Something went wrong,</Text3>
        <Text3 bold pb={8}>
          please try again.
        </Text3>
        <Div width={200} display="row.center.center">
          <BigFilledButton
            pill
            mb={3}
            onClick={() => window.location.reload()}
            bg="info5"
            display="row.center.center"
          >
            Try again
          </BigFilledButton>
        </Div>
        <a href={homeUrl} title={`Go to ${homeAction}`}>
          <BigTextButton
            LeftIcon={LeftArrowIcon}
            color="info5"
            onClick={() => {}}
          >
            {homeAction}
          </BigTextButton>
        </a>
      </Div>
    </BaseErrorHandler>
  );
};
