import React from "react";

import { connect } from "react-redux";

import { actions } from "../index";

import { Div, CloseIcon } from "components/Base";

import SortPicker from "components/Global/Module/Sidebar/Popovers/Sort";
import SortItem from "./SortItem";

const decorate = connect(
  null,
  {
    onAddSort: actions.addSort,
    onRemoveSort: actions.removeSort
  }
);

const SortByPopover = ({
  sectionId,
  onAddSort,
  onRemoveSort,
  sortBy = [],
  allFields = []
}) => {
  return (
    <Div display="column" py={sortBy.length ? 3 : 0}>
      <Div display="column">
        {sortBy.map((sortCase, idx) => {
          const field = allFields.find(f => f.id === sortCase.fieldId);
          return (
            <SortItem
              name={field.name}
              direction={sortCase.direction}
              removeIcon={
                <CloseIcon
                  onClick={() => onRemoveSort({ idx, sectionId })}
                  color="gray5"
                />
              }
            />
          );
        })}
      </Div>
      <Div display="row.flex-end.center">
        <SortPicker
          onChange={item => onAddSort({ item, sectionId })}
          fields={allFields}
        >
          <Div
            style={{
              cursor: "pointer"
            }}
            fs={sectionId ? 2 : 3}
            fw={3}
            display="row.flex-start.center"
            color="primary7"
            mt={sortBy.length ? 1 : 0}
          >
            Add sort by
          </Div>
        </SortPicker>
      </Div>
    </Div>
  );
};

export default decorate(SortByPopover);
