import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ListView from "./ListView";
import { withRouter } from "react-router";

import { addReference } from "redux/modules/entityReferences/actions";
import { bulkSelectRows } from "redux/modules/modules/records/settings/actions";
import * as modalActions from "redux/modules/modal/actions";
import { updateView } from "redux/modules/modules/views/actions";
import {
  createReview,
  createReviews,
  removeReview,
  removeReviews
} from "redux/modules/approvalWorkflows/reviews/actions";

import { orgDetails } from "redux/modules/organization/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import { selectedRows } from "redux/modules/modules/records/settings/selectors";
import { user } from "redux/modules/user/selectors";

function mapStateToProps(state, props) {
  return {
    user: user(state),
    orgDetails: orgDetails(state),
    eventDetails: eventDetails(state),
    selectedRows: selectedRows(state, props.moduleId)
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      ...modalActions,
      createReview,
      createReviews,
      removeReview,
      removeReviews,
      updateView,
      addReference,
      setSelectedRows: rows => bulkSelectRows(props.moduleId, rows)
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ListView)
);
