import React, { Component } from "react";
import getValue from "utils/value-types/get-value/event-list";
import { Div, Text2 } from "components/Base";
import { isEqual } from "lodash";
import { liftToArr } from "utils/General";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { connect } from "react-redux";

import OrderModal from "Orders/OrderModal/View";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

const decorate = connect(
  null,
  {
    showModal,
    hideModal
  }
);

class OrderNumbersFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  showOrderModal = orderId =>
    this.props.showModal({
      content: (
        <OrderModal
          onDone={this.props.dependentValues.helpers.refreshRecords}
          orderId={orderId}
        />
      ),
      wrapper: ModalWrapper
    });

  render() {
    const value = getValue(this.props.value);
    return (
      <Div display="row.flex-end.center" width={1}>
        {liftToArr(value).map(value => (
          <Text2
            key={value.id}
            mr={2}
            onClick={
              this.props.dependentValues.meta.enableViewOrder
                ? () => this.showOrderModal(value.id)
                : null
            }
            bb={this.props.dependentValues.meta.enableViewOrder ? 1 : 0}
            bc="neutral4"
          >
            #{value.number}
          </Text2>
        ))}
      </Div>
    );
  }
}

export default decorate(OrderNumbersFormatter);
