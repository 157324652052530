import * as R from "ramda";

import { createModule, createHandlers } from "redux-mvc";

import dataGrid from "ui-kit/Datagrid";
import tabs from "ui-kit/Tabs";

export const handlers = createHandlers({
  iniState: {
    tableIds: []
  },
  reducers: {
    addTableId: ({ tableIds }, { payload: tableId }) => ({
      tableIds: R.uniq(R.append(tableId, tableIds))
    })
  },
  namespace: "Subform"
});

const subform = createModule(handlers);

subform.plugModule(dataGrid);
subform.plugModule(tabs);

const { actions, getters } = subform;

export { actions, getters };

export default subform;
