import React from "react";

import MiniModalWrapper from "Orders/Common/View/MiniModalWrapper";

import { SelectAndCreateVariants } from "ui-kit/MiniItemsSelector/View";

import { noop } from "utils/General";

const SelectItemsModal = ({
  itemType = [],
  selectedItemIds = [],
  onClose = noop,
  onDone = noop
}) => {
  return (
    <MiniModalWrapper
      showModal={true}
      title="Choose items"
      hideModal={() => onClose()}
      width={865}
      style={{
        height: "95%"
      }}
    >
      {React.createElement(SelectAndCreateVariants, {
        instanceId: "selectPermissionItems",
        fields: itemType,
        iniSelected: selectedItemIds,
        iniClearSearch: true,
        onCancel: onClose,
        onDone: onDone
      })}
    </MiniModalWrapper>
  );
};

export default SelectItemsModal;
