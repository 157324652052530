export const IS_LENND_ADMIN = { NAME: "isLenndAdmin" };
export const IS_CUSTOMER_SUCCESS = { NAME: "isCustomerSuccess" };
export const CAN_VIEW_CREDENTIALS_MANAGEMENT = {
  NAME: "credentialsManagement"
};
export const CAN_VIEW_CATERING_MANAGEMENT = { NAME: "cateringManagement" };
export const CAN_CREATE_INTAKE_FORM = { NAME: "createIntakeForm" };
export const CAN_VIEW_PORTAL_SCHEDULE = { NAME: "viewPortalSchedules" };
export const CAN_VIEW_PAYMENTS = { NAME: "viewPayments" };
export const CAN_VIEW_PRICES = { NAME: "viewPrices" };
export const CAN_USE_FRONTGATE_INTEGRATION = { NAME: "viewFgtIntegration" };
export const CAN_SYNC_BULK_ORDERS = { NAME: "syncBulkOrders" };
export const CAN_VIEW_EVENT_REPORTS = { NAME: "viewEventReports" };
export const CAN_VIEW_PASSES = { NAME: "viewPasses" };
export const SHOULD_HIDE_ALLOCATIONS = { NAME: "hideAllocations" };
export const CAN_VIEW_INVENTORY = { NAME: "viewInventory" };
export const CAN_CREATE_CUSTOM_MODULES = { NAME: "createCustomModules" };
export const CAN_VIEW_TICKET_REQUESTS = { NAME: "viewTicketRequests" };
export const CAN_VIEW_DOCUMENT_GENERATION = {
  NAME: "canViewDocumentGeneration"
};
export const CAN_VIEW_QUICK_ACTIONS = {
  NAME: "viewQuickActions"
};
export const CAN_VIEW_PRINTING = {
  NAME: "viewPrinting"
};
export const CAN_EDIT_FIELD_VALUES = {
  NAME: "editFieldValues"
};
export const CAN_VIEW_APPROVAL_WORKFLOWS = {
  NAME: "viewApprovalWorkflows"
};
export const CAN_VIEW_NEW_PORTAL = {
  NAME: "viewNewPortal"
};
export const CAN_VIEW_MEDIA_HUB = {
  NAME: "canViewMediaHub"
};
export const CAN_VIEW_NEW_FILES = {
  NAME: "viewNewFiles"
};
export const CAN_VIEW_ORG_SCHEDULES = {
  NAME: "viewOrgSchedules"
};
export const CAN_VIEW_ALERTS = {
  NAME: "canViewAlerts"
};
export const CAN_VIEW_TICKETING_INTEGRATIONS = {
  NAME: "canViewTicketingIntegrations"
};
export const CAN_ADD_ITEM_BLOCKS = {
  NAME: "canAddItemBlocks"
};
export const CAN_ADD_PEOPLE_BLOCKS = {
  NAME: "canAddPeopleBlocks"
};
export const CAN_ADD_SUBFORM_BLOCKS = {
  NAME: "canAddSubformBlocks"
};
export const CAN_VIEW_HUBS = {
  NAME: "canViewHubs"
};
export const SHOW_ADVANCED_CLONING_OPTIONS = {
  NAME: "showAdvancedCloningOptions"
};
export const CAN_ADD_CUSTOM_FIELDS_TO_FORM = {
  NAME: "addCustomFieldsToForm"
};
export const SIMPLE_FORMS = {
  NAME: "simpleForms"
};
export const CAN_USE_CREDENTIAL_DESIGNS = {
  NAME: "useCredentialDesigns"
};
export const CAN_VIEW_PEOPLE_TAB_ON_PORTALS = {
  NAME: "canViewPeopleTabOnPortals"
};
export const CAN_VIEW_RECORDS_DEDUPLICATION = {
  NAME: "recordsDeduplication"
};
export const CAN_CLONE = {
  NAME: "canClone"
};
export const CAN_VIEW_LENND_FEATURES = {
  NAME: "canViewLenndFeatures"
};

/*
@NOTE: Example with multi variant

export const EXAMPLE_WITH_MULTI_VARIANT = {
  NAME: 'testFlag',
  VARIATES: {
    A: 'testFlagA',
    B: 'testFlagB'
  }
};
*/
