import React, { Component } from "react";
import buildLineItems from "Orders/utils/build-line-items";
import { findIndex } from "lodash";

function itemsDecorator(Comp) {
  // TODO: Something, somewhere in here is mutating the state object.
  return class ItemsActionController extends Component {
    state = { lineItems: [], selectedVariants: {} };
    updateLineItem = (lineItemId, values) =>
      this.setState(state => {
        const index = findIndex(this.state.lineItems, { id: lineItemId });
        state.lineItems[index] = {
          ...state.lineItems[index],
          ...values
        };
        return state;
      });

    removeLineItem = lineItemId =>
      this.setState(state => {
        const index = findIndex(this.state.lineItems, { id: lineItemId });
        if (state.lineItems[index].type === "item") {
          state.selectedVariants[state.lineItems[index].variantId] =
            state.selectedVariants[state.lineItems[index].variantId] - 1;
          if (state.selectedVariants[state.lineItems[index].variantId] <= 0) {
            delete state.selectedVariants[state.lineItems[index].variantId];
          }
        }
        state.lineItems = state.lineItems.filter(i => i.id !== lineItemId);
        return state;
      });

    removeLineItemByVariantId = variantId => e => {
      this.setState(state => {
        state.selectedVariants = Object.keys(state.selectedVariants)
          .filter(id => id !== variantId)
          .reduce((map, id) => {
            map[id] = state.selectedVariants[id];
            return map;
          }, {});
        state.lineItems = state.lineItems.filter(item => {
          if (item.type === "item" && item.variantId === variantId) {
            return false;
          }
          return true;
        });
      });
    };

    updateVariantQuantity = (id, quantity) =>
      this.setState(state => {
        state.selectedVariants = {
          ...state.selectedVariants,
          [id]: quantity <= 0 ? 0 : quantity
        };
        state.lineItems = buildLineItems(state.selectedVariants);
        return state;
      });

    reset = () => {
      this.setState({ lineItems: [], selectedVariants: {} });
    };

    render() {
      return (
        <Comp
          {...{
            updateVariantQuantity: this.updateVariantQuantity,
            removeLineItemByVariantId: this.removeLineItemByVariantId,
            removeLineItem: this.removeLineItem,
            updateLineItem: this.updateLineItem,
            clearAll: this.reset,
            ...this.props,
            ...this.state
          }}
        />
      );
    }
  };
}

export default itemsDecorator;
