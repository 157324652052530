import React from "react";
import SidebarWrapper from "Files/Manage/View/Sidebar/SidebarWrapper";
import Sidebar from "Files/Manage/View/Sidebar";
import Body from "Files/Manage/View/Body";
import FileDetailsSidebar from "Files/FileDetailsSidebar/View";

const Layout = ({ handlers }) => (
  <SidebarWrapper sidebar={<Sidebar handlers={handlers} />}>
    <Body handlers={handlers} />
    <FileDetailsSidebar />
  </SidebarWrapper>
);

export default Layout;
