import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const CancelSaveButtonGroup = ({ handleCancel }) => (
  <div styleName="button-group">
    <button styleName="cancel" type="button" onClick={handleCancel}>
      cancel
    </button>
    <button styleName="save" type="submit">
      <i className="material-icons" styleName="icon">
        keyboard_return
      </i>Save changes
    </button>
  </div>
);

CancelSaveButtonGroup.propTypes = {
  handleCancel: PropTypes.func
};

export default CSSModules(CancelSaveButtonGroup, css);
