import {
  put,
  call,
  takeEvery,
  all,
  fork,
  select,
  delay
} from "redux-saga/effects";
import { actions } from "./model";
import { registerError } from "redux/modules/errors/actions";

import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { showSnackbar } from "redux/modules/snackbar/actions";
import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  return {
    credentials,
    eventId
  };
};

const refreshZoneAssignments = function*() {
  try {
    const { credentials, eventId } = yield call(getParams);
    yield put(actions.setRefreshingZoneAssignments(true));

    const initialResult = yield call(Api.refreshZoneAssignments, {
      credentials,
      eventId
    });

    yield delay(200);

    let progressResult = yield call(Api.getStatus, credentials, {
      jobId: initialResult.payload.jobId
    });

    while (["pending", "processing"].includes(progressResult.payload.status)) {
      yield delay(1000);
      progressResult = yield call(Api.getStatus, credentials, {
        jobId: initialResult.payload.jobId
      });
    }

    if (progressResult.payload.status === "error") {
      yield all([
        put(
          registerError([
            {
              system: "An error occurred refreshing zone assignments",
              user: "An error occurred refreshing zone assignments"
            }
          ])
        )
      ]);
      return false;
    }

    yield put(
      showSnackbar({
        message: "Success! Zones have been refreshed",
        action: "OK"
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred refreshing zone assignments"
        }
      ])
    );
  } finally {
    yield put(actions.setRefreshingZoneAssignments(false));
  }
};

const watchRefreshZoneAssignments = function*() {
  yield takeEvery(actions.refreshZoneAssignments.type, refreshZoneAssignments);
};

const rootSaga = function*() {
  yield all([fork(watchRefreshZoneAssignments)]);
};

export default rootSaga;
