export const NAMESPACE = "CredentialsReport";

export const INSTANCE_SUMMARY_TABLE = "SummaryTable";
export const INSTANCE_ALL_ITEMS_TABLE = "AllItemsTable";

export const SUMMARY_FIELDS_WIDTHS = {
  name: 250,
  requested: 140,
  pending: 140,
  rejected: 140,
  approved: 140
};

export const ALL_FIELDS_WIDTHS = {
  contactName: 250,
  type: 200,
  quantity: 140,
  details: 200,
  status: 140
};
