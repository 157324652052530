import React from "react";
import { connect } from "react-redux";
import { actions } from "Forms/WizardModal";
import { APPLICATIONS_PAGES } from "Forms/WizardModal/constants";
import SelectRecordTypes from "Forms/WizardModal/View/Common/SelectRecordTypes";

const decorate = connect(
  null,
  {
    back: () =>
      actions.setSelectedApplications(APPLICATIONS_PAGES.SELECT_METHOD)
  }
);

const SelectAcc = ({ back, handlers }) => (
  <SelectRecordTypes back={back} handlers={handlers} />
);

export default decorate(SelectAcc);
