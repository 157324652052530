import React from "react";
import * as R from "ramda";
import { set, unset } from "lodash";
import FormBaseInput from "../FormBaseInput";
import Layout from "./Layout";
import autobind from "autobind-decorator";
import { DEFAULT_APPROVAL_FIELD_APPROVE_REJECT } from "components/Event/FormsV2/constants";
import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import ImportModal from "Modules/ImportModal/View";
import isRowNotEmpty from "utils/EventForms/FormGrid/isRowNotEmpty";
import isEmpty from "utils/value-types/is-empty";
import InvalidRowCount from "./InvalidRowCount";
import RequiredNotice from "./RequiredNotice";
import isValidEmail from "utils/value-types/validations/email/isEmail";

const mapWithIndex = R.addIndex(R.map);
const NUMBER_OF_CARDS_TO_SHOW = 10;

class FormPeople extends FormBaseInput {
  constructor(props) {
    super(props);

    this.validatorsMap = {};

    this.state = {
      activeView: "card",
      cardPaginationStart: 0,
      cardHeight: {},
      collapsedCards: {},
      errors: [],
      invalidRows: []
    };
  }

  cardRefs = {};

  @autobind
  registerValidator(isValid, row, field) {
    if (row && field) {
      set(this.validatorsMap, [row, field], isValid);
    }
  }

  @autobind
  unregisterValidator(isValid, row, field) {
    if (row && field) {
      unset(this.validatorsMap, [row, field]);
    }
  }

  // Validates a specific cell based on row/field.
  // Happens on changes to a cell.
  async validateCell(rowId, fieldId) {
    const rowHash = this.props.rows.reduce((hash, r) => {
      hash[r.id] = r.values;
      return hash;
    }, {});
    const validator = R.path([rowId, fieldId])(this.validatorsMap);
    const fields = this.props.fields;
    const isRowEmpty = !isRowNotEmpty(rowHash[rowId], fields);
    if (isRowEmpty) {
      const curRow = R.prop(rowId)(this.validatorsMap);
      if (curRow) {
        // Execute all validators for the current row
        Object.keys(curRow).map(k => curRow[k](rowHash));
      }
    } else if (validator) {
      validator(rowHash);
    }

    this.validateFormData();
  }

  // Top level validation on the table. Manages whether the table
  // is valid or not and the tabel level error messages.
  async validateFormData() {
    if (this.props.isEditing || this.props.isApproving) return true;

    const errors = [];
    const fields = this.props.fields;
    const requiredFields = fields.filter(f => f.is_required);
    const requireAtleastOneRow = this.props.field.people_block.block
      .is_required;

    /*
    // @NOTE: Commenting out because we need to validate empty contacts user is trying to add
    */

    const invalidRows = await Promise.all(
      this.props.rows.map(async r => {
        const rowsToCheck = await Promise.all(
          requiredFields.map(async c => {
            let isInvalid = false;
            if (c.type === "item-block") {
              const { value } = this.props.getValue(r, c.id, c.type);

              // isInvalid = R.length(value) < 1;
              isInvalid = isEmpty(value, c.type);
            } else {
              isInvalid = isEmpty(r.values[c.id], c.type);

              if (c.type === "email") {
                const emailValid = await isValidEmail(r.values[c.id]);

                if (!emailValid) {
                  isInvalid = true;
                }
              }
            }

            return isInvalid;
          })
        );

        const isRowInvalid = rowsToCheck.some(v => v);

        if (isRowInvalid) {
          return r.id;
        }

        return false;
      })
    );

    const filteredInvalidRows = invalidRows.filter(id => id);

    if (filteredInvalidRows.length) {
      errors.push(<InvalidRowCount count={filteredInvalidRows.length} />);
    }
    if (requireAtleastOneRow && !this.props.rows.length) {
      errors.push(<RequiredNotice />);
    }

    this.setState({
      errors,
      invalidRows: filteredInvalidRows,
      isValid: !errors.length
    });

    return !errors.length;
  }

  // Triggered on submit. Validates the table as well as validators (cells) in the table.
  async isValid() {
    const rowHash = this.props.rows.reduce((hash, r) => {
      hash[r.id] = r;
      return hash;
    }, {});

    // Validate data
    const isGridValid = this.validateFormData();

    // Trigger validation on all cells
    Object.keys(this.validatorsMap)
      .reduce(
        (validators, row) =>
          validators.concat(
            Object.keys(this.validatorsMap[row]).map(
              field => this.validatorsMap[row][field]
            )
          ),
        []
      )
      .map(isValid => isValid(rowHash));

    return isGridValid;
  }

  setActiveView = activeView => {
    if (this.state.activeView !== activeView) {
      this.setState({ activeView });
      this.validateFormData();
    }
  };

  // card actions
  goToPreviousCardPaginationSet = () => {
    this.setState({
      cardPaginationStart:
        this.state.cardPaginationStart - NUMBER_OF_CARDS_TO_SHOW
    });
  };

  goToNextCardPaginationSet = () => {
    this.setState({
      cardPaginationStart:
        this.state.cardPaginationStart + NUMBER_OF_CARDS_TO_SHOW
    });
  };

  onCardCollapse = rowId =>
    this.setState(state => {
      if (state.collapsedCards[rowId]) {
        delete state.collapsedCards[rowId];
      } else {
        state.collapsedCards[rowId] = true;
      }
      return true;
    });

  shouldDisableCard = ({ row, isPreviewing, isLocked }) => {
    return (
      ["rejected"].includes(
        R.path(
          ["values", DEFAULT_APPROVAL_FIELD_APPROVE_REJECT.id, "value"],
          row
        )
      ) ||
      isPreviewing ||
      isLocked
    );
  };

  canOnlyViewCards = () => {
    return this.props.settingsView === "card";
  };

  shouldShowInvalidCardWrapper = rowId => {
    // if single card, no need to show invalid wrapper
    return this.state.invalidRows.includes(rowId);
  };

  onAddRow = existingRecordId => this.props.addRow(existingRecordId);

  onRemoveRow = async recordId => {
    await this.props.removeRow(recordId);

    this.validateFormData();
  };

  getRowMetaData = (row, field) => {
    return {
      ...row,
      ...row.values,
      meta: {
        eventId: this.props.eventId,
        formId: this.props.formId,
        gridId: this.props.field.id,
        rowId: row.id,
        columnId: field.id,
        columns: this.props.fields,
        columnSettings: field.settings
          ? {
              ...field.settings,
              suppressLinkToRecord: true
            }
          : {
              suppressLinkToRecord: true
            },
        column: field,
        eventDetails: this.props.eventDetails
      },
      helpers: {
        registerValidator: this.registerValidator,
        unregisterValidator: this.unregisterValidator
      }
    };
  };

  showImportModal = () => {
    this.props.showModal({
      content: (
        <ImportModal
          blockId={this.props.peopleBlockId}
          submissionId={this.props.submissionId}
          maxAmount={this.props.maxAmountOfRows}
          onDone={() => {
            this.props.getSubmission(this.props.submissionId);
          }}
          eventId={this.props.eventDetails.id}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  saveValue = async (contactId, fieldId, value) => {
    if (fieldId === "role") {
      await this.props.saveRole(contactId, fieldId, value);
    } else {
      await this.props.saveValue(contactId, fieldId, value);
    }

    this.validateCell(contactId, fieldId);
  };

  updateRole = async (contactId, fieldId, value) => {
    await this.props.updatePersonRole({
      eventId: this.props.eventDetails.id,
      submissionId: this.props.submissionId,
      blockId: this.props.peopleBlockId,
      contactId,
      fieldId,
      value
    });

    this.validateCell(contactId, fieldId);
  };

  render() {
    const {
      getValue,
      saveItemBlockValue,
      //
      fields,
      rows,
      eventDetails,
      rowLabels,
      //
      maxAmountOfRows,
      allowNewRows,
      shouldDisableFields,
      isEditing,
      isPreviewing,
      isResponseLocked,
      isApproving,
      isFillingFormOut,
      isLenndAdmin
    } = this.props;
    const { activeView, cardPaginationStart, collapsedCards } = this.state;

    const views = [
      {
        id: "card",
        name: "Single Entry",
        icon: "view_agenda",
        active: activeView === "card",
        onClick: () => this.setActiveView("card")
      },
      {
        id: "table",
        name: "Multiple Entry",
        icon: "view_list",
        active: activeView === "table",
        onClick: () => this.setActiveView("table")
      }
    ];

    const fieldsWithHandlers = R.map(field => {
      return {
        ...field,
        value: null,
        isRequired: field.is_required,
        isEditing: this.props.isEditing,
        description: R.path(["settings", "description"])(field),
        limit: R.path(["settings", "limit"])(field),
        order: field.order,
        disabled: shouldDisableFields,
        editorProps: resolveEditorProps(field, eventDetails),
        validateFormData: () => this.validateFormData(),
        onValueChange: (rowId, value) => {
          if (field.type === "item-block") {
            const data = saveItemBlockValue(
              rowId,
              field.id,
              field.settings,
              value
            );
            return data;
          }

          return this.saveValue(rowId, field.id, value);
        }
      };
    })(fields);

    const rowsWithHandlers = mapWithIndex((row, index) => ({
      ...row,
      rowNumber: index + 1,
      isInvalidRow: this.shouldShowInvalidCardWrapper(row.id),
      showInvalidIcon:
        this.shouldShowInvalidCardWrapper(row.id) &&
        !this.props.settingsAllowNewRows,
      isCollapsed: Boolean(collapsedCards[row.id]),
      toggleCollapse: () => this.onCardCollapse(row.id),
      removeRow: () => this.onRemoveRow(row.id),
      getValue: (fieldId, fieldType) => getValue(row, fieldId, fieldType),
      getRowMetaData: field => this.getRowMetaData(row, field)
    }))(rows);

    const rowsToShow =
      activeView === "card"
        ? rowsWithHandlers.slice(
            cardPaginationStart,
            cardPaginationStart + NUMBER_OF_CARDS_TO_SHOW
          )
        : rowsWithHandlers;

    return (
      <Layout
        {...{
          ...this.props,
          // views
          activeView,
          views,
          // data
          rows: rowsToShow,
          fields: fieldsWithHandlers,
          maxAmountOfRows,
          allowNewRows,
          rowLabels,
          // pagination
          countOfRows: rows.length,
          cardPaginationStart,
          numberOfCardsToShow: NUMBER_OF_CARDS_TO_SHOW,
          goToPreviousCardPaginationSet: this.goToPreviousCardPaginationSet,
          goToNextCardPaginationSet: this.goToNextCardPaginationSet,
          // settings
          isLenndAdmin,
          isEditing,
          isPreviewing,
          isResponseLocked,
          isApproving,
          isFillingFormOut,
          mode: this.props.mode,
          // actions
          showImportModal:
            isLenndAdmin && allowNewRows ? this.showImportModal : undefined,
          // showImportModal: allowNewRows ? this.showImportModal : undefined,
          addRow: () => this.onAddRow(),
          // errors
          isValid: this.state.isValid,
          errors: this.state.errors,
          settingsView: this.props.settingsView,
          settingsAllowNewRows: this.props.settingsAllowNewRows
        }}
      />
    );
  }
}

export default FormPeople;
