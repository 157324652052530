import React from "react";
import { Page, Title } from "EventLight/Common/FieldLayout";

import Fields from "EventLight/Common/Fields/View";

const Layout = () => (
  <>
    <Page>
      <Title
        icon="group"
        title="Fields"
        description="Manage your group and people fields"
      />
      <Fields />
    </Page>
  </>
);

export default Layout;
