import { get } from "lodash";
import * as R from "ramda";

export const isFetching = (state, moduleId) =>
  get(state, `modules.module.fetching[${moduleId}]`, false);
export const modules = state => get(state, "modules.module.module", {});
export const modulesArray = state => {
  const list = modules(state);
  return Object.keys(list).map(k => list[k]);
};
export const moduleDetails = (state, moduleId) =>
  get(state, `modules.module.module[${moduleId}]`, {});
export const preferences = (state, moduleId) =>
  get(state, `modules.module.module[${moduleId}].preferences`, {});
export const forms = (state, moduleId) =>
  get(state, `modules.module.module[${moduleId}].forms`, []);
export const fields = (state, moduleId) =>
  get(state, `modules.module.module[${moduleId}].fields`, []);
export const fieldGroups = (state, moduleId) =>
  get(state, `modules.module.module[${moduleId}].field_groups`, []);
export const fieldGroupFields = (state, moduleId) =>
  R.reduce(
    (fields, group) => {
      return [...fields, ...group.fields.map(f => f.field)];
    },
    [],
    fieldGroups(state, moduleId)
  );
export const starredFields = (state, moduleId) =>
  get(state, `modules.module.module[${moduleId}].starred_fields`, []);
export const relatedModules = (state, moduleId) =>
  get(state, `modules.module.module[${moduleId}].related_modules`, []);
export const lookupModules = (state, moduleId) =>
  get(state, `modules.module.module[${moduleId}].lookup_modules`, []);
