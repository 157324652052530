import React from "react";

import { connect } from "react-redux";

import { actions } from "Orders/TicketsPasses";

import {
  getQuickAssignPreviousText,
  isNextQuickAssignBtnDisabled,
  getQuickAssignNextText
} from "Orders/TicketsPasses/selectors";

import { Div, BigOutlineButton, BigFilledButton } from "components/Base";

const decorate = connect(
  state => ({
    previousText: getQuickAssignPreviousText(state),
    isNextBtnDisabled: isNextQuickAssignBtnDisabled(state),
    nextText: getQuickAssignNextText(state)
  }),
  {
    previousAction: () => actions.quickAssignPreviousAction(),
    nextAction: () => actions.updateNextPriceAction()
  }
);

const QuickAssignPricesFooter = ({
  previousAction,
  previousText,
  isNextBtnDisabled,
  nextText,
  nextAction
}) => (
  <Div height={70} bc="neutral1" bt={1} px={6} display="row.flex-start.center">
    <BigOutlineButton mr={2} onClick={previousAction}>
      {previousText}
    </BigOutlineButton>
    <BigFilledButton
      flex={1}
      bg="altB5"
      disabled={isNextBtnDisabled}
      onClick={nextAction}
    >
      {nextText}
    </BigFilledButton>
  </Div>
);

export default decorate(QuickAssignPricesFooter);
