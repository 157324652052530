import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "Integrations/FuzionMappingModal";
import * as R from "ramda";
import {
  Div,
  BigOutlineButton,
  SearchIcon,
  BigTextInput,
  MediumFilledInput,
  SmallCheckbox
} from "components/Base";
import { LABELS } from "Integrations/FuzionMappingModal/constants";

import {
  getExhibitorFields,
  getExhibitorRecordTypes
} from "Integrations/FuzionMappingModal/selectors";

import {
  AccountFieldDropdown,
  AccountRecordTypeDropdown,
  ContactFieldDropdown,
  ContactRecordTypeDropdown
} from "./Fields";

const decorate = connect(
  state => ({
    loading: getters.loading(state),
    saving: getters.saving(state),
    error: getters.error(state),
    activeTab: getters.activeTab(state),
    toggledFieldId: getters.toggledFieldId(state),
    isPreviewEnabled: getters.isPreviewEnabled(state),
    requireProfileApproval: getters.requireProfileApproval(state),
    customLabels: getters.customLabels(state),
    fields: getExhibitorFields(state),
    recordTypes: getExhibitorRecordTypes(state),
    writeDataToLennd: getters.writeDataToLennd(state)
  }),
  {
    saveMapping: actions.saveMapping,
    toggleField: actions.toggleField,
    updateValue: actions.updateExhibitorValue,
    updateRecordType: actions.updateExhibitorRecordType,
    setSearchTerm: actions.setSearchTerm,
    setIsPreviewEnabled: actions.setIsPreviewEnabled,
    setRequireProfileApproval: actions.setRequireProfileApproval,
    updateCustomLabel: actions.updateCustomLabel,
    setWriteDataToLennd: actions.setWriteDataToLennd
  }
);

const renderFieldsDropdown = ({ type, value, path, updateValue }) =>
  R.prop(type)({
    [LABELS.GROUP]: <AccountFieldDropdown {...{ value, path, updateValue }} />,
    [LABELS.PERSON]: <ContactFieldDropdown {...{ value, path, updateValue }} />
  });

const renderRecordTypesDropdown = ({ type, value, path, updateRecordType }) =>
  R.prop(type)({
    [LABELS.GROUP]: (
      <AccountRecordTypeDropdown
        {...{ value, path, updateValue: updateRecordType }}
      />
    ),
    [LABELS.PERSON]: (
      <ContactRecordTypeDropdown
        {...{ value, path, updateValue: updateRecordType }}
      />
    )
  });

const renderField = (field, toggleField, updateValue) => {
  if (field.type === "field") {
    if (!field.display) {
      return null;
    }

    return (
      <Div
        key={field.path}
        display="column"
        py={4}
        px={4}
        width={1}
        mb={3}
        bra={1}
        onClick={e => {
          e.stopPropagation();

          if (!field.isToggled) {
            toggleField(field.path);
          }
        }}
        bg={
          field.isToggled
            ? "neutral2"
            : {
                default: "inherit",
                hover: "neutral2"
              }
        }
      >
        <Div fw={3} color="gray7">
          {field.name}
        </Div>
        <Div fs={1}>
          {field.moduleType} Field&nbsp;
          {field.mappedToFieldName ? (
            <>&middot;&nbsp;Mapped to "{field.mappedToFieldName}"</>
          ) : null}
        </Div>
        {field.isToggled ? (
          <Div mt={2} onClick={e => e.stopPropagation()}>
            <Div fs={1} fw={3} mb={1}>
              Select {field.moduleType} field to map to:
            </Div>
            <Div display="row.flex-start.center">
              {renderFieldsDropdown({
                type: field.moduleType,
                value: field.value,
                path: field.path,
                updateValue
              })}
              <BigOutlineButton
                ml={1}
                color="neutral5"
                onClick={() => toggleField()}
              >
                Done
              </BigOutlineButton>
            </Div>
          </Div>
        ) : null}
      </Div>
    );
  }

  if (!R.filter(f => f.display)(field.fields).length) {
    return null;
  }

  return (
    <Div
      key={field.path}
      mb={3}
      p={3}
      bg="neutral1"
      b={1}
      bc="neutral2"
      bra={1}
    >
      {R.map(f => renderField(f, toggleField, updateValue))(field.fields)}
    </Div>
  );
};

const Exhibitors = ({
  fields,
  recordTypes,
  toggleField,
  toggledFieldId,
  updateValue,
  updateRecordType,
  searchTerm,
  setSearchTerm,
  isPreviewEnabled,
  setIsPreviewEnabled,
  requireProfileApproval,
  setRequireProfileApproval,
  updateCustomLabel,
  customLabels,
  writeDataToLennd,
  setWriteDataToLennd
}) => {
  return (
    <Div
      px={8}
      py={3}
      onClick={toggledFieldId ? () => toggleField() : undefined}
    >
      {/* settings */}
      <Div bc="neutral3" bb={1} pb={2} mb={2} fw={4} color="gray7">
        Settings:
      </Div>

      <Div display="row.space-between.center" mb={5}>
        <Div display="column">
          <Div fw={3} color="gray7">
            Enable syncing data from Lennd to Fuzion
          </Div>
        </Div>
        <Div>
          <SmallCheckbox selected={true} disabled />
        </Div>
      </Div>

      <Div display="row.space-between.center" mb={5}>
        <Div display="column">
          <Div fw={3} color="gray7">
            Enable syncing data from Fuzion to Lennd
          </Div>
          <Div fs={1}>
            Any changes made in 3rd party systems that write, delete or update
            records in Fuzion will sync to Lennd
          </Div>
        </Div>
        <Div>
          <SmallCheckbox
            selected={writeDataToLennd}
            onClick={() => setWriteDataToLennd(!writeDataToLennd)}
          />
        </Div>
      </Div>

      <Div display="row.space-between.center" mb={5}>
        <Div display="column" fw={3} color="gray7">
          Enable Preview Mode
        </Div>
        <Div>
          <SmallCheckbox
            selected={isPreviewEnabled}
            onClick={() => setIsPreviewEnabled(!isPreviewEnabled)}
          />
        </Div>
      </Div>

      <Div display="row.space-between.center" mb={5}>
        <Div display="column" fw={3} color="gray7">
          Require profile approval in order to sync
        </Div>
        <Div>
          <SmallCheckbox
            selected={requireProfileApproval}
            onClick={() => setRequireProfileApproval(!requireProfileApproval)}
          />
        </Div>
      </Div>

      {/* labels */}
      {isPreviewEnabled ? (
        <>
          <Div bc="neutral3" bb={1} pb={2} mb={2} fw={4} color="gray7">
            Template Settings:
          </Div>

          <Div display="row.space-between.center" mb={5}>
            <Div display="column">
              <Div fw={3} color="gray7">
                Highlight Color (Hex)
              </Div>
              <Div fs={1}>(Optional) Example: #0066b3</Div>
            </Div>
            <Div>
              <MediumFilledInput
                bg="white"
                color="black"
                onChange={value =>
                  updateCustomLabel({
                    path: "actionButtonColor",
                    value
                  })
                }
                value={R.propOr("", "actionButtonColor")(customLabels)}
              />
            </Div>
          </Div>

          <Div display="row.space-between.center" mb={3}>
            <Div display="column">
              <Div fw={3} color="gray7">
                Show Banner Image
              </Div>
            </Div>
            <Div>
              <SmallCheckbox
                selected={R.propOr(false, "showBanner")(customLabels)}
                onClick={() =>
                  updateCustomLabel({
                    path: "showBanner",
                    value: !R.prop("showBanner")(customLabels)
                  })
                }
              />
            </Div>
          </Div>

          <Div display="row.space-between.center" mb={3}>
            <Div display="column">
              <Div fw={3} color="gray7">
                Show Banner Placeholder If No Image
              </Div>
            </Div>
            <Div>
              <SmallCheckbox
                selected={R.propOr(false, "showBannerPlaceholderIfNoImage")(
                  customLabels
                )}
                onClick={() =>
                  updateCustomLabel({
                    path: "showBannerPlaceholderIfNoImage",
                    value: !R.prop("showBannerPlaceholderIfNoImage")(
                      customLabels
                    )
                  })
                }
              />
            </Div>
          </Div>

          <Div display="row.space-between.center" mb={3}>
            <Div display="column">
              <Div fw={3} color="gray7">
                Show Logo Image
              </Div>
            </Div>
            <Div>
              <SmallCheckbox
                selected={R.propOr(false, "showLogo")(customLabels)}
                onClick={() =>
                  updateCustomLabel({
                    path: "showLogo",
                    value: !R.prop("showLogo")(customLabels)
                  })
                }
              />
            </Div>
          </Div>

          <Div display="row.space-between.center" mb={3}>
            <Div display="column">
              <Div fw={3} color="gray7">
                Show Logo Placeholder If No Image
              </Div>
            </Div>
            <Div>
              <SmallCheckbox
                selected={R.propOr(false, "showLogoPlaceholderIfNoImage")(
                  customLabels
                )}
                onClick={() =>
                  updateCustomLabel({
                    path: "showLogoPlaceholderIfNoImage",
                    value: !R.prop("showLogoPlaceholderIfNoImage")(customLabels)
                  })
                }
              />
            </Div>
          </Div>

          <Div display="row.space-between.center" mb={3}>
            <Div display="column">
              <Div fw={3} color="gray7">
                Show Chat Placeholder
              </Div>
            </Div>
            <Div>
              <SmallCheckbox
                selected={R.propOr(false, "showChatPlaceholder")(customLabels)}
                onClick={() =>
                  updateCustomLabel({
                    path: "showChatPlaceholder",
                    value: !R.prop("showChatPlaceholder")(customLabels)
                  })
                }
              />
            </Div>
          </Div>

          <Div display="row.space-between.center" mb={3}>
            <Div display="column">
              <Div fw={3} color="gray7">
                Contact Label
              </Div>
              <Div fs={1}>(Optional) Default: Representative Contact</Div>
            </Div>
            <Div>
              <MediumFilledInput
                bg="white"
                color="black"
                onChange={value =>
                  updateCustomLabel({
                    path: "contact",
                    value
                  })
                }
                value={R.propOr("", "contact")(customLabels)}
              />
            </Div>
          </Div>

          <Div display="row.space-between.center" mb={3}>
            <Div display="column">
              <Div fw={3} color="gray7">
                Show Action Button #1
              </Div>
            </Div>
            <Div>
              <SmallCheckbox
                selected={R.propOr(false, "showActionButton")(customLabels)}
                onClick={() =>
                  updateCustomLabel({
                    path: "showActionButton",
                    value: !R.prop("showActionButton")(customLabels)
                  })
                }
              />
            </Div>
          </Div>

          <Div display="row.space-between.center" mb={5}>
            <Div display="column">
              <Div fw={3} color="gray7">
                Action Button #1 Label
              </Div>
              <Div fs={1}>(Optional) Default: Schedule a Meeting</Div>
            </Div>
            <Div>
              <MediumFilledInput
                bg="white"
                color="black"
                onChange={value =>
                  updateCustomLabel({
                    path: "actionButton",
                    value
                  })
                }
                value={R.propOr("", "actionButton")(customLabels)}
              />
            </Div>
          </Div>

          <Div display="row.space-between.center" mb={3}>
            <Div display="column">
              <Div fw={3} color="gray7">
                Show Action Button #2
              </Div>
            </Div>
            <Div>
              <SmallCheckbox
                selected={R.propOr(false, "showAction2Button")(customLabels)}
                onClick={() =>
                  updateCustomLabel({
                    path: "showAction2Button",
                    value: !R.prop("showAction2Button")(customLabels)
                  })
                }
              />
            </Div>
          </Div>

          <Div display="row.space-between.center" mb={5}>
            <Div display="column">
              <Div fw={3} color="gray7">
                Action Button #2 Label
              </Div>
              <Div fs={1}>(Optional) Default: Drop a Business Card</Div>
            </Div>
            <Div>
              <MediumFilledInput
                bg="white"
                color="black"
                onChange={value =>
                  updateCustomLabel({
                    path: "action2Button",
                    value
                  })
                }
                value={R.propOr("", "action2Button")(customLabels)}
              />
            </Div>
          </Div>

          {/*
          <Div display="row.space-between.center" mb={5}>
            <Div display="column">
              <Div fw={3} color="gray7">
                Action Button #2 Color (Hex)
              </Div>
              <Div fs={1}>(Optional) Example: #0066b3</Div>
            </Div>
            <Div>
              <MediumFilledInput
                bg="white"
                color="black"
                onChange={value =>
                  updateCustomLabel({
                    path: "action2ButtonColor",
                    value
                  })
                }
                value={R.propOr("", "action2ButtonColor")(customLabels)}
              />
            </Div>
          </Div>
              */}
        </>
      ) : null}

      {/* record types */}
      <Div bc="neutral3" bb={1} pb={2} mb={2} fw={4} color="gray7">
        Record Types:
      </Div>
      {R.map(type => (
        <Div display="row.space-between.center" mb={3} key={type.path}>
          <Div display="column">
            <Div fw={3} color="gray7">
              {type.name}
            </Div>
            <Div fs={1}>Map to a {type.type} Record Type</Div>
          </Div>
          <Div>
            {renderRecordTypesDropdown({
              type: type.type,
              value: type.value,
              path: type.path,
              updateRecordType
            })}
          </Div>
        </Div>
      ))(recordTypes)}

      {/* fields */}
      <Div bc="neutral3" bb={1} pb={2} mb={2} mt={8} fw={4} color="gray7">
        Fields:
      </Div>
      <BigTextInput
        RightIcon={SearchIcon}
        onChange={value => setSearchTerm(value)}
        placeholder="Search..."
        value={searchTerm}
        continuous
        display="row.flex-start.center"
        style={{
          border: "1px solid #ccc"
        }}
        mb={3}
      />

      {R.map(field => renderField(field, toggleField, updateValue))(fields)}
    </Div>
  );
};

export default decorate(Exhibitors);
