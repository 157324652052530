import React from "react";
import { Div } from "components/Base";

import { noop } from "utils/General";

import { DefaultHeader } from "components/Global/Modal/Layout/Headers";

const MiniModalWrapper = ({
  children,
  showModal = false,
  hideModal = noop,
  title = "",
  width = 715,
  height = 528,
  hideHeader = false,
  zIndex = 11,
  style = {}
}) =>
  (showModal && (
    <Div
      display="row.center.center"
      style={{
        position: "fixed",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "rgba(0,0,0,0.3)",
        zIndex
      }}
    >
      <Div
        height={height}
        width={width}
        display="column.flex-start.stretch"
        bra={2}
        bg="white"
        style={style}
      >
        {hideHeader ? null : (
          <DefaultHeader hideModal={hideModal} heading={title} />
        )}
        {children}
      </Div>
    </Div>
  )) ||
  null;

export default MiniModalWrapper;
