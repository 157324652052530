import { BEGIN, COMMIT, REVERT } from "redux-optimist";
import uuid from "node-uuid";
import {
  SYNC,
  SYNC_COMPLETE,
  REQUEST,
  RECEIVE,
  RECEIVE_APPEND,
  CREATE,
  CREATE_COMPLETE,
  ERROR,
  UPDATE_META,
  CLEAR_ERROR,
  INVALIDATE
} from "./constants";
import { registerError } from "redux/modules/errors/actions";
import api from "./api";
import { addReferences } from "redux/modules/entityReferences/actions";

export function getOrders(data) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: REQUEST });
      const response = await api.get(getState().user.user.credentials, data);
      dispatch(addReferences(response.list.references));
      return dispatch({
        type: RECEIVE,
        payload: {
          ...response.list
        }
      });
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching orders"
          }
        ])
      );
    }
  };
}

export function upsertMeta(eventId, meta) {
  return async (dispatch, getState) => {
    try {
      const result = await api.upsertMeta(
        getState().user.user.credentials,
        eventId,
        meta
      );

      dispatch({
        type: UPDATE_META,
        payload: result
      });

      return result;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating columns"
          }
        ])
      );
    }
  };
}

export function searchOrders(eventId, criteria, integration) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: REQUEST });
      const response = await api.search(
        getState().user.user.credentials,
        eventId,
        criteria,
        integration
      );
      return dispatch({
        type: RECEIVE_APPEND,
        payload: {
          ...response.list
        }
      });
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching orders"
          }
        ])
      );
    }
  };
}

export function syncAllOrders(eventId, integrationId) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: SYNC });
      const response = await api.syncAllOrders(
        getState().user.user.credentials,
        {
          eventId: eventId,
          integrationId: integrationId
        }
      );
      return dispatch({
        type: SYNC_COMPLETE,
        payload: {
          orders: response.orders
        }
      });
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred while syncing orders"
          }
        ])
      );
    }
  };
}

export function createOrder({ status, contactId, source, eventId }) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: CREATE,
      payload: { status, contactId, source, eventId },
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.post(getState().user.user.credentials, {
        status,
        contactId,
        source,
        eventId
      });
      dispatch({
        type: CREATE_COMPLETE,
        payload: { order: result.order },
        optimist: { type: COMMIT, id: transactionId }
      });
      return result.order;
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
    }
  };
}

export function findOrdersBySubmissionIds(eventId, submissionIds) {
  return async (dispatch, getState) => {
    try {
      return await api.findOrdersBySubmissionIds(
        getState().user.user.credentials,
        eventId,
        submissionIds
      );
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching submissions"
          }
        ])
      );
    }
  };
}

export function invalidate() {
  return {
    type: INVALIDATE
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR
  };
}
