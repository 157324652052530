import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { getters, actions } from "../index";

import {
  getCountOfToggledRows,
  getFieldsForEditModal,
  getToggledRowsWithName,
  getSearchbarPlaceholder,
  getToggledRows,
  getHasViewChanged
} from "../selectors";

import { TABLE_INSTANCE_ID } from "Forms/Results2/constants";
import { actions as TableActions } from "ui-kit/Table/model";

import CSSModules from "react-css-modules";
import css from "ui-kit/ViewPicker/View/styles.scss";

import {
  Div,
  MediumFilledButton,
  BigFilledButton,
  AddIcon
} from "components/Base";
import { VARIANTS } from "ui-kit/Theme/constants";
import UISearchBar from "ui-kit/SearchBar/View";
import CanUserDo from "components/Global/Security/CanUserDo";
import OptionsButton from "./OptionsButton";

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      moduleId: getters.moduleId(state),
      countOfToggledRows: getCountOfToggledRows(state),
      toggledRows: getToggledRows(state),
      toggledRowsWithName: getToggledRowsWithName(state),
      fields: getFieldsForEditModal(state, props),
      references: getters.references(state),
      placeholder: getSearchbarPlaceholder(state),
      hasViewChanged: getHasViewChanged(state),
      collapsed: getters.collapsed(state)
    }),
    {
      onExport: actions.exportData,
      showSubscribeModal: actions.showSubscribeModal,
      showAddEventModal: actions.showAddEventModal,
      showDeleteEventsModal: actions.showDeleteEventsModal,
      clearSelectedRows: () =>
        TableActions.clearSelectedRows(null, {
          meta: {
            instanceId: TABLE_INSTANCE_ID
          }
        })
    }
  )
);

const Button = ({ LeftIcon, RightIcon, children, onClick }) => (
  <MediumFilledButton
    className={css.button1}
    color="white"
    hover={false}
    onClick={onClick}
    mr={2}
    LeftIcon={LeftIcon}
    RightIcon={RightIcon}
  >
    {children}
  </MediumFilledButton>
);

const EventsToolbar = ({
  moduleId,
  countOfToggledRows,
  toggledRows,
  clearSelectedRows,
  showDeleteEventsModal
}) => (
  <Div display="row.flex-start.center">
    <Div fw={4} mr={4} fs={4} color="white">
      {countOfToggledRows} Selected
    </Div>

    <CanUserDo action={`${moduleId}_update`}>
      <CanUserDo action={`${moduleId}_delete`}>
        <Button
          onClick={() => {
            showDeleteEventsModal(toggledRows);
          }}
        >
          Delete
        </Button>
      </CanUserDo>
    </CanUserDo>
    <Div ml={2} fw={4} color="white" onClick={clearSelectedRows}>
      Clear selected
    </Div>
  </Div>
);

const Toolbar = ({
  handlers,
  moduleId,
  countOfToggledRows,
  toggledRows,
  clearSelectedRows,
  fields,
  references,
  placeholder,
  showAddEventModal,
  showDeleteEventsModal
}) => (
  <Div
    display="row.space-between.center"
    width={1}
    height={75}
    px={3}
    style={{ position: "relative", backgroundColor: "#813CD2", flexShrink: 0 }}
  >
    {!countOfToggledRows ? (
      <>
        <Div display="row.flex-start.center" flex={1}>
          <UISearchBar
            variant={VARIANTS.SURFACE}
            flex={1}
            mr={2}
            placeholder={placeholder}
            height={43}
            style={{
              maxWidth: 445
            }}
          />
        </Div>

        <Div display="row.flex-start.center">
          <CanUserDo any={[`${moduleId}_manage`, `${moduleId}_export`]}>
            <OptionsButton handlers={handlers} />
          </CanUserDo>

          <CanUserDo action={`${moduleId}_create`}>
            <BigFilledButton
              bg="orange5"
              color="white"
              ml={2}
              onClick={showAddEventModal}
              LeftIcon={AddIcon}
            >
              Add
            </BigFilledButton>
          </CanUserDo>
        </Div>
      </>
    ) : (
      <EventsToolbar
        {...{
          moduleId,
          countOfToggledRows,
          toggledRows,
          clearSelectedRows,
          showDeleteEventsModal,
          fields,
          references,
          handlers
        }}
      />
    )}
  </Div>
);

export default CSSModules(decorate(Toolbar), css);
