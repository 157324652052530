import * as R from "ramda";

import React, { Component } from "react";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import * as STANDARD_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

import Tooltip from "components/Global/Tooltip";
import { PrimaryContactsModal } from "Passes/GuestLists/PrimaryContacts";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

import { getValue, getSettings } from "../../utils";

import styleSpects from "components/Base/Base/styleSpecs";
import { actions } from "../../model";
import { noop, parseComboId } from "utils/General";

const joinNames = R.compose(
  R.join(", "),
  R.map(R.prop("name"))
);

const decorate = connect(
  null,
  bindInstance({
    showModal,
    hideModal,
    onDone: actions.updateData
  })
);

class PrimaryContacts extends Component {
  showPrimaryContactsModal = () => {
    const accountId = parseComboId(this.props.data.id)[0];
    const accountName = R.path([
      "data",
      STANDARD_FIELD_IDS.ACCOUNTS.NAME,
      "value"
    ])(this.props);
    const recordTypeName = R.path(["data", "type", "name"])(this.props);
    const recordTypeColor = R.path(["data", "type", "background_color"])(
      this.props
    );
    const recordTypeIcon = R.path(["data", "type", "icon"])(this.props);

    this.props.showModal({
      content: (
        <PrimaryContactsModal
          groupId={accountId}
          groupName={accountName}
          recordTypeName={recordTypeName}
          recordTypeColor={recordTypeColor}
          recordTypeIcon={recordTypeIcon}
          onDone={this.props.onDone}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const contacts = getValue(this.props, R.identity, []);
    const isFieldReadOnly = getSettings(this.props, "readOnly", false);

    if (!contacts.length) {
      return (
        !isFieldReadOnly && (
          <div
            onClick={this.showPrimaryContactsModal}
            style={{
              color: styleSpects.color.gray6,
              fontSize: styleSpects.fontSize[3],
              cursor: "pointer",
              textDecoration: "underline"
            }}
          >
            + Add Contact
          </div>
        )
      );
    }

    return (
      <div
        onClick={isFieldReadOnly ? noop : this.showPrimaryContactsModal}
        className={isFieldReadOnly ? "" : "underline-on-hover"}
        style={{
          cursor: isFieldReadOnly ? "default" : "pointer"
        }}
      >
        {R.length(contacts) > 1 ? (
          <Tooltip tooltip={joinNames(contacts)}>
            <div>{R.length(contacts)} contacts</div>
          </Tooltip>
        ) : (
          <div>{R.prop("name", R.head(contacts) || {})}</div>
        )}
      </div>
    );
  }
}

export default decorate(PrimaryContacts);
