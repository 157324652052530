import React from "react";
import CSSModules from "react-css-modules";
import css from "../../styles.scss";
import Tabs from "components/Global/CRM/Tabs";
import Tab from "components/Global/CRM/Tabs/Tab";
import { Div } from "components/Base";

const Row = CSSModules(
  ({ name, account, goToContactProfile, goToAccountProfile, removeAccess }) => (
    <Div display="row.space-between.center">
      <div styleName="rowInfo">
        <span
          styleName="link"
          onClick={goToContactProfile}
          role="link"
          tabIndex="0"
        >
          {name}
        </span>
        {account ? (
          <>
            <i className="material-icons" styleName="icon">
              navigate_next
            </i>
            <span
              styleName="link"
              onClick={goToAccountProfile}
              role="link"
              tabIndex="0"
            >
              {account}
            </span>
          </>
        ) : null}
      </div>
      {removeAccess ? (
        <Div fs={1} color="danger5" underline onClick={removeAccess}>
          Remove Access
        </Div>
      ) : null}
    </Div>
  ),
  css
);

const ValidRow = CSSModules(
  ({ name, account, goToContactProfile, goToAccountProfile }) => (
    <div styleName="row">
      <Row
        goToContactProfile={goToContactProfile}
        goToAccountProfile={goToAccountProfile}
        name={name}
        account={account}
      />
    </div>
  ),
  css
);

const InvalidRow = CSSModules(
  ({
    name,
    account,
    reason,
    goToContactProfile,
    goToAccountProfile,
    removeAccess
  }) => (
    <div styleName="row">
      <Row
        removeAccess={removeAccess}
        goToContactProfile={goToContactProfile}
        goToAccountProfile={goToAccountProfile}
        name={name}
        account={account}
      />
      <div styleName="invalidReason">{reason}</div>
    </div>
  ),
  css
);

const Validate = CSSModules(
  ({ result, goToContactProfile, goToAccountProfile, removeAccess }) => {
    const countOfValid = result.valid.length;
    const countOfIssues =
      result.invalid_emails.length + result.already_has_access.length;

    return (
      <div styleName="body">
        <Tabs style={{ fontSize: 15 }} className={css.tabs}>
          <Tab label={`Valid (${countOfValid})`} className={css.tab}>
            <div styleName="tabBody">
              {!result.valid.length ? (
                <div>
                  There are no people to give access to. Please see the issues
                  tab.
                </div>
              ) : (
                <div styleName="caption">
                  You are giving portal login access to the following people:
                </div>
              )}
              {result.valid.map(r => {
                const rowWithHelpers = {
                  ...r,
                  goToContactProfile: () => goToContactProfile(r.id),
                  goToAccountProfile: () => goToAccountProfile(r.account_id)
                };

                return (
                  <ValidRow
                    key={`${r.id}_${r.account_id}`}
                    {...rowWithHelpers}
                  />
                );
              })}
            </div>
          </Tab>
          {countOfIssues ? (
            <Tab label={`Issues (${countOfIssues})`} className={css.tab}>
              <div styleName="tabBody">
                <div styleName="caption">
                  There are issues with giving the following people portal
                  access:
                </div>
                {result.invalid_emails.map(r => {
                  const rowWithHelpers = {
                    ...r,
                    goToContactProfile: () => goToContactProfile(r.id),
                    goToAccountProfile: () => goToAccountProfile(r.account_id)
                  };

                  return (
                    <InvalidRow
                      key={`${r.id}_${r.account_id}`}
                      reason="Invalid email"
                      {...rowWithHelpers}
                    />
                  );
                })}
                {result.already_has_access.map(r => {
                  const rowWithHelpers = {
                    ...r,
                    removeAccess: () => removeAccess(r.user_id),
                    goToContactProfile: () => goToContactProfile(r.id),
                    goToAccountProfile: () => goToAccountProfile(r.account_id)
                  };

                  return (
                    <InvalidRow
                      key={`${r.id}_${r.account_id}`}
                      reason="Already has access"
                      {...rowWithHelpers}
                    />
                  );
                })}
              </div>
            </Tab>
          ) : null}
        </Tabs>
      </div>
    );
  },
  css
);

export default Validate;
