import { put, all, takeEvery, fork, call, select } from "redux-saga/effects";
import { actions } from "Portal/PortalWrapper/model";

import { registerError } from "redux/modules/errors/actions";
import { getCredentials } from "redux/modules/user/selectors";

import Api from "./api";

import { RECEIVE } from "redux/modules/portal/constants";

const init = function*({ payload: { portalId } }) {
  const credentials = yield select(getCredentials);

  try {
    const { portal: portalPayload } = yield call(
      Api.getPortal,
      credentials,
      portalId
    );

    let portalUserDetails = {};
    try {
      const { portal: portalUserPayload } = yield call(
        Api.getPortalUserDetails,
        credentials,
        portalId
      );
      portalUserDetails = portalUserPayload;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("[portal user details] ignoring since not logged in");
    }

    yield put({
      type: RECEIVE,
      payload: {
        portal: {
          portal: {
            ...portalPayload,
            ...portalUserDetails
          }
        }
      }
    });

    yield put(actions.setInitialData({ portalId, ...portalPayload }));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred geting portal"
          }
        ])
      )
    ]);
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([fork(watchInit)]);
};

export default rootSaga;
