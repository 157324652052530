import React from "react";

import { connect } from "react-redux";

import { getters, actions } from "../index";

import { getSections } from "../selectors";
import { addS } from "utils/General";
import SortByPopover from "./SortByPopover";

import {
  Div,
  DragIcon,
  Text2,
  collapsableHandler,
  Collapsable,
  EditIcon,
  DeleteIcon,
  SmallFilledButton,
  MediumFilledInput,
  MediumFilledButton,
  DownFilledIcon,
  CloseIcon,
  Cards
} from "components/Base";

const decorate = connect(
  state => ({
    sections: getSections(state),
    editingSectionId: getters.editingSectionId(state),
    tempSection: getters.tempSection(state)
  }),
  {
    enabledEditingName: actions.enabledEditingSectionName,
    cancelEditingSectionName: actions.cancelEditingSectionName,
    updateEditingSectionName: actions.updateEditingSectionName,
    updateSection: actions.updateSection,
    deleteSection: actions.deleteSection,
    updateSectionOrder: actions.updateSectionOrder
  }
);

const Section = collapsableHandler(
  ({
    toggleCollapsed,
    collapsed,
    name,
    enabledEditingName,
    id,
    module_id,
    visible_fields,
    sort_by,
    fields,
    module,
    lookup_field,
    cancelEditingSectionName,
    updateEditingSectionName,
    editingSectionId,
    updateSection,
    deleteSection,
    tempSection,
    handlers
  }) => {
    return (
      <Div bg="white" bra={1} ba={2} bc="neutral2" mb={1} p={2}>
        <Div display="row.space-between.center" height={44}>
          <Div display="row.flex-start.center">
            <DragIcon mr={1} />
            <Div display="column">
              {id !== editingSectionId ? (
                <Div display="row.flex-start.center">
                  <Text2 bold>{name}</Text2>
                  <SmallFilledButton
                    ml={1}
                    bg="neutral0"
                    width={20}
                    height={1}
                    onClick={() => enabledEditingName({ editingSectionId: id })}
                  >
                    <EditIcon color="black" />
                  </SmallFilledButton>
                  <SmallFilledButton
                    ml={1}
                    bg="neutral0"
                    width={20}
                    height={1}
                    onClick={() => deleteSection(id)}
                  >
                    <DeleteIcon color="black" />
                  </SmallFilledButton>
                </Div>
              ) : (
                <Div display="row.flex-start.center">
                  <MediumFilledInput
                    mx={1}
                    bg="neutral1"
                    width={200}
                    height={1}
                    value={tempSection.name}
                    color="black"
                    continuous
                    onChange={value =>
                      updateEditingSectionName({ id, name: value })
                    }
                  />
                  <MediumFilledButton
                    bg="primary7"
                    onClick={() => updateSection()}
                  >
                    Save
                  </MediumFilledButton>
                  <MediumFilledButton
                    bg="neutral1"
                    ml={1}
                    width={30}
                    onClick={() => cancelEditingSectionName({ id })}
                  >
                    <CloseIcon color="black" />
                  </MediumFilledButton>
                </Div>
              )}
              <Div display="row" fs={1} color="gray5">
                {module
                  ? `${module.name} (Lookup Field: ${lookup_field.name})`
                  : ""}
              </Div>
            </Div>
          </Div>
          <Div flex={1} onClick={toggleCollapsed} display="row.flex-end">
            <DownFilledIcon
              size={24}
              color="black"
              style={{ transform: `rotate(${collapsed ? -90 : 0}deg)` }}
            />
          </Div>
        </Div>
        <Collapsable collapsed={collapsed}>
          <Div mt={1}>
            <Div
              height={44}
              bg="white"
              bra={1}
              ba={2}
              bc="neutral2"
              display="row.space-between.center"
              px={3}
              mb={1}
            >
              <Text2 bold>Select fields to show</Text2>
              <Div display="row.flex-start.center">
                <Text2
                  color="primary7"
                  bold
                  onClick={() =>
                    handlers.showSelectFieldsModal({
                      moduleId: module_id,
                      sectionId: id,
                      fields,
                      selectedFieldIds: visible_fields
                    })
                  }
                >
                  {visible_fields.length
                    ? `Showing ${visible_fields.length} field${addS(
                        visible_fields.length
                      )}`
                    : "Showing all fields"}
                </Text2>
              </Div>
            </Div>
            <Div
              style={{
                minHeight: "44px"
              }}
              bg="white"
              bra={1}
              ba={2}
              bc="neutral2"
              display="row.space-between.center"
              px={3}
              mb={1}
            >
              <Text2 bold>Sort records</Text2>
              <SortByPopover
                {...{
                  sectionId: id,
                  sortBy: sort_by,
                  allFields: fields
                }}
              />
            </Div>

            {/*
          // @NOTE: Uncomment to allow nested sections
          */}
          </Div>
        </Collapsable>
      </Div>
    );
  }
);

const MakeSectionCards = depth => Cards(Section, `SECTION_${depth}`);
const SectionCards = MakeSectionCards(0);

const Sections = ({
  sections,
  enabledEditingName,
  cancelEditingSectionName,
  updateEditingSectionName,
  editingSectionId,
  updateSection,
  deleteSection,
  tempSection,
  updateSectionOrder,
  handlers
}) => (
  <SectionCards
    depth={0}
    cards={sections}
    enabledEditingName={enabledEditingName}
    cancelEditingSectionName={cancelEditingSectionName}
    updateEditingSectionName={updateEditingSectionName}
    editingSectionId={editingSectionId}
    updateSection={updateSection}
    deleteSection={deleteSection}
    tempSection={tempSection}
    handlers={handlers}
    onReorder={orderedSections => updateSectionOrder(orderedSections)}
    updateSectionOrder={updateSectionOrder}
  />
);

export default decorate(Sections);
