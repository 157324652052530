import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

import { actions, getters } from "../model";
import { getDraggableSectionsAndFields } from "../selectors";

import {
  Div,
  Text2,
  DragIcon,
  SettingsIcon,
  DeleteIcon,
  CheckIcon,
  SmallToggle,
  AddIcon,
  BigOutlineButton,
  AddCircleIcon,
  Cards,
  MediumOutlineInput,
  PopoverMenu
} from "components/Base";
import DescriptionPopover from "./DescriptionPopover";
import LabelPopover from "./LabelPopover";

import Circle from "ui-kit/Circle";

const decorate = connect(
  state => ({
    draggableFields: getDraggableSectionsAndFields(state),
    tempDescription: getters.tempDescription(state),
    tempAlias: getters.tempAlias(state)
  }),
  {
    showAddFieldModal: actions.addField,
    editField: actions.editField,
    reorderFields: actions.reorderFields,
    removeRow: actions.removeRow,
    toggleRequiredField: actions.toggleRequiredField,
    updateDescription: actions.updateDescription,
    setTempDescription: actions.setTempDescription,
    addHeaderSection: actions.addHeaderSection,
    removeHeaderSection: actions.removeHeaderSection,
    updateDescriptionHeaderSection: actions.updateDescriptionHeaderSection,
    updateTitleHeaderSection: actions.updateTitleHeaderSection,
    setTempAlias: actions.setTempAlias,
    updateAlias: actions.updateAlias,
    showFieldsModal: () => actions.setShowSelectFieldsModal(true)
  }
);

const HeaderSectionFields = ({
  id,
  description,
  tempDescription,
  removeHeaderSection,
  updateDescriptionHeaderSection,
  setTempDescription,
  updateTitleHeaderSection,
  title
}) => {
  return (
    <Div
      display="row.space-between"
      mt={1}
      bra={1}
      bg="white"
      shadow={1}
      py={3}
    >
      <Div>
        <Div display="row.flex-start" pl={2} flex={1}>
          <DragIcon />
          <Div ml={2}>
            <Text2 bold color="black" mb={1}>
              Section Header
            </Text2>
            <MediumOutlineInput
              mb={1}
              width={500}
              continuous
              value={title}
              onChange={value => updateTitleHeaderSection({ id, title: value })}
            />
            <Div display="row.flex-start.center">
              <DescriptionPopover
                description={description}
                id={id}
                tempDescription={tempDescription}
                setTempDescription={setTempDescription}
                updateDescription={() => {
                  updateDescriptionHeaderSection({
                    id,
                    description: R.propOr("", "text", tempDescription)
                  });
                }}
              />
            </Div>
          </Div>
        </Div>
      </Div>
      <DeleteIcon
        tooltip="Remove Section"
        mr={11}
        color={{ default: "black", hover: "primary7" }}
        onClick={() => removeHeaderSection({ id })}
      />
    </Div>
  );
};

const QuestionRow = ({
  id,
  name,
  required,
  removeRow,
  toggleRequiredField,
  description,
  tempDescription,
  setTempDescription,
  updateDescription,
  editField,
  addHeaderSection,
  order,
  tempAlias,
  setTempAlias,
  alias,
  moduleLabel,
  updateAlias
}) => {
  return (
    <Div
      display="row.space-between.center"
      mt={1}
      bra={1}
      bg="white"
      shadow={1}
      height={53}
      style={{ position: "relative" }}
      className="hoverContainer"
    >
      <Div
        style={{
          position: "absolute",
          right: 0,
          top: -10,
          width: "102%",
          zIndex: 5
        }}
        display="row.flex-start.center"
        className="showOnHover"
        onClick={() => {
          addHeaderSection({ order });
        }}
      >
        <Circle size={16} bg="primary7">
          <AddIcon size={12} color="white" />
        </Circle>
        <Div width={1} height={2} bg="primary7" />
      </Div>
      <Div display="row.flex-start.center" pl={2}>
        <DragIcon />
        <LabelPopover
          name={name}
          id={id}
          tempAlias={tempAlias}
          setTempAlias={setTempAlias}
          alias={alias}
          moduleLabel={moduleLabel}
          updateAlias={() => {
            updateAlias({
              id,
              description: R.propOr("", "text", tempAlias)
            });
          }}
        />
      </Div>
      <Div display="row.flex-start.center">
        <Div display="row.flex-start.center">
          <DescriptionPopover
            description={description}
            id={id}
            tempDescription={tempDescription}
            setTempDescription={setTempDescription}
            updateDescription={() => {
              updateDescription({
                id,
                description: R.propOr("", "text", tempDescription)
              });
            }}
          />
          <Div mr={4} width={68} display="row.center.center">
            <SmallToggle
              active={required}
              onClick={() => toggleRequiredField({ id, required })}
            />
          </Div>
          <DeleteIcon
            tooltip="Remove Field"
            mr={2}
            color={{ default: "black", hover: "primary7" }}
            onClick={() => removeRow({ id })}
          />
          <SettingsIcon
            tooltip="Edit Field"
            mr={5}
            color={{ default: "black", hover: "primary7" }}
            onClick={() => editField(id)}
          />
        </Div>
      </Div>
    </Div>
  );
};

const DraggableField = ({ isHeader, ...otherProps }) =>
  !isHeader ? (
    <QuestionRow {...otherProps} />
  ) : (
    <HeaderSectionFields {...otherProps} />
  );

const DraggableRowCards = Cards(DraggableField, "DRAGGABLE_ROWS");

const NotDraggableRow = ({
  name,
  description,
  updateDescription,
  id,
  tempDescription,
  setTempDescription,
  tempAlias,
  setTempAlias,
  alias,
  updateAlias,
  moduleLabel
}) => {
  return (
    <Div
      display="row.space-between.center"
      height={53}
      bg="neutral1"
      bra={1}
      my={2}
      className="hoverContainer"
    >
      <Div ml={6}>
        <LabelPopover
          name={name}
          id={id}
          tempAlias={tempAlias}
          setTempAlias={setTempAlias}
          alias={alias}
          moduleLabel={moduleLabel}
          updateAlias={() => {
            updateAlias({
              id,
              description: R.propOr("", "text", tempAlias)
            });
          }}
          bgDefault="neutral1"
          bgHover="neutral2"
        />
      </Div>
      <Div display="row.flex-start.center">
        <DescriptionPopover
          description={description}
          id={id}
          tempDescription={tempDescription}
          setTempDescription={setTempDescription}
          updateDescription={() => {
            updateDescription({
              id,
              description: R.propOr("", "text", tempDescription)
            });
          }}
        />
        <Div mr={4} width={68} display="row.center.center">
          <CheckIcon color="altA7" />
        </Div>
        <Div width={59} />
      </Div>
    </Div>
  );
};

const Fields = ({
  requiredFields = [],
  draggableFields,
  removeRow,
  toggleRequiredField,
  reorderFields,
  updateDescription,
  tempDescription,
  setTempDescription,
  addField,
  editField,
  addHeaderSection = () => {},
  showAddFieldModal,
  removeHeaderSection,
  updateDescriptionHeaderSection,
  updateTitleHeaderSection,
  tempAlias,
  setTempAlias,
  updateAlias,
  showFieldsModal
}) => {
  return (
    <Div>
      <Div display="row.space-between.center">
        <Text2 color="neutral6" bold>
          LABEL
        </Text2>
        <Div display="row.flex-start.center">
          <Text2 color="neutral6" bold mr={4}>
            HELP TEXT
          </Text2>
          <Text2 color="neutral6" bold mr={4}>
            REQUIRED
          </Text2>
          <Text2 color="neutral6" bold>
            ACTIONS
          </Text2>
        </Div>
      </Div>

      {R.map(
        field => (
          <NotDraggableRow
            key={field.id}
            {...field}
            updateDescription={updateDescription}
            tempDescription={tempDescription}
            setTempDescription={setTempDescription}
            tempAlias={tempAlias}
            setTempAlias={setTempAlias}
            updateAlias={updateAlias}
          />
        ),
        requiredFields
      )}
      <DraggableRowCards
        cards={draggableFields}
        removeRow={removeRow}
        toggleRequiredField={toggleRequiredField}
        onReorder={orderedFields =>
          reorderFields([...requiredFields, ...orderedFields])
        }
        updateDescription={updateDescription}
        tempDescription={tempDescription}
        setTempDescription={setTempDescription}
        editField={editField}
        addHeaderSection={addHeaderSection}
        removeHeaderSection={removeHeaderSection}
        updateDescriptionHeaderSection={updateDescriptionHeaderSection}
        updateTitleHeaderSection={updateTitleHeaderSection}
        tempAlias={tempAlias}
        setTempAlias={setTempAlias}
        updateAlias={updateAlias}
      />

      <Div display="row.flex-start.center" mt={2}>
        <BigOutlineButton LeftIcon={AddCircleIcon} onClick={showFieldsModal}>
          Select Fields
        </BigOutlineButton>

        <PopoverMenu
          Label={({ onClick }) => (
            <BigOutlineButton ml={2} LeftIcon={AddCircleIcon} onClick={onClick}>
              Add Field
            </BigOutlineButton>
          )}
          menuItems={[
            ["Add field", () => showAddFieldModal(STANDARD_MODULES.contacts.id)]
            /*
            // Future: Reveal when group fields are needed
            [
              "Add person field",
              () => showAddFieldModal(STANDARD_MODULES.contacts.id)
            ],
            [
              "Add group field",
              () => showAddFieldModal(STANDARD_MODULES.accounts.id)
            ]
            */
          ]}
        />
      </Div>
    </Div>
  );
};

export default decorate(Fields);
