import React from "react";

import { connect } from "react-redux";

import { actions } from "../index";

import {
  Div,
  BigFilledButton,
  BigOutlineButton,
  BigTextButton
} from "components/Base";

const decorate = connect(null, {
  updateTemplate: actions.updateTemplate,
  deleteTemplate: actions.deleteTemplate
});

const Footer = ({ hideModal, templateId, updateTemplate, deleteTemplate }) => (
  <Div
    width={1}
    bt={1}
    bc="neutral1"
    display="row.space-between.center"
    px={4}
    height={80}
  >
    <Div display="row.flex-start.center">
      <BigFilledButton
        bg="altB4"
        color="white"
        mr={3}
        onClick={() => updateTemplate({ onDone: hideModal })}
      >
        Save
      </BigFilledButton>
      <BigOutlineButton width={121} onClick={hideModal}>
        Close
      </BigOutlineButton>
    </Div>

    <BigTextButton
      color="red"
      onClick={() => deleteTemplate({ onDone: hideModal, templateId })}
    >
      Delete Report Template
    </BigTextButton>
  </Div>
);

export default decorate(Footer);
