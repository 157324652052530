import * as R from "ramda";

import { Div, basePropTypesLess } from "../Base/index";
import { mapProps } from "../utils";

const makeText = (a, b, c) =>
  mapProps(({ fs, fw, bold, primary, ...props }) => ({
    fs: a,
    fw: typeof fw === "number" ? fw : bold ? b : c,
    color: primary ? "primary8" : "neutral7",
    ...props
  }))(Div);

const Text0 = makeText(0, 3, 2);
const Text1 = makeText(1, 3, 2);
const Text2 = makeText(2, 3, 2);
const Text3 = makeText(3, 3, 2);
const Text4 = makeText(4, 4, 2);
const Text5 = makeText(5, 3, 1);
const Text6 = makeText(6, 3, 1);

R.forEach(
  Comp => {
    Comp.propTypes = basePropTypesLess(["fs"]);
  },
  [Text0, Text1, Text2, Text3, Text4, Text5, Text6]
);

export { Text0, Text1, Text2, Text3, Text4, Text5, Text6 };
