import React, { Component } from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import Fuse from "fuse.js";
import View from "./View";
import CircularProgress from "material-ui/CircularProgress";
import {
  catalogCategories,
  catalogItems
} from "@lennd/value-types/src/constants/standard-modules";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import ViewRecordModal from "components/Global/Module/Modals/ViewRecord";
import DeleteItemModal from "../Modals/DeleteItem";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { CATALOG_ITEMS } from "utils/standard-module-field-ids";
const mapIndexed = R.addIndex(R.map);

const searchWithFuse = (searchTerm, fuse, opt, matchWith) => {
  if (R.isNil(searchTerm) || R.isEmpty(searchTerm)) {
    return opt;
  }
  return R.compose(
    R.reject(R.isNil),
    R.map(o => R.find(R.propEq(matchWith, o.id), opt))
  )(fuse.search(searchTerm));
};

class Controller extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      loading: true,
      activeTabValue: props.route.name
    };
  }

  componentDidMount() {
    this.itemsFuse = new Fuse(this.props.items, {
      threshold: 0.3,
      keys: ["name"],
      shouldSort: true
    });

    return Promise.all([
      this.getCatalogCategories(),
      this.getCatalogItems()
    ]).then(() => {
      this.setState({ loading: false });
    });
  }

  componentDidUpdate() {
    this.itemsFuse = new Fuse(this.props.items, {
      threshold: 0.3,
      keys: ["name"],
      shouldSort: true
    });
  }

  getCatalogCategories = (refreshEvent = false) => {
    const { eventId } = this.props.params;
    this.props.getRecords({
      moduleId: catalogCategories.id,
      options: { eventId }
    });

    if (refreshEvent) {
      this.props.getEvent(eventId);
    }
  };

  getCatalogItems = () => {
    const { eventId } = this.props.params;
    this.props.getRecords({
      moduleId: catalogItems.id,
      options: { eventId }
    });
  };

  onSearch = searchTerm => this.setState({ searchTerm });

  filterRecords = records => {
    const { parentRecordId } = this.props.params;
    if (parentRecordId) {
      return records.filter(record =>
        Object.keys(record.values).find(
          id =>
            record.values[id].type === "lookup" &&
            record.values[id].value.records.includes(parentRecordId)
        )
      );
    }
    return records;
  };

  goToInventory = () => {
    this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/inventory`
    });
  };

  showAddCategoryModal = () => {
    this.props.showModal({
      content: (
        <AddRecordModal
          orgId={this.props.params.orgId}
          eventId={this.props.params.eventId}
          moduleId={catalogCategories.id}
          onSave={() => this.getCatalogCategories(true)}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showViewCategoryModal = id => {
    this.props.showModal({
      content: (
        <ViewRecordModal
          moduleId={catalogCategories.id}
          recordId={id}
          onClose={() => {
            this.getCatalogCategories(true);
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  deleteCategory = async id => {
    await this.props.deleteRecord({
      moduleId: catalogCategories.id,
      eventId: this.props.eventDetails.id,
      record: { id },
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId
      }
    });

    return this.getCatalogCategories(true);
  };

  showAddItemModal = categoryId => {
    this.props.showModal({
      content: (
        <AddRecordModal
          orgId={this.props.params.orgId}
          eventId={this.props.params.eventId}
          moduleId={catalogItems.id}
          onSave={() => this.getCatalogItems()}
          values={
            categoryId
              ? {
                  [CATALOG_ITEMS.CATEGORY]: {
                    type: "lookup",
                    value: {
                      moduleId: catalogCategories.id,
                      records: [categoryId]
                    }
                  }
                }
              : {}
          }
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showViewItemModal = id => {
    this.props.showModal({
      content: (
        <ViewRecordModal
          moduleId={catalogItems.id}
          recordId={id}
          onClose={() => {
            this.getCatalogItems();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  deleteItem = item => {
    this.props.showModal({
      content: (
        <DeleteItemModal
          name={item.name}
          handleDelete={async () => {
            await this.props.deleteRecord({
              moduleId: catalogItems.id,
              eventId: this.props.eventDetails.id,
              record: { id: item.id },
              options: {
                orgId: this.props.params.orgId,
                eventId: this.props.params.eventId
              }
            });

            return this.getCatalogItems();
          }}
          hideModal={this.props.hideModal}
        />
      )
    });
  };

  render() {
    const { loading, activeTabValue, searchTerm } = this.state;
    const { catalogCategories } = this.props;

    if (loading) {
      return <CircularProgress color="#ccc" />;
    }

    const categoriesWithHandlers = R.map(category => {
      const items =
        searchWithFuse(searchTerm, this.itemsFuse, category.items, "id") || [];
      return {
        id: category.id,
        name: category.name,
        countOfItems: items.length,
        onEdit: () => this.showViewCategoryModal(category.id),
        onDelete: items.length
          ? undefined
          : () => this.deleteCategory(category.id),
        onAddItem: () => this.showAddItemModal(category.id),
        collapsedDefault: !items.length,
        forceCollapse: searchTerm.length && !items.length,
        items: R.map(item => ({
          id: item.id,
          name: item.name,
          onClick: () => this.showViewItemModal(item.id),
          onEdit: () => this.showViewItemModal(item.id),
          onDelete: () => this.deleteItem(item)
        }))(items)
      };
    })(catalogCategories);

    const tabs = [["editEventSettingsInventoryCatalog", "Catalog"]];

    return (
      <View
        {...{
          loading,
          tabs,
          activeTabValue,
          goToInventory: this.goToInventory,
          categories: categoriesWithHandlers,
          onSearch: this.onSearch,
          showAddCategoryModal: this.showAddCategoryModal,
          showAddItemModal: this.showAddItemModal
        }}
      />
    );
  }
}

Controller.propTypes = {};

export default Controller;
