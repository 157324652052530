import * as R from "ramda";
import { LABELS } from "utils/item-types";
import { createSelector } from "reselect";

import { getters } from "./index";

export const getItemTypesWithHandlers = createSelector(
  getters.types,
  getters.typeId,
  (types, typeId) => {
    return R.map(type => {
      const meta = LABELS[type.id];
      return {
        id: type.id,
        name: type.name,
        color: meta.color,
        icon: meta.icon,
        active: typeId === type.id,
        countOfCategories: R.compose(
          R.length,
          R.prop("groups")
        )(type),
        countOfItems: R.compose(
          R.length,
          R.flatten,
          R.map(R.prop("items")),
          R.prop("groups")
        )(type)
      };
    }, types);
  }
);

export const getShouldShowZones = createSelector(
  getters.types,
  getters.typeId,
  (types, typeId) => {
    if (R.isEmpty(typeId) || R.isEmpty(types)) {
      return false;
    }
    return R.compose(
      R.propEq("name", "Passes"),
      R.find(R.propEq("id", typeId))
    )(types);
  }
);

export const getShowSidebar = createSelector(
  getters.routeName,
  routeName => !["editEventSettingsCatalogQuestions"].includes(routeName)
);

export const getTypeName = createSelector(
  getters.types,
  getters.typeId,
  (types, typeId) =>
    R.compose(
      R.propOr("", "name"),
      R.find(({ id }) => id === typeId)
    )(types)
);
