import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const SidebarTitle = ({ children, ...props }) => (
  <div styleName="title" {...props}>
    {children}
  </div>
);

export default CSSModules(SidebarTitle, css);
