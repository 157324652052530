import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { Div, FontIcon, Text3 } from "components/Base";
import { WIDTH, TABS } from "Portal/Manage/constants";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { withRouter } from "react-router";
import { capitalizeFirst } from "utils/General";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";
import { createSelector } from "reselect";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { canUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

export const getTabs = createSelector(
  state => canUserDo(state, getEventId(state)),
  state => selectFeatureFlag(flags.CAN_VIEW_ALERTS.NAME)(state),
  (canDo, canViewAlerts) => {
    return [
      TABS.PORTALS,
      canViewAlerts &&
      canDo(`${STANDARD_MODULE_IDS.portal.id}_can_manage_portal_pages`)
        ? TABS.PAGES
        : null,
      canViewAlerts &&
      canDo(`${STANDARD_MODULE_IDS.portal.id}_can_manage_portal_alerts`)
        ? TABS.ALERTS
        : null,
      TABS.CONTENT
    ].filter(t => t);
  }
);

const decorate = connect(state => ({
  eventId: getEventId(state),
  tabs: getTabs(state)
}));

const Header = ({ currentRoute, router, eventId, tabs }) => (
  <Div width={1} bg="white" display="row.center.flex-start" pt={5} shadow={1}>
    <Div
      width={1}
      style={{
        maxWidth: WIDTH
      }}
    >
      <Div
        display="row.flex-start.center"
        style={{
          marginBottom: "28px"
        }}
      >
        <Div
          width={35}
          height={35}
          bg="primary8"
          display="row.center.center"
          bra={2}
        >
          <FontIcon color="white" fs={5}>
            pages
          </FontIcon>
        </Div>
        <Div color="black" fs={6} fw={2} ml={2} mr={4}>
          Portals
        </Div>
      </Div>
      <Div display="row.space-between.center" mt={6}>
        <Div display="row.flex-start.center">
          {R.map(
            tab => (
              <Text3
                key={tab}
                color={currentRoute === tab ? "primary8" : "black"}
                bb={2}
                bc={currentRoute === tab ? "primary8" : "transparent"}
                pb={1}
                bold
                mr={6}
                onClick={() =>
                  router.push(
                    `/event/${eventId}/portals/${
                      tab !== TABS.PORTALS ? tab : ""
                    }`
                  )
                }
              >
                {capitalizeFirst(tab)}
              </Text3>
            ),
            R.values(tabs)
          )}
        </Div>
      </Div>
    </Div>
  </Div>
);

export default R.compose(
  withRouter,
  decorate
)(Header);
