import React, { Component } from "react";
import * as R from "ramda";
import View from "./View";
import { isEmptyOrNil } from "utils/General";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import WorkflowModal from "components/Event/Settings/ApprovalWorkflows/Workflow/index";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

const SAMPLE_MODULE_ID = STANDARD_MODULE_IDS.accounts.id;

class Controller extends Component {
  componentDidMount() {
    this.getSpace();
  }

  getSpace = () => {
    this.props.getSpace({
      eventId: this.props.eventDetails.id,
      key: this.props.spaceKey,
      type: this.props.spaceType,
      moduleId: SAMPLE_MODULE_ID
    });
  };

  updateSpace = data => {
    return this.props.updateSpace({
      eventId: this.props.eventDetails.id,
      type: this.props.spaceType,
      moduleId: SAMPLE_MODULE_ID,
      ...data
    });
  };

  showWorkflowModal = workflowId => {
    this.props.showModal({
      content: (
        <WorkflowModal
          spaceKey={this.props.spaceKey}
          spaceId={this.props.space.id}
          workflowId={workflowId}
          getSpace={this.getSpace}
          toggleDefaultIsAny={this.toggleDefaultIsAny}
          onDone={() => {}}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  reorderWorkflows = newOrder => {
    return this.props
      .updateWorkflows({
        spaceId: this.props.space.id,
        workflows: newOrder.map(({ id }, i) => ({ id, order: i + 1 }))
      })
      .then(this.getSpace);
  };

  addWorkflow = () => {
    return this.props
      .addWorkflow({
        spaceId: this.props.space.id,
        name: "Custom Workflow",
        order: R.length(this.props.space.workflows) + 1 || 1
      })
      .then(this.getSpace);
  };

  toggleWorkflows = () => {
    if (this.props.space.is_enabled) {
      return this.deactivateWorkflows();
    }
    return this.activateWorkflows();
  };

  activateWorkflows = async () => {
    await this.updateSpace({
      isEnabled: true
    });

    return this.getSpace();
  };

  deactivateWorkflows = async () => {
    await this.updateSpace({
      isEnabled: false
    });

    return this.getSpace();
  };

  toggleDefaultIsAny = async isAny => {
    await this.updateSpace({
      isAny
    });

    return this.getSpace();
  };

  updateWorkflowActive = (newState, workflowId) => {
    return this.props
      .updateWorkflow({
        spaceId: this.props.space.id,
        workflow: {
          id: workflowId,
          isEnabled: newState
        }
      })
      .then(this.getSpace);
  };

  deleteWorkflow = workflowId => {
    return this.props
      .deleteWorkflow({
        spaceId: this.props.space.id,
        workflowId
      })
      .then(this.getSpace);
  };

  // TODO: back end support for duplication
  // duplicateWorkflow = workflow => {
  //   const spreadProps = R.omit(["id", "order", "name"], workflow);
  //   const newWorkflowProps = {
  //     ...spreadProps,
  //     order: R.length(this.props.space.workflows) + 1,
  //     name: `${workflow.name} 2`
  //   };
  //   return this.props
  //     .addWorkflow({
  //       spaceId: this.props.space.id,
  //       ...newWorkflowProps
  //     })
  //     .then(this.getSpace);
  // };

  render() {
    return (
      <View
        {...{
          workflowsAreActive: this.props.space.is_enabled,
          workflows: R.map(workflow => {
            const hasTrigger = !isEmptyOrNil(workflow.triggers);
            const hasApprover = !isEmptyOrNil(workflow.blocks);
            const hasTriggerAndApproval = hasApprover && hasTrigger;

            return {
              ...workflow,
              active: workflow.is_enabled,
              hasTriggerAndApproval,
              openWorkflow: () => this.showWorkflowModal(workflow.id),
              duplicateWorkflow: () => this.duplicateWorkflow(workflow),
              deleteWorkflow: () => this.deleteWorkflow(workflow.id),
              toggleWorkflow: () =>
                hasTriggerAndApproval
                  ? this.updateWorkflowActive(!workflow.is_enabled, workflow.id)
                  : () => {}
            };
          })(this.props.space.workflows || []),
          defaultWorkflowSelected: false,
          toggleWorkflows: this.toggleWorkflows,
          openDefaultWorkflow: () => this.showWorkflowModal("default"),
          addWorkflow: this.addWorkflow,
          reorderWorkflows: this.reorderWorkflows
        }}
      />
    );
  }
}

export default Controller;
