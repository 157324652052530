import React from "react";

import { connect } from "react-redux";

import { actions, getters } from "Modules/SubmitFormModal";

import {
  Div,
  BigClearInput,
  SearchIcon,
  MediumFilledButton,
  Text3
} from "components/Base";

import { SORTING } from "Modules/SubmitFormModal/constants";

const decorate = connect(
  state => ({
    filterForm: getters.filterForm(state),
    selectedSorting: getters.selectedSorting(state)
  }),
  {
    setFilterForm: actions.setFilterForm,
    setSelectedSorting: actions.setSelectedSorting
  }
);

const FilteringAndSorting = ({
  filterForm,
  setFilterForm,
  selectedSorting,
  setSelectedSorting
}) => (
  <Div width={1} bc="neutral3" bb={1} display="row.flex-start.center" p={4}>
    <Div flex={1}>
      <BigClearInput
        LeftIcon={SearchIcon}
        placeholder="Search..."
        width={1}
        value={filterForm}
        continuous
        onChange={value => setFilterForm(value)}
      />
    </Div>
    <Div display="row.flex-start.center" ml={2}>
      <Text3 color="black">Sort:</Text3>
      <MediumFilledButton
        ml={3}
        color={selectedSorting === SORTING.POPULAR ? "white" : "black"}
        bg={selectedSorting === SORTING.POPULAR ? "primary7" : "neutral3"}
        onClick={() => setSelectedSorting(SORTING.POPULAR)}
      >
        {SORTING.POPULAR}
      </MediumFilledButton>
      <MediumFilledButton
        ml={2}
        color={selectedSorting === SORTING.AZ ? "white" : "black"}
        bg={selectedSorting === SORTING.AZ ? "primary7" : "neutral3"}
        onClick={() => setSelectedSorting(SORTING.AZ)}
      >
        {SORTING.AZ}
      </MediumFilledButton>
    </Div>
  </Div>
);

export default decorate(FilteringAndSorting);
