import React from "react";
import { connect } from "react-redux";
import AddPageModal from "Portal/Settings/AddPageModal/View";
import { actions, getters } from "../../model";

const decorate = connect(
  state => ({
    showPageModal: getters.showPageModal(state),
    selectedModalId: getters.selectedModalId(state)
  }),
  {
    hideAddPageModal: () => actions.setShowPageModal(false)
  }
);

const PageModal = ({ showPageModal, hideAddPageModal, selectedModalId }) =>
  showPageModal ? (
    <AddPageModal hideModal={hideAddPageModal} id={selectedModalId} />
  ) : null;

export default decorate(PageModal);
