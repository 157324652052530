import React from "react";
import { connect } from "react-redux";
import { getters } from "Modules/ImportModal";
import { Div, LoadingIcon, Text4, Text5 } from "components/Base";
import { getTimeRemaining } from "Modules/ImportModal/selectors";

const decorate = connect(state => ({
  ratioBar: getters.ratioBar(state),
  remainingTime: getTimeRemaining(state)
}));

const Loading = ({ ratioBar, remainingTime }) => (
  <Div
    display="row.center.center"
    flex={1}
    style={{
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,0.3)",
      zIndex: 5
    }}
  >
    <Div
      display="column.space-between.center"
      width={398}
      height={164}
      bra={1}
      bg="white"
      p={5}
    >
      <Div display="column.flex-start.center">
        <Text5 bold>Processing Data...</Text5>
        <Text4>{remainingTime}</Text4>
      </Div>
      <LoadingIcon size={32} color="neutral4" />
      <Div
        bg="neutral3"
        display="row.flex-start.center"
        height={13}
        width={353}
        bra={3}
        px={1}
      >
        <Div
          bg="success6"
          height={7}
          flex={ratioBar}
          bra={3}
          style={{ transition: "all 0.3s ease-in-out" }}
        />
      </Div>
    </Div>
  </Div>
);

export default decorate(Loading);
