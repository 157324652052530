import React from "react";
import { connect } from "react-redux";
import { noop } from "utils/General";

import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";

import { Div, Modal } from "components/Base";

import { actions, getters } from "SalesPortal/TypesModal";
import TypesModal from "./index";

const decorate = connect(
  state => ({
    showModal: getters.showModal(state)
  }),
  {
    hideModal: actions.hideModal
  }
);

const Wrapper = ({ showModal, hideModal = noop }) => (
  <Modal isPortal background="translucent" isModalShowing={showModal}>
    <Div width={1}>
      <StyleWrapper
        heading="Select pass types to show"
        hideModal={hideModal}
        width={600}
        height={800}
        bodyStyles={{ padding: 0 }}
      >
        <TypesModal hideModal={hideModal} />
      </StyleWrapper>
    </Div>
  </Modal>
);

export default decorate(Wrapper);
