/* eslint-disable react/prop-types */
import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import {
  Text3,
  Div,
  BigFilledButton,
  BigOutlineButton,
  ReplyIcon
} from "components/Base";

import {
  getShowFooter,
  getFooterButtons,
  getFooterCaption,
  getCountOfRowsWithErrors
} from "Orders/ImportModal/selectors";

import { isImportBtnDisabled } from "Orders/ImportModal/selectors/selectItems";

import { hideModal } from "redux/modules/modal/actions";

import { actions } from "Orders/ImportModal";
import { WIZARD_PAGE } from "Orders/ImportModal/constants";

import { BUTTON_TYPES } from "Orders/ImportModal/constants";
import { withProps } from "utils/General";

const buttonsMap = {
  [BUTTON_TYPES.DOWNLOAD]: ({ handlers }) => (
    <BigFilledButton bg="altB5" onClick={() => handlers.download()} ml={2}>
      Download
    </BigFilledButton>
  ),
  [BUTTON_TYPES.CANCEL]: ({ handlers }) => (
    <BigOutlineButton onClick={() => handlers.cancel()} ml={2}>
      Cancel
    </BigOutlineButton>
  ),
  [BUTTON_TYPES.BACK]: ({ handlers }) => (
    <BigOutlineButton onClick={() => handlers.goBack()} ml={2}>
      Back
    </BigOutlineButton>
  ),
  [BUTTON_TYPES.DONE]: ({ handlers }) => (
    <BigFilledButton bg="primary7" onClick={() => handlers.done()} ml={2}>
      Done
    </BigFilledButton>
  ),
  [BUTTON_TYPES.IMPORT_VALID]: ({ handlers }) => (
    <BigFilledButton
      bg="primary7"
      onClick={() => handlers.importValid()}
      ml={2}
    >
      Import Only Valid Rows
    </BigFilledButton>
  ),
  [BUTTON_TYPES.IMPORT_ALL]: ({ handlers }) => (
    <BigFilledButton
      bg="primary7"
      onClick={() => handlers.importValid()}
      ml={2}
    >
      Import
    </BigFilledButton>
  ),
  [BUTTON_TYPES.IMPORT]: ({ handlers, buttons }) => (
    <BigFilledButton
      bg="altB5"
      onClick={() => handlers.import()}
      disabled={buttons.importDisabled}
      ml={2}
    >
      Import
    </BigFilledButton>
  ),
  [BUTTON_TYPES.REVIEW]: ({ handlers, buttons }) => (
    <BigFilledButton
      bg="altB5"
      onClick={() => handlers.import()}
      disabled={buttons.importDisabled}
      ml={2}
    >
      Review
    </BigFilledButton>
  ),
  [BUTTON_TYPES.UNDO]: ({ handlers }) => (
    <BigOutlineButton
      LeftIcon={ReplyIcon}
      onClick={() => handlers.undo()}
      ml={2}
    >
      Undo & Revert All Imported Data
    </BigOutlineButton>
  ),
  [BUTTON_TYPES.UPLOAD_RESOLVED]: ({ handlers }) => (
    <BigOutlineButton
      LeftIcon={ReplyIcon}
      onClick={() => handlers.uploadResolved()}
      ml={2}
    >
      Upload Resolved Skipped Rows
    </BigOutlineButton>
  ),
  [BUTTON_TYPES.RE_UPLOAD_RESOLVED]: ({ handlers }) => (
    <BigOutlineButton
      LeftIcon={ReplyIcon}
      onClick={() => handlers.reUploadResolved()}
      ml={2}
    >
      Re-Upload .CSV
    </BigOutlineButton>
  ),
  [BUTTON_TYPES.CAPTION]: ({ caption = "" }) => (
    <Text3 bold ml={2}>
      {caption}
    </Text3>
  )
};

const decorate = R.compose(
  connect(
    state => ({
      showFooter: getShowFooter(state),
      components: getFooterButtons(state),
      caption: getFooterCaption(state),
      importDisabled: isImportBtnDisabled(state),
      errors: getCountOfRowsWithErrors(state)
    }),
    {
      cancel: hideModal,
      done: hideModal,
      download: actions.downloadCustomizedRequest,
      import: actions.saveCsvData,
      goBack: () => actions.setWizardPage(WIZARD_PAGE.BULK_IMPORT),
      reUploadResolved: actions.uploadFile,
      importValid: actions.importValid,
      uploadResolved: actions.uploadResolved
    }
  ),
  withProps(props => ({
    buttons: R.pick(["importDisabled"], props),
    handlers: R.pick(
      [
        "cancel",
        "done",
        "download",
        "import",
        "importValid",
        "goBack",
        "reUploadResolved",
        "uploadResolved"
      ],
      props
    )
  }))
);

const Footer = ({
  showFooter = true,
  components = [],
  caption,
  buttons = {},
  handlers = {},
  errors
}) =>
  showFooter ? (
    <Div
      display="row.space-between.center"
      p={3}
      bt={1}
      bc="neutral4"
      bg="white"
      style={{ position: "relative" }}
    >
      {components.map((button, i) =>
        Array.isArray(button) ? (
          <Div display="row.flex-start.center" key={i}>
            {button.map((b, j) =>
              React.createElement(buttonsMap[b], {
                key: j,
                caption,
                handlers,
                buttons
              })
            )}
          </Div>
        ) : (
          React.createElement(buttonsMap[button], {
            key: i,
            caption,
            handlers,
            buttons,
            errors
          })
        )
      )}
    </Div>
  ) : null;

export default decorate(Footer);
