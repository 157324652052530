import * as R from "ramda";
import { createSelector } from "reselect";

import { MODULE_TABS, SCHEDULE_TABS } from "./constants";

import { getters } from "Modules/SubscribeModal";

export const getTabs = createSelector(
  getters.type,
  getters.selectedTabId,
  (type, selectedTabId) => {
    return R.map(tab => ({
      ...tab,
      active: tab.id === selectedTabId
    }))(
      R.propOr(MODULE_TABS, type)({
        module: MODULE_TABS,
        schedule: SCHEDULE_TABS
      })
    );
  }
);

export const getXmlUrl = createSelector(
  getters.subscriptionUrl,
  url => `${url}.xml`
);

export const getJsonUrl = createSelector(
  getters.subscriptionUrl,
  url => `${url}.json`
);

export const getCalendarUrl = createSelector(
  getters.subscriptionUrl,
  url => {
    const fullUrl = R.replace(/https|http/, "webcal", url);
    return `${fullUrl}.ics`;
  }
);
