import * as R from "ramda";
import { withStateHandlers, mapProps } from "../utils";

const ASC = "Asc";
const DESC = "Desc";

const sortAsc = key => R.sortBy(R.prop(key));
const sortDesc = key => x => R.reverse(R.sortBy(R.prop(key), x));

// sortFunc = (sorterFromTableSorter, allProps) => {returns propsModifiedBySorter}
// sorterFromTableSorter = (arr) => arrSortedByKeySpecifiedByToggleSort
// toggleSort = (key) => setsSortKeyAndSortDirection

const keySorter = sortFunc =>
  R.compose(
    withStateHandlers(
      ({ defaultSortKey = null, defaultSortDirection = ASC }) => ({
        sortKey: defaultSortKey,
        sortDirection: defaultSortDirection
      }),
      {
        toggleSort: ({
          defaultSortKey = null,
          defaultSortDirection = ASC,
          sortDirection,
          sortKey
        }) => key => {
          if (key !== sortKey) {
            return {
              sortKey: key,
              sortDirection: ASC
            };
          }

          if (sortDirection === ASC) {
            return {
              sortKey: key,
              sortDirection: DESC
            };
          } else if (sortDirection === DESC) {
            return {
              sortKey: defaultSortKey,
              sortDirection: defaultSortDirection
            };
          }
          return {
            sortKey: key,
            sortDirection: ASC
          };
        }
      }
    ),
    mapProps(props => {
      const { sortKey, sortDirection } = props;
      if (!R.isNil(sortKey)) {
        if (sortDirection === ASC) {
          return sortFunc(sortAsc(sortKey), props);
        } else if (sortDirection === DESC) {
          return sortFunc(sortDesc(sortKey), props);
        }
      }

      return props;
    })
  );

export { keySorter };
