import React from "react";
import { Div } from "components/Base";
import StatusAndItems from "./StatusAndItems";
import PersonalInfo from "./PersonalInfo";
import { Small, Medium, Big } from "./MediaQueryComponents";
import { noop } from "utils/General";

const Error = ({ show, message }) =>
  show && message && message.length ? (
    <Div
      width={1}
      display="row.center.center"
      fw={3}
      bg="danger3"
      color="gray7"
      p={3}
      bra={1}
      mb={4}
    >
      {message}
    </Div>
  ) : null;

const Structure = ({
  showItemBlockError = false,
  messageError = "",
  readOnly = false,
  formattedSummary = [],
  requestableItems = [],
  removeLineItem = noop,
  leftContent = null,
  isBig = false,
  isSmall = false
}) => {
  const StatusPersonal = () => (
    <>
      <StatusAndItems
        {...{
          readOnly,
          formattedSummary,
          requestableItems,
          removeLineItem
        }}
      />
      <PersonalInfo leftContent={leftContent} />
    </>
  );

  return (
    <Div
      bg="neutral0"
      width={1}
      flex={1}
      style={{
        overflowY: "auto",
        height: "calc(100vh - 237px)",
        maxHeight: isSmall ? "565px" : "580px",
        backgroundColor: "#F8F8F8"
      }}
      {...(isBig ? { py: "4", pl: "7", pr: "10" } : { p: "5" })}
    >
      <Error show={showItemBlockError} message={messageError} />

      {isBig && (
        <Div width={1} flex={1} display="row.space-between">
          <StatusPersonal />
        </Div>
      )}

      {!isBig && <StatusPersonal />}
    </Div>
  );
};

const Body = props => (
  <>
    <Small>
      <Structure {...props} isSmall={true}></Structure>
    </Small>
    <Medium>
      <Structure {...props}></Structure>
    </Medium>
    <Big>
      <Structure {...props} isBig={true}></Structure>
    </Big>
  </>
);

export default Body;
