import React from "react";
import { connect } from "react-redux";
import { getSearchPlaceholder } from "../selectors";

import UISearchBar from "ui-kit/SearchBar/View";

import { VARIANTS } from "ui-kit/Theme/constants";

const decorate = connect((state, props) => ({
  searchPlaceholder: getSearchPlaceholder(state, props)
}));

const SearchBar = ({
  searchPlaceholder = "Search by name, email or group affiliation",
  instanceId
}) => (
  <UISearchBar
    instanceId={instanceId}
    variant={VARIANTS.BACKGROUND}
    flex={1}
    mr={2}
    placeholder={searchPlaceholder}
    height={43}
    style={{
      maxWidth: 445
    }}
  />
);

export default decorate(SearchBar);
