import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import resolveFormatter from "components/Global/Table3/CellFormatters/utils/resolveFormatter";
import { get } from "lodash";
import Header from "../Header";
import { DEFAULT_APPROVAL_FIELD_APPROVE_REJECT } from "components/Event/FormsV2/constants";

class Card extends PureComponent {
  render() {
    const {
      collapsed,
      duplicateRow,
      filteredFields,
      getRowMetaData,
      index,
      onCollapse,
      removeSelectedRows,
      row,
      showMenu,
      styles
    } = this.props;

    const reviewStatus = get(
      row.values[DEFAULT_APPROVAL_FIELD_APPROVE_REJECT.id],
      "value"
    );

    return (
      <div>
        <Header
          rowId={row.id}
          status={reviewStatus}
          index={index}
          collapsed={collapsed}
          onCollapse={onCollapse}
          showMenu={showMenu}
          duplicateRow={duplicateRow}
          removeSelectedRows={removeSelectedRows}
        />
        <div styleName="cardInner">
          {filteredFields.map(field => {
            const Formatter = resolveFormatter(field);
            return (
              <label
                key={`${row.id}-${field.id}`}
                className={[css.entry, field.type].join(" ")}
              >
                <div styleName="label">{field.name}</div>
                <div styleName="value">
                  {get(row.values, `${field.id}.value`) ? (
                    <Formatter
                      value={row.values[field.id]}
                      dependentValues={getRowMetaData(row, field)}
                    />
                  ) : (
                    <span styleName="noResponse">None</span>
                  )}
                </div>
              </label>
            );
          })}
        </div>
      </div>
    );
  }
}

Card.propTypes = {
  filteredFields: PropTypes.array.isRequired,
  getRowMetaData: PropTypes.func.isRequired,
  invalidRows: PropTypes.array.isRequired,
  collapsed: PropTypes.bool,
  onEdit: PropTypes.func,
  onCollapse: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired
};

export default CSSModules(Card, css);
