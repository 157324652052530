import React from "react";
import { Div, RightIcon } from "components/Base";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import * as R from "ramda";
import {
  filteredNewsAndAlertsBySelection,
  newsAndAlertsDates,
  getAlertsTitle,
  getAlertsSubtitle,
  getAlertsViewAllLabel,
  getHasEnabledUpdateNotifications,
  getHasEnabledPhoneNumber
} from "../selectors";
import { PAGE_ACTIONS, MAX_NUMBER_OF_ALERTS } from "../constants";
import { withRouter } from "react-router";
import css from "./styles.scss";
import CSSModules from "react-css-modules";

const mapIndexed = R.addIndex(R.map);

const RenderDayButtons = ({
  countOfDates,
  dates,
  selectedDate,
  setSelectedSessionDate,
  onViewMore,
  ...otherProps
}) => (
  <Div display="row.flex-start.center" {...otherProps}>
    {mapIndexed((date, index) => {
      if (index === selectedDate) {
        return (
          <Div
            key={`${date}-${index}`}
            display="row.center.center"
            color="black"
            onClick={() => setSelectedSessionDate(index)}
            bg={{
              default: "#E2E2E2",
              hover: "#DDD"
            }}
            uppercase
            style={{
              height: "36px",
              width: "64px",
              border: "1px solid #E6E6E6",
              borderRadius: "6px",
              color: "#222222",
              fontFamily: "Roboto Condensed",
              fontSize: "14px",
              letterSpacing: "0.4px",
              marginRight: "8px"
            }}
          >
            {date}
          </Div>
        );
      } else {
        return (
          <Div
            key={`${date}-${index}`}
            display="row.center.center"
            mr={2}
            bg={{
              default: "inherit",
              hover: "#E2E2E2"
            }}
            color={{
              default: "#1789EB",
              hover: "#222222"
            }}
            onClick={() => setSelectedSessionDate(index)}
            uppercase
            style={{
              height: "37px",
              width: "65px",
              border: "1px solid #E6E6E6",
              borderRadius: "6px",
              fontFamily: "Roboto Condensed",
              letterSpacing: "0.4px",
              fontSize: "14px",
              marginRight: "8px"
            }}
          >
            {date}
          </Div>
        );
      }
    }, dates)}
    {countOfDates > MAX_NUMBER_OF_ALERTS && (
      <Div
        display="row.center.center"
        mr={2}
        bg={{
          default: "inherit",
          hover: "#E2E2E2"
        }}
        color={{
          default: "#1789EB",
          hover: "#222222"
        }}
        onClick={onViewMore}
        uppercase
        style={{
          height: "37px",
          width: "65px",
          border: "1px solid #E6E6E6",
          borderRadius: "6px",
          fontFamily: "Roboto Condensed",
          letterSpacing: "0.4px",
          fontSize: "14px",
          marginRight: "8px"
        }}
      >
        more
      </Div>
    )}
  </Div>
);

const decorator = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      newsAndAlertsDates: newsAndAlertsDates(state, props),
      selectedNewsAndAlertsDate: getters.selectedNewsAndAlertsDate(
        state,
        props
      ),
      filteredNewsAndAlertsBySelection: filteredNewsAndAlertsBySelection(
        state,
        props
      ),
      countOfDates: getters.countOfDates(state, props),
      hasEnabledPhoneNumber: getHasEnabledPhoneNumber(state, props),
      isSubscribedToNotifications: getHasEnabledUpdateNotifications(
        state,
        props
      ),
      alertsTitle: getAlertsTitle(state),
      alertsSubtitle: getAlertsSubtitle(state),
      alertsViewAllLabel: getAlertsViewAllLabel(state)
    }),
    {
      setSelectedNewsAndAlertsDate: actions.setSelectedNewsAndAlertsDate,
      executeAction: actions.executeAction
    }
  )
);

const EmptyListPlaceholder = ({ children }) => (
  <Div display="column.center.center" bg="gray1" height={100} width={1} bra={2}>
    {children}
  </Div>
);

const NewsAndAlerts = ({
  countOfDates,
  newsAndAlertsDates,
  selectedNewsAndAlertsDate,
  setSelectedNewsAndAlertsDate,
  filteredNewsAndAlertsBySelection,
  executeAction,
  isSubscribedToNotifications,
  hasEnabledPhoneNumber,
  router,
  alertsTitle,
  alertsSubtitle,
  alertsViewAllLabel = "view all alerts",
  ...otherprops
}) => (
  <Div className="news-and-alerts" {...otherprops}>
    <Div
      color="#323232"
      style={{
        fontSize: "24px",
        fontFamily: "'Roboto', sans-serif",
        letterSpacing: "-0.26px",
        fontWeight: 500,
        marginBottom: "12px"
      }}
    >
      {alertsTitle}
    </Div>
    <Div pb={4} className="news-and-alerts__list-header">
      <RenderDayButtons
        countOfDates={countOfDates}
        dates={newsAndAlertsDates}
        selectedDate={selectedNewsAndAlertsDate}
        setSelectedSessionDate={setSelectedNewsAndAlertsDate}
        onViewMore={() => router.push(`${router.location.pathname}/alerts`)}
      />
      <Div className="news-and-alerts__legend">
        <Div display="row.flex-start.center">
          <Div
            uppercase
            color={{
              default: "#1789EB",
              hover: "#097adc"
            }}
            display="row.flex-start.center"
            style={{
              fontFamily: "'Roboto Condensed', sans-serif",
              fontSize: "14px",
              letterSpacing: "0.4px"
            }}
            onClick={() =>
              executeAction({
                type: PAGE_ACTIONS.NOTIFY_ME,
                status: !isSubscribedToNotifications,
                section: "alerts",
                id: null
              })
            }
          >
            <img
              src="https://d2dks4tzxs6xee.cloudfront.net/img/pga/interview-notification-ico.svg"
              alt="Toggle Notification Icon"
              width={15}
              style={{
                marginRight: "8px"
              }}
            />

            {isSubscribedToNotifications ? "subscribed" : "notify me via SMS"}
          </Div>

          {hasEnabledPhoneNumber && (
            <>
              <Div mx={2} color="gray5">
                &middot;
              </Div>
              <Div
                uppercase
                color={{
                  default: "#1789EB",
                  hover: "#097adc"
                }}
                style={{
                  fontFamily: "'Roboto Condensed', sans-serif",
                  fontSize: "14px",
                  letterSpacing: "0.4px"
                }}
                onClick={() =>
                  executeAction({
                    type: PAGE_ACTIONS.NOTIFY_ME,
                    changeNumber: true
                  })
                }
              >
                Settings
              </Div>
            </>
          )}
        </Div>
        <Div
          display="row"
          fs={2}
          color="gray5"
          mt={1}
          italics
          style={{
            fontFamily: "'Roboto', sans-serif",
            letterSpacing: "-0.15px"
          }}
        >
          {alertsSubtitle}
        </Div>
      </Div>
    </Div>

    <Div width={1}>
      {R.isEmpty(filteredNewsAndAlertsBySelection) ? (
        <EmptyListPlaceholder>
          <Div
            mb={1}
            style={{
              color: "#666666",
              fontFamily: "Roboto",
              fontSize: "16px",
              fontWeight: "bold"
            }}
          >
            No News or Alerts
          </Div>
          <Div
            fs={2}
            style={{
              color: "#666666",
              fontFamily: "Roboto",
              fontSize: "14px"
            }}
          >
            New items will appear when posted.
          </Div>
        </EmptyListPlaceholder>
      ) : (
        R.map(
          item => (
            <Div
              className={css.newsitem}
              bra={1}
              bg="white"
              mb={3}
              display="row.space-between.center"
              px={4}
              py={3}
              key={item.id}
              style={{
                padding: "20px 17px 20px 27px", // "20px 35px 20px 27px",
                marginBottom: "6px"
              }}
              onClick={
                item.has_content
                  ? () =>
                      router.push(
                        `${router.location.pathname}/alerts/${item.id}`
                      )
                  : undefined
              }
            >
              <Div display="column">
                <Div
                  style={{
                    flex: 1,
                    color: "#222222",
                    fontFamily: "'Roboto', sans-serif",
                    fontSize: "16px",
                    letterSpacing: 0
                  }}
                >
                  {item.name}
                </Div>
              </Div>
              <Div
                display="row.flex-end.center"
                style={{
                  marginLeft: "10px",
                  flexShrink: 0
                }}
              >
                <Div
                  uppercase
                  style={{
                    color: "#666666",
                    fontFamily: "'Roboto Condensed', sans-serif",
                    fontSize: "12px",
                    letterSpacing: 0,
                    flexShrink: 0
                  }}
                >
                  {item.date_label}
                </Div>
                {item.has_content ? (
                  <RightIcon
                    color="#8C8C8C"
                    size={25}
                    style={{
                      marginLeft: "11px"
                    }}
                  />
                ) : null}
              </Div>
            </Div>
          ),
          filteredNewsAndAlertsBySelection
        )
      )}
    </Div>
    {filteredNewsAndAlertsBySelection.length ? (
      <Div
        onClick={() => router.push(`${router.location.pathname}/alerts`)}
        uppercase
        color={{
          default: "#1789EB",
          hover: "#097adc"
        }}
        style={{
          fontFamily: "'Roboto Condensed', sans-serif",
          fontSize: "14px",
          letterSpacing: "0.4px",
          marginTop: "15px"
        }}
      >
        {alertsViewAllLabel}
      </Div>
    ) : null}
  </Div>
);

export default CSSModules(decorator(NewsAndAlerts), css);
