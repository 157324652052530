import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AssignDocumentRequests from "./AssignDocumentRequests";
import { get } from "lodash";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

import * as EventSelectors from "redux/modules/event/selectors";
import * as FormsSelectors from "redux/modules/formsV2/forms/selectors";
import * as AccountAssignedDocumentRequestsSelectors from "redux/modules/accounts/assignedDocumentRequests/selectors";
import * as ContactAssignedDocumentRequestsSelectors from "redux/modules/contacts/assignedDocumentRequests/selectors";
import * as ModuleRecordsSelectors from "redux/modules/modules/records/selectors";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { getRecords } from "redux/modules/modules/records/actions";
import {
  getAssignedDocumentRequests as accountGetAssignedDocumentRequests,
  addAssignedDocumentRequests as accountAddAssignedDocumentRequests,
  deleteAssignedDocumentRequests as accountDeleteAssignedDocumentRequests
} from "redux/modules/accounts/assignedDocumentRequests/actions";

import {
  getAssignedDocumentRequests as contactGetAssignedDocumentRequests,
  addAssignedDocumentRequests as contactAddAssignedDocumentRequests,
  deleteAssignedDocumentRequests as contactDeleteAssignedDocumentRequests
} from "redux/modules/contacts/assignedDocumentRequests/actions";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { DOCUMENT_REQUEST_TYPES } from "utils/standard-module-field-ids";

const { NAME } = DOCUMENT_REQUEST_TYPES;

function mapStateToProps(state, props) {
  return {
    eventDetails: EventSelectors.eventDetails(state),
    isFetchingAssignedDocumentRequests:
      props.moduleId === STANDARD_MODULES.accounts.id
        ? AccountAssignedDocumentRequestsSelectors.isFetching(state)
        : ContactAssignedDocumentRequestsSelectors.isFetching(state),
    isFetchingForms: FormsSelectors.isFetching(state),
    assignedDocumentRequests:
      props.moduleId === STANDARD_MODULES.accounts.id
        ? AccountAssignedDocumentRequestsSelectors.assignedDocumentRequests(
            state
          ).reduce((a, b) => {
            a[b.id] = b;
            return a;
          }, {})
        : ContactAssignedDocumentRequestsSelectors.assignedDocumentRequests(
            state
          ).reduce((a, b) => {
            a[b.id] = b;
            return a;
          }, {}),
    documentRequestTypes: ModuleRecordsSelectors.records(
      state,
      STANDARD_MODULE_IDS.documentRequestTypes.id
    )
      .map(r => {
        const name = get(r, `values[${NAME}].value`);
        return {
          ...r,
          name,
          search: name.toLowerCase()
        };
      })
      .reduce((a, b) => {
        a[b.id] = b;
        return a;
      }, {})
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      showSnackbar,
      getRecords,
      getAssignedDocumentRequests:
        props.moduleId === STANDARD_MODULES.accounts.id
          ? accountGetAssignedDocumentRequests
          : contactGetAssignedDocumentRequests,
      addAssignedDocumentRequests:
        props.moduleId === STANDARD_MODULES.accounts.id
          ? accountAddAssignedDocumentRequests
          : contactAddAssignedDocumentRequests,
      deleteAssignedDocumentRequests:
        props.moduleId === STANDARD_MODULES.accounts.id
          ? accountDeleteAssignedDocumentRequests
          : contactDeleteAssignedDocumentRequests
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignDocumentRequests);
