import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Badge = CSSModules(
  ({ backgroundColor, color, children }) => (
    <div styleName="badge" style={{ backgroundColor }}>
      <div styleName="inner" style={{ color }}>
        {children}
      </div>
    </div>
  ),
  css
);

class Status extends Component {
  render() {
    switch (this.props.value.value.toLowerCase()) {
      case "approved":
        return (
          <Badge backgroundColor="#00cd81" color="#FFFFFF">
            <i className="material-icons">check</i>Reviewed
          </Badge>
        );
      case "denied":
        return (
          <Badge backgroundColor="#f0cfcf" color="#882626">
            Needs Changes
          </Badge>
        );
      case "pending":
      case "submitted":
        return (
          <Badge backgroundColor="#f1e1b2" color="#807426">
            Needs Review
          </Badge>
        );
      default:
        return (
          <Badge backgroundColor="#f0cfcf" color="#882626">
            Not Submitted
          </Badge>
        );
    }
  }
}

export default Status;
