import React, { Component } from "react";
import getValue from "utils/value-types/get-value/event-list";
import { Div } from "components/Base";
import * as R from "ramda";
import { isEqual } from "lodash";

export default class AccountListFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  sorter = (a, b) => {
    return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
  };

  render() {
    const value = R.sort(this.sorter, getValue(this.props.value));
    return (
      <Div display="row.flex-start.center">
        {value.map(v => (
          <Div
            key={v.id}
            bra={1}
            fw={3}
            bg="neutral1"
            color="gray9"
            px={2}
            py={1}
            mr={1}
            truncate
            style={{
              flexShrink: 0
            }}
          >
            {v.name}
          </Div>
        ))}
      </Div>
    );
  }
}
