import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "../index";
import { Div, BigFilledInput, SearchIcon } from "components/Base";
import TemplatesPopover from "./TemplatesPopover";
import TemplateList from "./TemplateList";
import UploadDocumentModal from "./UploadDocumentModal";
import MergeTagModal from "./MergeTagModal";
import MergeTags from "./MergeTags";
import { Page, Title } from "EventLight/Common/FieldLayout";

const decorate = connect(
  state => ({
    templatesFilter: getters.templatesFilter(state),
    hasModuleId: getters.hasModuleId(state)
  }),
  {
    setTemplatesFilter: actions.setTemplatesFilter
  }
);

const Layout = ({
  templatesFilter,
  setTemplatesFilter,
  handlers,
  hasModuleId
}) => (
  <Page>
    <Title
      icon="file_present"
      title="Document Templates"
      description="Manage your document templates"
    />

    <UploadDocumentModal />
    <MergeTagModal />

    <Div width={1} flex={1} display="row">
      <Div width={650} height={1}>
        <Div display="row.flex-start.center">
          {!hasModuleId && <TemplatesPopover />}
          <BigFilledInput
            flex={1}
            bg="neutral1"
            color="neutral5"
            RightIcon={SearchIcon}
            placeholder="Search document templates..."
            value={templatesFilter}
            onChange={value => setTemplatesFilter(value)}
            continuous
          />
        </Div>
        <TemplateList handlers={handlers} />
      </Div>
      <MergeTags />
    </Div>
  </Page>
);

export default decorate(Layout);
