import React from "react";
import { Div, TinyFilledBox, Text5 } from "../../index";
import { shadeColor, getInitials } from "../../utils";
import { indexedColors } from "../../Base/colorSpecs";

const InitialTitleTagDisplay = ({
  showInitials,
  color: inputColor,
  title,
  tag
}) => {
  const color = indexedColors[inputColor] || inputColor;
  return (
    <Div display="row.flex-start.center">
      {showInitials && (
        <Div
          size={40}
          style={{ minWidth: 40 }}
          pill
          display={"row.center.center"}
          bg={shadeColor(color, 0.1)}
          color={shadeColor(color, 0.4)}
          fs={5}
        >
          {getInitials(title)}
        </Div>
      )}
      <Div ml={showInitials ? 2 : 0}>
        {tag && (
          <TinyFilledBox bg={shadeColor(color, -0.2)} uppercase>
            {tag}
          </TinyFilledBox>
        )}
        <Text5 mt={1} display="row.flex-start.center" color="white" bold>
          {title}
        </Text5>
      </Div>
    </Div>
  );
};

export { InitialTitleTagDisplay };
