import React, { Component } from "react";
import * as R from "ramda";
import Fuse from "fuse.js";
import { sort as stableSort } from "timsort";
import SortableHeader from "components/Global/ReactTable/ColumnHeaders/Sort";
import getCheckboxColumn from "components/Global/ReactTable/Columns/Checkbox";
import {
  Div,
  SmallOutlineButton,
  FontIcon,
  PopoverMenu
} from "components/Base";
import { withState } from "utils/General";
import { AdminView, PortalView } from "Accounts/People/View/View";

const TABLE_COLUMNS = [
  {
    id: "name",
    Header: "Name",
    accessor: ({ name }) => name,
    Cell: args => <UserCell key={args.original.id} {...args.original} />
  },
  {
    id: "actions",
    Header: "Actions",
    maxWidth: 100,
    Cell: args => (
      <Div>
        <PopoverMenu
          Label={({ onClick }) => (
            <SmallOutlineButton onClick={onClick} py={1}>
              <FontIcon fs={3}>more_horiz</FontIcon>
            </SmallOutlineButton>
          )}
          menuItems={[
            ["Edit", args.original.onClick],
            args.original.onRemove ? ["Remove", args.original.onRemove] : null
          ].filter(i => i)}
        />
      </Div>
    )
  }
];

const UserCell = ({ name, onClick, badges }) => (
  <Div display="row.flex-start.center" py={1} height={35}>
    <Div
      fw={3}
      fs={2}
      onClick={onClick}
      mr={2}
      color={{ hover: "primary8", default: "primary9" }}
    >
      {name}
    </Div>
    {badges.map(label => (
      <Div
        mr={1}
        title={label}
        color="gray6"
        bg="neutral0"
        uppercase
        fw={3}
        p={1}
        bra={1}
        fs={1}
      >
        {label}
      </Div>
    ))}
  </Div>
);

const SortedRows = Comp =>
  class SortedRowsFactory extends Component {
    state = {
      sorterFieldId: undefined,
      sortDirection: undefined,
      valueGetter: null
    };

    toggleSort = (fieldId, valueGetter) => {
      if (["checkbox", "actions"].includes(fieldId)) return;
      const { sortDirection, sorterFieldId } = this.state;
      if (sorterFieldId === fieldId) {
        switch (sortDirection) {
          case "DESC":
            this.setState({
              sorterFieldId: null,
              sortDirection: "ASC",
              valueGetter: null
            });
            break;
          default:
            this.setState({
              sorterFieldId: fieldId,
              valueGetter,
              sortDirection: "DESC"
            });
        }
      } else {
        this.setState({
          sorterFieldId: fieldId,
          sortDirection: "ASC",
          valueGetter
        });
      }
    };

    sorter = (a, b) => {
      // assumes all columns are text values
      const { sorterFieldId, sortDirection, valueGetter } = this.state;
      if (!sorterFieldId) return 0;
      if (sortDirection === "DESC") {
        return valueGetter(a)
          .toLowerCase()
          .localeCompare(valueGetter(b).toLowerCase());
      }
      return valueGetter(b)
        .toLowerCase()
        .localeCompare(valueGetter(a).toLowerCase());
    };

    render() {
      const { people, ...props } = this.props;
      const sortedPeople = [...people];
      stableSort(sortedPeople, this.sorter); // WARNING: Mutates sortedPeople
      return (
        <Comp
          {...props}
          people={sortedPeople}
          onToggleSort={this.toggleSort}
          sortField={this.state.sorterFieldId}
          sortDirection={this.state.sortDirection}
        />
      );
    }
  };

const SelectedRows = Comp =>
  class SelectedRowsFactory extends Component {
    state = { selectedRows: [] };

    setSelectedRows = selectedRows => this.setState({ selectedRows });

    render() {
      const { selectedRows } = this.state;
      return (
        <Comp
          {...this.props}
          setSelectedRows={this.setSelectedRows}
          selectedRows={selectedRows}
        />
      );
    }
  };

const SearchPeople = Comp =>
  class SearchRowsFactory extends Component {
    static searchWithFuse = (searchTerm, fuse, opt, matchWith) => {
      if (!fuse || R.isNil(searchTerm) || R.isEmpty(searchTerm)) {
        return opt;
      }
      return R.compose(
        R.reject(R.isNil),
        R.map(o => R.find(R.propEq(matchWith, o.id), opt))
      )(fuse.search(searchTerm));
    };

    state = { searchTerm: "" };

    componentWillReceiveProps(nextProps) {
      if (nextProps.allPeople && nextProps.allPeople.length) {
        const allPeople = this.constructSearchablePeople(nextProps.allPeople);
        this.fuse = new Fuse(allPeople, {
          threshold: 0.3,
          keys: Object.keys(allPeople[0]),
          shouldSort: true
        });
      }
    }

    handleSearch = searchTerm => this.setState({ searchTerm });

    constructFieldsFromValues = values =>
      values.reduce(
        (fields, val) => [
          { id: val.field_id, type: val.value.type },
          ...fields
        ],
        []
      );

    constructSearchablePeople = people =>
      people.map(({ contact_id, name, email }) => ({
        id: contact_id,
        name,
        email
      }));

    render() {
      const { allPeople, ...props } = this.props;
      const filteredPeople = SearchRowsFactory.searchWithFuse(
        this.state.searchTerm,
        this.fuse,
        this.constructSearchablePeople(allPeople),
        "id"
      ).map(({ id }) => id);
      return (
        <Comp
          allPeople={allPeople}
          filteredPeopleIds={filteredPeople}
          {...props}
          searchTerm={this.state.searchTerm}
          onSearch={this.handleSearch}
        />
      );
    }
  };

class PortalPeopleController extends Component {
  onColumnHeaderClick = (e, state, rowInfo, column) => {
    if (e.target.className !== "rt-resizer") {
      this.props.onToggleSort(column.id, column.accessor);
    }
  };

  onRowSelect = rowId => {
    if (this.isChecked(rowId)) {
      this.props.setSelectedRows(
        this.props.selectedRows.filter(id => id !== rowId)
      );
    } else {
      this.props.setSelectedRows([...this.props.selectedRows, rowId]);
    }
  };

  getColumns = () => [
    {
      ...getCheckboxColumn({
        selectAll: this.selectAllRows,
        isSelectAllChecked: this.isSelectAllChecked,
        onClick: this.onRowSelect,
        isChecked: this.isChecked
      }),
      width: 50
    },
    ...TABLE_COLUMNS.map(column => {
      const isSorting = this.props.sortField === column.id;
      const sortDirection = isSorting ? this.props.sortDirection : "";
      const header = ["name", "status"].includes(column.id) ? (
        <SortableHeader sortByDirection={sortDirection}>
          {column.header}
        </SortableHeader>
      ) : (
        column.header
      );
      return {
        ...column,
        header,
        headerClassName: `sortable ${isSorting && "isSorting"}`,
        sortable: false
      };
    })
  ];

  isSelectAllChecked = () =>
    this.props.people.length === this.props.selectedRows.length &&
    this.props.selectedRows.length;

  isChecked = rowId => this.props.selectedRows.includes(rowId);

  selectAllRows = () => {
    if (this.isSelectAllChecked(this.props.people)) {
      this.props.setSelectedRows([]);
    } else {
      this.props.setSelectedRows(this.props.people.map(c => c.id));
    }
  };

  personIndex = id => R.findIndex(R.propEq("id", id), this.props.people);

  getPersonAtIndex(index, people) {
    if (index in people) {
      return people[index];
    }
    return false;
  }

  getNextPerson = currentPersonId =>
    this.getPersonAtIndex(
      this.personIndex(currentPersonId) + 1,
      this.props.people
    );

  getPrevPerson = currentPersonId =>
    this.getPersonAtIndex(
      this.personIndex(currentPersonId) - 1,
      this.props.people
    );

  filter = hasAssignments => {
    if (this.props.activeFilter === "all") {
      return true;
    }
    if (this.props.activeFilter === "no-assignments") {
      return !hasAssignments;
    }

    return hasAssignments;
  };

  render() {
    const {
      eventDetails,
      people,
      onSearch,
      onAddPerson,
      showImportModal,
      showPersonModal,
      showAddPersonModal,
      showRemovePersonModal,
      filteredPeopleIds,
      isViewingAsAdmin,
      loading,
      searchTerm,
      isLocked,
      title,
      description,
      backgroundImageUrl,
      activeFilter,
      setFilter,
      settings: { types }
    } = this.props;

    const peopleRender = contacts => {
      return R.map(
        ({
          contact_id,
          type_id,
          name,
          email,
          role,
          is_primary,
          contact_user_id,
          can_login
        }) => ({
          id: contact_id,
          typeId: type_id,
          name,
          email,
          role,
          badges: [
            ...[can_login && "user"],
            ...[is_primary && "Primary"]
          ].filter(Boolean),
          onClick: () =>
            showPersonModal({
              id: contact_id,
              getNext: this.getNextPerson,
              getPrev: this.getPrevPerson
            }),
          onRemove:
            !isLocked && (isViewingAsAdmin || !can_login)
              ? () =>
                  showRemovePersonModal([
                    { contactId: contact_id, contactUserId: contact_user_id }
                  ])
              : undefined
        }),
        R.filter(
          c =>
            filteredPeopleIds.includes(c.contact_id) &&
            this.filter(c.has_assignments)
        )(contacts)
      );
    };

    const typesRender = people
      .filter(
        contactType => contactType.is_enabled || contactType.contacts.length
      )
      .map(
        ({
          id,
          name,
          background_color,
          contacts,
          number_of_people_limit,
          is_enabled
        }) => ({
          id,
          name,
          enabled: is_enabled,
          accentColor: background_color,
          showAddPersonModal:
            !isLocked && is_enabled
              ? () => showAddPersonModal({ typeId: id })
              : undefined,
          showImportModal:
            !isLocked && is_enabled
              ? () => showImportModal({ typeId: id })
              : undefined,
          people: peopleRender(contacts),
          limit: number_of_people_limit
        })
      );

    const overview = {
      totalCount: this.props.allPeople.length,
      typeCounts: typesRender
        .filter(t => t.people.length)
        .map(type => ({
          name: type.name,
          count: type.people.length
        }))
    };

    const actions = {
      onSearch,
      onAddPerson
    };

    const tableProps = {
      searchTerm,
      onColumnHeaderClick: this.onColumnHeaderClick,
      columns: this.getColumns()
    };

    const View = isViewingAsAdmin ? AdminView : PortalView;

    const isEnabled = R.isEmpty(types)
      ? false
      : R.reduce(
          (accumulated, currentValue) => {
            return R.prop("is_enabled", currentValue) && accumulated;
          },
          true,
          types
        );

    return (
      <View
        {...{
          title,
          description,
          backgroundImageUrl,
          tableProps,
          activeFilter,
          setFilter,
          types: typesRender,
          enabledTypes: typesRender.filter(t => t.enabled),
          overview,
          eventDaysProps: eventDetails,
          showAddPersonModal,
          loading,
          isLocked,
          isEnabled,
          ...actions
        }}
      />
    );
  }
}

export default withState(
  "activeFilter",
  "setFilter",
  "all"
)(SortedRows(SelectedRows(SearchPeople(PortalPeopleController))));
