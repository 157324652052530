import React, { Component } from "react";
import { get } from "lodash";
import * as R from "ramda";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import Panel from "components/Global-2016/Panel";
import ActionButton from "components/Global/CRM/ActionButton";

import { Div, PopoverMenu, Popover, AddPhotoIcon } from "components/Base";
import Gravatar from "components/Global/Gravatar";
import Badge from "components/Atoms/Badge";
import { CONTACT_STANDARD_FIELD_IDS } from "components/Event/Contacts/constants";
import PhotoCard from "./PhotoCard";
import Helpers from "utils/Global/Helpers";

@CSSModules(css)
class Contact extends Component {
  constructor(props) {
    super(props);
    this.uploadPhoto = this.uploadPhoto.bind(this);
    this.removePhoto = this.removePhoto.bind(this);
  }

  get fullName() {
    let name = `${get(
      this.props,
      `data[${CONTACT_STANDARD_FIELD_IDS.fname}].value.value`,
      ""
    )} ${get(
      this.props,
      `data[${CONTACT_STANDARD_FIELD_IDS.lname}].value.value`,
      ""
    )}`;
    if (!name.trim()) {
      name = get(
        this.props,
        `data[${CONTACT_STANDARD_FIELD_IDS.email}].value.value`,
        ""
      );
    }
    return name;
  }

  get title() {
    let title = get(this.props, "data.title.value.value", "");
    if (!title.length) {
      title = get(
        this.props,
        `data[${CONTACT_STANDARD_FIELD_IDS.email}].value.value`,
        ""
      );
    }
    if (
      this.fullName ===
      get(
        this.props,
        `data[${CONTACT_STANDARD_FIELD_IDS.email}].value.value`,
        ""
      )
    ) {
      title = "";
    }
    const result = [
      get(this.props.data, ["record_type_name", "value"]),
      title
    ].filter(v => v && v.length);
    if (result.length) {
      return result.join(" · ");
    }
    return null;
  }

  uploadPhoto(source) {
    const options = {
      multiple: false,
      accept: ["image/jpeg", "image/svg+xml", "image/bmp", "image/png"],
      fromSources: source.showWebcam ? ["webcam"] : ["local_file_system"]
    };

    const path = { path: "attendee-photo/" };

    function callback(data) {
      this.props.updateField({
        fieldId: CONTACT_STANDARD_FIELD_IDS.avatar_url,
        value: {
          type: "file",
          value: {
            files: [data[0]]
          }
        }
      });
    }

    return Helpers.getFilepicker(options, path, callback.bind(this));
  }

  removePhoto() {
    this.props.updateField({
      fieldId: CONTACT_STANDARD_FIELD_IDS.avatar_url,
      value: null
    });
  }

  render() {
    let body = (
      <div>
        <div styleName="heading">
          <span styleName="headingName">{this.fullName}</span>
          {this.props.isUser ? (
            <Badge className={css.userBadge}>User</Badge>
          ) : (
            ""
          )}
        </div>
        <div styleName="subHeading">{this.title}</div>
      </div>
    );

    if (!this.props.data) {
      body = <div>Loading...</div>;
    }

    const overlay = (
      <Div
        className="showOnHover"
        bg="primary5"
        style={{
          borderRadius: 3,
          position: "absolute",
          opacity: 0.4,
          right: 0,
          left: 0,
          bottom: 0,
          top: 0
        }}
      />
    );

    const icon = (
      <AddPhotoIcon
        size={34}
        color="neutral0"
        className="showOnHover"
        style={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)"
        }}
      />
    );

    const menuItems = [
      this.props.showChangeRecordTypeModal
        ? ["Change type", this.props.showChangeRecordTypeModal]
        : null,
      this.props.isUser ? null : ["Delete", this.props.showDeleteContactModal]
    ].filter(i => i);

    const menu =
      menuItems && menuItems.length ? (
        <Div
          display="row.flex-start.flex-start"
          style={{
            marginLeft: 89
          }}
        >
          <PopoverMenu
            Label={({ onClick }) => (
              <ActionButton
                onClick={onClick}
                title={
                  <span className={css.buttonText}>
                    Actions&nbsp;
                    <i className="material-icons">more_horiz</i>
                  </span>
                }
              />
            )}
            menuItems={menuItems}
          />
        </Div>
      ) : null;

    const photoUrl = R.pathOr(
      "https://secure.gravatar.com/avatar/7478cea5ca1c71348c9ced89578e37e8/?s=70&r=g&d=mm",
      [
        "data",
        CONTACT_STANDARD_FIELD_IDS.avatar_url,
        "value",
        "value",
        "files",
        0,
        "url"
      ],
      this.props
    );

    return (
      <Panel>
        <div>
          <div styleName="container">
            <div styleName="image">
              <Popover
                Label={({ onClick }) => (
                  <Div
                    onClick={onClick}
                    className="hoverContainer"
                    style={{
                      position: "relative",
                      height: 70
                    }}
                  >
                    <Gravatar size={70} url={photoUrl} />
                    {overlay}
                    {icon}
                  </Div>
                )}
              >
                {() => (
                  <PhotoCard
                    photoUrl={photoUrl}
                    uploadPhoto={this.uploadPhoto}
                    removePhoto={this.removePhoto}
                  />
                )}
              </Popover>
            </div>
            {body}
          </div>

          {!this.props.readOnly && menu}
        </div>
      </Panel>
    );
  }
}

export default Contact;
