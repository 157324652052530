import React from "react";
import { connect } from "react-redux";
import {
  getGroupData,
  getGroupDataFields,
  getAvailableAccounts
} from "Portal/IntakeForm/selectors";
import { getters, actions } from "Portal/IntakeForm/model";
import InputFields from "./InputFields";
import { Div, Dropdown } from "components/Base";

const decorate = connect(
  state => ({
    isLoggedIn: getters.isLoggedIn(state),
    data: getGroupData(state),
    termsAccepted: getters.termsAccepted(state),
    groupValues: getters.groupValues(state),
    selectedAccountId: getters.selectedAccountId(state),
    groupDataFields: getGroupDataFields(state),
    availableAccounts: getAvailableAccounts(state)
  }),
  {
    updateGroupValues: actions.updateGroupValues,
    updateSelectedAccountId: actions.updateSelectedAccountId
  }
);

const GroupPage = ({
  isLoggedIn,
  handlers,
  data,
  updateGroupValues,
  groupValues,
  groupDataFields,
  selectedAccountId,
  availableAccounts,
  updateSelectedAccountId
}) => (
  <>
    <InputFields
      handlers={handlers}
      data={data}
      values={groupValues}
      updateValues={updateGroupValues}
      fields={groupDataFields}
    >
      {isLoggedIn && availableAccounts.length ? (
        <Div className="lennd-valid" mb={3}>
          <Div display="row.flex-start" color="black" fw={3} mb={1}>
            Select a group to fill out this form as:
          </Div>
          <Dropdown
            options={availableAccounts}
            selected={selectedAccountId}
            onChange={({ value }) => updateSelectedAccountId(value)}
            width={250}
          />
        </Div>
      ) : null}
    </InputFields>
  </>
);

export default decorate(GroupPage);
