import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "./constants";

const iniState = {
  loading: false,
  fileSource: "",
  fileId: "",
  name: "",
  mimetype: "image/jpg",
  modified: "8/12/20 4:10pm",
  assignedTo: "",
  size: "",
  url: "",
  addedBy: ""
};

const model = createHandlers({
  iniState,
  reducers: {
    openFile: R.identity,
    closeSidebar: R.identity,
    refreshData: R.identity,
    onDownload: R.identity,
    onOpen: R.identity,
    sendViaEmail: R.identity,
    attachToRecords: R.identity,
    showOnPortal: R.identity,
    setInitialData: (
      state,
      {
        payload: {
          source: fileSource,
          name,
          mimetype,
          modified,
          assigned_to: assignedTo,
          size,
          url,
          added_by: addedBy,
          id: fileId
        }
      }
    ) => ({
      fileSource,
      name,
      mimetype,
      modified,
      assignedTo,
      size,
      url,
      addedBy,
      fileId
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
