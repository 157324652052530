import * as R from "ramda";

import { createHandlers } from "redux-mvc";

const model = createHandlers({
  namespace: "UIToast",
  iniState: {
    toasts: [],
    showing: []
  },
  reducers: {
    show: ({ toasts, showing }, { payload: toast }) => ({
      toasts: R.append(toast, toasts),
      showing: R.append(toast.id, showing)
    }),
    hide: ({ showing }, { payload: toastId }) => ({
      showing: R.without([toastId], showing)
    }),
    remove: ({ toasts }, { payload: toastId }) => ({
      toasts: R.filter(
        R.compose(
          R.not,
          R.propEq("id", toastId)
        ),
        toasts
      )
    })
  }
});

const { actions, getters } = model;

export { actions, getters };

export default model;
