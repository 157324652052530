import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Modal from "./Modal";

import {
  people,
  member,
  error
} from "redux/modules/portal/groupManager/people/selectors";
import { resources } from "redux/modules/portal/groupManager/resources/selectors";
import { hideModal } from "redux/modules/modal/actions";
import {
  groupEvent,
  groupSettings
} from "redux/modules/portal/groupManager/selectors";

function mapStateToProps(state, { id }) {
  return {
    error: error(state),
    event: groupEvent(state),
    member: member(state, id),
    people: people(state),
    resources: resources(state),
    managerDetails: groupSettings(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ hideModal }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
