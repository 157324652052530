import React from "react";
import { props, prop } from "ramda";
import { connect } from "react-redux";

import { Div, Text6, ControlledDataTable } from "components/Base";

import { getTableRows } from "Passes/Confirmations/selectors";
import COLUMNS from "Passes/Confirmations/View/Columns";
import { getters as DoubleSelectGetters } from "ui-kit/DoubleSelect";

const getColumns = option =>
  prop(option)({
    not_sent: props(
      ["select", "attendee", "emailTo", "passTypes", "order", "status"],
      COLUMNS
    ),
    sent: props(
      ["select", "attendee", "emailTo", "passTypes", "order", "status"],
      COLUMNS
    ),
    unpaid: props(
      ["select", "attendee", "emailTo", "passTypes", "order", "paymentStatus"],
      COLUMNS
    )
  });

const decorate = connect((state, props) => ({
  rows: getTableRows(state, props),
  option: DoubleSelectGetters.selectedOption(state)
}));

const Table = ({ rows = [], option, ...rest }) => {
  const columns = getColumns(option);

  return (
    <Div shadow={2} m={3} bra={1} style={{ flexShrink: 0 }} flex={1}>
      <ControlledDataTable
        columns={columns}
        rows={rows}
        EmptyState={
          <Div display="column.center.center">
            <Text6 bold>There are no matching orders.</Text6>
          </Div>
        }
        PaginationBarComp={() => <div />}
        {...rest}
      />
    </Div>
  );
};

export default decorate(Table);
