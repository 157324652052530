import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Card from "../Card";

const Message = ({
  recipient,
  latest_message,
  submission,
  form,
  showSubmissionSummary
}) => (
  <div
    styleName="container"
    onClick={() =>
      showSubmissionSummary({ id: submission.id, form_id: form.id })
    }
  >
    {recipient ? (
      <div styleName="author">
        <div
          styleName="avatar"
          style={
            recipient.photo_url
              ? { backgroundImage: `url('${recipient.photo_url}')` }
              : {}
          }
        >
          {recipient.photo_url
            ? ""
            : recipient.first_name.substring(0, 1).toUpperCase()}
        </div>
        <div
          styleName={
            submission.count_of_unread_messages > 0
              ? "authorTitleBold"
              : "authorTitle"
          }
        >
          {recipient.first_name}
        </div>
      </div>
    ) : (
      <div styleName="author">
        <div
          styleName="avatar"
          style={
            latest_message.user.photo_url
              ? { backgroundImage: `url('${latest_message.user.photo_url}')` }
              : {}
          }
        >
          {latest_message.user.photo_url
            ? ""
            : latest_message.user.first_name.substring(0, 1).toUpperCase()}
        </div>
        <div
          styleName={
            submission.count_of_unread_messages > 0
              ? "authorTitleBold"
              : "authorTitle"
          }
        >
          {latest_message.user.first_name}
        </div>
      </div>
    )}
    <div styleName="body">
      {submission.count_of_unread_messages > 0 ? (
        <div styleName="message">
          <div styleName="label">
            <div styleName="tag">{submission.count_of_unread_messages} New</div>
            <div styleName="bold">
              {form.title}
              <span styleName="hideForSmall">:</span>
            </div>
          </div>
          <div styleName="mobileTitleBold">
            {recipient ? recipient.first_name : latest_message.user.first_name}
          </div>
          <div styleName="content">{latest_message.comment}</div>
        </div>
      ) : (
        <div styleName="message">
          <div styleName="label">
            <div>{form.title}:</div>
          </div>
          <div styleName="mobileTitle">
            {recipient ? recipient.first_name : latest_message.user.first_name}
          </div>
          <div styleName="content">
            <span styleName="light">{latest_message.comment}</span>
          </div>
        </div>
      )}
      <Card submission={submission} form={form} />
    </div>
  </div>
);

Message.propTypes = {
  latest_message: PropTypes.shape({
    user: PropTypes.shape({
      photo_url: PropTypes.string,
      first_name: PropTypes.string,
      last_name: PropTypes.string
    })
  }).isRequired,
  recipient: PropTypes.shape({
    photo_url: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string
  }).isRequired,
  submission: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  form: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string
  }).isRequired,
  showSubmissionSummary: PropTypes.func.isRequired
};

export default CSSModules(Message, css);
