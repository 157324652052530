/* eslint-disable no-underscore-dangle */
import { put, takeEvery, all, fork, call, select } from "redux-saga/effects";
import { registerError } from "redux/modules/errors/actions";
import { actions, getters } from "../model";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { apiCall } from "App/Data/sagas";
import { showSnackbar } from "redux/modules/snackbar/actions";

const getOnboardDetails = function*() {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    yield put(actions.setLoadingOnboard(true));

    const { payload } = yield call(apiCall, {
      method: "get",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/onboard`
    });
    yield put(actions.setOnboardDetails(payload));
    yield put(actions.setLoadingOnboard(false));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred white loading onboard details"
        }
      ])
    );
  }
};

const getIntakeDetails = function*() {
  yield all([
    put(actions.getOnboardDetails()),
    put(actions.getPermissionSets()),
    put(showSnackbar({ message: "Settings Updated" }))
  ]);
};

const watchGetIntakeDetails = function*() {
  yield takeEvery(actions.getOnboardDetails.type, getOnboardDetails);
};

const watchGetOnboardDetails = function*() {
  yield takeEvery(actions.getIntakeDetails.type, getIntakeDetails);
};

const rootSaga = function*() {
  yield all([fork(watchGetOnboardDetails), fork(watchGetIntakeDetails)]);
};

export default rootSaga;
