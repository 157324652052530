import { REQUEST, RECEIVE, RECEIVE_ALL_EVENTS } from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getRecordTypes(data) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: REQUEST });
      const result = await api.get(getState().user.user.credentials, data);
      dispatch({
        type: RECEIVE,
        payload: {
          moduleId: data.moduleId,
          recordTypes: result.record_types
        }
      });
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching record types"
          }
        ])
      );
    }
  };
}

export function getAllEventRecordTypes(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.get(getState().user.user.credentials, data);
      dispatch({
        type: RECEIVE_ALL_EVENTS,
        payload: {
          moduleId: data.moduleId,
          recordTypes: result.record_types
        }
      });
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching record types"
          }
        ])
      );
    }
  };
}

export function addRecordType(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding record type"
          }
        ])
      );
    }
  };
}

export function updateRecordType(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.put(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating record type"
          }
        ])
      );
    }
  };
}

export function deleteRecordType(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.delete(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting record type"
          }
        ])
      );
    }
  };
}
