import api from "./api";
import { registerError } from "redux/modules/errors/actions";
import { SHOW, HIDE, UPDATE } from "./constants";

export function addStarredField(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SHOW,
      payload: {
        moduleId: data.moduleId,
        layoutId: data.field.layoutId,
        order: data.field.order,
        field: data.field.field
      }
    });
    try {
      const result = await api.post(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding starred field"
          }
        ])
      );
    }
  };
}

export function updateStarredField(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE,
      payload: {
        moduleId: data.moduleId,
        starredFields: data.fields
      }
    });
    try {
      const result = await api.put(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating starred field"
          }
        ])
      );
    }
  };
}

export function deleteStarredField(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: HIDE,
      payload: {
        moduleId: data.moduleId,
        fieldId: data.field.fieldId
      }
    });
    try {
      const result = await api.delete(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting starred field"
          }
        ])
      );
    }
  };
}
