import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import { DateTimePicker } from "react-widgets";
import { get } from "lodash";
import moment from "moment";
import Helpers from "utils/Global/Helpers";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

// TODO: Global localizer kind of sucks.
import momentLocalizer from "react-widgets-moment";

const FORMAT = "YYYY-MM-DD";
const DISPLAY_FORMAT = "LL";

const DayComponent = (dateFrom, dateTo) =>
  CSSModules(
    ({ date, label }) => (
      <div styleName="day">
        <div>{label}</div>
        {date >= new Date(dateFrom) && date <= new Date(dateTo) ? (
          <div styleName="eventLabel">Event</div>
        ) : null}
      </div>
    ),
    css
  );

@CSSModules(css)
class FormDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.formatValue(props.value)
    };
  }

  componentWillUpdate(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        value: this.formatValue(nextProps.value)
      });
    }
  }

  componentWillMount() {
    moment.locale(Helpers.getLocale());
    momentLocalizer(moment);
  }

  formatValue(value) {
    return get(value, "value") && moment(value.value).isValid()
      ? moment(value.value).toDate()
      : null;
  }

  wrapValue(value) {
    return {
      type: "date",
      value:
        value && moment(value).isValid() ? moment(value).format(FORMAT) : ""
    };
  }

  @autobind
  onChange(value) {
    this.props.onChange(this.wrapValue(value));
  }

  parse(d) {
    if (!d) {
      return null;
    }
    let date;
    moment.locale(Helpers.getLocale());

    // Try to use the date constructor
    date = new Date(d);
    if (moment(date).isValid()) {
      return date;
    }

    // Case where date constructor can't handle it, attempt to parse with the foramt
    date = moment(d, DISPLAY_FORMAT);
    if (moment(date).isValid()) {
      return date;
    }

    return null;
  }

  render() {
    const { rowMetaData, disabled, placeholder, required } = this.props;
    const dateFrom = get(rowMetaData, "meta.eventDetails.date_from");
    const dateTo = get(rowMetaData, "meta.eventDetails.date_to");
    return (
      <div className={css.no_focus}>
        <DateTimePicker
          containerClassName={css.custom_styles}
          disabled={disabled}
          required={required}
          value={this.state.value}
          culture={Helpers.getLocale()}
          placeholder={placeholder || moment().format(DISPLAY_FORMAT)}
          onChange={this.onChange}
          format={DISPLAY_FORMAT}
          parse={this.parse}
          time={false}
          defaultCurrentDate={
            this.state.value || dateFrom ? new Date(dateFrom) : undefined
          }
          dayComponent={DayComponent(dateFrom, dateTo)}
        />
      </div>
    );
  }
}

FormDate.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  rowMetaData: PropTypes.object.isRequired
};

export default FormDate;
