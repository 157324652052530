import { createHandlers } from "redux-mvc";
import * as R from "ramda";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: false,
  id: null,
  name: ""
};

const model = createHandlers({
  iniState,
  reducers: {
    saveFolder: R.identity,
    closeModal: R.identity,
    init: R.identity,
    setInitialData: (_, { payload: { id, name } }) => ({
      id,
      name
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
