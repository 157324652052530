import { createSelector } from "reselect";
import * as R from "ramda";
import { TAB_IDS } from "./constants";

export const getCurrentRoute = createSelector(
  (_, props) => props.routes,
  routes => {
    if (R.any(R.propEq("name", "EventLightExpoSales"))(routes)) {
      return TAB_IDS.SALES;
    } else if (
      R.any(R.propEq("name", "EventLightExpoSettingsBooths"))(routes)
    ) {
      return TAB_IDS.BOOTHS;
    } else if (
      R.any(R.propEq("name", "EventLightExpoSettingsSponsorships"))(routes)
    ) {
      return TAB_IDS.SPONSORSHIPS;
    } else if (
      R.any(R.propEq("name", "EventLightExpoSettingsApprovers"))(routes)
    ) {
      return TAB_IDS.APPROVERS;
    } else if (R.any(R.propEq("name", "EventLightExpoSendEmails"))(routes)) {
      return TAB_IDS.SEND_EMAILS;
    } else if (R.any(R.propEq("name", "EventLightExpoRequests"))(routes)) {
      return TAB_IDS.REQUESTS;
    }
    return null;
  }
);

export const getTabs = createSelector(
  (_, props) => props.params,
  getCurrentRoute,
  (params, currentRoute) => {
    if (
      [
        TAB_IDS.BOOTHS,
        TAB_IDS.SPONSORSHIPS,
        TAB_IDS.APPROVERS,
        TAB_IDS.VIRTUAL_BOOTHS
      ].includes(currentRoute)
    ) {
      return [
        {
          key: "booths",
          label: "Booths",
          active: currentRoute === TAB_IDS.BOOTHS,
          to: `/event-light/${params.eventId}/expo/settings/booths`,
          visible: true
        },
        {
          key: "sponsorships",
          label: "Sponsorships",
          active: currentRoute === TAB_IDS.SPONSORSHIPS,
          to: `/event-light/${params.eventId}/expo/settings/sponsorships`,
          visible: true
        },
        {
          key: "approvers",
          label: "Approvers",
          active: currentRoute === TAB_IDS.APPROVERS,
          to: `/event-light/${params.eventId}/expo/settings/approvers`,
          visible: true
        }
      ].filter(l => l.visible);
    }

    if ([TAB_IDS.REQUESTS].includes(currentRoute)) {
      return [
        {
          key: "all-requests",
          label: "All Requests",
          active:
            currentRoute === TAB_IDS.REQUESTS &&
            params.viewId === "all-requests",
          to: `/event-light/${params.eventId}/expo/requests/all-requests`
        },
        {
          key: "my-approvals",
          label: "My Approvals",
          active:
            currentRoute === TAB_IDS.REQUESTS &&
            params.viewId === "my-approvals",
          to: `/event-light/${params.eventId}/expo/requests/my-approvals`
        }
      ];
    }

    return [];
  }
);
