import Helpers from "utils/Global/Helpers";

module.exports = {
  addVariant: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/items/variants`,
        credentials,
        data,
        success,
        error
      });
    }),
  /* eslint-enable no-underscore-dangle */

  updateVariant: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/items/variants/${
          data.variant.id
        }`,
        credentials,
        data,
        success,
        error
      });
    }),
  /* eslint-enable no-underscore-dangle */

  deleteVariant: (credentials, variantId) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/items/variants/${variantId}`,
        credentials,
        success,
        error
      });
    }),
  /* eslint-enable no-underscore-dangle */

  bulkUpdateVariants: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/items/variants/bulk-update`,
        credentials,
        data,
        success,
        error
      });
    })
  /* eslint-enable no-underscore-dangle */
};
