import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const SubmissionContainer = ({ children }) => (
  <div styleName="container">{children}</div>
);

SubmissionContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired
};

export default CSSModules(SubmissionContainer, css);
