import React from "react";

const ScheduleBadge = ({ color, text }) => (
  <div
    style={{
      height: "20px",
      maxWidth: 125,
      overflow: "hidden",
      position: "relative",
      padding: "0 8px",
      borderRadius: "12px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center"
    }}
  >
    <div
      style={{
        backgroundColor: color,
        position: "absolute",
        left: 0,
        top: 0,
        height: "100%",
        width: "100%",
        opacity: 0.1
      }}
    />
    <div
      style={{ height: 8, width: 8, backgroundColor: color, borderRadius: 4 }}
    />
    <div
      style={{
        maxWidth: 100,
        color: "black",
        fontWeight: "600",
        marginLeft: "4px",
        fontSize: "12px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }}
    >
      {text}
    </div>
  </div>
);

export default ScheduleBadge;
