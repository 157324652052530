import React, { Component } from "react";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import {
  MediumClearButton,
  Popover,
  MoreIcon,
  ReplyIcon,
  RightIcon,
  MenuCard,
  MenuItem,
  BoxGroup,
  DownFilledIcon
} from "components/Base";

import Tooltip from "components/Global/Tooltip";

import ApproversLabel from "ApproversLabel/View";
import BulkEditLineItemsModal from "Orders/BulkEditLineItemsModal";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";

import { ROW_ACTIONS } from "Items/Manage/constants";

import { actions } from "../../model";
import { getValue } from "../../utils";

const decorate = connect(
  null,
  bindInstance({
    showModal,
    hideModal,
    execute: actions.executeAction,
    refreshRecords: actions.updateData
  })
);

const UnpaidStatus = () => (
  <div
    style={{
      marginLeft: "8px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      height: "32px",
      fontWeight: 600,
      textTransform: "uppercase",
      width: "100%",
      color: "rgb(73, 73, 73)"
    }}
  >
    Unpaid
  </div>
);

const PendingStatus = () => (
  <div
    style={{
      marginLeft: "8px",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      height: "32px",
      fontWeight: 600,
      textTransform: "uppercase",
      width: "100%",
      color: "rgb(73, 73, 73)"
    }}
  >
    Pending
  </div>
);

const IssuanceMenu = ({ onCheckin, onUndoCheckin }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center"
    }}
  >
    <Popover
      Label={({ onClick }) => (
        <BoxGroup addSpacers display="row.flex-start.center">
          <MediumClearButton
            bg="neutral5"
            color="primary5"
            onClick={onCheckin}
            uppercase
          >
            Pending Pick up
          </MediumClearButton>
          <MediumClearButton onClick={onClick} OnlyIcon={DownFilledIcon} />
        </BoxGroup>
      )}
    >
      {({ closePopover }) => (
        <MenuCard>
          <MenuItem
            onClick={() => {
              closePopover();
              onUndoCheckin();
            }}
            LeftIcon={ReplyIcon}
            RightIcon={RightIcon}
          >
            Undo Issuance
          </MenuItem>
        </MenuCard>
      )}
    </Popover>
  </div>
);

class OrderActions extends Component {
  print = () => {
    const value = getValue(this.props);
    const { data: row, field: column } = this.props;

    this.props.execute({
      actionId: ROW_ACTIONS.PRINT_ITEMS,
      row,
      column,
      value
    });
  };

  undoPrint = () => {
    const value = getValue(this.props);
    const { data: row, field: column } = this.props;

    this.props.execute({
      actionId: ROW_ACTIONS.UNDO_PRINT_ITEMS,
      row,
      column,
      value
    });
  };

  checkin = () => {
    const value = getValue(this.props);
    const { data: row, field: column } = this.props;

    this.props.execute({
      actionId: ROW_ACTIONS.ISSUE_ITEMS,
      row,
      column,
      value
    });

    /*
    this.props.dependentValues.helpers.issueItems({
      orderType: value.orderType,
      customerAccountId: value.customerAccountId,
      customerContactId: value.customerContactId,
      recordId: value.recordId,
      variantId: value.variantId,
      fulfillmentId: value.fulfillmentId,
      status: value.issued && "pending-pickup",
      itemName: value.itemName,
      recordName: value.recordName,
      quantity: value.quantity
    });
    */
  };

  undoCheckin = () => {
    const value = getValue(this.props);
    const { data: row, field: column } = this.props;

    this.props.execute({
      actionId: ROW_ACTIONS.UNDO_CHECKIN,
      row,
      column,
      value
    });

    /*
    this.props.dependentValues.helpers.issueItems({
      orderType: value.orderType,
      customerAccountId: value.customerAccountId,
      customerContactId: value.customerContactId,
      recordId: value.recordId,
      variantId: value.variantId,
      fulfillmentId: value.fulfillmentId,
      status: value.pickedUp
        ? "undo-fulfillment"
        : value.issued
        ? "undo-issuance"
        : undefined,
      itemName: value.itemName,
      recordName: value.recordName,
      quantity: value.quantity
    });
    */
  };

  showBulkEditModal = () => {
    const value = getValue(this.props);
    this.props.showModal({
      content: (
        <BulkEditLineItemsModal
          where={{ lineItemId: value.recordIds }}
          onDone={() => {
            this.props.refreshRecords();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const mode = getValue(this.props, "mode");
    const isPickedUp = getValue(this.props, "isPickedUp");
    const title = getValue(this.props, "title");
    const enabled = getValue(this.props, "enabled");
    const isIssued = getValue(this.props, "isIssued");
    const needsPayment = getValue(this.props, "needsPayment");
    const isPending = getValue(this.props, "isPending");
    const printTitle = getValue(this.props, "printTitle");

    if (mode === "issuance") {
      if (isPickedUp) {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap"
            }}
          >
            <Tooltip tooltip={title}>
              <div
                style={{
                  maxWidth: "80%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap"
                }}
              >
                <i
                  className="material-icons"
                  style={{
                    color: "#51cf66",
                    marginRight: "8px",
                    fontSize: "16px"
                  }}
                >
                  check
                </i>

                <div
                  style={{
                    fontSize: "14px",
                    color: "rgb(206, 206, 206)",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                  }}
                >
                  {title}
                </div>
              </div>
            </Tooltip>
            {enabled ? (
              <Popover
                Label={({ onClick }) => (
                  <MediumClearButton
                    ml={2}
                    OnlyIcon={MoreIcon}
                    onClick={onClick}
                  />
                )}
              >
                {({ closePopover }) => (
                  <MenuCard>
                    <MenuItem
                      LeftIcon={ReplyIcon}
                      RightIcon={RightIcon}
                      onClick={() => {
                        closePopover();
                        this.undoCheckin();
                      }}
                    >
                      Undo Fulfillment
                    </MenuItem>
                  </MenuCard>
                )}
              </Popover>
            ) : null}
          </div>
        );
      }
      if (enabled && isIssued) {
        return (
          <IssuanceMenu
            {...{
              onCheckin: this.checkin,
              onUndoCheckin: this.undoCheckin
            }}
          />
        );
      }
      if (!enabled) {
        return null;
      }
      if (needsPayment) {
        return <UnpaidStatus />;
      }
      if (isPending) {
        return <PendingStatus />;
      }
      return (
        <MediumClearButton
          bg="neutral5"
          color="primary5"
          onClick={this.checkin}
          uppercase
        >
          Check-in
        </MediumClearButton>
      );
    }

    if (mode === "print") {
      if (printTitle) {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap"
            }}
          >
            <Tooltip tooltip={printTitle}>
              <div
                style={{
                  maxWidth: "80%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap"
                }}
              >
                <i
                  className="material-icons"
                  style={{
                    color: "#51cf66",
                    marginRight: "8px",
                    fontSize: "16px"
                  }}
                >
                  check
                </i>

                <div
                  style={{
                    fontSize: "14px",
                    color: "rgb(206, 206, 206)",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap"
                  }}
                >
                  {printTitle}
                </div>
              </div>
            </Tooltip>
            {enabled ? (
              <Popover
                Label={({ onClick }) => (
                  <MediumClearButton
                    ml={2}
                    OnlyIcon={MoreIcon}
                    onClick={onClick}
                  />
                )}
              >
                {({ closePopover }) => (
                  <MenuCard>
                    <MenuItem
                      LeftIcon={ReplyIcon}
                      RightIcon={RightIcon}
                      onClick={() => {
                        closePopover();
                        this.undoPrint();
                      }}
                    >
                      Undo Print
                    </MenuItem>
                  </MenuCard>
                )}
              </Popover>
            ) : null}
          </div>
        );
      }
      if (!enabled) {
        return null;
      }
      if (needsPayment) {
        return <UnpaidStatus />;
      }
      if (isPending) {
        return <PendingStatus />;
      }
      return (
        <MediumClearButton
          bg="neutral5"
          color="primary5"
          onClick={this.print}
          uppercase
        >
          Print
        </MediumClearButton>
      );
    }

    const type = getValue(this.props, "type");
    const status = getValue(this.props, "status");
    const manifestId = getValue(this.props, "manifestId");
    const recordIds = getValue(this.props, "recordIds");

    return (
      <ApproversLabel
        {...{
          // data
          instanceId: this.props.instanceId,
          type: type,
          status: status,
          manifestId: manifestId,
          recordIds: recordIds,
          // handlers
          handlers: {
            bulkEdit: this.showBulkEditModal
          },
          // style
          leftMenu: true,
          containerProps: {
            display: "row.flex-start.center",
            pl: 2,
            pr: 2
          }
        }}
      />
    );
  }
}

export default decorate(OrderActions);
