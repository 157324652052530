import * as R from "ramda";
import Layout from "./Layout";
import { actions } from "../model";
import { createContext } from "redux-mvc";
import module from "Portal/PortalPreviewProfile";

const decorate = createContext({
  module,
  options: {
    observedDomains: ["user", "event", "permissions", "organization", "portal"],
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    persist: false
  },
  lifeCycle: {
    constructor() {
      this.modified = false;
    },

    componentWillUnmount() {
      if (this.modified && this.props.onDone) {
        this.props.onDone();
      }
    },

    componentDidMount() {
      this.store.dispatch(
        actions.init({
          redirectToDashboardRecordTypeId: this.props
            .redirectToDashboardRecordTypeId,
          incomingAccountId: this.props.accountId
        })
      );
    }
  },
  handlers: {
    flagAsModified() {
      this.modified = true;
    }
  }
});

export default decorate(Layout);
