import React from "react";
import { connect } from "react-redux";

import { MediumOutlineButton, DownFilledIcon } from "components/Base";

import { getActionButtons } from "../selectors";

import Actions from "ui-kit/ActionsPopover/View";

const decorate = connect((state, props) => ({
  actions: getActionButtons(state, props)
}));

const ActionButtons = ({ actions }) => (
  <Actions actions={actions}>
    <MediumOutlineButton isPadded={false} RightIcon={DownFilledIcon}>
      Actions
    </MediumOutlineButton>
  </Actions>
);

export default decorate(ActionButtons);
