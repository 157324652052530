import * as R from "ramda";
import React, { useEffect } from "react";
import CSSModules from "react-css-modules";
import { connect } from "react-redux";

import { getPortal } from "redux/modules/portal/actions";
import { getPortalUser } from "redux/modules/portal/user/actions";
import { userId as getUserId } from "redux/modules/user/selectors";
import { getIsLoading, isBreadcrumbsVisible } from "../selectors";

import styles from "./styles.scss";
import Header from "./Header";
import Breadcrumbs from "./Breadcrumbs";
import Loading from "ui-kit/Loading";

import { noop } from "utils/General";

const decorate = R.compose(
  CSSModules(styles),
  connect(
    (state, props) => ({
      userId: getUserId(state, props),
      isLoading: getIsLoading(state, props),
      isBreadcrumbsVisible: isBreadcrumbsVisible(state, props)
    }),
    { getPortal, getPortalUser }
  )
);

const Wrapper = ({
  children,
  userId,
  params = {},
  isLoading = false,
  isBreadcrumbsVisible = false,
  getPortal = noop,
  getPortalUser = noop
}) => {
  useEffect(() => {
    if (params?.portalId && userId) {
      getPortal(params.portalId);
      getPortalUser({ userId, eventId: params.portalId });
    }
  }, []);
  return (
    <section className={styles.grid}>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <Header />
          {isBreadcrumbsVisible ? <Breadcrumbs /> : null}

          {children}
        </>
      )}
    </section>
  );
};

export default decorate(Wrapper);
