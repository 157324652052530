import React from "react";

import { connect } from "react-redux";

import { getters, actions } from "Schedules/AllowAccessUsersModal";

import { ALLOW_ACCESS_INSTANCE } from "Schedules/AllowAccessUsersModal/constants";

import { getUserAndPermissionFields } from "Schedules/AllowAccessUsersModal/selectors";

import ModalWrapper from "ui-kit/MiniModalWrapper";

import { SelectAndCreateVariants } from "ui-kit/MiniItemsSelector/View";
import { MODE_SINGLE_SELECT } from "ui-kit/MiniItemsSelector/constants";

import module from "Schedules/AllowAccessUsersModal";
import rootSaga from "Schedules/AllowAccessUsersModal/sagas";

module.setRootSaga(rootSaga);

const decorate = connect(
  (state, props) => ({
    isModalVisible: getters.showAllowAccessModal(state, props),
    fields: getUserAndPermissionFields(state, props),
    title: getters.title(state, props),
    iniSelectedFields: getters.selectedItemIds(state, props)
  }),
  {
    onClose: () => actions.setShowAllowAccessModal(false),
    onSave: actions.onSave
  }
);

const Layout = ({
  isModalVisible = false,
  title = "Select users with access",
  fields = [],
  iniSelectedFields = [],
  onClose,
  onSave
}) => (
  <ModalWrapper
    showModal={isModalVisible}
    title={title}
    hideModal={onClose}
    width={865}
    height={0.95}
  >
    {(isModalVisible && (
      <SelectAndCreateVariants
        instanceId={ALLOW_ACCESS_INSTANCE}
        fields={fields}
        iniSelected={iniSelectedFields}
        iniClearSearch
        onCancel={onClose}
        onDone={onSave}
        mode={MODE_SINGLE_SELECT}
      />
    )) ||
      null}
  </ModalWrapper>
);

export default decorate(Layout);
