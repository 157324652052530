import React from "react";
import * as R from "ramda";

import {
  Div,
  Text3,
  Text2,
  PopoverMenu,
  Text1,
  SmallTextButton,
  MoreIcon
} from "components/Base";
import { addS, toClass, isEmptyOrNil } from "utils/General";

const MoreButton = toClass(({ onClick }) => (
  <MoreIcon ml={3} onClick={onClick} size={20} />
));

const ApprovalsCard = ({
  title,
  subtitle,
  menuItems,
  rows,
  quanta,
  editSelection
}) => {
  const numSelection = R.length(rows);
  return (
    <Div py={2} px={3} bra={2} bg={"white"}>
      <Div display={"row.flex-start.center"}>
        {/* <DragIcon size={20} /> */}
        <Text3 flex={1} bold>
          {title}
        </Text3>
        <Text2 bold color={"neutral6"}>
          {subtitle}
        </Text2>
        {!isEmptyOrNil(menuItems) && (
          <PopoverMenu Label={MoreButton} menuItems={menuItems} />
        )}
      </Div>
      <Div mt={2}>{rows}</Div>
      <Div display={"row.space-between.center"}>
        <Text1
          flex={1}
          bold
          color={"neutral5"}
        >{`${numSelection} ${quanta}${addS(numSelection)} selected`}</Text1>
        <SmallTextButton color={"primary6"} onClick={editSelection}>
          Edit Selection
        </SmallTextButton>
      </Div>
    </Div>
  );
};

export { ApprovalsCard };
