import Helpers from "utils/Global/Helpers";

module.exports = {
  get: (credentials, eventId, userId) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/portal/event/${eventId}/users/${userId}/references`,
        credentials,
        data: {},
        success,
        error
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  post: (credentials, event_id, user_id, reference) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${
          window.__LENND_API_BASE_URL__
        }/portal/event/${event_id}/users/${user_id}/references`,
        credentials,
        data: {
          event_id,
          user_id,
          ...reference
        },
        success,
        error
      };
      /* eslint-enable no-underscore-dangl e */
      Helpers.request(options);
    })
};
