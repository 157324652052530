import api from "./api";
import { registerError } from "redux/modules/errors/actions";
import {
  CREATE as CREATE_APPROVAL,
  BULK_CREATE as BULK_CREATE_APPROVAL
} from "redux/modules/approvalWorkflows/reviews/constants";

export function createReview(data) {
  return (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE_APPROVAL,
        payload: {
          userId: data.review.userId,
          moduleId: data.review.moduleId,
          recordId: data.review.recordId,
          status: data.review.response
        }
      });
      return api.post(getState().user.user.credentials, data);
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding review"
          }
        ])
      );
    }
  };
}

export function createReviews(data) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: BULK_CREATE_APPROVAL,
        payload: {
          moduleId: data.reviews[0].moduleId,
          reviews: data.reviews
        }
      });
      return await api.post(getState().user.user.credentials, {
        bulk: true,
        ...data
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding reviews"
          }
        ])
      );
    }
  };
}

export function removeReview(data) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE_APPROVAL,
        payload: {
          userId: data.review.userId,
          moduleId: data.review.moduleId,
          recordId: data.review.recordId,
          status: null
        }
      });
      return await api.delete(getState().user.user.credentials, data);
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred removing review"
          }
        ])
      );
    }
  };
}

export function removeReviews(data) {
  return async (dispatch, getState) => {
    try {
      return await api.delete(getState().user.user.credentials, {
        bulk: true,
        ...data
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred removing reviews"
          }
        ])
      );
    }
  };
}
