import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import DocumentTitle from "components/Global/DocumentTitle";
import TemplatePanel from "components/Event/Settings/EmailTemplates/TemplatePanel";
import Heading from "components/Event/Settings/Heading";
import EditTemplateModal from "components/Event/Settings/Modals/EditTemplateModal";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

@CSSModules(css)
class EmailTemplates extends Component {
  componentDidMount() {
    this.props.getEmailTemplates(null, this.props.params.eventId);
  }

  editTemplate = template => {
    this.props.updateEmailTemplate(this.props.params.eventId, template);
  };

  deleteTemplate = id => {
    this.props.deleteEmailTemplate(this.props.params.eventId, id);
  };

  createTemplate = template => {
    this.props.createEmailTemplate(this.props.params.eventId, template);
  };

  showNewModal = () => {
    const emptyTemplate = {
      title: "",
      content_formatted: "",
      content_plaintext: "",
      attachments: []
    };
    this.props.showModal({
      content: (
        <EditTemplateModal
          done={this.createTemplate}
          template={emptyTemplate}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  duplicate = id => {
    const dupe = this.props.list[id];

    this.createTemplate({
      type: dupe.type,
      title: `Copy of ${dupe.title}`,
      moduleId: dupe.module_id,
      subject: dupe.subject,
      contentFormatted: dupe.content_formatted,
      contentPlaintext: dupe.content_plaintext,
      attachments: dupe.attachments
    });
  };

  render() {
    const { params, list, showModal } = this.props;
    const title = `${this.props.details.name} - Details`;

    const templateProps = {
      editTemplate: this.editTemplate,
      deleteTemplate: this.deleteTemplate,
      duplicate: this.duplicate,
      showModal
    };

    let templates = Object.keys(list).map(template => (
      <TemplatePanel
        key={template}
        template={list[template]}
        {...templateProps}
      />
    ));

    templates.push(
      <span key="link" styleName="newMessageLink" onClick={this.showNewModal}>
        Create new template
      </span>
    );

    if (!Object.keys(list).length) {
      templates = (
        <div>
          No templates have been created.&nbsp;
          <span onClick={this.showNewModal} styleName="clickHere">
            Click here
          </span>{" "}
          to create one.
        </div>
      );
    }

    return (
      <DocumentTitle title={title}>
        <div styleName="container">
          <div styleName="subContainer">
            <Heading title="Email Templates" />

            <div styleName="body">
              <div styleName="bodyHeading">Email Templates</div>
              <div styleName="well">{templates}</div>
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

EmailTemplates.propTypes = {
  params: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  list: PropTypes.object.isRequired,
  getEmailTemplates: PropTypes.func.isRequired,
  deleteEmailTemplate: PropTypes.func.isRequired,
  updateEmailTemplate: PropTypes.func.isRequired,
  createEmailTemplate: PropTypes.func.isRequired
};

export default EmailTemplates;
