import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import {
  NAMESPACE,
  DROP_USERS_OPTIONS,
  DEFAULT_FIELD_ORDER
} from "./constants";

import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_MODULE_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

const iniState = {
  loading: false,
  activeIndex: 0,
  welcomeTitle: "Welcome!",
  welcomeDescription:
    "Please agree to the terms of our event before proceeding.",
  showWelcomeDescription: true,
  terms:
    "These are our event terms. You must agree with them in order to proceed.",
  showTerms: true,
  groupTitle: "Group / Company Information",
  groupDescription: "",
  contactTitle: "Primary Contact / User Information",
  contactDescription: "",
  showLogin: true,
  dropNewUsers: DROP_USERS_OPTIONS.portalHome,
  contactFields: [],
  accountsFields: [],
  requiredAccountIds: [],
  requiredContactIds: [],
  selectedAccountFields: [],
  selectedContactFields: [],
  selectedAccountsOrder: DEFAULT_FIELD_ORDER,
  selectedContactsOrder: DEFAULT_FIELD_ORDER,
  accountsDescription: {},
  accountsTempDescription: {
    id: "",
    text: ""
  },
  accountsAlias: {},
  accountsTempAlias: {
    id: "",
    text: ""
  },
  contactsDescription: {},
  contactsTempDescription: {
    id: "",
    text: ""
  },
  contactsAlias: {},
  contactsTempAlias: {
    id: "",
    text: ""
  },
  permissionSetId: "",
  contactTypes: [],
  contactRecordTypeId: null,
  accountsHeaderSections: [],
  contactsHeaderSections: []
};

const model = createHandlers({
  iniState,
  reducers: {
    cancelInstance: R.always(iniState),
    nextView: R.identity,
    previousView: R.identity,
    init: R.identity,
    save: R.identity,
    fetchFields: R.identity,
    setFieldsData: (state, { payload: { contactFields, accountsFields } }) => ({
      contactFields,
      selectedContactFields: R.map(
        ({ id }) => R.find(R.propEq("id", id), contactFields),
        state.selectedContactFields
      ),
      accountsFields,
      selectedAccountFields: R.map(
        ({ id }) => R.find(R.propEq("id", id), accountsFields),
        state.selectedAccountFields
      )
    }),
    populateData: (_, { payload: { intakeForm } }) => {
      const accountFields = R.compose(
        R.sortBy(R.prop("order")),
        R.filter(
          R.pathEq(["field", "module_id"], STANDARD_MODULE_IDS.accounts.id)
        )
      )(R.propOr([], "fields", intakeForm));

      const selectedAccountFields = R.compose(
        R.map(f => ({
          id: f.field.id,
          name: f.field.name,
          type: f.field.type,
          required: f.is_required,
          alias: f.alias,
          description: f.description,
          order: f.order
        }))
      )(accountFields);

      const accountsHeaderSections = R.compose(
        R.map(s => ({
          id: s.id,
          order: s.order,
          title: s.title,
          description: s.description,
          isHeader: true
        })),
        R.filter(R.propEq("module_id", STANDARD_MODULE_IDS.accounts.id))
      )(R.propOr([], "sections", intakeForm));

      const contactFields = R.compose(
        R.sortBy(R.prop("order")),
        R.filter(
          R.pathEq(["field", "module_id"], STANDARD_MODULE_IDS.contacts.id)
        )
      )(R.propOr([], "fields", intakeForm));

      const selectedContactFields = R.compose(
        R.map(f => ({
          id: f.field.id,
          name: f.field.name,
          type: f.field.type,
          required: f.is_required,
          alias: f.alias,
          description: f.description,
          order: f.order
        }))
      )(contactFields);

      const contactsHeaderSections = R.compose(
        R.map(s => ({
          id: s.id,
          order: s.order,
          title: s.title,
          description: s.description,
          isHeader: true
        })),
        R.filter(R.propEq("module_id", STANDARD_MODULE_IDS.contacts.id))
      )(R.propOr([], "sections", intakeForm));

      return {
        permissionSetId: R.propOr("", "permission_set_id", intakeForm),
        welcomeTitle: R.propOr("", "welcome_title", intakeForm),
        welcomeDescription: R.propOr("", "welcome_description", intakeForm),
        showWelcomeDescription: R.propOr(
          "",
          "show_welcome_description",
          intakeForm
        ),
        terms: R.propOr("", "terms", intakeForm),
        showTerms: R.propOr("", "show_terms", intakeForm),
        groupTitle: R.propOr("", "group_title", intakeForm),
        groupDescription: R.propOr("", "group_description", intakeForm),
        contactTitle: R.propOr("", "contact_title", intakeForm),
        contactDescription: R.propOr("", "contact_description", intakeForm),
        showLogin: R.propOr("", "enable_login", intakeForm),
        dropNewUsers: R.propOr("", "portal_page", intakeForm),
        contactRecordTypeId: R.propOr("", "contact_record_type_id", intakeForm),
        selectedAccountFields,
        requiredAccountIds: R.compose(
          R.map(R.prop("id")),
          R.filter(R.propEq("required", true))
        )(selectedAccountFields),
        selectedAccountsOrder: selectedAccountFields.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.id]: curr.order
          }),
          {}
        ),
        accountsDescription: selectedAccountFields.reduce((acc, curr) => {
          if (R.isNil(curr.description)) {
            return acc;
          }
          return {
            ...acc,
            [curr.id]: curr.description
          };
        }, {}),
        accountsAlias: selectedAccountFields.reduce((acc, curr) => {
          if (R.isNil(curr.alias)) {
            return acc;
          }
          return {
            ...acc,
            [curr.id]: curr.alias
          };
        }, {}),
        selectedContactsOrder: selectedContactFields.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.id]: curr.order
          }),
          {}
        ),
        contactsAlias: selectedContactFields.reduce((acc, curr) => {
          if (R.isNil(curr.alias)) {
            return acc;
          }
          return {
            ...acc,
            [curr.id]: curr.alias
          };
        }, {}),
        contactsDescription: selectedContactFields.reduce((acc, curr) => {
          if (R.isNil(curr.description)) {
            return acc;
          }
          return {
            ...acc,
            [curr.id]: curr.description
          };
        }, {}),
        selectedContactFields,
        requiredContactIds: R.compose(
          R.map(R.prop("id")),
          R.filter(R.propEq("required", true))
        )(selectedContactFields),
        accountsHeaderSections,
        contactsHeaderSections
      };
    },
    setInitialData: (
      state,
      {
        payload: {
          contactFields,
          accountsFields,
          permissionSetId,
          shouldUpdate,
          contactTypes
        }
      }
    ) => ({
      permissionSetId,
      contactFields,
      accountsFields,
      selectedAccountFields: shouldUpdate
        ? R.filter(
            ({ id }) => id === STANDARD_MODULE_FIELD_IDS.ACCOUNTS.NAME,
            accountsFields
          )
        : state.selectedAccountFields,
      selectedContactFields: shouldUpdate
        ? R.filter(
            ({ id }) =>
              [
                STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME,
                STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME,
                STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL
              ].includes(id),
            contactFields
          )
        : state.selectedContactFields,
      requiredAccountIds: shouldUpdate
        ? R.compose(
            R.map(R.prop("id")),
            R.filter(({ id }) => id === STANDARD_MODULE_FIELD_IDS.ACCOUNTS.NAME)
          )(accountsFields)
        : state.requiredAccountIds,
      requiredContactIds: shouldUpdate
        ? R.compose(
            R.map(R.prop("id")),
            R.filter(({ id }) =>
              [
                STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME,
                STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME,
                STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL
              ].includes(id)
            )
          )(contactFields)
        : state.requiredContactIds,
      contactTypes
    }),
    toogleShowWelcomeDescription: state => ({
      showWelcomeDescription: !state.showWelcomeDescription
    }),
    toogleShowTerms: state => ({
      showTerms: !state.showTerms
    }),
    toogleShowLogin: state => ({
      showLogin: !state.showLogin
    }),
    updateSelectedAccountFields: (
      state,
      { payload: { visibleColumns, requiredFields, columnOrder } }
    ) => ({
      selectedAccountFields: R.map(
        id => R.find(R.propEq("id", id), state.accountsFields),
        visibleColumns
      ),
      requiredAccountIds: requiredFields,
      selectedAccountsOrder: columnOrder
    }),
    showCreatedAccountField: (state, { payload: { field } }) => ({
      selectedAccountFields: [...state.selectedAccountFields, field],
      selectedAccountsOrder: {
        ...state.selectedAccountsOrder,
        [field.id]:
          Math.max(
            Object.keys(state.selectedAccountsOrder).map(
              k => state.selectedAccountsOrder[k]
            )
          ) + 1
      }
    }),
    showCreatedContactField: (state, { payload: { field } }) => ({
      selectedContactFields: [...state.selectedContactFields, field],
      selectedContactsOrder: {
        ...state.selectedContactsOrder,
        [field.id]:
          Math.max(
            Object.keys(state.selectedContactsOrder).map(
              k => state.selectedContactsOrder[k]
            )
          ) + 1
      }
    }),
    updateSelectedContactFields: (
      state,
      { payload: { visibleColumns, requiredFields, columnOrder } }
    ) => ({
      selectedContactFields: R.map(
        id => R.find(R.propEq("id", id), state.contactFields),
        visibleColumns
      ),
      requiredContactIds: requiredFields,
      selectedContactsOrder: columnOrder
    }),
    removeGroupRow: (state, { payload: { id } }) => {
      const selectedAccountFields = R.filter(
        field => field.id !== id,
        state.selectedAccountFields
      );

      return {
        selectedAccountFields,
        requiredAccountIds: R.filter(
          fieldId => fieldId !== id,
          state.requiredAccountIds
        ),
        selectedAccountsOrder: selectedAccountFields.reduce(
          (acc, field, index) => {
            return {
              ...acc,
              [index]: field.id
            };
          },
          {}
        )
      };
    },
    removeContactRow: (state, { payload: { id } }) => {
      const selectedContactFields = R.filter(
        field => field.id !== id,
        state.selectedContactFields
      );
      return {
        selectedContactFields,
        requiredContactIds: R.filter(
          fieldId => fieldId !== id,
          state.requiredContactIds
        ),
        selectedContactsOrder: selectedContactFields.reduce(
          (acc, field, index) => {
            return {
              ...acc,
              [index]: field.id
            };
          },
          {}
        )
      };
    },
    toggleAccountRequiredField: (state, { payload: { id, required } }) => ({
      requiredAccountIds: required
        ? R.filter(fieldId => fieldId !== id, state.requiredAccountIds)
        : [...state.requiredAccountIds, id]
    }),
    toggleContactRequiredField: (state, { payload: { id, required } }) => ({
      requiredContactIds: required
        ? R.filter(fieldId => fieldId !== id, state.requiredContactIds)
        : [...state.requiredContactIds, id]
    }),
    reorderAccounts: (state, { payload: orderedFields }) => {
      let counter = 0;
      const { fieldsOrder, sectionList } = orderedFields.reduce(
        (acc, curr, idx, src) => {
          const isPreviousSection = R.propOr(false, "isHeader", src[idx - 1]);

          if (isPreviousSection) {
            counter = counter + 1;
          }
          if (curr.isHeader) {
            return {
              ...acc,
              sectionList: [
                ...acc.sectionList,
                {
                  ...curr,
                  order: idx - counter
                }
              ]
            };
          }
          return {
            ...acc,
            fieldsOrder: {
              ...acc.fieldsOrder,
              [curr.id]: idx - counter
            }
          };
        },
        { fieldsOrder: {}, sectionList: [] }
      );

      return {
        selectedAccountsOrder: fieldsOrder,
        accountsHeaderSections: sectionList
      };
    },
    reorderContacts: (state, { payload: orderedFields }) => {
      let counter = 0;
      const { fieldsOrder, sectionList } = orderedFields.reduce(
        (acc, curr, idx, src) => {
          const isPreviousSection = R.propOr(false, "isHeader", src[idx - 1]);

          if (isPreviousSection) {
            counter = counter + 1;
          }
          if (curr.isHeader) {
            return {
              ...acc,
              sectionList: [
                ...acc.sectionList,
                {
                  ...curr,
                  order: idx - counter
                }
              ]
            };
          }
          return {
            ...acc,
            fieldsOrder: {
              ...acc.fieldsOrder,
              [curr.id]: idx - counter
            }
          };
        },
        { fieldsOrder: {}, sectionList: [] }
      );

      return {
        selectedContactsOrder: fieldsOrder,
        contactsHeaderSections: sectionList
      };
    },
    updateAccountsDescription: (state, { payload: { id, description } }) => ({
      accountsDescription: R.assoc(id, description, state.accountsDescription),
      accountsTempDescription: {
        id: "",
        text: ""
      }
    }),
    updateAccountsAlias: (state, { payload: { id, description } }) => ({
      accountsAlias: R.assoc(id, description, state.accountsAlias),
      accountsTempAlias: {
        id: "",
        text: ""
      }
    }),
    updateContactsAlias: (state, { payload: { id, description } }) => ({
      contactsAlias: R.assoc(id, description, state.contactsAlias),
      contactsTempAlias: {
        id: "",
        text: ""
      }
    }),
    updateContactsDescription: (state, { payload: { id, description } }) => ({
      contactsDescription: R.assoc(id, description, state.contactsDescription),
      contactsTempDescription: {
        id: "",
        text: ""
      }
    }),
    clearTempDescriptions: () => ({
      accountsTempDescription: {
        id: "",
        text: ""
      },
      contactsTempDescription: {
        id: "",
        text: ""
      }
    }),
    addAccountsHeaderSection: (state, { payload: { order } }) => ({
      accountsHeaderSections: [
        ...state.accountsHeaderSections,
        {
          id: new Date().getTime(),
          order,
          title: "",
          description: "",
          isHeader: true
        }
      ]
    }),
    addContactsHeaderSection: (state, { payload: { order } }) => ({
      contactsHeaderSections: [
        ...state.contactsHeaderSections,
        {
          id: new Date().getTime(),
          order,
          title: "",
          description: "",
          isHeader: true
        }
      ]
    }),
    removeAccountsHeaderSection: (state, { payload: { id } }) => ({
      accountsHeaderSections: R.filter(
        R.compose(
          R.not,
          R.propEq("id", id)
        ),
        state.accountsHeaderSections
      )
    }),
    removeContactsHeaderSection: (state, { payload: { id } }) => ({
      contactsHeaderSections: R.filter(
        R.compose(
          R.not,
          R.propEq("id", id)
        ),
        state.contactsHeaderSections
      )
    }),
    updateAccountsDescriptionHeaderSection: (
      state,
      { payload: { id, description } }
    ) => {
      const headerSectionIndex = R.findIndex(
        R.propEq("id", id),
        state.accountsHeaderSections
      );
      const headerSection = state.accountsHeaderSections[headerSectionIndex];
      return {
        accountsHeaderSections: R.update(
          headerSectionIndex,
          { ...headerSection, description },
          state.accountsHeaderSections
        ),
        accountsTempDescription: {
          id: "",
          text: ""
        }
      };
    },
    updateContactsDescriptionHeaderSection: (
      state,
      { payload: { id, description } }
    ) => {
      const headerSectionIndex = R.findIndex(
        R.propEq("id", id),
        state.contactsHeaderSections
      );
      const headerSection = state.contactsHeaderSections[headerSectionIndex];
      return {
        contactsHeaderSections: R.update(
          headerSectionIndex,
          { ...headerSection, description },
          state.contactsHeaderSections
        ),
        contactsTempDescription: {
          id: "",
          text: ""
        }
      };
    },
    updateAccountsTitleHeaderSection: (state, { payload: { id, title } }) => {
      const headerSectionIndex = R.findIndex(
        R.propEq("id", id),
        state.accountsHeaderSections
      );
      const headerSection = state.accountsHeaderSections[headerSectionIndex];
      return {
        accountsHeaderSections: R.update(
          headerSectionIndex,
          { ...headerSection, title },
          state.accountsHeaderSections
        )
      };
    },
    updateContactsTitleHeaderSection: (state, { payload: { id, title } }) => {
      const headerSectionIndex = R.findIndex(
        R.propEq("id", id),
        state.contactsHeaderSections
      );
      const headerSection = state.contactsHeaderSections[headerSectionIndex];
      return {
        contactsHeaderSections: R.update(
          headerSectionIndex,
          { ...headerSection, title },
          state.contactsHeaderSections
        )
      };
    },
    removeAlias: (state, { payload: { id } }) => ({
      accountsAlias:
        state.activeIndex === 1
          ? R.dissoc(id, state.accountsAlias)
          : state.accountsAlias,
      contactsAlias:
        state.activeIndex === 2
          ? R.dissoc(id, state.contactsAlias)
          : state.contactsAlias
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
