import React from "react";
import * as R from "ramda";
import { withState } from "recompose";
import { Div, Text1, NoneIcon } from "components/Base";

const withStateUrl = withState("url", "setUrl", "");

const FormItem = ({
  Icon = NoneIcon,
  title,
  description,
  select = () => {},
  imgUrl = "",
  imgUrlHover = "",
  setUrl,
  url
}) => (
  <Div
    width={1}
    height={100}
    bra={2}
    ba={2}
    bc={{ default: "neutral2", hover: "info7" }}
    display="row.flex-start.center"
    p={3}
    mb={2}
    onMouseOver={() => setUrl(imgUrlHover)}
    onMouseOut={() => setUrl(imgUrl)}
  >
    {R.isEmpty(imgUrl) ? (
      <Div
        display="row.center.center"
        height={77}
        width={86}
        bra={1}
        bg="neutral1"
        style={{
          flexShrink: 0
        }}
      >
        <Icon color="gray7" size={35} />
      </Div>
    ) : (
      <img
        src={url || imgUrl}
        width={86}
        height={77}
        style={{ flexShrink: 0 }}
      />
    )}
    <Div ml={2} width={1} display="row.space-between.center">
      <Div height={1} display="column" px={3}>
        <Div fs={4} fw={4} mb={1} color="black">
          {title}
        </Div>
        <Text1>{description}</Text1>
      </Div>
      <Div
        bra={1}
        bg={{ default: "neutral2", hover: "info7" }}
        color={{ default: "gray7", hover: "white" }}
        px={4}
        py={2}
        style={{ cursor: "pointer", flexShrink: 0 }}
        fs={2}
        fw={3}
        transition="fast"
        onClick={select}
        width={100}
        textAlign="center"
      >
        SELECT
      </Div>
    </Div>
  </Div>
);

export default withStateUrl(FormItem);
