import React from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { noop } from "utils/General";
import { actions } from "../model";

import { getAllToggled } from "ui-kit/Table/selectors";
import Checkbox from "material-ui/Checkbox";

const decorate = connect(
  (state, props) => ({
    allToggled: getAllToggled(state, props)
  }),
  bindInstance({
    toggleAll: actions.toggleAllRows
  })
);

const ToggleAllCheckbox = ({
  allToggled = false,
  toggleAll = noop,
  group = {}
}) => (
  <div
    className="action-checkbox"
    onClick={ev => {
      ev.preventDefault();
      toggleAll(group.list);
    }}
  >
    <Checkbox
      checked={allToggled}
      style={{ width: "auto" }}
      iconStyle={{
        alignSelf: "center",
        marginRight: 6
      }}
    />
  </div>
);

export default decorate(ToggleAllCheckbox);
