import React from "react";
import { connect } from "react-redux";
import { actions } from "../model";

import { Div, Text4, DownFilledIcon, SmallToggle } from "components/Base";
import CollapsablePanel from "ui-kit/CollapsiblePanel";

const decorate = connect(
  null,
  {
    updateField: actions.updateField
  }
);

const SectionCard = ({
  name,
  updateField,
  isEnabled,
  fieldId,
  children,
  collapsedDefault = false,
  showToggle = true
}) => {
  return (
    <CollapsablePanel
      right={false}
      arrowIcon={DownFilledIcon}
      arrowColor="black"
      heading={
        <Div display="row.space-between.center" flex={1}>
          <Div display="row.flex-start.center">
            <Text4 color="black" bold>
              {name}
            </Text4>
          </Div>
          {showToggle ? (
            <Div display="row.flex-start.center">
              <SmallToggle
                active={isEnabled}
                onClick={ev => {
                  ev.stopPropagation();
                  updateField({
                    field: fieldId,
                    value: !isEnabled
                  });
                }}
              />
            </Div>
          ) : null}
        </Div>
      }
      collapsedDefault={collapsedDefault}
      bg="white"
      expandedBg="white"
      p={4}
      shadow={1}
      mb={3}
    >
      {children}
    </CollapsablePanel>
  );
};

export default decorate(SectionCard);
