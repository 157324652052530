import React, { Component } from "react";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import ModalColumnAdd from "Modules/AddEditColumnModal/View";
import ModalColumnEdit from "Modules/AddEditColumnModal/View";
import ModalColumnDelete from "components/Global/Table3/ModalColumnDelete";
import RelatedModuleFieldsModal from "components/Event/Settings/Module/Modals/RelatedModuleFields";

import FieldGroupModal from "components/Event/Settings/Module/Modals/FieldGroup";
import DeleteFieldGroupModal from "components/Event/Settings/Module/Modals/DeleteFieldGroup";
import Internal from "./Internal";

class External extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    this.props.getLayout().then(() => {
      this.setState({ loading: false });
    });
  }

  showCreateFieldGroupModal = () => {
    this.props.showModal({
      content: (
        <FieldGroupModal
          label="field group"
          onSave={this.handleCreateFieldGroup}
          hideModal={this.props.hideModal}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  handleCreateFieldGroup = data => {
    const { params, showSnackbar, addFieldGroup } = this.props;
    const { moduleId, eventId } = params;
    addFieldGroup({
      moduleId,
      group: data,
      options: {
        eventId
      }
    }).then(() => {
      showSnackbar({ message: "Field group added", action: "OK" });
      return this.props.getLayout();
    });
  };

  showFieldGroupModal = fieldGroup => {
    this.props.showModal({
      content: (
        <FieldGroupModal
          data={{
            ...fieldGroup
          }}
          onSave={this.handleUpdateFieldGroup}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  handleUpdateFieldGroup = data => {
    const { params, showSnackbar, updateFieldGroup } = this.props;
    const { moduleId, eventId } = params;
    updateFieldGroup({
      moduleId,
      group: data,
      options: {
        eventId
      }
    }).then(() => {
      showSnackbar({ message: "Field group updated", action: "OK" });
      return this.props.getLayout();
    });
  };

  showDeleteFieldGroupModal = fieldGroup => {
    const modal = (
      <DeleteFieldGroupModal
        name={fieldGroup.name}
        handleDelete={() => this.handleDeleteFieldGroup(fieldGroup)}
      />
    );
    this.props.showModal({ content: modal });
  };

  handleDeleteFieldGroup = fieldGroup => {
    const { params, showSnackbar, deleteFieldGroup } = this.props;
    const { moduleId, eventId } = params;
    deleteFieldGroup({
      moduleId,
      group: fieldGroup,
      options: {
        eventId
      }
    }).then(() => {
      showSnackbar({ message: "Field group deleted", action: "OK" });
      return this.props.getLayout();
    });
  };

  showCreateFieldModal = () => {
    const modal = (
      <ModalColumnAdd
        eventId={this.props.params.eventId}
        moduleId={this.props.params.moduleId}
        onSave={this.handleCreateField}
      />
    );
    this.props.showModal({ content: modal });
  };

  handleCreateField = () => {
    this.props.showSnackbar({ message: "Field added", action: "OK" });
    return this.props.getLayout();
  };

  showUpdateFieldModal = field => {
    const modal = (
      <ModalColumnEdit
        eventId={this.props.params.eventId}
        moduleId={this.props.params.moduleId}
        fieldId={field.id}
        onSave={() => this.handleUpdateField()}
      />
    );
    this.props.showModal({ content: modal });
  };

  handleUpdateField = () => {
    this.props.showSnackbar({ message: "Field updated", action: "OK" });
    return this.props.getLayout();
  };

  showDeleteFieldModal = field => {
    const modal = (
      <ModalColumnDelete
        column={field}
        handleDeleteColumn={this.handleDeleteField}
      />
    );
    this.props.showModal({ content: modal });
  };

  handleDeleteField = async data => {
    const { params, showSnackbar, deleteField } = this.props;
    const { moduleId, eventId } = params;
    await deleteField({
      moduleId,
      field: {
        id: data.columnId
      },
      options: {
        eventId
      }
    });

    showSnackbar({ message: "Field deleted", action: "OK" });
    return this.props.getLayout();
  };

  removeStar = field => {
    const { params, starredFields, selectedLayoutId } = this.props;
    const { moduleId, eventId } = params;
    return this.props.deleteStarredField({
      moduleId,
      eventId,
      field: {
        fieldId: field.id,
        layoutId: selectedLayoutId
      },
      options: { eventId }
    });
  };

  addStar = field => {
    const { params, starredFields, selectedLayoutId } = this.props;
    const { moduleId, eventId } = params;
    return this.props.addStarredField({
      moduleId,
      eventId,
      field: {
        field,
        fieldId: field.id,
        order: starredFields.length,
        layoutId: selectedLayoutId
      },
      options: {
        eventId
      }
    });
  };

  removePrimary = async field => {
    const { params } = this.props;
    const { moduleId, eventId } = params;
    await this.props.deletePrimaryField({
      moduleId,
      eventId,
      fieldId: field.id,
      options: { eventId }
    });

    this.props.getLayout();
  };

  addPrimary = async field => {
    const { params } = this.props;
    const { moduleId, eventId } = params;
    await this.props.addPrimaryField({
      moduleId,
      eventId,
      fieldId: field.id,
      options: {
        eventId
      }
    });

    return this.props.getLayout();
  };

  hideField = (fieldId, fieldGroupId) => {
    const { params, selectedLayoutId } = this.props;
    const { moduleId, eventId } = params;
    this.props.deleteVisibleField({
      moduleId,
      eventId,
      field: {
        fieldId,
        fieldGroupId,
        layoutId: selectedLayoutId
      },
      options: { eventId }
    });
  };

  showField = (fieldId, fieldGroupId) => {
    const { params, selectedLayoutId } = this.props;
    const { moduleId, eventId } = params;
    this.props.addVisibleField({
      moduleId,
      eventId,
      field: {
        fieldId,
        fieldGroupId,
        layoutId: selectedLayoutId
      },
      options: {
        eventId
      }
    });
  };

  handleAboutFieldsReorder = fields => {
    this.props.updateStarredField({
      moduleId: this.props.params.moduleId,
      eventId: this.props.params.eventId,
      bulk: true,
      fields: fields.map(({ id }, index) => ({
        layoutId: this.props.selectedLayoutId,
        fieldId: id,
        order: index
      })),
      options: {
        eventId: this.props.params.eventId
      }
    });
  };

  showRelatedModuleFieldsModal = relatedModule => {
    this.props.showModal({
      content: (
        <RelatedModuleFieldsModal
          orgId={this.props.params.orgId}
          eventId={this.props.params.eventId}
          layoutId={this.props.selectedLayoutId}
          moduleId={this.props.params.moduleId}
          relatedModuleId={relatedModule.id}
          relatedFieldId={relatedModule.lookup_field.id}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  hideRelatedModule = relatedModule => {
    this.props.deleteVisibleRelatedModule({
      moduleId: this.props.params.moduleId,
      eventId: this.props.params.eventId,
      layoutId: this.props.selectedLayoutId,
      relatedModule: {
        id: relatedModule.id,
        fieldId: relatedModule.lookup_field.id
      },
      options: { eventId: this.props.params.eventId }
    });
  };

  showRelatedModule = (relatedModule, order) => {
    this.props.addVisibleRelatedModule({
      moduleId: this.props.params.moduleId,
      eventId: this.props.params.eventId,
      layoutId: this.props.selectedLayoutId,
      relatedModule: {
        id: relatedModule.id,
        fieldId: relatedModule.lookup_field.id,
        order
      },
      options: { eventId: this.props.params.eventId }
    });
  };

  handleRelatedModulesReorder = relatedModules => {
    this.props.updateVisibleRelatedModule({
      moduleId: this.props.params.moduleId,
      eventId: this.props.params.eventId,
      layoutId: this.props.selectedLayoutId,
      bulk: true,
      relatedModules: relatedModules.map(({ id, fieldId }, index) => ({
        id,
        fieldId,
        order: index
      })),
      options: {
        eventId: this.props.params.eventId
      }
    });
  };

  handleLayoutFieldsReorder = (fields, fieldGroup, layout) => {};

  render() {
    return <Internal {...this} {...this.props} loading={this.state.loading} />;
  }
}

export default External;
