import Helpers from "utils/Global/Helpers";

/* eslint no-underscore-dangle: "off" */

export default {
  getLayout: ({ credentials, moduleId, options }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules/${moduleId}/layout/default`,
        credentials,
        qs: options,
        success,
        error
      });
    }),
  addField: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/modules/${data.moduleId}/fields`,
        credentials,
        data,
        qs: data.options,
        success,
        error
      });
    }),
  addFieldGroupField: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/modules/${data.moduleId}/field-group-fields`,
        credentials,
        data,
        success,
        error
      });
    }),
  updateField: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/modules/${data.moduleId}/fields`,
        credentials,
        qs: data.options,
        data,
        success,
        error
      });
    }),
  getModule: ({ credentials, moduleId, options }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules/${moduleId}`,
        credentials,
        qs: options,
        success,
        error
      });
    }),
  updateFieldGroup: ({ credentials, id, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/modules/${data.moduleId}/field-group-fields/${id}`,
        credentials,
        data,
        success,
        error
      });
    }),
  deleteFieldGroupField: ({ credentials, id, moduleId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/modules/${moduleId}/field-group-fields/${id}`,
        credentials,
        success,
        error
      });
    })
};
