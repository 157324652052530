import React from "react";

import { getValue } from "../../utils";

const SourceEventbriteOrder = props => {
  const orderId = getValue(props, ["meta", "eventbriteOrderId"], "");
  const eventId = getValue(props, ["meta", "eventbriteEventId"], "");

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%"
      }}
    >
      <i
        className="material-icons"
        style={{
          fontSize: 18,
          color: "#BFBFBF",
          marginRight: 2,
          transform: "rotateZ(135deg)"
        }}
      >
        link
      </i>
      <a
        href={`https://www.eventbrite.com/reports?eid=${eventId}&rid=h&filterby=all,${orderId}`}
        target="_blank"
        style={{
          lineHeight: "34px",
          color: "#2F2D2D",
          fontSize: 15
        }}
        title="View order on Eventbrite"
      >
        Order #{orderId}
      </a>
    </div>
  );
};

export default SourceEventbriteOrder;
