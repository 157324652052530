import React from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import {
  Div,
  LoadingIcon,
  SmallToggle,
  SuperFilledButton,
  SuperOutlineButton,
  Dropdown
} from "components/Base";
import DatePicker from "components/Event/FormsV2/Modals/FormEditModal/Calendar";
import { addS } from "utils/General";

const Loading = () => (
  <Div display="column.center.center" height={370}>
    <LoadingIcon size={60} color="neutral4" />
    <Div color="neutral4" mt={6}>
      Loading...
    </Div>
  </Div>
);

const GuestListSettings = ({
  isModal,
  isEnabled,
  toggleIsEnabled,
  hideModal,
  closeDate,
  isLocked,
  isOpen,
  updateCloseDate,
  toggleIsLocked,
  onSave,
  showEditColumnsModal,
  countOfFieldsSelected,
  recordTypes,
  selectedRecordTypeId,
  updateRecordTypeId,
  showMessageModal,
  hasMessage,
  countOfVariantsSelected,
  showEditVariantLimitsModal
}) => {
  return (
    <Div display="column">
      <Div display="column" mt={6} my={3} mx={8}>
        <Div bg="neutral0" p={4} display="row.space-between.center" mb={6}>
          <Div display="column" width={260}>
            <Div fs={3} fw={3} color="gray9">
              Enable Guest Lists
            </Div>
            <Div fs={1} color="neutral6">
              Allows you to share a unique link for each group to collect name
              assignments on allocated and unassigned passes
            </Div>
          </Div>

          <Div display="row.flex-start.center">
            {isEnabled ? (
              <Div fw={3} color="green7" mr={3}>
                Enabled
              </Div>
            ) : (
              <Div fw={3} color="red7" mr={3}>
                Not Enabled
              </Div>
            )}
            <SmallToggle active={isEnabled} onClick={toggleIsEnabled} />
          </Div>
        </Div>

        <Div display="row.space-between.center" mb={6}>
          <Div display="column" mr={3}>
            <Div fs={3} fw={3} color="gray9">
              Welcome Message
            </Div>
            <Div fs={1} color="neutral6">
              Add a welcome message / instructions
            </Div>
          </Div>

          <Div display="column.center.flex-end">
            <Div onClick={showMessageModal} color="purple8" fw={3} fs={2}>
              {hasMessage ? "Update message" : "Add message"}
            </Div>
          </Div>
        </Div>

        <Div display="row.space-between.center" mb={6}>
          <Div display="column" mr={3}>
            <Div fs={3} fw={3} color="gray9">
              Close Date
            </Div>
            <Div fs={1} color="neutral6">
              If set, no additions or changes will be allowed after specified
              date
            </Div>
          </Div>

          <Div display="column.center.flex-end">
            <DatePicker
              label="Set close date"
              value={closeDate}
              onChange={updateCloseDate}
            />
          </Div>
        </Div>

        <Div display="row.space-between.center" mb={6}>
          <Div display="column">
            <Div fs={3} fw={3} color="gray9">
              Allow additions and changes
            </Div>
            {closeDate && isLocked ? (
              <Div fs={1} color="neutral6">
                Closed because close date has passed
              </Div>
            ) : isLocked ? (
              <Div fs={1} color="neutral6">
                Will not be able to add or update people when close date passes
              </Div>
            ) : null}
          </Div>

          {closeDate ? (
            isLocked ? (
              <Div fw={3} color="red7">
                Closed
              </Div>
            ) : (
              <Div fw={3} color="green7">
                Open
              </Div>
            )
          ) : (
            <Div display="row.flex-start.center">
              {isOpen ? (
                <Div fw={3} color="green7" mr={3}>
                  Open
                </Div>
              ) : (
                <Div fw={3} color="red7" mr={3}>
                  Closed
                </Div>
              )}
              <SmallToggle active={isOpen} onClick={toggleIsLocked} />
            </Div>
          )}
        </Div>

        <Div display="row.space-between.center" mb={6}>
          <Div display="column" mr={3}>
            <Div fs={3} fw={3} color="gray9">
              Select the category of the attendee
            </Div>
            <Div fs={1} color="neutral6">
              This attendee / contact category allows you to report accurately
              on the people added in the allocation manager.
            </Div>
          </Div>
          <Div width={150}>
            <Dropdown
              selected={selectedRecordTypeId}
              onChange={updateRecordTypeId}
              options={recordTypes}
            />
          </Div>
        </Div>

        <Div display="row.space-between.center" mb={6}>
          <Div fs={3} fw={3} color="gray9">
            Select what fields to show
          </Div>
          <Div onClick={showEditColumnsModal} fs={2} fw={3} color="purple8">
            {countOfFieldsSelected} field
            {addS(countOfFieldsSelected)} selected
          </Div>
        </Div>

        <Div display="row.space-between.center" mb={6}>
          <Div display="column" mr={3}>
            <Div fs={3} fw={3} color="gray9">
              Add item limits
            </Div>
            <Div fs={1} color="neutral6">
              Limit how many of a single item can be assigned
            </Div>
          </Div>
          <Div
            onClick={showEditVariantLimitsModal}
            fs={2}
            fw={3}
            color="purple8"
          >
            {countOfVariantsSelected} item limit
            {addS(countOfVariantsSelected)} set
          </Div>
        </Div>
      </Div>
      <Div my={3} mx={6}>
        <SuperFilledButton
          bg="orange5"
          style={{
            cursor: "pointer"
          }}
          mr={3}
          onClick={onSave}
        >
          <Div fw={3}>Save</Div>
        </SuperFilledButton>
        {isModal ? (
          <SuperOutlineButton onClick={hideModal}>Cancel</SuperOutlineButton>
        ) : null}
      </Div>
    </Div>
  );
};

const View = ({
  isModal,
  loading,
  hideModal,
  closeDate,
  isLocked,
  isOpen,
  updateCloseDate,
  toggleIsLocked,
  name,
  onSave,
  showEditColumnsModal,
  countOfFieldsSelected,
  recordTypes,
  selectedRecordTypeId,
  updateRecordTypeId,
  showMessageModal,
  hasMessage,
  countOfVariantsSelected,
  showEditVariantLimitsModal,
  isEnabled,
  toggleIsEnabled
}) => (
  <StyleWrapper
    bodyStyles={{ padding: 0 }}
    heading={
      <Div display="column">
        <span>Guest List Settings</span>
        <span
          style={{
            fontSize: 13,
            color: "rgba(255, 255, 255, 0.8)"
          }}
        >
          {name}
        </span>
      </Div>
    }
    containerStyles={{ overflowY: "hidden" }}
    hideModal={hideModal}
    width={550}
  >
    {loading ? (
      <Loading />
    ) : (
      <GuestListSettings
        {...{
          name,
          isModal,
          hideModal,
          closeDate,
          isLocked,
          isOpen,
          updateCloseDate,
          toggleIsLocked,
          onSave,
          showEditColumnsModal,
          countOfFieldsSelected,
          recordTypes,
          selectedRecordTypeId,
          updateRecordTypeId,
          showMessageModal,
          hasMessage,
          countOfVariantsSelected,
          showEditVariantLimitsModal,
          isEnabled,
          toggleIsEnabled
        }}
      />
    )}
  </StyleWrapper>
);

export default { View, GuestListSettings };
