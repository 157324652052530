import React, { Component } from "react";
import { View, GuestListSettings } from "./View";
import isFormLocked from "components/Event/FormsV2/Utils/is-form-locked";
import EditColumnsModal from "components/Global/CRM/Modals/EditColumns";
import PortalMessageModal from "components/Event/Settings/Module/Modals/PortalMessageModal";
import EditVariantLimitsModal from "Passes/GuestLists/EditVariantLimitsModal";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_MODULE_FIELD_IDS from "utils/standard-module-field-ids";
import * as R from "ramda";
import { isEqual } from "lodash";
import SelectFieldsModal from "Modules/SelectFieldsModal/View";

const FULL_NAME_ID = "FULL_NAME";

const CONTACT_FIELDS_TO_HIDE = [
  STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME,
  STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME
];

const DEFAULT_FIELD_ORDER = {
  [FULL_NAME_ID]: -3,
  [STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL]: -2,
  [STANDARD_MODULE_FIELD_IDS.CONTACTS.EVENT_DAYS]: -1
};

const constructState = props => ({
  isEnabled: props.guestList ? props.guestList.is_enabled : null,
  title: props.guestList ? props.guestList.title : null,
  message: props.guestList ? props.guestList.message : null,
  contactRecordTypeId: props.guestList
    ? props.guestList.contact_record_type_id
    : null,
  closeDate: props.guestList ? props.guestList.close_date : null,
  isLocked: props.guestList ? props.guestList.is_locked : false,
  visibleFields: props.guestList ? props.guestList.visible_fields : [],
  requiredFields: props.guestList ? props.guestList.required_fields : [],
  fieldOrder: props.guestList ? props.guestList.field_order : {},
  variantLimits: props.guestList ? props.guestList.variant_limits : {}
});

class Controller extends Component {
  constructor(props) {
    super(props);
    this.state = constructState(props);
  }

  async componentDidMount() {
    await Promise.all([
      this.props.getFields({
        moduleId: STANDARD_MODULES.contacts.id,
        options: {
          eventId: this.props.eventDetails.id
        }
      }),
      this.props.getRecordTypes({
        moduleId: STANDARD_MODULES.contacts.id,
        options: {
          eventId: this.props.eventDetails.id
        }
      })
    ]);
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.guestList, this.props.guestList)) {
      this.setState(constructState(this.props));
    }
  }

  updateCloseDate = date =>
    this.setState({
      closeDate: date
    });

  toggleIsLocked = isLocked =>
    this.setState({
      isLocked
    });

  toggleIsEnabled = isEnabled =>
    this.setState({
      isEnabled
    });

  onSave = async closeModal => {
    await this.props.updateGuestList(this.props.recordTypeId, {
      isEnabled: this.state.isEnabled,
      title: this.state.title,
      message: this.state.message,
      contactRecordTypeId: this.state.contactRecordTypeId,
      closeDate: this.state.closeDate,
      isLocked: this.state.isLocked,
      visibleFields: this.state.visibleFields,
      requiredFields: this.state.requiredFields,
      fieldOrder: this.state.fieldOrder,
      variantLimits: this.state.variantLimits
    });
    await Promise.all([
      this.props.getGuestLists(),
      this.props.getRecordTypes({
        moduleId: STANDARD_MODULES.accounts.id,
        options: {
          eventId: this.props.eventDetails.id
        }
      })
    ]);

    if (closeModal) {
      this.props.hideModal();
    }
    if (!this.props.isModal) {
      this.props.showSnackbar({
        message: "Settings Saved"
      });
    }
  };

  showEditColumnsModal = () => {
    // add full name field
    this.props.contactFields.unshift({
      id: FULL_NAME_ID,
      name: "Full Name",
      type: "text",
      source: "standard"
    });

    // prepare fields
    const fields = R.compose(
      R.sortBy(R.prop("order")),
      R.map(f => ({
        id: f.id,
        name: f.name,
        type: f.type,
        source: f.source,
        order: DEFAULT_FIELD_ORDER[f.id] || this.state.fieldOrder[f.id],
        tooltip:
          f.id === STANDARD_MODULE_FIELD_IDS.CONTACTS.EVENT_DAYS
            ? "Only shown when meals are available to be assigned"
            : null,
        defaultSelected: [
          FULL_NAME_ID,
          STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL,
          STANDARD_MODULE_FIELD_IDS.CONTACTS.EVENT_DAYS
        ].includes(f.id),
        defaultRequired: [
          FULL_NAME_ID,
          STANDARD_MODULE_FIELD_IDS.CONTACTS.EVENT_DAYS
        ].includes(f.id),
        visible: this.state.visibleFields.includes(f.id),
        required: this.state.requiredFields.includes(f.id)
      })),
      R.filter(f => !CONTACT_FIELDS_TO_HIDE.includes(f.id))
    )(this.props.contactFields);

    const selectedFieldIds = R.compose(
      R.sortBy(R.prop("order")),
      R.map(R.prop("id")),
      R.filter(R.prop("visible"))
    )(fields);

    const requiredFieldIds = R.compose(
      R.map(R.prop("id")),
      R.filter(R.prop("required"))
    )(fields);

    const defaultSelectedFieldIds = R.compose(
      R.map(R.prop("id")),
      R.filter(R.prop("defaultSelected"))
    )(fields);

    const defaultRequiredFieldIds = R.compose(
      R.map(R.prop("id")),
      R.filter(R.prop("defaultRequired"))
    )(fields);

    this.props.showModal({
      content: (
        <SelectFieldsModal
          moduleId={STANDARD_MODULES.contacts.id}
          selectedFieldIds={selectedFieldIds}
          requiredFieldIds={requiredFieldIds}
          defaultSelectedFieldIds={defaultSelectedFieldIds}
          defaultRequiredFieldIds={defaultRequiredFieldIds}
          enableRequiredFields
          fields={fields}
          onDone={selectedFields => {
            this.updateViewMeta({
              visibleFields: selectedFields.map(f => f.id),
              fieldOrder: selectedFields.reduce((map, f) => {
                map[f.id] = f.order;
                return map;
              }, {}),
              requiredFields: selectedFields
                .filter(f => f.required)
                .map(f => f.id)
            });
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  updateViewMeta = ({ visibleFields, fieldOrder, requiredFields }) => {
    this.setState(
      {
        visibleFields,
        fieldOrder,
        requiredFields
      },
      () => {
        this.onSave();
      }
    );
  };

  updateContactRecordTypeId = data => {
    this.setState({
      contactRecordTypeId: data.value ? data.value : null
    });
  };

  showMessageModal = () => {
    this.props.showModal({
      content: (
        <PortalMessageModal
          buttonLabel="Done"
          title={this.state.title}
          message={this.state.message}
          onSave={({ title, message }) => {
            this.setState({
              title: title && title.length ? title : null,
              message: message && message.length ? message : null
            });
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showEditVariantLimitsModal = () => {
    this.props.showModal({
      content: (
        <EditVariantLimitsModal
          selectedVariants={this.state.variantLimits}
          onDone={variantLimits => this.setState({ variantLimits })}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const { hideModal, recordTypeName, contactRecordTypes } = this.props;
    const {
      isEnabled,
      closeDate,
      isLocked,
      visibleFields,
      contactRecordTypeId,
      variantLimits
    } = this.state;

    const recordTypes = R.map(type => ({
      label: type.name,
      value: type.id
    }))(contactRecordTypes);

    const ViewToRender = this.props.isModal ? View : GuestListSettings;

    const isEnabledToShow =
      isEnabled === true ||
      typeof isEnabled === "undefined" ||
      isEnabled === null;

    return (
      <ViewToRender
        {...{
          isModal: this.props.isModal,
          hideModal,
          name: recordTypeName,
          closeDate,
          isLocked: isFormLocked({
            close_date: closeDate,
            is_locked: isLocked
          }),
          isEnabled: isEnabledToShow,
          toggleIsEnabled: () => this.toggleIsEnabled(!isEnabledToShow),
          hasMessage: this.state.title && this.state.title.length,
          isOpen: !isLocked,
          updateCloseDate: this.updateCloseDate,
          toggleIsLocked: () => this.toggleIsLocked(!isLocked),
          showMessageModal: this.showMessageModal,
          showEditColumnsModal: this.showEditColumnsModal,
          countOfFieldsSelected: visibleFields.length || 3, // @NOTE: We pass 3 here because we at least show 3
          showEditVariantLimitsModal: this.showEditVariantLimitsModal,
          countOfVariantsSelected: Object.keys(variantLimits).length,
          onSave: () => this.onSave(true),
          recordTypes,
          selectedRecordTypeId: contactRecordTypeId,
          updateRecordTypeId: this.updateContactRecordTypeId
        }}
      />
    );
  }
}

export default Controller;
