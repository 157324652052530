import React from "react";
import { Text1, Text6 } from "components/Base";

const Title = ({ children }) => <Text6 bold>{children}</Text6>;
const Breadcrumbs = ({ children }) => (
  <Text1 pb={2} uppercase bold color="neutral4">
    {children}
  </Text1>
);

export { Title, Breadcrumbs };
