import PropTypes from "prop-types";
import React, { Component, cloneElement, Children } from "react";
import autobind from "autobind-decorator";
import { get } from "lodash";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import IconButton from "material-ui/IconButton";
import SubmissionCard from "./SubmissionCard";
import getBadges from "components/Event/FormsV2/Overview/StatusBadges/getBadges";
import ContextMenu from "components/Event/FormsV2/Overview/SubmissionContextMenu";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import isLocked from "../../utils/isLocked";
import getSubmitter from "components/Event/FormsV2/Utils/getSubmitter";
import isSubmissionSynced from "components/Event/FormsV2/Utils/is-submission-synced";
import orderIsEventbrite from "components/Event/Credentials/Utils/eventbrite/order-is-eventbrite";
import getCredentialSourceBadge from "components/Event/Credentials/Utils/get-credential-source-badge";
import EmptyState from "./EmptyState";
import getReportSummary from "components/Global/CRM/Forms/utils/card-report-counts";

const renderCardActionButtons = (cardActionButtons, submission) => {
  if (typeof cardActionButtons === "function") {
    return cardActionButtons(submission);
  }

  return Children.map(cardActionButtons, c =>
    cloneElement(c, { submissionId: submission.id })
  );
};

@CSSModules(css)
class SwimLane extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: "asc"
    };
  }

  sort(submissions, direction) {
    const submitterCache = {};
    submissions.sort((a, b) => {
      const aSubmitter =
        a.id in submitterCache
          ? submitterCache[a.id]
          : (submitterCache[a.id] = getSubmitter(a.collaborators, {
              onlyShowAccountName: true
            }));
      const bSubmitter =
        b.id in submitterCache
          ? submitterCache[b.id]
          : (submitterCache[b.id] = getSubmitter(b.collaborators, {
              onlyShowAccountName: true
            }));
      return aSubmitter.localeCompare(bSubmitter);
    });

    if (direction === "desc") {
      return submissions.reverse();
    }
    return submissions;
  }

  @autobind
  onMenuChange(event, value) {
    switch (value) {
      case "sortAsc":
        this.setState({
          sort: "asc"
        });
        break;
      case "sortDesc":
        this.setState({
          sort: "desc"
        });
        break;
      default:
        break;
    }
  }

  render() {
    const {
      cardActionButtons,
      emptyActionButtons,
      emptyText,
      eventDetails,
      fields,
      fieldsToShow,
      getMetaData,
      hasSubforms,
      header,
      icon,
      onShowSubmissionSummary,
      onSubmissionMenuChange,
      lane,
      showSendConfirmation,
      submissions,
      submissionToOrderMap,
      orders
    } = this.props;

    return (
      <div styleName="container">
        <div styleName="innerContainer">
          <div styleName="headerContainer">
            <div styleName="icon">{icon}</div>
            <div styleName="labelContainer">
              <div styleName="header">{header}</div>
              <div styleName="submissionCount">
                {`${submissions.length} ${
                  submissions.length !== 1 ? "submissions" : "submission"
                }`}
              </div>
            </div>
            <div>
              <IconMenu
                onChange={this.onMenuChange}
                iconButtonElement={
                  <IconButton style={{ height: 24, width: 24, padding: 0 }}>
                    <i className="material-icons" styleName="optionsMenu">
                      keyboard_arrow_down
                    </i>
                  </IconButton>
                }
                anchorOrigin={{ horizontal: "left", vertical: "top" }}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
              >
                <MenuItem value="sortAsc" primaryText="Sort A-Z" />
                <MenuItem value="sortDesc" primaryText="Sort Z-A" />
              </IconMenu>
            </div>
          </div>
          {submissions.length ? (
            <div styleName="submissionsContainer">
              {this.sort(submissions, this.state.sort).map(submission => {
                const { values } = submission;
                let hasEventbrite = false;
                let synced = false;
                if (submission.id in submissionToOrderMap) {
                  const orderId = submissionToOrderMap[submission.id];
                  const order = orders[orderId];
                  if (order) {
                    hasEventbrite = orderIsEventbrite(order);
                    synced = isSubmissionSynced(
                      submission.id,
                      submissionToOrderMap,
                      orders
                    );
                  }
                }
                const summaries = getReportSummary(
                  submission,
                  eventDetails,
                  fields
                );
                return (
                  <SubmissionCard
                    key={submission.id}
                    actions={renderCardActionButtons(
                      cardActionButtons,
                      submission
                    )}
                    onShowSummary={(...args) =>
                      onShowSubmissionSummary(submission.id, ...args)
                    }
                    submitter={getSubmitter(submission.collaborators, {
                      onlyShowAccountName: true
                    })}
                    showSendConfirmation={showSendConfirmation}
                    showSummaries={this.props.showSummaries}
                    isLocked={isLocked(submission)}
                    badges={getBadges(submission, fields, { synced })}
                    contextMenu={
                      <ContextMenu
                        source="card"
                        status={lane}
                        synced={synced}
                        syncNeeded={hasEventbrite && !synced}
                        hasSubforms={hasSubforms}
                        singleSubmissionStatus={get(
                          submission,
                          "review.status"
                        )}
                        submission={submission}
                        onMenuChange={(...args) =>
                          onSubmissionMenuChange(submission, ...args)
                        }
                      >
                        <i
                          className={[css.menuIcon, "material-icons"].join(" ")}
                        >
                          more_vert
                        </i>
                      </ContextMenu>
                    }
                    summaries={summaries}
                    entries={fieldsToShow.reduce((entries, field) => {
                      if (field.id in values) {
                        entries.push({
                          label: field.name,
                          value: values[field.id],
                          field,
                          meta: getMetaData(submission, values, field)
                        });
                      }
                      return entries;
                    }, [])}
                  />
                );
              })}
            </div>
          ) : (
            <EmptyState
              actionButtons={emptyActionButtons}
              caption={emptyText}
            />
          )}
        </div>
      </div>
    );
  }
}

SwimLane.defaultProps = {
  submissionToOrderMap: {},
  orders: {}
};

SwimLane.propTypes = {
  lane: PropTypes.string.isRequired,
  emptyActionButtons: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.func
  ]),
  cardActionButtons: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.func
  ]),
  emptyText: PropTypes.string.isRequired,
  eventDetails: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  fieldsToShow: PropTypes.array.isRequired,
  getMetaData: PropTypes.func.isRequired,
  hasSubforms: PropTypes.bool.isRequired,
  header: PropTypes.string.isRequired,
  icon: PropTypes.node,
  onShowSubmissionSummary: PropTypes.func.isRequired,
  onSubmissionMenuChange: PropTypes.func.isRequired,
  showSendConfirmation: PropTypes.string,
  showSummaries: PropTypes.bool,
  submissions: PropTypes.array.isRequired,
  submissionToOrderMap: PropTypes.object,
  orders: PropTypes.object
};

export default SwimLane;
