import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import * as createPackage from "./createPackage";
import * as createItems from "./createItems";
import * as settings from "./settings";
import * as addItems from "./addItems";
import * as eventShowcase from "./eventShowcase";
import * as application from "./application";

const sharedIniState = {
  itemTypes: []
};

const iniState = {
  loading: false,
  ...sharedIniState,
  ...createPackage.iniState,
  ...createItems.iniState,
  ...settings.iniState,
  ...addItems.iniState,
  ...eventShowcase.iniState,
  ...application.iniState
};

const reducers = {
  init: R.identity,
  itemTypesRequest: R.identity,
  viewSalesPortal: R.identity,
  itemTypesResponse: (_, { payload: itemTypes }) => ({
    itemTypes
  }),
  ...createPackage.reducers,
  ...createItems.reducers,
  ...settings.reducers,
  ...addItems.reducers,
  ...eventShowcase.reducers,
  ...application.reducers
};

const handlers = createHandlers({
  iniState,
  reducers,
  namespace: "BoothSelfSetup"
});

export default handlers;
