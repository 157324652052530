import React from "react";
import * as R from "ramda";

import { ACTION_CELLS } from "Items/Manage/constants";

import { Div, MediumOutlineButton, Text1 } from "components/Base";
import { Paper, Header } from "./Common";
import { PassesTable, COLUMNS } from "./PassesTable";
import BulkActionsButton from "./BulkActionsButton";

import ArrayLayout from "ui-kit/ArrayLayout";

const ByOrder = ({
  order,
  passes,
  allCredentialLineItemIds,
  allMealLineItemIds,
  handlers,
  mode,
  parentOrderId = "",
  instanceId = ""
}) => {
  const buttons = [
    [
      order.id === parentOrderId ? (
        <Div />
      ) : (
        <MediumOutlineButton
          key="open"
          onClick={() => handlers.viewOrder(order.number, order.id)}
        >
          Open Order
        </MediumOutlineButton>
      ),
      <BulkActionsButton
        key="bulk"
        cardType="byOrder"
        mode={mode}
        handlers={handlers}
        meta={{
          orderId: order.id,
          allCredentialLineItemIds,
          allMealLineItemIds
        }}
      />
    ]
  ];

  const caption = (
    <Div display="row.flex-start.center" fs={1} ml={3} color="gray6">
      {[
        order.caption,
        `Order #${order.number}`,
        order.type,
        order.type !== "allocation" && order.collectNames
          ? "Collect Names"
          : null
      ]
        .filter(v => v && v.length)
        .map(t => (
          <Text1 mx={1} color="gray6" uppercase>
            {t}
          </Text1>
        ))
        .reduce((prev, curr) => [prev, " · ", curr])}
    </Div>
  );

  return (
    <Paper>
      <Header
        title={order.title}
        caption={caption}
        statuses={order.statuses}
        integrationStatuses={order.integrationStatuses}
      />
      <PassesTable
        columns={R.props(
          [
            "quantity",
            "item",
            "details",
            // "attendee",
            "order",
            ACTION_CELLS[mode]
          ],
          COLUMNS
        )}
        rows={passes}
        instanceId={instanceId}
      />
      <ArrayLayout components={buttons} m={2} />
    </Paper>
  );
};

ByOrder.defaultProps = {
  handlers: {},
  mode: "issuance"
};

export default ByOrder;
