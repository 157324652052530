import React from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { noop } from "utils/General";
import { Div, Text6, SearchIcon, BigOutlineButton } from "components/Base";

import {
  actions as SearchBarActions,
  getters as SearchBarGetters
} from "ui-kit/SearchBar";

const decorate = connect(
  (state, props) => ({
    searchTerm: SearchBarGetters.searchTerm(state, props)
  }),
  bindInstance({
    onClearSearch: SearchBarActions.clearSearch
  })
);

export const UCShowNoResults = ({
  searchTerm = "",
  onClearSearch = noop,
  ...styleProps
}) => (
  <Div
    height={300}
    display="column.center.center"
    bg="white"
    bra={1}
    {...styleProps}
  >
    <Div display="column.center.center">
      <SearchIcon size={48} />
      <Text6 bold mt={5}>
        {searchTerm.length ? `No results for "${searchTerm}"` : "No results"}
      </Text6>
      {searchTerm.length ? (
        <BigOutlineButton
          mt={8}
          bg="neutral5"
          bc="neutral5"
          color="white"
          onClick={onClearSearch}
        >
          Clear Search
        </BigOutlineButton>
      ) : null}
    </Div>
  </Div>
);

export default decorate(UCShowNoResults);
