import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import { get } from "lodash";
import MaskedInput from "react-text-mask";
import moment from "moment";
import createDateMask from "./createDateMask";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const FORMAT = "YYYY-MM-DD";
const DISPLAY_FORMAT = "L";

@CSSModules(css)
class FormDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.formatValue(props.value)
    };
  }

  componentWillUpdate(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        value: this.formatValue(nextProps.value)
      });
    }
  }

  formatValue(value) {
    return get(value, "value") && moment(value.value).isValid()
      ? moment(value.value, FORMAT).format(DISPLAY_FORMAT)
      : undefined;
  }

  wrapValue(value) {
    return {
      type: "date",
      value: value ? moment(value, DISPLAY_FORMAT).format(FORMAT) : undefined
    };
  }

  getLocale() {
    return window.navigator.userLanguage || window.navigator.language;
  }

  @autobind
  onChange(e) {
    this.setState(
      {
        value: e.target.value
      },
      () => {
        if (this.props.continuous && moment(this.state.value).isValid()) {
          this.props.onChange(this.wrapValue(this.state.value));
        }
      }
    );
  }

  @autobind
  onBlur() {
    this.props.onChange(this.wrapValue(this.state.value));
  }

  render() {
    moment.locale(this.getLocale());
    const { disabled, placeholder, required } = this.props;

    return (
      <MaskedInput
        disabled={disabled}
        required={required}
        value={this.state.value}
        placeholder={placeholder || moment().format(DISPLAY_FORMAT)}
        onChange={this.onChange}
        onBlur={this.onBlur}
        placeholderChar={"\u2000"}
        mask={createDateMask(
          moment.localeData().longDateFormat(DISPLAY_FORMAT)
        )}
        styleName="input"
        type="text"
      />
    );
  }
}

FormDate.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.object
};

export default FormDate;
