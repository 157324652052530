import React from "react";
import { createContext } from "redux-mvc";
import { connect } from "react-redux";
import * as R from "ramda";
import module, { actions } from "../index";
import Layout from "./Layout";
import rootSaga from "../sagas";
import { showModal } from "redux/modules/modal/actions";
import CreateOrderModal from "Orders/CreateOrderModal";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { withRouter } from "react-router";
import { INVENTORY_TYPE_ID } from "utils/item-types";

module.setRootSaga(rootSaga);

const decorate = createContext({
  module: module,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(actions.init());
    }
  },
  handlers: {
    showCreateOrderModal: function() {
      this.props.showModal({
        content: (
          <CreateOrderModal
            itemTypeId={INVENTORY_TYPE_ID}
            onDone={() => {
              this.store.dispatch(actions.refetchData({ stats: true }));
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    goToCategory: function(categoryId) {
      this.props.router.push({
        pathname: `/event-light/${this.props.params.eventId}/inventory/requests/all-requests/category/${categoryId}`
      });
    },
    goToItem: function(itemId) {
      this.props.router.push({
        pathname: `/event-light/${this.props.params.eventId}/inventory/requests/all-requests/item-type/${itemId}`
      });
    }
  },
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: [
      "user",
      "event",
      "modal",
      "organization",
      "items",
      "fields",
      "routing"
    ]
  }
});

export default R.compose(
  connect(
    null,
    { showModal }
  ),
  withRouter,
  decorate
)(Layout);
