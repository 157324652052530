import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Panel from "components/Global/Module/Management/Panel";
import { withRouter } from "react-router";

import * as ModalActions from "redux/modules/modal/actions";
import * as EventSelectors from "redux/modules/event/selectors";
import { countsByModuleAndStatus } from "redux/modules/inventory/modules/counts/selectors";
import { getModuleCounts } from "redux/modules/inventory/modules/counts/actions";
import { getRecords } from "redux/modules/modules/records/actions";
import {
  createReview,
  createReviews,
  removeReview,
  removeReviews
} from "redux/modules/approvalWorkflows/reviews/actions";
import { records, fields } from "redux/modules/modules/records/selectors";
import selectedRowFactory from "components/Global/CRM/TableViews/HelperComponents/selected-row-factory";
import exportFactory from "components/Global/CRM/TableViews/HelperComponents/export-factory";
import cachedStatusFilterRowFactory from "components/Global/Module/Management/Panel/HelperComponents/status-filter-cached-row-factory.js";
import Table from "components/Event/InventoryV2/ModuleTable";

function mapStateToProps(state, props) {
  const eventId = EventSelectors.eventDetails(state).id;
  return {
    cachedCount: countsByModuleAndStatus(props.parent.id, props.status, state),
    records: records(state, props.parent.id),
    eventId,
    fields: fields(state, props.parent.id),
    returnTo: `/event/${eventId}/inventory/needs/status/${props.status}`,
    Table
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createReview,
      createReviews,
      removeReview,
      removeReviews,
      getModuleCounts,
      getRecords,
      ...ModalActions
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    cachedStatusFilterRowFactory(selectedRowFactory(exportFactory(Panel)))
  )
);
