import * as R from "ramda";
import React from "react";

import { getValue } from "../../utils";

import User from "./User";
import Submission from "./Submission";
import EventbriteOrder from "./EventbriteOrder";
import Unknown from "./Unknown";

const resolveFormatter = {
  user: User,
  submission: Submission,
  "eventbrite-order": EventbriteOrder
};

const Source = props => {
  const type = getValue(props, "type");
  const formatter = R.prop(type, resolveFormatter) || Unknown;

  return React.createElement(formatter, props);
};

export default Source;
