import React from "react";

import { connect } from "react-redux";

import { HEIGHTS } from "Items/EditQuestionSetQuestionsModal/constants";

import { getTitle } from "Items/EditQuestionSetQuestionsModal/selectors";

import { Div, CloseIcon } from "components/Base";

const decorate = connect(state => ({
  text: getTitle(state)
}));

const Header = ({ hideModal, text }) => (
  <Div
    width={1}
    height={HEIGHTS.HEADER}
    display="row.space-between.center"
    px={4}
    bg="primary8"
  >
    <Div fs={4} fw={3} color="white">
      {text}
    </Div>
    <CloseIcon color="white" size={24} onClick={hideModal} />
  </Div>
);

export default decorate(Header);
