import * as R from "ramda";
import { useState, useMemo, useEffect } from "react";

import moment from "moment";

const getEventOffset = ({ events, currentTime }) => {
  if (moment(R.path([0, "start"], events)).isAfter(currentTime, "hour")) {
    return 0;
  }

  if (
    moment(R.path([events.length - 1, "end"], events)).isBefore(
      currentTime,
      "hour"
    )
  ) {
    return events.length;
  }

  return R.reduce(
    (pos, nextEvent) =>
      moment(currentTime).isBefore(moment(nextEvent.start)) ||
      moment(currentTime).isBetween(
        moment(nextEvent.start),
        moment(nextEvent.end)
      )
        ? R.reduced(pos)
        : pos + 1,
    0,
    events
  );
};

const useTimeline = ({ events = [], timezone, delta = 5000 }) => {
  const [currentTime, setCurrentTime] = useState(moment().utc());
  useEffect(() => {
    const timeout = setInterval(() => setCurrentTime(moment().utc()), delta);
    return () => clearInterval(timeout);
  }, [delta]);
  const eventOffset = useMemo(
    () =>
      getEventOffset({
        timezone,
        currentTime,
        events
      }),
    [currentTime, events, timezone]
  );
  const event = events[eventOffset];
  const minuteOffset = useMemo(() => {
    if (!event || !moment(currentTime).isBetween(event.start, event.end)) {
      return 0;
    }
    const total = moment(event.end).diff(event.start);
    const elapsed = moment(currentTime).diff(event.start);
    if (total > 0) {
      return elapsed / total;
    }
    return 0;
  }, [currentTime, event]);

  return { eventOffset, minuteOffset };
};

export { useTimeline };
