import PropTypes from "prop-types";
import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Textarea from "components/Global/Modal/Layout/FormElements/Textarea";
import {
  ButtonGroup,
  ButtonOutline,
  ButtonOrange
} from "components/Global/Modal/Layout/Buttons";
import Tabs from "components/Global/CRM/Tabs";
import Tab from "components/Global/CRM/Tabs/Tab";
import Badge from "components/Global/CRM/UserTags/AvatarNameEmail";
import { CONTACT_STANDARD_FIELD_IDS } from "components/Event/Contacts/constants";
import { get } from "lodash";
import CopyToClipboard from "react-copy-to-clipboard";

const fullName = values => {
  const fname = get(
    values.find(v => v.field_id === CONTACT_STANDARD_FIELD_IDS.fname),
    ["value", "value"],
    ""
  );
  const lname = get(
    values.find(v => v.field_id === CONTACT_STANDARD_FIELD_IDS.lname),
    ["value", "value"],
    ""
  );
  if (fname) {
    return `${fname} ${lname}`;
  }
  return lname;
};

const email = values =>
  get(
    values.find(v => v.field_id === CONTACT_STANDARD_FIELD_IDS.email),
    ["value", "value"],
    ""
  );

class LoginDetailsModal extends Component {
  state = { copied: false, message: "" };

  handleCopy = () => {
    this.textarea.selectText();
    this.setState({ copied: true }, () =>
      setTimeout(() => this.setState({ copied: false }), 2500)
    );
  };

  handleMessageChange = ({ target }) =>
    this.setState({ message: target.value });

  handleSendEmail = () => {
    this.props.hideModal();
    this.props.onEmailSend({
      customMessage: this.state.message,
      email: this.props.email || email(this.props.row.values)
    });
  };

  onMessageChange = e => this.setState({ message: e.target.value });

  render() {
    const { hideModal, showFlashMessage, row, eventDetails } = this.props;
    const url = `${window.__LENND_APP_URL__}/portal-login/${
      eventDetails.slug
    }/${eventDetails.uuid}?email=${this.props.email || email(row.values)}`;

    return (
      <StyleWrapper
        bodyStyles={{ padding: 0 }}
        hideModal={hideModal}
        heading="Share login details"
        width={500}
      >
        {showFlashMessage ? (
          <div styleName="flash">
            <div styleName="icon">
              <i className="material-icons">check</i>
            </div>
            Login access granted
          </div>
        ) : (
          ""
        )}
        <div styleName="body">
          <div styleName="badgeWrapper">
            <Badge
              email={this.props.email || email(row.values)}
              fullName={this.props.name || fullName(row.values)}
            />
          </div>
          <Tabs style={{ fontSize: 15 }}>
            <Tab label="Email">
              <div styleName="tabBody">
                <div styleName="caption">
                  Include optional message with the email
                </div>
                <Textarea
                  rows={5}
                  placeholder="Optional: Add a personal message"
                  onChange={this.onMessageChange}
                />
                <ButtonGroup>
                  <ButtonOrange
                    title="Send Email"
                    onClick={this.handleSendEmail}
                  />
                  <ButtonOutline title="Not Now" onClick={hideModal} />
                </ButtonGroup>
              </div>
            </Tab>
            <Tab label="Get Link">
              <div styleName="tabBody">
                <div styleName="caption">Copy the link below:</div>
                <Textarea
                  ref={ref => {
                    this.textarea = ref;
                  }}
                  rows={5}
                  value={url}
                />
                <ButtonGroup>
                  <CopyToClipboard text={url} onCopy={this.handleCopy}>
                    <ButtonOrange
                      title={
                        this.state.copied ? (
                          <div className={css.copied}>
                            <i className="material-icons">check</i>
                            Copied!
                          </div>
                        ) : (
                          "Copy Link"
                        )
                      }
                    />
                  </CopyToClipboard>
                  <ButtonOutline title="Not Now" onClick={hideModal} />
                </ButtonGroup>
              </div>
            </Tab>
          </Tabs>
        </div>
      </StyleWrapper>
    );
  }
}

LoginDetailsModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  row: PropTypes.array,
  eventDetails: PropTypes.shape({
    uuid: PropTypes.string,
    slug: PropTypes.string
  }).isRequired,
  showFlashMessage: PropTypes.bool,
  onEmailSend: PropTypes.func.isRequired
};

export default CSSModules(LoginDetailsModal, css);
