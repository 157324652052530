import React from "react";
import {
  Div,
  PersonIcon,
  PeopleIcon,
  Radio,
  AsyncCreatableSelect,
  RightIcon,
  DownIcon,
  collapsableHandler,
  Collapsable,
  TinyToggle
} from "components/Base";
import * as R from "ramda";
import { withProps } from "utils/General";
import RequestSummary from "Orders/CreateOrderModal/RequestSummary";

import TextEditor from "components/Global/StandAloneEditors/Text";

const Description = withProps({
  color: "gray6",
  fs: 1,
  noLineHeight: true
})(Div);

const RecordFinder = withProps({
  clearable: true
})(AsyncCreatableSelect);

const getEntityType = isAllocation => (isAllocation ? "Allocation" : "Order");

const Fields = ({ children }) => (
  <Div width={340} ml={5} py={2} mb={4}>
    {children}
  </Div>
);

const Label = ({ children, required, description }) => (
  <Div display="column" mb={1}>
    <Div fw={3} color="gray7" fs={2}>
      {children}
      {required ? "*" : null}
    </Div>
    {description && description.length ? (
      <Description>{description}</Description>
    ) : null}
  </Div>
);

const TypeSwitch = ({ name, description, Icon, selected, onClick }) => (
  <Div
    display="row.flex-start.center"
    bg={
      selected
        ? "gray0"
        : {
            default: "white",
            hover: "gray0"
          }
    }
    onClick={onClick}
    bra={1}
    mb={1}
    p={2}
  >
    <Radio selected={selected} mr={1} size={20} />
    <Icon size={20} />
    <Div display="column" ml={2}>
      <Div fs={3} fw={3} color="gray7">
        {name}
      </Div>
      <Description>{description}</Description>
    </Div>
  </Div>
);

const Details = collapsableHandler(
  ({
    onSave,
    onPreviousStep,
    onChangeOrderType,
    onChangeCustomerAccount,
    onChangeCustomerContact,
    onChangeCustomerContactEmail,
    onCreateCustomerAccount,
    onCreateCustomerContact,
    onSearchAccounts,
    onSearchContacts,
    toggleRequireAssignment,
    //
    saving,
    isAllocation,
    isValidStep,
    orderType,
    customerAccount,
    customerContact,
    customerContactEmail,
    selectedVariants,
    fulfillmentMethod,
    fulfillmentMethods,
    requireAssignment,
    orderFields,
    pickupFields,
    shippingFields,
    subtotal,
    total,
    collapsed,
    toggleCollapsed,
    itemTypes,
    removeItem
  }) => {
    return (
      <Div
        height={600}
        display="row.flex-start.flex-start"
        bg="neutral0"
        style={{ overflowY: "auto" }}
      >
        <Div height={1} flex={1} bc="neutral2" br={1}>
          <Div display="row" px={8} py={7}>
            <Div width={0.5} pr={4}>
              <Div bg="white" shadow={1} bra={2} p={4} mb={8}>
                <Div fs={4} fw={3} color="neutral7" mb={2}>
                  Type of {getEntityType(isAllocation)}
                </Div>
                <Div>
                  {isAllocation ? null : (
                    <TypeSwitch
                      name={`Individual ${getEntityType(isAllocation)}`}
                      description="Items held under one person’s name."
                      Icon={PersonIcon}
                      selected={orderType === "individual"}
                      onClick={() => onChangeOrderType("individual")}
                    />
                  )}
                  <TypeSwitch
                    name={`Group ${getEntityType(isAllocation)}`}
                    description="Items held under a group's name."
                    Icon={PeopleIcon}
                    selected={orderType === "group"}
                    onClick={() => onChangeOrderType("group")}
                  />
                </Div>
              </Div>

              {orderType === "individual" ? (
                <Div bg="white" shadow={1} bra={2} p={4}>
                  <Div fs={4} fw={3} mb={2} color="neutral7">
                    Attendee Information
                  </Div>
                  <Div>
                    <Label
                      description="Items will be held under this person's name"
                      required
                    >
                      Attendee Name
                    </Label>
                    <RecordFinder
                      placeholder="Find or create a person..."
                      loadOptions={onSearchContacts}
                      onChange={onChangeCustomerContact}
                      onCreate={onCreateCustomerContact}
                      value={customerContact}
                      clearable
                    />
                    <Div mb={2} mt={3}>
                      <Label required>Attendee Email</Label>
                      <TextEditor
                        onChange={onChangeCustomerContactEmail}
                        value={customerContactEmail}
                      />
                    </Div>

                    <Div height={15}>&nbsp;</Div>
                    <Label description="Optional. Group that the attendee is representing.">
                      Group Name
                    </Label>
                    <RecordFinder
                      placeholder="Find or create a group..."
                      loadOptions={onSearchAccounts}
                      onChange={onChangeCustomerAccount}
                      onCreate={onCreateCustomerAccount}
                      value={customerAccount}
                      clearable
                    />
                  </Div>
                </Div>
              ) : (
                <Div bg="white" shadow={1} bra={2} p={4} mb={8}>
                  <Div fs={4} fw={3} mb={2} color="neutral7">
                    Group Information
                  </Div>
                  <Div>
                    <Label
                      description="Items will be held under this group's name"
                      required
                    >
                      Group Name
                    </Label>
                    <RecordFinder
                      placeholder="Find or create a group..."
                      loadOptions={onSearchAccounts}
                      onChange={onChangeCustomerAccount}
                      onCreate={onCreateCustomerAccount}
                      value={customerAccount}
                      clearable
                    />

                    <Div height={15}>&nbsp;</Div>
                    <Label
                      description="Point of contact for this order."
                      required
                    >
                      Person Responsible
                    </Label>
                    <RecordFinder
                      placeholder="Find or create a person..."
                      loadOptions={onSearchContacts}
                      onChange={onChangeCustomerContact}
                      onCreate={onCreateCustomerContact}
                      value={customerContact}
                      clearable
                    />
                    <Div mb={2} mt={3}>
                      <Label required>Email of Person Responsible</Label>
                      <TextEditor
                        onChange={onChangeCustomerContactEmail}
                        value={customerContactEmail}
                      />
                    </Div>
                  </Div>
                </Div>
              )}
            </Div>

            <Div width={0.5} pl={4}>
              <Div bg="white" shadow={1} bra={2} p={4} mb={8}>
                <Div fs={4} fw={3} mb={2} color="neutral7">
                  Fulfillment Method
                </Div>
                <Div>
                  {R.map(method => (
                    <Div
                      key={method.id}
                      display="row.flex-start.center"
                      onClick={method.onSelect}
                      mb={1}
                    >
                      <Radio selected={method.selected} mr={2} size={20} />
                      <Div fw={3} fs={2} color="gray7">
                        {method.name}
                      </Div>
                    </Div>
                  ))(fulfillmentMethods)}
                  {fulfillmentMethod === "pickup" ? (
                    <Div>
                      {R.map(
                        ({
                          id,
                          name,
                          Editor,
                          editorProps,
                          onChange,
                          value
                        }) => (
                          <Div key={id} mb={2}>
                            <Label>{name}</Label>
                            <Editor
                              onChange={onChange}
                              value={value}
                              {...editorProps}
                            />
                          </Div>
                        ),
                        pickupFields
                      )}
                    </Div>
                  ) : null}
                  {fulfillmentMethod === "mail" ? (
                    <Div>
                      {R.map(
                        ({
                          id,
                          name,
                          Editor,
                          editorProps,
                          onChange,
                          value
                        }) => (
                          <Div key={id} mb={2}>
                            <Label>{name}</Label>
                            <Editor
                              onChange={onChange}
                              value={value}
                              {...editorProps}
                            />
                          </Div>
                        ),
                        shippingFields
                      )}
                    </Div>
                  ) : null}
                </Div>
              </Div>

              {orderType === "group" ? (
                <Div bg="white" shadow={1} bra={2} p={4} mb={4}>
                  <Div fs={4} fw={3} mb={2} color="neutral7">
                    Collect Attendee Names
                  </Div>
                  <Div>
                    <Div
                      display="row.flex-start.center"
                      onClick={toggleRequireAssignment}
                    >
                      <TinyToggle
                        active={requireAssignment}
                        onClick={() => {}}
                      />
                      <Div ml={2}>
                        <Div fw={3} fs={2} color="gray7">
                          {requireAssignment
                            ? "Show on Assignment Manager"
                            : "Don't show on Assignment Manager"}
                        </Div>
                        <Div fs={1} color="gray5">
                          {requireAssignment
                            ? "Items can be assigned to additional attendees"
                            : "Order items will not show on the assignment manager"}
                        </Div>
                      </Div>
                    </Div>
                  </Div>
                </Div>
              ) : null}

              {orderFields.length ? (
                <Div bg="white" shadow={1} bra={2} pt={3} pb={2}>
                  <Div
                    display="row.flex-start.center"
                    onClick={toggleCollapsed}
                  >
                    <Div fs={4} fw={3} pl={6} color="neutral7">
                      Other Information
                    </Div>
                    {collapsed ? (
                      <RightIcon size={20} color="gray5" />
                    ) : (
                      <DownIcon size={20} color="gray5" />
                    )}
                  </Div>
                  <Collapsable collapsed={collapsed}>
                    <Fields>
                      {R.map(
                        ({
                          id,
                          name,
                          Editor,
                          editorProps,
                          onChange,
                          value
                        }) => (
                          <Div key={id} mb={2}>
                            <Label>{name}</Label>
                            <Editor
                              onChange={onChange}
                              value={value}
                              {...editorProps}
                            />
                          </Div>
                        ),
                        orderFields
                      )}
                    </Fields>
                  </Collapsable>
                </Div>
              ) : null}
            </Div>
          </Div>
        </Div>
        <RequestSummary
          selectedVariants={selectedVariants}
          itemTypes={itemTypes}
          onPreviousStep={onPreviousStep}
          total={total}
          subtotal={subtotal}
          isLastStep
          nextBtnDisabled={!isValidStep || saving}
          saving={saving}
          removeItem={removeItem}
          onNextStep={onSave}
          btnBg="teal6"
          btnText="SUBMIT ORDER"
        />
      </Div>
    );
  }
);

export default Details;
