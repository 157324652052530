import React from "react";

import FileViewer from "components/Global/Modals/FileViewer";
import FileViewerWrapper from "components/Global/Modals/FileViewer/Wrapper";
import ItemSummaryModal from "components/Event/InventoryV2/Modals/ItemSummary";
import DocumentRequestModal from "Portal/PortalDocuments/RequestModal";
import ViewOrderModal from "Orders/OrderModal/View";
import ViewRecord from "components/Global/Module/Modals/ViewRecord";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { FILES, CATALOG_ITEMS } from "utils/standard-module-field-ids";
import { get } from "lodash";

const showFileViewer = ({ file, helpers }) => {
  helpers.showModal({
    content: <FileViewer files={[file]} isReadOnly />,
    wrapper: FileViewerWrapper
  });
};

const showDocumentRequest = ({ recordId, helpers }) => {
  helpers.showModal({
    content: (
      <DocumentRequestModal
        view="admin"
        recordId={recordId}
        hideModal={helpers.hideModal}
      />
    ),
    wrapper: ModalWrapper
  });
};

const showRecord = ({ moduleId, recordId, helpers }) => {
  helpers.showModal({
    content: (
      <ViewRecord
        moduleId={moduleId}
        recordId={recordId}
        hideModal={helpers.hideModal}
      />
    ),
    wrapper: ModalWrapper
  });
};

const showOrder = ({ recordId, helpers }) => {
  helpers.showModal({
    content: (
      <ViewOrderModal orderId={recordId} hideModal={helpers.hideModal} />
    ),
    wrapper: ModalWrapper
  });
};

const showCatalogItem = ({ catalogItem, helpers }) => {
  helpers.showModal({
    content: (
      <ItemSummaryModal
        catalogItem={catalogItem}
        hideModal={helpers.hideModal}
      />
    ),
    wrapper: ModalWrapper
  });
};

export default function resolveOnClick({ orgId, eventId, moduleId, helpers }) {
  return props => {
    switch (moduleId) {
      case STANDARD_MODULE_IDS.contacts.id:
        helpers.router.push({
          pathname: eventId
            ? `/event/${eventId}/contact/${props.original.id}`
            : `/organization/${orgId}/contact/${props.original.id}`
        });
        return null;
      case STANDARD_MODULE_IDS.accounts.id:
        helpers.router.push({
          pathname: eventId
            ? `/event/${eventId}/account/${props.original.id}`
            : `/organization/${orgId}/account/${props.original.id}`
        });
        return null;
      case STANDARD_MODULE_IDS.files.id:
        return showFileViewer({
          file: {
            url: get(props.original.values[FILES.URL], "value"),
            filename: get(props.original.values[FILES.FILENAME], "value"),
            mimetype: get(props.original.values[FILES.MIMETYPE], "value")
          },
          helpers
        });
      case STANDARD_MODULE_IDS.documentRequests.id:
        return showDocumentRequest({
          recordId: props.original.id,
          helpers
        });
      case STANDARD_MODULE_IDS.orders.id:
        return showOrder({
          recordId: props.original.id,
          helpers
        });
      case STANDARD_MODULE_IDS.catalogItems.id:
        return showCatalogItem({
          catalogItem: {
            id: props.original.id,
            value: get(props.original.values[CATALOG_ITEMS.ITEM_NAME], "value")
          },
          helpers
        });
      case STANDARD_MODULE_IDS.formsv3.id:
        helpers.router.push({
          pathname: eventId
            ? `/event/${eventId}/forms-v3/${props.original.form_id}/preview`
            : `/organization/${orgId}/forms-v3/${props.original.form_id}/preview`
        });
        return null;
      default:
        if (helpers.showFullRecord) {
          helpers.router.push({
            pathname: eventId
              ? `/event/${eventId}/module/${moduleId}/records/${props.original.id}`
              : `/organization/${orgId}/module/${moduleId}/records/${props.original.id}`
          });
        } else {
          return showRecord({
            moduleId,
            recordId: props.original.id,
            helpers
          });
        }
        return null;
    }
  };
}
