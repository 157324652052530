import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Manager from "../Manager";
import Placeholder from "../Scenes/Placeholder";
import Group from "../Scenes/Group";
import List from "../Scenes/List";
import Person from "../Scenes/Person";
import Popover from "@lennd/material-ui/Popover";
import constructName from "../utils/construct-name";
import constructPlaceholder from "../utils/construct-placeholder";
import isPerson from "../utils/is-person";
import listensToClickOutside from "react-onclickoutside";
import ReactDOM from "react-dom";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

const defaults = {
  open: false,
  display: "",
  scene: "list",
  processing: false,
  inputValue: ""
};

@listensToClickOutside
@CSSModules(css)
export default class Reference extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...defaults,
      open: Boolean(props.open)
    };
  }

  componentDidMount() {
    this.setState({
      anchorEl: ReactDOM.findDOMNode(this.searchInput)
    });
  }

  @autobind
  onSelect(event, id) {
    if (id) {
      this.handleRequestClose();
    }
    this.props.onSelect(id);
  }

  @autobind
  onSearch(event) {
    this.setState({
      inputValue: event.target.value
    });
  }

  @autobind
  onSearchKeyUp(event) {
    // Delete key
    if (event.which === 8 && !this.state.inputValue.length) {
      this.clearValue();
    }
  }

  @autobind
  onRequestClose(reason) {
    if (reason === "offScreen") {
      this.handleRequestClose();
    }
  }

  @autobind
  changeScene(scene, direction) {
    if (!this.state.processing) {
      this.setState({
        scene,
        direction
      });
    }
  }

  @autobind
  handleOpen(event) {
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget
    });
  }

  handleClickOutside() {
    // Using listensToClickOutside here instead of popover's because
    // we need to manage multiple popovers.
    this.handleRequestClose();
  }

  @autobind
  handleRequestClose() {
    if (!this.state.processing) {
      this.setState({ ...defaults });
    }
  }

  @autobind
  clearValue() {
    this.onSelect(null, null);
  }

  @autobind
  async createPerson(first, last) {
    this.setState({
      processing: true
    });

    try {
      const reference = await this.props.addReferenceDangerous({
        type: "user",
        first,
        last
      });

      this.setState(
        {
          processing: false
        },
        () => this.onSelect(null, reference.id)
      );
    } catch (e) {
      this.setState({
        processing: false
      });

      throw e; // Rethrow for scene
    }
  }

  @autobind
  async createGroup(placeholder, count, owner) {
    this.setState({
      processing: true
    });

    try {
      const reference = await this.props.addReferenceDangerous({
        type: "placeholder",
        placeholder,
        count,
        owner
      });

      this.setState(
        {
          processing: false
        },
        () => this.onSelect(null, reference.id)
      );
    } catch (e) {
      this.setState({
        processing: false
      });

      throw e; // Rethrow for scene
    }
  }

  render() {
    const {
      addReferenceDangerous,
      disabled,
      isFetching,
      placeholder,
      references,
      referenceValues,
      value,
      types
    } = this.props;

    const scenes = {
      list: (
        <List
          isFetching={isFetching}
          changeScene={this.changeScene}
          onSelect={this.onSelect}
          references={references.filter(r => types.includes(r.type))}
          referenceValues={referenceValues}
          search={this.state.inputValue}
          types={types}
        />
      ),
      person: (
        <Person
          changeScene={this.changeScene}
          close={this.handleRequestClose}
          createPerson={this.createPerson}
          onSelect={this.onSelect}
          search={this.state.inputValue}
          allowPlaceholder={types.includes("placeholder")}
        />
      ),
      placeholder: (
        <Placeholder
          changeScene={this.changeScene}
          close={this.handleRequestClose}
          createGroup={this.createGroup}
          search={this.state.inputValue}
          onSelect={this.onSelect}
        />
      ),
      group: (
        <Group
          addReferenceDangerous={addReferenceDangerous}
          changeScene={this.changeScene}
          close={this.handleRequestClose}
          createGroup={this.createGroup}
          onSelect={this.onSelect}
          search={this.state.inputValue}
          references={references}
        />
      )
    };

    return (
      <div styleName="container">
        <div styleName="searchContainer">
          <input
            ref={c => {
              this.searchInput = c;
            }}
            disabled={disabled}
            className="ignore-react-onclickoutside"
            styleName="searchInput"
            onFocus={this.handleOpen}
            value={this.state.inputValue}
            onKeyDown={this.onSearchKeyUp}
            onChange={this.onSearch}
            autoComplete="off"
            placeholder={
              value
                ? isPerson(value)
                  ? constructName(value.first, value.last)
                  : constructPlaceholder(value.placeholder, value.count)
                : placeholder
            }
            type="text"
          />
          <div styleName="searchIcons">
            {value && !disabled ? (
              <a
                tabIndex="0"
                className={["material-icons", css.deleteIcon].join(" ")}
                onClick={this.clearValue}
              >
                close
              </a>
            ) : (
              ""
            )}
            <i className={["material-icons", css.searchIcon].join(" ")}>
              search
            </i>
          </div>
        </div>

        <Popover
          animated={false}
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom"
          }}
          transformOrigin={{
            horizontal: "left",
            vertical: "top"
          }}
          open={this.state.open}
          canAutoPosition={false}
          anchorEl={this.state.anchorEl}
          useLayerForClickAway={false}
          onClose={this.onRequestClose}
        >
          <div className="ignore-react-onclickoutside">
            <Manager
              current={this.state.scene}
              direction={this.state.direction}
              scenes={scenes}
            />
          </div>
        </Popover>
      </div>
    );
  }
}

Reference.propTypes = {
  addReferenceDangerous: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isFetching: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  open: PropTypes.bool,
  placeholder: PropTypes.string,
  references: PropTypes.array.isRequired,
  referenceValues: PropTypes.object.isRequired,
  types: PropTypes.array.isRequired,
  value: PropTypes.object
};
