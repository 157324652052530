import * as R from "ramda";
import { createHandlers, createModule } from "redux-mvc";
import { DEFAULT_ICON_GROUP } from "components/Global/OptionSelector/AccountsOption/IconSelector";
import {
  DEFAULT_TIME_FORMAT,
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_ZONE,
  DEFAULT_DATE_TIME_OPTIONS,
  SCHEDULE_TYPE
} from "./constants";

import allowAccessUsersModal from "Schedules/AllowAccessUsersModal";

const iniState = Object.freeze({
  scheduleName: "New Schedule",
  color: { backgroundColor: "rgb(29,115,191)", textColor: "#FFFFFF" },
  icon: DEFAULT_ICON_GROUP[0],
  sharing: false,
  moreOptions: false,
  dateTimeOptions: DEFAULT_DATE_TIME_OPTIONS,
  timeFormat: DEFAULT_TIME_FORMAT,
  dateFormat: DEFAULT_DATE_FORMAT,
  timeZone: DEFAULT_TIME_ZONE,
  showOnPortals: false,
  showOnProfileRecords: false,
  loading: false,
  redirectAfterSave: true,
  scheduleId: null,
  scheduleType: SCHEDULE_TYPE.SCHEDULE
});

const handlers = createHandlers({
  iniState,
  namespace: "NewSchedule",
  reducers: {
    selectDateTimeOptions: (state, { payload }) => ({
      dateTimeOptions: R.symmetricDifference(state.dateTimeOptions, [payload])
    }),
    saveNewSchedule: R.identity,
    updateSchedule: R.identity,
    createNewScheduleResponse: R.identity,
    init: R.identity,
    reset: () => ({ ...iniState }),
    setInitialData: (
      _,
      {
        payload: {
          name,
          color,
          icon,
          is_private,
          is_project,
          datetime_options,
          date_format,
          time_format,
          timezone,
          show_on_portals,
          show_on_profiles
        }
      }
    ) => ({
      scheduleName: name,
      color: { backgroundColor: color, textColor: "#FFFFFF" },
      icon,
      sharing: is_private,
      dateTimeOptions: datetime_options,
      dateFormat: date_format,
      timeFormat: time_format,
      timeZone: timezone,
      showOnPortals: show_on_portals,
      showOnProfileRecords: show_on_profiles,
      scheduleType: is_project ? SCHEDULE_TYPE.PROJECT : SCHEDULE_TYPE.SCHEDULE
    })
  }
});

const module = createModule(handlers);

module.plugModule(allowAccessUsersModal);

const { actions, getters } = module;

export { actions, getters };

export default module;
