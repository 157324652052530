import { RECEIVE_INVOICE } from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getInvoice(invoiceId) {
  return async (dispatch, getState) => {
    try {
      const result = await api.get(getState().user.user.credentials, invoiceId);
      dispatch({
        type: RECEIVE_INVOICE,
        payload: {
          invoiceId: result.payload.id,
          invoice: result.payload
        }
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting invoice"
          }
        ])
      );
    }
  };
}

export function addInvoiceByOrderId(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.addInvoiceByOrderId(
        getState().user.user.credentials,
        data
      );
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding invoice by order ID"
          }
        ])
      );
    }
  };
}

export function addInvoiceTransaction(data) {
  return async (dispatch, getState) => {
    // @NOTE: We rely on the calling method to catch the error here
    const result = await api.addInvoiceTransaction(
      getState().user.user.credentials,
      data
    );
    return result.payload;
  };
}
