import Helpers from "utils/Global/Helpers";

module.exports = {
  get: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/account/event/${
          data.eventId
        }/reports/credentials?accountId=${data.accountId}`,
        credentials,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */
};
