import { get } from "lodash";
import formatCatalogItemSingleQuantity from "./format-rows-catalog-item-single-quantity";
import formatCatalogItem from "./format-rows-catalog-item";

export default (records, fields, { eventDetails }) => {
  const fieldsHash = fields.reduce((a, b) => {
    a[b.id] = b;
    return a;
  }, {});

  return records.reduce((all, record) => {
    const values = Object.keys(record.values).reduce((allValues, fieldId) => {
      allValues[fieldId] = record.values[fieldId];

      // handle catalog item
      if (
        get(record, ["values", fieldId, "type"]) === "catalog-item" &&
        get(fieldsHash, [fieldId, "settings", "selectType"]) ===
          "single-quantity"
      ) {
        return formatCatalogItemSingleQuantity({
          record,
          fieldId,
          fieldsHash,
          allValues
        });
      } else if (get(record, ["values", fieldId, "type"]) === "catalog-item") {
        return formatCatalogItem({ record, fieldId, allValues });
      }

      return allValues;
    }, {});

    return [...all, Object.assign({}, record, { values })];
  }, []);
};
