import { createModule } from "redux-mvc";

import AddScheduleModalModule from "Schedules/Create";

import handlers from "Forms/WizardModal/handlers";

const module = createModule(handlers);

module.plugModule(AddScheduleModalModule);

const { actions, getters } = module;

export { actions, getters };

export default module;
