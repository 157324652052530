import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import { Div, Text1, Text2 } from "components/Base";
import { addS } from "utils/General";
import PersonCard from "./PersonCard";

const decorate = connect(
  state => ({
    contacts: getters.contacts(state)
  }),
  {
    selectContact: actions.selectContact
  }
);

const PeopleCards = ({ contacts, selectContact }) => {
  const contactsLength = R.length(contacts);
  return (
    <Div mb={3}>
      {contactsLength > 0 ? (
        <Text1 bold mb={2} color="neutral6">
          {contactsLength} {contactsLength !== 1 ? "people" : "person"} result
          {addS(contactsLength)}
        </Text1>
      ) : (
        <Text2>No people results</Text2>
      )}
      {R.map(
        contact => (
          <PersonCard {...contact} selectContact={selectContact} />
        ),
        contacts
      )}
    </Div>
  );
};

export default decorate(PeopleCards);
