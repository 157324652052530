import * as R from "ramda";
import {
  put,
  all,
  takeEvery,
  select,
  call,
  fork,
  take
} from "redux-saga/effects";

import {
  getPackage,
  getPackages,
  getUpdatedPrices,
  getUpdatedFormFields
} from "EventLight/Expo/Sales/selectors";
import { ITEM_TYPES, STEPS } from "EventLight/Expo/Sales/constants";

import { PACKAGE_FIELDS } from "Sponsors/PackageTable/constants";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import { BEGIN, COMMIT, REVERT } from "redux-optimist";

import { actions as FormActions } from "ui-kit/Form/model";
import { actions as TabActions } from "ui-kit/Tabs";
import { actions } from "EventLight/Expo/Sales/model";

import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { registerError } from "redux/modules/errors/actions";

import uuid from "node-uuid";

import Api from "../api";

const setFormFields = function*() {
  const packages = yield select(getPackages);
  const forms = R.map(
    form => ({
      id: form.id,
      values: R.map(
        value => ({ value }),
        R.pick(R.values(PACKAGE_FIELDS), form)
      )
    }),
    packages
  );
  yield put(FormActions.bulkWriteForms(forms));
};

const map = R.addIndex(R.map);

const init = function*() {
  for (;;) {
    const { payload } = yield take(actions.init.type);
    if (payload === STEPS.OPPORTUNITIES || payload === STEPS.ADD_ITEMS) {
      yield put(actions.setLoading(true));
      const credentials = yield select(getCredentials);
      const eventId = yield select(getEventId);

      try {
        const { payload } = yield call(Api.getPackages, {
          credentials,
          eventId
        });

        const packages = R.map(
          pkg => ({
            id: pkg.id,
            name: pkg.name || "",
            description: pkg.description || "",
            prices: map(
              (price, index) => ({
                index,
                label: price.name,
                value: price.price,
                isDefault: price.is_default,
                id: price.id
              }),
              R.propOr([], "prices", R.head(pkg.variants))
            ),
            type: pkg.type_id,
            variants: pkg.variants
          }),
          payload
        );

        yield all([
          put(actions.setPackages(R.indexBy(R.prop("id"), packages))),
          put(TabActions.setSelectedTab(ITEM_TYPES.BOOTHS))
        ]);
        yield call(setFormFields);
      } catch (e) {
        yield put(
          registerError([
            {
              system: e,
              user: "An error occurred fetching the packages"
            }
          ])
        );
      } finally {
        yield put(actions.setLoading(false));
      }
    }
  }
};

const addPackage = function*({ payload }) {
  const transactionId = yield call([uuid, uuid.v4]);
  yield put(
    actions.addPackage(payload, {
      optimist: { type: BEGIN, id: transactionId }
    })
  );

  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  try {
    const {
      payload: { item, variants }
    } = yield call(Api.createPackage, {
      credentials,
      item: {
        name: "",
        typeId: payload.type,
        eventId,
        isPackage: true
      }
    });
    yield put(
      actions.addPackageResponse(
        { oldId: payload.id, newItem: item, variants },
        {
          optimist: { type: COMMIT, id: transactionId }
        }
      )
    );
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred creating the package"
          }
        ])
      ),
      yield put(
        actions.addPackageResponse(null, {
          optimist: { type: REVERT, id: transactionId }
        })
      )
    ]);
  }
};

const revert = function*({ payload: packageId }) {
  const pkg = yield select(getPackage, { id: packageId });
  yield call(setFormFields, [pkg]);
};

const watchRevertUpdate = function*() {
  for (;;) {
    const action = yield take(actions.updatePackageResponse.type);
    if (action.error) {
      yield call(revert, action);
    }
  }
};

const watchAddPackage = function*() {
  yield takeEvery(actions.addPackageRequest.type, addPackage);
};

const updatePackage = function*({ item }) {
  const credentials = yield select(getCredentials);
  try {
    yield call(Api.updatePackage, {
      credentials,
      item
    });
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred updating the package"
          }
        ])
      ),
      put(actions.updatePackageResponse(item.id, {}, true))
    ]);
  }
};

const fieldDiffs = (newPkg, oldPkg) =>
  R.pick(
    R.filter(key => newPkg[key] !== oldPkg[key], Object.keys(newPkg)),
    newPkg
  );

const followUpdate = function*({ id, pkg }) {
  for (;;) {
    const {
      meta: { instanceId }
    } = yield take(FormActions.blurField.type);
    if (instanceId === id) {
      break;
    }
  }

  const newPkg = yield select(getUpdatedFormFields, { id });
  const item = fieldDiffs(newPkg, pkg);
  yield fork(updatePackage, { item: { id: id, ...item } });
};

const watchUpdatePackage = function*() {
  for (;;) {
    const {
      meta: { instanceId, fieldType }
    } = yield take(FormActions.setFieldValue.type);

    const pkg = yield select(getPackage, { id: instanceId });
    if (pkg) {
      if (fieldType !== FIELD_TYPES.VALUE) {
        const item = yield select(getUpdatedPrices, { id: instanceId });
        yield fork(updatePackage, { item });
      } else {
        yield call(followUpdate, { id: instanceId, pkg });
      }
    }
  }
};

const watchAddPrice = function*() {
  for (;;) {
    const {
      payload: { id, prices }
    } = yield take(actions.setPrices.type);

    const item = yield select(getUpdatedPrices, { id });
    yield call(updatePackage, { item });

    if (R.length(prices) === 1) {
      yield put(
        FormActions.setFieldValue(prices[0].id, {
          meta: {
            instanceId: id,
            fieldId: PACKAGE_FIELDS.PRICE
          }
        })
      );
    }
  }
};

const deletePackage = function*({ payload: itemId }) {
  const transactionId = yield call([uuid, uuid.v4]);
  yield put(
    actions.deletePackage(itemId, {
      optimist: { type: BEGIN, id: transactionId }
    })
  );

  const credentials = yield select(getCredentials);
  try {
    yield call(Api.deletePackage, {
      credentials,
      itemId
    });
    yield put(
      actions.deletePackageResponse(itemId, {
        optimist: { type: COMMIT, id: transactionId }
      })
    );
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred removing the package"
          }
        ])
      ),
      put(
        actions.deletePackageResponse(
          itemId,
          {
            optimist: { type: REVERT, id: transactionId }
          },
          true
        )
      )
    ]);
  }
};

const watchDeletePackage = function*() {
  yield takeEvery(actions.deletePackageRequest.type, deletePackage);
};

const rootSaga = function*() {
  yield all([
    fork(init),
    fork(watchRevertUpdate),
    fork(watchAddPackage),
    fork(watchDeletePackage),
    fork(watchAddPrice),
    fork(watchUpdatePackage)
  ]);
};

export default rootSaga;
