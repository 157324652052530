import { createSelector } from "reselect";
import * as R from "ramda";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import { getters } from "Event/Home";

import { userPermissionProfile } from "redux/modules/permissions/user-permission-profile/selectors";

import getVisibleModules from "components/Event/utils/get-visible-modules";

import { user as getUser } from "redux/modules/user/selectors";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

const getUserPermissions = (state, props) =>
  userPermissionProfile(state, props.params.eventId, state.user.user.id);

export const getModules = createSelector(
  getters.search,
  (_, { details }) => details.enabled_modules,
  getUserPermissions,
  selectFeatureFlag(flags.CAN_VIEW_PORTAL_SCHEDULE.NAME),
  (search, enabledModules, permissionProfile, canViewSchedule) => {
    const visibleModules = getVisibleModules(permissionProfile, enabledModules);

    const searching = Boolean(R.length(R.trim(search)));

    const modules = searching
      ? R.filter(
          R.compose(
            R.contains(search),
            R.trim,
            R.toLower,
            R.prop("name")
          ),
          visibleModules
        )
      : visibleModules;

    if (!canViewSchedule) {
      return R.filter(m => m.id !== STANDARD_MODULE_IDS.schedules.id)(modules);
    }

    return modules;
  }
);

export const getUserName = createSelector(
  getUser,
  user => user.fname
);
