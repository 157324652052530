import catering from "./catering/required";
import countries from "./countries/required";
import credentials from "./credentials/required";
import date from "./date/required";
import time from "./time/required";
import checkbox from "./checkbox/required";
import dropdown from "./dropdown/required";
import eventDays from "./event-days/required";
import file from "./file/required";
import reference from "./reference/required";
import text from "./text/required";
import catalogItem from "./catalog-item/required";
import phone from "./phone/required";
import number from "./number/required";
import multipleSelect from "./multiple_select/required";

export default function(value, type) {
  switch (type) {
    case "catalog-item":
      return catalogItem(value);
    case "catering":
      return catering(value);
    case "checkbox":
      return checkbox(value);
    case "countries":
      return countries(value);
    case "credentials":
      return credentials(value);
    case "date":
      return date(value);
    case "dropdown":
      return dropdown(value);
    case "event-days":
      return eventDays(value);
    case "file":
      return file(value);
    case "multiple_select":
      return multipleSelect(value);
    case "number":
      return number(value);
    case "phone":
      return phone(value);
    case "reference":
      return reference(value);
    case "text":
    case "textarea":
    case "email":
      return text(value);
    case "time":
      return time(value);
    default:
      return true;
  }
}
