import React from "react";
import * as R from "ramda";
import {
  BadgeWrapper,
  makeTopLeftBadge,
  TogglableStar,
  HugeAvatar
} from "components/Base";
import CSSModules from "react-css-modules";
import css from "./OpacityTrigger.css";

const TogglableStarBadge = makeTopLeftBadge(TogglableStar);

const StarrableHugeAvatar = CSSModules(({ id, name, starred, onClickStar }) => {
  return (
    <BadgeWrapper styleName={"parentContainer"}>
      <TogglableStarBadge
        styleName={"childContainer"}
        size={30}
        onClick={e => {
          e.stopPropagation();
          onClickStar();
        }}
        active={starred}
      />
      <HugeAvatar id={id} text={name} square longInitials />
    </BadgeWrapper>
  );
}, css);

export default StarrableHugeAvatar;
