import React from "react";

import {
  put,
  all,
  takeEvery,
  fork,
  select,
  call,
  take
} from "redux-saga/effects";
import * as R from "ramda";

import { CREDENTIAL_TYPE_ID } from "utils/item-types";
import { TABLE_INSTANCE_ID } from "./constants";
import { ROW_ACTIONS } from "ui-kit/FieldTypes/constants";

import { getCredentials } from "redux/modules/user/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { makeFuture } from "utils/General/sagas";

import { actions as TableActions } from "ui-kit/Table/model";
import { actions } from "./model";
import { registerError } from "redux/modules/errors/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal } from "redux/modules/modal/actions";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import ItemTypeModal from "components/Event/Settings/Catalog/Modals/ItemType";

import Api from "./api";
import itemTypesApi from "redux/modules/items/types/api";
// import { apiCall } from "App/Data/sagas";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventDetails = yield select(getEventDetails);

  return {
    credentials,
    eventDetails
  };
};

const loadData = function*({ payload = {} }) {
  try {
    if (!payload.refresh) {
      yield put(actions.setLoading(true));
    }
    const { credentials, eventDetails } = yield call(getParams);

    const result = yield call(Api.getRecordings, {
      credentials,
      eventId: eventDetails.id
    });

    yield all([
      put(actions.setInitialData(result.payload)),
      put(
        TableActions.setData(
          {
            canEditCells: false,
            columns: R.prop("columns")(result.payload),
            rows: R.prop("rows")(result.payload),
            columnWidths: R.prop("column_widths")(result.payload)
          },
          {
            meta: {
              instanceId: TABLE_INSTANCE_ID
            }
          }
        )
      )
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting records"
        }
      ])
    );
  } finally {
    if (!payload.refresh) {
      yield put(actions.setLoading(false));
    }
  }
};

const handleShowAddPassModal = function*({ id, clone = false }) {
  const handleModal = makeFuture();
  yield put(
    showModal({
      content: (
        <ItemTypeModal
          typeId={CREDENTIAL_TYPE_ID}
          itemId={id}
          clone={clone}
          onDone={handleModal.done}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleModal.onRealized);
};

const showAddPassModal = function*() {
  const result = yield call(handleShowAddPassModal, {});

  if (result) {
    yield put(showSnackbar({ message: "Content added", action: "OK" }));
  }
};

const onShowSelectPassesModal = function*({ payload: showModal }) {
  if (showModal) {
    try {
      yield put(actions.setLoadingItemTypes(true));
      const { credentials, eventDetails } = yield call(getParams);

      const result = yield call(
        itemTypesApi.getItemTypesByEvent,
        credentials,
        eventDetails.id
      );

      yield put(actions.setItemTypes(result.payload));
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error occurred while getting items"
          }
        ])
      );
    } finally {
      yield put(actions.setLoadingItemTypes(false));
    }
  }
};

const onSaveSelectPassesModal = function*({ payload }) {
  try {
    const { credentials, eventDetails } = yield call(getParams);

    const items = R.compose(
      R.map(itemId => ({
        id: itemId,
        enabled: true
      }))
    )(R.keys(payload));

    yield call(Api.updateContent, {
      credentials,
      eventId: eventDetails.id,
      data: { items }
    });
    yield call(loadData, { payload: { refresh: true } });
    yield put(showSnackbar({ message: "Passes updated", action: "OK" }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating items"
        }
      ])
    );
  } finally {
    yield put(actions.setShowSelectPassesModal(false));
  }
};

const playRecord = function*({ payload: { row } }) {
  try {
    const credentials = yield select(getCredentials);

    const { payload: token } = yield call(Api.getRecordingUrl, {
      credentials,
      url: `${row.url}/validation-token`
    });

    window.open(`${row.url}?token=${token}`, "_blank");
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "There was an error when playing the recording"
        }
      ])
    );
  }
};

const downloadRecord = function*({ payload: { row } }) {
  try {
    const credentials = yield select(getCredentials);

    const { payload: token } = yield call(Api.getRecordingUrl, {
      credentials,
      url: `${row.url}/validation-token`
    });

    window.location.href = `${row.url}?token=${token}&dl=1`;
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "There was an error when downloading the recording"
        }
      ])
    );
  }
};

const watchTableActions = function*() {
  for (;;) {
    const action = yield take(TableActions.executeAction.type);
    if (action.meta.instanceId === TABLE_INSTANCE_ID) {
      const delegate = R.prop(action.payload.actionId, {
        [ROW_ACTIONS.DOWNLOAD_RECORD]: downloadRecord,
        [ROW_ACTIONS.PLAY_RECORD]: playRecord
      });
      if (delegate) {
        yield fork(delegate, action);
      }
    }
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, loadData);
};

const watchShowAddPassModal = function*() {
  yield takeEvery(actions.showAddPassModal.type, showAddPassModal);
};

const watchShowSelectPassesModal = function*() {
  yield takeEvery(
    actions.setShowSelectPassesModal.type,
    onShowSelectPassesModal
  );
};

const watchSaveSelectPassesModal = function*() {
  yield takeEvery(actions.saveSelectPassesModal.type, onSaveSelectPassesModal);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchShowAddPassModal),
    fork(watchShowSelectPassesModal),
    fork(watchSaveSelectPassesModal),
    fork(watchTableActions)
  ]);
};

export default rootSaga;
