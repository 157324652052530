/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  updatePhoneNumber: ({ credentials, data }) =>
    new Promise((success, error) => {
      const options = {
        skipUserId: true,
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/user/me`,
        credentials,
        data,
        success,
        error
      };
      Helpers.request(options);
    })
};
