import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import styles from "./styles.scss";

const FormFooter = ({ formId }) => (
  <div styleName="footer">
    <a
      href={`https://www.lennd.com/?source=form_footer&form_id=${formId}`}
      target="_blank"
    >
      <img
        src="https://d2dks4tzxs6xee.cloudfront.net/img/powered-by-lennd.svg"
        styleName="footerLogo"
      />
    </a>
  </div>
);

FormFooter.propTypes = {
  formId: PropTypes.string.isRequired
};

export default CSSModules(FormFooter, styles);
