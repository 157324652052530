import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { MODES } from "../../../constants";

@CSSModules(css)
class Send extends Component {
  render() {
    // const validFilter =
    //   this.props.mode === MODES.SMS
    //     ? r => r.meta.hasValidPhone
    //     : r => r.meta.hasValidEmail;
    // const validRecipients = this.props.recipients.filter(validFilter);

    return (
      <div styleName="checkWrapper">
        <div styleName="checkIcon">
          <i className="material-icons">check</i>
        </div>
        {/*
        <div styleName="titleReady">
          {validRecipients.length}{" "}
          {this.props.mode === MODES.SMS
            ? validRecipients.length === 1
              ? "message"
              : "messages"
            : validRecipients.length === 1
            ? "email"
            : "emails"}{" "}
          {validRecipients.length === 1 ? "was" : "were"} successfully sent
        </div>
        */}

        <div styleName="titleReady">
          {this.props.mode === MODES.SMS ? "Messages" : "Emails"} successfully
          sent
        </div>
      </div>
    );
  }
}

Send.propTypes = {
  recipients: PropTypes.array.isRequired
};

export default Send;
