import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const DEFAULT_PAGINATION = {
  pageSize: 15,
  pages: 0,
  page: 0
};

const iniState = {
  showEmptyState: false,
  loading: true,
  searching: false,
  showAddContentModal: false,
  showSelectPassesModal: false,
  content: [],
  loadingItemTypes: false,
  itemTypes: [],
  data: {},
  scopes: [],
  search: "",
  sortBy: "newest",
  pagination: DEFAULT_PAGINATION
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    save: R.identity,
    update: R.identity,
    deleteContent: R.identity,
    showAddPassModal: R.identity,
    showEditPassModal: R.identity,
    saveSelectPassesModal: R.identity,
    goToCatalog: R.identity,
    setInitialData: (
      _,
      { payload: { show, search, sortBy, pagination, content } }
    ) => ({
      content,
      show,
      search,
      sortBy,
      pagination
    }),
    reset: () => ({
      search: "",
      sortBy: "newest"
    }),
    updateField: (state, { payload: { field, value } }) => ({
      data: R.assoc(field, value, state.data)
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
