import OrgLightGate from "OrgLight/Gate";
import OrgLightLayout from "OrgLight/Layout/View";
import OrgLightDashboard from "OrgLight/Dashboard/View";
import OrgLightEvents from "OrgLight/Events/View";
import OrgLightBilling from "OrgLight/Billing/View";
import OrgLightTeam from "OrgLight/Team/View";
import OrgLightIntegrations from "OrgLight/Integrations/View";
import OrgLightDetails from "OrgLight/Details/View";
import OrgLightGroups from "OrgLight/Groups/View";
import OrgLightPeople from "OrgLight/People/View";

import { hot } from "App/Config/hot";

const OrgLight = {
  OrgLightGate,
  OrgLightLayout,
  OrgLightDashboard,
  OrgLightEvents,
  OrgLightBilling,
  OrgLightTeam,
  OrgLightIntegrations,
  OrgLightDetails,
  OrgLightGroups,
  OrgLightPeople
};

export default hot(OrgLight);
