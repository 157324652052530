import * as R from "ramda";
import Layout from "./Layout";
import { createContext } from "redux-mvc";
import module from "EventLight/Portals/Pages";

const decorate = createContext({
  module,
  options: {
    observedDomains: [
      "user",
      "event",
      "permissions",
      "organization",
      "portal",
      "@flopflip"
    ],
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    persist: false
  }
});

export default decorate(Layout);
