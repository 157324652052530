import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SyncButton from "./SyncButton";

import { syncing } from "redux/modules/integrations/eventbrite/orders/selectors";
import { searchOrders } from "redux/modules/credentials/orders/actions";
import { setSubmissionLocked } from "redux/modules/formsV2/submission/actions";
import * as EventbriteOrdersActions from "redux/modules/integrations/eventbrite/orders/actions";
import * as snackbarActions from "redux/modules/snackbar/actions";

function mapStateToProps(state, props) {
  return {
    syncing: syncing(state, props.orderId)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...EventbriteOrdersActions,
      ...snackbarActions,
      searchOrders,
      setSubmissionLocked
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SyncButton);
