import * as R from "ramda";
import { put, select, call, take, all, fork } from "redux-saga/effects";

import { BEGIN, COMMIT, REVERT } from "redux-optimist";
import uuid from "node-uuid";

import {
  getters as TableGetters,
  actions as TableActions
} from "ui-kit/Datagrid";
import * as TableSelectors from "ui-kit/Datagrid/selectors";

import { getters } from "Submission/PeopleBlockTable";

import {
  updatePersonValue,
  updatePersonRole,
  updatePersonItemBlockValue,
  addPerson,
  removePerson
} from "redux/modules/formsV2/people-blocks/people/actions";

import { registerError } from "redux/modules/errors/actions";

import { actions as ValidatorActions } from "ui-kit/Validator";
import * as ValidatorSelectors from "ui-kit/Validator/selectors";

import { getCellId } from "ui-kit/Datagrid/utils";

const removeRow = function*({ meta: actionMeta }) {
  const tableProps = { instanceId: actionMeta.instanceId };
  const selectedRows = yield select(TableSelectors.getSelectedRows, tableProps);
  if (R.isEmpty(selectedRows)) {
    yield put(TableActions.setLoading(false, { meta: actionMeta }));
  } else {
    const transactionId = yield call([uuid, uuid.v4]);

    yield put(
      TableActions.removeRow(null, {
        optimist: { type: BEGIN, id: transactionId },
        meta: actionMeta
      })
    );

    const meta = yield select(TableGetters.meta, tableProps);

    try {
      const submissionRecordId = R.pathOr(
        "",
        ["submission", "submission_record_id"],
        meta
      );
      const blockId = R.pathOr(
        "",
        ["field", "people_block", "block", "id"],
        meta
      );
      const blockFieldId = R.pathOr("", ["field", "id"], meta);

      const data = {
        submissionRecordId,
        blockId,
        blockFieldId
      };

      const res = yield all(
        R.map(
          row =>
            put(
              removePerson({
                ...data,
                contactId: R.prop("contact_id", row) || R.propOr("", "id", row)
              })
            ),
          selectedRows
        )
      );
      const response = yield call(() => Promise.all(res));
      yield put(
        TableActions.removeRowResponse(response, {
          optimist: { type: COMMIT, id: transactionId },
          meta: actionMeta
        })
      );
    } catch (error) {
      yield put(
        TableActions.removeRowResponse(
          { error: error.error || "Could not delete the rows." },
          {
            optimist: { type: REVERT, id: transactionId },
            meta: actionMeta
          },
          true
        )
      );
    }
  }
};

const watchRemove = function*() {
  for (;;) {
    const action = yield take(TableActions.removeRowRequest.type);

    const tableIds = yield select(getters.tableIds);
    if (R.contains(action.meta.instanceId, tableIds)) {
      yield call(removeRow, action);
    }
  }
};

const addRow = function*({ meta: actionMeta }) {
  const tableProps = { instanceId: actionMeta.instanceId };
  const meta = yield select(TableGetters.meta, tableProps);

  const mode = yield select(TableGetters.mode, {
    instanceId: actionMeta.instanceId
  });

  const transactionId = yield call([uuid, uuid.v4]);

  yield put(
    TableActions.addRow(transactionId, {
      optimist: { type: BEGIN, id: transactionId },
      meta: actionMeta
    })
  );

  try {
    const submissionRecordId = R.pathOr(
      "",
      ["submission", "submission_record_id"],
      meta
    );
    const blockId = R.pathOr(
      "",
      ["field", "people_block", "block", "id"],
      meta
    );
    const blockFieldId = R.pathOr("", ["field", "id"], meta);

    const data = {
      submissionRecordId,
      blockId,
      blockFieldId,
      saveAsDraft: meta.saveAsDraft
    };
    const res = yield put(addPerson(data));
    const submission = yield call(() => res);

    yield put(
      TableActions.addRowResponse(
        { submission: submission, tId: transactionId, mode },
        {
          optimist: { type: COMMIT, id: transactionId },
          meta: actionMeta
        }
      )
    );
  } catch (error) {
    yield all([
      put(
        TableActions.addRowResponse(
          { error: error.error || "Could not create the rows." },
          {
            optimist: { type: REVERT, id: transactionId },
            meta: actionMeta
          },
          true
        )
      ),
      put(
        registerError([
          {
            system: error,
            user: "An error occurred getting submission"
          }
        ])
      )
    ]);
  }
};

const watchAdd = function*() {
  for (;;) {
    const action = yield take(TableActions.addRowRequest.type);

    const tableIds = yield select(getters.tableIds);
    if (R.contains(action.meta.instanceId, tableIds)) {
      yield call(addRow, action);
    }
  }
};

const waitForId = function*({ instanceId, rowId }) {
  const isTemporary = yield select(TableSelectors.isTemporaryRow, {
    instanceId,
    rowId
  });
  if (isTemporary) {
    const action = yield take(
      ac =>
        ac.type === TableActions.addRowResponse.type &&
        !ac.error &&
        R.path(["payload", "tId"], ac) === rowId
    );

    if (!action.error) {
      return R.path(["payload", "submission", "id"], action);
    }
    return "";
  }
  return rowId;
};

const updateCell = function*({ payload, meta: actionMeta }) {
  const mode = yield select(TableGetters.mode, {
    instanceId: actionMeta.instanceId
  });

  const tableProps = { instanceId: actionMeta.instanceId };
  const { fromRowData: row, cellKey, updated, action } = payload;

  // prevent updating a temporary row
  const meta = yield select(TableGetters.meta, tableProps);

  const transactionId = yield call([uuid, uuid.v4]);

  // currently bailing if trying to drag or copy + paste values
  if (!["CELL_DRAG", "COPY_PASTE"].includes(action)) {
    yield put(
      TableActions.updateCell(payload, {
        optimist: { type: BEGIN, id: transactionId },
        meta: actionMeta
      })
    );

    const blockId = R.pathOr(
      "",
      ["field", "people_block", "block", "id"],
      meta
    );
    const eventId = R.pathOr("", ["eventDetails", "id"], meta);
    const submissionId = R.pathOr("", ["submission", "id"], meta);
    const blockFieldId = R.pathOr("", ["field", "id"], meta);

    const newRowId = yield call(waitForId, { ...tableProps, rowId: row.id });
    const newRow = yield select(TableSelectors.getRow, {
      ...tableProps,
      rowId: newRowId
    });

    if (newRowId) {
      const contactId = R.prop("contact_id", newRow) || newRowId;

      try {
        if (cellKey === "role") {
          const data = {
            eventId,
            submissionId,
            blockId,
            blockFieldId,
            contactId,
            fieldId: cellKey,
            value: R.omit(["error"], R.propOr({}, cellKey, updated))
          };
          yield put(updatePersonRole(data));
        } else {
          const data = {
            blockId,
            blockFieldId,
            contactId,
            fieldId: cellKey,
            value: R.omit(["error"], R.propOr({}, cellKey, updated))
          };
          yield put(updatePersonValue(data));
        }
        yield put(
          TableActions.updateCellResponse(null, {
            optimist: { type: COMMIT, id: transactionId },
            payload: {
              submissionId: newRowId,
              fieldId: cellKey,
              value: updated[cellKey],
              mode
            },
            meta: actionMeta
          })
        );
      } catch (error) {
        yield put(
          TableActions.updateCellResponse(
            { error: error.error || "Could not update the cell." },
            {
              optimist: { type: REVERT, id: transactionId },
              meta: actionMeta
            },
            true
          )
        );
      }
    }
  }
};

const updateItemBlock = function*({ payload, meta: actionMeta }) {
  const mode = yield select(TableGetters.mode, {
    instanceId: actionMeta.instanceId
  });

  const tableProps = { instanceId: actionMeta.instanceId };
  const { fromRowData: row, cellKey, updated, action } = payload;

  // prevent updating a temporary row
  const meta = yield select(TableGetters.meta, tableProps);

  const transactionId = yield call([uuid, uuid.v4]);

  // currently bailing if trying to drag or copy + paste values
  if (!["CELL_DRAG", "COPY_PASTE"].includes(action)) {
    yield put(
      TableActions.updateCell(payload, {
        optimist: { type: BEGIN, id: transactionId },
        meta: actionMeta
      })
    );

    const eventId = R.path(
      ["meta", "columns", cellKey, "settings", "event_id"],
      row
    );
    const blockId = R.pathOr(
      "",
      ["field", "people_block", "block", "id"],
      meta
    );

    const newRowId = yield call(waitForId, { ...tableProps, rowId: row.id });
    const newRow = yield select(TableSelectors.getRow, {
      ...tableProps,
      rowId: newRowId
    });

    const blockFieldId = R.pathOr("", ["field", "id"], meta);
    const contactId = R.prop("contact_id", newRow) || newRowId;

    const items = R.path([cellKey, "value", "value"], updated);
    const removedItems = R.map(
      id => ({ id, quantity: 0 }),
      R.path([cellKey, "removedItems"], updated)
    );

    const orderId = R.path(["related_order", "id"], newRow);

    const data = {
      blockId,
      blockFieldId,
      contactId,
      orderId,
      actions: R.map(
        ({ id, quantity, price = 0 }) => ({
          action: "set-quantity",
          set: {
            orderId,
            variantId: id,
            price
          },
          by: quantity,
          where: {
            eventId,
            variantId: id,
            orderId,
            includeDraftOrders: true
          }
        }),
        R.concat(items, removedItems)
      )
    };

    try {
      const res = yield put(updatePersonItemBlockValue(data));
      yield call(R.always(res)); // updatePersonItemBlockValue is a thunk
      yield put(
        TableActions.updateCellResponse(null, {
          optimist: { type: COMMIT, id: transactionId },
          payload: {
            submissionId: row.id,
            fieldId: cellKey,
            value: {
              ...R.propOr({}, cellKey, updated),
              value: { type: "item-block", value: items }
            },
            mode
          },
          meta: actionMeta
        })
      );
    } catch (error) {
      yield put(
        TableActions.updateCellResponse(
          { error: error.error || "Could not update the cell." },
          {
            optimist: { type: REVERT, id: transactionId },
            meta: actionMeta
          },
          true
        )
      );
    }
  }
};

const filterValidatorResponse = ({ instanceId, cellId }) => vAction =>
  vAction.type === ValidatorActions.validationResponse.type &&
  R.path(["meta", "instanceId"], vAction) === instanceId &&
  R.path(["payload", "id"], vAction) === cellId;

const watchUpdateCell = function*() {
  for (;;) {
    const action = yield take(TableActions.updateCellRequest.type);
    const instanceId = action.meta.instanceId;

    const tableIds = yield select(getters.tableIds);
    if (R.contains(instanceId, tableIds)) {
      const {
        cellKey,
        updated,
        fromRowId: rowId,
        fromRowData: row
      } = action.payload;

      const cellId = getCellId({ columnId: cellKey, rowId });
      const column = R.path(["meta", "columns", cellKey], row);
      const value = R.prop(cellKey, updated);
      const cell = value ? R.omit(["error"])(value) : { value: null };

      yield put(
        ValidatorActions.validationRequest(
          {
            ...cell,
            id: cellId,
            type: R.prop("type", column),
            key: R.prop("name", column),
            required: R.prop("required", column)
          },
          { meta: action.meta }
        )
      );
      yield take(filterValidatorResponse({ instanceId, cellId }));

      const { valid } = yield select(ValidatorSelectors.getError, {
        id: cellId,
        instanceId
      });

      if (valid) {
        yield put(ValidatorActions.clear([rowId], { meta: action.meta }));
        if (R.path([cellKey, "value", "type"], updated) === "item-block") {
          yield call(updateItemBlock, action);
        } else {
          yield call(updateCell, action);
        }
      } else {
        yield put(
          TableActions.updateCell(action.payload, {
            meta: action.meta
          })
        );
      }
    }
  }
};

const rootSaga = function*() {
  yield all([fork(watchUpdateCell), fork(watchAdd), fork(watchRemove)]);
};

export default rootSaga;
