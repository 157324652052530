import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";

import { registerError } from "redux/modules/errors/actions";

import { actions, getters } from "SalesPortal/Home";

import { getCredentials } from "redux/modules/user/selectors";
import {
  getOrderedItems,
  getTotalAmount
} from "SalesPortal/ItemsModal/selectors";
import {
  getRequiredFields,
  getFields,
  getUserId
} from "SalesPortal/Home/selectors";

import portalApi from "SalesPortal/Home/api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const userId = yield select(getUserId);
  return { credentials, userId };
};

const getPortalDetails = function*({ payload }) {
  yield put(actions.setLoading(true));

  const { credentials } = yield call(getParams);

  try {
    const result = yield call(
      portalApi.getDetails,
      credentials,
      payload.eventId
    );
    yield put(actions.setSalesData(result.payload));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred loading portal details"
        }
      ])
    );
  }

  yield put(actions.setLoading(false));
};

const onSubmit = function*({ payload: { redirectLink, stripeTokenId } }) {
  yield put(actions.setSaving(true));

  // check for any fields with errors
  const requiredFields = yield select(getRequiredFields);
  yield put(actions.validateFields(requiredFields));

  const fieldsWithErrors = yield select(getters.fieldErrors);

  if (Object.keys(fieldsWithErrors).length) {
    yield put(actions.setSaving(false));
    return false;
  }

  // build payload
  const formatValues = fieldsToFormat =>
    fieldsToFormat.reduce((values, field) => {
      if (field.value) {
        values[field.id] = field.value;
      }
      return values;
    }, {});

  const { credentials, userId } = yield call(getParams);
  const salesData = yield select(getters.salesData);
  const items = yield select(getOrderedItems);
  const totalAmount = yield select(getTotalAmount);
  const fields = yield select(getFields);
  const valuesToSave = {
    contact: formatValues(fields.contact),
    account: formatValues(fields.account),
    order: formatValues(fields.order)
  };

  // const { fname, lname } = fullnameToFirstLast(contact.fullName);

  try {
    // attempt to save order
    yield call(portalApi.createRequest, credentials, {
      userId,
      eventId: salesData.event_id,
      stripeTokenId,
      items: items.map(i => ({
        id: i.id,
        quantity: i.quantity,
        price: i.price
      })),
      totalAmount,
      values: valuesToSave
    });

    // redirect to confirmation page
    window.location = redirectLink;
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred submitting request"
        }
      ])
    );
    yield put(actions.setSaving(false));
  }

  return true;
};

const watchPortalDetails = function*() {
  yield takeEvery(actions.getPortalDetails.type, getPortalDetails);
};

const watchOnSubmit = function*() {
  yield takeEvery(actions.onSubmit.type, onSubmit);
};

const rootSaga = function*() {
  yield all([fork(watchPortalDetails), fork(watchOnSubmit)]);
};

export default rootSaga;
