import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function updateCustomer(data) {
  return async (dispatch, getState) => {
    try {
      return await api.put(getState().user.user.credentials, data);
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating customer"
          }
        ])
      );
    }
  };
}
