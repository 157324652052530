import * as R from "ramda";
import React, { Component } from "react";
import ReactDataGrid from "react-data-grid";
import { Menu } from "react-data-grid-addons";
const { ContextMenuTrigger } = Menu;
import { connect } from "react-redux";
import { noop } from "utils/General";

import dataGrid, { actions, getters } from "ui-kit/Datagrid";
import { COLUMN_CONTEXT_MENU_ID, BUTTONS } from "ui-kit/Datagrid/constants";

import Footer from "ui-kit/Datagrid/View/Footer";
import { Div } from "components/Base";

import * as TableSelectors from "ui-kit/Datagrid/selectors";
import { getEditorId } from "ui-kit/Datagrid/utils";

import { bindInstance } from "redux-mvc";
import { addS } from "utils/General";

import sagas from "ui-kit/Datagrid/sagas";

import Headers from "./Headers";
import ContextMenu from "./Menu";

import RequiredHeader from "components/Global/Table3/HeaderFormatters/Required";
import DescriptionHeader from "components/Global/Table3/HeaderFormatters/DescriptionHeader";

import resolveFormatter from "ui-kit/Datagrid/utils/resolveFormatter";
import resolveEditor from "ui-kit/Datagrid/utils/resolveEditor";

dataGrid.setRootSaga(sagas);

// needs ui-kit/Datagrid module in scope to work

const buildHeaderRenderer = (
  { id, name, required, description },
  columnIndex,
  enableContextMenu
) => {
  let title = name;
  if (required) {
    title = <RequiredHeader>{title}</RequiredHeader>;
  }

  const header = (
    <DescriptionHeader
      ref={`description_${id}`}
      meta={{ fieldId: id }}
      updateDescription={false}
      description={description}
    >
      {title}
    </DescriptionHeader>
  );

  if (enableContextMenu) {
    return (
      <ContextMenuTrigger
        id={COLUMN_CONTEXT_MENU_ID}
        holdToDisplay={1}
        collect={() => ({
          columnId: id,
          columnIndex
        })}
      >
        {header}
      </ContextMenuTrigger>
    );
  }

  return header;
};

const EmptyRows = () => <div />;

const defaultEnabledButtons = R.values(R.omit([BUTTONS.IMPORT], BUTTONS));

const decorate = connect(
  (state, props) => ({
    selectedRows: getters.selectedRows(state, props),
    rowGetter: TableSelectors.getRowGetter(state, props),
    rowsCount: TableSelectors.getRowsCount(state, props)
  }),
  bindInstance({
    selectRows: actions.selectRows,
    deselectRows: actions.deselectRows,
    updateCell: actions.updateCellRequest,
    setRows: actions.setRows,
    setMeta: actions.setMeta,
    setIniData: actions.setIniData,
    cancelEdit: actions.cancelEdit,
    onColumnResize: actions.columnResizeRequest
  })
);

class Table extends Component {
  constructor(props) {
    super(props);

    this.rows = props.rows;
    this.meta = props.meta;

    this.props.setIniData({
      rows: props.rows,
      meta: props.meta,
      mode: props.mode
    });

    this.table = React.createRef();
  }

  componentWillReceiveProps({ rows, meta, updateRows = true }) {
    if (updateRows) {
      if (rows !== this.rows) {
        this.rows = rows;
        this.props.setRows(rows);
      }
      if (meta !== this.meta) {
        this.meta = meta;
        this.props.setMeta(meta);
      }
    }
  }

  render() {
    const {
      menu,
      columns = [],
      headers = [],
      showHeaders = false,
      rowGetter,
      rowsCount,
      enabledButtons = defaultEnabledButtons,
      rightButtons = [],
      instanceId,
      readOnly = false,
      selectedRows = [],
      selectRows = noop,
      deselectRows = noop,
      updateCell = noop,
      onColumnResize = noop,
      handlers = {}
    } = this.props;

    const height = (rowsCount + 1) * 36 + 15;
    const minHeight = height < 350 ? height : 350;
    const enableContextMenu = Boolean(menu && menu.length);
    const columnsToRender = columns.map((c, idx) => ({
      ...c,
      formatter: resolveFormatter(c),
      editor: resolveEditor(c),
      headerRenderer: buildHeaderRenderer(c, idx, enableContextMenu)
    }));

    return (
      <Div
        id={getEditorId({ instanceId })}
        onClick={e => {
          e.stopPropagation();
        }}
        className="react-grid-Lennd-Datagrid"
      >
        {showHeaders ? (
          <Headers headers={headers} followElm={this.table} />
        ) : null}
        <ReactDataGrid
          {...{
            columns: columnsToRender,
            rowGetter,
            rowsCount,
            emptyRowsView: EmptyRows,
            enableCellSelect: !readOnly,
            enableCellAutoFocus: false,
            onCellSelected: undefined,
            onGridRowsUpdated: readOnly ? undefined : updateCell,
            minHeight,
            contextMenu: enableContextMenu ? (
              <ContextMenu id={COLUMN_CONTEXT_MENU_ID} menu={menu} />
            ) : (
              undefined
            ),
            onColumnResize: (colIndex, width) =>
              onColumnResize({
                id: R.pathOr("", [colIndex - 1, "id"], columns),
                width
              }),
            rowSelection: {
              showCheckbox: !readOnly,
              enableShiftSelect: !readOnly,
              onRowsSelected: selectRows,
              onRowsDeselected: deselectRows,
              selectBy: {
                indexes: selectedRows
              }
            },
            ref: this.table
          }}
        />
        {readOnly ? (
          <Div p={3} fs={2} color="gray5">
            {rowsCount} request
            {addS(rowsCount)}
          </Div>
        ) : (
          <Footer
            m={2}
            instanceId={instanceId}
            enabledButtons={enabledButtons}
            rightButtons={rightButtons}
            handlers={handlers}
          />
        )}
      </Div>
    );
  }
}

export default decorate(Table);
