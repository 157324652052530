import * as R from "ramda";
import moment from "moment-timezone";
import createRanges from "components/Global/Editors/EventDaysEditor/utils/create-date-ranges";
import { liftToArr, setOtherZone } from "utils/General";

export const getValue = (props, key, def) => {
  const field = R.propOr({}, "field", props);
  const val = R.path(["data", field.id, "value"], props);

  if (typeof key === "undefined" || key === R.identity) {
    if (typeof def !== "undefined") {
      return typeof val !== "undefined" ? val : def;
    }
    return val;
  }

  if (typeof def === "undefined") {
    if (Array.isArray(key)) {
      return R.path(key, val);
    }
    return R.prop(key, val);
  }
  if (Array.isArray(key)) {
    return R.pathOr(def, key, val);
  }
  return R.propOr(def, key, val);
};

export const getField = (props, key, def) => {
  const val = R.propOr({}, "field", props);

  if (typeof key === "undefined" || key === R.identity) {
    if (typeof def !== "undefined") {
      return val || def;
    }
    return val;
  }

  if (typeof def === "undefined") {
    if (Array.isArray(key)) {
      return R.path(key, val);
    }
    return R.prop(key, val);
  }
  if (Array.isArray(key)) {
    return R.pathOr(def, key, val);
  }
  return R.propOr(def, key, val);
};

export const getSettings = (props, key, def) => {
  const val = R.pathOr({}, ["field", "settings"], props);

  if (typeof key === "undefined" || key === R.identity) {
    if (typeof def !== "undefined") {
      return val || def;
    }
    return val;
  }

  if (typeof def === "undefined") {
    if (Array.isArray(key)) {
      return R.path(key, val);
    }
    return R.prop(key, val);
  }
  if (Array.isArray(key)) {
    return R.pathOr(def, key, val);
  }
  return R.propOr(def, key, val);
};

export const getResult = ({ field, data, newField }) =>
  R.propOr(R.always(newField.value), field.type, {
    date: () =>
      moment(
        new Date(newField.value),
        R.path(["settings", "dateFormat"], field)
      ).format("YYYY-MM-DD"),
    time: () =>
      moment(new Date(newField.value), "h:mm a").isValid()
        ? moment(new Date(newField.value), "h:mm a").format("h:mm a")
        : newField.value,
    datetime: () => {
      let timezone = R.path(["settings", "timezone"], field);

      if (!timezone) {
        timezone = moment.tz.guess();
      }

      return newField.value
        ? {
            iso: setOtherZone(newField.value, timezone),
            formatted: newField.value
          }
        : null;
    },
    countries: () => R.head(newField.value),
    "event-days": () => createRanges(newField.value),
    dropdown: () => ({
      records: R.compose(
        R.map(id => ({
          id,
          value:
            R.prop(
              "value",
              R.find(
                R.propEq("id", id),
                R.pathOr([], ["settings", "options"], field)
              )
            ) || {}
        })),
        liftToArr
      )(newField.value)
    }),
    lookup: () => ({
      moduleId: R.path(["settings", "moduleId"], field),
      records: newField.value
    }),
    file: () => ({
      files: newField.value
    }),
    "catalog-item": () => ({
      records: Object.entries(newField.value).map(([id, quantity]) => ({
        id,
        quantity
      }))
    }),
    "attendee-photo": () => ({
      ...R.pathOr({}, [field.id, "value"], data),
      photoUrl: R.pathOr("", ["value", "url"], newField),
      file: newField.value
    })
  })();
