import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { getActiveScene } from "../selectors";

import { SCENES, ALERTS_FORM_ID } from "../constants";

import { Div } from "components/Base";
import MessageContent from "./Message";
import DeliveryMethod from "./DeliveryMethod";
import Sending from "./Sending";
import CriteriaPicker from "./CriteriaPicker";

import PreviewRecipients from "./PreviewRecipients";
import PreviewMessage from "./PreviewMessage";

import { WithFormInstanceProvider } from "ui-kit/Form/View";

const decorate = R.compose(
  WithFormInstanceProvider(R.always(ALERTS_FORM_ID)),
  connect(
    (state, props) => ({
      activeScene: getActiveScene(state, props)
    }),
    null
  )
);

const Body = ({ activeScene }) => (
  <Div
    display="column"
    width={1}
    height={1}
    p={4}
    bg="neutral0"
    style={{
      height: "100%",
      maxHeight: "calc(100vh - 120px)",
      overflowY: "auto"
    }}
  >
    {R.prop(activeScene, {
      [SCENES.EDIT]: (
        <>
          <MessageContent />
          <DeliveryMethod />
          <CriteriaPicker />
          {/* <Sending /> */}
        </>
      ),
      [SCENES.PREVIEW]: (
        <>
          <PreviewRecipients />
          <PreviewMessage />
        </>
      )
    })}
  </Div>
);

export default decorate(Body);
