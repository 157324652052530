import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import * as R from "ramda";
import { actions } from "./model";
import { actions as itemsCatalogActions } from "Event/ItemCatalog";
import { getZones } from "Event/Zones/selectors";

import { registerError } from "redux/modules/errors/actions";

import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";
import { showSnackbar } from "redux/modules/snackbar/actions";
import moveItemInArray from "utils/move-item-in-array";
import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  const eventDetails = yield select(getEventDetails);
  const userId = yield select(getUserId);

  return {
    credentials,
    eventId,
    eventDetails,
    userId
  };
};

const fetchZones = function*() {
  try {
    const { credentials, eventId } = yield call(getParams);

    const { payload: zones } = yield call(Api.getZoneGroups, {
      credentials,
      eventId
    });

    yield put(actions.setInitialData({ zones }));
    yield put(itemsCatalogActions.refetchAcessGridData());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error loading zones"
        }
      ])
    );
  }
};

const moveZoneCategoryUp = function*({ payload: { currentPosition } }) {
  if (currentPosition > 0) {
    try {
      const { credentials, eventId } = yield call(getParams);

      const zoneGroups = yield select(getZones);

      yield call(Api.bulkUpdateZoneGroups, {
        credentials,
        data: {
          eventId,
          bulk: true,
          groups: moveItemInArray(
            zoneGroups,
            currentPosition,
            currentPosition - 1
          ).map((g, order) => ({
            id: g.id,
            order
          }))
        }
      });

      yield call(fetchZones);
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error moving up category"
          }
        ])
      );
    }
  }
};

const moveZoneCategoryDown = function*({ payload: { currentPosition } }) {
  try {
    const { credentials, eventId } = yield call(getParams);

    const zoneGroups = yield select(getZones);

    yield call(Api.bulkUpdateZoneGroups, {
      credentials,
      data: {
        eventId,
        bulk: true,
        groups: moveItemInArray(
          zoneGroups,
          currentPosition,
          currentPosition + 1
        ).map((g, order) => ({
          id: g.id,
          order
        }))
      }
    });

    yield call(fetchZones);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error moving down category"
        }
      ])
    );
  }
};

const moveZoneItemUp = function*({
  payload: { groupId, order: currentPosition }
}) {
  if (currentPosition > 0) {
    try {
      const { credentials, eventId } = yield call(getParams);

      const zoneGroups = yield select(getZones);
      const items = R.compose(
        R.propOr([], "zones"),
        R.find(({ id }) => id === groupId)
      )(zoneGroups);

      yield call(Api.bulkUpdateZones, {
        credentials,
        data: {
          eventId,
          bulk: true,
          zones: moveItemInArray(
            items,
            currentPosition,
            currentPosition - 1
          ).map((g, order) => ({
            id: g.id,
            order
          }))
        }
      });

      yield call(fetchZones);
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error moving up zone"
          }
        ])
      );
    }
  }
};

const moveZoneItemDown = function*({
  payload: { groupId, order: currentPosition }
}) {
  try {
    const { credentials, eventId } = yield call(getParams);

    const zoneGroups = yield select(getZones);
    const items = R.compose(
      R.propOr([], "zones"),
      R.find(({ id }) => id === groupId)
    )(zoneGroups);

    yield call(Api.bulkUpdateZones, {
      credentials,
      data: {
        eventId,
        bulk: true,
        zones: moveItemInArray(items, currentPosition, currentPosition + 1).map(
          (g, order) => ({
            id: g.id,
            order
          })
        )
      }
    });

    yield call(fetchZones);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error moving down zone"
        }
      ])
    );
  }
};

const deleteZoneItem = function*({ payload: { itemId } }) {
  try {
    const { credentials } = yield call(getParams);

    yield call(Api.deleteZone, {
      credentials,
      data: { zoneId: itemId }
    });

    yield put(
      showSnackbar({
        message: "Zone deleted",
        action: "OK"
      })
    );

    yield call(fetchZones);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error deleting zone"
        }
      ])
    );
  }
};

const reorderZoneItems = function*({ payload: newOrder }) {
  try {
    const { credentials, eventId } = yield call(getParams);

    yield call(Api.bulkUpdateZones, {
      credentials,
      data: {
        eventId,
        bulk: true,
        zones: newOrder.map(({ id }, i) => ({ id, order: i }))
      }
    });

    yield call(fetchZones);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error reordering zones"
        }
      ])
    );
  }
};

const watchDeleteZoneItem = function*() {
  yield takeEvery(actions.deleteZoneItem.type, deleteZoneItem);
};

const watchZoneReorderItems = function*() {
  yield takeEvery(actions.reorderZoneItems.type, reorderZoneItems);
};

const watchMoveZoneItemUp = function*() {
  yield takeEvery(actions.moveZoneItemUp.type, moveZoneItemUp);
};

const watchMoveZoneItemDown = function*() {
  yield takeEvery(actions.moveZoneItemDown.type, moveZoneItemDown);
};

const watchMoveZoneCategoryUp = function*() {
  yield takeEvery(actions.moveZoneCategoryUp.type, moveZoneCategoryUp);
};

const watchMoveZoneCategoryDown = function*() {
  yield takeEvery(actions.moveZoneCategoryDown.type, moveZoneCategoryDown);
};

const rootSaga = function*() {
  yield all([
    fork(watchDeleteZoneItem),
    fork(watchZoneReorderItems),
    fork(watchMoveZoneItemUp),
    fork(watchMoveZoneItemDown),
    fork(watchMoveZoneCategoryUp),
    fork(watchMoveZoneCategoryDown)
  ]);
};

export default rootSaga;
