import React from "react";
import { Div, Text5 } from "components/Base";
import CardsList from "./CardsList";

const Body = ({ selectedTypeName }) => (
  <Div bg="neutral0" height={610} style={{ overflowY: "auto" }}>
    <Div bg="white" px={10} pt={8} pb={2}>
      <Text5 color="black" bold mb={2}>
        {selectedTypeName} Portal Customizations
      </Text5>
    </Div>
    <Div bg="neutral0" py={8} px={10} display="row.space-between">
      <CardsList />
      {/* <ReorderSections />*/}
    </Div>
  </Div>
);

export default Body;
