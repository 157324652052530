import * as R from "ramda";
import { createSelector } from "reselect";

import { getters } from "./model";
import { getters as PaginationGetters } from "ui-kit/PaginationBar";
import { getPage } from "ui-kit/PaginationBar/selectors";

export const getTaskProgress = createSelector(
  getters.tasks,
  tasks => {
    const total = R.length(R.filter(R.propEq("required", true), tasks));
    const completed = R.length(
      R.filter(
        task =>
          task.required &&
          R.contains(task.status, ["approved", "pending", "complete"]),
        tasks
      )
    );

    if (total === 0) {
      return 1;
    } else {
      return completed / total;
    }
  }
);
const alertsPage = (state, props) => {
  const total = R.length(getters.alerts(state, props));
  return getPage(state, { ...props, total, instanceId: "alerts" });
};

export const getAlerts = createSelector(
  alertsPage,
  (state, props) =>
    PaginationGetters.pageSize(state, { ...props, instanceId: "alerts" }),
  getters.alerts,
  (page, pageSize, alerts) =>
    alerts.slice(page * pageSize, pageSize * (1 + page))
);

export const getAlertsTotal = R.compose(
  R.length,
  getters.alerts
);

const filesPage = (state, props) => {
  const total = R.length(getters.files(state, props));
  return getPage(state, { ...props, total, instanceId: "files" });
};

export const getFiles = createSelector(
  filesPage,
  (state, props) =>
    PaginationGetters.pageSize(state, { ...props, instanceId: "files" }),
  getters.files,
  (page, pageSize, files) => files.slice(page * pageSize, pageSize * (1 + page))
);

export const getFilesTotal = R.compose(
  R.length,
  getters.files
);

const pagesPage = (state, props) => {
  const total = R.length(getters.pages(state, props));
  return getPage(state, { ...props, total, instanceId: "pages" });
};

export const getPages = createSelector(
  pagesPage,
  (state, props) =>
    PaginationGetters.pageSize(state, { ...props, instanceId: "pages" }),
  getters.pages,
  (page, pageSize, pages) => pages.slice(page * pageSize, pageSize * (1 + page))
);

export const getPagesTotal = R.compose(
  R.length,
  getters.pages
);
