import React, { PureComponent } from "react";
import moment from "moment";
import View from "./View";
import { initials } from "components/PortalV2/GroupManager/utils";
import { MEAL_TYPE_ID } from "utils/item-types";
import formatVariantTitle from "components/Event/Credentials/Modals/ViewOrder/utils/format-variant-title";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import SearchModal from "./Modals/Search";
import AllCheckinsModal from "./Modals/AllCheckins";
import MealPickerModal from "./Modals/Meals";
import OverrideModal from "./Modals/Override";
import * as R from "ramda";

const fullname = ({ fname = "", lname = "" } = {}) =>
  `${fname.trim()} ${lname.trim()}`;

class CateringCheckinController extends PureComponent {
  componentDidMount() {
    return Promise.all([
      this.props.getEvent(this.props.params.eventId),
      this.fetchScans(),
      this.fetchStats(),
      this.fetchMeals()
    ]).then(() => {
      if (!this.props.params.mealId) {
        this.showMealPickerModal();
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.params.mealId !== prevProps.params.mealId) {
      this.fetchScans();
      this.fetchStats();
    }
  }

  getActiveMeal = () => {
    // if meal not found, exit
    if (!this.props.params.mealId) return null;

    // find meal variant for given ID
    const variant = this.props.variants.find(
      v => v.id === this.props.params.mealId
    );

    // if bad ID or variant not found, exit
    if (!variant) return null;

    const variantTitle = formatVariantTitle(variant);

    return {
      id: variant.id,
      name: variant.item.name,
      subname: variantTitle,
      variant
    };
  };

  fetchMeals() {
    return this.props.getItemGroupsByEventAndType(
      this.props.eventDetails.id,
      MEAL_TYPE_ID
    );
  }

  fetchScans() {
    if (this.props.params.mealId) {
      return this.props.getMeal({
        mealId: this.props.params.mealId,
        limit: this.props.modalIsActive ? null : this.props.limit // fetch all from allcheckins modal
      });
    }
    return null;
  }

  fetchStats() {
    if (this.props.params.mealId) {
      return this.props.getMealStats({ mealId: this.props.params.mealId });
    }
    return null;
  }

  handleScan = async (query, { accountId, contactId } = {}) => {
    if (this.props.params.mealId && (query || accountId || contactId)) {
      await this.props.scanMeal({
        mealId: this.props.params.mealId,
        type: "scan",
        query: query && query.trim(),
        accountId,
        contactId
      });
      return await Promise.all([this.fetchStats(), this.fetchScans()]);
    }
    return true;
  };

  handleUndoScan = async parentId => {
    await this.props.scanMeal({
      mealId: this.props.params.mealId,
      parentCheckinId: parentId,
      type: "undo"
    });
    this.fetchStats();
    return this.fetchScans();
  };

  handleOverride = async (parentId, note) => {
    await this.props.scanMeal({
      mealId: this.props.params.mealId,
      parentCheckinId: parentId,
      type: "override",
      message: note
    });
    this.fetchStats();
    return this.fetchScans();
  };

  formatCard = ({
    contact = {},
    account = {},
    id,
    meals_consumed,
    meals_available,
    message,
    overridden_by = {},
    result,
    source_query,
    undone_by = {},
    updated_at
  } = {}) => ({
    id,
    initials: R.all(R.isEmpty, R.values(contact))
      ? R.compose(
          R.join(""),
          R.map(R.head),
          R.take(2),
          R.split(" ")
        )(account.name || "")
      : initials(fullname(contact)),
    mealsConsumed: meals_consumed,
    mealsAvailable: meals_available,
    message,
    name: R.all(R.isEmpty, R.values(contact))
      ? account.name || "Unknown Pass"
      : fullname(contact),
    accountName: R.all(R.isEmpty, R.values(contact)) ? null : account.name,
    onOverride: this.props.hasManagerOverride
      ? () => this.showOverrideModal(id)
      : undefined,
    onUndo: () => this.handleUndoScan(id),
    undone: undone_by.result === "success",
    overridden: overridden_by.result === "success",
    overrideReason: overridden_by ? overridden_by.message : null,
    sourceQuery: source_query,
    status: result,
    type: R.pathOr(R.path(["type", "name"], account), ["type", "name"])(
      contact
    ),
    scanTimestamp: updated_at
  });

  goToCheckinMeal = variantId => {
    this.props.router.push({
      pathname: `/event/${this.props.eventDetails.id}/catering/checkin/${variantId}`
    });
  };

  showMealPickerModal = () => {
    this.props.showModal({
      content: (
        <MealPickerModal
          goToCheckinMeal={this.goToCheckinMeal}
          selectedDays={this.props.selectedDays}
          mealDays={this.props.mealDays}
          variants={this.props.variants.map(v => ({
            ...v,
            active: v.id === this.props.params.mealId
          }))}
          activeMeal={this.getActiveMeal()}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showSearchModal = () => {
    this.props.showModal({
      content: (
        <SearchModal
          eventId={this.props.eventDetails.id}
          onScan={(id, type) =>
            this.handleScan(
              undefined,
              type === "contact" ? { contactId: id } : { accountId: id }
            )
          }
          hideModal={this.props.hideModal}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showViewAllModal = activeFilter => {
    this.props.showModal({
      content: (
        <AllCheckinsModal
          formatter={this.formatCard}
          hideModal={this.props.hideModal}
          activeFilter={activeFilter}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showOverrideModal = id => {
    const activeMeal = this.getActiveMeal();
    this.props.showModal({
      content: (
        <OverrideModal
          eventId={this.props.params.eventId}
          onOverride={note => this.handleOverride(id, note)}
          hideModal={this.props.hideModal}
          mealName={
            activeMeal
              ? `${activeMeal.subname}: ${activeMeal.name}`
              : "(No meal selected)"
          }
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const {
      backgroundUrl,
      checkinsCount,
      latestScan,
      overridesCount,
      params,
      scans,
      user,
      modalIsActive,
      scanning
    } = this.props;

    const viewProps = {
      eventId: params.eventId,
      cateringUrl: `/event/${params.eventId}/catering/manage/all-requests`,
      onScan: this.handleScan,
      backgroundUrl,
      activeMeal: this.getActiveMeal(),
      user: { name: fullname(user), initials: initials(fullname(user)) },
      list: scans.map(this.formatCard),
      latestScan: {
        ...this.formatCard(scans[0]),
        success: latestScan.result === "success",
        scannedBy: fullname(latestScan.created_by_user),
        scannedAt: moment(latestScan.created_at).format(),
        scannedAtFormatted: moment(latestScan.created_at).format(
          "dddd, MMMM Do YYYY, h:mm:ss a"
        ),
        source: latestScan.source_query || "Manual"
      },
      checkinsCount,
      overridesCount,
      scanning,
      showMealPickerModal: this.showMealPickerModal,
      showSearchModal: this.showSearchModal,
      modalIsActive,
      onViewAllScans: this.showViewAllModal,
      onOverridesFilterClick: () => this.showViewAllModal("overrides")
    };
    return <View {...viewProps} />;
  }
}

export default CateringCheckinController;
