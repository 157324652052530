import { createSelector } from "reselect";

import * as R from "ramda";

import { getters } from "Portal/IntakeSettingsModal/model";

import { MODAL_FOOTER_TEXTS, DEFAULT_FIELD_ORDER } from "./constants";

import * as STANDARD_MODULE_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

export const getFooterText = createSelector(
  getters.activeIndex,
  activeIndex => MODAL_FOOTER_TEXTS[activeIndex]
);

export const getShowBackBtn = createSelector(
  getters.activeIndex,
  activeIndex => activeIndex > 0
);

export const getRequiredContactFields = createSelector(
  getters.requiredContactIds,
  getters.contactFields,
  (requiredContactIds, contactFields) =>
    R.map(
      id => R.find(contact => id === contact.id, contactFields),
      requiredContactIds
    )
);

const selectedContactFieldsDescription = createSelector(
  getters.selectedContactFields,
  getters.contactsDescription,
  getters.contactsAlias,
  (fields, description, alias) =>
    R.map(
      field => ({
        ...field,
        description: R.propOr("", field.id, description),
        alias: R.propOr("", field.id, alias)
      }),
      fields
    )
);

export const getNotDraggableContactFields = createSelector(
  selectedContactFieldsDescription,
  R.compose(
    R.sortBy(f => R.prop(f.id)(DEFAULT_FIELD_ORDER)),
    R.filter(({ id }) =>
      [
        STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME,
        STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME,
        STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL
      ].includes(id)
    )
  )
);

export const getDraggableContactFields = createSelector(
  selectedContactFieldsDescription,
  getters.requiredContactIds,
  getters.selectedContactsOrder,
  (selectedContactFields, requiredContactIds, selectedContactsOrder) => {
    const byOrder = R.ascend(R.prop("order"));
    const contactFields = R.compose(
      R.map(contact => ({
        ...contact,
        required: R.any(id => id === contact.id, requiredContactIds),
        order: R.propOr(0, contact.id, selectedContactsOrder),
        isHeader: false
      })),
      R.filter(
        ({ id }) =>
          ![
            STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME,
            STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME,
            STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL
          ].includes(id)
      )
    )(selectedContactFields);

    if (R.isEmpty(selectedContactsOrder)) {
      return contactFields;
    }

    return R.sort(byOrder, contactFields);
  }
);

export const getDraggableContactSectionsAndFields = createSelector(
  getDraggableContactFields,
  getters.contactsHeaderSections,
  (draggableFields, headerSections) => {
    const orderSectionSort = R.sortWith([
      R.ascend(R.prop("order")),
      R.descend(R.prop("isHeader"))
    ]);

    return orderSectionSort([...draggableFields, ...headerSections]);
  }
);

export const getDraggableContactFieldsIds = createSelector(
  getNotDraggableContactFields,
  R.map(R.prop("id"))
);

const selectedAccountFieldsDescription = createSelector(
  getters.selectedAccountFields,
  getters.accountsDescription,
  getters.accountsAlias,
  (fields, description, alias) =>
    R.map(
      field => ({
        ...field,
        description: R.propOr("", field.id, description),
        alias: R.propOr("", field.id, alias)
      }),
      fields
    )
);

export const getNotDraggableAccountFields = createSelector(
  selectedAccountFieldsDescription,
  R.filter(({ id }) => id === STANDARD_MODULE_FIELD_IDS.ACCOUNTS.NAME)
);

export const getDraggableAccountFields = createSelector(
  selectedAccountFieldsDescription,
  getters.requiredAccountIds,
  getters.selectedAccountsOrder,
  (selectedAccountFields, requiredAccountIds, selectedAccountsOrder) => {
    const byOrder = R.ascend(R.prop("order"));
    const accountFields = R.compose(
      R.map(group => ({
        ...group,
        required: R.any(id => id === group.id, requiredAccountIds),
        order: R.propOr(0, group.id, selectedAccountsOrder),
        isHeader: false
      })),
      R.filter(({ id }) => id !== STANDARD_MODULE_FIELD_IDS.ACCOUNTS.NAME)
    )(selectedAccountFields);

    if (R.isEmpty(selectedAccountsOrder)) {
      return accountFields;
    }

    return R.sort(byOrder, accountFields);
  }
);

export const getDraggableAccountSectionsAndFields = createSelector(
  getDraggableAccountFields,
  getters.accountsHeaderSections,
  (draggableFields, headerSections) => {
    const orderSectionSort = R.sortWith([
      R.ascend(R.prop("order")),
      R.descend(R.prop("isHeader"))
    ]);

    return orderSectionSort([...draggableFields, ...headerSections]);
  }
);

export const getNotDraggableAccountFieldIds = createSelector(
  getNotDraggableAccountFields,
  R.map(R.prop("id"))
);

export const getAccountFieldsToSave = createSelector(
  selectedAccountFieldsDescription,
  getters.requiredAccountIds,
  getters.selectedAccountsOrder,
  getters.accountsDescription,
  getters.accountsHeaderSections,
  getters.accountsAlias,
  (
    selectedAccountFields,
    requiredAccountIds,
    selectedAccountsOrder,
    accountsDescription,
    headerSections,
    accountsAlias
  ) => {
    const orderSectionSort = R.compose(
      items =>
        items.map((i, idx) => ({
          ...i,
          order: idx
        })),
      R.sortWith([R.ascend(R.prop("order")), R.descend(R.prop("isHeader"))])
    );
    const accountFields = R.compose(
      R.map(group => ({
        id: group.id,
        alias: accountsAlias[group.id] || "",
        description: accountsDescription[group.id] || "",
        required: R.any(id => id === group.id, requiredAccountIds),
        order: [STANDARD_MODULE_FIELD_IDS.ACCOUNTS.NAME].includes(group.id)
          ? R.prop(group.id)(DEFAULT_FIELD_ORDER)
          : R.propOr(0, group.id, selectedAccountsOrder),
        isHeader: false
      }))
    )(selectedAccountFields);

    if (R.isEmpty(selectedAccountsOrder)) {
      return accountFields;
    }

    return orderSectionSort([...accountFields, ...headerSections]);
  }
);

export const getContactFieldsToSave = createSelector(
  selectedContactFieldsDescription,
  getters.requiredContactIds,
  getters.selectedContactsOrder,
  getters.contactsDescription,
  getters.contactsHeaderSections,
  getters.contactsAlias,
  (
    selectedContactFields,
    requiredContactIds,
    selectedContactsOrder,
    contactsDescription,
    headerSections,
    contactsAlias
  ) => {
    const orderSectionSort = R.compose(
      items =>
        items.map((i, idx) => ({
          ...i,
          order: idx
        })),
      R.sortWith([R.ascend(R.prop("order")), R.descend(R.prop("isHeader"))])
    );

    const contactFields = R.compose(
      R.map(contact => ({
        id: contact.id,
        alias: contactsAlias[contact.id] || "",
        description: contactsDescription[contact.id] || "",
        required: R.any(id => id === contact.id, requiredContactIds),
        order: [
          STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME,
          STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME,
          STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL
        ].includes(contact.id)
          ? R.prop(contact.id)(DEFAULT_FIELD_ORDER)
          : R.propOr(0, contact.id, selectedContactsOrder),
        isHeader: false
      }))
    )(selectedContactFields);

    if (R.isEmpty(selectedContactsOrder)) {
      return contactFields;
    }

    return orderSectionSort([...contactFields, ...headerSections]);
  }
);
