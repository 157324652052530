import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";
import * as R from "ramda";

const iniState = {
  loading: false,
  saving: false,
  salesData: {
    photos: [],
    files: [],
    items: []
  },
  values: {},
  touchedValues: {},
  fieldErrors: {},
  paymentValues: {},
  termsModalEnabled: false,
  modalEnabled: false,
  showingPhoto: null,
  showMapFloor: null
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    init: R.identity,
    getPortalDetails: R.identity,
    getPortalUser: R.identity,
    createRequest: R.identity,
    onSubmit: R.identity,
    showModal: (state, { payload }) => ({
      modalEnabled: payload || true
    }),
    hideModal: () => ({
      modalEnabled: false
    }),
    showTermsModal: () => ({
      termsModalEnabled: true
    }),
    hideTermsModal: () => ({
      termsModalEnabled: false
    }),
    validateFields: (state, { payload: requiredFields }) => {
      const errors = requiredFields.reduce((map, field) => {
        if (!field.valid) {
          map[field.id] = false;
        }
        return map;
      }, {});
      return {
        fieldErrors: errors
      };
    },
    updateValue: (state, { payload: { fieldId, value } }) => ({
      values: {
        ...state.values,
        [fieldId]: value
      },
      touchedValues: {
        ...state.touchedValues,
        [fieldId]: true
      },
      fieldErrors: {
        ...state.fieldErrors,
        [fieldId]: undefined
      }
    }),
    updatePaymentValue: (state, { payload: { fieldId, value } }) => ({
      paymentValues: {
        ...state.paymentValues,
        [fieldId]: value
      },
      touchedValues: {
        ...state.touchedValues,
        [fieldId]: true
      },
      fieldErrors: {
        ...state.fieldErrors,
        [fieldId]: undefined
      }
    })
  }
});

export default handlers;
