import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const DefaultHeader = ({ title }) => (
  <div styleName="container">
    <div styleName="text">{title}</div>
  </div>
);

export default CSSModules(DefaultHeader, css);
