import { createModule } from "redux-mvc";
import GenerateDocumentsModal from "Modules/GenerateDocumentsModal";

import model from "./model";
import sagas from "./sagas";

const templatePickerModule = createModule(model);
templatePickerModule.plugModule(GenerateDocumentsModal);

templatePickerModule.setRootSaga(sagas);

export default templatePickerModule;
