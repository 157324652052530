import React from "react";
import * as R from "ramda";
import CollapsablePanel from "ui-kit/CollapsiblePanel";
import MenuRow from "./MenuRow";

const FolderRow = ({
  hideMenu,
  name,
  pagesChildren,
  sections,
  paddingLeft = 0,
  ...otherProps
}) => (
  <CollapsablePanel
    bg="transparent"
    arrowColor="white"
    heading={() => (
      <div
        style={{
          padding: "15px 0 15px 28px",
          width: 255 - paddingLeft,
          paddingLeft: 28 + paddingLeft,
          marginRight: 5 + paddingLeft,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontWeight: 600,
          fontSize: 18,
          color: "white"
        }}
      >
        {name}
      </div>
    )}
    style={{ borderBottom: "1px solid #464646" }}
  >
    <div>
      {R.compose(
        R.map(page => (
          <MenuRow
            key={page.id}
            {...otherProps}
            hideMenu={hideMenu}
            sections={sections}
            pagesChildren={R.propOr([], "children", page)}
            contentType={page.content_type}
            customStyle={
              R.isEmpty(R.propOr([], "children", page))
                ? { border: "none", fontSize: 16, fontWeight: 400 }
                : {}
            }
            name={page.name}
            path={page.path}
            id={page.id}
            paddingLeft={paddingLeft + 8}
          >
            {page.name}
          </MenuRow>
        )),
        R.sort((a, b) => a.order - b.order),
        R.map(childId => R.find(R.propEq("id", childId), sections))
      )(pagesChildren)}
    </div>
  </CollapsablePanel>
);
export default FolderRow;
