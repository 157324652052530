import { REQUEST, RECEIVE, ERROR, CLEAR_ERROR, INVALIDATE } from "./constants";
import * as selectors from "./selectors";
import api from "./api";

export function getWidget(widgetId, eventId) {
  return async (dispatch, getState) => {
    // if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST, payload: { widgetId } });
      const widget = await api.get(
        getState().user.user.credentials,
        widgetId,
        eventId
      );
      dispatch({ type: RECEIVE, payload: widget });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

// { name, type, settings, dashboardId }
export function addWidget(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

// { name, type, widgetId, settings }
export function updateWidget(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.put(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}
// { widgetId }
export function deleteWidget(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.delete(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function invalidate() {
  return {
    type: INVALIDATE
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR
  };
}
