import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import TypePicker from "components/Global/StandAloneEditors/Types";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import {
  ButtonGroup,
  Submit,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";
import FormElements from "components/Global/Modal/Layout/FormElements";

const { Label, InputGroup } = FormElements;

class ChangeRecordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: props.typeId ? props.typeId : null,
      loading: false
    };
  }

  componentDidMount() {
    if (
      (!this.props.types || !this.props.types.length) &&
      this.props.moduleId
    ) {
      this.props.getRecordTypes({
        moduleId: this.props.moduleId,
        options: {
          orgId: this.props.orgId,
          eventId: this.props.eventId
        }
      });
    }
  }

  @autobind
  update(e) {
    if (e) e.preventDefault();

    if (this.validateForm()) {
      this.props.update({
        type: this.state.type
      });
    }

    return this.props.hideModal();
  }

  validateForm() {
    return this.state.type;
  }

  @autobind
  handleTypeSelect({ id }) {
    this.setState({ type: id });
  }

  @autobind
  name() {
    const type = this.props.types.find(t => t.id === this.state.type);
    return type && type.name ? type.name : type;
  }

  render() {
    return (
      <StyleWrapper
        hideModal={this.props.hideModal}
        heading="Change Type"
        width={410}
      >
        <form onSubmit={this.update}>
          <InputGroup>
            <Label>Type</Label>
            <TypePicker
              value={this.name(this.state.type)}
              list={this.props.types}
              onSelect={this.handleTypeSelect}
            />
          </InputGroup>
          <ButtonGroup>
            <Submit title="Update Type" disabled={!this.validateForm()} />
            <ButtonOutline title="Cancel" onClick={this.props.hideModal} />
          </ButtonGroup>
        </form>
      </StyleWrapper>
    );
  }
}

ChangeRecordModal.propTypes = {
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  ).isRequired,
  update: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default ChangeRecordModal;
