import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import BulkLoginDetailsModal from "./BulkLoginDetailsModal";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { sendEmails } from "redux/modules/email/actions";
import { createPortalUsersFromContacts } from "redux/modules/user/actions";
import { eventDetails } from "redux/modules/event/selectors";

function mapStateToProps(state) {
  return {
    eventDetails: eventDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createPortalUsersFromContacts,
      sendEmails,
      showSnackbar
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(
  BulkLoginDetailsModal
);
