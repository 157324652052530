import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  mode: "admin",
  moduleId: null,
  recordId: null,
  showAssigmentModal: false,
  requests: [],
  customCloseDate: null
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    onAssignFileRequests: R.identity,
    onViewRequest: R.identity,
    onViewDueDate: R.identity,
    updateDueDate: R.identity
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
