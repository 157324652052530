import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { Div, Text4, Text5 } from "components/Base";
import ImportRow from "./ImportRow";
import {
  getBulkImportCaption,
  getMessages,
  getPreviewList
} from "Orders/ImportModal/selectors";

const decorate = connect(state => ({
  messages: getMessages(state),
  importCaption: getBulkImportCaption(state),
  previewList: getPreviewList(state)
}));

const PreviewMappedFields = ({ messages, importCaption, previewList }) => (
  <Div
    p={3}
    bg="neutral0"
    display="column.flex-start.center"
    height={1}
    style={{ width: 400, overflowY: "auto", flexShrink: 0 }}
  >
    {R.gt(messages.length, 0) ? (
      <Div p={6} width={1}>
        <ul
          style={{
            listStyleType: "disclosure-closed"
          }}
        >
          {messages.map((message, index) => (
            <li key={`msg${index}`}>
              <Text5
                bold
                mb={4}
                align="center"
                style={{
                  textAlign: "justify"
                }}
              >
                {message}
              </Text5>
            </li>
          ))}
        </ul>
      </Div>
    ) : (
      <Div width={1}>
        <Text4 bold mb={2}>
          {importCaption}
        </Text4>
        {previewList.map((line, index) => (
          <ImportRow key={index} {...line} />
        ))}
      </Div>
    )}
  </Div>
);

export default decorate(PreviewMappedFields);
