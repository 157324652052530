import React from "react";
import { connect } from "react-redux";

import { getters } from "Items/Manage";
import { getSponsorshipGroups } from "Items/Manage/selectors";
import { SPONSORSHIPS_FILTER_ID } from "Passes/Common/constants";

import { ItemFilter } from "ui-kit/FilterControls/View";
import { Text2 } from "components/Base";

const decorate = connect((state, props) => ({
  itemGroups: getSponsorshipGroups(state, props),
  iniSelected: getters.selectedSponsorshipItems(state, props)
}));

const Inventory = ({ itemGroups = [], iniSelected = [] }) => (
  <ItemFilter
    mt={2}
    collapsedDefault
    instanceId={SPONSORSHIPS_FILTER_ID}
    itemGroups={itemGroups}
    modalTitle="Select Items to show"
    iniSelected={iniSelected}
    selectedText="Items"
  >
    <Text2 bold>Sponsorships</Text2>
  </ItemFilter>
);

export default decorate(Inventory);
