import * as R from "ramda";

import { getters } from "ui-kit/Sidebar";

export const isActive = (
  _,
  {
    routes = [],
    params = {},
    activeRoutes = [],
    activeParams = {},
    isActive = false
  }
) => {
  if (isActive) {
    return true;
  }
  if (R.any(r => R.any(R.equals(r.name), activeRoutes), routes)) {
    if (activeParams) {
      return R.all(
        R.equals(true),
        R.map(([key, val]) => {
          if (val === null) {
            return !R.has(key, params) || R.isNil(R.prop(key, params));
          }
          return params[key] === val;
        }, Object.entries(activeParams))
      );
    }
    return true;
  }
  return false;
};

export const isOpened = (state, props) =>
  R.contains(props.id, getters.openedMenues(state, props));
