import Helpers from "utils/Global/Helpers";

export default {
  getPermissionSet: (credentials, { eventId, recordTypeId }) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        skipUserId: true,
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/account/event/${eventId}/record-type/${recordTypeId}/permission-set`,
        credentials,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  addPermissionSet: (credentials, { eventId, recordTypeId }) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        skipUserId: true,
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/account/event/${eventId}/record-type/${recordTypeId}/permission-set`,
        credentials,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
