import React from "react";

import { connect } from "react-redux";

import * as R from "ramda";

import { getters, actions } from "../index";

import { getRecordTypeIds, getRecordTypesLabel } from "../selectors";

import {
  Text1,
  Text2,
  Text3,
  PopMenu,
  PopMenuCard,
  Div,
  DownFilledIcon,
  Radio,
  SmallCheckbox
} from "components/Base";

const decorate = connect(
  state => ({
    selectedRecordTypes: getRecordTypeIds(state),
    recordTypes: getters.recordTypes(state),
    recordLabel: getRecordTypesLabel(state)
  }),
  {
    updateRecordTypedIds: actions.updateRecordTypedIds,
    selectAllRecordTypedIds: actions.selectAllRecordTypedIds
  }
);

const RecordTypesPopover = ({
  selectedRecordTypes,
  recordTypes,
  updateRecordTypedIds,
  selectAllRecordTypedIds,
  recordLabel
}) => {
  const allRecordTypesSelected = R.isEmpty(selectedRecordTypes);
  return (
    <PopMenu
      Label={({ onClick }) => (
        <Div display="row.flex-start.center" onClick={onClick}>
          <Text3 color="primary7" bold mr={2}>
            {recordLabel}
          </Text3>
          <DownFilledIcon color="black" size={20} />
        </Div>
      )}
      onCloseMenu={() => {}}
    >
      {() => (
        <PopMenuCard position="bottom" align="right">
          <Div bg="white" p={1} width={278} bra={1}>
            <Div
              display="row.flex-start.center"
              bg={allRecordTypesSelected ? "neutral0" : "white"}
              px={1}
              py={2}
              bra={1}
              onClick={() => selectAllRecordTypedIds()}
            >
              <Radio selected={allRecordTypesSelected} />
              <Div ml={2}>
                <Text2
                  bold
                  color={allRecordTypesSelected ? "primary7" : "neutral7"}
                >
                  All Record Types
                </Text2>
                <Text1>All records will show</Text1>
              </Div>
            </Div>
            <Div
              bg={!allRecordTypesSelected ? "neutral0" : "white"}
              px={1}
              py={2}
              bra={1}
            >
              <Div
                display="row.flex-start.center"
                onClick={() =>
                  updateRecordTypedIds({
                    id: recordTypes[0].id,
                    isSelected: false
                  })
                }
              >
                <Radio selected={!allRecordTypesSelected} />
                <Div ml={2}>
                  <Text2
                    bold
                    color={!allRecordTypesSelected ? "primary7" : "neutral7"}
                  >
                    Only Selected Types
                  </Text2>
                  <Text1>Only selected types will show</Text1>
                </Div>
              </Div>
              <Div ml={7} mt={1}>
                {R.map(({ id, name }) => {
                  const isSelected = R.any(
                    selectedId => selectedId === id,
                    selectedRecordTypes
                  );
                  return (
                    <Div
                      display="row.flex-start.center"
                      onClick={() => updateRecordTypedIds({ id, isSelected })}
                    >
                      <SmallCheckbox selected={isSelected} />
                      <Text1 ml={1} bold>
                        {name}
                      </Text1>
                    </Div>
                  );
                }, recordTypes)}
              </Div>
            </Div>
          </Div>
        </PopMenuCard>
      )}
    </PopMenu>
  );
};

export default decorate(RecordTypesPopover);
