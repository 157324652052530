import { createHandlers } from "redux-mvc";

import * as R from "ramda";
import moment from "moment";

import { NAMESPACE, INITIAL_ALERT_DATA, SEND_DATE } from "./constants";

const iniState = {
  loading: true,
  isOpen: false,
  sending: false,
  data: INITIAL_ALERT_DATA,
  alertPreview: {},
  settings: {},
  isPreviousVisible: false
};

const model = createHandlers({
  iniState,
  reducers: {
    openModal: R.identity,
    init: R.identity,
    insertContentData: R.identity,
    hideModal: R.identity,
    previewAlert: R.identity,
    sendAlert: R.identity,
    saveAlert: R.identity,
    editAlert: R.identity,
    initData: (_, { payload: { sendAt, id, ...data } }) => ({
      data: {
        ...data,
        alertId: id,
        sendAt: sendAt ? moment(sendAt).format("LLL") : null,
        sendAtType: sendAt ? SEND_DATE.LATER : SEND_DATE.NOW
      }
    }),
    updateField: (state, { payload: { field, value } }) => ({
      data: R.assoc(field, value, state.data)
    }),
    toggleItem: ({ data }, { payload: { itemId, selectedTab } }) => {
      const { sendTo } = data;
      const selectedItems = R.propOr([], selectedTab, sendTo);
      return {
        data: R.assoc(
          "sendTo",
          {
            ...sendTo,
            [selectedTab]: R.contains(itemId, selectedItems)
              ? R.without([itemId], selectedItems)
              : R.concat([itemId], selectedItems)
          },
          data
        )
      };
    },
    clearSelectedItems: ({ data }) => ({
      data: R.assoc(
        "sendTo",
        {
          group_types: [],
          user_types: [],
          sessions: [],
          pass_types: []
        },
        data
      )
    }),
    selectAllItems: ({ data }, { payload: { itemIds, selectedTab } }) => ({
      data: R.assoc(
        "sendTo",
        {
          ...data.sendTo,
          [selectedTab]: itemIds
        },
        data
      )
    }),
    backToEdit: R.always({ alertPreview: {} }),
    closeModal: R.always({ isOpen: false })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
