import React from "react";

import { connect } from "react-redux";
import { Div } from "components/Base";

import { getters } from "../model";

import Table, { TableWrapper } from "ui-kit/Table/View";
import { TABLE_INSTANCE_ID, PAGE_OPTIONS } from "../constants";
import EmptyState from "EventLight/Common/EmptyState";
import PaginationBar from "ui-kit/PaginationBar/View/Slim";
import SearchBar from "ui-kit/SearchBar/View";
import { VARIANTS } from "ui-kit/Theme/constants";
import Loading from "ui-kit/Loading";
import { actions as contentModalActions } from "EventLight/Content/AddContentModal/model";

const decorate = connect(
  state => ({
    showEmptyState: getters.showEmptyState(state),
    content: getters.content(state),
    pagination: getters.pagination(state),
    searching: getters.searching(state)
  }),
  {
    showAddContentModal: () => contentModalActions.showContentModal({})
  }
);

const Body = ({
  showEmptyState,
  content,
  showAddContentModal,
  pagination,
  searching
}) => (
  <>
    {showEmptyState && !content.length ? (
      <EmptyState
        icon="ondemand_video"
        title="You haven't added any content yet"
        description="You can add content that you want to gate to certain ticket holders or to make available to all attendees"
        action="Add Content"
        onClick={showAddContentModal}
      />
    ) : (
      <>
        <Div style={{ marginBottom: 20 }}>
          <SearchBar pill flex={1} variant={VARIANTS.SURFACE} bc="neutral4" />
        </Div>

        {searching ? (
          <Loading />
        ) : (
          <>
            <Div width={1}>
              <TableWrapper
                instanceId={TABLE_INSTANCE_ID}
                style={{ flex: 1, overflowY: "inherit" }}
                innerStyle={{ overflowY: "inherit" }}
              >
                <Table instanceId={TABLE_INSTANCE_ID} showCheckbox={false} />
              </TableWrapper>
            </Div>
            <Div width={1} display="row.center.center">
              <PaginationBar
                style={{
                  width: "100%",
                  color: "#333333",
                  fontSize: "16px",
                  letterSpacing: "0px"
                }}
                pageSizeOptions={PAGE_OPTIONS}
                total={pagination.total}
                rowsText={pagination.total === 1 ? "item" : "items"}
              />
            </Div>
          </>
        )}
      </>
    )}
  </>
);

export default decorate(Body);
