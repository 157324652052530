import React, { Fragment } from "react";
import { withRouter } from "react-router";
import * as R from "ramda";

import { Div, MoneyIcon, SmallAvatar } from "components/Base";

const decorate = R.compose(withRouter);

const Toolbar = ({
  username,
  total,
  children,
  router: { push: navigate },
  adminLink,
  redirectLink,
  logoutLink,
  showCart = true
}) => (
  <Div
    width={1}
    height={54}
    bg="white"
    display="row.space-between.center"
    style={{
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 3,
      boxShadow:
        "0px 7.43943px 17.3587px rgba(0, 0, 0, 0.0423), 0px 32.2375px 49.5962px rgba(0, 0, 0, 0.01)"
    }}
    py={2}
    px={2}
    pr={[2, 2, 6]}
  >
    {children}
    <Div display="row.flex-start.center" height={1}>
      <Div px={[1, 1, 2]} display="row.flex-start.center" height={1}>
        {adminLink ? (
          <Fragment>
            <Div
              fs={1}
              color="black"
              fw={3}
              onClick={() => {
                window.location = adminLink;
              }}
            >
              Return to Admin
            </Div>
            <Div color="black" mx={2}>
              ·
            </Div>
          </Fragment>
        ) : null}
        {logoutLink ? (
          <Fragment>
            <Div
              fs={1}
              color="black"
              fw={3}
              onClick={() => {
                window.location = logoutLink;
              }}
            >
              Logout
            </Div>
            <Div color="black" mx={2}>
              ·
            </Div>
          </Fragment>
        ) : null}

        {username && username.length ? (
          <>
            <Div mr={2} fs={1} color="black" fw={3}>
              {username}
            </Div>
            <SmallAvatar bg="warning3" id={username} text={username} />
          </>
        ) : null}
      </Div>
      {showCart ? (
        <Div
          px={[1, 1, 2]}
          bc="neutral2"
          bl={1}
          display="row.flex-start.center"
          height={1}
          onClick={() => {
            navigate(redirectLink);
          }}
        >
          <MoneyIcon />
          <Div color="black">Cart</Div>
          <Div
            bra={3}
            width={23}
            height={18}
            display="row.center.center"
            bg="altB0"
            color="altB9"
            ml={2}
            fw={3}
            fs={2}
          >
            {total}
          </Div>
        </Div>
      ) : null}
    </Div>
  </Div>
);

export default decorate(Toolbar);
