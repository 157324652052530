import { REQUEST, RECEIVE } from "./constants";
import { registerError } from "redux/modules/errors/actions";
import * as selectors from "./selectors";
import api from "./api";

export function getEvents(data) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const response = await api.get(getState().user.user.credentials, data);
      dispatch({ type: RECEIVE, payload: response.result });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting Eventbrite events"
          }
        ])
      );
    }
  };
}
