import { createModule } from "redux-mvc";

import handlers from "./handlers";
import searchBar from "ui-kit/SearchBar";
import table from "ui-kit/Table";
import viewPicker from "ui-kit/ViewPicker";
import approversLabel from "ApproversLabel";
import selectiveApproversLabel from "SelectiveApproversLabel";
import snackbar from "ui-kit/Snackbar";

const thisModule = createModule({ ...handlers, batch: true });
thisModule.plugModule(searchBar);
thisModule.plugModule(table);
thisModule.plugModule(viewPicker);
thisModule.plugModule(approversLabel);
thisModule.plugModule(selectiveApproversLabel);
thisModule.plugModule(snackbar);

const { actions, getters } = thisModule;

export default thisModule;

export { actions, getters };
