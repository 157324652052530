import React from "react";
import { Div, FontIcon, DragIcon, Text1, Text2 } from "../../index";

const Type4Card = ({
  isDragging,
  locked,
  onRemove,
  onManage,
  name,
  subname,
  iconColor = "gray5",
  icon
}) => (
  <Div
    my={2}
    p={2}
    bra={1}
    bg="white"
    shadow={{ default: 1, hover: 2 }}
    display="row.flex-start.center"
    style={{ opacity: isDragging ? 0 : 1 }}
  >
    <DragIcon size={20} flex={0} />
    {icon && <FontIcon color={iconColor}>{icon}</FontIcon>}
    <Div display="column" flex={1}>
      <Text2 bold pl={2}>
        {name}
      </Text2>
      <Div display="row.flex-start.center">
        {onManage ? (
          <Text1 pl={2} primary onClick={onManage}>
            Edit
          </Text1>
        ) : null}
        {onManage && subname ? <Text1 pl={1}>&middot;</Text1> : null}
        {subname ? (
          <Text1 truncate pl={onManage ? 1 : 2}>
            {subname}
          </Text1>
        ) : null}
      </Div>
    </Div>
    {locked ? (
      <FontIcon fs={3} color="neutral6">
        lock
      </FontIcon>
    ) : (
      <FontIcon onClick={onRemove} fs={2} color="neutral6">
        close
      </FontIcon>
    )}
  </Div>
);

export { Type4Card };
