import React, { Component } from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import autobind from "autobind-decorator";
import ReactDOM from "react-dom";

import _ from "lodash";

const styles = {
  rowBottom: {
    padding: "30px 34px 30px 34px",
    borderTop: "1px solid #e2e2e2",
    background: "rgba(231,231,231,0.40)"
  },
  rowBottomTitle: {
    fontWeight: 600,
    fontSize: 18,
    color: "#494949",
    marginBottom: 15
  },
  rowActions: {
    padding: "12px 34px",
    borderTop: "1px solid #e2e2e2",
    background: "#F6F6F6",
    display: "flex",
    flexDirection: "row"
  },
  submit: {
    width: 177,
    height: 40,
    background: "#00CC83",
    borderRadius: 4,
    color: "#ffffff",
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: 15,
    letterSpacing: "1.15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    ":hover": {
      background: "#02BE7B"
    }
  },
  cancel: {
    width: 130,
    height: 40,
    borderRadius: 4,
    color: "#989898",
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: 15,
    letterSpacing: "1.15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    ":hover": {
      color: "#888888"
    }
  },
  input: {
    display: "flex",
    width: "100%",
    background: "none",
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: "1px solid #C5C5C5",
    outline: "none",
    fontSize: 18,
    color: "#494949",
    cursor: "pointer"
  }
};

@Radium
export default class Link extends Component {
  static propTypes = {
    hideModal: PropTypes.func.isRequired,
    formId: PropTypes.string.isRequired,
    eventSlug: PropTypes.string.isRequired,
    formSlug: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      copyButtonText: "Copy"
    };
  }

  @autobind
  _handleInputClick(event) {
    ReactDOM.findDOMNode(this.refs.input).setSelectionRange(
      0,
      event.target.value.length
    );
  }

  @autobind
  _handleCopy(event) {
    const input = ReactDOM.findDOMNode(this.refs.input);

    // is element selectable?
    if (input && input.select) {
      input.select();

      try {
        document.execCommand("copy");
        input.blur();
        this.setState({
          copyButtonText: "Copied!"
        });

        setTimeout(() => {
          this.setState({
            copyButtonText: "Copy"
          });
        }, 3000);
      } catch (err) {
        alert("Please press Ctrl/Cmd+C to copy.");
      }
    }
  }

  @autobind
  _handleCancel() {
    this.props.hideModal();
  }

  render() {
    const link = `${window.__LENND_APP_URL__}/forms/${this.props.eventSlug}/${
      this.props.formSlug
    }/${this.props.formId}`;
    return (
      <div>
        <div style={styles.rowBottom}>
          <div style={styles.rowBottomTitle}>Link</div>
          <input
            className="styled"
            ref="input"
            style={styles.input}
            defaultValue={link}
            onClick={this._handleInputClick}
          />
        </div>

        {/* row: actions */}
        <div style={styles.rowActions}>
          <div style={styles.submit} key="copy" onClick={this._handleCopy}>
            {this.state.copyButtonText}
          </div>
          <div style={styles.cancel} key="cancel" onClick={this._handleCancel}>
            Cancel
          </div>
        </div>
      </div>
    );
  }
}
