import PropTypes from "prop-types";
import React from "react";
import Popover from "@lennd/material-ui/Popover";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import LenndMuiTheme from "components/Global/Themes/LenndMuiTheme";
import PopoverOverrideTheme from "components/Global/Themes/PopoverOverrideTheme";

const AlertPopover = ({ children, ...props }) => (
  <MuiThemeProvider
    muiTheme={getMuiTheme(
      Object.assign({}, LenndMuiTheme, PopoverOverrideTheme)
    )}
  >
    <Popover
      style={{
        padding: 0,
        background: "none",
        borderRadius: 0,
        width: 296
      }}
      anchorOrigin={{ horizontal: "middle", vertical: "center" }}
      transformOrigin={{ horizontal: "middle", vertical: "center" }}
      {...props}
    >
      {children}
    </Popover>
  </MuiThemeProvider>
);

AlertPopover.propTypes = {
  children: PropTypes.node.isRequired
};

export default AlertPopover;
