import Helpers from "utils/Global/Helpers";

/* eslint no-underscore-dangle: "off" */

// @TODO: Remove harcoded event
export default {
  search: (
    credentials,
    userId,
    eventId,
    orgId,
    itemBlockId,
    submissionId,
    lineItemIds,
    orderIds,
    data
  ) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/orders/line-items/${data.grouping}`,
        qs: {
          eventId,
          orgId,
          itemBlockId,
          submissionId,
          lineItemIds,
          orderIds,
          ...data
        },
        credentials,
        success,
        error
      });
    }),
  export: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/orders/line-items/${data.grouping}/export`,
        qs: { eventId: eventId, ...data },
        credentials,
        success,
        error
      });
    }),
  getStats: ({ credentials, eventId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/orders/line-items/stats`,
        qs: { eventId: eventId },
        credentials,
        success,
        error
      });
    }),
  getCategory: ({ categoryId, credentials }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/items/groups/${categoryId}`,
        credentials,
        success,
        error
      });
    }),
  getItem: ({ itemId, credentials }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/items/items/${itemId}`,
        credentials,
        success,
        error
      });
    }),
  getAccount: ({ recordId, moduleId, credentials, eventId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules/${moduleId}/record/${recordId}`,
        qs: { eventId },
        credentials,
        success,
        error
      });
    }),
  getViews: ({ credentials, eventId, itemTypeId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/orders/order-management/views`,
        qs: { eventId, itemTypeId },
        data,
        credentials,
        success,
        error
      });
    }),
  addView: ({ credentials, eventId, itemTypeId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/orders/order-management/views`,
        qs: { eventId, itemTypeId },
        data,
        credentials,
        success,
        error
      });
    }),
  updateView: ({ credentials, eventId, itemTypeId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/orders/order-management/views`,
        qs: { eventId, itemTypeId },
        data,
        credentials,
        success,
        error
      });
    }),
  deleteView: ({ credentials, eventId, itemTypeId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/orders/order-management/views`,
        qs: { eventId, itemTypeId },
        data,
        credentials,
        success,
        error
      });
    }),
  selectView: ({ credentials, eventId, itemTypeId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/orders/order-management/active-view`,
        qs: { eventId, itemTypeId },
        data,
        credentials,
        success,
        error
      });
    }),
  getActiveView: ({ credentials, eventId, itemTypeId, mode }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/orders/order-management/active-view`,
        qs: { eventId, itemTypeId, mode },
        credentials,
        success,
        error
      });
    }),
  printItems: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/orders/line-items/print`,
        data,
        credentials,
        success,
        error
      });
    }),
  undoPrintItems: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/orders/line-items/print`,
        data,
        credentials,
        success,
        error
      });
    }),
  getStatus: (credentials, { jobId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/jobs/progress/${jobId}`,
        credentials,
        success,
        error
      });
    }),
  updateLineItem: ({ credentials, data }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/orders/line-items`,
        credentials,
        data,
        success,
        error
      });
    })
};
