import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import MuiSnackbar from "material-ui/Snackbar";
import { get } from "lodash";

import { connect } from "react-redux";
import * as SnackbarSelectors from "redux/modules/snackbar/selectors";

const decorate = connect(state => ({
  options: SnackbarSelectors.snackbar(state)
}));

class Snackbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      key: 0,
      message: "",
      action: null,
      onAction: null,
      open: false
    };
  }

  @autobind
  handleRequestClose() {
    this.setState({
      open: false
    });
  }

  @autobind
  onAction() {
    this.setState(
      {
        open: false
      },
      this.state.onAction
    );
  }

  componentWillReceiveProps(nextProps) {
    if (get(this.props, "options.key") === get(nextProps, "options.key")) {
      return;
    }

    const autoHideDuration =
      typeof nextProps.options.autoHideDuration === "undefined"
        ? 3000
        : nextProps.options.autoHideDuration;

    setTimeout(() => {
      this.setState({
        open: true,
        key: nextProps.options.key,
        message: nextProps.options.message,
        action: nextProps.options.action,
        onAction: nextProps.options.onAction,
        autoHideDuration
      });
    }, 400);
  }

  render() {
    return (
      <MuiSnackbar
        message={this.state.message}
        open={this.state.open}
        action={this.state.action}
        onActionClick={this.onAction}
        onRequestClose={this.handleRequestClose}
        autoHideDuration={this.state.autoHideDuration}
      />
    );
  }
}

Snackbar.propTypes = {
  options: PropTypes.object
};

export default decorate(Snackbar);
