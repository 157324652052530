import React from "react";
import { AddIcon, Text4, SuperFilledButton, Div } from "components/Base";

const Empty = ({ action, text }) => (
  <Div flex={1} display="column.center.center" py={7}>
    <Text4 bold>{text}</Text4>
    <SuperFilledButton mt={4} bg="primary5" onClick={action} LeftIcon={AddIcon}>
      Create Layout
    </SuperFilledButton>
  </Div>
);

export default Empty;
