import React, { Component } from "react";
import View from "components/Global/OrderReports/View";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as R from "ramda";
import orderReportController from "components/Global/OrderReports";
import {
  hasApprovals,
  parseApproval,
  isRejected,
  isApproved
} from "components/Global/Approvals/utils/approvals-helpers";
import { paginatorResults, paginatorHandler } from "components/Base";

const getApprovedQty = lineItem =>
  !hasApprovals(lineItem) || isApproved(lineItem)
    ? lineItem.count_of_requested
    : 0;

const getRejectedQty = lineItem =>
  isRejected(lineItem) ? lineItem.count_of_requested : 0;

class Controller extends Component {
  render() {
    const {
      approveLineItems,
      cando,
      countOfRemainingReviews,
      denyRemaining,
      approveRemaining,
      currentPage,
      loading,
      goToAccount,
      goToContact,
      goToPage,
      goToOrder,
      onSelectResultsPerPage,
      onSearch,
      removeAssignment,
      removeAssignments,
      showLinkedRecordsModal,
      rejectLineItems,
      report,
      resultsPerPage,
      searchTerm,
      showAddOrderModal,
      user
    } = this.props;

    const views = [
      {
        id: "all-orders",
        name: "All Orders",
        active: true
      }
    ];

    const reportWithHandlers = R.map(item => {
      return {
        headerSubmessages: [
          { value: item.contact.email },
          ...item.accounts.map(a => ({
            value: a.name,
            onClick: () => goToAccount(a.id)
          }))
        ],
        headerTitle: item.contact.name,
        percentComplete: (
          (item.count_of_approved / item.count_of_requested) *
          100
        ).toFixed(0),
        numPendingReview: item.count_of_requested - item.count_of_approved,
        bulkApprovalProps: {
          countOfRemainingReviews: countOfRemainingReviews(item.items),
          onBulkApprove: () => approveRemaining(item.items),
          onBulkReject: () => denyRemaining(item.items)
        },
        rowData: R.compose(
          R.sortBy(R.prop("itemName")),
          R.values,
          R.reduce(
            (
              rows,
              {
                count_of_requested,
                variant,
                order,
                price,
                assigned_to,
                ...lineItem
              }
            ) => {
              const approval = parseApproval({
                record: lineItem,
                userId: user.id
              });
              const key = `${variant.id}-${approval.approvalStatus}`;

              // grouping these by variantid and by approval status - approved, denied, pending
              // TODO
              // handle assignments when rolled together,
              // handle display multiple orders when rolled together,
              // re-sort item names, since this is going to destroy server sorting
              // update search to have more consistent results

              let value = {};
              if (rows[key]) {
                value = rows[key];
                value.numRequired += count_of_requested;
                value.numApproved += getApprovedQty({
                  count_of_requested,
                  ...lineItem
                });
                value.numRejected += getRejectedQty({
                  count_of_requested,
                  ...lineItem
                });
                value.orders = R.uniqBy(R.prop("number"), [
                  ...rows[key].orders,
                  { number: order.number, onClick: () => goToOrder(order.id) }
                ]);
                value.lineItems = [...rows[key].lineItems, lineItem];
                value.approvalProps = {
                  approveLineItems: () =>
                    approveLineItems([...rows[key].lineItems, lineItem]),
                  rejectLineItems: () =>
                    rejectLineItems([...rows[key].lineItems, lineItem]),
                  ...approval
                };
                value.assignmentIds = [
                  ...rows[key].assignmentIds,
                  assigned_to.id
                ];
                if (
                  cando(
                    `${STANDARD_MODULE_IDS.credentials.id}_update_line_items`
                  )
                ) {
                  value.onAssign = !lineItem.is_issued
                    ? count =>
                        showLinkedRecordsModal(count, [
                          ...rows[key].lineItems.map(r => r.id),
                          lineItem.id
                        ])
                    : rows[key].onAssign;
                  value.onRemoveAssignment = () =>
                    removeAssignments([
                      ...rows[key].assignmentIds,
                      assigned_to.id
                    ]);
                }
              } else {
                value = {
                  numRequired: count_of_requested,
                  numApproved: getApprovedQty({
                    count_of_requested,
                    ...lineItem
                  }),
                  numRejected: getRejectedQty({
                    count_of_requested,
                    ...lineItem
                  }),
                  itemName: variant.display_name,
                  itemColor: variant.item.background_color,
                  price: R.isNil(price) || R.isEmpty(price) ? undefined : price,
                  orders: [
                    { number: order.number, onClick: () => goToOrder(order.id) }
                  ],
                  assignedTo: assigned_to.name,
                  onClickAssignedTo: () => goToContact(assigned_to.id),
                  onAssign: undefined,
                  onRemoveAssignment: undefined,
                  onRemove: () => {}, //removeApproval(lineItem.id),
                  lineItems: [lineItem],
                  assignmentIds: [assigned_to.id],
                  approvalProps: {
                    approveLineItems: () => approveLineItems([lineItem]),
                    rejectLineItems: () => rejectLineItems([lineItem]),
                    ...approval
                  }
                  // hasApprovals: lineItemHasApprovals
                };
                if (
                  cando(
                    `${STANDARD_MODULE_IDS.credentials.id}_update_line_items`
                  )
                ) {
                  value.onAssign = !lineItem.is_issued
                    ? count => showLinkedRecordsModal(count, [lineItem.id])
                    : undefined;
                  value.onRemoveAssignment = () =>
                    removeAssignment(assigned_to.id);
                }
              }
              rows[key] = value;
              return rows;
            },
            {}
          )
        )(item.items)
      };
    })(report);

    return (
      <View
        {...{
          countOfResults: report.length,
          currentPage,
          goToPage,
          loading,
          moduleId: STANDARD_MODULE_IDS.orders.id,
          onSearch,
          searchTerm,
          onSelectResultsPerPage,
          report: paginatorResults({
            records: reportWithHandlers,
            resultsPerPage,
            currentPage
          }),
          reportSource: "Passes / Manage",
          reportTitle: "Items by assignee",
          resultsPerPage,
          showAddOrderModal,
          views
        }}
      />
    );
  }
}

export default paginatorHandler(
  orderReportController(Controller, "items", (item, base) => ({
    id: item.id,
    accountName: base.accounts.map(a => a.name).join(" "),
    assignedTo: item.assigned_to.name,
    other: item.variant.display_name
  }))
);
