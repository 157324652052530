import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const SaveForLater = ({ handleClick }) => (
  <div styleName="container">
    <div styleName="button" onClick={handleClick}>
      Save & submit later
    </div>
    <div styleName="description">
      Come back anytime before the due date to finish & submit this form.
    </div>
  </div>
);

SaveForLater.propTypes = {
  handleClick: PropTypes.func.isRequired
};

export default CSSModules(SaveForLater, css);
