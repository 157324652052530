import React, { useState } from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { eventDetails as getEvent } from "redux/modules/event/selectors";

import resolveFormatter from "components/Global/Table3/CellFormatters/utils/resolveFormatter";
import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";
import resolveEditor from "components/Global/StandAloneEditors/utils/resolveEditor";
import ButtonGroup from "components/Global/Buttons/CancelSaveButtonGroup";

import { Div, Text2, EditIcon } from "components/Base";

import { actions } from "Items/QuestionsDetails";
import { getEditingValue, getQuestion } from "Items/QuestionsDetails/selectors";

import { noop } from "utils/General";
import Tooltip from "components/Global/Tooltip";

const preventEnter = e => {
  if (e.which === 13) {
    e.preventDefault();
    return false;
  }
  return e;
};

const wrapAnywhere = name => R.all(n => n !== " ", name) && R.length(name) > 20;

const decorate = connect(
  (state, props) => ({
    question: getQuestion(state, props),
    editingValue: getEditingValue(state, props),
    event: getEvent(state, props)
  }),
  {
    onEditQuestion: actions.setIsEditing,
    onCancelQuestion: actions.cancelEditing,
    onSaveQuestion: actions.saveRequest,
    onChangeQuestion: actions.editQuestion
  }
);

const Field = ({
  id,
  question,
  event,
  index,
  editingValue,
  onEditQuestion = noop,
  onChangeQuestion = noop,
  onCancelQuestion = noop,
  onSaveQuestion = noop,
  ...props
}) => {
  const [hovered, setHovered] = useState(false);
  if (editingValue) {
    const Editor = resolveEditor(question);
    const editorProps = resolveEditorProps(question, event);
    return (
      <Div display="column" p={2} px={4}>
        <form
          onKeyPress={preventEnter}
          className="form-page"
          onSubmit={e => {
            e.preventDefault();
            onSaveQuestion(id);
          }}
        >
          <Div
            style={
              wrapAnywhere(question.name) ? { overflowWrap: "anywhere" } : {}
            }
            width={1}
          >
            <Div display="row.flex-start.center" mb={1}>
              <Text2>{question.name}</Text2>
              {question.is_internal && (
                <Tooltip tooltip="Internal Question" placement="top">
                  <span
                    className="material-icons"
                    style={{
                      fontSize: 14,
                      cursor: "pointer",
                      color: "#888",
                      marginLeft: 8
                    }}
                  >
                    visibility_off
                  </span>
                </Tooltip>
              )}
            </Div>
            <Editor
              {...editorProps}
              field={question}
              value={editingValue}
              onChange={value => onChangeQuestion({ id, value })}
            />
          </Div>
          <Div mt={2} mb={1} width={210}>
            <ButtonGroup handleCancel={() => onCancelQuestion(id)} />
          </Div>
        </form>
      </Div>
    );
  }

  const Formatter = resolveFormatter(question);
  return (
    <Div
      display="row.flex-start.center"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      bg={{ default: "transparent", hover: "neutral1" }}
      px={2}
    >
      <Div
        onClick={() => onEditQuestion(id)}
        p={2}
        mt={index > 0 ? 1 : 0}
        display="column"
        flex={1}
      >
        <Div
          display="row.flex-start.center"
          mb={1}
          style={
            wrapAnywhere(question.name) ? { overflowWrap: "anywhere" } : {}
          }
        >
          <Text2 style={{}}>{question.name}</Text2>
          {question.is_internal && (
            <Tooltip tooltip="Internal Question" placement="top">
              <span
                className="material-icons"
                style={{
                  fontSize: 14,
                  cursor: "pointer",
                  color: "#888",
                  marginLeft: 8
                }}
              >
                visibility_off
              </span>
            </Tooltip>
          )}
        </Div>
        <Formatter
          value={question.value}
          dependentValues={{ meta: { columnSettings: question.settings } }}
          {...props}
        />
      </Div>
      {(hovered && <EditIcon mr={2} />) || null}
    </Div>
  );
};

export default decorate(Field);
