import PropTypes from "prop-types";
import React, { Component } from "react";
import NeutralButton from "components/Global/Module/Buttons/NeutralButton";
import ActionsButtonMenu from "components/Global/CRM/TableViews/ListView/ActionsButtonMenu";
import resolveActionsMenu from "../../utils/resolveActionsMenu";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import ActionButton from "components/Global/Module/Buttons/ActionButton";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import AddFileModal from "EventLight/FileRequests/AddFileModal/View";

@CSSModules(css)
class DocumentRequestActions extends Component {
  componentDidMount() {
    this.fetchDocumentRequestTypes();
  }

  fetchDocumentRequestTypes = () => {
    this.props.getRecords({
      moduleId: STANDARD_MODULE_IDS.documentRequestTypes.id,
      options: {
        orgId: this.props.meta.params.orgId,
        eventId: this.props.meta.params.eventId
      }
    });
  };

  showAddRequestTypeModal = () =>
    this.props.showModal({
      content: (
        <AddFileModal
          onSave={() => {
            this.fetchDocumentRequestTypes();
          }}
        />
      ),
      wrapper: ModalWrapper
    });

  render() {
    return (
      <div styleName="actionButtons">
        <div styleName="buttonWrapper">
          <ActionsButtonMenu menu={resolveActionsMenu(this.props)}>
            <NeutralButton
              label={
                <span className={css.buttonLabel}>
                  Options <i className="material-icons">arrow_drop_down</i>
                </span>
              }
            />
          </ActionsButtonMenu>
        </div>
        <ActionButton
          label="Create Request"
          handleClick={this.showAddRequestTypeModal}
        />
      </div>
    );
  }
}

DocumentRequestActions.propTypes = {
  showModal: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    params: PropTypes.shape({
      orgId: PropTypes.string,
      eventId: PropTypes.string
    })
  }).isRequired,
  showSnackbar: PropTypes.func.isRequired,
  addRecord: PropTypes.func.isRequired,
  getRecords: PropTypes.func.isRequired
};

export default DocumentRequestActions;
