import React from "react";
import { ErrorIcon } from "components/Base";

const ErrorMark = ({ valid = true, description = "" }) =>
  valid ? null : (
    <ErrorIcon
      color={{
        default: "danger7",
        hover: "danger7"
      }}
      tooltip={description}
    />
  );

export default ErrorMark;
