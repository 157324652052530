import React, { Component } from "react";
import Wysiwyg from "components/Global/Wysiwyg";
import Input from "components/Global-2016/Forms/Input";
import PropTypes from "prop-types";
import Checkbox from "rc-checkbox";
import InsertTemplate from "./InsertTemplate";
import { values, get } from "lodash";
import MergeMenu from "./MergeMenu";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import * as R from "ramda";

import { Editor } from "@tinymce/tinymce-react";
import {
  DEFAULT_MODULES,
  DEFAULT_FORMATS
} from "ui-kit/Form/View/AdvancedWYSIWYG";
// const MAX_NUMBER_OF_FILES = 5;
// const MAX_FILES_SIZE = 20 * 1000 * 1000;

@CSSModules(css)
class EmailTemplate extends Component {
  componentDidMount() {
    this.props.getEmailTemplates(this.props.orgId, this.props.eventId);
  }

  handleTemplateSelect = id => {
    const template = get(this.props.templates, [id], {});
    this.props.updateMessage(
      template.content_formatted,
      template.content_plaintext
    );
    if (this.props.showSubject) {
      this.props.updateSubject(template.subject);
    }
    if (this.props.updateReplyTo) {
      this.props.updateReplyTo(template.reply_to);
    }
    if (this.props.updateInvoiceDueDate) {
      this.props.updateInvoiceDueDate(template.invoice_due_date);
    }

    if (this.props.updateAttachments) {
      this.props.updateAttachments(template.attachments);
    }

    if (this.props.updateInvoiceNotes) {
      this.props.updateInvoiceNotes(template.invoice_notes);
    }
  };

  handleTitleChange = e => this.props.updateTemplateTitle(e.target.value);
  handleSubjectChange = e => this.props.updateSubject(e.target.value);

  onMergeTagSelect = tag => {
    const message = `${this.props.message} ${tag}`;
    this.props.updateMessage(message, message);
  };

  render() {
    const {
      templates,
      newTemplate,
      message,
      updateMessage,
      toggleTemplate,
      height,
      showSubject,
      showTemplates,
      useWYSIWYG,
      subject,
      mergeTags,
      version
    } = this.props;

    const tags = R.map(({ fields, ...t }) => ({
      ...t,
      primary: R.filter(
        f =>
          f.is_primary_field &&
          f.meta.type !== "summary" &&
          f.meta.type !== "form"
      )(fields),
      summary: R.filter(f => f.meta.type === "summary")(fields),
      forms: R.filter(f => f.meta.type === "form")(fields),
      secondary: R.filter(
        f =>
          !f.is_primary_field &&
          f.meta.type !== "summary" &&
          f.meta.type !== "form"
      )(fields)
    }))(mergeTags);

    return (
      <div className={css.container}>
        <div className={css.topToolbar}>
          {showTemplates ? (
            <InsertTemplate
              onTemplateSelect={this.handleTemplateSelect}
              templates={values(templates)}
              orgId={this.props.orgId}
              eventId={this.props.eventId}
            />
          ) : null}
          {mergeTags.length ? (
            <MergeMenu
              mergeTags={tags}
              onMergeTagSelect={this.onMergeTagSelect}
            />
          ) : (
            ""
          )}
        </div>
        <div styleName="inputWrapper">
          {showSubject ? (
            <Input
              onChange={this.handleSubjectChange}
              name="subject"
              containerStyles={css.subject}
              placeholder="Enter your email subject..."
              required
              showRequiredMark={false}
              value={subject}
            />
          ) : (
            ""
          )}
          {version === 2 ? (
            <Editor
              // eslint-disable-next-line no-underscore-dangle
              apiKey={window.__TINY_MCE_API_KEY__}
              outputFormat={useWYSIWYG ? "html" : "text"}
              onEditorChange={val => updateMessage(val, val)}
              // onFocus={() => onFocus(null, { meta: { fieldId, fieldType } })}
              // onBlur={() => onBlur(null, { meta: { fieldId, fieldType } })}
              value={message}
              init={{
                placeholder: "Enter message here...",
                plugins: useWYSIWYG ? DEFAULT_MODULES : "",
                toolbar: useWYSIWYG ? DEFAULT_FORMATS : "",
                paste_as_text: true,
                menubar: false,
                statusbar: useWYSIWYG ? true : false,
                branding: false,
                relative_urls: false,
                remove_script_host: false,
                image_caption: true,
                image_title: true,
                urlconverter_callback: function(url) {
                  return url;
                }
                /*
                file_picker_callback: function(cb) {
                  const options = {
                    multiple: false,
                    accept: ["image/*"],
                    maxSize: MAX_FILES_SIZE,
                    maxFiles: MAX_NUMBER_OF_FILES - files.length,
                    fromSources: ["local_file_system", "dropbox"]
                  };

                  const path = { path: "template-img/" };

                  const callback = filesAdded => {
                    const file = filesAdded[0];
                    cb(file.url, { title: file.filename });
                  };

                  return Helpers.getFilepicker(options, path, callback);
                }
                */
              }}
            />
          ) : (
            <Wysiwyg
              modules={
                useWYSIWYG
                  ? undefined
                  : {
                      toolbar: ["clean"]
                    }
              }
              text={message}
              onChange={updateMessage}
              height={height}
            />
          )}
        </div>

        {toggleTemplate ? (
          <label styleName="saveTemplate">
            <Checkbox
              name="newTemplate"
              className={css.checkbox}
              onChange={toggleTemplate}
              checked={newTemplate}
            />
            <span styleName="checkboxLabel">Save as new template</span>
          </label>
        ) : null}

        {newTemplate ? (
          <div styleName="well">
            <Input
              onChange={this.handleTitleChange}
              label="Template Name"
              name="templateTitle"
              containerStyles={css.inputGroup}
              required
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

EmailTemplate.defaultProps = {
  height: 200,
  showSubject: false,
  updateSubject: () => {},
  subject: "",
  mergeTags: []
};

EmailTemplate.propTypes = {
  mergeTags: PropTypes.array.isRequired,
  subject: PropTypes.string.isRequired,
  showSubject: PropTypes.bool.isRequired,
  height: PropTypes.bool.isRequired,
  newTemplate: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  updateMessage: PropTypes.func.isRequired,
  updateTemplateTitle: PropTypes.func.isRequired,
  updateSubject: PropTypes.func,
  toggleTemplate: PropTypes.func.isRequired,
  templates: PropTypes.arrayOf(PropTypes.object).isRequired,
  getEmailTemplates: PropTypes.func.isRequired,
  updateReplyTo: PropTypes.func
};

export default EmailTemplate;
