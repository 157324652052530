import React from "react";

import { Div } from "components/Base";
import { StatusTag } from "utils/status-comps";

const Statuses = ({ statuses = [], integrationStatuses = [], ...props }) => (
  <Div display="column.flex-start.flex-start" {...props}>
    {statuses.map((status, index) => (
      <StatusTag key={index} m={1} status={status} />
    ))}
    {integrationStatuses.map(([providerId, status], index) => (
      <StatusTag key={index} m={1} providerId={providerId} status={status} />
    ))}
  </Div>
);

export default Statuses;
