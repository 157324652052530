import React, { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { actions, getters } from "Organizations/CloneEventModal/model";
import {
  Div,
  Dropdown,
  SuperOutlineButton,
  SuperFilledButton,
  TextAreaInput,
  Text3,
  Text2,
  Text1
} from "components/Base";
import { PAGES } from "Organizations/CloneEventModal/constants";
import { getEventsGroupedByYear } from "Organizations/CloneEventModal/selectors";
import { noop } from "utils/General";

const EVENT_FORMAT_DATE = "MMMM DD, YYYY";

const decorate = connect(
  state => ({
    eventsList: getEventsGroupedByYear(state),
    isSubmitting: getters.isSubmitting(state)
  }),
  {
    goToCloneOptions: () => actions.setSelectedPage(PAGES.NEW_EVENT),
    submitCloneRequestToContactSupport: payload =>
      actions.submitCloneRequestToContactSupport(payload)
  }
);

const OptionLabel = React.memo(({ data, innerProps, isFocused }) => {
  return (
    <Div
      display="row.space-between.center"
      style={
        isFocused
          ? {
              "&:hover": { backgroundColor: "#f6f6f6" }
            }
          : {}
      }
      px={3}
      py={1.5}
      {...innerProps}
    >
      <Text2 bold>{data.label}</Text2>
      <Text1 color="neutral6">
        {moment(data.dateFrom).format(EVENT_FORMAT_DATE)} -{" "}
        {moment(data.dateTo).format(EVENT_FORMAT_DATE)}
      </Text1>
    </Div>
  );
});

const ContactSupport = ({
  eventsList = [],
  goToCloneOptions = noop,
  submitCloneRequestToContactSupport = noop,
  isSubmitting = false
}) => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [additionalInformation, setAdditionalInformation] = useState("");

  const groupedOptions = eventsList.map(yearlyGroup => ({
    label: yearlyGroup.year,
    options: yearlyGroup.list.map(event => ({
      label: event.name,
      value: event.id,
      dateFrom: event.date_from,
      dateTo: event.date_to
    }))
  }));

  const onSubmit = () => {
    const payload = {
      eventId: selectedEvent,
      additionalInformation
    };

    submitCloneRequestToContactSupport(payload);
  };

  return (
    <Div display="column">
      <Div
        width={1}
        height={233}
        display="column.flex-start.center"
        px={5}
        pt={8}
        style={{
          overflowY: "auto"
        }}
      >
        <Div
          display="row.flex-start.flex-start"
          mb={6}
          width={1}
          style={{
            flexShrink: 0
          }}
        >
          <Div pl={17} width={270}>
            <Text3 bold color="neutral6">
              Select event to clone
            </Text3>
          </Div>
          <Div
            width={660}
            fw={3}
            color="gray7"
            style={{
              flexShrink: 0
            }}
          >
            <Dropdown
              onChange={({ value }) => setSelectedEvent(value)}
              placeholder="Select event..."
              options={groupedOptions}
              components={{
                Option: OptionLabel
              }}
            />
          </Div>
        </Div>

        <Div
          display="row.flex-start.flex-start"
          mb={6}
          width={1}
          style={{
            flexShrink: 0
          }}
        >
          <Div pl={17} width={270}>
            <Text3 bold color="neutral6">
              Any additional information you’d like to share?
            </Text3>
          </Div>
          <Div
            width={660}
            fw={3}
            color="gray7"
            style={{
              flexShrink: 0
            }}
          >
            <TextAreaInput
              continuous
              bold
              width={1}
              height={100}
              style={{ resize: "none", fontSize: "14px" }}
              shadow="none"
              placeholder="Enter details"
              value={additionalInformation}
              onChange={setAdditionalInformation}
            />
          </Div>
        </Div>
      </Div>

      <Div
        height={86}
        bt={1}
        bc="neutral2"
        width={1}
        display="row.space-between.center"
        px={5}
        style={{
          flexShrink: 0
        }}
      >
        <SuperOutlineButton onClick={goToCloneOptions}>Back</SuperOutlineButton>
        <SuperFilledButton
          bg="altB5"
          onClick={onSubmit}
          disabled={!selectedEvent || isSubmitting}
        >
          Submit
        </SuperFilledButton>
      </Div>
    </Div>
  );
};

export default decorate(ContactSupport);
