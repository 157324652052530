import catering from "./catering";
import cateringDietaryRestrictions from "./catering_dietary_restrictions";
import countries from "./countries";
import credentials from "./credentials";
import date from "./date";
import time from "./time";
import datetime from "./datetime";
import dropdown from "./dropdown";
import eventDays from "./event-days";
import file from "./file";
import inventory from "./inventory";
import reference from "./reference";
import percent from "./percent";
import currency from "./currency";
import source from "./source";
import user from "./user";
import lookup from "./lookup";
import checkbox from "./checkbox";
import text from "./text";
import catalogItem from "./catalog-item";
import approval from "./approval";
import eventList from "./event-list";
import accountList from "./account-list";

/**
 * @param  {Object} value
 * @param  {String} type
 * @param  {Object} eventDetails
 * @param  {Object} meta         Object containing any metadata the to-string
 *  functions might need. EventDetails could be in here but for backwards
 *  compatability, leaving it out for now.
 * @return {String}
 */
export default function(value, type, eventDetails = {}, meta = {}) {
  switch (type) {
    case "dropdown":
    case "multiple_select":
      return dropdown(value);
    case "event-days":
      return eventDays(value, eventDetails);
    case "credentials":
      return credentials(value, eventDetails, meta);
    case "catalog-item":
      return catalogItem(value, meta);
    case "countries":
      return countries(value);
    case "catering":
      return catering(value);
    case "catering-dietary-restrictions":
    case "catering_dietary_restrictions":
      return cateringDietaryRestrictions(value, eventDetails);
    case "file":
      return file(value);
    case "inventory":
    case "inventory-list":
      return inventory(value);
    case "date":
      return date(value);
    case "time":
      return time(value);
    case "datetime":
      return datetime(value, meta);
    case "source":
      return source(value, meta);
    case "reference":
      return reference(value);
    case "currency":
      return currency(value, meta);
    case "lookup":
      return lookup(value, meta);
    case "percent":
      return percent(value, meta);
    case "calculated-number":
      return percent(value, meta);
    case "user":
      return user(value);
    case "checkbox":
      return checkbox(value);
    case "email":
    case "textarea":
    case "text":
      return text(value);
    case "approval":
      return approval(value);
    case "event-list":
      return eventList(value);
    case "account-list":
      return accountList(value);
    default:
      return value;
  }
}
