import React from "react";
import { connect } from "react-redux";
import { Div, Text2 } from "components/Base";
import { actions } from "Forms/WizardModal";

const decorate = connect(
  null,
  {
    setFormMethod: actions.setFormMethod,
    getInventoryCategories: () => actions.getInventoryCategories()
  }
);

const SelectBox = ({
  method,
  title,
  description,
  children,
  setFormMethod,
  getInventoryCategories
}) => (
  <Div
    width={1}
    ba={2}
    bc={{ default: "neutral2", hover: "info7" }}
    bg="neutral1"
    bra={1}
    transition="fast"
    mt={5}
    display="column.flex-start"
    style={{ flexShrink: 0, minHeight: 230 }}
  >
    <Div
      height={70}
      width={1}
      bg="white"
      display="row.space-between.center"
      px={5}
    >
      <Div display="column" style={{ width: "calc(100% - 100px)" }}>
        <Div fs={4} fw={4} color="black">
          {title}
        </Div>
        <Text2 color="gray7">{description}</Text2>
      </Div>
      <Div
        bra={1}
        bg={{ default: "neutral2", hover: "info7" }}
        color={{ default: "gray7", hover: "white" }}
        px={4}
        py={2}
        style={{ cursor: "pointer" }}
        fs={2}
        fw={3}
        transition="fast"
        onClick={() => {
          setFormMethod(method);
          getInventoryCategories();
        }}
        width={100}
        textAlign="center"
      >
        SELECT
      </Div>
    </Div>
    <Div width={1} p={5} style={{ minHeight: 160 }}>
      {children}
    </Div>
  </Div>
);

export default decorate(SelectBox);
