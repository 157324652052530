import React from "react";
import SubNav from "./SubNav";
import * as R from "ramda";
import { connect } from "react-redux";
import { TAB_IDS } from "./constants";
import { withRouter } from "react-router";
import PageTitle from "EventLight/Common/PageTitle";
import { getCurrentRoute } from "./selectors";

import Results from "./Results/View";
import Approvers from "./Approvers/View";

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    currentRoute: getCurrentRoute(state, props)
  }))
);

const Wrapper = ({ currentRoute }) => {
  const Page = R.prop(currentRoute)({
    [TAB_IDS.RESULTS]: Results,
    [TAB_IDS.APPROVERS]: Approvers
  });

  return (
    <>
      <PageTitle titles={["Forms"]} />
      <SubNav selectedView={currentRoute} />
      <Page />
    </>
  );
};

export default decorate(Wrapper);
