import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { noop } from "utils/General";

import { actions } from "ui-kit/Form/model";
import { getValue } from "ui-kit/Form/selectors";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";

import IconPicker from "ui-kit/IconPicker";

const decorate = R.compose(
  WithFormInstanceConsumer({ fieldType: FIELD_TYPES.ICON_PICKER }),
  connect(
    (state, props) => ({
      value: getValue(state, props)
    }),
    bindInstance({
      setIniValue: actions.setIniValue,
      onChange: actions.setFieldValue
    })
  )
);

export const UCIconPicker = ({
  value = "",
  onChange = noop,
  options = [],
  fieldId,
  fieldType,
  setIniValue,
  iniValue
}) => {
  useEffect(() => {
    setIniValue(iniValue, {
      meta: { fieldType, fieldId }
    });
  }, []);
  return (
    <IconPicker
      onChange={({ value }) =>
        onChange(value, {
          meta: { fieldType, fieldId }
        })
      }
      value={value}
      icons={options}
    />
  );
};

export default decorate(UCIconPicker);
