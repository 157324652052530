import React from "react";
import { connect } from "react-redux";
import { createContext } from "redux-mvc";
import * as R from "ramda";
import { actions } from "Forms/WizardModal";
import module from "Forms/WizardModal";
import Layout from "Forms/WizardModal/View/Layout";
import { showModal, hideModal } from "redux/modules/modal/actions";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import FormWizardModalModalSagas from "Forms/WizardModal/sagas";
import AddEditColumnModal from "Modules/AddEditColumnModal/View";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import ItemBlockModal from "components/Event/Settings/Credentials/Modals/ItemBlock";
import CredentialTypeModal from "components/Event/Settings/Catalog/Modals/ItemType";
import RecordTypeModal from "components/Event/Settings/Module/Modals/RecordType";
import { withRouter } from "react-router";

module.setRootSaga(FormWizardModalModalSagas);

const decorate = createContext({
  module: module,
  options: {
    persist: false,
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: ["user", "event", "organization", "@flopflip"]
  },
  lifeCycle: {
    componentWillUnmount() {
      if (this.props.onDone) {
        this.props.onDone();
      }
    }
  },
  handlers: {
    showAddFieldModal({ moduleId, categoryRecordId, type, source }) {
      const modal = (
        <AddEditColumnModal
          eventId={this.props.params.eventId}
          moduleId={moduleId}
          categoryId={categoryRecordId}
          type={type}
          onSave={() => {
            if (source === "inventory") {
              this.store.dispatch(actions.getCategoryFields(moduleId));
            } else if (source === "passes") {
              this.store.dispatch(actions.getItemTypes({ refresh: true }));
            }
          }}
        />
      );
      this.props.showModal({ content: modal });
    },
    showCustomAddFieldModal({ moduleId, fieldId = "", source = "custom" }) {
      const modal = (
        <AddEditColumnModal
          eventId={this.props.params.eventId}
          moduleId={moduleId}
          onSave={() => {
            if (source === "custom") {
              this.store.dispatch(
                actions.getCustomModuleFields({ moduleId, fieldId })
              );
            } else if (source === "applications") {
              this.store.dispatch(actions.getApplicationsGroupField());
            } else if (source === "schedule") {
              this.store.dispatch(actions.getScheduleFields({ moduleId }));
            }
          }}
        />
      );
      this.props.showModal({ content: modal });
    },
    showEditCustomFieldModal({ moduleId, fieldId }) {
      const modal = (
        <AddEditColumnModal
          eventId={this.props.params.eventId}
          moduleId={moduleId}
          fieldId={fieldId}
          onSave={() => {
            this.store.dispatch(
              actions.getCustomModuleFields({ moduleId, fieldId })
            );
          }}
        />
      );
      this.props.showModal({ content: modal });
    },

    showEditFieldModal(moduleId, fieldId, source = "inventory") {
      const modal = (
        <AddEditColumnModal
          eventId={this.props.params.eventId}
          moduleId={moduleId}
          fieldId={fieldId}
          onSave={() => {
            if (source === "inventory") {
              this.store.dispatch(
                actions.updateEditedField({ categoryId: moduleId, fieldId })
              );
            } else if (source === "passes") {
              this.store.dispatch(actions.getItemTypes({ refresh: true }));
            }
          }}
        />
      );
      this.props.showModal({ content: modal });
    },

    showAddCategoryModal() {
      this.props.showModal({
        content: (
          <AddRecordModal
            eventId={this.props.params.eventId}
            moduleId={STANDARD_MODULE_IDS.catalogCategories.id}
            onSave={() => {
              this.store.dispatch(actions.getInventoryCategories(true));
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    showItemBlockModal(id) {
      this.props.showModal({
        content: (
          <ItemBlockModal
            onDone={() => {
              this.store.dispatch(actions.getItemBlocks());
            }}
            itemBlockId={id}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    showAddItemModal(typeId) {
      this.props.showModal({
        content: (
          <CredentialTypeModal
            typeId={typeId}
            onDone={data => {
              this.store.dispatch(actions.addItem(data));
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    showRecordTypeModal() {
      this.props.showModal({
        content: (
          <RecordTypeModal
            onSave={data => this.store.dispatch(actions.addRecordType(data))}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    hideModal() {
      this.props.hideModal();
    }
  }
});

export default R.compose(
  withRouter,
  decorate,
  connect(
    null,
    {
      showModal,
      hideModal
    }
  )
)(Layout);
