import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Popover from "@lennd/material-ui/Popover";

import MakeCopiesForm from "./MakeCopiesForm";
import listensToClickOutside from "react-onclickoutside";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@listensToClickOutside
@CSSModules(css)
export default class MakeCopies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.forceOpen || false,
      anchorEl: null,
      isMounted: false
    };
  }

  componentDidMount() {
    this.setState({
      isMounted: true
    });
  }

  @autobind
  handleClickOutside() {
    if (this.state.open) {
      this.setState({
        open: false
      });
    }
  }

  @autobind
  handleClick(event) {
    event.preventDefault();

    if (!this.state.open) {
      this.setState({
        open: true,
        anchorEl: event.currentTarget
      });
    }
  }

  @autobind
  handleRequestClose() {
    this.setState({
      open: false
    });
    if (this.props.onHide) {
      this.props.onHide();
    }
  }

  render() {
    return (
      <div
        onClick={this.handleClick}
        ref={el => {
          this.container = el;
        }}
      >
        {this.props.children}
        <Popover
          open={this.state.open}
          onClose={this.handleRequestClose}
          anchorEl={this.container}
          container={this.container}
        >
          <MakeCopiesForm
            onClose={this.handleRequestClose}
            duplicate={this.props.duplicate}
          />
        </Popover>
      </div>
    );
  }
}

MakeCopies.propTypes = {
  duplicate: PropTypes.func.isRequired
};
