import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "Orders/CreateOrderModal/Customer/constants";

const iniState = {
  loading: false,
  accounts: [],
  contacts: [],
  accountId: "",
  contactId: "",
  selectedContacts: [],
  selectedAccounts: []
};

const model = createHandlers({
  iniState,
  reducers: {
    cancel: R.always(iniState),
    init: R.identity,
    refetchCustomers: R.identity,
    searchCustomer: R.identity,
    selectAccount: R.identity,
    selectContact: R.identity,
    updateAccount: R.identity,
    updateContact: R.identity,
    setCustomerData: (_, { payload: { accounts, contacts } }) => ({
      accounts,
      contacts
    }),
    backToResults: () => ({
      accountId: "",
      contactId: "",
      selectedContacts: [],
      selectedAccounts: []
    }),
    updateAccountsList: (state, { payload: accounts }) => ({
      accounts: R.isEmpty(state.accounts) ? accounts : state.accounts
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
