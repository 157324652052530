import { get } from "lodash";

export default function(value) {
  // Table
  if (get(value, "value.time")) {
    return get(value, "value.time");
  }
  // Section
  if (typeof get(value, "value") === "string") {
    return get(value, "value");
  }
  return "";
}
