import React from "react";
import { Div, BigFilledButton, AddCircleIcon } from "components/Base";
import { Title } from "Passes/SetupCommon";

const Wrapper = ({ id, children }) => (
  <Div
    id={id}
    display="row"
    height={325}
    bra={1}
    mb={5}
    style={{
      backgroundColor: "#F9F9F9"
    }}
  >
    {children}
  </Div>
);

const LeftColumn = ({ children, ...props }) => (
  <Div
    display="column.center"
    width={475}
    br={1}
    bc="#E7E7E7"
    px={7}
    style={{
      flexShrink: 0,
      backgroundColor: "#EDF0F3"
    }}
    {...props}
  >
    {children}
  </Div>
);

const RightColumn = ({ children }) => (
  <Div
    display="column.space-between"
    pt={5}
    pb={5}
    px={5}
    style={{
      width: "100%"
    }}
  >
    {children}
  </Div>
);

const QuickStartForm = ({ icon, name, onClick }) => (
  <Div
    display="row.flex-start.center"
    onClick={onClick}
    bra={1}
    px={3}
    py={2}
    color="gray9"
    width={1}
    bg={{
      default: "inherit",
      hover: "gray1"
    }}
  >
    {icon}
    {name}
  </Div>
);

const Link = ({ children, onClick }) => (
  <Div
    fs={1}
    ml={3}
    underline
    fw={3}
    onClick={onClick}
    color={{
      default: "blue8",
      hover: "blue9"
    }}
  >
    {children}
  </Div>
);

const Description = ({ children }) => (
  <Div
    fs={3}
    color="gray7"
    mb={3}
    style={{
      maxWidth: 270
    }}
  >
    {children}
  </Div>
);

const SmallTitle = ({ children }) => (
  <Div fw={3} fs={5} mb={2} color="gray9">
    {children}
  </Div>
);

const View = ({
  createQuickStartForm,
  createQuickStartApplication,
  createQuickStartGuestList,
  quickStartForms,
  quickStartGuestLists,
  quickStartApplications,
  goToWhenToUseForms,
  goToWhenToUseApplications,
  goToWhenToUseGuestLists,
  canViewPasses,
  showTemplatesModal,
  showRequestFormsTemplatesModal,
  showApplicationsTemplatesModal
}) => (
  <Div>
    <Title>How are you collecting & managing pass requests?</Title>

    {/* Request Forms */}
    <Wrapper id="request-forms">
      <LeftColumn display="column.flex-start">
        <img
          src="//d2dks4tzxs6xee.cloudfront.net/img/passes-pencil.png"
          width={230}
          alt=""
          style={{
            marginTop: "-5px"
          }}
        />
        <Div display="column" ml={7} mt={3}>
          <Div fw={3} fs={5} mb={2} color="gray9">
            Request Forms
          </Div>
          <Description>
            Allow groups or individuals to request passes via custom request
            forms.
          </Description>
          <Div display="row.flex-start.center">
            <BigFilledButton bg="altB5" onClick={createQuickStartForm}>
              Get Started
            </BigFilledButton>
            <Link onClick={goToWhenToUseForms}>When to use forms</Link>
          </Div>
        </Div>
      </LeftColumn>
      <RightColumn>
        <Div>
          <Div uppercase fs={1} color="gray6" mb={2} fw={3} px={3}>
            Templates:
          </Div>
          {quickStartForms.map(form => (
            <QuickStartForm key={form.name} {...form} />
          ))}
        </Div>
        <QuickStartForm
          icon={<AddCircleIcon mr={3} color="gray4" />}
          name="View all templates"
          onClick={showRequestFormsTemplatesModal}
        />
      </RightColumn>
    </Wrapper>

    {/* Guest Lists */}
    {canViewPasses ? (
      <Wrapper id="guest-lists">
        <LeftColumn>
          <Div ml={5}>
            <img
              src="//d2dks4tzxs6xee.cloudfront.net/img/passes-guest-list.png"
              width={57}
              alt=""
            />
          </Div>
          <Div display="column" ml={7} mt={3}>
            <SmallTitle>Allocations</SmallTitle>
            <Description>
              Invite groups to add their guests via a unique link & assign from
              allocated passes
            </Description>
            <Div display="row.flex-start.center">
              <BigFilledButton bg="altB5" onClick={createQuickStartGuestList}>
                Get Started
              </BigFilledButton>
              <Link onClick={goToWhenToUseGuestLists}>
                When to use allocations
              </Link>
            </Div>
          </Div>
        </LeftColumn>
        <RightColumn>
          <Div>
            <Div uppercase fs={1} color="gray6" mb={2} fw={3} px={3}>
              Allocations:
            </Div>
            {quickStartGuestLists.map(form => (
              <QuickStartForm key={form.name} {...form} />
            ))}
          </Div>
        </RightColumn>
      </Wrapper>
    ) : null}

    {/* Applications */}
    {canViewPasses ? (
      <Wrapper id="applications">
        <LeftColumn>
          <Div ml={5}>
            <img
              src="//d2dks4tzxs6xee.cloudfront.net/img/passes-applications.png"
              width={54}
              alt=""
            />
          </Div>
          <Div display="column" ml={7} mt={3}>
            <SmallTitle>Applications</SmallTitle>
            <Description>
              Invite group managers to register their working staff & request
              the access passes & on-site needs for each person.
            </Description>
            <Div display="row.flex-start.center">
              <BigFilledButton bg="altB5" onClick={createQuickStartApplication}>
                Get Started
              </BigFilledButton>
              <Link onClick={goToWhenToUseApplications}>
                When to use applications
              </Link>
            </Div>
          </Div>
        </LeftColumn>
        <RightColumn>
          <Div>
            <Div uppercase fs={1} color="gray6" mb={2} fw={3} px={3}>
              Templates:
            </Div>
            {quickStartApplications.map(form => (
              <QuickStartForm key={form.name} {...form} />
            ))}
          </Div>
          <QuickStartForm
            icon={<AddCircleIcon mr={3} color="gray4" />}
            name="View all templates"
            onClick={showApplicationsTemplatesModal}
          />
        </RightColumn>
      </Wrapper>
    ) : null}
  </Div>
);

export default View;
