import { get } from "lodash";
import isRowNotEmpty from "utils/EventForms/FormGrid/isRowNotEmpty";
import getStatus from "utils/Forms/get-approval-status";

export default (submission, fields) => {
  const subforms = fields.filter(field => field.type === "subform");

  if (!subforms.length) {
    const status = getStatus(get(submission, ["module_record", "record"]));
    return {
      approved: status === "approved" ? 1 : 0,
      rejected: status === "rejected" ? 1 : 0,
      requested: 1
    };
  }

  return subforms.reduce(
    (statuses, field) => {
      const subFormSubmissions = get(
        submission,
        `values.${field.id}.value.submissions`,
        {}
      );
      Object.keys(subFormSubmissions).forEach(key => {
        const subFormSubmission = get(subFormSubmissions, key);
        if (
          isRowNotEmpty(subFormSubmission.values, field.subform.form.fields)
        ) {
          const status = getStatus(subFormSubmission);
          statuses.requested += 1;
          if (status) {
            statuses[status] = statuses[status] + 1;
          }
        }
      });
      return statuses;
    },
    {
      approved: 0,
      rejected: 0,
      requested: 0
    }
  );
};
