import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getters, actions } from "../model";

import Loading from "ui-kit/Loading";
import { Page, Title } from "EventLight/Common/FieldLayout";
import Body from "./Body";

import AddFolderModal from "Portal/Settings/AddFolderModal/View";
import AddPageModal from "Portal/Settings/AddPageModal/View";
import { WIDTH } from "Portal/List/constants";
import { Div } from "components/Base";

const decorate = connect(
  state => ({
    loading: getters.loading(state),
    showAddPageModal: getters.showAddPageModal(state),
    showAddFolderModal: getters.showAddFolderModal(state),
    selectedFolderId: getters.selectedFolderId(state),
    selectedPageId: getters.selectedPageId(state),
    folderName: getters.folderName(state)
  }),
  {
    init: actions.init,
    closeAddPageModal: actions.closeAddPageModal,
    closeAddFolderModal: actions.closeAddFolderModal
  }
);

const Layout = ({
  loading,
  init,
  showAddPageModal,
  closeAddPageModal,
  showAddFolderModal,
  closeAddFolderModal,
  selectedFolderId,
  selectedPageId,
  folderName
}) => {
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Page>
        <Title
          icon="pages"
          title="Pages"
          description="Build pages to share data to your users"
        />

        {loading ? (
          <Loading />
        ) : (
          <>
            {showAddPageModal ? (
              <AddPageModal hideModal={closeAddPageModal} id={selectedPageId} />
            ) : null}
            {showAddFolderModal ? (
              <AddFolderModal
                hideModal={closeAddFolderModal}
                id={selectedFolderId}
                folderName={folderName}
              />
            ) : null}

            <Div width={1} display="row.flex-start.flex-start" mt={3} mb={5}>
              <Div
                width={1}
                display="column"
                style={{
                  maxWidth: WIDTH
                }}
              >
                <Body />
              </Div>
            </Div>
          </>
        )}
      </Page>
    </>
  );
};

export default decorate(Layout);
