import * as R from "ramda";
import { createSelector } from "reselect";

import { getters } from "./model";

export const isRecorderMode = createSelector(
  (state, props) => R.prop("iniValue", props),
  getters.video,
  (iniPathVideo, video) => R.isNil(iniPathVideo) || !R.isNil(video)
);
