import { combineReducers } from "redux";
import { get, uniq, find, pickBy, findIndex } from "lodash";
import getCombinedRelationshipFields from "./utils/getCombinedRelationshipFields";
import {
  RECEIVE,
  REQUEST,
  UPDATE_COMPLETE as FORM_UPDATE_COMPLETE,
  ERROR,
  CLEAR_ERROR,
  INVALIDATE
} from "./constants";
import {
  CREATE_COMPLETE as FIELD_CREATE_COMPLETE,
  UPDATE_COMPLETE as FIELD_UPDATE_COMPLETE,
  BULK_UPDATE_COMPLETE as FIELD_BULK_UPDATE_COMPLETE,
  DELETE as FIELD_DELETE,
  SUBFORM_CREATE_COMPLETE as SUBFORM_FIELD_CREATE_COMPLETE,
  SUBFORM_UPDATE_COMPLETE as SUBFORM_FIELD_UPDATE_COMPLETE,
  SUBFORM_UPDATE_WIDTH as SUBFORM_FIELD_UPDATE_WIDTH,
  SUBFORM_BULK_UPDATE_COMPLETE as SUBFORM_FIELD_BULK_UPDATE_COMPLETE,
  SUBFORM_DELETE as SUBFORM_FIELD_DELETE,
  REORDER as FIELD_REORDER,
  START_DRAGGING_SIDEBAR_FIELD,
  STOP_DRAGGING_SIDEBAR_FIELD
} from "./fields/constants";
import {
  DELETE as SECTION_DELETE,
  REORDER as SECTION_REORDER,
  UPDATE_SECTION_FIELD_RELATION
} from "./fields/sections/constants";
import {
  CREATE_SUBFORM_DEFAULT_COMPLETE as SUBMISSION_CREATE_SUBFORM_DEFAULT_COMPLETE,
  CLONE_SUBFORM_DEFAULT_COMPLETE as SUBMISSION_CLONE_SUBFORM_DEFAULT_COMPLETE,
  BULK_DELETE_SUBFORM_DEFAULT as SUBMISSION_BULK_DELETE_SUBFORM_DEFAULT
} from "../submission/constants";
import {
  CREATE_DEFAULT as VALUE_CREATE_DEFAULT,
  BULK_CREATE_DEFAULT as VALUE_BULK_CREATE_DEFAULT
} from "../submission/values/constants";
import {
  DEFAULT_FIELD_WIDTH_UPDATE as SUBFORM_PREFERENCES_FIELD_WIDTH_UPDATE,
  DEFAULT_ROW_ORDER_UPDATE as SUBFORM_PREFERENCES_ROW_ORDER_UPDATE
} from "./preferences/constants";
import {
  CREATE_COMPLETE as FIELD_MAPPING_CREATE_COMPLETE,
  DELETE as FIELD_MAPPING_DELETE
} from "./fields/mapping/constants";
import {
  CREATE_COMPLETE as FIELD_RELATIONSHIP_CREATE_COMPLETE,
  DELETE as FIELD_RELATIONSHIP_DELETE
} from "./fields/relationship/constants";

const form = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload.form.form;
    case INVALIDATE:
      return {};
    case FORM_UPDATE_COMPLETE:
      return {
        ...state,
        ...action.payload.form
      };
    case FIELD_MAPPING_CREATE_COMPLETE:
      return {
        ...state,
        mapped_fields: [...state.mapped_fields, action.payload.record]
      };
    // @TODO: Handle FIELD_MAPPING_DELETE
    case FIELD_MAPPING_DELETE:
      return {
        ...state,
        mapped_fields: state.mapped_fields
      };
    case FIELD_RELATIONSHIP_CREATE_COMPLETE:
      return {
        ...state,
        field_relationships: [
          ...state.field_relationships,
          action.payload.record
        ]
      };
    // @TODO: Handle FIELD_RELATIONSHIP_DELETE
    case FIELD_RELATIONSHIP_DELETE:
      return {
        ...state,
        field_relationships: state.field_relationships
      };
    case FIELD_CREATE_COMPLETE:
      return {
        ...state,
        field_relationships:
          action.payload.parentFields && action.payload.childFields
            ? [
                ...state.field_relationships,
                ...getCombinedRelationshipFields(
                  action.payload.field.id,
                  action.payload.parentFields,
                  action.payload.childFields
                )
              ]
            : state.field_relationships,
        mapped_fields: action.payload.mappedFields
          ? uniq([
              ...state.mapped_fields,
              ...action.payload.mappedFields.map(record => ({
                parent_field_id: action.payload.field.id,
                source_field_id: record.sourceFieldId,
                destination_field_id: record.destinationFieldId
              }))
            ])
          : state.mapped_fields,
        fields: [...state.fields, action.payload.field]
      };
    case FIELD_UPDATE_COMPLETE:
      return {
        ...state,
        fields: [
          ...state.fields.map(
            field =>
              field.id === action.payload.field.id
                ? { ...field, ...action.payload.field }
                : field
          )
        ]
      };
    case FIELD_BULK_UPDATE_COMPLETE:
      return {
        ...state,
        fields: [
          ...action.payload.fields.reduce(
            (fields, field) => {
              const currentFieldIndex = findIndex(fields, { id: field.id });
              fields[currentFieldIndex] = {
                ...fields[currentFieldIndex],
                ...field
              };
              return fields;
            },
            [...state.fields]
          )
        ]
      };
    case FIELD_DELETE:
      return {
        ...state,
        fields: [
          ...state.fields.filter(field => field.id !== action.payload.fieldId)
        ]
      };
    case FIELD_REORDER:
      return {
        ...state,
        fields: state.fields.map(field => ({
          ...field,
          order: action.payload.fields[field.id]
        }))
      };
    case START_DRAGGING_SIDEBAR_FIELD:
      return {
        ...state,
        fields: [
          ...state.fields,
          {
            ...action.payload.sidebarField,
            order: state.fields.length - 1
          }
        ]
      };
    case STOP_DRAGGING_SIDEBAR_FIELD:
      return {
        ...state,
        fields: state.fields.filter(f => f.type !== "placeholder")
      };
    case VALUE_CREATE_DEFAULT:
      return {
        ...state,
        fields: [
          ...state.fields.map(field => {
            if (
              field.type === "subform" &&
              field.id === action.payload.subformId
            ) {
              return {
                ...field,
                subform: {
                  ...field.subform,
                  form: {
                    ...field.subform.form,
                    submissions: {
                      ...field.subform.form.submissions,
                      [action.payload.submissionId]: {
                        ...field.subform.form.submissions[
                          action.payload.submissionId
                        ],
                        values: {
                          ...field.subform.form.submissions[
                            action.payload.submissionId
                          ].values,
                          [action.payload.fieldId]: action.payload.value
                        }
                      }
                    }
                  }
                }
              };
            }
            return field;
          })
        ]
      };
    case VALUE_BULK_CREATE_DEFAULT:
      return {
        ...state,
        fields: [
          ...state.fields.map(field => {
            if (
              field.type === "subform" &&
              field.id === action.payload.subformId
            ) {
              return {
                ...field,
                subform: {
                  ...field.subform,
                  form: {
                    ...field.subform.form,
                    submissions: {
                      ...field.subform.form.submissions,
                      ...action.payload.values.reduce((submissions, value) => {
                        if (!submissions[value.submissionId]) {
                          submissions[value.submissionId] = {
                            ...field.subform.form.submissions[
                              value.submissionId
                            ],
                            values: {
                              ...field.subform.form.submissions[
                                value.submissionId
                              ].values,
                              [value.fieldId]: value.value
                            }
                          };
                        } else {
                          submissions[value.submissionId] = {
                            ...submissions[value.submissionId],
                            values: {
                              ...submissions[value.submissionId].values,
                              [value.fieldId]: value.value
                            }
                          };
                        }
                        return submissions;
                      }, {})
                    }
                  }
                }
              };
            }
            return field;
          })
        ]
      };
    case SUBFORM_PREFERENCES_ROW_ORDER_UPDATE:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          [action.payload.formId]: {
            ...state.preferences[action.payload.formId],
            row_order: action.payload.rowOrder
          }
        }
      };
    case SUBFORM_PREFERENCES_FIELD_WIDTH_UPDATE:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          [action.payload.formId]: {
            ...state.preferences[action.payload.formId],
            field_widths: {
              ...get(
                state,
                `preferences[${action.payload.formId}].field_widths`
              ),
              [action.payload.fieldId]: action.payload.width
            }
          }
        }
      };
    case SUBFORM_FIELD_CREATE_COMPLETE:
      return {
        ...state,
        field_relationships:
          action.payload.parentFields && action.payload.childFields
            ? uniq([
                ...state.field_relationships.filter(
                  record =>
                    record.parent_field_id !== action.payload.field.id &&
                    record.child_field_id !== action.payload.field.id
                ),
                ...getCombinedRelationshipFields(
                  action.payload.field.id,
                  action.payload.parentFields,
                  action.payload.childFields
                )
              ])
            : state.field_relationships,
        mapped_fields: action.payload.mappedFields
          ? uniq([
              ...state.mapped_fields,
              ...action.payload.mappedFields.map(record => ({
                parent_field_id: action.payload.field.id,
                source_field_id: record.sourceFieldId,
                destination_field_id: record.destinationFieldId
              }))
            ])
          : state.mapped_fields,
        fields: [
          ...state.fields.map(field => {
            if (
              field.type === "subform" &&
              field.id === action.payload.subformId
            ) {
              return {
                ...field,
                subform: {
                  ...field.subform,
                  form: {
                    ...field.subform.form,
                    fields: [
                      ...field.subform.form.fields,
                      action.payload.field
                    ],
                    fields_grouped_by_section: [
                      ...field.subform.form.fields_grouped_by_section.map(
                        section => {
                          if (section.id === action.payload.sectionId) {
                            return {
                              ...section,
                              fields: [...section.fields, action.payload.field]
                            };
                          }
                          return section;
                        }
                      )
                    ]
                  }
                }
              };
            }
            return field;
          })
        ]
      };
    case SUBFORM_FIELD_UPDATE_COMPLETE:
      return {
        ...state,
        field_relationships:
          action.payload.parentFields && action.payload.childFields
            ? uniq([
                ...state.field_relationships.filter(
                  record =>
                    record.parent_field_id !== action.payload.field.id &&
                    record.child_field_id !== action.payload.field.id
                ),
                ...getCombinedRelationshipFields(
                  action.payload.field.id,
                  action.payload.parentFields,
                  action.payload.childFields
                )
              ])
            : state.field_relationships,
        mapped_fields: action.payload.mappedFields
          ? uniq(
              state.mapped_fields
                .filter(f => f.parent_field_id !== action.payload.field.id)
                .concat(
                  action.payload.mappedFields.map(record => ({
                    parent_field_id: action.payload.field.id,
                    source_field_id: record.sourceFieldId,
                    destination_field_id: record.destinationFieldId
                  }))
                )
            )
          : state.mapped_fields,
        fields: [
          ...state.fields.map(field => {
            if (
              field.type === "subform" &&
              field.id === action.payload.subformId
            ) {
              return {
                ...field,
                subform: {
                  ...field.subform,
                  form: {
                    ...field.subform.form,
                    fields: [
                      ...field.subform.form.fields.map(
                        subformField =>
                          subformField.id === action.payload.field.id
                            ? action.payload.field
                            : subformField
                      )
                    ],
                    fields_grouped_by_section: [
                      ...field.subform.form.fields_grouped_by_section.map(
                        section => {
                          if (section.id === action.payload.sectionId) {
                            return {
                              ...section,
                              fields: [
                                ...section.fields.map(
                                  sectionField =>
                                    sectionField.id === action.payload.field.id
                                      ? action.payload.field
                                      : sectionField
                                )
                              ]
                            };
                          } else if (section.id === action.payload.field.id) {
                            return {
                              ...section,
                              ...action.payload.field
                            };
                          }
                          return section;
                        }
                      )
                    ]
                  }
                }
              };
            }
            return field;
          })
        ]
      };
    case SUBFORM_FIELD_UPDATE_WIDTH:
      return {
        ...state,
        fields: [
          ...state.fields.map(field => {
            if (
              field.type === "subform" &&
              field.id === action.payload.subformId
            ) {
              return {
                ...field,
                subform: {
                  ...field.subform,
                  form: {
                    ...field.subform.form,
                    fields: [
                      ...field.subform.form.fields.map(
                        subformField =>
                          subformField.id === action.payload.fieldId
                            ? {
                                ...subformField,
                                settings: action.payload.settings
                              }
                            : subformField
                      )
                    ],
                    fields_grouped_by_section: [
                      ...field.subform.form.fields_grouped_by_section.map(
                        section => {
                          if (section.id === action.payload.sectionId) {
                            return {
                              ...section,
                              fields: [
                                ...section.fields.map(
                                  sectionField =>
                                    sectionField.id === action.payload.fieldId
                                      ? {
                                          ...sectionField,
                                          settings: action.payload.settings
                                        }
                                      : sectionField
                                )
                              ]
                            };
                          }
                          return section;
                        }
                      )
                    ]
                  }
                }
              };
            }
            return field;
          })
        ]
      };
    /*
    case SUBFORM_FIELD_BULK_UPDATE_COMPLETE:
      return {
        ...state,
        fields: [
          ...state.fields.map(field => {
            if (
              field.type === "subform" &&
              field.id === action.payload.subformId
            ) {
              return {
                ...field,
                subform: {
                  ...field.subform,
                  form: {
                    ...field.subform.form,
                    fields: [
                      ...action.payload.fields.reduce(
                        (fields, updatedField) => {
                          const currentFieldIndex = findIndex(fields, {
                            id: updatedField.id
                          });
                          fields[currentFieldIndex] = {
                            ...fields[currentFieldIndex],
                            ...updatedField
                          };
                          return fields;
                        },
                        [...field.subform.form.fields]
                      )
                    ],
                    fields_grouped_by_section: [
                      ...field.subform.form.fields_grouped_by_section.map(
                        section => {
                          if (section.id === action.payload.sectionId) {
                            return {
                              ...section,
                              fields: [
                                ...action.payload.fields.reduce(
                                  (fields, updatedField) => {
                                    const currentFieldIndex = findIndex(
                                      fields,
                                      {
                                        id: updatedField.id
                                      }
                                    );
                                    fields[currentFieldIndex] = {
                                      ...fields[currentFieldIndex],
                                      ...updatedField
                                    };
                                    return fields;
                                  },
                                  [...section.fields]
                                )
                              ]
                            };
                          } else if (
                            action.payload.fields
                              .map(f => f.id)
                              .includes(section.id)
                          ) {
                            const updatedField = findIndex(
                              action.payload.fields,
                              { id: section.id }
                            );
                            return {
                              ...section,
                              ...updatedField
                            };
                          }
                          return section;
                        }
                      )
                    ]
                  }
                }
              };
            }
            return field;
          })
        ]
      };
    */
    case SUBFORM_FIELD_DELETE:
      return {
        ...state,
        fields: [
          ...state.fields.map(field => {
            if (
              field.type === "subform" &&
              field.id === action.payload.subformId
            ) {
              return {
                ...field,
                subform: {
                  ...field.subform,
                  form: {
                    ...field.subform.form,
                    fields: [
                      ...field.subform.form.fields.filter(
                        subformField =>
                          subformField.id !== action.payload.fieldId
                      )
                    ],
                    fields_grouped_by_section: [
                      ...field.subform.form.fields_grouped_by_section.map(
                        section => {
                          if (section.id === action.payload.sectionId) {
                            return {
                              ...section,
                              fields: [
                                ...section.fields.filter(
                                  sectionField =>
                                    sectionField.id !== action.payload.fieldId
                                )
                              ]
                            };
                          }
                          return section;
                        }
                      )
                    ]
                  }
                }
              };
            }
            return field;
          })
        ]
      };
    case SECTION_DELETE:
      return {
        ...state,
        fields: [
          ...state.fields.map(field => {
            if (
              field.type === "subform" &&
              field.id === action.payload.subformId
            ) {
              return {
                ...field,
                subform: {
                  ...field.subform,
                  form: {
                    ...field.subform.form,
                    fields: [
                      ...field.subform.form.fields.filter(
                        subformField =>
                          subformField.id !== action.payload.fieldId
                      )
                    ],
                    fields_grouped_by_section: [
                      ...field.subform.form.fields_grouped_by_section
                        .reduce((sections, section) => {
                          if (
                            section.id === action.payload.moveFieldsToSectionId
                          ) {
                            sections.push({
                              ...section,
                              fields: [].concat(
                                section.fields,
                                find(
                                  field.subform.form.fields_grouped_by_section,
                                  {
                                    id: action.payload.fieldId
                                  }
                                ).fields
                              )
                            });
                          } else {
                            sections.push(section);
                          }
                          return sections;
                        }, [])
                        .filter(
                          section => section.id !== action.payload.fieldId
                        )
                    ]
                  }
                }
              };
            }
            return field;
          })
        ]
      };
    case SECTION_REORDER:
      return {
        ...state,
        fields: [
          ...state.fields.map(field => {
            if (
              field.type === "subform" &&
              field.id === action.payload.subformId
            ) {
              return {
                ...field,
                subform: {
                  ...field.subform,
                  form: {
                    ...field.subform.form,
                    fields: [
                      ...field.subform.form.fields.filter(
                        subformField =>
                          subformField.id !== action.payload.fieldId
                      )
                    ],
                    fields_grouped_by_section: [
                      ...field.subform.form.fields_grouped_by_section
                        .reduce((sections, section) => {
                          if (
                            section.id === action.payload.moveFieldsToSectionId
                          ) {
                            sections.push({
                              ...section,
                              fields: [].concat(
                                section.fields,
                                find(
                                  field.subform.form.fields_grouped_by_section,
                                  {
                                    id: action.payload.fieldId
                                  }
                                ).fields
                              )
                            });
                          } else {
                            sections.push(section);
                          }
                          return sections;
                        }, [])
                        .filter(
                          section => section.id !== action.payload.fieldId
                        )
                    ]
                  }
                }
              };
            }
            return field;
          })
        ]
      };
    case SUBMISSION_CREATE_SUBFORM_DEFAULT_COMPLETE:
      return {
        ...state,
        fields: [
          ...state.fields.map(field => {
            if (
              field.type === "subform" &&
              field.id === action.payload.subformId
            ) {
              return {
                ...field,
                subform: {
                  ...field.subform,
                  form: {
                    ...field.subform.form,
                    submissions: {
                      ...field.subform.form.submissions,
                      [action.payload.submission.id]: {
                        id: action.payload.submission.id,
                        submission_record_id:
                          action.payload.submission.submission_record_id,
                        values: {}
                      }
                    }
                  }
                }
              };
            }
            return field;
          })
        ]
      };
    case SUBMISSION_CLONE_SUBFORM_DEFAULT_COMPLETE:
      return {
        ...state,
        fields: [
          ...state.fields.map(field => {
            if (
              field.type === "subform" &&
              field.id === action.payload.subformId
            ) {
              return {
                ...field,
                subform: {
                  ...field.subform,
                  form: {
                    ...field.subform.form,
                    submissions: {
                      ...field.subform.form.submissions,
                      ...Object.keys(action.payload.submissions).reduce(
                        (submissions, originalSubmissionId) => ({
                          ...submissions,
                          ...action.payload.submissions[originalSubmissionId]
                            .cloned_submissions
                        }),
                        {}
                      )
                    }
                  }
                }
              };
            }
            return field;
          })
        ]
      };
    case SUBMISSION_BULK_DELETE_SUBFORM_DEFAULT:
      return {
        ...state,
        fields: [
          ...state.fields.map(field => {
            if (
              field.type === "subform" &&
              field.id === action.payload.subformId
            ) {
              return {
                ...field,
                subform: {
                  ...field.subform,
                  form: {
                    ...field.subform.form,
                    submissions:
                      pickBy(
                        field.subform.form.submissions,
                        (value, key) =>
                          !action.payload.submissionIds.includes(key)
                      ) || {}
                  }
                }
              };
            }
            return field;
          })
        ]
      };
    /*
    case UPDATE_SECTION_FIELD_RELATION: {
      return {
        ...state,
        fields: [
          ...state.fields.map(field => {
            if (
              field.type === "subform" &&
              field.id === action.payload.subformId
            ) {
              return {
                ...field,
                subform: {
                  ...field.subform,
                  form: {
                    ...field.subform.form,
                    fields: field.subform.form.fields.map(subformField => {
                      if (subformField.id === action.payload.toSectionId) {
                        return {
                          ...subformField,
                          fields: [action.payload.field, ...subformField.fields]
                        };
                      }
                      if (subformField.id === action.payload.fromSectionId) {
                        return {
                          ...subformField,
                          fields: subformField.fields.filter(
                            f => f.id !== action.payload.field.id
                          )
                        };
                      }
                      return subformField;
                    }),
                    fields_grouped_by_section: field.subform.form.fields_grouped_by_section.map(
                      section => {
                        if (section.id === action.payload.toSectionId) {
                          return {
                            ...section,
                            fields: [action.payload.field, ...section.fields]
                          };
                        }
                        if (section.id === action.payload.fromSectionId) {
                          return {
                            ...section,
                            fields: section.fields.filter(
                              f => f.id !== action.payload.field.id
                            )
                          };
                        }
                        return section;
                      }
                    )
                  }
                }
              };
            }
            return field;
          })
        ]
      };
    }
    */
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case ERROR:
      return action.payload;
    case CLEAR_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  form,
  error,
  fetching
});
