export const ASSIGNED_FORMS = [
  {
    id: "9e4f50e3-7812-4d16-813c-3b38236d9b96",
    name: "Test form",
    title: "Test form",
    assigned: true,
    required: true,
    due_date: "2021-10-23"
  },
  {
    id: "2e3e4f86-bfce-43d9-a392-1c44fbbcc6d8",
    name: "Test form 2",
    title: "Test form 2",
    assigned: true,
    required: false,
    due_date: null
  }
];

export const ASSIGNED_DOCUMENT_REQUESTS = [
  {
    id: "4eff0b72-9299-4a4f-a14e-30c93e24e11d",
    name: "Credential",
    title: "some pdf",
    assigned: true,
    required: true,
    due_date: "2021-10-23"
  },
  {
    id: "a62fe120-7404-4f7e-90ce-c4b83dd7b8a6",
    name: "User photo",
    title: "some img",
    assigned: true,
    required: false,
    due_date: null
  }
];

export const ASSIGNED_SHARED_FILES = [
  {
    id: "c577ddb8-7114-40c2-a567-deca338a1859",
    content_id: "4eff0b72-9299-4a4f-a14e-30c93e24e11d",
    filename: "some-file.jpg",
    title: "This is some uploaded file",
    description: "This is a description of the file"
  },
  {
    id: "a62fe120-7404-4f7e-90ce-c4b83dd7b8a6",
    content_id: "4eff0b72-9299-4a4f-a14e-30c93e24e11d",
    filename: "User photo",
    title: "some img",
    description: null
  }
];

export const ASSIGNED_PAGES = [
  {
    id: "4eff0b72-9299-4a4f-a14e-30c93e24e11d",
    page_id: "b91b3498-10ad-499c-86ae-8cf705bb4bfb",
    title: "My Page",
    description: "This is some page",
    order: 0
  }
];
