import * as R from "ramda";
import React from "react";
import autobind from "autobind-decorator";

import EditorBase from "./TableConnector";
import CheckBox from "material-ui/Checkbox";

import { noop } from "utils/General";

export default class CheckboxEditor extends EditorBase {
  node = React.createRef();

  @autobind
  onClick() {
    this.handleChange({
      type: "boolean",
      value: !R.prop("value", this.state)
    });
  }

  @autobind
  handleClick(e) {
    if (this.node.current.contains(e.target)) {
      return;
    }

    this.props.onCommit();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClick);
  }

  render() {
    return (
      <div
        onClick={this.onClick}
        style={{
          backgroundColor: "white",
          borderRadius: 4,
          border: "solid 2px #66afe9",
          paddingLeft: 8,
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start"
        }}
        ref={this.node}
      >
        <CheckBox
          onChange={noop}
          checked={R.prop("value", this.state)}
          style={{ width: "auto" }}
          iconStyle={{
            alignSelf: "center"
          }}
        />
      </div>
    );
  }
}
