import React from "react";

import { connect } from "react-redux";

import { getters, actions } from "../index";

import MiniModalWrapper from "ui-kit/MiniModalWrapper";

import Footer from "./Footer";

import Loading from "ui-kit/Loading";

import Body from "./Body";

const decorate = connect(
  state => ({
    loading: getters.loading(state),
    loading: getters.loading(state)
  }),
  {
    setInitialData: actions.setInitialData,
    cancelInstance: actions.cancelInstance
  }
);

class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textCopied: false
    };
  }
  componentDidMount() {
    const { setInitialData, module, moduleId, fields, name } = this.props;
    setInitialData({ module, moduleId, fields, moduleName: name });
  }
  componentWillUnmount() {
    this.props.cancelInstance();
  }

  setInputRef = inputRef => {
    this.inputRef = inputRef;
  };

  onCopyLink = () => {
    if (this.inputRef) {
      const el = this.inputRef;
      const body = document.body;
      let range;
      let sel;
      if (document.createRange && window.getSelection) {
        range = document.createRange();
        sel = window.getSelection();
        sel.removeAllRanges();
        try {
          range.selectNodeContents(el);
          sel.addRange(range);
        } catch (e) {
          range.selectNode(el);
          sel.addRange(range);
        }
      } else if (body.createTextRange) {
        range = body.createTextRange();
        range.moveToElementText(el);
        range.select();
      }

      try {
        document.execCommand("copy");
        this.setState({ textCopied: true });
        setTimeout(() => {
          this.setState({ textCopied: false });
        }, 3000);
      } catch (err) {
        alert("Please press Ctrl/Cmd+C to copy."); // eslint-disable-line no-alert
      }
    }
  };

  render() {
    const { loading, hideModal, name } = this.props;

    return (
      <MiniModalWrapper
        title={`Generated Table of Related: ${name}`}
        hideModal={hideModal}
        width={743}
        height={600}
        bg="neutral0"
        showModal
        isPortal={false}
      >
        {loading ? <Loading /> : <Body setInputRef={this.setInputRef} />}
        <Footer
          hideModal={hideModal}
          onCopyLink={this.onCopyLink}
          textCopied={this.state.textCopied}
        />
      </MiniModalWrapper>
    );
  }
}

export default decorate(Layout);
