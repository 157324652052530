import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";

import * as R from "ramda";

import { actions, getters } from "./index";

import {
  actions as uploadTemplateActions,
  getters as uploadTemplateGetters
} from "Modules/UploadDocumentTemplateModal";

import { registerError } from "redux/modules/errors/actions";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { getCredentials } from "redux/modules/user/selectors";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import Helpers from "utils/Global/Helpers";
import { openInNewWindow } from "utils/General";

import Api from "./api";

const formatMergeData = ({ payload, moduleId }) => ({
  module_id: moduleId,
  merge_tags: R.map(mod => ({
    id: mod.module_id,
    name: mod.name,
    templateTags: R.filter(field => field.meta.type !== "related_module")(
      mod.fields
    )
  }))(payload),
  related_modules: R.compose(
    R.filter(field => field.meta.type === "related_module"),
    R.flatten,
    R.map(module => module.fields)
  )(payload)
});

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const eventId = yield select(getEventId);

  return {
    credentials,
    orgId,
    eventId
  };
};

const init = function*({ payload: { moduleId } }) {
  try {
    const hasModuleId = !R.isNil(moduleId);
    yield put(actions.setHasModuleId(hasModuleId));
    yield put(actions.setLoading(true));
    yield put(actions.setMergeTagsLoading(true));

    const { credentials, eventId } = yield call(getParams);

    const data = R.isNil(moduleId)
      ? {
          credentials,
          eventId
        }
      : {
          credentials,
          eventId,
          moduleId
        };

    const getDocumentApi = R.isNil(moduleId)
      ? Api.getDocumentTemplates
      : Api.getDocumentTemplatesByModuleId;

    const [docObj, { modules }, { payload }] = yield all([
      call(getDocumentApi, data),
      call(Api.getModulesByEventId, {
        eventId,
        credentials
      }),
      call(Api.getMergeTags, {
        moduleId: R.isNil(moduleId)
          ? STANDARD_MODULE_IDS.accounts.id
          : moduleId,
        eventId,
        credentials
      })
    ]);

    yield put(
      actions.setInitialData({
        documentTemplates: docObj.payload,
        modules,
        moduleId,
        hasModuleId,
        mergeData: formatMergeData({
          payload,
          moduleId: R.isNil(moduleId)
            ? STANDARD_MODULE_IDS.accounts.id
            : moduleId
        })
      })
    );
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading document templates"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
    yield put(actions.setMergeTagsLoading(false));
  }
};

const refetchDocumentTemplates = function*() {
  try {
    yield put(actions.setLoading(true));
    const moduleId = yield select(getters.moduleId);
    const { credentials, eventId } = yield call(getParams);

    const data = R.isNil(moduleId)
      ? {
          credentials,
          eventId
        }
      : {
          credentials,
          eventId,
          moduleId
        };

    const getDocumentApi = R.isNil(moduleId)
      ? Api.getDocumentTemplates
      : Api.getDocumentTemplatesByModuleId;

    const docObj = yield call(getDocumentApi, data);
    const documentTemplates = docObj.payload;
    yield put(
      actions.setDocumentTemplates(
        R.sortBy(
          R.compose(
            R.toLower,
            R.prop("title")
          ),
          [...documentTemplates]
        )
      )
    );
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading document templates"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const deleteTemplate = function*({ payload: { templateId } }) {
  try {
    yield put(actions.setLoading(true));

    const { credentials } = yield call(getParams);

    yield call(Api.deleteDocumentTemplate, {
      credentials,
      templateId
    });
    yield put(actions.hideTemplateModal());
    yield put(actions.refetchDocumentTemplates());
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading document templates"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const getFilePicker = () =>
  new Promise(resolve => {
    const options = {
      multiple: false,
      accept: [
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ]
    };

    const path = { path: "document-templates/" };

    Helpers.getFilepicker(options, path, resolve);
  });

const makeTemplateImgUrl = url =>
  `https://process.filestackapi.com/output=format:jpg/resize=w:200/${url}`;

const addUploadDocument = function*() {
  const files = yield call(getFilePicker);
  const templateUrl = R.propOr("", "url", files[0]);
  const templateTitle = R.propOr("", "filename", files[0]);

  yield put(
    actions.showAddTemplateModal({
      templateUrl,
      templateTitle,
      templateImageUrl: makeTemplateImgUrl(templateUrl)
    })
  );
};

const uploadDocument = function*() {
  const files = yield call(getFilePicker);
  const templateUrl = R.propOr("", "url", files[0]);

  yield put(actions.setTemplateUrl(templateUrl));
  yield put(actions.setTemplateImageUrl(makeTemplateImgUrl(templateUrl)));
};

const downloadDocument = function*({ payload: url }) {
  yield call(openInNewWindow, url);
};

const saveDocumentTemplate = function*() {
  const templateUrl = yield select(uploadTemplateGetters.templateUrl);
  const title = yield select(uploadTemplateGetters.title);
  const selectedModuleId = yield select(uploadTemplateGetters.selectedModuleId);

  try {
    yield put(actions.setLoading(true));
    const { credentials, eventId } = yield call(getParams);
    const template = yield select(uploadTemplateGetters.template);

    const apiUploadDocument = R.isNil(template)
      ? Api.postDocumentTemplate
      : Api.putDocumentTemplate;

    const payload = {
      data: { eventId, templateUrl, title, moduleId: selectedModuleId },
      credentials
    };

    yield put(actions.hideTemplateModal());

    yield call(
      apiUploadDocument,
      R.isNil(template)
        ? payload
        : {
            ...payload,
            data: { ...payload.data, templateId: template.id },
            templateId: template.id
          }
    );

    yield put(actions.refetchDocumentTemplates());
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error ocurred uploading fields"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const fetchMergeData = function*({ payload: moduleId }) {
  yield put(actions.setSelectedMergeModuleId(moduleId));

  try {
    yield put(actions.setMergeTagsLoading(true));

    const { credentials, eventId } = yield call(getParams);

    const { payload } = yield call(Api.getMergeTags, {
      moduleId,
      eventId,
      credentials
    });

    yield put(actions.setMergeData(formatMergeData({ payload, moduleId })));
    yield put(actions.setTagsFilter(""));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading document templates"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setMergeTagsLoading(false));
  }
};

const selectModule = function*({ payload: module }) {
  yield put(actions.setSelectedModule(module));
  yield put(actions.setModuleId(module.id));

  try {
    yield put(actions.setMergeTagsLoading(true));

    const { credentials, eventId } = yield call(getParams);
    const moduleId = R.propOr(STANDARD_MODULE_IDS.accounts.id, "id", module);
    const { payload } = yield call(Api.getMergeTags, {
      moduleId,
      eventId,
      credentials
    });

    yield put(actions.setMergeData(formatMergeData({ payload, moduleId })));
    yield put(actions.setSelectedMergeModuleId(moduleId));
    yield put(actions.setTagsFilter(""));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading document templates"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setMergeTagsLoading(false));
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchRefetchDocumentTemplates = function*() {
  yield takeEvery(
    actions.refetchDocumentTemplates.type,
    refetchDocumentTemplates
  );
};

const watchSelectModule = function*() {
  yield takeEvery(actions.selectModule.type, selectModule);
};

const watchDeleteTemplate = function*() {
  yield takeEvery(actions.deleteTemplate.type, deleteTemplate);
};

const watchFetchMergeData = function*() {
  yield takeEvery(actions.fetchMergeData.type, fetchMergeData);
};

const watchDeleteTemplateUpload = function*() {
  yield takeEvery(uploadTemplateActions.deleteTemplate.type, deleteTemplate);
};

const watchUploadDocument = function*() {
  yield takeEvery(uploadTemplateActions.uploadDocument.type, uploadDocument);
};

const watchAddUploadDocument = function*() {
  yield takeEvery(actions.addUploadDocument.type, addUploadDocument);
};

const watchDownloadDocument = function*() {
  yield takeEvery(actions.downloadDocument.type, downloadDocument);
};

const watchDownloadDocumentUpload = function*() {
  yield takeEvery(
    uploadTemplateActions.downloadDocument.type,
    downloadDocument
  );
};

const watchSaveDocumentTemplate = function*() {
  yield takeEvery(
    uploadTemplateActions.saveDocumentTemplate.type,
    saveDocumentTemplate
  );
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchRefetchDocumentTemplates),
    fork(watchDeleteTemplate),
    fork(watchUploadDocument),
    fork(watchDownloadDocument),
    fork(watchDownloadDocumentUpload),
    fork(watchSaveDocumentTemplate),
    fork(watchDeleteTemplateUpload),
    fork(watchFetchMergeData),
    fork(watchSelectModule),
    fork(watchAddUploadDocument)
  ]);
};

export default rootSaga;
