import PropTypes from "prop-types";
import * as R from "ramda";
import React, { Component, cloneElement } from "react";
import { reduce, get, uniqueId } from "lodash";
import listensToClickOutside from "react-onclickoutside";
import ContextMenu from "components/Global/Table3/ContextMenu/ContextMenu";
import jQuery from "jquery";
import DefaultRowRenderer from "components/Global/Table3/RowRenderers/DefaultRow";
import NumberedSelectableRowActionsCell from "components/Global/Table3/RowActionCells/NumberedSelectable";
import Table3Helpers from "components/Global/Table3/Utilities/Helpers";

import ReactDataGrid from "@lennd/react-data-grid/dist/react-data-grid";
import sortableTable from "./SortableTable";
import * as ReactDataGridPlugins from "react-data-grid-addons";
import {
  Div,
  Text5,
  DownIcon,
  RightIcon,
  SmallShadedBox,
  PopoverMenu,
  TinyOutlineButton,
  MoreIcon
} from "components/Base";
import { toClass } from "utils/General";
import exportSheet from "components/Global/CRM/TableViews/HelperComponents/export-factory/exportSheet";

const {
  Draggable: {
    Container: DraggableContainer,
    RowActionsCell,
    DropTargetRowContainer
  },
  Data: { Selectors }
} = ReactDataGridPlugins;

const ActionsButton = toClass(({ onClick }) => (
  <TinyOutlineButton ml={2} onClick={onClick}>
    <MoreIcon size={16} />
  </TinyOutlineButton>
));

const RowGroupRenderer = (groupedRows, exportSheetWithType) => ({
  name,
  onRowExpandClick,
  isExpanded
}) => {
  const rows = groupedRows[name];
  const ArrowIcon = isExpanded ? DownIcon : RightIcon;
  return (
    <Div
      onClick={onRowExpandClick}
      px={2}
      py={3}
      color="neutral7"
      bc="neutral2"
      bg={{ default: "white", hover: "neutral0" }}
      ba={1}
      display="row.flex-start.center"
    >
      <ArrowIcon size={24} color="inherit" />
      <Text5 ml={1} bold color="inherit">
        {name}
      </Text5>
      <SmallShadedBox ml={2}>{`Rows: ${R.length(rows)}`}</SmallShadedBox>

      <PopoverMenu
        key={name}
        Label={ActionsButton}
        menuItems={[
          ["Export CSV", () => exportSheetWithType("csv", rows)],
          ["Export XLSX", () => exportSheetWithType("xlsx", rows)]
        ]}
      />
    </Div>
  );
};

@sortableTable
@listensToClickOutside
class Table3 extends Component {
  componentDidMount() {
    this.id = uniqueId("react-grid-Lennd-Table3_");
  }

  onContextMenuShown = (e, info) => {
    if (this.props.onContextMenuShown) {
      this.props.onContextMenuShown(e, info);
    }
  };

  onCellsDragged = e => {
    if (this.props.onCellsDragged) {
      const cellsToUpdate = [];

      const rows = this.props.rows.slice(0);
      for (let i = e.fromRow; i <= e.toRow; i++) {
        if (e.value !== this.props.rows[i][e.cellKey]) {
          cellsToUpdate.push({
            rowId: this.props.rows[i].id,
            columnId: e.cellKey,
            value: e.value,
            meta: {
              rowIdx: i
            }
          });

          rows[i][e.cellKey] = e.value;
        }
      }
      this.props.onCellsDragged(e, cellsToUpdate);
    }
  };

  onCellSelected = coordinates => {
    const field = this.props.columns.toArray()[coordinates.idx - 1];
    if (!field) return;

    // auto open field types: dropdown, lookup, file
    if (
      field.type === "dropdown" &&
      !get(field, "settings.allowMultipleSelect")
    ) {
      this.refs.grid.openCellEditor(coordinates.rowIdx, coordinates.idx);
    } else if (["lookup", "file"].includes(field.type)) {
      this.refs.grid.openCellEditor(coordinates.rowIdx, coordinates.idx);
    }
  };

  onColumnResize = (index, width) => {
    if (this.props.onColumnResize) {
      this.props.onColumnResize(
        this.props.columns.toArray()[index - 1].id,
        width
      );
    }
  };

  onCellCopyPaste = data => {
    if (this.props.onCellCopyPaste && data !== null) {
      this.props.onCellCopyPaste(data);
    }
  };

  rowGetter = i => this.props.rows[i];

  handleRowUpdated = e => {
    // Don't save update if the old value is the same as the new value
    if (
      !Table3Helpers.valuesAreEqual(
        get(e, `updated[${e.cellKey}]`),
        get(this.props.rows, `[${e.rowIdx}][${e.cellKey}]`)
      )
    ) {
      this.props.saveCell({
        columnId: e.cellKey,
        rowId: this.props.rows[e.rowIdx].id,
        value: e.updated[e.cellKey],
        meta: {
          rowIdx: e.rowIdx
        }
      });
    }
  };

  deselectRows = rows => {
    this.refs.grid.deselectRows(rows);
  };

  handleClickOutside = e => {
    if (
      !jQuery(e.target).parents(`.${this.id}`).length &&
      !jQuery(e.target).parents(".tether-element").length
    ) {
      this.refs.grid.deselectCells();
    }
  };

  // DRAGGABLE ROWS

  reorderRows = e => {
    const selectedRows = Selectors.getSelectedRowsByKey({
      rowKey: this.props.rowKey,
      selectedKeys: this.props.selectedRows.map(r => r.id),
      rows: this.props.rows
    });
    const rows = [...this.props.rows];
    let insertIndex =
      e.rowSource.idx > e.rowTarget.idx ? e.rowTarget.idx + 1 : e.rowTarget.idx;
    const draggedRows = this.isDraggedRowSelected(selectedRows, e.rowSource)
      ? selectedRows
      : [e.rowSource.data];
    draggedRows.reduce((ignored, dr) => {
      const index = rows.indexOf(dr);
      rows.splice(index, 1);
      if (index < insertIndex) {
        if (ignored) {
          insertIndex -= 1;
          return true;
        }
        return true;
      }
    }, false);
    rows.splice(insertIndex, 0, ...draggedRows);
    this.props.updateRowOrder(this.formatRowOrder(rows));
  };

  formatRowOrder = rows =>
    rows.reduce((a, value, index) => {
      a[value[this.props.rowKey]] = index;
      return a;
    }, {});

  isDraggedRowSelected = (selectedRows, rowDragSource) => {
    if (selectedRows && selectedRows.length > 0) {
      const key = this.props.rowKey;
      return selectedRows.find(r => r[key] === rowDragSource.data[key]);
    }
    return false;
  };
  // END DRAGGABLE ROWS

  rowsSelect = newRows => {
    this.props.onRowSelect([
      ...this.props.selectedRows.concat(newRows.map(r => r.row))
    ]);
  };

  rowsUnselect = newRows => {
    let { selectedRows } = this.props;
    newRows.forEach(newRow => {
      selectedRows = selectedRows.filter(r => r.id !== newRow.row.id);
    });
    this.props.onRowSelect([...selectedRows]);
  };

  bindColumnEvents = columns =>
    columns.map(c => {
      if (c.events) {
        c.events = reduce(
          c.events,
          (events, value, key) => {
            events[key] = value.bind(this);
            return events;
          },
          {}
        );
      }
      return c;
    });

  render() {
    const {
      contextMenus,
      draggable,
      enableCellSelect,
      enableRowSelect,
      EmptyRowsView,
      Footer,
      height,
      rowKey,
      rowRenderer,
      rows,
      selectedRows,
      groupBy,
      groupedRows,
      eventDetails,
      references,
      exportSpreadsheetArray,
      showSnackbar,
      fields,
      module,
      ...otherProps
    } = this.props;

    const exportSheetWithType = (contentType, records) =>
      exportSheet({
        showSnackbar,
        details: eventDetails,
        references,
        exportSpreadsheetArray,
        contentType,
        module,
        records,
        fields
      });

    const RowRenderer = DropTargetRowContainer(
      rowRenderer || DefaultRowRenderer
    );
    const columns = this.bindColumnEvents(this.props.columns);
    const defaultDataGridProps = {
      ...otherProps,
      ref: "grid",
      columns,
      enableCellSelect,
      enableRowSelect,
      minHeight: height,
      // @TODO does this need to be here? currently unknown,
      onCellCopyPaste: this.onCellCopyPaste,
      onCellsDragged: this.onCellsDragged,
      onCellSelected: this.onCellSelected,
      onColumnResize: this.onColumnResize,
      onRowUpdated: this.handleRowUpdated,
      rowGroupRenderer: RowGroupRenderer(groupedRows, exportSheetWithType),
      rowGetter: this.rowGetter,
      rowsCount: rows.length,
      rowSelection: {
        enableShiftSelect: true,
        onRowsSelected: this.rowsSelect,
        onRowsDeselected: this.rowsUnselect,
        selectBy: {
          keys: { rowKey, values: selectedRows.map(r => r.id) }
        },
        showCheckbox: true
      },
      emptyRowsView: EmptyRowsView
    };

    const dataGrid = draggable ? (
      <DraggableContainer>
        <ReactDataGrid
          {...defaultDataGridProps}
          rowActionsCell={RowActionsCell}
          rowRenderer={<RowRenderer onRowDrop={this.reorderRows} />}
        />
      </DraggableContainer>
    ) : (
      <ReactDataGrid
        {...defaultDataGridProps}
        rowActionsCell={NumberedSelectableRowActionsCell}
        rowRenderer={rowRenderer || DefaultRowRenderer}
      />
    );

    return (
      <div ref="wrapper" className={this.id}>
        {dataGrid}
        {Footer ? cloneElement(Footer, { selectedRows, rows }) : ""}
        {contextMenus ? (
          <ContextMenu
            onShow={this.onContextMenuShown}
            contextMenus={contextMenus}
            scrollParent={this.refs.wrapper}
            parentId={this.id}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

Table3.defaultProps = {
  contextMenus: {},
  enableCellSelect: true,
  enableRowSelect: "multi",
  rowKey: "id",
  selectedRows: []
};

Table3.propTypes = {
  columns: PropTypes.array.isRequired,
  contextMenus: PropTypes.object,
  draggable: PropTypes.bool,
  enableCellSelect: PropTypes.bool,
  enableRowSelect: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  EmptyRowsView: PropTypes.element,
  Footer: PropTypes.element,
  height: PropTypes.number,
  onCellCopyPaste: PropTypes.func,
  onCellsDragged: PropTypes.func,
  onColumnResize: PropTypes.func,
  onContextMenuShown: PropTypes.func,
  onRowSelect: PropTypes.func.isRequired,
  onRowSort: PropTypes.func, // callback after sorting, returns { rowId: idx, ... }
  rowKey: PropTypes.string,
  rowOrder: PropTypes.object,
  rowRenderer: PropTypes.object,
  rows: PropTypes.array.isRequired,
  saveCell: PropTypes.func.isRequired,
  selectedRows: PropTypes.array,
  updateRowOrder: PropTypes.func
};

export default Table3;
