import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { find } from "lodash";
import { Div } from "components/Base";

class OptionsList extends PureComponent {
  render() {
    const { options, records, onClick, order } = this.props;
    const sortedRecords = order
      ? [...records].sort((a, b) => order[a.id] - order[b.id])
      : records;
    const style = onClick ? { cursor: "pointer" } : {};

    return (
      <Div
        onClick={onClick}
        display="row.flex-start.center"
        minHeight={23}
        style={{
          ...style,
          userSelect: "none",
          overflow: "hidden"
        }}
      >
        {sortedRecords.map(record => {
          const option = find(options, { id: record.id });

          if (!option) {
            return null;
          }

          return (
            <Div
              key={option.id}
              display="row.flex-start.center"
              mr={2}
              color="gray9"
              br={2}
              style={{
                flexBasis: "auto",
                flexShrink: 0,
                whiteSpace: "nowrap",
                border: "1px solid rgba(173, 173, 173, 0.18)",
                backgroundColor: option.backgroundColor,
                color: option.textColor,
                borderColor: option.textColor || "rgba(173, 173, 173, 0.18)",
                borderRadius: 3
              }}
            >
              <Div
                fw={3}
                style={{
                  fontSize: 13,
                  padding: "3px 6px 3px 8px"
                }}
              >
                {option.value}
              </Div>
              <Div
                style={{
                  alignSelf: "stretch",
                  background: "rgba(0,0,0,.03)",
                  borderRadius: "0 5px 5px 0",
                  fontSize: 13,
                  padding: "3px 9px",
                  fontWeight: 500
                }}
              >
                {record.quantity}
              </Div>
            </Div>
          );
        })}
      </Div>
    );
  }
}

OptionsList.propTypes = {
  order: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      backgroundColor: PropTypes.string,
      textColor: PropTypes.string
    })
  ).isRequired,
  records: PropTypes.array.isRequired,
  onClick: PropTypes.func
};

export default OptionsList;
