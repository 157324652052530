import React from "react";
import { connect } from "react-redux";
import { Div } from "components/Base";
import Loading from "ui-kit/Loading";
import { getters as TabsGetters } from "Portal/Tabs";
import ThisEvent from "./ThisEvent";
import Summary from "./Summary";
import PreviousEvent from "./PreviousEvent";
import { FILTER } from "Portal/PortalPeople/constants";
import { getters } from "Portal/PortalPeople/model";
import AddUsersModal from "./AddUsersModal";

import { getTabs } from "Portal/PortalPeople/selectors";

import Tabs from "Portal/Tabs/View";

const decorate = connect((state, props) => ({
  selectedTab: TabsGetters.selectedTab(state, props),
  tabs: getTabs(state),
  loading: getters.loading(state)
}));

const Body = ({ tabs, selectedTab, loading }) => (
  <Div
    bg="neutral1"
    style={{
      borderRadius: 10,
      padding: "10px 20px"
    }}
  >
    <Tabs tabs={tabs} mb={4} />
    <Div>
      {loading ? (
        <Loading />
      ) : selectedTab === FILTER.THIS_EVENT ? (
        <ThisEvent />
      ) : selectedTab === FILTER.SUMMARY ? (
        <Summary />
      ) : selectedTab === FILTER.PREVIOUS_EVENTS ? (
        <PreviousEvent />
      ) : null}
    </Div>
    <AddUsersModal />
  </Div>
);

export default decorate(Body);
