import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const CardAction = ({ children, onClick, submissionId }) => (
  <div
    className={css.cardAction}
    onMouseDown={e => {
      e.stopPropagation();
      onClick(e, submissionId);
    }}
  >
    <span className={css.cardActionLink}>{children}</span>
  </div>
);

CardAction.propTypes = {
  onClick: PropTypes.func.isRequired,
  submissionId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default CSSModules(CardAction, css);
