import PropTypes from "prop-types";
import React from "react";
import { Div } from "../Base/index";
import * as R from "ramda";
import { withStateHandlers } from "../utils";
import MaterialModal from "@lennd/material-ui/Modal";

const modalHandler = (id = "") => {
  const showXModal = `show${id}Modal`;
  const closeXModal = `close${id}Modal`;
  const isXModalShowing = `is${id}ModalShowing`;
  return withStateHandlers(
    () => ({
      [isXModalShowing]: false
    }),
    {
      [showXModal]: () => () => ({
        [isXModalShowing]: true
      }),
      [closeXModal]: () => () => ({
        [isXModalShowing]: false
      })
    }
  );
};

const modalBackgrounds = {
  opaque:
    "linear-gradient(45deg, rgba(147,82,214,1), rgba(99,56,215,1), rgba(88,50,215,1))",
  translucent: "rgba(0,0,0,0.25)",
  clear: "rgba(0,0,0,0)"
};

const Modal = ({
  isPortal,
  background = "opaque",
  isModalShowing,
  children,
  zIndex = 100
}) => {
  if (isPortal) {
    return <MaterialModal open={isModalShowing}>{children}</MaterialModal>;
  } else if (isModalShowing) {
    return (
      <Div
        display="row.center.center"
        style={{
          pointerEvents: "auto",
          position: "fixed",
          zIndex, // @TODO: z index standardization
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          background: R.propOr("rgba(0,0,0,0)", background, modalBackgrounds)
        }}
        children={children}
      />
    );
  }
  return null;
};

Modal.propTypes = {
  background: PropTypes.oneOf(["opaque", "translucent", "clear"]),
  isModalShowing: PropTypes.bool.isRequired,
  isPortal: PropTypes.bool
};

Modal.defaultProps = {
  background: "opaque"
};

export { Modal, modalHandler };
