import React from "react";
import { Div, DragIcon, Text2, Text0 } from "components/Base";
import { capitalizeFirst } from "utils/General";

const ItemQuestions = ({ name, type, required }) => (
  <Div shadow={1} bg="white" bra={1} p={2} ba={1} bc="neutral2" mt={2}>
    <Div width={1} display="row.flex-start.center">
      <DragIcon color="neutral3" size={24} />
      <Div>
        <Div display="row">
          <Text2 bold color="black">
            {name}
          </Text2>
          {required && (
            <Text2 bold color="danger8" ml={1}>
              *
            </Text2>
          )}
        </Div>
        <Div display="row.flex-start.center">
          <Text0 color="neutral6" bold>
            {capitalizeFirst(type)}
          </Text0>
        </Div>
      </Div>
    </Div>
  </Div>
);

export default ItemQuestions;
