import { createSelector } from "reselect";
import * as R from "ramda";
import { getters } from "./model";
import { PAGES, FIELDS, EVENTS_LIST } from "./constants";
import { getters as FormGetters } from "ui-kit/Form/model";
import ALL_TIMEZONES from "utils/timezones";
import moment from "moment";

export const getDescribeOrganizationFieldOptions = R.always([
  {
    id: "Small business (1-50 Employees)",
    label: "Small business (1-50 Employees)"
  },
  {
    id: "Mid-Size business (51-250 Employees)",
    label: "Mid-Size business (51-250 Employees)"
  },
  {
    id: "Large Business (251+ employees)",
    label: "Large Business (251+ employees)"
  },
  {
    id: "Event/Management Agency",
    label: "Event/Management Agency"
  },
  {
    id: "University/Educational Institute",
    label: "University/Educational Institute"
  },
  {
    id: "Nonprofit",
    label: "Nonprofit"
  },
  {
    id: "Media Organization",
    label: "Media Organization"
  },
  {
    id: "Sports Organization",
    label: "Sports Organization"
  }
]);

export const getOftenHostEventsFieldOptions = R.always([
  {
    id: "Just Once",
    label: "Just Once"
  },
  {
    id: "Once a Week",
    label: "Once a Week"
  },
  {
    id: "Once a Month",
    label: "Once a Month"
  },
  {
    id: "A Few Times a Year",
    label: "A Few Times a Year"
  }
]);

export const getNextBtnText = createSelector(
  getters.page,
  page => {
    if (page === PAGES.EVENT) {
      return "Get Started";
    }
    return "Continue";
  }
);

export const getIsNextBtnDisabled = createSelector(
  getters.page,
  FormGetters.fields,
  (page, fields) => {
    if (page === PAGES.ABOUT) {
      return (
        R.compose(
          R.length,
          R.pathOr("", [FIELDS.DESCRIBE_ORGANIZATION, "value", 0])
        )(fields) < 2 ||
        R.compose(
          R.length,
          R.pathOr("", [FIELDS.OFTEN_HOST, "value", 0])
        )(fields) < 2
      );
    } else if (page === PAGES.ORGANIZATION) {
      return (
        R.length(R.pathOr("", [FIELDS.ORGANIZATION_NAME, "value"], fields)) < 2
      );
    }

    return (
      R.compose(
        R.length,
        R.pathOr("", [FIELDS.EVENT_NAME, "value"])
      )(fields) < 2 ||
      R.compose(
        R.isEmpty,
        R.pathOr("", [FIELDS.EVENT_START_AT, "value"])
      )(fields) ||
      R.compose(
        R.isEmpty,
        R.pathOr("", [FIELDS.EVENT_END_AT, "value"])
      )(fields) ||
      R.compose(
        R.isEmpty,
        R.pathOr("", [FIELDS.EVENT_TIMEZONE, "value", 0])
      )(fields)
    );
  }
);

export const getTypeOfEvents = createSelector(
  R.always(EVENTS_LIST),
  getters.typeOfEvents,
  (eventsList, typeOfEvents) => {
    return R.map(
      event => ({
        id: event,
        selected: R.any(typeEvent => event === typeEvent, typeOfEvents)
      }),
      eventsList
    );
  }
);

export const getTimezones = () =>
  R.map(t => ({
    id: t.value,
    label: t.label
  }))(ALL_TIMEZONES);

export const getIniTimezone = () => {
  const userTimezone = moment.tz.guess();

  return R.compose(
    R.propOr("", "value"),
    R.find(({ value }) => value === userTimezone)
  )(ALL_TIMEZONES);
};
