import * as R from "ramda";
import { all, takeEvery, fork, put, call, select } from "redux-saga/effects";
import { actions, getters } from "./model";
import { registerError } from "redux/modules/errors/actions";
import { apiCall } from "App/Data/sagas";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { noop } from "utils/General";
import { hideModal } from "redux/modules/modal/actions";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import { userId as getUserId } from "redux/modules/user/selectors";
import { actions as addAssignmentModalActions } from "EventLight/HealthPass/AddAssignmentModal/model";
import { actions as formActions } from "ui-kit/Form/model";
import { getters as formGetters } from "ui-kit/Form/model";
import { DROPDOWN_FIELD_ID } from "EventLight/HealthPass/AddAssignmentModal/constants";

const CONTACT_ID = STANDARD_MODULES.contacts.id;

const init = function*() {
  const eventId = yield select(getEventId);
  const userId = yield select(getUserId);

  try {
    const [
      { payload: initialData },
      { record_types: contactRecordTypes }
    ] = yield all([
      call(apiCall, {
        method: "get",
        url: `/health-pass/event/${eventId}/assignments`
      }),
      call(apiCall, {
        method: "get",
        url: `/modules/${CONTACT_ID}/record-types`,
        qs: { eventId, userId }
      })
    ]);

    yield all([
      put(actions.setInitialData(initialData)),
      put(actions.setRecords({ contactRecordTypes }))
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting assignments"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const save = function*() {
  const assignedToPeopleTypes = yield select(getters.assignedToPeopleTypes);
  const assignedToPeople = yield select(getters.assignedToPeople);

  const data = {
    assignedToPeopleTypes,
    assignedToPeople
  };

  try {
    yield put(actions.setSaving(true));
    const eventId = yield select(getEventId);
    yield call(apiCall, {
      method: "put",
      url: `/health-pass/event/${eventId}/assignments`,
      data
    });

    yield put(
      showSnackbar({
        message: "Assignments updated",
        action: "OK"
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: `An error occurred while updating assignments`
        }
      ])
    );
  } finally {
    yield put(actions.setSaving(false));
  }
};

const hideAddAssignmentModal = function*() {
  yield all([
    put(formActions.clearValues()),
    put(actions.setShowAddAssignmentModal(false))
  ]);
};

const addAssignedToGroupsOrPeople = function*() {
  const fields = yield select(formGetters.fields);

  const typeField = R.path([DROPDOWN_FIELD_ID, "value", 0], fields);

  const objToSave = {
    id: typeField.value,
    name: typeField.label
  };

  yield put(actions.updateAssignedToPeople(objToSave));

  yield call(hideAddAssignmentModal);
};

const hideCreateModal = function*() {
  yield all([put(hideModal()), put(actions.resetData())]);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchSave = function*() {
  yield takeEvery(actions.save.type, save);
};

const watchHideCreateModal = function*() {
  yield takeEvery(actions.hideCreateModal.type, hideCreateModal);
};

const watchHideAddAssignmentModal = function*() {
  yield takeEvery(
    addAssignmentModalActions.hideAddAssignmentModal.type,
    hideAddAssignmentModal
  );
};

const watchHideAddAssignedToGroupTypes = function*() {
  yield takeEvery(
    addAssignmentModalActions.save.type,
    addAssignedToGroupsOrPeople
  );
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchSave),
    fork(watchHideCreateModal),
    fork(watchHideAddAssignmentModal),
    fork(watchHideAddAssignedToGroupTypes)
  ]);
};

export default rootSaga;
