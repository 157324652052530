import * as R from "ramda";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { Div, AddIcon } from "components/Base";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { noop } from "utils/General";

import * as DataSelectors from "App/Data/selectors";

import { actions } from "ui-kit/Form/model";
import { getValue as getFormValue } from "ui-kit/Form/selectors";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";
import LinkedRecordsModal from "./LinkedRecordsModal";
import ViewRecordModal from "components/Global/Module/Modals/ViewRecord";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

import { showModal } from "redux/modules/modal/actions";

import getValue from "utils/value-types/get-value";
import toString from "utils/value-types/to-string";
import * as SYSTEM_FIELD_IDS from "utils/system-field-ids";
import { CONTACTS } from "utils/standard-module-field-ids";

const { ID } = SYSTEM_FIELD_IDS;
const { EMAIL } = CONTACTS;

const decorate = R.compose(
  WithFormInstanceConsumer({ fieldType: FIELD_TYPES.LOOKUP }),
  connect(
    (state, props) => ({
      value: getFormValue(state, props),
      recordReferences: DataSelectors.getAllRecordReferencesById(state),
      fieldReferences: DataSelectors.getAllFieldReferencesById(state)
    }),
    bindInstance({
      onChange: actions.setFieldValue,
      setIniValue: actions.setIniValue,
      showModal
    })
  ),
  CSSModules(css)
);

const getRecordLabel = (recordId, linkedField, linkedRecord) => {
  if (!linkedField || linkedField.id === ID) {
    const value =
      R.prop(ID, linkedRecord) || R.path(["values", ID], linkedRecord);
    return toString(getValue(value, "text"), "text");
  }

  const value =
    R.prop(linkedField.id, linkedRecord) ||
    R.path(["values", linkedField.id], linkedRecord);
  const name = toString(getValue(value, linkedField.type), linkedField.type);

  // if attempting to get full name and no value, return email
  if ((!name || !name.length) && linkedField.id === "full_name") {
    return toString(getValue(linkedRecord.values[EMAIL], "email"), "email");
  }

  return name;
};

const getRecords = ({
  linkedFieldId,
  fieldReferences,
  recordReferences,
  recordIds
}) => {
  const field = R.prop(linkedFieldId, fieldReferences);
  return R.filter(
    R.compose(
      R.not,
      R.isNil
    ),
    R.map(recordId => {
      const record = R.prop(recordId, recordReferences);
      if (!record) {
        return null;
      }
      return {
        id: recordId,
        label: getRecordLabel(recordId, field, record)
      };
    }, recordIds)
  );
};

const LinkedRecordsEditor = ({
  value = [],
  iniValue = [],
  moduleId,
  recordId,
  linkedModuleId,
  linkedFieldId,
  disableClickingRecord = false,
  fieldType,
  fieldId,
  isReadOnly = false,
  setIniValue = noop,
  onChange = noop,
  showModal = noop,
  recordReferences = {},
  fieldReferences = {},
  multipleSelect = false
}) => {
  useEffect(() => {
    setIniValue(iniValue, {
      meta: { fieldType, fieldId }
    });
  }, []);

  const records = useMemo(
    () =>
      getRecords({
        linkedFieldId,
        recordReferences,
        fieldReferences,
        recordIds: value || []
      }),
    [linkedFieldId, recordReferences, fieldReferences, value]
  );

  const [showLinkedRecords, setShowLinkedRecords] = useState(false);

  const showRecordModal = recordId => {
    showModal({
      content: (
        <ViewRecordModal moduleId={linkedModuleId} recordId={recordId} />
      ),
      wrapper: ModalWrapper
    });
  };

  return (
    <div styleName={isReadOnly ? "containerReadOnly" : "container"}>
      {!isReadOnly ? (
        <Div
          bg={{ default: "neutral2", hover: "neutral1" }}
          onClick={() => setShowLinkedRecords(true)}
          display="flex.center.center"
          bra={1}
          ml={1}
          mr={2}
          style={{
            width: 20,
            height: 22,
            flexShrink: 0
          }}
        >
          <AddIcon size={16} color="gray9" />
        </Div>
      ) : null}
      <div styleName="formatter">
        <Div
          display="row"
          style={{
            overflow: "hidden"
          }}
        >
          {R.map(
            record => (
              <Div
                key={record.id}
                bra={1}
                fw={3}
                bg={
                  disableClickingRecord
                    ? "neutral1"
                    : { default: "neutral1", hover: "neutral0" }
                }
                onClick={
                  disableClickingRecord
                    ? noop
                    : () => showRecordModal(record.id)
                }
                color="gray9"
                px={2}
                py={1}
                mr={1}
                truncate
                style={{
                  flexShrink: 0
                }}
              >
                {record.label}
              </Div>
            ),
            records
          )}
        </Div>
      </div>
      <LinkedRecordsModal
        showModal={showLinkedRecords}
        hideModal={() => setShowLinkedRecords(false)}
        moduleId={moduleId}
        fieldId={fieldId}
        recordId={recordId}
        linkedModuleId={linkedModuleId}
        linkedFieldId={linkedFieldId}
        linkedRecordIds={value || []}
        useValuesKeyForReferences
        selectionMode={multipleSelect ? "multiple" : "single"}
        onSave={value =>
          onChange(R.pathOr([], ["value", "records"], value), {
            meta: { fieldId, fieldType }
          })
        }
      />
    </div>
  );
};

export const Lookup = decorate(LinkedRecordsEditor);
