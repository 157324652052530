import PropTypes from "prop-types";
import React, { Component } from "react";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class RelatedFields extends Component {
  render() {
    const {
      availableFields,
      relationships,
      updateFieldRelationship
    } = this.props;

    return (
      <div>
        {availableFields.map(field => {
          const checked = relationships.includes(field.id);
          return (
            <label key={field.id} styleName="row">
              <div>
                <input
                  id={field.id}
                  value={field.id}
                  type="checkbox"
                  styleName="checkbox"
                  checked={checked}
                  onChange={() => {
                    updateFieldRelationship(field.id, !checked);
                  }}
                />
              </div>
              <div styleName="label">
                {typeof field.name !== "undefined"
                  ? field.name
                  : field.schema.label}
              </div>
            </label>
          );
        })}
        {availableFields.length === 0 && (
          <div>There are no available fields.</div>
        )}
      </div>
    );
  }
}

RelatedFields.propTypes = {
  availableFields: PropTypes.array.isRequired,
  relationships: PropTypes.array.isRequired,
  updateFieldRelationship: PropTypes.func.isRequired
};

export default RelatedFields;
