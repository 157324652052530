import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../model";
import { getFilteredRows } from "../selectors";

import { Div, Text4, RefreshIcon, MediumFilledButton } from "components/Base";
import ActivityLog from "./ActivityLog";

import { withProps } from "utils/General";

const DayLogContainer = withProps({
  width: 1,
  py: 2,
  bg: "white",
  bra: 2,
  display: "column.flex-start.center",
  style: {
    boxShadow:
      "rgba(0, 0, 0, 0.04) 0px 8px 20px, rgba(0, 0, 0, 0.01) 0px 35px 54px"
  }
})(Div);

const decorate = connect(
  (state, props) => ({
    days: getFilteredRows(state, props),
    canLoadMore: getters.canLoadMore(state, props),
    loadingMessage: getters.loadingMessage(state, props)
  }),
  {
    loadMore: actions.loadMoreData
  }
);

const List = ({ days, canLoadMore, loadingMessage, loadMore }) => (
  <Div width={1} height={1} mt={2}>
    {R.addIndex(R.map)(
      (day, idx) => (
        <Div
          key={`${day.name}-${idx}`}
          mt={2}
          display="column.flex-start.stretch"
        >
          <Text4 bold ml={4} py={4}>
            {R.prop("name", day)}
          </Text4>
          <DayLogContainer>
            {R.map(
              activity => (
                <ActivityLog key={activity.id} {...activity} />
              ),
              R.prop("activities", day)
            )}
          </DayLogContainer>
        </Div>
      ),
      days
    )}
    <MediumFilledButton
      mt={8}
      disabled={R.not(canLoadMore)}
      onClick={() => loadMore()}
      LeftIcon={RefreshIcon}
      color="neutral9"
      bg="neutral2"
    >
      {loadingMessage ? "...Loading" : "Load more activity"}
    </MediumFilledButton>
  </Div>
);

export default decorate(List);
