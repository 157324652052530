import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";
import { MEAL_TYPE_ID } from "utils/item-types";
import * as flags from "utils/feature-flags";

import { getEvent } from "redux/modules/event/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { getItemGroupsByEventAndType } from "redux/modules/items/item-groups/actions";
import {
  updateItem,
  bulkUpdateItems,
  deleteItem
} from "redux/modules/items/items/actions";
import {
  updateCateringSettings,
  addMeal
} from "redux/modules/catering/settings/actions";
import {
  getItemBlocksByEventAndType,
  addItemBlock
} from "redux/modules/items/item-blocks/actions";

import { eventDetails } from "redux/modules/event/selectors";
import { groups } from "redux/modules/items/item-groups/selectors";
import { itemBlocks } from "redux/modules/items/item-blocks/selectors";
import { selectFeatureFlag } from "@flopflip/react-redux";

function mapStateToProps(state) {
  const itemGroupsToPass = groups(state).filter(
    g => g.type_id === MEAL_TYPE_ID
  );
  const itemBlocksToPass = itemBlocks(state).filter(
    g => g.item_type_id === MEAL_TYPE_ID
  );
  return {
    eventDetails: eventDetails(state),
    items: itemGroupsToPass.reduce((a, b) => [...a, ...b.items], []),
    itemGroups: itemGroupsToPass,
    canViewCateringManagement: selectFeatureFlag(
      flags.CAN_VIEW_CATERING_MANAGEMENT.NAME
    )(state),
    itemBlocks: itemBlocksToPass
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getItemBlocksByEventAndType,
      addItemBlock,
      getEvent,
      getItemGroupsByEventAndType,
      addMeal,
      updateItem,
      bulkUpdateItems,
      deleteItem,
      showSnackbar,
      updateCateringSettings
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Controller);
