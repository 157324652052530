import React from "react";
import EditorBase from "./TableConnector";
import Cell from "./CellBase";
import Editor from "components/Global/Editors/CatalogItemEditor";
import Formatter from "ui-kit/Datagrid/View/Formatters/CatalogItem";

import { getSettings, ep } from "ui-kit/Datagrid/utils";

export default class CatalogItemEditor extends EditorBase {
  render() {
    const props = ep(this.props);
    const { row } = props;
    const selectType = getSettings(props, "selectType");

    const editorProps = {
      customWidth: getSettings(props, "customWidth"),
      selectType,
      options: getSettings(props, "availableCatalogItems"),
      quantityOptions: getSettings(props, "availableQuantities"),
      shouldLimitToAvailableQuantities: getSettings(
        props,
        "shouldLimitToAvailableQuantities"
      )
    };
    const editor = (
      <Editor
        {...editorProps}
        value={this.state}
        onChange={this.handleChange}
      />
    );

    if (
      ["quantity-only-input", "quantity-only-dropdown"].includes(selectType)
    ) {
      return editor;
    }

    return (
      <Cell
        ref="editor"
        formatter={<Formatter value={this.state} row={row} />}
        editor={editor}
        onClose={this.props.onCommit}
      />
    );
  }
}

CatalogItemEditor.propTypes = EditorBase.propTypes;
