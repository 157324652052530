import React from "react";
import { Div, Dropdown, LeftArrowIcon, Text2 } from "components/Base";

const TypesDropdown = ({ text, selectedTypeId, typesList, selectTypeId }) => {
  const list = [...typesList, { value: null, label: "Unassigned" }];
  return (
    <Div display="row.flex-start.center">
      <Dropdown
        usePortal
        options={list}
        selected={selectedTypeId}
        onChange={({ value }) => selectTypeId(value)}
        placeholder="Unassigned"
        width="160px"
      />
      <LeftArrowIcon color="black" mx={2} />
      <Text2 bold>{text}</Text2>
    </Div>
  );
};

export default TypesDropdown;
