import React from "react";

import { Div, FontIcon } from "components/Base";
import Circle from "ui-kit/Circle";

import CollapseButton from "Organizations/Events/View/Sidebar/CollapseButton";

const Header = () => (
  <Div display="row.flex-start.center" width={1} mb={5}>
    <Div display="row.space-between.flex-start" width={1}>
      <Div display="row.flex-start.center">
        <Circle
          size={32}
          style={{
            backgroundColor: "#7a38c3",
            flexShrink: 0
          }}
        >
          <FontIcon
            fs={3}
            style={{
              color: "#fff"
            }}
          >
            list
          </FontIcon>
        </Circle>
        <Div color="white" fw={3} fs={5} ml={2} mr={2}>
          Events
        </Div>
      </Div>
      <CollapseButton />
    </Div>
  </Div>
);

export default Header;
