import { createHandlers } from "redux-mvc";
import * as R from "ramda";
import { MODES, DEFAULT_PAGE_SIZE } from "./constants";

// const zeroIndexPage = R.compose(R.dec, R.pathOr(1, ["pagination", "page"]));
const DEFAULT_PAGINATION = {
  pageSize: DEFAULT_PAGE_SIZE,
  pages: 0,
  page: 0,
  total: 0
};

// Initial State
const iniState = {
  loading: true,
  mode: MODES.EMAIL,
  moduleId: null,
  records: [],
  activeStepIndex: 0,
  generatingPreviews: false,
  sendingEmails: false,
  selectedOptions: null,
  subject: "",
  message: "",
  messagePlainText: "",
  replyTo: "",
  emailType: "custom",
  attachments: [],
  newTemplate: false,
  templateTitle: "",
  invoiceDueDate: null,
  invoiceNotes: "",
  groupByRecipient: false,
  messagePreviews: [],
  bodyRef: null,
  sourceRecordId: null,
  sourceFormId: null,
  sourceOrderId: null,
  preview: null,
  pagination: DEFAULT_PAGINATION,
  skippedToSend: false
};

// Handlers
const handlers = createHandlers({
  iniState,
  reducers: {
    init: (_, { payload }) => ({
      loading: false,
      skippedToSend: false,
      mode: payload.mode,
      records: payload.records,
      moduleId: payload.moduleId,
      selectedOptions: payload.selectedOptions,
      subject: payload.subject,
      message: payload.message,
      replyTo: payload.replyTo,
      emailType: payload.emailType,
      attachments: payload.attachments,
      invoiceDueDate: payload.invoiceDueDate,
      sourceRecordId: payload.sourceRecordId,
      sourceFormId: payload.sourceFormId,
      sourceOrderId: payload.sourceOrderId
    }),
    handleNextStep: R.identity,
    refreshPreviews: R.identity,
    getPreview: R.identity,
    onOptionSelect: (_, { payload: selected }) => ({
      selectedOptions: selected ? selected.value : selected
    }),
    handleBackStep: state => ({
      activeStepIndex: Math.max(state.activeStepIndex - 1, 0)
    }),
    updateMessage: (_, { payload }) => ({
      message: payload.message,
      messagePlainText: payload.messagePlainText
    }),
    toggleTemplate: state => ({
      newTemplate: !state.newTemplate
    }),
    toggleGroupByRecipient: state => ({
      groupByRecipient: !state.groupByRecipient
    }),
    handleResponse: (_, { payload: reponseProps }) => ({
      ...reponseProps
    }),
    setPageSize: (state, { payload }) => ({
      pagination: {
        ...state.pagination,
        pageSize: payload
      }
    }),
    setCurrentPage: ({ pagination }, { payload }) => ({
      pagination: {
        ...pagination,
        page: payload
      }
    })
  },
  namespace: "SendEmailModal"
});

export default handlers;
