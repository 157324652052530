import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE, FILTER } from "./constants";

const iniState = {
  events: {},
  notifications: {},
  loading: true,
  loadingNext: false,
  countOfVisibleNotifications: 0,
  lastDate: null,
  hasMore: false,
  refreshing: false,
  showFilters: false,
  filterBy: FILTER.ALL,
  eventId: null,
  selectedTypeFilters: []
};

const reducers = {
  setIsDrawerOpen: R.identity,
  setInitialData: (
    _,
    {
      payload: {
        eventId,
        events,
        notifications,
        lastDate,
        countOfVisibleNotifications
      }
    }
  ) => ({
    eventId,
    events,
    notifications,
    lastDate,
    countOfVisibleNotifications
  }),
  setNextData: (
    state,
    { payload: { notifications, lastDate, countOfVisibleNotifications } }
  ) => ({
    countOfVisibleNotifications:
      state.countOfVisibleNotifications + countOfVisibleNotifications,
    notifications: {
      today: [...state.notifications.today, ...notifications.today],
      past_7_days: [
        ...state.notifications.past_7_days,
        ...notifications.past_7_days
      ],
      this_month: [
        ...state.notifications.this_month,
        ...notifications.this_month
      ],
      older: [...state.notifications.older, ...notifications.older]
    },
    lastDate
  }),
  save: (
    { rows, editing: { columnId, rowId } },
    { payload: { value, column } }
  ) => {
    return {
      editing: { columnId: "", rowId: "" },
      rows: R.assocPath(
        [rowId, columnId],
        {
          type: column.type,
          ...R.pathOr({}, [rowId, columnId], rows),
          value
        },
        rows
      )
    };
  },
  refreshRecords: R.identity,
  executeAction: R.identity,
  selectFilter: R.identity,
  toggleFilters: ({ showFilters }) => ({
    showFilters: R.not(showFilters)
  }),
  selectFilterType: ({ selectedTypeFilters }, { payload }) => ({
    selectedTypeFilters: R.symmetricDifference(selectedTypeFilters, [payload])
  }),
  updateNotification: (state, { payload: { id, sectionId } }) => {
    const notificationIndex = R.findIndex(
      ({ notification_ids }) => notification_ids[0] === id,
      R.pathOr([], ["notifications", sectionId], state)
    );

    const notificationLens = R.lensPath([
      "notifications",
      sectionId,
      notificationIndex,
      "is_read"
    ]);

    return R.set(notificationLens, "true", state);
  },
  updateNotificationAllNotifications: ({ notifications }) => ({
    notifications: R.map(
      R.map(notification => R.assoc("is_read", true, notification))
    )(notifications)
  }),
  confirmNotificationUpdate: R.identity,
  markAsRead: R.identity,
  markAsArchive: R.identity,
  loadNext: R.identity
};

const model = createHandlers({ iniState, reducers, namespace: NAMESPACE });

const { actions, getters } = model;

export { actions, getters };

export default model;
