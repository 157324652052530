import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import External from "./External";

import {
  group,
  groupEvent,
  groupSettings,
  isFetching
} from "redux/modules/portal/groupManager/selectors";
import { getDetails } from "redux/modules/portal/groupManager/actions";
import {
  getGroupPeople,
  updatePerson,
  addPerson,
  removePerson
} from "redux/modules/portal/groupManager/people/actions";
import { people } from "redux/modules/portal/groupManager/people/selectors";
import { resources } from "redux/modules/portal/groupManager/resources/selectors";
import { fetchGroupResources } from "redux/modules/portal/groupManager/resources/actions";
import { user } from "redux/modules/user/selectors";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { getLoggedInUserDetails } from "redux/modules/user/actions";

function mapStateToProps(state) {
  return {
    event: groupEvent(state),
    group: group(state),
    settings: groupSettings(state),
    loading: isFetching(state),
    people: people(state),
    user: user(state),
    resources: resources(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addPerson,
      getLoggedInUserDetails,
      fetchGroupResources,
      getGroupPeople,
      getDetails,
      removePerson,
      updatePerson,
      showSnackbar
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(External);
