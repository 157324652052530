import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";

import AlertPopover from "components/Global/Alerts/Popover";
import Container from "components/Global/Alerts/Container";
import Header from "components/Global/Alerts/Header";
import Paragraph from "components/Global/Alerts/ParagraphContainer";
import Separator from "components/Global/Alerts/Separator";
import ButtonContainer from "components/Global/Alerts/ButtonContainer";
import PrimaryAction from "components/Global/Alerts/PrimaryAction";
import SecondaryAction from "components/Global/Alerts/SecondaryAction";

class DeleteConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopover: false
    };
  }

  @autobind
  handleClick(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ showPopover: true });
  }

  @autobind
  onRequestClose(e) {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ showPopover: false });
  }

  @autobind
  onConfirm(e) {
    this.onRequestClose(e);
    this.props.onConfirm();
  }

  render() {
    const containerWidth = this.props.width || "auto";
    const buttonStyle = this.props.buttonStyle || {};
    return (
      <div {...this.props} ref={ref => (this.anchor = ref)}>
        {React.cloneElement(this.props.children, { onClick: this.handleClick })}
        <AlertPopover
          anchorEl={this.anchor}
          onClose={this.onRequestClose}
          open={this.state.showPopover}
        >
          <Container width={containerWidth}>
            <Header>{this.props.text}</Header>
            {this.props.paragraph && (
              <Paragraph>{this.props.paragraph}</Paragraph>
            )}
            <Separator />
            <ButtonContainer>
              <PrimaryAction
                onClick={this.onConfirm}
                data-cy="confirmDelete"
                style={{ ...buttonStyle }}
              >
                {this.props.confirmLabel || "Remove"}
              </PrimaryAction>
              <SecondaryAction onClick={this.onRequestClose}>
                Cancel
              </SecondaryAction>
            </ButtonContainer>
          </Container>
        </AlertPopover>
      </div>
    );
  }
}

DeleteConfirmation.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string,
  paragraph: PropTypes.string
};

export default DeleteConfirmation;
