import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import { Div } from "components/Base";
import Body from "./Body";
import Loading from "ui-kit/Loading";
import AddContentModal from "./AddContentModal";

const decorate = connect(
  state => ({
    loading: getters.loading(state)
  }),
  {
    init: actions.init
  }
);

const Layout = ({ loading, init }) => {
  useEffect(() => {
    init();
  }, []);

  return (
    <Div width={1} display="row.center.flex-start" mt={3} mb={5}>
      <Div
        width={1}
        display="column"
        style={{
          maxWidth: "825px"
        }}
      >
        {loading ? <Loading /> : <Body />}
      </Div>
      <AddContentModal />
    </Div>
  );
};

export default decorate(Layout);
