import React, { Component } from "react";
import constructName from "components/Global/Editors/Reference/utils/construct-name";
import constructPlaceholder from "components/Global/Editors/Reference/utils/construct-placeholder";
import isPerson from "components/Global/Editors/Reference/utils/is-person";
import { Div } from "components/Base";
import { isEqual } from "lodash";

class ReferenceFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  formatValue(props) {
    if (props.value && props.value.value) {
      return props.value.value;
    }
    return "";
  }

  render() {
    const value = this.formatValue(this.props);
    const text = value
      ? isPerson(value)
        ? constructName(value.first, value.last)
        : constructPlaceholder(value.placeholder, value.count)
      : "";

    return (
      <Div
        title={text}
        display="row.flex-start.center"
        style={{
          lineHeight: "34px"
        }}
      >
        <Div truncate width={1}>
          {text}
        </Div>
      </Div>
    );
  }
}

export default ReferenceFormatter;
