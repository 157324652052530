import React from "react";

import { connect } from "react-redux";

import { actions, getters } from "Items/BulkQuestionItemsModal";

import { Div, BigClearInput, SearchIcon } from "components/Base";

import ItemsList from "./ItemsList";

import SelectedVariants from "./SelectedVariants";

const decorate = connect(
  state => ({
    variantsFilter: getters.variantsFilter(state)
  }),
  {
    setVariantsFilter: actions.setVariantsFilter
  }
);

const AssignItems = ({ variantsFilter, setVariantsFilter }) => (
  <Div width={1} height={1}>
    <Div
      height={60}
      width={1}
      bb={1}
      bc="neutral1"
      display="row.flex-start.center"
      px={2}
    >
      <BigClearInput
        LeftIcon={SearchIcon}
        onChange={value => setVariantsFilter(value)}
        width={1}
        continuous
        value={variantsFilter}
        placeholder="Filter items..."
      />
    </Div>
    <Div
      display="row.flex-start.center"
      width={1}
      style={{ height: "calc(100% - 60px)" }}
    >
      <Div
        width={467}
        height={1}
        bg="neutral0"
        p={2}
        style={{ overflowY: "auto" }}
      >
        <ItemsList />
      </Div>
      <Div width={276} height={1}>
        <SelectedVariants />
      </Div>
    </Div>
  </Div>
);

export default decorate(AssignItems);
