import * as R from "ramda";
import React from "react";
import { put, takeEvery, all, fork, select, call } from "redux-saga/effects";
import { actions, getters } from "./model";
import { makeFuture } from "utils/General/sagas";

import { registerError } from "redux/modules/errors/actions";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal } from "redux/modules/modal/actions";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import EditTemplateModal from "components/Event/Settings/Modals/EditTemplateModal";
import DeleteConfirmation from "components/Global/CRM/Modals/DeleteConfirmation";

import Api from "./api";

const EMPTY_TEMPLATE = {
  title: "",
  content_formatted: "",
  content_plaintext: "",
  attachments: []
};

const getParams = function*() {
  const eventId = yield select(getEventId);
  const credentials = yield select(getCredentials);

  return { eventId, credentials };
};

const loadSettings = function*() {
  const { credentials, eventId } = yield call(getParams);

  try {
    const [{ payload: list }, { payload: lifecycleList }] = yield all([
      call(Api.all, credentials, eventId),
      call(Api.allLifecycle, credentials, eventId)
    ]);

    yield all([
      put(actions.setList(list)),
      put(actions.setLifecycleList(lifecycleList))
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while loading settings"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const createEmailTemplate = function*({ payload: { template } }) {
  const { credentials, eventId } = yield call(getParams);
  try {
    yield call(Api.post, credentials, eventId, template);

    yield all([
      put(showSnackbar({ message: "Template created", action: "OK" })),
      call(loadSettings)
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred creating template"
        }
      ])
    );
  }
};

const updateEmailTemplate = function*({ payload: { template } }) {
  const { credentials, eventId } = yield call(getParams);
  try {
    yield call(Api.put, credentials, eventId, template);

    yield all([
      put(showSnackbar({ message: "Template updated", action: "OK" })),
      call(loadSettings)
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating template"
        }
      ])
    );
  }
};

const updateLifecycleEmailTemplate = function*({ payload: { template } }) {
  const { credentials, eventId } = yield call(getParams);
  try {
    yield call(Api.putLifecycle, credentials, eventId, template);

    yield all([
      put(showSnackbar({ message: "Template updated", action: "OK" })),
      call(loadSettings)
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating lifecycle template"
        }
      ])
    );
  }
};

const duplicateEmailTemplate = function*({ payload: { templateId } }) {
  const templates = yield select(getters.list);
  const dupe = templates.find(t => t.id === templateId);

  yield call(createEmailTemplate, {
    payload: {
      template: {
        type: dupe.type,
        title: `Copy of ${dupe.title}`,
        moduleId: dupe.module_id,
        subject: dupe.subject,
        contentFormatted: dupe.content_formatted,
        contentPlaintext: dupe.content_plaintext,
        attachments: dupe.attachments
      }
    }
  });
};

const handleConfirmDeleteModal = function*() {
  const handleDelete = makeFuture();
  yield put(
    showModal({
      content: (
        <DeleteConfirmation
          heading="Delete Template?"
          message={
            <div>
              {`
                Are you sure you want to delete this template?
                `}
              <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                This cannot be undone.
              </div>
            </div>
          }
          onConfirm={() => handleDelete.done(true)}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleDelete.onRealized);
};

const handleShowTemplateModal = function*({ template, editMode, isLifecycle }) {
  const handleModal = makeFuture();
  yield put(
    showModal({
      content: (
        <EditTemplateModal
          isLifecycle={isLifecycle}
          done={template => handleModal.done({ action: "save", template })}
          template={template}
          deleteTemplate={
            editMode
              ? template => handleModal.done({ action: "delete", template })
              : undefined
          }
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleModal.onRealized);
};

const showAddTemplateModal = function*({
  payload: { type } = { type: "custom" }
}) {
  const { action, template } = yield call(handleShowTemplateModal, {
    template: {
      ...EMPTY_TEMPLATE,
      type
    },
    editMode: false
  });

  if (action === "save") {
    yield call(createEmailTemplate, { payload: { template } });
  }
};

const deleteEmailTemplate = function*({ payload: { templateId } }) {
  const done = yield call(handleConfirmDeleteModal);

  if (done) {
    const { credentials, eventId } = yield call(getParams);
    try {
      yield call(Api.delete, credentials, eventId, templateId);

      yield all([
        put(showSnackbar({ message: "Template deleted", action: "OK" })),
        call(loadSettings)
      ]);
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error occurred deleting template"
          }
        ])
      );
    }
  }
};

const showEditTemplateModal = function*({
  payload: { template, isLifecycle }
}) {
  const { action, template: updatedTemplate } = yield call(
    handleShowTemplateModal,
    {
      template,
      editMode: true,
      isLifecycle
    }
  );

  if (action === "save") {
    if (isLifecycle) {
      yield call(updateLifecycleEmailTemplate, {
        payload: { template: updatedTemplate }
      });
    } else {
      yield call(updateEmailTemplate, {
        payload: { template: updatedTemplate }
      });
    }
  } else if (action === "delete") {
    yield call(deleteEmailTemplate, { payload: { templateId: template.id } });
  }
};

const watchDuplicateEmailTemplate = function*() {
  yield takeEvery(actions.duplicateEmailTemplate.type, duplicateEmailTemplate);
};

const watchDeleteEmailTemplate = function*() {
  yield takeEvery(actions.deleteEmailTemplate.type, deleteEmailTemplate);
};

const watchUpdateEmailTemplate = function*() {
  yield takeEvery(actions.updateEmailTemplate.type, updateEmailTemplate);
};

const watchUpdateLifecycleEmailTemplate = function*() {
  yield takeEvery(
    actions.updateLifecycleEmailTemplate.type,
    updateLifecycleEmailTemplate
  );
};

const watchCreateEmailTemplate = function*() {
  yield takeEvery(actions.createEmailTemplate.type, createEmailTemplate);
};

const watchGetEmailTemplates = function*() {
  yield takeEvery(actions.getEmailTemplates.type, loadSettings);
};

const watchOpenAddTemplateModal = function*() {
  yield takeEvery(actions.openAddTemplateModal.type, showAddTemplateModal);
};

const watchOpenEditTemplateModal = function*() {
  yield takeEvery(actions.openEditTemplateModal.type, showEditTemplateModal);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, loadSettings);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchGetEmailTemplates),
    fork(watchCreateEmailTemplate),
    fork(watchUpdateEmailTemplate),
    fork(watchUpdateLifecycleEmailTemplate),
    fork(watchDeleteEmailTemplate),
    fork(watchOpenAddTemplateModal),
    fork(watchOpenEditTemplateModal),
    fork(watchDuplicateEmailTemplate)
  ]);
};

export default rootSaga;
