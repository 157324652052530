import React from "react";
import { withProps } from "utils/General";

import {
  Div,
  makeTable,
  Text3,
  SmallOutlineButton,
  ExpandIcon,
  ThreeQuarterSizeSquareIcon,
  PopoverMenu,
  FontIcon
} from "components/Base";

import {
  HeaderStyler,
  RowStyler
} from "components/Event/Settings/Layout/Tables/stylers";

import CSSModules from "react-css-modules";
import css from "components/Event/Settings/Layout/Tables/styles.css";

const TableStyler = withProps({
  bra: 1
})(Div);

const addS = thing => (thing === 1 ? "" : "s");

const MealCell = CSSModules(
  ({ value, color, onMealClick }) => (
    <Div display="row.flex-start.center" onClick={onMealClick}>
      <ThreeQuarterSizeSquareIcon
        color={color}
        size={12}
        mr={2}
        style={{ minWidth: 12 }}
      />
      <Text3 bold mr={3} primary>
        {value}
      </Text3>
      <SmallOutlineButton
        m={2}
        pill
        styleName="childContainer"
        OnlyIcon={ExpandIcon}
        color="neutral7"
      />
    </Div>
  ),
  css
);

const MealsTable = makeTable({
  TableStyler,
  HeaderStyler,
  RowStyler,
  HeaderCellStyler: Div,
  RowCellStyler: Div,
  columnProps: [{ width: 2 / 3 }, { width: 1 / 3 }],
  headerCellComps: [
    () => <Text3 bold>Name</Text3>,
    () => <Text3 bold>Actions</Text3>
  ],
  rowCellComps: [
    ({ value, color, onMealClick }) => (
      <MealCell value={value} color={color} onMealClick={onMealClick} />
    ),
    ({ onEdit, onClone, onDelete, moveMealUp, moveMealDown }) => (
      <PopoverMenu
        Label={({ onClick }) => (
          <SmallOutlineButton onClick={onClick} py={1}>
            <FontIcon fs={3}>more_horiz</FontIcon>
          </SmallOutlineButton>
        )}
        menuItems={[
          ["Edit", onEdit],
          ["Clone", onClone],
          ["Delete", onDelete],
          moveMealUp ? ["Move up", moveMealUp] : undefined,
          moveMealDown ? ["Move down", moveMealDown] : undefined
        ].filter(i => i)}
      />
    )
  ]
});

export default ({ meals }) => <MealsTable rowsData={meals} />;
