import { createSelector } from "reselect";

import * as R from "ramda";

import { getters as TicketsPassesGetters } from "Orders/TicketsPasses";

import { getters } from "Organizations/EditEventModal";

import { QUICK_ASSIGN_PAGES } from "Orders/TicketsPasses/constants";

export const getVariantsPrices = createSelector(
  TicketsPassesGetters.data,
  R.map(item => ({
    id: R.propOr("", "variantId", item),
    prices: R.map(
      price => ({
        id: R.propOr(null, "id", price),
        name: price.name,
        price: price.amount
      }),
      item.prices
    )
  }))
);

export const getItemVariantPrices = createSelector(
  TicketsPassesGetters.data,
  (_, { id }) => id,
  (ticketsPasses, id) => {
    R.compose(
      R.filter(R.propEq("id", id)),
      R.map(item => ({
        id: R.propOr("", "variantId", item),
        prices: R.map(
          price => ({
            id: R.propOr(null, "id", price),
            name: price.name,
            price: price.amount
          }),
          item.prices
        )
      }))
    )(ticketsPasses);
  }
);

export const isCredentialsModalEnabled = createSelector(
  TicketsPassesGetters.selectedQuickAssignPage,
  R.equals(QUICK_ASSIGN_PAGES.CREDENTIALS)
);

export const getVariantsData = createSelector(
  TicketsPassesGetters.data,
  getters.eventId,
  (_, { profileId }) => profileId,
  (ticketsPasses, eventId, profileId) => {
    const variants = R.reduce(
      (acc, item) => {
        const profiles = R.propOr([], "who_has_access", item);

        const profile = R.find(R.propEq("id", profileId), profiles);

        if (profile) {
          return [
            ...acc,
            {
              id: item.variantId,
              limit: profile.limit,
              prices: R.map(price => {
                return {
                  id: price.tempId || null,
                  price_id: price.tempId || null,
                  price: price.price,
                  limit: profile.limit
                };
              }, profile.prices)
            }
          ];
        }
        return acc;
      },
      [],
      ticketsPasses
    );

    const data = {
      eventId,
      profilesToUpdate: [
        {
          id: profileId,
          variants
        }
      ]
    };

    return data;
  }
);
