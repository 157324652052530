import React from "react";
import {
  Div,
  Text1,
  Text2,
  Text3,
  BigShadedInput,
  BigFilledButton,
  SearchIcon,
  AddCircleIcon,
  SuperOutlineButton,
  SuperFilledButton,
  RightArrowIcon
} from "components/Base";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "Forms/WizardModal";
import {
  getFilterCategories,
  isAllCategoriesDisabled,
  getInventoryFormsLanguage
} from "Forms/WizardModal/selectors";
import Header from "Forms/WizardModal/View/Header";
import CategoryItem from "./CategoryItem";
import { INVENTORY_PAGES } from "Forms/WizardModal/constants";

const decorate = connect(
  state => ({
    inventoryCategories: getFilterCategories(state),
    isAllCategoriesDisabled: isAllCategoriesDisabled(state),
    selectedCategoriesFilter: getters.selectedCategoriesFilter(state),
    language: getInventoryFormsLanguage(state)
  }),
  {
    populateInventoryFormDetails: actions.populateInventoryFormDetails,
    setSelectedCategoriesFilter: actions.setSelectedCategoriesFilter,
    back: () => actions.setSelectedInventory(INVENTORY_PAGES.SELECT_METHOD),
    goToSelectFields: () =>
      actions.setSelectedInventory(INVENTORY_PAGES.SELECT_FIELDS),
    enableAllCategories: actions.enableAllCategories,
    disableAllCategories: actions.disableAllCategories
  }
);

const InventoryCategories = ({
  handlers,
  inventoryCategories,
  setSelectedCategoriesFilter,
  selectedCategoriesFilter,
  back,
  goToSelectFields,
  enableAllCategories,
  disableAllCategories,
  isAllCategoriesDisabled,
  populateInventoryFormDetails,
  language
}) => (
  <Div width={1} display="column.space-between" height={1} bg="neutral0">
    <Header handlers={handlers} />
    <Div
      bg="neutral0"
      display="column.flex-start.center"
      flex={1}
      p={5}
      style={{ overflowY: "auto" }}
    >
      <Div fs={6} fw={4} color="black">
        Select a category of equipment &amp; inventory
      </Div>
      {language.subheader ? (
        <Text2 color="black" mt={2}>
          {language.subheader}
        </Text2>
      ) : null}
      <Div
        display="row.space-between"
        width={1}
        mt={5}
        style={{ flexShrink: 0 }}
      >
        <BigShadedInput
          continuous
          LeftIcon={SearchIcon}
          placeholder="Search items & category list"
          onChange={text => setSelectedCategoriesFilter(text)}
          bg="neutral1"
          style={{ width: "calc(100% - 190px)" }}
        />
        <BigFilledButton
          LeftIcon={AddCircleIcon}
          width={180}
          color="neutral7"
          bg="neutral1"
          onClick={handlers.showAddCategoryModal}
        >
          Create Category
        </BigFilledButton>
      </Div>

      {selectedCategoriesFilter.length ? (
        <Div height={20} />
      ) : (
        <Div display="row" my={3} ml={1} width={1} style={{ flexShrink: 0 }}>
          <Text1 bold color="neutral6" mr={1}>
            Select:
          </Text1>
          <Div onClick={enableAllCategories} style={{ cursor: "pointer" }}>
            <Text1
              bold
              color={{ default: "neutral6", hover: "info6" }}
              underline
            >
              All
            </Text1>
          </Div>
          <Div onClick={disableAllCategories} style={{ cursor: "pointer" }}>
            <Text1
              bold
              color={{ default: "neutral6", hover: "info6" }}
              underline
              ml={1}
            >
              None
            </Text1>
          </Div>
        </Div>
      )}

      <Div width={1}>
        {R.map(
          category => (
            <CategoryItem key={category.id} {...category} />
          ),
          inventoryCategories
        )}
      </Div>
      <Div width={300} textAlign="center" mt={3}>
        <Text3>
          Don't see the asset category you need to collect requests for?
        </Text3>
        <BigFilledButton
          LeftIcon={AddCircleIcon}
          color="neutral7"
          bg="neutral1"
          mt={2}
          onClick={handlers.showAddCategoryModal}
        >
          Add another category
        </BigFilledButton>
      </Div>
    </Div>

    <Div
      display="row.space-between.center"
      width={1}
      height={90}
      bt={1}
      bc="neutral2"
      bg="white"
      px={7}
      style={{ flexShrink: 0 }}
    >
      <SuperOutlineButton onClick={back}>Back</SuperOutlineButton>
      <SuperFilledButton
        ml={3}
        bg="altB5"
        onClick={() => {
          populateInventoryFormDetails();
          goToSelectFields();
        }}
        disabled={isAllCategoriesDisabled}
        RightIcon={RightArrowIcon}
        width={133}
        display="row.space-between"
      >
        Next
      </SuperFilledButton>
    </Div>
  </Div>
);

export default decorate(InventoryCategories);
