// NOTE: this file should probably be moved to renamed to match this specific statusMap
// ideally there should be a file like this for each status domain (fulfillments, payments, etc.)

import React from "react";
import * as R from "ramda";
import { Div, Text2 } from "components/Base";
import {
  STATUS_STATES,
  makeStatusTag,
  makeStatusDot,
  makeStatusIcon
} from "utils/make-status";

const {
  ATTENTION_NOT_STARTED,
  IN_PROGRESS,
  WAITING,
  SUCCESS,
  FAILURE,
  ERROR,
  DONE,
  WAITING_ACTION,
  LOCKED,
  UNLOCKED,
  OPEN,
  CLOSED,
  ENABLED,
  DISABLED
} = STATUS_STATES;

const statusMap = {
  not_fulfilled: {
    state: ATTENTION_NOT_STARTED,
    verbiage: "Not Fullfilled"
  },
  pending: {
    state: WAITING,
    verbiage: "Pending"
  },
  pending_names: {
    state: WAITING,
    verbiage: "Pending Names"
  },
  partial_payment: {
    state: IN_PROGRESS,
    verbiage: "Partial Payment"
  },
  partial_review: {
    state: IN_PROGRESS,
    verbiage: "Partial Review"
  },
  partially_fulfilled: {
    state: IN_PROGRESS,
    verbiage: "Partially Fulfilled"
  },
  authorized: {
    state: SUCCESS,
    verbiage: "Authorized"
  },
  reviewed: {
    state: DONE,
    verbiage: "Reviewed"
  },
  needs_review: {
    state: WAITING_ACTION,
    verbiage: "Needs Review"
  },
  free_order: {
    state: SUCCESS,
    verbiage: "Free Order"
  },
  approved: {
    state: SUCCESS,
    verbiage: "Approved"
  },
  enabled: {
    state: ENABLED,
    verbiage: "Enabled"
  },
  disabled: {
    state: DISABLED,
    verbiage: "Disabled"
  },
  unpaid: {
    state: ATTENTION_NOT_STARTED,
    verbiage: "Unpaid"
  },
  paid: {
    state: SUCCESS,
    verbiage: "Paid"
  },
  placed: {
    state: SUCCESS,
    verbiage: "Placed"
  },
  fulfilled: {
    state: SUCCESS,
    verbiage: "Fulfilled"
  },
  synced: {
    state: SUCCESS,
    verbiage: "Synced"
  },
  partially_synced: {
    state: IN_PROGRESS,
    verbiage: "Partial Sync"
  },
  not_synced: {
    state: ATTENTION_NOT_STARTED,
    verbiage: "Not Synced"
  },
  sync_issue: {
    state: ERROR,
    verbiage: "Sync Issue"
  },
  syncing: {
    state: ATTENTION_NOT_STARTED,
    verbiage: "Syncing..."
  },
  cancelled: {
    state: FAILURE,
    verbiage: "Cancelled"
  },
  denied: {
    state: FAILURE,
    verbiage: "Denied"
  },
  rejected: {
    state: FAILURE,
    verbiage: "Denied"
  },
  sent: {
    state: SUCCESS,
    verbiage: "Sent"
  },
  not_sent: {
    state: ATTENTION_NOT_STARTED,
    verbiage: "Not Sent"
  },
  deleted: {
    state: FAILURE,
    verbiage: "Deleted"
  },
  checked_in: {
    state: SUCCESS,
    verbiage: "Checked In"
  },
  locked: {
    state: LOCKED,
    verbiage: "Locked"
  },
  unlocked: {
    state: UNLOCKED,
    verbiage: "Unlocked"
  },

  // event statuses

  open: {
    state: OPEN,
    verbiage: "Open"
  },
  closed: {
    state: CLOSED,
    verbiage: "Closed"
  },

  // integration statuses
  UNPAID: {
    state: ATTENTION_NOT_STARTED,
    verbiage: "Unpaid"
  },
  PARTIALLY_PAID: {
    state: IN_PROGRESS,
    verbiage: "Partial Payment"
  },
  PAID: {
    state: SUCCESS,
    verbiage: "Paid"
  },
  PARTIALLY_REFUNDED: {
    state: IN_PROGRESS,
    verbiage: "Partially Refunded"
  },
  REFUNDED: {
    state: ERROR,
    verbiage: "Refunded"
  },
  VOID: {
    state: FAILURE,
    verbiage: "Cancelled"
  }
};

const StatusTag = makeStatusTag(statusMap);
const StatusDot = makeStatusDot(statusMap);
const StatusIcon = makeStatusIcon(statusMap);

const Story = () => {
  return (
    <Div display={"column"} width={200}>
      {R.map(
        key => (
          <Div display="row.flex-start.center">
            <Text2 bold pr={2}>
              {key}
            </Text2>
            <StatusTag ml={2} mb={2} key={key} status={key} />
          </Div>
        ),
        R.keys(statusMap)
      )}
    </Div>
  );
};

export { Story, StatusTag, StatusDot, StatusIcon };
