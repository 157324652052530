import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Body from "../Layout/Body";
import Header from "../Layout/Header";
import Content from "../Layout/Content";
import ShowHideSwitch from "components/Global/Module/Sidebar/ShowHideSwitch";
import Title from "components/Global/Module/Title";
import Container from "components/Global/Module/Container";
import ReportPanel from "./ReportPanel";
import Sources from "./Sources";
import ProgressWidget from "./ProgressWidget";
import { sortBy } from "lodash";

@CSSModules(css)
class InventoryHome extends Component {
  componentDidMount() {
    this.fetchReport();
  }

  fetchReport = () =>
    this.props.getCategoryOverviewReport(this.props.params.eventId);

  render() {
    const { isFetching, params, categories, showModal } = this.props;

    return (
      <Content loading={isFetching}>
        <Body>
          <Container>
            <Header>
              <ShowHideSwitch moduleId="inventory" />
              <Title>Inventory Overview</Title>
            </Header>
            <div styleName="body">
              <div styleName="reports">
                {sortBy(categories, category =>
                  category.category_name.toLowerCase()
                ).map(category => (
                  <ReportPanel
                    key={category.category_id}
                    name={category.category_name}
                    items={category.items}
                    isFetching={isFetching}
                    onUpdate={this.fetchReport}
                    showModal={showModal}
                  />
                ))}
              </div>
              <div styleName="widgets">
                <ProgressWidget />
                <Sources />
              </div>
            </div>
          </Container>
        </Body>
      </Content>
    );
  }
}

InventoryHome.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    eventId: PropTypes.number
  }).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      category_id: PropTypes.string,
      category_name: PropTypes.string,
      items: PropTypes.array
    })
  ).isRequired,
  showModal: PropTypes.func.isRequired,
  getCategoryOverviewReport: PropTypes.func.isRequired
};

export default InventoryHome;
