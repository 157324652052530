import { put, all, takeEvery, take, fork, select } from "redux-saga/effects";
import * as R from "ramda";
import uuid from "node-uuid";
import { actions, getters } from "./";

import { actions as ScheduleActions } from "Schedules/Schedule";

import { actions as ValidatorActions } from "ui-kit/Validator";
import * as ValidatorSelectors from "ui-kit/Validator/selectors";

import { getValidations, valuesToValidate } from "./selectors";

import { registerError } from "redux/modules/errors/actions";

const saveRow = function*() {
  const values = yield select(getters.values);
  const moduleId = yield select(getters.moduleId);
  const fieldIds = R.keys(values);
  const validateValues = yield select(valuesToValidate);
  const transactionId = uuid.v4();
  yield put(
    ValidatorActions.bulkValidationRequest({
      values: validateValues,
      id: transactionId
    })
  );
  yield take(
    action =>
      action.type === ValidatorActions.bulkValidationResponse.type &&
      action.payload.id === transactionId
  );
  const isValidForm = yield select(ValidatorSelectors.isValid);
  try {
    if (isValidForm) {
      yield put(
        ScheduleActions.addActivity({
          values,
          moduleId: R.prop("value")(moduleId)
        })
      );
      yield put(actions.resetForm());
      yield put(ValidatorActions.clear(fieldIds));
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred creating a activity"
        }
      ])
    );
  }
};

const onChangeValues = function*({ fieldId }) {
  const validators = yield select(getValidations);
  const values = yield select(getters.values);
  yield put(
    ValidatorActions.validationRequest({
      ...values[fieldId],
      value: R.pathOr(null, ["value", "value"], values[fieldId]),
      ...validators[fieldId]
    })
  );
};

const watchSaveRow = function*() {
  yield takeEvery(actions.setSaveRow.type, saveRow);
};

const watchOnChange = function*() {
  yield takeEvery(actions.onChange.type, onChangeValues);
};

const rootSaga = function*() {
  yield all([fork(watchSaveRow), fork(watchOnChange)]);
};

export default rootSaga;
