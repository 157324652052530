import React from "react";
import { Div } from "components/Base";
import resolveEditor from "components/Global/StandAloneEditors/utils/resolveEditor";
import FormElements from "components/Global/Modal/Layout/FormElements";
const { InputGroup } = FormElements;
import Label from "components/Global-2016/Forms/Label";
import FormInputWrapper from "components/Event/FormsV2/Sections/FormInputWrapper";

const Name = ({ children, ...props }) => (
  <Div mb={1} style={{ color: "#454447", fontSize: 27 }} {...props}>
    {children}
  </Div>
);

const Description = ({ children }) => (
  <Div mb={4} style={{ color: "#454447", fontSize: 14 }}>
    {children}
  </Div>
);

const Fields = ({ fields, ...props }) => (
  <Div {...props}>
    {fields.map(field => (
      <Field key={field.id} field={field} />
    ))}
  </Div>
);

const Field = ({ field }) => {
  const Editor = resolveEditor(field);
  return (
    <InputGroup>
      <FormInputWrapper
        isEditing={field.isEditing}
        isValid={field.isValid}
        errorMessages={field.errors}
        disabled={field.disabled}
      >
        <Label
          required={field.required}
          description={field.settings.description}
        >
          {field.name}
        </Label>
        <Editor
          onChange={field.onValueChange}
          disabled={field.disabled}
          value={field.value}
          autocomplete={`autocomplete_${field.id}`} // @NOTE: Use random autocomplete ID so browsers dont have a value cached for it
          {...field.editorProps}
        />
      </FormInputWrapper>
    </InputGroup>
  );
};

const View = ({ fields, name, description }) => (
  <Div>
    {name ? <Name mb={description ? 1 : 4}>{name}</Name> : null}
    {description ? <Description>{description}</Description> : null}
    <Div>
      {fields.orderFields.length ? (
        <Fields fields={fields.orderFields} />
      ) : null}
    </Div>
  </Div>
);

export default View;
