import React from "react";

import { Div, Text3, Text2, EditIcon } from "components/Base";
import { noop } from "utils/General";

const filterCountMessage = (selected, total) =>
  selected === 0 ? "Showing All" : `Showing ${selected} of ${total}`;

const TypeSelectButton = ({
  selectedCount = 0,
  count = 0,
  showModal = noop,
  title = ""
}) => (
  <Div mt={3} px={4}>
    <Text3 bold>{title}</Text3>
    <Div onClick={showModal} display="row">
      <Text2 bold mb={2} color="primary7">
        {filterCountMessage(selectedCount, count)}
      </Text2>
      <EditIcon color="primary7" ml={2} />
    </Div>
  </Div>
);
export default TypeSelectButton;
