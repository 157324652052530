/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
import api from "./api";
import { registerError } from "redux/modules/errors/actions";
import { RECEIVE_USER, UPDATE_PHONE } from "redux/modules/user/constants";
import { EventTypes } from "redux-segment";

const isLoginPage = path => {
  return path.includes("/login");
};

const isPortalLoginPage = path => {
  return (
    path.includes("/portal-login") && !path.includes("/portal-login-callback")
  );
};

const isTicketPortalLoginPage = path => {
  return (
    path.includes("/requests-login") &&
    !path.includes("/requests-login-callback")
  );
};

const isSignupPage = path => {
  return path.includes("/signup") || path.includes("/account/confirm");
};

const isAttendPage = path => {
  return path.includes("/attend") || path.includes("/attend-login");
};

const isRegisterPage = path => {
  return path.includes("/register");
};

const isFormsPage = path => {
  return (
    path.startsWith("/forms") ||
    path.includes("/open-submissions") ||
    path.includes("/form-link")
  );
};

const isAssignmentManagerPage = path => {
  return path.startsWith("/assignment-manager");
};

const isPortalPage = path => {
  return (
    path.includes("/portals") ||
    path.includes("/portal-switch") ||
    path.includes("/portal-login-callback") ||
    path.includes("/submit-callback")
  );
};

const isSalesPortalPage = path => {
  return path.includes("/sales-portal");
};

const isIntakePage = path => {
  return path.includes("/intake") || path.includes("/submit/");
};

const isHealthPassPage = path => {
  return path.includes("/health-pass");
};

const isTicketPortalPage = path => {
  return path.startsWith("/requests");
};

const isViewingPageThatDoesntRequireAuth = path => {
  return (
    path.includes("/join/vu/") ||
    path.includes("/logout/callback") ||
    path.startsWith("/invoice") ||
    path.startsWith("/forgot") ||
    path.startsWith("/reset")
  );
};

const decorateUserPayload = (user, credentials = {}) => ({
  id: user.id,
  email: user.email,
  slug: user.slug,
  fname: user.fname,
  lname: user.lname,
  photo_url: user.photo_url,
  phone: user.phone,
  location: user.location,
  timezone: user.timezone,
  events: user.events,
  mute_sounds: user.mute_sounds,
  credentials: {
    userId: user.id,
    ...credentials
  }
});

const decorateUserMeta = user => {
  let meta = {};
  if (window.__ENABLED_ANALYTICS__.segment) {
    meta = {
      analytics: {
        eventType: EventTypes.identify,
        eventPayload: {
          userId: user.id,
          traits: {
            fname: user.fname,
            lname: user.lname,
            email: user.email,
            username: user.email
          },
          options: {
            Intercom: { hideDefaultLauncher: true },
            context: {
              app: {
                env: window.__ENV__,
                name: window.__APP_NAME__,
                version: window.__APP_VERSION__,
                build: window.__APP_BUILD__,
                deploy: window.__DEPLOY__,
                cookies_enabled: navigator.cookieEnabled
              }
            }
          }
        }
      }
    };
  }
  return meta;
};

const configureRollbar = user => {
  try {
    if (window.__ENABLED_ANALYTICS__.rollbar && window.__ROLLBAR_CONFIG__) {
      const rbConfig = window.__ROLLBAR_CONFIG__ || {};
      Rollbar.configure({
        ...rbConfig,
        payload: {
          ...rbConfig.payload,
          person: {
            id: user.id,
            email: user.email
          }
        }
      });
    }
  } catch (e) {
    /* swallow */
  }
};

export function createPortalUserFromContact(data) {
  return async (dispatch, getState) => {
    try {
      return await api.createPortalUserFromContact(
        getState().user.user.credentials,
        data
      );
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred giving login access to contact"
          }
        ])
      );
    }
  };
}

export function createPortalUsersFromContacts({ eventId, contacts, save }) {
  return async (dispatch, getState) => {
    try {
      const result = await api.createPortalUsersFromContacts(
        getState().user.user.credentials,
        eventId,
        contacts,
        save
      );
      return result.result;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred creating giving login access to contacts"
          }
        ])
      );
    }
  };
}

export function getLoggedInUserDetails() {
  return async (dispatch, getState) => {
    try {
      const { payload: user } = await api.getLoggedInUserDetails(
        getState().user.user.credentials
      );

      // config: rollbar
      configureRollbar(user);

      const decoratedUser = decorateUserPayload(
        user,
        getState().user.user.credentials
      );

      dispatch({
        type: RECEIVE_USER,
        payload: decoratedUser,
        meta: decorateUserMeta(user)
      });

      return decoratedUser;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting logged in user details"
          }
        ])
      );
    }
  };
}

export function login(data, cb) {
  return async () => {
    window.webAuth.login(
      {
        username: data.email,
        password: data.password,
        realm: "Username-Password-Authentication"
      },
      (err, result) => {
        return cb(err, result);
      }
    );
  };
}

export function logout() {
  return async () => {
    window.webAuth.logout({
      federated: true,
      returnTo: `${window.__LENND_APP_URL__}/logout/callback`
    });
  };
}

export function checkSession(options = {}) {
  return async dispatch => {
    // @TODO: ReturnTo
    // getState().routing.locationBeforeTransitions.query.returnTo

    // Allow access to whitelisted pages that don't require auth
    if (isViewingPageThatDoesntRequireAuth(window.location.pathname)) {
      dispatch({
        type: RECEIVE_USER,
        payload: decorateUserPayload({})
      });
      return true;
    }

    // prepare options to pass to Auth0
    const optionsToPass = {
      // eslint-disable-next-line no-underscore-dangle
      redirectUri: `${window.__LENND_APP_URL__}/login?checkSession=true`,
      responseType: "token id_token",
      ...options
    };

    const currentlyViewingLoginPage = isLoginPage(window.location.pathname);
    const currentlyViewingPortalLoginPage = isPortalLoginPage(
      window.location.pathname
    );
    const currentlyViewingTicketPortalLoginPage = isTicketPortalLoginPage(
      window.location.pathname
    );

    // ping Auth0 to check if user has valid session (proxies to webAuth.authorize)
    window.webAuth.checkSession(optionsToPass, async (err, authResult) => {
      // if we have a valid session, store our token in state to pass to any calls we make
      if (authResult && authResult.accessToken && authResult.idToken) {
        if (currentlyViewingLoginPage) {
          window.location = "/home?loggedIn=true";
          return true;
        } else if (currentlyViewingPortalLoginPage) {
          const pathParts = window.location.pathname.split("/");
          window.location = `/portal-switch/${pathParts[2]}/${pathParts[3]}`;
          return true;
        } else if (currentlyViewingTicketPortalLoginPage) {
          const pathParts = window.location.pathname.split("/");
          window.location = `/requests/${pathParts[2]}`;
          return true;
        }

        // @NOTE: If local login is ever added, it can be put here
        // localLogin(authResult);

        try {
          const { payload: user } = await api.getLoggedInUserDetails({
            idToken: authResult.idToken
          });

          const decoratedUser = decorateUserPayload(user, {
            idToken: authResult.idToken
          });

          dispatch({
            type: RECEIVE_USER,
            payload: decoratedUser,
            meta: decorateUserMeta(user)
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(`Warning: User could not be fetched`);
          dispatch({
            type: RECEIVE_USER,
            payload: {},
            meta: {}
          });
        }

        return true;
      } else if (err) {
        // eslint-disable-next-line no-console
        console.error(
          `Could not get a new token:`,
          err.error,
          err.error_description
        );
        // logout();
      }

      // otherwise, assume not logged in and redirect to login page (if not already on login page)
      if (
        !currentlyViewingLoginPage &&
        !currentlyViewingPortalLoginPage &&
        !currentlyViewingTicketPortalLoginPage &&
        !isFormsPage(window.location.pathname) &&
        !isSignupPage(window.location.pathname) &&
        !isAttendPage(window.location.pathname) &&
        !isRegisterPage(window.location.pathname) &&
        !isAssignmentManagerPage(window.location.pathname) &&
        !isSalesPortalPage(window.location.pathname) &&
        !isIntakePage(window.location.pathname) &&
        !isHealthPassPage(window.location.pathname) &&
        !options.noRedirect
      ) {
        if (isPortalPage(window.location.pathname)) {
          const pathParts = window.location.pathname.split("/");
          window.location = `/portal-login/${pathParts[2]}/${pathParts[3]}`;
          return true;
        } else if (isTicketPortalPage(window.location.pathname)) {
          const pathParts = window.location.pathname.split("/");
          window.location = `/requests/${pathParts[2]}/login`;
          return true;
        } else {
          const returnTo = `${window.location.pathname}${
            window.location.search ? window.location.search : ""
          }`;
          window.location = `/login?message=${encodeURIComponent(
            "You must be logged in to continue."
          )}&returnTo=${returnTo}`;
        }
      }

      dispatch({
        type: RECEIVE_USER,
        payload: decorateUserPayload({})
      });

      return true;
    });

    return true;
  };
}

export function updatePhone(phone) {
  return {
    type: UPDATE_PHONE,
    payload: phone
  };
}
