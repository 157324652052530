import * as R from "ramda";

import {
  put,
  all,
  takeEvery,
  select,
  call,
  fork,
  take
} from "redux-saga/effects";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import * as FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

import { STEPS } from "EventLight/Expo/Sales/constants";

import { getters, actions } from "EventLight/Expo/Sales/model";
import { getters as PackageTableGetters } from "Sponsors/PackageTable";

import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { registerError } from "redux/modules/errors/actions";
import { actions as FormActions } from "ui-kit/Form/model";
import { actions as PackageTableActions } from "Sponsors/PackageTable";

import {
  getVisibleFields,
  getModalSelectedQuestions,
  getFieldById
} from "EventLight/Expo/Sales/selectors";
import * as FormSelectors from "ui-kit/Form/selectors";

import { APPLICATION_FIELDS } from "Sponsors/PackageTable/constants";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";

import Api from "../api";

const mapWithIndex = R.addIndex(R.map);

const contactReadOnlyFields = resolveReadOnlyFields({
  moduleId: STANDARD_MODULES.contacts.id
});
const accountReadOnlyFields = resolveReadOnlyFields({
  moduleId: STANDARD_MODULES.accounts.id
});
const orderReadOnlyFields = [
  ...resolveReadOnlyFields({
    moduleId: STANDARD_MODULES.orders.id
  }),
  FIELD_IDS.MODULE.SUBMITTING_ACCOUNT,
  FIELD_IDS.MODULE.SUBMITTING_CONTACT
];

const setFormFields = function*() {
  const visibleFields = yield select(getVisibleFields);

  const forms = R.map(
    form => ({
      id: form.id,
      values: R.map(
        value => ({ value }),
        R.pick(R.values(APPLICATION_FIELDS), form)
      )
    }),
    visibleFields
  );

  yield put(FormActions.bulkWriteForms(forms));
};

const refreshFields = function*() {
  yield put(
    actions.refreshEventShowcase({
      callWhenDone: actions.getModuleFields
    })
  );
};

const deleteField = function*({ payload: { moduleId, fieldId } }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  try {
    yield call(Api.deleteModuleField, {
      credentials,
      eventId,
      moduleId,
      data: {
        field: {
          id: fieldId
        }
      }
    });

    yield fork(refreshFields);
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred deleting showcase field"
          }
        ])
      )
    ]);
  }
};

const getModuleFields = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  try {
    const [
      {
        fields: { fields: accountFields }
      },
      {
        fields: { fields: contactFields }
      },
      {
        fields: { fields: orderFields }
      }
    ] = yield all([
      call(Api.getModuleFields, {
        credentials,
        eventId,
        moduleId: STANDARD_MODULES.accounts.id
      }),
      call(Api.getModuleFields, {
        credentials,
        eventId,
        moduleId: STANDARD_MODULES.contacts.id
      }),
      call(Api.getModuleFields, {
        credentials,
        eventId,
        moduleId: STANDARD_MODULES.orders.id
      })
    ]);

    const filteredAccountFields = R.filter(
      field => !R.contains(field.id, accountReadOnlyFields),
      accountFields
    );
    const filteredContactFields = R.filter(
      field => !R.contains(field.id, contactReadOnlyFields),
      contactFields
    );
    const filteredOrderFields = R.filter(
      field => !R.contains(field.id, orderReadOnlyFields),
      orderFields
    );

    yield put(
      actions.setFieldsByModule({
        [STANDARD_MODULES.accounts.id]: R.map(
          R.assoc("moduleId", STANDARD_MODULES.accounts.id),
          filteredAccountFields
        ),
        [STANDARD_MODULES.contacts.id]: R.map(
          R.assoc("moduleId", STANDARD_MODULES.contacts.id),
          filteredContactFields
        ),
        [STANDARD_MODULES.orders.id]: R.map(
          R.assoc("moduleId", STANDARD_MODULES.orders.id),
          filteredOrderFields
        )
      })
    );

    yield put(
      actions.setVisibleFieldsByModule({
        [STANDARD_MODULES.orders.id]: R.map(
          R.prop("id"),
          // R.slice(0, 5, filteredOrderFields)
          filteredOrderFields
        )
      })
    );

    yield call(setFormFields);
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred geting module fields"
          }
        ])
      )
    ]);
  }
};

const updateEventShowcaseField = function*({
  payload: { fieldId, isRequired }
}) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  const eventShowcase = yield select(getters.eventShowcase);

  try {
    yield call(Api.updateEventShowcaseField, {
      credentials,
      eventId,
      data: {
        pageId: eventShowcase.id,
        fieldId,
        isRequired
      }
    });
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred updating sales portal field"
          }
        ])
      )
    ]);
  }
};

const deleteEventShowcaseField = function*({ payload: { fieldId } }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  const eventShowcase = yield select(getters.eventShowcase);

  try {
    yield call(Api.deleteEventShowcaseField, {
      credentials,
      eventId,
      data: {
        pageId: eventShowcase.id,
        fieldId
      }
    });
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred deleting sales portal field"
          }
        ])
      )
    ]);
  }
};

const reorderEventShowcaseFields = function*({ payload: { id, index } }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  const eventShowcase = yield select(getters.eventShowcase);

  const accountFieldOrder = yield select(PackageTableGetters.order, {
    instanceId: STANDARD_MODULES.accounts.id
  });
  const contactFieldOrder = yield select(PackageTableGetters.order, {
    instanceId: STANDARD_MODULES.contacts.id
  });
  const orderFieldOrder = yield select(PackageTableGetters.order, {
    instanceId: STANDARD_MODULES.orders.id
  });

  const formatFields = function*(fieldIds) {
    const data = [];

    for (const fieldId of fieldIds) {
      const included = yield select(FormSelectors.getFieldValue, {
        fieldId: APPLICATION_FIELDS.INCLUDE,
        instanceId: fieldId
      });

      data.push({
        pageId: eventShowcase.id,
        fieldId: fieldId,
        order: fieldIds.indexOf(fieldId),
        included
      });
    }

    return data;
  };

  const STANDARD_FIELD_IDS = [
    FIELD_IDS.CONTACTS.FIRST_NAME,
    FIELD_IDS.CONTACTS.LAST_NAME,
    FIELD_IDS.CONTACTS.EMAIL,
    FIELD_IDS.ACCOUNTS.NAME
  ];

  const fields = R.filter(
    f => f.included && !STANDARD_FIELD_IDS.includes(f.fieldId),
    R.concat(
      R.concat(
        yield formatFields(accountFieldOrder),
        yield formatFields(contactFieldOrder)
      ),
      yield formatFields(orderFieldOrder)
    )
  );

  try {
    yield call(Api.updateEventShowcaseField, {
      credentials,
      eventId,
      data: {
        bulk: true,
        fields
      }
    });
  } catch (e) {
    yield put(
      registerError([
        {
          system: e,
          user: "An error occurred reordering sales portal fields"
        }
      ])
    );
  }
};

//

const addEventShowcaseVariant = function*({ payload: {} }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  try {
    yield call(Api.addEventShowcaseVariant, {
      credentials,
      eventId,
      data: {}
    });
  } catch (e) {
    yield put(
      registerError([
        {
          system: e,
          user: "An error occurred adding sales portal variant"
        }
      ])
    );
  }
};

const updateEventShowcaseVariant = function*({ payload: {} }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  try {
    yield call(Api.updateEventShowcaseVariant, {
      credentials,
      eventId,
      data: {}
    });
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred updating sales portal variant"
          }
        ])
      )
    ]);
  }
};

const deleteEventShowcaseVariant = function*({ payload: {} }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  try {
    yield call(Api.deleteEventShowcaseVariant, {
      credentials,
      eventId,
      data: {}
    });
    yield put(actions.refreshEventShowcase());
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred deleting sales portal variant"
          }
        ])
      )
    ]);
  }
};

const reorderEventShowcaseVariants = function*({ payload: {} }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  try {
    yield call(Api.updateEventShowcaseVariant, {
      credentials,
      eventId,
      data: {}
    });
    yield put(actions.refreshEventShowcase());
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred reordering sales portal variants"
          }
        ])
      )
    ]);
  }
};

const addEventShowcaseField = function*({
  payload: { bulk, fields, fieldId, isRequired, order }
}) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  const eventShowcase = yield select(getters.eventShowcase);

  try {
    yield call(Api.addEventShowcaseField, {
      credentials,
      eventId,
      data: bulk
        ? {
            bulk: true,
            fields: fields.map((f, idx) => ({
              ...f,
              pageId: eventShowcase.id
            }))
          }
        : {
            pageId: eventShowcase.id,
            fieldId: fieldId,
            isRequired: isRequired,
            order: order
          }
    });
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred adding sales portal field"
          }
        ])
      )
    ]);
  }
};

const followFieldUpdate = function*({ id, fieldId }) {
  let newValue = yield select(FormSelectors.getFieldValue, {
    instanceId: id,
    fieldId
  });
  const existingField = yield select(getFieldById, { fieldId });

  if (fieldId === APPLICATION_FIELDS.INCLUDE && newValue === true) {
    const isRequired = yield select(FormSelectors.getFieldValue, {
      instanceId: id,
      fieldId: APPLICATION_FIELDS.REQUIRE
    });
    yield fork(addEventShowcaseField, {
      payload: {
        fieldId: id,
        isRequired: typeof isRequired === "string" ? false : isRequired,
        order: existingField.order
      }
    });
  } else if (fieldId === APPLICATION_FIELDS.INCLUDE && newValue === false) {
    yield fork(deleteEventShowcaseField, {
      payload: { fieldId: id }
    });
    yield put(
      FormActions.setFieldValue(false, {
        meta: {
          instanceId: id,
          fieldId: APPLICATION_FIELDS.REQUIRE
        }
      })
    );
  } else if (fieldId === APPLICATION_FIELDS.REQUIRE) {
    const included = yield select(FormSelectors.getFieldValue, {
      instanceId: id,
      fieldId: APPLICATION_FIELDS.INCLUDE
    });

    if (!included) {
      yield fork(addEventShowcaseField, {
        payload: {
          fieldId: id,
          isRequired: newValue,
          order: existingField.order
        }
      });
      yield put(
        FormActions.setFieldValue(true, {
          meta: {
            instanceId: id,
            fieldId: APPLICATION_FIELDS.INCLUDE
          }
        })
      );
    } else {
      yield fork(updateEventShowcaseField, {
        payload: { fieldId: id, isRequired: newValue }
      });
    }
  }
};

const watchUpdateFields = function*() {
  for (;;) {
    const {
      meta: { instanceId, fieldId }
    } = yield take(FormActions.setFieldValue.type);
    if (
      [APPLICATION_FIELDS.INCLUDE, APPLICATION_FIELDS.REQUIRE].includes(fieldId)
    ) {
      yield call(followFieldUpdate, {
        id: instanceId,
        fieldId
      });
    }
  }
};

const syncEnabledFields = function*({ payload: moduleId }) {
  const oldSelected = yield select(getModalSelectedQuestions, { moduleId });

  for (;;) {
    const action = yield take([
      actions.closeSelectQuestionsModal.type,
      actions.setVisibleFields.type
    ]);
    if (action.type === actions.closeSelectQuestionsModal.type) {
      break;
    }
    const forms = R.map(
      id => ({
        id,
        values: { [APPLICATION_FIELDS.INCLUDE]: { value: true } }
      }),
      R.without(oldSelected, action.payload)
    );
    yield all([
      put(FormActions.bulkUpdateForms(forms)),
      put(actions.setSelectingQuestions("")),
      fork(addEventShowcaseField, {
        payload: {
          bulk: true,
          fields: forms.map(f => ({
            fieldId: f.id
          }))
        }
      })
    ]);
  }
};

const syncNewField = function*({ payload: newField }) {
  yield all([
    put(
      FormActions.mergeValues(
        { [APPLICATION_FIELDS.INCLUDE]: { value: true } },
        { meta: { instanceId: newField.id } }
      )
    ),
    fork(addEventShowcaseField, {
      payload: {
        fieldId: newField.id
      }
    })
  ]);
};

const saveItemsToShow = function*({ payload: variants }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  const eventShowcase = yield select(getters.eventShowcase);
  const savingTypeId = yield select(getters.selectingItemsToShow);

  try {
    yield call(Api.addEventShowcaseVariant, {
      credentials,
      eventId,
      data: {
        pageId: eventShowcase.id,
        typeId: savingTypeId,
        variants: Object.keys(variants).map(variantId => ({
          id: variantId,
          priceId: variants[variantId].price,
          order: variants[variantId].order
        }))
      }
    });
    yield put(actions.refreshEventShowcase());
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred adding sales portal variant"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setItemsToShowResponse(null));
  }
};

const watchSyncNewField = function*() {
  yield takeEvery(actions.addQuestionField.type, syncNewField);
};

const watchUpdateFieldDetails = function*() {
  yield takeEvery(actions.updateFieldDetails.type, refreshFields);
};

const watchDeleteField = function*() {
  yield takeEvery(actions.deleteField.type, deleteField);
};

const watchEnableFields = function*() {
  yield takeEvery(actions.setSelectingQuestions.type, syncEnabledFields);
};

const watchGetModuleFields = function*() {
  yield takeEvery(actions.getModuleFields.type, getModuleFields);
};

const watchAddEventShowcaseField = function*() {
  yield takeEvery(
    actions.addEventShowcaseModuleField.type,
    addEventShowcaseField
  );
};

const watchUpdateEventShowcaseField = function*() {
  yield takeEvery(
    actions.updateEventShowcaseModuleField.type,
    updateEventShowcaseField
  );
};

const watchDeleteEventShowcaseField = function*() {
  yield takeEvery(
    actions.deleteEventShowcaseModuleField.type,
    deleteEventShowcaseField
  );
};

const watchReorderEventShowcaseFields = function*() {
  yield takeEvery(
    PackageTableActions.switchIndexes.type,
    reorderEventShowcaseFields
  );
};

const watchAddEventShowcaseVariant = function*() {
  yield takeEvery(
    actions.addEventShowcaseVariant.type,
    addEventShowcaseVariant
  );
};

const watchUpdateEventShowcaseVariant = function*() {
  yield takeEvery(
    actions.updateEventShowcaseVariant.type,
    updateEventShowcaseVariant
  );
};

const watchDeleteEventShowcaseVariant = function*() {
  yield takeEvery(
    actions.deleteEventShowcaseVariant.type,
    deleteEventShowcaseVariant
  );
};

const watchReorderEventShowcaseVariants = function*() {
  yield takeEvery(
    actions.reorderEventShowcaseVariants.type,
    reorderEventShowcaseVariants
  );
};

const watchSetItemsToShowRequest = function*() {
  yield takeEvery(actions.setItemsToShowRequest.type, saveItemsToShow);
};

//

const rootSaga = function*() {
  yield all([
    fork(watchUpdateFields),
    fork(watchGetModuleFields),
    fork(watchUpdateFieldDetails),
    fork(watchDeleteField),
    fork(watchAddEventShowcaseField),
    fork(watchUpdateEventShowcaseField),
    fork(watchDeleteEventShowcaseField),
    fork(watchReorderEventShowcaseFields),
    fork(watchAddEventShowcaseVariant),
    fork(watchUpdateEventShowcaseVariant),
    fork(watchDeleteEventShowcaseVariant),
    fork(watchReorderEventShowcaseVariants),
    fork(watchEnableFields),
    fork(watchSyncNewField),
    fork(watchSetItemsToShowRequest)
  ]);
};

export default rootSaga;
