import React from "react";

import { getValue } from "../../utils";

const Signature = props => {
  const value = getValue(props);

  if (!value) {
    return null;
  }
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <img src={value} height={35} />
    </div>
  );
};

export default Signature;
