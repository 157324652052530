import React, { Component } from "react";
import * as R from "ramda";
import View from "components/Global/OrderReports/View";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import orderReportController from "components/Global/OrderReports";
import {
  hasApprovals,
  isRejected,
  parseApproval,
  isApproved
} from "components/Global/Approvals/utils/approvals-helpers";
import { paginatorResults, paginatorHandler } from "components/Base";
import { withState, withHandlers } from "utils/General";

const getApprovedQty = lineItem =>
  !hasApprovals(lineItem) || isApproved(lineItem) ? lineItem.quantity : 0;
const getRejectedQty = lineItem =>
  isRejected(lineItem) ? lineItem.quantity : 0;

class AccountsCredentialReportController extends Component {
  render() {
    const {
      approveLineItems,
      approveRemaining,
      cando,
      countOfRemainingReviews,
      denyRemaining,
      loading,
      currentPage,
      goToContact,
      goToOrder,
      goToPage,
      onSelectResultsPerPage,
      removeAssignment,
      showLinkedRecordsModal,
      toggleVisibleTable,
      visibleTables,
      onSearch,
      searchTerm,
      rejectLineItems,
      removeApproval,
      removeAssignments,
      report,
      resultsPerPage,
      showAddOrderModal,
      user
    } = this.props;
    const views = [
      {
        id: "all-orders",
        name: "All Orders",
        active: true
      }
    ];
    const rowReducer = (
      rows,
      {
        order_number,
        order_id,
        price,
        assigned_to,
        quantity,
        item,
        ...lineItem
      }
    ) => {
      const approval = parseApproval({
        record: lineItem,
        userId: user.id
      });
      const isLineItemApproved =
        !hasApprovals(lineItem) || isApproved(lineItem);
      const key = `${assigned_to.contact_name}-${approval.approvalStatus}`;
      let value = {};
      if (rows[key]) {
        value = rows[key];
        value.numApproved += isLineItemApproved ? quantity : 0;
        value.numRejected += isRejected(lineItem) ? quantity : 0;
        value.numRequired += quantity;
        value.orders = R.uniqBy(R.prop("number"), [
          ...rows[key].orders,
          { number: order_number, onClick: () => goToOrder(order_id) }
        ]);
        value.assignmentIds = [...rows[key].assignmentIds, assigned_to.id];
        value.lineItems = [...rows[key].lineItems, lineItem];
        value.approvalProps = {
          approveLineItems: () =>
            approveLineItems([...rows[key].lineItems, lineItem]),
          rejectLineItems: () =>
            rejectLineItems([...rows[key].lineItems, lineItem]),
          ...approval
        };
        if (cando(`${STANDARD_MODULE_IDS.credentials.id}_update_line_items`)) {
          value.onAssign = !lineItem.is_issued
            ? count =>
                showLinkedRecordsModal(count, [
                  ...rows[key].lineItems.map(r => r.id),
                  lineItem.id
                ])
            : rows[key].onAssign;
          value.onRemoveAssignment = () =>
            removeAssignments([...rows[key].assignmentIds, assigned_to.id]);
        }
      } else {
        value = {
          numRequired: quantity,
          numRejected: isRejected(lineItem) ? quantity : 0,
          numApproved: isLineItemApproved ? quantity : 0,
          itemName: item.name,
          itemColor: item.background_color,
          price: R.isNil(price) || R.isEmpty(price) ? undefined : price,
          orders: [
            { number: order_number, onClick: () => goToOrder(order_id) }
          ],
          onClickAssignedTo: () => goToContact(assigned_to.id),
          assignedTo: assigned_to.contact_name,
          onAssign: undefined,
          onRemoveAssignment: undefined,
          onRemove: () => removeApproval(lineItem.id),
          lineItems: [lineItem],
          assignmentIds: [assigned_to.id],
          approvalProps: {
            approveLineItems: () => approveLineItems([lineItem]),
            rejectLineItems: () => rejectLineItems([lineItem]),
            ...parseApproval({ record: lineItem, userId: user.id })
          }
        };
        if (cando(`${STANDARD_MODULE_IDS.credentials.id}_update_line_items`)) {
          value.onAssign = !lineItem.is_issued
            ? count => showLinkedRecordsModal(count, [lineItem.id])
            : undefined;
          value.onRemoveAssignment = () => removeAssignment(assigned_to.id);
        }
      }
      rows[key] = value;
      return rows;
    };

    const reportWithHandlers = R.map(account => {
      const tableHash = group => `${account.name}-${group[0].item.name}`;
      let tableProps = { rowData: [], collapsableRowsData: [] };
      R.forEach(group => {
        const preGroupedRows = R.compose(
          R.sortBy(R.prop("assignedTo")),
          R.values,
          R.reduce(rowReducer, {})
        )(group);

        if (preGroupedRows.length > 1) {
          tableProps.rowData.push({
            isNested: true,
            price: undefined,
            orders: [],
            onCollapse: () => toggleVisibleTable(tableHash(group)),
            numRequired: R.sum(R.map(R.prop("quantity"), group)),
            numApproved: R.sum(R.map(getApprovedQty, group)),
            numRejected: R.sum(R.map(getRejectedQty, group)),
            itemName: group[0].item.name,
            itemColor: group[0].item.background_color,
            bulkApprovalProps: {
              countOfRemainingReviews: countOfRemainingReviews(group),
              onBulkApprove: () => approveRemaining(group),
              onBulkReject: () => denyRemaining(group)
            }
          });
          tableProps.collapsableRowsData.push({
            collapsedDefault: !visibleTables.includes(tableHash(group)),
            rowsData: preGroupedRows
          });
        } else {
          tableProps.rowData.push(preGroupedRows[0]);
          tableProps.collapsableRowsData.push(undefined);
        }
      })(R.values(R.groupBy(R.prop("variant_id"), account.line_items)));
      return {
        headerSubmessages: [],
        headerTitle: account.name,
        percentComplete: (account.count_of_reviewed / account.total) * 100,
        numPendingReview: account.count_of_pending,
        bulkApprovalProps: {
          countOfRemainingReviews: countOfRemainingReviews(account.line_items),
          onBulkApprove: () => approveRemaining(account.line_items),
          onBulkReject: () => denyRemaining(account.line_items)
        },
        ...tableProps
      };
    })(report);
    return (
      <View
        {...{
          countOfResults: report.length,
          currentPage: currentPage,
          goToPage: goToPage,
          loading,
          moduleId: STANDARD_MODULE_IDS.orders.id,
          onSearch,
          onSelectResultsPerPage: onSelectResultsPerPage,
          report: paginatorResults({
            records: reportWithHandlers,
            resultsPerPage,
            currentPage
          }),
          reportSource: "Passes / Manage",
          reportTitle: "Items by group",
          resultsPerPage: resultsPerPage,
          searchTerm,
          showAddOrderModal,
          views
        }}
      />
    );
  }
}

export default R.compose(
  withState("visibleTables", "setVisibleTables", []),
  withHandlers({
    toggleVisibleTable: props => id => {
      if (props.visibleTables.includes(id)) {
        return props.setVisibleTables(
          props.visibleTables.filter(t => t !== id)
        );
      }
      return props.setVisibleTables([id, ...props.visibleTables]);
    }
  })
)(
  paginatorHandler(
    orderReportController(
      AccountsCredentialReportController,
      "line_items",
      (val, base) => ({
        id: val.id,
        accountName: base.name,
        assignedTo: val.assigned_to.contact_name,
        other: val.item.name
      })
    )
  )
);
