import React from "react";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

const AttachIcon = () => (
  <div styleName="container">
    <i className="material-icons" styleName="plus">
      add
    </i>
    <i className="material-icons" styleName="clip">
      attach_file
    </i>
  </div>
);

export default CSSModules(AttachIcon, css);
