import React from "react";

import { Div, Text2 } from "components/Base";

import { WithThemeConsumer } from "ui-kit/Theme/View";
import { defaultTh, os, wsp } from "ui-kit/Theme/utils";

const at = WithThemeConsumer();

export const Label = at(({ children, Th = defaultTh, ...styleProps }) => (
  <Text2 {...Th(["color"], { bold: true, mb: 1, ...styleProps })}>
    {children}
  </Text2>
));

// Layout
export const Row = ({ children, ...styleProps }) => (
  <Div display="row.flex-start.center" {...os(styleProps)} {...wsp(styleProps)}>
    {children}
  </Div>
);

export const Column = ({ children, ...styleProps }) => (
  <Div display="column.flex-start.stretch" {...os(styleProps)}>
    {children}
  </Div>
);

export const Box = ({ children, direction = "row", ...styleProps }) =>
  direction === "row" ? (
    <Row {...styleProps}>{children}</Row>
  ) : (
    <Column {...styleProps}>{children}</Column>
  );
