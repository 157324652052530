import React from "react";
import { Page, Title } from "EventLight/Common/FieldLayout";
import ItemCatalogInventory from "Event/ItemCatalogInventory/View";

const Layout = () => {
  return (
    <Page>
      <Title
        icon="assignment_turned_in"
        title="Inventory"
        description="Manage your available inventory"
      />
      <ItemCatalogInventory />
    </Page>
  );
};

export default Layout;
