import * as R from "ramda";
import React from "react";
import PropTypes from "prop-types";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { connect } from "react-redux";
import { createContext } from "redux-mvc";
import * as FormSelectors from "redux/modules/formsV2/form/selectors";
import HeaderContent from "./HeaderContent/HeaderContent";
import { Div, PeopleIcon, PersonIcon, StarIcon } from "components/Base";
import TypeFieldComponent from "./TypeFieldComponent/TypeFieldComponent";
import module from "./index";
import { noop } from "utils/General";
const decorate = R.compose(
  createContext({
    module
  }),
  connect((state, props) => ({
    formData: FormSelectors.form(state, props)
  })),
  CSSModules(css)
);

const SimpleFormInputWrapper = ({
  children = "",
  isValid = true,
  formData = null,
  fieldData = null,
  isShown = false,
  setIsShown = noop,
  connectDragSource = noop
}) => {
  const TypeComponent = ({ withName = false }) => {
    const isPeopleOrPersonType = fieldData?.settings
      ? Object.keys(fieldData.settings).length &&
        fieldData.settings?.isReferenceField
      : false;

    if (formData.scope === "account" && isPeopleOrPersonType) {
      return (
        <TypeFieldComponent
          labelTxt={withName ? fieldData.name : fieldData.type}
          Icon={PeopleIcon}
          color={"purple9"}
        />
      );
    }

    if (formData.scope === "contact" && isPeopleOrPersonType) {
      return (
        <TypeFieldComponent
          labelTxt={withName ? fieldData.name : fieldData.type}
          Icon={PersonIcon}
          color={"purple9"}
        />
      );
    }

    if (!isPeopleOrPersonType && fieldData) {
      return (
        <TypeFieldComponent
          labelTxt={withName ? fieldData.name : fieldData.type}
          Icon={StarIcon}
          color={"altB5"}
        />
      );
    }
    return null;
  };
  return (
    <Div
      data-section-error={!isValid}
      onClick={setIsShown}
      bc={isShown ? { default: "neutral4" } : { default: "neutral0" }}
      ba={isShown ? 2 : 0}
    >
      {isShown && (
        <Div
          className="header"
          onMouseDown={e => e.stopPropagation()}
          ba={isShown ? 2 : 0}
          bc={isShown ? { default: "neutral4" } : { default: "neutral0" }}
          bg={isShown ? { default: "neutral4" } : { default: "neutral0" }}
        >
          <HeaderContent
            TypeComponent={TypeComponent}
            fieldData={fieldData}
            connectDragSource={connectDragSource}
          />
        </Div>
      )}

      <Div p={5}>{children}</Div>

      {!isShown && (
        <Div display="row.flex-end.flex-start">
          <TypeComponent />
        </Div>
      )}
    </Div>
  );
};

SimpleFormInputWrapper.propTypes = {
  isEditing: PropTypes.bool,
  isValid: PropTypes.bool,
  disabled: PropTypes.bool,
  errorMessages: PropTypes.array,
  formData: PropTypes.object,
  fieldData: PropTypes.object,
  setIsShown: PropTypes.bool
};

export default decorate(SimpleFormInputWrapper);
