import React from "react";
import { connect } from "react-redux";

import { ItemCard } from "Sponsors/Common/View";
import { Text3 } from "components/Base";
import { addS } from "utils/General";

import { VARIANTS } from "ui-kit/Theme";
import { actions } from "EventLight/Expo/Sales/model";

const decorate = connect(
  null,
  {
    showSelectItemsToShow: actions.setSelectingItemsToShow
  }
);

const packagesMessage = (visible, label) => {
  if (visible) {
    return `${visible} ${label}${addS(visible)} `;
  }

  return `No ${label}${addS(visible)} `;
};

const PackageCard = ({ id, name = "", visible = 0, label = "package", showSelectItemsToShow, ...styleProps }) => (
  <ItemCard
    title={name}
    caption={
      <>
        <Text3 bold mr={1}>
          {packagesMessage(visible, label)}
        </Text3>
        <Text3>will show</Text3>
      </>
    }
    primaryText={visible ? "Edit" : "Select"}
    primaryAction={() => showSelectItemsToShow(id)}
    {...styleProps}
    variant={VARIANTS.BACKGROUND}
  />
);

export default decorate(PackageCard);
