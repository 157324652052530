import * as R from "ramda";
import React from "react";

import countries from "world-countries";

import { getValue } from "../../utils";

const options = R.indexBy(
  R.prop("id"),
  R.map(
    country => ({
      id: country.ccn3,
      label: country.name.common
    }),
    countries
  )
);

const Countries = props => {
  const id = getValue(props);
  const selectedOption = R.propOr({}, id, options);
  return <div>{selectedOption.label}</div>;
};

export default Countries;
