import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const NotFound = () => (
  <div styleName="container">
    <div>
      <i className="material-icons">error_outline</i>
    </div>
    <h1>Oops!</h1>
    <h3>
      The page you are trying to access does not exist.{" "}
      <a href={`${window.__LENND_APP_URL__}/home`}>Click here</a> to return to
      your events.
    </h3>
  </div>
);

export default CSSModules(NotFound, css);
