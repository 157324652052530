import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import {
  getSelectedTasks,
  getEmptyTaskText
} from "Event/TasksWidget/selectors";
import { actions } from "Event/TasksWidget/model";
import { Div, BigFilledButton, AddIcon } from "components/Base";
import Task from "./Task";

const decorate = connect(
  state => ({
    tasks: getSelectedTasks(state),
    emptyText: getEmptyTaskText(state)
  }),
  {
    goToMyTasks: actions.goToMyTasks,
    goToSchedule: actions.goToSchedule,
    handleShowAddRecordModal: actions.handleShowAddRecordModal
  }
);

const Body = ({
  tasks,
  goToMyTasks,
  emptyText,
  goToSchedule,
  handleShowAddRecordModal
}) => (
  <Div px={3} pb={2} style={{ position: "relative" }}>
    <Div
      width={42}
      height={37}
      display="row.center.center"
      bg={{ default: "primary7", hover: "primary8" }}
      style={{
        position: "absolute",
        top: "-21px",
        right: "15px",
        borderRadius: 20
      }}
      transition="fast"
      onClick={handleShowAddRecordModal}
    >
      <AddIcon color="white" size={24} />
    </Div>
    {R.length(tasks) ? (
      <Div py={2}>
        {R.map(
          task => (
            <Task
              key={task.id}
              task={task}
              goToSchedule={() => goToSchedule(task.schedule_id)}
            />
          ),
          tasks
        )}
      </Div>
    ) : (
      <Div p={4} color="gray7" fw={2} width={1}>
        {emptyText}
      </Div>
    )}
    {R.length(tasks) ? (
      <BigFilledButton
        color="primary7"
        bg="neutral0"
        width={1}
        onClick={goToMyTasks}
      >
        View all tasks
      </BigFilledButton>
    ) : null}
  </Div>
);

export default decorate(Body);
