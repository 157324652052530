import * as R from "ramda";
import { createContext } from "redux-mvc";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import dashboard from "EventLight/Common/Dashboard";
import DashboardView, {
  contextConfig,
  getDashboardProps
} from "EventLight/Common/Dashboard/View";

const decorate = R.compose(
  getDashboardProps({
    moduleId: STANDARD_MODULE_IDS.healthPass.id,
    showAddRecordButton: false,
    showDataFeedButton: false,
    showSidebar: false,
    showSelectRowColumn: true,
    showOpenRecordColumn: false,
    showImportButton: false,
    showColumnActions: false,
    enableEditingCells: false,
    emptyStateIcon: "health_and_safety",
    emptyStateTitle: "No one has submitted a questionnaire yet",
    emptyStateDescription:
      "To get started, send out Health Pass questionnaires to your attendees and configure how frequently they need to fill it out"
  }),
  createContext({ module: dashboard, ...contextConfig })
);

export default decorate(DashboardView);
