import * as R from "ramda";
import { createSelector } from "reselect";

import { WIZARD_PAGE, BUTTON_TYPES } from "Orders/ImportModal/constants";
import { getters } from "Orders/ImportModal";

import { getCustomizeDownloadCaption } from "./customizeDownload";
import { getBulkImportCaption } from "./selectItems";
import { getCountOfRowsWithErrors, isThereAnyValidRow } from "./invalidRows";

const mapTitle = {
  [WIZARD_PAGE.IMPORT]: "Import",
  [WIZARD_PAGE.BULK_IMPORT]: "Import",
  [WIZARD_PAGE.CUSTOMIZE]: "Customize your Import Template CSV",
  [WIZARD_PAGE.REVIEW]: "Review Import",
  [WIZARD_PAGE.CONFIRM]: "Import Confirmation"
};

const mapTitleWithErrors = {
  [WIZARD_PAGE.REVIEW]:
    "Import cannot be processed. Please fix the issues below in your .csv file."
};

export const getPageTitle = createSelector(
  getters.wizardPage,
  getCountOfRowsWithErrors,
  (currentPage, countOfErrors) => {
    if (countOfErrors === 0) {
      return R.propOr(mapTitle.default, currentPage)(mapTitle);
    }

    return R.propOr(mapTitle.default, currentPage)(mapTitleWithErrors);
  }
);

const pagesWithFooter = [
  WIZARD_PAGE.BULK_IMPORT,
  WIZARD_PAGE.CONFIRM,
  WIZARD_PAGE.REVIEW,
  WIZARD_PAGE.CUSTOMIZE
];

export const getShowFooter = R.compose(
  R.contains(R.__, pagesWithFooter), //eslint-disable-line no-underscore-dangle
  getters.wizardPage
);

const getButtonMap = ({ errors, isThereAnyValidRow }) => ({
  [WIZARD_PAGE.BULK_IMPORT]: [
    BUTTON_TYPES.CAPTION,
    [BUTTON_TYPES.CANCEL, BUTTON_TYPES.REVIEW]
  ],
  [WIZARD_PAGE.REVIEW]: [
    BUTTON_TYPES.BACK,
    [
      BUTTON_TYPES.CANCEL,
      errors ? BUTTON_TYPES.RE_UPLOAD_RESOLVED : undefined,
      isThereAnyValidRow ? BUTTON_TYPES.IMPORT_ALL : undefined
    ].filter(b => b)
  ],
  [WIZARD_PAGE.CUSTOMIZE]: [
    BUTTON_TYPES.CAPTION,
    [BUTTON_TYPES.CANCEL, BUTTON_TYPES.DOWNLOAD]
  ],
  [WIZARD_PAGE.CONFIRM]: [
    BUTTON_TYPES.CAPTION,
    [
      errors ? BUTTON_TYPES.UPLOAD_RESOLVED : undefined,
      BUTTON_TYPES.DONE
    ].filter(b => b)
  ]
});

export const getFooterButtons = createSelector(
  getters.wizardPage,
  getCountOfRowsWithErrors,
  isThereAnyValidRow,
  (wizardPage, countOfErrors, isThereAnyValidRow) =>
    R.propOr([], wizardPage)(
      getButtonMap({ errors: countOfErrors, isThereAnyValidRow })
    )
);

export const getFooterCaption = createSelector(
  getters.wizardPage,
  getCustomizeDownloadCaption,
  getBulkImportCaption,
  (page, customizeCaption, bulkImportCaption) => {
    switch (page) {
      case WIZARD_PAGE.CUSTOMIZE:
        return customizeCaption;
      case WIZARD_PAGE.BULK_IMPORT:
        return bulkImportCaption;
      case WIZARD_PAGE.CONFIRM:
        return "";
      default:
        return "implement caption";
    }
  }
); // @NOTE to be implemented

export {
  getFilterEnabledGroups,
  getMappedFields,
  getBulkImportCaption,
  getMessages,
  getPreviewList,
  getImportSettings,
  getFieldsBySection,
  getEnabledFieldsBySection
} from "./selectItems";
export { getConfirmationCounters } from "./reviewImport";
export {
  getCustomEnabledSections,
  getActiveFieldsToSelect,
  getSelectedFieldsTitle,
  getSectionFieldsToDownload,
  getCustomOrderType,
  getIniEnabledCustomFields,
  isContactsOrAccountsModule,
  isContactsModule
} from "./customizeDownload";

export {
  getInvalidFields,
  getCountOfRowsWithErrors,
  getFieldsWithErrors,
  isThereAnyValidRow,
  getFields // @TODO rename to a more semantic selector
} from "./invalidRows";
