import {
  put,
  takeEvery,
  all,
  fork,
  select,
  take,
  call
} from "redux-saga/effects";

import * as R from "ramda";

import { actions, getters } from "./model";

import { PAGES, FIELDS } from "./constants";

import {
  actions as FormActions,
  getters as FormGetters
} from "ui-kit/Form/model";

import moment from "moment";

import { setLocalZone } from "utils/General";

import { registerError } from "redux/modules/errors/actions";

import { apiCall } from "App/Data/sagas";

import { push } from "react-router-redux";

const save = function*() {
  try {
    const fields = yield select(FormGetters.fields);
    const typeOfEvents = yield select(getters.typeOfEvents);
    const email1 = R.pathOr("", [FIELDS.COLLABORATOR_EMAIL_1, "value"], fields);
    const email2 = R.pathOr("", [FIELDS.COLLABORATOR_EMAIL_2, "value"], fields);
    const email3 = R.pathOr("", [FIELDS.COLLABORATOR_EMAIL_3, "value"], fields);

    const data = {
      [FIELDS.DESCRIBE_ORGANIZATION]: R.pathOr(
        "",
        [FIELDS.DESCRIBE_ORGANIZATION, "value", 0],
        fields
      ),
      [FIELDS.OFTEN_HOST]: R.pathOr(
        "",
        [FIELDS.OFTEN_HOST, "value", 0],
        fields
      ),
      typeOfEvents,
      [FIELDS.ORGANIZATION_NAME]: R.pathOr(
        "",
        [FIELDS.ORGANIZATION_NAME, "value"],
        fields
      ),
      collaboratorEmails: [
        ...(R.isEmpty(email1) ? [] : [email1]),
        ...(R.isEmpty(email2) ? [] : [email2]),
        ...(R.isEmpty(email3) ? [] : [email3])
      ],
      [FIELDS.EVENT_NAME]: R.pathOr("", [FIELDS.EVENT_NAME, "value"], fields),
      [FIELDS.EVENT_START_AT]: R.pathOr(
        "",
        [FIELDS.EVENT_START_AT, "value"],
        fields
      ),
      [FIELDS.EVENT_END_AT]: R.pathOr(
        "",
        [FIELDS.EVENT_END_AT, "value"],
        fields
      ),
      [FIELDS.EVENT_TIMEZONE]: R.pathOr(
        "",
        [FIELDS.EVENT_TIMEZONE, "value", 0],
        fields
      )
    };

    const {
      payload: { organization_id }
    } = yield call(apiCall, {
      method: "post",
      url: `/signup/onboard`,
      data
    });

    yield put(push(`/org-light/${organization_id}/dashboard?onboarded=true`));
  } catch (e) {
    yield put(
      registerError([
        {
          system: e,
          user: "An error occurred when sending onboarding"
        }
      ])
    );
  }
};

const nextBtnActions = function*() {
  const page = yield select(getters.page);

  if (page === PAGES.ABOUT) {
    // yield put(actions.setPage(PAGES.ORGANIZATION));
    yield put(actions.setPage(PAGES.EVENT));
  } else if (page === PAGES.ORGANIZATION) {
    yield put(actions.setPage(PAGES.EVENT));
  } else if (page === PAGES.EVENT) {
    yield call(save);
  }
};

const fieldUpdate = function*(fieldId) {
  const fields = yield select(FormGetters.fields);
  const startAt = R.pathOr(null, [FIELDS.EVENT_START_AT, "value"], fields);
  const endAt = R.pathOr(null, [FIELDS.EVENT_END_AT, "value"], fields);
  const timezone = R.pathOr("", [FIELDS.EVENT_TIMEZONE, "value", 0], fields);
  const today = moment()
    .startOf("day")
    .tz(timezone)
    .utc();

  if (fieldId === FIELDS.EVENT_START_AT) {
    const dateStart = moment(startAt).tz(timezone);
    if (R.isNil(endAt)) {
      const endDate = dateStart
        .clone()
        .add(12, "hours")
        .utc();
      yield put(
        FormActions.setFieldValue(setLocalZone(endDate.format(), timezone), {
          meta: {
            fieldId: FIELDS.EVENT_END_AT
          }
        })
      );
    } else {
      const dateStart = moment(startAt).tz(timezone);
      const endDate = moment(endAt).tz(timezone);

      if (endDate.isBefore(dateStart)) {
        const updatedDate = dateStart
          .clone()
          .add(12, "hours")
          .utc();
        yield put(
          FormActions.setFieldValue(
            setLocalZone(updatedDate.format(), timezone),
            {
              meta: {
                fieldId: FIELDS.EVENT_END_AT
              }
            }
          )
        );
      }
    }
  } else if (fieldId === FIELDS.EVENT_END_AT) {
    if (R.isNil(startAt)) {
      const endDate = moment(endAt).tz(timezone);

      const dateStart = endDate
        .clone()
        .subtract(12, "hours")
        .utc();

      const updatedDate = dateStart.isBefore(today) ? today : dateStart;
      yield put(
        FormActions.setFieldValue(
          setLocalZone(updatedDate.format(), timezone),
          {
            meta: {
              fieldId: FIELDS.EVENT_START_AT
            }
          }
        )
      );
    } else {
      const dateStart = moment(startAt).tz(timezone);
      const endDate = moment(endAt).tz(timezone);

      if (endDate.isBefore(dateStart)) {
        const newStartDate = endDate
          .clone()
          .subtract(12, "hours")
          .utc();

        const updatedDate = newStartDate.isBefore(today) ? today : newStartDate;
        yield put(
          FormActions.setFieldValue(
            setLocalZone(updatedDate.format(), timezone),
            {
              meta: {
                fieldId: FIELDS.EVENT_START_AT
              }
            }
          )
        );
      }
    }
  }
};

const watchNextBtnActions = function*() {
  yield takeEvery(actions.nextBtnActions.type, nextBtnActions);
};

const watchUpdateFields = function*() {
  for (;;) {
    const {
      meta: { fieldId }
    } = yield take(FormActions.setFieldValue.type);
    if (R.contains(fieldId, [FIELDS.EVENT_START_AT, FIELDS.EVENT_END_AT])) {
      yield call(fieldUpdate, fieldId);
    }
  }
};

const rootSaga = function*() {
  yield all([fork(watchNextBtnActions), fork(watchUpdateFields)]);
};

export default rootSaga;
