import { createSelector } from "reselect";
import * as R from "ramda";

import { getters } from "./model";
import * as FormSelectors from "ui-kit/Form/selectors";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";

export const getSelectedItems = createSelector(
  getters.content,
  R.map(R.prop("item_id"))
);

export const getItemGroups = createSelector(
  getters.itemTypes,
  itemTypes =>
    R.compose(
      R.map(group => {
        return {
          ...group,
          items: R.map(item => ({
            id: item.id,
            name: item.name
          }))(group.items)
          /*
          For Variants:
          R.compose(
            R.flatten,
            R.map(item => {
              return item.variants.map(v => ({
                id: v.id,
                name: v.display_name
              }));
            })
          )(group.items)
          */
        };
      }),
      R.propOr([], "groups"),
      R.head,
      R.filter(R.propEq("id", CREDENTIAL_TYPE_ID))
    )(itemTypes)
);

export const getIsValid = createSelector(
  getters.data,
  data => {
    if (!data.title || !data.title.length || !data.url || !data.url.length) {
      return false;
    }

    return true;
  }
);

export const getEditedField = R.compose(
  field => R.prop("value", field),
  FormSelectors.getField
);
