import PropTypes from "prop-types";
import * as R from "ramda";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import RaisedButton from "components/Atoms/RaisedButton";

import {
  getHandlers,
  getMeta,
  getRow,
  getIsRowEmpty
} from "ui-kit/Datagrid/utils";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { noop } from "utils/General";

// @TODO refactor
@CSSModules(css)
class FormRowApprovalCellFormatter extends Component {
  formatValue(value) {
    return R.propOr("", "value", value);
  }

  @autobind
  validateApproveRow() {
    const readOnly = getMeta(this.props, "readOnly");
    const showAdminChangeDialog = getHandlers(
      this.props,
      "showAdminChangeDialog",
      noop
    );
    const showAdminUndoDialog = getHandlers(
      this.props,
      "showAdminUndoDialog",
      noop
    );
    if (!readOnly) {
      // if we're not reverting back to 'pending' state, check if we should show
      // the changes dialog
      if (this.formatValue(this.props.value) !== "approved") {
        showAdminChangeDialog(
          getMeta(this.props, "submissionId"),
          this.approveRow
        );
      } else {
        showAdminUndoDialog(
          getMeta(this.props, "submissionId"),
          this.approveRow
        );
      }
    }
  }

  @autobind
  approveRow() {
    const row = getRow(this.props);
    const meta = getMeta(this.props);
    const createReview = getHandlers(this.props, "createReview", noop);

    createReview(
      {
        eventId: meta.eventId,
        formId: meta.parentId,
        subformId: meta.formId,
        submissionId: row.id,
        status:
          this.formatValue(this.props.value) === "approved"
            ? "pending"
            : "approved"
      },
      this.props.row
    );
  }

  @autobind
  validateRejectRow() {
    const meta = getMeta(this.props);

    const showAdminChangeDialog = getHandlers(
      this.props,
      "showAdminChangeDialog",
      noop
    );
    if (!meta.readOnly) {
      // if we're not reverting back to 'pending' state, check if we should show
      // the changes dialog
      if (this.formatValue(this.props.value) !== "rejected") {
        showAdminChangeDialog(meta.submissionId, this.rejectRow);
      } else {
        this.rejectRow();
      }
    }
  }

  @autobind
  rejectRow() {
    const row = getRow(this.props);
    const meta = getMeta(this.props);
    const createReview = getHandlers(this.props, "createReview", noop);

    createReview(
      {
        subformId: meta.formId,
        submissionId: row.id,
        status:
          this.formatValue(this.props.value) === "rejected"
            ? "pending"
            : "rejected"
      },
      this.props.row
    );
  }

  render() {
    const row = getRow(this.props);
    const meta = getMeta(this.props);

    const isRowEmpty = getIsRowEmpty(this.props);

    if (isRowEmpty) {
      return <div styleName="emptyWrapper">Empty</div>;
    }

    const value = this.formatValue(this.props.value);

    let action;

    switch (value) {
      case "approved":
        action = (
          <div styleName="wrapper">
            <RaisedButton
              action={this.validateApproveRow}
              label={
                <div className={css.buttonWrapper}>
                  <i className={["material-icons", css.buttonIcon].join(" ")}>
                    &#xE5CA;
                  </i>{" "}
                  Approved
                </div>
              }
              className={meta.readOnly ? css.disabledApproved : css.approved}
            />
          </div>
        );
        break;
      case "rejected":
        action = (
          <div styleName="wrapper">
            <RaisedButton
              action={this.validateRejectRow}
              label={
                <div className={css.buttonWrapper}>
                  <i className={["material-icons", css.buttonIcon].join(" ")}>
                    &#xE5CD;
                  </i>{" "}
                  Rejected
                </div>
              }
              className={meta.readOnly ? css.disabledRejected : css.rejected}
            />
          </div>
        );
        break;
      default:
        if (meta.readOnly) {
          return <div styleName="emptyWrapper">Pending</div>;
        }
        action = (
          <div styleName="wrapper">
            <RaisedButton
              action={this.validateApproveRow}
              label={<i className="material-icons">&#xE5CA;</i>}
              className={css.button}
            />
            <RaisedButton
              action={this.validateRejectRow}
              label={<i className="material-icons">&#xE5CD;</i>}
              className={css.button}
            />
          </div>
        );
    }

    return action;
  }
}

FormRowApprovalCellFormatter.propTypes = {
  value: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired
};

export default FormRowApprovalCellFormatter;
