import React from "react";
import { connect } from "react-redux";
import { getFilteredMeals } from "Orders/CreateOrderModal/SelectItems/selectors";
import { Div, BigNumberSelector, SmallCheckbox } from "components/Base";
import * as R from "ramda";
const mapWithIndex = R.addIndex(R.map);

const MODE_SINGLE_SELECT = "single-select";
const MODE_MULTIPLE_SELECT = "multiple-select";
const MODE_QUANTITY = "quantity";
const DEFAULT_MODE = MODE_QUANTITY;

const decorate = connect((state, props) => ({
  filteredMeals: getFilteredMeals(state, props)
}));

const Column = ({ children, width = 150, alt, ...props }) => (
  <Div
    display="column"
    width={width}
    px={3}
    py={3}
    bg={alt ? "gray1" : undefined}
    {...props}
  >
    {children}
  </Div>
);

const HeaderCell = ({ children }) => (
  <Div height={40} mb={3} flex={1}>
    {children}
  </Div>
);

const ValueCell = ({ children, ...props }) => (
  <Div height={60} display="row.center.center" {...props}>
    {children}
  </Div>
);

const View = ({ filteredMeals, days, mode = DEFAULT_MODE }) => {
  return (
    <Div display="row.flex-start.flex-start">
      <Column width={75}>
        <HeaderCell />
        {R.map(day => (
          <ValueCell key={day.id} display="row.flex-start.center">
            <Div
              width={4}
              height={40}
              style={{
                backgroundColor: day.color || "#ccc"
              }}
              mr={1}
            />
            <Div display="column">
              <Div uppercase color="gray6" fw={3} noLineHeight>
                {day.dayName}
              </Div>
              <Div fs={6} color="gray6" noLineHeight>
                {day.dayNumber}
              </Div>
            </Div>
          </ValueCell>
        ))(days)}
      </Column>
      {mapWithIndex((meal, idx) => (
        <Div key={meal.id}>
          <Column alt={idx % 2 === 0}>
            <HeaderCell>
              <Div
                color="gray7"
                fw={3}
                fs={4}
                noLineHeight
                flex={1}
                truncate
                textAlign="center"
                title={meal.name}
              >
                {meal.name}
              </Div>
              <Div display="row.center.center" fs={1} color="gray6">
                <Div onClick={meal.selectAllItems} underline mr={2}>
                  All
                </Div>
                <Div onClick={meal.deselectAllItems} underline>
                  None
                </Div>
              </Div>
            </HeaderCell>

            {R.map(day => {
              const mealDay = day.meals[meal.id];
              return (
                <ValueCell key={day.id}>
                  {mealDay && mealDay.show
                    ? R.prop(mode, {
                        [MODE_QUANTITY]: (
                          <BigNumberSelector
                            value={mealDay.qty}
                            onChangeValue={mealDay.updateVariantQuantity}
                          />
                        ),
                        [MODE_SINGLE_SELECT]: (
                          <SmallCheckbox
                            selected={Boolean(mealDay.qty)}
                            onClick={mealDay.updateVariantQuantity}
                          />
                        ),
                        [MODE_MULTIPLE_SELECT]: (
                          <SmallCheckbox
                            selected={Boolean(mealDay.qty)}
                            onClick={mealDay.updateVariantQuantity}
                          />
                        )
                      })
                    : null}
                </ValueCell>
              );
            })(days)}
          </Column>
        </Div>
      ))(filteredMeals)}
    </Div>
  );
};

export default decorate(View);
