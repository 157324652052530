import React from "react";
import { Div } from "components/Base";
import {
  CONTENT_WIDTH,
  LEFT_COLUMN_WIDTH,
  RIGHT_COLUMN_WIDTH
} from "Organizations/Home/constants";
import AppsContainer from "./AppsContainer";
import MyTasks from "./MyTasks";
import EventsList from "./EventsList";

const Body = () => (
  <Div display="column.flex-start.center" style={{ marginTop: -28 }}>
    <Div width={CONTENT_WIDTH} display="row.space-between.flex-start">
      <Div width={RIGHT_COLUMN_WIDTH}>
        <EventsList />
        <MyTasks />
      </Div>
      <Div width={LEFT_COLUMN_WIDTH}>
        {/* @NOTE: commented temporaly until data get connected <MyApprovals /> */}
        <AppsContainer />
      </Div>
    </Div>
  </Div>
);

export default Body;
