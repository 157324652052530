import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AssignForms from "./AssignForms";

import * as EventSelectors from "redux/modules/event/selectors";
import * as FormsSelectors from "redux/modules/formsV2/forms/selectors";
import * as ContactAssignedFormsSelectors from "redux/modules/contacts/assignedForms/selectors";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { getForms } from "redux/modules/formsV2/forms/actions";
import {
  getAssignedForms,
  addAssignedForms,
  deleteAssignedForms
} from "redux/modules/contacts/assignedForms/actions";

function mapStateToProps(state) {
  return {
    eventDetails: EventSelectors.eventDetails(state),
    isFetchingAssignedForms: ContactAssignedFormsSelectors.isFetching(state),
    isFetchingForms: FormsSelectors.isFetching(state),
    assignedForms: ContactAssignedFormsSelectors.assignedForms(state).reduce(
      (a, b) => {
        a[b.id] = b;
        return a;
      },
      {}
    ),
    forms: FormsSelectors.forms(state)
      .filter(f => {
        return f.scope !== "account";
      })
      .map(f => ({
        ...f,
        name: f.name,
        search: f.name.toLowerCase()
      }))
      .reduce((a, b) => {
        a[b.id] = b;
        return a;
      }, {})
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSnackbar,
      getForms,
      getAssignedForms,
      addAssignedForms,
      deleteAssignedForms
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignForms);
