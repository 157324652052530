import { BEGIN, COMMIT } from "redux-optimist";
import {
  ADD,
  ADD_COMPLETE,
  ADD_BULK,
  ADD_BULK_COMPLETE,
  REMOVE,
  REMOVE_BULK,
  REMOVE_COMPLETE
} from "./constants";
import api from "redux/modules/orders/line-items/api";
import { registerError } from "redux/modules/errors/actions";
import uuid from "node-uuid";

// @TODO: Add bulk support
export function addItemToCart(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: ADD,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.post(getState().user.user.credentials, data);
      dispatch({
        type: ADD_COMPLETE,
        payload: {
          ...data,
          lineItem: {
            ...result.payload,
            id: result.payload.id,
            ...data.lineItem
          }
        },
        optimist: { type: COMMIT, id: transactionId }
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding item to cart"
          }
        ])
      );
    }
    return true;
  };
}

// @TODO: Add bulk support
export function addItemsToCart(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: ADD_BULK,
      payload: { ...data, fetchingBulk: true },
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.post(getState().user.user.credentials, {
        bulk: true,
        ...data
      });
      dispatch({
        type: ADD_BULK_COMPLETE,
        payload: {
          ...data,
          lineItems: result.payload.map((li, idx) => ({
            ...li,
            id: li.id,
            ...data.lineItems[idx]
          })),
          fetchingBulk: false
        },
        optimist: { type: COMMIT, id: transactionId }
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding items to cart"
          }
        ])
      );
    }
    return true;
  };
}

export function removeItemFromCart(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: REMOVE,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.delete(getState().user.user.credentials, data);
      dispatch({
        type: REMOVE_COMPLETE,
        payload: result.payload,
        optimist: { type: COMMIT, id: transactionId }
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred removing item from cart"
          }
        ])
      );
    }
    return true;
  };
}

export function removeItemsFromCart(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: REMOVE_BULK,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.delete(getState().user.user.credentials, {
        bulk: true,
        ...data
      });
      dispatch({
        type: REMOVE_COMPLETE,
        payload: result.payload,
        optimist: { type: COMMIT, id: transactionId }
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred removing items from cart"
          }
        ])
      );
    }
    return true;
  };
}
