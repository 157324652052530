import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import EditTemplateModal from "./EditTemplateModal";
import { withRouter } from "react-router";

import { getModules } from "redux/modules/modules/modules/actions";
import { getEmailMergeTags } from "redux/modules/event/email-merge-tags/actions";
import { modules } from "redux/modules/modules/modules/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import { mergeTags } from "redux/modules/event/email-merge-tags/selectors";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

function mapStateToProps(state, props) {
  return {
    modules: props.params.orgId
      ? [
          {
            id: STANDARD_MODULE_IDS.accounts.id,
            name: "Groups"
          },
          {
            id: STANDARD_MODULE_IDS.contacts.id,
            name: "People"
          }
        ]
      : modules(state),
    eventDetails: eventDetails(state),
    mergeTags: mergeTags(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getModules,
      getEmailMergeTags
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditTemplateModal)
);
