import React from "react";
import EditorBase from "./TableConnector";
import DependencyEditor from "components/Global/Editors/DependencyEditor";

import { ep, getRowMetadata } from "ui-kit/Datagrid/utils";

export default class DependencyEditorEditor extends EditorBase {
  render() {
    const rowMetaData = getRowMetadata(ep(this.props));
    const { column } = this.props;

    return (
      <DependencyEditor
        column={column}
        rowMetaData={rowMetaData}
        style={{
          width: "100%",
          height: 35
        }}
        value={this.state}
        onChange={this.handleChange}
      />
    );
  }
}
