import React from "react";
import { connect } from "react-redux";
import { Div } from "components/Base";
import * as R from "ramda";
import { withRouter } from "react-router";
import { getters } from "../model";
import PaginationBar from "ui-kit/PaginationBar/View/Slim";
import { actions as PortalPagePGAActions } from "Portal/PortalPagePGA/PortalPagePGA/model";
import { PAGE_OPTIONS } from "../constants";
import Filters from "./Filters";
import moment from "moment";
import Loading from "ui-kit/Loading";

import {
  getVideosTitle,
  getVideosSubtitle
} from "Portal/PortalPagePGA/PortalPagePGA/selectors";

const decorator = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      pagination: getters.pagination(state, props),
      videos: getters.videos(state, props),
      loading: getters.loading(state, props),
      videosTitle: getVideosTitle(state),
      videosSubtitle: getVideosSubtitle(state)
    }),
    {
      showVideoModal: PortalPagePGAActions.showVideoModal
    }
  )
);

const Body = ({
  loading,
  pagination,
  videos,
  showVideoModal,
  videosTitle,
  videosSubtitle,
  ...otherProps
}) => (
  <Div
    display="column.flex-start.flex-start"
    {...otherProps}
    style={{
      maxWidth: "1080px",
      margin: "0 auto",
      marginTop: "27px"
    }}
  >
    <Div
      style={{
        color: "#222222",
        fontFamily: "Roboto",
        fontSize: "24px",
        fontWeight: "bold",
        letterSpacing: 0
      }}
    >
      {videosTitle}
    </Div>
    <Div
      display="row"
      fs={2}
      color="gray5"
      mt={1}
      italics
      style={{
        fontFamily: "'Roboto', sans-serif",
        letterSpacing: "-0.15px"
      }}
      pb={5}
    >
      {videosSubtitle}
    </Div>

    <Filters />
    <Div
      width={1}
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between"
      }}
    >
      {loading ? (
        <Div width={1} mt={5} display="row.center.center">
          <Loading />
        </Div>
      ) : (
        R.map(
          video => (
            <Div
              key={video.id}
              width={0.32}
              mb={10}
              style={{ cursor: "pointer", borderRadius: "5px" }}
              onClick={() => showVideoModal(video)}
              bg={{
                default: "#f7f7f7",
                hover: "#ececec"
              }}
            >
              <Div
                width={1}
                height={150}
                position="relative"
                style={{
                  borderRadius: "5px 5px 0 0",
                  backgroundImage: video.thumbnail_image_url
                    ? `url(${video.thumbnail_image_url})`
                    : undefined,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  backgroundSize: "cover"
                }}
              >
                <img
                  src="https://d2dks4tzxs6xee.cloudfront.net/img/pga/play-ico.svg"
                  alt="Play Icon"
                  width={40}
                  height={40}
                  style={{
                    position: "absolute",
                    left: "25px",
                    bottom: "20px"
                  }}
                />
              </Div>
              <Div style={{ padding: "20px" }}>
                <Div display="row.space-between.center" mb={3}>
                  <Div
                    uppercase
                    style={{
                      color: "#686868",
                      fontFamily: "Roboto Condensed",
                      fontSize: "14px",
                      letterSpacing: "0px"
                    }}
                  >
                    {video.type}
                  </Div>
                  <Div
                    uppercase
                    style={{
                      color: "#686868",
                      fontFamily: "Roboto Condensed",
                      fontSize: "14px",
                      letterSpacing: "0px"
                    }}
                  >
                    {moment(video.date).fromNow()}
                  </Div>
                </Div>
                <Div>
                  <Div
                    fw={4}
                    fs={2}
                    style={{
                      color: "#222222",
                      fontFamily: "Roboto",
                      fontSize: "18px",
                      fontWeight: "bold",
                      letterSpacing: "0px"
                    }}
                  >
                    {video.name}
                  </Div>
                  <Div
                    uppercase
                    style={{
                      color: "#222222",
                      fontFamily: "Roboto",
                      fontSize: "18px",
                      letterSpacing: "0px"
                    }}
                  >
                    {video.label}
                  </Div>
                </Div>
              </Div>
            </Div>
          ),
          videos
        )
      )}
    </Div>
    <Div width={1} display="row.center.center">
      <PaginationBar
        style={{
          width: "100%",
          maxWidth: "580px",
          color: "#333333",
          fontFamily: "Roboto",
          fontSize: "16px",
          letterSpacing: "0px"
        }}
        pageSizeOptions={PAGE_OPTIONS}
        total={pagination.total}
        rowsText={pagination.total === 1 ? "video" : "videos"}
      />
    </Div>
  </Div>
);

export default decorator(Body);
