import * as R from "ramda";
import React, { useEffect } from "react";
import "videojs-record/dist/css/videojs.record.css";
import "video.js/dist/video-js.min.css";

import "video.js";
import "recordrtc";
import "videojs-record/dist/videojs.record.js";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { connect } from "react-redux";

import {
  Div,
  Text2,
  Text3,
  MediumFilledButton,
  MediumOutlineButton
} from "components/Base";
import { noop } from "utils/General";

import { getters, actions } from "../model";
import { isRecorderMode } from "../selectors";

import ProgressBar from "ui-kit/ProgressBar";

const DEFAULT_MAX_VIDEO_LENGTH_SECS = 20;

const videoJsProps = (props = {}) =>
  R.prop("isRecorderMode", props)
    ? {
        controls: true,
        bigPlayButton: false,
        width: R.propOr(320, "width", props),
        height: R.propOr(240, "heigth", props),
        fluid: false,
        plugins: {
          record: {
            audio: true,
            image: false,
            autoMuteDevice: true,
            videoMimeType: "video/webm;codecs=vp8",
            maxLength: R.propOr(
              DEFAULT_MAX_VIDEO_LENGTH_SECS,
              "maxLengthVideo",
              props
            ),
            debug: false,
            video: { width: 1280, height: 720 },
            frameWidth: 1280,
            frameHeight: 720
          }
        }
      }
    : {
        controls: true,
        bigPlayButton: true,
        liveui: false,
        width: R.propOr(320, "width", props),
        height: R.propOr(240, "heigth", props),
        fluid: false,
        sources: R.propOr("", "srcVideo", props)
          ? [
              {
                src: R.propOr("", "srcVideo", props),
                type: "video/mp4"
              }
            ]
          : []
      };

const decorate = R.compose(
  connect(
    (state, props) => ({
      video: getters.video(state, props),
      srcVideo: getters.srcVideo(state, props),
      loadingProgress: getters.loadingProgress(state, props),
      isUploading: getters.isUploading(state, props),
      isRecorderMode: isRecorderMode(state, props)
    }),
    {
      setIniValue: actions.setSrcVideo,
      uploadVideo: actions.uploadVideo,
      importVideo: actions.importVideo,
      clearVideo: actions.clearVideo,
      initVideo: actions.initVideo
    }
  ),
  CSSModules(css)
);

export const Video = ({
  iniValue = null,
  video,
  srcVideo = "",
  setIniValue = noop,
  loadingProgress = 0,
  isUploading = false,
  containerProps = {},
  uploadVideo = noop,
  onChange = noop,
  clearVideo = noop,
  importVideo = noop,
  fieldId,
  width = 320,
  height = 240,
  maxLengthVideo = DEFAULT_MAX_VIDEO_LENGTH_SECS,
  initVideo = noop
}) => {
  useEffect(() => {
    // iniValue will be a video URL
    if (!R.isNil(iniValue)) {
      setIniValue(iniValue);
    }
  }, []);

  useEffect(() => {
    if (iniValue !== srcVideo) {
      onChange(srcVideo);
    }
  }, [srcVideo]);

  useEffect(() => {
    const isRecorderMode = R.isNil(iniValue);
    const videoProps = videoJsProps({
      isRecorderMode,
      width,
      height,
      maxLengthVideo,
      srcVideo: iniValue
    });
    initVideo({
      fieldId: isRecorderMode ? `${fieldId}-recorder` : `${fieldId}-player`,
      videoProps
    });
  }, [iniValue]);
  return (
    <Div display="column.flex-start.stretch" {...containerProps}>
      <div
        id="video-container"
        className="video-container"
        style={{ backgroundColor: "#000", width, height }}
      ></div>
      <Div my={2} display="column.flex-start.stretch">
        {isUploading && (
          <ProgressBar progress={loadingProgress / 100} mb={2} width={width} />
        )}
        <Div display="row.flex-start.center">
          {!R.isNil(video) && R.isNil(srcVideo) && (
            <MediumFilledButton
              color="white"
              bg="success5"
              disabled={R.isNil(video)}
              onClick={isUploading ? undefined : uploadVideo}
            >
              {isUploading ? "Uploading..." : "Use this recording"}
            </MediumFilledButton>
          )}
          {!R.isNil(srcVideo) ? (
            <MediumOutlineButton
              ml={R.isNil(srcVideo) ? 2 : 0}
              onClick={clearVideo}
              disabled={R.isNil(srcVideo)}
            >
              Clear Video
            </MediumOutlineButton>
          ) : null}
        </Div>
      </Div>
      {R.isNil(srcVideo) && (
        <Div display="row.flex-start.center">
          <Text2>To upload a video instead,</Text2>
          <Text3 ml={1} bold onClick={importVideo}>
            click here
          </Text3>
        </Div>
      )}
    </Div>
  );
};

export default decorate(Video);
