import { createContext } from "redux-mvc";
import * as R from "ramda";

import Layout from "./Layout";

import module, { actions } from "Schedules/Create";
import rootSaga from "Schedules/Create/sagas";

module.setRootSaga(rootSaga);

const decorate = createContext({
  module: module,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(
        actions.setRedirectAfterSave(
          R.propOr(true, "redirectAfterSave", this.props)
        )
      );
    }
  },
  options: {
    persist: false,
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: ["user", "event", "accounts", "routing", "portal"]
  }
});

export default decorate(Layout);
