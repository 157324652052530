import PropTypes from "prop-types";
import React, { Component } from "react";
import Editor from "../../Editors/FileUploadEditor";

export default class FileUploadEditor extends Component {
  render() {
    return <Editor {...this.props} />;
  }
}

FileUploadEditor.propTypes = {
  column: PropTypes.object.isRequired
};
