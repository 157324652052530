import {
  put,
  all,
  take,
  takeEvery,
  fork,
  select,
  call
} from "redux-saga/effects";
import * as R from "ramda";

import moment from "moment-timezone";

import { eventId as getEventId } from "redux/modules/event/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";

import { actions, getters } from "./model";
import { registerError } from "redux/modules/errors/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";

import Api from "./api";

import addListOfPermissionSlugs from "redux/modules/permissions/utils/add-list-of-permission-slugs";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventDetails = yield select(getEventDetails);

  return {
    credentials,
    eventDetails
  };
};

const init = function*() {
  try {
    const { credentials, eventDetails } = yield call(getParams);

    yield put(actions.setLoading(true));

    const { payload: eventPermissionGroups } = yield call(
      Api.getPermissionGroups,
      credentials,
      eventDetails.id
    );

    const { payload: eventPermissionProfiles } = yield call(
      Api.getPermissionProfiles,
      credentials,
      eventDetails.id
    );

    const permissionGroups = eventPermissionGroups.map(
      addListOfPermissionSlugs
    );
    const permissionProfiles = eventPermissionProfiles.map(
      addListOfPermissionSlugs
    );

    yield put(
      actions.setInitData({
        permissionGroups,
        permissionProfiles
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting details"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const getEventPermissionProfiles = function*() {
  try {
    const { credentials, eventDetails } = yield call(getParams);

    const { payload: eventPermissionProfiles } = yield call(
      Api.getPermissionProfiles,
      credentials,
      eventDetails.id
    );

    const permissionProfiles = eventPermissionProfiles.map(
      addListOfPermissionSlugs
    );

    yield put(actions.setPermissionProfiles(permissionProfiles));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting details"
        }
      ])
    );
  }
};

const updatePermissionProfilePermissions = function*({ payload: data }) {
  try {
    const { credentials } = yield call(getParams);

    yield call(Api.putPermissions, credentials, data);

    yield call(getEventPermissionProfiles);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting details"
        }
      ])
    );
  }
};

const addEventPermissionProfile = function*({ payload: data }) {
  try {
    const { credentials } = yield call(getParams);

    const { payload } = yield call(Api.post, credentials, data);

    yield call(getEventPermissionProfiles);

    yield put(actions.setActivePermissionProfileId(payload.id));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while adding profile"
        }
      ])
    );
  }
};

const updatePermissionProfile = function*({ payload: data }) {
  try {
    const { credentials } = yield call(getParams);
    yield call(Api.put, credentials, data);
    yield call(getEventPermissionProfiles);
    yield put(showSnackbar({ message: "Profile Updated" }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while updating profile"
        }
      ])
    );
  }
};

const deletePermissionProfile = function*({ payload: data }) {
  try {
    const { credentials } = yield call(getParams);
    yield call(Api.delete, credentials, data);
    yield call(getEventPermissionProfiles);
    yield put(showSnackbar({ message: "Profile Deleted" }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while deleting profile"
        }
      ])
    );
  }
};

const watchUpdatePermissionProfilePermissions = function*() {
  yield takeEvery(
    actions.updatePermissionProfilePermissions.type,
    updatePermissionProfilePermissions
  );
};

const watchGetEventPermissionProfiles = function*() {
  yield takeEvery(
    actions.getEventPermissionProfiles.type,
    getEventPermissionProfiles
  );
};

const watchAddEventPermissionProfile = function*() {
  yield takeEvery(
    actions.addEventPermissionProfile.type,
    addEventPermissionProfile
  );
};

const watchUpdatePermissionProfile = function*() {
  yield takeEvery(
    actions.updatePermissionProfile.type,
    updatePermissionProfile
  );
};

const watchDeletePermissionProfile = function*() {
  yield takeEvery(
    actions.deletePermissionProfile.type,
    deletePermissionProfile
  );
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([
    fork(watchAddEventPermissionProfile),
    fork(watchUpdatePermissionProfile),
    fork(watchDeletePermissionProfile),
    fork(watchUpdatePermissionProfilePermissions),
    fork(watchGetEventPermissionProfiles),
    fork(watchInit)
  ]);
};

export default rootSaga;
