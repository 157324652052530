import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  collapsed: false,
  navigation: {
    links: []
  },
  openLinks: []
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    cancelInstance: R.always(iniState),
    init: R.identity,
    goBack: R.identity,
    goToPreview: R.identity,
    refreshNavigation: R.identity,
    showLogoFilepicker: R.identity,
    showAddEventModal: R.identity,
    toggleCollapse: ({ collapsed }) => ({ collapsed: !collapsed }),
    toggleOpenLink: ({ openLinks }, { payload: linkId }) => ({
      openLinks: openLinks.includes(linkId)
        ? openLinks.filter(id => id !== linkId)
        : [...openLinks, linkId]
    })
  }
});

export default handlers;
