import React, { useEffect } from "react";
import { connect } from "react-redux";

import { actions, getters } from "../model";

import { getSelectedItemsToShow, getItemsToShow } from "../selectors";

import MiniModalWrapper from "ui-kit/MiniModalWrapper";

import { SelectAndCreateVariants } from "ui-kit/MiniItemsSelector/View";
import { MODE_PRICE_SELECT } from "ui-kit/MiniItemsSelector/constants";

import { noop } from "utils/General";

const decorate = connect(
  (state, props) => ({
    iniSelectedFields: getSelectedItemsToShow(state, props),
    showModal: getters.showSelectItemsModal(state, props),
    fields: getItemsToShow(state, props)
  }),
  {
    hideModal: () => actions.setShowSelectItemsModal(false),
    onDone: actions.onSelectItemsToShow,
    init: () => actions.getItemsToShow()
  }
);

const SelectItemsModal = ({
  fields = [],
  iniSelectedFields = [],
  hideModal = noop,
  onDone = noop,
  init = noop,
  showModal = false
}) => {
  useEffect(() => {
    init();
  }, []);
  return (
    <MiniModalWrapper
      showModal={showModal}
      hideModal={() => hideModal()}
      title="Select items to show"
      display="column.flex-start.stretch"
      width={845}
      height={600}
    >
      <SelectAndCreateVariants
        instanceId="selectItems"
        fields={fields}
        iniSelected={iniSelectedFields}
        iniClearSearch={true}
        onCancel={hideModal}
        onDone={onDone}
        mode={MODE_PRICE_SELECT}
        enableReorder={false}
      />
    </MiniModalWrapper>
  );
};

export default decorate(SelectItemsModal);
