const { find } = require("lodash");
const isMatch = require("./is-match");

/* NOTE: This is from services. If you make updates, update it there as well */

exports.filterRecords = (filters, fields, records, meta) => {
  const filtersWithFields = filters.filters.map(filter => ({
    ...filter,
    field: find(fields, { id: filter.fieldId }) || {}
  }));

  // if `expression` = `and`, return TRUE if all filters match
  const and = record => {
    let matches = 0;

    for (let i = 0; i < filtersWithFields.length; i++) {
      if (isMatch(filtersWithFields[i], record, meta)) {
        matches++;
      }
    }

    if (matches === filtersWithFields.length) {
      return true;
    }

    return false;
  };

  // if `expression` = `or`, return TRUE once one filter matches
  const or = record => {
    for (let i = 0; i < filtersWithFields.length; i++) {
      if (isMatch(filtersWithFields[i], record, meta)) {
        return true;
      }
    }

    return false;
  };

  if (filters.expression === "or") {
    return records.filter(or);
  }
  return records.filter(and);
};
