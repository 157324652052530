import {
  RECEIVE_ASSETS_REPORT,
  RECEIVE_CREDENTIALS_REPORT,
  RECEIVE_CATERING_REPORT
} from "./constants";
import { registerError } from "redux/modules/errors/actions";
import api from "./api";

export function getAssetReport({ accountId, eventId }) {
  return async (dispatch, getState) => {
    try {
      const report = await api.get(
        getState().user.user.credentials,
        eventId,
        accountId,
        "assets"
      );
      dispatch({ type: RECEIVE_ASSETS_REPORT, payload: report });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting asset report"
          }
        ])
      );
    }
  };
}

export function getItemAssetReport({ accountId, eventId }) {
  return async (dispatch, getState) => {
    try {
      const report = await api.get(
        getState().user.user.credentials,
        eventId,
        accountId,
        "item-assets"
      );
      dispatch({ type: RECEIVE_ASSETS_REPORT, payload: report });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting asset report"
          }
        ])
      );
    }
  };
}

export function getCredentialsReport({ accountId, eventId }) {
  return async (dispatch, getState) => {
    try {
      const report = await api.get(
        getState().user.user.credentials,
        eventId,
        accountId,
        "credentials"
      );
      dispatch({ type: RECEIVE_CREDENTIALS_REPORT, payload: report });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting credentials report"
          }
        ])
      );
    }
  };
}

export function getCateringReport({ accountId, eventId }) {
  return async (dispatch, getState) => {
    try {
      const report = await api.get(
        getState().user.user.credentials,
        eventId,
        accountId,
        "catering"
      );
      dispatch({ type: RECEIVE_CATERING_REPORT, payload: report });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting catering report"
          }
        ])
      );
    }
  };
}
