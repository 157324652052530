import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getters } from "Event/MyApprovals/model";
import { Div, Text4, ClockIcon } from "components/Base";
import { eventId as getEventId } from "redux/modules/event/selectors";
import Circle from "ui-kit/Circle";
import { getCountOfPendingApprovals } from "Event/MyApprovals/selectors";

const decorate = connect(state => ({
  eventId: getEventId(state),
  countOfPendingApprovals: getCountOfPendingApprovals(state),
  initLoaded: getters.initLoaded(state)
}));

const TempMyApprovals = ({
  eventId,
  countOfPendingApprovals,
  initLoaded,
  router: { push }
}) => {
  return (
    <Div
      bg="white"
      bra={1}
      width={1}
      height={64}
      mt={2}
      display="row.flex-start.center"
      style={{
        flexShrink: 0,
        boxShadow:
          "0px 8px 20px rgba(0, 0, 0, 0.04), 0px 35px 54px rgba(0, 0, 0, 0.01)"
      }}
    >
      <Div
        bg="white"
        height={1}
        display="row.flex-start.center"
        style={{
          width: "100%",
          cursor: "pointer",
          borderRadius: "4px"
        }}
        p={1}
        onClick={() => push(`/event/${eventId}/my-approvals`)}
      >
        <Div
          bg={{ default: "white", hover: "neutral0" }}
          display="row.flex-start.center"
          px={3}
          bra={1}
          width={1}
          height={1}
        >
          <Circle size={40} bg="neutral1">
            <ClockIcon size={24} />
          </Circle>
          <Text4 ml={2} color="gray7" bold>
            My Approvals
          </Text4>
          {initLoaded ? (
            <Div
              p={1}
              bra={1}
              height={20}
              bg={countOfPendingApprovals ? "altB2" : "gray2"}
              display="row.center.center"
              ml={2}
              style={{ minWidth: "26px" }}
            >
              <Div
                fs={1}
                fw={3}
                color={countOfPendingApprovals ? "altB7" : "gray5"}
              >
                {countOfPendingApprovals < 100
                  ? countOfPendingApprovals
                  : "99+"}
              </Div>
            </Div>
          ) : null}
        </Div>
      </Div>
    </Div>
  );
};

export default R.compose(
  withRouter,
  decorate
)(TempMyApprovals);
