import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import { noop } from "utils/General";
import InputGroup from "components/Global-2016/Forms/InputGroup";
import Label from "components/Global-2016/Forms/Label";
import LabelSimpleForm from "components/Event/FormsV2/Sections/LabelSimpleForm/view";

const Radios = ({
  disabled = false,
  required = false,
  isAdminField = false,
  label = null,
  description = null,
  selected = null,
  onChange = noop,
  options = [],
  id = null,
  containerStyles = null,
  instanceId = null,
  fieldData = null,
  isShown = false,
  canShowSimpleSideBar = false,
  setIsShown = noop
}) => {
  const radios = options.reduce((acc = [], option, idx) => {
    if (!option) return acc;
    const RadioButtonComponent = (
      <RadioButton
        key={idx}
        value={option}
        label={option}
        disabled={disabled}
        required={required}
      />
    );
    return [...acc, RadioButtonComponent];
  });

  return (
    <InputGroup styles={containerStyles} style={{ width: "100%" }}>
      {!canShowSimpleSideBar ? (
        <Label
          required={required}
          isAdminField={isAdminField}
          description={description}
        >
          {label}
        </Label>
      ) : (
        <LabelSimpleForm
          isAdminField={isAdminField}
          required={required}
          description={description}
          instanceId={instanceId}
          fieldData={fieldData}
          isShown={isShown}
          setIsShown={setIsShown}
        >
          {label}
        </LabelSimpleForm>
      )}
      <RadioButtonGroup valueSelected={selected} name={id} onChange={onChange}>
        {radios}
      </RadioButtonGroup>
    </InputGroup>
  );
};

export default CSSModules(Radios, css);
