import React from "react";
import * as R from "ramda";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import Layout from "./Layout";

import { createContext } from "redux-mvc";

import module from "EventLight/Agenda/AddSessionModal";

import { actions } from "EventLight/Agenda/AddSessionModal/model";
import ImportModal from "Modules/ImportModal/View";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

const decorate = createContext({
  module,
  options: {
    observedDomains: [
      "user",
      "event",
      "permissions",
      "organization",
      "@flopflip"
    ],
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    persist: false
  },
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(
        actions.init({
          sessionId: this.props.sessionId
        })
      );
    }
  },
  handlers: {
    onDone() {
      if (this.props.onDone) {
        this.props.onDone();
      }
    },
    showImportModal() {
      this.props.showModal({
        content: (
          <ImportModal
            moduleId={STANDARD_MODULE_IDS.sessions.id}
            onDone={this.props.onDone}
          />
        ),
        wrapper: ModalWrapper
      });
    }
  }
});

export default decorate(Layout);
