import React, { Component } from "react";
import * as R from "ramda";
import View from "./View";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import AddFieldModal from "Modules/AddEditColumnModal/View";

class Controller extends Component {
  state = {
    showUntoggledOrderFields: false
  };

  componentDidMount() {
    this.props.getFields({
      moduleId: STANDARD_MODULE_IDS.orders.id,
      options: {
        eventId: this.props.eventId
      }
    });
  }

  componentWillReceiveProps(props) {
    if (!props.field || !props.field.customer_block_fields) {
      this.props.returnTo();
    }
  }

  reloadForm = () => this.props.refreshForm(this.props.form.id);

  onToggleFieldsList = key =>
    this.setState({
      [key]: !this.state[key]
    });

  isFieldToggled = fieldId => {
    return R.find(R.propEq("field_id", fieldId))(
      this.props.customerBlockFields
    );
  };

  onFieldToggle = async (moduleId, fieldId, toggled) => {
    await this.props.toggleField({
      field: {
        formFieldId: this.props.field.id,
        moduleId,
        fieldId,
        toggled,
        order: 0
      }
    });

    return this.reloadForm();
  };

  onReorderFields = async fields => {
    await this.props.updateFields({
      fields: fields.map(({ customerFieldId }, order) => ({
        id: customerFieldId,
        order
      }))
    });

    return this.reloadForm();
  };

  onToggleRequired = async (fieldId, isRequired) => {
    await this.props.updateField({
      field: {
        id: fieldId,
        is_required: isRequired
      }
    });

    return this.reloadForm();
  };

  updateBlockName = async name => {
    await this.props.updateFormField({
      fieldId: this.props.field.id,
      name
    });
    this.reloadForm();
  };

  updateFieldSettings = async (property, value) => {
    await this.props.updateFormField({
      fieldId: this.props.field.id,
      settings: {
        ...this.props.field.settings,
        [property]: value
      }
    });
    this.reloadForm();
  };

  updateBlockDescription = async description => {
    await this.props.updateFormField({
      fieldId: this.props.field.id,
      settings: {
        ...this.props.field.settings,
        description
      }
    });
    this.reloadForm();
  };

  showAddFieldModal = moduleId => {
    this.props.showModal({
      content: (
        <AddFieldModal
          eventId={this.props.eventId}
          moduleId={moduleId}
          onSave={() => {
            this.props.getFields({
              moduleId,
              options: {
                eventId: this.props.eventId
              }
            });
          }}
        />
      )
    });
  };

  render() {
    const { field, orderFields, customerBlockFields } = this.props;

    const formatFields = fields =>
      R.compose(
        ({ toggled, untoggled }) => ({
          toggled: R.sortBy(R.prop("order"))(toggled),
          untoggled
        }),
        R.reduce(
          (fields, formattedField) => {
            if (formattedField.isToggled) {
              fields.toggled.push(formattedField);
            } else {
              fields.untoggled.push(formattedField);
            }

            return fields;
          },
          {
            toggled: [],
            untoggled: []
          }
        ),
        R.sortBy(R.prop("order")),
        R.map(f => {
          const customerField = R.find(R.propEq("field_id", f.id))(
            customerBlockFields
          );
          const isToggled = Boolean(customerField);

          return {
            ...f,
            customerFieldId: customerField ? customerField.id : null,
            order: customerField ? customerField.order : null,
            isRequired: customerField ? customerField.is_required : false,
            isToggled,
            onToggle: () => this.onFieldToggle(f.module_id, f.id, !isToggled),
            onRequire: customerField
              ? () =>
                  this.onToggleRequired(
                    customerField.id,
                    !customerField.is_required
                  )
              : undefined
          };
        })
      )(fields);

    const orderFieldsToPass = formatFields(orderFields);

    return (
      <View
        {...{
          field,
          orderFields: orderFieldsToPass,
          onToggleOrderFields: () =>
            this.onToggleFieldsList("showUntoggledOrderFields"),
          showUntoggledOrderFields: this.state.showUntoggledOrderFields,
          onReorderOrderFields: this.onReorderFields,
          updateBlockName: this.updateBlockName,
          updateBlockDescription: this.updateBlockDescription,
          onReturn: () => this.props.returnTo(),
          onAddOrderField: () =>
            this.showAddFieldModal(STANDARD_MODULE_IDS.orders.id)
        }}
      />
    );
  }
}

export default Controller;
