import { get } from "lodash";
import { isManifestApproved } from "components/Global/Approvals/utils/approvals-helpers";
import getCatalogItemValue from "utils/value-types/get-value/catalog-item";
import getApprovalValue from "utils/value-types/get-value/approval";
import { APPROVAL } from "utils/system-field-ids";

export default function({ fields, id, values }, records = []) {
  const catalogFields = fields.filter(field => field.type === "catalog-item");

  const itemMap = catalogFields.reduce((items, field) => {
    get(field.settings, "availableCatalogItems", []).forEach(
      fieldItem => (items[fieldItem.id] = fieldItem)
    );
    return items;
  }, {});

  const rows = records.reduce((a, record) => {
    const catalogItems = catalogFields
      .filter(catalogField => record.values[catalogField.id]) // only need fields w/ values
      .map(catalogField => getCatalogItemValue(record.values[catalogField.id]));

    catalogItems.forEach(item =>
      get(item, "records", []).forEach(catalogItemRecordValue => {
        if (!a[catalogItemRecordValue.id]) {
          a[catalogItemRecordValue.id] = {
            approved: 0,
            requested: 0,
            name: {
              value: itemMap[catalogItemRecordValue.id]
                ? itemMap[catalogItemRecordValue.id].value
                : `(No Value - ${catalogItemRecordValue.id})`,
              id: catalogItemRecordValue.id
            }
          };
        }
        if (isManifestApproved(getApprovalValue(record.values[APPROVAL]))) {
          a[catalogItemRecordValue.id].approved +=
            catalogItemRecordValue.quantity;
        }
        a[catalogItemRecordValue.id].requested +=
          catalogItemRecordValue.quantity;
      })
    );
    return a;
  }, {});

  return rows;
}
