import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import { Div, Dropdown, LoadingIcon } from "components/Base";

import CreateMessage from "ui-kit/CreateMessage";
import MessageFeed from "ui-kit/MessageFeed";

import { MESSAGE_TYPES } from "Orders/OrderModal/constants";
import { actions, getters } from "Orders/OrderModal";
import {
  getOrderId,
  getMessages,
  getMessagesTabs,
  getSelectedTab,
  getLastMessageId
} from "Orders/OrderModal/selectors";

import { noop } from "utils/General";

const filterOptions = [
  {
    value: MESSAGE_TYPES.ALL,
    label: "All"
  },
  {
    value: MESSAGE_TYPES.NOTE,
    label: "Notes"
  },
  {
    value: MESSAGE_TYPES.ALERT,
    label: "Alert"
  }
];

const decorate = connect(
  (state, props) => ({
    orderId: getOrderId(state),
    feedLoading: getters.feedLoading(state),
    sending: getters.sending(state),
    lastMessageId: getLastMessageId(state),
    messages: getMessages(state, { handlers: props.handlers }),
    tabs: getMessagesTabs(state, { handlers: props.handlers }),
    selectedFilter: getters.selectedMessageFilter(state),
    selectedTab: getSelectedTab(state),
    newMessage: getters.newMessage(state),
    isPortalUser: getters.isPortalUser(state)
  }),
  {
    markResolved: actions.markResolved,
    loadMore: actions.loadMore,
    sendMessage: actions.sendMessage,
    setTab: actions.setSelectedMessageTab,
    setFilter: actions.setSelectedMessageFilter,
    setMessage: actions.setNewMessage,
    refreshFeed: () => actions.loadFeed()
  }
);

const MessagesView = ({
  orderId,
  handlers,
  feedLoading,
  sending,
  messages = [],
  newMessage = "",
  markResolved = noop,
  sendMessage = noop,
  tabs = [],
  setTab = noop,
  setMessage = noop,
  setFilter = noop,
  selectedFilter = "",
  isPortalUser = false,
  selectedTab,
  lastMessageId
}) => (
  <Div
    bg="white"
    bc="neutral3"
    bs={1}
    brb={1}
    brr={1}
    pt={3}
    style={{ overflow: "visible", minHeight: 500 }}
    display="column.flex-start.stretch"
  >
    <CreateMessage
      key={lastMessageId}
      onCreate={data => {
        handlers.flagAsModified();
        sendMessage(data);
      }}
      message={newMessage}
      onChangeMessage={setMessage}
      onChangeTab={setTab}
      tabs={tabs}
      bc={selectedTab.color}
      placeholder={selectedTab.placeholder}
      buttonLabel={selectedTab.button}
      processing={sending}
      buttonStyleProps={{
        bg: selectedTab.color,
        color: "white"
      }}
    />
    {isPortalUser ? null : (
      <Div display="row.flex-start.center" px={8}>
        <Dropdown
          allowMultipleSelect={false}
          bc="neutral3"
          placeholder="Showing Everything"
          options={filterOptions}
          style={{ alignSelf: "flex-start" }}
          selected={selectedFilter}
          width="200px"
          onChange={R.compose(
            setFilter,
            R.propOr("", "value")
          )}
        />
      </Div>
    )}

    <Div px={8} py={3} display="column.flex-start.stretch">
      {messages.length ? (
        <MessageFeed
          messages={messages}
          handlers={{
            updateAddress: () => {
              handlers.flagAsModified();
              handlers.updateAddress(orderId);
            },
            markResolved: noteId => {
              handlers.flagAsModified();
              markResolved(noteId);
            }
          }}
        />
      ) : !feedLoading ? (
        <Div bg="neutral0" py={3} bra={1} display="row.center.center" fs={2}>
          No messages or notes have been added yet.
        </Div>
      ) : null}
      {/*
      // @NOTE: Hide until implemented
      <MediumOutlineButton onClick={refreshFeed}>Load All...</MediumOutlineButton>
      */}
      {feedLoading ? (
        <Div bg="neutral0" py={3} bra={1} display="row.center.center">
          <LoadingIcon />
          <Div ml={2} fs={2}>
            Loading...
          </Div>
        </Div>
      ) : null}
    </Div>
  </Div>
);

export default decorate(MessagesView);
