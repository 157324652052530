import React from "react";
import SidebarWrapper from "Modules/Dashboard/View/Sidebar/SidebarWrapper";
import Sidebar from "Modules/Dashboard/View/Sidebar";
import Body from "Modules/Dashboard/View/Body";

const Layout = ({ handlers }) => (
  <SidebarWrapper sidebar={<Sidebar handlers={handlers} />}>
    <Body handlers={handlers} />
  </SidebarWrapper>
);

export default Layout;
