import PropTypes from "prop-types";
import React, { Component } from "react";
import Option from "components/Global/OptionSelector/Option";
import DefaultHeader from "components/Global/OptionSelector/DefaultHeader";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class OptionSelector extends Component {
  render() {
    const {
      handleRemoveOption,
      handleUpdateOption,
      Header,
      OptionRenderer,
      options,
      placeholder
    } = this.props;
    return (
      <div styleName="container">
        {Header}
        {options.map((option, idx) => (
          <OptionRenderer
            handleRemoveOption={handleRemoveOption}
            handleUpdateOption={handleUpdateOption}
            index={idx}
            key={idx}
            option={option}
            placeholder={placeholder}
          />
        ))}
        <OptionRenderer
          index={-1}
          option={{
            value: ""
          }}
          placeholder={placeholder}
          handleUpdateOption={handleUpdateOption}
        />
      </div>
    );
  }
}

OptionSelector.propTypes = {
  options: PropTypes.array.isRequired,
  handleUpdateOption: PropTypes.func.isRequired,
  handleRemoveOption: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  Header: PropTypes.object.isRequired,
  OptionRenderer: PropTypes.object.isRequired
};

OptionSelector.defaultProps = {
  OptionRenderer: Option,
  Header: <DefaultHeader title="Add options" />
};

export default OptionSelector;
