import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import { actions, getters } from "Modules/AddEditColumnModal/model";
import Currency from "components/Global/Table3/ModalColumn/CurrencySettings";
import { noop } from "utils/General";
import css from "./style.scss";

const decorate = R.compose(
  connect(
    (state, props) => ({
      currencySettings: getters.currencySettings(state, props)
    }),
    {
      setCurrencySettings: actions.setCurrencySettings
    }
  ),
  CSSModules(css)
);

const CurrencySettings = ({ currencySettings, setCurrencySettings = noop }) => (
  <div styleName="dropdownOptionsWrapper">
    <Currency value={currencySettings} onChange={setCurrencySettings} />
  </div>
);

export default decorate(CurrencySettings);
