import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters } from "../model";
import { Div, Text0, Text2, Text3, Text6, Text4 } from "components/Base";

const formatNumber = number => {
  if (number < 1000) {
    return number;
  }
  const stringNumber = number.toString();
  return `${stringNumber.slice(0, -3)},${stringNumber.slice(-3)}`;
};

const decorate = connect(state => ({
  data: getters.data(state)
}));

const MealsTotal = ({ data: { meal_totals_by_day, total_meals } }) => (
  <Div width={1} bg="white" p={4} bra={2}>
    <Text4 bold color="neutral7">
      Meals Totals by Day
    </Text4>
    <Div width={1}>
      {R.map(
        ({ name, days }) => (
          <Div key={name} width={1}>
            <Text0 color="neutral6" mt={4} mb={2}>
              {R.toUpper(name)}
            </Text0>
            {R.map(
              day => (
                <Div display="row.space-between" width={1} my={1}>
                  <Text3 color="black" mr={1}>
                    {day.name}
                  </Text3>
                  <Div
                    flex={1}
                    height={15}
                    bb={2}
                    bc="neutral2"
                    style={{ borderBottomStyle: "dotted" }}
                  />
                  <Text3 color="black" ml={1}>
                    {day.total}
                  </Text3>
                </Div>
              ),
              days
            )}
          </Div>
        ),
        meal_totals_by_day
      )}
    </Div>
    <Div width={1} mt={2} p={6} pb={4} display="column.flex-start.center">
      <Text2 bold color="neutral4">
        TOTAL MEALS
      </Text2>
      <Text6>{formatNumber(total_meals)}</Text6>
    </Div>
  </Div>
);

export default decorate(MealsTotal);
