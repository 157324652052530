export const NAMESPACE = "PortalForm";

export const TABS = {
  ALL: "all",
  SUBMITTED: "submitted",
  DRAFT: "draft"
};

export const TABLE_INSTANCE_ID = "portal_form_id";

export const ROW_ACTIONS_ITEMS = {
  OPEN_RECORD: "openRecordRow",
  EDIT: "row-edit",
  DELETE: "row-delete",
  OPEN_SUBMISSION_MODAL: "row-openSubmissionModal"
};
