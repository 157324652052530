import React from "react";
import * as R from "ramda";
import {
  IconLabelGroupedMultiSelect,
  AvatarLabelMultiSelect,
  IconLabelMultiSelect
} from "components/Base";
import { ACCOUNTS, CONTACTS } from "utils/standard-module-field-ids";

const ItemTypesMultiSelect = ({
  itemTypes = [],
  initialSelection = [],
  onCancel,
  onSave
}) => {
  return (
    <IconLabelMultiSelect
      items={R.map(({ id, name }) => {
        return {
          id,
          text: name
        };
      }, itemTypes)}
      searchPlaceholder={"Search..."}
      onSearch={undefined}
      onCancel={onCancel}
      onSave={onSave}
      initialSelection={initialSelection}
    />
  );
};

const AccountsMultiSelect = ({
  accounts = [],
  initialSelection = [],
  onCancel,
  onSave
}) => {
  return (
    <AvatarLabelMultiSelect
      items={R.map(account => {
        const name = R.path(["values", ACCOUNTS.NAME, "value"], account);
        return {
          id: R.prop("id", account),
          frontText: name
          // icon: // TODO
        };
      }, accounts)}
      searchPlaceholder={"Search by name or email..."}
      onSearch={undefined}
      onCancel={onCancel}
      onSave={onSave}
      initialSelection={initialSelection}
    />
  );
};

const ContactsMultiSelect = ({
  contacts = [],
  initialSelection = [],
  onCancel,
  onSave
}) => {
  return (
    <AvatarLabelMultiSelect
      items={R.map(contact => {
        // TODO toString these
        const firstName = R.pathOr(
          "",
          ["values", CONTACTS.FIRST_NAME, "value"],
          contact
        );
        const lastName = R.pathOr(
          "",
          ["values", CONTACTS.LAST_NAME, "value"],
          contact
        );
        const email = R.path(["values", CONTACTS.EMAIL, "value"], contact);
        const photoURL = R.path(
          ["values", CONTACTS.AVATAR_URL, "value"],
          contact
        );

        return {
          id: R.prop("id", contact),
          frontText: `${firstName} ${lastName}`,
          backText: email,
          photoURL
        };
      })(contacts)}
      searchPlaceholder={"Search by name or email..."}
      onSearch={undefined}
      onCancel={onCancel}
      onSave={onSave}
      initialSelection={initialSelection}
    />
  );
};

const FormsMultiSelect = ({
  forms = [],
  initialSelection = [],
  onCancel,
  onSave
}) => {
  return (
    <AvatarLabelMultiSelect
      items={R.map(({ id }) => ({ id, frontText: id }))(forms)}
      searchPlaceholder={"Search..."}
      onSearch={undefined}
      onCancel={onCancel}
      onSave={onSave}
      initialSelection={initialSelection}
    />
  );
};

const UsersMultiSelect = ({
  users = [],
  initialSelection = [],
  onCancel,
  onSave
}) => {
  return (
    <AvatarLabelMultiSelect
      items={R.map(({ user_id, user_fname, user_lname, user_email }) => ({
        frontText: `${user_fname} ${user_lname}`,
        backText: user_email,
        email: user_email,
        id: user_id
      }))(users)}
      searchPlaceholder={"Search by name or email..."}
      onSearch={undefined}
      onCancel={onCancel}
      onSave={onSave}
      initialSelection={initialSelection}
    />
  );
};

export {
  ItemTypesMultiSelect,
  AccountsMultiSelect,
  ContactsMultiSelect,
  FormsMultiSelect,
  UsersMultiSelect
};
