import { createSelector } from "reselect";
import { getters } from "SalesPortal/ItemsModal";
import * as R from "ramda";
import Helpers from "utils/Global/Helpers";
import numeral from "numeral";

export const getSelectedItems = createSelector(
  getters.itemTypesList,
  R.compose(
    R.propOr([], "items"),
    R.find(R.propEq("selected", true))
  )
);

export const getOrderedItems = createSelector(
  getters.itemTypesList,
  R.compose(
    R.filter(item => item.quantity > 0),
    R.reduce(
      (acc, itemType) => [
        ...acc,
        ...R.map(item => ({ ...item, groupId: itemType.id }), [
          ...R.propOr([], "items", itemType)
        ])
      ],
      []
    )
  )
);

export const getSelectedGroupsAndItems = createSelector(
  getters.itemTypesList,
  R.compose(
    R.map(group => ({
      ...group,
      items: R.filter(item => item.quantity > 0, group.items)
    })),
    R.filter(group => R.any(item => item.quantity > 0, group.items))
  )
);

export const getTotalCartItems = createSelector(
  getOrderedItems,
  R.reduce((acc, curr) => acc + curr.quantity, 0)
);

export const getTotalAmount = createSelector(
  getOrderedItems,
  R.compose(
    total => numeral(total || 0).format("0.00"),
    R.reduce((acc, curr) => acc + curr.quantity * curr.price, 0)
  )
);

export const isCheckoutValid = createSelector(
  getters.contact,
  getters.payment,
  getTotalCartItems,
  (contact, payment, quantity) => {
    return Boolean(
      quantity > 0 &&
        contact.fullName &&
        contact.fullName.trim().length &&
        contact.email &&
        contact.email.trim().length &&
        Helpers.isValidEmail(contact.email)
    );
  }
);
