import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Settings from "./Settings";

import { getModulesWithFields } from "redux/modules/modules/modules/actions";
import { getFields } from "redux/modules/modules/fields/actions";

import { modules } from "redux/modules/modules/modules/selectors";
import { fields } from "redux/modules/modules/fields/selectors";
import { eventDetails } from "redux/modules/event/selectors";

function mapStateToProps(state, props) {
  let modulesToPass = modules(state);
  const selectedModule = props.value.moduleId
    ? modulesToPass.find(m => m.id === props.value.moduleId)
    : null;
  const selectedModuleFields = selectedModule
    ? selectedModule.fields.fields
    : [];
  const selectedModulePrimaryFields = selectedModuleFields.filter(
    f => f.is_primary_field
  );

  if (props.moduleBlacklist) {
    modulesToPass = modules.filter(m => !props.moduleBlacklist.includes(m.id));
  }

  return {
    modules: modulesToPass.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    ),
    selectedModulePrimaryFields,
    eventId: eventDetails(state).id,
    fields: moduleId => fields(state, moduleId)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getModulesWithFields,
      getFields
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings);
