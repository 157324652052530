import React from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import {
  Div,
  Text3,
  BigOutlineButton,
  UpIcon,
  DownIcon
} from "components/Base";
import {
  getDescription,
  shouldShowReadMoreButton,
  textForViewMore
} from "../selectors";

import { getters, actions } from "../model";

const decorate = connect(
  (state, props) => ({
    description: getDescription(state, props),
    viewMore: getters.viewMore(state, props),
    shouldShowReadMoreButton: shouldShowReadMoreButton(state, props),
    textForViewMore: textForViewMore(state, props)
  }),
  bindInstance({
    toggleShowMore: actions.toggleShowMore
  })
);

const Description = ({
  title,
  description,
  toggleShowMore,
  shouldShowReadMoreButton,
  textForViewMore,
  viewMore
}) => (
  <Div>
    <Div
      mb={2}
      fw={4}
      style={{
        fontSize: "30px",
        color: "#123059"
      }}
    >
      {title}
    </Div>
    <Text3
      mb={4}
      style={{
        color: "#123059"
      }}
      className="lennd-wysiwyg-content portal-message-text"
    >
      <span
        dangerouslySetInnerHTML={{
          __html: description
        }}
        style={{
          whiteSpace: "pre-line"
        }}
      />
    </Text3>
    {shouldShowReadMoreButton && (
      <BigOutlineButton
        bg="neutral3"
        color="neutral8"
        LeftIcon={viewMore ? UpIcon : DownIcon}
        onClick={toggleShowMore}
        pill
      >
        {textForViewMore}
      </BigOutlineButton>
    )}
  </Div>
);

export default decorate(Description);
