import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Controller from "./Controller";

import { getSettings } from "redux/modules/portal/settings/actions";

import { eventDetails as eventEventDetails } from "redux/modules/event/selectors";
import { eventDetails as portalEventDetails } from "redux/modules/portal/selectors";
import { settings } from "redux/modules/portal/settings/selectors";
import { people as getPeople } from "redux/modules/portal/people/selectors";
import { getPeople as fetchPeople } from "redux/modules/portal/people/actions";

function mapStateToProps(state, props) {
  const eventDetailsToPass = props.routes.find(r =>
    [
      "PortalV2_LoggedInView",
      "PortalV2_LoggedOutView",
      "portalHub_LoggedInView"
    ].includes(r.name)
  )
    ? portalEventDetails(state)
    : eventEventDetails(state);

  const portalSettings = settings(state);
  const types =
    portalSettings && portalSettings.types
      ? portalSettings.types.map(t => ({
          id: t.id,
          name: t.name,
          fields: t.fields
        }))
      : [];
  return {
    eventId: eventDetailsToPass.id,
    portalSettings,
    types,
    eventDetails: eventDetailsToPass,
    people: getPeople(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getSettings,
      fetchPeople
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
