import PropTypes from "prop-types";
import React, { Component } from "react";
import getValue from "utils/value-types/get-value/lookup";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import LinkedRecordsModal from "components/Global/CRM/Modals/LinkedRecords";
import View from "./View";

class Controller extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || null
    };
  }

  /*
  // @NOTE: Not sure if we need this. Commenting out for now.
  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.value, nextProps.value)) {
      this.setState({
        value: nextProps.value
      });
    }
  }
  */

  onChange = value => {
    this.setState(
      {
        value
      },
      () => this.props.onChange(this.state.value)
    );
  };

  getRecords = () => {
    const { records } = getValue(this.state.value);
    return records || [];
  };

  deleteFile = id => {
    const existingFiles = this.getRecords();
    this.onChange({
      type: "file",
      value: {
        files: [...existingFiles.filter(f => f.id !== id)]
      }
    });
  };

  showLinkedRecordsModal = () => {
    const { linkedModuleId, linkedFieldId, moduleId, fieldId } = this.props;
    const { records } = getValue(this.state.value);
    this.props.showModal({
      content: (
        <LinkedRecordsModal
          moduleId={moduleId}
          fieldId={fieldId}
          linkedModuleId={linkedModuleId}
          linkedFieldId={linkedFieldId}
          linkedRecordIds={records}
          onSave={data => {
            this.onChange(data);
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const { linkedModuleId, linkedFieldId, linkedModule } = this.props;
    const records = this.getRecords();

    return (
      <View
        {...{
          showLinkedRecordsModal: this.showLinkedRecordsModal,
          linkedModuleId,
          linkedFieldId,
          linkedRecordIds: records,
          linkedModuleName: linkedModule.name
        }}
      />
    );
  }
}

Controller.defaultProps = {
  allowDownload: false,
  placeholder: "Add files to this submission"
};

Controller.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  column: PropTypes.object
};

export default Controller;
