import { put, all, takeEvery, fork, select, call } from "redux-saga/effects";
import copy from "copy-to-clipboard";

import { getCredentials } from "redux/modules/user/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";

import { actions, getters } from "./model";
import { registerError } from "redux/modules/errors/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { push } from "react-router-redux";

import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventDetails = yield select(getEventDetails);

  return {
    credentials,
    eventDetails
  };
};

const init = function*() {
  try {
    yield put(actions.setLoading(true));
    const { credentials, eventDetails } = yield call(getParams);

    if (["conference"].includes(eventDetails.template)) {
      const { payload } = yield call(Api.getDashboardDetails, {
        credentials,
        eventId: eventDetails.id
      });

      yield all([put(actions.setInitialData(payload))]);
    } else {
      /*
      const { payload } = yield call(Api.getDashboardDetails, {
        credentials,
        eventId: eventDetails.id
      });
      */

      const result = yield call(
        Api.getEventAccountRecordTypes,
        credentials,
        eventDetails.id
      );
      yield all([
        // put(actions.setInitialData(payload)),
        put(actions.setGroupTypes(result.payload))
      ]);
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting details"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const openRegistrationPage = function*() {
  const registrationUrl = yield select(getters.registrationUrl);
  yield call(window.open, registrationUrl, "_blank");
};

const copyValue = function*({ payload }) {
  yield call(copy, payload);
  yield put(showSnackbar({ message: "Link Copied" }));
};

const viewAttendees = function*() {
  const eventId = yield select(getEventId);
  yield put(push(`/event-light/${eventId}/attendees`));
};

const viewEventDetails = function*() {
  const eventId = yield select(getEventId);
  yield put(push(`/event-light/${eventId}/details`));
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchOpenRegistrationPage = function*() {
  yield takeEvery(actions.openRegistrationPage.type, openRegistrationPage);
};

const watchCopyValue = function*() {
  yield takeEvery(actions.copyValue.type, copyValue);
};

const watchViewAttendees = function*() {
  yield takeEvery(actions.viewAttendees.type, viewAttendees);
};

const watchViewEventDetails = function*() {
  yield takeEvery(actions.viewEventDetails.type, viewEventDetails);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchOpenRegistrationPage),
    fork(watchCopyValue),
    fork(watchViewAttendees),
    fork(watchViewEventDetails)
  ]);
};

export default rootSaga;
