import React from "react";
import { connect } from "react-redux";
import { MediumFilledButton, DownFilledIcon } from "components/Base";
import { getRowActions } from "EventLight/Portals/Portals/selectors";
import PopoverMenu from "ui-kit/PopoverMenu/View";

const decorate = connect(state => ({
  rowActions: getRowActions(state)
}));

const OptionsPopover = ({ data, rowActions }) => (
  <PopoverMenu data={data} actions={rowActions}>
    <MediumFilledButton
      RightIcon={DownFilledIcon}
      bg="neutral1"
      color="neutral7"
    >
      Options
    </MediumFilledButton>
  </PopoverMenu>
);

export default decorate(OptionsPopover);
