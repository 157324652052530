import React from "react";
import { PopoverMenu } from "components/Base";

const ActionsPopover = ({ showEditTypeModal }) => (
  <PopoverMenu
    Label={({ onClick }) => (
      <div className="actions-icon-container" onClick={onClick}>
        <i className="material-icons flash">flash_on</i>
        <i className="material-icons down">keyboard_arrow_down</i>
      </div>
    )}
    menuItems={[["Edit Type", () => showEditTypeModal()]]}
  />
);

export default ActionsPopover;
