import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";

import { actions, getters } from "Items/BulkQuestionItemsModal";

import { getVariants } from "Items/BulkQuestionItemsModal/selectors";

import itemTypesApi from "redux/modules/items/types/api";

import { eventId as getEventId } from "redux/modules/event/selectors";

import { getCredentials } from "redux/modules/user/selectors";

import { registerError } from "redux/modules/errors/actions";

import Api from "./api";

import { PAGES } from "Items/BulkQuestionItemsModal/constants";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  return {
    credentials,
    eventId
  };
};

const init = function*({ payload: { questionSetIds, questionIds } }) {
  try {
    yield put(actions.setLoading(true));

    const { credentials, eventId } = yield call(getParams);

    const [
      { payload: sets },
      { payload: questions },
      { payload: itemTypes }
    ] = yield all([
      call(Api.getQuestionSets, {
        credentials,
        eventId
      }),
      call(Api.getQuestions, {
        credentials,
        eventId
      }),
      call(itemTypesApi.getItemTypesByEvent, credentials, eventId)
    ]);

    yield put(
      actions.setInitialData({
        questionSetIds,
        questionIds,
        itemTypes,
        questions,
        sets
      })
    );

    // if passing in question set ids or question ids, trigger next action
    if (questionSetIds.length || questionIds.length) {
      yield put(actions.selectItems());
    }
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading questions"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const assignQuestionsToItems = function*({ payload: { onDone } }) {
  try {
    yield put(actions.setLoading(true));

    const { credentials } = yield call(getParams);
    const questionSetIds = yield select(getters.setsIds);
    const questionIds = yield select(getters.itemQuestionsIds);
    const variantIds = yield select(getVariants);

    yield call(Api.assignQuestions, {
      credentials,
      data: {
        mode: "question",
        variantIds,
        questionSetIds,
        questionIds,
        questionAndQuestionSetOrder: []
      }
    });
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error applying questions to items"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
    yield call(onDone);
  }
};

const selectItems = function*() {
  try {
    yield put(actions.setLoading(true));

    const { credentials } = yield call(getParams);
    const questionSetIds = yield select(getters.setsIds);
    const questionIds = yield select(getters.itemQuestionsIds);

    const {
      payload: { variant_ids: variantList }
    } = yield call(Api.saveSelectedIds, {
      credentials,
      data: {
        questionSetIds,
        questionIds
      }
    });

    yield put(actions.goToSelectItems({ variantList }));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error applying questions to items"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const refetchSets = function*() {
  try {
    yield put(actions.setLoading(true));

    const { credentials, eventId } = yield call(getParams);

    const { payload: sets } = yield call(Api.getQuestionSets, {
      credentials,
      eventId
    });

    yield put(actions.setSets(sets));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading question sets"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchAssignQuestionsToItems = function*() {
  yield takeEvery(actions.assignQuestionsToItems.type, assignQuestionsToItems);
};

const watchSelectItems = function*() {
  yield takeEvery(actions.selectItems.type, selectItems);
};

const watchRefetchSets = function*() {
  yield takeEvery(actions.refetchSets.type, refetchSets);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchAssignQuestionsToItems),
    fork(watchSelectItems),
    fork(watchRefetchSets)
  ]);
};

export default rootSaga;
