import * as R from "ramda";
import { createSelector } from "reselect";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import {
  CREDENTIAL_TYPE_ID,
  MEAL_TYPE_ID,
  INVENTORY_TYPE_ID,
  BOOTH_TYPE_ID
} from "utils/item-types";

export const getGroupUrl = createSelector(
  R.path(["params", "groupId"]),
  R.path(["params", "moduleId"]),
  (groupId, moduleId) =>
    groupId
      ? `/${
          moduleId === STANDARD_MODULE_IDS.accounts.id ? "group" : "contact"
        }/${groupId}`
      : ""
);

export const getManageUrl = createSelector(
  R.path(["params", "eventId"]),
  getGroupUrl,
  (eventId, groupUrl) => (view, base = "/passes/manage", query = "") =>
    `/event/${eventId}${base}${view}${groupUrl}${query}`
);

export const getActiveItemTypeId = (path = "") => {
  if (path.includes("/catering")) {
    return MEAL_TYPE_ID;
  } else if (path.includes("/inventory")) {
    return INVENTORY_TYPE_ID;
  } else if (path.includes("/booths")) {
    return BOOTH_TYPE_ID;
  }
  return CREDENTIAL_TYPE_ID;
};
