import React from "react";

import { connect } from "react-redux";
import Loading from "ui-kit/Loading";
import { addS } from "utils/General";

import * as R from "ramda";

import { getFormsSorted } from "Modules/SubmitFormModal/selectors";
import { getters } from "Modules/SubmitFormModal";

import { Div, Text1, RightIcon, LoadingIcon } from "components/Base";

const decorate = connect(state => ({
  forms: getFormsSorted(state),
  loading: getters.loading(state),
  saving: getters.saving(state),
  filter: getters.filterForm(state)
}));

const FormList = ({ forms, handlers, saving, loading, filter }) => (
  <Div width={1} flex={1} p={4} style={{ overflowY: "auto" }}>
    {loading ? (
      <Loading />
    ) : !forms.length && !loading ? (
      <Div fs={4} bra={1} bg="neutral2" color="neutral6" p={5}>
        {filter && filter.length
          ? "There are no forms that match your search"
          : "There are no forms available"}
      </Div>
    ) : (
      R.map(
        form => (
          <Div
            bra={1}
            bc="neutral2"
            bg={
              form.saving
                ? "white"
                : saving
                ? "neutral0"
                : {
                    default: "white",
                    hover: "neutral0"
                  }
            }
            ba={1}
            p={4}
            key={form.id}
            mb={2}
            display="row.space-between.center"
            className={saving ? undefined : "hoverContainer"}
            height={70}
            onClick={
              saving
                ? undefined
                : () =>
                    handlers.goToSubmitForm({
                      baseModuleId: form.base_module_id,
                      formId: form.id,
                      accountId: null,
                      slug: form.slug,
                      scope: form.scope
                    })
            }
          >
            <Div>
              <Div fs={5} fw={3} color="black" truncate>
                {form.name}
              </Div>
              <Text1 bold>
                {form.count_of_submissions} submission
                {addS(form.count_of_submissions)}
              </Text1>
            </Div>
            {form.saving ? (
              <LoadingIcon color="neutral4" size={40} />
            ) : saving ? null : (
              <RightIcon color="neutral3" size={48} className="showOnHover" />
            )}
          </Div>
        ),
        forms
      )
    )}
  </Div>
);

export default decorate(FormList);
