import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PortalSwitch from "./PortalSwitch";
import { withRouter } from "react-router";

import * as PortalUserActions from "redux/modules/portal/user/actions";
import * as PortalUserSelectors from "redux/modules/portal/user/selectors";

import * as PortalSelectors from "redux/modules/portal/selectors";
import * as UserSelectors from "redux/modules/user/selectors";

function mapStateToProps(state) {
  return {
    portalUser: PortalUserSelectors.portalUser(state),
    accounts: PortalUserSelectors.accounts(state),

    user: UserSelectors.user(state),
    portal: PortalSelectors.portal(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...PortalUserActions
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PortalSwitch)
);
