import React, { Component } from "react";
import CSSModules from "react-css-modules";
import { get } from "lodash";
import css from "./styles.scss";
import {
  ButtonGroup,
  ButtonOutline,
  Submit
} from "components/Global/Modal/Layout/Buttons";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import FormElements from "components/Global/Modal/Layout/FormElements";

import ColorPicker from "components/Global/ColorPicker";
import IconSelector, {
  DEFAULT_ICON_GROUP
} from "components/Global/OptionSelector/AccountsOption/IconSelector";
import IconSelectorLabel from "components/Global/OptionSelector/AccountsOption/IconSelectorLabel";

const { Input, Label, InputGroup } = FormElements;

class RecordTypeModal extends Component {
  state = {
    name: get(this.props, "recordType.name", ""),
    textColor: get(this.props, "recordType.text_color", "#000000"),
    backgroundColor: get(this.props, "recordType.background_color", "#ececec"),
    icon: get(this.props, "recordType.icon", DEFAULT_ICON_GROUP[0])
  };

  handleNameChange = e => this.setState({ name: e.target.value });

  handleIconChange = icon => this.setState({ icon });

  handleColorChange = ({ textColor, backgroundColor }) =>
    this.setState({
      textColor,
      backgroundColor
    });

  handleSave = () => {
    const data = {
      ...this.props.recordType,
      name: this.state.name,
      icon: this.state.icon,
      textColor: this.state.textColor,
      backgroundColor: this.state.backgroundColor
    };
    this.props.hideModal();
    this.props.onSave(data);
  };

  render() {
    const { recordType, hideModal, recordName } = this.props;
    return (
      <StyleWrapper
        heading={recordType ? "Edit type" : "Add type"}
        hideModal={hideModal}
      >
        <form onSubmit={this.handleSave}>
          <InputGroup>
            <Label>Name</Label>
            <div styleName="container">
              <div styleName="inputWrapper">
                <Input
                  className={css.inputWithActions}
                  type="text"
                  value={this.state.name}
                  onChange={this.handleNameChange}
                />
                <div styleName="colorIcon">
                  <ColorPicker
                    selected={{
                      textColor: this.state.textColor,
                      backgroundColor: this.state.backgroundColor
                    }}
                    onSelect={this.handleColorChange}
                  />
                </div>
              </div>
              <div styleName="inputWrapper">
                <IconSelector selectIcon={this.handleIconChange}>
                  <IconSelectorLabel icon={this.state.icon} />
                </IconSelector>
              </div>
            </div>
          </InputGroup>

          <ButtonGroup>
            <Submit title="Save" disabled={!this.state.name.length} />
            <ButtonOutline title="Cancel" onClick={hideModal} />
          </ButtonGroup>
        </form>
      </StyleWrapper>
    );
  }
}

export default CSSModules(RecordTypeModal, css);
