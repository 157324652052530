import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { actions } from "ApproversLabel";

import {
  Div,
  Text0,
  Text2,
  Popover,
  MediumOutlineButton,
  DownFilledIcon
} from "components/Base";

import { StatusIcon, StatusTag } from "utils/status-comps";
import { toClass } from "utils/General";

import Menu from "./Menu";

import approversLabel from "ApproversLabel";
import approversLabelSagas from "ApproversLabel/sagas";

approversLabel.setRootSaga(approversLabelSagas);

const Container = ({ children, ...props }) => (
  <Div display="row.space-between.center" width={1} {...props}>
    {children}
  </Div>
);

const Button = ({ onClick, status, top, bottom }) => (
  <Div flex={1} onClick={onClick} display={"row.flex-start.center"}>
    <StatusIcon status={status} />
    <Div ml={1} width={1} maxWidth={100}>
      <Text0 color={"neutral6"} bold truncate>
        {top}
      </Text0>
      <Text2 color={"neutral8"} bold truncate>
        {" "}
        {bottom}
      </Text2>
    </Div>
  </Div>
);

const AutoApprovedButton = toClass(({ onClick }) => (
  <Button onClick={onClick} status={"approved"} bottom={"Auto-Approved"} />
));

const ApprovedButton = toClass(({ onClick }) => (
  <StatusTag onClick={onClick} status={"approved"} style={{ minWidth: 100 }} />
));

const DeniedButton = toClass(({ onClick }) => (
  <StatusTag onClick={onClick} status={"denied"} style={{ minWidth: 100 }} />
));

const PendingButton = toClass(({ onClick }) => (
  <StatusTag onClick={onClick} status={"pending"} style={{ minWidth: 100 }} />
));

const getPopoverProps = status =>
  R.propOr(
    {
      Label: PendingButton,
      labelProps: {}
    },
    status
  )({
    approved: {
      Label: ApprovedButton
    },
    rejected: {
      Label: DeniedButton
    }
  });

const decorate = connect(
  null,
  bindInstance({
    fetchManifest: actions.fetchManifest
  })
);

const ApproverLabelView = ({
  containerProps = {},
  fetchManifest,
  leftMenu,
  instanceId = "",
  ...props
}) => {
  // build options menu
  const menu = props.manifestId ? (
    <Popover
      Label={({ onClick }) => (
        <MediumOutlineButton
          onClick={e => {
            onClick(e);
            if (props.manifestId) {
              fetchManifest({
                type: props.type,
                manifestId: props.manifestId,
                recordIds: props.recordIds
              });
            }
          }}
          p={0}
          ml={leftMenu ? 0 : 2}
          mr={leftMenu ? 2 : 0}
          style={{ width: 30, padding: 0 }}
        >
          <DownFilledIcon size={24} />
        </MediumOutlineButton>
      )}
      labelProps={{}}
      wrapperProps={{}}
    >
      {({ closePopover }) => (
        <Menu
          {...{
            ...props,
            onEdit:
              props.handlers && props.handlers.bulkEdit
                ? () =>
                    props.handlers.bulkEdit({
                      lineItemId: props.recordIds
                    })
                : undefined,
            closeMenu: closePopover,
            instanceId
          }}
        />
      )}
    </Popover>
  ) : null;

  if (R.isNil(props.manifestId)) {
    return (
      <Container {...containerProps}>
        <AutoApprovedButton />
        {menu}
      </Container>
    );
  }

  const { Label, labelProps } = getPopoverProps(props.status);

  return (
    <Container {...containerProps}>
      {leftMenu ? (
        <>
          {menu}
          <Label {...labelProps} />
        </>
      ) : (
        <>
          <Label {...labelProps} />
          {menu}
        </>
      )}
    </Container>
  );
};

export default decorate(ApproverLabelView);
