import * as R from "ramda";

import React from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { Div } from "components/Base";
import { noop } from "utils/General";

import { getDateRangeOptions } from "../selectors";
import { getters, actions } from "../model";

const decorate = connect(
  (state, props) => ({
    selectedRange: getters.selectedRange(state, props),
    options: getDateRangeOptions(state, props)
  }),
  bindInstance({
    onSelectRange: actions.setSelectedRange
  })
);

const DateRangeSelector = ({
  selectedRange = "",
  onSelectRange = noop,
  options = [],
  ...styleProps
}) => {
  if (R.isEmpty(options)) {
    return null;
  }

  return (
    <Div
      display="row.flex-start.center"
      style={{ flexShrink: 0 }}
      bg="#7a38c3"
      p={1}
      bra={1}
      {...styleProps}
    >
      {options.map(
        (option, index) => (
          <Div
            key={option.id}
            flex={1}
            p={2}
            display="row.center.center"
            bra={1}
            fs={2}
            fw={3}
            onClick={() => onSelectRange(option.id)}
            ml={index === 0 ? 0 : 1}
            color="gray0"
            bg={{
              default: selectedRange === option.id ? "primary7" : "transparent",
              hover: "primary7"
            }}
          >
            {option.label}
          </Div>
        ),
        options
      )}
    </Div>
  );
};

export default decorate(DateRangeSelector);
