import { createModule } from "redux-mvc";

import handlers from "Modules/GenerateDocumentsModal/handlers";
import snackbar from "ui-kit/Snackbar";

const module = createModule(handlers);
module.plugModule(snackbar);

const { actions, getters } = module;

export { actions, getters };

export default module;
