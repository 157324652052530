import { REQUEST, RECEIVE } from "./constants";
import * as selectors from "./selectors";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getCategoryOverviewReport(eventId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState(), eventId)) return;
    try {
      dispatch({
        type: REQUEST,
        payload: {
          eventId
        }
      });
      const result = await api.get(getState().user.user.credentials, eventId);
      dispatch({
        type: RECEIVE,
        payload: {
          eventId,
          report: result.payload
        }
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching category overview report"
          }
        ])
      );
    }
  };
}
