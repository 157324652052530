import React from "react";
import MiniModalWrapper from "Orders/Common/View/MiniModalWrapper";
import { SelectAndCreateVariants } from "ui-kit/MiniItemsSelector/View";
import { connect } from "react-redux";
import { noop } from "utils/General";
import { MODE_PRICE_SELECT } from "ui-kit/MiniItemsSelector/constants";

import { actions } from "../../model";
import {
  getItemsTypes,
  getSelectedRequestableItems,
  getSelectRequestableItemsModalTitle
} from "../../selectors";
import { SELECT_REQUESTABLE_ITEMS_MODAL_ID } from "../../constants";

const decorate = connect(
  state => ({
    itemsTypes: getItemsTypes(state),
    selectedItemIds: getSelectedRequestableItems(state),
    title: getSelectRequestableItemsModalTitle(state)
  }),
  {
    hideModal: () => actions.setShowSelectRequestableItemsModal(false),
    onDone: actions.updateRequestableItems
  }
);

const SelectRequestableItemsModal = ({
  itemsTypes = [],
  selectedItemIds = [],
  hideModal = noop,
  onDone = noop,
  title = "Choose items"
}) => {
  return (
    <MiniModalWrapper
      showModal={true}
      title={title}
      hideModal={hideModal}
      width={750}
      height={850}
    >
      {React.createElement(SelectAndCreateVariants, {
        instanceId: SELECT_REQUESTABLE_ITEMS_MODAL_ID,
        fields: itemsTypes,
        iniSelected: selectedItemIds,
        iniClearSearch: true,
        onCancel: hideModal,
        onDone: onDone,
        mode: MODE_PRICE_SELECT
      })}
    </MiniModalWrapper>
  );
};

export default decorate(SelectRequestableItemsModal);
