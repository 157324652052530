import Helpers from "utils/Global/Helpers";
import getQuery from "redux/modules/modules/utils/get-query";

/* eslint no-underscore-dangle: "off" */

export default {
  getFileRequest: ({ credentials, eventId, fileRequestId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/file-requests/event/${eventId}/request/${fileRequestId}`,
        credentials,
        success,
        error
      });
    }),
  getFields: ({ credentials, eventId, fileRequestId, options }) =>
    new Promise((success, error) => {
      const query = getQuery(options);

      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/file-requests/event/${eventId}/results/${fileRequestId}/fields${query ||
          ""}`,
        credentials,
        success,
        error
      });
    }),
  search: (credentials, eventId, orgId, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/file-requests/event/${eventId}/results/${data.fileRequestId}`,
        qs: { eventId, orgId, ...data },
        credentials,
        success,
        error
      });
    }),
  export: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/file-requests/event/${eventId}/results/${data.fileRequestId}/export`,
        qs: { eventId: eventId, ...data },
        credentials,
        success,
        error
      });
    }),
  getViews: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/file-requests/event/${eventId}/results/${data.fileRequestId}/views`,
        qs: { eventId },
        data,
        credentials,
        success,
        error
      });
    }),
  addView: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/file-requests/event/${eventId}/results/${data.fileRequestId}/views`,
        qs: { eventId },
        data,
        credentials,
        success,
        error
      });
    }),
  updateView: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/file-requests/event/${eventId}/results/${data.fileRequestId}/view`,
        qs: { eventId },
        data,
        credentials,
        success,
        error
      });
    }),
  deleteView: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/file-requests/event/${eventId}/results/${data.fileRequestId}/view`,
        qs: { eventId },
        data,
        credentials,
        success,
        error
      });
    }),
  selectView: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/file-requests/event/${eventId}/results/${data.fileRequestId}/active-view`,
        qs: { eventId },
        data,
        credentials,
        success,
        error
      });
    }),
  getActiveView: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/file-requests/event/${eventId}/results/${data.fileRequestId}/active-view`,
        qs: { eventId },
        data,
        credentials,
        success,
        error
      });
    }),
  getStatus: (credentials, { jobId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/jobs/progress/${jobId}`,
        credentials,
        success,
        error
      });
    })
};
