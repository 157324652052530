import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import {
  getClosingThisWeek,
  getAllForms
} from "EventLight/Forms/Forms/selectors";
import { Div, Text4 } from "components/Base";
import { WIDTH } from "EventLight/Forms/Forms/constants";
import ClosingThisWeek from "./ClosingThisWeek";
import AllForms from "./AllForms";
import EmptyState from "EventLight/Common/EmptyState";
import { getters } from "../model";

const decorate = connect(state => ({
  closingList: getClosingThisWeek(state),
  allFormsList: getAllForms(state),
  showEmptyState: getters.showEmptyState(state)
}));

const Body = ({ closingList, allFormsList, showEmptyState }) => (
  <Div width={1} display="row.flex-start.flex-start" mb={5}>
    <Div
      width={1}
      style={{
        maxWidth: WIDTH
      }}
    >
      {showEmptyState ? (
        <EmptyState
          style={{
            marginTop: "15px"
          }}
          icon="web"
          title="You haven't created any forms yet"
          description="Create and send forms to collect information from people and groups"
        />
      ) : (
        <>
          {R.isEmpty(closingList) && R.isEmpty(allFormsList) ? (
            <Text4 mt={3}>No forms matched your search</Text4>
          ) : null}
          {!R.isEmpty(closingList) ? <ClosingThisWeek /> : null}
          {!R.isEmpty(allFormsList) ? (
            <AllForms showTitle={!R.isEmpty(closingList)} />
          ) : null}
        </>
      )}
    </Div>
  </Div>
);

export default decorate(Body);
