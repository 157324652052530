import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { getters, actions } from "ui-kit/TreeTable/model";
import { getItemsOrdered } from "ui-kit/TreeTable/selectors";
import CSSModules from "react-css-modules";
import css from "./style.scss";
import RowDnD from "./RowDnD";
import { withProps } from "utils/General";

const decorate = connect(
  state => ({
    items: getters.items(state),
    compressedList: getters.compressedList(state),
    itemsOrdered: getItemsOrdered(state),
    canDrag: getters.canDrag(state),
    showCheck: getters.showCheck(state)
  }),
  {
    reorder: actions.reorder,
    toggleCompressedList: actions.toggleCompressedList,
    toggleSelectedId: actions.toggleSelectedId
  }
);

const TreeTable = ({
  reorder,
  itemsOrdered,
  compressedList,
  toggleCompressedList,
  rowComponent,
  dropComponent,
  canDrag,
  toggleSelectedId,
  showCheck
}) => {
  const UpdatedRow = withProps({
    compressedList,
    toggleCompressedList
  })(rowComponent);
  return (
    <div>
      {itemsOrdered.map((item, index) =>
        canDrag ? (
          <RowDnD
            key={item.id}
            {...item}
            index={index}
            reorder={reorder}
            compressedList={compressedList}
            toggleCompressedList={toggleCompressedList}
            lastItem={index === R.length(itemsOrdered) - 1}
            rowComponent={rowComponent}
            dropComponent={dropComponent}
            toggleSelectedId={() =>
              toggleSelectedId({ id: item.id, selected: item.selected })
            }
            showCheck={showCheck}
          />
        ) : (
          <div
            styleName="flex-row"
            style={{
              paddingLeft: item.level * 16,
              borderBottom:
                canDrag || index < R.length(itemsOrdered) - 1
                  ? "1px solid #e7e6ea"
                  : "none"
            }}
          >
            {showCheck ? (
              <div
                styleName="checkbox"
                style={item.selected ? { background: "#823dd1" } : {}}
                onClick={() =>
                  toggleSelectedId({ id: item.id, selected: item.selected })
                }
              >
                {item.selected ? (
                  <i
                    class="material-icons"
                    style={{ color: "white", fontSize: 16, height: 16 }}
                  >
                    {item.type === "folder" ? "remove" : "done"}
                  </i>
                ) : null}
              </div>
            ) : null}
            <UpdatedRow
              key={item.id}
              {...item}
              index={index}
              paddingLeft={
                showCheck ? 42 + item.level * 16 : 22 + item.level * 16
              }
            />
          </div>
        )
      )}
    </div>
  );
};

export default R.compose(
  decorate,
  CSSModules(css)
)(TreeTable);
