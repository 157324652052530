import React from "react";
import * as R from "ramda";
import moment from "moment";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { Toggle } from "ui-kit/Form/View";
import DeleteConfirmation from "ui-kit/DeleteConfirmation";
import { noop } from "utils/General";
import { DATE_FORMAT, STATUSES_COLORS } from "../constants";
import { Cards, DragIcon } from "components/Base";
import Tooltip from "ui-kit/Tooltip";

const isCloseDateColumn = column => column.value === "close_date_formatted";

const Required = CSSModules(css)(({ row, style = {} }) => (
  <div styleName="center" style={{ width: 80, ...style }}>
    <Toggle size="tiny" fieldId={row.id} iniValue={row.required} bg="green6" />
  </div>
));

const Actions = CSSModules(css)(({ row, customProps, style }) => (
  <div styleName="actions" style={style}>
    <Tooltip tooltip="Edit">
      <div styleName="edit">
        <span
          className="material-icons"
          onClick={() => customProps.openUpdateModal(row)}
        >
          border_color
        </span>
      </div>
    </Tooltip>
    <Tooltip tooltip="Remove">
      <DeleteConfirmation
        onConfirm={() => customProps.deleteRow(row.id)}
        text="Are you sure?"
      >
        <div styleName="remove">
          <span className="material-icons">close</span>
        </div>
      </DeleteConfirmation>
    </Tooltip>
  </div>
));

const DueDate = CSSModules(css)(({ row, customProps, style }) => (
  <div styleName="thirdColumn" style={style}>
    {row[customProps.thirdRow]
      ? moment(row[customProps.thirdRow]).format(DATE_FORMAT)
      : "-"}
  </div>
));

const Status = ({ row, column }) => (
  <div
    style={{
      ...R.propOr({}, "style", column),
      overflow: "hidden",
      textOverflow: "ellipsis",
      textTransform: "capitalize",
      ...(column.type === "status"
        ? { color: STATUSES_COLORS[row[column.value]] }
        : {})
    }}
  >
    {row[column.value]}
  </div>
);

const TextColumn = ({ row, column }) => {
  const displayedVaue =
    isCloseDateColumn(column) && row.close_date
      ? moment(row.close_date).format(DATE_FORMAT)
      : row[column.value] || "-";
  return !column.useInnerdangerously ? (
    <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
      {displayedVaue}
    </div>
  ) : (
    <div
      styleName="sessionDescription"
      dangerouslySetInnerHTML={{
        __html: displayedVaue
      }}
    />
  );
};

const FieldCard = ({ customProps, ...row }) => (
  <div key={row.id} styleName="tableRow" style={{ height: 38 }}>
    {customProps.shouldSort ? <DragIcon /> : null}
    {R.isEmpty(customProps.customColumns) ? (
      <>
        <div styleName="firstColumn">{row[customProps.firstRow] || "-"}</div>
        <div styleName="secondColumn">{row[customProps.secondRow] || "-"}</div>
        <div
          styleName="thirdColumn"
          style={customProps.thirdRow === "due_date" ? {} : { flex: 1 }}
        >
          {row[customProps.thirdRow]
            ? customProps.thirdRow === "due_date"
              ? moment(row[customProps.thirdRow]).format(DATE_FORMAT)
              : row[customProps.thirdRow]
            : "-"}
        </div>
        {customProps.showRequired ? (
          <div styleName="center" style={{ width: 80 }}>
            <Toggle
              size="tiny"
              fieldId={row.id}
              iniValue={row.required}
              bg="green6"
            />
          </div>
        ) : null}
        <Actions row={row} customProps={customProps} />
      </>
    ) : (
      R.map(column => {
        const tooltipContent = column.showTooltip
          ? isCloseDateColumn(column) && row.close_date
            ? moment(row.close_date).format(DATE_FORMAT)
            : row[column.value] || "-"
          : null;
        const containerStyle = {
          display: "flex",
          ...R.propOr({}, "style", column)
        };
        const tooltipStyle = {
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          display: "inline-block"
        };
        return (
          <div key={column.name} style={containerStyle}>
            <Tooltip tooltip={tooltipContent} style={tooltipStyle}>
              {R.propOr(<TextColumn row={row} column={column} />, column.type, {
                actions: <Actions row={row} customProps={customProps} />,
                required: <Required row={row} customProps={customProps} />,
                dueDate: <DueDate row={row} customProps={customProps} />,
                status: <Status row={row} column={column} />
              })}
            </Tooltip>
          </div>
        );
      }, customProps.customColumns)
    )}
  </div>
);

const FieldCardStyled = CSSModules(css)(FieldCard);

const SortableFields = Cards(FieldCardStyled);

const Table = ({
  rows = [],
  deleteRow = noop,
  firstColumn = "Title",
  secondColumn = "Label",
  thirdColumn = "Due Date",
  openUpdateModal,
  firstRow = "title",
  secondRow = "name",
  thirdRow = "due_date",
  showRequired = true,
  emptyText = "You haven't uploaded any files to this portal yet.",
  onReorder = noop,
  columns = [],
  shouldSort = true
}) =>
  R.isEmpty(rows) ? (
    <div styleName="no-items">{emptyText}</div>
  ) : (
    <div styleName="table">
      <div styleName="header">
        {shouldSort ? <DragIcon color="transparent" /> : null}
        {R.isEmpty(columns) ? (
          <>
            <div styleName="firstColumn">{firstColumn}</div>
            <div styleName="secondColumn">{secondColumn}</div>
            <div
              styleName="thirdColumn"
              style={thirdRow === "due_date" ? {} : { flex: 1 }}
            >
              {thirdColumn}
            </div>
            {showRequired ? (
              <div style={{ textAlign: "center", width: 80 }}>Required?</div>
            ) : null}
            <div style={{ textAlign: "center", width: 80 }}>Actions</div>
          </>
        ) : (
          <>
            {R.map(
              column => (
                <div key={column.name} style={R.propOr({}, "style", column)}>
                  {column.name}
                </div>
              ),
              columns
            )}
          </>
        )}
      </div>
      {shouldSort ? (
        <SortableFields
          cards={rows}
          onReorder={onReorder}
          customProps={{
            firstColumn,
            secondColumn,
            thirdColumn,
            deleteRow,
            openUpdateModal,
            firstRow,
            secondRow,
            thirdRow,
            showRequired,
            customColumns: columns,
            shouldSort
          }}
        />
      ) : (
        R.map(
          row => (
            <FieldCardStyled
              {...row}
              customProps={{
                firstColumn,
                secondColumn,
                thirdColumn,
                deleteRow,
                openUpdateModal,
                firstRow,
                secondRow,
                thirdRow,
                showRequired,
                customColumns: columns,
                shouldSort
              }}
            />
          ),
          rows
        )
      )}
    </div>
  );

export default CSSModules(css)(Table);
