import React, { useEffect } from "react";
import * as R from "ramda";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import DocumentTitle from "components/Global/DocumentTitle";
import { getters, actions } from "../model";
// import AboutYou from "./AboutYou";
import YourEvent from "./YourEvent";
import YourOrganization from "./YourOrganization";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { PAGES } from "../constants";

const decorate = R.compose(
  withRouter,
  connect(
    state => ({
      loading: getters.loading(state),
      page: getters.page(state)
    }),
    {
      init: actions.init,
      toggleSession: actions.toggleSession,
      setVerificationHash: actions.setVerificationHash
    }
  ),
  CSSModules(css)
);

const Layout = ({
  page,
  params: { verificationHash },
  setVerificationHash
}) => {
  useEffect(() => {
    setVerificationHash(verificationHash);
  }, []);
  return (
    <DocumentTitle title="Account Verification">
      <div styleName="container">
        {/* logo */}
        <div styleName="logoWrapper">
          <img
            src="https://d2dks4tzxs6xee.cloudfront.net/img/Lennd-white-login.png"
            width="154px"
          />
        </div>
        <div styleName="content">
          {React.cloneElement(
            R.propOr(<YourEvent />, page, {
              // [PAGES.ABOUT]: <AboutYou />,
              [PAGES.EVENT]: <YourEvent />,
              [PAGES.ORGANIZATION]: <YourOrganization />
            })
          )}
        </div>
      </div>
    </DocumentTitle>
  );
};

export default decorate(Layout);
