import React from "react";
import { connect } from "react-redux";

import { actions, getters } from "../model";

import { getTotalGroups, getPages } from "../selectors";

import PaginationBar from "components/Global/ReactTable/Pagination/General";
import Table, { TableWrapper } from "ui-kit/Table/View";
import { Div } from "components/Base";
import ShowNoResults from "ui-kit/ShowNoResults";
import LoadingOverlay from "ui-kit/LoadingOverlay";

import { noop } from "utils/General";

const decorate = connect(
  (state, props) => ({
    total: getTotalGroups(state, props),
    pages: getPages(state, props),
    page: getters.page(state, props),
    pageSize: getters.pageSize(state, props),
    loading: getters.loading(state, props)
  }),
  {
    onPageSizeChange: actions.setPageSize,
    onPageChange: actions.setPage
  }
);

const GroupsTable = ({
  loading = false,
  pageSize = 25,
  pages = 0,
  total = 0,
  page = 0,
  onPageSizeChange = noop,
  onPageChange = noop
}) => {
  return (
    <Div
      display="column.flex-start.stretch"
      flex={1}
      style={{ overflowY: "hidden", position: "relative" }}
    >
      {!loading && total === 0 ? (
        <ShowNoResults flex={1} />
      ) : (
        <>
          {loading && <LoadingOverlay />}
          <TableWrapper>
            <Table />
          </TableWrapper>
          <Div bg="white" bt={1} bc="neutral3" width={1}>
            <PaginationBar
              pageSize={pageSize}
              page={page}
              pages={pages}
              total={total}
              showPageSizeOptions
              pageSizeOptions={[5, 10]}
              showPageJump={false}
              canPrevious={page > 0}
              canNext={page < pages - 1}
              rowsText="groups"
              onPageSizeChange={onPageSizeChange}
              onPageChange={onPageChange}
            />
          </Div>
        </>
      )}
    </Div>
  );
};

export default decorate(GroupsTable);
