import Immutable from "immutable";
import { RECEIVE_INVOICE } from "./constants";

const invoice = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_INVOICE:
      return Immutable.fromJS(state)
        .setIn(
          ["byInvoiceId", action.payload.invoiceId],
          action.payload.invoice
        )
        .toJS();
    default:
      return state;
  }
};

export default invoice;
