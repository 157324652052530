import { TREE_NODE_COLUMN_ID } from "ui-kit/Table/constants";

export const NAMESPACE = "FileList";

export const TABLE_INSTANCE_ID = "file-list";
export const VIEWPICKER_INSTANCE_ID = "file-list";
export const TABLE_VIEW_ID = "file-list-table";

export const STATUS = {
  UPCOMING: "upcoming",
  PAST: "past",
  ALL: "all"
};

export const ROW_ACTIONS = {
  VIEW_RECORD: "row-viewRecord",
  OPEN_EVENT_MODAL: "row-openEventModal"
};

export const FIELD_ID_TO_UPDATE = "name";

export const CUSTOM_TREE_NODE_COLUMN = {
  id: TREE_NODE_COLUMN_ID,
  type: "tree-node",
  name: "Name"
};

export const DEFAULT_TREE_NODE_WIDTH = { [TREE_NODE_COLUMN_ID]: 400 };
