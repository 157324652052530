import * as R from "ramda";

import { createHandlers } from "redux-mvc";

const model = createHandlers({
  iniState: {},
  reducers: {
    updateText: R.identity
  },
  namespace: "LabelSimpleForm"
});

const { getters, actions } = model;

export { getters, actions };

export default model;
