import React from "react";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { connect } from "react-redux";
import { actions, getters } from "../model";
import { getContactRecordTypes } from "../selectors";
import RecordCollapsible from "./RecordCollapsible";
import { Div, BigFilledButton } from "components/Base";

import { FloatingSaveBar } from "EventLight/Common/FieldLayout";

const decorate = R.compose(
  connect(
    state => ({
      contactsCollapsed: getters.contactsCollapsed(state),
      assignedToPeople: getters.assignedToPeople(state),
      contactRecordTypes: getContactRecordTypes(state)
    }),
    {
      setContactsCollapsed: actions.setContactsCollapsed,
      toggleContactRecordType: actions.toggleContactRecordType,
      selectAllContactRecordTypes: actions.selectAllContactRecordTypes,
      deselectAllContactRecordTypes: actions.deselectAllContactRecordTypes,
      showContactAddAssignmentModal: actions.showContactAddAssignmentModal,
      removeAssignedContactId: actions.removeAssignedContactId,
      save: actions.save
    }
  ),
  CSSModules(css)
);

const Body = ({
  contactsCollapsed,
  setContactsCollapsed,
  contactRecordTypes,
  selectAllContactRecordTypes,
  deselectAllContactRecordTypes,
  toggleContactRecordType,
  showContactAddAssignmentModal,
  assignedToPeople,
  removeAssignedContactId,
  save
}) => (
  <Div>
    <RecordCollapsible
      title="Assign to People"
      recordCollapsed={contactsCollapsed}
      toggleRecordCollapsed={() => setContactsCollapsed(!contactsCollapsed)}
      recordList={contactRecordTypes}
      selectAllRecords={selectAllContactRecordTypes}
      deselectAllRecords={deselectAllContactRecordTypes}
      toggleSelectedRecord={toggleContactRecordType}
      showModal={showContactAddAssignmentModal}
      btnText="Assign to person"
      selectedText="Specific people enrolled to fill out questionnaire"
      selectedList={assignedToPeople}
      unassign={removeAssignedContactId}
    />
    <FloatingSaveBar>
      <Div display="row.flex-start.center" mt={5}>
        <BigFilledButton bg="altB5" color="white" onClick={save}>
          Save
        </BigFilledButton>
      </Div>
    </FloatingSaveBar>
  </Div>
);

export default decorate(Body);
