import React from "react";
import {
  Div,
  DarkTab,
  BigOutlineInput,
  PopoverMenu,
  BigOutlineButton,
  SearchIcon,
  searchHandler,
  BigFilledButton,
  Text1,
  Text2,
  Text6,
  DownFilledIcon,
  LoadingIcon
} from "components/Base";
import CanUserDo from "components/Global/Security/CanUserDo";
import DefaultEmptyState from "components/Global/OrderReports/EmptyState";
import OrdersTable from "./OrdersTable";
import { Title, Breadcrumbs } from "Passes/Common";

const View = searchHandler("data", "data")(
  ({
    orderTypeName,
    loading,
    columns,
    views,
    showAddOrderModal,
    moduleId,
    data,
    onSearch,
    syncAllOrders,
    searchTerm,
    exportXLSX,
    exportCSV,
    goToSetup,
    totalPrice,
    isFiltered,
    activeView,
    activeSubView,
    resetView,
    clearSearch,
    orderType,
    EmptyState,
    Actions,
    title = "Requests & Orders"
  }) => {
    const resetFilters = () => {
      clearSearch();
      resetView();
    };
    return (
      <Div>
        <Div
          bb={1}
          bc={"neutral3"}
          bg={"white"}
          display={"row.flex-start.stretch"}
        >
          <Div ml={6} mt={6} flex={1}>
            <Breadcrumbs>{orderTypeName}</Breadcrumbs>
            <Title>{title}</Title>

            <Div mt={2} flex={1} display={"row.flex-start.flex-end"}>
              {views.map(view => (
                <DarkTab
                  key={view.id}
                  px={2}
                  fs={3}
                  active={view.active}
                  onClick={!view.active ? view.selectView : null}
                >
                  {view.name}
                </DarkTab>
              ))}
            </Div>
          </Div>

          <Div mx={4} mb={4} display={"row.flex-start.flex-end"}>
            {Actions ? (
              <Actions />
            ) : (
              <CanUserDo any={[`${moduleId}_add`]}>
                <BigFilledButton onClick={showAddOrderModal} bg={"altB5"}>
                  Create Request/Order
                </BigFilledButton>
              </CanUserDo>
            )}
          </Div>
        </Div>

        <Div mt={2} px={4} flex={1} display={"row.flex-start.flex-end"}>
          {activeView && activeView.subViews
            ? activeView.subViews.map(subView => (
                <DarkTab
                  key={subView.id}
                  px={2}
                  fs={2}
                  active={subView.active}
                  onClick={!subView.active ? subView.selectSubView : null}
                >
                  <subView.icon color={subView.iconColor} mr={1} />
                  {subView.name}
                </DarkTab>
              ))
            : null}
        </Div>

        {activeSubView && activeSubView.header ? (
          <Div
            p={5}
            mx={4}
            brb={2}
            flex={1}
            display="row.space-between.center"
            bg={"altB1"}
          >
            <Div display="column" pr={4} maxWidth={600}>
              <Text2
                display={"column.center.flex-start"}
                fs={2}
                bold={true}
                mb={2}
              >
                {activeSubView.header.title}
              </Text2>
              <Text1 display={"column.center.flex-start"} fs={2}>
                {activeSubView.header.message}
              </Text1>
            </Div>
            {activeSubView.header.LeftContent ? (
              <activeSubView.header.LeftContent onClick={syncAllOrders} />
            ) : (
              ""
            )}
          </Div>
        ) : null}

        <Div px={4} pt={4} display="row.space-between.center">
          <BigOutlineInput
            LeftIcon={SearchIcon}
            value={searchTerm}
            continuous
            placeholder={"Search..."}
            onChange={onSearch}
            style={{ width: "100%", maxWidth: 300 }}
          />
          <CanUserDo any={[`${moduleId}_export`]}>
            <PopoverMenu
              Label={({ onClick }) => (
                <BigOutlineButton RightIcon={DownFilledIcon} onClick={onClick}>
                  Export
                </BigOutlineButton>
              )}
              menuItems={[["XLSX", exportXLSX], ["CSV", exportCSV]]}
            />
          </CanUserDo>
        </Div>

        {loading ? (
          <Div mt={10} display="column.center.center" flex={1}>
            <LoadingIcon size={60} color="neutral4" />
            <Div mt={5}>Loading requests &amp; orders...</Div>
          </Div>
        ) : data.length ? (
          <Div mt={3} mx={5} b={1} bc="neutral0">
            <OrdersTable
              activeView={activeView}
              columns={columns}
              list={data}
            />
          </Div>
        ) : isFiltered || searchTerm.length ? (
          EmptyState ? (
            <EmptyState />
          ) : (
            <DefaultEmptyState
              title="No items match your search."
              bg="white"
              action={{
                title: "View all",
                onClick: resetFilters
              }}
            />
          )
        ) : EmptyState ? (
          <EmptyState />
        ) : (
          <DefaultEmptyState
            title={"There are no items to review"}
            bg="white"
            action={{
              title: "Go to setup",
              onClick: goToSetup
            }}
          />
        )}
      </Div>
    );
  }
);

export default View;
