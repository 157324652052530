import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import {
  Text2,
  BigOutlineButton,
  UploadIcon,
  Cards,
  Div,
  DragIcon
} from "components/Base";
import { actions } from "Sponsors/SelfSetup";
import { getPhotos } from "Sponsors/SelfSetup/selectors";

import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";
import { PackageContainer } from "Sponsors/Common/View";
import { Row, Column } from "ui-kit/Form/View";
import { withProps } from "utils/General";

const decorate = R.compose(
  WithThemeConsumer({ variant: VARIANTS.SURFACE }),
  connect(
    state => ({
      photos: getPhotos(state)
    }),
    {
      showImageModal: actions.showImageModal,
      reorderEventShowcasePhotos: actions.reorderEventShowcasePhotos,
      deleteEventShowcasePhoto: actions.deleteEventShowcasePhoto
    }
  )
);

const Wrapper = withProps({
  display: "row",
  style: {
    flexWrap: "wrap"
  }
})(Div);

const Photo = ({ id, photo_url, deleteEventShowcasePhoto }) => (
  <Div
    mr={2}
    mb={2}
    className="hoverContainer"
    display="row"
    style={{
      width: 90,
      height: 90,
      backgroundImage: `url(${photo_url})`,
      backgroundPosition: "center center",
      backgroundSize: "cover",
      position: "relative",
      overflow: "hidden"
    }}
  >
    <Div
      style={{
        position: "absolute",
        bottom: 0,
        left: 0,
        width: "100%"
      }}
      display="row.space-between.center"
      bg="white"
      p={1}
      bt={1}
      bc="gray1"
      fs={1}
      className="showOnHover"
      shadow={1}
    >
      <DragIcon size={10} mr={2} />
      <Div onClick={() => deleteEventShowcasePhoto(id)} color="primary8">
        Remove
      </Div>
    </Div>
  </Div>
);

const Photos = Cards(Photo, "PHOTOS");

const ImagesSlideshow = ({
  Th = defaultTh,
  photos,
  showImageModal,
  reorderEventShowcasePhotos,
  deleteEventShowcasePhoto
}) => (
  <PackageContainer
    title="Event Images"
    // caption={<Text2>View Example</Text2>}
  >
    <Column {...Th(["bg"], { flex: 1, p: 3, bra: 1 })}>
      <Row style={{ flexWrap: "wrap" }}>
        <Photos
          Wrapper={Wrapper}
          cards={photos}
          onReorder={reorderEventShowcasePhotos}
          deleteEventShowcasePhoto={deleteEventShowcasePhoto}
        />
      </Row>
      <Row>
        <BigOutlineButton
          onClick={showImageModal}
          LeftIcon={UploadIcon}
          {...Th(["bc", "color"])}
        >
          Upload Images
        </BigOutlineButton>
      </Row>
    </Column>
  </PackageContainer>
);

export default decorate(ImagesSlideshow);
