import * as R from "ramda";
import { createSelector } from "reselect";

import { getters } from "./index";
import { eventDetails } from "redux/modules/event/selectors";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

const isModuleEnabled = (enabledModules, moduleId) =>
  enabledModules && Boolean(enabledModules.find(m => m.id === moduleId));

export const getReportGroups = createSelector(
  canEventUserDo,
  eventDetails,
  getters.runningReportIds,
  getters.reports,
  (canDo, event, runningReportIds, reports) => {
    const groups = R.compose(
      R.map(rg => ({
        ...rg,
        reports: R.map(r => ({
          ...r,
          running: runningReportIds.includes(r.id)
        }))(rg.reports)
      })),
      R.filter(
        rg =>
          isModuleEnabled(event.enabled_modules, rg.id) &&
          canDo(`${rg.id}_read`)
      )
    )(reports);

    return groups;
  }
);
