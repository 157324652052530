import React, { Fragment } from "react";
import { connect } from "react-redux";

import { getFilterOptions } from "../selectors";

import PassesTypeSelect from "./PassTypeSelect";
import SourceTypeSelect from "./SourceTypeSelect";
import UserTypeSelect from "./UserTypeSelect";

import FiltersPanel from "ui-kit/FiltersPanel/View";

const decorate = connect((state, props) => ({
  sections: getFilterOptions(state, props)
}));

const Filters = ({ sections = [], ...styleProps }) => (
  <FiltersPanel
    {...styleProps}
    sections={sections}
    footer={
      <Fragment>
        <PassesTypeSelect />
        <SourceTypeSelect />
        <UserTypeSelect />
      </Fragment>
    }
  />
);

export default decorate(Filters);
