import PropTypes from "prop-types";
import React from "react";
import Container from "components/Global/Alerts/Container";
import Header from "components/Global/Alerts/Header";
import Separator from "components/Global/Alerts/Separator";
import PrimaryAction from "components/Global/Alerts/PrimaryAction";

const LockedRow = ({ onAccept }) => (
  <Container>
    <Header>This row is currently locked.</Header>
    <div>
      To make changes to this row, please unapprove or unreject the row first.
    </div>
    <Separator />
    <PrimaryAction onClick={onAccept}>Okay</PrimaryAction>
  </Container>
);

LockedRow.propTypes = {
  onAccept: PropTypes.func.isRequired
};

export default LockedRow;
