import React from "react";
import * as R from "ramda";
import { Title } from "Passes/SetupCommon";
import { withProps } from "utils/General";
import CanUserDo from "components/Global/Security/CanUserDo";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import {
  Div,
  CloseIcon,
  BigShadedInput,
  SearchIcon,
  SmallToggle,
  Text0,
  Text1,
  Text4,
  Text2,
  SmallAvatar,
  BigAvatar,
  CheckIcon,
  Text3,
  Text5,
  BigFilledButton,
  HugeAvatar,
  MediumClearButton,
  SmallFilledButton,
  AddIcon,
  LockIcon,
  DragIcon,
  NoneIcon,
  EditIcon,
  MoreVertIcon,
  PopoverMenu,
  Cards,
  DarkTab,
  makeTable
} from "components/Base";

const headerCell = text => props => (
  <Text2 color="gray8" bold {...props}>
    {text}
  </Text2>
);

const HeaderStyler = withProps({
  display: "row.flex-start.center",
  p: 3,
  bg: "gray0"
})(Div);

const RowStyler = withProps({
  display: "row.flex-start.center",
  bb: 1,
  bc: "neutral1",
  py: 2,
  px: 3
})(Div);

const Well = withProps({
  width: 1,
  bra: 2,
  bg: "gray0",
  p: 4,
  display: "row.flex-start.center"
})(Div);

const UserCell = ({ id, photoUrl, email, name }) => (
  <Div display="row.flex-start.center">
    <SmallAvatar text={name} photoURL={photoUrl} />
    <Div ml={2} display="column.center.flex-start">
      <Text3 bold color="gray7">
        {name}
      </Text3>
      <Text2>{email}</Text2>
    </Div>
  </Div>
);
const RoleCell = ({ role }) => <Text2>{role}</Text2>;
const PermissionRoleCell = ({ permissionTitle }) => (
  <Text2>{permissionTitle}</Text2>
);
const ApprovalCell = ({ canApprove, onToggleApprover }) => (
  <Div display="row.center.center">
    <SmallToggle active={canApprove} onClick={onToggleApprover} />
  </Div>
);
const ActionsCell = ({ onEdit }) => (
  <Div display="row.center.center">
    <CanUserDo action={`${STANDARD_MODULES.settings.id}_manage_users`}>
      <Div fs={2} fw={3} onClick={onEdit} color="primary5">
        Edit
      </Div>
    </CanUserDo>
  </Div>
);

const sharedTableProps = {
  TableStyler: withProps({ bg: "white", bra: 2, mt: 3 })(Div),
  HeaderStyler,
  RowStyler,
  HeaderCellStyler: Div,
  RowCellStyler: Div
};

const ManagersTable = makeTable({
  ...sharedTableProps,
  columnProps: [
    { width: 5 / 15 },
    { width: 4 / 15 },
    { width: 2 / 15 },
    { width: 2 / 15, br: 1, bc: "neutral1" },
    { width: 2 / 15 }
  ],
  headerCellComps: [
    headerCell("Name"),
    headerCell("Role"),
    headerCell("Permission"),
    withProps({ textAlign: "center" })(headerCell("Approver")),
    withProps({ textAlign: "center" })(headerCell("Actions"))
  ],
  rowCellComps: [
    UserCell,
    RoleCell,
    PermissionRoleCell,
    ApprovalCell,
    ActionsCell
  ]
});

const UsersTable = makeTable({
  ...sharedTableProps,
  columnProps: [
    { width: 5 / 13 },
    { width: 4 / 13 },
    { width: 2 / 13, br: 1, bc: "neutral1" },
    { width: 2 / 13 }
  ],
  headerCellComps: [
    headerCell("Name"),
    headerCell("Role"),
    headerCell("Permission"),
    withProps({ textAlign: "center" })(headerCell("Actions"))
  ],
  rowCellComps: [UserCell, RoleCell, PermissionRoleCell, ActionsCell]
});

const MoreVertButton = ({ onClick }) => (
  <MoreVertIcon onClick={onClick} ml={2} size={20} />
);

const makeDisabled = isDisabled => ({
  opacity: isDisabled ? 0.5 : 1,
  pointerEvents: isDisabled ? "none" : "auto"
});

const WorkflowRow = ({
  isDefault,
  index,
  name,
  subtitle,
  active,
  toggleWorkflow,
  openDefaultWorkflow,
  openWorkflow,
  deleteWorkflow,
  hasTriggerAndApproval
}) => {
  return (
    <Div
      bg={"white"}
      px={2}
      py={3}
      my={1}
      bra={1}
      shadow={1}
      display={"row.flex-start.center"}
    >
      {isDefault ? <LockIcon size={20} /> : <DragIcon size={20} />}
      <Text2 ml={2} bold>
        {index + 1}
      </Text2>
      <Div ml={3} flex={1}>
        <Text3 bold>{name}</Text3>
        {subtitle && (
          <Text0 bold color={"neutral5"}>
            {subtitle}
          </Text0>
        )}
      </Div>

      <SmallFilledButton
        pill
        bg={"blue7"}
        LeftIcon={EditIcon}
        onClick={isDefault ? openDefaultWorkflow : openWorkflow}
      >
        Configure workflow
      </SmallFilledButton>

      <Div style={makeDisabled(isDefault || !hasTriggerAndApproval)}>
        <SmallToggle
          ml={3}
          active={isDefault || active}
          onClick={toggleWorkflow}
        />
      </Div>

      {isDefault ? (
        <NoneIcon size={20} ml={2} />
      ) : (
        <PopoverMenu
          Label={MoreVertButton}
          menuItems={[
            ["Open", openWorkflow],
            // ["Duplicate", duplicateWorkflow],
            ["Delete", deleteWorkflow]
          ]}
        />
      )}
    </Div>
  );
};

const WorkflowRows = Cards(WorkflowRow, "WORKFLOW_ROWS");

const Advanced = ({
  workflowsAreActive,
  workflows,
  toggleWorkflows,
  addWorkflow,
  reorderWorkflows,
  openDefaultWorkflow
}) => {
  return (
    <Div>
      <Div maxWidth={800}>
        <Div
          mt={4}
          mb={4}
          display={"row.flex-start.center"}
          bg="white"
          bra={1}
          b={1}
          bc="neutral1"
          width={280}
          p={4}
        >
          <SmallToggle active={workflowsAreActive} onClick={toggleWorkflows} />
          <Text3 ml={2} bold>{`Approval Workflows ${
            workflowsAreActive ? "ON" : "OFF"
          }`}</Text3>
        </Div>

        <Div
          p={4}
          style={makeDisabled(!workflowsAreActive)}
          bg="neutral0"
          bra={1}
        >
          <Text5>Workflows</Text5>
          {/*
          <BigFilledButton
            onClick={addWorkflow}
            pill
            mt={4}
            bg={"blue7"}
            LeftIcon={AddIcon}
          >
            Create Workflow
          </BigFilledButton>
          */}
          <Div mt={4}>
            <WorkflowRows
              isDefault={false}
              cards={workflows}
              onReorder={reorderWorkflows}
            />
            <WorkflowRow
              isDefault={true}
              key={"basic workflow"}
              index={R.length(workflows)}
              name={"Basic Approval"}
              subtitle={
                "Will be used as a fallback if no other workflows are triggered"
              }
              openDefaultWorkflow={openDefaultWorkflow}
            />
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

const Basic = ({
  searchTerm,
  managerRoles,
  managerData,
  onSearch,
  userData,
  onInviteUser
}) => (
  <Div>
    <Div display="row.flex-start.center" pt={2}>
      <BigShadedInput
        placeholder="Search users..."
        bg="gray1"
        LeftIcon={SearchIcon}
        RightIcon={
          searchTerm.length
            ? withProps({ onClick: () => onSearch("") })(CloseIcon)
            : undefined
        }
        mr={2}
        placeholder="Search..."
        flex={1}
        continuous
        value={searchTerm}
        onChange={val => onSearch(val)}
      />
      <CanUserDo action={`${STANDARD_MODULES.settings.id}_manage_users`}>
        <BigFilledButton bg="altB5" onClick={onInviteUser}>
          Invite New User
        </BigFilledButton>
      </CanUserDo>
    </Div>
    {searchTerm.length && !managerData.length && !userData.length ? (
      ""
    ) : (
      <Div pb={10}>
        <Well mt={4}>
          <BigAvatar Icon={CheckIcon} bg="success5" />
          <Div ml={4}>
            <Text3 bold>
              To give a user access to Passes - You must assign them a
              permission profile with access.
            </Text3>
            <Text2 bold>Permission Profiles with access: {managerRoles}</Text2>
            <Text2>
              To create a new permission profile - go to: Settings > Users >
              Permissions
            </Text2>
          </Div>
        </Well>

        <ManagersTable
          emptyState={
            <Div py={10} display="row.center.center">
              <Text3>
                No managers{" "}
                {searchTerm.length ? `found for '${searchTerm}'` : ""}{" "}
              </Text3>
            </Div>
          }
          rowsData={managerData}
        />
      </Div>
    )}
    {userData.length ? (
      <Div>
        <Well mt={4}>
          <BigAvatar Icon={CloseIcon} bg="danger5" />
          <Div ml={4}>
            <Text3 bold>These users do NOT have access to Passes</Text3>
            <Text2 bold>
              To grant them access, change their Permission Profile to one with
              access: {managerRoles}
            </Text2>
            <Text2>
              OR - create a new permission profile in: Settings > Users >
              Permissions
            </Text2>
          </Div>
        </Well>
        <UsersTable rowsData={userData} />
      </Div>
    ) : (
      ""
    )}
    {searchTerm.length && !managerData.length && !userData.length ? (
      <Div py={10} display="column.center.center">
        <Text5 pb={4}>
          No users found for <strong>'{searchTerm}'</strong>
        </Text5>
        <BigFilledButton bg="altB5" onClick={() => onSearch("")}>
          Clear Search
        </BigFilledButton>
      </Div>
    ) : (
      ""
    )}
  </Div>
);

const View = ({ activeRoute, loading, tabs, ...props }) => (
  <Div style={{ width: "100%" }}>
    <Title>Managers & Approvers</Title>
    <Div pt={3}>
      <Div bb={1} bc="gray2" px={4}>
        {tabs.map(tab => (
          <DarkTab
            key={tab.id}
            px={2}
            fs={3}
            active={tab.isActive}
            onClick={tab.onClick}
          >
            {tab.name}
          </DarkTab>
        ))}
      </Div>
      <Div pb={4}>
        {R.prop(activeRoute, {
          PassesSetupApprovers: <Basic {...props} />,
          PassesSetupApproversAdvanced: <Advanced {...props} />
        })}
      </Div>
    </Div>
  </Div>
);

export default View;
