import { combineReducers } from "redux";
import {
  RECEIVE,
  RECEIVE_USER,
  REQUEST,
  ERROR,
  CLEAR_ERROR,
  INVALIDATE
} from "./constants";

const users = (state = [], action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload.users.payload;
    case INVALIDATE:
      return {};
    default:
      return state;
  }
};

const user = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_USER:
      return action.payload;
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case ERROR:
      return action.payload;
    case CLEAR_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  users,
  user,
  error,
  fetching
});
