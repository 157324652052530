import React from "react";
import * as R from "ramda";

import {
  Div,
  SmallToggle,
  SuperOutlineBox,
  Text0,
  Text2,
  Text3,
  Text5,
  Text6,
  BigFilledButton,
  SmallFilledButton,
  AddIcon,
  LockIcon,
  DragIcon,
  NoneIcon,
  EditIcon,
  MoreVertIcon,
  PopoverMenu,
  Cards
} from "components/Base";

const MoreVertButton = ({ onClick }) => (
  <MoreVertIcon onClick={onClick} ml={2} size={20} />
);

const makeDisabled = isDisabled => ({
  opacity: isDisabled ? 0.5 : 1,
  pointerEvents: isDisabled ? "none" : "auto"
});

const WorkflowRow = ({
  isDefault,
  index,
  name,
  subtitle,
  active,
  toggleWorkflow,
  openDefaultWorkflow,
  openWorkflow,
  duplicateWorkflow,
  deleteWorkflow,
  hasTriggerAndApproval
}) => {
  return (
    <Div
      bg={"white"}
      px={2}
      py={3}
      my={1}
      bra={1}
      shadow={1}
      display={"row.flex-start.center"}
    >
      {isDefault ? <LockIcon size={20} /> : <DragIcon size={20} />}
      <Text2 ml={2} bold>
        {index + 1}
      </Text2>
      <Div ml={3} flex={1}>
        <Text3 bold>{name}</Text3>
        {subtitle && (
          <Text0 bold color={"neutral5"}>
            {subtitle}
          </Text0>
        )}
      </Div>

      <SmallFilledButton
        pill
        bg={"blue7"}
        LeftIcon={EditIcon}
        onClick={isDefault ? openDefaultWorkflow : openWorkflow}
      >
        Configure workflow
      </SmallFilledButton>

      <Div style={makeDisabled(isDefault || !hasTriggerAndApproval)}>
        <SmallToggle
          ml={3}
          active={isDefault || active}
          onClick={toggleWorkflow}
        />
      </Div>

      {isDefault ? (
        <NoneIcon size={20} ml={2} />
      ) : (
        <PopoverMenu
          Label={MoreVertButton}
          menuItems={[
            ["Open", openWorkflow],
            // ["Duplicate", duplicateWorkflow],
            ["Delete", deleteWorkflow]
          ]}
        />
      )}
    </Div>
  );
};

const WorkflowRows = Cards(WorkflowRow, "WORKFLOW_ROWS");

const View = ({
  workflowsAreActive,
  workflows,
  toggleWorkflows,
  addWorkflow,
  reorderWorkflows,
  openDefaultWorkflow
}) => {
  return (
    <Div>
      <Div
        bb={1}
        bc="gray3"
        bg="white"
        display="row.flex-start.flex-end"
        height={80}
      >
        <Div flex={1}>
          <Text6 pl={8} pt={6}>
            Approval Workflows
          </Text6>
        </Div>
      </Div>

      <Div maxWidth={800} ml={8}>
        <Div
          mt={4}
          mb={4}
          display={"row.flex-start.center"}
          bg="white"
          bra={1}
          b={1}
          bc="neutral1"
          width={280}
          p={4}
        >
          <SmallToggle active={workflowsAreActive} onClick={toggleWorkflows} />
          <Text3 ml={2} bold>{`Approval Workflows ${
            workflowsAreActive ? "ON" : "OFF"
          }`}</Text3>
        </Div>

        <Div
          p={4}
          style={makeDisabled(!workflowsAreActive)}
          bg="neutral0"
          bra={1}
        >
          <Text5>Workflows</Text5>
          <BigFilledButton
            onClick={addWorkflow}
            pill
            mt={4}
            bg={"blue7"}
            LeftIcon={AddIcon}
          >
            Create Workflow
          </BigFilledButton>
          <Div mt={4}>
            <WorkflowRows
              isDefault={false}
              cards={workflows}
              onReorder={reorderWorkflows}
            />
            <WorkflowRow
              isDefault={true}
              key={"basic workflow"}
              index={R.length(workflows)}
              name={"Basic Approval"}
              subtitle={
                "Will be used as a fallback if no other workflows are triggered"
              }
              openDefaultWorkflow={openDefaultWorkflow}
            />
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default View;
