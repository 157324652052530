import React, { Component } from "react";
import { withState } from "utils/General";
import Report from "Portal/PortalReports/CateringReport";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getCateringReport } from "redux/modules/portal/reports/actions";
import { Div, LoadingIcon } from "components/Base";

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCateringReport
    },
    dispatch
  );
}

class AccountCateringReport extends Component {
  async componentDidMount() {
    await this.props.getCateringReport({
      eventId: this.props.params.eventId,
      accountId: this.props.params.recordId
    });
    this.props.setLoadState(false);
  }
  render() {
    if (this.props.loading) {
      return (
        <Div p={10} display="row.center.center">
          <LoadingIcon size={40} />
        </Div>
      );
    }
    return <Report view="admin" />;
  }
}

export default connect(
  null,
  mapDispatchToProps
)(withState("loading", "setLoadState", true)(AccountCateringReport));
