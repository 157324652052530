import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getters, actions } from "../index";
import {
  getCountOfToggledRows,
  getFieldsForEditModal,
  getToggledRowsWithName,
  getSearchbarPlaceholder,
  getToggledRows
} from "../selectors";

import { withProps } from "utils/General";

import { TABLE_INSTANCE_ID } from "EventLight/Agenda/Manage/constants";
import { actions as TableActions } from "ui-kit/Table/model";

import CSSModules from "react-css-modules";
import css from "ui-kit/ViewPicker/View/styles.scss";
import Tooltip from "components/Global/Tooltip";

import {
  Div,
  FontIcon,
  DownloadIcon,
  EditIcon,
  MediumFilledButton,
  BigFilledButton,
  DownFilledIcon,
  PopMenu,
  PopMenuCard,
  MenuItem,
  AddIcon
} from "components/Base";

import { VARIANTS } from "ui-kit/Theme/constants";
import UISearchBar from "ui-kit/SearchBar/View";
import CanUserDo from "components/Global/Security/CanUserDo";

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      moduleId: getters.moduleId(state),
      countOfToggledRows: getCountOfToggledRows(state),
      toggledRows: getToggledRows(state),
      toggledRowsWithName: getToggledRowsWithName(state),
      fields: getFieldsForEditModal(state, props),
      references: getters.references(state),
      placeholder: getSearchbarPlaceholder(state)
    }),
    {
      onExport: actions.exportData,
      showSubscribeModal: actions.showSubscribeModal,
      showAddSessionModal: actions.showAddSessionModal,
      showDeleteSessionModal: actions.showDeleteSessionModal,
      showBulkEditModal: actions.showBulkEditModal,
      duplicateSessions: actions.duplicateSessions,
      clearSelectedRows: () =>
        TableActions.clearSelectedRows(null, {
          meta: {
            instanceId: TABLE_INSTANCE_ID
          }
        })
    }
  )
);

const Button = ({ LeftIcon, RightIcon, children, onClick }) => (
  <MediumFilledButton
    className={css.button1Light}
    color="#222"
    hover={false}
    onClick={onClick}
    mr={2}
    LeftIcon={LeftIcon}
    RightIcon={RightIcon}
  >
    {children}
  </MediumFilledButton>
);

const SessionsToolbar = ({
  moduleId,
  countOfToggledRows,
  toggledRows,
  clearSelectedRows,
  showDeleteSessionModal,
  showBulkEditModal,
  duplicateSessions,
  fields,
  references
}) => (
  <Div display="row.flex-start.center">
    <Div fw={4} mr={4} fs={4} ml={2} color="#222">
      {countOfToggledRows} Selected
    </Div>

    <CanUserDo action={`${moduleId}_update`}>
      <Button
        LeftIcon={withProps({
          color: "#999"
        })(EditIcon)}
        onClick={() => showBulkEditModal({ toggledRows, fields, references })}
      >
        Edit
      </Button>

      <Button
        LeftIcon={withProps({
          children: "content_copy",
          color: "#999",
          fs: 4
        })(FontIcon)}
        onClick={() => duplicateSessions(toggledRows)}
      >
        Duplicate
      </Button>

      {/*
      <Button
        LeftIcon={withProps({
          children: "show_chart",
          color: "#999",
          fs: 4
        })(FontIcon)}
        onClick={() => handlers.showRunReportModal(toggledRows)}
      >
        Run Report
      </Button>
      */}

      {/*
      <SendEmailPopoverButton
        {...{
          handlers,
          toggledRows,
          countOfToggledRows
        }}
      />
      */}

      <CanUserDo action={`${moduleId}_delete`}>
        <Button
          onClick={() => {
            showDeleteSessionModal(toggledRows);
          }}
        >
          Delete
        </Button>
      </CanUserDo>
    </CanUserDo>
    <Div ml={2} fw={4} color="#222" onClick={clearSelectedRows}>
      Clear selected
    </Div>
  </Div>
);

const Toolbar = ({
  onExport,
  handlers,
  moduleId,
  countOfToggledRows,
  toggledRows,
  clearSelectedRows,
  fields,
  references,
  placeholder,
  showAddSessionModal,
  showDeleteSessionModal,
  showBulkEditModal,
  showSubscribeModal,
  duplicateSessions
}) => (
  <Div
    display="row.space-between.center"
    width={1}
    height={75}
    px={3}
    style={{ position: "relative", flexShrink: 0 }}
  >
    {!countOfToggledRows ? (
      <>
        <Div display="row.flex-start.center" flex={1}>
          <UISearchBar
            variant={VARIANTS.SURFACE}
            flex={1}
            mr={2}
            placeholder={placeholder}
            height={43}
            style={{
              maxWidth: 445
            }}
          />
        </Div>

        <Div display="row.flex-start.center">
          <CanUserDo any={[`${moduleId}_manage`, `${moduleId}_export`]}>
            <Tooltip tooltip="Subscribe to feed">
              <span>
                <BigFilledButton
                  mr={2}
                  onClick={showSubscribeModal}
                  className={css.button1Light}
                >
                  <FontIcon fs={4} color="#222">
                    rss_feed
                  </FontIcon>
                </BigFilledButton>
              </span>
            </Tooltip>

            <PopMenu
              Label={({ onClick }) => (
                <BigFilledButton
                  className={css.button1Light}
                  hover={false}
                  color="#222"
                  RightIcon={withProps({
                    color: "#999"
                  })(DownFilledIcon)}
                  onClick={onClick}
                >
                  Options
                </BigFilledButton>
              )}
            >
              {({ closeMenu }) => (
                <PopMenuCard position="bottom" align="right" zIndex={20}>
                  <CanUserDo action={`${moduleId}_export`}>
                    {/* <Div width={1} style={{ height: "1px" }} bg="gray3" /> */}
                    <MenuItem
                      LeftIcon={DownloadIcon}
                      onClick={() => {
                        onExport("csv");
                        closeMenu();
                      }}
                    >
                      Export .CSV
                    </MenuItem>
                    <MenuItem
                      LeftIcon={DownloadIcon}
                      onClick={() => {
                        onExport("xlsx");
                        closeMenu();
                      }}
                    >
                      Export .XLSX
                    </MenuItem>
                  </CanUserDo>
                </PopMenuCard>
              )}
            </PopMenu>
          </CanUserDo>

          <CanUserDo action={`${moduleId}_create`}>
            <BigFilledButton
              bg="orange5"
              color="white"
              ml={2}
              onClick={showAddSessionModal}
              LeftIcon={AddIcon}
            >
              Add
            </BigFilledButton>
          </CanUserDo>
        </Div>
      </>
    ) : (
      <SessionsToolbar
        {...{
          moduleId,
          countOfToggledRows,
          toggledRows,
          clearSelectedRows,
          showDeleteSessionModal,
          showBulkEditModal,
          fields,
          references,
          handlers,
          duplicateSessions
        }}
      />
    )}
  </Div>
);

export default CSSModules(decorate(Toolbar), css);
