import React from "react";

import { Div } from "components/Base";

import Header from "./Header";

import Body from "./Body";

import Footer from "./Footer";

const Frame = ({ children }) => (
  <Div m={5} style={{ overflow: "none" }} display="row.center.center">
    <Div
      display="column.flex-start"
      bra={1}
      shadow={2}
      bg="white"
      height={661}
      width={743}
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxHeight: "calc(100% - 100px)"
      }}
      onMouseDown={e => e.stopPropagation()}
    >
      {children}
    </Div>
  </Div>
);

const Layout = ({ hideModal, handlers, onDone }) => {
  return (
    <Frame>
      <Div
        width={1}
        height={1}
        bg="white"
        bra={1}
        style={{ overflow: "hidden" }}
      >
        <Header hideModal={hideModal} />
        <Body handlers={handlers} />
        <Footer
          hideModal={hideModal}
          showAddQuestionSetModal={handlers.showAddQuestionSetModal}
          showAddQuestionModal={handlers.showAddQuestionModal}
          saveOnDone={onDone}
        />
      </Div>
    </Frame>
  );
};

export default Layout;
