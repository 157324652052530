import { SHOW, HIDE } from "./constants";

export function showModal(payload) {
  return {
    type: SHOW,
    payload
  };
}

export function hideModal() {
  return {
    type: HIDE
  };
}
