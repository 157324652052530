import React, { Component } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import StatusCircle from "ui-kit/StatusCircle";

import { getters, actions } from "../../model";
import { getValue } from "../../utils";

const decorate = connect(
  (state, props) => ({
    canEditCells: getters.canEditCells(state, props)
  }),
  bindInstance({
    saveValue: actions.saveValue
  })
);

class Form extends Component {
  toggleTask = () => {
    const isComplete = getValue(this.props);

    this.props.saveValue({
      value: !isComplete,
      column: this.props.field,
      row: this.props.data
    });
  };

  render() {
    const isComplete = getValue(this.props);

    return (
      <div
        onClick={this.props.canEditCells ? this.toggleTask : undefined}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: "100%",
          padding: 8,
          borderRadius: 4,
          cursor: this.props.canEditCells ? "pointer" : undefined
        }}
      >
        <StatusCircle
          onClick={this.props.canEditCells ? () => {} : undefined}
          status={isComplete ? "task_complete" : "task_not_complete"}
        />
      </div>
    );
  }
}

export default decorate(Form);
