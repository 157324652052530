/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  post: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: data.eventId
          ? `${window.__LENND_API_BASE_URL__}/event/${data.eventId}/email-merge-tags`
          : `${window.__LENND_API_BASE_URL__}/organizations/${data.orgId}/email-merge-tags`,
        data,
        credentials,
        qs: { ...data.options, moduleId: data.moduleId },
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */

      Helpers.request(options);
    }),
  getPreview: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: data.eventId
          ? `${window.__LENND_API_BASE_URL__}/event/${data.eventId}/email-merge-tags/preview`
          : `${window.__LENND_API_BASE_URL__}/organizations/${data.orgId}/email-merge-tags/preview`,
        data,
        credentials,
        qs: { ...data.options, moduleId: data.moduleId },
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */

      Helpers.request(options);
    }),
  getPreviews: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: data.eventId
          ? `${window.__LENND_API_BASE_URL__}/event/${data.eventId}/email-merge-tags/previews`
          : `${window.__LENND_API_BASE_URL__}/organizations/${data.orgId}/email-merge-tags/previews`,
        data,
        credentials,
        qs: { ...data.options, moduleId: data.moduleId },
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */

      Helpers.request(options);
    }),
  getStatus: (credentials, { jobId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        // eslint-disable-next-line no-underscore-dangle
        url: `${window.__LENND_API_BASE_URL__}/jobs/progress/${jobId}`,
        credentials,
        success,
        error
      });
    }),
  updateOrder: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/orders/orders/${data.orderId}`,
        credentials,
        data,
        success,
        error
      });
    })
};
