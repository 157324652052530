import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "Portal/PortalForm/model";
import { TABS } from "Portal/PortalForm/constants";
import { Text1, Div } from "components/Base";
import Table, { TableWrapper } from "ui-kit/Table/View";
import { TABLE_INSTANCE_ID } from "Portal/PortalForm/constants";
import { getRowActions } from "Portal/PortalForm/selectors";

const decorate = connect(
  state => ({
    data: getters.data(state),
    activeTab: getters.activeTab(state),
    loading: getters.loading(state),
    rowActions: getRowActions(state)
  }),
  {
    setSubmissionsTabFilter: actions.setSubmissionsTabFilter
  }
);

const SubmissionsCard = ({
  data,
  activeTab,
  setSubmissionsTabFilter,
  loading,
  rowActions
}) => (
  <Div bg="white" bra={2} shadow={1} width={1} mt={3}>
    <Div
      width={1}
      display="row.flex-start.center"
      height={46}
      bb={1}
      bc="neutral1"
      px={4}
    >
      <Div
        color={activeTab === TABS.ALL ? "indigo7" : "black"}
        fw={3}
        fs={3}
        onClick={() => setSubmissionsTabFilter(TABS.ALL)}
        height={1}
        display="row.flex-start.center"
        bb={2}
        bc={activeTab === TABS.ALL ? "indigo7" : "transparent"}
        px={1}
      >
        All
        <Div bg="neutral2" pill pr={2} pl={2} ml={2}>
          <Text1 bold>{data.count}</Text1>
        </Div>
      </Div>
      <Div
        color={activeTab === TABS.SUBMITTED ? "indigo7" : "black"}
        fw={3}
        fs={3}
        ml={4}
        onClick={() => setSubmissionsTabFilter(TABS.SUBMITTED)}
        height={1}
        display="row.flex-start.center"
        bb={2}
        bc={activeTab === TABS.SUBMITTED ? "indigo7" : "transparent"}
        px={1}
      >
        Submitted
        <Div bg="neutral2" pill pr={2} pl={2} ml={2}>
          <Text1 bold>{data.count_of_submitted}</Text1>
        </Div>
      </Div>
      <Div
        color={activeTab === TABS.DRAFT ? "indigo7" : "black"}
        fw={3}
        fs={3}
        ml={4}
        onClick={() => setSubmissionsTabFilter(TABS.DRAFT)}
        height={1}
        display="row.flex-start.center"
        bb={2}
        bc={activeTab === TABS.DRAFT ? "indigo7" : "transparent"}
        px={1}
      >
        Drafts
        <Div bg="neutral2" pill pr={2} pl={2} ml={2}>
          <Text1 bold>{data.count_of_drafts}</Text1>
        </Div>
      </Div>
    </Div>
    <Div width={1}>
      <TableWrapper
        instanceId={TABLE_INSTANCE_ID}
        loading={loading}
        style={{ flex: 1 }}
      >
        <Table
          instanceId={TABLE_INSTANCE_ID}
          rowActions={rowActions}
          showOpenRecord
          showCheckbox={false}
          columnActions={[]}
          canEditCells={false}
        />
      </TableWrapper>
    </Div>
  </Div>
);

export default decorate(SubmissionsCard);
