/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  getNotificationStats: ({ credentials }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/notifications/status`,
        credentials,
        success,
        error
      });
    }),
  getNotificationsByEvent: ({ credentials, qs }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/notifications`,
        credentials,
        qs,
        success,
        error
      });
    }),
  setNotificationStatus: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/notifications/unread`,
        credentials,
        data,
        success,
        error
      });
    })
};
