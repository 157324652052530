export const NAMESPACE = "OrgLightBilling";

export const FORM_ID = "OrgLightBillingForm";

export const FIELD_IDS = {
  BILLING_EMAIL: "billing_email"
};

export const CHARGEBEE_SITE = "lennd";

export const PLANS = {
  FREE: "free",
  LAUNCH_MONTHLY: "launch-monthly",
  LAUNCH_ANNUAL: "launch-annual",
  PRO_ANNUAL: "pro-annual",
  CUSTOM: "custom"
};

export const ADDONS = {
  ADDITIONAL_ADMIN_MONTHLY: "additional-admin-(monthly)",
  ADDITIONAL_ADMIN_ANNUAL: "additional-admin-(annual)",
  ADDITIONAL_REGISTRATION_LAUNCH: "additional-registration-(launch)",
  ADDITIONAL_REGISTRATION_PRO: "additional-registration-(pro)"
};
