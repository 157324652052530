import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import { Dropdown } from "components/Base";
import css from "./styles.scss";
import { ArrowRenderer } from "components/Global/Editors/utils/reactSelect";

@CSSModules(css)
class QuantityDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false
    };
  }

  @autobind
  onBlur() {
    this.setState({
      focused: false
    });
  }

  @autobind
  onFocus() {
    this.setState({
      focused: true
    });
  }

  onChange = data => {
    this.props.onChange(this.props.option, data.value || 0);
  };

  renderOption = option => <span>{option.label}</span>;

  render() {
    const { disabled, quantity, autofocus, quantityOptions } = this.props;
    if (Number(quantity) || this.state.focused) {
      return (
        <Dropdown
          usePortal={this.props.usePortal}
          arrowRenderer={ArrowRenderer}
          autofocus={autofocus}
          clearable={false}
          disabled={disabled}
          onChange={this.onChange}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          openAfterFocus={autofocus}
          optionRenderer={this.renderOption}
          options={quantityOptions.map(({ value }) => ({
            label: value,
            value
          }))}
          placeholder=""
          searchable
          value={quantity}
        />
      );
    }
    return "";
  }
}

QuantityDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  option: PropTypes.object.isRequired,
  quantity: PropTypes.number,
  disabled: PropTypes.bool,
  autofocus: PropTypes.bool,
  quantityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number
    })
  ).isRequired
};

export default QuantityDropdown;
