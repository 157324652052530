import React from "react";
import { Div, CloseIcon } from "components/Base";
import { noop } from "utils/General";
const getInitials = name => {
  const splitName = name.split(" ");
  const first = splitName[0] || " ";
  const last = splitName[1] || " ";

  return `${first[0].toUpperCase()}${last[0].toUpperCase()}`;
};

const Header = ({
  hideModal = noop,
  heading = "",
  typeName = "",
  canCloseModal = true
}) => (
  <Div width={1} p={5} bg="primary8" display="row.space-between.center">
    <Div display="row.flex-start.center">
      <Div
        height={40}
        width={40}
        display="row.center.center"
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.27)",
          borderRadius: 30,
          color: "white"
        }}
        mr={2}
        fs={5}
      >
        {getInitials(heading)}
      </Div>
      <Div fs={5} color="white">
        {heading}
      </Div>
      <Div ml={1} fs={5} color="white" style={{ opacity: 0.5 }}>
        ({typeName})
      </Div>
    </Div>
    {canCloseModal && <CloseIcon size={30} color="white" onClick={hideModal} />}
  </Div>
);

export default Header;
