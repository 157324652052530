import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Link = ({ name, icon, active, handleClick, count, size }) => (
  <div styleName={active ? "linkActive" : "link"} onClick={handleClick}>
    <div styleName={size ? `label-${size}` : "label"}>
      {icon}
      {name}
    </div>
    {count ? <div styleName="count">{count}</div> : null}
  </div>
);

Link.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.object,
  active: PropTypes.bool,
  handleClick: PropTypes.func,
  count: PropTypes.number,
  size: PropTypes.oneOf(["small"])
};

export default CSSModules(Link, css);
