import React from "react";
import { connect } from "react-redux";
import { getLogoImageUrl } from "Portal/IntakeForm/selectors";

const decorate = connect(state => ({
  logoImageUrl: getLogoImageUrl(state)
}));

const Logo = ({ logoImageUrl }) =>
  logoImageUrl ? (
    <div
      style={{
        width: "135px",
        minHeight: "100px"
      }}
    >
      <img
        src={logoImageUrl}
        style={{
          maxWidth: "100%"
        }}
      />
    </div>
  ) : null;

export default decorate(Logo);
