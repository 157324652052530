import { CONTACT } from "components/Event/Credentials/constants/order/standard-fields";

export default {
  mustBeChildField: false,
  canBeChildField: false,
  canBeParentField: true,
  canBeChildOfFieldTypes: [],
  canBeParentOfGridFieldTypes: [],
  mappableFields: [
    {
      id: CONTACT,
      name: "Contact Name",
      mappableFieldTypes: ["reference"]
    }
  ]
};
