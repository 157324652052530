export default {
  address_city: "2767c901-4a73-4909-81b4-ae853c499efd",
  address_country: "f396dbc4-7d93-4c24-8240-6e35a852c66e",
  address_state: "f8ee941b-307f-49f5-ac6a-07baaa92de2a",
  address_street_line_1: "6a0fa06c-8685-48f2-8347-e420f87086aa",
  address_street_line_2: "4bf1f90b-5059-4e65-aec3-b8a997d2823b",
  address_zip: "68d917cb-5e1c-40db-bed1-b0719aab6bed",
  attachments: "97d6b7fc-c059-4c49-8801-88758f5760cc",
  description: "e8b47247-d758-4a56-95d5-7d4acfc87522",
  name: "e8f3dac1-cce5-42be-bab1-0a88e8c03a72",
  phone: "bae778db-289d-4890-a5b7-4d3e2150bd71",
  website: "0a93676a-6093-4078-a9f7-e2455dcbf982"
};
