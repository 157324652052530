import React from "react";
import { connect } from "react-redux";

import { Div, Text2, QuarterSizeDotIcon } from "components/Base";

import { noop } from "utils/General";

import { actions } from "ui-kit/MiniItemsSelector";

import { getSelectedVariantType } from "ui-kit/MiniItemsSelector/selectors";
import { bindInstance } from "redux-mvc";

const decorate = connect(
  (state, props) => ({
    groups: getSelectedVariantType(state, props)
  }),
  bindInstance({
    selectAll: actions.selectAll,
    clearAll: actions.clearAll
  })
);

const AllNone = ({
  groups = [],
  selectAll = noop,
  clearAll = noop,
  instanceId
}) => (
  <Div display="row.flex-start.center">
    <Text2
      color="primary7"
      underline
      onClick={() => {
        selectAll(groups, {
          meta: {
            instanceId
          }
        });
      }}
    >
      Select All
    </Text2>
    <QuarterSizeDotIcon color="primary7" mx={1} />
    <Text2
      color="primary7"
      underline
      onClick={() => {
        clearAll(groups, {
          meta: {
            instanceId
          }
        });
      }}
    >
      Remove All
    </Text2>
  </Div>
);

export default decorate(AllNone);
