import React, { Component } from "react";
import * as R from "ramda";
import {
  Div,
  Text1,
  Text2,
  SmallShadedButton,
  SmallFilledButton
} from "components/Base";
import resolveFormatter from "components/Global/Table3/CellFormatters/utils/resolveFormatter";
import resolveEditor from "components/Global/StandAloneEditors/utils/resolveEditor";
import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";

////////////////////////////////////////////////////////////////////////////////

const FieldValueContainer = ({ children, ...props }) => (
  <Div
    height={40}
    maxWidth={340}
    px={2}
    bra={1}
    bg={
      children.props.onClick
        ? { default: props.bgDefaultColor, hover: "neutral1" }
        : props.bgDefaultColor
    }
    {...props}
  >
    {children}
  </Div>
);

const FieldProperty = ({
  fieldName,
  wrapValue,
  children,
  required = false,
  bgDefaultColor = "neutral0"
}) => (
  <Div pb={4}>
    <Div display="row.flex-start.center">
      <Text1 bold uppercase color="neutral6">
        {fieldName}
      </Text1>
      {required && (
        <Text2 color="danger5" ml={1}>
          *
        </Text2>
      )}
    </Div>
    {wrapValue ? (
      <FieldValueContainer
        mt={1}
        bgDefaultColor={bgDefaultColor}
        bc="gray3"
        color="gray8"
        fs={3}
        fw={2}
      >
        {children}
      </FieldValueContainer>
    ) : (
      children
    )}
  </Div>
);

const valueWrapper = Comp => {
  class ValueHandler extends Component {
    constructor(props) {
      super(props);
      this.state = {
        value: props.value
      };
    }

    /*
    // @NOTE: Not sure if we need this - commenting out for now
    // This will break saving linked records / lookup values
    componentWillReceiveProps({ value }) {
      if (this.state.value !== value) {
        this.setState({ value });
      }
    }
    */

    onCancel = () => {
      this.setState({ value: this.props.value });
      this.props.onCancel();
    };
    onSave = () => {
      this.props.onSave(this.state.value);
    };
    onChange = newValue => {
      this.setState({ value: newValue });
    };
    render() {
      return (
        <Comp
          Editor={this.props.Editor}
          field={this.props.field}
          moduleId={this.props.moduleId}
          editorProps={this.props.editorProps}
          dependentValues={this.props.dependentValues}
          value={this.state.value}
          onChange={this.onChange}
          onSave={this.onSave}
          onCancel={this.onCancel}
        />
      );
    }
  }
  return ValueHandler;
};

const EditorDisplay = valueWrapper(
  ({
    value,
    onChange,
    onSave,
    onCancel,
    Editor,
    editorProps,
    dependentValues
  }) => (
    <Div>
      <Editor
        onChange={onChange}
        value={value}
        rowMetaData={dependentValues}
        usePortal={true}
        {...editorProps}
      />
      <Div mt={1} display="row.flex-start.center">
        <SmallShadedButton onClick={onCancel}>Cancel</SmallShadedButton>
        <SmallFilledButton ml={1} bg="altA6" onClick={onSave}>
          Save Changes
        </SmallFilledButton>
      </Div>
    </Div>
  )
);

const FieldDisplay = ({
  name,
  data,
  value,
  dependentValues,
  references,
  isEditing,
  field,
  setIsEditing,
  readOnlyFields,
  eventDetails,
  moduleId,
  saveField,
  disabled,
  bgDefaultColor,
  required = false
}) => {
  const Editor = resolveEditor(field);
  const Formatter = resolveFormatter(data);
  const editorProps = resolveEditorProps(field, eventDetails);

  const isEditingThisField = isEditing === field.id;
  const notEditable =
    disabled ||
    !R.isNil(isEditing) ||
    R.contains(field.id, readOnlyFields) ||
    R.isNil(setIsEditing) ||
    R.path(["settings", "isReferenceField"])(field);

  return (
    <FieldProperty
      wrapValue={!isEditingThisField}
      fieldName={name}
      required={required}
      bgDefaultColor={bgDefaultColor}
    >
      {isEditingThisField ? (
        <EditorDisplay
          Editor={Editor}
          value={value}
          field={field}
          moduleId={moduleId}
          editorProps={editorProps}
          dependentValues={dependentValues}
          onSave={newValue => {
            setIsEditing(null);
            saveField(newValue);
          }}
          onCancel={() => {
            setIsEditing(null);
          }}
        />
      ) : (
        <Div
          height={1}
          display={"row.flex-start.center"}
          onClick={notEditable ? undefined : () => setIsEditing(field.id)}
        >
          <Formatter
            value={value}
            dependentValues={dependentValues}
            references={references}
          />
        </Div>
      )}
    </FieldProperty>
  );
};

export { FieldDisplay };
