import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { find } from "lodash";
import autobind from "autobind-decorator";
import { Link } from "react-router";

import DocumentTitle from "components/Global/DocumentTitle";
import { MODULES } from "components/Global/NavIcons";

@CSSModules(css)
class SettingsHandler extends Component {
  getIcon(tabKey) {
    if (MODULES[tabKey]) {
      return MODULES[tabKey].icon;
    }
    return "";
  }

  @autobind
  activeTab(tabKey) {
    if (find(this.props.routes, { name: "accountSettingsSettings" })) {
      return tabKey === "settings";
    }
  }

  render() {
    const path = route =>
      `/event/${this.props.params.eventId}/settings${route}`;

    return (
      <DocumentTitle title="Account - Settings">
        <div styleName="container">
          <div styleName="leftColumn">
            <div styleName="navHeading">
              <span styleName="navIcon">{this.getIcon("settings")}</span>{" "}
              Settings
            </div>
            <Link
              to={path("")}
              styleName={
                this.activeTab("settings") ? "navItemActive" : "navItem"
              }
            >
              Account Settings
            </Link>
          </div>
          <div styleName="rightColumn">
            {React.cloneElement(this.props.children, {
              user: this.props.user,
              params: this.props.params,
              showModal: this.props.showModal,
              hideModal: this.props.hideModal,
              dispatch: this.props.dispatch,
              showSnackbar: this.props.showSnackbar
            })}
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

export default SettingsHandler;
