import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "redux/modules/user/actions";
import { withRouter } from "react-router";
import * as R from "ramda";
import { user as getUser } from "redux/modules/user/selectors";

import Avatar from "components/Atoms/Avatar";
import OptionsDropdown from "components/Global/OptionsDropdown";

const styles = {
  avatar: {
    width: 30,
    height: 30,
    cursor: "pointer"
  },
  photo: {
    height: 30,
    width: 30,
    borderRadius: 5
  },
  status: {
    backgroundColor: "#00CC83",
    border: "2px solid #5827DB",
    width: 12,
    height: 12,
    position: "absolute",
    left: -6,
    top: 8,
    zIndex: 1,
    borderRadius: 100
  },
  avatarWrapper: {
    position: "relative",
    width: 30,
    height: 30
  }
};

const decorate = R.compose(
  withRouter,
  connect(
    state => ({
      user: getUser(state)
    }),
    {
      logout
    }
  )
);

class HeaderAvatar extends Component {
  render() {
    const { user } = this.props;
    const topLinks = [
      {
        name: "Edit Profile",
        click: () => {
          this.props.router.push({ pathname: "/account/settings" });
        }
      },
      {
        name: "Log Out",
        click: () => this.props.logout()
      }
    ];

    const status = <div style={styles.status} />;

    const avatar = (
      <div style={styles.avatarWrapper}>
        {status}
        <Avatar
          size={30}
          style={styles.photo}
          userId={user.id}
          imageUrl={user.photo_url}
          firstName={user.fname}
          lastName={user.lname}
        />
      </div>
    );

    return (
      <div style={styles.avatar} className="platform-header-avatar">
        <OptionsDropdown
          id="header-avatar"
          innerStyles={{
            right: 0,
            top: 45,
            left: "initial"
          }}
          topLinks={topLinks}
          bare
          label={avatar}
        />
      </div>
    );
  }
}

export default decorate(HeaderAvatar);
