import PropTypes from "prop-types";
import React, { Component } from "react";
import { get, sortBy } from "lodash";
import { Link } from "react-router";
import Body from "components/Event/InventoryV2/Layout/Body";
import Header from "components/Event/InventoryV2/Layout/Header";
import Content from "components/Event/InventoryV2/Layout/Content";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import ReportSummary from "components/Global/Reports/Summary/Panel";
import Title from "components/Global/Module/Title";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import NameFormatter from "components/Event/InventoryV2/Home/ReportPanel/Formatters/Name";
import NumberFormatter from "components/Event/InventoryV2/Home/ReportPanel/Formatters/Number";
import ShowHideSwitch from "components/Global/Module/Sidebar/ShowHideSwitch";

export const FIELDS = [
  {
    id: "name",
    formatter: NameFormatter,
    label: "Item Name",
    style: { width: "50%" },
    valueGetter: value => value.value
  },
  {
    id: "requested",
    label: "Req",
    formatter: NumberFormatter,
    style: { textAlign: "right" }
  },
  {
    id: "approved",
    label: "Approved",
    formatter: NumberFormatter,
    style: { textAlign: "right" }
  }
];

@CSSModules(css)
class InventoryNeedsOverview extends Component {
  componentDidMount() {
    this.fetchReport();
  }

  componentDidUpdate() {}

  fetchReport = () => {
    this.props.getNeedsOverviewReport(this.props.params.eventId);
  };

  handleNewRecord = async (moduleId, record) => {
    // auto approve records added here
    await this.props.createReview(moduleId, record.id, "approved");
    this.fetchReport();
  };

  showAddRecordModal = moduleId => {
    this.props.showModal({
      content: (
        <AddRecordModal
          moduleId={moduleId}
          onSave={record => this.handleNewRecord(moduleId, record)}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  renderMenu = id => [
    <Link
      to={{
        pathname: `/event/${this.props.params.eventId}/module/${id}/grid`,
        query: {
          returnTo: `/event/${this.props.params.eventId}/inventory/needs`
        }
      }}
      className={css.button}
    >
      Detail View
    </Link>,
    <div className={css.button} onClick={() => this.showAddRecordModal(id)}>
      <i className="material-icons">add_circle</i>
      Add
    </div>
  ];

  render() {
    const { isFetching, modules } = this.props;

    const panels = sortBy(modules, m =>
      get(m, "module_name", "").toLowerCase()
    ).map(moduleDetails => (
      <ReportSummary
        key={moduleDetails.module_id}
        emptyState="No requests yet"
        menu={this.renderMenu(moduleDetails.module_id)}
        fetching={false}
        title={moduleDetails.module_name}
        fields={FIELDS}
        rows={moduleDetails.items}
      />
    ));

    return (
      <Content loading={isFetching}>
        <Body>
          <Header>
            <ShowHideSwitch moduleId="inventory" />
            <Title>
              <div styleName="title">Needs Overview</div>
            </Title>
          </Header>
          <div styleName="content">
            <div id="panels">{panels}</div>
          </div>
        </Body>
      </Content>
    );
  }
}

InventoryNeedsOverview.propTypes = {
  createReview: PropTypes.func.isRequired,
  getNeedsOverviewReport: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  params: PropTypes.shape({
    eventId: PropTypes.string
  }).isRequired,
  modules: PropTypes.arrayOf(
    PropTypes.shape({
      module_id: PropTypes.string,
      module_name: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          item_id: PropTypes.string,
          item_name: PropTypes.string,
          requested: PropTypes.number,
          pending: PropTypes.number,
          approved: PropTypes.number,
          rejected: PropTypes.number
        })
      )
    })
  ).isRequired,
  showModal: PropTypes.func.isRequired
};

export default InventoryNeedsOverview;
