import React from "react";
import PropTypes from "prop-types";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Button = CSSModules(
  ({ text, action, iconLeft, iconRight, style = {}, hoverStyle = {} }) => (
    <div
      styleName="button"
      role="button"
      tabIndex="0"
      style={Object.assign({}, style, hoverStyle)}
      onClick={action || null}
    >
      {iconLeft}
      {text && (
        <div
          styleName={iconLeft ? "marginLeft" : iconRight ? "marginRight" : null}
        >
          {text}
        </div>
      )}
      {iconRight}
    </div>
  ),
  css
);

Button.propTypes = {
  text: PropTypes.string,
  action: PropTypes.func.isRequired,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hoverStyle: PropTypes.object
};

export default Button;
