import React from "react";
import OrderView from "components/Global/Orders";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";

const View = () => (
  <OrderView
    orderTypeName="Passes / Manage"
    ordersPath={"/passes/manage/all-orders"}
    orderType="passes"
    title="Requests & Orders"
    typeId={CREDENTIAL_TYPE_ID}
  />
);

export default View;
