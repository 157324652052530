export const FIELD_TYPES = {
  VALUE: "value",
  CURRENCY: "currency",
  DATE: "date",
  TIME: "time",
  DATETIME: "datetime",
  TOGGLE: "toggle",
  DROPDOWN: "dropdown",
  DROPDOWN_ASYNC: "dropdownAsync",
  US_PHONE: "usPhone",
  EVENT_DAYS: "eventDays",
  FILE: "file",
  CATALOG: "catalog",
  PHOTO: "photo",
  USER: "user",
  RADIO_LIST: "radioList",
  TOGGLE_OPTION: "toggleOption",
  COLOR_PICKER: "colorPicker",
  ICON_PICKER: "iconPicker",
  CHECKBOX: "checkbox",
  LOOKUP: "lookup",
  IMAGE: "image",
  COLOR: "color",
  VIDEO: "videoUploader",
  MARK_EVENT_DAYS: "eventGroupDays"
};

export const FORM_ACTIONS = {
  UPLOAD_PHOTO: "uploadPhoto",
  TAKE_PHOTO: "takePhoto",
  EDIT_PHOTO: "editPhoto",
  REMOVE_PHOTO: "removePhoto",
  INIT_VIDEO_UPLOADER: "initVideo",
  UPLOAD_VIDEO: "uploadVideo",
  CANCEL_VIDEO: "cancelUpload"
};

export const DEFAULT_DATE_FORMAT = "L";
