import * as R from "ramda";
import { createHandlers } from "redux-mvc";

const model = createHandlers({
  iniState: {
    errors: {}
  },
  reducers: {
    validationRequest: R.identity,
    validationResponse: (state, { payload: { id, error } }, e) =>
      e
        ? state
        : {
            errors: R.assoc(id, error, state.errors)
          },
    bulkValidationRequest: R.identity,
    bulkValidationResponse: (state, { payload: { errors } }, e) =>
      e
        ? state
        : {
            errors: R.mergeAll([state.errors, errors])
          },
    clear: ({ errors }, { payload: ids }) => ({
      errors: R.omit(ids, errors)
    })
  },
  namespace: "UIValidators"
});

const { actions, getters } = model;

export { actions, getters };

export default model;
