import React from "react";
import { SubNavWrapper, SubNavLink } from "OrgLight/Common/SubNav";
import { TAB_IDS } from "./constants";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as R from "ramda";
import { orgDetails as getOrgDetails } from "redux/modules/organization/selectors";

const decorate = R.compose(
  withRouter,
  connect(state => ({
    orgDetails: getOrgDetails(state)
  }))
);

const SubNav = ({ selectedView, router, params, orgDetails }) => (
  <SubNavWrapper supportId="orgTeam">
    <SubNavLink
      name="Team"
      active={selectedView === TAB_IDS.TEAM}
      onClick={() =>
        router.push({
          pathname: `/org-light/${params.orgId}/team`
        })
      }
    />
    {!orgDetails.flag_enable_permissions ? null : (
      <SubNavLink
        name="Permissions"
        active={selectedView === TAB_IDS.PERMISSIONS}
        onClick={() =>
          router.push({
            pathname: `/org-light/${params.orgId}/team/permissions`
          })
        }
      />
    )}
  </SubNavWrapper>
);

export default decorate(SubNav);
