import React, { Component } from "react";

class AddCollaboratorInfoModal extends Component {
  state = {
    eventRole: ""
  };

  onSave = async () => {
    const { user } = this.props;
    const data = {
      eventRole: this.state.eventRole
    };

    await this.props.acceptInvitationToEvent(this.props.eventId, user.id, data);

    this.props.onUpdated(this.props.eventId);
    this.props.hideModal();
  };

  onEventRoleChange = event => {
    this.setState({ eventRole: event.target.value });
  };

  render() {
    return (
      <div>
        <div className="modal-header">
          <div className="modal-title">You are joining</div>
          <div className="modal-description modal-description-big">
            {this.props.eventName}
          </div>
        </div>

        <div className="modal-body">
          <div className="modal-body-wrapper">
            <div className="input-wrapper">
              <label htmlFor="role">What's your role in this event?</label>
              <input
                id="role"
                className="styled"
                type="text"
                name=""
                value={this.state.eventRole}
                placeholder="ex: Event Director"
                onChange={this.onEventRoleChange}
              />
            </div>

            <div className="actions-wrapper">
              <a className="action-positive small" onClick={this.onSave}>
                Continue
              </a>
            </div>
          </div>

          <div
            className="modal-tip"
            style={{
              color: "#999"
            }}
          >
            <strong>Helpful tip: </strong>
            You can change your event role at anytime on the event team page.
          </div>
        </div>
      </div>
    );
  }
}

export default AddCollaboratorInfoModal;
