import React, { Component } from "react";
import { map } from "ramda";
import { ACCOUNTS_BY_TYPE_REPORT_ID } from "components/Event/Reports/utils/constants";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import View from "./View";

class Controller extends Component {
  componentDidMount() {
    this.props.getRecordTypes({
      moduleId: STANDARD_MODULE_IDS.accounts.id,
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId
      }
    });
  }

  goToAccountRecordTypeReport = typeId => {
    const { orgId, eventId } = this.props.params;
    const basePath = orgId ? `/organization/${orgId}` : `/event/${eventId}`;

    this.props.router.push({
      pathname: `${basePath}/reports/${ACCOUNTS_BY_TYPE_REPORT_ID}`,
      query: {
        filters: `{"group_type":["${typeId}"]}`
      }
    });
  };

  render() {
    const { accountRecordTypes } = this.props;
    return (
      <View
        {...{
          accountRecordTypes: map(type => ({
            ...type,
            goToTypeReport: () => this.goToAccountRecordTypeReport(type.id)
          }))(accountRecordTypes)
        }}
      />
    );
  }
}

export default Controller;
