import React from "react";
import CSSModules from "react-css-modules";
import css from "../../styles.scss";
import Tabs from "components/Global/CRM/Tabs";
import Tab from "components/Global/CRM/Tabs/Tab";

import Textarea from "components/Global/Modal/Layout/FormElements/Textarea";
import { ButtonOrange } from "components/Global/Modal/Layout/Buttons";
import CopyToClipboard from "react-copy-to-clipboard";

const Send = CSSModules(
  ({
    onMessageChange,
    handleSendEmail,
    handleCopy,
    copied,
    registerTextareaRef,
    url,
    sending,
    message,
    records,
    showConfirmation
  }) => (
    <div>
      {showConfirmation ? (
        <div styleName="flash">
          <div styleName="icon">
            <i className="material-icons">check</i>
          </div>
          Login access granted
        </div>
      ) : null}
      <div styleName="body">
        <Tabs style={{ fontSize: 15 }}>
          <Tab label={`Send Email${records.length !== 1 ? "s" : ""}`}>
            <div styleName="tabBody">
              <div styleName="caption">
                Include optional message with the email:
              </div>
              <Textarea
                rows={5}
                placeholder="Optional: Add a personal message"
                onChange={onMessageChange}
                value={message}
              />
              <div>
                <ButtonOrange
                  title={
                    sending ? (
                      <span>
                        <i
                          className={[
                            "fa",
                            "fa-circle-o-notch",
                            css.processingIcon
                          ].join(" ")}
                        />
                        Sending
                      </span>
                    ) : (
                      `Send Email${records.length !== 1 ? "s" : ""}`
                    )
                  }
                  disabled={sending}
                  onClick={handleSendEmail}
                />
              </div>
            </div>
          </Tab>
          <Tab label="Get Link">
            <div styleName="tabBody">
              <div styleName="caption">Copy the link below:</div>
              <Textarea ref={registerTextareaRef} rows={5} value={url} />
              <div>
                <CopyToClipboard text={url} onCopy={handleCopy}>
                  <ButtonOrange
                    title={
                      copied ? (
                        <div className={css.copied}>
                          <i className="material-icons">check</i>
                          Copied!
                        </div>
                      ) : (
                        "Copy Link"
                      )
                    }
                  />
                </CopyToClipboard>
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  ),
  css
);

export default Send;
