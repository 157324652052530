import * as R from "ramda";
import moment from "moment";

import { CREDENTIAL_TYPE_ID, MEAL_TYPE_ID } from "utils/item-types";

import { getters } from "EventLight/Common/Manage";
import { makeInstanceSelector } from "redux-mvc";

import {
  formatName,
  getStatuses,
  showCheckinForUnpaidOrders,
  handlersSelector,
  getGroupBy,
  getKey
} from "./utils";

export const getItemTypeRows = makeInstanceSelector(
  getGroupBy,
  getters.itemTypeRows,
  handlersSelector,
  (_, props) => R.pathOr("", ["params", "eventId"], props),
  (view, itemTypeRows = [], handlers, eventId) =>
    R.map(
      itemType => ({
        itemType: {
          id: itemType.item_id,
          statuses: itemType.statuses || [],
          title: itemType.item_type_name,
          caption: itemType.item_group_name,
          color: R.path(["detail", 0, "bg_color"])(itemType),
          stats: R.compose(
            stats => {
              if (!R.path(["stats", "planned", "value"])(itemType)) {
                return R.filter(
                  s => !["planned", "remaining", "progress"].includes(s.name)
                )(stats);
              }
              return stats;
            },
            R.map(([name, val]) => ({ name, ...val }))
          )(Object.entries(itemType.stats))
        },
        items: R.map(r => {
          const contactName = [r.first_name, r.last_name]
            .filter(v => v && v.length)
            .join(" ");
          const checkinRecordId =
            r.order_type === "group"
              ? r.customer_account_id
              : r.customer_contact_id;
          const checkinRecordName =
            r.order_type === "group" ? r.customer_account_name : contactName;

          return {
            quantity: r.quantity,
            attendee: {
              name:
                r.order_type === "group"
                  ? formatName(r.customer_account_name, r.customer_account_id)
                  : formatName(contactName, r.customer_contact_id),
              groupName:
                r.order_type === "group"
                  ? formatName(contactName, r.customer_contact_id)
                  : formatName(r.customer_account_name, r.customer_account_id),
              contactType: r.customer_account_type || ""
            },
            passType: {
              name: r.pass_type_name,
              statuses: getStatuses(r.issued, r.picked_up)
            },
            orderId: R.uniqBy(o => ({ id: o.id, number: o.number }))(
              r.order_numbers
            ),
            details: r.question_values_preview
              ? R.replace(/,\s/g, " · ", r.question_values_preview)
              : null,
            order: r.ordernumber,
            enableViewOrder: true,
            handlers,
            approval: {
              manifestId: r.approval_manifest_id,
              status: r.approval_status,
              recordIds: r.line_item_ids
            },
            location: r.location || " - ",
            daysNeeded:
              moment(R.path(["daysNeeded", "from"])).isValid &&
              moment(R.path(["daysNeeded", "to"])).isValid
                ? `${moment(R.path(["daysNeeded", "from"], r)).format(
                    "ddd MMM D"
                  )} - ${moment(R.path(["daysNeeded", "to"], r)).format(
                    "ddd MMM D"
                  )}`
                : " - ",
            checkin: {
              isPending: r.approval_status === "pending",
              needsPayment: showCheckinForUnpaidOrders(eventId)
                ? false
                : r.payment_status === "unpaid",
              enabled:
                r.order_type === "group"
                  ? Boolean(r.customer_account_id)
                  : Boolean(r.customer_contact_id),
              title: r.picked_up
                ? `${moment(r.fulfillment_created_at).format("h:mma M/D")} - ${
                    r.fulfillment_created_by_user
                  }`
                : null,
              isIssued: r.issued,
              isPickedUp: r.picked_up,
              checkin: () =>
                handlers.issueItems({
                  orderType: r.order_type,
                  customerAccountId: r.customer_account_id,
                  customerContactId: r.customer_contact_id,
                  recordId: checkinRecordId,
                  variantId: r.variant_id,
                  fulfillmentId: r.fulfillment_id,
                  status: r.issued && "pending-pickup",
                  itemName: r.pass_type,
                  recordName: checkinRecordName,
                  quantity: r.count
                }),
              undoCheckin: () =>
                handlers.issueItems({
                  orderType: r.order_type,
                  customerAccountId: r.customer_account_id,
                  customerContactId: r.customer_contact_id,
                  recordId: checkinRecordId,
                  variantId: r.variant_id,
                  fulfillmentId: r.fulfillment_id,
                  status: r.picked_up
                    ? "undo-fulfillment"
                    : r.issued
                    ? "undo-issuance"
                    : undefined,
                  itemName: r.pass_type,
                  recordName: checkinRecordName,
                  quantity: r.count
                })
            }
          };
        }, itemType.detail),
        allLineItemIds: R.compose(
          R.uniq,
          R.flatten,
          R.map(R.prop("pending_line_item_ids"))
        )(itemType.detail),
        allCredentialLineItemIds: R.compose(
          R.uniq,
          R.flatten,
          R.map(detail => {
            if (detail.type_id === CREDENTIAL_TYPE_ID) {
              return detail.pending_line_item_ids;
            }
            return [];
          })
        )(itemType.detail),
        allMealLineItemIds: R.compose(
          R.uniq,
          R.flatten,
          R.map(detail => {
            if (detail.type_id === MEAL_TYPE_ID) {
              return detail.pending_line_item_ids;
            }
            return [];
          })
        )(itemType.detail)
      }),
      itemTypeRows
    )
)(getKey);
