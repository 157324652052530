import * as R from "ramda";
import moment from "moment";
import { createSelector } from "reselect";
import { getters } from "./model";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import {
  EDIT_MODAL_DATA,
  CARD_TYPES,
  SHARE_FILE_ID,
  PERSON_SUBCARDS,
  MAP_CARD_TYPES_ID
} from "./constants";
import { getters as FormGetters } from "ui-kit/Form/model";
import * as STANDARD_MODULE_FIELD_IDS from "utils/standard-module-field-ids";
import {
  CREDENTIAL_TYPE_ID,
  INVENTORY_TYPE_ID,
  MEAL_TYPE_ID
} from "utils/item-types";
import { getters as MiniItemsGetters } from "ui-kit/MiniItemsSelector";

const ENABLED_TYPE_IDS = [CREDENTIAL_TYPE_ID, INVENTORY_TYPE_ID, MEAL_TYPE_ID];

export const getPermissionSet = createSelector(
  getters.selectedPermissionSetId,
  getters.permissionsSets,
  (selectedPermissionSetId, permissionSets) => {
    return selectedPermissionSetId
      ? permissionSets.find(s => s.id === selectedPermissionSetId)
      : null;
  }
);

export const getIntakeForm = createSelector(
  getters.onboardDetails,
  onboardDetails => R.propOr({}, "intake_form", onboardDetails) || {}
);

export const getIsAccountPortal = createSelector(
  getters.moduleId,
  moduleId => moduleId === STANDARD_MODULES.accounts.id
);

export const getIsUpdateBtnDisabled = createSelector(
  FormGetters.fields,
  fields =>
    !R.pathOr("", [EDIT_MODAL_DATA.ALIAS, "value"], fields) &&
    !R.pathOr(null, [EDIT_MODAL_DATA.DUE_DATE, "value"], fields)
);

export const getIsTaskSaveBtnDisabled = createSelector(
  FormGetters.fields,
  fields => {
    return (
      !R.pathOr("", [EDIT_MODAL_DATA.NAME, "value"], fields) ||
      !R.pathOr(null, [EDIT_MODAL_DATA.METHOD, "value", 0], fields)
    );
  }
);

export const getAssignSelectedPersonItems = createSelector(
  getters.assignedPersonFormIds,
  getters.selectedModalPersonId,
  (assignedPersonFormIds, selectedModalPersonId) =>
    R.propOr([], selectedModalPersonId, assignedPersonFormIds)
);

export const getAssignModalTitle = createSelector(
  MiniItemsGetters.selectedItems,
  getters.assignModalType,
  (selectedItems, assignModalType) => {
    const assigned = R.length(selectedItems);
    if (assignModalType === CARD_TYPES.FORMS) {
      return `${assigned} form${assigned !== 1 ? "s" : ""} assigned`;
    } else if (assignModalType === CARD_TYPES.TASKS) {
      return `${assigned} task${assigned !== 1 ? "s" : ""} assigned`;
    } else if (assignModalType === CARD_TYPES.DOCUMENTS) {
      return `${assigned} document request${
        assigned !== 1 ? "s" : ""
      } assigned`;
    } else if (assignModalType === CARD_TYPES.PERSON_FORMS) {
      return `${assigned} form${assigned !== 1 ? "s" : ""} assigned`;
    } else if (assignModalType === CARD_TYPES.PERSON_DOCUMENTS) {
      return `${assigned} document${assigned !== 1 ? "s" : ""} assigned`;
    } else if (assignModalType === CARD_TYPES.PAGES) {
      return `${assigned} page${assigned !== 1 ? "s" : ""} assigned`;
    }

    return "";
  }
);

export const getUpdateSharedFileModalData = createSelector(
  getters.assignedSharedFiles,
  getters.selectedSharedFileId,
  (sharedFiles, selectedId) =>
    selectedId ? R.find(R.propEq("id", selectedId), sharedFiles) : {}
);

export const getSharedFileModalTitle = createSelector(
  getters.selectedSharedFileId,
  selectedId => `${selectedId ? "Edit" : "Add"} file to share`
);

export const getIsShareBtnDisabled = createSelector(
  state =>
    FormGetters.fields(state, {
      instanceId: SHARE_FILE_ID
    }),
  getters.uploadedFileData,
  (fields, uploadedFileData) =>
    !R.pathOr("", [EDIT_MODAL_DATA.TITLE, "value"], fields) ||
    R.isNil(uploadedFileData)
);

export const getPeopleTypesForGuestListModal = createSelector(
  getters.recordTypes,
  peopleTypes =>
    R.map(p => ({
      id: p.id,
      label: p.name
    }))(peopleTypes)
);

export const getPeopleTypes = createSelector(
  getters.recordTypes,
  getters.subtypes,
  getters.collapsablePeopleCards,
  (peopleTypes, subtypes, collapsablePeopleCards) => {
    return R.compose(
      R.map(type => {
        const currentType =
          R.find(R.propEq("record_type_id", type.id), subtypes) || {};

        return {
          ...type,
          backgroundColor: R.propOr("lightgrey", "background_color", type),
          canRequestItems: R.propOr(false, "can_request_items", currentType),
          isDueDateClose: currentType.close_date
            ? moment(currentType.close_date).isBefore(new Date())
            : false,

          isEnabled: R.propOr(false, "is_enabled", currentType),
          countOfFieldsSelected: R.compose(
            R.length,
            R.propOr([], "visible_fields")
          )(currentType),

          visibleFields: R.propOr([], "visible_fields", currentType),

          requiredFields: R.propOr([], "required_fields", currentType),

          fieldOrder: R.propOr({}, "field_order", currentType),

          subTypeId: currentType.id,

          items: R.propOr([], "items", currentType),

          autoAssignItems: R.propOr([], "auto_assign_items", currentType),

          closeDate: currentType.close_date,
          collapsed: R.propOr(false, type.id, collapsablePeopleCards),

          assignedDocumentRequests: R.sort(
            (a, b) => a.order - b.order,
            R.propOr([], "assigned_document_requests", currentType)
          ),
          assignedForms: R.sort(
            (a, b) => a.order - b.order,
            R.propOr([], "assigned_forms", currentType)
          )

          /*
        availableItems: itemBlocks.length
          ? activePermissionSet.item_blocks
              .filter(i => i.record_type_id === type.id)
              .map(i => {
                const block = itemBlocks.find(b => b.id === i.block_id);
                const required = R.propOr(false, "is_required", i);
                return {
                  id: i.id,
                  name: block.name,
                  quantity: block.items.length,
                  type: LABELS[block.item_type_id].label,
                  required,
                  status: "Pending"
                };
              })
          : []
        */
        };
      })
    )(peopleTypes);
  }
);

export const getEnabledTypesLength = createSelector(
  getPeopleTypes,
  R.compose(R.length, R.filter(R.propEq("isEnabled", true)))
);

export const getMappedSelectRequiredFields = createSelector(
  getters.contactFields,
  R.compose(
    R.map(f => ({
      id: f.id,
      name: f.name,
      type: f.type,
      source: f.source
    })),
    R.sortBy(f => (f.name ? f.name.toLowerCase() : f.name)),
    R.concat([
      {
        id: "role",
        name: "Role",
        type: "text",
        source: "standard"
      }
    ])
  )
);

export const getMappedSelectGuestListRequiredFields = createSelector(
  getters.contactFields,
  R.compose(
    R.map(f => ({
      id: f.id,
      name: f.name,
      type: f.type,
      source: f.source
    })),
    R.sortBy(f => (f.name ? f.name.toLowerCase() : f.name)),
    R.concat([
      {
        id: "role",
        name: "Role",
        type: "text",
        source: "standard"
      }
    ])
  )
);

export const getVisibleFieldsCount = createSelector(
  getters.guestListSettings,
  R.compose(R.length, R.propOr([], "visible_fields"))
);

export const getGuestListNotDraggableFieldIds = R.always([
  STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME,
  STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME,
  STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL,
  STANDARD_MODULE_FIELD_IDS.CONTACTS.EVENT_DAYS
]);

export const getNotDraggableFieldIds = R.always([
  STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME,
  STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME,
  STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL,
  STANDARD_MODULE_FIELD_IDS.CONTACTS.EVENT_DAYS
]);

export const getEnabledPeopleTypes = createSelector(
  getPeopleTypes,
  R.filter(R.propEq("isEnabled", true))
);

export const getEnabledPeopleTypeIds = createSelector(
  getEnabledPeopleTypes,
  R.map(R.prop("id"))
);

export const getItemsTypes = createSelector(getters.itemsTypes, itemTypes => {
  return R.compose(
    R.map(tab => ({
      id: tab.id,
      name: tab.name,
      items: R.map(
        item => ({
          id: item.id,
          name: item.name,
          items: R.compose(
            R.map(v => ({
              ...v,
              name: v.display_name
            })),
            R.flatten,
            R.map(R.prop("variants"))
          )(item.items)
        }),
        tab.groups
      )
    })),
    R.filter(t => ENABLED_TYPE_IDS.includes(t.id))
  )(itemTypes);
});

export const getSelectedRequestableItems = createSelector(
  getters.assignModalType,
  getters.selectedModalPersonId,
  getEnabledPeopleTypes,
  getters.assignedSellItems,
  (assignModalType, personId, peopleTypes, assignedSellItems) => {
    if (assignModalType === PERSON_SUBCARDS.REQUESTABLE) {
      return R.compose(
        R.reduce((acc, item) => ({ ...acc, [item.id]: {} }), {}),
        R.propOr([], "items"),
        R.find(R.propEq("id", personId))
      )(peopleTypes);
    } else if (assignModalType === CARD_TYPES.REQUESTABLE) {
      return R.reduce(
        (acc, item) => ({ ...acc, [item.id]: {} }),
        {},
        assignedSellItems
      );
    }
    return [];
  }
);

export const getSelectedAutoAssignItems = createSelector(
  getters.itemsTypes,
  () => {
    return [];
  }
);

export const getSelectedGuestListItems = createSelector(
  getters.guestListSettings,
  guestListSettings => {
    return R.compose(
      R.reduce((map, id) => {
        map[id] = guestListSettings.variant_limits[id];
        return map;
      }, {}),
      R.keys,
      R.prop("variant_limits")
    )(guestListSettings);
  }
);

export const getSelectRequestableItemsModalTitle = createSelector(
  getters.selectedModalPersonId,
  getEnabledPeopleTypes,
  (personId, peopleTypes) => {
    const textBase = "Choose items";

    if (!personId) {
      return textBase;
    }

    const personName = R.compose(
      R.propOr("", "name"),
      R.find(R.propEq("id", personId))
    )(peopleTypes);

    return personName ? `${textBase} for ${personName}` : textBase;
  }
);

export const getPersonSubtypeData = createSelector(
  getters.selectedModalPersonId,
  getEnabledPeopleTypes,
  getters.selectedPersonSubCard,
  (personId, peopleTypes, selectedPersonSubCard) => {
    const subTypePropName = R.propOr("", selectedPersonSubCard, {
      [PERSON_SUBCARDS.REQUESTABLE]: "items",
      [PERSON_SUBCARDS.AUTO_ASSIGN]: "autoAssignItems",
      [PERSON_SUBCARDS.FORMS]: "assignedForms",
      [PERSON_SUBCARDS.DOCUMENTS]: "assignedDocumentRequests"
    });

    return R.compose(
      R.propOr([], subTypePropName),
      R.find(R.propEq("id", personId))
    )(peopleTypes);
  }
);

export const getPersonItemData = createSelector(
  getters.selectedModalPersonItemId,
  getPersonSubtypeData,
  (itemId, personSubTypeData) =>
    R.find(R.propEq("id", itemId), personSubTypeData)
);

export const getGroupItemData = createSelector(
  getters.assignModalType,
  getters.assignedAutoItems,
  getters.selectedModalId,
  getters.assignedSellItems,
  (assignModalType, assignedAutoItems, selectedModalId, assignedSellItems) => {
    const list = R.propOr([], assignModalType, {
      [CARD_TYPES.AUTO_ASSIGN]: assignedAutoItems,
      [CARD_TYPES.REQUESTABLE]: assignedSellItems
    });

    return R.find(R.propEq("id", selectedModalId), list);
  }
);

export const getItemData = createSelector(
  getters.selectedModalPersonItemId,
  getPersonItemData,
  getGroupItemData,
  (selectedModalPersonItemId, person, group) => {
    return selectedModalPersonItemId ? person : group;
  }
);

export const getPersonMinQty = createSelector(
  getters.selectedPersonSubCard,
  selectedPersonSubCard =>
    R.propOr(0, selectedPersonSubCard, {
      [PERSON_SUBCARDS.REQUESTABLE]: 0,
      [PERSON_SUBCARDS.AUTO_ASSIGN]: 1,
      [CARD_TYPES.REQUESTABLE]: 0,
      [CARD_TYPES.AUTO_ASSIGN]: 1
    })
);

export const getPersonItemQtyLabel = createSelector(
  getters.assignModalType,
  assignModalType =>
    R.propOr(0, assignModalType, {
      [PERSON_SUBCARDS.REQUESTABLE]: "Max Quantity",
      [PERSON_SUBCARDS.AUTO_ASSIGN]: "Quantity",
      [CARD_TYPES.REQUESTABLE]: "Max Quantity",
      [CARD_TYPES.AUTO_ASSIGN]: "Quantity"
    })
);

export const getPersonItemQtyField = createSelector(
  getters.assignModalType,
  assignModalType =>
    R.propOr(0, assignModalType, {
      [PERSON_SUBCARDS.REQUESTABLE]: "max_quantity",
      [PERSON_SUBCARDS.AUTO_ASSIGN]: "quantity",
      [CARD_TYPES.REQUESTABLE]: "max_quantity",
      [CARD_TYPES.AUTO_ASSIGN]: "quantity"
    })
);

export const getAssignModalItems = createSelector(
  getters.forms,
  getters.documentRequests,
  getters.assignModalType,
  getters.pages,
  getters.tasks,
  (forms, documentRequests, assignModalType, pages, tasks) => {
    if (
      assignModalType === CARD_TYPES.FORMS ||
      assignModalType === CARD_TYPES.PERSON_FORMS
    ) {
      return forms;
    } else if (
      assignModalType === CARD_TYPES.DOCUMENTS ||
      assignModalType === CARD_TYPES.PERSON_DOCUMENTS
    ) {
      return documentRequests;
    } else if (assignModalType === CARD_TYPES.PAGES) {
      return pages;
    } else if (assignModalType === CARD_TYPES.TASKS) {
      return tasks;
    }

    return [];
  }
);

export const getAssignModalSelectedItems = createSelector(
  getters.assignedForms,
  getters.assignedDocumentRequests,
  getters.assignModalType,
  getPersonSubtypeData,
  getters.assignedPages,
  getters.assignedAutoItems,
  getters.assignedTasks,
  (
    assignedForms,
    assignedDocumentRequests,
    assignModalType,
    personSubtypeData,
    assignedPages,
    assignedAutoItems,
    assignedTasks
  ) => {
    if (assignModalType === CARD_TYPES.FORMS) {
      return assignedForms;
    } else if (assignModalType === CARD_TYPES.DOCUMENTS) {
      return assignedDocumentRequests;
    } else if (R.contains(assignModalType, R.values(PERSON_SUBCARDS))) {
      return personSubtypeData;
    } else if (assignModalType === CARD_TYPES.PAGES) {
      return assignedPages;
    } else if (assignModalType === CARD_TYPES.AUTO_ASSIGN) {
      return assignedAutoItems;
    } else if (assignModalType === CARD_TYPES.TASKS) {
      return assignedTasks;
    }

    return [];
  }
);

export const getAssignedIds = createSelector(
  getAssignModalSelectedItems,
  getters.assignModalType,
  (selectedItems, assignModalType) =>
    R.map(R.prop(MAP_CARD_TYPES_ID[assignModalType]), selectedItems)
);

export const getAutoAssignedIds = createSelector(
  getAssignModalSelectedItems,
  R.reduce((acc, item) => ({ ...acc, [item.id]: {} }), {})
);

export const getEditAssignedFormData = createSelector(
  getters.assignedForms,
  getters.selectedModalId,
  (assignedForms, selectedModalId) =>
    selectedModalId
      ? R.find(R.propEq("id", selectedModalId), assignedForms)
      : {}
);

export const getEditAssignedTaskData = createSelector(
  getters.assignedTasks,
  getters.selectedModalId,
  (assignedTasks, selectedModalId) =>
    selectedModalId
      ? R.find(R.propEq("id", selectedModalId), assignedTasks)
      : {}
);

export const getUpdateDocumentData = createSelector(
  getters.assignedDocumentRequests,
  getters.selectedModalId,
  (assignedDocumentRequests, selectedId) =>
    selectedId
      ? R.find(R.propEq("id", selectedId), assignedDocumentRequests)
      : {}
);

export const getUpdatePersonData = createSelector(
  getPersonSubtypeData,
  getters.selectedModalId,
  (personData, selectedId) =>
    selectedId ? R.find(R.propEq("id", selectedId), personData) : {}
);

export const getUpdateModalData = createSelector(
  getters.assignModalType,
  getEditAssignedFormData,
  getUpdateDocumentData,
  getUpdatePersonData,
  getEditAssignedTaskData,
  (assignModalType, form, document, personItem, task) => {
    if (assignModalType === CARD_TYPES.FORMS) {
      return form;
    } else if (assignModalType === CARD_TYPES.DOCUMENTS) {
      return document;
    } else if (
      assignModalType === CARD_TYPES.PERSON_FORMS ||
      assignModalType === CARD_TYPES.PERSON_DOCUMENTS
    ) {
      return personItem;
    } else if (assignModalType === CARD_TYPES.TASKS) {
      return task;
    }

    return {};
  }
);

export const getShowPriceField = createSelector(
  getters.assignModalType,
  assignModalType => assignModalType === CARD_TYPES.REQUESTABLE
);
