import React from "react";
import { Div, MediumFilledButton, ReplyIcon } from "components/Base";
import { connect } from "react-redux";
import { getters } from "../model";
import ReminderdButton from "./ReminderdButton";

const decorator = connect((state, props) => ({
  title: getters.title(state, props),
  logoImageUrl: getters.logoImageUrl(state, props)
}));

export const Header = ({ title, logoImageUrl }) => (
  <Div
    display="row.space-between.stretch"
    style={{
      marginBottom: "30px"
    }}
  >
    <Div display="column.space-between.flex-start">
      <MediumFilledButton
        bg="#f3f4f5"
        color="black"
        LeftIcon={ReplyIcon}
        pill
        onClick={() => null}
      >
        Dashboard
      </MediumFilledButton>
      <Div display="row.flex-start.center">
        <Div
          fw={4}
          style={{
            fontSize: "30px",
            color: "#123059",
            marginRight: 12
          }}
        >
          {title}
        </Div>

        <ReminderdButton />
      </Div>
    </Div>

    <Div
      width={92}
      height={92}
      style={{
        backgroundImage: `url(${logoImageUrl})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        backgroundSize: "cover"
      }}
    />
  </Div>
);

export default decorator(Header);
