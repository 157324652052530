import React, { Component } from "react";
import PageLocation from "../PageLocation";
import MutedPagination from "./MutedPagination";
import SizeSelector from "../SizeSelector";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import GeneralPagination from "../General";

@CSSModules(css)
class ReactTablePagination extends GeneralPagination {
  render() {
    const {
      pages,
      page,
      showPageSizeOptions,
      pageSizeOptions,
      pageSize,
      canPrevious,
      canNext,
      onPageSizeChange
    } = this.props;

    return (
      <div className={css.container}>
        <PageLocation
          pageSize={pageSize}
          pages={pages}
          page={page}
          total={this.props.data.length}
          rowsText={this.props.rowsText}
        />
        <MutedPagination
          canNext={canNext}
          canPrevious={canPrevious}
          page={page}
          pages={pages}
          onPageChange={this.changePage}
        />
        {showPageSizeOptions ? (
          <SizeSelector
            pageSize={pageSize}
            rowsText={this.props.rowsText}
            onPageSizeChange={onPageSizeChange}
            pageSizeOptions={pageSizeOptions}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default ReactTablePagination;
