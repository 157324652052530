/* eslint-disable no-underscore-dangle */

import * as R from "ramda";

import { createSelector } from "reselect";

import { getters } from "Schedules/ActivityDetailsModal/model";

export const getFields = createSelector(
  getters.showMoreFields,
  getters.visibleFields,
  getters.moreFields,
  (showMoreFields, visibleFields, moreFields) => {
    if (!showMoreFields) {
      return visibleFields;
    }
    return [...visibleFields, ...moreFields];
  }
);

export const getMoreFieldsText = createSelector(
  getters.showMoreFields,
  showMoreFields => {
    if (showMoreFields) {
      return "Hide fields";
    }
    return "Show more fields";
  }
);

export const getShowMoreFieldsElement = createSelector(
  getters.visibleFields,
  getters.moreFields,
  (visibleFields, moreFields) => {
    return R.length(visibleFields) > 0 && R.length(moreFields) > 0;
  }
);

export const getValuesToBeUpdated = createSelector(
  getters.values,
  getters.updatedIds,
  getters.activityId,
  (values, updatedIds, activityId) =>
    R.compose(
      R.filter(({ value }) => !R.isNil(value)),
      R.map(id => ({
        recordId: activityId,
        fieldId: id,
        value: values[id]
      }))
    )(updatedIds)
);

export const getValuesToBeSaved = createSelector(
  getters.values,
  getters.visibleFields,
  getters.moreFields,
  (values, visibleFields, moreFields) => {
    const fields = [...visibleFields, ...moreFields];

    return R.compose(
      R.reduce(
        (acc, fieldId) => ({
          ...acc,
          [fieldId]: {
            ...(R.find(({ id }) => id === fieldId, fields) || {}),
            value: values[fieldId]
          }
        }),
        {}
      ),
      R.keys
    )(values);
  }
);
