import { createHandlers, createModule } from "redux-mvc";
import * as R from "ramda";
import selectiveApproversLabel from "SelectiveApproversLabel";

const handlers = createHandlers({
  iniState: {
    activeTabValue: "",
    recordId: undefined,
    hasContactId: false,
    isLoading: false
  },
  namespace: "RequestsAndOrders",
  reducers: {
    exportItemSummaries: R.identity,
    approveRequests: R.identity,
    denyRequests: R.identity,
    reviewResponse: R.identity
  }
});

const module = createModule(handlers);

module.plugModule(selectiveApproversLabel);

const { actions, getters } = module;

export { actions, getters };

export default module;
