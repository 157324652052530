import React, { Component } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import * as R from "ramda";

import { actions } from "Orders/ImportModal";
import importModal from "Orders/ImportModal";
import importModalSagas from "Orders/ImportModal/sagas";

importModal.setRootSaga(importModalSagas);

import Layout from "Orders/ImportModal/View/Layout";

class Modal extends Component {
  static contextTypes = {
    store: PropTypes.shape({
      subscribe: PropTypes.func.isRequired,
      dispatch: PropTypes.func.isRequired,
      getState: PropTypes.func.isRequired
    })
  };

  constructor(props, context) {
    super(props, context);

    const { moduleId } = props;

    const { store } = importModal.createStore({
      globalStore: context.store,
      observedDomains: ["user", "event", "organization"],
      dispatchToGlobal: R.compose(
        R.not,
        R.test(new RegExp(R.join("|", importModal.modules))),
        R.prop("type")
      ),
      persist: false
    });
    this.store = store;
    this.store.dispatch(actions.setModuleId(moduleId));

    importModal.run();
  }

  componentWillUnmount() {
    if (this.props.onDone) {
      this.props.onDone();
    }
    importModal.cancel();
  }

  render() {
    return (
      <Provider store={this.store}>
        <Layout />
      </Provider>
    );
  }
}

export default Modal;
