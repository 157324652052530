import PropTypes from "prop-types";
import React, { Component } from "react";
import { get, find } from "lodash";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Contact from "./Contact";
import ContactMenu from "./ContactMenu";

import { OutlineButton } from "components/Global/CRM/Card/Buttons";
import Card from "components/Global/CRM/Card";
import Header from "components/Global/CRM/Card/Header";
import IconTitle from "components/Global/CRM/Card/IconTitle";
import Title from "components/Global/CRM/Card/Title";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import AddContactModal from "./AddContactToAccount";
import EditRelatedContactModal from "../Modals/EditRelatedContactModal";
import { CONTACT_STANDARD_FIELD_IDS } from "components/Event/Contacts/constants";
import createContactFields from "components/Event/Contacts/Utils/create-contact-fields";
import LoginDetails from "./LoginDetailsModal";
import BulkLoginDetails from "components/Event/Accounts/Account/People/BulkLoginDetailsModal";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import ActionsBar from "./ActionsBar";
import PaginationBar from "components/Global/ReactTable/Pagination/Muted";
import PaginationManager from "components/Global/ReactTable/Pagination/Manager";
import getRowsSelectedHeader from "components/Global/ReactTable/TableHeaders/RowsSelected";
import getCheckboxColumn from "components/Global/ReactTable/Columns/Checkbox";
import ReactTable from "react-table";
import {
  tabStateHandler,
  DarkTabs,
  Div,
  Text3,
  TinyShadedBox
} from "components/Base";
import { makeEnum, isEmptyOrNil } from "utils/General";

import * as R from "ramda";

const COLOR = "#61A5FF";

const TabValues = makeEnum(["ALL_PEOPLE", "PRIMARY_CONTACTS", "LOGIN_USERS"]);

const Table = ({ data, helpers, ...other }) => {
  const dataWithHelpers = data.map(contact => ({
    ...contact,
    helpers
  }));

  const countOfRecords = data.length;
  const pageSize = countOfRecords < 5 ? countOfRecords || 2 : 5;
  const rowsText = countOfRecords === 1 ? "person" : "people";

  return (
    <PaginationManager pageSize={pageSize}>
      <ReactTable
        data={dataWithHelpers}
        countOfRecords={countOfRecords}
        rowsText={rowsText}
        {...other}
      />
    </PaginationManager>
  );
};

const Display = CSSModules(
  tabStateHandler(
    ({
      tabs,
      activeComponent,
      showAddContactModal,
      accountName,
      emptyComponent
    }) => {
      return (
        <Card color={COLOR}>
          <Header>
            <Title>
              <IconTitle title={`People at ${accountName}`} color={COLOR}>
                <i className={`material-icons ${css.icon}`}>group</i>
              </IconTitle>
              {Boolean(showAddContactModal) && (
                <OutlineButton onClick={showAddContactModal}>
                  Add Person
                </OutlineButton>
              )}
            </Title>
          </Header>
          <div>
            <Div bb={1} mb={3} bc={"neutral3"}>
              <Div ml={4}>
                <DarkTabs tabs={tabs} />
              </Div>
            </Div>
            {emptyComponent || activeComponent}
          </div>
        </Card>
      );
    }
  ),
  css
);

const EventList = ({ events }) => (
  <Div style={{ overflowX: "auto" }}>
    <Div
      style={{
        flexShrink: 0
      }}
      display={"row.flex-start.center"}
    >
      {R.map(
        ({ name, id }) => (
          <TinyShadedBox
            style={{
              flexShrink: 0
            }}
            mr={1}
            pill
            key={id}
          >
            {name}
          </TinyShadedBox>
        ),
        events
      )}
    </Div>
  </Div>
);

////////////////////////////////////////////////////////////////////////////////

const ORG_COLUMN_IDS = ["fname", "role", "events", "actions"];
const EVENT_COLUMN_IDS = ["fname", "type", "role", "actions"];
const COLUMNS = {
  fname: {
    id: "fname",
    Header: "Full Name",
    accessor: val => {
      const fname = get(
        val.values.find(v => v.field_id === CONTACT_STANDARD_FIELD_IDS.fname),
        ["value", "value"],
        ""
      );
      const lname = get(
        val.values.find(v => v.field_id === CONTACT_STANDARD_FIELD_IDS.lname),
        ["value", "value"],
        ""
      );
      return `${fname} ${lname}`;
    },
    sortable: true,
    Cell: args => {
      return (
        <Contact
          id={args.original.contact_id}
          canLogin={args.original.can_login}
          isPrimaryContact={args.original.is_primary}
          name={args.value}
          params={args.original.helpers.params}
        />
      );
    }
  },
  events: {
    id: "events",
    Header: "Events",
    accessor: val => val.events,
    maxWidth: 250,
    sortable: false,
    Cell: args => <EventList events={args.value} />
  },
  type: {
    id: "type",
    Header: "Type",
    accessor: val => val.type.name,
    maxWidth: 100,
    sortable: true,
    Cell: args => <span>{args.value || "—"}</span>
  },
  role: {
    id: "role",
    Header: "Role",
    accessor: val => val.role,
    maxWidth: 100,
    sortable: true,
    Cell: args => <span>{args.value || "—"}</span>
  },
  //email: {
  //   id: "email",
  //   Header: "Email",
  //   accessor: val =>
  //     get(
  //       val.values.find(v => v.field_id === CONTACT_STANDARD_FIELD_IDS.email),
  //       ["value", "value"],
  //       ""
  //     ),
  //   maxWidth: 150,
  //   sortable: true,
  //   Cell: args => <span>{args.value || "—"}</span>
  // },
  // can_login: {
  //   id: "can_login",
  //   Header: "Can login",
  //   accessor: val => val.can_login,
  //   maxWidth: 100,
  //   sortable: true,
  //   Cell: args => <span>{args.value ? "Yes" : "No"}</span>
  // },
  actions: {
    id: "actions",
    Header: "",
    accessor: "",
    width: 100,
    sortable: false,
    Cell: args => (
      <ContactMenu row={args.original} {...args.original.helpers}>
        <OutlineButton style={{ height: 26 }}>
          Actions <i className="material-icons">arrow_drop_down</i>
        </OutlineButton>
      </ContactMenu>
    )
  }
};

////////////////////////////////////////////////////////////////////////////////

// @CSSModules(css)

class AccountPeople extends Component {
  static getContactFieldValue(contact, fieldId) {
    return get(find(contact.values, { field_id: fieldId }), "value.value", "");
  }

  state = {
    selectedRows: []
  };

  showAddContactModal = () => {
    this.props.showModal({
      content: (
        <AddContactModal
          existingContacts={this.props.contacts}
          onSave={this.relateContactToAccount}
          params={this.props.params}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showEditContactModal = ({ relationshipId, role }) => {
    this.props.showModal({
      content: (
        <EditRelatedContactModal
          onDelete={this.removeRelatedContact}
          onSave={this.updateRelatedContact}
          relationshipId={relationshipId}
          role={role}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showBulkEditContactModal = () => {
    this.props.showModal({
      content: <EditRelatedContactModal onSave={this.bulkEditContacts} />,
      wrapper: ModalWrapper
    });
  };

  showGiveLoginAccessModal = contactId => {
    this.props.showModal({
      content: (
        <BulkLoginDetails
          contacts={
            typeof contactId === "string"
              ? [
                  {
                    contactId,
                    accountId: this.props.params.recordId
                  }
                ]
              : this.getSelectedContactRecords().map(record => ({
                  contactId: record.contact_id,
                  accountId: this.props.params.recordId
                }))
          }
          onDone={this.postUpdateActions}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showShareLoginDetailsModal = () => {
    this.props.showModal({
      content: (
        <BulkLoginDetails
          shareOnly
          contacts={this.getSelectedContactRecords().map(record => ({
            contactId: record.contact_id,
            accountId: this.props.params.recordId
          }))}
          onDone={this.postUpdateActions}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  getSelectedContactRecords = () =>
    this.state.selectedRows.map(rId =>
      this.props.contacts.find(c => c.id === rId)
    );

  postUpdateActions = () => {
    this.setState({ selectedRows: [] });
    return this.props.getAccount();
  };

  relateContactToAccount = async (
    contact,
    accountId = this.props.params.recordId,
    eventId = this.props.params.eventId
  ) => {
    if (!contact.contactId) {
      // If we need to create a contact, create it
      const createdContact = await this.props.addRecord({
        moduleId: STANDARD_MODULE_IDS.contacts.id,
        typeId: contact.recordTypeId,
        record: createContactFields(contact).reduce((a, b) => {
          a[b.fieldId] = b.value;
          return a;
        }, {}),
        options: {
          orgId: this.props.params.orgId,
          eventId
        }
      });

      // Update the id
      contact.contactId = createdContact.id;
    }

    // Associate the contact with the account
    await this.props.addRelationship({
      orgId: this.props.params.orgId,
      eventId,
      isPrimary: contact.isPrimary,
      role: contact.role,
      accountId,
      contactId: contact.contactId
    });

    if (contact.giveLoginAccess) {
      try {
        await this.giveLoginAccess({
          accountId,
          contactId: contact.contactId,
          orgId: this.props.params.orgId,
          eventId
        });
        if (contact.sendNotification) {
          this.sendNotificationEmail(contact);
        }
        this.props.showSnackbar({ message: "Person updated!" });
      } catch (e) {
        this.props.showSnackbar({ message: "Error giving login access" });
      } finally {
        this.postUpdateActions();
      }
    } else {
      this.postUpdateActions();
      this.props.showSnackbar({ message: "Person updated!" });
    }
  };

  removeRelatedContact = async row => {
    try {
      await this.deleteContactRelationship(row);
      this.props.showSnackbar({ message: "Person removed from account" });
    } catch (e) {
      this.props.showSnackbar({
        message: "Error removing person from account"
      });
    } finally {
      this.postUpdateActions();
    }
  };

  async deleteContactRelationship({ id: relationshipId, can_login: userId }) {
    await this.props.deleteRelationship({
      accountId: this.props.params.recordId,
      relationshipId
    });
    if (userId) {
      await this.revokeLoginAccess(userId);
    }
  }

  updateRelatedContact = data =>
    this.props
      .updateRelationship({
        ...data,
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId,
        accountId: this.props.params.recordId
      })
      .then(this.postUpdateActions)
      .then(() => this.props.showSnackbar({ message: "Person updated" }));

  async giveLoginAccess({ accountId, contactId, orgId, eventId }) {
    await this.props.createPortalUserFromContact({
      accountId,
      contactId,
      orgId,
      eventId
    });
  }

  async revokeLoginAccess(userId) {
    const accountUser = this.props.users.find(u => u.user_id === userId);
    if (accountUser) {
      return this.props.deleteAccountUser({
        accountUserId: accountUser.id,
        accountId: this.props.params.recordId
      });
    }
    throw new Error("No account user found");
  }

  handleLoginAccess = async row => {
    if (row.can_login) {
      try {
        await this.revokeLoginAccess(row.can_login);
        this.props.showSnackbar({ message: "Person removed from account" });
      } catch (e) {
        this.props.showSnackbar({ message: "Error removing login access" });
      } finally {
        this.postUpdateActions();
      }
    } else {
      this.showGiveLoginAccessModal(row.contact_id);
    }
  };

  showLoginDetails = (row, showFlashMessage = false) =>
    this.props.showModal({
      content: (
        <LoginDetails
          showFlashMessage={showFlashMessage}
          row={row}
          hideModal={this.props.hideModal}
          eventDetails={this.props.eventDetails}
          onEmailSend={this.sendNotificationEmail}
        />
      ),
      wrapper: ModalWrapper
    });

  sendNotificationEmail = ({ customMessage, email }) => {
    const { eventDetails, accountName } = this.props;

    const variables = {
      message: customMessage,
      event_name: eventDetails.name,
      event_slug: eventDetails.slug,
      portal_id: eventDetails.uuid,
      account_name: accountName,
      user_email: email
    };

    // @FIX SEND EMAIL
    return this.props.sendEmail({
      eventId: eventDetails.id,
      templateName: "notification-account-invite",
      recipients: [{ email }],
      variables: Object.keys(variables).map(key => ({
        name: key,
        content: variables[key]
      }))
    });
  };

  isSelectAllChecked = () =>
    this.state.selectedRows.length &&
    this.state.selectedRows.length === this.props.contacts.length;

  isChecked = id =>
    this.state.selectedRows.length && this.state.selectedRows.includes(id);

  selectAll = () =>
    this.setState(state => {
      if (this.isSelectAllChecked()) {
        state.selectedRows = [];
      } else {
        state.selectedRows = this.props.contacts.map(({ id }) => id);
      }
      return state;
    });

  toggleContact = id =>
    this.setState(state => {
      if (state.selectedRows.includes(id)) {
        state.selectedRows = state.selectedRows.filter(r => r !== id);
      } else {
        state.selectedRows = [...state.selectedRows, id];
      }
      return state;
    });

  buildColumns = () => {
    const colGroup = this.props.params.orgId
      ? ORG_COLUMN_IDS
      : EVENT_COLUMN_IDS;
    return [
      {
        ...getCheckboxColumn({
          selectAll: this.selectAll,
          onClick: this.toggleContact,
          isSelectAllChecked: this.isSelectAllChecked,
          isChecked: this.isChecked
        }),
        width: 69
      },
      ...colGroup.map(key => COLUMNS[key])
    ];
  };

  gotoContact = id =>
    this.props.router.push({
      pathname: this.props.params.orgId
        ? `/organization/${this.props.params.orgId}/contact/${id}`
        : `/event-light/${this.props.params.eventId}/contact/${id}`,
      query: {
        viaAccount: this.props.params.recordId
      }
    });

  bulkEditContacts = sharedData =>
    Promise.all(
      this.getSelectedContactRecords().map(contact =>
        this.props.updateRelationship({
          orgId: this.props.params.orgId,
          eventId: this.props.params.eventId,
          accountId: this.props.params.recordId,
          relationshipId: contact.id,
          ...contact,
          ...sharedData
        })
      )
    ).then(this.postUpdateActions);

  bulkDelete = async () => {
    const rows = this.state.selectedRows;
    const contacts = this.props.contacts;
    const selectedContacts = contacts.filter(c => rows.includes(c.id));
    await Promise.all(
      selectedContacts.map(r => this.deleteContactRelationship(r))
    );
    this.postUpdateActions();
  };

  render() {
    const { contacts, accountName, params } = this.props;
    const helpers = {
      params,
      gotoContact: this.gotoContact,
      onDelete: this.removeRelatedContact,
      onEditRole: this.showEditContactModal,
      onUpdatePrimary: this.updateRelatedContact,
      handleLoginAccess: this.handleLoginAccess,
      onLoginDetails: this.showLoginDetails,
      showChangeContactRecordTypeModal: this.props
        .showChangeContactRecordTypeModal
    };

    const sharedProps = {
      helpers: helpers,
      className: "ReactTable-record",
      noDataText: "No one has been added to this group",
      columns: this.buildColumns(),
      showPageJump: false,
      showPageSizeOptions: false,
      PaginationComponent: PaginationBar,
      TheadComponent: getRowsSelectedHeader({
        ActionsComponent: ActionsBar,
        selectedRows: this.state.selectedRows,
        helpers: {
          params,
          selectAll: this.selectAll,
          isSelectAllChecked: this.isSelectAllChecked,
          deleteAccounts: this.bulkDelete,
          edit: this.showBulkEditContactModal,
          giveLoginAccess: this.showGiveLoginAccessModal,
          shareLoginDetails: this.showShareLoginDetailsModal
        }
      })
    };

    const tabs = [
      {
        label: "All People",
        value: TabValues.ALL_PEOPLE,
        component: <Table {...sharedProps} data={contacts} />
      },
      {
        label: "Primary Contacts",
        value: TabValues.PRIMARY_CONTACTS,
        component: (
          <Table
            {...sharedProps}
            data={contacts.filter(x => x.is_primary === true)}
          />
        )
      },
      this.props.params.eventId
        ? {
            label: "Login Users",
            value: TabValues.LOGIN_USERS,
            component: (
              <Table
                {...sharedProps}
                data={contacts.filter(x => x.can_login)}
              />
            )
          }
        : null
    ].filter(Boolean);

    return (
      <Display
        accountName={accountName}
        showAddContactModal={this.showAddContactModal}
        tabs={tabs}
        defaultTabValue={TabValues.ALL_PEOPLE}
        emptyComponent={
          isEmptyOrNil(contacts) ? (
            <Text3 bold p={4} color="neutral8">
              No People
            </Text3>
          ) : null
        }
      />
    );
  }
}

AccountPeople.defaultProps = {
  contacts: []
};

AccountPeople.propTypes = {
  accountName: PropTypes.string.isRequired,
  createPortalUserFromContact: PropTypes.func.isRequired,
  deleteAccountUser: PropTypes.func.isRequired,
  eventDetails: PropTypes.object.isRequired,
  addAccountUser: PropTypes.func.isRequired,
  addRelationship: PropTypes.func.isRequired,
  contacts: PropTypes.arrayOf(PropTypes.object).isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  deleteRelationship: PropTypes.func.isRequired,
  getAccount: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  params: PropTypes.shape({
    accountId: PropTypes.string,
    eventId: PropTypes.string
  }).isRequired,
  showModal: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  sendEmail: PropTypes.func.isRequired,
  updateRelationship: PropTypes.func.isRequired
};

export default AccountPeople;
