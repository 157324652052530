import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import { getBlocksWithLineItems } from "../selectors";

import {
  Text4,
  Text3,
  Text2,
  Text1,
  Div,
  FullSizeDotIcon
} from "components/Base";
import createPassesSimpleTable from "ui-kit/PassesSimpleTable";
import { os } from "ui-kit/Theme";

import BlockFooter from "./BlockFooter";

const Num = (align = "center") => accessor =>
  R.compose(
    number => (
      <Text2 display={`row.${align}.center`} flex={1}>
        {number}
      </Text2>
    ),
    num => {
      if (Number.isNaN(num) || num === 0) {
        return "-";
      }
      if (Array.isArray(num)) {
        return R.sum(num);
      }
      return num;
    },
    Number,
    R.path(R.split(".", accessor))
  );

const columns = [
  {
    cell: ({ variant }) => (
      <Div display="row.flex-start.center">
        <FullSizeDotIcon
          size={8}
          style={{ color: R.path(["item", "background"], variant) }}
        />
        <Text3 bold ml={2}>
          {R.path(["display_name"], variant)}
        </Text3>
      </Div>
    ),
    size: [3 / 7, "left"],
    header: () => <Text1 bold>ITEM NAME</Text1>
  },
  {
    cell: Num()("summary.requested"),
    size: [1 / 7, "right"],
    header: () => <Text1 bold>REQUESTED</Text1>
  },
  {
    cell: Num()("summary.pending"),
    size: [1 / 7, "right"],
    header: () => <Text1 bold>PENDING</Text1>
  },
  {
    cell: Num()("summary.approved"),
    size: [1 / 7, "right"],
    header: () => <Text1 bold>APPROVED</Text1>
  }
];

const Table = createPassesSimpleTable({ columns, style: { bb: 0 } });

const decorate = connect((state, props) => ({
  blocks: getBlocksWithLineItems(state, props)
}));

const ItemBlocks = ({
  instanceId,
  blocks = [],
  handlers,
  readOnly,
  ...styleProps
}) => (
  <Div {...os(styleProps)}>
    {R.map(
      block => (
        <Div my={4}>
          <Text4 bold mb={2}>
            {block.name}
          </Text4>
          {R.length(block.variants) ? (
            <Table rowsData={block.variants} />
          ) : (
            <Div my={3}>
              <Text3>No Items Selected </Text3>
            </Div>
          )}
          {readOnly ? null : (
            <BlockFooter
              handlers={handlers}
              block={block}
              instanceId={instanceId}
            />
          )}
        </Div>
      ),
      blocks
    )}
  </Div>
);

export default decorate(ItemBlocks);
