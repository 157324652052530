import React, { Component } from "react";
import { get } from "lodash";

import Helpers from "utils/Global/Helpers";
import View from "./View";

import { connect } from "react-redux";
import { getCredentials } from "redux/modules/user/selectors";

import Api from "utils/Global/WebApiUtils";

const decorate = connect(state => ({
  userCredentials: getCredentials(state)
}));

const KEYS = {
  fname: {
    message: "Please enter your first name",
    validator: "isValidValue"
  },
  lname: {
    message: "Please enter your last name",
    validator: "isValidValue"
  },
  email: {
    message: "Please enter your email",
    validator: "isValidEmail"
  },
  company: {
    message: "Please enter the name of your company",
    validator: "isValidValue"
  },
  agreeToTerms: {
    message: "Please agree to the terms of service and privacy policy",
    validator: "isValidValue"
  }
};

class Controller extends Component {
  constructor(props) {
    super(props);
    const { location, params } = props;

    this.state = {
      error: null,
      emailExists: false,
      existingUserRequiresVerification: false,
      processing: false,
      agreeToTerms: false,
      receiveUpdates: false,
      template: get(params, "template", null),
      company: get(location.query, "org", ""),
      fname: get(location.query, "fname", ""),
      lname: get(location.query, "lname", ""),
      email: get(location.query, "email", ""),
      referralSourceId: get(location.query, "referralSourceId")
    };
  }

  validateForm = async () => {
    if (this.isLoggedIn(this.props.user)) {
      return this.validateLoggedInForm();
    }
    return await this.validateLoggedOutForm();
  };

  handleRegistration = response => {
    // api creates the organization and user if user does not exist
    if (response.success) {
      window.location = this.isLoggedIn(this.props.user)
        ? `/org-light/${response.payload.organization.id}/dashboard`
        : "/signup/complete";
    } else {
      throw new Error(`Trial Signup Error: ${JSON.stringify(response)}`);
    }
  };

  validateLoggedOutForm = async () => {
    const keysToCheck = ["fname", "lname", "email", "company", "agreeToTerms"];

    // validate: we have valid values
    for (let i = 0; i < keysToCheck.length; i++) {
      const key = keysToCheck[i];
      if (!this[KEYS[key].validator](this.state[key])) {
        this.setState({
          processing: false,
          error: KEYS[keysToCheck[i]].message
        });
        return false;
      }
    }

    this.setState({
      error: null,
      emailExists: false,
      existingUserRequiresVerification: false
    });

    return true;
  };

  validateLoggedInForm = () => {
    const keysToCheck = ["company"];

    // validate: we have valid values
    for (let i = 0; i < keysToCheck.length; i++) {
      const key = keysToCheck[i];
      if (!this[KEYS[key].validator](this.state[key])) {
        this.setState({
          processing: false,
          error: KEYS[keysToCheck[i]].message
        });
        return false;
      }
    }

    this.setState({ error: null });

    return true;
  };

  onFieldChange = e =>
    this.setState({ [e.target.name]: e.target.value, error: null });

  onToggleCheckbox = (name, value) =>
    this.setState({ [name]: value, error: null });

  isValidValue = v => Boolean(v);
  isValidEmail = v => this.isValidValue(v) && Helpers.isValidEmail(v);
  isLoggedIn = u => u && Boolean(u.id);

  onSubmit = async e => {
    e.preventDefault();

    const isValidForm = await this.validateForm();
    if (this.state.processing || !isValidForm) return false;

    this.setState({ processing: true });

    const payload = {
      template: this.state.template,
      existingUserId:
        this.props.userCredentials && this.props.userCredentials.userId
          ? this.props.userCredentials.userId
          : undefined,
      email: this.state.email,
      fname: this.state.fname,
      lname: this.state.lname,
      company: this.state.company,
      referralSourceId: this.state.referralSourceId,
      receiveUpdates: this.state.receiveUpdates
    };

    try {
      const response = await Api.registerTrial(
        this.props.userCredentials,
        payload
      );

      this.handleRegistration(response);
    } catch (err) {
      // @NOTE: We get a 500 on any error, but we'll assume it's because email exists
      this.setState({
        processing: false,
        emailExists: true,
        existingUserRequiresVerification: false,
        // existingUser.email_verified_at === null,
        error: "email_already_exists"
      });
      return false;
    }

    return true;
  };

  render() {
    const { user } = this.props;
    const {
      error,
      emailExists,
      existingUserRequiresVerification,
      processing,
      fname,
      lname,
      email,
      company,
      agreeToTerms,
      receiveUpdates
    } = this.state;

    const isUserLoggedIn = this.isLoggedIn(user);

    return (
      <View
        {...{
          onSubmit: this.onSubmit,
          onFieldChange: this.onFieldChange,
          onToggleCheckbox: this.onToggleCheckbox,
          user,
          isUserLoggedIn,
          company,
          fname,
          lname,
          email,
          error,
          emailExists,
          existingUserRequiresVerification,
          processing,
          agreeToTerms,
          receiveUpdates
        }}
      />
    );
  }
}

export default decorate(Controller);
