import { connect } from "react-redux";

import DeleteProfile from "./DeleteProfile";

import { permissionProfiles } from "redux/modules/permissions/permission-profiles/selectors";

function mapStateToProps(state) {
  return {
    permissionProfiles: permissionProfiles(state)
  };
}

export default connect(mapStateToProps)(DeleteProfile);
