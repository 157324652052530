import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { isEmptyOrNil } from "utils/General";
import { memoize } from "ramda";

const ORG_GLOBAL_READ = `${STANDARD_MODULE_IDS.orgSettings.id}_read_all_module_data`;
const ORG_GLOBAL_MANAGE = `${STANDARD_MODULE_IDS.orgSettings.id}_manage_all_module_data`;

const EVENT_GLOBAL_READ = `${STANDARD_MODULE_IDS.settings.id}_read_all_module_data`;
const EVENT_GLOBAL_MANAGE = `${STANDARD_MODULE_IDS.settings.id}_manage_all_module_data`;

// @NOTE: If Org + Event Permissions are ever returned combined for a user,
// cando is currenly setup to allow any user who as ORG_GLOBAL_READ
// to qualify for EVENT_GLOBAL_READ (the same for EVENT_GLOBAL_MANAGE).
module.exports = memoize(function can(userPermissionProfile) {
  return {
    do: action =>
      userPermissionProfile &&
      userPermissionProfile.permissionList &&
      // includes: specified action

      (userPermissionProfile.permissionList.includes(action) ||
        // includes: ability to read typed data
        ((action === "read" || action.endsWith("_read")) &&
          (userPermissionProfile.permissionList.includes(
            `${action}_typed_records`
          ) ||
            userPermissionProfile.permissionList.includes(
              `${action}_owned_records`
            ))) ||
        // includes: ability to add typed line items
        ((action === "add_line_items" || action.endsWith("_add_line_items")) &&
          userPermissionProfile.permissionList.includes(
            `${action}_for_type`
          )) ||
        // includes: ability to read all module data
        (((action === "read" || action.includes("_read")) &&
          userPermissionProfile.permissionList.includes(ORG_GLOBAL_READ)) ||
          userPermissionProfile.permissionList.includes(EVENT_GLOBAL_READ)) ||
        // includes: ability to manage all module data
        // @NOTE: we disqualify the permission if trying to access a settings resource
        (userPermissionProfile &&
          (userPermissionProfile.permissionList.includes(ORG_GLOBAL_MANAGE) ||
            userPermissionProfile.permissionList.includes(
              EVENT_GLOBAL_MANAGE
            )) &&
          !action.includes(STANDARD_MODULE_IDS.orgSettings.id) &&
          !action.includes(STANDARD_MODULE_IDS.settings.id))),

    view: (moduleId, action = "read") => {
      let moduleIdToUse =
        moduleId === "master" ? STANDARD_MODULE_IDS.schedules.id : moduleId;
      if (isEmptyOrNil(userPermissionProfile)) return false;
      const { permissionList } = userPermissionProfile;

      let regex = new RegExp(`${action}`);
      if (moduleIdToUse) {
        regex = new RegExp(`${moduleIdToUse}+.*.+${action}`);
      }

      if (
        !permissionList.filter(Boolean).some(val => {
          return (
            regex.test(val) ||
            [ORG_GLOBAL_MANAGE, EVENT_GLOBAL_MANAGE].includes(val) ||
            (action === "read" &&
              [ORG_GLOBAL_READ, EVENT_GLOBAL_READ].includes(val))
          );
        })
      ) {
        return false;
      }
      return true;
    }
  };
});
