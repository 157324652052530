import React, { Component } from "react";
import { get, isEqual } from "lodash";
import { Link } from "react-router";
import { Div } from "components/Base";
import PillButton from "components/Global/CRM/PillButton";
import * as STANDARD_MODULE_FIELDS from "@lennd/value-types/src/constants/standard-module-field-ids";

class ContactName extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  formatValue = props => {
    if (props.value && props.value.value) {
      return props.value.value;
    }
    return "";
  };

  render() {
    const { dependentValues, row } = this.props;
    const contactId =
      get(row, "contact_id") || get(dependentValues, "meta.rowId");
    const suppressLinkToRecord = get(
      dependentValues,
      "meta.columnSettings.suppressLinkToRecord"
    );
    const columnId = get(dependentValues, "meta.columnId");
    const value = this.formatValue(this.props);
    const qs = dependentValues.meta.viewingContactVia
      ? `?viewingContactVia=${dependentValues.meta.viewingContactVia}`
      : "";
    const big =
      columnId === STANDARD_MODULE_FIELDS.MODULE.FULL_NAME
        ? get(dependentValues, "meta.columnSettings.big")
        : false;
    const showButton =
      columnId === STANDARD_MODULE_FIELDS.MODULE.FULL_NAME
        ? get(dependentValues, "meta.showButton")
        : false;
    const path = dependentValues.meta.orgId
      ? `/organization/${dependentValues.meta.orgId}/contact/${contactId}${qs}`
      : `/event/${dependentValues.meta.eventId}/contact/${contactId}${qs}`;

    return (
      <Div
        width={1}
        className="hoverContainer"
        style={{
          position: "relative"
        }}
      >
        {!suppressLinkToRecord ? (
          <Link to={path}>
            <Div fs={big ? 4 : 3} fw={3} color="purple8">
              {value}
            </Div>
          </Link>
        ) : (
          <Div fs={big ? 4 : 3} fw={3} color="black">
            {value}
          </Div>
        )}
        {showButton ? (
          <Div
            className="showOnHover"
            style={{
              position: "absolute",
              right: 3,
              top: "50%",
              transform: "translateY(-50%)"
            }}
          >
            <PillButton title="View Person" linkTo={path} />
          </Div>
        ) : null}
      </Div>
    );
  }
}

ContactName.defaultProps = {
  dependentValues: {},
  row: {}
};

export default ContactName;
