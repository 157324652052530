import React, { Component } from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import autobind from "autobind-decorator";
import listensToClickOutside from "react-onclickoutside";

import Themes from "components/Global/Themes/LenndThemes";

const styles = {
  helpButtonContainer: {
    width: 27,
    height: 45,
    marginRight: 10,
    position: "relative",
    display: "flex",
    alignItems: "center"
  },
  helpButton: {
    width: 20,
    height: 20,
    borderRadius: 100,
    color: Themes.purple.headerBarBackBackgroundColor,
    backgroundColor: Themes.purple.accent5Color,
    textAlign: "center",
    cursor: "pointer",
    userSelect: "none",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "22px",
    ":hover": {
      backgroundColor: "#FFFFFF"
    }
  }
};

@Radium
export default class HeaderHelp extends Component {
  goToHelpPage = () => {
    if (window.Intercom) {
      window.Intercom("show");
    } else {
      window.open("https://learn.lennd.com/", "_blank");
    }
  };

  render() {
    return (
      <div
        style={styles.helpButtonContainer}
        className="platform-header-help-button"
        onClick={this.goToHelpPage}
      >
        <div style={styles.helpButton}>?</div>
      </div>
    );
  }
}
