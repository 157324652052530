import PropTypes from "prop-types";
import React, { Component } from "react";
import Popover from "@lennd/material-ui/Popover";
import FilePreviewer from "components/Global/Table3/CellEditors/FileEditor/FilePreviewer";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class ExpandButton extends Component {
  render() {
    return (
      <div styleName="wrapper">
        <div styleName="container" onClick={this.props.onExpandClick}>
          <i className="material-icons" styleName="arrows">
            code
          </i>
        </div>
        <Popover
          open={this.props.expanded}
          anchorEl={this.props.anchorEl}
          anchorOrigin={{ horizontal: "left", vertical: "top" }}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
          onClose={this.props.closeExpanded}
        >
          <FilePreviewer
            showFilepicker={() => {
              this.props.closeExpanded();
              this.props.showFilepicker();
            }}
            onFileClick={this.props.onFileClick}
            deleteFile={this.props.deleteFile}
            downloadFile={this.props.downloadFile}
            files={this.props.files}
            isReadOnly={this.props.isReadOnly}
          />
        </Popover>
      </div>
    );
  }
}

ExpandButton.propTypes = {
  closeExpanded: PropTypes.func.isRequired,
  onExpandClick: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired,
  anchorEl: PropTypes.object,
  files: PropTypes.array.isRequired,
  showFilepicker: PropTypes.func.isRequired,
  onFileClick: PropTypes.func.isRequired,
  deleteFile: PropTypes.func.isRequired,
  downloadFile: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool
};

export default ExpandButton;
