import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Popover from "@lennd/material-ui/Popover";
import ColorChart from "./ColorChart";

@CSSModules(css)
class ColorPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
      selected: null
    };
  }

  @autobind
  togglePicker() {
    this.setState({ open: !this.state.open });
  }

  @autobind
  handleRequestClose() {
    this.setState({
      open: false
    });
  }

  @autobind
  handleOpen(event) {
    event.preventDefault();
    this.setState({
      open: true,
      anchorEl: event.currentTarget
    });
  }

  @autobind
  onSelect(selected) {
    this.setState({
      open: false,
      selected
    });
    this.props.onSelect(selected);
  }

  render() {
    const { backgroundColor, textColor } = this.props.selected;

    return (
      <div>
        <div
          styleName="colorBox"
          style={{ backgroundColor: backgroundColor || "lightgrey" }}
          onClick={this.handleOpen}
        >
          <i
            className="material-icons"
            styleName="icon"
            style={{ color: textColor || "black" }}
          >
            keyboard_arrow_down
          </i>
        </div>
        <Popover
          anchorOrigin={{
            horizontal: "left",
            vertical: "top"
          }}
          transformOrigin={{
            horizontal: "left",
            vertical: "top"
          }}
          open={this.state.open}
          canAutoPosition={false}
          onClose={this.handleRequestClose}
          anchorEl={this.state.anchorEl}
        >
          <ColorChart onSelect={this.onSelect} selected={this.props.selected} />
        </Popover>
      </div>
    );
  }
}

ColorPicker.propTypes = {
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.object
};

export default ColorPicker;
