import {
  REQUEST,
  RECEIVE,
  RECEIVE_USER,
  ERROR,
  CLEAR_ERROR,
  INVALIDATE
} from "./constants";
import * as selectors from "./selectors";
import api from "./api";

export function getUsers(eventId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const users = await api.list(getState().user.user.credentials, eventId);
      dispatch({ type: RECEIVE, payload: { users } });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function getUser(eventId, userId) {
  return async (dispatch, getState) => {
    try {
      const { payload } = await api.get(
        getState().user.user.credentials,
        eventId,
        userId
      );
      dispatch({ type: RECEIVE_USER, payload });
      return payload;
    } catch (error) {
      return dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function acceptInvitationToEvent(eventId, userId, data) {
  return async (dispatch, getState) => {
    try {
      await api.put(getState().user.user.credentials, {
        eventId,
        userId,
        data
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: "An error occurred accepting this invite" }
      });
    }
  };
}

export function invalidate() {
  return {
    type: INVALIDATE
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR
  };
}
