export const isFetchingProviders = state =>
  state.ticketing.providers.providers.fetching;
export const isFetchingConfigurations = state =>
  state.ticketing.providers.configurations.fetching;
export const fetchedConfigurations = state =>
  state.ticketing.providers.fetchedConfigurations;
export const isConfiguringProvider = state =>
  state.ticketing.providers.configurations.creating;
export const isRemovingProvider = state =>
  state.ticketing.providers.configurations.removing;
export const ticketingProviders = state => state.ticketing.providers.providers;
export const ticketingProviderConfigurations = state =>
  state.ticketing.providers.configurations;
export const isFetchingProviderEvents = state =>
  state.ticketing.providers.events.fetching;
export const providerEvents = state => state.ticketing.providers.events;
export const isFetchingTicketTypes = state =>
  state.ticketing.providers.ticketTypes.fetching;
export const ticketTypes = state => state.ticketing.providers.ticketTypes;
export const ticketTypeMapping = state =>
  state.ticketing.providers.ticketTypeMapping;
export const isSyncingTicketTypes = state =>
  state.ticketing.providers.ticketTypes.syncing;
