import PropTypes from "prop-types";
import React, { Component } from "react";
import Label from "components/Global-2016/Forms/Label";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Checkbox from "material-ui/Checkbox";
import { Dropdown, LoadingIcon, Div } from "components/Base";

@CSSModules(css)
class LookupSettings extends Component {
  state = { loading: true };

  componentDidMount() {
    this.props.getModulesWithFields(this.props.eventId).then(() => {
      this.setState({ loading: false });
    });
  }

  onModuleChange = value => {
    this.props.onChange({
      ...this.props.value,
      moduleId: value.value,
      fieldId: null
    });
  };

  onFieldChange = value => {
    this.props.onChange({
      ...this.props.value,
      fieldId: value.value
    });
  };

  onMultipleSelectChange = e => {
    this.props.onChange({
      ...this.props.value,
      multiple: !this.props.value.multiple
    });
  };

  fetchFields = moduleId => {
    this.props.getFields({
      moduleId,
      options: {
        eventId: this.props.eventId
      }
    });
  };

  render() {
    const {
      label,
      value,
      editing,
      modules,
      selectedModulePrimaryFields
    } = this.props;
    const { loading } = this.state;

    if (loading) {
      return (
        <Div pt={5} display="row.center.center">
          <LoadingIcon fs={5} />
        </Div>
      );
    }

    return (
      <div>
        <div className="input-wrapper">
          {label || <Label>Choose a module to lookup</Label>}
          <Dropdown
            disabled={editing}
            options={modules.map(({ id, name }) => ({
              label: name,
              value: id
            }))}
            selected={value.moduleId}
            onChange={this.onModuleChange}
          />
        </div>
        {value.moduleId ? (
          <div className="input-wrapper">
            <Label>Choose which field to search on</Label>
            <Dropdown
              options={selectedModulePrimaryFields.map(({ id, name }) => ({
                label: name,
                value: id
              }))}
              selected={value.fieldId}
              onChange={this.onFieldChange}
            />
          </div>
        ) : null}

        <div className="input-wrapper">
          <Checkbox
            label="Allow selecting multiple records"
            checked={value.multiple}
            onClick={this.onMultipleSelectChange}
            style={{ zIndex: 0 }}
          />
        </div>
      </div>
    );
  }
}

LookupSettings.propTypes = {
  eventId: PropTypes.string.isRequired,
  getModules: PropTypes.func.isRequired,
  modules: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  editing: PropTypes.bool,
  label: PropTypes.object,
  moduleBlacklist: PropTypes.array,
  getFields: PropTypes.func.isRequired,
  fields: PropTypes.func.isRequired
};

export default LookupSettings;
