import { get } from "lodash";

export const isFetching = (state, accountId) =>
  get(
    state,
    ["inventoryV2", "reports", "accountOverview", "fetching", accountId],
    false
  );

export const accountOverviewReport = (state, accountId) =>
  get(
    state,
    ["inventoryV2", "reports", "accountOverview", "report", accountId],
    []
  );
