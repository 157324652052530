import PropTypes from "prop-types";
import React from "react";
import SelectBase from "../SelectBase";
import { get } from "lodash";
import Formatter from "../../Table3/CellFormatters/User";
import Editor from "../../Editors/UserEditor";

const UserSelect = ({
  excludeUsers,
  hideGroups,
  disableAddPerson,
  onChange,
  value,
  options,
  rowMetaData
}) => (
  <SelectBase
    formatter={
      get(value, "value.records.length") ? (
        <Formatter dependentValues={rowMetaData} value={value} />
      ) : null
    }
    editor={
      <Editor
        excludeUsers={excludeUsers}
        disableAddPerson={disableAddPerson}
        hideGroups={hideGroups}
        rowMetaData={rowMetaData}
        onChange={onChange}
        options={options}
        value={value}
      />
    }
  />
);

UserSelect.propTypes = {
  value: PropTypes.object,
  rowMetaData: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  hideGroups: PropTypes.bool.isRequired,
  disableAddPerson: PropTypes.bool.isRequired
};

export default UserSelect;
