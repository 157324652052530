import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SendSummaryConfirmations from "./SendSummaryConfirmations";

import * as TemplateActions from "redux/modules/eventEmailTemplates/actions";
import * as ConfirmationActions from "redux/modules/formsV2/submission/confirmation/actions";
import * as TemplateSelectors from "redux/modules/eventEmailTemplates/selectors";

function mapStateToProps(state) {
  return {
    emailTemplates: TemplateSelectors.listArray(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...ConfirmationActions,
      ...TemplateActions
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(
  SendSummaryConfirmations
);
