import Helpers from "utils/Global/Helpers";

export default {
  getRecordWithIssuance: (
    credentials,
    eventId,
    recordId,
    variantId,
    fulfillmentId,
    orderType,
    customerAccountId,
    customerContactId
  ) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/modules/record/${recordId}/issue`,
        credentials,
        qs: {
          eventId,
          variantId,
          fulfillmentId,
          orderType,
          customerAccountId,
          customerContactId
        },
        success,
        error
      });
    }),
  searchCheckins: (credentials, eventId, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules/records/checkin`,
        qs: { eventId, ...data },
        credentials,
        success,
        error
      });
    }),
  getStats: (credentials, eventId, recordTypeId) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/credentials/event/${eventId}/passes/checkin/items/stats`,
        qs: { recordTypeId },
        credentials,
        success,
        error
      });
    }),
  checkin: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/modules/record/${
          data.recordId
        }/checkin`,
        data,
        credentials,
        success: result => {
          resolve(result);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  revertCheckin: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/modules/record/${
          data.recordId
        }/checkin`,
        data,
        credentials,
        success: result => {
          resolve(result);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    })
};
