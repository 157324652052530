export const NAMESPACE = "AddPageModal";
export const FORM_ID = "AddPageModalForm";

export const PAGES = {
  BASIC_DETAILS: "Basic Details",
  CONTENT: "Content",
  VISIBILITY: "Visibility"
};

export const STEPS = [
  PAGES.BASIC_DETAILS,
  PAGES.CONTENT
  //PAGES.VISIBILITY
];

export const LAYOUT_OPTIONS = {
  MEDIA_CENTER: "media_center",
  CREW_TRAINING: "training_center",
  CHECKOUT: "checkout",
  CUSTOM: "custom"
};

export const AVAILABLE_TO_OPTIONS = {
  EVERYONE_CAN_ACCESS: "everyoneCanAccess",
  LIMIT_ACCESS: "limitAccess"
};

export const VIDEO_SORT_METHODS = [
  {
    id: "date",
    label: "Date Added"
  },
  {
    id: "order",
    label: "Specified Order"
  }
];

export const LINK_TYPES = [
  {
    value: "link",
    label: "External Link (New Window)"
  },
  {
    value: "popup_link",
    label: "External Link (iFrame Popup)"
  },
  {
    value: "intercom",
    label: "Open Intercom"
  },
  {
    value: "network",
    label: "Open Community Sidebar"
  },
  {
    value: "lobby",
    label: "Go to Lobby"
  },
  {
    value: "agenda",
    label: "Go to Agenda"
  },
  {
    value: "attendees",
    label: "Go to Attendees"
  },
  {
    value: "rooms",
    label: "Go to Rooms"
  },
  {
    value: "networking",
    label: "Go to Networking"
  },
  {
    value: "speakers",
    label: "Go to Speakers"
  },
  {
    value: "sponsors",
    label: "Go to Sponsors"
  },
  {
    value: "expo",
    label: "Go to Expo"
  },
  {
    value: "content",
    label: "Go to Content"
  },
  {
    value: "bidding",
    label: "Go to Bidding"
  }
];

export const getLinkTypes = ({ stages }) => {
  return [
    ...LINK_TYPES,
    ...stages.map(s => ({
      value: `stage||${s.id}`,
      label: `Go to Stage: ${s.title}`
    }))
  ];
};

export const MATERIAL_ICONS = [
  {
    value: "home",
    label: "Home"
  },
  {
    value: "present_to_all",
    label: "Present"
  },
  {
    value: "groups",
    label: "People"
  },
  {
    value: "link",
    label: "Link"
  },
  {
    value: "ondemand_video",
    label: "Video"
  },
  {
    value: "videocam",
    label: "Camera"
  },
  {
    value: "storefront",
    label: "Expo"
  },
  {
    value: "keyboard_voice",
    label: "Microphone"
  },
  {
    value: "calendar_today",
    label: "Calendar"
  },
  {
    value: "add_comment",
    label: "Add Comment"
  },
  {
    value: "view_in_ar",
    label: "Virtual"
  },
  {
    value: "work_outline",
    label: "Work"
  },
  {
    value: "waving_hand",
    label: "Waving Hand"
  },
  {
    value: "chair_alt",
    label: "Chair"
  },
  {
    value: "description",
    label: "Description"
  },
  {
    value: "list",
    label: "List"
  },
  {
    value: "folder",
    label: "Folder"
  },
  {
    value: "grid_view",
    label: "Grid View"
  }
];

export const CUSTOM_ICONS = [
  {
    value: "leaderboard",
    label: "Leaderboard"
  },
  {
    value: "photos",
    label: "Photos"
  },
  {
    value: "notes",
    label: "Notes"
  },
  {
    value: "paper",
    label: "Paper"
  },
  {
    value: "chart",
    label: "Chart"
  },
  {
    value: "book",
    label: "Book"
  },
  {
    value: "team-of-20",
    label: "Team of 20"
  },
  {
    value: "support",
    label: "Support"
  },
  {
    value: "tv",
    label: "TV"
  },
  {
    value: "play",
    label: "Play Button"
  },
  {
    value: "golf",
    label: "Golf"
  },
  {
    value: "chat1",
    label: "Chat (1)"
  },
  {
    value: "chat2",
    label: "Chat (2)"
  },
  {
    value: "website",
    label: "Website"
  },
  {
    value: "food",
    label: "Food"
  },
  {
    value: "shirt",
    label: "Shirt"
  },
  {
    value: "home",
    label: "Home"
  },
  {
    value: "present_to_all",
    label: "Present"
  },
  {
    value: "link",
    label: "Link"
  },
  {
    value: "captech",
    label: "CapTech Logo"
  },
  {
    value: "corebridge",
    label: "Corebridge Financial Team Logo"
  }
];

export const INTERCOM_METHODS = [
  {
    id: "client",
    label: "Trigger using .launch-intercom class"
  },
  {
    id: "intercom",
    label: "Trigger by using settings in Intercom"
  }
];

export const CONTENT_SECTION_TYPES = [
  {
    id: "content",
    label: "Custom Content"
  },
  {
    id: "link",
    label: "External Link"
  }
];

export const FONT_FAMILIES = [
  {
    id: "roboto",
    label: "Roboto"
  }
];

export const FIELD_IDS = {
  CUSTOM: {
    PAGE_TITLE: "page_title",
    PAGE_SUBTITLE: "page_subtitle",
    PAGE_CONTENT: "page_content",
    DESCRIPTION: "description"
  },
  CHECKOUT: {
    PAGE_TITLE: "page_title",
    PAGE_SUBTITLE: "page_subtitle",
    VARIANTS: "variants"
  },
  MEDIA_CENTER: {
    INTERCOM_ID: "intercom_id",
    INTERCOM_METHOD: "intercom_method",
    GOOGLE_TAG_MANAGER_ID: "google_tag_manager_id",
    //
    ENABLE_SIDEBAR_VIDEO_1: "enable_sidebar_video_1",
    SIDEBAR_VIDEO_1_LABEL: "sidebar_video_1_label",
    SIDEBAR_VIDEO_1_TITLE: "sidebar_video_1_title",
    SIDEBAR_VIDEO_1_URL: "sidebar_video_1_url",
    //
    ENABLE_SIDEBAR_VIDEO_2: "enable_sidebar_video_2",
    SIDEBAR_VIDEO_2_LABEL: "sidebar_video_2_label",
    SIDEBAR_VIDEO_2_TITLE: "sidebar_video_2_title",
    SIDEBAR_VIDEO_2_URL: "sidebar_video_2_url",
    //
    ENABLE_SIDEBAR_VIDEO_3: "enable_sidebar_video_3",
    SIDEBAR_VIDEO_3_LABEL: "sidebar_video_3_label",
    SIDEBAR_VIDEO_3_TITLE: "sidebar_video_3_title",
    SIDEBAR_VIDEO_3_URL: "sidebar_video_3_url",
    //
    INTERVIEW_SCHEDULE_TITLE: "interview_schedule_title",
    INTERVIEW_SCHEDULE_SUBTITLE: "interview_schedule_subtitle",
    WATCH_INTERVIEW_REPLAYS_URL: "watch_interview_replays_url",
    ENABLE_REQUEST_INTERVIEW_BUTTON: "enable_request_interview_button",
    LEFT_SIDEBAR_LOGO_IMAGE_URL: "left_sidebar_logo_image_url",
    //
    EVENT_LOGO_URL: "event_logo_url",
    EVENT_WEBSITE_URL: "event_website_url",
    EVENT_TITLE: "event_title",
    EVENT_SUBTITLE: "event_subtitle",
    //
    SHOW_COUNT_OF_PEOPLE: "show_count_of_people",
    NETWORK_CHAT_LABEL: "network_chat_label",
    NETWORK_PEOPLE_LABEL: "network_people_label",
    ENABLE_POLLS: "enable_polls",
    ENABLE_QA: "enable_qa",
    USE_MATERIAL_ICONS: "use_material_icons",
    //
    ENABLE_ALERTS_SECTION: "enable_alerts_section",
    ALERTS_TITLE: "alerts_title",
    ALERTS_SUBTITLE: "alerts_subtitle",
    ALERTS_VIEW_ALL_LABEL: "alerts_view_all_label",
    //
    ENABLE_VIDEOS_SECTION: "enable_videos_section",
    VIDEOS_TITLE: "videos_title",
    VIDEOS_SUBTITLE: "videos_subtitle",
    VIDEOS_VIEW_ALL_LABEL: "videos_view_all_label",
    SORT_VIDEOS_BY: "sort_videos_by",
    //
    ENABLE_SOCIAL_SECTION: "enable_social_section",
    SOCIAL_TITLE: "social_title",
    SOCIAL_SUBTITLE: "social_subtitle",
    SOCIAL_FEED_URL: "social_feed_url",
    SOCIAL_FEED_ID: "social_feed_id",
    SOCIAL_FEED_CONTAINER_ID: "social_feed_container_id",
    //
    CONTENT_SECTION_1_LABEL: "content_section_1_label",
    CONTENT_SECTION_1_TYPE: "content_section_1_type",
    CONTENT_SECTION_1_LINK: "content_section_1_link",
    CONTENT_SECTION_1_CONTENT: "content_section_1_content",
    //
    CONTENT_SECTION_2_LABEL: "content_section_2_label",
    CONTENT_SECTION_2_TYPE: "content_section_2_type",
    CONTENT_SECTION_2_LINK: "content_section_2_link",
    CONTENT_SECTION_2_CONTENT: "content_section_2_content",
    //
    CONTENT_SECTION_3_LABEL: "content_section_3_label",
    CONTENT_SECTION_3_TYPE: "content_section_3_type",
    CONTENT_SECTION_3_LINK: "content_section_3_link",
    CONTENT_SECTION_3_CONTENT: "content_section_3_content",
    //
    LEFT_SIDEBAR_COLOR: "left_sidebar_color",
    LINK_COLOR: "link_color",
    LEFT_SIDEBAR_SECONDARY_COLOR: "left_sidebar_secondary_color",
    FONT_FAMILY: "font_family",
    //
    RIGHT_SIDEBAR_LINKS: "right_sidebar_links",
    RIGHT_SIDEBAR_LOGO_URL: "right_sidebar_logo_url",
    MOBILE_TITLE_LINE_1: "mobile_title_line_1",
    MOBILE_TITLE_LINE_2: "mobile_title_line_2"
  }
};

export const DEFAULT_SIDEBAR_LINKS = [
  {
    id: 1,
    type: "link",
    label: "Leaderboard",
    icon: "leaderboard",
    value: "http://www.website.com",
    enabled: true,
    order: 1
  },
  {
    id: 2,
    type: "link",
    label: "Photos",
    icon: "photos",
    value: "http://www.website.com",
    enabled: true,
    order: 2
  },
  {
    id: 3,
    type: "link",
    label: "Notes",
    icon: "notes",
    value: "http://www.website.com",
    enabled: true,
    order: 3
  },
  {
    id: 4,
    type: "link",
    label: "Transcripts",
    icon: "paper",
    value: "http://www.website.com",
    enabled: true,
    order: 4
  },
  {
    id: 5,
    type: "link",
    label: "Statistics",
    icon: "chart",
    value: "http://www.website.com",
    enabled: true,
    order: 5
  },
  {
    id: 6,
    type: "link",
    label: "Players Guide",
    icon: "book",
    value: "http://www.website.com",
    enabled: true,
    order: 6
  },
  {
    id: 7,
    type: "link",
    label: "Team",
    icon: "team-of-20",
    value: "http://www.website.com",
    enabled: true,
    order: 7
  },
  {
    id: 8,
    type: "intercom",
    label: "Live Support",
    icon: "support",
    value: null,
    enabled: true,
    order: 8
  }
];
