import { registerError } from "redux/modules/errors/actions";
import { REQUEST, RECEIVE, TOGGLE_STARRED_EVENT } from "./constants";
import * as selectors from "./selectors";
import api from "./api";

export function getEvents(userId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const events = await api.get(getState().user.user.credentials, userId);
      dispatch({ type: RECEIVE, payload: events });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting events"
          }
        ])
      );
    }
  };
}

export function toggleStarredEvent(data) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: TOGGLE_STARRED_EVENT,
        payload: data
      });
      const result = await api.put(getState().user.user.credentials, data);
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred toggling starred event"
          }
        ])
      );
    }
  };
}
