import CalculatedNumberEditor from "components/Global/StandAloneEditors/CalculatedNumber";
import CatalogItemEditor from "components/Global/StandAloneEditors/CatalogItem";
import CateringEditor from "components/Global/StandAloneEditors/Catering";
import CheckboxEditor from "components/Global/StandAloneEditors/Checkbox";
import CountriesEditor from "components/Global/Editors/Countries";
import CredentialEditor from "components/Global/StandAloneEditors/Credential";
import CurrencyEditor from "components/Global/StandAloneEditors/Currency";
import DateEditor from "components/Global/StandAloneEditors/Date";
import DateTimeEditor from "components/Global/StandAloneEditors/DateTime";
import DependencyEditor from "components/Global/Editors/DependencyEditor";
import DietaryRestrictionsEditor from "components/Global/StandAloneEditors/CateringDietaryRestrictions";
import DropDownEditor from "components/Global/Editors/DropdownEditor";
import EventDaysEditor from "components/Global/StandAloneEditors/EventDays";
import FileUploadEditor from "components/Global/StandAloneEditors/FileUpload";
import ItemBlockEditor from "components/Global/StandAloneEditors/ItemBlock";
import LinkedRecordsEditor from "components/Global/StandAloneEditors/LinkedRecords";
import MultipleSelectEditor from "components/Global/StandAloneEditors/MultipleSelect";
import NumberEditor from "components/Global/StandAloneEditors/Number";
import PasswordEditor from "components/Global/StandAloneEditors/Password";
import PercentEditor from "components/Global/StandAloneEditors/Percent";
import PhoneEditor from "components/Global/StandAloneEditors/Phone";
import Reference from "components/Global/StandAloneEditors/Reference";
import TextArea from "components/Global/StandAloneEditors/TextArea";
import TextEditor from "components/Global/StandAloneEditors/Text";
import TimeEditor from "components/Global/StandAloneEditors/Time";
import UserEditor from "components/Global/StandAloneEditors/User";

export default function resolveEditor(column) {
  if (
    column.type === "dropdown" &&
    column.settings &&
    column.settings.allowMultipleSelect &&
    column.editable !== false
  ) {
    return MultipleSelectEditor;
  } else if (column.type === "dependency" && column.editable !== false) {
    return DependencyEditor;
  } else if (column.type === "dropdown" && column.editable !== false) {
    return DropDownEditor;
  } else if (
    (column.type === "checkbox" || column.type === "boolean") &&
    column.editable !== false
  ) {
    return CheckboxEditor;
  } else if (column.type === "date" && column.editable !== false) {
    return DateEditor;
  } else if (column.type === "percent" && column.editable !== false) {
    return PercentEditor;
  } else if (column.type === "currency" && column.editable !== false) {
    return CurrencyEditor;
  } else if (column.type === "datetime" && column.editable !== false) {
    return DateTimeEditor;
  } else if (column.type === "countries" && column.editable !== false) {
    return CountriesEditor;
  } else if (column.type === "phone" && column.editable !== false) {
    return PhoneEditor;
  } else if (column.type === "time") {
    return TimeEditor;
  } else if (column.type === "file") {
    return FileUploadEditor;
  } else if (column.type === "credentials" && column.editable !== false) {
    return CredentialEditor;
  } else if (column.type === "item-block" && column.editable !== false) {
    return ItemBlockEditor;
  } else if (column.type === "event-days" && column.editable !== false) {
    return EventDaysEditor;
  } else if (column.type === "catering" && column.editable !== false) {
    return CateringEditor;
  } else if (
    column.type === "catering-dietary-restrictions" &&
    column.editable !== false
  ) {
    return DietaryRestrictionsEditor;
  } else if (
    (column.type === "number" || column.type === "quantity") &&
    column.editable !== false
  ) {
    return NumberEditor;
  } else if (column.type === "user" && column.editable !== false) {
    return UserEditor;
  } else if (column.type === "reference" && column.editable !== false) {
    return Reference;
  } else if (column.type === "calculated-number") {
    return CalculatedNumberEditor;
  } else if (column.type === "textarea" && column.editable !== false) {
    return TextArea;
  } else if (column.type === "lookup" && column.editable !== false) {
    return LinkedRecordsEditor;
  } else if (column.type === "catalog-item" && column.editable !== false) {
    return CatalogItemEditor;
  } else if (column.type === "password" && column.editable !== false) {
    return PasswordEditor;
  } else if (column.type !== "static" && column.editable !== false) {
    return TextEditor;
  }
  return null;
}
