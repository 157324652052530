import PropTypes from "prop-types";
import React from "react";

import { Div, Text2, FontIcon } from "components/Base";

const Label = ({
  children,
  description,
  required,
  readonly,
  isAdminField,
  ...styleProps
}) => (
  <Div {...styleProps}>
    <Div display="row.flex-start.center">
      {children}

      {isAdminField ? (
        <FontIcon ml={2} size={8} color="primary3">
          playlist_add_check
        </FontIcon>
      ) : (
        ""
      )}

      {required ? <Div color="danger5">*</Div> : null}

      {readonly ? (
        <Div
          bra={1}
          color="neutral7"
          bg="neutral1"
          uppercase
          fs={1}
          ml={2}
          p={1}
        >
          Read-only
        </Div>
      ) : null}
    </Div>

    {description && <Text2>{description}</Text2>}
  </Div>
);

Label.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  required: PropTypes.bool,
  isAdminField: PropTypes.bool,
  description: PropTypes.string
};

export default Label;
