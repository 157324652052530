import Helpers from "utils/Global/Helpers";

module.exports = {
  get: (credentials, eventId, accountId) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/portal/settings/${eventId}/${accountId}`,
        data: {},
        credentials,
        success: result => {
          resolve(result.payload);
        },
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  getPageType: (credentials, pageId) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/sites/page/${pageId}`,
        data: {},
        credentials,
        success: result => {
          resolve(result.payload);
        },
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
