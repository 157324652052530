import * as ITEM_TYPES from "utils/item-types";

export const TabValues = {
  OVERVIEW: "OVERVIEW",
  MANAGE_ORDERS: "MANAGE_ORDERS"
};
export const ManageOrdersTabValues = {
  PENDING: "PENDING",
  ALL: "ALL",
  ACCOUNT: "ACCOUNT",
  PEOPLE: "PEOPLE"
};

export const ItemTypesEmailMap = {
  [ITEM_TYPES.CREDENTIAL_TYPE_ID]: "CREDENTIALS_SUMMARY",
  [ITEM_TYPES.INVENTORY_TYPE_ID]: "INVENTORY_SUMMARY",
  [ITEM_TYPES.MEAL_TYPE_ID]: "CATERING_SUMMARY",
  [ITEM_TYPES.SPONSORSHIP_TYPE_ID]: "CREDENTIALS_SUMMARY"
};
