import isEmpty from "../is-empty";

// Empty values should always be less than values with data regardless of ascending/decending
export default function sortEmpty(type, sortFunc) {
  return (a, b, options) => {
    if (isEmpty(a, type) && isEmpty(b, type)) return 0;
    if (isEmpty(a, type)) return 1;
    if (isEmpty(b, type)) return -1;
    return sortFunc(a, b, options);
  };
}
