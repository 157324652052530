import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as R from "ramda";
import { Div } from "components/Base";

import { getters, actions } from "../model";
import { getSelectedTabTotal } from "../selectors";
import { TABLE_VIEW_ID } from "../constants";

import PaginationBar from "./PaginationBar";
import Table from "./Table";
import Toolbar from "./Toolbar";
import Tabs from "./Tabs";
import ShowNoResults from "ui-kit/ShowNoResults";

import LoadingOverlay from "ui-kit/LoadingOverlay";

import { groups } from "redux/modules/items/item-groups/selectors";

import { getters as SearchBarGetters } from "ui-kit/SearchBar";

import SubNav from "./SubNav";

const renderTable = ({
  loading,
  showNoResults,
  passesTypes,
  instanceId = ""
}) => {
  if (loading) {
    return <LoadingOverlay />;
  }

  if (showNoResults) {
    return <ShowNoResults flex={1} instanceId={instanceId} />;
  }

  return (
    <Table
      {...{
        passesTypes,
        instanceId
      }}
    />
  );
};

const decorate = R.compose(
  connect(
    state => ({
      loading: getters.loading(state),
      searchTerm: SearchBarGetters.searchTerm(state),
      showNoResults: getters.showNoResults(state),
      total: getSelectedTabTotal(state),
      passesTypes: groups(state)
    }),
    {
      initData: actions.initData
    }
  ),
  withRouter
);

const ManagementView = ({
  passesTypes,
  loading = false,
  showNoResults = false,
  total = 0,
  instanceId = "",
  params,
  initData
}) => {
  useEffect(() => {
    initData(params);
  }, [params.historyType]);
  return (
    <Div display="column" style={{ height: "calc(100% - 51px)" }}>
      <SubNav />
      <Div display="row.center.stretch" style={{ height: "calc(100% - 43px)" }}>
        <Div
          flex={1}
          display="column.center.stretch"
          width={5} // @NOTE: This is a hack to have the table width nicely fit
        >
          <Div
            px={4}
            bg="white"
            display="column.space-between.stretch"
            style={{ flexShrink: 0, position: "relative" }}
            bb={1}
            bc="neutral3"
          >
            <Toolbar instanceId={instanceId} />
            <Tabs />
            {/* Removing create order button*/}
          </Div>
          <Div
            bg="neutral1"
            flex={1}
            display="column.space-between.stretch"
            style={{
              overflowY: loading ? "hidden" : "auto",
              position: "relative"
            }}
            id={TABLE_VIEW_ID}
          >
            {renderTable({
              loading,
              showNoResults,
              passesTypes,
              total,
              instanceId
            })}
          </Div>
          <PaginationBar instanceId={instanceId} />
        </Div>
      </Div>
    </Div>
  );
};

export default decorate(ManagementView);
