import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Editor from "../../Editors/TextEditor";
import { equals } from "ramda";

export default class TextEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  componentDidUpdate(prevProps) {
    if (!equals(prevProps.value, this.props.value)) {
      this.setState({
        value: this.props.value
      });
    }
  }

  @autobind
  onBlur() {
    this.props.onChange(this.state.value);
  }

  @autobind
  onChange(value) {
    this.setState({
      value
    });
    if (this.props.continuous) {
      this.props.onChange(value);
    }
  }

  render() {
    return (
      <Editor
        maxLength={this.props.maxLength}
        disabled={this.props.disabled}
        autocomplete={this.props.autocomplete}
        onBlur={this.onBlur}
        onChange={this.onChange}
        value={this.state.value}
        className="styled"
        data-cy={this.props["data-cy"]}
      />
    );
  }
}

TextEditor.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.object
};
