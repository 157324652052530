import React from "react";
import { Div } from "components/Base";
import Header from "./Header";
import Body from "./ModalBody";

const Frame = ({ children }) => (
  <Div m={5} style={{ overflow: "none" }} display="row.center.center">
    <Div
      display="column.flex-start"
      bra={1}
      shadow={2}
      bg="white"
      height={569}
      width={1019}
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxHeight: "calc(100% - 100px)"
      }}
      onMouseDown={e => e.stopPropagation()}
    >
      {children}
    </Div>
  </Div>
);

const Layout = ({ handlers }) => (
  <Frame>
    <Header handlers={handlers} />
    <Body handlers={handlers} />
  </Frame>
);

export default Layout;
