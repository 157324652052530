/* eslint-disable no-underscore-dangle */
import { createSelector } from "reselect";
import * as R from "ramda";

import { assetRequests } from "redux/modules/portal/reports/selectors";

const mapIndexed = R.addIndex(R.map);

export const getAllAssignmentsRows = createSelector(
  assetRequests,
  R.compose(
    mapIndexed((assignmentRow, idx) => ({
      id: R.propOr(idx, "id", assignmentRow),
      contactName: {
        type: "text",
        value: R.prop("contactName", assignmentRow)
      },
      type: {
        type: "text",
        value: R.prop("type", assignmentRow)
      },
      quantity: {
        type: "text",
        value: R.prop("quantity", assignmentRow)
      },
      details: {
        type: "text",
        value: R.prop("details", assignmentRow)
      },
      status: {
        type: "text",
        value: R.prop("status", assignmentRow)
      }
    })),
    R.propOr([], "assignments")
  )
);

export const getSummaryValues = createSelector(
  assetRequests,
  R.compose(
    R.map(summaryRow => ({
      id: R.prop("id", summaryRow),
      name: {
        type: "text",
        value: R.prop("name", summaryRow)
      },
      requested: {
        type: "text",
        value: R.prop("requested", summaryRow)
      },
      approved: {
        type: "text",
        value: R.prop("approved", summaryRow)
      },
      rejected: {
        type: "text",
        value: R.prop("rejected", summaryRow)
      },
      pending: {
        type: "text",
        value: R.prop("pending", summaryRow)
      }
    })),
    R.propOr([], "counts")
  )
);
