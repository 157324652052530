import React, { Component } from "react";

import ApproversLabel from "ApproversLabel/View";

import { getValue } from "../../utils";

class Approval extends Component {
  render() {
    let value = getValue(this.props);

    if (!value) return "-";

    return (
      <ApproversLabel
        {...{
          // data
          instanceId: this.props.instanceId,
          type: value.type,
          status: value.status,
          manifestId: value.manifestId,
          recordIds: value.recordIds,
          // style
          leftMenu: true,
          containerProps: {
            display: "row.flex-start.center",
            pl: 2,
            pr: 2
          }
        }}
      />
    );
  }
}

export default Approval;
