import getValue from "../get-value/date";
import sortEmpty from "./sort-empty";

export default {
  ASC: sortEmpty(
    "date",
    (a, b) => new Date(getValue(a)) - new Date(getValue(b))
  ),
  DESC: sortEmpty(
    "date",
    (a, b) => new Date(getValue(b)) - new Date(getValue(a))
  )
};
