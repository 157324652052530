import * as R from "ramda";
import { withStateHandlers, mapProps } from "../utils";

const areAll = (x, y) => R.isEmpty(R.difference(x, y));
const areAny = (x, y) => !R.isEmpty(R.intersection(x, y));

const addSelectableGroupProps = ({ selection, items, onRemove, onSelect }) => {
  const allIds = R.pluck("id", items);
  const allAreSelected = areAll(allIds, selection);
  return {
    toggleAll: allAreSelected ? () => onRemove(allIds) : () => onSelect(allIds),
    onSelectAll: onSelect && (() => onSelect(allIds)),
    onRemoveAll: onRemove && (() => onRemove(allIds)),
    allAreSelected,
    someAreSelected: areAny(allIds, selection)
  };
};

const enhanceSelectableItems = ({ selection, items, onRemove, onSelect }) => {
  return R.map(item => {
    const itemId = R.prop("id", item);
    const itemArray = R.isNil(itemId) ? [] : [itemId];
    const selected = R.contains(itemId, selection);
    return {
      ...item,
      selected,
      toggle: selected ? () => onRemove(itemArray) : () => onSelect(itemArray),
      onRemove: onRemove && (() => onRemove(itemArray)),
      onSelect: onSelect && (() => onSelect(itemArray))
    };
  }, items);
};

const selectableHandler = withStateHandlers(
  ({ initialSelection = [] }) => ({
    selection: initialSelection
  }),
  {
    onRemove: ({ selection }) => ids => ({
      selection: R.without(ids, selection)
    }),
    onSelect: ({ selection }) => ids => ({
      selection: R.union(ids, selection)
    })
  }
);

const extendedSelectableHandler = (Comp, selectProp) => {
  return R.compose(
    selectableHandler,
    mapProps(props => ({
      ...props,
      [selectProp]: enhanceSelectableItems({
        selection: props.selection,
        items: props[selectProp],
        onRemove: props.onRemove,
        onSelect: props.onSelect
      }),
      ...addSelectableGroupProps({
        selection: props.selection,
        items: props[selectProp],
        onRemove: props.onRemove,
        onSelect: props.onSelect
      })
    }))
  )(Comp);
};

////////////////////////////////////////////////////////////////////////////////

export {
  selectableHandler,
  addSelectableGroupProps,
  enhanceSelectableItems,
  extendedSelectableHandler
};
