import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import {
  Div,
  EditIcon,
  SmallFilledButton,
  SmallOutlineButton
} from "components/Base";
import { Input } from "components/Global/Modal/Layout/FormElements";
import { noop } from "utils/General";
import {
  getters as editTextGetters,
  actions as editTextActions
} from "./model";
import { bindInstance } from "redux-mvc";
import { actions } from "../model";
import { isEditing } from "./selectors";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const decorate = R.compose(
  connect(
    (state, props) => ({
      isEditing: isEditing(state, props),
      newText: editTextGetters.newText(state, props)
    }),
    bindInstance({
      updateText: actions.updateText,
      editNewText: editTextActions.setNewText,
      setEditing: editTextActions.setEditing
    })
  ),
  CSSModules(css)
);

const EditText = ({
  children = "",
  updateText = noop,
  editNewText = noop,
  newText = "",
  setEditing = noop,
  isEditing = false,
  fieldData = null,
  type = "",
  isShown = false
}) => {
  const child = children;
  const pointerCursor = { cursor: "pointer" };
  return (
    <Div display="row.flex-start.center">
      {isShown ? (
        !isEditing ? (
          <Div
            onMouseDown={e => {
              e.stopPropagation();
              setEditing();
            }}
            display="row"
          >
            <EditIcon mr={1} size={20} style={pointerCursor} />
            {child ? (
              child
            ) : (
              <label styleName="labelPlaceHolder"> Add a {type} ... </label>
            )}
          </Div>
        ) : (
          <Div
            display="row.flex-start.center"
            mb={1}
            onClick={e => {
              e.stopPropagation();
            }}
          >
            <Input
              type="text"
              value={newText}
              name="newText"
              maxLength="255"
              onClick={e => {
                e.stopPropagation();
              }}
              onChange={e => {
                e.stopPropagation();
                editNewText(e.target.value);
              }}
            />
            <SmallFilledButton
              ml={2}
              bg="altB5"
              disabled={(newText === "" || newText === null) && type === "name"}
              onClick={e => {
                e.stopPropagation();
                updateText({
                  text: newText,
                  fieldData,
                  type
                });
                setEditing();
              }}
              style={pointerCursor}
            >
              save
            </SmallFilledButton>
            <SmallOutlineButton
              ml={2}
              onClick={e => {
                e.stopPropagation();
                setEditing();
                editNewText("");
              }}
              style={pointerCursor}
            >
              Cancel
            </SmallOutlineButton>
          </Div>
        )
      ) : (
        child
      )}
    </Div>
  );
};

export default decorate(EditText);
