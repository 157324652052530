export const DOCUMENTS_REPORT_ID = "b42c6991-9ef9-4125-a55a-56213e69bc56";
export const FORMS_REPORT_ID = "8647d858-d48b-44d6-9acc-0ebd75753ed3";
export const PEOPLE_REPORT_ID = "2437d858-d48b-44d6-9acc-0ebd75753ed5";
export const ASSETS_REPORT_ID = "8a55f2b6-483b-4eaa-bddd-fc0c0b5ee19a";
export const CREDENTIALS_REPORT_ID = "9a22f2b6-483b-4eaa-bddd-fc0c0b5ee19b";
export const CATERING_REPORT_ID = "4a24f2b6-383b-4eaa-aedd-fc0c0b5ee19a";
export const MEALS_BY_GROUP_REPORT_ID = "5a24f2b6-383b-4eaa-aedd-fc0c0b5ee19a";
export const MEALS_BY_DAY_REPORT_ID = "6a24f2b6-383b-4eaa-aedd-fc0c0b5ee19a";
export const CREDENTIALS_BY_GROUP_REPORT_ID =
  "7a24f2b6-383b-4eaa-aedd-fc0c0b5ee19a";
export const ACCOUNTS_BY_TYPE_REPORT_ID =
  "2a32e2b6-383b-4eaa-aedd-fc0c0b5ee19a";
export const ORDER_LINE_ITEMS_REPORT_ID =
  "1a42e2b5-383b-4eaa-aedd-ec0c0b5ee19b";
export const GUEST_LIST_REPORT_ID = "5a44e2b6-383b-4eaa-aedd-fc0c0b5ee19b";
export const ISSUANCE_REPORT_ID = "5b13e2b6-383b-4eaa-aedd-fc0c0b5ee19a";
