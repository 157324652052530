import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  eventCredentials: [],
  dashboard: {}
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    refreshDashboard: R.identity,
    showAddModal: R.identity,
    setInitialData: (_, { payload: { eventCredentials, dashboard } }) => ({
      eventCredentials,
      dashboard
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
