/* eslint-disable no-underscore-dangle */
import React, { Component } from "react";
import View from "./View";
import Fuse from "fuse.js";
import moment from "moment";

import * as R from "ramda";
import ManageEventActions from "actions/Event/DetailsActions";

import RemoveEventTeamUserModal from "components/Global/Modals/RemoveEventTeamMemberModal";
import EditCollaboratorInfoModal from "components/Global/Modals/EditCollaboratorInfoModal";
import LoggedInProfileActions from "actions/Global/LoggedInProfileActions";
import AddEventTeamUserModal from "components/Global/Modals/AddEventTeamMemberModal";
import PortalUsersApi from "redux/modules/portal/users/api";

const formatFullName = user => [user.user_fname, user.user_lname].join(" ");

const searchWithFuse = (searchTerm, fuse, opt, matchWith) => {
  if (R.isNil(searchTerm) || R.isEmpty(searchTerm)) {
    return opt;
  }
  return R.compose(
    R.reject(R.isNil),
    R.map(o => R.find(R.propEq(matchWith, o.user_id), opt))
  )(fuse.search(searchTerm));
};

class Controller extends Component {
  state = {
    searchTerm: ""
  };

  componentDidMount() {
    this.props.getUsers(this.props.eventDetails.id);
  }

  componentWillReceiveProps(nextProps) {
    this.usersFuse = new Fuse(nextProps.users, {
      threshold: 0.3,
      keys: ["user_fname", "user_lname", "user_email"],
      shouldSort: true
    });
  }

  onSearch = searchTerm => {
    if (searchTerm !== this.state.searchTerm) {
      this.setState({ searchTerm });
    }
  };

  showEditInfoModal = record => {
    const modal = (
      <EditCollaboratorInfoModal
        hideModal={this.props.hideModal}
        userId={record.user_id}
        record={record}
        onUpdated={this.handleInfoUpdated}
        showPermissions
      />
    );

    this.props.showModal({ content: modal });
  };

  handleInfoUpdated = () => {
    this.props.getUsers(this.props.eventDetails.id);

    this.props.showSnackbar({ message: "User updated", action: "OK" });
  };

  resendInvitation = user => {
    ManageEventActions.resendInvitation(
      this.props.user.credentials,
      this.props.eventDetails.id,
      user.user_id,
      () => {
        this.props.getUsers(this.props.eventDetails.id);
        this.props.showSnackbar({
          message: `Invitation resent to ${user.user_email}`,
          action: "OK"
        });
      }
    );
  };

  handleRemoveMember = data => {
    if (this.props.users.find(u => u.user_id === data.userId).is_portal_user) {
      PortalUsersApi.delete(
        { userId: 7 },
        {
          eventId: this.props.eventDetails.id,
          userId: data.userId
        }
      ).then(() => {
        this.props.getUsers(this.props.eventDetails.id);
        this.props.showSnackbar({ message: "User removed", action: "OK" });
      });
    } else {
      ManageEventActions.removeUserFromEvent(
        this.props.user.credentials,
        this.props.eventDetails.id,
        data.userId,
        () => {
          if (data.userId === this.props.user.id) {
            // refresh events and notifications, then redirect to home
            this.props.getEvents(this.props.user.id);
            LoggedInProfileActions.receiveUserNotifications(
              this.props.user.credentials
            );
            this.props.router.push({ pathname: "/home" });

            this.props.showSnackbar({
              message: "You left the event",
              action: "OK"
            });
          } else {
            this.props.getUsers(this.props.eventDetails.id);
            this.props.showSnackbar({ message: "User removed", action: "OK" });
          }
        }
      );
    }
  };

  showRemoveFromEventModal = (userName, userId, user) => {
    const modal = (
      <RemoveEventTeamUserModal
        user={user}
        userName={userName}
        userId={userId}
        hideModal={this.props.hideModal}
        handleRemoveMember={this.handleRemoveMember}
      />
    );

    this.props.showModal({ content: modal });
  };

  showAddEventTeamUserModal = () => {
    const modal = (
      <AddEventTeamUserModal
        hideModal={this.props.hideModal}
        addToEventId={this.props.eventDetails.id}
        onAdded={this.handleEventTeamUserAdded}
      />
    );

    this.props.showModal({ content: modal });
  };

  handleEventTeamUserAdded = (data, user, action) => {
    this.props.getUsers(this.props.eventDetails.id);

    this.props.showSnackbar({
      message: action === "added" ? "User added" : "User invited",
      action: "OK"
    });
  };

  onCopy = () => {
    this.props.showSnackbar({
      message: "Copied",
      action: "OK"
    });
  };

  render() {
    const users = R.map(user => ({
      ...user,
      name: formatFullName(user),
      email: user.user_email,
      photoUrl: user.user_photo_url,
      permissionRole: user.permission_role_name,
      isPending: user.status === "pending",
      inviteSentAt: moment(new Date(user.invite_sent_at)).fromNow(),
      onCopy: this.onCopy,
      inviteLink: user.is_virtual_user
        ? `${window.__LENND_APP_URL__}/join/vu/${user.user_virtual_hash}`
        : window.__LENND_APP_URL__,
      onView: () => this.showEditInfoModal(user),
      onRemove: () =>
        this.showRemoveFromEventModal(formatFullName(user), user.user_id, user),
      onResend: () => this.resendInvitation(user)
    }))(this.props.users);

    const filteredUsers = searchWithFuse(
      this.state.searchTerm,
      this.usersFuse,
      users,
      "user_id"
    );

    return (
      <View
        {...{
          users: filteredUsers,
          onSearch: this.onSearch,
          onInviteUser: this.showAddEventTeamUserModal
        }}
      />
    );
  }
}

export default Controller;
