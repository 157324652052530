import { createModule } from "redux-mvc";

import model from "./model";

import sagasModule from "./sagas";

const module = createModule({
  ...model,
  observedDomains: ["permissions"]
});

module.setRootSaga(sagasModule);

export default module;
