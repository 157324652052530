import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Select from "components/Global/Inputs/Select";
import Popover from "@lennd/material-ui/Popover";
import { cloneDeep, isEqual } from "lodash";

export default class SelectBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
      top: 0,
      cachedValue: props.initialValue
        ? cloneDeep(props.initialValue)
        : undefined
    };
    this.editor = React.createRef();
  }

  componentDidUpdate(prevProps) {
    // @NOTE: When leveraging this component as an `onClose` handler, we need
    // to refresh the state's initialValue as it changes
    if (this.props.initialValue !== prevProps.initialValue) {
      this.setState({
        cachedValue: cloneDeep(this.props.initialValue)
      });
    }
  }

  @autobind
  handleClick(event) {
    this.setState({
      open: !this.state.open,
      anchorEl: event.currentTarget
    });
  }

  @autobind
  handleOpen(event) {
    event.preventDefault();
    if (this.state.open) return false;
    this.setState(
      {
        anchorEl: event.currentTarget,
        open: true
      },
      () => {
        const editor = this.editor.current;
        if (editor && typeof editor.focus === "function") {
          editor.focus();
        }
      }
    );
  }

  @autobind
  handleClose() {
    // @NOTE: If saveOnClose, we assume the need to pass
    // the cached value via the onChange handler. If
    // the initial value is the same value, we don't need
    // to fire the handler.
    if (
      this.props.saveOnClose &&
      this.props.onClose &&
      !isEqual(this.props.initialValue, this.state.cachedValue)
    ) {
      this.props.onClose(this.state.cachedValue);
    }
    this.setState({
      open: false
    });
  }

  onChange = value => this.setState({ cachedValue: value });

  render() {
    // @NOTE: This currently causes the popover to trigger open whenever it's closed. Currently no-opping.
    // const onFocus = this.handleOpen;
    const onFocus = undefined;

    const editorProps = {
      close: this.handleClose,
      ref: this.editor
    };

    // @NOTE: If `saveOnClose`, then we override the onChange handler to
    // save the value on this component so we can pass it back when
    // the popover closes
    if (this.props.saveOnClose) {
      editorProps.onChange = this.onChange;
    }

    return (
      <Select
        width={"100%"}
        open={this.state.open}
        placeholder={"Select"}
        onClose={this.handleClose}
        onMouseDown={this.handleClick}
        onFocus={onFocus}
        value={this.props.formatter}
      >
        <Popover
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom"
          }}
          transformOrigin={{
            horizontal: "left",
            vertical: "top"
          }}
          open={this.state.open}
          canAutoPosition
          onClose={this.handleClose}
          anchorEl={this.state.anchorEl}
          transitionDuration={0.1}
        >
          <div className="ignore-react-onclickoutside">
            {this.state.open ? (
              React.cloneElement(this.props.editor, editorProps)
            ) : (
              <span />
            )}
          </div>
        </Popover>
      </Select>
    );
  }
}

SelectBase.propTypes = {
  editor: PropTypes.node.isRequired,
  formatter: PropTypes.node
};
