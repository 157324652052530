import PropTypes from "prop-types";
import React from "react";
import Arrow from "./Arrow";
import ListButton from "../ListButton";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const NoMatches = ({ search, addPerson }) => (
  <div>
    {search ? (
      <div styleName="optionContainer">
        <div styleName="noMatchesContainer">
          No matches for
          <span styleName="search">{search}</span>
          in your quick add list...
        </div>
        <div styleName="arrow">
          <Arrow />
        </div>
      </div>
    ) : (
      ""
    )}
    <div styleName="addButton">
      <ListButton
        primary
        title="Add new person"
        description={search ? `${search}...` : "First & last name"}
        onClick={addPerson}
      />
    </div>
  </div>
);

NoMatches.propTypes = {
  addPerson: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired
};

export default CSSModules(NoMatches, css);
