import { get } from "lodash";

export const fetching = (state, moduleId) =>
  get(state, `modules.views.fetching[${moduleId}]`, false);
export const views = (state, moduleId) =>
  Object.keys(get(state, `modules.views.views[${moduleId}].byId`, {})).map(
    id => state.modules.views.views[moduleId].byId[id]
  );
export const view = (state, moduleId, viewId) =>
  get(state, `modules.views.views[${moduleId}].byId[${viewId}]`, {});
export const activeView = (state, moduleId, viewId, groupId) => {
  if (viewId) {
    return get(state, `modules.views.views[${moduleId}].byId[${viewId}]`, {});
  }
  // if no viewId specified, attempt to find the current active view for user
  return (
    Object.keys(get(state, `modules.views.views[${moduleId}].byId`, {}))
      .map(id => state.modules.views.views[moduleId].byId[id])
      .find(v => {
        // if groupId is specified, only look at views scoped to this group type
        if (groupId) {
          return v.is_active && v.account_record_type_id === groupId;
        }

        // otherwise, only look at views that dont have groupId
        return v.is_active && v.account_record_type_id === null;
      }) || {}
  );
};
