import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import { addS } from "utils/General";
import { VisibleIcon, NotVisibleIcon } from "components/Base";
import { noop } from "utils/General";
import { usePasswordValidator } from "../hooks/usePasswordValidator";

import { actions } from "ui-kit/Form/model";
import { getValue } from "ui-kit/Form/selectors";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import {
  TinyOutlineInput,
  SmallOutlineInput,
  MediumOutlineInput,
  BigOutlineInput,
  SuperOutlineInput,
  TinyInsetInput,
  SmallInsetInput,
  MediumInsetInput,
  BigInsetInput,
  SuperInsetInput
} from "components/Base";

import { WithThemeConsumer, defaultTh, wsp, VARIANTS } from "ui-kit/Theme";
import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";

const decorate = R.compose(
  WithFormInstanceConsumer({ fieldType: FIELD_TYPES.VALUE }),
  WithThemeConsumer({ variant: VARIANTS.BACKGROUND }),
  connect(
    (state, props) => ({
      value: getValue(state, props)
    }),
    bindInstance({
      setIniValue: actions.setIniValue,
      onChange: actions.setFieldValueRequest,
      onFocus: actions.focusField,
      onBlur: actions.blurField
    })
  )
);

const inputs = {
  outline: {
    tiny: TinyOutlineInput,
    small: SmallOutlineInput,
    medium: MediumOutlineInput,
    big: BigOutlineInput,
    super: SuperOutlineInput
  },
  inset: {
    tiny: TinyInsetInput,
    small: SmallInsetInput,
    medium: MediumInsetInput,
    big: BigInsetInput,
    super: SuperInsetInput
  }
};

export const UCPassword = ({
  value = "",
  iniValue,
  Th = defaultTh,
  fieldId,
  fieldType,
  size = "medium",
  inputType = "inset",
  onChange = noop,
  setIniValue = noop,
  onFocus = noop,
  onBlur = noop,
  maxLength = "",
  "data-cy": dataCy,
  rules,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [validate, messages] = usePasswordValidator(rules);
  useEffect(() => {
    validate(iniValue);
    setIniValue(iniValue, {
      meta: { fieldType, fieldId }
    });
  }, []);

  const changevisibility = () => setShowPassword(!showPassword);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column"
      }}
    >
      <>
        {React.createElement(inputs[inputType][size] || MediumOutlineInput, {
          value,
          type: showPassword ? "text" : "password",
          onChange: val => {
            validate(val);
            return onChange(val, {
              meta: { fieldType, fieldId }
            });
          },
          continuous: true,
          maxLength,
          onFocus: () => onFocus(null, { meta: { fieldId, fieldType } }),
          onBlur: () => onBlur(null, { meta: { fieldId, fieldType } }),
          ...Th(["bg", "color", "bc"], props),
          ...wsp(props),
          "data-cy": dataCy,
          showPassword,
          RightIcon: () =>
            showPassword ? (
              <NotVisibleIcon onClick={changevisibility} />
            ) : (
              <VisibleIcon onClick={changevisibility} />
            )
        })}
        <div>{messages}</div>
        {!R.isEmpty(maxLength) ? (
          <div
            style={{
              fontSize: "13px",
              marginTop: "5px"
            }}
          >
            {value ? value.length : 0}/{maxLength} Character
            {value ? addS(R.length(value)) : ""}
          </div>
        ) : null}
      </>
    </div>
  );
};

export const Password = decorate(UCPassword);
