import React, { Component } from "react";
import View from "./View";

class Controller extends Component {
  render() {
    const resendLink = `${window.__LENND_APP_URL__}/signup/resend`;

    return (
      <View
        {...{
          resendLink
        }}
      />
    );
  }
}

export default Controller;
