import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { createContext } from "redux-mvc";
import * as R from "ramda";
import module, { actions } from "../index";
import Layout from "./Layout";
import rootSaga from "../sagas";
import ItemCatalogItems from "Event/ItemCatalogItems";
import CredentialGroupModal from "components/Event/Settings/Module/Modals/FieldGroup";
import ItemTypeModal from "components/Event/Settings/Catalog/Modals/ItemType";
import ZoneModal from "components/Event/Settings/Catalog/Modals/Zone";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import DeleteCredentialGroupModal from "components/Event/Settings/Credentials/Modals/DeleteCredentialGroup";
import { showModal, hideModal } from "redux/modules/modal/actions";

module.setRootSaga(rootSaga);
module.plugModule(ItemCatalogItems);

const decorate = createContext({
  module,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(actions.init());
    }
  },
  handlers: {
    addCategory: function({ isZone = false }) {
      this.props.showModal({
        content: (
          <CredentialGroupModal
            onSave={data =>
              !isZone
                ? this.store.dispatch(actions.addCategory(data))
                : this.store.dispatch(actions.addZoneCategory(data))
            }
            hideModal={this.props.hideModal}
            label="Category"
          />
        ),
        wrapper: ModalWrapper
      });
    },
    updateCategory: function(group, isZone = false) {
      this.props.showModal({
        content: (
          <CredentialGroupModal
            data={group}
            onSave={data =>
              !isZone
                ? this.store.dispatch(actions.updateCategory(data))
                : this.store.dispatch(actions.updateZoneCategory(data))
            }
            hideModal={this.props.hideModal}
            label="Category"
          />
        ),
        wrapper: ModalWrapper
      });
    },
    addUpdateItem: function({ id = "", typeId, clone = false }) {
      const updatedTypeId =
        typeId || R.pathOr("", ["params", "typeId"], this.props);
      this.props.showModal({
        content: (
          <ItemTypeModal
            typeId={updatedTypeId}
            itemId={id}
            clone={clone}
            onDone={data =>
              this.store.dispatch(actions.addUpdateItem({ data, id, clone }))
            }
            onUpdate={() => {}}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    addUpdateZoneItem: function({ id = "", typeId, clone = false }) {
      this.props.showModal({
        content: (
          <ZoneModal
            typeId={typeId}
            itemId={id}
            clone={clone}
            onDone={data =>
              this.store.dispatch(
                actions.addUpdateZoneItem({ data, id, clone })
              )
            }
            onUpdate={() => {}}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    deleteCategory: function(group, isZone = false) {
      this.props.showModal({
        content: (
          <DeleteCredentialGroupModal
            name={group.name}
            handleDelete={() =>
              !isZone
                ? this.store.dispatch(actions.deleteCategory({ group }))
                : this.store.dispatch(actions.deleteZoneCategory({ group }))
            }
            hideModal={this.props.hideModal}
          />
        )
      });
    },
    addZone: function({ id = "", typeId, clone = false }) {
      this.props.showModal({
        content: (
          <ZoneModal
            typeId={typeId}
            itemId={id}
            clone={clone}
            onDone={data => this.store.dispatch(actions.addZone(data))}
            onUpdate={() => {}}
          />
        ),
        wrapper: ModalWrapper
      });
    }
  },
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: [
      "user",
      "event",
      "modal",
      "organization",
      "permissions",
      "userAccessLevels",
      "@flopflip"
    ],
    persist: true
  }
});

export default R.compose(
  connect(
    null,
    { showModal, hideModal }
  ),
  withRouter,
  decorate
)(Layout);
