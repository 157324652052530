import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";

import {
  toggleField,
  updateField
} from "redux/modules/formsV2/form/fields/fulfillmentBlockFields/actions";
import { updateFulfillmentMethod } from "redux/modules/orders/fulfillment-method/actions";

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleField,
      updateField,
      updateFulfillmentMethod
    },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(Controller);
