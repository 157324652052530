import columnTypes from "./column-types";
import {
  catalogItem,
  checkbox,
  date,
  datetime,
  dropdown,
  email,
  eventDays,
  number,
  percent,
  phone,
  text,
  textarea,
  time,
  lookup,
  reference
} from "utils/value-types/schema";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

const blacklist = {
  [STANDARD_MODULE_IDS.accounts.id]: [
    catalogItem,
    lookup,
    reference,
    eventDays
  ],
  [STANDARD_MODULE_IDS.contacts.id]: [catalogItem, lookup, reference, eventDays]
};
export default (moduleId, orgId) => {
  if (moduleId === STANDARD_MODULE_IDS.sessions.id) {
    return [
      checkbox,
      date,
      datetime,
      dropdown,
      number,
      percent,
      phone,
      text,
      textarea,
      time
    ];
  }
  if (moduleId === STANDARD_MODULE_IDS.itemQuestions.id) {
    return [
      checkbox,
      date,
      datetime,
      dropdown,
      email,
      eventDays,
      number,
      percent,
      phone,
      text,
      textarea,
      time
    ];
  }
  if (moduleId in blacklist) {
    return columnTypes.filter(t => !blacklist[moduleId].includes(t));
  }
  if (orgId) {
    return columnTypes.filter(t => ![lookup, reference, eventDays].includes(t));
  }
  return columnTypes.filter(t => ![eventDays].includes(t));
};
