export default class Helpers {
  static formatRole(role) {
    switch (role) {
      case "canedit":
        return "Can edit";
      case "canview":
        return "Can view";
      case "owner":
        return "Is owner";
      default:
        return role;
    }
  }

  static mapOwners(list = []) {
    return list.reduce((a, curr) => ({ ...a, [curr.user_id]: curr }), {});
  }
}
