import PropTypes from "prop-types";
import React, { useState } from "react";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import * as R from "ramda";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Fuse from "fuse.js";
import Body from "components/Global/Modal/Layout/ScrollableBody";
import {
  ButtonGroup,
  Submit,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";
import Panel from "../Panel";
import Item from "../Item";

const searchWithFuse = (searchTerm, fuse, opt, matchWith) => {
  if (R.isNil(searchTerm) || R.isEmpty(searchTerm)) {
    return opt;
  }
  return R.compose(
    R.reject(R.isNil),
    R.map(o => R.find(R.propEq(matchWith, o.id), opt))
  )(fuse.search(searchTerm));
};

const AssignEvents = ({
  assignEvents,
  hideModal,
  list = [],
  moduleId = "",
  onDone,
  recordNamePlural = "",
  recordNameSingular = "",
  selectedRecords = [],
  unassignEvents
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedForAssignment, setSelectedForAssignment] = useState([]);
  const fuse = new Fuse(list, {
    threshold: 0.3,
    keys: ["name"],
    shouldSort: true
  });

  const changeSearchTerm = event => {
    setSearchTerm(R.path(["target", "value"], event));
  };

  const filterList = list => {
    return searchWithFuse(searchTerm, fuse, list, "id");
  };

  const handleSave = async () => {
    if (selectedForAssignment.length) {
      await assignEvents({
        eventIds: selectedForAssignment,
        moduleId: moduleId,
        recordIds: selectedRecords
      });
    }
    if (selectedForAssignment.length !== list.length) {
      const selectedForRemoval = list
        .filter(eventOption => !selectedForAssignment.includes(eventOption.id))
        .map(eventOption => eventOption.id);
      await unassignEvents({
        eventIds: selectedForRemoval,
        moduleId: moduleId,
        recordIds: selectedRecords
      });
    }
    onDone();
    return hideModal();
  };

  const isSelectedForAssignment = eventId => {
    return selectedForAssignment.includes(eventId);
  };

  const toggleSelectionForAssignment = id => {
    if (isSelectedForAssignment(id)) {
      return setSelectedForAssignment(
        R.reject(R.equals(id))(selectedForAssignment)
      );
    }
    return setSelectedForAssignment([...selectedForAssignment, id]);
  };

  const transformEventOption = ({ id, name }) => {
    return {
      id,
      isSelected: isSelectedForAssignment(id),
      name,
      onClick: () => toggleSelectionForAssignment(id)
    };
  };

  const eventsSelectedToAssign = R.compose(
    R.map(transformEventOption),
    R.filter(r => isSelectedForAssignment(r.id))
  )(list);
  const onClearSearch = () => changeSearchTerm({ target: { value: "" } });
  const results = R.compose(R.map(transformEventOption), filterList)(list);

  return (
    <StyleWrapper
      bodyStyles={{ padding: 0 }}
      containerStyles={{ overflowY: "hidden" }}
      heading={`Assigning events to ${selectedRecords.length} ${
        selectedRecords.length !== 1 ? recordNamePlural : recordNameSingular
      }`}
      hideModal={hideModal}
      width={745}
      height={600}
    >
      <Body
        style={{
          height: 400,
          padding: "20px 30px 0px"
        }}
      >
        <div styleName="container">
          <div styleName="users">
            <p styleName="assignWarning">
              <b>Warning: </b>
              {`this will overwrite any events that were previously assigned to these groups. To ensure continuity, please select all events that these groups should belong to.`}
              <br />
              <br />
              {`If you want to only edit certain groups, click "Cancel" and select a different combination of groups.`}
            </p>
            <div styleName="inputWrapper">
              <input
                autoFocus
                value={searchTerm}
                onChange={changeSearchTerm}
                placeholder="Search events..."
                styleName="input"
                type="text"
              />
              {searchTerm.length ? (
                <i
                  className="material-icons"
                  onClick={onClearSearch}
                  styleName="clearFilter"
                >
                  close
                </i>
              ) : (
                <i className="material-icons">search</i>
              )}
            </div>
            <div styleName="usersLists">
              {!results.length && searchTerm.length && (
                <div styleName="empty">No events match your search</div>
              )}
              {results.map(resultItem => (
                <Item
                  key={resultItem.id}
                  name={resultItem.name}
                  onClick={resultItem.onClick}
                  selected={resultItem.isSelected}
                  showCheckbox
                />
              ))}
            </div>
          </div>
          <div styleName="owners">
            <Panel title="Selected for assignment:">
              <div styleName="usersList">
                {!eventsSelectedToAssign.length ? (
                  <div styleName="empty">No events selected</div>
                ) : (
                  eventsSelectedToAssign.map(selectedEvent => {
                    return (
                      <Item
                        key={selectedEvent.id}
                        name={selectedEvent.name}
                        onClick={selectedEvent.onClick}
                        selected={selectedEvent.isSelected}
                        style={{
                          backgroundColor: "#FDE7CB",
                          marginBottom: 3
                        }}
                        icon={
                          <i
                            className={["material-icons", css.removeIcon].join(
                              " "
                            )}
                          >
                            close
                          </i>
                        }
                      />
                    );
                  })
                )}
              </div>
            </Panel>
          </div>
        </div>
      </Body>
      <div className={css.footer}>
        <ButtonGroup>
          <Submit title="Save" onClick={handleSave} />
          <ButtonOutline title="Cancel" onClick={hideModal} />
        </ButtonGroup>
      </div>
    </StyleWrapper>
  );
};

AssignEvents.propTypes = {
  recordIds: PropTypes.array.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  recordNamePlural: PropTypes.string.isRequired,
  onDone: PropTypes.func.isRequired
};

export default CSSModules(AssignEvents, css);
