import * as R from "ramda";
import {
  put,
  takeEvery,
  all,
  fork,
  take,
  select,
  call
} from "redux-saga/effects";
import { actions } from "./model";

import { actions as TableActions } from "ui-kit/Table/model";
import { ROW_ACTIONS as TABLE_ROW_ACTIONS } from "ui-kit/Table/constants";
import { actions as CreateAlertActions } from "Portal/Alerts/Create/model";

import {
  ROW_ACTIONS,
  TABLE_INSTANCE_ID,
  DEFAULT_COLUMN_WIDTHS
} from "./constants";
import { getColumns, getRows } from "./selectors";

import { registerError } from "redux/modules/errors/actions";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import { showSnackbar } from "redux/modules/snackbar/actions";

import Api from "./api";

const getParams = function*() {
  const eventId = yield select(getEventId);
  const credentials = yield select(getCredentials);

  return { eventId, credentials };
};

const loadAlerts = function*() {
  yield put(actions.setLoading(true));
  try {
    const params = yield call(getParams);
    const { payload: alertRows } = yield call(Api.getAlerts, params);
    const columns = yield select(getColumns);
    const rows = yield select(getRows, { rows: alertRows });
    yield all([
      put(actions.setData(rows)),
      put(
        TableActions.setData(
          {
            canEditCells: false,
            columns,
            rows: rows,
            columnWidths: DEFAULT_COLUMN_WIDTHS
          },
          {
            meta: {
              instanceId: TABLE_INSTANCE_ID
            }
          }
        )
      ),
      put(actions.setShowNoResults(R.length(rows) === 0))
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while loading alerts"
        }
      ])
    );
  }
  yield put(actions.setLoading(false));
};

const deleteAlert = function*({
  payload: {
    row: { id }
  }
}) {
  const { credentials, eventId } = yield call(getParams);
  try {
    yield call(Api.delete, { credentials, eventId, alertId: id });

    yield all([
      put(showSnackbar({ message: "Alert deleted", action: "OK" })),
      call(loadAlerts)
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred deleting alert"
        }
      ])
    );
  }
};

const createNewAlert = function*() {
  yield put(CreateAlertActions.openModal());
};

const editAlert = function*({
  payload: {
    row: { id }
  }
}) {
  yield put(CreateAlertActions.editAlert(id));
};

const watchTableActions = function*() {
  for (;;) {
    const action = yield take(TableActions.executeAction.type);

    const delegate = R.prop(action.payload.actionId, {
      [ROW_ACTIONS.DELETE]: deleteAlert,
      [ROW_ACTIONS.EDIT]: editAlert,
      [TABLE_ROW_ACTIONS.OPEN_RECORD]: editAlert
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};
const watchAddAlertModal = function*() {
  yield takeEvery(actions.openAddAlertModal.type, createNewAlert);
};

const watchOnCloseAlertModal = function*() {
  yield takeEvery(CreateAlertActions.closeModal.type, loadAlerts);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, loadAlerts);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchTableActions),
    fork(watchAddAlertModal),
    fork(watchOnCloseAlertModal)
  ]);
};

export default rootSaga;
