import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Container = ({ children, ...props }) => (
  <div {...props} styleName="container">
    {children}
  </div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired
};

export default CSSModules(Container, css);
