import React from "react";

import { put, all, takeEvery, fork, select, call } from "redux-saga/effects";

import { getCredentials } from "redux/modules/user/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { makeFuture } from "utils/General/sagas";

import { actions } from "./model";
import { registerError } from "redux/modules/errors/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal } from "redux/modules/modal/actions";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";

import Api from "./api";
import AddEditColumnModal from "Modules/AddEditColumnModal/View";
import ModalColumnDelete from "components/Global/Table3/ModalColumnDelete";
import DeleteFieldGroupModal from "components/Event/Settings/Module/Modals/DeleteFieldGroup";
import FieldGroupModal from "components/Event/Settings/Module/Modals/FieldGroup";

const formatFields = fields => {
  return fields;
};

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventDetails = yield select(getEventDetails);

  return {
    credentials,
    eventDetails
  };
};

const loadData = function*({ payload = {} }) {
  try {
    if (!payload.refresh) {
      yield put(actions.setLoading(true));
    }
    const { credentials, eventDetails } = yield call(getParams);

    const { payload: fieldsPayload } = yield call(Api.getFields, {
      credentials,
      eventId: eventDetails.id,
      moduleId: payload.moduleId
    });

    yield all([
      put(
        actions.setInitialData({
          groupFields: formatFields(fieldsPayload.group_fields),
          peopleFields: formatFields(fieldsPayload.people_fields)
        })
      )
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting fields"
        }
      ])
    );
  } finally {
    if (!payload.refresh) {
      yield put(actions.setLoading(false));
    }
  }
};

//

const handleShowAddEditFieldModal = function*({ moduleId, fieldId }) {
  const eventDetails = yield select(getEventDetails);
  const handleModal = makeFuture();
  yield put(
    showModal({
      content: (
        <AddEditColumnModal
          eventId={eventDetails.id}
          moduleId={moduleId}
          fieldId={fieldId}
          onSave={handleModal.done}
        />
      )
    })
  );
  return yield call(handleModal.onRealized);
};

const handleShowDeleteFieldModal = function*({ field }) {
  const handleModal = makeFuture();
  yield put(
    showModal({
      content: (
        <ModalColumnDelete
          column={field}
          handleDeleteColumn={handleModal.done}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleModal.onRealized);
};

const showAddFieldModal = function*({ payload: { moduleId } }) {
  const result = yield call(handleShowAddEditFieldModal, {
    moduleId
  });

  if (result) {
    yield put(showSnackbar({ message: "Field added", action: "OK" }));
    yield call(loadData, { payload: { refresh: true } });
  }
};

const showEditFieldModal = function*({ payload: { moduleId, fieldId } }) {
  const result = yield call(handleShowAddEditFieldModal, {
    moduleId,
    fieldId
  });

  if (result) {
    yield put(showSnackbar({ message: "Field saved", action: "OK" }));
    yield call(loadData, { payload: { refresh: true } });
  }
};

const showDeleteFieldModal = function*({ payload: { moduleId, field } }) {
  const { credentials, eventDetails } = yield call(getParams);
  const data = yield call(handleShowDeleteFieldModal, {
    field
  });

  if (data) {
    const toSend = {
      moduleId,
      field: {
        id: field.id
      },
      options: {
        eventId: eventDetails.id
      }
    };

    yield call(Api.deleteField, credentials, toSend);
    yield put(showSnackbar({ message: "Field removed", action: "OK" }));
    yield call(loadData, { payload: { refresh: true } });
  }
};

//

const handleAddEditFieldGroupModal = function*({ fieldGroup }) {
  const handleModal = makeFuture();
  yield put(
    showModal({
      content: (
        <FieldGroupModal
          label="field group"
          data={
            fieldGroup
              ? {
                  ...fieldGroup
                }
              : undefined
          }
          onSave={handleModal.done}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleModal.onRealized);
};

const handleShowDeleteFieldGroupModal = function*({ name }) {
  const handleModal = makeFuture();
  yield put(
    showModal({
      content: (
        <DeleteFieldGroupModal name={name} handleDelete={handleModal.done} />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleModal.onRealized);
};

const showDeleteFieldGroupModal = function*({
  payload: { moduleId, fieldGroup }
}) {
  const { eventDetails, credentials } = yield call(getParams);
  const data = yield call(handleShowDeleteFieldGroupModal, {
    name: fieldGroup.name
  });

  if (data) {
    const toSend = {
      moduleId,
      group: fieldGroup,
      options: {
        eventId: eventDetails.id
      }
    };

    yield call(Api.deleteFieldGroup, credentials, toSend);
    yield put(showSnackbar({ message: "Field group removed", action: "OK" }));
    yield call(loadData, { payload: { refresh: true } });
  }
};

const showAddFieldGroupModal = function*({ payload: { moduleId } }) {
  const { credentials, eventDetails } = yield call(getParams);
  const data = yield call(handleAddEditFieldGroupModal, {});

  if (data) {
    const toSend = {
      moduleId,
      group: data,
      options: {
        eventId: eventDetails.id
      }
    };

    yield call(Api.addFieldGroup, credentials, toSend);
    yield put(showSnackbar({ message: "Field group added", action: "OK" }));
    yield call(loadData, { payload: { refresh: true } });
  }
};

const showEditFieldGroupModal = function*({
  payload: { moduleId, fieldGroup }
}) {
  const { credentials, eventDetails } = yield call(getParams);
  const data = yield call(handleAddEditFieldGroupModal, {
    fieldGroup
  });

  if (data) {
    const toSend = {
      moduleId,
      group: data,
      options: {
        eventId: eventDetails.id
      }
    };

    yield call(Api.updateFieldGroup, credentials, toSend);
    yield put(showSnackbar({ message: "Field group added", action: "OK" }));
    yield call(loadData, { payload: { refresh: true } });
  }
};

//

const watchInit = function*() {
  yield takeEvery(actions.init.type, loadData);
};

//

const watchShowAddFieldModal = function*() {
  yield takeEvery(actions.showAddFieldModal.type, showAddFieldModal);
};

const watchShowEditFieldModal = function*() {
  yield takeEvery(actions.showEditFieldModal.type, showEditFieldModal);
};

const watchShowDeleteFieldModal = function*() {
  yield takeEvery(actions.showDeleteFieldModal.type, showDeleteFieldModal);
};

//

const watchShowAddFieldGroupModal = function*() {
  yield takeEvery(actions.showAddFieldGroupModal.type, showAddFieldGroupModal);
};

const watchShowEditFieldGroupModal = function*() {
  yield takeEvery(
    actions.showEditFieldGroupModal.type,
    showEditFieldGroupModal
  );
};

const watchShowDeleteFieldGroupModal = function*() {
  yield takeEvery(
    actions.showDeleteFieldGroupModal.type,
    showDeleteFieldGroupModal
  );
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchShowAddFieldModal),
    fork(watchShowEditFieldModal),
    fork(watchShowDeleteFieldModal),
    //
    fork(watchShowAddFieldGroupModal),
    fork(watchShowEditFieldGroupModal),
    fork(watchShowDeleteFieldGroupModal)
  ]);
};

export default rootSaga;
