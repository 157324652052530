import * as R from "ramda";
import { formatAmount } from "redux/modules/event/selectors";

export const portal = R.path(["portal", "portal"]);

export const isFetching = state => state.portal.fetching;
export const getBackgroundImageUrl = R.compose(
  R.prop("background_image_url"),
  portal
);
export const forms = R.compose(R.propOr([], "forms"), portal);

export const eventDetails = R.pathOr({}, ["portal", "portal", "event"]);

export const getEventCurrency = R.path([
  "portal",
  "portal",
  "event",
  "currency"
]);

export const getEventColor = R.path([
  "portal",
  "portal",
  "event",
  "highlight_color"
]);

export const isEventFeatureEnabled = state => feature => {
  return R.pathOr(
    [],
    ["portal", "portal", "event", "enabled_features"]
  )(state).includes(feature);
};

export const eventId = R.compose(R.path(["event", "id"]), portal);
export const eventUUID = R.compose(R.path(["event", "uuid"]), portal);
export const messages = R.compose(R.propOr([], "messages"), portal);
export const accounts = R.compose(R.propOr([], "accounts"), portal);
export const error = state => state.portal.error;

export const formatAmountForEvent = state => amount => {
  const currency = R.path(["portal", "portal", "event", "currency"])(state);
  return formatAmount(amount, currency);
};
