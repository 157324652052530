import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Header = ({ children, types }) => (
  <div
    styleName="container"
    title={typeof children === "string" ? children : ""}
  >
    {types.length
      ? types.reduce(
          (result, { TypeComponent, meta }) => (
            <TypeComponent {...meta}>{result}</TypeComponent>
          ),
          children
        )
      : children}
  </div>
);

Header.defaultProps = {
  types: []
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
  types: PropTypes.arrayOf(PropTypes.object)
};

export default CSSModules(Header, css);
