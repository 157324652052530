export default {
  fontFamily: "proxima-nova, arial, sans-serif",
  purple: {
    headerBarBackgroundColor: "#552193",
    headerBarBackBackgroundColor: "#552193",
    primary1Color: "#813CD2",
    primary2Color: "#9E69DC",
    primary3Color: "#6D33B2",
    primary4Color: "#602C9F",
    primary5Color: "#AD7AE8",
    accent1Color: "#D0BEE4",
    accent2Color: "#DFD3EE",
    accent3Color: "#F4EDFC",
    accent4Color: "#A877E1",
    accent5Color: "#A88DC7",

    tableTabOff: "#6D32B1",
    tableTabOn: "#9B5DE2",
    tableBackArrow: "#C19FE8",
    tableOptionsToolbar: "#9B5DE2",
    tableOptionsButton: "#AC72EC",
    tableOptionsButtonHover: "#C492FB",
    tableOptionsButtonIcon: "#EFE3FD",
    tableToolbarVerticalSeparator: "#9063C3",

    inventoryListToggleBackground: "#834FC0",
    inventoryListToggleSwitch: "#9C69D6",

    tabIconOff: "#BCA0DB",
    tabIconOn: "#C099EC"

    // primary2Color: Colors.deepPurple400,
    // primary3Color: Colors.lightBlack,
    // accent1Color: Colors.deepPurple400,
    // accent2Color: Colors.grey100,
    // accent3Color: Colors.grey500,
    // textColor: Colors.lightBlack,
    // alternateTextColor: Colors.white,
    // canvasColor: Colors.white,
    // borderColor: Colors.grey300,
    // disabledColor: ColorManipulator.fade(Colors.darkBlack, 0.3)
  }
};
