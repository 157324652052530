import * as R from "ramda";

import { DEFAULT_APPROVAL_FIELD_APPROVE_REJECT as reviewV3 } from "components/Event/FormsV3/constants";
import { EMPTY_CELL, BUTTONS } from "ui-kit/Datagrid/constants";

import { getters } from "ui-kit/Datagrid";
import { NAMESPACE } from "ui-kit/Datagrid/constants";
import { getRawCellValue, reverseCellId } from "ui-kit/Datagrid/utils";

import { userId } from "redux/modules/user/selectors";

import { makeInstanceSelector } from "redux-mvc";

import { getters as ValidatorSelectors } from "ui-kit/Validator/model";

const getKey = (state, props) => props.instanceId;

const getColumIds = makeInstanceSelector(
  getters.meta,
  R.compose(
    R.map(R.prop("id")),
    R.values,
    R.propOr([], "columns")
  )
)(getKey);

const compare = {
  string: (a, b) => b.toLowerCase().includes(a.toLowerCase()),
  number: (a, b) => R.equals(a, R.toString(b)),
  array: (a, b) => b.map(x => x.toLowerCase()).includes(a.toLowerCase())
};

const getType = value => {
  if (Array.isArray(value)) {
    return "array";
  }

  return typeof value;
};

const rowIncludes = ({ rows, searchTerm, columnIds }) => rowId =>
  R.any(colId => {
    const row = R.propOr({}, rowId, rows);
    const cell = R.propOr(EMPTY_CELL, colId, row);

    if (cell === EMPTY_CELL) {
      return false;
    }

    const value = getRawCellValue({ value: cell, row });

    const comparator = R.propOr(R.equals, getType(value), compare);

    return comparator(searchTerm, value);
  }, columnIds);

const rowsIndex = makeInstanceSelector(
  getters.rows,
  getters.rowsIndex,
  getters.searchTerm,
  getColumIds,
  getters.meta,
  (rows, index, searchTerm, columnIds, meta) =>
    R.isEmpty(searchTerm)
      ? index
      : R.filter(rowIncludes({ rows, searchTerm, columnIds, meta }), index)
)(getKey);

export const getIsMultipleSelection = R.compose(
  R.lt(1),
  R.length,
  getters.selectedRows
);

export const getIsValid = makeInstanceSelector(
  ValidatorSelectors.errors,
  R.compose(
    R.all(R.prop("valid")),
    R.values
  )
)(getKey);

const getErrorsByRow = makeInstanceSelector(
  ValidatorSelectors.errors,
  R.compose(
    R.reduce((errors, [key, value]) => {
      const { columnId, rowId } = reverseCellId(key);

      return {
        ...errors,
        [rowId]: {
          ...(errors[rowId] || {}),
          [columnId]: value
        }
      };
    }, {}),
    Object.entries
  )
)(getKey);

const EMPTY_HANDLERS = {};
export const getRowGetter = makeInstanceSelector(
  getters.rows,
  rowsIndex,
  getErrorsByRow,
  (_, props) => R.propOr(EMPTY_HANDLERS, "handlers", props),
  (rows, index, errors, handlers) => {
    if (R.isEmpty(errors)) {
      return i => ({
        ...rows[index[i]],
        handlers // check if handlers are needed here
      });
    }
    return i => {
      const row = rows[index[i]] || {};

      if (R.has(row.id, errors)) {
        const rowErrors = R.prop(row.id, errors);

        return R.reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: {
              ...row[key],
              error: value
            }
          }),
          row,
          Object.entries(rowErrors)
        );
      }

      return { ...row, handlers };
    };
  }
)(getKey);

export const getRowsCount = R.compose(
  R.length,
  rowsIndex
);

export const getSelectedRowIds = makeInstanceSelector(
  getters.selectedRows,
  rowsIndex,
  (selectedRows, rowsIndex) => R.map(sri => rowsIndex[sri], selectedRows)
)(getKey);

export const getSelectedRows = makeInstanceSelector(
  getSelectedRowIds,
  getters.rows,
  R.compose(
    R.values,
    R.pick
  )
)(getKey);

export const getNewRows = makeInstanceSelector(
  getters.newIds,
  getters.rows,
  R.compose(
    R.values,
    R.pick
  )
)(getKey);

export const isTemporaryRow = (state, props) =>
  R.any(R.equals(props.rowId), R.values(getters.tempIds(state, props)));

export const getRow = (state, props) =>
  R.prop(props.rowId, getters.rows(state, props));

export const getPendingRowIds = makeInstanceSelector(
  getSelectedRowIds,
  getters.rows,
  userId,
  (selectedRows, rows, userId) => {
    const forReview = R.isEmpty(selectedRows)
      ? rows
      : R.pick(selectedRows, rows);

    return R.map(
      R.prop("id"),
      R.filter(
        R.both(
          R.compose(
            R.equals("pending"),
            R.pathOr("", [reviewV3.id, "value", "status"])
          ),
          R.compose(
            R.any(R.equals(userId)),
            R.map(R.prop("user_id")),
            R.pathOr("", [reviewV3.id, "value", "current_approvers"])
          )
        ),
        R.values(forReview)
      )
    );
  }
)(getKey);

export const getSelectionActive = R.compose(
  R.not,
  R.equals(0),
  R.length,
  getters.selectedRows
);

export const getEnabledButtons = makeInstanceSelector(
  R.compose(
    R.length,
    getters.selectedRows
  ),
  R.compose(
    R.length,
    getPendingRowIds
  ),
  (_, props) => R.prop("enabledButtons", props),
  (rows, pending, enabledButtons) => {
    const buttons = [BUTTONS.ADD_ROW.id, BUTTONS.IMPORT.id];

    if (pending > 0) {
      buttons.splice(0, 0, BUTTONS.REVIEW.id);
    }

    if (rows > 0) {
      buttons.push(BUTTONS.DUPLICATE_ROW.id);
      buttons.push(BUTTONS.REMOVE_ROW.id);
    }

    return R.filter(
      R.compose(
        //eslint-disable-next-line no-underscore-dangle
        R.contains(R.__, buttons),
        R.prop("id")
      ),
      enabledButtons
    );
  }
)(getKey);

export const getLoading = R.compose(
  R.any(R.identity),
  R.map(R.prop("loading")),
  R.filter(R.has("loading")),
  R.values,
  R.propOr({}, NAMESPACE)
);
