import {
  takeEvery,
  put,
  call,
  select,
  all,
  fork,
  take
} from "redux-saga/effects";

import Helpers from "utils/Global/Helpers";

import { getCredentials } from "redux/modules/user/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { actions as TableActions } from "ui-kit/Table/model";
import * as R from "ramda";
import { renderDropZone } from "utils/General/sagas";
import { registerError } from "redux/modules/errors/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { actions as contentModalActions } from "EventLight/Content/AddContentModal/model";

import {
  actions as SearchBarActions,
  getters as SearchBarGetters
} from "ui-kit/SearchBar";

import {
  getters as PaginationGetters,
  actions as PaginationActions
} from "ui-kit/PaginationBar";

import { actions, getters } from "./model";

import { ROW_ACTIONS, MEDIA_CONTENT_INSTANCE_ID } from "./constants";

import api from "./api";

const getParams = function*(meta) {
  const credentials = yield select(getCredentials);
  const eventDetails = yield select(getEventDetails);
  const accept = yield select(getters.accept);
  const scopes = R.map(
    R.compose(
      R.head,
      R.split("/")
    )
  )(accept);
  const page = yield select(PaginationGetters.page, {
    instanceId: MEDIA_CONTENT_INSTANCE_ID
  });
  const pageSize = yield select(PaginationGetters.pageSize, {
    instanceId: MEDIA_CONTENT_INSTANCE_ID
  });
  const search = yield select(SearchBarGetters.searchTerm, {
    instanceId: meta.instanceId
  });

  return {
    credentials,
    eventDetails,
    filters: {
      page: page + 1,
      pageSize,
      search,
      "scopes[]": scopes
    }
  };
};

const search = function*({ type, meta }) {
  try {
    const isModalOpened = yield select(getters.showModal, meta);
    if (isModalOpened) {
      yield put(actions.search(null, { meta }));

      const { credentials, eventDetails, filters } = yield call(
        getParams,
        meta
      );

      const result = yield call(api.getContent, {
        credentials,
        eventId: eventDetails.id,
        filters
      });

      yield all([
        put(
          actions.setInitialData(
            {
              pagination: R.path(["payload", "pagination"], result),
              content: R.path(["payload", "rows"], result)
            },
            { meta }
          )
        ),
        put(
          TableActions.setData(
            {
              canEditCells: false,
              columnWidths: {
                title: 279,
                type: 109,
                size: 123
              },
              columns: [
                {
                  id: "title",
                  type: "text",
                  name: "Title"
                },
                {
                  id: "type",
                  type: "text",
                  name: "Type"
                },
                {
                  id: "size",
                  type: "text",
                  name: "Size"
                },
                {
                  id: "actions",
                  type: "general-actions",
                  name: "Actions"
                }
              ],
              rows: R.compose(
                R.map(
                  R.assocPath(
                    ["actions", "value"],
                    [
                      { id: "edit", name: "Edit" },
                      { id: "view", name: "View" },
                      { id: "delete", name: "Delete" },
                      { id: "select", name: "Select" }
                    ]
                  )
                ),
                R.prop("rows")
              )(result.payload)
            },
            {
              meta
            }
          )
        )
      ]);

      const pagination = R.pathOr({}, ["payload", "pagination"], result);
      if (
        [
          PaginationActions.setPageSize.type,
          SearchBarActions.clearSearch.type,
          SearchBarActions.setSearchTerm.type
        ].includes(type)
      ) {
        yield put(
          PaginationActions.setPagination(
            {
              ...pagination,
              page: 0
            },
            {
              meta: { instanceId: MEDIA_CONTENT_INSTANCE_ID }
            }
          )
        );
      } else {
        yield put(
          PaginationActions.setPagination(
            {
              ...pagination,
              page: pagination.page - 1
            },
            {
              meta: { instanceId: MEDIA_CONTENT_INSTANCE_ID }
            }
          )
        );
      }
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting content"
        }
      ])
    );
  } finally {
    yield put(actions.searchResponse(null, { meta }));
  }
};

// eslint-disable-next-line consistent-return
const save = function*({ file, meta }) {
  try {
    const { credentials, eventDetails } = yield call(getParams, meta);

    /*
    container: "content.lennd.com"
    filename: "512.png"
    handle: "NunZb6jSQpWVvIPknq3h"
    key: "content/Mq1uhrwKTWqi4bjzVQ4L_512.png"
    mimetype: "image/png"
    originalFile: {name: "512.png", type: "image/png", size: 6943}
    originalPath: "512.png"
    size: 6943
    source: "local_file_system"
    status: "Stored"
    uploadId: "dS0r7bNgE11Q460H"
    url: "https://cdn.filestackcontent.com/NunZb6jSQpWVvIPknq3h"
    */

    let title = null;
    let mimetype = null;
    let size = null;
    let key = null;
    let filename = null;
    let isExternal = false;
    let url = null;
    let payload = null;

    if (file) {
      url = `https://content-cdn.lennd.com/${file[0].key}`;
      title = file[0].filename;
      key = file[0].key;
      filename = file[0].filename;
      mimetype = file[0].mimetype;
      size = file[0].size;
      payload = file[0];
    } else {
      url = yield select(getters.url, {
        instanceId: meta.instanceId
      });
      title = url;
      isExternal = true;

      if (!Helpers.isValidHTTPSURL(url)) {
        yield put(
          registerError([
            {
              system: "Please enter a valid HTTPS URL",
              user: "Please enter a valid HTTPS URL"
            }
          ])
        );
        return false;
      }
    }

    const toSave = {
      title,
      filename,
      size,
      key,
      mimetype,
      isExternal,
      url,
      payload,
      visible: true
    };

    yield call(api.addContent, {
      credentials,
      eventId: eventDetails.id,
      data: toSave
    });

    yield all([
      put(showSnackbar({ message: "Content Added" })),
      call(search, {
        meta,
        payload: { refresh: true }
      })
      // put(actions.setVideoURL("", { meta }))
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while saving content"
        }
      ])
    );
  }
};

const deleteRecord = function*({ meta, payload: { row } }) {
  try {
    const { credentials, eventDetails } = yield call(getParams, meta);

    yield call(api.deleteContent, {
      credentials,
      eventId: eventDetails.id,
      data: {
        contentIds: [row.id]
      }
    });

    yield put(showSnackbar({ message: "Content Deleted" }));
    yield call(search, { meta, payload: { refresh: true } });
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while deleting content"
        }
      ])
    );
  }
};

const editRecord = function*({ payload: { row } }) {
  yield put(contentModalActions.showContentModal({ id: row.data.id }));
};

const onEditRecordDone = function*() {
  yield call(search, {
    meta: { instanceId: MEDIA_CONTENT_INSTANCE_ID },
    payload: { refresh: true }
  });
};

const init = function*(opts) {
  yield call(search, opts);

  for (;;) {
    const file = yield call(renderDropZone, {
      multiple: false,
      fromSources: ["local_file_system"],
      container: "dropzone-container",
      accept: opts.payload,
      storeTo: {
        path: "content/"
      }
    });

    yield call(save, { file, meta: opts.meta });

    document.getElementById("dropzone-container").innerHTML = "";
  }
};

export const openRecord = function*({
  payload: {
    row: {
      data: { url }
    }
  }
}) {
  yield call([window, window.open], url, "_blank");
};

export const selectRecord = function*({
  payload: {
    row: { data }
  },
  meta
}) {
  yield put(actions.selectRecord(data, { meta }));
};

const handleHideModal = function*() {
  yield put(
    PaginationActions.setPagination(
      {
        pageSize: 15,
        page: 0
      },
      {
        meta: { instanceId: MEDIA_CONTENT_INSTANCE_ID }
      }
    )
  );
};

const watchSearch = function*() {
  yield takeEvery(
    [
      SearchBarActions.clearSearch.type,
      SearchBarActions.setSearchTerm.type,
      action =>
        [
          PaginationActions.setPage.type,
          PaginationActions.setPageSize.type
        ].includes(action.type) &&
        [MEDIA_CONTENT_INSTANCE_ID].includes(
          R.path(["meta", "instanceId"], action)
        )
    ],
    search
  );
};

const watchTableActions = function*() {
  for (;;) {
    const action = yield take(TableActions.executeAction.type);
    const delegate = R.prop(action.payload.actionId, {
      [ROW_ACTIONS.OPEN_RECORD]: openRecord,
      [ROW_ACTIONS.SELECT_RECORD]: selectRecord,
      [ROW_ACTIONS.DELETE_RECORD]: deleteRecord,
      [ROW_ACTIONS.EDIT_RECORD]: editRecord
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchSave = function*() {
  yield takeEvery(actions.save.type, save);
};

const watchHideModal = function*() {
  yield takeEvery(actions.hideModal.type, handleHideModal);
};

const watchEditRecordDone = function*() {
  yield takeEvery(contentModalActions.closeContentModal.type, onEditRecordDone);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchSearch),
    fork(watchTableActions),
    fork(watchSave),
    fork(watchHideModal),
    fork(watchEditRecordDone)
  ]);
};

export default rootSaga;
