import {
  BULK_SET_SELECTED_ROWS,
  CLEAR_SEARCH,
  DESELECT_ALL_ROWS,
  SEARCH,
  TABLE_LAYOUT_OPTIONS,
  TABLE_LAYOUT_STYLE,
  VIEW
} from "./constants";

export function updateView({ moduleId, viewId }) {
  return {
    type: VIEW,
    moduleId,
    viewId
  };
}

export function updateSearch({ moduleId, searchTerm }) {
  return {
    type: SEARCH,
    moduleId,
    searchTerm
  };
}

export function clearSearch(moduleId) {
  return {
    type: CLEAR_SEARCH,
    moduleId
  };
}

export function deselectAllRows(moduleId) {
  return {
    type: DESELECT_ALL_ROWS,
    selectedRows: [],
    moduleId
  };
}

export function bulkSelectRows(moduleId, selectedRows) {
  return {
    type: BULK_SET_SELECTED_ROWS,
    selectedRows,
    moduleId
  };
}

export function updateTableLayout({ moduleId, activeStyle, options }) {
  if (activeStyle) {
    return {
      type: TABLE_LAYOUT_STYLE,
      activeStyle,
      moduleId
    };
  }
  return {
    type: TABLE_LAYOUT_OPTIONS,
    moduleId,
    options
  };
}
