import React from "react";

import { getValue } from "../../utils";

import ScheduleBadge from "ui-kit/ScheduleBadge";

const Schedule = props => {
  const name = getValue(props, "name");
  const color = getValue(props, "color");

  return <ScheduleBadge color={color} text={name} />;
};

export default Schedule;
