import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const AccountTypeIcon = ({
  name,
  icon,
  backgroundColor,
  color,
  size,
  fontSize
}) => (
  <div
    styleName="container"
    style={{
      height: size,
      width: size,
      backgroundColor,
      color,
      fontSize: size
    }}
    title={name}
  >
    {icon ? (
      <i className="material-icons">{icon}</i>
    ) : (
      <div
        styleName="initials"
        style={{
          fontSize: fontSize || Math.floor(size * 0.6)
        }}
      >
        {name ? name.replace(/[^a-z]/gi, "")[0] : ""}
      </div>
    )}
  </div>
);

AccountTypeIcon.defaultProps = {
  size: 36,
  color: "#686868",
  backgroundColor: "#E3E3E3"
};

AccountTypeIcon.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.number,
  fontSize: PropTypes.number
};

export default CSSModules(AccountTypeIcon, css);
