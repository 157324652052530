import { createModule } from "redux-mvc";

import model from "./model";

import formModule from "ui-kit/Form";
import sliderSidebar from "ui-kit/SliderSidebar";
import actionsPopover from "ui-kit/ActionsPopover";

import sagasModule from "./sagas";

const module = createModule(model);

module.setRootSaga(sagasModule);

module.plugModule(formModule);
module.plugModule(sliderSidebar);
module.plugModule(actionsPopover);

export default module;
