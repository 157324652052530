import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";
import {
  locations,
  locationAreas
} from "@lennd/value-types/src/constants/standard-modules";
import getLookupValue from "utils/value-types/get-value/lookup";
import { pathOr } from "ramda";
import { LOCATIONS, LOCATION_AREAS } from "utils/standard-module-field-ids";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { hideModal, showModal } from "redux/modules/modal/actions";
import { getModule } from "redux/modules/modules/module/actions";
import {
  getRecords,
  deleteRecord
} from "redux/modules/modules/records/actions";

import { eventDetails } from "redux/modules/event/selectors";
import { records } from "redux/modules/modules/records/selectors";
import { fields } from "redux/modules/modules/module/selectors";

function mapStateToProps(state) {
  // buld location map
  const locationRecordMap = records(state, locations.id).reduce(
    (map, record) => {
      map[record.id] = {
        id: record.id,
        name: pathOr(null, ["values", LOCATIONS.NAME, "value"], record),
        items: []
      };
      return map;
    },
    {}
  );

  // add items to location
  records(state, locationAreas.id).forEach(record => {
    const locationId = pathOr(
      null,
      ["records", 0],
      getLookupValue(pathOr(null, ["values", LOCATION_AREAS.LOCATION], record))
    );
    if (locationId && locationRecordMap[locationId]) {
      locationRecordMap[locationId].items.push({
        id: record.id,
        name: pathOr(null, ["values", LOCATION_AREAS.NAME, "value"], record)
      });
    }
  });

  const locationsToPass = Object.keys(locationRecordMap).reduce((a, b) => {
    a.push(locationRecordMap[b]);
    return a;
  }, []);

  return {
    eventDetails: eventDetails(state),
    items: locationsToPass.reduce((a, b) => [...a, ...b.items], []),
    locations: locationsToPass
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getModule,
      getRecords,
      deleteRecord
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controller);
