import React from "react";

import { createContext } from "redux-mvc";

import * as R from "ramda";

import module, { actions } from "Items/BulkQuestionItemsModal";

import Layout from "./Layout";

import EditQuestionSetQuestionsModal from "Items/EditQuestionSetQuestionsModal/View";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";

import rootSaga from "Items/BulkQuestionItemsModal/sagas";

module.setRootSaga(rootSaga);

const decorate = createContext({
  module: module,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(
        actions.init({
          questionIds: this.props.questionIds,
          questionSetIds: this.props.questionSetIds
        })
      );
    },
    componentWillUnmount() {
      if (this.props.onDone) {
        this.props.onDone();
      }
    }
  },
  handlers: {
    showEditQuestionSetQuestionsModal(setId) {
      this.props.showModal({
        content: (
          <EditQuestionSetQuestionsModal
            setId={setId}
            hideModal={this.props.hideModal}
            onDone={() => this.store.dispatch(actions.refetchSets())}
          />
        ),
        wrapper: ModalWrapper
      });
    }
  },
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: ["user", "event", "modal", "organization"]
  }
});

export default decorate(Layout);
