export default ({ price, currency }) => {
  if (!price) {
    return null;
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency || "USD",
    minimumFractionDigits: 2
  });

  return formatter.format(price / 100);
};
