export default {
  mustBeChildField: false,
  canBeChildField: true,
  canBeParentField: false,
  canBeChildOfFieldTypes: [
    "contact",
    "inventory",
    "schedule-activity",
    "reference"
  ]
};
