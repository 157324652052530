import { all, takeEvery, fork, put, select, call } from "redux-saga/effects";
import { actions, getters } from "./model";
import { eventId as getEventId } from "redux/modules/portal/selectors";
import { apiCall } from "App/Data/sagas";
import { userId as getUserId } from "redux/modules/user/selectors";

const refreshData = function*() {
  const eventId = yield select(getters.eventId);
  const accountId = yield select(getters.accountId);
  const recordId = yield select(getters.recordId);
  const userId = yield select(getUserId);

  const { payload } = yield call(apiCall, {
    method: "get",
    url: `/portal/person/${eventId}/${accountId}/${recordId}`,
    qs: { userId }
  });

  yield put(actions.setRefreshedData({ payload }));

  return true;
};

const init = function*({
  payload: { moduleId, eventId, accountId, recordId }
}) {
  const eventId2 = yield select(getEventId);
  const userId = yield select(getUserId);

  yield put(actions.setIsLoading(true));

  try {
    const [module, { payload }] = yield all([
      call(apiCall, {
        method: "get",
        url: `/modules/${moduleId}`,
        qs: { eventId: eventId ?? eventId2 }
      }),
      call(apiCall, {
        method: "get",
        url: `/portal/person/${eventId}/${accountId}/${recordId}`,
        qs: { userId }
      })
    ]);

    yield put(
      actions.setInitialData({
        payload,
        module,
        accountId,
        recordId,
        userId,
        eventId: eventId ?? eventId2
      })
    );
    yield put(actions.setIsLoading(false));
  } catch (e) {
    return e;
  }

  return true;
};

const addValue = function*({ payload: data }) {
  try {
    yield call(apiCall, {
      method: "post",
      url: `/modules/${data.moduleId}/values${""}`,
      data
    });

    yield put(actions.addValueOnStore(data));
  } catch (e) {
    return e;
  }

  return true;
};

const updateRelationship = function*({ payload: data }) {
  try {
    yield call(apiCall, {
      method: "put",
      url: `/account/${data.accountId}/contact-relationships/${data.contactId}`,
      data
    });

    yield put(actions.updateRelationshipOnStore(data));
  } catch (e) {
    return e;
  }

  return true;
};

const removeLineItem = function*({ payload: lineItemId }) {
  const userId = yield select(getUserId);

  try {
    yield call(apiCall, {
      method: "delete",
      url: "/orders/line-items",
      data: { userId, lineItemId }
    });
  } catch (e) {
    return e;
  }

  yield call(refreshData);

  return true;
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchAddValue = function*() {
  yield takeEvery(actions.addValue.type, addValue);
};

const watchUpdateRelationship = function*() {
  yield takeEvery(actions.updateRelationship.type, updateRelationship);
};

const watchRemoveLineItem = function*() {
  yield takeEvery(actions.removeLineItem.type, removeLineItem);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchAddValue),
    fork(watchRemoveLineItem),
    fork(watchUpdateRelationship)
  ]);
};

export default rootSaga;
