import React, { Component } from "react";
import * as R from "ramda";
import View from "./View";

const getFieldGroupsFromSettings = settings => {
  return R.compose(
    R.map(key => ({
      id: key,
      name: settings[key].title,
      fields: R.map(field => ({
        ...field
      }))(settings[key].form)
    })),
    R.keys
  )(settings);
};

const formatPayloadToSave = (settings, config) => {
  const payload = {};

  Object.keys(settings).forEach(key => {
    payload[key] = {};

    settings[key].form.map(field => {
      if (field.type === "map") {
        // grab the first queue id to set it if we dont have any values
        const defaultQueueId = R.compose(
          R.prop("queue_id"),
          R.head
        )(field.value.selectValue.values);

        // grabs existing values if they exist from provider config
        const selectedValues = R.pathOr({}, ["settings", "fulfillment", 0])(
          config
        );

        // build value payload so we can easily send as-is when done
        payload[key][field.name] = [{}];
        const toIterateOn = field.value.keys.values || field.value.keys;
        toIterateOn.forEach(valueKey => {
          payload[key][field.name][0][valueKey.value] =
            selectedValues[valueKey.value] || defaultQueueId;
        });
      }
    });
  });

  return payload;
};

class Controller extends Component {
  state = { loading: true, saving: false, settings: [], payload: {} };

  async componentDidMount() {
    const result = await this.props.getProviderConfigurationSettings(
      this.props.provider,
      {
        eventId: this.props.eventDetails.id
      },
      1
    );

    this.setState({
      loading: false,
      settings: getFieldGroupsFromSettings(result),
      payload: formatPayloadToSave(result, this.props.providerConfiguration)
    });
  }

  contactSupport = () => {
    window.Intercom(
      "showNewMessage",
      `Hello! I would like to turn on the ${this.props.name} integration for my event. Thank you for your help.`
    );
    this.props.hideModal();
  };

  onSave = async () => {
    this.setState({
      error: null,
      saving: true
    });

    const result = await this.props.updateProviderConfigurationSettings(
      this.props.provider,
      {
        eventId: this.props.eventDetails.id,
        settings: this.state.payload
      }
    );

    // handle error
    const hasError = result ? Boolean(result.errorCode) : false;
    if (hasError) {
      this.setState({
        error: {
          code: result ? result.errorCode : null,
          details: result
            ? result.errorDetail
            : "We encountered some trouble connecting your integration."
        }
      });
    } else {
      this.props.onDone();
      this.props.hideModal();
    }

    this.setState({
      saving: false
    });
  };

  render() {
    const { settings, payload, loading, saving } = this.state;
    const { name, hideModal } = this.props;

    const fieldsWithHandlers = R.map(group => ({
      ...group,
      fields: R.map(field => ({
        ...field,
        values: payload[group.id][field.name][0],
        onChange: (option, value) => {
          this.setState(state => {
            state.payload[group.id][field.name][0][option] = value;
            return state;
          });
        }
      }))(group.fields)
    }))(settings);

    return (
      <View
        {...{
          loading,
          saving,
          hideModal,
          name,
          fields: fieldsWithHandlers,
          contactSupport: this.contactSupport,
          onSave: this.onSave
        }}
      />
    );
  }
}

export default Controller;
