import * as R from "ramda";
import { createSelector } from "reselect";

import { getters } from "./model";

export const getZones = createSelector(
  getters.zones,
  R.compose(
    R.map(group => ({
      ...group,
      zones: R.sortBy(R.prop("order"), group.zones)
    })),
    R.sortBy(R.prop("order"))
  )
);

export const getZoneGroups = createSelector(
  getZones,
  getters.typesFilter,
  (zoneGroups, typesFilter) => {
    return R.reduce(
      (acc, group) => {
        const sortedZones = R.sortBy(R.prop("order"), group.zones);
        const zones = typesFilter.length
          ? R.filter(
              zone => R.contains(R.toLower(typesFilter), R.toLower(zone.name)),
              sortedZones
            )
          : sortedZones;
        if (!typesFilter.length || R.length(zones) > 0) {
          return [
            ...acc,
            {
              ...group,
              zones
            }
          ];
        }
        return acc;
      },
      [],
      R.sortBy(R.prop("order"))(zoneGroups)
    );
  }
);
