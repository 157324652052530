import React from "react";
import { Div } from "components/Base";
import { connect } from "react-redux";
import { getLeftSidebarSections, currentSection } from "../../selectors";
import Section from "./Section";
import Tabs from "ui-kit/Tabs/View";
import { getters as TabsGetters } from "ui-kit/Tabs";
import * as R from "ramda";

const decorator = connect((state, props) => ({
  sectionsTabs: getLeftSidebarSections(state, props),
  selectedTab: TabsGetters.selectedTab(state, props),
  currentSection: currentSection(state, props)
}));

export const Sections = ({ sectionsTabs, selectedTab, currentSection }) => (
  <Div width={1}>
    <Div style={{ marginBottom: 20 }}>
      {R.length(sectionsTabs) === 1 ? (
        <Div color="neutral9" fw={4} style={{ fontSize: 30 }}>
          {sectionsTabs[0].title}
        </Div>
      ) : (
        <Tabs
          tabs={sectionsTabs}
          tabStyle={{
            activeColor: "#0A6EF4",
            color: "#222222",
            barColor: "#0A6EF4",
            fs: 4
          }}
        />
      )}
    </Div>
    <Div>
      <Section key={selectedTab} section={currentSection} />
    </Div>
  </Div>
);

export default decorator(Sections);
