import { connect } from "react-redux";
import factory from "./search";

import * as EventSelectors from "redux/modules/event/selectors";
import { references } from "redux/modules/entityReferences/selectors";

function mapStateToProps(state) {
  return {
    eventDetails: EventSelectors.eventDetails(state),
    references: references(state)
  };
}

export default Child => connect(mapStateToProps)(factory(Child));
