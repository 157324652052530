import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { noop } from "utils/General";

import { DownIcon, MediumShadedButton } from "components/Base";
import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";

import { getActiveFilterName } from "ui-kit/SelectFilter/selectors";
import { actions } from "ui-kit/SelectFilter";
import PopoverMenu from "ui-kit/PopoverMenu/View";

const decorate = R.compose(
  connect(
    (state, props) => ({
      activeFilterName: getActiveFilterName(state, props)
    }),
    bindInstance({
      setActiveFilter: actions.setActiveFilterId
    })
  ),
  WithThemeConsumer({ variant: VARIANTS.SURFACE })
);

const SelectFilter = ({
  activeFilterName = "",
  setActiveFilter = noop,
  filters = [],
  Th = defaultTh,
  ...styleProps
}) => (
  <PopoverMenu actions={filters} onClickItem={setActiveFilter}>
    <MediumShadedButton
      RightIcon={DownIcon}
      {...Th(["bg", "color"], styleProps)}
    >
      {activeFilterName}
    </MediumShadedButton>
  </PopoverMenu>
);

export default decorate(SelectFilter);
