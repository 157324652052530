import React from "react";
import * as R from "ramda";

import { connect } from "react-redux";

import { DRAGGABLE_IDS } from "../constants";
import { getPeopleFields } from "../selectors";
import { actions } from "../model";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import FieldsList from "./FieldsList";

const decorate = R.compose(
  connect(
    state => ({
      fields: getPeopleFields(state)
    }),
    {
      onAddField: actions.showAddFieldModal,
      onEditField: actions.showEditFieldModal,
      onDeleteField: actions.showDeleteFieldModal,
      //
      onAddFieldGroup: actions.showAddFieldGroupModal,
      //
      onSearch: actions.setPeopleSearch
    }
  ),
  CSSModules(css)
);

const GroupFields = ({
  fields,
  onSearch,
  onReorder,
  onAddField,
  onEditField,
  onDeleteField,
  onAddFieldGroup
}) => {
  const fieldsToPass = fields.map(fg => ({
    ...fg,
    fields: fg.fields.map(f => ({
      ...f,
      onClick: () => onEditField({ moduleId: f.module_id, fieldId: f.id }),
      onDelete: () => onDeleteField({ moduleId: f.module_id, fieldId: f.id })
    }))
  }));

  return (
    <FieldsList
      fieldsId={DRAGGABLE_IDS.GROUP}
      title="People Fields"
      icon="person"
      onSearch={onSearch}
      onReorder={onReorder}
      onAddFieldGroup={() =>
        onAddFieldGroup({
          moduleId: STANDARD_MODULE_IDS.contacts.id
        })
      }
      onAddField={() =>
        onAddField({
          moduleId: STANDARD_MODULE_IDS.contacts.id
        })
      }
      fields={fieldsToPass}
    />
  );
};

export default decorate(GroupFields);
