/*
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Requests from "./Requests";

import {
  deleteSubmission,
  createSubmission,
  setSubmissionLocked
} from "redux/modules/formsV2/submission/actions";
import {
  addAccountForm,
  deleteAccountForm
} from "redux/modules/accounts/forms/actions";
import {
  updateAccountFormAssignment,
  removeAccountFormAssignment
} from "redux/modules/formsV2/form/assignments/account/actions";
import * as snackbarActions from "redux/modules/snackbar/actions";
import * as modalActions from "redux/modules/modal/actions";
import { getCredentials } from "redux/modules/accounts/credentials/actions";
import { addRecord } from "redux/modules/modules/records/actions";

import * as UserSelectors from "redux/modules/user/selectors";
import * as EventSelectors from "redux/modules/event/selectors";

function mapStateToProps(state) {
  return {
    user: UserSelectors.user(state),
    eventDetails: EventSelectors.eventDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addRecord,
      getCredentials,
      addAccountForm,
      createSubmission,
      deleteAccountForm,
      deleteSubmission,
      setSubmissionLocked,
      updateAccountFormAssignment,
      removeAccountFormAssignment,
      ...modalActions,
      ...snackbarActions
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Requests);
*/

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actions } from "../model";
import EditCloseDateModal from "./EditCloseDateModal";
import Requests from "./Requests";

const decorate = connect(
  null,
  {
    init: actions.init
  }
);

const Layout = ({ init, mode, moduleId, recordId }) => {
  useEffect(() => {
    init({ mode, moduleId, recordId });
  }, []);

  return (
    <>
      <EditCloseDateModal />
      <Requests />
    </>
  );
};

export default decorate(Layout);
