import * as R from "ramda";
import React from "react";

import { connect } from "react-redux";
import { actions, getters } from "../model";

import {
  Div,
  DownloadIcon,
  BigFilledButton,
  DownFilledIcon,
  MenuItem
} from "components/Base";
import CanUserDo from "components/Global/Security/CanUserDo";
import MenuPopover from "ui-kit/MenuPopover";
import { withProps } from "utils/General";

import CSSModules from "react-css-modules";
import css from "ui-kit/ViewPicker/View/styles.scss";

import { noop } from "utils/General";

const decorate = R.compose(
  connect(
    (state, props) => ({
      moduleId: getters.moduleId(state, props)
    }),
    { onExport: actions.exportData }
  ),
  CSSModules(css)
);

const OptionsButton = ({
  onExport = noop,
  wrapperStyle = {},
  moduleId,
  ...styleProps
}) => {
  return (
    <MenuPopover
      Label={
        <BigFilledButton
          className={css.button1Light}
          hover={false}
          color="#222"
          RightIcon={withProps({
            color: "#999"
          })(DownFilledIcon)}
          {...styleProps}
        >
          Options
        </BigFilledButton>
      }
      wrapperStyle={wrapperStyle}
    >
      {({ closePopover }) => (
        <Div bra={3} bg="white" py={1}>
          <CanUserDo action={`${moduleId}_export`}>
            <MenuItem
              LeftIcon={DownloadIcon}
              onClick={() => {
                onExport("csv");
                closePopover();
              }}
            >
              Export .CSV
            </MenuItem>
            <MenuItem
              LeftIcon={DownloadIcon}
              onClick={() => {
                onExport("xlsx");
                closePopover();
              }}
            >
              Export .XLSX
            </MenuItem>
          </CanUserDo>
        </Div>
      )}
    </MenuPopover>
  );
};

export default decorate(OptionsButton);
