import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import {
  NAMESPACE,
  LOGIN_FIELDS,
  FORGOT_FIELDS
} from "Portal/IntakeForm/constants";

const iniState = {
  loading: false,
  saving: false,
  searching: false,
  sessionId: null,
  error: null,
  userExists: false,
  isVirtualUser: false,
  portalUserExists: false,
  activeIndex: 0,
  formData: {},
  termsAccepted: false,
  groupValues: {},
  contactValues: {},
  loginValues: {},
  loginFields: LOGIN_FIELDS,
  forgotValues: {},
  forgotFields: FORGOT_FIELDS,
  intakeId: "",
  selectedAccountId: null,
  selectedContactId: null,
  availableGroups: [],
  userEmail: null,
  isLoggedIn: false,
  eventUserGroups: []
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    nextPage: R.identity,
    backPage: R.identity,
    goToLoginPage: R.identity,
    goToFormPage: R.identity,
    login: R.identity,
    forgotPassword: R.identity,
    saveValues: R.identity,
    handleCallback: R.identity,
    handleLogin: R.identity,
    handleLogout: R.identity,
    checkUser: R.identity,
    setUserData: (
      _,
      { payload: { userExists, isVirtualUser, portalUserExists } }
    ) => ({
      userExists,
      isVirtualUser,
      portalUserExists
    }),
    seedFormData: (
      _,
      {
        payload: {
          userEmail,
          formPayload,
          eventUserGroups,
          isLoggedIn,

          selectedAccountId,
          selectedContactId,
          contactValues,
          groupValues
        }
      }
    ) => ({
      userEmail: userEmail ? userEmail.toLowerCase().trim() : userEmail,
      formData: formPayload,
      availableGroups: formPayload.available_accounts || [],
      eventUserGroups: eventUserGroups || [],
      isLoggedIn,
      selectedAccountId: selectedAccountId || formPayload.selected_account_id,
      selectedContactId: selectedContactId || formPayload.selected_contact_id,
      contactValues: contactValues
        ? contactValues
        : formPayload.selected_contact_values || {},
      groupValues: groupValues
        ? groupValues
        : formPayload.selected_account_values || {}
    }),
    toggleTermsAccepted: state => ({
      termsAccepted: !state.termsAccepted
    }),
    updateGroupValues: (state, { payload: { id, value } }) => ({
      groupValues: R.assoc(id, value, state.groupValues)
    }),
    updateContactValues: (state, { payload: { id, value } }) => ({
      contactValues: R.assoc(id, value, state.contactValues)
    }),
    updateLoginValues: (state, { payload: { id, value } }) => ({
      loginValues: R.assoc(id, value, state.loginValues)
    }),
    updateForgotValues: (state, { payload: { id, value } }) => ({
      forgotValues: R.assoc(id, value, state.forgotValues)
    }),
    updateSelectedAccountId: (state, { payload: selectedAccountId }) => {
      const account = selectedAccountId
        ? R.find(a => a.id === selectedAccountId)(state.eventUserGroups)
        : null;
      const selectedContact =
        account && state.userEmail
          ? R.find(c =>
              c.email ? c.email.toLowerCase().trim() === state.userEmail : false
            )(account.contacts)
          : null;

      return {
        selectedAccountId,
        selectedContactId: selectedContact ? selectedContact.id : null,
        contactValues: selectedContact ? selectedContact.values : {},
        groupValues: selectedAccountId
          ? R.compose(
              R.prop("values"),
              R.find(a => a.id === selectedAccountId)
            )(state.eventUserGroups)
          : {}
      };
    },
    updateSelectedContactId: (state, { payload: selectedContactId }) => {
      return {
        selectedContactId,
        contactValues:
          selectedContactId && state.selectedAccountId
            ? R.compose(
                R.prop("values"),
                R.find(c => c.id === selectedContactId),
                R.propOr([], "contacts"),
                R.find(a => a.id === state.selectedAccountId)
              )(state.eventUserGroups)
            : {}
      };
    }
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
