import { sortBy, get } from "lodash";
import * as R from "ramda";

export default function resolveProps(column, event = {}) {
  if (column.type === "datetime") {
    return {
      column,
      timezone:
        R.path(["settings", "timezone"], column) || R.prop("timezone", event),
      format: R.path(["settings", "format"], column)
    };
  } else if (
    column.type === "dropdown" &&
    column.settings &&
    column.settings.allowMultipleSelect
  ) {
    return {
      options: get(column, "settings.options", [])
    };
  } else if (
    column.type === "dropdown" ||
    (["inventory", "schedule-activity"].includes(column.type) &&
      ["dropdown", "list"].includes(column.settings.inventoryType))
  ) {
    return {
      options: get(column, "settings.options", [])
    };
  } else if (column.type === "radio") {
    return {
      options: get(column, "settings.options", [])
    };
  } else if (column.type === "credentials") {
    const availableCredentials = get(
      column,
      "settings.availableCredentials",
      []
    );
    const credentials = get(
      event,
      "module_settings.credentials.credentials",
      []
    ).filter(c => !availableCredentials || availableCredentials.includes(c.id));
    return {
      options: sortBy(credentials, c => get(column.settings, ["order", c.id]))
    };
  } else if (column.type === "item-block") {
    return {
      limit: R.path(["settings", "limit"])(column),
      options: R.compose(
        R.map(variant => ({
          id: variant.variant_id,
          value: variant.variant.display_name,
          color: variant.variant.item.background,
          limit: variant.limit,
          price: R.pathOr(0, ["price", "price"])(variant)
        })),
        R.sortBy(R.prop("order")),
        R.pathOr([], ["settings", "variants"])
      )(column)
    };
  } else if (column.type === "catalog-item") {
    return {
      customWidth: get(column, "settings.customWidth"),
      selectType: get(column, "settings.selectType"),
      options: get(column, "settings.availableCatalogItems"),
      quantityOptions: get(column, "settings.availableQuantities"),
      shouldLimitToAvailableQuantities: get(
        column,
        "settings.shouldLimitToAvailableQuantities"
      )
    };
  } else if (column.type === "event-days") {
    return {
      startDate: event.date_from,
      endDate: event.date_to,
      dayGroups: event.date_groups
    };
  } else if (column.type === "catering") {
    return {
      selectedDays: get(event, "module_settings.catering.selected_days", []),
      meals: get(event, "module_settings.catering.meals", []),
      mealDays: get(event, "module_settings.catering.meal_days", []),
      column
    };
  } else if (column.type === "catering-dietary-restrictions") {
    return {
      options: get(event, "module_settings.catering.dietary_restrictions", [])
    };
  } else if (column.type === "percent") {
    return {
      format: get(column, "settings.percentFormat", "")
    };
  } else if (column.type === "currency") {
    return get(column, "settings", {});
  } else if (column.type === "lookup") {
    return get(column, "settings", {});
  } else if (column.type === "reference") {
    return {
      eventId: get(event, "id")
    };
  }

  return { column };
}
