import PropTypes from "prop-types";
import { Component, cloneElement } from "react";

class FormHandler extends Component {
  render() {
    return cloneElement(this.props.children, {
      ...this.props
    });
  }
}

FormHandler.propTypes = {
  children: PropTypes.object.isRequired
};

export default FormHandler;
