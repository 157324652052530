import PropTypes from "prop-types";
import React, { Component } from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Body from "components/Global/Modal/Layout/ScrollableBody";
import { get } from "lodash";
import { ButtonGroup, Submit } from "components/Global/Modal/Layout/Buttons";
import FormElements from "components/Global/Modal/Layout/FormElements";
import CircularProgress from "material-ui/CircularProgress";
import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";
import resolveEditor from "components/Global/StandAloneEditors/utils/resolveEditor";
import getMetaData from "utils/value-types/get-meta-data";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";
import resolveRecordName from "components/Event/Module/utils/resolveRecordName";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

const { Label, InputSection, InputGroup } = FormElements;

export const Loading = CSSModules(
  () => (
    <div styleName="progress">
      <CircularProgress color="#ccc" />
    </div>
  ),
  css
);

@CSSModules(css)
class EditRecordModal extends Component {
  constructor(props) {
    super(props);

    this.state = { ...props.values };
  }

  componentDidMount() {
    this.props.getModule({
      moduleId: this.props.moduleId,
      options: {
        eventId: get(this.props.eventDetails, "id")
      }
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({ ...newProps.values });
  }

  validateForm = () => Object.keys(this.state).length;

  resetState = () => {
    this.setState(
      state => {
        Object.keys(state).forEach(id => delete state[id]);
        return state;
      },
      () => {
        this.setState({ ...this.props.values });
      }
    );
  };

  getRowMetaData = field =>
    getMetaData({
      row: {},
      rowId: 0,
      field,
      eventDetails: this.props.eventDetails,
      eventId: get(this.props.eventDetails, "id")
    });

  renderFieldGroup = () => {
    const { preferences, fields } = this.props;
    const { visible_fields, field_order } = preferences;
    const readOnlyFields = resolveReadOnlyFields({
      moduleId: this.props.moduleId
    });

    let fieldsToShow = fields.filter(f => !readOnlyFields.includes(f.id));

    if (visible_fields) {
      fieldsToShow = fieldsToShow.filter(f => visible_fields.includes(f.id));
    }

    if (field_order) {
      fieldsToShow = fieldsToShow.sort(
        (a, b) => field_order[a.id] - field_order[b.id]
      );
    }

    return (
      <div>
        {fieldsToShow.map(field => {
          const Editor = resolveEditor(field);
          return (
            <InputSection key={field.id}>
              <InputGroup>
                <Label>{field.name}</Label>
                <Editor
                  rowMetaData={this.getRowMetaData(field)}
                  value={this.state[field.id]}
                  onChange={val => this.saveFieldValue(field.id, val)}
                  {...resolveEditorProps(field, this.props.eventDetails)}
                />
              </InputGroup>
            </InputSection>
          );
        })}
      </div>
    );
  };

  saveFieldValue = (fieldId, value) => {
    return this.props
      .addValue({
        fieldId,
        moduleId: this.props.module.id,
        recordId: this.props.record.id,
        value
      })
      .then(() => this.updateState(fieldId, value));
  };

  updateState = (fieldId, value) => {
    this.setState({ [fieldId]: value });
  };

  render() {
    const { isFetching, module, hideModal } = this.props;
    const recordName = resolveRecordName({
      moduleId: module.id,
      plural: false
    }).toLowerCase();

    return (
      <StyleWrapper
        bodyStyles={{ padding: 0 }}
        containerStyles={{ overflowY: "hidden" }}
        heading={
          <div styleName="nameWrapper">
            <span>Edit {recordName}</span>
            <span styleName="moduleName">
              {module.name} (ID: {this.props.module.record_prefix}-{
                this.props.record.friendly_id
              })
            </span>
          </div>
        }
        hideModal={hideModal}
        width={475}
      >
        <Body style={{ padding: "20px 30px 10px 30px" }}>
          {isFetching ? <Loading /> : this.renderFieldGroup()}
        </Body>
        <div className={css.footer}>
          <ButtonGroup>
            <Submit title="Done" onClick={() => this.props.hideModal()} />
          </ButtonGroup>
        </div>
      </StyleWrapper>
    );
  }
}

EditRecordModal.defaultProps = {
  values: []
};

EditRecordModal.propTypes = {
  preferences: PropTypes.shape({
    field_order: PropTypes.object,
    visible_fields: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  addRecord: PropTypes.func.isRequired,
  addValue: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      fieldId: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.object
    })
  ),
  isFetching: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  params: PropTypes.shape({
    eventId: PropTypes.string
  }).isRequired,
  getModule: PropTypes.func.isRequired,
  starredFields: PropTypes.array.isRequired,
  fieldGroups: PropTypes.array.isRequired,
  module: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    record_name: PropTypes.string,
    record_name_plural: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.string,
    event_id: PropTypes.number
  }).isRequired,
  eventDetails: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  onSave: PropTypes.func.isRequired,
  record: PropTypes.shape({
    id: PropTypes.string
  }),
  getRecordTypes: PropTypes.func.isRequired,
  moduleId: PropTypes.string.isRequired,
  recordTypes: PropTypes.array,
  fields: PropTypes.array.isRequired
};

export default EditRecordModal;
