import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Step = CSSModules(({ label, active, index, stepCount }) => {
  const isFirst = index === 0;
  const isLast = index === stepCount - 1;
  const activeState = active ? "Active" : "";
  return (
    <div styleName={`step${activeState}`}>
      <div styleName="label">{label}</div>
      <div styleName="progress">
        <div styleName="centerIcon" />
        <div
          styleName="connector"
          style={{
            width: isFirst || isLast ? "50%" : "100%",
            right: isLast ? "50%" : 0
          }}
        />
      </div>
    </div>
  );
}, css);

const Stepper = ({ steps }) => (
  <div styleName="container">
    {steps.map(({ id, name, active }, index) => (
      <Step
        key={id}
        index={index}
        id={id}
        label={name}
        active={active}
        stepCount={steps.length}
      />
    ))}
  </div>
);

export default CSSModules(Stepper, css);
