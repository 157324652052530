import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ShareModal from "./ShareModal";

import * as EventSelectors from "redux/modules/event/selectors";
import * as ModuleRecordOwnersSelectors from "redux/modules/modules/owners/selectors";
import * as EventUserSelectors from "redux/modules/eventUsers/selectors";
import * as UserSelectors from "redux/modules/user/selectors";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { getUsers } from "redux/modules/eventUsers/actions";
import {
  getOwners,
  addOwners,
  deleteOwners
} from "redux/modules/modules/owners/actions";

function mapStateToProps(state, props) {
  return {
    eventDetails: EventSelectors.eventDetails(state),
    isFetchingOwners: ModuleRecordOwnersSelectors.isFetching(
      state,
      props.moduleId
    ),
    isFetchingUsers: EventUserSelectors.isFetching(state),
    owners: ModuleRecordOwnersSelectors.owners(state, props.moduleId),
    users: EventUserSelectors.users(state),
    user: UserSelectors.user(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSnackbar,
      getUsers,
      getOwners,
      addOwners,
      deleteOwners
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareModal);
