import React from "react";
import { Div, SmallAvatar } from "components/Base";
import { bindInstance } from "redux-mvc";
import { connect } from "react-redux";
import SearchBar from "ui-kit/SearchBar/View";
import { FILTER } from "ui-kit/Notifications/constants";
import { actions } from "ui-kit/Notifications/model";
import { eventsFilteredByName } from "ui-kit/Notifications/selectors";
import { VARIANTS } from "ui-kit/Theme/constants";

const decorate = connect(
  (state, props) => ({
    eventsFilteredByName: eventsFilteredByName(state, props)
  }),
  bindInstance({
    selectFilter: actions.selectFilter
  })
);

const EventPopoverFilter = ({
  instanceId,
  closePopover,
  eventsFilteredByName,
  selectFilter
}) => (
  <Div p={3} bg="white" display="column" height={300} width={330} bra={1}>
    <SearchBar
      size="medium"
      pill
      shadow={0}
      filters={[]}
      variant={VARIANTS.SURFACE}
      flex={1}
      placeholder="Search events"
      instanceId={instanceId}
      mb={2}
      style={{
        flexShrink: 0,
        flexGrow: 0
      }}
    />
    <Div>
      {eventsFilteredByName.map(
        ({ id, logo_image_url, name, count_of_notifications }) => (
          <Div
            display="row.flex-start.center"
            key={id}
            onClick={() => {
              selectFilter({ type: FILTER.BY_EVENT, id: id });
              closePopover();
            }}
            bg={{
              hover: "#fafafa"
            }}
            p={2}
            bra={2}
          >
            <SmallAvatar square photoURL={logo_image_url} />
            <Div color="gray8" fs={2} ml={2} fw={3}>
              {name}
            </Div>
            <Div
              color="gray8"
              fs={2}
              style={{ marginLeft: "auto" }}
              ml={2}
              fw={3}
            >
              {count_of_notifications}
            </Div>
          </Div>
        )
      )}
    </Div>
  </Div>
);

export default decorate(EventPopoverFilter);
