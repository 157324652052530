import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import getCredentialPrice from "Orders/utils/get-credential-price";
import Counter from "Orders/ItemPickers/Counter";

const Credential = ({ item }) => {
  const variant = item.variants[0];
  const price = getCredentialPrice({
    price: variant.price,
    currency: variant.currency
  });
  return (
    <div styleName="container">
      <div
        styleName="color"
        style={{ backgroundColor: item.background_color }}
      />
      <div styleName="main">
        <div styleName="title" title={item.name}>
          {item.name}
        </div>
        <div styleName="subTitle">
          <span>
            {typeof variant.inventory_quantity !== "number"
              ? ""
              : `${variant.inventory_quantity} remaining`}
          </span>
        </div>
      </div>
      {price ? <div styleName="price">{price}</div> : null}
      <Counter
        {...{
          quantity: variant.quantity,
          incrementQuantity: variant.incrementQuantity,
          decrementQuantity: variant.decrementQuantity,
          updateVariantQuantity: variant.updateVariantQuantity
        }}
      />
    </div>
  );
};

Credential.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    background_color: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.number,
        inventoryQuantity: PropTypes.number,
        incrementQuantity: PropTypes.func,
        decrementQuantity: PropTypes.func,
        updateVariantQuantity: PropTypes.func,
        price: PropTypes.number,
        currency: PropTypes.string
      })
    )
  }).isRequired
};

export default CSSModules(Credential, css);
