import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";
import { withRouter } from "react-router";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";

import {
  getGuestLists,
  updateGuestList
} from "redux/modules/guestLists/actions";
import { getFields } from "redux/modules/modules/fields/actions";
import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";

import { eventDetails } from "redux/modules/event/selectors";
import { guestLists } from "redux/modules/guestLists/selectors";
import { fields } from "redux/modules/modules/fields/selectors";
import { recordTypes } from "redux/modules/modules/recordTypes/selectors";

const contactReadOnlyFields = resolveReadOnlyFields({
  moduleId: STANDARD_MODULES.contacts.id
});

function mapStateToProps(state, props) {
  const guestListsToPass = guestLists(state);
  const guestList = guestListsToPass.find(
    g => g.record_type_id === props.recordTypeId
  );
  const contactFieldsToPass = fields(
    state,
    STANDARD_MODULES.contacts.id
  ).filter(
    f =>
      !contactReadOnlyFields.includes(f.id) &&
      !["lookup", "reference"].includes(f.type)
  );

  return {
    guestList,
    eventDetails: eventDetails(state),
    contactFields: contactFieldsToPass,
    contactRecordTypes: recordTypes(state, STANDARD_MODULES.contacts.id)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSnackbar,
      getRecordTypes,
      getGuestLists,
      updateGuestList,
      getFields,
      showModal,
      hideModal
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
