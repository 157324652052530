import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const FormMain = ({ children }) => <div styleName="main">{children}</div>;

FormMain.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.node
  ]).isRequired
};

export default CSSModules(FormMain, css);
