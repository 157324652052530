import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Card from "components/Global/CRM/Card";
import Header from "components/Global/CRM/Card/Header";
import Title from "components/Global/CRM/Card/Title";
import IconTitle from "components/Global/CRM/Card/IconTitle";
import Body from "components/Global/CRM/Card/Body";
import Report from "components/Global/CRM/Forms/Report";
import hasReports from "components/Global/CRM/Forms/utils/has-reports";

const COLOR = "#813CD2";

const AccountReports = ({ eventDetails, forms = [] }) => {
  if (!hasReports(forms)) {
    return <div />;
  }

  const reports = forms
    .filter(({ submissions }) => submissions.length)
    .map(form => <Report key={form.id} details={eventDetails} form={form} />);

  return (
    <Card color={COLOR}>
      <Header>
        <Title>
          <IconTitle title="Form Reports" color={COLOR}>
            <i className={`material-icons ${css.icon}`}>&#xE051;</i>
          </IconTitle>
        </Title>
      </Header>
      <Body>
        <div styleName="body">{reports}</div>
      </Body>
    </Card>
  );
};

AccountReports.propTypes = {
  forms: PropTypes.arrayOf(PropTypes.object).isRequired,
  eventDetails: PropTypes.objectOf(PropTypes.string).isRequired
};

export default CSSModules(AccountReports, css);
