import Helpers from "utils/Global/Helpers";

module.exports = {
  get: (credentials, { eventId, integration, filters }) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      let url = `${
        window.__LENND_API_BASE_URL__
      }/credentials/event/${eventId}/orders`;
      if (integration && filters) {
        url = `${url}?integration=${integration}&filters=${filters}`;
      } else if (integration) {
        url = `${url}?integration=${integration}`;
      } else if (filters) {
        url = `${url}?filters=${filters}`;
      }
      const options = {
        method: "get",
        url,
        credentials,
        data: {},
        success: result => {
          resolve(result);
        },
        error: err => {
          reject(err);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  search: (credentials, eventId, data, integration) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      let url = `${
        window.__LENND_API_BASE_URL__
      }/credentials/event/${eventId}/orders/search`;
      if (integration) {
        url = `${url}?integration=${integration}`;
      }
      const options = {
        method: "post",
        url,
        credentials,
        data,
        success,
        error
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  post: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/credentials/orders`,
        credentials,
        data,
        success: result => {
          resolve(result);
        },
        error: err => {
          reject(err);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  syncAllOrders: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/orders/sync/bulk`,
        credentials,
        data,
        success: result => {
          resolve(result);
        },
        error: err => {
          reject(err);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  findOrdersBySubmissionIds: (credentials, eventId, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${
          window.__LENND_API_BASE_URL__
        }/credentials/event/${eventId}/orders/submissions`,
        credentials,
        data,
        success: result => {
          resolve(result);
        },
        error: err => {
          reject(err);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  upsertMeta: (credentials, eventId, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/credentials/meta`,
        data,
        credentials,
        success,
        error
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
