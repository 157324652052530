import orderIsEventbrite from "components/Event/Credentials/Utils/eventbrite/order-is-eventbrite";
import eventbriteLastSynced from "components/Event/Credentials/Utils/eventbrite/last-synced";

export default (submissionId, submissionToOrderMap, orders) => {
  if (submissionId in submissionToOrderMap) {
    const orderId = submissionToOrderMap[submissionId];
    const order = orders[orderId];

    return order && orderIsEventbrite(order) && eventbriteLastSynced(order);
  }
  return false;
};
