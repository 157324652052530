import React, { Component } from "react";
import View from "./View";
import * as R from "ramda";
import itemsDecorator from "Orders/ItemPickers/ItemsActionsDecorator";

class Controller extends Component {
  state = {
    loading: true,
    activeView: "editable"
  };

  async componentDidMount() {
    await this.props.getItemTypesByEvent(this.props.eventDetails.id);
    this.setState({ loading: false });
  }

  getSelectedVariantId = () => Object.keys(this.props.selectedVariants)[0];

  onSave = async () => {
    this.setState({ saving: true });
    this.props.onSave(this.getSelectedVariantId());
    this.props.hideModal();
  };

  get itemCount() {
    return Object.keys(this.props.selectedVariants).reduce(
      (a, id) => (a += this.props.selectedVariants[id]),
      0
    );
  }

  selectVariant = variantId => {
    this.props.clearAll();
    this.props.updateVariantQuantity(variantId, 1);
  };

  render() {
    const { loading, saving } = this.state;
    const {
      eventDetails,
      hideModal,
      types,
      selectedVariants,
      selectedVariant,
      qty,
      activeTypeId
    } = this.props;
    const itemCount = this.itemCount;

    const itemTypesWithHandlers = R.map(type => ({
      ...type,
      active: activeTypeId === type.id,
      groups: R.compose(
        R.filter(g => !!g.items.length),
        R.map(group => ({
          ...group,
          items: R.map(item => ({
            ...item,
            variants: R.map(variant => {
              const quantity =
                selectedVariants[variant.id] >= 0
                  ? selectedVariants[variant.id]
                  : 0;
              return {
                ...variant,
                quantity,
                price: 0,
                currency: "USD",
                incrementQuantity: () =>
                  this.props.updateVariantQuantity(variant.id, quantity + 1),
                decrementQuantity: () =>
                  this.props.updateVariantQuantity(variant.id, quantity - 1),
                updateVariantQuantity: () => this.selectVariant(variant.id)
              };
            }, item.variants)
          }))(group.items)
        }))
      )(type.groups)
    }))(types);

    const activeType = itemTypesWithHandlers.find(
      ({ id }) => id === activeTypeId
    );

    const replaceWithVariantId = this.getSelectedVariantId();
    const replaceWithVariant = this.props.variants.find(
      v => v.id === replaceWithVariantId
    );

    return (
      <View
        {...{
          activeType,
          cateringMealsPerDay: eventDetails.module_settings.catering.meal_days,
          eventDateGroups: eventDetails.date_groups,
          hideModal,
          loading,
          saving,
          onSave: this.onSave,
          isValid: itemCount > 0,
          replaceWithItemName: replaceWithVariant
            ? replaceWithVariant.item.name
            : null,
          selectedItemName:
            selectedVariant && selectedVariant.item
              ? selectedVariant.item.name
              : null,
          selectedItemQty: qty
        }}
      />
    );
  }
}

export default itemsDecorator(Controller);
