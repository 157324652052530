import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import {
  getAllForms,
  getClosingThisWeek
} from "EventLight/Forms/Forms/selectors";
import { Div, Text5 } from "components/Base";
import FormRow from "./FormRow";

const decorate = connect(state => ({
  closingThisWeek: getClosingThisWeek(state),
  list: getAllForms(state)
}));

const AllForms = ({ list, closingThisWeek, showTitle }) => (
  <Div mt={5}>
    {showTitle ? (
      <Div display="row.flex-start.center" pl={1}>
        <Text5 bold color="black">
          {closingThisWeek.length ? "All other forms" : "Forms"}
        </Text5>
        <Text5 ml={1} color="black">
          ({R.length(list)})
        </Text5>
      </Div>
    ) : null}
    <Div mt={2}>
      {R.map(
        row => (
          <FormRow row={row} />
        ),
        list
      )}
    </Div>
  </Div>
);

export default decorate(AllForms);
