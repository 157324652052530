import * as R from "ramda";
import { select, call, takeEvery, all, fork, put } from "redux-saga/effects";
import { debounce } from "utils/General/sagas";

import { actions, getters } from "Orders/List";
import { registerError } from "redux/modules/errors/actions";
import { actions as TabActions, getters as TabGetters } from "ui-kit/Tabs";

import { eventId as getEventId } from "redux/modules/event/selectors";
import {
  userId as getUserId,
  getCredentials
} from "redux/modules/user/selectors";

import api from "Orders/List/api";

import { STATUS } from "Orders/List/constants";

import { navigateTo } from "utils/General";

const resetPaginationActions = [
  actions.setSearchTerm.type,
  TabActions.setSelectedTab.type
];

const getSearchParams = (state, actionType) => {
  const eventId = getEventId(state);
  const searchTerm = getters.searchTerm(state);
  const status = TabGetters.selectedTab(state);
  const view = getters.view(state);

  const pagination = getters.pagination(state);

  const data = {
    eventId,
    page: R.contains(actionType, resetPaginationActions)
      ? 1
      : pagination.page + 1,
    pageSize: pagination.pageSize,
    status,
    view
  };

  if (searchTerm) {
    data.search = searchTerm;
  }

  return data;
};

const search = function*({ type }) {
  yield put(actions.setLoading(true));
  const userId = yield select(getUserId);
  const credentials = yield select(getCredentials);

  const state = yield select(R.identity);
  const data = getSearchParams(state, type);

  try {
    const result = yield call(api.search, credentials, userId, data);
    yield put(actions.receiveList(result.payload));
  } catch (e) {
    yield put(
      registerError([
        { system: e, user: "An error occurred fetching orders list" }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const watchSearch = function*() {
  yield takeEvery(
    [
      TabActions.setSelectedTab.type,
      actions.setCurrentPage.type,
      actions.setPageSize.type,
      actions.search.type
    ],
    search
  );
};

const init = function*() {
  yield put(TabActions.setSelectedTab(STATUS.ALL));
};

const watchSearchTerm = debounce(actions.setSearchTerm.type, search, 500);

const exportData = function*({ type, payload: contentType }) {
  const credentials = yield select(getCredentials);
  const userId = yield select(getUserId);

  const state = yield select(R.identity);

  const data = getSearchParams(state, type);

  yield put(actions.setLoading(true));
  try {
    const result = yield call(api.export, credentials, userId, {
      ...data,
      contentType
    });
    yield all([call(navigateTo, result.url), put(actions.setLoading(false))]);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred exporting the orders list"
          }
        ])
      ),
      put(actions.setLoading(false, {}, true))
    ]);
  }
  yield put(actions.setLoading(false));
};

const watchExport = function*() {
  yield takeEvery(actions.exportData.type, exportData);
};

const rootSaga = function*() {
  yield call(init);
  yield all([
    fork(search, { type: actions.setSearchTerm.type }),
    fork(watchSearch),
    fork(watchSearchTerm),
    fork(watchExport)
  ]);
};

export default rootSaga;
