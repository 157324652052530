import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Status = ({ icon, label, color }) => (
  <div styleName="caption" key={label} style={{ color }}>
    {icon && <i className={`material-icons ${icon}`}>{icon}</i>}
    {label}
  </div>
);

Status.propTypes = {
  icon: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string.isRequired
};

export default CSSModules(Status, css);
