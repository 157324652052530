import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Edit from "./Edit";

import * as SubmissionActions from "redux/modules/formsV2/submission/actions";
import * as ValueActions from "redux/modules/formsV2/submission/values/actions";
import * as SubmissionSelectors from "redux/modules/formsV2/submission/selectors";
import * as FormSelectors from "redux/modules/formsV2/form/selectors";

function mapStateToProps(state, props) {
  return {
    eventDetails: props.isPreviewing
      ? FormSelectors.eventDetails(state)
      : SubmissionSelectors.eventDetails(state),
    submission: SubmissionSelectors.submission(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    Object.assign(SubmissionActions, ValueActions),
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Edit);
