import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  saving: false,
  contactRecordTypes: [],
  contactsCollapsed: false,
  assignedToPeopleTypes: [],
  assignedToPeople: [],
  showAddAssignmentModal: false,
  customCloseDate: null
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    showUploadFilesModal: R.identity,
    save: R.identity,
    onDone: R.identity,
    hideCreateModal: R.identity,
    setInitialData: (_, { payload }) => ({
      assignedToPeopleTypes: R.propOr([], "assigned_to_people_types", payload),
      assignedToPeople: R.propOr([], "assigned_to_people", payload)
    }),
    toggleEnableReminders: ({ enableReminders }) => ({
      enableReminders: !enableReminders
    }),
    deleteFile: ({ files }, { payload: idx }) => ({
      files: [...files.slice(0, idx), ...files.slice(idx + 1)]
    }),
    resetData: R.always(iniState),
    setRecords: (_, { payload: { contactRecordTypes } }) => ({
      contactRecordTypes
    }),
    toggleContactRecordType: (state, { payload: { id, selected } }) => ({
      assignedToPeopleTypes: selected
        ? R.filter(contactId => contactId !== id, state.assignedToPeopleTypes)
        : [...state.assignedToPeopleTypes, id]
    }),
    selectAllContactRecordTypes: state => ({
      assignedToPeopleTypes: R.map(R.prop("id"), state.contactRecordTypes)
    }),
    deselectAllContactRecordTypes: () => ({
      assignedToPeopleTypes: []
    }),
    showContactAddAssignmentModal: () => ({
      showAddAssignmentModal: true
    }),
    updateAssignedToPeople: (state, { payload: contact }) => ({
      assignedToPeople: R.uniqBy(R.prop("id"))([
        ...state.assignedToPeople,
        contact
      ])
    }),
    removeAssignedContactId: (state, { payload: contactId }) => ({
      assignedToPeople: R.filter(
        ({ id }) => id !== contactId,
        state.assignedToPeople
      )
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
