import React from "react";
import { connect } from "react-redux";

import { actions } from "Forms/WizardModal";

import { Div, SuperOutlineButton } from "components/Base";
import { PAGES } from "Forms/WizardModal/constants";
import Header from "Forms/WizardModal/View/Header";
import Option from "Forms/WizardModal/View/Common/Option";
import { withProps } from "utils/General";
import TitleBox from "Forms/WizardModal/View/Common/LayoutMock/TitleBox";
import Button from "Forms/WizardModal/View/Common/LayoutMock/Button";
import InputFieldStatic from "Forms/WizardModal/View/Common/LayoutMock/InputFieldStatic";

const CustomOption = withProps({
  styleProps: { height: 102 },
  btnText: "CONTINUE"
})(Option);

const decorate = connect(
  null,
  {
    back: () => actions.setSelectedPage(PAGES.SELECT_METHOD),
    goToApplicationsSelectAccounts: ({ requestType }) =>
      actions.getRecordTypes({ requestType })
  }
);

const SelectMethod = ({ handlers, back, goToApplicationsSelectAccounts }) => (
  <Div width={1} height={1}>
    <Header
      steps={["Step 1", "Step 2", "Step 3", "Step 4"]}
      handlers={handlers}
    />
    <Div
      bg="neutral0"
      display="column.flex-start.center"
      p={5}
      style={{ height: "calc(100% - 157px)", overflow: "auto" }}
    >
      <Div fs={6} fw={4} color="black">
        Who's Applying?
      </Div>
      <Div width={1} mt={5}>
        <CustomOption
          title="Company / Group Applications"
          description="Collect high-level information about each applying organization (e.g. vendors, exhibitors, talent) along with additional attendees' information."
          onClick={() =>
            goToApplicationsSelectAccounts({ requestType: "group" })
          }
        >
          <Div width={1}>
            <TitleBox />
            <InputFieldStatic width={1} mb={2} />
            <Button title="Add Applicant" width={1} />
          </Div>
        </CustomOption>
        <CustomOption
          title="Individual Applications"
          description="Collect information from individuals (e.g. volunteers, job applicants)"
          onClick={() =>
            goToApplicationsSelectAccounts({ requestType: "individual" })
          }
        >
          <Div width={1}>
            <TitleBox />
            <InputFieldStatic width={1} mb={2} />
          </Div>
        </CustomOption>
      </Div>
    </Div>
    <Div
      display="row.space-between.center"
      width={1}
      height={90}
      bt={1}
      bc="neutral2"
      bg="white"
      p={5}
      style={{ flexShrink: 0 }}
    >
      <SuperOutlineButton onClick={back}>Back</SuperOutlineButton>
      <SuperOutlineButton onClick={handlers.hideModal}>
        Cancel
      </SuperOutlineButton>
    </Div>
  </Div>
);

export default decorate(SelectMethod);
