import Helpers from "utils/Global/Helpers";

module.exports = {
  post: (credentials, { recordId, documentId, moduleId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/modules/${moduleId}/documents`,
        credentials,
        data: {
          recordId,
          documentId,
          moduleId
        },
        success,
        error
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
