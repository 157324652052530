import Helpers from "utils/Global/Helpers";

/* eslint no-underscore-dangle: "off" */

export default {
  search: (credentials, userId, eventId, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/orders/line-items/bulk-confirmations-list`,
        qs: { eventId, ...data },
        credentials,
        success,
        error
      });
    })
};
