import React from "react";
import { Div } from "components/Base";

const Panel = ({ children, style = {}, ...styleProps }) => (
  <Div
    bg="white"
    mt={2}
    style={{
      flexShrink: 0,
      boxShadow:
        "0px 8px 20px rgba(0, 0, 0, 0.04), 0px 35px 54px rgba(0, 0, 0, 0.01)",
      borderRadius: 3,
      ...style
    }}
    {...styleProps}
  >
    {children}
  </Div>
);

export default Panel;
