import React from "react";
import EditorBase from "./TableConnector";
import Cell from "./CellBase";
import Editor from "components/Global/Editors/UserEditor";
import Formatter from "ui-kit/Datagrid/View/Formatters/User";

import { ep, getRowMetadata } from "ui-kit/Datagrid/utils";

export default class UserEditor extends EditorBase {
  render() {
    const props = ep(this.props);
    const { row } = props;

    const rowMetaData = getRowMetadata(props);

    return (
      <Cell
        ref="editor"
        formatter={<Formatter value={this.state} row={row} />}
        editor={
          <Editor
            {...this.props}
            rowMetaData={rowMetaData}
            value={this.state}
            onChange={this.handleChange}
          />
        }
      />
    );
  }
}
