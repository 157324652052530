import PropTypes from "prop-types";
import React, { Component } from "react";
import Table3 from "components/Global/Table3/Table3/Table";
import Immutable from "immutable";

const createColumnMap = columns =>
  columns.reduce((map, c) => {
    map[c.id] = c;
    return map;
  }, {});

class RequestsTable extends Component {
  render() {
    return (
      <div ref="wrapper" className="react-grid-Lennd-Table3">
        <Table3
          columns={new Immutable.List(this.props.columns)}
          columnsMap={createColumnMap(this.props.columns)}
          contextMenus={this.props.contextMenus}
          enableRowSelect
          height={this.props.height}
          onCellCopyPaste={this.props.onCellCopyPaste}
          onCellsDragged={this.props.onCellsDragged}
          onColumnResize={this.props.onColumnResize}
          onRowSelect={this.props.onRowSelect}
          onRowSort={this.props.onRowSort}
          rowRenderer={this.props.rowRenderer}
          rowOrder={this.props.rowOrder}
          rows={this.props.rows}
          saveCell={this.props.onRowUpdated}
          selectedRows={this.props.selectedRows}
        />
      </div>
    );
  }
}

RequestsTable.propTypes = {
  columns: PropTypes.array.isRequired,
  contextMenus: PropTypes.object.isRequired,
  onRowSort: PropTypes.func.isRequired,
  height: PropTypes.number,
  onCellCopyPaste: PropTypes.func.isRequired,
  onCellsDragged: PropTypes.func.isRequired,
  onColumnResize: PropTypes.func.isRequired,
  onRowSelect: PropTypes.func,
  onRowUpdated: PropTypes.func.isRequired,
  rowGetter: PropTypes.func.isRequired,
  rowRenderer: PropTypes.func.isRequired,
  rowOrder: PropTypes.object,
  rows: PropTypes.array.isRequired,
  selectedRows: PropTypes.array.isRequired
};

export default RequestsTable;
