import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE } from "./constants";

const iniState = {
  variantIds: [],
  loading: false
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    cancelInstance: R.always(iniState),
    reorderQuestions: R.identity,
    getInitialData: (state, { payload: { variantIds } }) => ({
      variantIds
    })
  }
});

export default handlers;
