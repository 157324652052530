import * as R from "ramda";

import { createModule, createHandlers } from "redux-mvc";
import { NAMESPACE } from "ui-kit/Tabs/constants";

const iniState = {
  selectedTab: ""
};

const getIniSelectedId = (iniSelected, tabs) => {
  if (typeof iniSelected === "number" && R.path([iniSelected, "id"], tabs)) {
    return R.path([iniSelected, "id"], tabs);
  } else if (!R.isEmpty(iniSelected)) {
    return iniSelected;
  }
  return R.path([0, "id"], tabs);
};

const handlers = createHandlers({
  iniState,
  reducers: {
    setIniSelected: (state, { payload: { iniSelected, tabs } }) => {
      if (state.selectedTab) {
        return state;
      }
      const selectedTab = getIniSelectedId(iniSelected, tabs);
      if (selectedTab) {
        return {
          selectedTab
        };
      }
      return state;
    }
  },
  namespace: NAMESPACE
});

const { actions, getters } = handlers;

export { actions, getters };

const module = createModule(handlers);

export default module;
