import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";
import * as R from "ramda";
import { withRouter } from "react-router";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { hideModal, showModal } from "redux/modules/modal/actions";
import {
  getTicketTypes,
  getTicketTypeMapping,
  syncTicketTypes
} from "redux/modules/ticketing/providers/actions";
import {
  getItemGroupsByEventAndType,
  addItemGroup
} from "redux/modules/items/item-groups/actions";
import { addItem } from "redux/modules/items/items/actions";
import { bulkUpdateVariants } from "redux/modules/items/item-variants/actions";

import { eventDetails } from "redux/modules/event/selectors";
import { groups } from "redux/modules/items/item-groups/selectors";
import {
  ticketTypes,
  ticketTypeMapping
} from "redux/modules/ticketing/providers/selectors";
import * as UserSelectors from "redux/modules/user/selectors";

function mapStateToProps(state) {
  const ticketTypeMappingToPass = ticketTypeMapping(state) || [];
  const ticketTypesToPass = ticketTypes(state) || [];

  const mappingsByTicketTypeId = R.reduce((map, mapping) => {
    if (map[mapping.ticketType.id]) {
      map[mapping.ticketType.id].push(mapping.lenndTicketTypeId);
    } else {
      map[mapping.ticketType.id] = [mapping.lenndTicketTypeId];
    }
    return map;
  }, {})(ticketTypeMappingToPass);

  const ticketTypesWithMapping = R.map(t => ({
    ...t,
    lenndItemIds: mappingsByTicketTypeId[t.id] || []
  }))(ticketTypesToPass);

  return {
    user: UserSelectors.user(state),
    ticketTypes: ticketTypesWithMapping,
    ticketTypeMapping: ticketTypeMappingToPass,
    eventDetails: eventDetails(state),
    itemGroups: groups(state).map(group => ({
      ...group,
      items: group.items.reduce((items, item) => {
        return [
          ...items,
          ...item.variants.map(variant => ({
            ...item,
            id: variant.id,
            name: variant.display_name
          }))
        ];
      }, [])
    })),
    items: groups(state).reduce(
      (allItems, group) => [
        ...allItems,
        ...group.items.reduce((items, item) => {
          return [
            ...items,
            ...item.variants.map(variant => ({
              ...item,
              id: variant.id,
              name: variant.display_name
            }))
          ];
        }, [])
      ],
      []
    )
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSnackbar,
      showModal,
      hideModal,
      getItemGroupsByEventAndType,
      getTicketTypes,
      getTicketTypeMapping,
      syncTicketTypes,
      addItemGroup,
      addItem,
      bulkUpdateVariants
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
