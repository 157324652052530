import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import * as R from "ramda";
import { actions, getters } from "SalesPortal/ItemsModal";
import { getCredentials } from "redux/modules/user/selectors";
import itemTypesApi from "redux/modules/items/types/api";
import { registerError } from "redux/modules/errors/actions";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  return { credentials };
};

const getItemTypes = function*({ payload: { eventId, iniSelectedTab } }) {
  const items = yield select(getters.itemTypesList);
  if (R.isEmpty(items)) {
    yield put(actions.setLoading(true));

    const { credentials } = yield call(getParams);

    try {
      const { payload: itemTypesList } = yield call(
        itemTypesApi.getItemTypesByEvent,
        credentials,
        eventId
      );

      yield put(actions.setInitialData({ itemTypesList, iniSelectedTab }));
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error occurred loading item types"
          }
        ])
      );
    }
    yield put(actions.setLoading(false));
  }
};

const watchItemTypes = function*() {
  yield takeEvery(actions.getItemTypes.type, getItemTypes);
};

const rootSaga = function*() {
  yield all([fork(watchItemTypes)]);
};

export default rootSaga;
