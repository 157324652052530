import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

// @NOTE: Written as class so we can use `refs` to focus the input
class ModalSelect extends Component {
  focus = () => this.select.focus();

  render() {
    const { children, ...rest } = this.props;
    return (
      <select
        styleName="input"
        {...rest}
        ref={ref => {
          this.select = ref;
        }}
      >
        {children}
      </select>
    );
  }
}

export default CSSModules(ModalSelect, css);
