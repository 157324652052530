import React from "react";
import {
  Div,
  FontIcon,
  SearchIcon,
  BigFilledButton,
  BigShadedInput,
  Text2,
  Text3,
  PopoverMenu,
  TinyOutlineButton,
  makeTable,
  SmallAvatar
} from "components/Base";
import Tooltip from "components/Global/Tooltip";
import CopyToClipboard from "react-copy-to-clipboard";
import { withProps, noop } from "utils/General";
import CanUserDo from "components/Global/Security/CanUserDo";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

import CSSModules from "react-css-modules";
import css from "components/Event/Settings/Module/tableStyles.scss";

const TableStyler = withProps({
  my: 3,
  bra: 1,
  shadow: 1
})(Div);

const HeaderStyler = withProps({
  display: "row.flex-start.center",
  bg: "neutral1",
  py: 3,
  pl: 4
})(Div);

const RowStyler = CSSModules(
  withProps({
    display: "row.flex-start.center",
    bc: "neutral2",
    bg: "white",
    bt: 1,
    pl: 4,
    pt: 2,
    pb: 2,
    styleName: "parentContainer"
  })(Div),
  css
);

const UsersTable = makeTable({
  TableStyler,
  HeaderStyler,
  RowStyler,
  HeaderCellStyler: Div,
  RowCellStyler: Div,
  columnProps: [
    { width: 4 / 12 },
    { width: 3 / 12 },
    { width: 3 / 12 },
    { width: 2 / 12 }
  ],
  headerCellComps: [
    () => <Text3 bold>Name</Text3>,
    () => <Text3 bold>Role</Text3>,
    () => <Text3 bold>Permissions</Text3>,
    () => <Text3 bold>Actions</Text3>
  ],
  rowCellComps: [
    ({ name, email, photoUrl, onView, isPending, inviteSentAt }) => (
      <Div display="row.flex-start.center" onClick={onView}>
        <SmallAvatar photoURL={photoUrl} text={name.toUpperCase()} />
        <Div display="column.center.flex-start" width={1} mr={4} ml={2}>
          <Div display="row.flex-start.center">
            <Text3 bold primary truncate>
              {name}
            </Text3>
            {isPending ? (
              <Tooltip tooltip={`Invite sent ${inviteSentAt}`}>
                <FontIcon ml={1} fs={2} color="gray6">
                  hourglass_empty
                </FontIcon>
              </Tooltip>
            ) : null}
          </Div>
          <Div fs={1} color="gray5">
            {email}
          </Div>
        </Div>
      </Div>
    ),
    ({ role }) => <Text2>{role}</Text2>,
    ({ permissionRole }) => <Text2>{permissionRole}</Text2>,
    ({ onView, onRemove, onResend, onCopy, locked, isPending, inviteLink }) => (
      <CanUserDo
        any={[
          `${STANDARD_MODULES.settings.id}_manage_users`,
          `${STANDARD_MODULES.orgSettings.id}_manage_users`
        ]}
      >
        <PopoverMenu
          Label={({ onClick }) => (
            <TinyOutlineButton onClick={onClick} py={1}>
              <FontIcon fs={3}>more_horiz</FontIcon>
            </TinyOutlineButton>
          )}
          menuItems={
            locked
              ? [["Edit", onView]]
              : [
                  ["Edit", onView],
                  ["Remove", onRemove],
                  isPending ? ["Resend invite", onResend] : null,
                  isPending && onCopy
                    ? [
                        <CopyToClipboard
                          key="copy"
                          text={inviteLink}
                          onCopy={onCopy}
                        >
                          <span>Copy invite URL</span>
                        </CopyToClipboard>,
                        noop
                      ]
                    : null
                ].filter(o => o)
          }
        />
      </CanUserDo>
    )
  ]
});

const View = ({ onSearch, onInviteUser, users }) => (
  <Div display="row.flex-start.flex-start">
    <Div flex={1} px={8}>
      <CanUserDo
        any={[
          `${STANDARD_MODULES.settings}_invite_users`,
          `${STANDARD_MODULES.orgSettings}_invite_users`
        ]}
      >
        <Div display="row.flex-start.center" pt={4} pb={4}>
          <BigShadedInput
            continuous
            LeftIcon={SearchIcon}
            flex={1}
            placeholder="Search users"
            onChange={onSearch}
          />
          <BigFilledButton bg="altB5" ml={1} onClick={onInviteUser}>
            Invite user
          </BigFilledButton>
        </Div>
      </CanUserDo>
      <UsersTable headerData={{}} rowsData={users} />
    </Div>
  </Div>
);

export default View;
