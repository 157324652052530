import {
  put,
  call,
  takeEvery,
  all,
  fork,
  take,
  select,
  delay
} from "redux-saga/effects";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import * as R from "ramda";

import messageSagas from "./messages";

import { registerError } from "redux/modules/errors/actions";

import * as STANDARD_MODULE_FIELD_IDS from "utils/standard-module-field-ids";

import { actions, getters } from "Orders/OrderModal";
import api from "Orders/OrderModal/api";
import orderApi from "redux/modules/orders/orders/api";
import valueApi from "redux/modules/modules/values/api";
import recordApi from "redux/modules/modules/records/api";

import { actions as approvalsActions } from "ApproversLabel";

import { eventId as getEventId } from "redux/modules/event/selectors";
import { getCredentials } from "redux/modules/user/selectors";

import {
  getOrderId,
  getIsSubmission,
  getSubmissionId as getOrderSubmissionId,
  getRelatedModuleRecordId
} from "Orders/OrderModal/selectors";

// submissionEditor module
import { actions as submissionActions } from "Submission/Editor";
import {
  getNonDraftOrderId as getSubmissionOrderId,
  getSubmissionId,
  getFormSlug,
  eventDetails
} from "Submission/Editor/selectors";

import * as TableSelectors from "ui-kit/Datagrid/selectors";

const getNonEmptyValue = R.compose(
  R.head,
  R.filter(R.compose(R.not, R.isEmpty))
);

const openExternalOrder = function*({ payload: url }) {
  yield call([window, window.open], url, "_blank");
};

const goToAccountProfile = function*(accountId) {
  const id = yield select(getEventId);

  // eslint-disable-next-line no-underscore-dangle
  const url = `${window.__LENND_APP_URL__}/event-light/${id}/account/${accountId}`;

  yield call([window, window.open], url, "_blank");
};

const goToContactProfile = function*(contactId) {
  const id = yield select(getEventId);

  // eslint-disable-next-line no-underscore-dangle
  const url = `${window.__LENND_APP_URL__}/event-light/${id}/contact/${contactId}`;

  yield call([window, window.open], url, "_blank");
};

const goToProfile = function*({ payload }) {
  if (payload.type === "account") {
    yield call(goToAccountProfile, payload.id);
  } else {
    yield call(goToContactProfile, payload.id);
  }
};

const watchGoToProfile = function*() {
  yield takeEvery(actions.viewProfile.type, goToProfile);
};

const goToSubmission = function*({ payload: submissionId }) {
  const event = yield select(eventDetails);
  const formSlug = yield select(getFormSlug);
  //eslint-disable-next-line no-underscore-dangle
  const url = `${window.__LENND_APP_URL__}/submissions/${event.slug}/${formSlug}/${submissionId}`;
  window.location.href = url;
};

const watchGoToSubmission = function*() {
  yield takeEvery(actions.viewSubmission.type, goToSubmission);
};

const goToInvoice = function*({ payload: orderId }) {
  // eslint-disable-next-line no-underscore-dangle
  const url = `${window.__LENND_APP_URL__}/invoice/${orderId}`;

  yield call([window, window.open], url, "_blank");
};

const watchGoToInvoice = function*() {
  yield takeEvery(actions.viewInvoice.type, goToInvoice);
};

const orderFlow = function*({ orderId: oId, orderNumber }) {
  let orderId = oId;
  const credentials = yield select(getCredentials);

  try {
    if (R.isNil(orderId) || R.isEmpty(orderId)) {
      const eventId = yield select(getEventId);
      const result = yield call(orderApi.getOrderIdByOrderNumber, credentials, {
        eventId,
        orderNumber
      });
      orderId = result.payload;
    }
    if (R.not(R.isNil(orderId))) {
      const result = yield call(api.getOrder, { credentials, orderId });
      yield put(actions.setOrder(result.payload));
    }
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred fetching the order"
          }
        ])
      )
    ]);
  }
};

const submissionFlow = function*({ submissionId }) {
  yield put(submissionActions.fetch(submissionId));
  yield take(submissionActions.response.type);

  return yield select(getSubmissionOrderId);
};

const fetchData = function*({
  payload: { orderNumber, orderId, submissionId, fetchFeed }
}) {
  yield put(actions.loading());

  if (R.isNil(submissionId) || R.isEmpty(submissionId)) {
    yield call(orderFlow, { orderId, orderNumber });
    if (yield select(getIsSubmission)) {
      const submissionId = yield select(getOrderSubmissionId);
      yield call(submissionFlow, { submissionId });
    }
  } else {
    const orderId = yield call(submissionFlow, { submissionId });
    if (!R.isNil(orderId) && !R.isEmpty(orderId)) {
      yield call(orderFlow, { orderId, orderNumber });
    }
  }

  yield put(actions.loading());

  if (fetchFeed) {
    yield put(actions.loadFeed());
  }
};

const watchLoading = function*() {
  yield take(actions.loading.type);
  yield put(actions.setLoading(true));
  yield take(actions.loading.type);
  yield put(actions.setLoading(false));

  for (;;) {
    yield take(actions.loading.type);
    yield put(actions.setReloading(true));
    yield take(actions.loading.type);
    yield put(actions.setReloading(false));
  }
};

const watchPollOrderChanges = function*() {
  // @NOTE: We're refreshing the summary every 10 seconds in order
  // to capture any integration updates
  yield take(actions.setIntegrations.type);
  const integrations = yield select(getters.integrations);
  if (integrations.length > 0) {
    while (true) {
      const loading = yield select(TableSelectors.getLoading);
      if (!loading) {
        yield delay(10000);
        const oId = getNonEmptyValue([
          yield select(getOrderId),
          yield select(getSubmissionOrderId)
        ]);
        const submissionId = getNonEmptyValue([
          yield select(getOrderSubmissionId),
          yield select(getSubmissionId)
        ]);

        yield all([
          put(actions.fetchOrder({ orderId: oId, submissionId })),
          put(actions.loadFeed()),
          put(actions.setSyncing(false))
        ]);
      }
    }
  }
};

const watchFetchOrder = function*() {
  yield takeEvery([actions.fetchOrder.type, actions.init.type], fetchData);
};

const watchReviewDone = function*() {
  for (;;) {
    yield take(approvalsActions.reviewDone.type);
    const orderId = getNonEmptyValue([
      yield select(getOrderId),
      yield select(getSubmissionOrderId)
    ]);
    const submissionId = getNonEmptyValue([
      yield select(getOrderSubmissionId),
      yield select(getSubmissionId)
    ]);

    yield put(actions.fetchOrder({ orderId, submissionId }));
  }
};

const cancelOrder = function*({ payload: orderId }) {
  try {
    const credentials = yield select(getCredentials);
    yield call(api.cancelOrder, credentials, orderId);
  } catch (error) {
    //eslint-disable-next-line no-console
    console.error(error);
  }
};

const watchCancelOrder = function*() {
  yield takeEvery(actions.cancelOrder.type, cancelOrder);
};

const syncOrder = function*({ payload: orderIds }) {
  yield put(actions.setSyncing(true));

  try {
    const credentials = yield select(getCredentials);
    yield call(api.syncOrders, credentials, {
      orderIds
    });
    // @NOTE: We move setSyncing = false to when the order refreshes the next time

    const oId = getNonEmptyValue([
      yield select(getOrderId),
      yield select(getSubmissionOrderId)
    ]);
    const submissionId = getNonEmptyValue([
      yield select(getOrderSubmissionId),
      yield select(getSubmissionId)
    ]);
    yield put(actions.fetchOrder({ orderId: oId, submissionId }));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred syncing the order"
          }
        ])
      ),
      put(actions.setSyncing(false, {}, true))
    ]);
  }
};

const saveEmailAddress = function*({ payload: email }) {
  try {
    const credentials = yield select(getCredentials);
    const orderId = getNonEmptyValue([
      yield select(getOrderId),
      yield select(getSubmissionOrderId)
    ]);
    const submissionId = getNonEmptyValue([
      yield select(getOrderSubmissionId),
      yield select(getSubmissionId)
    ]);

    const data = {
      orderId,
      contactValues: {
        [STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL]: {
          type: "text",
          value: email
        }
      },
      accountValues: {}
    };

    yield call(api.updateOrderCustomer, credentials, data);
    yield put(actions.fetchOrder({ orderId, submissionId }));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred saving email address"
          }
        ])
      )
    ]);
  }
};

const savePhone = function*({ payload: phone }) {
  try {
    const credentials = yield select(getCredentials);
    const orderId = getNonEmptyValue([
      yield select(getOrderId),
      yield select(getSubmissionOrderId)
    ]);
    const submissionId = getNonEmptyValue([
      yield select(getOrderSubmissionId),
      yield select(getSubmissionId)
    ]);

    const data = {
      orderId,
      contactValues: {
        [STANDARD_MODULE_FIELD_IDS.CONTACTS.MOBILE_PHONE]: {
          type: "phone",
          value: phone
        }
      },
      accountValues: {}
    };

    yield call(api.updateOrderCustomer, credentials, data);
    yield put(actions.fetchOrder({ orderId, submissionId }));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred saving phone"
          }
        ])
      )
    ]);
  }
};

const addTransaction = function*({ payload }) {
  try {
    const credentials = yield select(getCredentials);
    const orderId = getNonEmptyValue([
      yield select(getOrderId),
      yield select(getSubmissionOrderId)
    ]);
    const submissionId = getNonEmptyValue([
      yield select(getOrderSubmissionId),
      yield select(getSubmissionId)
    ]);

    const data = {
      orderId,
      amount: payload.amount,
      method: "stripe",
      stripeTokenId: payload.stripeTokenId,
      source: "admin",
      sendConfirmation: true,
      email: payload.email
    };

    yield call(api.addTransaction, credentials, data);
    yield put(actions.fetchOrder({ orderId, submissionId }));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred adding transaction"
          }
        ])
      )
    ]);
  }
};

const addManualTransaction = function*({ payload }) {
  try {
    const credentials = yield select(getCredentials);
    const orderId = getNonEmptyValue([
      yield select(getOrderId),
      yield select(getSubmissionOrderId)
    ]);
    const submissionId = getNonEmptyValue([
      yield select(getOrderSubmissionId),
      yield select(getSubmissionId)
    ]);

    const data = {
      orderId,
      amount: payload.amount,
      method: "manual",
      source: "admin",
      sendConfirmation: false,
      foreignTransactionId: payload.reference
    };

    yield call(api.addManualTransaction, credentials, data);
    yield put(actions.fetchOrder({ orderId, submissionId }));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred adding transaction"
          }
        ])
      )
    ]);
  }
};

const addOrderFieldValue = function*({ payload }) {
  try {
    const credentials = yield select(getCredentials);
    const recordId = yield select(getRelatedModuleRecordId);
    const eventIdToUse = yield select(getEventId);

    const orderId = getNonEmptyValue([
      yield select(getOrderId),
      yield select(getSubmissionOrderId)
    ]);
    const submissionId = getNonEmptyValue([
      yield select(getOrderSubmissionId),
      yield select(getSubmissionId)
    ]);

    if (recordId) {
      yield call(valueApi.post, credentials, {
        ...payload,
        moduleId: STANDARD_MODULE_IDS.orders.id,
        recordId
      });
    } else {
      const { record } = yield call(recordApi.post, credentials, {
        moduleId: STANDARD_MODULE_IDS.orders.id,
        record: {
          [payload.fieldId]: payload.value
        },
        options: {
          eventId: eventIdToUse
        }
      });
      yield call(orderApi.updateOrder, credentials, {
        orderId,
        order: {
          relatedRecordId: record.id
        }
      });
    }

    yield put(actions.fetchOrder({ orderId, submissionId }));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred adding value"
          }
        ])
      )
    ]);
  }
};

const watchSyncOrder = function*() {
  yield takeEvery(actions.syncOrder.type, syncOrder);
};

const watchOpenExternalOrder = function*() {
  yield takeEvery(actions.openExternalOrder.type, openExternalOrder);
};

const watchSaveEmailAddress = function*() {
  yield takeEvery(actions.saveEmailAddress.type, saveEmailAddress);
};

const watchSavePhone = function*() {
  yield takeEvery(actions.savePhone.type, savePhone);
};

const watchAddTransaction = function*() {
  yield takeEvery(actions.addTransaction.type, addTransaction);
};

const watchAddManualTransaction = function*() {
  yield takeEvery(actions.addManualTransaction.type, addManualTransaction);
};

const watchAddOrderFieldValue = function*() {
  yield takeEvery(actions.addOrderFieldValue.type, addOrderFieldValue);
};

const rootSaga = function*() {
  yield all([
    fork(watchLoading),
    fork(watchFetchOrder),
    fork(watchGoToProfile),
    fork(watchPollOrderChanges),
    fork(watchGoToSubmission),
    fork(watchGoToInvoice),
    fork(watchReviewDone),
    fork(watchSyncOrder),
    fork(watchCancelOrder),
    fork(messageSagas),
    fork(watchSaveEmailAddress),
    fork(watchSavePhone),
    fork(watchAddTransaction),
    fork(watchAddManualTransaction),
    fork(watchOpenExternalOrder),
    fork(watchAddOrderFieldValue)
  ]);
};

export default rootSaga;
