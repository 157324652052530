import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: false,
  items: []
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    save: R.identity,
    hideAddAssignmentModal: R.identity,
    clearData: R.always(iniState)
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
