import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import {
  Div,
  Text1,
  EigthSizeDotIcon,
  MediumFilledButton
} from "components/Base";
import { actions } from "EventLight/FileRequests/List/model";
import { addS } from "utils/General";
import { STATUS } from "EventLight/FileRequests/List/constants";
import Tooltip from "components/Global/Tooltip";
import OptionsPopover from "./OptionsPopover";

const decorate = connect(
  null,
  {
    goToResults: actions.goToResults
  }
);

const RequestRow = ({ row, goToResults }) => (
  <Div
    bra={1}
    bg="white"
    shadow={{
      default: 1,
      hover: 2
    }}
    display="row.space-between.center"
    className="hoverContainer"
    p={4}
    mb={2}
  >
    <Div display="row.flex-start.center">
      {row.count_of_pending ? (
        <Tooltip tooltip="Pending Submissions">
          <span>
            <Div
              bra={1}
              bg="neutral1"
              p={1}
              mr={2}
              width={25}
              display="row.center.center"
              style={{
                backgroundColor: "#EEE6F8"
              }}
            >
              <Div fs={0} color="primary8" fw={3}>
                {row.count_of_pending}
              </Div>
            </Div>
          </span>
        </Tooltip>
      ) : null}
      <Div fw={3} fs={4} color="black">
        {row.name}
      </Div>
    </Div>
    <Div display="row.flex-start.center" style={{ position: "relative" }}>
      <Div
        display="row.flex-end.center"
        style={{ position: "absolute", top: 0, right: 0 }}
        bg="white"
        height={1}
        width={300}
        className="showOnHover"
      >
        <MediumFilledButton
          mr={2}
          bg="primary8"
          color="white"
          onClick={() => goToResults({ data: row })}
        >
          View Results
        </MediumFilledButton>
        {row.isManager ? <OptionsPopover data={row} /> : null}
      </Div>
      <Text1>
        {row.total} result{addS(row.total)}
      </Text1>
      {row.label ? (
        <>
          <EigthSizeDotIcon />
          <Text1>{row.label}</Text1>
        </>
      ) : null}
      <Div
        bra={1}
        py={1}
        px={2}
        bg={row.status === STATUS.OPEN ? "teal0" : "danger0"}
        ml={2}
      >
        <Div
          fs={0}
          fw={3}
          color={row.status === STATUS.OPEN ? "teal7" : "danger7"}
        >
          {R.toUpper(row.status)}
        </Div>
      </Div>
    </Div>
  </Div>
);

export default decorate(RequestRow);
