import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import Frame from "react-frame-component";

function ClickToPrintHOC(Child) {
  return class ClickToPrint extends Component {
    frameRef = React.createContext();

    print = () => {
      ReactDOM.findDOMNode(this.frameRef.current).contentWindow.print();
    };

    injectStyleSheet = () => {
      if (this.frameRef.current) {
        const iframeHead = ReactDOM.findDOMNode(this.frameRef.current)
          .contentDocument.head;
        var styles = document.getElementsByTagName("style");
        var links = document.getElementsByTagName("link");
        for (var i = 0; i < styles.length; i++) {
          const sNode = styles[i].cloneNode(true);
          // cloneNode doesnt clone the 'sheet' attribute where Base styles live.
          // iterate over the sheet attribute and inject into node
          if (styles[i].id === "cxs") {
            for (const rule of styles[i].sheet.cssRules) {
              sNode.innerText += rule.cssText;
              sNode.innerHTML += rule.cssText;
            }
          }
          iframeHead.appendChild(sNode);
        }
        for (var i = 0; i < links.length; i++) {
          iframeHead.appendChild(links[i].cloneNode(true));
        }
      }
      return "";
    };

    render() {
      const initialContent = `
      <!DOCTYPE html>
      <html>
        <head>
          <style type="text/css">
            .no-print { display: none !important; }
          </style>
          <style type="text/css" media="print">
            .no-print { display: none !important; }
            
            /* Force print of background colors and icons */
            * {
              -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
              color-adjust: exact !important;                 /*Firefox*/
            }
          </style>
        </head>
        <body>
          <div>
          </div>
        </body>
      </html>
    `;
      return (
        <Fragment>
          <Frame
            head={this.injectStyleSheet()} // hack to inject stylesheets on updates since Base is generating stylesheet on the fly
            initialContent={initialContent}
            ref={this.frameRef}
            style={{
              height: 0,
              width: 0,
              border: "none",
              position: "absolute"
            }}
            contentDidMount={this.injectStyleSheet}
            contentDidUpdate={this.injectStyleSheet}
          >
            <Child {...this.props} />
          </Frame>
          <Child onClickToPrint={this.print} {...this.props} />
        </Fragment>
      );
    }
  };
}

export default ClickToPrintHOC;
