import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { get } from "lodash";
import PropTypes from "prop-types";
import autobind from "autobind-decorator";
import { DragSource, DropTarget } from "react-dnd";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

/**
 * Implements the drag source contract.
 */
const columnRowSource = {
  canDrag(props) {
    return !!props.updateColumnOrder;
  },
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index
    };
  },
  endDrag(props) {
    props.updateColumnOrder();
  }
};

const columnRowTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

@DropTarget(
  props => props.dragId || "COLUMN_ROW",
  columnRowTarget,
  connect => ({
    connectDropTarget: connect.dropTarget()
  })
)
@DragSource(
  props => props.dragId || "COLUMN_ROW",
  columnRowSource,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  })
)
@CSSModules(css)
class ButtonEditColumnsRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hoverActive: false
    };
  }

  @autobind
  onMouseOver() {
    this.setState({ hoverActive: true });
  }

  @autobind
  onMouseLeave() {
    this.setState({ hoverActive: false });
  }

  render() {
    const { column, connectDragSource, connectDropTarget } = this.props;
    let lock;
    let editColumn;
    let deleteColumn;
    if (column.source === "custom" && this.props.showEditColumnModal) {
      editColumn = (
        <i
          key={`edit_${column.id}`}
          className="material-icons"
          onClick={this.props.showEditColumnModal}
          styleName="columnOption"
        >
          &#xE3C9;
        </i>
      );
    }
    if (
      get(column, "settings.isReadOnly", false) ||
      get(column, "settings.readOnly", false)
    ) {
      lock = (
        <i className="material-icons" styleName="lock">
          &#xE897;
        </i>
      );
    } else if (this.props.showDeleteColumnModal) {
      deleteColumn = (
        <i
          key={`delete_${column.id}`}
          className="material-icons"
          onClick={this.props.showDeleteColumnModal}
          styleName="columnOptionLeft"
        >
          &#xE872;
        </i>
      );
    }

    let options;
    if (this.state.hoverActive) {
      options = (
        <div>
          {deleteColumn}
          {editColumn}
        </div>
      );
    }
    return connectDragSource(
      connectDropTarget(
        <label
          htmlFor={column.id}
          key={column.id}
          styleName="columnRow"
          onMouseOver={this.onMouseOver}
          onMouseLeave={this.onMouseLeave}
        >
          <div styleName="columnDragHandle">
            <i className="material-icons" styleName="columnDragHandleIcon">
              drag_handle
            </i>
          </div>
          {this.props.toggleColumn && (
            <div styleName="columnCheckbox">
              <input
                className="styled"
                type="checkbox"
                styleName="checkbox"
                value={column.id}
                id={column.id}
                defaultChecked={column.visible}
                onChange={() => {
                  this.props.toggleColumn(column.id, !column.visible);
                }}
                disabled={column.is_always_visible}
              />
            </div>
          )}
          <div styleName="columnTitle">
            <div styleName="columnTitleInner">
              <span>{column.name}</span>
              {column.original_name && (
                <span styleName="original_name">({column.original_name})</span>
              )}
            </div>
            {lock}
          </div>
          <div style={{ flex: 1 }} />
          {options}
        </label>
      )
    );
  }
}

ButtonEditColumnsRow.propTypes = {
  column: PropTypes.object.isRequired,
  toggleColumn: PropTypes.func,
  showEditColumnModal: PropTypes.func,
  showDeleteColumnModal: PropTypes.func,
  index: PropTypes.number.isRequired,
  moveRow: PropTypes.func.isRequired
};

export default ButtonEditColumnsRow;
