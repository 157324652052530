import PropTypes from "prop-types";
import React, { Component } from "react";
import ClassNames from "classnames";

const iconStyle = {
  position: "absolute",
  top: 10,
  right: 10,
  cursor: "pointer",
  color: "rgba(255, 255, 255, 0.4)"
};

class DefaultWrapper extends Component {
  getModalClasses(content) {
    // build classes
    let modalClasses = {
      "modal-wrapper": true,
      "modal-visible": true
    };
    let modalClass;

    if (content.type && content.type.getModalClassName) {
      modalClass = content.type.getModalClassName();
    } else {
      modalClass = content.type.displayName
        ? content.type.displayName
        : content.type.name;

      if (modalClass.includes("OptionsModal")) {
        modalClass = "OptionsModal";
      }
    }
    modalClass = modalClass.replace(/([a-z])([A-Z])/g, "$1-$2").toLowerCase();
    modalClasses[modalClass] = true;
    modalClasses = ClassNames(modalClasses);

    return modalClasses;
  }

  render() {
    return (
      <div className={this.getModalClasses(this.props.modal.content)}>
        <div className="modal-inner">
          <div className="modal-content" onMouseDown={e => e.stopPropagation()}>
            {this.props.children}
          </div>
          <i
            className="material-icons"
            onClick={this.props.hideModal}
            style={iconStyle}
          >
            close
          </i>
        </div>
      </div>
    );
  }
}

DefaultWrapper.propTypes = {
  modal: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default DefaultWrapper;
