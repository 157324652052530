import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";

import { getMonthEvents } from "../selectors";

import {
  actions as TimeTableActions,
  getters as TimeTableGetters
} from "ui-kit/TimeTable/model";

import { Div, LeftIcon, RightIcon } from "components/Base";
import MonthCalendar from "ui-kit/MonthCalendar";
import CSSModules from "react-css-modules";

import { noop } from "utils/General";

import styles from "./monthCalendarStyles.scss";

const decorate = R.compose(
  connect(
    (state, props) => ({
      currentMonthDate: TimeTableGetters.currentMonthDate(state, props),
      events: getMonthEvents(state, props)
    }),
    {
      onDayClick: TimeTableActions.jump,
      prev: TimeTableActions.prevMonth,
      next: TimeTableActions.nextMonth
    }
  ),
  CSSModules(styles)
);

const customRender = date => date.format("MMMM YYYY");

const Calendar = ({
  currentMonthDate,
  events = [],
  next = noop,
  prev = noop,
  onDayClick = noop,
  closePopover = noop
}) => (
  <Div display="row.center.stretch" styleName="calendar" bra={1}>
    <div styleName="arrow-container-left" onClick={prev}>
      <LeftIcon color="black" size={26} />
    </div>
    <MonthCalendar
      year={moment(currentMonthDate).year()}
      customMonthRender={customRender}
      showCurrentDay
      month={moment(currentMonthDate).month()}
      events={events}
      onClick={({ day }) => {
        onDayClick(day);
        closePopover();
      }}
    />
    <div styleName="arrow-container-right" onClick={next}>
      <RightIcon color="black" size={26} />
    </div>
  </Div>
);

export default decorate(Calendar);
