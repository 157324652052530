import React from "react";
import { Div } from "components/Base";
import Panel from "./Panel";
import CurrentCourse from "./CurrentCourse";
import CoursesList from "./CoursesList";
import Header from "./Header";
import CSSModules from "react-css-modules";

import css from "./styles.scss";

const Body = () => (
  <Panel styleProps={{ marginBottom: 23 }} styleName="portal-page-courses">
    <Header />
    <Div styleName="main-section">
      <Div styleName="courses">
        <CoursesList />
      </Div>
      <Div styleName="course">
        <CurrentCourse />
      </Div>
    </Div>
  </Panel>
);

export default CSSModules(Body, css);
