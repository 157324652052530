import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import { actions } from "../index";

import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { MediumFilledButton } from "components/Base";

const decorate = R.compose(
  connect(
    null,
    {
      showAddRecordModal: actions.showAddRecordModal
    }
  ),
  CSSModules(css)
);

const AddButton = ({ showAddRecordModal, ...styleProps }) => (
  <MediumFilledButton
    mr={2}
    onClick={showAddRecordModal}
    className={css.actionButton}
    {...styleProps}
  >
    <span className="material-icons-outlined">add</span>
    Add Activity
  </MediumFilledButton>
);

export default decorate(AddButton);
