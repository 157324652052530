import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";
import { isMasterSchedule, schedulesList } from "Schedules/Schedule/selectors";
import { currentFields } from "../selectors";
import { getInput } from "./Editors";

import {
  Div,
  SuperFilledButton,
  Text1,
  Text3,
  Dropdown,
  ExpandIcon,
  SuperOutlineButton
} from "components/Base";

import { eventDetails } from "redux/modules/event/selectors";

import { getters, actions } from "Schedules/AddActivityRow";
import {
  getters as scheduleGetters,
  actions as scheduleActions
} from "Schedules/Schedule";

import { getters as validatorGetters } from "ui-kit/Validator/model";
import { ErrorMark } from "ui-kit/Validator/View";

import styles from "./styles.scss";

const Label = ({ children, required, error }) => (
  <Div
    display="row.flex-start.center"
    fs={1}
    uppercase
    mb={1}
    fw={3}
    mr={3}
    height={20}
  >
    <Div>{children}</Div>
    {required ? (
      <Div ml={1} color="red7">
        *
      </Div>
    ) : null}
    <Div ml={1}>{error}</Div>
  </Div>
);

const FieldWrapper = ({ children }) => <Div mr={1}>{children}</Div>;

const renderSaveButton = () => (
  <SuperFilledButton m={1} bg="warning7" px={2}>
    <Div display="row.space-between.center">
      <Div mr={2}>
        <i className="material-icons">add</i>
      </Div>
      <Div display="column">
        <Text3 color="white" m={0} bold>
          Add to Schedule
        </Text3>
        <Div display="row.flex-start.center">
          <i
            className="material-icons"
            style={{ fontSize: 15, marginRight: 5 }}
          >
            keyboard_return
          </i>
          <Text1 color="white" m={0}>
            Enter to submit
          </Text1>
        </Div>
      </Div>
    </Div>
  </SuperFilledButton>
);

const decorate = connect(
  (state, props) => ({
    values: getters.values(state, props),
    eventDetails: eventDetails(state),
    isMasterSchedule: isMasterSchedule(state),
    currentFields: currentFields(state),
    schedulesList: schedulesList(state),
    moduleId: getters.moduleId(state, props),
    errors: validatorGetters.errors(state, props),
    quickAddFields: scheduleGetters.quickAddFields(state, props)
  }),
  {
    saveRow: actions.setSaveRow,
    onChange: actions.onChange,
    init: actions.init,
    setModuleId: actions.setModuleId,
    showAddRecordModal: scheduleActions.showAddRecordModal
  }
);

const AddActivityRow = ({
  onChange,
  init,
  values,
  saveRow,
  eventDetails,
  isMasterSchedule,
  currentFields,
  schedulesList,
  moduleId,
  setModuleId,
  quickAddFields,
  errors,
  showAddRecordModal
}) => {
  useEffect(() => {
    if (R.not(isMasterSchedule)) {
      init(quickAddFields);
    }
  }, []);

  useEffect(() => {
    if (isMasterSchedule) {
      if (R.length(schedulesList) >= 1 && R.isEmpty(moduleId)) {
        setModuleId(R.head(schedulesList));
      }
      init(currentFields);
    }
  }, [moduleId]);

  const fieldsToPass = R.map(field => {
    const { id, name, is_required: isRequired, order, value, type } = field;
    const editorProps = resolveEditorProps(field, eventDetails);

    if (type === "datetime") {
      editorProps.format = R.path(["settings", "format"], field);
      editorProps.timezone = R.prop("timezone", eventDetails);
    }

    return {
      id,
      name,
      isRequired,
      Editor: getInput(type),
      editorProps,
      value,
      order,
      onChange: data => onChange(data, { fieldId: field.id })
    };
  })(values);

  const renderInputs = R.compose(
    R.map(
      ({
        id,
        Editor,
        name,
        isRequired,
        value,
        onChange: changeInput,
        editorProps
      }) => (
        <Div
          key={id}
          bc="neutral2"
          p={1}
          pr={2}
          bl={0}
          br={1}
          color={R.pathOr(true, [id, "valid"], errors) ? "" : "danger7"}
        >
          <FieldWrapper>
            <Label required={isRequired} error={<ErrorMark {...errors[id]} />}>
              {name}
            </Label>
            <Editor {...editorProps} onChange={changeInput} value={value} />
          </FieldWrapper>
        </Div>
      )
    ),
    R.sortWith([R.ascend(R.prop("order"))]),
    R.values
  );

  const handleFormSubmit = event => {
    event.preventDefault();
    saveRow();
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <Div display="row.space-between.center" px={2} bra={1} bg="white" mb={2}>
        <Div display="row.space-between.center">
          {isMasterSchedule && (
            <Div br={2} bc="neutral2" p={1} pr={2}>
              <FieldWrapper>
                <Label required={true}>Schedule</Label>
                <Dropdown
                  usePortal
                  width="150px"
                  clearable={true}
                  className={styles.fix}
                  selected={moduleId.value}
                  onChange={setModuleId}
                  options={schedulesList}
                />
              </FieldWrapper>
            </Div>
          )}
          {(R.not(isMasterSchedule) || moduleId) && renderInputs(fieldsToPass)}
        </Div>
        <Div display="row.center.center">
          <SuperOutlineButton
            type="button"
            m={1}
            bg="neutral0"
            OnlyIcon={ExpandIcon}
            onClick={showAddRecordModal}
          />
          {renderSaveButton()}
        </Div>
      </Div>
    </form>
  );
};

export default decorate(AddActivityRow);
