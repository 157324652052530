import Helpers from "utils/Global/Helpers";

module.exports = {
  all: (credentials, orgId, eventId) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: orgId
          ? `${window.__LENND_API_BASE_URL__}/organizations/${orgId}/email-templates`
          : `${window.__LENND_API_BASE_URL__}/event/${eventId}/email-templates`,
        data: {},
        credentials,
        success: result => {
          resolve(result);
        },
        error: error => {
          reject(error);
        }
      };
      /* eslint-enable no-underscore-dangle */

      Helpers.request(options);
    }),

  post: (credentials, eventId, template) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/event/${eventId}/email-templates`,
        data: template,
        credentials,
        success: result => {
          resolve(result);
        },
        error: error => {
          reject(error);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  put: (credentials, eventId, template) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/event/${eventId}/email-templates/${template.id}`,
        data: template,
        credentials,
        success: result => {
          resolve(result);
        },
        error: error => {
          reject(error);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  delete: (credentials, eventId, templateId) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/event/${eventId}/email-templates/${templateId}`,
        data: {},
        credentials,
        success: result => {
          resolve(result);
        },
        error: error => {
          reject(error);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
