import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Controller from "./Controller";

import { eventId, eventDetails } from "redux/modules/event/selectors";
import { user, getCredentials } from "redux/modules/user/selectors";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";
import { sourcesByTypeId } from "redux/modules/orders/sources/selectors";
import { addRecord } from "redux/modules/modules/records/actions";

function mapStateToProps(state, props) {
  return {
    eventId: eventId(state),
    eventSlug: eventDetails(state).slug,
    userId: user(state).id,
    userCredentials: getCredentials(state),
    formMeta:
      sourcesByTypeId(state, CREDENTIAL_TYPE_ID).find(
        f => f.id === props.params.sourceId
      ) || {}
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal,
      hideModal,
      showSnackbar,
      addRecord
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
