import { createSelector } from "reselect";

import * as R from "ramda";
import {
  PEOPLE_FILTERS,
  NAMESPACE,
  CONTACTS_TABLE_INSTANCE_ID,
  FILTER
} from "./constants";

import { getters } from "Portal/PortalPeople/model";
import { getters as SearchBarGetters } from "ui-kit/SearchBar";
import { getters as TabsGetters } from "Portal/Tabs";
import { settings as getSettings } from "redux/modules/portal/settings/selectors";
import isFormLocked from "components/Event/FormsV2/Utils/is-form-locked";

export const userList = createSelector(
  getters.data,
  getters.filterby,
  getters.eventId,
  state =>
    SearchBarGetters.searchTerm(state, {
      instanceId: NAMESPACE
    }),
  (data, filter, eventId, seachQuery) => {
    if (filter === PEOPLE_FILTERS.ALL) {
      return R.filter(
        contacts => R.toLower(contacts.name).includes(R.toLower(seachQuery)),
        data.contacts
      );
    }
    return R.compose(
      R.head,
      R.map(
        R.evolve({
          types: R.compose(
            R.reject(R.propSatisfies(R.isEmpty, "contact_ids")),
            R.map(
              R.evolve({
                contact_ids: R.compose(
                  R.filter(
                    R.propSatisfies(
                      name => R.toLower(name).includes(R.toLower(seachQuery)),
                      "name"
                    )
                  ),
                  R.map(e => R.find(R.propEq("id", e))(data.contacts))
                )
              })
            )
          )
        })
      ),
      R.filter(R.propEq("id", eventId)),
      R.propOr([], "events")
    )(data);
  }
);

export const eventList = createSelector(
  getters.data,
  R.propOr([], "events")
);

export const currentEventName = createSelector(
  eventList,
  getters.eventId,
  (events, eventId) =>
    R.compose(
      R.prop("name"),
      R.find(R.propEq("id", eventId))
    )(events)
);

export const getTableIds = createSelector(
  userList,
  getters.filterby,
  TabsGetters.selectedTab,
  (users, filter, tab) => {
    if (tab === FILTER.PREVIOUS_EVENTS) {
      if (filter === PEOPLE_FILTERS.BY_EVENT) {
        return R.map(R.prop("id"))(users.types);
      }
      return [CONTACTS_TABLE_INSTANCE_ID];
    }
  }
);

export const getShowEmptyState = createSelector(
  getters.loading,
  getters.data,
  (loading, data) => {
    if (!loading && (!data.contacts && !data.contacts.length)) {
      return true;
    }
    return false;
  }
);

export const getPeopleTypes = createSelector(
  getters.selectedTypeId,
  getters.data,
  (selectedTypeId, data) => {
    return R.map(t => {
      const usesSpotsLeft =
        t.number_of_people_limit === null
          ? false
          : isNaN(t.number_of_people_limit)
          ? false
          : true;

      return {
        id: t.id,
        selected: selectedTypeId === t.id,
        categoryName: t.name,
        usesSpotsLeft,
        spotsLeft: usesSpotsLeft
          ? t.number_of_people_limit - t.contacts.length
          : 0,
        canAdd: true
      };
    })(data.types);
  }
);

export const canAddPeople = createSelector(
  getSettings,
  getters.data,
  (settings, data) => {
    return data.types.length > 0 && !isFormLocked(settings);
  }
);

export const getTabs = createSelector(
  canAddPeople,
  getters.tabs,
  (canAddPeople, tabs) => {
    if (!canAddPeople) {
      return tabs.filter(t => t.id !== FILTER.PREVIOUS_EVENTS);
    }
    return tabs;
  }
);

export const isValid = createSelector(
  getters.selectedTypeId,
  getters.selectedIds,
  (selectedTypeId, selectedIds) => {
    return selectedTypeId && selectedIds.length;
  }
);
