import React, { Component } from "react";
import PropTypes from "prop-types";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import EmailSection from "components/Event/FormsV2/Modals/FormSendModal/Email";
import LinkSection from "components/Event/FormsV2/Modals/FormSendModal/Link";

@CSSModules(css)
class FormSendModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inviteVia: "email",
      emails: props.emails || [],
      subject: "",
      message: "",
      sendToMe: false
    };
  }

  inviteViaEmail = () => {
    this.setState({
      inviteVia: "email"
    });
  };

  inviteViaLink = () => {
    this.setState({
      inviteVia: "link"
    });
  };

  handleEmailsChange = emails => {
    // Helpers.isValidEmail(lastEmail)
    this.setState({ emails });
  };

  handleSubjectChange = e => {
    this.setState({
      subject: e.target.value
    });
  };

  handleMessageChange = e => {
    this.setState({
      message: e.target.value
    });
  };

  handleSendToMeChange = () => {
    this.setState({
      sendToMe: !this.state.sendToMe
    });
  };

  handleSend = () => {
    this.props
      .sendInvite({
        eventId: this.props.eventDetails.id,
        formId: this.props.formId,
        emails: this.state.emails,
        message: this.state.message,
        sendToMe: this.state.sendToMe
      })
      .then(() => {
        this.props.hideModal();
        this.props.handleFormSent();
      });
  };

  render() {
    let content;
    if (this.state.inviteVia === "email") {
      content = (
        <EmailSection
          hideModal={this.props.hideModal}
          handleEmailsChange={this.handleEmailsChange}
          handleMessageChange={this.handleMessageChange}
          handleSendToMeChange={this.handleSendToMeChange}
          handleSend={this.handleSend}
          emails={this.state.emails}
          message={this.state.message}
          sendToMe={this.state.sendToMe}
        />
      );
    } else {
      content = (
        <LinkSection
          formId={this.props.formId}
          eventSlug={this.props.eventDetails.slug}
          formSlug={this.props.formSlug}
          hideModal={this.props.hideModal}
        />
      );
    }

    return (
      <div>
        <div className="modal-header">
          <div className="modal-title">Send your form</div>
        </div>

        <div
          className="modal-body"
          style={{
            padding: 0,
            width: 600
          }}
        >
          <div className="modal-body-wrapper">
            {/* row: top */}
            <div styleName="rowTop">
              {/* row: form status */}
              {/*
              <div styleName="rowFormStatus}>
                <div style={[css.rowFormStatusColumn, css.rowFormStatusColumnLeft]}>
                  <div styleName="rowFormStatusBig}>Leave form open until manually closed</div>
                  <div styleName="rowFormStatusSmall}>You can close the form whenver you’d like from Form Overview</div>
                </div>
                <div style={{flex: 1}}/>
                <div style={[css.rowFormStatusColumn, css.rowFormStatusColumnRight]}>
                  <div styleName="rowFormStatusBig}>Or set auto close date</div>
                  <div>Pick Date</div>
                </div>
              </div>
              */}

              {/* row: send via */}
              <div styleName="rowSendVia">
                <div styleName="rowSendViaTitle">Send Via:</div>
                <div styleName="toggleButtonTrack">
                  <div
                    styleName={
                      this.state.inviteVia === "email"
                        ? "toggleButtonActive"
                        : "toggleButton"
                    }
                    onClick={this.inviteViaEmail}
                  >
                    <i className="material-icons" styleName="toggleButtonIcon">
                      &#xE0BE;
                    </i>
                    <span>Email</span>
                  </div>
                  <div
                    styleName={
                      this.state.inviteVia === "link"
                        ? "toggleButtonActive"
                        : "toggleButton"
                    }
                    onClick={this.inviteViaLink}
                  >
                    <i className="material-icons" styleName="toggleButtonIcon">
                      &#xE157;
                    </i>
                    <span>Link</span>
                  </div>
                </div>
              </div>
            </div>

            {/* row: content */}
            {content}
          </div>
        </div>
      </div>
    );
  }
}

FormSendModal.propTypes = {
  eventDetails: PropTypes.shape({
    id: PropTypes.number,
    slug: PropTypes.string
  }).isRequired,
  form: PropTypes.shape({
    id: PropTypes.string,
    slug: PropTypes.string
  }).isRequired,
  hideModal: PropTypes.func.isRequired,
  handleFormSent: PropTypes.func.isRequired,
  emails: PropTypes.array,
  sendInvite: PropTypes.func.isRequired
};

export default FormSendModal;
