import React from "react";

import { Div, LeftIcon, ListIcon } from "components/Base";
import ActivityLogs from "Event/ActivityLogs/View";
import { WIDTH } from "../constants";
import { withRouter } from "react-router";

const Body = ({ router, params }) => (
  <Div style={{ marginTop: -100 }} display="column.flex-start.center">
    <Div width={WIDTH}>
      <Div display="column" mb={4}>
        <Div
          onClick={() =>
            router.push(`/event-light/${params.eventId}/dashboard`)
          }
          color="primary3"
          fs={2}
          underline
          display="row.flex-start.center"
          mb={2}
        >
          <LeftIcon color="primary3" size={15} />
          Event Home
        </Div>
        <Div color="white" fw={3} fs={7} display="row.flex-start.center">
          <Div display="row.center.center" pill bg="primary7" size={40} mr={3}>
            <ListIcon color="primary3" />
          </Div>
          Activity Log
        </Div>
      </Div>

      <Div display="column.flex-start.center">
        <ActivityLogs />
      </Div>
    </Div>
  </Div>
);

export default withRouter(Body);
