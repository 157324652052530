import React, { useMemo } from "react";
import { Div } from "components/Base";

const styles = {
  radius: 5,
  height: 13
};

function getColorForPercentage(percentage) {
  const percentDecimal = percentage / 100;
  let red = 255;
  let green = 255;
  if (percentDecimal >= 0 && percentDecimal <= 0.5) {
    green = 510 * percentDecimal;
  } else if (percentDecimal > 0.5 && percentDecimal <= 1) {
    red = -510 * percentDecimal + 510;
  }

  return "rgb(" + [red, green, 0].join(",") + ", .7)";
}

export const ProgressBar = ({ progress }) => {
  const color = useMemo(() => getColorForPercentage(progress), [progress]);

  return (
    <Div
      width={1}
      bg="neutral4"
      style={{
        borderRadius: styles.radius,
        height: styles.height
      }}
    >
      <Div
        style={{
          width: `${progress}%`,
          backgroundColor: color,
          borderRadius: styles.radius,
          height: styles.height
        }}
      />
    </Div>
  );
};

export default ProgressBar;
