import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "EventLight/Agenda/AddSessionModal/model";
import { Div, RightIcon, CheckIcon } from "components/Base";
import Circle from "ui-kit/Circle";
import { TABS } from "EventLight/Agenda/AddSessionModal/constants";

const decorate = connect(
  state => ({
    activeTab: getters.activeTab(state)
  }),
  {
    setActiveTab: actions.setActiveTab
  }
);

const Stepper = ({ activeTab }) => (
  <Div display="row.flex-start.center">
    <Circle
      size={25}
      style={
        activeTab === TABS.BASIC_DETAILS
          ? { boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.17)" }
          : {}
      }
      bg={activeTab === TABS.BASIC_DETAILS ? "white" : "neutral3"}
    >
      {activeTab === TABS.BASIC_DETAILS ? (
        <Circle size={13} bg="primary7" />
      ) : (
        <CheckIcon color="white" size={16} />
      )}
    </Circle>
    <Div
      color={activeTab === TABS.BASIC_DETAILS ? "primary7" : "neutral4"}
      ml={2}
      fw={3}
      fs={4}
    >
      Basic Details
    </Div>
    <RightIcon size={24} mx={4} color="neutral3" />
    <Circle
      size={25}
      style={
        activeTab === TABS.ATTENDEE_SETTINGS
          ? { boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.17)" }
          : {}
      }
      bg={activeTab === TABS.ATTENDEE_SETTINGS ? "white" : "neutral3"}
    >
      <>
        {activeTab === TABS.ATTENDEE_SETTINGS ? (
          <Circle size={13} bg="primary7" />
        ) : null}
        {activeTab === TABS.BASIC_DETAILS ? (
          <Div color="neutral5" fs={1} fw={3}>
            2
          </Div>
        ) : null}
        {activeTab === TABS.CONTENT ? (
          <CheckIcon color="white" size={16} />
        ) : null}
      </>
    </Circle>
    <Div
      color={activeTab === TABS.ATTENDEE_SETTINGS ? "primary7" : "neutral4"}
      ml={2}
      fw={3}
      fs={4}
    >
      Attendee Settings
    </Div>
    <RightIcon size={24} mx={4} color="neutral3" />
    <Circle
      size={25}
      style={
        activeTab === TABS.CONTENT
          ? { boxShadow: "0px 1px 5px rgba(0, 0, 0, 0.17)" }
          : {}
      }
      bg={activeTab === TABS.CONTENT ? "white" : "neutral3"}
    >
      {activeTab === TABS.CONTENT ? (
        <Circle size={13} bg="primary7" />
      ) : (
        <Div color="neutral5" fs={1} fw={3}>
          3
        </Div>
      )}
    </Circle>
    <Div
      color={activeTab === TABS.CONTENT ? "primary7" : "neutral4"}
      ml={2}
      fw={3}
      fs={4}
    >
      Content
    </Div>
  </Div>
);

export default decorate(Stepper);
