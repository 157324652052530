import React, { Component } from "react";
import * as R from "ramda";
import getValue from "utils/value-types/get-value/multiple_select";
import { Div } from "components/Base";

import { getSettings } from "ui-kit/Datagrid/utils";

class MultipleSelectCellFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props.value, nextProps.value);
  }

  formatValue(props) {
    return getValue(props.value);
  }

  render() {
    const props = this.props;
    const options = getSettings(props, "options");
    const value = this.formatValue(this.props);

    const records = R.propOr([], "records", value)
      .filter(v => v)
      .sort((a, b) =>
        a.value.toLowerCase().localeCompare(b.value.toLowerCase())
      );

    return (
      <Div
        display="row.flex-start.center"
        style={{
          userSelect: "none",
          overflow: "hidden"
        }}
      >
        {records.map((record, index) => {
          if (record.value) {
            const selectedOption = R.find(
              R.compose(
                R.equals(record.value),
                R.prop("value")
              ),
              options
            );
            return (
              <Div
                key={index}
                display="row.flex-start.center"
                mr={2}
                style={{
                  borderRadius: 25,
                  fontSize: 12,
                  padding: "4px 8px",
                  lineHeight: "normal",
                  whiteSpace: "nowrap",
                  flexBasis: "auto",
                  flexShrink: 0,
                  backgroundColor: R.propOr(
                    "#EBEBEB",
                    "backgroundColor",
                    selectedOption
                  ),
                  color: R.propOr("#494949", "textColor", selectedOption)
                }}
              >
                {record.value}
              </Div>
            );
          } else {
            return null;
          }
        })}
      </Div>
    );
  }
}

export default MultipleSelectCellFormatter;
