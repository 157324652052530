import React from "react";

import { SEARCH_BAR_INSTANCE } from "ui-kit/MiniItemsSelector/constants";
import { VARIANTS } from "ui-kit/Theme/constants";

import VariantsList from "./VariantList";
import AllNone from "./AllNone";
import SelectedSummary from "./SelectedSummary";

import { Div } from "components/Base";

import SearchBar from "ui-kit/SearchBar/View";

const Body = ({ groups = [], instanceId }) => (
  <Div
    display="row.space-between"
    flex={1}
    height={1}
    style={{ overflowY: "hidden" }}
  >
    <Div
      pt={2}
      pr={2}
      pl={4}
      display="column"
      flex={1}
      style={{
        width: "60%"
      }}
    >
      <AllNone instanceId={instanceId} groups={groups} />
      <Div my={2} display="row.flex-start.center">
        <SearchBar
          ba={0}
          bra={1}
          variant={VARIANTS.SURFACE}
          style={{ flexShrink: 0 }}
          mb={2}
          width={1}
          instanceId={instanceId || SEARCH_BAR_INSTANCE}
        />
        {/* @TODO: connect create new button*/}
      </Div>
      <VariantsList groups={groups} maxHeight={250} instanceId={instanceId} />
    </Div>
    <SelectedSummary groups={groups} instanceId={instanceId} />
  </Div>
);

export default Body;
