import React, { useState } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import { getters, actions } from "EventLight/ProductionSchedules/Schedule";

import Body from "components/Global/Modal/Layout/ScrollableBody";

import { Div, MediumOutlineInput, Text3, SmallToggle } from "components/Base";

import {
  ButtonGroup,
  Submit,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";

import { noop } from "utils/General";

const decorate = R.compose(
  connect(
    (state, props) => ({
      schedule: getters.schedule(state, props),
      isLoading: getters.isEditScheduleLoading(state, props),
      showModal: getters.isShowingEditScheduleModal(state, props)
    }),
    {
      hideModal: () => actions.setIsShowingEditScheduleModal(false),
      setIsLoading: isItLoading =>
        actions.setIsEditScheduleLoading(isItLoading),
      updateSchedule: actions.updateSchedule
    }
  )
);

function EditScheduleModal({
  isLoading = false,
  showModal = false,
  hideModal = noop,
  setIsLoading = noop,
  schedule = {},
  updateSchedule = noop
}) {
  // Copy the Redux store's data to local scope in order to:
  //   - Improve  performance by not changing without need anywhere else in the store
  //   - Avoid side-effects/UI glitches due to data-binding
  const [scheduleName, setScheduleName] = useState(schedule.name);
  const [showOnPortals, setShowOnPortals] = useState(schedule.show_on_portals);
  const [showOnProfiles, setShowOnProfiles] = useState(
    schedule.show_on_profiles
  );

  const handleUpdateSchedule = () => {
    setIsLoading(true);

    updateSchedule({
      scheduleName,
      showOnPortals,
      showOnProfiles
    });
  };

  return (
    <MiniModalWrapper
      showModal={showModal}
      hideModal={hideModal}
      title="Edit Schedule"
      style={{ width: 600, height: 400 }}
      isPortal={false}
      bg="white"
      stopPropagation
    >
      <Body style={{ height: 438, maxHeight: 438 }}>
        <Div display="column" bg="white" mt={6} my={3} mx={15}>
          <Div display="column.space-between.flex-start" mx={5} mb={6}>
            <Div display="column" mr={3} width={0.7} bg="white">
              <Text3 bold color="neutral8">
                Schedule Name
              </Text3>
            </Div>
            <Div display="column.center.flex-start" width={0.7}>
              <MediumOutlineInput
                continuous
                type="text"
                value={schedule.name}
                name="name"
                onChange={setScheduleName}
                autoFocus
                width={1}
              />
            </Div>
          </Div>
          <Div display="row.flex-start.center" mx={5} mb={6}>
            <Div display="column.center.flex-start" width={0.2}>
              <SmallToggle
                active={showOnPortals}
                onClick={() => setShowOnPortals(!showOnPortals)}
              />
            </Div>
            <Div display="column" mr={3} width={0.5}>
              <Text3 bold color="neutral8">
                Show assigned activities on portals?
              </Text3>
            </Div>
          </Div>
          <Div display="row.flex-start.center" mx={5} mb={6}>
            <Div display="column.center.flex-start" width={0.2}>
              <SmallToggle
                active={showOnProfiles}
                onClick={() => setShowOnProfiles(!showOnProfiles)}
              />
            </Div>
            <Div display="column" mr={3} width={0.5}>
              <Text3 bold color="neutral8">
                Show assigned activities on profiles?
              </Text3>
            </Div>
          </Div>
        </Div>
      </Body>
      <Div display="row.flex-start.center" bg="white" mx={15} bt={1} bc="gray1">
        <ButtonGroup>
          <Submit
            title={isLoading ? "Editing..." : "Edit Schedule"}
            disabled={!schedule.name || isLoading}
            onClick={handleUpdateSchedule}
          />
          <ButtonOutline title="Cancel" onClick={hideModal} />
        </ButtonGroup>
      </Div>
    </MiniModalWrapper>
  );
}

export default decorate(EditScheduleModal);
