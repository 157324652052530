import { REQUEST, RECEIVE } from "./constants";
import * as selectors from "./selectors";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getEventPermissionProfiles(eventId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const { payload } = await api.get(
        getState().user.user.credentials,
        eventId
      );
      dispatch({ type: RECEIVE, payload });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching event permission profiles"
          }
        ])
      );
    }
  };
}

export function getOrganizationPermissionProfiles(orgId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const { payload } = await api.getOrganization(
        getState().user.user.credentials,
        orgId
      );
      dispatch({ type: RECEIVE, payload });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching organization permission profiles"
          }
        ])
      );
    }
  };
}

export function addEventPermissionProfile(data) {
  return async (dispatch, getState) => {
    try {
      const { payload } = await api.post(
        getState().user.user.credentials,
        data
      );
      return payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding event permission profile"
          }
        ])
      );
    }
  };
}

export function addOrganizationPermissionProfile(data) {
  return async (dispatch, getState) => {
    try {
      const { payload } = await api.postOrganization(
        getState().user.user.credentials,
        data
      );
      return payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding organization permission profile"
          }
        ])
      );
    }
  };
}

export function updatePermissionProfile(data) {
  return async (dispatch, getState) => {
    try {
      const { payload } = await api.put(getState().user.user.credentials, data);
      return payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating permission profile"
          }
        ])
      );
    }
  };
}

export function updatePermissionProfilePermissions(data) {
  return async (dispatch, getState) => {
    try {
      const { payload } = await api.putPermissions(
        getState().user.user.credentials,
        data
      );
      return payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating permission profile permissions"
          }
        ])
      );
    }
  };
}

export function deletePermissionProfile(data) {
  return async (dispatch, getState) => {
    try {
      const { payload } = await api.delete(
        getState().user.user.credentials,
        data
      );
      return payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting permission profile"
          }
        ])
      );
    }
  };
}
