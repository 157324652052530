import { ADD_MODULES } from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getModulesWithCatalogItems(eventId) {
  return async (dispatch, getState) => {
    try {
      const result = await api.get(
        getState().user.user.credentials,
        eventId,
        "catalog-item"
      );
      dispatch({
        type: ADD_MODULES,
        payload: result.payload
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching sub-modules"
          }
        ])
      );
    }
  };
}
