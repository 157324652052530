import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";
import { withRouter } from "react-router";

import { getIntegrations } from "redux/modules/credentials/integrations/actions";
import {
  getTicketingProviders,
  getTicketingProviderConfigurations,
  syncTicketTypes,
  getAuth1stStep
} from "redux/modules/ticketing/providers/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { hideModal, showModal } from "redux/modules/modal/actions";
import EventbriteActions from "redux/modules/integrations/eventbrite/actions";
import { deleteItemProviderId } from "redux/modules/items/items/actions";

import { integrations } from "redux/modules/credentials/integrations/selectors";
import {
  ticketingProviders,
  ticketingProviderConfigurations,
  ticketTypes
} from "redux/modules/ticketing/providers/selectors";
import { eventDetails } from "redux/modules/event/selectors";

function mapStateToProps(state) {
  return {
    eventDetails: eventDetails(state),
    integrations: integrations(state),
    ticketingProviders: ticketingProviders(state),
    ticketTypes: ticketTypes(state),
    ticketingProviderConfigurations: ticketingProviderConfigurations(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSnackbar,
      showModal,
      hideModal,
      getIntegrations,
      syncEventbriteTicketClasses: EventbriteActions.syncTicketClasses,
      getTicketingProviders,
      getTicketingProviderConfigurations,
      syncTicketTypes,
      getAuth1stStep,
      deleteItemProviderId
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
