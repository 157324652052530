import { ADD_REFERENCE_VALUE, RECEIVE_VALUES } from "./constants";
import { get, set } from "lodash";
import Immutable from "immutable";

const formatValues = values =>
  Object.keys(values).reduce((state, key) => {
    const value = values[key];
    const path = [value.record_id, value.destination_field_id];
    const currentValues = get(state, path, []);
    set(state, path, [value, ...currentValues]);
    return state;
  }, {});

export default function(state = {}, action) {
  switch (action.type) {
    case RECEIVE_VALUES:
      return formatValues(action.payload);
    case ADD_REFERENCE_VALUE: {
      const { referenceId, destinationField, value } = action.payload;
      const path = [referenceId, destinationField];
      const currentValues = get(state, path, []);
      const newState = Immutable.fromJS(state)
        .setIn(path, [{ value }, ...currentValues])
        .toJS();
      return newState;
    }
    default:
      return state;
  }
}
