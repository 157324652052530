import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  loadingPermissionSet: false,
  loadingRecordTypes: false,
  loadingGuestList: false,
  loadingPersonDocuments: false,
  loadingPersonForms: false,
  loadingPersonAutoAssignItems: false,
  loadingPersonRequestItems: false,
  permissionSet: {
    title: null,
    message: null,
    hub_background_image_url: null
  },
  guestListSettings: {
    visible_fields: [],
    required_fields: [],
    field_order: {}
  },
  showAssignModal: false,
  showUpdateAssignFormModal: false,
  showSelectRequiredFieldsModal: false,
  showPeopleTypesModal: false,
  showSelectRequestableItemsModal: false,
  showSelectAutoAssignItemsModal: false,
  showUpdatePersonItemModal: false,
  showUpdatePersonAutoAssignItemModal: false,
  showGuestListSettingsModal: true,
  showSelectGuestListItemsModal: false,
  showPageModal: false,
  showOpenTaskModal: false,
  subtypes: [],
  selectedPermissionSetId: "",
  permissionsSets: [],
  moduleId: "",
  assignedForms: [],
  assignedTasks: [],
  forms: [],
  tasks: [],
  selectedTypeId: "",
  selectedModalId: "",
  documentRequests: [],
  assignedDocumentRequests: [],
  assignModalType: "",
  assignedSharedFiles: [],
  selectedSharedFileId: "",
  uploadedFileData: null,
  recordTypes: [],
  contactFields: [],
  itemsTypes: [],
  selectedModalTypeId: "",
  collapsablePeopleCards: {},
  selectedModalPersonId: "",
  selectedModalPersonItemId: "",
  assignedPersonFormIds: {},
  selectedPersonSubCard: "",
  assignedPages: [],
  pages: [],
  assignedAutoItems: [],
  assignedSellItems: []
};

const reducers = {
  init: R.identity,
  getPermissionSets: R.identity,
  getStaffPersonnel: R.identity,
  getGuestListDetails: R.identity,
  updateRequestableItems: R.identity,
  onSelectRequestablePersonItems: R.identity,
  onSelectAutoAssignItems: R.identity,
  onSelectGuestListItems: R.identity,
  openUpdateSellItemModal: R.identity,
  removeSelectedItem: R.identity,
  removeSelectedAutoAssignItem: R.identity,
  removePersonForm: R.identity,
  removePersonDocument: R.identity,
  updateModalData: R.identity,
  updateSharedFile: R.identity,
  reorderPersonDocuments: R.identity,
  reorderPersonForms: R.identity,
  openSelectRequiredFieldsModal: R.identity,
  updateEnableSelectFields: R.identity,
  updateEnabledPeopleTypes: R.identity,
  updateCloseDate: R.identity,
  updatePersonItemData: R.identity,
  updatePersonAutoAssignItemData: R.identity,
  updateAssignedPersonForms: R.identity,
  updateAssignedPersonDocuments: R.identity,
  togglePersonForm: R.identity,
  updatePersonAssginedForm: R.identity,
  updatePersonAssginedDocumentRequest: R.identity,
  updateGuestListSettings: R.identity,
  updateAutoAssignedItems: R.identity,
  updateAssignItems: R.identity,
  updateGroupItemData: R.identity,
  updateItemData: R.identity,
  closeSettings: R.identity,
  prepareOpenModalReducer: () => ({
    loadingGuestList: true,
    showGuestListSettingsModal: true
  }),
  openModalReducer: (state, { payload }) => ({
    guestListSettings: payload,
    loadingGuestList: false,
    showGuestListSettingsModal: true
  }),
  closeModalReducer: () => ({
    guestListSettings: [],
    loadingGuestList: false,
    showGuestListSettingsModal: false
  }),
  setInitialData: (
    state,
    {
      payload: {
        loadingPermissionSet,
        moduleId,
        selectedTypeId,
        selectedPermissionSetId,
        permissionSet
      }
    }
  ) => ({
    loadingPermissionSet,
    moduleId,
    selectedTypeId,
    selectedPermissionSetId,
    permissionSet
  }),
  closeUpdateModal: () => ({
    selectedModalId: "",
    showUpdateAssignFormModal: false,
    assignModalType: "",
    selectedModalPersonId: "",
    selectedPersonSubCard: ""
  }),
  setPersonnelInitialData: (
    state,
    {
      payload: {
        subtypes,
        contactFields,
        recordTypes,
        loadingRecordTypes,
        itemsTypes
      }
    }
  ) => ({
    subtypes,
    contactFields,
    recordTypes,
    loadingRecordTypes,
    itemsTypes,
    loadingPersonDocuments: false,
    loadingPersonForms: false,
    loadingPersonAutoAssignItems: false,
    loadingPersonRequestItems: false
  })
};

const model = createHandlers({ iniState, reducers, namespace: NAMESPACE });

const { actions, getters } = model;

export { actions, getters };

export default model;
