import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import TextButton from "../TextButton";
import IsFeatureEnabled from "components/Global/Feature";
import style from "./styles.scss";

const EventPermissionsHeader = ({
  role,
  enableAllPermissions,
  disableAllPermissions,
  canEditPermissions
}) => (
  <header>
    <h1 styleName="header">
      <span>
        <span styleName="title">
          {canEditPermissions ? (
            ""
          ) : (
            <i styleName="title-icon" className={"material-icons"}>
              lock
            </i>
          )}
          {role.name}
        </span>
        <span styleName="description">{role.description}</span>
      </span>
      <IsFeatureEnabled feature="permissions.toggleAll">
        {canEditPermissions ? (
          <div styleName="textToggle">
            <TextButton
              onClick={disableAllPermissions}
              styleName="textToggleOff"
            >
              All Off
            </TextButton>
            <TextButton onClick={enableAllPermissions} styleName="textToggleOn">
              All On
            </TextButton>
          </div>
        ) : (
          ""
        )}
      </IsFeatureEnabled>
    </h1>
  </header>
);

EventPermissionsHeader.propTypes = {
  role: PropTypes.object.isRequired,
  enableAllPermissions: PropTypes.func.isRequired,
  disableAllPermissions: PropTypes.func.isRequired,
  canEditPermissions: PropTypes.bool.isRequired
};

export default CSSModules(EventPermissionsHeader, style);
