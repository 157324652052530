import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Popover from "@lennd/material-ui/Popover";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import DeleteConfirmation from "components/Global/CRM/Modals/DeleteConfirmation";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import CanUserDo from "components/Global/Security/CanUserDo";

class ActionMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopover: false
    };
  }

  @autobind
  handleClick(e) {
    e.stopPropagation();
    this.setState({ showPopover: true });
  }

  @autobind
  onRequestClose() {
    this.setState({ showPopover: false });
  }

  @autobind
  showDeleteConfirmation() {
    const message = (
      <div>
        Are you sure you want to delete {this.props.name}?
        <div style={{ fontWeight: "bold", padding: "10px 0" }}>
          This cannot be undone.
        </div>
      </div>
    );
    this.onRequestClose();
    this.props.showModal({
      content: (
        <DeleteConfirmation
          hideModal={this.props.hideModal}
          heading="Delete account?"
          message={message}
          onConfirm={this.props.deleteAccount}
        />
      ),
      wrapper: ModalWrapper
    });
  }

  render() {
    return (
      <div>
        <span
          onClick={this.handleClick}
          ref={ref => {
            this.anchor = ref;
          }}
          role="link"
          tabIndex="0"
        >
          {this.props.children}
        </span>
        <Popover
          anchorEl={this.anchor}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          onClose={this.onRequestClose}
          open={this.state.showPopover}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
        >
          <Menu>
            {this.props.showChangeRecordTypeModal ? (
              <MenuItem
                primaryText="Change type"
                onClick={() => {
                  this.onRequestClose();
                  this.props.showChangeRecordTypeModal();
                }}
              />
            ) : null}
            <CanUserDo action={`${STANDARD_MODULE_IDS.accounts.id}_delete`}>
              <MenuItem
                primaryText="Delete"
                onClick={() => {
                  this.onRequestClose();
                  this.props.deleteAccount();
                }}
              />
            </CanUserDo>
          </Menu>
        </Popover>
      </div>
    );
  }
}

ActionMenu.propTypes = {
  deleteAccount: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired
};

export default ActionMenu;
