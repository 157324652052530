import { withProps } from "utils/General";

import DoubleSelect from "ui-kit/DoubleSelect/View";

import { CONFIRMATIONS } from "Passes/Confirmations/constants";
import { STATUS } from "Items/Manage/constants";

const options = [
  {
    name: "Confirmation Not Sent",
    id: CONFIRMATIONS.NOT_SENT,
    options: [
      { id: STATUS.APPROVED, name: "Approved" },
      { id: STATUS.DENIED, name: "Denied" }
    ]
  },
  {
    name: "Confirmation Sent",
    id: CONFIRMATIONS.SENT,
    options: []
  },
  {
    name: "Unpaid",
    id: CONFIRMATIONS.UNPAID,
    options: []
  }
];

const iniSelected = { [CONFIRMATIONS.NOT_SENT]: [] };

const decorate = withProps({
  options,
  iniSelected
});

export default decorate(DoubleSelect);
