import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

import EventHeader from "./EventHeader";

import * as ModalActions from "redux/modules/modal/actions";
import * as SnackbarActions from "redux/modules/snackbar/actions";

import * as EventSelectors from "redux/modules/event/selectors";
import * as EventsSelectors from "redux/modules/events/selectors";
import * as UserSelectors from "redux/modules/user/selectors";
import { userPermissionProfile } from "redux/modules/permissions/user-permission-profile/selectors";

function mapStateToProps(state, props) {
  return {
    isFetchingEventDetails: EventSelectors.isFetching(state),
    eventDetails: EventSelectors.eventDetails(state),
    myEvents: EventsSelectors.events(state),
    user: UserSelectors.user(state),
    userPermissionProfile: userPermissionProfile(
      state,
      props.params.eventId,
      state.user.user.id
    ),
    canViewInventory: selectFeatureFlag(flags.CAN_VIEW_INVENTORY.NAME)(state),
    canViewQuickActions: selectFeatureFlag(flags.CAN_VIEW_QUICK_ACTIONS.NAME)(
      state
    )
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...SnackbarActions,
      ...ModalActions
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventHeader);
