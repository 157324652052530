/* eslint-disable no-underscore-dangle */
import * as R from "ramda";

import { call, select } from "redux-saga/effects";
import { eventChannel } from "redux-saga";

import Pusher from "pusher-js";

import Helpers from "utils/Global/Helpers";
import superagent from "superagent";

const debug = require("debug")("lennd:apiCall");

import { getCredentials } from "redux/modules/user/selectors";

export const makeApiCall = (opts = {}) => {
  const idToken = R.pathOr("", ["credentials", "idToken"], opts);

  if (!idToken && process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line no-console
    console.warn(
      `${"----------------------------\n" +
        "No idToken!\n" +
        "isBrowser: "}${Helpers.isBrowser()}\n` +
        `Url: ${opts.url}\n` +
        "----------------------------"
    );
  }

  const baseUrl =
    typeof opts.baseUrl !== "undefined"
      ? opts.baseUrl
      : window.__LENND_API_BASE_URL__;
  const url = `${baseUrl}${opts.url}`;

  const request = R.propOr(R.always(Promise.resolve()), opts.method, {
    get: baseRequest => baseRequest.get(url),
    post: baseRequest =>
      baseRequest
        .post(url)
        .set("Content-Type", "application/json")
        .send(opts.data)
        .set("Accept", "application/json"),
    put: baseRequest =>
      baseRequest
        .put(url)
        .set("Content-Type", "application/json")
        .send(opts.data)
        .set("Accept", "application/json"),
    delete: baseRequest =>
      baseRequest
        .del(url)
        .set("Content-Type", "application/json")
        .send(opts.data)
        .set("Accept", "application/json"),
    head: baseRequest =>
      baseRequest
        .head(url)
        .set("Content-Type", "application/json")
        .send(opts.data)
        .set("Accept", "application/json")
  })(superagent);

  return request
    .query(opts.qs)
    .set("Accept", "application/json")
    .set("Authorization", `Bearer ${idToken}`)
    .then(response => {
      debug(`API Success: ${opts.method} ${opts.url}`);
      return response.body;
    })
    .catch(error => {
      if (R.pathEq(["response", "body", "message"], "jwt expired", error)) {
        const message = "[Error] JWT Expired... refreshing session";

        // eslint-disable-next-line no-console
        console.error(message);
        window.location.reload();
        return false;
      }

      if (error.response) {
        throw error.response;
      }

      throw error;
    });
};

export const apiCall = function*(options) {
  const credentials = yield select(getCredentials);
  return yield call(makeApiCall, { ...options, credentials });
};

let pusherInstance = null;
export const getPusherInstance = () => {
  if (!pusherInstance) {
    pusherInstance = new Pusher(window.__PUSHER_APP_KEY__, {
      cluster: window.__PUSHER_APP_CLUSTER__,
      forceTLS: true
    });
  }
  return pusherInstance;
};

export const createPusherChannel = ({
  channelId = "",
  eventTypes = [],
  withEventTypes = false,
  decoratePayload = R.identity
}) => {
  const pusher = getPusherInstance();
  return eventChannel(emit => {
    const channel = pusher.subscribe(channelId);
    const errorHandler = error => emit(new Error(error));

    channel.bind("pusher:subscription_error", errorHandler);

    for (let event of eventTypes) {
      // eslint-disable-next-line no-unused-expressions
      channel.bind(event, data =>
        emit(
          decoratePayload(withEventTypes ? { eventType: event, data } : data)
        )
      );
    }

    return () => {
      channel.unsubscribe();
      channel.unbind("pusher:subscription_error", errorHandler);
    };
  });
};
