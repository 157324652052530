import { put, takeEvery, all, fork, select, call } from "redux-saga/effects";
import { actions } from "./model";

import { registerError } from "redux/modules/errors/actions";

import { eventId as getEventId } from "redux/modules/event/selectors";

import { apiCall } from "App/Data/sagas";

const init = function*({ payload: { itemId, defaultSelected = [] } }) {
  yield put(actions.setLoading(true));
  const eventId = yield select(getEventId);
  try {
    const { payload } = yield call(apiCall, {
      method: "get",
      url: `/items/zones/groups/event/${eventId}`
    });
    yield put(
      actions.initializeData({
        zoneGroups: payload,
        itemId,
        defaultSelected
      })
    );
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred getting pending attendee requests"
          }
        ])
      )
    ]);
  }
  yield put(actions.setLoading(false));
};

const watchOpenModal = function*() {
  yield takeEvery(actions.openZonesPicker.type, init);
};

const rootSaga = function*() {
  yield fork(watchOpenModal);
};

export default rootSaga;
