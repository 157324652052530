import React from "react";
import { Div, CloseIcon } from "components/Base";
import { withProps } from "utils/General";

const NeutralCloseIcon = withProps({
  color: { default: "neutral5", hover: "danger7" }
})(CloseIcon);

const HeaderTitle = ({ handlers: { hideModal }, title }) => (
  <Div
    pl={7}
    pr={3}
    bb={1}
    bc="neutral2"
    display="row.space-between.center"
    color="neutral4"
    height={67}
    style={{
      flexShrink: 0
    }}
  >
    <Div display="row.flex-star.center" fs={6} color="gray7" fw={3}>
      <img
        src="http://d2dks4tzxs6xee.cloudfront.net/img/icons8-template-96.svg"
        width={36}
        height={36}
        style={{
          marginRight: 10
        }}
      />
      {title}
    </Div>
    <NeutralCloseIcon size={30} onClick={hideModal} />
  </Div>
);

export default HeaderTitle;
