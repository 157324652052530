import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import * as R from "ramda";
import { actions, getters } from "./model";
import { getSelectedItem } from "./selectors";
import { registerError } from "redux/modules/errors/actions";
import itemTypesApi from "redux/modules/items/types/api";
import { getCredentials } from "redux/modules/user/selectors";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";
import { apiCall } from "App/Data/sagas";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { ADD_OPTIONS } from "./constants";
import { hideModal } from "redux/modules/modal/actions";

const init = function*({ payload: { eventId } }) {
  try {
    yield put(actions.setLoading(true));
    const credentials = yield select(getCredentials);

    const { payload } = yield call(
      itemTypesApi.getItemTypesByEvent,
      credentials,
      eventId
    );

    const items = R.compose(
      R.map(item => ({
        id: R.pathOr("", ["variants", 0, "id"], item),
        label: R.pathOr("", ["variants", 0, "display_name"], item)
      })),
      R.filter(R.pathOr(false, ["variants", 0, "track_inventory"])),
      R.flatten,
      R.map(R.propOr([], "items")),
      R.propOr([], "groups"),
      R.find(({ id }) => id === CREDENTIAL_TYPE_ID)
    )(payload);

    yield put(actions.setItems(items));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading inventory"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const addBarcodes = function*({ payload: onSave }) {
  try {
    yield put(actions.setLoading(true));
    yield put(actions.setErrorMessage(""));
    const eventId = yield select(getEventId);
    const variantId = yield select(getSelectedItem);
    const addOption = yield select(getters.addOption);
    const quantityToGenerate = yield select(getters.quantityToGenerate);
    const startAt = yield select(getters.startingNumber);
    const numbers = yield select(getters.numbers);

    const data =
      addOption === ADD_OPTIONS.GENERATE
        ? {
            startAt: parseInt(startAt),
            quantityToGenerate: parseInt(quantityToGenerate)
          }
        : {
            inventory: R.split("\n", numbers)
          };

    yield call(apiCall, {
      method: "post",
      url: `/items/event/${eventId}/variant/${variantId}/${addOption}-inventory`,
      data
    });

    yield all([put(hideModal()), call(onSave)]);
  } catch (error) {
    yield all([
      put(actions.setErrorMessage(R.replace(/,/g, ", ", error.message))),
      put(
        registerError([
          {
            system: error,
            user: "An error adding barcodes"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchAddBarcodes = function*() {
  yield takeEvery(actions.addBarcodes.type, addBarcodes);
};

const rootSaga = function*() {
  yield all([fork(watchInit), fork(watchAddBarcodes)]);
};

export default rootSaga;
