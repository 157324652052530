import React, { Component } from "react";
import * as R from "ramda";
import { Link } from "react-router";
import { Div, Span } from "components/Base";

import {
  getSettings,
  getMeta,
  getRow,
  getCellValue
} from "ui-kit/Datagrid/utils";

import ErrorMark from "./ErrorMark";

const errorStyle = {
  display: "flex",
  flexDirection: "row-reverse",
  alignItems: "center",
  justifyContent: "space-between"
};

class ContactName extends Component {
  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props.value, nextProps.value);
  }

  render() {
    const props = this.props;
    const { valid, description } = R.propOr({}, "error", this.props.value);
    const contactId = getRow(props, "contact_id") || getRow(props, "id");
    const suppressLinkToRecord =
      getSettings(props, "suppressLinkToRecord") ||
      getMeta(props, "suppressLinkToRecord");
    const viewingContactVia = {
      viaFormRequests: getMeta(props, ["form", "id"])
    };
    const orgId = getMeta(props, "orgId");
    const eventId = getMeta(props, ["eventDetails", "id"]);
    const qs = viewingContactVia
      ? `?viewingContactVia=${JSON.stringify(viewingContactVia)}`
      : "";

    const value = getCellValue(props, R.identity, "");

    return (
      <Div
        truncate
        style={{
          lineHeight: "34px",
          ...((valid === false && errorStyle) || {})
        }}
      >
        <ErrorMark valid={valid} description={description} />
        {!suppressLinkToRecord ? (
          <Link
            to={
              orgId
                ? `/org-light/${orgId}/contact/${contactId}${qs}`
                : `/event-light/${eventId}/contact/${contactId}${qs}`
            }
            title={value}
          >
            <Span color="purple8" fw={3}>
              {value}
            </Span>
          </Link>
        ) : (
          value
        )}
      </Div>
    );
  }
}

ContactName.defaultProps = {
  value: {},
  row: { meta: {} }
};

export default ContactName;
