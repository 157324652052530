import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AccountReportPeople from "./AccountReportPeople";

import { deselectAllRows } from "redux/modules/modules/records/settings/actions";
import { selectedRows } from "redux/modules/modules/records/settings/selectors";
import * as modalActions from "redux/modules/modal/actions";

function mapStateToProps(state, props) {
  return {
    // @NOTE: The People report record IDs are formatted as `${accountId}_${contactId}`
    // To get the contact ID, we need to split it out
    selectedRows: selectedRows(state, props.moduleId).map(
      id => id.split("_")[1]
    ),
    contactsAndAccounts: selectedRows(state, props.moduleId).map(id => ({
      accountId: id.split("_")[0],
      contactId: id.split("_")[1]
    }))
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        ...modalActions,
        deselectAllRows: () => deselectAllRows(props.moduleId)
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(
  AccountReportPeople
);
