import React from "react";
import * as R from "ramda";

import { Div, MediumOutlineButton } from "components/Base";
import CollapsablePanel from "ui-kit/CollapsiblePanel";
import CSSModules from "react-css-modules";
import css from "../onboard.scss";
import { connect } from "react-redux";
import { actions, getters } from "../../model";
import { LoadingIcon } from "components/Base";
import NotConfigured from "../NotConfigured";

const decorate = R.compose(
  connect(
    (state, props) => ({
      permissionSet: getters.permissionSet(state, props),
      loadingAppearance: getters.loadingAppearance(state)
    }),
    {
      init: actions.init,
      showModal: () => actions.setShowAppearanceSettingsModal(true)
    }
  ),
  CSSModules(css)
);

const Appearance = ({ permissionSet, showModal, loadingAppearance }) => (
  <Div flex={1} display="column.flex-start.stretch">
    <CollapsablePanel
      heading={() => (
        <Div display="row.space-between.center" my={2} width={1} pr={4}>
          <div className={css.title}>Customize Appearance</div>
          <NotConfigured
            configured={permissionSet.title && permissionSet.title.length}
            loading={loadingAppearance}
            text={`Customized`}
          />
        </Div>
      )}
      bg="white"
      arrowColor="gray7"
      bc="neutral3"
      right={false}
      bra={1}
      mb={3}
      p={3}
      collapsedDefault={true}
    >
      <div styleName="wrapper">
        <div styleName="row">
          <div>
            <div styleName="header">
              <span className="material-icons" style={{ color: "#db4a71" }}>
                palette
              </span>
              <div styleName="name">Appearance Settings</div>
            </div>
            <div styleName="description">
              Customize the title, description and background image of this
              portal
            </div>
          </div>
          {loadingAppearance ? (
            <div styleName="loading-wrapper">
              <LoadingIcon color="gray3" size={32} />
            </div>
          ) : (
            <div styleName="actions">
              <MediumOutlineButton
                color="primary7"
                bc="primary7"
                mr={2}
                LeftIcon={() => (
                  <span
                    class="material-icons"
                    style={{ fontSize: 12, marginRight: 8, height: 12 }}
                  >
                    border_color
                  </span>
                )}
                width={100}
                onClick={showModal}
              >
                Edit
              </MediumOutlineButton>
            </div>
          )}
        </div>
      </div>
    </CollapsablePanel>
  </Div>
);

export default decorate(Appearance);
