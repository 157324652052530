import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { TAB_IDS } from "./constants";

import { getTabs, getCurrentRoute } from "./selectors";
import PageTitle from "EventLight/Common/PageTitle";
import SubNav from "./SubNav";

import Dashboard from "./Dashboard/View";
import Requests from "./Requests/View";
import Catalog from "./Catalog/View";
import Zones from "./Zones/View";
import Inventory from "./Inventory/View";
import ZoneGrid from "./ZoneGrid/View";
import Actions from "./Actions/View";
import Approvers from "./Approvers/View";
import Questions from "./Questions/View";
import Integrations from "./Integrations";
import SendEmails from "EventLight/Common/SendEmail/View";
import Design from "./Design/View";

import { Div } from "components/Base";

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    currentRoute: getCurrentRoute(state, props),
    tabs: getTabs(state, props)
  }))
);

const Pages = {
  [TAB_IDS.DASHBOARD]: Dashboard,
  [TAB_IDS.REQUESTS]: Requests,
  [TAB_IDS.CATALOG]: Catalog,
  [TAB_IDS.ZONES]: Zones,
  [TAB_IDS.ZONE_GRID]: ZoneGrid,
  [TAB_IDS.ACTIONS]: Actions,
  [TAB_IDS.DESIGNS]: Design,
  [TAB_IDS.APPROVERS]: Approvers,
  [TAB_IDS.QUESTIONS]: Questions,
  [TAB_IDS.INTEGRATIONS]: Integrations,
  [TAB_IDS.SEND_EMAILS]: SendEmails,
  [TAB_IDS.INVENTORY]: Inventory
};

const Wrapper = ({ currentRoute = "", tabs = [] }) => {
  return (
    <Div
      display="column.flex-start.stretch"
      height={1}
      style={{ maxHeight: "100%" }}
    >
      <PageTitle titles={["Passes"]} />
      <SubNav tabs={tabs} selectedView={currentRoute} />
      {React.createElement(R.propOr("div", currentRoute, Pages))}
    </Div>
  );
};

export default decorate(Wrapper);
