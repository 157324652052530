import React from "react";
import { withProps } from "utils/General";
import CanUserDo from "components/Global/Security/CanUserDo";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import {
  Div,
  SmallToggle,
  Text2,
  SmallAvatar,
  Text3,
  makeTable
} from "components/Base";

const headerCell = text => props => (
  <Text2 color="gray8" bold {...props}>
    {text}
  </Text2>
);

const HeaderStyler = withProps({
  display: "row.flex-start.center",
  p: 3,
  bg: "gray0"
})(Div);

const RowStyler = withProps({
  display: "row.flex-start.center",
  bb: 1,
  bc: "neutral1",
  py: 2,
  px: 3
})(Div);

const UserCell = ({ photo_url, email, first_name, last_name }) => (
  <Div display="row.flex-start.center">
    <SmallAvatar
      text={[first_name, last_name].filter(v => v).join(" ")}
      photoURL={photo_url}
    />
    <Div ml={2} display="column.center.flex-start">
      <Text3 bold color="gray7">
        {first_name} {last_name}
      </Text3>
      <Text2>{email}</Text2>
    </Div>
  </Div>
);
const RoleCell = ({ role }) => <Text2>{role}</Text2>;
const PermissionRoleCell = ({ permission_name }) => (
  <Text2>{permission_name}</Text2>
);
const ApprovalCell = ({ is_approver, onToggleApprover }) => (
  <Div display="row.center.center">
    <SmallToggle active={is_approver} onClick={onToggleApprover} />
  </Div>
);
const ActionsCell = ({ onEdit }) => (
  <Div display="row.center.center">
    <CanUserDo action={`${STANDARD_MODULES.settings.id}_manage_users`}>
      <Div fs={2} fw={3} onClick={onEdit} color="primary5">
        Edit
      </Div>
    </CanUserDo>
  </Div>
);

const sharedTableProps = {
  TableStyler: withProps({ bg: "white", bra: 2, mt: 3 })(Div),
  HeaderStyler,
  RowStyler,
  HeaderCellStyler: Div,
  RowCellStyler: Div
};

const ManagersTable = makeTable({
  ...sharedTableProps,
  columnProps: [
    { width: 5 / 15 },
    { width: 4 / 15 },
    { width: 2 / 15 },
    { width: 2 / 15, br: 1, bc: "neutral1" },
    { width: 2 / 15 }
  ],
  headerCellComps: [
    headerCell("Name"),
    headerCell("Role"),
    headerCell("Permission"),
    withProps({ textAlign: "center" })(headerCell("Approver")),
    withProps({ textAlign: "center" })(headerCell("Actions"))
  ],
  rowCellComps: [
    UserCell,
    RoleCell,
    PermissionRoleCell,
    ApprovalCell,
    ActionsCell
  ]
});

const UsersTable = makeTable({
  ...sharedTableProps,
  columnProps: [
    { width: 5 / 13 },
    { width: 4 / 13 },
    { width: 2 / 13, br: 1, bc: "neutral1" },
    { width: 2 / 13 }
  ],
  headerCellComps: [
    headerCell("Name"),
    headerCell("Role"),
    headerCell("Permission"),
    withProps({ textAlign: "center" })(headerCell("Actions"))
  ],
  rowCellComps: [UserCell, RoleCell, PermissionRoleCell, ActionsCell]
});

export default {
  ManagersTable,
  UsersTable
};
