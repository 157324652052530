import React from "react";
import Tooltip from "components/Global/Tooltip";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const AdminColumn = ({ adminFieldIsAdminOnly }) => (
  <Tooltip
    tooltip={adminFieldIsAdminOnly ? "Admin only field" : "Approval only field"}
    placement="bottom"
    key="admin"
  >
    <i className={`material-icons ${css.icon}`}>playlist_add_check</i>
  </Tooltip>
);

export default CSSModules(AdminColumn, css);
