import getFieldType from "utils/Forms/get-field-type";
// catering credentials inventory only
const formHasReports = (forms = []) => {
  if (forms.length === 0) {
    return false;
  }
  const [form, ...rest] = forms;
  const subformFields = form.fields.reduce((acc, field) => {
    if (field.type === "subform") {
      return [...acc, ...field.subform.form.fields];
    }
    return acc;
  }, []);
  const hasReport = subformFields.some(field =>
    ["inventory", "credentials", "catering", "catalog-item"].includes(
      getFieldType(field)
    )
  );
  const hasSubmissions = form.submissions.some(
    submission => submission.is_submitted
  );
  if (hasReport && hasSubmissions) {
    return true;
  }

  return formHasReports(rest);
};

export default formHasReports;
