import React from "react";

import { connect } from "react-redux";

import * as R from "ramda";

import { actions, getters } from "../model";

import { getFilteredSets, getIsAllSelectedSets } from "../selectors";

import { noop, addS } from "utils/General";

import {
  collapsableHandler,
  Div,
  Collapsable,
  SmallCheckbox,
  Text1,
  Text2,
  Text3,
  MediumOutlineButton,
  DownIcon,
  RightIcon,
  MoneyIcon
} from "components/Base";

import QuestionsSetsActions from "./QuestionsSetsActions";

const decorate = connect(
  state => ({
    questionSets: getFilteredSets(state),
    setsIds: getters.setsIds(state),
    isAllSelectedSets: getIsAllSelectedSets(state)
  }),
  {
    toggleSet: actions.toggleSet,
    toggleAllSets: actions.toggleAllSets,
    showBulkQuestionsModal: actions.showBulkQuestionsModal,
    showAddSetQuestionModal: actions.showAddSetQuestionModal
  }
);

const ROW = {
  HEIGHT: 46
};

const Header = ({ toggleAllSets, isAllSelectedSets }) => (
  <Div display="row.space-between.center" height={ROW.HEIGHT} width={1} px={2}>
    <Div display="row.flex-start.center" style={{ width: "35%" }}>
      {/* <SmallCheckbox
        onClick={() => toggleAllSets()}
        selected={isAllSelectedSets}
      /> */}
      <Text1 bold color="black" ml={1}>
        Name
      </Text1>
    </Div>
    <Div style={{ width: "20%" }}>
      <Text1 bold color="black">
        # of questions
      </Text1>
    </Div>
    <Div style={{ width: "20%" }}>
      <Text1 bold color="black">
        Used in
      </Text1>
    </Div>
    <Div style={{ width: "25%" }}>
      <Text1 bold color="black">
        Actions
      </Text1>
    </Div>
  </Div>
);

const formatItems = itemsCount => {
  if (itemsCount === 0) {
    return "Not Assigned";
  }
  return `${itemsCount} item${addS(itemsCount)}`;
};

const Row = ({
  name,
  count_of_questions,
  count_of_items,
  has_price,
  toggleSet,
  isEnabled,
  id,
  showBulkQuestionsModal,
  showAddSetQuestionModal,
  showEditQuestionSetModal,
  showDeleteQuestionSetModal,
  showEditQuestionSetQuestionsModal,
  showAssignQuestionToItemsModal
}) => (
  <Div
    display="row.space-between.center"
    height={ROW.HEIGHT}
    width={1}
    px={2}
    bt={1}
    bc="neutral1"
  >
    <Div display="row.flex-start.center" style={{ width: "35%" }}>
      {/*<SmallCheckbox
        selected={isEnabled}
        onClick={() => toggleSet({ id, isEnabled })}
      />*/}
      <Text3
        // bold
        // color="primary7"
        ml={1}
        // onClick={() => showBulkQuestionsModal({ selectedQuestionIds: [] })}
        truncate
      >
        {name}
      </Text3>
      {has_price && <MoneyIcon color="black" />}
    </Div>
    <Div style={{ width: "20%" }}>
      <Text3 underline onClick={showEditQuestionSetQuestionsModal}>
        {count_of_questions} question
        {addS(count_of_questions)}
      </Text3>
    </Div>
    <Div style={{ width: "20%" }} onClick={showAssignQuestionToItemsModal}>
      <Text3 underline>{formatItems(count_of_items)}</Text3>
    </Div>
    <Div style={{ width: "25%" }} display="row">
      <MediumOutlineButton onClick={showAssignQuestionToItemsModal}>
        Assign
      </MediumOutlineButton>
      <QuestionsSetsActions
        onEdit={showEditQuestionSetModal}
        onDelete={showDeleteQuestionSetModal}
        showEditQuestionSetQuestionsModal={showEditQuestionSetQuestionsModal}
        showAssignQuestionToItemsModal={showAssignQuestionToItemsModal}
      />
    </Div>
  </Div>
);

const iconProps = {
  size: 24,
  color: "black"
};

const SetsCollapsable = collapsableHandler(
  ({
    toggleCollapsed,
    collapsed,
    questionSets,
    toggleSet,
    setsIds,
    toggleAllSets,
    isAllSelectedSets,
    showBulkQuestionsModal,
    showAddSetQuestionModal,
    handlers
  }) => (
    <Div>
      <Div
        onClick={toggleCollapsed}
        fs={4}
        fw={3}
        height={ROW.HEIGHT}
        bg="white"
        display="row.space-between.center"
        pr={1}
      >
        <Div display="row.flex-start.center">
          {collapsed ? (
            <RightIcon {...iconProps} />
          ) : (
            <DownIcon {...iconProps} />
          )}
          <Div color="black">Questions Sets</Div>
        </Div>
      </Div>
      <Collapsable collapsed={collapsed}>
        <Div bg="white" shadow={1}>
          <Header
            toggleAllSets={toggleAllSets}
            isAllSelectedSets={isAllSelectedSets}
          />
          <Div>
            {R.map(
              question => (
                <Row
                  {...question}
                  key={question.id}
                  isEnabled={R.any(id => id === question.id, setsIds)}
                  toggleSet={toggleSet}
                  showBulkQuestionsModal={showBulkQuestionsModal}
                  showAssignQuestionToItemsModal={() =>
                    handlers.showAssignQuestionToItemsModal({
                      questionSetIds: [question.id]
                    })
                  }
                  showEditQuestionSetModal={() =>
                    handlers.showEditQuestionSetModal(question.id)
                  }
                  showDeleteQuestionSetModal={() =>
                    handlers.showDeleteQuestionSetModal(question.id)
                  }
                  showEditQuestionSetQuestionsModal={() =>
                    handlers.showEditQuestionSetQuestionsModal(question.id)
                  }
                />
              ),
              questionSets
            )}
          </Div>
        </Div>
      </Collapsable>
    </Div>
  )
);

const QuestionsSets = props => <SetsCollapsable {...props} />;

export default decorate(QuestionsSets);
