import { combineReducers } from "redux";
import Api from "utils/Event/WebAPIUtils";
import _ from "lodash";

// ------------------------------------
// Constants
// ------------------------------------
const INVALIDATE_EVENT_MEMBERS = "INVALIDATE_EVENT_MEMBERS";
const REQUEST_EVENT_MEMBERS = "REQUEST_EVENT_MEMBERS";
const RECEIVE_EVENT_MEMBERS = "RECEIVE_EVENT_MEMBERS";

// ------------------------------------
// Actions
// ------------------------------------
export const invalidateMembers = eventId => ({
  type: INVALIDATE_EVENT_MEMBERS,
  eventId
});

const requestMembers = eventId => ({
  type: REQUEST_EVENT_MEMBERS,
  eventId
});

const receiveMembers = (eventId, members) => ({
  type: RECEIVE_EVENT_MEMBERS,
  eventId,
  members,
  receivedAt: Date.now()
});

const fetchMembers = eventId => (dispatch, getState) => {
  dispatch(requestMembers(eventId));
  return Api.fetchMembers(getState().user.user.credentials, eventId).then(
    members => {
      dispatch(receiveMembers(eventId, members));
    }
  );
};

const shouldFetchMembers = (state, eventId) => {
  const members = state.eventMembers.membersByEvent[eventId];
  if (!members) {
    return true;
  }
  if (members.isFetching) {
    return false;
  }
  return members.didInvalidate;
};

export const fetchMembersIfNeeded = eventId => (dispatch, getState) => {
  if (shouldFetchMembers(getState(), eventId)) {
    return dispatch(fetchMembers(eventId));
  }
};

export const addMember = data => (dispatch, getState) => {
  Api.addMember(getState().user.user.credentials, data).then(member => {
    dispatch(fetchMembers(data.eventId));
    return null;
  });
};

export const updateMember = data => (dispatch, getState) => {
  Api.updateMember(getState().user.user.credentials, data).then(() => {
    dispatch(fetchMembers(data.eventId));
    return null;
  });
};

export const updateMemberPhone = data => (dispatch, getState) =>
  Api.updateMemberPhone(getState().user.user.credentials, data);

export const updateMemberMembers = data => (dispatch, getState) => {
  Api.updateMemberMembers(getState().user.user.credentials, data).then(() => {
    dispatch(fetchMembers(data.eventId));
    return null;
  });
};

export const deleteMember = data => (dispatch, getState) => {
  Api.deleteMember(getState().user.user.credentials, data).then(() => {
    dispatch(fetchMembers(data.eventId));
    return null;
  });
};

export const actions = {
  fetchMembers,
  fetchMembersIfNeeded,
  deleteMember,
  updateMemberMembers,
  updateMemberPhone,
  updateMember,
  addMember,
  invalidateMembers
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isFetching: false,
  didInvalidate: false,
  lastUpdated: null,
  members: []
};

const members = (state = initialState, action) => {
  switch (action.type) {
    case INVALIDATE_EVENT_MEMBERS:
      return _.extend({}, state, {
        didInvalidate: true
      });

    case REQUEST_EVENT_MEMBERS:
      return _.extend({}, state, {
        isFetching: true,
        didInvalidate: false
      });

    case RECEIVE_EVENT_MEMBERS:
      return _.extend({}, state, {
        isFetching: false,
        didInvalidate: false,
        members: action.members,
        lastUpdated: action.receivedAt
      });

    default:
      return state;
  }
};

const membersByEvent = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_EVENT_MEMBERS:
    case RECEIVE_EVENT_MEMBERS:
    case REQUEST_EVENT_MEMBERS:
      return _.extend({}, state, {
        [action.eventId]: members(state[action.eventId], action)
      });
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  membersByEvent
});

export default rootReducer;
