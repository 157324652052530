import { createModule, createHandlers } from "redux-mvc";

const iniState = {
  pageSize: 10,
  page: 0
};

const reducers = {
  setPagination: (
    { pageSize, page },
    { payload: { pageSize: ps, page: p } }
  ) => ({
    page: typeof p !== "undefined" ? p : page,
    pageSize: typeof ps !== "undefined" ? ps : pageSize
  }),
  setPageSize: (_, { payload: pageSize }) => ({
    pageSize
  })
};

const handlers = createHandlers({
  iniState,
  reducers,
  namespace: "UIPaginationBar"
});

const { actions, getters } = handlers;

const module = createModule(handlers);

export { actions, getters };

export default module;
