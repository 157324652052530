import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as R from "ramda";

import { showModal, hideModal } from "redux/modules/modal/actions";
import { getters, actions } from "../model";
import { getZoneGroups } from "../selectors";
import {
  Div,
  BigFilledInput,
  BigOutlineButton,
  SearchIcon
} from "components/Base";
import CollapsableGroup from "./CollapsableGroup";
import AddItemPopover from "./AddItemPopover";

import Loading from "ui-kit/Loading";
import { Page, Title } from "EventLight/Common/FieldLayout";
import AddBulkItemsModal from "./AddBulkItemsModal";

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      loading: getters.loading(state, props),
      typesFilter: getters.typesFilter(state, props),
      zoneGroups: getZoneGroups(state, props)
    }),
    {
      setTypesFilter: actions.setTypesFilter,
      addCategory: actions.addCategory,
      showModal,
      hideModal
    }
  )
);

const Layout = ({
  addCategory,
  typesFilter,
  setTypesFilter,
  zoneGroups,
  loading
}) => {
  return (
    <>
      <Page>
        <Title
          icon="assignment_turned_in"
          title="Zones"
          description="Manage your zones"
        />

        {loading ? (
          <Loading />
        ) : (
          <Div
            px={3}
            pb={3}
            pl={6}
            style={{
              paddingBottom: 100
            }}
          >
            <Div flex={1} display="row.flex-start.center">
              <BigFilledInput
                width={1}
                color="neutral5"
                bg="neutral1"
                placeholder="Search Zones"
                LeftIcon={SearchIcon}
                value={typesFilter}
                onChange={value => {
                  setTypesFilter(value);
                }}
                continuous
              />
              <BigOutlineButton onClick={addCategory} ml={2}>
                Add Category
              </BigOutlineButton>
              <AddItemPopover disabled={R.length(zoneGroups) === 0} />
            </Div>
            <Div>
              {R.map(
                group => (
                  <CollapsableGroup
                    group={group}
                    groupsLength={R.length(zoneGroups)}
                  />
                ),
                zoneGroups
              )}
            </Div>
          </Div>
        )}
      </Page>
      <AddBulkItemsModal />
    </>
  );
};

export default decorate(Layout);
