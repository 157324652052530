import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import StyleWrapper from "../StyleWrapper";

const WarningHeader = CSSModules(
  ({ heading, hideModal }) => (
    <div styleName="header">
      <div styleName="title">{heading}</div>
      <div onClick={hideModal}>
        <i className="material-icons">close</i>
      </div>
    </div>
  ),
  css
);

const WarningWrapper = ({ children, ...props }) => (
  <StyleWrapper {...props} Header={WarningHeader}>
    {children}
  </StyleWrapper>
);

export default WarningWrapper;
