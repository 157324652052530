import PropTypes from "prop-types";
import React from "react";
import autobind from "autobind-decorator";
import EditorBase from "../TableConnector";
import Formatter from "ui-kit/Datagrid/View/Formatters/LinkedRecords";
import getValue from "utils/value-types/get-value/lookup";
import * as SYSTEM_FIELD_IDS from "utils/system-field-ids";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import LinkedRecordsModal from "components/Global/CRM/Modals/LinkedRecords";

import { Div, AddIcon } from "components/Base";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { getSettings, getHandlers, ep } from "ui-kit/Datagrid/utils";
import { showModal } from "redux/modules/modal/actions";

const { ID } = SYSTEM_FIELD_IDS;

const wrapValue = ({ moduleId, records }) => ({
  type: "lookup",
  value: {
    moduleId,
    records: records || []
  }
});

@CSSModules(css)
class LinkedRecordsEditor extends EditorBase {
  handleChange(state) {
    return super.handleChange(state, this.props.onCommit);
  }

  @autobind
  getValue() {
    return { [this.props.column.key]: wrapValue(getValue(this.state)) };
  }

  showLinkedRecordsModal = () => {
    const props = ep(this.props);
    const handlers = getHandlers(props);

    const columnModuleId = getSettings(props, "moduleId");
    const columnFieldId = getSettings(props, "fieldId", ID);

    handlers.dispatch(
      showModal({
        content: (
          <LinkedRecordsModal
            moduleId={this.props.rowMetaData.meta.moduleId}
            fieldId={this.props.column.id}
            recordId={this.props.rowData.id}
            linkedModuleId={columnModuleId}
            linkedFieldId={columnFieldId}
            linkedRecordIds={getValue(this.state).records}
            onDone={() => {
              if (handlers.refreshRecords) {
                handlers.refreshRecords();
              }
            }}
          />
        ),
        wrapper: ModalWrapper
      })
    );
  };

  render() {
    const props = ep(this.props);
    const isReadOnly = getSettings(props, "isReadOnly", false);

    return (
      <div
        styleName={isReadOnly ? "containerReadOnly" : "container"}
        ref="anchorEl"
      >
        {!isReadOnly ? (
          <Div
            bg={{ default: "neutral2", hover: "neutral1" }}
            onClick={this.showLinkedRecordsModal}
            display="flex.center.center"
            bra={1}
            ml={1}
            mr={2}
            style={{
              width: 20,
              height: 22,
              flexShrink: 0
            }}
          >
            <AddIcon size={16} color="gray9" />
          </Div>
        ) : null}
        <div styleName="formatter">
          <Formatter canClickRecord value={this.state} row={props.row} />
        </div>
      </div>
    );
  }
}

LinkedRecordsEditor.propTypes = {
  ...EditorBase.propTypes,
  rowMetaData: PropTypes.object.isRequired
};

export default LinkedRecordsEditor;
