import { connect } from "react-redux";

import Summaries from "./Summaries";
import * as SubmissionSelectors from "redux/modules/formsV2/submission/selectors";

function mapStateToProps(state) {
  return {
    details: SubmissionSelectors.eventDetails(state),
    submissions: [SubmissionSelectors.submission(state)],
    fields: SubmissionSelectors.sortedFormFields(state)
  };
}

export default connect(mapStateToProps)(Summaries);
