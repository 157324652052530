import React from "react";
import PropTypes from "prop-types";
import {
  TinyFilledButton,
  SmallFilledButton,
  MediumFilledButton,
  BigFilledButton,
  SuperFilledButton,
  NoneIcon,
  FullSizeDotIcon
} from "../index";

const makeToggle = Comp => {
  const result = ({
    active,
    onClick,
    m,
    mx,
    my,
    mr,
    ml,
    mt,
    mb,
    bg = "altA7"
  }) => (
    <Comp
      onClick={onClick}
      pill
      m={1}
      bg={active ? bg : "neutral7"}
      LeftIcon={active ? NoneIcon : FullSizeDotIcon}
      RightIcon={active ? FullSizeDotIcon : NoneIcon}
      style={{
        flexShrink: 0,
        padding: 2
      }}
      {...{
        m,
        mx,
        my,
        mr,
        ml,
        mt,
        mb
      }}
    />
  );

  result.propTypes = {
    active: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
  };
  return result;
};

const [TinyToggle, SmallToggle, MediumToggle, BigToggle, SuperToggle] = [
  TinyFilledButton,
  SmallFilledButton,
  MediumFilledButton,
  BigFilledButton,
  SuperFilledButton
].map(makeToggle);

export { TinyToggle, SmallToggle, MediumToggle, BigToggle, SuperToggle };
