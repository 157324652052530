/* eslint-disable react/prop-types */

import React from "react";
import * as R from "ramda";
import {
  Div,
  Type8Card,
  AddIcon,
  CheckIcon,
  // CircleIcon,
  ClockIcon,
  MediumTextButton,
  SmallFilledBox,
  CloseIcon
} from "components/Base";
import { withProps } from "utils/General";

const FillOutButton = ({ onClick }) => (
  <MediumTextButton
    onClick={onClick}
    LeftIcon={AddIcon}
    color="purple7"
    style={{ flexShrink: 0 }}
  >
    Fill Out
  </MediumTextButton>
);

const AdminRequest = ({}) => (
  <Div color="neutral5" fs={3}>
    *only managers see this
  </Div>
);

const getIconPerStatus = status => {
  switch (status) {
    case "admin":
      return withProps({
        bg: "primary8",
        color: "white",
        OnlyIcon: AddIcon,
        bra: 3
      })(SmallFilledBox);
    case "pending":
      return withProps({
        bg: "orange6",
        color: "white",
        OnlyIcon: ClockIcon,
        pill: true
      })(SmallFilledBox);
    case "approved":
      return withProps({
        bg: "teal6",
        color: "white",
        OnlyIcon: CheckIcon,
        pill: true
      })(SmallFilledBox);
    case "denied":
      return withProps({
        bg: "red6",
        color: "white",
        OnlyIcon: CloseIcon,
        pill: true
      })(SmallFilledBox);
    default:
      return withProps({
        bg: "white",
        pill: true,
        ba: 2,
        bc: "gray4"
      })(Div);
  }
};

const getRequestableButton = rowType => {
  switch (rowType) {
    case "credentials":
      return FillOutButton;
    case "admin":
      return AdminRequest;
    default:
      return Div;
  }
};

// const getAdditionalStatus = status => {
//   switch (status) {
//     case "something":
//       return {
//         text: "2d",
//         color: "red5"
//       };
//     default:
//       return {};
//   }
// };

const RequestableItems = ({ mobile, requestableItems }) => (
  <Div>
    {R.map(
      ({ title, description, status, type, onClick, required }) => {
        return (
          <Type8Card
            mb={1}
            title={title}
            subtitle={description}
            mobile={mobile}
            LeftIcon={getIconPerStatus(status)}
            required={required}
            // RightIcon={LockIcon}
            // rightTextColor={"red7"} // replace with getAdditionalStatus
            // rightText={"2d"}
            Button={getRequestableButton(type)}
            onClick={onClick}
            // onClickButton={onClick}
            bg={{ default: "white", hover: "neutral0" }}
          />
        );
      },

      requestableItems
    )}
  </Div>
);

export default RequestableItems;
