import { REQUEST, RECEIVE, ERROR, CLEAR_ERROR } from "./constants";
import * as selectors from "./selectors";
import api from "./api";

export function fetchGroupResources({ accountId, eventUUID }) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const data = await api.get({ accountId, eventUUID });
      dispatch({ type: RECEIVE, payload: data });
      return data;
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}
