import { createHandlers, createModule } from "redux-mvc";

import miniItemsSelector from "ui-kit/MiniItemsSelector";

const iniState = {
  loading: false,
  title: "Select users with access",
  users: [],
  permissionProfiles: [],
  selectedItemIds: [],
  showAllowAccessModal: false
};

const handlers = createHandlers({
  iniState,
  namespace: "AllowAccessModal",
  reducers: {
    onSave: (state, { payload: selectedItemIds }) => ({
      showAllowAccessModal: false,
      selectedItemIds
    }),
    usersAndProfilesResponse: (
      state,
      { payload: { usersList, profilesList } }
    ) => ({
      users: usersList,
      permissionProfiles: profilesList
    }),
    showModalAndUpdateItemIds: (state, { payload: { selectedItemIds } }) => ({
      selectedItemIds,
      showAllowAccessModal: true
    })
  }
});

const module = createModule(handlers);

module.plugModule(miniItemsSelector);

const { actions, getters } = module;

export { actions, getters };

export default module;
