/*
 * @NOTE: Deprecating in favor of src/Submission/Editor
 */

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Fields from "./Fields";
import * as ValueActions from "redux/modules/formsV2/submission/values/actions";
import * as SubmissionSelectors from "redux/modules/formsV2/submission/selectors";
import * as UserSelectors from "redux/modules/user/selectors";

function mapStateToProps(state) {
  return {
    user: UserSelectors.user(state),
    details: SubmissionSelectors.eventDetails(state),
    submission: SubmissionSelectors.submission(state),
    form: SubmissionSelectors.form(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...ValueActions
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Fields);
