import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";

import { actions, getters } from "Reports/ReportTemplates";

import { registerError } from "redux/modules/errors/actions";

import { eventId as getEventId } from "redux/modules/event/selectors";

import { getCredentials } from "redux/modules/user/selectors";

import Api from "./api";

const getParams = function*() {
  const moduleId = yield select(getters.moduleId);
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  return {
    credentials,
    eventId,
    moduleId
  };
};

const init = function*({ payload: moduleId }) {
  try {
    yield put(actions.setLoading(true));

    const { credentials, eventId } = yield call(getParams);

    const { payload: templates } = yield call(Api.getTemplates, {
      credentials,
      eventId,
      moduleId
    });

    yield put(actions.setInitialData({ moduleId, templates }));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading templates"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const refetchTemplates = function*() {
  try {
    yield put(actions.setLoading(true));

    const { credentials, eventId, moduleId } = yield call(getParams);

    const { payload: templates } = yield call(Api.getTemplates, {
      credentials,
      eventId,
      moduleId
    });

    yield put(actions.setTemplates(templates));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading templates"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const createTemplate = function*({ payload: { onDone } }) {
  try {
    const { credentials, eventId, moduleId } = yield call(getParams);

    const { payload } = yield call(Api.createTemplate, {
      credentials,
      data: {
        eventId,
        moduleId,
        name: "New Report"
      }
    });

    onDone(payload.id);

    yield put(actions.refetchTemplates());
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error creating template"
          }
        ])
      )
    ]);
  }
};

const copyTemplate = function*({ payload: templateId }) {
  try {
    const { credentials } = yield call(getParams);

    const { payload } = yield call(Api.copyTemplate, {
      credentials,
      data: {
        templateId
      }
    });

    yield put(actions.refetchTemplates());
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error copying template"
          }
        ])
      )
    ]);
  }
};

const deleteTemplate = function*({ payload: templateId }) {
  try {
    const { credentials } = yield call(getParams);

    yield call(Api.deleteTemplate, {
      credentials,
      templateId
    });

    yield put(actions.refetchTemplates());
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error deleting a template"
          }
        ])
      )
    ]);
  }
};

const goToReports = function*() {
  const { eventId } = yield call(getParams);
  window.location = `/event/${eventId}/reports`;
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchDeleteTemplate = function*() {
  yield takeEvery(actions.deleteTemplate.type, deleteTemplate);
};

const watchRefetchTemplates = function*() {
  yield takeEvery(actions.refetchTemplates.type, refetchTemplates);
};

const watchCreateTemplate = function*() {
  yield takeEvery(actions.createTemplate.type, createTemplate);
};

const watchCopyTemplate = function*() {
  yield takeEvery(actions.copyTemplate.type, copyTemplate);
};

const watchGoToReports = function*() {
  yield takeEvery(actions.goToReports.type, goToReports);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchRefetchTemplates),
    fork(watchCreateTemplate),
    fork(watchCopyTemplate),
    fork(watchDeleteTemplate),
    fork(watchGoToReports)
  ]);
};

export default rootSaga;
