import React from "react";

import { connect } from "react-redux";
import { Div, BigFilledButton, Text5, AddCircleIcon } from "components/Base";

import { actions, getters } from "../model";

import Table, { TableWrapper } from "ui-kit/Table/View";
import { TABLE_INSTANCE_ID } from "../constants";
import EmptyState from "EventLight/Common/EmptyState";

const decorate = connect(
  state => ({
    content: getters.content(state)
  }),
  {
    showAddContentModal: () => actions.setShowAddContentModal(true)
  }
);

const Body = ({ content, showAddContentModal }) => (
  <Div display="column" width={1}>
    <Div display="row.space-between.center" mb={4}>
      <Text5 bold color="neutral9">
        Content
      </Text5>
      <Div ml={5} display="row.flex-start.center">
        <BigFilledButton
          color="white"
          bg="altB5"
          LeftIcon={AddCircleIcon}
          onClick={showAddContentModal}
        >
          Add Content
        </BigFilledButton>
      </Div>
    </Div>
    {!content.length ? (
      <EmptyState
        icon="ondemand_video"
        title="You haven't added any content yet"
        description="You can add content that you want to gate to certain ticket holders or to make available to all attendees"
        action="Add Content"
        onClick={showAddContentModal}
      />
    ) : (
      <TableWrapper
        instanceId={TABLE_INSTANCE_ID}
        style={{ flex: 1, overflowY: "inherit" }}
        innerStyle={{ overflowY: "inherit" }}
      >
        <Table instanceId={TABLE_INSTANCE_ID} showCheckbox={false} />
      </TableWrapper>
    )}
  </Div>
);

export default decorate(Body);
