import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Back from "../Common/Back";
import PrimaryAction from "../Common/PrimaryAction";
import SecondaryAction from "../Common/SecondaryAction";
import ButtonContainer from "../Common/ButtonContainer";
import Label from "../Common/Label";
import Form from "../Common/Form";
import Input from "../Common/Input";
import ErrorMessage from "../Common/Error";
import Container from "../Common/Container";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const isValid = (first, last) => first && last;
const onSubmit = event => event.preventDefault();

@CSSModules(css)
export default class Person extends Component {
  constructor(props) {
    super(props);

    const searchWords = props.search.split(" ");

    this.state = {
      first: searchWords.shift() || "",
      last: searchWords.join(" ") || "",
      processing: false
    };
  }

  @autobind
  changeState(field, event) {
    this.setState({
      [field]: event.target.value
    });
  }

  @autobind
  async createPerson() {
    const { first, last } = this.state;
    if (!isValid(first, last)) return;

    this.setState({
      processing: true
    });

    try {
      await this.props.createPerson(first, last);
    } catch (e) {
      this.setState({
        processing: false,
        error: "Something went wrong! Please try again."
      });
    }
  }

  render() {
    const { changeScene, close, allowPlaceholder } = this.props;
    const { first, last, error } = this.state;

    return (
      <Container>
        <Back onClick={() => changeScene("list", "back")} />
        <Form onSubmit={onSubmit}>
          <Label>First Name</Label>
          <Input
            styleName="input"
            value={first}
            onChange={this.changeState.bind(null, "first")}
            type="text"
          />
          <Label>Last Name</Label>
          <Input
            styleName="input"
            value={last}
            onChange={this.changeState.bind(null, "last")}
            type="text"
          />
          {allowPlaceholder ? (
            <div styleName="link" onClick={() => changeScene("placeholder")}>
              I don’t know their name yet
            </div>
          ) : (
            <div styleName="link" />
          )}
          <ButtonContainer>
            <PrimaryAction
              disabled={!isValid(first, last)}
              processing={this.state.processing}
              onClick={this.createPerson}
            >
              Add Person
            </PrimaryAction>
            <SecondaryAction onClick={close}>Cancel</SecondaryAction>
          </ButtonContainer>
          {error ? <ErrorMessage>{error}</ErrorMessage> : ""}
        </Form>
      </Container>
    );
  }
}

Person.propTypes = {
  allowPlaceholder: PropTypes.bool,
  changeScene: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  createPerson: PropTypes.func.isRequired,
  search: PropTypes.string.isRequired
};
