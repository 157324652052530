import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ByStatus from "./ByStatus";

import { getModulesWithCatalogItems } from "redux/modules/inventory/modules/actions";
import { modules } from "redux/modules/inventory/modules/selectors";

import * as ModalActions from "redux/modules/modal/actions";
import * as EventSelectors from "redux/modules/event/selectors";
import { getModuleCounts } from "redux/modules/inventory/modules/counts/actions";
import { countsByModuleAndStatus } from "redux/modules/inventory/modules/counts/selectors";

function mapStateToProps(state, { params }) {
  return {
    parents: modules(state),
    eventDetails: EventSelectors.eventDetails(state),
    panelCachedCount: (parentId, status) =>
      countsByModuleAndStatus(parentId, status, state),
    status: params.view
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getModuleCounts,
      getModulesWithCatalogItems,
      ...ModalActions
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ByStatus);
