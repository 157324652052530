import Helpers from "utils/Global/Helpers";

/* eslint no-underscore-dangle: "off" */

export default {
  getFields: ({ credentials, moduleId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules-v2/dashboard/${moduleId}/fields`,
        credentials,
        qs: data,
        success,
        error
      });
    })
};
