import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import ColorPicker from "components/Global/ColorPicker";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class Option extends Component {
  @autobind
  updateValue(e) {
    const { handleUpdateOption, option, index } = this.props;
    handleUpdateOption(index, {
      ...option,
      value: e.target.value
    });
    if (index === -1) {
      this.refs.input.value = "";
    }
  }

  @autobind
  updateColor(colors) {
    const { handleUpdateOption, option, index } = this.props;
    handleUpdateOption(index, { ...option, ...colors });
  }

  render() {
    const { option, placeholder, index, handleRemoveOption } = this.props;
    return (
      <div styleName="container">
        <div styleName="inputWrapper">
          {index !== -1 ? (
            <input
              ref="input"
              styleName="inputWithActions"
              type="text"
              value={option.value}
              onChange={this.updateValue}
              placeholder={
                typeof placeholder !== "undefined" ? placeholder : ""
              }
            />
          ) : (
            <input
              ref="input"
              styleName="input"
              type="text"
              defaultValue={option.value}
              onBlur={this.updateValue}
              onKeyDown={e =>
                e.keyCode === 13 ? this.updateValue(e) : undefined
              }
              placeholder={
                typeof placeholder !== "undefined" ? placeholder : ""
              }
            />
          )}
          {index !== -1 && (
            <div styleName="colorIcon">
              <ColorPicker selected={option} onSelect={this.updateColor} />
            </div>
          )}
          {index !== -1 ? (
            <i
              className="material-icons"
              styleName="remove"
              onClick={handleRemoveOption.bind(this, index, option)}
            >
              close
            </i>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

Option.propTypes = {
  option: PropTypes.object.isRequired,
  handleUpdateOption: PropTypes.func.isRequired,
  handleRemoveOption: PropTypes.func,
  index: PropTypes.number.isRequired,
  placeholder: PropTypes.string
};

export default Option;
