import React from "react";

import * as R from "ramda";

import { connect } from "react-redux";

import {
  Div,
  PopMenu,
  PopMenuCard,
  Text2,
  SmallFilledButton,
  AddIcon
} from "components/Base";

import { actions } from "Orders/TicketsPasses";

import { getProfileNoneSelected } from "Orders/TicketsPasses/selectors";

import LimitPopOver from "Orders/TicketsPasses/View/LimitPopOver";

const decorate = connect(
  state => ({
    noneSelected: getProfileNoneSelected(state)
  }),
  {
    showLimitPopOver: actions.showLimitPopOver
  }
);

const ProfileLimitPopOver = ({
  showLimitPopOver,
  handlers: { profileId, prices, limit }
}) => {
  return (
    <PopMenu
      Label={({ onClick }) => (
        <Div
          onClick={() => {
            showLimitPopOver({ profileId, updateAllTempProfiles: false });
            onClick();
          }}
        >
          {R.isEmpty(prices) && limit < 1 ? (
            <SmallFilledButton bg="primary7">
              <AddIcon color="white" />
            </SmallFilledButton>
          ) : (
            <Text2 color="primary7" underline>
              Edit
            </Text2>
          )}
        </Div>
      )}
    >
      {({ closeMenu }) => (
        <PopMenuCard position="bottom" align="right">
          <LimitPopOver handlers={{ prices, closeMenu }} />
        </PopMenuCard>
      )}
    </PopMenu>
  );
};

export default decorate(ProfileLimitPopOver);
