import {
  put,
  all,
  take,
  takeEvery,
  fork,
  select,
  call
} from "redux-saga/effects";
import * as R from "ramda";

import { CREDENTIAL_TYPE_ID } from "utils/item-types";
import { TABLE_INSTANCE_ID, FIELD_IDS, FORM_ID } from "./constants";
import { ROW_ACTIONS } from "ui-kit/FieldTypes/constants";

import { getCredentials } from "redux/modules/user/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";

import { actions as TableActions } from "ui-kit/Table/model";
import { actions, getters } from "./model";
import { registerError } from "redux/modules/errors/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";

import Api from "./api";
import itemTypesApi from "redux/modules/items/types/api";

import { getEditedField } from "./selectors";
import { actions as FormActions } from "ui-kit/Form/model";

import { actions as contentModalActions } from "EventLight/Content/AddContentModal/model";

import {
  getters as PaginationGetters,
  actions as PaginationActions
} from "ui-kit/PaginationBar";
import {
  getters as SearchBarGetters,
  actions as SearchBarActions
} from "ui-kit/SearchBar";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventDetails = yield select(getEventDetails);

  const page = yield select(PaginationGetters.page);
  const pageSize = yield select(PaginationGetters.pageSize);
  const search = yield select(SearchBarGetters.searchTerm);
  const sortBy = yield select(getters.sortBy);
  const scopes = yield select(getters.scopes);

  return {
    credentials,
    eventDetails,
    filters: {
      page: page + 1,
      pageSize,
      search,
      sortBy,
      scopes
    }
  };
};

const search = function*({ type }) {
  try {
    yield put(actions.setSearching(true));

    /*
    if (!payload.refresh) {
      yield put(actions.setLoading(true));
    }
    if ([
      PaginationActions.setPageSize.type,
      SearchBarActions.clearSearch.type,
      SearchBarActions.setSearchTerm.type,
      actions.setSortBy.type
    ].includes(type)) {
      yield put(actions.setSearching(true));
    }
    */

    const { credentials, eventDetails, filters } = yield call(getParams);

    const result = yield call(Api.getContent, {
      credentials,
      eventId: eventDetails.id,
      filters
    });

    // set data
    yield all([
      put(
        actions.setInitialData({
          show: R.path(["payload", "show"])(result),
          sortBy: R.path(["payload", "sort_by"])(result),
          pagination: R.path(["payload", "pagination"])(result),
          content: R.path(["payload", "rows"])(result)
        })
      ),
      put(
        TableActions.setData(
          {
            canEditCells: false,
            columns: R.prop("columns")(result.payload),
            columnWidths: R.prop("column_widths")(result.payload),
            rows: R.prop("rows")(result.payload)
          },
          {
            meta: {
              instanceId: TABLE_INSTANCE_ID
            }
          }
        )
      )
    ]);

    // set empty state
    if (
      type === actions.init.type &&
      !R.path(["payload", "rows"])(result).length
    ) {
      yield put(actions.setShowEmptyState(true));
    }

    // set pagination
    const pagination = R.pathOr({}, ["payload", "pagination"], result);
    if (
      [
        PaginationActions.setPageSize.type,
        SearchBarActions.clearSearch.type,
        SearchBarActions.setSearchTerm.type,
        actions.setSortBy.type
      ].includes(type)
    ) {
      yield put(
        PaginationActions.setPagination({
          ...pagination,
          page: 0
        })
      );
    } else {
      yield put(
        PaginationActions.setPagination({
          ...pagination,
          page: pagination.page - 1
        })
      );
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting content"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
    yield put(actions.setSearching(false));
  }
};

const init = function*(opts) {
  yield all([
    put(PaginationActions.setPageSize(15)),
    put(PaginationActions.setPage(0)),
    put(actions.reset())
  ]);

  yield call(search, opts);
};

const showEditContentModal = function*({ payload: { row } }) {
  yield put(contentModalActions.showContentModal({ id: row.data.id }));
};

const onShowSelectPassesModal = function*({ payload: showModal }) {
  if (showModal) {
    try {
      yield put(actions.setLoadingItemTypes(true));
      const { credentials, eventDetails } = yield call(getParams);

      const result = yield call(
        itemTypesApi.getItemTypesByEvent,
        credentials,
        eventDetails.id
      );

      yield put(actions.setItemTypes(result.payload));
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error occurred while getting items"
          }
        ])
      );
    } finally {
      yield put(actions.setLoadingItemTypes(false));
    }
  }
};

const onSaveSelectPassesModal = function*({ payload }) {
  try {
    const { credentials, eventDetails } = yield call(getParams);

    const items = R.compose(
      R.map(itemId => ({
        id: itemId,
        enabled: true
      }))
    )(R.keys(payload));

    yield call(Api.updateContent, {
      credentials,
      eventId: eventDetails.id,
      data: { items }
    });
    yield call(search, { payload: { refresh: true } });
    yield put(showSnackbar({ message: "Passes updated", action: "OK" }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating items"
        }
      ])
    );
  } finally {
    yield put(actions.setShowSelectPassesModal(false));
  }
};

const goToCatalog = function*() {
  const { eventDetails } = yield call(getParams);
  window.location = `/event/${eventDetails.id}/settings/catalog/type/${CREDENTIAL_TYPE_ID}/items`;
};

const onHideAddContentModal = function*() {
  yield call(search, { payload: { refresh: true } });
};

const deleteContent = function*({ payload: { row } }) {
  try {
    const { credentials, eventDetails } = yield call(getParams);

    yield call(Api.deleteContent, {
      credentials,
      eventId: eventDetails.id,
      data: {
        contentIds: [row.id]
      }
    });

    yield put(showSnackbar({ message: "Content Deleted" }));
    yield call(search, { payload: { refresh: true } });
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while deleting content"
        }
      ])
    );
  }
};

export const openRecord = function*({
  payload: {
    row: {
      data: { url }
    }
  }
}) {
  yield call([window, window.open], url, "_blank");
};

const fieldUpdate = function*({ id, fieldId }) {
  const newValue = yield select(getEditedField, {
    instanceId: id,
    fieldId
  });

  const processedValue = R.propOr(newValue, fieldId, {
    [FIELD_IDS.THUMBNAIL_URL]: R.path([0, "url"], newValue),
    [FIELD_IDS.ORDER]: isNaN(newValue) ? null : newValue
  });

  yield put(actions.updateField({ field: fieldId, value: processedValue }));
};

const watchDelete = function*() {
  yield takeEvery(actions.deleteContent.type, deleteContent);
};

const watchTableActions = function*() {
  for (;;) {
    const action = yield take(TableActions.executeAction.type);
    const delegate = R.prop(action.payload.actionId, {
      [ROW_ACTIONS.EDIT_RECORD]: showEditContentModal,
      [ROW_ACTIONS.DELETE_RECORD]: deleteContent,
      [ROW_ACTIONS.OPEN_RECORD]: openRecord
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchShowSelectPassesModal = function*() {
  yield takeEvery(
    actions.setShowSelectPassesModal.type,
    onShowSelectPassesModal
  );
};

const watchShowAddContentModal = function*() {
  yield takeEvery(
    contentModalActions.closeContentModal.type,
    onHideAddContentModal
  );
};

const watchSaveSelectPassesModal = function*() {
  yield takeEvery(actions.saveSelectPassesModal.type, onSaveSelectPassesModal);
};

const watchGoToCatalog = function*() {
  yield takeEvery(actions.goToCatalog.type, goToCatalog);
};

const watchUpdateFields = function*() {
  for (;;) {
    const {
      meta: { instanceId, fieldId }
    } = yield take(FormActions.setFieldValue.type);
    if (instanceId === FORM_ID) {
      yield call(fieldUpdate, {
        id: instanceId,
        fieldId
      });
    }
  }
};

const watchSearch = function*() {
  yield takeEvery(
    [
      SearchBarActions.clearSearch.type,
      SearchBarActions.setSearchTerm.type,
      PaginationActions.setPage.type,
      PaginationActions.setPageSize.type,
      actions.setSortBy.type
    ],
    search
  );
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchShowAddContentModal),
    fork(watchShowSelectPassesModal),
    fork(watchSaveSelectPassesModal),
    fork(watchGoToCatalog),
    fork(watchTableActions),
    fork(watchUpdateFields),
    fork(watchDelete),
    fork(watchSearch)
  ]);
};

export default rootSaga;
