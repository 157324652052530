import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { getters, actions } from "../model";
import { Div, Text4, MediumFilledButton, DownloadIcon } from "components/Base";
import InfoMeal from "./InfoMeal";
import HeaderMeal from "./HeaderMeal";

import { showSnackbar } from "redux/modules/snackbar/actions";

const decorate = connect(
  state => ({
    data: getters.data(state)
  }),
  {
    downloadReportDays: actions.downloadReportDays,
    showSnackbar
  }
);

const MealDays = ({ data: { days }, downloadReportDays, showSnackbar }) => (
  <Div width={774}>
    {R.map(
      ({ name, meals, export_url }) => (
        <Div key={name} width={1} bg="white" bra={2} p={4} mb={2}>
          <Div width={1} display="row.space-between.center" mb={3}>
            <Text4 bold>{name}</Text4>
            {meals.length > 0 && (
              <MediumFilledButton
                LeftIcon={DownloadIcon}
                color="neutral6"
                bg="neutral1"
                onClick={() => {
                  showSnackbar({ message: "Exporting...", action: "OK" });
                  downloadReportDays(export_url);
                }}
              >
                Download Report
              </MediumFilledButton>
            )}
          </Div>
          <HeaderMeal meals={meals} />
          <InfoMeal meals={meals} />
        </Div>
      ),
      days
    )}
  </Div>
);

export default decorate(MealDays);
