import React from "react";
import { connect } from "react-redux";
import { Div, MediumFilledButton } from "components/Base";
import { getters, actions } from "Portal/PortalHome/model";

const decorate = connect(
  state => ({
    filters: getters.filters(state)
  }),
  {
    selectFilters: actions.selectFilters
  }
);

const FilterBy = ({ filters, selectFilters }) => (
  <Div pill bg="neutral2" p={1}>
    {filters.map(({ id, text, selected }, index) => (
      <MediumFilledButton
        pill
        bg={selected ? "neutral1" : "neutral2"}
        color="gray8"
        key={id}
        onClick={() => selectFilters(id)}
        mr={filters.length - 1 === index ? 0 : 1}
      >
        {text}
      </MediumFilledButton>
    ))}
  </Div>
);

export default decorate(FilterBy);
