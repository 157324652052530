import { RECEIVE, REQUEST } from "./constants";
import { combineReducers } from "redux";

export const defaultMealState = {
  scans: [],
  latestScan: {},
  checkins: 0,
  overrides: 0
};

const meals = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      const { id, ...rest } = action.payload;
      state[id] = { ...defaultMealState, ...state[id], ...rest };
      return state;
    default:
      return state;
  }
};
const fetching = (state = false, action) => {
  switch (action.type) {
    case RECEIVE:
      return false;
    case REQUEST:
      return true;
    default:
      return state;
  }
};

export default combineReducers({
  meals,
  fetching
});
