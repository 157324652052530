import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const ActionButton = ({ title, primary, description, ...other }) => (
  <div
    className={primary ? css.primary : ""}
    styleName="optionContainer"
    {...other}
  >
    <div styleName="optionLabel">
      <i className={["material-icons", css.buttonIcon].join(" ")}>add</i>
      <div>
        <div styleName="optionTitle">{title}</div>
        <div styleName="optionDescription">{description}</div>
      </div>
    </div>
    <i className={["material-icons", css.optionArrow].join(" ")}>
      keyboard_arrow_right
    </i>
  </div>
);

ActionButton.propTypes = {
  primary: PropTypes.bool,
  styles: PropTypes.object,
  title: PropTypes.string.isRequired,
  description: PropTypes.string
};

export default CSSModules(ActionButton, css);
