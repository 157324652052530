import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function createOpenFormSubmission(data) {
  return async (dispatch, getState) => {
    try {
      return await api.post(data);
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred creating submsision"
          }
        ])
      );
    }
  };
}
