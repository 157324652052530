import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Popover from "@lennd/material-ui/Popover";
import { ArrowRenderer } from "components/Global/Editors/utils/reactSelect";
import { Dropdown } from "components/Base";

@CSSModules(css)
class GroupByPicker extends Component {
  state = { showPopover: false };

  onRequestClose = () => this.setState({ showPopover: false });

  handleClick = e => {
    e.stopPropagation();
    return this.setState({ showPopover: true });
  };

  handleSelectChange = selected => {
    this.props.onChange({ groupedBy: selected ? selected.value : selected });
    this.onRequestClose();
  };

  render() {
    const { fields, selectedFieldId } = this.props;

    return (
      <div>
        <span
          onClick={this.handleClick}
          ref={ref => {
            this.anchor = ref;
          }}
        >
          {this.props.children}
        </span>
        <Popover
          anchorEl={this.anchor}
          anchorOrigin={{ horizontal: "left", vertical: "top" }}
          onClose={this.onRequestClose}
          open={this.state.showPopover}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
        >
          <div styleName="container">
            <div styleName="heading">Group by</div>
            <div styleName="selectWrapper">
              <Dropdown
                arrowRenderer={ArrowRenderer}
                onChange={this.handleSelectChange}
                options={fields.map(field => ({
                  label: field.name,
                  value: field.id
                }))}
                placeholder="Select a field..."
                searchable
                simpleValue
                value={selectedFieldId}
                usePortal
                menuPlacement="auto"
              />
            </div>
            <div styleName="buttons">
              <div styleName="cancel" onClick={this.onRequestClose}>
                Cancel
              </div>
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}

GroupByPicker.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedFieldId: PropTypes.string
};

export default GroupByPicker;
