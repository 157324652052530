import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import { actions, getters } from "Modules/AddEditColumnModal/model";
import DateTimeEditorSettings from "components/Global/Table3/ModalColumn/DateTimeEditorSettings";
import DateTimeFormatSettings from "components/Global/Table3/ModalColumn/DateTimeFormatSettings";
import { noop } from "utils/General";
import css from "./style.scss";

const decorate = R.compose(
  connect(
    (state, props) => ({
      dateTimeSettings: getters.dateTimeSettings(state, props)
    }),
    {
      setDateTimeSettings: actions.setDateTimeSettings
    }
  ),
  CSSModules(css)
);

const DateTimeSettings = ({ dateTimeSettings, setDateTimeSettings = noop }) => (
  <>
    {/*
  <div styleName="dropdownOptionsWrapper">
    <DateTimeFormatSettings
      value={dateTimeSettings}
      onChange={setDateTimeSettings}
    />
    <DateTimeEditorSettings
      value={dateTimeSettings}
      onChange={setDateTimeSettings}
    />
  </div>
  */}
  </>
);

export default decorate(DateTimeSettings);
