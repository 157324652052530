import Helpers from "utils/Global/Helpers";

module.exports = {
  get: (credentials, { mealId, limit }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/catering/checkin/${mealId}`,
        qs: { limit },
        credentials,
        success,
        error
      });
    }),
  getStats: (credentials, { mealId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/catering/checkin/${mealId}/stats`,
        credentials,
        success,
        error
      });
    }),
  post: ({
    mealId,
    credentials,
    type,
    query,
    parentCheckinId,
    contactId,
    accountId,
    message
  }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/catering/checkin/${mealId}`,
        data: {
          type,
          query,
          parentCheckinId,
          contactId,
          accountId,
          message
        },
        credentials,
        success,
        error
      });
    }),
  validateManagerOverride: (credentials, { code, eventId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/catering/checkin/override`,
        data: {
          code,
          eventId
        },
        credentials,
        success: ({ payload }) => success(payload),
        error
      });
    })
};
