import React from "react";
import { Div, BigOutlineButton, StarIcon } from "components/Base";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import Tabs from "ui-kit/Tabs/View";
import Video from "./Video";
import RelatedContent from "./Sections/RelatedContent";
import Questions from "./Sections/Questions";
import People from "./Sections/People";
import Details from "./Sections/Details";
import { sessionTabs, selectedSessionTabs } from "../selectors";
import { PAGE_ACTIONS, CONFERENCE_TABS_INSTANCE_ID } from "../constants";

const decorator = connect(
  (state, props) => ({
    session: getters.sessionDetails(state, props),
    sessionDetailTabs: sessionTabs(state, props),
    selectedSessionTabs: selectedSessionTabs(state, props)
  }),
  { executeAction: actions.executeAction }
);

const SessionDetail = ({
  session: { video_url, conference_url },
  sessionDetailTabs,
  executeAction,
  selectedSessionTabs
}) => (
  <Div>
    <Div>
      <Video url={video_url} />
    </Div>
    <Div
      display="row.flex-start.center"
      style={{ borderLeft: "4px solid #F8B22A", padding: 20 }}
    >
      <Div style={{ marginRight: 12, alignSelf: "flex-start" }}>
        <StarIcon size={30} color="warning5" />
      </Div>
      {conference_url && (
        <Div display="column.flex-start.flex-start">
          <Div color="black" fw={3} fs={4}>
            Participate in the Video Conference
          </Div>
          <Div fs={4}>{conference_url}</Div>
        </Div>
      )}

      <Div style={{ marginLeft: "auto" }}>
        <BigOutlineButton
          onClick={() =>
            executeAction({
              type: PAGE_ACTIONS.OPEN_CONFERENCE,
              conference_url
            })
          }
        >
          Open Link
        </BigOutlineButton>
      </Div>
    </Div>
    <Div
      style={{
        padding: 20,
        border: "1px solid #f0f0f0",
        borderRight: "none"
      }}
    >
      <Tabs
        instanceId={CONFERENCE_TABS_INSTANCE_ID}
        tabs={sessionDetailTabs}
        tabStyle={{
          activeColor: "#0A6EF4",
          color: "#222222",
          barColor: "#0A6EF4",
          fs: 4
        }}
      />
    </Div>
    <Div
      style={{
        padding: 41,
        border: "1px solid #f0f0f0",
        borderRight: "none",
        borderBottom: "none"
      }}
    >
      {selectedSessionTabs === "people" ? (
        <People />
      ) : selectedSessionTabs === "details" ? (
        <Details />
      ) : selectedSessionTabs === "questions" ? (
        <Questions />
      ) : selectedSessionTabs === "related_content" ? (
        <RelatedContent />
      ) : null}
    </Div>
  </Div>
);

export default decorator(SessionDetail);
