import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import { NAMESPACE, TABS } from "./constants";

const iniState = {
  loading: true,
  creatingSubmission: false,
  lightMode: "",
  darkMode: "",
  isApproved: false,
  mode: TABS.LIGHT_MODE,
  editProfileFormId: null,
  editProfileFormSlug: null,
  editProfileFormBaseModuleId: null,
  editProfileSubmissionId: null,
  accountId: null,
  redirectToDashboardRecordTypeId: null
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    goToForms: R.identity,
    goToSubmission: R.identity,
    approveProfile: R.identity,
    setInitialData: (
      _,
      {
        payload: {
          edit_profile_form_id,
          edit_profile_form_slug,
          edit_profile_form_base_module_id,
          edit_profile_submission_id,
          is_approved,
          light_mode,
          dark_mode,
          accountId,
          redirectToDashboardRecordTypeId
        }
      }
    ) => ({
      editProfileFormId: edit_profile_form_id,
      editProfileFormSlug: edit_profile_form_slug,
      editProfileFormBaseModuleId: edit_profile_form_base_module_id,
      editProfileSubmissionId: edit_profile_submission_id,
      isApproved: is_approved,
      lightMode: light_mode,
      darkMode: dark_mode,
      accountId,
      redirectToDashboardRecordTypeId
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
