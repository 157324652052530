import PropTypes from "prop-types";
import React, { Component } from "react";
import Editor from "../../Editors/Phone";

export default class PhoneEditor extends Component {
  render() {
    return (
      <Editor
        {...this.props}
        phoneDisplayType={this.props.column.settings.phoneDisplayType}
      />
    );
  }
}

PhoneEditor.propTypes = {
  column: PropTypes.object.isRequired
};
