import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getters } from "Modules/CloneModuleModal";
import { PAGES } from "Modules/CloneModuleModal/constants";
import SelectEvent from "./SelectEvent";
import SelectContent from "./SelectContent";
import Confirmation from "./Confirmation";

const decorate = connect(state => ({
  selectedPage: getters.selectedPage(state)
}));

const Body = ({ handlers, selectedPage }) => {
  if (selectedPage === PAGES.SELECT_EVENT) {
    return <SelectEvent handlers={handlers} />;
  } else if (selectedPage === PAGES.SELECT_CONTENT) {
    return <SelectContent handlers={handlers} />;
  } else if (selectedPage === PAGES.CONFIRMATION) {
    return <Confirmation />;
  }
};

Body.propTypes = {
  selectedPage: PropTypes.func.isRequired
};

export default decorate(Body);
