import { put, all, takeEvery, fork } from "redux-saga/effects";
import { actions } from "./model";

const init = function*({ payload }) {
  yield put(
    actions.setInitialData({
      ...payload
    })
  );
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([fork(watchInit)]);
};

export default rootSaga;
