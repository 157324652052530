import React, { Component } from "react";
import * as R from "ramda";
import { Div } from "components/Base";

import { getHandlers, getMeta } from "ui-kit/Datagrid/utils";

import { noop } from "utils/General";

export default class ViewSubmissionCellFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !R.equals(getMeta(this.props), getMeta(nextProps));
  }

  render() {
    const submissionId = getMeta(this.props, ["submission", "id"]);
    const showSubmissionSummary = getHandlers(
      this.props,
      "showSubmissionSummary",
      noop
    );

    return (
      <Div
        textAlign="center"
        onClick={() => showSubmissionSummary(submissionId)}
      >
        View Submission
      </Div>
    );
  }
}
