import { RECEIVE, REQUEST } from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getNeeds({ catalogItemId, eventId }) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: REQUEST,
        payload: results
      });
      const results = await api.get(getState().user.user.credentials, {
        eventId,
        catalogItemId
      });
      dispatch({
        type: RECEIVE,
        payload: results
      });
      return results;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching sub-modules"
          }
        ])
      );
    }
  };
}
