import { createSelector } from "reselect";
import * as R from "ramda";
import { TAB_IDS } from "./constants";

export const getCurrentRoute = createSelector(
  (_, props) => props.routes,
  routes => {
    if (R.any(R.propEq("name", "EventLightFormsResults"))(routes)) {
      return TAB_IDS.RESULTS;
    } else if (R.any(R.propEq("name", "EventLightFormsApprovers"))(routes)) {
      return TAB_IDS.APPROVERS;
    }
    return null;
  }
);
