import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import getValue from "utils/value-types/get-value";
import Recipient from "../Recipient";
import Helpers from "utils/Global/Helpers";
import getOrderedFields from "components/Event/FormsV2/Utils/get-ordered-fields";
import { CONTACT_STANDARD_FIELD_IDS } from "components/Event/Contacts/constants";
import isRowNotEmpty from "utils/EventForms/FormGrid/isRowNotEmpty";
import { get, sortBy } from "lodash";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const getMappedEmailFields = (mappings = []) =>
  mappings
    .filter(
      mapping =>
        mapping.destination_field_id === CONTACT_STANDARD_FIELD_IDS.email
    )
    .map(mapping => mapping.source_field_id);

const getSubforms = fields => fields.filter(field => field.type === "subform");

const isEmailValid = email => Boolean(email) && Helpers.isValidEmail(email);

const formatEmail = email => (email || "").toLowerCase().trim();

@CSSModules(css)
class Recipients extends Component {
  constructor(props) {
    super(props);

    const { form, submission } = props;

    const topLevelRequest = {
      row: submission,
      fields: sortBy(form.fields, "order")
    };

    const mappedEmailFields = getMappedEmailFields(form.mapped_fields);
    const subforms = getSubforms(form.fields);

    // Get collaborator emails
    const collaboratorEmails = submission.collaborators
      .map(c => formatEmail(c.email))
      .filter(isEmailValid);

    // Get emails used on the top level
    const topLevelEmails = form.fields
      .filter(field => mappedEmailFields.includes(field.id))
      .map(({ id, type }) => formatEmail(getValue(submission.values[id], type)))
      .filter(isEmailValid);

    // Get subforms with an email field in them
    const subformsWithEmails = subforms.filter(({ subform }) =>
      subform.form.fields.some(({ id }) => mappedEmailFields.includes(id))
    );

    // Get each row from a subform with an email and associate it to the email
    const subformRequests = subformsWithEmails.map(({ subform, id }) => {
      const submissions = get(
        submission,
        ["values", id, "value", "submissions"],
        {}
      );
      return Object.keys(submissions).map(key => {
        const row = submissions[key];
        // Only grabs the first. If there are more need to figure it out.
        const emailField = subform.form.fields.find(field =>
          mappedEmailFields.includes(field.id)
        );
        const email = formatEmail(
          getValue(row.values[emailField.id], emailField.type)
        );
        return {
          row,
          email,
          fields: getOrderedFields(subform.form.fields_grouped_by_section)
        };
      });
    });

    // Flatten the subform requests and remove any with invalid emails
    const requests = []
      .concat(...subformRequests)
      .filter(({ email }) => isEmailValid(email));

    // Add those to an email hash to de-dupe
    let recipientsEntries = requests.reduce((hash, request) => {
      const entries = (hash[request.email] = hash[request.email] || []);
      entries.push(request);
      return hash;
    }, {});

    // Get all rows
    const allSubformRequests = subforms.map(({ subform, id }) => {
      const submissions = get(
        submission,
        ["values", id, "value", "submissions"],
        {}
      );
      return Object.keys(submissions)
        .filter(key =>
          isRowNotEmpty(submissions[key].values, subform.form.fields)
        )
        .map(key => {
          const row = submissions[key];
          // Only grabs the first. If there are more need to figure it out.
          return {
            row,
            fields: getOrderedFields(subform.form.fields_grouped_by_section)
          };
        });
    });

    // Flatten them
    const allRequests = [].concat(...allSubformRequests);

    // Add them to the email hash so we blow out emails at the top level AND subforms
    recipientsEntries = [...topLevelEmails, ...collaboratorEmails].reduce(
      (hash, email) => {
        hash[email] = allRequests;
        return hash;
      },
      recipientsEntries
    );

    this.state = {
      collaboratorEmails,
      recipientsEntries,
      topLevelRequest
    };
  }

  render() {
    const {
      message,
      eventDetails,
      selectedRecipients,
      toggleRecipient,
      submission,
      title
    } = this.props;

    // Generate Link Button
    // TODO: Need to clean this up, but works for now.
    const link = `
      <a
        href="${window.__LENND_APP_URL__}/portals/${eventDetails.slug}/${
      eventDetails.uuid
    }/summary/${submission.id}"
        target="_blank"
        class="hide-in-preview"
        style="color: #ffffff;font-weight: normal;font-size:15px;text-decoration: none;width:150px;height:44px;display:block;background-color:#00CC83;text-align:center;vertical-align:middle;line-height:1">
        View Form
      </a>
    `;

    const { topLevelRequest, collaboratorEmails } = this.state;
    const recipientsEntries = { ...this.state.recipientsEntries };

    return (
      <div>
        <div>
          <div styleName="heading">Form Collaborators</div>
          <div styleName="collaboratorList">
            {collaboratorEmails.map(email => {
              const component = (
                <Recipient
                  content={`${message} ${link}`}
                  email={email}
                  requests={recipientsEntries[email]}
                  selected={Object.keys(selectedRecipients).includes(email)}
                  toggleRecipient={toggleRecipient}
                  topLevelRequest={topLevelRequest}
                  eventDetails={eventDetails}
                  header={title}
                />
              );
              // Remove from the list of emails
              delete recipientsEntries[email];
              return component;
            })}
          </div>
          {Object.keys(recipientsEntries).length ? (
            <div>
              <div styleName="heading">Others on the form</div>
              <div styleName="caption">
                Selected people will receive an email report with their related
                requests.
              </div>
              <div styleName="listWrapper">
                <div styleName="list">
                  {Object.keys(recipientsEntries).map(email => (
                    <Recipient
                      content={message}
                      email={email}
                      eventDetails={eventDetails}
                      requests={recipientsEntries[email]}
                      selected={Object.keys(selectedRecipients).includes(email)}
                      toggleRecipient={toggleRecipient}
                      topLevelRequest={topLevelRequest}
                      header={title}
                    />
                  ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

Recipients.propTypes = {
  eventDetails: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  selectedRecipients: PropTypes.object,
  submission: PropTypes.object.isRequired,
  toggleRecipient: PropTypes.func.isRequired
};

export default Recipients;
