import React, { Component } from "react";
import { LOGIN_RETURN_TO_KEY } from "../LoginView";
import PlatformLoading from "components/Platform/PlatformLoading";
import { push } from "react-router-redux";

import ActivityApi from "redux/modules/activity/api";
import ActivityContstants from "redux/modules/activity/constants";
import { connect } from "react-redux";
import { getCredentials } from "redux/modules/user/selectors";

const decorate = connect(
  state => ({
    userCredentials: getCredentials(state)
  }),
  { push }
);

class LoginCallbackHandler extends Component {
  async componentDidMount() {
    await ActivityApi.post({
      actorId: this.props.userCredentials.userId,
      type: ActivityContstants.LOGIN
    });

    const url = localStorage.getItem(LOGIN_RETURN_TO_KEY);
    localStorage.removeItem(LOGIN_RETURN_TO_KEY);

    if (url && url.length) {
      this.props.push(url);
    } else {
      this.props.push(
        `/home?message=${encodeURIComponent("You have been logged in.")}`
      );
    }
  }

  render() {
    return <PlatformLoading message="Logging in..." />;
  }
}

export default decorate(LoginCallbackHandler);
