export const NAMESPACE = "ImportModal";

export const PAGES = {
  IMPORT_FILE: "importFile",
  COPY_PASTE: "copyPaste",
  MAP_CSV: "mapCsv",
  MAP_CSV_PREVIEW: "mapCsvReview",
  INVALID_ROWS: "invalidRows",
  SUMMARY_SCENE: "summaryScene"
};

export const FIELD_IDS = {
  FULL_NAME: "4da66db6-a49a-469f-a5d5-d89a17dfd739",
  FIRST_NAME: "7608ee49-290c-4028-b4f9-32e231e65091",
  LAST_NAME: "aab68ab0-402c-434f-aecb-6d17fe582725"
};

export const DEFAULT_VALUE = "DefaultValue";

export const ACCEPTABLE_FORMATS = {
  "full-name": `Any Text. First & Last Name will be split on the first space in the imported string value.

  Example:
  John Rumple Stiltskin would import as:
  First Name: John 
  Last Name: Rumple Stiltskin`,
  text: "Any Text",
  dropdown: `Depending on the context, import may allow new options to be created. In most cases however, imported values must match existing dropdown options. 

  If dropdown field allows multiple select - options may be separated by commas.
  
  Option A, Option B
  
  Not case sensitive.`,
  "event-days": `Will accept a date range & attempt to select any valid event days within that date range. You will not be notified if a day gets trimmed - so be sure to review the event day values upon completion of the import. If year is not included - the year of the event will be used.

  Please separate the two dates with a dash (-).
  
  yyyy-mm-dd - yyyy-mm-dd
  mm-dd-yyyy - mm-dd-yyyy
  
  Jan 11 2018 - Jan 14 2018
  Dec 28 2017 - Jan 15 2018`,
  phone: `Any Length - With or without dashes

  Can also accept country codes as prefixes 
  +1 for US, +27 for South Africa, etc`,
  date: `yyyy-mm-dd
  mm-dd-yyyy`,
  number: `Any integer. Decimals accepted.`,
  currency: `Any integer. Will assume the default currency of your event. Up to 2 decimal places. Currently only supports USD.`,
  boolean: `"Yes" will check the box. Leave the cell blank if you do not want to check the box.`,
  file: `Any Valid File URL - will be uploaded to a secure image server.`,
  datetime: `We will attempt to validate & parse any date-like format you attempt to import. 

  Without Timezone Specified
  yyyy-mm-ddThh:mm:ss.ffffff || 2008-09-15T15:53:00
  
  With Timezone Specified
  yyyy-mm-ddThh:mm:ss.nnnnnn+|-hh:mm || 2008-09-15T15:53:00+05:00`,
  time: `Displays the hour as a number with a leading zero when appropriate. If the format contains AM or PM, the hour is based on the 12-hour clock. Otherwise, the hour is based on the 24-hour clock.

  h:mm:ss
  h:mm
  hh:mm`,
  countries: `Any Text.

  ISO Alpha-2, Alpha-3 Country Codes recommended but not required.`,
  textarea: "Any Text",
  email: "Any Text",
  percent: `Any Integer. We will not do any decimal to percent calculation. We assume the number you are importing is the percentage. For example - if you upload 0.5 - we assume this means 0.5% as opposed to 50%.`
};
