import React, { Component } from "react";
import * as R from "ramda";
import moment from "moment";
import getValue from "utils/value-types/get-value/datetime";
import { Div } from "components/Base";
import autobind from "autobind-decorator";

import { getSettings, getCellValue } from "ui-kit/Datagrid/utils";

export default class DateTimeFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props.value, nextProps.value);
  }

  @autobind
  getTimezone() {
    const id = R.path(["value", "columnId"], this.props);
    const settings = R.path(["row", "meta", "columns", id], this.props);
    return R.path(["settings", "timezone"], settings);
  }

  formatValue(props) {
    const DISPLAY_FORMAT = getSettings(this.props, "format", "LL LT");
    const timezone = this.getTimezone();

    if (!timezone) {
      console.warn("no timezone passed");
    }

    const value = getValue(props.value);

    return moment.tz(value, timezone).format(DISPLAY_FORMAT);
  }

  render() {
    const value = this.formatValue(this.props);

    return (
      <Div
        truncate
        title={value}
        style={{
          userSelect: "none",
          lineHeight: "34px"
        }}
      >
        {value || null}
      </Div>
    );
  }
}
