import { REQUEST, RECEIVE } from "./constants";
import * as selectors from "./selectors";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getIntakeDetails(eventId, typeId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const details = await api.get(eventId, typeId);
      dispatch({ type: RECEIVE, payload: details });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred intake details"
          }
        ])
      );
    }
  };
}
