import React, { Fragment } from "react";
import {
  SuperFilledButton,
  Div,
  PersonIcon,
  PeopleIcon,
  Radio,
  AsyncCreatableSelect,
  LoadingIcon,
  collapsableHandler,
  TinyToggle
} from "components/Base";
import * as R from "ramda";
import { withProps } from "utils/General";
import TextEditor from "components/Global/StandAloneEditors/Text";

import Modal from "components/Global/Modal/Layout/StyleWrapper";

import { ToggleFeature } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

const OverflowScroll = withProps({
  style: {
    overflowY: "auto"
  }
})(Div);
const Frame = withProps({
  height: 500
})(Div);
const Footer = withProps({
  display: "row.space-between.center",
  px: 5,
  py: 3,
  bt: 1,
  bc: "gray3"
})(Div);
const Description = withProps({
  color: "gray6",
  fs: 1,
  noLineHeight: true
})(Div);
const RecordFinder = withProps({
  clearable: true
})(AsyncCreatableSelect);

const getEntityType = isAllocation => (isAllocation ? "Allocation" : "Order");

const Step = ({ number, label }) => (
  <Div display="row.flex-start.center">
    <Div fs={4} fw={3} color="gray4" mr={3}>
      {number}
    </Div>
    <Div fs={4} fw={3} color="gray7">
      {label}
    </Div>
  </Div>
);

const Fields = ({ children }) => (
  <Div width={340} ml={5} py={2} mb={4}>
    {children}
  </Div>
);

const Label = ({ children, required, description }) => (
  <Div display="column" mb={1}>
    <Div fw={3} color="gray7" fs={2}>
      {children}
      {required ? "*" : null}
    </Div>
    {description && description.length ? (
      <Description>{description}</Description>
    ) : null}
  </Div>
);

const TypeSwitch = ({ name, description, Icon, selected, onClick }) => (
  <Div
    display="row.flex-start.center"
    bg={
      selected
        ? "gray0"
        : {
            default: "white",
            hover: "gray0"
          }
    }
    onClick={onClick}
    bra={1}
    mb={1}
    p={2}
  >
    <Radio selected={selected} mr={1} size={20} />
    <Icon size={20} />
    <Div display="column" ml={2}>
      <Div fs={3} fw={3} color="gray7">
        {name}
      </Div>
      <Description>{description}</Description>
    </Div>
  </Div>
);

const Details = collapsableHandler(
  ({
    onSave,
    onChangeOrderType,
    onChangeCustomerAccount,
    onChangeCustomerContact,
    onCreateCustomerAccount,
    onCreateCustomerContact,
    onChangeCustomerContactEmail,
    onSearchAccounts,
    onSearchContacts,
    toggleRequireAssignment,
    //
    saving,
    isAllocation,
    isValidStep,
    orderType,
    customerAccount,
    customerContact,
    customerContactEmail,
    fulfillmentMethod,
    fulfillmentMethods,
    pickupFields,
    shippingFields,
    requireAssignment
  }) => {
    return (
      <Frame>
        <Div display="row.flex-start.flex-start" height={427}>
          <OverflowScroll height={427} flex={1}>
            <Div display="column" px={8} py={7}>
              <Step
                number={1}
                label={`Type of ${getEntityType(isAllocation)}`}
              />
              <Fields>
                {isAllocation ? null : (
                  <TypeSwitch
                    name={`Individual ${getEntityType(isAllocation)}`}
                    description="Items held under one person’s name."
                    Icon={PersonIcon}
                    selected={orderType === "individual"}
                    onClick={() => onChangeOrderType("individual")}
                  />
                )}
                <TypeSwitch
                  name={`Group ${getEntityType(isAllocation)}`}
                  description="Items held under a group's name."
                  Icon={PeopleIcon}
                  selected={orderType === "group"}
                  onClick={() => onChangeOrderType("group")}
                />
              </Fields>

              {orderType === "individual" ? (
                <Fragment>
                  <Step number={2} label="Attendee Information" />
                  <Fields>
                    <Label
                      description="Items will be held under this person's name"
                      required
                    >
                      Attendee Name
                    </Label>
                    <RecordFinder
                      placeholder="Find or create a person..."
                      loadOptions={onSearchContacts}
                      onChange={onChangeCustomerContact}
                      onCreate={onCreateCustomerContact}
                      value={customerContact}
                      clearable
                    />
                    <Div mb={2} mt={3}>
                      <Label required>Attendee Email</Label>
                      <TextEditor
                        onChange={onChangeCustomerContactEmail}
                        value={customerContactEmail}
                      />
                    </Div>

                    <Div height={15}>&nbsp;</Div>
                    <Label description="Optional. Group that the attendee is representing.">
                      Group Name
                    </Label>
                    <RecordFinder
                      placeholder="Find or create a group..."
                      loadOptions={onSearchAccounts}
                      onChange={onChangeCustomerAccount}
                      onCreate={onCreateCustomerAccount}
                      value={customerAccount}
                      clearable
                    />
                  </Fields>
                </Fragment>
              ) : (
                <Fragment>
                  <Step number={2} label="Group Information" />
                  <Fields>
                    <Label
                      description="Items will be held under this group's name"
                      required
                    >
                      Group Name
                    </Label>
                    <RecordFinder
                      placeholder="Find or create a group..."
                      loadOptions={onSearchAccounts}
                      onChange={onChangeCustomerAccount}
                      onCreate={onCreateCustomerAccount}
                      value={customerAccount}
                      clearable
                    />

                    <Div height={15}>&nbsp;</Div>
                    <Label
                      description="Point of contact for this order."
                      required
                    >
                      Person Responsible
                    </Label>
                    <RecordFinder
                      placeholder="Find or create a person..."
                      loadOptions={onSearchContacts}
                      onChange={onChangeCustomerContact}
                      onCreate={onCreateCustomerContact}
                      value={customerContact}
                      clearable
                    />
                    <Div mb={2} mt={3}>
                      <Label required>Email of Person Responsible</Label>
                      <TextEditor
                        onChange={onChangeCustomerContactEmail}
                        value={customerContactEmail}
                      />
                    </Div>
                  </Fields>
                </Fragment>
              )}

              <Step number={3} label="Fulfillment Method" />
              <Fields>
                {R.map(method => (
                  <Div
                    key={method.id}
                    display="row.flex-start.center"
                    onClick={method.onSelect}
                    mb={1}
                  >
                    <Radio selected={method.selected} mr={2} size={20} />
                    <Div fw={3} fs={2} color="gray7">
                      {method.name}
                    </Div>
                  </Div>
                ))(fulfillmentMethods)}
                {fulfillmentMethod === "pickup" ? (
                  <Div>
                    {R.map(
                      ({ id, name, Editor, editorProps, onChange, value }) => (
                        <Div key={id} mb={2}>
                          <Label>{name}</Label>
                          <Editor
                            onChange={onChange}
                            value={value}
                            {...editorProps}
                          />
                        </Div>
                      )
                    )(pickupFields)}
                  </Div>
                ) : null}
                {fulfillmentMethod === "mail" ? (
                  <Div>
                    {R.map(
                      ({ id, name, Editor, editorProps, onChange, value }) => (
                        <Div key={id} mb={2}>
                          <Label>{name}</Label>
                          <Editor
                            onChange={onChange}
                            value={value}
                            {...editorProps}
                          />
                        </Div>
                      )
                    )(shippingFields)}
                  </Div>
                ) : null}
              </Fields>

              {orderType === "group" ? (
                <Fragment>
                  <Step number={4} label="Collect Attendee Names" />
                  <Fields>
                    <Div
                      display="row.flex-start.center"
                      onClick={toggleRequireAssignment}
                    >
                      <TinyToggle
                        active={requireAssignment}
                        onClick={() => {}}
                      />
                      <Div ml={2}>
                        <Div fw={3} fs={2} color="gray7">
                          {requireAssignment
                            ? "Show on Assignment Manager"
                            : "Don't show on Assignment Manager"}
                        </Div>
                        <Div fs={1} color="gray5">
                          {requireAssignment
                            ? "Items can be assigned to additional attendees"
                            : "Order items will not show on the assignment manager"}
                        </Div>
                      </Div>
                    </Div>
                  </Fields>
                </Fragment>
              ) : null}

              {/*
              {orderFields.length ? (
                <Fragment>
                  <Step
                    number={orderType === "group" ? 5 : 4}
                    label={
                      <Div
                        display="row.flex-start.center"
                        onClick={toggleCollapsed}
                      >
                        <Div>Other Information</Div>
                        {collapsed ? (
                          <RightIcon size={20} color="gray5" />
                        ) : (
                          <DownIcon size={20} color="gray5" />
                        )}
                      </Div>
                    }
                  />
                  <Collapsable collapsed={collapsed}>
                    <Fields>
                      {R.map(
                        ({
                          id,
                          name,
                          Editor,
                          editorProps,
                          onChange,
                          value
                        }) => (
                          <Div key={id} mb={2}>
                            <Label>{name}</Label>
                            <Editor
                              onChange={onChange}
                              value={value}
                              {...editorProps}
                            />
                          </Div>
                        )
                      )(orderFields)}
                    </Fields>
                  </Collapsable>
                </Fragment>
              ) : null}
             */}
            </Div>
          </OverflowScroll>
        </Div>
        <Footer>
          <SuperFilledButton
            bg={isValidStep ? "orange5" : "neutral2"}
            mr={3}
            onClick={isValidStep ? onSave : undefined}
          >
            <Div display="row.center.center">
              <span>{saving ? "Saving..." : "Save"}</span>
              {saving ? <LoadingIcon size={20} color="white" ml={3} /> : null}
            </Div>
          </SuperFilledButton>
        </Footer>
      </Frame>
    );
  }
);

const View = ({
  onChangeOrderType,
  onSave,
  onChangeCustomerAccount,
  onChangeCustomerContact,
  onChangeCustomerContactEmail,
  onCreateCustomerAccount,
  onCreateCustomerContact,
  onSearchAccounts,
  onSearchContacts,
  toggleIsAllocation,
  toggleRequireAssignment,
  //
  loading,
  saving,
  isValidStep,
  orderType,
  customerAccount,
  customerContact,
  customerContactEmail,
  fulfillmentMethod,
  fulfillmentMethods,
  requireAssignment,
  pickupFields,
  shippingFields,
  isAllocation
}) => {
  return (
    <Modal
      width={500}
      heading={
        <Div display="row.space-between.center" width={1}>
          <Div>Edit {getEntityType(isAllocation)}</Div>
          <ToggleFeature
            flag={flags.IS_LENND_ADMIN.NAME}
            untoggledComponent={Div}
            toggledComponent={withProps({
              fw: 2,
              fs: 2,
              color: "white",
              onClick: toggleIsAllocation,
              children: <span>Change to {getEntityType(!isAllocation)}</span>
            })(Div)}
          />
        </Div>
      }
      bodyStyles={{ padding: 0 }}
    >
      <Div>
        {loading ? (
          <Div py={20} display="row.center.center">
            <LoadingIcon size={60} color="neutral4" />
          </Div>
        ) : (
          <Details
            {...{
              onSave,
              onChangeOrderType,
              onChangeCustomerAccount,
              onChangeCustomerContact,
              onChangeCustomerContactEmail,
              onCreateCustomerAccount,
              onCreateCustomerContact,
              onSearchAccounts,
              onSearchContacts,
              toggleRequireAssignment,
              //
              saving,
              isValidStep,
              isAllocation,
              orderType,
              customerAccount,
              customerContact,
              customerContactEmail,
              fulfillmentMethod,
              fulfillmentMethods,
              requireAssignment,
              pickupFields,
              shippingFields,
              collapsedDefault: true
            }}
          />
        )}
      </Div>
    </Modal>
  );
};

export default View;
