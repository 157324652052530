import * as R from "ramda";
import { createHandlers } from "redux-mvc";

import { NAMESPACE, DEFAULT_PAGINATION } from "./constants";

const iniState = {
  loading: true,
  searching: false,
  saving: false,
  content: [],
  showModal: false,
  pagination: DEFAULT_PAGINATION,
  search: "",
  url: "",
  accept: []
};

const reducers = {
  init: (_, { payload: accept }) => ({
    accept
  }),
  hideModal: R.assoc("showModal", false),
  selectRecord: R.always({
    showModal: false,
    search: ""
  }),
  save: R.identity,
  setInitialData: (_, { payload: { search, pagination, content } }) => ({
    content,
    search,
    pagination
  }),
  search: R.always({ searching: true, loading: true }),
  searchResponse: R.always({ searching: false, loading: false })
};

const model = createHandlers({ iniState, reducers, namespace: NAMESPACE });

const { actions, getters } = model;

export { actions, getters };

export default model;
