import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import { Div, BigFilledButton, BigOutlineButton } from "components/Base";

import { noop, withProps } from "utils/General";

import { getSelectedItems } from "ui-kit/MiniItemsSelector/selectors";

const decorate = R.compose(
  connect((state, props) => ({
    selectedItems: getSelectedItems(state, props)
  })),
  withProps(({ handlers: { onCancel, onDone }, selectedItems }) => ({
    onDone: () => onDone(selectedItems),
    onCancel
  }))
);

const Footer = ({ onCancel = noop, onDone = noop }) => (
  <Div display="row.flex-start.center" bc="neutral2" bt={1} p={4} height={66}>
    <BigFilledButton bg="altB5" width={140} onClick={() => onDone()}>
      Done
    </BigFilledButton>
    <BigOutlineButton ml={2} onClick={() => onCancel()}>
      Cancel
    </BigOutlineButton>
  </Div>
);

export default decorate(Footer);
