import React from "react";
import Draggable from "components/Event/FormsV2/Form/Views/V3_Edit/Shared/DraggableField";
import { Sidebar } from "components/Event/FormsV2/Form/Views/V3_Edit/Shared/Shared";
import {
  Div,
  AddIcon,
  DragIcon,
  Text2,
  Type11Card,
  Text4,
  SearchIcon,
  BigShadedInput,
  MediumClearButton,
  CardGroup2,
  collapsableHandler,
  RightIcon,
  DownIcon,
  Collapsable,
  StarIcon,
  SegmentedLineIcon,
  PersonIcon,
  PeopleIcon,
  ExpandVertIcon
} from "components/Base";
import { Label } from "EventLight/Common/FieldLayout";
import * as R from "ramda";
import { noop } from "utils/General";

const TogglableGroup = collapsableHandler(
  ({
    toggleCollapsed,
    collapsed,
    relatedFieldGroups,
    getRelatedFields,
    onAddField,
    typeNewFieldlabelText
  }) => {
    const toggle = () => {
      if (collapsed) {
        getRelatedFields();
      }
      toggleCollapsed();
    };
    return (
      <Div mb={1} bg="" p={2}>
        <Div display="row.flex-start.center" onClick={toggle}>
          {collapsed ? (
            <RightIcon size={20} color={"neutral6"} />
          ) : (
            <DownIcon size={20} color={"neutral6"} />
          )}
          <Div fs={2} fw={3}>
            Information
          </Div>
        </Div>
        <Collapsable collapsed={collapsed}>
          {relatedFieldGroups.map(g => (
            <Div key={g.id}>
              {g.fields.map(f => {
                return (
                  <Div key={f.id}>
                    <DragField
                      id={f.id}
                      field={f.rawField}
                      onClick={f.onAdd}
                      onDrop={f.onDrop}
                      onDragStart={f.onDragStart}
                      onEdit={f.onEdit}
                      name={f.name}
                      subname={f.subname}
                      isSimpleBar={true}
                      wasModified={f.wasModified}
                    />
                  </Div>
                );
              })}
            </Div>
          ))}
          <MediumClearButton LeftIcon={AddIcon} onClick={onAddField}>
            Create new {typeNewFieldlabelText} field
          </MediumClearButton>
        </Collapsable>
      </Div>
    );
  }
);

const TabBody = ({ children }) => (
  <Div
    px={4}
    py={4}
    style={{
      overflowY: "auto",
      height: "calc(100vh - 107px)"
    }}
  >
    {children}
  </Div>
);

const BlockElement = ({ Icon, name, onClick }) => (
  <Div
    shadow={{ default: 1, hover: 3 }}
    bra={1}
    display="row.flex-start.center"
    h={10}
    mt={1}
    style={{ position: "relative", width: "100%", cursor: "pointer" }}
    className="hoverContainer"
    onClick={() => onClick()} // do not pass event
    bg="white"
  >
    {ExpandVertIcon !== Icon ? (
      <div
        className="showOnHover"
        style={{ position: "absolute", left: 0, top: 5 }}
      >
        <DragIcon />
      </div>
    ) : null}
    <Icon size={35} ml={2} />
    <Div display="column" flex={1} ml={4}>
      <Text2 bold>{name}</Text2>
    </Div>
  </Div>
);
const DragField = Draggable(Type11Card);
const DragBlock = Draggable(BlockElement);

const Empty = ({ searchTerm, label }) => (
  <Div bg="neutral1" bra={1} mb={2} p={4} display="column.center.center">
    <Text4 color="gray5">
      {searchTerm ? `No ${label} match search` : `No ${label} added`}
    </Text4>
  </Div>
);

const CreateNew = ({ blocks = [], showReorderFormFieldModal = noop }) => {
  return (
    <Div>
      <Div pt={1} my={4}>
        <Div display="column.space-between.center">
          {blocks.map(block => (
            <DragBlock key={block.id} {...block} onClick={block.onAdd} />
          ))}
          <BlockElement
            Icon={ExpandVertIcon}
            name="Reorder Fields"
            onClick={() => showReorderFormFieldModal()}
          />
        </Div>
      </Div>

      {/* @NOTE: Hiding until implemented Advanced Collection Blocks */}
    </Div>
  );
};

const View = ({
  blocks = [],
  fields = [],
  relatedFieldGroups = [],
  onAddField = noop,
  onSearch = noop,
  searchTerm = "",
  scope = "account",
  showReorderFormFieldModal = noop
}) => {
  const filteredFieldGroup = R.filter(
    g =>
      (g.name === "Submitting Person" && scope === "contact") ||
      (g.name === "Submitting Group" && scope === "account"),
    relatedFieldGroups
  );

  const individualTooltipTxt =
    "Add People Fields to your form to collect information from a person. Whatever a person enters will write data to the fields you add to the form.";
  const groupTooltipTxt =
    "Add Group Fields to your form to collect information from a group. Whatever a group enters will write data to the fields you add to the form.";

  const typeFieldTooltip =
    filteredFieldGroup[0]?.name === "Submitting Person"
      ? individualTooltipTxt
      : groupTooltipTxt;

  const typeFieldlabelText =
    filteredFieldGroup[0]?.name === "Submitting Person"
      ? "Individual Fields"
      : "Group Fields";

  const typeNewFieldlabelText =
    filteredFieldGroup[0]?.name === "Submitting Person" ? "person" : "group";

  const typeIconField =
    filteredFieldGroup[0]?.name === "Submitting Person"
      ? PersonIcon
      : PeopleIcon;

  return (
    <Sidebar>
      <TabBody>
        <BigShadedInput
          onChange={onSearch}
          continuous
          width={1}
          RightIcon={SearchIcon}
          placeholder="Search..."
          mb={5}
        />

        <CardGroup2
          heading={
            <Label mt={2} tooltip={typeFieldTooltip}>
              {typeFieldlabelText}
            </Label>
          }
          actions={[]}
          Icon={typeIconField}
          iconColor="purple9"
        >
          {filteredFieldGroup.map(fieldGroup => (
            <TogglableGroup
              key={fieldGroup.id}
              typeNewFieldlabelText={typeNewFieldlabelText}
              {...fieldGroup}
              collapsedDefault
            />
          ))}
        </CardGroup2>

        <CardGroup2
          heading={
            <Label
              mt={2}
              tooltip={
                "Custom Fields allow you to collect data that you don’t want to clutter your people or group records with. This is useful for form or event specific information."
              }
            >
              Custom Fields
            </Label>
          }
          actions={[]}
          Icon={StarIcon}
          iconColor="altB5"
          footer={
            <MediumClearButton LeftIcon={AddIcon} onClick={onAddField}>
              Create new custom fields
            </MediumClearButton>
          }
        >
          {fields.length ? (
            fields.map(f => {
              return (
                <DragField
                  key={f.id}
                  id={f.id}
                  field={f.rawField}
                  onClick={f.onAdd}
                  onDrop={f.onDrop}
                  onDragStart={f.onDragStart}
                  onEdit={f.onEdit}
                  name={f.name}
                  subname={f.subname}
                  isSimpleBar={true}
                  wasModified={f.wasModified}
                />
              );
            })
          ) : (
            <Empty searchTerm={searchTerm} label={"fields"} />
          )}
        </CardGroup2>

        <CardGroup2
          mt={2}
          heading={<Label mt={2}>Actions</Label>}
          actions={[]}
          Icon={SegmentedLineIcon}
        >
          <CreateNew
            blocks={blocks}
            showReorderFormFieldModal={showReorderFormFieldModal}
          />
        </CardGroup2>
      </TabBody>
    </Sidebar>
  );
};
export default View;
