import React from "react";
import * as R from "ramda";
import { Div } from "../index";

const BoxGroup = ({ children, addSpacers = false, ...other }) => {
  const endIndex = R.length(children) - 1;
  const notEndProps = addSpacers ? { mr: 1 / 4 } : {};
  return (
    <Div {...other}>
      {R.addIndex(R.map)((child, i) => {
        if (i === 0) {
          return React.cloneElement(child, { key: i, brr: 0, ...notEndProps });
        } else if (i === endIndex) {
          return React.cloneElement(child, { key: i, brl: 0, bl: 0 });
        }
        return React.cloneElement(child, {
          key: i,
          brr: 0,
          brl: 0,
          bl: 0,
          ...notEndProps
        });
      }, children)}
    </Div>
  );
};

export { BoxGroup };
