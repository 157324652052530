import * as R from "ramda";
import {
  delay,
  put,
  call,
  takeEvery,
  all,
  fork,
  select
} from "redux-saga/effects";
import { actions, getters } from "./index";

import { registerError } from "redux/modules/errors/actions";

import { orgId as getOrgId } from "redux/modules/organization/selectors";

import { eventId as getEventId } from "redux/modules/event/selectors";

import { getCredentials } from "redux/modules/user/selectors";

import Api from "./api";

import { navigateTo, openInNewWindow } from "utils/General";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const eventId = yield select(getEventId);

  return {
    credentials,
    orgId,
    eventId
  };
};

const init = function*({ payload: { recordIds, moduleId } }) {
  try {
    yield put(actions.setLoading(true));

    const { credentials, eventId } = yield call(getParams);

    const { payload: reportGroups } = yield call(Api.getReports, {
      credentials,
      eventId,
      moduleId
    });

    const sortedReports = R.compose(
      R.map(r => ({
        label: r.name,
        value: r.id
      })),
      R.sortBy(r => r.name.toLowerCase())
    )(R.pathOr([], [0, "reports"])(reportGroups));

    yield put(
      actions.setInitialData({ reports: sortedReports, recordIds, moduleId })
    );
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occured loading reports"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const runReport = function*() {
  try {
    yield put(actions.setProcessing(true));

    const { credentials, orgId, eventId } = yield call(getParams);
    const recordIds = yield select(getters.recordIds);
    const reportId = yield select(getters.selectedReport);
    const format = yield select(getters.selectedFormat);

    const { payload } = yield call(Api.runReport, {
      credentials,
      data: {
        orgId,
        eventId,
        reportId,
        recordIds,
        format
      }
    });

    yield delay(500);

    let progressResult = yield call(Api.getReportStatus, credentials, {
      jobId: payload.job_id
    });

    while (["pending", "processing"].includes(progressResult.payload.status)) {
      yield delay(3000);
      progressResult = yield call(Api.getReportStatus, credentials, {
        jobId: payload.job_id
      });
    }

    yield call(openInNewWindow, progressResult.payload.payload.fileUrl);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred running report"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setProcessing(false));
  }
};

const goToModuleReports = function*() {
  const { eventId } = yield call(getParams);
  yield call(navigateTo, `/event-light/${eventId}/reports`);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchGoToModuleReports = function*() {
  yield takeEvery(actions.goToModuleReports.type, goToModuleReports);
};

const watchRunReport = function*() {
  yield takeEvery(actions.runReport.type, runReport);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchGoToModuleReports),
    fork(watchRunReport)
  ]);
};

export default rootSaga;
