import { connect } from "react-redux";
import * as R from "ramda";
import Controller from "./Controller";
import STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";
import { withRouter } from "react-router";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { hideModal, showModal } from "redux/modules/modal/actions";
import { getItemTypesByEvent } from "redux/modules/items/types/actions";
import { getFields } from "redux/modules/modules/fields/actions";
import { addOrder } from "redux/modules/orders/orders/actions";

import { eventDetails } from "redux/modules/event/selectors";
import { user, getCredentials } from "redux/modules/user/selectors";
import { types as itemTypes } from "redux/modules/items/types/selectors";
import { fields } from "redux/modules/modules/fields/selectors";
import { canUserAddItemType } from "redux/modules/permissions/user-permission-profile/selectors";

const orderReadOnlyFields = resolveReadOnlyFields({
  moduleId: STANDARD_MODULES.orders.id
});

function mapStateToProps(state) {
  const { types, itemGroups, items, variants } = itemTypes(state).reduce(
    (list, type) => {
      list.types.push(type);
      type.groups.forEach(group => {
        list.itemGroups.push(group);
        group.items.forEach(item => {
          list.items.push(item);
          item.variants.forEach(variant => {
            list.variants.push({
              ...variant,
              group_order: group.order,
              type_id: group.type_id,
              item
            });
          });
        });
      });
      return list;
    },
    {
      types: [],
      itemGroups: [],
      items: [],
      variants: []
    }
  );

  return {
    user: user(state),
    userCredentials: getCredentials(state),
    canUserAddItemType: canUserAddItemType(state),
    eventDetails: eventDetails(state),
    //
    types,
    itemGroups,
    items,
    variants,
    orderCustomFields: fields(state, STANDARD_MODULES.orders.id).filter(
      f => !orderReadOnlyFields.includes(f.id)
    )
  };
}

const decorate = R.compose(
  withRouter,
  connect(mapStateToProps, {
    showSnackbar,

    showModal,
    hideModal,
    getItemTypesByEvent,
    getFields,
    addOrder
  })
);

export default decorate(Controller);
