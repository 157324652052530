import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { getRecords } from "redux/modules/modules/records/actions";

export default function resolveEventHandler({ moduleId, event, meta }) {
  switch (moduleId) {
    case STANDARD_MODULE_IDS.documentRequests.id:
      if (event === "delete_records") {
        // refresh file request type counts
        meta.dispatch(
          getRecords({
            moduleId: STANDARD_MODULE_IDS.documentRequestTypes.id,
            options: { orgId: meta.orgId, eventId: meta.eventId }
          })
        );
      }
      break;
    default:
      break;
  }
}
