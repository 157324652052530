import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";

import { getFields } from "redux/modules/modules/fields/actions";

import { fields } from "redux/modules/modules/fields/selectors";
import { eventDetails } from "redux/modules/event/selectors";

const BLACKLISTED_FIELD_TYPES = [
  "catalog-item",
  "catering-dietary-restrictions",
  "catering",
  "dependency",
  "event-list",
  "inventory-list",
  "inventory",
  "lookup",
  "schedule-activity-list",
  "source",
  "user"
];

function mapStateToProps(state, props) {
  let referencedModuleFields = [];

  if (props.value.lookupFieldId) {
    const lookupField = props.lookupFields.find(
      f => f.id === props.value.lookupFieldId
    );
    if (lookupField) {
      referencedModuleFields = fields(
        state,
        lookupField.settings.moduleId
      ).filter(f => !BLACKLISTED_FIELD_TYPES.includes(f.type));
    }
  }

  return {
    referencedModuleFields,
    eventId: eventDetails(state).id
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getFields
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controller);
