import { createModule } from "redux-mvc";
import model from "Event/MyApprovals/model";

import sagas from "./sagas";

const module = createModule(model);

module.setRootSaga(sagas);

export default module;
