/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  getContent: ({ credentials, eventId, filters }) =>
    new Promise((success, error) => {
      Helpers.request({
        skipUserId: true,
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/virtual/event/${eventId}/content`,
        qs: filters,
        credentials,
        success,
        error
      });
    }),
  addContent: ({ credentials, eventId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        skipUserId: true,
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/virtual/event/${eventId}/content`,
        credentials,
        data,
        success,
        error
      });
    }),
  updateContent: ({ credentials, eventId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        skipUserId: true,
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/virtual/event/${eventId}/content`,
        credentials,
        data,
        success,
        error
      });
    }),
  deleteContent: ({ credentials, eventId, data }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        skipUserId: true,
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/virtual/event/${eventId}/content`,
        credentials,
        data,
        success,
        error
      });
    })
};
