const ns = "TICKETING_PROVIDERS";

export const TICKETING_PROVIDERS_REQUEST = `${ns}_PROVIDERS_REQUEST`;
export const TICKETING_PROVIDERS_RESPONSE = `${ns}_PROVIDERS_RESPONSE`;

export const TICKETING_CONFIGURATIONS_REQUEST = `${ns}_CONFIGURATIONS_REQUEST`;
export const TICKETING_CONFIGURATIONS_RESPONSE = `${ns}_CONFIGURATIONS_RESPONSE`;

export const TICKETING_CONFIGURING_REQUEST = `${ns}_CONFIGURING_REQUEST`;
export const TICKETING_CONFIGURING_RESPONSE = `${ns}_CONFIGURING_RESPONSE`;

export const TICKETING_REMOVING_REQUEST = `${ns}_REMOVING_REQUEST`;
export const TICKETING_REMOVING_RESPONSE = `${ns}_REMOVING_RESPONSE`;

export const TICKETING_EVENTS_REQUEST = `${ns}_EVENTS_REQUEST`;
export const TICKETING_EVENTS_RESPONSE = `${ns}_EVENTS_RESPONSE`;

export const TICKETING_TYPES_REQUEST = `${ns}_TYPES_REQUEST`;
export const TICKETING_TYPES_RESPONSE = `${ns}_TYPES_RESPONSE`;

export const TICKETING_TYPES_MAPPING_REQUEST = `${ns}_TYPES_MAPPING_REQUEST`;
export const TICKETING_TYPES_MAPPING_RESPONSE = `${ns}_TYPES_MAPPING_RESPONSE`;

export const TICKETING_SYNC_TYPES_REQUEST = `${ns}_SYNC_TYPES_REQUEST`;
export const TICKETING_SYNC_TYPES_RESPONSE = `${ns}_SYNC_TYPES_RESPONSE`;

export const TICKETING_CONFIGURING_REQUEST_COMPLETE = `${ns}_CONFIGURING_REQUEST_COMPLETE`;
