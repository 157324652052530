import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import { SidebarWrapper } from "ui-kit/Sidebar/View";
import BoothsSidebar from "./Sidebar";

import * as R from "ramda";
import { createSelector } from "reselect";

import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

//create order Modal
import CreateOrderModal from "Orders/CreateOrderModal";
import ImportOrdersModal from "Orders/ImportModal/View";

import { BOOTH_TYPE_ID } from "utils/item-types";

import { eventDetails } from "redux/modules/event/selectors";
import { user } from "redux/modules/user/selectors";
import { sourcesByTypeId } from "redux/modules/orders/sources/selectors";
import { canUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";

const getUrl = createSelector(
  R.path(["eventDetails", "id"]),
  eventId => (view, base = "/booths") => `/event/${eventId}${base}${view}`
);

const getLinks = createSelector(
  R.prop("eventDetails"),
  R.prop("cando"),
  getUrl,
  R.prop("showCreateOrderModal"),
  R.prop("showImportOrdersModal"),
  (eventDetails, cando, getUrl, showCreateOrderModal, showImportOrdersModal) =>
    [
      /*
      {
        id: "dashboard",
        to: getUrl("/dashboard"),
        name: "Dashboard",
        activeRoutes: ["BoothsDashboard"]
      },
      {
        id: "myApprovals",
        to: getUrl("/manage/pending-approvals"),
        name: "Pending Approval"
      },
      {
        id: "dashboardDivider",
        divide: true
      },
      */
      {
        id: "allRequests",
        to: getUrl("/manage/all-requests"),
        name: "Review",
        activeRoutes: ["BoothsManageBy"],
        activeParams: { viewId: "all-requests" }
      },
      {
        id: "sales-portal",
        name: "Sales Portal",
        external: true,
        activeRoutes: ["SalesPortal"],
        to: `/sales-portal/${eventDetails.slug}/${eventDetails.uuid}`
      },
      {
        id: "inventoryDivider",
        divide: true
      },
      /*
      {
        id: "exhibitor",
        to: getUrl("/exhibitor-list"),
        name: "Exhibitor List",
        activeRoutes: ["BoothsExhibitorList"]
      },
      {
        id: "sponsor",
        to: getUrl("/sponsor-list"),
        name: "Sponsor List",
        activeRoutes: ["BoothsSponsorList"]
      },
      {
        id: "fulfill",
        to: getUrl("/manage/fulfill"),
        name: "Assign Spaces",
        activeRoutes: ["BoothsManageBy"],
        activeParams: { viewId: "fulfill" }
      },
      {
        id: "inventoryDivider",
        divide: true
      },
      {
        id: "inventory",
        name: "Booth List",
        to: getUrl("/manage/inventory")
      },
      {
        id: "floorPlan",
        name: "Floor Plan",
        to: getUrl("/floor-plan")
      },
      {
        id: "tasks",
        name: "Tasks",
        to: getUrl("/tasks")
      },
      {
        id: "inventoryDivider2",
        divide: true
      },
      */
      {
        id: "subMenu",
        links: [
          {
            id: "create-order",
            name: "Create Order",
            onClick: () => showCreateOrderModal()
          },
          cando(`${STANDARD_MODULES.booths.id}_add_line_items`)
            ? {
                id: "import-order",
                name: "Import Orders",
                onClick: () => showImportOrdersModal()
              }
            : null,
          {
            id: "confirm-emails",
            name: "Send Emails",
            to: getUrl("/confirm", "/booths"),
            activeRoutes: ["BoothsConfirmation"]
          }
        ]
      }
      /*
      {
        id: "reports",
        name: "Reports",
        links: [
          {
            id: "report-items",
            to: getUrl(ORDER_LINE_ITEMS_REPORT_ID, "/reports/"),
            name: "Passes & Meals by Person"
          },
          {
            id: "report-groups",
            to: getUrl(CREDENTIALS_BY_GROUP_REPORT_ID, "/reports/"),
            name: "Passes by Group"
          },
          {
            id: "report-groups2",
            to: getUrl(GUEST_LIST_REPORT_ID, "/reports/"),
            name: "Guest List Report"
          }
        ]
      }
      */
    ].filter(
      R.compose(
        R.not,
        R.either(R.isEmpty, R.isNil)
      )
    )
);

const getSetupLink = createSelector(
  R.prop("cando"),
  getUrl,
  (cando, getUrl) => {
    return {
      id: "setupLinks",
      links: cando(`${STANDARD_MODULES.booths.id}_manage`)
        ? [
            {
              id: "setup",
              name: "Setup",
              to: getUrl("/setup", "/booths")
            },
            /*
            {
              id: "manage-booth-types",
              name: "Manage Booth Types",
              to: getUrl(`/settings/catalog/type/${BOOTH_TYPE_ID}/items`, "")
            },
              */
            {
              id: "manage-approvers",
              name: "Manage Approvers",
              activeRoutes: ["PassesSetupApprovers"],
              to: getUrl(
                `/settings/catalog/type/${BOOTH_TYPE_ID}/approvers`,
                ""
              )
            }
          ]
        : []
    };
  }
);

const decorate = R.compose(
  connect(
    (state, props) => ({
      eventDetails: eventDetails(state),
      user: user(state),
      sources: sourcesByTypeId(state, BOOTH_TYPE_ID),
      cando: canUserDo(state, props.params.orgId || props.params.eventId)
    }),
    {
      getRecordTypes
    }
  ),
  withRouter
);

// @NOTE: use oppacity, (STEVE)
class View extends Component {
  componentDidMount() {
    // get: account record types
    this.props.getRecordTypes({
      moduleId: STANDARD_MODULES.accounts.id,
      options: {
        eventId: this.props.params.eventId
      }
    });
  }

  showCreateOrderModal = () => {
    this.props.showModal({
      content: <CreateOrderModal hideModal={this.props.hideModal} />,
      wrapper: ModalWrapper
    });
  };

  showImportOrdersModal = () => {
    this.props.showModal({
      content: <ImportOrdersModal />,
      wrapper: ModalWrapper
    });
  };

  render() {
    const { children } = this.props;

    return (
      <SidebarWrapper
        instanceId="booths"
        sidebar={
          <BoothsSidebar
            links={getLinks({
              ...this.props,
              showCreateOrderModal: this.showCreateOrderModal,
              showImportOrdersModal: this.showImportOrdersModal
            })}
            setupLink={getSetupLink(this.props)}
          />
        }
      >
        {children}
      </SidebarWrapper>
    );
  }
}

export default decorate(View);
