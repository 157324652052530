import React from "react";

import { getValue } from "../../utils";

const AttendeePhoto = props => {
  const photoUrl = getValue(props, "photoUrl");

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center"
      }}
    >
      {!photoUrl ? (
        <i
          className="material-icons"
          style={{
            color: "#dedede",
            marginRight: "8px",
            fontSize: "32px",
            cursor: "pointer"
          }}
        >
          add_circle_outline
        </i>
      ) : (
        <div
          style={{
            width: "30px",
            height: "30px",
            borderRadius: "100px",
            backgroundColor: "#e7e6ea",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            position: "relative",
            cursor: "pointer"
          }}
          className="hoverContainer"
        >
          <img src={photoUrl} height="110%" />
          <div
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "100px",
              position: "absolute",
              backgroundColor: "rgba(149, 72, 232, 0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
            className="showOnHover"
          >
            <i
              className="material-icons"
              style={{
                color: "white",
                fontSize: "16px"
              }}
            >
              add_a_photo
            </i>
          </div>
        </div>
      )}
    </div>
  );
};

export default AttendeePhoto;
