import React from "react";
import * as R from "ramda";
import { Div } from "components/Base";
import { getValue } from "../../utils";
import { eventDetails } from "redux/modules/event/selectors";
import { connect } from "react-redux";

const decorate = connect(state => ({
  eventDetails: eventDetails(state)
}));

const CateringDietaryRestrictions = props => {
  const value = getValue(props);
  const restrictions = R.pathOr(
    [],
    ["eventDetails", "module_settings", "catering", "dietary_restrictions"],
    props
  );
  const getDietaryRestriction = id => {
    const restri = restrictions.find(r => r.id === id);
    return restri.value;
  };

  if (!value) {
    return null;
  }
  return (
    <Div
      display="row.flex-start.center"
      height={35}
      style={{
        userSelect: "none",
        overflow: "hidden"
      }}
    >
      {value.records.map(record => (
        <Div
          key={record}
          display="row.flex-start.center"
          mr={2}
          style={{
            flexDirection: "row",
            flexBasis: "auto",
            flexShrink: 0,
            borderRadius: 25,
            backgroundColor: "#EBEBEB",
            fontSize: "12px",
            color: "#494949",
            padding: "3px 8px",
            lineHeight: "normal",
            whiteSpace: "nowrap"
          }}
        >
          {getDietaryRestriction(record)}
        </Div>
      ))}
    </Div>
  );
};

export default decorate(CateringDietaryRestrictions);
