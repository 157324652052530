import React from "react";
import {
  CheckIcon,
  collapsableHandler,
  Collapsable,
  Div,
  Text1
} from "components/Base";
import { defaultProps } from "utils/General";
import { RemoveButton } from "../Buttons";

const AssignedItem = ({
  icon,
  color,
  title,
  sublabel,
  onRemove,
  onClick,
  quantity,
  issued,
  disabled
}) => (
  <Div display="row.flex-start.center" p={1} onClick={onClick} flex={1}>
    <Div mr={2} size={8} pill style={{ backgroundColor: color }} />
    <Text1 mr={2} bold>
      {quantity}
    </Text1>
    <Text1 bold mr={1}>
      {title}
    </Text1>
    {!icon ? (
      issued ? (
        <Text1 display="row.flex-start.center">
          <CheckIcon sizeWFS={1} mr={1} />
          Issued
        </Text1>
      ) : disabled ? null : (
        <RemoveButton onClick={onRemove} />
      )
    ) : (
      <Div flex={1} display="row.flex-end.center">
        {icon}
      </Div>
    )}
  </Div>
);

const ChildItem = ({ title, sublabel, onRemove, issued, disabled, id }) => (
  <Div display="row.flex-start.center" p={1}>
    <Text1 bold mr={1} pl={8}>
      {sublabel || title}
    </Text1>
    {issued && (
      <Text1 display="row.flex-start.center">
        <CheckIcon sizeWFS={1} mr={1} />
        Issued
      </Text1>
    )}
    {!issued && !disabled && <RemoveButton onClick={onRemove} />}
  </Div>
);

const CollapsableListPanel = collapsableHandler(
  ({ ItemComp, items, title, collapsed, disabled, toggleCollapsed }) => (
    <Div>
      <ItemComp
        onClick={toggleCollapsed}
        icon={
          <i
            className="material-icons"
            style={{ fontSize: 18, color: "rgba(0, 0, 0, 0.26)" }}
            key={String(collapsed)}
          >
            {collapsed ? "keyboard_arrow_right" : "keyboard_arrow_down"}
          </i>
        }
      />
      <Collapsable collapsed={collapsed}>
        {items.map((i, idx) => (
          <ChildItem
            key={`${idx}-${i.id}`}
            onRemove={i.onRemove}
            issued={i.issued}
            title={title}
            sublabel={i.sublabel}
            disabled={disabled}
          />
        ))}
      </Collapsable>
    </Div>
  )
);

const List = ({ id, items = [], quantity, color, title, disabled }) => {
  const ItemComp = defaultProps({
    id,
    quantity,
    color,
    title,
    disabled
  })(AssignedItem);

  if (items.length > 1) {
    return (
      <CollapsableListPanel
        collapsedDefault
        key={`${id}-${quantity}`}
        items={items}
        title={title}
        ItemComp={ItemComp}
        disabled={disabled}
      />
    );
  }
  return <ItemComp {...items[0]} />;
};

export default List;
