import React from "react";

import { Div } from "components/Base";

const SecondarySidebar = ({ children, ...styleProps }) => (
  <Div
    bg="white"
    w={58}
    display="column.flex-start.stretch"
    shadow={2}
    style={{
      position: "relative",
      zIndex: 2,
      overflowY: "auto"
    }}
    {...styleProps}
  >
    {children}
  </Div>
);

export default SecondarySidebar;
