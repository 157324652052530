import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Checkbox from "material-ui/Checkbox";

class CateringSettings extends PureComponent {
  handleCheck = (e, val) => this.props.onChange({ allowMultiple: val });
  render() {
    const { disabled, settings } = this.props;
    return (
      <div>
        <Checkbox
          label="Allow multiple meals for single date?"
          checked={settings.allowMultiple}
          onCheck={this.handleCheck}
          disabled={disabled}
        />
      </div>
    );
  }
}

CateringSettings.propTypes = {
  settings: PropTypes.shape({
    allowMultiple: PropTypes.bool
  }).isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};

export default CateringSettings;
