import PropTypes from "prop-types";
import React, { Component } from "react";
import Request from "./Request";
import Title from "./Title";

const contentStyle = {
  fontFamily: "arial"
};

const headerStyle = {
  fontSize: "21px"
};

class Preview extends Component {
  render() {
    const {
      header,
      content,
      requests,
      topLevelRequest,
      eventDetails
    } = this.props;

    return (
      <div style={contentStyle}>
        <div style={headerStyle}>{header}</div>
        <div dangerouslySetInnerHTML={{ __html: content }} />
        <Title>GENERAL FORM INFO</Title>
        <Request request={topLevelRequest} />
        {requests.length ? <Title>YOUR REQUEST DETAILS & STATUS</Title> : ""}
        {requests.map((request, index) => (
          <Request
            request={request}
            index={index + 1}
            eventDetails={eventDetails}
          />
        ))}
      </div>
    );
  }
}

Preview.propTypes = {
  header: PropTypes.string.isRequired,
  topLevelRequest: PropTypes.object.isRequired,
  requests: PropTypes.array.isRequired,
  eventDetails: PropTypes.object.isRequired,
  content: PropTypes.string.isRequired
};

export default Preview;
