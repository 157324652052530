import React, { Component } from "react";
import "styles/less/includes/plugins/react-toggle-switch.less";
import Panel from "components/Event/Settings/Users/Permissions/Panel";
import Group from "components/Event/Settings/Users/Permissions/Group";
import Permission from "components/Event/Settings/Users/Permissions/Permission";
import Well from "components/Event/Settings/Users/Permissions/Well";
import ModifyRoleModal from "components/Event/Settings/Users/Permissions/Modals/ModifyRole";
import DeleteProfileModal from "components/Event/Settings/Users/Permissions/Modals/DeleteProfile";
import PermissionsSidebar from "components/Event/Settings/Users/Permissions/PermissionsSidebar";
import PermissionsHeader from "components/Event/Settings/Users/Permissions/PermissionsHeader";
import Search from "components/Event/Settings/Users/Permissions/Search";
import Loading from "components/Global/Loading";
import Body from "components/Event/Settings/Body";
import Page from "components/Event/Settings/Page";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import CSSModules from "react-css-modules";
import css from "components/Event/Settings/Users/Permissions/styles.scss";

@CSSModules(css)
class OrganizationPermissionsHandler extends Component {
  state = {
    search: "",
    activePermissionProfileId: "6c1fba5d-633a-4d54-9077-a50a5bd4e7e0",
    loading: true
  };

  componentDidMount() {
    Promise.all([
      this.props.getOrganizationPermissionProfiles(this.props.params.orgId),
      this.props.getOrganizationPermissionGroups(this.props.params.orgId)
    ]).then(() => {
      this.setState({ loading: false });
    });
  }

  updateSearch = e => this.setState({ search: e.target.value });

  searchQueryExists = () => Boolean(this.state.search.trim().length);

  groupNameMatchesSearch = name =>
    name
      .toLowerCase()
      .trim()
      .indexOf(this.state.search) > -1;

  getActivePermissionProfile = () =>
    this.props.permissionProfiles.find(
      p => p.id === this.state.activePermissionProfileId
    );

  handlePermissionGroup = (permissionGroupId, enabled) => {
    const activePermissionProfile = this.getActivePermissionProfile();
    const permissionGroup = this.props.permissionGroups.find(
      g => g.id === permissionGroupId
    );
    let permissions = [...activePermissionProfile.permissions];

    if (enabled) {
      const permissionGroupHasDefaultPermissions = Boolean(
        permissionGroup.permissions.filter(p => p.is_default).length
      );
      const permissionsToAdd = permissionGroupHasDefaultPermissions
        ? permissionGroup.permissions.reduce((result, permission) => {
            if (permission.is_default) {
              result.push(permission);
            }
            return result;
          }, [])
        : permissionGroup.permissions;

      permissions = [...permissions, ...permissionsToAdd];
    } else {
      permissions = permissions.filter(
        permission =>
          permissionGroup.permissionList.indexOf(permission.slug) === -1
      );
    }

    this.props
      .updatePermissionProfilePermissions({
        orgId: this.props.params.orgId,
        profileId: this.state.activePermissionProfileId,
        permissions: permissions.map(this.formatPermission)
      })
      .then(() => {
        this.props.getOrganizationPermissionProfiles(this.props.params.orgId);
      });
  };

  handlePermission = (permission, enabled) => {
    const activePermissionProfile = this.getActivePermissionProfile();
    let permissions = [...activePermissionProfile.permissions];
    if (enabled) {
      permissions.push(permission);
    } else {
      permissions = permissions.filter(p => p.slug !== permission.slug);
    }

    this.props
      .updatePermissionProfilePermissions({
        orgId: this.props.params.orgId,
        profileId: this.state.activePermissionProfileId,
        permissions: permissions.map(this.formatPermission)
      })
      .then(() => {
        this.props.getOrganizationPermissionProfiles(this.props.params.orgId);
      });
  };

  enableAllPermissions = () => {
    const permissions = this.props.permissionGroups.reduce(
      (result, group) => [...result, ...group.permissions],
      []
    );

    this.props
      .updatePermissionProfilePermissions({
        orgId: this.props.params.orgId,
        profileId: this.state.activePermissionProfileId,
        permissions: permissions.map(this.formatPermission)
      })
      .then(() => {
        this.props.getOrganizationPermissionProfiles(this.props.params.orgId);
      });
  };

  disableAllPermissions = () => {
    this.props.updateRolePermissions(
      this.props.params.orgId,
      this.state.activePermissionProfileId,
      []
    );
  };

  isPermissionGroupSwitchOn = permissionGroup => {
    const activePermissionProfile = this.props.permissionProfiles.find(
      p => p.id === this.state.activePermissionProfileId
    );
    return permissionGroup.permissions
      .map(p => p.slug)
      .some(slug => activePermissionProfile.permissionList.indexOf(slug) > -1);
  };

  isPermissionSwitchOn = permissionSlug =>
    this.props.permissionProfiles
      .find(p => p.id === this.state.activePermissionProfileId)
      .permissionList.indexOf(permissionSlug) > -1;

  onPermissionMenuClick = (permissionProfileId, event, command) => {
    event.preventDefault();
    switch (command) {
      case "edit":
        this.showModifyRoleModal(permissionProfileId);
        break;
      case "delete":
        this.showDeletePermissionProfileModal(permissionProfileId);
        break;
      case "duplicate":
        const permissionProfile = this.props.permissionProfiles.find(
          p => p.id === permissionProfileId
        );
        const name = `${permissionProfile.name} Copy`;
        this.onCreatePermissionProfile(
          this.generateCopyName(name, this.props.permissionProfiles),
          permissionProfile.permissions
        );
        break;
      default:
        break;
    }
  };

  generateCopyName = (name, permissionProfiles, count = 0) => {
    const newName = `${name}${count > 0 ? ` (${count})` : ""}`;
    const duplicate = permissionProfiles.find(r => r.name === newName);
    if (duplicate) {
      return this.generateCopyName(name, permissionProfiles, count + 1);
    }
    return newName;
  };

  getDefaultPermissionProfile = permissionProfiles =>
    permissionProfiles.filter(p => !p.editable)[0].id;

  selectPermissionProfile = activePermissionProfileId =>
    this.setState({ activePermissionProfileId });

  formatPermission = permission => ({
    permissionId: permission.id,
    permissionModuleId: permission.module_id
  });

  onCreatePermissionProfile = (name, permissions = []) => {
    this.props
      .addOrganizationPermissionProfile({
        orgId: this.props.params.orgId,
        name,
        editable: true,
        description: null,
        permissions: permissions.map(this.formatPermission)
      })
      .then(result =>
        Promise.all([
          result,
          this.props.getOrganizationPermissionProfiles(this.props.params.orgId)
        ])
      )
      .then(([result]) => {
        if (result.id) {
          this.selectPermissionProfile(result.id);
        }
      });
  };

  showModifyRoleModal = permissionProfileId => {
    const { name, description } = this.props.permissionProfiles.find(
      p => p.id === permissionProfileId
    );
    const { hideModal } = this.props;
    const done = (newName, newDescription) => {
      this.props
        .updatePermissionProfile({
          orgId: this.props.params.orgId,
          profileId: permissionProfileId,
          name: newName,
          description: newDescription
        })
        .then(() => {
          this.props.getOrganizationPermissionProfiles(this.props.params.orgId);
        });

      this.props.showSnackbar({
        message: `${newName} updated`,
        action: "OK"
      });
    };

    this.props.showModal({
      content: <ModifyRoleModal {...{ done, name, description, hideModal }} />
    });
  };

  showDeletePermissionProfileModal = permissionProfileId => {
    const permissionProfile = this.props.permissionProfiles.find(
      p => p.id === permissionProfileId
    );
    const { hideModal } = this.props;
    const orgId = this.props.params.orgId;
    const done = ({ replaceWithProfileId }) => {
      hideModal();

      if (this.state.activePermissionProfileId === permissionProfileId) {
        this.setState({
          activePermissionProfileId: replaceWithProfileId
        });
      }

      this.props
        .deletePermissionProfile({
          orgId,
          profileId: permissionProfile.id,
          replaceWithProfileId
        })
        .then(() => {
          this.props.getOrganizationPermissionProfiles(this.props.params.orgId);
        });

      this.props.showSnackbar({
        message: `${permissionProfile.name} deleted`,
        action: "OK"
      });
    };

    this.props.showModal({
      content: (
        <DeleteProfileModal
          {...{ done, orgId, profileId: permissionProfile.id, hideModal }}
        />
      )
    });
  };

  renderPermissionGroups = ({
    groups,
    canUpdatePermission,
    forceEnableAllPermissions
  }) =>
    groups.map(permissionGroup => {
      const isPermissionGroupSwitchOn = this.isPermissionGroupSwitchOn(
        permissionGroup
      );
      const handlePermissionGroup = () => {
        if (canUpdatePermission) {
          this.handlePermissionGroup(
            permissionGroup.id,
            !isPermissionGroupSwitchOn
          );
        }
      };

      return (
        <Group
          permissionGroup={permissionGroup}
          enabled={canUpdatePermission}
          on={forceEnableAllPermissions || isPermissionGroupSwitchOn}
          onClick={handlePermissionGroup}
          key={`${this.state.activePermissionProfileId}_${permissionGroup.id}`}
        >
          {permissionGroup.permissions
            .filter(
              p =>
                !p.slug.includes("read_typed_records") &&
                !p.slug.includes("read_owned_records")
            )
            .map(permission => {
              const isPermissionSwitchOn = this.isPermissionSwitchOn(
                permission.slug
              );
              const handlePermission = () => {
                if (canUpdatePermission) {
                  this.handlePermission(permission, !isPermissionSwitchOn);
                }
              };

              return (
                <Permission
                  key={`${permissionGroup.id}_${permission.slug}`}
                  permission={{
                    ...permission
                  }}
                  onClick={handlePermission}
                  enabled={canUpdatePermission}
                  on={forceEnableAllPermissions || isPermissionSwitchOn}
                />
              );
            })}
        </Group>
      );
    });

  render() {
    if (this.state.loading) {
      return (
        <div styleName="loadingWrapper">
          <Loading content="Loading permissions..." />
        </div>
      );
    }

    const { permissionProfiles, orgDetails } = this.props;

    const activeRole = this.getActivePermissionProfile();

    // override switches for admin profile to show all turne don
    const forceEnableAllPermissions = [
      "6c1fba5d-633a-4d54-9077-a50a5bd4e7e0",
      "35f029df-33ca-4c53-a02c-bbd6c92a5f35"
    ].includes(activeRole.id);

    const canUpdatePermission = !!activeRole.editable;
    const searchQueryExists = this.searchQueryExists();
    let standardModulePermissionGroups = searchQueryExists
      ? this.props.permissionGroups.filter(
          g => g.source === "standard" && this.groupNameMatchesSearch(g.name)
        )
      : this.props.permissionGroups.filter(g => g.source === "standard");

    standardModulePermissionGroups = standardModulePermissionGroups.filter(g =>
      orgDetails.enabled_modules.find(m => m.id === g.id)
    );

    const customModulePermissionGroups = searchQueryExists
      ? this.props.permissionGroups.filter(
          g => g.source !== "standard" && this.groupNameMatchesSearch(g.name)
        )
      : this.props.permissionGroups.filter(g => g.source !== "standard");

    return (
      <Page>
        <Body>
          <PermissionsSidebar
            permissionProfiles={permissionProfiles}
            activeRole={this.state.activePermissionProfileId}
            onRoleSelect={this.selectPermissionProfile}
            onCreate={this.onCreatePermissionProfile}
            onMenuClick={this.onPermissionMenuClick}
          />
          <Well>
            <PermissionsHeader
              canEditPermissions={canUpdatePermission}
              role={activeRole}
              enableAllPermissions={this.enableAllPermissions}
              disableAllPermissions={this.disableAllPermissions}
            />
            <Search onChange={this.updateSearch} query={this.state.search} />

            {standardModulePermissionGroups.length ? (
              <Panel>
                {this.renderPermissionGroups({
                  groups: [
                    ...standardModulePermissionGroups.filter(
                      g => g.id === STANDARD_MODULE_IDS.settings.id
                    ),
                    ...standardModulePermissionGroups.filter(
                      g => g.id !== STANDARD_MODULE_IDS.settings.id
                    )
                  ],
                  canUpdatePermission
                })}
              </Panel>
            ) : null}

            {customModulePermissionGroups.length ? (
              <div>
                <div styleName="moduleTypeTitle">Custom Modules</div>
                <Panel>
                  {this.renderPermissionGroups({
                    groups: customModulePermissionGroups,
                    canUpdatePermission,
                    forceEnableAllPermissions
                  })}
                </Panel>
              </div>
            ) : null}

            <br />
            <br />
          </Well>
        </Body>
      </Page>
    );
  }
}

export default OrganizationPermissionsHandler;
