import * as R from "ramda";
import { makeInstanceSelector } from "redux-mvc";

import { getters } from "ui-kit/SelectPrice";

const getId = R.prop("instanceId");

export const getPrices = makeInstanceSelector(getters.prices, R.values)(getId);
export const getEditingPrices = makeInstanceSelector(getters.editing, R.values)(
  getId
);
export const getDisableSave = makeInstanceSelector(
  getEditingPrices,
  R.any(price => price.value <= 0 || R.length(price.label) === 0)
)(getId);
