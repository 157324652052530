const toTitleCase = str =>
  str.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );

export default type => {
  switch (type) {
    case "catalog-item":
      return "Catalog Item";
    case "event-days":
      return "Event Days";
    default:
      return toTitleCase(type);
  }
};
