import React, { useState } from "react";
import { Div, RightIcon, LeftIcon } from "components/Base";
import { connect } from "react-redux";
import { getters } from "../../model";
import VideoItem from "./VideoItem";
import Circle from "ui-kit/Circle";
import * as R from "ramda";
import { NUMBER_OF_VIDEOS } from "../../constants";

const decorator = connect((state, props) => ({
  videos: getters.featuredContent(state, props)
}));

export const VideoSlider = ({ videos }) => {
  const [index, setIndex] = useState(0);

  const moveLeft = () => setIndex(i => i - NUMBER_OF_VIDEOS);

  const moveRight = () => setIndex(i => i + NUMBER_OF_VIDEOS);

  return (
    <Div
      display="row.flex-start.flex-start"
      style={{ position: "relative" }}
      width={1}
    >
      {R.length(videos) > NUMBER_OF_VIDEOS ? (
        <>
          <Circle
            style={{
              position: "absolute",
              left: 2,
              top: 35,
              backgroundColor: "#ffffff",
              border: "2px solid #eaeaea",
              zIndex: 10,
              display: `${index === 0 ? "none" : "inherit"}`
            }}
            size={25}
          >
            <LeftIcon size={25} onClick={moveLeft} />
          </Circle>

          <Circle
            size={25}
            style={{
              position: "absolute",
              right: 2,
              top: 35,
              backgroundColor: "#ffffff",
              border: "2px solid #eaeaea",
              zIndex: 10,
              display: `${
                index + NUMBER_OF_VIDEOS < videos.length ? "inherit" : "none"
              }`
            }}
          >
            <RightIcon size={25} onClick={moveRight} />
          </Circle>
        </>
      ) : null}

      <Div display="row.flex-start.flex-start" width={1}>
        {R.map(
          video => (
            <VideoItem
              key={video.id}
              video={video}
              style={{ marginRight: 3, width: `${100 / NUMBER_OF_VIDEOS}%` }}
            />
          ),
          videos.slice(index, index + NUMBER_OF_VIDEOS)
        )}
      </Div>
    </Div>
  );
};

export default decorator(VideoSlider);
