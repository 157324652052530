import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import ItemSummaryModal from "components/Event/InventoryV2/Modals/ItemSummary";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

class NameFormatter extends Component {
  showItemSummaryModal = () => {
    this.props.showModal({
      content: <ItemSummaryModal catalogItem={this.props.value} />,
      wrapper: ModalWrapper
    });
  };

  render() {
    const { value, tooltipRef, tooltipValue } = this.props;
    tooltipValue(value.value);
    return (
      <div
        styleName="nameFormatter"
        onClick={this.showItemSummaryModal}
        ref={tooltipRef}
        title={value.value}
      >
        {value.value}
      </div>
    );
  }
}

NameFormatter.propTypes = {
  showModal: PropTypes.func.isRequired,
  tooltipRef: PropTypes.func.isRequired,
  tooltipValue: PropTypes.func.isRequired,
  value: PropTypes.shape({
    value: PropTypes.string,
    id: PropTypes.string
  }).isRequired
};

export default CSSModules(NameFormatter, css);
