import * as R from "ramda";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import {
  getColumns,
  getRows,
  getMetaData,
  getHeaders,
  getEnabledButtons,
  getRightButtons
} from "Accounts/PeopleTable/selectors";
import module from "Accounts/PeopleTable";

import { actions as TableActions } from "ui-kit/Datagrid";
import Table from "ui-kit/Datagrid/View";

export const contextConfig = {
  module,
  options: {
    observedDomains: ["user", "event", "organization", "portal"],
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    )
  }
};

const decorate = connect(
  (state, props) => ({
    columns: getColumns(state, props),
    rows: getRows(state, props),
    meta: getMetaData(state, props),
    headers: getHeaders(state, props),
    enabledButtons: getEnabledButtons(state, props),
    rightButtons: getRightButtons(state, props),
    fieldId: props.group.id,
    instanceId: props.group.id,
    showHeaders: true
  }),
  bindInstance(
    {
      makeReview: TableActions.makeReviewRequest,
      requestEdit: TableActions.requestEdit
    },
    ["field", "id"]
  )
);

export default decorate(Table);
