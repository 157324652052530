import { connect } from "react-redux";
import Submission from "./Submission";

import { eventDetails } from "redux/modules/event/selectors";

function mapStateToProps(state) {
  return {
    eventDetails: eventDetails(state)
  };
}

export default connect(mapStateToProps)(Submission);
