import React from "react";
import { Page, Title } from "EventLight/Common/FieldLayout";
import { INVENTORY_TYPE_ID } from "utils/item-types";

import Catalog from "EventLight/Common/Items/Catalog/View";

const Layout = () => (
  <>
    <Page>
      <Title
        icon="widgets"
        title="Assets"
        description="Manage your supplies, golf carts, tables, chairs and other types of assets"
      />
      <Catalog typeId={INVENTORY_TYPE_ID} />
    </Page>
  </>
);

export default Layout;
