import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Messages from "./Messages";

import { getMessages } from "redux/modules/formsV2/messages/actions";
import * as MessagesSelectors from "redux/modules/formsV2/messages/selectors";
import { settings } from "redux/modules/portal/settings/selectors";

function mapStateToProps(state) {
  return {
    messages: MessagesSelectors.messages(state),
    settings: settings(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getMessages
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Messages);
