import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import { Div, BigFilledButton, BigOutlineButton } from "components/Base";

const decorate = connect(
  state => ({
    visibleColumns: getters.visibleFields(state),
    columnOrder: getters.fieldOrder(state),
    requiredFields: getters.requiredFields(state)
  }),
  {
    save: actions.save,
    hideModal: actions.hideModal
  }
);

const Footer = ({
  hideModal,
  save
  // visibleColumns,
  // columnOrder,
  // requiredFields
}) => (
  <Div
    height={80}
    width={1}
    bg="white"
    bt={1}
    bc="neutral1"
    display="row.space-between.center"
    px={3}
  >
    <BigFilledButton bg="altB4" onClick={save}>
      Done
    </BigFilledButton>
    <BigOutlineButton onClick={hideModal}>Cancel</BigOutlineButton>
  </Div>
);

export default decorate(Footer);
