import { takeEvery, put, select } from "redux-saga/effects";

import { actions as SearchBarActions } from "ui-kit/SearchBar";
import { actions, getters } from "ui-kit/MiniItemsSelector";

const init = function*({ meta }) {
  const clearSearch = yield select(getters.clearSearch, meta);

  if (clearSearch) {
    yield put(SearchBarActions.clearSearch(null, { meta }));
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

export default watchInit;
