/* eslint-disable no-underscore-dangle */
import React from "react";

import {
  delay,
  put,
  call,
  takeEvery,
  all,
  fork,
  select
} from "redux-saga/effects";
import { actions } from "./index";

import { registerError } from "redux/modules/errors/actions";

import { orgId as getOrgId } from "redux/modules/organization/selectors";

import { eventId as getEventId } from "redux/modules/event/selectors";

import { getCredentials } from "redux/modules/user/selectors";
import { makeFuture } from "utils/General/sagas";
import { showModal } from "redux/modules/modal/actions";
import EditReportTemplateModal from "Reports/EditReportTemplateModal/View";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import DeleteConfirmation from "components/Global/CRM/Modals/DeleteConfirmation";
import { actions as SnackbarActions } from "ui-kit/Snackbar/model";
import { SEVERITY } from "ui-kit/Alert/constants";

import Api from "./api";

import { openInNewWindow } from "utils/General";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const eventId = yield select(getEventId);

  return {
    credentials,
    orgId,
    eventId
  };
};

const init = function*() {
  try {
    yield put(actions.setLoading(true));

    const { credentials, eventId } = yield call(getParams);

    const { payload: reports } = yield call(Api.getReports, {
      credentials,
      eventId
    });

    yield put(actions.setInitialData({ reports }));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading reports"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const refetchReports = function*() {
  try {
    const { credentials, eventId } = yield call(getParams);

    const { payload: reports } = yield call(Api.getReports, {
      credentials,
      eventId
    });

    yield put(actions.setReports(reports));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading reports"
          }
        ])
      )
    ]);
  }
};

const runReport = function*({
  payload: { reportId, runningId, format, meta = {} }
}) {
  try {
    yield put(actions.toggleRunningReport(runningId));

    const { credentials, orgId, eventId } = yield call(getParams);

    const { payload } = yield call(Api.runReport, {
      credentials,
      data: {
        orgId,
        eventId,
        reportId,
        format,
        meta
      }
    });

    yield delay(500);

    let progressResult = yield call(Api.getReportStatus, credentials, {
      jobId: payload.job_id
    });

    while (["pending", "processing"].includes(progressResult.payload.status)) {
      yield delay(3000);
      progressResult = yield call(Api.getReportStatus, credentials, {
        jobId: payload.job_id
      });
    }
    if (progressResult.payload.payload.fileUrl) {
      yield call(openInNewWindow, progressResult.payload.payload.fileUrl);
    } else {
      yield put(
        SnackbarActions.show({
          id: "error-running-report",
          severity: SEVERITY.ERROR,
          autoHideDuration: false,
          message: "Error generating report"
        })
      );
    }
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred running report"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.toggleRunningReport(runningId));
    yield put(actions.refetchReports());
  }
};

const copyTemplate = function*({ payload: templateId }) {
  try {
    const { credentials } = yield call(getParams);

    yield call(Api.copyTemplate, {
      credentials,
      data: {
        templateId
      }
    });

    yield put(actions.refetchReports());
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error copying template"
          }
        ])
      )
    ]);
  }
};

const showDeleteReportModal = function*() {
  const handleDelete = makeFuture();
  yield put(
    showModal({
      content: (
        <DeleteConfirmation
          heading="Delete Record?"
          message={
            <div>
              {`
                Are you sure you want to remove this report?
                `}
              <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                This cannot be undone.
              </div>
            </div>
          }
          onConfirm={() => handleDelete.done(true)}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleDelete.onRealized);
};

const deleteTemplate = function*({ payload: { reportId } }) {
  const result = yield call(showDeleteReportModal);

  if (!result) {
    return false;
  }

  try {
    const { credentials } = yield call(getParams);

    yield call(Api.deleteTemplate, {
      credentials,
      templateId: reportId
    });

    yield put(actions.refetchReports());
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error deleting a template"
          }
        ])
      )
    ]);
  }

  return true;
};

const showEditReportModal = function*({ reportId }) {
  const handleModal = makeFuture();
  yield put(
    showModal({
      content: (
        <EditReportTemplateModal
          templateId={reportId}
          onDone={() => handleModal.done(true)}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleModal.onRealized);
};

const editReport = function*({ payload: { reportId } }) {
  const result = yield call(showEditReportModal, {
    reportId
  });

  if (!result) {
    return false;
  }

  yield put(actions.refetchReports());

  return true;
};

const createTemplate = function*({ payload: { moduleId } }) {
  try {
    const { credentials, eventId } = yield call(getParams);

    const { payload } = yield call(Api.createTemplate, {
      credentials,
      data: {
        eventId,
        moduleId,
        name: "New Report"
      }
    });

    yield put(actions.refetchReports());

    yield call(editReport, { payload: { reportId: payload.id } });
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error creating template"
          }
        ])
      )
    ]);
  }
};

const goToModuleReports = function*({ payload: moduleId }) {
  const { eventId } = yield call(getParams);
  window.location = `/event/${eventId}/settings/module/${moduleId}/report-templates`;
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchRefetchReports = function*() {
  yield takeEvery(actions.refetchReports.type, refetchReports);
};

const watchGoToModuleReports = function*() {
  yield takeEvery(actions.goToModuleReports.type, goToModuleReports);
};

const watchRunReport = function*() {
  yield takeEvery(actions.runReport.type, runReport);
};

const watchDeleteTemplate = function*() {
  yield takeEvery(actions.deleteReport.type, deleteTemplate);
};

const watchCreateTemplate = function*() {
  yield takeEvery(actions.createReport.type, createTemplate);
};

const watchCopyTemplate = function*() {
  yield takeEvery(actions.copyReport.type, copyTemplate);
};

const watchEditReport = function*() {
  yield takeEvery(actions.editReport.type, editReport);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchRefetchReports),
    fork(watchGoToModuleReports),
    fork(watchRunReport),
    fork(watchCreateTemplate),
    fork(watchCopyTemplate),
    fork(watchDeleteTemplate),
    fork(watchEditReport)
  ]);
};

export default rootSaga;
