import PropTypes from "prop-types";
import React from "react";
import Helpers from "utils/Global/Helpers";
import ContactName from "../../ContactName";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Contact = ({ user_fname, user_email, user_lname }) => (
  <div styleName="selectedContact">
    <div styleName="avatar">
      {user_fname
        ? Helpers.buildUserAcronym(user_fname, user_lname)
        : Helpers.buildUserAcronym(user_email, "")}
    </div>
    <ContactName
      user_fname={user_fname}
      user_email={user_email}
      user_lname={user_lname}
    />
  </div>
);

Contact.propTypes = {
  user_fname: PropTypes.string,
  user_email: PropTypes.string,
  user_lname: PropTypes.string
};

export default CSSModules(Contact, css);
