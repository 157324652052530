import React from "react";

import { Div, Text2, FilterIcon } from "components/Base";

const FiltersOn = ({
  on = false,
  onClearFilters,
  caption = "Filtering is ON",
  bg = "success1",
  Icon = FilterIcon,
  ...styleProps
}) =>
  on ? (
    <Div
      display="row.flex-start.center"
      p={2}
      bg={bg}
      style={{ flexShrink: 0 }}
      {...styleProps}
    >
      <Icon color="neutral8" />
      <Text2 ml={2} bold>
        {caption}
      </Text2>
      {onClearFilters ? (
        <Text2
          ml={2}
          color="neutral8"
          bb={1}
          bc="neutral6"
          onClick={() => onClearFilters()}
        >
          Clear
        </Text2>
      ) : null}
    </Div>
  ) : null;

export default FiltersOn;
