import { MEAL_TYPE_ID, CREDENTIAL_TYPE_ID } from "utils/item-types";

export const types = state => state.items.types.types;
export const typeByType = (state, typeId) =>
  state.items.types.types.find(t => t.id === typeId) || {};
export const credentialItemTypes = state =>
  typeByType(state, CREDENTIAL_TYPE_ID);
export const mealItemTypes = state => typeByType(state, MEAL_TYPE_ID);

// all types
export const allTypes = state => state.items.types.allTypes;
