import * as R from "ramda";
import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import { actions, getters } from "./index";
import { registerError } from "redux/modules/errors/actions";
import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";
import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  const eventDetails = yield select(getEventDetails);
  const userId = yield select(getUserId);

  return {
    credentials,
    eventId,
    eventDetails,
    userId
  };
};

const init = function*({ payload: { moduleId, typeId } }) {
  try {
    yield put(actions.setLoading(true));
    const { credentials, eventId } = yield call(getParams);

    const apiToUse = moduleId
      ? Api.getModuleApprovers
      : Api.getItemTypeApprovers;
    const { payload } = yield call(apiToUse, {
      credentials,
      eventId,
      moduleId,
      typeId
    });

    yield put(
      actions.setInitialData({
        data: payload,
        moduleId,
        typeId
      })
    );
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading approvers"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const refreshData = function*() {
  try {
    const { credentials, eventId } = yield call(getParams);
    const moduleId = yield select(getters.moduleId);
    const typeId = yield select(getters.typeId);
    const apiToUse = moduleId
      ? Api.getModuleApprovers
      : Api.getItemTypeApprovers;

    const { payload } = yield call(apiToUse, {
      credentials,
      eventId,
      moduleId,
      typeId
    });

    yield put(
      actions.setInitialData({
        data: payload,
        moduleId,
        typeId
      })
    );
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading approvers"
          }
        ])
      )
    ]);
  }
};

const updateApprover = function*({ payload: { canApprove, userId } }) {
  try {
    const { credentials } = yield call(getParams);
    const data = yield select(getters.data);

    let approvers = R.compose(
      R.map(R.prop("user_id")),
      R.filter(u => u.is_approver)
    )(data.has_access);

    if (canApprove) {
      approvers = R.uniq([...approvers, userId]);
    } else {
      approvers = R.without([userId], approvers);
    }

    yield call(Api.updateApprovers, {
      credentials,
      data: {
        approvers: approvers,
        spaceId: data.space_id
      }
    });

    yield call(refreshData);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading approvers"
          }
        ])
      )
    ]);
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchRefreshData = function*() {
  yield takeEvery(actions.refreshData.type, refreshData);
};

const watchUpdateApprover = function*() {
  yield takeEvery(actions.updateApprover.type, updateApprover);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchRefreshData),
    fork(watchUpdateApprover)
  ]);
};

export default rootSaga;
