import React, { Component } from "react";
import { Link } from "react-router";
import * as R from "ramda";

import { Div } from "components/Base";

import {
  getSettings,
  getMeta,
  getRow,
  getCellValue
} from "ui-kit/Datagrid/utils";

class AccountNameFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props.value, nextProps.value);
  }

  render() {
    const suppressLinkToRecord = getSettings(
      this.props,
      "suppressLinkToRecord"
    );
    const orgId = getMeta(this.props, "orgId");
    const eventId = getMeta(this.props, ["eventDetails", "id"]);
    const row = getRow(this.props);
    const value = getCellValue(this.props);

    const path = orgId
      ? `/organization/${orgId}/account/${row.id}`
      : `/event/${eventId}/account/${row.id}`;

    return (
      <Div width={1}>
        {!suppressLinkToRecord ? (
          <Link to={path}>
            <Div fw={3} color="purple8">
              {value}
            </Div>
          </Link>
        ) : (
          <Div fw={3}>{value}</Div>
        )}
      </Div>
    );
  }
}

export default AccountNameFormatter;
