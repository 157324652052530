import React from "react";
import { withRouter } from "react-router";
import * as R from "ramda";
import MenuItem from "./MenuItem";
import FolderRow from "./FolderRow";

const isRouteActive = (routes, routeNames = []) =>
  routes.some(r => routeNames.includes(r.name));

const SectionRow = ({
  children,
  route,
  path,
  hideMenu,
  routes,
  params,
  customStyle = {},
  id,
  contentType,
  paddingLeft
}) => (
  <MenuItem
    hideMenu={hideMenu}
    rightIcon={null}
    active={
      contentType !== "page"
        ? isRouteActive(routes, route)
        : id === params.pageId
    }
    url={`/portals/${params.portalSlug}/${params.portalId}/${path}`}
    customStyle={customStyle}
    paddingLeft={paddingLeft}
  >
    {children}
  </MenuItem>
);

const MenuRow = ({ contentType, pagesChildren, ...otherProps }) =>
  contentType !== "page" || R.isEmpty(pagesChildren) ? (
    <SectionRow {...otherProps} contentType={contentType} />
  ) : (
    <FolderRow {...otherProps} pagesChildren={pagesChildren} />
  );

export default withRouter(MenuRow);
