import api from "./api";

export function sendEmail({
  eventId,
  recipients,
  templateName,
  variables,
  tags,
  recipientMetaData
}) {
  return async (dispatch, getState) => {
    const state = getState();
    return api.post(state.user.user.credentials, {
      eventId,
      emails: [
        {
          recipients,
          templateName,
          variables,
          tags,
          recipientMetaData
        }
      ]
    });
  };
}

export function sendEmails(eventId, emails) {
  return async (dispatch, getState) => {
    const state = getState();
    return api.post(state.user.user.credentials, {
      eventId,
      emails
    });
  };
}
