import { get } from "lodash";

export default ({ all, field }) => [
  ...all,
  ...get(field, "settings.availableCatalogItems", []).map(item => ({
    id: `${field.id}_${item.id}`,
    name:
      field.name === item.value ? field.name : `${item.value} (${field.name})`,
    type: "number",
    visible: true
  }))
];
