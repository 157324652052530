import * as R from "ramda";

import PropTypes from "prop-types";
import React, { Component } from "react";
import { get, merge } from "lodash";
import { addS } from "utils/General";
import { Div, Text1, OpenInNewIcon } from "components/Base";

import Card from "components/Global/CRM/Card";
import IconTitle from "components/Global/CRM/Card/IconTitle";
import Header from "components/Global/CRM/Card/Header";
import Title from "components/Global/CRM/Card/Title";

import Loading from "./Loading";
import ActionsBar from "./ActionsBar";
import PaginationBar from "components/Global/ReactTable/Pagination/Muted";
import getRowsSelectedHeader from "components/Global/ReactTable/TableHeaders/RowsSelected";
import getCheckboxColumn from "components/Global/ReactTable/Columns/Checkbox";
import getColumn from "components/Global/ReactTable/utils/get-column";
import ReactTable from "react-table";
import resolveOnClickPrimaryField from "components/Global/ReactTable/CellFormatters/PrimaryField/resolveOnClick";

import ViewRecord from "components/Global/Module/Modals/ViewRecord";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import EditRecordModal from "components/Global/Module/Modals/EditRecord";
import DeleteRecordsModal from "components/Global/CRM/Modals/DeleteConfirmation";
import SendRecordFormModal from "components/Global/Module/Modals/SendRecordForm";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

import getColumns from "components/Global/CRM/TableViews/utils/get-fields";
import getMetaData from "utils/value-types/get-meta-data";
import resolveActionButton from "components/Global/CRM/RelatedModule/utils/resolveActionButton";
import resolveRecordName from "components/Event/Module/utils/resolveRecordName";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

const standardModuleIds = Object.keys(STANDARD_MODULE_IDS).map(
  k => STANDARD_MODULE_IDS[k].id
);

class RelatedModule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRows: [],
      page: 0,
      pageSize: 2
    };
  }

  componentDidMount() {
    this.getRelatedRecords();
  }

  getRelatedRecords = () => {
    this.props.getRelatedRecords({
      recordId: this.props.record.id,
      moduleId: this.props.module.id,
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId,
        fieldId: this.props.module.lookup_field
          ? this.props.module.lookup_field.id
          : null,
        valueId: this.props.record.id,
        valueModuleId: this.props.record.moduleId
      }
    });
  };

  showRecordModal = recordId => {
    this.props.showModal({
      content: (
        <ViewRecord
          moduleId={this.props.module.id}
          recordId={recordId}
          hideModal={this.onHideModal}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  getHelpers = () => ({
    showModal: this.props.showModal,
    hideModal: this.onHideModal,
    addReference: this.props.addReference
  });

  onHideModal = () => {
    this.getRelatedRecords();
    this.props.hideModal();
  };

  isFieldVisible = c => {
    if (get(this.props, ["preferences", "visible_fields"], []).length) {
      return this.props.preferences.visible_fields.includes(c.id);
    }
    return true;
  };

  getFieldOrder = c => {
    return get(this.props, ["preferences", "field_order", c.id], 1);
  };

  getFieldWidth = c => {
    if (c.width) {
      // Handle hard coded fixed width (approvals, system fields)
      return c.width;
    }
    const fieldWidths = get(this.props, ["preferences", "field_widths"]);
    // @NOTE: The fallback field width (if undefined below) is handled in `get-column`,
    // so, no need to specify a fallback below unless you want to overide the default
    return get(fieldWidths, [c.id], 150);
  };

  getColumns = () => {
    if (!this.props.records.length) {
      return [];
    }

    let columns = getColumns(this.props.module.fields.fields, {
      isFieldVisible: this.isFieldVisible,
      getFieldOrder: this.getFieldOrder,
      getFieldWidth: this.getFieldWidth,
      meta: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId,
        moduleId: this.props.module.id,
        helpers: {
          showModal: this.props.showModal,
          router: this.props.router,
          hideModal: this.onHideModal
        }
      }
    }).filter(c => c.visible);

    columns.unshift(
      {
        ...getCheckboxColumn({
          selectAll: this.selectAllRows,
          onClick: this.onRowSelect,
          isSelectAllChecked: this.isSelectAllChecked,
          isChecked: this.isChecked
        }),
        width: 69
      },
      {
        id: "view",
        visible: true,
        width: 70,
        sortable: false,
        resizable: false,
        Header: "",
        Cell: props => {
          const onClick = resolveOnClickPrimaryField({
            orgId: this.props.params.orgId,
            eventId: this.props.params.eventId,
            moduleId: this.props.module.id,
            helpers: {
              showModal: this.props.showModal,
              hideModal: this.onHideModal,
              router: this.props.router
            }
          });
          return (
            <Div
              display="row.center.center"
              bra={2}
              py={1}
              bc="neutral0"
              b={1}
              fs={1}
              bg={{
                default: "white",
                hover: "neutral0"
              }}
              onClick={() => onClick(props)}
            >
              <OpenInNewIcon sizeWFS={2} mr={1} />
              <span>View</span>
            </Div>
          );
        }
      }
    );

    columns = columns.filter(c => c);

    return columns.map(column =>
      getColumn({
        column,
        getRowMetaData: this.getRowMetaData
      })
    );
  };

  approveRecord = recordId => {
    return this.props.createReview({
      review: {
        moduleId: this.props.module.id,
        recordId,
        response: "approve",
        userId: this.props.user.id
      }
    });
  };

  rejectRecord = recordId => {
    return this.props.createReview({
      review: {
        moduleId: this.props.module.id,
        recordId,
        response: "reject",
        userId: this.props.user.id
      }
    });
  };

  removeReview = recordId => {
    this.props.removeReview({
      review: {
        moduleId: this.props.module.id,
        recordId,
        userId: this.props.user.id
      }
    });
  };

  getRowMetaData = (rowData, column) =>
    merge(
      getMetaData({
        row: rowData.values,
        rowId: rowData.id,
        field: column,
        fields: this.props.module.fields.fields,
        userId: this.props.user.id,
        eventDetails: this.props.eventDetails,
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId,
        references: this.props.references
      }),
      {
        meta: {
          moduleId: this.props.module.id
        },
        helpers: {
          showModal: this.props.showModal,
          hideModal: this.onHideModal,
          router: this.props.router,
          addReference: this.props.addReference,
          approveRecord: () => this.approveRecord(rowData.id),
          rejectRecord: () => this.rejectRecord(rowData.id),
          removeReview: () => this.removeReview(rowData.id)
        }
      }
    );

  recordModalProps = {
    moduleId: this.props.module.id,
    relatedRecord: this.props.record,
    references: [
      {
        moduleInternalName: this.props.record.moduleInternalName,
        recordId: this.props.record.id,
        recordName: this.props.record.name
      }
    ],
    values: this.props.record.values.reduce((a, b) => {
      a[b.fieldId] = b.value;
      return a;
    }, {}),
    onSave: this.getRelatedRecords
  };

  showAddRecordModal = () => {
    this.props.showModal({
      content: <AddRecordModal {...this.recordModalProps} />,
      wrapper: ModalWrapper
    });
  };

  showEditRecordModal = recordId => {
    const record = this.props.records.find(r => r.id === recordId);
    this.props.showModal({
      content: (
        <EditRecordModal
          {...this.recordModalProps}
          values={record.values}
          record={record}
          hideModal={() => {
            this.props.hideModal();
            this.setState({ selectedRows: [] });
            this.getRelatedRecords();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showSendFormModal = recordIds => {
    this.props.showModal({
      content: (
        <SendRecordFormModal
          sendToAccountId={this.props.record.id}
          sendToAccountName={this.props.record.name}
          moduleId={this.props.module.id}
          recordIds={recordIds}
          onUpdate={this.props.onUpdate}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  postUpdateActions = () => {
    this.setState({ selectedRows: [] });
    return this.getRelatedRecords();
  };

  isSelectAllChecked = () =>
    this.state.selectedRows.length &&
    this.state.selectedRows.length === this.props.records.length;

  isChecked = id =>
    this.state.selectedRows.length && this.state.selectedRows.includes(id);

  selectAllRows = () =>
    this.setState(state => {
      if (this.isSelectAllChecked()) {
        state.selectedRows = [];
      } else {
        state.selectedRows = this.props.records.map(({ id }) => id);
      }
      return state;
    });

  onRowSelect = id =>
    this.setState(state => {
      if (state.selectedRows.includes(id)) {
        state.selectedRows = state.selectedRows.filter(r => r !== id);
      } else {
        state.selectedRows = [...state.selectedRows, id];
      }
      return state;
    });

  showDeleteRecordsModal = () => {
    const { showModal, hideModal } = this.props;
    const { selectedRows } = this.state;
    const message = (
      <div>
        {`
            Are you sure you want to remove ${
              selectedRows.length === 1
                ? "this"
                : `these ${selectedRows.length}`
            } record${addS(selectedRows.length)}?
            `}
        <div style={{ fontWeight: "bold", padding: "10px 0" }}>
          This cannot be undone.
        </div>
      </div>
    );

    showModal({
      content: (
        <DeleteRecordsModal
          hideModal={hideModal}
          heading={`Delete record${addS(selectedRows.length)}?`}
          message={message}
          onConfirm={this.bulkDelete}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  bulkDelete = () =>
    this.props
      .deleteRecord({
        bulk: true,
        moduleId: this.props.module.id,
        records: this.state.selectedRows,
        options: {
          orgId: this.props.params.orgId,
          eventId: this.props.params.eventId
        }
      })
      .then(this.postUpdateActions);

  // @NOTE: Only show send form option if on an account and if related record
  // is not a standard module
  canShowSendFormModal = () =>
    this.props.record.moduleInternalName === "accounts" &&
    !standardModuleIds.includes(this.props.module.id);

  setPage = page => {
    this.setState({ page });
  };

  setPageSize = (pageSize, page) => {
    this.setState({ pageSize, page });
  };

  static getDerivedStateFromProps(props) {
    const countOfRecords = R.length(props.records);

    return {
      pageSize: countOfRecords < 5 ? countOfRecords || 2 : 5
    };
  }

  render() {
    const { records, record, isFetching, user } = this.props;

    const recordName = resolveRecordName({
      moduleId: this.props.module.id,
      plural: false
    }).toLowerCase();
    const recordNamePlural = resolveRecordName({
      moduleId: this.props.module.id,
      plural: true
    }).toLowerCase();

    return (
      <Card color={this.props.module.color}>
        <Header>
          <Title>
            <IconTitle
              title={
                <Div display="row.flex-start.center">
                  <Div display="column">
                    <span>{this.props.module.name}</span>
                    {get(this.props.module, ["lookup_field", "name"]) ? (
                      <Text1 color="neutral8">
                        Field: {this.props.module.lookup_field.name}
                      </Text1>
                    ) : null}
                  </Div>
                  {isFetching ? <Loading style={{ marginLeft: 10 }} /> : null}
                </Div>
              }
              color={this.props.module.color}
            >
              <i
                className="material-icons"
                style={{
                  color: "#fff",
                  fontSize: 16
                }}
              >
                {this.props.module.icon}
              </i>
            </IconTitle>
            {resolveActionButton({
              moduleId: this.props.module.id,
              meta: {
                user,
                module,
                record,
                showSnackbar: this.props.showSnackbar,
                showModal: this.props.showModal,
                hideModal: this.props.hideModal,
                showAddRecordModal: this.showAddRecordModal,
                onCreate: this.postUpdateActions
              }
            })}
          </Title>
        </Header>
        <div>
          <ReactTable
            resizable
            className="ReactTable-record"
            noDataText=""
            columns={this.getColumns()}
            data={records}
            showPageSizeOptions={false}
            rowsText={records.length === 1 ? recordName : recordNamePlural}
            PaginationComponent={PaginationBar}
            TheadComponent={getRowsSelectedHeader({
              ActionsComponent: ActionsBar,
              selectedRows: this.state.selectedRows,
              helpers: {
                selectAll: this.selectAllRows,
                isSelectAllChecked: this.isSelectAllChecked,
                deleteRecords: this.showDeleteRecordsModal,
                edit:
                  this.props.module.id === STANDARD_MODULE_IDS.files.id
                    ? undefined
                    : this.showRecordModal,
                sendForm: this.canShowSendFormModal()
                  ? this.showSendFormModal
                  : false
              }
            })}
            page={this.state.page}
            pageSize={this.state.pageSize}
            pages={Math.max(
              1,
              Math.ceil(R.length(records) / this.state.pageSize)
            )}
            onPageChange={this.setPage}
            onPageSizeChange={this.setPageSize}
          />
        </div>
      </Card>
    );
  }
}

RelatedModule.defaultProps = {
  records: []
};

RelatedModule.propTypes = {
  record: PropTypes.shape({
    valueName: PropTypes.string,
    moduleInternalName: PropTypes.string,
    moduleId: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    values: PropTypes.array
  }).isRequired,
  module: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    record_name: PropTypes.string,
    record_name_plural: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.string,
    event_id: PropTypes.number,
    preferences: PropTypes.shape({
      visible_fields: PropTypes.array,
      field_order: PropTypes.object,
      field_widths: PropTypes.object
    }).isRequired,
    fields: PropTypes.shape({
      fields: PropTypes.array,
      starred_fields: PropTypes.array,
      field_groups: PropTypes.array
    })
  }).isRequired,
  records: PropTypes.arrayOf(PropTypes.object).isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  getRelatedRecords: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  deleteRecord: PropTypes.func.isRequired,
  addReference: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  onUpdate: PropTypes.func
};

export default RelatedModule;
