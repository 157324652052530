import PropTypes from "prop-types";
import React from "react";
import PaginationBar from "../Pagination/General";
import ReactTable from "react-table";

const HEADER_HEIGHT = 46;
const ROW_HEIGHT = 39;

const Table = ({ data, columns, onColumnHeaderClick, className, ...rest }) => (
  <ReactTable
    className={className || "ReactTable--Lennd"}
    data={data}
    columns={columns}
    getTheadProps={() => ({
      style: {
        height: HEADER_HEIGHT
      }
    })}
    getTheadThProps={(state, rowInfo, column, instance) => ({
      onClick: onColumnHeaderClick
        ? e => onColumnHeaderClick(e, state, rowInfo, column, instance)
        : undefined
    })}
    getTbodyProps={state => ({
      style: {
        height: `calc(100% - ${HEADER_HEIGHT}px)`,
        overflowX: "hidden"
      }
    })}
    getTdProps={() => ({
      style: {
        height: ROW_HEIGHT
      }
    })}
    showPageSizeOptions
    showPagination
    showPaginationBottom
    {...rest}
  >
    {(state, makeTable, instance) => makeTable()}
  </ReactTable>
);

Table.defaultProps = {
  data: [],
  columns: [],
  PaginationComponent: PaginationBar
};

Table.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  onColumnHeaderClick: PropTypes.func
};

export default Table;
