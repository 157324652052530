import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { actions } from "SalesPortal/ItemsModal";
import salesPortalItemsModal from "SalesPortal/ItemsModal";
import Layout from "./Layout";
import SalesPortalItemsModalSagas from "SalesPortal/ItemsModal/sagas";

salesPortalItemsModal.setRootSaga(SalesPortalItemsModalSagas);

const decorate = connect(
  null,
  {
    getItemTypes: actions.getItemTypes,
    setInitialData: actions.setInitialData
  }
);

class SalesPortal extends Component {
  componentDidMount() {
    if (!this.props.itemTypes) {
      this.props.getItemTypes({
        eventId: this.props.eventId,
        iniSelectedTab: this.props.iniSelectedTab
      });
    } else {
      this.props.setInitialData({
        itemTypesList: this.props.itemTypes,
        iniSelectedTab: this.props.iniSelectedTab
      });
    }
  }

  render() {
    const handlers = {
      hideModal: this.props.hideModal,
      title: this.props.title,
      navigate: this.props.router.push,
      eventId: this.props.params.eventId,
      redirectLink: this.props.redirectLink
    };
    return <Layout handlers={handlers} />;
  }
}

export default decorate(withRouter(SalesPortal));
