import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import InputGroup from "components/Global-2016/Forms/InputGroup";
import Label from "components/Global-2016/Forms/Label";

const TextArea = ({
  label,
  disabled,
  description,
  placeholder,
  value,
  onChange,
  onBlur,
  required,
  isAdminField,
  containerStyles,
  ...props
}) => (
  <InputGroup styles={containerStyles} style={{ width: "100%" }}>
    {label && (
      <Label
        isAdminField={isAdminField}
        required={required}
        description={description}
      >
        {label}
      </Label>
    )}
    <textarea
      styleName="input"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      required={required}
      rows={props.rows || 5}
      {...props}
    />
  </InputGroup>
);

TextArea.propTypes = {
  label: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  isAdminField: PropTypes.bool,
  containerStyles: PropTypes.object,
  rows: PropTypes.number
};

export default CSSModules(TextArea, css);
