import PropTypes from "prop-types";
import React, { Component } from "react";
import { get } from "lodash";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import AccountTypeIcon from "components/Global/CRM/AccountTypeIcon";
import UserTypeIcon from "components/PortalV2/UserTypeIcon";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Body from "components/Global/Modal/Layout/ScrollableBody";
import CircularProgress from "material-ui/CircularProgress";

export const Loading = CSSModules(
  () => (
    <div styleName="progress">
      <CircularProgress color="#ccc" />
    </div>
  ),
  css
);

const Account = CSSModules(
  ({ name, type, icon, onClick }) => (
    <div styleName="account" onClick={onClick}>
      {icon}
      <div styleName="accountBody">
        <div styleName="accountTitle">{name}</div>
        <div styleName="accountType">{type}</div>
      </div>
      <div styleName="separator" />
      <i className="material-icons" styleName="arrow">
        keyboard_arrow_right
      </i>
    </div>
  ),
  css
);

class AddSubmissionAsModal extends Component {
  state = { filter: null };

  componentDidMount() {
    this.props.getPortalUser({
      userId: this.props.user.id,
      eventId: this.props.eventDetails.uuid
    });
  }

  selectRecord = recordId => {
    this.props.onDone(recordId);
  };

  onFilterChange = e => this.setState({ filter: e.target.value });

  clearFilter = () => this.setState({ filter: null });

  getFilteredItems = (filter, items) => {
    if (!filter) return items;

    const formattedFilter = filter.trim().toLowerCase();
    return items.filter(i => i.search.indexOf(formattedFilter) > -1);
  };

  render() {
    const { filter } = this.state;
    const { user, accounts, isFetching, scope } = this.props;
    const name = user.fname ? `${user.fname} ${user.lname}` : user.email;
    const filtered = this.getFilteredItems(filter, accounts);

    return (
      <StyleWrapper
        hideModal={this.props.hideModal}
        heading="Add submission as..."
        width={391}
        bodyStyles={{ padding: 0 }}
      >
        <div className={css.inputWrapper}>
          <input
            autoFocus
            value={filter}
            onChange={this.onFilterChange}
            placeholder={`Filter ${
              scope === "account" ? "your groups" : "users"
            }...`}
            className={css.input}
            type="text"
          />
          {filter ? (
            <i
              className={["material-icons", css.clearFilter].join(" ")}
              onClick={this.clearFilter}
            >
              close
            </i>
          ) : (
            <i className="material-icons">search</i>
          )}
        </div>
        <Body
          style={{
            height: 400,
            padding: "10px 17px 10px 17px"
          }}
        >
          {isFetching ? (
            <Loading />
          ) : (
            <div>
              {filter && !filtered.length ? (
                <div className={css.empty}>
                  No {scope === "account" ? "groups" : "users"} matched your
                  search
                </div>
              ) : null}
              {filter || (scope && scope === "account") ? null : (
                <Account
                  name={name}
                  type="As yourself"
                  icon={<UserTypeIcon color="#FDE23D" size={48} name={name} />}
                  onClick={() => this.selectRecord()}
                />
              )}
              {filtered.map(account => (
                <Account
                  key={account.id}
                  name={get(account, "name")}
                  type={get(account, "type.name") || "Account"}
                  icon={
                    <AccountTypeIcon
                      name={get(account, "name")}
                      color={get(account, "type.text_color")}
                      backgroundColor={get(account, "type.background_color")}
                      icon={get(account, "type.icon")}
                      size={48}
                    />
                  }
                  onClick={() => this.selectRecord(account.id)}
                />
              ))}
            </div>
          )}
        </Body>
      </StyleWrapper>
    );
  }
}

AddSubmissionAsModal.propTypes = {
  accounts: PropTypes.array.isRequired,
  eventDetails: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  getPortalUser: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onDone: PropTypes.func.isRequired,
  scope: PropTypes.string
};

export default AddSubmissionAsModal;
