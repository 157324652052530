import Helpers from "utils/Global/Helpers";

/* eslint no-underscore-dangle: "off" */

export default {
  getReports: ({ credentials, eventId, moduleId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/reports/event/${eventId}/templates/module/${moduleId}`,
        credentials,
        success,
        error
      });
    }),
  runReport: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/reports/event/${data.eventId}/run`,
        credentials,
        data,
        success,
        error
      });
    }),
  getReportStatus: (credentials, { jobId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/jobs/progress/${jobId}`,
        credentials,
        success,
        error
      });
    })
};
