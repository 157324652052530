import React, { Fragment } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import SidebarLink from "components/Event/Module/Navigation/Common/SidebarLink";
import { Div, EditIcon } from "components/Base";
import Tooltip from "components/Global/Tooltip";
import { Link } from "react-router";

const QuickFilters = CSSModules(
  ({
    showMainFilters = true,
    selectQuickFilter,
    recordTypes,
    selectMyRecords,
    myRecordsIsSelected,
    allRecordsIsSelected,
    recordNamePluralLowercase,
    countOfRecords,
    countOfMyRecords,
    moduleHasApproval,
    //
    selectPending,
    selectApproved,
    selectDenied,
    //
    pendingIsSelected,
    approvedIsSelected,
    deniedIsSelected,
    //
    forms
  }) => (
    <div>
      {showMainFilters || recordTypes.length > 1 ? (
        <div styleName="header">Filters</div>
      ) : null}

      {showMainFilters ? (
        <Fragment>
          <SidebarLink
            onClick={selectQuickFilter}
            active={allRecordsIsSelected}
          >
            <span>All {recordNamePluralLowercase}</span>{" "}
            <div className={css.count}>{countOfRecords}</div>
          </SidebarLink>
          <SidebarLink onClick={selectMyRecords} active={myRecordsIsSelected}>
            <span>My {recordNamePluralLowercase}</span>{" "}
            <div className={css.count}>{countOfMyRecords}</div>
          </SidebarLink>
        </Fragment>
      ) : null}

      {moduleHasApproval ? (
        <div>
          <div styleName="separator" style={{ paddingTop: 5 }} />
          <div styleName="enclosedHeader">Status</div>
          <SidebarLink active={pendingIsSelected} onClick={selectPending}>
            Pending
          </SidebarLink>
          <SidebarLink active={approvedIsSelected} onClick={selectApproved}>
            Approved
          </SidebarLink>
          <SidebarLink active={deniedIsSelected} onClick={selectDenied}>
            Denied
          </SidebarLink>
        </div>
      ) : null}

      {recordTypes.length > 1 ? (
        <div>
          {showMainFilters ? (
            <Fragment>
              <div styleName="separator" />
              <div styleName="enclosedHeader">Type</div>
            </Fragment>
          ) : null}
          {[...recordTypes].map(type => (
            <SidebarLink
              key={type.id}
              active={type.isSelected}
              onClick={type.onClick}
            >
              {type.name}
              <div className={css.count}>{type.countOfRecords}</div>
            </SidebarLink>
          ))}{" "}
        </div>
      ) : null}

      {forms.length ? (
        <div>
          <div styleName="separator" style={{ paddingTop: 5 }} />
          <div styleName="enclosedHeader">Form</div>
          {forms.map(form => (
            <SidebarLink key={form.id} active={form.isSelected}>
              <Div
                className="hoverContainer"
                display="row.space-between.center"
                width={1}
              >
                <Div onClick={form.onClick} truncate>
                  {form.name}
                </Div>
                <span
                  className="showOnHover"
                  style={{
                    flexShrink: 0
                  }}
                >
                  {form.canEdit && (
                    <Tooltip key={form.id} tooltip="Edit Form">
                      <Link to={{ pathname: form.formEditPath }}>
                        <EditIcon sizeWFS={2} />
                      </Link>
                    </Tooltip>
                  )}
                </span>
              </Div>
            </SidebarLink>
          ))}{" "}
        </div>
      ) : null}
    </div>
  ),
  css
);

export default QuickFilters;
