import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";

import { FieldDisplay } from "./Parts";
import View from "./View";
import RelatedModule from "./RelatedModule";
import Feed from "components/Global/Module/Feed";
import {
  toClass,
  mapProps,
  withState,
  lifecycle,
  findBy,
  defaultProps,
  withProps,
  makeEnum,
  noop
} from "utils/General";
import {
  Div,
  DarkTab,
  PopoverMenu,
  CollapsablePanelType1,
  DownFilledIcon
} from "components/Base";

import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import getRecordName from "utils/get-record-name";

const mapWithIndex = R.addIndex(R.map);

const fetchers = mapProps(
  ({ moduleId, eventId, recordId, getModule, getRecord, ...props }) => ({
    fetchModule: () =>
      getModule({
        moduleId,
        options: {
          eventId
        }
      }),
    fetchRecord: () =>
      getRecord({
        recordId,
        moduleId,
        options: {
          eventId
        }
      }),
    moduleId,
    eventId,
    recordId,
    ...props
  })
);

const onDidMount = lifecycle({
  componentDidMount() {
    Promise.all([this.props.fetchModule(), this.props.fetchRecord()]).then(() =>
      this.props.setLoading(false)
    );
  },
  componentWillUnmount() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }
});

const modalAdder = mapProps(
  ({ fetchRecord, showModal, hideModal, ...props }) => ({
    showAddRecordModal: (moduleId, values, getRelatedRecords) => {
      showModal({
        content: (
          <AddRecordModal
            moduleId={moduleId}
            onSave={() => {
              getRelatedRecords();
            }}
            values={values}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    fetchRecord,
    showModal,
    hideModal,
    ...props
  })
);

const starredFieldParser = mapProps(
  ({ fieldGroups, starredFields, ...props }) => {
    let newStarredFields = [];
    const starredFieldIds = R.map(R.prop("field_id"))(starredFields);
    const starredFieldOrder = R.reduce((map, field) => {
      map[field.field_id] = field.order;
      return map;
    }, {})(starredFields);

    const strippedFieldGroups = R.mapObjIndexed(group => {
      const pickBasedOn = f =>
        f(field => R.contains(field.field_id, starredFieldIds), group.fields);
      newStarredFields = [...newStarredFields, ...pickBasedOn(R.filter)];
      return {
        ...group,
        fields: pickBasedOn(R.reject)
      };
    }, fieldGroups);

    return {
      fieldGroups: strippedFieldGroups,
      starredFields: R.sortBy(f => starredFieldOrder[f.field_id])(
        newStarredFields
      ),
      ...props
    };
  }
);

const PopoverTab = toClass(({ active, view, onClick }) => (
  <DarkTab onClick={onClick} active={active}>
    {view ? `Linked Records: ${view}` : "Linked Records"}
    <DownFilledIcon size={16} color="inherit" />{" "}
  </DarkTab>
));

const relatedModuleViewHandler = mapProps(
  ({ relatedModules = [], ...props }) => {
    const relatedModuleNames = R.map(R.prop("name"))(relatedModules);
    const views = makeEnum(["Details", "All", ...relatedModuleNames]);
    return {
      views,
      defaultSelectedView: views.Details,
      relatedModules,
      ...props
    };
  }
);

const loadingToggle = withState("loading", "setLoading", true);

const viewSelector = withState(
  "selectedView",
  "setSelectedView",
  R.prop("defaultSelectedView")
);

const FieldFormatter = ({
  field,
  record,
  references,
  eventId,
  moduleId,
  isEditing,
  setIsEditing,
  saveField,
  eventDetails,
  readOnlyFields
}) => {
  if (!R.isNil(field)) {
    return (
      <FieldDisplay
        field={field}
        name={field.name}
        data={{
          id: field.id,
          type: field.type
        }}
        saveField={saveField}
        eventDetails={eventDetails}
        isEditing={isEditing}
        moduleId={moduleId}
        setIsEditing={setIsEditing}
        readOnlyFields={readOnlyFields}
        references={references}
        value={R.path(["values", field.id], record)}
        dependentValues={{
          meta: {
            rowId: record.id,
            moduleId,
            eventId,
            columnId: field.id,
            columnSettings: field.settings,
            references
          }
        }}
      />
    );
  }

  return null;
};

const isEditingHandler = withState("isEditing", "setIsEditing", null);

const contentPicker = mapProps(
  ({
    addValue,
    eventDetails,
    eventId,
    fetchRecord,
    fieldGroups = [],
    isEditing,
    loading,
    moduleDetails,
    readonly,
    record,
    references,
    relatedModules,
    selectedView,
    setIsEditing,
    setSelectedView,
    showAddRecordModal,
    starredFields,
    views,
    ...props
  }) => {
    const moduleId = moduleDetails.id;

    const readOnlyFields = resolveReadOnlyFields({ moduleId });

    const menuItems = R.map(
      view => [view, () => setSelectedView(view)],
      R.without([views.Details], R.keys(views))
    );
    const detailsViewActive = selectedView === views.Details;

    const topBarContent = (
      <Div display="row.flex-start.center">
        <DarkTab
          onClick={
            detailsViewActive ? noop : () => setSelectedView(views.Details)
          }
          active={detailsViewActive}
        >
          Details
        </DarkTab>
        {relatedModules.length ? (
          <PopoverMenu
            Label={withProps({ active: !detailsViewActive })(PopoverTab)}
            labelProps={{ view: detailsViewActive ? null : selectedView }}
            menuItems={menuItems}
          />
        ) : null}
      </Div>
    );

    const mapFieldFormatter = mapWithIndex(({ field }, i) => (
      <FieldFormatter
        key={i}
        readOnlyFields={readOnlyFields}
        eventId={eventId}
        field={field}
        record={record}
        references={references}
        isEditing={isEditing}
        eventDetails={eventDetails}
        setIsEditing={readonly ? undefined : setIsEditing}
        moduleId={moduleId}
        saveField={value => {
          addValue({
            value,
            fieldId: field.id,
            recordId: record.id,
            moduleId,
            eventId
          }).then(fetchRecord);
        }}
      />
    ));

    const makeRelatedModules = (relatedModule, i = 0) => (
      <RelatedModule
        key={i}
        relatedModule={relatedModule}
        recordId={record.id}
        moduleId={moduleId}
        eventId={eventId}
        references={references}
        eventDetails={eventDetails}
        onClickCreate={(moduleRecords, getRelatedRecords) => {
          const defaultFieldValue = R.compose(
            R.fromPairs,
            R.reduce((a, [k, v]) => {
              if (R.path(["value", "moduleId"], v) === moduleId) {
                return [
                  ...a,
                  [k, R.set(R.lensPath(["value", "records"]), [record.id], v)]
                ];
              }
              return a;
            }, []),
            R.toPairs,
            R.path([0, "values"])
          )(moduleRecords);

          return showAddRecordModal(
            relatedModule.id,
            defaultFieldValue,
            getRelatedRecords
          );
        }}
      />
    );

    const leftContent = <Div>{mapFieldFormatter(starredFields)}</Div>;

    let middleContent = null;

    if (selectedView === views.Details) {
      middleContent = (
        <Div>
          {mapWithIndex(
            ({ name, fields }, i) => (
              <CollapsablePanelType1 key={i} headingText={name}>
                <Div pt={2}>{mapFieldFormatter(fields)}</Div>
              </CollapsablePanelType1>
            ),

            R.values(R.filter(({ fields }) => fields.length)(fieldGroups))
          )}
        </Div>
      );
    } else if (selectedView === views.All) {
      middleContent = (
        <Div>{mapWithIndex(makeRelatedModules, relatedModules)}</Div>
      );
    } else {
      middleContent = makeRelatedModules(
        findBy("name", selectedView)(relatedModules)
      );
    }

    const rightContent = (
      <Feed
        eventId={eventId}
        moduleId={moduleId}
        recordId={record.id}
        whitelist={["messages", "activity"]}
        style={{
          width: "100%",
          height: "100%",
          background: "#fcfcfc",
          right: 0,
          padding: 5
        }}
      />
    );

    return {
      loading,
      topBarContent,
      leftContent,
      middleContent,
      // rightContent,
      recordColor: moduleDetails.color,
      recordDisplayId: record.friendly_id
        ? `${record.record_prefix}-${record.friendly_id}`
        : null,
      createdAt: record.created_at,
      lastUpdatedAt: record.updated_at,
      title: getRecordName(moduleDetails.type_id || moduleDetails.id, record),
      ...props
    };
  }
);

const Controller = R.compose(
  loadingToggle,
  fetchers,
  onDidMount,
  modalAdder,
  starredFieldParser,
  relatedModuleViewHandler,
  viewSelector,
  isEditingHandler,
  contentPicker
)(View);

Controller.propTypes = {
  moduleId: PropTypes.string.isRequired,
  recordId: PropTypes.string.isRequired,
  eventId: PropTypes.number.isRequired,
  record: PropTypes.shape({
    id: PropTypes.string,
    friendly_id: PropTypes.string
  }).isRequired,
  getModule: PropTypes.func.isRequired,
  getRecord: PropTypes.func.isRequired
};

export default Controller;
