import PropTypes from "prop-types";
import React from "react";
import { get } from "lodash";
import Formatter from "../../Table3/CellFormatters/Catering";
import CateringEditor from "../../Editors/CateringEditor";
import SelectBase from "../SelectBase";

const CateringSelect = ({
  onChange,
  value,
  meals,
  mealDays,
  selectedDays,
  rowMetaData,
  disabled,
  column,
  close
}) => (
  <SelectBase
    formatter={
      get(value, "value.meals.length") ? (
        <Formatter dependentValues={rowMetaData} value={value} />
      ) : null
    }
    editor={
      <CateringEditor
        style={{
          body: {
            maxHeight: 160
          }
        }}
        onChange={onChange}
        selectedDays={selectedDays}
        meals={meals}
        mealDays={mealDays}
        value={value}
        rowMetaData={rowMetaData}
        disabled={disabled}
        column={column}
        close={close}
      />
    }
  />
);

CateringSelect.propTypes = {
  value: PropTypes.object,
  rowMetaData: PropTypes.object.isRequired,
  selectedDays: PropTypes.array.isRequired,
  meals: PropTypes.array.isRequired,
  mealDays: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default CateringSelect;
