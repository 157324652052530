import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import numeral from "numeral";

import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Body from "components/Global/Modal/Layout/ScrollableBody";
import Info from "./Info";
import Footer from "./Footer";

@CSSModules(css)
class ManualPaymentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      amount: props.amount || null,
      reference: ""
    };
  }

  onSubmit = async e => {
    this.setState({
      saving: true
    });

    // We don't want to let default form submission happen here, which would refresh the page.
    e.preventDefault();

    await this.props.addTransaction({
      amount: numeral(this.state.amount).format("0.00"),
      reference: this.state.reference,
      email: this.state.email
    });

    this.props.onDone();
    this.props.hideModal();
  };

  isValid = () =>
    this.state.reference.length &&
    parseFloat(this.state.amount) &&
    parseFloat(this.state.amount) > 0;

  render() {
    const { hideModal } = this.props;
    const { saving, reference } = this.state;

    const infoHelpers = {
      amount: this.state.amount,
      reference,
      updateAmount: e =>
        this.setState({
          amount: e.target.value
        }),
      updateReference: e => this.setState({ reference: e.target.value })
    };

    return (
      <StyleWrapper
        bodyStyles={{ padding: 0 }}
        hideModal={hideModal}
        heading="Add Manual Payment"
        width={450}
      >
        <Body>
          <Info {...infoHelpers} />
        </Body>
        <div styleName="footer">
          <Footer
            hideModal={hideModal}
            isValid={this.isValid()}
            processing={saving}
            onSubmit={this.onSubmit}
          />
        </div>
      </StyleWrapper>
    );
  }
}

export default ManualPaymentModal;
