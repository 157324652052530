import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "../AddRelatedAccountModal/styles.scss";
import { Account } from "../AddRelatedAccountModal";

import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import {
  Submit,
  ButtonGroup,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";

@CSSModules(css)
class EditRelatedAccountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: props.account.role || ""
    };
  }

  @autobind
  handleRoleChange(e) {
    this.setState({ role: e.target.value });
  }

  @autobind
  handleSubmit() {
    this.props.hideModal();
    this.props.onSave({
      relationshipId: this.props.account.relationship_id,
      role: this.state.role
    });
  }

  @autobind
  handleRemove() {
    this.props.hideModal();
    this.props.onDelete({
      accountId: this.props.account.account_id,
      relationshipId: this.props.account.relationship_id
    });
  }

  render() {
    const { hideModal, account } = this.props;
    return (
      <StyleWrapper
        heading="Add related group"
        hideModal={hideModal}
        width={500}
      >
        <Account {...account} />
        <form onSubmit={this.handleSubmit}>
          <div styleName="roleWrapper">
            <div styleName="label">
              <span>Role with this group</span>
              <span styleName="caption">Optional*</span>
            </div>
            <input
              type="text"
              autoFocus
              value={this.state.role}
              styleName="input"
              onChange={this.handleRoleChange}
            />
          </div>
          <ButtonGroup>
            <Submit title="Add" />
            <ButtonOutline title="Cancel" onClick={hideModal} />
            <div styleName="floatRight">
              <div styleName="remove" onClick={this.handleRemove}>
                Remove
              </div>
            </div>
          </ButtonGroup>
        </form>
      </StyleWrapper>
    );
  }
}

EditRelatedAccountModal.propTypes = {
  account: PropTypes.object,
  hideModal: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default EditRelatedAccountModal;
