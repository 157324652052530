import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FormGrid from "./FormGrid";

import * as ReferenceValueSelectors from "redux/modules/formsV2/references/values/selectors";
import * as UserSelectors from "redux/modules/user/selectors";

import * as PreferencesActions from "redux/modules/formsV2/form/preferences/actions";
import * as SubmissionActions from "redux/modules/formsV2/submission/actions";
import * as ValueActions from "redux/modules/formsV2/submission/values/actions";
import * as ModalActions from "redux/modules/modal/actions";
import { updateContactValue } from "redux/modules/formsV2/form/fields/transactions/actions";
import { addReferenceValue } from "redux/modules/formsV2/references/values/actions";
import { addRecord } from "redux/modules/modules/records/actions";

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...PreferencesActions,
      ...SubmissionActions,
      ...ValueActions,
      ...ModalActions,
      addRecord,
      updateContactValue,
      addReferenceValue
    },
    dispatch
  );
}

function mapStateToProps(state) {
  return {
    user: UserSelectors.user(state),
    references: {
      values: ReferenceValueSelectors.values(state)
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  {
    withRef: true
  }
)(FormGrid);
