import { get } from "lodash";

export const isFetching = state =>
  state.integrations.eventbrite.orders.fetching;
export const orders = state => state.integrations.eventbrite.orders.orders;
export const syncing = (state, credentialOrderId) =>
  get(
    state,
    `integrations.eventbrite.orders.orders[${credentialOrderId}].syncing`
  );
export const isSynced = (state, credentialOrderId) =>
  Boolean(
    get(
      state,
      `integrations.eventbrite.orders.orders[${credentialOrderId}].last_synced_at`
    )
  );
export const lastSyncedAt = (state, credentialOrderId) =>
  get(
    state,
    `integrations.eventbrite.orders.orders[${credentialOrderId}].last_synced_at`
  );
export const credentialOrders = (state, credentialOrderId) =>
  get(
    state,
    `integrations.eventbrite.orders.orders[${credentialOrderId}].orders`,
    []
  );
