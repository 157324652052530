import { combineReducers } from "redux";
import { RECEIVE_SESSION, RECEIVE_USER, UPDATE_PHONE } from "./constants";
import events from "../events/reducer";

const initialState = {
  id: 0,
  email: null,
  slug: null,
  fname: null,
  lname: null,
  coverPhotoUrl: null,
  photoUrl: null,
  events: [],
  credentials: {},
  sessionCheckComplete: false
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_SESSION:
      return {
        ...state,
        credentials: {
          ...state.credentials,
          idToken: action.payload.idToken
        },
        sessionCheckComplete: true
      };
    case RECEIVE_USER:
      return {
        ...action.payload,
        sessionCheckComplete: true
      };
    case UPDATE_PHONE:
      return {
        ...state,
        phone: action.payload
      };
    default:
      return state;
  }
};

export default combineReducers({
  user,
  events
});
