import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Popover from "@lennd/material-ui/Popover";
import Checkbox from "material-ui/Checkbox";
import { sortBy } from "lodash";

@CSSModules(css)
class CustomizeCardPopover extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopover: false
    };
  }

  handleClick = e => {
    e.stopPropagation();
    this.setState({ showPopover: true });
  };

  onRequestClose = () => {
    this.setState({ showPopover: false });
  };

  handleCheck = (id, e, value) => {
    if (value === false) {
      this.props.updateFields([
        ...this.props.selectedFields.filter(f => f !== id)
      ]);
    } else {
      this.props.updateFields([...this.props.selectedFields, id]);
    }
  };

  hideAll = () => {
    this.props.updateFields([]);
  };

  render() {
    const { fields, selectedFields, activeField = {}, children } = this.props;

    const fieldsList = sortBy(fields, ["name"]).map(f => (
      <Checkbox
        key={f.id}
        checked={selectedFields.includes(f.id)}
        iconStyle={{ marginRight: 8 }}
        labelStyle={{
          fontWeight: 600,
          fontSize: 12,
          color: "#545454",
          textTransform: "uppercase"
        }}
        onCheck={this.handleCheck.bind(null, f.id)}
        label={f.name}
      />
    ));

    return (
      <div ref={ref => (this.anchor = ref)}>
        <span onClick={this.handleClick}>{children}</span>
        <Popover
          anchorEl={this.anchor}
          anchorOrigin={{ horizontal: "left", vertical: "top" }}
          onClose={this.onRequestClose}
          open={this.state.showPopover}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
        >
          <div styleName="container">
            <div styleName="heading">
              <div styleName="title">Customize View</div>
            </div>
            <div styleName="list">
              <div styleName="listHeading">
                <div>available fields</div>
                <div styleName="hideAll" onClick={this.hideAll}>
                  Hide all
                </div>
              </div>
              {fieldsList}
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}

CustomizeCardPopover.defaultProps = {
  selectedFields: []
};

CustomizeCardPopover.propTypes = {
  children: PropTypes.node,
  fields: PropTypes.array.isRequired,
  selectedFields: PropTypes.array.isRequired,
  updateFields: PropTypes.func.isRequired,
  onActiveFieldChange: PropTypes.func.isRequired,
  activeField: PropTypes.shape({
    name: PropTypes.string
  })
};

export default CustomizeCardPopover;
