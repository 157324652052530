import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Tooltip from "ui-kit/Tooltip";

const EmptyLabel = CSSModules(
  () => (
    <span styleName="emptyCredential">
      <div styleName="emptyColor" />
      <div
        styleName="emptyTitle"
        style={{ width: `${Math.floor(Math.random() * 50) + 60}px` }}
      />
    </span>
  ),
  css
);

const TooltipLabel = CSSModules(
  ({ text }) => (
    <div styleName="tooltipLabel">
      <div styleName="tooltipText">{text}</div>
    </div>
  ),
  css
);

const CredentialLabel = CSSModules(
  ({ value, backgroundColor, security }) => (
    <span styleName="credential">
      <div
        styleName="color"
        style={{
          backgroundColor: backgroundColor || "lightgrey",
          border: backgroundColor === "#FFFFFF" ? "1px solid #E9E9E9" : "none"
        }}
      />
      <Tooltip
        className="credential-progress"
        tooltip={<TooltipLabel text={value} />}
        placement="right"
      >
        <div styleName="title">{value}</div>
      </Tooltip>
      {security && <div styleName="badge">{security}</div>}
    </span>
  ),
  css
);

const CredentialLabelWrapper = ({ empty, ...props }) => {
  if (empty) {
    return <EmptyLabel />;
  }
  return <CredentialLabel {...props} />;
};

export default CredentialLabelWrapper;
