import React, { Component } from "react";
import * as R from "ramda";
import getLookupValue from "utils/value-types/get-value/lookup";
import getValue from "utils/value-types/get-value";
import toString from "utils/value-types/to-string";
import { Div } from "components/Base";
import * as SYSTEM_FIELD_IDS from "utils/system-field-ids";

import ViewRecordModal from "components/Global/Module/Modals/ViewRecord";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

import { showModal } from "redux/modules/modal/actions";

import { getSettings, getHandlers, getMeta } from "ui-kit/Datagrid/utils";

const { ID } = SYSTEM_FIELD_IDS;

const getLinkedRecordIds = props => {
  const { records } = getLookupValue(props.value);
  return records;
};

const getRecordName = (recordId, linkedField, linkedRecord) =>
  !linkedField || linkedField.id === ID
    ? toString(getValue(linkedRecord[ID], "text"), "text")
    : toString(
        getValue(linkedRecord.values[linkedField.id], linkedField.type),
        linkedField.type
      );

const Record = ({ name, onClick }) => (
  <Div
    bra={1}
    fw={3}
    bg={onClick ? { default: "neutral1", hover: "neutral0" } : "neutral1"}
    onClick={onClick}
    color="gray9"
    px={2}
    py={1}
    mr={1}
    truncate
    style={{
      flexShrink: 0
    }}
  >
    {name}
  </Div>
);

class LinkedRecordsCellFormatted extends Component {
  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props.value, nextProps.value);
  }

  showRecordModal = recordId => {
    const handlers = getHandlers(this.props);
    const linkedModuleId = getSettings(this.props, "moduleId");

    handlers.dispatch(
      showModal({
        content: (
          <ViewRecordModal moduleId={linkedModuleId} recordId={recordId} />
        ),
        wrapper: ModalWrapper
      })
    );
  };

  render() {
    const recordIds = getLinkedRecordIds(this.props);
    const suppressClickingRecord = getSettings(
      this.props,
      "suppressClickingRecord"
    );

    if (!recordIds) return null;
    // @TODO check where this data is comming from
    const linkedModuleId = getSettings(this.props, "moduleId");
    const linkedFieldId = getSettings(this.props, "fieldId");
    const fields = getMeta(
      // @NOTE references does not exist in FormsV2/Overview/SubmissionSummary getMetaData
      this.props,
      ["references", linkedModuleId, "fields", "fields"],
      []
    );
    const linkedRecordReferences = getMeta(
      this.props,
      ["references", linkedModuleId, "records"],
      {}
    );
    const linkedField = R.find(f => f.id === linkedFieldId, fields);

    const records = recordIds.map(id => {
      return {
        id,
        name: getRecordName(
          id,
          linkedField,
          R.propOr({}, id, linkedRecordReferences)
        ),
        onClick: !suppressClickingRecord
          ? () => this.showRecordModal(id)
          : undefined
      };
    });

    return (
      <Div
        display="row"
        style={{
          overflow: "hidden"
        }}
      >
        {records.map(record => (
          <Record onClick={record.onClick} key={record.id} name={record.name} />
        ))}
      </Div>
    );
  }
}

export default LinkedRecordsCellFormatted;
