import React, { Component } from "react";
import PropTypes from "prop-types";
import InternalHandler from "./Internal";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";

class ExternalHandler extends Component {
  getItemGroups = () => {
    this.props.getItemGroupsByEventAndType(
      this.props.eventDetails.id,
      this.props.typeId
    );

    if (this.props.typeId === CREDENTIAL_TYPE_ID) {
      this.props.getZoneGroups(this.props.eventDetails.id);
    }
  };

  fetchItem = () => this.props.getItem(this.props.itemId);

  saveItem = item => {
    this.props.onDone(item);
    this.props.hideModal();
  };

  render() {
    return <InternalHandler {...this} {...this.props} />;
  }
}

ExternalHandler.propTypes = {
  itemId: PropTypes.string.isRequired,
  eventDetails: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  getItemGroupsByEventAndType: PropTypes.func.isRequired,
  getItem: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default ExternalHandler;
