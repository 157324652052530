import React from "react";

import { Div } from "components/Base";

import Header from "./Header";
import Footer from "./Footer";
import VariantList from "./VariantList";
import QuestionList from "./QuestionList";

import { connect } from "react-redux";
import { getters, actions } from "../index";
import {
  getVariantIdForQuestionValues,
  getShowFooterBorder
} from "../selectors";

const decorate = connect(
  state => ({
    hasSingleVariant: getters.hasSingleVariant(state),
    variantIdForQuestionValues: getVariantIdForQuestionValues(state),
    showFooterBorder: getShowFooterBorder(state)
  }),
  {}
);

const Body = ({
  handlers,
  hasSingleVariant,
  variantIdForQuestionValues,
  showFooterBorder
}) => {
  return (
    <Div display="column" width={1}>
      <Header handlers={handlers} />

      {showFooterBorder ? (
        <Div pt={5} px={10} width={1}>
          {hasSingleVariant ? null : <VariantList />}
          {hasSingleVariant ? (
            <QuestionList variantId={variantIdForQuestionValues} />
          ) : null}
        </Div>
      ) : null}

      <Div pb={5} px={10} width={1}>
        <Footer showBorder={showFooterBorder} handlers={handlers} />
      </Div>
    </Div>
  );
};

export default decorate(Body);
