/* eslint-disable no-underscore-dangle */
import { createSelector } from "reselect";
import * as R from "ramda";

import {
  credentialsRequests,
  cateringRequests,
  assetRequests
} from "redux/modules/portal/reports/selectors";
import { settings as portalSettings } from "redux/modules/portal/settings/selectors";
import { getters } from "./model";

import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";
import { REPORT_TYPES } from "./constants";

export const canViewInventory = createSelector(
  selectFeatureFlag(flags.CAN_VIEW_INVENTORY.NAME),
  R.identity
);

export const isReportsLoading = createSelector(
  assetRequests,
  credentialsRequests,
  cateringRequests,
  (assetReqs, credentialReqs, cateringReqs) =>
    !R.has("counts", assetReqs) &&
    !R.has("counts", credentialReqs) &&
    !R.has("counts", cateringReqs)
);

export const hasAssetsReport = createSelector(
  canViewInventory,
  assetRequests,
  (canViewInv, assetReqs) =>
    canViewInv
      ? R.compose(
          R.gt(R.__, 0),
          R.length,
          R.prop("counts")
        )(assetReqs)
      : R.gt(assetReqs.length, 0)
);

export const hasCredentialsReport = createSelector(
  credentialsRequests,
  R.compose(
    R.gt(R.__, 0),
    R.length,
    R.prop("counts")
  )
);

export const hasCateringReport = createSelector(
  cateringRequests,
  R.compose(
    R.gt(R.__, 0),
    R.length,
    R.keys,
    R.prop("counts")
  )
);

export const isReportsEmpty = createSelector(
  hasAssetsReport,
  hasCredentialsReport,
  hasCateringReport,
  (hasAssets, hasCredentials, hasCatering) =>
    !hasAssets && !hasCredentials && !hasCatering
);

export const getLinks = createSelector(
  hasAssetsReport,
  hasCredentialsReport,
  hasCateringReport,
  getters.activeReport,
  (hasAssets, hasCreds, hasCatering, activeReport) => {
    return [
      {
        id: REPORT_TYPES.ASSETS,
        name: "Assets",
        active: activeReport === REPORT_TYPES.ASSETS,
        visible: hasAssets
      },
      {
        id: REPORT_TYPES.CREDENTIALS,
        name: "Passes",
        active: activeReport === REPORT_TYPES.CREDENTIALS,
        visible: hasCreds
      },
      {
        id: REPORT_TYPES.MEALS,
        name: "Catering / Meals",
        active: activeReport === REPORT_TYPES.MEALS,
        visible: hasCatering
      }
    ].filter(l => l.visible);
  }
);

export const getReportSettings = createSelector(
  portalSettings,
  R.compose(
    R.find(R.propEq("id", "reports")),
    R.propOr([], "sections")
  )
);
