import { connect } from "react-redux";
import Controller from "./Controller";
import STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal } from "redux/modules/modal/actions";
import { getFields } from "redux/modules/modules/fields/actions";
import { getOrder, updateOrder } from "redux/modules/orders/orders/actions";

import { eventDetails } from "redux/modules/event/selectors";
import { user, getCredentials } from "redux/modules/user/selectors";
import { fields } from "redux/modules/modules/fields/selectors";
import { order } from "redux/modules/orders/orders/selectors";

const orderReadOnlyFields = resolveReadOnlyFields({
  moduleId: STANDARD_MODULES.orders.id
});

function mapStateToProps(state, props) {
  return {
    user: user(state),
    userCredentials: getCredentials(state),
    order: order(state, props.orderId) || {},
    eventDetails: eventDetails(state),
    orderCustomFields: fields(state, STANDARD_MODULES.orders.id).filter(
      f => !orderReadOnlyFields.includes(f.id)
    )
  };
}

export default connect(
  mapStateToProps,
  {
    getOrder,
    updateOrder,
    showSnackbar,
    showModal,
    getFields
  }
)(Controller);
