import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Message from "./Message";
import Sidebar from "./Sidebar";

@CSSModules(css)
class Setup extends Component {
  render() {
    const {
      mode,
      moduleId,
      records,
      onOptionSelect,
      options,
      selectedOptions,
      message,
      newTemplate,
      templateTitle,
      subject,
      replyTo,
      updateReplyTo,
      mergeTags,
      toggleGroupByRecipient,
      groupByRecipient,
      emailType,
      invoiceNotes,
      invoiceDueDate,
      attachments
    } = this.props;

    return (
      <div className={css.container}>
        <Sidebar
          mode={mode}
          moduleId={moduleId}
          records={records}
          onOptionSelect={onOptionSelect}
          options={options}
          selectedOptions={selectedOptions}
          replyTo={replyTo}
          updateReplyTo={updateReplyTo}
          toggleGroupByRecipient={toggleGroupByRecipient}
          groupByRecipient={groupByRecipient}
          updateInvoiceDueDate={this.props.updateInvoiceDueDate}
          updateInvoiceNotes={this.props.updateInvoiceNotes}
          invoiceNotes={invoiceNotes}
          invoiceDueDate={invoiceDueDate}
          emailType={emailType}
        />
        <Message
          mode={mode}
          updateSubject={this.props.updateSubject}
          subject={subject}
          updateMessage={this.props.updateMessage}
          updateReplyTo={this.props.updateReplyTo}
          updateTemplateTitle={this.props.updateTemplateTitle}
          updateInvoiceDueDate={this.props.updateInvoiceDueDate}
          updateInvoiceNotes={this.props.updateInvoiceNotes}
          updateAttachments={this.props.updateAttachments}
          toggleTemplate={this.props.toggleTemplate}
          newTemplate={newTemplate}
          attachments={attachments}
          message={message}
          templateTitle={templateTitle}
          mergeTags={mergeTags}
        />
      </div>
    );
  }
}

Setup.propTypes = {
  moduleId: PropTypes.string.isRequired,
  records: PropTypes.array.isRequired,
  options: PropTypes.shape({
    optionType: PropTypes.string,
    optionName: PropTypes.string,
    fieldId: PropTypes.string
  }).isRequired,
  selectedOptions: PropTypes.shape({
    optionType: PropTypes.string,
    optionName: PropTypes.string,
    fieldId: PropTypes.string
  }).isRequired,
  onOptionSelect: PropTypes.func.isRequired,
  updateSubject: PropTypes.func.isRequired,
  updateMessage: PropTypes.func.isRequired,
  updateReplyTo: PropTypes.func.isRequired,
  updateTemplateTitle: PropTypes.func.isRequired,
  toggleTemplate: PropTypes.func.isRequired,
  replyTo: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  newTemplate: PropTypes.bool.isRequired,
  templateTitle: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired
};

export default Setup;
