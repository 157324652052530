export const NAMESPACE = "SalesPortal";

export const SHIPPING = {
  SAME: "same",
  DIFFERENT: "different"
};

export const ORG = {
  ID: "3f1524cf-8d07-49b7-98d2-f749e5d6a982"
};
