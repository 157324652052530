import { get } from "lodash";

export const isFetching = state => state.modules.layout.fetching;
export const layout = (state, moduleId) =>
  get(state, `modules.layout.layout[${moduleId}]`, {});
export const fields = (state, moduleId) =>
  get(state, `modules.layout.layout[${moduleId}].fields`, []);
export const starredFields = (state, moduleId) =>
  get(state, `modules.layout.layout[${moduleId}].starred_fields`, []);
export const fieldGroups = (state, moduleId) =>
  get(state, `modules.layout.layout[${moduleId}].field_groups`, []);
export const relatedModules = (state, moduleId) =>
  get(state, `modules.layout.layout[${moduleId}].related_modules`, []);
