import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import css from "Schedules/Schedule/View/styles.scss";

import { Div, Text2, AddIcon, CloseIcon, FilterIcon } from "components/Base";
import { noop } from "utils/General";
import FilterPicker from "components/Global/Module/Sidebar/Popovers/Filter";

import { Panel } from "ui-kit/FilterControls/View";

import {
  getFieldFiltersSelectedCount,
  getFieldFilters,
  getSelectedFieldFilters
} from "Schedules/Schedule/selectors";

import { actions } from "Schedules/Schedule";

const decorate = R.compose(
  connect(
    (state, props) => ({
      selectedCount: getFieldFiltersSelectedCount(state, props),
      fields: getFieldFilters(state),
      filters: getSelectedFieldFilters(state)
    }),
    {
      setFilters: actions.setSelectedFieldFilters,
      removeFilter: actions.removeSelectedFieldFilter
    }
  )
);

const FieldSelect = ({
  fields,
  setFilters,
  filters,
  removeFilter,
  ...styleProps
}) => {
  return (
    <Panel
      label={
        <Div display="row.flex-start.center" mb={1}>
          <FilterIcon mr={2} color="purple4" />
          <Text2 color="white" bold>
            Filters
          </Text2>
        </Div>
      }
      collapsedDefault
      filtersOn={R.length(R.propOr([], "filters", filters))}
      bodyProps={{ ml: 3, mr: 3 }}
      className={css.button2}
      {...styleProps}
    >
      <Div display="column.flex-start.flex-start">
        <Div display="column" width={1}>
          {filters && filters.filters
            ? filters.filters.map((filter, idx) => {
                return (
                  <Div
                    display="row.flex-start.flex-start"
                    width={1}
                    fs={1}
                    mb={1}
                    bra={1}
                    p={2}
                    style={{
                      backgroundColor: "#9A68D2"
                    }}
                  >
                    <Div flex={1} fs={1} color="white">
                      {filter.title} <b>{filter.label}</b>
                    </Div>
                    <CloseIcon
                      size={15}
                      ml={1}
                      onClick={() => removeFilter(idx)}
                      style={{
                        color: "#B997E0",
                        flexShrink: 0
                      }}
                    />
                  </Div>
                );
              })
            : null}
        </Div>
        <FilterPicker filters={filters} onChange={setFilters} fields={fields}>
          <Div
            display="row"
            onClick={noop}
            mt={filters && filters.filters ? 2 : 0}
          >
            <Div fs={2} fw={3} color="white">
              Add Filter
            </Div>
            <AddIcon color="purple4" ml={1} />
          </Div>
        </FilterPicker>
      </Div>
    </Panel>
  );
};

export default CSSModules(decorate(FieldSelect), css);
