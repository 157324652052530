import React from "react";
import { Div, LoadingIcon, Text6 } from "components/Base";

const Loading = () => (
  <Div width={1} height={1} display="row.center.center">
    <Div display="column.flex-start.center">
      <Text6 bold>Loading...</Text6>
      <LoadingIcon color="neutral4" size={40} mt={1} />
    </Div>
  </Div>
);

export default Loading;
