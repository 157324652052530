import api from "redux/modules/approvalWorkflows/reviews/api";
import { CREATE } from "./constants";
import { registerError } from "redux/modules/errors/actions";

export function createReview(data) {
  return (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE,
        payload: {
          rowId: data.review.rowId,
          moduleId: data.review.moduleId,
          recordId: data.review.recordId,
          subformId: data.review.subformId,
          submissionId: data.review.submissionId,
          userId: data.review.userId,
          status: data.review.response
        }
      });
      return api.post(getState().user.user.credentials, data);
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding review"
          }
        ])
      );
    }
  };
}

export function removeReview(data) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: CREATE,
        payload: {
          rowId: data.review.rowId,
          moduleId: data.review.moduleId,
          recordId: data.review.recordId,
          subformId: data.review.subformId,
          submissionId: data.review.submissionId,
          userId: data.review.userId,
          status: null
        }
      });
      return await api.delete(getState().user.user.credentials, data);
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred removing review"
          }
        ])
      );
    }
  };
}
