import { createSelector } from "reselect";

import * as R from "ramda";

import { getters } from "Portal/Settings/AddVideoLinkModal/model";

export const isAddBtnDisabled = createSelector(
  getters.title,
  getters.url,
  (title, url) => R.length(title) < 2 || R.length(url) < 6
);
