import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import * as R from "ramda";
import { actions, getters } from "./model";
import {
  actions as SearchBarActions,
  getters as SearchBarGetters
} from "ui-kit/SearchBar";

import { registerError } from "redux/modules/errors/actions";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { getCredentials } from "redux/modules/user/selectors";

import Api from "./api";
import { SEARCH_BAR_INSTANCE } from "./constants";

const searchCustomer = function*(filter) {
  try {
    yield put(actions.setLoading(true));
    const credentials = yield select(getCredentials);
    const eventDetails = yield select(getEventDetails);
    const { payload } = yield call(Api.getCustomers, {
      credentials,
      eventId: eventDetails.id,
      filter
    });
    yield put(
      actions.setCustomerData({
        accounts: R.propOr([], "accounts", payload),
        contacts: R.propOr([], "contacts", payload)
      })
    );
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred getting customers"
          }
        ])
      ),
      put(actions.setLoading(false))
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const searchSecondaryAccountsOrContacts = function*() {
  const filter = yield select(SearchBarGetters.searchTerm);
  const accountId = yield select(getters.accountId);
  const contactId = yield select(getters.contactId);
  const query = {};
  const forAccount = Boolean(accountId && accountId.length);
  const forContact = Boolean(contactId && contactId.length);

  if (forAccount) {
    query.accountId = accountId;
  }
  if (forContact) {
    query.contactId = contactId;
  }

  try {
    const qs =
      R.length(filter) > 0
        ? {
            ...query,
            q: filter
          }
        : query;
    yield put(actions.setLoading(true));

    const credentials = yield select(getCredentials);
    const eventDetails = yield select(getEventDetails);
    const { payload } = yield call(Api.getSecondaryAccountsOrContacts, {
      credentials,
      eventId: eventDetails.id,
      qs
    });

    if (forAccount) {
      yield put(actions.setSelectedContacts(R.propOr([], "contacts", payload)));
      yield put(actions.setAccounts(R.propOr([], "accounts", payload)));
    }
    if (forContact) {
      yield put(actions.setSelectedAccounts(R.propOr([], "accounts", payload)));
      yield put(actions.setContacts(R.propOr([], "contacts", payload)));
    }
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred getting secondary records"
          }
        ])
      ),
      put(actions.setLoading(false))
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const init = function*({ payload: { accountId, contactId } }) {
  if (contactId) {
    yield put(actions.setContactId(contactId));
    yield call(searchSecondaryAccountsOrContacts);
  } else if (accountId) {
    yield put(actions.setAccountId(accountId));
    yield call(searchSecondaryAccountsOrContacts);
  }
};

const selectAccount = function*({ payload: { accountId } }) {
  yield put(actions.setAccountId(accountId));
  yield put(SearchBarActions.clearSearch());
  yield call(searchSecondaryAccountsOrContacts);
};

const selectContact = function*({ payload: { contactId } }) {
  yield put(actions.setContactId(contactId));
  yield put(SearchBarActions.clearSearch());
  yield call(searchSecondaryAccountsOrContacts);
};

const updateAccount = function*({ payload: { accountId } }) {
  const filter = yield select(SearchBarGetters.searchTerm, {
    instanceId: SEARCH_BAR_INSTANCE
  });

  if (R.isEmpty(filter)) {
    yield call(selectAccount, { payload: { accountId } });
  } else {
    yield all([
      call(searchCustomer, filter),
      call(selectAccount, { payload: { accountId } })
    ]);
  }
};

const updateContact = function*({ payload: { contactId } }) {
  const filter = yield select(SearchBarGetters.searchTerm, {
    instanceId: SEARCH_BAR_INSTANCE
  });

  if (R.isEmpty(filter)) {
    yield call(selectContact, { payload: { contactId } });
  } else {
    yield all([
      call(searchCustomer, filter),
      call(selectContact, { payload: { contactId } })
    ]);
  }
};

const search = function*() {
  const filter = yield select(SearchBarGetters.searchTerm, {
    instanceId: SEARCH_BAR_INSTANCE
  });

  if (!R.isEmpty(filter)) {
    yield call(searchCustomer, filter);
  }
};

const watchSearch = function*() {
  yield takeEvery(
    action =>
      action.type === SearchBarActions.setSearchTerm.type &&
      R.path(["meta", "instanceId"], action) === SEARCH_BAR_INSTANCE,
    search
  );
};

const watchSearchSecondaryAccountsOrContacts = function*() {
  yield takeEvery(
    action =>
      action.type === SearchBarActions.setSearchTerm.type &&
      R.path(["meta", "instanceId"], action) !== SEARCH_BAR_INSTANCE,
    searchSecondaryAccountsOrContacts
  );
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchSelectAccount = function*() {
  yield takeEvery(actions.selectAccount.type, selectAccount);
};

const watchSelectContact = function*() {
  yield takeEvery(actions.selectContact.type, selectContact);
};

const watchUpdateAccount = function*() {
  yield takeEvery(actions.updateAccount.type, updateAccount);
};

const watchUpdateContact = function*() {
  yield takeEvery(actions.updateContact.type, updateContact);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchSearch),
    fork(watchSelectAccount),
    fork(watchSelectContact),
    fork(watchSearchSecondaryAccountsOrContacts),
    fork(watchUpdateAccount),
    fork(watchUpdateContact)
  ]);
};

export default rootSaga;
