import React from "react";

import {
  delay,
  put,
  call,
  takeEvery,
  take,
  all,
  fork,
  select
} from "redux-saga/effects";
import { actions, getters } from "Modules/GenerateDocumentsModal";
import { getCredentials } from "redux/modules/user/selectors";
import { registerError } from "redux/modules/errors/actions";
import api from "Modules/GenerateDocumentsModal/api";
import { eventId } from "redux/modules/event/selectors";
import { showModal } from "redux/modules/modal/actions";
import DocumentPreview from "components/Global/Modals/DocumentGeneration/DocumentPreview";
import FileViewerWrapper from "components/Global/Modals/FileViewer/Wrapper";
import { actions as SnackbarActions } from "ui-kit/Snackbar/model";
import { SEVERITY } from "ui-kit/Alert/constants";
import { actions as AlertActions } from "ui-kit/Alert/model";

const init = function*({ payload: { selected, showSaveAndSend, moduleId } }) {
  const credentials = yield select(getCredentials);
  const eventIdToUse = yield select(eventId);

  try {
    const { payload } = yield call(api.getTemplates, credentials, {
      eventId: eventIdToUse,
      moduleId
    });

    yield put(
      actions.setInitialData({
        selected,
        showSaveAndSend,
        moduleId,
        templates: payload
      })
    );
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred getting event options"
          }
        ])
      ),
      put(actions.setLoading(false, {}, true))
    ]);
  }
};

// eslint-disable-next-line consistent-return
const createDocuments = function*({ payload: { onDone } }) {
  const credentials = yield select(getCredentials);
  const selectedTemplate = yield select(getters.selectedTemplate);
  const moduleId = yield select(getters.moduleId);
  const selected = yield select(getters.selected);
  const eventIdToUse = yield select(eventId);
  const showSaveAndSend = yield select(getters.showSaveAndSend);

  if (!selectedTemplate) return null;

  yield put(actions.setCreating(true));

  try {
    const initialResult = yield call(api.generateDocuments, credentials, {
      eventId: eventIdToUse,
      moduleId,
      templateId: selectedTemplate.id,
      recordIds: selected
    });

    yield delay(500);

    let progressResult = yield call(api.getStatus, credentials, {
      jobId: initialResult.payload.jobId
    });

    while (["pending", "processing"].includes(progressResult.payload.status)) {
      yield delay(3000);
      progressResult = yield call(api.getStatus, credentials, {
        jobId: initialResult.payload.jobId
      });
    }

    if (progressResult.payload.status === "error") {
      yield put(
        actions.setGenerateDetailsErrors(
          progressResult.payload.payload?.error_messages
        )
      );
      yield all([
        yield put(
          SnackbarActions.show({
            id: "error-generating-document",
            severity: SEVERITY.ERROR,
            autoHideDuration: false,
            message: "An error occurred generating documents",
            buttonAction: {
              id: "view-details",
              name: "View Details"
            }
          })
        ),
        put(actions.setCreating(false))
      ]);
      return false;
    }

    yield put(actions.setCreating(false));

    yield put(
      showModal({
        content: (
          <DocumentPreview
            showSaveAndSend={showSaveAndSend}
            onDone={onDone}
            documents={progressResult.payload.payload.results}
          />
        ),
        wrapper: FileViewerWrapper
      })
    );
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred generating documents"
          }
        ])
      ),
      put(actions.setCreating(false))
    ]);
  }
};

const watchCreateModalDetails = function*() {
  for (;;) {
    const { payload } = yield take(AlertActions.executeAction.type);
    if (payload.actionId === "view-details") {
      yield put(actions.setShowDetailsErrorsModal(true));
    }
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchCreateDocuments = function*() {
  yield takeEvery(actions.createDocuments.type, createDocuments);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchCreateDocuments),
    fork(watchCreateModalDetails)
  ]);
};

export default rootSaga;
