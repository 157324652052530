import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const PillButton = ({ title, linkTo, icon, ...props }) => {
  const iconDefault = (
    <div styleName="previewIconGroup">
      <i className="material-icons">keyboard_arrow_up</i>
      <i className="material-icons">keyboard_arrow_down</i>
    </div>
  );

  if (linkTo) {
    return (
      <Link to={linkTo} className={css.preview} {...props}>
        {icon || iconDefault}
        {title}
      </Link>
    );
  }
  return (
    <div styleName="preview" {...props}>
      {icon || iconDefault}
      {title}
    </div>
  );
};

PillButton.propTypes = {
  linkTo: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default CSSModules(PillButton, css);
