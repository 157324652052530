import { createModule } from "redux-mvc";

import model from "./model";
import sagas from "./sagas";
import dataGrid from "ui-kit/Datagrid";

const peopleBlock = createModule(model);

peopleBlock.setRootSaga(sagas);
peopleBlock.plugModule(dataGrid);

export default peopleBlock;
