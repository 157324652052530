import { ERROR } from "../constants";
import api from "./api";

export function addRelationship(data) {
  return async (dispatch, getState) => {
    try {
      return await api.post(getState().user.user.credentials, data);
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function updateRelationship(data) {
  return async (dispatch, getState) => {
    try {
      return await api.put(getState().user.user.credentials, data);
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function deleteRelationship(data) {
  return async (dispatch, getState) => {
    try {
      return await api.delete(getState().user.user.credentials, data);
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}
