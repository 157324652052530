import * as R from "ramda";
import { createSelector } from "reselect";

import { FIELDS } from "./constants";

export const getColumns = createSelector(
  R.always(FIELDS),
  R.identity
);

export const getRows = createSelector(
  (state, props) => R.prop("rows", props),
  R.map(({ id, ...alert }) => ({
    id,
    row: alert,
    ...R.compose(
      R.mergeAll,
      R.map(key => ({ [key]: { value: R.prop(key, alert) } })),
      R.keys
    )(alert)
  }))
);
