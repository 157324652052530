import PropTypes from "prop-types";
import React, { Component } from "react";
import { get } from "lodash";
import Checkbox from "material-ui/Checkbox";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import QuantityInput from "./QuantityInput";
import QuantityDropdown from "./QuantityDropdown";
import RadioButtonUnchecked from "material-ui/svg-icons/toggle/radio-button-unchecked";
import RadioButtonChecked from "material-ui/svg-icons/toggle/radio-button-checked";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const styles = {
  label: {
    flex: 1,
    color: "#424242",
    fontSize: "16px",
    fontWeight: 600,
    display: "block",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
    maxWidth: "100%",
    marginTop: "3px"
  },
  option: {
    padding: "5px 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
};

@CSSModules(css)
class OptionEditorList extends Component {
  createIconStyle(background) {
    const style = {
      marginTop: "1.5px",
      marginRight: "8px",
      fill: background
    };
    if (background === "#FFFFFF") {
      return {
        ...style,
        stroke: "#ccc",
        strokeWidth: "1px"
      };
    }
    return style;
  }

  renderQuantity(options) {
    const radios = options.map(option => {
      const value = this.props.selectedOptions.find(
        selected => selected.id === option.id
      );

      return (
        <li key={option.id} style={styles.option}>
          <div className={css.checkboxWithQuantity} title={option.value}>
            <Checkbox
              style={{
                flex: 1,
                display: "flex"
              }}
              checked={value}
              value={value ? 0 : 1}
              onCheck={e =>
                !this.props.disabled
                  ? this.props.updateQuantity(option, e.target.value)
                  : undefined
              }
              label={option.value}
              labelStyle={styles.label}
              iconStyle={this.createIconStyle(option.backgroundColor)}
              disabled={this.props.disabled}
            />
          </div>
          {this.props.shouldLimitToAvailableQuantities ? (
            <QuantityDropdown
              onChange={this.props.updateQuantity}
              option={option}
              quantity={value ? value.quantity : 0}
              quantityOptions={this.props.quantityOptions}
              disabled={this.props.disabled}
            />
          ) : (
            <QuantityInput
              onChange={this.props.updateQuantity}
              option={option}
              quantity={value ? value.quantity : 0}
              disabled={this.props.disabled}
            />
          )}
        </li>
      );
    });

    return <ul styleName="optionList">{radios}</ul>;
  }

  renderMultiple(options) {
    const radios = options.map(option => {
      const checked =
        this.props.selectedOptions.find(
          selected => selected.id === option.id
        ) || 0;

      return (
        <Checkbox
          style={{
            ...styles.option
          }}
          key={option.label}
          checked={checked}
          value={checked ? 0 : 1}
          onCheck={e =>
            !this.props.disabled
              ? this.props.updateQuantity(option, e.target.value)
              : undefined
          }
          label={option.value}
          labelStyle={styles.label}
          iconStyle={this.createIconStyle(option.backgroundColor)}
          disabled={this.props.disabled}
        />
      );
    });

    return <div styleName="optionList">{radios}</div>;
  }

  renderSingle(options) {
    const selectedId = get(this.props, "selectedOptions[0].id", null);
    const selected = options.find(c => c.id === selectedId);
    const radios = options.map(option => (
      <RadioButton
        key={option.id}
        style={{
          ...styles.option
        }}
        value={option}
        label={option.value}
        labelStyle={styles.label}
        iconStyle={this.createIconStyle(option.backgroundColor)}
        disabled={this.props.disabled}
      />
    ));

    return (
      <RadioButtonGroup
        valueSelected={selected}
        onChange={(event, value) =>
          !this.props.disabled
            ? this.props.updateSingleOption(value)
            : undefined
        }
        className={css.optionList}
      >
        <RadioButton
          style={styles.option}
          label="None"
          labelStyle={styles.label}
          iconStyle={this.createIconStyle()}
          disabled={this.props.disabled}
        />
        {radios}
      </RadioButtonGroup>
    );
  }

  renderSingleQuantity(options) {
    const radios = options.map(option => {
      const value = this.props.selectedOptions.find(
        selected => selected.id === option.id
      );

      return (
        <li key={option.id} style={styles.option}>
          <div className={css.checkboxWithQuantity} title={option.value}>
            <Checkbox
              style={{
                flex: 1,
                display: "flex"
              }}
              checkedIcon={<RadioButtonChecked />}
              uncheckedIcon={<RadioButtonUnchecked />}
              checked={value}
              value={value ? 0 : 1}
              onCheck={e =>
                !this.props.disabled
                  ? this.props.updateSingleQuantityOption(
                      option,
                      e.target.value
                    )
                  : undefined
              }
              label={option.value}
              labelStyle={styles.label}
              iconStyle={this.createIconStyle(option.backgroundColor)}
              disabled={this.props.disabled}
            />
          </div>
          {this.props.shouldLimitToAvailableQuantities ? (
            <QuantityDropdown
              onChange={this.props.updateQuantity}
              option={option}
              quantity={value ? value.quantity : 0}
              quantityOptions={this.props.quantityOptions}
              disabled={this.props.disabled}
            />
          ) : (
            <QuantityInput
              onChange={this.props.updateQuantity}
              option={option}
              quantity={value ? value.quantity : 0}
              disabled={this.props.disabled}
            />
          )}
        </li>
      );
    });

    return <ul styleName="optionList">{radios}</ul>;
  }

  renderOptions(type, options) {
    switch (type) {
      case "quantity":
        return this.renderQuantity(options);
      case "single":
        return this.renderSingle(options);
      case "single-quantity":
        return this.renderSingleQuantity(options);
      case "multiple":
        return this.renderMultiple(options);
      default:
        return "";
    }
  }

  render() {
    const { options, selectType, emptyMessage, quantityHeader } = this.props;
    const showQuantity = ["quantity", "single-quantity"].includes(selectType);
    return (
      <div
        styleName="optionSelectBox"
        style={{
          maxWidth: "500px"
        }}
      >
        {showQuantity ? (
          <div styleName="heading">
            <span>Types</span>
            <span>{quantityHeader}</span>
          </div>
        ) : (
          ""
        )}
        {!options.length ? (
          <div styleName="empty">{emptyMessage}</div>
        ) : (
          this.renderOptions(selectType, options)
        )}
      </div>
    );
  }
}

OptionEditorList.defaultProps = {
  quantityHeader: "QTY",
  customWidth: 250
};

OptionEditorList.propTypes = {
  quantityHeader: PropTypes.string.isRequired,
  emptyMessage: PropTypes.string.isRequired,
  updateQuantity: PropTypes.func.isRequired,
  updateSingleOption: PropTypes.func.isRequired,
  updateSingleQuantityOption: PropTypes.func.isRequired,
  selectedOptions: PropTypes.object.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      backgroundColor: PropTypes.string
    })
  ).isRequired,
  disabled: PropTypes.bool.isRequired,
  selectType: PropTypes.string.isRequired,
  customWidth: PropTypes.number,
  shouldLimitToAvailableQuantities: PropTypes.bool,
  quantityOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number
    })
  )
};

export default OptionEditorList;
