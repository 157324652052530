import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { actions, getters } from "../../model";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { getLinks } from "../../selectors";
import { Link as RouterLink } from "react-router";
import { withRouter } from "react-router";

import CSSModules from "react-css-modules";
import css from "../styles.scss";

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      collapsed: getters.collapsed(state),
      orgId: getOrgId(state),
      links: getLinks(state, props)
    }),
    {
      toggleOpenLink: actions.toggleOpenLink,
      onToggleCollapsed: actions.toggleCollapse,
      goBack: actions.goBack
    }
  ),
  CSSModules(css)
);

const Link = ({ title, icon, selected = false, to, isSublink, type }) =>
  type === "separator" ? (
    <Separator />
  ) : (
    <RouterLink
      className={
        isSublink
          ? selected
            ? css.sidebarSublinkSelected
            : css.sidebarSublink
          : selected
          ? css.sidebarLinkSelected
          : css.sidebarLink
      }
      to={to}
    >
      {icon ? <span className="material-icons-outlined">{icon}</span> : null}
      {title}
    </RouterLink>
  );

const LinkDropdown = ({
  title,
  icon,
  selected = false,
  open,
  options = [],
  onToggle
}) => (
  <>
    <div
      className={
        selected ? css.sidebarLinkDropdownSelected : css.sidebarLinkDropdown
      }
      onClick={onToggle}
    >
      <div className={css.left}>
        <span className="material-icons-outlined">{icon}</span>
        {title}
      </div>
      <span className={["material-icons-outlined", css.arrow].join(" ")}>
        {open ? "keyboard_arrow_down" : "keyboard_arrow_right"}
      </span>
    </div>
    {open ? (
      <div className={css.subLinksWrapper}>
        {options.map(link => {
          return (
            <Link
              icon={link.icon}
              title={link.name}
              selected={link.selected}
              to={link.to}
              type={link.type}
              isSublink
            />
          );
        })}
      </div>
    ) : null}
  </>
);

const Separator = () => <div className={css.separator} />;

const Sidebar = ({ links, orgId, toggleOpenLink }) => {
  return (
    <div className={css.sidebar}>
      <div className={css.topSection}>
        <div className={css.linksWrapper}>
          {links.map(link => {
            if (link.type === "separator") {
              return <Separator />;
            }

            if (link.options && link.options.length) {
              return (
                <LinkDropdown
                  icon={link.icon}
                  title={link.name}
                  selected={link.selected}
                  open={link.open}
                  options={link.options}
                  onToggle={() => toggleOpenLink(link.id)}
                />
              );
            }

            return (
              <Link
                icon={link.icon}
                title={link.name}
                selected={link.selected}
                to={link.to}
              />
            );
          })}
        </div>
      </div>

      <RouterLink className={css.inviteWrapper} to={`/org-light/${orgId}/team`}>
        <span className="material-icons-outlined">group_add</span>
        Invite your team
      </RouterLink>
    </div>
  );
};

export default decorate(Sidebar);
