import React, { Component } from "react";
import {
  ButtonGroup,
  ButtonOutline,
  Submit
} from "components/Global/Modal/Layout/Buttons";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Wysiwyg from "components/Global/Wysiwyg";
import { Div, BigOutlineInput } from "components/Base";

class PortalMessageModal extends Component {
  state = {
    title: this.props.title || "",
    message: this.props.message || ""
  };

  handleTitleChange = title => this.setState({ title });

  handleMessageChange = message => this.setState({ message });

  handleSave = () => {
    this.props.onSave({
      title: this.state.title,
      message: this.state.message
    });
    this.props.hideModal();
  };

  render() {
    const { hideModal } = this.props;
    const { title, message } = this.state;
    return (
      <StyleWrapper
        width={600}
        heading={"Customize message"}
        hideModal={hideModal}
      >
        <form onSubmit={this.handleSave}>
          <Div mb={5} display="column">
            <Div fw={3} fs={4} color="gray7" mb={2}>
              Message Title
            </Div>
            <BigOutlineInput
              value={title}
              onChange={this.handleTitleChange}
              continuous
              color="gray7"
            />
          </Div>

          <Div mb={3} display="column">
            <Div fw={3} fs={4} color="gray7" mb={2}>
              Message Body
            </Div>
            <Wysiwyg
              text={message}
              onChange={this.handleMessageChange}
              height={200}
            />
          </Div>

          <ButtonGroup>
            <Submit
              title={this.props.buttonLabel || "Save"}
              disabled={!title.length && message.length}
            />
            <ButtonOutline title="Cancel" onClick={hideModal} />
          </ButtonGroup>
        </form>
      </StyleWrapper>
    );
  }
}

export default PortalMessageModal;
