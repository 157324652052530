import React from "react";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import {
  take,
  call,
  put,
  all,
  fork,
  takeEvery,
  select
} from "redux-saga/effects";
import { registerError } from "redux/modules/errors/actions";
import { actions, getters } from "./model";
import { actions as sliderSidebarActions } from "ui-kit/SliderSidebar/model";
import { getNotificationsIds } from "./selectors";
import { getCredentials } from "redux/modules/user/selectors";
import api from "./api";
import { BEGIN, COMMIT, REVERT } from "redux-optimist";
import uuid from "node-uuid";
import * as R from "ramda";
import { TASK_ACTIONS } from "./constants";
import { push } from "react-router-redux";
import { eventId as getEventId } from "redux/modules/event/selectors";

import AddCollaboratorInfoModal from "components/Global/Modals/AddCollaboratorInfoModal";
import OrderModal from "Orders/OrderModal/View";
import { showModal } from "redux/modules/modal/actions";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import ViewRecordModal from "components/Global/Module/Modals/ViewRecord";
import DocumentRequestModal from "Portal/PortalDocuments/RequestModal";

const refreshNotifications = function*({
  eventId = null,
  loading = false,
  refreshing = false,
  meta
}) {
  if (loading) {
    yield put(actions.setLoading(true, { meta }));
  }
  if (refreshing) {
    yield put(actions.setRefreshing(true, { meta }));
  }

  try {
    const credentials = yield select(getCredentials);
    const qs = {};
    if (eventId) {
      qs.eventId = eventId;
    }

    const [{ payload: events }, { payload: notifications }] = yield all([
      call(api.getNotificationStats, { credentials }),
      call(api.getNotificationsByEvent, { credentials, qs })
    ]);

    yield put(
      actions.setInitialData(
        {
          events,
          notifications,
          eventId,
          lastDate: notifications.last_date,
          countOfVisibleNotifications: notifications.count_of_notifications
        },
        { meta }
      )
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred getting notifications"
        }
      ])
    );
  } finally {
    if (loading) {
      yield put(actions.setLoading(false, { meta }));
    }
    if (refreshing) {
      yield put(actions.setRefreshing(false, { meta }));
    }
  }
};

const getNotifications = function*({ meta }) {
  yield put(
    sliderSidebarActions.openSliderSidebar(null, {
      meta
    })
  );

  const eventId = yield select(getEventId);

  yield call(refreshNotifications, {
    eventId,
    loading: true,
    refreshing: false,
    meta
  });
};

const selectFilter = function*({ payload: { type, id = null }, meta }) {
  yield all([
    put(
      actions.setFilterBy(type, {
        meta
      })
    ),
    put(
      actions.setEventId(id, {
        meta
      })
    )
  ]);

  yield call(refreshNotifications, {
    eventId: id,
    loading: false,
    refreshing: true,
    meta
  });
};

const markAsRead = function*({ payload, meta }) {
  let notificationList = null;
  if (R.isNil(payload)) {
    notificationList = yield select(getNotificationsIds, meta);
  } else {
    notificationList = [payload.id];
  }

  const credentials = yield select(getCredentials);
  const transactionId = yield call([uuid, uuid.v4]);

  if (R.isNil(payload)) {
    yield put(
      actions.updateNotificationAllNotifications(
        { ids: notificationList },
        {
          optimist: { type: BEGIN, id: transactionId },
          meta
        }
      )
    );
  } else {
    yield put(
      actions.updateNotification(
        { id: payload.id, sectionId: payload.sectionId, status: "read" },
        {
          optimist: { type: BEGIN, id: transactionId },
          meta
        }
      )
    );
  }

  try {
    yield put(
      actions.confirmNotificationUpdate(null, {
        optimist: { type: COMMIT, id: transactionId },
        meta
      })
    );

    yield call(api.setNotificationStatus, {
      credentials,
      data: { notificationIds: notificationList, action: "read" }
    });

    const { payload } = yield call(api.getNotificationStats, { credentials });
    yield put(actions.setEvents(payload, { meta }));
  } catch (error) {
    yield put(
      actions.confirmNotificationUpdate(null, {
        optimist: { type: REVERT, id: transactionId },
        meta
      })
    );
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred getting the notifications"
        }
      ])
    );
  }
};

const markAsArchive = function*({ payload, meta }) {
  let notificationList = null;
  if (R.isNil(payload)) {
    notificationList = yield select(getNotificationsIds, meta);
  } else {
    notificationList = [payload.id];
  }

  const credentials = yield select(getCredentials);
  const eventId = yield select(getters.eventId, meta);

  try {
    yield call(api.setNotificationStatus, {
      credentials,
      data: { notificationIds: notificationList, action: "archive" }
    });
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating notification status"
        }
      ])
    );
  }

  yield call(refreshNotifications, {
    eventId,
    loading: false,
    refreshing: false,
    meta
  });
};

const loadNext = function*({ meta }) {
  yield put(actions.setLoadingNext(true, { meta }));

  try {
    const credentials = yield select(getCredentials);
    const eventId = yield select(getters.eventId, meta);
    const lastDate = yield select(getters.lastDate, meta);

    const qs = {
      after: lastDate
    };
    if (eventId) {
      qs.eventId = eventId;
    }

    const { payload: notifications } = yield call(api.getNotificationsByEvent, {
      credentials,
      qs
    });

    yield put(
      actions.setNextData(
        {
          notifications,
          lastDate: notifications.last_date,
          countOfVisibleNotifications: notifications.count_of_notifications
        },
        { meta }
      )
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred getting next set of notifications"
        }
      ])
    );
  } finally {
    yield put(actions.setLoadingNext(false, { meta }));
  }
};

const formNewSubmission = function*({ payload }) {
  const submissionId = R.path(["meta", "submission_id"], payload);
  yield put(
    showModal({
      content: <OrderModal submissionId={submissionId} onDone={() => null} />,
      wrapper: ModalWrapper
    })
  );
};

const newMessage = function*({ payload }) {
  const submissionId = R.path(["meta", "submission_id"], payload);
  yield put(
    showModal({
      content: <OrderModal submissionId={submissionId} onDone={() => null} />,
      wrapper: ModalWrapper
    })
  );
};

const newRecordMessage = function*({ payload }) {
  const moduleId = R.path(["meta", "module_id"], payload);
  const recordId = R.path(["meta", "record_id"], payload);

  if (STANDARD_MODULE_IDS.documentRequests.id === moduleId) {
    yield put(
      showModal({
        content: <DocumentRequestModal view="admin" recordId={recordId} />,
        wrapper: ModalWrapper
      })
    );
  } else {
    yield put(
      showModal({
        content: <ViewRecordModal moduleId={moduleId} recordId={recordId} />,
        wrapper: ModalWrapper
      })
    );
  }
};

const newRecordNote = function*({ payload: { module_id, record_id, meta } }) {
  const eventId = R.path(["event", "id"], meta);
  yield put(push(`/event/${eventId}/module/${module_id}/records/${record_id}`));
};

const orgInvite = function*({ payload }) {
  const orgId = R.path(["meta", "organization", "id"], payload);

  window.location = `/org-light/${orgId}/dashboard`;
};

const eventInvite = function*({ payload }) {
  const accepted = R.pathSatisfies(
    status => (status && status === "accepted" ? true : false),
    ["meta", "event_invite", "status"],
    payload
  );
  const eventId = R.path(["meta", "event", "id"], payload);

  if (accepted) {
    window.location = `/event-light/${eventId}/dashboard`;
  } else {
    const eventName = R.path(["meta", "event", "name"], payload);

    yield put(
      showModal({
        content: (
          <AddCollaboratorInfoModal
            eventId={eventId}
            eventName={eventName}
            onUpdated={() => {
              window.location = `/event-light/${eventId}/dashboard`;
            }}
          />
        )
      })
    );
  }
};

const task = function*() {
  // @TODO: Open task sidebar
  const taskId = "taskId";
  yield put(push(`/schedule/scheduleId?open=${taskId}`));
};

const watchOpenPicker = function*() {
  for (;;) {
    const action = yield take(actions.setIsDrawerOpen.type);
    yield call(getNotifications, action);
  }
};

const watchSelectFilter = function*() {
  yield takeEvery(actions.selectFilter.type, selectFilter);
};

const watchMarkAsRead = function*() {
  yield takeEvery(actions.markAsRead.type, markAsRead);
};

const watchMarkAsArchive = function*() {
  yield takeEvery(actions.markAsArchive.type, markAsArchive);
};

const watchLoadNext = function*() {
  yield takeEvery(actions.loadNext.type, loadNext);
};

const watchExecuteAction = function*() {
  for (;;) {
    const action = yield take(actions.executeAction.type);
    const delegate = R.prop(action.payload.type, {
      [TASK_ACTIONS.FORM_NEW_SUBMISSION]: formNewSubmission,
      [TASK_ACTIONS.NEW_MESSAGE]: newMessage,
      [TASK_ACTIONS.NEW_RECORD_MESSAGE]: newRecordMessage,
      [TASK_ACTIONS.NEW_RECORD_NOTE]: newRecordNote,
      [TASK_ACTIONS.EVENT_INVITE]: eventInvite,
      [TASK_ACTIONS.ORG_INVITE]: orgInvite,
      [TASK_ACTIONS.TASK]: task
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const rootSaga = function*() {
  yield all([
    fork(watchOpenPicker),
    fork(watchSelectFilter),
    fork(watchMarkAsRead),
    fork(watchMarkAsArchive),
    fork(watchExecuteAction),
    fork(watchLoadNext)
  ]);
};

export default rootSaga;
