import * as R from "ramda";
import { createHandlers } from "redux-mvc";

const model = createHandlers({
  iniState: {
    title: null,
    description: null,
    backgroundImageUrl: null,
    progress: 0,
    alerts: [],
    tasks: [],
    files: [],
    pages: [],
    mediaHub: {}
  },
  reducers: {
    config: (
      state,
      {
        payload: {
          progress,
          title,
          description,
          backgroundImageUrl,
          alerts,
          tasks,
          files,
          pages,
          mediaHub
        }
      }
    ) =>
      R.filter(R.identity, {
        title,
        description,
        backgroundImageUrl,
        alerts,
        tasks,
        files,
        pages,
        mediaHub,
        progress
      })
  },
  namespace: "HubHome"
});

const { actions, getters } = model;

export { actions, getters };

export default model;
