/* eslint-disable react/prop-types */ //
import React from "react";
import { get } from "lodash";
import { Div, BigFilledButton } from "components/Base";
import Sticky from "@lennd/react-stickynode/dist/Sticky";

import Loading from "components/Global/Loading";
import NavBar from "components/PortalV2/NavBar";
import FormBackground from "components/Event/FormsV2/Sections/FormBackground";
import FormFooter from "components/Event/FormsV2/Form/FormFooter";
import FormSubmit from "components/Event/FormsV2/Form/FormSubmit";
import SaveForLater from "components/Event/FormsV2/Form/SaveForLater";
import Section from "components/Event/FormsV2/Sections/Section";
import FormShareNotice from "components/Event/FormsV2/Sections/FormShareNotice";
import FormStatusNotice from "components/Event/FormsV2/Sections/FormStatusNotice";
import isAdminField from "components/Event/FormsV2/Utils/isAdminField";
import NotFoundMessage from "components/Event/FormsV2/NotFound";

import CSSModules from "react-css-modules";
import css from "../styles.scss";

import { MODES } from "ui-kit/Datagrid/constants";

import { connect } from "react-redux";
import { formatAmountForEvent } from "redux/modules/event/selectors";

const decorate = connect(state => ({
  formatAmountForEvent: formatAmountForEvent(state)
}));

const CartSubItemUndecorated = ({
  formatAmountForEvent,
  priceEach,
  subname,
  quantity,
  onRemove
}) => (
  <Div className={css.parentContainer} display="row.flex-start.center">
    <Div display="row.flex-start.center">
      <Div fs={1}>{quantity}</Div>
      <Div fs={1} mx={1}>
        x
      </Div>
      <Div fs={1}>
        {subname}
        {priceEach ? ` (${formatAmountForEvent(priceEach)} ea)` : null}
      </Div>
    </Div>
    <Div
      color={{
        default: "gray5",
        hover: "gray7"
      }}
      ml={2}
      fs={1}
      className={css.childContainer}
      onClick={onRemove}
    >
      Remove
    </Div>
  </Div>
);

const CartSubItem = decorate(CartSubItemUndecorated);

const CartItemUndecorated = ({
  name,
  subname,
  quantity,
  onRemove,
  items,
  questions,
  zones,
  showChildren,
  isLastItem,
  totalPrice,
  priceEach,
  disabled,
  formatAmountForEvent
}) => (
  <Div display="row.flex-start.flex-start" mb={2}>
    <Div fw={3} fs={3} mr={4} style={{ flexShrink: 0 }} color="gray7">
      {quantity}
    </Div>
    <Div
      flex={1}
      pb={2}
      style={
        isLastItem
          ? undefined
          : {
              borderBottom: "1px dotted #cecece"
            }
      }
    >
      <Div
        display={
          showChildren ? "row.flex-start.flex-start" : "row.flex-start.center"
        }
      >
        <Div display="column" flex={1}>
          <Div color="gray7" fs={3} fw={3}>
            {name}
          </Div>
          {zones && zones.length ? (
            <Div color="gray7" fs={1}>
              {zones}
            </Div>
          ) : null}
          {questions && questions.length ? (
            <Div color="gray7" fs={1}>
              {questions}
            </Div>
          ) : null}
          <Div display="row.flex-start.center">
            {subname ? (
              <Div color="gray7" fs={1} mr={1}>
                {subname} &middot;
              </Div>
            ) : null}
            {!disabled && !showChildren ? (
              <Div
                color={{
                  default: "gray5",
                  hover: "gray7"
                }}
                fs={1}
                onClick={onRemove}
              >
                Remove
              </Div>
            ) : null}
          </Div>
          {showChildren ? (
            <Div>
              {items.map(item => (
                <CartSubItem key={item.id} {...item} />
              ))}
            </Div>
          ) : null}
        </Div>
        {totalPrice ? (
          <Div
            display="column"
            style={{
              flexShrink: 0
            }}
          >
            <Div fs={3} fw={3} color="gray7">
              {formatAmountForEvent(totalPrice)}
            </Div>
            {!showChildren ? (
              <Div color="gray3" fs={1}>
                {formatAmountForEvent(priceEach)} ea
              </Div>
            ) : null}
          </Div>
        ) : null}
      </Div>
    </Div>
  </Div>
);

const CartItem = decorate(CartItemUndecorated);

const CartUndecorated = ({
  cart,
  totalPriceOfCartItems,
  disabled,
  formatAmountForEvent,
  ...props
}) => (
  <Div bg="white" {...props}>
    <Div
      bg="neutral0"
      fw={3}
      fs={3}
      px={4}
      py={3}
      style={{
        borderRadius: "0 4px 0 0",
        backgroundColor: "#F6F4F8"
      }}
    >
      Request Summary
    </Div>
    <Div py={3} px={4}>
      {cart.map(type => {
        const countOfItems = type.items.length;
        return (
          <Div>
            {type.name ? (
              <Div display="row.flex-start.center" mb={2}>
                <Div uppercase color="gray6" fs={1} pr={2}>
                  {type.name}
                </Div>
                <Div
                  flex={1}
                  style={{
                    borderBottom: "1px dotted #cecece"
                  }}
                />
              </Div>
            ) : null}
            {type.items.map((item, itemIdx) => (
              <CartItem
                key={item.id}
                isLastItem={countOfItems - 1 === itemIdx}
                disabled={disabled}
                {...item}
              />
            ))}
          </Div>
        );
      })}
      {!cart.length ? <Div>You have not selected any items.</Div> : null}
      {totalPriceOfCartItems ? (
        <Div bt={1} bc="gray2" display="column" color="gray7" fw={3} pt={3}>
          <Div display="row.space-between.center" fs={1} mb={1}>
            <Div>Subtotal</Div>
            <Div>{formatAmountForEvent(totalPriceOfCartItems)}</Div>
          </Div>
          <Div display="row.space-between.center" fs={2}>
            <Div>Total</Div>
            <Div fs={4} color="purple8">
              {formatAmountForEvent(totalPriceOfCartItems)}
            </Div>
          </Div>
        </Div>
      ) : null}
    </Div>
  </Div>
);

const Cart = decorate(CartUndecorated);

const Sidebar = ({ cart, totalPriceOfCartItems, disabled }) => (
  <Div width={3 / 10} className={css.sidebarCart}>
    <Div>
      <Sticky enabled={true} top={50}>
        <Cart
          disabled={disabled}
          cart={cart}
          totalPriceOfCartItems={totalPriceOfCartItems}
        />
      </Sticky>
    </Div>
  </Div>
);

const Confirmation = CSSModules(
  ({
    message,
    goToPortal,
    isOpenSubmission,
    form,
    eventDetails,
    collaborator
  }) => (
    <div styleName="form" className="page-form">
      <NavBar
        isOpenSubmission={isOpenSubmission}
        contactId={""}
        contactValues={{}}
        eventName={eventDetails.name}
        form={form}
        portalId={eventDetails.uuid}
        portalSlug={eventDetails.slug}
        collaborator={collaborator}
        showSaveState
      />

      {/* form container */}
      <div styleName="container">
        {/* form top */}
        <FormBackground
          backgroundUrl={
            form.background_image_url ||
            get(eventDetails, "background_image_url")
          }
          isEditing={false}
        />

        {/* form content */}
        <div styleName="contentContainer" className="page-form-content">
          <Div display="row.flex-start.flex-start">
            <Div display="column" width={1}>
              {/* form header */}
              <div styleName="headerContainer">
                <div styleName="headerTitle">{form.title}</div>
              </div>

              <div styleName="formContainer">
                <Div style={{ textAlign: "left" }}>
                  <Div
                    mt={5}
                    mb={!goToPortal ? 20 : 10}
                    className="lennd-wysiwyg-content"
                  >
                    {message ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: message }}
                        style={{ fontSize: 18 }}
                      />
                    ) : (
                      "Your submission has been received."
                    )}
                  </Div>
                  {goToPortal ? (
                    <BigFilledButton mb={20} onClick={goToPortal}>
                      Go to your portal
                    </BigFilledButton>
                  ) : null}
                </Div>

                <FormFooter formId={form.id} />
              </div>
            </Div>
          </Div>
        </div>
      </div>
    </div>
  ),
  css
);

const NotFound = () => <NotFoundMessage />;

const Submission = CSSModules(
  ({
    isFetching,
    isOrderFormType,
    totalQtyOfCartItems,
    totalPriceOfCartItems,
    isLocked,
    isFormLocked,
    onSubmit,
    onKeyPress,
    eventDetails,
    isOpenSubmission,
    form,
    submission,
    collaborator,
    preferences,
    moduleRecordReferences,
    isFetchingBulk = false,
    sortedFormFields,
    goToPortal,
    goToSubmission,
    getFormRows,
    dispatch,
    showModal,
    hideModal,
    updateFormValue,
    registerValidator,
    unregisterValidator,
    registerRef,
    cartWithHandlers
  }) => (
    <form
      ref={ref => registerRef(ref, "container")}
      noValidate
      styleName="form"
      className="page-form"
      onSubmit={onSubmit}
      onKeyPress={onKeyPress}
      autocomplete="off"
    >
      {/* form container */}
      <NavBar
        isOrderFormType={isOrderFormType}
        totalQtyOfCartItems={totalQtyOfCartItems}
        isOpenSubmission={isOpenSubmission}
        contactId={""}
        contactValues={{}}
        eventName={eventDetails.name}
        form={form}
        portalId={eventDetails.uuid}
        portalSlug={eventDetails.slug}
        collaborator={collaborator}
        showSaveState
      />
      {!isFetching ? (
        <div styleName="container">
          {/* form top */}
          <FormBackground
            backgroundUrl={
              form.background_image_url ||
              get(eventDetails, "background_image_url")
            }
            isEditing={false}
          />

          <FormShareNotice
            showShareLink={form.show_share_link}
            formId={form.id}
            formSlug={form.slug}
            eventSlug={eventDetails.slug}
          />

          {/* form content */}
          <div
            styleName={
              isOrderFormType ? "contentContainerOrder" : "contentContainer"
            }
            className="page-form-content"
          >
            <Div display="row.flex-start.flex-start">
              <Div
                display="column"
                width={isOrderFormType ? 7 / 10 : 1}
                br={isOrderFormType ? 1 : 0}
                style={{ borderColor: "#e4e4e4" }}
                className={isOrderFormType ? css.submissionContent : null}
              >
                <FormStatusNotice
                  isFormLocked={isFormLocked}
                  submission={submission}
                  goToPortal={isOpenSubmission ? undefined : goToPortal}
                  goToSubmission={goToSubmission}
                />

                {/* form header */}
                <div styleName="headerContainer">
                  <div styleName="headerTitle">{form.title}</div>
                  <div styleName="headerDescription">{form.description}</div>
                </div>

                <div
                  styleName={
                    isOrderFormType ? "orderFormContainer" : "formContainer"
                  }
                >
                  <Section
                    ref={ref => registerRef(ref, "section_0")}
                    sectionId={0}
                    form={form}
                    submission={submission}
                    preferences={preferences}
                    fields={sortedFormFields.filter(f => !isAdminField(f))}
                    eventDetails={eventDetails}
                    moduleRecordReferences={moduleRecordReferences}
                    getFormRows={getFormRows}
                    dispatch={dispatch}
                    showModal={showModal}
                    hideModal={hideModal}
                    updateFormValue={updateFormValue}
                    registerValidator={registerValidator}
                    unregisterValidator={unregisterValidator}
                    isFillingFormOut
                    isApprovingForm={false}
                    isResponseLocked={isLocked}
                    isViewingApproval={!!submission.is_submitted}
                    isEditing={false}
                    mode={MODES.SUBMISSION}
                    registerRef={registerRef}
                  />

                  {isOrderFormType ? (
                    <Cart
                      disabled={isLocked}
                      cart={cartWithHandlers}
                      totalPriceOfCartItems={totalPriceOfCartItems}
                      className={css.footerCart}
                    />
                  ) : null}

                  <div styleName="sendResponseRow">
                    {!isLocked ? (
                      <div styleName="sendResponseRowInner">
                        {!isFetchingBulk ? (
                          <FormSubmit
                            ref={ref => registerRef(ref, "submitButton")}
                          />
                        ) : (
                          <Loading content="Updating info..." />
                        )}
                        {!submission.is_submitted &&
                        !isOpenSubmission &&
                        form.enable_save_and_submit_later ? (
                          <SaveForLater
                            handleClick={goToPortal.bind(null, {
                              confirmation: "saved"
                            })}
                          />
                        ) : null}
                      </div>
                    ) : (
                      <div styleName="sendResponseDisabled">
                        <div>Locked for Review</div>
                        <i
                          className="material-icons"
                          styleName="sendResponseIconFooter"
                        >
                          &#xE899;
                        </i>
                      </div>
                    )}
                  </div>
                  <FormFooter formId={form.id} />
                </div>
              </Div>
              {isOrderFormType ? (
                <Sidebar
                  {...{
                    disabled: isLocked,
                    cart: cartWithHandlers,
                    totalPriceOfCartItems
                  }}
                />
              ) : null}
            </Div>
          </div>
        </div>
      ) : (
        <Div
          display="row.center.center"
          style={{
            height: "100vh"
          }}
        >
          <Loading dark content="Loading form..." styleName="loading" />
        </Div>
      )}
    </form>
  ),
  css
);

export { Submission, Confirmation, NotFound };
