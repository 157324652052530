import Helpers from "utils/Global/Helpers";
import getQuery from "redux/modules/modules/utils/get-query";

/* eslint no-underscore-dangle: "off" */

export default {
  getModule: ({ credentials, moduleId, eventId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules/${moduleId}?eventId=${eventId}`,
        credentials,
        success,
        error
      });
    }),
  getRecordType: ({ credentials, moduleId, eventId, recordTypeId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules/${moduleId}/record-types/${recordTypeId}?eventId=${eventId}`,
        credentials,
        success,
        error
      });
    }),
  getRecordTypes: ({ credentials, moduleId, eventId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules/${moduleId}/record-types?eventId=${eventId}`,
        credentials,
        success,
        error
      });
    }),
  getFields: ({ credentials, moduleId, options }) =>
    new Promise((success, error) => {
      const query = getQuery(options);

      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/modules/${moduleId}/fields${query || ""}`,
        credentials,
        success,
        error
      });
    }),
  search: (credentials, userId, eventId, orgId, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules-v2/event/${eventId}/dashboard/${data.moduleId}`,
        qs: { eventId, orgId, ...data },
        credentials,
        success,
        error
      });
    }),
  export: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules-v2/event/${eventId}/dashboard/${data.moduleId}/export`,
        qs: { eventId: eventId, ...data },
        credentials,
        success,
        error
      });
    }),
  downloadFiles: ({ credentials, eventId, moduleId, recordIds }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules-v2/event/${eventId}/module/${moduleId}/download-files`,
        qs: { eventId, moduleId, recordIds },
        credentials,
        success,
        error
      });
    }),
  getViews: ({ credentials, eventId, recordTypeId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules-v2/dashboard/${data.moduleId}/views`,
        qs: { eventId, recordTypeId },
        data,
        credentials,
        success,
        error
      });
    }),
  addView: ({ credentials, eventId, recordTypeId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/modules-v2/dashboard/${data.moduleId}/views`,
        qs: { eventId, recordTypeId },
        data,
        credentials,
        success,
        error
      });
    }),
  updateView: ({ credentials, eventId, recordTypeId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/modules-v2/dashboard/${data.moduleId}/view`,
        qs: { eventId, recordTypeId },
        data,
        credentials,
        success,
        error
      });
    }),
  deleteView: ({ credentials, eventId, recordTypeId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/modules-v2/dashboard/${data.moduleId}/view`,
        qs: { eventId, recordTypeId },
        data,
        credentials,
        success,
        error
      });
    }),
  selectView: ({ credentials, eventId, recordTypeId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/modules-v2/dashboard/${data.moduleId}/active-view`,
        qs: { eventId, recordTypeId },
        data,
        credentials,
        success,
        error
      });
    }),
  getActiveView: ({ credentials, eventId, recordTypeId, mode, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules-v2/dashboard/${data.moduleId}/active-view`,
        qs: { eventId, recordTypeId, mode },
        data,
        credentials,
        success,
        error
      });
    }),
  syncToFuzion: ({ credentials, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/integrations/fuzion/events/${data.eventId}/syncRecords`,
        data,
        credentials,
        success,
        error
      });
    }),
  getStatus: (credentials, { jobId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/jobs/progress/${jobId}`,
        credentials,
        success,
        error
      });
    })
};
