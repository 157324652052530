import Helpers from "utils/Global/Helpers";
import getQuery from "redux/modules/modules/utils/get-query";

module.exports = {
  export: (credentials, reportId, options) =>
    new Promise((resolve, reject) => {
      const query = getQuery(options);

      Helpers.request({
        method: "post",
        url: `${
          window.__LENND_API_BASE_URL__
        }/reports/${reportId}/export${query || ""}`,
        credentials,
        success: resolve,
        error: reject
      });
    }),

  print: (credentials, reportId, options) =>
    new Promise((resolve, reject) => {
      const query = getQuery(options);

      Helpers.request({
        method: "post",
        url: `${
          window.__LENND_API_BASE_URL__
        }/reports/${reportId}/print${query || ""}`,
        credentials,
        success: resolve,
        error: reject
      });
    })
};
