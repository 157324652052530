import PropTypes from "prop-types";
import React, { Component } from "react";
import { get } from "lodash";
import { Div } from "components/Base";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";
import resolveEditor from "components/Global/StandAloneEditors/utils/resolveEditor";
import resolveEditorProps from "components/Global/CRM/About/InlineInput/resolveEditorProps";
import resolveFormatter from "components/Global/Table3/CellFormatters/utils/resolveFormatter";
import getMetaData from "utils/value-types/get-meta-data";

@CSSModules(css)
class EditRecord extends Component {
  render() {
    const {
      user,
      module,
      fieldGroups,
      record,
      eventDetails,
      onChange
    } = this.props;
    const list = fieldGroups
      .map(fg => ({
        ...fg,
        fields: fg.fields.filter(
          f =>
            !resolveReadOnlyFields({
              moduleId: module.id
            }).includes(f.field_id)
        )
      }))
      .filter(fg => fg.fields.length)
      .map(group => (
        <li>
          <div styleName="header">{group.name}</div>
          <ul>
            {group.fields.sort((a, b) => a.order - b.order).map(field => {
              let Renderer;
              let props;
              const meta = {
                ...getMetaData({
                  row: record.values,
                  rowId: record.id,
                  field: field.field,
                  userId: user.id,
                  eventDetails,
                  eventId: get(eventDetails, "id")
                }),
                helpers: {}
              };

              if (field.field.settings.readOnly) {
                Renderer = resolveFormatter(field.field);
                props = {
                  dependentValues: meta,
                  value: get(record.values, [field.field_id])
                };
              } else {
                Renderer = resolveEditor(field.field);
                props = {
                  ...resolveEditorProps(field.field, eventDetails),
                  onChange: val => onChange(field.field_id, val),
                  rowMetaData: meta,
                  value: get(record.values, [field.field_id])
                };
              }

              return (
                <li styleName="inputGroup">
                  <Div fw={3} color="gray7">
                    {field.field.name}
                  </Div>
                  <Renderer {...props} />
                </li>
              );
            })}
          </ul>
        </li>
      ));

    return <ul>{list}</ul>;
  }
}

EditRecord.propTypes = {
  eventDetails: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
  module: PropTypes.shape({
    field_groups: PropTypes.array
  }).isRequired
};

export default EditRecord;
