import PropTypes from "prop-types";
import React, { Component } from "react";
import RaisedButton from "components/Atoms/RaisedButton";
import autobind from "autobind-decorator";
import Popover from "@lennd/material-ui/Popover";
import MakeCopiesForm from "components/Event/FormsV2/Sections/FormGrid/CardView/Edit/MakeCopies/MakeCopiesForm";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class DuplicateButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null
    };
  }

  @autobind
  handleClick(event) {
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget
    });
  }

  @autobind
  handleRequestClose() {
    this.setState({
      open: false
    });
  }

  render() {
    return (
      <div>
        {this.props.button ? (
          <div onClick={this.handleClick}>{this.props.button}</div>
        ) : (
          <RaisedButton
            action={this.handleClick}
            leftIcon="control_point_duplicate"
            label={
              this.props.selectedRowsCount !== 1
                ? "Duplicate Rows"
                : "Duplicate Row"
            }
            className={this.props.className}
          />
        )}
        <Popover
          open={this.state.open}
          onClose={this.handleRequestClose}
          anchorEl={this.state.anchorEl}
        >
          <MakeCopiesForm
            onClose={this.handleRequestClose}
            duplicate={this.props.duplicate}
          />
        </Popover>
      </div>
    );
  }
}

DuplicateButton.propTypes = {
  selectedRowsCount: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  duplicate: PropTypes.func.isRequired,
  button: PropTypes.object
};

export default DuplicateButton;
