import React from "react";
import { connect } from "react-redux";
import { getters } from "Portal/ViewPortalAsModal/model";
import Loading from "ui-kit/Loading";
import { Div } from "components/Base";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Body from "components/Global/Modal/Layout/ScrollableBody";

const decorate = connect(state => ({
  loading: getters.loading(state)
}));

const Layout = ({ loading, hideModal, useModal }) => {
  return (
    <ModalWrapper>
      <StyleWrapper
        heading="View portal as..."
        hideModal={hideModal}
        width="95%"
        containerStyles={{
          height: "600px"
        }}
        bodyStyles={{ padding: 0 }}
      >
        <Body style={{ maxHeight: "532px", height: "100%", border: 0 }}>
          <Div
            style={{
              position: "relative"
            }}
          >
            {loading ? <Loading /> : <>Some stuff here...</>}
          </Div>
        </Body>
      </StyleWrapper>
    </ModalWrapper>
  );
};

export default decorate(Layout);
