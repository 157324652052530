import { get } from "lodash";

export default ({ record, fieldId, allValues }) => {
  get(record, ["values", fieldId, "value", "records"], []).forEach(item => {
    allValues[`${fieldId}_${item.id}`] = {
      type: "number",
      value: item.quantity
    };
  });

  return allValues;
};
