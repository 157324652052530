import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import PropTypes from "prop-types";
import Drawer from "material-ui/Drawer";

import { actions, getters } from "../model";
import { noop } from "utils/General";

const cssProps = {
  left: { borderRadius: "0 12px 12px 0" },
  right: { borderRadius: "12px 0 0 12px" }
};

const decorate = R.compose(
  connect(
    (state, props) => ({
      open: getters.isDrawerOpen(state, props)
    }),
    bindInstance({
      close: actions.close
    })
  )
);

const DrawerLayout = ({
  open = false,
  position = "right",
  width = 500,
  close,
  onClose = noop,
  children = null,
  overlayStyle = null,
  style = {}
}) => {
  // TODO: when update material-ui, remove openSecondary and use => anchor={position}
  return (
    <Drawer
      docked={false}
      width={width}
      open={open}
      openSecondary={R.equals(position, "right")}
      onRequestChange={() => {
        close();
        onClose();
      }}
      containerStyle={{ ...cssProps[position], ...style }}
      overlayStyle={overlayStyle}
      zDepth={1}
    >
      {children}
    </Drawer>
  );
};

DrawerLayout.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  children: PropTypes.node,
  position: PropTypes.string,
  width: PropTypes.number
};

export default decorate(DrawerLayout);
