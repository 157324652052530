import React from "react";
import { connect } from "react-redux";

import { actions, getters } from "../../index";

import { EVENT_VIEWS } from "../../constants";

import { Div } from "components/Base";
import { noop } from "utils/General";

const options = [
  { id: EVENT_VIEWS.GRID, label: "List" },
  { id: EVENT_VIEWS.CALENDAR, label: "Calendar" }
];

const decorate = connect(
  (state, props) => ({
    selectedView: getters.selectedView(state, props)
  }),
  { onSelectView: actions.setSelectedView }
);

const ToggleCalendarView = ({
  selectedView = EVENT_VIEWS.CALENDAR,
  onSelectView = noop,
  ...styleProps
}) => (
  <Div
    display="row.flex-start.center"
    style={{ flexShrink: 0 }}
    bg="#7a38c3"
    p={1}
    bra={1}
    {...styleProps}
    data-cy="toggle-calendar-view"
  >
    {options.map(
      (option, index) => (
        <Div
          key={option.id}
          flex={1}
          p={2}
          display="row.center.center"
          bra={1}
          fs={2}
          fw={3}
          onClick={() => onSelectView(option.id)}
          ml={index === 0 ? 0 : 1}
          color="gray0"
          bg={{
            default: selectedView === option.id ? "primary7" : "transparent",
            hover: "primary7"
          }}
          data-cy={option.id}
        >
          {option.label}
        </Div>
      ),
      options
    )}
  </Div>
);

export default decorate(ToggleCalendarView);
