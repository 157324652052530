import React from "react";

import * as R from "ramda";

import { connect } from "react-redux";

import {
  Div,
  MediumOutlineButton,
  DownIcon,
  PopMenu,
  PopMenuCard,
  Text0,
  Text1,
  Text2,
  SmallCheckbox
} from "components/Base";

import Tooltip from "components/Global/Tooltip";

import { actions } from "Orders/TicketsPasses";

import { PASSES_COLUMNS_WIDTH } from "Orders/TicketsPasses/constants";

import { getProfilesTemp } from "Orders/TicketsPasses/selectors";

import ProfileLimitPopOver from "Orders/TicketsPasses/View/ProfileLimitPopOver";

import ProfileMultiLimitsPopOver from "Orders/TicketsPasses/View/ProfileMultiLimitsPopOver";

const formatWhoHasAccess = whoHasAccess => {
  if (R.all(R.propEq("selected", false), whoHasAccess)) {
    return "Assign...";
  }
  const selectedProfiles = R.compose(
    R.length,
    R.filter(R.propEq("selected", true))
  )(whoHasAccess);

  return `${selectedProfiles} profile${selectedProfiles > 1 ? "s" : ""}`;
};

const formatWhoHasAccessTooltip = whoHasAccess => {
  return R.compose(
    R.reduce((acc, curr) => `${acc === "" ? acc : `${acc}, `}${curr.name}`, ""),
    R.filter(R.propEq("selected", true))
  )(whoHasAccess);
};

const decorate = connect(
  state => ({
    profilesTemp: getProfilesTemp(state)
  }),
  {
    selectPass: actions.selectPass,
    toggleProfileTemp: actions.toggleProfileTemp,
    enableAllProfileTemp: () => actions.enableAllProfileTemp(),
    disableAllProfileTemp: () => actions.disableAllProfileTemp()
  }
);

const UserProfilePopOver = ({
  who_has_access,
  id,
  profilesTemp,
  selectPass,
  toggleProfileTemp,
  enableAllProfileTemp,
  disableAllProfileTemp
}) => {
  return (
    <PopMenu
      Label={({ onClick }) => {
        return R.all(R.propEq("selected", false), who_has_access) ? (
          <Div>
            <MediumOutlineButton
              onClick={() => {
                selectPass({ id });
                onClick();
              }}
              RightIcon={DownIcon}
              ml={2}
              width={PASSES_COLUMNS_WIDTH.WHO_HAS_ACCESS}
            >
              {formatWhoHasAccess(who_has_access)}
            </MediumOutlineButton>
          </Div>
        ) : (
          <Tooltip
            tooltip={`Profiles: ${formatWhoHasAccessTooltip(who_has_access)}`}
          >
            <Div>
              <MediumOutlineButton
                onClick={() => {
                  selectPass({ id });
                  onClick();
                }}
                RightIcon={DownIcon}
                ml={2}
                width={PASSES_COLUMNS_WIDTH.WHO_HAS_ACCESS}
              >
                {formatWhoHasAccess(who_has_access)}
              </MediumOutlineButton>
            </Div>
          </Tooltip>
        );
      }}
    >
      {({ closeMenu }) => (
        <PopMenuCard position="bottom" align="right">
          <Div py={3} mb={1} width={260}>
            <Div display="row.space-between.center" px={3}>
              <Text0 color="neutral6" bold>
                USER PROFILES
              </Text0>
              <Div display="row.flex-start.center">
                <Text0
                  color="primary7"
                  bold
                  mr={2}
                  underline
                  onClick={enableAllProfileTemp}
                >
                  All
                </Text0>
                <Text0
                  color="primary7"
                  underline
                  onClick={disableAllProfileTemp}
                >
                  None
                </Text0>
              </Div>
            </Div>
            {who_has_access.length > 0 ? (
              <Div>
                {R.map(
                  profile => (
                    <Div key={profile.id} bb={1} bc="neutral2">
                      <Div display="row.space-between.center" p={2}>
                        <Div display="row.flex-start.center" p={2}>
                          <SmallCheckbox
                            selected={profile.selected}
                            onClick={() => {
                              toggleProfileTemp({ profileId: profile.id });
                            }}
                          />
                          <Text2 bold color="neutral7" ml={1}>
                            {profile.name}
                          </Text2>
                        </Div>
                        <ProfileLimitPopOver
                          handlers={{
                            profileId: profile.id,
                            prices: profile.prices,
                            limit: profile.limit
                          }}
                        />
                      </Div>
                    </Div>
                  ),
                  profilesTemp
                )}
                <Div display="row.space-between.center" mt={2} px={3}>
                  <MediumOutlineButton
                    onClick={() => {
                      closeMenu();
                    }}
                  >
                    Cancel
                  </MediumOutlineButton>
                  <ProfileMultiLimitsPopOver
                    handlers={{
                      profileId: "",
                      prices: []
                    }}
                  />
                </Div>
              </Div>
            ) : (
              <Text1 bold color="neutral7">
                No profiles created
              </Text1>
            )}
          </Div>
        </PopMenuCard>
      )}
    </PopMenu>
  );
};

export default decorate(UserProfilePopOver);
