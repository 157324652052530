import React from "react";
import EditorBase from "./TableConnector";
import ReactDOM from "react-dom";
import DateTime from "components/Global/Editors/DateTimeEditor";
import Portal from "./CellPortal";
import BeforeClose from "./BeforeClose";

export default class DateTimeEditor extends EditorBase {
  componentDidMount() {
    const node = ReactDOM.findDOMNode(this.focusRef);
    if (node) {
      const inputs = node.getElementsByTagName("input");
      if (inputs.length) {
        this.focusElement = inputs[0];
      }
    }
  }

  handleClose = () => {
    this.props.onCommit();
  };

  render() {
    return (
      <Portal
        ref="editor"
        onClose={this.handleClose}
        editor={
          <div style={{ width: this.props.column.width - 3 }}>
            <BeforeClose do={this.handleClose}>
              <div style={{ fontSize: "13.5px" }}>
                <DateTime
                  ref={c => (this.focusRef = c)}
                  {...this.props}
                  value={this.state}
                  onChange={this.handleChange}
                />
              </div>
            </BeforeClose>
          </div>
        }
      />
    );
  }
}
