import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function addTrigger(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post(getState().user.user.credentials, data);
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding trigger"
          }
        ])
      );
    }
  };
}

export function updateTrigger(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.put(getState().user.user.credentials, data);
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating trigger"
          }
        ])
      );
    }
  };
}

export function updateTriggers(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.put(getState().user.user.credentials, {
        ...data,
        bulk: true
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating triggers"
          }
        ])
      );
    }
  };
}

export function deleteTrigger(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.delete(getState().user.user.credentials, data);
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting trigger"
          }
        ])
      );
    }
  };
}
