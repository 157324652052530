import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import { actions } from "Event/Home";

import { registerError } from "redux/modules/errors/actions";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import {
  COUNT_APPROVALS_CHECKLISTS,
  SETUP_CHECKLISTS,
  APPROVALS_PAYLOAD
} from "Event/Home/mockedData";

import eventApi from "redux/modules/event/api";

const getEventRecordTypes = function*() {
  try {
    yield put(actions.setLoading(true));
    const credentials = yield select(getCredentials);
    const eventDetails = yield select(getEventDetails);
    const result = yield call(
      eventApi.getEventAccountRecordTypes,
      credentials,
      eventDetails.id
    );
    yield all([
      put(actions.setLoading(false)),
      put(actions.setGroupTypes(result.payload)),
      put(actions.setApprovalsChecklistsCount(COUNT_APPROVALS_CHECKLISTS)),
      put(actions.setSetupChecklists(SETUP_CHECKLISTS)),
      put(actions.setMyApprovals(APPROVALS_PAYLOAD))
    ]);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred event dashboard"
          }
        ])
      ),
      put(actions.setLoading(false))
    ]);
  }
};

const watchGetEventRecordTypes = function*() {
  yield takeEvery(actions.getEventRecordTypes.type, getEventRecordTypes);
};

const rootSaga = function*() {
  yield all([fork(watchGetEventRecordTypes)]);
};

export default rootSaga;
