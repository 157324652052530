import React from "react";
import { Div } from "components/Base";

export const Row = ({ children, ...styleProps }) => (
  <Div mb={3} width={1} {...styleProps}>
    {children}
  </Div>
);

export default Row;
