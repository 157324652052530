import PropTypes from "prop-types";
import React from "react";
import { get } from "lodash";
import autobind from "autobind-decorator";
import EditorBase from "../TableConnector";
import Formatter from "../../CellFormatters/File";
import UploadButton from "components/Atoms/UploadButton";
import ExpandButton from "./ExpandButton";
import Helpers from "utils/Global/Helpers";
import getValue from "utils/value-types/get-value/file";
import isEmpty from "utils/value-types/is-empty/file";
import uuid from "node-uuid";
import FileViewer from "components/Global/Modals/FileViewer";
import FileViewerWrapper from "components/Global/Modals/FileViewer/Wrapper";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FileEditor extends EditorBase {
  downloadFile(url) {
    window.open(url, "_blank");
  }

  @autobind
  deleteFile(file) {
    const existingFiles = getValue(this.state);
    this.handleChange({
      type: "file",
      value: {
        files: [...existingFiles.filter(f => f.id !== file.id)]
      }
    });
  }

  @autobind
  getValue() {
    return {
      [this.props.column.key]: this.wrapValue(getValue(this.state))
    };
  }

  @autobind
  wrapValue(files) {
    return {
      type: "file",
      value:
        files && files.length
          ? {
              files: files || []
            }
          : undefined
    };
  }

  @autobind
  onExpandClick(e) {
    e.stopPropagation();
    this.setState({
      expanded: !get(this.state, "expanded", false)
    });
  }

  @autobind
  showFilepicker() {
    const callback = files => {
      if (files.length) {
        const filteredFiles = files.filter(f => typeof f.url !== "undefined");
        if (filteredFiles.length) {
          const existingFiles = getValue(this.state);
          this.handleChange({
            type: "file",
            value: {
              files: [
                ...existingFiles,
                ...filteredFiles.map(f => ({
                  ...f,
                  filepickerId: f.id,
                  id: uuid.v4(),
                  uploadedAt: new Date()
                }))
              ]
            }
          });
        }
      }
    };

    const options = {
      multiple: true,
      accept: [],
      fromSources: ["local_file_system", "dropbox"]
    };

    const path = { path: "event-files/" };

    Helpers.getFilepicker(options, path, callback);
  }

  @autobind
  showFileViewer(file, files) {
    this.setState({ expanded: false });
    this.props.rowMetaData.helpers.showModal({
      content: (
        <FileViewer
          onFileDelete={this.deleteFile}
          start={files.indexOf(file)}
          files={files}
          isReadOnly={this.isReadOnly()}
        />
      ),
      wrapper: FileViewerWrapper
    });
  }

  @autobind
  isReadOnly() {
    return get(this.props, "column.settings.isReadOnly", false);
  }

  render() {
    const { rowMetaData } = this.props;
    const existingFiles = getValue(this.state);
    const hasFiles = !isEmpty(this.state);
    const isReadOnly = this.isReadOnly();

    return (
      <div
        styleName={isReadOnly ? "containerReadOnly" : "container"}
        ref="anchorEl"
      >
        {!isReadOnly ? (
          <div styleName={hasFiles ? "attachHasFiles" : "attach"}>
            <UploadButton onClick={this.showFilepicker}>
              {!hasFiles ? (
                <div className={css.label}>
                  Upload a file
                  <i className={[css.viewIcon, "material-icons"].join(" ")}>
                    arrow_back
                  </i>
                </div>
              ) : (
                ""
              )}
            </UploadButton>
          </div>
        ) : null}
        <div styleName="formatter">
          <Formatter
            mode="edit"
            onFileClick={file => this.showFileViewer(file, existingFiles)}
            value={this.state}
            dependentValues={rowMetaData}
          />
        </div>
        {existingFiles.length ? (
          <ExpandButton
            expanded={get(this.state, "expanded", false)}
            closeExpanded={() => this.setState({ expanded: false })}
            onExpandClick={this.onExpandClick}
            anchorEl={this.refs.anchorEl}
            showFilepicker={this.showFilepicker}
            onFileClick={file => this.showFileViewer(file, existingFiles)}
            downloadFile={this.downloadFile}
            deleteFile={this.deleteFile}
            files={existingFiles}
            isReadOnly={isReadOnly}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

FileEditor.propTypes = {
  ...EditorBase.propTypes,
  rowMetaData: PropTypes.object.isRequired
};

export default FileEditor;
