import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE, ADD_OPTIONS } from "./constants";

const iniState = {
  loading: false,
  addOption: ADD_OPTIONS.IMPORT,
  items: [],
  startingNumber: "",
  quantityToGenerate: 500,
  numbers: "",
  errorMessage: ""
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    addBarcodes: R.identity,
    updateStartingNumber: (_, { payload: startingNumber }) => ({
      startingNumber: R.slice(0, 12, startingNumber)
    }),
    updateQuantityToGenerate: (_, { payload: quantityToGenerate }) => {
      const slicedQuantityToGenerate = R.slice(0, 4, quantityToGenerate);
      return {
        quantityToGenerate:
          parseInt(slicedQuantityToGenerate) > 1000
            ? "1000"
            : slicedQuantityToGenerate
      };
    }
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
