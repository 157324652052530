import React from "react";
import { connect } from "react-redux";

import {
  Div,
  Text5,
  BigTextButton,
  CalendarIcon,
  LoadingIcon
} from "components/Base";

import { getters } from "Schedules/ScheduleViewPicker";

const decorate = connect((state, props) => ({
  loading: getters.loading(state, props)
}));

const Header = ({ handleClose, loading, ...styleProps }) => (
  <Div
    display="row.space-between.center"
    bb={1}
    bc="neutral3"
    height={55}
    {...styleProps}
  >
    <Div display="row.flex-start.center">
      <CalendarIcon color="neutral4" size={20} />
      <Text5 bold ml={3} color="gray7">
        Schedules
      </Text5>
    </Div>
    <Div>
      {loading ? <LoadingIcon color="gray5" /> : null}
      <BigTextButton
        isPadded={false}
        onClick={handleClose}
        ml={2}
        bold
        color="purple6"
      >
        Done
      </BigTextButton>
    </Div>
  </Div>
);

export default decorate(Header);
