import React from "react";
import { Div, Text4 } from "components/Base";
import SelectBox from "./SelectBox";
import InputRow from "Forms/WizardModal/View/Common/LayoutMock/InputRow";

const SelectCollectBulk = () => (
  <SelectBox
    method="bulk"
    title="Single Asset Request Grid"
    description="Collect asset requests in a grid or form for a single category of equipment or asset."
  >
    <Div width={1} display="column">
      <Text4 bold color="neutral6" ml={1} mb={3}>
        RADIO'S & ACESSORIES
      </Text4>
      <InputRow secondColumn />
    </Div>
  </SelectBox>
);

export default SelectCollectBulk;
