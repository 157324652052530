import * as R from "ramda";
import React, { useMemo } from "react";

import { getValue, getSettings, getField } from "../../utils";

import { CatalogItem } from "ui-kit/Form/View";

const Countries = props => {
  const rawOptions = getSettings(props, "availableCatalogItems", []);
  const options = useMemo(
    () => R.map(option => ({ id: option.id, label: option.value }), rawOptions),
    [rawOptions]
  );
  const value = getValue(props, "records", []);
  const iniValue = useMemo(
    () =>
      R.reduce(
        (value, selected) => ({
          ...value,
          [selected.id]: selected.quantity
        }),
        {},
        value
      ),
    [value]
  );
  const field = getField(props);
  const selectType = getSettings(props, "selectType");
  const editorProps = {
    iniValue,
    customWidth: getSettings(props, "customWidth"),
    options,
    quantityOptions: getSettings(props, "availableQuantities"),
    limitQuantity: getSettings(props, "shouldLimitToAvailableQuantities"),
    showQuantity: R.contains(selectType, ["quantity", "single-quantity"]),
    multiple: !R.contains(selectType, ["single", "single-quantity"])
  };

  return (
    <CatalogItem
      {...editorProps}
      instanceId={props.instanceId}
      fieldId={field.id}
    />
  );
};

export default Countries;
