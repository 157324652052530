import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import css from "./styles.scss";
import {
  ButtonOutline,
  Submit,
  ButtonGroup
} from "components/Global/Modal/Layout/Buttons";
import TextArea from "react-textarea-autosize";

@CSSModules(css)
class EditNoteModal extends Component {
  constructor(props) {
    super(props);
    this.state = { error: "" };
  }

  @autobind
  onSave(e) {
    e.preventDefault();
    const message = this.textArea.value.trim();

    if (message.length < 1) {
      return this.setState({ error: "Cannot save empty note" });
    }
    const { done, hideModal } = this.props;
    done(message);
    hideModal();
  }

  render() {
    const { hideModal, note } = this.props;
    return (
      <StyleWrapper heading="Edit Note" hideModal={hideModal}>
        <div>
          <TextArea
            ref={el => (this.textArea = el)}
            styleName="textarea"
            defaultValue={note.message}
            autoFocus
          />
          <span style={{ color: "red", fontSize: 12, fontStyle: "italic" }}>
            {this.state.error}
          </span>
          <ButtonGroup>
            <div styleName="buttonLeft">
              <Submit title="Save" onClick={this.onSave} />
            </div>
            <ButtonOutline title="Cancel" onClick={hideModal} />
          </ButtonGroup>
        </div>
      </StyleWrapper>
    );
  }
}

EditNoteModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  note: PropTypes.object.isRequired
};

export default EditNoteModal;
