import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const IconTitle = ({ children, icon }) => (
  <span styleName="container">
    <i className={["material-icons", css.icon].join(" ")}>{icon}</i>
    {children}
  </span>
);

IconTitle.propTypes = {
  icon: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default CSSModules(IconTitle, css);
