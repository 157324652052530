import React from "react";

import { Div } from "components/Base";

const ArrayLayout = ({ components = [], ...props }) => (
  <Div display="row.space-between.center" {...props}>
    {components.map(
      (button, index) =>
        Array.isArray(button) ? (
          <Div display="row.flex-start.center" key={index}>
            {button}
          </Div>
        ) : (
          React.cloneElement(button, { key: index })
        )
    )}
  </Div>
);

export default ArrayLayout;
