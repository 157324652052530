import React, { Component } from "react";
import * as R from "ramda";
import getValue from "utils/value-types/get-value/credentials";
import CredentialsList from "./List";
import CredentialQuantity from "./QuantityInput";

import { getSettings, getMeta } from "ui-kit/Datagrid/utils";

class Credential extends Component {
  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props.value, nextProps.value);
  }

  render() {
    const props = this.props;

    // ensure we're only handling data of type 'credentials'
    if (R.prop("type", props.value) !== "credentials") {
      return null;
    }
    const type = getSettings(props, "selectType");
    const credentials = getMeta(
      props,
      ["eventDetails", "module_settings", "credentials", "credentials"],
      []
    );
    const records = getValue(props.value).records;

    if (type === "quantity-only-input") {
      return (
        <CredentialQuantity quantity={R.path(["0", "quantity"], records)} />
      );
    }
    return <CredentialsList credentials={credentials} records={records} />;
  }
}

export default Credential;
