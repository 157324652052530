import React, { Component } from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";

import {
  ButtonGroup,
  Submit,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";
import { Div } from "components/Base";

import Api from "utils/EventContacts/WebAPIUtils";

import AsyncSelect from "@lennd/react-select/lib/Async";
import { sortBy } from "lodash";
import DatePicker from "components/Event/FormsV2/Modals/FormEditModal/Calendar";

import { connect } from "react-redux";
import { getCredentials } from "redux/modules/user/selectors";

const decorate = connect(state => ({
  userCredentials: getCredentials(state)
}));

class AddContactFormAssignment extends Component {
  state = {
    contact: {
      contactId: "",
      name: ""
    },
    closeDate: null
  };

  onSelectContact = option => {
    const contact = {
      contactId: "",
      name: ""
    };
    if (option) {
      contact.contactId = option.id;
      contact.name = option.name;
    }
    this.setState({
      contact
    });
  };

  onChangeCloseDate = closeDate => this.setState({ closeDate });

  promiseOptions = async term => {
    const contacts = await Api.searchContacts(
      this.props.userCredentials,
      null,
      this.props.eventId,
      term
    );

    return sortBy(
      contacts
        .filter(a => a.name)
        .map(a => ({
          id: a.id,
          name: a.name,
          type: null,
          value: a.id,
          label: a.name
        })),
      a => a.label
    );
  };

  validateForm = () => this.state.contact.name.length;

  handleSubmit = e => {
    e.preventDefault();

    this.props.onSave({
      contactId: this.state.contact.contactId,
      closeDate: this.state.closeDate
    });

    this.props.hideModal();
  };

  render() {
    const { contact, closeDate } = this.state;

    return (
      <StyleWrapper
        hideModal={this.props.hideModal}
        heading="Assign form to a person"
        width={390}
      >
        <form onSubmit={this.handleSubmit}>
          <Div mb={4}>
            <Div fw={3} color="gray7" mb={1}>
              Person:
            </Div>
            <AsyncSelect
              isClearable
              cacheOptions
              defaultOptions
              onChange={this.onSelectContact}
              loadOptions={this.promiseOptions}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: base => ({
                  ...base,
                  zIndex: 9999
                })
              }}
              formatOptionLabel={({ label, type }) => (
                <div>
                  <strong>{label}</strong> {type}
                </div>
              )}
              placeholder="Type to search people..."
              value={{ label: contact.name, value: contact.contactId }}
            />
          </Div>

          <Div mb={4}>
            <Div fw={3} color="gray7" mb={1}>
              Specific due date for this person (Optional):
            </Div>
            <DatePicker value={closeDate} onChange={this.onChangeCloseDate} />
          </Div>

          <Div display="row.flex-start.center">
            <ButtonGroup>
              <Submit title="Save" disabled={!this.validateForm()} />
              <ButtonOutline title="Cancel" onClick={this.props.hideModal} />
            </ButtonGroup>
          </Div>
        </form>
      </StyleWrapper>
    );
  }
}

export default decorate(AddContactFormAssignment);
