import React, { Component } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { createSelector } from "reselect";

import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { VIEWS } from "Orders/List/constants";
import { MANAGE_BY } from "Items/Manage/constants";

import Sidebar, { SidebarWrapper } from "ui-kit/Sidebar/View";

import { noop } from "utils/General";

const getBasePath = createSelector(
  R.prop("orgId"),
  orgId => `/organization/${orgId}`
);

const getLinks = createSelector(
  getBasePath,
  R.pathOr(noop, ["handlers", "requestsPortal"]),
  (base, requestsPortal) => [
    {
      id: "PassesContainer",
      name: "Manage",
      activeRoutes: ["PassesList"],
      links: [
        {
          id: "all-passes",
          to: `${base}/requests/manage`,
          name: "All Items",
          activeRoutes: ["PassesList"],
          activeParams: { viewId: null }
        },
        {
          id: MANAGE_BY.ATTENDEE_LIST,
          to: `${base}/requests/manage/${MANAGE_BY.ATTENDEE_LIST}`,
          name: "By Attendee",
          activeRoutes: ["PassesList"],
          activeParams: { viewId: MANAGE_BY.ATTENDEE_LIST }
        },
        {
          id: MANAGE_BY.GROUP_AFFILIATION,
          to: `${base}/requests/manage/${MANAGE_BY.GROUP_AFFILIATION}`,
          name: "By Group",
          activeRoutes: ["PassesList"],
          activeParams: { viewId: MANAGE_BY.GROUP_AFFILIATION }
        },
        {
          id: MANAGE_BY.ORDER_NUMBER,
          to: `${base}/requests/manage/${MANAGE_BY.ORDER_NUMBER}`,
          name: "By Order",
          activeRoutes: ["PassesList"],
          activeParams: { viewId: MANAGE_BY.ORDER_NUMBER }
        }
      ]
    },
    {
      name: "Invoices",
      id: "Invoices",
      to: `${base}/orders/invoices`,
      activeRoutes: ["OrdersList"],
      activeParams: { view: VIEWS.INVOICES }
    },
    {
      name: "Transactions",
      id: "Transactions",
      to: `${base}/orders/transactions`,
      activeRoutes: ["OrdersList"],
      activeParams: { view: VIEWS.TRANSACTIONS }
    },
    {
      id: "PeopleDivider",
      divide: true
    },
    {
      id: "PortalDivider",
      divide: true
    },
    {
      name: "Open Requests Portal",
      id: "Transactions",
      onClick: () => requestsPortal(),
      external: true
    }
  ]
);

const decorate = connect(state => ({
  orgId: getOrgId(state)
}));

class View extends Component {
  requestsPortal = () => {
    window.open(`/requests/${this.props.orgId}`, "_blank");
  };

  handlers = {
    requestsPortal: this.requestsPortal
  };

  render() {
    return (
      <SidebarWrapper
        instanceId="credentials"
        sidebar={
          <Sidebar
            links={getLinks({ ...this.props, handlers: this.handlers })}
            iniOpenedMenues={["managePortal"]}
            title="Ticket Requests"
          />
        }
      >
        {this.props.children}
      </SidebarWrapper>
    );
  }
}

export default decorate(View);
