import React from "react";
import * as R from "ramda";
import {
  Div,
  QuarterLeftTemplate,
  FontIcon,
  TinyOutlineBox,
  DarkTab,
  PopoverMenu,
  SmallOutlineButton,
  MoreIcon
} from "components/Base";
import Portal from "./Portal";
import GuestList from "./GuestList";
import RecordLayout from "./Layouts/Record";
import SharedRecordLayout from "./Layouts/Shared";

const noop = () => {};

const LeftType = ({ name, icon, color, isActive, isDefault, goToType }) => (
  <Div
    display="row.flex-start.center"
    bg={isActive ? "white" : { default: "inherit", hover: "white" }}
    onClick={goToType}
    py={2}
    px={2}
    brl={1}
  >
    {/*
  // @NOTE: Hide type icon for now
    <FontIcon
      bg={color || "neutral5"}
      color="white"
      pill
      p={1}
      mr={2}
      fs={2}
      flex={0}
    >
      {icon}
    </FontIcon>
  */}
    <Div
      fw={3}
      color={{ default: isActive ? "gray9" : "gray7", hover: "gray9" }}
    >
      {name}
    </Div>
    {isDefault ? <TinyOutlineBox ml={2}>Default</TinyOutlineBox> : null}
  </Div>
);

const Left = ({ types, showAddTypeModal }) => {
  return (
    <Div display="column">
      {types.map(type => (
        <LeftType key={type.id} {...type} />
      ))}
      <Div
        display="row.center.center"
        bg="orange5"
        color="white"
        bra={1}
        mt={3}
        py={2}
        fw={3}
        ml={2}
        mr={5}
        onClick={showAddTypeModal}
      >
        Add Type
      </Div>
    </Div>
  );
};

const Right = ({ selectedType, tabs, activeRecordTypeSection }) => {
  return (
    <Div>
      <Div pt={4}>
        {/* NOTE: Change this to `space-between` to have options on the right*/}
        <Div display="row.flex-start.center" px={4} mb={3}>
          <Div display="row.flex-start.center">
            {selectedType.icon ? (
              <Div
                pill
                bg={selectedType.color || "neutral5"}
                display="row.center.center"
                mr={2}
                flex={0}
                width={37}
                height={37}
              >
                <FontIcon color="white" fs={17}>
                  {selectedType.icon}
                </FontIcon>
              </Div>
            ) : null}
            <Div
              fw={3}
              style={{
                fontSize: 30
              }}
            >
              {selectedType.name}
            </Div>
          </Div>
          <Div ml={3}>
            <PopoverMenu
              Label={({ onClick }) => (
                <SmallOutlineButton onClick={onClick}>
                  Options
                  <MoreIcon fs={3} ml={1} />
                </SmallOutlineButton>
              )}
              menuItems={selectedType.menuItems}
            />
          </Div>
        </Div>
        <Div bb={1} bc="gray2" px={6}>
          {tabs.map(({ routeNames, tabName, onClick, isActive }) => {
            return (
              <DarkTab
                key={tabName}
                px={2}
                fs={3}
                active={isActive}
                onClick={isActive ? noop : onClick}
              >
                {tabName}
              </DarkTab>
            );
          })}
        </Div>
        <Div>
          <Div style={{ minHeight: "100%" }} pb={14}>
            {R.prop(activeRecordTypeSection, {
              eventSettingsModuleTypesPortal: (
                <Portal
                  selectedTypeId={selectedType.id}
                  selectedTypeName={selectedType.name}
                  selectedPermissionSetId={selectedType.permissionSetId}
                />
              ),
              eventSettingsModuleTypesRecordLayout: (
                <RecordLayout
                  selectedLayoutId={selectedType.layoutId}
                  layoutType="record-layout"
                />
              ),
              eventSettingsModuleTypesGuestList: (
                <GuestList selectedTypeId={selectedType.id} />
              ),
              eventSettingsModuleTypesAddRecordLayout: (
                <SharedRecordLayout
                  selectedLayoutId={selectedType.layoutId}
                  layoutType="add-record-layout"
                />
              ),
              eventSettingsModuleTypesCardLayout: (
                <SharedRecordLayout
                  selectedLayoutId={selectedType.layoutId}
                  layoutType="card-layout"
                />
              ),
              eventSettingsModuleTypesReportLayout: (
                <SharedRecordLayout
                  selectedLayoutId={selectedType.layoutId}
                  layoutType="report-layout"
                />
              )
            })}
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

const View = ({
  selectedType,
  types,
  showAddTypeModal,
  typeIsSelected,
  tabs,
  activeRecordTypeSection
}) => {
  return (
    <Div>
      <Div>
        <QuarterLeftTemplate
          wrapperProps={{ px: 8, py: 5, maxWidth: 1400 }}
          leftProps={{ maxWidth: 200 }}
          rightProps={{ bg: "white", brt: 4 }}
          leftChildren={
            <Left
              {...{
                types,
                showAddTypeModal
              }}
            />
          }
          rightChildren={
            typeIsSelected ? (
              <Right {...{ selectedType, tabs, activeRecordTypeSection }} />
            ) : (
              <Div />
            )
          }
        />
      </Div>
    </Div>
  );
};

export default View;
