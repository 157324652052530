import React, { Component } from "react";
import {
  Div,
  Text3,
  TextAreaInput,
  VertColorGrid,
  BigInsetInput
} from "components/Base";

class Overview extends Component {
  handleFieldChange = (value, e) =>
    this.props.onFieldChange({ name: e.target.name, value });
  handleDropdownChange = name => ({ value }) =>
    this.props.onFieldChange({ name, value });
  render() {
    const { onSelectColor, meal } = this.props;
    return (
      <Div display="row">
        <Div display="column" flex={1} pr={5}>
          <Div py={1}>
            <Text3 bold pb={1}>
              Meal Name
            </Text3>
            <BigInsetInput
              name="name"
              width={1}
              fs={3}
              flex={1}
              onChange={this.handleFieldChange}
              continuous
              value={meal.name}
            />
          </Div>
          <Div py={1}>
            <Text3 bold>Description of meal (optional)</Text3>
            <Div pb={1} fs={1}>
              ie: This meal will be served...
            </Div>
            <TextAreaInput
              name="description"
              width={1}
              fs={3}
              autoSize
              onChange={this.handleFieldChange}
              continuous
              value={meal.description}
            />
          </Div>
        </Div>
        <div>
          <Text3 bold pb={1}>
            Select Color
          </Text3>
          <Div p={3} bg="white" bs={2} bra={1}>
            <VertColorGrid
              selectedColor={meal.color}
              onSelectColor={onSelectColor}
            />
          </Div>
        </div>
      </Div>
    );
  }
}

export default Overview;
