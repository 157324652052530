import React from "react";
import {
  Div,
  BigOutlineInput,
  SmallToggle,
  InfoIcon,
  DragIcon,
  Dropdown,
  FontIcon
} from "components/Base";
import IconPicker from "ui-kit/IconPicker";

import {
  getLinkTypes,
  CUSTOM_ICONS,
  MATERIAL_ICONS
} from "Portal/Settings/AddPageModal/constants";
import * as R from "ramda";
import Support from "EventLight/Common/Support";
import Tooltip from "components/Global/Tooltip";

const getIcons = set =>
  R.propOr(
    [],
    set
  )({
    material: MATERIAL_ICONS,
    custom: CUSTOM_ICONS
  });
const getIconPickerProps = ({ iconsType, ...props }) =>
  R.prop(iconsType)({
    material: {
      icons: R.prop("options", props),
      value: R.path(["link", "icon"], props),
      fieldId: R.prop("fieldId", props),
      onChange: R.prop("onChange", props)
    },
    custom: {
      selected: R.path(["link", "icon"], props),
      onChange: R.prop("onChange", props),
      options: R.prop("options", props),
      width: "100%",
      usePortal: true
    }
  });

const resolveIconPicker = {
  material: IconPicker,
  custom: Dropdown
};

const Panel = ({ children }) => (
  <Div
    bg="white"
    bra={1}
    p={5}
    display="column"
    mb={4}
    style={{
      border: "1px solid #eee"
    }}
  >
    {children}
  </Div>
);

const PanelTitle = ({ children, description, right }) => (
  <Div display="row.space-between.center" mb={5}>
    <Div display="column" width={1}>
      <Div fw={3} fs={4} color="black">
        {children}
      </Div>
      {description ? (
        <Div fs={2} color="gray6" mt={1}>
          {description}
        </Div>
      ) : null}
    </Div>

    {right}
  </Div>
);

const FieldWrapper = ({ children }) => <Div mb={5}>{children}</Div>;

const Label = ({ children, tooltip, ...props }) => (
  <Div
    display="row.flex-start.center"
    color="black"
    fw={3}
    fs={3}
    mb={1}
    {...props}
  >
    {children}
    {tooltip ? (
      <Tooltip tooltip={tooltip}>
        <div
          style={{
            marginBottom: "-4px",
            cursor: "pointer"
          }}
        >
          <InfoIcon ml={1} color="gray5" style={{ pointerEvents: "none" }} />
        </div>
      </Tooltip>
    ) : null}
  </Div>
);

const Link = ({
  onUpdateValue,
  iconSet = "material",
  stages = [],
  ...link
}) => (
  <Div
    display="row.flex-start"
    bra={1}
    bg="#fafafa"
    width={1}
    p={2}
    mt={2}
    bc="#eeeeee"
    ba={1}
    py={3}
    style={{
      maxWidth: "500px"
    }}
  >
    <Div
      width={35}
      style={{
        flexShrink: 0
      }}
    >
      <DragIcon />
    </Div>

    <Div display="column" px={5} flex={1}>
      <FieldWrapper>
        <Label>Label</Label>

        <BigOutlineInput
          width={1}
          bg="white"
          value={link.label}
          onChange={value =>
            onUpdateValue({ linkId: link.id, fieldId: "label", value })
          }
          continuous
        />
      </FieldWrapper>

      <FieldWrapper>
        <Label>Icon</Label>
        {React.createElement(
          resolveIconPicker[iconSet] || IconPicker,
          getIconPickerProps({
            iconsType: iconSet,
            link,
            fieldId: link.id,
            onChange: ({ value }) =>
              onUpdateValue({ linkId: link.id, fieldId: "icon", value }),
            options: getIcons(iconSet)
          })
        )}
      </FieldWrapper>

      <FieldWrapper>
        <Label>Type</Label>
        <Dropdown
          selected={link.type}
          onChange={({ value }) =>
            onUpdateValue({ linkId: link.id, fieldId: "type", value })
          }
          options={getLinkTypes({ stages })}
          width="100%"
          usePortal
        />
      </FieldWrapper>

      {link.type === "link" || link.type === "popup_link" ? (
        <FieldWrapper>
          <Label>Link URL</Label>

          <BigOutlineInput
            width={1}
            bg="white"
            value={link.value}
            onChange={value =>
              onUpdateValue({ linkId: link.id, fieldId: "value", value })
            }
            continuous
          />
        </FieldWrapper>
      ) : null}
    </Div>

    <Div
      width={40}
      style={{
        flexShrink: 0
      }}
    >
      <SmallToggle
        active={link.enabled}
        onClick={() =>
          onUpdateValue({
            linkId: link.id,
            fieldId: "enabled",
            value: !link.enabled
          })
        }
      />
    </Div>
  </Div>
);

const FloatingSaveBar = ({ children }) => (
  <Div
    bt={1}
    bc="gray1"
    bg="white"
    style={{
      position: "sticky",
      bottom: 0,
      padding: "10px",
      zIndex: 1,
      borderTop: "1px solid #eee"
    }}
  >
    {children}
  </Div>
);

const Divider = () => (
  <Div
    width={1}
    my={8}
    bg="gray3"
    style={{
      height: "1px"
    }}
  />
);

const Page = ({ children, ...otherProps }) => (
  <Div
    width={1}
    bg="white"
    style={{
      padding: "30px 50px"
    }}
    {...otherProps}
  >
    {children}
  </Div>
);

const Title = ({ icon, title, description, buttons, supportId, ...props }) => (
  <Div
    display="row.space-between.center"
    mb={8}
    pb={5}
    bb={1}
    bc="gray3"
    {...props}
  >
    <Div display="row.flex-start.flex-start">
      <FontIcon fs={7} mr={3} mt={2} color="gray3">
        {icon}
      </FontIcon>
      <Div display="column">
        <Div color="gray8" fs={5} fw={3}>
          {title}
        </Div>
        <Div fs={2} color="gray7">
          {description}
        </Div>
        <Support
          small={true}
          id={supportId}
          style={{
            marginTop: "5px"
          }}
        />
      </Div>
    </Div>

    {buttons}
  </Div>
);

export {
  Panel,
  PanelTitle,
  FieldWrapper,
  Label,
  Link,
  FloatingSaveBar,
  Divider,
  Page,
  Title
};
