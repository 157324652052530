import React from "react";

import { connect } from "react-redux";

import { getters, actions } from "Orders/TicketsPasses";

import MiniModalWrapper from "Orders/Common/View/MiniModalWrapper";

import QuickAssignPricesFooter from "./QuickAssignPricesFooter";

import QuickAssignPricesBody from "./QuickAssignPricesBody";

const decorate = connect(
  state => ({
    modalVisible: getters.quickAssignModalVisible(state)
  }),
  {
    hideModal: () => actions.setQuickAssignModalVisible(false)
  }
);

const QuickAssignPricesModal = ({ hideModal, modalVisible }) => (
  <MiniModalWrapper
    showModal={modalVisible}
    title="Quick Assign Prices"
    width={600}
    height={525}
    hideModal={hideModal}
  >
    <QuickAssignPricesBody />
    <QuickAssignPricesFooter />
  </MiniModalWrapper>
);

export default decorate(QuickAssignPricesModal);
