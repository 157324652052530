import React, { Component } from "react";
import PropTypes from "prop-types";
import { sum, values } from "ramda";
import CredentialCard from "./Credential";
import { MediumTextButton, Text2, Text3, Text1, Div } from "components/Base";
import { datesInRanges } from "./utils";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";

const ONLY_ALLOW_ITEMS_WITH_VALID_DATES = false;

const Group = ({ name, items = [] }) => (
  <Div p={3} bg="nuetral2">
    <Div display="row.space-between.center" pb={2}>
      <Text3 bold uppercase>
        {name}
      </Text3>
      <Text1 bold uppercase color="gray6">
        How Many?
      </Text1>
    </Div>
    {items.map(i => (
      <CredentialCard key={i.id} item={i} />
    ))}
  </Div>
);

const CredentialsPicker = ({ groups }) => (
  <Div>
    {groups.map(g => (
      <Group key={g.id} {...g} />
    ))}
  </Div>
);

const credentialsMap = ({ credentials, assignments = [], variantLimits }) =>
  credentials.reduce((list, c) => {
    const assignment = assignments.find(a => a.id === c.id);

    const countOfAvailable = c.count_of_available;
    let countOfRemaining = Math.max(0, countOfAvailable - c.count_of_assigned);

    // if a variant limit is set, ensure it serves as the ceiling for remaining count
    if (variantLimits[c.id]) {
      if (
        assignment &&
        assignment.quantity &&
        assignment.quantity >= variantLimits[c.id]
      ) {
        countOfRemaining = 0;
      }
    }

    if (assignment && typeof assignment.quantity !== "undefined") {
      list[c.id] = {
        selected: assignment.quantity,
        remaining:
          typeof assignment.updatedRemaining !== "undefined"
            ? assignment.updatedRemaining
            : typeof assignment.updatedQuantity !== "undefined"
              ? Math.max(0, countOfRemaining - assignment.updatedQuantity)
              : countOfRemaining,
        issued: assignment.issued
      };
    } else {
      list[c.id] = {
        selected: 0,
        remaining: countOfRemaining,
        issued: 0
      };
    }
    return list;
  }, {});

/*
  // @NOTE: A known gap is if opening popover with some assignments -- then removing those assignments
  -- then opening popover again, the limit will be whatever the parent limit is and will not add what was
  removed back to the parent limit.
*/
class CredentialsController extends Component {
  state = credentialsMap(this.props);

  updateCount = (id, newCount) => {
    // don't allow selected count to go below the number of issued
    const remaining = this.props.variantLimits[id]
      ? Math.min(this.props.variantLimits[id], this.state[id].remaining)
      : this.state[id].remaining;
    this.setState(state => {
      if (remaining === 0 && newCount > state[id].selected) {
        return state;
      }
      state[id] = {
        ...state[id],
        selected: Math.max(state[id].issued, newCount),
        remaining: Math.max(0, remaining - newCount + state[id].selected)
      };
      return state;
    });
  };

  handleClose = () => {
    this.props.onSave({
      typeId: CREDENTIAL_TYPE_ID,
      values: Object.keys(this.state)
        .filter(key => this.state[key].selected > 0)
        .map(key => ({
          typeId: CREDENTIAL_TYPE_ID,
          quantity: this.state[key].selected,
          issued: this.state[key].issued,
          updatedQuantity: this.state[key].selected,
          updatedRemaining: this.state[key].remaining,
          id: key
        }))
    });
    this.props.onClose();
  };

  render() {
    const { credentials, eventDays } = this.props;
    const eventDaysDates = datesInRanges(eventDays);

    const availableCredentials = credentials
      .filter(c => {
        if (!ONLY_ALLOW_ITEMS_WITH_VALID_DATES) {
          return true;
        }
        if (!c.dates_available.length) {
          return true;
        }
        return c.dates_available.some(d => eventDaysDates.includes(d));
      })
      .map(({ name, group, background_color, id }) => ({
        name,
        id,
        background_color,
        group,
        variants: [
          {
            inventory_quantity: this.props.variantLimits[id]
              ? Math.min(this.props.variantLimits[id], this.state[id].remaining)
              : this.state[id].remaining,
            quantity: this.state[id].selected,
            updateVariantQuantity: val => this.updateCount(id, val),
            decrementQuantity: () =>
              this.updateCount(id, this.state[id].selected - 1),
            incrementQuantity: () =>
              this.updateCount(id, this.state[id].selected + 1)
          }
        ]
      }));

    const groupedCredentials = values(
      availableCredentials.reduce((list, credential) => {
        if (!list[credential.group.id]) {
          list[credential.group.id] = {
            ...credential.group,
            items: []
          };
        }
        list[credential.group.id].items.push(credential);
        return list;
      }, {})
    );

    const quantity = sum(
      Object.keys(this.state).map(key => this.state[key].selected)
    );

    return (
      <Div>
        <Div style={{ maxHeight: 350, overflowY: "auto" }} bb={1} bc="gray4">
          <CredentialsPicker groups={groupedCredentials} />
        </Div>
        <Div display="row.space-between.center" p={3}>
          <Text2 bold>Quantity: {quantity}</Text2>
          <MediumTextButton onClick={this.handleClose}>DONE</MediumTextButton>
        </Div>
      </Div>
    );
  }
}

CredentialsController.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

export default CredentialsController;
