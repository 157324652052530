import React from "react";
import { Div } from "components/Base";
import * as R from "ramda";
import { withRouter } from "react-router";
import css from "./styles.scss";
import CSSModules from "react-css-modules";

const decorator = R.compose(
  withRouter,
  CSSModules(css)
);

const Body = ({ router, ...otherProps }) => (
  <Div {...otherProps} styleName="overview-and-resources">
    <Div styleName="overview-and-resources_title">
      #PGAChamp Overview & Resources
    </Div>
    <Div styleName="overview-and-resources_social">
      <a href="https://www.facebook.com/pgachampionship" target="_blank">
        Facebook
      </a>
      |
      <a href="https://twitter.com/pgachampionship" target="_blank">
        Twitter
      </a>
      |
      <a href="https://www.instagram.com/pgachampionship/" target="_blank">
        Instagram
      </a>
    </Div>
    <Div styleName="overview-and-resources_details-container">
      <Div styleName="overview-and-resources_section-container">
        <Div styleName="overview-and-resources_section-title">Overview</Div>
        <Div styleName="overview-and-resources_section-list">
          <ul>
            <li>
              <span>102nd PGA Championship</span> <br />
              August 6-9, 2020 <br />
              TPC Harding Park <br />
              San Francisco, California
            </li>
            <li>
              <span>Defending Champion:</span> Brooks Koepka
            </li>
            <li>
              <span>Yardage:</span> 7,251
            </li>
            <li>
              <span>Par:</span> 70
            </li>
            <li>
              <span>Host PGA Professional:</span> Tom Smith, PGA
            </li>
            <li>
              <span>Course Designers:</span> Sam Whiting & Willie Watson
            </li>
            <li>
              <span>Purse:</span> $11 million ($1.98 to PGA Champion)
            </li>
            <li>
              <span>Playoff Format:</span> In the event of a tie for first place
              after 72 holes, there will be a three-hole aggregate score playoff
              on holes 16, 17 and 18. If a tie still remains, there will be a
              hole-by-hole playoff beginning on No. 18, and, if necessary, on to
              holes 16, 17 and 18 repeated until a winner is determined.
            </li>
          </ul>
        </Div>
      </Div>
      <Div styleName="overview-and-resources_section-container">
        <Div styleName="overview-and-resources_section-title">
          Championship Week Resources
        </Div>
        <Div styleName="overview-and-resources_section-list">
          <ul>
            <li>
              <a
                href="https://drive.google.com/file/d/1IPP-R1nPHn6DbqIvBgIVmLNH6YpjQZqm/view"
                target="_blank"
              >
                Field
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://drive.google.com/file/d/17-tLUMznZSxHxJkrq4KMbtgVqIk5YEAR/view"
              >
                Eligibility
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://drive.google.com/drive/folders/1OQzBOPZA5b4hpB16mwvaVWmzRxWlET0v?usp=sharing"
              >
                Groupings & Tee Times
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://drive.google.com/drive/folders/1AmcZPxDjB2sbbrT9EnZUSu5vKZxSE6Wu?usp=sharing"
              >
                Hole Locations
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://drive.google.com/drive/folders/1mfwCbuztrSbGAPsW9u62OzdF4tXk1jmF?usp=sharing"
              >
                Yardages
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://drive.google.com/file/d/1ADgIwaarJvBTfg73cV4Bgi-EdtmuJ3D2/view?usp=sharing"
              >
                Scorecard
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://drive.google.com/file/d/1pKVGtkxHgESzpKiIFmZcKLLv-Y5LvUHP/view"
              >
                Media Guide
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://drive.google.com/file/d/1pbwMXoMBkt3xgHDXOLEmcPVptZWXZUl_/view"
              >
                Prize Money Breakdown
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://drive.google.com/file/d/1879U1e0Hg43M1FwWhzFi0sxUNeT6KZTP/view?usp=sharing"
              >
                Grass Types & Heights
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://drive.google.com/file/d/1avSA_sqxUVCMJtzPBz9bjPJQRcEiROU0/view?usp=sharing"
              >
                PGA Rules Assignments
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://drive.google.com/drive/folders/1NbgYyFZKfHpFT6k_ZQ9A-0KFgUPHx6io?usp=sharing"
              >
                Weather
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://www.pgachampionship.com/how-to-watch"
              >
                U.S. Broadcast Schedule
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://drive.google.com/drive/folders/1BRQoVoBOM2hQE16kowrZK28oAeSFsCB0?usp=sharing"
              >
                Releases, Fact Sheets & Bios
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://drive.google.com/drive/folders/1FuRVij--TLPWI9na7N_dK0vhzCy1luzS"
              >
                SF Travel Assets
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://drive.google.com/drive/folders/1Nf84Pb1WXKeA_uo1oGCy_S7fuOrIujTd"
              >
                Logos
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="https://drive.google.com/file/d/1unCqOBeCfLw5jmJuzx8x7V8sTXpMz-YO/view"
              >
                Coverage Regulations
              </a>
            </li>
          </ul>
        </Div>
      </Div>
    </Div>
  </Div>
);

export default decorator(Body);
