import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";
import * as R from "ramda";

import { withRouter } from "react-router";
import {
  credentialsRequests,
  cateringRequests,
  assetRequests
} from "redux/modules/portal/reports/selectors";
import { settings } from "redux/modules/portal/settings/selectors";

import {
  getAssetReport,
  getItemAssetReport,
  getCateringReport,
  getCredentialsReport
} from "redux/modules/portal/reports/actions";

import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

function mapStateToProps(state) {
  const canViewInventory = selectFeatureFlag(flags.CAN_VIEW_INVENTORY.NAME)(
    state
  );
  return {
    settings: settings(state),
    canViewInventory,
    hasAssetsReport: canViewInventory
      ? R.compose(
          R.length,
          R.keys,
          R.prop("counts")
        )(assetRequests(state))
      : assetRequests(state).length,
    hasCredentialsReport: R.compose(
      R.length,
      R.keys,
      R.prop("counts")
    )(credentialsRequests(state)),
    hasCateringReport: R.compose(
      R.length,
      R.keys,
      R.prop("counts")
    )(cateringRequests(state))
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCredentialsReport,
      getCateringReport,
      getAssetReport,
      getItemAssetReport
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Controller));
