import * as R from "ramda";
import React from "react";
import EditorBase from "./TableConnector";
import Cell from "./CellBase";
import Formatter from "ui-kit/Datagrid/View/Formatters/Catering";
import Editor from "components/Global/Editors/CateringEditor";

import { getMeta, ep, getRowMetadata } from "ui-kit/Datagrid/utils";

export default class CateringEditor extends EditorBase {
  render() {
    const props = ep(this.props);
    const { row } = props;

    const catering = getMeta(props, [
      "eventDetails",
      "module_settings",
      "catering"
    ]);

    const editorProps = {
      selectedDays: R.propOr([], "selected_days", catering),
      meals: R.propOr([], "meals", catering),
      mealDays: R.propOr([], "meal_days", catering),
      column: props.column,
      rowMetaData: getRowMetadata(props)
    };

    return (
      <Cell
        ref="editor"
        formatter={<Formatter value={this.state} row={row} />}
        editor={
          <Editor
            {...editorProps}
            value={this.state}
            onChange={this.handleChange}
          />
        }
      />
    );
  }
}

CateringEditor.propTypes = EditorBase.propTypes;
