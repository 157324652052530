import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "Portal/PortalPagePGA/NotificationModal/constants";

const iniState = {
  isModalVisible: false,
  phoneNumber: "",
  status: null,
  changePhone: null,
  section: "",
  id: null
};

export const model = createHandlers({
  iniState,
  reducers: {
    init: (_, { payload: { phone } }) => {
      return {
        phoneNumber: phone || ""
      };
    },
    updateNotification: (
      _,
      { payload: { hasNumber, changeNumber, section, id, status } }
    ) => {
      if (hasNumber && !changeNumber) {
        return {
          section,
          id
        };
      }
      return {
        isModalVisible: true,
        changePhone: changeNumber,
        status,
        section,
        id
      };
    },
    save: R.identity,
    hideModal: () => ({
      isModalVisible: false,
      phoneNumber: "",
      status: null,
      changePhone: null,
      section: "",
      id: null
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
