import React from "react";

import {
  Div,
  MediumClearButton,
  LeftArrowIcon,
  Dropdown
} from "components/Base";

import {
  Toolbar,
  Sidebar,
  Separator,
  InputGroup,
  InputLabel,
  Input
} from "components/Event/FormsV2/Form/Views/V3_Edit/Shared/Shared";

const View = ({
  field,
  updateBlockName,
  updateBlockDescription,
  updateMethod,
  fulfillmentOptions,
  onReturn
}) => (
  <Sidebar>
    <Toolbar>
      <LeftArrowIcon sizeWFS={4} mr={3} onClick={onReturn} />
      <Div fs={4} fw={3} color="gray7" flex={1}>
        Fulfillment Information
      </Div>
      <MediumClearButton onClick={onReturn} color="primary5">
        Done
      </MediumClearButton>
    </Toolbar>

    <Div py={4} px={6}>
      <InputGroup>
        <InputLabel>Name</InputLabel>
        <Input
          value={field ? field.name : ""}
          onChange={value => updateBlockName(value)}
        />
      </InputGroup>
      <InputGroup>
        <InputLabel>Description</InputLabel>
        <Input
          value={field ? field.settings.description || "" : ""}
          onChange={value => updateBlockDescription(value)}
        />
      </InputGroup>
      <Separator />

      <InputGroup>
        <InputLabel>Fulfillment Method</InputLabel>
        <Dropdown
          selected={field ? field.settings.method : null}
          onChange={({ value }) => updateMethod(value)}
          options={fulfillmentOptions}
          usePortal
        />
      </InputGroup>

      {/*
    {field.settings.method === "pickup" ? (
      <Div>
        <ToggleGroup>
          <Div>
            <InputLabel>Show Pickup Date</InputLabel>
            <InputSubtext>
              If enabled, allow user to select pickup date
            </InputSubtext>
          </Div>
          <Div>
            <SmallToggle
              active={field.settings.showPickupAtField}
              onClick={() => {
                field.updateField({
                  settings: {
                    ...field.settings,
                    showPickupAtField: !field.settings.showPickupAtField
                  }
                });
              }}
            />
          </Div>
        </ToggleGroup>
      </Div>
    ) : null}
    */}
    </Div>
  </Sidebar>
);

export default View;
