import React, { Component } from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import autobind from "autobind-decorator";
import TagsInput from "react-tagsinput";
import Textarea from "react-textarea-autosize";

const styles = {
  rowBottom: {
    padding: "30px 34px 30px 34px",
    borderTop: "1px solid #e2e2e2",
    background: "rgba(231,231,231,0.40)"
  },
  rowBottomTitle: {
    fontWeight: 600,
    fontSize: 18,
    color: "#494949",
    marginBottom: 15
  },
  rowActions: {
    padding: "12px 34px",
    borderTop: "1px solid #e2e2e2",
    background: "#F6F6F6",
    display: "flex",
    flexDirection: "row"
  },
  inputWrapper: {
    borderBottom: "1px solid #c5c5c5",
    position: "relative",
    marginBottom: 18
  },
  inputLabel: {
    fontSize: 18,
    color: "#494949",
    position: "absolute",
    left: 0,
    top: 3
  },
  inputHint: {
    fontSize: 14,
    color: "#A5A5A5",
    position: "absolute",
    right: 0,
    top: 5
  },
  inputError: {
    marginTop: 10,
    fontSize: 14,
    color: "#903737"
  },
  input: {
    border: 0,
    fontSize: 15,
    paddingBottom: 11,
    paddingTop: 5,
    outline: "none",
    paddingLeft: 0,
    background: "none",
    display: "block",
    width: "100%",
    fontWeight: "bold"
  },
  textarea: {
    border: 0,
    fontSize: 15,
    outline: "none",
    padding: "5px 5px 11px 5px",
    background: "none",
    display: "block",
    width: "100%"
  },
  checkboxWrapper: {
    display: "flex",
    marginTop: 15
  },
  checkboxLabel: {
    fontWeight: 600,
    fontSize: 16,
    color: "#8B8B8B"
  },
  submit: {
    width: 177,
    height: 40,
    background: "#00CC83",
    borderRadius: 4,
    color: "#ffffff",
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: 15,
    letterSpacing: "1.15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    ":hover": {
      background: "#02BE7B"
    }
  },
  cancel: {
    width: 130,
    height: 40,
    borderRadius: 4,
    color: "#989898",
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: 15,
    letterSpacing: "1.15px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    ":hover": {
      color: "#888888"
    }
  }
};

@Radium
export default class Email extends Component {
  static propTypes = {
    hideModal: PropTypes.func.isRequired,
    handleEmailsChange: PropTypes.func.isRequired,
    handleMessageChange: PropTypes.func.isRequired,
    handleSendToMeChange: PropTypes.func.isRequired,
    handleSend: PropTypes.func.isRequired,
    emails: PropTypes.array.isRequired,
    message: PropTypes.string.isRequired,
    sendToMe: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
  }

  @autobind
  _renderEmailsInput(props) {
    const { onChange, value, ...other } = props;
    let placeholder;
    if (!this.props.emails.length) {
      placeholder = "To:";
    }
    return (
      <input
        className="styled"
        tabIndex="1"
        placeholder={placeholder}
        type="text"
        onChange={onChange}
        value={value}
        {...other}
      />
    );
  }

  @autobind
  _handleCancel() {
    this.props.hideModal();
  }

  render() {
    let inputEmailsHint;
    if (!this.props.emails.length) {
      inputEmailsHint = (
        <div style={styles.inputHint}>Enter email addresses here</div>
      );
    }

    return (
      <div>
        <div style={styles.rowBottom}>
          <div style={styles.rowBottomTitle}>Email</div>
          <div style={styles.inputWrapper}>
            {inputEmailsHint}
            <TagsInput
              addOnBlur
              renderInput={this._renderEmailsInput}
              value={this.props.emails}
              onChange={this.props.handleEmailsChange}
              addKeys={[13, 188, 32]}
              inputProps={{
                className: "react-tagsinput-input",
                placeholder: ""
              }}
            />
          </div>

          {/*
          <div style={styles.inputWrapper}>
            <div style={styles.inputHint}>Optional*</div>
            <input
              placeholder='Subject'
              type='text'
              value={this.props.subject}
              style={styles.input}
              onChange={this.props.handleSubjectChange}/>
          </div>
          */}

          <div style={styles.inputWrapper}>
            <div style={styles.inputHint}>Optional*</div>
            <Textarea
              tabIndex="2"
              placeholder="Your message"
              style={styles.textarea}
              value={this.props.message}
              onChange={this.props.handleMessageChange}
              onBlur={this._onBlur}
            />
          </div>

          {/*
          <div style={styles.checkboxWrapper}>
            <Checkbox
              tabIndex='3'
              label='Send a copy to myself'
              labelStyle={styles.checkboxLabel}
              checked={this.props.sendToMe}
              onCheck={this.props.handleSendToMeChange}
            />
          </div>
          */}
        </div>

        {/* row: actions */}
        <div style={styles.rowActions}>
          <div style={styles.submit} key="send" onClick={this.props.handleSend}>
            Send
          </div>
          <div style={styles.cancel} key="cancel" onClick={this._handleCancel}>
            Cancel
          </div>
        </div>
      </div>
    );
  }
}
