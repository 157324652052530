import PropTypes from "prop-types";
import React, { Component } from "react";
import * as R from "ramda";
import uuid from "node-uuid";
import moment from "moment";
import {
  groupBy,
  isEqual,
  get,
  cloneDeep,
  filter,
  has,
  difference,
  without,
  find,
  uniq
} from "lodash";
import { MEAL_TYPE_ID } from "utils/item-types";
import moveItemInArray from "utils/move-item-in-array";

import AddItemBlockModal from "components/Event/Settings/Credentials/Modals/AddItemBlock";
import ItemBlockModal from "components/Event/Settings/Credentials/Modals/ItemBlock";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import MealModal from "components/Event/Settings/Catering/Modals/Meal";
import View from "./View";
import HelpArticleUrls from "utils/help-article-urls";

const mapIndexed = R.addIndex(R.map);

class Controller extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      loading: true,
      finished: false,
      stepIndex: 0,
      settings: cloneDeep(this.getSettings(props.eventDetails, props.items)),
      activeTabValue: props.route.name
    };
  }

  componentDidMount() {
    Promise.all([this.getMealGroups(), this.getItemBlocks()]).then(() => {
      this.setState({ loading: false });
    });
  }

  componentDidUpdate(oldProps) {
    if (
      !isEqual(this.props.items, oldProps.items) ||
      !isEqual(this.props.eventDetails, oldProps.eventDetails)
    ) {
      this.setState({
        settings: cloneDeep(
          this.getSettings(this.props.eventDetails, this.props.items)
        )
      });
    }
  }

  getMealGroups = () =>
    this.props.getItemGroupsByEventAndType(
      this.props.eventDetails.id,
      MEAL_TYPE_ID
    );

  getItemBlocks = () =>
    this.props.getItemBlocksByEventAndType(
      this.props.eventDetails.id,
      MEAL_TYPE_ID
    );

  getFormattedItems = () => this.state.settings.meals;

  getFormattedItemVariants = () => {
    const itemsByItemId = this.props.items.reduce((a, b) => {
      a[b.id] = b;
      return a;
    }, {});
    const mealsDaysByMealId = groupBy(this.state.settings.mealDays, "mealId");
    return Object.keys(mealsDaysByMealId)
      .filter(mealId => itemsByItemId[mealId])
      .map(mealId => {
        const item = itemsByItemId[mealId];
        return {
          id: item.id,
          variants: mealsDaysByMealId[mealId].map((mealDay, index) => ({
            itemId: item.id,
            order: index,
            name: moment(mealDay.dayId).format("dddd, MMM DD"),
            values: item.options.reduce((values, option) => {
              values.push({
                optionId: option.id,
                value: moment(mealDay.dayId).format("dddd, MMM DD")
              });
              return values;
            }, []),
            rules: [
              {
                pattern: "is_valid_for",
                key: "valid_date",
                valueType: "date",
                value: moment(mealDay.dayId).format("YYYY-MM-DD")
              }
            ]
          }))
        };
      });
  };

  getMeals = items =>
    items.map(item => ({
      id: item.id,
      value: item.name,
      color:
        item.background_color && item.background_color.length
          ? item.background_color
          : null,
      item
    }));

  getMealDays = items =>
    items.reduce((list, item) => {
      item.variants.forEach(variant => {
        variant.rules.forEach(rule => {
          list.push({
            mealId: item.id,
            dayId: rule.value,
            item
          });
        });
      });
      return list;
    }, []);

  getSettings = (details, items) => ({
    selectedDays: get(details, "module_settings.catering.selected_days", []),
    meals: this.getMeals(items),
    mealDays: get(details, "module_settings.catering.meal_days", []),
    dietaryRestrictions: get(
      details,
      "module_settings.catering.dietary_restrictions",
      []
    )
  });

  setTabValue = activeTabValue => this.setState({ activeTabValue });

  saveSettings = async () => {
    this.setState({
      saving: true
    });

    await this.props.updateCateringSettings({
      eventId: this.props.params.eventId,
      selectedDays: this.state.settings.selectedDays,
      meals: this.state.settings.meals,
      mealDays: this.state.settings.mealDays,
      dietaryRestrictions: this.state.settings.dietaryRestrictions,
      items: this.getFormattedItems(),
      variants: this.getFormattedItemVariants(),
      managerOverridePassword: this.state.settings.managerOverridePassword
    });

    await Promise.all([
      this.props.getItemGroupsByEventAndType(
        this.props.eventDetails.id,
        MEAL_TYPE_ID
      ),
      // @NOTE: Use this if we find that we need to refresh event setting to grab latest catering updates
      this.props.getEvent(this.props.eventDetails.id)
    ]);

    return true;
  };

  goBackToStart = () => this.setState({ stepIndex: 0, finished: false });

  handleNext = async () => {
    await this.saveSettings();

    const { stepIndex } = this.state;
    this.setState({
      stepIndex: stepIndex + 1,
      finished: stepIndex >= 3,
      saving: false
    });
  };

  handlePrev = async () => {
    await this.saveSettings();

    const { stepIndex } = this.state;
    if (stepIndex > 0) {
      this.setState({ stepIndex: stepIndex - 1, saving: false });
    }
  };

  handleDayClick = ydm => {
    this.setState(state => {
      if (this.isDaySelected(ydm)) {
        state.settings.selectedDays = without(state.settings.selectedDays, ydm);
      } else {
        state.settings.selectedDays.push(ydm);
      }
      return state;
    });
  };

  handleGroupSelectAll = id => {
    this.setState(state => {
      const group = find(this.props.eventDetails.date_groups, { id });
      group.days.forEach(day => {
        state.settings.selectedDays.push(day);
      });
      state.settings.selectedDays = uniq(state.settings.selectedDays);
      return state;
    });
  };

  handleGroupDeselectAll = id => {
    this.setState(state => {
      const group = find(this.props.eventDetails.date_groups, { id });
      state.settings.selectedDays = difference(
        state.settings.selectedDays,
        group.days
      );
      return state;
    });
  };

  isDaySelected = ydm => this.state.settings.selectedDays.includes(ydm);

  buildDayMap = (startDate, endDate, dayGroups) => {
    const map = {};
    dayGroups.forEach(group => {
      group.days.forEach(day => {
        map[day] = {
          color: group.color,
          isSelected: this.isDaySelected(day)
        };
      });
    });
    for (
      let m = moment(startDate).utc();
      m.isSameOrBefore(endDate);
      m.add(1, "days")
    ) {
      const day = m.format("YYYY-MM-DD");
      if (!has(map, day)) {
        map[day] = {
          color: "#333333",
          isSelected: this.isDaySelected(day)
        };
      }
    }
    return map;
  };

  handleUpdateOption = (key, index, option) => {
    this.setState(state => {
      if (index === -1) {
        if (option.value.length > 0) {
          state.settings[key].push({
            ...option,
            id: uuid.v4(),
            create: true
          });
        }
      } else {
        state.settings[key][index] = option;
      }
      return state;
    });
  };

  handleRemoveOption = (key, index) => {
    this.setState(state => {
      if (key === "meals") {
        state.settings.mealDays = state.settings.mealDays.filter(
          m => m.mealId !== state.settings[key][index].id
        );
      }
      state.settings[key] = state.settings[key].filter(
        r => r.id !== state.settings[key][index].id
      );
      return state;
    });
  };

  handleMealDayChange = (mealId, dayId) => {
    this.setState(state => {
      if (find(state.settings.mealDays, { mealId, dayId })) {
        state.settings.mealDays = filter(state.settings.mealDays, record => {
          if (record.mealId === mealId && record.dayId === dayId) {
            return false;
          }
          return true;
        });
      } else {
        state.settings.mealDays.push({ mealId, dayId });
      }
      return state;
    });
  };

  isMealDaySelected = (mealId, dayId) => {
    if (find(this.state.settings.mealDays, { mealId, dayId })) {
      return true;
    }
    return false;
  };

  /* meals */
  addMeal = data => {
    this.props
      .addMeal({
        ...data,
        eventId: this.props.eventDetails.id,
        defaultDays: this.state.settings.selectedDays,
        item: {
          ...data.item,
          order: this.state.settings.meals.length
        }
      })
      .then(() => {
        this.props.showSnackbar({ message: "Meal added", action: "OK" });
        return this.getMealGroups();
      });
  };

  updateMeal = data => {
    this.props.updateItem(data).then(() => {
      this.props.showSnackbar({ message: "Meal updated", action: "OK" });
      return this.getMealGroups();
    });
  };

  deleteMeal = itemId => {
    this.props
      .deleteItem({
        itemId
      })
      .then(() => {
        this.props.showSnackbar({ message: "Meal deleted", action: "OK" });
        return this.getMealGroups();
      });
  };

  moveMealUp = (groupId, currentPosition) => {
    const meals = this.props.itemGroups.find(g => g.id === groupId).items;
    if (currentPosition === 0) return;
    this.props
      .bulkUpdateItems({
        eventId: this.props.eventDetails.id,
        bulk: true,
        items: moveItemInArray(meals, currentPosition, currentPosition - 1).map(
          (g, order) => ({
            id: g.id,
            order
          })
        )
      })
      .then(() => this.getMealGroups());
  };

  moveMealDown = (groupId, currentPosition) => {
    const meals = this.props.itemGroups.find(g => g.id === groupId).items;
    if (currentPosition === meals.length - 1) return;
    this.props
      .bulkUpdateItems({
        eventId: this.props.eventDetails.id,
        bulk: true,
        items: moveItemInArray(meals, currentPosition, currentPosition + 1).map(
          (g, order) => ({
            id: g.id,
            order
          })
        )
      })
      .then(() => this.getMealGroups());
  };

  /* modals */
  showMealModal = (id, clone = false) => {
    this.props.showModal({
      content: (
        <MealModal
          onDone={id && !clone ? this.updateMeal : this.addMeal}
          mealId={id}
          clone={clone}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showCloneMealModal = id => this.showMealModal(id, true);

  showAddItemBlockModal = () => {
    this.props.showModal({
      content: (
        <AddItemBlockModal
          onDone={this.getItemBlocks}
          selectedTypeId={MEAL_TYPE_ID}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  goToItemBlocksDoc = () => {
    window.open(HelpArticleUrls.ITEM_BLOCKS, "_blank");
  };

  showItemBlockModal = id => {
    this.props.showModal({
      content: <ItemBlockModal onDone={this.getItemBlocks} itemBlockId={id} />,
      wrapper: ModalWrapper
    });
  };

  goToCatering = () => {
    this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/catering/manage/all-requests`
    });
  };

  goToMeals = () => {
    this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/settings/catering`
    });
    this.setTabValue("editEventSettingsCatering");
  };

  goToManagerOverride = () => {
    this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/settings/catering/manager-override`
    });
    this.setTabValue("editEventSettingsCateringManagerOverride");
  };

  goToApprovals = () => {
    this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/settings/catalog/type/${MEAL_TYPE_ID}/approvers`
    });
  };

  updateManagerOverride = async managerOverridePassword => {
    await this.props.updateCateringSettings({
      eventId: this.props.eventDetails.id,
      managerOverridePassword
    });

    return await this.props.getEvent(this.props.eventDetails.id);
  };

  render() {
    const { eventDetails, itemGroups, itemBlocks } = this.props;
    const {
      saving,
      loading,
      finished,
      stepIndex,
      settings,
      activeTabValue
    } = this.state;
    const blocks = mapIndexed(block => {
      return {
        ...block,
        count: block.items.length,
        onClick: () => this.showItemBlockModal(block.id)
      };
    }, itemBlocks);

    const tabs = [
      ["editEventSettingsCatering", "Meals", this.goToMeals],
      [
        "editEventSettingsCateringApprovalWorkflows",
        "Approval Workflows",
        this.goToApprovals
      ],
      [
        "editEventSettingsCateringManagerOverride",
        "Manager Override Code",
        this.goToManagerOverride
      ]
    ];
    return (
      <View
        {...{
          loading,
          saving,
          stepIndex,
          eventDetails,
          onManagerOverrideSave: this.updateManagerOverride,
          managerOverrideIsActive: get(
            eventDetails,
            "module_settings.catering.manager_override_is_active",
            false
          ),
          dayMap: this.buildDayMap(
            eventDetails.date_from,
            eventDetails.date_to,
            eventDetails.date_groups
          ),
          selectedDays: settings.selectedDays,
          meals: settings.meals.map((meal, idx) => ({
            ...meal,
            onMealClick: () => this.showMealModal(meal.id),
            onEdit: () => this.showMealModal(meal.id),
            onClone: () => this.showCloneMealModal(meal.id),
            onDelete: () => this.deleteMeal(meal.id),
            moveMealUp:
              idx === 0
                ? undefined
                : () => this.moveMealUp(itemGroups[0].id, idx),
            moveMealDown:
              idx === settings.meals.length - 1
                ? undefined
                : () => this.moveMealDown(itemGroups[0].id, idx)
          })),
          showAddItemBlockModal: this.showAddItemBlockModal,
          goToItemBlocksDoc: this.goToItemBlocksDoc,
          blocks,
          mealDays: settings.mealDays,
          dietaryRestrictions: settings.dietaryRestrictions,
          handleDayClick: this.handleDayClick,
          handleGroupSelectAll: this.handleGroupSelectAll,
          handleGroupDeselectAll: this.handleGroupDeselectAll,
          handleNext: this.handleNext,
          handlePrev: this.handlePrev,
          handleUpdateOption: this.handleUpdateOption,
          handleRemoveOption: this.handleRemoveOption,
          handleMealDayChange: this.handleMealDayChange,
          isMealDaySelected: this.isMealDaySelected,
          finished,
          goBackToStart: this.goBackToStart,
          showAddMealModal: () => this.showMealModal(),
          activeTabValue,
          tabs,
          goToCatering: this.goToCatering,
          setTabValue: this.setTabValue
        }}
      />
    );
  }
}

Controller.propTypes = {
  dispatch: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  saveCateringSettings: PropTypes.func.isRequired,
  eventDetails: PropTypes.object.isRequired,
  addMeal: PropTypes.func.isRequired
};

export default Controller;
