import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import autobind from "autobind-decorator";
import Select from "../Select";
import { get } from "lodash";
import { Link } from "react-router";
import Popover from "@lennd/material-ui/Popover";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import CanUserDo from "components/Global/Security/CanUserDo";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import {
  getOrganizationPermissionProfiles,
  getEventPermissionProfiles
} from "redux/modules/permissions/permission-profiles/actions";
import { permissionProfiles } from "redux/modules/permissions/permission-profiles/selectors";

export const PermissionOption = CSSModules(
  ({ title, description, selected, ...other }) => (
    <div
      styleName={selected ? "optionContainerSelected" : "optionContainer"}
      {...other}
    >
      <i className={["material-icons", css.optionLeftIcon].join(" ")}>done</i>
      <div>
        <div styleName="optionTitle">{title}</div>
        <div styleName="optionDescription">{description}</div>
      </div>
    </div>
  ),
  css
);

PermissionOption.propTypes = {
  styles: PropTypes.object,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  selected: PropTypes.bool
};

@CSSModules(css)
class PermissionSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
      selected: this.props.value || null
    };
  }

  componentDidMount() {
    if (this.props.orgId) {
      this.props.getOrganizationPermissionProfiles(this.props.orgId);
    } else {
      this.props.getEventPermissionProfiles(this.props.eventId);
    }
  }

  @autobind
  onSelect(e, selected) {
    this.setState({
      open: false,
      selected
    });
    this.props.onSelect(selected);
  }

  @autobind
  handleOpen(event) {
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget
    });
  }

  @autobind
  handleRequestClose() {
    this.setState({
      open: false
    });
  }

  render() {
    return (
      <Select
        width={this.props.width}
        onClick={this.handleOpen}
        value={
          this.state.selected
            ? get(
                this.props.permissionProfiles.find(
                  p => p.id === this.state.selected
                ),
                "name"
              ) || "Loading..."
            : null
        }
        placeholder="Select Permission"
      >
        <Popover
          anchorOrigin={{
            horizontal: "left",
            vertical: "top"
          }}
          transformOrigin={{
            horizontal: "left",
            vertical: "top"
          }}
          PaperProps={{
            style: {
              width: this.props.width
            }
          }}
          open={this.state.open}
          canAutoPosition={false}
          onClose={this.handleRequestClose}
          anchorEl={this.state.anchorEl}
        >
          <div>
            <div className={css.selectHeader}>Select Permission</div>
            <div
              className={css.optionsList}
              style={{ ...this.props.menuStyle }}
            >
              {this.props.permissionProfiles.map(role => (
                <PermissionOption
                  key={role.id}
                  selected={role.id === this.state.selected}
                  onClick={e => this.onSelect(e, role.id)}
                  title={role.name}
                  description={role.description}
                />
              ))}
            </div>

            <CanUserDo
              action={`${STANDARD_MODULE_IDS.settings.id}_manage_permissions`}
            >
              <Link
                to={
                  this.props.orgId
                    ? `/org-light/${this.props.orgId}/team/permissions`
                    : `/event-light/${this.props.eventId}/team/permissions`
                }
                onClick={this.handleRequestClose}
                target="_blank"
                className={css.linkContainer}
              >
                <i className={["material-icons", css.linkLeftIcon].join(" ")}>
                  open_in_new
                </i>
                <div>
                  <div className={css.linkTitle}>Edit/View Permissions</div>
                  <div className={css.linkDescription}>
                    Opens in a new window
                  </div>
                </div>
              </Link>
            </CanUserDo>
          </div>
        </Popover>
      </Select>
    );
  }
}

PermissionSelect.defaultProps = {
  width: 250,
  menuStyle: {
    maxHeight: 150
  }
};

PermissionSelect.propTypes = {
  eventId: PropTypes.number.isRequired,
  getEventPermissionProfiles: PropTypes.func.isRequired,
  menuStyle: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  permissionProfiles: PropTypes.arrayOf({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  styles: PropTypes.object,
  value: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

function mapStateToProps(state, props) {
  return {
    permissionProfiles: permissionProfiles(state),
    orgId: props.params.orgId,
    eventId: props.params.eventId
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getOrganizationPermissionProfiles,
      getEventPermissionProfiles
    },
    dispatch
  );
}

export { PermissionSelect };

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(PermissionSelect)
);
