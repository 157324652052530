import { REQUEST, RECEIVE } from "./constants";
import { ERROR as SUBMISSION_ERROR } from "../constants";
import api from "./api";

export function getActivity(submissionId) {
  return async (dispatch, getState) => {
    dispatch({ type: REQUEST });
    try {
      const result = await api.get(
        getState().user.user.credentials,
        submissionId
      );
      dispatch({
        type: RECEIVE,
        payload: result.activity
      });
    } catch (error) {
      dispatch({
        type: SUBMISSION_ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}
