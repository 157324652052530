import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Templates from "./Templates";

import { eventDetails } from "redux/modules/event/selectors";

import { getTemplates } from "redux/modules/modules/templates/actions";
import { templates } from "redux/modules/modules/templates/selectors";

function mapStateToProps(state) {
  return {
    eventDetails: eventDetails(state),
    templates: templates(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getTemplates
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
