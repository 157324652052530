import { RECEIVE, REQUEST } from "./constants";
import * as api from "./api";
import { registerError } from "redux/modules/errors/actions";
import { isFetching } from "./selectors";
import { eventId } from "redux/modules/event/selectors";

export function getSourcesByItemType(typeId) {
  return async (dispatch, getState) => {
    if (isFetching(getState())) return;
    try {
      dispatch({
        type: REQUEST
      });
      const result = await api.getSourcesByItemType(
        getState().user.user.credentials,
        eventId(getState()),
        typeId
      );
      dispatch({
        type: RECEIVE,
        payload: { data: result.payload, typeId }
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching sources"
          }
        ])
      );
    }
  };
}
