import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import { actions, getters } from "Modules/AddEditColumnModal/model";
import DateEditorSettings from "components/Global/Table3/ModalColumn/DateEditorSettings";
import DateFormatSettings from "components/Global/Table3/ModalColumn/DateFormatSettings";
import { noop } from "utils/General";
import css from "./style.scss";

const decorate = R.compose(
  connect(
    (state, props) => ({
      dateSettings: getters.dateSettings(state, props)
    }),
    {
      setDateSettings: actions.setDateSettings
    }
  ),
  CSSModules(css)
);

const DateSettings = ({ dateSettings, setDateSettings = noop }) => (
  <>
    {/*
  // @NOTE: Commenting out for now
  
  <div styleName="dropdownOptionsWrapper">
    <DateEditorSettings value={dateSettings} onChange={setDateSettings} />
    <DateFormatSettings
      settings={dateSettings}
      updateDateSettings={setDateSettings}
    />
  </div>
  */}
  </>
);

export default decorate(DateSettings);
