import PropTypes from "prop-types";
import React, { Component } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Motion, spring } from "react-motion";
import autobind from "autobind-decorator";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FormShareNotice extends Component {
  constructor(props) {
    super(props);
    this.state = { hidden: true, copied: false };
  }
  componentDidMount() {
    setTimeout(() => {
      this.show();
    }, 3000);
  }

  @autobind
  show() {
    this.setState({
      hidden:
        !this.props.showShareLink ||
        Boolean(sessionStorage.getItem("hideFormLinkNotice"))
    });
  }

  @autobind
  clearNotice() {
    sessionStorage.setItem("hideFormLinkNotice", true);
    this.setState({ hidden: !this.state.hidden });
  }

  render() {
    const { formId, formSlug, eventSlug, styles } = this.props;
    const {
      closeIcon,
      title,
      container,
      bottom,
      textButtonDismiss,
      textButtonGroup,
      top
    } = css;
    const url = window.location.href;
    return (
      <div styleName="parent">
        <Motion style={{ y: spring(this.state.hidden ? -400 : 0) }}>
          {({ y }) => (
            <div
              className={container}
              style={{
                WebkitTransform: `translate3d(0, ${y}px, 0)`,
                transform: `translate3d(0, ${y}px, 0)`
              }}
            >
              <div className={top}>
                <div className={title}>
                  If you share a link to this page, it will allow others to view
                  and edit your submission.
                </div>
                <div className={closeIcon} onClick={this.clearNotice}>
                  <i className="material-icons">close</i>
                </div>
              </div>
              <div className={bottom}>
                <span onClick={this.clearNotice} className={textButtonDismiss}>
                  Dismiss
                </span>
                <span style={{ flex: 1 }} />
                <CopyToClipboard
                  text={url}
                  onCopy={() => this.setState({ copied: true })}
                >
                  <span className={textButtonGroup}>
                    {this.state.copied && (
                      <i
                        className="material-icons"
                        style={{ marginRight: 10, fontSize: "20px" }}
                      >
                        check
                      </i>
                    )}
                    <span>
                      {this.state.copied
                        ? "Copied to clipboard! "
                        : "Copy shareable link"}
                    </span>
                  </span>
                </CopyToClipboard>&nbsp;
              </div>
            </div>
          )}
        </Motion>
      </div>
    );
  }
}

FormShareNotice.propTypes = {
  showShareLink: PropTypes.bool,
  formId: PropTypes.string.isRequired,
  eventSlug: PropTypes.string.isRequired,
  formSlug: PropTypes.string.isRequired,
  styles: PropTypes.object.isRequired
};

export default FormShareNotice;
