/* eslint-disable no-underscore-dangle */
import React from "react";

import {
  put,
  call,
  all,
  fork,
  select,
  take,
  takeEvery
} from "redux-saga/effects";
import { openInNewWindow } from "utils/General";
import { makeFuture } from "utils/General/sagas";
import * as R from "ramda";
import { addS } from "utils/General";

import copy from "copy-to-clipboard";

import { actions, getters } from "EventLight/Agenda/Manage";
import { getters as ViewPickerGetters } from "ui-kit/ViewPicker";
import { actions as TableActions } from "ui-kit/Table/model";
import { hideModal, showModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import Helpers from "utils/Global/Helpers";
import { formatValue } from "EventLight/Agenda/Manage/selectors";

import {
  ROW_ACTIONS as TABLE_ROW_ACTIONS,
  GROUP_ACTIONS
} from "ui-kit/Table/constants";
import { VIEWPICKER_INSTANCE_ID } from "EventLight/Agenda/Manage/constants";

import { getCredentials } from "redux/modules/user/selectors";
import { getAllRecordReferences } from "App/Data/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import {
  TABLE_INSTANCE_ID,
  ROW_ACTIONS
} from "EventLight/Agenda/Manage/constants";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";
import * as SEND_TO_OPTION_TYPES from "SendEmailModal/utils/send-to-option-types";
import * as EMAIL_TEMPLATES from "utils/Emails/default-emails";
import recordApi from "redux/modules/modules/records/api";
import accountValuesApi from "redux/modules/accounts/values/api";
import Api from "../api";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import SendEmail from "SendEmailModal/View";
import RunReportModal from "Reports/RunReportModal/View";
import SendDocument from "components/Event/Accounts/Modals/SendDocument";
import AssignDocumentRequestsModal from "components/Global/CRM/Modals/Assign/AssignDocumentRequests";
import AssignOwnersModal from "components/Global/Module/Modals/AssignOwners";
import DeleteConfirmation from "components/Global/CRM/Modals/DeleteConfirmation";
import DeleteOrRemoveConfirmation from "components/Global/CRM/Modals/DeleteOrRemoveConfirmation";
import BulkEdit from "components/Global/CRM/Modals/BulkEdit";
import CreateDocuments from "Modules/GenerateDocumentsModal/View";
import Retrieving from "components/Global/CRM/Modals/Retrieving";
import SubscribeModal from "Modules/SubscribeModal/View";
import AddSessionModal from "EventLight/Agenda/AddSessionModal/View";

import accountApi from "redux/modules/accounts/profile/api";
import { registerError } from "redux/modules/errors/actions";

import { navigateTo } from "utils/General";

const UPLOAD_FIELD_ID = "d512aeba-ae22-4623-b64c-90f293e37f16";

const sendEmail = function*({
  payload: { row, selectedOptions, subject = null, message = null }
}) {
  const moduleId = yield select(getters.moduleId);

  yield put(
    showModal({
      content: (
        <SendEmail
          moduleId={moduleId}
          records={[row.id]}
          subject={subject}
          message={message}
          selectedOptions={
            selectedOptions || moduleId === STANDARD_MODULE_IDS.accounts.id
              ? [SEND_TO_OPTION_TYPES.ACCOUNT_PRIMARY_CONTACTS]
              : moduleId === STANDARD_MODULE_IDS.contacts.id
              ? [SEND_TO_OPTION_TYPES.EMAIL_FIELD]
              : null
          }
        />
      ),
      wrapper: ModalWrapper
    })
  );
};

const showBulkEditModal = function*({ toggledRows, fields, references }) {
  const handleEdit = makeFuture();

  yield put(
    showModal({
      content: (
        <BulkEdit
          selected={toggledRows}
          getMetaData={(rowData, column) => ({
            meta: {
              columnId: column.id,
              columnSettings: column.settings,
              references
            },
            helpers: {}
          })}
          columns={fields}
          onSave={handleEdit.done}
        />
      ),
      wrapper: ModalWrapper
    })
  );

  return yield call(handleEdit.onRealized);
};

const bulkEdit = function*({ payload: { toggledRows } }) {
  const credentials = yield select(getCredentials);
  const references = yield select(getAllRecordReferences);
  const fields = yield select(getters.columns);

  const result = yield call(showBulkEditModal, {
    toggledRows,
    fields,
    references
  });

  if (!result) {
    return false;
  }

  yield call(Api.updateSession, {
    credentials,
    data: {
      bulk: true,
      sessions: toggledRows.map(sessionId => ({
        sessionId,
        ...formatValue(result.fieldId, R.prop("value")(result.value))
      }))
    }
  });

  yield put(
    showSnackbar({
      message: `Session${toggledRows.length !== 1 ? "s" : ""} updated`,
      action: "OK"
    })
  );

  yield put(actions.fetchData());

  return true;
};

const sendItemSummary = function*({ payload: { row, actionId } }) {
  const content = R.prop(actionId)({
    [ROW_ACTIONS.SEND_ALL_ITEMS]: EMAIL_TEMPLATES.ITEM_SUMMARY,
    [ROW_ACTIONS.SEND_ALL_PASSES]: EMAIL_TEMPLATES.CREDENTIALS_SUMMARY,
    [ROW_ACTIONS.SEND_ALL_MEALS]: EMAIL_TEMPLATES.CATERING_SUMMARY,
    [ROW_ACTIONS.SEND_ALL_PASSES_MEALS]:
      EMAIL_TEMPLATES.CREDENTIALS_AND_CATERING_SUMMARY,
    [ROW_ACTIONS.SEND_ALL_ASSETS]: EMAIL_TEMPLATES.INVENTORY_SUMMARY
  });

  yield call(sendEmail, {
    payload: {
      row,
      ...content
    }
  });
};

const sendLogin = function*({ payload: { row } }) {
  yield call(sendEmail, {
    payload: {
      row,
      ...EMAIL_TEMPLATES.PORTAL_LOGIN
    }
  });
};

const sendLink = function*({ payload: { row } }) {
  yield call(sendEmail, {
    payload: {
      row,
      selectedOptions: [SEND_TO_OPTION_TYPES.ACCOUNT_PRIMARY_CONTACTS],
      ...EMAIL_TEMPLATES.ASSIGNMENT_MANAGER
    }
  });
};

const copyLogin = function*() {
  const eventDetails = yield select(getEventDetails);
  yield call(
    copy,
    `${window.__LENND_APP_URL__}/portal-login/${eventDetails.slug}/${eventDetails.uuid}`
  );
  yield put(showSnackbar({ message: "Copied" }));
};

const copyLink = function*({ payload: { row } }) {
  const eventDetails = yield select(getEventDetails);
  yield call(
    copy,
    `${window.__LENND_APP_URL__}/assignment-manager/${eventDetails.slug}/${eventDetails.uuid}/${row.id}`
  );
  yield put(showSnackbar({ message: "Copied" }));
};

const openLink = function*({ payload: { row } }) {
  const eventDetails = yield select(getEventDetails);
  yield call(
    openInNewWindow,
    `${window.__LENND_APP_URL__}/assignment-manager/${eventDetails.slug}/${eventDetails.uuid}/${row.id}`
  );
};

const showAssignDocumentsModal = function*({ moduleId, row }) {
  const handleAssign = makeFuture();
  yield put(
    showModal({
      content: (
        <AssignDocumentRequestsModal
          moduleId={moduleId}
          recordNameSingular={
            moduleId === STANDARD_MODULE_IDS.accounts.id ? "Group" : "Person"
          }
          recordNamePlural={
            moduleId === STANDARD_MODULE_IDS.accounts.id ? "Groups" : "People"
          }
          recordIds={[row.id]}
          onDone={handleAssign.done}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleAssign.onRealized);
};

const assignDocumentRequests = function*({ payload: { row } }) {
  const moduleId = yield select(getters.moduleId);

  yield call(showAssignDocumentsModal, {
    moduleId,
    row
  });

  yield put(actions.fetchData());
};

const showAssignOwnersModal = function*({ moduleId, row }) {
  const handleAssign = makeFuture();
  yield put(
    showModal({
      content: (
        <AssignOwnersModal
          moduleId={moduleId}
          recordNameSingular={
            moduleId === STANDARD_MODULE_IDS.accounts.id ? "Group" : "Person"
          }
          recordNamePlural={
            moduleId === STANDARD_MODULE_IDS.accounts.id ? "Groups" : "People"
          }
          recordIds={[row.id]}
          onDone={handleAssign.done}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleAssign.onRealized);
};

const assignOwners = function*({ payload: { row } }) {
  const moduleId = yield select(getters.moduleId);

  yield call(showAssignOwnersModal, {
    moduleId,
    row
  });

  yield put(actions.fetchData());
};

const showGenerateDocumentsModal = function*({
  orgId,
  eventId,
  row,
  moduleId
}) {
  const handleGenerate = makeFuture();
  yield put(
    showModal({
      content: (
        <CreateDocuments
          onDone={handleGenerate.done}
          orgId={orgId}
          eventId={eventId}
          moduleId={moduleId}
          selected={[row.id]}
          showSaveAndSend={true}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleGenerate.onRealized);
};

const showSendDocumentsModal = function*({ recipients, documents }) {
  const handleSend = makeFuture();
  yield put(
    showModal({
      content: (
        <SendDocument
          onDone={handleSend.done}
          recipients={recipients}
          documents={documents}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleSend.onRealized);
};

// @TODO: Clean this up - move more to the backend and generalize
const generateDocument = function*({ payload: { row } }) {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const eventId = yield select(getEventId);
  const moduleId = yield select(getters.moduleId);

  const { documents, send } = yield call(showGenerateDocumentsModal, {
    orgId,
    eventId,
    row,
    moduleId
  });

  // Handle sending document notifications
  if (send) {
    yield put(
      showModal({
        content: (
          <Retrieving message="Please wait..." title="Retrieving Records" />
        ),
        wrapper: ModalWrapper
      })
    );

    const fetchedRecord = yield call(accountApi.get, credentials, {
      accountId: row.id,
      orgId,
      eventId
    });

    const recipients = [fetchedRecord]
      .map(({ account }) => account)
      .filter(Boolean)
      .map(a => ({
        name: R.pathOr("(No Name)", [
          "values",
          STANDARD_FIELD_IDS.ACCOUNTS.NAME,
          "value",
          "value"
        ])(a),
        users: a.users.filter(u => u.user_id && u.email)
      }));

    yield put(hideModal());

    const count = yield call(showSendDocumentsModal, {
      recipients,
      documents
    });

    yield put(
      showSnackbar({
        message: `${count} Document${count === 1 ? "" : "s"} Saved & Sent`,
        action: "OK"
      })
    );
  }
};

const runReport = function*({ payload: { row } }) {
  const moduleId = yield select(getters.moduleId);

  yield put(
    showModal({
      content: <RunReportModal moduleId={moduleId} recordIds={[row.id]} />,
      wrapper: ModalWrapper
    })
  );
};

const showUploadFilesModal = function*() {
  const handleUpload = makeFuture();

  Helpers.getFilepicker(
    {
      multiple: true,
      mimetypes: [],
      container: "modal",
      services: ["COMPUTER", "DROPBOX", "GOOGLE_DRIVE"]
    },
    { path: "event-files/" },
    handleUpload.done
  );

  return yield call(handleUpload.onRealized);
};

const uploadFiles = function*({ payload: { row } }) {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const eventId = yield select(getEventId);

  const files = yield call(showUploadFilesModal);

  const moduleFiles = yield call(recordApi.bulkPost, credentials, {
    records: files.map(file => ({
      moduleId: STANDARD_MODULE_IDS.files.id,
      record: {
        [UPLOAD_FIELD_ID]: { type: "file", value: { files: [file] } }
      }
    })),
    options: {
      orgId,
      eventId
    }
  });

  const relatedRecordIds = moduleFiles.records.map(({ id }) => id);

  yield call(accountValuesApi.bulkRelate, credentials, {
    recordIds: [row.id],
    relatedRecordIds,
    relatedModuleId: STANDARD_MODULE_IDS.files.id
  });

  yield put(showSnackbar({ message: "Files added", action: "OK" }));

  yield put(actions.fetchData());
};

const showDeleteOrRemoveConfirmation = function*() {
  const handleDelete = makeFuture();
  yield put(
    showModal({
      content: (
        <DeleteOrRemoveConfirmation
          countOfSelected={1}
          heading={`Remove Record?`}
          onRemove={() => handleDelete.done(true)}
          onDelete={() => handleDelete.done(false)}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleDelete.onRealized);
};

const showDeleteConfirmation = function*() {
  const handleDelete = makeFuture();
  yield put(
    showModal({
      content: (
        <DeleteConfirmation
          heading="Delete Record?"
          message={
            <div>
              {`
                Are you sure you want to remove this record?
                `}
              <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                This cannot be undone.
              </div>
            </div>
          }
          onConfirm={() => handleDelete.done(false)}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleDelete.onRealized);
};

const deleteRecord = function*({ payload: { row } }) {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const eventId = yield select(getEventId);
  const moduleId = yield select(getters.moduleId);
  let removeOnly = false;

  if (
    !orgId &&
    [STANDARD_MODULE_IDS.accounts.id, STANDARD_MODULE_IDS.contacts.id].includes(
      moduleId
    )
  ) {
    removeOnly = yield call(showDeleteOrRemoveConfirmation);
  } else {
    yield call(showDeleteConfirmation);
  }

  yield call(recordApi.delete, credentials, {
    moduleId,
    orgId,
    eventId,
    record: { id: row.id },
    options: {
      orgId,
      eventId,
      removeOnly
    }
  });

  yield put(
    showSnackbar({
      message: `Record ${removeOnly ? "removed" : "deleted"}`,
      action: "OK"
    })
  );

  yield put(
    TableActions.clearSelectedRows(null, {
      meta: {
        instanceId: TABLE_INSTANCE_ID
      }
    })
  );

  yield put(actions.fetchData());
};

const exportGroup = function*({ payload: { group, actionId } }) {
  yield put(actions.setLoading(true));
  try {
    // @NOTE: replace by api call
    const result = yield call(R.always({ url: "./" }), {
      groupIds: group.list,
      type: R.prop(actionId, {
        [GROUP_ACTIONS.EXPORT_CSV]: "csv",
        [GROUP_ACTIONS.EXPORT_XLSX]: "xlsx"
      })
    });
    yield call(navigateTo, result.url);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred exporting the schedule group"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const showAddSessionModal = function*(sessionId) {
  const handleSubmit = makeFuture();

  yield put(
    showModal({
      content: (
        <AddSessionModal
          sessionId={sessionId}
          onDone={() => handleSubmit.done({ type: "onDone" })}
        />
      ),
      wrapper: ModalWrapper
    })
  );

  return yield call(handleSubmit.onRealized);
};

const openRecord = function*({ payload: { row } }) {
  const result = yield call(showAddSessionModal, row.id);

  if (result.type === "onDone") {
    yield put(actions.fetchData());
  }
};

const handleAddSession = function*() {
  const result = yield call(showAddSessionModal);

  if (result.type === "onDone") {
    yield put(actions.fetchData());
  }
};

const showDeleteSessionModal = function*(toggledRows) {
  const handleSubmit = makeFuture();

  yield put(
    showModal({
      content: (
        <DeleteConfirmation
          heading={`Delete Session${addS(toggledRows.length)}?`}
          message={
            <div>
              {`
                    Are you sure you want to remove
                    ${
                      toggledRows.length === 1
                        ? "this"
                        : `these ${toggledRows.length}`
                    }
                    session${addS(toggledRows.length)}?
                    `}
              <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                This cannot be undone.
              </div>
            </div>
          }
          onConfirm={() => handleSubmit.done(true)}
        />
      ),
      wrapper: ModalWrapper
    })
  );

  return yield call(handleSubmit.onRealized);
};

const deleteSessions = function*({ payload: sessionIds }) {
  const credentials = yield select(getCredentials);
  const shouldDelete = yield call(showDeleteSessionModal, sessionIds);

  if (shouldDelete) {
    yield call(Api.deleteSessions, {
      credentials,
      data: {
        bulk: true,
        sessionIds
      }
    });

    yield put(
      showSnackbar({
        message: `Session${addS(sessionIds.length)} deleted`,
        action: "OK"
      })
    );

    yield put(
      TableActions.clearSelectedRows(null, {
        meta: {
          instanceId: TABLE_INSTANCE_ID
        }
      })
    );

    yield put(actions.fetchData());
  }
};

const duplicateSessions = function*({ payload: sessionIds }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  yield call(Api.duplicateSessions, {
    credentials,
    eventId,
    data: {
      bulk: true,
      sessionIds
    }
  });

  yield put(
    showSnackbar({
      message: `Session${addS(sessionIds.length)} duplicated`,
      action: "OK"
    })
  );

  yield put(
    TableActions.clearSelectedRows(null, {
      meta: {
        instanceId: TABLE_INSTANCE_ID
      }
    })
  );

  yield put(actions.fetchData());
};

const duplicateRow = function*({ payload: { row } }) {
  yield call(duplicateSessions, { payload: [row.id] });
};

const watchTableActions = function*() {
  for (;;) {
    const action = yield take(TableActions.executeAction.type);
    const delegate = R.prop(action.payload.actionId, {
      [ROW_ACTIONS.VIEW_RECORD]: openRecord,
      [ROW_ACTIONS.EDIT]: openRecord,
      [ROW_ACTIONS.DUPLICATE]: duplicateRow,

      [ROW_ACTIONS.SEND_EMAIL]: sendEmail,
      [ROW_ACTIONS.SEND_ALL_ITEMS]: sendItemSummary,
      [ROW_ACTIONS.SEND_ALL_PASSES]: sendItemSummary,
      [ROW_ACTIONS.SEND_ALL_MEALS]: sendItemSummary,
      [ROW_ACTIONS.SEND_ALL_PASSES_MEALS]: sendItemSummary,
      [ROW_ACTIONS.SEND_ALL_ASSETS]: sendItemSummary,
      [ROW_ACTIONS.SEND_LOGIN]: sendLogin,
      [ROW_ACTIONS.SEND_LINK]: sendLink,
      [ROW_ACTIONS.COPY_LOGIN]: copyLogin,
      [ROW_ACTIONS.COPY_LINK]: copyLink,
      [ROW_ACTIONS.OPEN_LINK]: openLink,
      [ROW_ACTIONS.DOCUMENT_REQUESTS]: assignDocumentRequests,
      [ROW_ACTIONS.ASSIGN_OWNERS]: assignOwners,
      [ROW_ACTIONS.GENERATE_DOCUMENT]: generateDocument,
      [ROW_ACTIONS.RUN_REPORT]: runReport,
      [ROW_ACTIONS.UPLOAD_FILES]: uploadFiles,
      [ROW_ACTIONS.DELETE_RECORDS]: deleteRecord,
      [TABLE_ROW_ACTIONS.OPEN_RECORD]: openRecord,
      [GROUP_ACTIONS.EXPORT_CSV]: exportGroup,
      [GROUP_ACTIONS.EXPORT_XLSX]: exportGroup
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const showSubscribeModal = function*() {
  const eventId = yield select(getEventId);
  const activeViewId = yield select(ViewPickerGetters.activeViewId, {
    instanceId: VIEWPICKER_INSTANCE_ID
  });

  yield put(
    showModal({
      content: (
        <SubscribeModal
          type="sessions"
          moduleId={STANDARD_MODULE_IDS.sessions.id}
          eventId={eventId}
          viewId={activeViewId}
        />
      ),
      wrapper: ModalWrapper
    })
  );
};

const watchShowAddSessionModal = function*() {
  yield takeEvery(actions.showAddSessionModal.type, handleAddSession);
};

const watchShowSubscribeModal = function*() {
  yield takeEvery(actions.showSubscribeModal.type, showSubscribeModal);
};

const watchShowDeleteSessionModal = function*() {
  yield takeEvery(actions.showDeleteSessionModal.type, deleteSessions);
};

const watchShowBulkEditModal = function*() {
  yield takeEvery(actions.showBulkEditModal.type, bulkEdit);
};

const watchDuplicateSessions = function*() {
  yield takeEvery(actions.duplicateSessions.type, duplicateSessions);
};

const rootSaga = function*() {
  yield all([
    fork(watchTableActions),
    fork(watchShowSubscribeModal),
    fork(watchShowAddSessionModal),
    fork(watchShowBulkEditModal),
    fork(watchShowDeleteSessionModal),
    fork(watchDuplicateSessions)
  ]);
};

export default rootSaga;
