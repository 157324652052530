import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: false,
  title: "",
  modules: [],
  selectedModuleId: "",
  templateUrl: "",
  imageUrl: "",
  filename: "",
  template: null,
  showDeleteConfirmation: false
};

const handlers = createHandlers({
  iniState,
  reducers: {
    cancelInstance: R.always(iniState),
    init: R.identity,
    uploadDocument: R.identity,
    saveDocumentTemplate: R.identity,
    downloadDocument: R.identity,
    deleteTemplate: R.identity,
    setInitialData: (
      state,
      {
        payload: {
          modules,
          template,
          templateUrl,
          templateTitle,
          templateImageUrl,
          moduleId
        }
      }
    ) => ({
      modules,
      selectedModuleId: !R.isNil(moduleId)
        ? moduleId
        : R.propOr(R.propOr("", "id", modules[0]), "module_id", template),
      title: R.propOr(templateTitle, "title", template),
      templateUrl: R.propOr(templateUrl, "template_url", template),
      imageUrl: R.propOr(templateImageUrl, "image_url", template),
      template
    }),
    updateDocumentUrlData: (state, { payload: { templateUrl, filename } }) => ({
      templateUrl,
      filename
    })
  },
  namespace: NAMESPACE
});

export default handlers;
