module.exports = {
  MANAGE_ACCOUNT_FORMS:
    "https://learn.lennd.com/en/articles/4745074-how-to-build-and-configure-forms",
  MANAGE_ACCOUNT_FILE_REQUESTS:
    "https://learn.lennd.com/en/articles/3769938-manage-your-file-requests",
  MANAGE_ACCOUNT_USERS: "https://learn.lennd.com/",
  FORM_MANAGERS: "https://learn.lennd.com/",
  PORTAL_DOCUMENT_REQUESTS: "https://learn.lennd.com/", // TODO
  ITEM_BLOCKS:
    "https://learn.lennd.com/hc/en-us/articles/360000760314-Building-item-blocks",
  PASSES: "https://learn.lennd.com/"
};
