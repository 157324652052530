import * as R from "ramda";
import { createSelector } from "reselect";

import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

import { ASSETS_TYPE_ID } from "utils/item-types";

const getUrl = createSelector(
  R.path(["eventDetails", "id"]),
  eventId => (view, base = "/inventory") => `/event/${eventId}${base}${view}`
);

export const getLinks = createSelector(
  getUrl,
  R.prop("cando"),
  R.prop("showCreateOrderModal"),
  R.prop("showImportOrdersModal"),
  R.prop("categories"),
  R.pathOr("", ["params", "categoryId"]),
  (
    getUrl,
    cando,
    showCreateOrderModal,
    showImportOrdersModal,
    categories,
    categoryId
  ) =>
    [
      {
        id: "dashboard",
        to: getUrl("/dashboard"),
        name: "Dashboard",
        activeRoutes: ["eventInventoryDashboard"]
      },
      {
        id: "myApprovals",
        to: getUrl("/manage/my-approvals"),
        name: "My Approvals",
        activeRoutes: ["ManageInventory"],
        activeParams: { viewId: "my-approvals" }
      },
      {
        id: "allRequests",
        to: getUrl("/manage/all-requests"),
        name: "All Requests",
        activeRoutes: R.isEmpty(categoryId) ? ["ManageInventory"] : [],
        activeParams: { viewId: "all-requests" }
      },
      {
        id: "issue",
        to: getUrl("/manage/fulfill"),
        name: "Issue",
        activeRoutes: ["ManageInventory"],
        activeParams: { viewId: "fulfill" }
      },
      R.length(categories)
        ? {
            id: "inventoryDivider",
            divide: true
          }
        : null,
      R.length(categories)
        ? {
            id: "categories",
            to: getUrl("/manage/fulfill"),
            name: "Categories",
            links: R.map(
              category => ({
                id: category.id,
                name: category.name,
                activeRoutes: [],
                activeParams: { groupId: category.id },
                to: getUrl(`/manage/all-requests/category/${category.id}`),
                isActive: categoryId === category.id
              }),
              categories
            )
          }
        : null,
      {
        id: "inventoryDivider",
        divide: true
      },
      {
        id: "subMenu",
        links: [
          {
            id: "create-order",
            name: "Create Order",
            onClick: () => showCreateOrderModal()
          },
          cando(`${STANDARD_MODULES.inventory.id}_add_line_items`)
            ? {
                id: "import-order",
                name: "Import Orders",
                onClick: () => showImportOrdersModal()
              }
            : null,
          {
            id: "confirm-emails",
            name: "Send Emails",
            to: getUrl("/confirm", "/inventory"),
            activeRoutes: ["InventoryConfirmation"]
          }
        ]
      }
    ].filter(R.identity)
);

export const getSetupLink = createSelector(
  getUrl,
  R.prop("cando"),
  (getUrl, cando) => {
    return {
      id: "setupLinks",
      links: cando(`${STANDARD_MODULES.inventory.id}_manage`)
        ? [
            {
              id: "manage-item-types",
              name: "Manage Catalog",
              to: getUrl(`/settings/catalog/type/${ASSETS_TYPE_ID}/items`, "")
            },
            {
              id: "manage-approvers",
              name: "Manage Approvers",
              to: getUrl(
                `/settings/catalog/type/${ASSETS_TYPE_ID}/approvers`,
                ""
              )
            },
            {
              id: "manage-forms",
              name: "Manage Forms",
              to: getUrl("/forms-v2", "")
            }
          ]
        : []
    };
  }
);
