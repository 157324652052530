import React from "react";
import * as R from "ramda";
import {
  put,
  all,
  take,
  takeEvery,
  fork,
  select,
  call
} from "redux-saga/effects";
import { makeFuture } from "utils/General/sagas";
import copy from "copy-to-clipboard";
import moment from "moment-timezone";
import { debounce } from "utils/General/sagas";
import { apiCall } from "App/Data/sagas";

import {
  FIELD_IDS,
  FORM_ID,
  REQUIRED_ERROR_MESSAGE,
  DATE_FORMAT
} from "./constants";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_MODULE_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

import { getCredentials } from "redux/modules/user/selectors";
import {
  eventDetails as getEventDetails,
  eventId as getEventId
} from "redux/modules/event/selectors";
import { getEditedField } from "./selectors";

import { actions, getters } from "./model";
import { registerError } from "redux/modules/errors/actions";
import { actions as FormActions } from "ui-kit/Form/model";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal } from "redux/modules/modal/actions";
import { actions as LayoutActions } from "EventLight/Layout/model";
import { getEvent } from "redux/modules/event/actions";
import { setOtherZone, setLocalZone } from "utils/General";

import { getFieldsToSave } from "./selectors";

import AddFieldModal from "Modules/AddEditColumnModal/View";
import EditFieldModal from "Modules/AddEditColumnModal/View";

import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventDetails = yield select(getEventDetails);

  return {
    credentials,
    eventDetails
  };
};

const init = function*() {
  try {
    yield put(actions.setLoading(true));
    const { credentials, eventDetails } = yield call(getParams);

    const { payload } = yield call(Api.getSettings, {
      credentials,
      eventId: eventDetails.id
    });

    const [{ fields: healthPassFields }, { fields: contactFields }] = yield all(
      [
        call(Api.getModuleFields, {
          credentials,
          eventId: eventDetails.id,
          moduleId: STANDARD_MODULES.healthPass.id
        }),
        call(Api.getModuleFields, {
          credentials,
          eventId: eventDetails.id,
          moduleId: STANDARD_MODULES.contacts.id
        })
      ]
    );

    yield put(
      actions.setInitialData({
        ...payload,
        healthPassFields,
        contactFields
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting settings"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const save = function*() {
  try {
    const { credentials, eventDetails } = yield call(getParams);

    const data = yield select(getters.data);
    const fields = yield select(getFieldsToSave);
    const notifyUserIds = R.compose(
      R.map(R.prop("value")),
      R.propOr([], "notify_user_ids")
    )(data);

    /*
    const fnameField = fields.find(
      f => f.id === STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME
    );
    const lnameField = fields.find(
      f => f.id === STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME
    );
    const emailField = fields.find(
      f => f.id === STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL
    );


    if (!fnameField || !lnameField || !emailField) {
      yield put(actions.setError(REQUIRED_ERROR_MESSAGE));
      return false;
    }

    if (!fnameField.required || !lnameField.required || !emailField.required) {
      yield put(actions.setError(REQUIRED_ERROR_MESSAGE));
      return false;
    }
    */

    yield put(actions.setError(null));

    const toSave = {
      welcomeTitle: data.welcome_title,
      welcomeMessage: data.welcome_message,
      approvedMessage: data.approved_message,
      deniedMessage: data.denied_message,
      reminderMessage: data.reminder_message,
      backgroundImageUrl: data.background_image_url,
      testingBeginsAt: data.testing_begins_at,
      testingEndsAt: data.testing_ends_at,
      expiresAfterNumHours: data.expires_after_num_hours,
      sendAtTime: data.send_at_time,
      frequency: data.frequency,
      notifyUserIds,
      fields
    };

    yield call(Api.updateSettings, {
      credentials,
      eventId: eventDetails.id,
      data: toSave
    });

    yield put(showSnackbar({ message: "Settings Updated" }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while saving settings"
        }
      ])
    );
  }
};

const fetchFields = function*() {
  const { credentials, eventDetails } = yield call(getParams);

  try {
    const [{ fields: healthPassFields, fields: contactFields }] = yield all([
      call(Api.getModuleFields, {
        credentials,
        eventId: eventDetails.id,
        moduleId: STANDARD_MODULES.healthPass.id
      }),
      call(Api.getModuleFields, {
        credentials,
        eventId: eventDetails.id,
        moduleId: STANDARD_MODULES.contacts.id
      })
    ]);

    yield all([
      yield put(
        actions.setAllFields(
          [...healthPassFields.fields, ...contactFields.fields].map(f => ({
            ...f,
            moduleLabel:
              f.module_id === STANDARD_MODULES.healthPass.id
                ? "Health Pass Field"
                : "Attendee Field"
          }))
        )
      ),
      yield put(actions.setHealthPassFields(healthPassFields)),
      yield put(actions.setContactFields(contactFields))
    ]);
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred geting module fields"
          }
        ])
      )
    ]);
  }
};

const handleShowEditFieldModal = function*({ id }) {
  const handleModal = makeFuture();
  const { eventDetails } = yield call(getParams);
  const allFields = yield select(getters.allFields);
  const field = R.find(R.propEq("id", id))(allFields);

  yield put(
    showModal({
      content: (
        <EditFieldModal
          eventId={eventDetails.id}
          moduleId={field.module_id}
          fieldId={id}
          onSave={handleModal.done}
        />
      )
    })
  );
  return yield call(handleModal.onRealized);
};

const showEditFieldModal = function*({ payload: id }) {
  const result = yield call(handleShowEditFieldModal, { id });

  if (result) {
    yield put(showSnackbar({ message: "Field updated", action: "OK" }));
    yield call(fetchFields);
  }
};

const handleShowAddFieldModal = function*({ moduleId }) {
  const handleModal = makeFuture();
  const { eventDetails } = yield call(getParams);

  yield put(
    showModal({
      content: (
        <AddFieldModal
          eventId={eventDetails.id}
          moduleId={moduleId}
          onSave={handleModal.done}
        />
      )
    })
  );
  return yield call(handleModal.onRealized);
};

const showAddFieldModal = function*({ payload: moduleId }) {
  const result = yield call(handleShowAddFieldModal, { moduleId });

  if (result) {
    yield put(showSnackbar({ message: "Field added", action: "OK" }));
    yield call(fetchFields);
  }
};

const onSaveSelectFieldsModal = function*({ payload }) {
  try {
    yield put(actions.updateSelectedFields(payload));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating items"
        }
      ])
    );
  } finally {
    yield put(actions.setShowSelectFieldsModal(false));
  }
};

const fieldUpdate = function*({ id, fieldId }) {
  const eventDetails = yield select(getEventDetails);
  const data = yield select(getters.data);

  const newValue = yield select(getEditedField, {
    instanceId: id,
    fieldId
  });

  const processedValue = R.propOr(newValue, fieldId, {
    [FIELD_IDS.BACKGROUND_IMAGE_URL]: R.path([0, "url"], newValue),
    [FIELD_IDS.FREQUENCY]: R.path([0], newValue),
    [FIELD_IDS.EXPIRES_AFTER_NUM_HOURS]: R.path([0], newValue),
    [FIELD_IDS.SEND_AT_TIME]: R.path([0], newValue),
    [FIELD_IDS.TESTING_BEGINS_AT]: moment
      .tz(
        moment(
          setOtherZone(newValue, R.prop("timezone", eventDetails))
        ).toDate(),
        DATE_FORMAT,
        R.prop("timezone", eventDetails)
      )
      .utc()
      .format(),
    [FIELD_IDS.TESTING_ENDS_AT]: moment
      .tz(
        moment(
          setOtherZone(newValue, R.prop("timezone", eventDetails))
        ).toDate(),
        DATE_FORMAT,
        R.prop("timezone", eventDetails)
      )
      .utc()
      .format()
  });

  if (fieldId === FIELD_IDS.TESTING_BEGINS_AT) {
    const start = moment(processedValue);
    let end = moment(data.testing_ends_at);

    if (start.isAfter(end)) {
      end = start
        .clone()
        .add(1, "hours")
        .utc()
        .format();

      yield all([
        put(
          actions.updateField({
            field: FIELD_IDS.TESTING_ENDS_AT,
            value: end
          })
        ),
        put(
          FormActions.setFieldValue(
            setLocalZone(end, R.prop("timezone", eventDetails)),
            {
              meta: {
                instanceId: FORM_ID,
                fieldId: FIELD_IDS.TESTING_ENDS_AT
              }
            }
          )
        )
      ]);
    }
  } else if (fieldId === FIELD_IDS.TESTING_ENDS_AT) {
    const end = moment(processedValue);
    let start = moment(data.testing_begins_at);

    if (end.isBefore(start)) {
      start = end
        .clone()
        .subtract(1, "hours")
        .utc()
        .format();

      yield all([
        put(
          actions.updateField({
            field: FIELD_IDS.TESTING_BEGINS_AT,
            value: start
          })
        ),
        put(
          FormActions.setFieldValue(
            setLocalZone(start, R.prop("timezone", eventDetails)),
            {
              meta: {
                instanceId: FORM_ID,
                fieldId: FIELD_IDS.TESTING_BEGINS_AT
              }
            }
          )
        )
      ]);
    }
  }

  yield put(actions.updateField({ field: fieldId, value: processedValue }));
};

const openRegistrationPage = function*() {
  const registrationUrl = yield select(getters.registrationUrl);
  yield call(window.open, registrationUrl, "_blank");
};

const copyValue = function*({ payload }) {
  yield call(copy, payload);
  yield put(showSnackbar({ message: "Link Copied" }));
};

const loadOptions = function*({ payload: search, meta: { callback } }) {
  try {
    const eventId = yield select(getEventId);
    const optionsUrl = `/event-users/${eventId}/users`;

    const result = yield call(apiCall, {
      method: "get",
      url: optionsUrl
    });

    const payload = R.prop("payload", result);

    const options = R.compose(
      R.filter(u =>
        search && search.length
          ? u.label.toLowerCase().includes(search.toLowerCase())
          : true
      ),
      R.map(el => ({
        value: R.prop("user_id", el),
        label: [R.prop("user_fname", el), R.prop("user_lname", el)]
          .filter(v => v && v.length)
          .join(" ")
      }))
    )(payload);

    callback(options);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred fetching options for async dropdown"
          }
        ])
      )
    ]);
  }
};

const watchLoadAsyncDropdownOptions = debounce(
  FormActions.dataRequest.type,
  loadOptions,
  250
);

const watchUpdateFields = function*() {
  for (;;) {
    const {
      meta: { instanceId, fieldId }
    } = yield take(FormActions.setFieldValue.type);
    if (instanceId === FORM_ID) {
      yield call(fieldUpdate, {
        id: instanceId,
        fieldId
      });
    }
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchSave = function*() {
  yield takeEvery(actions.save.type, save);
};

const watchEditField = function*() {
  yield takeEvery(actions.editField.type, showEditFieldModal);
};

const watchAddField = function*() {
  yield takeEvery(actions.addField.type, showAddFieldModal);
};

const watchSaveSelectFieldsModal = function*() {
  yield takeEvery(actions.saveSelectFieldsModal.type, onSaveSelectFieldsModal);
};

const watchOpenRegistrationPage = function*() {
  yield takeEvery(actions.openRegistrationPage.type, openRegistrationPage);
};

const watchCopyValue = function*() {
  yield takeEvery(actions.copyValue.type, copyValue);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchSave),
    fork(watchUpdateFields),
    fork(watchEditField),
    fork(watchAddField),
    fork(watchOpenRegistrationPage),
    fork(watchCopyValue),
    fork(watchSaveSelectFieldsModal),
    fork(watchLoadAsyncDropdownOptions)
  ]);
};

export default rootSaga;
