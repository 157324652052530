import React from "react";

import { createContext } from "redux-mvc";

import * as R from "ramda";

import module, { actions } from "Items/AssignQuestionsItemsModal";

import Layout from "./Layout";

import rootSaga from "Items/AssignQuestionsItemsModal/sagas";

import AddQuestionSetModal from "Items/AddQuestionSetModal/View";
import AddQuestionModal from "Items/AddQuestionModal/View";
import EditQuestionSetQuestionsModal from "Items/EditQuestionSetQuestionsModal/View";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";

module.setRootSaga(rootSaga);

const decorate = createContext({
  module: module,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(
        actions.init({ variants: this.props.variantIds || [] })
      );
    }
  },
  handlers: {
    showAddQuestionSetModal(questionSetId) {
      this.props.showModal({
        content: (
          <AddQuestionSetModal
            onDone={() => {
              this.store.dispatch(actions.refetchSets());
              this.store.dispatch(actions.refetchQuestions());
            }}
            questionSetId={questionSetId}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showAddQuestionModal(questionId) {
      this.props.showModal({
        content: (
          <AddQuestionModal
            questionId={questionId}
            onDone={() => {
              this.store.dispatch(actions.refetchSets());
              this.store.dispatch(actions.refetchQuestions());
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showEditQuestionSetQuestionsModal(setId) {
      this.props.showModal({
        content: (
          <EditQuestionSetQuestionsModal
            setId={setId}
            onDone={() => {
              this.store.dispatch(actions.refetchSets());
              this.store.dispatch(actions.refetchQuestions());
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    }
  },
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: ["user", "event", "modal", "organization"]
  }
});

export default decorate(Layout);
