import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class DaysSelected extends Component {
  render() {
    const { label } = this.props;
    return <div styleName="container">{label}</div>;
  }
}

DaysSelected.propTypes = {
  label: PropTypes.string.isRequired
};

export default DaysSelected;
