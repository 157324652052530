module.exports = ({
  views,
  viewId,
  groupId,
  filterByAccountRecordTypeId,
  filterByContactRecordTypeId,
  search = ""
}) => {
  const view = views.find(v => v.id === viewId) || {};

  let components = [];
  if (groupId) {
    components.push("group");
    components.push(groupId);
  }
  if (filterByAccountRecordTypeId) {
    components.push("group-filter");
    components.push(filterByAccountRecordTypeId);
  }
  if (filterByContactRecordTypeId) {
    components.push("contact-filter");
    components.push(filterByContactRecordTypeId);
  }
  if (viewId) {
    if (view.view_type === "report") {
      components.push("report");
    } else if (view.view_type === "form") {
      components.push("form");
    } else {
      components.push("view");
    }
    components.push(viewId);
  }

  const path = components.join("/");

  return `/${path}${search}`;
};
