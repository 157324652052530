import React, { Component } from "react";
import * as R from "ramda";

function BaseLayoutController(Child) {
  return class Controller extends Component {
    state = { loading: true };

    async componentDidMount() {
      await Promise.all([
        this.props.getFields({
          moduleId: this.props.params.moduleId,
          options: {
            orgId: this.props.params.orgId,
            eventId: this.props.params.eventId
          }
        }),
        this.fetchVisibleFields()
      ]);

      this.setState({ loading: false });
    }

    fetchVisibleFields = () =>
      this.props.getLayoutVisibleFields({
        layoutType: this.props.layoutType,
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId,
        recordTypeId: this.props.params.typeId,
        moduleId: this.props.params.moduleId,
        options: {
          orgId: this.props.params.orgId,
          eventId: this.props.params.eventId
        }
      });

    onFieldsReorder = async fields => {
      await this.props.updateLayoutVisibleFields({
        layoutType: this.props.layoutType,
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId,
        recordTypeId: this.props.params.typeId,
        moduleId: this.props.params.moduleId,
        fieldIds: fields.map(field => field.id),
        options: {
          orgId: this.props.params.orgId,
          eventId: this.props.params.eventId
        }
      });

      return this.fetchVisibleFields();
    };

    onFieldToggle = async (fieldId, toggle) => {
      if (toggle) {
        await this.props.addLayoutVisibleFields({
          layoutType: this.props.layoutType,
          orgId: this.props.params.orgId,
          eventId: this.props.params.eventId,
          recordTypeId: this.props.params.typeId,
          moduleId: this.props.params.moduleId,
          fieldIds: [fieldId],
          options: {
            orgId: this.props.params.orgId,
            eventId: this.props.params.eventId
          }
        });
      } else {
        await this.props.removeLayoutVisibleFields({
          layoutType: this.props.layoutType,
          orgId: this.props.params.orgId,
          eventId: this.props.params.eventId,
          recordTypeId: this.props.params.typeId,
          moduleId: this.props.params.moduleId,
          fieldIds: [fieldId],
          options: {
            orgId: this.props.params.orgId,
            eventId: this.props.params.eventId
          }
        });
      }

      return this.fetchVisibleFields();
    };

    render() {
      const { hideModal, fields, ...props } = this.props;
      const { loading } = this.state;

      const allFields = R.map(f => ({
        ...f,
        onRemove: () => this.onFieldToggle(f.id, false),
        onToggle: () => this.onFieldToggle(f.id, !f.isVisible)
      }))(fields);

      const sortedFields = R.sortBy(f => f.name.toLowerCase())(allFields);
      const visibleFields = R.compose(
        R.sortBy(R.prop("order")),
        R.filter(R.prop("isVisible"))
      )(allFields);

      return (
        <Child
          {...{
            loading,
            hideModal,
            visibleFields,
            allFields: sortedFields,
            onFieldsReorder: this.onFieldsReorder,
            ...props
          }}
        />
      );
    }
  };
}

export default BaseLayoutController;
