import getValue from "../get-value/event-days";
import toString from "../to-string/event-days";
import sortEmpty from "./sort-empty";

export default {
  ASC: sortEmpty("event-days", (a, b, { eventDetails }) =>
    toString(getValue(a), eventDetails)
      .toLowerCase()
      .localeCompare(toString(getValue(b), eventDetails).toLowerCase())
  ),
  DESC: sortEmpty("event-days", (a, b, { eventDetails }) =>
    toString(getValue(b), eventDetails)
      .toLowerCase()
      .localeCompare(toString(getValue(a), eventDetails).toLowerCase())
  )
};
