import React from "react";
import EditorBase from "./TableConnector";
import Input from "components/Global/Editors/Countries";
import BeforeClose from "./BeforeClose";

export default class CountriesEditor extends EditorBase {
  handleClose = () => this.props.onCommit();

  render() {
    return (
      <div style={{ width: this.props.column.width - 2 }}>
        <BeforeClose do={this.handleClose}>
          <Input
            innerRef={r => {
              this.input = r;
            }}
            className="editor-main"
            value={this.state}
            onChange={this.handleChange}
            noTether
          />
        </BeforeClose>
      </div>
    );
  }
}
