export const DATA = [
  {
    id: "uuid1",
    name: "Departments",
    color: "purple",
    icon: "attach_money",
    // details
    count_of_groups: 15,
    count_of_managers: 3,
    count_of_approvers: 1,
    count_of_people_types: 2,
    item_catalog_access: "All Categories & Items",
    assigned_forms: "3 forms",
    assigned_document_requests: "3 doc requests",
    other_tasks: "2 custom tasks",
    portal_invite_link: "http://test.com",
    portal_lock_date: "Feb 25 - 2 days from now",
    allow_changes: false,
    group_applications: "2 forms",
    static_files: "2 files assigned",
    event_info_pages: "All Pages",
    pass_allocations: "15 items each",
    meal_allocations: "15 items each"
  },
  {
    id: "uuid2",
    name: "Artists",
    color: "purple",
    icon: "attach_money",
    // details
    count_of_groups: 0,
    count_of_managers: 0,
    count_of_approvers: 0,
    count_of_people_types: 0,
    item_catalog_access: null,
    assigned_forms: null,
    assigned_document_requests: null,
    other_tasks: null,
    portal_invite_link: "http://test.com",
    portal_lock_date: null,
    allow_changes: true,
    group_applications: null,
    static_files: null,
    event_info_pages: "All Pages",
    pass_allocations: null,
    meal_allocations: null
  }
];
