import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { createContext } from "redux-mvc";
import * as R from "ramda";
import module, { actions } from "../index";
import Layout from "./Layout";
import rootSaga from "../sagas";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { eventDetails } from "redux/modules/event/selectors";

import EditCollaboratorInfoModal from "components/Global/Modals/EditCollaboratorInfoModal";
import AddEventTeamUserModal from "components/Global/Modals/AddEventTeamMemberModal";

module.setRootSaga(rootSaga);

const decorate = createContext({
  module,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(
        actions.init({
          moduleId: this.props.params.moduleId,
          typeId: this.props.params.typeId
        })
      );
    }
  },
  handlers: {
    showEditUserModal(user) {
      const modal = (
        <EditCollaboratorInfoModal
          showPermissions
          hideModal={this.props.hideModal}
          userId={user.user_id}
          onUpdated={() => {
            this.store.dispatch(actions.refreshData());
          }}
        />
      );

      this.props.showModal({ content: modal });
    },
    showAddEventTeamUserModal() {
      const modal = (
        <AddEventTeamUserModal
          hideModal={this.props.hideModal}
          addToEventId={this.props.eventDetails.id}
          onAdded={() => {
            this.store.dispatch(actions.refreshData());
          }}
        />
      );

      this.props.showModal({ content: modal });
    }
  },
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: [
      "user",
      "event",
      "modal",
      "organization",
      "permissions",
      "userAccessLevels",
      "@flopflip"
    ]
  }
});

export default R.compose(
  connect(
    state => ({
      eventDetails: eventDetails(state)
    }),
    { showModal, hideModal }
  ),
  withRouter,
  decorate
)(Layout);
