import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { uniqueId } from "lodash";

class SubmissionList extends Component {
  id = uniqueId("list_");

  componentDidMount() {
    const container = `#${this.id}`;
  }

  componentDidUpdate() {}

  render() {
    return (
      <div id={this.id} styleName="container">
        {this.props.children}
      </div>
    );
  }
}

SubmissionList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.node
  ]).isRequired
};

export default CSSModules(SubmissionList, css);
