export const NAMESPACE = "OrgLightIntegrations";

export const CONTAINER_HEIGHT = 250;

export const CLASSY_FORM_ID = "IntegrationsFormClassy";
export const NEONONE_FORM_ID = "IntegrationsFormNeonOne";
export const BLACKBAUD_FORM_ID = "IntegrationsFormBlackbaud";
export const PARDOT_FORM_ID = "IntegrationsFormPardot";
export const SALESFORCE_FORM_ID = "IntegrationsFormSalesforce";
export const EVENTBRITE_FORM_ID = "IntegrationsFormEventbrite";
export const HUBSPOT_FORM_ID = "IntegrationsFormHubspot";

export const INTEGRATION_IDS = {
  CLASSY: "classy",
  NEONONE: "neonone",
  RAISERSEDGE: "raisersedge",
  PARDOT: "pardot",
  SALESFORCE: "salesforce",
  HUBSPOT: "hubspot",
  EVENTBRITE: "eventbrite"
};

export const FIELD_IDS = {
  CLASSY: {
    CLIENT_ID: "client_id",
    CLIENT_SECRET: "client_secret",
    ORG_ID: "org_id"
  },
  NEONONE: {
    API_KEY: "api_key",
    ORG_ID: "org_id"
  },
  PARDOT: {
    BUSINESS_UNIT_ID: "pardotBusinessUnitId",
    ALLOW_DUPLICATE_EMAIL: "pardotAllowDuplicateEmails",
    OVERWRITE_PARDOT_DATA: "pardotOverwritePardotData"
  },
  SALESFORCE: {
    API_USERNAME: "sfUsername",
    API_PASSWORD: "sfPassword",
    ENVIRONMENT: "sfEnvironment",
    SALESFORCE_ORG_ID: "sfExternalOrgId",
    SALESFORCE_URL: "sfInstanceUrl"
  },
  EVENTBRITE: {
    EVENTBRITE_ORG_ID: "eventbriteOrgId"
  },
  HUBSPOT: {}
};

export const ACTIONS = {
  DELETE: "delete",
  EDIT_CONNECTION_SETTINGS: "edit-connection-settings",
  EDIT_CAMPAIGNS: "edit-campaigns"
};
