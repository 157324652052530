import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const TableSizer = ({ width, height, rowCount, style, children }) => (
  <div
    styleName="container"
    style={{
      ...style,
      width,
      height: height || rowCount * 48
    }}
  >
    {children}
  </div>
);

TableSizer.defaultProps = {
  width: "100%",
  rowCount: 0
};

TableSizer.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.number,
  rowCount: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired
};

export default CSSModules(TableSizer, css);
