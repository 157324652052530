import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  form,
  fields,
  sortedFormFields
} from "redux/modules/formsV2/form/selectors";
import reduceFieldOrder from "components/Event/FormsV2/Utils/reduce-field-order";
import {
  addField as addFormField,
  deleteField as deleteFormField,
  updateFieldOrder
} from "redux/modules/formsV2/form/fields/actions";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_MODULE_FIELD_IDS from "utils/standard-module-field-ids";
import { toggleFields } from "redux/modules/formsV2/form/fields/customerBlockFields/actions";
import { eventId } from "redux/modules/event/selectors";
import {
  ORDER_ITEMS_BLOCK_TYPE,
  ORDER_CUSTOMER_BLOCK_TYPE,
  ORDER_FULFILLMENT_BLOCK_TYPE,
  ORDER_DETAILS_BLOCK_TYPE
} from "components/Event/FormsV2/Form/Views/V3_Edit/Shared/constants/block-types";

const ORDER_FIELD_SETTINGS_MAP = {
  [ORDER_CUSTOMER_BLOCK_TYPE]: {
    name: "Customer Information",
    settings: {
      description: "Enter the customer's information"
    }
  },
  [ORDER_FULFILLMENT_BLOCK_TYPE]: {
    name: "Pickup Information",
    settings: {
      method: "pickup",
      description: "Enter the information of the person picking this up"
    }
  },
  [ORDER_DETAILS_BLOCK_TYPE]: {
    name: "Order Details",
    settings: {}
  }
};

function mapStateToProps(state) {
  return {
    eventId: eventId(state),
    userId: state.user.user.id,
    form: form(state),
    formFields: fields(state),
    sortedFormFields: sortedFormFields(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { addFormField, deleteFormField, toggleFields, updateFieldOrder },
    dispatch
  );
}

function CreateContactController(Child) {
  return class Controller extends Component {
    addOrderFields = async order => {
      await Promise.all([
        this.addOrderCustomerField(order),
        this.addOrderFulfillmentField(order + 1)
      ]);

      return this.props.updateFieldOrder({
        formId: this.props.form.id,
        commit: true,
        fields: reduceFieldOrder(this.props.sortedFormFields)
      });
    };

    addOrderCustomerField = async order => {
      const { field } = await this.props.addFormField({
        version: 3,
        eventId: this.props.form.event_id,
        formId: this.props.form.id,
        name: ORDER_FIELD_SETTINGS_MAP[ORDER_CUSTOMER_BLOCK_TYPE].name,
        settings: ORDER_FIELD_SETTINGS_MAP[ORDER_CUSTOMER_BLOCK_TYPE].settings,
        type: ORDER_CUSTOMER_BLOCK_TYPE,
        order:
          typeof order === "undefined"
            ? this.props.sortedFormFields.length + 1
            : order
      });

      await this.props.toggleFields({
        fields: [
          STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME,
          STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME,
          STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL,
          STANDARD_MODULE_FIELD_IDS.CONTACTS.MOBILE_PHONE
        ].map((fieldId, idx) => ({
          formFieldId: field.id,
          moduleId: STANDARD_MODULE_IDS.contacts.id,
          fieldId,
          toggled: true,
          order: idx
        }))
      });

      return field;
    };

    addOrderFulfillmentField = async order => {
      const formField = await this.props.addFormField({
        version: 3,
        eventId: this.props.form.event_id,
        formId: this.props.form.id,
        name: ORDER_FIELD_SETTINGS_MAP[ORDER_FULFILLMENT_BLOCK_TYPE].name,
        settings:
          ORDER_FIELD_SETTINGS_MAP[ORDER_FULFILLMENT_BLOCK_TYPE].settings,
        type: ORDER_FULFILLMENT_BLOCK_TYPE,
        order:
          typeof order === "undefined"
            ? this.props.sortedFormFields.length + 1
            : order
      });

      return formField;
    };

    addOrderDetailsField = async order => {
      const formField = await this.props.addFormField({
        version: 3,
        eventId: this.props.form.event_id,
        formId: this.props.form.id,
        name: ORDER_FIELD_SETTINGS_MAP[ORDER_DETAILS_BLOCK_TYPE].name,
        settings: ORDER_FIELD_SETTINGS_MAP[ORDER_DETAILS_BLOCK_TYPE].settings,
        type: ORDER_DETAILS_BLOCK_TYPE,
        order
      });

      return formField;
    };

    removeOrderFields = async () => {
      const orderFields = this.props.formFields.filter(f =>
        [
          ORDER_ITEMS_BLOCK_TYPE,
          ORDER_CUSTOMER_BLOCK_TYPE,
          ORDER_FULFILLMENT_BLOCK_TYPE
        ].includes(f.type)
      );

      if (orderFields.length) {
        await Promise.all(
          orderFields.map(f =>
            this.props.deleteFormField({
              eventId: this.props.form.event_id,
              formId: this.props.form.id,
              fieldId: f.id
            })
          )
        );

        this.props.updateFieldOrder({
          formId: this.props.form.id,
          commit: true,
          fields: reduceFieldOrder(this.props.sortedFormFields)
        });
      }

      return true;
    };

    render() {
      const { ...props } = this.props;

      const methods = {
        removeOrderFields: this.removeOrderFields,
        addOrderFields: this.addOrderFields,
        addOrderCustomerField: this.addOrderCustomerField,
        addOrderFulfillmentField: this.addOrderFulfillmentField,
        addOrderDetailsField: this.addOrderDetailsField
      };

      return <Child {...props} {...methods} />;
    }
  };
}

export default Child =>
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CreateContactController(Child));
