import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import SubmissionSummary from "./SubmissionSummary";
import * as FormActions from "redux/modules/formsV2/form/actions";
import * as ValueActions from "redux/modules/formsV2/submission/values/actions";
import * as OverviewActions from "redux/modules/formsV2/overview/actions";
import * as SubmissionActions from "redux/modules/formsV2/submission/actions";
import * as SubmissionSelectors from "redux/modules/formsV2/submission/selectors";
import * as UserSelectors from "redux/modules/user/selectors";
import * as SubmissionReviewActions from "redux/modules/formsV2/submission/reviews/actions";
import * as LogActions from "redux/modules/log/actions";
import {
  createReview,
  removeReview
} from "redux/modules/formsV2/submission/approvals/actions";
import { addRecord } from "redux/modules/modules/records/actions";
import readOnlyFactory from "./Factories/readOnlyFactory";

function mapStateToProps(state) {
  return {
    user: UserSelectors.user(state),
    form: SubmissionSelectors.form(state),
    fields: SubmissionSelectors.sortedFormFields(state),
    details: SubmissionSelectors.eventDetails(state),
    submission: SubmissionSelectors.submission(state),
    collaborators: SubmissionSelectors.collaborators(state),
    preferences: SubmissionSelectors.preferences(state),
    comments: SubmissionSelectors.comments(state),
    isFetching: SubmissionSelectors.isFetching(state),
    errorMessages: [state.formsV2.submission.error].filter(e => e),
    isFormManager: UserSelectors.isFormManager(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addRecord,
      createReviewV3: createReview,
      removeReviewV3: removeReview,
      ...FormActions,
      ...SubmissionActions,
      ...ValueActions,
      ...SubmissionReviewActions,
      ...LogActions,
      deleteOverviewSubmission: OverviewActions.deleteOverviewSubmission
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(readOnlyFactory(SubmissionSummary));
