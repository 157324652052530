import PropTypes from "prop-types";
import React, { Component } from "react";
import Internationalized from "./Internationalized";
import Mask from "./Mask";

class FormPhone extends Component {
  render() {
    if (this.props.phoneDisplayType === "mask") {
      return <Mask {...this.props} />;
    }
    return <Internationalized {...this.props} />;
  }
}

FormPhone.propTypes = {
  phoneDisplayType: PropTypes.string
};

export default FormPhone;
