import React from "react";
import { get } from "lodash";
import { countSummary } from "components/Event/FormsV2/Overview/utils/summaries/submission";
import getCredentialSourceBadge from "components/Event/Credentials/Utils/get-credential-source-badge";
import getCatalogItemNamesFromFields from "utils/Modules/catalog-item/id-to-name-hash-from-fields";

export default (submission, eventDetails, fields) => {
  const summary = countSummary(submission.values, fields, eventDetails);
  const summaries = [];
  const catalogItems = getCatalogItemNamesFromFields(fields);
  const credentials = get(
    eventDetails,
    "module_settings.credentials.credentials",
    []
  ).reduce((hash, credential) => {
    hash[credential.id] = credential;
    return hash;
  }, {});
  const catering = get(
    eventDetails,
    "module_settings.catering.meals",
    []
  ).reduce((hash, meal) => {
    hash[meal.id] = meal;
    return hash;
  }, {});

  if (summary.credentials) {
    summaries.push({
      label: "Credentials",
      values: Object.keys(summary.credentials.requested).map(id => ({
        label:
          id in credentials ? (
            <span key={id}>
              {getCredentialSourceBadge({
                credential: credentials[id],
                size: 10
              })}
              {credentials[id].value}
            </span>
          ) : (
            "No Value"
          ),
        requested: summary.credentials.requested[id],
        approved: get(summary.credentials, `approved[${id}]`, 0),
        rejected: get(summary.credentials, `rejected[${id}]`, 0)
      }))
    });
  }

  if (summary.catering) {
    summaries.push({
      label: "Catering",
      values: Object.keys(summary.catering.requested).map(id => ({
        label: id in catering ? catering[id].value : "No Value",
        requested: summary.catering.requested[id],
        approved: get(summary.catering, `approved[${id}]`, 0),
        rejected: get(summary.catering, `rejected[${id}]`, 0)
      }))
    });
  }

  if (summary["catalog-item"]) {
    summaries.push({
      label: "Catalog Items",
      values: Object.keys(summary["catalog-item"].requested).map(id => ({
        label: id in catalogItems ? catalogItems[id] : "No Value",
        requested: summary["catalog-item"].requested[id],
        approved: get(summary["catalog-item"], `approved[${id}]`, 0),
        rejected: get(summary["catalog-item"], `rejected[${id}]`, 0)
      }))
    });
  }

  if (summary.inventory) {
    summaries.push({
      label: "Inventory",
      values: Object.keys(summary.inventory.requested).map(id => ({
        label: id,
        requested: summary.inventory.requested[id],
        approved: get(summary.inventory, `approved[${id}]`, 0),
        rejected: get(summary.inventory, `rejected[${id}]`, 0)
      }))
    });
  }
  return summaries;
};
