import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { Div, InfoIcon } from "components/Base";
import { Panel, PanelTitle } from "EventLight/Common/FieldLayout";

import {
  FIELD_IDS,
  DATE_FORMAT
} from "EventLight/Agenda/AddSessionModal/constants";

import { getLocationOptions } from "EventLight/Agenda/AddSessionModal/selectors";

import { setLocalZone } from "utils/General";

import { actions, getters } from "EventLight/Agenda/AddSessionModal/model";
// import { actions as MediaContentActions } from "ui-kit/MediaContentModal/model";
import {
  getEventTimezone,
  eventId as getEventId
} from "redux/modules/event/selectors";

import {
  Input,
  DateTimeInput,
  AdvancedWYSIWYG,
  Toggle
} from "ui-kit/Form/View";

import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

const FieldWrapperRow = ({ children }) => {
  return (
    <Div display="row.space-between.center" mb={5}>
      {children}
    </Div>
  );
};

const InputWrapperRow = ({ children, ...props }) => {
  return (
    <Div width={680} display="row" {...props}>
      {children}
    </Div>
  );
};

const Label = ({ children, tooltip, style = {} }) => {
  return (
    <Div
      color="black"
      fw={3}
      fs={3}
      display="row.flex-start.center"
      style={style}
    >
      {children}
      {tooltip ? <InfoIcon ml={1} color="gray5" tooltip={tooltip} /> : null}
    </Div>
  );
};

const decorate = connect(
  state => ({
    data: getters.data(state),
    timezone: getEventTimezone(state),
    eventId: getEventId(state),
    locationOptions: getLocationOptions(state),
    isLenndAdmin: selectFeatureFlag(flags.IS_LENND_ADMIN.NAME)
  }),
  {
    uploadHeaderImg: actions.uploadHeaderImg,
    toggleOnlineSession: actions.toggleOnlineSession,
    removeContent: actions.removeContent,
    reorderContent: actions.reorderContent,
    showContentModal: actions.showContentModal,
    removeContentVideo: actions.removeContentVideo
  }
);

const BasicDetails = ({ data, timezone }) => (
  <Div
    height={500}
    bg="neutral0"
    style={{ overflowY: "auto", padding: "25px" }}
  >
    <Panel>
      <PanelTitle>Session Details</PanelTitle>

      {/* published */}
      <FieldWrapperRow>
        <Label tooltip="Make this session visible to the public">
          Published
        </Label>
        <InputWrapperRow>
          <Toggle
            fieldId={FIELD_IDS.IS_PUBLIC}
            iniValue={R.prop(FIELD_IDS.IS_PUBLIC)(data)}
            width="auto"
          />
        </InputWrapperRow>
      </FieldWrapperRow>

      {/* title */}
      <FieldWrapperRow>
        <Label>
          Title <span style={{ color: "red" }}>*</span>
        </Label>
        <Input
          fieldId={FIELD_IDS.TITLE}
          width={680}
          iniValue={R.prop(FIELD_IDS.TITLE)(data)}
          size="big"
          inputType="outline"
          placeholder="Enter a title for your session"
        />
      </FieldWrapperRow>

      {/* date */}
      <FieldWrapperRow>
        <Label>
          Date & Time <span style={{ color: "red" }}>*</span>
        </Label>
        <InputWrapperRow display="column">
          <Div display="row.flex-start.center">
            <Div flex={1}>
              <DateTimeInput
                fieldId={FIELD_IDS.STARTS_AT}
                dateFormat={DATE_FORMAT}
                iniValue={setLocalZone(
                  R.propOr("", FIELD_IDS.STARTS_AT, data),
                  timezone
                )}
                timezone={timezone}
              />
            </Div>
            <Div ml={2} flex={1}>
              <DateTimeInput
                fieldId={FIELD_IDS.ENDS_AT}
                dateFormat={DATE_FORMAT}
                iniValue={setLocalZone(
                  R.propOr("", FIELD_IDS.ENDS_AT, data),
                  timezone
                )}
                timezone={timezone}
              />
            </Div>
          </Div>
          <Div mt={1} fs={1} color="gray5" fw={2}>
            Event Timezone: {timezone}
          </Div>
        </InputWrapperRow>
      </FieldWrapperRow>

      {/* description */}
      <FieldWrapperRow>
        <Label>Description</Label>

        <InputWrapperRow bg="white" display="column">
          <AdvancedWYSIWYG
            fieldId={FIELD_IDS.DESCRIPTION}
            iniValue={R.propOr("", FIELD_IDS.DESCRIPTION, data)}
          />
        </InputWrapperRow>
      </FieldWrapperRow>
    </Panel>

    <Panel>
      <PanelTitle description="Specify the content you would like to show for during and after this session">
        Content Details
      </PanelTitle>

      <FieldWrapperRow>
        <Label tooltip="If this is a livestream or video (e.g. YouTube, Vimeo), enter the URL of your video">
          Video / Livestream URL
        </Label>
        <InputWrapperRow display="column">
          <Input
            fieldId={FIELD_IDS.VIDEO_URL}
            width={680}
            iniValue={R.prop(FIELD_IDS.VIDEO_URL)(data)}
            size="big"
            inputType="outline"
          />
        </InputWrapperRow>
      </FieldWrapperRow>

      <FieldWrapperRow>
        <Label>Video / Livestream Title</Label>
        <InputWrapperRow display="column">
          <Input
            fieldId={FIELD_IDS.VIDEO_TITLE}
            width={680}
            iniValue={R.prop(FIELD_IDS.VIDEO_TITLE)(data)}
            size="big"
            inputType="outline"
          />
        </InputWrapperRow>
      </FieldWrapperRow>

      <FieldWrapperRow>
        <Label tooltip="If this is a video conference (e.g. WebEx, Zoom), enter the URL of your video conference">
          Video Conference URL
        </Label>
        <InputWrapperRow display="column">
          <Input
            fieldId={FIELD_IDS.CONFERENCE_URL}
            width={680}
            iniValue={R.prop(FIELD_IDS.CONFERENCE_URL)(data)}
            size="big"
            inputType="outline"
          />
        </InputWrapperRow>
      </FieldWrapperRow>

      <FieldWrapperRow>
        <Label>Video Conference Title</Label>
        <InputWrapperRow display="column">
          <Input
            fieldId={FIELD_IDS.CONFERENCE_TITLE}
            width={680}
            iniValue={R.prop(FIELD_IDS.CONFERENCE_TITLE)(data)}
            size="big"
            inputType="outline"
          />
        </InputWrapperRow>
      </FieldWrapperRow>

      <FieldWrapperRow>
        <Label tooltip="Enter a URL where attendees can go to read a transcript of this session">
          Transcript URL
        </Label>
        <InputWrapperRow display="column">
          <Input
            fieldId={FIELD_IDS.TRANSCRIPT_URL}
            width={680}
            iniValue={R.prop(FIELD_IDS.TRANSCRIPT_URL)(data)}
            size="big"
            inputType="outline"
          />
        </InputWrapperRow>
      </FieldWrapperRow>

      <FieldWrapperRow>
        <Label tooltip="Enter a URL where attendees can go to view a replay of this session">
          Replay URL
        </Label>
        <InputWrapperRow display="column">
          <Input
            fieldId={FIELD_IDS.REPLAY_URL}
            width={680}
            iniValue={R.prop(FIELD_IDS.REPLAY_URL)(data)}
            size="big"
            inputType="outline"
          />
        </InputWrapperRow>
      </FieldWrapperRow>
    </Panel>
  </Div>
);

export default decorate(BasicDetails);
