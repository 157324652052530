export const NAMESPACE = "EventLightDetails";

export const FORM_ID = "EventLightDetailsForm";

export const SETTINGS_FORM_ID = "EventLightDetailsSettingsForm";

export const DATE_FORMAT = "M/D [at] h:mma";

export const DATE_TIME_ZONE_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";

export const FIELD_IDS = {
  NAME: "name",
  SLUG: "slug",
  TYPE: "type",
  SCOPE: "scope",
  DATE_FROM: "date_from",
  DATE_TO: "date_to",
  EVENT_DAYS: "date_groups",
  REGISTRATION_TYPE: "registration_type",
  BACKGROUND_IMAGE_URL: "background_image_url",
  LOGO_IMAGE_URL: "logo_image_url",
  ENABLE_STAGE: "virtual_enable_stage",
  ENABLE_ROOMS: "virtual_enable_roundtables",
  ENABLE_NETWORKING: "virtual_enable_networking",
  ENABLE_EXPO: "virtual_enable_expo",
  CURRENCY: "currency",
  LANGUAGE: "language",
  COLOR: "highlight_color",
  TIMEZONE: "timezone",
  PUBLISHED: "is_virtual_event_published"
};

export const TYPE_TAB_INSTANCE_ID = "types";
export const STANDARD_TYPE_TAB = "standard-type";
export const CUSTOM_TYPE_TAB = "custom-type";
export const TYPE_TABS = [
  {
    id: STANDARD_TYPE_TAB,
    title: "Quick Start"
  },
  {
    id: CUSTOM_TYPE_TAB,
    title: "Advanced"
  }
];

export const FEATURE_TAB_INSTANCE_ID = "features";
export const STANDARD_FEATURE_TAB = "standard-feature";
export const CUSTOM_FEATURE_TAB = "custom-feature";
export const FEATURE_TABS = [
  {
    id: STANDARD_FEATURE_TAB,
    title: "Quick Start"
  },
  {
    id: CUSTOM_FEATURE_TAB,
    title: "Advanced"
  }
];

export const EVENT_TYPES = [
  {
    id: "Conference",
    label: "Conference"
  },
  {
    id: "Endurance",
    label: "Endurance"
  },
  {
    id: "Exhibits & Shows",
    label: "Exhibits & Shows"
  },
  {
    id: "Festivals, Fairs & Concerts",
    label: "Festivals, Fairs & Concerts"
  },
  {
    id: "Happy Hours",
    label: "Happy Hours"
  },
  {
    id: "In-Store Engagment",
    label: "In-Store Engagment"
  },
  {
    id: "Launch Event",
    label: "Launch Event"
  },
  {
    id: "Networking Event",
    label: "Networking Event"
  },
  {
    id: "Recruiting Event",
    label: "Recruiting Event"
  },
  {
    id: "Seminars & Workshops",
    label: "Seminars & Workshops"
  },
  {
    id: "Webinar",
    label: "Webinar"
  },
  {
    id: "Other",
    label: "Other"
  }
];

export const DEFAULT_SIDEBAR_LINKS = [
  {
    id: 1,
    type: "link",
    label: "Leaderboard",
    icon: "leaderboard",
    value: "http://www.website.com",
    enabled: true,
    order: 1
  },
  {
    id: 2,
    type: "link",
    label: "Photos",
    icon: "photos",
    value: "http://www.website.com",
    enabled: true,
    order: 2
  },
  {
    id: 3,
    type: "link",
    label: "Notes",
    icon: "notes",
    value: "http://www.website.com",
    enabled: true,
    order: 3
  },
  {
    id: 4,
    type: "link",
    label: "Transcripts",
    icon: "paper",
    value: "http://www.website.com",
    enabled: true,
    order: 4
  },
  {
    id: 5,
    type: "link",
    label: "Statistics",
    icon: "chart",
    value: "http://www.website.com",
    enabled: true,
    order: 5
  },
  {
    id: 6,
    type: "link",
    label: "Players Guide",
    icon: "book",
    value: "http://www.website.com",
    enabled: true,
    order: 6
  },
  {
    id: 7,
    type: "link",
    label: "Team",
    icon: "team-of-20",
    value: "http://www.website.com",
    enabled: true,
    order: 7
  },
  {
    id: 8,
    type: "intercom",
    label: "Live Support",
    icon: "support",
    value: null,
    enabled: true,
    order: 8
  }
];

export const FORM_FIELD_IDS = {
  BASIC: {},
  HUB: {
    INTERCOM_ID: "intercom_id",
    INTERCOM_METHOD: "intercom_method",
    GOOGLE_TAG_MANAGER_ID: "google_tag_manager_id",
    //
    ENABLE_SIDEBAR_VIDEO_1: "enable_sidebar_video_1",
    SIDEBAR_VIDEO_1_LABEL: "sidebar_video_1_label",
    SIDEBAR_VIDEO_1_TITLE: "sidebar_video_1_title",
    SIDEBAR_VIDEO_1_URL: "sidebar_video_1_url",
    //
    ENABLE_SIDEBAR_VIDEO_2: "enable_sidebar_video_2",
    SIDEBAR_VIDEO_2_LABEL: "sidebar_video_2_label",
    SIDEBAR_VIDEO_2_TITLE: "sidebar_video_2_title",
    SIDEBAR_VIDEO_2_URL: "sidebar_video_2_url",
    //
    ENABLE_SIDEBAR_VIDEO_3: "enable_sidebar_video_3",
    SIDEBAR_VIDEO_3_LABEL: "sidebar_video_3_label",
    SIDEBAR_VIDEO_3_TITLE: "sidebar_video_3_title",
    SIDEBAR_VIDEO_3_URL: "sidebar_video_3_url",
    //
    INTERVIEW_SCHEDULE_TITLE: "interview_schedule_title",
    INTERVIEW_SCHEDULE_SUBTITLE: "interview_schedule_subtitle",
    WATCH_INTERVIEW_REPLAYS_URL: "watch_interview_replays_url",
    LEFT_SIDEBAR_LOGO_IMAGE_URL: "left_sidebar_logo_image_url",
    ALWAYS_SHOW_CONFERENCE_ICON: "always_show_conference_icon",
    VIDEO_STREAM_PLACEHOLDER_IMAGE_URL: "video_stream_placeholder_image_url",
    VIDEO_STREAM_START_TIMESTAMP: "video_stream_start_timestamp",
    //
    EVENT_LOGO_URL: "event_logo_url",
    EVENT_WEBSITE_URL: "event_website_url",
    EVENT_TITLE: "event_title",
    EVENT_SUBTITLE: "event_subtitle",
    //
    SHOW_COUNT_OF_PEOPLE: "show_count_of_people",
    NETWORK_CHAT_LABEL: "network_chat_label",
    NETWORK_PEOPLE_LABEL: "network_people_label",
    //
    ENABLE_ALERTS_SECTION: "enable_alerts_section",
    ALERTS_TITLE: "alerts_title",
    ALERTS_SUBTITLE: "alerts_subtitle",
    ALERTS_VIEW_ALL_LABEL: "alerts_view_all_label",
    //
    ENABLE_VIDEOS_SECTION: "enable_videos_section",
    HIDE_VIDEO_TIMESTAMPS: "hide_video_timestamps",
    VIDEOS_TITLE: "videos_title",
    VIDEOS_SUBTITLE: "videos_subtitle",
    VIDEOS_VIEW_ALL_LABEL: "videos_view_all_label",
    ENABLE_GATED_CONTENT: "enable_gated_content",
    GATED_CONTENT_DESCRIPTION: "gated_content_description",
    TICKETS_WITH_ACCESS_TO_GATED_CONTENT:
      "tickets_with_access_to_gated_content",
    //
    ENABLE_SOCIAL_SECTION: "enable_social_section",
    SOCIAL_TITLE: "social_title",
    SOCIAL_SUBTITLE: "social_subtitle",
    SOCIAL_FEED_URL: "social_feed_url",
    SOCIAL_FEED_ID: "social_feed_id",
    //
    CONTENT_SECTION_1_LABEL: "content_section_1_label",
    CONTENT_SECTION_1_TYPE: "content_section_1_type",
    CONTENT_SECTION_1_LINK: "content_section_1_link",
    CONTENT_SECTION_1_CONTENT: "content_section_1_content",
    //
    CONTENT_SECTION_2_LABEL: "content_section_2_label",
    CONTENT_SECTION_2_TYPE: "content_section_2_type",
    CONTENT_SECTION_2_LINK: "content_section_2_link",
    CONTENT_SECTION_2_CONTENT: "content_section_2_content",
    //
    CONTENT_SECTION_3_LABEL: "content_section_3_label",
    CONTENT_SECTION_3_TYPE: "content_section_3_type",
    CONTENT_SECTION_3_LINK: "content_section_3_link",
    CONTENT_SECTION_3_CONTENT: "content_section_3_content",
    //
    LEFT_SIDEBAR_COLOR: "left_sidebar_color",
    LINK_COLOR: "link_color",
    LEFT_SIDEBAR_SECONDARY_COLOR: "left_sidebar_secondary_color",
    FONT_FAMILY: "font_family",
    //
    RIGHT_SIDEBAR_LINKS: "right_sidebar_links",
    RIGHT_SIDEBAR_LOGO_URL: "right_sidebar_logo_url",
    MOBILE_TITLE_LINE_1: "mobile_title_line_1",
    MOBILE_TITLE_LINE_2: "mobile_title_line_2"
  }
};
