import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { actions, getters } from "Inventory/Dashboard";
import {
  Popover,
  Div,
  MediumOutlineInput,
  SmallClearButton,
  Text0,
  Text1,
  Text2,
  AddIcon,
  EditIcon,
  NoneIcon
} from "components/Base";

const decorate = connect(
  state => ({
    plannedValue: getters.plannedValue(state)
  }),
  {
    setPlannedValue: actions.setPlannedValue,
    updatePlannedQty: actions.updatePlannedQty
  }
);

const PopoverContent = ({
  title,
  plannedValue,
  setPlannedValue,
  closePopover,
  value,
  id,
  updatePlannedQty
}) => {
  useEffect(() => {
    setPlannedValue(value);
    return () => setPlannedValue(0);
  }, []);
  return (
    <Div shadow={1} bra={1} bg="white" p={3} pb={1}>
      <Text2 bold mb={2}>
        {title}
      </Text2>
      <MediumOutlineInput
        value={plannedValue}
        type="number"
        continuous
        onChange={val => {
          setPlannedValue(val);
        }}
      />
      <Div display="row.flex-start.center" mt={1}>
        <SmallClearButton
          onClick={() => {
            closePopover();
            updatePlannedQty({ id, inventoryQuantity: plannedValue });
          }}
        >
          Done
        </SmallClearButton>
        <SmallClearButton onClick={closePopover}>Cancel</SmallClearButton>
      </Div>
    </Div>
  );
};

const AddPopover = ({
  title,
  value,
  updatePlannedQty,
  id,
  countOfPlanned,
  setPlannedValue,
  plannedValue
}) => {
  const [hoverEditIcon, setHover] = useState(false);
  return (
    <Popover
      Label={({ onClick }) => (
        <Div
          onClick={onClick}
          onMouseOver={() => setHover(true)}
          onMouseOut={() => setHover(false)}
        >
          {!R.isNil(countOfPlanned) ? (
            <Div
              display="row.flex-start.center"
              style={{ position: "relative" }}
            >
              <Text1 mr={1}>{countOfPlanned}</Text1>
              {hoverEditIcon ? <EditIcon size={10} /> : <NoneIcon size={10} />}
            </Div>
          ) : (
            <Div display="row.flex-start.center">
              <AddIcon size={8} color="primary7" />
              <Text0 color="primary7" underline mr={3}>
                ADD
              </Text0>
            </Div>
          )}
        </Div>
      )}
    >
      {({ closePopover }) => (
        <PopoverContent
          title={title}
          plannedValue={plannedValue}
          setPlannedValue={setPlannedValue}
          closePopover={closePopover}
          value={value}
          id={id}
          updatePlannedQty={updatePlannedQty}
        />
      )}
    </Popover>
  );
};

export default decorate(AddPopover);
