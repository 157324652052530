import React from "react";
import * as R from "ramda";
import { TogglableStar, BadgeWrapper, makeTopLeftBadge } from "../index";

const enhanceWithStarBadge = Comp => ({
  iconSize,
  onClickStar,
  starred,
  showStar = true,
  ...props
}) => {
  if (!R.isNil(onClickStar)) {
    const TogglableStarBadge = makeTopLeftBadge(TogglableStar);
    return (
      <BadgeWrapper>
        {showStar && (
          <TogglableStarBadge
            size={iconSize}
            onClick={onClickStar}
            active={starred}
          />
        )}
        <Comp {...props} />
      </BadgeWrapper>
    );
  } else {
    return <Comp {...props} />;
  }
};

export { enhanceWithStarBadge };
