import Helpers from "utils/Global/Helpers";

module.exports = {
  createReview: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/form/reviews`,
        credentials,
        data,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  getSubmission: (credentials, submissionId) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/form/submissions/${submissionId}`,
        credentials,
        data: {},
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  updateSubmission: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/form/submissions/${data.id}`,
        credentials,
        data,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  deleteSubmission: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/form/submissions`,
        credentials,
        data,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  addValue: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/form/submissions/${data.submissionId}/values`,
        credentials,
        data,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  addCustomerBlockValue: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/orders/orders/${data.orderId}/customer`,
        credentials,
        data,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  addFulfillmentBlockValue: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/orders/orders/${data.orderId}/fulfillment-method`,
        credentials,
        data,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
