import {
  put,
  takeEvery,
  all,
  fork,
  select,
  call,
  take
} from "redux-saga/effects";
import { actions, getters } from "Portal/PortalPagePGA/NotificationModal/model";
import { actions as PortalPagePGAActions } from "Portal/PortalPagePGA/PortalPagePGA/model";
import { actions as FormActions } from "ui-kit/Form/model";
import { FORM_INSTANCE_ID } from "./constants";
import { PAGE_ACTIONS } from "Portal/PortalPagePGA/PortalPagePGA/constants";
import { getLoggedInUserDetails } from "redux/modules/user/actions";

import {
  getCredentials,
  userId as getUserId
} from "redux/modules/user/selectors";
import { registerError } from "redux/modules/errors/actions";
import * as snackbarActions from "redux/modules/snackbar/actions";

import { getEditedField } from "./selectors";

import api from "./api";

const save = function*() {
  const phone = yield select(getters.phoneNumber);
  const section = yield select(getters.section);
  const id = yield select(getters.id);
  const isUpdatingPhoneNumber = yield select(getters.changePhone);

  try {
    const credentials = yield select(getCredentials);
    const userId = yield select(getUserId);

    yield call(
      api.updatePhoneNumber,
      {
        credentials,
        data: { phone }
      },
      userId
    );

    yield put(getLoggedInUserDetails());

    if (!isUpdatingPhoneNumber) {
      yield put(
        PortalPagePGAActions.executeAction({
          type: PAGE_ACTIONS.NOTIFY_ME,
          status: true,
          section,
          id,
          force: true
        })
      );
    } else {
      yield put(
        snackbarActions.showSnackbar({
          message: `Phone number updated`,
          action: "OK"
        })
      );
    }

    yield put(actions.hideModal());
  } catch (e) {
    yield put(
      registerError([
        {
          system: e,
          user: "An error occurred updating phone number"
        }
      ])
    );
  }
};

const fieldUpdate = function*({ id, fieldId }) {
  const newValue = yield select(getEditedField, {
    instanceId: id,
    fieldId
  });

  yield put(actions.setPhoneNumber(newValue));
};

const updateNotification = function*({ payload }) {
  if (payload.hasNumber && !payload.changeNumber) {
    yield call(save, payload.status);
  }
};

const watchUpdateFields = function*() {
  for (;;) {
    const {
      meta: { instanceId, fieldId }
    } = yield take(FormActions.setFieldValue.type);
    if (instanceId === FORM_INSTANCE_ID) {
      yield call(fieldUpdate, {
        id: instanceId,
        fieldId
      });
    }
  }
};

const watchSave = function*() {
  yield takeEvery(actions.save.type, save);
};

const watchUpdateNotification = function*() {
  yield takeEvery(actions.updateNotification.type, updateNotification);
};

const rootSaga = function*() {
  yield all([
    fork(watchSave),
    fork(watchUpdateNotification),
    fork(watchUpdateFields)
  ]);
};

export default rootSaga;
