import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { actions, getters } from "Modules/UploadDocumentTemplateModal";
import DeleteConfirmation from "components/Global/CRM/Popovers/DeleteConfirmation";
import Tooltip from "components/Global/CRM/Tooltip";
import {
  Div,
  Text1,
  Text2,
  MediumOutlineInput,
  BigOutlineButton,
  Dropdown,
  DownloadIcon,
  UploadIcon,
  AttachIcon
} from "components/Base";

const decorate = connect(
  state => ({
    title: getters.title(state),
    modules: getters.modules(state),
    selectedModuleId: getters.selectedModuleId(state),
    filename: getters.filename(state),
    template: getters.template(state),
    templateUrl: getters.templateUrl(state),
    templateImageUrl: getters.imageUrl(state)
  }),
  {
    setTitle: actions.setTitle,
    setSelectedModuleId: actions.setSelectedModuleId,
    uploadDocument: actions.uploadDocument,
    downloadDocument: actions.downloadDocument,
    deleteTemplate: actions.deleteTemplate
  }
);

const Body = ({
  title,
  setTitle,
  modules,
  selectedModuleId,
  setSelectedModuleId,
  uploadDocument,
  downloadDocument,
  template,
  templateUrl,
  templateImageUrl,
  deleteTemplate
}) => (
  <Div width={1} p={5}>
    <Div width={1} mb={4} display="row.flex-start.center">
      <Div width={120} display="row.flex-end.center" p={2}>
        <Div width={100} display="row.flex-end.center">
          <Text2 mb={1} bold color="black">
            Template Title
          </Text2>
        </Div>
      </Div>
      <MediumOutlineInput
        flex={1}
        continuous
        value={title}
        onChange={value => setTitle(value)}
        bg="white"
      />
    </Div>
    <Div width={1} mb={4} display="row.flex-start.center">
      <Div
        width={120}
        display="row.flex-end.center"
        style={{ overflow: "visible" }}
        mb={1}
        pr={2}
      >
        <Tooltip
          message="The base module ensures that your merge tags work properly. For
          example: If your document primarily references company information
          & related data, you would select Accounts as your base module."
          placement="bottom"
        />
        <Text2 bold color="black" ml={1}>
          Base Module
        </Text2>
      </Div>
      <Div flex={1}>
        <Dropdown
          options={modules.map(({ id, name }) => ({
            label: name,
            value: id
          }))}
          width="230px"
          selected={selectedModuleId}
          onChange={({ value }) => setSelectedModuleId(value)}
        />
      </Div>
    </Div>
    <Div width={1} display="row">
      <Div
        width={199}
        height={208}
        mr={2}
        bg="white"
        bra={1}
        display="column"
        style={{ position: "relative" }}
      >
        {!R.isNil(templateImageUrl) && (
          <Div width={199} height={208} bra={1} style={{ overflow: "hidden" }}>
            <img src={templateImageUrl} width={199} />
          </Div>
        )}
        <Div
          style={{ position: "absolute", bottom: 0, left: 0 }}
          p={3}
          width={1}
          bg="white"
        >
          {!R.isEmpty(title) ? (
            <Div display="row.flex-start.center">
              <AttachIcon />
              <Text1 ml={1} truncate>
                {title}
              </Text1>
            </Div>
          ) : null}
        </Div>
      </Div>
      <Div flex={1}>
        <BigOutlineButton
          width={1}
          LeftIcon={DownloadIcon}
          display="row.flex-start.center"
          onClick={() => downloadDocument(templateUrl)}
        >
          Download File
        </BigOutlineButton>
        <BigOutlineButton
          width={1}
          LeftIcon={UploadIcon}
          display="row.flex-start.center"
          mt={2}
          onClick={() => uploadDocument()}
        >
          Replace File
        </BigOutlineButton>
        {!R.isNil(template) ? (
          <DeleteConfirmation
            onConfirm={() => {
              deleteTemplate({ templateId: template.id });
            }}
            text="Are you sure you want to delete this item?"
          >
            <Text1
              mt={2}
              underline
              color="neutral5"
              style={{ cursor: "pointer" }}
            >
              Delete this template
            </Text1>
          </DeleteConfirmation>
        ) : null}
      </Div>
    </Div>
  </Div>
);

export default decorate(Body);
