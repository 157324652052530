import PropTypes from "prop-types";
import React from "react";
import Popover from "../Popover";
import CanUserDo from "components/Global/Security/CanUserDo";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import Base from "../Base";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as R from "ramda";

import RunReportModal from "Reports/RunReportModal/View";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

class DataTableActionsBar extends Base {
  showRunReportModal = () => {
    this.props.showModal({
      content: (
        <RunReportModal
          moduleId={this.props.moduleId}
          recordIds={this.props.selectedRows}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const {
      module,
      selectedRows,
      recordTypes,
      moduleId,
      deselectAllRows,
      fields
    } = this.props;
    const selectedRowsCount = selectedRows.length;
    const pluralize = text => `${text}${selectedRowsCount !== 1 ? "s" : ""}`;
    const accountLookupField = fields.find(
      f =>
        f.type === "lookup" &&
        R.path(["moduleId"])(f.settings) === STANDARD_MODULE_IDS.accounts.id
    );

    return (
      <div styleName="container">
        <div styleName="count">{selectedRowsCount} selected</div>
        <CanUserDo action={`${moduleId}_update`} className={css.candoWrapper}>
          {module.has_approval ? (
            <div styleName="candoWrapper">
              <div
                role="button"
                tabIndex={0}
                styleName="menuItem"
                onClick={this.approveRecords}
              >
                <i className="material-icons">check</i>
                Approve
              </div>
              <div
                role="button"
                tabIndex={0}
                styleName="menuItem"
                onClick={this.denyRecords}
              >
                <i className="material-icons">close</i>
                Reject
              </div>
            </div>
          ) : null}

          <div
            role="button"
            tabIndex={0}
            styleName="menuItem"
            onClick={this.showEditRecordsModal}
          >
            <i className="material-icons">edit</i>
            Edit
          </div>

          <div
            role="button"
            tabIndex={0}
            styleName="menuItem"
            onClick={this.showRunReportModal}
          >
            <i className="material-icons">show_chart</i>
            Run Report
          </div>

          <div
            role="button"
            tabIndex={0}
            styleName="menuItem"
            onClick={this.sendEmailModal}
          >
            Send Email
            {selectedRowsCount !== 1 ? "s" : ""}
          </div>

          <Popover label="More">
            {({ closeOnClick }) => (
              <Menu>
                {recordTypes.length > 1 ? (
                  <MenuItem
                    primaryText={pluralize("Change Type")}
                    onClick={closeOnClick(this.changeRecordTypes)}
                  />
                ) : null}
                {accountLookupField ? (
                  <MenuItem
                    primaryText={pluralize("Create Submission")}
                    onClick={closeOnClick(() =>
                      this.createSubmissionModal(accountLookupField)
                    )}
                  />
                ) : null}
                <MenuItem
                  primaryText="Assign Record Owners"
                  onClick={closeOnClick(this.showAssignOwnersModal)}
                />
                <MenuItem
                  primaryText={pluralize("Generate Document")}
                  onClick={closeOnClick(this.showGenerateDocumentsModal)}
                />
              </Menu>
            )}
          </Popover>
        </CanUserDo>

        <CanUserDo action={`${moduleId}_delete`}>
          <div
            role="button"
            tabIndex={0}
            styleName="menuItem"
            onClick={this.showDeleteRecordsModal}
          >
            <i className="material-icons">delete</i>
            Delete
          </div>
        </CanUserDo>
        <div
          role="button"
          tabIndex={0}
          styleName="menuTextButton"
          onClick={deselectAllRows}
        >
          Clear selected
        </div>
      </div>
    );
  }
}

DataTableActionsBar.propTypes = {
  module: PropTypes.shape({
    has_approval: PropTypes.bool
  }).isRequired,
  deselectAllRows: PropTypes.func.isRequired,
  moduleId: PropTypes.string,
  selectedRows: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default CSSModules(DataTableActionsBar, css);
