import React from "react";

import { put, all, takeEvery, fork, select, call } from "redux-saga/effects";
import * as R from "ramda";
import moveItemInArray from "utils/move-item-in-array";

import { getCredentials } from "redux/modules/user/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { makeFuture } from "utils/General/sagas";
import { getItemGroups } from "./selectors";

import { actions, getters } from "./model";
import { registerError } from "redux/modules/errors/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal } from "redux/modules/modal/actions";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import ItemTypeModal from "components/Event/Settings/Catalog/Modals/ItemType";
import CredentialGroupModal from "components/Event/Settings/Module/Modals/FieldGroup";
import DeleteCredentialGroupModal from "components/Event/Settings/Credentials/Modals/DeleteCredentialGroup";

import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventDetails = yield select(getEventDetails);
  const itemTypeId = yield select(getters.itemTypeId);

  return {
    credentials,
    eventDetails,
    itemTypeId
  };
};

const loadData = function*({ payload = {} }) {
  try {
    if (payload.typeId) {
      yield put(actions.setItemTypeId(payload.typeId));
    }

    if (!payload.refresh) {
      yield put(actions.setLoading(true));
    }
    const { credentials, eventDetails, itemTypeId } = yield call(getParams);

    const { payload: itemGroups } = yield call(
      Api.getItemGroupsByEventAndType,
      credentials,
      eventDetails.id,
      itemTypeId
    );

    yield put(
      actions.setInitialData({
        items: itemGroups.reduce((a, b) => [...a, ...b.items], []),
        itemGroups: itemGroups
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting item catalog"
        }
      ])
    );
  } finally {
    if (!payload.refresh) {
      yield put(actions.setLoading(false));
    }
  }
};

//

const handleShowAddGroupModal = function*() {
  const handleModal = makeFuture();
  yield put(
    showModal({
      content: (
        <CredentialGroupModal onSave={handleModal.done} label="Category" />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleModal.onRealized);
};

const showAddGroupModal = function*() {
  const result = yield call(handleShowAddGroupModal);

  if (result) {
    const { credentials, eventDetails, itemTypeId } = yield call(getParams);
    const itemGroups = yield select(getItemGroups);

    yield call(Api.addItemGroup, credentials, {
      eventId: eventDetails.id,
      typeId: itemTypeId,
      name: result.name,
      order: !R.isNil(R.path([itemGroups.length - 1, "order"], itemGroups))
        ? itemGroups[itemGroups.length - 1].order + 1
        : 0
    });

    yield call(loadData, { payload: { refresh: true } });

    yield put(showSnackbar({ message: "Category added", action: "OK" }));
  }
};

//

const handleShowUpdateGroupModal = function*({ group }) {
  const handleModal = makeFuture();
  yield put(
    showModal({
      content: (
        <CredentialGroupModal
          data={{
            ...group
          }}
          onSave={handleModal.done}
          label="Category"
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleModal.onRealized);
};

const showUpdateGroupModal = function*({ payload: group }) {
  const result = yield call(handleShowUpdateGroupModal, { group });

  if (result) {
    const { credentials } = yield call(getParams);

    yield call(Api.updateItemGroup, credentials, {
      groupId: group.id,
      group: {
        id: group.id,
        name: result.name,
        order: result.order
      }
    });

    yield call(loadData, { payload: { refresh: true } });

    yield put(showSnackbar({ message: "Category updated", action: "OK" }));
  }
};

//

const handleShowDeleteGroupModal = function*({ group }) {
  const handleModal = makeFuture();
  yield put(
    showModal({
      content: (
        <DeleteCredentialGroupModal
          name={group.name}
          handleDelete={() => handleModal.done(true)}
          label="Category"
        />
      )
    })
  );
  return yield call(handleModal.onRealized);
};

const showDeleteGroupModal = function*({ payload: group }) {
  const result = yield call(handleShowDeleteGroupModal, { group });

  if (result) {
    const { credentials } = yield call(getParams);
    const itemGroups = yield select(getItemGroups);

    yield call(Api.deleteItemGroup, credentials, {
      groupId: group.id,
      replaceWithGroupId: itemGroups.filter(g => g.id !== group.id)[0].id
    });

    yield call(loadData, { payload: { refresh: true } });

    yield put(showSnackbar({ message: "Category deleted", action: "OK" }));
  }
};

//

const handleShowItemModal = function*({ itemTypeId, itemId, clone }) {
  const handleModal = makeFuture();
  yield put(
    showModal({
      content: (
        <ItemTypeModal
          typeId={itemTypeId}
          itemId={itemId}
          clone={clone}
          onDone={payload =>
            itemId && !clone
              ? handleModal.done({ action: "update", payload })
              : handleModal.done({ action: "add", payload })
          }
          onUpdate={payload =>
            handleModal.done({ action: "updateDone", payload })
          }
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleModal.onRealized);
};

const showItemModal = function*({
  payload: { itemId, clone } = { itemId: null, clone: false }
}) {
  const { credentials, itemTypeId } = yield call(getParams);
  const { action, payload } = yield call(handleShowItemModal, {
    itemTypeId,
    itemId,
    clone
  });

  if (action === "updateDone") {
    yield call(loadData, { payload: { refresh: true } });
  } else if (action === "update") {
    yield call(Api.updateItem, credentials, payload);
    yield put(showSnackbar({ message: "Item updated", action: "OK" }));
    yield call(loadData, { payload: { refresh: true } });
  } else if (action === "add") {
    yield call(Api.addItem, credentials, payload);
    yield put(showSnackbar({ message: "Item created", action: "OK" }));
    yield call(loadData, { payload: { refresh: true } });
  }
};

//

const deleteItem = function*({ payload: itemId }) {
  const { credentials } = yield call(getParams);
  yield call(Api.deleteItem, credentials, {
    itemId
  });
  yield put(showSnackbar({ message: "Item deleted", action: "OK" }));
  yield call(loadData, { payload: { refresh: true } });
};

//

const moveItemUp = function*({ payload: { groupId, currentPosition } }) {
  const { credentials, eventDetails } = yield call(getParams);
  const itemGroups = yield select(getItemGroups);

  const items = itemGroups.find(g => g.id === groupId).items;

  if (currentPosition === 0) return;

  yield call(Api.bulkUpdateItems, credentials, {
    eventId: eventDetails.id,
    bulk: true,
    items: moveItemInArray(items, currentPosition, currentPosition - 1).map(
      (g, order) => ({
        id: g.id,
        order
      })
    )
  });
  yield call(loadData, { payload: { refresh: true } });
};

//

const moveItemDown = function*({ payload: { groupId, currentPosition } }) {
  const { credentials, eventDetails } = yield call(getParams);
  const itemGroups = yield select(getItemGroups);

  const items = itemGroups.find(g => g.id === groupId).items;

  if (currentPosition === items.length - 1) return;

  yield call(Api.bulkUpdateItems, credentials, {
    eventId: eventDetails.id,
    bulk: true,
    items: moveItemInArray(items, currentPosition, currentPosition + 1).map(
      (g, order) => ({
        id: g.id,
        order
      })
    )
  });
  yield call(loadData, { payload: { refresh: true } });
};

//

const moveGroupUp = function*({ payload: currentPosition }) {
  const { credentials, eventDetails } = yield call(getParams);
  const itemGroups = yield select(getItemGroups);

  if (currentPosition === 0) return;

  yield call(Api.bulkUpdateItemGroups, credentials, {
    eventId: eventDetails.id,
    bulk: true,
    groups: moveItemInArray(
      itemGroups,
      currentPosition,
      currentPosition - 1
    ).map((g, order) => ({
      id: g.id,
      order
    }))
  });
  yield call(loadData, { payload: { refresh: true } });
};

//

const moveGroupDown = function*({ payload: currentPosition }) {
  const { credentials, eventDetails } = yield call(getParams);
  const itemGroups = yield select(getItemGroups);

  if (currentPosition === itemGroups.length - 1) return;

  yield call(Api.bulkUpdateItemGroups, credentials, {
    eventId: eventDetails.id,
    bulk: true,
    groups: moveItemInArray(
      itemGroups,
      currentPosition,
      currentPosition + 1
    ).map((g, order) => ({
      id: g.id,
      order
    }))
  });
  yield call(loadData, { payload: { refresh: true } });
};

//

const reorderItems = function*({ payload: newOrder }) {
  const { credentials, eventDetails } = yield call(getParams);

  yield call(Api.bulkUpdateItems, credentials, {
    eventId: eventDetails.id,
    bulk: true,
    items: newOrder.map(({ id }, i) => ({ id, order: i }))
  });
  yield call(loadData, { payload: { refresh: true } });
};

//

const watchInit = function*() {
  yield takeEvery(actions.init.type, loadData);
};

const watchShowAddGroupModal = function*() {
  yield takeEvery(actions.showAddGroupModal.type, showAddGroupModal);
};

const watchShowUpdateGroupModal = function*() {
  yield takeEvery(actions.showUpdateGroupModal.type, showUpdateGroupModal);
};

const watchShowDeleteGroupModal = function*() {
  yield takeEvery(actions.showDeleteGroupModal.type, showDeleteGroupModal);
};

const watchShowItemModal = function*() {
  yield takeEvery(actions.showItemModal.type, showItemModal);
};

const watchDeleteItem = function*() {
  yield takeEvery(actions.deleteItem.type, deleteItem);
};

const watchMoveItemUp = function*() {
  yield takeEvery(actions.moveItemUp.type, moveItemUp);
};

const watchMoveItemDown = function*() {
  yield takeEvery(actions.moveItemDown.type, moveItemDown);
};

const watchMoveGroupUp = function*() {
  yield takeEvery(actions.moveGroupUp.type, moveGroupUp);
};

const watchMoveGroupDown = function*() {
  yield takeEvery(actions.moveGroupDown.type, moveGroupDown);
};

const watchReorderItems = function*() {
  yield takeEvery(actions.reorderItems.type, reorderItems);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchShowAddGroupModal),
    fork(watchShowUpdateGroupModal),
    fork(watchShowDeleteGroupModal),
    fork(watchShowItemModal),
    fork(watchDeleteItem),
    fork(watchMoveItemUp),
    fork(watchMoveItemDown),
    fork(watchMoveGroupUp),
    fork(watchMoveGroupDown),
    fork(watchReorderItems)
  ]);
};

export default rootSaga;
