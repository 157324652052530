import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import { actions } from "Event/MyApprovals/model";

import { registerError } from "redux/modules/errors/actions";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { getCredentials } from "redux/modules/user/selectors";

import Api from "./api";

const init = function*() {
  try {
    yield put(actions.setLoading(true));
    const credentials = yield select(getCredentials);
    const eventDetails = yield select(getEventDetails);
    const result = yield call(Api.getMyApprovals, credentials, eventDetails.id);
    yield all([
      put(actions.setLoading(false)),
      put(actions.setMyApprovals(result.payload))
    ]);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred getting my approvals"
          }
        ])
      ),
      put(actions.setLoading(false))
    ]);
  } finally {
    yield put(actions.setInitLoaded(true));
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([fork(watchInit)]);
};

export default rootSaga;
