import React from "react";

import * as R from "ramda";
import { connect } from "react-redux";

import { getStateValue } from "ui-kit/Form/selectors";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";

import { Div } from "components/Base";

const decorate = R.compose(
  WithFormInstanceConsumer({
    fieldType: FIELD_TYPES.VALUE
  }),
  connect((state, props) => ({
    value: getStateValue(state, props)
  }))
);

export const UCFieldValue = ({
  value = "",
  render: Render = Div,
  children,
  ...props
}) =>
  typeof children === "function" ? (
    children(value)
  ) : (
    <Render {...props}>{value}</Render>
  );

export const FieldValue = decorate(UCFieldValue);
