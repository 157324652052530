/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  getReplaysList: ({ credentials, filters }) =>
    new Promise((success, error) => {
      const options = {
        skipUserId: true,
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/integrations/wsc/videos`,
        credentials,
        qs: filters,
        success,
        error
      };
      Helpers.request(options);
    })
};
