import * as R from "ramda";
import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import moment from "moment";

import { noop, liftToArr } from "utils/General";

import EventDaysSelector from "components/Global/EventDaySelector";
import MarkDaysAs from "./MarkDaysAs";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";

import { actions } from "ui-kit/Form/model";
import { getValue } from "ui-kit/Form/selectors";
import { FIELD_TYPES } from "ui-kit/Form/constants";

const defaultDayGroups = [
  {
    id: "uuid-load-in-days",
    name: "Load In Days",
    color: "#E19517",
    days: []
  },
  {
    id: "uuid-show-days",
    name: "Show Days",
    color: "#01C07C",
    days: []
  },
  {
    id: "uuid-load-out-days",
    name: "Load Out Days",
    color: "#D0021B",
    days: []
  },
  {
    id: 0,
    name: "Day Off",
    color: "#ffffff",
    days: []
  }
];

const buildDayMap = ({
  startDate,
  endDate,
  dayGroups,
  selectedDays = {},
  format = "YYYY-MM-DD"
}) => {
  const map = {};
  dayGroups.forEach(group => {
    group.days.forEach(day => {
      map[day] = {
        color: group.color,
        isSelected: R.contains(day, selectedDays)
      };
    });
  });
  for (
    let m = moment(startDate).utc();
    m.isSameOrBefore(endDate);
    m.add(1, "days")
  ) {
    const day = m.format(format);
    if (!R.has(day, map)) {
      map[day] = {
        color: "#333333",
        isSelected: R.contains(day, selectedDays)
      };
    }
  }
  return map;
};

const decorate = R.compose(
  WithFormInstanceConsumer({
    fieldType: FIELD_TYPES.MARK_EVENT_DAYS
  }),
  connect(
    (state, props) => ({
      value: getValue(state, props)
    }),
    bindInstance({
      onChange: actions.setFieldValue,
      setIniValue: actions.setIniValue
    })
  ),
  CSSModules(css)
);

export const MarkEventDays = decorate(
  ({
    fieldId,
    fieldType,
    value = {},
    iniValue: iniDayGroups = defaultDayGroups,
    startDate,
    endDate,
    onChange = noop,
    setIniValue = noop,
    ...props
  }) => {
    useEffect(() => {
      setIniValue(
        { init: { selectedDays: [], dayGroups: iniDayGroups } },
        {
          meta: { fieldId, fieldType }
        }
      );
    }, []);
    const selectedDays = R.propOr([], "selectedDays", value);
    const dayGroups = R.propOr(defaultDayGroups, "dayGroups", value);

    const dayMap = useMemo(
      () => buildDayMap({ startDate, endDate, dayGroups, selectedDays }),
      [startDate, endDate, dayGroups, value]
    );

    return (
      <div className={css.daySelectorContainer}>
        <div className={css.daySelector}>
          <EventDaysSelector
            startDate={startDate}
            endDate={endDate}
            dayGroups={dayGroups}
            showDayGroups={false}
            dayMap={dayMap}
            showCountOfSelectedDays
            noShadow
            className={css.eventDays}
            countOfSelectedDays={R.length(selectedDays)}
            handleDayClick={day =>
              onChange(
                { toggle: liftToArr(day) },
                { meta: { fieldId, fieldType } }
              )
            }
            {...props}
          />
        </div>
        <MarkDaysAs
          groups={dayGroups}
          selectGroup={groupId =>
            onChange({ groupId }, { meta: { fieldId, fieldType } })
          }
        />
      </div>
    );
  }
);
