import React from "react";
import { connect } from "react-redux";
import { getters } from "Portal/CustomizeSectionsModal/model";
import { Div } from "components/Base";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import Body from "./Body";
import Footer from "./Footer";
import Loading from "ui-kit/Loading";

const decorate = connect(state => ({
  name: getters.name(state),
  loading: getters.loading(state)
}));

const Layout = ({
  hideCustomizeSectionsModal,
  fetchPermissionSets,
  name,
  loading
}) => (
  <MiniModalWrapper
    hideModal={hideCustomizeSectionsModal}
    title={`Customize Portal for: ${name}`}
    isPortal={false}
    showModal
    style={{ width: 1064, height: 700 }}
  >
    {loading ? (
      <Div bg="neutral0" style={{ width: 1064, height: 700 }}>
        <Loading />
      </Div>
    ) : (
      <>
        <Body selectedTypeName={name} />
        <Footer
          hideModal={hideCustomizeSectionsModal}
          fetchPermissionSets={fetchPermissionSets}
        />
      </>
    )}
  </MiniModalWrapper>
);

export default decorate(Layout);
