import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import {
  Div,
  Text2,
  Text3,
  Text5,
  MediumOutlineInput,
  TextAreaInput
} from "components/Base";
import { getters, actions } from "../model";
import {
  getNotDraggableAccountFieldIds,
  getNotDraggableAccountFields,
  getDraggableAccountSectionsAndFields
} from "../selectors";
import FormQuestions from "./FormQuestions";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

const decorate = connect(
  state => ({
    groupTitle: getters.groupTitle(state),
    groupDescription: getters.groupDescription(state),
    accountsFields: getters.accountsFields(state),
    notDraggableAccountFieldIds: getNotDraggableAccountFieldIds(state),
    notDraggableAccountFields: getNotDraggableAccountFields(state),
    requiredAccountIds: getters.requiredAccountIds(state),
    draggableAccountFields: getDraggableAccountSectionsAndFields(state),
    selectedAccountFields: getters.selectedAccountFields(state),
    selectedAccountsOrder: getters.selectedAccountsOrder(state),
    accountsTempDescription: getters.accountsTempDescription(state),
    accountsTempAlias: getters.accountsTempAlias(state)
  }),
  {
    setGroupTitle: actions.setGroupTitle,
    setGroupDescription: actions.setGroupDescription,
    removeGroupRow: actions.removeGroupRow,
    toggleAccountRequiredField: actions.toggleAccountRequiredField,
    reorderAccounts: actions.reorderAccounts,
    updateAccountsDescription: actions.updateAccountsDescription,
    setAccountsTempDescription: actions.setAccountsTempDescription,
    addAccountsHeaderSection: actions.addAccountsHeaderSection,
    removeAccountsHeaderSection: actions.removeAccountsHeaderSection,
    updateAccountsDescriptionHeaderSection:
      actions.updateAccountsDescriptionHeaderSection,
    updateAccountsTitleHeaderSection: actions.updateAccountsTitleHeaderSection,
    setAccountsTempAlias: actions.setAccountsTempAlias,
    updateAccountsAlias: actions.updateAccountsAlias
  }
);

const GroupInformation = ({
  groupTitle,
  setGroupTitle,
  setGroupDescription,
  groupDescription,
  handlers,
  accountsFields,
  notDraggableAccountFieldIds,
  notDraggableAccountFields,
  requiredAccountIds,
  draggableAccountFields,
  selectedAccountFields,
  removeGroupRow,
  toggleAccountRequiredField,
  selectedAccountsOrder,
  reorderAccounts,
  updateAccountsDescription,
  accountsTempDescription,
  setAccountsTempDescription,
  addAccountsHeaderSection,
  removeAccountsHeaderSection,
  updateAccountsDescriptionHeaderSection,
  updateAccountsTitleHeaderSection,
  accountsTempAlias,
  setAccountsTempAlias,
  updateAccountsAlias
}) => {
  return (
    <Div
      style={{
        overflow: "auto",
        position: "relative"
      }}
      height={1}
      width={1}
      bg="neutral0"
    >
      <Div
        py={5}
        px={8}
        style={{ position: "absolute", top: 0, left: 0 }}
        width={1}
      >
        <Text5 color="black" bold>
          Group / Company Information
        </Text5>
        <Text2>Collect any new group's primary information.</Text2>
        <Div bra={1} bg="white" shadow={1} p={4} mt={4} mb={3}>
          <Div display="row.flex-start.center">
            <Text3 bold mr={3}>
              Section Title
            </Text3>
            <Div flex={1}>
              <MediumOutlineInput
                width={1}
                value={groupTitle}
                onChange={setGroupTitle}
              />
            </Div>
          </Div>
          <Div mt={4} display="row.space-between.center" mb={2}>
            <Text3 bold>Description & Instructions</Text3>
          </Div>
          <TextAreaInput
            width={1}
            value={groupDescription}
            onChange={setGroupDescription}
            height={85}
            style={{ resize: "vertical" }}
            mt={1}
          />
        </Div>
        <FormQuestions
          removeRow={removeGroupRow}
          requiredFields={notDraggableAccountFields}
          draggableFields={draggableAccountFields}
          showFieldsModal={() =>
            handlers.showAccountFieldsModal({
              fields: accountsFields,
              notDraggableFieldIds: notDraggableAccountFieldIds,
              requiredFields: requiredAccountIds,
              visibleFields: R.map(R.prop("id"), selectedAccountFields),
              fieldOrder: selectedAccountsOrder
            })
          }
          toggleRequiredField={toggleAccountRequiredField}
          reorderFields={reorderAccounts}
          updateDescription={updateAccountsDescription}
          tempDescription={accountsTempDescription}
          setTempDescription={setAccountsTempDescription}
          editField={fieldId =>
            handlers.editField({
              moduleId: STANDARD_MODULES.accounts.id,
              fieldId
            })
          }
          showAddFieldModal={() =>
            handlers.showAddFieldModal({
              moduleId: STANDARD_MODULES.accounts.id
            })
          }
          addHeaderSection={addAccountsHeaderSection}
          removeHeaderSection={removeAccountsHeaderSection}
          updateDescriptionHeaderSection={
            updateAccountsDescriptionHeaderSection
          }
          updateTitleHeaderSection={updateAccountsTitleHeaderSection}
          tempAlias={accountsTempAlias}
          setTempAlias={setAccountsTempAlias}
          updateAlias={updateAccountsAlias}
        />
      </Div>
    </Div>
  );
};

export default decorate(GroupInformation);
