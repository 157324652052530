import PropTypes from "prop-types";
import React, { Component } from "react";
import css from "./styles.scss";
import CSSModules from "react-css-modules";
import autobind from "autobind-decorator";
import {
  portalModules as modules,
  portalFormats as formats
} from "utils/Quill/Options";
import { MODULES } from "components/Global/NavIcons";
import Loading from "components/Global/Loading";

import DocumentTitle from "components/Global/DocumentTitle";
import MessagePanel from "components/Event/Settings/Portal/MessagePanel";
import Heading from "components/Event/Settings/Heading";
import EditPortalMessageModal from "components/Event/Settings/Modals/EditPortalMessageModal";
import CircularProgress from "material-ui/CircularProgress";
import EmptyState from "components/Global-2016/EmptyState";

@CSSModules(css)
class PortalSettings extends Component {
  componentDidMount() {
    this.props.fetchMessages(this.props.details.uuid);
  }

  @autobind
  editTemplate(message) {
    this.props.updateMessage(message, this.props.details.uuid);
  }

  @autobind
  deleteTemplate(id) {
    this.props.deleteMessage(id, this.props.details.uuid);
  }

  @autobind
  createTemplate(message) {
    this.props.createMessage(
      { eventId: this.props.params.eventId, ...message },
      this.props.details.uuid
    );
  }

  @autobind
  showNewModal() {
    const emptyTemplate = {
      title: "",
      content_formatted: "",
      content_plaintext: ""
    };
    this.props.showModal({
      content: (
        <EditPortalMessageModal
          done={this.createTemplate}
          template={emptyTemplate}
          wysiwygProps={{ formats, modules }}
        />
      )
    });
  }

  render() {
    const { list, showModal, loading, details } = this.props;

    const title = `${details.name} - Details`;

    const templateProps = {
      editTemplate: this.editTemplate,
      deleteTemplate: this.deleteTemplate,
      showModal
    };

    let messages = Object.keys(list).map(template => (
      <MessagePanel
        key={template}
        template={list[template]}
        {...templateProps}
      />
    ));

    messages.push(
      <span key="link" styleName="newMessageLink" onClick={this.showNewModal}>
        Add new event update
      </span>
    );

    if (!Object.keys(list).length) {
      messages = (
        <div>
          No event updates have been added.&nbsp;<span
            onClick={this.showNewModal}
            styleName="clickHere"
          >
            Click here
          </span>{" "}
          to add one.
        </div>
      );
    }

    return (
      <DocumentTitle title={title}>
        <div styleName="container">
          <div styleName="subContainer">
            <Heading
              title="Portal Settings"
              linkTo={MODULES.portal.path({}, details)}
              linkTitle="Go to portal"
              target="_blank"
            />
            <div styleName="body">
              <div styleName="bodyHeading">
                Event Updates
                {loading && messages.length ? (
                  <CircularProgress size={17.85} mode="indeterminate" />
                ) : (
                  ""
                )}
              </div>
              <div styleName="well">
                {loading && !messages.length ? (
                  <Loading content="Loading updates..." />
                ) : (
                  messages
                )}
              </div>
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

PortalSettings.propTypes = {
  createMessage: PropTypes.func.isRequired,
  deleteMessage: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired,
  fetchMessages: PropTypes.func.isRequired,
  list: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  updateMessage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
};

export default PortalSettings;
