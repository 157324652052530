import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Checkbox from "material-ui/Checkbox";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class DateTimeEditorSettings extends Component {
  @autobind
  onUseDateTimeTimezoneChange() {
    this.props.onChange({
      ...this.props.value,
      useDateTimeTimezone: !this.props.value.useDateTimeTimezone
    });
  }
  render() {
    const { value } = this.props;
    const { useDateTimeTimezone } = value;
    return (
      <div>
        <div className="input-wrapper">
          <Checkbox
            label="Auto-adjust based on users timezone"
            checked={useDateTimeTimezone}
            onClick={this.onUseDateTimeTimezoneChange}
          />
        </div>
      </div>
    );
  }
}

DateTimeEditorSettings.propTypes = {
  styles: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default DateTimeEditorSettings;
