import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Catalog from "./Catalog";

import { records } from "redux/modules/modules/records/selectors";
import { moduleDetails } from "redux/modules/modules/module/selectors";
import { getRecords, addRecord } from "redux/modules/modules/records/actions";
import { getModule } from "redux/modules/modules/module/actions";
import { catalogCategories } from "@lennd/value-types/src/constants/standard-modules";
import * as modalActions from "redux/modules/modal/actions";

function mapStateToProps(state) {
  return {
    module: moduleDetails(state, catalogCategories.id),
    records: records(state, catalogCategories.id)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getModule,
      getRecords,
      addRecord,
      ...modalActions
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Catalog);
