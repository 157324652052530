import { combineReducers } from "redux";
import _ from "lodash";
import Api from "utils/EventContacts/WebAPIUtils";

// ------------------------------------
// Constants
// ------------------------------------
const INVALIDATE_EVENT_CONTACT = "INVALIDATE_EVENT_CONTACT";
const REQUEST_EVENT_CONTACT = "REQUEST_EVENT_CONTACT";
const RECEIVE_EVENT_CONTACT = "RECEIVE_EVENT_CONTACT";
const INVALIDATE_EVENT_CONTACT_LIST = "INVALIDATE_EVENT_CONTACT_LIST";
const RECEIVE_EVENT_CONTACT_LISTS = "RECEIVE_EVENT_CONTACT_LISTS";
const UPDATE_SELECTED_CONTACTS = "UPDATE_SELECTED_CONTACTS";

// ------------------------------------
// Actions
// ------------------------------------

const addContacts = data => (dispatch, getState) =>
  Api.addContacts(getState().user.user.credentials, data);

/**
 * contact
 */
const invalidateContact = () => ({
  type: INVALIDATE_EVENT_CONTACT
});

/*
function requestContact (eventId) {
  return {
    type: REQUEST_EVENT_CONTACT,
    eventId
  };
}
*/

const receiveContact = (eventId, contact) => ({
  type: RECEIVE_EVENT_CONTACT,
  eventId,
  contact,
  receivedAt: Date.now()
});

const fetchContact = (eventId, contactId) => (dispatch, getState) => {
  Api.fetchContact(getState().user.user.credentials, eventId, contactId).then(
    contact => {
      dispatch(receiveContact(eventId, contact));
      return null;
    }
  );
};

const updateContactNotifications = data => (dispatch, getState) => {
  const credentials = getState().user.user.credentials;
  Api.updateContactNotifications(credentials, data).then(() => {
    dispatch(fetchContact(data.eventId, data.contactId));
    return null;
  });
};

// /**
//  * notes
//  */
// const createNote = data => (dispatch, getState) => {
//   const credentials = getState().user.user.credentials;
//   Api.createNote(credentials, data).then(() => {
//     dispatch(fetchContact(data.eventId, data.contactId));
//     return null;
//   });
// };

// const updateNote = data => (dispatch, getState) => {
//   const credentials = getState().user.user.credentials;
//   Api.updateNote(credentials, data).then(() => {
//     dispatch(fetchContact(data.eventId, data.contactId));
//     return null;
//   });
// };

// const deleteNote = data => (dispatch, getState) => {
//   const credentials = getState().user.user.credentials;
//   Api.deleteNote(credentials, data).then(() => {
//     dispatch(fetchContact(data.eventId, data.contactId));
//     return null;
//   });
// };

export const actions = {
  // deleteNote,
  // updateNote,
  // createNote,
  updateContactNotifications,
  fetchContact,
  receiveContact,
  invalidateContact,
  addContacts
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialListsState = {
  isFetching: true,
  didInvalidate: false,
  lists: []
};

const selectedContact = (state = null, action) => {
  switch (action.type) {
    case INVALIDATE_EVENT_CONTACT:
      return null;
    case RECEIVE_EVENT_CONTACT:
    case REQUEST_EVENT_CONTACT:
      return action.contact;
    default:
      return state;
  }
};

/**
 * reducer: listsWithContactsByEvent
 */
const eventContactList = (state, action) => {
  switch (action.type) {
    case UPDATE_SELECTED_CONTACTS:
      return _.extend({}, state, {
        contacts: action.contacts
      });

    default:
      return state;
  }
};

const eventContactListItem = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_EVENT_CONTACT_LIST:
    case UPDATE_SELECTED_CONTACTS:
      return _.extend({}, state, {
        [action.listId]: eventContactList(state[action.listId], action)
      });

    default:
      return state;
  }
};

const listsWithContactsByEvent = (state = {}, action) => {
  switch (action.type) {
    case INVALIDATE_EVENT_CONTACT_LIST:
    case UPDATE_SELECTED_CONTACTS:
      return _.extend({}, state, {
        [action.eventId]: eventContactListItem(state[action.eventId], action)
      });

    default:
      return state;
  }
};

/**
 * reducer: lists
 */
const eventContactLists = (state = initialListsState, action) => {
  switch (action.type) {
    case RECEIVE_EVENT_CONTACT_LISTS:
      return _.extend({}, state, {
        isFetching: false,
        didInvalidate: false,
        lastUpdated: action.receivedAt,
        lists: action.lists
      });

    default:
      return state;
  }
};

const listsByEvent = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_EVENT_CONTACT_LISTS:
      return _.extend({}, state, {
        [action.eventId]: eventContactLists(state[action.eventId], action)
      });

    default:
      return state;
  }
};

const rootReducer = combineReducers({
  selectedContact,
  listsByEvent,
  listsWithContactsByEvent
});

export default rootReducer;
