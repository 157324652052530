import React, { Component } from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import { withState } from "recompose";
import {
  Div,
  BigFilledButton,
  BigOutlineButton,
  Text2,
  Stepper,
  BigInsetInput,
  FontIcon
} from "components/Base";
import { PrimaryContactsView } from "./PrimaryContacts";
import { AllocationsModal } from "./Items";
import * as R from "ramda";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import { ACCOUNTS } from "utils/standard-module-field-ids";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";
import Avatar from "components/PortalV2/GroupManager/ScalableAvatar";
import ScrollableModalBody from "components/Global/Modal/Layout/ScrollableBody";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { eventId } from "redux/modules/event/selectors";
import { hideModal } from "redux/modules/modal/actions";
import { addRecord } from "redux/modules/modules/records/actions";
import { track } from "redux/modules/analytics/actions";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

export const Modal = ({
  minHeight = 400,
  heading,
  body,
  footer,
  header,
  hideModal
}) => (
  <StyleWrapper
    width={545}
    bodyStyles={{ padding: 0 }}
    heading={heading}
    hideModal={hideModal}
  >
    {Boolean(header) && (
      <Div bg="white" py={3} px={5}>
        {header}
      </Div>
    )}
    <Div
      py={6}
      display="row.center.center"
      bc="neutral2"
      bt={Boolean(header) && 1}
      bb={Boolean(footer) && 1}
      style={{
        backgroundColor: "#FCFCFD"
      }}
    >
      <ScrollableModalBody
        style={{
          minHeight,
          border: 0,
          height: "100%",
          width: "85%",
          padding: 0,
          margin: "0 auto"
        }}
      >
        {body}
      </ScrollableModalBody>
    </Div>
    {Boolean(footer) && (
      <Div bg="white" py={3} px={7} display="row.center.center">
        {footer}
      </Div>
    )}
  </StyleWrapper>
);

function mapStateToProps(state) {
  return {
    cando: canEventUserDo(state),
    eventId: eventId(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      hideModal,
      addRecord,
      track
    },
    dispatch
  );
}

class AddGuestListRecordModal extends Component {
  addAccount = async () => {
    this.props.setLoading(true);

    if (this.props.loading) return false;

    const record = await this.props.addRecord({
      moduleId: STANDARD_MODULES.accounts.id,
      typeId: this.props.recordTypeId,
      record: {
        [ACCOUNTS.NAME]: { type: "text", value: this.props.accountName.trim() }
      },
      options: {
        eventId: this.props.eventId
      }
    });
    this.props.setAccount(record);
    if (this.props.onAddAccount) {
      this.props.onAddAccount();
    }
    this.props.setLoading(false);

    this.props.track({
      event: "lennd_glm_admin_add_group",
      properties: {
        eventId: this.props.eventId,
        accountId: record.id,
        typeId: this.props.recordTypeId
      }
    });

    return record;
  };

  handleDone = () => {
    if (this.props.onDone) {
      this.props.onDone();
    }
    this.props.hideModal();
  };

  render() {
    const {
      hideModal,
      recordTypeName,
      recordTypeIcon,
      recordTypeColor
    } = this.props;

    let steps = ["Add Group", "Allocate Passes"];

    if (this.props.cando(`${STANDARD_MODULES.contacts.id}_add`)) {
      steps = R.insert(1, "Add People")(steps);
    }

    switch (this.props.sceneIndex) {
      case 0:
        return (
          <Modal
            {...{
              minHeight: 290,
              hideModal,
              heading: "Add Group",
              header: <Stepper activeStepIndex={0} steps={steps} />,
              body: (
                <Div>
                  <Div display="row.flex-start.center" mb={4}>
                    <Avatar
                      size={29}
                      style={{ backgroundColor: recordTypeColor }}
                      mr={2}
                      content={
                        <FontIcon
                          style={{
                            fontSize: 16,
                            color: "rgba(255,255,255,0.6"
                          }}
                        >
                          {recordTypeIcon}
                        </FontIcon>
                      }
                    />
                    <Div fw={3} fs={6} color="gray7">
                      {recordTypeName}
                    </Div>
                  </Div>

                  <Div pb={2} display="row.space-between.flex-end">
                    <Div fw={3} fs={4} color="gray7">
                      Group Name
                    </Div>
                    <Text2 italic color="neutral4">
                      * Required
                    </Text2>
                  </Div>
                  <Div display="row" width={1}>
                    <BigInsetInput
                      flex={1}
                      onChange={val => this.props.setAccountName(val)}
                      continuous
                    />
                  </Div>
                </Div>
              ),
              footer: [
                <BigFilledButton
                  bg="altB5"
                  color="white"
                  mr={2}
                  display="row.center.center"
                  flex={1}
                  disabled={
                    !this.props.accountName || !this.props.accountName.length
                  }
                  onClick={async () => {
                    await this.addAccount();
                    const nextScene = this.props.cando(
                      `${STANDARD_MODULES.contacts.id}_add`
                    )
                      ? 1
                      : 2;
                    this.props.setScene(nextScene);
                  }}
                >
                  {!this.props.loading ? "Save & Continue" : "Creating..."}
                </BigFilledButton>,
                <BigOutlineButton onClick={this.props.hideModal}>
                  Cancel
                </BigOutlineButton>
              ]
            }}
          />
        );
        break;
      case 1:
        return (
          <Modal
            {...{
              minHeight: 320,
              hideModal,
              heading: "Add Group",
              header: <Stepper activeStepIndex={1} steps={steps} />,
              body: (
                <PrimaryContactsView
                  groupId={this.props.createdAccount.id}
                  groupName={this.props.accountName}
                  recordTypeColor={recordTypeColor}
                  recordTypeIcon={recordTypeIcon}
                />
              ),
              footer: [
                <BigFilledButton
                  bg="altB5"
                  mr={2}
                  display="row.center.center"
                  flex={1}
                  onClick={() => {
                    // refresh the table data
                    if (this.props.onAddAccount) {
                      this.props.onAddAccount();
                    }

                    // jump to next scene
                    this.props.setScene(2);
                  }}
                >
                  Next
                </BigFilledButton>
              ]
            }}
          />
        );
      case 2:
        return (
          <AllocationsModal
            {...{
              itemTypeId: CREDENTIAL_TYPE_ID,
              groupId: this.props.createdAccount.id,
              groupName: this.props.accountName,
              minHeight: 320,
              hideModal,
              heading: "Add Group",
              header: <Stepper activeStepIndex={2} steps={steps} />,
              onSave: this.handleDone
            }}
          />
        );
      default:
        break;
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  R.compose(
    withState("accountName", "setAccountName"),
    withState("createdAccount", "setAccount"),
    withState("sceneIndex", "setScene", 0),
    withState("loading", "setLoading", false)
  )(AddGuestListRecordModal)
);
