import * as R from "ramda";
import { makeInstanceSelector } from "redux-mvc";
import moment from "moment";
import numeral from "numeral";

import { liftToArr } from "utils/General";

import { getters } from "ui-kit/Form/model";

import { FIELD_TYPES } from "ui-kit/Form/constants";

const getInstance = (_, props) => R.prop("instanceId", props);

export const getField = (state, props = {}) =>
  R.propOr({}, props.fieldId, getters.fields(state, props));

export const getStateValue = R.compose(
  R.prop("value"),
  getField
);

const getFieldType = R.compose(
  R.path(["meta", "fieldType"]),
  getField
);

export const getDropdownOptions = makeInstanceSelector(
  (_, props) => R.propOr([], "options", props),
  R.map(({ id, label, textColor, backgroundColor }) => ({
    value: id,
    label,
    textColor,
    backgroundColor
  }))
)(getInstance);

export const getUserOptions = makeInstanceSelector(
  getters.users,
  R.sortBy(user =>
    user.user_fname
      ? `${user.user_fname} ${user.user_lname}`.toLowerCase()
      : user.user_email.toLowerCase()
  )
)(getInstance);

const getValueByType = {
  [FIELD_TYPES.VALUE]: getStateValue,
  [FIELD_TYPES.CURRENCY]: makeInstanceSelector(
    getStateValue,
    (_, props) => R.propOr("0,0.00", "format", props),
    (value, format) => {
      if (R.isNil(value)) {
        return "";
      }
      if (typeof value !== "number") {
        return value;
      }
      return R.concat("$", numeral(value).format(format));
    }
  )(getInstance),
  [FIELD_TYPES.DATE]: makeInstanceSelector(
    getStateValue,
    (_, props) => R.propOr("L", "format", props),
    (value, format) => value && moment(value, format)
  )(getInstance),
  [FIELD_TYPES.DATETIME]: makeInstanceSelector(getStateValue, value => value)(
    getInstance
  ),
  [FIELD_TYPES.TOGGLE]: R.compose(
    R.propOr(false, "value"),
    getField
  ),
  [FIELD_TYPES.DROPDOWN]: makeInstanceSelector(
    getStateValue,
    getDropdownOptions,
    (_, props) => R.prop("multipleSelect", props),
    (value = [], options, multipleSelect) => {
      const selected = R.filter(
        R.compose(
          R.contains(R.__, liftToArr(value)), // eslint-disable-line no-underscore-dangle
          R.prop("value")
        ),
        options || []
      );
      return multipleSelect ? selected : R.head(selected);
    }
  )(getInstance),
  [FIELD_TYPES.DROPDOWN_ASYNC]: makeInstanceSelector(
    getStateValue,
    getDropdownOptions,
    (_, props) => R.prop("multipleSelect", props),
    (value = []) => value
  )(getInstance),
  [FIELD_TYPES.USER]: makeInstanceSelector(
    getStateValue,
    R.propOr([], "records")
  )(getInstance)
};

// should be used by ui-kit/Form only
export const getValue = (state, props) =>
  R.propOr(
    getStateValue,
    props.fieldType || getFieldType(state, props),
    getValueByType
  )(state, props);

const getFieldValueByType = {
  ...getValueByType,
  [FIELD_TYPES.DATE]: getStateValue,
  [FIELD_TYPES.DATETIME]: getStateValue,
  [FIELD_TYPES.DROPDOWN]: makeInstanceSelector(
    getStateValue,
    (_, props) => R.prop("isMulti", props),
    (value = [], isMulti) =>
      isMulti ? liftToArr(value) : R.head(liftToArr(value))
  )(getInstance)
};

// should be used for getting values from outside ui-kit/Form
export const getFieldValue = (state, props) =>
  R.propOr(
    getStateValue,
    props.fieldType || getFieldType(state, props),
    getFieldValueByType
  )(state, props);

export const getFormFields = (state, props) =>
  R.mergeAll(
    R.map(
      fieldId => ({ [fieldId]: getFieldValue(state, { ...props, fieldId }) }),
      props.fields
    )
  );
