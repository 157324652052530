import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Form = ({ children, ...props }) => (
  <form styleName="form" {...props}>
    {children}
  </form>
);

Form.propTypes = {
  children: PropTypes.node.isRequired
};

export default CSSModules(Form, css);
