import React from "react";
import { Page, Title } from "EventLight/Common/FieldLayout";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import EmailTemplates from "EventLight/Common/EmailTemplates/View";

const Layout = () => (
  <Page>
    <Title
      icon="email"
      title="Email Templates"
      description="Manage your email templates"
    />
    <EmailTemplates moduleId={STANDARD_MODULE_IDS.accounts.id} />
  </Page>
);

export default Layout;
