import React, { Component } from "react";

export default class RemoveOrganizationUserModal extends Component {
  onClick = () => {
    const data = {
      userId: this.props.userId
    };
    this.props.handleRemoveMember(data);
    this.props.hideModal();
  };

  render() {
    return (
      <div>
        <div className="modal-header">
          <div className="modal-title">Remove member?</div>
        </div>

        <div className="modal-body">
          <div className="modal-body-wrapper">
            <div>
              Are you sure you want to remove{" "}
              <strong>{this.props.userName}</strong> from this organization?
            </div>
            <br />
            <div className="actions-wrapper">
              <a
                className="action-cancel small clear"
                onClick={this.props.hideModal}
              >
                Cancel
              </a>
              <span className="action-positive small" onClick={this.onClick}>
                Remove
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
