import React from "react";
import { withRouter } from "react-router";
import { Page, Title } from "EventLight/Common/FieldLayout";

import Approvers from "EventLight/Common/Items/Approvers/View";

const Layout = ({ params }) => (
  <Page>
    <Title
      icon="assignment_turned_in"
      title="Approvers"
      description="Manage who can approve"
    />
    <Approvers moduleId={params.moduleId} />
  </Page>
);

export default withRouter(Layout);
