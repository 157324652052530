import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FormContactReference extends Component {
  render() {
    if (!this.props.isEditing) {
      return null;
    }

    return (
      <div styleName="container">
        <div styleName="fieldWrapper">
          <i className="material-icons" styleName="icon">
            assignment
          </i>
          <div styleName="wrapper">
            Contact Reference -&nbsp;{this.props.field.name || "New Contact"}
          </div>
        </div>
      </div>
    );
  }
}

FormContactReference.propTypes = {
  isEditing: PropTypes.bool,
  field: PropTypes.object.isRequired
};

export default FormContactReference;
