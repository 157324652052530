import React from "react";
import * as R from "ramda";
import { TABS } from "../constants";
import { getTabs } from "../selectors";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import { actions } from "../model";
import css from "./styles.scss";
import { DarkTab } from "components/Base";

const decorate = R.compose(
  connect(
    state => ({
      tabs: getTabs(state)
    }),
    {
      setActiveTab: actions.setActiveTab
    }
  ),
  CSSModules(css)
);

const Tabs = ({ setActiveTab, tabs }) => (
  <div styleName="tabsWrapper">
    <div styleName="tabs">
      {tabs.map(t => (
        <DarkTab
          key={t.id}
          active={t.active}
          onClick={() => setActiveTab(t.id)}
          fs={3}
        >
          {t.title}
        </DarkTab>
      ))}
    </div>
  </div>
);

export default decorate(Tabs);
