import React from "react";
import * as R from "ramda";
import {
  Div,
  BigAvatar,
  MediumAvatar,
  SmallAvatar,
  SuperAvatar,
  HugeAvatar
} from "components/Base";
import Tooltip from "components/Global/Tooltip";
import Circle from "ui-kit/Circle";

const sizes = {
  huge: HugeAvatar,
  super: SuperAvatar,
  big: BigAvatar,
  medium: MediumAvatar,
  small: SmallAvatar
};

const AvatarCard = ({
  children,
  avatar = {},
  size = "big",
  avatarProps = {},
  isActive = false,
  showActiveIcon = false,
  isTeamMember = false,
  activeIconSize = 10,
  activeIconStyle = {},
  ...props
}) => (
  <Div display="row.flex-start.flex-start" {...props}>
    <Div display="row">
      {React.createElement(sizes[size] || BigAvatar, {
        photoURL: R.prop("photoUrl", avatar),
        bg: R.propOr("neutral3", "bg", avatar),
        text: R.propOr("", "name", avatar),
        singleInitial: true,
        ...avatarProps
      })}
      {showActiveIcon ? (
        <Circle
          size={activeIconSize}
          style={{
            right: 6,
            alignSelf: "flex-end",
            border: "2px solid white",
            position: "relative",
            ...activeIconStyle
          }}
          bg={isActive ? "altA5" : "neutral4"}
        />
      ) : null}
      {isTeamMember ? (
        <Circle
          size={15}
          style={{
            right: 50,
            alignSelf: "flex-start",
            border: "2px solid white",
            position: "relative",
            fontWeight: "bold",
            fontSize: "12px",
            color: "white"
          }}
          bg="info5"
        >
          <Tooltip tooltip="Team member">
            <span>T</span>
          </Tooltip>
        </Circle>
      ) : null}
    </Div>
    {children}
  </Div>
);

export default AvatarCard;
