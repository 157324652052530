import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE } from "./constants";

const iniState = {
  questionsFilter: "",
  categoriesFilter: "",
  setId: null,
  questionSet: {},
  questions: [],
  selectedQuestionIds: [],
  requiredQuestionIds: [],
  loading: false
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    cancelInstance: R.always(iniState),
    init: R.identity,
    updateQuestionSet: R.identity,
    setInitialData: (
      state,
      {
        payload: {
          setId,
          questionSet,
          questions,
          selectedQuestionIds,
          requiredQuestionIds
        }
      }
    ) => ({
      setId,
      questionSet,
      questions,
      selectedQuestionIds,
      requiredQuestionIds
    }),
    toggleQuestion: (state, { payload: questionId }) => ({
      selectedQuestionIds: state.selectedQuestionIds.includes(questionId)
        ? state.selectedQuestionIds.filter(id => id !== questionId)
        : [...state.selectedQuestionIds, questionId]
    }),
    toggleRequiredQuestion: (state, { payload: questionId }) => ({
      requiredQuestionIds: state.requiredQuestionIds.includes(questionId)
        ? state.requiredQuestionIds.filter(id => id !== questionId)
        : [...state.requiredQuestionIds, questionId]
    }),
    removeAllSelectedQuestions: () => ({
      selectedQuestionIds: []
    }),
    updateSelectedQuestionOrder: (state, { payload: questions }) => ({
      selectedQuestionIds: questions.map(q => q.id)
    })
  }
});

export default handlers;
