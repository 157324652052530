import Helpers from "../Global/Helpers";

module.exports = {
  /**
   * forms
   */
  fetchForm: (credentials, eventId, formId) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "get",
        url: `${window.__LENND_API_URL__}/event/${eventId}/forms/${formId}`,
        data: {},
        credentials,
        success: result => {
          resolve(result.form);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  fetchFormByRecipientHash: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "get",
        url: `${window.__LENND_API_URL__}/form/${data.recipientHash}`,
        data: {},
        credentials,
        success: result => {
          resolve(result.form);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  sendForm: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "post",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/forms/${data.formId}/recipients`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  trackFormOpen: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "put",
        url: `${window.__LENND_API_URL__}/form/${data.recipientHash}/open`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  deleteSubmission: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "delete",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/forms/${data.formId}/responses/${data.submissionId}`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  saveFormResponse: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "post",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/forms/${data.formId}/responses/${data.responseId}`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  recordReponseChange: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "post",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/forms/${data.formId}/responses/${data.responseId}/changes`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  updateResponse: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "put",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/forms/${data.formId}/responses/${data.responseId}`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  approveRow: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "post",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/forms/${data.formId}/responses/${data.responseId}/approvals`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  bulkApproveRows: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "post",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/forms/${data.formId}/bulk-approve`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  unapproveRow: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "delete",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/forms/${data.formId}/responses/${data.recipientId}/approvals`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  updateApprovalNote: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "put",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/forms/${data.formId}/responses/${data.recipientId}/approvals`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  createConnector: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "post",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/forms/${data.formId}/connectors`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  updateConnector: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "put",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/forms/${data.formId}/connectors`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  removeConnector: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "delete",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/forms/${data.formId}/connectors`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    }),

  writeConnectorData: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "post",
        url: `${window.__LENND_API_URL__}/event/${data.eventId}/forms/${data.formId}/connectors/data`,
        data,
        credentials,
        success: () => {
          resolve();
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    })
};
