import { connect } from "react-redux";
import * as R from "ramda";

import DeleteRecordType from "./DeleteRecordType";
import { withRouter } from "react-router";
import * as ModuleRecordTypesSelectors from "redux/modules/modules/recordTypes/selectors";

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    recordTypes: ModuleRecordTypesSelectors.recordTypes(
      state,
      props.params.moduleId
    )
  }))
);

export default decorate(DeleteRecordType);
