import React from "react";
import { Div } from "components/Base";
import RecordCard from "components/Global/CRM/RecordCardList/RecordCard";

const View = ({ records, selectionMode }) => (
  <Div>
    {records.map(record => (
      <RecordCard
        key={record.id}
        name={record.name}
        type={record.type}
        onClick={record.selectRecord}
        metaValues={record.metaValues}
        selected={record.isSelected}
        selectionMode={selectionMode}
      />
    ))}
  </Div>
);

export default View;
