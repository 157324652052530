import React from "react";
import PopoverMainContainer from "components/Global/Module/Sidebar/ViewSelector/Popover/MainContainer";
import ViewSelector from "components/Global/Module/Sidebar/ViewSelector";

const Views = ({
  moduleId,
  groupId,
  viewId,
  activeViewName,
  activeViewType,
  updateViewName,
  activeViewIsPublic,
  toggleIsPublic,
  selectView,
  deleteView,
  duplicateView,
  reports
}) => (
  <ViewSelector
    viewId={viewId}
    activeViewName={activeViewName}
    activeViewType={activeViewType}
    updateViewName={updateViewName}
    activeViewIsPublic={activeViewIsPublic}
    toggleIsPublic={toggleIsPublic}
    duplicateView={duplicateView}
    deleteView={deleteView}
  >
    {({ composeClosePopover, showEditName }) => (
      <PopoverMainContainer
        moduleId={moduleId}
        groupId={groupId}
        activeViewId={viewId}
        composeClosePopover={composeClosePopover}
        selectView={selectView}
        showEditName={showEditName}
        reports={reports}
      />
    )}
  </ViewSelector>
);

export default Views;
