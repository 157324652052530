import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import ReactQuill from "react-quill";
import {
  modules as defaultModules,
  formats as defaultFormats
} from "utils/Quill/Options";

require("react-quill/dist/quill.snow.css");

@CSSModules(css)
class Wysiwyg extends Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.quill = React.createRef();
  }

  onTextChange = content => {
    if (!this.quill.current) return;

    // get plain text and remove returns and extra spaces
    const plainText = this.quill.current
      .getEditor()
      .getText()
      .replace(/\r?\n|\r/g, " ")
      .replace(/[ ]{2,}/g, " ");

    if (content === "<p><br></p>" || plainText === "") {
      this.props.onChange("", "");
    } else {
      this.props.onChange(content, plainText);
    }
  };

  render() {
    const { text, onBlur, formats, modules, id } = this.props;

    // @NOTE: Hold until custom input re-implemented
    // const inputHeight = height ? { height } : undefined;

    return (
      <div
        ref={this.container}
        styleName="container"
        className={`_quill-${id}`}
        onBlur={onBlur}
      >
        <ReactQuill
          ref={this.quill}
          bounds={this.container.current}
          formats={formats}
          modules={modules}
          placeholder={this.props.placeholder}
          onChange={this.onTextChange}
          theme="snow"
          value={text}
        />
      </div>
    );
  }
}

/*
@NOTE: There's currently an issue with custom editing field and React 16
Link: https://github.com/zenoamaro/react-quill/issues/309

  <ReactQuill
    ref={this.quill}
    bounds={this.container.current}
    formats={formats}
    modules={modules}
    placeholder={this.props.placeholder}
    onChange={this.onTextChange}
    theme="snow"
    value={text}
  >
    <div
      key="editor"
      className="quill-contents"
      styleName="textInput"
      dangerouslySetInnerHTML={{ __html: text }}
      style={inputHeight}
    />
  </ReactQuill>

*/

Wysiwyg.defaultProps = {
  formats: defaultFormats,
  modules: defaultModules
};

Wysiwyg.propTypes = {
  formats: PropTypes.array.isRequired,
  height: PropTypes.number,
  id: PropTypes.string,
  modules: PropTypes.object.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  text: PropTypes.string.isRequired
};

export default Wysiwyg;
