import * as SYSTEM_FIELD_IDS from "utils/system-field-ids";

export const DEFAULT_APPROVAL_FIELD_APPROVE_REJECT = {
  id: SYSTEM_FIELD_IDS.APPROVAL,
  type: "action-approve-form-v3-row",
  name: "Approval",
  visible: true,
  editable: false,
  dontWatch: true,
  schema: {
    locked: true
  },
  width: 150,
  settings: {
    width: 150,
    readOnly: true
  }
};
