import React from "react";
import Checkbox from "rc-checkbox";
import EmailTemplate from "components/Global/Email/Message";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const FormActions = ({
  isOrg,
  isPrimary,
  isPrimaryDisabled,
  giveLoginAccess,
  giveLoginAccessDisabled,
  sendNotification,
  sendNotificationDisabled,
  handleFormFieldChange,
  addMessage,
  hasMessage,
  updateMessage,
  newTemplate,
  message,
  templateTitle,
  updateTemplateTitle,
  toggleTemplate
}) => (
  <div styleName="checkboxes">
    <label>
      <Checkbox
        onChange={handleFormFieldChange}
        name="isPrimary"
        checked={isPrimary}
        disabled={isPrimaryDisabled}
      />
      &nbsp;
      <span styleName="bold">Mark as primary person</span>
    </label>
    {isOrg ? null : (
      <label>
        <Checkbox
          onChange={handleFormFieldChange}
          name="giveLoginAccess"
          checked={giveLoginAccess}
          disabled={giveLoginAccessDisabled}
        />
        &nbsp;
        <span styleName="bold">Give login access to this group's portal</span>
      </label>
    )}

    {isOrg ? null : (
      <label styleName="inset">
        <Checkbox
          onChange={handleFormFieldChange}
          name="sendNotification"
          checked={giveLoginAccess && sendNotification}
          disabled={!giveLoginAccess || sendNotificationDisabled}
        />
        &nbsp;
        <span
          className={
            !giveLoginAccess || sendNotificationDisabled ? css.disabled : ""
          }
          styleName="bold"
        >
          Send email notification
        </span>
        {sendNotification ? (
          <span styleName="addMessage" onClick={addMessage}>
            - Add a custom message
          </span>
        ) : (
          ""
        )}
      </label>
    )}

    {giveLoginAccess && sendNotification && hasMessage ? (
      <EmailTemplate
        updateMessage={updateMessage}
        updateTemplateTitle={updateTemplateTitle}
        toggleTemplate={toggleTemplate}
        newTemplate={newTemplate}
        message={message}
        templateTitle={templateTitle}
      />
    ) : (
      ""
    )}
  </div>
);

export default CSSModules(FormActions, css);
