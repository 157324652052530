import React from "react";
import * as R from "ramda";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { actions } from "SalesPortal/ItemsModal";
import {
  getSelectedGroupsAndItems,
  getTotalCartItems,
  getTotalAmount
} from "SalesPortal/ItemsModal/selectors";
import { getCurrency } from "SalesPortal/Home/selectors";
import CommonCart from "Common/Cart";

const decorate = R.compose(
  withRouter,
  connect(
    state => ({
      orderedItems: getSelectedGroupsAndItems(state),
      total: getTotalCartItems(state),
      totalAmount: getTotalAmount(state),
      currency: getCurrency(state)
    }),
    {
      updateItemCartQuantity: actions.updateItemCartQuantity
    }
  )
);

const Cart = ({ currency, ...props }) => {
  const {
    params: { eventId }
  } = props;
  const cartProps = {
    ...props,
    currency: currency,
    backUrlText: "View event",
    backUrlLink: `/event/${eventId}/sales-portal`,
    checkoutUrlLink: `/event/${eventId}/sales-portal/checkout`
  };
  return <CommonCart {...cartProps} />;
};

export default decorate(Cart);
