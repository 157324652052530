import React, { Component } from "react";
import * as R from "ramda";
import exportFactory from "components/Event/Reports/utils/export-factory";
import NotesController from "./ModuleNotes";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as SEND_TO_OPTION_TYPES from "SendEmailModal/utils/send-to-option-types";
import { PORTAL_LOGIN, ASSIGNMENT_MANAGER } from "utils/Emails/default-emails";
import { ACCOUNTS_BY_TYPE_REPORT_ID } from "components/Event/Reports/utils/constants";
import { withRouter } from "react-router";

import DeleteConfirmation from "components/Global/CRM/Modals/DeleteConfirmation";
import DeleteOrRemoveConfirmation from "components/Global/CRM/Modals/DeleteOrRemoveConfirmation";
import EditRelatedContactModal from "components/Event/Accounts/Account/Modals/EditRelatedContactModal";
import LoginDetailsModal from "components/Event/Accounts/Account/People/LoginDetailsModal";
import BulkLoginDetails from "components/Event/Accounts/Account/People/BulkLoginDetailsModal";
import ChangeRecordTypeModal from "components/Global/CRM/Modals/ChangeRecordType";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import MoreInfoModal from "components/Event/Accounts/Account/Modals/MoreInfo";
import SendEmailModal from "SendEmailModal/View";
import {
  getAccount,
  invalidateAccount
} from "redux/modules/accounts/profile/actions";
import {
  deleteRecord,
  removeEventAssignments,
  updateType
} from "redux/modules/modules/records/actions";
import { addValue } from "redux/modules/modules/values/actions";
import { logAction } from "redux/modules/log/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import {
  addRelationship,
  updateRelationship,
  deleteRelationship
} from "redux/modules/accounts/contactRelationship/actions";
import { deleteAccountUser } from "redux/modules/accounts/users/actions";
import { sendEmail } from "redux/modules/email/actions";

import {
  account,
  accountName,
  isFetching
} from "redux/modules/accounts/profile/selectors";
import { canUserDo } from "redux/modules/permissions/user-permission-profile/selectors";
import { accountTypes } from "redux/modules/event/selectors";
import { orgDetails } from "redux/modules/organization/selectors";
import { eventDetails } from "redux/modules/event/selectors";

import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
const ACCOUNT_ID = STANDARD_MODULES.accounts.id;
const CONTACT_ID = STANDARD_MODULES.contacts.id;

function mapStateToProps(state, props) {
  const context = {
    ...props.params,
    recordId: props.groupId || props.accountId || props.params.recordId
  };

  return {
    params: context,
    orgDetails: orgDetails(state),
    eventDetails: eventDetails(state),
    account: account(state),
    name: accountName(state),
    accountTypes: accountTypes(state),
    readOnly: !canUserDo(state, context.eventId || context.orgId)(
      `${ACCOUNT_ID}_update`
    ),
    canManageFields: canUserDo(state, context.eventId || context.orgId)(
      `${ACCOUNT_ID}_update`
    ),
    loading: isFetching(state)
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      addValue,
      deleteRecord,
      getAccount,
      invalidateAccount,
      logAction,
      removeEventAssignments,
      showModal,
      hideModal: props.hideModal || hideModal,
      showSnackbar,
      updateType,
      addRelationship,
      updateRelationship,
      deleteRelationship,
      deleteAccountUser,
      sendEmail
    },
    dispatch
  );
}

function AccountProfileController(Child) {
  return class Controller extends Component {
    componentDidMount() {
      this.fetchAccountProfile();
    }

    shouldComponentUpdate(nextProps) {
      return (
        (nextProps.loading && R.isEmpty(this.props.account)) ||
        nextProps.account !== this.props.account ||
        this.props.location.pathname !== nextProps.location.pathname
      );
    }

    componentWillUnmount() {
      this.props.invalidateAccount(this.props.params.recordId);
    }

    fetchAccountProfile = () => {
      const { orgId, eventId, recordId } = this.props.params;
      this.props.getAccount({ orgId, eventId, accountId: recordId });
    };

    handleRemoveFromEvent = async eventId => {
      await this.props.removeEventAssignments({
        eventIds: [eventId],
        recordIds: [this.props.params.recordId],
        moduleId: ACCOUNT_ID
      });
      this.fetchAccountProfile();
    };

    deleteAccount = () => {
      if (this.props.params.orgId) {
        this.props.showModal({
          content: (
            <DeleteConfirmation
              hideModal={this.props.hideModal}
              heading="Delete account?"
              message={
                <div>
                  Are you sure you want to delete this group?
                  <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                    This cannot be undone.
                  </div>
                </div>
              }
              onConfirm={() => this.handleDelete(false)}
            />
          ),
          wrapper: ModalWrapper
        });
      } else {
        this.props.showModal({
          content: (
            <DeleteOrRemoveConfirmation
              hideModal={this.props.hideModal}
              countOfSelected={1}
              heading="Remove account?"
              onRemove={() => this.handleDelete(true)}
              onDelete={() => this.handleDelete(false)}
            />
          ),
          wrapper: ModalWrapper
        });
      }
    };

    handleDelete = async (removeOnly = false) => {
      const { orgId, eventId, recordId } = this.props.params;
      await this.props.deleteRecord({
        moduleId: ACCOUNT_ID,
        orgId,
        eventId,
        record: { id: recordId },
        options: {
          orgId: this.props.params.orgId,
          eventId: this.props.params.eventId,
          removeOnly
        }
      });
      this.props.router.replace({
        pathname: orgId
          ? `/organization/${orgId}/module/${ACCOUNT_ID}`
          : `/event/${eventId}/module/${ACCOUNT_ID}/dashboard`
      });
    };

    updateField = async ({ fieldId, value }) => {
      await this.props.addValue({
        fieldId,
        value,
        moduleId: ACCOUNT_ID,
        recordId: this.props.params.recordId
      });
      return this.fetchAccountProfile();
    };

    getStandardFieldValue = (id, defaultValue) =>
      R.pathOr(
        defaultValue,
        ["values", id, "value", "value"],
        this.props.account
      );

    showViewAllPropertiesModal = () => {
      const { orgId, eventId } = this.props.params;
      this.props.showModal({
        content: (
          <MoreInfoModal
            updateField={this.updateField}
            readOnly={this.props.readOnly}
            goToManageFields={
              orgId
                ? undefined
                : this.props.canManageFields
                ? () => {
                    this.props.router.replace({
                      pathname: window.location.pathname.includes("light")
                        ? orgId
                          ? `/org-light/${orgId}/contacts/settings`
                          : `/event-light/${eventId}/contacts/settings`
                        : orgId
                        ? `/organization/${orgId}/settings/module/${ACCOUNT_ID}/fields`
                        : `/event/${eventId}/settings/module/${ACCOUNT_ID}/fields`
                    });
                  }
                : null
            }
          />
        ),
        wrapper: ModalWrapper
      });
    };

    updateNote = async (note, noteId) => {
      await this.props.updateNote({
        recordId: this.props.params.recordId,
        noteId,
        note
      });
      this.fetchAccountProfile();
      return this.props.showSnackbar({ message: "Note updated" });
    };

    deleteNote = async noteId => {
      await this.props.deleteNote({
        noteId,
        moduleId: ACCOUNT_ID,
        recordId: this.props.params.recordId
      });
      return this.fetchAccountProfile();
    };

    addNote = async note => {
      const { recordId } = this.props.params;

      await this.props.addNote({
        moduleId: ACCOUNT_ID,
        recordId: recordId,
        note
      });

      this.fetchAccountProfile();
      return this.props.showSnackbar({ message: "Note added" });
    };

    emailPrimaryContacts = () => {
      this.showSendEmailModal({
        recipients: SEND_TO_OPTION_TYPES.ACCOUNT_PRIMARY_CONTACTS
      });
    };

    sharePortalLoginLink = () => {
      this.showSendEmailModal({
        recipients: SEND_TO_OPTION_TYPES.ACCOUNT_CONTACT_USERS,
        subject: PORTAL_LOGIN.subject,
        message: PORTAL_LOGIN.message
      });
    };

    shareAssignmentManagerLink = () => {
      this.showSendEmailModal({
        recipients: SEND_TO_OPTION_TYPES.ACCOUNT_CONTACT_USERS,
        subject: ASSIGNMENT_MANAGER.subject,
        message: ASSIGNMENT_MANAGER.message
      });
    };

    exportProfile = () => {
      return this.props.exportNestedReport({
        reportId: ACCOUNTS_BY_TYPE_REPORT_ID,
        options: {
          orgId: this.props.params.orgId,
          eventId: this.props.params.eventId,
          filters: JSON.stringify({
            group_type: [this.props.account.type.id],
            record_id: [this.props.params.recordId]
          })
        }
      });
    };

    printProfile = () => {
      return this.props.printNestedReport({
        reportId: ACCOUNTS_BY_TYPE_REPORT_ID,
        options: {
          orgId: this.props.params.orgId,
          eventId: this.props.params.eventId,
          filters: JSON.stringify({
            group_type: [this.props.account.type.id],
            record_id: [this.props.params.recordId]
          })
        }
      });
    };

    showSendEmailModal = ({ recipients, subject, message }) => {
      this.props.showModal({
        content: (
          <SendEmailModal
            moduleId={ACCOUNT_ID}
            records={[this.props.params.recordId]}
            selectedOptions={recipients ? [recipients] : []}
            subject={subject}
            message={message}
          />
        ),
        wrapper: ModalWrapper
      });
    };

    showChangeRecordTypeModal = () => {
      this.props.showModal({
        content: (
          <ChangeRecordTypeModal
            moduleId={ACCOUNT_ID}
            update={async ({ type }) => {
              await this.props.updateType({
                moduleId: ACCOUNT_ID,
                recordId: this.props.params.recordId,
                type,
                options: {
                  orgId: this.props.params.orgId,
                  eventId: this.props.params.eventId
                }
              });
              return this.fetchAccountProfile();
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    };

    showChangeContactRecordTypeModal = (contactId, typeId) => {
      this.props.showModal({
        content: (
          <ChangeRecordTypeModal
            moduleId={CONTACT_ID}
            typeId={typeId}
            update={async ({ type }) => {
              await this.props.updateType({
                moduleId: CONTACT_ID,
                recordId: contactId,
                type,
                options: {
                  orgId: this.props.params.orgId,
                  eventId: this.props.params.eventId
                }
              });
              return this.fetchAccountProfile();
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    };

    goToView = path => {
      const { orgId, eventId, recordId } = this.props.params;
      this.props.router.push({
        pathname: window.location.pathname.includes("light")
          ? orgId
            ? `/org-light/${orgId}/account/${recordId}${path}`
            : `/event-light/${eventId}/account/${recordId}${path}`
          : orgId
          ? `/organization/${orgId}/account/${recordId}${path}`
          : `/event/${eventId}/account/${recordId}${path}`
      });
    };

    getActiveView = () => {
      if (
        this.props.routes.find(r => r.name === "OrgLightAccountProfile") ||
        this.props.routes.find(r => r.name === "EventLightAccountProfile") ||
        this.props.routes.find(r => r.name === "orgAccountProfileOverview") ||
        this.props.routes.find(r => r.name === "accountProfile")
      ) {
        return "overview";
      } else if (
        this.props.routes.find(r => r.name === "OrgLightAccountActivity") ||
        this.props.routes.find(r => r.name === "EventLightAccountActivity") ||
        this.props.routes.find(r => r.name === "orgAccountProfileActivities") ||
        this.props.routes.find(r => r.name === "accountProfileActivities")
      ) {
        return "activity";
      } else if (
        this.props.routes.find(r => r.name === "orgAccountProfilePeople") ||
        this.props.routes.find(r => r.name === "accountProfilePeople") ||
        this.props.routes.find(r => r.name === "EventLightAccountPeople") ||
        this.props.routes.find(r => r.name === "OrgLightAccountPeople")
      ) {
        return "people";
      } else if (
        this.props.routes.some(r => r.name === "accountProfileCatering") ||
        this.props.routes.some(r => r.name === "EventLightAccountCatering")
      ) {
        return "catering";
      } else if (
        this.props.routes.some(r => r.name === "accountProfileCredentials") ||
        this.props.routes.some(r => r.name === "EventLightAccountCredentials")
      ) {
        return "credentials";
      } else if (
        this.props.routes.some(r => r.name === "accountProfileInventory") ||
        this.props.routes.some(r => r.name === "EventLightAccountInventory")
      ) {
        return "inventory";
      }
      return undefined;
    };

    removeRelatedContact = async row => {
      try {
        await this.deleteContactRelationship(row);
        this.props.showSnackbar({ message: "Person removed from account" });
      } catch (e) {
        this.props.showSnackbar({
          message: "Error removing person from account"
        });
      } finally {
        this.fetchAccountProfile();
      }
    };

    async deleteContactRelationship({ id: relationshipId, can_login: userId }) {
      await this.props.deleteRelationship({
        accountId: this.props.params.recordId,
        relationshipId
      });
      if (userId) {
        await this.revokeLoginAccess(userId);
      }
    }

    async revokeLoginAccess(userId) {
      const accountUser = this.props.account.users.find(
        u => u.user_id === userId
      );
      if (accountUser) {
        return this.props.deleteAccountUser({
          accountUserId: accountUser.id,
          accountId: this.props.params.recordId
        });
      }
      throw new Error("No account user found");
    }

    handleLoginAccess = async row => {
      if (row.can_login) {
        try {
          await this.revokeLoginAccess(row.can_login);
          this.props.showSnackbar({ message: "Login access has been removed" });
        } catch (e) {
          this.props.showSnackbar({ message: "Error removing login access" });
        } finally {
          this.fetchAccountProfile();
        }
      } else {
        this.showGiveLoginAccessModal(row.contact_id);
      }
    };

    updateRelatedContact = async data => {
      await this.props.updateRelationship({
        ...data,
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId,
        accountId: this.props.params.recordId
      });

      this.fetchAccountProfile();

      this.props.showSnackbar({ message: "Person updated" });
    };

    showEditContactModal = ({ relationshipId, role }) => {
      this.props.showModal({
        content: (
          <EditRelatedContactModal
            onDelete={({ relationshipId }) =>
              this.removeRelatedContact({ id: relationshipId })
            }
            onSave={this.updateRelatedContact}
            relationshipId={relationshipId}
            role={role}
          />
        ),
        wrapper: ModalWrapper
      });
    };

    showLoginDetailsModal = (row, showFlashMessage = false) =>
      this.props.showModal({
        content: (
          <LoginDetailsModal
            showFlashMessage={showFlashMessage}
            row={row}
            hideModal={this.props.hideModal}
            eventDetails={this.props.eventDetails}
            onEmailSend={this.sendNotificationEmail}
          />
        ),
        wrapper: ModalWrapper
      });

    showGiveLoginAccessModal = contactId => {
      this.props.showModal({
        content: (
          <BulkLoginDetails
            contacts={
              typeof contactId === "string"
                ? [
                    {
                      contactId,
                      accountId: this.props.params.recordId
                    }
                  ]
                : contactId.map(record => ({
                    contactId: record.contact_id,
                    accountId: this.props.params.recordId
                  }))
            }
            onDone={() => {
              this.fetchAccountProfile();
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    };

    sendNotificationEmail = async ({ customMessage, email }) => {
      const { eventDetails, name } = this.props;

      const variables = {
        message: customMessage,
        event_name: eventDetails.name,
        event_slug: eventDetails.slug,
        portal_id: eventDetails.uuid,
        account_name: name,
        user_email: email
      };

      // @FIX SEND EMAIL
      await this.props.sendEmail({
        eventId: eventDetails.id,
        templateName: "notification-account-invite",
        recipients: [{ email }],
        variables: Object.keys(variables).map(key => ({
          name: key,
          content: variables[key]
        }))
      });

      this.props.showSnackbar({ message: "Email sent" });
    };

    render() {
      const { account, name, readOnly, loading, ...props } = this.props;

      if ((!account || !account.id) && !loading) return <div />;

      const methods = {
        addNote: this.addNote,
        deleteAccount: this.deleteAccount,
        deleteNote: this.deleteNote,
        fetchProfile: this.fetchAccountProfile,
        getFieldValue: this.getStandardFieldValue,
        onRemoveFromEvent: this.handleRemoveFromEvent,
        onToggleLoginAccess: this.handleLoginAccess,
        showEditContactModal: this.showEditContactModal,
        showLoginDetailsModal: this.showLoginDetailsModal,
        updateRelatedContact: this.updateRelatedContact,
        showSendEmailModal: this.showSendEmailModal,
        emailPrimaryContacts: this.emailPrimaryContacts,
        shareAssignmentManagerLink: this.shareAssignmentManagerLink,
        sharePortalLoginLink: this.sharePortalLoginLink,
        removeRelatedContact: this.removeRelatedContact,
        printProfile: this.printProfile,
        exportProfile: this.exportProfile,
        showViewAllPropertiesModal: this.showViewAllPropertiesModal,
        showChangeRecordTypeModal: this.showChangeRecordTypeModal,
        showChangeContactRecordTypeModal: this.showChangeContactRecordTypeModal,
        updateField: this.updateField,
        updateNote: this.updateNote,
        goToView: this.goToView
      };

      const activeView = this.getActiveView();
      const isOrgProfile = Boolean(this.props.params.orgId);

      // explicitly add dependencies, rather than passing everything
      return (
        <Child
          {...{
            account,
            name,
            readOnly,
            activeView,
            isOrgProfile,
            ...props,
            ...methods
          }}
        />
      );
    }
  };
}

export default Child =>
  withRouter(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(exportFactory(NotesController(AccountProfileController(Child))))
  );
