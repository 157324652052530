import PropTypes from "prop-types";
import React, { Component } from "react";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import EditRecordModal from "components/Global/Module/Modals/EditRecord";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import {
  locations,
  locationAreas
} from "@lennd/value-types/src/constants/standard-modules";
import InternalHandler from "./Internal";

class ExternalHandler extends Component {
  componentDidMount() {
    const { eventId } = this.props.params;
    this.props.getModule({ moduleId: locations.id, options: { eventId } });
    this.getLocations();
    this.getAreas();
  }

  componentWillUpdate(nextProps) {
    if (this.props.params.parentRecordId !== nextProps.params.parentRecordId) {
      this.props.deselectAllRows();
    }
  }

  getAllLocationsPath = () => `/event/${this.props.params.eventId}/locations`;

  getLocations = () => {
    const { eventId } = this.props.params;
    this.props.getRecords({ moduleId: locations.id, options: { eventId } });
  };

  getAreas = () => {
    const { eventId } = this.props.params;
    this.props.getRecords({ moduleId: locationAreas.id, options: { eventId } });
  };

  showAddLocationModal = () => {
    this.props.showModal({
      content: (
        <AddRecordModal moduleId={locations.id} onSave={this.getLocations} />
      ),
      wrapper: ModalWrapper
    });
  };

  showAddAreaModal = () => {
    this.props.showModal({
      content: (
        <AddRecordModal moduleId={locationAreas.id} onSave={this.getAreas} />
      ),
      wrapper: ModalWrapper
    });
  };

  showEditLocationModal = () => {
    const { parentRecordId } = this.props.params;
    const location = this.props.locationRecords.find(
      r => r.id === parentRecordId
    );
    this.props.showModal({
      content: (
        <EditRecordModal
          record={location}
          values={location.values}
          hideModal={() => {
            this.props.hideModal();
            this.getLocations();
            this.getAreas();
          }}
          moduleId={this.props.locationModule.id}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    return <InternalHandler {...this} {...this.props} />;
  }
}

ExternalHandler.propTypes = {
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  getModule: PropTypes.func.isRequired,
  getRecords: PropTypes.func.isRequired,
  params: PropTypes.shape({
    eventId: PropTypes.string,
    parentRecordId: PropTypes.string
  }).isRequired,
  locationModule: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  locationRecords: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ExternalHandler;
