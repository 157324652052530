import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router";
import autobind from "autobind-decorator";
import DocumentTitle from "components/Global/DocumentTitle";
import SignupActions from "actions/Global/SignupActions";
import ViewWrapper from "views/Auth/ViewWrapper";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class ForgotPasswordHandler extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: this.props.location.query.message || null,
      complete: false
    };
  }

  @autobind
  handleSubmit(e) {
    e.preventDefault();
    const email = this.refs.email.value.trim();
    if (!email || this.state.complete) {
      return;
    }

    this.setState(
      {
        message: "Searching..."
      },
      () => {
        SignupActions.forgotPassword(email, result => {
          if (result.success) {
            this.setState({
              message:
                "If you entered a valid email address, we've sent you an email with a link to reset your password.",
              complete: true
            });
          } else {
            this.setState({
              message: (
                <span>
                  Hmmm... we can&#39;t find that email address in our big
                  ol&#39; rolodex. Try a different one or{" "}
                  <a href="mailto:hello@lennd.com">shoot us a message</a>.
                </span>
              )
            });
          }
        });
      }
    );
  }

  render() {
    return (
      <DocumentTitle title="Reset Password">
        <ViewWrapper>
          <div styleName="container">
            <div styleName="title">Reset password</div>

            {this.state.complete ? (
              <span>
                <div styleName="successContainer">
                  <i className="material-icons">check</i>
                  <div>
                    <div styleName="header">Success!</div>
                    <div styleName="body">{this.state.message}</div>
                  </div>
                </div>
                <Link to="/login" styleName="goBackBlack">
                  <i className="material-icons">arrow_back</i>
                  Back to sign in
                </Link>
                <div styleName="captionCenter">
                  If the email does not arrive soon, check your spam folder. It
                  was sent from chris@lennd.com
                </div>
              </span>
            ) : (
              <span>
                <div styleName="caption">
                  We will email you instructions to reset your password.
                </div>
                <form onSubmit={this.handleSubmit}>
                  <div styleName="inputWrapper">
                    <input
                      className="styled"
                      type="email"
                      placeholder="Email address"
                      required
                      autoFocus
                      defaultValue={
                        this.props.location.query.email
                          ? this.props.location.query.email
                          : ""
                      }
                      ref="email"
                    />
                  </div>

                  {this.state.message && (
                    <div styleName="message">{this.state.message}</div>
                  )}

                  <button styleName="buttonWrapper" type="submit">
                    <span styleName="button">
                      Reset Password
                      <i className="material-icons">arrow_forward</i>
                    </span>
                  </button>
                  <Link to="/login" styleName="goBack">
                    <i className="material-icons">arrow_back</i>
                    Back to sign in
                  </Link>
                </form>
              </span>
            )}
          </div>
        </ViewWrapper>
      </DocumentTitle>
    );
  }
}
export default ForgotPasswordHandler;
