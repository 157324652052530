import React from "react";
import EditorBase from "./TableConnector";
import DateTime from "components/Global/Editors/DateTimeEditor";

import { getRowMetadata, ep } from "ui-kit/Datagrid/utils";

export default class DateTimeEditor extends EditorBase {
  render() {
    const rowMetaData = getRowMetadata(ep(this.props));

    return (
      <DateTime
        rowMetaData={rowMetaData}
        ref={c => (this.focusRef = c)}
        value={this.state}
        onChange={this.handleChange}
      />
    );
  }
}
