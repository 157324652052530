import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import Attachments from "../Attachments";
import css from "./styles.scss";
import Message from "components/Global/Email/Message";
import { MODES } from "../../../../constants";

@CSSModules(css)
class Setup extends Component {
  render() {
    const {
      mode,
      message,
      newTemplate,
      templateTitle,
      subject,
      updateSubject,
      updateMessage,
      updateReplyTo,
      updateTemplateTitle,
      updateInvoiceDueDate,
      updateInvoiceNotes,
      mergeTags,
      updateAttachments,
      attachments
    } = this.props;
    return (
      <div className={css.messageContainer}>
        <Message
          mergeTags={mergeTags}
          height={270}
          version={2}
          showSubject={mode === MODES.EMAIL}
          showTemplates={mode === MODES.EMAIL}
          useWYSIWYG={mode === MODES.EMAIL}
          updateSubject={updateSubject}
          subject={subject}
          updateReplyTo={updateReplyTo}
          updateMessage={updateMessage}
          updateTemplateTitle={updateTemplateTitle}
          updateInvoiceDueDate={updateInvoiceDueDate}
          updateInvoiceNotes={updateInvoiceNotes}
          updateAttachments={updateAttachments}
          newTemplate={newTemplate}
          message={message}
          templateTitle={templateTitle}
        />
        {mode === MODES.EMAIL ? (
          <Attachments
            attachments={attachments}
            updateAttachments={updateAttachments}
          />
        ) : null}
      </div>
    );
  }
}

Setup.propTypes = {
  message: PropTypes.string.isRequired,
  newTemplate: PropTypes.bool.isRequired,
  templateTitle: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired,
  updateSubject: PropTypes.func.isRequired,
  updateMessage: PropTypes.func.isRequired,
  updateReplyTo: PropTypes.func.isRequired,
  updateTemplateTitle: PropTypes.func.isRequired,
  toggleTemplate: PropTypes.func.isRequired
};

export default Setup;
