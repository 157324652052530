import Helpers from "utils/Global/Helpers";

/* eslint no-underscore-dangle: "off" */

export default {
  getQuestionSets: ({ credentials, eventId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/items/question-sets/event/${eventId}`,
        credentials,
        success,
        error
      });
    }),
  getQuestions: ({ credentials, eventId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/items/questions/event/${eventId}`,
        credentials,
        success,
        error
      });
    }),
  getSelectedIds: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${
          window.__LENND_API_BASE_URL__
        }/items/variant-question-assignments`,
        credentials,
        success,
        error,
        data
      });
    }),
  saveSelectedIds: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${
          window.__LENND_API_BASE_URL__
        }/items/question-variant-assignments`,
        credentials,
        success,
        error,
        data
      });
    }),
  assignQuestions: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${
          window.__LENND_API_BASE_URL__
        }/items/variant-question-assignments`,
        credentials,
        success,
        error,
        data
      });
    })
};
