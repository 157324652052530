import { REQUEST, RECEIVE, RECEIVE_GROUP_DATA } from "./constants";
import { accounts } from "@lennd/value-types/src/constants/standard-modules";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";
import { eventId } from "redux/modules/event/selectors";

export function getGuestLists() {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: REQUEST });

      const result = await api.get(
        getState().user.user.credentials,
        eventId(getState())
      );
      dispatch({
        type: RECEIVE,
        payload: result.payload
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching guest lists"
          }
        ])
      );
    }
  };
}

export function getGuestListData(recordTypeId) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: REQUEST });

      const { groups } = await api.getListData(
        getState().user.user.credentials,
        recordTypeId,
        accounts.id
      );
      dispatch({
        type: RECEIVE_GROUP_DATA,
        payload: { typeId: recordTypeId, groups }
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching guest lists"
          }
        ])
      );
    }
  };
}

export function updateGuestList(recordTypeId, data) {
  return async (dispatch, getState) => {
    try {
      return await api.post(getState().user.user.credentials, {
        eventId: eventId(getState()),
        recordTypeId,
        ...data
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating guest list"
          }
        ])
      );
    }
  };
}

export function enableGuestList(recordTypeId) {
  return async (dispatch, getState) => {
    try {
      return await api.post({
        eventId: eventId(getState()),
        recordTypeId,
        disable: false
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating guest list"
          }
        ])
      );
    }
  };
}

export function disableGuestList(recordTypeId) {
  return async (dispatch, getState) => {
    try {
      return await api.post({
        eventId: eventId(getState()),
        recordTypeId,
        disable: true
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating guest list"
          }
        ])
      );
    }
  };
}
