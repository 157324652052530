import React from "react";
import {
  Div,
  Text1,
  Text2,
  Text3,
  DragIcon,
  CloseIcon,
  InfoIcon,
  Cards,
  ErrorIcon
} from "components/Base";

import { actions, getters } from "../index";

import { connect } from "react-redux";

import {
  getDefaultSelectedFields,
  getNonDefaultSelectedFields
} from "../selectors";

const decorate = connect(
  state => ({
    defaultFields: getDefaultSelectedFields(state),
    fields: getNonDefaultSelectedFields(state),
    enableRequiredFields: getters.enableRequiredFields(state)
  }),
  {
    updateSelectedFieldOrder: actions.updateSelectedFieldOrder,
    toggleField: actions.toggleField,
    toggleRequiredField: actions.toggleRequiredField,
    removeAllSelectedFields: actions.removeAllSelectedFields
  }
);

const Field = ({
  toggleField,
  toggleRequiredField,
  draggable,
  enableRequiredFields,
  ...q
}) => (
  <Div bra={1} bg="altB1" width={1} p={2} mt={2}>
    <Div display="row.space-between.center">
      <Div display="row.flex-start.center">
        {draggable ? <DragIcon /> : null}
        {q.tooltip ? (
          <InfoIcon mr={1} tooltip={q.tooltip} color="gray5" size={15} />
        ) : null}
        <Text2 ml={2} color="black" bold>
          {q.name}
        </Text2>
      </Div>

      <Div display="row.flex-start.center">
        {enableRequiredFields ? (
          <ErrorIcon
            mr={2}
            color={{
              default: q.required || q.defaultRequired ? "danger7" : "gray4",
              hover: "danger7"
            }}
            onClick={
              q.defaultRequired ? undefined : () => toggleRequiredField(q.id)
            }
            tooltip={
              q.defaultRequired
                ? "Field is mandatory"
                : "Toggle if field is required"
            }
          />
        ) : null}
        {!q.defaultSelected ? (
          <CloseIcon
            color={{
              default: "gray4",
              hover: "gray5"
            }}
            onClick={() => toggleField(q.id)}
          />
        ) : null}
      </Div>
    </Div>
  </Div>
);

const FieldRows = Cards(Field, "SELECT_MODULE_FIELDS");

const SelectedFields = ({
  defaultFields,
  fields,
  updateSelectedFieldOrder,
  removeAllSelectedFields,
  toggleField,
  toggleRequiredField,
  enableRequiredFields
}) => (
  <Div width={1} height={1} style={{ overflowY: "auto" }} p={3}>
    <Div display="row.space-between.center">
      <Text3 bold color="black">
        Selected Fields
      </Text3>
      <Text1 color="primary7" underline onClick={removeAllSelectedFields}>
        Remove All
      </Text1>
    </Div>
    <Div width={1} mt={1}>
      {defaultFields.map(field => (
        <Field
          key={field.id}
          toggleRequiredField={toggleRequiredField}
          toggleField
          enableRequiredFields={enableRequiredFields}
          {...field}
        />
      ))}
      <FieldRows
        draggable
        cards={fields}
        onReorder={updateSelectedFieldOrder}
        toggleRequiredField={toggleRequiredField}
        toggleField={toggleField}
        enableRequiredFields={enableRequiredFields}
      />
    </Div>
  </Div>
);

export default decorate(SelectedFields);
