import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Popover from "@lennd/material-ui/Popover";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";

@CSSModules(css)
class ReportPanelMenu extends Component {
  state = { showPopover: false };

  onRequestClose = () => this.setState({ showPopover: false });

  handleClick = e => {
    e.stopPropagation();
    return this.setState({ showPopover: true });
  };

  wrapClick = action => () => {
    this.onRequestClose();
    action();
  };

  render() {
    return (
      <div>
        <span
          onClick={this.handleClick}
          ref={ref => {
            this.anchor = ref;
          }}
        >
          <div styleName="button">
            Options <i className="material-icons">keyboard_arrow_down</i>
          </div>
        </span>
        <Popover
          anchorEl={this.anchor}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          onClose={this.onRequestClose}
          open={this.state.showPopover}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <Menu desktop>
            <MenuItem
              onClick={this.wrapClick(this.props.onAddItem)}
              primaryText="Add Item"
            />
          </Menu>
        </Popover>
      </div>
    );
  }
}

ReportPanelMenu.propTypes = {
  onAddItem: PropTypes.func.isRequired
};

export default ReportPanelMenu;
