import Helpers from "utils/Global/Helpers";

module.exports = {
  get: (credentials, eventId) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/event/progress/${eventId}`,
        credentials,
        data: {},
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  post: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/event/progress`,
        credentials,
        data,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  setup: (credentials, eventId) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/event/${eventId}/setup`,
        credentials,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  put: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/event/progress/${data.eventId}`,
        credentials,
        data,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
