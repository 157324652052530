import React from "react";

import { Div } from "components/Base";

import MealItems from "Orders/ItemPickers/MealItems";

const Catering = ({ activeType, cateringMealsPerDay, eventDateGroups }) => (
  <Div my={6}>
    <MealItems
      {...{
        activeType,
        cateringMealsPerDay,
        eventDateGroups,
        toggleOnly: true,
        datesPosition: "top"
      }}
    />
  </Div>
);

export default Catering;
