import React from "react";
import { Page, Title } from "EventLight/Common/FieldLayout";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";

import Catalog from "EventLight/Common/Items/Catalog/View";

const Layout = () => (
  <>
    <Page>
      <Title
        icon="assignment_turned_in"
        title="Passes"
        description="Manage your tickets, wristbands, laminates and other types of passes"
      />
      <Catalog typeId={CREDENTIAL_TYPE_ID} />
    </Page>
  </>
);

export default Layout;
