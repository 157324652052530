import React from "react";
import moment from "moment";
import { Div } from "components/Base";

export const Timestamp = ({ stamp, eventName }) => (
  <Div style={{ fontSize: 13 }} color="#979698">
    {moment(stamp).fromNow()}
    {eventName ? <>&nbsp;&middot;&nbsp;{eventName}</> : null}
  </Div>
);

export default Timestamp;
