import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { Div, FontIcon } from "components/Base";

const PortalSidebarMenuItem = ({
  hideMenu,
  paddingLeft = 0,
  customStyle = {},
  ...props
}) => {
  const textStyle = {
    width: 235 - paddingLeft,
    marginRight: 5,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  };
  return props.url ? (
    <Link
      to={props.url}
      styleName={props.active ? "itemLinkActive" : "itemLink"}
      onClick={hideMenu}
      style={{ ...customStyle, paddingLeft: 28 + paddingLeft }}
    >
      <div style={textStyle}>{props.children}</div>
      {props.rightIcon ? (
        <FontIcon color="white" fs={3} ml={3}>
          {props.rightIcon}
        </FontIcon>
      ) : null}
    </Link>
  ) : (
    <div
      styleName="item"
      style={{ ...customStyle, paddingLeft: 28 + paddingLeft }}
    >
      <Div display="row.flex-start.center" styleName="wrapper">
        <div style={textStyle}>{props.children}</div>
        {props.rightIcon ? (
          <FontIcon color="white" fs={5} ml={2}>
            {props.rightIcon}
          </FontIcon>
        ) : null}
      </Div>
    </div>
  );
};

PortalSidebarMenuItem.propTypes = {
  active: PropTypes.bool,
  url: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default CSSModules(PortalSidebarMenuItem, css);
