import { put, call } from "redux-saga/effects";

import { actions } from "../model";

import { apiCall } from "./utils";

export const getSupport = function*() {
  try {
    // get support articles
    const result = yield call(apiCall, {
      method: "get",
      url: `/support/articles`
    });

    yield put(
      actions.supportResponse({
        articles: result.payload
      })
    );

    return result;
  } catch (error) {
    yield put(actions.error(error, { meta: {} }, true));
    throw error;
  }
};
