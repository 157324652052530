import React, { Component } from "react";
import EditColumns from "components/Global/CRM/Modals/EditColumns";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { get } from "lodash";
import kanbanFieldFilter from "../utils/kanban-field-filter";
import resolveViewPath from "components/Event/Module/utils/resolve-view-path";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as R from "ramda";
import FormWizardModal from "Forms/WizardModal/View";

import {
  VIEW_MY_RECORDS_ID,
  VIEW_PENDING_RECORDS_ID,
  VIEW_APPROVED_RECORDS_ID,
  VIEW_DENIED_RECORDS_ID
} from "components/Event/Module/utils/views";
import * as SYSTEM_FIELD_IDS from "utils/system-field-ids";
const { EVENT_LIST } = SYSTEM_FIELD_IDS;

const filterByEventId = recordTypeFilter =>
  R.compose(
    R.length,
    R.filter(
      R.compose(
        recordTypeFilter,
        R.path(["values", EVENT_LIST, "value"])
      )
    )
  );

const localStorageId = moduleId => `kanban_settings_${moduleId}`;

const filterOwnedRecords = (records, userId) =>
  records.filter(r => r.owners && r.owners.some(o => o.id === userId));

const filterRecordTypeRecords = (records, typeId) =>
  records.filter(r => r.type_id === typeId);

class SidebarBase extends Component {
  baseUrl = ({ orgId, eventId, moduleId }) =>
    orgId
      ? `/organization/${orgId}/module/${moduleId}`
      : `/event/${eventId}/module/${moduleId}`;

  selectView = viewId => {
    const { params, router, location, views } = this.props;
    this.props.setActiveView({
      moduleId: params.moduleId,
      viewId,
      options: {
        orgId: params.orgId,
        eventId: params.eventId,
        accountRecordTypeId: params.groupId
      }
    });
    return router.push({
      pathname: `${this.baseUrl(params)}${resolveViewPath({
        views,
        viewId,
        groupId: this.props.params.groupId,
        filterByAccountRecordTypeId: this.props.params
          .filterByAccountRecordTypeId,
        filterByContactRecordTypeId: this.props.params
          .filterByContactRecordTypeId
      })}`,
      query: location.query
    });
  };

  goToModuleSetup = () => {
    const { orgId, eventId, moduleId } = this.props.params;
    const pathname = orgId
      ? `/organization/${orgId}/settings/module/${moduleId}`
      : `/event/${eventId}/settings/module/${moduleId}`;

    this.props.router.push({
      pathname: pathname
    });
  };

  goToReports = () => {
    const { orgId, eventId } = this.props.params;
    const pathname = orgId
      ? `/organization/${orgId}/reports`
      : `/event/${eventId}/reports`;

    this.props.router.push({
      pathname: pathname
    });
  };

  resolveActiveView = (activeType, types = [], recordNamePlural) => {
    if (types.find(t => activeType === t.id)) {
      return types.find(t => activeType === t.id).name;
    }
    if (activeType === VIEW_MY_RECORDS_ID) {
      return <span>My {recordNamePlural}</span>;
    }
    if (activeType === VIEW_PENDING_RECORDS_ID) {
      return <span>Pending</span>;
    }
    if (activeType === VIEW_APPROVED_RECORDS_ID) {
      return <span>Approved</span>;
    }
    if (activeType === VIEW_DENIED_RECORDS_ID) {
      return <span>Denied</span>;
    }
    return <span>All {recordNamePlural}</span>;
  };

  removeFilter = filterIndex => {
    this.updateViewMeta({
      filters: {
        ...this.props.activeView.filters,
        filters: this.props.activeView.filters.filters.filter(
          (f, i) => i !== filterIndex
        )
      }
    });
  };

  addSort = item => {
    this.updateViewMeta({
      sortBy: [
        ...this.props.activeView.sort_by.filter(
          f => f.fieldId !== item.fieldId
        ),
        item
      ]
    });
  };

  removeSort = sortIndex => {
    this.updateViewMeta({
      sortBy: this.props.activeView.sort_by.filter((f, i) => i !== sortIndex)
    });
  };

  handleModuleSettingChange = (options = {}) => {
    this.props.updateTableLayout({
      moduleId: this.props.moduleDetails.id,
      options: { ...this.props.moduleSettings, ...options }
    });
  };

  handleKanbanFieldsChange = cardFields => {
    this.updateViewMeta({ cardFields });
  };

  updateScheduleVisibleFields = cardFields => {
    this.updateViewMeta({ cardFields });
  };
  updateScheduleFieldsOrder = fieldOrder => {
    this.updateViewMeta({ fieldOrder });
  };
  updateColorByField = colorBy => {
    this.updateViewMeta({ colorBy });
  };

  saveCustomCardFields = () => {
    localStorage.setItem(
      localStorageId(this.props.moduleDetails.id),
      JSON.stringify({ fields: this.props.moduleSettings.kanbanCardFields })
    );
  };

  handleActiveFieldChange = field => {
    if (field) {
      this.props.updateTableLayout({
        moduleId: this.props.moduleDetails.id,
        options: { kanbanViewBy: field.field }
      });
    }
  };

  showEditColumnsModal = () => {
    this.props.showModal({
      content: (
        <EditColumns
          onSave={this.updateViewMeta}
          columns={this.props.getColumns(this.props.fields)}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showAddFormModal = () => {
    /*
    const modal = (
      <AddFormModal
        eventId={this.props.params.eventId}
        moduleId={this.props.moduleDetails.id}
        handleAddForm={data => {
          this.props.addForm(data).then(form => {
            this.props.router.push({
              pathname: `/event/${this.props.params.eventId}/forms-v2/${
                form.id
              }/edit?initialEdit=1`
            });
            this.props.showSnackbar({ message: "Form created", action: "OK" });
          });
        }}
      />
    );

    this.props.showModal({
      content: modal,
      wrapper: ModalWrapper
    });
    */
    this.props.showModal({
      content: <FormWizardModal />,
      wrapper: ModalWrapper
    });
  };

  toggleColumnVisibility = (id, visible) => {
    const { fields } = this.props;
    const columns = this.props.getColumns(fields);

    const visibleColumns = columns
      .filter(c => (c.id === id ? visible : c.visible))
      .map(c => c.id);

    const columnOrder = get(this.props.preferences, "field_order");
    return this.updateViewMeta({
      visibleColumns,
      columnOrder
    });
  };

  getSelectedType = () => get(this.props.location, "query.type");

  getSelectedSavedView = () => get(this.props.location, "query.view");

  countRows = (activeType, rows) => {
    if (this.props.activeView.filter_by_event_id) {
      const recordTypeFilter = activeType
        ? R.any(
            v =>
              v.event_id === this.props.activeView.filter_by_event_id &&
              v.record_type_id === activeType
          )
        : R.any(R.propEq("event_id", this.props.activeView.filter_by_event_id));

      return filterByEventId(recordTypeFilter)(rows);
    }

    if (activeType) {
      if (activeType === VIEW_MY_RECORDS_ID) {
        return filterOwnedRecords(rows, this.props.user.id).length;
      }

      if (
        this.props.activeView.quick_filters &&
        this.props.activeView.quick_filters.includes(VIEW_MY_RECORDS_ID)
      ) {
        return filterRecordTypeRecords(
          filterOwnedRecords(rows, this.props.user.id),
          activeType
        ).length;
      }

      return filterRecordTypeRecords(rows, activeType).length;
    }

    return rows.length;
  };

  getReports = () => [];

  renderReports = () => "";

  resolveView = ({ type, view }) => type || view;

  updateViewMeta = async ({
    reportId,
    visibleColumns,
    columnOrder,
    sortBy,
    filters,
    quickFilters,
    filterByEventId,
    groupedBy,
    cardFields,
    fieldOrder,
    colorBy,
    isPublic,
    name
  }) => {
    const data = {
      orgId: this.props.params.evenorgIdtId,
      eventId: this.props.params.eventId,
      moduleId: this.props.moduleDetails.id,
      viewId: this.props.params.viewId,
      view: {},
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId
      }
    };

    if (visibleColumns) {
      data.view.visibleFields = visibleColumns;
    }
    if (columnOrder || fieldOrder) {
      data.view.fieldOrder = columnOrder || fieldOrder;
    }
    if (sortBy) {
      data.view.sortBy = sortBy;
    }
    if (filters) {
      data.view.filters = filters;
    }
    if (quickFilters) {
      data.view.quickFilters = quickFilters;
    }
    if (typeof groupedBy !== "undefined") {
      data.view.groupedBy = groupedBy;
    }
    if (typeof filterByEventId !== "undefined") {
      data.view.filterByEventId = filterByEventId;
    }
    if (cardFields) {
      data.view.cardFields = cardFields;
    }
    if (colorBy) {
      data.view.colorBy = colorBy;
    }
    if (reportId) {
      data.view.reportId = reportId;
    }
    if (typeof isPublic !== "undefined") {
      data.view.isPublic = isPublic;
    }
    if (name) {
      data.view.name = name;
    }

    this.props.updateView(data);
  };

  canViewKanban = () =>
    this.props.fields && this.props.fields.some(kanbanFieldFilter);

  canViewSchedule = () =>
    this.props.moduleDetails.type_id === STANDARD_MODULE_IDS.schedules.id;

  canViewReports = () =>
    this.props.canViewEventReports &&
    this.props.moduleDetails.id === STANDARD_MODULE_IDS.accounts.id;

  canView = viewType => {
    const viewEnabled = this.props.availableViews.includes(viewType);
    if (viewType === "kanban") return this.canViewKanban() && viewEnabled;
    if (viewType === "schedule") return this.canViewSchedule() && viewEnabled;
    return viewEnabled;
  };
}

SidebarBase.defaultProps = {
  availableViews: ["grid", "list", "kanban", "schedule", "reports"]
};

export default SidebarBase;
