import Helpers from "utils/Global/Helpers";

module.exports = {
  post: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */

      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/modules/${
          data.moduleId
        }/field-group-fields`,
        credentials,
        data,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */,

  put: (credentials, id, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */

      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/modules/${
          data.moduleId
        }/field-group-fields/${id}`,
        credentials,
        data,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */,

  delete: (credentials, id, moduleId) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */

      Helpers.request({
        method: "delete",
        url: `${
          window.__LENND_API_BASE_URL__
        }/modules/${moduleId}/field-group-fields/${id}`,
        credentials,
        success,
        error
      });
    })
  /* eslint-enable no-underscore-dangle */
};
