import React from "react";
import { connect } from "react-redux";
import { Div } from "components/Base";
import SearchBar from "ui-kit/SearchBar/View";
import ProgressBar from "Portal/PortalHome/View/ProgressBar";
import * as R from "ramda";
import Loading from "ui-kit/Loading";
import MediaQuery from "react-responsive";

import { VARIANTS } from "ui-kit/Theme/constants";
import {
  tasksSelector,
  filteredTasks,
  getCountOfAllSections
} from "Portal/PortalHome/selectors";
import ToggleDueDate from "Portal/PortalHome/View/ToggleDueDate";
import FilterBy from "Portal/PortalHome/View/FilterBy";
import Task from "Portal/PortalHome/View/Task";
import EmptyState from "components/PortalV2/Home/EmptyState";

import { getters } from "Portal/PortalHome/model";

const Section = ({ title, data }) => (
  <Div>
    {title && (
      <Div fs={5} fw={3} mb={3} mt={5} color="black">
        {title}
      </Div>
    )}
    {data.map(task => (
      <Task key={task.id} type={task.type} data={task} />
    ))}
  </Div>
);

const Mobile = props => <MediaQuery {...props} maxWidth={1240} />;
const Default = props => <MediaQuery {...props} minWidth={1241} />;

const decorate = connect(state => ({
  loading: getters.loading(state),
  percent_complete: getters.percent_complete(state),
  tasksSelector: tasksSelector(state),
  filteredTasks: filteredTasks(state),
  countOfAllSections: getCountOfAllSections(state)
}));

const Tasks = ({
  loading,
  percent_complete,
  filteredTasks,
  countOfAllSections
}) => {
  const sections = filteredTasks.filter(task => R.length(task.data));
  return loading && !countOfAllSections ? (
    <Loading />
  ) : !countOfAllSections ? (
    <EmptyState
      title="You don’t have any tasks to complete yet"
      description="When tasks are assigned by your event administrator, they will be listed here,
        along with due dates, task status, and other information."
    />
  ) : (
    <Div>
      <Mobile>
        <Div display="column" mb={4}>
          <Div display="row.flex-start.center" width={1} mb={2}>
            <SearchBar
              pill
              shadow={0}
              filters={[]}
              variant={VARIANTS.BLUE_SECONDARY}
              flex={1}
              placeholder="Search"
              bg="neutral2"
            />
            <Div ml={2}>
              <ToggleDueDate />
            </Div>
          </Div>
          <Div display="row.flex-start.center" width={1}>
            <FilterBy />
          </Div>
        </Div>
      </Mobile>
      <Default>
        <Div display="row.space-between.center" mb={4}>
          <Div width={0.4}>
            <SearchBar
              pill
              shadow={0}
              filters={[]}
              variant={VARIANTS.BLUE_SECONDARY}
              flex={1}
              placeholder="Search"
              bg="neutral2"
            />
          </Div>
          <Div display="row.flex-end.center" width={0.5}>
            <Div mr={1}>
              <ToggleDueDate />
            </Div>
            <FilterBy />
          </Div>
        </Div>
      </Default>

      {isNaN(parseInt(percent_complete, 10)) ? null : (
        <Div
          bg="neutral2"
          pill
          display="row.center.center"
          mb={4}
          py={2}
          px={3}
        >
          <Div
            fs={2}
            mr={3}
            fw={3}
            color="gray8"
            style={{ whiteSpace: "nowrap" }}
          >
            {percent_complete}% complete
          </Div>
          <ProgressBar progress={percent_complete} />
        </Div>
      )}
      <Div>
        {sections.map(task => (
          <Section key={task.id} title={task.title} data={task.data} />
        ))}
      </Div>
    </Div>
  );
};

export default decorate(Tasks);
