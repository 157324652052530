import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import { actions } from "./index";

import { registerError } from "redux/modules/errors/actions";

import { getCredentials } from "redux/modules/user/selectors";
import { getDataToSave } from "./selectors";

import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);

  return {
    credentials
  };
};

const init = function*({
  payload: {
    itemId,
    variantId,
    selectedPriceId,
    limit,
    remaining,
    disableChangingPrice,
    quantity,
    questions,
    color,
    buttonLabel,
    showInternalQuestions = true
  }
}) {
  try {
    yield put(actions.setLoading(true));

    const { credentials } = yield call(getParams);

    if (variantId) {
      const { payload } = yield call(Api.getVariant, {
        credentials,
        variantId
      });
      const variant = payload[0];
      yield put(
        actions.setInitialData({
          item: {
            ...variant.item,
            variants: [variant]
          },
          hasSingleVariant: true,
          selectedPriceId,
          disableChangingPrice,
          limit,
          remaining,
          quantity,
          questions,
          color,
          buttonLabel,
          imageUrl: variant.item.photo_url,
          showInternalQuestions
        })
      );
    } else {
      const { payload } = yield call(Api.getItem, {
        credentials,
        itemId
      });
      yield put(
        actions.setInitialData({
          item: payload[0],
          hasSingleVariant: payload[0].variants.length === 1,
          selectedPriceId,
          disableChangingPrice,
          limit,
          remaining,
          imageUrl: payload[0].photo_url
        })
      );
    }
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading item"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const onSave = function*({ payload: { callback } }) {
  const data = yield select(getDataToSave);
  return callback(data);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchOnSave = function*() {
  yield takeEvery(actions.onSave.type, onSave);
};

const rootSaga = function*() {
  yield all([fork(watchInit), fork(watchOnSave)]);
};

export default rootSaga;
