import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import { getOrderId } from "Orders/OrderModal/selectors";
import * as SubmissionSelectors from "Submission/Editor/selectors";

import { Div, Text2, Text4, BigFilledButton } from "components/Base";

import Panel from "ui-kit/Panel";

const decorate = connect(state => ({
  orderId: getOrderId(state),
  submissionRecordId: SubmissionSelectors.getSubmissionRecordId(state),
  submissionAccount: SubmissionSelectors.getSubmissionAccount(state)
}));

const EnableOrder = ({ handlers, submissionRecordId, submissionAccount }) => {
  return (
    <Panel display="row.space-between.center" px={8} py={3} bg="white">
      <Div display="column.flex-start" width={400}>
        <Text4 bold color="gray7">
          Enable Order Features
        </Text4>
        <Div display="column">
          <Text2>
            Add ability to attach items, collect payment and track fulfillment
            of physical items associated with this submission
          </Text2>
        </Div>
      </Div>

      <Div display="row.flex-end.center">
        <BigFilledButton
          bg="purple7"
          color="white"
          ml={2}
          onClick={() =>
            handlers.showAddOrderModal({
              submissionRecordId,
              orderType: "group",
              accountId: R.prop("id")(submissionAccount),
              accountName: R.prop("name")(submissionAccount)
            })
          }
        >
          Enable
        </BigFilledButton>
      </Div>
    </Panel>
  );
};

export default decorate(EnableOrder);
