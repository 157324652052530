import React, { Component } from "react";

import getApprovalValue from "utils/value-types/get-value/approval";
import ApproversLabel from "components/Global/Approvals/ApproversLabel";
import { parseApproval } from "components/Global/Approvals/utils/approvals-helpers";
import { Div } from "components/Base";

import { noop } from "utils/General";

import {
  isManifestApproved,
  isManifestRejected
} from "components/Global/Approvals/utils/approvals-helpers";

import {
  getRow,
  getHandlers,
  getMeta,
  getIsRowEmpty,
  getIsRowDraft
} from "ui-kit/Datagrid/utils";

// @TODO refactor
class FormV3RowApproval extends Component {
  reviewRecord = response => {
    const rowId = getRow(this.props, "id");
    const makeReview = getHandlers(this.props, "makeReview", noop);
    return makeReview({ rowId, response });
  };

  isDenied(value) {
    return isManifestRejected(getApprovalValue(value));
  }

  isApproved(value) {
    return isManifestApproved(getApprovalValue(value));
  }

  getReadOnlyValue = value => {
    if (this.isApproved(value)) {
      return "Approved";
    }
    if (this.isDenied(value)) {
      return "Denied";
    }
    return "Pending";
  };

  render() {
    const readOnly = getMeta(this.props, "readOnly");
    const userId = getMeta(this.props, ["user", "id"]);
    const isRowEmpty = getIsRowEmpty(this.props);
    const isRowDraft = getIsRowDraft(this.props);

    if (isRowDraft) {
      return (
        <Div display="row.center.center" fw={3} color="gray3" uppercase>
          Not Submitted
        </Div>
      );
    }

    if (isRowEmpty) {
      return (
        <Div display="row.center.center" fw={3} color="gray3" uppercase>
          Empty
        </Div>
      );
    }

    if (readOnly) {
      return (
        <Div display="row.center.center" fw={3} color="gray3" uppercase>
          {this.getReadOnlyValue(this.props.value)}
        </Div>
      );
    }

    return (
      <ApproversLabel
        {...{
          ...parseApproval({
            record: {
              approval_manifest: getApprovalValue(this.props.value)
            },
            userId
          }),
          approveLineItems: () => this.reviewRecord("approve"),
          rejectLineItems: () => this.reviewRecord("reject"),
          removeApproval: () => this.reviewRecord(null),
          showMenu: true
        }}
      />
    );
  }
}

export default FormV3RowApproval;
