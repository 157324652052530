import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import {
  Div,
  Text2,
  Text3,
  Text5,
  MediumOutlineInput,
  TextAreaInput
} from "components/Base";
import { getters, actions } from "../model";
import {
  getNotDraggableContactFields,
  getDraggableContactSectionsAndFields,
  getDraggableContactFieldsIds
} from "../selectors";
import FormQuestions from "./FormQuestions";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

const decorate = connect(
  state => ({
    contactTitle: getters.contactTitle(state),
    contactDescription: getters.contactDescription(state),
    contactFields: getters.contactFields(state),
    notDraggableContactFields: getNotDraggableContactFields(state),
    requiredContactIds: getters.requiredContactIds(state),
    draggableContactFields: getDraggableContactSectionsAndFields(state),
    selectedContactFields: getters.selectedContactFields(state),
    draggableContactFieldsIds: getDraggableContactFieldsIds(state),
    selectedContactsOrder: getters.selectedContactsOrder(state),
    contactsTempDescription: getters.contactsTempDescription(state),
    contactsTempAlias: getters.contactsTempAlias(state)
  }),
  {
    setContactTitle: actions.setContactTitle,
    setContactDescription: actions.setContactDescription,
    removeContactRow: actions.removeContactRow,
    toggleContactRequiredField: actions.toggleContactRequiredField,
    reorderContacts: actions.reorderContacts,
    updateContactsDescription: actions.updateContactsDescription,
    setContactsTempDescription: actions.setContactsTempDescription,
    addContactsHeaderSection: actions.addContactsHeaderSection,
    removeContactsHeaderSection: actions.removeContactsHeaderSection,
    updateContactsDescriptionHeaderSection:
      actions.updateContactsDescriptionHeaderSection,
    updateContactsTitleHeaderSection: actions.updateContactsTitleHeaderSection,
    setContactsTempAlias: actions.setContactsTempAlias,
    updateContactsAlias: actions.updateContactsAlias
  }
);

const ContactInformation = ({
  contactTitle,
  setContactTitle,
  setContactDescription,
  contactDescription,
  handlers,
  contactFields,
  removeContactRow,
  toggleContactRequiredField,
  notDraggableContactFields,
  requiredContactIds,
  draggableContactFields,
  selectedContactFields,
  draggableContactFieldsIds,
  selectedContactsOrder,
  reorderContacts,
  contactsTempDescription,
  updateContactsDescription,
  setContactsTempDescription,
  addContactsHeaderSection,
  removeContactsHeaderSection,
  updateContactsDescriptionHeaderSection,
  updateContactsTitleHeaderSection,
  contactsTempAlias,
  setContactsTempAlias,
  updateContactsAlias
}) => (
  <Div
    style={{
      overflow: "auto",
      position: "relative"
    }}
    height={1}
    width={1}
    bg="neutral0"
  >
    <Div
      py={5}
      px={8}
      style={{ position: "absolute", top: 0, left: 0 }}
      width={1}
    >
      <Text5 color="black" bold>
        Contact Information
      </Text5>
      <Text2>
        Collect information for the account's primary contact / login user.
      </Text2>
      <Div bra={1} bg="white" shadow={1} p={4} mt={4} mb={3}>
        <Div display="row.flex-start.center">
          <Text3 bold mr={3}>
            Section Title
          </Text3>
          <Div flex={1}>
            <MediumOutlineInput
              width={1}
              value={contactTitle}
              onChange={setContactTitle}
            />
          </Div>
        </Div>
        <Div mt={4} display="row.space-between.center" mb={2}>
          <Text3 bold>Description & Instructions</Text3>
        </Div>
        <TextAreaInput
          width={1}
          value={contactDescription}
          onChange={setContactDescription}
          height={85}
          style={{ resize: "vertical" }}
          mt={1}
        />
      </Div>
      <FormQuestions
        removeRow={removeContactRow}
        requiredFields={notDraggableContactFields}
        draggableFields={draggableContactFields}
        showFieldsModal={() =>
          handlers.showContactFieldsModal({
            fields: contactFields,
            notDraggableFieldIds: draggableContactFieldsIds,
            requiredFields: requiredContactIds,
            visibleFields: R.map(R.prop("id"), selectedContactFields),
            fieldOrder: selectedContactsOrder
          })
        }
        toggleRequiredField={toggleContactRequiredField}
        reorderFields={reorderContacts}
        updateDescription={updateContactsDescription}
        tempDescription={contactsTempDescription}
        setTempDescription={setContactsTempDescription}
        editField={fieldId =>
          handlers.editField({
            moduleId: STANDARD_MODULES.contacts.id,
            fieldId
          })
        }
        showAddFieldModal={() =>
          handlers.showAddFieldModal({
            moduleId: STANDARD_MODULES.contacts.id
          })
        }
        addHeaderSection={addContactsHeaderSection}
        removeHeaderSection={removeContactsHeaderSection}
        updateDescriptionHeaderSection={updateContactsDescriptionHeaderSection}
        updateTitleHeaderSection={updateContactsTitleHeaderSection}
        tempAlias={contactsTempAlias}
        setTempAlias={setContactsTempAlias}
        updateAlias={updateContactsAlias}
      />
    </Div>
  </Div>
);

export default decorate(ContactInformation);
