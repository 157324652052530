import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Popover from "@lennd/material-ui/Popover";
import MenuItem from "material-ui/MenuItem";
import Menu from "material-ui/Menu";
import DeleteConfirmation from "components/Global/CRM/Popovers/DeleteConfirmation";

class UserMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopover: false
    };
  }

  @autobind
  onRequestClose() {
    this.setState({ showPopover: false });
  }

  @autobind
  handleClick(e) {
    e.stopPropagation();
    this.setState({ showPopover: true });
  }

  handleAction(action) {
    this.onRequestClose();
    action();
  }

  render() {
    return (
      <div>
        <span
          onClick={this.handleClick}
          ref={ref => {
            this.anchor = ref;
          }}
        >
          {this.props.children}
        </span>
        <Popover
          anchorEl={this.anchor}
          anchorOrigin={{ horizontal: "left", vertical: "top" }}
          onClose={this.onRequestClose}
          open={this.state.showPopover}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
        >
          <Menu>
            <DeleteConfirmation
              onConfirm={() => this.handleAction(this.props.onRemove)}
              text="Are you sure you want to remove this owner?"
            >
              <MenuItem value="delete" primaryText="Remove owner" />
            </DeleteConfirmation>
          </Menu>
        </Popover>
      </div>
    );
  }
}

UserMenu.propTypes = {
  children: PropTypes.node.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default UserMenu;
