import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as R from "ramda";
import {
  Div,
  Text0,
  Text2,
  Text1,
  TinyOutlineButton,
  RemoveIcon
} from "components/Base";
import { getters, actions } from "EventLight/Inventory/Dashboard";
import {
  getSelectedView,
  getViewsToSelect
} from "EventLight/Inventory/Dashboard/selectors";

import SecondarySidebar from "ui-kit/SecondarySidebar";
import SelectView from "ui-kit/SelectView";
import { SUMMARY_TYPE } from "EventLight/Inventory/Dashboard/constants";

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      views: getViewsToSelect(state, props),
      selectedView: getSelectedView(state, props),
      categoryStats: getters.categoryStats(state, props),
      loading: getters.loading(state, props),
      summaryType: getters.summaryType(state, props)
    }),
    {
      setSummaryType: actions.setSummaryType,
      scrollToCategory: actions.setScrollToCategoryId
    }
  )
);

const LoadingSidebarList = ({ width = 70 }) => (
  <Div
    display="row.space-between.center"
    p={2}
    bra={1}
    transition="fast"
    bg="white"
  >
    <Div height={8} width={width} bra={1} bg="neutral1" />
    <RemoveIcon color="neutral1" />
  </Div>
);

const Sidebar = ({
  views = [],
  selectedView,
  categoryStats,
  loading,
  summaryType,
  setSummaryType,
  scrollToCategory
}) => {
  return (
    <SecondarySidebar
      display="column.flex-start.stretch"
      height={1}
      px={2}
      maxWidth={200}
    >
      {/* <Div width={47} height={1} p={1}>
        <Div
          width={1}
          height={37}
          bg={{ default: "primary5", hover: "primary4" }}
          bra={1}
          display="row.center.center"
        >
          <RightIcon color="primary2" size={24} />
        </Div>
      </Div> */}
      {/*
      <Div mt={2} px={2} display="row.flex-start.center">
        <SelectView views={views} selectedView={selectedView} />
      </Div>
      */}
      <Div mt={4} width={1}>
        <Div display="row.space-between.center" px={3}>
          <Text0>JUMP TO SUMMARY</Text0>
          {loading ? (
            <Div width={30} height={10} bg="neutral1" bra={1} />
          ) : (
            <TinyOutlineButton
              onClick={() =>
                setSummaryType(
                  summaryType === SUMMARY_TYPE.PENDING
                    ? SUMMARY_TYPE.APPROVED
                    : SUMMARY_TYPE.PENDING
                )
              }
            >
              {summaryType}
            </TinyOutlineButton>
          )}
        </Div>
        <Div mt={1}>
          {loading ? (
            <Div>
              <LoadingSidebarList width={90} />
              <LoadingSidebarList width={160} />
              <LoadingSidebarList />
            </Div>
          ) : (
            R.map(
              category => (
                <Div
                  key={category.id}
                  display="row.space-between.center"
                  py={2}
                  px={3}
                  bra={1}
                  transition="fast"
                  onClick={() => scrollToCategory(category.id)}
                  bg={{ default: "white", hover: "neutral0" }}
                  style={{ cursor: "pointer" }}
                >
                  <Text2 bold>{category.name}</Text2>
                  <Text1>
                    {summaryType === SUMMARY_TYPE.PENDING
                      ? category.count_of_pending
                      : category.count_of_approved}
                  </Text1>
                </Div>
              ),
              categoryStats
            )
          )}
        </Div>
      </Div>
    </SecondarySidebar>
  );
};

export default decorate(Sidebar);
