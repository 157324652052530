import approval from "./approval";
import boolean from "./boolean";
import date from "./date";
import number from "./number";
import text from "./text";
import time from "./time";
import user from "./user";
import staticValue from "./static";
import dropdown from "./dropdown";
import eventDays from "./event-days";
import credentials from "./credentials";
import lookup from "./lookup";
import catalogItem from "./catalog-item";
import accountList from "./account-list";

export default function(type) {
  switch (type) {
    case "approval":
      return approval;
    case "catalog-item":
      return catalogItem;
    case "dropdown":
      return dropdown;
    case "event-days":
      return eventDays;
    case "credentials":
      return credentials;
    case "boolean":
    case "checkbox":
      return boolean;
    case "date":
      return date;
    case "quantity":
    case "percent":
    case "currency":
    case "number":
      return number;
    case "time":
      return time;
    case "user":
      return user;
    case "static":
      return staticValue;
    case "lookup":
      return lookup;
    case "account-list":
      return accountList;
    case "email":
    case "textarea":
    case "text":
    default:
      return text;
  }
}
