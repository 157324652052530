import React from "react";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import Groups from "./Groups";
import StatusBar from "./StatusBar";
import QuickActions from "./QuickActions";
import TasksWidget from "Event/TasksWidget/View";
import MyApprovals from "Event/MyApprovals/View/Page";

import { Div } from "components/Base";

const Main = props => {
  const {
    canDo,
    handlers,
    params: { eventId },
    router: { push }
  } = props;
  const goToAccountSettings = groupId => {
    push(
      `/event-light/${eventId}/crm/settings/${STANDARD_MODULE_IDS.accounts.id}/types/${groupId}/portal`
    );
  };

  const goToContactSettings = groupId => {
    push(
      `/event-light/${eventId}/crm/settings/${STANDARD_MODULE_IDS.contacts.id}/types/${groupId}`
    );
  };

  const canUserManageAccounts = canDo(
    `${STANDARD_MODULE_IDS.accounts.id}_manage`
  );
  const canUserAddAccounts = canDo(
    `${STANDARD_MODULE_IDS.accounts.id}_accounts_add`
  );
  const canUserManageContacts = canDo(
    `${STANDARD_MODULE_IDS.contacts.id}_manage`
  );
  const canUserAddContacts = canDo(`${STANDARD_MODULE_IDS.contacts.id}_create`);
  const canInviteUsers = canDo(
    `${STANDARD_MODULE_IDS.settings.id}_invite_users`
  );
  const canAddOrders = canDo(
    `${STANDARD_MODULE_IDS.settings.id}_manage_all_module_data`
  );

  return (
    <>
      {/* <StatusBar /> */}

      <Div width={1} display="row">
        <Div display="column">
          <QuickActions
            showAddAccountModal={
              canUserAddAccounts ? handlers.showAddAccountModal : undefined
            }
            showAddContactModal={
              canUserAddContacts ? handlers.showAddContactModal : undefined
            }
            showCreateOrderModal={
              canAddOrders ? handlers.showCreateOrderModal : undefined
            }
            showAddFormModal={handlers.showAddFormModal}
            showAddEventTeamUserModal={
              canInviteUsers ? handlers.showAddEventTeamUserModal : undefined
            }
            showSubmitFormModal={handlers.showSubmitFormModal}
          />
          <Groups
            showAddAccountModal={
              canUserAddAccounts ? handlers.showAddAccountModal : undefined
            }
            showAddContactModal={
              canUserAddContacts ? handlers.showAddContactModal : undefined
            }
            goToAccountSettings={
              canUserManageAccounts ? goToAccountSettings : undefined
            }
            goToContactSettings={
              canUserManageContacts ? goToContactSettings : undefined
            }
          />
        </Div>
        <Div display="column" flex={1} ml={4}>
          {/* <ApprovalsSetupChecklists /> */}
          {/* <TasksWidget /> */}
          <MyApprovals />
        </Div>
      </Div>
    </>
  );
};

export default Main;
