import { REQUEST, RECEIVE, INVALIDATE } from "./constants";
import * as selectors from "./selectors";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getContact({ contactId, eventId }) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return null;
    try {
      dispatch({
        type: REQUEST,
        payload: {
          contactId
        }
      });
      const result = await api.get(getState().user.user.credentials, {
        contactId,
        eventId
      });
      dispatch({
        type: RECEIVE,
        payload: {
          contactId,
          profile: result.contact
        }
      });
      return result.contact;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting contact"
          }
        ])
      );
      return null;
    }
  };
}

export function invalidateContact(recordId) {
  return {
    type: INVALIDATE,
    payload: {
      recordId
    }
  };
}
