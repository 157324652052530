import React from "react";
import Header from "./Header";
import Body from "./Body";
import ActivityDetailSidebar from "Schedules/ActivityDetailsSidebar/View";
import PageTitle from "OrgLight/Common/PageTitle";
import Loading from "ui-kit/Loading";

import { connect } from "react-redux";
import { getters } from "../model";

const decorate = connect(
  (state, props) => ({
    loading: getters.loading(state, props)
  }),
  {}
);

const Layout = ({ loading }) => (
  <>
    <PageTitle titles={["Dashboard"]} />
    {loading ? <Loading /> : <Body />}
    <ActivityDetailSidebar />
  </>
);

export default decorate(Layout);
