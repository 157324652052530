import {
  // calculatedNumber,
  catalogItem,
  // catering,
  checkbox,
  countries,
  // credentials,
  currency,
  date,
  datetime,
  dropdown,
  email,
  eventDays,
  file,
  lookup,
  reference,
  number,
  percent,
  phone,
  text,
  textarea,
  time,
  user,
  signature
} from "utils/value-types/schema";

export default [
  // @NOTE: Commenting out until we have better handling in place
  // calculatedNumber
  catalogItem,
  checkbox,
  countries,
  currency,
  date,
  datetime,
  dropdown,
  email,
  eventDays,
  file,
  lookup,
  number,
  percent,
  phone,
  reference,
  text,
  textarea,
  time,
  user,
  signature
];
