import React, { Component } from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import {
  ButtonGroup,
  Submit,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";
import FormElements from "components/Global/Modal/Layout/FormElements";
import { addS, withProps } from "utils/General";
import AccountsApi from "redux/modules/accounts/list/api";
import debounce from "debounce-promise";
import * as R from "ramda";
import STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import STANDARD_MODULE_FIELDS from "utils/standard-module-field-ids";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { AsyncCreatableSelect } from "components/Base";

const { Label, InputGroup, Input } = FormElements;

const RecordFinder = withProps({
  clearable: true
})(AsyncCreatableSelect);

const formatNewRecordName = (moduleId, record) => {
  return R.path(["values", STANDARD_MODULE_FIELDS.ACCOUNTS.NAME, "value"])(
    record
  );
};

const formatLookupRecord = record => ({
  id: record.id,
  name: record.name,
  type: record.type ? record.type.name : null,
  value: record.id,
  label: record.name || ""
});

class AssignContactsToAccountModal extends Component {
  constructor(props) {
    super(props);

    const wait = 500; // milliseconds

    const onSearchAccounts = inputValue => this.onSearchAccounts(inputValue);
    this.debouncedOnSearchAccounts = debounce(onSearchAccounts, wait, {
      leading: false
    });

    this.state = {
      saving: false,
      account: null,
      isPrimaryContact: false,
      role: ""
    };
  }

  onSearchAccounts = async inputValue => {
    const result = await AccountsApi.search(
      this.props.orgId,
      this.props.eventId,
      inputValue,
      this.props.userCredentials
    );

    return R.compose(
      R.sortBy(R.prop("label")),
      R.map(formatLookupRecord)
    )(result.accounts);
  };

  showAddRecordModal = (
    moduleId,
    values,
    stateValue,
    statePropertyToUpdate
  ) => {
    this.props.showModal({
      content: (
        <AddRecordModal
          moduleId={moduleId}
          onSave={createdRecord => {
            if (createdRecord) {
              this.setState({
                [statePropertyToUpdate]: formatLookupRecord({
                  id: createdRecord.id,
                  name: formatNewRecordName(moduleId, createdRecord),
                  type: createdRecord.type.name
                })
              });
            }
          }}
          values={values}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  onCreateAccount = account =>
    this.showAddRecordModal(
      STANDARD_MODULES.accounts.id,
      {
        [STANDARD_MODULE_FIELDS.ACCOUNTS.NAME]: {
          type: "text",
          value: account
        }
      },
      account,
      "account"
    );

  onChangeAccount = account => this.setState({ account });

  update = async e => {
    if (e) e.preventDefault();

    if (this.state.saving) {
      return false;
    }

    if (this.validateForm()) {
      this.setState({ saving: true });
      await this.props.addRelationship({
        orgId: this.props.orgId,
        eventId: this.props.eventId,
        relationships: this.props.contactIds.map(id => ({
          accountId: this.state.account.id,
          contactId: id,
          isPrimary: this.state.isPrimaryContact,
          role: this.state.role
        }))
      });
    }

    if (this.props.onDone) {
      this.props.onDone();
    }

    return this.props.hideModal();
  };

  validateForm = () => {
    return this.state.account;
  };

  handleCheckboxChange = () => {
    this.setState({
      isPrimaryContact: !this.state.isPrimaryContact
    });
  };

  handleRoleChange = e => {
    this.setState({
      role: e.target.value
    });
  };

  render() {
    return (
      <StyleWrapper
        hideModal={this.props.hideModal}
        heading="Assign Account"
        width={410}
      >
        <form onSubmit={this.update}>
          <InputGroup>
            <Label>
              Select an account to assign to {this.props.contactIds.length}{" "}
              contact
              {addS(this.props.contactIds.length)}
            </Label>
            <RecordFinder
              placeholder="Find or create a group..."
              loadOptions={this.debouncedOnSearchAccounts}
              onChange={this.onChangeAccount}
              onCreate={this.onCreateAccount}
              value={this.state.account}
              clearable
            />
          </InputGroup>
          <InputGroup>
            <Label>Role</Label>
            <Input
              onChange={this.handleRoleChange}
              type="text"
              value={this.state.role}
            />
          </InputGroup>
          <InputGroup>
            <Label>
              Mark as primary contact
              {addS(this.props.contactIds.length)}
            </Label>
            <input
              type="checkbox"
              onChange={this.handleCheckboxChange}
              checked={this.state.isPrimaryContact}
            />
          </InputGroup>
          <ButtonGroup>
            <Submit
              title="Assign"
              disabled={this.state.saving || !this.validateForm()}
            />
            <ButtonOutline title="Cancel" onClick={this.props.hideModal} />
          </ButtonGroup>
        </form>
      </StyleWrapper>
    );
  }
}

export default AssignContactsToAccountModal;
