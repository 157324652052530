import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FormBackground extends Component {
  render() {
    const {
      showFilePicker = () => {},
      backgroundUrl,
      style,
      shadow
    } = this.props;
    return (
      <div
        styleName="formTop"
        style={{
          ...style,
          backgroundImage: backgroundUrl ? `url("${backgroundUrl}")` : undefined
        }}
      >
        {this.props.isEditing && (
          <div styleName="uploadPhoto" onClick={showFilePicker}>
            <i className="material-icons" styleName="uploadPhotoIcon">
              add_a_photo
            </i>
            <span>Add a cover image</span>
          </div>
        )}
        {shadow ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              background:
                "linear-gradient(0deg, rgba(0, 0, 0, 0.48) 0%, rgba(0,0,0,0) 42%)"
            }}
          />
        ) : null}
      </div>
    );
  }
}

FormBackground.propTypes = {
  isEditing: PropTypes.bool,
  showFilePicker: PropTypes.func,
  backgroundUrl: PropTypes.string
};

export default FormBackground;
