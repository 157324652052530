import PropTypes from "prop-types";
import React from "react";
import Editor from "../../Editors/Reference";

const Reference = ({ onChange, value, options, eventId }) => (
  <Editor
    eventId={eventId}
    onChange={onChange}
    options={options}
    value={value}
  />
);

Reference.propTypes = {
  eventId: PropTypes.string.isRequired,
  value: PropTypes.object,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Reference;
