import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import styles from "./styles.scss";

const TextButton = props => (
  <button {...props} styleName="container">
    {props.children}
  </button>
);

TextButton.propTypes = {
  children: PropTypes.node,
  styles: PropTypes.object,
  className: PropTypes.string
};

export default CSSModules(TextButton, styles, {
  allowMultiple: true
});
