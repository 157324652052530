import React from "react";
import {
  Div,
  MediumFilledButton,
  PopoverMenu,
  DownFilledIcon,
  CloseIcon,
  SmallCheckbox
} from "components/Base";
import { getters, actions } from "../model";
import { connect } from "react-redux";
import { FILTER_BY_TYPE } from "../constants";
import { bindInstance } from "redux-mvc";
import * as R from "ramda";

const decorate = connect(
  (state, props) => ({
    selectedTypeFilters: getters.selectedTypeFilters(state, props)
  }),
  bindInstance({
    selectFilterType: actions.selectFilterType
  })
);

export const TypeFilter = ({
  selectedTypeFilters,
  selectFilterType,
  ...styleProps
}) => {
  return (
    <Div
      height={55}
      bg="#f7f6f8"
      width={1}
      display="row.flex-start.center"
      style={{
        flexShrink: 0
      }}
      {...styleProps}
    >
      <PopoverMenu
        Label={({ onClick }) => (
          <MediumFilledButton
            RightIcon={DownFilledIcon}
            pill
            bg="#ffffff"
            color="gray8"
            onClick={onClick}
          >
            Types
          </MediumFilledButton>
        )}
        menuItems={R.compose(
          R.map(([key, text]) => [
            text,
            () => selectFilterType(key),
            <Div display="row.flex-start.center">
              <SmallCheckbox
                style={{
                  marginRight: 5,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                selected={selectedTypeFilters.includes(key)}
              />
              {text}
            </Div>
          ]),
          R.toPairs
        )(FILTER_BY_TYPE)}
      />
      <Div>
        {R.map(
          value => (
            <MediumFilledButton
              RightIcon={CloseIcon}
              key={value}
              pill
              bg="#f1eff3"
              color="gray8"
              onClick={() => selectFilterType(value)}
              ml={2}
            >
              {FILTER_BY_TYPE[value]}
            </MediumFilledButton>
          ),
          selectedTypeFilters
        )}
      </Div>
    </Div>
  );
};

export default decorate(TypeFilter);
