import Helpers from "utils/Global/Helpers";
import getQuery from "redux/modules/modules/utils/get-query";

/* eslint no-underscore-dangle: "off" */

export default {
  getFields: ({ credentials, orgId, options }) =>
    new Promise((success, error) => {
      const query = getQuery(options);

      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/organizations/${orgId}/events-list/fields${query || ""}`,
        credentials,
        success,
        error
      });
    }),
  search: (credentials, orgId, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/organizations/${orgId}/events-list`,
        qs: data,
        credentials,
        success,
        error
      });
    }),
  export: ({ credentials, orgId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/organizations/${orgId}/events-list/export`,
        qs: data,
        credentials,
        success,
        error
      });
    }),
  getViews: ({ credentials, orgId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/organizations/${orgId}/events-list/views`,
        data,
        credentials,
        success,
        error
      });
    }),
  addView: ({ credentials, orgId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/organizations/${orgId}/events-list/views`,
        data,
        credentials,
        success,
        error
      });
    }),
  updateView: ({ credentials, orgId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/organizations/${orgId}/events-list/view`,
        data,
        credentials,
        success,
        error
      });
    }),
  deleteView: ({ credentials, orgId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/organizations/${orgId}/events-list/view`,
        data,
        credentials,
        success,
        error
      });
    }),
  selectView: ({ credentials, orgId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/organizations/${orgId}/events-list/active-view`,
        data,
        credentials,
        success,
        error
      });
    }),
  getActiveView: ({ credentials, orgId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/organizations/${orgId}/events-list/active-view`,
        data,
        credentials,
        success,
        error
      });
    }),

  postSubmission: ({ credentials, data }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/form/form/${data.formId}/submissions`,
        credentials,
        data,
        success,
        error
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  deleteEvents: ({ credentials, data }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */

      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/organizations/${data.orgId}/events`,
        credentials,
        data,
        success,
        error
      });
    }),

  addEvent: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/event/organization/${data.orgId}/events`,
        credentials,
        data,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
