import { createModule } from "redux-mvc";

import model from "./model";

import sagasModule from "./sagas";

import formModule from "ui-kit/Form";

const module = createModule({
  ...model,
  observedDomains: ["user", "event", "permissions", "organization"]
});

module.setRootSaga(sagasModule);
module.plugModule(formModule);

export default module;
