import React, { Component } from "react";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import Body from "./Body";

import * as R from "ramda";
import { difference, find, map, prop, propEq } from "ramda";

import RecordTypeModal from "components/Event/Settings/Module/Modals/RecordType";
import DeleteRecordTypeModal from "components/Event/Settings/Module/Modals/DeleteRecordType";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

class External extends Component {
  componentDidMount() {
    this.getModuleRecordTypes();
  }

  componentDidUpdate(prevProps) {
    const { selectedRecordType, recordTypes } = this.props;

    // set active pset to newest when adding one
    if (
      selectedRecordType &&
      recordTypes.length < prevProps.recordTypes.length
    ) {
      const mapId = map(prop("id"));

      const selected = find(
        propEq(
          "id",
          difference(mapId(recordTypes), mapId(prevProps.recordTypes))[0]
        )
      )(this.props.recordTypes);

      this.setActiveRecordType(selected ? selected.id : null);
    }
  }

  getModuleRecordTypes() {
    this.props.getRecordTypes({
      moduleId: STANDARD_MODULE_IDS.accounts.id,
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId
      }
    });

    this.props.getRecordTypes({
      moduleId: STANDARD_MODULE_IDS.contacts.id,
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId
      }
    });
  }

  showRecordTypeModal = recordType => {
    this.props.showModal({
      content: (
        <RecordTypeModal
          onSave={
            recordType
              ? this.handleUpdateRecordType
              : this.handleCreateRecordType
          }
          recordType={recordType}
          moduleId={this.props.moduleId}
          recordName={this.props.module.record_name}
          eventId={this.props.eventId}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  handleCreateRecordType = async data => {
    const { layout } = await this.props.addLayout({
      moduleId: this.props.moduleId,
      layout: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId
      }
    });

    await this.props.addRecordType({
      moduleId: this.props.moduleId,
      recordType: {
        ...data,
        layoutId: layout.id
      },
      options: {
        eventId: this.props.eventId
      }
    });

    this.props.showSnackbar({ message: "Type added", action: "OK" });
    return this.getModuleRecordTypes();
  };

  handleUpdateRecordType = data => {
    this.props
      .updateRecordType({
        moduleId: this.props.moduleId,
        recordType: data,
        options: {
          eventId: this.props.eventId
        }
      })
      .then(() => {
        this.getModuleRecordTypes();
        this.props.showSnackbar({ message: "Type updated", action: "OK" });
      });
  };

  showDeleteRecordTypeModal = recordType => {
    this.props.showModal({
      content: (
        <DeleteRecordTypeModal
          onSave={this.handleDeleteRecordType}
          recordType={recordType}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  handleDeleteRecordType = data => {
    this.props
      .deleteRecordType({
        moduleId: this.props.moduleId,
        recordType: data,
        options: {
          orgId: this.props.orgId,
          eventId: this.props.eventId
        }
      })
      .then(() => {
        this.getModuleRecordTypes();
        this.props.showSnackbar({ message: "Type deleted", action: "OK" });
      });
  };

  handleUpdateDefaultRecordType = recordType => {
    this.props
      .updateDefaultRecordType({
        moduleId: this.props.moduleId,
        recordType,
        options: {
          orgId: this.props.orgId,
          eventId: this.props.eventId
        }
      })
      .then(() => {
        this.getModuleRecordTypes();
        this.props.showSnackbar({
          message: "Type marked as default",
          action: "OK"
        });
      });
  };

  setActiveRecordType = typeId => {
    this.props.router.push({
      pathname: typeId
        ? `/event-light/${this.props.params.eventId}/crm/settings/${this.props.params.moduleId}/types/${typeId}`
        : `/event-light/${this.props.params.eventId}/crm/settings/${this.props.params.moduleId}/types`
    });
  };

  setActiveRecordTypeSection = sufPath => {
    const pathname = `/event-light/${this.props.params.eventId}/crm/settings/${this.props.params.moduleId}/types/${this.props.selectedRecordType.id}/${sufPath}`;
    this.props.router.push({ pathname });
  };

  render() {
    const {
      recordTypes,
      selectedRecordType,
      routes,
      moduleId,
      isConferenceTemplate,
      canViewNewPortal
    } = this.props;

    const {
      showRecordTypeModal,
      showDeleteRecordTypeModal,
      handleUpdateDefaultRecordType,
      setActiveRecordType,
      setActiveRecordTypeSection
    } = this;

    let tabs = [];

    if (
      [
        STANDARD_MODULE_IDS.accounts.id,
        STANDARD_MODULE_IDS.contacts.id
      ].includes(moduleId)
    ) {
      tabs.push({
        routeName: "EventLightCRMSettingsTypesPortal",
        tabName: "Portal",
        onClick: () => setActiveRecordTypeSection("portal")
      });
    }

    tabs.push({
      routeName: "EventLightCRMSettingsTypesLayout",
      tabName: "Record Layout",
      onClick: () => setActiveRecordTypeSection("layout")
    });

    if (
      !isConferenceTemplate &&
      moduleId === STANDARD_MODULE_IDS.accounts.id &&
      !canViewNewPortal
    ) {
      tabs.push({
        routeName: "EventLightCRMSettingsTypesGuestList",
        tabName: "Guest List",
        onClick: () => setActiveRecordTypeSection("guest-list")
      });
    }

    tabs.push({
      routeName: "EventLightCRMSettingsTypesAddRecordLayout",
      tabName: "Add Record Layout",
      onClick: () => setActiveRecordTypeSection("add-record-layout")
    });

    /*
      // @TODO
      {
        routeName: "EventLightCRMSettingsTypesCardLayout",
        tabName: "Card Layout",
        onClick: () => setActiveRecordTypeSection("card-layout")
      },
      {
        routeName: "EventLightCRMSettingsTypesReportLayout",
        tabName: "Report Layout",
        onClick: () => setActiveRecordTypeSection("report-layout")
      }
    */

    tabs = R.map(t => ({
      ...t,
      isActive: R.any(
        R.compose(
          R.equals(t.routeName),
          R.prop("name")
        )
      )(routes)
    }))(tabs);

    if (
      R.compose(
        R.not,
        R.length,
        R.filter(
          R.compose(
            R.equals(true),
            R.prop("isActive")
          )
        )
      )(tabs)
    ) {
      tabs = R.set(R.lensPath([0, "isActive"]), true)(tabs);
    }

    const recordTypesWithHandlers = R.map(type => {
      return {
        id: type.id,
        name: type.name,
        icon: type.icon,
        color: type.background_color,
        isDefault: type.is_default,
        isActive: selectedRecordType
          ? selectedRecordType.id === type.id
          : false,
        goToType: () => setActiveRecordType(type.id),
        showEditTypeModal: () => showRecordTypeModal(type)
      };
    })(recordTypes);

    const selectedType = selectedRecordType
      ? {
          id: selectedRecordType.id,
          color: selectedRecordType.background_color,
          icon: selectedRecordType.icon,
          name: selectedRecordType.name,
          isDefault: selectedRecordType.is_default,
          permissionSetId: selectedRecordType.permission_set_id,
          layoutId: selectedRecordType.layout_id,
          menuItems: [
            isConferenceTemplate
              ? null
              : ["Edit", () => showRecordTypeModal(selectedRecordType)],
            [
              "Set as default",
              () => handleUpdateDefaultRecordType(selectedRecordType)
            ],
            isConferenceTemplate
              ? null
              : ["Delete", () => showDeleteRecordTypeModal(selectedRecordType)]
          ].filter(o => o)
        }
      : {};

    return (
      <Body
        {...{
          moduleId,
          tabs,
          typeIsSelected: Boolean(selectedRecordType),
          types: recordTypesWithHandlers,
          selectedType,
          canViewNewPortal: this.props.canViewNewPortal,
          isConferenceTemplate,
          activeRecordTypeSection: R.compose(
            R.prop("routeName"),
            R.find(
              R.compose(
                R.equals(true),
                R.prop("isActive")
              )
            )
          )(tabs),
          showAddTypeModal: () => showRecordTypeModal()
        }}
      />
    );
  }
}

export default External;
