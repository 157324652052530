import {
  text,
  textarea,
  number,
  dropdown,
  date,
  datetime,
  time,
  checkbox,
  file,
  phone,
  countries,
  credentials,
  inventory,
  eventDays,
  catering,
  cateringDietaryRestrictions,
  contact,
  scheduleActivity,
  reference,
  percent,
  currency,
  calculatedNumber,
  dependency
} from "utils/value-types/schema";

export default [
  text,
  textarea,
  number,
  dropdown,
  date,
  datetime,
  time,
  checkbox,
  file,
  phone,
  countries,
  credentials,
  inventory,
  eventDays,
  catering,
  cateringDietaryRestrictions,
  contact,
  scheduleActivity,
  reference,
  percent,
  currency,
  calculatedNumber,
  dependency
];
