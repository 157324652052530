import React, { Component } from "react";
import View from "./View";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import FormWizardModal from "Forms/WizardModal/View";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { showModal } from "redux/modules/modal/actions";
import { getResources } from "redux/modules/marketing/resources/actions";
import { resources } from "redux/modules/marketing/resources/selectors";

class Controller extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: props.resources && props.resources.images ? false : true
    };
  }

  async componentDidMount() {
    if (this.state.loading) {
      await this.props.getResources(this.props.resourceType);

      return this.setState({
        loading: false
      });
    }
    return true;
  }

  showTemplatesModal = () => {
    this.props.showModal({
      content: <FormWizardModal />,
      wrapper: ModalWrapper
    });
  };

  render() {
    return (
      <View
        {...{
          loading: this.state.loading,
          resources: this.props.resources.images || [],
          showTemplatesModal: this.showTemplatesModal
        }}
      />
    );
  }
}

function mapStateToProps(state) {
  const resourceType = "application-overview";

  return {
    resourceType,
    resources: resources(state, resourceType)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getResources,
      showModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controller);
