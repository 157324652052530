import React from "react";
import { Div, DonutIcon } from "../index";

const DonutWithFilling = ({
  size = 200,
  value,
  defaultColor,
  valueColor,
  strokeRatio = 1 / 10,
  children
}) => (
  <Div maxWidth={size} maxHeight={size}>
    <Div style={{ position: "relative" }}>
      <Div
        style={{
          position: "absolute",
          left: 0,
          right: 0,
          top: "50%",
          transform: "translateY(-50%)",
          textAlign: "center"
        }}
      >
        {children}
      </Div>
      <DonutIcon
        size={size}
        value={value}
        defaultColor={defaultColor}
        valueColor={valueColor}
        strokeRatio={strokeRatio}
      />
    </Div>
  </Div>
);

export { DonutWithFilling };
