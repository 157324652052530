import React from "react";
import SubNav from "./SubNav";
import * as R from "ramda";
import { connect } from "react-redux";
import { TAB_IDS } from "./constants";
import { withRouter } from "react-router";
import PageTitle from "EventLight/Common/PageTitle";
import { getTabs, getCurrentRoute } from "./selectors";

import Dashboard from "./Dashboard/View";
import Requests from "./Requests/View";
import Catalog from "./Catalog/View";
import Approvers from "./Approvers/View";
import Questions from "./Questions/View";
import SendEmails from "EventLight/Common/SendEmail/View";

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    currentRoute: getCurrentRoute(state, props),
    tabs: getTabs(state, props)
  }))
);

const Wrapper = ({ currentRoute, tabs }) => {
  const Page = R.prop(currentRoute)({
    [TAB_IDS.DASHBOARD]: Dashboard,
    [TAB_IDS.REQUESTS]: Requests,
    [TAB_IDS.CATALOG]: Catalog,
    [TAB_IDS.APPROVERS]: Approvers,
    [TAB_IDS.QUESTIONS]: Questions,
    [TAB_IDS.SEND_EMAILS]: SendEmails
  });

  return (
    <>
      <PageTitle titles={["Assets"]} />
      <SubNav tabs={tabs} selectedView={currentRoute} />
      <Page />
    </>
  );
};

export default decorate(Wrapper);
