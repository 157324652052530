import MoreInfoModal from "components/Global/CRM/Modals/MoreInfoModal";
import { account, accountName } from "redux/modules/accounts/profile/selectors";
import { connect } from "react-redux";
import * as EventSelectors from "redux/modules/event/selectors";

const mapStateToProps = state => ({
  data: account(state),
  name: accountName(state),
  eventDetails: EventSelectors.eventDetails(state)
});

export default connect(mapStateToProps)(MoreInfoModal);
