import React from "react";
import { Div } from "components/Base";

import MainSection from "./MainSection";
import FooterSections from "./FooterSections";
import ActiveAlerts from "./ActiveAlerts";

export const Body = () => (
  <Div display="column.flex-start.stretch">
    <ActiveAlerts />
    <MainSection />
    <FooterSections />
  </Div>
);

export default Body;
