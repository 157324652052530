import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";

class DeleteItemModal extends Component {
  @autobind
  onClick() {
    const { done, hideModal } = this.props;
    done();
    hideModal();
  }

  render() {
    const { hideModal, name } = this.props;
    return (
      <div>
        <div className="modal-header">
          <div className="modal-title">Delete {name}?</div>
        </div>

        <div className="modal-body">
          <div className="modal-body-wrapper">
            <div>Are you sure you want to delete this {name}?</div>
            <br />
            <div className="actions-wrapper">
              <a className="action-cancel small clear" onClick={hideModal}>
                Cancel
              </a>
              <span className="action-positive small" onClick={this.onClick}>
                Delete
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DeleteItemModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  done: PropTypes.func.isRequired
};

export default DeleteItemModal;
