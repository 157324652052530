import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Panel from "components/Global-2016/Panel";
import Entry from "./FieldEntry";
import getValue from "utils/value-types/get-value";
import getMetaData from "utils/value-types/get-meta-data";

const SwimlaneCard = ({
  fields,
  onClick,
  primaryField,
  readOnly,
  references,
  row,
  getRowMetaData
}) => {
  const title = primaryField
    ? getValue(row.values[primaryField.id], primaryField.type)
    : row.id.slice(0, 4);
  return (
    <Panel
      onClick={() => onClick(row.id)}
      style={readOnly ? css.panel : css.draggablePanel}
    >
      <div styleName="container">
        <div styleName="header">
          <div styleName="submitter" title={title}>
            {title}
          </div>
        </div>
        <div styleName="fields">
          {fields.map(field => (
            <Entry
              key={field.id}
              label={field.name}
              field={field}
              value={row.values[field.id]}
              meta={getRowMetaData(row, field)}
            />
          ))}
        </div>
      </div>
    </Panel>
  );
};

SwimlaneCard.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired,
  primaryField: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string
  }).isRequired,
  readOnly: PropTypes.bool.isRequired,
  references: PropTypes.object.isRequired,
  row: PropTypes.shape({
    id: PropTypes.string,
    values: PropTypes.object
  }).isRequired
};

export default CSSModules(SwimlaneCard, css);
