import React, { Component } from "react";
import * as R from "ramda";
import { createSelector } from "reselect";

import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";

import { getItemTypesByEvent } from "redux/modules/items/types/actions";
import { getSourcesByItemType } from "redux/modules/orders/sources/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";
import { getForms } from "redux/modules/formsV2/forms/actions";
import { toggleMenu, landed } from "redux/modules/passes/actions";
import { getTicketingProviderConfigurations } from "redux/modules/ticketing/providers/actions";
import { getItemGroupsByEventAndType } from "redux/modules/items/item-groups/actions";

import { eventDetails } from "redux/modules/event/selectors";
import { user } from "redux/modules/user/selectors";
import { sourcesByTypeId } from "redux/modules/orders/sources/selectors";
import { recordType } from "redux/modules/modules/recordTypes/selectors";
import { applications } from "redux/modules/applications/selectors";
import { recordTypes } from "redux/modules/modules/recordTypes/selectors";
import { canUserDo } from "redux/modules/permissions/user-permission-profile/selectors";
import { getOpenedMenues } from "redux/modules/passes/selectors";
import {
  ticketingProviderConfigurations,
  fetchedConfigurations
} from "redux/modules/ticketing/providers/selectors";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";
import { getGroupsByType } from "redux/modules/items/item-groups/selectors";

import {
  ORDER_LINE_ITEMS_REPORT_ID,
  CREDENTIALS_BY_GROUP_REPORT_ID,
  GUEST_LIST_REPORT_ID
} from "components/Event/Reports/utils/constants";

import { MANAGE_BY } from "Items/Manage/constants";

import FormWizardModal from "Forms/WizardModal/View";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

//create order Modal
import CreateOrderModal from "Orders/CreateOrderModal";
import ImportOrdersModal from "Orders/ImportModal/View";

import Layout from "./Layout";

const getUrl = createSelector(
  R.path(["eventDetails", "id"]),
  eventId => (view, base = "/passes") => `/event/${eventId}${base}${view}`
);

const getLinks = createSelector(
  R.prop("integrations"),
  R.prop("groupRecordTypes"),
  R.prop("eventDetails"),
  R.prop("cando"),
  getUrl,
  R.prop("showCreateOrderModal"),
  R.prop("showImportOrdersModal"),
  R.prop("categories"),
  R.prop("canViewPrinting"),
  (
    integrations,
    groupRecordTypes,
    eventDetails,
    cando,
    getUrl,
    showCreateOrderModal,
    showImportOrdersModal,
    categories,
    canViewPrinting
  ) => {
    let LINKS = [
      {
        id: "dashboard",
        to: getUrl("/dashboard"),
        name: "Dashboard",
        activeRoutes: ["PassesDashboard"]
      },
      {
        id: "myApprovals",
        to: getUrl("/manage/my-approvals"),
        name: "My Approvals",
        activeRoutes: ["PassesManageBy"],
        activeParams: { viewId: "my-approvals" }
      },
      {
        id: "dashboardDivider",
        divide: true
      },
      {
        id: "allRequests",
        to: getUrl("/manage/all-requests"),
        name: "All Requests",
        activeRoutes: ["PassesManageBy"],
        activeParams: { viewId: "all-requests", categoryId: null }
      },
      cando(`${STANDARD_MODULES.credentials.id}_checkin`)
        ? {
            id: "issue",
            to: getUrl("/manage/fulfill"),
            name: "Issue & Check In",
            activeRoutes: ["PassesManageBy"],
            activeParams: { viewId: "fulfill" }
          }
        : null,
      canViewPrinting && cando(`${STANDARD_MODULES.credentials.id}_checkin`)
        ? {
            id: "issue",
            to: getUrl("/manage/print"),
            name: "Print",
            activeRoutes: ["PassesManageBy"],
            activeParams: { viewId: "print" }
          }
        : null,
      integrations && integrations.length
        ? {
            id: MANAGE_BY.INTEGRATION,
            to: getUrl("/manage/integration-orders"),
            name: "Integration Orders",
            activeRoutes: ["PassesManageBy"],
            activeParams: { viewId: MANAGE_BY.INTEGRATION }
          }
        : null,
      R.length(categories)
        ? {
            id: "inventoryDivider",
            divide: true
          }
        : null,
      R.length(categories)
        ? {
            id: "categories",
            to: getUrl("/manage/fulfill"),
            name: "Categories",
            links: R.map(
              category => ({
                id: category.id,
                name: category.name,
                activeRoutes: ["PassesManageBy"],
                activeParams: { categoryId: category.id },
                to: getUrl(`/manage/all-requests/category/${category.id}`)
              }),
              categories
            )
          }
        : null
      // @NOTE: remove checkin link
    ];

    // Allocations
    if (groupRecordTypes.length) {
      LINKS = [
        ...LINKS,
        {
          id: "allocationDivider",
          divide: true
        },
        {
          id: "PassesGuestList",
          name: "Allocations",
          activeRoutes: ["PassesGuestList"],
          links: R.compose(
            R.sortBy(
              R.compose(
                R.toLower,
                R.prop("name")
              )
            ),
            R.map(l => ({
              id: l.id,
              to: getUrl(`/guest-list/${l.id}`),
              activeRoutes: ["PassesGuestList"],
              activeParams: { recordTypeId: l.id },
              name: l.name
            })),
            R.filter(l => l.guest_list_enabled !== false)
          )(groupRecordTypes)
        },
        {
          id: "allocationDivider2",
          divide: true
        }
      ];
    }

    LINKS = [
      ...LINKS,
      // @NOTE: remove inventory links
      {
        id: "subMenu",
        links: [
          {
            id: "create-order",
            name: "Create Order",
            onClick: () => showCreateOrderModal()
          },
          cando(`${STANDARD_MODULES.credentials.id}_add_line_items`)
            ? {
                id: "import-order",
                name: "Import Orders",
                onClick: () => showImportOrdersModal()
              }
            : null,
          {
            id: "confirm-emails",
            name: "Send Emails",
            to: getUrl("/confirm"),
            activeRoutes: ["PassesConfirmation"]
          }
        ]
      },
      {
        id: "reports",
        name: "Reports",
        links: [
          {
            id: "report-items",
            to: getUrl(ORDER_LINE_ITEMS_REPORT_ID, "/reports/"),
            name: "Passes & Meals by Person"
          },
          {
            id: "report-groups",
            to: getUrl(CREDENTIALS_BY_GROUP_REPORT_ID, "/reports/"),
            name: "Passes by Group"
          },
          {
            id: "report-groups2",
            to: getUrl(GUEST_LIST_REPORT_ID, "/reports/"),
            name: "Guest List Report"
          }
        ]
      }
    ].filter(
      R.compose(
        R.not,
        R.either(R.isEmpty, R.isNil)
      )
    );

    return LINKS;
  }
);

const getSetupLink = createSelector(
  R.prop("cando"),
  getUrl,
  (cando, getUrl) => {
    return {
      id: "setupLinks",
      links: cando(`${STANDARD_MODULES.credentials.id}_manage`)
        ? [
            {
              id: "setup-overview",
              name: "Setup Overview",
              activeRoutes: ["PassesSetupOverview"],
              to: getUrl("/setup")
            },
            {
              id: "manage-pass-types",
              name: "Manage Pass Types",
              activeRoutes: ["PassesSetupTypes"],
              to: getUrl(
                `/settings/catalog/type/${CREDENTIAL_TYPE_ID}/items`,
                ""
              )
            },
            {
              id: "manage-forms",
              name: "Manage Forms",
              to: getUrl("/forms-v2", "")
            },
            {
              id: "manage-approvers",
              name: "Manage Approvers",
              activeRoutes: ["PassesSetupApprovers"],
              to: getUrl(
                `/settings/catalog/type/${CREDENTIAL_TYPE_ID}/approvers`,
                ""
              )
            }
          ]
        : []
    };
  }
);

const getSources = () => getSourcesByItemType(CREDENTIAL_TYPE_ID);

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      eventDetails: eventDetails(state),
      groupRecordTypes: recordTypes(state, STANDARD_MODULES.accounts.id),
      user: user(state),
      sources: sourcesByTypeId(state, CREDENTIAL_TYPE_ID),
      applications: applications(state),
      cando: canUserDo(state, props.params.orgId || props.params.eventId),
      integrations: ticketingProviderConfigurations(state),
      fetchedConfigurations: fetchedConfigurations(state),
      isLenndAdmin: selectFeatureFlag(flags.IS_LENND_ADMIN.NAME)(state),
      canViewPrinting: selectFeatureFlag(flags.CAN_VIEW_PRINTING.NAME)(state),
      getGuestListName: recordTypeId =>
        R.pathOr(
          "",
          ["name"],
          recordType(state, STANDARD_MODULES.accounts.id, recordTypeId)
        ),
      openedMenues: getOpenedMenues(state),
      categories: getGroupsByType(state, {
        ...props,
        type: CREDENTIAL_TYPE_ID
      })
    }),
    {
      getForms,
      getRecordTypes,
      showModal,
      hideModal,
      showSnackbar,
      getItemTypesByEvent,
      fetchSources: getSources,
      toggleMenu,
      landed,
      getItemGroupsByEventAndType,
      getTicketingProviderConfigurations
    }
  )
);

class Controller extends Component {
  componentDidMount() {
    // get: account record types
    if (!this.props.groupRecordTypes.length) {
      this.props.getRecordTypes({
        moduleId: STANDARD_MODULES.accounts.id,
        options: {
          eventId: this.props.params.eventId
        }
      });
    }

    // get: integrations
    if (!this.props.fetchedConfigurations) {
      this.props.getTicketingProviderConfigurations(this.props.params.eventId);
    }

    // get: item groups
    if (!this.props.categories || !this.props.categories.length) {
      this.props.getItemGroupsByEventAndType(
        this.props.params.eventId,
        CREDENTIAL_TYPE_ID
      );
    }

    this.props.landed(R.map(R.prop("name"), this.props.routes));
  }

  isActive = (routes, params) => {
    if (R.any(r => R.any(R.equals(r.name), routes), this.props.routes)) {
      if (params) {
        return R.all(
          R.equals(true),
          R.map(([key, val]) => {
            if (val === null) {
              return !R.has(key, this.props.params);
            }
            return this.props.params[key] === val;
          }, Object.entries(params))
        );
      }
      return true;
    }
    return false;
  };

  showTemplatesModal = () => {
    this.props.showModal({
      content: <FormWizardModal />,
      wrapper: ModalWrapper
    });
  };

  showCreateOrderModal = () => {
    this.props.showModal({
      content: <CreateOrderModal hideModal={this.props.hideModal} />,
      wrapper: ModalWrapper
    });
  };

  showImportOrdersModal = () => {
    this.props.showModal({
      content: <ImportOrdersModal />,
      wrapper: ModalWrapper
    });
  };

  toggleSetup = () => {
    this.props.toggleMenu("setup");
  };

  render() {
    const {
      children,
      showSnackbar,
      showModal,
      hideModal,
      router,
      routes,
      params
    } = this.props;

    return (
      <Layout
        {...{
          children,
          showSnackbar,
          showModal,
          hideModal,
          router,
          routes,
          params,
          links: getLinks({
            ...this.props,
            showCreateOrderModal: this.showCreateOrderModal,
            showImportOrdersModal: this.showImportOrdersModal
          }),
          setupLink: getSetupLink(this.props)
        }}
      />
    );
  }
}

export default decorate(Controller);
