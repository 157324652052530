import CONTACT_FIELDS from "./constants/contact-fields";
import { CONTACT_STANDARD_FIELD_IDS } from "components/Event/Contacts/constants";

export default {
  mustBeChildField: false,
  canBeChildField: true,
  canBeParentField: true,
  canBeChildOfFieldTypes: ["account"],
  mappableFields: [].concat(
    [
      {
        id: "full_name",
        name: "Full Name",
        mappableFieldTypes: ["text"]
      },
      {
        id: CONTACT_STANDARD_FIELD_IDS.fname,
        name: "First Name",
        mappableFieldTypes: ["text"]
      },
      {
        id: CONTACT_STANDARD_FIELD_IDS.lname,
        name: "Last Name",
        mappableFieldTypes: ["text"]
      }
    ],
    CONTACT_FIELDS
  )
};
