import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";
import toString from "utils/value-types/to-string";
import getValue from "utils/value-types/get-value";
import * as SYSTEM_FIELD_IDS from "utils/system-field-ids";
import { get } from "lodash";

import { getRecords } from "redux/modules/modules/records/actions";
import { addReferences } from "redux/modules/entityReferences/actions";
import { addValue } from "redux/modules/modules/values/actions";

import { references } from "redux/modules/entityReferences/selectors";

const {
  ID,
  APPROVAL,
  OWNERS,
  SOURCE,
  CREATED_AT,
  CREATED_BY,
  LAST_SENT_AT,
  LAST_MODIFIED_AT,
  LAST_MODIFIED_BY
} = SYSTEM_FIELD_IDS;

const METAFIELD_BLACKLIST = [
  ID,
  APPROVAL,
  OWNERS,
  SOURCE,
  CREATED_AT,
  CREATED_BY,
  LAST_SENT_AT,
  LAST_MODIFIED_AT,
  LAST_MODIFIED_BY,
  "full_name",
  "accounts_list"
];

function mapStateToProps(state, props) {
  const referencesToPass = references(state);
  const fields = get(
    referencesToPass,
    [props.linkedModuleId, "fields", "fields"],
    []
  );
  const field = fields.find(f => f.id === props.linkedFieldId);
  const fieldId = field ? field.id : ID;
  const fieldType = field ? field.type : "text";
  const metaFields = fields
    .filter(f => ![...METAFIELD_BLACKLIST, fieldId].includes(f.id))
    .slice(0, 3);

  return {
    records: props.linkedRecordIds.map(recordId => {
      const record = get(
        referencesToPass,
        [props.linkedModuleId, "records", recordId],
        {}
      );

      const values = record.values || {};
      const name =
        fieldId === ID
          ? `${record.record_prefix}-${record.friendly_id}`
          : toString(getValue(values[fieldId], fieldType), fieldType) ||
            record.name;

      return {
        id: recordId,
        name,
        metaValues: metaFields.reduce((list, metaField) => {
          const value = toString(
            getValue(values[metaField.id], metaField.type),
            metaField.type
          );
          list.push({
            name: metaField.name,
            value: value && value.length ? value : null
          });
          return list;
        }, [])
      };
    })
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addValue,
      addReferences,
      getRecords
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controller);
