import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import { addS } from "utils/General";

import { noop } from "utils/General";

import { actions } from "ui-kit/Form/model";
import { getValue } from "ui-kit/Form/selectors";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import ReactTextarea from "react-textarea-autosize";
import { Custom } from "components/Base/Base";

import { WithThemeConsumer } from "ui-kit/Theme";
import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";

const decorate = R.compose(
  WithFormInstanceConsumer({ fieldType: FIELD_TYPES.VALUE }),
  WithThemeConsumer(),
  connect(
    (state, props) => ({
      value: getValue(state, props)
    }),
    bindInstance({
      setIniValue: actions.setIniValue,
      onChange: actions.setFieldValue,
      onFocus: actions.focusField,
      onBlur: actions.blurField
    })
  )
);

const TextArea = Custom(ReactTextarea);

export const TextAreaInput = decorate(
  ({
    value = "",
    iniValue,
    setIniValue = noop,
    onChange = noop,
    onFocus = noop,
    onBlur = noop,
    fieldType,
    fieldId,
    style,
    maxLength = "",
    ...props
  }) => {
    useEffect(() => {
      setIniValue(iniValue, {
        meta: { fieldType, fieldId }
      });
    }, []);
    return (
      <>
        <TextArea
          {...{
            bg: "white",
            color: "neutral7",
            fs: 3,
            mb: 2,
            p: 2,
            ba: 1,
            bc: "neutral2",
            bra: 1,
            shadow: "inset",
            maxLength,
            value,
            style,
            onChange: e =>
              onChange(e.target.value, {
                meta: { fieldType, fieldId }
              }),
            onFocus: () => onFocus(null, { meta: { fieldId, fieldType } }),
            onBlur: () => onBlur(null, { meta: { fieldId, fieldType } }),
            ...props
          }}
        />
        {!R.isEmpty(maxLength) ? (
          <div
            style={{
              fontSize: "13px",
              marginTop: "5px"
            }}
          >
            {value.length}/{maxLength} Character{addS(value.length)}
          </div>
        ) : null}
      </>
    );
  }
);
