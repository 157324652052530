import PropTypes from "prop-types";
import React, { Component, cloneElement } from "react";

class SelectedRowsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRows: []
    };
  }

  setSelectedRows = selectedRows => {
    this.setState({
      selectedRows
    });
  };

  deselectAllRows = () => {
    this.setState({
      selectedRows: []
    });
  };

  render() {
    return cloneElement(this.props.children, {
      deselectAllRows: this.deselectAllRows,
      setSelectedRows: this.setSelectedRows,
      selectedRows: this.state.selectedRows
    });
  }
}

SelectedRowsContainer.defaultProps = {
  selectedRows: []
};
SelectedRowsContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export default Child => props => (
  <SelectedRowsContainer>
    <Child {...props} />
  </SelectedRowsContainer>
);
