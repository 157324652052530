import Immutable from "immutable";
import { combineReducers } from "redux";
import { RECEIVE, REQUEST } from "./constants";
import { ERROR } from "redux/modules/errors/constants";

const report = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn([action.payload.accountId], action.payload.report)
        .toJS();
    default:
      return state;
  }
};

const fetching = (state = {}, action) => {
  switch (action.type) {
    case REQUEST:
      return Immutable.fromJS(state)
        .setIn([action.payload.accountId], true)
        .toJS();
    case ERROR:
      return {};
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn([action.payload.accountId], false)
        .toJS();
    default:
      return state;
  }
};

export default combineReducers({
  report,
  fetching
});
