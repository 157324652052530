import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import {
  Div,
  MediumInsetInput,
  Text4,
  MediumFilledButton
} from "components/Base";

@CSSModules(css)
class Empty extends Component {
  state = { adding: false, name: "", error: null };

  onFieldNameChange = name => this.setState({ name, error: null });

  toggleAdding = () =>
    this.setState({ adding: !this.state.adding, error: null });

  onCreateField = () => {
    if (!this.state.name.length) {
      return this.setState({
        error: "Please enter a field name"
      });
    }
    this.props.onCreateField({ name: this.state.name, type: "dropdown" });
  };

  render() {
    const dropdownFields = this.props.dropdownFields.map(f => ({
      ...f,
      onClick: () => this.props.selectField(f.id)
    }));

    if (this.state.adding) {
      return (
        <div styleName="addContainer">
          <div styleName="addTitle">
            <div
              styleName="back"
              onClick={this.toggleAdding}
              role="button"
              tabIndex="0"
            >
              <i className="material-icons" styleName="backIcon">
                chevron_left
              </i>
              Back
            </div>
            Create a new dropdown field:
          </div>
          <Div style={{ width: 200 }}>
            <Text4 pt={4} pb={2}>
              Field name:
            </Text4>
            <MediumInsetInput
              onChange={this.onFieldNameChange}
              value={this.state.name}
            />
            {this.state.error ? <div>{this.state.error}</div> : null}
            <MediumFilledButton
              mt={1}
              onClick={this.onCreateField}
              style={{ width: 90 }}
              primary
            >
              Save
            </MediumFilledButton>
          </Div>
        </div>
      );
    }

    return (
      <div styleName="container">
        <div styleName="title">Choose a grouping field:</div>
        <div styleName="description">
          Which dropdown field would you like to use for this kanban view? Your
          records will be stacked based on this field.
        </div>
        <div styleName="list">
          {dropdownFields.map(field => (
            <div
              styleName="field"
              onClick={field.onClick}
              role="button"
              tabIndex="0"
            >
              {field.name}
            </div>
          ))}
          <div
            styleName="addField"
            onClick={this.toggleAdding}
            role="button"
            tabIndex="0"
          >
            <i className="material-icons" styleName="addIcon">
              add
            </i>
            <div>
              <div styleName="top">Create a new dropdown field</div>
              <div styleName="bottom">
                to represent each record's status, stage, or type
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Empty;
