import React, { Component } from "react";
import getValue from "utils/value-types/get-value/countries";
import countries from "components/Global/Editors/Countries/countries";
import * as R from "ramda";

export default class CountriesFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props.value, nextProps.value);
  }

  render() {
    const countryCode = getValue(this.props.value);
    const value = countryCode ? countries[countryCode] : "";
    return <div title={value}>{value}</div>;
  }
}
