import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";
import { withRouter } from "react-router";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { ACCOUNTS, CONTACTS } from "utils/standard-module-field-ids";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";
import { CREDENTIAL_TYPE_ID, MEAL_TYPE_ID } from "utils/item-types";

const accountReadOnlyFields = resolveReadOnlyFields({
  moduleId: STANDARD_MODULE_IDS.accounts.id
});
const contactReadOnlyFields = resolveReadOnlyFields({
  moduleId: STANDARD_MODULE_IDS.contacts.id
});

const FIELD_TEMPLATES = {
  ACCOUNT_NAME: {
    id: ACCOUNTS.NAME
  },
  ACCOUNT_ADDRESS_LINE_1: {
    id: ACCOUNTS.ADDRESS_LINE_1
  },
  ACCOUNT_ADDRESS_CITY: {
    id: ACCOUNTS.ADDRESS_CITY
  },
  ACCOUNT_ADDRESS_STATE: {
    id: ACCOUNTS.ADDRESS_STATE
  },
  ACCOUNT_ADDRESS_ZIP: {
    id: ACCOUNTS.ADDRESS_ZIP
  },
  TYPE_OF_MEDIA_OUTLET: {
    create: true,
    id: "media-outlet",
    name: "Type of outlet",
    type: "dropdown",
    settings: {}
  },
  //
  CONTACT_FIRST_NAME: {
    id: CONTACTS.FIRST_NAME
  },
  CONTACT_LAST_NAME: {
    id: CONTACTS.LAST_NAME
  },
  CONTACT_EMAIL: {
    id: CONTACTS.EMAIL
  },
  CONTACT_PHONE: {
    id: CONTACTS.MOBILE_PHONE
  },
  CONTACT_ADDRESS_LINE_1: {
    id: CONTACTS.ADDRESS_LINE_1
  },
  CONTACT_ADDRESS_CITY: {
    id: CONTACTS.ADDRESS_CITY
  },
  CONTACT_ADDRESS_STATE: {
    id: CONTACTS.ADDRESS_STATE
  },
  CONTACT_ADDRESS_ZIP: {
    id: CONTACTS.ADDRESS_ZIP
  }
};

const REQUEST_FORM_TEMPLATE_STUBS = [
  { id: "default-request-form", blockName: "Pass Request Form" },
  { id: "sponsor-credentials", blockName: "Sponsor Credentials" },
  { id: "artist-guest-passes", blockName: "Artist Guest Passes" },
  { id: "friends-family-tickets", blockName: "Friends & Family Tickets" },
  { id: "comp-ticket-requests", blockName: "Available Comp Tickets" },
  { id: "internal-staff-tickets", blockName: "Internal Staff Tickets" },
  { id: "industry-guest-passes", blockName: "Guest Passes" }
];

const APPLICATION_FORM_TEMPLATE_STUBS = [
  { id: "default-application-form", blockName: "Application Form" },
  { id: "media-application", blockName: "Media Application" },
  { id: "vendor-application", blockName: "Vendor Application" }
];

const DEFAULT_REQUEST_FORM_TEMPLATES = REQUEST_FORM_TEMPLATE_STUBS.map(
  stub => ({
    defaultItemType: CREDENTIAL_TYPE_ID,
    steps: [
      {
        id: "select-passes",
        type: "items",
        name: "Step 1",
        title: "Select the passes you'd like to make available on this form",
        subtitle: null
      },
      {
        id: "select-fields",
        type: "fields",
        name: "Step 2",
        title: "Select the information to collect",
        subtitle:
          "You'll be able to edit these questions and create your own later",
        groups: [
          {
            id: "group",
            type: "account",
            name: "Company/Group Information",
            seeAllLabel: "See all group fields",
            autoInclude: [
              FIELD_TEMPLATES.ACCOUNT_NAME,
              FIELD_TEMPLATES.ACCOUNT_ADDRESS_LINE_1,
              FIELD_TEMPLATES.ACCOUNT_ADDRESS_CITY,
              FIELD_TEMPLATES.ACCOUNT_ADDRESS_STATE,
              FIELD_TEMPLATES.ACCOUNT_ADDRESS_ZIP
            ],
            autoRequire: [FIELD_TEMPLATES.ACCOUNT_NAME],
            primary: [
              FIELD_TEMPLATES.ACCOUNT_NAME,
              FIELD_TEMPLATES.ACCOUNT_ADDRESS_LINE_1,
              FIELD_TEMPLATES.ACCOUNT_ADDRESS_CITY,
              FIELD_TEMPLATES.ACCOUNT_ADDRESS_STATE,
              FIELD_TEMPLATES.ACCOUNT_ADDRESS_ZIP
            ]
          },
          {
            id: "contact",
            type: "contact",
            name: "Person Information",
            seeAllLabel: "See all person fields",
            autoInclude: [
              FIELD_TEMPLATES.CONTACT_FIRST_NAME,
              FIELD_TEMPLATES.CONTACT_LAST_NAME,
              FIELD_TEMPLATES.CONTACT_EMAIL,
              FIELD_TEMPLATES.CONTACT_PHONE,
              FIELD_TEMPLATES.CONTACT_ADDRESS_LINE_1,
              FIELD_TEMPLATES.CONTACT_ADDRESS_CITY,
              FIELD_TEMPLATES.CONTACT_ADDRESS_STATE,
              FIELD_TEMPLATES.CONTACT_ADDRESS_ZIP
            ],
            autoRequire: [
              FIELD_TEMPLATES.CONTACT_FIRST_NAME,
              FIELD_TEMPLATES.CONTACT_LAST_NAME,
              FIELD_TEMPLATES.CONTACT_EMAIL
            ],
            primary: [
              FIELD_TEMPLATES.CONTACT_FIRST_NAME,
              FIELD_TEMPLATES.CONTACT_LAST_NAME,
              FIELD_TEMPLATES.CONTACT_EMAIL,
              FIELD_TEMPLATES.CONTACT_PHONE,
              FIELD_TEMPLATES.CONTACT_ADDRESS_LINE_1,
              FIELD_TEMPLATES.CONTACT_ADDRESS_CITY,
              FIELD_TEMPLATES.CONTACT_ADDRESS_STATE,
              FIELD_TEMPLATES.CONTACT_ADDRESS_ZIP
            ]
          }
        ]
      },
      {
        id: "all-done",
        type: "success",
        name: "Step 3",
        title: null,
        subtitle: null
      }
    ],
    ...stub
  })
);

const DEFAULT_APPLICATION_FORM_TEMPLATES = APPLICATION_FORM_TEMPLATE_STUBS.map(
  stub => ({
    defaultItemType: CREDENTIAL_TYPE_ID,
    steps: [
      {
        id: "select-fields",
        type: "fields",
        name: "Step 1",
        title: "Select the information to collect",
        subtitle:
          "You'll be able to edit these questions and create your own later",
        groups: [
          {
            id: "group",
            type: "account",
            name: "Media Outlet Information",
            seeAllLabel: "See all group fields",
            autoInclude: [
              FIELD_TEMPLATES.ACCOUNT_NAME,
              FIELD_TEMPLATES.ACCOUNT_ADDRESS_LINE_1,
              FIELD_TEMPLATES.ACCOUNT_ADDRESS_CITY,
              FIELD_TEMPLATES.ACCOUNT_ADDRESS_STATE,
              FIELD_TEMPLATES.ACCOUNT_ADDRESS_ZIP
            ],
            autoRequire: [FIELD_TEMPLATES.ACCOUNT_NAME],
            primary: [
              FIELD_TEMPLATES.ACCOUNT_NAME,
              FIELD_TEMPLATES.ACCOUNT_ADDRESS_LINE_1,
              FIELD_TEMPLATES.ACCOUNT_ADDRESS_CITY,
              FIELD_TEMPLATES.ACCOUNT_ADDRESS_STATE,
              FIELD_TEMPLATES.ACCOUNT_ADDRESS_ZIP
            ]
          }
        ]
      },
      {
        id: "all-done",
        type: "success",
        name: "Step 2",
        title: null,
        subtitle: null
      }
    ],
    ...stub
  })
);

const FIELD_GROUP_TEMPLATES = [
  ...DEFAULT_REQUEST_FORM_TEMPLATES,
  ...DEFAULT_APPLICATION_FORM_TEMPLATES
  /*
  {
    id: "media-application",
    blockName: "Media Application - Passes",
    defaultItemType: CREDENTIAL_TYPE_ID,
    steps: [
      {
        id: "select-passes",
        type: "items",
        name: "Step 1",
        title: "Select the passes you'd like to make available on this form",
        subtitle: null
      },
      {
        id: "select-fields",
        type: "fields",
        name: "Step 2",
        title: "Select the information to collect",
        subtitle:
          "You'll be able to edit these questions and create your own later",
        groups: [
          {
            id: "media",
            type: "account",
            name: "Media Outlet Information",
            seeAllLabel: "See all group fields",
            primary: [
              FIELD_TEMPLATES.ACCOUNT_NAME,
              FIELD_TEMPLATES.TYPE_OF_MEDIA_OUTLET,
              FIELD_TEMPLATES.ACCOUNT_ADDRESS_LINE_1,
              FIELD_TEMPLATES.ACCOUNT_ADDRESS_CITY,
              FIELD_TEMPLATES.ACCOUNT_ADDRESS_STATE,
              FIELD_TEMPLATES.ACCOUNT_ADDRESS_ZIP
            ]
          }
        ]
      },
      {
        id: "all-done",
        type: "success",
        name: "Step 3",
        title: null,
        subtitle: null
      }
    ]
  }
  */
];

const hydratePrimaryFields = (list, fields) => {
  return list
    .map(field => {
      if (field.id) {
        return fields.find(f => f.id === field.id);
      }
      return field;
    })
    .filter(f => f);
};

const hydrateSecondaryFields = (list, primaryFields, allFields) => {
  const primaryFieldIds = primaryFields.map(f => f.id);

  if (!list || !list.length) {
    return allFields.filter(f => !primaryFieldIds.includes(f.id));
  }

  return list
    .map(field => {
      if (field.id) {
        return allFields.find(f => f.id === field.id);
      }
      return field;
    })
    .filter(f => f);
};

import { getItemTypesByEvent } from "redux/modules/items/types/actions";
import {
  addItemBlock,
  updateItemBlock,
  deleteItemBlock
} from "redux/modules/items/item-blocks/actions";
import { getFields } from "redux/modules/modules/fields/actions";

import { eventDetails } from "redux/modules/event/selectors";
import { types } from "redux/modules/items/types/selectors";
import { fields } from "redux/modules/modules/fields/selectors";

function mapStateToProps(state, props) {
  const accountFieldsToPass = fields(
    state,
    STANDARD_MODULE_IDS.accounts.id
  ).filter(f => !accountReadOnlyFields.includes(f.id));
  const contactFieldsToPass = fields(
    state,
    STANDARD_MODULE_IDS.contacts.id
  ).filter(f => !contactReadOnlyFields.includes(f.id));
  const templateToUse =
    FIELD_GROUP_TEMPLATES.find(t => t.id === props.template) || {};

  const template = {
    ...templateToUse,
    steps: (templateToUse.steps || []).map(step => {
      return {
        ...step,
        groups: step.groups
          ? step.groups.map(group => {
              let fieldsToUse = [];
              switch (group.type) {
                case "account":
                  fieldsToUse = accountFieldsToPass;
                  break;
                case "contact":
                  fieldsToUse = contactFieldsToPass;
                  break;
              }

              const primaryFields = hydratePrimaryFields(
                group.primary,
                fieldsToUse
              );
              const secondaryFields = hydrateSecondaryFields(
                group.secondary,
                primaryFields,
                fieldsToUse
              );

              return {
                ...group,
                primary: primaryFields,
                secondary: secondaryFields
              };
            })
          : step.groups
      };
    })
  };

  return {
    types: types(state),
    eventDetails: eventDetails(state),
    template,
    contactFields: contactFieldsToPass,
    accountFields: accountFieldsToPass
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getFields,
      getItemTypesByEvent,
      addItemBlock,
      updateItemBlock,
      deleteItemBlock
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
