import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";

import * as snackbarActions from "redux/modules/snackbar/actions";
import * as modalActions from "redux/modules/modal/actions";
import { getOrderSummaryForAccount } from "redux/modules/orders/orderSummaryByRecord/actions";
import { getOrdersForAccount } from "redux/modules/orders/ordersByRecord/actions";

import * as UserSelectors from "redux/modules/user/selectors";
import * as EventSelectors from "redux/modules/event/selectors";
import {
  orderSummaryByRecord,
  isFetching
} from "redux/modules/orders/orderSummaryByRecord/selectors";
import { ordersByRecord } from "redux/modules/orders/ordersByRecord/selectors";

function mapStateToProps(state, ownProps) {
  return {
    user: UserSelectors.user(state),
    eventDetails: EventSelectors.eventDetails(state),
    summary: orderSummaryByRecord(state, ownProps.params.recordId),
    orders: ordersByRecord(state, ownProps.params.recordId),
    isFetching: isFetching(state, ownProps.params.recordId)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getOrderSummaryForAccount,
      getOrdersForAccount,
      ...modalActions,
      ...snackbarActions
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controller);
