import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  refreshingZoneAssignments: false
};

const model = createHandlers({
  iniState,
  reducers: {
    refreshZoneAssignments: R.identity
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
