import React from "react";

import {
  put,
  call,
  all,
  fork,
  select,
  take,
  takeEvery
} from "redux-saga/effects";
import { makeFuture } from "utils/General/sagas";
import * as R from "ramda";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import { ROW_ACTIONS, VIEWPICKER_INSTANCE_ID } from "Items/Manage/constants";

import { actions } from "Items/Manage";
import { actions as TableActions } from "ui-kit/Table/model";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { actions as AddZonesModalActions } from "Orders/AddZonesModal/model";

import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { getters as ViewPickerGetters } from "ui-kit/ViewPicker";
import { getItemTypes } from "./search";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import IssuanceModal from "Passes/Checkin/IssuanceModal";
import SubscribeModal from "Modules/SubscribeModal/View";

import checkinApi from "redux/modules/passesCheckin/api";

const printItems = function*({ payload: { value }, meta }) {
  yield put(
    actions.printItems(
      {
        lineItemIds: value.recordIds
      },
      { meta }
    )
  );
};

const undoPrintItems = function*({ payload: { value }, meta }) {
  yield put(
    actions.undoPrintItems(
      {
        lineItemIds: value.recordIds
      },
      { meta }
    )
  );
};

const showIssuanceModal = function*({
  itemName,
  recordName,
  status,
  quantity,
  orderType,
  customerAccountId,
  customerContactId,
  recordId,
  variantId,
  fulfillmentId
}) {
  const handleIssuance = makeFuture();
  yield put(
    showModal({
      content: (
        <IssuanceModal
          status={status}
          onIssue={count =>
            handleIssuance.done({ type: "issueCheckinItems", count })
          }
          onFulfill={count =>
            handleIssuance.done({ type: "fulfillItems", count })
          }
          onIssueAndFulfill={count =>
            handleIssuance.done({ type: "issueAndFulfill", count })
          }
          onUndoIssuance={count =>
            handleIssuance.done({ type: "undoIssuance", count })
          }
          onUndoPickup={count =>
            handleIssuance.done({ type: "undoFulfillment", count })
          }
          onUndoIssuanceAndPickup={count =>
            handleIssuance.done({ type: "undoIssuanceAndFulfillment", count })
          }
          itemName={itemName}
          recordName={recordName}
          totalAvailable={parseInt(quantity, 10)}
          //
          orderType={orderType}
          customerAccountId={customerAccountId}
          customerContactId={customerContactId}
          recordId={recordId}
          variantId={variantId}
          fulfillmentId={fulfillmentId}
          onDone={() => handleIssuance.done({ type: "hideModal" })}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleIssuance.onRealized);
};
const editZonesItem = function*({ payload: { row } }) {
  const zoneIds = R.propOr([], "all_zone_ids", row);
  const lineItemId = R.prop("id", row);
  yield put(
    AddZonesModalActions.openZonesPicker({
      itemId: lineItemId,
      defaultSelected: zoneIds
    })
  );
};

const issueItems = function*({ payload: { value, actionId }, meta }) {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  const {
    orderType,
    customerAccountId,
    customerContactId,
    recordId,
    variantId,
    fulfillmentId,
    itemName,
    recordName,
    quantity,
    issued,
    pickedUp
  } = value;

  let status = null;
  if (actionId === ROW_ACTIONS.UNDO_CHECKIN) {
    status = pickedUp
      ? "undo-fulfillment"
      : issued
      ? "undo-issuance"
      : undefined;
  } else {
    status = issued && "pending-pickup";
  }

  const result = yield call(showIssuanceModal, {
    itemName,
    recordName,
    status,
    quantity,
    orderType,
    customerAccountId,
    customerContactId,
    recordId,
    variantId,
    fulfillmentId
  });

  if (result.type === "issueCheckinItems") {
    yield call(checkinApi.checkin, credentials, {
      actions: ["issue"],
      recordId,
      orderType,
      customerAccountId,
      customerContactId,
      variantId,
      options: {
        quantity: result.count
      },
      eventId
    });
  }

  if (result.type === "fulfillItems") {
    yield call(checkinApi.checkin, credentials, {
      actions: ["fulfill"],
      recordId,
      orderType,
      customerAccountId,
      customerContactId,
      variantId,
      options: {
        quantity: result.count
      },
      eventId
    });
  }

  if (result.type === "issueAndFulfill") {
    yield call(checkinApi.checkin, credentials, {
      actions: ["issue", "fulfill"],
      recordId,
      orderType,
      customerAccountId,
      customerContactId,
      variantId,
      options: {
        quantity: result.count
      },
      eventId
    });
  }

  if (result.type === "undoIssuance") {
    yield call(checkinApi.revertCheckin, credentials, {
      variantId,
      recordId,
      orderType,
      customerAccountId,
      customerContactId,
      action: "issuance",
      options: { quantity: result.count, fulfillmentId },
      eventId
    });
  }

  if (result.type === "undoFulfillment") {
    yield call(checkinApi.revertCheckin, credentials, {
      variantId,
      recordId,
      orderType,
      customerAccountId,
      customerContactId,
      action: "fulfillment",
      options: { quantity: result.count, fulfillmentId },
      eventId
    });
  }

  if (result.type === "undoIssuanceAndFulfillment") {
    yield call(checkinApi.revertCheckin, credentials, {
      variantId,
      recordId,
      orderType,
      customerAccountId,
      customerContactId,
      action: "fulfillment-issuance",
      options: { quantity: result.count, fulfillmentId },
      eventId
    });
  }

  if (result.type === "hideModal") {
    yield put(hideModal());
    yield put(actions.fetchData({ silent: true }, meta));
  }
};

const watchTableActions = function*() {
  for (;;) {
    const action = yield take(TableActions.executeAction.type);
    const delegate = R.prop(action.payload.actionId, {
      [ROW_ACTIONS.PRINT_ITEMS]: printItems,
      [ROW_ACTIONS.UNDO_PRINT_ITEMS]: undoPrintItems,
      [ROW_ACTIONS.ISSUE_ITEMS]: issueItems,
      [ROW_ACTIONS.UNDO_CHECKIN]: issueItems,
      [ROW_ACTIONS.EDIT_ZONES]: editZonesItem
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const showSubscribeModal = function*({ meta = {} }) {
  const instanceId = meta.instanceId;
  const state = yield select(R.identity);
  const itemTypeIds = getItemTypes(state, { instanceId });
  const activeViewId = yield select(ViewPickerGetters.activeViewId, {
    instanceId: VIEWPICKER_INSTANCE_ID
  });

  yield put(
    showModal({
      content: (
        <SubscribeModal
          type="items"
          moduleId={STANDARD_MODULE_IDS.orders.id}
          itemTypeId={itemTypeIds[0]}
          viewId={activeViewId}
        />
      ),
      wrapper: ModalWrapper
    })
  );
};

const watchShowSubscribeModal = function*() {
  yield takeEvery(actions.showSubscribeModal.type, showSubscribeModal);
};

const rootSaga = function*() {
  yield all([fork(watchTableActions), fork(watchShowSubscribeModal)]);
};

export default rootSaga;
