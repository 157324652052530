import { combineReducers } from "redux";
import { RECEIVE, REQUEST } from "./constants";
import { ERROR } from "redux/modules/errors/constants";

const assignedForms = (state = [], action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload.forms;
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case ERROR:
      return false;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  assignedForms,
  fetching
});
