import { createSelector } from "reselect";

import * as R from "ramda";

import { getters } from "HealthPass/HealthPassForm/model";

import { user as getUser } from "redux/modules/user/selectors";

import {
  WELCOME_COLORS,
  GROUP_COLORS,
  CONTACT_COLORS
} from "HealthPass/HealthPassForm/constants";

import isValid from "utils/value-types/validations/is-valid";

import isEmpty from "utils/value-types/is-empty";

import Helpers from "utils/Global/Helpers";

import * as STANDARD_MODULE_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

export const getWelcomeColor = createSelector(
  getters.activeIndex,
  activeIndex => WELCOME_COLORS[activeIndex]
);

export const getWelcomeData = createSelector(
  getters.formData,
  formData => (R.isEmpty(formData) ? {} : formData.sections[0])
);

export const getShowDeniedMessage = createSelector(
  getters.formData,
  formData => (formData.status && !formData.expired ? true : false)
);

export const getGroupColor = createSelector(
  getters.activeIndex,
  activeIndex => GROUP_COLORS[activeIndex]
);

export const getQuestionsData = createSelector(
  getters.formData,
  formData => (R.isEmpty(formData) ? {} : formData.sections[1])
);

export const getLogoImageUrl = createSelector(
  getters.formData,
  R.prop("logo_image_url")
);

export const getPageTitle = createSelector(
  getters.formData,
  R.prop("title")
);

const isRequiredFieldValid = (value, emailId, allValues) => {
  if (R.isEmpty(R.dissoc("id", value))) {
    return true;
  }
  const type = R.propOr("text", "type", value);
  if (
    value.id === "confirm-password" &&
    allValues &&
    R.propOr("", "value", value) !==
      R.pathOr("", ["password", "value"], allValues)
  ) {
    return false;
  } else if (value.id === emailId) {
    return Helpers.isValidEmail(R.propOr("", "value", value));
  } else if (value.type === "password") {
    return Helpers.isValidPassword(R.propOr("", "value", value));
  }

  return isValid(value, type);
};

const isFieldValid = (value, emailId) => {
  if (isEmpty(value, value.type)) {
    return true;
  }

  return isRequiredFieldValid(value, emailId);
};

export const getQuestionsDataFields = createSelector(
  getQuestionsData,
  getters.questionsValues,
  getters.selectedAccountId,
  (groupData, questionsValues, isGroupSelected) =>
    R.compose(
      R.map(field => {
        const fieldValue = R.propOr({}, field.id, questionsValues);
        return {
          ...field,
          disabled:
            field.id === STANDARD_MODULE_FIELD_IDS.ACCOUNTS.NAME
              ? Boolean(isGroupSelected)
              : false,
          isValid: !field.required
            ? isFieldValid(
                { ...fieldValue, id: field.id },
                STANDARD_MODULE_FIELD_IDS.ACCOUNTS.EMAIL
              )
            : isRequiredFieldValid(
                { ...fieldValue, id: field.id },
                STANDARD_MODULE_FIELD_IDS.ACCOUNTS.EMAIL
              )
        };
      }),
      R.propOr([], "fields")
    )(groupData)
);

export const getContactColor = createSelector(
  getters.activeIndex,
  activeIndex => CONTACT_COLORS[activeIndex]
);

export const getContactData = createSelector(
  getters.formData,
  formData => (R.isEmpty(formData) ? {} : formData.sections[2])
);

export const getContactDataFields = createSelector(
  getContactData,
  getters.contactValues,
  getters.userExists,
  getters.isVirtualUser,
  getters.selectedContactId,
  getters.isLoggedIn,
  (
    contactData,
    contactValues,
    userExists,
    isVirtualUser,
    isContactSelected,
    isLoggedIn
  ) =>
    R.compose(
      R.map(field => {
        const fieldValue = R.propOr({}, field.id, contactValues);
        return {
          ...field,
          disabled: [
            STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME,
            STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME
          ].includes(field.id)
            ? Boolean(isContactSelected)
            : false,
          isValid: !field.required
            ? isFieldValid(
                { ...fieldValue, id: field.id },
                STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL
              )
            : isRequiredFieldValid(
                { ...fieldValue, id: field.id },
                STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL,
                contactValues
              )
        };
      }),
      fields =>
        isLoggedIn
          ? fields.filter(f => !["password", "confirm-password"].includes(f.id))
          : userExists && !isVirtualUser
          ? fields
              .filter(f => !["confirm-password"].includes(f.id))
              .map(f => ({
                ...f,
                name: f.id === "password" ? "Enter Your Password" : f.name,
                settings:
                  f.id === "password"
                    ? {
                        description:
                          "Use the password for your existing account"
                      }
                    : f.settings
              }))
          : fields,
      R.propOr([], "fields")
    )(contactData)
);

export const getContactDataFieldsFiltered = createSelector(
  getContactDataFields,
  R.filter(
    ({ id }) =>
      ![
        STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME,
        STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME,
        STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL
      ].includes(id)
  )
);

export const getContactFirstLastNameFields = createSelector(
  getContactDataFields,
  R.compose(
    R.sortBy(({ id }) =>
      R.prop(id)({
        [STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME]: 0,
        [STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME]: 1,
        [STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL]: 2
      })
    ),
    R.filter(({ id }) =>
      [
        STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME,
        STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME,
        STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL
      ].includes(id)
    )
  )
);

export const getNextButtonText = createSelector(
  getters.activeIndex,
  activeIndex => {
    if (activeIndex === 0) {
      return "Continue";
    }
    return "Complete";
  }
);

const getQuestionsValuesValid = createSelector(
  getQuestionsData,
  getters.questionsValues,
  (questionsData, questionsValues) => {
    const fields = R.propOr([], "fields", questionsData);

    if (R.isEmpty(fields)) {
      return true;
    }

    return R.compose(
      R.all(field => {
        if (!field.required) {
          if (R.isEmpty(R.dissoc("id", field)) || isEmpty(field, field.type)) {
            return true;
          }
        }
        const type = R.propOr("text", "type", field);
        if (field.id === STANDARD_MODULE_FIELD_IDS.ACCOUNTS.EMAIL) {
          return Helpers.isValidEmail(R.propOr("", "value", field));
        }
        return isValid(field, type);
      }),
      R.map(({ id, required }) => ({
        id,
        ...R.propOr({}, id, questionsValues),
        required
      }))
    )(fields);
  }
);

export const getLoginFields = createSelector(
  getters.loginFields,
  getters.loginValues,
  (fields, loginValues) =>
    R.map(field => {
      const fieldValue = R.propOr({}, field.id, loginValues);
      return {
        ...field,
        isValid: !field.required
          ? isFieldValid({ ...fieldValue, id: field.id }, "email")
          : isRequiredFieldValid({ ...fieldValue, id: field.id }, "email")
      };
    }, fields)
);

export const getForgotFields = createSelector(
  getters.forgotFields,
  getters.forgotValues,
  (fields, forgotValues) =>
    R.map(field => {
      const fieldValue = R.propOr({}, field.id, forgotValues);
      return {
        ...field,
        isValid: !field.required
          ? isFieldValid({ ...fieldValue, id: field.id }, "email")
          : isRequiredFieldValid({ ...fieldValue, id: field.id }, "email")
      };
    }, fields)
);

const fieldsValid = R.all(field => {
  if (!field.required) {
    if (R.isEmpty(R.dissoc("id", field)) || isEmpty(field, field.type)) {
      return true;
    }
  }
  const type = R.propOr("text", "type", field);
  if (field.id === "email") {
    return Helpers.isValidEmail(R.propOr("", "value", field));
  } else if (field.type === "password") {
    return R.length(R.propOr("", "value", field)) >= 6;
  }
  return isValid(field, type);
});

export const getForgotValuesValid = createSelector(
  getters.forgotFields,
  getters.forgotValues,
  (fields, forgotValues) => {
    if (R.isEmpty(fields)) {
      return true;
    }

    return R.compose(
      fieldsValid,
      R.map(({ id, required }) => ({
        id,
        ...R.propOr({}, id, forgotValues),
        required
      }))
    )(fields);
  }
);

export const getLoginValuesValid = createSelector(
  getters.loginFields,
  getters.loginValues,
  (fields, loginValues) => {
    if (R.isEmpty(fields)) {
      return true;
    }

    return R.compose(
      fieldsValid,
      R.map(({ id, required }) => ({
        id,
        ...R.propOr({}, id, loginValues),
        required
      }))
    )(fields);
  }
);

export const getLoginData = createSelector(
  getters.loginValues,
  R.map(data => R.propOr("", "value", data))
);

export const getForgotData = createSelector(
  getters.forgotValues,
  R.map(data => R.propOr("", "value", data))
);

export const getQuestionsValues = createSelector(
  getters.questionsValues,
  R.filter(data => !R.isEmpty(R.propOr("", "value", data)))
);

export const getQuestionsFieldsAndValues = createSelector(
  getQuestionsData,
  getters.questionsValues,
  (data, values) => {
    return R.compose(
      R.filter(field => !R.isEmpty(R.pathOr("", ["value", "value"], field))),
      R.map(field => {
        const fieldValue = R.propOr({}, field.id, values);
        return {
          id: field.id,
          moduleId: field.module_id,
          value: fieldValue
        };
      }),
      R.propOr([], "fields")
    )(data);
  }
);

export const getContactValues = createSelector(
  getters.contactValues,
  R.filter(data => !R.isEmpty(R.propOr("", "value", data)))
);

export const getNextButtonDisabled = createSelector(
  getters.activeIndex,
  getWelcomeData,
  getters.termsAccepted,
  getQuestionsValuesValid,
  (activeIndex, welcomeData, termsAccepted, questionsValuesValid) => {
    if (activeIndex === 0) {
      const showTerms = R.propOr(false, "show_terms", welcomeData);
      if (showTerms) {
        return !termsAccepted;
      }
      return false;
    } else if (activeIndex === 1) {
      return !questionsValuesValid;
    }
    return false;
  }
);

export const getShowBackButton = createSelector(
  getters.activeIndex,
  activeIndex => activeIndex > 0
);

export const getPortalUrl = createSelector(
  getters.formData,
  data => `/portals/${data.event_slug}/${data.event_uuid}`
);

export const getUserName = createSelector(
  getUser,
  user => {
    const name = [user.fname, user.lname].filter(v => v).join(" ");
    if (name.length) {
      return name;
    }
    return user.email;
  }
);

export const getAvailableAccounts = createSelector(
  getters.eventUserGroups,
  accounts => {
    return accounts && accounts.length
      ? [
          ...accounts.map(a => ({
            label: a.name,
            value: a.id
          })),
          {
            value: null,
            label: "Fill out as a new group..."
          }
        ]
      : [];
  }
);

export const getAvailableContacts = createSelector(
  getters.selectedAccountId,
  getters.eventUserGroups,
  (selectedAccountId, accounts) => {
    if (!selectedAccountId) {
      return [];
    }
    const contacts = R.compose(
      R.propOr([], "contacts"),
      R.find(a => a.id === selectedAccountId)
    )(accounts);

    return contacts && contacts.length
      ? [
          ...contacts.map(a => ({
            label: a.name,
            value: a.id
          })),
          {
            value: null,
            label: "Fill out as a new person..."
          }
        ]
      : [];
  }
);
