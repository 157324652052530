import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE } from "./constants";

const iniState = {
  fieldsFilter: "",
  fields: [],
  selectedFieldIds: [],
  requiredFieldIds: [],
  defaultSelectedFieldIds: [],
  defaultRequiredFieldIds: [],
  loading: false,
  enableRequiredFields: false
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    cancelInstance: R.always(iniState),
    init: R.identity,
    setInitialData: (
      state,
      {
        payload: {
          enableRequiredFields,
          fields,
          selectedFieldIds,
          requiredFieldIds,
          defaultSelectedFieldIds,
          defaultRequiredFieldIds
        }
      }
    ) => ({
      enableRequiredFields,
      fields,
      selectedFieldIds,
      requiredFieldIds,
      defaultSelectedFieldIds,
      defaultRequiredFieldIds
    }),
    toggleField: (state, { payload: fieldId }) => ({
      selectedFieldIds: state.selectedFieldIds.includes(fieldId)
        ? state.selectedFieldIds.filter(id => id !== fieldId)
        : [...state.selectedFieldIds, fieldId]
    }),
    toggleRequiredField: (state, { payload: fieldId }) => ({
      requiredFieldIds: state.requiredFieldIds.includes(fieldId)
        ? state.requiredFieldIds.filter(id => id !== fieldId)
        : [...state.requiredFieldIds, fieldId]
    }),
    removeAllSelectedFields: () => ({
      selectedFieldIds: []
    }),
    updateSelectedFieldOrder: (state, { payload: fields }) => ({
      selectedFieldIds: fields.map(f => f.id)
    }),
    selectAll: ({ selectedFieldIds }, { payload: fields }) => ({
      selectedFieldIds: R.uniq(
        R.concat(selectedFieldIds, R.map(R.prop("id"), fields))
      )
    }),
    clearAll: ({ selectedFieldIds }, { payload: fields }) => ({
      selectedFieldIds: R.without(R.map(R.prop("id"), fields), selectedFieldIds)
    })
  }
});

export default handlers;
