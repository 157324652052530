import React, { Component } from "react";
import * as R from "ramda";

import getValue from "utils/value-types/get-value/number";
import toString from "utils/value-types/to-string/percent";

import { getSettings } from "ui-kit/Datagrid/utils";

export default class PercentCellFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props.value, nextProps.value);
  }

  render() {
    const value = getValue(this.props.value);
    const formatted = value ? toString(value, getSettings(this.props)) : "";
    return (
      <div
        style={{
          lineHeight: "34px"
        }}
        title={formatted}
      >
        {formatted}
      </div>
    );
  }
}
