import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import { PEOPLE_LIST_ID } from "../constants";
import {
  getIsSaveSelectedPeopleEnabled,
  getDropdownPeopleList
} from "../selectors";
import { Div, Text1, Text5, CloseIcon, BigFilledButton } from "components/Base";

import MiniModalWrapper from "ui-kit/MiniModalWrapper";

import { DropdownAsync } from "ui-kit/Form/View";
import { noop } from "utils/General";

const Header = ({ title = "", hideModal = noop }) => (
  <Div
    style={{
      flexShrink: 0
    }}
    display="row.space-between.center"
    bg="white"
    p={4}
    bb={1}
    bc="neutral2"
    brt={1}
  >
    <Text5 bold color="neutral8">
      {title}
    </Text5>

    <Div onClick={hideModal}>
      <CloseIcon color="neutral6" sizeWFS={6} />
    </Div>
  </Div>
);

const decorate = R.compose(
  connect(
    (state, props) => ({
      isOpen: getters.isSelectPeopleModalOpen(state, props),
      isSaveBtnEnabled: getIsSaveSelectedPeopleEnabled(state, props),
      peopleList: getDropdownPeopleList(state, props)
    }),
    {
      closeModal: () => actions.setIsSelectPeopleModalOpen(false),
      updateSelectedPeople: actions.updateSelectedPeople
    }
  )
);

const SelectPeopleModal = ({
  isOpen,
  closeModal = noop,
  updateSelectedPeople = noop,
  isSaveBtnEnabled,
  peopleList
}) => (
  <MiniModalWrapper
    hideModal={closeModal}
    isPortal={false}
    showModal={isOpen}
    showHeader={false}
    style={{
      width: 450,
      backgroundColor: "white",
      height: "50vh",
      maxHeight: "300px"
    }}
    bg="neutral0"
  >
    <Header title="Add People to Order" hideModal={closeModal} />
    <Div
      width={1}
      height={1}
      p={4}
      display="column.flex-start.stretch"
      bb={1}
      bg="neutral0"
      bc="neutral1"
      shadow={1}
      style={{ overflowY: "auto", flex: "1 1 auto" }}
    >
      <Div width={1} display="column.flex-start.stretch" mt={4}>
        <Text1 color="black" mb={2}>
          Select people to add to this order
        </Text1>
        <DropdownAsync
          fieldId={PEOPLE_LIST_ID}
          iniValue={peopleList}
          multipleSelect
          usePortal
          bg="white"
        />
      </Div>
    </Div>
    <Div
      bg="white"
      width={1}
      display="column.flex-start.stretch"
      p={4}
      bt={1}
      bc="neutral2"
    >
      <BigFilledButton
        bg="primary7"
        color="white"
        width={1}
        display="row.center.center"
        onClick={updateSelectedPeople}
        disabled={!isSaveBtnEnabled}
      >
        Save
      </BigFilledButton>
    </Div>
  </MiniModalWrapper>
);

export default decorate(SelectPeopleModal);
