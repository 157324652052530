import { createSelector } from "reselect";
import * as R from "ramda";
import { getters } from "Organizations/TagsVenues";

export const getSelectedTagsIds = createSelector(
  getters.tags,
  R.compose(
    R.map(R.propOr("", "id")),
    R.filter(R.propEq("selected", true))
  )
);
