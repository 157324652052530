import React from "react";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import css from "Schedules/Schedule/View/styles.scss";

import { VARIANTS } from "ui-kit/Theme";

import { getIndividualSchedules } from "Schedules/Schedule/selectors";
import { SCHEDULES_FILTER_INSTANCE_ID } from "Schedules/Schedule/constants";

import { Text2 } from "components/Base";
import { CheckboxFilter } from "ui-kit/FilterControls/View";

const decorate = connect((state, props) => ({
  schedules: getIndividualSchedules(state, props)
}));

const Schedules = ({ schedules, ...styleProps }) => {
  if (!schedules || !schedules.length) {
    return null;
  }
  return (
    <CheckboxFilter
      options={schedules}
      filtersOn
      instanceId={SCHEDULES_FILTER_INSTANCE_ID}
      multiSelect
      color="white"
      variant={VARIANTS.PRIMARY_VARIANT}
      className={css.button2}
      {...styleProps}
    >
      <Text2 bold color="white" uppercase>
        My Schedules
      </Text2>
    </CheckboxFilter>
  );
};

export default CSSModules(decorate(Schedules), css);
