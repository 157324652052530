import React from "react";
import SubNav from "./SubNav";
import * as R from "ramda";
import { connect } from "react-redux";
import { TAB_IDS } from "./constants";
import { withRouter } from "react-router";
import PageTitle from "EventLight/Common/PageTitle";

import Manage from "./Manage/View";
import Questions from "./Questions/View";
import Emails from "./Emails/View";
import Assign from "./Assign/View";

import { getCurrentRoute, getTabs } from "./selectors";

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    currentRoute: getCurrentRoute(state, props),
    tabs: getTabs(state, props)
  }))
);

const Wrapper = ({ currentRoute, tabs }) => {
  const Page = R.prop(currentRoute)({
    [TAB_IDS.MANAGE]: Manage,
    [TAB_IDS.QUESTIONS]: Questions,
    [TAB_IDS.EMAILS]: Emails,
    [TAB_IDS.ASSIGN]: Assign
  });

  return (
    <>
      <PageTitle titles={["Health Pass"]} />
      <SubNav tabs={tabs} selectedView={currentRoute} />
      <Page />
    </>
  );
};

export default decorate(Wrapper);
