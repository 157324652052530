import * as R from "ramda";

import { createSelector } from "reselect";

import { getters } from "./index";

import isValid from "utils/value-types/validations/is-valid";

import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { eventDetails as getSubmissionEventDetails } from "redux/modules/formsV2/submission/selectors";
import { formatAmountForEvent } from "redux/modules/event/selectors";

const formatQuestion = q => ({
  id: q.id,
  required: q.required,
  value: q.value,
  name: q.name,
  type: q.type,
  description: R.path(["settings", "description"])(q),
  settings: q.settings
});

export const getHeaderInformation = createSelector(
  getters.hasSingleVariant,
  getters.item,
  (hasSingleVariant, item) => {
    return {
      name: hasSingleVariant ? item.variants[0].display_name : item.name,
      color: item.background_color,
      description: item.description
    };
  }
);

export const getVariants = createSelector(
  getters.selectedVariants,
  getters.item,
  (selectedVariants, item) => {
    const variants = R.map(variant => {
      const quantity =
        selectedVariants[variant.id] >= 0 ? selectedVariants[variant.id] : 0;

      return {
        ...variant,
        mode: "single",
        quantity,
        count: selectedVariants[variant.id],
        max: undefined,
        selected: Boolean(parseInt(selectedVariants[variant.id], 10))
        /*
        // @TODO: Handle with actiosn
        incrementQuantity: () =>
          this.updateVariantQuantity(variant.id, quantity + 1),
        decrementQuantity: () =>
          this.updateVariantQuantity(variant.id, quantity - 1),
        updateVariantQuantity: qty =>
          this.updateVariantQuantity(variant.id, parseInt(qty, 10) || 0),
        addToCart: () => {
          if (calculateIsValid(quantity)) {
            variant.addItemToCart({
              variant,
              quantity,
              price: variant.price
            });
            hideModal();
          }
          return false;
        }
        */
      };
    })(item.variants);
    return variants;
  }
);

export const getSelectedVariants = createSelector(
  getters.item,
  getters.selectedVariants,
  getters.selectedVariantPrices,
  (item, selectedVariants, selectedVariantPrices) => {
    let variants = [];
    Object.keys(selectedVariants).forEach(variantId => {
      if (selectedVariants[variantId] && selectedVariants[variantId] > 0) {
        const variant = item.variants.find(v => v.id === variantId);
        variants.push({
          variantId,
          variant,
          quantity: selectedVariants[variantId],
          price: selectedVariantPrices[variantId]
            ? selectedVariantPrices[variantId].price
            : undefined
        });
      }
    });
    return variants;
  }
);

const getPriceValue = (item, priceId) =>
  R.compose(
    R.propOr(0, "price"),
    R.find(R.propEq("id", priceId)),
    R.flatten,
    R.map(R.prop("prices")),
    R.prop("variants")
  )(item);

export const getQuantityAndCost = createSelector(
  getters.item,
  getters.selectedVariants,
  getters.selectedVariantPrices,
  getters.selectedVariantQuestions,
  (item, quantities, prices) => {
    const { cost, qty } = Object.keys(quantities).reduce(
      (map, variantId) => {
        if (prices[variantId]) {
          map.cost =
            map.cost +
            (prices[variantId] ? getPriceValue(item, prices[variantId]) : 0) *
              quantities[variantId];
        }
        map.qty = map.qty + quantities[variantId];
        return map;
      },
      { cost: 0, qty: 0 }
    );
    return { cost, qty };
  }
);

export const getCountOfSelectedVariants = createSelector(
  getQuantityAndCost,
  R.prop("qty")
);

export const getCostOfSelectedVariants = createSelector(
  getQuantityAndCost,
  formatAmountForEvent,
  (data, formatAmountForEvent) =>
    R.compose(
      cost => (cost ? formatAmountForEvent(cost) : 0),
      R.prop("cost")
    )(data)
);

export const getSingleVariantHasPrices = createSelector(getters.item, item =>
  Boolean(R.compose(R.length, R.pathOr([], ["variants", 0, "prices"]))(item))
);

export const getSingleVariantPrices = createSelector(
  getters.item,
  R.pathOr([], ["variants", 0, "prices"])
);

export const getSingleVariantQuantity = createSelector(
  getters.item,
  getters.selectedVariants,
  (item, selectedVariants) =>
    R.prop(R.pathOr(0, ["variants", 0, "id"])(item))(selectedVariants)
);

export const getSingleVariantSelectedPriceId = createSelector(
  getters.item,
  getters.selectedVariantPrices,
  (item, selectedPrices) =>
    R.prop(R.pathOr("", ["variants", 0, "id"])(item))(selectedPrices)
);

export const getVariantIdForQuestionValues = createSelector(
  getters.item,
  R.path(["variants", 0, "id"])
);

export const getDataToSave = createSelector(
  getters.item,
  getters.selectedVariants,
  getters.selectedVariantPrices,
  getters.selectedVariantQuestions,
  (item, selectedVariants, selectedPrices, selectedQuestions) => {
    const data = Object.keys(selectedVariants).reduce((map, variantId) => {
      map[variantId] = {
        quantity: selectedVariants[variantId],
        priceId: selectedPrices[variantId],
        price: getPriceValue(item, selectedPrices[variantId]),
        questions: selectedQuestions[variantId] || {}
      };
      return map;
    }, {});
    return data;
  }
);

export const getQuestions = createSelector(
  getters.item,
  getters.selectedVariantQuestions,
  getters.showInternalQuestions,
  (item, selectedQuestions, showInternalQuestions) => {
    const variant = R.path(["variants", 0])(item);
    if (!variant) {
      return [];
    }

    const answers = R.propOr({}, variant.id)(selectedQuestions);

    // format question-set questions
    const questionSetQuestions = R.compose(
      R.flatten,
      R.map(q => {
        return R.compose(
          R.map(qSetQuestion => ({
            ...qSetQuestion,
            parentSort: q.order,
            childSort: qSetQuestion.order,
            value: answers[qSetQuestion.id],
            required: qSetQuestion.required
          })),
          R.filter(qSetQuestion =>
            showInternalQuestions ? true : !qSetQuestion.is_internal
          )
        )(q.question_set.questions);
      }),
      R.filter(q => q.question_set),
      R.prop("questions")
    )(variant);
    const questionSetQuestionIds = R.map(R.prop("id"))(questionSetQuestions);

    // format non-question set questions
    const nonQuestionSetQuestions = R.compose(
      R.map(q => ({
        ...q.question,
        parentSort: q.order,
        childSort: null,
        value: answers[q.question.id],
        required: q.required
      })),
      R.filter(q => (showInternalQuestions ? true : !q.question.is_internal)),
      R.filter(
        q => !q.question_set && !questionSetQuestionIds.includes(q.question_id)
      ),
      R.prop("questions")
    )(variant);

    const formattedQuestions = R.compose(
      R.map(q => formatQuestion(q)),
      R.sortBy(R.prop("parentSort")),
      R.sortBy(R.prop("childSort"))
    )(R.concat(questionSetQuestions, nonQuestionSetQuestions));

    return formattedQuestions;
  }
);

export const getShowFooterBorder = createSelector(
  getQuestions,
  getters.hasSingleVariant,
  (questions, hasSingleVariant) => {
    if (!hasSingleVariant || questions.length) {
      return true;
    }
    return false;
  }
);

export const getIsValid = createSelector(
  getters.remaining,
  getters.selectedVariants,
  getters.isValid,
  getQuantityAndCost,
  getQuestions,
  (remaining, selectedVariants, isValidCheck, qtyAndCost, questions) => {
    // check limits
    const variantId = Object.keys(selectedVariants)[0];
    if (variantId) {
      const quantity =
        selectedVariants[variantId] >= 0 ? selectedVariants[variantId] : 0;

      if (remaining !== undefined && quantity > remaining) return false;
    }

    // check questions
    let invalidAnswers = [];
    const requiredQuestions = R.filter(R.propEq("required", true))(questions);
    if (requiredQuestions.length) {
      invalidAnswers = R.compose(
        R.filter(v => !v),
        R.map(q => isValid(q.value, q.type))
      )(requiredQuestions);
    }

    return isValidCheck && qtyAndCost.qty > 0 && !invalidAnswers.length;
  }
);

export const getEventDetailsToUse = createSelector(
  getEventDetails,
  getSubmissionEventDetails,
  (eventDetails, submissionEventDetails) => {
    return eventDetails && eventDetails.id
      ? eventDetails
      : submissionEventDetails;
  }
);
