import { get } from "lodash";

export const searchTerm = (state, moduleId) =>
  get(state, `modules.records.settings.search[${moduleId}]`, "");
export const selectedRows = (state, moduleId) =>
  get(state, `modules.records.settings.selectedRows[${moduleId}]`, []);
export const tableLayout = (state, moduleId) =>
  get(state, `modules.records.settings.tableLayout[${moduleId}]`, {});
export const tableLayoutOptions = (state, moduleId) =>
  get(tableLayout(state, moduleId), "options", {});
export const view = (state, moduleId) =>
  get(state, `modules.records.settings.view[${moduleId}]`, "");
