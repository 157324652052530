import React, { useMemo } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import * as R from "ramda";
import { debounce } from "lodash";

import { noop } from "utils/General";

import { actions, getters } from "ui-kit/SearchBar";
import { actions as FocusActions, getters as FocusGetters } from "ui-kit/Focus";

import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";

import SelectFilter from "ui-kit/SelectFilter/View";
import {
  Div,
  BigTextInput,
  MediumTextInput,
  SearchIcon,
  CloseIcon
} from "components/Base";

const decorate = R.compose(
  connect(
    (state, props) => ({
      searchTerm: getters.searchTerm(state, props),
      isActive: FocusGetters.isActive(state, props)
    }),
    bindInstance({
      onSearch: actions.setSearchTerm,
      focus: FocusActions.setIsActive,
      clearSearch: actions.clearSearch
    })
  ),
  WithThemeConsumer({ variant: VARIANTS.BACKGROUND })
);

const input = {
  big: BigTextInput,
  medium: MediumTextInput
};

const SearchBar = ({
  searchTerm = "",
  onSearch = noop,
  placeholder = "Search",
  size = "big",
  instanceId, // eslint-disable-line no-unused-vars
  isActive = false,
  focus = noop,
  filters = [],
  Th = defaultTh,
  showClear = true,
  maxLength = 32,
  placeholderWithDots = true,
  clearSearch,
  ...styleProps
}) => {
  const { bc, buttonColor, ...otherStyles } = styleProps;

  const onSearchDB = useMemo(() => debounce(v => onSearch(v), 250), []);

  return (
    <Div
      bra={2}
      shadow={4}
      onFocus={() => focus(true)}
      onBlur={() => focus(false)}
      {...Th(
        ["bg", "bc"],
        {
          bra: 2,
          shadow: 4,
          display: "row.flex-start.stretch",
          active: isActive,
          bc: bc ? (isActive ? bc : "neutral2") : undefined,
          ...otherStyles
        },
        { overflow: "hidden" }
      )}
    >
      {R.not(R.isEmpty(filters)) ? (
        <SelectFilter
          variant={Th.negativeVariant}
          pill={R.prop("pill", otherStyles)}
          filters={filters}
          ml={1}
          mt={1}
        />
      ) : null}
      <Div flex={1} display="row">
        {React.createElement(input[size] || BigTextInput, {
          placeholder: `${placeholder}${placeholderWithDots ? "..." : ""}`,
          continuous: true,
          value: searchTerm,
          flex: 1,
          maxLength,
          onChange: onSearchDB,
          ...Th(["color"], {
            flex: 1
          })
        })}
      </Div>
      {showClear && searchTerm.length ? (
        <Div
          onClick={clearSearch}
          {...Th(["bg"], {
            variant: Th.accentVariant,
            display: "row.center.center",
            width: 50,
            bg: buttonColor || undefined
          })}
        >
          <CloseIcon
            sizeWFS={5}
            {...Th(["color"], { variant: Th.accentVariant })}
          />
        </Div>
      ) : (
        <Div width={50} display="row.center.center">
          <SearchIcon sizeWFS={5} {...Th(["color"])} />
        </Div>
      )}
    </Div>
  );
};

export default decorate(SearchBar);
