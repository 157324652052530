import * as R from "ramda";
import { createSelector } from "reselect";

import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import jQuery from "jquery";
import isAdminField from "components/Event/FormsV2/Utils/isAdminField";

import { get, debounce, reduce } from "lodash";

// @NOTE: Comment until until we need this
// import ButtonEditColumns from 'components/Global/Table3/ButtonEditColumns';

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { noop } from "utils/General";

import { SubformTable } from "Submission/Subform/View";

const getMenuHandlers = createSelector(
  R.propOr(noop, "handleToggleRequiredField"),
  toggleRequiredField => ({
    toggleRequiredField
  })
);

const styles = {
  editingWrapper: {
    marginTop: 35,
    marginBottom: 60
  },
  fillingOutWrapper: {
    marginBottom: 0
  }
};

// @listensToClickOutside
@CSSModules(css)
class TableView extends Component {
  constructor(props) {
    super(props);
    this.handleResize = debounce(this.handleResize, 500);

    this.state = {
      zoomMode: false,
      columnWidthMap: {},
      documentWidth: this.getDocumentWidth(),
      height: 500,
      selectedRows: [],
      currentScroll: ""
    };

    this.props.addTableId(props.field.id);
  }

  /**
   * ui helpers
   */
  @autobind
  toggleZoom() {
    const elm =
      document.getElementById(this.props.scrollTopId) ||
      document.documentElement;
    this.setState(
      {
        zoomMode: !this.state.zoomMode,
        lastScroll: !this.state.zoomMode ? elm.scrollTop : this.state.lastScroll
      },
      () => {
        if (this.state.zoomMode) {
          elm.scrollTop = 0;
          elm.style.overflow = "hidden";
        } else {
          elm.scrollTop = this.state.lastScroll;
          elm.style.overflow = "auto";
        }
      }
    );
  }

  @autobind
  getDocumentWidth() {
    return jQuery(".page-form-content").width();
  }

  @autobind
  handleResize() {
    this.setState({
      documentWidth: this.getDocumentWidth()
    });
  }

  getTableWidth(fields) {
    return reduce(
      fields,
      (sum, field) => {
        const width =
          isAdminField(field) && !this.props.showAdminFields
            ? 0
            : this.getFieldWidth(field);
        return sum + width;
      },
      0
    );
  }

  getFieldWidth(field) {
    return (
      get(this.props, `preferences.field_widths[${field.id}]`) ||
      get(field, "settings.width") ||
      this.state.columnWidthMap[field.id] ||
      175
    );
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    this.handleResize.cancel();
    window.removeEventListener("resize", this.handleResize);
  }

  handleToggleRequiredField = ({ subformFieldId, fieldId }) => {
    const subformField = this.props.parentForm.fields.find(
      f => f.id === subformFieldId
    );
    const field = subformField.subform.form.fields.find(f => f.id === fieldId);
    const sectionId = subformField.subform.form.fields_grouped_by_section.find(
      section => !!section.fields.find(sf => sf.id === field.id)
    ).id;

    this.props.updateSubformField({
      isRequired: !field.is_required,
      formId: subformField.subform.form.id,
      subformId: subformFieldId,
      sectionId,
      fieldId
    });
  };

  render() {
    let zoomIcon;
    const menuHandlers = getMenuHandlers(this);

    if (this.state.zoomMode) {
      zoomIcon = (
        <div styleName="gridButtonClose" key="expand" onClick={this.toggleZoom}>
          <i className="material-icons">clear</i>
        </div>
      );
    } else {
      zoomIcon = (
        <div
          styleName="gridButtonExpand"
          key="expand"
          onClick={this.toggleZoom}
        >
          <i className="material-icons">code</i>
          expand
        </div>
      );
    }

    return (
      <div
        styleName={this.props.isEditing ? "borderEditing" : "border"}
        style={
          !this.state.zoomMode && this.props.isEditing
            ? styles.editingWrapper
            : {}
        }
      >
        <div
          styleName={this.state.zoomMode ? "zoomWrapper" : "noZoomWrapper"}
          style={this.props.isFillingFormOut ? styles.fillingOutWrapper : {}}
          className="react-grid-Lennd-Form-Wrapper show-drag-handle"
          data-grid-id={this.props.field.id}
        >
          {zoomIcon}
          <div
            styleName={this.state.zoomMode ? "zoomWrapperInner" : ""}
            style={{ width: "100%" }}
          >
            <div
              className={`react-grid-Lennd-Form react-grid-Lennd-Form_${this.props.field.id}`}
              styleName="container"
            >
              <div
                style={{ overflowY: "auto", overflowX: "hidden" }}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                {/* context for this table is in components/Event/Formv2/Sections/Section/index */}
                <SubformTable
                  instanceId={this.props.field.id}
                  readOnly={this.props.isResponseLocked}
                  submission={this.props.submission}
                  field={this.props.field}
                  mode={this.props.mode}
                  menuHandlers={menuHandlers}
                  showHeaders
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TableView.propTypes = {
  updateFieldWidth: PropTypes.func.isRequired,
  updateDefaultFieldWidth: PropTypes.func.isRequired,
  addNewRow: PropTypes.func.isRequired,
  addSubformField: PropTypes.func.isRequired,
  updateSubformField: PropTypes.func.isRequired,
  updateSubformFieldWidth: PropTypes.func.isRequired,
  allowNewRows: PropTypes.bool.isRequired,
  fieldsGroupedBySection: PropTypes.array.isRequired,
  field: PropTypes.object.isRequired,
  deleteField: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  formId: PropTypes.string.isRequired,
  getRowMetaData: PropTypes.func.isRequired,
  headers: PropTypes.array.isRequired,
  hideModal: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isViewingApproval: PropTypes.bool.isRequired,
  isPreviewing: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isFillingFormOut: PropTypes.bool.isRequired,
  isResponseLocked: PropTypes.bool.isRequired,
  recipient: PropTypes.object.isRequired,
  recipientId: PropTypes.number.isRequired,
  removeSelectedRows: PropTypes.func.isRequired,
  duplicateRow: PropTypes.func.isRequired,
  response: PropTypes.object,
  responseId: PropTypes.number.isRequired,
  rows: PropTypes.object.isRequired,
  scrollParent: PropTypes.object.isRequired,
  showEditColumnModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  updateFormValue: PropTypes.func.isRequired,
  updateFormValues: PropTypes.func.isRequired,
  eventDetails: PropTypes.object.isRequired,
  styles: PropTypes.object,
  showRejectedRowDeletionDialog: PropTypes.func.isRequired,
  showRejectedCellUpdateDialog: PropTypes.func.isRequired,
  showAdminFields: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  fieldRelationships: PropTypes.array,
  mappedFields: PropTypes.array,
  getColumnModalFields: PropTypes.func,
  submission: PropTypes.object.isRequired,
  parentForm: PropTypes.object.isRequired
};

export default TableView;
