import PropTypes from "prop-types";
import React, { Component } from "react";
import Popover from "@lennd/material-ui/Popover";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";

class StatusFilter extends Component {
  state = { showPopover: false };

  onRequestClose = () => this.setState({ showPopover: false });

  handleClick = e => {
    e.stopPropagation();
    return this.setState({ showPopover: true });
  };

  handleMenuChange = (e, val) => {
    switch (val) {
      case "all":
        this.props.onMenuSelect(undefined);
        break;
      default:
        this.props.onMenuSelect(val);
    }
    this.onRequestClose();
  };

  render() {
    return (
      <div>
        <span
          onClick={this.handleClick}
          ref={ref => {
            this.anchor = ref;
          }}
        >
          {this.props.children}
        </span>
        <Popover
          anchorEl={this.anchor}
          anchorOrigin={{ horizontal: "left", vertical: "top" }}
          onClose={this.onRequestClose}
          open={this.state.showPopover}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
        >
          <Menu desktop onChange={this.handleMenuChange}>
            <MenuItem primaryText="All" value="all" />
            <MenuItem primaryText="Approved" value="approved" />
            <MenuItem primaryText="Pending" value="pending" />
          </Menu>
        </Popover>
      </div>
    );
  }
}

StatusFilter.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onMenuSelect: PropTypes.func.isRequired
};

export default StatusFilter;
