import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import Loading from "ui-kit/Loading";

import {
  Div,
  CloseIcon,
  MediumInsetInput,
  MediumOutlineButton
} from "components/Base";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";

import { actions, getters } from "../model";

import SearchBar from "ui-kit/SearchBar/View";
import Table, { TableWrapper } from "ui-kit/Table/View";
import PaginationBar from "ui-kit/PaginationBar/View/Slim";
import AddContentModal from "EventLight/Content/AddContentModal/View";

import { PAGE_OPTIONS, MEDIA_CONTENT_INSTANCE_ID } from "../constants";

const decorate = connect(
  (state, props) => ({
    loading: getters.loading(state, props),
    content: getters.content(state, props),
    pagination: getters.pagination(state, props),
    url: getters.url(state, props),
    showModal: getters.showModal(state, props)
  }),
  bindInstance({
    init: actions.init,
    hideModal: actions.hideModal,
    save: actions.save,
    setUrl: actions.setUrl
  })
);

const Body = ({
  init,
  loading,
  instanceId,
  hideModal,
  pagination,
  save,
  setUrl,
  url,
  accept,
  showModal = false
}) => {
  useEffect(() => {
    if (showModal) {
      init(accept);
    }
  }, [accept, showModal]);

  return (
    <MiniModalWrapper
      showModal={showModal}
      showHeader={false}
      style={{
        width: 800,
        maxHeight: "550px",
        overflowY: "auto",
        backgroundColor: "white",
        padding: 20,
        height: "auto"
      }}
      zIndex={1000}
      bg="white"
    >
      <Div>
        <Div style={{ position: "relative" }} display="row.center.center">
          <Div style={{ marginBottom: 20 }} fw={3} fs={4} color="black">
            Select Content
          </Div>
          <Div
            onClick={hideModal}
            style={{ position: "absolute", right: 0 }}
            height={1}
          >
            <CloseIcon color="neutral6" size={20} />
          </Div>
        </Div>

        <Div
          style={{ marginBottom: 20, cursor: "pointer" }}
          id="dropzone-container"
        ></Div>

        <Div style={{ marginBottom: 20 }} display="row.flex-start.center">
          <MediumInsetInput
            width={1}
            style={{ marginRight: 5 }}
            continuous
            type="url"
            onChange={setUrl}
            value={url}
            placeholder="Or link to an external file (e.g. YouTube, Dropbox, Google Doc) by pasting the URL here"
          />
          <MediumOutlineButton onClick={save}>
            Add external file
          </MediumOutlineButton>
        </Div>

        <Div style={{ marginBottom: 20 }}>
          <SearchBar instanceId={instanceId} placeholder="Search content" />
        </Div>
        <Div>
          {loading ? (
            <Loading />
          ) : (
            <>
              <TableWrapper
                instanceId={instanceId}
                style={{ flex: 1, overflowY: "inherit" }}
                innerStyle={{ overflowY: "inherit" }}
              >
                <Table instanceId={instanceId} showCheckbox={false} />
              </TableWrapper>
              <PaginationBar
                style={{
                  width: "100%",
                  color: "#333333",
                  fontSize: "16px",
                  letterSpacing: "0px"
                }}
                pageSizeOptions={PAGE_OPTIONS}
                total={pagination.total}
                rowsText={pagination.total === 1 ? "item" : "items"}
                instanceId={MEDIA_CONTENT_INSTANCE_ID}
              />
            </>
          )}
        </Div>
      </Div>
      <AddContentModal />
    </MiniModalWrapper>
  );
};

export default decorate(Body);
