import React, { Fragment } from "react";
import { connect } from "react-redux";
import { getters, actions } from "Forms/WizardModal";

import SelectMethod from "./SelectMethod";
import SelectRecordTypes from "./SelectRecordTypes";
import SelectFields from "./SelectFields";
import CollectFields from "./CollectFields";
import ReviewForm from "Forms/WizardModal/View/ReviewForm";
import SelectPasses from "Forms/WizardModal/View/Passes/SelectPasses";

import { APPLICATIONS_PAGES } from "Forms/WizardModal/constants";
import {
  shouldEnableSaveApplicationForm,
  shouldShowApplicationPasses
} from "Forms/WizardModal/selectors";

const decorate = connect(
  state => ({
    selectedApplications: getters.selectedApplications(state),
    collectFieldsEnabled: getters.collectFieldsEnabled(state),
    saveBtnEnabled: shouldEnableSaveApplicationForm(state),
    shouldShowApplicationPasses: shouldShowApplicationPasses(state)
  }),
  {
    passesGoBack: () =>
      actions.setSelectedApplications(APPLICATIONS_PAGES.SELECT_FIELDS),
    passesGoNext: () =>
      actions.setSelectedApplications(APPLICATIONS_PAGES.REVIEW),
    reviewGoBack: shouldShowApplicationPasses =>
      actions.setSelectedApplications(
        shouldShowApplicationPasses
          ? APPLICATIONS_PAGES.SELECT_PASSES
          : APPLICATIONS_PAGES.SELECT_FIELDS
      ),
    saveForm: actions.saveApplicationForm
  }
);

const Pages = ({
  handlers,
  selectedApplications,
  shouldShowApplicationPasses,
  reviewGoBack,
  passesGoBack,
  passesGoNext,
  saveForm,
  saveBtnEnabled
}) => {
  if (selectedApplications === APPLICATIONS_PAGES.SELECT_FIELDS) {
    return <SelectFields handlers={handlers} />;
  } else if (selectedApplications === APPLICATIONS_PAGES.SELECT_ACCOUNTS) {
    return <SelectRecordTypes handlers={handlers} />;
  } else if (selectedApplications === APPLICATIONS_PAGES.SELECT_PASSES) {
    return (
      <SelectPasses
        steps={["Step 1", "Step 2", "Step 3", "Step 4"]}
        handlers={handlers}
        goBack={passesGoBack}
        goToNextPage={passesGoNext}
      />
    );
  } else if (selectedApplications === APPLICATIONS_PAGES.REVIEW) {
    return (
      <ReviewForm
        steps={["Step 1", "Step 2", "Step 3", "Step 4"]}
        handlers={handlers}
        goBack={() => reviewGoBack(shouldShowApplicationPasses)}
        saveForm={saveForm}
        saveBtnEnabled={saveBtnEnabled}
      />
    );
  }
  return <SelectMethod handlers={handlers} />;
};

const Applications = ({
  handlers,
  selectedApplications,
  collectFieldsEnabled,
  reviewGoBack,
  passesGoBack,
  passesGoNext,
  saveForm,
  saveBtnEnabled,
  shouldShowApplicationPasses
}) => (
  <Fragment>
    {collectFieldsEnabled && <CollectFields handlers={handlers} />}
    <Pages
      {...{
        handlers,
        selectedApplications,
        reviewGoBack,
        passesGoBack,
        passesGoNext,
        saveForm,
        saveBtnEnabled,
        shouldShowApplicationPasses
      }}
    />
  </Fragment>
);

export default decorate(Applications);
