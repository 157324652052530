import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";

class FormBaseInput extends Component {
  componentDidMount() {
    this.setState({
      isValid: true
    });
    if (typeof this.props.register === "function") {
      this.props.register(this.validate);
    }
  }

  @autobind
  async validate() {
    const isValid = await this.isValid();
    this.setState({
      isValid
    });
    return isValid;
  }

  @autobind
  async save(id, value) {
    const isValid = await this.validate();
    this.setState({
      isValid
    });
    this.props.updateFormValue(this.props.field.id, value);
    return isValid;
  }

  componentWillUnmount() {
    if (typeof this.props.unregister === "function") {
      this.props.unregister(this.validate);
    }
  }
}

FormBaseInput.propTypes = {
  field: PropTypes.object.isRequired,
  updateFormValue: PropTypes.func.isRequired,
  unregister: PropTypes.func,
  register: PropTypes.func
};

export default FormBaseInput;
