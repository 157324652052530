import React, { useState } from "react";
import { Div, MediumFilledButton, MediumTextButton } from "components/Base";
import * as R from "ramda";
import moment from "moment";
import { connect } from "react-redux";
import { actions } from "../../model";

import { PAGE_ACTIONS } from "../../constants";

const decorator = connect(
  null,
  { executeAction: actions.executeAction }
);

const isPastSession = (date, hours) => {
  const time = hours.split("-")[0].trim();
  const sessionDate = moment(`${date} ${time}`, "YYYY-MM-DD, h:mm A");
  return sessionDate.isBefore(moment());
};

const RenderDayButtons = ({ dates, selectedDate, onSelectDate }) => (
  <Div>
    {R.map(date => {
      if (selectedDate.date === date.date) {
        return (
          <MediumFilledButton
            key={date.date}
            bg="#0A6EF4"
            mr={2}
            onClick={() => onSelectDate(date)}
          >
            {moment(date.date).format("ddd D")}
          </MediumFilledButton>
        );
      } else {
        return (
          <MediumTextButton
            key={date.date}
            mr={2}
            color="#0A6EF4"
            onClick={() => onSelectDate(date)}
          >
            {moment(date.date).format("ddd D")}
          </MediumTextButton>
        );
      }
    }, dates)}
  </Div>
);

const Sessions = ({ content, executeAction }) => {
  const [selectedDate, setSelectedDate] = useState(content[0]);

  return (
    <Div>
      <RenderDayButtons
        dates={content}
        selectedDate={selectedDate}
        onSelectDate={setSelectedDate}
      />
      <Div>
        {R.map(date => {
          const pastSession = isPastSession(
            selectedDate.date,
            date.date_label || "12am"
          );

          return (
            <Div
              key={date.id}
              style={{ padding: 17, cursor: "pointer", borderRadius: 4 }}
              bg={{ default: "neutral0", hover: "neutral1" }}
              onClick={() =>
                executeAction({
                  type: PAGE_ACTIONS.OPEN_SESSION,
                  payload: date
                })
              }
            >
              <Div
                color={pastSession ? "neutral4" : "black"}
                fs={3}
                fw={3}
                truncate
                width={0.9}
              >
                {date.name}
              </Div>
              <Div color={pastSession ? "neutral4" : "black"} fs={2}>
                {date.date_label}
              </Div>
            </Div>
          );
        }, R.propOr([], "sessions", selectedDate))}
      </Div>
    </Div>
  );
};

export default decorator(Sessions);
