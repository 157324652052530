import { registerError } from "redux/modules/errors/actions";
import { RECEIVE } from "./constants";
import api from "./api";

export function getUsers(orgId) {
  return async (dispatch, getState) => {
    try {
      const data = await api.get(getState().user.user.credentials, orgId);
      dispatch({
        type: RECEIVE,
        payload: data.payload
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting organization users"
          }
        ])
      );
    }
  };
}

export function addExistingUser(data) {
  return async (dispatch, getState) => {
    try {
      return await api.post(getState().user.user.credentials, data);
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding user to organization"
          }
        ])
      );
    }
  };
}

export function updateUser(data) {
  return async (dispatch, getState) => {
    try {
      return await api.put(getState().user.user.credentials, data);
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating organization user"
          }
        ])
      );
    }
  };
}

export function removeUser(data) {
  return async (dispatch, getState) => {
    try {
      return await api.delete(getState().user.user.credentials, data);
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred removing organization user"
          }
        ])
      );
    }
  };
}

export function resendInvite(data) {
  return async (dispatch, getState) => {
    try {
      return await api.resendInvite(getState().user.user.credentials, data);
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred resending invite to user"
          }
        ])
      );
    }
  };
}
