import { createModule } from "redux-mvc";

import model from "./model";
import sagas from "./sagas";

import searchBar from "ui-kit/SearchBar";
import tableModule from "ui-kit/Table";
import paginationModule from "ui-kit/PaginationBar";
import addContentModalModule from "EventLight/Content/AddContentModal";

const module = createModule(model);

module.setRootSaga(sagas);

module.plugModule(searchBar);
module.plugModule(tableModule);
module.plugModule(paginationModule);
module.plugModule(addContentModalModule);

export default module;
