import * as R from "ramda";

import { createSelector } from "reselect";

import { ACTION_TYPES } from "ui-kit/Table/constants";

import { getters } from "Portal/PortalForm/model";

import { ROW_ACTIONS_ITEMS } from "Portal/PortalForm/constants";

export const getRowActions = R.always([
  {
    id: ROW_ACTIONS_ITEMS.OPEN_SUBMISSION_MODAL,
    type: ACTION_TYPES.ITEM,
    name: "Open Submission Modal",
    leftIcon: "open_in_new"
  },
  {
    id: ROW_ACTIONS_ITEMS.EDIT,
    type: ACTION_TYPES.ITEM,
    name: "Edit",
    leftIcon: "edit"
  },
  {
    id: ROW_ACTIONS_ITEMS.DELETE,
    type: ACTION_TYPES.ITEM,
    name: "Delete",
    leftIcon: "delete"
  }
]);

export const getDescription = createSelector(
  getters.data,
  getters.viewMore,
  (data, viewMore) => {
    const description = R.propOr("", "description", data);
    return R.length(description) > MAX_LIMIT_OF_CHARACTERS && !viewMore
      ? description.slice(0, MAX_LIMIT_OF_CHARACTERS) + "..."
      : description;
  }
);

export const getShouldShowReadMoreButton = createSelector(
  getters.data,
  data => R.length(R.propOr("", "description", data)) > MAX_LIMIT_OF_CHARACTERS
);

export const getTextForViewMore = createSelector(
  getShouldShowReadMoreButton,
  getters.viewMore,
  (showButton, viewMore) => {
    if (showButton) {
      return viewMore ? "View less" : "View more";
    }
    return "";
  }
);

export const getIsClosed = createSelector(
  getters.data,
  data => data.due_label === "Closed"
);

export const getBlockSubmit = createSelector(
  getters.data,
  data =>
    R.propEq("single_submission", true, data) && R.propOr(0, "count", data) >= 1
);
