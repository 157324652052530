import React from "react";

import { connect } from "react-redux";

import { actions, getters } from "../index";
import { capitalizeFirst } from "utils/General";

import { getFields } from "../selectors";

import {
  Div,
  BigClearInput,
  SearchIcon,
  Text0,
  SmallCheckbox,
  Text2,
  EigthSizeDotIcon,
  QuarterSizeDotIcon
} from "components/Base";

import SelectedFields from "./SelectedFields";

const decorate = connect(
  state => ({
    fieldsFilter: getters.fieldsFilter(state),
    fields: getFields(state)
  }),
  {
    setFieldsFilter: actions.setFieldsFilter,
    toggleField: actions.toggleField,
    selectAll: actions.selectAll,
    clearAll: actions.clearAll
  }
);

const Fields = ({
  fieldsFilter,
  setFieldsFilter,
  fields,
  toggleField,
  selectAll,
  clearAll
}) => (
  <Div width={1} height={1}>
    <Div
      height={60}
      width={1}
      bb={1}
      bc="neutral1"
      display="row.flex-start.center"
      px={2}
    >
      <BigClearInput
        LeftIcon={SearchIcon}
        onChange={value => setFieldsFilter(value)}
        width={1}
        continuous
        value={fieldsFilter}
        placeholder="Filter fields..."
      />
    </Div>
    <Div
      display="row.flex-start.center"
      width={1}
      style={{ height: "calc(100% - 60px)" }}
    >
      <Div
        width={467}
        height={1}
        bg="neutral0"
        p={2}
        style={{ overflow: "auto" }}
      >
        <Div display="row.flex-start.center" mt={1} mb={4} ml={1}>
          <Text2
            color="primary7"
            underline
            onClick={() => {
              selectAll(fields);
            }}
          >
            Select All
          </Text2>
          <QuarterSizeDotIcon color="primary7" mx={1} />
          <Text2
            color="primary7"
            underline
            onClick={() => {
              clearAll(fields);
            }}
          >
            Remove All
          </Text2>
        </Div>

        {fieldsFilter && fieldsFilter.length && !fields.length ? (
          <Div p={5}>No fields matched your search.</Div>
        ) : !fields.length ? (
          <Div p={5}>No fields have been added yet.</Div>
        ) : null}
        {fields.map(f => (
          <Div
            width={1}
            bra={1}
            p={1}
            shadow={1}
            mb={2}
            bg={{
              default: "white",
              hover: "neutral1"
            }}
            display="row.flex-start.center"
            onClick={() => toggleField(f.id)}
          >
            <SmallCheckbox selected={f.selected} />
            <Div display="row.space-between.center" flex={1} pl={2}>
              <Div>
                <Text2 bold color="black">
                  {f.name}
                </Text2>
                <Div display="row.flex-start.center">
                  <Text0 color="neutral6" bold>
                    {capitalizeFirst(f.type)} &middot;{" "}
                    {capitalizeFirst(f.source)}
                  </Text0>
                </Div>
              </Div>
            </Div>
          </Div>
        ))}
      </Div>
      <Div width={276} height={1}>
        <SelectedFields />
      </Div>
    </Div>
  </Div>
);

export default decorate(Fields);
