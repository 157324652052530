import React from "react";
import { SubNavWrapper, SubNavLink } from "EventLight/Common/SubNav";
import { TAB_IDS } from "./constants";
import { withRouter } from "react-router";
import CanUserDo from "components/Global/Security/CanUserDo";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

const SubNav = ({ selectedView, router, params, tabs = [] }) => (
  <SubNavWrapper tabs={tabs} supportId="inventory">
    <SubNavLink
      name="Dashboard"
      active={selectedView === TAB_IDS.DASHBOARD}
      onClick={() =>
        router.push({
          pathname: `/event-light/${params.eventId}/inventory`
        })
      }
    />
    <SubNavLink
      name="Manage"
      active={selectedView === TAB_IDS.REQUESTS}
      onClick={() =>
        router.push({
          pathname: `/event-light/${params.eventId}/inventory/requests/all-requests`
        })
      }
    />
    <SubNavLink
      name="Send Emails"
      active={selectedView === TAB_IDS.SEND_EMAILS}
      onClick={() =>
        router.push({
          pathname: `/event-light/${params.eventId}/inventory/send-emails`
        })
      }
    />

    <CanUserDo action={`${STANDARD_MODULE_IDS.inventory.id}_manage`}>
      <SubNavLink
        name="Settings"
        active={[
          TAB_IDS.CATALOG,
          TAB_IDS.QUESTIONS,
          TAB_IDS.APPROVERS
        ].includes(selectedView)}
        onClick={() =>
          router.push({
            pathname: `/event-light/${params.eventId}/inventory/settings`
          })
        }
      />
    </CanUserDo>
  </SubNavWrapper>
);

export default withRouter(SubNav);
