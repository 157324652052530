export const NAMESPACE = "EventLightPassesDashboard";

export const FIELD_OPTIONS = [
  { key: "requested", name: "Requested", visible: true },
  { key: "approved", name: "Approved", visible: true },
  { key: "issued", name: "Issued", visible: true, view: "issued" },
  { key: "budget", name: "Budget", visible: true },
  { key: "remaining", name: "Remaining", visible: true },
  { key: "progress", name: "Progress", visible: true }
];
