import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import isLocked from "components/Event/FormsV2/Overview/utils/isLocked";
import getSubmitter from "components/Event/FormsV2/Utils/getSubmitter";
import { get, debounce, flattenDeep } from "lodash";
import ContextMenu from "components/Event/FormsV2/Overview/SubmissionContextMenu";
import NotFound from "./NotFound";
import SearchInput from "components/Atoms/AsyncSearchInput";
import Feed from "./Feed";
import Fields from "./Fields";
import RequestTable from "./RequestTable";
import Button from "./Button";
import Loading from "components/Global/Loading";
import hiddenFields from "components/Event/FormsV2/Utils/field-blacklist";
import countApprovals from "components/Event/FormsV2/Utils/count-approvals";
import getBadges from "components/Event/FormsV2/Overview/StatusBadges/getBadges";
import getNotEmptyRows from "components/Event/FormsV2/Overview/SubmissionSummary/utils/get-not-empty-rows";
import constructRowData from "components/Event/FormsV2/Utils/constructRowData";
import isSubmissionInReview from "components/Event/FormsV2/Utils/isSubmissionInReview";
import searchTable from "utils/Search/table-search";
import constructSearchObject from "utils/Search/construct-searchable-object";
import getFormVersion from "components/Event/FormsV2/Utils/get-form-version";
import getModuleFieldId from "components/Event/FormsV2/Utils/get-module-field-id";
import { getSubmissionState } from "components/Global/CRM/Forms/utils/utils";
import isRowPendingV2 from "./utils/is-row-pending-forms-v2";
import isRowPendingV3 from "./utils/is-row-pending-forms-v3";
import { APPROVAL } from "utils/system-field-ids";
import ApproversLabel from "components/Global/Approvals/ApproversLabel";
import { parseApproval } from "components/Global/Approvals/utils/approvals-helpers";
import { Div } from "components/Base";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

// Gather all fields (parent + child) into a one level array
function aggregateFields(fields) {
  return fields.reduce(
    (allFields, field) => {
      if (field.type === "subform") {
        allFields.push(...field.subform.form.fields);
      }
      return allFields;
    },
    [...fields, { id: "primaryCollaborator" }]
  );
}

function createSubmissionSearchObjects(fields, values, eventDetails) {
  return flattenDeep(
    fields.filter(field => field.type === "subform").map(field => {
      const subformSubmissions = values[field.id].value.submissions;
      return Object.keys(subformSubmissions).map(key =>
        constructSearchObject(
          field.subform.form.fields,
          subformSubmissions[key].values,
          subformSubmissions[key].id,
          eventDetails
        )
      );
    })
  );
}

class SubmissionSummary extends Component {
  constructor(props) {
    super(props);

    this.search = debounce(this.search, 500);

    this.state = {
      filteredSubmissions: null,
      isSearching: false
    };
  }

  componentDidMount() {
    this.props.getSubmission(this.props.submissionId).then(() => {});
  }

  componentWillUnmount() {
    this.search.cancel();
    if (typeof this.props.onHide === "function") {
      this.props.onHide();
    }
  }

  @autobind
  async onContextMenuChange(submission, event, type) {
    switch (type) {
      case "approve":
        this.reviewSingleSubmission("approve");
        break;
      case "reject":
        this.reviewSingleSubmission("reject");
        break;
      case "approve_remaining":
        this.reviewRemainingSubmissions("approve");
        break;
      case "reject_remaining":
        this.reviewRemainingSubmissions("reject");
        break;
      case "delete":
        await this.props.deleteOverviewSubmission({
          submissionId: submission.id
        });
        this.props.hideModal();
        break;
      case "lock":
      case "unlock":
        this.setLock(!submission.is_locked);
        break;
      default:
        break;
    }
  }

  @autobind
  setLock(lock) {
    this.props.setSubmissionLocked(lock, this.props.submission);
  }

  @autobind
  formatSingleSubmissionStatus() {
    return get(
      this.props.submission,
      ["module_record", "record", "values", APPROVAL, "value", "status"],
      "pending"
    );
  }

  getSingleSubmissionApprovalManifest = () => {
    return get(this.props.submission, [
      "module_record",
      "record",
      "values",
      APPROVAL,
      "value"
    ]);
  };

  reviewSingleSubmission = status => {
    const payload = {
      review: {
        // record
        moduleId: this.props.form.base_module_id,
        recordId: this.props.submission.submission_record_id,
        userId: this.props.user.id,
        // submission
        rowId: this.props.submission.id,
        submissionRecordId: this.props.submission.submission_record_id,
        submissionId: this.props.submission.id,
        response: status
      }
    };

    if (!status) {
      return this.props.removeReviewV3(payload);
    }

    return this.props.createReviewV3(payload);
  };

  @autobind
  bulkCreateReviews(subformId, reviews) {
    switch (getFormVersion(this.props.form)) {
      case 3: {
        return Promise.all(
          reviews.map(review => {
            const submissionRecordId = get(this.props, [
              "submission",
              "values",
              subformId,
              "value",
              "submissions",
              review.submissionId,
              "submission_record_id"
            ]);
            return this.props.createReviewV3({
              review: {
                // record
                moduleId: this.props.form.base_module_id,
                recordId: submissionRecordId,
                userId: this.props.user.id,
                // submission
                rowId: review.submissionId,
                submissionRecordId,
                subformId,
                submissionId: review.submissionId,
                response: review.status
              }
            });
          })
        );
      }
      default:
        return this.props.bulkCreateReviews({
          eventId: this.props.details.id,
          formId: this.props.form.id,
          parentSubmissionId: this.props.submission.id,
          subformId,
          reviews
        });
    }
  }

  @autobind
  async search(fields, rows, term) {
    if (!term) {
      // Bail out if we don't have a search term
      return this.clearSearch();
    }

    this.setState({ isSearching: true });
    const filteredSubmissions = await searchTable(fields, rows, term);
    this.setState({
      filteredSubmissions: filteredSubmissions.map(r => r.id),
      isSearching: false
    });
  }

  @autobind
  clearSearch() {
    this.setState({
      filteredSubmissions: null
    });
  }

  @autobind
  isRowPending(r) {
    const version = getFormVersion(this.props.form);
    switch (version) {
      case 3:
        return isRowPendingV3(r);
      default:
        return isRowPendingV2(r);
    }
  }

  @autobind
  getCountOfRemainingSubmissions() {
    return this.props.fields
      .filter(f => f.type === "subform")
      .reduce((count, form) => {
        const submissions = this.props.submission.values[form.id].value
          .submissions;
        const rows = getNotEmptyRows(
          Object.keys(submissions).map(id => constructRowData(submissions[id])),
          form.subform.form.fields.filter(f => !hiddenFields.includes(f.type))
        );

        const reviews = rows.filter(this.isRowPending);

        return count + reviews.length;
      }, 0);
  }

  @autobind
  reviewRemainingSubmissions(status) {
    this.props.fields.filter(f => f.type === "subform").forEach(form => {
      const submissions = this.props.submission.values[form.id].value
        .submissions;
      const rows = getNotEmptyRows(
        Object.keys(submissions).map(id => constructRowData(submissions[id])),
        form.subform.form.fields.filter(f => !hiddenFields.includes(f.type))
      );

      const reviews = rows.filter(this.isRowPending).map(row => ({
        submissionId: row.id,
        status,
        userId: this.props.user.id
      }));

      this.bulkCreateReviews(form.id, reviews);
    });
  }

  @autobind
  viewSubmission() {
    window.location = `/submissions/${this.props.details.slug}/${
      this.props.form.slug
    }/${this.props.submission.id}`;
  }

  @autobind
  getMetaData(fields, submission, rowData = {}, field, subform) {
    const version = getFormVersion(submission.form);
    switch (version) {
      case 3: {
        return {
          ...rowData,
          meta: {
            readOnly: this.props.readOnly,
            changes: get(submission, "changes", []),
            columnId: field.id,
            columns: fields,
            columnSettings: field.settings,
            contactId: submission.contact_id,
            eventDetails: this.props.details,
            eventId: this.props.details.id,
            formId: this.props.form.id,
            subformId: get(subform, "id"),
            isApprovingForm: true,
            isResponseLocked: submission.is_locked,
            rowId: rowData.id,
            submissionRecordId: get(this.props, [
              "submission",
              "values",
              get(subform, "id"),
              "value",
              "submissions",
              rowData.id,
              "submission_record_id"
            ]),
            submissionId: submission.id,
            moduleId: get(subform, "settings.moduleId"),
            via: "requests",
            viewingContactVia: { viaFormRequests: this.props.form.id }
          },
          helpers: {
            createReview: this.props.createReview,
            dispatch: this.props.dispatch,
            router: this.props.router,
            showModal: this.props.showModal
          }
        };
      }
      default:
        return {
          ...rowData,
          meta: {
            readOnly: this.props.readOnly,
            changes: get(submission, "changes", []),
            columnId: field.id,
            columns: fields,
            columnSettings: field.settings,
            contactId: submission.contact_id,
            eventDetails: this.props.details,
            eventId: this.props.details.id,
            formId: this.props.form.id,
            isApprovingForm: true,
            isResponseLocked: submission.is_locked,
            rowId: rowData.rowId,
            via: "requests",
            viewingContactVia: { viaFormRequests: this.props.form.id }
          },
          helpers: {
            createReview: this.props.createReview,
            dispatch: this.props.dispatch,
            router: this.props.router,
            showModal: this.props.showModal
          }
        };
    }
  }

  renderActions({
    submission,
    readOnly,
    hasSubforms,
    singleSubmissionStatus,
    countOfRemainingSubmissions,
    noActions
  }) {
    if (noActions) {
      return null;
    }
    if (readOnly) {
      return (
        <div styleName="headerActions">
          <div styleName="headerAction" onClick={this.viewSubmission}>
            {isLocked(submission) ? "View Submission" : "Edit Submission"}
          </div>
        </div>
      );
    }
    return (
      <div styleName="headerActions">
        {/* TODO: Implement <div styleName="headerAction">Send Message</div> */}
        <ContextMenu
          source="modal"
          hasSubforms={hasSubforms}
          singleSubmissionStatus={singleSubmissionStatus}
          submission={submission}
          countOfRemainingSubmissions={countOfRemainingSubmissions}
          onMenuChange={(...args) =>
            this.onContextMenuChange(submission, ...args)
          }
        >
          <div styleName="headerAction">
            Actions{" "}
            <i className={["material-icons", css.headerActionMore].join(" ")}>
              keyboard_arrow_down
            </i>
          </div>
        </ContextMenu>
      </div>
    );
  }

  renderFooter({
    readOnly,
    hasSubforms,
    countOfRemainingSubmissions,
    singleSubmissionStatus
  }) {
    if (readOnly) {
      return null;
    }
    if (hasSubforms) {
      if (countOfRemainingSubmissions > 0) {
        return (
          <div styleName="footer">
            <Button
              classNames={{
                container: css.approveRemainingButton,
                label: css.approveRemainingLabel
              }}
              onClick={() => this.reviewRemainingSubmissions("approve")}
            >
              <i
                className={["material-icons", css.approveRemainingLabel].join(
                  " "
                )}
                styleName="buttonIcon"
              >
                done
              </i>
              Approve Remaining
            </Button>
            <Button
              classNames={{ container: css.rejectRemainingButton }}
              onClick={() => this.reviewRemainingSubmissions("reject")}
            >
              Reject Remaining
            </Button>
          </div>
        );
      }
      return (
        <div styleName="footer">
          <Button
            classNames={{
              container: css.allReviewedButton,
              label: css.approveRemainingLabel
            }}
          >
            <i
              className={["material-icons", css.approveRemainingLabel].join(
                " "
              )}
              styleName="buttonIcon"
            >
              done_all
            </i>
            All requests have been reviewed
          </Button>
        </div>
      );
    }

    return (
      <div styleName="footer">
        <Div display="row.flex-start.center">
          <Div fw={3} color="gray9" mr={3} style={{ flexShrink: 0 }}>
            Approval Status:
          </Div>
          <ApproversLabel
            {...{
              ...parseApproval({
                record: {
                  approval_manifest: this.getSingleSubmissionApprovalManifest()
                },
                userId: this.props.user.id
              }),
              approveLineItems: () => this.reviewSingleSubmission("approve"),
              rejectLineItems: () => this.reviewSingleSubmission("reject"),
              removeApproval: () => this.reviewSingleSubmission(null),
              showMenu: true
            }}
          />
        </Div>
      </div>
    );
  }

  resolveSubmitter = () => {
    if (!this.props.collaborators || !this.props.collaborators.length) {
      return "";
    }

    const { account_name, fname, lname } = this.props.collaborators[0];

    if (account_name) {
      return account_name;
    }
    return `${fname} ${lname}`;
  };

  createSubformSubmission = async ({
    formId,
    subformId,
    subformModuleId,
    subformModuleFieldId,
    parentSubmissionId,
    addCollaborator,
    isSubformSubmission
  }) => {
    let submission;
    const version = getFormVersion(this.props.form);

    // v3
    if (version === 3) {
      // create module record
      // @NOTE: We're making the assumption here NOT to create this record
      // as a draft because this modal shouldn't be able to be viewed if parent
      // is a draft
      const submissionModuleRecord = await this.props.addRecord({
        moduleId: subformModuleId,
        record: {
          [subformModuleFieldId]: {
            type: "lookup",
            value: {
              moduleId: this.props.form.base_module_id,
              records: [this.props.submission.submission_record_id]
            }
          }
        },
        options: {
          eventId: this.props.details.id
        }
      });

      submission = await this.props.createSubformSubmission({
        version: 3,
        formId,
        subformId,
        parentSubmissionId,
        addCollaborator,
        isSubformSubmission,
        submissionRecordId: submissionModuleRecord.id
      });
    } else {
      // v2
      submission = await this.props.createSubformSubmission({
        formId,
        subformId,
        parentSubmissionId,
        addCollaborator,
        isSubformSubmission
      });
    }

    return submission;
  };

  render() {
    const {
      submission,
      fields,
      details,
      collaborators,
      readOnly,
      showAdminFields,
      noActions
    } = this.props;
    if (this.props.isFetching) {
      return (
        <div styleName="loading">
          <Loading content="Loading submission..." />;
        </div>
      );
    }

    if (!submission) {
      return <NotFound />;
    }
    const submitterContact = collaborators.find(c => c.is_primary) || {};
    const submitter = getSubmitter(collaborators);
    const values = submission.values;
    const countReviews = countApprovals(submission, fields);
    const subforms = fields.filter(f => f.type === "subform");
    const countOfRemainingSubmissions = this.getCountOfRemainingSubmissions();
    const singleSubmissionStatus = this.formatSingleSubmissionStatus();

    const search = term => {
      const searchObjects = createSubmissionSearchObjects(
        fields,
        submission.values,
        details
      );
      const searchFields = aggregateFields(fields).map(f => f.id);
      this.search(searchFields, searchObjects, term);
    };

    const hasSubforms = Boolean(subforms.length);
    const submissionState = getSubmissionState(submission, fields);

    return (
      <div styleName="container" onMouseDown={e => e.stopPropagation()}>
        <div styleName="header">
          <i
            className="material-icons"
            onClick={this.props.hideModal}
            styleName="closeIcon"
          >
            close
          </i>
          <div styleName="headerInner">
            <div styleName="headerLeft">
              <div styleName="submitterTitle">
                <div title={submitter} styleName="submitter">
                  {submitter}
                </div>
                <i className="material-icons" styleName="lockIconContainer">
                  {isLocked(submission) ? "lock" : "lock_open"}
                </i>
              </div>
              <div styleName="submitterSubtitle">{submitterContact.email}</div>
              {this.renderActions({
                submission,
                readOnly,
                hasSubforms,
                singleSubmissionStatus,
                countOfRemainingSubmissions,
                noActions
              })}
            </div>

            {hasSubforms ? (
              <div styleName="headerRight">
                <div styleName="badgeContainer">
                  {getBadges(submission, fields).map(b => b)}
                  <div styleName="count">
                    <div styleName="countHeader">Remaining</div>
                    <div styleName="countValue">
                      {countReviews.requested -
                        (countReviews.rejected + countReviews.approved)}
                    </div>
                  </div>
                  <div styleName="count">
                    <div styleName="countHeader">Approved</div>
                    <div styleName="countValue">{countReviews.approved}</div>
                  </div>
                  <div styleName="count">
                    <div styleName="countHeader">Rejected</div>
                    <div styleName="countValue">{countReviews.rejected}</div>
                  </div>
                </div>
              </div>
            ) : (
              <div styleName="headerRight">
                <div styleName="badgeContainer">
                  {getBadges(submission, fields).map(b => b)}
                </div>
              </div>
            )}
          </div>
        </div>
        <div styleName="content">
          <div styleName="contentTopWrapper">
            <Fields
              {...{
                readOnly,
                showAdminFields,
                getMetaData: this.getMetaData
              }}
            />
            <Feed
              submissionId={this.props.submissionId}
              commentsOnly={readOnly}
              messagesFor={readOnly ? "admin" : this.resolveSubmitter()}
            />
          </div>

          {hasSubforms ? (
            <div styleName="requests">
              <div styleName="requestsHeader">
                <div styleName="requestsTitle">Requests</div>
                <SearchInput
                  style={{
                    border: "1px solid #D0D0D0",
                    color: "#535353",
                    background: "#fff",
                    borderRadius: "4px",
                    margin: 0
                  }}
                  placeholder="Search"
                  onChange={search}
                  onClear={this.clearSearch}
                  isProcessing={this.state.isSearching}
                />
              </div>

              {subforms.map(form => {
                const submissions = values[form.id].value.submissions;
                return (
                  <RequestTable
                    key={form.id}
                    ref={`subform_${form.id}`}
                    readOnly={readOnly}
                    filteredSubmissions={this.state.filteredSubmissions}
                    saveCell={({ fieldId, submissionId, value }) =>
                      this.props.addSubformValue({
                        version: getFormVersion(form.subform.form),
                        submissionRecordId:
                          submissions[submissionId].submission_record_id,
                        moduleFieldId: getModuleFieldId(
                          fieldId,
                          form.subform.form.fields
                        ),
                        subformFieldId: form.id,
                        submissionId,
                        fieldId,
                        value,
                        userId: this.props.user.id,
                        parentSubmissionId: this.props.submission.id
                      })
                    }
                    bulkCreateReviews={reviews =>
                      this.bulkCreateReviews(
                        form.id,
                        reviews.map(review => ({
                          ...review,
                          userId: this.props.user.id
                        }))
                      )
                    }
                    createSubformSubmission={() =>
                      this.createSubformSubmission({
                        formId: get(form, "subform.form.id"),
                        subformId: form.id,
                        subformModuleId: get(
                          form,
                          "subform.form.base_module_id"
                        ),
                        subformModuleFieldId: form.module_field_id,
                        parentSubmissionId: this.props.submission.id,
                        addCollaborator: false,
                        isSubformSubmission: true
                      })
                    }
                    cloneSubformSubmissions={(submissionIds, quantity) =>
                      this.props.cloneSubformSubmissions({
                        formId: get(form, "subform.form.id"),
                        subformId: form.id,
                        parentSubmissionId: this.props.submission.id,
                        submissionIds,
                        quantity
                      })
                    }
                    preferences={
                      this.props.preferences[get(form, "subform.form.id")]
                    }
                    setLock={this.setLock}
                    isSubmissionLocked={submission.is_locked}
                    isSubmissionInReview={isSubmissionInReview(
                      countApprovals(submission, this.props.fields)
                    )}
                    form={form}
                    submissionState={submissionState}
                    getMetaData={(columns, rowData, field) =>
                      this.getMetaData(
                        columns,
                        submission,
                        rowData,
                        field,
                        form
                      )
                    }
                    eventDetails={details}
                    title={form.name}
                    showAdminFields={showAdminFields}
                    fields={form.subform.form.fields.filter(
                      f => !hiddenFields.includes(f.type)
                    )}
                    values={Object.keys(submissions).map(id =>
                      constructRowData(submissions[id])
                    )}
                  />
                );
              })}
            </div>
          ) : null}
          {this.renderFooter({
            readOnly,
            hasSubforms,
            countOfRemainingSubmissions,
            singleSubmissionStatus
          })}
        </div>
      </div>
    );
  }
}

SubmissionSummary.propTypes = {
  addSubformValue: PropTypes.func.isRequired,
  bulkCreateReviews: PropTypes.func.isRequired,
  cloneSubformSubmissions: PropTypes.func.isRequired,
  collaborators: PropTypes.array.isRequired,
  createReview: PropTypes.func.isRequired,
  createSubformSubmission: PropTypes.func.isRequired,
  deleteOverviewSubmission: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  getSubmission: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  noActions: PropTypes.bool,
  onHide: PropTypes.func,
  orderId: PropTypes.string,
  preferences: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
  setSubmissionLocked: PropTypes.func.isRequired,
  showAdminFields: PropTypes.bool,
  submission: PropTypes.object.isRequired,
  submissionId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  logAction: PropTypes.func.isRequired,
  createReviewV3: PropTypes.func.isRequired
};

export default CSSModules(SubmissionSummary, css);
