import { createSelector } from "reselect";
import * as R from "ramda";

import { getters } from "Event/MyApprovals/model";
import {
  eventId as getEventId,
  eventDetails as getEventDetails
} from "redux/modules/event/selectors";
import { user as getUser } from "redux/modules/user/selectors";
import moment from "moment";

import {
  CREDENTIAL_TYPE_ID,
  MEAL_TYPE_ID,
  ASSETS_TYPE_ID,
  SPONSORSHIP_TYPE_ID,
  BOOTH_TYPE_ID
} from "utils/item-types";

const getGreetingTime = m => {
  let g;

  if (!m || !m.isValid()) {
    return g;
  }

  const splitAfternoon = 12;
  const splitEvening = 17;
  const currentHour = parseFloat(m.format("HH"));

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    g = "afternoon";
  } else if (currentHour >= splitEvening) {
    g = "evening";
  } else {
    g = "morning";
  }

  return g;
};

export const getUserName = createSelector(
  getUser,
  user => user.fname
);

export const getCountOfPendingApprovals = createSelector(
  getters.myApprovals,
  payload => R.path(["total_pending"])(payload)
);

const getItemTypePath = (typeId, eventId, isLight) =>
  R.propOr(``, typeId)({
    [CREDENTIAL_TYPE_ID]: isLight
      ? `/event-light/${eventId}/passes/requests/my-approvals`
      : `/event/${eventId}/passes/manage/my-approvals`,
    [MEAL_TYPE_ID]: isLight
      ? `/event-light/${eventId}/catering/requests/my-approvals`
      : `/event/${eventId}/catering/manage/my-approvals`,
    [ASSETS_TYPE_ID]: isLight
      ? `/event-light/${eventId}/inventory/requests/my-approvals`
      : `/event/${eventId}/inventory/manage/my-approvals`,
    [SPONSORSHIP_TYPE_ID]: isLight
      ? `/event-light/${eventId}/booths/requests/my-approvals`
      : `/event/${eventId}/booths/manage/my-approvals`,
    [BOOTH_TYPE_ID]: isLight
      ? `/event-light/${eventId}/booths/requests/my-approvals`
      : `/event/${eventId}/booths/manage/my-approvals`
  });

export const getPendingItems = createSelector(
  getters.myApprovals,
  getEventId,
  getEventDetails,
  (payload, eventId, eventDetails) => {
    return {
      itemTypes: R.compose(
        R.map(i => ({
          ...i,
          path: getItemTypePath(i.id, eventId, eventDetails.is_light),
          groups: R.sortBy(g => (g.pending ? `0_${g.order}` : `1_${g.order}`))(
            i.groups
          )
        })),
        R.filter(i => i.pending > 0),
        R.pathOr([], ["item_types"])
      )(payload),
      modules: R.compose(
        R.map(i => ({
          ...i,
          path: eventDetails.is_light
            ? i.form_id
              ? `/event-light/${eventId}/forms/${i.form_id}/results`
              : `/event-light/${eventId}/custom/${i.id}/manage`
            : `/event/${eventId}/module/${i.id}`
        })),
        R.filter(i => i.pending > 0),
        R.pathOr([], ["modules"])
      )(payload),
      document_requests: R.compose(
        R.map(i => ({
          ...i,
          path: `/event-light/${eventId}/file-requests/${i.id}/results`
        })),
        R.filter(i => i.pending > 0),
        R.pathOr([], ["document_requests"])
      )(payload)
    };
  }
);

export const getApprovedItems = createSelector(
  getters.myApprovals,
  getEventId,
  getEventDetails,
  (payload, eventId, eventDetails) => {
    return {
      itemTypes: R.compose(
        R.map(i => ({
          ...i,
          path: getItemTypePath(i.id, eventId, eventDetails.is_light)
        })),
        R.filter(i => i.pending <= 0),
        R.pathOr([], ["item_types"])
      )(payload),
      modules: R.compose(
        R.map(i => ({
          ...i,
          path: eventDetails.is_light
            ? i.form_id
              ? `/event-light/${eventId}/forms/${i.form_id}/results`
              : `/event-light/${eventId}/custom/${i.id}/manage`
            : `/event/${eventId}/module/${i.id}/dashboard`
        })),
        R.filter(i => i.pending <= 0),
        R.pathOr([], ["modules"])
      )(payload),
      document_requests: R.compose(
        R.map(i => ({
          ...i,
          path: `/event-light/${eventId}/file-requests/${i.id}/results`
        })),
        R.filter(i => i.pending <= 0),
        R.pathOr([], ["document_requests"])
      )(payload)
    };
  }
);

export const getGreeting = createSelector(
  getUser,
  user => {
    const greetingTime = getGreetingTime(moment(new Date()));
    const name = user.fname ? `, ${user.fname}.` : null;
    return `Good ${greetingTime}${name}`;
  }
);
