import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduce } from "lodash";
import SummaryPanel from "./SummaryPanel";
import * as modalActions from "redux/modules/modal/actions";
import * as widgetActions from "redux/modules/dashboards/widgets/actions";
import * as dashboardActions from "redux/modules/dashboards/actions";
import { updateVariant } from "redux/modules/items/item-variants/actions";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

function mapStateToProps(state, props) {
  const widgets = props.widgets;
  const eventDetailsCredentials = props.credentials;
  const canDo = canEventUserDo(state);

  return {
    widgets: reduce(
      widgets,
      (updatedWidgets, widget) => {
        if (
          widget.settings &&
          widget.settings.is_default &&
          widget.type !== "credentials-summary-v3"
        ) {
          widget.settings.credentials = eventDetailsCredentials.map(c => c.id);
        }
        updatedWidgets[widget.id] = widget;
        return updatedWidgets;
      },
      {}
    ),
    eventDetailsCredentials,
    canUpdateBudget: canDo(`${STANDARD_MODULES.credentials.id}_manage`)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...dashboardActions,
      ...modalActions,
      ...widgetActions,
      updateVariant
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SummaryPanel);
