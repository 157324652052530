import * as R from "ramda";
import React from "react";
import * as STANDARD_MODULE_FIELDS from "@lennd/value-types/src/constants/standard-module-field-ids";

import AllocatedItems from "./AllocatedItems";
import Approval from "./Approval";
import AccountList from "./AccountList";
import AccountName from "./AccountName";
import ContactName from "./ContactName";
import CateringDietaryRestrictions from "./CateringDietaryRestrictions";
import DocumentRequest from "./DocumentRequest";
import EventName from "./EventName";
import EventUsers from "./EventUsers";
import EmsStatus from "./EmsStatus";
import Text from "./Text";
import Checkbox from "./Checkbox";
import Dropdown from "./Dropdown";
import Lookup from "./Lookup";
import EventDays from "./EventDays";
import Form from "./Form";
import Countries from "./Countries";
import Currency from "./Currency";
import Datetime from "./Datetime";
import Date from "./Date";
import File from "./File";
import GeneralActions from "./GeneralActions";
import PrimaryContacts from "./PrimaryContacts";
import User from "./User";
import ItemGroup from "./ItemGroup";
import ItemBlock from "./ItemBlock";
import PeopleBlock from "./PeopleBlock";
import Source from "./Source";
import Submission from "./Submission";
import EventbriteOrder from "./EventbriteOrder";
import EventList from "./EventList";
import OrderName from "./OrderName";
import OrderItem from "./OrderItem";
import OrderNumbers from "./OrderNumbers";
import OrderItemDetails from "./OrderItemDetails";
import Payment from "./Payment";
import AttendeePhoto from "./AttendeePhoto";
import Zone from "./Zone";
import OrderActions from "./OrderActions";
import PortalPeopleActions from "./PortalPeopleActions";
import Schedule from "./Schedule";
import SubmissionActions from "./SubmissionActions";
import ProfileApproval from "./ProfileApproval";
import SyncedToFuzion from "./SyncedToFuzion";
import ActionsButtonCustom from "./ActionsButtonCustom";
import Status from "./Status";
import Signature from "./Signature";
import Title from "./Title";
import HasPortalAccess from "./HasPortalAccess";
import HealthPass from "./HealthPass";
import IframeOverlay from "./IframeOverlay";
import OrderStatus from "./OrderStatus";
import Task from "./Task";

const resolveByType = {
  "account-list": AccountList, //
  "account-name": AccountName,
  "actions-button-custom": ActionsButtonCustom, //
  "allocated-meals": AllocatedItems, //
  "allocated-passes": AllocatedItems,
  "attendee-photo": AttendeePhoto, //
  "contact-name": ContactName,
  "catering-dietary-restrictions": CateringDietaryRestrictions,
  "document-request": DocumentRequest, //
  "health-pass": HealthPass, //
  "ems-status": EmsStatus,
  "event-days": EventDays,
  "event-list": EventList, //
  "event-name": EventName,
  "event-users": EventUsers,
  "eventbrite-order": EventbriteOrder, //
  "general-actions": GeneralActions,
  "item-block": ItemBlock, //
  "item-group": ItemGroup,
  "order-actions": OrderActions,
  "order-item": OrderItem,
  "order-item-details": OrderItemDetails,
  "order-name": OrderName,
  "order-numbers": OrderNumbers,
  "people-block": PeopleBlock, //
  "portal-people-actions": PortalPeopleActions, //
  "primary-contacts": PrimaryContacts,
  "submission-actions": SubmissionActions, //
  "has-portal-access": HasPortalAccess,
  "iframe-overlay": IframeOverlay,
  approval: Approval, //
  checkbox: Checkbox,
  countries: Countries,
  currency: Currency,
  date: Date,
  datetime: Datetime,
  dropdown: Dropdown,
  file: File,
  form: Form,
  task: Task,
  lookup: Lookup,
  payment: Payment, //
  schedule: Schedule, //
  source: Source, //
  status: Status,
  submission: Submission, //
  text: Text,
  textarea: Text,
  user: User,
  zone: Zone, //
  signature: Signature,
  title: Title,
  "order-status": OrderStatus
};

// @NOTE: Sometimes we prefer to resolve based on field ID so to not have
// to invent new "field types". We handle those here.
const resolveByFieldId = {
  [STANDARD_MODULE_FIELDS.ACCOUNTS.PROFILE_APPROVAL]: ProfileApproval,
  "synced-to-fuzion": SyncedToFuzion
};

const mapValue = val =>
  R.contains(typeof val, ["number", "string"]) ? val : "";

const Formatter = ({ field, data, instanceId }) =>
  React.createElement(
    R.prop(field.id, resolveByFieldId) ||
      R.prop(field.type, resolveByType) ||
      Text,
    {
      data,
      field,
      instanceId
    },
    mapValue(R.path([field.id, "value"], data))
  );

export default Formatter;
