import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: false,
  managers: [],
  managersFilter: "",
  usersFilter: "",
  data: null,
  moduleId: null,
  typeId: null
};

const reducers = {
  init: R.identity,
  refreshData: R.identity,
  updateApprover: R.identity,
  setInitialData: (state, { payload: { data, moduleId, typeId } }) => ({
    data,
    moduleId,
    typeId
  })
};

const model = createHandlers({ iniState, reducers, namespace: NAMESPACE });

const { actions, getters } = model;

export { actions, getters };

export default model;
