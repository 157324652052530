import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function updateBlockApprovers(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.put(getState().user.user.credentials, data);
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating workflow"
          }
        ])
      );
    }
  };
}
