import PropTypes from "prop-types";
import React from "react";
import EditorBase from "./TableConnector";
import Cell from "./CellBase";
import Editor from "components/Global/Editors/RadioEditor";
import Formatter from "ui-kit/Datagrid/View/Formatters/Text";

import { getSettings, ep } from "ui-kit/Datagrid/utils";

export default class InventoryListEditor extends EditorBase {
  render() {
    const { rowData } = this.props;
    const options = getSettings(ep(this.props), "options");

    return (
      <Cell
        ref="editor"
        formatter={<Formatter value={this.state} row={rowData} />}
        editor={
          <Editor
            options={options}
            value={this.state}
            onChange={this.handleChange}
          />
        }
      />
    );
  }
}

InventoryListEditor.propTypes = {
  ...EditorBase.propTypes,
  rowMetaData: PropTypes.object.isRequired
};
