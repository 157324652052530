import React from "react";

import * as R from "ramda";

import { connect } from "react-redux";

import {
  Div,
  MediumOutlineButton,
  SmallOutlineButton,
  PopMenu,
  PopMenuCard,
  MediumFilledButton,
  DownIcon,
  CheckIcon
} from "components/Base";

import { addS } from "utils/General";

import { actions, getters } from "Orders/TicketsPasses";

import { getProfilePricesNone } from "Orders/TicketsPasses/selectors";

const decorate = connect(
  state => ({
    profilePricesTemp: getters.profilePricesTemp(state),
    isProfilePricesNone: getProfilePricesNone(state)
  }),
  {
    showLimitPopOver: actions.showLimitPopOver,
    updateProfileLimitTemp: actions.updateProfileLimitTemp,
    updateProfileLimit: actions.updateProfileLimit,
    toggleProfilePrice: actions.toggleProfilePrice,
    disableProfilePrices: actions.disableProfilePrices,
    updateProfilePrices: actions.updateProfilePrices
  }
);

const ProfilePricesPopOver = ({
  profilePricesTemp,
  toggleProfilePrice,
  disableProfilePrices,
  isProfilePricesNone,
  updateProfilePrices,
  handlers: { prices }
}) => {
  return (
    <PopMenu
      Label={({ onClick }) => (
        <SmallOutlineButton
          RightIcon={DownIcon}
          onClick={() => {
            onClick();
          }}
          mb={2}
        >
          {R.all(R.propEq("selected", false), prices)
            ? "None"
            : `${R.length(prices)} price${addS(R.length(prices))}`}
        </SmallOutlineButton>
      )}
    >
      {({ closeMenu }) => (
        <PopMenuCard position="bottom" align="right">
          <Div p={3} mb={1} width={220}>
            <Div>
              {R.map(
                price => (
                  <Div
                    display="row.flex-start.stretch"
                    key={price.tempId}
                    onClick={() => {
                      toggleProfilePrice({ tempId: price.tempId });
                    }}
                    bg={{ default: "white", hover: "neutral1" }}
                    transition="fast"
                  >
                    <Div width={30}>
                      {price.selected && (
                        <CheckIcon color="primary7" size={24} />
                      )}
                    </Div>
                    <Div>
                      <Div fw={3} color="black">
                        {price.name}
                      </Div>
                      <Div fs={1}>{`$${price.amount}`}</Div>
                    </Div>
                  </Div>
                ),
                profilePricesTemp
              )}
              <Div
                display="row.flex-start.stretch"
                onClick={() => {
                  disableProfilePrices();
                }}
                p={1}
                height={30}
                bg={{ default: "white", hover: "neutral1" }}
                transition="fast"
              >
                <Div width={30}>
                  {isProfilePricesNone && (
                    <CheckIcon color="primary7" size={24} />
                  )}
                </Div>
                <Div>
                  <Div fw={3} color="black">
                    None
                  </Div>
                </Div>
              </Div>
            </Div>
            <Div display="row.space-between.center" mt={2}>
              <MediumOutlineButton
                onClick={() => {
                  closeMenu();
                }}
              >
                Cancel
              </MediumOutlineButton>
              <MediumFilledButton
                bg="primary7"
                onClick={() => {
                  updateProfilePrices();
                  closeMenu();
                }}
              >
                Assign Prices
              </MediumFilledButton>
            </Div>
          </Div>
        </PopMenuCard>
      )}
    </PopMenu>
  );
};

export default decorate(ProfilePricesPopOver);
