import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import listensToClickOutside from "react-onclickoutside";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Option from "./Option";

@listensToClickOutside
@CSSModules(css)
class Dropdown extends Component {
  onClick = action => {
    this.props.close();
    action();
  };

  stopPropagation = e => {
    e.stopPropagation();
  };

  handleClickOutside = () => {
    this.props.close();
  };

  render() {
    const options = [
      /*
      {
        id: 'import',
        name: 'Import a spreadsheet',
        icon: 'file_upload',
        iconColor: null,
        onClick: () => this.onClick(() => {})
      },
      {
        id: 'copy',
        name: 'Copy from existing',
        icon: 'content_copy',
        iconColor: null,
        onClick: () => this.onClick(this.props.showAddModuleModal)
      },
      */
      {
        id: "schedule",
        name: "Add a new schedule",
        icon: "schedule",
        iconColor: null,
        onClick: () => this.onClick(this.props.showAddScheduleModal),
        enable: this.props.canViewSchedules
      },
      {
        id: "module",
        name: "Add a new module",
        icon: "create",
        iconColor: null,
        onClick: () => this.onClick(this.props.showAddModuleModal),
        enable: true
      },
      {
        id: "clone",
        name: "Clone existing modules",
        icon: "photo_library",
        iconColor: null,
        onClick: () => this.onClick(this.props.showCloneModuleModal),
        enable: this.props.isLenndAdmin
      },
      {
        id: "create",
        name: "Start with a template",
        icon: "photo_library",
        iconColor: null,
        onClick: () => this.onClick(this.props.showSelectTemplateModal),
        enable: this.props.isLenndAdmin
      }
    ];
    return (
      <ReactCSSTransitionGroup
        transitionAppear
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}
        transitionName={{
          enter: css.enter,
          enterActive: css.enterActive,
          leave: css.leave,
          leaveActive: css.leaveActive,
          appear: css.appear,
          appearActive: css.appearActive
        }}
      >
        <div
          role="link"
          tabIndex="0"
          styleName="container"
          onClick={this.stopPropagation}
        >
          <div styleName="caret" />
          <div styleName="inner">
            {options
              .filter(o => o.enable)
              .map(o => (
                <Option key={o.id} option={o} />
              ))}
          </div>
        </div>
      </ReactCSSTransitionGroup>
    );
  }
}

Dropdown.propTypes = {
  close: PropTypes.func.isRequired,
  showAddModuleModal: PropTypes.func.isRequired,
  showSelectTemplateModal: PropTypes.func.isRequired
};

export default Dropdown;
