import React from "react";
import countSummary from "components/Event/FormsV2/Utils/count-approvals";
import isConfirmationSentCheck from "components/Event/FormsV2/Overview/utils/isConfirmationSent";

export default (submission, fields) => {
  const status = [];
  const counts = countSummary(submission, fields);

  if (counts.approved === 0 && counts.rejected === 0) {
    status.push("needs-review");
  } else if (counts.approved + counts.rejected < counts.requested) {
    status.push("in-review");
  } else if (counts.approved + counts.rejected === counts.requested) {
    if (counts.rejected && counts.approved) {
      status.push("reviewed");
    } else if (counts.rejected) {
      status.push("rejected");
    } else if (counts.approved) {
      status.push("approved");
    }
  }
  if (isConfirmationSentCheck(submission)) {
    status.push("sent");
  }
  return status;
};
