import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import {
  getCollectDepositOptions,
  getShowCollectPanel
} from "Sponsors/SelfSetup/selectors";

import { PAYMENT_FORM_ID, PAYMENT_FIELDS } from "Sponsors/SelfSetup/constants";

import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";
import {
  WithFormInstanceProvider,
  Row,
  Column,
  Dropdown,
  Input
} from "ui-kit/Form/View";

import { Text3, Text2, Span, MoneyIcon } from "components/Base";
import { QuestionCard } from "Sponsors/Common/View";

const decorate = R.compose(
  connect((state, props) => ({
    options: getCollectDepositOptions(state, props),
    showPanel: getShowCollectPanel(state, props)
  })),
  WithFormInstanceProvider(R.always(PAYMENT_FORM_ID)),
  WithThemeConsumer({ variant: VARIANTS.BACKGROUND })
);

const whenOptions = [
  { id: "submission", label: "Upon Submission" },
  { id: "approval", label: "Upon Approval" },
  { id: "date", label: "On a Specific Date" },
  { id: "manually", label: "Manually Process" }
];

const depositType = [
  { id: "flatAmount", label: "Flat Amount" },
  { id: "percentage", label: "% Percentage" }
];

const CollectDeposit = ({ options = [], showPanel = false }) => (
  <QuestionCard
    mt={3}
    question={
      <Text3>
        Do you want to collect a <Span fw={3}>required deposit</Span> on
        selected packages/order total?
      </Text3>
    }
    options={options}
    fieldId={PAYMENT_FIELDS.COLLECT_DEPOSIT}
    footer={
      showPanel ? (
        <Row>
          <Column>
            <Text2 my={2}>When</Text2>
            <Dropdown
              options={whenOptions}
              fieldId={PAYMENT_FIELDS.COLLECT_WHEN}
            />
          </Column>
          <Column ml={3}>
            <Text2 my={2}>Deposit Type</Text2>
            <Dropdown
              options={depositType}
              fieldId={PAYMENT_FIELDS.COLLECT_TYPE}
            />
          </Column>
          <Column ml={3}>
            <Text2 my={2}>$ Amount</Text2>
            <Input
              fieldId={PAYMENT_FIELDS.COLLECT_PRICE}
              LeftIcon={MoneyIcon}
            />
          </Column>
        </Row>
      ) : null
    }
  />
);

export default decorate(CollectDeposit);
