import * as R from "ramda";
import { createSelector } from "reselect";
import { getters } from "./model";
import { EXTRA_FIELDS } from "./constants";

import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";
import resolveEditor from "components/Global/StandAloneEditors/utils/resolveEditor";

export const getSelectedType = createSelector(
  getters.types,
  getters.typeId,
  (types, typeId) => R.find(R.propEq("id", typeId), types)
);

export const fieldsToPass = createSelector(
  getSelectedType,
  getters.eventDetails,
  getters.values,
  (selectedType, eventDetails, values) =>
    R.compose(
      R.map(field => ({
        id: field.id,
        name: field.name,
        isRequired: field.required,
        Editor: resolveEditor(field),
        editorProps: {
          usePortal: true,
          ...resolveEditorProps(field, eventDetails)
        },
        value: values[field.id]
      })),
      fields => [...fields, ...EXTRA_FIELDS],
      R.propOr([], "fields")
    )(selectedType)
);

export const typesToPass = createSelector(
  getters.types,
  R.map(({ id, name }) => ({
    value: id,
    label: name
  }))
);

export const getTypeName = createSelector(
  getSelectedType,
  selectedType => R.propOr("type", "name")(selectedType)
);

export const shouldShowTypes = createSelector(
  getters.types,
  types => R.length(types) > 1
);
