import React from "react";
import { connect } from "react-redux";
import { actions } from "Forms/WizardModal";
import {
  getApplicationsModuleId,
  getApplicationCollectFields
} from "Forms/WizardModal/selectors";
import FieldsModal from "Forms/WizardModal/View/Common/FieldsModal";

const decorate = connect(
  state => ({
    fieldsList: getApplicationCollectFields(state),
    moduleId: getApplicationsModuleId(state)
  }),
  {
    hideModal: actions.hideCollectFieldsModal,
    toggleEnabled: actions.toggleEnabledCollectField,
    disableField: actions.disableCollectField,
    enableAll: () => actions.enableAllCollectFields(),
    disableAll: () => actions.disableAllCollectFields(),
    setSearchFilter: actions.setCollectFieldsFilter
  }
);

const CollectFields = ({
  hideModal,
  fieldsList,
  handlers: { showCustomAddFieldModal: showAddFieldModal },
  toggleEnabled,
  disableField,
  enableAll,
  disableAll,
  setSearchFilter,
  moduleId
}) => (
  <FieldsModal
    hideModal={hideModal}
    fieldsList={fieldsList}
    toggleEnabled={toggleEnabled}
    disableField={disableField}
    enableAll={enableAll}
    disableAll={disableAll}
    setSearchFilter={setSearchFilter}
    showAddFieldModal={() =>
      showAddFieldModal({ moduleId, source: "applications" })
    }
    moduleId={moduleId}
  />
);

export default decorate(CollectFields);
