import React from "react";

import { connect } from "react-redux";

import { getters, actions } from "../index";

import MiniModalWrapper from "ui-kit/MiniModalWrapper";

import { Div } from "components/Base";

import Loading from "ui-kit/Loading";

import Body from "./Body";

import Footer from "./Footer";

const decorate = connect(
  state => ({
    loading: getters.loading(state)
  }),
  {
    setInitialData: actions.setInitialData
  }
);

class Layout extends React.Component {
  componentDidMount() {
    const {
      modules,
      template,
      templateUrl,
      templateTitle,
      templateImageUrl,
      setInitialData,
      moduleId
    } = this.props;
    setInitialData({
      modules,
      template,
      templateUrl,
      templateTitle,
      templateImageUrl,
      moduleId
    });
  }

  render() {
    const { loading, hideModal, onDone } = this.props;

    return (
      <MiniModalWrapper
        title="Upload Document Template"
        hideModal={hideModal}
        width={590}
        height={480}
        bg="neutral0"
        showModal
        isPortal={false}
      >
        <Div>
          {loading ? <Loading /> : <Body />}
          <Footer hideModal={hideModal} onDone={onDone} />
        </Div>
      </MiniModalWrapper>
    );
  }
}

export default decorate(Layout);
