import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Item = ({ children }) => <div styleName="item">{children}</div>;

Item.propTypes = {
  children: PropTypes.object.isRequired
};

export default CSSModules(Item, css);
