import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Helpers from "utils/Global/Helpers";
import { Div, Text6, DarkTab } from "components/Base";
import * as R from "ramda";

const TABS = [[null, "Settings", () => {}, true]];

@CSSModules(css)
class GeneralSettings extends Component {
  static stateFromProps({ organization }) {
    return {
      name: organization.name || "",
      logoImageUrl: organization.logo_image_url || "",
      backgroundImageUrl: organization.background_image_url || ""
    };
  }

  state = GeneralSettings.stateFromProps(this.props);

  componentWillReceiveProps(nextProps) {
    if (nextProps.organization !== this.props.organization) {
      this.setState(GeneralSettings.stateFromProps(nextProps));
    }
  }

  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  selectImageUrl = () => {
    const opts = { path: "organization-logo-image/" };
    const callback = InkBlobs => {
      if (InkBlobs[0] && typeof InkBlobs[0].url !== "undefined") {
        this.setState({
          logoImageUrl: InkBlobs[0].url
        });
      }
    };
    Helpers.getFilepicker({}, opts, callback);
  };

  selectBackgroundImageUrl = () => {
    const opts = { path: "event-background-image/" };
    const callback = InkBlobs => {
      if (InkBlobs[0] && typeof InkBlobs[0].url !== "undefined") {
        this.setState({
          backgroundImageUrl: InkBlobs[0].url
        });
      }
    };
    Helpers.getFilepicker({}, opts, callback);
  };

  handleSubmit = async e => {
    e.preventDefault();
    const orgId = this.props.organization.id;
    await this.props.updateOrganization({ orgId, ...this.state });
    this.props.getUserAccessLevels();
    return this.props.fetchOrganization(orgId);
  };

  validateForm = () => Boolean(this.state.name.length);

  render() {
    return (
      <Div style={{ minHeight: "100%" }}>
        <Div bb={1} bc="gray3" bg="white" display="row.flex-start.flex-end">
          <Div flex={1}>
            <Text6 pl={8} pt={6}>
              General Settings
            </Text6>

            <Div pt={3} pl={8}>
              {R.map(([routeName, tabName, onClick, isActive]) => {
                return (
                  <DarkTab
                    key={tabName}
                    px={2}
                    fs={3}
                    active={isActive}
                    onClick={!isActive ? onClick : undefined}
                  >
                    {tabName}
                  </DarkTab>
                );
              })(TABS)}
            </Div>
          </Div>
        </Div>
        <Div style={{ minHeight: "100%" }} pb={14}>
          <div styleName="container">
            <form onSubmit={this.handleSubmit}>
              <div className="input-wrapper">
                <label htmlFor="name" styleName="label">
                  Organization Name
                </label>
                <input
                  styleName="input"
                  type="text"
                  value={this.state.name}
                  placeholder="Name of your event"
                  name="name"
                  onChange={this.handleInputChange}
                  required
                />
              </div>

              <div className="input-wrapper">
                <label htmlFor="imageUpload" styleName="label">
                  Organization Logo
                </label>
                <div
                  name="imageUpload"
                  styleName={
                    this.state.logoImageUrl
                      ? "logoContainer"
                      : "logoContainerEmpty"
                  }
                  style={
                    this.state.logoImageUrl
                      ? { backgroundImage: `url(${this.state.logoImageUrl})` }
                      : null
                  }
                  onClick={this.selectImageUrl}
                >
                  <div styleName="logoContainerOverlay">
                    <span>Upload logo</span>
                    &nbsp;
                    <i className="material-icons">file_upload</i>
                  </div>
                </div>
              </div>

              <div className="input-wrapper">
                <label styleName="label">Organization Background Image</label>
                <div
                  role="button"
                  tabIndex="0"
                  styleName={
                    this.state.backgroundImageUrl
                      ? "backgroundContainer"
                      : "backgroundContainerEmpty"
                  }
                  style={
                    this.state.backgroundImageUrl
                      ? {
                          backgroundImage: `url(${
                            this.state.backgroundImageUrl
                          })`
                        }
                      : null
                  }
                  onClick={this.selectBackgroundImageUrl}
                >
                  <div styleName="containerOverlay">
                    <span>Upload background</span>
                    &nbsp;
                    <i className="material-icons">file_upload</i>
                  </div>
                </div>
              </div>

              <button type="submit" styleName="button">
                Save Changes
              </button>
            </form>
          </div>
        </Div>
      </Div>
    );
  }
}

export default GeneralSettings;
