import React, { Component } from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";

import {
  ButtonGroup,
  Submit,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";
import { Div } from "components/Base";
import formaCloseDate from "components/Event/Module/Form/utils/format-close-date";

import DatePicker from "components/Event/FormsV2/Modals/FormEditModal/Calendar";

class EditContactFormAssignment extends Component {
  state = {
    customCloseDate: this.props.customCloseDate
  };

  onChangeCloseDate = customCloseDate => this.setState({ customCloseDate });

  handleSubmit = e => {
    e.preventDefault();

    this.props.onSave({
      closeDate: this.state.customCloseDate
    });

    this.props.hideModal();
  };

  render() {
    const { name, closeDate } = this.props;
    const { customCloseDate } = this.state;

    return (
      <StyleWrapper
        hideModal={this.props.hideModal}
        heading="Update form assignment"
        width={390}
      >
        <form onSubmit={this.handleSubmit}>
          <Div mb={4}>
            <Div fw={3} color="gray7" mb={1}>
              Person:
            </Div>
            <Div>{name}</Div>
          </Div>

          <Div mb={4}>
            <Div fw={3} color="gray7">
              Specify a due date for this group:
            </Div>
            <Div fs={1} color="neutral6" mb={2}>
              Optional. If no due date is specified, the form's due date{" "}
              {closeDate ? `(${formaCloseDate(closeDate)})` : null} will be
              applied.
            </Div>
            <DatePicker
              value={customCloseDate}
              onChange={this.onChangeCloseDate}
            />
          </Div>

          <Div display="row.flex-start.center">
            <ButtonGroup>
              <Submit title="Save" />
              <ButtonOutline title="Cancel" onClick={this.props.hideModal} />
            </ButtonGroup>
          </Div>
        </form>
      </StyleWrapper>
    );
  }
}

export default EditContactFormAssignment;
