import React, { useEffect } from "react";
import * as R from "ramda";
import { withRouter } from "react-router";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import { actions } from "../model";
import css from "./styles.scss";
import { getSelectedItem } from "../selectors";
import Footer from "./Footer";
import Body from "./Body";
import { noop } from "utils/General";

const decorate = R.compose(
  connect(
    state => ({
      selectedItem: getSelectedItem(state)
    }),
    {
      init: actions.init
    }
  ),
  withRouter,
  CSSModules(css)
);

const AddInventoryModal = ({ hideModal, init, params, onSave = noop }) => {
  useEffect(() => {
    init(params);
  }, []);

  return (
    <MiniModalWrapper
      showModal={true}
      hideModal={hideModal}
      title="Add Pass Inventory"
      width={424}
      height={600}
      stopPropagation
    >
      <div styleName="wrapper">
        <Body />
        <Footer onSave={onSave} hideModal={hideModal} />
      </div>
    </MiniModalWrapper>
  );
};

export default decorate(AddInventoryModal);
