import * as R from "ramda";
import { createSelector } from "reselect";

import { getters } from "./model";

export const getManagers = createSelector(
  getters.data,
  getters.usersFilter,
  (data, usersFilter) => {
    const hasAccess = R.propOr([], "has_access", data);
    if (R.isEmpty(usersFilter)) {
      return hasAccess;
    }

    return R.filter(
      item =>
        R.contains(
          R.toLower(usersFilter),
          R.toLower(
            `${item.first_name ? item.first_name : ""} ${
              item.last_name ? item.last_name : ""
            }`
          )
        ),
      hasAccess
    );
  }
);

export const getNotAccessUsers = createSelector(
  getters.data,
  getters.usersFilter,
  (data, usersFilter) => {
    const hasAccess = R.propOr([], "does_not_have_access", data);
    if (R.isEmpty(usersFilter)) {
      return hasAccess;
    }

    return R.filter(
      item =>
        R.contains(
          R.toLower(usersFilter),
          R.toLower(
            `${item.first_name ? item.first_name : ""} ${
              item.last_name ? item.last_name : ""
            }`
          )
        ),
      hasAccess
    );
  }
);

export const getManagerRoles = createSelector(
  getters.data,
  data => {
    const profiles = R.propOr([], "manager_permission_profiles", data);
    return profiles.map(r => r.name).join(", ");
  }
);
