/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { actions, getters } from "../model";
import SelectRequiredFieldsModal from "Modules/SelectRequiredFieldsModalRefactored/View";
import SelectGuestListItemsModal from "./Modals/SelectGuestListItemsModal";
import UpdateGuestListSettingsModal from "./Modals/UpdateGuestListSettingsModal";

const decorate = R.compose(
  connect(
    state => ({
      selectedPermissionSetId: getters.selectedPermissionSetId(state),
      loadingPermissionSet: getters.loadingPermissionSet(state),
      showSelectRequiredFieldsModal: getters.showSelectRequiredFieldsModal(
        state
      ),
      showSelectGuestListItemsModal: getters.showSelectGuestListItemsModal(
        state
      )
    }),
    {
      init: actions.init
    }
  ),
  CSSModules(css)
);

const Layout = ({
  init,
  selectedPermissionSetId,
  showSelectRequiredFieldsModal,
  showSelectGuestListItemsModal,
  moduleId,
  recordTypeId,
  onClose
}) => {
  useEffect(() => {
    init({
      selectedPermissionSetId,
      moduleId,
      selectedTypeId: recordTypeId
    });
  }, []);
  return (
    <div styleName="wrapper">
      {showSelectRequiredFieldsModal ? (
        <SelectRequiredFieldsModal width={750} height={700} />
      ) : null}
      {showSelectGuestListItemsModal ? <SelectGuestListItemsModal /> : null}
      <UpdateGuestListSettingsModal onClose={onClose} />
    </div>
  );
};

export default decorate(Layout);
