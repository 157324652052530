import * as R from "ramda";

let hot, cold;

if (process.env.HMR) {
  const root = require("react-hot-loader/root");
  const hml = require("react-hot-loader");

  hot = R.map(root.hot);
  cold = hml.cold;
} else {
  hot = R.identity();
  cold = hot;
}

export { hot, cold };
