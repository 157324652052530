import * as R from "ramda";
import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { noop } from "utils/General";

import SmallCalendar from "react-datetime";

import { Input } from "./Input";
import { CalendarIcon, ClockIcon } from "components/Base";

import { WithThemeConsumer, defaultTh } from "ui-kit/Theme";
import { WithFormInstanceConsumer } from "./Context";

import { actions } from "../model";
import { getValue } from "../selectors";
import { FIELD_TYPES, DEFAULT_DATE_FORMAT } from "../constants";

import Popover from "@lennd/material-ui/Popover";
import Tooltip from "ui-kit/Tooltip";

const decorate = R.compose(
  WithThemeConsumer(),
  WithFormInstanceConsumer({
    fieldType: FIELD_TYPES.DATE
  }),
  connect(
    (state, props) => ({
      value: getValue(state, props)
    }),
    bindInstance({
      onChange: actions.setFieldValue,
      onBlur: actions.blurField,
      setIniValue: actions.setIniValue
    })
  )
);

export const DateInput = decorate(
  ({
    format = DEFAULT_DATE_FORMAT,
    showDateTimeButton = true,
    showCalendar = true,
    showTime = false,
    fieldId,
    fieldType,
    onChange = noop,
    onBlur = noop,
    value = "",
    iniValue,
    autoFocus = false,
    setIniValue = noop,
    Th = defaultTh,
    showClearBtn = false,
    ...props
  }) => {
    useEffect(() => {
      setIniValue(iniValue, {
        meta: { fieldId, fieldType, format }
      });
    }, []);
    const anchorEl = useRef();
    const [open, setOpen] = useState(false);

    if (showDateTimeButton) {
      return (
        <div
          ref={anchorEl}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <Input
              fieldId={fieldId}
              width={1}
              autoFocus={autoFocus}
              {...props}
              RightIcon={() =>
                React.createElement(
                  showTime && !showCalendar ? ClockIcon : CalendarIcon,
                  Th(["color"])
                )
              }
            />
            {showClearBtn && value ? (
              <Tooltip tooltip="Clear Date">
                <span
                  class="material-icons"
                  style={{ marginLeft: 4, cursor: "pointer" }}
                  onClick={ev => {
                    ev.stopPropagation();
                    onChange("", {
                      meta: { fieldId, fieldType, format }
                    });
                  }}
                >
                  cancel
                </span>
              </Tooltip>
            ) : null}
          </div>

          <Popover
            open={open}
            anchorEl={anchorEl.current}
            onClose={() => {
              onBlur(null, { meta: { fieldId, fieldType } });
              setOpen(false);
            }}
            {...props}
          >
            <div onClick={e => e.stopPropagation()}>
              <SmallCalendar
                defaultValue={value}
                input={false}
                timeFormat={showTime}
                dateFormat={showCalendar}
                open
                value={value}
                onChange={val => {
                  onChange(val, {
                    meta: { fieldId, fieldType, format }
                  });
                  if (!showTime) {
                    onBlur(null, { meta: { fieldId, fieldType } });
                    setOpen(false);
                  }
                }}
              />
            </div>
          </Popover>
        </div>
      );
    }
    return <Input fieldId={fieldId} autoFocus={autoFocus} {...props} />;
  }
);
