import React, { Component } from "react";
import * as R from "ramda";
import getValue from "utils/value-types/get-value";
import toString from "utils/value-types/to-string";
import View from "./View";
import ClickToPrint from "../ClickToPrint";

class PortalReportsController extends Component {
  render() {
    const { requests, onClickToPrint } = this.props;

    return (
      <View
        {...{
          modules: requests.map(m => ({
            title: m.module.name,
            requestValues: {
              fields: R.compose(
                R.sortBy(R.prop("name")),
                R.map(f => ({ name: f.name, id: f.id })),
                R.filter(f => !["lookup"].includes(f.type))
              )(m.fields),
              values: m.records.map(r =>
                R.mapObjIndexed((val, key, obj) =>
                  toString(
                    getValue(val, val.type),
                    val.type,
                    this.props.eventDetails,
                    {
                      references: m.references,
                      availableCatalogItems: R.compose(
                        R.flatten,
                        R.map(R.path(["settings", "availableCatalogItems"])),
                        R.filter(R.propEq("type", "catalog-item"))
                      )(m.fields),
                      fieldId: R.path(["settings", "fieldId"])(
                        m.fields.find(f => f.id === key) || {}
                      )
                    }
                  )
                )(r.values)
              )
            },
            summaryValues: R.sortBy(R.prop("name"), m.counts)
          })),
          onClickToPrint
        }}
      />
    );
  }
}

PortalReportsController.propTypes = {};

export default ClickToPrint(PortalReportsController);
