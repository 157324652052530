export default {
  mustBeChildField: false,
  canBeChildField: true,
  canBeParentField: true,
  canBeChildOfFieldTypes: ["contact", "reference"],
  mappableFields: [
    /*
  // @NOTE: Currently we are not doing anything with these re: choosing a field
  to map as a standard field, but we will.
  {
    id: 'activity',
    name: 'Activity',
    mappableFieldTypes: ['text', 'dropdown']
  }, {
    id: 'start_date',
    name: 'Date',
    mappableFieldTypes: ['date']
  }, {
    id: 'start_time',
    name: 'Time',
    mappableFieldTypes: ['time']
  }, {
    id: 'attachments',
    name: 'Attachments',
    mappableFieldTypes: ['file']
  }, {
    id: 'notes',
    name: 'Notes',
    mappableFieldTypes: ['text']
  }
  */
  ]
};
