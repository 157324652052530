import { connect } from "react-redux";
import { withRouter } from "react-router";
import Controller from "./Controller";
import { showModal, hideModal } from "redux/modules/modal/actions";
import {
  getInvoice,
  addInvoiceTransaction
} from "redux/modules/orders/invoices/actions";
import { invoice } from "redux/modules/orders/invoices/selectors";

const mapStateToProps = (state, props) => ({
  invoice: invoice(state, props.params.invoiceId)
});

export default connect(
  mapStateToProps,
  {
    getInvoice,
    addInvoiceTransaction,
    showModal,
    hideModal
  }
)(withRouter(Controller));
