import React from "react";
import { AvatarLabel, IconLabel } from "components/Base";

import {
  ItemTypesMultiSelect,
  AccountsMultiSelect,
  ContactsMultiSelect,
  FormsMultiSelect,
  UsersMultiSelect
} from "./MultiSelectParsers";

const ApproversAvatarNameEmail = ({
  first_name,
  last_name,
  email,
  photo_url
}) => (
  <AvatarLabel
    py={1}
    frontText={`${first_name} ${last_name}`}
    backText={email}
    photoURL={photo_url}
  />
);

const AvatarNameEmail = ({ record_id }) => {
  return <AvatarLabel py={1} frontText={record_id} />;
};

const IconName = ({ record_id, icon }) => (
  <IconLabel py={1} text={record_id} icon={icon} />
);

const approvalsDict = {
  itemType: {
    Editor: ItemTypesMultiSelect,
    title: "Item Type is...",
    quanta: "Item",
    RowComp: IconName,
    rowProp: "itemTypes",
    fetcher: "getItemTypes",
    sectorType: "trigger"
  },
  orderAccount: {
    Editor: AccountsMultiSelect,
    title: "Account on Order is...",
    quanta: "Account",
    RowComp: AvatarNameEmail,
    rowProp: "accounts",
    fetcher: "getAccounts",
    sectorType: "trigger"
  },
  orderContact: {
    Editor: ContactsMultiSelect,
    title: "Contact on Order is...",
    quanta: "Contact",
    RowComp: AvatarNameEmail,
    rowProp: "contacts",
    fetcher: "getContacts",
    sectorType: "trigger"
  },
  submittedByAccount: {
    Editor: AccountsMultiSelect,
    title: "Submitted via Account...",
    quanta: "Account",
    RowComp: AvatarNameEmail,
    rowProp: "accounts",
    fetcher: "getAccounts",
    sectorType: "trigger"
  },
  submittedByContact: {
    Editor: ContactsMultiSelect,
    title: "Submitted via Contact...",
    quanta: "Contact",
    RowComp: AvatarNameEmail,
    rowProp: "contacts",
    fetcher: "getContacts",
    sectorType: "trigger"
  },
  submittedViaSource: {
    Editor: FormsMultiSelect,
    title: "Submitted via Source...",
    quanta: "Source",
    RowComp: AvatarNameEmail,
    rowProp: "forms",
    fetcher: "getForms",
    sectorType: "trigger"
  },
  user: {
    Editor: UsersMultiSelect,
    quanta: "User",
    RowComp: ApproversAvatarNameEmail,
    rowProp: "users",
    fetcher: "getUsers",
    sectorType: "approver"
  }
  // itemPrice: money, // TODO
  // orderTotal: money,
  // orderProperty: //,
};

export { approvalsDict };
