import AccountProfileController from "Accounts/Profile/AccountProfile";
import { eventDetails } from "redux/modules/event/selectors";
import { connect } from "react-redux";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

import Profile from "./Profile";

function mapStateToProps(state) {
  return {
    eventDetails: eventDetails(state),
    canViewCredentialsManagement: selectFeatureFlag(
      flags.CAN_VIEW_CREDENTIALS_MANAGEMENT.NAME
    )(state),
    canShowDeduplicate: selectFeatureFlag(
      flags.CAN_VIEW_RECORDS_DEDUPLICATION.NAME
    )(state)
  };
}
export default connect(mapStateToProps)(AccountProfileController(Profile));
