import React from "react";
import { connect } from "react-redux";

import { Div } from "components/Base";

import OrdersTable from "Orders/Table/View";
import LoadingOverlay from "ui-kit/LoadingOverlay";

import { getters } from "Orders/List";

import {
  getTotal,
  getList,
  getColumns
} from "Organizations/Requests/selectors";

const decorate = connect((state, props) => ({
  rows: getList(state, props),
  loading: getters.loading(state),
  total: getTotal(state),
  columns: getColumns(state)
}));

const Table = ({ loading = false, total, columns, rows }) => {
  const table = (
    <Div
      flex={1}
      style={{
        overflowY: loading ? "hidden" : "auto"
      }}
    >
      <Div
        mx={3}
        mb={3}
        bra={2}
        bc="neutral3"
        ba={1}
        style={{ overflow: "hidden" }}
        display="column.flex-start"
      >
        <OrdersTable rows={rows} columns={columns} />
      </Div>
    </Div>
  );
  if (loading) {
    return (
      <React.Fragment>
        <LoadingOverlay />
        {(total > 0 && table) || null}
      </React.Fragment>
    );
  }

  return table;
};

export default decorate(Table);
