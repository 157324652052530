import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const PrimaryAction = ({ children, processing, disabled, ...props }) => (
  <button
    type="submit"
    {...props}
    styleName={disabled ? "primaryActionDisabled" : "primaryAction"}
  >
    {processing ? (
      <i className="fa fa-circle-o-notch" styleName="processingIcon" />
    ) : (
      children
    )}
  </button>
);

PrimaryAction.propTypes = {
  children: PropTypes.node.isRequired,
  processing: PropTypes.bool,
  disabled: PropTypes.bool
};

export default CSSModules(PrimaryAction, css);
