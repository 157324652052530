import React from "react";

import { Div, BigFilledButton } from "components/Base";
import CredentialSummaryWidget from "Passes/Dashboard/SummaryPanel";
import EmptyCredentialSummaryWidget from "Passes/Dashboard/EmptySummaryPanel";
import { Title, Breadcrumbs } from "Passes/Common";

const View = ({ widgets = [], eventId, onFetchDashboard, onAddWidget }) => {
  return (
    <Div bg="neutral0" display="column" height={1}>
      <Div
        px={6}
        pt={6}
        bg="white"
        width={1}
        bb={1}
        bc="neutral3"
        display="row.space-between.center"
        style={{
          flexShrink: 0
        }}
      >
        <Div pr={5} pb={3}>
          <Breadcrumbs>Passes</Breadcrumbs>
          <Title>Dashboard</Title>
        </Div>
        <BigFilledButton bg="altB5" onClick={onAddWidget}>
          Add Summary
        </BigFilledButton>
      </Div>

      <Div p={5}>
        {widgets.length ? (
          widgets.map(w => (
            <CredentialSummaryWidget
              key={w.id}
              params={{ eventId }}
              fetchDashboard={onFetchDashboard}
              widget={w}
            />
          ))
        ) : (
          <EmptyCredentialSummaryWidget addWidget={onAddWidget} />
        )}
      </Div>
    </Div>
  );
};

export default View;
