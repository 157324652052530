import * as R from "ramda";
import PropTypes from "prop-types";
import React from "react";
import autobind from "autobind-decorator";
import Label from "components/Global-2016/Forms/Label";
import LabelSimpleForm from "../LabelSimpleForm/view";
import Editor from "components/Global/StandAloneEditors/Catering";
import FormBaseInput from "../FormBaseInput";
import FormInputWrapper from "components/Event/FormsV2/Sections/FormInputWrapper";
import isRequiredValid from "utils/value-types/validations/catering/required";
import InputGroup from "components/Global-2016/Forms/InputGroup";
import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";
import getMetaData from "utils/value-types/get-meta-data";

import { isEqual } from "lodash";

class FormCatering extends FormBaseInput {
  constructor(props) {
    super(props);

    this.state = {
      value: props.formValues[props.field.id],
      isShown: false
    };
  }

  componentWillUpdate(nextProps) {
    if (
      !this.props.isEditing &&
      !isEqual(
        this.props.formValues[this.props.field.id],
        nextProps.formValues[this.props.field.id]
      )
    ) {
      this.setState({
        value: nextProps.formValues[this.props.field.id]
      });
    }
  }

  @autobind
  handleSave(value) {
    this.setState(
      {
        value
      },
      () => this.save(this.props.field.id, this.state.value)
    );
  }

  @autobind
  async isValid() {
    const errors = [];
    const required = this.props.field.is_required;
    const value = this.state.value;

    if (required && !isRequiredValid(value)) {
      errors.push("This is a required question");
    }
    this.setState({
      errors
    });
    return !errors.length;
  }

  @autobind
  handleToogleShowEditMode() {
    this.setState({
      isShown: R.not(this.state.isShown)
    });
  }

  render() {
    let handleSave;
    const {
      disabled,
      field: {
        name,
        settings: { description, isAdminField, displayType }
      },
      eventId,
      canShowSimpleSideBar
    } = this.props;
    const required = this.props.field.is_required;
    const readonly = this.props.field.is_readonly;
    const { connectDragSource } = this.props;
    if (!this.props.isEditing) {
      handleSave = this.handleSave;
    }

    return (
      <FormInputWrapper
        isEditing={this.props.isEditing}
        isValid={this.state.isValid}
        errorMessages={this.state.errors}
        fieldData={this.props.field}
        instanceId={this.props.field.id}
        isShown={this.state.isShown}
        setIsShown={() => this.handleToogleShowEditMode()}
        connectDragSource={connectDragSource}
      >
        <InputGroup style={this.props.groupStyles}>
          {name ? (
            !canShowSimpleSideBar ? (
              <Label
                isAdminField={isAdminField}
                required={required}
                description={description}
                readonly={readonly}
              >
                {name}
              </Label>
            ) : (
              <LabelSimpleForm
                isAdminField={isAdminField}
                required={required}
                description={description}
                readonly={readonly}
                instanceId={this.props.field.id}
                fieldData={this.props.field}
                isShown={this.state.isShown}
                setIsShown={() => this.handleToogleShowEditMode()}
              >
                {name}
              </LabelSimpleForm>
            )
          ) : null}
          <Editor
            eventId={eventId}
            required={required}
            disabled={disabled}
            value={this.state.value}
            displayType={displayType}
            onChange={handleSave}
            rowMetaData={getMetaData({
              row: this.props.formValues,
              field: this.props.field,
              fields: this.props.fields
            })}
            {...resolveEditorProps(this.props.field, this.props.eventDetails)}
          />
        </InputGroup>
      </FormInputWrapper>
    );
  }
}

FormCatering.propTypes = {
  eventDetails: PropTypes.object.isRequired,
  eventId: PropTypes.string.isRequired,
  groupStyles: PropTypes.object,
  formValues: PropTypes.object,
  field: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isResponseLocked: PropTypes.bool.isRequired,
  isApproving: PropTypes.bool.isRequired,
  isFillingFormOut: PropTypes.bool.isRequired,
  updateFormValue: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default FormCatering;
