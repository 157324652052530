import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const SidebarActionText = ({ children, style, onClick }) => (
  <div styleName="container" style={style} onClick={onClick}>
    {children}
  </div>
);

SidebarActionText.propTypes = {
  onClick: PropTypes.func
};

export default CSSModules(SidebarActionText, css);
