import React from "react";

import { connect } from "react-redux";
import { getValue, getSettings } from "../../utils";

import { ROW_ACTIONS } from "Items/Manage/constants";

import { actions } from "../../model";

const decorate = connect(
  null,
  {
    execute: actions.executeAction
  }
);

const Zone = props => {
  const value = getValue(props) || " - ";
  const clickable = getSettings(props, "canAdjustZones", false);
  const clickFn = () =>
    props.execute({
      row: props.data,
      column: props.field,
      actionId: ROW_ACTIONS.EDIT_ZONES
    });
  return (
    <div
      className={clickable ? "underline-on-hover" : null}
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        cursor: `${clickable ? "pointer" : "default"}`
      }}
      onClick={clickable ? clickFn : null}
    >
      {value}
    </div>
  );
};

export default decorate(Zone);
