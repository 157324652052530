import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import EmailTemplate from "./EmailTemplate";

import { getEmailTemplates } from "redux/modules/eventEmailTemplates/actions";
import { list } from "redux/modules/eventEmailTemplates/selectors";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";

function mapStateToProps(state) {
  return {
    orgId: getOrgId(state),
    eventId: getEventId(state),
    templates: list(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEmailTemplates
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailTemplate);
