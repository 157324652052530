import { createContext } from "redux-mvc";
import * as R from "ramda";
import module, { actions } from "Items/AddQuestionModal";
import Layout from "./Layout";
import rootSaga from "Items/AddQuestionModal/sagas";

module.setRootSaga(rootSaga);

const decorate = createContext({
  module: module,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(
        actions.init({
          questionId: this.props.questionId,
          showQuestionSets: this.props.showQuestionSets
        })
      );
    }
  },
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    persist: false,
    observedDomains: ["user", "event", "modal", "organization"]
  }
});

export default decorate(Layout);
