import PropTypes from "prop-types";
import React, { Component } from "react";
import Popover from "@lennd/material-ui/Popover";
import MenuItem from "material-ui/MenuItem";
import Menu from "material-ui/Menu";

class AddManagerPermissions extends Component {
  state = { showPopover: false };

  onRequestClose = () => this.setState({ showPopover: false });

  handleClick = e => {
    e.stopPropagation();
    return this.setState({ showPopover: true });
  };

  handleSelect = (e, val) => {
    this.props.onChange(val, this.props.id);
    this.onRequestClose();
  };

  render() {
    return (
      <div>
        <span
          onClick={this.handleClick}
          ref={ref => {
            this.anchor = ref;
          }}
        >
          {this.props.children}
        </span>
        <Popover
          anchorEl={this.anchor}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          onClose={this.onRequestClose}
          open={this.state.showPopover}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
        >
          <Menu onChange={this.handleSelect}>
            <MenuItem primaryText="Is owner" value="owner" />
            <MenuItem primaryText="Can edit" value="canedit" />
            {/* <MenuItem primaryText="can view" value="canview" /> */}
          </Menu>
        </Popover>
      </div>
    );
  }
}

AddManagerPermissions.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default AddManagerPermissions;
