import React from "react";
import { connect } from "react-redux";
import {
  Div,
  MediumFilledButton,
  DownFilledIcon,
  Popover
} from "components/Base";

import { getters, actions } from "ui-kit/Notifications/model";
import { FILTER } from "ui-kit/Notifications/constants";
import UnseenCount from "./UnseenCount";
import EventPopoverFilter from "./EventPopoverFilter";
import { getCountOfAllNotifications } from "../selectors";

import { bindInstance } from "redux-mvc";

import * as R from "ramda";

const decorate = connect(
  (state, props) => ({
    filterBy: getters.filterBy(state, props),
    notificationsCount: getCountOfAllNotifications(state, props)
  }),
  bindInstance({
    selectFilter: actions.selectFilter
  })
);

const FilterBy = ({
  filterBy,
  selectFilter,
  instanceId,
  notificationsCount
}) => {
  return (
    <Div display="row" pill bg="#fbfafb" p={1}>
      <MediumFilledButton
        pill
        bg={filterBy === FILTER.ALL ? "#ffffff" : "#fbfafb"}
        color="gray8"
        onClick={() => selectFilter({ type: FILTER.ALL })}
        mr={1}
      >
        All
        {notificationsCount && (
          <UnseenCount ml={2} count={notificationsCount} />
        )}
      </MediumFilledButton>
      <Popover
        Label={({ onClick }) => (
          <MediumFilledButton
            RightIcon={DownFilledIcon}
            pill
            bg={filterBy === FILTER.BY_EVENT ? "#ffffff" : "#fbfafb"}
            color="gray8"
            onClick={onClick}
          >
            By Event
          </MediumFilledButton>
        )}
      >
        {({ closePopover }) => (
          <EventPopoverFilter
            closePopover={closePopover}
            instanceId={instanceId}
          />
        )}
      </Popover>
    </Div>
  );
};

export default decorate(FilterBy);
