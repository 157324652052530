import * as R from "ramda";
import { createSelector } from "reselect";
import { TABS } from "./constants";
import { getters } from "./model";
import { user as getUser } from "redux/modules/user/selectors";

const tabsFilter = (tasks, selectedTab) => {
  if (selectedTab === TABS.ALL) {
    return R.filter(R.propEq("is_complete", false), tasks);
  }

  if (selectedTab === TABS.DUE) {
    return R.filter(t => t.is_due && !t.is_complete, tasks);
  }

  return R.filter(R.propEq("is_complete", true), tasks);
};

export const getPastDueList = createSelector(
  getters.data,
  getters.filter,
  getters.selectedTab,
  (data, filter, selectedTab) => {
    const tasks = R.propOr([], "past_due", data);

    const filteredTasks = R.isEmpty(filter)
      ? tasks
      : R.filter(
          task => R.contains(R.toLower(filter), R.toLower(task.name)),
          tasks
        );

    return tabsFilter(filteredTasks, selectedTab);
  }
);

export const getUpcomingList = createSelector(
  getters.data,
  getters.filter,
  getters.selectedTab,
  (data, filter, selectedTab) => {
    const tasks = R.propOr([], "upcoming", data);

    const filteredTasks = R.isEmpty(filter)
      ? tasks
      : R.filter(
          task => R.contains(R.toLower(filter), R.toLower(task.name)),
          tasks
        );

    return tabsFilter(filteredTasks, selectedTab);
  }
);

export const getLaterList = createSelector(
  getters.data,
  getters.filter,
  getters.selectedTab,
  (data, filter, selectedTab) => {
    const tasks = R.propOr([], "later", data);

    const filteredTasks = R.isEmpty(filter)
      ? tasks
      : R.filter(
          task => R.contains(R.toLower(filter), R.toLower(task.name)),
          tasks
        );

    return tabsFilter(filteredTasks, selectedTab);
  }
);

export const getUserText = createSelector(
  getUser,
  user => `${user.fname} ${user.lname}'s Tasks`
);
