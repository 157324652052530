import * as R from "ramda";
import { NAMESPACE } from "Portal/PortalPeople/PersonModal/constants";

import { createHandlers } from "redux-mvc";

const iniState = {
  eventId: null,
  accountId: null,
  recordId: null,
  isLoading: false,
  relationship: {},
  record: {},
  module: {},
  itemBlocks: [],
  summary: [],
  contactPermissionSet: {},
  accountPermissionSet: {}
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    onSave: R.identity,
    setInitialData: (
      state,
      { payload: { module, payload, eventId, accountId, recordId } }
    ) => {
      return {
        eventId,
        accountId,
        recordId,
        module: R.pathOr([], ["module"], module),
        record: R.pathOr([], ["contact", "record"], payload),
        itemBlocks: R.pathOr([], ["item_blocks"], payload),
        summary: R.pathOr([], ["summary"], payload),
        accountPermissionSet: R.pathOr({}, ["account_permission_set"], payload),
        contactPermissionSet: R.pathOr({}, ["contact_permission_set"], payload)
      };
    },
    setRefreshedData: (_, { payload: { payload } }) => {
      return {
        record: R.pathOr([], ["contact", "record"], payload),
        itemBlocks: R.pathOr([], ["item_blocks"], payload),
        summary: R.pathOr([], ["summary"], payload)
      };
    },
    addValue: R.identity,
    addValueOnStore: (
      { record, record: { values } },
      { payload: { fieldId, value } }
    ) => ({
      record: {
        ...record,
        values: {
          ...values,
          [fieldId]: {
            ...(values[fieldId] || []),
            ...value
          }
        }
      }
    }),
    updateRelationship: R.identity,
    updateRelationshipOnStore: (
      { record, record: { values } },
      { payload: { value, field } }
    ) => ({
      relationship: {
        role: R.prop("value")(value)
      },
      record: {
        ...record,
        values: {
          ...values,
          [field.id]: {
            ...(values[field.id] || []),
            ...value
          }
        }
      }
    }),
    removeLineItem: R.identity,
    removeLineItemOnStore: (state, { payload: lineItemId }) => {
      return {
        summary: state.summary.map(section => ({
          ...section,
          item_groups: section.item_groups.map(group => ({
            ...group,
            items: group.items.filter(item => item.lineItemId !== lineItemId)
          }))
        }))
      };
    }
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
