import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Checkbox from "material-ui/Checkbox";
import { Div } from "components/Base";

@CSSModules(css)
class Footer extends PureComponent {
  handleClick = (e, val) => this.props.onSelectNone(val);
  render() {
    const { isNone, disabled, onClose, limit, remaining } = this.props;
    return (
      <div styleName="footer">
        <div style={{ flex: 1 }}>
          {limit ? (
            <Div color="gray6" fs={2}>
              Limit: {limit} ({remaining} remaining)
            </Div>
          ) : null}
          {/*
        // @NOTE: Hiding until we decide to re-implement
          <Checkbox
            label="None needed"
            checked={isNone}
            onCheck={this.handleClick}
            disabled={disabled}
          />
        */}
        </div>
        <div styleName="done" onClick={onClose}>
          done
        </div>
      </div>
    );
  }
}

Footer.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  ).isRequired,
  disabled: PropTypes.bool,
  isNone: PropTypes.bool,
  onSelectNone: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};
export default Footer;
