import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "../model";
import BulkItemsModal from "Event/AddBulkItemsModal/View/Layout";

const decorate = connect(
  state => ({
    showBulkItemsModal: getters.showBulkItemsModal(state)
  }),
  {
    hideModal: () => actions.setShowBulkItemsModal(false)
  }
);

const AddBulkItemsModal = ({ hideModal, showBulkItemsModal }) =>
  showBulkItemsModal ? <BulkItemsModal hideModal={hideModal} /> : null;

export default decorate(AddBulkItemsModal);
