import { createSelector } from "reselect";
import * as R from "ramda";

import { getters } from "EventLight/Agenda/AddSessionModal/model";
import * as FormSelectors from "ui-kit/Form/selectors";
import { isEventFeatureEnabled } from "redux/modules/event/selectors";
import { FEATURES } from "utils/event-types-and-feature-constants";

import {
  TABS,
  LOCATION_TYPE_IDS,
  LOCATION_TYPES
} from "EventLight/Agenda/AddSessionModal/constants";

export const getNextBtnText = createSelector(getters.activeTab, () => {
  // if (activeTab === TABS.CONTENT) {
  return "Save Session";
  // }
  // return "Next Step";
});

export const isExpoEnabled = createSelector(isEventFeatureEnabled, isEnabled =>
  isEnabled(FEATURES.EXPO)
);

export const isBiddingEnabled = createSelector(
  isEventFeatureEnabled,
  isEnabled => isEnabled(FEATURES.BIDDING)
);

export const getLocationOptions = createSelector(
  isExpoEnabled,
  isBiddingEnabled,
  (isExpoEnabled, isBiddingEnabled) => {
    let typesToReturn = LOCATION_TYPES;
    if (!isExpoEnabled) {
      typesToReturn = typesToReturn.filter(
        t => t.id !== LOCATION_TYPE_IDS.BOOTH
      );
    }
    if (!isBiddingEnabled) {
      typesToReturn = typesToReturn.filter(
        t => t.id !== LOCATION_TYPE_IDS.BIDDING
      );
    }
    return typesToReturn;
  }
);

export const getIsValid = createSelector(
  getters.activeTab,
  getters.data,
  (activeTab, data) => {
    if (activeTab === TABS.BASIC_DETAILS) {
      if (
        !data.title ||
        !data.title.length ||
        !data.starts_at ||
        !data.starts_at.length ||
        !data.ends_at ||
        !data.ends_at.length
      ) {
        return false;
      }
    }

    return true;
  }
);

export const getShowDraftBtn = createSelector(
  getters.activeTab,
  activeTab => activeTab === TABS.CONTENT
);

export const getShowNextIcon = createSelector(
  getters.activeTab,
  () => false // activeTab !== TABS.CONTENT
);

export const getShowBackBtn = createSelector(
  getters.activeTab,
  () => false // activeTab !== TABS.BASIC_DETAILS
);

export const getNextBtnWidth = createSelector(getters.activeTab, activeTab => {
  if (activeTab === TABS.CONTENT) {
    return 268;
  }
  return 322;
});

export const getEditedField = R.compose(
  field => R.prop("value", field),
  FormSelectors.getField
);
