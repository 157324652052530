import React from "react";
import * as R from "ramda";
import { Div, Text1, Text2, Text3 } from "components/Base";
import Circle from "ui-kit/Circle";

const ImportRow = ({
  name = "",
  email = "",
  initials = "",
  accountName = "",
  variantsItems = []
}) => (
  <Div
    width={1}
    bra={1}
    bc="neutral2"
    bg="white"
    display="row"
    p={2}
    shadow={1}
    mb={2}
  >
    <Div
      style={{
        width: R.length(variantsItems) > 0 ? "100%" : "50%"
      }}
      display="row"
    >
      <Div pr={2}>
        <Circle size={24}>
          <Text1 color="white" bold>
            {initials}
          </Text1>
        </Circle>
      </Div>
      <Div>
        <Text3 bold color="black">
          {name}
        </Text3>
        {!R.isEmpty(accountName) && (
          <Text2 bold color="neutral7">
            {accountName}
          </Text2>
        )}
        <Text1 color="neutral5">{email}</Text1>
      </Div>
    </Div>
    {R.length(variantsItems) > 0 && (
      <Div style={{ width: "50%" }}>
        {R.map(
          variant => (
            <Div key={variant.id} display="row.flex-start.center" mb={3}>
              <Circle
                size={7}
                style={{ backgroundColor: variant.backgroundColor }}
              />
              <Text1 bold mx={4} color="black">
                {variant.amount}
              </Text1>
              <Text1 bold>{variant.name}</Text1>
            </Div>
          ),
          variantsItems
        )}
      </Div>
    )}
  </Div>
);

export default ImportRow;
