import { createModule } from "redux-mvc";

import model from "./model";
import rootSaga from "./sagas";
import EditTextModule from "./EditText";

const module = createModule({
  ...model,
  observedDomains: [
    "user",
    "event",
    "permissions",
    "organization",
    "modal",
    "@flopflip",
    "formsV2"
  ]
});
module.setRootSaga(rootSaga);
module.plugModule(EditTextModule);
export default module;
