import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Sidebar from "./Sidebar";
import { isVisible } from "redux/modules/modules/sidebar/selectors";
import { showSidebar } from "redux/modules/modules/sidebar/actions";

function mapStateToProps(state, props) {
  return {
    isVisible: isVisible(state, props.moduleId)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSidebar
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
