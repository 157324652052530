import React from "react";
import { connect } from "react-redux";
import { Div, SuperOutlineButton } from "components/Base";
import Header from "Forms/WizardModal/View/Header";
import { actions } from "Forms/WizardModal";
import SelectOneRequest from "./SelectOneRequest";
import SelectCollectBulk from "./SelectCollectBulk";
import SelectMultiCategory from "./SelectMultiCategory";
import { PAGES } from "Forms/WizardModal/constants";

const decorate = connect(
  null,
  {
    back: () => actions.setSelectedPage(PAGES.SELECT_METHOD)
  }
);

const SelectMethod = ({ handlers, back }) => (
  <Div width={1} display="column.flex-start" height={1}>
    <Header handlers={handlers} />
    <Div
      bg="neutral0"
      display="column.flex-start.center"
      p={5}
      flex={1}
      style={{ overflowY: "auto" }}
    >
      <Div fs={6} fw={4} color="black">
        How do you want to collect asset &amp; equipment requests?
      </Div>
      <SelectCollectBulk />
      <SelectMultiCategory />
      <SelectOneRequest />
    </Div>
    <Div
      display="row.space-between.center"
      width={1}
      height={90}
      bt={1}
      bc="neutral2"
      bg="white"
      px={7}
      style={{ flexShrink: 0 }}
    >
      <SuperOutlineButton onClick={back}>Back</SuperOutlineButton>
      <SuperOutlineButton onClick={handlers.hideModal}>
        Cancel
      </SuperOutlineButton>
    </Div>
  </Div>
);

export default decorate(SelectMethod);
