import React from "react";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { connect } from "react-redux";
import { actions } from "../model";
import { getNextBtnText, getIsNextBtnDisabled } from "../selectors";
import { BigFilledButton } from "components/Base";

const decorate = R.compose(
  connect(
    state => ({
      btnText: getNextBtnText(state),
      isBtnDisabled: getIsNextBtnDisabled(state)
    }),
    {
      nextBtnActions: actions.nextBtnActions
    }
  ),
  CSSModules(css)
);

const NextButton = ({ btnText, isBtnDisabled, nextBtnActions }) => (
  <BigFilledButton
    bg="purple7"
    width={1}
    disabled={isBtnDisabled}
    onClick={nextBtnActions}
  >
    {btnText}
  </BigFilledButton>
);

export default decorate(NextButton);
