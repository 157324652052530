import React, { Component } from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import Fuse from "fuse.js";
import View from "./View";
import {
  GridView,
  ListView,
  KanbanView,
  ReportView
} from "components/Global/Module/Sidebar/ViewSelector/Popover/Icons";

const getViewIcon = type => {
  switch (type) {
    case "list":
      return <ListView size={13} />;
    case "grid":
      return <GridView size={13} />;
    case "kanban":
      return <KanbanView size={13} />;
    case "report":
      return <ReportView size={17} />;
    default:
      return null;
  }
};

const searchWithFuse = (searchTerm, fuse, opt, matchWith) => {
  if (R.isNil(searchTerm) || R.isEmpty(searchTerm)) {
    return opt;
  }
  return R.compose(
    R.reject(R.isNil),
    R.map(o => R.find(R.propEq(matchWith, o.id), opt))
  )(fuse.search(searchTerm));
};

class InternalHandler extends Component {
  state = {
    searchTerm: ""
  };

  componentWillMount() {
    this.viewsFuse = new Fuse(this.props.views, {
      threshold: 0.3,
      keys: ["name", "type"],
      shouldSort: true
    });
  }

  componentDidMount() {
    if (this.viewRefs[`ref_${this.props.activeViewId}`]) {
      this.viewRefs[`ref_${this.props.activeViewId}`].scrollIntoView(false);
    }
    if (this.viewRefs.ref_search) {
      this.viewRefs.ref_search.focus();
    }
  }

  componentWillReceiveProps(nextProps) {
    this.viewsFuse = new Fuse(nextProps.views, {
      threshold: 0.3,
      keys: ["name", "type"],
      shouldSort: true
    });
  }

  onSearch = e => this.setState({ searchTerm: e.target.value });

  onClearSearch = () => this.setState({ searchTerm: "" });

  viewRefs = {};

  registerRef = (ref, id) => {
    this.viewRefs[`ref_${id}`] = ref;
  };

  render() {
    const { searchTerm } = this.state;
    const {
      types,
      moduleId,
      onSortViews,
      addView,
      selectView,
      views,
      activeViewId,
      reports
    } = this.props;
    const isSearching = Boolean(searchTerm.length);
    const viewsWithHandlers = R.map(
      view => ({
        ...view,
        icon: getViewIcon(view.view_type),
        isActive: view.id === activeViewId,
        onClick: () => selectView(view.id)
      }),
      searchWithFuse(searchTerm, this.viewsFuse, views, "id")
    );
    const typesWithHandlers = R.map(
      type => ({
        ...type,
        onClick: () => (type.icon ? addView({ type: type.id }) : null),
        addView
      }),
      types
    );

    return (
      <View
        {...{
          views: viewsWithHandlers,
          types: typesWithHandlers,
          onSearch: this.onSearch,
          onClearSearch: this.onClearSearch,
          searchTerm,
          countOfMatchingViews: viewsWithHandlers.length,
          isSearching,
          onSortViews,
          addView,
          registerRef: this.registerRef
        }}
      />
    );
  }
}

InternalHandler.defaultProps = {
  reports: []
};

InternalHandler.propTypes = {
  moduleId: PropTypes.string.isRequired,
  onSortViews: PropTypes.func.isRequired,
  addView: PropTypes.func.isRequired,
  selectView: PropTypes.func.isRequired,
  activeViewId: PropTypes.string.isRequired,
  views: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  ).isRequired,
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.string,
      enabled: PropTypes.func
    })
  ),
  types: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
      enabled: PropTypes.bool
    })
  ).isRequired
};

export default InternalHandler;
