/* eslint-disable react/prop-types */

import React from "react";
import * as R from "ramda";
import {
  Div,
  CloseIcon,
  DownIcon,
  CheckIcon,
  HalfSizeDotIcon,
  NoneIcon,
  LeftIcon,
  Collapsable,
  collapsableHandler,
  TinyShadedButton,
  SmallTextBox,
  Text0,
  Text2,
  Text3
} from "components/Base";
import { defaultProps, withProps } from "utils/General";

const RowCell = withProps({ display: "row.flex-end.center" })(Div);

const TableRow = ({ content, ...props }) => {
  return (
    <Div display={"row.flex-start.center"} {...props}>
      <Div width={1 / 2}>{content[0]}</Div>
      <RowCell width={2 / 14}>{content[1]}</RowCell>
      <RowCell width={2 / 14}>{content[2]}</RowCell>
      <RowCell width={2 / 14}>{content[3]}</RowCell>
      <RowCell width={1 / 14}>{content[4]}</RowCell>
    </Div>
  );
};

const ItemTitle = ({ title, color, isSubitem, isIssued, deleteItem }) => (
  <Div py={2} display={"row.flex-start.center"}>
    {!isSubitem ? (
      <HalfSizeDotIcon
        size={16}
        color={color || "neutral7"}
        style={{ flexShrink: 0 }}
      />
    ) : null}
    <Text3 ml={!isSubitem ? 1 : 5} bold color={"neutral8"}>
      {title}
    </Text3>
    {isIssued ? (
      <SmallTextBox LeftIcon={CheckIcon}>Issued</SmallTextBox>
    ) : deleteItem ? (
      <TinyShadedButton
        ml={2}
        bigIcons
        onClick={deleteItem}
        OnlyIcon={CloseIcon}
      />
    ) : null}
  </Div>
);

const HeaderTitle = ({ title, color }) => (
  <Div py={2} display={"row.flex-start.center"}>
    {color ? (
      <HalfSizeDotIcon size={16} color={color} />
    ) : (
      <NoneIcon size={16} />
    )}
    <Text3 ml={1} color={"neutral8"}>
      {title}
    </Text3>
  </Div>
);

const TogglableGroup = collapsableHandler(
  ({ toggleCollapsed, collapsed, header, rows }) => {
    let appliedHeader = header;
    appliedHeader[4] = collapsed ? (
      <LeftIcon size={24} color={"neutral6"} />
    ) : (
      <DownIcon size={24} color={"neutral6"} />
    );
    return (
      <Div>
        <TableRow onClick={toggleCollapsed} content={appliedHeader} />
        <Collapsable collapsed={collapsed}>
          {R.map(
            row => (
              <TableRow content={[row, null, null, null, null]} />
            ),
            rows
          )}
        </Collapsable>
      </Div>
    );
  }
);

const ItemGroup = ({ groupSummary, items }) => {
  let header = [
    <HeaderTitle title={groupSummary.title} color={groupSummary.color} />,
    groupSummary.requested,
    groupSummary.approved,
    groupSummary.issued,
    null
  ];
  if (R.length(items) === 1) {
    const item = items[0];
    header[0] = (
      <ItemTitle
        title={
          groupSummary.title === item.title
            ? groupSummary.title
            : `${groupSummary.title} - ${item.title}`
        }
        color={groupSummary.color}
        isIssued={item.isIssued}
        deleteItem={item.deleteItem}
        isSubitem={false}
      />
    );
    return <TableRow content={header} />;
  }

  const rows = R.map(({ title, isIssued, deleteItem }) => {
    return (
      <ItemTitle
        key={title}
        title={title}
        isIssued={isIssued}
        deleteItem={deleteItem}
        isSubitem
      />
    );
  }, items);
  return <TogglableGroup collapsedDefault header={header} rows={rows} />;
};

const SectionContainer = defaultProps({ px: 2, ba: 1, bra: 1, bc: "neutral3" })(
  Div
);

const Summary = ({ mobile = false, summarySections = [] }) => (
  <SectionContainer py={3} px={0}>
    {R.map(
      ({ title, item_groups }) => (
        <Div pl={2} mb={2}>
          <TableRow
            content={[
              <Text2 bold uppercase color={"neutral5"}>
                {title}
              </Text2>,
              <Text0 bold uppercase color={"neutral5"}>
                {"Requested"}
              </Text0>,
              mobile ? (
                <CheckIcon size={16} />
              ) : (
                <Text0 bold uppercase color={"neutral5"}>
                  {"Approved"}
                </Text0>
              ),
              <Text0 bold uppercase color={"neutral5"}>
                {"Issued"}
              </Text0>,
              null
            ]}
          />
          {R.map(({ group_summary, items }) => {
            return (
              <Div bb={1} bc={"neutral3"}>
                <ItemGroup groupSummary={group_summary} items={items} />
              </Div>
            );
          }, item_groups)}
        </Div>
      ),
      summarySections
    )}
  </SectionContainer>
);

export default Summary;
