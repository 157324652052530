import React from "react";
import * as R from "ramda";

import { Div, collapsableHandler, DownIcon } from "components/Base";

import { noop, withProps } from "utils/General";

const isCollapsed = (opened, collapsed, controlled) => {
  if (controlled) {
    return !opened;
  }
  return collapsed;
};

const getBackground = ({ collapsed, expandedBg, bg }) => {
  if (R.not(R.isEmpty(expandedBg)) && R.not(collapsed)) {
    return expandedBg;
  }
  return bg;
};

const getDisplay = (accentBorder, collapsed) => {
  if (!accentBorder) {
    return "row.space-between.stretch";
  }
  if (collapsed) {
    return "row.space-between.stretch";
  } else {
    return "column.flex-start.stretch";
  }
};

const getBorderAccent = collapsed =>
  collapsed
    ? {
        width: 3
      }
    : { height: 3, width: 1 };

const getBorderWidth = (accentBorder, collapsed) => {
  if (!accentBorder) {
    return {};
  }
  if (collapsed) {
    return { bt: 1, bb: 1, br: 1 };
  }
  return { br: 1, bl: 1, bb: 1 };
};

const renderHeader = (header, collapsed) => {
  if (typeof header === "function") {
    return header(collapsed);
  } else {
    return header;
  }
};

const CollapsiblePanel = collapsableHandler(
  ({
    collapsed: uCollapsed,
    toggleCollapsed,
    heading,
    children,
    opened,
    onClick = noop,
    isArrowVisible = true,
    arrowIcon = DownIcon,
    arrowColor = "rgba(255, 255, 255, 0.4)",
    arrowAlignment = "middle",
    right = true,
    collapsedDefault, // eslint-disable-line no-unused-vars
    expandedBg = "",
    hoverHeaderBg = null,
    bg = "neutral0",
    accentBorder = "",
    ...styleProps
  }) => {
    const DownPanelIcon = arrowIcon;
    const RightPanelIcon = withProps({
      transition: "fast",
      style: {
        transform: "rotate(-90deg)"
      }
    })(arrowIcon);

    const collapsed = isCollapsed(opened, uCollapsed, onClick !== noop);

    const defaultBg = getBackground({
      expandedBg,
      bg,
      collapsed
    });

    return (
      <Div
        bg={
          hoverHeaderBg
            ? {
                hover: hoverHeaderBg,
                default: defaultBg
              }
            : defaultBg
        }
        {...getBorderWidth(accentBorder, collapsed)}
        {...styleProps}
      >
        <Div display={getDisplay(accentBorder, collapsed)}>
          {accentBorder && (
            <Div
              bg={accentBorder}
              color="transparent"
              {...getBorderAccent(collapsed)}
            >
              a
            </Div>
          )}
          <Div
            display={
              arrowAlignment === "top"
                ? "row.flex-start.top"
                : "row.flex-start.center"
            }
            flex={1}
            onClick={() => {
              if (onClick !== noop) {
                onClick();
              } else {
                toggleCollapsed();
              }
            }}
          >
            {isArrowVisible && !right ? (
              collapsed ? (
                <RightPanelIcon sizeWFS={6} color={arrowColor} ml={1} />
              ) : (
                <DownPanelIcon sizeWFS={6} color={arrowColor} ml={1} />
              )
            ) : null}
            {renderHeader(heading, collapsed)}
            {isArrowVisible && right ? (
              collapsed ? (
                <RightPanelIcon sizeWFS={6} color={arrowColor} mr={1} />
              ) : (
                <DownPanelIcon sizeWFS={6} color={arrowColor} mr={1} />
              )
            ) : null}
          </Div>
        </Div>
        {collapsed ? null : children}
      </Div>
    );
  }
);

export default CollapsiblePanel;
