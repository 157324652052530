import React from "react";
import { Div } from "components/Base";
import InputFieldStatic from "./InputFieldStatic";
import TitleBox from "./TitleBox";
import Button from "./Button";

const columnsWidth = 110;

const InputRow = ({ secondColumn = false }) => (
  <Div>
    <Div width={1} display="row.flex-start.flex-end" mb={2}>
      <Div display="column" width={columnsWidth} mr={2}>
        <TitleBox />
        <InputFieldStatic downIcon />
        {secondColumn && <InputFieldStatic mt={2} downIcon />}
      </Div>
      <Div display="column" width={columnsWidth} mr={2}>
        <TitleBox width={70} />
        <InputFieldStatic />
        {secondColumn && <InputFieldStatic mt={2} />}
      </Div>
      <Div display="column" width={columnsWidth} mr={2}>
        <TitleBox width={50} />
        <InputFieldStatic />
        {secondColumn && <InputFieldStatic mt={2} />}
      </Div>
      <Div display="column" width={columnsWidth} mr={2}>
        <Div display="row">
          <TitleBox width={30} />
          <TitleBox width={40} styleProps={{ ml: 1 }} />
        </Div>
        <InputFieldStatic />
        {secondColumn && <InputFieldStatic mt={2} />}
      </Div>
    </Div>
    <Button title="Add Another" />
  </Div>
);

export default InputRow;
