import { RECEIVE_ITEM, REQUEST_ITEM } from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getItem(itemId) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: REQUEST_ITEM,
        payload: {
          itemId
        }
      });
      const result = await api.getItem(
        getState().user.user.credentials,
        itemId
      );
      dispatch({
        type: RECEIVE_ITEM,
        payload: {
          itemId,
          item: result.payload[0]
        }
      });
      return result.payload[0];
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching item"
          }
        ])
      );
    }
  };
}

export function addItem(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.addItem(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding item"
          }
        ])
      );
    }
  };
}

export function updateItem(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.updateItem(
        getState().user.user.credentials,
        data
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating item"
          }
        ])
      );
    }
  };
}

export function bulkUpdateItems(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.bulkUpdateItems(
        getState().user.user.credentials,
        data
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred bulk updating items"
          }
        ])
      );
    }
  };
}

export function deleteItem(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.deleteItem(
        getState().user.user.credentials,
        data
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting item"
          }
        ])
      );
    }
  };
}

export function deleteItemProviderId(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.deleteItemProviderId(
        getState().user.user.credentials,
        data
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting item provider"
          }
        ])
      );
    }
  };
}
