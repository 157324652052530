import PropTypes from "prop-types";
import React from "react";
import { get } from "lodash";
import Summary from "./Summary";

import countSummaries from "components/Event/FormsV2/Overview/utils/summaries/submission";
import validatedEntries from "components/Event/FormsV2/Overview/utils/summaries/validEntries";
import getCredentialSourceBadge from "components/Event/Credentials/Utils/get-credential-source-badge";
import getCatalogItemNamesFromFields from "utils/Modules/catalog-item/id-to-name-hash-from-fields";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

const getSummaries = (submissions, subforms, details) => {
  const credentials = get(
    details,
    "module_settings.credentials.credentials",
    []
  ).reduce((hash, credential) => {
    hash[credential.id] = credential;
    return hash;
  }, {});

  const catering = get(details, "module_settings.catering.meals", []).reduce(
    (hash, meal) => {
      hash[meal.id] = meal;
      return hash;
    },
    {}
  );

  return subforms.map(field => {
    const summary = countSummaries(submissions, [field], details);
    const entries = [];
    const catalogItems = getCatalogItemNamesFromFields([field]);

    if (summary.credentials) {
      entries.push({
        label: "Credentials",
        values: Object.keys(summary.credentials.requested).reduce(
          (counts, id) => {
            if (id in credentials) {
              counts.push({
                label: (
                  <span key={id}>
                    {getCredentialSourceBadge({
                      credential: credentials[id],
                      size: 10
                    })}
                    {credentials[id].value}
                  </span>
                ),
                requested: summary.credentials.requested[id],
                approved: get(summary.credentials, `approved[${id}]`, 0),
                rejected: get(summary.credentials, `rejected[${id}]`, 0)
              });
            }
            return counts;
          },
          []
        )
      });
    }

    if (summary.catering) {
      entries.push({
        label: "Meals",
        values: Object.keys(summary.catering.requested).reduce((counts, id) => {
          if (id in catering) {
            counts.push({
              label: catering[id].value,
              requested: summary.catering.requested[id],
              approved: get(summary.catering, `approved[${id}]`, 0),
              rejected: get(summary.catering, `rejected[${id}]`, 0)
            });
          }
          return counts;
        }, [])
      });
    }

    if (summary["catalog-item"]) {
      entries.push({
        label: "Catalog Items",
        values: Object.keys(summary["catalog-item"].requested).reduce(
          (counts, id) => {
            if (id in catalogItems) {
              counts.push({
                label: catalogItems[id],
                requested: summary["catalog-item"].requested[id],
                approved: get(summary["catalog-item"], `approved[${id}]`, 0),
                rejected: get(summary["catalog-item"], `rejected[${id}]`, 0)
              });
            }
            return counts;
          },
          []
        )
      });
    }

    if (summary.inventory) {
      entries.push({
        label: "Item Names",
        values: Object.keys(summary.inventory.requested).map(id => ({
          label: id,
          requested: summary.inventory.requested[id],
          approved: get(summary.inventory, `approved[${id}]`, 0),
          rejected: get(summary.inventory, `rejected[${id}]`, 0)
        }))
      });
    }

    return {
      name: field.name,
      entries: validatedEntries(entries)
    };
  });
};

const Summaries = ({ details, submissions, fields, readOnly }) => {
  const subforms = fields.filter(f => f.type === "subform");
  return (
    <div>
      {getSummaries(submissions, subforms, details)
        .filter(s => s.entries.length)
        .map(s => (
          <div key={s.name}>
            <div styleName="fieldLabel">{s.name}</div>
            <Summary readOnly={readOnly} counts={s.entries} />
          </div>
        ))}
    </div>
  );
};

Summaries.propTypes = {
  details: PropTypes.object.isRequired,
  submissions: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  readOnly: PropTypes.bool
};

export default CSSModules(Summaries, css);
