import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import PageTitle from "EventLight/Common/PageTitle";

import Loading from "ui-kit/Loading";
import { Page, Title } from "EventLight/Common/FieldLayout";
import Body from "./Body";
import Header from "./Header";
import SimpleFormModal from "EventLight/Forms/Forms/View/Modals/SimpleFormModal";

const decorate = connect(
  state => ({
    loading: getters.loading(state),
    showSimpleFormModal: getters.showSimpleFormModal(state)
  }),
  {
    init: actions.init
  }
);

const Layout = ({ loading, init, showSimpleFormModal }) => {
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <PageTitle titles={["Forms"]} />
      <Page>
        <Title
          icon="web"
          title="Forms"
          description="Create and share forms to collect information"
        />

        {loading ? (
          <Loading />
        ) : (
          <>
            <Header />
            {/* <PortalBar /> */}
            {showSimpleFormModal ? <SimpleFormModal /> : null}
            <Body />
          </>
        )}
      </Page>
    </>
  );
};

export default decorate(Layout);
