import React from "react";
import { connect } from "react-redux";

import { actions } from "../model";

import { Div, Text5 } from "components/Base";
import { Input } from "ui-kit/Form/View";

import { ACTIVITY_NAME } from "../constants";
import { isEditingField, getIsComplete, getActivityName } from "../selectors";
import StatusCircle from "ui-kit/StatusCircle";

const decorate = connect(
  (state, props) => ({
    name: getActivityName(state, props),
    isCompleted: getIsComplete(state, props),
    isEditing: isEditingField(state, {
      ...props,
      field: { id: ACTIVITY_NAME }
    })
  }),
  {
    onEdit: actions.edit,
    markCompleted: actions.markCompleted
  }
);

const TitleEditor = ({
  name,
  isEditing,
  isCompleted,
  onEdit,
  markCompleted
}) => {
  return (
    <Div display="row.flex-start.center" my={2}>
      <StatusCircle
        size={35}
        onClick={() => markCompleted(!isCompleted)}
        status={isCompleted ? "task_complete" : "task_not_complete"}
      />

      {isEditing ? (
        <Div width={1} py={2} px={1} ml={2} onClick={e => e.stopPropagation()}>
          <Input fieldId={ACTIVITY_NAME} width={1} iniValue={name} autoFocus />
        </Div>
      ) : (
        <Div
          width={1}
          py={2}
          px={1}
          onClick={e => {
            onEdit(ACTIVITY_NAME);
            e.stopPropagation();
          }}
          bg={{ default: "white", hover: "neutral0" }}
          bra={1}
          ml={2}
        >
          <Text5 bold>{name}</Text5>
        </Div>
      )}
    </Div>
  );
};

export default decorate(TitleEditor);
