import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "Portal/PortalPagePGA/AllNewsAndAlertsList/constants";

const iniState = {
  newsAndAlerts: [],
  loading: true
};

export const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    setInitialData: (_, { payload }) => ({
      loading: false,
      newsAndAlerts: payload
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
