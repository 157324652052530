import React, { useEffect } from "react";
import { Page, Title } from "EventLight/Common/FieldLayout";
import Body from "./Body";

import { connect } from "react-redux";
import { getters, actions } from "../model";

import Loading from "ui-kit/Loading";

const decorate = connect(
  state => ({
    loading: getters.loading(state)
  }),
  {
    init: actions.init
  }
);

const Layout = ({ loading, init }) => {
  useEffect(() => {
    init();
  }, []);

  return (
    <Page>
      <Title
        icon="assignment_turned_in"
        title="Design"
        description="Update the templates that are used to print your different types of passes"
      />
      {loading ? <Loading /> : <Body />}
    </Page>
  );
};

export default decorate(Layout);
