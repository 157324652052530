import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import ReportSummary from "components/Global/Reports/Summary/Table";
import tooltipFactory from "components/Global/Factories/TruncateTextTooltip";
import NumberFormatter from "components/Event/InventoryV2/Home/ReportPanel/Formatters/Number";

const formatRows = rows => rows.map(r => ({ ...r, ...r.counts }));

const LabelFormatter = CSSModules(
  ({ value }) => <div styleName="label">{value}</div>,
  css
);

const FIELDS = [
  {
    id: "name",
    formatter: tooltipFactory(LabelFormatter),
    label: "Item Name",
    style: { width: "50%", textAlign: "left" }
  },
  {
    id: "requested",
    label: "Req",
    formatter: NumberFormatter,
    style: { textAlign: "right" }
  },
  {
    id: "approved",
    label: "Approved",
    formatter: NumberFormatter,
    style: { textAlign: "right" }
  }
];

const ItemSummary = ({ summary }) => (
  <div styleName="container">
    <ReportSummary fields={FIELDS} rows={formatRows(summary.items)} />
  </div>
);

ItemSummary.propTypes = {
  summary: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        counts: PropTypes.shape({
          approved: PropTypes.number,
          requested: PropTypes.number
        }).isRequired
      })
    ).isRequired
  })
};

export default CSSModules(ItemSummary, css);
