import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import FormElements from "components/Global/Modal/Layout/FormElements";
import CheckBox from "material-ui/Checkbox";

const { InputGroup, Label, Input } = FormElements;

const Receipt = CSSModules(
  ({
    email,
    updateEmail,
    showSendConfirmationOption,
    sendConfirmation,
    toggleSendConfirmation
  }) => (
    <div styleName="container">
      <div styleName="heading">Send receipt to...</div>
      <div styleName="groups">
        {showSendConfirmationOption ? (
          <InputGroup className={css.group}>
            <Label>
              <div styleName="label">Send Receipt?</div>
            </Label>
            <CheckBox
              checked={sendConfirmation}
              onCheck={() => toggleSendConfirmation()}
            />
          </InputGroup>
        ) : null}
        <InputGroup className={css.group}>
          <Label>
            <div styleName="label">
              Email
              {sendConfirmation ? <span styleName="required">*</span> : null}
            </div>
          </Label>
          <Input
            onChange={updateEmail}
            type="text"
            value={email}
            required={sendConfirmation}
          />
        </InputGroup>
      </div>
    </div>
  ),
  css
);

export default Receipt;
