import React from "react";

import { connect } from "react-redux";

import * as R from "ramda";

import moment from "moment";

import {
  Div,
  Text2,
  BigOutlineInput,
  BoxGroup,
  BigOutlineBox,
  BigFilledButton,
  CalendarIcon,
  DatePicker,
  AddIcon,
  MediumFilledButton
} from "components/Base";

import { actions, getters } from "Organizations/EventDetails";

const DateSpan = ({ onClick, dateString }) => (
  <BoxGroup m={1} display="row.flex-start.flex-start">
    <BigOutlineBox bg="white">{dateString}</BigOutlineBox>
    <BigFilledButton onClick={onClick} OnlyIcon={CalendarIcon} />
  </BoxGroup>
);

const emptyDateSpan = ({ onClick }) => (
  <Div onClick={onClick} ml={2} display="row.flex-start.center">
    <AddIcon color="primary7" mr={1} />
    <Text2 color="primary7">Add End Date</Text2>
  </Div>
);

const decorate = connect(
  state => ({
    startDate: getters.startDate(state),
    endDate: getters.endDate(state),
    status: getters.status(state),
    eventName: getters.eventName(state),
    logoImageUrl: getters.logoImageUrl(state),
    backgroundImageUrl: getters.backgroundImageUrl(state),
    onBlurEnabled: getters.onBlurEnabled(state)
  }),
  {
    updateEventStartDate: actions.updateEventStartDate,
    updateEventEndDate: actions.updateEventEndDate,
    updateEventStatus: actions.updateEventStatus,
    updateEventName: actions.updateEventName
  }
);

const EventDetails = ({
  startDate,
  updateEventStartDate,
  eventName,
  updateEventName,
  endDate,
  updateEventEndDate,
  logoImageUrl,
  uploadLogoImg = () => {},
  backgroundImageUrl,
  uploadHeaderImg = () => {},
  onBlurEnabled
}) => {
  return (
    <Div p={7}>
      <Div display="row.flex-start.center" mb={6}>
        <Div width={250} fs={4} fw={3} mr={4}>
          Name of Event
        </Div>
        <BigOutlineInput
          bg="white"
          width={400}
          value={eventName}
          onChange={value => {
            updateEventName({ name: value });
          }}
          continuous={!onBlurEnabled}
        />
      </Div>

      <Div display="row.flex-start.center" mb={6}>
        <Div width={250} fs={4} fw={3} mr={4}>
          Event Date(s)
        </Div>
        <Div display="row.flex-start.center">
          <DatePicker
            defaultValue={startDate}
            onChange={date => {
              updateEventStartDate({ startDate: moment(date).format() });
            }}
            labelProps={{
              dateString: moment(startDate).format("ddd, MMM DD, YYYY")
            }}
            Label={DateSpan}
          />

          <DatePicker
            defaultValue={endDate}
            onChange={date => {
              updateEventEndDate({ endDate: moment(date).format() });
            }}
            labelProps={{
              dateString: moment(endDate).format("ddd, MMM DD, YYYY")
            }}
            Label={R.isEmpty(endDate) ? emptyDateSpan : DateSpan}
          />
        </Div>
      </Div>

      <Div display="row.flex-start.center" mb={6}>
        <Div width={250} fs={4} fw={3} mr={4}>
          Event Logo
        </Div>
        <Div display="row.space-between.center">
          <Div>
            <MediumFilledButton width={1} bg="neutral2" onClick={uploadLogoImg}>
              Upload New
            </MediumFilledButton>
            <Text2>Recommended: 600 w x 600 h</Text2>
          </Div>
          <Div
            bg="neutral0"
            width={100}
            height={100}
            mr={5}
            b={1}
            bc="neutral4"
            bra={1}
            style={{
              flexShrink: 0,
              backgroundImage: logoImageUrl
                ? `url("${logoImageUrl}")`
                : "inherit",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "contain"
            }}
            ml={2}
          />
        </Div>
      </Div>

      <Div display="row.flex-start.center" mb={6}>
        <Div width={250} fs={4} fw={3} mr={4}>
          Event Header
        </Div>
        <Div display="row.space-between.center">
          <Div>
            <MediumFilledButton
              width={1}
              bg="neutral2"
              onClick={uploadHeaderImg}
            >
              Upload New
            </MediumFilledButton>
            <Text2>Recommended: 1500 w x 500 h</Text2>
          </Div>
          <Div
            bg="neutral0"
            width={150}
            height={43}
            mr={5}
            b={1}
            bc="neutral4"
            bra={1}
            style={{
              flexShrink: 0,
              backgroundImage: backgroundImageUrl
                ? `url("${backgroundImageUrl}")`
                : "inherit",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "top center",
              backgroundSize: "cover"
            }}
            ml={2}
          />
        </Div>
      </Div>
    </Div>
  );
};

export default decorate(EventDetails);
