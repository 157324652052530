import { REQUEST, RECEIVE } from "./constants";
import * as selectors from "./selectors";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getNeedsOverviewReport(eventId, moduleId = "all") {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState(), eventId, moduleId)) return;
    try {
      dispatch({
        type: REQUEST,
        payload: {
          eventId,
          moduleId
        }
      });
      const result = await api.get(
        getState().user.user.credentials,
        eventId,
        moduleId
      );
      dispatch({
        type: RECEIVE,
        payload: {
          eventId,
          moduleId,
          report: result.payload
        }
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching needs overview report"
          }
        ])
      );
    }
  };
}
