import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Day from "components/Global/EventDaySelector/Calendar/Day";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class Week extends Component {
  render() {
    const {
      handleDayClick,
      days,
      availableDayKeys,
      availableDayMap,
      disabled
    } = this.props;
    return (
      <div styleName="container">
        {days.map(d => (
          <Day
            key={d.ydm}
            disabled={disabled}
            meta={d}
            availableDayMap={availableDayMap}
            isAvailable={availableDayKeys.includes(d.ydm)}
            onClick={handleDayClick}
          />
        ))}
      </div>
    );
  }
}

Week.propTypes = {
  days: PropTypes.array.isRequired,
  availableDayKeys: PropTypes.array.isRequired,
  availableDayMap: PropTypes.object.isRequired,
  handleDayClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default Week;
