import React from "react";
import * as R from "ramda";

import { connect } from "react-redux";
import { WithFormInstanceProvider } from "ui-kit/Form/View";
import { Div, BigFilledButton } from "components/Base";
import {
  Label,
  FloatingSaveBar,
  Panel,
  PanelTitle,
  FieldWrapper
} from "EventLight/Common/FieldLayout";

import {
  FORM_ID,
  FIELD_IDS,
  DATE_FORMAT,
  FREQUENCY_OPTIONS,
  NUM_HOURS_OPTIONS,
  SEND_AT_TIME_OPTIONS
} from "../constants";
import { actions, getters } from "../model";
import { getIsValid } from "../selectors";

import {
  Input,
  Dropdown,
  DropdownAsync,
  AdvancedWYSIWYG,
  Image,
  DateTimeInput
} from "ui-kit/Form/View";

import { setLocalZone } from "utils/General";

import Fields from "./Fields";

const decorate = R.compose(
  WithFormInstanceProvider(R.always(FORM_ID)),
  connect(
    state => ({
      error: getters.error(state),
      data: getters.data(state),
      timezone: getters.timezone(state),
      isValid: getIsValid(state)
    }),
    {
      save: actions.save
    }
  )
);

const Body = ({ data, timezone, save, error, isValid }) => (
  <Div display="column">
    <Panel>
      <PanelTitle description="Customize how individuals see your questionnaire">
        Settings
      </PanelTitle>

      <FieldWrapper>
        <Label>Background Image</Label>
        <Image
          fieldId={FIELD_IDS.BACKGROUND_IMAGE_URL}
          containerWidth="auto"
          containerProps={{
            display: "row.flex-start.flex-start",
            flex: 1
          }}
          imageWidth={198}
          imageHeight={57}
          recommendedWidth={"1500"}
          recommendedHeight={"500"}
          iniValue={
            R.prop(FIELD_IDS.BACKGROUND_IMAGE_URL, data)
              ? [
                  {
                    url: R.prop(FIELD_IDS.BACKGROUND_IMAGE_URL, data)
                  }
                ]
              : null
          }
        />
      </FieldWrapper>

      <FieldWrapper>
        <Label>Welcome Title</Label>
        <Input
          fieldId={FIELD_IDS.WELCOME_TITLE}
          width={1}
          size="big"
          iniValue={R.prop(FIELD_IDS.WELCOME_TITLE)(data)}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Label tooltip="Add any instructions or welcome text before people start answering questions">
          Welcome Message
        </Label>
        <AdvancedWYSIWYG
          fieldId={FIELD_IDS.WELCOME_MESSAGE}
          width={"100%"}
          iniValue={R.prop(FIELD_IDS.WELCOME_MESSAGE)(data)}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Label tooltip="When should people begin receiving questionnaires?">
          Testing Begins At
        </Label>
        <DateTimeInput
          fieldId={FIELD_IDS.TESTING_BEGINS_AT}
          dateFormat={DATE_FORMAT}
          timezone={timezone}
          iniValue={setLocalZone(
            R.propOr("", FIELD_IDS.TESTING_BEGINS_AT, data),
            timezone
          )}
        />
        <Div mt={1} fs={1} color="gray5" fw={2}>
          Event Timezone: {timezone}
        </Div>
      </FieldWrapper>

      <FieldWrapper>
        <Label tooltip="When should people stop receiving questionnaires?">
          Testing Ends At
        </Label>
        <DateTimeInput
          fieldId={FIELD_IDS.TESTING_ENDS_AT}
          dateFormat={DATE_FORMAT}
          timezone={timezone}
          iniValue={setLocalZone(
            R.propOr("", FIELD_IDS.TESTING_ENDS_AT, data),
            timezone
          )}
        />
        <Div mt={1} fs={1} color="gray5" fw={2}>
          Event Timezone: {timezone}
        </Div>
      </FieldWrapper>

      <FieldWrapper>
        <Label>
          How often should an individual have to fill out a questionnaire?
        </Label>
        <Dropdown
          fieldId={FIELD_IDS.FREQUENCY}
          width="100%"
          iniValue={R.prop(FIELD_IDS.FREQUENCY)(data)}
          options={FREQUENCY_OPTIONS}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Label>
          What time should indiviudals receive an email reminder
          {R.prop(FIELD_IDS.FREQUENCY)(data) === "recurring" ? " each day" : ""}
          ?
        </Label>
        <Dropdown
          fieldId={FIELD_IDS.SEND_AT_TIME}
          width="100%"
          iniValue={R.prop(FIELD_IDS.SEND_AT_TIME)(data)}
          options={SEND_AT_TIME_OPTIONS}
        />
      </FieldWrapper>

      {R.prop(FIELD_IDS.FREQUENCY)(data) === "recurring" ? (
        <FieldWrapper>
          <Label tooltip="Set the time limit of a valid health pass.">
            After how many hours does a Health Pass expire?
          </Label>
          <Dropdown
            fieldId={FIELD_IDS.EXPIRES_AFTER_NUM_HOURS}
            width="100%"
            iniValue={R.prop(FIELD_IDS.EXPIRES_AFTER_NUM_HOURS)(data)}
            options={NUM_HOURS_OPTIONS}
          />
        </FieldWrapper>
      ) : null}

      <FieldWrapper>
        <Label tooltip="The selected users will receive an email when someone fails an assessment">
          Which users should be notified upon a failed assessment?
        </Label>
        <DropdownAsync
          fieldId={FIELD_IDS.NOTIFY_USER_IDS}
          iniValue={R.prop(FIELD_IDS.NOTIFY_USER_IDS)(data)}
          multipleSelect
          usePortal
          bg="white"
        />
      </FieldWrapper>
    </Panel>

    <Panel>
      <PanelTitle description="Select what questions to show">
        Questions
      </PanelTitle>

      <Fields />
    </Panel>

    <Panel>
      <PanelTitle>Messages</PanelTitle>
      <FieldWrapper>
        <Label tooltip="This message will show in reminder emails sent to individuals">
          Reminder Email Message
        </Label>
        <AdvancedWYSIWYG
          fieldId={FIELD_IDS.REMINDER_MESSAGE}
          width={"100%"}
          iniValue={R.prop(FIELD_IDS.REMINDER_MESSAGE)(data)}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Label tooltip="This message will show to individuals who pass their assessment">
          "Passed" Confirmation Message
        </Label>
        <AdvancedWYSIWYG
          fieldId={FIELD_IDS.APPROVED_MESSAGE}
          width={"100%"}
          iniValue={R.prop(FIELD_IDS.APPROVED_MESSAGE)(data)}
        />
      </FieldWrapper>

      <FieldWrapper>
        <Label tooltip="This message will show to individuals who do not pass their assessment">
          "Did Not Pass" Confirmation Message
        </Label>
        <AdvancedWYSIWYG
          fieldId={FIELD_IDS.DENIED_MESSAGE}
          width={"100%"}
          iniValue={R.prop(FIELD_IDS.DENIED_MESSAGE)(data)}
        />
      </FieldWrapper>
    </Panel>

    <FloatingSaveBar>
      <BigFilledButton
        bg="altB5"
        color="white"
        onClick={save}
        mr={4}
        disabled={!isValid}
      >
        Save
      </BigFilledButton>
      {error ? (
        <Div fs={1} color="danger8">
          {error}
        </Div>
      ) : null}
    </FloatingSaveBar>
  </Div>
);

export default decorate(Body);
