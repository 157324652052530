import reportCounts from "./card-report-counts";
import reportEntries from "./card-report-entries";

export default ({ submission, eventDetails, fields, getMetaData }) => {
  const summaries = reportCounts(submission, eventDetails, fields);
  const entries = length =>
    reportEntries({ fields, getMetaData, submission }).slice(0, length);

  if (summaries.length) {
    return { summaries, entries: entries(2) };
  }
  return { summaries, entries: entries(4) };
};
