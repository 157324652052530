import React from "react";
import { connect } from "react-redux";
import { Div, RightIcon } from "components/Base";
import { newsAndAlertsDates } from "../selectors";
import * as R from "ramda";
import moment from "moment";
import { getters } from "../model";
import { withRouter } from "react-router";
import css from "Portal/PortalPagePGA/PortalPagePGA/View/styles.scss";
import CSSModules from "react-css-modules";
import Loading from "ui-kit/Loading";

import { getAlertsTitle } from "Portal/PortalPagePGA/PortalPagePGA/selectors";

const decorator = R.compose(
  withRouter,
  connect((state, props) => ({
    alertsTitle: getAlertsTitle(state),
    newsAndAlerts: newsAndAlertsDates(state, props),
    loading: getters.loading(state, props)
  }))
);

const Body = ({
  alertsTitle,
  newsAndAlerts,
  loading,
  router,
  ...otherProps
}) => (
  <Div
    display="column.flex-start.flex-start"
    {...otherProps}
    style={{
      marginTop: "25px"
    }}
  >
    <Div
      pb={5}
      style={{
        color: "#222222",
        fontFamily: "Roboto",
        fontSize: "24px",
        fontWeight: "bold",
        letterSpacing: 0
      }}
    >
      {alertsTitle}
    </Div>

    {loading ? (
      <Div width={1} mt={5} display="row.center.center">
        <Loading />
      </Div>
    ) : (
      R.map(
        date => (
          <Div key={date.date} width={1} mb={5}>
            <Div
              mb={3}
              style={{
                color: "#222222",
                fontFamily: "Roboto",
                fontSize: "20px",
                letterSpacing: 0
              }}
            >
              {moment(date.date).isSame(new Date(), "day")
                ? "Today"
                : moment(date.date).format("dddd, MMMM Do")}
            </Div>
            <Div display="column.flex-start.flex-start" width={1}>
              {R.map(
                item => (
                  <Div
                    className={css.newsitem}
                    bra={1}
                    width={1}
                    bg="white"
                    mb={3}
                    display="row.space-between.center"
                    px={4}
                    py={3}
                    key={item.id}
                    style={{
                      padding: "20px 17px 20px 27px",
                      marginBottom: "6px"
                    }}
                    onClick={
                      item.has_content
                        ? () =>
                            router.push(
                              `${router.location.pathname}/${item.id}`
                            )
                        : undefined
                    }
                  >
                    <Div display="column">
                      <Div
                        style={{
                          flex: 1,
                          color: "#222222",
                          fontFamily: "'Roboto', sans-serif",
                          fontSize: "16px",
                          letterSpacing: 0
                        }}
                      >
                        {item.name}
                      </Div>
                    </Div>
                    <Div
                      display="row.flex-end.center"
                      style={{
                        marginLeft: "10px",
                        flexShrink: 0
                      }}
                    >
                      <Div
                        uppercase
                        style={{
                          color: "#666666",
                          fontFamily: "'Roboto Condensed', sans-serif",
                          fontSize: "12px",
                          letterSpacing: 0,
                          flexShrink: 0
                        }}
                      >
                        {item.date_label}
                      </Div>
                      {item.has_content ? (
                        <RightIcon
                          color="#8C8C8C"
                          size={25}
                          style={{
                            marginLeft: "11px"
                          }}
                        />
                      ) : null}
                    </Div>
                  </Div>
                ),
                date.items
              )}
            </Div>
          </Div>
        ),

        newsAndAlerts
      )
    )}
  </Div>
);

export default CSSModules(decorator(Body), css);
