import React from "react";
import { connect } from "react-redux";
import { BigFilledButton } from "components/Base";
import { getters } from "HealthPass/HealthPassStatus/model";

const decorate = connect(state => ({
  linkId: getters.linkId(state)
}));

const NextButton = ({ linkId }) => (
  <BigFilledButton
    color="white"
    bg="indigo7"
    style={{ borderRadius: "20px" }}
    onClick={() => {
      window.location = `/health-pass/${linkId}`;
    }}
  >
    Fill out new assessment
  </BigFilledButton>
);

export default decorate(NextButton);
