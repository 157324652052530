import * as R from "ramda";

import React from "react";
import { defaultInstanceId } from "redux-mvc";

import { getDisplayName } from "utils/General";

export const FormInstanceContext = React.createContext(defaultInstanceId);

export const WithFormInstanceProvider = (
  getInstanceId = R.propOr(defaultInstanceId, "instanceId")
) => Component => {
  class WithFormInstance extends React.Component {
    render() {
      const props = this.props;
      return (
        <FormInstanceContext.Provider value={getInstanceId(props)}>
          <Component {...props} />
        </FormInstanceContext.Provider>
      );
    }
  }

  WithFormInstance.displayName = `WithFormInstanceProvider(${getDisplayName(
    Component
  )})`;

  return WithFormInstance;
};

export const WithFormInstanceConsumer = (cProps = {}) => Component => {
  class WithFormInstance extends React.Component {
    render() {
      const props = this.props;
      return props.instanceId ? (
        <Component {...cProps} {...props} />
      ) : (
        <FormInstanceContext.Consumer>
          {instanceId => (
            <Component {...cProps} {...props} instanceId={instanceId} />
          )}
        </FormInstanceContext.Consumer>
      );
    }
  }

  WithFormInstance.displayName = `WithFormInstanceConsumer(${getDisplayName(
    Component
  )})`;

  return WithFormInstance;
};
