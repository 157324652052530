import { combineReducers } from "redux";
import { RECEIVE, REQUEST, ERROR, CLEAR_ERROR, INVALIDATE } from "./constants";
import { UPDATE as SUBMISSION_UPDATE } from "./submission/constants";
import {
  CREATE as SUBMISSION_VALUE_CREATE,
  CREATE_SUBFORM as SUBMISSION_SUBFORM_VALUE_CREATE
} from "./submission/values/constants";
import {
  CREATE as SUBMISSION_REVIEW_CREATE,
  BULK_CREATE as SUBMISSION_REVIEW_BULK_CREATE
} from "./submission/reviews/constants";

import submissionReducer from "./submission/reducer";

const list = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload.reduce((map, submission) => {
        map[submission.id] = submissionReducer(submission, action);
        return map;
      }, {});
    case SUBMISSION_UPDATE:
    case SUBMISSION_VALUE_CREATE:
      return {
        ...state,
        [action.payload.submissionId]: submissionReducer(
          state[action.payload.submissionId],
          action
        )
      };
    case SUBMISSION_SUBFORM_VALUE_CREATE:
    case SUBMISSION_REVIEW_CREATE:
    case SUBMISSION_REVIEW_BULK_CREATE:
      return {
        ...state,
        [action.payload.parentSubmissionId]: submissionReducer(
          state[action.payload.parentSubmissionId],
          action
        )
      };
    case INVALIDATE:
      return {};
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case ERROR:
      return action.payload;
    case CLEAR_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  list,
  error,
  fetching
});
