import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import CollapsablePanel from "ui-kit/CollapsiblePanel";
import { Text3, Text2, Div, FontIcon } from "components/Base";
import Table, { TableWrapper } from "ui-kit/Table/View";

import { noop } from "utils/General";

const decorate = R.compose(
  connect(
    state => ({
      openedTabs: getters.openedRequestTabs(state)
    }),
    {
      openRequests: actions.openDocument,
      selectTabOfRequestType: actions.selectTabOfRequestType
    }
  ),
  CSSModules(css)
);

const RequestsContainer = ({
  title = "",
  requestTypes = [],
  personId = "",
  openedTabs = {},
  selectTabOfRequestType = noop
}) => {
  return (
    <CollapsablePanel
      arrowColor="gray7"
      bg="white"
      heading={title}
      collapsedDefault
      arrowColor="gray7"
      bg="white"
      width={1}
      mt={4}
      mb={4}
    >
      <div styleName="content">
        {R.isEmpty(requestTypes) ? (
          <Text2>0 requests / orders</Text2>
        ) : (
          <>
            {R.map(
              requestType => (
                <div key={requestType.id} styleName="requestTypes">
                  <Div py={2} display="row.flex-start.center">
                    {requestType.icon ? (
                      <FontIcon color="black" fs={4} mr={2}>
                        {requestType.icon}
                      </FontIcon>
                    ) : null}
                    <Text3 color="black">{requestType.name}</Text3>
                  </Div>
                  <div styleName="tabs">
                    {R.map(
                      ({ group_id, group_name, items, columns }) => (
                        <div
                          key={group_id}
                          styleName="tab"
                          style={
                            R.path([personId, requestType.id], openedTabs) ===
                            group_id
                              ? {
                                  borderBottomColor: "#8d64d1",
                                  color: "#8d64d1"
                                }
                              : {}
                          }
                          onClick={() =>
                            selectTabOfRequestType({
                              personId,
                              requestTypeId: requestType.id,
                              requestGroupId: group_id,
                              items,
                              columns
                            })
                          }
                        >
                          {group_name}
                        </div>
                      ),
                      R.propOr([], "groups", requestType)
                    )}
                  </div>
                  <div
                    key={`${personId}-${requestType.id}-${R.path(
                      [personId, requestType.id],
                      openedTabs
                    )}`}
                    styleName="requestTable"
                  >
                    <TableWrapper
                      style={{ flex: 1 }}
                      instanceId={`${personId}-${requestType.id}`}
                    >
                      <Table
                        instanceId={`${personId}-${requestType.id}`}
                        showCheckbox={false}
                      />
                    </TableWrapper>
                  </div>
                </div>
              ),
              requestTypes
            )}
          </>
        )}
      </div>
    </CollapsablePanel>
  );
};

export default decorate(RequestsContainer);
