import React from "react";

import { connect } from "react-redux";

import * as R from "ramda";

import { HEIGHTS } from "Items/AssignQuestionsItemsModal/constants";

import { getters } from "Items/AssignQuestionsItemsModal";

import { Div, CloseIcon } from "components/Base";

const decorate = connect(state => ({
  variants: getters.variantIds(state),
  variantNames: getters.variantNames(state)
}));

const formatText = (variantIds, variantNames) => {
  const variantsLength = variantIds.length;

  if (variantsLength === 0) {
    return "-";
  } else if (variantsLength === 1) {
    return variantNames[0];
  }
  return `${variantsLength} items`;
};

const Header = ({ hideModal, variants, variantNames }) => (
  <Div
    width={1}
    height={HEIGHTS.HEADER}
    display="row.space-between.center"
    px={4}
    bg="primary8"
  >
    <Div fs={4} fw={3} color="white">{`Assign Questions to: ${formatText(
      variants,
      variantNames
    )}`}</Div>
    <CloseIcon color="white" size={24} onClick={hideModal} />
  </Div>
);

export default decorate(Header);
