import PropTypes from "prop-types";
import React, { Component } from "react";
import * as R from "ramda";
import About from "components/Global/CRM/About";
import Contact from "components/Event/ContactProfile/Contact";
import RelatedAccounts from "components/Event/ContactProfile/RelatedAccounts";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import ProfileView from "components/Global/ProfileView";
import Events from "./EventsTable";
import ContactActivities from "Profile/Activities/View";
import RelatedModule from "components/Global/CRM/RelatedModule";
import { FILE_FIELDS } from "components/Event/Accounts/Account/constants";

class ContactProfile extends Component {
  getBasePath = () =>
    window.location.pathname.includes("org-light")
      ? `/org-light/${this.props.params.orgId}`
      : `/organization/${this.props.params.orgId}`;

  getReturnToUrl = () => {
    const { viaAccount } = this.props.location.query;

    const basePath = this.getBasePath();

    if (typeof viaAccount !== "undefined") {
      return `${basePath}/account/${viaAccount}`;
    }

    if (window.location.pathname.includes("org-light")) {
      return `${basePath}/people/manage/${STANDARD_MODULE_IDS.contacts.id}`;
    }

    return null;
    // return `${basePath}/module/${STANDARD_MODULE_IDS.contacts.id}`;
  };

  getReturnToText = () => {
    const { viaAccount } = this.props.location.query;

    if (viaAccount) return "Back to Group";

    return "Back to People";
  };

  render() {
    const {
      // getFieldValue,
      dispatch,
      hideModal,
      router,
      params,
      showModal,
      name,
      showSnackbar,
      contact,
      addNote,
      updateField,
      showViewAllPropertiesModal,
      showAddRelationshipModal,
      showEditRelationshipModal,
      updateNote,
      deleteNote,
      showViewMoreModal,
      onRemoveRelationship,
      onRemoveFromEvent,
      readOnly,
      cando,
      showDeleteContactModal,
      activeView,
      goToView,
      eventDetails
    } = this.props;

    const { orgId, recordId } = params;

    const contactProps = {
      dispatch,
      hideModal,
      router,
      params,
      showModal,
      showSnackbar,
      showDeleteContactModal
    };

    const aboutProps = {
      data: contact,
      eventDetails: R.pathOr(eventDetails, ["details"], this.props),
      name,
      updateField,
      readOnly,
      viewAllProperties: showViewAllPropertiesModal,
      showStarredFields: true
    };

    let leftContent = [<Contact />, <About />];
    if (!R.isEmpty(contact)) {
      leftContent = [
        <Contact
          key="contact"
          isUser={Boolean(contact.userId)}
          data={contact.values}
          readOnly={readOnly}
          {...contactProps}
        />,
        <About key="about" {...aboutProps} />,
        <RelatedAccounts
          key="relAccounts"
          readOnly={readOnly}
          canViewAccounts={cando(`${STANDARD_MODULE_IDS.accounts.id}_read`)}
          addRelationship={showAddRelationshipModal}
          editRelationship={showEditRelationshipModal}
          accounts={contact.accounts || []}
          params={params}
          removeRelationship={onRemoveRelationship}
          viewMoreModal={showViewMoreModal}
        />
      ];
    }

    return (
      <ProfileView
        leftContent={leftContent}
        centerContent={R.prop(activeView, {
          overview: [
            <Events
              key="events"
              events={
                contact.events
                  ? contact.events.map(e => ({
                      id: e.id,
                      type: e.record_type.name,
                      name: e.name,
                      onRemove: () => onRemoveFromEvent(e.id)
                    }))
                  : []
              }
            />,
            <RelatedModule
              module={{
                ...STANDARD_MODULE_IDS.files,
                event_id: params.eventId,
                preferences: {
                  visible_fields: FILE_FIELDS.map(f => f.id),
                  field_order: FILE_FIELDS.reduce((map, f, idx) => {
                    map[f.id] = idx;
                    return map;
                  }, {})
                },
                fields: {
                  fields: FILE_FIELDS
                }
              }}
              record={{
                id: recordId,
                name,
                valueName: "accountId",
                moduleId: STANDARD_MODULE_IDS.accounts.id,
                moduleInternalName: "accounts",
                values: []
              }}
            />
          ],
          activity: <ContactActivities contacttId={recordId} orgId={orgId} />
        })}
        rightContent={[]}
        notes={
          contact.notes && contact.notes.length
            ? contact.notes.map(n => ({
                note: n,
                authorUrl: `${this.getBasePath()}/contact/${
                  n.author_contact_id
                }`,
                title: name || "contact",
                onUpdate: note => updateNote(note, n.id),
                onDelete: () => deleteNote(n.id)
              }))
            : []
        }
        returnToText={this.getReturnToText()}
        returnToPath={this.getReturnToUrl()}
        tabs={[
          {
            name: "Overview",
            onClick: () => goToView(""),
            active: activeView === "overview"
          }
          /*
          {
            name: "Activity",
            onClick: () => goToView("/activity"),
            active: activeView === "activity"
          }
          */
        ]}
        showNotes={activeView === "overview"}
        onAddNote={addNote}
      />
    );
  }
}

ContactProfile.propTypes = {
  addValue: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  details: PropTypes.object.isRequired,
  deleteRelationship: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  updateField: PropTypes.func.isRequired,
  updateRelationship: PropTypes.func.isRequired,
  contact: PropTypes.shape({
    fields: PropTypes.object,
    values: PropTypes.object
  }).isRequired
};

export default ContactProfile;
