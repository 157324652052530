import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Controller from "./Controller";
import * as R from "ramda";

import { showModal, hideModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import {
  getUsers,
  addExistingUser,
  updateUser,
  removeUser,
  resendInvite
} from "redux/modules/organization/users/actions";

import { user } from "redux/modules/user/selectors";
import { orgDetails } from "redux/modules/organization/selectors";
import { users } from "redux/modules/organization/users/selectors";

function mapStateToProps(state) {
  return {
    orgDetails: orgDetails(state),
    users: R.sortBy(r => {
      if (r.user_lname) {
        return r.user_lname.toLowerCase();
      }
      return null;
    })(users(state)),
    user: user(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal,
      hideModal,
      showSnackbar,
      getUsers,
      addExistingUser,
      updateUser,
      removeUser,
      resendInvite
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
