import { createModule } from "redux-mvc";

import model from "./model";

import sagasModule from "./sagas";
import tableModule from "ui-kit/Table";
import miniItemsSelector from "ui-kit/MiniItemsSelector";

const module = createModule(model);

module.setRootSaga(sagasModule);
module.plugModule(tableModule);
module.plugModule(miniItemsSelector);

export default module;
