import PropTypes from "prop-types";
import React, { Component } from "react";

import View from "./View";

class InternalHandler extends Component {
  filterAreas = records => {
    const { parentRecordId } = this.props.params;
    if (parentRecordId) {
      return records.filter(record =>
        Object.keys(record.values).find(
          id =>
            record.values[id].type === "lookup" &&
            record.values[id].value.records.includes(parentRecordId)
        )
      );
    }
    return records;
  };

  render() {
    const {
      params,
      locationRecords,
      locationAreaRecords,
      locationAreaFields
    } = this.props;
    const locations = locationRecords.map(location => ({
      ...location,
      active: params.parentRecordId === location.id,
      path: `/event/${params.eventId}/locations/${location.id}`
    }));
    const areas = this.filterAreas(locationAreaRecords);
    return (
      <View
        params={params}
        locations={locations}
        areas={areas}
        areaFields={locationAreaFields}
        showAddLocationModal={this.props.showAddLocationModal}
        showEditLocationModal={this.props.showEditLocationModal}
        getAllLocationsPath={this.props.getAllLocationsPath}
        isViewingAllLocations={!this.props.params.parentRecordId}
      />
    );
  }
}

InternalHandler.propTypes = {
  params: PropTypes.shape({
    parentRecordId: PropTypes.string,
    eventId: PropTypes.string.isRequired
  }).isRequired,
  locationRecords: PropTypes.arrayOf(
    PropTypes.shape({
      values: PropTypes.object
    })
  ).isRequired,
  locationAreaRecords: PropTypes.arrayOf(
    PropTypes.shape({
      values: PropTypes.object
    })
  ).isRequired
};

export default InternalHandler;
