import {
  put,
  call,
  takeEvery,
  all,
  fork,
  select,
  take,
  delay
} from "redux-saga/effects";
import * as R from "ramda";
import { defaultInstanceId } from "redux-mvc";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { showSnackbar } from "redux/modules/snackbar/actions";

import { navigateTo } from "utils/General";

import { actions as DataActions } from "App/Data/model";
import { actions, getters } from "OrgLight/Common/Dashboard";
import { actions as TableActions } from "ui-kit/Table/model";
import { actions as ApprovalsActions } from "ApproversLabel";

import {
  actions as ViewPickerActions,
  getters as ViewPickerGetters
} from "ui-kit/ViewPicker";

import {
  actions as SearchBarActions,
  getters as SearchBarGetters
} from "ui-kit/SearchBar";

import {
  getCurrentPage,
  getPageSize
} from "OrgLight/Common/Dashboard/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";
import { registerError } from "redux/modules/errors/actions";
import {
  TABLE_VIEW_ID,
  TABLE_INSTANCE_ID,
  VIEWPICKER_INSTANCE_ID
} from "OrgLight/Common/Dashboard/constants";
import { GROUP_BY_COLUMN_ID } from "ui-kit/Table/constants";

import Api from "../api";

import tableSagas from "./table";
import actionsSagas from "./actions";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);

  return {
    credentials,
    orgId
  };
};

const notResetPagination = [
  actions.setCurrentPage.type,
  actions.fetchData.type,
  TableActions.refreshRecords.type
];

const init = function*({
  payload: {
    moduleId,
    recordTypeId,
    mode,
    showAddRecordButton = true,
    showDataFeedButton = true,
    showSidebar = true,
    showSelectRowColumn = true,
    showOpenRecordColumn = true,
    showImportButton = true,
    showColumnActions = true,
    enableEditingCells = true
  }
}) {
  try {
    const loading = yield select(getters.loading);
    if (!loading) {
      yield put(actions.setLoading(true));
    }

    const { credentials, orgId } = yield call(getParams);

    let moduleToUse = null;
    let recordType = null;
    let recordTypes = [];
    let peopleRecordTypes = [];
    let preferences = {};

    if (recordTypeId) {
      const [
        { record_type },
        peopleRecordTypesPayload,
        preferencesPayload
      ] = yield all([
        call(Api.getRecordType, {
          credentials,
          orgId,
          moduleId,
          recordTypeId
        }),
        moduleId === STANDARD_MODULE_IDS.accounts.id
          ? call(Api.getRecordTypes, {
              credentials,
              orgId,
              moduleId: STANDARD_MODULE_IDS.contacts.id
            })
          : { record_types: [] },
        call(Api.getActiveView, {
          credentials,
          orgId,
          moduleId,
          recordTypeId
        })
      ]);
      recordType = record_type;
      peopleRecordTypes = peopleRecordTypesPayload.record_types;
      preferences = preferencesPayload.payload;
    } else {
      const [
        payload,
        recordTypesPayload,
        peopleRecordTypesPayload,
        preferencesPayload
      ] = yield all([
        call(Api.getModule, {
          credentials,
          orgId,
          moduleId
        }),
        call(Api.getRecordTypes, { credentials, orgId, moduleId }),
        moduleId === STANDARD_MODULE_IDS.accounts.id
          ? call(Api.getRecordTypes, {
              credentials,
              orgId,
              moduleId: STANDARD_MODULE_IDS.contacts.id
            })
          : { record_types: [] },
        call(Api.getActiveView, {
          credentials,
          orgId,
          moduleId,
          recordTypeId
        })
      ]);
      moduleToUse = payload.module;
      recordTypes = recordTypesPayload.record_types;
      peopleRecordTypes = peopleRecordTypesPayload.record_types;
      preferences = preferencesPayload.payload;
    }

    yield all([
      put(
        ViewPickerActions.setActiveView(
          {
            activeViewId: preferences.id,
            activeViewName: preferences.name
          },
          {
            meta: {
              instanceId: VIEWPICKER_INSTANCE_ID
            }
          }
        )
      ),
      put(
        actions.setInitialData({
          module: moduleToUse,
          recordType,
          recordTypes,
          peopleRecordTypes,
          moduleId,
          recordTypeId,
          preferences,
          mode,
          showAddRecordButton,
          showDataFeedButton,
          showSidebar,
          showSelectRowColumn,
          showOpenRecordColumn,
          showImportButton,
          showColumnActions,
          enableEditingCells
        })
      )
    ]);

    yield all([
      put(actions.fetchData({ initialFetch: true })),
      put(actions.fetchViews())
    ]);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred loading initializing view"
          }
        ])
      )
    ]);
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const getSearchParams = (state, actionType) => {
  const resetPagination = R.not(
    R.any(R.equals(actionType), notResetPagination)
  );

  const preferences = getters.preferences(state);
  const mode = getters.mode(state);

  return {
    moduleId: getters.moduleId(state),
    recordTypeId: getters.recordTypeId(state),
    view: getters.selectedTab(state),
    search: SearchBarGetters.searchTerm(state),
    preferences: JSON.stringify(preferences),
    page: resetPagination ? 1 : getCurrentPage(state) + 1,
    pageSize: getPageSize(state),
    mode
  };
};

const loading = function*(scrollTop = true) {
  for (;;) {
    const { payload } = yield take(actions.setLoading.type);
    if (payload && scrollTop) {
      const elm = yield call(
        [document, document.getElementById],
        TABLE_VIEW_ID
      );
      if (elm) {
        elm.scrollTop = 0;
      }

      const elms = yield call(
        [document, document.getElementsByClassName],
        TABLE_VIEW_ID
      );
      Array.prototype.forEach.call(elms, elm => {
        elm.scrollTop = 0;
      });
    }
  }
};

export const search = function*({ type, payload }) {
  yield put(actions.setShowNoResults(false));
  const credentials = yield select(getCredentials);
  const userId = yield select(getUserId);
  const orgId = yield select(getOrgId);
  const enableEditingCells = yield select(getters.enableEditingCells);

  const state = yield select(R.identity);
  const data = getSearchParams(state, type);
  const silentMode =
    payload && typeof payload === "object" ? payload.useSilentRefresh : false;
  const initialFetch =
    payload && typeof payload === "object" ? payload.initialFetch : false;

  if (!silentMode) {
    yield put(actions.setLoading(true));
  }

  try {
    const result = yield call(Api.search, credentials, userId, orgId, data);

    const isGroupedBy = R.compose(
      R.not,
      R.isNil,
      R.prop("grouped_by")
    )(result.payload);

    const rows = isGroupedBy // fake group by column
      ? R.flatten(
          R.map(
            group =>
              R.map(
                row => ({
                  ...row,
                  [GROUP_BY_COLUMN_ID]: R.propOr("", "title", group)
                }),
                group.records
              ),
            result.payload.records
          )
        )
      : result.payload.records;

    yield all([
      put(
        TableActions.setData(
          {
            columns: result.payload.fields,
            rows,
            columnWidths: result.payload.preferences.field_widths,
            groupBy: isGroupedBy ? GROUP_BY_COLUMN_ID : "",
            canEditCells: enableEditingCells
          },
          {
            meta: {
              instanceId: TABLE_INSTANCE_ID
            }
          }
        )
      ),
      put(actions.receiveList({ ...result.payload })),
      put(DataActions.addReferences(result.payload.references)),
      put(actions.setShowNoResults(!initialFetch && R.length(rows) === 0)),
      put(actions.setLoading(false))
    ]);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred fetching data"
          }
        ])
      ),
      put(actions.setLoading(false))
    ]);
  }
};

const watchSearch = function*() {
  yield takeEvery(
    action =>
      action.type === SearchBarActions.setSearchTerm.type &&
      R.path(["meta", "instanceId"], action) === defaultInstanceId,
    search
  );
};

const watchClearSearch = function*() {
  for (;;) {
    const action = yield take(SearchBarActions.clearSearch.type);
    if (R.path(["meta", "instanceId"], action) === defaultInstanceId) {
      yield call(search, action);
    }
  }
};

const exportData = function*({ type, payload: contentType }) {
  const credentials = yield select(getCredentials);
  const state = yield select(R.identity);
  const orgIdToUse = yield select(getOrgId);

  const data = getSearchParams(state, type);

  yield put(actions.setLoading(true));

  yield put(showSnackbar({ message: "Preparing export..." }));

  try {
    const initialResult = yield call(Api.export, {
      ...data,
      orgId: orgIdToUse,
      credentials,
      contentType
    });

    yield delay(200);

    let progressResult = yield call(Api.getStatus, credentials, {
      jobId: initialResult.payload.jobId
    });

    while (["pending", "processing"].includes(progressResult.payload.status)) {
      yield delay(1000);
      progressResult = yield call(Api.getStatus, credentials, {
        jobId: initialResult.payload.jobId
      });
    }

    if (progressResult.payload.status === "error") {
      yield all([
        put(
          registerError([
            {
              system: "An error occurred exporting",
              user: "An error occurred exporting"
            }
          ])
        )
      ]);
      return false;
    }

    yield all([
      call([window, window.open], progressResult.payload.payload.url, "_blank"),
      put(actions.setLoading(false))
    ]);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred exporting"
          }
        ])
      ),
      put(actions.setLoading(false))
    ]);
  }
};

const watchExport = function*() {
  yield takeEvery(actions.exportData.type, exportData);
};

const fetchViews = function*() {
  const credentials = yield select(getCredentials);
  const state = yield select(R.identity);
  const orgIdToUse = yield select(getOrgId);

  try {
    const { payload: views } = yield call(Api.getViews, {
      credentials,
      orgId: orgIdToUse,
      moduleId: getters.moduleId(state),
      recordTypeId: getters.recordTypeId(state)
    });
    yield put(
      ViewPickerActions.setViews(views, {
        meta: {
          instanceId: VIEWPICKER_INSTANCE_ID
        }
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred gettings views"
        }
      ])
    );
  }
};

const watchFetchViews = function*() {
  yield takeEvery(actions.fetchViews.type, fetchViews);
};

const addView = function*({ payload: newViewData = {} }) {
  const credentials = yield select(getCredentials);
  const state = yield select(R.identity);
  const orgIdToUse = yield select(getOrgId);
  const preferences = getters.preferences(state);

  try {
    const { payload } = yield call(Api.addView, {
      credentials,
      orgId: orgIdToUse,
      moduleId: getters.moduleId(state),
      recordTypeId: getters.recordTypeId(state),
      name: newViewData.name
        ? newViewData.name
        : preferences.name && preferences.name.length
        ? preferences.id === "default"
          ? preferences.name
          : `${preferences.name} (Copy)`
        : "New View",
      visibleFields: preferences.visible_fields,
      fieldOrder: preferences.field_order,
      sortBy: preferences.sort_by,
      filters: preferences.filters,
      fieldWidths: preferences.field_widths,
      quickFilters: preferences.quick_filters,
      groupedBy: preferences.grouped_by,
      pageSize: preferences.page_size,
      contactRecordTypeId: preferences.contact_record_type_id
    });

    yield all([
      put(actions.addViewResponse(payload)),
      put(
        ViewPickerActions.addNewActiveView(payload, {
          meta: {
            instanceId: VIEWPICKER_INSTANCE_ID
          }
        })
      )
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred adding view"
        }
      ])
    );
  }
};

const watchAddView = function*() {
  yield takeEvery(ViewPickerActions.addView.type, addView);
};

const updateView = function*({ payload: data }) {
  const credentials = yield select(getCredentials);
  const state = yield select(R.identity);
  const orgIdToUse = yield select(getOrgId);
  const preferences = yield getters.preferences(state);

  try {
    yield call(Api.updateView, {
      credentials,
      orgId: orgIdToUse,
      moduleId: getters.moduleId(state),
      recordTypeId: getters.recordTypeId(state),
      viewId: preferences.id,
      ...data
    });
    yield put(actions.fetchViews());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating view"
        }
      ])
    );
  }
};

const watchUpdateView = function*() {
  yield takeEvery(
    [ViewPickerActions.updateView.type, ViewPickerActions.updateViewName.type],
    updateView
  );
};

const updateViewName = function*({ payload }) {
  yield put(actions.updateViewName(payload));
};

const watchUpdateViewName = function*() {
  yield takeEvery(ViewPickerActions.updateViewName.type, updateViewName);
};

const revertViewChanges = function*({ payload }) {
  yield put(actions.revertViewChanges(payload));
};

const watchRevertViewChanges = function*() {
  yield takeEvery(ViewPickerActions.revertViewChanges.type, revertViewChanges);
};

const saveViewChanges = function*() {
  const credentials = yield select(getCredentials);
  const state = yield select(R.identity);
  const orgIdToUse = yield select(getOrgId);
  const preferences = yield getters.preferences(state);

  try {
    if (preferences.id === "default") {
      const { payload } = yield call(Api.addView, {
        credentials,
        orgId: orgIdToUse,
        moduleId: getters.moduleId(state),
        recordTypeId: getters.recordTypeId(state),
        name: preferences.name,
        visibleFields: preferences.visible_fields,
        fieldOrder: preferences.field_order,
        sortBy: preferences.sort_by,
        filters: preferences.filters,
        fieldWidths: preferences.field_widths,
        quickFilters: preferences.quick_filters,
        groupedBy: preferences.grouped_by,
        pageSize: preferences.page_size,
        contactRecordTypeId: preferences.contact_record_type_id
      });

      yield all([
        put(
          ViewPickerActions.addNewActiveView(payload, {
            meta: {
              instanceId: VIEWPICKER_INSTANCE_ID
            }
          })
        ),
        put(actions.addViewResponse(payload))
      ]);
    } else {
      yield put(actions.saveViewChangesResponse(preferences));

      yield call(Api.updateView, {
        credentials,
        orgId: orgIdToUse,
        moduleId: getters.moduleId(state),
        recordTypeId: getters.recordTypeId(state),
        viewId: preferences.id,
        visibleFields: preferences.visible_fields,
        fieldOrder: preferences.field_order,
        sortBy: preferences.sort_by,
        filters: preferences.filters,
        fieldWidths: preferences.field_widths,
        quickFilters: preferences.quick_filters,
        groupedBy: preferences.grouped_by,
        pageSize: preferences.page_size,
        contactRecordTypeId: preferences.contact_record_type_id
      });
      yield put(actions.fetchViews());
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred saving view changes"
        }
      ])
    );
  }
};

const watchSaveViewChanges = function*() {
  yield takeEvery(ViewPickerActions.saveViewChanges.type, saveViewChanges);
};

const deleteView = function*({ payload: data }) {
  const credentials = yield select(getCredentials);
  const state = yield select(R.identity);
  const orgIdToUse = yield select(getOrgId);
  const preferences = yield getters.preferences(state);

  try {
    yield call(Api.deleteView, {
      credentials,
      orgId: orgIdToUse,
      moduleId: getters.moduleId(state),
      recordTypeId: getters.recordTypeId(state),
      viewId: preferences.id,
      ...data
    });

    const [activeViewResult] = yield all([
      call(Api.getActiveView, {
        credentials,
        orgId: orgIdToUse,
        moduleId: getters.moduleId(state),
        recordTypeId: getters.recordTypeId(state)
      }),
      put(actions.fetchViews())
    ]);

    yield put(actions.selectActiveView(activeViewResult.payload));

    yield put(actions.fetchData());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred deleting view"
        }
      ])
    );
  }
};

const watchDeleteView = function*() {
  yield takeEvery(ViewPickerActions.deleteView.type, deleteView);
};

const selectView = function*({ payload: viewId }) {
  const credentials = yield select(getCredentials);
  const state = yield select(R.identity);
  const orgIdToUse = yield select(getOrgId);

  const views = ViewPickerGetters.views(state, {
    instanceId: VIEWPICKER_INSTANCE_ID
  });
  const view = views.find(v => v.id === viewId);
  yield put(actions.selectView(view));

  try {
    yield call(Api.selectView, {
      credentials,
      orgId: orgIdToUse,
      moduleId: getters.moduleId(state),
      recordTypeId: getters.recordTypeId(state),
      viewId
    });

    yield put(actions.fetchData());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred selecting view"
        }
      ])
    );
  }
};

const watchSelectView = function*() {
  yield takeEvery(ViewPickerActions.selectView.type, selectView);
};

const updateViewFields = function*({ payload: fields }) {
  yield put(
    actions.updateVisibleFields({
      visibleFields: fields.map(f => f.id),
      fieldOrder: fields.reduce((map, f, idx) => {
        map[f.id] = idx;
        return map;
      }, {})
    })
  );

  yield put(actions.fetchData());
};

const watchUpdateViewFields = function*() {
  yield takeEvery(actions.updateViewFields.type, updateViewFields);
};

const updatePeopleType = function*({ payload }) {
  yield put(actions.setFilteredPeopleView(payload ? payload : null));
  yield put(actions.fetchData());
};

const watchUpdatePeopleType = function*() {
  yield takeEvery(actions.updatePeopleType.type, updatePeopleType);
};

const watch = function*() {
  yield takeEvery(
    [
      actions.setGroupedByField.type,
      actions.setCurrentPage.type,
      actions.setPageSize.type,
      actions.setSelectedFieldFilters.type,
      actions.setSelectedSortBy.type,
      actions.removeSelectedSortBy.type,
      actions.clearFilters.type,
      actions.removeSelectedFieldFilter.type,
      actions.setSelectedTab.type,
      ViewPickerActions.revertViewChanges.type,
      actions.fetchData.type,
      ApprovalsActions.reviewDone.type
    ],
    search
  );
};

const watchRefreshRecords = function*() {
  yield takeEvery(
    action => action.type === TableActions.refreshRecords.type,
    search
  );
};

const rootSaga = function*() {
  yield all([
    fork(tableSagas),
    fork(actionsSagas),
    fork(loading),
    fork(watchInit),
    fork(watchUpdateViewFields),
    fork(watchSearch),
    fork(watchRefreshRecords),
    fork(watchExport),
    fork(watchClearSearch),
    fork(watchUpdatePeopleType),
    fork(watchFetchViews),
    fork(watchAddView),
    fork(watchDeleteView),
    fork(watchUpdateView),
    fork(watchUpdateViewName),
    fork(watchRevertViewChanges),
    fork(watchSelectView),
    fork(watchSaveViewChanges),
    fork(watch)
  ]);
};

export default rootSaga;
