import React, { Component } from "react";
import View from "./View";
import AddSubmissionAsModal from "components/Event/FormsV2/Modals/AddSubmissionAsModal";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import SubmissionApi from "redux/modules/formsV2/submission/api";

class SourcesController extends Component {
  goto(pathname) {
    return this.props.router.push({ pathname });
  }
  goToEditForm = () => {
    this.goto(
      `/event/${this.props.eventId}/module/${
        this.props.formMeta.base_module_id
      }/form/${this.props.formMeta.id}`
    );
  };
  goToShareForm = () => {
    this.goto(
      `/event/${this.props.eventId}/module/${
        this.props.formMeta.base_module_id
      }/form/${this.props.formMeta.id}/share`
    );
  };
  goToSubmitForm = () => {
    this.props.showModal({
      content: (
        <AddSubmissionAsModal
          onDone={this.addSubmission}
          hideModal={this.props.hideModal}
        />
      ),
      wrapper: ModalWrapper
    });
  };
  addSubmission = async accountId => {
    const { userId, eventId, formMeta, eventSlug } = this.props;

    const submissionModuleRecord = await this.props.addRecord({
      moduleId: this.props.formMeta.base_module_id,
      record: {
        isDraft: true
      },
      options: {
        eventId
      }
    });

    const submissionResult = await SubmissionApi.post(
      this.props.userCredentials,
      {
        eventId,
        formId: this.props.params.sourceId,
        userId,
        accountId,
        suppressSubmissionStartedEmail: true,
        submissionRecordId: submissionModuleRecord.id
      }
    );

    window.location = `/submissions/${eventSlug}/${formMeta.slug}/${
      submissionResult.submission.id
    }?redirectToSource=1`;
  };
  render() {
    const { params, userId, formMeta } = this.props;
    let formUserProps = {};
    if (
      formMeta.form_users &&
      formMeta.form_users.some(u => u.user_id === userId)
    ) {
      formUserProps = {
        onShareForm: this.goToShareForm,
        onEditForm: this.goToEditForm
      };
    }
    return (
      <View
        {...{
          onSubmitForm: this.goToSubmitForm,
          formName: this.props.formMeta.name,
          formId: params.sourceId,
          ...formUserProps
        }}
      />
    );
  }
}

export default SourcesController;
