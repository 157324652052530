export const NAMESPACE = "PortalPagePGA";
export const SIDEBAR_INSTANCE_ID = "PortalPageNetworkSidebar";

export const INTERVIEW_DAYS_SELECTED_ID =
  "interview-schedule-days-selected-date";

export const PAGE_ACTIONS = {
  REQUEST_INTERVIEW: "request-interview",
  WATCH_INTERVIEW_REPLAY: "watch-interview-replay",
  JOIN_AS_PARTICIPANT: "join-as-participant",
  WATCH: "watch",
  NOTIFY_ME: "notify-me",
  VIEW_TRANSCRIPT: "view-transcript",
  VIEW_REPLAY: "view-replay"
};

export const EVENT_TYPES = {
  SESSION_DELETE: "session-delete",
  SESSION_CREATE: "session-create",
  SESSION_UPDATE: "session-update",
  ALERT_UPDATE: "alert-update",
  ALERT_CREATE: "alert-create",
  ALERT_DELETE: "alert-delete",
  PAGE_UPDATE: "page-update",
  VIDEO_CREATE: "video-create",
  VIDEO_DELETE: "video-delete",
  VIDEO_UPDATE: "video-update"
};

export const MAX_NUMBER_OF_ALERTS = 3;

export const ICON_SPECS = {
  tv: {
    width: 45,
    height: 32,
    smallWidth: 35,
    smallHeight: 25,
    opacity: 0.7,
    url: "https://d2dks4tzxs6xee.cloudfront.net/img/pga/tv-ico.png"
  },
  play: {
    width: 45,
    height: 32,
    smallWidth: 35,
    smallHeight: 25,
    opacity: 0.7,
    url: "https://d2dks4tzxs6xee.cloudfront.net/img/pga/play-button-ico.png"
  },
  golf: {
    width: 36,
    height: 36,
    smallWidth: 32,
    smallHeight: 32,
    opacity: 0.9,
    url: "https://d2dks4tzxs6xee.cloudfront.net/img/pga/golf-ico.png"
  },
  leaderboard: {
    width: 46,
    height: 21,
    smallWidth: 41,
    smallHeight: 20,
    url: "https://d2dks4tzxs6xee.cloudfront.net/img/pga/leaderboard-ico.svg"
  },
  photos: {
    width: 36,
    height: 36,
    smallWidth: 35,
    smallHeight: 35,
    url: "https://d2dks4tzxs6xee.cloudfront.net/img/pga/photos-broll-ico.svg"
  },
  notes: {
    width: 37,
    height: 35,
    smallWidth: 32,
    smallHeight: 30,
    url: "https://d2dks4tzxs6xee.cloudfront.net/img/pga/notes-ico.svg"
  },
  paper: {
    width: 37,
    height: 35,
    smallWidth: 26,
    smallHeight: 30,
    url: "https://d2dks4tzxs6xee.cloudfront.net/img/pga/transcripts-ico.svg"
  },
  chart: {
    width: 40,
    height: 40,
    smallWidth: 35,
    smallHeight: 35,
    url: "https://d2dks4tzxs6xee.cloudfront.net/img/pga/statistics-ico.svg"
  },
  book: {
    width: 29,
    height: 39,
    smallWidth: 26,
    smallHeight: 34,
    url: "https://d2dks4tzxs6xee.cloudfront.net/img/pga/guides-ico.svg"
  },
  "team-of-20": {
    width: 49,
    height: 30,
    smallWidth: 40,
    smallHeight: 25,
    url: "https://d2dks4tzxs6xee.cloudfront.net/img/pga/team-of-20-ico.svg"
  },
  captech: {
    width: 25,
    height: 30,
    smallWidth: 25,
    smallHeight: 30,
    url:
      "https://d2dks4tzxs6xee.cloudfront.net/img/CT_ChatGPT_PGAChampionship_MakersMark.png"
  },
  corebridge: {
    width: 42,
    height: 45,
    smallWidth: 25,
    smallHeight: 30,
    url:
      "https://d2dks4tzxs6xee.cloudfront.net/img/PGAA_CF_Team_RGB (1) (1).png"
  },
  support: {
    width: 38,
    height: 33,
    smallWidth: 36,
    smallHeight: 31,
    url: "https://d2dks4tzxs6xee.cloudfront.net/img/pga/live-support-ico.svg"
  },
  chat1: {
    width: 36,
    height: 36,
    smallWidth: 32,
    smallHeight: 32,
    url: "https://d2dks4tzxs6xee.cloudfront.net/img/pga/chat-1-ico.svg"
  },
  chat2: {
    width: 45,
    height: 62,
    smallWidth: 38,
    smallHeight: 32,
    url: "https://d2dks4tzxs6xee.cloudfront.net/img/pga/chat-2-ico.svg"
  },
  website: {
    width: 36,
    height: 36,
    smallWidth: 35,
    smallHeight: 35,
    url: "https://d2dks4tzxs6xee.cloudfront.net/img/pga/website-icon.svg"
  },
  food: {
    width: 43,
    height: 43,
    smallWidth: 35,
    smallHeight: 35,
    url: "https://d2dks4tzxs6xee.cloudfront.net/img/pga/food-icon.svg"
  },
  shirt: {
    width: 43,
    height: 43,
    smallWidth: 35,
    smallHeight: 35,
    url: "https://d2dks4tzxs6xee.cloudfront.net/img/pga/shirt-icon.svg"
  }
};

export const ICONS_FALLBACK = {
  groups: "team-of-20"
};
