import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import autobind from "autobind-decorator";
import css from "../styles.scss";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Body from "components/Global/Modal/Layout/ScrollableBody";
import {
  ButtonGroup,
  Submit,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";
import CircularProgress from "material-ui/CircularProgress";
import Panel from "../Panel";
import Item from "../Item";

export const Loading = CSSModules(
  () => (
    <div styleName="progress">
      <CircularProgress color="#ccc" />
    </div>
  ),
  css
);

@CSSModules(css)
class AssignFormsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      filter: null,
      toAdd: {},
      toRemove: {}
    };
  }

  componentDidMount() {
    Promise.all([
      this.props.getForms(this.props.eventDetails.id),
      this.props.getAssignedForms({
        recordIds: this.props.recordIds,
        eventId: this.props.eventDetails.id
      })
    ]).then(([forms]) => {
      const toAdd = {};
      if (this.props.formIds) {
        this.props.formIds.forEach(id => {
          const item = forms.find(f => f.id === id);
          if (item) {
            toAdd[id] = item;
          }
        });
      }

      this.setState({ loading: false, toAdd });
    });
  }

  onFilterChange = e => this.setState({ filter: e.target.value });

  clearFilter = () => this.setState({ filter: null });

  onItemSelect = item => {
    this.setState(state => {
      if (state.toAdd[item.id]) {
        delete state.toAdd[item.id];
      } else {
        state.toAdd[item.id] = item;
      }
      return state;
    });
  };

  onExistingItemSelect = item => {
    this.setState(state => {
      if (state.toRemove[item.id]) {
        delete state.toRemove[item.id];
      } else {
        state.toRemove[item.id] = item;
      }
      return state;
    });
  };

  getFilteredItems = (filter, items) => {
    const formattedItems = Object.keys(items).map(k => items[k]);
    if (!filter) return formattedItems;

    const formattedFilter = filter.trim().toLowerCase();
    return formattedItems.filter(i => i.search.indexOf(formattedFilter) > -1);
  };

  isSelected = item => Boolean(this.state.toAdd[item.id]);

  isExistingItemSelected = item => Boolean(this.state.toRemove[item.id]);

  @autobind
  async deleteAssignedForms() {
    const formIdsToRemove = Object.keys(this.state.toRemove);

    if (!formIdsToRemove.length) return true;

    const result = await this.props.deleteAssignedForms({
      recordIds: this.props.recordIds,
      formIds: formIdsToRemove,
      eventId: this.props.eventDetails.id
    });

    return result;
  }

  @autobind
  async addAssignedForms() {
    const formIdsToAdd = Object.keys(this.state.toAdd);

    if (!formIdsToAdd.length) return true;

    const result = await this.props.addAssignedForms({
      recordIds: this.props.recordIds,
      formIds: formIdsToAdd,
      eventId: this.props.eventDetails.id
    });

    return result;
  }

  @autobind
  async saveAndClose() {
    this.setState({ saving: true });
    await this.deleteAssignedForms();
    await this.addAssignedForms();
    this.props.hideModal();
    this.props.showSnackbar({ message: "Assigned forms updated" });
    if (this.props.onDone) {
      this.props.onDone();
    }
  }

  render() {
    const { loading, saving, filter, toAdd } = this.state;
    const {
      assignedForms,
      forms,
      recordIds,
      recordNamePlural,
      recordNameSingular
    } = this.props;

    const filtered = this.getFilteredItems(filter, forms);

    return (
      <StyleWrapper
        bodyStyles={{ padding: 0 }}
        containerStyles={{ overflowY: "hidden" }}
        heading={`Assigning forms to ${recordIds.length} ${
          recordIds.length !== 1 ? recordNamePlural : recordNameSingular
        }`}
        hideModal={this.props.hideModal}
        width={745}
        height={600}
      >
        <Body
          style={{
            height: 400,
            padding: "20px 30px 0px 30px"
          }}
        >
          {loading ? (
            <Loading />
          ) : (
            <div styleName="container">
              <div styleName="users">
                <div styleName="inputWrapper">
                  <input
                    autoFocus
                    value={filter}
                    onChange={this.onFilterChange}
                    placeholder="Search forms..."
                    styleName="input"
                    type="text"
                  />
                  {filter ? (
                    <i
                      className="material-icons"
                      onClick={this.clearFilter}
                      styleName="clearFilter"
                    >
                      close
                    </i>
                  ) : (
                    <i className="material-icons">search</i>
                  )}
                </div>
                <div styleName="usersLists">
                  {!filtered.length ? (
                    <div styleName="empty">No forms matched your search</div>
                  ) : null}
                  {filtered.map(item => (
                    <Item
                      key={item.id}
                      name={item.name}
                      onClick={() => this.onItemSelect(item)}
                      selected={this.isSelected(item)}
                      showCheckbox
                    />
                  ))}
                </div>
              </div>
              <div styleName="owners">
                <Panel title="Selected:">
                  <div styleName="usersList">
                    {!Object.keys(toAdd).length ? (
                      <div styleName="empty">No forms selected</div>
                    ) : null}
                    {Object.keys(toAdd).map(itemId => {
                      const item = toAdd[itemId];
                      return (
                        <Item
                          key={itemId}
                          name={item.name}
                          onClick={() => this.onItemSelect(item)}
                          selected={this.isSelected(item)}
                          style={{
                            backgroundColor: "#FDE7CB",
                            marginBottom: 3
                          }}
                          icon={
                            <i
                              className={[
                                "material-icons",
                                css.removeIcon
                              ].join(" ")}
                            >
                              close
                            </i>
                          }
                        />
                      );
                    })}
                  </div>
                </Panel>

                {Object.keys(assignedForms).length ? (
                  <Panel
                    title="Existing forms:"
                    description={`These forms are currently assigned to 1 or more of the ${
                      recordIds.length
                    } selected ${
                      recordIds.length !== 1
                        ? recordNamePlural
                        : recordNameSingular
                    }.`}
                  >
                    <div styleName="usersList">
                      {Object.keys(assignedForms).map(itemId => {
                        const item = assignedForms[itemId];
                        const isExistingItemSelected = this.isExistingItemSelected(
                          item
                        );
                        return (
                          <Item
                            key={item.id}
                            name={forms[itemId].name}
                            onClick={() => this.onExistingItemSelect(item)}
                            selected={isExistingItemSelected}
                            faded={isExistingItemSelected}
                            style={{
                              backgroundColor: "#F5F3F8",
                              marginBottom: 3
                            }}
                            icon={
                              isExistingItemSelected ? (
                                <span className={css.undo}>Undo</span>
                              ) : (
                                <i
                                  className={[
                                    "material-icons",
                                    css.removeIcon
                                  ].join(" ")}
                                >
                                  close
                                </i>
                              )
                            }
                          />
                        );
                      })}
                    </div>
                  </Panel>
                ) : null}
              </div>
            </div>
          )}
        </Body>
        <div className={css.footer}>
          <ButtonGroup>
            <Submit
              disabled={saving}
              title={
                <span>
                  <span>{saving ? "Saving" : "Save"}</span>
                  {saving ? (
                    <i
                      className={[
                        "fa",
                        "fa-circle-o-notch",
                        css.processingIcon
                      ].join(" ")}
                    />
                  ) : null}
                </span>
              }
              onClick={this.saveAndClose}
            />
            <ButtonOutline title="Cancel" onClick={this.props.hideModal} />
          </ButtonGroup>
        </div>
      </StyleWrapper>
    );
  }
}

AssignFormsModal.propTypes = {
  recordIds: PropTypes.array.isRequired,
  getForms: PropTypes.func.isRequired,
  getAssignedForms: PropTypes.func.isRequired,
  addAssignedForms: PropTypes.func.isRequired,
  deleteAssignedForms: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  eventDetails: PropTypes.object.isRequired,
  assignedForms: PropTypes.array.isRequired,
  forms: PropTypes.array.isRequired,
  recordNameSingular: PropTypes.string.isRequired,
  recordNamePlural: PropTypes.string.isRequired,
  onDone: PropTypes.func.isRequired
};

export default AssignFormsModal;
