import { createSelector } from "reselect";
import * as R from "ramda";

import { getPathname } from "App/Router/selectors";

export const getViewsToSelect = createSelector(
  getPathname,
  pathname =>
    [
      {
        id: "dashboard",
        name: "Dashboard",
        icon: "dashboard",
        to: pathname,
        visible: !pathname.includes("/booth")
      },
      {
        id: "my-approvals",
        name: "My Approvals",
        icon: "check_circle_outline",
        to: R.replace(/\/dashboard$/, "/manage/my-approvals", pathname),
        visible: true
      },
      {
        id: "all-requests",
        name: "All Requests",
        icon: "list",
        to: R.replace(/\/dashboard$/, "/manage/all-requests", pathname),
        visible: true
      },
      {
        id: "fulfill",
        name: "Issue",
        icon: "add_shopping_cart",
        to: R.replace(/\/dashboard$/, "/manage/fulfill", pathname),
        visible: !pathname.includes("/booth")
      }
    ].filter(item => item.visible)
);

export const getSelectedView = createSelector(
  getViewsToSelect,
  (_, props) => R.path(["location", "pathname"], props),
  (views, pathname) =>
    R.find(
      R.compose(
        R.contains(R.__, pathname), //eslint-disable-line no-underscore-dangle
        R.prop("id")
      ),
      views
    )
);
