import React from "react";

import { Div } from "components/Base";

import Header from "Organizations/EditEventModal/View/Header";

import Footer from "Organizations/EditEventModal/View/Footer";

import Body from "Organizations/EditEventModal/View/Body";

const Layout = () => (
  <Div m={5} style={{ overflow: "none" }} display="row.center.center">
    <Div
      display="column.flex-start"
      bra={1}
      shadow={2}
      bg="white"
      width={958}
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxHeight: "calc(100% - 100px)"
      }}
      onMouseDown={e => e.stopPropagation()}
    >
      <Header />
      <Body />
      <Footer />
    </Div>
  </Div>
);

export default Layout;
