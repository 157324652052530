import React from "react";
import { Div, Text4, FontIcon } from "components/Base";
import Circle from "ui-kit/Circle";

const ReviewedCard = ({ children, title }) => (
  <Div
    width={1}
    py={4}
    px={8}
    brb={1}
    bg="neutral0"
    bt={1}
    bc="neutral2"
    shadow={1}
  >
    <Div mt={3}>
      <Div display="row.flex-start.center">
        <Circle size={30} bg="neutral2">
          <FontIcon color="neutral6" fs={4} icon="thumb_up" />
        </Circle>
        <Text4 color="black" bold ml={2}>
          {title}
        </Text4>
      </Div>
      <Div mt={4}>{children}</Div>
    </Div>
  </Div>
);

export default ReviewedCard;
