import React from "react";
import PeopleCards from "./PeopleCards";
import Summary from "./Summary";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Body = () => (
  <div styleName="body">
    <div styleName="summary-mobile">
      <Summary />
    </div>
    <PeopleCards />
    <div styleName="summary-desktop">
      <Summary />
    </div>
  </div>
);

export default CSSModules(css)(Body);
