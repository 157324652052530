import React, { cloneElement } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as R from "ramda";
import { actions, getters } from "SalesPortal/Home";
import MediaQuery from "react-responsive";
import { Div } from "components/Base";
import Toolbar from "SalesPortal/Home/View/Toolbar";
import ItemsModal from "./ItemsModal";
import TermsModal from "./TermsModal";

const decorate = R.compose(
  withRouter,
  connect(
    state => ({
      salesData: getters.salesData(state),
      user: {
        id: 1,
        email: "dev@lennd.com",
        slug: "lennddev",
        fname: "LENND",
        lname: "Dev",
        coverPhotoUrl: null,
        photoUrl: null
      },
      modalEnabled: getters.modalEnabled(state),
      termsModalEnabled: getters.termsModalEnabled(state)
    }),
    {
      hideModal: () => actions.hideModal()
    }
  )
);

const isLoggedIn = u => u && Boolean(u.id);

const SalesPortal = ({
  modalEnabled,
  termsModalEnabled,
  user,
  salesData: { background_url: coverImage },
  children,
  handlers
}) => {
  return (
    <Div width={1} height={1}>
      {modalEnabled && <ItemsModal />}
      {termsModalEnabled && <TermsModal />}

      <Div
        width={1}
        height={1}
        display="column.flex-start.center"
        style={{ overflow: "auto" }}
      >
        {isLoggedIn(user) && <Toolbar handlers={handlers} />}
        <MediaQuery minWidth={1040}>
          <Div
            width={1}
            height={550}
            style={{ overflow: "hidden", position: "absolute", zIndex: -2 }}
            display="row.center.center"
            bg="black"
          >
            <img
              src={coverImage}
              style={{
                width: "100%",
                filter: "blur(8px)",
                transform: "scale(1.1)"
              }}
            />
          </Div>
        </MediaQuery>
        {cloneElement(children, {
          handlers
        })}
      </Div>
    </Div>
  );
};

export default decorate(SalesPortal);
