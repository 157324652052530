/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

export default {
  getMappedFile: (credentials, { userId, eventId, url }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/modules/import/mapping/orders?userId=${userId}`,
        credentials,
        success,
        error,
        data: { event_id: eventId, url }
      });
    }),
  downloadCustomized: (
    credentials,
    { eventId, userId, fieldGroups, orderType }
  ) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/modules/${STANDARD_MODULE_IDS.accounts.id}/export/orders`,
        success,
        error,
        credentials,
        data: {
          event_id: eventId,
          user_id: userId,
          fields_groups: fieldGroups,
          content_type: "csv",
          order_type: orderType
        }
      });
    }),
  postCsvData: (credentials, { moduleId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/modules/${moduleId}/import`,
        data,
        credentials,
        success,
        error
      });
    }),
  getCsvUploadStatus: (credentials, { moduleId, importId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules/${moduleId}/import/${importId}`,
        credentials,
        success,
        error
      });
    })
};
