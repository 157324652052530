/* eslint-disable no-underscore-dangle */
import React from "react";

import {
  put,
  call,
  all,
  fork,
  select,
  take,
  takeEvery
} from "redux-saga/effects";
import { makeFuture } from "utils/General/sagas";
import * as R from "ramda";
import { addS } from "utils/General";

import { actions } from "OrgLight/Events";
import { getters as ViewPickerGetters } from "ui-kit/ViewPicker";
import { actions as TableActions } from "ui-kit/Table/model";
import { actions as TimeTableActions } from "ui-kit/TimeTable/model";
import { hideModal, showModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";

import {
  ROW_ACTIONS as TABLE_ROW_ACTIONS,
  GROUP_ACTIONS
} from "ui-kit/Table/constants";
import { VIEWPICKER_INSTANCE_ID } from "OrgLight/Events/constants";

import { getCredentials } from "redux/modules/user/selectors";
import {
  orgId as getOrgId,
  organization
} from "redux/modules/organization/selectors";
import * as EventsSelectors from "redux/modules/events/selectors";
import { ROW_ACTIONS } from "OrgLight/Events/constants";
import Api from "../api";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import DeleteConfirmation from "components/Global/CRM/Modals/DeleteConfirmation";
import SubscribeModal from "Modules/SubscribeModal/View";
import EditEventModal from "Organizations/EditEventModal/View";

import AddEventModal from "Organizations/CloneEventModal/View";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

import { registerError } from "redux/modules/errors/actions";

import { navigateTo } from "utils/General";

const showEditEventModal = function*({ eventId }) {
  const handleClose = makeFuture();

  yield put(
    showModal({
      content: (
        <EditEventModal id={eventId} onDone={() => handleClose.done(true)} />
      ),
      wrapper: ModalWrapper
    })
  );

  return yield call(handleClose.onRealized);
};

const watchEditEvent = function*() {
  for (;;) {
    const { payload: event } = yield take(TimeTableActions.eventEdit.type);
    const result = yield call(showEditEventModal, { eventId: event.id });

    if (result) {
      yield put(actions.fetchData());
    }
  }
};

const openRecord = function*({ payload: { row } }) {
  const result = yield call(showEditEventModal, { eventId: row.id });
  if (result) {
    yield put(actions.fetchData());
  }
};

const exportGroup = function*({ payload: { group, actionId } }) {
  yield put(actions.setLoading(true));
  try {
    // @NOTE: replace by api call
    const result = yield call(R.always({ url: "./" }), {
      groupIds: group.list,
      type: R.prop(actionId, {
        [GROUP_ACTIONS.EXPORT_CSV]: "csv",
        [GROUP_ACTIONS.EXPORT_XLSX]: "xlsx"
      })
    });
    yield call(navigateTo, result.url);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred exporting the schedule group"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const showAddEventModal = function*({ eventIdToClone }) {
  const handleAddEvent = yield call(makeFuture);

  const org = yield select(organization);
  // const allUserEvents = yield select(EventsSelectors.events);
  const isLenndAdmin = yield select(
    selectFeatureFlag(flags.IS_LENND_ADMIN.NAME)
  );
  let isCloning = false;

  yield put(
    showModal({
      content: (
        <AddEventModal
          eventIdToClone={eventIdToClone}
          //
          addEvent={() =>
            handleAddEvent.done({
              createEvent: true,
              shouldHideModal: true
            })
          }
          organization={org}
          // allUserEvents={allUserEvents}
          isLenndAdmin={isLenndAdmin}
          isCloning={() => {
            isCloning = true;
          }}
          hideModal={() => {
            if (isCloning) return false;

            return handleAddEvent.done({
              createEvent: false,
              shouldHideModal: true
            });
          }}
        />
      ),
      wrapper: ModalWrapper
    })
  );

  return yield call(handleAddEvent.onRealized);
};

const handleAddEvent = function*() {
  const orgId = yield select(getOrgId);
  const credentials = yield select(getCredentials);

  const { createEvent, shouldHideModal } = yield call(showAddEventModal, {});

  if (shouldHideModal) {
    yield put(hideModal());
  }

  if (createEvent) {
    yield put(showSnackbar({ message: "Creating event..." }));

    const { payload: event } = yield call(Api.addEvent, credentials, {
      orgId
    });
    window.location = `/event-light/${event.id}/dashboard`;
  }
};

const showDeleteEventsModal = function*(toggledRows) {
  const handleSubmit = makeFuture();

  yield put(
    showModal({
      content: (
        <DeleteConfirmation
          heading={`Delete Event${addS(toggledRows.length)}?`}
          message={
            <div>
              {`
                    Are you sure you want to delete
                    ${
                      toggledRows.length === 1
                        ? "this"
                        : `these ${toggledRows.length}`
                    }
                    event${addS(toggledRows.length)}?
                    `}
              <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                This cannot be undone.
              </div>
            </div>
          }
          onConfirm={() => handleSubmit.done(true)}
        />
      ),
      wrapper: ModalWrapper
    })
  );

  return yield call(handleSubmit.onRealized);
};

const deleteEvents = function*({ payload: eventIds }) {
  const credentials = yield select(getCredentials);
  const shouldDelete = yield call(showDeleteEventsModal, eventIds);

  if (shouldDelete) {
    const orgId = yield select(getOrgId);

    yield call(Api.deleteEvents, {
      credentials,
      data: {
        bulk: true,
        orgId,
        eventIds
      }
    });

    yield put(
      showSnackbar({
        message: `Event${addS(eventIds.length)} deleted`,
        action: "OK"
      })
    );
    yield put(actions.fetchData());
  }
};

const watchTableActions = function*() {
  for (;;) {
    const action = yield take(TableActions.executeAction.type);
    const delegate = R.prop(action.payload.actionId, {
      [ROW_ACTIONS.VIEW_RECORD]: openRecord,
      [ROW_ACTIONS.OPEN_EVENT_MODAL]: openRecord,
      [TABLE_ROW_ACTIONS.OPEN_RECORD]: openRecord,
      [GROUP_ACTIONS.EXPORT_CSV]: exportGroup,
      [GROUP_ACTIONS.EXPORT_XLSX]: exportGroup
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const showSubscribeModal = function*() {
  const activeViewId = yield select(ViewPickerGetters.activeViewId, {
    instanceId: VIEWPICKER_INSTANCE_ID
  });

  yield put(
    showModal({
      content: <SubscribeModal type="module" viewId={activeViewId} />,
      wrapper: ModalWrapper
    })
  );
};

const watchShowAddEventModal = function*() {
  yield takeEvery(actions.showAddEventModal.type, handleAddEvent);
};

const watchShowSubscribeModal = function*() {
  yield takeEvery(actions.showSubscribeModal.type, showSubscribeModal);
};

const watchShowDeleteEventsModal = function*() {
  yield takeEvery(actions.showDeleteEventsModal.type, deleteEvents);
};

const rootSaga = function*() {
  yield all([
    fork(watchEditEvent),
    fork(watchTableActions),
    fork(watchShowSubscribeModal),
    fork(watchShowAddEventModal),
    fork(watchShowDeleteEventsModal)
  ]);
};

export default rootSaga;
