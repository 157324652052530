import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { getters } from "EventLight/Agenda/Manage";

import SecondarySidebar from "ui-kit/SecondarySidebar";

import { Div } from "components/Base";

import ShowHideFields from "./ShowHideFields";
import Fields from "./FieldFilter";
import GroupBy from "./GroupByFilter";
import ViewPicker from "./ViewPicker";
import SortByPicker from "./SortByPicker";

const filters = {
  viewPicker: ({ instanceId }) => <ViewPicker mt={2} instanceId={instanceId} />,
  groupBy: <GroupBy mt={2} />,
  sortBy: <SortByPicker mt={2} />,
  fields: <Fields mt={2} />,
  showHideFields: ({ showSelectFieldsModal, preferences }) => (
    <ShowHideFields onClick={() => showSelectFieldsModal(preferences)} mt={2} />
  )
};

const OPTIONS = [
  "viewPicker",
  "showHideFields",
  // "groupBy",
  "fields",
  "sortBy"
];

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    preferences: getters.preferences(state, props)
  }))
);

const Sidebar = ({
  preferences,
  instanceId,
  handlers: { showSelectFieldsModal }
}) => (
  <SecondarySidebar display="column.flex-start.stretch">
    <Div px={3} pb={2} display="column.flex-start.stretch">
      {R.map(
        filter =>
          typeof filters[filter] === "function"
            ? React.createElement(filters[filter], {
                preferences,
                showSelectFieldsModal,
                instanceId
              })
            : filters[filter],
        OPTIONS
      )}
    </Div>
  </SecondarySidebar>
);

export default decorate(Sidebar);
