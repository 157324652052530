module.exports = {
  ACCOUNT_PRIMARY_CONTACTS: "account-primary-contacts",
  ACCOUNT_CONTACT_USERS: "account-contact-users",
  CONTACT: "contact",
  EMAIL_FIELD: "email-field",
  CONTACT_LOOKUP: "contact-lookup",
  ACCOUNT_LOOKUP_PRIMARY_CONTACTS: "account-lookup-primary-contacts",
  ACCOUNT_LOOKUP_CONTACT_USERS: "account-lookup-contact-users",
  ORDER_CUSTOMER: "order-customer",
  //
  PHONE_FIELD: "phone-field"
};
