import React from "react";
import { Div } from "components/Base";

const EditWrapper = ({ children, onClick, ...styleProps }) => {
  return (
    <Div
      display="row.flex-start.center"
      width={1}
      height={1}
      p={2}
      bra={1}
      bg={{
        hover: "gray1",
        default: "transparent"
      }}
      onClick={onClick}
      {...styleProps}
    >
      {children}
    </Div>
  );
};

export default EditWrapper;
