import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FieldGroupSettings extends Component {
  render() {
    const { fieldGroups, onChange, value } = this.props;
    if (!fieldGroups || !fieldGroups.length) {
      return "";
    }
    return (
      <div className="input-wrapper">
        <label styleName="formLabel">Field Group</label>
        <select
          key="fieldgroup"
          styleName="select"
          onChange={onChange}
          value={value}
        >
          {fieldGroups.map(group => (
            <option value={group.id} key={group.id}>
              {group.name}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

FieldGroupSettings.defaultProps = {
  fieldGroups: []
};

FieldGroupSettings.propTypes = {
  fieldGroups: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired
};

export default FieldGroupSettings;
