import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../../model";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import { Input } from "ui-kit/Form/View";
import {
  BigFilledButton,
  BigOutlineButton,
  Div,
  NoneIcon,
  PersonIcon,
  PeopleIcon,
  Text3,
  Text1
} from "components/Base";
import { getNameForm } from "EventLight/Forms/Forms/selectors";
import { noop } from "utils/General";

const decorate = R.compose(
  connect(
    state => ({
      scopeSelected: getters.scopeSelected(state),
      nameForm: getNameForm(state),
      isSaving: getters.isSaving(state)
    }),
    {
      hideSimpleFormModal: () => actions.hideSimpleFormModal(),
      selectFormScope: actions.setScopeSelected,
      saveBuildForm: actions.saveBuildForm
    }
  ),
  CSSModules(css)
);

const FormOption = ({
  name = "",
  scopeSelected = "",
  Icon = NoneIcon,
  description = "",
  onClick = noop,
  scope = null
}) => (
  <Div
    display="column.center.center"
    p={5}
    bc={
      scope === scopeSelected
        ? { default: "info7", hover: "info7" }
        : { default: "neutral1", hover: "info7" }
    }
    transition="fast"
    className="formOption"
    onClick={onClick}
  >
    <Icon
      color={
        scope === scopeSelected
          ? { default: "info7", hover: "info7" }
          : { default: "neutral1", hover: "info7" }
      }
      size={50}
    />
    <Text3 color={"neutral7"} bb={2} bc={"transparent"} pb={1} bold>
      {name}
    </Text3>
    <Text1
      color={"neutral7"}
      bb={2}
      bc={"transparent"}
      pl={2}
      pr={2}
      pb={1}
      textAlign="center"
    >
      {description}
    </Text1>
  </Div>
);

const SimpleFormModal = ({
  hideSimpleFormModal = noop,
  selectFormScope = noop,
  scopeSelected = "",
  saveBuildForm = noop,
  nameForm = "",
  isSaving = false
}) => {
  return (
    <MiniModalWrapper
      hideModal={hideSimpleFormModal}
      title={"Create New Form"}
      display="column.flex-start.stretch"
      width={600}
      height={450}
      showModal
    >
      <div styleName="simple-form-modal">
        <div styleName="body">
          <div styleName="fieldWrapper">
            <div styleName="label">What is the name of your form?</div>
            <Input fieldId="name" iniValue="" size="medium" width={1} />
          </div>
          <div styleName="fieldWrapper">
            <div styleName="label">Who do you want to create a form for?</div>
            <Div
              display="row.space-between"
              pt={2}
              style={{
                gap: "50px"
              }}
              fieldId="scope"
              iniValue=""
            >
              <FormOption
                name="Individuals"
                Icon={PersonIcon}
                description="Use Individual Forms to collect information from individual people"
                onClick={() => selectFormScope("contact")}
                scopeSelected={scopeSelected}
                scope="contact"
              />
              <FormOption
                name="Groups"
                Icon={PeopleIcon}
                description="Use Group Forms to collect information from a group"
                onClick={() => selectFormScope("account")}
                scopeSelected={scopeSelected}
                scope="account"
              />
            </Div>
          </div>
        </div>
        <div styleName="footer">
          <BigFilledButton
            bg="altB5"
            onClick={() => {
              saveBuildForm();
            }}
            disabled={!scopeSelected || !nameForm}
          >
            {isSaving ? "Saving…" : "Save & Build Form"}
          </BigFilledButton>
          <BigOutlineButton
            ml={2}
            onClick={() => {
              hideSimpleFormModal();
            }}
          >
            Cancel
          </BigOutlineButton>
        </div>
      </div>
    </MiniModalWrapper>
  );
};
export default decorate(SimpleFormModal);
