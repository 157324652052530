import React, { Component } from "react";
import * as R from "ramda";

import getValue from "utils/value-types/get-value/catalog-item";

import OptionsList from "./List";
import OptionQuantity from "./QuantityInput";

import { getSettings } from "ui-kit/Datagrid/utils";

class Option extends Component {
  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props.value, nextProps.value);
  }

  formatValue(value) {
    return getValue(value);
  }

  render() {
    const props = this.props;

    const type = getSettings(props, "selectType");
    const options = getSettings(props, "availableCatalogItems");
    const order = getSettings(props, "order");
    const records = this.formatValue(props.value).records;

    switch (type) {
      case "quantity-only-dropdown":
      case "quantity-only-input":
        return <OptionQuantity quantity={R.path(["0", "quantity"], records)} />;
      default:
        return (
          <OptionsList order={order} options={options} records={records} />
        );
    }
  }
}

export default Option;
