import React from "react";
import { SubNavWrapper, SubNavLink } from "EventLight/Common/SubNav";
import { TAB_IDS } from "./constants";
import { withRouter } from "react-router";

const SubNav = ({ selectedView, router, params }) => (
  <SubNavWrapper supportId="schedules">
    <SubNavLink
      name="Schedules"
      active={selectedView === TAB_IDS.SCHEDULES}
      onClick={() =>
        router.push({
          pathname: `/event-light/${params.eventId}/schedules/master`
        })
      }
    />
    {/*
    <SubNavLink
      name="Settings"
      active={selectedView === TAB_IDS.SETTINGS}
      onClick={() =>
        router.push({
          pathname: `/event-light/${params.eventId}/schedules/settings`
        })
      }
    />
    */}
  </SubNavWrapper>
);

export default withRouter(SubNav);
