import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { get, sortBy } from "lodash";
import getValue from "utils/value-types/get-value";
import toString from "utils/value-types/to-string";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Loading from "components/Global/Modal/Layout/Loading";
import PortalView from "./PortalView";
import AdminView from "./AdminView";
import Header from "./Shared/Header";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as FILE_RECORD_FIELDS from "@lennd/value-types/src/constants/standard-module-field-ids";
import {
  DOCUMENT_REQUEST_REVISIONS,
  DOCUMENT_REQUEST_TYPES,
  DOCUMENT_REQUESTS,
  FILES
} from "utils/standard-module-field-ids";
import Api from "./api";

const UPLOAD_FIELD_ID = "d512aeba-ae22-4623-b64c-90f293e37f16";
const { TITLE } = FILES;
const { FILES: REVISION_FILES, REVISION_STATUS } = DOCUMENT_REQUEST_REVISIONS;
const { NAME } = DOCUMENT_REQUEST_TYPES;
const { RECIPIENT_NAME } = DOCUMENT_REQUESTS;

@CSSModules(css)
class DocumentRequestModal extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, record: {} };
  }

  modified = false;

  componentDidMount = async () => {
    await this.props.getModule({
      moduleId: STANDARD_MODULE_IDS.documentRequests.id,
      options: {
        eventId: this.props.eventDetails.id
      }
    });

    if (this.props.recordId) {
      await this.fetchRecord();
    } else if (this.props.requestTypeId) {
      const { payload } = await Api.findOrCreateSubmission({
        credentials: this.props.credentials,
        eventId: this.props.eventDetails.id,
        requestTypeId: this.props.requestTypeId,
        accountId: this.props.accountId,
        contactId: this.props.contactId
      });

      await this.props.getRecord({
        moduleId: STANDARD_MODULE_IDS.documentRequests.id,
        recordId: payload.id,
        options: {
          eventId: this.props.eventDetails.id
        }
      });

      this.setState({
        record: this.props.getFetchedRecord(payload.id)
      });
    }

    this.setState({ loading: false });
  };

  getRevisions = () => {
    const revisions = get(
      this.state.record,
      ["expanded", STANDARD_MODULE_IDS.documentRequestRevisions.id, "records"],
      {}
    );
    return sortBy(
      Object.keys(revisions).map(id => revisions[id]),
      r => new Date(r.created_at)
    ).reverse();
  };

  getLatestRevision = () => {
    const revisions = this.getRevisions();
    return revisions[0];
  };

  getRequestType = () => {
    return get(
      this.state.record,
      [
        "expanded",
        STANDARD_MODULE_IDS.documentRequestTypes.id,
        "records",
        this.state.record.request_type_id
      ],
      {}
    );
  };

  getStatus = () => {
    const latestRevision = this.getLatestRevision();
    if (!latestRevision) return "Not Submitted";
    return toString(
      getValue(latestRevision.values[REVISION_STATUS], "dropdown"),
      "dropdown"
    );
  };

  componentWillUnmount() {
    if (this.modified && this.props.onDone) {
      this.props.onDone();
    }
  }

  createRecord = () => {
    this.props.createRe({
      moduleId: STANDARD_MODULE_IDS.documentRequests.id,
      recordId: this.props.recordId,
      options: {
        eventId: this.props.eventDetails.id
      }
    });
  };

  fetchRecord = async () => {
    await this.props.getRecord({
      moduleId: STANDARD_MODULE_IDS.documentRequests.id,
      recordId: this.state.record.id || this.props.recordId,
      options: {
        eventId: this.props.eventDetails.id
      }
    });
    this.setState({
      record: this.props.getFetchedRecord(
        this.state.record.id || this.props.recordId
      )
    });
  };

  markAsModified = () => {
    this.modified = true;
  };

  getView = () => {
    const isOwner = Boolean(
      get(this.state.record, ["request_type", "owners"], []).find(
        o => o.id === this.props.user.id
      )
    );

    return (
      <div>
        <Header
          {...this.props}
          record={this.state.record}
          requestType={this.getRequestType()}
          status={this.getStatus()}
        />
        {this.props.view === "admin" ? (
          <AdminView
            {...this.props}
            record={this.state.record}
            revisions={this.getRevisions()}
            latestRevision={this.getLatestRevision()}
            requestType={this.getRequestType()}
            status={this.getStatus()}
            markAsModified={this.markAsModified}
            fetchRecord={this.fetchRecord}
            updateRevisionStatus={this.updateRevisionStatus}
            onRevisionMenuChange={this.onRevisionMenuChange}
            readOnly={!isOwner}
          />
        ) : (
          <PortalView
            {...this.props}
            record={this.state.record}
            revisions={this.getRevisions()}
            latestRevision={this.getLatestRevision()}
            requestType={this.getRequestType()}
            status={this.getStatus()}
            markAsModified={this.markAsModified}
            fetchRecord={this.fetchRecord}
            updateRevisionStatus={this.updateRevisionStatus}
            onRevisionMenuChange={this.onRevisionMenuChange}
          />
        )}
      </div>
    );
  };

  updateRevisionStatus = status => {
    this.modified = true;

    const latestRevision = this.getLatestRevision();
    const requestType = this.getRequestType();

    this.props
      .addValue({
        fieldId: REVISION_STATUS,
        moduleId: STANDARD_MODULE_IDS.documentRequestRevisions.id,
        recordId: latestRevision.id,
        value: { type: "dropdown", value: { records: [{ value: status }] } }
      })
      .then(() => {
        const fileRequestId = this.state.record.id || this.props.recordId;

        this.fetchRecord();

        if (status === "Approved") {
          const files = getValue(
            get(latestRevision.values, [REVISION_FILES]),
            "file"
          );

          // if approved, add document to account
          const title = `${getValue(
            get(requestType.values, [NAME]),
            "text"
          )} - ${getValue(
            get(this.state.record.values, [RECIPIENT_NAME]),
            "text"
          )}`;
          const countOfFiles = files.length;
          return Promise.all(
            files.map((file, idx) =>
              this.props.addRecord({
                moduleId: STANDARD_MODULE_IDS.files.id,
                record: {
                  [FILE_RECORD_FIELDS.FILES
                    .FILE_DOCUMENT_REQUEST_ID]: fileRequestId,
                  relatedModuleId: this.state.record.recipient_module_id,
                  relatedRecordId: this.state.record.recipient_record_id,
                  [TITLE]: {
                    type: "text",
                    value:
                      countOfFiles > 1
                        ? `${title} (${idx + 1} of ${countOfFiles})`
                        : title
                  },
                  [UPLOAD_FIELD_ID]: {
                    type: "text",
                    value: {
                      files: [file]
                    }
                  }
                },
                options: {
                  eventId: this.props.eventDetails.id
                }
              })
            )
          );
        }

        return null;
      });
  };

  onRevisionMenuChange = (event, value) => {
    event.stopPropagation();
    switch (value) {
      case "revert-to-pending":
        this.updateRevisionStatus("Pending");
        break;
      case "approve":
        this.updateRevisionStatus("Approved");
        break;
      case "deny":
        this.updateRevisionStatus("Denied");
        break;
      default:
        break;
    }
  };

  render() {
    const { hideModal } = this.props;
    return (
      <StyleWrapper
        hideHeader
        hideModal={hideModal}
        width={909}
        bodyStyles={{ padding: 0 }}
      >
        {this.state.loading ? <Loading /> : this.getView()}
      </StyleWrapper>
    );
  }
}

DocumentRequestModal.propTypes = {
  recordId: PropTypes.string.isRequired,
  view: PropTypes.string.isRequired,
  getModule: PropTypes.func.isRequired,
  getRecord: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  eventDetails: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  addValue: PropTypes.func.isRequired,
  latestRevision: PropTypes.object.isRequired,
  logAction: PropTypes.func.isRequired,
  addRecord: PropTypes.func.isRequired,
  record: PropTypes.object.isRequired,
  requestType: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default DocumentRequestModal;
