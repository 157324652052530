import Helpers from "utils/Global/Helpers";

module.exports = {
  get: submissionId =>
    // return new Promise((success, error) => setTimeout(() => success(COMMENTS), 4000))
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/form/submissions/${submissionId}/comments`,
        success,
        error
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  post: (credentials, data) =>
    // new Promise((success, error) => setTimeout(() => success(), 1000));
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/form/submissions/${
          data.submissionId
        }/comments`,
        credentials,
        data,
        success,
        error
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
