import React from "react";

import { connect } from "react-redux";
import { Div } from "components/Base";

import { getters } from "../model";

import Table, { TableWrapper } from "ui-kit/Table/View";
import { TABLE_INSTANCE_ID } from "../constants";
import EmptyState from "EventLight/Common/EmptyState";

const decorate = connect(state => ({
  webhooks: getters.webhooks(state)
}));

const Body = ({ webhooks }) =>
  !webhooks.length ? (
    <EmptyState icon="outbound" title="You haven't added any webhooks yet" />
  ) : (
    <Div width={1}>
      <TableWrapper
        instanceId={TABLE_INSTANCE_ID}
        style={{ flex: 1, overflowY: "inherit" }}
        innerStyle={{ overflowY: "inherit" }}
      >
        <Table showCheckbox={false} instanceId={TABLE_INSTANCE_ID} />
      </TableWrapper>
    </Div>
  );

export default decorate(Body);
