import React from "react";
import { connect } from "react-redux";
import { getters } from "../model";
import { Div } from "components/Base";
import Body from "./Body";
import Loading from "ui-kit/Loading";
import { WIDTH } from "../constants";
import AlertsCreateModal from "Portal/Alerts/Create/View";

const decorate = connect(state => ({
  loading: getters.loading(state)
}));

const Layout = ({ loading }) => (
  <Div width={1} display="row.center.flex-start" mt={3} mb={5}>
    <Div
      width={1}
      display="column"
      style={{
        maxWidth: WIDTH
      }}
    >
      {loading ? <Loading /> : <Body />}
    </Div>
    <AlertsCreateModal />
  </Div>
);

export default decorate(Layout);
