import React from "react";
import { Div } from "components/Base";
import { WIDTH } from "Portal/List/constants";
import GroupPortals from "./GroupPortals";
import IndividualPortals from "./IndividualPortals";
import SalesPortals from "./SalesPortals";
import CustomSites from "./CustomSites";

const Body = () => (
  <Div width={1} display="row.center.flex-start" mb={5}>
    <Div
      width={1}
      style={{
        maxWidth: WIDTH
      }}
    >
      <GroupPortals />

      <IndividualPortals />
      {/*
      <SalesPortals />
      <CustomSites />
      */}
    </Div>
  </Div>
);

export default Body;
