import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { noop } from "utils/General";

import {
  Div,
  Text3,
  Text2,
  CloseIcon,
  Cards,
  DragIcon,
  SmallFilledBox
} from "components/Base";

import { actions, getters } from "ui-kit/MiniItemsSelector";

import {
  getEnabledVariantTypes,
  getSelectedItemsMap
} from "ui-kit/MiniItemsSelector/selectors";
import { bindInstance } from "redux-mvc";

import {
  MODE_MULTIPLE_SELECT,
  MODE_SINGLE_SELECT
} from "ui-kit/MiniItemsSelector/constants";

const decorate = connect(
  (state, props) => ({
    selectedFields: getEnabledVariantTypes(state, props),
    selectedCount: getSelectedItemsMap(state, props),
    mode: getters.mode(state, props),
    enableReorder: getters.enableReorder(state, props)
  }),
  bindInstance({
    deselectField: actions.deselectField,
    onReorder: actions.handleReorder
  })
);

const ItemRow = ({
  selectedCount,
  deselectField,
  mode,
  enableReorder,
  ...field
}) => {
  return (
    <Div
      shadow={1}
      mt={1}
      bg={field.required ? "white" : { default: "white", hover: "gray1" }}
      color="black"
      display="row.flex-start.center"
      onClick={field.required ? noop : () => deselectField(field.id)}
      bra={1}
      height={33}
      px={3}
      key={field.id}
      style={{
        minHeight: "33px"
      }}
    >
      <Div display="row.flex-start.center" truncate flex={1}>
        {enableReorder ? (
          <DragIcon
            style={{
              flexShrink: 0
            }}
            size={15}
            mr={1}
          />
        ) : null}
        <Div display="row.space-between.center" truncate flex={1}>
          <Text2 bold truncate>
            {field.name}
            {"  "}
            {mode === MODE_MULTIPLE_SELECT
              ? `(${selectedCount[field.id]})`
              : ""}
          </Text2>
          {field.label && mode !== MODE_MULTIPLE_SELECT && (
            <SmallFilledBox mr={1} bg="neutral3">
              {field.label}
            </SmallFilledBox>
          )}
        </Div>
      </Div>
      {field.required ? null : (
        <CloseIcon
          style={{
            flexShrink: 0
          }}
          color={{ default: "neutral6", hover: "danger8" }}
        />
      )}
    </Div>
  );
};

const ItemRows = Cards(ItemRow, "ITEM_CARD_TYPE");

const SelectedSummary = ({
  selectedFields = [],
  selectedCount = {},
  deselectField = noop,
  mode = MODE_SINGLE_SELECT,
  enableReorder = false,
  onReorder = noop
}) => {
  return (
    <Div
      bg="neutral0"
      bra={1}
      p={4}
      style={{ overflowY: "auto", width: "40%" }}
    >
      <Text3 bold mb={1}>
        Selected
      </Text3>
      {R.length(selectedFields) ? (
        enableReorder ? (
          <ItemRows
            cards={selectedFields}
            selectedCount={selectedCount}
            deselectField={deselectField}
            mode={mode}
            enableReorder={enableReorder}
            onReorder={onReorder}
          />
        ) : (
          R.map(
            field => (
              <ItemRow
                selectedCount={selectedCount}
                deselectField={deselectField}
                mode={mode}
                key={field.id}
                enableReorder={enableReorder}
                {...field}
              />
            ),
            selectedFields
          )
        )
      ) : (
        <Text2 color="neutral5" mb={1}>
          You haven't selected any items yet.
        </Text2>
      )}
      {/* @TODO: Fetch existing variants */}
    </Div>
  );
};

export default decorate(SelectedSummary);
