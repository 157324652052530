import PropTypes from "prop-types";
import React, { Component } from "react";
import getValue from "utils/value-types/get-value/text";
import autobind from "autobind-decorator";

export default class TextEditor extends Component {
  formatValue(value) {
    return getValue(value);
  }

  wrapValue(value) {
    return {
      type: this.props.type === "password" ? "password" : "text",
      value
    };
  }

  @autobind
  onUpdate(e) {
    this.props.onChange(this.wrapValue(e.target.value));
  }

  render() {
    return (
      <input
        {...this.props}
        type={this.props.type === "password" ? "password" : "text"}
        onChange={this.onUpdate}
        className={["form-control", this.props.className].join(" ")}
        value={this.formatValue(this.props.value)}
      />
    );
  }
}

TextEditor.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.object
};
