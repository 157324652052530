import React, { Component } from "react";
import getValue from "utils/value-types/get-value/number";
import toString from "utils/value-types/to-string/currency";
import { isEqual } from "lodash";
import { Div } from "components/Base";

export default class CurrencyCellFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  render() {
    const value = getValue(this.props.value);
    const formatted = value
      ? toString(value, this.props.dependentValues.meta.columnSettings)
      : "";

    return (
      <Div
        truncate
        style={{
          lineHeight: "34px"
        }}
        title={formatted}
      >
        {formatted}
      </Div>
    );
  }
}
