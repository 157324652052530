import React from "react";
import { connect } from "react-redux";

import { Div } from "components/Base";

import { getters } from "Orders/OrderModal";
import { getIsSubmission } from "Orders/OrderModal/selectors";
import { TABS } from "Orders/OrderModal/constants";
import Tabs from "Orders/OrderModal/View/Tabs";
import OrderSummaryView from "Orders/OrderModal/View/OrderSummaryView";
import SubmissionSummaryView from "Orders/OrderModal/View/SubmissionSummaryView";
import MessagesView from "Orders/OrderModal/View/MessagesView";
import ManageView from "Orders/OrderModal/View/ManageView";

const decorate = connect(state => ({
  selectedTab: getters.selectedTab(state),
  isSubmission: getIsSubmission(state)
}));

const mapSelectedTabToSubmissionView = {
  [TABS.SUMMARY]: SubmissionSummaryView,
  [TABS.MANAGE]: ManageView,
  [TABS.MESSAGES]: MessagesView
};

const mapSelectedTabToOrderView = {
  [TABS.SUMMARY]: OrderSummaryView,
  [TABS.MANAGE]: ManageView,
  [TABS.MESSAGES]: MessagesView
};

const Body = ({ selectedTab, isSubmission, handlers }) => (
  <Div p={4} width={1} height={1} display="column.flex-start" bg="neutral1">
    <Tabs handlers={handlers} />
    <Div
      height={1}
      style={{
        overflowY: "auto",
        transform: "translate(0px, 0px)"
      }}
      id="orderModal"
    >
      {React.createElement(
        isSubmission
          ? mapSelectedTabToSubmissionView[selectedTab] || SubmissionSummaryView
          : mapSelectedTabToOrderView[selectedTab] || OrderSummaryView,
        {
          handlers,
          instanceId: "order-modal"
        }
      )}
    </Div>
  </Div>
);

export default decorate(Body);
