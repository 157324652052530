import * as R from "ramda";
import { createHandlers, createModule } from "redux-mvc";
import { NAMESPACE, preserveFields } from "./constants";
import validator from "ui-kit/Validator";
import moment from "moment";

import * as STANDARD_MODULE_FIELDS from "@lennd/value-types/src/constants/standard-module-field-ids";

const iniState = {
  values: {},
  moduleId: ""
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    resetForm: state => ({
      values: {
        ...state.values,
        ...R.map(R.omit(["value"]), R.omit(preserveFields, state.values))
      }
    }),
    setSaveRow: R.identity,
    init: (_, action) => ({
      values: R.indexBy(R.prop("id"), action.payload)
    }),
    onChange: (state, action) => {
      let startsAtValue =
        state.values[STANDARD_MODULE_FIELDS.SCHEDULES.STARTS_AT].value;
      let startsAtIso = R.path(["value", "iso"])(startsAtValue);

      let endsAtValue =
        state.values[STANDARD_MODULE_FIELDS.SCHEDULES.ENDS_AT].value;
      let endsAtIso = R.path(["value", "iso"])(endsAtValue);

      if (action.fieldId === STANDARD_MODULE_FIELDS.SCHEDULES.STARTS_AT) {
        startsAtIso = R.path(["value", "iso"])(action.payload);
        if (startsAtIso && !endsAtIso) {
          endsAtValue = R.assocPath(
            ["value", "iso"],
            moment(new Date(startsAtIso))
              .add(1, "hours")
              .toISOString(),
            action.payload
          );
        } else if (
          startsAtIso &&
          moment(new Date(startsAtIso)).isAfter(endsAtIso)
        ) {
          endsAtValue = R.assocPath(
            ["value", "iso"],
            moment(new Date(startsAtIso))
              .add(1, "hours")
              .toISOString(),
            action.payload
          );
        }
      }

      if (action.fieldId === STANDARD_MODULE_FIELDS.SCHEDULES.ENDS_AT) {
        endsAtIso = R.path(["value", "iso"])(action.payload);
        if (endsAtIso && !startsAtIso) {
          startsAtValue = R.assocPath(
            ["value", "iso"],
            moment(new Date(endsAtIso))
              .subtract(1, "hours")
              .toISOString(),
            action.payload
          );
        } else if (
          endsAtIso &&
          moment(new Date(startsAtIso)).isAfter(endsAtIso)
        ) {
          startsAtValue = R.assocPath(
            ["value", "iso"],
            moment(new Date(endsAtIso))
              .subtract(1, "hours")
              .toISOString(),
            action.payload
          );
        }
      }

      return {
        values: {
          ...state.values,
          [STANDARD_MODULE_FIELDS.SCHEDULES.STARTS_AT]: {
            ...state.values[STANDARD_MODULE_FIELDS.SCHEDULES.STARTS_AT],
            value: startsAtValue
          },
          [STANDARD_MODULE_FIELDS.SCHEDULES.ENDS_AT]: {
            ...state.values[STANDARD_MODULE_FIELDS.SCHEDULES.ENDS_AT],
            value: endsAtValue
          },
          [action.fieldId]: {
            ...state.values[action.fieldId],
            value: action.payload
          }
        }
      };
    }
  }
});

const module = createModule(handlers);

module.plugModule(validator);

const { actions, getters } = module;

export { actions, getters };

export default module;
