import PropTypes from "prop-types";
import React, { Component } from "react";
import Popover from "@lennd/material-ui/Popover";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";
import { Div } from "../Base/index";

class PopoverBase extends Component {
  constructor(props) {
    super(props);
    this.popoverContainer = React.createRef();
    this.state = {
      popoverOpen: false
    };
  }
  closePopover = () => {
    this.setState({ popoverOpen: false }, () => {
      if (typeof this.props.onClose === "function") {
        this.props.onClose();
      }
    });
  };
  openPopover = event => {
    event.stopPropagation();
    this.setState({
      popoverOpen: true
    });
  };
  render() {
    const {
      uniqueKey,
      children,
      Label, // must be class
      labelProps,
      wrapperProps,
      innerSpanStyle,
      anchorOrigin,
      targetOrigin, // @NOTE: This property needs to be deprecated
      transformOrigin,
      transitionDuration = 0.1,
      PaperProps
    } = this.props;

    return (
      <Div display={"row.center.center"} {...wrapperProps}>
        <span style={innerSpanStyle} ref={this.popoverContainer}>
          <Label
            onClick={this.openPopover}
            onClose={this.closePopover}
            {...labelProps}
          />
        </span>

        <Popover
          key={uniqueKey}
          open={this.state.popoverOpen}
          anchorEl={this.popoverContainer.current}
          anchorOrigin={anchorOrigin}
          transformOrigin={targetOrigin || transformOrigin}
          transitionDuration={transitionDuration}
          onClose={this.closePopover}
          PaperProps={PaperProps}
        >
          {children({
            open: this.state.popoverOpen,
            closePopover: this.closePopover
          })}
        </Popover>
      </Div>
    );
  }
}

PopoverBase.propTypes = {
  Label: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  labelProps: PropTypes.object,
  wrapperProps: PropTypes.object,
  transformOrigin: PropTypes.object,
  anchorOrigin: PropTypes.object
};

PopoverBase.defaultProps = {
  anchorOrigin: { horizontal: "left", vertical: "top" },
  labelProps: {},
  transformOrigin: { horizontal: "left", vertical: "top" },
  wrapperProps: {}
};

const PopoverMenu = ({
  Label,
  anchorOrigin,
  labelProps,
  maxHeight,
  menuItems = [],
  targetOrigin,
  transformOrigin,
  wrapperProps
}) => (
  <PopoverBase
    Label={Label}
    anchorOrigin={anchorOrigin}
    transformOrigin={transformOrigin || targetOrigin}
    labelProps={labelProps}
    wrapperProps={wrapperProps}
  >
    {({ closePopover }) => (
      <Menu maxHeight={maxHeight}>
        {menuItems.map(([label, func, children], i) => {
          if (children) {
            return (
              <MenuItem
                key={i}
                onClick={e => {
                  e.stopPropagation();
                  func();
                  closePopover();
                }}
              >
                {children}
              </MenuItem>
            );
          }

          return (
            <MenuItem
              key={i}
              primaryText={label}
              onClick={e => {
                e.stopPropagation();
                func();
                closePopover();
              }}
            />
          );
        })}
      </Menu>
    )}
  </PopoverBase>
);

PopoverMenu.propTypes = {
  Label: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired,
  labelProps: PropTypes.object,
  maxHeight: PropTypes.number,
  menuItems: PropTypes.arrayOf(PropTypes.array).isRequired
};

PopoverMenu.defaultProps = {
  labelProps: {},
  maxHeight: undefined
};

export { PopoverMenu, PopoverBase as Popover };
