import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import autobind from "autobind-decorator";
import { map } from "lodash";
import AddNewSectionMenuItem from "components/Event/FormsV2/AddNewSectionItem";
import { SECTION_TYPES } from "components/Event/FormsV2/constants";
import Popover from "@lennd/material-ui/Popover";

@CSSModules(css)
class AddNewSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      anchorEl: null
    };
  }

  @autobind
  handleClick(e) {
    e.stopPropagation();
    this.setState({
      showMenu: true,
      anchorEl: e.target
    });
  }

  @autobind
  onRequestClose() {
    this.setState({ showMenu: false });
  }

  render() {
    return (
      <div styleName="container">
        <Popover
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          onClose={this.onRequestClose}
          open={this.state.showMenu}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
        >
          <div styleName="menu">
            <div styleName="title">Elements</div>
            <div styleName="items">
              {map(SECTION_TYPES, (item, key) => (
                <AddNewSectionMenuItem
                  key={key}
                  index={this.props.index}
                  eventId={this.props.eventId}
                  formId={this.props.formId}
                  dispatch={this.props.dispatch}
                  sectionId={this.props.sectionId}
                  name={item.name}
                  icon={item.icon}
                  type={item.type}
                  showModal={this.props.showModal}
                  showAddQuestionModal={this.props.showAddQuestionModal}
                />
              ))}
            </div>
            <div styleName="title">Section Templates</div>
            <div styleName="templates">
              <div>
                Looks like you have not created any section templates yet.
              </div>
            </div>
          </div>
        </Popover>

        <div
          styleName={
            this.props.menuLink ? "iconWrapperMenuLink" : "iconWrapper"
          }
          onClick={this.handleClick}
        >
          <i
            className="material-icons"
            styleName={this.props.menuLink ? "iconMenuLink" : "icon"}
          >
            add
          </i>
          <div
            styleName={this.props.menuLink ? "triangleMenuLink" : "triangle"}
          />
        </div>
      </div>
    );
  }
}

AddNewSection.propTypes = {
  dispatch: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  formId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  menuLink: PropTypes.bool.isRequired,
  sectionId: PropTypes.string,
  showAddQuestionModal: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired
};

export default AddNewSection;
