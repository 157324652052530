import React from "react";
import { Div, MediumOutlineButton } from "components/Base";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions } from "../../model";

import { PAGE_ACTIONS } from "../../constants";

const MAX_ITEMS = 5;

const decorator = connect(
  null,
  { executeAction: actions.executeAction }
);

const Alerts = ({ data: { name, content }, executeAction }) => {
  const shouldShowButton = () => {
    if (R.length(content) > MAX_ITEMS) {
      return (
        <MediumOutlineButton
          onClick={() => executeAction({ type: PAGE_ACTIONS.OPEN_ALERT })}
        >
          All media alerts
        </MediumOutlineButton>
      );
    }
    return null;
  };

  return (
    <Div width={1}>
      <Div
        style={{
          fontWeight: 600,
          fontSize: 20,
          color: "#000000",
          marginBottom: 12
        }}
      >
        {name}
      </Div>
      <Div>
        {R.map(
          alert => (
            <Div
              key={alert.id}
              style={{
                padding: 9,
                borderRadius: alert.type === "breaking" ? 8 : 0,
                backgroundColor:
                  alert.type === "breaking"
                    ? "rgba(255, 241, 191, 0.4)"
                    : "transparent"
              }}
            >
              <Div
                style={{
                  fontSize: 12,
                  color: "#000000",
                  marginBottom: alert.date ? 5 : 0
                }}
              >
                {alert.content}
              </Div>
              <Div style={{ fontSize: 10, color: "#000000", fontWeight: 600 }}>
                {alert.date}
              </Div>
            </Div>
          ),
          R.length(content) > MAX_ITEMS ? content.slice(0, 5) : content
        )}
      </Div>

      {shouldShowButton()}
    </Div>
  );
};

export default decorator(Alerts);
