import React, { Fragment } from "react";
import * as R from "ramda";
import Toggle from "material-ui/Toggle";
import { FieldsTable } from "./Tables";
import {
  CollapsablePanelType1,
  ColorGrid,
  DarkTab,
  Div,
  FontIcon,
  IconGrid,
  SearchIcon,
  RightIcon,
  DeleteIcon,
  BigInsetInput,
  BigOutlineButton,
  BigFilledButton,
  MediumInsetInput,
  BigShadedInput,
  Text1,
  Text3,
  Text4,
  Text6
} from "components/Base";
import { noop } from "utils/General";
import Types from "./Types";
import Approvers from "Event/ItemCatalogApprovers/View";
import Loading from "components/Event/Settings/Module/Loading";
import ReportTemplates from "Reports/ReportTemplates/View";
import DocumentTemplates from "components/Event/Settings/DocumentTemplates/View";
import QuickSetup from "Accounts/QuickSetup/View";
import { addS } from "utils/General";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

// ////////////////////////////////////////////////////////////////////////////////

const Fields = ({
  onFieldsSearch,
  onCreateFieldGroup,
  onCreateField,
  fieldGroups
  // @NOTE: for future impl
  // onCreateNewRelatedForms,
  // onLearnMoreRelatedForms,
  // relatedForms,
  // onLearnMoreRelatedRecords,
  // relatedRecords,
  // onCreateNewFieldRelationships,
  // onLearnMoreFieldRelationships,
  // fieldRelationships,
}) => (
  <Div display="row.flex-start.flex-start">
    <Div pr={4} width={2 / 3} px={8}>
      <Div display="row.flex-start.center" pt={4} pb={4}>
        <BigShadedInput
          continuous
          LeftIcon={SearchIcon}
          flex={1}
          placeholder="Search fields"
          onChange={onFieldsSearch}
        />
        <BigOutlineButton ml={1} onClick={onCreateFieldGroup}>
          Create field group
        </BigOutlineButton>
        <BigFilledButton bg="altB5" ml={1} onClick={onCreateField}>
          Create field
        </BigFilledButton>
      </Div>
      {fieldGroups.map((fieldGroup, i) => {
        const fields = fieldGroup.fields;
        const numFields = fields.length || 0;
        const subText = `${numFields} field${addS(numFields)}`;
        return (
          <CollapsablePanelType1
            key={i}
            headingText={fieldGroup.name}
            subText={subText}
            forceCollapse={fieldGroup.forceCollapse}
            collapsedDefault={fieldGroup.collapsedDefault}
            menuItems={
              fieldGroup.isDefault
                ? null
                : [["Edit", fieldGroup.onEdit], ["Delete", fieldGroup.onDelete]]
            }
          >
            <FieldsTable fields={fields} />
          </CollapsablePanelType1>
        );
      })}
    </Div>
    {/* @NOTE: for future impl */}
    {/* <Div width={1 / 3}>
        <CardGroup1
          heading={'Related Forms'}
          icon={'web'}
          subtext={
            'Forms that reference this module or have permission to create group records.'
          }
          onCreateNew={onCreateNewRelatedForms}
          onLearnMore={onLearnMoreRelatedForms}
        >
          {relatedForms.map(({ name, subtext, onClick }) => (
            <Type1Card heading={name} subtext={subtext} onClick={onClick} />
          ))}
        </CardGroup1>
        <CardGroup1
          heading={'Related Records'}
          icon={'link'}
          subtext={'Modules that reference or lookup Groups will show up here.'}
          onLearnMore={onLearnMoreRelatedRecords}
        >
          {relatedRecords.map(({ name, icon, iconColor, onClick }) => (
            <Type1Card
              heading={name}
              icon={icon}
              iconColor={iconColor}
              onClick={onClick}
            />
          ))}
        </CardGroup1>
        <CardGroup1
          heading={'Field Relationships'}
          icon={'swap_horiz'}
          subtext={
            'Related fields and filter options depending on the selection of the parent field.'
          }
          onCreateNew={onCreateNewFieldRelationships}
          onLearnMore={onLearnMoreFieldRelationships}
        >
          {fieldRelationships.map(({ name, subtext, onClick }) => (
            <Type1Card heading={name} subtext={subtext} onClick={onClick} />
          ))}
        </CardGroup1>
      </Div> */}
  </Div>
);

const settingIcons = [
  "attach_money",
  "airplanemode_active",
  "map",
  "location_on",
  "receipt",
  "people",
  "music_note",
  "stars",
  "local_shipping",
  "mic",
  "business",
  "home",
  "build",
  "business_center",
  "restaurant",
  "extension",
  "alarm",
  "event_seat",
  "explore",
  "timeline",
  "local_dining",
  "power",
  "airport_shuttle",
  "list"
];

// //////////////////////////////////////////////////////////////////////////////

const SettingsHeader = props => <Text3 pt={4} pb={2} {...props} />;

const Settings = ({
  moduleInfo,
  updateModuleInfo,
  onDeleteModule,
  showEmptyValueError
}) => {
  const makeUpdater = key => value => {
    if (typeof value === "string" && (!value || !value.trim().length)) {
      return showEmptyValueError();
    }
    return updateModuleInfo({
      [key]: value
    });
  };
  return (
    <Div
      px={8}
      pt={4}
      mb={4}
      style={{ maxWidth: 500 }}
      display="column.flex-start.stretch"
    >
      <SettingsHeader>Module Name</SettingsHeader>
      <BigInsetInput
        onChange={makeUpdater("name")}
        value={moduleInfo.name}
        placeholder="Module Name"
      />
      <SettingsHeader>Select Icon to represent this module</SettingsHeader>
      <IconGrid
        icons={settingIcons}
        selectedIcon={moduleInfo.icon || "home"}
        onSelectIcon={makeUpdater("icon")}
      />
      <Div display="row.flex-start.stretch" mb={8}>
        <Div>
          <SettingsHeader>Select Color</SettingsHeader>
          <Div p={2} bg="white" bc="neutral3" shadow={1}>
            <ColorGrid
              selectedColor={moduleInfo.color}
              onSelectColor={makeUpdater("color")}
            />
          </Div>
        </Div>

        <Div pl={4} display="column.flex-start.flex-start">
          <SettingsHeader>Preview</SettingsHeader>
          <Div
            p={3}
            bg="white"
            bc="neutral3"
            flex={1}
            display="column.center.center"
            style={{ minWidth: 120 }}
            shadow={1}
          >
            <FontIcon
              bra={2}
              fs={7}
              p={3}
              bg={moduleInfo.color || "neutral5"}
              color="white"
            >
              {moduleInfo.icon}
            </FontIcon>
            <Text1 pt={2} textAlign="center">
              {moduleInfo.name}
            </Text1>
          </Div>
        </Div>
      </Div>

      <CollapsablePanelType1 collapsedDefault headingText="Advanced Settings">
        <Div p={4} bra={1} bg="gray1" mt={2}>
          <Text4 bold pt={4}>
            Records
          </Text4>
          <Div display="row.flex-start.center">
            <Div flex={1}>
              <SettingsHeader>Record Name - Singular</SettingsHeader>
              <MediumInsetInput
                width={1}
                onChange={makeUpdater("recordName")}
                value={moduleInfo.record_name}
                placeholder="Record Name - Singular"
              />
            </Div>
            <Div ml={6} flex={1}>
              <SettingsHeader>Record Name - Plural</SettingsHeader>
              <MediumInsetInput
                width={1}
                onChange={makeUpdater("recordNamePlural")}
                value={moduleInfo.record_name_plural}
                placeholder="Record Name - Plural"
              />
            </Div>
          </Div>
          <SettingsHeader>Record Prefix</SettingsHeader>
          <MediumInsetInput
            width={80}
            mb={4}
            onChange={makeUpdater("recordPrefix")}
            value={moduleInfo.record_prefix}
            placeholder="Prefix"
          />
          <Text4 bold pt={4}>
            Module
          </Text4>
          <SettingsHeader>Internal Name</SettingsHeader>
          <MediumInsetInput
            onChange={makeUpdater("internalName")}
            value={moduleInfo.internal_name}
            placeholder="Internal Name"
          />
          <Toggle
            label="Show in navigation"
            labelStyle={{ fontWeight: "normal" }}
            onToggle={(event, newState) =>
              makeUpdater("showInNavigation")(newState)
            }
            style={{ marginBottom: 8 }}
            toggled={moduleInfo.show_in_navigation}
          />
          <Toggle
            label="Has approval"
            labelStyle={{ fontWeight: "normal" }}
            onToggle={(event, newState) => makeUpdater("hasApproval")(newState)}
            style={{ marginBottom: 8 }}
            toggled={moduleInfo.has_approval}
          />
          <Toggle
            label="Enable record types"
            labelStyle={{ fontWeight: "normal" }}
            onToggle={(event, newState) =>
              makeUpdater("enableRecordTypes")(newState)
            }
            style={{ marginBottom: 8 }}
            toggled={moduleInfo.enable_record_types}
          />
          <Toggle
            disabled={!moduleInfo.enable_record_types}
            inputStyle={{
              cursor: moduleInfo.enable_record_types ? "default" : "not-allowed"
            }}
            label="Record types use icons and colors"
            labelStyle={{ fontWeight: "normal" }}
            onToggle={(event, newState) =>
              makeUpdater("enableRecordTypeIcons")(newState)
            }
            style={{ marginBottom: 8 }}
            toggled={moduleInfo.enable_record_type_icons}
          />
          <BigOutlineButton
            LeftIcon={DeleteIcon}
            my={1}
            onClick={onDeleteModule}
          >
            Delete Module
          </BigOutlineButton>
        </Div>
      </CollapsablePanelType1>
    </Div>
  );
};

// //////////////////////////////////////////////////////////////////////////////

const View = ({
  isOrg,
  contentOnly,
  loading,
  isDefaultModule,
  goToName,
  goToModule,
  activeTabValue,
  setTabValue,
  onFieldsSearch,
  onCreateFieldGroup,
  onCreateField,
  fieldGroups,
  moduleInfo,
  updateModuleInfo,
  onDeleteModule,
  showEmptyValueError,
  moduleId
}) => {
  const fields = (
    <Fields
      {...{
        onFieldsSearch,
        onCreateFieldGroup,
        onCreateField,
        fieldGroups
      }}
    />
  );

  const types = <Types />;

  const approvals = <Approvers />;

  const reportTemplates = <ReportTemplates moduleId={moduleId} />;

  const documentTemplates = <DocumentTemplates moduleId={moduleId} />;

  const quickSetup = <QuickSetup />;

  const settings = (
    <Settings
      {...{
        moduleInfo,
        updateModuleInfo,
        onDeleteModule,
        showEmptyValueError
      }}
    />
  );

  let tabs = [];

  /*
  if (
    STANDARD_MODULE_IDS.contacts.id === moduleId ||
    STANDARD_MODULE_IDS.accounts.id === moduleId
  ) {
    tabs.push([
      ["eventSettingsModuleQuickSetup"],
      "Quick Setup",
      "quick-setup"
    ]);
  }
  */

  if (isOrg) {
    tabs.push([["organizationSettingsModule"], "All Fields", ""]);
  } else {
    tabs.push([["eventSettingsModule"], "All Fields", ""]);
  }

  if (!isOrg) {
    tabs.push([
      [
        "eventSettingsModuleTypes",
        "eventSettingsModuleTypesTypeId",
        "eventSettingsModuleTypesPortal",
        "eventSettingsModuleTypesRecordLayout",
        "eventSettingsModuleTypesAddRecordLayout",
        "eventSettingsModuleTypesCardLayout",
        "eventSettingsModuleTypesReportLayout"
      ],
      "Types",
      "types"
    ]);
  }

  if (!isDefaultModule) {
    tabs.push(
      [
        ["eventSettingsModuleApprovalWorkflows"],
        "Approval Workflows",
        "approval-workflows"
      ],
      [["eventSettingsModuleSettings"], "Settings", "settings"]
    );
  }

  tabs.push([
    ["eventSettingsModuleReportTemplates"],
    "Report Templates",
    "report-templates"
  ]);

  tabs.push([
    ["eventSettingsModuleDocumentTemplates"],
    "Document Templates",
    "document-templates"
  ]);

  return (
    <Div style={{ minHeight: "100%" }}>
      <Div bb={1} bc="gray3" bg="white" display="row.flex-start.flex-end">
        {contentOnly ? null : (
          <Fragment>
            <Div flex={1}>
              {loading ? (
                <Div width={200} height={10} bg="gray1" ml={8} mt={8} mb={3} />
              ) : (
                <Text6 pl={8} pt={6}>
                  {moduleInfo.name}
                </Text6>
              )}

              <Div pt={3} pl={8}>
                {R.map(([routeNames, tabName, sufPath]) => {
                  const active = R.any(R.equals(activeTabValue))(routeNames);
                  return (
                    <DarkTab
                      key={tabName}
                      px={2}
                      fs={3}
                      active={active}
                      onClick={!active ? () => setTabValue(sufPath) : noop}
                    >
                      {tabName}
                    </DarkTab>
                  );
                })(tabs)}
              </Div>
            </Div>

            <Div
              display="row.flex-start.center"
              style={{ alignSelf: "center" }}
              pr={4}
            >
              <BigOutlineButton RightIcon={RightIcon} onClick={goToModule}>
                {`Go to ${goToName}`}
              </BigOutlineButton>
            </Div>
          </Fragment>
        )}
      </Div>

      <Div style={{ minHeight: "100%" }} pb={14}>
        {loading ? (
          <Loading />
        ) : (
          R.prop(activeTabValue, {
            organizationSettingsModule: fields,

            //
            eventSettingsModule: fields,
            eventSettingsModuleTypes: types,
            eventSettingsModuleTypesTypeId: types,
            eventSettingsModuleTypesPortal: types,
            eventSettingsModuleTypesGuestList: types,

            eventSettingsModuleTypesRecordLayout: types,
            eventSettingsModuleTypesAddRecordLayout: types,
            eventSettingsModuleTypesCardLayout: types,
            eventSettingsModuleTypesReportLayout: types,

            eventSettingsModuleApprovalWorkflows: approvals,
            eventSettingsModuleSettings: settings,

            eventSettingsModuleReportTemplates: reportTemplates,
            eventSettingsModuleDocumentTemplates: documentTemplates,
            eventSettingsModuleQuickSetup: quickSetup
          })
        )}
      </Div>
    </Div>
  );
};

export default View;
