import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Details from "./Details";

import { getUserAccessLevels } from "redux/modules/user/access-levels/actions";
import { getEvent } from "redux/modules/event/actions";
import { getEvents } from "redux/modules/events/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";

import { user } from "redux/modules/user/selectors";
import { eventDetails } from "redux/modules/event/selectors";

function mapStateToProps(state) {
  return {
    user: user(state),
    details: eventDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEvent,
      getEvents,
      getUserAccessLevels,
      showSnackbar
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Details)
);
