import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../index";
import MergeTagTableModal from "Modules/MergeTagTableModal/View";

const decorate = connect(
  state => ({
    showMergetagModal: getters.showMergetagModal(state),
    selectedRelatedModule: getters.selectedRelatedModule(state)
  }),
  {
    hideModal: () => actions.setShowMergetagModal(false)
  }
);

const MergetagModal = ({
  showMergetagModal,
  hideModal,
  selectedRelatedModule
}) =>
  showMergetagModal ? (
    <MergeTagTableModal
      hideModal={hideModal}
      fields={R.propOr([], "fields", selectedRelatedModule)}
      name={R.propOr("", "name", selectedRelatedModule)}
      moduleId={R.propOr("", "id", selectedRelatedModule)}
      module={selectedRelatedModule}
    />
  ) : null;

export default decorate(MergetagModal);
