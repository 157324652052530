import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Reports from "./Reports";
import flattenFields from "components/Global/CRM/TableViews/utils/flatten-fields";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import exportFactory from "components/Global/CRM/TableViews/HelperComponents/export-factory";
import searchFactory from "components/Global/CRM/TableViews/HelperComponents/SearchFactory";
import sortRows from "components/Event/Module/utils/sort-rows";
import { isViewingAsOrg } from "utils/is-viewing-as-context";

import { getViews, updateView } from "redux/modules/modules/views/actions";
import { addAccount } from "redux/modules/accounts/list/actions";
import { deselectAllRows } from "redux/modules/modules/records/settings/actions";
import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";
import { getModule } from "redux/modules/modules/module/actions";
import { getReport } from "redux/modules/accounts/reports/actions";

import { references } from "redux/modules/entityReferences/selectors";
import {
  reportFields,
  reportRows,
  isFetching,
  preferences
} from "redux/modules/accounts/reports/selectors";
import {
  selectedRows,
  searchTerm,
  tableLayoutOptions
} from "redux/modules/modules/records/settings/selectors";
import { user } from "redux/modules/user/selectors";
import { eventDetails, accountTypes } from "redux/modules/event/selectors";
import { views, activeView } from "redux/modules/modules/views/selectors";

import { recordTypes } from "redux/modules/modules/recordTypes/selectors";

import { modules } from "redux/modules/inventory/modules/selectors";
import { getModulesWithCatalogItems } from "redux/modules/inventory/modules/actions";

const getContext = ({ params = {}, context = {} }) => ({
  ...params,
  ...context,
  moduleId: STANDARD_MODULE_IDS.accounts.id
});

function mapStateToProps(state, props) {
  const context = getContext(props);
  const activeViewToPass = activeView(state, context.moduleId, context.viewId);
  const fieldsToPass = reportFields(state, context.viewId);
  const eventDetailsToPass = eventDetails(state);
  const referencesToPass = references(state);
  const sortedRecords = sortRows(
    reportRows(state, context.viewId),
    flattenFields(fieldsToPass),
    activeViewToPass,
    {
      eventDetails: eventDetailsToPass,
      references: referencesToPass
    }
  );

  // build contextual props based on org vs event
  let contextProps = {};
  if (isViewingAsOrg(context)) {
    contextProps = {
      baseUrl: `/organization/${context.orgId}/module/${context.moduleId}`,
      editFieldsUrl: `/organization/${context.orgId}/settings/module/${context.moduleId}`,
      moduleUrl: `/organization/${context.orgId}/module/${context.moduleId}`,
      viewUrl: `/organization/${context.orgId}/module/${context.moduleId}${
        context.view ? `/${context.view}` : ""
      }`
    };
  } else {
    contextProps = {
      baseUrl: `/event/${context.eventId}/module/${context.moduleId}/dashboard`,
      editFieldsUrl: `/event/${context.eventId}/settings/module/${context.moduleId}`,
      moduleUrl: `/event/${context.eventId}/module/${context.moduleId}/dashboard`,
      viewUrl: `/event/${context.eventId}/module/${context.moduleId}/dashboard`
    };
  }

  return {
    context,
    ...contextProps,
    eventDetails: eventDetailsToPass,
    views: views(state, context.moduleId),
    activeView: activeViewToPass,
    fields: fieldsToPass,
    isFetchingRecords: isFetching(state, context.viewId),
    module: STANDARD_MODULE_IDS.accounts,
    forms: [],
    moduleSettings: tableLayoutOptions(state, context.moduleId),
    preferences: preferences(state, context.viewId),
    records: sortedRecords,
    recordTypes: accountTypes(state),
    references: referencesToPass,
    searchTerm: searchTerm(state, context.moduleId),
    selectedRows: selectedRows(state, context.moduleId),
    user: user(state),
    groupTypes: recordTypes(state, STANDARD_MODULE_IDS.accounts.id) || [],
    catalogItemsModules: modules(state) || []
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getViews,
      updateView,
      getRecordTypes,
      addAccount,
      deselectAllRows,
      getReport,
      getModule,
      getModulesWithCatalogItems
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(searchFactory(exportFactory(Reports)));
