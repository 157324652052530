import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./External";
import { withRouter } from "react-router";
import {
  contacts,
  accounts
} from "@lennd/value-types/src/constants/standard-modules";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

import { showModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import {
  getPermissionSets,
  updateIntakeForm
} from "redux/modules/accounts/permissionSets/actions";
import {
  addPermissionSet,
  deletePermissionSet,
  updatePermissionSet
} from "redux/modules/accounts/permissionSets/permissionSet/actions";
import {
  removeItemBlock,
  addItemBlock,
  updateItemBlock
} from "redux/modules/accounts/permissionSets/itemBlocks/actions";
import { updateRecordType as updateRecordTypeForPermissionSet } from "redux/modules/accounts/permissionSets/recordTypes/actions";
import {
  getRecordTypes,
  updateRecordType
} from "redux/modules/modules/recordTypes/actions";
import { getItemBlocks } from "redux/modules/items/item-blocks/actions";
import { getForms } from "redux/modules/formsV2/forms/actions";
import { getFields } from "redux/modules/modules/fields/actions";

import { itemBlocks } from "redux/modules/items/item-blocks/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import { recordTypes } from "redux/modules/modules/recordTypes/selectors";
import { permissionSets } from "redux/modules/accounts/permissionSets/selectors";
import { forms } from "redux/modules/formsV2/forms/selectors";
import { fields } from "redux/modules/modules/fields/selectors";

function mapStateToProps(state, props) {
  const permissionSetsToPass = permissionSets(state);
  const selectedPermissionSet = props.selectedPermissionSetId
    ? permissionSetsToPass.find(s => s.id === props.selectedPermissionSetId)
    : null;
  const formsToPass = forms(state);
  const applicationForms = formsToPass.filter(
    f =>
      f.scope === "account" && f.scope_record_type_id === props.selectedTypeId
  );

  const contactReadOnlyFields = resolveReadOnlyFields({
    moduleId: STANDARD_MODULES.contacts.id
  });

  const contactFieldsToPass = fields(
    state,
    STANDARD_MODULES.contacts.id
  ).filter(f => !contactReadOnlyFields.includes(f.id));

  return {
    eventDetails: eventDetails(state),
    eventId: eventDetails(state).id,
    itemBlocks: itemBlocks(state),
    peopleTypes: recordTypes(state, contacts.id),
    permissionSets: permissionSetsToPass,
    activePermissionSet: selectedPermissionSet,
    contactFields: contactFieldsToPass,
    applicationForms
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSnackbar,
      getFields,
      getForms,
      addItemBlock,
      addPermissionSet,
      deletePermissionSet,
      getItemBlocks,
      getPermissionSets,
      updateIntakeForm,
      getAccountRecordTypes: eventId =>
        getRecordTypes({
          moduleId: accounts.id,
          options: { eventId }
        }),
      getContactRecordTypes: eventId =>
        getRecordTypes({
          moduleId: contacts.id,
          options: { eventId }
        }),
      removeItemBlock,
      showModal,
      updatePermissionSet,
      updateRecordType,
      updateRecordTypeForPermissionSet,
      updateItemBlock
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
