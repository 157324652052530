import * as R from "ramda";
import React, { useMemo } from "react";

import { getValue } from "../../utils";

import Avatar from "components/Atoms/Avatar";

const User = props => {
  const records = getValue(props, "records", []);
  const sortedRecords = useMemo(
    () =>
      R.sortBy(record => {
        const sortId = record.type === "member" ? 1 : 2;
        return `${sortId}_${record.id}`;
      }, records),
    [records]
  );

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center"
      }}
    >
      {sortedRecords.map((record, index) => {
        const splitName = record.name ? record.name.split(" ") : {};
        return (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginRight: 5,
                width: 25,
                height: 25
              }}
            >
              <Avatar
                userId={record.id}
                imageUrl={record.photoUrl}
                firstName={splitName[0] || ""}
                lastName={splitName[1] || ""}
                size={25}
                alt
                border
              />
            </div>
            {R.length(records) === 1 && (
              <div
                style={{
                  fontSize: 14,
                  display: "flex",
                  marginLeft: 3
                }}
              >
                {record.name}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default User;
