import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { getters } from "Event/Home";
import { Div } from "components/Base";
import ReviewedRow from "Event/MyApprovals/View/Common/ReviewedRow";
import CommonReviewedCard from "Event/MyApprovals/View/Common/ReviewedCard";

const decorate = connect(state => ({
  data: getters.setupChecklists(state)
}));

const ReviewedCard = ({ data }) => (
  <CommonReviewedCard title="Setup Complete">
    <Div mt={2}>
      {R.map(
        reviewed => (
          <ReviewedRow
            barText="Setup Complete!"
            name={reviewed.name}
            url={reviewed.url}
            total={reviewed.count_of_all_steps}
            id={reviewed.id}
            btnText="Adjust"
          />
        ),
        R.propOr([], "reviewed", data)
      )}
    </Div>
  </CommonReviewedCard>
);

export default decorate(ReviewedCard);
