import React, { Fragment } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import css from "../styles.scss";
import CSSModules from "react-css-modules";

import {
  WITH_POPOVER_BUTTON,
  GROUP_BY_DATE_VALUE
} from "Schedules/Schedule/constants";

import { actions } from "Schedules/Schedule";

import {
  getGroupedByField,
  selectedGroupByFieldName,
  getGroupByButtons
} from "Schedules/Schedule/selectors";

import { Div, MediumFilledButton, CancelIcon } from "components/Base";
import GroupByField from "./GroupByField";

const decorate = connect(
  (state, props) => ({
    groupedByField: getGroupedByField(state, props),
    selectedFieldLabel: selectedGroupByFieldName(state, props),
    groupByButtons: getGroupByButtons(state, props)
  }),
  {
    onGroupByField: actions.setGroupedByField
  }
);

const Button = ({ children, onClick, ...styleProps }) => {
  return (
    <Div
      height={1}
      flex={1}
      fs={2}
      fw={3}
      bra={1}
      display="row.flex-start.center"
      px={3}
      py={3}
      onClick={onClick}
      {...styleProps}
    >
      {children}
    </Div>
  );
};

const GroupBy = ({
  groupedByField,
  selectedFieldLabel,
  onGroupByField,
  groupByButtons,
  ...styleProps
}) => {
  return (
    <Div display="row.flex-start.center" {...styleProps}>
      <Div
        display="row.flex-start.center"
        bra={1}
        py={1}
        px={1}
        className={css.buttonsWrapper}
      >
        {R.map(
          button => (
            <Fragment key={R.prop("id", button)}>
              {R.prop("type", button) === WITH_POPOVER_BUTTON ? (
                <GroupByField
                  isGroupByFieldSelected={
                    !R.isNil(groupedByField) &&
                    groupedByField !== GROUP_BY_DATE_VALUE
                  }
                  selectFieldToGroupBy={onGroupByField}
                  label={R.propOr("", "label", button)}
                  Button={Button}
                />
              ) : (
                <Button
                  onClick={
                    R.propOr(null, "value", button) === groupedByField
                      ? undefined
                      : () => onGroupByField(R.propOr(null, "value", button))
                  }
                  mr={1}
                  height={32}
                  color="white"
                  className={
                    R.propOr(null, "value", button) === groupedByField
                      ? css.button7
                      : css.button6
                  }
                >
                  {R.propOr("", "label", button)}
                </Button>
              )}
            </Fragment>
          ),
          groupByButtons
        )}
      </Div>
      {!R.isNil(groupedByField) && groupedByField !== GROUP_BY_DATE_VALUE && (
        <MediumFilledButton
          LeftIcon={CancelIcon}
          leftIconColor="primary2"
          mx={1}
          onClick={() => onGroupByField(null)}
          className={css.button2}
        >
          {selectedFieldLabel}
        </MediumFilledButton>
      )}
    </Div>
  );
};

export default CSSModules(decorate(GroupBy), css);
