import { createSelector } from "reselect";
import { getters } from "Toolbar/QuickActionsNavigation";
import { getters as EventHomeGetters } from "Event/Home";
import { getters as MyApprovalsGetters } from "Event/MyApprovals/model";

import * as R from "ramda";

import { eventDetails } from "redux/modules/event/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { userPermissionProfile } from "redux/modules/permissions/user-permission-profile/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";
import getVisibleModules from "components/Event/utils/get-visible-modules";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";
import { noop } from "utils/General";

import {
  CREDENTIAL_TYPE_ID,
  MEAL_TYPE_ID,
  ASSETS_TYPE_ID,
  BOOTH_TYPE_ID
} from "utils/item-types";

const getRecordName = moduleId =>
  R.propOr("Record", moduleId)({
    [STANDARD_MODULE_IDS.accounts.id]: "Group",
    [STANDARD_MODULE_IDS.contacts.id]: "Person"
  });

const getPluralRecordName = moduleId =>
  R.propOr("Records", moduleId)({
    [STANDARD_MODULE_IDS.accounts.id]: "Groups",
    [STANDARD_MODULE_IDS.contacts.id]: "People"
  });

const getModuleIdToUseForPendingCounts = moduleId => {
  return R.propOr(moduleId, moduleId)({
    [STANDARD_MODULE_IDS.credentials.id]: CREDENTIAL_TYPE_ID,
    [STANDARD_MODULE_IDS.catering.id]: MEAL_TYPE_ID,
    [STANDARD_MODULE_IDS.inventory.id]: ASSETS_TYPE_ID,
    [STANDARD_MODULE_IDS.booths.id]: BOOTH_TYPE_ID
  });
};

const getGoTo = ({ moduleId, handlers, flags }) => {
  const isItemTypeModule = [
    STANDARD_MODULE_IDS.credentials.id,
    flags.canViewInventory ? STANDARD_MODULE_IDS.inventory.id : undefined,
    STANDARD_MODULE_IDS.catering.id,
    STANDARD_MODULE_IDS.booths.id
  ].includes(moduleId);
  const isSettings = STANDARD_MODULE_IDS.settings.id === moduleId;
  const isForms = STANDARD_MODULE_IDS.formsv3.id === moduleId;
  const isSessions = STANDARD_MODULE_IDS.sessions.id === moduleId;
  const isVirtual = STANDARD_MODULE_IDS.virtual.id === moduleId;
  const isPortal = STANDARD_MODULE_IDS.portal.id === moduleId;
  const isReports = STANDARD_MODULE_IDS.reports.id === moduleId;
  const isDocRequests = STANDARD_MODULE_IDS.documentRequests.id === moduleId;
  const isOrders = STANDARD_MODULE_IDS.orders.id === moduleId;
  const isSchedules = STANDARD_MODULE_IDS.schedules.id === moduleId;

  if (
    moduleId === STANDARD_MODULE_IDS.inventory.id &&
    !flags.canViewInventory
  ) {
    return handlers.viewOldInventoryDashboard();
  }

  if (isItemTypeModule) {
    return handlers.viewItemTypeRequests({ moduleId });
  }

  if (isSettings) {
    return handlers.viewEventSettings();
  }

  if (isForms) {
    return handlers.viewForms();
  }

  if (isSessions) {
    return handlers.viewSessions();
  }

  if (isVirtual) {
    return handlers.viewVirtual();
  }

  if (isPortal) {
    return handlers.viewPortal();
  }

  if (isReports) {
    return handlers.viewReports();
  }

  if (isDocRequests) {
    return handlers.viewModuleRecords({ moduleId });
  }

  if (isOrders) {
    return handlers.viewOrders();
  }

  if (isSchedules) {
    return handlers.viewMasterSchedule();
  }

  return handlers.viewModuleRecords({ moduleId });
};

const getActions = ({
  moduleId,
  canDo,
  handlers,
  flags,
  countOfPendingApprovals,
  modules
}) => {
  const actions = [];
  const isItemTypeModule = [
    STANDARD_MODULE_IDS.credentials.id,
    flags.canViewInventory ? STANDARD_MODULE_IDS.inventory.id : undefined,
    STANDARD_MODULE_IDS.catering.id,
    STANDARD_MODULE_IDS.booths.id
  ].includes(moduleId);
  const isSettings = STANDARD_MODULE_IDS.settings.id === moduleId;
  const isForms = STANDARD_MODULE_IDS.formsv3.id === moduleId;
  const isSessions = STANDARD_MODULE_IDS.sessions.id === moduleId;
  const isVirtual = STANDARD_MODULE_IDS.virtual.id === moduleId;
  const isPortal = STANDARD_MODULE_IDS.portal.id === moduleId;
  const isReports = STANDARD_MODULE_IDS.reports.id === moduleId;
  const isDocRequests = STANDARD_MODULE_IDS.documentRequests.id === moduleId;
  const isOrders = STANDARD_MODULE_IDS.orders.id === moduleId;
  const isFiles = STANDARD_MODULE_IDS.files.id === moduleId;
  const isSchedules = STANDARD_MODULE_IDS.schedules.id === moduleId;

  if (isItemTypeModule) {
    if (countOfPendingApprovals) {
      actions.push({
        id: "my-approvals",
        name: "My Approvals",
        count_of_alerts: countOfPendingApprovals,
        type: "link",
        is_my_approvals: true,
        onClick: () => {
          handlers.viewMyApprovals(moduleId);
        }
      });
    }

    if (
      [
        STANDARD_MODULE_IDS.credentials.id,
        STANDARD_MODULE_IDS.catering.id
      ].includes(moduleId)
    ) {
      actions.push({
        id: "view-dashboard",
        name: "Dashboard",
        count_of_alerts: 0,
        type: "link",
        onClick: () => {
          handlers.viewItemTypeDashboard({ moduleId });
        }
      });
    }
    actions.push({
      id: "view-requests",
      name: "All Requests",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.viewItemTypeRequests({ moduleId });
      }
    });

    if (
      moduleId === STANDARD_MODULE_IDS.credentials.id &&
      canDo(`${moduleId}_checkin`)
    ) {
      actions.push({
        id: "passes-checkin",
        name: "Issue & Check In",
        count_of_alerts: 0,
        type: "link",
        onClick: () => {
          handlers.viewPassesCheckin();
        }
      });
    }

    if (
      moduleId === STANDARD_MODULE_IDS.catering.id &&
      canDo(`${moduleId}_checkin`)
    ) {
      actions.push({
        id: "catering-checkin",
        name: "Check In",
        count_of_alerts: 0,
        type: "link",
        newWindow: true,
        onClick: () => {
          handlers.viewCateringCheckin();
        }
      });
    }

    actions.push({
      id: "create-order",
      name: "Create Order",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.showCreateOrderModal({ moduleId });
      }
    });
    if (canDo(`${moduleId}_add_line_items`)) {
      actions.push({
        id: "import-orders",
        name: "Import Orders",
        count_of_alerts: 0,
        type: "link",
        onClick: () => {
          handlers.showImportOrdersModal();
        }
      });
    }
    if (
      [
        STANDARD_MODULE_IDS.credentials.id,
        STANDARD_MODULE_IDS.catering.id
      ].includes(moduleId)
    ) {
      actions.push({
        id: "send-emails",
        name: "Send Emails",
        count_of_alerts: 0,
        type: "link",
        onClick: () => {
          handlers.viewItemTypeSendEmails({ moduleId });
        }
      });
    }
    if (
      moduleId !== STANDARD_MODULE_IDS.booths.id &&
      canDo(`${moduleId}_manage`)
    ) {
      actions.push({
        id: "item-types",
        name: R.prop(moduleId)({
          [STANDARD_MODULE_IDS.credentials.id]: "Manage Pass Types",
          [STANDARD_MODULE_IDS.catering.id]: "Manage Meal Types",
          [STANDARD_MODULE_IDS.inventory.id]: "Manage Asset Types"
        }),
        count_of_alerts: 0,
        type: "link",
        onClick: () => {
          handlers.viewItemTypes({ moduleId });
        }
      });
    }
    if (moduleId === STANDARD_MODULE_IDS.booths.id) {
      actions.push({
        id: "item-types",
        name: "Setup",
        count_of_alerts: 0,
        type: "link",
        onClick: () => {
          handlers.viewBoothSetup();
        }
      });
      actions.push({
        id: "view-sales-portal",
        name: "View Sales Portal",
        count_of_alerts: 0,
        type: "link",
        newWindow: true,
        onClick: () => {
          handlers.viewBoothSalesPortal();
        }
      });
    }

    if (
      [
        STANDARD_MODULE_IDS.credentials.id,
        STANDARD_MODULE_IDS.catering.id
      ].includes(moduleId) &&
      canDo(`${moduleId}_manage`)
    ) {
      actions.push({
        id: "manage-approvers",
        name: "Manage Approvers",
        count_of_alerts: 0,
        type: "link",
        onClick: () => {
          handlers.viewItemTypeApprovers({ moduleId });
        }
      });
    }
  }

  if (
    !isItemTypeModule &&
    !isSettings &&
    !isPortal &&
    !isForms &&
    !isReports &&
    !isDocRequests &&
    !isOrders &&
    !isFiles &&
    !isSchedules &&
    !isSessions &&
    !isVirtual &&
    moduleId !== STANDARD_MODULE_IDS.inventory.id
  ) {
    if (countOfPendingApprovals) {
      actions.push({
        id: "my-approvals",
        name: "My Approvals",
        count_of_alerts: countOfPendingApprovals,
        type: "link",
        is_my_approvals: true,
        onClick: () => {
          handlers.viewModuleRecords({ moduleId });
        }
      });
    }

    actions.push({
      id: "view_records",
      name: `View ${getPluralRecordName(moduleId)}`,
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.viewModuleRecords({ moduleId });
      }
    });
    actions.push({
      id: "add_record",
      name: `Add ${getRecordName(moduleId)}`,
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.showAddRecordModal({ moduleId });
      }
    });
    if (canDo(`${moduleId}_manage`)) {
      actions.push({
        id: "manage-fields",
        name: "Manage Fields",
        count_of_alerts: 0,
        type: "link",
        onClick: () => {
          handlers.viewManageFields({ moduleId });
        }
      });

      if (
        ![
          STANDARD_MODULE_IDS.accounts.id,
          STANDARD_MODULE_IDS.contacts.id
        ].includes(moduleId)
      ) {
        actions.push({
          id: "manage-approvers",
          name: "Manage Approvers",
          count_of_alerts: 0,
          type: "link",
          onClick: () => {
            handlers.viewManageApprovers({ moduleId });
          }
        });
      }
    }
  }

  if (
    moduleId === STANDARD_MODULE_IDS.inventory.id &&
    !flags.canViewInventory
  ) {
    actions.push({
      id: "overview",
      name: "Overview",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.viewOldInventoryDashboard();
      }
    });
    actions.push({
      id: "inventory-needs",
      name: "View Requests",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.viewOldInventoryNeeds();
      }
    });
  }

  if (isSettings) {
    actions.push({
      id: "event-settings",
      name: "Event Settings",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.viewEventSettings();
      }
    });

    if (canDo(`${STANDARD_MODULE_IDS.settings.id}_read`)) {
      actions.push({
        id: "manage-users",
        name: "Manage Users",
        count_of_alerts: 0,
        type: "link",
        onClick: () => {
          handlers.viewUsers();
        }
      });
    }

    if (canDo(`${STANDARD_MODULE_IDS.settings.id}_manage_permissions`)) {
      actions.push({
        id: "manage-permissions",
        name: "Manage Permissions",
        count_of_alerts: 0,
        type: "link",
        onClick: () => {
          handlers.viewPermissions();
        }
      });
    }

    if (canDo(`${STANDARD_MODULE_IDS.settings.id}_manage_email_templates`)) {
      actions.push({
        id: "email-templates",
        name: "Email Templates",
        count_of_alerts: 0,
        type: "link",
        onClick: () => {
          handlers.viewEmailTemplates();
        }
      });
    }

    if (canDo(`${STANDARD_MODULE_IDS.settings.id}_update`)) {
      actions.push({
        id: "payment-stetings",
        name: "Payment Settings",
        count_of_alerts: 0,
        type: "link",
        onClick: () => {
          handlers.viewPaymentSettings();
        }
      });
    }
  }

  if (isForms) {
    actions.push({
      id: "view-forms",
      name: "View Forms",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.viewForms();
      }
    });

    actions.push({
      id: "create-form",
      name: "Create New Form",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.showAddFormModal({});
      }
    });

    actions.push({
      id: "submit-form",
      name: "Submit Form",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.showSubmitFormModal({});
      }
    });
  }

  if (isSessions) {
    actions.push({
      id: "view-sessions",
      name: "View Sessions",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.viewSessions();
      }
    });
  }

  if (isVirtual) {
    actions.push({
      id: "view-virtual-event",
      name: "Dashboard",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.viewVirtual();
      }
    });

    actions.push({
      id: "view-virtual-preview",
      name: "Preview",
      count_of_alerts: 0,
      type: "link",
      newWindow: true,
      onClick: () => {
        handlers.viewVirtualPreview();
      }
    });

    actions.push({
      id: "view-virtual-event-details",
      name: "Event Details",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.viewVirtualDetails();
      }
    });

    actions.push({
      id: "view-virtual-event-tickets",
      name: "Tickets",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.viewVirtualTickets();
      }
    });

    actions.push({
      id: "view-virtual-event-registration",
      name: "Registration",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.viewVirtualRegistration();
      }
    });
  }

  if (isPortal) {
    actions.push({
      id: "view-portal",
      name: "View My Portal",
      count_of_alerts: 0,
      type: "link",
      newWindow: true,
      onClick: () => {
        handlers.viewPortal();
      }
    });

    actions.push({
      id: "manage-group-portals",
      name: "Manage Portals",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.viewPortals();
      }
    });
  }

  if (isReports) {
    actions.push({
      id: "view-reports",
      name: "View Reports",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.viewReports();
      }
    });
  }

  if (isDocRequests) {
    actions.push({
      id: "view-doc-requests",
      name: "View Requests",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.viewModuleRecords({ moduleId });
      }
    });
    actions.push({
      id: "create-doc-requests",
      name: "Create Request",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.showCreateDocRequestModal();
      }
    });
  }

  if (isOrders) {
    actions.push({
      id: "view-orders",
      name: "View Orders",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.viewOrders();
      }
    });
    actions.push({
      id: "view-invoices",
      name: "View Invoices",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.viewInvoices();
      }
    });
    actions.push({
      id: "view-transactions",
      name: "View Transactions",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.viewTransactions();
      }
    });
    actions.push({
      id: "manage-fields",
      name: "Manage Order Fields",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.viewManageFields({ moduleId });
      }
    });
  }

  if (isFiles) {
    actions.push({
      id: "view-files",
      name: "View Files",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.viewModuleRecords({ moduleId });
      }
    });
  }

  if (isSchedules) {
    const schedulesList = R.filter(
      ({ type_id }) => type_id === STANDARD_MODULE_IDS.schedules.id,
      modules
    );

    actions.push({
      id: "view_schedule_master",
      name: "View Master Schedule",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.viewMasterSchedule();
      }
    });
    actions.push({
      id: "create_schedule_modal",
      name: "Add Schedule",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.showCreateScheduleModal();
      }
    });
    actions.push({
      id: "manage-schedule-fields",
      name: "Manage Fields",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.viewScheduleFields();
      }
    });
    actions.push({
      id: "view_my_tasks",
      name: "My Tasks",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.viewMyTasks();
      }
    });
    actions.push({
      id: "schedules_title",
      name: "Schedules List",
      count_of_alerts: 0,
      type: "link",
      isTitle: true,
      onClick: noop
    });
    R.forEach(({ id, name }) => {
      actions.push({
        id,
        name,
        count_of_alerts: 0,
        type: "link",
        onClick: () => {
          handlers.viewSchedule({ id });
        }
      });
    }, schedulesList);
  }

  return actions;
};

const getTypes = ({
  moduleId,
  accountRecordTypes,
  contactRecordTypes,
  handlers
}) => {
  if (moduleId === STANDARD_MODULE_IDS.accounts.id) {
    return R.map(t => ({
      id: t.id,
      name: t.name,
      onClick: () => {
        handlers.viewRecordTypeRecords({ moduleId, typeId: t.id });
      }
    }))(accountRecordTypes);
  }

  if (moduleId === STANDARD_MODULE_IDS.contacts.id) {
    return R.map(t => ({
      id: t.id,
      name: t.name,
      onClick: () => {
        handlers.viewRecordTypeRecords({ moduleId, typeId: t.id });
      }
    }))(contactRecordTypes);
  }

  return [];
};

export const getVisibleModulesToReference = createSelector(
  eventDetails,
  state => {
    return userPermissionProfile(state, getEventId(state), getUserId(state));
  },
  (eventDetails, userPermissionProfile) => {
    const modules = getVisibleModules(
      userPermissionProfile,
      eventDetails.enabled_modules
    );

    return modules;
  }
);

export const getEnabledModules = createSelector(
  eventDetails,
  getVisibleModulesToReference,
  EventHomeGetters.groupTypes,
  MyApprovalsGetters.myApprovals,
  selectFeatureFlag(flags.CAN_VIEW_INVENTORY.NAME),
  selectFeatureFlag(flags.CAN_VIEW_PORTAL_SCHEDULE.NAME),
  (_, props) => R.prop("handlers")(props),
  (_, props) => R.prop("canDo")(props),
  (
    eventDetails,
    modules,
    eventHomeGroupTypes,
    myApprovals,
    canViewInventory,
    canViewSchedules,
    handlers,
    canDo
  ) => {
    return R.compose(
      R.map(m => {
        const pendingCount = R.pathOr(0, [
          "pending_count_map",
          getModuleIdToUseForPendingCounts(m.id)
        ])(myApprovals);

        return {
          ...m,
          count_of_alerts: pendingCount,
          goTo: () =>
            getGoTo({
              moduleId: m.id,
              canDo,
              handlers,
              flags: {
                canViewInventory,
                canViewSchedules
              }
            }),
          actions: getActions({
            countOfPendingApprovals: pendingCount,
            moduleId: m.id,
            modules,
            canDo,
            handlers,
            flags: {
              canViewInventory,
              canViewSchedules
            }
          }),
          types: getTypes({
            moduleId: m.id,
            accountRecordTypes: R.propOr([], "account_record_types")(
              eventHomeGroupTypes
            ),
            contactRecordTypes: R.propOr([], "contact_record_types")(
              eventHomeGroupTypes
            ),
            handlers
          })
        };
      }),
      R.filter(m => STANDARD_MODULE_IDS.schedules.id !== m.type_id),
      R.filter(m => ![STANDARD_MODULE_IDS.users.id].includes(m.id))
    )(modules);
  }
);

export const getCountOfPendingApprovals = createSelector(
  MyApprovalsGetters.myApprovals,
  payload => R.path(["total_pending"])(payload)
);

export const getDefaultActions = createSelector(
  (_, props) => R.prop("handlers")(props),
  (_, props) => R.prop("canDo")(props),
  getCountOfPendingApprovals,
  (handlers, canDo, countOfPendingApprovals) => {
    const actions = [];

    actions.push({
      id: "my-approvals",
      name: "My Approvals",
      count_of_alerts: countOfPendingApprovals,
      type: "link",
      is_my_approvals: true,
      onClick: () => {
        handlers.viewMyApprovals();
      }
    });

    actions.push({
      id: "my-tasks",
      name: "My Tasks",
      count_of_alerts: 0,
      type: "link",
      is_my_tasks: true,
      onClick: () => {
        handlers.viewMyTasks();
      }
    });

    actions.push({
      id: "create-order",
      name: "Create Order",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.showCreateOrderModal({});
      }
    });

    actions.push({
      id: "submit-form",
      name: "Submit Form",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.showSubmitFormModal({});
      }
    });

    if (canDo(`${STANDARD_MODULE_IDS.accounts.id}_accounts_add`)) {
      actions.push({
        id: "add-account",
        name: "Add Group",
        count_of_alerts: 0,
        type: "link",
        onClick: () => {
          handlers.showAddAccountModal();
        }
      });
    }

    if (canDo(`${STANDARD_MODULE_IDS.contacts.id}_create`)) {
      actions.push({
        id: "add-contact",
        name: "Add Person",
        count_of_alerts: 0,
        type: "link",
        onClick: () => {
          handlers.showAddContactModal();
        }
      });
    }

    actions.push({
      id: "create-form",
      name: "Create New Form",
      count_of_alerts: 0,
      type: "link",
      onClick: () => {
        handlers.showAddFormModal({});
      }
    });

    if (canDo(`${STANDARD_MODULE_IDS.settings.id}_invite_users`)) {
      actions.push({
        id: "invite-user",
        name: "Invite New User",
        count_of_alerts: 0,
        type: "link",
        onClick: () => {
          handlers.showAddEventTeamUserModal({});
        }
      });
    }

    if (canDo(`${STANDARD_MODULE_IDS.settings.id}_read`)) {
      actions.push({
        id: "event-settings",
        name: "Event Settings",
        count_of_alerts: 0,
        type: "link",
        onClick: () => {
          handlers.viewEventSettings();
        }
      });
    }

    return actions;
  }
);

export const getFilteredModules = createSelector(
  getters.filter,
  getEnabledModules,
  (filter, enabledModules) => {
    if (R.isEmpty(filter)) {
      return enabledModules;
    }
    const lowerCaseFilter = R.toLower(filter);
    return R.filter(
      mod => R.contains(lowerCaseFilter, R.toLower(mod.name)),
      enabledModules
    );
  }
);
