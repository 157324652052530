import * as R from "ramda";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

export default function resolveTitle(props) {
  switch (props.moduleId) {
    case STANDARD_MODULE_IDS.documentRequests.id:
      return "File Requests";
    case STANDARD_MODULE_IDS.accounts.id:
      if (props.group) {
        return props.group.name;
      }
      return "Groups";
    case STANDARD_MODULE_IDS.contacts.id:
      if (props.group) {
        return props.group.name;
      }
      return "People";
    default:
      return props.meta.module.name;
  }
}
