import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Checkbox from "material-ui/Checkbox";

import FormInputWrapper from "components/Event/FormsV2/Sections/FormInputWrapper";
import DeleteConfirmation from "components/Atoms/DeleteConfirmation";

const styles = {
  label: {
    flex: 1,
    color: "#424242",
    fontSize: "16px",
    fontWeight: 600,
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    marginTop: "3px",
    textTransform: "capitalize"
  },
  credential: {
    padding: "5px 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
};

@CSSModules(css)
class EditSummaryModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings: props.summary.settings,
      name: props.summary.name,
      error: { name: false }
    };
  }

  componentWillMount() {
    document.addEventListener("keydown", this.keyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  }

  @autobind
  keyDown(e) {
    switch (e.which) {
      // esc
      case 27:
        this.props.cancel();
        break;
      default:
    }
  }

  @autobind
  handleNameChange(e) {
    const name = e.target.value;
    this.setState({
      name,
      error: { name: false }
    });
  }

  @autobind
  onSave() {
    const { name, settings } = this.state;
    if (this.state.name) {
      this.props.onSave({ ...this.props.summary, name, settings });
    } else {
      this.setState({ error: { name: true } });
    }
  }

  @autobind
  credentialSelect(status, credential) {
    const settings = { ...this.state.settings };
    if (status) {
      settings.credentials = [credential.id, ...settings.credentials];
    } else {
      settings.credentials = [
        ...settings.credentials.filter(c => c !== credential.id)
      ];
    }
    this.setState({ settings });
  }

  @autobind
  onCheck(status, field) {
    const settings = { ...this.state.settings };
    settings.fields[settings.fields.indexOf(field)].visible = status;
    this.setState({ settings });
  }

  createIconStyle(background) {
    const style = {
      marginTop: "1.5px",
      marginRight: "8px",
      fill: background
    };
    if (background === "#FFFFFF") {
      return {
        ...style,
        stroke: "#ccc",
        strokeWidth: "1px"
      };
    }
    return style;
  }

  render() {
    const { summary } = this.props;

    const credentialList = summary.value.credentials.map(credential => (
      <Checkbox
        key={credential.id}
        style={{
          flex: 1,
          display: "flex"
        }}
        checked={this.state.settings.credentials.includes(credential.id)}
        onCheck={e => this.credentialSelect(e.target.checked, credential)}
        label={credential.value}
        labelStyle={styles.label}
        disabled={!!this.props.summary.settings.is_default}
        iconStyle={this.createIconStyle(credential.backgroundColor)}
      />
    ));

    const fieldsList = this.state.settings.fields.map(field => (
      <div key={field.key} styleName="inlineCheckbox">
        <Checkbox
          checked={field.visible}
          labelStyle={styles.label}
          iconStyle={this.createIconStyle("#813CD2")}
          label={field.name}
          onCheck={e => this.onCheck(e.target.checked, field)}
        />
      </div>
    ));

    return (
      <div styleName="container">
        <div className="modal-body">
          <div onClick={this.props.cancel} styleName="iconWrapper">
            <i className="material-icons" styleName="icon">
              close
            </i>
          </div>
          <div styleName="modalBody">
            <div styleName="section">
              <FormInputWrapper
                isValid={!this.state.error.name}
                errorMessages={["Please enter a name"]}
              >
                <div styleName="title">Summary Name*</div>
                <input
                  type="text"
                  placeholder="Name..."
                  styleName="input"
                  defaultValue={this.state.name}
                  disabled={!!this.props.summary.settings.is_default}
                  onChange={this.handleNameChange}
                />
              </FormInputWrapper>
            </div>
            <div styleName="section">
              <div styleName="subtitleWrapper">
                <div styleName="subtitle">Passes showing</div>
                <div styleName="subtitleLine" />
              </div>
              {credentialList}
            </div>
            <div styleName="section">
              <div styleName="subtitleWrapper">
                <div styleName="subtitle">Show for each credential</div>
                <div styleName="subtitleLine" />
              </div>
              <div styleName="inlineCheckboxWrapper">{fieldsList}</div>
            </div>
          </div>
          {this.props.onDelete && !this.props.summary.settings.is_default ? (
            <div styleName="modalBottom">
              <div styleName="done" onClick={this.onSave}>
                Done
              </div>
              <DeleteConfirmation
                button={<div className={css.delete}>Delete</div>}
                handleDelete={this.props.onDelete}
                message="Are you sure you want to delete this summary? This cannot be undone."
                title="Delete summary?"
              />
            </div>
          ) : (
            <div styleName="modalBottom">
              <div styleName="done" onClick={this.onSave}>
                Save
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

EditSummaryModal.getModalClassName = () => "EditSummaryModal";

EditSummaryModal.propTypes = {
  summary: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func
};

export default EditSummaryModal;
