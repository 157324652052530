import React from "react";
import { Div, BigFilledButton, BigOutlineButton } from "components/Base";
import { connect } from "react-redux";
import { getters } from "Items/SelectOrderManagementFieldsModal";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

const decorate = connect(state => ({
  selectedFields: getters.selectedFields(state),
  moduleId: getters.moduleId(state),
  canDo: canEventUserDo(state)
}));

const Footer = ({ hideModal, handlers, selectedFields, moduleId, canDo }) => (
  <Div
    height={70}
    bg="white"
    bt={1}
    bc="neutral1"
    display="row.space-between.center"
    px={4}
    style={{
      flexShrink: "0"
    }}
  >
    <Div display="row.flex-start.center">
      <BigFilledButton
        bg="altB5"
        onClick={() => handlers.onSave(selectedFields)}
      >
        Update View
      </BigFilledButton>
      <BigOutlineButton ml={2} onClick={hideModal}>
        Cancel
      </BigOutlineButton>
    </Div>
    {/*
    {canDo(`${moduleId}_manage`) ? (
      <BigOutlineButton
        ml={2}
        onClick={() => {
          handlers.showAddFieldModal();
        }}
      >
        + Add New Field
      </BigOutlineButton>
    ) : null}
      */}
  </Div>
);

export default decorate(Footer);
