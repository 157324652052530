import * as R from "ramda";
import {
  put,
  takeEvery,
  take,
  all,
  fork,
  call,
  select
} from "redux-saga/effects";
import { batchActions } from "redux-batched-actions";
import Api from "./api";
import { registerError } from "redux/modules/errors/actions";

import { actions } from "./model";
import { openFilePicker, openFileTransform } from "utils/General/sagas";
import { getValue } from "./selectors";

import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";

import { debounce } from "utils/General/sagas";

import { FORM_ACTIONS } from "./constants";

const bulkWriteForms = function*({ payload: forms }) {
  yield put(
    batchActions(
      R.map(
        form =>
          actions.setFields(form.values, { meta: { instanceId: form.id } }),
        forms
      )
    )
  );
};

const bulkUpdateForms = function*({ payload: forms }) {
  yield put(
    batchActions(
      R.map(
        form =>
          actions.mergeValues(form.values, { meta: { instanceId: form.id } }),
        forms
      )
    )
  );
};

const showFilePicker = function*({ meta, payload = {} }) {
  const opts = {
    multiple: R.propOr(true, "multiple")(payload),
    fromSources: ["local_file_system", "dropbox"]
  };

  const accept = R.propOr([], "accept", payload);

  if (accept && accept.length) {
    opts.accept = accept;
  }

  const files = yield call(openFilePicker, opts);

  yield put(actions.setFieldValue({ add: files }, { meta }));
};

const watchShowFilePicker = function*() {
  yield takeEvery(actions.showFilePicker.type, showFilePicker);
};

const watchBulkWriteForms = function*() {
  yield takeEvery(actions.bulkWriteForms.type, bulkWriteForms);
};

const watchBulkUpdateForms = function*() {
  yield takeEvery(actions.bulkUpdateForms.type, bulkUpdateForms);
};

const watchSetValueRequest = debounce(
  actions.setFieldValueRequest.type,
  function*({ payload, error, ...props }) {
    yield put(actions.setFieldValue(payload, { ...props }, error));
  },
  150
);

const uploadPhoto = function*({ payload: { actionId }, meta }) {
  const files = yield call(openFilePicker, {
    multiple: false,
    fromSources:
      actionId === FORM_ACTIONS.TAKE_PHOTO ? ["webcam"] : ["local_file_system"],
    accept: ["image/jpeg", "image/svg+xml", "image/bmp", "image/png"],
    storeTo: {
      path: "attendee-photo/"
    }
  });

  yield put(
    actions.setFieldValue(R.head(files) || {}, {
      meta
    })
  );
};

const removePhoto = function*({ meta }) {
  yield put(
    actions.setFieldValue(
      { url: "" },
      {
        meta
      }
    )
  );
};

const editPhoto = function*({ meta }) {
  const value = yield select(getValue, {
    instanceId: meta.instanceId,
    fieldId: meta.fieldId
  });
  if (value && value.url) {
    const file = yield call(openFileTransform, value.url, { save: false });

    const url = URL.createObjectURL(file);

    yield put(
      actions.setFieldValue(
        { url, blob: file },
        {
          meta
        }
      )
    );
  }
};

const watchExecuteAction = function*() {
  for (;;) {
    const action = yield take(actions.executeAction.type);

    const delegate = R.prop(action.payload.actionId, {
      [FORM_ACTIONS.UPLOAD_PHOTO]: uploadPhoto,
      [FORM_ACTIONS.TAKE_PHOTO]: uploadPhoto,
      [FORM_ACTIONS.EDIT_PHOTO]: editPhoto,
      [FORM_ACTIONS.REMOVE_PHOTO]: removePhoto
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const getUsers = function*({ meta: { instanceId } }) {
  try {
    const credentials = yield select(getCredentials);
    const eventIdToUse = yield select(getEventId);

    const result = yield call(Api.getEventUsers, {
      credentials,
      eventId: eventIdToUse
    });

    yield put(actions.setUsers(result.payload, { meta: { instanceId } }));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred fetching users"
          }
        ])
      )
    ]);
  }
};

const uploadWYSIWYGImg = function*({ payload: cb }) {
  const files = yield call(openFilePicker, { accept: ["image/*"] });
  const file = files[0];
  cb(file.url, { title: file.filename });
};

const watchGetUsers = function*() {
  yield takeEvery(actions.getUsers.type, getUsers);
};

const watchUploadWYSIWYGImg = function*() {
  yield takeEvery(actions.uploadWYSIWYGImg.type, uploadWYSIWYGImg);
};

const rootSaga = function*() {
  yield all([
    fork(watchExecuteAction),
    fork(watchBulkUpdateForms),
    fork(watchBulkWriteForms),
    fork(watchShowFilePicker),
    fork(watchGetUsers),
    fork(watchSetValueRequest),
    fork(watchUploadWYSIWYGImg)
  ]);
};

export default rootSaga;
