import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import {
  Div,
  PopMenu,
  PopMenuCard,
  Text2,
  MediumFilledButton,
  BigFilledButton,
  BigOutlineButton,
  EditIcon,
  MoneyIcon,
  MediumClearInput,
  AddIcon,
  CloseIcon
} from "components/Base";

import { actions } from "ui-kit/SelectPrice";
import { getEditingPrices, getDisableSave } from "ui-kit/SelectPrice/selectors";

import { noop } from "utils/General";
import uuid from "node-uuid";

const decorate = connect(
  (state, props) => ({
    prices: getEditingPrices(state, props),
    disableSave: getDisableSave(state, props)
  }),
  bindInstance({
    onSave: actions.save,
    onCancel: actions.cancel,
    setAmount: actions.setAmount,
    setName: actions.setName,
    addRow: actions.addRow,
    removeRow: actions.removeRow
  })
);

const EditPricesPopOver = ({
  prices = [],
  disableSave = true,
  onSave = noop,
  onCancel = noop,
  setAmount = noop,
  setName = noop,
  addRow = noop,
  removeRow = noop
}) => (
  <PopMenu
    Label={({ onClick }) => (
      <BigFilledButton
        LeftIcon={EditIcon}
        bg="altB5"
        onClick={onClick}
        data-cy="editPrice"
      >
        Edit Prices
      </BigFilledButton>
    )}
  >
    {({ closeMenu }) => (
      <PopMenuCard
        position="right"
        align="left"
        customStyle={{ top: "15px" }}
        onCloseMenu={onCancel}
      >
        <Div
          width={360}
          bra={1}
          shadow={1}
          p={4}
          bg="white"
          style={{ overflow: "visible" }}
        >
          <Div width={1}>
            <Div>
              <Div display="row.space-between">
                <Div width={130} mb={1}>
                  <Text2 color="neutral6" bold>
                    PRICE
                  </Text2>
                </Div>
                <Div flex={1}>
                  <Text2 color="neutral6" bold>
                    LABEL
                  </Text2>
                </Div>
              </Div>
              <Div>
                {R.map(
                  ({ id, label, value }) => (
                    <Div
                      display="row.flex-start.center"
                      key={id}
                      data-cy="priceItem"
                    >
                      <Div width={130}>
                        <Div
                          bc="neutral2"
                          ba={1}
                          display="row"
                          flex={1}
                          height={40}
                        >
                          <Div
                            bg="neutral1"
                            display="column.center.center"
                            px={1}
                          >
                            <MoneyIcon color="neutral7" size={24} />
                          </Div>
                          <Div
                            flex={1}
                            height={40}
                            display="column.center.center"
                          >
                            <MediumClearInput
                              width={1}
                              placeholder="price"
                              value={value}
                              color="neutral7"
                              type="number"
                              name="number"
                              onChange={value => {
                                setAmount({
                                  id,
                                  value
                                });
                              }}
                              continuous
                            />
                          </Div>
                        </Div>
                      </Div>
                      <Div flex={1}>
                        <Div
                          bc="neutral2"
                          ba={1}
                          flex={1}
                          height={40}
                          bl={0}
                          display="column.center.center"
                        >
                          <MediumClearInput
                            width={1}
                            placeholder="enter the price label..."
                            value={label}
                            color="neutral7"
                            name="name"
                            onChange={value => {
                              setName({
                                id,
                                value
                              });
                            }}
                            continuous
                          />
                        </Div>
                      </Div>
                      <Div>
                        <CloseIcon
                          size={20}
                          ml={1}
                          color={{ default: "neutral5", hover: "danger7" }}
                          transition="fast"
                          onClick={() => {
                            removeRow(id);
                          }}
                        />
                      </Div>
                    </Div>
                  ),
                  prices
                )}
              </Div>
              <Div display="row">
                <MediumFilledButton
                  bg="neutral1"
                  mt={2}
                  onClick={() => {
                    addRow(uuid.v4());
                  }}
                  ml={2}
                  data-cy="createPrice"
                >
                  <AddIcon color="black" />
                </MediumFilledButton>
              </Div>
            </Div>
            <Div display="row" width={1} mt={2}>
              <BigOutlineButton
                mr={2}
                onClick={() => {
                  onCancel();
                  closeMenu();
                }}
              >
                Cancel
              </BigOutlineButton>
              <BigFilledButton
                bg="primary7"
                flex={1}
                disabled={disableSave}
                onClick={() => {
                  onSave();
                  closeMenu();
                }}
                data-cy="savePrice"
              >
                Save Changes
              </BigFilledButton>
            </Div>
          </Div>
        </Div>
      </PopMenuCard>
    )}
  </PopMenu>
);

export default decorate(EditPricesPopOver);
