import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { eventId as getEventId } from "redux/modules/event/selectors";
import { VIEWS } from "Orders/List/constants";

import { Text1, Div, SettingsIcon } from "components/Base";

import { actions as SidebarActions } from "ui-kit/Sidebar";
import { isOpened } from "ui-kit/Sidebar/selectors";
import CollapsablePanel from "ui-kit/CollapsiblePanel";

import Sidebar, { getLinks, SidebarWrapper } from "ui-kit/Sidebar/View";

const SetupPanel = ({ link, opened = true, onClick }) => (
  <CollapsablePanel
    arrowColor="rgba(242, 241, 244, 0.3)"
    heading={
      <Div display="row.flex-start" width={1}>
        <SettingsIcon sizeWFS={2} color="rgba(242, 241, 244, 0.3)" />{" "}
        <Text1 color="rgba(242, 241, 244, 0.3)" bold ml={1}>
          SETTINGS
        </Text1>
      </Div>
    }
    bg="transparent"
    opened={opened}
    mt={4}
    onClick={onClick}
  >
    {getLinks(link, 1)}
  </CollapsablePanel>
);

const links = [
  // { name: "Dashboard", id: "dashboard" },
  {
    name: "Orders",
    id: "OrdersList",
    to: "orders/orders",
    activeRoutes: ["OrdersList"],
    activeParams: { view: VIEWS.ORDERS }
  },
  {
    name: "Invoices",
    id: "Invoices",
    to: "orders/invoices",
    activeRoutes: ["OrdersList"],
    activeParams: { view: VIEWS.INVOICES }
  },
  {
    name: "Transactions",
    id: "Transactions",
    to: "orders/transactions",
    activeRoutes: ["OrdersList"],
    activeParams: { view: VIEWS.TRANSACTIONS }
  }
];

const setupLinks = [
  {
    name: "Payment Settings",
    id: "payments",
    to: "settings/orders/payments"
  },
  {
    name: "Invoice Settings",
    id: "invoices",
    to: "settings/orders/payments"
  },
  {
    name: "Email Templates",

    to: "settings/email-templates",
    id: "emails"
  }
];

const mapLinks = (links, eventId) =>
  R.map(
    l => ({
      ...l,
      to: `/event/${eventId}/${l.to}`
    }),
    links
  );

const decorate = connect(
  state => ({
    eventId: getEventId(state),
    setupOpened: isOpened(state, { id: "setup" })
  }),
  {
    toggleMenu: SidebarActions.toggleMenu
  }
);

const View = ({ children, eventId, setupOpened, toggleMenu }) => (
  <SidebarWrapper
    instanceId="credentials"
    sidebar={
      <Sidebar
        links={mapLinks(links, eventId)}
        title="Orders"
        iniOpenedMenues={["setup"]}
        footer={
          <SetupPanel
            link={{ links: mapLinks(setupLinks, eventId) }}
            opened={setupOpened}
            onClick={() => toggleMenu("setup")}
          />
        }
      />
    }
  >
    {children}
  </SidebarWrapper>
);

export default decorate(View);
