import { createModule } from "redux-mvc";

import handlers from "./handlers";

import selectRowsModule from "ui-kit/SelectRows";
import tabsModule from "ui-kit/Tabs";
import formModule from "ui-kit/Form";
import selectItemsModule from "ui-kit/MiniItemsSelector";
import selectFieldsModule from "ui-kit/SelectFields";
import packageTable from "Sponsors/PackageTable";

const { actions, getters } = handlers;

export { actions, getters };

const module = createModule({ ...handlers, optimist: true });

module.plugModule(selectRowsModule);
module.plugModule(tabsModule);
module.plugModule(formModule);
module.plugModule(packageTable);
module.plugModule(selectItemsModule);
module.plugModule(selectFieldsModule);

export default module;
