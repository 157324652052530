import * as R from "ramda";
import React from "react";
import { withRouter } from "react-router";

import { Lookup } from "ui-kit/Form/View";

import { getValue, getField, getSettings } from "../../utils";

import { ID } from "utils/system-field-ids";

const decorate = withRouter;

const LookupEditor = props => {
  const value = getValue(props, R.identity, "");
  const field = getField(props);

  const linkedModuleId = getSettings(props, "moduleId");
  const multiple = getSettings(props, "multiple");
  const linkedFieldId = getSettings(props, "fieldId", ID);

  return (
    <Lookup
      iniValue={R.propOr([], "records", value)}
      moduleId={R.path(["params", "moduleId"], props)}
      fieldId={field.id}
      recordId={R.path(["data", "id"], props)}
      linkedModuleId={linkedModuleId}
      linkedFieldId={linkedFieldId}
      multipleSelect={multiple}
      instanceId={props.instanceId}
      disableClickingRecord={props.disableClickingRecord}
    />
  );
};

export default decorate(LookupEditor);
