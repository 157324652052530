import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";

import { hideModal } from "redux/modules/modal/actions";
import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";
import { orgDetails } from "redux/modules/organization/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import { recordTypes } from "redux/modules/modules/recordTypes/selectors";

function mapStateToProps(state, props) {
  let types = props.types;
  if ((!props.types || !props.types.length) && props.moduleId) {
    types = recordTypes(state, props.moduleId);
  }

  return {
    orgId: orgDetails(state).id,
    eventId: eventDetails(state).id,
    types
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getRecordTypes,
      hideModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controller);
