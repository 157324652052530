import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { Div, Text2, AddIcon, CloseIcon } from "components/Base";
import { noop } from "utils/General";
import FilterPicker from "components/Global/Module/Sidebar/Popovers/Filter";
import Tooltip from "components/Global/Tooltip";

import { Panel } from "ui-kit/FilterControls/View";

import {
  getFieldFiltersSelectedCount,
  getFieldFiltersCount,
  getFieldFilters,
  getSelectedFieldFilters
} from "Items/Manage/selectors";

import { actions } from "Items/Manage";

const decorate = R.compose(
  connect(
    (state, props) => ({
      selectedCount: getFieldFiltersSelectedCount(state, props),
      count: getFieldFiltersCount(state, props),
      fields: getFieldFilters(state),
      filters: getSelectedFieldFilters(state)
    }),
    {
      setFilters: actions.setSelectedFieldFilters,
      removeFilter: actions.removeSelectedFieldFilter
    }
  )
);

const FieldSelect = ({
  fields,
  setFilters,
  filters,
  removeFilter,
  ...styleProps
}) => {
  if (!fields.length) return null;

  return (
    <Panel
      label={
        <Div display="row.flex-start.center" mb={1}>
          <Text2 bold>Order Fields</Text2>
        </Div>
      }
      collapsedDefault
      filtersOn={R.length(R.propOr([], "filters", filters))}
      bodyProps={{ ml: 3, mr: 2 }}
      {...styleProps}
    >
      <Div display="column.flex-start.flex-start">
        <FilterPicker filters={filters} onChange={setFilters} fields={fields}>
          <Div display="row" onClick={noop}>
            <Text2 bold mb={2} color="primary7">
              Add Filter
              {filters && filters.filters && filters.filters.length
                ? ` (${filters.filters.length})`
                : null}
            </Text2>
            <AddIcon color="primary7" ml={1} />
          </Div>
        </FilterPicker>
        <Div display="column" width={1}>
          {filters && filters.filters
            ? filters.filters.map((filter, idx) => {
                const title = `"${R.compose(
                  R.prop("name"),
                  R.find(R.propEq("id", filter.fieldId))
                )(fields)}" ${filter.label}`;
                return (
                  <Tooltip tooltip={title}>
                    <Div
                      display="row.flex-start.center"
                      width={1}
                      fs={1}
                      mb={1}
                    >
                      <Div truncate fs={1}>
                        {title}
                      </Div>
                      <CloseIcon
                        size={15}
                        ml={1}
                        color={{
                          default: "gray5",
                          hover: "gray7"
                        }}
                        onClick={() => removeFilter(idx)}
                      />
                    </Div>
                  </Tooltip>
                );
              })
            : null}
        </Div>
      </Div>
    </Panel>
  );
};

export default decorate(FieldSelect);
