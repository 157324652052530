import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import isRowNotEmpty from "utils/EventForms/FormGrid/isRowNotEmpty";
import isEmpty from "utils/value-types/is-empty";
import FormInputWrapper from "components/Event/FormsV2/Sections/FormInputWrapper";
import Label from "components/Global-2016/Forms/Label";
import InputGroup from "components/Global-2016/Forms/InputGroup";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
export default class Required extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isValid: true
    };
  }

  componentDidMount() {
    this.validate(this.props.value);
  }

  @autobind
  onChange(value) {
    this.validate(value);
    this.props.onChange(value);
  }

  @autobind
  validate(value) {
    if (!this.props.column.is_required) return true;
    const errors = [];
    const isRowNotEmptyCheck = isRowNotEmpty(
      this.props.rowMetaData,
      this.props.columns
    );
    const isEmptyCheck = isEmpty(value, this.props.column.type);
    const isValid = !isRowNotEmptyCheck || !isEmptyCheck;
    if (isEmptyCheck) {
      errors.push("This is a required question");
    }
    if (isValid !== this.state.isValid) {
      this.setState({
        isValid,
        errors
      });
    }
    return !isEmptyCheck;
  }

  render() {
    const { column, editor: Editor } = this.props;
    return (
      <FormInputWrapper
        isValid={this.state.isValid}
        errorMessages={this.state.errors}
      >
        <InputGroup>
          <Label
            key={column.id}
            required={column.is_required || column.settings.isRequired}
            description={column.description || column.settings.description}
          >
            {column.name}
          </Label>
          <div styleName="editorWrapper">
            <Editor {...this.props} onChange={this.onChange} />
          </div>
        </InputGroup>
      </FormInputWrapper>
    );
  }
}

Required.propTypes = {
  editor: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  rowMetaData: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  columns: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};
