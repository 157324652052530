import React, { Fragment } from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { actions, getters } from "Orders/OrderModal";
import {
  getOrderId,
  getInfoForSubmission,
  getAttendee,
  getOrderNumber
} from "Orders/OrderModal/selectors";

import * as selectors from "Submission/Editor/selectors";

import { Paper, EditableValue } from "Orders/OrderModal/View/Common";

import { SubmitterCard } from "Orders/OrderModal/View/Common";

import { Div, Text4, Text2, Text1, InfoIcon } from "components/Base";

import { noop } from "utils/General";

const Info = ({ infos = [], title, ...props }) => (
  <Div style={{ flexShrink: 0 }} {...props}>
    <Div fs={3} fw={3} color="neutral6">
      {title}
    </Div>
    {R.map(
      section => (
        <Fragment key={section.title}>
          <Div display="row.flex-start.center" mt={4} mb={1}>
            <Div fs={3} fw={3} color="neutral6">
              {section.title}
            </Div>

            {section.tooltip ? (
              <InfoIcon
                ml={1}
                tooltip={section.tooltip}
                color="gray5"
                size={15}
              />
            ) : null}
          </Div>

          {R.map(
            info => (
              <Div fs={3} fw={4} color="neutral7">
                {info}
              </Div>
            ),
            section.infos
          )}
        </Fragment>
      ),
      infos
    )}
  </Div>
);

const decorate = connect(
  state => ({
    orderId: getOrderId(state),
    orderNumber: getOrderNumber(state),
    orderInfo: getInfoForSubmission(state),
    submissionInfo: selectors.getInfo(state, {}),
    hasRelatedOrder: selectors.hasRelatedOrder(state),
    submitter: selectors.getSubmitter(state),
    attendee: getAttendee(state),
    editingEmail: getters.editingEmail(state),
    updatedEmail: getters.updatedEmail(state),
    editingPhone: getters.editingPhone(state),
    updatedPhone: getters.updatedPhone(state),
    restricted: getters.isPortalUser(state)
  }),
  {
    goToProfile: actions.viewProfile,
    setEditingEmail: actions.setEditingEmail,
    setUpdatedEmail: actions.setUpdatedEmail,
    saveEmailAddress: actions.saveEmailAddress,
    setEditingPhone: actions.setEditingPhone,
    setUpdatedPhone: actions.setUpdatedPhone,
    savePhone: actions.savePhone
  }
);

const Sidebar = ({
  orderId,
  orderInfo = [],
  submissionInfo = [],
  restricted = false,
  handlers,
  goToProfile = noop,
  hasRelatedOrder,
  submitter,
  attendee,
  orderNumber,
  setEditingEmail,
  editingEmail,
  updatedEmail,
  setUpdatedEmail,
  saveEmailAddress,
  setEditingPhone,
  editingPhone,
  updatedPhone,
  setUpdatedPhone,
  savePhone
}) => (
  <Div bg="neutral0" bs={1} width={1} pb={8} height={1}>
    <Paper bra={0}>
      {hasRelatedOrder && R.isNil(submitter.recordType) ? null : (
        <SubmitterCard avatar={submitter} width={1} p={5}>
          <Div display="column.flex-start.flex-start" height={1} ml={2}>
            <Text4
              bold
              onClick={
                submitter.id && !restricted
                  ? () =>
                      goToProfile({
                        type: submitter.type,
                        id: submitter.id
                      })
                  : undefined
              }
            >
              {submitter.name}
            </Text4>
            {!R.isNil(submitter.recordType) ? (
              <Text2 bold>{submitter.recordType}</Text2>
            ) : null}
          </Div>
        </SubmitterCard>
      )}
      {hasRelatedOrder ? (
        <Div
          display="column.flex-start.flex-start"
          height={1}
          width={1}
          bt={1}
          bc="gray2"
          pl={7}
          pr={5}
          pt={4}
          pb={4}
        >
          <Div display="row.space-between.center" width={1}>
            <Text4 bold>Order #{orderNumber}</Text4>
            {restricted ? null : (
              <Text1
                color="primary7"
                bb={1}
                bc="primary7"
                onClick={() => handlers.editOrder(orderId)}
              >
                Edit
              </Text1>
            )}
          </Div>
          {attendee.name && attendee.name.length ? (
            <Text2
              bold
              onClick={
                restricted
                  ? undefined
                  : () =>
                      goToProfile({
                        type: attendee.type,
                        id: attendee.id
                      })
              }
            >
              {attendee.name}
            </Text2>
          ) : null}

          {attendee.secondaryName && attendee.secondaryName.length ? (
            <Text2
              onClick={
                restricted
                  ? undefined
                  : () =>
                      goToProfile({
                        type: attendee.secondaryType,
                        id: attendee.secondaryId
                      })
              }
            >
              {attendee.secondaryName}
            </Text2>
          ) : null}

          {attendee.id && attendee.name && attendee.name.length ? (
            <Div display="column.flex-start.flex-start" mt={1}>
              <EditableValue
                {...{
                  toggled: editingEmail,
                  placeholder: "Add email",
                  updatedValue: updatedEmail,
                  setUpdatedValue: setUpdatedEmail,
                  saveUpdatedValue: data => {
                    handlers.flagAsModified();
                    saveEmailAddress(data);
                  },
                  setToggle: setEditingEmail,
                  originalValue: attendee.email,
                  validateEmail: true,
                  mb: 1,
                  readOnly: restricted
                }}
              />
              <EditableValue
                {...{
                  toggled: editingPhone,
                  placeholder: "Add phone",
                  updatedValue: updatedPhone,
                  setUpdatedValue: setUpdatedPhone,
                  saveUpdatedValue: data => {
                    handlers.flagAsModified();
                    savePhone(data);
                  },
                  setToggle: setEditingPhone,
                  originalValue: attendee.phone,
                  readOnly: restricted
                }}
              />
            </Div>
          ) : null}
        </Div>
      ) : null}
    </Paper>
    <Info infos={submissionInfo} mx={5} mt={4} title="OTHER INFORMATION" />
    {hasRelatedOrder && orderInfo.length ? (
      <Info infos={orderInfo} mx={5} mt={7} mb={7} title="ORDER INFORMATION" />
    ) : null}
  </Div>
);

export default decorate(Sidebar);
