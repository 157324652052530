import * as R from "ramda";
import moment from "moment-timezone";
import { createHandlers } from "redux-mvc";

import { liftToArr } from "utils/General";

import { FIELD_TYPES, DEFAULT_DATE_FORMAT } from "ui-kit/Form/constants";

const iniState = {
  fields: {},
  users: []
};

const inputHandlers = {
  [FIELD_TYPES.VALUE]: (state, { payload: value }) => ({ value }),
  [FIELD_TYPES.CURRENCY]: (state, { payload: value }) => {
    const number = value ? parseFloat(R.replace(/\$/g, "", value)) : null;
    return {
      value: isNaN(number) ? value : number
    };
  },
  [FIELD_TYPES.DATE]: (state, { payload: value, meta: { format } }) => ({
    value: value
      ? moment(value)
          .tz(moment.tz.guess())
          .format(format || DEFAULT_DATE_FORMAT)
      : value
  }),
  [FIELD_TYPES.DATETIME]: (state, { payload: value }) => ({
    value: value
  }),
  [FIELD_TYPES.TOGGLE]: (state, { payload: value }) => ({ value: value }),
  [FIELD_TYPES.ICON_PICKER]: (state, { payload: value }) => ({ value }),
  [FIELD_TYPES.DROPDOWN]: (_, { payload: item, meta: { multipleSelect } }) =>
    multipleSelect
      ? {
          value: R.map(R.prop("value"), liftToArr(item))
        }
      : {
          value:
            R.isEmpty(item) || R.isNil(item) || R.isEmpty(R.prop("value", item))
              ? []
              : [R.propOr(item, "value", item)]
        },
  [FIELD_TYPES.DROPDOWN_ASYNC]: (_, { payload: item }) => ({
    value: liftToArr(item)
  }),
  [FIELD_TYPES.USER]: (state, { payload: value }) => ({
    value: {
      records: R.compose(R.uniqBy(R.prop("id")), R.propOr([], "records"))(value)
    }
  }),
  [FIELD_TYPES.EVENT_DAYS]: (
    { value = [] },
    { payload: { toggle = [], add = [], remove = [], only } }
  ) => ({
    value: Array.isArray(only)
      ? only
      : R.uniq(
          R.without(remove, R.concat(add, R.symmetricDifference(toggle, value)))
        )
  }),
  [FIELD_TYPES.MARK_EVENT_DAYS]: (
    { value = {} },
    { payload: { toggle = [], groupId, init } }
  ) => {
    const { selectedDays, dayGroups } = value;
    if (!R.isNil(groupId)) {
      // remove selected days from existing groups
      const newDayGroups = R.map(
        dayGroup => ({
          ...dayGroup,
          days: R.difference(dayGroup.days, selectedDays)
        }),
        dayGroups
      );

      if (groupId !== 0) {
        const idx = R.findIndex(R.propEq("id", groupId))(newDayGroups);
        newDayGroups[idx].days = R.uniq(
          [].concat(dayGroups[idx].days, selectedDays)
        );
      }
      return {
        value: {
          dayGroups: newDayGroups,
          selectedDays: []
        }
      };
    }

    return {
      value: !R.isNil(init)
        ? init
        : {
            selectedDays: Array.isArray(toggle)
              ? R.symmetricDifference(toggle, selectedDays)
              : [],
            dayGroups
          }
    };
  },
  [FIELD_TYPES.FILE]: (
    { value = [] },
    { payload: { add = [], only, remove = [] } }
  ) => ({
    value: Array.isArray(only)
      ? only
      : R.filter(
          R.compose(
            R.not,
            R.contains(R.__, R.map(R.prop("uploadId"), remove)), //eslint-disable-line
            R.prop("uploadId")
          ),
          R.concat(value, add)
        )
  }),
  [FIELD_TYPES.IMAGE]: (
    _, // { value = [] },
    { payload: { add = [], only, remove = [] } }
  ) => ({
    value: Array.isArray(only)
      ? only
      : R.filter(
          R.compose(
            R.not,
            R.contains(R.__, R.map(R.prop("uploadId"), remove)), //eslint-disable-line
            R.prop("uploadId")
          ),
          // @NOTE: To have multiple and preserve original, use below -- currently only using single
          // R.concat(value, add)
          add
        )
  }),
  [FIELD_TYPES.US_PHONE]: (_, { payload: value }) => ({
    value: R.compose(
      R.reduce((phone, char) => {
        const charMap = {
          0: {
            char: "+",
            fill: "+1 ("
          },
          1: {
            char: "1",
            fill: "1 ("
          },
          2: {
            char: " ",
            fill: " ("
          },
          3: {
            char: "(",
            fill: "("
          },
          7: {
            char: ")",
            fill: ") "
          },
          8: {
            char: " ",
            fill: " "
          },
          12: {
            char: "-",
            fill: "-"
          }
        };
        const index = phone.length;
        if (charMap[index] && char === charMap[index].char) {
          return `${phone}${char}`;
        }
        if (charMap[index] && char !== charMap[index].char) {
          return `${phone}${charMap[index].fill}${char}`;
        }
        return `${phone}${char}`;
      }, ""),
      R.replace(/(^\+1)|\(|\)|-|\s/g, ""),
      R.ifElse(R.isNil, R.always(""), R.identity)
    )(value)
  }),
  [FIELD_TYPES.CATALOG]: (
    state,
    { payload: { toggle = {}, quantity = {}, only = {} } }
  ) => {
    if (!R.isEmpty(only)) {
      return { value: only };
    }
    if (!R.isEmpty(toggle)) {
      return {
        value: R.has(toggle.id, state.value || {})
          ? R.omit([toggle], state.value || {})
          : toggle.single
          ? { [toggle.id]: 1 }
          : R.mergeAll([state.value, { [toggle.id]: 1 }])
      };
    }
    if (!R.isEmpty(quantity)) {
      return {
        value: R.mergeAll([
          state.value || {},
          { [quantity.id]: quantity.value }
        ])
      };
    }
    return state;
  }
};

const setFieldValue = (state, action) => {
  const fieldId = R.path(["meta", "fieldId"], action);
  const meta = R.propOr({}, "meta", action);
  const field = R.pathOr({}, ["fields", fieldId], state);
  const nextField = R.propOr(
    inputHandlers[FIELD_TYPES.VALUE],
    R.path(["meta", "fieldType"], action),
    inputHandlers
  )(field, action);
  return R.equals(nextField, field) || R.isEmpty(nextField)
    ? state
    : R.assocPath(["fields", fieldId], { ...nextField, meta }, state);
};

const reducers = {
  setIniValue: (state, action) =>
    typeof R.prop("payload", action) === "undefined"
      ? state
      : setFieldValue(state, action),
  setFieldValue,
  setFieldValueRequest: R.identity,
  mergeValues: ({ fields }, { payload: updated = {} }) => ({
    fields: {
      ...fields,
      ...updated
    }
  }),
  clearValues: R.always({
    fields: {}
  }),
  focusField: R.identity,
  blurField: R.identity,
  bulkWriteForms: R.identity,
  bulkUpdateForms: R.identity,
  showFilePicker: R.identity,
  getUsers: R.identity,
  executeAction: R.identity,
  dataRequest: R.identity,
  uploadVideo: R.identity,
  cancelUploadVideo: R.identity,
  cancelInstance: R.identity,
  uploadWYSIWYGImg: R.identity
};

const model = createHandlers({ iniState, reducers, namespace: "UIForm" });

const { actions, getters } = model;

export { actions, getters };

export default model;
