import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Header = ({ children, types }) => (
  <div styleName="container">
    <span styleName="text">{children}</span>
    <div styleName="separator" />
    {types.map(c => c)}
  </div>
);

Header.defaultProps = {
  types: []
};

Header.propTypes = {
  children: PropTypes.node.isRequired,
  types: PropTypes.array
};

export default CSSModules(Header, css);
