import * as R from "ramda";
import { createModule, createHandlers } from "redux-mvc";

const iniState = {
  prices: {},
  editing: {}
};

const reducers = {
  init: (_, { payload: prices }) => ({
    prices: R.indexBy(R.prop("id"), prices),
    editing: R.indexBy(R.prop("id"), prices)
  }),
  cancel: ({ prices }) => ({
    editing: prices
  }),
  save: ({ editing }) => ({
    prices: editing
  }),
  setName: ({ editing: prices }, { payload: { id, value } }) => ({
    editing: R.assocPath([id, "label"], value, prices)
  }),
  setAmount: ({ editing: prices }, { payload: { id, value } }) => ({
    editing: R.assocPath([id, "value"], value, prices)
  }),
  addRow: ({ editing: prices }, { payload: id }) => ({
    editing: R.assoc(id, { id, value: 0, label: "" }, prices)
  }),
  removeRow: ({ editing: prices }, { payload: id }) => ({
    editing: R.omit([id], prices)
  })
};

const handlers = createHandlers({
  iniState,
  reducers,
  namespace: "UISelectPrice"
});

const { actions, getters } = handlers;

const module = createModule(handlers);

export { actions, getters };

export default module;
