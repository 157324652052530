import PropTypes from "prop-types";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import React from "react";
import CanUserDo from "components/Global/Security/CanUserDo";
import NeutralButton from "components/Global/Module/Buttons/NeutralButton";
import ActionButton from "components/Global/Module/Buttons/ActionButton";
import ActionsButtonMenu from "components/Global/CRM/TableViews/ListView/ActionsButtonMenu";
import resolveActionsMenu from "../../utils/resolveActionsMenu";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import SummaryModal from "components/Event/InventoryV2/Needs/Modals/Summary";
import { PopoverMenu } from "components/Base";

const ModuleActions = props => {
  const {
    module,
    showAddRecordModal,
    showAddSubmissionModal,
    isForm,
    showModal
  } = props.meta;
  if (!module) return null;
  const showInventorySummary =
    module.fields && module.fields.some(f => f.type === "catalog-item");

  return (
    <div styleName="actionButtons">
      {showInventorySummary ? (
        <div styleName="buttonWrapper">
          <ActionButton
            label="View Summary"
            handleClick={() =>
              showModal({
                content: <SummaryModal moduleId={module.id} />,
                wrapper: ModalWrapper
              })
            }
          />
        </div>
      ) : (
        ""
      )}
      <CanUserDo any={[`${module.id}_manage`, `${module.id}_export`]}>
        <div styleName="buttonWrapper">
          <ActionsButtonMenu menu={resolveActionsMenu(props)}>
            <NeutralButton
              label={
                <span className={css.buttonLabel}>
                  Options <i className="material-icons">arrow_drop_down</i>
                </span>
              }
            />
          </ActionsButtonMenu>
        </div>
      </CanUserDo>
      <CanUserDo
        action={
          module.id === STANDARD_MODULES.accounts.id
            ? `${module.id}_accounts_add`
            : `${module.id}_create`
        }
      >
        {isForm ? (
          <PopoverMenu
            Label={({ onClick }) => (
              <ActionButton label="Add Record" handleClick={onClick} />
            )}
            menuItems={[
              ["Add new record", showAddRecordModal],
              ["Submit via form", showAddSubmissionModal]
            ]}
          />
        ) : (
          <ActionButton label="Add Record" handleClick={showAddRecordModal} />
        )}
      </CanUserDo>
    </div>
  );
};

ModuleActions.propTypes = {
  meta: PropTypes.shape({
    module: PropTypes.shape({
      id: PropTypes.string,
      fields: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          type: PropTypes.string
        })
      ),
      record_name: PropTypes.string
    }),
    showAddRecordModal: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired
  }).isRequired
};

export default CSSModules(ModuleActions, css);
