import React, { Component } from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import { get } from "lodash";
import { ButtonGroup, Submit } from "components/Global/Modal/Layout/Buttons";
import FormElements from "components/Global/Modal/Layout/FormElements";
import CircularProgress from "material-ui/CircularProgress";
import { ArrowRenderer } from "components/Global/Editors/utils/reactSelect";
import SubmissionApi from "redux/modules/formsV2/submission/api";
import SendEmailModal from "SendEmailModal/View";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as SEND_TO_OPTION_TYPES from "SendEmailModal/utils/send-to-option-types";
import { addS } from "utils/General";
import { Dropdown } from "components/Base";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

const { Label, InputSection, InputGroup } = FormElements;

import { connect } from "react-redux";
import { getCredentials } from "redux/modules/user/selectors";

const decorate = connect(state => ({
  userCredentials: getCredentials(state)
}));

export const Loading = CSSModules(
  () => (
    <div styleName="progress">
      <CircularProgress color="#ccc" />
    </div>
  ),
  css
);

@CSSModules(css)
class SendRecordForm extends Component {
  state = {
    form: null,
    saving: false
  };

  componentWillMount() {
    this.props.getModule({
      moduleId: this.props.moduleId,
      options: {
        eventId: get(this.props.eventDetails, "id")
      }
    });
  }

  isValid = () => !!this.state.form;

  handleSave = async sendMessage => {
    this.setState({ saving: true });

    if (this.props.accountsAndRecordIds) {
      await Promise.all(
        this.props.accountsAndRecordIds.map(({ recordId, accountId }) =>
          SubmissionApi.post(this.props.userCredentials, {
            version: 3,
            eventId: this.props.eventDetails.id,
            formId: this.state.form.value,
            userId: this.props.user.id, // @NOTE: Currently using userId of admin
            isSubmitted: true,
            accountId,
            suppressSubmissionStartedEmail: true,
            submissionRecordId: recordId
          })
        )
      );
    } else {
      await Promise.all(
        this.props.recordIds.map(recordId =>
          SubmissionApi.post(
            { userId: this.props.user.id },
            {
              version: 3,
              eventId: this.props.eventDetails.id,
              formId: this.state.form.value,
              userId: this.props.user.id, // @NOTE: Currently using userId of admin
              isSubmitted: true,
              accountId: this.props.sendToAccountId,
              suppressSubmissionStartedEmail: true,
              submissionRecordId: recordId
            }
          )
        )
      );
    }

    this.props.showSnackbar({
      message: "Saved"
    });

    if (this.props.onUpdate) {
      this.props.onUpdate();
    }

    this.props.hideModal();

    if (sendMessage) {
      this.props.showModal({
        content: (
          <SendEmailModal
            moduleId={STANDARD_MODULE_IDS.accounts.id}
            records={[this.props.sendToAccountId]}
            selectedOptions={[SEND_TO_OPTION_TYPES.ACCOUNT_CONTACT_USERS]}
          />
        ),
        wrapper: ModalWrapper
      });
    }
  };

  handleFormChange = form => this.setState({ form });

  render() {
    const {
      isFetching,
      hideModal,
      accountsAndRecordIds,
      recordIds,
      sendToAccountName
    } = this.props;

    const { saving, form } = this.state;
    const recordCount = accountsAndRecordIds
      ? accountsAndRecordIds.length
      : recordIds.length;

    return (
      <StyleWrapper
        heading={`Send ${recordCount} record${recordCount === 1 ? "" : "s"}`}
        hideModal={hideModal}
        width={475}
      >
        {isFetching ? (
          <Loading />
        ) : (
          <div>
            <InputSection>
              {accountsAndRecordIds ? (
                <InputGroup>
                  This will make the {recordCount} selected record
                  {addS(recordCount)} available to the account that is selected
                  in the record's lookup field using the form selected below.
                </InputGroup>
              ) : (
                <InputGroup>
                  This will give {sendToAccountName} the ability to make changes
                  to the {recordCount} selected record {addS(recordCount)} using
                  the form selected below.
                </InputGroup>
              )}
            </InputSection>
            <InputSection>
              <InputGroup>
                <Label>Select form</Label>
                <Dropdown
                  arrowRenderer={ArrowRenderer}
                  clearable={false}
                  onChange={this.handleFormChange}
                  usePortal
                  options={this.props.forms
                    .map(f => ({
                      label: f.name,
                      value: f.id
                    }))
                    .sort((a, b) => a.label - b.label)}
                  placeholder="Select a form to send"
                  value={form}
                />
              </InputGroup>
            </InputSection>

            <br />
          </div>
        )}
        <div className={css.footer}>
          <ButtonGroup>
            {/*
            // @NOTE: Hiding for now
            <Submit
              title={saving ? "Saving..." : "Save and send message"}
              disabled={saving || !this.isValid()}
              onClick={() => this.handleSave(true)}
            />
            */}
            <Submit
              title={saving ? "Saving..." : "Save"}
              disabled={saving || !this.isValid()}
              onClick={() => this.handleSave()}
            />
          </ButtonGroup>
        </div>
      </StyleWrapper>
    );
  }
}

export default decorate(SendRecordForm);
