import React, { Component } from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";

import {
  ButtonGroup,
  Submit,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";
import { Div } from "components/Base";

import Api from "redux/modules/accounts/list/api";
import AsyncSelect from "@lennd/react-select/lib/Async";
import { sortBy } from "lodash";
import DatePicker from "components/Event/FormsV2/Modals/FormEditModal/Calendar";

import { connect } from "react-redux";
import { getCredentials } from "redux/modules/user/selectors";

const decorate = connect(state => ({
  userCredentials: getCredentials(state)
}));

class AddAccountFormAssignment extends Component {
  state = {
    account: {
      accountId: "",
      accountName: ""
    },
    closeDate: null
  };

  onSelectAccount = option => {
    const account = {
      accountId: "",
      accountName: ""
    };
    if (option) {
      account.accountId = option.id;
      account.accountName = option.name;
    }
    this.setState({
      account
    });
  };

  onChangeCloseDate = closeDate => this.setState({ closeDate });

  promiseOptions = async term => {
    const result = await Api.search(
      null,
      this.props.eventId,
      term,
      this.props.userCredentials
    );

    return sortBy(
      result.accounts
        .filter(a => a.name)
        .map(a => ({
          id: a.id,
          name: a.name,
          type: a.type.name,
          value: a.id,
          label: a.name
        })),
      a => a.label
    );
  };

  validateForm = () => this.state.account.accountName.length;

  handleSubmit = e => {
    e.preventDefault();

    this.props.onSave({
      accountId: this.state.account.accountId,
      closeDate: this.state.closeDate
    });

    this.props.hideModal();
  };

  render() {
    const { account, closeDate } = this.state;

    return (
      <StyleWrapper
        hideModal={this.props.hideModal}
        heading="Assign form to a group"
        width={390}
      >
        <form onSubmit={this.handleSubmit}>
          <Div mb={4}>
            <Div fw={3} color="gray7" mb={1}>
              Group:
            </Div>
            <AsyncSelect
              isClearable
              cacheOptions
              defaultOptions
              onChange={this.onSelectAccount}
              loadOptions={this.promiseOptions}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: base => ({
                  ...base,
                  zIndex: 9999
                })
              }}
              formatOptionLabel={({ label, type }) => (
                <div>
                  <strong>{label}</strong> {type}
                </div>
              )}
              placeholder="Type to search groups..."
              value={{ label: account.accountName, value: account.accountId }}
            />
          </Div>

          <Div mb={4}>
            <Div fw={3} color="gray7" mb={1}>
              Specific due date for this group (Optional):
            </Div>
            <DatePicker value={closeDate} onChange={this.onChangeCloseDate} />
          </Div>

          <Div display="row.flex-start.center">
            <ButtonGroup>
              <Submit title="Save" disabled={!this.validateForm()} />
              <ButtonOutline title="Cancel" onClick={this.props.hideModal} />
            </ButtonGroup>
          </Div>
        </form>
      </StyleWrapper>
    );
  }
}

export default decorate(AddAccountFormAssignment);
