import React from "react";
import { Div, BigTextButton } from "components/Base";
import { connect } from "react-redux";

import Categories from "./Categories";
import Products from "./Products";
import SearchBar from "ui-kit/SearchBar/View";
import Cart from "Portal/Cart/View/widget";
import { showWidget } from "Portal/Cart/selectors";
import { withRouter } from "react-router";
import { getters } from "../model";
import SelectedPeople from "./SelectedPeople";
import SelectPeopleModal from "./SelectPeopleModal";
import Loading from "ui-kit/Loading";
import * as R from "ramda";

const decorate = R.compose(
  connect((state, props) => ({
    title: getters.title(state, props),
    subtitle: getters.subtitle(state, props),
    showShow: showWidget(state, props),
    countOfExistingRequests: getters.countOfExistingRequests(state, props),
    highlightColor: getters.highlightColor(state, props),
    isSelectPeopleModalOpen: getters.isSelectPeopleModalOpen(state, props),
    loading: getters.loading(state, props)
  })),
  withRouter
);

const Body = ({
  showShow,
  countOfExistingRequests,
  highlightColor,
  title,
  subtitle,
  router: { push, params },
  isSelectPeopleModalOpen,
  loading
}) => (
  <Div
    display="row.flex-start.flex-start"
    style={{ paddingBottom: "50px" }}
    width={1}
  >
    {isSelectPeopleModalOpen ? <SelectPeopleModal /> : null}
    {!loading ? (
      <>
        <Div width={300} style={{ marginRight: 31 }}>
          <Div>
            <SelectedPeople />
            <Categories />
          </Div>
          {R.gte(countOfExistingRequests, 1) && (
            <Div
              style={{ marginTop: 21, padding: 20, borderRadius: 4 }}
              display="row.space-between.center"
              bg="#FFFFFF"
            >
              <Div color="#000000" style={{ fontWeight: 500 }}>
                Your Request ({countOfExistingRequests})
              </Div>
              <BigTextButton
                onClick={() =>
                  push(
                    `/hubs/${params.portalSlug}/${params.portalId}/people/${params.recordTypeId}/reports`
                  )
                }
                color="#2F7FEC"
              >
                View
              </BigTextButton>
            </Div>
          )}
        </Div>

        <Div
          width={1}
          style={{
            maxWidth: "900px"
          }}
        >
          {showShow && (
            <Div style={{ marginBottom: 23, position: "sticky", top: 0 }}>
              <Cart />
            </Div>
          )}

          {title && title.length ? (
            <Div color="gray7" mt={3} fw={3} fs={5}>
              {title}
            </Div>
          ) : null}

          {subtitle && subtitle.length ? (
            <Div mb={5} color="gray7" fs={3}>
              <span
                className="lennd-wysiwyg-content"
                dangerouslySetInnerHTML={{
                  __html: subtitle
                }}
              />
            </Div>
          ) : null}

          <Div style={{ marginBottom: 23 }}>
            <SearchBar
              pill
              shadow={0}
              flex={1}
              placeholder="Search catalog"
              buttonColor={highlightColor}
              bc={highlightColor}
            />
          </Div>

          <Products />
        </Div>
      </>
    ) : (
      <Loading />
    )}
  </Div>
);

export default decorate(Body);
