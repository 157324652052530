/* eslint-disable react/prop-types */
// noinspection JSCheckFunctionSignatures

import React, { useEffect, useState } from "react";
import * as R from "ramda";
import buildLineItems from "Orders/utils/build-line-items";
import { MEAL_TYPE_ID } from "utils/item-types";
import moment from "moment";

import View from "./View";

import { connect } from "react-redux";
import { formatAmountForEvent } from "redux/modules/portal/selectors";

const decorate = connect(state => ({
  formatAmountForEvent: formatAmountForEvent(state)
}));

const Controller = props => {
  const {
    block,
    hideModal,
    onDone,
    eventDetails,
    addToGroupOrder,
    accountId,
    contactId,
    formatAmountForEvent
  } = props;
  const [state, setState] = useState({
    selectedVariants: {},
    variantsToRemove: [],
    processing: null
  });
  const { selectedVariants, variantsToRemove, processing } = state;
  const { items } = block;

  useEffect(() => {
    const selectedVariants = {};
    items.forEach(item => {
      selectedVariants[R.prop("variant_id", item)] = R.propOr(
        0,
        "count_of_editable_items",
        item
      );
    });
    setState(prevState => ({
      ...prevState,
      selectedVariants
    }));
  }, [block]);

  const getQuantityOfItems = ({ replaceIdWithQty }) => {
    const { selectedVariants } = state;
    const countOfIssued = R.reduce((result, item) => {
      return result + R.prop("count_of_issued_items", item);
    }, 0)(block.items);
    const countOfEditable = R.reduce((result, id) => {
      return id === R.prop("id", replaceIdWithQty)
        ? result + R.prop("quantity", replaceIdWithQty)
        : result + selectedVariants[id];
    }, 0)(Object.keys(selectedVariants));
    return countOfIssued + countOfEditable;
  };
  const getItemBlockLimit = ({ replaceIdWithQty }) => {
    const { limit } = block;
    const blockItem =
      items.find(
        item => R.prop("variant_id", item) === R.prop("id", replaceIdWithQty)
      ) || {};
    const blockBasedLimit = limit
      ? {
          limit: limit,
          remaining: limit - getQuantityOfItems({ replaceIdWithQty })
        }
      : null;

    if (R.prop("limit", blockItem)) {
      const itemBasedLimit =
        R.prop("limit", blockItem) - R.prop("quantity", replaceIdWithQty);
      return {
        limit: R.prop("limit", blockItem),
        remaining:
          blockBasedLimit &&
          R.prop("remaining", blockBasedLimit) < itemBasedLimit
            ? R.prop("remaining", blockBasedLimit)
            : itemBasedLimit
      };
    }
    return blockBasedLimit;
  };

  const updateVariantQuantity = (id, quantity) => {
    const { selectedVariants, variantsToRemove } = state;
    const limit = getItemBlockLimit({
      replaceIdWithQty: { id, quantity }
    });

    /**
     * If the incoming quantity would surpass our limit AND the incoming
     * quantity is greater than existing quantity (in order to still allow
     * decreasing the value), do not allow
     */
    if (
      R.not(
        limit &&
          R.prop("remaining", limit) < 0 &&
          quantity > selectedVariants[id]
      )
    ) {
      const currentVariantsToRemove = variantsToRemove;
      if (quantity <= 0) {
        currentVariantsToRemove.push(id);
      } else {
        currentVariantsToRemove.splice(currentVariantsToRemove.indexOf(id), 1);
      }
      setState(prevState => ({
        ...prevState,
        selectedVariants: {
          ...selectedVariants,
          [id]: quantity <= 0 ? 0 : quantity
        },
        variantsToRemove: currentVariantsToRemove
      }));
    }
  };

  const onVariantSelect = id => {
    const currentSelectedVariants = selectedVariants;
    if (typeof currentSelectedVariants[id] !== "undefined") {
      delete currentSelectedVariants[id];
    } else {
      currentSelectedVariants[id] = null;
    }
    setState(prevState => ({
      ...prevState,
      selectedVariants: currentSelectedVariants
    }));
  };

  const isVariantSelected = id => {
    return typeof selectedVariants[id] !== "undefined";
  };

  const getIssuedVariants = R.compose(
    R.map(
      R.reduce(
        (current, value) => current + R.prop("count_of_issued_items", value),
        0
      )
    ),
    R.groupBy(R.prop("variant_id")),
    R.filter(item => R.prop("count_of_issued_items", item) > 0)
  );

  const endAddingVariants = () => {
    if (onDone) onDone();
    hideModal();
  };

  useEffect(() => {
    if (!R.isNil(processing) && processing) {
      endAddingVariants();
      setState(prevState => ({
        ...prevState,
        processing: false
      }));
    }
  }, [processing]);

  const sendVariantsToBeAdded = async selectedVariants => {
    setState(prevState => ({
      ...prevState,
      processing: true
    }));
    await addToGroupOrder({
      eventId: R.prop("id", eventDetails),
      accountId: accountId,
      contactId: contactId,
      blockId: R.prop("id", block),
      variants: selectedVariants
    });
  };

  const startAddingVariants = async () => {
    const issuedVariants = getIssuedVariants(R.propOr([], "items", block));
    const variants = R.reduce(
      (v, key) => {
        const sum = selectedVariants[key] - (issuedVariants[key] || 0);
        return {
          ...v,
          [key]: sum < 0 ? 0 : sum
        };
      },
      0,
      R.keys(selectedVariants)
    );
    let currentSelectedVariants = buildLineItems(variants).map(lineItem => ({
      id: R.prop("variantId", lineItem),
      quantity: 1,
      operation: null
    }));
    variantsToRemove.forEach(variant => {
      currentSelectedVariants.push({
        quantity: 0,
        id: variant,
        operation: "remove"
      });
    });
    await sendVariantsToBeAdded(currentSelectedVariants);
  };

  const handleSave = () => startAddingVariants();

  const itemsWithHandlers = R.compose(
    R.map(item => {
      const variantId = R.prop("variant_id", item);
      const quantity =
        selectedVariants[variantId] >= 0 ? selectedVariants[variantId] : 0;
      const meta = [];
      if (R.prop("variant_name", item)) {
        meta.push(R.prop("variant_name", item));
      }
      if (R.path(["price", "price"], item)) {
        meta.push(
          `${formatAmountForEvent(R.path(["price", "price"], item))} each`
        );
      }
      if (R.prop("limit", item)) {
        meta.push(`Limit: ${R.prop("limit", item)}`);
      }

      return {
        status: "done",
        title: R.prop("item_name", item),
        color: R.prop("item_background_color", item),
        additionalInfo: meta.join(" · "),
        groupOn: R.prop("variant_name", item),
        limit: R.prop("limit", item),
        typeId: R.prop("item_type_id", item),
        quantity,
        count: selectedVariants[variantId],
        selected: isVariantSelected(variantId),
        onToggle: () => onVariantSelect(variantId),
        incrementQuantity: () => updateVariantQuantity(variantId, quantity + 1),
        decrementQuantity: () => updateVariantQuantity(variantId, quantity - 1),
        updateVariantQuantity: qty =>
          updateVariantQuantity(variantId, parseInt(qty, 10) || 0)
      };
    }),
    R.sortBy(item => {
      if (R.prop("item_type_id", item) === MEAL_TYPE_ID) {
        return `${moment(new Date(R.prop("variant_name", item))).format(
          "YYYY-MM-DD"
        )}_${R.prop("item_order", item)}`;
      }
      return R.prop("order", item);
    })
  )(items);

  const itemsToShow = {
    quantity: Object.keys(selectedVariants).reduce(
      (count, key) => count + selectedVariants[key],
      0
    ),
    onSearch: () => {},
    groups:
      R.prop("item_type_id", block) === MEAL_TYPE_ID
        ? R.compose(
            mealsByKey =>
              R.map(mealKey => {
                return {
                  title: mealKey,
                  dateRange: null,
                  showTitle: true,
                  sections: [
                    {
                      title: "Available",
                      rows: mealsByKey[mealKey]
                    }
                  ]
                };
              })(R.keys(mealsByKey)),
            R.groupBy(R.prop("groupOn"))
          )(itemsWithHandlers)
        : [
            {
              title: "Passes",
              dateRange: null,
              showTitle: false,
              sections: [
                {
                  title: "Available",
                  rows: itemsWithHandlers
                }
              ]
            }
          ]
  };

  return (
    <View
      {...{
        hideModal,
        name: R.prop("name", block),
        limit: R.prop("limit", block),
        description: R.path(["description", "length"], block)
          ? R.prop("description", block)
          : null,
        items: itemsToShow,
        handleSave: handleSave,
        isSaving: processing
      }}
    />
  );
};

export default decorate(Controller);
