import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const CardActionLink = ({ children, onClick, submissionId }) => (
  <span
    className={css.cardActionLink}
    onMouseDown={e => {
      e.stopPropagation();
      onClick(e, submissionId);
    }}
  >
    {children}
  </span>
);

CardActionLink.propTypes = {
  onClick: PropTypes.func.isRequired,
  submissionId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default CSSModules(CardActionLink, css);
