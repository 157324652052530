import * as R from "ramda";
import React from "react";

import { noop } from "utils/General";

import {
  Div,
  Popover,
  MediumOutlineButton,
  DownFilledIcon
} from "components/Base";

const DefaultButton = ({ onClick, icon = "" }) => (
  <MediumOutlineButton
    onClick={onClick}
    RightIcon={DownFilledIcon}
    color="neutral7"
    bg="white"
  >
    <span className="material-icons">{icon}</span>
  </MediumOutlineButton>
);

const IconPicker = ({ value = "", onChange = noop, icons = [] }) => (
  <Div display="row.flex-start.flex-start">
    <Popover
      anchorOrigin={{ horizontal: "center", vertical: "center" }}
      Label={({ onClick }) => <DefaultButton icon={value} onClick={onClick} />}
    >
      {({ closePopover }) => (
        <Div
          bg="white"
          display="row.flex-start.flex-start"
          width={138}
          p={2}
          style={{
            flexWrap: "wrap"
          }}
        >
          {R.map(icon => (
            <Div
              key={icon.value}
              display="row.flex-start.center"
              p={1}
              m={1}
              bg={{
                default: icon.value === value ? "info2" : "transparent",
                hover: "neutral1"
              }}
              onClick={() => {
                onChange({ value: icon.value });
                closePopover();
              }}
            >
              <span className="material-icons">{icon.value}</span>
            </Div>
          ))(icons)}
        </Div>
      )}
    </Popover>
  </Div>
);

export default IconPicker;
