import { put, takeEvery, select } from "redux-saga/effects";
import * as R from "ramda";
import { actions } from "./model";
import { actions as TableActions } from "ui-kit/Table/model";

import {
  getMealSummaryValues,
  getAllAssignmentsRows,
  getSummaryFields,
  getAllFields
} from "./selectors";
import {
  SUMMARY_FIELDS_WIDTHS,
  ALL_FIELDS_WIDTHS,
  INSTANCE_ALL_ITEMS_TABLE
} from "./constants";

const init = function*() {
  yield put(actions.setLoading(true));
  const summaryGroupedRows = yield select(getMealSummaryValues);
  const allAssignmentGroupedRows = yield select(getAllAssignmentsRows);
  const summaryFields = yield select(getSummaryFields);
  const allFields = yield select(getAllFields);

  for (const summaryData of summaryGroupedRows) {
    yield put(
      TableActions.setData(
        {
          columns: summaryFields,
          columnWidths: SUMMARY_FIELDS_WIDTHS,
          rows: R.propOr([], "rows", summaryData),
          canEditCells: false
        },
        {
          meta: {
            instanceId: summaryData.id
          }
        }
      )
    );
  }

  for (const assignmentDataKey of R.keys(allAssignmentGroupedRows)) {
    const data = R.prop(assignmentDataKey, allAssignmentGroupedRows);
    yield put(
      TableActions.setData(
        {
          columns: allFields,
          columnWidths: ALL_FIELDS_WIDTHS,
          rows: R.propOr([], "rows", data),
          canEditCells: false
        },
        {
          meta: {
            instanceId: `${INSTANCE_ALL_ITEMS_TABLE}-${data.id}`
          }
        }
      )
    );
  }

  yield put(actions.setLoading(false));
};

const rootSaga = function*() {
  yield takeEvery(actions.init.type, init);
};

export default rootSaga;
