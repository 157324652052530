import React from "react";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import { connect } from "react-redux";
import { getReportGroups } from "../selectors";

import {
  Div,
  Span,
  MoreVertIcon,
  PopoverMenu,
  makeTable,
  HalfSizeDotIcon,
  TinyShadedBox,
  DarkTabs,
  MediumOutlineButton,
  Text0,
  Text1,
  Text2,
  Text3,
  Text5,
  tabStateHandler,
  MoreIcon,
  MediumFilledButton
} from "components/Base";
import moment from "moment";
import { withProps, capitalizeFirst } from "utils/General";

import { getters, actions } from "../index";

const decorate = connect(
  state => ({
    reportGroups: getReportGroups(state)
  }),
  {
    goToModuleReports: actions.goToModuleReports,
    runReport: actions.runReport
  }
);

const Group = ({ children }) => (
  <Div bra={1} bg="white" p={5} mb={3} shadow={1}>
    {children}
  </Div>
);
const GroupTitle = ({ children, isStandard, goToReports }) => (
  <Div display="row.flex-start.center" fs={4} color="gray7" fw={3} mb={5}>
    {children}
    {isStandard ? null : (
      <Div
        color={{
          default: "primary7",
          hover: "primary5"
        }}
        ml={4}
        onClick={goToReports}
        fs={2}
      >
        Manage Reports
      </Div>
    )}
  </Div>
);

const Empty = ({ children }) => (
  <Div display="row.flex-start.center">{children}</Div>
);

const RowText = ({ text = "—" }) => <Text3 color="gray7">{text}</Text3>;

const headerCell = text => () => (
  <Text1 color="gray7" bold uppercase>
    {text}
  </Text1>
);

const HeaderStyler = withProps({
  display: "row.flex-start.center",
  pb: 2,
  bc: "gray3",
  bb: 1
})(Div);

const RowStyler = withProps({
  display: "row.flex-start.center",
  bg: "white",
  py: 1
})(Div);

const tableProps = {
  TableStyler: Div,
  HeaderStyler,
  RowStyler,
  HeaderCellStyler: Div,
  RowCellStyler: Div,
  columnProps: [
    { width: 2 / 5 },
    { width: 1 / 5 },
    { width: 1 / 5 },
    { width: 1 / 5 }
  ],
  headerCellComps: [
    headerCell("Report Name"),
    headerCell("Created by"),
    headerCell("Last run"),
    headerCell("Actions")
  ],
  rowCellComps: [
    ({ name }) => (
      <Div display="row.flex-start.center">
        <Text3 ml={1} color="neutral7" bold>
          {name}
        </Text3>
      </Div>
    ),
    ({ created_by }) => (
      <RowText text={[created_by.first_name, created_by.last_name].join(" ")} />
    ),
    ({ last_run_at }) => (
      <RowText text={last_run_at ? moment(last_run_at).fromNow() : null} />
    ),
    ({ showEditReportModal, runReport, running }) => (
      <Div display="row.flex-start.center">
        <PopoverMenu
          Label={({ onClick }) => (
            <MediumFilledButton
              onClick={running ? undefined : onClick}
              bg={running ? "yellow0" : "neutral0"}
              color={running ? "gray7" : "primary7"}
            >
              {running ? "Preparing..." : "Run Report"}
            </MediumFilledButton>
          )}
          menuItems={[
            ["PDF", () => runReport("pdf")],
            ["XLSX", () => runReport("xlsx")],
            ["HTML", () => runReport("html")]
          ].filter(i => i)}
        />
        <PopoverMenu
          Label={({ onClick }) => (
            <MediumOutlineButton onClick={onClick} ml={3}>
              <MoreIcon size={16} />
            </MediumOutlineButton>
          )}
          menuItems={[["Edit Report", () => showEditReportModal()]]}
        />
      </Div>
    )
  ]
};

const ReportTable = makeTable({
  ...tableProps
});

const StandardReportTable = makeTable({
  ...tableProps,
  columnProps: [{ width: 4 / 5 }, { width: 1 / 5 }],
  headerCellComps: [headerCell("Report Name"), headerCell("Actions")],
  rowCellComps: [
    ({ name }) => (
      <Div display="row.flex-start.center">
        <Text3 ml={1} color="neutral7" bold>
          {name}
        </Text3>
      </Div>
    ),
    ({ runReport, running }) => (
      <Div display="row.flex-start.center">
        <PopoverMenu
          Label={({ onClick }) => (
            <MediumFilledButton
              onClick={running ? undefined : onClick}
              bg={running ? "yellow0" : "neutral0"}
              color={running ? "gray7" : "primary7"}
            >
              {running ? "Preparing..." : "Run Report"}
            </MediumFilledButton>
          )}
          menuItems={[["XLSX", () => runReport("xlsx")]].filter(i => i)}
        />
      </Div>
    )
  ]
});

const Layout = ({ reportGroups, goToModuleReports, runReport, handlers }) => (
  <Div>
    <Div
      color="white"
      py={7}
      px={7}
      fw={3}
      fs={6}
      style={{
        backgroundColor: "#813CD2"
      }}
    >
      Reports
    </Div>
    <Div py={5} px={7}>
      {reportGroups.map(group => {
        const Table = group.is_standard ? StandardReportTable : ReportTable;
        return (
          <Group>
            <GroupTitle
              isStandard={group.is_standard}
              goToReports={() => goToModuleReports(group.id)}
            >
              {group.name} Reports ({group.reports.length})
            </GroupTitle>
            {group.reports.length ? (
              <Table
                rowsData={group.reports.map(r => ({
                  ...r,
                  runReport: format => runReport({ reportId: r.id, format }),
                  showEditReportModal: () => handlers.showEditReportModal(r.id)
                }))}
              />
            ) : (
              <Empty>No reports have been created yet</Empty>
            )}
          </Group>
        );
      })}
    </Div>
  </Div>
);

export default decorate(Layout);
