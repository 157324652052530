import React, { Component } from "react";
import { get } from "lodash";

import Helpers from "utils/Global/Helpers";
import SignupActions from "actions/Global/SignupActions";
import View from "./View";

const KEYS = {
  email: {
    message: "Please enter your email.",
    validator: "isValidEmail"
  }
};

class Controller extends Component {
  constructor(props) {
    super(props);
    const { location } = props;

    this.state = {
      error: null,
      processing: false,
      email: get(location.query, "email", "")
    };
  }

  validateForm = async () => {
    return await this.validateForm();
  };

  handleResendConfirmation = async response => {
    // api creates the organization and user if user does not exist
    if (response.success) {
      window.location = `/signup/complete`;
    } else {
      throw new Error(
        `Re-send Confirmation Error: ${JSON.stringify(response)}`
      );
    }
  };

  validateForm = async () => {
    const keysToCheck = ["email"];

    // validate: we have valid values
    for (let i = 0; i < keysToCheck.length; i++) {
      const key = keysToCheck[i];
      if (!this[KEYS[key].validator](this.state[key])) {
        this.setState({
          processing: false,
          error: KEYS[keysToCheck[i]].message
        });
        return false;
      }
    }

    this.setState({ error: null });

    return true;
  };

  onFieldChange = e =>
    this.setState({ [e.target.name]: e.target.value, error: null });

  isValidValue = v => Boolean(v);
  isValidEmail = v => this.isValidValue(v) && Helpers.isValidEmail(v);

  onSubmit = async e => {
    e.preventDefault();

    if (this.state.processing || !this.validateForm()) return false;

    this.setState({ processing: true });

    const payload = {
      email: this.state.email
    };

    SignupActions.resendConfirmation(payload, response => {
      this.handleResendConfirmation(response);
    });
  };

  render() {
    const { error, processing, email } = this.state;

    return (
      <View
        {...{
          onSubmit: this.onSubmit,
          onFieldChange: this.onFieldChange,
          email,
          error,
          processing
        }}
      />
    );
  }
}

export default Controller;
