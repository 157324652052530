/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";
import getQuery from "redux/modules/modules/utils/get-query";

export default {
  getModuleFields: ({ credentials, moduleId, eventId }) =>
    new Promise((success, error) => {
      const query = getQuery({ eventId });

      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/modules/${moduleId}/fields${query || ""}`,
        credentials,
        success,
        error
      });
    }),
  saveIntakeForm: ({ credentials, data, permissionSetId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/account/permission-sets/${permissionSetId}/intake-form`,
        credentials,
        data,
        success,
        error
      });
    })
};
