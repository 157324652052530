import React, { Component } from "react";
import * as R from "ramda";
import View from "./View";
import { INTEGRATIONS } from "utils/integration-types";

class Controller extends Component {
  state = { saving: false };

  updateValue = (id, value) => {
    return this.setState(state => {
      state[id] = value;
      return state;
    });
  };

  contactSupport = () => {
    window.Intercom(
      "showNewMessage",
      `Hello! I would like to turn on the ${this.props.name} integration for my event. Thank you for your help.`
    );
    this.props.hideModal();
  };

  onSave = async () => {
    this.setState({
      error: null,
      saving: true
    });

    let result;
    let settings = this.props.authConfig.form.reduce((map, field) => {
      if (
        (field.type === "settings" || field.field_type === "settings") &&
        field.visible
      ) {
        map[field.name] = this.state[field.name];
      }
      return map;
    }, {});

    // @NOTE: Currently we handle only GBS integration in a separate way
    if (this.props.provider.id === INTEGRATIONS.gingerbreadshed.id) {
      // @NOTE: This is the format of the hack payload that needs to be submitted
      const payload = {
        payload: this.props.authConfig.form.reduce((map, field) => {
          if (field.type === "queryParam" && field.visible) {
            map[field.name] = this.state[field.name];
          }
          return map;
        }, {}),
        userId: this.props.user.id,
        eventId: this.props.eventDetails.id,
        settings,
        event_end_date: this.props.eventDetails.date_to
      };

      result = await this.props.getAuth2ndStepHack(
        this.props.provider,
        payload
      );
    } else {
      result = await this.props.getAuth2ndStep(this.props.provider, {
        userId: this.props.user.id,
        eventId: this.props.eventDetails.id,
        settings,
        event_end_date: this.props.eventDetails.date_to
      });
    }

    // handle error
    const errorCode = result ? result.errorCode || result.error_code : false;
    const errorDetails = result ? result.error_details : false;
    const hasError = Boolean(errorCode);

    if (hasError) {
      this.setState({
        error: {
          code: errorCode,
          details:
            errorDetails ||
            "We encountered some trouble connecting your integration."
        }
      });
    } else {
      this.props.onDone();
      this.props.hideModal();
    }

    this.setState({
      saving: false
    });

    /*
    // @NOTE: We need GBS to loosen their CORS/CORBS restrictions before we can
    // utilize this approach
    // client_id={OAuth_CLIENT_ID}&email={EMAIL_ADDRESS}&password={PASSW ORD}
    const qs = buildQueryString(this.state);
    console.log(`${this.props.authConfig.submit.form}?${qs}`);
    // window.location = `${this.props.authConfig.submit.form}${qs}`;
    this.setState({ saving: true });
    /*
    const res = await superagent.get(
      `${this.props.authConfig.submit.form}?${qs}`
    );

     const data = {
      payload: {
        Payload: res.body.Payload
      },
      user: 0,
      eventId: 0,
      settings: {
        gingerbreadEventId: "string"
      }
    };
    */
  };

  render() {
    const { name, authConfig, hideModal } = this.props;

    const fieldsWithHandlers = R.compose(
      R.map(field => ({
        id: field.name,
        type: field.field_type,
        label: field.label || field.name,
        onChange: value => this.updateValue(field.name, value),
        value: this.state[field.name] || ""
      })),
      R.sortBy(R.prop("order")),
      R.filter(R.prop("visible"))
    )(authConfig.form);

    return (
      <View
        {...{
          hideModal,
          name,
          fields: fieldsWithHandlers,
          onSave: this.onSave,
          saving: this.state.saving,
          contactSupport: this.contactSupport,
          error: this.state.error
        }}
      />
    );
  }
}

export default Controller;
