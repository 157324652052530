import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  selectedTabId: null,
  saving: true,
  type: "module",
  subscriptionUrl: "",
  moduleId: null,
  viewId: null,
  recordTypeId: null
};

const handlers = createHandlers({
  iniState,
  reducers: {
    init: (
      _,
      { payload: { type, moduleId, viewId, recordTypeId, formId, itemTypeId } }
    ) => ({
      type,
      moduleId,
      viewId,
      recordTypeId,
      formId,
      itemTypeId,
      selectedTabId: type === "schedule" ? "calendar" : "json"
    }),
    copySubscriptionUrl: R.identity,
    clickInput: R.identity
  },
  namespace: NAMESPACE
});

export default handlers;
