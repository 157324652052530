import { combineReducers } from "redux";
import { FETCH, RECEIVE, ERROR, CLEAR_ERROR } from "./constants";

const activities = (state = [], action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload;
    default:
      return state;
  }
};

const loading = (state = false, action) => {
  switch (action.type) {
    case FETCH:
      return true;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case ERROR:
      return action.payload;
    case CLEAR_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  activities,
  loading,
  error
});
