import * as R from "ramda";
import { createSelector } from "reselect";
import {
  TABS,
  SORTING,
  ROW_ACTIONS
} from "EventLight/FileRequests/List/constants";
import { ACTION_TYPES } from "ui-kit/PopoverMenu/constants";
import { getters } from "EventLight/FileRequests/List/model";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";

const sortingForms = (forms, activeSorting) => {
  if (activeSorting === SORTING.PENDING) {
    const sorting = R.descend(R.prop(activeSorting));

    return R.sort(sorting, forms);
  }
  const sorting = R.ascend(R.prop(activeSorting));
  return R.sort(sorting, forms);
};

export const getClosingThisWeek = createSelector(
  getters.data,
  getters.activeTab,
  getters.filter,
  getters.activeSorting,
  getUserId,
  (data, activeTab, filter, activeSorting, userId) => {
    if (activeTab === TABS.CLOSED) {
      return [];
    }
    const forms = R.compose(
      R.map(f => ({
        ...f,
        isManager: f.managers.includes(userId)
      })),
      R.propOr([], "closing_this_week")
    )(data);
    const orderedForms = sortingForms(forms, activeSorting);

    if (R.isEmpty(filter)) {
      return orderedForms;
    }

    return R.filter(
      form => R.contains(R.toLower(filter), R.toLower(form.name)),
      orderedForms
    );
  }
);

export const getAllFileRequests = createSelector(
  getters.data,
  getters.activeTab,
  getters.filter,
  getters.activeSorting,
  getUserId,
  (data, activeTab, filter, activeSorting, userId) => {
    const allForms = R.compose(
      R.map(f => ({
        ...f,
        isManager: f.managers.includes(userId)
      })),
      R.propOr([], "allItems")
    )(data);
    const allFormsTab =
      activeTab === TABS.ALL_FORMS
        ? allForms
        : R.filter(R.propEq("status", activeTab), allForms);

    const orderedForms = sortingForms(allFormsTab, activeSorting);

    if (R.isEmpty(filter)) {
      return orderedForms;
    }

    return R.filter(
      form => R.contains(R.toLower(filter), R.toLower(form.name)),
      orderedForms
    );
  }
);

export const getSortingLabel = createSelector(
  getters.activeSorting,
  activeSorting => {
    if (activeSorting === SORTING.ABC) {
      return "ABC";
    }
    if (activeSorting === SORTING.CREATED_AT) {
      return "Created Date";
    }
    return "Most Pending";
  }
);

export const getRowActions = R.always([
  {
    id: ROW_ACTIONS.EDIT,
    type: ACTION_TYPES.ITEM,
    name: "Edit",
    leftIcon: "settings"
  },
  {
    id: ROW_ACTIONS.DELETE,
    type: ACTION_TYPES.ITEM,
    name: "Delete",
    leftIcon: "delete",
    confirm: true
  }
]);

export const getPortalLink = createSelector(
  getEventDetails,
  eventDetails => {
    // eslint-disable-next-line no-underscore-dangle
    return `${window.__LENND_APP_URL__}/portal-login/${eventDetails.slug}/${eventDetails.uuid}`;
  }
);
