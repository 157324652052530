import React from "react";

import { connect } from "react-redux";

import { actions, getters } from "../index";
import { capitalizeFirst } from "utils/General";

import { getModules } from "../selectors";

import {
  Div,
  BigClearInput,
  SearchIcon,
  Text0,
  SmallCheckbox,
  Text2,
  EigthSizeDotIcon
} from "components/Base";

import SelectedModules from "./SelectedModules";

const decorate = connect(
  state => ({
    modulesFilter: getters.modulesFilter(state),
    modules: getModules(state)
  }),
  {
    setModulesFilter: actions.setModulesFilter,
    toggleModule: actions.toggleModule
  }
);

const Modules = ({
  modulesFilter,
  setModulesFilter,
  modules,
  toggleModule
}) => (
  <Div width={1} height={1}>
    <Div
      height={60}
      width={1}
      bb={1}
      bc="neutral1"
      display="row.flex-start.center"
      px={2}
    >
      <BigClearInput
        LeftIcon={SearchIcon}
        onChange={value => setModulesFilter(value)}
        width={1}
        continuous
        value={modulesFilter}
        placeholder="Filter modules..."
      />
    </Div>
    <Div
      display="row.flex-start.center"
      width={1}
      style={{ height: "calc(100% - 60px)" }}
    >
      <Div
        width={467}
        height={1}
        bg="neutral0"
        p={2}
        style={{ overflow: "auto" }}
      >
        {modulesFilter && modulesFilter.length && !modules.length ? (
          <Div p={5}>No modules matched your search.</Div>
        ) : !modules.length ? (
          <Div p={5}>No modules have been added yet.</Div>
        ) : null}
        {modules.map(q => (
          <Div
            width={1}
            bra={1}
            p={1}
            shadow={1}
            mb={2}
            bg={{
              default: "white",
              hover: "neutral1"
            }}
            display="row.flex-start.center"
            onClick={() =>
              toggleModule({
                moduleId: q.id,
                fieldId: q.lookup_field.id
              })
            }
          >
            <SmallCheckbox selected={q.selected} />
            <Div display="row.space-between.center" flex={1} pl={2}>
              <Div>
                <Text2 bold color="black">
                  {q.name}
                </Text2>
                <Div display="row.flex-start.center">
                  <Text0 color="neutral6" bold>
                    Lookup Field: {q.lookup_field.name}
                  </Text0>
                </Div>
              </Div>
            </Div>
          </Div>
        ))}
      </Div>
      <Div width={276} height={1}>
        <SelectedModules />
      </Div>
    </Div>
  </Div>
);

export default decorate(Modules);
