import React, { Component, Fragment } from "react";
import { Div, AddIcon, AvatarLabel } from "components/Base";
import ShareModal from "components/Global/CRM/Modals/Share";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { Page, Title } from "EventLight/Common/FieldLayout";

const STANDARD_INVOICE_RECORD_ID = "10ecc167-7f40-4d4f-b3d3-19f8bc40fc50";

const SectionTitle = ({ children }) => (
  <Div fw={3} fs={4} color="gray7" mb={3} bb={1} bc="neutral2" pb={2}>
    {children}
  </Div>
);

const SectionBody = ({ children }) => (
  <Div
    style={{
      marginBottom: 50
    }}
  >
    {children}
  </Div>
);

const FieldWrapper = ({ children }) => <Div mb={5}>{children}</Div>;

const Well = ({ children }) => (
  <Div bg="gray1" bra={2} p={3}>
    {children}
  </Div>
);

class Payments extends Component {
  state = { loading: true, notifyUsers: [] };

  async componentDidMount() {
    await Promise.all([this.fetchNotifications()]);

    this.setState({ loading: false });
  }

  onNotifyUsersChange = notifyUsers => this.setState({ notifyUsers });

  fetchNotifications = () =>
    this.props.getNotifications({
      moduleId: STANDARD_MODULE_IDS.orders.id,
      recordIds: [STANDARD_INVOICE_RECORD_ID],
      options: {
        eventId: this.props.eventId
      }
    });

  showNotificationseModal = () => {
    this.props.showModal({
      content: (
        <ShareModal
          moduleId={STANDARD_MODULE_IDS.orders.id}
          recordId={STANDARD_INVOICE_RECORD_ID}
          onDone={this.fetchNotifications}
          //
          title="Users to Notify"
          showRoles={false}
          allowNotifyingUsers={false}
          selectedLabel="Will receive payment notifications"
          emptyLabel="No users are selected to receive payment notifications."
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const { notifications } = this.props;

    return (
      <Page>
        <Title
          icon="attach_money"
          title="Invoice Settings"
          description="Specify who should be notified on new payments"
        />

        <Fragment>
          <Div px={8} pb={8} maxWidth={900}>
            <SectionTitle>Payment Notifications</SectionTitle>

            <SectionBody>
              <Div display="row.flex-start.flex-start">
                <Div display="column" flex={1}>
                  <FieldWrapper>
                    <Well>
                      <Div fw={3} color="gray7" mb={2}>
                        Users to Notify:
                      </Div>

                      {notifications.map(record => {
                        return (
                          <Div key={record.id}>
                            <AvatarLabel
                              py={2}
                              frontText={`${record.user.fname} ${record.user.lname}`}
                              backText={record.user.email}
                              photoURL={record.user.photo_url}
                            />
                          </Div>
                        );
                      })}

                      <Div
                        color="purple8"
                        display="row.flex-start.center"
                        onClick={this.showNotificationseModal}
                        mt={3}
                      >
                        <AddIcon mr={2} color="purple8" />
                        <Div fw={3}>Manage notifications</Div>
                      </Div>
                    </Well>
                  </FieldWrapper>
                </Div>
              </Div>
            </SectionBody>
          </Div>
        </Fragment>
      </Page>
    );
  }
}

export default Payments;
