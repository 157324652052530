import React from "react";
import * as R from "ramda";
import {
  Div,
  Text1,
  Text2,
  Text3,
  DragIcon,
  CloseIcon,
  Cards,
  DoneIcon
} from "components/Base";

import { actions } from "../model";
import {
  getSortableSelectedFields,
  getNonSortableSelectedFields
} from "../selectors";

import { connect } from "react-redux";

const decorate = connect(
  state => ({
    nonSortableFields: getNonSortableSelectedFields(state),
    fields: getSortableSelectedFields(state)
  }),
  {
    removeAll: actions.removeAll,
    toggleRequired: actions.toggleRequired,
    toggleVisible: actions.toggleVisible,
    reorderFields: actions.reorderFields
  }
);

const Field = ({
  name,
  required,
  toggleRequired,
  id,
  toggleVisible,
  draggable = true
}) => (
  <Div bra={1} bg="altB1" width={1} p={2} mt={2}>
    <Div display="row.space-between.center">
      <Div display="row.flex-start.center">
        {draggable && <DragIcon />}
        <Text2 ml={2} color="black" bold>
          {name}
        </Text2>
      </Div>

      <Div display="row.flex-start.center">
        <DoneIcon
          mr={2}
          color={{
            default: required ? "danger7" : "gray4",
            hover: "danger7"
          }}
          onClick={() => {
            if (draggable) {
              toggleRequired({ id, required });
            }
          }}
          tooltip={
            required ? "Field is mandatory" : "Toggle if field is required"
          }
        />

        {draggable && (
          <CloseIcon
            color={{
              default: "gray4",
              hover: "gray5"
            }}
            onClick={() => toggleVisible({ id, selected: true })}
          />
        )}
      </Div>
    </Div>
  </Div>
);

const FieldRows = Cards(Field, "SELECT_MODULE_FIELDS");

const SelectedFields = ({
  fields,
  removeAll,
  toggleRequired,
  toggleVisible,
  nonSortableFields,
  reorderFields
}) => (
  <Div height={1} style={{ overflowY: "auto" }} p={3} flex={1}>
    <Div display="row.space-between.center">
      <Text3 bold color="black">
        Selected Fields
      </Text3>
      <Text1 color="primary7" underline onClick={removeAll}>
        Remove All
      </Text1>
    </Div>
    <Div width={1} mt={1}>
      {R.map(
        field => (
          <Field
            key={field.id}
            {...field}
            toggleRequired={toggleRequired}
            toggleVisible={toggleVisible}
            draggable={false}
          />
        ),
        nonSortableFields
      )}
      <FieldRows
        cards={fields}
        onReorder={reorderedFields =>
          reorderFields({ reorderedFields, nonSortableFields })
        }
        toggleRequired={toggleRequired}
        toggleVisible={toggleVisible}
      />
    </Div>
  </Div>
);

export default decorate(SelectedFields);
