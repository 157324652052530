import React, { Component } from "react";
import * as R from "ramda";
import { find, sortBy, cloneDeep, get, sumBy } from "lodash";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import moment from "moment";
import PropTypes from "prop-types";
import Checkbox from "rc-checkbox";
import Footer from "components/Global/Editors/CredentialEditor/Footer";
import { NONE } from "utils/system-field-values";

const NONE_MEAL = { id: NONE.id, name: NONE.value, checked: false };

@CSSModules(css)
class CateringEditor extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedMeals: cloneDeep(this.formatValue(props).meals) };
  }

  @autobind
  wrapValue(meals) {
    const validatedMeals = this.getMealsWithRestrictions(
      meals,
      this.getRestrictedDays()
    );
    return {
      type: "catering",
      value: validatedMeals.some(
        m => m.dates.length || (m.id === NONE.id && m.checked)
      )
        ? {
            meals: validatedMeals || []
          }
        : undefined
    };
  }

  formatValue(props) {
    if (props.value && props.value.value) {
      if (props.value.value.meals.some(v => v.id === NONE.id)) {
        return props.value.value;
      }
      // inject none if not included
      return {
        ...props.value.value,
        meals: [NONE_MEAL, ...props.value.value.meals]
      };
    }

    const defaultState = [
      NONE_MEAL,
      ...props.meals.map(meal => ({
        id: meal.id,
        name: meal.value,
        dates: []
      }))
    ];

    return { meals: defaultState };
  }

  @autobind
  getRestrictedDays() {
    const eventDaysField = find(
      get(this.props.rowMetaData, "meta.columns", []),
      {
        type: "event-days"
      }
    );
    return eventDaysField
      ? get(this.props.rowMetaData[eventDaysField.id], "value")
      : null;
  }

  getMealsWithRestrictions = (meals, restrictedDays) =>
    meals.map(meal => {
      const dates = this.getDaysWithRestrictions(meal.dates, restrictedDays);
      return {
        ...meal,
        dates
      };
    });

  isMealToggled = mealId => {
    const meal = this.getMeal(mealId, this.state.selectedMeals);
    if (meal && meal.dates) {
      return (
        meal.dates.length &&
        meal.dates.length === this.availableDatesForMeal(meal.id).length
      );
    }
    return false;
  };

  availableDatesForMeal = (mealId, mealDates) =>
    this.getDaysWithRestrictions(
      this.getAvailableDates(mealId, this.props.mealDays, mealDates),
      this.getRestrictedDays()
    );

  getDaysWithRestrictions = (days = [], restrictedDays) => {
    // if we have no value / malformed value for event-days,
    // return without restrictions
    if (!restrictedDays || typeof restrictedDays !== "object") {
      return days;
    }

    return days.filter(({ date }) => {
      for (let i = 0; i < restrictedDays.length; i++) {
        if (
          moment
            .utc(date)
            .isBetween(
              restrictedDays[i].start,
              restrictedDays[i].end,
              "day",
              "[]"
            )
        ) {
          return true;
        }
      }
      return false;
    });
  };

  getAvailableDates(mealId, mealDays, existingDates = []) {
    return mealDays.filter(mealDay => mealDay.mealId === mealId).map(
      mealDay =>
        existingDates.find(existing => existing.date === mealDay.dayId) || {
          date: mealDay.dayId,
          quantity: 1
        }
    );
  }

  getMeal(mealId, mealList) {
    return mealList.find(meal => meal.id === mealId);
  }

  toggleNone = isNone => {
    if (isNone) {
      return this.addNone().then(() =>
        this.props.onChange(this.wrapValue(this.state.selectedMeals))
      );
    }
    return this.removeNone().then(() =>
      this.props.onChange(this.wrapValue(this.state.selectedMeals))
    );
  };

  removeNone = () =>
    new Promise(resolve => {
      this.setState(
        state => ({
          selectedMeals: state.selectedMeals.map(m => {
            if (m.id === NONE.id) {
              m.checked = false;
            }
            return m;
          })
        }),
        resolve
      );
    });

  addNone = () =>
    new Promise(resolve => {
      this.setState(
        state => ({
          selectedMeals: state.selectedMeals.map(m => {
            if (m.id === NONE.id) m.checked = true;
            m.dates = [];
            return m;
          })
        }),
        resolve
      );
    });

  removeSelectedDates = () =>
    this.setState(
      state => {
        state.selectedMeals = state.selectedMeals.map(m => {
          if (m.dates) m.dates = [];
          return m;
        });
        return state;
      },
      () => this.props.onChange(this.wrapValue(this.state.selectedMeals))
    );

  @autobind
  async toggleMeals(meal) {
    await this.removeNone();
    const newState = this.state.selectedMeals;
    const index = newState.indexOf(meal);
    if (this.isMealToggled(meal.id)) {
      newState[index].dates = [];
    } else {
      newState[index].dates = this.availableDatesForMeal(
        meal.id,
        newState[index].dates
      );
    }

    this.props.onChange(this.wrapValue(newState));
    this.setState({ selectedMeals: newState });
  }

  @autobind
  async toggleMeal(meal, dateId) {
    await this.removeNone();
    return this.setState(
      state => {
        const newState = state.selectedMeals;
        const mealObj = this.getMeal(meal.id, newState);
        const index = newState.indexOf(meal);

        if (mealObj.dates.some(d => d.date === dateId)) {
          const dateIndex = mealObj.dates.indexOf(
            mealObj.dates.find(d => d.date === dateId)
          );
          mealObj.dates.splice(dateIndex, 1);
        } else {
          mealObj.dates.push({ date: dateId, quantity: 1 });
        }

        newState[index] = mealObj;
        state.selectedMeals = newState;
        return state;
      },
      () => this.props.onChange(this.wrapValue(this.state.selectedMeals))
    );
  }

  mealAvailableForDate(mealId, dayId, mealDays) {
    return !!mealDays.find(
      mealDay => mealDay.mealId === mealId && mealDay.dayId === dayId
    );
  }

  handleQuantityChange = (quantity, meal, day) =>
    this.setState(
      state => {
        state.selectedMeals = state.selectedMeals.map(selected => {
          if (selected.id === meal.id) {
            selected.dates = selected.dates
              .map(d => {
                if (d.date === day) {
                  return { date: d.date, quantity: parseInt(quantity, 10) };
                }
                return d;
              })
              .filter(d => d.quantity > 0);
          }
          return selected;
        });
        return state;
      },
      () => this.props.onChange(this.wrapValue(this.state.selectedMeals))
    );

  quantitySelected = meals => sumBy(meals, m => sumBy(m.dates, "quantity"));

  render() {
    const {
      column,
      close,
      disabled,
      mealDays,
      meals,
      selectedDays,
      style
    } = this.props;

    const allowMultiple = R.pathOr(
      false,
      ["settings", "allowMultiple"],
      column
    );

    const selectedDaysWithRestrictions = this.getDaysWithRestrictions(
      selectedDays.map(d => ({ date: d })),
      this.getRestrictedDays()
    ).map(d => d.date);

    const sortedSelectedDays = sortBy(selectedDaysWithRestrictions, d => d);

    const mealList = meals.map(meal => {
      const mealFromState = this.getMeal(meal.id, this.state.selectedMeals);
      return (
        <label key={meal.id} className={css.mealLabelGroup} htmlFor={meal.id}>
          <div className={css.mealLabel}>{meal.value}</div>
          <Checkbox
            id={meal.id}
            checked={this.isMealToggled(meal.id)}
            disabled={disabled}
            onChange={() => this.toggleMeals(mealFromState)}
          />
        </label>
      );
    });

    const dateList = sortedSelectedDays.map(day => (
      <div key={day} styleName="date">
        <div styleName="dateDay">{moment.utc(day).format("ddd")}</div>
        <div styleName="dateNumber">{moment.utc(day).format("DD")}</div>
      </div>
    ));

    const checkboxGrid = sortedSelectedDays.map(day => {
      const mealCheckboxes = meals.map(meal => {
        const mealFromState = this.getMeal(meal.id, this.state.selectedMeals);
        const selected = mealFromState.dates.find(d => d.date === day);
        let input = this.mealAvailableForDate(meal.id, day, mealDays) ? (
          <Checkbox
            onChange={this.toggleMeal.bind(null, mealFromState, day)}
            checked={Boolean(selected && selected.quantity > 0)}
            disabled={disabled}
          />
        ) : (
          undefined
        );
        if (
          input &&
          Boolean(selected) &&
          allowMultiple &&
          selected.quantity > 0
        ) {
          input = (
            <input
              type="number"
              styleName="numberInput"
              min={0}
              onChange={e =>
                this.handleQuantityChange(e.target.value, mealFromState, day)
              }
              value={selected.quantity}
            />
          );
        }
        return (
          <label key={meal.id} styleName="checkbox">
            {input}
          </label>
        );
      });
      return (
        <li key={day} styleName="checkboxRow">
          {mealCheckboxes}
        </li>
      );
    });

    // Handle empty states when event-days field exists
    const eventDaysField =
      find(get(this.props.rowMetaData, "meta.columns", []), {
        type: "event-days"
      }) || {};
    const eventDaysTitle = eventDaysField.name || "Days on site";
    const eventDaysFieldData = eventDaysField
      ? this.props.rowMetaData[eventDaysField.id] || {}
      : {};

    if (eventDaysField && !eventDaysFieldData.value) {
      return (
        <div styleName="emptyState">
          <i className="material-icons" styleName="emptyStateIcon">
            reply
          </i>
          <span>
            Please fill out <span styleName="bold">{eventDaysTitle}</span>{" "}
            first.
          </span>
        </div>
      );
    }

    if (!dateList.length) {
      return (
        <div styleName="emptyState">
          <div>
            There are no meals available for the{" "}
            <span styleName="bold">{eventDaysTitle}</span> you have selected.
          </div>
        </div>
      );
    }

    return (
      <div styleName="cateringContainer">
        <div styleName="topSection">
          <div styleName="heading">On-Site</div>
          <div className="catering-meal-list-heading" styleName="mealList">
            {mealList}
          </div>
        </div>
        <div styleName="body" style={get(style, "body", {})}>
          <div styleName="dayList">{dateList}</div>
          <ul className="catering-checkbox-grid" styleName="checkboxGrid">
            {checkboxGrid}
          </ul>
        </div>
        <div styleName="bottomSection">
          <div>
            <strong>
              {this.quantitySelected(this.state.selectedMeals)} selected
            </strong>{" "}
            {this.quantitySelected(this.state.selectedMeals) > 0 ? (
              <span styleName="remove" onClick={this.removeSelectedDates}>
                Remove selected
              </span>
            ) : (
              ""
            )}
          </div>
          <div styleName="subText">
            If you would like to select a meal for a day not listed, go back and
            change the dates this crew member is onsite in the "{eventDaysTitle}"
            question above.
          </div>
        </div>
        <Footer
          onClose={close}
          isNone={get(
            this.state.selectedMeals.find(m => m.id === NONE.id),
            "checked"
          )}
          onSelectNone={this.toggleNone}
        />
      </div>
    );
  }
}

CateringEditor.defaultProps = {
  style: {
    body: {
      maxHeight: "40vh"
    }
  }
};

CateringEditor.propTypes = {
  close: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  mealDays: PropTypes.array.isRequired,
  meals: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  rowMetaData: PropTypes.object.isRequired,
  selectedDays: PropTypes.array.isRequired,
  style: PropTypes.object,
  styles: PropTypes.object,
  value: PropTypes.object
};

export default CateringEditor;
