import PropTypes from "prop-types";
import React, { Component } from "react";
import moment from "moment-timezone";
import * as R from "ramda";
import View from "./View";

const filterByIdMatches = (arrToFilter, arrOfMatches) =>
  R.filter(({ id }) => R.contains(id, arrOfMatches), arrToFilter);

const escapeRegex = str => str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");

const getGreetingTime = m => {
  let g;

  if (!m || !m.isValid()) {
    return g;
  }

  const splitAfternoon = 12;
  const splitEvening = 17;
  const currentHour = parseFloat(m.format("HH"));

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    g = "afternoon";
  } else if (currentHour >= splitEvening) {
    g = "evening";
  } else {
    g = "morning";
  }

  return g;
};

class Controller extends Component {
  constructor(props) {
    super(props);
    const initialState = {
      searchTerm: "",
      ...this.getOrgAndEventMatches(
        "",
        props.organizations,
        props.events,
        props.portals
      )
    };

    this.state = initialState;
  }

  redirected = false;

  async componentWillReceiveProps(nextProps) {
    this.setState({
      ...this.getOrgAndEventMatches(
        this.state.searchTerm,
        nextProps.organizations,
        nextProps.events,
        nextProps.portals
      )
    });

    // Go directly into event setup if there is only one event in draft mode
    if (
      nextProps.orgEvents &&
      nextProps.orgEvents.length === 1 &&
      nextProps.orgEvents[0].is_draft === true &&
      nextProps.orgEvents[0].is_light === false &&
      !this.redirected
    ) {
      this.redirected = true;
      this.props.router.push({
        pathname: `/event/${nextProps.orgEvents[0].id}/setup`
      });
    }
  }

  viewRefs = {};

  setRef = (id, ref) => {
    this.viewRefs[id] = ref;
  };

  scrollToRef = id => {
    if (this.viewRefs[id]) {
      this.viewRefs[id].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest"
      });
    }
  };

  getUserAccessLevels = () => this.props.getUserAccessLevels();

  goToOrg = orgId => {
    //this should be push of react-router-redux
    this.props.router.push(`/org-light/${orgId}/dashboard`);
  };

  archiveOrg = async orgId => {
    await this.props.updateOrganization({
      orgId,
      isArchived: true
    });

    return this.getUserAccessLevels();
  };

  getOrgAndEventMatches = (
    searchTerm,
    inputOrgs = [],
    inputEvents = [],
    inputPortals = []
  ) => {
    if (searchTerm === "") {
      return {
        orgMatches: R.map(R.prop("id"), inputOrgs),
        eventMatches: R.map(R.prop("id"), inputEvents),
        portalMatches: R.map(R.prop("id"), inputPortals)
      };
    }
    const myRegex = new RegExp(escapeRegex(R.toLower(searchTerm)), "g");
    const searchFilter = R.filter(el =>
      R.test(myRegex, R.toLower(R.join(" ", R.values(el))))
    );
    const getMatchIds = x => R.map(R.prop("id"), searchFilter(x));

    return {
      orgMatches: getMatchIds(inputOrgs),
      eventMatches: getMatchIds(inputEvents),
      portalMatches: getMatchIds(inputPortals)
    };
  };

  onSearch = searchTerm => {
    this.setState({
      searchTerm,
      ...this.getOrgAndEventMatches(
        searchTerm,
        this.props.organizations,
        this.props.events,
        this.props.portals
      )
    });
  };

  unpinEvent = async eventId => {
    await this.props.toggleStarredEvent({
      eventId,
      starred: false
    });

    return this.getUserAccessLevels();
  };

  pinEvent = async eventId => {
    await this.props.toggleStarredEvent({
      eventId,
      starred: true
    });

    return this.getUserAccessLevels();
  };

  addOrganization = () =>
    this.props.router.push({
      pathname: "/signup"
    });

  goToEvent = event => {
    window.location = event.is_light
      ? `/event-light/${event.id}/dashboard`
      : `/event/${event.id}/${event.is_draft ? "setup" : "home"}`;
  };

  goToPortal = ({ slug, uuid }) => {
    window.location = `/portals/${slug}/${uuid}`;
  };

  render() {
    const {
      user,
      organizations,
      orgEvents,
      events,
      allEvents,
      portals,
      isLenndAdmin
    } = this.props;
    const { orgMatches, eventMatches, portalMatches, searchTerm } = this.state;
    const greetingTime = getGreetingTime(moment(new Date()));
    const name = user.fname ? `, ${user.fname}.` : null;

    const orgs = R.map(
      ({ id, name, logo_image_url }) => ({
        id,
        name,
        photoURL: logo_image_url,
        goTo: e => {
          e.stopPropagation();
          this.goToOrg(id);
        },
        scrollTo: () => this.scrollToRef(id)
      }),
      filterByIdMatches(organizations, orgMatches)
    );

    const matchedEventOrgs = R.map(
      R.prop("org_id"),
      filterByIdMatches(orgEvents, eventMatches)
    );

    const orgsWithEvents = R.map(org => {
      const eventsToShow = org.events;

      // if there's only event matches, just show those events
      if (!R.contains(org.id, orgMatches)) {
        eventsToShow = R.filter(
          ({ id }) => R.contains(id, eventMatches),
          events
        );
      }

      return {
        id: org.id,
        name: org.name,
        unpinEvent: this.unpinEvent,
        pinEvent: this.pinEvent,
        photoURL: org.logo_image_url,
        setOrgRef: el => this.setRef(org.id, el),
        goToOrg: () => this.goToOrg(org.id),
        archiveOrg: () => this.archiveOrg(org.id),
        events: R.map(
          event => ({
            ...event,
            goToEvent: () => this.goToEvent(event)
          }),
          eventsToShow
        )
      };
    }, filterByIdMatches(organizations, [...matchedEventOrgs, ...orgMatches]));

    const pinnedEvents = R.map(
      event => ({
        ...event,
        goToEvent: () => this.goToEvent(event)
      }),
      R.filter(R.prop("is_event_starred"), allEvents)
    );

    const managedEvents = R.map(event => {
      return {
        id: event.id,
        name: event.name,
        organizationPhotoURL: event.org_logo_image_url,
        organizationName: event.org_name,
        organizationId: event.org_id,
        start: event.date_from,
        end: event.date_to,
        goToEvent: () => this.goToEvent(event)
      };
    }, filterByIdMatches(events, eventMatches));

    const portalEvents = R.map(portal => {
      return {
        id: portal.id,
        name: portal.name,
        organizationPhotoURL: portal.org_logo_image_url,
        organizationName: portal.org_name,
        organizationId: portal.org_id,
        start: portal.date_from,
        end: portal.date_to,
        goToEvent: () =>
          this.goToPortal({
            uuid: portal.uuid,
            slug: portal.slug
          })
      };
    }, filterByIdMatches(portals, portalMatches));

    return (
      <View
        {...{
          isSearching: Boolean(searchTerm.length),
          greetingTime,
          name,
          orgs,
          orgsWithEvents,
          onSearch: this.onSearch,
          addOrganization: isLenndAdmin ? this.addOrganization : null,
          pinnedEvents,
          managedEvents,
          portals: portalEvents,
          setRef: this.setRef,
          hasPortals: Boolean(portals.length),
          hasEvents: Boolean(events.length),
          scrollToEvents: () => this.scrollToRef("events"),
          scrollToPortals: () => this.scrollToRef("portals"),
          setEventsRef: el => this.setRef("events", el),
          setPortalsRef: el => this.setRef("portals", el)
        }}
      />
    );
  }
}

Controller.propTypes = {
  user: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  portals: PropTypes.array.isRequired,
  getPortals: PropTypes.func.isRequired,
  getUserAccessLevels: PropTypes.func.isRequired
};

export default Controller;
