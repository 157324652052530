import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Div } from "../Base/index";
import { FontIcon } from "../Level1/Icon";
import { get } from "lodash";
import chroma from "chroma-js";

import Select from "components/Global/ReactSelect/TetherSelect";
import RSelect from "@lennd/react-select";
import Creatable from "@lennd/react-select/lib/Creatable";
import AsyncCreatable from "@lennd/react-select/lib/AsyncCreatable";

const ArrowRenderer = ({ onMouseDown }) => (
  <Div display="row.center.center" onMouseDown={onMouseDown}>
    <FontIcon>expand_more</FontIcon>
  </Div>
);

const getSelectedValuesNames = (
  options,
  selectedValues = [],
  placeholder = "(Unknown value)"
) =>
  selectedValues.map(value => ({
    label: get(
      options.find(o => o.value === value || o.label === value),
      ["label"],
      placeholder
    ),
    value
  }));

class Dropdown extends PureComponent {
  render() {
    const {
      allowMultipleSelect,
      isMulti,
      closeMenuOnSelect = true,
      clearable = false,
      onBlur,
      onChange,
      options,
      placeholder = "Select...",
      selected,
      value, // use for older dropdowns
      usePortal,
      width,
      ...rest
    } = this.props;

    let values = [];
    const selectedValue = value || selected || [];

    if (typeof selectedValue !== "object") {
      // accept single non-object value
      values = getSelectedValuesNames(options, [selectedValue], placeholder);
    } else {
      // accept array of objects
      values = getSelectedValuesNames(options, selectedValue, placeholder);
    }

    // if not multiple select, get first value
    if (!allowMultipleSelect && !isMulti) {
      values = values[0];
    }

    const styles = {};
    if (usePortal) {
      styles.menuPortal = styles => ({
        ...styles,
        zIndex: 9999
      });
    }

    if (width) {
      styles.container = provided => ({
        ...provided,
        width
      });
    }

    // @NOTE: This helps with the race case on mobile w/ react-select
    const blurHandler = onBlur
      ? props => {
          setTimeout(function() {
            onBlur(props);
          }, 100);
        }
      : undefined;

    return (
      <Select
        arrowRenderer={ArrowRenderer}
        clearable={clearable}
        closeMenuOnSelect={closeMenuOnSelect}
        isMulti={isMulti || allowMultipleSelect}
        onBlur={blurHandler}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        value={values}
        menuPortalTarget={usePortal ? document.body : undefined}
        styles={styles}
        {...rest}
      />
    );
  }
}

Dropdown.displayName = "Dropdown";

Dropdown.defaultProps = {
  allowMultipleSelect: false,
  clearable: false,
  placeholder: "Select...",
  selected: [],
  onBlur: undefined
};

Dropdown.propTypes = {
  allowMultipleSelect: PropTypes.bool,
  clearable: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  placeholder: PropTypes.string,
  selected: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    ),
    PropTypes.string,
    PropTypes.number
  ])
};

const sharedStaticProps = {
  menuPortalTarget: document.body,
  className: "basic-multi-select",
  classNamePrefix: "select",
  styles: {
    menuPortal: styles => ({
      ...styles,
      zIndex: 9999
    }),
    control: styles => ({
      ...styles,
      minHeight: 33
    }),
    dropdownIndicator: styles => ({
      ...styles,
      padding: 4
    }),
    option: (styles, { data, isDisabled, isSelected, isFocused }) => {
      const color = chroma(data.color || "black");
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected || isFocused
          ? color.alpha(0.1).css()
          : null,
        color: isDisabled ? "#ccc" : color.css(),
        cursor: isDisabled ? "not-allowed" : "default"
      };
    },
    singleValue: (styles, { data }) => {
      const color = chroma(data.color || "black");
      return {
        ...styles,
        padding: "3px 6px",
        color: color.css(),
        borderRadius: 2,
        backgroundColor: color.alpha(0.1).css()
      };
    },
    multiValue: (styles, { data }) => {
      const color = chroma(data.color || "white");
      return {
        ...styles,
        backgroundColor: color.alpha(0.1).css()
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: data.color || styles.color
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color || styles.color,
      ":hover": {
        backgroundColor: data.color || styles.color,
        color: "white"
      }
    })
  }
};
const SelectBase = ({
  isCreatable,
  isAsyncCreatable,
  isMulti,
  isClearable,
  isDisabled,
  options,
  cacheOptions,
  defaultOptions,
  loadOptions,
  onChange,
  value,
  placeholder,
  menuPlacement = "bottom"
}) => {
  let Comp = RSelect;
  if (isCreatable) {
    Comp = Creatable;
  } else if (isAsyncCreatable) {
    Comp = AsyncCreatable;
  }
  return (
    <Comp
      isClearable={isClearable}
      isDisabled={isDisabled}
      isMulti={isMulti}
      onChange={onChange}
      options={options}
      cacheOptions={cacheOptions}
      defaultOptions={defaultOptions}
      loadOptions={loadOptions}
      value={value}
      placeholder={placeholder}
      menuPlacement={menuPlacement}
      {...sharedStaticProps}
    />
  );
};
SelectBase.displayName = "SelectBase";
SelectBase.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
      color: PropTypes.string
    })
  )
};

const SimpleSelect = ({
  isDisabled,
  isClearable,
  onChange,
  options,
  value
}) => (
  <SelectBase
    isDisabled={isDisabled}
    isClearable={isClearable}
    onChange={({ value }) => onChange(value)}
    value={{ value, label: value }}
    options={options.map(o => ({ value: o, label: o }))}
  />
);
SimpleSelect.displayName = "SimpleSelect";

const MultiSelect = props => <SelectBase isMulti {...props} />;
MultiSelect.displayName = "MultiSelect";

const CreatableSelect = ({ onChange, onCreate, ...props }) => {
  const mode = props.isAsyncCreatable ? "isAsyncCreatable" : "isCreatable";

  const handleChange = (newValue, actionMeta) => {
    // @NOTE: this shouldn't be called because `onCreateOption` is being passed, but just in case
    if (actionMeta.action === "create-option") {
      if (Array.isArray(newValue)) {
        // if is multiple, just send the created value to onCreate
        return onCreate(newValue[newValue.length - 1].value);
      }
      return onCreate(newValue.value);
    }
    return onChange(newValue);
  };
  return (
    <SelectBase
      {...{
        onChange: handleChange,
        onCreateOption: onCreate,
        [mode]: true,
        ...props
      }}
    />
  );
};

CreatableSelect.displayName = "CreatableSelect";
CreatableSelect.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired
};

const AsyncCreatableSelect = ({ onChange, onCreate, ...props }) => (
  <CreatableSelect
    {...{
      isAsyncCreatable: true,
      onChange,
      onCreate,
      ...props
    }}
  />
);

export {
  Dropdown,
  SimpleSelect,
  SelectBase as Select,
  CreatableSelect,
  AsyncCreatableSelect,
  MultiSelect
};
