import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "Organizations/CloneEventModal/model";
import {
  Div,
  SuperOutlineButton,
  SuperFilledButton,
  Radio,
  DatePicker,
  BigOutlineInput,
  CalendarIcon,
  SmallCheckbox,
  InfoIcon
} from "components/Base";
import { PAGES } from "Organizations/CloneEventModal/constants";
import { getDayMap } from "Organizations/CloneEventModal/selectors";
import moment from "moment";
import DaySelector from "components/Event/Settings/EventSettings/EventDays/DaySelector";
import MarkDaysAs from "components/Event/Settings/EventSettings/EventDays/MarkDaysAs";

import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

const decorate = connect(
  state => ({
    selectedEvent: getters.selectedEvent(state),
    eventName: getters.newEventName(state),
    eventStartDate: getters.newEventStartDate(state),
    eventEndDate: getters.newEventEndDate(state),
    dayGroups: getters.dayGroups(state),
    eventDayMap: getDayMap(state),
    contentType: getters.contentType(state),
    selectedDays: getters.selectedDays(state),
    includeAllRecordData: getters.includeAllRecordData(state),
    includeAllOrders: getters.includeAllOrders(state),
    includeAllFormSubmissions: getters.includeAllFormSubmissions(state),
    showAdvancedCloningOptions: selectFeatureFlag(
      flags.SHOW_ADVANCED_CLONING_OPTIONS.NAME
    )(state)
  }),
  {
    goToSelectEvent: () => actions.setSelectedPage(PAGES.SELECT_EVENT),
    goToSelectContent: () => actions.setSelectedPage(PAGES.SELECT_CONTENT),
    updateEventName: actions.setNewEventName,
    updateEventStartDate: actions.setNewEventStartDate,
    updateEventEndDate: actions.setNewEventEndDate,
    selectDayGroup: actions.selectDayGroup,
    selectDayGroupDay: actions.selectDayGroupDay,
    selectContentType: actions.setContentType,
    updateIncludeAllRecordData: actions.setIncludeAllRecordData,
    updateIncludeAllOrders: actions.setIncludeAllOrders,
    updateIncludeAllFormSubmissions: actions.setIncludeAllFormSubmissions,
    cloneEvent: actions.cloneEvent
  }
);

const Row = ({ children }) => (
  <Div
    display="row.flex-start.flex-start"
    mb={6}
    width={1}
    style={{
      flexShrink: 0
    }}
  >
    {children}
  </Div>
);
const Column = ({ children }) => <Div flex={1}>{children}</Div>;
const Label = ({ children }) => (
  <Div
    width={150}
    fw={3}
    color="gray7"
    style={{
      flexShrink: 0
    }}
  >
    {children}
  </Div>
);

const DateInput = ({ onClick, date }) => (
  <Div
    bra={1}
    onClick={onClick}
    width={260}
    height={40}
    b={1}
    bc="gray3"
    display="row.flex-start.center"
    bg={{
      default: "white",
      hover: "neutral0"
    }}
  >
    <Div fs={2} color="gray7" py={2} px={4} flex={1}>
      {moment(date).format("ddd, MMM D, YYYY")}
    </Div>
    <Div
      width={40}
      bc="gray3"
      bl={1}
      display="row.center.center"
      height={1}
      bg="white"
    >
      <CalendarIcon size={20} />
    </Div>
  </Div>
);

const CloneEvents = ({
  selectedEvent,
  goToSelectEvent,
  goToSelectContent,
  updateEventName,
  updateEventStartDate,
  updateEventEndDate,
  eventName,
  eventStartDate,
  eventEndDate,
  eventDayMap,
  dayGroups,
  selectDayGroup,
  selectDayGroupDay,
  selectContentType,
  contentType,
  selectedDays,
  cloneEvent,
  includeAllRecordData,
  includeAllOrders,
  includeAllFormSubmissions,
  updateIncludeAllRecordData,
  updateIncludeAllFormSubmissions,
  updateIncludeAllOrders,
  handlers,
  showAdvancedCloningOptions
}) => (
  <Div display="column">
    <Div
      height={419}
      width={1}
      display="column.flex-start.center"
      px={5}
      pt={8}
      pl={18}
      style={{
        overflowY: "auto"
      }}
    >
      <Row>
        <Label>Event Name</Label>
        <Column>
          <BigOutlineInput
            continuous
            value={eventName}
            onChange={updateEventName}
            width={260}
          />
        </Column>
      </Row>

      {showAdvancedCloningOptions ? (
        <Row>
          <Label>Content</Label>
          <Column>
            <Div display="column">
              <Div
                display="row.flex-start.center"
                onClick={() => selectContentType("everything")}
                mb={contentType === "everything" ? 0 : 2}
                color="gray7"
              >
                <Radio
                  selected={contentType === "everything"}
                  mr={2}
                  size={24}
                />
                <Div>Clone everything</Div>
              </Div>
              {contentType === "everything" ? (
                <>
                  <Div
                    display="row.flex-start.center"
                    ml={6}
                    mt={2}
                    mb={2}
                    color="gray7"
                    onClick={e => {
                      e.stopPropagation();
                      updateIncludeAllRecordData(!includeAllRecordData);
                    }}
                  >
                    <SmallCheckbox
                      onClick={() => {}}
                      selected={includeAllRecordData}
                      mr={2}
                    />
                    <Div display="row.flex-start.center">
                      <Div>Include all record data (clone may take longer)</Div>
                      <InfoIcon
                        ml={2}
                        color="gray5"
                        tooltip="Will clone over all database records from the selected event"
                      />
                    </Div>
                  </Div>

                  {includeAllRecordData ? (
                    <Div
                      display="row.flex-start.center"
                      mt={2}
                      mb={2}
                      ml={12}
                      color="gray7"
                      onClick={e => {
                        e.stopPropagation();
                        updateIncludeAllOrders(!includeAllOrders);
                      }}
                    >
                      <SmallCheckbox
                        onClick={() => {}}
                        selected={includeAllOrders}
                        mr={2}
                      />
                      <Div display="row.flex-start.center">
                        <Div>Include all order data</Div>
                        <InfoIcon
                          ml={2}
                          color="gray5"
                          tooltip="Will clone over all orders from the selected event"
                        />
                      </Div>
                    </Div>
                  ) : null}

                  {includeAllRecordData ? (
                    <Div
                      display="row.flex-start.center"
                      mt={2}
                      mb={2}
                      ml={12}
                      color="gray7"
                      onClick={e => {
                        e.stopPropagation();
                        updateIncludeAllFormSubmissions(
                          !includeAllFormSubmissions
                        );
                      }}
                    >
                      <SmallCheckbox
                        onClick={() => {}}
                        selected={includeAllFormSubmissions}
                        mr={2}
                      />
                      <Div display="row.flex-start.center">
                        <Div>Include all form submissions</Div>
                        <InfoIcon
                          ml={2}
                          color="gray5"
                          tooltip="Will clone over all form submissions from the selected event"
                        />
                      </Div>
                    </Div>
                  ) : null}
                </>
              ) : null}
            </Div>
            <Div
              display="row.flex-start.center"
              onClick={() => {
                selectContentType("specific");
                updateIncludeAllRecordData(false);
              }}
              color="gray7"
            >
              <Radio selected={contentType === "specific"} mr={2} size={24} />
              <Div>Select specific content</Div>
            </Div>
          </Column>
        </Row>
      ) : null}

      <Row>
        <Label>New Start Date</Label>
        <Column>
          <DatePicker
            wrapperProps={{
              width: 260
            }}
            defaultValue={eventStartDate}
            onChange={updateEventStartDate}
            labelProps={{ date: eventStartDate }}
            Label={DateInput}
          />
          <Div mt={2} fs={1} color="gray5">
            Previously:{" "}
            {moment(selectedEvent.date_from).format("ddd, MMM D, YYYY")}
          </Div>
        </Column>
      </Row>

      <Row>
        <Label>New End Date</Label>
        <Column>
          <DatePicker
            wrapperProps={{
              width: 260
            }}
            defaultValue={eventEndDate}
            onChange={updateEventEndDate}
            labelProps={{ date: eventEndDate }}
            Label={DateInput}
          />
          <Div mt={2} fs={1} color="gray5">
            Previously:{" "}
            {moment(selectedEvent.date_to).format("ddd, MMM D, YYYY")}
          </Div>
        </Column>
      </Row>

      <Row>
        <Label>Set Date Labels</Label>
        <Column>
          <Div display="row.flex-start.flex-start">
            <Div display="row" mr={5}>
              <DaySelector
                startDate={eventStartDate}
                endDate={eventEndDate}
                dayMap={eventDayMap}
                handleDayClick={selectDayGroupDay}
              />
            </Div>
            <Div display="row">
              <MarkDaysAs
                groups={dayGroups}
                selectGroup={selectedDays.length ? selectDayGroup : undefined}
              />
            </Div>
          </Div>
        </Column>
      </Row>
    </Div>
    <Div
      height={86}
      bt={1}
      bc="neutral2"
      width={1}
      display="row.space-between.center"
      px={5}
      style={{
        flexShrink: 0
      }}
    >
      <SuperOutlineButton onClick={goToSelectEvent}>Back</SuperOutlineButton>
      {contentType === "specific" ? (
        <SuperFilledButton onClick={goToSelectContent} bg="altB5">
          Next: Select Content to Clone
        </SuperFilledButton>
      ) : (
        <SuperFilledButton
          onClick={() => {
            cloneEvent();
            handlers.isCloning(true);
          }}
          bg="altB5"
        >
          Clone Event
        </SuperFilledButton>
      )}
    </Div>
  </Div>
);

export default decorate(CloneEvents);
