import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import BookmarkIcon from "components/Atoms/BookmarkIcon";

const SwimlaneHeader = ({
  count = 0,
  iconColor = "#D3D3D3",
  title,
  singular,
  plural,
  IconMenu,
  ...props
}) => (
  <div styleName="headerContainer">
    {iconColor ? (
      <div styleName="icon">
        <BookmarkIcon color={iconColor} />
      </div>
    ) : (
      ""
    )}
    <div styleName="labelContainer">
      <div styleName="header" title={title}>
        {title}
      </div>
      <div styleName="submissionCount">{`${count} ${
        count !== 1 ? plural : singular
      }`}</div>
    </div>
    <div>{IconMenu}</div>
  </div>
);

SwimlaneHeader.propTypes = {};

export default CSSModules(SwimlaneHeader, css);
