import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import PillButton from "components/Global/CRM/PillButton";
import { HalfSizeDotIcon } from "components/Base";

const AccountContact = ({
  name,
  params = {},
  id,
  isPrimaryContact = false,
  canLogin = false
}) => (
  <div styleName="contactContainer">
    {canLogin ? <HalfSizeDotIcon mr={1} color={"primary7"} size={16} /> : ""}
    {isPrimaryContact ? <i className="material-icons star">star</i> : ""}
    <Link
      to={`/${
        params.orgId
          ? `${
              window.location.pathname.includes("light")
                ? "org-light"
                : "organization"
            }/${params.orgId}`
          : `${
              window.location.pathname.includes("light")
                ? "event-light"
                : "event"
            }/${params.eventId}`
      }/contact/${id}?viaAccount=${params.recordId}`}
      className={css.name}
    >
      {name}
    </Link>
    <div styleName="preview">
      <PillButton
        title="Preview"
        linkTo={`/${
          params.orgId
            ? `${
                window.location.pathname.includes("light")
                  ? "org-light"
                  : "organization"
              }/${params.orgId}`
            : `${
                window.location.pathname.includes("light")
                  ? "event-light"
                  : "event"
              }/${params.eventId}`
        }/contact/${id}?viaAccount=${params.recordId}`}
      />
    </div>
  </div>
);

AccountContact.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  params: PropTypes.shape({
    accountId: PropTypes.string,
    eventId: PropTypes.string
  }).isRequired,
  styles: PropTypes.object
};

export default CSSModules(AccountContact, css);
