import React from "react";
import { Div } from "components/Base";

export const Icon = ({ children }) => (
  <Div
    display="row.center.center"
    bg="#fff"
    height={38}
    width={38}
    style={{ marginRight: 10, flexShrink: 0 }}
    pill
  >
    {children}
  </Div>
);

export default Icon;
