import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import ReactTable from "react-table";
import "react-table/react-table.css";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table-hoc-fixed-columns/lib/styles.css";
import {
  Div,
  Text0,
  Text1,
  DownArrowIcon,
  RightArrowIcon,
  CloseIcon,
  CheckIcon
} from "components/Base";
import { actions } from "ui-kit/AccessGridTable/model";
import {
  getColumns,
  getRows,
  getRowsLength
} from "ui-kit/AccessGridTable/selectors";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

const decorate = connect(
  (state, props) => ({
    columns: getColumns(state, props),
    rows: getRows(state, props),
    rowsLength: getRowsLength(state, props)
  }),
  {
    toggleZoneItemAssociations: actions.toggleZoneItemAssociations,
    deselectRow: actions.deselectMultipleZoneItem,
    selectMultipleZoneItem: actions.selectMultipleZoneItem
  }
);

const Table = ({
  columns,
  rows,
  style,
  toggleZoneItemAssociations,
  deselectRow,
  selectMultipleZoneItem,
  rowsLength
}) => {
  return (
    <ReactTableFixedColumns
      showPagination={false}
      resizable={false}
      data={rows}
      style={style}
      pageSize={rowsLength}
      sortable={false}
      className="ReactTable-record"
      columns={[
        {
          Header: (
            <Div fw={3} display="row.space-between.center" height={40} ba={0}>
              <Div display="row.flex-start.center">
                <DownArrowIcon size={10} color="black" />
                <Text0 color="black" bold>
                  PASS TYPES
                </Text0>
              </Div>
              <Div display="row.flex-start.center">
                <Text0 color="black" bold>
                  ZONES
                </Text0>
                <RightArrowIcon size={10} color="black" />
              </Div>
            </Div>
          ),
          accessor: "passes",
          fixed: "left",

          width: 200,
          Cell: ({ value, original }) => {
            const values = R.compose(
              R.map(({ variantId, zoneId }) => ({ variantId, zoneId })),
              R.values,
              R.omit(["passes"])
            )(original);

            return (
              <Div
                display="row.flex-start.center"
                className="hoverContainer"
                height={value.isTitle ? 16 : 28}
              >
                <Div display="row.flex-start.center" truncate>
                  <Div
                    fs={value.isTitle ? 2 : 3}
                    fw={3}
                    color={value.isTitle ? "neutral7" : "black"}
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden"
                    }}
                  >
                    {R.propOr("-", "name", value)}
                  </Div>
                  {!value.isTitle && (
                    <Div display="row.flex-start.center" height={16} ml={1}>
                      <Div
                        display="row.flex-start.center"
                        height={16}
                        className="showOnHover"
                      >
                        <CheckIcon
                          onClick={() => selectMultipleZoneItem({ values })}
                          size={16}
                          color={{ default: "neutral5", hover: "altA5" }}
                          tooltip="Select all"
                        />
                        <CloseIcon
                          bold
                          onClick={() => deselectRow({ values })}
                          size={16}
                          color={{ default: "neutral5", hover: "danger5" }}
                          tooltip="Remove all"
                        />
                      </Div>
                    </Div>
                  )}
                </Div>
              </Div>
            );
          }
        },
        ...R.map(column => {
          const values = R.compose(
            R.map(({ variantId, zoneId }) => ({ variantId, zoneId })),
            R.values,
            R.map(R.propOr({}, column.id)),
            R.filter(row => !R.pathOr(false, ["passes", "isTitle"], row))
          )(rows);

          return {
            Header: (
              <Div
                height={40}
                display="column.flex-start.center"
                className="hoverContainer"
              >
                <Div>{column.name}</Div>
                <Div display="row.flex-start.center" height={16}>
                  <Div
                    display="row.flex-start.center"
                    height={16}
                    className="showOnHover"
                  >
                    <CheckIcon
                      onClick={ev => {
                        ev.stopPropagation();
                        selectMultipleZoneItem({ values });
                      }}
                      size={16}
                      color={{ default: "neutral5", hover: "altA5" }}
                      tooltip="Select all"
                    />
                    <CloseIcon
                      bold
                      onClick={ev => {
                        ev.stopPropagation();
                        deselectRow({ values });
                      }}
                      size={16}
                      color={{ default: "neutral5", hover: "danger5" }}
                      tooltip="Remove all"
                    />
                  </Div>
                </Div>
              </Div>
            ),
            accessor: column.id,
            width: 60,
            Cell: cellInfo => {
              const {
                value: { hasValue, columnName, zoneId, variantId }
              } = cellInfo;
              const isTitle = R.pathOr(
                false,
                ["original", "passes", "isTitle"],
                cellInfo
              );
              return !isTitle ? (
                <Div display="row.center.center">
                  <Div
                    width={38}
                    height={28}
                    bra={1}
                    display="row.center.center"
                    bc={hasValue ? "black" : "neutral2"}
                    ba={2}
                    bg={hasValue ? "black" : "transparent"}
                    onClick={() =>
                      toggleZoneItemAssociations({
                        zoneId,
                        variantId,
                        hasValue
                      })
                    }
                  >
                    {hasValue ? (
                      <Text1 bold color="white">
                        {columnName}
                      </Text1>
                    ) : (
                      <CloseIcon color="neutral2" />
                    )}
                  </Div>
                </Div>
              ) : (
                <Div width={28} height={16} />
              );
            }
          };
        }, columns)
      ]}
    />
  );
};

export default decorate(Table);
