import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "components/Event/Accounts/Account/People/styles.scss";

import Card from "components/Global/CRM/Card";
import Header from "components/Global/CRM/Card/Header";
import IconTitle from "components/Global/CRM/Card/IconTitle";
import Title from "components/Global/CRM/Card/Title";

import ActionsBar from "components/Event/Accounts/Account/People/ActionsBar";
import PaginationBar from "components/Global/ReactTable/Pagination/Muted";
import PaginationManager from "components/Global/ReactTable/Pagination/Manager";
import getRowsSelectedHeader from "components/Global/ReactTable/TableHeaders/RowsSelected";
import getCheckboxColumn from "components/Global/ReactTable/Columns/Checkbox";
import ReactTable from "react-table";
import resolveOnClickPrimaryField from "components/Global/ReactTable/CellFormatters/PrimaryField/resolveOnClick";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import getColumn from "components/Global/ReactTable/utils/get-column";

const COLOR = "#00A7F0";
const FIELDS = [
  {
    id: "ea87516b-d6ca-40fc-9b17-e93b128f587b",
    name: "Title",
    internal_name: "title",
    type: "text",
    settings: {},
    source: "standard",
    is_primary_field: true,
    order: 0,
    width: 350,
    accessor: data =>
      data.values["ea87516b-d6ca-40fc-9b17-e93b128f587b"] ||
      data.values["8fc10190-5c45-42de-8c9b-6a9c2e003b36"]
  },
  {
    id: "d87fd0b2-a84e-4f70-92be-864b457115dc",
    name: "Description",
    internal_name: "description",
    type: "textarea",
    settings: {},
    source: "standard",
    order: 1
  },
  {
    id: "8fc10190-5c45-42de-8c9b-6a9c2e003b36",
    name: "Filename",
    internal_name: "filename",
    type: "text",
    settings: {},
    source: "standard",
    order: 2
  }
  /*
  {
    id: "f26fe10c-993d-485f-aa85-110dd748a66a",
    name: "URL",
    internal_name: "url",
    type: "text",
    settings: {},
    source: "standard",
    order: 3
  },
  {
    id: "250b390a-8307-4130-bb12-3df6e8ea0af3",
    name: "File Type",
    internal_name: "mimetype",
    type: "text",
    settings: {},
    source: "standard",
    order: 4
  }
  */
];

@CSSModules(css)
class DocumentsCard extends Component {
  getHelpers = () => ({
    showModal: this.props.showModal
  });

  getColumns = () => {
    const fieldDefinitions = FIELDS.map(field => {
      const fieldData = { ...field };
      if (fieldData.is_primary_field) {
        fieldData.onClick = resolveOnClickPrimaryField({
          orgId: this.props.orgId,
          eventId: this.props.eventId,
          moduleId: STANDARD_MODULE_IDS.files.id,
          helpers: this.getHelpers()
        });
      }
      fieldData.visible = true;
      fieldData.order = field.order;
      return fieldData;
    });
    fieldDefinitions.sort((a, b) => a.order - b.order);
    fieldDefinitions.unshift({
      ...getCheckboxColumn({
        isFiller: true
      })
    });
    return fieldDefinitions;
  };

  getRowMetaData = (rowData, column) => ({
    ...rowData,
    meta: {
      orgId: this.props.orgId,
      eventId: this.props.eventId,
      rowId: rowData.id,
      columnId: column.id,
      columnSettings: column.settings
    },
    helpers: this.getHelpers()
  });

  render() {
    const { recordName, documents } = this.props;
    const countOfRecords = documents.length;
    const pageSize = countOfRecords < 10 ? countOfRecords || 2 : 10;

    return (
      <Card color={COLOR}>
        <Header>
          <Title>
            <IconTitle title={`${recordName}'s Documents`} color={COLOR}>
              <i className={`material-icons ${css.icon}`}>attach_file</i>
            </IconTitle>
          </Title>
        </Header>
        <div>
          <PaginationManager countOfRows={documents.length} pageSize={pageSize}>
            <ReactTable
              className="ReactTable-record"
              noDataText="No documents has been added to this portal"
              columns={this.getColumns().map(column =>
                getColumn({
                  column,
                  getRowMetaData: this.getRowMetaData
                })
              )}
              data={documents}
              showPageJump={false}
              showPageSizeOptions={false}
              PaginationComponent={PaginationBar}
              rowsText={countOfRecords === 1 ? "document" : "documents"}
              TheadComponent={getRowsSelectedHeader({
                ActionsComponent: ActionsBar,
                selectedRows: [],
                helpers: {
                  selectAll: () => {},
                  isSelectAllChecked: () => {},
                  deleteAccounts: () => {},
                  edit: () => {}
                }
              })}
            />
          </PaginationManager>
        </div>
      </Card>
    );
  }
}

DocumentsCard.propTypes = {
  eventId: PropTypes.string.isRequired,
  account: PropTypes.object.isRequired,
  documents: PropTypes.array.isRequired
};

export default DocumentsCard;
