import * as R from "ramda";
import { fork, all, put, select, call } from "redux-saga/effects";
import { apiCall } from "App/Data/sagas";
import { registerError } from "redux/modules/errors/actions";

import { actions } from "../model";
import { actions as TableActions } from "ui-kit/Table/model";
import { actions as PaginationActions } from "ui-kit/PaginationBar";

import { eventId as getPortalEventId } from "redux/modules/portal/selectors";
import { portalUser as getPortalUser } from "redux/modules/portal/user/selectors";

import executeActions from "./executeActions";

const fetch = function*() {
  try {
    const eventId = yield select(getPortalEventId);
    const portalUser = yield select(getPortalUser);
    const isViewingAsAccount = R.pathEq(["active_view", "type"], "account")(
      portalUser
    );
    const accountId = isViewingAsAccount
      ? R.path(["active_view", "id"], portalUser)
      : null;

    const { payload } = yield call(apiCall, {
      method: "get",
      url: `/hub/event/${eventId}/account/${accountId}/home`
    });

    yield put(
      actions.config({
        title: payload.title,
        description: payload.description,
        backgroundImageUrl: payload.background_image_url,
        alerts: payload.alerts,
        tasks: payload.tasks.tasks,
        files: payload.files,
        pages: payload.pages,
        mediaHub: payload.media_hub,
        progress: payload.tasks.percentage_complete
      })
    );
    const submissions = R.filter(R.propEq("type", "form"), payload.tasks.tasks);
    yield all([
      ...R.map(
        submission =>
          put(
            TableActions.setData(
              {
                canEditCells: false,
                columns: R.propOr([], "columns", submission),
                rows: R.propOr([], "submissions", submission),
                columnWidths: submission.preferences.field_widths
              },
              {
                meta: {
                  instanceId: submission.id
                }
              }
            )
          ),
        submissions
      )
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while loading portal"
        }
      ])
    );
  }

  yield put(
    PaginationActions.setPagination(
      { pageSize: 5 },
      { meta: { instanceId: "alerts" } }
    )
  );
  yield put(
    PaginationActions.setPagination(
      { pageSize: 5 },
      { meta: { instanceId: "files" } }
    )
  );
  yield put(
    PaginationActions.setPagination(
      { pageSize: 5 },
      { meta: { instanceId: "pages" } }
    )
  );
};

const rootSaga = function*() {
  yield all([fork(fetch), fork(executeActions)]);
};

export default rootSaga;
