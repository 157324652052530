import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SendRecordForm from "./SendRecordForm";

import { getModule } from "redux/modules/modules/module/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";

import * as EventSelectors from "redux/modules/event/selectors";
import * as ModuleSelectors from "redux/modules/modules/module/selectors";
import * as UserSelectors from "redux/modules/user/selectors";

function mapStateToProps(state, props) {
  return {
    eventDetails: EventSelectors.eventDetails(state),
    isFetching: ModuleSelectors.isFetching(state, props.moduleId),
    module: ModuleSelectors.moduleDetails(state, props.moduleId),
    forms: ModuleSelectors.forms(state, props.moduleId),
    user: UserSelectors.user(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getModule,
      showSnackbar
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SendRecordForm);
