import getColumnTypes from "components/Event/Settings/Module/utils/get-column-types";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

export const NAMESPACE = "AddQuestionModal";

export const ADD_QUESTION = {
  NO_SET: "noSet",
  NEW_SET: "newSet",
  EXISTING_SET: "existingSet"
};

export const PAGES = {
  SELECT_FIELDS: "selectFields",
  ADD_QUESTION: "addQuestion"
};

export const HEIGHTS = {
  HEADER: 64,
  FOOTER: 75
};

export const QUESTION_TYPES = getColumnTypes(
  STANDARD_MODULE_IDS.itemQuestions.id
);
