import React, { Fragment, Component } from "react";
import { propEq, compose, filter, prop } from "ramda";
import Fuse from "fuse.js";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import { BaseHeader } from "components/Global/Modal/Layout/Headers";
import { withState } from "utils/General";
import { List } from "react-virtualized";
import {
  DownIcon,
  PopoverMenu,
  Div,
  SearchIcon,
  BigTextInput,
  MediumFilledButton
} from "components/Base";
import { ContactCard } from "../View";
import { scans } from "redux/modules/catering/checkin/meal/selectors";
import { getMeal } from "redux/modules/catering/checkin/meal/actions";
const WIDTH = 700;

function mapStateToProps(state, props) {
  return {
    scans: scans(state, props.params.mealId).map(props.formatter)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getMeal
    },
    dispatch
  );
}

class AllCheckinsModal extends Component {
  UNSAFE_componentWillMount() {
    this.props.getMeal({
      mealId: this.props.params.mealId
    });
    this.initFuse(this.props.scans);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.initFuse(nextProps.scans);
  }
  initFuse = searchList => {
    this.fuse = new Fuse(searchList, {
      threshold: 0.3,
      keys: ["name", "accountName"],
      shouldSort: true
    });
  };

  rowRenderer = list => ({ key, index, style }) => {
    return (
      <div key={key} style={style}>
        <Div px={3}>
          <ContactCard
            {...list[index]}
            hideOverrideMessage
            eventId={this.props.params.eventId}
            notifyModalActive={() => {}}
          />
        </Div>
      </div>
    );
  };

  filter = list => {
    switch (this.props.activeFilter) {
      case "overrides": {
        return filter(prop("overridden"))(list);
      }
      case "denied": {
        return filter(v => propEq("status", "error")(v) && !v.overridden)(list);
      }
      case "success": {
        return filter(v => v.status === "success" && !v.undone)(list);
      }
      default: {
        return list;
      }
    }
  };

  resolveLabel = filter => {
    switch (filter) {
      case "overrides":
        return "Overrides";
      case "denied":
        return "Denied";
      case "success":
        return "Successful";
      default:
        return `All (${this.props.scans.length})`;
    }
  };

  search = list => {
    if (this.props.searchTerm) {
      return this.fuse.search(this.props.searchTerm);
    }
    return list;
  };

  getRowHeight = list => ({ index, ...rest }) => {
    if (list[index].overrideReason) return 110; // needs to be first, since it will also have a `message` attr
    if (list[index].message || list[index].mealsAvailable > 0) return 85;
    return 65;
  };

  render() {
    const {
      activeFilter,
      onSearchTermChange,
      scans,
      setFilterType
    } = this.props;
    const list = compose(this.filter, this.search)(scans);
    return (
      <StyleWrapper
        hideModal={this.props.hideModal}
        heading="All Checkins for meal"
        Header={BaseHeader}
        bodyStyles={{ padding: 0 }}
        width={WIDTH}
      >
        <Fragment>
          <Div
            bg="neutral0"
            bb={1}
            bc="neutral1"
            p={2}
            px={4}
            display="row.flex-start.center"
          >
            <BigTextInput
              flex={1}
              placeholder="Search..."
              LeftIcon={SearchIcon}
              continuous
              onChange={v => onSearchTermChange(v)}
            />
            <PopoverMenu
              menuItems={[
                [`All (${scans.length})`, () => setFilterType("all")],
                ["Overrides", () => setFilterType("overrides")],
                ["Successful", () => setFilterType("success")],
                ["Denied", () => setFilterType("denied")]
              ]}
              Label={({ onClick }) => (
                <MediumFilledButton onClick={onClick} RightIcon={DownIcon}>
                  {this.resolveLabel(activeFilter)}
                </MediumFilledButton>
              )}
            />
          </Div>
          <Div py={2} display="row.center.flex-start">
            <List
              height={400}
              width={WIDTH - 16}
              rowCount={list.length}
              rowRenderer={this.rowRenderer(list)}
              rowHeight={this.getRowHeight(list)}
            />
          </Div>
        </Fragment>
      </StyleWrapper>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(
  compose(
    withState("searchTerm", "onSearchTermChange", ""),
    withState(
      "activeFilter",
      "setFilterType",
      props => props.activeFilter || "all"
    )
  )(AllCheckinsModal)
);
