import FormRowApprovalCellFormatter from "components/Global/Table3/CellFormatters/FormV2RowApproval";
import FormV3RowApprovalCellFormatter from "components/Global/Table3/CellFormatters/FormV3RowApproval";
import ViewSubmissionFormatter from "components/Global/Table3/CellFormatters/ViewSubmission";
import resolveFormatterV1 from "./resolveFormatter";

export default function resolveFormatter(column) {
  if (column.type === "action-approve-form-v3-row") {
    return FormV3RowApprovalCellFormatter;
  } else if (column.type === "action-approve-form-row") {
    return FormRowApprovalCellFormatter;
  } else if (column.type === "view-submission") {
    return ViewSubmissionFormatter;
  }
  return resolveFormatterV1(column);
}
