import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";

import AlertPopover from "components/Global/Alerts/Popover";
import Container from "components/Global/Alerts/Container";
import Header from "components/Global/Alerts/Header";
import Separator from "components/Global/Alerts/Separator";
import ButtonContainer from "components/Global/Alerts/ButtonContainer";
import PrimaryAction from "components/Global/Alerts/PrimaryAction";
import SecondaryAction from "components/Global/Alerts/SecondaryAction";

class DeleteConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopover: false
    };
  }

  @autobind
  handleClick(e) {
    e.stopPropagation();
    this.setState({ showPopover: true });
  }

  @autobind
  onRequestClose() {
    this.setState({ showPopover: false });
  }

  @autobind
  onConfirm(e) {
    e.stopPropagation();
    this.onRequestClose();
    this.props.onConfirm(e);
  }

  render() {
    return (
      <div onClick={e => e.stopPropagation()}>
        <span onClick={this.handleClick} ref={ref => (this.anchor = ref)}>
          {this.props.children}
        </span>
        <AlertPopover
          anchorEl={this.anchor}
          onClose={this.onRequestClose}
          open={this.state.showPopover}
        >
          <Container>
            <Header>{this.props.text}</Header>
            <Separator />
            <ButtonContainer>
              <PrimaryAction onClick={this.onConfirm}>
                {this.props.confirmLabel || "Remove"}
              </PrimaryAction>
              <SecondaryAction onClick={this.onRequestClose}>
                Cancel
              </SecondaryAction>
            </ButtonContainer>
          </Container>
        </AlertPopover>
      </div>
    );
  }
}

DeleteConfirmation.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string
};

export default DeleteConfirmation;
