import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "Modules/SelectRequiredFieldsModal";
import { Div, BigTextInput, SearchIcon } from "components/Base";
import Fields from "./Fields";
import SelectedFields from "./SelectedFields";

const decorate = connect(
  state => ({
    filter: getters.filter(state)
  }),
  {
    setFilter: actions.setFilter
  }
);

const Body = ({ filter, setFilter }) => (
  <Div style={{ height: "calc(100% - 143px)" }}>
    <Div
      height={60}
      bg="white"
      display="column.center"
      px={2}
      bc="neutral1"
      bb={1}
    >
      <BigTextInput
        LeftIcon={SearchIcon}
        placeholder="Filter fields..."
        continuous
        value={filter}
        onChange={value => setFilter(value)}
      />
    </Div>
    <Div display="row.flex-start" style={{ height: "calc(100% - 60px)" }}>
      <Fields />
      <SelectedFields />
    </Div>
  </Div>
);

export default decorate(Body);
