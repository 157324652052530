import React from "react";
import EditorBase from "./TableConnector";
import Input from "components/Global/Editors/Phone";

export default class PhoneEditor extends EditorBase {
  render() {
    return (
      <Input
        ref={c => (this.focusRef = c)}
        value={this.state}
        phoneDisplayType={this.props.column.settings.phoneDisplayType}
        onChange={this.handleChange}
        continuous
      />
    );
  }
}
