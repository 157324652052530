import Helpers from "./Helpers";

module.exports = {
  receiveUserNotifications: (credentials, cb) => {
    const options = {
      method: "get",
      url: `${window.__LENND_API_URL__}/user/me/notifications`,
      data: {},
      credentials,
      success: result => {
        cb(result.notifications);
      }
    };
    Helpers.request(options);
  },

  updateUser: (credentials, details) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/user/me`,
        data: details,
        credentials,
        success: resolve,
        error: reject
      };
      Helpers.request(options);
    }),

  forgotPassword: (email, cb) => {
    const options = {
      method: "post",
      url: `${window.__LENND_API_BASE_URL__}/user/forgot-password`,
      data: {
        email
      },
      success: result => {
        cb(result);
      }
    };
    Helpers.request(options);
  },

  resendConfirmation: (data, cb) => {
    const options = {
      skipUserId: true,
      method: "post",
      url: `${window.__LENND_API_BASE_URL__}/signup/resend`,
      data,
      success: result => {
        cb(result);
      },
      error: result => {
        cb(result);
      }
    };
    Helpers.request(options);
  },

  getVerificationLink: (hash, cb) => {
    const options = {
      skipUserId: true,
      method: "get",
      url: `${window.__LENND_API_BASE_URL__}/signup/confirm/${hash}`,
      success: result => {
        cb(result);
      },
      error: result => {
        cb(result);
      }
    };
    Helpers.request(options);
  },

  verifyEmail: (hash, newPassword, cb) => {
    const options = {
      skipUserId: true,
      method: "post",
      url: `${window.__LENND_API_BASE_URL__}/signup/confirm/${hash}`,
      data: {
        password: newPassword
      },
      success: result => {
        cb(result);
      },
      error: result => {
        cb(result);
      }
    };
    Helpers.request(options);
  },

  registerTrial: (credentials, data) =>
    new Promise((resolve, reject) => {
      const options = {
        skipUserId: true,
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/signup`,
        data,
        credentials,
        success: resolve,
        error: reject
      };
      Helpers.request(options);
    })
};
