import * as R from "ramda";
import { createHandlers, createModule } from "redux-mvc";
import { NAMESPACE } from "Orders/Table/constants";

const iniState = {
  selectedRows: [],
  rows: []
};

const reducers = {
  setRows: ({ selectedRows }, { payload: rows }) => ({
    rows,
    selectedRows: R.filter(r => R.contains(r.id, selectedRows), rows)
  }),
  selectRow: ({ selectedRows }, { payload: id }) => ({
    selectedRows: R.contains(id.toString(), selectedRows)
      ? R.without([id.toString()], selectedRows)
      : R.concat([id.toString()], selectedRows)
  }),
  toggleSelectAll: ({ rows, selectedRows }) =>
    R.equals(R.length(rows), R.length(selectedRows))
      ? { selectedRows: [] }
      : {
          selectedRows: R.map(
            R.compose(
              R.toString,
              R.prop("id")
            ),
            rows
          )
        }
};

const handlers = createHandlers({
  iniState,
  reducers,
  namespace: NAMESPACE
});

const { actions, getters } = handlers;

export { actions, getters };

const module = createModule(handlers);

export default module;
