import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const ContactName = ({ user_fname, user_email, user_lname }) => {
  if (user_fname) {
    return (
      <div styleName="name" title={`${user_fname} ${user_lname}`}>
        {user_fname} {user_lname}
        {user_email ? <span>({user_email})</span> : ""}
      </div>
    );
  }
  return (
    <div styleName="name" title={user_email}>
      {user_email}
    </div>
  );
};

ContactName.propTypes = {
  user_fname: PropTypes.string,
  user_email: PropTypes.string,
  user_lname: PropTypes.string
};

export default CSSModules(ContactName, css);
