import { createSelector } from "reselect";
import * as R from "ramda";
import { getters } from "Modules/AddEditColumnModal/model";

export const getIsPhoneDisabled = createSelector(
  getters.field,
  field => {
    const phoneDisplayType = R.pathOr(
      "",
      ["settings", "phoneDisplayType"],
      field
    );

    return phoneDisplayType === "international";
  }
);

export const getEditField = createSelector(
  (state, props) => getters.fields(state, props),
  (_, props) => props.fieldId,
  (moduleFields, fieldId) => {
    const moduleField = moduleFields.find(field => field.id === fieldId) || {};

    const fieldType = R.path(["settings", "isReferenceField"], moduleField)
      ? "reference"
      : R.path(["type"], moduleField);

    return {
      ...moduleField,
      type: fieldType
    };
  }
);

export const getIsStandardField = createSelector(
  (_, props) => props.fieldId,
  getEditField,
  (fieldId, field) => {
    if (R.isNil(fieldId)) {
      return false;
    }

    return R.propEq("source", "standard", field);
  }
);

export const getErrorMessage = createSelector(
  getters.name,
  getters.type,
  getters.lookupSettings,
  getters.referenceSettings,
  (name, type, lookupSettings, referenceSettings) => {
    if (name.length === 0) {
      return "Please enter the column's name";
    } else if (type === "lookup" && !lookupSettings.moduleId) {
      return "Select a module";
    } else if (
      type === "reference" &&
      (!referenceSettings.lookupFieldId || !referenceSettings.referenceFieldId)
    ) {
      return "Select an existing lookup field";
    }

    return null;
  }
);

export const getFileOptions = R.always([
  { value: "word", label: "Word" },
  { value: "excel", label: "Excel" },
  { value: "ppt", label: "PPT" },
  { value: "pdf", label: "PDF" },
  { value: "image", label: "Image" },
  { value: "video", label: "Video" },
  { value: "audio", label: "Audio" }
]);

export const getSelectedFileOptions = createSelector(
  getters.fileSettings,
  R.propOr([], "allowedFileTypes")
);

export const getIsAllowMultiUpload = createSelector(
  getters.fileSettings,
  R.propOr([], "allowMultiple")
);

export const getShowMaxLength = createSelector(
  getters.type,
  type => type === "text" || type === "textarea" || type === "email"
);

export const getMaxLengthFormatted = createSelector(
  getters.maxLength,
  maxLength => (R.isEmpty(maxLength) ? 0 : parseInt(maxLength, 10))
);
