import React from "react";
import { map } from "ramda";
import {
  Div,
  BigOutlineButton,
  RightIcon,
  Text3,
  FontIcon,
  SmallOutlineButton
} from "components/Base";
import {
  SectionOverviewTemplate,
  SectionOverviewContainer
} from "Passes/SetupCommon";

const GuestListsOverview = ({ resources, loading, types }) => (
  <SectionOverviewContainer breadcrumbs={["collect & manage", "allocations"]}>
    <SectionOverviewTemplate
      loading={loading}
      resources={resources}
      title="Allocations"
      whatSubText="Allocate passes, collect names, save your box office team hours of work"
      whatSectionContent={
        <Div
          bg="neutral1"
          bra={1}
          width={640}
          height={300}
          display="row.center.center"
        >
          <FontIcon fs={7} color="neutral3">
            insert_photo
          </FontIcon>
        </Div>
      }
      doSectionContent={
        <Div display="row">
          <Div
            bg="neutral1"
            bra={1}
            width={300}
            height={200}
            mr={4}
            display="row.center.center"
          >
            <FontIcon fs={7} color="neutral3">
              play_arrow
            </FontIcon>
          </Div>
          <Div display="column">
            <Div fw={3} fs={4} mb={1} mt={2} color="gray7">
              Build forms to collect requests
            </Div>
            <Div mb={3}>
              Quickly set up forms with specific pass types to collect requests
            </Div>
            <Div display="row">
              <BigOutlineButton onClick={() => {}}>
                Watch tutorial
              </BigOutlineButton>
            </Div>
          </Div>
        </Div>
      }
      steps={[
        {
          title: "Manage Allocations for:",
          body: (
            <Div mt={2}>
              <Div p={1}>
                {map(type => (
                  <Div
                    key={type.id}
                    onClick={type.onClick}
                    width={1}
                    px={0}
                    py={2}
                    bg={{ hover: "neutral0" }}
                    bra={1}
                    display="row.flex-start.center"
                    mb={1 / 4}
                    bc="neutral0"
                    bb={1}
                  >
                    <Div
                      pill
                      size={25}
                      mr={2}
                      ml={2}
                      style={{ backgroundColor: type.color }}
                      display="row.center.center"
                    >
                      <FontIcon style={{ color: type.iconColor }} fs={3}>
                        {type.icon}
                      </FontIcon>
                    </Div>
                    <Text3 bold flex={1} pr={4}>
                      {type.title}
                    </Text3>

                    <SmallOutlineButton mr={2}>Manage</SmallOutlineButton>
                  </Div>
                ))(types)}
              </Div>
            </Div>
          )
        },
        {
          title: "Allocate passes & quantities to your groups",
          subtitle: "Choose the available passes & amounts for each group"
        },
        {
          title: "Share and collect names",
          subtitle:
            "Copy & paste your assignment links, send from Lennd, and stay on top of things"
        }
      ]}
    />
  </SectionOverviewContainer>
);

export default GuestListsOverview;
