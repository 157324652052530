import { createModule } from "redux-mvc";
import videoRecorderModule from "ui-kit/VideoRecorder";

import model from "./model";
import sagas from "./sagas";

const module = createModule({
  ...model,
  batch: true,
  observedDomains: ["event", "Data", "user", "modules"]
});

module.plugModule(videoRecorderModule);
module.setRootSaga(sagas);

export default module;
