import { createModule } from "redux-mvc";

import model from "EventLight/Passes/Zones/model";
import sagas from "EventLight/Passes/Zones/sagas";
import addBulkItemsModalModule from "Event/AddBulkItemsModal";

const module = createModule({
  ...model,
  observedDomains: [
    "user",
    "event",
    "modal",
    "organization",
    "permissions",
    "userAccessLevels",
    "@flopflip",
    "Data"
  ]
});
module.setRootSaga(sagas);
module.plugModule(addBulkItemsModalModule);

export default module;
