import React from "react";
import * as R from "ramda";
import {
  Div,
  SmallAvatar,
  Text1,
  Text2,
  Text6,
  RightIcon,
  DownIcon,
  BigShadedInput,
  SearchIcon,
  Collapsable,
  collapsableHandler,
  LoadingIcon
} from "components/Base";
import { connect } from "react-redux";
import moment from "moment";
import { actions, getters } from "Modules/CloneModuleModal";
import { getEventsGroupedByYear } from "Modules/CloneModuleModal/selectors";

const decorate = connect(
  state => ({
    eventsGroupedByYear: getEventsGroupedByYear(state),
    loading: getters.loading(state)
  }),
  {
    setEventsFilter: actions.setEventsFilter,
    selectEvent: actions.selectEvent
  }
);

const Row = collapsableHandler(
  ({ event, selectEvent, collapsed, toggleCollapsed }) => {
    return (
      <Div
        display="column"
        width={1}
        style={{
          flexShrink: 0
        }}
      >
        <Div
          display="row.flex-start.center"
          px={2}
          my={2}
          onClick={toggleCollapsed}
        >
          {collapsed ? (
            <RightIcon size={24} color="black" />
          ) : (
            <DownIcon size={24} color="black" />
          )}
          <Text6 color="gray7" ml={2} bold>{`${event.year} Events`}</Text6>
        </Div>

        <Collapsable collapsed={collapsed}>
          <Div display="column">
            {R.map(
              ev => (
                <Div
                  key={ev.id}
                  display="row.space-between.center"
                  height={41}
                  width={786}
                  bra={3}
                  bg={{
                    default: "white",
                    hover: "gray0"
                  }}
                  px={2}
                  onClick={() => {
                    selectEvent({ event: ev });
                  }}
                >
                  <Div display="row.flex-start.center">
                    <SmallAvatar
                      square
                      longInitials
                      id={ev.id}
                      text={ev.name}
                    />
                    <Text2 color="gray7" bold ml={2}>
                      {ev.name}
                    </Text2>
                  </Div>
                  <Div display="row.flex-start.center">
                    <Text2 color="gray7" bold ml={2}>
                      {`
          ${moment(ev.date_from).format("MMM D YYYY")} -
          ${moment(ev.date_to).format("MMM D YYYY")}
          `}
                    </Text2>
                    <Div
                      display="row.center.center"
                      bg={{
                        default: "neutral2",
                        hover: "primary1"
                      }}
                      width={76}
                      height={28}
                      bra={1}
                      ml={5}
                      style={{ cursor: "pointer" }}
                    >
                      <Text1 color="gray7">SELECT</Text1>
                    </Div>
                  </Div>
                </Div>
              ),
              event.list
            )}
          </Div>
        </Collapsable>
      </Div>
    );
  }
);

const SelectEvent = ({
  eventsGroupedByYear,
  setEventsFilter,
  selectEvent,
  goToNewEvent,
  loading
}) => (
  <Div display="column">
    <Div height={419} width={1} display="column.flex-start.center" px={5}>
      <BigShadedInput
        continuous
        LeftIcon={SearchIcon}
        placeholder="Search events"
        onChange={setEventsFilter}
        width={909}
        mt={5}
        mb={3}
        style={{
          flexShrink: 0
        }}
      />
      <Div
        display="column"
        width={810}
        style={{
          overflowY: "auto"
        }}
        pb={3}
      >
        {R.map(
          event => (
            <Row key={event.year} event={event} selectEvent={selectEvent} />
          ),
          eventsGroupedByYear
        )}
      </Div>
    </Div>
    <Div
      height={86}
      bt={1}
      bc="neutral2"
      width={1}
      display="row.flex-start.center"
      px={5}
      style={{
        flexShrink: 0
      }}
    >
      <Div flex={1}>&nbsp;</Div>
      {loading ? (
        <Div display="row.flex-start.center" mr={7}>
          <LoadingIcon mr={3} />
          <Div fw={3}>Loading...</Div>
        </Div>
      ) : null}
    </Div>
  </Div>
);

export default decorate(SelectEvent);
