import React from "react";
import {
  Div,
  DownIcon,
  RightIcon,
  ExpandVertIcon,
  MoreVertIcon,
  PopoverMenu
} from "components/Base";
import { Field } from "./Fields";
import resolveFormatter from "components/Global/Table3/CellFormatters/utils/resolveFormatter";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Cards = ({ label, rows, fields }) => {
  return rows.map(row => {
    const CardView = row.isCollapsed ? CollapsedCard : ExpandedCard;
    return <CardView key={row.id} label={label} row={row} fields={fields} />;
  });
};

const CardWrapper = CSSModules(
  ({ children, invalid, showInvalidIcon }) => (
    <Div
      b={1}
      bc="neutral1"
      bra={1}
      shadow={{
        default: 1,
        hover: 2
      }}
      mb={4}
      px={2}
      pt={2}
      pb={4}
      style={
        invalid
          ? {
              background: "#ffe9e9",
              border: "1px solid #ea4358"
            }
          : {}
      }
      styleName={showInvalidIcon ? "invalidCard" : undefined}
    >
      {children}
    </Div>
  ),
  css
);

const CardHeaderWrapper = ({ children, onClick, onRemove }) => (
  <Div display="row.space-between.center" mb={5}>
    <Div display="row.flex-start.center" onClick={onClick} flex={1}>
      {children}
    </Div>
    <RowMenu onRemove={onRemove} />
  </Div>
);

const ExpandArrow = ({ Icon, ...props }) => (
  <Div
    bra={1}
    bc="neutral2"
    mr={3}
    display="row.center.center"
    py={0}
    px={1}
    {...props}
  >
    <Icon fs={5} sizwWFS={5} />
  </Div>
);

const RowLabel = ({ children }) => (
  <Div display="row.flex-start.center" uppercase fs={1} fw={3} color="gray7">
    {children}
  </Div>
);

const RowMenu = ({ onRemove }) => (
  <PopoverMenu
    Label={({ onClick }) => <MoreVertIcon size={16} onClick={onClick} />}
    menuItems={[onRemove ? ["Delete", onRemove] : null].filter(i => i)}
  />
);

const ExpandedCard = ({ label, row, fields }) => {
  return (
    <CardWrapper
      invalid={row.isInvalidRow}
      showInvalidIcon={row.showInvalidIcon}
    >
      <CardHeaderWrapper onClick={row.toggleCollapse} onRemove={row.removeRow}>
        <ExpandArrow Icon={DownIcon} bg="white" color="gray8" />
        <RowLabel>
          {label} {row.rowNumber}
        </RowLabel>
      </CardHeaderWrapper>
      <Div px={2}>
        {fields.map(field => (
          <Field
            key={`${row.id}-${field.id}`}
            field={field}
            rowId={row.id}
            isValid={true}
            errors={[]}
            value={row.getValue(field.id, field.type)}
          />
        ))}
      </Div>
      <Div display="row.flex-start.center" mt={2} px={2}>
        <Div
          bra={1}
          bc="neutral3"
          display="row.center.center"
          p={1}
          shadow={1}
          onClick={row.toggleCollapse}
        >
          <Div color="gray7" fw={3} uppercase fs={1} mr={1} p={1}>
            Collapse
          </Div>
          <ExpandVertIcon color="gray5" fs={2} />
        </Div>
      </Div>
    </CardWrapper>
  );
};

const CollapsedCard = ({ label, row, fields }) => {
  return (
    <CardWrapper
      invalid={row.isInvalidRow}
      showInvalidIcon={row.showInvalidIcon}
    >
      <CardHeaderWrapper onClick={row.toggleCollapse} onRemove={row.removeRow}>
        <ExpandArrow
          Icon={RightIcon}
          bg={{
            hover: "primary8",
            default: "neutral2"
          }}
          color={{
            hover: "white",
            default: "gray8"
          }}
        />
        <RowLabel>
          {label} {row.rowNumber}
        </RowLabel>
      </CardHeaderWrapper>
      <Div
        display="row.flex-start.flex-start"
        style={{
          flexWrap: "nowrap",
          marginLeft: 5,
          minWidth: 0,
          overflow: "hidden"
        }}
      >
        {fields.map(field => {
          const Formatter = resolveFormatter(field);
          const value = row.getValue(field.id, field.type);
          return (
            <Div
              key={`${row.id}-${field.id}`}
              display="column"
              mr={2}
              width={1 / 7}
              style={{
                flexShrink: 0
              }}
            >
              <Div fs={1} truncate flex={1}>
                {field.name}
              </Div>
              <Div
                height={26}
                color="gray4"
                fs={2}
                display="row.space-between.center"
                style={{
                  color: "#5f5f5f",
                  minWidth: 0,
                  overflow: "hidden"
                }}
              >
                {value ? (
                  <Formatter
                    value={value}
                    dependentValues={row.getRowMetaData(field)}
                  />
                ) : (
                  <Div
                    truncate
                    color="gray5"
                    style={{
                      fontSize: 13
                    }}
                  >
                    None
                  </Div>
                )}
              </Div>
            </Div>
          );
        })}
      </Div>
    </CardWrapper>
  );
};

export { Cards };
