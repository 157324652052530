import React, { Component } from "react";
import { withState } from "utils/General";
import FieldsAndBlocks from "./Scenes/FieldsAndBlocks/Controller";
import PeopleBlock from "./Scenes/PeopleBlock/Controller";
import Subform from "./Scenes/Subform/Controller";
import OrderCustomerBlock from "./Scenes/OrderCustomer";
import OrderFulfillmentBlock from "./Scenes/OrderFulfillment";
import OrderDetailsBlock from "./Scenes/OrderDetails";

class SidebarController extends Component {
  render() {
    switch (this.props.scene.id) {
      case "addPeopleBlock": {
        return (
          <PeopleBlock
            blockId={this.props.scene.blockId}
            onCreate={() =>
              this.props.setScene({
                id: "editPeopleBlock",
                blockId: this.props.scene.blockId,
                isNew: true
              })
            }
            returnTo={() => this.props.setScene({})}
          />
        );
      }
      case "editPeopleBlock": {
        return (
          <PeopleBlock
            id={this.props.scene.blockId}
            returnTo={() => this.props.setScene({})}
            isNew={this.props.scene.isNew}
          />
        );
      }
      case "subformBlock": {
        return (
          <Subform
            formFieldId={this.props.scene.formFieldId}
            selectedModuleName={this.props.scene.selectedModuleName}
            onCreate={(formFieldId, selectedModuleName) =>
              this.props.setScene({
                id: "subformBlock",
                formFieldId,
                selectedModuleName
              })
            }
            returnTo={() => this.props.setScene({})}
          />
        );
      }
      case "editOrderCustomerBlock": {
        return <OrderCustomerBlock returnTo={() => this.props.setScene({})} />;
      }
      case "editOrderFulfillmentBlock": {
        return (
          <OrderFulfillmentBlock returnTo={() => this.props.setScene({})} />
        );
      }
      case "editOrderDetailsBlock": {
        return <OrderDetailsBlock returnTo={() => this.props.setScene({})} />;
      }
      case "editSubform":
      case "addItemBlock":
      case "editItemBlock":
      default:
        return (
          <FieldsAndBlocks
            onCreatePeopleBlock={blockId =>
              this.props.setScene({
                id: "addPeopleBlock",
                blockId,
                isNew: true
              })
            }
            onCreateSubformBlock={() =>
              this.props.setScene({
                id: "subformBlock",
                formFieldId: null
              })
            }
            onEditSubformBlock={(formFieldId, selectedModuleName) =>
              this.props.setScene({
                id: "subformBlock",
                formFieldId,
                selectedModuleName
              })
            }
            onEditPeopleBlock={blockId =>
              this.props.setScene({ id: "editPeopleBlock", blockId })
            }
            onEditItemBlock={blockId =>
              this.props.setScene({ id: "editItemBlock", blockId })
            }
            onEditOrderCustomerBlock={() =>
              this.props.setScene({
                id: "editOrderCustomerBlock"
              })
            }
            onEditOrderFulfillmentBlock={() =>
              this.props.setScene({
                id: "editOrderFulfillmentBlock"
              })
            }
            onEditOrderDetailsBlock={() =>
              this.props.setScene({
                id: "editOrderDetailsBlock"
              })
            }
          />
        );
    }
  }
}

export default withState("scene", "setScene", {})(SidebarController);
