import Modal from "./AddCollaboratorInfoModal";
import { connect } from "react-redux";

import * as UserSelectors from "redux/modules/user/selectors";
import { acceptInvitationToEvent } from "redux/modules/eventUsers/actions";

export default connect(
  state => ({
    user: UserSelectors.user(state)
  }),
  {
    acceptInvitationToEvent
  }
)(Modal);
