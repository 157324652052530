import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import RaisedButton from "components/Atoms/RaisedButton";
import { actions as FormActions } from "redux/modules/eventForms";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FormRowApprovalCellFormatter extends Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
    dependentValues: PropTypes.object.isRequired
  };

  formatValue(props) {
    if (props.value && props.value.value) {
      return props.value.value;
    }
    return "";
  }

  @autobind
  shouldShowChangeDialog() {
    return (
      this.props.dependentValues.meta.isApprovingForm &&
      !this.props.dependentValues.meta.isResponseLocked
    );
  }

  @autobind
  validateApproveRow() {
    // if we're not reverting back to 'pending' state, check if we should show
    // the changes dialog
    if (
      !(this.formatValue(this.props) === "approved") &&
      this.shouldShowChangeDialog()
    ) {
      this.props.dependentValues.helpers.showAdminChangeDialog(this.approveRow);
      return;
    }
    this.approveRow();
  }

  @autobind
  approveRow() {
    if (this.formatValue(this.props) === "approved") {
      this.props.dependentValues.helpers.dispatch(
        FormActions.unapproveRow({
          eventId: this.props.dependentValues.meta.eventId,
          formId: this.props.dependentValues.meta.formId,
          responseId: this.props.dependentValues.meta.responseId,
          recipientId: this.props.dependentValues.meta.recipientId,
          gridId: this.props.dependentValues.meta.gridId,
          rowId: this.props.dependentValues.meta.rowId,
          recipientHash: this.props.dependentValues.meta.recipientHash,
          via: this.props.dependentValues.meta.via,
          status: "pending"
        })
      );
    } else {
      this.props.dependentValues.helpers.dispatch(
        FormActions.approveRow({
          eventId: this.props.dependentValues.meta.eventId,
          formId: this.props.dependentValues.meta.formId,
          responseId: this.props.dependentValues.meta.responseId,
          recipientId: this.props.dependentValues.meta.recipientId,
          gridId: this.props.dependentValues.meta.gridId,
          rowId: this.props.dependentValues.meta.rowId,
          recipientHash: this.props.dependentValues.meta.recipientHash,
          via: this.props.dependentValues.meta.via,
          status: "approved"
        })
      );
    }
  }

  @autobind
  validateRejectRow() {
    // if we're not reverting back to 'pending' state, check if we should show
    // the changes dialog
    if (
      !(this.formatValue(this.props) === "rejected") &&
      this.shouldShowChangeDialog()
    ) {
      this.props.dependentValues.helpers.showAdminChangeDialog(this.rejectRow);
      return;
    }
    this.rejectRow();
  }

  @autobind
  rejectRow() {
    if (this.formatValue(this.props) === "rejected") {
      this.props.dependentValues.helpers.dispatch(
        FormActions.unapproveRow({
          eventId: this.props.dependentValues.meta.eventId,
          formId: this.props.dependentValues.meta.formId,
          responseId: this.props.dependentValues.meta.responseId,
          recipientId: this.props.dependentValues.meta.recipientId,
          gridId: this.props.dependentValues.meta.gridId,
          rowId: this.props.dependentValues.meta.rowId,
          recipientHash: this.props.dependentValues.meta.recipientHash,
          via: this.props.dependentValues.meta.via,
          status: "pending"
        })
      );
    } else {
      this.props.dependentValues.helpers.dispatch(
        FormActions.approveRow({
          eventId: this.props.dependentValues.meta.eventId,
          formId: this.props.dependentValues.meta.formId,
          responseId: this.props.dependentValues.meta.responseId,
          recipientId: this.props.dependentValues.meta.recipientId,
          gridId: this.props.dependentValues.meta.gridId,
          rowId: this.props.dependentValues.meta.rowId,
          recipientHash: this.props.dependentValues.meta.recipientHash,
          via: this.props.dependentValues.meta.via,
          status: "rejected"
        })
      );
    }
  }

  render() {
    const value = this.formatValue(this.props);
    const approvedClassNames = [css.button];
    if (value === "approved") {
      approvedClassNames.push(css.approved);
    }

    const rejectedClassNames = [css.button];
    if (value === "rejected") {
      rejectedClassNames.push(css.rejected);
    }
    return (
      <div styleName="wrapper">
        <RaisedButton
          action={this.validateApproveRow}
          label={<i className="material-icons">&#xE5CA;</i>}
          className={approvedClassNames.join(" ")}
        />
        <RaisedButton
          action={this.validateRejectRow}
          label={<i className="material-icons">&#xE5CD;</i>}
          className={rejectedClassNames.join(" ")}
        />
      </div>
    );
  }
}

FormRowApprovalCellFormatter.propTypes = {};

export default FormRowApprovalCellFormatter;
