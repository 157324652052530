const statuses = {
  approve: "Approve",
  approved: "Approved",
  reject: "Deny",
  rejected: "Denied",
  deny: "Deny",
  denied: "Denied",
  pending: "Pending",
  undo: "Undo review",
  waiting: "Waiting",
  noApprovalEnabled: "(No approval)"
};

export default statuses;
