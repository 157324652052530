import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actions } from "../model";
import { Div } from "components/Base";
import { withRouter } from "react-router";
import CSSModules from "react-css-modules";

import Cart from "PortalHub/CreateOrderRequest/Cart/View";
import Confirmation from "PortalHub/CreateOrderRequest/Confirmation/View";
import { getters as cartGetters } from "PortalHub/CreateOrderRequest/Cart/model";
import { totalValue } from "PortalHub/CreateOrderRequest/Cart/selectors";

import styles from "./styles.scss";

import * as R from "ramda";
import Body from "./Body";

const decorate = R.compose(
  connect(
    (state, props) => ({
      items: cartGetters.items(state, props),
      total: totalValue(state, props)
    }),
    { init: actions.init }
  ),
  withRouter,
  CSSModules(styles)
);

const Layout = ({ init, location, items, total, params }) => {
  useEffect(() => {
    init({
      recordTypeId: params.recordTypeId
    });
  }, []);

  let view = <Body />;

  if (location.pathname.includes("/cart")) {
    view = <Cart items={items} total={total} />;
  } else if (location.pathname.includes("/confirmation")) {
    view = <Confirmation />;
  }

  return <Div styleName="order-container">{view}</Div>;
};

export default decorate(Layout);
