import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
export default class AsyncSearchInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      term: props.search || ""
    };
  }

  componentWillReceiveProps(props) {
    if (props.search) {
      this.setState({
        term: props.search
      });
    }
  }

  @autobind
  onChange(e) {
    const term = e.target.value;
    this.setState({
      term
    });

    if (typeof this.props.onChange === "function") {
      this.props.onChange(term);
    }
  }

  @autobind
  onClear() {
    if (this.props.isProcessing) {
      // Bail out if processing
      return;
    }

    this.setState({
      term: ""
    });

    if (typeof this.props.onClear === "function") {
      this.props.onClear();
    }
  }

  render() {
    const {
      placeholder,
      isProcessing,
      onClear,
      results,
      style,
      findIconStyle
    } = this.props;

    return (
      <div styleName="container" style={style}>
        <div styleName="leftIcon">
          {typeof onClear === "function" && this.state.term ? (
            <i
              className="material-icons"
              onClick={this.onClear}
              styleName="clearIcon"
            >
              cancel
            </i>
          ) : (
            <i
              className="material-icons"
              style={findIconStyle}
              styleName="searchIcon"
            >
              search
            </i>
          )}
        </div>
        <input
          ref={c => (this.input = c)}
          onChange={this.onChange}
          value={this.state.term}
          placeholder={placeholder}
          styleName="input"
        />
        {isProcessing ? (
          <i className="fa fa-circle-o-notch" styleName="processingIcon" />
        ) : (
          <div styleName="rightContainer">{results || ""}</div>
        )}
      </div>
    );
  }
}

AsyncSearchInput.propTypes = {
  onClear: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  search: PropTypes.string,
  isProcessing: PropTypes.bool,
  placeholder: PropTypes.string,
  findIconStyle: PropTypes.object,
  style: PropTypes.object,
  results: PropTypes.node
};
