import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";

import {
  toggleField,
  updateField
} from "redux/modules/formsV2/form/fields/customerBlockFields/actions";
import { updateCustomer } from "redux/modules/orders/customer/actions";

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      toggleField,
      updateField,
      updateCustomer
    },
    dispatch
  );
}

export default connect(
  null,
  mapDispatchToProps
)(Controller);
