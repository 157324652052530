import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import { noop } from "utils/General";

import { actions } from "Sponsors/SelfSetup";
import { STEPS } from "Sponsors/SelfSetup/constants";

import { EMAIL_FORM_ID } from "Sponsors/SelfSetup/constants";

import Submitters from "./Submitters";
import AdminNotifications from "./AdminNotifications";

import { os } from "ui-kit/Theme";
import { WithFormInstanceProvider } from "ui-kit/Form/View";

import { Div } from "components/Base";
import { PageTitle } from "Sponsors/Common/View";

const decorate = R.compose(
  WithFormInstanceProvider(R.always(EMAIL_FORM_ID)),
  connect(
    null,
    {
      init: actions.init
    }
  )
);

const ApplicationPage = ({ init = noop, ...styleProps }) => {
  useEffect(() => {
    init(STEPS.NOTIFICATIONS);
  }, []);
  return (
    <Div display="column" {...os(styleProps)}>
      <PageTitle>Emails & Notifications</PageTitle>
      <Submitters />
      <AdminNotifications />
    </Div>
  );
};

export default decorate(ApplicationPage);
