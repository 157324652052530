import Helpers from "utils/Global/Helpers";

// mont jazz uuid
// 3e4e3923-a3c6-4bdd-be7e-4e3f8ca6b356
// an acct id from mont jazz
// e75544ce-1614-42a8-82bb-46e119d9f989
module.exports = {
  get: ({
    eventUUID = "3e4e3923-a3c6-4bdd-be7e-4e3f8ca6b356",
    accountId = "e75544ce-1614-42a8-82bb-46e119d9f989"
  }) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/portal/assignment-manager/event/${eventUUID}/group/${accountId}`,
        data: {},
        success: result => {
          resolve(result.payload);
        },
        error: err => {
          reject(err);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  post: () => {},
  put: () => {},
  delete: () => {}
};
