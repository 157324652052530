import PropTypes from "prop-types";
import React, { Component } from "react";
import moment from "moment-timezone";
import Helpers from "utils/Global/Helpers";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Popover from "@lennd/material-ui/Popover";
import InputMoment from "input-moment";
import formaCloseDate from "components/Event/Module/Form/utils/format-close-date";

@CSSModules(css)
class FormDate extends Component {
  state = { showPopover: false, date: this.props.value };

  componentWillMount() {
    moment.locale(Helpers.getLocale());
  }

  onRequestClose = () => {
    this.setState({ showPopover: false, date: this.props.value });
  };

  onSave = () => {
    if (this.props?.onSave) {
      this.props.onSave(this.state.date);
    }
    this.setState({ showPopover: false });
  };

  handleClick = e => {
    e.stopPropagation();
    return this.setState({ showPopover: true });
  };

  handleChange = val => {
    this.setState({ date: val });
    this.props.onChange(val);
  };

  handleClearDate = () => {
    if (this.props?.onSave) {
      this.props.onSave(null);
    }
    this.handleChange(null);
  };

  render() {
    const { label } = this.props;
    const { date: value } = this.state;
    return (
      <div>
        <div
          ref={ref => {
            this.anchor = ref;
          }}
        >
          {!value ? (
            <span styleName="change" onClick={this.handleClick}>
              {label ? label : "Set due date"}
            </span>
          ) : (
            <div styleName="flex">
              <span styleName="change" onClick={this.handleClick}>
                {formaCloseDate(value)}
              </span>
              <span styleName="icon" onClick={this.handleClearDate}>
                <i className="material-icons">add_circle</i>
              </span>
            </div>
          )}
        </div>
        <Popover
          anchorEl={this.anchor}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          onClose={this.onRequestClose}
          open={this.state.showPopover}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <InputMoment
            moment={value ? moment(value) : moment(new Date())}
            onChange={this.handleChange}
            onSave={this.onSave}
            minStep={1}
            hourStep={1}
            prevMonthIcon={css.previousIcon}
            nextMonthIcon={css.nextIcon}
          />
        </Popover>
      </div>
    );
  }
}

FormDate.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object
};

export default FormDate;
