import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  adding: false,
  hasPortal: false,
  recordTypeId: null,
  permissionProfileId: null,
  isAccountPortal: false,
  people: [],
  intakeForm: null,
  intakeFormUrl: null,
  closeDate: null,
  isOpen: true,
  isLocked: true,
  showCustomizeSectionsModal: false,
  showIntakeSettingsModal: false,
  selectedTypeName: null
};

const model = createHandlers({
  iniState,
  reducers: {
    setData: (
      _,
      {
        payload: {
          hasPortal,
          permissionProfileId,
          isAccountPortal,
          people,
          intakeForm,
          intakeFormUrl,
          closeDate,
          isOpen,
          isLocked,
          selectedTypeName
        }
      }
    ) => ({
      hasPortal,
      permissionProfileId,
      isAccountPortal,
      people,
      intakeForm,
      intakeFormUrl,
      closeDate,
      isOpen,
      isLocked,
      selectedTypeName
    }),
    init: R.identity,
    openAddAlertModal: R.identity,
    updateCloseDate: R.identity,
    openIntakeSettingsModal: R.identity,
    onClickLinkInput: R.identity,
    disableIntakeForm: R.identity,
    openCustomizeSectionsModal: R.identity,
    refreshData: R.identity,
    addPermissionSet: R.identity
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
