import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actions, getters } from "Portal/PortalSession/model";

import * as R from "ramda";
import Page from "Portal/Page";
import Body from "./Body";
import Loading from "ui-kit/Loading";

const decorate = connect(
  (state, props) => ({
    backgroundImageUrl: getters.backgroundImageUrl(state, props),
    loading: getters.loading(state, props),
    title: getters.title(state, props)
  }),
  { init: actions.init }
);

const Layout = ({
  init,
  backgroundImageUrl,
  loading,
  title,
  params: { sessionId }
}) => {
  useEffect(() => {
    init(sessionId);
  }, [sessionId]);

  useEffect(() => {
    const originalTitle = document.title;
    document.title = title || originalTitle;

    return () => {
      document.title = originalTitle;
    };
  }, [title]);

  return (
    <Page
      instanceId="portal-session"
      backgroundImageUrl={backgroundImageUrl}
      style={{
        width: "90%",
        maxWidth: "1420px",
        boxShadow: "none",
        padding: 0,
        backgroundColor: "transparent"
      }}
    >
      {loading ? <Loading /> : <Body />}
    </Page>
  );
};

export default R.compose(decorate)(Layout);
