import React, { Component } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import cateringDashboard from "Catering/Dashboard";
import * as R from "ramda";
import Layout from "Catering/Dashboard/View/Layout";
import { actions } from "Catering/Dashboard";
import CateringDashboardSagas from "Catering/Dashboard/sagas";

cateringDashboard.setRootSaga(CateringDashboardSagas);

class CateringDashboard extends Component {
  static contextTypes = {
    store: PropTypes.shape({
      subscribe: PropTypes.func.isRequired,
      dispatch: PropTypes.func.isRequired,
      getState: PropTypes.func.isRequired
    })
  };

  constructor(props, context) {
    super(props);

    const { store } = cateringDashboard.createStore({
      globalStore: context.store,
      observedDomains: ["user", "event"],
      dispatchToGlobal: R.compose(
        R.not,
        R.test(new RegExp(cateringDashboard.namespace)),
        R.prop("type")
      )
    });
    this.store = store;

    cateringDashboard.run();
  }

  componentDidMount() {
    this.store.dispatch(actions.getCateringDashboardData());
  }

  componentWillUnmount() {
    cateringDashboard.cancel();
  }

  render() {
    return (
      <Provider store={this.store}>
        <Layout />
      </Provider>
    );
  }
}

export default CateringDashboard;
