import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "redux/modules/user/actions";
import { withRouter } from "react-router";
import PlatformLoading from "components/Platform/PlatformLoading";

export const LOGOUT_RETURN_TO_KEY = "logoutReturnTo";

const decorate = connect(
  null,
  {
    logout
  }
);

class LogoutHandler extends Component {
  componentDidMount() {
    this.setLogoutReturnToUrl();
    this.props.logout();
  }

  setLogoutReturnToUrl() {
    if (this.props.location.query && this.props.location.query.returnTo) {
      localStorage.setItem(
        LOGOUT_RETURN_TO_KEY,
        this.props.location.query.returnTo
      );
    } else {
      // Ensure there is nothing left over that is stored
      localStorage.removeItem(LOGOUT_RETURN_TO_KEY);
    }
    return true;
  }

  render() {
    return <PlatformLoading message="Logging out..." />;
  }
}

export default withRouter(decorate(LogoutHandler));
