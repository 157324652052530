import * as R from "ramda";
import { createSelector } from "reselect";

import { getters } from "./model";

export const getFilteredItemTypes = createSelector(
  getters.filterItemType,
  (_, props) => R.propOr([], "itemTypes", props),
  (filterItemType, itemTypes) => {
    if (R.isEmpty(filterItemType)) {
      return itemTypes;
    }
    return R.filter(
      item => R.contains(R.toLower(filterItemType), R.toLower(item.name)),
      itemTypes
    );
  }
);

export const getFilteredMeals = createSelector(
  getters.filterItem,
  (_, props) => R.propOr([], "meals", props),
  (filterItem, meals) => {
    if (R.isEmpty(filterItem)) {
      return meals;
    }
    return R.filter(
      item => R.contains(R.toLower(filterItem), R.toLower(item.name)),
      meals
    );
  }
);

export const getFilteredGroups = createSelector(
  getters.filterItem,
  (_, props) => R.propOr([], "itemGroups", props),
  (filterItem, itemGroups) => {
    if (R.isEmpty(filterItem)) {
      return itemGroups;
    }

    return R.reduce(
      (acc, group) => {
        const hasGroupName = R.contains(
          R.toLower(filterItem),
          R.toLower(group.name)
        );
        if (hasGroupName) {
          return [...acc, group];
        }
        const items = R.filter(
          item => R.contains(R.toLower(filterItem), R.toLower(item.name)),
          R.propOr([], "items", group)
        );
        if (R.isEmpty(items)) {
          return acc;
        }
        const updatedGroup = {
          ...group,
          items
        };
        return [...acc, updatedGroup];
      },
      [],
      itemGroups
    );
  }
);

export const getSelectedTypeItems = createSelector(
  (_, props) => R.propOr([], "selectedVariants", props),
  (_, props) => R.propOr([], "itemTypes", props),
  (selectedVariants, itemTypes) =>
    R.reduce(
      (acc, variant) => {
        const typeIndex = R.findIndex(R.propEq("id", variant.typeId), acc);

        if (typeIndex === -1) {
          return [
            ...acc,
            {
              id: variant.typeId,
              name: R.compose(
                R.propOr("", "name"),
                R.find(R.propEq("id", variant.typeId))
              )(itemTypes),
              items: [variant]
            }
          ];
        }
        return R.update(
          typeIndex,
          {
            ...acc[typeIndex],
            items: [
              ...R.filter(
                R.compose(
                  R.not,
                  R.propEq("id", variant.id)
                ),
                acc[typeIndex].items
              ),
              variant
            ]
          },
          acc
        );
      },
      [],
      selectedVariants
    )
);
