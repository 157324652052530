import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const reduceIndexed = R.addIndex(R.reduce);

const iniState = {
  loading: false,
  columns: [],
  requiredFields: [],
  visibleFields: [],
  fieldOrder: {},
  filter: "",
  notDraggableFieldIds: []
};

const handlers = createHandlers({
  iniState,
  reducers: {
    cancelInstance: R.always(iniState),
    setInitialData: (
      state,
      {
        payload: {
          columns,
          requiredFields,
          visibleFields,
          fieldOrder,
          notDraggableFieldIds = []
        }
      }
    ) => ({
      columns: R.map(
        field => ({
          ...field,
          draggable: !R.any(id => id === field.id, notDraggableFieldIds)
        }),
        columns
      ),
      requiredFields: R.isEmpty(requiredFields)
        ? notDraggableFieldIds
        : requiredFields,
      visibleFields: R.isEmpty(visibleFields)
        ? R.compose(
            R.map(R.prop("id")),
            R.filter(field =>
              R.any(id => id === field.id, notDraggableFieldIds)
            )
          )(columns)
        : visibleFields,
      fieldOrder: R.isEmpty(fieldOrder)
        ? reduceIndexed(
            (acc, id, index) => ({ ...acc, [id]: index }),
            {},
            notDraggableFieldIds
          )
        : fieldOrder,
      notDraggableFieldIds
    }),
    toggleVisible: (state, { payload: { id, selected } }) => {
      const visibleFields = selected
        ? R.filter(fieldId => fieldId !== id, state.visibleFields)
        : [...state.visibleFields, id];
      const idOrder = state.fieldOrder[id];

      return {
        visibleFields,
        requiredFields: selected
          ? R.filter(fieldId => fieldId !== id, state.requiredFields)
          : state.requiredFields,
        fieldOrder: !selected
          ? { ...state.fieldOrder, [id]: R.length(visibleFields) - 1 }
          : R.compose(
              R.reduce((acc, field) => {
                const fieldId = field[0];
                const fieldOrder = field[1];
                if (fieldOrder < idOrder) {
                  return { ...acc, [fieldId]: fieldOrder };
                } else if (fieldOrder > idOrder) {
                  return { ...acc, [fieldId]: fieldOrder - 1 };
                }
                return acc;
              }, {}),
              R.toPairs
            )(state.fieldOrder)
      };
    },
    toggleRequired: (state, { payload: { id, required } }) => ({
      requiredFields: required
        ? R.filter(fieldId => fieldId !== id, state.requiredFields)
        : [...state.requiredFields, id]
    }),
    removeAll: state => ({
      visibleFields: state.notDraggableFieldIds,
      requiredFields: state.notDraggableFieldIds,
      fieldOrder: reduceIndexed(
        (acc, id, index) => ({ ...acc, [id]: index }),
        {},
        state.notDraggableFieldIds
      )
    }),
    selectAll: state => ({
      visibleFields: R.map(R.prop("id"), state.columns)
    }),
    reorderFields: (
      state,
      { payload: { reorderedFields, nonSortableFields } }
    ) => ({
      fieldOrder: reduceIndexed(
        (acc, field, index) => ({
          ...acc,
          [field.id]: index
        }),
        {},
        [...nonSortableFields, ...reorderedFields]
      )
    })
  },
  namespace: NAMESPACE
});

export default handlers;
