import React from "react";
import { connect } from "react-redux";

import { getPageTitle } from "Orders/ImportModal/selectors";

import { Div, Text4, DarkCloseButton } from "components/Base";

import { noop } from "utils/General";

import { hideModal } from "redux/modules/modal/actions";

const decorate = connect(
  state => ({
    title: getPageTitle(state)
  }),
  {
    hideModal
  }
);

const Header = ({ hideModal = noop, title = "" }) => (
  <Div
    pl={7}
    pr={3}
    bb={1}
    bc="neutral2"
    color="neutral4"
    height={67}
    display="row.space-between.center"
    style={{
      flexShrink: 0
    }}
  >
    <Text4 color="gray7" bold>
      {title}
    </Text4>
    <DarkCloseButton size={30} onClick={() => hideModal()} />
  </Div>
);

export default decorate(Header);
