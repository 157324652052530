import React, { Component } from "react";
import { isEqual } from "lodash";
import View from "./View";
import { CREDENTIAL_TYPE_ID, MEAL_TYPE_ID } from "utils/item-types";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as R from "ramda";

const buildState = (mode, selectedTypeId, itemBlock, variants) => {
  const variantPrices = {};
  const variantLimits = {};
  const selectedVariants = [];

  // populate default prices
  variants.forEach(variant => {
    const priceId = variant.prices.length
      ? R.compose(
          price => (price ? price.id : null),
          R.find(R.propEq("is_default", true)),
          R.filter(R.prop("is_enabled"))
        )(variant.prices)
      : null;

    if (priceId) {
      variantPrices[variant.id] = priceId;
    }
  });

  // populate selected options
  if (itemBlock && itemBlock.items) {
    itemBlock.items.forEach(item => {
      variantPrices[item.variant.id] = item.price ? item.price.id : null;
      variantLimits[item.variant.id] = item.limit;
      selectedVariants.push(item.variant.id);
    }, {});
  }

  return {
    searchTerm: "",
    activeTab: selectedTypeId || mode === "update" ? "items" : "types",
    selectedTypeId: selectedTypeId || itemBlock.item_type_id,
    variantLimits,
    variantPrices,
    selectedVariants:
      itemBlock && itemBlock.items
        ? itemBlock.items.map(item => item.variant.id)
        : []
  };
};

class InternalHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
      loading: true,
      ...buildState(
        props.mode,
        props.selectedTypeId,
        props.itemBlock,
        props.variants
      )
    };
  }

  componentDidMount() {
    if (this.props.mode === "update") {
      Promise.all([this.props.getTypes(), this.props.getBlock()]).then(() => {
        this.setState({ loading: false });
      });
    } else {
      this.props.getTypes().then(() => {
        this.setState({ loading: false });
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(this.props.itemBlock, prevProps.itemBlock) ||
      this.props.variants.length !== prevProps.variants.length
    ) {
      this.setState({
        ...buildState(
          this.props.mode,
          this.props.selectedTypeId,
          this.props.itemBlock,
          this.props.variants
        )
      });
    }
  }

  setTab = tab => this.setState({ activeTab: tab });

  handleFieldChange = ({ name, value }) => {
    this.setState({ [name]: value });
  };

  canSave = () =>
    this.state.selectedTypeId && this.state.selectedVariants.length;

  handleSave = async () => {
    this.setState({ saving: true });

    if (this.props.mode === "update") {
      await this.handleUpdate();

      if (this.props.onDone) {
        this.props.onDone(this.props.itemBlockId);
      }

      this.props.hideModal();
    } else {
      const block = await this.handleAdd();

      if (this.props.onDone) {
        this.props.onDone(block.id);
      }

      this.props.hideModal();

      this.props.showItemBlockModal(block.id);
    }
  };

  handleUpdate = async () => {
    const { itemBlockId } = this.props;
    const { selectedVariants, variantPrices, variantLimits } = this.state;
    const variantIds = selectedVariants;

    return await this.props.updateItemBlock({
      blockId: itemBlockId,
      block: {
        id: itemBlockId,
        variants: variantIds.map((id, idx) => ({
          variantId: id,
          priceId: variantPrices[id] ? variantPrices[id] : null,
          limit: variantLimits[id] ? variantLimits[id] : null,
          order: idx,
          defaultQuantity: null,
          defaultAddToCart: null,
          disableRemoveFromCart: null,
          disableAddItem: null
        }))
      }
    });
  };

  handleAdd = async () => {
    const { eventDetails, types } = this.props;
    const {
      selectedTypeId,
      selectedVariants,
      variantPrices,
      variantLimits
    } = this.state;
    const activeType = types.find(t => t.id === selectedTypeId);
    const variantIds = selectedVariants;

    return await this.props.addItemBlock({
      eventId: eventDetails.id,
      itemTypeId: selectedTypeId,
      name: `${activeType.name} block`,
      description: null,
      displayType: "grid",
      selectionType: "multiple",
      enableQuantityInput: true,
      enableItemLimit: false,
      variants: variantIds.map((id, idx) => ({
        variantId: id,
        priceId: variantPrices[id] ? variantPrices[id] : null,
        limit: variantLimits[id] ? variantLimits[id] : null,
        order: idx,
        defaultQuantity: null,
        defaultAddToCart: null,
        disableRemoveFromCart: null,
        disableAddItem: null
      }))
    });
  };

  selectType = typeId => {
    this.setState({ activeTab: "items", selectedTypeId: typeId });
  };

  onVariantSelect = id => {
    this.setState(state => {
      if (state.selectedVariants.includes(id)) {
        state.selectedVariants = state.selectedVariants.filter(
          variantId => variantId !== id
        );
      } else {
        state.selectedVariants.push(id);
      }
      return state;
    });
  };

  isVariantSelected = id => this.state.selectedVariants.includes(id);

  selectAllVariants = () => {
    const activeType = this.props.types.find(
      t => t.id === this.state.selectedTypeId
    );
    this.setState(state => {
      state.selectedVariants = this.getGroupsWithItems(
        activeType.groups
      ).reduce((list, group) => {
        this.getItemsMatchingSearch(group.items).forEach(item => {
          item.variants.forEach(variant => {
            list.push(variant.id);
          });
        });
        return list;
      }, []);

      return state;
    });
  };

  removeAllVariants = () => {
    this.setState({
      selectedVariants: []
    });
  };

  onSearchChange = searchTerm => {
    this.setState({ searchTerm });
  };

  getItemsMatchingSearch = items => {
    if (!this.state.searchTerm || !this.state.searchTerm.length) return items;

    const term = this.state.searchTerm.toLowerCase();

    return items.filter(i => i.name.toLowerCase().includes(term));
  };

  getGroupsWithItems = groups =>
    R.compose(
      R.filter(g => g.items.length),
      R.map(group => ({
        ...group,
        items: this.getItemsMatchingSearch(group.items)
      }))
    )(groups);

  updateVariantLimit = (id, limit) =>
    this.setState(state => {
      state.variantLimits = {
        ...state.variantLimits,
        [id]: limit <= 0 ? 0 : limit
      };
      return state;
    });

  updateVariantPrice = (id, priceId) =>
    this.setState(state => {
      state.variantPrices = {
        ...state.variantPrices,
        [id]: priceId
      };
      return state;
    });

  cancel = () => {
    if (typeof this.props.onCancel === "function") {
      this.props.onCancel();
    }
    this.props.hideModal();
  };

  render() {
    const { types, eventDetails } = this.props;
    const {
      activeTab,
      loading,
      saving,
      selectedTypeId,
      selectedVariants,
      variantLimits,
      variantPrices,
      searchTerm
    } = this.state;

    const itemTypes = types.map(type => {
      let color = "#ccc";
      let icon = "done";
      const groups = this.getGroupsWithItems(type.groups);

      if (type.id === CREDENTIAL_TYPE_ID) {
        color = STANDARD_MODULE_IDS.credentials.color;
        icon = STANDARD_MODULE_IDS.credentials.icon;
      } else if (type.id === MEAL_TYPE_ID) {
        color = STANDARD_MODULE_IDS.catering.color;
        icon = STANDARD_MODULE_IDS.catering.icon;
      }

      return {
        id: type.id,
        name: type.name,
        color,
        icon,
        countOfItems: groups.reduce((a, b) => a + b.items.length, 0),
        countOfCategories: groups.length,
        onClick: () => this.selectType(type.id)
      };
    });

    const itemTypesWithHandlers = R.map(type => {
      let color = "#ccc";
      let icon = "done";
      const groups = this.getGroupsWithItems(type.groups);

      if (type.id === CREDENTIAL_TYPE_ID) {
        color = STANDARD_MODULE_IDS.credentials.color;
        icon = STANDARD_MODULE_IDS.credentials.icon;
      } else if (type.id === MEAL_TYPE_ID) {
        color = STANDARD_MODULE_IDS.catering.color;
        icon = STANDARD_MODULE_IDS.catering.icon;
      }

      return {
        id: type.id,
        name: type.name,
        color,
        icon,
        countOfItems: groups.reduce((a, b) => a + b.items.length, 0),
        countOfCategories: groups.length,
        onClick: () => this.selectType(type.id),
        groups: R.compose(
          R.filter(g => this.getItemsMatchingSearch(g.items).length),
          R.map(group => ({
            ...group,
            items: R.map(item => {
              return {
                ...item,
                variants: R.map(variant => {
                  const quantity =
                    selectedVariants[variant.id] >= 0
                      ? selectedVariants[variant.id]
                      : 0;
                  return {
                    ...variant,
                    quantity,
                    count: selectedVariants[variant.id],
                    limit: variantLimits[variant.id],
                    price: variantPrices[variant.id],
                    onChangeLimit: limit =>
                      this.updateVariantLimit(
                        variant.id,
                        parseInt(limit, 10) || 0
                      ),
                    onChangePrice: priceId =>
                      this.updateVariantPrice(variant.id, priceId),
                    selected: this.isVariantSelected(variant.id),
                    onToggle: () => this.onVariantSelect(variant.id)
                  };
                }, item.variants)
              };
            })(R.filter(i => i.variants.length)(group.items))
          }))
        )(groups)
      };
    }, types);

    const activeType = itemTypesWithHandlers.find(
      ({ id }) => id === selectedTypeId
    );

    return (
      <View
        {...{
          loading: (activeTab === "items" && !activeType) || loading,
          saving,
          hideModal: this.cancel,
          activeTab,
          searchTerm,
          canSave: this.canSave(),
          handleSave: this.handleSave,
          selectAll: this.selectAllVariants,
          removeAll: this.removeAllVariants,
          types: itemTypes,
          countOfSelectedVariants: selectedVariants.length,
          onSearchChange: this.onSearchChange,
          eventDateGroups: eventDetails.date_groups,
          cateringMealsPerDay: R.pathOr(
            [],
            ["module_settings", "catering", "meal_days"],
            eventDetails
          ),
          activeType
        }}
      />
    );
  }
}

InternalHandler.defaultProps = {
  itemBlockId: null,
  itemBlock: {},
  items: []
};

export default InternalHandler;
