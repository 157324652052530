import React from "react";
import { withProps, noop } from "utils/General";
import {
  makeTable,
  Text3,
  TinyOutlineBox,
  TinyOutlineButton,
  SmallOutlineButton,
  ExpandIcon,
  FontIcon,
  KeyIcon,
  Div,
  PopoverMenu
} from "components/Base";
import CSSModules from "react-css-modules";
import css from "./tableStyles.scss";

// //////////////////////////////////////////////////////////////////////////////

const FancyCell = CSSModules(
  ({ icon, iconColor, text, isDefault = false, ...props }) => (
    <Div display="row.flex-start.center" {...props}>
      {icon && (
        <FontIcon
          bg={iconColor || "neutral5"}
          color="white"
          pill
          p={1}
          mr={3}
          fs={3}
          flex={0}
        >
          {icon}
        </FontIcon>
      )}
      <Text3 bold mr={3} primary maxWidth={0.8}>
        {text}
      </Text3>
      {isDefault && <TinyOutlineBox mr={2}>Default</TinyOutlineBox>}
      <SmallOutlineButton
        m={2}
        pill
        styleName="childContainer"
        OnlyIcon={ExpandIcon}
        color="neutral7"
        raised
      />
    </Div>
  ),
  css
);

const TableStyler = withProps({
  my: 3,
  bra: 1,
  shadow: 1
})(Div);

const HeaderStyler = withProps({
  display: "row.flex-start.center",
  bg: "neutral1",
  py: 3,
  pl: 4
})(Div);

const RowStyler = CSSModules(
  withProps({
    display: "row.flex-start.center",
    bc: "neutral2",
    bg: "white",
    bt: 1,
    pl: 4,
    styleName: "parentContainer"
  })(Div),
  css
);

// //////////////////////////////////////////////////////////////////////////////
// Exportables

const FieldsTableFormat = makeTable({
  TableStyler,
  HeaderStyler,
  RowStyler,
  HeaderCellStyler: Div,
  RowCellStyler: Div,
  columnProps: [{ width: 4 / 10 }, { width: 4 / 10 }, { width: 2 / 10 }],
  headerCellComps: [
    () => <Text3 bold>Name</Text3>,
    () => <Text3 bold>Type</Text3>,
    () => <Text3 bold>Actions</Text3>
  ],
  rowCellComps: [
    ({ name, isPrimaryField, typeIcon, onClickName }) => (
      <FancyCell
        text={
          <Div display="row.flex-start.center" width={1}>
            {isPrimaryField ? <KeyIcon fs={3} mr={2} color="warning5" /> : null}
            <FontIcon fs={2} mr={1} color="inherit">
              {typeIcon}
            </FontIcon>
            <Div truncate>{name}</Div>
          </Div>
        }
        onClick={onClickName}
      />
    ),
    ({ typeName }) => <Text3>{typeName}</Text3>,
    ({
      onEdit,
      onDelete,
      locked,
      canTogglePrimaryField,
      isPrimaryField,
      onTogglePrimaryField
    }) => (
      <PopoverMenu
        Label={({ onClick }) => (
          <TinyOutlineButton onClick={onClick} py={1}>
            <FontIcon fs={3}>more_horiz</FontIcon>
          </TinyOutlineButton>
        )}
        menuItems={
          locked
            ? [["Edit", onEdit]]
            : [
                ["Edit", onEdit],
                ["Delete", onDelete],
                canTogglePrimaryField
                  ? [
                      isPrimaryField
                        ? "Remove as primary field"
                        : "Set as primary field",
                      onTogglePrimaryField
                    ]
                  : null
              ].filter(o => o)
        }
      />
    )
  ]
});

// //////////////////////////////////////////////////////////////////////////////

const FieldsTable = ({ onSelectAll, allSelected, fields }) => (
  <FieldsTableFormat
    headerData={{ onSelect: onSelectAll, selected: allSelected }}
    rowsData={fields}
  />
);

// //////////////////////////////////////////////////////////////////////////////
// Story

// TODO: redo these
const Story = () => (
  <div>
    <FieldsTable
      fields={[
        {
          name: "Address Line 2",
          type: "Text",
          onClickName: noop,
          onClickExpand: noop,
          onClickMore: noop
        },
        {
          name: "Name",
          type: "Text"
        }
      ]}
    />
  </div>
);

// //////////////////////////////////////////////////////////////////////////////

export { FieldsTable, Story };
