import React, { useMemo } from "react";
import { Div } from "components/Base";

import { dateFormatter } from "utils/EventDays";

const EventDays = ({ dayGroups, days }) => {
  const formattedDates = useMemo(() => dateFormatter(days, dayGroups), [
    days,
    dayGroups
  ]);
  const countOfDates = formattedDates.length;

  return (
    <Div
      display="row.flex-start.center"
      minHeight={23}
      style={{
        userSelect: "none",
        overflow: "hidden"
      }}
    >
      {formattedDates.map(({ label, color }, idx) => (
        <Div
          key={`${label}_${color}`}
          display="row.flex-start.center"
          height={20}
          style={{
            flexShrink: 0,
            marginRight: countOfDates - 1 === idx ? 0 : 4,
            fontSize: 12,
            borderRadius: 2,
            color: "#000",
            padding: "0 5px",
            backgroundColor: `rgba(${color}, 0.15)`
          }}
        >
          {label}
        </Div>
      ))}
    </Div>
  );
};

export default EventDays;
