import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import General from "./General";

// import { hideModal, showModal } from 'redux/modules/modal/actions';
import { getUserAccessLevels } from "redux/modules/user/access-levels/actions";

import { organization } from "redux/modules/organization/selectors";

import {
  updateOrganization,
  fetchOrganization
} from "redux/modules/organization/actions";

function mapStateToProps(state) {
  return {
    organization: organization(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // showModal,
      // hideModal
      fetchOrganization,
      updateOrganization,
      getUserAccessLevels
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(General);
