import * as R from "ramda";
import { createHandlers, createModule } from "redux-mvc";

import { NAMESPACE } from "Passes/Confirmations/constants";

const iniState = {
  sentConfirmation: true,
  pagination: {
    pageSize: 10,
    pages: 0,
    page: 0
  },
  selectedRows: [],
  rows: [],
  showSourceModal: false,
  showPassModal: false,
  showUserModal: false,
  loading: false,
  itemTypeIds: []
};

const zeroIndexPage = R.compose(
  R.dec,
  R.pathOr(1, ["pagination", "page"])
);

const reducers = {
  setPageSize: (state, { payload: pageSize }) => ({
    pagination: {
      ...state.pagination,
      pageSize
    }
  }),
  setCurrentPage: ({ pagination }, { payload: page }) => ({
    pagination: {
      ...pagination,
      page
    },
    selectedRows: []
  }),
  toggleRow: ({ selectedRows }, { payload: id }) => ({
    selectedRows: R.contains(id, selectedRows)
      ? R.without([id], selectedRows)
      : R.concat([id], selectedRows)
  }),
  selectAll: ({ rows }, { payload: select }) => ({
    selectedRows: select ? R.map(R.prop("order_id"), rows) : []
  }),
  setTypeFilters: R.always({
    showUserModal: false,
    showPassModal: false,
    showSourceModal: false
  }),
  fetchData: R.identity,
  receiveList: (_, { payload }) => ({
    rows: payload.orders,
    pagination: {
      ...payload.pagination,
      page: zeroIndexPage(payload)
    }
  })
};

const handlers = createHandlers({ iniState, reducers, namespace: NAMESPACE });

const { actions, getters } = handlers;

export { actions, getters };

const module = createModule(handlers);

export default module;
