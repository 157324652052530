import React, { Component, Fragment } from "react";
import * as R from "ramda";
import {
  Div,
  Text1,
  Text3,
  TextAreaInput,
  VertColorGrid,
  Dropdown,
  BigInsetInput,
  BoxGroup,
  BigFilledBox,
  AttachIcon,
  DeleteIcon,
  CloseIcon
} from "components/Base";
import { capitalize } from "utils/General";

class Overview extends Component {
  handleFieldChange = (value, e) =>
    this.props.onFieldChange({ name: e.target.name, value });
  handleDropdownChange = name => ({ value }) =>
    this.props.onFieldChange({ name, value });

  render() {
    const {
      name,
      activeGroupId,
      description,
      groups,
      color,
      isIntegrationItem,
      integrationName,
      integrationItemName,
      onNameChange,
      onDescriptionChange,
      onGroupChange,
      onSelectColor,
      isViewingVariant,
      itemName,
      itemTypeName,
      photoUrl,
      uploadPhoto,
      removeUrl,
      isLightMode,
      order,
      onOrderChange,
      hideImage
    } = this.props;
    return (
      <Div>
        <Div display="row">
          <Div display="column" flex={1} pr={5}>
            {isIntegrationItem ? (
              <Div p={3} fs={2} bg="neutral2" mb={3}>
                This item is synced with {integrationName}.{" "}
                {integrationItemName ? (
                  <span>
                    The original name is <i>{integrationItemName}</i>.
                  </span>
                ) : null}
              </Div>
            ) : null}

            <Div py={1}>
              <Text3 bold pb={1}>
                {isLightMode
                  ? "Ticket Name"
                  : isViewingVariant
                  ? "Variant Name"
                  : `${capitalize(itemTypeName)} Name`}
              </Text3>
              {isViewingVariant ? (
                <BoxGroup mt={2} display="row.flex-start.flex-start">
                  <BigFilledBox
                    bg="neutral2"
                    color="neutral6"
                    px={3}
                    style={{
                      flexShrink: 0
                    }}
                  >
                    {itemName} -
                  </BigFilledBox>
                  <BigInsetInput
                    name="name"
                    width={1}
                    fs={3}
                    flex={1}
                    onChange={onNameChange}
                    continuous
                    value={name}
                  />
                </BoxGroup>
              ) : (
                <BigInsetInput
                  name="name"
                  width={1}
                  fs={3}
                  flex={1}
                  onChange={onNameChange}
                  continuous
                  value={name}
                />
              )}
            </Div>
            {isViewingVariant ? null : (
              <Fragment>
                {isLightMode ? null : (
                  <Div py={1} pb={4}>
                    <Text3 bold pb={1}>
                      Category
                    </Text3>
                    <Dropdown
                      usePortal
                      options={groups}
                      selected={activeGroupId}
                      onChange={onGroupChange}
                    />
                  </Div>
                )}
                <Div py={1}>
                  <Text3 bold>
                    {isLightMode
                      ? "Description"
                      : `Description of ${itemTypeName}`}{" "}
                    (optional)
                  </Text3>
                  {/*
                <Div pb={1} fs={1}>
                  ie: This is for...
                </Div>
                */}
                  <TextAreaInput
                    name="description"
                    width={1}
                    fs={3}
                    autoSize
                    onChange={onDescriptionChange}
                    continuous
                    value={description}
                  />
                </Div>
                {isLightMode ? (
                  <Div py={1}>
                    <Text3 bold>Order</Text3>
                    <BigInsetInput
                      name="order"
                      width={1}
                      fs={3}
                      flex={1}
                      onChange={onOrderChange}
                      continuous
                      value={order}
                      type="number"
                    />
                  </Div>
                ) : null}
              </Fragment>
            )}
          </Div>
          {isViewingVariant || isLightMode ? null : (
            <Div pb={4}>
              {hideImage ? null : (
                <>
                  <Text3 bold pb={1}>
                    Image
                  </Text3>
                  <Div width={1} display="row.flex-end.center" mb={4}>
                    <Div
                      width={114}
                      height={100}
                      bra={1}
                      bg="white"
                      shadow={{ default: 1, hover: 2 }}
                      p={!photoUrl ? 3 : 0}
                      display="row.center.center"
                      transition="fast"
                      style={{
                        cursor: "pointer",
                        overflow: "hidden",
                        position: "relative",
                        backgroundImage: photoUrl
                          ? `url("${photoUrl}")`
                          : undefined,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat"
                      }}
                      onClick={uploadPhoto}
                      className="hoverContainer"
                    >
                      {photoUrl && (
                        <CloseIcon
                          style={{ position: "absolute", top: 1, right: 1 }}
                          className="showOnHover"
                          size={20}
                          color={{ default: "neutral4", hover: "danger5" }}
                          onClick={ev => {
                            ev.stopPropagation();
                            removeUrl();
                          }}
                        />
                      )}
                      {!photoUrl ? (
                        <Div
                          width={1}
                          height={1}
                          bra={1}
                          ba={2}
                          bc="altB5"
                          display="row.center.center"
                        >
                          <Div display="column.flex-start.center">
                            <AttachIcon color="altB5" size={24} />
                            <Text1 mt={1} color="altB5" bold>
                              ADD IMAGE
                            </Text1>
                          </Div>
                        </Div>
                      ) : null}
                    </Div>
                  </Div>
                </>
              )}

              <Text3 bold pb={1}>
                Select Color
              </Text3>
              <Div p={3} bg="white" bs={2} bra={1}>
                <VertColorGrid
                  selectedColor={color}
                  onSelectColor={onSelectColor}
                />
              </Div>
            </Div>
          )}
        </Div>
      </Div>
    );
  }
}

export default Overview;
