import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import EmptyRows from "./EmptyRows";

import { searchTerm } from "redux/modules/modules/records/settings/selectors";
import { clearSearch } from "redux/modules/modules/records/settings/actions";

function mapStateToProps(state, props) {
  return {
    searchIsActive: Boolean(searchTerm(state, props.moduleId).length)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearSearch
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EmptyRows);
