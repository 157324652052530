import React, { Component } from "react";
import { SearchableListMenu } from "../Menus";
import Fuse from "fuse.js";
import { searchWithFuse } from "../utils";

class SearchList extends Component {
  state = { searchTerm: "" };
  componentDidMount() {
    this.fuse = new Fuse(this.props.list, {
      threshold: 0.3,
      keys: ["name", "email"],
      shouldSort: true
    });
  }

  handleSearchChange = searchTerm => this.setState({ searchTerm });

  render() {
    const { list, onSelect } = this.props;
    return (
      <SearchableListMenu
        list={searchWithFuse(this.state.searchTerm, this.fuse, list, "id")}
        searchTerm={this.state.searchTerm}
        onChange={this.handleSearchChange}
        onSelect={onSelect}
      />
    );
  }
}

export default SearchList;
