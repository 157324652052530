import React from "react";
import DocumentTitle from "components/Global/DocumentTitle";
import CSSModules from "react-css-modules";
import css from "../shared/styles.scss";
import PasswordField from "components/Global/Inputs/Password";
import PasswordChecklist from "react-password-checklist";

const View = CSSModules(
  ({
    onFieldChange,
    password,
    confirmPassword,
    verificationEmail,
    error,
    validVerificationLink,
    consumedVerificationLink,
    resendLink,
    onLoginClick,
    onForgotPasswordClick,
    processing,
    onSubmit
  }) => (
    <DocumentTitle title="Account Confirmation">
      <div styleName="container">
        {/* logo */}
        <div styleName="logoWrapper">
          <img
            src="https://d2dks4tzxs6xee.cloudfront.net/img/Lennd-white-login.png"
            width="154px"
          />
        </div>

        <div styleName="content">
          <div styleName="wrapper">
            <div styleName="left">
              <form onSubmit={onSubmit}>
                <div>
                  <div styleName="title">Account Confirmation</div>
                  {validVerificationLink ? (
                    consumedVerificationLink ? (
                      <div>
                        <div>
                          <p>
                            This confirmation link has already been used. Choose
                            one of the options below to continue.
                          </p>
                        </div>
                        <div>
                          <button
                            styleName="buttonWrapper"
                            onClick={onLoginClick}
                          >
                            <span styleName="button">Log In</span>
                          </button>
                          <button
                            styleName="secondaryButtonWrapper"
                            onClick={onForgotPasswordClick}
                          >
                            <span styleName="button">Forgot Password</span>
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div>
                          <p>
                            Thank you for confirming your email address
                            {verificationEmail ? (
                              <span>
                                {" "}
                                <span styleName="emailAddress">
                                  {verificationEmail}
                                </span>
                              </span>
                            ) : null}
                            .
                          </p>
                          <p>
                            Please set the password for your new Lennd account
                            below--once you've completed this step, we can get
                            started setting up your new Event!
                          </p>
                        </div>
                        <div>
                          {/* field: password */}
                          <div styleName="inputWrapper">
                            <PasswordField
                              className="styled"
                              name="password"
                              placeholder="Password"
                              required
                              onChange={onFieldChange}
                              value={password}
                              data-cy="signup-confirmation-password-input"
                            />
                          </div>

                          {/* field: password confirmation */}
                          <div styleName="inputWrapper">
                            <PasswordField
                              className="styled"
                              name="confirmPassword"
                              placeholder="Confirm Password"
                              required
                              onChange={onFieldChange}
                              value={confirmPassword}
                              data-cy="signup-confirmation-confirm-password-input"
                            />
                          </div>

                          <div>
                            <PasswordChecklist
                              rules={[
                                "length",
                                "specialChar",
                                "number",
                                "capital"
                              ]}
                              minLength={8}
                              value={password}
                            />
                          </div>

                          {/* message */}
                          {error && <div styleName="message">{error}</div>}

                          {/* button */}
                          {processing ? (
                            <button styleName="buttonWrapper">
                              <span styleName="button">
                                Creating your account...
                              </span>
                            </button>
                          ) : (
                            <button
                              styleName="buttonWrapper"
                              type="submit"
                              data-cy="signup-confirmation-submit-button"
                            >
                              <span styleName="button">
                                Set up my organization
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                    )
                  ) : (
                    <div>
                      <p>
                        This is not a valid confirmation link. Please check your
                        email and try again.
                      </p>
                      <p>
                        If you haven't received a confirmation email, please
                        check your spam folder or{" "}
                        <a styleName="resendConfirmation" href={resendLink}>
                          re-send it here
                        </a>
                        .
                      </p>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </DocumentTitle>
  ),
  css
);

export default View;
