import React from "react";

import { connect } from "react-redux";

import { actions } from "Items/BulkQuestionItemsModal";

import {
  HEIGHTS,
  BULK_QUESTIONS_PAGE
} from "Items/BulkQuestionItemsModal/constants";

import {
  isSelectQuestionsPage,
  isAssignItemsPage
} from "Items/BulkQuestionItemsModal/selectors";

import { Div, BigFilledButton, BigOutlineButton } from "components/Base";

const decorate = connect(
  state => ({
    selectQuestionsPage: isSelectQuestionsPage(state),
    assignItemsPage: isAssignItemsPage(state)
  }),
  {
    goToSelectItems: () => actions.selectItems(),
    goToSelectQuestions: () =>
      actions.setBulkQuestionsPage(BULK_QUESTIONS_PAGE.SELECT_QUESTIONS),
    assignQuestionsToItems: actions.assignQuestionsToItems
  }
);

const Footer = ({
  hideModal,
  goToSelectItems,
  selectQuestionsPage,
  assignItemsPage,
  goToSelectQuestions,
  assignQuestionsToItems
}) => (
  <Div
    width={1}
    bt={1}
    bc="neutral1"
    display="row.space-between.center"
    px={4}
    height={HEIGHTS.FOOTER}
  >
    {selectQuestionsPage && (
      <BigFilledButton bg="altB4" color="white" onClick={goToSelectItems}>
        Next: Select Items
      </BigFilledButton>
    )}
    {assignItemsPage && (
      <Div display="row.flex-start.center">
        <BigFilledButton
          bg="altB4"
          color="white"
          onClick={() => assignQuestionsToItems({ onDone: hideModal })}
        >
          Assign Questions to Items
        </BigFilledButton>
        <BigOutlineButton onClick={goToSelectQuestions} ml={2}>
          Back to questions
        </BigOutlineButton>
      </Div>
    )}
    <BigOutlineButton width={121} onClick={hideModal}>
      Cancel
    </BigOutlineButton>
  </Div>
);

export default decorate(Footer);
