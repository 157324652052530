import PropTypes from "prop-types";
import React, { Component } from "react";
import { get, cloneDeep } from "lodash";
import List from "./List";
import QuantityInput from "./QuantityInput";
import { NONE } from "utils/system-field-values";

class ItemBlockEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItems: cloneDeep(this.formatValue(props.value)),
      removedItems: []
    };
  }

  wrapValue = items => ({
    type: "item-block",
    value:
      items && items.length
        ? items.map(({ id, quantity, price }) => ({ id, quantity, price }))
        : [],
    removedItems: this.state.removedItems
  });

  formatValue(value) {
    return get(value, "value", []);
  }

  updateQuantity = (item, value) => {
    let newState = [...this.pureItems()];
    const quantity = this.formatQuantity(value);

    if (this.itemExists(item, newState)) {
      newState = this.updateItem(item, quantity, newState);
    } else {
      newState = this.addItem(item, quantity, newState);
    }

    this.props.onChange(this.wrapValue(newState));
    this.setState({ selectedItems: newState });
  };

  updateQuantityOnlyInput = (item, value) => {
    const quantity = this.formatQuantity(value);
    const newState = this.addItem(item, quantity, []);

    this.props.onChange(this.wrapValue(newState));
    this.setState({ selectedItems: newState });
  };

  pureItems = () => this.state.selectedItems.filter(c => c.id !== NONE.id);

  clearNoneValue = () => {
    this.props.onChange(this.wrapValue(this.pureItems()));
    this.setState({ selectedItems: this.pureItems() });
  };

  setNoneValue = () => {
    const noneValue = { id: NONE.id, quantity: 1, value: NONE.value };
    this.props.onChange(this.wrapValue([noneValue]));
    this.setState({ selectedItems: [noneValue] });
  };

  handleSelectNone = val => {
    if (val) {
      return this.setNoneValue();
    }
    return this.clearNoneValue();
  };

  updateSingleItem = item => {
    const newState = [];
    if (item) {
      newState.push({ ...item, quantity: 1 });
    }
    this.props.onChange(this.wrapValue(newState));
    this.setState({ selectedItems: newState });
  };

  formatQuantity = quantity => {
    const quantityAsInt = parseInt(quantity, 10);
    return isFinite(quantityAsInt) && quantityAsInt > 0 ? quantityAsInt : 0;
  };

  itemExists = (item, items) => Boolean(this.getItem(item, items));

  getItem = (item, items) => items.find(i => i.id === item.id);

  updateItem(item, quantity, items) {
    if (quantity === 0) {
      return this.removeItem(item, items);
    }
    items[items.indexOf(this.getItem(item, items))].quantity = quantity;
    return items;
  }

  addItem = (item, quantity, items) => {
    if (quantity === 0) {
      return items;
    }

    items.push({ quantity, ...item });
    this.setState(state => {
      state.removedItems.filter(i => i !== item.id);
      return state;
    });
    return items;
  };

  removeItem(item, items) {
    items.splice(items.indexOf(this.getItem(item, items)), 1);
    this.setState(state => {
      state.removedItems.push(item.id);
      return state;
    });
    return items;
  }

  render() {
    const { limit, close, options, disabled, column, className } = this.props;
    const selectType = get(column, "settings.selectType", "quantity");
    if (selectType === "quantity-only-input") {
      return (
        <QuantityInput
          className={className}
          column={column}
          disabled={disabled}
          selectedItems={this.state.selectedItems}
          updateQuantity={this.updateQuantityOnlyInput}
        />
      );
    }

    // Currently 'single', 'multiple', 'quantity'
    return (
      <List
        limit={limit}
        onClose={close}
        onSelectNone={this.handleSelectNone}
        customWidth={get(column, "settings.customWidth")}
        selectType={selectType}
        options={options}
        disabled={disabled}
        selectedItems={this.state.selectedItems}
        updateSingleItem={this.updateSingleItem}
        updateQuantity={this.updateQuantity}
      />
    );
  }
}

ItemBlockEditor.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  column: PropTypes.object,
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  close: PropTypes.func
};

export default ItemBlockEditor;
