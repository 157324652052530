import { createSelector } from "reselect";

import * as R from "ramda";

import { getters as formGetters } from "ui-kit/Form/model";

import { DROPDOWN_FIELD_ID } from "./constants";

export const getIsSaveBtnDisabled = createSelector(
  formGetters.fields,
  R.compose(
    R.isEmpty,
    R.pathOr("", [DROPDOWN_FIELD_ID, "value", 0, "value"])
  )
);
