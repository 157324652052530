import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import RequestModal from "./RequestModal";

import { addValue } from "redux/modules/modules/values/actions";
import { getModule } from "redux/modules/modules/module/actions";
import { getRecord, deleteRecord } from "redux/modules/modules/record/actions";
import { addRecord } from "redux/modules/modules/records/actions";
import { logAction } from "redux/modules/log/actions";

import * as UserSelectors from "redux/modules/user/selectors";
import * as PortalSelectors from "redux/modules/portal/selectors";
import * as EventSelectors from "redux/modules/event/selectors";
import * as ModuleSelectors from "redux/modules/modules/module/selectors";
import * as ModuleRecordSelectors from "redux/modules/modules/record/selectors";

function mapStateToProps(state, props) {
  return {
    credentials: UserSelectors.getCredentials(state),
    eventDetails:
      props.view === "admin"
        ? EventSelectors.eventDetails(state)
        : PortalSelectors.eventDetails(state),
    user: UserSelectors.user(state),
    module: ModuleSelectors.moduleDetails(state, props.moduleId),
    fieldGroups: ModuleSelectors.fieldGroups(state, props.moduleId),
    starredFields: ModuleSelectors.fieldGroups(state, props.moduleId),
    getFetchedRecord: recordId => ModuleRecordSelectors.record(state, recordId)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addRecord,
      addValue,
      getModule,
      getRecord,
      deleteRecord,
      logAction
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestModal);
