import * as R from "ramda";
import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "Event/Home/constants";

const iniState = {
  search: "",
  activities: [],
  groupTypes: {
    account_record_types: [],
    contact_record_types: []
  },
  isFetching: false,
  loading: true,
  approvalsChecklistsCount: {},
  setupChecklists: {},
  myApprovals: {}
};

const handlers = createHandlers({
  iniState,
  reducers: {
    getEventRecordTypes: R.identity,
    fetchActivities: R.always({ isFetching: true }),
    activitiesResponse: (state, { payload, error }) =>
      error
        ? {
            isFetching: false
          }
        : {
            activities: payload
          }
  },
  namespace: NAMESPACE
});

export default handlers;
