import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import { find } from "lodash";
import LoggedOutHeader from "components/Global/Headers/LoggedOutHeader";
import EnvironmentTag from "components/Platform/EnvironmentTag";
import Modal from "components/Global/Modal";
import Snackbar from "components/Global/Snackbar";

import CSSModules from "react-css-modules";
import styles from "./styles.scss";

@CSSModules(styles)
class LoggedOutView extends Component {
  @autobind
  showModal(modal) {
    this.props.showModal(modal.content ? modal : { content: modal });
  }

  @autobind
  hideModal() {
    this.props.hideModal();
  }

  render() {
    let header;
    if (
      !find(this.props.routes, { name: "joinVirtualUser" }) &&
      !find(this.props.routes, { name: "formEntry" }) &&
      !find(this.props.routes, { name: "formApprove" }) &&
      !find(this.props.routes, { name: "formLandingConvert" }) &&
      !find(this.props.routes, { name: "formsV2_FormSubmissionLanding" }) &&
      !find(this.props.routes, { name: "formsV2_FormSubmissionLanding" }) &&
      !find(this.props.routes, { name: "LoginView" }) &&
      !find(this.props.routes, { name: "forgotPassword" }) &&
      !find(this.props.routes, { name: "Invoice" }) &&
      !find(this.props.routes, { name: "LogoutView" }) &&
      !find(this.props.routes, { name: "LogoutCallbackView" }) &&
      !find(this.props.routes, { name: "CallbackView" }) &&
      !find(this.props.routes, { name: "healthPassForm" }) &&
      !find(this.props.routes, { name: "healthPassStatus" }) &&
      !find(this.props.routes, { name: "intakeForm" }) &&
      !find(this.props.routes, { name: "intakeCallback" }) &&
      !find(this.props.routes, { name: "intakeLogin" }) &&
      !find(this.props.routes, {
        name: "formsV2_FormSubmissionLandingCallback"
      }) &&
      !find(this.props.routes, { name: "portalV2_PortalLoginCallback" })
    ) {
      header = (
        <LoggedOutHeader
          routes={this.props.routes}
          location={this.props.location}
        />
      );
    }

    return (
      <div styleName="container">
        {header}
        <div style={{ height: "100%" }}>
          {React.cloneElement(this.props.children, {
            showModal: this.showModal,
            hideModal: this.hideModal
          })}
          <Modal params={this.props.params} router={this.props.router} />
          <Snackbar />
        </div>
        <EnvironmentTag />
      </div>
    );
  }
}

LoggedOutView.propTypes = {
  routes: PropTypes.array.isRequired,
  children: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default LoggedOutView;
