import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import { get } from "lodash";
import RaisedButton from "components/Atoms/RaisedButton";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FormRowApprovalCellFormatter extends Component {
  formatValue(value) {
    return get(value, "value", "");
  }

  @autobind
  validateApproveRow() {
    if (this.props.dependentValues.meta.readOnly) return;

    // if we're not reverting back to 'pending' state, check if we should show
    // the changes dialog
    if (this.formatValue(this.props.value) !== "approved") {
      return this.props.dependentValues.helpers.showAdminChangeDialog(
        this.props.dependentValues.submissionId,
        this.approveRow
      );
    }
    return this.props.dependentValues.helpers.showAdminUndoDialog(
      this.props.dependentValues.submissionId,
      this.approveRow
    );
  }

  @autobind
  approveRow() {
    this.props.dependentValues.helpers.createReview(
      {
        eventId: this.props.dependentValues.meta.eventId,
        formId: this.props.dependentValues.meta.formId,
        subformId: this.props.dependentValues.meta.subformId,
        submissionId: this.props.dependentValues.id,
        status:
          this.formatValue(this.props.value) === "approved"
            ? "pending"
            : "approved"
      },
      this.props.dependentValues
    );
  }

  @autobind
  validateRejectRow() {
    if (this.props.dependentValues.meta.readOnly) return;

    // if we're not reverting back to 'pending' state, check if we should show
    // the changes dialog
    if (this.formatValue(this.props.value) !== "rejected") {
      return this.props.dependentValues.helpers.showAdminChangeDialog(
        this.props.dependentValues.submissionId,
        this.rejectRow
      );
    }
    this.rejectRow();
  }

  @autobind
  rejectRow() {
    this.props.dependentValues.helpers.createReview(
      {
        subformId: this.props.dependentValues.meta.subformId,
        submissionId: this.props.dependentValues.id,
        status:
          this.formatValue(this.props.value) === "rejected"
            ? "pending"
            : "rejected"
      },
      this.props.dependentValues
    );
  }

  render() {
    if (this.props.dependentValues.meta.isRowEmpty) {
      return <div styleName="emptyWrapper">Empty</div>;
    }

    const value = this.formatValue(this.props.value);

    let action;

    switch (value) {
      case "approved":
        action = (
          <div styleName="wrapper">
            <RaisedButton
              action={this.validateApproveRow}
              label={
                <div className={css.buttonWrapper}>
                  <i className={["material-icons", css.buttonIcon].join(" ")}>
                    &#xE5CA;
                  </i>{" "}
                  Approved
                </div>
              }
              className={
                this.props.dependentValues.meta.readOnly
                  ? css.disabledApproved
                  : css.approved
              }
            />
          </div>
        );
        break;
      case "rejected":
        action = (
          <div styleName="wrapper">
            <RaisedButton
              action={this.validateRejectRow}
              label={
                <div className={css.buttonWrapper}>
                  <i className={["material-icons", css.buttonIcon].join(" ")}>
                    &#xE5CD;
                  </i>{" "}
                  Rejected
                </div>
              }
              className={
                this.props.dependentValues.meta.readOnly
                  ? css.disabledRejected
                  : css.rejected
              }
            />
          </div>
        );
        break;
      default:
        if (this.props.dependentValues.meta.readOnly) {
          return <div styleName="emptyWrapper">Pending</div>;
        }
        action = (
          <div styleName="wrapper">
            <RaisedButton
              action={this.validateApproveRow}
              label={<i className="material-icons">&#xE5CA;</i>}
              className={css.button}
            />
            <RaisedButton
              action={this.validateRejectRow}
              label={<i className="material-icons">&#xE5CD;</i>}
              className={css.button}
            />
          </div>
        );
    }

    return action;
  }
}

FormRowApprovalCellFormatter.propTypes = {
  value: PropTypes.object.isRequired,
  dependentValues: PropTypes.object.isRequired
};

export default FormRowApprovalCellFormatter;
