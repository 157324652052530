import api from "./api";

export function associateDocumentToModuleRecord({
  recordId,
  documentId,
  moduleId
}) {
  return async (dispatch, getState) =>
    api.post(getState().user.user.credentials, {
      recordId,
      documentId,
      moduleId
    });
}
