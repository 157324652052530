import { registerError } from "redux/modules/errors/actions";
import { RECEIVE } from "./constants";
import api from "./api";

export function getEventSetupProgress(eventId) {
  return async (dispatch, getState) => {
    try {
      const { payload } = await api.get(
        getState().user.user.credentials,
        eventId
      );
      dispatch({
        type: RECEIVE,
        payload: {
          eventId,
          result: payload
        }
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting event setup progress"
          }
        ])
      );
    }
  };
}

export function addEventSetupProgress(data = {}) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post(getState().user.user.credentials, data);
      return result.event;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred creating event setup progress"
          }
        ])
      );
    }
  };
}

export function updateEventSetupProgress(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.put(getState().user.user.credentials, data);
      return result.event;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating event setup progress"
          }
        ])
      );
    }
  };
}

export function setupEvent(eventId) {
  return async (dispatch, getState) => {
    try {
      const result = await api.setup(getState().user.user.credentials, eventId);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred setting up event"
          }
        ])
      );
    }
  };
}
