import React from "react";
import { Div } from "components/Base";
import { Title } from "Passes/SetupCommon";

const View = ({}) => (
  <Div style={{ width: "100%" }}>
    <Title>Confirmations</Title>
  </Div>
);

export default View;
