import { createSelector } from "reselect";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import {
  eventDetails,
  isEventFeatureEnabled
} from "redux/modules/event/selectors";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";
import { FEATURES } from "utils/event-types-and-feature-constants";

export const isCredentialsEnabled = createSelector(
  canEventUserDo,
  eventDetails,
  isEventFeatureEnabled,
  (canDo, event, isEnabled) =>
    isEnabled(FEATURES.PASSES) &&
    canDo(`${STANDARD_MODULE_IDS.credentials.id}_read`)
);
