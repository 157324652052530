import PropTypes from "prop-types";
import React, { Component } from "react";
import { isEqual, cloneDeep } from "lodash";
import helpers from "./utils";
import Invite from "./Invite";
import Permissions from "./Permissions";
import { AccountUserIcon } from "components/Global/CRM/Users/AccountUser";
import DeleteConfirmation from "components/Global/CRM/Popovers/DeleteConfirmation";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import {
  ButtonGroup,
  ButtonOutline,
  Submit
} from "components/Global/Modal/Layout/Buttons";
import CircularProgress from "material-ui/CircularProgress";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

export const Menu = CSSModules(
  ({ selectedValue, disabled = false }) => (
    <div
      styleName="menu"
      style={disabled ? { background: 0, border: 0 } : { cursor: "pointer" }}
    >
      {selectedValue}
      {!disabled ? <i className="material-icons">keyboard_arrow_down</i> : null}
    </div>
  ),
  css
);

export const Loading = CSSModules(
  () => (
    <div styleName="progress">
      <CircularProgress color="#ccc" />
    </div>
  ),
  css
);

@CSSModules(css)
class ShareModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      name: null,
      type: null,
      owners: helpers.mapOwners(this.getSharedWith())
    };
  }

  componentDidMount() {
    this.fetchOwners().then(() => {
      this.setState({ loading: false });
    });
  }

  componentDidUpdate(oldProps) {
    if (!this.areRecordOwnersTheSame(oldProps.owners, this.props.owners)) {
      this.setState({
        owners: helpers.mapOwners(this.getSharedWith(this.props))
      });
    }
  }

  getSharedWith = (props = this.props) => cloneDeep(props.owners);

  areRecordOwnersTheSame = (a, b) =>
    isEqual(
      a.map(u => `${u.user_id}_${u.role}`).sort(),
      b.map(u => `${u.user_id}_${u.role}`).sort()
    );

  hasChanges = () =>
    !isEqual(
      Object.keys(this.state.owners)
        .map(id => `${id}_${this.state.owners[id].role}`)
        .sort(),
      this.getSharedWith()
        .map(u => `${u.user_id}_${u.role}`)
        .sort()
    );

  undoDisable = id =>
    this.setState(state => {
      state.owners[id] = this.getSharedWith().find(o => o.user_id === id);
      return state;
    });

  isOwnerDisabled = id =>
    this.getSharedWith().some(o => o.user_id === id) &&
    !Object.keys(this.state.owners).includes(id.toString());

  removeOwner = id =>
    this.setState(state => {
      delete state.owners[id];
      return state;
    });

  fetchOwners = () =>
    this.props.getOwners({
      moduleId: this.props.moduleId,
      recordIds: [this.props.recordId],
      options: {
        eventId: this.props.eventDetails.id
      }
    });

  handleSendToOwners = async ({ role, userIds, message }) => {
    const result = await this.props.addOwners({
      moduleId: this.props.moduleId,
      recordIds: [this.props.recordId],
      userIds,
      roles: userIds.reduce((a, userId) => {
        a[userId] = role;
        return a;
      }, {}),
      message,
      options: {
        eventId: this.props.eventDetails.id
      }
    });

    await this.fetchOwners();

    return result;
  };

  deleteOwners = async ownersToRemove => {
    if (!ownersToRemove.length) return true;

    const result = await this.props.deleteOwners({
      moduleId: this.props.moduleId,
      recordIds: [this.props.recordId],
      userIds: ownersToRemove.map(o => o.userId),
      options: {
        eventId: this.props.eventDetails.id
      }
    });

    return result;
  };

  addOwners = async ownersToAdd => {
    if (!ownersToAdd.length) return true;

    const result = await this.props.addOwners({
      moduleId: this.props.moduleId,
      recordIds: [this.props.recordId],
      userIds: ownersToAdd.map(o => o.userId),
      roles: ownersToAdd.reduce((a, owner) => {
        a[owner.userId] = owner.role;
        return a;
      }, {}),
      options: {
        eventId: this.props.eventDetails.id
      }
    });

    return result;
  };

  handleSave = async () => {
    this.setState({ saving: true });
    const data = {
      recordId: this.props.recordId,
      remove: [],
      update: []
    };

    const incomingHash = this.props.owners.reduce((a, user) => {
      a[user.user_id] = `${user.user_id}_${user.role}`;
      return a;
    }, {});

    const updatedHash = Object.keys(this.state.owners).reduce((a, id) => {
      a[id] = `${id}_${this.state.owners[id].role}`;
      return a;
    }, {});

    // build diff
    this.props.owners.forEach(user => {
      if (!updatedHash[user.user_id]) {
        data.remove.push({
          recordId: this.props.recordId,
          userId: user.user_id
        });
        return;
      }
      if (incomingHash[user.user_id] !== updatedHash[user.user_id]) {
        // @NOTE: We add users w/ updates to the "remove" hash so
        // they can be removed and then replaced w/ up to date info
        data.remove.push({
          recordId: this.props.recordId,
          userId: user.user_id
        });
        data.update.push({
          recordId: this.props.recordId,
          userId: user.user_id,
          role: this.state.owners[user.user_id].role
        });
      }
    });

    await this.deleteOwners(data.remove);
    await this.addOwners(data.update);
    this.props.hideModal();
    this.props.showSnackbar({ message: "Updated" });
    if (this.props.onDone) {
      this.props.onDone();
    }
  };

  handlePermissionChange = (role, id) =>
    this.setState(state => {
      state.owners[id].role = role;
      return state;
    });

  canChangeRole = id => {
    // if removed, cannot change role
    if (!this.state.owners[id]) return false;

    // if not an owner, we can modify role
    if (this.state.owners[id].role !== "owner") return true;

    // if there are other owners, we can modify this person's role
    if (
      Object.keys(this.state.owners).filter(
        userId => this.state.owners[userId].role === "owner"
      ).length > 1
    ) {
      return true;
    }

    return false;
  };

  render() {
    const { hideModal, params } = this.props;

    const hasChanges = this.hasChanges();

    const list = this.getSharedWith().map(owner => {
      const role = helpers.formatRole(
        this.state.owners[owner.user_id]
          ? this.state.owners[owner.user_id].role
          : owner.role
      );
      const disabled = this.isOwnerDisabled(owner.user_id);
      const canChangeRole = this.canChangeRole(owner.user_id);
      const label = `${owner.user.fname} ${owner.user.lname} `;
      const email = `${
        owner.user.fname && owner.user.lname
          ? `(${owner.user.email})`
          : owner.user.email
      }`;

      return (
        <div
          styleName={disabled ? "listItemDisabled" : "listItem"}
          key={owner.user_id}
        >
          <div styleName="owner">
            <AccountUserIcon />
            <div styleName="label" title={`${label} ${email}`}>
              {label}
              <span styleName="email">{email}</span>
            </div>
          </div>
          {(() => {
            if (disabled) {
              return (
                <div
                  styleName="undo"
                  onClick={() => this.undoDisable(owner.user_id)}
                >
                  undo
                </div>
              );
            } else if (!canChangeRole) {
              return (
                <Menu
                  selectedValue={role}
                  disabled={disabled || !canChangeRole}
                />
              );
            }
            return this.props.showRoles ? (
              <Permissions
                id={owner.user_id}
                onChange={this.handlePermissionChange}
              >
                <Menu selectedValue={helpers.formatRole(role)} />
              </Permissions>
            ) : null;
          })()}
          {(() => {
            if (disabled || !canChangeRole) {
              return <div styleName="iconPlaceholder" />;
            }
            if (this.props.user.id === owner.user_id) {
              return (
                <DeleteConfirmation
                  onConfirm={() => this.removeOwner(owner.user_id)}
                  text="Are you sure you want to remove yourself?"
                >
                  <i className="material-icons remove">close</i>
                </DeleteConfirmation>
              );
            }
            return (
              <i
                className="material-icons remove"
                onClick={() => this.removeOwner(owner.user_id)}
              >
                close
              </i>
            );
          })()}
        </div>
      );
    });

    return (
      <StyleWrapper
        width={570}
        heading={this.props.title}
        hideModal={hideModal}
      >
        {this.state.loading ? (
          <Loading />
        ) : (
          <div>
            <div styleName="well">
              <Invite
                title={this.props.title}
                params={params}
                owners={this.getSharedWith()}
                sendInvites={this.handleSendToOwners}
                showRoles={this.props.showRoles}
                allowNotifyingUsers={this.props.allowNotifyingUsers}
              />
            </div>
            <div styleName="body">
              <div styleName="title">{this.props.selectedLabel}</div>
              <div>
                {list.length ? (
                  list
                ) : (
                  <div styleName="well">
                    <p>{this.props.emptyLabel}</p>
                  </div>
                )}
              </div>
            </div>
            {hasChanges ? (
              <ButtonGroup>
                <Submit title="Save" onClick={this.handleSave} />
                <ButtonOutline title="Cancel" onClick={hideModal} />
              </ButtonGroup>
            ) : (
              <ButtonGroup>
                <Submit title="Done" onClick={hideModal} />
              </ButtonGroup>
            )}
          </div>
        )}
      </StyleWrapper>
    );
  }
}

ShareModal.defaultProps = {
  title: "Invite people to manage",
  showRoles: true,
  allowNotifyingUsers: true,
  selectedLabel: "Has Access",
  emptyLabel: "No managers have been assigned to this record."
};

ShareModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  params: PropTypes.shape({
    eventId: PropTypes.string
  }).isRequired,
  eventDetails: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  showSnackbar: PropTypes.func.isRequired,
  owners: PropTypes.array.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  getOwners: PropTypes.func.isRequired,
  moduleId: PropTypes.string.isRequired,
  recordId: PropTypes.string.isRequired,
  onDone: PropTypes.func.isRequired
};

export default ShareModal;
