import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class EmptyState extends Component {
  render() {
    return (
      <div styleName="wrapper">
        <div styleName="header">No files to view.</div>
      </div>
    );
  }
}

export default EmptyState;
