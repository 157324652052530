import * as R from "ramda";
import { defaultInstanceId } from "./bindInstance";

export const concatReducers = reducers => (state, action) =>
  reducers.reduce((acc, r) => r(acc, action), state);

export const combineReducers = reducers => {
  const reducersList = Object.entries(reducers);
  return (state, action) => {
    let hasChanged = false;
    const newState = reducersList.reduce(
      (newState, [key, reducer]) => {
        const pNewState = reducer(newState[key], action);
        hasChanged = hasChanged || pNewState !== newState[key];
        newState[key] = pNewState;
        return newState;
      },
      { ...state }
    );

    return hasChanged ? newState : state;
  };
};

export const makeReducer = (reducers, iniState, moduleNamespace) => (
  state,
  action
) => {
  if (state === undefined) {
    return iniState;
  }
  if (action.type === `${moduleNamespace + "/"}GlobalUpdate`) {
    return R.mergeAll([state, action.payload]);
  }
  const selectedReducer = reducers[action.type];
  if (typeof selectedReducer !== "function") {
    return state;
  }

  const instanceId =
    R.path(["meta", "instanceId"], action) || defaultInstanceId;

  const namespace = action.namespace;
  const path = [namespace, instanceId];
  const oldState = R.pathOr(
    R.path([namespace, defaultInstanceId], iniState),
    path,
    state
  );
  const newState = selectedReducer(oldState, action);

  if (newState !== oldState) {
    return R.assocPath(path, R.mergeAll([oldState, newState]), state);
  }
  return state;
};
