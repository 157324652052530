import { connect } from "react-redux";
import Controller from "./Controller";

import { cateringRequests } from "redux/modules/portal/reports/selectors";

function mapStateToProps(state, props) {
  return {
    requests: cateringRequests(state),
    view: props.view
  };
}

export default connect(mapStateToProps)(Controller);
