import { REQUEST, RECEIVE, ERROR, DELETE } from "./constants";

import api from "./api";

export function getEmailTemplates(orgId, eventId) {
  return async (dispatch, getState) => {
    const state = getState();
    if (state.fetching) return;
    try {
      dispatch({ type: REQUEST });
      const result = await api.all(state.user.user.credentials, orgId, eventId);
      dispatch({ type: RECEIVE, payload: result.payload });
    } catch (error) {
      dispatch({ type: ERROR, payload: { error } });
    }
  };
}

export function deleteEmailTemplate(eventId, templateId) {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      dispatch({ type: REQUEST });
      const result = await api.delete(
        state.user.user.credentials,
        eventId,
        templateId
      );
      dispatch({ type: DELETE, payload: templateId });
    } catch (error) {
      dispatch({ type: ERROR, payload: { error } });
    }
  };
}

export function updateEmailTemplate(eventId, template) {
  return async (dispatch, getState) => {
    const state = getState();
    if (state.fetching) return;
    try {
      dispatch({ type: REQUEST });
      const result = await api
        .put(state.user.user.credentials, eventId, template)
        .then(() => {
          api.all(state.user.user.credentials, eventId).then(data => {
            dispatch({ type: RECEIVE, payload: data.payload });
          });
        });
    } catch (error) {
      dispatch({ type: ERROR, payload: { error } });
    }
  };
}

export function createEmailTemplate(eventId, template) {
  return async (dispatch, getState) => {
    const state = getState();
    if (state.fetching) return;
    try {
      dispatch({ type: REQUEST });
      const result = await api
        .post(state.user.user.credentials, eventId, template)
        .then(() => {
          api.all(state.user.user.credentials, eventId).then(data => {
            dispatch({ type: RECEIVE, payload: data.payload });
          });
        });
    } catch (error) {
      dispatch({ type: ERROR, payload: { error } });
    }
  };
}
