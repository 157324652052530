import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { ORDER_FULFILLMENT_BLOCK_TYPE } from "components/Event/FormsV2/Form/Views/V3_Edit/Shared/constants/block-types";

import Controller from "./Controller";
import { refreshForm } from "redux/modules/formsV2/form/actions";
import { updateField as updateFormField } from "redux/modules/formsV2/form/fields/actions";

import { form } from "redux/modules/formsV2/form/selectors";

function mapStateToProps(state) {
  const formToPass = form(state);
  const field = formToPass.fields.find(
    f => f.type === ORDER_FULFILLMENT_BLOCK_TYPE
  );

  return {
    form: formToPass,
    eventId: formToPass.event_id,
    field
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      refreshForm,
      updateFormField
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controller);
