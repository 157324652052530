import React from "react";
import { connect } from "react-redux";

import { getters } from "Items/Manage";
import { getPassesGroups } from "Items/Manage/selectors";
import { PASSES_FILTER_ID } from "Passes/Common/constants";

import { ItemFilter } from "ui-kit/FilterControls/View";
import { Text2 } from "components/Base";

const decorate = connect((state, props) => ({
  itemGroups: getPassesGroups(state, props),
  iniSelected: getters.selectedPasses(state, props)
}));

const Passes = ({ itemGroups = [], iniSelected = [], ...styleProps }) => (
  <ItemFilter
    collapsedDefault
    instanceId={PASSES_FILTER_ID}
    itemGroups={itemGroups}
    modalTitle="Select Passes to show"
    iniSelected={iniSelected}
    selectedText="Passes"
    {...styleProps}
  >
    <Text2 bold>Pass Types</Text2>
  </ItemFilter>
);

export default decorate(Passes);
