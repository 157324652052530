import { get } from "lodash";

export default ({ record, fieldId, fieldsHash, allValues }) => {
  get(record, ["values", fieldId, "value", "records"], []).forEach(item => {
    const catalogItem = fieldsHash[fieldId].settings.availableCatalogItems.find(
      ci => ci.id === item.id
    );

    allValues[fieldId] = {
      type: "text",
      value: catalogItem ? catalogItem.value : "(Unnamed Credential)"
    };

    allValues[`${fieldId}_qty`] = {
      type: "number",
      value: item.quantity
    };
  });

  return allValues;
};
