import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const SearchInput = ({
  autoFocus,
  search,
  onChange,
  placeholder,
  containerType,
  style
}) => (
  <div
    styleName={containerType === "dropdown" ? "dropdownContainer" : "container"}
    style={style}
  >
    <i className="material-icons" styleName="icon">
      &#xE8B6;
    </i>
    <input
      autoFocus={autoFocus}
      onChange={onChange}
      value={search}
      placeholder={placeholder}
      styleName={containerType === "dropdown" ? "dropdownInput" : "input"}
    />
  </div>
);

SearchInput.propTypes = {
  autoFocus: PropTypes.bool,
  containerType: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  search: PropTypes.string
};

export default CSSModules(SearchInput, css);
