import React from "react";
import { Div } from "components/Base";

const TypeFieldComponent = ({ labelTxt = "", Icon = null, color = "" }) => (
  <Div display="row.center.center">
    <Icon size={15} color={color} mr={1} />
    <label
      style={{
        fontSize: 14,
        fontWeight: 500,
        color: "#545454",
        margin: 0
      }}
    >
      {labelTxt}
    </label>
  </Div>
);

export default TypeFieldComponent;
