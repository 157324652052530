import PropTypes from "prop-types";
import React from "react";
import PaginationManager from "components/Global/ReactTable/Pagination/Manager";

import ReactTable from "react-table";
import PaginationBar from "components/Global/ReactTable/Pagination/General";

const OrdersTable = ({ activeView, list, columns }) => {
  const countOfRows = list.length;
  const hasRows = Boolean(countOfRows);
  return (
    <PaginationManager
      key={`table_${activeView}_${countOfRows}`}
      pageSize={countOfRows === 0 ? 3 : countOfRows < 10 ? countOfRows : 10}
    >
      <ReactTable
        key={`table_${activeView}_${countOfRows}`}
        className="ReactTable--Lennd ReactTable--Lennd-Simple"
        noDataText="No orders have been added yet"
        columns={columns}
        data={list}
        resizable
        PaginationComponent={hasRows ? PaginationBar : null}
        showPageSizeOptions={hasRows && countOfRows > 5}
        showPagination={hasRows}
        showPaginationBottom={hasRows}
      />
    </PaginationManager>
  );
};

OrdersTable.propTypes = {
  columns: PropTypes.array.isRequired,
  list: PropTypes.array.isRequired
};

export default OrdersTable;
