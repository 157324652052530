import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Layout from "./Layout";
import { actions } from "../model";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { createContext } from "redux-mvc";
import module from "Portal/IntakeSettingsModal";
import FieldsModal from "Modules/SelectRequiredFieldsModal/View";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import EditFieldModal from "Modules/AddEditColumnModal/View";
import AddFieldModal from "Modules/AddEditColumnModal/View";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

const decorate = createContext({
  module,
  options: {
    observedDomains: ["user", "event", "permissions", "organization"],
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    persist: false
  },
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(
        actions.init({
          permissionSetId: this.props.permissionSetId,
          intakeForm: this.props.intakeForm
        })
      );
    }
  },
  handlers: {
    showAddFieldModal({ moduleId }) {
      this.props.showFieldsModal({
        content: (
          <AddFieldModal
            eventId={this.props.params.eventId}
            moduleId={moduleId}
            onSave={field => {
              if (moduleId === STANDARD_MODULE_IDS.accounts.id) {
                this.store.dispatch(
                  actions.showCreatedAccountField({
                    field
                  })
                );
              } else {
                this.store.dispatch(
                  actions.showCreatedContactField({
                    field
                  })
                );
              }
              this.store.dispatch(actions.fetchFields());
            }}
          />
        )
      });
    },
    showAccountFieldsModal({
      fields,
      notDraggableFieldIds,
      requiredFields: required,
      visibleFields,
      fieldOrder
    }) {
      this.props.showFieldsModal({
        content: (
          <FieldsModal
            visibleFields={visibleFields}
            fieldOrder={fieldOrder}
            notDraggableFieldIds={notDraggableFieldIds}
            requiredFields={required}
            columns={fields}
            onClose={this.props.hideFieldsModal}
            onSave={({ requiredFields, visibleColumns, columnOrder }) => {
              this.store.dispatch(
                actions.updateSelectedAccountFields({
                  requiredFields,
                  visibleColumns,
                  columnOrder
                })
              );
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showContactFieldsModal({
      fields,
      notDraggableFieldIds,
      requiredFields: required,
      visibleFields,
      fieldOrder
    }) {
      this.props.showFieldsModal({
        content: (
          <FieldsModal
            visibleFields={visibleFields}
            fieldOrder={fieldOrder}
            notDraggableFieldIds={notDraggableFieldIds}
            requiredFields={required}
            columns={fields}
            onClose={this.props.hideFieldsModal}
            onSave={({ requiredFields, visibleColumns, columnOrder }) => {
              this.store.dispatch(
                actions.updateSelectedContactFields({
                  requiredFields,
                  visibleColumns,
                  columnOrder
                })
              );
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    editField({ moduleId, fieldId }) {
      this.props.showFieldsModal({
        content: (
          <EditFieldModal
            eventId={this.props.params.eventId}
            moduleId={moduleId}
            fieldId={fieldId}
            onSave={() => {
              this.store.dispatch(actions.fetchFields());
            }}
          />
        )
      });
    }
  }
});

export default R.compose(
  connect(
    null,
    { showFieldsModal: showModal, hideFieldsModal: hideModal }
  ),
  withRouter,
  decorate
)(Layout);
