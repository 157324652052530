import PropTypes from "prop-types";
import React from "react";
import * as R from "ramda";
import { groupBy } from "lodash";
import { Div } from "components/Base";
import { ListView, CateringView } from "./View";
import formatVariantTitle from "components/Event/Credentials/Modals/ViewOrder/utils/format-variant-title";
import { MEAL_TYPE_ID } from "utils/item-types";
import moment from "moment";
import FormBaseInput from "../FormBaseInput";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import autobind from "autobind-decorator";
import { addS } from "utils/General";

import ItemDetailsModal from "Orders/ItemDetailsModal/View";

const mapIndexed = R.addIndex(R.map);
const BACKGROUND_COLOR_1 = "white";
const BACKGROUND_COLOR_2 = "#fafafa";

const getDayColor = (dateGroups, day) => {
  const group = dateGroups.filter(g => g.days.includes(day));
  return group.length ? group[0].color : "#eee";
};

class FormOrderItems extends FormBaseInput {
  constructor(props) {
    super(props);

    this.state = {
      selectedVariants: {},
      errors: [],
      isValid: true
    };
  }

  componentDidMount() {
    if (typeof this.props.register === "function") {
      this.props.register(this.validate);
    }
  }

  componentWillUnmount() {
    if (typeof this.props.unregister === "function") {
      this.props.unregister(this.validate);
    }
  }

  @autobind
  async validate() {
    const isValid = await this.isValid();
    this.setState({
      isValid
    });
    return isValid;
  }

  @autobind
  async isValid() {
    const errors = [];

    if (this.props.field.is_required) {
      const countOfAddedItems = this.getQuantityOfItemsAddedToCart();
      if (countOfAddedItems === 0) {
        errors.push(() => (
          <Div>You must add at least one item from this area.</Div>
        ));
      }
    }

    const variantsWithQuantities = Object.keys(
      this.state.selectedVariants
    ).filter(id => this.state.selectedVariants[id]);

    // if we have any items selected that have not been added to cart, raise error
    if (Object.keys(variantsWithQuantities).length) {
      errors.push(({ addItemsToCart }) => (
        <Div>
          <Div>
            You have selected items but haven't added them to your cart.
          </Div>
          <Div>
            <span
              onClick={this.removeAllVariants}
              style={{
                cursor: "pointer",
                borderBottom: "1px dotted #ea4358"
              }}
            >
              Deselect items
            </span>{" "}
            or{" "}
            <span
              style={{
                cursor: "pointer",
                borderBottom: "1px dotted #ea4358"
              }}
              onClick={addItemsToCart}
            >
              add items to cart
            </span>
          </Div>
        </Div>
      ));
    }

    this.setState({
      errors
    });

    return !errors.length;
  }

  getItemBlockLimit = () => {
    const { field } = this.props;
    return field.item_block && field.item_block.limit
      ? {
          limit: field.item_block.limit,
          remaining:
            field.item_block.limit - this.getQuantityOfItemsAddedToCart()
        }
      : null;
  };

  getQuantityOfItemsAddedToCart = variantId => {
    const { field, cart } = this.props;
    if (!cart) return 0;
    let cartItems = cart.filter(
      l => l.created_via_item_block_id === field.item_block.id
    );

    if (variantId) {
      cartItems = cartItems.filter(l => l.variant.id === variantId);
    }

    return R.reduce((a, b) => {
      return a + b.quantity;
    }, 0)(cartItems);
  };

  addItemToCart = item => {
    const limit = this.getItemBlockLimit();

    // if we have a limit and the quantity is greater
    // than the remaining amount don't add to cart
    if (limit && item.quantity > limit.remaining) {
      return false;
    }

    this.props.addItemsToCart({
      submissionId: this.props.submissionId,
      orderId: this.props.order.id,
      lineItems: R.times(
        () => ({
          name: item.variant.item.name,
          quantity: 1,
          variantId: item.variant.id,
          variant: item.variant,
          createdViaItemBlockId: this.props.field.item_block.id,
          price: item.price || 0,
          questions: item.questions,
          zones: item.zones,

          // @NOTE: We also pass this because the cart is virtually updated and the non-camelcase
          // version of the property is needed for referencing
          created_via_item_block_id: this.props.field.item_block.id
        }),
        item.quantity
      )
    });
    this.updateVariantQuantity(item.variant.id, 0);
    return true;
  };

  addItemsToCart = items => {
    const limit = this.getItemBlockLimit();

    // if we have a limit and the quantity is greater
    // than the remaining amount don't add to cart
    if (limit) {
      const isValid = R.compose(
        R.not,
        v => R.gt(v, limit.remaining),
        R.sum,
        R.map(R.prop("quantity"))
      )(items);
      if (!isValid) return false;
    }

    this.props.addItemsToCart({
      submissionId: this.props.submissionId,
      orderId: this.props.order.id,
      lineItems: R.reduce((list, item) => {
        return [
          ...list,
          ...R.times(
            () => ({
              name: item.variant.item.name,
              quantity: 1,
              variantId: item.variant.id,
              variant: item.variant,
              createdViaItemBlockId: this.props.field.item_block.id,
              price: item.price || 0,

              // @NOTE: We also pass this because the cart is virtually updated and the non-camelcase
              // version of the property is needed for referencing
              created_via_item_block_id: this.props.field.item_block.id
            }),
            item.quantity
          )
        ];
      }, [])(items)
    });

    this.updateVariantQuantities(items.map(v => v.variant.id), 0);
    return true;
  };

  updateVariantQuantity = (id, quantity) => {
    // only check limit if not resetting to 0
    if (quantity) {
      // set arbitray upper max to ensure people can't break the system
      if (quantity > 500) return false;

      const limit = this.getItemBlockLimit();

      // if we have a limit and the quantity is greater
      // than the remaining amount don't update qty
      if (limit && quantity > limit.remaining) {
        return false;
      }
    }

    return this.setState(state => {
      state.selectedVariants = {
        ...state.selectedVariants,
        [id]: quantity <= 0 ? 0 : quantity
      };
      state.errors = [];
      state.isValid = true;
      return state;
    });
  };

  updateVariantQuantities = (variantIds, quantity) => {
    const limit = this.getItemBlockLimit();

    return this.setState(state => {
      let qtys = state.selectedVariants;
      // eslint-disable-next-line consistent-return
      variantIds.forEach(variantId => {
        if (limit && quantity > limit.remaining) {
          return false;
        }
        qtys[variantId] = quantity <= 0 ? 0 : quantity;
      });

      state.errors = [];
      state.isValid = true;
      state.selectedVariants = qtys;
      return state;
    });
  };

  onVariantSelect = id => {
    this.setState(state => {
      if (typeof state.selectedVariants[id] !== "undefined") {
        delete state.selectedVariants[id];
      } else {
        state.selectedVariants[id] = null;
      }
      return state;
    });
  };

  isVariantSelected = id => {
    return Boolean(parseInt(this.state.selectedVariants[id], 10));
  };

  /*
 // @NOTE: For reference
  selectAllVariants = () => {
    const activeType = this.props.types.find(
      t => t.id === this.state.selectedTypeId
    );
    this.setState(state => {
      state.selectedVariants = this.getGroupsWithItems(
        activeType.groups
      ).reduce((map, group) => {
        this.getItemsMatchingSearch(group.items).forEach(item => {
          item.variants.forEach(variant => {
            map[variant.id] = state.selectedVariants[variant.id]
              ? state.selectedVariants[variant.id]
              : null;
          });
        });

        return map;
      }, {});

      return state;
    });
  };
  */

  removeAllVariants = () => {
    this.setState({
      selectedVariants: {},
      errors: [],
      isValid: true
    });
  };

  showItemModal = ({
    itemId,
    variantId,
    selectedPriceId,
    variant,
    limit,
    remaining
  }) => {
    this.props.showModal({
      content: (
        <ItemDetailsModal
          itemId={itemId}
          variantId={variantId}
          selectedPriceId={selectedPriceId}
          disableChangingPrice={true}
          limit={limit}
          remaining={remaining}
          showInternalQuestions={false}
          onSave={data => {
            this.addItemToCart({
              variant,
              quantity: data[variantId].quantity,
              price: data[variantId].price,
              questions: data[variantId].questions,
              zones: data[variantId].zones
            });
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const {
      field,
      isEditing,
      isPreviewing,
      isResponseLocked,
      eventDetails
    } = this.props;
    const { selectedVariants, isValid, errors } = this.state;
    const isEditingOrPreviewing = isEditing || isPreviewing;
    const limit = this.getItemBlockLimit();
    let hasMetLimit = false;

    if (limit && limit.remaining <= 0) {
      hasMetLimit = true;
    }

    const disabled = hasMetLimit || isEditingOrPreviewing || isResponseLocked;

    // handle: catering view
    if (field.item_block && field.item_block.item_type_id === MEAL_TYPE_ID) {
      const blockItemsByItemId = groupBy(
        field.item_block && field.item_block.items
          ? field.item_block.items
          : [],
        i => i.variant.item.id
      );
      const days = Object.keys(
        groupBy(eventDetails.module_settings.catering.meal_days, "dayId")
      ).sort();

      const { variantsByItem, totalQuantity } = R.compose(
        ({ variantsByItem, totalQuantity }) => ({
          variantsByItem: R.sortBy(R.prop("itemSortOrder"))(variantsByItem),
          totalQuantity
        }),
        R.reduce(
          (data, itemId) => {
            const blockItems = blockItemsByItemId[itemId];
            const item = blockItems[0].variant.item;

            data.variantsByItem = [
              ...data.variantsByItem,
              {
                id: itemId,
                name: item.name,
                itemSortOrder: item.order,
                variants: R.map(blockItem => {
                  const variant = blockItem.variant;
                  const quantity =
                    selectedVariants[variant.id] >= 0
                      ? selectedVariants[variant.id]
                      : 0;
                  data.totalQuantity = data.totalQuantity + quantity;

                  return {
                    ...variant,
                    toggleOnly: false,
                    inputOnly: true,
                    disabled,
                    name: variant.item.name,
                    color: variant.item.background_color,
                    subname: formatVariantTitle(variant),
                    quantity,
                    count: selectedVariants[variant.id],
                    max: limit ? limit.remaining : undefined,
                    selected: this.isVariantSelected(variant.id),
                    onToggle: () => this.onVariantSelect(variant.id),
                    incrementQuantity: () =>
                      this.updateVariantQuantity(variant.id, quantity + 1),
                    decrementQuantity: () =>
                      this.updateVariantQuantity(variant.id, quantity - 1),
                    updateVariantQuantity: qty =>
                      this.updateVariantQuantity(
                        variant.id,
                        parseInt(qty, 10) || 0
                      ),
                    addToCart: () =>
                      this.addItemToCart({
                        variant,
                        quantity
                      })
                  };
                })(blockItems)
              }
            ];

            return {
              variantsByItem: data.variantsByItem,
              totalQuantity: data.totalQuantity
            };
          },
          { variantsByItem: [], totalQuantity: 0 }
        )
      )(Object.keys(blockItemsByItemId));

      const small = variantsByItem.length > 4;
      const cateringColumns = mapIndexed(({ id, name }, idx) => ({
        id,
        name,
        color: idx % 2 ? BACKGROUND_COLOR_1 : BACKGROUND_COLOR_2,
        small
      }))(variantsByItem);

      const cateringRows = R.compose(
        R.filter(R.prop("countOfValid")),
        R.reduce((rows, day) => {
          const dayColor = getDayColor(eventDetails.date_groups, day);
          const dayName = moment(day).format("ddd");
          const dayNumber = moment(day).format("D");
          const row = {
            dayColor,
            dayName,
            dayNumber,
            small,
            countOfValid: 0
          };

          variantsByItem.forEach((item, itemIdx) => {
            const mealDay = eventDetails.module_settings.catering.meal_days.find(
              m => m.mealId === item.id && m.dayId === day
            );
            const variant = item.variants.find(v =>
              Boolean(
                v.rules.find(
                  r => r.pattern === "is_valid_for" && r.value === day
                )
              )
            );

            const isValid = mealDay && variant;
            const itemHelpers = isValid ? variant : {};

            if (isValid) {
              row.countOfValid = row.countOfValid + 1;
            }

            row[item.id] = {
              bg: itemIdx % 2 ? BACKGROUND_COLOR_1 : BACKGROUND_COLOR_2,
              ...itemHelpers
            };
          });

          return [...rows, row];
        }, [])
      )(days);

      const variantsToAdd = R.reduce((variants, item) => {
        return [
          ...variants,
          ...R.compose(
            R.filter(R.prop("quantity")),
            R.map(variant => ({
              variant,
              quantity: variant.quantity
            }))
          )(item.variants)
        ];
      }, [])(variantsByItem);

      return (
        <CateringView
          {...{
            field,
            disabled,
            name: field.item_block ? field.item_block.name : "",
            description: field.item_block ? field.item_block.description : "",
            limit,
            required: field.is_required,
            // catering
            cateringColumns,
            cateringRows,
            totalQuantity,
            eventDateGroups: eventDetails.date_groups,
            isValid,
            messages: errors,
            addItemsToCart: () => this.addItemsToCart(variantsToAdd)
          }}
        />
      );
    }

    const items = R.compose(
      R.map(blockItem => {
        const variant = blockItem.variant;

        if (variant.questions.length || variant.zones.length) {
          let subname = null;
          if (variant.questions.length) {
            subname = `${blockItem.count_of_questions} Question${addS(
              blockItem.count_of_questions
            )}`;
          }
          if (variant.zones.length) {
            if (variant.questions.length) {
              subname = `${subname} · ${variant.zones.length} Zone${addS(
                variant.zones.length
              )}`;
            } else {
              subname = `${variant.zones.length} Zone${addS(
                variant.zones.length
              )}`;
            }
          }

          // calculate remaining
          const itemLimit = blockItem.limit
            ? blockItem.limit - this.getQuantityOfItemsAddedToCart(variant.id)
            : undefined;

          const itemBlockLimit = this.getItemBlockLimit();
          const itemBlockLimitRemaining = itemBlockLimit
            ? itemBlockLimit.remaining
            : null;

          // calculate limit
          const itemBlockLimitLimit = itemBlockLimit
            ? itemBlockLimit.limit
            : null;
          const overallLimit =
            itemBlockLimitLimit && blockItem.limit
              ? Math.min(itemBlockLimitLimit, blockItem.limit)
              : blockItem.limit
              ? blockItem.limit
              : itemBlockLimitLimit;

          return {
            mode: "multiple",
            id: blockItem.id,
            variant,
            variantId: variant.id,
            itemId: variant.item.id,
            name: variant.display_name,
            subname,
            price: blockItem.price ? blockItem.price.price : null,
            color: variant.item.background_color,
            showItemModal: () => {
              this.showItemModal({
                itemId: variant.item.id,
                variantId: variant.id,
                selectedPriceId: blockItem.price ? blockItem.price.id : null,
                limit: overallLimit,
                remaining:
                  !isNaN(itemLimit) &&
                  !isNaN(itemBlockLimitRemaining) &&
                  itemBlockLimitRemaining !== null
                    ? itemBlockLimitRemaining <= itemLimit
                      ? itemBlockLimitRemaining
                      : itemLimit
                    : !isNaN(itemLimit) && itemLimit !== null
                    ? itemLimit
                    : !isNaN(itemBlockLimitRemaining) &&
                      itemBlockLimitRemaining !== null
                    ? itemBlockLimitRemaining
                    : null,
                variant

                /*
                  name: variant.item.name,
                  color: variant.item.background_color,
                  description: variant.item.description,
                  limit,
                  disabled,
                  getItemBlockLimit: this.getItemBlockLimit,
                  addItemsToCart: this.addItemsToCart,
                  variants: R.compose(
                    R.sortBy(R.prop("order")),
                    R.map(blockItem => {
                      const variant = blockItem.variant;
                      const price = blockItem.price
                        ? blockItem.price.price
                        : null;
                      const itemMax = blockItem.limit
                        ? blockItem.limit -
                          this.getQuantityOfItemsAddedToCart(variant.id)
                        : undefined;

                      return {
                        id: variant.id,
                        variant,
                        variantId: variant.id,
                        itemId: variant.item.id,
                        order: variant.order,
                        name: variant.display_name,
                        price,
                        itemMax,
                        color: variant.item.background_color,
                        subname: formatVariantTitle(variant),
                        hideAddButton: true
                      };
                    })
                  )(blockItems)
                  */
              });
            }
          };
        } else {
          const quantity =
            selectedVariants[variant.id] >= 0
              ? selectedVariants[variant.id]
              : 0;

          const itemBlockMax = limit
            ? limit.remaining - R.sum(R.values(selectedVariants)) + quantity
            : undefined;

          const itemMax = blockItem.limit
            ? blockItem.limit - this.getQuantityOfItemsAddedToCart(variant.id)
            : undefined;

          const isValid = qty => {
            if (itemBlockMax !== undefined && qty > itemBlockMax) return false;
            if (itemMax !== undefined && qty > itemMax) return false;
            return true;
          };

          const price = blockItem.price ? blockItem.price.price : null;

          return {
            mode: "single",
            id: blockItem.id,
            variant,
            variantId: variant.id,
            itemId: variant.item.id,
            name: variant.display_name,
            color: variant.item.background_color,
            subname: formatVariantTitle(variant),
            quantity,
            count: selectedVariants[variant.id],
            itemMax,
            max: itemBlockMax,
            selected: this.isVariantSelected(variant.id),
            price,
            onToggle: () => this.onVariantSelect(variant.id),
            incrementQuantity: () =>
              this.updateVariantQuantity(variant.id, quantity + 1),
            decrementQuantity: () =>
              this.updateVariantQuantity(variant.id, quantity - 1),
            updateVariantQuantity: qty =>
              this.updateVariantQuantity(variant.id, parseInt(qty, 10) || 0),
            addToCart: () =>
              isValid(quantity)
                ? this.addItemToCart({
                    variant,
                    quantity,
                    price
                  })
                : false
          };
        }
      }),
      R.sortBy(R.prop("order"))
    )(field.item_block && field.item_block.items ? field.item_block.items : []);

    const variantsToAdd = R.compose(
      R.filter(R.prop("quantity")),
      R.map(item => ({
        variant: item.variant,
        quantity: item.quantity,
        price: item.price
      }))
    )(items);

    const totalQuantity = R.compose(
      R.sum,
      R.map(R.prop("quantity"))
    )(items);

    // handle: fallback to list view
    return (
      <ListView
        {...{
          field,
          disabled,
          name: field.item_block ? field.item_block.name : "",
          description: field.item_block ? field.item_block.description : "",
          items,
          limit,
          required: field.is_required,
          totalQuantity,
          isValid,
          messages: errors,
          addItemsToCart: () => this.addItemsToCart(variantsToAdd)
        }}
      />
    );

    /*
    let handleSave;
    if (!this.props.isEditing) {
      handleSave = this.handleSave;
    }

    return (
      <FormInputWrapper
        isEditing={this.props.isEditing}
        isValid={this.state.isValid}
        errorMessages={this.state.errors}
        disabled={this.props.disabled}
      >
        <Input
          key={this.props.field.id}
          disabled={this.props.disabled}
          placeholder={this.props.field.settings.placeholder}
          description={this.props.field.settings.description}
          label={this.props.field.name}
          value={this.state.value}
          onChange={this.handleChange}
          onBlur={handleSave}
          required={this.props.field.is_required}
          isAdminField={this.props.field.settings.isAdminField}
          type={this.props.type}
          autoComplete="off"
        />
      </FormInputWrapper>
    );
     */
  }
}

FormOrderItems.propTypes = {
  submissionId: PropTypes.string.isRequired,
  order: PropTypes.object.isRequired,
  field: PropTypes.shape({
    item_block: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          variant: PropTypes.object
        })
      )
    })
  }).isRequired
};

export default FormOrderItems;
