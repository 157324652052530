import React from "react";
import { connect } from "react-redux";

import * as R from "ramda";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import { Div } from "components/Base";
import Tabs from "./Tabs";
import { Calendar, Xml, Json } from "./Content";
import { getters } from "Modules/SubscribeModal";
import Loading from "ui-kit/Loading";

const decorate = connect(state => ({
  selectedTabId: getters.selectedTabId(state),
  saving: getters.saving(state)
}));

const Layout = ({ hideModal, selectedTabId, saving }) => (
  <MiniModalWrapper
    title="Subscribe to Feed"
    hideModal={hideModal}
    width={600}
    height={500}
    bg="white"
    showModal
    stopPropagation
  >
    <Div>
      <Tabs />
      {saving ? (
        <Loading />
      ) : (
        <Div px={8}>
          {R.prop(selectedTabId)({
            calendar: <Calendar />,
            xml: <Xml />,
            json: <Json />
          })}
        </Div>
      )}
    </Div>
  </MiniModalWrapper>
);

export default decorate(Layout);
