import Immutable from "immutable";
import { combineReducers } from "redux";
import { SYNC, SYNC_COMPLETE } from "./constants";
import { ERROR } from "redux/modules/errors/constants";

const syncing = (state = {}, action) => {
  switch (action.type) {
    case SYNC:
      return Immutable.fromJS(state)
        .setIn(["byOrderId", action.payload.orderId], true)
        .toJS();
    case SYNC_COMPLETE:
      return Immutable.fromJS(state)
        .setIn(["byOrderId", action.payload.orderId], false)
        .toJS();
    case ERROR:
      return {};
    default:
      return state;
  }
};

export default combineReducers({
  syncing
});
