import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import IntakeSettingsModal from "Portal/IntakeSettingsModal/View";
import CustomizeSectionsModal from "Portal/CustomizeSectionsModal/View";

const decorate = connect(
  state => ({
    showIntakeSettingsModal: getters.showIntakeSettingsModal(state),
    showCustomizeSectionsModal: getters.showCustomizeSectionsModal(state),
    permissionProfileId: getters.permissionProfileId(state),
    intakeForm: getters.intakeForm(state),
    selectedTypeName: getters.selectedTypeName(state)
  }),
  {
    hideIntakeSettingsModal: () => actions.setShowIntakeSettingsModal(false),
    hideCustomizeSectionsModal: () =>
      actions.setShowCustomizeSectionsModal(false),
    refreshData: actions.refreshData
  }
);

const Layout = ({
  showIntakeSettingsModal,
  showCustomizeSectionsModal,
  hideIntakeSettingsModal,
  hideCustomizeSectionsModal,
  refreshData,
  permissionProfileId,
  intakeForm,
  selectedTypeName
}) => (
  <>
    {showIntakeSettingsModal && (
      <IntakeSettingsModal
        hideModal={hideIntakeSettingsModal}
        permissionSetId={permissionProfileId}
        fetchPermissionSets={refreshData}
        intakeForm={intakeForm}
        selectedTypeName={selectedTypeName}
      />
    )}
    {showCustomizeSectionsModal && (
      <CustomizeSectionsModal
        hideCustomizeSectionsModal={hideCustomizeSectionsModal}
        selectedTypeName={selectedTypeName}
        permissionSetId={permissionProfileId}
        fetchPermissionSets={refreshData}
      />
    )}
  </>
);

export default decorate(Layout);
