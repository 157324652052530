import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const ErrorList = ({
  children,
  type,
  isValid,
  errorMessages,
  allowNewRows,
  view,
  rows
}) => {
  // if single card, no need to show errors but still mark as invalid
  if (!allowNewRows && view === "card" && rows.length === 1) {
    return <div data-section-error={!isValid}>{children}</div>;
  }

  const messages =
    errorMessages && errorMessages.length
      ? errorMessages.map(message => (
          <li styleName="errorText" key={message}>
            {message}
          </li>
        ))
      : [];
  return (
    <div
      data-section-error={!isValid}
      styleName={
        isValid
          ? ""
          : type === "table"
            ? "containerInvalidTable"
            : "containerInvalid"
      }
    >
      {children}
      {!isValid ? (
        <div styleName="errors">
          {messages.length ? <ul>{messages}</ul> : ""}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CSSModules(ErrorList, css);
