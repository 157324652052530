import { takeEvery, put, all, fork, select } from "redux-saga/effects";
import * as R from "ramda";
import { actions, getters } from "ui-kit/TreeTable/model";

const getHasChildFolder = ({ id, items, type, order }) => {
  if (type === "page") {
    return false;
  }

  const children = R.propOr([], id, order);
  return R.compose(
    R.any(R.propEq("type", "folder")),
    R.filter(item => R.any(childId => childId === item.id, children))
  )(items);
};

const reorder = function*({
  payload: {
    movedId,
    droppedId,
    droppedParentId,
    movedParentId,
    movedType,
    droppedLevel,
    isDropPlace = false,
    droppedLastItem = false
  }
}) {
  if (movedId !== droppedId) {
    const order = yield select(getters.order);
    const items = yield select(getters.items);

    const hasChildFolder = getHasChildFolder({
      id: movedId,
      items,
      type: movedType,
      order
    });

    if (droppedLastItem) {
      if (movedParentId === "root") {
        yield put(
          actions.reorderRootChildren({
            movedId,
            droppedId,
            parentId: movedParentId
          })
        );
      } else {
        yield put(
          actions.movePageIntoFolder({
            movedId,
            droppedId: "root",
            movedParentId
          })
        );
      }
    } else if (isDropPlace) {
      if (droppedParentId === movedParentId) {
        yield put(
          actions.reorderSameParentChildren({
            movedId,
            droppedId,
            parentId: movedParentId
          })
        );
      } else if (
        movedType === "page" ||
        droppedLevel === 0 ||
        (droppedLevel === 1 && !hasChildFolder)
      ) {
        yield put(
          actions.moveToDifferentParent({
            movedId,
            droppedId,
            droppedParentId,
            movedParentId
          })
        );
      }
    } else if (
      movedType === "page" ||
      (droppedLevel === 0 && !hasChildFolder)
    ) {
      yield put(
        actions.movePageIntoFolder({
          movedId,
          droppedId,
          movedParentId
        })
      );
    }

    yield put(actions.saveAfterReordering());
  }
};

const watchReorder = function*() {
  yield takeEvery(actions.reorder.type, reorder);
};

const rootSaga = function*() {
  yield all([fork(watchReorder)]);
};

export default rootSaga;
