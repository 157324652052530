import api from "./api";
import { REQUEST, RECEIVE, INVALIDATE } from "./constants";
import { isFetching } from "./selectors";
import { registerError } from "redux/modules/errors/actions";

export function getOrdersForContact({ eventId, contactId, typeIds }) {
  return async (dispatch, getState) => {
    if (isFetching(getState(), contactId)) return null;
    try {
      dispatch({
        type: REQUEST,
        payload: {
          recordId: contactId
        }
      });
      const result = await api.getOrdersForContact(
        getState().user.user.credentials,
        {
          eventId,
          recordId: contactId,
          typeIds
        }
      );
      dispatch({
        type: RECEIVE,
        payload: {
          recordId: contactId,
          orders: result.payload
        }
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting orders for contact"
          }
        ])
      );
    }
  };
}

export function getOrdersForAccount({ eventId, accountId, typeIds }) {
  return async (dispatch, getState) => {
    if (isFetching(getState(), accountId)) return null;
    try {
      dispatch({
        type: REQUEST,
        payload: {
          recordId: accountId
        }
      });
      const result = await api.getOrdersForAccount(
        getState().user.user.credentials,
        {
          eventId,
          recordId: accountId,
          typeIds
        }
      );
      dispatch({
        type: RECEIVE,
        payload: {
          recordId: accountId,
          orders: result.payload
        }
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting orders for account"
          }
        ])
      );
    }
  };
}

export function invalidateOrders(recordId) {
  return {
    type: INVALIDATE,
    payload: {
      recordId
    }
  };
}
