import { createSelector } from "reselect";

import * as R from "ramda";

import { getters } from "Items/AddQuestionModal";

import { PAGES, HEIGHTS, ADD_QUESTION } from "Items/AddQuestionModal/constants";

export const getAddQuestionModalHeight = createSelector(
  getters.itemQuestionModalPage,
  itemQuestionModalPage => {
    return 500;
    /*
    if (itemQuestionModalPage === PAGES.ADD_QUESTION) {
      return 500;
    }
    return 696;
    */
  }
);

export const getAddItemQuestionBodyHeight = createSelector(
  getAddQuestionModalHeight,
  modalHeight => modalHeight - (HEIGHTS.FOOTER + HEIGHTS.HEADER)
);

export const isSelectFieldsPage = createSelector(
  getters.itemQuestionModalPage,
  itemQuestionModalPage => itemQuestionModalPage === PAGES.SELECT_FIELDS
);

export const isAddQuestionPage = createSelector(
  getters.itemQuestionModalPage,
  itemQuestionModalPage => itemQuestionModalPage === PAGES.ADD_QUESTION
);

export const isExistingSetPage = createSelector(
  isAddQuestionPage,
  getters.addQuestion,
  (addQuestionPage, addQuestion) =>
    addQuestionPage && addQuestion === ADD_QUESTION.EXISTING_SET
);

export const isNewSetPage = createSelector(
  isAddQuestionPage,
  getters.addQuestion,
  (addQuestionPage, addQuestion) =>
    addQuestionPage && addQuestion === ADD_QUESTION.NEW_SET
);

export const getFilteredExistingSets = createSelector(
  getters.sets,
  getters.existingSetsFilter,
  getters.selectedQuestionSetIds,
  (sets, existingSetsFilter, selectedQuestionSetIds) => {
    if (R.isEmpty(existingSetsFilter)) {
      return sets.map(s => ({
        ...s,
        selected: selectedQuestionSetIds.includes(s.id)
      }));
    }
    return R.compose(
      R.map(s => ({
        ...s,
        selected: selectedQuestionSetIds.includes(s.id)
      })),
      R.filter(field =>
        R.contains(R.toLower(existingSetsFilter), R.toLower(field.name))
      )
    )(sets);
  }
);

export const getShowPrices = createSelector(
  getters.questionType,
  questionType => questionType === "checkbox" || questionType === "dropdown"
);
