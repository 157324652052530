export const NAMESPACE = "ActivityLogModule";

export const ACTIVITY_INSTANCE_ID = "ActivityLogInstance";

export const TABLE_LOGS_INSTANCE_ID = "LogsTableInstance-";

export const COL_WIDTH = 160;

export const FILTERS = {
  BY_ACTIVITY_TYPE: "filterByType",
  BY_USERS: "filterByUser"
};

export const ACTIVITY_LOG_IDS = {
  RECORD: "friendly_id",
  TIMESTAMP: "timestamp",
  RECIPIENT: "recipient",
  EMAIL: "email",
  ACTIONS: "action"
};

export const RECORD_TYPES = {
  ADD_RECORD: "added_record",
  UPDATE_RECORD: "updated_record",
  DELETE_RECORD: "deleted_record",
  INVITE_TO_EVENT: "invite_person_to_event",
  CREATE_ORDER: "create_order",
  CANCEL_ORDER: "cancel_order",
  SEND_EMAIL: "send_email"
};

export const ACTIVITY_TYPE_FILTERS = [
  {
    id: RECORD_TYPES.ADD_RECORD,
    name: "Record Added"
  },
  {
    id: RECORD_TYPES.UPDATE_RECORD,
    name: "Record Updated"
  },
  {
    id: RECORD_TYPES.DELETE_RECORD,
    name: "Record Deleted"
  },
  {
    id: RECORD_TYPES.INVITE_TO_EVENT,
    name: "Invite Person to Event"
  },
  {
    id: RECORD_TYPES.CREATE_ORDER,
    name: "Order Created"
  },
  {
    id: RECORD_TYPES.CANCEL_ORDER,
    name: "Order Canceled"
  },
  {
    id: RECORD_TYPES.SEND_EMAIL,
    name: "Send Email"
  }
];
