import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { getters } from "EventLight/Inventory/Dashboard";
import { Div } from "components/Base";
import CategoryCollapsable from "./CategoryCollapsable";
import CategoryCardLoading from "./CategoryCardLoading";

const decorate = connect(state => ({
  categories: getters.categories(state),
  loading: getters.loading(state),
  scrollToCategoryId: getters.scrollToCategoryId(state)
}));

const Body = ({ categories, loading, scrollToCategoryId, handlers }) => {
  useEffect(() => {
    if (!R.isNil(scrollToCategoryId)) {
      const categoryNode = document.getElementById(scrollToCategoryId);
      categoryNode.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, [scrollToCategoryId]);
  return (
    <Div
      width={1}
      flex={1}
      height={1}
      p={2}
      pr={10}
      style={{
        overflowY: "auto",
        height: "calc(100% - 80px)",
        minWidth: "680px"
      }}
    >
      {loading ? (
        <Div>
          <CategoryCardLoading />
          <CategoryCardLoading />
          <CategoryCardLoading />
        </Div>
      ) : (
        R.map(
          category => (
            <CategoryCollapsable
              key={category.id}
              category={category}
              handlers={handlers}
            />
          ),
          categories
        )
      )}
    </Div>
  );
};

export default decorate(Body);
