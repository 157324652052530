import React from "react";
import CSSModules from "react-css-modules";
import css from "Schedules/Schedule/View/styles.scss";

import { Div, Text2, VisibleIcon } from "components/Base";

const SelectFields = ({ onClick, ...styleProps }) => {
  return (
    <Div
      display="row.center.center"
      py={2}
      onClick={onClick}
      bra={1}
      className={css.button2}
      {...styleProps}
    >
      <Div flex={1} display="row.flex-start.center" pl={3}>
        <Div display="row.flex-start.center" mb={1}>
          <VisibleIcon mr={2} color="purple4" />
          <Text2 color="white" bold>
            Show/Hide Fields
          </Text2>
        </Div>
      </Div>
    </Div>
  );
};

export default CSSModules(SelectFields, css);
