import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import { debounce } from "lodash";

import { noop } from "utils/General";

import { actions } from "ui-kit/Form/model";
import { getValue } from "ui-kit/Form/selectors";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import { wsp } from "ui-kit/Theme";
import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";

import TelephoneInput from "@lennd/react-telephone-input";

import { Input } from "../Input";

import CSSModules from "react-css-modules";

import css from "./styles.scss";

const decorate = R.compose(
  CSSModules(css),
  WithFormInstanceConsumer({ fieldType: FIELD_TYPES.VALUE }),
  connect(
    (state, props) => ({
      value: getValue(state, props)
    }),
    bindInstance({
      setIniValue: actions.setIniValue,
      onChange: actions.setFieldValue,
      onFocus: actions.focusField,
      onBlur: actions.blurField
    })
  )
);

export const UCPhoneInput = ({
  value = "",
  iniValue,
  fieldId,
  fieldType,
  type = "mask",
  placeholder = "(123) 456-7890",
  onChange = noop,
  setIniValue = noop,
  onFocus = noop,
  onBlur = noop,
  autoFocus = false,
  ...styleProps
}) => {
  useEffect(() => {
    setIniValue(iniValue, {
      meta: { fieldType, fieldId }
    });
  }, []);

  if (type === "mask") {
    return (
      <Input
        fieldType={FIELD_TYPES.US_PHONE}
        fieldId={fieldId}
        placeholder={placeholder}
        type="tel"
        debounceMs={100}
        maxLength={17}
        autoFocus={autoFocus}
        {...wsp(styleProps)}
      />
    );
  } else {
    return (
      <div styleName="phoneEditor">
        <TelephoneInput
          value={value}
          pattern="\+.+"
          defaultCountry="us"
          flagsImagePath="https://d2dks4tzxs6xee.cloudfront.net/img/flags.png"
          onChange={debounce(
            val =>
              onChange(val, {
                meta: { fieldType, fieldId }
              }),
            300
          )}
          onFocus={() => onFocus(null, { meta: { fieldId, fieldType } })}
          onBlur={() => onBlur(null, { meta: { fieldId, fieldType } })}
          inputProps={{ autoFocus }}
          {...wsp(styleProps)}
        />
      </div>
    );
  }
};

export const PhoneInput = decorate(UCPhoneInput);
