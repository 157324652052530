import React, { Component, cloneElement } from "react";
import jQuery from "jquery";

class ExportContainer extends Component {
  exportContent = async (contentType, moduleName, rows, fields) => {
    const { showSnackbar, exportSpreadsheetArray } = this.props;
    showSnackbar({ message: "Exporting...", action: "OK" });

    const sheet = rows.reduce(
      (sheet, row) => {
        sheet.push(fields.map(field => row[field.id]));
        return sheet;
      },
      [fields.map(field => field.name)]
    );

    try {
      const { url } = await exportSpreadsheetArray({
        contentType,
        source: {
          type: "records"
        },
        sheet
      });
      if (!url) throw new Error();
      /* eslint-disable no-underscore-dangle */
      window.location = url;
      /* eslint-enable no-underscore-dangle */
    } catch (e) {
      showSnackbar({ message: "Oops, there was an error.", action: "OK" });
    }
  };

  exportNestedReport = async ({ reportId, options }) => {
    const { showSnackbar, exportReport } = this.props;
    showSnackbar({ message: "Exporting...", action: "OK" });

    try {
      const { payload } = await exportReport({
        reportId,
        options
      });

      if (!payload) throw new Error();
      /* eslint-disable no-underscore-dangle */
      window.location = payload;
      /* eslint-enable no-underscore-dangle */
    } catch (e) {
      showSnackbar({ message: "Oops, there was an error.", action: "OK" });
    }
  };

  printNestedReport = async ({ reportId, options }) => {
    const { showSnackbar, printReport } = this.props;
    showSnackbar({ message: "Opening...", action: "OK" });

    try {
      const { payload } = await printReport({
        reportId,
        options
      });

      if (!payload) throw new Error();

      const preview = window.open();
      preview.document.title = "Report";
      jQuery(preview.document.body).html(payload);
      preview.focus();
      preview.print();
      //preview.close();
    } catch (e) {
      showSnackbar({ message: "Oops, there was an error.", action: "OK" });
    }
  };

  render() {
    return cloneElement(this.props.children, {
      printNestedReport: this.printNestedReport,
      exportNestedReport: this.exportNestedReport,
      exportSheet: this.exportContent
    });
  }
}

export default Child => props => (
  <ExportContainer {...props}>
    <Child {...props} />
  </ExportContainer>
);
