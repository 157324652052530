import React from "react";
import * as R from "ramda";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import ScrollableModalBody from "components/Global/Modal/Layout/ScrollableBody";

import {
  Div,
  DarkTab,
  BigFilledButton,
  BigOutlineButton,
  LoadingIcon,
  InfoIcon
} from "components/Base";

const View = ({
  canSave,
  onSave,
  hideModal,
  loading,
  saving,
  heading,
  subheading,
  tabs,
  Body
}) => {
  const Header = subheading ? (
    <Div display="column">
      <Div>{heading}</Div>
      <Div fs={1}>{subheading}</Div>
    </Div>
  ) : (
    heading
  );

  return (
    <StyleWrapper
      heading={Header}
      hideModal={hideModal}
      bodyStyles={{ padding: 0 }}
      width={580}
    >
      {loading ? (
        <Div pt={2} px={5} bb={1} bc="gray2" display="row" width={1}>
          <Div display="row.center.center" flex={1} py={10}>
            <LoadingIcon size={60} color="neutral4" />
          </Div>
        </Div>
      ) : (
        <Div>
          <Div pt={2} px={5} bb={1} bc="gray2" display="row">
            {R.map(({ id, name, active, enabled, onClick, tooltip }) => (
              <DarkTab
                key={id}
                fs={2}
                display="row.center.center"
                active={active}
                onClick={onClick}
                style={
                  enabled
                    ? undefined
                    : {
                        opacity: ".5"
                      }
                }
              >
                {tooltip ? (
                  <Div display="row.center.center">
                    {name}{" "}
                    <InfoIcon
                      ml={1}
                      tooltip={tooltip}
                      color="gray5"
                      size={15}
                    />
                  </Div>
                ) : (
                  name
                )}
              </DarkTab>
            ))(tabs)}
          </Div>
          <ScrollableModalBody
            style={{
              minHeight: 400,
              display: "flex",
              backgroundColor: "rgba(224, 224, 224, 0.19)"
            }}
          >
            <Div px={6} py={4} flex={1}>
              {Body}
            </Div>
          </ScrollableModalBody>
          <Div display="row.space-between.center" py={3} px={5}>
            <Div display="row.flex-start.center">
              <BigFilledButton
                bg="altB5"
                mr={1}
                onClick={onSave}
                disabled={!canSave}
              >
                Save
              </BigFilledButton>
              <BigOutlineButton onClick={hideModal}>Cancel</BigOutlineButton>
            </Div>
            {saving ? (
              <Div display="row.flex-start.center" ml={2} width={1}>
                <LoadingIcon color="gray6" mr={2} size={18} />
                <Div fs={3} color="gray6">
                  Saving...
                </Div>
              </Div>
            ) : null}
          </Div>
        </Div>
      )}
    </StyleWrapper>
  );
};

export default View;
