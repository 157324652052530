import React, { Component } from "react";
import View from "./View";
import STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import STANDARD_MODULE_FIELDS from "utils/standard-module-field-ids";
import * as R from "ramda";
import { isEqual } from "lodash";
import AccountsApi from "redux/modules/accounts/list/api";
import ContactsApi from "utils/EventContacts/WebAPIUtils";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";
import resolveEditor from "components/Global/StandAloneEditors/utils/resolveEditor";
import Helpers from "utils/Global/Helpers";
import debounce from "debounce-promise";
import fullnameToFirstLast from "utils/Contacts/fullname-to-first-last";

const formatNewRecordName = (moduleId, record) => {
  if (moduleId === STANDARD_MODULES.accounts.id) {
    return R.path(["values", STANDARD_MODULE_FIELDS.ACCOUNTS.NAME, "value"])(
      record
    );
  }

  return [
    R.path(["values", STANDARD_MODULE_FIELDS.CONTACTS.FIRST_NAME, "value"])(
      record
    ),
    R.path(["values", STANDARD_MODULE_FIELDS.CONTACTS.LAST_NAME, "value"])(
      record
    )
  ]
    .filter(v => v && v.length)
    .join(" ");
};

const formatLookupRecord = record => ({
  id: record.id,
  name: record.name,
  type: record.type ? record.type.name : null,
  value: record.id,
  label: record.name || ""
});

const constructState = props => {
  return !props.order.id
    ? {
        saving: false,
        loading: true,
        fieldValues: {}
      }
    : {
        saving: false,
        loading: true,
        isAllocation: props.order.is_allocation ? true : false,
        orderType: props.order.order_type,
        customerAccount: props.order.customer.account_id
          ? formatLookupRecord({
              id: props.order.customer.account_id,
              name: props.order.customer.account_name
            })
          : null,
        customerContact: props.order.customer.contact_id
          ? formatLookupRecord({
              id: props.order.customer.contact_id,
              name: [
                props.order.customer.first_name,
                props.order.customer.last_name
              ]
                .filter(v => v && v.length)
                .join(" ")
            })
          : null,
        customerContactEmail: {
          type: "text",
          value: props.order.customer.email
        },
        fulfillmentMethod: props.order.shipping_method,
        fieldValues: {},
        pickupPersonName: {
          type: "text",
          value: [
            props.order.pickup_person_fname,
            props.order.pickup_person_lname
          ]
            .filter(v => v)
            .join(" ")
        },
        shippingAddressLine1: {
          type: "text",
          value: props.order.shipping_address_line_1
        },
        shippingAddressLine2: {
          type: "text",
          value: props.order.shipping_address_line_2
        },
        shippingCity: { type: "text", value: props.order.shipping_city },
        shippingState: { type: "text", value: props.order.shipping_state },
        shippingZip: { type: "text", value: props.order.shipping_zip },
        shippingCountry: { type: "text", value: props.order.shipping_country },
        requireAssignment: props.order.require_assignment
      };
};

class Controller extends Component {
  constructor(props) {
    super(props);

    const wait = 500; // milliseconds
    const onSearchContacts = inputValue => this.onSearchContacts(inputValue);
    this.debouncedOnSearchContacts = debounce(onSearchContacts, wait, {
      leading: false
    });

    const onSearchAccounts = inputValue => this.onSearchAccounts(inputValue);
    this.debouncedOnSearchAccounts = debounce(onSearchAccounts, wait, {
      leading: false
    });

    this.state = constructState(props);
  }

  async componentDidMount() {
    await Promise.all([
      this.props.getOrder(this.props.orderId),
      this.props.getFields({
        moduleId: STANDARD_MODULES.orders.id,
        options: {
          eventId: this.props.eventDetails.id
        }
      })
    ]);

    this.setState({ loading: false });
  }

  componentDidUpdate(oldProps) {
    if (!isEqual(oldProps.order, this.props.order)) {
      this.setState(constructState(this.props));
    }
  }

  showAddRecordModal = (
    moduleId,
    values,
    stateValue,
    statePropertyToUpdate
  ) => {
    this.props.showModal({
      content: (
        <AddRecordModal
          moduleId={moduleId}
          onSave={createdRecord => {
            if (createdRecord) {
              this.setState({
                [statePropertyToUpdate]: formatLookupRecord({
                  id: createdRecord.id,
                  name: formatNewRecordName(moduleId, createdRecord),
                  type: createdRecord.type.name
                }),
                customerContactEmail:
                  moduleId === STANDARD_MODULES.contacts.id
                    ? createdRecord.values[
                        STANDARD_MODULE_FIELDS.CONTACTS.EMAIL
                      ]
                    : this.state.customerContactEmail
              });
            }
          }}
          values={values}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  handleSave = async () => {
    if (this.state.saving) return false;

    this.setState({
      saving: true
    });

    const pickupPersonName = R.pathOr("", ["pickupPersonName", "value"])(
      this.state
    );
    const pickupPersonFname = pickupPersonName
      .split(" ")
      .slice(0, -1)
      .join(" ");
    const pickupPersonLname = pickupPersonName
      .split(" ")
      .slice(-1)
      .join(" ");

    const payload = {
      orderId: this.props.order.id,
      eventId: this.props.eventDetails.id,
      order: {
        isAllocation: this.state.isAllocation,
        orderType: this.state.orderType,
        requireAssignment: this.state.requireAssignment,
        customerAccountId: R.pathOr(null, ["customerAccount", "id"])(
          this.state
        ),
        customerContactId: R.pathOr(null, ["customerContact", "id"])(
          this.state
        ),
        customerContactEmail:
          this.state.customerContactEmail &&
          this.state.customerContactEmail.value &&
          this.state.customerContactEmail.value.length
            ? this.state.customerContactEmail
            : null,
        fulfillmentMethod: this.state.fulfillmentMethod,
        relatedRecord: {
          values: this.state.fieldValues
        },
        pickupPersonFname,
        pickupPersonLname,
        shippingAddressLine1: this.state.shippingAddressLine1.value,
        shippingAddressLine2: this.state.shippingAddressLine2.value,
        shippingCity: this.state.shippingCity.value,
        shippingState: this.state.shippingState.value,
        shippingZip: this.state.shippingZip.value,
        shippingCountry: this.state.shippingCountry.value
      }
    };

    const order = await this.props.updateOrder(payload);

    if (this.props.onDone) this.props.onDone(order);

    this.props.hideModal();

    return true;
  };

  onChangeOrderType = orderType =>
    this.setState({ orderType, requireAssignment: false });

  onChangeOrderField = (fieldId, value) =>
    this.setState(state => {
      state.fieldValues[fieldId] = value;
      return state;
    });

  onChangeShippingField = (fieldId, value) =>
    this.setState(state => {
      state[fieldId] = value;
      return state;
    });

  onChangeFulfillmentMethod = fulfillmentMethod =>
    this.setState({ fulfillmentMethod });

  onChangeCustomerAccount = customerAccount =>
    this.setState({ customerAccount });

  onChangeCustomerContact = customerContact =>
    this.setState({
      customerContact,
      customerContactEmail: {
        type: "text",
        value: customerContact.email
      }
    });

  onChangeCustomerContactEmail = value =>
    this.setState({ customerContactEmail: value });

  onCreateCustomerAccount = customerAccount =>
    this.showAddRecordModal(
      STANDARD_MODULES.accounts.id,
      {
        [STANDARD_MODULE_FIELDS.ACCOUNTS.NAME]: {
          type: "text",
          value: customerAccount
        }
      },
      customerAccount,
      "customerAccount"
    );

  onCreateCustomerContact = customerContact => {
    const { fname, lname } = fullnameToFirstLast(customerContact);

    return this.showAddRecordModal(
      STANDARD_MODULES.contacts.id,
      {
        [STANDARD_MODULE_FIELDS.CONTACTS.FIRST_NAME]: {
          type: "text",
          value: fname || ""
        },
        [STANDARD_MODULE_FIELDS.CONTACTS.LAST_NAME]: {
          type: "text",
          value: lname || ""
        }
      },
      customerContact,
      "customerContact"
    );
  };

  onSearchAccounts = async inputValue => {
    const result = await AccountsApi.search(
      null,
      this.props.eventDetails.id,
      inputValue,
      this.props.userCredentials
    );

    return R.compose(
      R.sortBy(R.prop("label")),
      R.map(formatLookupRecord)
    )(result.accounts);
  };

  onSearchContacts = async inputValue => {
    const contacts = await ContactsApi.searchContacts(
      this.props.userCredentials,
      null,
      this.props.eventDetails.id,
      inputValue
    );

    return R.compose(
      R.sortBy(R.prop("label")),
      R.map(record => ({
        id: record.id,
        email: record.values[STANDARD_MODULE_FIELDS.CONTACTS.EMAIL],
        label: [
          record.values[STANDARD_MODULE_FIELDS.CONTACTS.FIRST_NAME],
          record.values[STANDARD_MODULE_FIELDS.CONTACTS.LAST_NAME]
        ]
          .filter(v => v && v.length)
          .join(" "),
        value: record.id
      }))
    )(contacts);
  };

  toggleIsAllocation = () =>
    this.setState({
      isAllocation: !this.state.isAllocation,
      orderType: !this.state.isAllocation ? "group" : this.state.orderType
    });

  updateRequireAssignment = requireAssignment =>
    this.setState({
      requireAssignment
    });

  render() {
    const {
      loading,
      saving,
      isAllocation,
      orderType,
      customerAccount,
      customerContact,
      customerContactEmail,
      fulfillmentMethod,
      fieldValues,
      pickupPersonName,
      shippingAddressLine1,
      shippingAddressLine2,
      shippingCity,
      shippingState,
      shippingZip,
      shippingCountry,
      requireAssignment
    } = this.state;
    const { hideModal, eventDetails, orderCustomFields } = this.props;

    const fulfillmentMethods = [
      {
        id: "pickup",
        name: "Pickup/Will Call",
        selected: fulfillmentMethod === "pickup",
        onSelect: () => this.onChangeFulfillmentMethod("pickup")
      },
      {
        id: "mail",
        name: "Mail/Ship",
        selected: fulfillmentMethod === "mail",
        onSelect: () => this.onChangeFulfillmentMethod("mail")
      }
    ];

    const orderFields = R.map(field => ({
      id: field.id,
      name: field.name,
      Editor: resolveEditor(field),
      editorProps: resolveEditorProps(field, eventDetails),
      onChange: value => this.onChangeOrderField(field.id, value),
      value: fieldValues[field.id]
    }))(orderCustomFields);

    const isValidStep =
      orderType === "individual"
        ? Boolean(customerContact) &&
          customerContactEmail &&
          Helpers.isValidEmail(customerContactEmail.value)
        : Boolean(customerAccount) &&
          Boolean(customerContact) &&
          customerContactEmail &&
          Helpers.isValidEmail(customerContactEmail.value);

    const pickupFields = [
      {
        id: "pickupPersonName",
        name: "Pickup Person",
        Editor: resolveEditor({ type: "text" }),
        editorProps: {
          maxLength: 75
        },
        onChange: value =>
          this.onChangeShippingField("pickupPersonName", value),
        value: pickupPersonName
      }
    ];

    const shippingFields = [
      {
        id: "shippingAddressLine1",
        name: "Address Line 1",
        Editor: resolveEditor({ type: "text" }),
        editorProps: {
          maxLength: 75
        },
        onChange: value =>
          this.onChangeShippingField("shippingAddressLine1", value),
        value: shippingAddressLine1
      },
      {
        id: "shippingAddressLine2",
        name: "Address Line 2",
        Editor: resolveEditor({ type: "text" }),
        editorProps: {
          maxLength: 45
        },
        onChange: value =>
          this.onChangeShippingField("shippingAddressLine2", value),
        value: shippingAddressLine2
      },
      {
        id: "shippingCity",
        name: "City",
        Editor: resolveEditor({ type: "text" }),
        editorProps: {},
        onChange: value => this.onChangeShippingField("shippingCity", value),
        value: shippingCity
      },
      {
        id: "shippingState",
        name: "State",
        Editor: resolveEditor({ type: "text" }),
        editorProps: {},
        onChange: value => this.onChangeShippingField("shippingState", value),
        value: shippingState
      },
      {
        id: "shippingZip",
        name: "Zip Code",
        Editor: resolveEditor({ type: "text" }),
        editorProps: {},
        onChange: value => this.onChangeShippingField("shippingZip", value),
        value: shippingZip
      },
      {
        id: "shippingCountry",
        name: "Country",
        Editor: resolveEditor({ type: "text" }),
        editorProps: {},
        onChange: value => this.onChangeShippingField("shippingCountry", value),
        value: shippingCountry
      }
    ];

    return (
      <View
        {...{
          onSave: this.handleSave,
          onChangeOrderType: this.onChangeOrderType,
          onChangeCustomerAccount: this.onChangeCustomerAccount,
          onChangeCustomerContact: this.onChangeCustomerContact,
          onChangeCustomerContactEmail: this.onChangeCustomerContactEmail,
          onCreateCustomerAccount: this.onCreateCustomerAccount,
          onCreateCustomerContact: this.onCreateCustomerContact,
          onSearchAccounts: this.debouncedOnSearchAccounts,
          onSearchContacts: this.debouncedOnSearchContacts,
          toggleIsAllocation: this.toggleIsAllocation,
          toggleRequireAssignment: () =>
            this.updateRequireAssignment(!requireAssignment),
          //
          loading,
          saving,
          hideModal,
          isAllocation,
          isValidStep,
          orderType,
          customerAccount,
          customerContact,
          customerContactEmail,
          fulfillmentMethod,
          fulfillmentMethods,
          requireAssignment,
          orderFields,
          pickupFields,
          shippingFields
        }}
      />
    );
  }
}

export default Controller;
