import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import { actions, getters } from "Modules/AddEditColumnModal/model";
import CalculatedNumber from "components/Global/Table3/ModalColumn/CalculatedNumberSettings";
import { noop } from "utils/General";
import css from "./style.scss";

const decorate = R.compose(
  connect(
    (state, props) => ({
      calculatedNumberSettings: getters.calculatedNumberSettings(state, props),
      fields: getters.fields(state, props)
    }),
    {
      setCalculatedNumberSettings: actions.setCalculatedNumberSettings
    }
  ),
  CSSModules(css)
);

const CalculatedNumberSettings = ({
  calculatedNumberSettings,
  setCalculatedNumberSettings = noop,
  fields
}) => (
  <div styleName="dropdownOptionsWrapper">
    <CalculatedNumber
      columns={fields}
      value={calculatedNumberSettings}
      onChange={setCalculatedNumberSettings}
    />
  </div>
);

export default decorate(CalculatedNumberSettings);
