/* eslint no-underscore-dangle: "off" */

import PropTypes from "prop-types";
import React, { Component } from "react";
import * as R from "ramda";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { MODULES } from "components/Global/NavIcons";

import About from "components/Global/CRM/About";
import RecordOwners from "components/Global/CRM/RecordOwners";
import Account from "components/Event/Accounts/Account/ActionTag";
import PrimaryContacts from "components/Event/Accounts/Account/PrimaryContacts";
import { withProps } from "utils/General";
import ProfileViewTemplate from "Accounts/Profile/View/Layout";

import { Text5, Text2, Div } from "components/Base";
import ClickToCopy from "components/Global/ClickToCopy";

import Header from "components/Global/CRM/Card/Header";
import IconTitle from "components/Global/CRM/Card/IconTitle";
import Title from "components/Global/CRM/Card/Title";

import OverviewView from "components/Event/Accounts/Account/Views/Overview";
import PeopleView from "Accounts/People/View";
import CateringView from "components/Event/Accounts/Account/Views/CateringReport";
import CredentialsView from "components/Event/Accounts/Account/Views/CredentialsReport";
import AssetView from "components/Event/Accounts/Account/Views/AssetReport";
import AccountActivities from "Profile/Activities/View";

const ActionsPanel = withProps({ shadow: 2, bra: 1, p: 5, bg: "white" })(Div);
const ActionsLink = withProps({ color: "primary5", mb: 3, bold: true })(Text2);
const moduleId = STANDARD_MODULE_IDS.accounts.id;

const Wrapper = ({ title, children }) => (
  <Div
    style={{
      backgroundColor: "#FFF",
      border: "1px solid #DAD7DC",
      borderRadius: 4
    }}
  >
    <Header>
      <Title>{title}</Title>
    </Header>
    <Div px={3}>{children}</Div>
  </Div>
);

const ActionsUrl = ({ children, url }) => (
  <Div fw={3} color="purple5" mb={3}>
    <Div display="row.space-between.center" mb={2}>
      <Div color="gray6" uppercase fs={1}>
        {children}
      </Div>
      <Div onClick={() => {}} color="purple5" fs={1}>
        <ClickToCopy text="Copy" textToCopy={url} />
      </Div>
    </Div>
    <input
      value={url}
      style={{
        color: "#494949",
        border: "1px solid #D7D8DA",
        borderRadius: 2,
        backgroundColor: "#EFF2F5",
        width: "100%",
        fontSize: 13,
        fontWeight: 400,
        padding: 5
      }}
    />
  </Div>
);

class AccountProfile extends Component {
  getReturnToUrl = () => {
    const { viaDashboard } = this.props.location.query;
    const { eventId } = this.props.params;
    const isLight = this.props.eventDetails.is_light;
    const validViaDashboard =
      typeof viaDashboard !== "undefined" && viaDashboard.length;

    return isLight
      ? `/event-light/${eventId}/crm/manage/${moduleId}${
          validViaDashboard ? `/type/${viaDashboard}` : ""
        }`
      : `/event/${eventId}/module/${moduleId}/dashboard${
          validViaDashboard ? `/type/${viaDashboard}` : ""
        }`;
  };

  getReturnToText = () => "Back to Dashboard";

  render() {
    const {
      account,
      activeView,
      addNote,
      deleteAccount,
      deleteNote,
      eventDetails,
      fetchProfile,
      goToView,
      hideModal,
      isOrgProfile,
      name,
      params,
      readOnly,
      showChangeRecordTypeModal,
      showModal,
      emailPrimaryContacts,
      sharePortalLoginLink,
      shareAssignmentManagerLink,
      runReport,
      showViewAllPropertiesModal,
      updateField,
      updateNote,
      showChangeContactRecordTypeModal
    } = this.props;
    const { recordId, eventId } = params;

    const primaryContacts = R.compose(
      R.filter(R.prop("is_primary")),
      R.pathOr([], ["related_contacts"])
    )(account);

    const tabs = [
      {
        name: "Overview",
        onClick: () => goToView(""),
        active: activeView === "overview"
      }
      /*
      {
        name: "Activity",
        onClick: () => goToView("/activity"),
        active: activeView === "activity"
      }
      */
    ];

    if (!isOrgProfile) {
      tabs.push(
        {
          name: "People",
          onClick: () => goToView("/people"),
          active: activeView === "people"
        },
        {
          name: "Passes",
          onClick: () => goToView("/credentials"),
          active: activeView === "credentials"
        },
        {
          name: "Catering",
          onClick: () => goToView("/catering"),
          active: activeView === "catering"
        },
        {
          name: "Inventory",
          onClick: () => goToView("/inventory"),
          active: activeView === "inventory"
        }
      );
    }

    let leftContent = [<Account />, <About />];
    if (!R.isEmpty(account)) {
      leftContent = [
        <Account
          key="contact"
          hideModal={hideModal}
          name={name}
          onDelete={deleteAccount}
          readOnly={readOnly}
          showChangeRecordTypeModal={showChangeRecordTypeModal}
          showModal={showModal}
          type={account.type || {}}
        />,
        <About
          key="about"
          {...{
            data: account,
            eventDetails,
            name,
            readOnly,
            showStarredFields: true,
            updateField,
            viewAllProperties: showViewAllPropertiesModal
          }}
        />,
        primaryContacts.length ? (
          <PrimaryContacts
            key="primaryContacts"
            accountId={recordId}
            basePath={`/event/${eventId}`}
            eventId={eventId}
            contacts={primaryContacts}
            getAccount={fetchProfile}
            readOnly={readOnly}
          />
        ) : null,
        <RecordOwners
          key="recordOwners"
          title="Group Owners"
          recordId={recordId}
          moduleId={moduleId}
          recordNameSingular="Group"
          recordNamePlural="Groups"
          onOwnersChange={fetchProfile}
          readOnly={readOnly}
          owners={R.pathOr(
            [],
            ["values", "owners", "value", "records"],
            account
          )}
        />
      ].filter(Boolean);
    }
    const viewProps = {
      leftContent,
      centerContent: R.prop(activeView, {
        overview: (
          <OverviewView
            {...{
              eventDetails,
              name,
              fetchProfile,
              params,
              account,
              showChangeContactRecordTypeModal
            }}
          />
        ),
        activity: <AccountActivities accountId={recordId} eventId={eventId} />,
        people: (
          <Wrapper
            {...{
              color: "#61A5FF",
              title: (
                <IconTitle title={`People at ${name}`} color="#61A5FF">
                  <i
                    className={"material-icons"}
                    style={{
                      color: "#fff",
                      fontSize: 16
                    }}
                  >
                    group
                  </i>
                </IconTitle>
              )
            }}
          >
            <PeopleView {...{ params, fetchProfile }} isViewingAsAdmin={true} />
          </Wrapper>
        ),
        catering: (
          <Wrapper
            {...{
              color: "#61A5FF",
              title: (
                <IconTitle
                  title={`Catering at ${name}`}
                  color={MODULES.catering.color}
                >
                  <i
                    className={"material-icons"}
                    style={{
                      color: "#fff",
                      fontSize: 16
                    }}
                  >
                    {MODULES.catering.iconKey}
                  </i>
                </IconTitle>
              )
            }}
          >
            <CateringView {...{ params }} />
          </Wrapper>
        ),
        credentials: (
          <Wrapper
            {...{
              color: "#61A5FF",
              title: (
                <IconTitle
                  title={`Passes at ${name}`}
                  color={MODULES.credentials.color}
                >
                  <i
                    className={"material-icons"}
                    style={{
                      color: "#fff",
                      fontSize: 16
                    }}
                  >
                    {MODULES.credentials.iconKey}
                  </i>
                </IconTitle>
              )
            }}
          >
            <CredentialsView {...{ params }} />
          </Wrapper>
        ),
        inventory: (
          <Wrapper
            {...{
              color: "#61A5FF",
              title: (
                <IconTitle
                  title={`Inventory at ${name}`}
                  color={MODULES.inventory.color}
                >
                  <i
                    className="material-icons"
                    style={{
                      color: "#fff",
                      fontSize: 16
                    }}
                  >
                    widgets
                  </i>
                </IconTitle>
              )
            }}
          >
            <AssetView {...{ params }} />
          </Wrapper>
        )
      }),
      rightContent: R.propOr(
        [
          <ActionsPanel key="links">
            <Text5 fw={3} mb={3}>
              Actions
            </Text5>
            <ActionsLink onClick={emailPrimaryContacts}>
              Email primary contacts
            </ActionsLink>
            <ActionsLink onClick={sharePortalLoginLink}>
              Send portal login link
            </ActionsLink>
            {account.type && account.type.guest_list_enabled ? (
              <ActionsLink onClick={shareAssignmentManagerLink}>
                Send assignment manager link
              </ActionsLink>
            ) : null}
            <ActionsLink onClick={runReport}>Run report</ActionsLink>
          </ActionsPanel>,
          <ActionsPanel key="shareLinks">
            <Text5 fw={3} mb={3}>
              Links to share
            </Text5>
            <ActionsUrl
              url={`${window.__LENND_APP_URL__}/portal-login/${eventDetails.slug}/${eventDetails.uuid}`}
            >
              Portal Login
            </ActionsUrl>

            {account.type && account.type.guest_list_enabled ? (
              <ActionsUrl
                url={`${window.__LENND_APP_URL__}/assignment-manager/${eventDetails.slug}/${eventDetails.uuid}/${this.props.params.recordId}`}
              >
                Assignment Manager
              </ActionsUrl>
            ) : null}
          </ActionsPanel>
        ],
        activeView,
        {
          activity: []
        }
      ),
      tabs,
      showNotes: activeView === "overview",
      notes: R.map(
        n => ({
          note: n,
          authorUrl: `/event/${params.eventId}/contact/${n.author_contact_id}`,
          title: name || "account",
          onUpdate: note => updateNote(note, n.id),
          onDelete: () => deleteNote(n.id)
        }),
        account.notes || []
      ),
      returnToPath: this.getReturnToUrl(),
      returnToText: this.getReturnToText(),
      onAddNote: addNote
    };

    return <ProfileViewTemplate {...viewProps} />;
  }
}

AccountProfile.propTypes = {
  account: PropTypes.object.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  deleteRelationship: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  updateRelationship: PropTypes.func.isRequired
};

export default AccountProfile;
