import { connect } from "react-redux";
import { actions, getters } from "Forms/WizardModal";
import { PAGES } from "Forms/WizardModal/constants";
import { getFilterModules } from "Forms/WizardModal/selectors";
import SelectForm from "Forms/WizardModal/View/Common/SelectForm";

const title = "Start with a blank form";
const description =
  "A new module will be created where your form data will be collected";
const buttonTxt = "GET STARTED";
const placeholder = "Search existing modules...";

const decorate = connect(
  state => ({
    moduleFields: getFilterModules(state),
    newModuleName: getters.newModuleName(state),
    createTitle: title,
    createDescription: description,
    createButton: buttonTxt,
    placeholder
  }),
  {
    back: () => actions.setSelectedPage(PAGES.SELECT_METHOD),
    selectId: params => actions.selectModule(params),
    setFilter: actions.setModuleSelectedFilter,
    setNewModuleName: actions.setNewModuleName,
    createAction: actions.createNewModule
  }
);

export default decorate(SelectForm);
