import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import DeletePopover from "components/Atoms/DeletePopover";
import Popover from "@lennd/material-ui/Popover";

class DeleteConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeletePopover: false,
      anchorEl: null
    };
  }

  @autobind
  handleClick(e) {
    e.stopPropagation();
    this.setState({
      showDeletePopover: true,
      anchorEl: e.target
    });
  }

  @autobind
  onRequestClose() {
    this.setState({ showDeletePopover: false });
  }

  @autobind
  handleDelete() {
    this.onRequestClose();
    this.props.handleDelete();
  }

  render() {
    return (
      <div>
        {React.cloneElement(this.props.button, { onClick: this.handleClick })}
        <Popover
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          onClose={this.onRequestClose}
          open={this.state.showDeletePopover}
          transformOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <DeletePopover
            confirmLabel={this.props.confirmLabel}
            cancel={this.onRequestClose}
            handleDelete={this.handleDelete}
            message={this.props.message}
            title={this.props.title}
          />
        </Popover>
      </div>
    );
  }
}

DeleteConfirmation.propTypes = {
  confirmLabel: PropTypes.string,
  button: PropTypes.node.isRequired,
  handleDelete: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default DeleteConfirmation;
