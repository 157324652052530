import React from "react";
import { connect } from "react-redux";

import { noop } from "utils/General";

import { actions } from "Items/Manage";
import { getFiltersOn } from "Items/Manage/selectors";

import { Div, Text0, Text2, CancelIcon, FontIcon } from "components/Base";

const decorate = connect(
  (state, props) => ({
    filtersOn: getFiltersOn(state, {
      ...props,
      ignoreApprovalsFilter: true
    })
  }),
  {
    clearAll: () => actions.clearFilters()
  }
);
const ClearFilters = ({ clearAll = noop, filtersOn, ...styleProps }) => (
  <Div display="row.flex-start.center" pl={3} {...styleProps}>
    <FontIcon icon="filter_list" mr={2} fs={4} />
    <Text2 bold flex={1}>
      Filters
    </Text2>
    <Div
      bg="neutral0"
      style={{
        cursor: "pointer",
        visibility: filtersOn ? "visible" : "hidden"
      }}
      display="row.flex-start.center"
      ml={2}
      bra={1}
      p={1}
      onClick={clearAll}
    >
      <CancelIcon mr={2} />
      <Text0 uppercase bold>
        Clear All
      </Text0>
    </Div>
  </Div>
);

export default decorate(ClearFilters);
