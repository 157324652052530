import PropTypes from "prop-types";
import React, { Component } from "react";
import getValue from "utils/value-types/get-value/number";
import autobind from "autobind-decorator";
import { Creatable } from "react-select";
import { uniq } from "lodash";

const isNumber = v => String(v).length && !Number.isNaN(Number(v));

export default class NumberWithDefaultsEditor extends Component {
  formatValue(value) {
    return getValue(value);
  }

  wrapValue(value) {
    return {
      type: "number",
      value
    };
  }

  @autobind
  onChange(option) {
    this.props.onChange(this.wrapValue(option ? option.value : undefined));
  }

  render() {
    const value = this.formatValue(this.props.value);
    const numbers = this.props.options.map(option => option.value);
    const options = uniq([...numbers, value])
      .filter(isNumber)
      .map(v => ({ label: String(v), value: String(v) }));
    return (
      <Creatable
        name="form-field-name"
        options={options}
        onChange={this.onChange}
        isValidNewOption={({ label }) => isNumber(label)}
        promptTextCreator={
          label =>
            `${label} ` /* Adding space for https://github.com/JedWatson/react-select/issues/1466 */
        }
        value={String(value)}
      />
    );
  }
}

NumberWithDefaultsEditor.defaultProps = {
  options: []
};
NumberWithDefaultsEditor.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.object
};
