import React from "react";
import { Div, Text3 } from "components/Base";

const PhotoCard = ({ photoUrl, uploadPhoto, removePhoto }) => (
  <Div display="row.flex-start.stretch">
    <Div width={200} br={1} bc="neutral1">
      <Div>
        <Div p={2}>
          <Text3 bold color="black">
            Photo
          </Text3>
        </Div>
        <Div
          p={2}
          pl={4}
          bg={{ default: "white", hover: "neutral0" }}
          onClick={() => uploadPhoto({ showWebcam: false })}
        >
          <Text3 bold>Upload {photoUrl !== "" ? "New Photo" : "Photo"}</Text3>
        </Div>
        <Div
          p={2}
          pl={4}
          bg={{ default: "white", hover: "neutral0" }}
          onClick={() => uploadPhoto({ showWebcam: true })}
        >
          <Text3 bold>Take {photoUrl !== "" ? "New Photo" : "Photo"}</Text3>
        </Div>
        {photoUrl !== "" && (
          <>
            <Div
              p={2}
              pl={4}
              bg={{ default: "white", hover: "neutral0" }}
              onClick={removePhoto}
            >
              <Text3 color="danger8" bold>
                Remove Photo
              </Text3>
            </Div>
          </>
        )}
      </Div>
    </Div>
    {photoUrl !== "" && (
      <Div p={2}>
        <Div
          bg="neutral0"
          height={200}
          width={170}
          mt={2}
          bra={1}
          style={{ overflow: "hidden" }}
          display="row.center.center"
        >
          <img src={photoUrl} height="100%" />
        </Div>
      </Div>
    )}
  </Div>
);

export default PhotoCard;
