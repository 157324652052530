import Helpers from "utils/Global/Helpers";
import { filepickerImageHandler } from "./handlers";

export const formats = [
  // 'header',
  "bold",
  "italic",
  "underline",
  "strike",
  // 'blockquote',
  "list",
  "bullet",
  "indent",
  "link"
  // 'image'
];

export const modules = {
  toolbar: [
    ["bold", "italic", "underline", "strike"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ],
    ["link"],
    ["clean"]
  ]
  // toolbar: [
  // 		[{ 'header': [1, 2, false] }],
  // 		['bold', 'italic', 'underline','strike', 'blockquote'],
  // 		[{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
  // 		['link', 'image'],
  // 		['clean']
  // ]
};

export const portalFormats = [...formats, "video", "header", "image"];
export const portalModules = {
  ...modules,
  toolbar: {
    container: [
      [
        {
          header: [1, 2, false]
        }
      ],
      ...modules.toolbar,
      ["image", "video"]
    ],
    handlers: {
      image: filepickerImageHandler
    }
  }
};

export const formBuilderFormats = [...formats, "image", "video"];
export const formBuilderModules = {
  ...modules,
  toolbar: {
    container: [...modules.toolbar, ["image", "video"]],
    handlers: {
      image: filepickerImageHandler
    }
  }
};
