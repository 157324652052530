import PropTypes from "prop-types";
import { Component } from "react";
import ReactDOM from "react-dom";
import autobind from "autobind-decorator";

class EditorBase extends Component {
  @autobind
  getStyle() {
    return {
      width: "100%"
    };
  }

  @autobind
  getValue() {
    const updated = {};
    updated[this.props.column.key] = {
      type: "text",
      value: this.getInputNode().value
    };
    return updated;
  }

  @autobind
  getInputNode() {
    const domNode = ReactDOM.findDOMNode(this);
    if (domNode.tagName === "INPUT") {
      return domNode;
    }

    return domNode.querySelector("input:not([type=hidden])");
  }

  @autobind
  inheritContainerStyles() {
    return true;
  }
}

EditorBase.propTypes = {
  onKeyDown: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  onBlur: PropTypes.func.isRequired,
  column: PropTypes.object.isRequired,
  commit: PropTypes.func.isRequired
};

export default EditorBase;
