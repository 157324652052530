import Helpers from "utils/Global/Helpers";

const baseUrl = (uuid, accountId) =>
  `${
    window.__LENND_API_BASE_URL__
  }/portal/assignment-manager/event/${uuid}/group/${accountId}/people`;

module.exports = {
  get: (credentials, { eventUUID, accountId }) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: baseUrl(eventUUID, accountId),
        credentials,
        data: {},
        success: result => {
          resolve(result.payload.members);
        },
        error: err => {
          reject(err);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  post: (credentials, { eventUUID, accountId, values }) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: baseUrl(eventUUID, accountId),
        credentials,
        data: { values },
        success: result => {
          resolve(result.payload.member);
        },
        error: err => {
          reject(err);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  put: (credentials, { eventUUID, accountId, values }) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "put",
        url: `${baseUrl(eventUUID, accountId)}/${values.id}`,
        credentials,
        data: { values },
        success: result => {
          if (result.success) {
            resolve(result.payload);
          } else {
            reject(result);
          }
        },
        error: err => {
          reject(err);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  delete: (credentials, { eventUUID, accountId, memberId }) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "delete",
        url: `${baseUrl(eventUUID, accountId)}/${memberId}`,
        credentials,
        data: {},
        success: result => {
          resolve(result.payload);
        },
        error: err => {
          reject(err);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
