import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "Accounts/QuickSetup/model";
import { Div, Text5, BigOutlineButton, AddCircleIcon } from "components/Base";
import Table from "./Table";
import TermsDescriptions from "./TermsDescriptions";
import Loading from "ui-kit/Loading";

const decorate = connect(
  state => ({
    loading: getters.loading(state)
  }),
  {
    handleRecordTypeModal: actions.handleRecordTypeModal
  }
);

const Layout = ({ handleRecordTypeModal, loading }) =>
  loading ? (
    <Div p={8} display="column.center">
      <Loading />
    </Div>
  ) : (
    <Div p={8} pb={0}>
      <Text5 color="black">Overview of your group types</Text5>
      <Div display="row.flex-start.center" mt={3} mb={5}>
        <BigOutlineButton
          LeftIcon={AddCircleIcon}
          onClick={handleRecordTypeModal}
        >
          New Group Type
        </BigOutlineButton>
        {/* <BigOutlineButton ml={2}>Clone in from previous event</BigOutlineButton> */}
      </Div>
      <Table />
      <TermsDescriptions />
    </Div>
  );

export default decorate(Layout);
