import { connect } from "react-redux";
import Layout from "./Layout";
import { withRouter } from "react-router";
import * as R from "ramda";

import { showModal, hideModal } from "redux/modules/modal/actions";
import { showSnackbar, hideSnackbar } from "redux/modules/snackbar/actions";
import {
  addRecordType,
  updateRecordType,
  deleteRecordType
} from "redux/modules/modules/recordTypes/actions";
import { updateDefaultRecordType } from "redux/modules/modules/recordTypes/default/actions";
import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";
import { addLayout } from "redux/modules/modules/layouts/actions";

import {
  eventDetails,
  isConferenceTemplate
} from "redux/modules/event/selectors";
import { recordTypes } from "redux/modules/modules/recordTypes/selectors";
import { moduleDetails } from "redux/modules/modules/module/selectors";

import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => {
      const recordTypesToPass = recordTypes(state, props.moduleId);
      const selectedRecordType = props.params.typeId
        ? recordTypesToPass.find(t => t.id === props.params.typeId)
        : recordTypesToPass[0];

      /*
        const accountRecordTypes = recordTypes(
        state,
        STANDARD_MODULE_IDS.accounts.id
        );
        const contacRecordTypes = recordTypes(
        state,
        STANDARD_MODULE_IDS.contacts.id
        );
        const recordTypesToPass = [...accountRecordTypes, ...contacRecordTypes];
        const selectedRecordType = props.params.typeId
        ? recordTypesToPass.find(t => t.id === props.params.typeId)
        : recordTypesToPass[0];
      */

      return {
        eventId: eventDetails(state).id,
        module: moduleDetails(state, props.moduleId),
        isConferenceTemplate: isConferenceTemplate(state),
        recordTypes: recordTypesToPass,
        selectedRecordType,
        canViewNewPortal: selectFeatureFlag(flags.CAN_VIEW_NEW_PORTAL.NAME)(
          state
        )
      };
    },
    {
      getRecordTypes,
      showModal,
      hideModal,
      showSnackbar,
      hideSnackbar,
      addRecordType,
      updateRecordType,
      deleteRecordType,
      updateDefaultRecordType,
      addLayout
    }
  )
);

export default decorate(Layout);
