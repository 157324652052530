import React, { Component } from "react";
import { CREDENTIAL_TYPE_ID, LABELS } from "utils/item-types";
import * as R from "ramda";
import {
  Span,
  Div,
  Text1,
  Text2,
  BigFilledButton,
  Text4
} from "components/Base";
import { Modal } from "./AddRecordModal";
import Items from "Orders/ItemPickers";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { getItemTypesByEvent } from "redux/modules/items/types/actions";
import { addOrder } from "redux/modules/orders/orders/actions";
import { hideModal, showModal } from "redux/modules/modal/actions";
import { track } from "redux/modules/analytics/actions";
import { typeByType } from "redux/modules/items/types/selectors";
import { eventDetails, eventId } from "redux/modules/event/selectors";
import itemsActions from "Orders/ItemPickers/ItemsActionsDecorator";

function mapStateToProps(state, props) {
  return {
    items: typeByType(state, props.itemTypeId),
    cateringMealsPerDay: R.pathOr(
      [],
      ["module_settings", "catering", "meal_days"],
      eventDetails(state)
    ),
    dateGroups: eventDetails(state).date_groups,
    eventId: eventId(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getItemTypesByEvent,
      addOrder,
      showModal,
      hideModal,
      track
    },
    dispatch
  );
}

function itemsController(Comp) {
  class ItemsController extends Component {
    componentDidMount() {
      this.props.getItemTypesByEvent(this.props.eventId);
    }

    createOrder = async () => {
      if (this.props.lineItems.length) {
        const order = await this.props.addOrder({
          eventId: this.props.eventId,
          order: {
            orderType: "group",
            isAllocation: true,
            customerAccountId: this.props.groupId,
            options: {
              requireAssignment: true,
              addToExistingAllocation: true
            },
            lineItems: this.props.lineItems.map(lineItem => ({
              variantId: lineItem.variantId,
              quantity: 1,
              currency: "USD",
              price: 0
            }))
          }
        });

        this.props.track({
          event: "lennd_glm_admin_allocate_passes",
          properties: {
            eventId,
            orderId: order.id
          }
        });
      }

      return this.props.onSave();
    };

    render() {
      const itemGroups = R.compose(
        R.map(i => ({
          id: i.id,
          name: i.name,
          items: i.items.map(item => ({
            id: item.id,
            name: item.name,
            background_color: item.background_color,
            message:
              !item.show_in_assignment_manager &&
              "Hidden from Guest List Manager",
            variants: R.map(variant => {
              const quantity =
                this.props.selectedVariants[variant.id] >= 0
                  ? this.props.selectedVariants[variant.id]
                  : 0;
              return {
                ...variant,
                inventory_quantity: variant.inventory_quantity,
                quantity,
                price: 0,
                currency: "USD",
                incrementQuantity: () =>
                  this.props.updateVariantQuantity(variant.id, quantity + 1),
                decrementQuantity: () =>
                  this.props.updateVariantQuantity(variant.id, quantity - 1),
                updateVariantQuantity: qty =>
                  this.props.updateVariantQuantity(
                    variant.id,
                    parseInt(qty, 10) || 0
                  )
              };
            })(item.variants)
          }))
        })),
        R.reject(
          R.compose(
            R.isEmpty,
            R.prop("items")
          )
        )
      )(this.props.items.groups || []);

      return (
        <Comp
          hideModal={this.props.hideModal}
          totalSelected={R.sum(R.values(this.props.selectedVariants))}
          onReset={this.props.clearAll}
          onSave={this.createOrder}
          itemGroups={itemGroups}
          groupName={this.props.groupName}
          header={this.props.header}
          itemTypeId={this.props.itemTypeId}
          eventDateGroups={this.props.dateGroups}
          cateringMealsPerDay={this.props.cateringMealsPerDay}
        />
      );
    }
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(itemsActions(ItemsController));
}

const View = ({
  cateringMealsPerDay,
  itemTypeId = CREDENTIAL_TYPE_ID,
  eventDateGroups,
  itemGroups,
  groupName
}) => (
  <Div width={1}>
    <Text4 mb={5}>
      Add {LABELS[itemTypeId].label} for <Span fw={3}>{groupName}</Span>
    </Text4>
    <Items
      eventDateGroups={eventDateGroups}
      cateringMealsPerDay={cateringMealsPerDay}
      activeType={{
        id: itemTypeId,
        groups: itemGroups
      }}
    />
  </Div>
);

const ModalView = ({
  hideModal,
  onSave,
  totalSelected,
  onReset,
  header,
  ...props
}) => (
  <Modal
    heading={header ? "Add Group" : "Add Items"}
    hideModal={hideModal}
    header={header}
    footer={[
      <Div flex={1}>
        <Text2 bold pb={2}>
          Quantity: {totalSelected}
        </Text2>
        <Text1 underline bold color="primary5" onClick={onReset}>
          Clear Selection
        </Text1>
      </Div>,
      <BigFilledButton
        flex={2}
        bg="altB5"
        onClick={() => {
          hideModal();
          onSave();
        }}
        display="row.center.center"
      >
        Save
      </BigFilledButton>
    ]}
    body={<View {...props} />}
  />
);

export const AllocationsView = itemsController(View);
export const AllocationsModal = itemsController(ModalView);

export default AllocationsView;
