import { REQUEST, RECEIVE } from "./constants";
import * as selectors from "./selectors";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getAccountInventoryReport({ accountId, eventId }) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState(), accountId)) return;
    try {
      dispatch({
        type: REQUEST,
        payload: {
          accountId
        }
      });
      const report = await api.get(getState().user.user.credentials, {
        accountId,
        eventId
      });
      dispatch({
        type: RECEIVE,
        payload: {
          accountId,
          report
        }
      });

      return report;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching category overview report"
          }
        ])
      );
    }
  };
}
