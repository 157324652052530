import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE, TABS } from "./constants";

const iniState = {
  loading: false,
  moduleId: null,
  recordTypeId: null,
  data: [],
  selectedTab: TABS.ALL,
  fieldsFilter: "",
  selectedFields: [],
  allFields: [],
  defaultPreferences: {
    visible_fields: [],
    field_order: {}
  }
};

const indexedMap = R.addIndex(R.map);

const handlers = createHandlers({
  iniState,
  reducers: {
    cancelInstance: R.always(iniState),
    init: R.identity,
    refreshFields: R.identity,
    setInitialData: (
      state,
      {
        payload: {
          moduleId,
          recordTypeId,
          fields,
          allFields,
          defaultPreferences,
          visibleFields = [],
          fieldOrder = {}
        }
      }
    ) => ({
      moduleId,
      recordTypeId,
      data: fields,
      allFields,
      defaultPreferences,
      selectedFields: R.compose(
        R.sortBy(({ id }) => fieldOrder[id]),
        indexedMap(({ id, name, is_auto_enabled, is_report_field }, order) => ({
          id,
          name,
          order: fieldOrder[id] || order,
          is_auto_enabled,
          is_report_field
        })),
        R.filter(
          ({ id, is_auto_enabled }) =>
            is_auto_enabled === true || visibleFields.includes(id)
        ),
        R.reduce((acc, category) => [...acc, ...category.fields], [])
      )(fields)
    }),
    setRefreshedFields: (state, { payload: { fields, allFields } }) => ({
      data: fields,
      allFields
    }),
    toggleSelectedField: (
      state,
      { payload: { selected, fieldId, name, is_auto_enabled, is_report_field } }
    ) => ({
      selectedFields: selected
        ? R.filter(({ id }) => id !== fieldId, state.selectedFields)
        : [
            ...state.selectedFields,
            {
              id: fieldId,
              name,
              is_auto_enabled,
              is_report_field,
              order: R.length(state.selectedFields)
            }
          ]
    }),
    disableSelectedField: (state, { payload: { fieldId } }) => ({
      selectedFields: R.filter(({ id }) => id !== fieldId, state.selectedFields)
    }),
    reorderFields: (state, { payload: fields }) => ({
      selectedFields: indexedMap(
        (field, order) => ({ ...field, order }),
        fields
      )
    }),
    disableAllCategoryFields: (state, { payload: { categoryId } }) => {
      const fieldsToDisable = R.compose(
        R.reduce((acc, field) => {
          if (!field.is_auto_enabled) {
            return [...acc, field.id];
          }
          return acc;
        }, []),
        R.propOr([], "fields"),
        R.find(({ id }) => id === categoryId)
      )(state.data);

      return {
        selectedFields: R.compose(
          indexedMap((field, order) => ({ ...field, order })),
          R.filter(selectedField =>
            R.all(id => id !== selectedField.id, fieldsToDisable)
          )
        )(state.selectedFields)
      };
    },
    enableAllCategoryFields: (state, { payload: { categoryId } }) => {
      const categoryFields = R.compose(
        R.reduce((acc, field) => {
          if (!field.is_auto_enabled) {
            return [
              ...acc,
              {
                id: field.id,
                name: field.name,
                is_auto_enabled: field.is_auto_enabled
              }
            ];
          }
          return acc;
        }, []),
        R.propOr([], "fields"),
        R.find(({ id }) => id === categoryId)
      )(state.data);

      const fieldsToEnable = R.filter(
        selectedField =>
          R.all(({ id }) => id !== selectedField.id, state.selectedFields),
        categoryFields
      );

      return {
        selectedFields: R.compose(
          indexedMap((field, order) => ({ ...field, order })),
          selectedFields => [...selectedFields, ...fieldsToEnable]
        )(state.selectedFields)
      };
    },
    removeAllSelectedFields: state => ({
      selectedFields: R.compose(
        indexedMap((field, order) => ({ ...field, order })),
        R.filter(({ is_auto_enabled }) => is_auto_enabled === true)
      )(state.selectedFields)
    }),
    resetToDefaults: state => {
      return {
        selectedFields: state.defaultPreferences.visible_fields
          .map(fId => state.allFields.find(f => f.id === fId))
          .filter(f => f)
          .map(f => ({
            ...f,
            order: state.defaultPreferences.field_order[f.id]
          }))
      };
    }
  },
  namespace: NAMESPACE
});

export default handlers;
