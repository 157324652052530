import React, { Component } from "react";
import * as R from "ramda";
import View from "./View";
import HelpArticleUrls from "utils/help-article-urls";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import formatInternalName from "utils/format-internal-name";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import FormWizardModal from "Forms/WizardModal/View";

class Controller extends Component {
  state = { creatingForm: false };

  goToWhenToUseForms = () => {
    window.open(HelpArticleUrls.MANAGE_ACCOUNT_FORMS, "_blank");
  };

  goToWhenToUseGuestLists = () => {};

  goToWhenToUseApplications = () => {};

  goToView = view => {
    this.props.router.push({
      pathname: `/event/${this.props.eventDetails.id}/passes${view}`
    });
  };

  goToForm = ({ formId, created, template, trigger }) => {
    this.props.router.push(
      `/event/${this.props.eventDetails.id}/module/${
        STANDARD_MODULE_IDS.orders.id
      }/form/${formId}${created ? `?${trigger}=1&template=${template}` : ""}`
    );
  };

  goToGuestListSetup = () => {
    this.props.router.push(
      `/event/${this.props.eventDetails.id}/passes/setup/collect/guest-lists`
    );
  };

  goToRequestFormSetup = () => {
    this.props.router.push(
      `/event/${this.props.eventDetails.id}/passes/setup/collect/request-forms`
    );
  };

  goToApplicationSetup = () => {
    this.props.router.push(
      `/event/${this.props.eventDetails.id}/passes/setup/collect/applications`
    );
  };

  createTemplate = async template => {
    const form = await this.props.createFormFromTemplate({
      template,
      eventId: this.props.eventDetails.id
    });

    this.props.router.push(
      `/event/${this.props.eventDetails.id}/module/${STANDARD_MODULE_IDS.orders.id}/form/${form.id}`
    );
  };

  createForm = async ({ type = "order", template, name, description }) => {
    // Reduce chance of multiple clicks to create a form
    if (this.state.creatingForm) return false;

    this.props.showSnackbar({ message: "Creating form...", action: "OK" });
    this.setState({ creatingForm: true });

    let applicationModule = null;

    if (type === "application") {
      // create new module of type "Application"
      applicationModule = await this.props.addModule({
        color: "rgb(142,185,223)",
        eventId: this.props.eventDetails.id,
        hasApproval: true,
        icon: "list",
        internalName: formatInternalName(name),
        name,
        recordName: "Application",
        recordNamePlural: "Applications",
        recordPrefix: "APP",
        showInNavigation: true,
        typeId: STANDARD_MODULE_IDS.formsv3.id
      });
    }

    const record = await this.props.addRecord({
      moduleId: STANDARD_MODULE_IDS.formsv3.id,
      record: {
        relatedModuleId:
          type === "application"
            ? applicationModule.id
            : STANDARD_MODULE_IDS.orders.id,
        name,
        title: name,
        type: type === "application" ? "survey" : type,
        scope: type === "application" ? "account" : null,
        description,
        internalDescription: null,
        showShareLink: false,
        allowChanges: false,
        lockOnSubmission: false,
        closeDate: null
      },
      options: {
        eventId: this.props.eventDetails.id
      }
    });

    this.goToForm({
      formId: record.form.id,
      created: true,
      trigger:
        type === "application" ? "setupApplicationForm" : "setupOrderForm",
      template
    });
  };

  showTemplatesModal = () => {
    this.props.showModal({
      content: <FormWizardModal />,
      wrapper: ModalWrapper
    });
  };

  render() {
    const { eventDetails, guestLists } = this.props;

    const quickStartForms = [
      {
        name: "Sponsor Credentials",
        onClick: () =>
          this.createForm({
            template: "sponsor-credentials",
            name: "Sponsor Credentials",
            description: `Hello! Please use the form below to request Sponsor Credentials for ${eventDetails.name}. If you have any questions, please contact us.`
          })
      },
      {
        name: "Artist Guest Passes",
        onClick: () =>
          this.createForm({
            template: "artist-guest-passes",
            name: "Artist Guest Passes",
            description: `Hello! Please use the form below to request Artist Guest Passes for ${eventDetails.name}. If you have any questions, please contact us.`
          })
      },
      {
        name: "Industry Guest Passes",
        onClick: () =>
          this.createForm({
            template: "industry-guest-passes",
            name: "Industry Guest Passes",
            description: `Hello! Please use the form below to request Industry Guest Passes for ${eventDetails.name}. If you have any questions, please contact us.`
          })
      },
      {
        name: "Friends & Family Tickets",
        onClick: () =>
          this.createForm({
            template: "friends-family-tickets",
            name: "Friends & Family Tickets",
            description: `Hello! Please use the form below to request Friends & Family tickets for ${eventDetails.name}. If you have any questions, please contact us.`
          })
      },
      {
        name: "Comp. Ticket Requests",
        onClick: () =>
          this.createForm({
            template: "comp-ticket-requests",
            name: "Comp. Ticket Requests",
            description: `Hello! Please use the form below to request comp tickets for ${eventDetails.name}. If you have any questions, please contact us.`
          })
      },
      {
        name: "Internal Staff Tickets",
        onClick: () =>
          this.createForm({
            template: "internal-staff-tickets",
            name: "Internal Staff Tickets",
            description: `Hello! Please use the form below to request internal staff tickets for ${eventDetails.name}. If you have any questions, please contact us.`
          })
      }
    ];

    const quickStartGuestLists = R.compose(
      R.sortBy(
        R.compose(
          R.toLower,
          R.prop("name")
        )
      ),
      R.map(l => ({
        onClick: () => this.goToView(`/guest-list/${l.id}`),
        name: l.name
      }))
    )(guestLists);

    const quickStartApplications = [
      {
        name: "Media Application",
        onClick: () => this.createTemplate("media-application")
      },
      {
        name: "Vendor Application",
        onClick: () => this.createTemplate("vendor-application")
      }
    ];

    return (
      <View
        {...{
          canViewPasses: this.props.canViewPasses,
          createQuickStartForm: this.goToRequestFormSetup,
          createQuickStartGuestList: this.goToGuestListSetup,
          createQuickStartApplication: this.goToApplicationSetup,
          showTemplatesModal: () => this.showTemplatesModal(),
          showRequestFormsTemplatesModal: () =>
            this.showTemplatesModal("passes"),
          showApplicationsTemplatesModal: () =>
            this.showTemplatesModal("applications"),
          //
          quickStartForms,
          quickStartGuestLists,
          quickStartApplications,
          //
          goToWhenToUseForms: this.goToWhenToUseForms,
          goToWhenToUseGuestLists: this.goToWhenToUseGuestLists,
          goToWhenToUseApplications: this.goToWhenToUseApplications
        }}
      />
    );
  }
}

export default Controller;
