import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";
import { withRouter } from "react-router";
import * as R from "ramda";
import { INTEGRATIONS as INTEGRATIONS_LIST } from "utils/integration-types";

import TicketingProviderActions from "redux/modules/ticketing/providers/actions";

import { user } from "redux/modules/user/selectors";
import { eventDetails } from "redux/modules/event/selectors";

function mapStateToProps(state, props) {
  const enableSelectOrg = R.prop(props.provider.id)({
    [INTEGRATIONS_LIST.eventbrite.id]: true
  });
  const orgSettingsProperty = R.prop(props.provider.id)({
    [INTEGRATIONS_LIST.eventbrite.id]: "eventbriteOrganizationId"
  });
  const eventSettingsProperty = R.prop(props.provider.id)({
    [INTEGRATIONS_LIST.eventbrite.id]: "eventbriteEventId",
    [INTEGRATIONS_LIST.glownet.id]: "glownetEventId",
    [INTEGRATIONS_LIST.token.id]: "tokenEventId"
  });

  return {
    enableSelectOrg,
    orgSettingsProperty,
    eventSettingsProperty,
    orgId: props.providerConfiguration.settings[orgSettingsProperty],
    eventId: props.providerConfiguration.settings[eventSettingsProperty],
    user: user(state),
    eventDetails: eventDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getProviderConfigurationSettings:
        TicketingProviderActions.getProviderConfigurationSettings,
      updateProviderConfigurationSettings:
        TicketingProviderActions.updateProviderConfigurationSettings
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
