import React from "react";
import { SubNavWrapper, SubNavLink } from "EventLight/Common/SubNav";
import { withRouter } from "react-router";
import * as R from "ramda";
import { connect } from "react-redux";
import { getLinks } from "./selectors";

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    links: getLinks(state, props)
  }))
);

const SubNav = ({ links, router }) => (
  <SubNavWrapper supportId="portals">
    {R.map(link => (
      <SubNavLink
        name={link.label}
        active={link.active}
        onClick={() =>
          router.push({
            pathname: link.to
          })
        }
      />
    ))(links)}
  </SubNavWrapper>
);

export default decorate(SubNav);
