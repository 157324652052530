import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const InvalidRowCount = ({ count }) => (
  <div styleName="container">
    <div>
      <div styleName="count">{count}</div>
      {`request${count > 1 ? "s" : ""} above ${
        count > 1 ? "have" : "has"
      } required fields remaining`}
    </div>
    <i className={`material-icons ${css.icon}`}>arrow_upward</i>
  </div>
);

InvalidRowCount.propTypes = {
  count: PropTypes.number.isRequired
};

export default CSSModules(InvalidRowCount, css);
