import { REQUEST, RECEIVE } from "./constants";
import { combineReducers } from "redux";
import { TOGGLE_STARRED_EVENT } from "redux/modules/events/constants";
import { concat, reduce, map, prop, pathOr, compose } from "ramda";

const getUserOrgs = payload =>
  compose(
    map(prop("id")),
    pathOr([], ["organizations"])
  )(payload);

const getUserEvents = payload =>
  compose(
    map(prop("id")),
    pathOr([], ["events"])
  )(payload);

const getUserOrgEvents = payload =>
  compose(
    reduce((list, org) => {
      return concat(list, map(prop("id"))(org.events));
    }, []),
    pathOr([], ["organizations"])
  )(payload);

const accessLevels = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload;
    case TOGGLE_STARRED_EVENT:
    // @TODO: Virtually handle starred event updates
    default:
      return state;
  }
};

const userEvents = (state = [], action) => {
  switch (action.type) {
    case RECEIVE:
      return concat(
        getUserEvents(action.payload),
        getUserOrgEvents(action.payload)
      );
    default:
      return state;
  }
};

const userOrgs = (state = [], action) => {
  switch (action.type) {
    case RECEIVE:
      return getUserOrgs(action.payload);
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  accessLevels,
  userEvents,
  userOrgs,
  fetching
});
