import moment from "moment";
import React from "react";
import CSSModules from "react-css-modules";

import { CAPTION_STATES } from "PortalHub/ActionComponents/constants";
import { CARD_ACTIONS } from "../constants";

import { ActionItem } from "PortalHub/ActionComponents/View";
import { Text3, FontIcon } from "components/Base";

import styles from "./styles.scss";

import { mapActionItemStatus } from "../utils";

const documentButtons = [
  { label: "Submit", id: CARD_ACTIONS.SUBMIT_DOCUMENT, primary: true }
];

const decorate = CSSModules(styles);

const DocumentType = ({ task = {} }) => (
  <ActionItem
    title={task.name}
    caption={`${
      task.status === "not_submitted" ? "Not submitted" : "Submitted"
    } • Due ${moment().isBefore(task.due_raw) ? "in" : ""} ${moment(
      task.due_raw
    ).fromNow()}`}
    captionState={
      task.required ? CAPTION_STATES.REQUIRED : CAPTION_STATES.OPTIONAL
    }
    icon={
      task.status === "not_submitted" &&
      moment().isAfter(task.due_raw) &&
      !task.required
        ? "lock"
        : ""
    }
    status={mapActionItemStatus({
      status: task.status,
      required: task.required,
      pastDue: moment().isAfter(task.due_raw)
    })}
    expandable
    buttons={documentButtons}
    id={task.id}
    data={task}
  >
    <div styleName="people-card">
      <Text3 bold>Due Date: </Text3>
      <Text3>{moment(task.due_raw).format("MMMM D, YYYY @ hha z")}</Text3>
      {task.description ? (
        <>
          <Text3 bold>Description: </Text3>
          <div dangerouslySetInnerHTML={{ __html: task.description }} />
        </>
      ) : null}
      <Text3 bold>Attatchment: </Text3>
      <FontIcon color="black" style={{ fontSize: 48 }}>
        picture_as_pdf
      </FontIcon>
    </div>
  </ActionItem>
);

export default decorate(DocumentType);
