import React from "react";
import { set } from "lodash";
import { withProps } from "utils/General";

import {
  Div,
  makeTable,
  Text3,
  Text2,
  Text1,
  Text0,
  ExpandIcon,
  ThreeQuarterSizeSquareIcon,
  PopoverMenu,
  SmallOutlineButton,
  FontIcon
} from "components/Base";

import CSSModules from "react-css-modules";
import css from "components/Event/Settings/Layout/Tables/styles.css";

const HeaderStyler = withProps({
  display: "row.flex-start.center",
  bg: "white",
  py: 2,
  pt: 3,
  px: 4,
  mx: 1
})(Div);

const RowStyler = ({ children, onClick }) => (
  <Div
    display="row.flex-start.center"
    bg={{ default: "white", hover: "gray0" }}
    py={2}
    pt={3}
    px={4}
    mx={1}
    onClick={onClick}
  >
    {children}
  </Div>
);

const addS = thing => (thing === 1 ? "" : "s");

const ItemBlocksTable = makeTable({
  TableStyler: Div,
  HeaderStyler,
  RowStyler,
  HeaderCellStyler: Div,
  RowCellStyler: Div,
  columnProps: [{ width: 3 / 4 }, { width: 1 / 4 }],
  headerCellComps: [
    () => (
      <Text0 bold uppercase color="gray7">
        Block Name
      </Text0>
    ),
    () => (
      <Div display="row.flex-end.center">
        <Text0 bold uppercase color="gray7">
          # of Items
        </Text0>
      </Div>
    )
  ],
  rowCellComps: [
    ({ name }) => (
      <Text2 truncate color="gray7">
        {name}
      </Text2>
    ),
    ({ count }) => (
      <Div display="row.flex-end.center">
        <Text2 color="gray7">{`${count} item${addS(count)}`}</Text2>
      </Div>
    )
  ]
});

export default ({ blocks }) => <ItemBlocksTable rowsData={blocks} />;
