import React, { Component } from "react";
import * as R from "ramda";
import moment from "moment";
import View from "./View";
import SendFormModal from "components/Event/FormsV2/Modals/FormSendModal";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import AddAccountFormAssignment from "components/Event/Module/Form/Modals/AddAccountFormAssignment";
import EditAccountFormAssignment from "components/Event/Module/Form/Modals/EditAccountFormAssignment";
import AddContactFormAssignment from "components/Event/Module/Form/Modals/AddContactFormAssignment";
import EditContactFormAssignment from "components/Event/Module/Form/Modals/EditContactFormAssignment";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import formaCloseDate from "components/Event/Module/Form/utils/format-close-date";

class Controller extends Component {
  constructor(props) {
    super(props);
    this.linkInputRef = React.createRef();
    this.state = {
      loading: true,
      copyButtonText: "Copy"
    };
  }

  async componentDidMount() {
    await Promise.all([
      this.props.getRecordTypes({
        moduleId: STANDARD_MODULE_IDS.accounts.id,
        options: {
          orgId: this.props.params.orgId,
          eventId: this.props.params.eventId
        }
      }),
      this.props.getRecordTypes({
        moduleId: STANDARD_MODULE_IDS.contacts.id,
        options: {
          orgId: this.props.params.orgId,
          eventId: this.props.params.eventId
        }
      }),
      this.fetchFormAssignments()
    ]);

    this.setState({
      loading: false
    });
  }

  goToScene = path => {
    if (this.props.eventDetails.is_light) {
      this.props.router.push({
        pathname: `/event-light/${this.props.params.eventId}/forms/${this.props.params.viewId}/settings${path}`
      });
    } else {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/module/${this.props.params.moduleId}/form/${this.props.params.viewId}${path}`
      });
    }
  };

  fetchFormAssignments = () =>
    this.props.getAssignments(this.props.params.viewId);

  onClickLinkInput = event => {
    if (this.linkInputRef.current) {
      this.linkInputRef.current.setSelectionRange(0, event.target.value.length);
    }
  };

  onCopyLinkInput = () => {
    // is element selectable?
    if (this.linkInputRef.current && this.linkInputRef.current.select) {
      this.linkInputRef.current.select();

      try {
        document.execCommand("copy");
        this.linkInputRef.current.blur();
        this.setState({
          copyButtonText: "Copied!"
        });

        setTimeout(() => {
          this.setState({
            copyButtonText: "Copy"
          });
        }, 3000);
      } catch (err) {
        // eslint-disable-next-line no-alert
        alert("Please press Ctrl/Cmd+C to copy.");
      }
    }
  };

  onClickSendForm = (emails = []) => {
    this.props.showModal({
      content: (
        <SendFormModal
          formId={this.props.form.id}
          formSlug={this.props.form.slug}
          emails={emails}
          handleFormSent={() => {
            this.props.showSnackbar({ message: "Form sent", action: "OK" });
          }}
        />
      )
    });
  };

  onClickAddToAllPeopleTypes = async () => {
    const typeIds = this.props.peopleRecordTypes.map(r => r.id);
    await this.props.addContactTypeFormAssignment({
      formId: this.props.form.id,
      types: typeIds.map(id => ({
        id,
        closeDate: null
      }))
    });

    return this.fetchFormAssignments();
  };

  onClickAddToAllGroupTypes = async () => {
    const typeIds = this.props.recordTypes.map(r => r.id);
    await this.props.addAccountTypeFormAssignment({
      formId: this.props.form.id,
      types: typeIds.map(id => ({
        id,
        closeDate: null
      }))
    });

    return this.fetchFormAssignments();
  };

  onClickRemoveFromAllPeopleTypes = async () => {
    const typeIds = this.props.peopleRecordTypes.map(r => r.id);
    await this.props.removeContactTypeFormAssignment({
      formId: this.props.form.id,
      typeIds
    });

    return this.fetchFormAssignments();
  };

  onClickRemoveFromAllGroupTypes = async () => {
    const typeIds = this.props.recordTypes.map(r => r.id);
    await this.props.removeAccountTypeFormAssignment({
      formId: this.props.form.id,
      typeIds
    });

    return this.fetchFormAssignments();
  };

  onClickAssignToPerson = () => {
    this.props.showModal({
      content: (
        <AddContactFormAssignment
          eventId={this.props.params.eventId}
          userId={this.props.userId}
          onSave={data => this.onAssignToPerson(data)}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  onClickAssignToGroup = () => {
    this.props.showModal({
      content: (
        <AddAccountFormAssignment
          eventId={this.props.params.eventId}
          userId={this.props.userId}
          onSave={data => this.onAssignToGroup(data)}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  onClickEditPersonCloseDate = ({
    id,
    name,
    custom_close_date,
    close_date
  }) => {
    this.props.showModal({
      content: (
        <EditContactFormAssignment
          eventId={this.props.params.eventId}
          name={name}
          closeDate={close_date}
          customCloseDate={custom_close_date}
          onSave={data =>
            this.onUpdatePersonAssignment({
              contactId: id,
              ...data
            })
          }
        />
      ),
      wrapper: ModalWrapper
    });
  };

  onClickEditCloseDate = ({ id, name, custom_close_date, close_date }) => {
    this.props.showModal({
      content: (
        <EditAccountFormAssignment
          eventId={this.props.params.eventId}
          accountName={name}
          closeDate={close_date}
          customCloseDate={custom_close_date}
          onSave={data =>
            this.onUpdateGroupAssignment({
              accountId: id,
              ...data
            })
          }
        />
      ),
      wrapper: ModalWrapper
    });
  };

  onAssignToGroupType = async typeId => {
    await this.props.addAccountTypeFormAssignment({
      formId: this.props.form.id,
      types: [
        {
          id: typeId,
          closeDate: null
        }
      ]
    });

    return this.fetchFormAssignments();
  };

  onAssignToPeopleType = async typeId => {
    await this.props.addContactTypeFormAssignment({
      formId: this.props.form.id,
      types: [
        {
          id: typeId,
          closeDate: null
        }
      ]
    });

    return this.fetchFormAssignments();
  };

  onRemoveAssignmentFromGroupType = async typeId => {
    await this.props.removeAccountTypeFormAssignment({
      formId: this.props.form.id,
      typeIds: [typeId]
    });

    return this.fetchFormAssignments();
  };

  onRemoveAssignmentFromPeopleType = async typeId => {
    await this.props.removeContactTypeFormAssignment({
      formId: this.props.form.id,
      typeIds: [typeId]
    });

    return this.fetchFormAssignments();
  };

  onAssignToPerson = async ({ contactId, closeDate }) => {
    await this.props.addContactFormAssignment({
      eventId: this.props.params.eventId,
      formId: this.props.form.id,
      contacts: [
        {
          id: contactId,
          closeDate
        }
      ]
    });

    this.props.showSnackbar({ message: "Form Assigned", action: "OK" });

    return this.fetchFormAssignments();
  };

  onAssignToGroup = async ({ accountId, closeDate }) => {
    await this.props.addAccountFormAssignment({
      eventId: this.props.params.eventId,
      formId: this.props.form.id,
      accounts: [
        {
          id: accountId,
          closeDate
        }
      ]
    });

    this.props.showSnackbar({ message: "Form Assigned", action: "OK" });

    return this.fetchFormAssignments();
  };

  onRemoveAssignmentFromPerson = async contactId => {
    await this.props.removeContactFormAssignment({
      formId: this.props.form.id,
      contactIds: [contactId]
    });

    this.props.showSnackbar({ message: "Form Unassigned", action: "OK" });

    return this.fetchFormAssignments();
  };

  onRemoveAssignmentFromGroup = async accountId => {
    await this.props.removeAccountFormAssignment({
      formId: this.props.form.id,
      accountIds: [accountId]
    });

    this.props.showSnackbar({ message: "Form Unassigned", action: "OK" });

    return this.fetchFormAssignments();
  };

  onUpdatePersonAssignment = async ({ contactId, closeDate }) => {
    await this.props.updateContactFormAssignment({
      eventId: this.props.params.eventId,
      formId: this.props.form.id,
      contactId,
      closeDate
    });

    this.props.showSnackbar({ message: "Assignment Updated", action: "OK" });

    return this.fetchFormAssignments();
  };

  onUpdateGroupAssignment = async ({ accountId, closeDate }) => {
    await this.props.updateAccountFormAssignment({
      eventId: this.props.params.eventId,
      formId: this.props.form.id,
      accountId,
      closeDate
    });

    this.props.showSnackbar({ message: "Assignment Updated", action: "OK" });

    return this.fetchFormAssignments();
  };

  render() {
    const { loading, copyButtonText } = this.state;
    const {
      routes,
      assignments,
      recordTypes,
      peopleRecordTypes,
      isConferenceTemplate
    } = this.props;

    let tabs = [
      {
        routeName: "ModuleFormShare",
        tabName: "Link",
        onClick: () => this.goToScene("/share"),
        visible: !isConferenceTemplate
      },
      {
        routeName: "ModuleFormShareAssign",
        tabName: "Assign",
        onClick: () => this.goToScene("/share/assign"),
        visible: true
      }
    ].filter(t => t.visible);

    tabs = R.map(t => ({
      ...t,
      isActive: R.any(
        R.compose(
          R.equals(t.routeName),
          R.prop("name")
        )
      )(routes)
    }))(tabs);

    const viewHelpers = {
      onCopyLinkInput: () => this.onCopyLinkInput(),
      onClickLinkInput: this.onClickLinkInput,
      onClickSendForm: () => this.onClickSendForm(),
      onClickAddToAllGroups: () => this.onClickAddToAllGroupTypes(),
      onClickRemoveFromAllGroups: () => this.onClickRemoveFromAllGroupTypes(),
      onClickAssignToGroup: () => this.onClickAssignToGroup(),

      onClickAddToAllPeople: () => this.onClickAddToAllPeopleTypes(),
      onClickRemoveFromAllPeople: () => this.onClickRemoveFromAllPeopleTypes(),
      onClickAssignToPerson: () => this.onClickAssignToPerson()
    };

    const actions = [];

    actions.push({
      id: "assign",
      name: "Assign to group",
      icon: "playlist_add",
      description: "Shows the form on a groups unique event portal",
      onClick: () => this.goToScene("/share/assign")
    });

    const groupTypes = R.compose(
      R.sortBy(
        R.compose(
          name => (name ? R.toLower(name) : name),
          R.propOr("", "name")
        )
      ),
      R.map(type => {
        const isSelected = assignments.types.some(
          a => a.record_type_id === type.id
        );
        return {
          id: type.id,
          name: type.name,
          selected: isSelected,
          onExtendDueDate: () => {},
          onClick: isSelected
            ? () => this.onRemoveAssignmentFromGroupType(type.id)
            : () => this.onAssignToGroupType(type.id)
        };
      })
    )(recordTypes);

    const peopleTypes = R.compose(
      R.sortBy(
        R.compose(
          name => (name ? R.toLower(name) : name),
          R.propOr("", "name")
        )
      ),
      R.map(type => {
        const isSelected = assignments.contactTypes.some(
          a => a.record_type_id === type.id
        );
        return {
          id: type.id,
          name: type.name,
          selected: isSelected,
          onExtendDueDate: () => {},
          onClick: isSelected
            ? () => this.onRemoveAssignmentFromPeopleType(type.id)
            : () => this.onAssignToPeopleType(type.id)
        };
      })
    )(peopleRecordTypes);

    const now = moment();
    const groups = assignments.accounts.map(account => ({
      id: account.id,
      name: account.name,
      closeDate: account.custom_close_date
        ? formaCloseDate(account.custom_close_date, "MMM D, YYYY - h:mmA zz")
        : null,
      hasExpired: account.custom_close_date
        ? now.isAfter(moment(account.custom_close_date))
        : false,
      onClickDueDate: () => this.onClickEditCloseDate(account),
      onUnassign: () => this.onRemoveAssignmentFromGroup(account.id)
    }));
    const people = assignments.contacts.map(contact => ({
      id: contact.id,
      name: contact.name,
      closeDate: contact.custom_close_date
        ? formaCloseDate(contact.custom_close_date, "MMM D, YYYY - h:mmA zz")
        : null,
      hasExpired: contact.custom_close_date
        ? now.isAfter(moment(contact.custom_close_date))
        : false,
      onClickDueDate: () => this.onClickEditPersonCloseDate(contact),
      onUnassign: () => this.onRemoveAssignmentFromPerson(contact.id)
    }));

    return (
      <View
        {...{
          formScope: this.props.form.scope,
          loading,
          tabs,
          activeRoute: R.compose(
            R.prop("routeName"),
            R.find(
              R.compose(
                R.equals(true),
                R.prop("isActive")
              )
            )
          )(tabs),
          linkInputRef: this.linkInputRef,
          // eslint-disable-next-line no-underscore-dangle
          link: `${window.__LENND_APP_URL__}/forms/${this.props.eventDetails.slug}/${this.props.form.slug}/${this.props.form.id}`,
          copyButtonText,
          actions,
          groupTypes,
          peopleTypes,
          groups,
          people,
          ...viewHelpers
        }}
      />
    );
  }
}

export default Controller;
