import PropTypes from "prop-types";
import React from "react";
import ReferenceOption from "../ReferenceOption";
import IconTitle from "../IconTitle";
import Loading from "../Loading";
import getReferenceEmail from "../../../utils/get-email";
import isPerson from "../../../utils/is-person";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const referenceSort = (a, b) =>
  a.display.toLowerCase().localeCompare(b.display.toLowerCase());

const renderTitle = reference => {
  if (isPerson(reference)) {
    return reference.display;
  }
  if (reference.count === 1) {
    return <IconTitle icon="person">{reference.display}</IconTitle>;
  }
  return <IconTitle icon="people">{reference.display}</IconTitle>;
};

const SearchResults = ({
  search,
  references,
  referenceValues,
  onSelect,
  isFetching
}) => {
  if (!references.length && isFetching) {
    return <Loading />;
  }

  return (
    <div>
      <div styleName="count">
        {search.length
          ? `${references.length} Match${references.length === 1 ? "" : "es"}`
          : `Your People (${references.length})`}
      </div>
      {references
        .sort(referenceSort)
        .map(reference => (
          <ReferenceOption
            key={reference.id}
            onClick={e => onSelect(e, reference.id)}
            title={renderTitle(reference)}
            description={getReferenceEmail(reference.id, referenceValues)}
          />
        ))}
    </div>
  );
};

SearchResults.propTypes = {
  isFetching: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  references: PropTypes.array.isRequired,
  referenceValues: PropTypes.object.isRequired,
  search: PropTypes.string.isRequired
};

export default CSSModules(SearchResults, css);
