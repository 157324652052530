import { get } from "lodash";

export const isFetching = (state, eventId) =>
  get(
    state,
    ["inventoryV2", "reports", "categoryOverview", "fetching", eventId],
    false
  );

export const categoryOverviewReport = (state, eventId) =>
  get(
    state,
    ["inventoryV2", "reports", "categoryOverview", "report", eventId],
    []
  );
