import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Documents from "./Documents";

import { addValue } from "redux/modules/modules/values/actions";
import { getRecords } from "redux/modules/modules/records/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";

import * as ModuleRecordsSelectors from "redux/modules/modules/records/selectors";
import * as UserSelectors from "redux/modules/user/selectors";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

function mapStateToProps(state) {
  return {
    documentRequestTypes: ModuleRecordsSelectors.records(
      state,
      STANDARD_MODULE_IDS.documentRequestTypes.id
    ),
    user: UserSelectors.user(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        addValue,
        getRecords,
        showModal,
        hideModal,
        showSnackbar
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Documents);
