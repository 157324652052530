import * as R from "ramda";
import firstBy from "thenby";
import sortValue from "utils/value-types/sort";

const noopSort = (a, b) => 0;

const sortRows = (rows, fields, view, { references, eventDetails }) => {
  if (!view || !view.sort_by || !fields.length || !view.sort_by.length)
    return rows;

  const sortFuncs = R.filter(f => f)(
    R.map(({ fieldId, direction }) => {
      const field = R.find(R.propEq("id", fieldId))(fields);
      if (!field) return null;
      return { sortFunc: sortValue(field.type)[direction], field };
    })(view.sort_by)
  );

  const sortSequence = R.reduce((chainedSortFuncs, { sortFunc, field }) => {
    return chainedSortFuncs.thenBy((a, b) =>
      sortFunc(a.values[field.id], b.values[field.id], {
        // passing meta to the sort fns, including field ID to reference for lookup fields
        references,
        eventDetails,
        fieldId: R.path(["settings", "fieldId"])(field)
      })
    );
  }, firstBy(noopSort))(sortFuncs);

  return R.sort(sortSequence)(rows);
};

export default sortRows;
