import {
  delay,
  put,
  call,
  takeEvery,
  all,
  fork,
  select
} from "redux-saga/effects";
import { actions } from "./index";

import { registerError } from "redux/modules/errors/actions";

import { orgId as getOrgId } from "redux/modules/organization/selectors";

import { eventId as getEventId } from "redux/modules/event/selectors";

import { getCredentials } from "redux/modules/user/selectors";

import Api from "./api";

import { openInNewWindow } from "utils/General";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const eventId = yield select(getEventId);

  return {
    credentials,
    orgId,
    eventId
  };
};

const init = function*() {
  try {
    yield put(actions.setLoading(true));

    const { credentials, eventId } = yield call(getParams);

    const { payload: reports } = yield call(Api.getReports, {
      credentials,
      eventId
    });

    yield put(actions.setInitialData({ reports }));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading reports"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const refetchReports = function*() {
  try {
    const { credentials, eventId } = yield call(getParams);

    const { payload: reports } = yield call(Api.getReports, {
      credentials,
      eventId
    });

    yield put(actions.setReports(reports));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading reports"
          }
        ])
      )
    ]);
  }
};

const runReport = function*({ payload: { reportId, format } }) {
  try {
    yield put(actions.toggleRunningReport(reportId));

    const { credentials, orgId, eventId } = yield call(getParams);

    const { payload } = yield call(Api.runReport, {
      credentials,
      data: {
        orgId,
        eventId,
        reportId,
        format
      }
    });

    yield delay(500);

    let progressResult = yield call(Api.getReportStatus, credentials, {
      jobId: payload.job_id
    });

    while (["pending", "processing"].includes(progressResult.payload.status)) {
      yield delay(3000);
      progressResult = yield call(Api.getReportStatus, credentials, {
        jobId: payload.job_id
      });
    }

    yield call(openInNewWindow, progressResult.payload.payload.fileUrl);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred running report"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.toggleRunningReport(reportId));
    yield put(actions.refetchReports());
  }
};

const goToModuleReports = function*({ payload: moduleId }) {
  const { eventId } = yield call(getParams);
  window.location = `/event/${eventId}/settings/module/${moduleId}/report-templates`;
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchRefetchReports = function*() {
  yield takeEvery(actions.refetchReports.type, refetchReports);
};

const watchGoToModuleReports = function*() {
  yield takeEvery(actions.goToModuleReports.type, goToModuleReports);
};

const watchRunReport = function*() {
  yield takeEvery(actions.runReport.type, runReport);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchRefetchReports),
    fork(watchGoToModuleReports),
    fork(watchRunReport)
  ]);
};

export default rootSaga;
