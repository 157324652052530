import React, { Component } from "react";
import { View, DefaultView } from "./View";
import ModalContainer from "../ModalContainer";
import * as R from "ramda";
import { approvalsDict } from "../approvalsDict";
import { findBy, mapProps, withHandlers, isEmptyOrNil } from "utils/General";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import Editor from "../Editor/index";

const lifecycle = Comp => {
  class Wrapper extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true
      };
    }
    componentDidMount() {
      this.props.getWorkflow().then(() => {
        this.setState({ loading: false });
      });
    }

    render() {
      return <Comp {...this.props} {...this.state} />;
    }
  }
  return Wrapper;
};

////////////////////////////////////////////////////////////////////////////////

const handlers = withHandlers({
  deleteBlock: ({
    spaceId,
    getWorkflow,
    workflow,
    workflowId,
    updateWorkflow,
    deleteBlock,
    getSpace
  }) => blockId => {
    return deleteBlock({
      spaceId,
      workflowId,
      blockId
    })
      .then(response => {
        const lastBlockDeleted = R.length(workflow.blocks) === 1;
        if (lastBlockDeleted) {
          return updateWorkflow({
            spaceId,
            workflow: {
              id: workflowId,
              isEnabled: false
            }
          });
        }
        return Promise.resolve(response);
      })
      .then(getWorkflow)
      .then(getSpace);
  },
  updateBlockSelection: ({
    updateBlockApprovers,
    getWorkflow,
    spaceId,
    workflowId
  }) => (blockId, newSelection) => {
    return updateBlockApprovers({
      spaceId,
      workflowId,
      blockId,
      approvers: newSelection
    }).then(getWorkflow);
  },
  updateBlockRequirement: ({
    updateBlock,
    getWorkflow,
    spaceId,
    workflowId
  }) => (blockId, newState) => {
    return updateBlock({
      workflowId,
      spaceId,
      block: {
        id: blockId,
        isAny: newState
      }
    }).then(getWorkflow);
  },
  reorderApproverGroups: ({
    workflowId,
    updateBlocks,
    spaceId,
    getWorkflow
  }) => newOrder => {
    return updateBlocks({
      spaceId,
      workflowId,
      blocks: newOrder.map(({ id }, i) => ({ id, order: i }))
    }).then(getWorkflow);
  },
  updateMultiStepApproval: ({
    updateWorkflow,
    workflowId,
    getWorkflow,
    spaceId
  }) => newState => {
    return updateWorkflow({
      spaceId,
      workflow: {
        id: workflowId,
        isMultiStepApprovalEnabled: newState
      }
    }).then(getWorkflow);
  },
  updateDefaultApprovers: ({
    updateDefaultApprovers,
    spaceId,
    getWorkflow
  }) => approvers => {
    return updateDefaultApprovers({
      spaceId,
      approvers
    }).then(getWorkflow);
  },

  changeWorkflowName: ({
    updateWorkflow,
    workflowId,
    getSpace,
    spaceId
  }) => newName => {
    return updateWorkflow({
      spaceId,
      workflow: {
        id: workflowId,
        name: newName
      }
    }).then(getSpace);
  },
  addBlock: ({ getSpace, spaceId, workflowId, addBlock, getWorkflow }) => (
    approvers,
    order
  ) => {
    return addBlock({
      spaceId,
      workflowId,
      order,
      approvers
    })
      .then(getWorkflow)
      .then(getSpace);
  },
  addTrigger: ({
    spaceId,
    workflowId,
    addTrigger,
    getWorkflow,
    workflow,
    getSpace,
    updateTriggers
  }) => (selection, order, type) => {
    return addTrigger({
      spaceId,
      workflowId,
      order,
      type,
      records: selection.map(id => ({ id, type })),
      isAnd: true
    })
      .then(() => {
        return updateTriggers({
          spaceId,
          workflowId,
          bulk: true,
          triggers: R.compose(
            R.map(({ id, order: currentOrder }) => ({
              id,
              order: currentOrder + 1
            })),
            R.filter(({ order: currentOrder }) => currentOrder >= order)
          )(workflow.triggers)
        });
      })
      .then(getWorkflow)
      .then(getSpace);
  },
  deleteTrigger: ({
    deleteTrigger,
    spaceId,
    workflowId,
    getWorkflow,
    getSpace,
    workflow,
    updateWorkflow
  }) => triggerId => {
    return deleteTrigger({
      spaceId,
      workflowId,
      triggerId
    })
      .then(response => {
        const lastTriggerDeleted = R.length(workflow.triggers) === 1;
        if (lastTriggerDeleted) {
          return updateWorkflow({
            spaceId,
            workflow: {
              id: workflowId,
              isEnabled: false
            }
          });
        }
        return Promise.resolve(response);
      })
      .then(getWorkflow)
      .then(getSpace);
  },
  updateIsAnd: ({ updateTrigger, spaceId, workflowId, getWorkflow }) => (
    newState,
    triggerId
  ) => {
    return updateTrigger({
      spaceId,
      workflowId,
      trigger: {
        id: triggerId,
        isAnd: newState
      }
    }).then(getWorkflow);
  },
  updateTriggerSelection: ({
    updateTriggerRecords,
    getWorkflow,
    spaceId,
    workflowId
  }) => (triggerId, newSelection) => {
    return updateTriggerRecords({
      spaceId,
      workflowId,
      triggerId,
      records: newSelection
    }).then(getWorkflow);
  },
  reorderTriggers: ({
    updateTriggers,
    spaceId,
    workflowId,
    getWorkflow
  }) => newOrder => {
    return updateTriggers({
      spaceId,
      workflowId,
      bulk: true,
      triggers: newOrder.map(({ id }, i) => ({ id, order: i }))
    }).then(getWorkflow);
  },
  updateWorkflowActive: ({
    getSpace,
    updateWorkflow,
    spaceId,
    getWorkflow
  }) => (newState, workflowId) => {
    return updateWorkflow({
      spaceId,
      workflow: {
        id: workflowId,
        isEnabled: newState
      }
    })
      .then(getWorkflow)
      .then(getSpace);
  },
  toggleDefaultIsAny: ({ toggleDefaultIsAny, getWorkflow }) => isAny => {
    return toggleDefaultIsAny(isAny).then(getWorkflow);
  }
});

const downstreamHandlers = withHandlers({
  showEditorModal: ({
    showModal,
    spaceId,
    workflowId,
    eventDetails,
    updateDefaultApprovers,
    updateWorkflow,
    workflow,
    updateBlockSelection,
    deleteBlock,
    getWorkflow,
    addBlock,
    addTrigger,
    deleteTrigger,
    updateTriggerSelection
  }) => ({ type, blockId, selection, order }) => {
    return showModal({
      content: (
        <Editor
          selection={selection}
          sectorType={approvalsDict[type].sectorType}
          order={order}
          addBlock={addBlock}
          addTrigger={addTrigger}
          updateDefaultApprovers={updateDefaultApprovers}
          updateBlockSelection={updateBlockSelection}
          deleteBlock={deleteBlock}
          deleteTrigger={deleteTrigger}
          updateTriggerSelection={updateTriggerSelection}
          updateWorkflow={updateWorkflow}
          getWorkflow={getWorkflow}
          eventId={eventDetails.id}
          spaceId={spaceId}
          workflowId={workflowId}
          workflow={workflow}
          type={type}
          blockId={blockId}
        />
      ),
      wrapper: ModalWrapper
    });
  }
});

////////////////////////////////////////////////////////////////////////////////

const Parser = mapProps(
  ({
    loading,
    workflow,
    workflowId,
    hideModal,
    changeWorkflowName,
    showEditorModal,
    updateIsAnd,
    deleteBlock,
    reorderApproverGroups,
    updateMultiStepApproval,
    updateBlockRequirement,
    deleteTrigger,
    reorderTriggers,
    editDefaultApprovers,
    addDefaultApprovers,
    updateWorkflowActive
  }) => {
    const {
      name,
      is_enabled: active,
      triggers = [],
      blocks,
      is_multi_step_approval_enabled: multiStepApprovalsActive
    } = workflow;

    const approverGroups = blocks || [];

    const makeTriggerMenuItems = order => {
      const appliedOrder = order + 1;
      return R.compose(
        R.map(([type, { title }]) => [
          title,
          () =>
            showEditorModal({
              type,
              blockId: "new",
              selection: [],
              order: appliedOrder
            })
        ]),
        R.toPairs,
        R.filter(x => x.sectorType === "trigger")
      )(approvalsDict);
    };

    const formattedTriggers = R.addIndex(R.map)(
      ({ is_and, trigger_records, order, type, id }, i) => {
        const { title = "", quanta = "", RowComp } = approvalsDict[type];
        const lastIndex = R.length(triggers) - 1;
        const editTrigger = () =>
          showEditorModal({
            type,
            blockId: id,
            selection: trigger_records.map(R.prop("record_id")),
            order
          });
        return {
          key: id,
          id,
          quanta,
          title,
          isLast: i === lastIndex,
          rows: R.map(
            ({ id, ...props }) => <RowComp key={id} {...props} />,
            trigger_records
          ),
          isAnd: is_and,
          toggleAndOr: () => updateIsAnd(!is_and, id),
          triggerMenuItems: makeTriggerMenuItems(order),
          editTrigger,
          menuItems: [
            ["Edit Trigger", editTrigger],
            ["Delete Trigger", () => deleteTrigger(id)]
            // ["Duplicate Trigger", () => duplicateTrigger(id)]
          ]
        };
      },
      R.sortBy(R.prop("order"), triggers)
    );

    const formattedApproverGroups = R.addIndex(R.map)(
      ({ approvers, id, approverType = "user", is_any }, index) => {
        const { quanta = "", RowComp } = approvalsDict[approverType];
        const numSelection = R.length(approvers);
        const noApprovers = numSelection === 0;

        const editThisGroup = () =>
          showEditorModal({
            type: approverType,
            blockId: id,
            selection: R.map(R.prop("user_id"), approvers)
          });
        return {
          key: id,
          id,
          quanta,
          title: `Approver Group ${multiStepApprovalsActive ? index + 1 : ""}`,
          subtitle: noApprovers
            ? "no approvers added yet"
            : is_any ? "any required" : "all required",
          rows: R.map(
            ({ id, ...props }) => <RowComp key={id} {...props} />,
            approvers
          ),
          menuItems: [
            [
              is_any ? "Require All" : "Require Any",
              () => updateBlockRequirement(id, !is_any)
            ],
            ["Edit Approver Group", editThisGroup],
            ["Delete Approver Group", () => deleteBlock(id)]
          ],
          editApproverGroup: editThisGroup
        };
      },
      approverGroups
    );

    const hasTrigger = !isEmptyOrNil(triggers);
    const hasApprover = !isEmptyOrNil(approverGroups);
    const hasTriggerAndApproval = hasApprover && hasTrigger;

    const addTriggerMenuItems = makeTriggerMenuItems(
      R.propOr(-1, "order", R.last(triggers))
    );

    return {
      workflowId,
      hideModal,
      loading,
      name,
      active,
      triggers: formattedTriggers,
      approverGroups: formattedApproverGroups,
      multiStepApprovalsActive,
      toggleMultiStepApprovals: () =>
        updateMultiStepApproval(!multiStepApprovalsActive),
      toggleWorkflow: () => updateWorkflowActive(!active, workflowId),
      changeWorkflowName,
      reorderTriggers,
      newApproverGroup: () =>
        showEditorModal({
          type: "user",
          blockId: "new",
          selection: [],
          order: R.length(approverGroups) || 0
        }),
      addTriggerMenuItems,
      reorderApproverGroups,
      hasTrigger,
      hasApprover,
      hasTriggerAndApproval,
      editDefaultApprovers,
      addDefaultApprovers
    };
  }
);

////////////////////////////////////////////////////////////////////////////////

const DefaultParser = mapProps(
  ({
    workflow,
    toggleDefaultIsAny,
    showEditorModal,
    workflowId,
    hideModal
  }) => {
    const approverGroups = R.propOr([], "blocks", workflow);
    const defaultApproverGroup = findBy("id", "default")(approverGroups) || {};
    const defaultApprovers = R.propOr([], "approvers", defaultApproverGroup);
    const defaultIsAny = R.prop("is_any", defaultApproverGroup);

    // const meta = { users };
    const type = "user";
    const { quanta = "", RowComp } = approvalsDict[type];
    // const getRowProps = x => findBy("id", x)(R.propOr([], rowProp, meta));
    const openEditor = () => {
      showEditorModal({
        type,
        blockId: "default",
        selection: R.map(R.prop("user_id"), defaultApprovers)
      });
    };
    return {
      hideModal,
      workflowId,
      title: "Approvers",
      subtitle: defaultIsAny ? "any required" : "all required",
      rows: R.map(
        ({ id, ...props }) => <RowComp key={id} {...props} />,
        defaultApprovers
      ),
      quanta,
      menuItems: [
        [
          defaultIsAny ? "Require All" : "Require Any",
          () => toggleDefaultIsAny(!defaultIsAny)
        ]
      ],
      editDefaultApprovers: openEditor,
      addDefaultApprovers: openEditor,
      hasApprover: !isEmptyOrNil(defaultApprovers)
    };
  }
);

////////////////////////////////////////////////////////////////////////////////

const ViewBrancher = props => {
  if (props.workflowId === "default") {
    return <DefaultView {...props} />;
  }
  return <View {...props} />;
};

const ParseBrancher = Comp => props => {
  let Result = Parser(Comp);
  if (props.workflowId === "default") {
    Result = DefaultParser(Comp);
  }
  return <Result {...props} />;
};

////////////////////////////////////////////////////////////////////////////////

const Controller = R.compose(
  lifecycle,
  handlers,
  downstreamHandlers,
  ParseBrancher,
  ModalContainer
)(ViewBrancher);

export default Controller;
