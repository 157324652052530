import React from "react";

import { connect } from "react-redux";
import { Div } from "components/Base";

import { getters } from "../model";
import { ROW_ACTIONS } from "../constants";

import Table, { TableWrapper } from "ui-kit/Table/View";
import { TABLE_INSTANCE_ID } from "../constants";
import EmptyState from "OrgLight/Common/EmptyState";

const decorate = connect(state => ({
  types: getters.types(state)
}));

const Body = ({ types }) => (
  <>
    {!types.length ? (
      <EmptyState
        icon="group"
        title="You don't have any types yet"
        description="Manage different types of groups affiliated with your event"
      />
    ) : (
      <Div width={1}>
        <TableWrapper
          instanceId={TABLE_INSTANCE_ID}
          style={{ flex: 1, overflowY: "inherit" }}
          innerStyle={{ overflowY: "inherit" }}
        >
          <Table instanceId={TABLE_INSTANCE_ID} showCheckbox={false} />
        </TableWrapper>
      </Div>
    )}
  </>
);

export default decorate(Body);
