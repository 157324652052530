import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import {
  Div,
  MediumFilledButton,
  DownFilledIcon,
  Popover,
  SmallCheckbox,
  Text1
} from "components/Base";

import { actions } from "../model";
import { withProps } from "utils/General";
import { filterSelected } from "../selectors";

import { bindInstance } from "redux-mvc";

const PopMenuItem = withProps({
  p: 1,
  width: 1,
  display: "row.flex-start.center",
  bg: { hover: "neutral3", default: "transparent" }
})(Div);

const PopMenuContainer = withProps({
  style: {
    overflowY: "auto"
  },
  bra: 1,
  p: 1,
  bg: "white",
  shadow: 2,
  width: 200,
  maxHeight: 250,
  px: 2
})(Div);

const decorate = connect(
  (state, props) => ({
    filterSelected: filterSelected(state, props)
  }),
  bindInstance({
    selectFilter: actions.toggleFilter
  })
);

const FilterBy = ({
  name,
  type,
  elements,
  filterSelected,
  selectFilter,
  ...styleProps
}) => (
  <Popover
    wrapperProps={styleProps}
    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
    Label={({ onClick }) => (
      <MediumFilledButton
        RightIcon={DownFilledIcon}
        pill
        bg="neutral2"
        color="gray8"
        onClick={onClick}
      >
        {name}
      </MediumFilledButton>
    )}
  >
    {() => (
      <PopMenuContainer>
        {elements.map(({ id, name }) => (
          <PopMenuItem
            key={id}
            onClick={() => {
              selectFilter({ type, id });
            }}
          >
            <SmallCheckbox mr={2} selected={R.contains(id, filterSelected)} />
            <Text1>{name}</Text1>
          </PopMenuItem>
        ))}
      </PopMenuContainer>
    )}
  </Popover>
);

export default decorate(FilterBy);
