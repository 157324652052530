import React from "react";
import { Helmet } from "react-helmet";

import { orgDetails as getOrgDetails } from "redux/modules/organization/selectors";
import { connect } from "react-redux";

const decorate = connect(state => ({
  orgDetails: getOrgDetails(state)
}));

const PageTitle = ({ orgDetails, titles = [] }) => {
  return (
    <>
      <Helmet>
        <title>
          {titles.join(" - ")} - {orgDetails.name} | Lennd
        </title>
      </Helmet>
    </>
  );
};

export default decorate(PageTitle);
