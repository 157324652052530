import React, { Component } from "react";
import getValue from "utils/value-types/get-value/catering";
import { NONE } from "utils/system-field-values";
import { Div } from "components/Base";
import { get, sumBy, isEqual } from "lodash";
import moment from "moment";
import twix from "twix";

const Wrapper = ({ children }) => (
  <Div
    display="row.flex-start.center"
    minHeight={23}
    style={{
      overflow: "hidden",
      userSelect: "none"
    }}
  >
    {children}
  </Div>
);

const Meal = ({ children }) => (
  <Div
    display="row.flex-start.center"
    mr={2}
    style={{
      flexBasis: "auto",
      flexShrink: 0,
      borderRadius: 25,
      backgroundColor: "#EAEAEA",
      lineHeight: "normal",
      whiteSpace: "nowrap"
    }}
  >
    {children}
  </Div>
);

const Title = ({ children }) => (
  <Div
    style={{
      padding: "3px 6px 3px 8px",
      fontSize: 12
    }}
  >
    {children}
  </Div>
);

class Catering extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  formatValue(props) {
    return getValue(props.value);
  }

  render() {
    const value = this.formatValue(this.props);
    if (
      get(value, "meals") &&
      value.meals.length &&
      value.meals.some(m => m.id && m.id === NONE.id && m.checked)
    ) {
      return (
        <Wrapper>
          <Meal>
            <Title>{NONE.value}</Title>
          </Meal>
        </Wrapper>
      );
    }

    let daysOnSite = null;
    const valueKeys = Object.keys(this.props.dependentValues);
    for (let i = 0; i < valueKeys.length; i++) {
      if (
        this.props.dependentValues[valueKeys[i]] &&
        this.props.dependentValues[valueKeys[i]].type &&
        this.props.dependentValues[valueKeys[i]].type === "event-days"
      ) {
        daysOnSite = this.props.dependentValues[valueKeys[i]];
        break;
      }
    }

    return (
      <Wrapper>
        {value.meals.map(meal => {
          let dates;

          // if we have a days-on-site field to reference, only show
          // selected meal days that match the date ranges of the days on site
          if (daysOnSite) {
            dates = get(meal, "dates", []).filter(({ date }) => {
              let isWithinSelectedDaysOnSite = false;
              get(daysOnSite, "value", []).forEach(d => {
                const contains = moment(d.start)
                  .twix(d.end)
                  .contains(date);
                if (contains) {
                  isWithinSelectedDaysOnSite = true;
                }
              });
              return isWithinSelectedDaysOnSite;
            });
          } else {
            dates = meal.dates;
          }

          if (!dates.length) {
            return "";
          }
          return (
            <Meal key={meal.id}>
              <Title>{meal.name}</Title>
              <Div
                style={{
                  background: "rgba(#E4E3E4, 0.75)",
                  borderRadius: 50,
                  fontSize: 13,
                  padding: "3px 9px",
                  alignSelf: "stretch",
                  fontWeight: "bold"
                }}
              >
                {sumBy(dates, "quantity")}
              </Div>
            </Meal>
          );
        })}
      </Wrapper>
    );
  }
}

export default Catering;
