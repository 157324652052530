import React from "react";

import { connect } from "react-redux";

import { Div } from "components/Base";

import {
  getAddItemQuestionBodyHeight,
  isSelectFieldsPage,
  isAddQuestionPage
} from "Items/AddQuestionModal/selectors";

import SelectQuestionFields from "./SelectQuestionFields";

import AddQuestion from "./AddQuestion";

const decorate = connect(state => ({
  bodyHeight: getAddItemQuestionBodyHeight(state),
  selectFieldsPage: isSelectFieldsPage(state),
  addQuestionPage: isAddQuestionPage(state)
}));

const ItemQuestionBody = ({
  bodyHeight,
  selectFieldsPage,
  addQuestionPage
}) => (
  <Div
    width={1}
    height={bodyHeight}
    style={{
      overflowY: "auto"
    }}
  >
    {selectFieldsPage && <SelectQuestionFields />}
    {addQuestionPage && <AddQuestion />}
  </Div>
);

export default decorate(ItemQuestionBody);
