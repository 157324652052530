import React, { Component } from "react";
import { get } from "lodash";
import OrderModal from "Orders/OrderModal/View";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { baseIcon, baseText, baseContainer } from "../_base";

const getFormId = value => get(value, ["meta", "formId"]);
const getSubmissionId = value => get(value, ["meta", "submissionId"]);
const getFormName = (id, references) => get(references, ["forms", id]);

class SourceSubmission extends Component {
  refetchRecords = () => {
    if (this.props.moduleId) {
      this.props.getRecords({
        moduleId: this.props.moduleId,
        options: {
          eventId: this.props.eventId
        }
      });
    }
    this.props.hideModal();
  };

  showSubmissionSummaryModal = (formId, submissionId) => () =>
    this.props.showModal({
      content: (
        <OrderModal
          hideModal={this.refetchRecords}
          submissionId={submissionId}
        />
      ),
      wrapper: ModalWrapper
    });

  render() {
    const { value, references } = this.props;
    const formName = getFormName(getFormId(value), references);
    const formId = getFormId(value);
    const submissionId = getSubmissionId(value);

    return (
      <div style={{ ...baseContainer, cursor: "pointer" }}>
        <i
          className="material-icons"
          style={{
            ...baseIcon,
            transform: "rotateZ(135deg)"
          }}
        >
          link
        </i>
        <div
          style={baseText}
          title={formName}
          onClick={this.showSubmissionSummaryModal(formId, submissionId)}
        >
          {formName}
        </div>
      </div>
    );
  }
}

SourceSubmission.defaultProps = {
  references: {},
  value: {}
};

export default SourceSubmission;
