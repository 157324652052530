import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE } from "Items/AddQuestionSetModal/constants";

const iniState = {
  questionSetId: null,
  //
  name: "",
  loading: false,
  saving: false
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    init: R.identity,
    save: R.identity,
    cancelInstance: R.always(iniState),
    setInitialQuestionSet: (state, { payload: { id, name } }) => ({
      questionSetId: id,
      name
    })
  }
});

export default handlers;
