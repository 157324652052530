import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { UploadIcon, DownloadIcon, SettingsIcon } from "components/Base";
import Divider from "material-ui/Divider";
import CanUserDo from "components/Global/Security/CanUserDo";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import CSSModules from "react-css-modules";
import ImportModal from "Modules/ImportModal/View";
import css from "../styles.scss";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

const decorate = connect(
  state => ({
    isLenndAdmin: selectFeatureFlag(flags.IS_LENND_ADMIN.NAME)(state)
  }),
  {
    showModal,
    hideModal
  }
);

const Menu = CSSModules(
  ({ children }) => <div styleName="menu">{children}</div>,
  css
);
const MenuItem = CSSModules(
  ({ onClick, children }) => (
    <div role="link" tabIndex="0" styleName="menuItem" onClick={onClick}>
      {children}
    </div>
  ),
  css
);

@CSSModules(css)
class ModuleActionsMenu extends Component {
  wrapAction(action) {
    const { close } = this.props;
    return () => {
      action();
      close();
    };
  }

  showImportModal = () => {
    this.props.close();
    this.props.showModal({
      content: <ImportModal {...this.props} />,
      wrapper: ModalWrapper
    });
  };

  render() {
    const {
      module,
      showSettings,
      manageFields,
      exportXLSX,
      exportCSV,
      isLenndAdmin
    } = this.props;
    return (
      <Menu>
        {exportXLSX ? (
          <CanUserDo action={`${module.id}_export`}>
            <MenuItem onClick={this.wrapAction(exportXLSX)}>
              <DownloadIcon mr={2} size={20} color="neutral3" />
              <span>Export .XLSX</span>
            </MenuItem>
          </CanUserDo>
        ) : (
          ""
        )}
        {exportCSV ? (
          <CanUserDo action={`${module.id}_export`}>
            <MenuItem onClick={this.wrapAction(exportCSV)}>
              <DownloadIcon mr={2} size={20} color="neutral3" />
              <span>Export .CSV</span>
            </MenuItem>
          </CanUserDo>
        ) : (
          ""
        )}
        <MenuItem onClick={this.showImportModal}>
          <UploadIcon mr={2} size={20} color="neutral3" />
          <span>Import .CSV</span>
        </MenuItem>
        {showSettings ? (
          <CanUserDo action={`${module.id}_manage`}>
            <CanUserDo any={[`${module.id}_export`]}>
              <Divider />
            </CanUserDo>
            <MenuItem onClick={this.wrapAction(manageFields)}>
              <SettingsIcon mr={2} size={20} color="neutral3" />
              <span>Settings</span>
            </MenuItem>
          </CanUserDo>
        ) : (
          ""
        )}
      </Menu>
    );
  }
}

ModuleActionsMenu.propTypes = {
  module: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  close: PropTypes.func.isRequired,
  manageFields: PropTypes.func.isRequired,
  exportCSV: PropTypes.func,
  exportXLSX: PropTypes.func,
  showSettings: PropTypes.bool
};

export default decorate(ModuleActionsMenu);
