import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const ReferenceOption = ({ title, description, ...other }) => (
  <div styleName="optionContainer" {...other}>
    <div styleName="optionTitle">{title}</div>
    <div styleName="optionDescription">{description}</div>
  </div>
);

ReferenceOption.propTypes = {
  styles: PropTypes.object,
  title: PropTypes.string.isRequired,
  description: PropTypes.string
};

export default CSSModules(ReferenceOption, css);
