import React, { Component } from "react";
import PropTypes from "prop-types";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";
import InternalHandler from "./Internal";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import ItemBlockModal from "components/Event/Settings/Credentials/Modals/ItemBlock";

class ExternalHandler extends Component {
  getBlock = () => this.props.getItemBlock(this.props.itemBlockId);

  getTypes = () => this.props.getItemTypesByEvent(this.props.eventDetails.id);

  showItemBlockModal = id => {
    this.props.showModal({
      content: <ItemBlockModal onDone={this.props.onDone} itemBlockId={id} />,
      wrapper: ModalWrapper
    });
  };

  render() {
    return <InternalHandler {...this} {...this.props} />;
  }
}

ExternalHandler.propTypes = {
  itemBlockId: PropTypes.string,
  eventDetails: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  getItemBlock: PropTypes.func.isRequired,
  getItemTypesByEvent: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default ExternalHandler;
