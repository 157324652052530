export const NAMESPACE = "ModuleDashboard";

export const TABLE_INSTANCE_ID = "dashboard";
export const VIEWPICKER_INSTANCE_ID = "dashboard";
export const TABLE_VIEW_ID = "module-dashboard-table";

export const STATUS = {
  ALL: "all",
  NO_PRIMARY_CONTACTS: "no-primary-contacts",
  UNPAID: "unpaid",
  PAID: "paid",
  PENDING: "pending",
  APPROVED: "approved",
  DENIED: "denied"
};

export const ROW_ACTIONS = {
  VIEW_RECORD: "row-viewRecord",
  SEND_EMAIL: "row-sendEmail",
  EDIT: "row-edit",
  SEND_ALL_ITEMS: "row-sendAllItems",
  SEND_ALL_PASSES: "row-sendAllPasses",
  SEND_ALL_MEALS: "row-sendAllMeals",
  SEND_ALL_PASSES_MEALS: "row-sendAllPassesMeals",
  SEND_ALL_ASSETS: "row-sendAllAssets",
  SEND_LOGIN: "row-sendLogin",
  SEND_LINK: "row-sendLink",
  COPY_LOGIN: "row-copyLogin",
  COPY_LINK: "row-copyLink",
  OPEN_LINK: "row-openLink",
  DOCUMENT_REQUESTS: "row-documentRequests",
  ASSIGN_FORMS: "row-forms",
  ASSIGN_OWNERS: "row-assignOwners",
  CHANGE_GROUP: "row-changeGroup",
  CHANGE_PERSON: "row-changeGroup",
  GENERATE_DOCUMENT: "row-generateDocument",
  RUN_REPORT: "row-runReport",
  UPLOAD_FILES: "row-uploadFiles",
  DELETE_RECORDS: "row-deleteRecords",
  VIEW_PERSON: "row-viewPerson",
  SYNC_TO_FUZION: "row-syncToFuzion",
  GIVE_LOGIN_ACCESS: "row-giveLoginAccess",
  SHARE_LOGIN_DETAILS: "row-shareLoginDetails",
  GIVE_CONTACT_LOGIN_ACCESS: "row-giveContactLoginAccess",
  SHARE_CONTACT_LOGIN_DETAILS: "row-shareContactLoginDetails",
  APPROVE_ROWS: "row-approveRows",
  DENY_ROWS: "row-denyRows"
};
