import * as R from "ramda";
import React from "react";
import CSSModules from "react-css-modules";
import { connect } from "react-redux";

import { actions, getters } from "../model";

import { CAPTION_STATES } from "../constants";

import {
  MediumOutlineButton,
  NotVisibleIcon,
  VisibleIcon,
  Text5,
  Text2,
  FilterIcon,
  DownloadIcon,
  PrintIcon,
  OpenInNewIcon,
  RightIcon
} from "components/Base";
import StatusCircle from "./StatusCircle";
import styleSpects from "components/Base/Base/styleSpecs";

import styles from "./styles.scss";

import { noop } from "utils/General";

const mapIcons = {
  visibleIcon: VisibleIcon,
  filter: FilterIcon,
  download: DownloadIcon,
  printIcon: PrintIcon,
  openInNewIcon: OpenInNewIcon,
  rightIcon: RightIcon
};

const mapCaptionStates = {
  [CAPTION_STATES.BREAKING]: {
    text: "Breaking",
    color: styleSpects.color.danger7,
    icon: "error"
  },
  [CAPTION_STATES.REQUIRED]: {
    text: "Required",
    color: styleSpects.color.danger7
  },
  [CAPTION_STATES.OPTIONAL]: {
    text: "Optional",
    color: styleSpects.color.info5
  }
};

const decorate = R.compose(
  connect(
    (state, props) => ({
      expandedCards: getters.expandedCards(state, props)
    }),
    {
      executeAction: actions.executeAction,
      toggleExpandCard: actions.toggleExpandCard
    }
  ),
  CSSModules(styles)
);

const ActionItem = decorate(
  ({
    id = "",
    title = "",
    caption = "",
    captionState = CAPTION_STATES.EMPTY,
    icon = "",
    status,
    buttons = [],
    data = {},
    expandable = false,
    children,
    expandedCards = {},
    toggleExpandCard = noop,
    executeAction = noop
  }) => (
    <div styleName="action-item">
      <div styleName="header">
        <div styleName="item-titles">
          {icon ? (
            <i styleName="left-icon" className="material-icons">
              {icon}
            </i>
          ) : (
            <StatusCircle
              status={status}
              size={30}
              className={styles["left-circle"]}
            />
          )}
          <div styleName="title">
            <div styleName="title-caption">
              <Text5 bold mr={2} className={styles["title-text"]}>
                {title}
              </Text5>
              {captionState !== CAPTION_STATES.EMPTY ? (
                <div styleName="caption-state">
                  {R.prop("icon", mapCaptionStates[captionState]) ? (
                    <i
                      styleName="caption-icon"
                      className="material-icons"
                      style={{
                        color: R.prop("color", mapCaptionStates[captionState])
                      }}
                    >
                      {R.prop("icon", mapCaptionStates[captionState])}
                    </i>
                  ) : null}
                  <Text2
                    bold
                    underline
                    color={R.prop("color", mapCaptionStates[captionState])}
                  >
                    {R.prop("text", mapCaptionStates[captionState])}
                  </Text2>
                </div>
              ) : null}
            </div>
            <Text2>{caption}</Text2>
          </div>
        </div>
        <div styleName="actions">
          {expandable ? (
            <MediumOutlineButton
              LeftIcon={
                R.contains(id, expandedCards) ? NotVisibleIcon : VisibleIcon
              }
              onClick={() => toggleExpandCard(id)}
              mr={2}
            >
              {R.contains(id, expandedCards) ? "Less" : "More"}
            </MediumOutlineButton>
          ) : null}

          {R.map(
            button => (
              <MediumOutlineButton
                LeftIcon={mapIcons[button.icon]}
                RightIcon={mapIcons[button.rightIcon]}
                key={button.id}
                onClick={() => executeAction({ actionId: button.id, data })}
                mr={2}
                color={button.primary ? "primary7" : ""}
              >
                {button.label}
              </MediumOutlineButton>
            ),
            buttons
          )}
        </div>
      </div>
      {R.contains(id, expandedCards) ? children : null}
    </div>
  )
);

export { ActionItem };
