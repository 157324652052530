import { get } from "lodash";
import Range from "moment-range";

const format = "YYYY-MM-DD";
export default function(value) {
  const eventDays = get(value, "value");

  if (!Array.isArray(eventDays)) {
    return [];
  }
  return eventDays.reduce((days, dayRange) => {
    new Range(dayRange.start, dayRange.end).by("days", day => {
      days.push(day.utc().format(format));
    });
    return days;
  }, []);
}
