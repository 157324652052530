/* eslint-disable no-underscore-dangle */
import { put, takeEvery, all, fork, call, select } from "redux-saga/effects";
import { registerError } from "redux/modules/errors/actions";
import { actions, getters } from "../model";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { apiCall } from "App/Data/sagas";
import * as R from "ramda";
import { getters as FormGetters } from "ui-kit/Form/model";
import { REQUEST_ITEM_SETTINGS_MODAL_FORM_ID } from "../constants";

const getSellableItems = function*({ payload: showLoading }) {
  try {
    if (showLoading) {
      yield put(actions.setLoadingSellItems(true));
    }
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    const { payload } = yield call(apiCall, {
      method: "get",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/sellable-items`
    });

    yield put(actions.setAssignedSellItems(payload));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error loading auto assigned items"
        }
      ])
    );
  } finally {
    yield put(actions.setLoadingSellItems(false));
  }
};

const onSelectRequestableGroupItems = function*({ payload }) {
  try {
    const eventId = yield select(getEventId);
    const variantIds = R.compose(
      R.filter(id => R.path([id, "qty"])(payload)),
      R.keys
    )(payload);
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/sellable-items`,
      data: {
        items: R.map(vId => ({
          variantId: vId,
          maxQuantity: null,
          status: "approved",
          price: null
        }))(variantIds)
      }
    });

    yield all([
      put(actions.getSellableItems(true)),
      put(actions.setShowSelectRequestableItemsModal(false))
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while saving settings"
        }
      ])
    );
  }
};

const removeSellItem = function*({ payload: variantId }) {
  try {
    const recordTypeId = yield select(getters.selectedTypeId);
    const eventId = yield select(getEventId);

    yield call(apiCall, {
      method: "delete",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/sellable-item/variant/${variantId}`
    });

    yield put(actions.getSellableItems());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while deleting item"
        }
      ])
    );
  }
};

const openUpdateSellItemModal = function*() {
  const fields = yield select(FormGetters.fields, {
    instanceId: REQUEST_ITEM_SETTINGS_MODAL_FORM_ID
  });
  const quantity = R.pathOr(0, ["max_quantity", "value"], fields) || 0;
  const status = R.pathOr("pending", ["status", "value", 0], fields);
  const price = R.pathOr(0, ["price", "value"], fields);

  try {
    const recordTypeId = yield select(getters.selectedTypeId);
    const variantId = yield select(getters.selectedModalId);
    const eventId = yield select(getEventId);

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/sellable-item/variant/${variantId}`,
      data: {
        maxQuantity: quantity < 1 ? "1" : quantity.toString(),
        status,
        price: price <= 0 ? null : price.toString()
      }
    });

    yield all([
      put(actions.setShowUpdatePersonItemModal(false)),
      put(actions.getSellableItems(true))
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while updating sellable item"
        }
      ])
    );
  }
};

const watchGetSellableItems = function*() {
  yield takeEvery(actions.getSellableItems.type, getSellableItems);
};

const watchRemoveSellItem = function*() {
  yield takeEvery(actions.removeSellItem.type, removeSellItem);
};

const watchOnSelectRequestableGroupItems = function*() {
  yield takeEvery(
    actions.onSelectRequestableGroupItems.type,
    onSelectRequestableGroupItems
  );
};

const watchUpdateSellItem = function*() {
  yield takeEvery(
    actions.openUpdateSellItemModal.type,
    openUpdateSellItemModal
  );
};

const rootSaga = function*() {
  yield all([
    fork(watchGetSellableItems),
    fork(watchOnSelectRequestableGroupItems),
    fork(watchRemoveSellItem),
    fork(watchUpdateSellItem)
  ]);
};

export default rootSaga;
