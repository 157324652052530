import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";

import { actions } from "./index";

import { registerError } from "redux/modules/errors/actions";

import { getCredentials } from "redux/modules/user/selectors";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";

import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const eventId = yield select(getEventId);

  return {
    credentials,
    orgId,
    eventId
  };
};

const init = function*({
  payload: {
    moduleId,
    enableRequiredFields,
    existingFields,
    selectedFieldIds,
    requiredFieldIds,
    defaultSelectedFieldIds,
    defaultRequiredFieldIds
  }
}) {
  try {
    yield put(actions.setLoading(true));

    const { credentials, orgId, eventId } = yield call(getParams);

    let fieldsToUse = existingFields;

    if (!fieldsToUse) {
      const { fields } = yield call(Api.getFields, {
        credentials,
        moduleId,
        options: {
          orgId,
          eventId
        }
      });
      fieldsToUse = fields.fields;
    }

    yield put(
      actions.setInitialData({
        enableRequiredFields,
        fields: fieldsToUse,
        selectedFieldIds,
        requiredFieldIds,
        defaultSelectedFieldIds,
        defaultRequiredFieldIds
      })
    );
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading fields"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([fork(watchInit)]);
};

export default rootSaga;
