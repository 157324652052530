import React from "react";
import { connect } from "react-redux";
import { Div, BuildingIcon, ListIcon } from "components/Base";
import { actions } from "Forms/WizardModal";
import HeaderTitle from "Forms/WizardModal/View/HeaderTitle";
import FormItem from "./FormItem";
import { PAGES } from "Forms/WizardModal/constants";
import * as flags from "utils/feature-flags";
import { selectFeatureFlag } from "@flopflip/react-redux";

const decorate = connect(
  state => ({
    canViewInventory: selectFeatureFlag(flags.CAN_VIEW_INVENTORY.NAME)(state),
    canCreateCustomModules: selectFeatureFlag(
      flags.CAN_CREATE_CUSTOM_MODULES.NAME
    )(state)
  }),
  {
    goToInventoryForm: () => actions.setPage({ page: PAGES.INVENTORY }),
    goToPasses: () => actions.setPage({ page: PAGES.PASSES }),
    goToMeals: () => actions.setPage({ page: PAGES.MEALS }),
    goToNewInventory: () => actions.setPage({ page: PAGES.NEW_INVENTORY }),
    goToCustomModule: () => actions.setPage({ page: PAGES.CUSTOM }),
    goToApplications: () => actions.setPage({ page: PAGES.APPLICATIONS }),
    getModulesWithFields: () => actions.getModulesWithFields(),
    getSchedulesWithFields: () => actions.getSchedulesWithFields()
  }
);

const SelectForm = ({
  handlers,
  goToInventoryForm,
  goToPasses,
  goToMeals,
  goToNewInventory,
  getModulesWithFields,
  goToApplications,
  canViewInventory,
  canCreateCustomModules
}) => (
  <Div width={1} display="column.flex-start" height={1}>
    <HeaderTitle handlers={handlers} title="Form Wizard" />
    <Div
      bg="neutral0"
      display="column.flex-start.center"
      p={5}
      pt={0}
      style={{ overflowY: "auto" }}
      height={633}
    >
      <Div fs={6} fw={4} my={6} color="black">
        What type of form would you like to create?
      </Div>
      <Div width={1}>
        <FormItem
          Icon={BuildingIcon}
          title="Applications"
          description="Create & share application forms for possible participants in your event. From artists to vendors and media. Create as many as you need"
          select={goToApplications}
        />

        <FormItem
          title="Passes & Credentials"
          description="Collect requests for credentials, parking, or miscellaneous access passes via a simple request form"
          select={goToPasses}
          imgUrl="https://d2dks4tzxs6xee.cloudfront.net/img/passes-not-hovered.png"
          imgUrlHover="https://d2dks4tzxs6xee.cloudfront.net/img/passes-hovered.png"
        />
        <FormItem
          title="Meals & Catering"
          description="Collect catered meal requests via a simple request form"
          select={goToMeals}
          imgUrl="https://d2dks4tzxs6xee.cloudfront.net/img/meals-not-hovered.png"
          imgUrlHover="https://d2dks4tzxs6xee.cloudfront.net/img/meals-hovered.png"
        />
        {canViewInventory ? (
          <FormItem
            title="Assets & Equipment"
            description="Collect needs for Golf Carts, Radios, Heavy Equipment, even the smaller stuff printer and gaffer tape"
            select={goToNewInventory}
            imgUrl="https://d2dks4tzxs6xee.cloudfront.net/img/asset-not-hovered.png"
            imgUrlHover="https://d2dks4tzxs6xee.cloudfront.net/img/asset-hovered.png"
          />
        ) : (
          <FormItem
            title="Assets & Equipment"
            description="Collect needs for Golf Carts, Radios, Heavy Equipment, even the smaller stuff printer and gaffer tape"
            select={goToInventoryForm}
            imgUrl="https://d2dks4tzxs6xee.cloudfront.net/img/asset-not-hovered.png"
            imgUrlHover="https://d2dks4tzxs6xee.cloudfront.net/img/asset-hovered.png"
          />
        )}

        {canCreateCustomModules ? (
          <FormItem
            Icon={ListIcon}
            title="Custom Event Data"
            description="i.e: Transportation Details, Lodging, Flights, etc - No special powers - this form can collect custom data and save it to a custom module in your Lennd Event"
            select={getModulesWithFields}
          />
        ) : null}
      </Div>
    </Div>
  </Div>
);

export default decorate(SelectForm);
