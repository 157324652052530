import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import Loading from "ui-kit/Loading";
import Body from "./Body";
import { withRouter } from "react-router";
import * as R from "ramda";

const decorate = R.compose(
  withRouter,
  connect(
    state => ({
      loading: getters.loading(state)
    }),
    {
      init: actions.init
    }
  )
);

const Layout = ({ loading, init, moduleId }) => {
  useEffect(() => {
    init({
      moduleId
    });
  }, []);

  return <>{loading ? <Loading /> : <Body />}</>;
};

export default decorate(Layout);
