import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { CREDENTIAL_TYPE_ID, MEAL_TYPE_ID } from "utils/item-types";
import { noop, withProps } from "utils/General";

import { ToggleFeature } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

import { actions } from "Accounts/PeopleTypePanel";
import {
  getSelectedCountByType,
  getSelectedIdsByType,
  getSelectedIdsWithUserId,
  getPeople
} from "Accounts/PeopleTypePanel/selectors";
import { actions as SelectActions } from "ui-kit/SelectRows";

import {
  Div,
  Text2,
  MediumOutlineButton,
  ReplyIcon,
  MenuItem,
  DeleteIcon,
  EditIcon,
  KeyIcon,
  AddIcon,
  OpenInNewIcon
} from "components/Base";

const BulkMenuItem = withProps({
  width: 1
})(MenuItem);

import ActionsButton from "./ActionsButton";

const buttons = {
  credentials: ({ showAdd, closeMenu, handlers, selectedIds }) =>
    !showAdd ? null : (
      <BulkMenuItem
        onClick={() => {
          closeMenu();
          handlers.showAddItemsModal({
            contactIds: selectedIds,
            typeId: CREDENTIAL_TYPE_ID
          });
        }}
      >
        Credentials
      </BulkMenuItem>
    ),
  meals: ({ showAdd, closeMenu, handlers, selectedIds }) =>
    !showAdd ? null : (
      <BulkMenuItem
        onClick={() => {
          closeMenu();
          handlers.showAddItemsModal({
            contactIds: selectedIds,
            typeId: MEAL_TYPE_ID
          });
        }}
      >
        Meals
      </BulkMenuItem>
    ),
  changeRole: ({ closeMenu, handlers, typeId }) => (
    <BulkMenuItem
      LeftIcon={EditIcon}
      onClick={() => {
        closeMenu();
        handlers.changeRole(typeId);
      }}
    >
      Change Role
    </BulkMenuItem>
  ),
  giveLoginAccess: ({ closeMenu, handlers, typeId }) => (
    <BulkMenuItem
      LeftIcon={KeyIcon}
      onClick={() => {
        closeMenu();
        handlers.giveLoginAccess(typeId);
      }}
    >
      Give Login Access
    </BulkMenuItem>
  ),
  shareLoginDetails: ({ closeMenu, handlers, typeId }) => (
    <BulkMenuItem
      LeftIcon={EditIcon}
      onClick={() => {
        closeMenu();
        handlers.shareLoginDetails(typeId);
      }}
    >
      Share Login Details
    </BulkMenuItem>
  ),
  remove: ({ closeMenu, handlers, selectedIdsWithUserId, showAdd }) =>
    !showAdd ? null : (
      <BulkMenuItem
        LeftIcon={DeleteIcon}
        onClick={() => {
          closeMenu();
          handlers.showRemovePersonModal(selectedIdsWithUserId);
        }}
      >
        Remove
      </BulkMenuItem>
    ),
  add: ({ closeMenu, handlers, typeId }) => (
    <BulkMenuItem
      LeftIcon={AddIcon}
      onClick={() => {
        closeMenu();
        handlers.addPerson({ typeId });
      }}
    >
      Add Person
    </BulkMenuItem>
  )
};

const addButtons = ["credentials", "meals"];
const actionButtons = ["add"];
const selectedActionButtons = ["remove"];
const adminSelectedActionButtons = ["remove"];

const decorate = connect(
  (state, props) => ({
    selectedCount: getSelectedCountByType(state, props),
    selectedIds: getSelectedIdsByType(state, props),
    selectedIdsWithUserId: getSelectedIdsWithUserId(state, props),
    people: getPeople(state, props)
  }),
  bindInstance({
    clearSelected: SelectActions.clearRows,
    showTable: payload => actions.setShowTable(payload)
  })
);

const PanelInfo = ({
  selectedCount = 0,
  selectedIds = [],
  selectedIdsWithUserId = [],
  typeId = "",
  isAdminView = true,
  people,
  handlers,
  showAdd,
  showTable = noop,
  clearSelected = noop
}) => (
  <Div
    display="row.flex-start.center"
    p={2}
    bra={1}
    style={{ overflow: "visible" }}
    onClick={e => {
      e.preventDefault();
      e.stopPropagation();
    }}
  >
    {selectedCount > 0 && (
      <>
        <Text2 bold>{selectedCount} Selected</Text2>

        <MediumOutlineButton
          ml={3}
          onClick={clearSelected}
          LeftIcon={ReplyIcon}
        >
          Clear Selection
        </MediumOutlineButton>
        {showAdd && isAdminView && (
          <ActionsButton
            title="Add Items"
            ml={2}
            buttonStyle={{ bg: "primary7", color: "white" }}
            buttons={R.map(
              name =>
                React.createElement(buttons[name] || null, {
                  key: name,
                  typeId,
                  handlers,
                  selectedIds,
                  selectedIdsWithUserId,
                  showAdd
                }),
              addButtons
            )}
          />
        )}
      </>
    )}
    {selectedCount === 0 && (
      <ToggleFeature
        flag={flags.IS_LENND_ADMIN.NAME}
        untoggledComponent={() => null}
        toggledComponent={() => (
          <MediumOutlineButton
            ml={3}
            onClick={() => showTable(typeId)}
            RightIcon={OpenInNewIcon}
          >
            Bulk Edit
          </MediumOutlineButton>
        )}
      />
    )}
    {showAdd && (
      <ActionsButton
        title="Actions"
        ml={2}
        buttons={R.map(
          name =>
            React.createElement(buttons[name] || null, {
              key: name,
              typeId,
              handlers,
              people,
              selectedIds,
              selectedIdsWithUserId,
              showAdd
            }),
          selectedCount && isAdminView
            ? adminSelectedActionButtons
            : selectedCount
            ? selectedActionButtons
            : actionButtons
        )}
      />
    )}
  </Div>
);
export default decorate(PanelInfo);
