import * as R from "ramda";
import { createContext } from "redux-mvc";

import dashboard from "EventLight/Common/Dashboard";
import DashboardView, {
  contextConfig,
  getDashboardProps
} from "EventLight/Common/Dashboard/View";

const decorate = R.compose(
  getDashboardProps({
    // moduleId: STANDARD_MODULE_IDS.accounts.id,
    // emptyStateIcon: "group",
    // emptyStateTitle: "No groups have been added yet",
    // emptyStateDescription:
    //   "Manage the different types of groups that are involved with your event"
    // recordTypeId: null,
    // mode: "exhibitors"
  }),
  createContext({ module: dashboard, ...contextConfig })
);

export default decorate(DashboardView);
