import React from "react";
import { connect } from "react-redux";
import {
  Div,
  Text1,
  Text2,
  Text3,
  Text5,
  MediumOutlineInput,
  SmallToggle,
  AddIcon,
  CloseIcon
} from "components/Base";
import Wysiwyg from "components/Global/Wysiwyg";
import { getters, actions } from "../model";

const decorate = connect(
  state => ({
    welcomeTitle: getters.welcomeTitle(state),
    welcomeDescription: getters.welcomeDescription(state),
    showWelcomeDescription: getters.showWelcomeDescription(state),
    showTerms: getters.showTerms(state),
    terms: getters.terms(state)
  }),
  {
    setWelcomeTitle: actions.setWelcomeTitle,
    setWelcomeDescription: actions.setWelcomeDescription,
    toogleShowWelcomeDescription: actions.toogleShowWelcomeDescription,
    toogleShowTerms: actions.toogleShowTerms,
    setTerms: actions.setTerms
  }
);

const WelcomeScreen = ({
  welcomeTitle,
  setWelcomeTitle,
  setWelcomeDescription,
  welcomeDescription,
  toogleShowWelcomeDescription,
  showWelcomeDescription,
  showTerms,
  toogleShowTerms,
  terms,
  setTerms
}) => (
  <Div
    style={{
      overflow: "auto",
      position: "relative"
    }}
    height={1}
    width={1}
    bg="neutral0"
  >
    <Div
      py={5}
      px={8}
      style={{ position: "absolute", top: 0, left: 0 }}
      width={1}
    >
      <Text5 color="black" bold>
        Welcome Screen
      </Text5>
      <Text2>
        The first screen a user will see upon clicking the link you share.
      </Text2>
      <Div bra={1} bg="white" shadow={1} p={4} mt={4}>
        <Div display="row.flex-start.center">
          <Text3 bold mr={3}>
            Title
          </Text3>
          <Div flex={1}>
            <MediumOutlineInput
              width={1}
              value={welcomeTitle}
              onChange={setWelcomeTitle}
            />
          </Div>
        </Div>
        <Div mt={4} display="row.space-between.center" mb={2}>
          <Text3 bold>Welcome Message</Text3>
          <Div
            display="row.flex-start.center"
            onClick={toogleShowWelcomeDescription}
          >
            {showWelcomeDescription ? <CloseIcon /> : <AddIcon />}
            <Text1 ml={1} color={{ default: "neutral7", hover: "primary7" }}>
              {showWelcomeDescription ? "Remove" : "Add"} welcome message
            </Text1>
          </Div>
        </Div>
        {showWelcomeDescription ? (
          <Wysiwyg text={welcomeDescription} onChange={setWelcomeDescription} />
        ) : null}
      </Div>
      <Div bra={1} bg="white" shadow={1} p={4} mt={4}>
        <Div mt={4} display="row.space-between.center" mb={2}>
          <Text3 bold>Show Terms & Conditions</Text3>
          <Div display="row.flex-start.center">
            <Text2 mr={2} bold color={showTerms ? "altA7" : "neutral7"}>
              {showTerms ? "" : "Not "}Showing
            </Text2>
            <SmallToggle active={showTerms} onClick={toogleShowTerms} />
          </Div>
        </Div>
        {showTerms ? <Wysiwyg text={terms} onChange={setTerms} /> : null}
      </Div>
    </Div>
  </Div>
);

export default decorate(WelcomeScreen);
