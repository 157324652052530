import React from "react";
import { withRouter } from "react-router";
import * as R from "ramda";
import { connect } from "react-redux";
import { Div } from "components/Base";
import { getTotalCartItems } from "SalesPortal/ItemsModal/selectors";
import CommonToolbar from "Common/Toolbar";
import { getters } from "SalesPortal/Home";
import { getUsername, getIsLoggedIn } from "SalesPortal/Home/selectors";

const decorate = R.compose(
  withRouter,
  connect(state => ({
    total: getTotalCartItems(state),
    salesData: getters.salesData(state),
    username: getUsername(state),
    isLoggedIn: getIsLoggedIn(state)
  }))
);

const Toolbar = props => {
  const {
    params: { slug, eventId },
    salesData,
    router,
    username,
    isLoggedIn
  } = props;

  return (
    <CommonToolbar
      {...props}
      username={username}
      showCart={false}
      adminLink={
        isLoggedIn ? `/event-light/${salesData.event_id}/dashboard` : null
      }
      logoutLink={
        isLoggedIn ? `/logout?returnTo=/sales-portal/${slug}/${eventId}` : null
      }
      redirectLink={`/sales-portal/${slug}/${eventId}`}
    >
      <Div
        ml={5}
        display="row.flex-start.center"
        height={1}
        onClick={() => router.push(`/sales-portal/${slug}/${eventId}`)}
      >
        <img
          src={salesData.logo_image_url}
          style={{
            maxHeight: "38px",
            maxWidth: "100%"
          }}
        />
        <Div ml={4} fw={3} fs={3} color="gray7">
          {salesData.name}
        </Div>
      </Div>
    </CommonToolbar>
  );
};

export default decorate(Toolbar);
