import * as R from "ramda";
import { getters } from "./model";

import { createSelector } from "reselect";

const getTextValue = R.compose(
  R.propOr("", "text_value"),
  (key, data) => R.find(R.propEq("key", key), R.propOr([], "settings", data))
);

export const getPage = createSelector(
  getters.page,
  page => ({
    title: page.title,
    subtitle: getTextValue("page_subtitle", page),
    content: getTextValue("page_content", page)
  })
);
