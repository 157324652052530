import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE, STATUS } from "./constants";

const zeroIndexPage = R.compose(
  R.dec,
  R.pathOr(1, ["pagination", "page"])
);
const DEFAULT_PAGINATION = {
  pageSize: 25,
  pages: 0,
  page: 0
};
const DEFAULT_PREFERENCES = {
  visible_fields: [],
  field_order: {},
  field_widths: {},
  quick_filters: [],
  sort_by: [],
  filters: {}
};

const iniState = {
  loading: true,
  moduleId: "events",
  selectedTab: STATUS.UPCOMING,
  collapsed: false,
  preferences: DEFAULT_PREFERENCES,
  originalPreferences: DEFAULT_PREFERENCES,
  openedMenues: [],
  showNoResults: false,
  references: {},
  groupedByField: null,
  views: [],
  tabStats: Object.values(STATUS).reduce(
    (acc, val) => ({ ...acc, [val]: 0 }),
    {}
  ),
  pagination: DEFAULT_PAGINATION,
  rows: [],
  columns: [],
  rowsById: {},
  rowsParents: {},
  rootRowIds: [],
  expandedRowIds: [],
  toggledRows: []
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    cancelInstance: R.always(iniState),
    init: R.identity,
    fetchData: R.identity,
    fetchViews: R.identity,
    exportData: R.identity,
    groupBy: R.identity,
    updateViewFields: R.identity,
    insertField: R.identity,
    hideColumn: R.identity,
    showSubscribeModal: R.identity,
    showAddEventModal: R.identity,
    showDeleteEventsModal: R.identity,
    goToAllForms: R.identity,
    clearSelectedRows: R.identity,
    setInitialData: (state, { payload: { statusList, preferences } }) => ({
      statusList,
      preferences,
      originalPreferences: preferences
    }),
    receiveList: ({ tabStats }, { payload }) => ({
      rows: payload.records || [],
      rowsById: R.indexBy(R.prop("id"), payload.records),
      rootRowIds: R.compose(
        R.map(R.prop("id")),
        R.filter(R.propEq("parent", null))
      )(payload.records),
      columns: payload.fields || [],
      tabStats: {
        ...tabStats,
        ...(payload.counters || {})
      },
      pagination: {
        ...payload.pagination,
        page: zeroIndexPage(payload)
      },
      showNoResults: (payload.records || []).length === 0,
      groupedByField: R.propOr("", "grouped_by", payload)
    }),
    expandRow: ({ expandedRowIds }, { payload: rowId }) => ({
      expandedRowIds: R.contains(rowId, expandedRowIds)
        ? R.without([rowId], expandedRowIds)
        : R.concat([rowId], expandedRowIds)
    }),
    toggleRow: ({ toggledRows }, { payload: rowId }) => ({
      toggledRows: R.contains(rowId, toggledRows)
        ? R.without([rowId], toggledRows)
        : R.concat([rowId], toggledRows)
    }),
    updateSelectedRows: (
      { toggledRows },
      { payload: { rowIds, isSelected } }
    ) => ({
      toggledRows: isSelected
        ? R.concat(rowIds, toggledRows)
        : R.without(rowIds, toggledRows)
    }),
    updateParentRows: ({ toggledRows, rowsById }, { payload: rowId }) => {
      const rowData = rowsById[rowId];
      let newToggledRows = toggledRows;
      let parentId = R.propOr(null, "parent", rowData);
      while (parentId !== null) {
        const parentRowData = rowsById[parentId];
        const childrenIds = R.prop("children", parentRowData);
        if (
          R.length(R.intersection(newToggledRows, childrenIds)) ===
          R.length(childrenIds)
        ) {
          newToggledRows = R.concat([parentId], newToggledRows);
        } else {
          newToggledRows = R.without([parentId], newToggledRows);
        }
        parentId = R.prop("parent", parentRowData);
      }
      return {
        toggledRows: R.uniq(newToggledRows)
      };
    },
    untoggleAllRows: R.always({ toggledRows: [] }),
    addViewResponse: (state, { payload: newView }) => ({
      views: [...state.views, newView],
      preferences: newView,
      originalPreferences: newView
    }),
    saveViewChangesResponse: (state, { payload: newView }) => ({
      views: state.views.map(v => (v.id === newView.id ? newView : v)),
      preferences: newView,
      originalPreferences: newView
    }),
    revertViewChanges: state => ({
      preferences: state.originalPreferences
    }),
    selectActiveView: (state, { payload: activeView }) => ({
      preferences: activeView,
      originalPreferences: activeView,
      pagination: {
        ...state.pagination,
        page: 0
      }
    }),
    selectView: (state, { payload: view }) => {
      return {
        preferences: view,
        originalPreferences: view,
        pagination: {
          ...state.pagination,
          page: 0
        }
      };
    },
    setGroupedByField: ({ preferences }, { payload: groupedBy }) => ({
      preferences: {
        ...preferences,
        grouped_by:
          !R.isNil(groupedBy) && groupedBy === R.prop("grouped_by", preferences)
            ? null
            : groupedBy
      }
    }),
    setPageSize: (state, { payload }) => ({
      pagination: {
        ...state.pagination,
        pageSize: payload
      }
    }),
    setCurrentPage: ({ pagination }, { payload }) => ({
      pagination: {
        ...pagination,
        page: payload
      }
    }),
    setSelectedTab: (state, { payload: selectedTab }) => ({
      selectedTab
    }),
    toggleCollapse: ({ collapsed }) => ({ collapsed: !collapsed }),
    toggleMenu: ({ openedMenues }, { payload: menuId }) => ({
      openedMenues: R.contains(menuId, openedMenues)
        ? R.without([menuId], openedMenues)
        : R.concat([menuId], openedMenues)
    }),
    //
    setSelectedFieldFilters: (state, { payload: filters }) => ({
      preferences: {
        ...state.preferences,
        filters
      }
    }),
    removeSelectedFieldFilter: (state, { payload: idxOfFilter }) => {
      const updatedFilters = {
        filters: {
          ...state.preferences.filters.filters,
          filters: R.remove(idxOfFilter, 1)(
            state.preferences.filters.filters.filters
          )
        }
      };
      return {
        preferences: {
          ...state.preferences,
          filters: updatedFilters.filters.filters.length ? updatedFilters : {}
        }
      };
    },
    //
    setSelectedSortBy: (state, { payload: sortBy }) => ({
      preferences: {
        ...state.preferences,
        sort_by: [
          ...R.filter(f => f.fieldId !== sortBy.fieldId)(
            state.preferences.sort_by
          ),
          sortBy
        ]
      }
    }),
    removeSelectedSortBy: (state, { payload: idxOfFilter }) => {
      return {
        preferences: {
          ...state.preferences,
          sort_by: R.remove(idxOfFilter, 1)(state.preferences.sort_by)
        }
      };
    },
    //
    clearFilters: ({ preferences }) => {
      return {
        preferences: {
          ...preferences,
          sort_by: [],
          filters: {}
        }
      };
    },
    updateFieldWidths: ({ preferences }, { payload: fieldWidths }) => ({
      preferences: {
        ...preferences,
        field_widths: fieldWidths
      }
    }),
    updateVisibleFields: (
      { preferences },
      { payload: { visibleFields, fieldOrder } }
    ) => ({
      preferences: {
        ...preferences,
        visible_fields: visibleFields || preferences.visible_fields,
        field_order: fieldOrder || preferences.field_order
      }
    }),
    updateViewName: (
      { preferences, originalPreferences, views },
      { payload: { name } }
    ) => ({
      preferences: {
        ...preferences,
        name
      },
      originalPreferences: {
        ...originalPreferences,
        name
      },
      views: views.map(v => ({
        ...v,
        name: v.id === preferences.id ? name : v.name
      }))
    })
  }
});

export default handlers;
