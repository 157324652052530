import React, { useState } from "react";
import { Link } from "react-router";

import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";

import { Div, Text2, CheckCircleIcon } from "components/Base";

const decorate = WithThemeConsumer({
  variant: VARIANTS.BACKGROUND
});

const getColor = ({ selected, completed, hovered, Th }) => {
  if (selected) {
    return null;
  }
  if (hovered) {
    return Th.FEATURES.colorh;
  }
  if (completed) {
    return Th.FEATURES.bga;
  }
  return null;
};

const Step = ({
  to = "",
  selected = false,
  completed = false,
  name = "",
  id = "",
  Th = defaultTh,
  ...styleProps
}) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Link to={to} style={{ width: "100%" }} data-cy={id}>
      <Div
        {...Th(["bg"], {
          bra: 1,
          mb: 1,
          display: "row.flex-start.center",
          p: 2,
          style: { cursor: "pointer" },
          width: 1,
          active: selected,
          hover: true,
          ...styleProps
        })}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <CheckCircleIcon
          {...Th(["color"], {
            active: selected,
            color: getColor({ selected, completed, hovered, Th })
          })}
        />
        <Text2
          {...Th(["color"], {
            active: selected,
            bold: true,
            ml: 2,
            color: (hovered && Th.FEATURES.colorh) || null
          })}
        >
          {name}
        </Text2>
      </Div>
    </Link>
  );
};

export default decorate(Step);
