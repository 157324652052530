import React from "react";
import PropTypes from "prop-types";
import {
  Div,
  DownFilledIcon,
  PopoverMenu,
  SimpleTable,
  SmallAvatar,
  SmallShadedButton,
  Text2,
  Text4
} from "components/Base";

const EventName = ({ name, id }) => (
  <Div display="row.flex-start.center">
    <SmallAvatar square text={name} id={id} />
    <Text2 bold ml={2}>
      {name}
    </Text2>
  </Div>
);
const Type = ({ type }) => <Text2 bold>{type}</Text2>;

const Actions = ({ onRemove }) => (
  <PopoverMenu
    Label={({ onClick }) => (
      <SmallShadedButton onClick={onClick} pill RightIcon={DownFilledIcon}>
        Actions
      </SmallShadedButton>
    )}
    menuItems={[["Remove from event", onRemove]]}
  />
);

const Table = SimpleTable({
  columnProps: [[2 / 4, "left"], [1 / 4, "right"], [1 / 4, "right"]],
  headerCellComps: [() => <Text4 bold>Events</Text4>, "Type", "Actions"],
  rowCellComps: [EventName, Type, Actions]
});

const EventsTable = ({ events }) => {
  // if (!this.props.events.length) {
  //   return <div />;
  // }
  return (
    <Div p={5} ba={1} bc="neutral3" bra={1}>
      <Table rowsData={events} />
    </Div>
  );
};

EventsTable.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      onRemove: PropTypes.func,
      id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string
    })
  )
};

export default EventsTable;
