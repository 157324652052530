import React, { Component } from "react";
import getValue from "@lennd/value-types/src/get-value/primary-contacts";
import * as R from "ramda";
import { Div } from "components/Base";
import { isEqual } from "lodash";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { connect } from "react-redux";
import { PrimaryContactsModal } from "Passes/GuestLists/PrimaryContacts";
import EditWrapper from "components/Global/Table3/CellFormatters/EditWrapper";
import * as STANDARD_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";
import Tooltip from "components/Global/Tooltip";

const decorate = connect(
  null,
  {
    showModal,
    hideModal
  }
);

class PrimaryContactsFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  showPrimaryContactsModal = () => {
    const accountId = R.prop("id")(this.props.dependentValues.meta.row);
    const accountName = R.path([STANDARD_FIELD_IDS.ACCOUNTS.NAME, "value"])(
      this.props.dependentValues.meta.row
    );
    const recordTypeName = R.path(["type", "name"])(
      this.props.dependentValues.meta.row
    );
    const recordTypeColor = R.path(["type", "background_color"])(
      this.props.dependentValues.meta.row
    );
    const recordTypeIcon = R.path(["type", "icon"])(
      this.props.dependentValues.meta.row
    );

    this.props.showModal({
      content: (
        <PrimaryContactsModal
          groupId={accountId}
          groupName={accountName}
          recordTypeName={recordTypeName}
          recordTypeColor={recordTypeColor}
          recordTypeIcon={recordTypeIcon}
          hideModal={() => {
            this.props.hideModal();
            this.props.dependentValues.helpers.refreshRecords();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const contacts = getValue(this.props.value);
    if (!contacts.length) {
      return (
        <EditWrapper onClick={this.showPrimaryContactsModal}>
          <Div color="gray6" underline fs={3}>
            + Add Contact
          </Div>
        </EditWrapper>
      );
    }

    return (
      <EditWrapper onClick={this.showPrimaryContactsModal}>
        {contacts.length > 1 ? (
          <Tooltip tooltip={contacts.map(c => c.name).join(", ")}>
            <Div color="black" fs={2}>
              {contacts.length} contacts
            </Div>
          </Tooltip>
        ) : (
          <Div color="black" fs={2}>
            {contacts[0].name}
          </Div>
        )}
      </EditWrapper>
    );
  }
}

export default decorate(PrimaryContactsFormatter);
