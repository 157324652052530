import * as R from "ramda";

import { createSelector } from "reselect";

import { getters as FormGetters } from "ui-kit/Form/model";

import { getters } from "./model";

import { NAMESPACE, ADD_OPTIONS } from "./constants";

export const getSelectedItem = createSelector(
  FormGetters.fields,
  R.pathOr("", [NAMESPACE, "value", 0])
);

export const getIsSaveDisabled = createSelector(
  getSelectedItem,
  getters.addOption,
  getters.startingNumber,
  getters.numbers,
  getters.quantityToGenerate,
  (selectedItem, addOption, startingNumber, numbers, quantityToGenerate) => {
    if (selectedItem) {
      if (addOption === ADD_OPTIONS.GENERATE) {
        return !startingNumber || parseInt(quantityToGenerate) <= 0;
      }

      return R.isEmpty(numbers);
    }

    return true;
  }
);
