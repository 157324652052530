import React, { Component } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { actions, getters as ItemManageGetters } from "Items/Manage";

import { getOrderIds, getVariantIdsByBlockId } from "../selectors";

import { Div } from "components/Base";

import { contextConfig } from "Items/Manage/View";

import AllPassesView from "Items/Manage/View/AllPassesView";
import PaginationBar from "Items/Manage/View/PaginationBar";
import BulkEditLineItemsModal from "Orders/BulkEditLineItemsModal";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

import SendEmailModal from "SendEmailModal/View";
import IssuanceModal from "Passes/Checkin/IssuanceModal";
import ViewOrderModal from "Orders/OrderModal/View";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as SEND_TO_OPTION_TYPES from "SendEmailModal/utils/send-to-option-types";

import { MANAGE_BY, TABLE_VIEW_ID } from "Items/Manage/constants";
import { ALL_ITEM_TYPE_IDS } from "utils/item-types";

import { showModal, hideModal } from "redux/modules/modal/actions";

import LoadingOverlay from "ui-kit/LoadingOverlay";
import ShowNoResults from "ui-kit/ShowNoResults";

const decorate = connect(
  (state, props) => ({
    loading: ItemManageGetters.loading(state, props),
    showNoResults: ItemManageGetters.showNoResults(state, props),
    orderIds: getOrderIds(state, props),
    variantIds: !props.itemBlockId ? [] : getVariantIdsByBlockId(state, props)
  }),
  bindInstance({
    init: actions.init,
    initData: actions.initData,
    showModal,
    hideModal,
    fetchData: actions.fetchData,
    stats: actions.fetchStats
  })
);

class ManageView extends Component {
  constructor(props) {
    super();
    props.init({
      itemTypeView: ALL_ITEM_TYPE_IDS,
      view: MANAGE_BY.ALL_PASSES,
      orderIds: props.orderIds,
      variantIds: props.variantIds
    });

    this.handlers = Object.entries({
      ...contextConfig.handlers,

      viewOrder: (number, id) => {
        this.props.showModal({
          content: (
            <ViewOrderModal
              orderNumber={id ? undefined : number}
              orderId={id ? id : undefined}
              onDone={() => {
                this.props.fetchData();
              }}
            />
          ),
          wrapper: ModalWrapper
        });
      },

      issueItems: async ({
        recordId,
        variantId,
        orderType,
        customerAccountId,
        customerContactId,
        fulfillmentId,
        status,
        itemName,
        recordName,
        quantity
      }) => {
        const handleCheckin = actions => async (
          quantity,
          recordId,
          orderType,
          customerAccountId,
          customerContactId,
          variantId
        ) => {
          await this.props.checkin({
            actions,
            recordId,
            orderType,
            customerAccountId,
            customerContactId,
            variantId,
            options: { quantity }
          });
        };

        const handleRevertCheckin = async (
          quantity,
          recordId,
          orderType,
          customerAccountId,
          customerContactId,
          variantId,
          action,
          fulfillmentId
        ) => {
          await this.props.revertCheckin({
            variantId,
            recordId,
            orderType,
            customerAccountId,
            customerContactId,
            action,
            options: { quantity, fulfillmentId }
          });
        };

        const fulfillItems = handleCheckin(["fulfill"]);
        const issueItems = handleCheckin(["issue"]);
        const issueAndFulfill = handleCheckin(["issue", "fulfill"]);

        const undo = undoStatus => count =>
          handleRevertCheckin(
            count,
            recordId,
            orderType,
            customerAccountId,
            customerContactId,
            variantId,
            undoStatus,
            fulfillmentId
          );

        this.props.showModal({
          content: (
            <IssuanceModal
              status={status}
              onIssue={count =>
                issueItems(
                  count,
                  recordId,
                  orderType,
                  customerAccountId,
                  customerContactId,
                  variantId
                )
              }
              onFulfill={count =>
                fulfillItems(
                  count,
                  recordId,
                  orderType,
                  customerAccountId,
                  customerContactId,
                  variantId
                )
              }
              onIssueAndFulfill={count =>
                issueAndFulfill(
                  count,
                  recordId,
                  orderType,
                  customerAccountId,
                  customerContactId,
                  variantId
                )
              }
              onUndoIssuance={undo("issuance")}
              onUndoPickup={undo("fulfillment")}
              onUndoIssuanceAndPickup={undo("fulfillment-issuance")}
              itemName={itemName}
              recordName={recordName}
              totalAvailable={parseInt(quantity, 10)}
              //
              orderType={orderType}
              customerAccountId={customerAccountId}
              customerContactId={customerContactId}
              recordId={recordId}
              variantId={variantId}
              fulfillmentId={fulfillmentId}
              onDone={() => {
                this.props.fetchData();
              }}
            />
          ),
          wrapper: ModalWrapper
        });
      },

      refetchData: ({ stats } = { stats: false }) => {
        this.props.fetchData();
        if (stats) {
          this.props.stats();
        }
      },

      hideAndFetchData: ({ stats } = { stats: false }) => {
        this.props.hideModal();
        this.props.fetchData();
        if (stats) {
          this.props.stats();
        }
      },

      bulkEdit: ({ orderId, lineItemId }) => {
        const where = {};

        if (orderId) {
          where.orderId = orderId;
        } else if (lineItemId) {
          where.lineItemId = lineItemId;
        }

        this.props.showModal({
          content: (
            <BulkEditLineItemsModal
              where={where}
              onDone={() => {
                this.props.fetchData();
              }}
            />
          ),
          wrapper: ModalWrapper
        });
      },

      sendConfirmation: ({ orderId }) => {
        this.props.showModal({
          content: (
            <SendEmailModal
              emailType="confirmation"
              moduleId={STANDARD_MODULE_IDS.orders.id}
              selectedOptions={[SEND_TO_OPTION_TYPES.ORDER_CUSTOMER]}
              sourceOrderId={orderId}
              records={[orderId]}
              onDone={() => {
                this.props.fetchData();
              }}
            />
          ),
          wrapper: ModalWrapper
        });
      }
    }).reduce(
      (handlers, [key, f]) => ({
        ...handlers,
        [key]: f
      }),
      {}
    );
  }

  render() {
    const {
      loading = false,
      showNoResults = false,
      instanceId = ""
    } = this.props;

    return (
      <Div
        flex={1}
        bg="white"
        display="column"
        height={1}
        style={{ minHeight: 220, maxWidth: 785, overflowX: "hidden" }}
      >
        <Div
          style={{
            overflowY: loading ? "hidden" : "auto",
            position: "relative"
          }}
          flex={1}
          mt={2}
          px={4}
          py={2}
          className={TABLE_VIEW_ID}
        >
          {showNoResults ? (
            <ShowNoResults instanceId={instanceId} height={140} mt={0} />
          ) : (
            <AllPassesView
              m={0}
              handlers={this.handlers}
              instanceId={instanceId}
            />
          )}
          {loading && <LoadingOverlay />}
        </Div>
        <PaginationBar instanceId={instanceId} />
      </Div>
    );
  }
}

export default decorate(ManageView);
