import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "EventLight/Integrations/Fuzion";
import FuzionConnectModal from "EventLight/Integrations/FuzionConnectModal/View";

const decorate = connect(
  state => ({
    showConnectModal: getters.showConnectModal(state)
  }),
  {
    hideModal: () => actions.setShowConnectModal(false)
  }
);

const ConnectModal = ({ hideModal, showConnectModal }) =>
  showConnectModal ? <FuzionConnectModal hideModal={hideModal} /> : null;

export default decorate(ConnectModal);
