import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Panel from "components/Global-2016/Panel";
import ProgressChart from "../SummaryPanel/ProgressChart";
import CredentialLabel from "EventLight/Passes/Dashboard/View/SummaryPanel/CredentialLabel";

export const TableHeader = CSSModules(
  () => (
    <tr>
      <td styleName="header">Type</td>
      <td styleName="numberHeader">Requested</td>
      <td styleName="numberHeader">Approved</td>
      <td styleName="numberHeader">Budget</td>
      <td styleName="numberHeader">Remain.</td>
      <td styleName="numberHeader">Toggle</td>
      <td styleName="chartHeader">Progress</td>
    </tr>
  ),
  css
);

export const Row = CSSModules(
  () => (
    <tr styleName="row">
      <td styleName="emptyCredential">
        <CredentialLabel empty />
      </td>
      <td styleName="emptyNumber">—</td>
      <td styleName="emptyNumber">—</td>
      <td styleName="emptyNumber">—</td>
      <td styleName="emptyNumber">—</td>
      <td styleName="emptyNumber">—</td>
      <td styleName="chart">
        <ProgressChart empty />
      </td>
    </tr>
  ),
  css
);

export const Table = CSSModules(
  () => (
    <table styleName="emptyTable">
      <thead>
        <TableHeader />
      </thead>
      <tbody>
        <Row />
        <Row />
        <Row />
        <Row />
      </tbody>
    </table>
  ),
  css
);

@CSSModules(css)
class EmptySummaryPanel extends Component {
  render() {
    return (
      <Panel style={css.panel}>
        <div styleName="emptyTitle">Pass Summary</div>
        <Table />
        <div styleName="actionButton" onClick={this.props.addWidget}>
          <i className="material-icons" styleName="icon">
            add
          </i>
          Configure Summary
        </div>
      </Panel>
    );
  }
}

EmptySummaryPanel.propTypes = {
  addWidget: PropTypes.func.isRequired
};

export default EmptySummaryPanel;
