import React, { Fragment } from "react";
import * as R from "ramda";
import MediaQuery from "react-responsive";
import {
  Div,
  MoneyIcon,
  BigFilledButton,
  CloseIcon,
  LeftIcon
} from "components/Base";

const Header = ({ total, navigate, backUrlText, backUrlLink }) => (
  <Div
    height={[50, 50, 75]}
    display="column.center.center"
    width={1}
    bb={2}
    bc="neutral1"
    style={{ position: "relative" }}
  >
    <Div
      style={{ position: "absolute", left: 0 }}
      onClick={() => navigate(backUrlLink)}
      p={3}
      display="row.flex-start.center"
    >
      <LeftIcon size={24} />
      <Div fw={3}>{backUrlText}</Div>
    </Div>
    <Div display="row.flex-start.center">
      <MoneyIcon size={24} color="neutral4" />
      <Div fs={[4, 4, 6]} bold ml={2} color="black">
        Cart
      </Div>
      <Div
        bra={1}
        width={35}
        height={29}
        display="row.center.center"
        bg="altB0"
        color="altB4"
        ml={2}
        fw={4}
        fs={4}
      >
        {total}
      </Div>
    </Div>
  </Div>
);

const ItemsList = ({
  orderedItems,
  updateItemCartQuantity,
  totalAmount,
  navigate,
  checkoutUrlLink,
  total,
  currency
}) => (
  <Div width={1} p={[4, 4, 6]} display="column.flex-start.center">
    <Div fs={[4, 4, 6]} fw={3} color="black" mb={3}>
      Request Overview
    </Div>
    <Div width={1} display="row.center">
      <Div display="column" width={[1, 1, 621]}>
        {R.map(
          group => (
            <Div display="column.center" width={1} key={group.id}>
              <Div fs={3} fw={3} color="black" my={3} textAlign="center">
                {group.name}
              </Div>
              <Div width={558} display="row.flex-end.center">
                <Div fs={0} fw={2} color="black">
                  SUBTOTAL
                </Div>
              </Div>
              {R.map(
                item => (
                  <Div display="row" width={1} key={item.id} mt={2}>
                    <Div width={40}>{`${item.quantity} x`}</Div>

                    <Div width={518} display="column">
                      <Div
                        display="row.space-between.center"
                        style={{ borderBottom: "1px dotted #cecece" }}
                        pb={2}
                      >
                        <Div fw={3} fs={4} color="black">
                          {item.name}
                        </Div>
                        <Div fs={1} fw={2} color="black">
                          {`$${item.amount}`}
                        </Div>
                      </Div>
                    </Div>
                    <Div display="row" ml={3}>
                      <Div
                        width={22}
                        height={22}
                        bg={{ default: "neutral1", hover: "neutral2" }}
                        transition="fast"
                        display="row.center.center"
                        bra={1}
                        style={{ cursor: "pointer" }}
                        ml={1}
                      >
                        <CloseIcon
                          color="black"
                          onClick={() => {
                            updateItemCartQuantity({
                              itemId: item.id,
                              groupId: group.id,
                              quantity: 0
                            });
                          }}
                        />
                      </Div>
                    </Div>
                  </Div>
                ),
                group.items
              )}
            </Div>
          ),
          orderedItems
        )}
        <Div display="row" width={1} my={6}>
          <Div width={40} />
          <Div width={[1, 1, 518]} display="row.space-between.center">
            <Div>
              <Div fs={3} fw={2} color="black">
                Subtotal ({currency})
              </Div>
              <Div fs={1} color="neutral6">
                excluding taxes & fulfillment fees
              </Div>
            </Div>
            <Div color="altB8" fw={4} fs={4}>
              {`$${totalAmount}`}
            </Div>
          </Div>
        </Div>
        <Div display="row">
          <Div width={[0, 0, 40]} height={10} />
          <BigFilledButton
            bg="warning2"
            width={[1, 1, 518]}
            bra={1}
            color="black"
            onClick={() => {
              if (R.gt(total, 0)) {
                navigate(checkoutUrlLink);
              }
            }}
          >
            Proceed to Checkout
          </BigFilledButton>
        </Div>
      </Div>
    </Div>
  </Div>
);

const Footer = () => (
  <Div height={82} width={1} display="row.center.center" bt={2} bc="neutral1">
    <img
      src="https://d2dks4tzxs6xee.cloudfront.net/img/lennd-footer-logo.png"
      width={140}
    />
  </Div>
);

const Content = ({
  orderedItems,
  total,
  updateItemCartQuantity,
  totalAmount,
  router,
  backUrlText,
  backUrlLink,
  checkoutUrlLink,
  currency
}) => (
  <Fragment>
    <Header
      total={total}
      navigate={router.push}
      backUrlLink={backUrlLink}
      backUrlText={backUrlText}
    />
    <ItemsList
      orderedItems={orderedItems}
      updateItemCartQuantity={updateItemCartQuantity}
      totalAmount={totalAmount}
      navigate={router.push}
      checkoutUrlLink={checkoutUrlLink}
      total={total}
      currency={currency}
    />
    <Footer />
  </Fragment>
);

const Cart = props => {
  return (
    <Fragment>
      <MediaQuery minWidth={1024} maxWidth={1039}>
        <Div
          width={1024}
          bg="white"
          display="column"
          bra={1}
          mb={3}
          style={{ flexShrink: 0 }}
        >
          <Content {...props} />
        </Div>
      </MediaQuery>
      <MediaQuery minWidth={1040}>
        <Div
          width={1024}
          bg="white"
          display="column"
          bra={1}
          mb={3}
          style={{
            flexShrink: 0,
            marginTop: "125px",
            boxShadow:
              "0px 7.43943px 17.3587px rgba(0, 0, 0, 0.0423), 0px 32.2375px 49.5962px rgba(0, 0, 0, 0.01)"
          }}
        >
          <Content {...props} />
        </Div>
      </MediaQuery>
      <MediaQuery maxWidth={1023}>
        <Div
          width={1}
          height={1}
          bg="white"
          display="column"
          bra={0}
          mb={3}
          style={{ flexShrink: 0 }}
        >
          <Content {...props} />
        </Div>
      </MediaQuery>
    </Fragment>
  );
};

export default Cart;
