// @TODO: recreate with components form miniItemsSelector

import React, { Component } from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { bindInstance } from "redux-mvc";

import { actions } from "ui-kit/SelectFields";
import { VARIANTS } from "ui-kit/Theme/constants";

import {
  getSelectedFields,
  getRemovedFieldIds,
  getFields
} from "ui-kit/SelectFields/selectors";
import { getters } from "ui-kit/SelectFields";

import { defaultInstanceId } from "redux-mvc";

import {
  Div,
  Text3,
  Text2,
  BigFilledButton,
  BigOutlineButton,
  CheckIcon,
  CloseIcon
} from "components/Base";
import CheckBox from "material-ui/Checkbox";

import SearchBar from "ui-kit/SearchBar/View";
import AllNone from "./AllNone";

import { noop } from "utils/General";

const decorate = connect(
  (state, props) => ({
    selectedFields: getSelectedFields(state, props),
    fields: getFields(state, props),
    selectedFieldIds: getters.selectedFields(state, props),
    removedFieldIds: getRemovedFieldIds(state)
  }),
  bindInstance({
    toggleField: actions.toggleField,
    disableField: actions.disableField,
    enableAllFields: actions.enableAllFields,
    disableAllFields: actions.disableAllFields,
    init: actions.init
  })
);

const fieldProps = {
  display: "row.flex-start.center",
  style: { flexShrink: 0, minHeight: 32 },
  bra: 1,
  mt: 1,
  bc: "neutral2",
  px: 2
};

const Field = ({ onClick = noop, children }) => (
  <Div {...fieldProps} onClick={onClick}>
    {children}
  </Div>
);

class SelectFields extends Component {
  componentDidMount() {
    if (this.props.iniSelected && this.iniSelected !== this.props.iniSelected) {
      this.props.init(this.props.iniSelected);
      this.iniSelected = this.props.iniSelected;
    }
  }
  render() {
    const {
      fields = [],
      selectedFieldIds = [],
      selectedFields = [],
      onCancel = noop,
      onDone = noop,
      toggleField = noop,
      disableField = noop,
      instanceId
    } = this.props;

    return (
      <Div
        display="column.flex-start.stretch"
        bg="white"
        pt={6}
        flex={1}
        style={{ overflowY: "hidden" }}
      >
        <Div mb={2} px={6}>
          <AllNone fields={fields} instanceId={instanceId} />
        </Div>
        <Div
          display="row.space-between"
          flex={1}
          style={{ overflowY: "hidden" }}
        >
          <Div
            flex={1}
            bg="white"
            mr={3}
            display="column.flex-start.stretch"
            pl={6}
          >
            <SearchBar
              ba={0}
              bra={1}
              variant={VARIANTS.SURFACE}
              style={{ flexShrink: 0 }}
              mb={2}
              instanceId={instanceId || defaultInstanceId}
            />
            <Div
              display="column.flex-start.stretch"
              style={{ overflowY: "auto" }}
              flex={1}
              pb={4}
            >
              {R.map(
                field =>
                  field.required ? (
                    <Field key={field.id}>
                      <CheckIcon color="primary9" size={20} ml={1} />
                      <Text2 bold ml={4}>
                        {field.name}
                      </Text2>
                    </Field>
                  ) : (
                    <Field key={field.id} onClick={() => toggleField(field.id)}>
                      <CheckBox
                        style={{ width: "auto" }}
                        checked={R.contains(field.id, selectedFieldIds)}
                      />
                      <Text2 bold>{field.name}</Text2>
                    </Field>
                  ),
                fields
              )}
            </Div>
          </Div>

          <Div
            width={330}
            bg="neutral0"
            bra={1}
            p={4}
            mr={6}
            style={{ overflowY: "auto" }}
          >
            <Text3 bold mb={1}>
              Selected
            </Text3>
            {selectedFields.length ? (
              R.map(
                field => (
                  <Div
                    shadow={1}
                    mt={1}
                    bg={
                      field.required
                        ? "white"
                        : { default: "white", hover: "gray1" }
                    }
                    color="black"
                    display="row.space-between.center"
                    onClick={
                      field.required ? noop : () => disableField(field.id)
                    }
                    bra={1}
                    px={3}
                    key={field.id}
                    style={{
                      minHeight: "33px"
                    }}
                  >
                    <Text2 bold>{field.name}</Text2>
                    {field.required ? null : (
                      <CloseIcon
                        color={{ default: "neutral6", hover: "danger8" }}
                      />
                    )}
                  </Div>
                ),
                selectedFields
              )
            ) : (
              <Text2 color="gray5" mb={1}>
                You haven't selected any fields to make availabe on this import
                yet.
              </Text2>
            )}
          </Div>
        </Div>
        <Div
          bc="neutral2"
          bt={1}
          py={2}
          px={6}
          display="row.flex-start"
          width={1}
        >
          <BigFilledButton
            bg="altB5"
            width={140}
            onClick={() => onDone(selectedFieldIds)}
          >
            Done
          </BigFilledButton>
          <BigOutlineButton ml={2} onClick={() => onCancel()}>
            Cancel
          </BigOutlineButton>
        </Div>
      </Div>
    );
  }
}

export default decorate(SelectFields);
