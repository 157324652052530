import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const LayoutHeader = ({ children }) => (
  <div styleName="container">{children}</div>
);

LayoutHeader.propTypes = {
  children: PropTypes.node.isRequired
};

export default CSSModules(LayoutHeader, css);
