import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import {
  getOrderId,
  getConfirmation,
  getCanSendConfirmation
} from "Orders/OrderModal/selectors";

import { Div, Text1, Text4, SendIcon, BigOutlineButton } from "components/Base";

import { StatusTag } from "utils/status-comps";

import Panel from "ui-kit/Panel";

const decorate = connect(state => ({
  orderId: getOrderId(state),
  confirmation: getConfirmation(state),
  canSendConfirmation: getCanSendConfirmation(state)
}));

const EmailConfirmation = ({
  handlers,
  orderId,
  confirmation,
  canSendConfirmation
}) => {
  return (
    <Panel
      display="row.space-between.center"
      px={8}
      py={3}
      bg={!canSendConfirmation ? "neutral0" : "white"}
    >
      <Div display="row.flex-start.center">
        <SendIcon size={26} mr={2} />
        <Text4 bold color={!canSendConfirmation ? "neutral5" : "gray7"}>
          Confirmation Email:
        </Text4>
        <StatusTag
          status={confirmation.lastSent ? "sent" : "not_sent"}
          ml={2}
        />
      </Div>
      <Div display="row.flex-end.center">
        <Div display="column.flex-start.flex-end">
          {confirmation.lastSent && (
            <Text1>
              Last sent {moment(new Date(confirmation.lastSent)).fromNow()}{" "}
              {confirmation.by && confirmation.by.length ? "by " : ""}
              {confirmation.by}
            </Text1>
          )}
        </Div>
        <BigOutlineButton
          ml={2}
          onClick={() => handlers.send(orderId)}
          disabled={!canSendConfirmation}
        >
          {confirmation.lastSent ? "Resend" : "Send"}
        </BigOutlineButton>
      </Div>
    </Panel>
  );
};

export default decorate(EmailConfirmation);
