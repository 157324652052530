import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect, Provider } from "react-redux";
import { withRouter } from "react-router";
import * as R from "ramda";

import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";
import { getItemGroupsByEventAndType } from "redux/modules/items/item-groups/actions";
import { checkin, revertCheckin } from "redux/modules/passesCheckin/actions";

import { recordTypes } from "redux/modules/modules/recordTypes/selectors";

import {
  CREDENTIAL_TYPE_ID,
  BOOTH_TYPE_ID,
  INVENTORY_TYPE_ID
} from "utils/item-types";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import confirmations, { actions, getters } from "Passes/Confirmations";
import doubleSelect from "ui-kit/DoubleSelect";
import filtersPanel from "ui-kit/FiltersPanel";
import searchBar from "ui-kit/SearchBar";
import miniItemsSelector from "ui-kit/MiniItemsSelector";

import { showModal, hideModal } from "redux/modules/modal/actions";

import { getForms } from "redux/modules/formsV2/forms/actions";

import { getUsers as fetchUsers } from "redux/modules/eventUsers/actions";

import Layout from "./Layout";

import confirmationsSagas from "Passes/Confirmations/sagas";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import SendEmailModal from "SendEmailModal/View";
import ViewOrderModal from "Orders/OrderModal/View";

import * as SEND_TO_OPTION_TYPES from "SendEmailModal/utils/send-to-option-types";

confirmations.setRootSaga(confirmationsSagas);

const decorate = R.compose(
  connect(
    state => ({
      attendeeTypes: recordTypes(state, STANDARD_MODULE_IDS.contacts.id),
      groupTypes: recordTypes(state, STANDARD_MODULE_IDS.accounts.id)
    }),
    {
      getRecordTypes,
      getItemGroupsByEventAndType,
      checkin,
      revertCheckin,
      showModal,
      hideModal,
      getForms,
      fetchUsers
    }
  ),
  withRouter
);

confirmations.plugModule(doubleSelect);
confirmations.plugModule(filtersPanel);
confirmations.plugModule(searchBar);
confirmations.plugModule(miniItemsSelector);

class Confirmations extends Component {
  static contextTypes = {
    store: PropTypes.shape({
      subscribe: PropTypes.func.isRequired,
      dispatch: PropTypes.func.isRequired,
      getState: PropTypes.func.isRequired
    })
  };

  constructor(props, context) {
    super(props);

    const { store } = confirmations.createStore({
      globalStore: context.store,
      observedDomains: [
        "user",
        "event",
        "permissions",
        "formsV2",
        "eventUsers",
        "items",
        "routing"
      ],
      dispatchToGlobal: R.compose(
        R.not,
        R.test(new RegExp(R.join("|", confirmations.modules))),
        R.prop("type")
      )
    });
    this.store = store;
    confirmations.run();
  }

  componentDidMount() {
    const {
      route: { name: routeName }
    } = this.props;

    const itemTypeId =
      routeName === "BoothsConfirmation"
        ? BOOTH_TYPE_ID
        : routeName === "InventoryConfirmation"
        ? INVENTORY_TYPE_ID
        : CREDENTIAL_TYPE_ID;

    this.store.dispatch(actions.setItemTypeIds([itemTypeId]));
    this.props.getRecordTypes({
      moduleId: STANDARD_MODULE_IDS.accounts.id,
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId
      }
    });
    this.props.getRecordTypes({
      moduleId: STANDARD_MODULE_IDS.contacts.id,
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId
      }
    });
    this.props.getItemGroupsByEventAndType(
      this.props.params.eventId,
      itemTypeId
    );
    this.props.getForms(this.props.params.eventId);
    this.props.fetchUsers(this.props.params.eventId);
  }

  componentWillUnmount() {
    confirmations.cancel();
  }

  refetchData = () => {
    this.store.dispatch(actions.selectAll());
    this.store.dispatch(actions.fetchData());
  };

  showSendConfirmationEmail = (emailType = "confirmation") => {
    const orders = getters.selectedRows(this.store.getState());
    this.props.showModal({
      content: (
        <SendEmailModal
          emailType={emailType}
          moduleId={STANDARD_MODULE_IDS.orders.id}
          selectedOptions={[SEND_TO_OPTION_TYPES.ORDER_CUSTOMER]}
          records={orders}
          onDone={() => {
            this.refetchData({ stats: false });
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showViewOrderModal = (number, id) => {
    this.props.showModal({
      content: (
        <ViewOrderModal
          orderNumber={id ? undefined : number}
          orderId={id ? id : undefined}
          onDone={this.refetchData}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const { attendeeTypes, groupTypes } = this.props;
    const handlers = {
      sendEmail: this.showSendConfirmationEmail,
      sendInvoice: () => this.showSendConfirmationEmail("invoice"),
      viewOrder: this.showViewOrderModal
    };

    return (
      <Provider store={this.store}>
        <Layout
          {...{
            attendeeTypes,
            groupTypes,
            handlers
          }}
        />
      </Provider>
    );
  }
}

export default decorate(Confirmations);
