import PropTypes from "prop-types";
import React from "react";
import CanUserDo from "components/Global/Security/CanUserDo";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import Base from "../Base";

class DataTableActionsBar extends Base {
  render() {
    const { selectedRows, module, deselectAllRows } = this.props;
    const selectedRowsCount = selectedRows.length;

    return (
      <div styleName="container">
        <div styleName="count">{selectedRowsCount} selected</div>
        <CanUserDo
          action={`${module.id}_delete`}
          className={css.optionsWrapper}
        >
          <div
            role="button"
            styleName="menuItem"
            onClick={this.showDeleteRecordsModal}
          >
            <i className="material-icons">delete</i>
            Delete
          </div>
        </CanUserDo>
        <div role="button" styleName="menuTextButton" onClick={deselectAllRows}>
          Clear selected
        </div>
      </div>
    );
  }
}

DataTableActionsBar.propTypes = {
  deselectAllRows: PropTypes.func.isRequired,
  module: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default CSSModules(DataTableActionsBar, css);
