import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { getIsSaveDisabled } from "../selectors";
import { BigFilledButton, BigOutlineButton } from "components/Base";
import { actions } from "../model";

const decorate = R.compose(
  connect(
    state => ({
      isSaveDisabled: getIsSaveDisabled(state)
    }),
    {
      addBarcodes: actions.addBarcodes
    }
  ),
  CSSModules(css)
);

const Footer = ({ isSaveDisabled, hideModal, addBarcodes, onSave }) => (
  <div styleName="footer">
    <BigFilledButton
      disabled={isSaveDisabled}
      bg="altB5"
      mr={2}
      onClick={() => addBarcodes(onSave)}
    >
      Save
    </BigFilledButton>
    <BigOutlineButton onClick={hideModal}>Cancel</BigOutlineButton>
  </div>
);

export default decorate(Footer);
