import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Overview from "./Overview";
import can from "components/Global/Security";

import {
  createReview,
  createReviews,
  removeReview,
  removeReviews
} from "redux/modules/approvalWorkflows/reviews/actions";
import { getNeedsOverviewReport } from "redux/modules/inventory/reports/needs-overview/actions";
import { showModal } from "redux/modules/modal/actions";

import {
  needsOverviewReport,
  isFetching
} from "redux/modules/inventory/reports/needs-overview/selectors";
import { userPermissionProfile } from "redux/modules/permissions/user-permission-profile/selectors";

function mapStateToProps(state, props) {
  return {
    modules: needsOverviewReport(state, props.params.eventId, "all").filter(m =>
      can(
        userPermissionProfile(
          state,
          props.params.eventId || props.params.orgId,
          state.user.user.id
        )
      ).do(`${m.module_id}_read`)
    ),
    isFetching: isFetching(state, props.params.eventId, "all")
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createReview,
      createReviews,
      removeReview,
      removeReviews,
      getNeedsOverviewReport,
      showModal
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
