import { combineReducers } from "redux";
import { ERROR } from "redux/modules/errors/constants";
import {
  REQUEST,
  RECEIVE,
  RECEIVE_EVENT_HOME_ACCOUNT_RECORD_TYPES,
  GET_ERROR
} from "./constants";

const event = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload;
    default:
      return state;
  }
};

const eventHomeAccountRecordTypes = (
  state = {
    record_types: []
  },
  action
) => {
  switch (action.type) {
    case RECEIVE_EVENT_HOME_ACCOUNT_RECORD_TYPES:
      return action.payload;
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case ERROR:
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

const eventError = (state = false, action) => {
  switch (action.type) {
    case GET_ERROR:
      return true;
    default:
      return state;
  }
};

export default combineReducers({
  event,
  eventHomeAccountRecordTypes,
  fetching,
  eventError
});
