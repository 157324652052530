import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";
import Loading from "ui-kit/Loading";
import { WIDTH } from "../constants";

import MiniModalWrapper from "ui-kit/MiniModalWrapper";

const decorate = connect(
  (state, props) => ({
    loading: getters.loading(state, props),
    isOpen: getters.isOpen(state, props)
  }),
  {
    hideModal: actions.closeModal
  }
);

const Layout = ({ loading, isOpen, hideModal = () => {} }) => (
  <MiniModalWrapper
    hideModal={hideModal}
    isPortal={false}
    showModal={isOpen}
    showHeader={false}
    style={{
      width: WIDTH,
      backgroundColor: "white",
      height: "calc(100vh - 120px)"
    }}
    bg="neutral0"
  >
    <Header />
    {loading ? (
      <Loading />
    ) : (
      <>
        <Body />
        <Footer />
      </>
    )}
  </MiniModalWrapper>
);

export default decorate(Layout);
