import * as R from "ramda";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import Tooltip from "components/Global/Tooltip";
import BulkEditLineItemsModal from "Orders/BulkEditLineItemsModal";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { parseComboId } from "utils/General";

import { showModal, hideModal } from "redux/modules/modal/actions";

import { noop } from "utils/General";
import { getValue, getSettings } from "../../utils";
import { actions } from "../../model";

import styleSpects from "components/Base/Base/styleSpecs";

const joinItems = R.compose(
  R.join(", "),
  R.take(5),
  R.map(i => `(${i.count}) ${i.name}`.trim())
);

const decorate = connect(
  null,
  bindInstance({
    showModal,
    hideModal,
    onDone: actions.updateData
  })
);

class ItemGroup extends Component {
  showBulkEditModal = () => {
    const moduleId = getSettings(this.props, "moduleId");
    const eventId = getSettings(this.props, "eventId");

    this.props.showModal({
      content: (
        <BulkEditLineItemsModal
          where={
            moduleId === STANDARD_MODULE_IDS.accounts.id
              ? {
                  eventId,
                  orderAccountId: parseComboId(this.props.data.id)[0],
                  orderType: "group"
                }
              : {
                  eventId,
                  orderContactId: parseComboId(this.props.data.id)[0],
                  orderType: "individual"
                }
          }
          onDone={this.props.onDone}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const items = getValue(this.props, "items", []);
    const count = getValue(this.props, "count", 0);
    const labelPlural = getSettings(this.props, "labelPlural");
    const label = getSettings(this.props, "label");
    const isFieldReadOnly = getSettings(this.props, "readOnly", false);

    const joined = joinItems(items);
    const tooltip =
      R.length(items) > 5
        ? `${joined} and ${R.length(items) - 5} other ${
            R.length(items) - 5 !== 1 ? labelPlural : label
          }`
        : joined;

    return R.length(items) === 1 ? (
      <div
        onClick={isFieldReadOnly ? noop : this.showBulkEditModal}
        className="underline-on-hover"
        title={tooltip}
        style={{
          fontSize: styleSpects.fontSize[2],
          width: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}
      >
        {tooltip}
      </div>
    ) : count ? (
      <Tooltip tooltip={tooltip} placement="bottom">
        <div
          onClick={isFieldReadOnly ? noop : this.showBulkEditModal}
          className={isFieldReadOnly ? "" : "underline-on-hover"}
          style={{ fontSize: styleSpects.fontSize[2] }}
        >
          {count} {count !== 1 ? labelPlural : label}
        </div>
      </Tooltip>
    ) : (
      isFieldReadOnly || (
        <div
          onClick={this.showBulkEditModal}
          className="underline-on-hover"
          style={{
            color: styleSpects.color.purple8,
            fontWeight: styleSpects.fontWeight[3],
            fontSize: styleSpects.fontSize[3],
            cursor: "pointer"
          }}
        >
          + Request
        </div>
      )
    );
  }
}

export default decorate(ItemGroup);
