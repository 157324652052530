import React from "react";
import { Div } from "components/Base";
import Stepper from "./Stepper";

const Header = () => (
  <Div
    bg="white"
    width={1}
    bb={1}
    bc="neutral2"
    display="column.space-between"
    style={{
      position: "relative",
      paddingTop: 28,
      paddingBottom: 28,
      paddingLeft: 40
    }}
  >
    <Stepper />
  </Div>
);

export default Header;
