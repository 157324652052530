import { connect } from "react-redux";

import { getTabs, getSubtabs } from "./selectors";

import Wrapper from "EventLight/Common/PageWrapper/View";

const titles = ["Emails"];

const decorate = connect((state, props) => ({
  tabs: getTabs(state, props),
  subtabs: getSubtabs(state, props),
  titles,
  style: { overflow: "auto" }
}));

export default decorate(Wrapper);
