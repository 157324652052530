import PropTypes from "prop-types";
import { Component, cloneElement } from "react";

class RowSizer extends Component {
  render() {
    const { rows, maxHeight, children } = this.props;
    const tableHeight = rows.length * 35 + 50;
    const height = tableHeight > maxHeight ? maxHeight : tableHeight;
    return cloneElement(children, {
      height,
      minHeight: height
    });
  }
}

RowSizer.propTypes = {
  rows: PropTypes.array.isRequired,
  maxHeight: PropTypes.number,
  children: PropTypes.node.isRequired
};

RowSizer.defaultProps = {
  rows: [],
  maxHeight: 375
};

export default RowSizer;
