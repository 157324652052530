import React, { Component } from "react";
import PermissionSelect from "components/Global/Inputs/PermissionSelect";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class OrganizationUserModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      orgPermissionsRole: props.record.permission_role,
      orgRole: props.record.role ? props.record.role : ""
    };
  }

  _handleChange = event => {
    this.setState({ message: event.target.value });
  };

  onSave = async () => {
    const data = {
      orgRole: this.state.orgRole
    };

    if (this.props.showPermissions) {
      data.orgPermissionsRole = this.state.orgPermissionsRole;
    }

    await this.props.updateUser({
      orgId: this.props.orgId,
      userId: this.props.record.user_id,
      ...data
    });

    this.props.onUpdated();

    // If the current user is updating their own permissions, refresh so it's accurate
    if (this.props.record.user_id === this.props.currentUser.id) {
      this.props.getUserAccessLevels();
    }

    this.props.hideModal();
  };

  onOrgRoleChange = event => {
    this.setState({ orgRole: event.target.value });
  };

  onPermissionsChange = permission => {
    this.setState({ orgPermissionsRole: permission });
  };

  render() {
    return (
      <div>
        <div className="modal-header">
          <div className="modal-title">
            {this.props.record.user_fname} {this.props.record.user_lname}
          </div>
          <div className="modal-description modal-description-big">
            {this.props.orgName}
          </div>
        </div>

        <div className="modal-body">
          <div className="modal-body-wrapper">
            <div className="input-wrapper">
              <label>
                <span className={css.label}>
                  What's the user's role in this organization?
                </span>
              </label>

              <input
                className="styled"
                type="text"
                name=""
                value={this.state.orgRole}
                placeholder="ex: Event Director"
                onChange={this.onOrgRoleChange}
              />
            </div>

            {this.props.showPermissions ? (
              <div className="input-wrapper">
                <label>
                  <span className={css.label}>Change Permissions</span>
                  <PermissionSelect
                    value={this.state.orgPermissionsRole}
                    onSelect={this.onPermissionsChange}
                  />
                </label>
              </div>
            ) : null}

            <div className="actions-wrapper">
              <a className="action-positive small" onClick={this.onSave}>
                Save
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OrganizationUserModal;
