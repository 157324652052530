import React, { Component } from "react";
import { isEqual } from "lodash";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { connect } from "react-redux";
import EditWrapper from "components/Global/Table3/CellFormatters/EditWrapper";
import StatusCircle from "ui-kit/StatusCircle";
import DocumentRequestModal from "Portal/PortalDocuments/RequestModal";
import Tooltip from "components/Global/Tooltip";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import AssignDocumentRequestsModal from "components/Global/CRM/Modals/Assign/AssignDocumentRequests";

const decorate = connect(
  null,
  {
    showModal,
    hideModal
  }
);

class DocumentRequestFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  showDocRequestModal = () => {
    this.props.showModal({
      content: (
        <DocumentRequestModal
          view="admin"
          recordId={this.props.value.value.recordId}
          hideModal={() => {
            this.props.hideModal();
            this.props.dependentValues.helpers.refreshRecords();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showAssignDocRequestModal = () => {
    this.props.showModal({
      content: (
        <AssignDocumentRequestsModal
          moduleId={STANDARD_MODULE_IDS.accounts.id}
          recordNameSingular="Group"
          recordNamePlural="Groups"
          documentRequestIds={[this.props.dependentValues.meta.columnId]}
          recordIds={[this.props.dependentValues.meta.rowId]}
          onDone={() => {
            this.props.hideModal();
            this.props.dependentValues.helpers.refreshRecords();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    let status =
      this.props.value && this.props.value.value
        ? this.props.value.value.status
        : null;
    let tooltip;
    if (status === "not-submitted") {
      status = "not-submitted";
      tooltip = "Not Submitted";
    } else if (status === "pending") {
      status = "pending";
      tooltip = "Pending Approval";
    } else if (status === "approved") {
      status = "approved";
      tooltip = "Approved";
    } else if (status === "rejected") {
      status = "rejected";
      tooltip = "Rejected";
    } else {
      status = "not-assigned";
      tooltip = "Not Assigned";
    }

    return (
      <EditWrapper
        display="row.center.center"
        onClick={
          ["pending", "approved", "rejected", "not-submitted"].includes(status)
            ? this.showDocRequestModal
            : this.showAssignDocRequestModal
        }
      >
        <Tooltip tooltip={tooltip} placement="bottom">
          <span>
            <StatusCircle status={status} />
          </span>
        </Tooltip>
      </EditWrapper>
    );
  }
}

export default decorate(DocumentRequestFormatter);
