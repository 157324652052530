import * as R from "ramda";
import { createHandlers } from "redux-mvc";

import { NAMESPACE, TABS, SUB_TABS, SUB_TABS_ROUTES } from "./constants";

const iniState = {
  showBulkItemsModal: false,
  activeTab: TABS.ITEMS,
  activeSubTab: SUB_TABS.ITEMS,
  loading: false,
  types: [],
  typeId: "",
  routeName: "",
  accessGrid: null,
  isZones: false,
  refreshingZoneAssignments: false
};

const handlers = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    updateTypeId: R.identity,
    addCategory: R.identity,
    addZoneCategory: R.identity,
    updateCategory: R.identity,
    updateZoneCategory: R.identity,
    deleteCategory: R.identity,
    deleteZoneCategory: R.identity,
    addUpdateItem: R.identity,
    addUpdateZoneItem: R.identity,
    fetchTypes: R.identity,
    addZone: R.identity,
    refreshZoneAssignments: R.identity,
    setZonesSubTab: R.identity,
    setAccessGridSubTab: R.identity,
    setActionsSubTab: R.identity,
    setApproversSubTab: R.identity,
    setInventorySubTab: R.identity,
    setItemsSubTab: R.identity,
    refetchAcessGridData: R.identity,
    showBulkModal: R.identity,
    setInitialData: (state, { payload: { types, typeId, dataGrid } }) => ({
      types,
      typeId,
      dataGrid
    }),
    selectTypeId: (state, { payload: { typeId } }) => ({
      typeId
    }),
    selectActiveSubTab: (state, { payload: { routeName } }) => ({
      activeTab: R.propOr(TABS.ITEMS, routeName)({
        editEventSettingsCatalogQuestions: TABS.ITEMS_QUESTIONS,
        editEventSettingsCatalogZones: TABS.ZONES
      }),
      activeSubTab: SUB_TABS_ROUTES[routeName],
      routeName
    })
  },
  namespace: NAMESPACE
});

export default handlers;
