import React from "react";
import { actions, getters } from "../model";
import { getFieldsForModal, getSelectedFieldIds } from "../selectors";
import { FIELDS_MODAL_INSTANCE_ID } from "../constants";
import { connect } from "react-redux";

import { SelectAndCreateVariants } from "ui-kit/MiniItemsSelector/View";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";

import { MODE_SINGLE_SELECT } from "ui-kit/MiniItemsSelector/constants";

const decorate = connect(
  state => ({
    showModal: getters.showSelectFieldsModal(state),
    fieldGroups: getFieldsForModal(state),
    selectedFieldIds: getSelectedFieldIds(state)
  }),
  {
    hideModal: () => actions.setShowSelectFieldsModal(false),
    onDone: actions.saveSelectFieldsModal
  }
);

const WithModal = ({
  showModal,
  hideModal,
  fieldGroups,
  selectedFieldIds,
  onDone
}) => {
  return (
    <MiniModalWrapper
      showModal={showModal}
      hideModal={hideModal}
      width={750}
      title="Select Fields"
      style={{
        height: "calc(100vh - 90px)",
        maxHeight: "800px"
      }}
    >
      <SelectAndCreateVariants
        instanceId={FIELDS_MODAL_INSTANCE_ID}
        iniClearSearch
        fields={fieldGroups}
        iniSelected={selectedFieldIds}
        onCancel={hideModal}
        onDone={onDone}
        mode={MODE_SINGLE_SELECT}
      />
    </MiniModalWrapper>
  );
};

export default decorate(WithModal);
