import PropTypes from "prop-types";
import React, { Component } from "react";
import moment from "moment-timezone";

import DayGroup from "components/Global/EventDaySelector/DayGroup";
import DateRange from "components/Global/EventDaySelector/DateRange";
import DaysSelected from "components/Global/EventDaySelector/DaysSelected";
import Calendar from "components/Global/EventDaySelector/Calendar";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class EventDaySelector extends Component {
  getDaysGroupedByWeek(start, end) {
    const days = {};
    for (let m = moment(start); m.isBefore(end); m.add(1, "days")) {
      const data = {
        day: m.format("D"),
        ydm: m.format("YYYY-MM-DD"),
        isoWeek: m.isoWeek()
      };
      if (typeof days[data.isoWeek] !== "undefined") {
        days[data.isoWeek].push(data);
      } else {
        days[data.isoWeek] = [data];
      }
    }
    return days;
  }

  getAvailableDays(start, end) {
    const days = [];
    for (let m = moment(start); m.isBefore(end); m.add(1, "days")) {
      days.push(m.format("YYYY-MM-DD"));
    }
    return days;
  }

  render() {
    const {
      disabled,
      startDate,
      endDate,
      dayGroups,
      showCountOfSelectedDays,
      countOfSelectedDays,
      dayMap,
      handleDayClick,
      handleGroupSelectAll,
      handleGroupDeselectAll,
      close,
      showDayGroups = true,
      noShadow = false,
      className = ""
    } = this.props;
    const dateRangeStart = moment(startDate)
      .utc()
      .startOf("isoWeek");
    const dateRangeEnd = moment(endDate)
      .utc()
      .endOf("isoWeek");

    const datesAvailableStart = moment(startDate)
      .utc()
      .startOf("day");
    const datesAvailableEnd = moment(endDate)
      .utc()
      .endOf("day");

    const dates = {
      weeks: this.getDaysGroupedByWeek(dateRangeStart, dateRangeEnd),
      availableDayKeys: this.getAvailableDays(
        datesAvailableStart,
        datesAvailableEnd
      ),
      availableDayMap: dayMap || {}
    };
    return (
      <div
        styleName={noShadow ? "noShadow" : "container"}
        className={className}
      >
        {showDayGroups && dayGroups.length ? (
          <div styleName={noShadow ? "noShadowDayGroups" : "dayGroups"}>
            {dayGroups.map(group => {
              if (group.days.length === 0) {
                return null;
              }
              return (
                <DayGroup
                  key={group.id}
                  disabled={disabled}
                  groupId={group.id}
                  color={group.color}
                  name={group.name}
                  selectAllDaysInGroup={handleGroupSelectAll}
                  deselectAllDaysInGroup={handleGroupDeselectAll}
                />
              );
            })}
          </div>
        ) : null}
        <div styleName="days">
          <DateRange
            label={`${datesAvailableStart.format(
              "MMM D"
            )} - ${datesAvailableEnd.format("MMM D")}`}
          />
          <Calendar
            disabled={disabled}
            dates={dates}
            handleDayClick={handleDayClick}
          />

          <div styleName="footer">
            {showCountOfSelectedDays === true ? (
              <DaysSelected
                label={`${countOfSelectedDays} ${
                  countOfSelectedDays !== 1 ? "days" : "day"
                } selected`}
              />
            ) : (
              ""
            )}
            {close ? (
              <div styleName="done" onClick={close}>
                done
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

EventDaySelector.propTypes = {
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  dayGroups: PropTypes.array.isRequired,
  handleDayClick: PropTypes.func.isRequired,
  handleGroupSelectAll: PropTypes.func,
  handleGroupDeselectAll: PropTypes.func,
  showCountOfSelectedDays: PropTypes.bool,
  countOfSelectedDays: PropTypes.number,
  dayMap: PropTypes.object,
  disabled: PropTypes.bool,
  close: PropTypes.func
};

export default EventDaySelector;
