import getValue from "utils/value-types/get-value";
import toString from "utils/value-types/to-string";
import { get } from "lodash";
import * as SYSTEM_FIELD_IDS from "utils/system-field-ids";

const { ID } = SYSTEM_FIELD_IDS;

export default (record, fieldId) => {
  // if we're handling the record ID, return the friendly version
  if (fieldId === ID) {
    return `${record.record_prefix}-${record.friendly_id}`;
  }
  const value = get(record, ["values", fieldId], {});
  return toString(getValue(value, value.type), value.type);
};
