import * as R from "ramda";

export const iniState = {
  showAddEndDate: true,
  eventShowcase: {
    photos: [],
    files: [],
    completed_steps: [],
    fields: [],
    variants: [],
    event: {}
  },
  editingFileId: 0,
  updatedFilename: ""
};

export const reducers = {
  refreshEventShowcase: R.identity,
  toggleCompletedStep: R.identity,
  updateEventShowcaseField: R.identity,
  addEventShowcasePhoto: R.identity,
  updateEventShowcasePhoto: R.identity,
  deleteEventShowcasePhoto: R.identity,
  reorderEventShowcasePhotos: R.identity,
  addEventShowcaseFile: R.identity,
  updateEventShowcaseFile: R.identity,
  deleteEventShowcaseFile: R.identity,
  reorderEventShowcaseFiles: R.identity,
  updateEventShowcaseDetails: R.identity,
  showBgModal: R.identity,
  showImageModal: R.identity,
  showFloormapImageModal: R.identity,
  showSalesDeckModal: R.identity,
  removeFloormap: R.identity,
  updateFilename: R.identity,
  receiveEventShowcase: (state, { payload: eventShowcase }) => ({
    eventShowcase: {
      ...eventShowcase,
      description: eventShowcase.description || "",
      terms: eventShowcase.terms || "",
      confirmation: eventShowcase.confirmation || "",
      venue_address_city: eventShowcase.venue_address_city || "",
      venue_address_line_1: eventShowcase.venue_address_line_1 || "",
      venue_address_line_2: eventShowcase.venue_address_line_2 || "",
      venue_address_state: eventShowcase.venue_address_state || "",
      venue_address_zip: eventShowcase.venue_address_zip || "",
      venue_name: eventShowcase.venue_name || ""
    }
  })
};
