import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Controller from "./Controller";

import { eventDetails } from "redux/modules/event/selectors";
import { user } from "redux/modules/user/selectors";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";

function mapStateToProps(state) {
  return {
    eventDetails: eventDetails(state),
    user: user(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal,
      hideModal,
      showSnackbar
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
