import React from "react";

import { connect } from "react-redux";

import * as R from "ramda";

import {
  Div,
  PopMenu,
  PopMenuCard,
  MediumFilledButton,
  MediumOutlineButton,
  Text2
} from "components/Base";

import { RadioButton } from "material-ui/RadioButton";

import {
  getProfilePricesNone,
  getWhoHasAccessFormattedData
} from "Orders/TicketsPasses/selectors";

import { actions, getters } from "Orders/TicketsPasses";

const decorate = connect(
  state => ({
    isProfilePricesNone: getProfilePricesNone(state),
    profiles: getWhoHasAccessFormattedData(state),
    copyToProfileId: getters.copyToProfileId(state)
  }),
  {
    clearProfileAllAssignedItems: actions.clearProfileAllAssignedItems,
    copyProfileSettings: actions.copyItemProfileSettings,
    setCopyToProfileId: actions.setCopyToProfileId,
    setProfilesId: actions.setProfilesId
  }
);

const CopyToPopOver = ({
  profiles,
  profileId,
  copyToProfileId,
  setCopyToProfileId,
  copyProfileSettings,
  closeParent,
  setProfilesId,
  isSelected
}) => {
  return (
    <PopMenu
      Label={({ onClick }) => (
        <Div
          width={1}
          p={2}
          fw={3}
          color={isSelected ? "black" : "neutral5"}
          bg={{
            default: "white",
            hover: isSelected ? "neutral0" : "white"
          }}
          onClick={() => {
            if (isSelected) {
              setProfilesId({
                copyToProfileId: "",
                copyFromProfileId: profileId
              });
              onClick();
            }
          }}
          style={{ overflow: "visible" }}
        >
          Copy Settings to...
        </Div>
      )}
    >
      {({ closeMenu }) => (
        <PopMenuCard position="bottom" align="right">
          <Div p={3} mb={1} width={250}>
            <Text2 bold ml={2}>
              Profiles
            </Text2>
            <Div>
              {R.compose(
                R.map(({ id, name }) => (
                  <Div
                    key={id}
                    width={1}
                    p={2}
                    fw={3}
                    color="neutral7"
                    bg={{
                      default: "white",
                      hover: "neutral0"
                    }}
                    display="row.flex-start.center"
                  >
                    <Div
                      width={30}
                      onClick={() => {
                        setCopyToProfileId(id);
                      }}
                    >
                      <RadioButton checked={copyToProfileId === id} />
                    </Div>
                    <Text2 bold color="black">
                      {name}
                    </Text2>
                  </Div>
                )),
                R.filter(profile => profile.id !== profileId)
              )(profiles)}
            </Div>
            <Div display="row.space-between.center" mt={2} px={2}>
              <MediumOutlineButton
                mr={2}
                onClick={() => {
                  closeMenu();
                }}
              >
                Cancel
              </MediumOutlineButton>
              <MediumFilledButton
                flex={1}
                bg="primary7"
                onClick={() => {
                  copyProfileSettings();
                  closeMenu();
                  closeParent();
                }}
              >
                Copy Settings
              </MediumFilledButton>
            </Div>
          </Div>
        </PopMenuCard>
      )}
    </PopMenu>
  );
};

export default decorate(CopyToPopOver);
