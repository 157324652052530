import React from "react";
import { CalendarIcon, Type6Card, Div } from "components/Base";
import EventList from "Schedules/ScheduleActivitiesList";

import { makeEnum, displayTimezone } from "utils/General";

const ActivityTabValues = makeEnum(["SCHEDULE", "CALENDAR"]);

const View = ({ recordId }) => (
  <Type6Card
    cardTitleIcon={CalendarIcon}
    cardTitleIconColor="blue5"
    cardTitle="Schedule"
    defaultTabValue={ActivityTabValues.SCHEDULE}
    cardSubtitle={displayTimezone()}
  >
    <Div px={5} height={1}>
      <EventList
        recordId={recordId}
        source="profile"
        showActivityRecordModal={false}
      />
    </Div>
  </Type6Card>
);

// tabs={[
//   {
//     value: ActivityTabValues.SCHEDULE,
//     label: 'Schedule',
//     component: <EventList eventsByYear={eventsByYear} />,
//     onClick: () => console.log('Schedule clicked')
//   },
//   {
//     value: ActivityTabValues.CALENDAR,
//     label: 'Calendar',
//     component: <Div>Calendar</Div>,
//     onClick: () => console.log('Calendar clicked')
//   }
// ]}
// cardTitleButtons={[
//   {
//     children: 'Add related activity',
//     onClick: () => console.log('Add related activity')
//   },
//   {
//     children: 'Actions',
//     RightIcon: DownFilledIcon,
//     onClick: () => console.log('Actions')
//   }
// ]}

export default View;

// const PeopleCard = () => (
//   <Card1
//     cardTitleIcon={PeopleIcon}
//     cardTitleIconColor={'blue5'}
//     cardTitle={'People'}
//     cardTitleButtons={[
//       {
//         children: 'Add person',
//         onClick: () => console.log('Add Person')
//       },
//       {
//         children: 'Actions',
//         RightIcon: DownFilledIcon,
//         onClick: () => console.log('Actions')
//       }
//     ]}
//     defaultTabValue={'Everyone'}
//     tabs={[
//       {
//         label: 'Needs Review',
//         component: <Div>Hey</Div>,
//         onClick: () => console.log('Needs Review Active')
//       },
//       {
//         label: 'Everyone',
//         component: <Div>Hey</Div>,
//         onClick: () => console.log('Everyone Active')
//       },
//       {
//         label: 'Portal Users',
//         component: <Div>Hey</Div>,
//         onClick: () => console.log('Portal Users Active')
//       },
//       {
//         label: 'By People Type',
//         component: <Div>Hey</Div>,
//         onClick: () => console.log('By People Type Active')
//       }
//     ]}
//   />
// );
