import * as R from "ramda";
import React from "react";
import { withRouter, Link } from "react-router";
import { connect } from "react-redux";

import {
  getStepsProgress,
  getNextStep,
  getPrevStep,
  getIsLast,
  getIsFirst
} from "Sponsors/SelfSetup/selectors";

import { STEPS } from "Sponsors/SelfSetup/constants";
import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";

import RightBarProgress from "./RightBarProgress";
import CreatePackagePage from "./CreatePackagePage";
import CreateItemsPage from "./CreateItemsPage";
import SettingsPage from "./SettingsPage";
import AddItemsPage from "./AddItemsPage";
import FloormapPage from "./FloormapPage";
import ApplicationPage from "./ApplicationPage";
import EmailsPage from "./EmailsPage";
import PaymentPage from "./PaymentPage";
import DocumentsPage from "./DocumentsPage";
import FinishedPage from "./FinishedPage";

import ProgressBar from "ui-kit/ProgressBar";

import {
  Div,
  LeftArrowIcon,
  BigFilledButton,
  BigTextButton
} from "components/Base";

const decorate = R.compose(
  withRouter,
  WithThemeConsumer({ variant: VARIANTS.BACKGROUND }),
  connect((state, props) => ({
    progress: getStepsProgress(state, props),
    nextStep: getNextStep(state, props),
    prevStep: getPrevStep(state, props),
    isLast: getIsLast(state, props),
    isFirst: getIsFirst(state, props)
  }))
);

const viewMap = {
  [STEPS.OPPORTUNITIES]: CreatePackagePage,
  [STEPS.ITEMS]: CreateItemsPage,
  [STEPS.ADD_ITEMS]: AddItemsPage,
  [STEPS.DETAILS]: SettingsPage,
  [STEPS.SPACES]: FloormapPage,
  [STEPS.APPLICATION]: ApplicationPage,
  [STEPS.NOTIFICATIONS]: EmailsPage,
  [STEPS.PAYMENT]: PaymentPage,
  [STEPS.DOCUMENTS]: DocumentsPage,
  [STEPS.FINISHED]: FinishedPage
};

const BoothSelfSetup = ({
  nextStep = "",
  prevStep = "",
  progress = 0,
  isLast = false,
  isFirst = false,
  params,
  handlers = {},
  Th = defaultTh
}) => (
  <Div>
    <Div
      {...Th(["bg"], {
        bra: 2,
        display: "row.flex-start.center",
        shadow: 2,
        py: 3,
        px: 6,
        mb: 5,
        style: {
          maxWidth: "1200px"
        }
      })}
    >
      {isFirst ? null : (
        <Link to={prevStep}>
          <BigTextButton
            LeftIcon={LeftArrowIcon}
            {...Th(["color"], { variant: Th.VARIANTS.BACKGROUND })}
          >
            Back
          </BigTextButton>
        </Link>
      )}
      <ProgressBar progress={progress} mr={3} />
      {isLast ? null : (
        <Link to={nextStep}>
          <BigFilledButton
            {...Th(["bg", "color"], { variant: Th.VARIANTS.SECONDARY })}
          >
            Next
          </BigFilledButton>
        </Link>
      )}
    </Div>

    <Div
      {...Th(["bg"], {
        bra: 2,
        display: "row.flex-start.stretch",
        shadow: 2,
        mb: 10,
        style: {
          maxWidth: "1200px"
        }
      })}
    >
      {React.createElement(viewMap[params.stepId] || Div, {
        pt: 7,
        pb: 3,
        pl: 6,
        pr: 4,
        flex: 1,
        handlers
      })}
      <RightBarProgress pb={3} pt={7} px={6} />
    </Div>
  </Div>
);

export default decorate(BoothSelfSetup);
