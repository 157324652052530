import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "Orders/ImportModal";
import {
  Div,
  Text3,
  Text4,
  Radio,
  PersonIcon,
  Text1,
  PeopleIcon
} from "components/Base";
import Circle from "ui-kit/Circle";
import { ORDER_TYPE } from "Orders/ImportModal/constants";
import { isContactsOrAccountsModule } from "Orders/ImportModal/selectors";
import { noop } from "utils/General";

const decorate = connect(
  state => ({
    orderType: getters.orderType(state),
    isContactsOrAccountsModule: isContactsOrAccountsModule(state)
  }),
  {
    setOrderType: actions.setOrderTypeData
  }
);

const TypeOrder = ({ orderType, setOrderType, isContactsOrAccountsModule }) =>
  isContactsOrAccountsModule || (
    <Div width={1} px={4} pt={4} pb={4}>
      <Text4 bold>Type of Order</Text4>
      <Div mt={2}>
        <Div
          display="row.space-between.center"
          p={2}
          bc="neutral2"
          bb={2}
          bg={orderType === ORDER_TYPE.INDIVIDUAL ? "neutral0" : "white"}
          onClick={() => setOrderType(ORDER_TYPE.INDIVIDUAL)}
        >
          <Div display="row.flex-start.center">
            <Radio
              selected={orderType === ORDER_TYPE.INDIVIDUAL}
              mr={2}
              size={24}
              onClick={noop}
            />
            <Circle size={24}>
              <PersonIcon color="neutral0" />
            </Circle>
            <Text3 bold ml={2}>
              Individual Order
            </Text3>
          </Div>
          <Text1 color="neutral6">Items held under one person's name</Text1>
        </Div>

        <Div
          display="row.space-between.center"
          p={2}
          bg={orderType === ORDER_TYPE.GROUP ? "neutral0" : "white"}
          onClick={() => setOrderType(ORDER_TYPE.GROUP)}
        >
          <Div display="row.flex-start.center">
            <Radio
              selected={orderType === ORDER_TYPE.GROUP}
              mr={2}
              size={24}
              onClick={noop}
            />
            <PeopleIcon color="neutral3" size={24} />
            <Text3 bold ml={2}>
              Group Order
            </Text3>
          </Div>
          <Text1 color="neutral6">Items held under a group's name</Text1>
        </Div>
      </Div>
    </Div>
  );
export default decorate(TypeOrder);
