import PropTypes from "prop-types";
import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { DragSource, DropTarget } from "react-dnd";
import FieldOptions from "components/Event/FormsV2/Sections/FieldOptions";
import { ItemTypes } from "components/Event/FormsV2/Form/Views/V3_Edit/Shared/constants/constants";

import CSSModules from "react-css-modules";
import css from "./styles.scss";
import {
  ORDER_CUSTOMER_BLOCK_TYPE,
  ORDER_FULFILLMENT_BLOCK_TYPE,
  ORDER_DETAILS_BLOCK_TYPE
} from "components/Event/FormsV2/Form/Views/V3_Edit/Shared/constants/block-types";
const cardSource = {
  beginDrag(props) {
    return {
      index: props.index,
      id: props.field.id,
      field: props.field
    };
  }
};

const cardTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.moveField(
      dragIndex,
      hoverIndex,
      monitor.getItem().field,
      monitor.getItem().viaSidebar
    );

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

@DropTarget(ItemTypes.FIELD, cardTarget, connect => ({
  connectDropTarget: connect.dropTarget()
}))
@DragSource(ItemTypes.FIELD, cardSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging()
}))
@CSSModules(css)
class Field extends Component {
  render() {
    const {
      children,
      isEditing,
      isDragging,
      connectDragPreview,
      connectDropTarget,
      hideOptions,
      canShowSimpleSideBar
    } = this.props;
    const includesTypes = this.props.field?.type
      ? [
          "image",
          "separator",
          "header",
          "user",
          ORDER_CUSTOMER_BLOCK_TYPE,
          ORDER_FULFILLMENT_BLOCK_TYPE,
          ORDER_DETAILS_BLOCK_TYPE
        ].includes(this.props.field.type)
      : null;

    const field = (
      <div
        styleName={isEditing ? "containerHover" : "container"}
        style={{
          opacity: isDragging ? 0 : 1
        }}
      >
        {canShowSimpleSideBar && !includesTypes && children
          ? React.cloneElement(children, {
              connectDragSource: this.props.connectDragSource
            })
          : children}

        {!hideOptions &&
        isEditing &&
        ((canShowSimpleSideBar && includesTypes) || !canShowSimpleSideBar) ? (
          <FieldOptions
            {...this.props}
            connectDragSource={this.props.connectDragSource}
            field={this.props.field}
          />
        ) : (
          ""
        )}
      </div>
    );

    return isEditing ? connectDragPreview(connectDropTarget(field)) : field;
  }
}

Field.propTypes = {
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  isDragging: PropTypes.bool.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.object]).isRequired,
  moveField: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  hideOptions: PropTypes.bool.isRequired
};

export default Field;
