import React from "react";
import { SubNavWrapper, SubNavLink } from "EventLight/Common/SubNav";
import { TAB_IDS } from "./constants";
import { withRouter } from "react-router";

const SubNav = ({ selectedView, router, params }) => (
  <SubNavWrapper supportId="alerts">
    <SubNavLink
      name="Alerts"
      active={selectedView === TAB_IDS.ALERTS}
      onClick={() =>
        router.push({
          pathname: `/event-light/${params.eventId}/alerts`
        })
      }
    />
    <SubNavLink
      name="Settings"
      active={selectedView === TAB_IDS.SETTINGS}
      onClick={() =>
        router.push({
          pathname: `/event-light/${params.eventId}/emails/settings`
        })
      }
    />
    {/*
    <SubNavLink
      name="Activity"
      active={selectedView === TAB_IDS.ACTIVITY}
      onClick={() =>
        router.push({
          pathname: `/event-light/${params.eventId}/emails/activity`
        })
      }
    />
    */}
  </SubNavWrapper>
);

export default withRouter(SubNav);
