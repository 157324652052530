import React, { Component } from "react";
import * as R from "ramda";
import getValue from "utils/value-types/get-value/event-days";
import dateFormatter from "./utils";
import { Div } from "components/Base";

import { getMeta } from "ui-kit/Datagrid/utils";

import { EMPTY_ERRORS } from "ui-kit/Validator/constants";
import ErrorMark from "../ErrorMark";

const errorStyle = {
  display: "flex",
  flexDirection: "row-reverse",
  alignItems: "center",
  justifyContent: "space-between"
};

class EventDays extends Component {
  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props.value, nextProps.value);
  }

  render() {
    const props = this.props;

    const days = R.sortBy(R.identity, getValue(this.props.value) || []);
    const dayGroups = getMeta(props, ["eventDetails", "date_groups"], []);
    // sort dates
    const formattedDates = dateFormatter(days, dayGroups);
    const countOfDates = formattedDates.length;

    const { valid, description } = R.propOr(
      EMPTY_ERRORS,
      "error",
      this.props.value
    );

    return (
      <Div
        display="row.flex-start.center"
        minHeight={23}
        style={{
          userSelect: "none",
          overflow: "hidden",
          ...((!valid && errorStyle) || {})
        }}
      >
        <ErrorMark valid={valid} description={description} />
        {formattedDates.map(({ label, color }, idx) => (
          <Div
            key={`${label}_${color}`}
            display="row.flex-start.center"
            height={20}
            style={{
              flexShrink: 0,
              marginRight: countOfDates - 1 === idx ? 0 : 4,
              fontSize: 12,
              borderRadius: 2,
              color: "#000",
              padding: "0 5px",
              backgroundColor: `rgba(${color}, 0.15)`
            }}
          >
            {label}
          </Div>
        ))}
      </Div>
    );
  }
}

export default EventDays;
