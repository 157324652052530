import React from "react";

import * as R from "ramda";

import {
  Div,
  MediumFilledButton,
  BigFilledButton,
  Text1,
  OpenInNewIcon
} from "components/Base";

import QuestionCards from "./QuestionCards";

const Questions = ({ questions, handlers }) => {
  return (
    <Div p={2}>
      {/* <Div display="row.center.center" mb={3} px={8}>
        <Div display="row.flex-start.center">
          <Div>
            <Text1>You can drag & drop these questions into the order</Text1>
            <Text1>you'd like them to be answered</Text1>
          </Div>
        </Div>
         </Div> */}
      <MediumFilledButton
        bg="primary7"
        width={1}
        onClick={handlers.showAssignQuestionsModal}
      >
        Manage / Assign Questions
      </MediumFilledButton>
      <QuestionCards
        questions={questions}
        showEditQuestionSetQuestionsModal={
          handlers.showEditQuestionSetQuestionsModal
        }
      />
      {/*
      <BigFilledButton
        mt={2}
        bg="neutral1"
        color="black"
        LeftIcon={OpenInNewIcon}
      >
        Copy questions from...
      </BigFilledButton>
      */}
    </Div>
  );
};

export default Questions;
