import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import * as R from "ramda";

import CollapsablePanel from "ui-kit/CollapsiblePanel";
import moment from "moment";

const Summary = CSSModules(css)(({ activeContentRows = [] }) => (
  <div styleName="summaryContent">
    {R.map(
      contentRow => (
        <CollapsablePanel
          key={contentRow.id}
          arrowColor="gray7"
          bg="white"
          heading={contentRow.name}
          collapsedDefault
          arrowColor="gray7"
          bg="white"
          width={1}
          mt={2}
        >
          {R.map(
            item => (
              <div styleName="dueDateField" key={item.id}>
                <span styleName="dueDateFieldLabel">{item.name}</span>
                <span>{item.due_raw}</span>
              </div>
            ),
            R.propOr([], "items", contentRow)
          )}
        </CollapsablePanel>
      ),
      activeContentRows
    )}
  </div>
));

const DueDates = CSSModules(css)(({ activeContentRows = [] }) => (
  <div styleName="summaryContent">
    {R.map(
      contentRow => (
        <CollapsablePanel
          key={contentRow.id}
          arrowColor="gray7"
          bg="white"
          heading={contentRow.name}
          collapsedDefault
          arrowColor="gray7"
          bg="white"
          width={1}
          mt={2}
        >
          {R.map(
            item => (
              <div styleName="dueDateField" key={item.id}>
                <span
                  styleName="dueDateFieldLabel"
                  style={{
                    backgroundColor: R.propOr("#494949", "color", item)
                  }}
                >
                  {item.name}
                </span>
                <span>{moment(item.due_raw).format("MMM, D @ h:mm a")}</span>
              </div>
            ),
            R.propOr([], "items", contentRow)
          )}
        </CollapsablePanel>
      ),
      activeContentRows
    )}
  </div>
));

export { DueDates, Summary };
