export const NAMESPACE = "EventLightPortalsePortals";

export const WIDTH = "825px";

export const TABS = {
  ALL_FORMS: "allForms",
  OPEN: "open",
  CLOSED: "closed"
};

export const STATUS = {
  OPEN: "open",
  CLOSED: "closed"
};

export const SORTING = {
  PENDING: "count_of_pending",
  CREATED_AT: "created_at",
  ABC: "name"
};

export const ROW_ACTIONS = {
  EDIT: "edit",
  DELETE: "delete",
  BASIC_SETTINGS: "basicSettings",
  CONFIRMATION_MESSAGE: "confirmationMessage",
  COPY_LINK: "copyLink",
  SHARE_LINK: "shareLink"
};
