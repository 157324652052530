import PropTypes from "prop-types";
import React, { Component } from "react";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";
import IconButton from "material-ui/IconButton";
import Menu from "./Menu";
import Avatar from "components/Atoms/Avatar";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class RecordOwnerUser extends Component {
  render() {
    const { user, onRemove, readOnly } = this.props;

    return (
      <div styleName="contactContainer">
        {!readOnly && (
          <div styleName="iconWrapper">
            <Menu onRemove={onRemove}>
              <IconButton>
                <MoreVertIcon />
              </IconButton>
            </Menu>
          </div>
        )}
        <div styleName="contactIconWrapper">
          <Avatar
            size={28}
            style={{
              borderRadius: 5,
              marginRight: 9,
              backgroundColor: "#E2E2E2"
            }}
            textStyle={{
              color: "#FFFFFF",
              fontWeight: "bold"
            }}
            userId={user.user_id}
            imageUrl={user.user_photo_url}
            firstName={user.user_fname}
            lastName={user.user_lname}
          />
        </div>
        <div>
          <div styleName="name">{`${user.user_fname} ${user.user_lname}`}</div>
          <div styleName="detail">{user.user_email}</div>
        </div>
      </div>
    );
  }
}

RecordOwnerUser.propTypes = {
  user: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default RecordOwnerUser;
