import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  title: "",
  backgroundImageUrl: "",
  remindersEnabled: false,
  logoImageUrl: "",
  leftSidebarSections: [],
  footerSections: [],
  activeAlerts: [],
  alertVisible: true,
  sessionDetails: {}
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    executeAction: R.identity,
    setInitialData: (
      _,
      {
        payload: {
          title,
          background_image_url: backgroundImageUrl,
          reminders_enabled: remindersEnabled,
          logo_image_url: logoImageUrl,
          left_sidebar_sections: leftSidebarSections,
          footer_sections: footerSections,
          active_alerts: activeAlerts,
          session_details: sessionDetails
        }
      }
    ) => ({
      title,
      backgroundImageUrl,
      remindersEnabled,
      logoImageUrl,
      leftSidebarSections,
      footerSections,
      activeAlerts,
      sessionDetails
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
