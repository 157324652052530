import { pathOr } from "ramda";

export const checkins = state => state.passesCheckin.list;
export const pageSize = state => state.passesCheckin.pagination.pageSize || 10;
export const countOfIssued = state => state.passesCheckin.counts.issued || 0;
export const countOfTotal = state => state.passesCheckin.counts.total || 0;
export const countOfAvailableResults = state =>
  state.passesCheckin.pagination.total;

export const totalPages = state => {
  return Math.ceil(countOfAvailableResults(state) / pageSize(state));
};
export const currentPage = state => state.passesCheckin.pagination.page || 0;
export const isFetching = state => state.passesCheckin.fetching;
export const recordWithIssuance = (state, key) =>
  pathOr({}, ["byKey", key])(state.passesCheckin.recordWithIssuance);
