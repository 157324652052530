import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function addFieldGroupField(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding field group field"
          }
        ])
      );
    }
  };
}

export function updateFieldGroupField(id, data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.put(getState().user.user.credentials, id, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating field group field"
          }
        ])
      );
    }
  };
}

export function deleteFieldGroupField(id, moduleId) {
  return async (dispatch, getState) => {
    try {
      const result = await api.delete(
        getState().user.user.credentials,
        id,
        moduleId
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting field group field"
          }
        ])
      );
    }
  };
}
