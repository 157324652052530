import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import {
  ButtonGroup,
  Submit,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";
import TemplateSelect from "./TemplateSelect";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import SelectRecipients from "./SelectRecipients";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

@CSSModules(css)
class SelectDocumentRequestTemplate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTemplate: null
    };
  }

  componentDidMount() {
    this.props.getRecords({
      moduleId: STANDARD_MODULE_IDS.documentRequestTypes.id,
      options: {
        eventId: this.props.eventDetails.id
      }
    });
  }

  onTemplateSelect = selectedTemplate => {
    this.setState({
      selectedTemplate
    });
  };

  getScopeModuleId = () => {
    if (this.props.scope === "accounts") {
      return STANDARD_MODULE_IDS.accounts.id;
    }
    return STANDARD_MODULE_IDS.contacts.id;
  };

  addRequests = ({ template, selected, message }) => {
    const { eventDetails } = this.props;
    return Promise.all(
      selected.map(id =>
        this.props.addRecord({
          moduleId: STANDARD_MODULE_IDS.documentRequests.id,
          record: {
            requestTypeId: template.id,
            recipientModuleId: this.getScopeModuleId(),
            recipientRecordId: id,
            message
          },
          options: {
            eventId: eventDetails.id
          }
        })
      )
    );
  };

  onSaveRequestClick = async e => {
    e.preventDefault();
    const { selectedTemplate } = this.state;
    if (!selectedTemplate) return null;

    const requests = await this.addRequests({
      template: selectedTemplate,
      selected: this.props.selected
    });
    this.props.hideModal();
    this.props.onDone(requests.length, "create");
  };

  onSaveAndSendRequestClick = async e => {
    e.preventDefault();
    const { selectedTemplate } = this.state;
    if (!selectedTemplate) return null;

    const requests = await this.addRequests({
      template: selectedTemplate,
      selected: this.props.selected
    });

    this.props.hideModal();
    this.props.onDone(requests.length, "create");

    return this.showSendDocumentRequestsModal({
      template: selectedTemplate,
      requests
    });
  };

  showSendDocumentRequestsModal({ template, requests }) {
    this.props.showModal({
      content: (
        <SelectRecipients
          onDone={this.props.onDone}
          template={template}
          selected={this.props.selected}
          requests={requests}
          recipients={this.props.recipients}
        />
      ),
      wrapper: ModalWrapper
    });
  }

  render() {
    const { documentRequestTypes } = this.props;
    return (
      <StyleWrapper
        hideModal={this.props.hideModal}
        heading="Assign File Request"
        width={410}
        containerStyles={{ overflowY: "visible" }}
      >
        <span>
          <TemplateSelect
            templates={documentRequestTypes}
            selected={this.state.selectedTemplate}
            onSelect={this.onTemplateSelect}
          />
          <br />
          <ButtonGroup>
            <Submit
              type="button"
              onClick={this.onSaveRequestClick}
              title="Save"
              disabled={!this.state.selectedTemplate}
            />
            <ButtonOutline
              onClick={this.onSaveAndSendRequestClick}
              title="Save and Send"
              disabled={!this.state.selectedTemplate}
            />
          </ButtonGroup>
        </span>
      </StyleWrapper>
    );
  }
}

SelectDocumentRequestTemplate.propTypes = {
  documentRequestTypes: PropTypes.array.isRequired,
  moduleId: PropTypes.string.isRequired,
  eventId: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  getRecords: PropTypes.func.isRequired,
  addRecord: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired,
  eventDetails: PropTypes.object.isRequired,
  recipients: PropTypes.array.isRequired,
  scope: PropTypes.string.isRequired
};

export default SelectDocumentRequestTemplate;
