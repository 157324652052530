import { get } from "lodash";

export const isFetching = (state, recordId) =>
  get(state, ["orders", "ordersByRecord", "fetching", "byId", recordId]);

export const ordersByRecord = (state, recordId) =>
  get(
    state,
    ["orders", "ordersByRecord", "ordersByRecord", "byId", recordId],
    []
  );
