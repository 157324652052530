import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "EventLight/Passes/Zones/constants";

const iniState = {
  loading: true,
  zones: [],
  typesFilter: "",
  showBulkItemsModal: false
};

const model = createHandlers({
  iniState,
  reducers: {
    setInitialData: (state, { payload: { zones } }) => ({
      zones
    }),
    init: R.identity,
    fetchZones: R.identity,
    addUpdateZoneItem: R.identity,
    addCategory: R.identity,
    deleteZoneItem: R.identity,
    reorderZoneItems: R.identity,
    moveZoneItemUp: R.identity,
    moveZoneItemDown: R.identity,
    moveZoneCategoryUp: R.identity,
    moveZoneCategoryDown: R.identity,
    showDeleteGroupModal: R.identity,
    showUpdateGroupModal: R.identity,
    showBulkModal: R.identity
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
