import * as R from "ramda";
import { createSelector } from "reselect";

import { getters } from "./model";

const makeTree = (order, partial = [], h1, ...rest) => {
  if (!h1) {
    return partial;
  }

  const childrenIds = order[h1] || [];
  const newRest = !R.isEmpty(childrenIds) ? [...childrenIds, ...rest] : rest;
  return makeTree(order, [...partial, h1], ...newRest);
};

const hasChildrenNameRecursive = (id, order, updatedItems, filterName) => {
  const children = R.compose(
    R.map(childId => R.find(item => item.id === childId, updatedItems)),
    R.propOr([], id)
  )(order);

  if (R.isEmpty(children)) {
    return false;
  }

  return (
    R.any(
      child => R.contains(R.toLower(filterName), R.toLower(child.name)),
      children
    ) ||
    R.any(
      child =>
        hasChildrenNameRecursive(child.id, order, updatedItems, filterName),
      children
    )
  );
};

export const getItems = createSelector(
  getters.items,
  getters.order,
  getters.compressedList,
  getters.selectedIds,
  (items, order, compressedList, selectedIds) => {
    const orderKeys = R.keys(order);

    const updatedOrder = R.reduce(
      (acc, orderKey) => {
        return [...acc, { children: order[orderKey], id: orderKey }];
      },
      [],
      orderKeys
    );

    const level = {
      root: -1
    };

    return R.compose(
      R.map(id => {
        const item = R.find(R.propEq("id", id), items);
        const parentId = R.find(
          ({ children }) => R.any(childId => childId === item.id, children),
          updatedOrder
        ).id;
        level[item.id] = level[parentId] + 1;
        return {
          ...item,
          parentId,
          level: level[item.id],
          selected: R.any(selectedId => selectedId === id, selectedIds),
          isExpanded:
            item.type === "page"
              ? false
              : !R.any(compressedId => compressedId === item.id, compressedList)
        };
      }),
      R.filter(item => !R.isNil(item)),
      R.slice(1, Infinity)
    )(makeTree(order, [], "root"));
  }
);

export const getItemsOrdered = createSelector(
  getItems,
  getters.order,
  getters.compressedList,
  getters.filterList,
  getters.filterName,
  (items, order, compressedList, filterList, filterName) => {
    const childrenToHide = R.reduce(
      (acc, compressedId) => {
        const children = R.propOr([], compressedId, order);
        const grandChildren = R.compose(
          R.flatten,
          R.map(id => R.propOr([], id, order))
        )(children);
        return [...acc, ...children, ...grandChildren];
      },
      [],
      compressedList
    );

    const updatedItems = R.compose(
      R.filter(({ id }) => {
        if (R.isEmpty(filterList)) {
          return true;
        }
        return R.any(filterId => id === filterId, filterList);
      }),
      R.filter(({ id }) => !R.any(childId => childId === id, childrenToHide))
    )(items);

    if (R.isEmpty(filterName)) {
      return updatedItems;
    }

    return R.filter(field => {
      return (
        R.contains(R.toLower(filterName), R.toLower(field.name)) ||
        hasChildrenNameRecursive(field.id, order, updatedItems, filterName)
      );
    }, updatedItems);
  }
);

const getOrderedItemsRecursive = (parent, order) => {
  const children = R.propOr([], parent, order);

  if (R.isEmpty(children)) {
    return [];
  }

  const parentPageId = parent !== "root" ? parent : null;

  return [
    ...children.map((pageId, idx) => ({ pageId, order: idx, parentPageId })),
    ...R.reduce(
      (acc, pageId) => [...acc, ...getOrderedItemsRecursive(pageId, order)],
      [],
      children
    )
  ];
};

export const getItemsToSave = createSelector(
  getters.items,
  getters.order,
  (items, order) => getOrderedItemsRecursive("root", order)
);
