import * as R from "ramda";
import Layout from "./Layout";
import { withRouter } from "react-router";
import { createContext } from "redux-mvc";
import module from "Portal/IntakeForm";
import { actions } from "Portal/IntakeForm/model";

const decorate = createContext({
  module,
  options: {
    observedDomains: ["user", "event", "permissions", "organization"],
    persist: false,
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    )
  },
  lifeCycle: {
    constructor() {
      this.showAlert = true;
    },
    componentDidMount() {
      if (window.Intercom) {
        window.Intercom("update", {
          hide_default_launcher: true
        });
      }

      window.addEventListener("beforeunload", this.handlers.onUnload);

      if (this.props.route.name === "intakeCallback") {
        this.showAlert = false;
        this.store.dispatch(actions.handleCallback(this.props.location.query));
      } else {
        this.store.dispatch(
          actions.init({
            ...this.props.params,
            ...this.props.location.query
          })
        );
      }
    },
    componentWillUnmount() {
      window.removeEventListener("beforeunload", this.handlers.onUnload);
    }
  },
  handlers: {
    setShowAlert(value) {
      this.showAlert = value;
    },
    onUnload(e) {
      if (this.showAlert) {
        const confirmationMessage =
          "Are you sure you want to leave your submission?";
        (e || window.event).returnValue = confirmationMessage; // Gecko + IE
        return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
      }
      return undefined;
    }
  }
});

export default R.compose(
  withRouter,
  decorate
)(Layout);
