import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { Div } from "components/Base";

import { noop } from "utils/General";

import Sidebar from "./Sidebar";
import Table from "./Table";
import PaginationBar from "./PaginationBar";
import MiniPassModal from "./MiniPassModal";
import MiniSourceModal from "./MiniSourceModal";
import MiniUserModal from "./MiniUserModal";
import TopBar from "./TopBar";

import LoadingOverlay from "ui-kit/LoadingOverlay";
import FiltersOn from "ui-kit/FiltersOn";

import { actions, getters } from "../model";
import { getFiltersOn, getItemTypeId } from "../selectors";
import { recordTypes } from "redux/modules/modules/recordTypes/selectors";

import { actions as FiltersActions } from "ui-kit/FiltersPanel";
import { TABLE_VIEW_ID } from "Passes/Common/constants";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      attendeeTypes: recordTypes(state, STANDARD_MODULE_IDS.contacts.id),
      groupTypes: recordTypes(state, STANDARD_MODULE_IDS.accounts.id),
      filtersOn: getFiltersOn(state, props),
      itemTypeId: getItemTypeId(state, props),
      loading: getters.loading(state, props)
    }),
    {
      onClearFilters: FiltersActions.clearFilters,
      init: actions.init,
      onCellClick: actions.showViewOrder
    }
  )
);

const Layout = ({
  groupTypes = [],
  attendeeTypes = [],
  filtersOn = false,
  onClearFilters = noop,
  loading = false,
  itemTypeId,
  onCellClick = noop,
  init
}) => {
  useEffect(() => {
    init({ itemTypeId });
  }, []);
  return (
    <Div display="row.center.stretch" style={{ height: "calc(100vh - 116px)" }}>
      <Sidebar attendeeTypes={attendeeTypes} groupTypes={groupTypes} />
      <Div flex={1} display="column.flex-start">
        <TopBar />
        <FiltersOn
          on={filtersOn}
          onClearFilters={onClearFilters}
          caption="Only showing results that contain or match filters"
        />
        <Div
          style={{
            overflowY: loading ? "hidden" : "auto",
            position: "relative"
          }}
          flex={1}
          id={TABLE_VIEW_ID}
        >
          <Table onCellClick={onCellClick} />
          {(loading && <LoadingOverlay />) || null}
        </Div>
        <PaginationBar />
      </Div>
      <MiniPassModal />
      <MiniSourceModal />
      <MiniUserModal />
    </Div>
  );
};

export default decorate(Layout);
