import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import AddUserToAccount from "components/Global/CRM/Users/Modals/AddUserToAccount";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import Team from "./Team";

@CSSModules(css)
class Settings extends Component {
  showAddTeamMemberModal = () => {
    this.props.showModal({
      content: (
        <AddUserToAccount
          userCredentials={this.props.user.credentials}
          hideModal={this.props.hideModal}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    return (
      <div>
        <div styleName="title">Account Settings</div>
        <Team onAddTeamMember={this.showAddTeamMemberModal} />
      </div>
    );
  }
}

Settings.propTypes = {
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default Settings;
