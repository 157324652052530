import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { noop } from "utils/General";

import {
  MediumOutlineButton,
  MediumFilledButton,
  AddIcon,
  DeleteIcon,
  AddCircleMultiIcon,
  CheckIcon,
  Div,
  MenuItem,
  PopMenu,
  PopMenuCard,
  SmallShadedBox,
  CloseIcon
} from "components/Base";

import { withProps } from "utils/General";

import { actions } from "ui-kit/Datagrid";
import * as TableSelectors from "ui-kit/Datagrid/selectors";

import { BUTTONS } from "ui-kit/Datagrid/constants";

import DeleteConfirmation from "ui-kit/DeleteConfirmation";

const ApproveIcon = withProps({
  color: "green6",
  pill: true,
  bg: "green1",
  OnlyIcon: CheckIcon
})(SmallShadedBox);

const DenyIcon = withProps({
  color: "red6",
  pill: true,
  bg: "red1",
  OnlyIcon: CloseIcon
})(SmallShadedBox);

const BulkMenuItem = withProps({
  py: 1,
  bb: 1,
  bc: "neutral3",
  width: 1
})(MenuItem);

const buttons = {
  [BUTTONS.REVIEW.id]: ({ handlers, selected = false }) => (
    <PopMenu
      key="actions"
      width={1}
      Label={({ onClick }) => (
        <MediumOutlineButton
          LeftIcon={withProps({ size: 16 })(CheckIcon)}
          mr={1}
          onClick={e => {
            e.preventDefault();
            onClick(e);
          }}
        >
          Review {selected ? "Selected" : "All Remaining"}
        </MediumOutlineButton>
      )}
    >
      {({ closeMenu }) => (
        <PopMenuCard position="top" align="left" zIndex={20}>
          <BulkMenuItem
            LeftIcon={ApproveIcon}
            onClick={e => {
              e.preventDefault();
              handlers.reviewRemaining({ response: "approve" });
              closeMenu();
            }}
          >
            Approve {selected ? "Selected" : "All Remaining"}
          </BulkMenuItem>
          <BulkMenuItem
            LeftIcon={DenyIcon}
            onClick={e => {
              e.preventDefault();
              handlers.reviewRemaining({ response: "reject" });
              closeMenu();
            }}
          >
            Deny {selected ? "Selected" : "All Remaining"}
          </BulkMenuItem>
        </PopMenuCard>
      )}
    </PopMenu>
  ),
  [BUTTONS.ADD_ROW.id]: ({ handlers, label = BUTTONS.ADD_ROW.label }) => (
    <MediumOutlineButton
      LeftIcon={withProps({ size: 16 })(AddIcon)}
      onClick={e => {
        e.preventDefault();
        handlers.addRow();
      }}
      mr={1}
    >
      {label}
    </MediumOutlineButton>
  ),
  [BUTTONS.DUPLICATE_ROW.id]: ({ handlers, multiple = false }) => (
    <MediumOutlineButton
      LeftIcon={withProps({ size: 16 })(AddCircleMultiIcon)}
      onClick={e => {
        e.preventDefault();
        handlers.duplicateRow();
      }}
      mr={1}
    >
      Duplicate {multiple ? "Rows" : "Row"}
    </MediumOutlineButton>
  ),
  [BUTTONS.REMOVE_ROW.id]: ({
    handlers,
    multiple = false,
    confirm = true,
    prompt = ""
  }) =>
    confirm ? (
      <DeleteConfirmation
        onConfirm={() => {
          handlers.removeRow();
        }}
        text={prompt}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
        data-cy="deleteRowGroup"
      >
        <MediumOutlineButton
          LeftIcon={withProps({ size: 16 })(DeleteIcon)}
          mr={1}
        >
          Remove {multiple ? "Rows" : "Row"}
        </MediumOutlineButton>
      </DeleteConfirmation>
    ) : (
      <MediumOutlineButton
        LeftIcon={withProps({ size: 16 })(DeleteIcon)}
        mr={1}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          handlers.removeRow();
        }}
      >
        Remove {multiple ? "Rows" : "Row"}
      </MediumOutlineButton>
    ),

  [BUTTONS.IMPORT.id]: ({ handlers, instanceId }) => (
    <MediumOutlineButton
      LeftIcon={withProps({ size: 16 })(AddIcon)}
      onClick={e => {
        e.preventDefault();
        handlers.importRows({ id: instanceId });
      }}
      mr={1}
    >
      Import
    </MediumOutlineButton>
  ),

  [BUTTONS.SAVE.id]: ({ handlers, instanceId, isValid }) => (
    <MediumFilledButton
      bg="altB5"
      onClick={e => {
        e.preventDefault();
        handlers.saveRequest({ id: instanceId });
      }}
      mr={1}
      tooltip={!isValid && "Please check the validation errors."}
    >
      Save and Close
    </MediumFilledButton>
  )
};

const decorate = connect(
  (state, props) => ({
    enabledButtons: TableSelectors.getEnabledButtons(state, props),
    multiple: TableSelectors.getIsMultipleSelection(state, props),
    selected: TableSelectors.getSelectionActive(state, props),
    isValid: TableSelectors.getIsValid(state, props)
  }),
  bindInstance({
    addRow: actions.addRowRequest,
    removeRow: actions.removeRowRequest,
    duplicateRow: actions.duplicateRowRequest,
    reviewRemaining: actions.reviewRemaining,
    requestValidate: actions.requestValidate,
    saveRequest: actions.saveRequest
  })
);

const Footer = ({
  enabledButtons = [],
  rightButtons = [],
  isValid = true,
  addRow = noop,
  removeRow = noop,
  duplicateRow = noop,
  saveRequest = noop,
  reviewRemaining,
  multiple = false,
  selected = false,
  handlers = {},
  instanceId,
  ...styleProps
}) => (
  <Div display="row.flex-start.center" height={32} {...styleProps}>
    <Div display="row.flex-start.center">
      {R.map(
        b =>
          React.createElement(buttons[b.id], {
            key: b.id,
            handlers: {
              ...handlers,
              addRow,
              removeRow,
              duplicateRow,
              reviewRemaining
            },
            multiple,
            selected,
            instanceId,
            ...b
          }),
        enabledButtons
      )}
    </Div>
    {R.map(
      b =>
        React.createElement(buttons[b.id], {
          key: b.id,
          handlers: {
            ...handlers,
            saveRequest
          },
          isValid,
          instanceId,
          ...b
        }),
      rightButtons
    )}
  </Div>
);
export default decorate(Footer);
