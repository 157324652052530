import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Records from "components/Event/Module/Records";
import { withRouter } from "react-router";
import can from "components/Global/Security";

import {
  addRecord,
  deleteRecord,
  updateType,
  getRecords
} from "redux/modules/modules/records/actions";
import { addValue } from "redux/modules/modules/values/actions";
import {
  deselectAllRows,
  clearSearch
} from "redux/modules/modules/records/settings/actions";
import { references } from "redux/modules/entityReferences/selectors";
import {
  searchTerm,
  selectedRows,
  tableLayoutOptions
} from "redux/modules/modules/records/settings/selectors";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { getViews, updateView } from "redux/modules/modules/views/actions";
import { userPermissionProfile } from "redux/modules/permissions/user-permission-profile/selectors";
import * as modalActions from "redux/modules/modal/actions";
import { getModule } from "redux/modules/modules/module/actions";

import * as ModuleRecordsSelectors from "redux/modules/modules/records/selectors";
import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";
import * as ModuleRecordTypesSelectors from "redux/modules/modules/recordTypes/selectors";
import * as ModuleSelectors from "redux/modules/modules/module/selectors";
import { views, activeView } from "redux/modules/modules/views/selectors";

import * as UserSelectors from "redux/modules/user/selectors";
import exportFactory from "components/Global/CRM/TableViews/HelperComponents/export-factory";
import searchFactory from "components/Global/CRM/TableViews/HelperComponents/SearchFactory";

const getContext = ({ context = {}, params = {} }) => ({
  ...params,
  ...context
});

function mapStateToProps(state, props) {
  const context = getContext(props);
  const { moduleId, eventId, viewId, groupId } = context;

  return {
    context,
    baseUrl: `/event/${context.eventId}/module/${context.moduleId}`,
    editFieldsUrl: `/event/${context.eventId}/settings/module/${
      context.moduleId
    }/fields`,
    moduleUrl: `/event/${context.eventId}/module/${context.moduleId}`,
    viewUrl: `/event/${context.eventId}/module/${context.moduleId}${
      context.view ? `/${context.view}` : ""
    }`,
    views: views(state, moduleId),
    activeView: activeView(state, moduleId, viewId, groupId),
    fields: props.fields || ModuleRecordsSelectors.fields(state, moduleId),
    forms: ModuleSelectors.forms(state, moduleId),
    isFetchingRecords: ModuleRecordsSelectors.fetching(state, moduleId),
    module: ModuleSelectors.moduleDetails(state, moduleId),
    moduleSettings: tableLayoutOptions(state, moduleId),
    preferences: ModuleRecordsSelectors.preferences(state, moduleId),
    readOnly: !can(
      userPermissionProfile(state, eventId, state.user.user.id)
    ).do(`${moduleId}_update`),
    recordTypes: ModuleRecordTypesSelectors.recordTypes(state, moduleId),
    references: references(state),
    searchTerm: searchTerm(state, moduleId),
    selectedRows: selectedRows(state, moduleId),
    showSidebarAction: true,
    sidebarControlId: "inventory",
    user: UserSelectors.user(state),
    baseUrl: `/event/${eventId}/inventory/catalog`,
    disableViews: true
  };
}

function mapDispatchToProps(dispatch, props) {
  const context = getContext(props);

  const eventOpts = {
    options: {
      eventId: context.eventId
    }
  };
  const actions = {
    getModule: moduleId => getModule({ moduleId, ...eventOpts }),
    getViews: moduleId => getViews({ moduleId, ...eventOpts }),
    getRecordTypes: moduleId => getRecordTypes({ moduleId, ...eventOpts }),
    getRecords: moduleId => getRecords({ moduleId, ...eventOpts })
  };

  return bindActionCreators(
    {
      ...modalActions,
      addRecord,
      addValue,
      clearSearch,
      deleteRecord,
      deselectAllRows: () => deselectAllRows(getContext(props).moduleId),
      getRecords,
      showSnackbar,
      updateType,
      updateView,
      ...actions
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(searchFactory(exportFactory(Records)))
);
