import {
  put,
  all,
  take,
  takeEvery,
  fork,
  select,
  call
} from "redux-saga/effects";
import * as R from "ramda";

import { FIELD_IDS, FORM_ID } from "./constants";

import { getCredentials } from "redux/modules/user/selectors";
import { orgDetails as getOrgDetails } from "redux/modules/organization/selectors";
import { getEditedField } from "./selectors";

import { actions, getters } from "./model";
import { registerError } from "redux/modules/errors/actions";
import { actions as FormActions } from "ui-kit/Form/model";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { fetchOrganization } from "redux/modules/organization/actions";

import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const orgDetails = yield select(getOrgDetails);

  return {
    credentials,
    orgDetails
  };
};

const init = function*() {
  try {
    yield put(actions.setLoading(true));
    const { credentials, orgDetails } = yield call(getParams);

    const { payload } = yield call(Api.getDetails, {
      credentials,
      orgId: orgDetails.id
    });

    yield put(actions.setInitialData(payload));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting details"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const save = function*() {
  try {
    const { credentials, orgDetails } = yield call(getParams);

    const data = yield select(getters.data);

    const toSave = {
      name: data.name,
      slug: data.slug,
      logoImageUrl: data.logo_image_url,
      backgroundImageUrl: data.background_image_url
    };

    yield call(Api.updateDetails, {
      credentials,
      orgId: orgDetails.id,
      data: toSave
    });

    yield put(showSnackbar({ message: "Details Updated" }));
    yield put(fetchOrganization(orgDetails.id));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while saving details"
        }
      ])
    );
  }
};

const fieldUpdate = function*({ id, fieldId }) {
  const newValue = yield select(getEditedField, {
    instanceId: id,
    fieldId
  });

  const processedValue = R.propOr(newValue, fieldId, {
    [FIELD_IDS.LOGO_IMAGE_URL]: R.path([0, "url"], newValue),
    [FIELD_IDS.BACKGROUND_IMAGE_URL]: R.path([0, "url"], newValue)
  });

  yield put(actions.updateField({ field: fieldId, value: processedValue }));
};

const watchUpdateFields = function*() {
  for (;;) {
    const {
      meta: { instanceId, fieldId }
    } = yield take(FormActions.setFieldValue.type);
    if (instanceId === FORM_ID) {
      yield call(fieldUpdate, {
        id: instanceId,
        fieldId
      });
    }
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchSave = function*() {
  yield takeEvery(actions.save.type, save);
};

const rootSaga = function*() {
  yield all([fork(watchInit), fork(watchSave), fork(watchUpdateFields)]);
};

export default rootSaga;
