import { createSelector } from "reselect";
import * as R from "ramda";
import moment from "moment";
import { capitalizeFirst, getNow as now, joinNotNullBy } from "utils/General";
import { IntegrationIconsById } from "components/Event/Settings/Credentials/constants";
import { getOrderDetailsBlockFieldIds } from "Submission/Editor/selectors";
import { formatAmountForEvent } from "redux/modules/event/selectors";

import { getters } from "Orders/OrderModal";
import { INTEGRATIONS } from "utils/integration-types";

import { CREDENTIAL_TYPE_ID, MEAL_TYPE_ID } from "utils/item-types";
import {
  EMPTY_INVOICE,
  ORDER_TYPES,
  TABS,
  MESSAGE_TABS
} from "Orders/OrderModal/constants";

const joinWithSpace = joinNotNullBy(" ");

export const getIsSubmission = createSelector(getters.order, order => {
  return R.or(
    R.isNil(R.prop("id", order)),
    R.not(R.isNil(R.prop("related_submission_id", order)))
  );
});

export const getOrderId = R.compose(R.prop("id"), getters.order);

export const getTabs = createSelector(
  getters.feed,
  getOrderId,
  getters.isPortalUser,
  (messages, orderId, isPortalUser) =>
    [
      {
        name: "Summary",
        id: TABS.SUMMARY
      },
      orderId && !isPortalUser
        ? {
            name: "Manage",
            id: TABS.MANAGE
          }
        : null,
      {
        name: "Messages",
        id: TABS.MESSAGES,
        count: messages.length,
        alert: R.any(R.prop("alert"), messages)
      }
    ].filter(tab => tab)
);

export const getAttendee = createSelector(getters.order, order => {
  if (order.order_type === ORDER_TYPES.GROUP) {
    return {
      id: order.customer_account_id,
      name: R.prop("customer_account_name", order) || "(No Group Name)",
      email: order.contact_email,
      phone: order.contact_phone,
      type: "account",
      secondaryLabel: order.customer_contact_id ? "Point of Contact" : null,
      secondaryId: order.customer_contact_id,
      secondaryType: "contact",
      secondaryName: order.customer_contact_id
        ? order.contact_first_name
          ? joinWithSpace([order.contact_first_name, order.contact_last_name])
          : "(No Name)"
        : null
    };
  }

  return {
    id: order.customer_contact_id,
    name: order.contact_first_name
      ? joinWithSpace([order.contact_first_name, order.contact_last_name])
      : "(No Name)",
    email: order.contact_email,
    phone: order.contact_phone,
    type: "contact",
    secondaryLabel: order.customer_account_id ? "Group Affiliation" : null,
    secondaryId: order.customer_account_id,
    secondaryType: "account",
    secondaryName: R.prop("customer_account_name", order)
  };
});

export const getOrderNumber = R.compose(R.propOr("", "number"), getters.order);

export const getParentOrderNumber = R.compose(
  R.prop("parent_order_number"),
  getters.order
);

export const getParentOrderId = R.compose(
  R.prop("parent_order_id"),
  getters.order
);

export const getOrderFields = createSelector(
  getters.order,
  getOrderDetailsBlockFieldIds,
  (order, fieldIds) => {
    return R.compose(
      R.filter(f => !fieldIds.includes(f.id)),
      R.propOr([], "order_fields")
    )(order);
  }
);

export const getRelatedModuleRecordId = R.compose(
  R.prop("related_module_record_id"),
  getters.order
);

export const getIsSyncing = R.compose(
  R.propOr(false, "is_syncing"),
  getters.order
);

export const getCanBeSynced = R.compose(
  R.propOr(false, "can_be_synced"),
  getters.order
);

export const getHasSyncIssue = R.compose(
  R.propOr(false, "has_sync_issues"),
  getters.order
);

export const getIntegrationOrders = R.compose(
  R.propOr([], "integration_orders"),
  getters.order
);

export const getOrderIdsWithSyncIssues = R.compose(
  R.propOr([], "order_ids_with_sync_issues"),
  getters.order
);

export const getOrderIds = R.compose(R.propOr([], "order_ids"), getters.order);

export const getInfo = createSelector(
  getters.order,
  (_, { getNow = now } = {}) => getNow, // inject the getNow function so this selector can be pure again
  (order, getNow) => {
    const components = [];

    if (order.source) {
      components.push({ title: "Source", infos: [order.source] });
    }

    if (order.created_at) {
      components.push({
        title: "Submitted",
        infos: [moment(new Date(order.created_at)).from(getNow())]
      });
    }

    if (order.create_by_user_first_name || order.create_by_user_last_name) {
      components.push({
        title: "Submitted By",
        infos: [
          order.create_by_user_first_name &&
          order.create_by_user_first_name.length
            ? joinWithSpace([
                order.create_by_user_first_name,
                order.create_by_user_last_name
              ])
            : order.create_by_user_email
        ]
      });
    }

    if (order.order_type === "group") {
      components.push({
        title: "Collect Names",
        tooltip:
          "If enabled, items can be assigned to additional attendees via the Assignment Manager",
        infos: [order.require_assignment ? "Yes" : "No"]
      });
    }

    if (order.fulfillment_method) {
      components.push({
        title: "Fulfillment Method",
        infos: [capitalizeFirst(order.fulfillment_method)]
      });
    }

    if (order.fulfillment_method === "mail" && order.shipping_address_line_1) {
      components.push({
        title: "Shipping Address",
        infos: R.filter(R.compose(R.not, R.isEmpty), [
          order.shipping_address_line_1,
          order.shipping_address_line_2,
          joinWithSpace([
            order.shipping_city,
            order.shipping_state,
            order.shipping_zip
          ]),
          order.shipping_country
        ])
      });
    }

    if (
      order.fulfillment_method === "pickup" &&
      (order.pickup_person_fname || order.pickup_person_lname)
    ) {
      components.push({
        title: "Pickup Person",
        infos: R.filter(R.compose(R.not, R.isEmpty), [
          joinWithSpace([order.pickup_person_fname, order.pickup_person_lname])
        ])
      });
    }

    return components;
  }
);

export const getInfoForSubmission = createSelector(getters.order, order => {
  const components = [];

  if (order.order_type === "group") {
    components.push({
      title: "Require Assignment",
      tooltip:
        "If enabled, items on this order can be assigned via the Guest List Manager",
      infos: [order.require_assignment ? "Yes" : "No"]
    });
  }

  if (order.fulfillment_method) {
    components.push({
      title: "Fulfillment Method",
      infos: [capitalizeFirst(order.fulfillment_method)]
    });
  }

  if (order.fulfillment_method === "mail" && order.shipping_address_line_1) {
    components.push({
      title: "Shipping Address",
      infos: R.filter(R.compose(R.not, R.isEmpty), [
        order.shipping_address_line_1,
        order.shipping_address_line_2,
        joinWithSpace([
          order.shipping_city,
          order.shipping_state,
          order.shipping_zip
        ]),
        order.shipping_country
      ])
    });
  }

  if (
    order.fulfillment_method === "pickup" &&
    (order.pickup_person_fname || order.pickup_person_lname)
  ) {
    components.push({
      title: "Pickup Person",
      infos: R.filter(R.compose(R.not, R.isEmpty), [
        joinWithSpace([order.pickup_person_fname, order.pickup_person_lname])
      ])
    });
  }

  return components;
});

export const getInvoiceStats = createSelector(
  getters.order,
  formatAmountForEvent,
  (order, formatAmountForEvent) =>
    R.ifElse(R.isEmpty, R.always(EMPTY_INVOICE), order => ({
      requested: formatAmountForEvent(order.cost_of_requested_items || 0),
      approved: formatAmountForEvent(order.cost_of_approved_items || 0),
      fees: formatAmountForEvent(order.costs_of_fees || 0),
      due: formatAmountForEvent(order.total_due_raw || 0),
      dueRaw: order.total_due_raw,
      applied: formatAmountForEvent(order.total_payments_applied_raw || 0),
      status: order.payment_status
    }))(order)
);

export const getTransactions = createSelector(
  getters.order,
  R.propOr([], "transactions")
);

export const getPaymentStatus = createSelector(
  getters.order,
  R.prop("payment_status")
);

export const getCanAcceptPayment = createSelector(getters.order, order => {
  return (
    (!["pending", "denied"].includes(order.approval_status) &&
      parseFloat(order.cost_of_approved_items) > 0) ||
    order.invoice_last_sent_at
  );
});

export const getShowAcceptPayment = createSelector(getters.order, order => {
  return (
    parseFloat(order.cost_of_requested_items) ||
    parseFloat(order.cost_of_approved_items) ||
    parseFloat(order.costs_of_fees) ||
    order.invoice_last_sent_at
  );
});

export const getShowTransactions = createSelector(getters.order, order =>
  order.transactions ? Boolean(order.transactions.length) : false
);

export const getCanSendConfirmation = createSelector(getters.order, order => {
  return (
    order.confirmation_last_sent_at ||
    !["pending", "denied"].includes(order.approval_status)
  );
});

export const getStatuses = R.compose(
  R.append(R.__, []), // eslint-disable-line no-underscore-dangle
  R.propOr("", "approval_status"),
  getters.order
);

export const getIntegrationStatuses = R.compose(
  R.propOr([], "integration_statuses"),
  getters.order
);

export const getConfirmation = createSelector(getters.order, order => ({
  lastSent: order.confirmation_last_sent_at,
  by: joinWithSpace([
    order.confirmation_last_sent_first_name,
    order.confirmation_last_sent_last_name
  ])
}));

export const getInvoice = createSelector(getters.order, order => ({
  lastSent: order.invoice_last_sent_at,
  by: joinWithSpace([
    order.invoice_last_sent_first_name,
    order.invoice_last_sent_last_name
  ])
}));

export const getPaymentsEnabled = createSelector(
  getters.order,
  R.prop("payments_enabled")
);

export const getLineItemIds = createSelector(
  getters.order,
  R.prop("line_item_ids")
);

export const getPendingLineItemIds = createSelector(
  getters.order,
  R.compose(
    R.flatten,
    R.map(R.prop("pending_line_item_ids")),
    R.propOr([], "items")
  )
);

export const getCredentialLineItemIds = createSelector(
  getters.order,
  R.pathOr([], ["line_item_ids_by_item_type", CREDENTIAL_TYPE_ID])
);

export const getMealLineItemIds = createSelector(
  getters.order,
  R.pathOr([], ["line_item_ids_by_item_type", MEAL_TYPE_ID])
);

export const getPasses = createSelector(
  getters.order,
  getters.isPortalUser,
  formatAmountForEvent,
  (order, isPortalUser, formatAmountForEvent) =>
    R.compose(
      R.map(item => ({
        passType: {
          name: item.name,
          subname: item.subname,
          color: item.bg_color,
          IntegrationIcon: isPortalUser
            ? null
            : IntegrationIconsById[item.provider_id]
        },
        summary: {
          requested: item.requested,
          rejected: item.rejected,
          approved: item.approved,
          price: item.price ? formatAmountForEvent(item.price || 0) : "-",
          issued: item.issued,
          received: item.fulfilled,
          total: item.price
            ? formatAmountForEvent(item.price ? item.price * item.approved : 0)
            : "-"
        }
      })),
      R.propOr([], "items")
    )(order)
);

export const getSubmissionId = createSelector(
  getters.order,
  R.propOr("", "related_submission_id")
);

const labelMap = {
  [ORDER_TYPES.INDIVIDUAL]: "Individual Order",
  [ORDER_TYPES.GROUP]: "Group Order"
};

export const getOrderLabel = createSelector(getters.order, order => {
  if (order.is_allocation) {
    return "Allocation";
  }

  return labelMap[order.order_type] || labelMap[ORDER_TYPES.GROUP];
});

const orderTitleMap = {
  [ORDER_TYPES.GROUP]: "GROUP CUSTOMER",
  [ORDER_TYPES.INDIVIDUAL]: "ATTENDEE / CUSTOMER NAME"
};

export const getOrderTitle = createSelector(
  R.compose(R.propOr("", "order_type"), getters.order),
  type => orderTitleMap[type] || orderTitleMap[ORDER_TYPES.GROUP]
);

// messages

const getSource = source =>
  R.propOr(
    "System",
    source
  )({
    GBS: "Elevate Tickets",
    EVENTBRITE: "Eventbrite",
    FGT: "FrontGate Tickets",
    INTELLITIX: "Intellitix"
  });

const getFooter = ({ type, userName, lastSent }) => {
  if (type === "admin") {
    return `You - ${lastSent}`;
  } else if (type === "alert") {
    return `${userName} - ${lastSent}`;
  }
  return `${userName} - ${lastSent}`;
};

export const getLastMessageId = createSelector(
  getters.feed,
  R.compose(R.prop("id"), R.last)
);

export const getMessages = createSelector(
  getters.feed,
  getters.selectedMessageFilter,
  (_, { getNow = now } = {}) => getNow, // inject the getNow function so this selector can be pure again
  getters.isPortalUser,
  (messages, filter, getNow, isPortalUser) =>
    R.compose(
      R.filter(
        filter ? R.compose(R.equals(filter), R.propOr("", "type")) : R.T
      ),
      R.filter(isPortalUser ? n => n.type === "message" : R.T),
      R.map(n => {
        let name = joinWithSpace([
          n.created_by_user_fname,
          n.created_by_user_lname
        ]);

        if (!name || !name.length) {
          name = n.created_by_source
            ? getSource(n.created_by_source)
            : "System";
        }

        return {
          id: n.id,
          avatar: {
            name,
            photoURL: n.created_by_user_photo_url
          },
          type: n.alert ? "alert" : n.item_type,
          position: n.item_type === "admin" ? "right" : "left",
          message: n.content,
          footer: getFooter({
            type: n.alert ? "alert" : n.item_type,
            sourceName: n.created_by_source,
            userName: name,
            lastSent: moment(n.created_at).from(getNow())
          })
        };
      })
    )(messages)
);

const messageTabs = [
  {
    title: "Message",
    id: MESSAGE_TABS.MESSAGE,
    color: "blue6",
    placeholder: "Send a message...",
    button: "Send"
  },
  {
    title: "Note",
    id: MESSAGE_TABS.NOTE,
    color: "yellow9",
    placeholder: "Add an internal note here that only admin users can view...",
    button: "Add Note"
  }
];

export const getMessagesTabs = createSelector(
  getters.selectedMessageTab,
  getters.isPortalUser,
  (selected, isPortalUser) =>
    R.compose(
      R.filter(isPortalUser ? t => t.id !== MESSAGE_TABS.NOTE : R.T),
      R.map(t => ({ ...t, selected: t.id === selected }))
    )(messageTabs)
);

export const getSelectedTab = createSelector(
  getters.selectedMessageTab,
  selected => R.find(t => t.id === selected)(messageTabs)
);

const getOrderUrl = ({ providerId, providerSettings, orderId }) => {
  switch (providerId) {
    case INTEGRATIONS.eventbrite.id:
      return `https://www.eventbrite.com/reports?eid=${providerSettings.eventbriteEventId}&rid=h&filterby=all,${orderId}`;
    case INTEGRATIONS.gingerbreadshed.id:
      return `https://dashboard.gingerbreadshed.com/pulse/orders/search/${orderId}`;
    case INTEGRATIONS.intellitix.id:
      return `https://cp.intellifest.com/cs/${providerSettings.intellitixProjectId}/transactionmanagement#transaction=${orderId}`;
    /*
      case INTEGRATIONS.frontgate.id:
      return `https://tak.frontgatetickets.com/tak/#/site/${
        providerSettings.frontGateEventId
      }/assist/order/${orderId}/`;
    */
    default:
      return null;
  }
};

export const getIntegrationsWithOrders = createSelector(
  getters.integrations,
  getIntegrationOrders,
  (_, { getNow = now } = {}) => getNow,
  (integrations, integrationOrders, getNow) => {
    if (integrations.length) {
      const ordersByIntId = R.groupBy(R.prop("integration_id"))(
        integrationOrders
      );
      return R.compose(
        R.map(integration => {
          return {
            ...integration,
            orderIds: R.compose(
              R.join(", "),
              R.map(R.prop("foreignOrderId"))
            )(integration.orders),
            lastSynced: R.path(["orders", 0, "lastSynced"])(integration)
          };
        }),
        R.map(integration => ({
          id: integration.provider.id,
          name: integration.provider.name,
          slug: integration.provider.slug,
          orders: R.compose(
            R.map(intOrder => ({
              providerId: intOrder.integration_id,
              foreignOrderId: intOrder.foreign_order_id,
              lastSynced: moment(
                intOrder.last_synced_at || intOrder.created_at
              ).from(getNow()),
              url: getOrderUrl({
                providerId: integration.provider.id,
                providerSettings: integration.settings,
                orderId: intOrder.foreign_order_id
              })
            })),
            R.propOr([], integration.provider.id)
          )(ordersByIntId)
        }))
      )(integrations);
    }

    return [];
  }
);
