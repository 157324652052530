import * as R from "ramda";
import { createSelector } from "reselect";

import { getters } from "Sponsors/SelfSetup";
import { getters as tabGetters } from "ui-kit/Tabs";
import { ITEM_FIELDS, TYPE_ORDER } from "Sponsors/SelfSetup/constants";
import * as FormSelectors from "ui-kit/Form/selectors";

export const getItem = (state, props) =>
  R.find(R.propEq("id", props.id), getters.items(state, props));

export const getItemTypes = createSelector(
  getters.types,
  R.compose(
    R.sortBy(R.prop("order")),
    R.map(type => ({
      id: type.id,
      name: type.name,
      order: TYPE_ORDER(type.id)
    }))
  )
);

export const getTabs = createSelector(
  getItemTypes,
  R.map(type => ({
    title: type.name,
    id: type.id
  }))
);

export const getItems = createSelector(
  getters.groups,
  getters.items,
  tabGetters.selectedTab,
  (groups, items, selectedTab) => {
    const groupsToPass = R.compose(
      R.sortBy(R.prop("order")),
      R.map(g => ({
        id: g.id,
        label: g.name,
        order: g.order,
        value: g.id
      })),
      R.filter(R.propEq("type_id", selectedTab))
    )(groups);

    return R.compose(
      R.indexBy(R.prop("id")),
      R.map(i => ({
        ...i,
        category: [
          {
            id: i.group_id,
            label: "group",
            value: i.group_id
          }
        ],
        groups: groupsToPass
      })),
      R.sortBy(i => `${i.group_order}_${i.group_id}_${i.order}`),
      R.filter(i => i.type_id === selectedTab && !i.is_package)
    )(items);
  }
);

export const getUpdatedItemFormFields = (state, props) =>
  FormSelectors.getFormFields(state, {
    ...props,
    instanceId: R.prop("id", props),
    fields: [ITEM_FIELDS.NAME, ITEM_FIELDS.CATEGORY, ITEM_FIELDS.DESCRIPTION]
  });

export const getUpdatedCategory = createSelector(
  getItem,
  (state, props) =>
    FormSelectors.getFieldValue(state, {
      instanceId: R.prop("id", props),
      fieldId: ITEM_FIELDS.CATEGORY
    }),
  (item, selectedCategory) => {
    if (!item) {
      return {};
    }

    return {
      id: item.id,
      groupId: selectedCategory
    };
  }
);

export const getCategoryToAdd = createSelector(
  getters.newCategoryName,
  tabGetters.selectedTab,
  (name, selectedTab) => ({
    name,
    typeId: selectedTab
  })
);
