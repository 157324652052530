import React from "react";

import { Div } from "components/Base";

import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";
import { connect } from "react-redux";
import { actions } from "../index";
import { getQuestions, getEventDetailsToUse } from "../selectors";
import resolveEditor from "components/Global/StandAloneEditors/utils/resolveEditor";
import Tooltip from "components/Global/Tooltip";

const decorate = connect(
  state => ({
    questions: getQuestions(state),
    eventDetails: getEventDetailsToUse(state)
  }),
  {
    updateQuestionValue: actions.updateQuestionValue
  }
);

const Question = ({
  variantId,
  question,
  eventDetails,
  updateQuestionValue
}) => {
  const Editor = resolveEditor(question);
  return (
    <Div display="column" pb={4} mb={4} bb={1} bc="neutral0">
      <Div display="row.flex-start.center">
        <Div fw={3} color="gray7">
          {question.name}
        </Div>
        {question.is_internal ? (
          <Tooltip tooltip="Internal Question" placement="top">
            <span
              className="material-icons"
              style={{
                fontSize: 16,
                cursor: "pointer",
                color: "#888",
                marginLeft: 8
              }}
            >
              visibility_off
            </span>
          </Tooltip>
        ) : null}
        {question.required ? (
          <Div fw={3} fs={1} color="green7" ml={3}>
            Required
          </Div>
        ) : null}
      </Div>
      {question.description && question.description.length ? (
        <Div fs={1} color="gray6" mb={1} width={1}>
          {question.description}
        </Div>
      ) : null}
      <Div mt={1}>
        <Editor
          onChange={val =>
            updateQuestionValue({ variantId, question, value: val })
          }
          value={question.value}
          rowMetaData={{
            meta: {
              eventDetails
            }
          }}
          {...resolveEditorProps(question, eventDetails)}
        />
      </Div>
    </Div>
  );
};

const QuestionList = ({
  variantId,
  questions,
  eventDetails,
  updateQuestionValue
}) => {
  return !questions.length ? null : (
    <Div display="column" width={1}>
      <Div fw={4} color="gray7" uppercase mb={3}>
        Details
      </Div>
      {questions.map(question => (
        <Question
          key={question.id}
          variantId={variantId}
          eventDetails={eventDetails}
          question={question}
          updateQuestionValue={updateQuestionValue}
        />
      ))}
    </Div>
  );
};

export default decorate(QuestionList);
