import React from "react";
import { connect } from "react-redux";

import { WIZARD_PAGE } from "Orders/ImportModal/constants";
import ImportPage from "Orders/ImportModal/View/ImportPage";
import ImportConfirmationPage from "Orders/ImportModal/View/ImportConfirmationPage";
import CustomizePage from "Orders/ImportModal/View/CustomizePage";
import SelectItems from "Orders/ImportModal/View/SelectItems";
import InvalidRows from "Orders/ImportModal/View/InvalidRows";

import { Div } from "components/Base";

import { getters } from "Orders/ImportModal";

const selectedView = {
  [WIZARD_PAGE.IMPORT]: ImportPage,
  [WIZARD_PAGE.CUSTOMIZE]: CustomizePage,
  [WIZARD_PAGE.BULK_IMPORT]: SelectItems,
  [WIZARD_PAGE.REVIEW]: InvalidRows,
  [WIZARD_PAGE.CONFIRM]: ImportConfirmationPage
};

const decorate = connect(state => ({
  page: getters.wizardPage(state)
}));

const Body = ({ page, ...styleProps }) =>
  React.createElement(selectedView[page] || Div, styleProps);

export default decorate(Body);
