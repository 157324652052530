import { connect } from "react-redux";
import Table3 from "./Table3";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { exportSpreadsheetArray } from "redux/modules/export/actions";
import { eventDetails } from "redux/modules/event/selectors";
import { references } from "redux/modules/entityReferences/selectors";
import { moduleDetails } from "redux/modules/modules/module/selectors";

function mapStateToProps(state, props) {
  return {
    module: moduleDetails(state, props.moduleId),
    eventDetails: eventDetails(state),
    references: references(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      exportSpreadsheetArray,
      showSnackbar
    },
    dispatch
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Table3));
