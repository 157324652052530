import React from "react";
import { ReplyIcon, Div } from "components/Base";

export const CustomTabButton = ({
  title,
  onClick,
  id,
  className,
  selected
}) => (
  <Div
    key={id}
    onClick={onClick}
    display="row.flex-start.center"
    style={{ marginLeft: "auto" }}
    className={className}
  >
    <ReplyIcon
      mr={2}
      size={23}
      style={{
        color: selected ? "#123059" : "#a0adc3"
      }}
    />
    <Div fw={4} style={{ fontSize: "23px" }}>
      {title}
    </Div>
  </Div>
);

export default CustomTabButton;
