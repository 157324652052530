import * as R from "ramda";
import { createSelector } from "reselect";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import { getters } from "Orders/ImportModal";
import { PERSON_SECTIONS, GROUP_SECTIONS, OTHER_SECTIONS } from "../constants";
import { MEAL_TYPE_ID } from "utils/item-types";

import {
  ORDER_TYPE,
  FIELD_IDS,
  VARIANTS_GROUP_ID
} from "Orders/ImportModal/constants";

const getSectionFields = fields =>
  Array.isArray(R.head(fields)) ? R.head(fields) : fields;

export const isContactsModule = createSelector(
  getters.moduleId,
  currentModuleId => R.equals(currentModuleId, STANDARD_MODULE_IDS.contacts.id)
);

export const isAccountsModule = createSelector(
  getters.moduleId,
  currentModuleId => R.equals(currentModuleId, STANDARD_MODULE_IDS.accounts.id)
);

export const isContactsOrAccountsModule = createSelector(
  isContactsModule,
  isAccountsModule,
  (isContactsModule, isAccountsModule) => isContactsModule || isAccountsModule
);

export const sectionsToShow = createSelector(
  isContactsOrAccountsModule,
  isContactsOrAccountsModule =>
    isContactsOrAccountsModule
      ? [...PERSON_SECTIONS, ...GROUP_SECTIONS]
      : [...PERSON_SECTIONS, ...GROUP_SECTIONS, ...OTHER_SECTIONS]
);

const getSections = createSelector(
  getters.fieldsMetadata,
  sectionsToShow,
  (fieldsMetadata, sections) =>
    R.compose(
      // @NOTE: Currently hiding type of order section
      // R.prepend(TypeOfOrderSection),
      R.sortBy(section => R.indexOf(section.name, sections)),
      R.filter(section => R.contains(section.name, sections)),
      R.flatten,
      R.map(R.propOr([], "fields_groups"))
    )(fieldsMetadata)
);

export const getCustomOrderType = createSelector(
  getters.selectedCustomFields,
  R.ifElse(
    R.contains(ORDER_TYPE.INDIVIDUAL),
    R.always(ORDER_TYPE.INDIVIDUAL),
    R.always(ORDER_TYPE.GROUP)
  )
);

const emptyEnabled = [];
export const getIniEnabledCustomFields = (state, props) => {
  const activeSelectFieldsSection = getters.activeSelectFieldsSection(
    state,
    props
  );

  return R.propOr(
    emptyEnabled,
    activeSelectFieldsSection,
    getters.enabledCustomFields(state, props)
  );
};

const getEnabledCustomFields = createSelector(
  getters.enabledCustomFields,
  R.compose(
    R.flatten,
    R.values
  )
);

export const getCustomEnabledSections = createSelector(
  getEnabledCustomFields,
  getters.selectedCustomFields,
  getters.selectedCustomSections,
  getSections,
  getCustomOrderType,
  (enabledFields, selectedFields, selectedSections, fieldGroups, orderType) =>
    R.compose(
      // @NOTE: Currently hiding order details
      R.filter(s => s.name !== "Order Details"),
      R.map(section => ({
        ...section,
        enabled: true,
        toggle: false,
        fields: R.map(field => {
          // @NOTE: This can be used for toggling account name
          const isRequired =
            field.id === FIELD_IDS.ACCOUNT_NAME
              ? orderType === ORDER_TYPE.GROUP
                ? true
                : false
              : field.required;

          return {
            ...field,
            name:
              field.source === "item-variant" &&
              field.settings.typeId === MEAL_TYPE_ID
                ? field.settings.variantDisplayName
                : field.name,
            required: isRequired,
            enabled: isRequired || R.contains(field.id, selectedFields)
          };
        }, R.filter(field => field.required || R.contains(field.id, enabledFields), getSectionFields(section.fields)))
      }))
    )(fieldGroups)
);

const groupFieldsBy = (key = "name", map = R.identity) =>
  R.compose(
    R.map(([id, val]) => ({
      id,
      name: R.path(["settings", `${key}Name`], R.head(val)),
      items: map(val)
    })),
    Object.entries,
    R.groupBy(R.path(["settings", `${key}Id`]))
  );

const groupByGroupName = groupFieldsBy("group");
const groupByTypeName = groupFieldsBy("type", groupByGroupName);

export const getActiveFieldsToSelect = createSelector(
  getters.activeSelectFieldsSection,
  getSections,
  (id, fieldGroups) => {
    const fields = getSectionFields(
      R.propOr(
        [],
        "fields",
        R.find(
          R.compose(
            R.equals(id),
            R.prop("id")
          ),
          fieldGroups
        )
      )
    );
    if (id === VARIANTS_GROUP_ID) {
      return groupByTypeName(fields);
    }
    return fields;
  }
);

export const getSectionFieldsToDownload = createSelector(
  getCustomEnabledSections,
  getters.selectedCustomFields,
  (selectedSections, selectedFields) => {
    return R.map(
      section => ({
        name: section.name,
        module_id: section.id,
        options: [],
        fields: section.enabled
          ? R.filter(
              field => field.required || R.contains(field.id, selectedFields),
              section.fields
            )
          : []
      }),
      selectedSections
    );
  }
);

const getFieldsToDownload = createSelector(
  getSectionFieldsToDownload,
  R.compose(
    R.flatten,
    R.map(R.propOr([], "fields"))
  )
);

export const getSelectedFieldsTitle = R.always(
  "Choose more fields to map CSV data into"
);

export const getCustomizeDownloadCaption = createSelector(
  getFieldsToDownload,
  fieldsToDownload =>
    `${R.length(
      R.without(
        [ORDER_TYPE.INDIVIDUAL, ORDER_TYPE.GROUP],
        R.map(R.prop("id"), fieldsToDownload)
      )
    )} columns will be included in the import template csv`
);
