import PropTypes from "prop-types";
import React from "react";

import CSSModules from "react-css-modules";
import styles from "./styles.scss";

const Panel = ({ style, children, ...other }) => (
  <div {...other} className={[style, styles.container].join(" ")}>
    {children}
  </div>
);

Panel.propTypes = {
  style: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default CSSModules(Panel, styles);
