import { get } from "lodash";

export const fetching = (state, moduleId) =>
  get(state, `modules.records.fetching[${moduleId}]`, false);
export const records = (state, moduleId) =>
  get(state, `modules.records.records[${moduleId}].records`, []);
export const fields = (state, moduleId) =>
  get(state, `modules.records.records[${moduleId}].fields.fields`, []);
export const fieldGroups = (state, moduleId) =>
  get(state, `modules.records.records[${moduleId}].fields.field_groups`, []);
export const preferences = (state, moduleId) =>
  get(state, `modules.records.records[${moduleId}].preferences`, {});
export const allRecords = state => get(state, "modules.records.records", {});
