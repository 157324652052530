import React from "react";
import { connect } from "react-redux";
import { Div, SuperFilledButton } from "components/Base";

import ImportCSVSummary from "ui-kit/ImportCSVSummary";

import { getConfirmationCounters } from "Modules/ImportModal/selectors";

const decorate = connect(state => ({
  counters: getConfirmationCounters(state)
}));

const Summary = ({ handlers: { hideModal }, counters }) => (
  <Div display="column" flex={1} height={1} width={1}>
    <Div height={450} display="column" bg="neutral0" p={6} width={1}>
      <ImportCSVSummary {...counters} />
    </Div>
    <Div
      display="row.flex-end.center"
      style={{
        flexShrink: 0
      }}
      bt={1}
      bc="neutral2"
      pt={3}
      px={3}
    >
      <SuperFilledButton ml={3} width={297} bg="purple8" onClick={hideModal}>
        Done
      </SuperFilledButton>
    </Div>
  </Div>
);

export default decorate(Summary);
