import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import Module from "./Module";
import Schedule from "./Schedule";
import Account from "./Account";
import Contact from "./Contact";
import DocumentRequest from "./Document";
import CatalogCategory from "./CatalogCategory";
import CatalogItem from "./CatalogItem";

export default function(moduleId, typeId) {
  if (typeId === STANDARD_MODULE_IDS.schedules.id) {
    return Schedule;
  }

  switch (moduleId) {
    case STANDARD_MODULE_IDS.files.id:
      return DocumentRequest;
    case STANDARD_MODULE_IDS.catalogCategories.id:
      return CatalogCategory;
    case STANDARD_MODULE_IDS.catalogItems.id:
      return CatalogItem;
    case STANDARD_MODULE_IDS.accounts.id:
      return Account;
    case STANDARD_MODULE_IDS.contacts.id:
      return Contact;
    default:
      return Module;
  }
}
