import React from "react";
import * as R from "ramda";
import { noop } from "utils/General";

import { Div } from "components/Base";
import CollapsablePanel from "ui-kit/CollapsiblePanel";

import PassInfo from "./PassInfo";

const Passes = ({ passes = [], onRemove = noop, ...styleProps }) => (
  <Div flex={1} display="column.flex-start.stretch" {...styleProps}>
    {R.map(
      pass =>
        pass.children.length > 0 ? (
          <CollapsablePanel
            heading={<PassInfo pass={pass} />}
            collapsedDefault
            bg="transparent"
            expandedBg="neutral0"
            pl={2}
          >
            {R.map(
              pass => (
                <PassInfo pass={pass} onRemove={onRemove} />
              ),
              pass.children
            )}
          </CollapsablePanel>
        ) : (
          <PassInfo pass={pass} pl={2} mt={1} onRemove={onRemove} />
        ),
      passes
    )}
  </Div>
);

export default Passes;
