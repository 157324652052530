import React from "react";
import * as R from "ramda";
import { Modal, Div, Text5, CloseIcon } from "components/Base";
import { noop } from "utils/General";
import MediaQuery from "react-responsive";

const Small = props => <MediaQuery {...props} maxWidth={props.maxWidth} />;
const Medium = props => (
  <MediaQuery {...props} minWidth={props.minWidth} maxWidth={props.maxWidth} />
);
const Big = props => <MediaQuery {...props} minWidth={props.minWidth} />;

const Header = ({ title = "", hideModal = noop }) => (
  <Div
    style={{
      flexShrink: 0
    }}
    display="row.space-between.center"
    bg="purple8"
    px={8}
    py={4}
    brt={1}
  >
    {typeof title === "string" ? (
      <Text5 bold color="white">
        {title}
      </Text5>
    ) : (
      title
    )}
    {hideModal !== noop && (
      <Div onClick={hideModal}>
        <CloseIcon color="white" sizeWFS={6} />
      </Div>
    )}
  </Div>
);

const MiniModalWrapperResponsive = ({
  children,
  showModal = false,
  hideModal = noop,
  title = "",
  isPortal = true,
  style = {},
  showHeader = true,
  stopPropagation = false,
  responsiveWidth = { small: 360, medium: 500, big: 600 },
  mediaMaxWidth = { small: 500, medium: 768 },
  ...styleProps
}) => (
  <Modal
    isPortal={isPortal}
    background="translucent"
    isModalShowing={showModal}
  >
    <Div display="column.center.center" width={1}>
      <Small maxWidth={R.propOr(500, "small", mediaMaxWidth)}>
        <Div
          height={500}
          bra={1}
          style={{ ...style, overflow: "hidden" }}
          display="column.flex-start.stretch"
          {...styleProps}
          width={R.propOr(360, "small", responsiveWidth)}
          onMouseDown={e => {
            if (stopPropagation) {
              e.stopPropagation();
            }
          }}
        >
          {showHeader && <Header hideModal={hideModal} title={title} />}
          {children}
        </Div>
      </Small>
      <Medium
        minWidth={R.propOr(500, "small", mediaMaxWidth) + 1}
        maxWidth={R.propOr(500, "medium", mediaMaxWidth)}
      >
        <Div
          height={500}
          bra={1}
          style={{ ...style, overflow: "hidden" }}
          display="column.flex-start.stretch"
          {...styleProps}
          width={R.propOr(360, "medium", responsiveWidth)}
          onMouseDown={e => {
            if (stopPropagation) {
              e.stopPropagation();
            }
          }}
        >
          {showHeader && <Header hideModal={hideModal} title={title} />}
          {children}
        </Div>
      </Medium>
      <Big minWidth={R.propOr(500, "medium", mediaMaxWidth) + 1}>
        <Div
          height={500}
          bra={1}
          style={{ ...style, overflow: "hidden" }}
          display="column.flex-start.stretch"
          {...styleProps}
          width={R.propOr(360, "big", responsiveWidth)}
          onMouseDown={e => {
            if (stopPropagation) {
              e.stopPropagation();
            }
          }}
        >
          {showHeader && <Header hideModal={hideModal} title={title} />}
          {children}
        </Div>
      </Big>
    </Div>
  </Modal>
);

export default MiniModalWrapperResponsive;
