import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import { noop } from "utils/General";

import { actions } from "Sponsors/SelfSetup";
import { STEPS } from "Sponsors/SelfSetup/constants";

import { Div, Text3, Span } from "components/Base";
import { PageTitle, StepDescription, FAQsPanel } from "Sponsors/Common/View";

import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";

import ImagesSlideshow from "./ImagesSlideshow";
import SalesDeck from "./SalesDeck";
import Venue from "./Venue";

const faqs = [
  {
    id: "dynamic floormap",
    title: `What if I manage my floormap in a different system? Can I embed it or integrate?`,
    text: (
      <span>
        If you are interested in using your dynamic floormap with Lennd, please{" "}
        <Span
          underline
          onClick={() => {
            window.Intercom("show");
          }}
        >
          contact us
        </Span>{" "}
        to discuss further.
      </span>
    ),
    isLast: true
  }
];

const decorate = R.compose(
  WithThemeConsumer({ variant: VARIANTS.BACKGROUND }),
  connect(
    null,
    {
      init: actions.init
    }
  )
);

const FloorMapPage = ({ init = noop, Th = defaultTh, ...styleProps }) => {
  useEffect(() => {
    init(STEPS.SPACES);
  }, []);

  return (
    <Div
      {...Th([], {
        display: "column",
        ...styleProps
      })}
    >
      <PageTitle>Add Photos & Floor Map</PageTitle>
      <StepDescription mt={4}>
        <Text3 {...Th(["color"], { ml: 2 })}>
          Upload materials such as past event photos, your sales deck and an
          image of your floor map to help potential exhibitors and sponsors
          learn more about your event.
        </Text3>
      </StepDescription>
      <ImagesSlideshow />
      <SalesDeck />
      <Venue />
      <FAQsPanel questions={faqs} mt={6} />
    </Div>
  );
};

export default decorate(FloorMapPage);
