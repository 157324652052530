import Immutable from "immutable";
import { combineReducers } from "redux";
import { ERROR } from "redux/modules/errors/constants";
import { REQUEST, RECEIVE, CREATE, CREATE_COMPLETE } from "./constants";
import {
  RESYNC,
  RESYNC_COMPLETE
} from "redux/modules/integrations/eventbrite/orders/resync/constants";

// @NOTE: State is an object keyed by the Credential Order ID
const orders = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload.orders;
    case RESYNC:
    case CREATE:
      return Immutable.fromJS(state)
        .setIn([action.payload.credentialOrderId, "syncing"], true)
        .toJS();
    case RESYNC_COMPLETE:
    case CREATE_COMPLETE:
      return Immutable.fromJS(state)
        .setIn([action.payload.credentialOrderId, "syncing"], false)
        .setIn([action.payload.credentialOrderId, "last_synced_at"], new Date())
        .toJS();
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case ERROR:
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  orders,
  fetching
});
