import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Title = ({ children, style, className }) => (
  <div styleName="title" style={style} className={className}>
    {children}
  </div>
);

export default CSSModules(Title, css);
