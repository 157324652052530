import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import { getters } from "EventLight/Common/Manage";

import { ContactTypes } from "EventLight/Common/Manage/View/Common";

import { ButtonContainer } from "ui-kit/FilterControls/View";
import { Text2, Text5, Div } from "components/Base";

const decorate = connect((state, props) => ({
  summary: getters.summary(state, props)
}));

const Summary = ({ summary }) => (
  <ButtonContainer
    mt={2}
    display="column.flex-start.flex-start"
    pl={3}
    bg="transparent"
    style={{
      cursor: "default"
    }}
  >
    {summary.photoUrl ? (
      <Div
        mt={1}
        mb={3}
        width={1}
        height={100}
        style={{
          backgroundImage: `url(${summary.photoUrl})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat"
        }}
      />
    ) : null}
    <Div display="column.flex-start.flex-start">
      <Text5 bold>{summary.name}</Text5>
      {summary.moduleType ? <Text2 bold>{summary.moduleType}</Text2> : null}
    </Div>
    {R.length(summary.contacts) > 0 ? (
      <ContactTypes contacts={summary.contacts} my={2} />
    ) : null}
  </ButtonContainer>
);

export default decorate(Summary);
