import { createModule } from "redux-mvc";

import handlers from "./handlers";

const createdModule = createModule(handlers);

const { actions, getters } = createdModule;

export { actions, getters };

export default createdModule;
