import React from "react";
import { Div } from "components/Base";

const View = ({ searchTerm = "" }) => (
  <Div fw={3} display="row.center.center" mt={10}>
    No items {searchTerm.length ? "matched your search." : "available"}
  </Div>
);

export default View;
