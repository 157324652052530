import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import { noop } from "utils/General";

import { actions } from "../index";

import CSSModules from "react-css-modules";
import css from "./styles.scss";
import {
  MediumFilledButton,
  Div,
  CloseIcon,
  AddIcon,
  Text2
} from "components/Base";
import FilterPicker from "components/Global/Module/Sidebar/Popovers/Filter";

import Tooltip from "components/Global/Tooltip";
import MenuPopover from "ui-kit/MenuPopover";

import { getFieldFilters, getSelectedFieldFilters } from "../selectors";

const decorate = R.compose(
  connect(
    state => ({
      fields: getFieldFilters(state),
      filters: getSelectedFieldFilters(state)
    }),
    {
      setFilters: actions.setSelectedFieldFilters,
      removeFilter: actions.removeSelectedFieldFilter
    }
  ),
  CSSModules(css)
);

const FilterButton = ({
  fields,
  setFilters,
  filters,
  removeFilter,
  ...styleProps
}) => {
  return (
    <MenuPopover
      Label={
        <MediumFilledButton mr={2} styleName="actionButton" {...styleProps}>
          <span className="material-icons-outlined">filter_list</span>
          Filter
          {filters && filters.filters && filters.filters.length
            ? ` (${filters.filters.length})`
            : null}
        </MediumFilledButton>
      }
    >
      {({ closePopover }) => (
        <Div
          display="column.flex-start.flex-start"
          style={{
            width: "200px",
            padding: "10px"
          }}
        >
          <FilterPicker filters={filters} onChange={setFilters} fields={fields}>
            <Div
              display="row"
              onClick={noop}
              mb={filters && filters.filters && filters.filters.length ? 2 : 0}
            >
              <Text2 bold color="primary7">
                Add Filter
                {filters && filters.filters && filters.filters.length
                  ? ` (${filters.filters.length})`
                  : null}
              </Text2>
              <AddIcon color="primary7" ml={1} />
            </Div>
          </FilterPicker>

          {filters && filters.filters && filters.filters.length ? (
            <Div display="column" width={1}>
              {filters.filters.map((filter, idx) => {
                const title = `"${R.compose(
                  R.prop("name"),
                  R.find(R.propEq("id", filter.fieldId))
                )(fields)}" ${filter.label}`;
                return (
                  <Tooltip tooltip={title}>
                    <Div
                      display="row.space-between.center"
                      width={1}
                      fs={1}
                      mb={1}
                      bra={1}
                      p={2}
                      style={{
                        backgroundColor: "#eee"
                      }}
                    >
                      <Div truncate color="#222" fs={1}>
                        {title}
                      </Div>
                      <CloseIcon
                        size={15}
                        ml={1}
                        color={{
                          default: "gray5",
                          hover: "gray7"
                        }}
                        onClick={() => removeFilter(idx)}
                      />
                    </Div>
                  </Tooltip>
                );
              })}
            </Div>
          ) : null}
        </Div>
      )}
    </MenuPopover>
  );
};

export default decorate(FilterButton);
