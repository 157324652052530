import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import * as R from "ramda";
import Controller from "./Controller";
import {
  addPerson,
  removePerson,
  updatePersonValue,
  updatePersonItemBlockValue,
  updatePersonRole
} from "redux/modules/formsV2/people-blocks/people/actions";
import { getSubmission } from "redux/modules/formsV2/submission/actions";
import { showModal } from "redux/modules/modal/actions";
import { updateRelationship } from "redux/modules/accounts/contactRelationship/actions";
import * as STANDARD_MODULE_FIELD_IDS from "utils/standard-module-field-ids";

import { actions } from "Submission/PeopleBlockTable";

const mapWithIndex = R.addIndex(R.map);

function mapStateToProps(state, props) {
  // resolve view: card, table, all
  let settingsView = R.pathOr("all", [
    "people_block",
    "block",
    "collection_mode"
  ])(props.field);
  if (settingsView === "form") {
    settingsView = "card";
  }

  let view = settingsView;

  // if previewing or editing, add default row
  let defaultRows = [];
  if (props.isEditing || props.isPreviewing) {
    defaultRows = [
      {
        id: "default",
        values: {}
      }
    ];
  }

  const getValue = function(record, fieldId, fieldType) {
    if (fieldType === "item-block") {
      const block = props.field.people_block.block.item_blocks.find(
        ib => ib.id === fieldId
      );
      return {
        type: "item-block",
        value: R.compose(
          R.sortBy(R.prop("order")),
          groupedLines =>
            R.map(lineId => {
              return {
                id: groupedLines[lineId][0].variant.id,
                value: groupedLines[lineId][0].variant.item.name,
                quantity: R.sum(
                  R.map(R.prop("quantity"))(groupedLines[lineId])
                ),
                price: R.path([0, "price"])(groupedLines[lineId]),
                color: groupedLines[lineId][0].variant.item.background_color,
                order: R.compose(
                  R.prop("order"),
                  R.find(
                    R.propEq("variant_id", groupedLines[lineId][0].variant.id)
                  )
                )(block.variants)
              };
            })(R.keys(groupedLines)),
          R.groupBy(
            li => `${R.path(["variant", "id"])(li)}_${R.path(["price"])(li)}`
          ),
          R.filter(li => {
            return R.contains(R.path(["variant", "id"])(li))(
              R.map(R.prop("variant_id"))(block.variants)
            );
          })
        )(R.pathOr([], ["related_order", "line_items"])(record))
      };
    }

    return record.values[fieldId];
  };

  const rows = R.pathOr(defaultRows, ["block", "people"])(props);

  // determine if can add additional rows
  const settingsAllowNewRows = R.pathOr(false, [
    "people_block",
    "block",
    "allow_multiple"
  ])(props.field);
  let allowNewRows = settingsAllowNewRows;

  const maxAmountOfRows = R.pathOr(0, ["people_block", "block", "max_amount"])(
    props.field
  );
  if (allowNewRows && maxAmountOfRows && rows.length >= maxAmountOfRows) {
    allowNewRows = false;
  } else if (!allowNewRows && rows.length === 0) {
    allowNewRows = true;
  }

  const requiredFields = R.pathOr(
    [],
    ["people_block", "block", "required_fields"],
    props.field
  );
  const fieldOrder = R.pathOr(
    {},
    ["people_block", "block", "field_order"],
    props.field
  );

  return {
    rows,
    fields: R.compose(
      R.sortBy(R.prop("order")),
      R.map(f => ({
        ...f,
        is_required:
          R.contains(f.id, requiredFields) ||
          [
            STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME,
            STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME
          ].includes(f.id)
      })),
      field => [
        ...mapWithIndex((field, idx) => ({
          id: field.id,
          name: field.name,
          type: field.type,
          settings: field.settings,
          order:
            typeof fieldOrder[field.id] === undefined
              ? -5 + idx
              : fieldOrder[field.id]
        }))(R.pathOr([], ["people_block", "block", "fields"])(field)),
        ...(R.pathOr([], ["people_block", "block", "collect_role"])(field) &&
        R.pathOr([], ["people_block", "block", "collect_role_method"])(
          field
        ) === "collect_for_each"
          ? [
              {
                id: "role",
                name:
                  props.field.people_block.block.role_field_alias &&
                  props.field.people_block.block.role_field_alias.length
                    ? props.field.people_block.block.role_field_alias
                    : "Role",
                type: "text",
                settings: {},
                order: fieldOrder.role || 99
              }
            ]
          : []),
        ...mapWithIndex((block, idx) => ({
          id: block.id,
          name: block.name,
          type: "item-block",
          settings: block,
          order:
            typeof fieldOrder[block.id] === undefined
              ? 100 + idx
              : fieldOrder[block.id]
        }))(R.pathOr([], ["people_block", "block", "item_blocks"])(field))
      ]
    )(props.field),
    rowLabels: {
      singular:
        R.pathOr(
          "person",
          ["people_block", "block", "row_label"],
          props.field
        ) || "person",
      plural: "people"
    },
    peopleBlockId: R.path(["people_block", "block", "id"])(props.field),
    view,
    getValue,
    allowNewRows,
    maxAmountOfRows,
    isLenndAdmin: props.location.query.admin,
    settingsView,
    settingsAllowNewRows
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      getSubmission,
      showModal,
      updateRelationship,
      updatePersonRole,
      saveValue: (contactId, fieldId, value) =>
        updatePersonValue({
          blockId: props.field.people_block.block.id,
          blockFieldId: props.field.id,
          contactId,
          fieldId,
          value
        }),
      saveRole: (contactId, fieldId, value) =>
        updatePersonRole({
          eventId: props.eventDetails.id,
          submissionId: props.submissionId,
          blockId: props.field.people_block.block.id,
          blockFieldId: props.field.id,
          contactId,
          fieldId,
          value
        }),
      saveItemBlockValue: (contactId, itemBlockId, itemBlock, value) => {
        const row = props.block.people.find(r => r.id === contactId);

        const items = value.value.map(({ id, quantity, price }) => ({
          id,
          quantity,
          price
          // variant: itemBlock.variants.find(v => v.variant_id === id)
        }));
        const removedItems = value.removedItems.map(id => ({
          id,
          quantity: 0
          // variant: itemBlock.variants.find(v => v.variant_id === id)
        }));

        // @TODO: Add limit

        return updatePersonItemBlockValue({
          blockFieldId: props.field.id,
          contactId,
          orderId: row.related_order.id,
          actions: [...items, ...removedItems].map(
            ({ id, price = 0, quantity }) => ({
              action: "set-quantity",
              set: {
                orderId: row.related_order.id,
                variantId: id,
                price
              },
              by: quantity,
              where: {
                eventId: props.eventId,
                orderId: row.related_order.id,
                variantId: id,
                includeDraftOrders: true
              }
            })
          )
        });
      },
      addRow: contactId =>
        addPerson({
          submissionRecordId: props.submissionRecordId,
          blockId: props.field.people_block.block.id,
          blockFieldId: props.field.id,
          contactId
        }),
      removeRow: contactId =>
        removePerson({
          submissionRecordId: props.submissionRecordId,
          blockId: props.field.people_block.block.id,
          blockFieldId: props.field.id,
          contactId
        }),
      addTableId: actions.addTableId
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
