import * as R from "ramda";
import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import PillButton from "components/Global/CRM/PillButton";
import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";
import IconButton from "material-ui/IconButton";
import Menu from "./Menu";
import Avatar from "components/Atoms/Avatar";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import CanUserDo from "components/Global/Security/CanUserDo";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

import { withRouter } from "react-router";

const getPersonLink = ({
  location,
  params,
  basePath,
  contactId,
  accountId
}) => {
  const isEventLight = R.contains("event-light", location.pathname);
  if (isEventLight) {
    return `/event-light/${params.eventId}/contact/${contactId}?viaAccount=${accountId}`;
  } else {
    return `${basePath}/contact/${contactId}?viaAccount=${accountId}`;
  }
};

@CSSModules(css)
class AccountContact extends Component {
  @autobind
  removePrimary() {
    this.props.onRemove({
      accountId: this.props.accountId,
      isPrimary: false,
      relationshipId: this.props.id
    });
  }

  render() {
    const {
      fname,
      lname,
      email,
      phone,
      basePath,
      accountId,
      readOnly,
      contact_id: contactId,
      role,
      location,
      params
    } = this.props;

    return (
      <div styleName="contactContainer">
        {!readOnly && (
          <div styleName="iconWrapper">
            <Menu onRemovePrimary={this.removePrimary}>
              <IconButton>
                <MoreVertIcon />
              </IconButton>
            </Menu>
          </div>
        )}
        <div styleName="contactIconWrapper">
          <Avatar
            size={28}
            style={{
              borderRadius: 5,
              marginRight: 9,
              backgroundColor: "#E2E2E2"
            }}
            textStyle={{
              color: "#FFFFFF",
              fontWeight: "bold"
            }}
            userId={contactId}
            imageUrl={null}
            firstName={fname || email || ""}
            lastName={lname || ""}
          />
        </div>
        <div>
          <div styleName="name">{[fname, lname].join(" ")}</div>
          <div styleName="detail">{email}</div>
          <div styleName="detail">{phone}</div>
          {role ? <div styleName="roleBadge">{role}</div> : ""}
        </div>
        <CanUserDo action={`${STANDARD_MODULES.contacts.id}_read`}>
          <div styleName="preview">
            <PillButton
              title="Preview"
              linkTo={getPersonLink({
                basePath,
                location,
                params,
                contactId,
                accountId
              })}
            />
          </div>
        </CanUserDo>
      </div>
    );
  }
}

AccountContact.propTypes = {
  fname: PropTypes.string.isRequired,
  lname: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string,
  contact_id: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired
};

export default withRouter(AccountContact);
