import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import { Div, Text5, Text3, Text2, Text1, Dropdown } from "components/Base";
import { RadioButton } from "material-ui/RadioButton";
import { DROP_USERS_OPTIONS } from "../constants";

const decorate = connect(
  state => ({
    showLogin: getters.showLogin(state),
    dropNewUsers: getters.dropNewUsers(state),
    contactTypes: getters.contactTypes(state),
    contactRecordTypeId: getters.contactRecordTypeId(state)
  }),
  {
    toogleShowLogin: actions.toogleShowLogin,
    setDropNewUsers: actions.setDropNewUsers,
    selectTypeId: actions.setContactRecordTypeId
  }
);

const ReviewSettings = ({
  dropNewUsers,
  setDropNewUsers,
  contactTypes,
  contactRecordTypeId,
  selectTypeId
}) => (
  <Div py={5} px={8}>
    <Text5 color="black" bold>
      Form Settings
    </Text5>

    {/*
    <Div bra={1} bg="white" shadow={1} p={3} px={5} mt={3}>
      <Div display="row.space-between.flex-start">
        <Div>
          <Text3 bold mb={1}>
            Show Login Ability
          </Text3>
          <Div width={481}>
            <Text1>
              For users who have previously registered their group with your
              event organization - this login feature allows them to use their
              previous email & password to bring their group over for this
              event.
            </Text1>
          </Div>
        </Div>
        <Div display="row.flex-start.center">
          <Text2 mr={2} bold color={showLogin ? "altA7" : "neutral7"}>
            {showLogin ? "Enabled" : "Disabled"}
          </Text2>
          <SmallToggle active={showLogin} onClick={toogleShowLogin} />
        </Div>
      </Div>
    </Div>
    */}

    <Div
      display="row.space-between.flex-start"
      mt={3}
      bra={1}
      bg="white"
      shadow={1}
      p={3}
      px={5}
    >
      <Div>
        <Text3 bold mb={1}>
          Drop new users on:
        </Text3>
        <Div width={481}>
          <Text1>
            Set which portal page new users should see first upon register. For
            example, if your primary goal is to collect applicant for
            credentialing purposes - the People section might be a better
            landing page.
          </Text1>
        </Div>
      </Div>
      <Div>
        <RadioButton
          checked={dropNewUsers === DROP_USERS_OPTIONS.portalHome}
          onClick={() => setDropNewUsers(DROP_USERS_OPTIONS.portalHome)}
          label={
            <Div display="row.flex-start.center">
              <Text2
                bold
                color={
                  dropNewUsers === DROP_USERS_OPTIONS.portalHome
                    ? "primary7"
                    : "black"
                }
              >
                Portal Home
              </Text2>
            </Div>
          }
          iconStyle={{ marginRight: 10 }}
          labelStyle={{ width: "100%" }}
        />
        <RadioButton
          checked={dropNewUsers === DROP_USERS_OPTIONS.peopleSection}
          onClick={() => setDropNewUsers(DROP_USERS_OPTIONS.peopleSection)}
          label={
            <Text2
              bold
              color={
                dropNewUsers === DROP_USERS_OPTIONS.peopleSection
                  ? "primary7"
                  : "black"
              }
            >
              People Section
            </Text2>
          }
          iconStyle={{ marginRight: 10 }}
          labelStyle={{ width: "100%" }}
        />
      </Div>
    </Div>
    {contactTypes && contactTypes.length > 1 ? (
      <Div bra={1} bg="white" shadow={1} p={3} px={5} mt={3}>
        <Div display="row.space-between.flex-start">
          <Div>
            <Text3 bold mb={1}>
              Person Type
            </Text3>
            <Div width={481}>
              <Text1>
                Select the record type that the primary contact should be
                created as.
              </Text1>
            </Div>
          </Div>
          <Div display="row.flex-start.center">
            <Dropdown
              usePortal
              options={[...contactTypes, { value: null, label: "Unassigned" }]}
              selected={contactRecordTypeId}
              onChange={({ value }) => selectTypeId(value)}
              placeholder="Unassigned"
              width="160px"
            />
          </Div>
        </Div>
      </Div>
    ) : null}
  </Div>
);

export default decorate(ReviewSettings);
