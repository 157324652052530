import { call, select, takeEvery, put, fork, all } from "redux-saga/effects";
import uuid from "node-uuid";
import Api from "./api";
import { BEGIN, COMMIT, REVERT } from "redux-optimist";

import { actions, getters } from "Items/QuestionsDetails";

import { getCredentials } from "redux/modules/user/selectors";
import { getEditingValue } from "Items/QuestionsDetails/selectors";

const fetchQuestions = function*({ payload: lineItemIds }) {
  const credentials = yield select(getCredentials);
  const { payload } = yield call(Api.get, {
    credentials,
    lineItemIds
  });
  yield put(actions.questionsResponse(payload));
};

const watchFetchQuestions = function*() {
  yield takeEvery(actions.questionsRequest.type, fetchQuestions);
};

const saveQuestion = function*({ payload: id }) {
  const transactionId = yield call([uuid, uuid.v4]);
  yield put(
    actions.save(id, {
      optimist: { type: BEGIN, id: transactionId }
    })
  );

  const credentials = yield select(getCredentials);
  const lineItemIds = yield select(getters.lineItemIds);
  const value = yield select(getEditingValue, { id });

  try {
    yield call(Api.put, {
      credentials,
      lineItemIds,
      questions: {
        [id]: value
      }
    });
    yield all([
      put(actions.setModified(true)),
      put(
        actions.saveResponse(id, {
          optimist: { type: COMMIT, id: transactionId }
        })
      )
    ]);
  } catch (e) {
    yield put(
      actions.saveResponse(
        id,
        {
          optimist: { type: REVERT, id: transactionId }
        },
        true
      )
    );
  }
};

const watchSaveQuestion = function*() {
  yield takeEvery(actions.saveRequest.type, saveQuestion);
};

const rootSaga = function*() {
  yield all([fork(watchFetchQuestions), fork(watchSaveQuestion)]);
};

export default rootSaga;
