import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { find } from "lodash";
import autobind from "autobind-decorator";

import DocumentTitle from "components/Global/DocumentTitle";
import Header from "components/Atoms/Header";
import Tabs from "components/Global-2016/Tabs";

@CSSModules(css)
class FormsHandler extends Component {
  @autobind
  getSelectedTab() {
    if (find(this.props.routes, { name: "editEventFormsList" })) {
      return "my forms";
    } else if (find(this.props.routes, { name: "editEventFormsTemplates" })) {
      return "templates";
    }
    return "my forms";
  }

  render() {
    const { params } = this.props;

    const title = `${this.props.details.name} - Forms`;

    const tabsProps = {
      meta: { pageKey: this.getSelectedTab() },
      tabs: [
        {
          name: "My Forms",
          url: `/event/${params.eventId}/forms-v2`
        }
      ]
    };

    return (
      <DocumentTitle title={title}>
        <div styleName="container">
          <Header title="Forms">
            <Tabs {...tabsProps} />
          </Header>

          {React.cloneElement(this.props.children, {
            params: this.props.params,
            showModal: this.props.showModal,
            hideModal: this.props.hideModal,
            dispatch: this.props.dispatch,
            showSnackbar: this.props.showSnackbar,
            eventId: this.props.params.eventId,
            details: this.props.details
          })}
        </div>
      </DocumentTitle>
    );
  }
}

FormsHandler.propTypes = {
  routes: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  details: PropTypes.object,
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func,
  showModal: PropTypes.func,
  hideModal: PropTypes.func,
  showSnackbar: PropTypes.func
};

export default FormsHandler;
