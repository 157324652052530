import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const NumberFormatter = ({ value, field }) => (
  <div styleName="numberFormatter">{value}</div>
);

NumberFormatter.propTypes = {};

export default CSSModules(NumberFormatter, css);
