import React from "react";

import { SHOW_MEALS_FILTER_ID } from "Passes/Common/constants";

import { ToggleFilter } from "ui-kit/FilterControls/View";
import { Text2 } from "components/Base";

const ShowMealsFilter = props => (
  <ToggleFilter instanceId={SHOW_MEALS_FILTER_ID} {...props} bg="transparent">
    <Text2 bold>Show Meal Items</Text2>
  </ToggleFilter>
);

export default ShowMealsFilter;
