import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  permissionGroups: [],
  permissionProfiles: [],
  search: "",
  activePermissionProfileId: "6c1fba5d-633a-4d54-9077-a50a5bd4e7e0",
  showItemsModalForModuleId: null
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    getOrgPermissionProfiles: R.identity,
    updatePermissionProfilePermissions: R.identity,
    addOrgPermissionProfile: R.identity,
    updatePermissionProfile: R.identity,
    deletePermissionProfile: R.identity,
    setInitData: (
      _,
      { payload: { permissionGroups, permissionProfiles } }
    ) => ({
      permissionGroups,
      permissionProfiles
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
