import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Record from "./Record";

import { showModal, hideModal } from "redux/modules/modal/actions";
import { getModule } from "redux/modules/modules/module/actions";
import {
  getRecord,
  deleteRecord,
  invalidateRecord
} from "redux/modules/modules/record/actions";
import { addValue } from "redux/modules/modules/values/actions";
import { logAction } from "redux/modules/log/actions";

import * as ModuleSelectors from "redux/modules/modules/module/selectors";
import {
  record,
  relatedModules,
  starredFields,
  fieldGroups
} from "redux/modules/modules/record/selectors";
import * as EventSelectors from "redux/modules/event/selectors";
import { references } from "redux/modules/entityReferences/selectors";
import { user } from "redux/modules/user/selectors";

function mapStateToProps(state, props) {
  return {
    module: ModuleSelectors.moduleDetails(state, props.params.moduleId),
    record: record(state, props.params.recordId),
    relatedModules: relatedModules(state, props.params.recordId),
    fieldGroups: fieldGroups(state, props.params.recordId),
    starredFields: starredFields(state, props.params.recordId),
    eventDetails: EventSelectors.eventDetails(state),
    references: references(state),
    user: user(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal,
      hideModal,
      getModule,
      addValue,
      deleteRecord,
      getRecord,
      invalidateRecord,
      logAction
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Record);
