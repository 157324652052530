import { connect } from "react-redux";
import { createContext } from "redux-mvc";
import * as R from "ramda";
import reports, { actions } from "../index";
import Layout from "./Layout";
import rootSaga from "../sagas";
import { withRouter } from "react-router";

import { getModule } from "redux/modules/modules/module/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { getEmailMergeTags } from "redux/modules/event/email-merge-tags/actions";
import { updateOrder } from "redux/modules/orders/orders/actions";

import { orgId as getOrgId } from "redux/modules/organization/selectors";
import {
  eventId as getEventId,
  eventDetails
} from "redux/modules/event/selectors";
import { mergeTags } from "redux/modules/event/email-merge-tags/selectors";
import { user } from "redux/modules/user/selectors";
import * as ModuleSelectors from "redux/modules/modules/module/selectors";

import * as STANDARD_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";
import * as SEND_TO_OPTION_TYPES from "SendEmailModal/utils/send-to-option-types";

const buildInitialSelectedOptions = (mode, incomingOptions) => {
  let selectedOptions = null;

  if (incomingOptions.includes(SEND_TO_OPTION_TYPES.ACCOUNT_PRIMARY_CONTACTS)) {
    selectedOptions = SEND_TO_OPTION_TYPES.ACCOUNT_PRIMARY_CONTACTS;
  } else if (
    incomingOptions.includes(SEND_TO_OPTION_TYPES.ACCOUNT_CONTACT_USERS)
  ) {
    selectedOptions = SEND_TO_OPTION_TYPES.ACCOUNT_CONTACT_USERS;
  } else if (incomingOptions.includes(SEND_TO_OPTION_TYPES.ORDER_CUSTOMER)) {
    selectedOptions = SEND_TO_OPTION_TYPES.ORDER_CUSTOMER;
  } else if (incomingOptions.includes(STANDARD_FIELD_IDS.CONTACTS.EMAIL)) {
    selectedOptions = STANDARD_FIELD_IDS.CONTACTS.EMAIL;
  } else if (
    incomingOptions.includes(STANDARD_FIELD_IDS.CONTACTS.MOBILE_PHONE)
  ) {
    selectedOptions = STANDARD_FIELD_IDS.CONTACTS.MOBILE_PHONE;
  }

  return selectedOptions;
};

reports.setRootSaga(rootSaga);

const contextConfig = {
  module: reports,
  lifeCycle: {
    async componentDidMount() {
      const selectedOptions = this.props.selectedOptions
        ? buildInitialSelectedOptions(
            this.props.mode,
            this.props.selectedOptions
          )
        : null;

      await Promise.all([
        this.props.getModule({
          moduleId: this.props.moduleId,
          options: {
            orgId: this.props.orgId,
            eventId: this.props.eventId
          }
        }),
        this.props.getEmailMergeTags({
          orgId: this.props.orgId,
          eventId: this.props.eventId,
          moduleId: this.props.moduleId,
          options: selectedOptions
        })
      ]);

      this.store.dispatch(
        actions.init({
          mode: this.props.mode,
          records: this.props.records,
          moduleId: this.props.moduleId,
          selectedOptions,
          emailType: this.props.emailType,
          subject: this.props.subject || "",
          message: this.props.message || "",
          replyTo: this.props.user.email || "",
          attachments: this.props.attachments || [],
          invoiceDueDate: this.props.eventDetails.date_to,
          sourceRecordId: this.props.sourceRecordId,
          sourceFormId: this.props.sourceFormId,
          sourceOrderId: this.props.sourceOrderId,
          groupByRecipient: false
        })
      );
    }
  },
  handlers: {
    onDone() {
      if (this.props.onDone) {
        this.props.onDone();
      }
      this.props.hideModal();
    }
  },
  options: {
    persist: false,
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", reports.modules))),
      R.prop("type")
    ),
    observedDomains: [
      "user",
      "event",
      "modal",
      "organization",
      "permissions",
      "eventEmailTemplates",
      "modules"
    ]
  }
};

const decorate = R.compose(
  connect(
    (state, props) => {
      const moduleId = props.moduleId || props.params.moduleId;
      return {
        moduleId,
        orgId: getOrgId(state),
        eventId: getEventId(state),
        eventDetails: eventDetails(state),
        user: user(state),
        module: ModuleSelectors.moduleDetails(state, moduleId),
        fields: ModuleSelectors.fields(state, moduleId),
        isFetchingModule: ModuleSelectors.isFetching(state, moduleId),
        mergeTags: mergeTags(state)
      };
    },
    {
      getEmailMergeTags,
      getModule,
      showSnackbar,
      updateOrder
    }
  ),
  withRouter,
  createContext(contextConfig)
);

export default decorate(Layout);
