import PropTypes from "prop-types";
import React, { Component } from "react";
import { get } from "lodash";
import autobind from "autobind-decorator";
import Editor from "../../Editors/NumberEditor";
import EditorWithDefaults from "../../Editors/NumberWithDefaultsEditor";

export default class NumberEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  @autobind
  onBlur() {
    this.props.onChange(this.state.value);
  }

  @autobind
  onChange(value) {
    this.setState({
      value
    });
  }

  render() {
    const options = get(this.props.column, "settings.options", []);
    if (options.length) {
      return (
        <EditorWithDefaults
          options={options}
          disabled={this.props.disabled}
          onChange={this.props.onChange}
          value={this.props.value}
          className="styled"
        />
      );
    }
    return (
      <Editor
        disabled={this.props.disabled}
        onBlur={this.onBlur}
        onChange={this.onChange}
        value={this.state.value}
        className="styled"
      />
    );
  }
}

NumberEditor.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.object,
  column: PropTypes.object
};
