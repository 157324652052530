const timezones = [
  {
    value: "America/Adak",
    label: "(GMT-10:00) America/Adak (Hawaii-Aleutian Standard Time)"
  },
  {
    value: "America/Atka",
    label: "(GMT-10:00) America/Atka (Hawaii-Aleutian Standard Time)"
  },
  {
    value: "America/Anchorage",
    label: "(GMT-9:00) America/Anchorage (Alaska Standard Time)"
  },
  {
    value: "America/Juneau",
    label: "(GMT-9:00) America/Juneau (Alaska Standard Time)"
  },
  {
    value: "America/Nome",
    label: "(GMT-9:00) America/Nome (Alaska Standard Time)"
  },
  {
    value: "America/Yakutat",
    label: "(GMT-9:00) America/Yakutat (Alaska Standard Time)"
  },
  {
    value: "America/Dawson",
    label: "(GMT-8:00) America/Dawson (Pacific Standard Time)"
  },
  {
    value: "America/Ensenada",
    label: "(GMT-8:00) America/Ensenada (Pacific Standard Time)"
  },
  {
    value: "America/Los_Angeles",
    label: "(GMT-8:00) America/Los_Angeles (Pacific Standard Time)"
  },
  {
    value: "America/Tijuana",
    label: "(GMT-8:00) America/Tijuana (Pacific Standard Time)"
  },
  {
    value: "America/Vancouver",
    label: "(GMT-8:00) America/Vancouver (Pacific Standard Time)"
  },
  {
    value: "America/Whitehorse",
    label: "(GMT-8:00) America/Whitehorse (Pacific Standard Time)"
  },
  {
    value: "Canada/Pacific",
    label: "(GMT-8:00) Canada/Pacific (Pacific Standard Time)"
  },
  {
    value: "Canada/Yukon",
    label: "(GMT-8:00) Canada/Yukon (Pacific Standard Time)"
  },
  {
    value: "Mexico/BajaNorte",
    label: "(GMT-8:00) Mexico/BajaNorte (Pacific Standard Time)"
  },
  {
    value: "America/Boise",
    label: "(GMT-7:00) America/Boise (Mountain Standard Time)"
  },
  {
    value: "America/Cambridge_Bay",
    label: "(GMT-7:00) America/Cambridge_Bay (Mountain Standard Time)"
  },
  {
    value: "America/Chihuahua",
    label: "(GMT-7:00) America/Chihuahua (Mountain Standard Time)"
  },
  {
    value: "America/Dawson_Creek",
    label: "(GMT-7:00) America/Dawson_Creek (Mountain Standard Time)"
  },
  {
    value: "America/Denver",
    label: "(GMT-7:00) America/Denver (Mountain Standard Time)"
  },
  {
    value: "America/Edmonton",
    label: "(GMT-7:00) America/Edmonton (Mountain Standard Time)"
  },
  {
    value: "America/Hermosillo",
    label: "(GMT-7:00) America/Hermosillo (Mountain Standard Time)"
  },
  {
    value: "America/Inuvik",
    label: "(GMT-7:00) America/Inuvik (Mountain Standard Time)"
  },
  {
    value: "America/Mazatlan",
    label: "(GMT-7:00) America/Mazatlan (Mountain Standard Time)"
  },
  {
    value: "America/Phoenix",
    label: "(GMT-7:00) America/Phoenix (Mountain Standard Time)"
  },
  {
    value: "America/Shiprock",
    label: "(GMT-7:00) America/Shiprock (Mountain Standard Time)"
  },
  {
    value: "America/Yellowknife",
    label: "(GMT-7:00) America/Yellowknife (Mountain Standard Time)"
  },
  {
    value: "Canada/Mountain",
    label: "(GMT-7:00) Canada/Mountain (Mountain Standard Time)"
  },
  {
    value: "Mexico/BajaSur",
    label: "(GMT-7:00) Mexico/BajaSur (Mountain Standard Time)"
  },
  {
    value: "America/Belize",
    label: "(GMT-6:00) America/Belize (Central Standard Time)"
  },
  {
    value: "America/Cancun",
    label: "(GMT-6:00) America/Cancun (Central Standard Time)"
  },
  {
    value: "America/Chicago",
    label: "(GMT-6:00) America/Chicago (Central Standard Time)"
  },
  {
    value: "America/Costa_Rica",
    label: "(GMT-6:00) America/Costa_Rica (Central Standard Time)"
  },
  {
    value: "America/El_Salvador",
    label: "(GMT-6:00) America/El_Salvador (Central Standard Time)"
  },
  {
    value: "America/Guatemala",
    label: "(GMT-6:00) America/Guatemala (Central Standard Time)"
  },
  {
    value: "America/Knox_IN",
    label: "(GMT-6:00) America/Knox_IN (Central Standard Time)"
  },
  {
    value: "America/Managua",
    label: "(GMT-6:00) America/Managua (Central Standard Time)"
  },
  {
    value: "America/Menominee",
    label: "(GMT-6:00) America/Menominee (Central Standard Time)"
  },
  {
    value: "America/Merida",
    label: "(GMT-6:00) America/Merida (Central Standard Time)"
  },
  {
    value: "America/Mexico_City",
    label: "(GMT-6:00) America/Mexico_City (Central Standard Time)"
  },
  {
    value: "America/Monterrey",
    label: "(GMT-6:00) America/Monterrey (Central Standard Time)"
  },
  {
    value: "America/Rainy_River",
    label: "(GMT-6:00) America/Rainy_River (Central Standard Time)"
  },
  {
    value: "America/Rankin_Inlet",
    label: "(GMT-6:00) America/Rankin_Inlet (Central Standard Time)"
  },
  {
    value: "America/Regina",
    label: "(GMT-6:00) America/Regina (Central Standard Time)"
  },
  {
    value: "America/Swift_Current",
    label: "(GMT-6:00) America/Swift_Current (Central Standard Time)"
  },
  {
    value: "America/Tegucigalpa",
    label: "(GMT-6:00) America/Tegucigalpa (Central Standard Time)"
  },
  {
    value: "America/Winnipeg",
    label: "(GMT-6:00) America/Winnipeg (Central Standard Time)"
  },
  {
    value: "Canada/Central",
    label: "(GMT-6:00) Canada/Central (Central Standard Time)"
  },
  {
    value: "Canada/East-Saskatchewan",
    label: "(GMT-6:00) Canada/East-Saskatchewan (Central Standard Time)"
  },
  {
    value: "Canada/Saskatchewan",
    label: "(GMT-6:00) Canada/Saskatchewan (Central Standard Time)"
  },
  {
    value: "Chile/EasterIsland",
    label: "(GMT-6:00) Chile/EasterIsland (Easter Is. Time)"
  },
  {
    value: "Mexico/General",
    label: "(GMT-6:00) Mexico/General (Central Standard Time)"
  },
  {
    value: "America/Atikokan",
    label: "(GMT-5:00) America/Atikokan (Eastern Standard Time)"
  },
  {
    value: "America/Bogota",
    label: "(GMT-5:00) America/Bogota (Colombia Time)"
  },
  {
    value: "America/Cayman",
    label: "(GMT-5:00) America/Cayman (Eastern Standard Time)"
  },
  {
    value: "America/Coral_Harbour",
    label: "(GMT-5:00) America/Coral_Harbour (Eastern Standard Time)"
  },
  {
    value: "America/Detroit",
    label: "(GMT-5:00) America/Detroit (Eastern Standard Time)"
  },
  {
    value: "America/Fort_Wayne",
    label: "(GMT-5:00) America/Fort_Wayne (Eastern Standard Time)"
  },
  {
    value: "America/Grand_Turk",
    label: "(GMT-5:00) America/Grand_Turk (Eastern Standard Time)"
  },
  {
    value: "America/Guayaquil",
    label: "(GMT-5:00) America/Guayaquil (Ecuador Time)"
  },
  {
    value: "America/Havana",
    label: "(GMT-5:00) America/Havana (Cuba Standard Time)"
  },
  {
    value: "America/Indianapolis",
    label: "(GMT-5:00) America/Indianapolis (Eastern Standard Time)"
  },
  {
    value: "America/Iqaluit",
    label: "(GMT-5:00) America/Iqaluit (Eastern Standard Time)"
  },
  {
    value: "America/Jamaica",
    label: "(GMT-5:00) America/Jamaica (Eastern Standard Time)"
  },
  { value: "America/Lima", label: "(GMT-5:00) America/Lima (Peru Time)" },
  {
    value: "America/Louisville",
    label: "(GMT-5:00) America/Louisville (Eastern Standard Time)"
  },
  {
    value: "America/Montreal",
    label: "(GMT-5:00) America/Montreal (Eastern Standard Time)"
  },
  {
    value: "America/Nassau",
    label: "(GMT-5:00) America/Nassau (Eastern Standard Time)"
  },
  {
    value: "America/New_York",
    label: "(GMT-5:00) America/New_York (Eastern Standard Time)"
  },
  {
    value: "America/Nipigon",
    label: "(GMT-5:00) America/Nipigon (Eastern Standard Time)"
  },
  {
    value: "America/Panama",
    label: "(GMT-5:00) America/Panama (Eastern Standard Time)"
  },
  {
    value: "America/Pangnirtung",
    label: "(GMT-5:00) America/Pangnirtung (Eastern Standard Time)"
  },
  {
    value: "America/Port-au-Prince",
    label: "(GMT-5:00) America/Port-au-Prince (Eastern Standard Time)"
  },
  {
    value: "America/Resolute",
    label: "(GMT-5:00) America/Resolute (Eastern Standard Time)"
  },
  {
    value: "America/Thunder_Bay",
    label: "(GMT-5:00) America/Thunder_Bay (Eastern Standard Time)"
  },
  {
    value: "America/Toronto",
    label: "(GMT-5:00) America/Toronto (Eastern Standard Time)"
  },
  {
    value: "Canada/Eastern",
    label: "(GMT-5:00) Canada/Eastern (Eastern Standard Time)"
  },
  {
    value: "America/Caracas",
    label: "(GMT-4:-30) America/Caracas (Venezuela Time)"
  },
  {
    value: "America/Anguilla",
    label: "(GMT-4:00) America/Anguilla (Atlantic Standard Time)"
  },
  {
    value: "America/Antigua",
    label: "(GMT-4:00) America/Antigua (Atlantic Standard Time)"
  },
  {
    value: "America/Aruba",
    label: "(GMT-4:00) America/Aruba (Atlantic Standard Time)"
  },
  {
    value: "America/Asuncion",
    label: "(GMT-4:00) America/Asuncion (Paraguay Time)"
  },
  {
    value: "America/Barbados",
    label: "(GMT-4:00) America/Barbados (Atlantic Standard Time)"
  },
  {
    value: "America/Blanc-Sablon",
    label: "(GMT-4:00) America/Blanc-Sablon (Atlantic Standard Time)"
  },
  {
    value: "America/Boa_Vista",
    label: "(GMT-4:00) America/Boa_Vista (Amazon Time)"
  },
  {
    value: "America/Campo_Grande",
    label: "(GMT-4:00) America/Campo_Grande (Amazon Time)"
  },
  { value: "America/Cuiaba", label: "(GMT-4:00) America/Cuiaba (Amazon Time)" },
  {
    value: "America/Curacao",
    label: "(GMT-4:00) America/Curacao (Atlantic Standard Time)"
  },
  {
    value: "America/Dominica",
    label: "(GMT-4:00) America/Dominica (Atlantic Standard Time)"
  },
  {
    value: "America/Eirunepe",
    label: "(GMT-4:00) America/Eirunepe (Amazon Time)"
  },
  {
    value: "America/Glace_Bay",
    label: "(GMT-4:00) America/Glace_Bay (Atlantic Standard Time)"
  },
  {
    value: "America/Goose_Bay",
    label: "(GMT-4:00) America/Goose_Bay (Atlantic Standard Time)"
  },
  {
    value: "America/Grenada",
    label: "(GMT-4:00) America/Grenada (Atlantic Standard Time)"
  },
  {
    value: "America/Guadeloupe",
    label: "(GMT-4:00) America/Guadeloupe (Atlantic Standard Time)"
  },
  { value: "America/Guyana", label: "(GMT-4:00) America/Guyana (Guyana Time)" },
  {
    value: "America/Halifax",
    label: "(GMT-4:00) America/Halifax (Atlantic Standard Time)"
  },
  {
    value: "America/La_Paz",
    label: "(GMT-4:00) America/La_Paz (Bolivia Time)"
  },
  { value: "America/Manaus", label: "(GMT-4:00) America/Manaus (Amazon Time)" },
  {
    value: "America/Marigot",
    label: "(GMT-4:00) America/Marigot (Atlantic Standard Time)"
  },
  {
    value: "America/Martinique",
    label: "(GMT-4:00) America/Martinique (Atlantic Standard Time)"
  },
  {
    value: "America/Moncton",
    label: "(GMT-4:00) America/Moncton (Atlantic Standard Time)"
  },
  {
    value: "America/Montserrat",
    label: "(GMT-4:00) America/Montserrat (Atlantic Standard Time)"
  },
  {
    value: "America/Port_of_Spain",
    label: "(GMT-4:00) America/Port_of_Spain (Atlantic Standard Time)"
  },
  {
    value: "America/Porto_Acre",
    label: "(GMT-4:00) America/Porto_Acre (Amazon Time)"
  },
  {
    value: "America/Porto_Velho",
    label: "(GMT-4:00) America/Porto_Velho (Amazon Time)"
  },
  {
    value: "America/Puerto_Rico",
    label: "(GMT-4:00) America/Puerto_Rico (Atlantic Standard Time)"
  },
  {
    value: "America/Rio_Branco",
    label: "(GMT-4:00) America/Rio_Branco (Amazon Time)"
  },
  {
    value: "America/Santiago",
    label: "(GMT-4:00) America/Santiago (Chile Time)"
  },
  {
    value: "America/Santo_Domingo",
    label: "(GMT-4:00) America/Santo_Domingo (Atlantic Standard Time)"
  },
  {
    value: "America/St_Barthelemy",
    label: "(GMT-4:00) America/St_Barthelemy (Atlantic Standard Time)"
  },
  {
    value: "America/St_Kitts",
    label: "(GMT-4:00) America/St_Kitts (Atlantic Standard Time)"
  },
  {
    value: "America/St_Lucia",
    label: "(GMT-4:00) America/St_Lucia (Atlantic Standard Time)"
  },
  {
    value: "America/St_Thomas",
    label: "(GMT-4:00) America/St_Thomas (Atlantic Standard Time)"
  },
  {
    value: "America/St_Vincent",
    label: "(GMT-4:00) America/St_Vincent (Atlantic Standard Time)"
  },
  {
    value: "America/Thule",
    label: "(GMT-4:00) America/Thule (Atlantic Standard Time)"
  },
  {
    value: "America/Tortola",
    label: "(GMT-4:00) America/Tortola (Atlantic Standard Time)"
  },
  {
    value: "America/Virgin",
    label: "(GMT-4:00) America/Virgin (Atlantic Standard Time)"
  },
  {
    value: "Antarctica/Palmer",
    label: "(GMT-4:00) Antarctica/Palmer (Chile Time)"
  },
  {
    value: "Atlantic/Bermuda",
    label: "(GMT-4:00) Atlantic/Bermuda (Atlantic Standard Time)"
  },
  {
    value: "Atlantic/Stanley",
    label: "(GMT-4:00) Atlantic/Stanley (Falkland Is. Time)"
  },
  { value: "Brazil/Acre", label: "(GMT-4:00) Brazil/Acre (Amazon Time)" },
  { value: "Brazil/West", label: "(GMT-4:00) Brazil/West (Amazon Time)" },
  {
    value: "Canada/Atlantic",
    label: "(GMT-4:00) Canada/Atlantic (Atlantic Standard Time)"
  },
  {
    value: "Chile/Continental",
    label: "(GMT-4:00) Chile/Continental (Chile Time)"
  },
  {
    value: "America/St_Johns",
    label: "(GMT-3:-30) America/St_Johns (Newfoundland Standard Time)"
  },
  {
    value: "Canada/Newfoundland",
    label: "(GMT-3:-30) Canada/Newfoundland (Newfoundland Standard Time)"
  },
  {
    value: "America/Araguaina",
    label: "(GMT-3:00) America/Araguaina (Brasilia Time)"
  },
  { value: "America/Bahia", label: "(GMT-3:00) America/Bahia (Brasilia Time)" },
  { value: "America/Belem", label: "(GMT-3:00) America/Belem (Brasilia Time)" },
  {
    value: "America/Buenos_Aires",
    label: "(GMT-3:00) America/Buenos_Aires (Argentine Time)"
  },
  {
    value: "America/Catamarca",
    label: "(GMT-3:00) America/Catamarca (Argentine Time)"
  },
  {
    value: "America/Cayenne",
    label: "(GMT-3:00) America/Cayenne (French Guiana Time)"
  },
  {
    value: "America/Cordoba",
    label: "(GMT-3:00) America/Cordoba (Argentine Time)"
  },
  {
    value: "America/Fortaleza",
    label: "(GMT-3:00) America/Fortaleza (Brasilia Time)"
  },
  {
    value: "America/Godthab",
    label: "(GMT-3:00) America/Godthab (Western Greenland Time)"
  },
  {
    value: "America/Jujuy",
    label: "(GMT-3:00) America/Jujuy (Argentine Time)"
  },
  {
    value: "America/Maceio",
    label: "(GMT-3:00) America/Maceio (Brasilia Time)"
  },
  {
    value: "America/Mendoza",
    label: "(GMT-3:00) America/Mendoza (Argentine Time)"
  },
  {
    value: "America/Miquelon",
    label: "(GMT-3:00) America/Miquelon (Pierre & Miquelon Standard Time)"
  },
  {
    value: "America/Montevideo",
    label: "(GMT-3:00) America/Montevideo (Uruguay Time)"
  },
  {
    value: "America/Paramaribo",
    label: "(GMT-3:00) America/Paramaribo (Suriname Time)"
  },
  {
    value: "America/Recife",
    label: "(GMT-3:00) America/Recife (Brasilia Time)"
  },
  {
    value: "America/Rosario",
    label: "(GMT-3:00) America/Rosario (Argentine Time)"
  },
  {
    value: "America/Santarem",
    label: "(GMT-3:00) America/Santarem (Brasilia Time)"
  },
  {
    value: "America/Sao_Paulo",
    label: "(GMT-3:00) America/Sao_Paulo (Brasilia Time)"
  },
  {
    value: "Antarctica/Rothera",
    label: "(GMT-3:00) Antarctica/Rothera (Rothera Time)"
  },
  { value: "Brazil/East", label: "(GMT-3:00) Brazil/East (Brasilia Time)" },
  {
    value: "America/Noronha",
    label: "(GMT-2:00) America/Noronha (Fernando de Noronha Time)"
  },
  {
    value: "Atlantic/South_Georgia",
    label: "(GMT-2:00) Atlantic/South_Georgia (South Georgia Standard Time)"
  },
  {
    value: "Brazil/DeNoronha",
    label: "(GMT-2:00) Brazil/DeNoronha (Fernando de Noronha Time)"
  },
  {
    value: "America/Scoresbysund",
    label: "(GMT-1:00) America/Scoresbysund (Eastern Greenland Time)"
  },
  {
    value: "Atlantic/Azores",
    label: "(GMT-1:00) Atlantic/Azores (Azores Time)"
  },
  {
    value: "Atlantic/Cape_Verde",
    label: "(GMT-1:00) Atlantic/Cape_Verde (Cape Verde Time)"
  },
  {
    value: "Africa/Abidjan",
    label: "(GMT+0:00) Africa/Abidjan (Greenwich Mean Time)"
  },
  { value: "Africa/Accra", label: "(GMT+0:00) Africa/Accra (Ghana Mean Time)" },
  {
    value: "Africa/Bamako",
    label: "(GMT+0:00) Africa/Bamako (Greenwich Mean Time)"
  },
  {
    value: "Africa/Banjul",
    label: "(GMT+0:00) Africa/Banjul (Greenwich Mean Time)"
  },
  {
    value: "Africa/Bissau",
    label: "(GMT+0:00) Africa/Bissau (Greenwich Mean Time)"
  },
  {
    value: "Africa/Casablanca",
    label: "(GMT+0:00) Africa/Casablanca (Western European Time)"
  },
  {
    value: "Africa/Conakry",
    label: "(GMT+0:00) Africa/Conakry (Greenwich Mean Time)"
  },
  {
    value: "Africa/Dakar",
    label: "(GMT+0:00) Africa/Dakar (Greenwich Mean Time)"
  },
  {
    value: "Africa/El_Aaiun",
    label: "(GMT+0:00) Africa/El_Aaiun (Western European Time)"
  },
  {
    value: "Africa/Freetown",
    label: "(GMT+0:00) Africa/Freetown (Greenwich Mean Time)"
  },
  {
    value: "Africa/Lome",
    label: "(GMT+0:00) Africa/Lome (Greenwich Mean Time)"
  },
  {
    value: "Africa/Monrovia",
    label: "(GMT+0:00) Africa/Monrovia (Greenwich Mean Time)"
  },
  {
    value: "Africa/Nouakchott",
    label: "(GMT+0:00) Africa/Nouakchott (Greenwich Mean Time)"
  },
  {
    value: "Africa/Ouagadougou",
    label: "(GMT+0:00) Africa/Ouagadougou (Greenwich Mean Time)"
  },
  {
    value: "Africa/Sao_Tome",
    label: "(GMT+0:00) Africa/Sao_Tome (Greenwich Mean Time)"
  },
  {
    value: "Africa/Timbuktu",
    label: "(GMT+0:00) Africa/Timbuktu (Greenwich Mean Time)"
  },
  {
    value: "America/Danmarkshavn",
    label: "(GMT+0:00) America/Danmarkshavn (Greenwich Mean Time)"
  },
  {
    value: "Atlantic/Canary",
    label: "(GMT+0:00) Atlantic/Canary (Western European Time)"
  },
  {
    value: "Atlantic/Faeroe",
    label: "(GMT+0:00) Atlantic/Faeroe (Western European Time)"
  },
  {
    value: "Atlantic/Faroe",
    label: "(GMT+0:00) Atlantic/Faroe (Western European Time)"
  },
  {
    value: "Atlantic/Madeira",
    label: "(GMT+0:00) Atlantic/Madeira (Western European Time)"
  },
  {
    value: "Atlantic/Reykjavik",
    label: "(GMT+0:00) Atlantic/Reykjavik (Greenwich Mean Time)"
  },
  {
    value: "Atlantic/St_Helena",
    label: "(GMT+0:00) Atlantic/St_Helena (Greenwich Mean Time)"
  },
  {
    value: "Europe/Belfast",
    label: "(GMT+0:00) Europe/Belfast (Greenwich Mean Time)"
  },
  {
    value: "Europe/Dublin",
    label: "(GMT+0:00) Europe/Dublin (Greenwich Mean Time)"
  },
  {
    value: "Europe/Guernsey",
    label: "(GMT+0:00) Europe/Guernsey (Greenwich Mean Time)"
  },
  {
    value: "Europe/Isle_of_Man",
    label: "(GMT+0:00) Europe/Isle_of_Man (Greenwich Mean Time)"
  },
  {
    value: "Europe/Jersey",
    label: "(GMT+0:00) Europe/Jersey (Greenwich Mean Time)"
  },
  {
    value: "Europe/Lisbon",
    label: "(GMT+0:00) Europe/Lisbon (Western European Time)"
  },
  {
    value: "Europe/London",
    label: "(GMT+0:00) Europe/London (Greenwich Mean Time)"
  },
  {
    value: "Africa/Algiers",
    label: "(GMT+1:00) Africa/Algiers (Central European Time)"
  },
  {
    value: "Africa/Bangui",
    label: "(GMT+1:00) Africa/Bangui (Western African Time)"
  },
  {
    value: "Africa/Brazzaville",
    label: "(GMT+1:00) Africa/Brazzaville (Western African Time)"
  },
  {
    value: "Africa/Ceuta",
    label: "(GMT+1:00) Africa/Ceuta (Central European Time)"
  },
  {
    value: "Africa/Douala",
    label: "(GMT+1:00) Africa/Douala (Western African Time)"
  },
  {
    value: "Africa/Kinshasa",
    label: "(GMT+1:00) Africa/Kinshasa (Western African Time)"
  },
  {
    value: "Africa/Lagos",
    label: "(GMT+1:00) Africa/Lagos (Western African Time)"
  },
  {
    value: "Africa/Libreville",
    label: "(GMT+1:00) Africa/Libreville (Western African Time)"
  },
  {
    value: "Africa/Luanda",
    label: "(GMT+1:00) Africa/Luanda (Western African Time)"
  },
  {
    value: "Africa/Malabo",
    label: "(GMT+1:00) Africa/Malabo (Western African Time)"
  },
  {
    value: "Africa/Ndjamena",
    label: "(GMT+1:00) Africa/Ndjamena (Western African Time)"
  },
  {
    value: "Africa/Niamey",
    label: "(GMT+1:00) Africa/Niamey (Western African Time)"
  },
  {
    value: "Africa/Porto-Novo",
    label: "(GMT+1:00) Africa/Porto-Novo (Western African Time)"
  },
  {
    value: "Africa/Tunis",
    label: "(GMT+1:00) Africa/Tunis (Central European Time)"
  },
  {
    value: "Africa/Windhoek",
    label: "(GMT+1:00) Africa/Windhoek (Western African Time)"
  },
  {
    value: "Arctic/Longyearbyen",
    label: "(GMT+1:00) Arctic/Longyearbyen (Central European Time)"
  },
  {
    value: "Atlantic/Jan_Mayen",
    label: "(GMT+1:00) Atlantic/Jan_Mayen (Central European Time)"
  },
  {
    value: "Europe/Amsterdam",
    label: "(GMT+1:00) Europe/Amsterdam (Central European Time)"
  },
  {
    value: "Europe/Andorra",
    label: "(GMT+1:00) Europe/Andorra (Central European Time)"
  },
  {
    value: "Europe/Belgrade",
    label: "(GMT+1:00) Europe/Belgrade (Central European Time)"
  },
  {
    value: "Europe/Berlin",
    label: "(GMT+1:00) Europe/Berlin (Central European Time)"
  },
  {
    value: "Europe/Bratislava",
    label: "(GMT+1:00) Europe/Bratislava (Central European Time)"
  },
  {
    value: "Europe/Brussels",
    label: "(GMT+1:00) Europe/Brussels (Central European Time)"
  },
  {
    value: "Europe/Budapest",
    label: "(GMT+1:00) Europe/Budapest (Central European Time)"
  },
  {
    value: "Europe/Copenhagen",
    label: "(GMT+1:00) Europe/Copenhagen (Central European Time)"
  },
  {
    value: "Europe/Gibraltar",
    label: "(GMT+1:00) Europe/Gibraltar (Central European Time)"
  },
  {
    value: "Europe/Ljubljana",
    label: "(GMT+1:00) Europe/Ljubljana (Central European Time)"
  },
  {
    value: "Europe/Luxembourg",
    label: "(GMT+1:00) Europe/Luxembourg (Central European Time)"
  },
  {
    value: "Europe/Madrid",
    label: "(GMT+1:00) Europe/Madrid (Central European Time)"
  },
  {
    value: "Europe/Malta",
    label: "(GMT+1:00) Europe/Malta (Central European Time)"
  },
  {
    value: "Europe/Monaco",
    label: "(GMT+1:00) Europe/Monaco (Central European Time)"
  },
  {
    value: "Europe/Oslo",
    label: "(GMT+1:00) Europe/Oslo (Central European Time)"
  },
  {
    value: "Europe/Paris",
    label: "(GMT+1:00) Europe/Paris (Central European Time)"
  },
  {
    value: "Europe/Podgorica",
    label: "(GMT+1:00) Europe/Podgorica (Central European Time)"
  },
  {
    value: "Europe/Prague",
    label: "(GMT+1:00) Europe/Prague (Central European Time)"
  },
  {
    value: "Europe/Rome",
    label: "(GMT+1:00) Europe/Rome (Central European Time)"
  },
  {
    value: "Europe/San_Marino",
    label: "(GMT+1:00) Europe/San_Marino (Central European Time)"
  },
  {
    value: "Europe/Sarajevo",
    label: "(GMT+1:00) Europe/Sarajevo (Central European Time)"
  },
  {
    value: "Europe/Skopje",
    label: "(GMT+1:00) Europe/Skopje (Central European Time)"
  },
  {
    value: "Europe/Stockholm",
    label: "(GMT+1:00) Europe/Stockholm (Central European Time)"
  },
  {
    value: "Europe/Tirane",
    label: "(GMT+1:00) Europe/Tirane (Central European Time)"
  },
  {
    value: "Europe/Vaduz",
    label: "(GMT+1:00) Europe/Vaduz (Central European Time)"
  },
  {
    value: "Europe/Vatican",
    label: "(GMT+1:00) Europe/Vatican (Central European Time)"
  },
  {
    value: "Europe/Vienna",
    label: "(GMT+1:00) Europe/Vienna (Central European Time)"
  },
  {
    value: "Europe/Warsaw",
    label: "(GMT+1:00) Europe/Warsaw (Central European Time)"
  },
  {
    value: "Europe/Zagreb",
    label: "(GMT+1:00) Europe/Zagreb (Central European Time)"
  },
  {
    value: "Europe/Zurich",
    label: "(GMT+1:00) Europe/Zurich (Central European Time)"
  },
  {
    value: "Africa/Blantyre",
    label: "(GMT+2:00) Africa/Blantyre (Central African Time)"
  },
  {
    value: "Africa/Bujumbura",
    label: "(GMT+2:00) Africa/Bujumbura (Central African Time)"
  },
  {
    value: "Africa/Cairo",
    label: "(GMT+2:00) Africa/Cairo (Eastern European Time)"
  },
  {
    value: "Africa/Gaborone",
    label: "(GMT+2:00) Africa/Gaborone (Central African Time)"
  },
  {
    value: "Africa/Harare",
    label: "(GMT+2:00) Africa/Harare (Central African Time)"
  },
  {
    value: "Africa/Johannesburg",
    label: "(GMT+2:00) Africa/Johannesburg (South Africa Standard Time)"
  },
  {
    value: "Africa/Kigali",
    label: "(GMT+2:00) Africa/Kigali (Central African Time)"
  },
  {
    value: "Africa/Lubumbashi",
    label: "(GMT+2:00) Africa/Lubumbashi (Central African Time)"
  },
  {
    value: "Africa/Lusaka",
    label: "(GMT+2:00) Africa/Lusaka (Central African Time)"
  },
  {
    value: "Africa/Maputo",
    label: "(GMT+2:00) Africa/Maputo (Central African Time)"
  },
  {
    value: "Africa/Maseru",
    label: "(GMT+2:00) Africa/Maseru (South Africa Standard Time)"
  },
  {
    value: "Africa/Mbabane",
    label: "(GMT+2:00) Africa/Mbabane (South Africa Standard Time)"
  },
  {
    value: "Africa/Tripoli",
    label: "(GMT+2:00) Africa/Tripoli (Eastern European Time)"
  },
  {
    value: "Asia/Amman",
    label: "(GMT+2:00) Asia/Amman (Eastern European Time)"
  },
  {
    value: "Asia/Beirut",
    label: "(GMT+2:00) Asia/Beirut (Eastern European Time)"
  },
  {
    value: "Asia/Damascus",
    label: "(GMT+2:00) Asia/Damascus (Eastern European Time)"
  },
  { value: "Asia/Gaza", label: "(GMT+2:00) Asia/Gaza (Eastern European Time)" },
  {
    value: "Asia/Istanbul",
    label: "(GMT+2:00) Asia/Istanbul (Eastern European Time)"
  },
  {
    value: "Asia/Jerusalem",
    label: "(GMT+2:00) Asia/Jerusalem (Israel Standard Time)"
  },
  {
    value: "Asia/Nicosia",
    label: "(GMT+2:00) Asia/Nicosia (Eastern European Time)"
  },
  {
    value: "Asia/Tel_Aviv",
    label: "(GMT+2:00) Asia/Tel_Aviv (Israel Standard Time)"
  },
  {
    value: "Europe/Athens",
    label: "(GMT+2:00) Europe/Athens (Eastern European Time)"
  },
  {
    value: "Europe/Bucharest",
    label: "(GMT+2:00) Europe/Bucharest (Eastern European Time)"
  },
  {
    value: "Europe/Chisinau",
    label: "(GMT+2:00) Europe/Chisinau (Eastern European Time)"
  },
  {
    value: "Europe/Helsinki",
    label: "(GMT+2:00) Europe/Helsinki (Eastern European Time)"
  },
  {
    value: "Europe/Istanbul",
    label: "(GMT+2:00) Europe/Istanbul (Eastern European Time)"
  },
  {
    value: "Europe/Kaliningrad",
    label: "(GMT+2:00) Europe/Kaliningrad (Eastern European Time)"
  },
  {
    value: "Europe/Kiev",
    label: "(GMT+2:00) Europe/Kiev (Eastern European Time)"
  },
  {
    value: "Europe/Mariehamn",
    label: "(GMT+2:00) Europe/Mariehamn (Eastern European Time)"
  },
  {
    value: "Europe/Minsk",
    label: "(GMT+2:00) Europe/Minsk (Eastern European Time)"
  },
  {
    value: "Europe/Nicosia",
    label: "(GMT+2:00) Europe/Nicosia (Eastern European Time)"
  },
  {
    value: "Europe/Riga",
    label: "(GMT+2:00) Europe/Riga (Eastern European Time)"
  },
  {
    value: "Europe/Simferopol",
    label: "(GMT+2:00) Europe/Simferopol (Eastern European Time)"
  },
  {
    value: "Europe/Sofia",
    label: "(GMT+2:00) Europe/Sofia (Eastern European Time)"
  },
  {
    value: "Europe/Tallinn",
    label: "(GMT+2:00) Europe/Tallinn (Eastern European Time)"
  },
  {
    value: "Europe/Tiraspol",
    label: "(GMT+2:00) Europe/Tiraspol (Eastern European Time)"
  },
  {
    value: "Europe/Uzhgorod",
    label: "(GMT+2:00) Europe/Uzhgorod (Eastern European Time)"
  },
  {
    value: "Europe/Vilnius",
    label: "(GMT+2:00) Europe/Vilnius (Eastern European Time)"
  },
  {
    value: "Europe/Zaporozhye",
    label: "(GMT+2:00) Europe/Zaporozhye (Eastern European Time)"
  },
  {
    value: "Africa/Addis_Ababa",
    label: "(GMT+3:00) Africa/Addis_Ababa (Eastern African Time)"
  },
  {
    value: "Africa/Asmara",
    label: "(GMT+3:00) Africa/Asmara (Eastern African Time)"
  },
  {
    value: "Africa/Asmera",
    label: "(GMT+3:00) Africa/Asmera (Eastern African Time)"
  },
  {
    value: "Africa/Dar_es_Salaam",
    label: "(GMT+3:00) Africa/Dar_es_Salaam (Eastern African Time)"
  },
  {
    value: "Africa/Djibouti",
    label: "(GMT+3:00) Africa/Djibouti (Eastern African Time)"
  },
  {
    value: "Africa/Kampala",
    label: "(GMT+3:00) Africa/Kampala (Eastern African Time)"
  },
  {
    value: "Africa/Khartoum",
    label: "(GMT+3:00) Africa/Khartoum (Eastern African Time)"
  },
  {
    value: "Africa/Mogadishu",
    label: "(GMT+3:00) Africa/Mogadishu (Eastern African Time)"
  },
  {
    value: "Africa/Nairobi",
    label: "(GMT+3:00) Africa/Nairobi (Eastern African Time)"
  },
  {
    value: "Antarctica/Syowa",
    label: "(GMT+3:00) Antarctica/Syowa (Syowa Time)"
  },
  { value: "Asia/Aden", label: "(GMT+3:00) Asia/Aden (Arabia Standard Time)" },
  {
    value: "Asia/Baghdad",
    label: "(GMT+3:00) Asia/Baghdad (Arabia Standard Time)"
  },
  {
    value: "Asia/Bahrain",
    label: "(GMT+3:00) Asia/Bahrain (Arabia Standard Time)"
  },
  {
    value: "Asia/Kuwait",
    label: "(GMT+3:00) Asia/Kuwait (Arabia Standard Time)"
  },
  {
    value: "Asia/Qatar",
    label: "(GMT+3:00) Asia/Qatar (Arabia Standard Time)"
  },
  {
    value: "Europe/Moscow",
    label: "(GMT+3:00) Europe/Moscow (Moscow Standard Time)"
  },
  {
    value: "Europe/Volgograd",
    label: "(GMT+3:00) Europe/Volgograd (Volgograd Time)"
  },
  {
    value: "Indian/Antananarivo",
    label: "(GMT+3:00) Indian/Antananarivo (Eastern African Time)"
  },
  {
    value: "Indian/Comoro",
    label: "(GMT+3:00) Indian/Comoro (Eastern African Time)"
  },
  {
    value: "Indian/Mayotte",
    label: "(GMT+3:00) Indian/Mayotte (Eastern African Time)"
  },
  {
    value: "Asia/Tehran",
    label: "(GMT+3:30) Asia/Tehran (Iran Standard Time)"
  },
  { value: "Asia/Baku", label: "(GMT+4:00) Asia/Baku (Azerbaijan Time)" },
  { value: "Asia/Dubai", label: "(GMT+4:00) Asia/Dubai (Gulf Standard Time)" },
  {
    value: "Asia/Muscat",
    label: "(GMT+4:00) Asia/Muscat (Gulf Standard Time)"
  },
  { value: "Asia/Tbilisi", label: "(GMT+4:00) Asia/Tbilisi (Georgia Time)" },
  { value: "Asia/Yerevan", label: "(GMT+4:00) Asia/Yerevan (Armenia Time)" },
  { value: "Europe/Samara", label: "(GMT+4:00) Europe/Samara (Samara Time)" },
  { value: "Indian/Mahe", label: "(GMT+4:00) Indian/Mahe (Seychelles Time)" },
  {
    value: "Indian/Mauritius",
    label: "(GMT+4:00) Indian/Mauritius (Mauritius Time)"
  },
  {
    value: "Indian/Reunion",
    label: "(GMT+4:00) Indian/Reunion (Reunion Time)"
  },
  { value: "Asia/Kabul", label: "(GMT+4:30) Asia/Kabul (Afghanistan Time)" },
  { value: "Asia/Aqtau", label: "(GMT+5:00) Asia/Aqtau (Aqtau Time)" },
  { value: "Asia/Aqtobe", label: "(GMT+5:00) Asia/Aqtobe (Aqtobe Time)" },
  {
    value: "Asia/Ashgabat",
    label: "(GMT+5:00) Asia/Ashgabat (Turkmenistan Time)"
  },
  {
    value: "Asia/Ashkhabad",
    label: "(GMT+5:00) Asia/Ashkhabad (Turkmenistan Time)"
  },
  {
    value: "Asia/Dushanbe",
    label: "(GMT+5:00) Asia/Dushanbe (Tajikistan Time)"
  },
  { value: "Asia/Karachi", label: "(GMT+5:00) Asia/Karachi (Pakistan Time)" },
  { value: "Asia/Oral", label: "(GMT+5:00) Asia/Oral (Oral Time)" },
  {
    value: "Asia/Samarkand",
    label: "(GMT+5:00) Asia/Samarkand (Uzbekistan Time)"
  },
  {
    value: "Asia/Tashkent",
    label: "(GMT+5:00) Asia/Tashkent (Uzbekistan Time)"
  },
  {
    value: "Asia/Yekaterinburg",
    label: "(GMT+5:00) Asia/Yekaterinburg (Yekaterinburg Time)"
  },
  {
    value: "Indian/Kerguelen",
    label:
      "(GMT+5:00) Indian/Kerguelen (French Southern & Antarctic Lands Time)"
  },

  {
    value: "Indian/Maldives",
    label: "(GMT+5:00) Indian/Maldives (Maldives Time)"
  },
  {
    value: "Asia/Calcutta",
    label: "(GMT+5:30) Asia/Calcutta (India Standard Time)"
  },
  {
    value: "Asia/Colombo",
    label: "(GMT+5:30) Asia/Colombo (India Standard Time)"
  },
  {
    value: "Asia/Kolkata",
    label: "(GMT+5:30) Asia/Kolkata (India Standard Time)"
  },
  { value: "Asia/Katmandu", label: "(GMT+5:45) Asia/Katmandu (Nepal Time)" },
  {
    value: "Antarctica/Mawson",
    label: "(GMT+6:00) Antarctica/Mawson (Mawson Time)"
  },
  {
    value: "Antarctica/Vostok",
    label: "(GMT+6:00) Antarctica/Vostok (Vostok Time)"
  },
  { value: "Asia/Almaty", label: "(GMT+6:00) Asia/Almaty (Alma-Ata Time)" },
  { value: "Asia/Bishkek", label: "(GMT+6:00) Asia/Bishkek (Kirgizstan Time)" },
  { value: "Asia/Dacca", label: "(GMT+6:00) Asia/Dacca (Bangladesh Time)" },
  { value: "Asia/Dhaka", label: "(GMT+6:00) Asia/Dhaka (Bangladesh Time)" },
  {
    value: "Asia/Novosibirsk",
    label: "(GMT+6:00) Asia/Novosibirsk (Novosibirsk Time)"
  },
  { value: "Asia/Omsk", label: "(GMT+6:00) Asia/Omsk (Omsk Time)" },
  {
    value: "Asia/Qyzylorda",
    label: "(GMT+6:00) Asia/Qyzylorda (Qyzylorda Time)"
  },
  { value: "Asia/Thimbu", label: "(GMT+6:00) Asia/Thimbu (Bhutan Time)" },
  { value: "Asia/Thimphu", label: "(GMT+6:00) Asia/Thimphu (Bhutan Time)" },
  {
    value: "Indian/Chagos",
    label: "(GMT+6:00) Indian/Chagos (Indian Ocean Territory Time)"
  },
  { value: "Asia/Rangoon", label: "(GMT+6:30) Asia/Rangoon (Myanmar Time)" },
  {
    value: "Indian/Cocos",
    label: "(GMT+6:30) Indian/Cocos (Cocos Islands Time)"
  },
  {
    value: "Antarctica/Davis",
    label: "(GMT+7:00) Antarctica/Davis (Davis Time)"
  },
  { value: "Asia/Bangkok", label: "(GMT+7:00) Asia/Bangkok (Indochina Time)" },
  {
    value: "Asia/Ho_Chi_Minh",
    label: "(GMT+7:00) Asia/Ho_Chi_Minh (Indochina Time)"
  },
  { value: "Asia/Hovd", label: "(GMT+7:00) Asia/Hovd (Hovd Time)" },
  {
    value: "Asia/Jakarta",
    label: "(GMT+7:00) Asia/Jakarta (West Indonesia Time)"
  },
  {
    value: "Asia/Krasnoyarsk",
    label: "(GMT+7:00) Asia/Krasnoyarsk (Krasnoyarsk Time)"
  },
  {
    value: "Asia/Phnom_Penh",
    label: "(GMT+7:00) Asia/Phnom_Penh (Indochina Time)"
  },
  {
    value: "Asia/Pontianak",
    label: "(GMT+7:00) Asia/Pontianak (West Indonesia Time)"
  },
  { value: "Asia/Saigon", label: "(GMT+7:00) Asia/Saigon (Indochina Time)" },
  {
    value: "Asia/Vientiane",
    label: "(GMT+7:00) Asia/Vientiane (Indochina Time)"
  },
  {
    value: "Indian/Christmas",
    label: "(GMT+7:00) Indian/Christmas (Christmas Island Time)"
  },
  {
    value: "Antarctica/Casey",
    label: "(GMT+8:00) Antarctica/Casey (Western Standard Time (Australia))"
  },
  { value: "Asia/Brunei", label: "(GMT+8:00) Asia/Brunei (Brunei Time)" },
  {
    value: "Asia/Choibalsan",
    label: "(GMT+8:00) Asia/Choibalsan (Choibalsan Time)"
  },
  {
    value: "Asia/Chongqing",
    label: "(GMT+8:00) Asia/Chongqing (China Standard Time)"
  },
  {
    value: "Asia/Chungking",
    label: "(GMT+8:00) Asia/Chungking (China Standard Time)"
  },
  {
    value: "Asia/Harbin",
    label: "(GMT+8:00) Asia/Harbin (China Standard Time)"
  },
  {
    value: "Asia/Hong_Kong",
    label: "(GMT+8:00) Asia/Hong_Kong (Hong Kong Time)"
  },
  { value: "Asia/Irkutsk", label: "(GMT+8:00) Asia/Irkutsk (Irkutsk Time)" },
  {
    value: "Asia/Kashgar",
    label: "(GMT+8:00) Asia/Kashgar (China Standard Time)"
  },
  {
    value: "Asia/Kuala_Lumpur",
    label: "(GMT+8:00) Asia/Kuala_Lumpur (Malaysia Time)"
  },
  { value: "Asia/Kuching", label: "(GMT+8:00) Asia/Kuching (Malaysia Time)" },
  { value: "Asia/Macao", label: "(GMT+8:00) Asia/Macao (China Standard Time)" },
  { value: "Asia/Macau", label: "(GMT+8:00) Asia/Macau (China Standard Time)" },
  {
    value: "Asia/Makassar",
    label: "(GMT+8:00) Asia/Makassar (Central Indonesia Time)"
  },
  { value: "Asia/Manila", label: "(GMT+8:00) Asia/Manila (Philippines Time)" },
  {
    value: "Asia/Shanghai",
    label: "(GMT+8:00) Asia/Shanghai (China Standard Time)"
  },
  {
    value: "Asia/Singapore",
    label: "(GMT+8:00) Asia/Singapore (Singapore Time)"
  },
  {
    value: "Asia/Taipei",
    label: "(GMT+8:00) Asia/Taipei (China Standard Time)"
  },
  {
    value: "Asia/Ujung_Pandang",
    label: "(GMT+8:00) Asia/Ujung_Pandang (Central Indonesia Time)"
  },
  {
    value: "Asia/Ulaanbaatar",
    label: "(GMT+8:00) Asia/Ulaanbaatar (Ulaanbaatar Time)"
  },
  {
    value: "Asia/Ulan_Bator",
    label: "(GMT+8:00) Asia/Ulan_Bator (Ulaanbaatar Time)"
  },
  {
    value: "Asia/Urumqi",
    label: "(GMT+8:00) Asia/Urumqi (China Standard Time)"
  },
  {
    value: "Australia/Perth",
    label: "(GMT+8:00) Australia/Perth (Western Standard Time (Australia))"
  },
  {
    value: "Australia/West",
    label: "(GMT+8:00) Australia/West (Western Standard Time (Australia))"
  },
  {
    value: "Australia/Eucla",
    label:
      "(GMT+8:45) Australia/Eucla (Central Western Standard Time (Australia))"
  },

  { value: "Asia/Dili", label: "(GMT+9:00) Asia/Dili (Timor-Leste Time)" },
  {
    value: "Asia/Jayapura",
    label: "(GMT+9:00) Asia/Jayapura (East Indonesia Time)"
  },
  {
    value: "Asia/Pyongyang",
    label: "(GMT+9:00) Asia/Pyongyang (Korea Standard Time)"
  },
  { value: "Asia/Seoul", label: "(GMT+9:00) Asia/Seoul (Korea Standard Time)" },
  { value: "Asia/Tokyo", label: "(GMT+9:00) Asia/Tokyo (Japan Standard Time)" },
  { value: "Asia/Yakutsk", label: "(GMT+9:00) Asia/Yakutsk (Yakutsk Time)" },
  {
    value: "Australia/Adelaide",
    label:
      "(GMT+9:30) Australia/Adelaide (Central Standard Time (South Australia))"
  },

  {
    value: "Australia/Broken_Hill",
    label:
      "(GMT+9:30) Australia/Broken_Hill (Central Standard Time (South Australia/New South Wales))"
  },

  {
    value: "Australia/Darwin",
    label:
      "(GMT+9:30) Australia/Darwin (Central Standard Time (Northern Territory))"
  },

  {
    value: "Australia/North",
    label:
      "(GMT+9:30) Australia/North (Central Standard Time (Northern Territory))"
  },

  {
    value: "Australia/South",
    label:
      "(GMT+9:30) Australia/South (Central Standard Time (South Australia))"
  },

  {
    value: "Australia/Yancowinna",
    label:
      "(GMT+9:30) Australia/Yancowinna (Central Standard Time (South Australia/New South Wales))"
  },

  {
    value: "Antarctica/DumontDUrville",
    label: "(GMT+10:00) Antarctica/DumontDUrville (Dumont-dUrville Time)"
  },
  {
    value: "Asia/Sakhalin",
    label: "(GMT+10:00) Asia/Sakhalin (Sakhalin Time)"
  },
  {
    value: "Asia/Vladivostok",
    label: "(GMT+10:00) Asia/Vladivostok (Vladivostok Time)"
  },
  {
    value: "Australia/ACT",
    label: "(GMT+10:00) Australia/ACT (Eastern Standard Time (New South Wales))"
  },

  {
    value: "Australia/Brisbane",
    label: "(GMT+10:00) Australia/Brisbane (Eastern Standard Time (Queensland))"
  },

  {
    value: "Australia/Canberra",
    label:
      "(GMT+10:00) Australia/Canberra (Eastern Standard Time (New South Wales))"
  },

  {
    value: "Australia/Currie",
    label:
      "(GMT+10:00) Australia/Currie (Eastern Standard Time (New South Wales))"
  },

  {
    value: "Australia/Hobart",
    label: "(GMT+10:00) Australia/Hobart (Eastern Standard Time (Tasmania))"
  },
  {
    value: "Australia/Lindeman",
    label: "(GMT+10:00) Australia/Lindeman (Eastern Standard Time (Queensland))"
  },

  {
    value: "Australia/Melbourne",
    label: "(GMT+10:00) Australia/Melbourne (Eastern Standard Time (Victoria))"
  },
  {
    value: "Australia/NSW",
    label: "(GMT+10:00) Australia/NSW (Eastern Standard Time (New South Wales))"
  },

  {
    value: "Australia/Queensland",
    label:
      "(GMT+10:00) Australia/Queensland (Eastern Standard Time (Queensland))"
  },

  {
    value: "Australia/Sydney",
    label:
      "(GMT+10:00) Australia/Sydney (Eastern Standard Time (New South Wales))"
  },

  {
    value: "Australia/Tasmania",
    label: "(GMT+10:00) Australia/Tasmania (Eastern Standard Time (Tasmania))"
  },
  {
    value: "Australia/Victoria",
    label: "(GMT+10:00) Australia/Victoria (Eastern Standard Time (Victoria))"
  },
  {
    value: "Australia/LHI",
    label: "(GMT+10:30) Australia/LHI (Lord Howe Standard Time)"
  },
  {
    value: "Australia/Lord_Howe",
    label: "(GMT+10:30) Australia/Lord_Howe (Lord Howe Standard Time)"
  },
  { value: "Asia/Magadan", label: "(GMT+11:00) Asia/Magadan (Magadan Time)" },
  {
    value: "Antarctica/McMurdo",
    label: "(GMT+12:00) Antarctica/McMurdo (New Zealand Standard Time)"
  },
  {
    value: "Antarctica/South_Pole",
    label: "(GMT+12:00) Antarctica/South_Pole (New Zealand Standard Time)"
  },
  { value: "Asia/Anadyr", label: "(GMT+12:00) Asia/Anadyr (Anadyr Time)" },
  {
    value: "Asia/Kamchatka",
    label: "(GMT+12:00) Asia/Kamchatka (Petropavlovsk-Kamchatski Time)"
  }
];

export default timezones;
