import * as R from "ramda";
import React from "react";
import {
  Div,
  AvatarLabel,
  SmallShadedBox,
  MediumShadedInput,
  SearchIcon,
  Text2,
  AddIcon,
  CalendarIcon,
  OpenInNewIcon
} from "components/Base";
import CSSModules from "react-css-modules";
import css from "./LinkTrigger.css";

const Wrapper = ({ children, onClick, ...props }) => (
  <Div
    bra={1}
    p={1}
    mb={1}
    display={"row.flex-start.center"}
    bg={{ default: "transparent", hover: "neutral1" }}
    onClick={onClick}
    {...props}
  >
    {children}
  </Div>
);

const Empty = () => (
  <Div
    bra={2}
    p={3}
    mb={1}
    fs={2}
    display={"row.flex-start.center"}
    bg="neutral1"
  >
    No organizations match your search.
  </Div>
);

const OrgsList = CSSModules(
  ({
    isSearching,
    organizations,
    addOrganization,
    onSearch,
    scrollToEvents,
    scrollToPortals,
    hasPortals,
    hasEvents
  }) => {
    return (
      <Div>
        <MediumShadedInput
          display={"row.center.center"}
          continuous
          placeholder={"Find an event or organization"}
          LeftIcon={SearchIcon}
          onChange={onSearch}
        />
        <Div fs={4} pb={2} pt={4} color={"gray7"} fw={3}>
          Organizations
        </Div>
        {R.map(
          ({ id, name, photoURL, goTo, scrollTo }) => (
            <Wrapper
              key={id}
              onClick={scrollTo}
              styleName="parentContainer"
              display="row.space-between.center"
            >
              <AvatarLabel
                id={id}
                frontText={name}
                photoURL={photoURL}
                color="gray7"
              />
              <Div
                pill
                styleName="childContainer"
                onClick={goTo}
                bg={{ default: "gray3", hover: "gray4" }}
                p={1}
              >
                <OpenInNewIcon color="primary8" />
              </Div>
            </Wrapper>
          ),
          R.sortBy(R.prop("name"), organizations)
        )}
        {isSearching && !organizations.length ? <Empty /> : null}
        {hasEvents ? (
          <Wrapper onClick={scrollToEvents}>
            <SmallShadedBox pill bigIcons OnlyIcon={CalendarIcon} />
            <Text2 ml={2} color={"gray7"} bold>
              Events you manage
            </Text2>
          </Wrapper>
        ) : null}
        {hasPortals ? (
          <Wrapper onClick={scrollToPortals}>
            <SmallShadedBox pill bigIcons OnlyIcon={OpenInNewIcon} />
            <Text2 ml={2} color={"gray7"} bold>
              Events shared with you
            </Text2>
          </Wrapper>
        ) : null}
        {addOrganization ? (
          <Wrapper onClick={addOrganization} data-cy="org-add-button">
            <SmallShadedBox pill bigIcons OnlyIcon={AddIcon} />
            <Text2 ml={2} color={"gray7"} bold>
              Add an organization
            </Text2>
          </Wrapper>
        ) : null}
      </Div>
    );
  },
  css
);

export default OrgsList;
