import React from "react";
import * as R from "ramda";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Div, OpenInNewIcon } from "components/Base";
import { getters, actions } from "Accounts/QuickSetup/model";
import ReactTable from "react-table";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { addS } from "utils/General";
import ActionsPopover from "./ActionsPopover";

const decorate = R.compose(
  connect(
    state => ({
      data: getters.data(state)
    }),
    {
      handleAddRecordModal: actions.handleAddRecordModal,
      handleEditRecordTypeModal: actions.handleEditRecordTypeModal,
      setApprover: actions.setApprover,
      setManager: actions.setManager,
      enablePeopleTypes: actions.enablePeopleTypes,
      enableItemCatalogAccess: actions.enableItemCatalogAccess,
      assignForms: actions.assignForms,
      assignDocRequest: actions.assignDocRequest,
      assignTask: actions.assignTask,
      copyInviteLink: actions.copyInviteLink,
      viewLink: actions.viewLink,
      editLink: actions.editLink,
      uploadFiles: actions.uploadFiles,
      allocateMeals: actions.allocateMeals,
      allocatePass: actions.allocatePass
    }
  ),
  CSSModules(css),
  withRouter
);

const Table = ({
  data,
  handleAddRecordModal,
  router: { push },
  params: { eventId, moduleId },
  handleEditRecordTypeModal,
  setApprover,
  setManager,
  enablePeopleTypes,
  enableItemCatalogAccess,
  assignForms,
  assignDocRequest,
  assignTask,
  copyInviteLink,
  viewLink,
  editLink,
  uploadFiles,
  allocateMeals,
  allocatePass
}) => {
  const columns = [
    {
      Header: () => <div className="header-text">Group Types</div>,
      columns: [
        {
          Header: () => (
            <Div display="row.flex-start.center" px={2} height={1}>
              <div className="checkbox" />
              <div className="open-icon-container">
                <OpenInNewIcon />
              </div>
              <div className="actions-icon-container">
                <i className="material-icons flash">flash_on</i>
                <i className="material-icons down">keyboard_arrow_down</i>
              </div>
              <div className="subheader" style={{ marginLeft: 8 }}>
                Group Type
              </div>
            </Div>
          ),
          minWidth: 275,
          accessor: "name",
          Cell: ({ value, original }) => (
            <Div bg="white" display="row.flex-start.center" px={2} height={1}>
              <div className="checkbox" />
              <div className="open-icon-container">
                <i
                  className="material-icons launch"
                  onClick={() =>
                    push(
                      `/event/${eventId}/settings/module/${moduleId}/types/${original.id}`
                    )
                  }
                >
                  launch
                </i>
              </div>
              <ActionsPopover
                showEditTypeModal={() =>
                  handleEditRecordTypeModal({ moduleId, typeId: original.id })
                }
              />
              <div
                style={{
                  backgroundColor: original.color,
                  height: 25,
                  width: 25,
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginLeft: 8
                }}
              >
                <i
                  className="material-icons"
                  style={{ color: "white", fontSize: 16 }}
                >
                  {original.icon}
                </i>
              </div>
              <div className="group-type-name">{value}</div>
            </Div>
          )
        },
        {
          Header: () => <div className="subheader"># of groups</div>,
          accessor: "count_of_groups",
          width: 110,
          Cell: ({ value, original }) =>
            value > 0 ? (
              <div className="row-group underline">
                {value} group{addS(value)}
              </div>
            ) : (
              <div className="row-group">
                <div
                  className="row-group-empty"
                  onClick={() =>
                    handleAddRecordModal({
                      typeId: original.id,
                      typeName: original.name
                    })
                  }
                >
                  + Add
                </div>
                <div
                  className="row-group-empty"
                  style={{ marginLeft: 15 }}
                  onClick={() =>
                    handleAddRecordModal({
                      typeId: original.id,
                      typeName: original.name
                    })
                  }
                >
                  Import
                </div>
              </div>
            )
        }
      ]
    },
    {
      Header: () => (
        <div className="header-text" style={{ margin: 0 }}>
          Managers & Approvers
        </div>
      ),
      columns: [
        {
          Header: () => (
            <div className="subheader" style={{ margin: 0 }}>
              Manager(s)
            </div>
          ),
          accessor: "count_of_managers",
          width: 120,
          Cell: ({ value }) =>
            value > 0 ? (
              <div className="row-group underline">
                {value} manager{addS(value)}
              </div>
            ) : (
              <div className="row-group">
                <div className="row-group-empty" onClick={setManager}>
                  + Set Manager
                </div>
              </div>
            )
        },
        {
          Header: () => (
            <div className="subheader" style={{ margin: 0 }}>
              Approver(s)
            </div>
          ),
          accessor: "count_of_approvers",
          width: 120,
          Cell: ({ value }) =>
            value > 0 ? (
              <div className="row-group underline">
                {value} approver{addS(value)}
              </div>
            ) : (
              <div className="row-group">
                <div className="row-group-empty" onClick={setApprover}>
                  + Set Approver
                </div>
              </div>
            )
        }
      ]
    },
    {
      Header: () => (
        <div className="header-text" style={{ margin: 0 }}>
          People Registration
        </div>
      ),
      columns: [
        {
          Header: () => (
            <div className="subheader" style={{ margin: 0 }}>
              People Types
            </div>
          ),
          accessor: "count_of_people_types",
          width: 180,
          Cell: ({ value }) =>
            value > 0 ? (
              <div className="row-group underline">
                {value} {value === 1 ? "person type" : "people types"}
              </div>
            ) : (
              <div className="row-group">
                <div className="row-group-empty" onClick={enablePeopleTypes}>
                  + Enable
                </div>
              </div>
            )
        }
      ]
    },
    {
      Header: () => (
        <div className="header-text" style={{ margin: 0 }}>
          Catalog
        </div>
      ),
      columns: [
        {
          Header: () => (
            <div className="subheader" style={{ margin: 0 }}>
              Item Catalog Access
            </div>
          ),
          accessor: "item_catalog_access",
          width: 155,
          Cell: ({ value }) =>
            !R.isNil(value) && !R.isEmpty(value) ? (
              <div className="row-group underline">{value}</div>
            ) : (
              <div className="row-group">
                <div
                  className="row-group-empty"
                  onClick={enableItemCatalogAccess}
                >
                  + Enable
                </div>
              </div>
            )
        }
      ]
    },
    {
      Header: () => (
        <div className="header-text" style={{ margin: 0 }}>
          Forms, Documents & Tasks
        </div>
      ),
      columns: [
        {
          Header: () => (
            <div className="subheader" style={{ margin: 0 }}>
              Assigned Forms
            </div>
          ),
          accessor: "assigned_forms",
          width: 120,
          Cell: ({ value }) =>
            !R.isNil(value) && !R.isEmpty(value) ? (
              <div className="row-group underline">{value}</div>
            ) : (
              <div className="row-group">
                <div className="row-group-empty" onClick={assignForms}>
                  + Assign Forms
                </div>
              </div>
            )
        },
        {
          Header: () => (
            <div className="subheader" style={{ margin: 0 }}>
              Assigned File Requests
            </div>
          ),
          accessor: "assigned_document_requests",
          width: 195,
          Cell: ({ value }) =>
            !R.isNil(value) && !R.isEmpty(value) ? (
              <div className="row-group underline">{value}</div>
            ) : (
              <div className="row-group">
                <div className="row-group-empty" onClick={assignDocRequest}>
                  + Assign File Request
                </div>
              </div>
            )
        },
        {
          Header: () => (
            <div className="subheader" style={{ margin: 0 }}>
              Other tasks
            </div>
          ),
          accessor: "other_tasks",
          width: 120,
          Cell: ({ value }) =>
            !R.isNil(value) && !R.isEmpty(value) ? (
              <div className="row-group underline">{value}</div>
            ) : (
              <div className="row-group">
                <div className="row-group-empty" onClick={assignTask}>
                  + Assign Tasks
                </div>
              </div>
            )
        }
      ]
    },
    {
      Header: () => (
        <div className="header-text" style={{ margin: 0 }}>
          Portal Intake & Settings
        </div>
      ),
      columns: [
        {
          Header: () => (
            <div className="subheader" style={{ margin: 0 }}>
              Portal Intake Form & Invite Link
            </div>
          ),
          accessor: "portal_invite_link",
          width: 200,
          Cell: ({ value }) =>
            !R.isNil(value) && !R.isEmpty(value) ? (
              <div className="row-group">
                <div
                  className="row-group-empty"
                  style={{ textDecorationLine: "underline" }}
                  onClick={copyInviteLink}
                >
                  Copy Invite Link
                </div>
                <div
                  className="row-group-empty"
                  style={{ marginLeft: 13, textDecorationLine: "underline" }}
                  onClick={viewLink}
                >
                  View
                </div>
                <div
                  className="row-group-empty"
                  style={{ marginLeft: 13, textDecorationLine: "underline" }}
                  onClick={editLink}
                >
                  Edit
                </div>
              </div>
            ) : (
              <div className="row-group">
                <div className="row-group-empty">+ Enable</div>
              </div>
            )
        },
        {
          Header: () => (
            <div className="subheader" style={{ margin: 0 }}>
              Portal Lock Date
            </div>
          ),
          accessor: "portal_lock_date",
          width: 180,
          Cell: ({ value }) =>
            !R.isNil(value) && !R.isEmpty(value) ? (
              <div className="row-group black">{value}</div>
            ) : (
              <div className="row-group">
                <div className="no-close-date">No Close Date Set</div>
              </div>
            )
        },
        {
          Header: () => (
            <div className="subheader" style={{ margin: 0 }}>
              Alow Changes?
            </div>
          ),
          accessor: "allow_changes",
          width: 110,
          Cell: () => (
            <div className="row-group">
              <div className="open-edits">Open to Edits</div>
            </div>
          )
        }
      ]
    },
    {
      Header: () => (
        <div className="header-text" style={{ margin: 0 }}>
          Files & Event Info
        </div>
      ),
      columns: [
        {
          Header: () => (
            <div className="subheader" style={{ margin: 0 }}>
              Static Files
            </div>
          ),
          accessor: "static_files",
          width: 120,
          Cell: ({ value }) =>
            !R.isNil(value) && !R.isEmpty(value) ? (
              <div className="row-group underline">{value}</div>
            ) : (
              <div className="row-group">
                <div className="row-group-empty" onClick={uploadFiles}>
                  + Upload/Assign
                </div>
              </div>
            )
        },
        {
          Header: () => (
            <div className="subheader" style={{ margin: 0 }}>
              Event Info Pages
            </div>
          ),
          accessor: "event_info_pages",
          width: 115,
          Cell: ({ value }) => <div className="row-group black">{value}</div>
        }
      ]
    },
    {
      Header: () => (
        <div className="header-text" style={{ margin: 0 }}>
          Allocations
        </div>
      ),
      columns: [
        {
          Header: () => (
            <div className="subheader" style={{ margin: 0 }}>
              Pass Allocations
            </div>
          ),
          accessor: "pass_allocations",
          width: 115,
          Cell: ({ value }) =>
            !R.isNil(value) && !R.isEmpty(value) ? (
              <div className="row-group underline">{value}</div>
            ) : (
              <div className="row-group">
                <div className="row-group-empty" onClick={allocatePass}>
                  + Allocate
                </div>
              </div>
            )
        },
        {
          Header: () => (
            <div className="subheader" style={{ margin: 0 }}>
              Meals Allocations
            </div>
          ),
          accessor: "meal_allocations",
          width: 120,
          Cell: ({ value }) =>
            !R.isNil(value) && !R.isEmpty(value) ? (
              <div className="row-group underline">{value}</div>
            ) : (
              <div className="row-group">
                <div className="row-group-empty" onClick={allocateMeals}>
                  + Allocate
                </div>
              </div>
            )
        }
      ]
    }
  ];
  return (
    <ReactTable
      data={data}
      columns={columns}
      className="QuickSetupTable--Lennd"
      showPagination={false}
      pageSize={R.length(data)}
    />
  );
};

export default decorate(Table);
