import { createHandlers } from "redux-mvc";
import * as R from "ramda";

import {
  TABS,
  MESSAGE_TABS,
  MESSAGE_TYPES,
  NAMESPACE
} from "Orders/OrderModal/constants";

const iniState = {
  selectedTab: TABS.SUMMARY,
  loading: true,
  reloading: false,
  feedLoading: false,
  sending: false,
  syncing: false,
  order: {},
  integrations: [],
  feed: [],
  recipients: [],

  editingEmail: false,
  updatedEmail: "",
  editingPhone: false,
  updatedPhone: "",

  newMessage: "",
  selectedMessageTab: MESSAGE_TABS.MESSAGE,
  selectedMessageFilter: MESSAGE_TYPES.ALL,
  isPortalUser: false
};

const handlers = createHandlers({
  iniState,
  reducers: {
    init: (_, { payload: { isPortalUser } }) => ({
      isPortalUser
    }),
    fetchOrder: R.identity(),
    cancelOrder: R.identity(),
    syncOrder: R.identity(),
    onApprove: R.identity(),
    onDeny: R.identity(),
    onUndoReview: R.identity(),
    viewProfile: R.identity(),
    viewSubmission: R.identity(),
    viewInvoice: R.identity(),
    sendMessage: R.identity(),
    markResolved: R.identity(),
    loadFeed: R.identity(),
    loading: R.identity(),
    saveEmailAddress: R.identity(),
    savePhone: R.identity(),
    openExternalOrder: R.identity(),
    addTransaction: R.identity(),
    addManualTransaction: R.identity(),
    addOrderFieldValue: R.identity()
  },
  namespace: NAMESPACE
});

export default handlers;
