import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import { actions, getters } from "Modules/AddEditColumnModal/model";
import Percent from "components/Global/Table3/ModalColumn/PercentSettings";
import { noop } from "utils/General";
import css from "./style.scss";

const decorate = R.compose(
  connect(
    (state, props) => ({
      percentSettings: getters.percentSettings(state, props)
    }),
    {
      setPercentSettings: actions.setPercentSettings
    }
  ),
  CSSModules(css)
);

const PercentSettings = ({ percentSettings, setPercentSettings = noop }) => (
  <div styleName="dropdownOptionsWrapper">
    <Percent value={percentSettings} onChange={setPercentSettings} />
  </div>
);

export default decorate(PercentSettings);
