import formatFieldCatalogItemSingleQuantity from "./format-field-catalog-item-single-quantity";
import formatFieldCatalogItem from "./format-field-catalog-item";

export default fields =>
  fields.reduce((all, field) => {
    if (
      field.type === "catalog-item" &&
      field.settings.selectType === "single-quantity" &&
      field.visible === true
    ) {
      return formatFieldCatalogItemSingleQuantity({ all, field });
    } else if (field.type === "catalog-item" && field.visible === true) {
      return formatFieldCatalogItem({ all, field });
    }

    return [...all, field];
  }, []);
