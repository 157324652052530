import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters, actions } from "Event/MyTasks/model";
import {
  Div,
  Text0,
  Text2,
  AddIcon,
  MediumFilledInput,
  SearchIcon
} from "components/Base";
import { TABS } from "Event/MyTasks/constants";

const decorate = connect(
  state => ({
    selectedTab: getters.selectedTab(state),
    data: getters.data(state),
    filter: getters.filter(state)
  }),
  {
    setSelectedTab: actions.setSelectedTab,
    setFilter: actions.setFilter,
    handleShowAddRecordModal: actions.handleShowAddRecordModal
  }
);

const TasksBar = ({
  selectedTab,
  setSelectedTab,
  data,
  filter,
  setFilter,
  handleShowAddRecordModal
}) => (
  <Div
    height={68}
    width={1}
    bra={2}
    bg="white"
    display="row.space-between.center"
    px={5}
    style={{
      boxShadow:
        "rgba(0, 0, 0, 0.04) 0px 8px 20px, rgba(0, 0, 0, 0.01) 0px 35px 54px"
    }}
  >
    <Div
      bg="neutral1"
      height={32}
      style={{ borderRadius: "18px" }}
      width={
        data.count_of_due && data.count_of_assigned_to_me
          ? 344
          : data.count_of_due && !data.count_of_assigned_to_me
          ? 314
          : !data.count_of_due && data.count_of_assigned_to_me
          ? 309
          : 260
      }
      p={1}
      display="row.space-between.center"
    >
      <Div
        width={data.count_of_assigned_to_me ? 160 : 125}
        height={1}
        bg={selectedTab === TABS.ALL ? "white" : "transparent"}
        style={{ borderRadius: "16px" }}
        display="row.space-between.center"
        pr={2}
        pl={3}
        onClick={() => setSelectedTab(TABS.ALL)}
        transition="fast"
      >
        <Text2 bold>Assigned to me</Text2>
        {data.count_of_assigned_to_me ? (
          <Div
            bra={2}
            bg="neutral1"
            px={2}
            height={15}
            display="row.center.center"
            transition="fast"
          >
            <Text0 color="neutral7">
              {R.propOr(0, "count_of_assigned_to_me", data)}
            </Text0>
          </Div>
        ) : null}
      </Div>
      <Div
        width={data.count_of_due ? 80 : 50}
        height={1}
        bg={selectedTab === TABS.DUE ? "white" : "transparent"}
        style={{ borderRadius: "16px" }}
        display="row.space-between.center"
        pr={2}
        pl={3}
        onClick={() => setSelectedTab(TABS.DUE)}
        transition="fast"
      >
        <Text2 bold>Due</Text2>
        {data.count_of_due ? (
          <Div
            bra={2}
            px={2}
            bg="danger7"
            height={15}
            display="row.center.center"
            transition="fast"
          >
            <Text0 color="white">{R.propOr(0, "count_of_due", data)}</Text0>
          </Div>
        ) : null}
      </Div>
      <Div
        width={84}
        height={1}
        bg={selectedTab === TABS.COMPLETE ? "white" : "transparent"}
        style={{ borderRadius: "16px" }}
        display="row.space-between.center"
        pr={2}
        pl={3}
        onClick={() => setSelectedTab(TABS.COMPLETE)}
        transition="fast"
      >
        <Text2 bold>Complete</Text2>
      </Div>
    </Div>
    <MediumFilledInput
      width={404}
      bg="neutral1"
      style={{ borderRadius: "16px" }}
      RightIcon={SearchIcon}
      color="neutral5"
      placeholder="Search tasks..."
      value={filter}
      onChange={setFilter}
      continuous
    />
    <Div
      width={40}
      height={40}
      bg={{ default: "neutral1", hover: "neutral2" }}
      transition="fast"
      onClick={handleShowAddRecordModal}
      display="row.center.center"
      style={{ borderRadius: 20 }}
    >
      <AddIcon size={24} color="neutral6" />
    </Div>
  </Div>
);

export default decorate(TasksBar);
