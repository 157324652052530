import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters } from "Event/MyTasks/model";
import {
  getPastDueList,
  getUpcomingList,
  getLaterList
} from "Event/MyTasks/selectors";
import { Div } from "components/Base";
import ProgressBar from "Portal/PortalHome/View/ProgressBar";
import TaskContainer from "./TaskContainer";

const decorate = connect(state => ({
  data: getters.data(state),
  pastDueList: getPastDueList(state),
  upcomingList: getUpcomingList(state),
  laterList: getLaterList(state)
}));

const List = ({ data, pastDueList, upcomingList, laterList }) => (
  <Div
    width={1}
    bra={2}
    bg="white"
    mt={4}
    style={{
      boxShadow:
        "rgba(0, 0, 0, 0.04) 0px 8px 20px, rgba(0, 0, 0, 0.01) 0px 35px 54px"
    }}
  >
    <Div
      height={58}
      display="row.flex-start.center"
      width={1}
      bc="neutral1"
      bb={1}
      px={5}
    >
      <Div fs={2} mr={3} fw={3} color="gray8" style={{ whiteSpace: "nowrap" }}>
        {R.propOr(0, "percent_complete", data) || 0}% complete
      </Div>
      <ProgressBar progress={R.propOr(0, "percent_complete", data)} />
    </Div>
    <Div>
      {R.length(pastDueList) ? (
        <TaskContainer
          title="Past Due"
          items={pastDueList}
          isDue
          count={R.propOr(0, "count_of_due", data)}
        />
      ) : null}
      {R.length(upcomingList) ? (
        <TaskContainer title="Upcoming" items={upcomingList} />
      ) : null}
      {R.length(laterList) ? (
        <TaskContainer title="Later" items={laterList} />
      ) : null}
    </Div>
  </Div>
);

export default decorate(List);
