import { createSelector } from "reselect";
import * as R from "ramda";

import { getters } from "./model";
import moment from "moment";

const formatFullName = user => [user.user_fname, user.user_lname].join(" ");

export const getUsers = createSelector(
  getters.users,
  getters.searchTerm,
  (theseUsers, searchTerm) => {
    let users = R.map(user => ({
      ...user,
      name: formatFullName(user),
      email: user.user_email,
      photoUrl: user.user_photo_url,
      permissionRole: user.permission_role_name,
      isPending: user.status === "pending",
      inviteSentAt: moment(new Date(user.invite_sent_at)).fromNow(),
      inviteLink: user.is_virtual_user
        ? `${window.__LENND_APP_URL__}/join/vu/${user.user_virtual_hash}`
        : window.__LENND_APP_URL__
    }))(theseUsers);

    if (searchTerm && searchTerm.length) {
      const searchTermLowerCase = searchTerm.toLowerCase().trim();
      return users.filter(u =>
        u.name.toLowerCase().includes(searchTermLowerCase)
      );
    }

    return users;
  }
);
