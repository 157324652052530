import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import { actions, getters } from "../model";
import css from "./styles.scss";
import { MediumOutlineInput, MediumFilledBox } from "components/Base";

const decorate = R.compose(
  connect(
    state => ({
      startingNumber: getters.startingNumber(state),
      quantityToGenerate: getters.quantityToGenerate(state)
    }),
    {
      updateStartingNumber: actions.updateStartingNumber,
      updateQuantityToGenerate: actions.updateQuantityToGenerate
    }
  ),
  CSSModules(css)
);

const AutoGenerateOptions = ({
  updateStartingNumber,
  startingNumber,
  quantityToGenerate,
  updateQuantityToGenerate
}) => (
  <div styleName="rangeWrapper">
    <div styleName="row">
      <div styleName="column" style={{ marginRight: 8, flex: 1 }}>
        <div styleName="label">Starting number:</div>
        <MediumOutlineInput
          bg="white"
          width={1}
          type="number"
          continuous
          onChange={updateStartingNumber}
          value={startingNumber}
          min={1}
        />
      </div>
      <div styleName="column" style={{ marginLeft: 8, flex: 1 }}>
        <div styleName="label">Quantity to generate:</div>
        <div styleName="row">
          <MediumOutlineInput
            width={1}
            bg="white"
            value={quantityToGenerate}
            continuous
            onChange={updateQuantityToGenerate}
            min={1}
            type="number"
          />
        </div>
      </div>
    </div>
    <div styleName="column" style={{ marginTop: 8 }}>
      <div styleName="label">Sequence to be generated:</div>
      <MediumFilledBox width={1} bg="neutral1" color="neutral5">
        {startingNumber && quantityToGenerate
          ? `${startingNumber} - ${parseInt(startingNumber) +
              parseInt(quantityToGenerate)}`
          : ""}
      </MediumFilledBox>
    </div>
  </div>
);

export default decorate(AutoGenerateOptions);
