import * as R from "ramda";
import { createSelector } from "reselect";
import moment from "moment";
import { SCENES, SEND_DATE, FIELDS_IDS } from "./constants";
import { getters } from "./model";
import { capitalizeFirst } from "utils/General";

import * as FormSelectors from "ui-kit/Form/selectors";
import { getters as TabGetters } from "ui-kit/Tabs";

import { getters as SearchBarGetters } from "ui-kit/SearchBar";

export const getSceneTitle = R.always("Create Alert Message");

export const getActiveScene = createSelector(
  getters.alertPreview,
  R.compose(
    R.ifElse(R.isNil, R.always(SCENES.EDIT), R.always(SCENES.PREVIEW)),
    R.propOr(null, "id")
  )
);

export const isPreviousBtnVisible = createSelector(
  getActiveScene,
  R.equals(SCENES.PREVIEW)
);

export const hasAlreadyBeenSent = createSelector(
  getters.data,
  data => {
    return Boolean(data.sentAt) && Boolean(!data.isDraft);
  }
);

export const nextBtnText = createSelector(
  getActiveScene,
  getters.alertPreview,
  getters.sending,
  hasAlreadyBeenSent,
  (activeScene, previewData, sending, hasAlreadyBeenSent) => {
    if (hasAlreadyBeenSent) {
      return "Save Alert";
    }

    if (sending) {
      return "Sending alerts...";
    }

    if (R.equals(SCENES.PREVIEW, activeScene)) {
      return R.propEq("send_at", null, previewData)
        ? "Send Now"
        : "Save and schedule to send";
    }
    return "Preview";
  }
);

export const getEditedField = R.compose(
  field => R.prop("value", field),
  FormSelectors.getField
);

const snakeToTitleCase = R.compose(
  R.join(" "),
  R.map(capitalizeFirst),
  R.split("_")
);

export const getDeliveryMethods = createSelector(
  getters.data,
  alertData =>
    [
      R.propEq("deliverViaPortal", true, alertData) ? "Portal" : null,
      R.propEq("deliverViaEmail", true, alertData) ? "Email" : null,
      R.propEq("deliverViaSms", true, alertData) ? "SMS" : null
    ]
      .filter(d => d)
      .join(", ")
);

export const getCriteriaTabs = createSelector(
  getters.settings,
  R.compose(
    R.map(tabKey => ({ id: tabKey, title: snakeToTitleCase(tabKey) })),
    R.keys
  )
);

export const getSelectedCriteriaTab = createSelector(
  TabGetters.selectedTab,
  R.identity
);

const getCriteriaItems = createSelector(
  getSelectedCriteriaTab,
  getters.settings,
  (selectedTab, criteriaItems) => R.propOr([], selectedTab, criteriaItems)
);

export const getFilteredItems = createSelector(
  getCriteriaItems,
  R.compose(
    R.toLower,
    SearchBarGetters.searchTerm
  ),
  (criteriaItems, searchTerm) => {
    if (R.isNil(searchTerm) || R.isEmpty(searchTerm)) {
      return criteriaItems;
    }
    return R.filter(
      item => R.toLower(item.name).includes(searchTerm),
      criteriaItems
    );
  }
);

export const getCriteriaItemIds = createSelector(
  getCriteriaItems,
  R.map(R.prop("id"))
);

const getAllItemsSelectedByTabs = createSelector(
  getters.data,
  R.propOr({}, "sendTo")
);

export const getSelectedItemsOfTab = createSelector(
  getSelectedCriteriaTab,
  getAllItemsSelectedByTabs,
  (tabSelected, allItemsSelectedByTab) =>
    R.propOr([], tabSelected, allItemsSelectedByTab)
);

export const getAllSelectedItems = createSelector(
  getAllItemsSelectedByTabs,
  getters.settings,
  (selectedItemIdsByTab, settings) =>
    R.compose(
      R.filter(
        R.compose(
          R.not,
          R.isNil
        )
      ),
      R.map(criteriaKey => {
        const selectedItemIds = R.propOr([], criteriaKey, selectedItemIdsByTab);
        const allItems = R.propOr([], criteriaKey, settings);
        return R.isEmpty(selectedItemIds)
          ? null
          : {
              name: snakeToTitleCase(criteriaKey),
              id: criteriaKey,
              values: R.filter(
                ({ id }) => R.contains(id, selectedItemIds),
                allItems
              )
            };
      }),
      R.keys
    )(selectedItemIdsByTab)
);

const allItemsData = createSelector(
  getters.settings,
  R.compose(
    R.flatten,
    R.values
  )
);

const getSelectedCriteriaItemsWithData = createSelector(
  getAllItemsSelectedByTabs,
  allItemsData,
  (allItemsSelectedByTab, allItemsData) =>
    R.compose(
      R.map(itemId => R.find(R.propEq("id", itemId))(allItemsData)),
      R.flatten,
      R.values
    )(allItemsSelectedByTab)
);

export const getRecipientsCount = createSelector(
  getSelectedCriteriaItemsWithData,
  R.reduce((acc, item) => acc + R.propOr(0, "count_of_recipients", item), 0)
);

export const getRecipientsWithSMSCount = createSelector(
  getSelectedCriteriaItemsWithData,
  R.reduce(
    (acc, item) => acc + R.propOr(0, "count_of_recipients_with_sms", item),
    0
  )
);

export const getSendingSubtitle = createSelector(
  getters.data,
  R.ifElse(R.propEq("sendAtType", SEND_DATE.NOW), R.always("Now"), alertData =>
    moment(R.prop("sendAt", alertData)).format("LLL")
  )
);

export const getScheduledDate = createSelector(
  getters.alertPreview,
  R.ifElse(R.propEq("send_at", null), R.always("Now"), preview =>
    moment(R.prop("send_at", preview)).format("LLL")
  )
);

export const getStatsRecipients = createSelector(
  getters.alertPreview,
  R.pathOr("-", ["stats", "recipients"])
);

export const getStatsSMS = createSelector(
  getters.alertPreview,
  R.pathOr("-", ["stats", "recipients_with_sms"])
);

export const sendByEmail = createSelector(
  getters.alertPreview,
  R.propOr(false, "deliver_via_email")
);

export const sendViaPortal = createSelector(
  getters.alertPreview,
  R.propOr(false, "deliver_via_portal")
);

export const sendBySMS = createSelector(
  getters.alertPreview,
  R.propOr(false, "deliver_via_sms")
);

export const isMessageSectionCompleted = createSelector(
  getters.data,
  alertData => Boolean(R.propOr(false, FIELDS_IDS.MESSAGE.HEADER, alertData))
);

export const pickedDeliveryMethod = createSelector(
  getters.data,
  alertData =>
    R.propOr(false, FIELDS_IDS.DELIVERY.PORTAL_NOTIFICATION, alertData) ||
    R.propOr(false, FIELDS_IDS.DELIVERY.EMAIL, alertData) ||
    R.propOr(false, FIELDS_IDS.DELIVERY.SMS, alertData)
);

export const isPreviewEnabled = createSelector(
  isMessageSectionCompleted,
  pickedDeliveryMethod,
  getAllSelectedItems,
  (isMessageSectionCompleted, pickedDeliveryMethod, AllSelectedCriteriaItems) =>
    isMessageSectionCompleted &&
    pickedDeliveryMethod &&
    !R.isEmpty(AllSelectedCriteriaItems)
);
