import React from "react";
import { Div } from "components/Base";
import Table, { TableWrapper } from "ui-kit/Table/View";

const UsersTable = ({ instanceId }) => {
  return (
    <Div width={1}>
      <TableWrapper instanceId={instanceId} loading={false} style={{ flex: 1 }}>
        <Table showCheckbox={true} columnActions={[]} instanceId={instanceId} />
      </TableWrapper>
    </Div>
  );
};
export default UsersTable;
