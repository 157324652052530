import PropTypes from "prop-types";
import React, { Component } from "react";
import { cloneDeep, sortBy, get } from "lodash";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Required from "./Required";
import resolveEditor from "components/Global/StandAloneEditors/utils/resolveEditor";
import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";
import MakeCopies from "./MakeCopies";
import isAdminField from "components/Event/FormsV2/Utils/isAdminField";
import FormDisabledCheck from "components/Event/FormsV2/Sections/FormDisabledCheck";
import { DEFAULT_APPROVAL_FIELD_APPROVE_REJECT } from "components/Event/FormsV2/constants";
import Header from "../Header";

@CSSModules(css)
class Edit extends Component {
  onChange(row, column, oldValue, value) {
    this.props.updateFormValue(row, column, value, oldValue || "");
  }

  isFieldDisabled({ field, reviewStatus }) {
    if (this.props.isPreviewing) {
      return true;
    }

    if (isAdminField(field)) {
      return true;
    }

    if (["approved", "rejected"].includes(reviewStatus)) {
      return true;
    }

    return this.props.isResponseLocked;
  }

  duplicateRow = qty => this.props.duplicateRow(this.props.row.id, qty);

  render() {
    const {
      collapsed,
      duplicateRow,
      eventDetails,
      fieldsGroupedBySection,
      form,
      getRowMetaData,
      index,
      onCollapse,
      removeSelectedRows,
      row,
      scrollParent,
      showAdminFields,
      showMenu
    } = this.props;

    const reviewStatus = get(
      row.values[DEFAULT_APPROVAL_FIELD_APPROVE_REJECT.id],
      "value"
    );

    // If not able to view admin-only fields, reduce the headers to contain
    // fields that are not admin-only
    const sections = form.fields_grouped_by_section.reduce(
      (filteredSections, section) => {
        if (!showAdminFields) {
          section.fields = section.fields.filter(field => !isAdminField(field));
        }
        filteredSections.push(section);
        return filteredSections;
      },
      []
    );

    return (
      <div>
        <Header
          rowId={row.id}
          index={index}
          status={reviewStatus}
          collapsed={collapsed}
          onCollapse={onCollapse}
          showMenu={showMenu}
          duplicateRow={duplicateRow}
          removeSelectedRows={removeSelectedRows}
        />
        {sortBy(sections, "order").map(section => (
          <section styleName="section" key={section.id}>
            <h2 styleName="headerTitle">{section.name}</h2>
            <p styleName="headerDescription">{section.settings.description}</p>
            {sortBy(section.fields, "order").map(field => {
              const Editor = resolveEditor(field);
              const disabled = this.isFieldDisabled({
                field,
                reviewStatus
              });
              const editorProps = {
                scrollParent,
                onChange: this.onChange.bind(
                  this,
                  row.id,
                  field.id,
                  cloneDeep(row.values[field.id])
                ),
                column: field,
                columns: fieldsGroupedBySection,
                value: row.values[field.id],
                disabled,
                rowMetaData: getRowMetaData(row, field),
                ...resolveEditorProps(field, eventDetails)
              };
              return (
                <div key={field.id} styleName="sectionInput">
                  <FormDisabledCheck disabled={disabled}>
                    <Required editor={Editor} {...editorProps} />
                  </FormDisabledCheck>
                </div>
              );
            })}
          </section>
        ))}
        {!this.props.locked ? (
          <div styleName="actionButtons">
            <span styleName="button" onClick={onCollapse}>
              Collapse
              <div styleName="iconWrapper">
                <i className="material-icons">expand_more</i>
                <i className="material-icons">expand_less</i>
              </div>
            </span>
            {this.props.showMenu ? (
              <MakeCopies duplicate={this.duplicateRow}>
                <div styleName="button">
                  make duplicates<i className="material-icons">
                    control_point_duplicate
                  </i>
                </div>
              </MakeCopies>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

Edit.propTypes = {
  onCollapse: PropTypes.func.isRequired,
  collapsed: PropTypes.bool,
  columns: PropTypes.array.isRequired,
  duplicateRow: PropTypes.func.isRequired,
  eventDetails: PropTypes.object.isRequired,
  fieldsGroupedBySection: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  formId: PropTypes.string.isRequired,
  getRowMetaData: PropTypes.func.isRequired,
  headers: PropTypes.array.isRequired,
  isResponseLocked: PropTypes.bool.isRequired,
  isPreviewing: PropTypes.bool,
  registerValidator: PropTypes.func,
  row: PropTypes.object.isRequired,
  scrollParent: PropTypes.object.isRequired,
  showAdminFields: PropTypes.bool.isRequired,
  styles: PropTypes.object,
  unregisterValidator: PropTypes.func,
  updateFormValue: PropTypes.func.isRequired
};

export default Edit;
