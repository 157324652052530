import React from "react";
import { connect } from "react-redux";
import { Div } from "components/Base";
import CustomersPage from "./CustomersPage";
import SelectedPeople from "./SelectedPeople";
import SelectedGroups from "./SelectedGroups";
import { getIsSelectedAccount, getIsSelectedContact } from "../selectors";

const decorate = connect(state => ({
  isSelectedAccount: getIsSelectedAccount(state),
  isSelectedContact: getIsSelectedContact(state)
}));

const Customer = ({
  handlers,
  onSelectCustomer,
  isSelectedAccount,
  isSelectedContact
}) => (
  <Div style={{ maxHeight: 560, overflow: "auto" }}>
    {isSelectedAccount ? (
      <SelectedPeople onSelectCustomer={onSelectCustomer} handlers={handlers} />
    ) : null}
    {isSelectedContact ? (
      <SelectedGroups onSelectCustomer={onSelectCustomer} handlers={handlers} />
    ) : null}
    {!isSelectedAccount && !isSelectedContact ? (
      <CustomersPage handlers={handlers} onSelectCustomer={onSelectCustomer} />
    ) : null}
  </Div>
);

export default decorate(Customer);
