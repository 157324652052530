import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE, SCHEDULE_VIEWS } from "./constants";

const filterOutDefaultSortBy = (preferences = {}) => ({
  ...preferences,
  sort_by: R.compose(R.filter(s => !s.isDefault))(preferences.sort_by || [])
});

const DEFAULT_PREFERENCES = {
  visible_fields: [],
  field_order: {},
  field_widths: {},
  sort_by: [],
  quick_filters: [],
  filters: {},
  grouped_by: null,
  mode: "",
  resource_field_id: null
};

const iniState = {
  selectedView: SCHEDULE_VIEWS.GRID,
  loading: false,
  collapsed: false,
  schedule: {},
  scheduleId: null,
  moduleId: null,
  preferences: DEFAULT_PREFERENCES,
  originalPreferences: DEFAULT_PREFERENCES,
  catalogItemsModules: [],
  openedMenues: [],
  rows: [],
  columns: [],
  showNoResults: false,
  showNoSchedules: false,
  groupedByField: null,
  references: {},
  views: [],
  schedules: [],
  quickAddFields: [],
  showCreateModal: false,
  showActivityDetailsModal: false,
  selectedItemIds: [],
  showAllGroupByFields: false,
  resourceOptions: [],
  isEditScheduleLoading: false,
  isShowingEditScheduleModal: false
};

const model = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    duplicateRow: R.identity,
    insertRow: R.identity,
    deleteRow: R.identity,
    cancelInstance: R.always(iniState),
    init: R.identity,
    fetchData: R.identity,
    fetchViews: R.identity,
    exportData: R.identity,
    updateViewFields: R.identity,
    addActivity: R.identity,
    openActivityDetailsModal: R.identity,
    showEditorsModal: R.identity,
    hideColumn: R.identity,
    groupBy: R.identity,
    insertField: R.identity,
    editField: R.identity,
    showSubscribeModal: R.identity,
    showAddRecordModal: R.identity,
    showSelectFieldsModal: R.identity,
    refreshSchedule: R.identity,
    deleteSchedule: R.identity,
    updateSchedule: R.identity,
    updateScheduleView: (_, { payload }) => ({
      schedule: payload,
      isEditScheduleLoading: false,
      isShowingEditScheduleModal: false
    }),
    setInitialData: (
      { preferences: prevPreferences },
      {
        payload: {
          schedule,
          scheduleId,
          moduleId,
          preferences,
          schedules,
          quickAddFields,
          showNoSchedules
        }
      }
    ) => ({
      schedule,
      scheduleId,
      moduleId,
      preferences: {
        ...filterOutDefaultSortBy(preferences),
        mode: prevPreferences.mode || preferences.mode
      },
      originalPreferences: {
        ...filterOutDefaultSortBy(preferences),
        mode: prevPreferences.mode || preferences.mode
      },
      schedules,
      quickAddFields,
      showNoSchedules
    }),
    setScheduleData: (_, { payload: { schedule, quickAddFields } }) => ({
      schedule,
      quickAddFields
    }),
    receiveList: (_, { payload }) => ({
      preferences: filterOutDefaultSortBy(payload.preferences),
      references: payload.references || {},
      rows: payload.records || [],
      columns: payload.fields || [],
      showNoResults: (payload.records || []).length === 0,
      groupedByField: R.propOr("", "grouped_by", payload)
    }),
    addViewResponse: (state, { payload: newView }) => ({
      views: [...state.views, newView],
      preferences: filterOutDefaultSortBy(newView),
      originalPreferences: filterOutDefaultSortBy(newView)
    }),
    saveViewChangesResponse: (state, { payload: newView }) => ({
      views: state.views.map(v => (v.id === newView.id ? newView : v)),
      preferences: filterOutDefaultSortBy(newView),
      originalPreferences: filterOutDefaultSortBy(newView)
    }),
    revertViewChanges: state => ({
      preferences: state.originalPreferences
    }),
    selectActiveView: (state, { payload: activeView }) => ({
      preferences: filterOutDefaultSortBy(activeView),
      originalPreferences: filterOutDefaultSortBy(activeView)
    }),
    selectView: (state, { payload: view }) => {
      return {
        preferences: view,
        originalPreferences: view
      };
    },
    toggleCollapse: ({ collapsed }) => ({ collapsed: !collapsed }),
    toggleMenu: ({ openedMenues }, { payload: menuId }) => ({
      openedMenues: R.contains(menuId, openedMenues)
        ? R.without([menuId], openedMenues)
        : R.concat([menuId], openedMenues)
    }),
    toggleShowAllGroupByFields: ({ showAllGroupByFields }) => ({
      showAllGroupByFields: !showAllGroupByFields
    }),
    setGroupedByField: ({ preferences }, { payload: groupedBy }) => ({
      preferences: {
        ...preferences,
        grouped_by:
          !R.isNil(groupedBy) && groupedBy === R.prop("grouped_by", preferences)
            ? null
            : groupedBy
      }
    }),
    //
    setSelectedFieldFilters: (state, { payload: filters }) => ({
      preferences: {
        ...state.preferences,
        filters
      }
    }),
    removeSelectedFieldFilter: (state, { payload: idxOfFilter }) => {
      const updatedFilters = {
        filters: {
          ...state.preferences.filters.filters,
          filters: R.remove(idxOfFilter, 1)(
            state.preferences.filters.filters.filters
          )
        }
      };
      return {
        preferences: {
          ...state.preferences,
          filters: updatedFilters.filters.filters.length ? updatedFilters : {}
        }
      };
    },
    //
    setSelectedSortBy: (state, { payload: sortBy }) => ({
      preferences: {
        ...state.preferences,
        sort_by: [
          ...R.filter(f => f.fieldId !== sortBy.fieldId)(
            state.preferences.sort_by
          ),
          sortBy
        ]
      }
    }),
    removeSelectedSortBy: (state, { payload: idxOfFilter }) => {
      return {
        preferences: {
          ...state.preferences,
          sort_by: R.remove(idxOfFilter, 1)(state.preferences.sort_by)
        }
      };
    },
    //
    clearFilters: ({ preferences }) => {
      return {
        preferences: {
          ...preferences,
          sort_by: [],
          filters: {}
        }
      };
    },
    updateFieldWidths: ({ preferences }, { payload: fieldWidths }) => ({
      preferences: {
        ...preferences,
        field_widths: fieldWidths
      }
    }),
    updateVisibleFields: (
      { preferences },
      { payload: { visibleFields, fieldOrder } }
    ) => ({
      preferences: {
        ...preferences,
        visible_fields: visibleFields || preferences.visible_fields,
        field_order: fieldOrder || preferences.field_order
      }
    }),
    updateViewName: (
      { preferences, originalPreferences, views },
      { payload: { name } }
    ) => ({
      preferences: {
        ...preferences,
        name
      },
      originalPreferences: {
        ...originalPreferences,
        name
      },
      views: views.map(v => ({
        ...v,
        name: v.id === preferences.id ? name : v.name
      }))
    }),
    setSelectedView: ({ preferences }, { payload }) => ({
      preferences: {
        ...preferences,
        mode: payload // payload === SCHEDULE_VIEWS.GRID ? "" : "resource"
      }
    }),
    eventCreate: R.identity
  }
});

const { actions, getters } = model;

export { actions, getters };

export default model;
