import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import SwimLane from "./SwimLane";
import { Button } from "./SwimLane/EmptyState";
import CardActionButton from "./CardAction";
import CardActions from "./CardActions";
import CardActionLink from "./CardActionLink";
import SyncCardActionButton from "./SyncCardAction";
import ReactSwipe from "react-swipe";
import BookmarkIcon from "components/Atoms/BookmarkIcon";
import groupedSubmissions from "components/Event/FormsV2/Utils/groupedSubmissions";
import orderIsEventbrite from "components/Event/Credentials/Utils/eventbrite/order-is-eventbrite";
import Messages from "components/Global/CRM/Forms/Form/Submission/Messages";
import Helpers from "utils/Global/Helpers";
import MediaQuery from "react-responsive";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class SwimLanes extends Component {
  render() {
    const {
      addSubmission,
      eventDetails,
      fields,
      fieldsToShow,
      getMetaData,
      hasSubforms,
      onSendSubmissionConfirmation,
      onShowSubmissionSummary,
      onSubmissionMenuChange,
      orders = [],
      submissionToOrderMap = {},
      showSendFormModal,
      showSummaries,
      submissions,
      showSynced
    } = this.props;

    const states = groupedSubmissions(submissions, fields, {
      submissionToOrderMap,
      hasIntegration: showSynced,
      orders
    });

    const lanes = [];

    // Needs Review
    lanes.push(
      <SwimLane
        key="needsReview"
        onSubmissionMenuChange={onSubmissionMenuChange}
        onShowSubmissionSummary={onShowSubmissionSummary}
        cardActionButtons={submission =>
          submission.count_of_messages ? (
            <CardActions>
              <Messages
                countOfMessages={submission.count_of_messages}
                countOfUnreadMessages={submission.count_of_unread_messages}
                onClick={this.showSubmissionSummary}
              />
            </CardActions>
          ) : null
        }
        fieldsToShow={fieldsToShow}
        fields={fields}
        hasSubforms={hasSubforms}
        getMetaData={getMetaData}
        header="Needs Review"
        lane="needs-review"
        showSummaries={showSummaries}
        emptyActionButtons={[
          <Button label="Add Submission" onClick={addSubmission} />,
          <Button label="Send Form" onClick={showSendFormModal} />
        ]}
        emptyText="No new submissions"
        eventDetails={eventDetails}
        icon={<BookmarkIcon color="#813CD2" />}
        submissions={states.needsReview}
      />
    );

    // In Review
    lanes.push(
      <SwimLane
        key="inReview"
        onSubmissionMenuChange={onSubmissionMenuChange}
        onShowSubmissionSummary={onShowSubmissionSummary}
        fieldsToShow={fieldsToShow}
        lane="in-review"
        fields={fields}
        hasSubforms={hasSubforms}
        getMetaData={getMetaData}
        header="In Review"
        showSummaries={showSummaries}
        emptyText="Nothing in review"
        eventDetails={eventDetails}
        icon={<BookmarkIcon color="#DF822E" />}
        submissions={states.inReview}
      />
    );

    // Done
    lanes.push(
      <SwimLane
        key="done"
        onSubmissionMenuChange={onSubmissionMenuChange}
        onShowSubmissionSummary={onShowSubmissionSummary}
        cardActionButtons={submission => {
          const orderId = submissionToOrderMap[submission.id];
          const isEventBrite = orderIsEventbrite(orders[orderId]);
          if (isEventBrite) {
            return (
              <SyncCardActionButton
                submission={submission}
                orderId={orderId}
                eventId={this.props.eventId}
              />
            );
          }
          return submission.count_of_messages ? (
            <CardActions>
              <CardActionLink
                onClick={() => onSendSubmissionConfirmation(submission.id)}
              >
                Send Confirmation
              </CardActionLink>
              <Messages
                countOfMessages={submission.count_of_messages}
                countOfUnreadMessages={submission.count_of_unread_messages}
                onClick={this.showSubmissionSummary}
              />
            </CardActions>
          ) : (
            <CardActionButton
              onClick={() => onSendSubmissionConfirmation(submission.id)}
            >
              Send Confirmation
            </CardActionButton>
          );
        }}
        lane="done"
        fieldsToShow={fieldsToShow}
        fields={fields}
        hasSubforms={hasSubforms}
        getMetaData={getMetaData}
        header="Done"
        submissionToOrderMap={submissionToOrderMap}
        orders={orders}
        showSummaries={showSummaries}
        emptyText="Nothing done"
        eventDetails={eventDetails}
        icon={
          <div>
            <i className={["material-icons", css.bookmarkOver].join(" ")}>
              done
            </i>
            <BookmarkIcon color="#0BBD74" />
          </div>
        }
        submissions={states.done}
      />
    );

    if (showSynced) {
      // Synced
      lanes.push(
        <SwimLane
          key="synced"
          onSubmissionMenuChange={onSubmissionMenuChange}
          onShowSubmissionSummary={onShowSubmissionSummary}
          cardActionButtons={submission =>
            submission.count_of_messages ? (
              <CardActions>
                <CardActionLink
                  onClick={() => onSendSubmissionConfirmation(submission.id)}
                >
                  Send Confirmation
                </CardActionLink>
                <Messages
                  countOfMessages={submission.count_of_messages}
                  countOfUnreadMessages={submission.count_of_unread_messages}
                  onClick={this.showSubmissionSummary}
                />
              </CardActions>
            ) : (
              <CardActionButton
                onClick={() => onSendSubmissionConfirmation(submission.id)}
              >
                Send Confirmation
              </CardActionButton>
            )
          }
          fieldsToShow={fieldsToShow}
          fields={fields}
          hasSubforms={hasSubforms}
          getMetaData={getMetaData}
          header="Synced"
          submissionToOrderMap={submissionToOrderMap}
          orders={orders}
          showSummaries={showSummaries}
          lane="synced"
          emptyText="Nothing synced"
          eventDetails={eventDetails}
          icon={
            <div>
              <i className={["material-icons", css.bookmarkOver].join(" ")}>
                sync
              </i>
              <BookmarkIcon color="rgb(248,188,90)" />
            </div>
          }
          submissions={states.synced}
        />
      );
    }

    // Confirmation Sent
    lanes.push(
      <SwimLane
        key="confirmationSent"
        onSubmissionMenuChange={onSubmissionMenuChange}
        onShowSubmissionSummary={onShowSubmissionSummary}
        cardActionButtons={submission =>
          submission.count_of_messages ? (
            <CardActions>
              <CardActionLink
                onClick={() => onSendSubmissionConfirmation(submission.id)}
              >
                Re-send Confirmation
              </CardActionLink>
              <Messages
                countOfMessages={submission.count_of_messages}
                countOfUnreadMessages={submission.count_of_unread_messages}
                onClick={this.showSubmissionSummary}
              />
            </CardActions>
          ) : (
            <CardActionButton
              onClick={() => onSendSubmissionConfirmation(submission.id)}
            >
              Re-send Confirmation
            </CardActionButton>
          )
        }
        fieldsToShow={fieldsToShow}
        fields={fields}
        hasSubforms={hasSubforms}
        getMetaData={getMetaData}
        submissionToOrderMap={submissionToOrderMap}
        orders={orders}
        header="Confirmation Sent"
        lane="confirmation-sent"
        showSummaries={showSummaries}
        emptyText="No confirmations sent"
        eventDetails={eventDetails}
        icon={
          <div>
            <i className={["material-icons", css.bookmarkOver].join(" ")}>
              &#xE163;
            </i>
            <BookmarkIcon color="#0BBD74" />
          </div>
        }
        submissions={states.sent}
      />
    );

    return (
      <MediaQuery maxWidth={750}>
        {matches => {
          if (matches && Helpers.isTouchDevice()) {
            return (
              <ReactSwipe
                style={{
                  container: {
                    overflow: "hidden",
                    visibility: "hidden",
                    position: "relative",
                    width: "100vw"
                  },
                  wrapper: {
                    height: "60vh",
                    overflow: "hidden",
                    position: "relative"
                  },
                  child: {
                    float: "left",
                    width: "80%",
                    height: "100%",
                    position: "relative",
                    transitionProperty: "transform"
                  }
                }}
                swipeOptions={{ continuous: false }}
              >
                {lanes.map(l => l)}
              </ReactSwipe>
            );
          }
          return <div className={css.swimLanes}>{lanes.map(l => l)}</div>;
        }}
      </MediaQuery>
    );
  }
}

SwimLanes.propTypes = {
  addSubmission: PropTypes.func.isRequired,
  eventDetails: PropTypes.object.isRequired,
  orders: PropTypes.object.isRequired,
  submissionToOrderMap: PropTypes.object.isRequired,
  eventId: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  fieldsToShow: PropTypes.array.isRequired,
  getMetaData: PropTypes.func.isRequired,
  hasSubforms: PropTypes.bool.isRequired,
  onSendSubmissionConfirmation: PropTypes.func.isRequired,
  onShowSubmissionSummary: PropTypes.func.isRequired,
  onSubmissionMenuChange: PropTypes.func.isRequired,
  showSendFormModal: PropTypes.func.isRequired,
  showSummaries: PropTypes.bool,
  showSynced: PropTypes.bool,
  submissions: PropTypes.array.isRequired
};

export default SwimLanes;
