import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../model";
import { addS } from "utils/General";

import {
  CollapsablePanelType1,
  Div,
  BigShadedInput,
  BigOutlineButton,
  BigFilledButton,
  SearchIcon
} from "components/Base";

import { getItemGroups } from "../selectors";
import { TypesTable } from "./Tables";

const mapIndexed = R.addIndex(R.map);

const decorate = connect(
  state => ({
    itemGroups: getItemGroups(state),
    searchTerm: getters.searchTerm(state)
  }),
  {
    onSearch: v => actions.setSearchTerm(v),
    showUpdateGroupModal: actions.showUpdateGroupModal,
    showDeleteGroupModal: actions.showDeleteGroupModal,
    showItemModal: actions.showItemModal,
    deleteItem: actions.deleteItem,
    moveItemUp: actions.moveItemUp,
    moveItemDown: actions.moveItemDown,
    moveGroupUp: actions.moveGroupUp,
    moveGroupDown: actions.moveGroupDown,
    reorderItems: actions.reorderItems,
    showAddGroupModal: actions.showAddGroupModal
  }
);

const Body = ({
  itemGroups,
  onSearch,
  showUpdateGroupModal,
  showDeleteGroupModal,
  showItemModal,
  deleteItem,
  moveItemUp,
  moveItemDown,
  isLimited,
  searchTerm,
  moveGroupUp,
  moveGroupDown,
  reorderItems,
  showAddGroupModal
}) => {
  const groupsWithHandlers = mapIndexed((group, index) => {
    const items = group.items;
    return {
      ...group,
      onEdit: () => showUpdateGroupModal(group),
      onDelete:
        itemGroups.length > 1 ? () => showDeleteGroupModal(group) : null,
      moveGroupUp: index === 0 ? undefined : () => moveGroupUp(index),
      moveGroupDown:
        index === itemGroups.length - 1
          ? undefined
          : () => moveGroupDown(index),
      reorderItems: newOrder => reorderItems(newOrder),
      items: mapIndexed((item, cIndex) => {
        return {
          ...item,
          onItemClick: () => showItemModal({ itemId: item.id }),
          onEdit: () => showItemModal({ itemId: item.id }),
          onClone: () => showItemModal({ itemId: item.id, clone: true }),
          onDelete: () => deleteItem(item.id),
          moveItemUp:
            cIndex === 0 || searchTerm.length
              ? undefined
              : () =>
                  moveItemUp({ groupId: group.id, currentPosition: cIndex }),
          moveItemDown:
            cIndex === items.length - 1 || searchTerm.length
              ? undefined
              : () =>
                  moveItemDown({ groupId: group.id, currentPosition: cIndex })
        };
      }, items)
    };
  }, itemGroups);

  return (
    <Div display="row" px={8}>
      <Div width={1} pb={4}>
        <Div display="row.flex-start.center" pb={2}>
          <BigShadedInput
            flex={1}
            placeholder="Search items"
            onChange={onSearch}
            continuous
            LeftIcon={SearchIcon}
            maxLength={32}
            mr={2}
          />
          {isLimited ? null : (
            <BigOutlineButton mr={1} onClick={showAddGroupModal}>
              Add Category
            </BigOutlineButton>
          )}
          <BigFilledButton
            bg="altB5"
            color="white"
            onClick={() => showItemModal()}
          >
            Add Item
          </BigFilledButton>
        </Div>
        <Div pt={4}>
          {groupsWithHandlers.map(
            ({
              id,
              name,
              countOfItems,
              onEdit,
              onDelete,
              items,
              moveGroupUp,
              moveGroupDown,
              collapsedDefault,
              forceCollapse,
              reorderItems
            }) => (
              <CollapsablePanelType1
                key={`${id}_${countOfItems}`}
                headingText={name}
                subText={`${countOfItems} type${addS(countOfItems)}`}
                menuItems={[
                  ["Edit", onEdit],
                  !isLimited && onDelete ? ["Delete", onDelete] : undefined,
                  moveGroupUp ? ["Move up", moveGroupUp] : undefined,
                  moveGroupDown ? ["Move down", moveGroupDown] : undefined
                ].filter(i => i)}
                forceCollapse={forceCollapse}
                collapsedDefault={collapsedDefault}
              >
                <TypesTable
                  types={items}
                  dragTypeId={"TypesTable" + id}
                  onReorder={reorderItems}
                />
              </CollapsablePanelType1>
            )
          )}
        </Div>
      </Div>
    </Div>
  );
};

export default decorate(Body);
