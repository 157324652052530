import { put, all, takeEvery, fork, select, call } from "redux-saga/effects";
import * as R from "ramda";
import * as STANDARD_MODULE_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import { actions, getters } from "Portal/ViewPortalAsModal/model";
import { registerError } from "redux/modules/errors/actions";
import { push } from "react-router-redux";

import Api from "./api";
import RecordsApi from "redux/modules/modules/records/api";

import { getCredentials } from "redux/modules/user/selectors";
import { user as getUser } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";

const init = function*({ payload: { moduleId, recordTypeId } }) {
  try {
    const credentials = yield select(getCredentials);
    const eventId = yield select(getEventId);

    const payload = yield call(RecordsApi.get, credentials, {
      moduleId,
      options: {
        eventId,
        recordTypeId
      }
    });

    yield all([
      put(
        actions.setInitialData({
          records: payload,
          moduleId,
          recordTypeId
        })
      )
    ]);
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred geting records"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const goToPortal = function*({ payload }) {
  /*
  let eventDetails = yield select(getEventDetails);
  yield put(
    push(`/submissions/${eventDetails.slug}/${formSlug}/${submission.id}`)
  );
  */
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchGoToPortal = function*() {
  yield takeEvery(actions.goToPortal.type, goToPortal);
};

const rootSaga = function*() {
  yield all([fork(watchInit), fork(watchGoToPortal)]);
};

export default rootSaga;
