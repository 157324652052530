import React, { Fragment } from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { actions, getters } from "Orders/OrderModal";
import {
  getOrderId,
  getAttendee,
  getInfo,
  getOrderTitle
} from "Orders/OrderModal/selectors";

import { Paper, EditableValue } from "Orders/OrderModal/View/Common";
import AttendeeCard from "ui-kit/AvatarCard";

import { Div, Text1, Text2, Text4, InfoIcon } from "components/Base";

import { noop } from "utils/General";

const Info = ({ infos = [], title, ...props }) => (
  <Div style={{ flexShrink: 0 }} {...props}>
    <Div fs={3} fw={3} color="neutral6">
      {title}
    </Div>
    {R.map(
      section => (
        <Fragment key={section.title}>
          <Div display="row.flex-start.center" mt={4} mb={1}>
            <Div fs={3} fw={3} color="neutral6">
              {section.title}
            </Div>

            {section.tooltip ? (
              <InfoIcon
                ml={1}
                tooltip={section.tooltip}
                color="gray5"
                size={15}
              />
            ) : null}
          </Div>
          {R.map(
            info => (
              <Div fs={3} fw={4} color="neutral7">
                {info}
              </Div>
            ),
            section.infos
          )}
        </Fragment>
      ),
      infos
    )}
  </Div>
);

const decorate = connect(
  state => ({
    orderId: getOrderId(state),
    attendee: getAttendee(state),
    info: getInfo(state, {}),
    orderTitle: getOrderTitle(state),
    editingEmail: getters.editingEmail(state),
    updatedEmail: getters.updatedEmail(state),
    editingPhone: getters.editingPhone(state),
    updatedPhone: getters.updatedPhone(state),
    restricted: getters.isPortalUser(state)
  }),
  {
    goToProfile: actions.viewProfile,
    setEditingEmail: actions.setEditingEmail,
    setUpdatedEmail: actions.setUpdatedEmail,
    saveEmailAddress: actions.saveEmailAddress,
    setEditingPhone: actions.setEditingPhone,
    setUpdatedPhone: actions.setUpdatedPhone,
    savePhone: actions.savePhone
  }
);

const Sidebar = ({
  orderId,
  attendee,
  info = [],
  orderTitle,
  handlers,
  goToProfile = noop,
  setEditingEmail,
  editingEmail,
  updatedEmail,
  setUpdatedEmail,
  saveEmailAddress,
  setEditingPhone,
  editingPhone,
  updatedPhone,
  setUpdatedPhone,
  savePhone,
  restricted = false
}) => (
  <Div bg="neutral0" bs={1} width={1} height={1} pb={8}>
    <Paper p={5} bra={0}>
      <Div display="row.space-between.center">
        <Text1 bold>{orderTitle}</Text1>
        {restricted ? null : (
          <Text1
            color="primary7"
            bb={1}
            bc="primary7"
            onClick={() => handlers.editOrder(orderId)}
          >
            Edit
          </Text1>
        )}
      </Div>
      <AttendeeCard avatar={attendee} mt={3} width={1}>
        <Div display="column.flex-start.flex-start" height={1} ml={2}>
          <Text4 bold width={1}>
            {attendee.name}
          </Text4>

          {attendee.type === "contact" &&
          attendee.id &&
          attendee.name &&
          attendee.name.length ? (
            <Div display="column.flex-start.flex-start">
              <EditableValue
                {...{
                  toggled: editingEmail,
                  placeholder: "Add email",
                  updatedValue: updatedEmail,
                  setUpdatedValue: setUpdatedEmail,
                  saveUpdatedValue: data => {
                    handlers.flagAsModified();
                    saveEmailAddress(data);
                  },
                  setToggle: setEditingEmail,
                  originalValue: attendee.email,
                  validateEmail: true,
                  mb: 1,
                  readOnly: restricted
                }}
              />
              <EditableValue
                {...{
                  toggled: editingPhone,
                  placeholder: "Add phone",
                  updatedValue: updatedPhone,
                  setUpdatedValue: setUpdatedPhone,
                  saveUpdatedValue: data => {
                    handlers.flagAsModified();
                    savePhone(data);
                  },
                  setToggle: setEditingPhone,
                  originalValue: attendee.phone,
                  readOnly: restricted
                }}
              />
            </Div>
          ) : null}
        </Div>
      </AttendeeCard>
      {attendee.secondaryId ? (
        <Div
          display="column.flex-start.flex-start"
          mt={3}
          pt={3}
          bt={1}
          bc="neutral3"
        >
          <Text2>{attendee.secondaryLabel}:</Text2>
          <Text2
            bold
            mt={1}
            bb={1}
            bc="neutral6"
            onClick={
              restricted
                ? undefined
                : () =>
                    goToProfile({
                      type: attendee.secondaryType,
                      id: attendee.secondaryId
                    })
            }
          >
            {attendee.secondaryName}
          </Text2>
          {attendee.secondaryType === "contact" &&
          attendee.secondaryId &&
          attendee.secondaryLabel &&
          attendee.secondaryLabel.length ? (
            <Div display="column.flex-start.flex-start" mt={1}>
              <EditableValue
                {...{
                  toggled: editingEmail,
                  placeholder: "Add email",
                  updatedValue: updatedEmail,
                  setUpdatedValue: setUpdatedEmail,
                  saveUpdatedValue: data => {
                    handlers.flagAsModified();
                    saveEmailAddress(data);
                  },
                  setToggle: setEditingEmail,
                  originalValue: attendee.email,
                  validateEmail: true,
                  mb: 1,
                  readOnly: restricted
                }}
              />
              <EditableValue
                {...{
                  toggled: editingPhone,
                  placeholder: "Add phone",
                  updatedValue: updatedPhone,
                  setUpdatedValue: setUpdatedPhone,
                  saveUpdatedValue: data => {
                    handlers.flagAsModified();
                    savePhone(data);
                  },
                  setToggle: setEditingPhone,
                  originalValue: attendee.phone,
                  readOnly: restricted
                }}
              />
            </Div>
          ) : null}
        </Div>
      ) : null}
    </Paper>
    <Info infos={info} mx={5} mt={4} title="OTHER INFORMATION" />
  </Div>
);

export default decorate(Sidebar);
