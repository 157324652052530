import React from "react";
import * as R from "ramda";
import {
  Div,
  SuperOutlineButton,
  BigFilledButton,
  SuperFilledButton,
  Text0,
  Text3,
  RightArrowIcon,
  AddCircleIcon
} from "components/Base";
import Header from "Forms/WizardModal/View/Header";
import FieldItem from "./SelectQuestionsItem";

const SelectQuestions = ({
  handlers,
  back,
  moduleSelected,
  toggleSelectedModuleField,
  toggleRequiredModuleField,
  isButtonDisabled,
  goToReview,
  selectedAllModuleField,
  deselectedAllModuleField,
  source = "custom"
}) => (
  <Div width={1} height={1}>
    <Header handlers={handlers} steps={["Step 1", "Step 2"]} />
    <Div
      bg="neutral0"
      display="column.flex-start.center"
      p={5}
      style={{ height: "calc(100% - 157px)", overflow: "auto" }}
    >
      <Div my={5} display="column.center.center" style={{ flexShrink: 0 }}>
        <Div fs={6} fw={4} color="black">
          Select the questions to ask...
        </Div>
      </Div>

      <Div
        bg="neutral1"
        bra={1}
        p={3}
        width={595}
        style={{ overflow: "visible" }}
      >
        <Text3 bold mb={3}>
          {moduleSelected.name}
        </Text3>
        <Div width={1} display="row.space-between.center" px={1} mb={1}>
          <Div display="row.flex-start.center">
            <Text0 color="neutral6">Include Question</Text0>
            <Div display="row.flex-start.center" ml={4}>
              <Text0
                ml={2}
                color="primary9"
                underline
                onClick={() => {
                  selectedAllModuleField({ moduleId: moduleSelected.id });
                }}
              >
                Select All
              </Text0>
              <Text0 mx={1} color="primary9">
                -
              </Text0>
              <Text0
                color="primary9"
                underline
                onClick={() => {
                  deselectedAllModuleField({ moduleId: moduleSelected.id });
                }}
              >
                Deselect All
              </Text0>
            </Div>
          </Div>
          <Text0 color="neutral6">*Require</Text0>
        </Div>
        <Div width={1}>
          {R.map(
            field => (
              <FieldItem
                {...field}
                toggleSelectedModuleField={toggleSelectedModuleField}
                toggleRequiredModuleField={toggleRequiredModuleField}
                moduleId={moduleSelected.id}
                handlers={handlers}
                key={field.id}
              />
            ),
            R.propOr([], "fields", moduleSelected)
          )}
        </Div>
        <Div width={1} display="row.space-between" mt={2}>
          <BigFilledButton
            bg="neutral3"
            color="black"
            onClick={() => {
              handlers.showCustomAddFieldModal({
                moduleId: moduleSelected.id,
                source
              });
            }}
            LeftIcon={AddCircleIcon}
          >
            Add another question
          </BigFilledButton>
        </Div>
      </Div>
    </Div>
    <Div
      display="row.space-between.center"
      width={1}
      height={90}
      bt={1}
      bc="neutral2"
      bg="white"
      p={5}
      style={{ flexShrink: 0 }}
    >
      <SuperOutlineButton onClick={back}>Back</SuperOutlineButton>
      <Div display="row">
        <SuperOutlineButton onClick={handlers.hideModal}>
          Cancel
        </SuperOutlineButton>
        <SuperFilledButton
          ml={3}
          bg="altB5"
          onClick={goToReview}
          RightIcon={RightArrowIcon}
          disabled={isButtonDisabled}
        >
          Review
        </SuperFilledButton>
      </Div>
    </Div>
  </Div>
);

export default SelectQuestions;
