export const NAMESPACE = "BulkQuestionItemsModal";

export const PAGES = {
  SELECT_QUESTIONS: "selectQuestions",
  ASSIGN_ITEMS: "assignItems"
};

export const HEIGHTS = {
  HEADER: 64,
  FOOTER: 89
};

export const BULK_QUESTIONS_PAGE = {
  SELECT_QUESTIONS: "selectQuestions",
  ASSIGN_ITEMS: "assignItems"
};
