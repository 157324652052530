import { get } from "lodash";

export const isFetching = (state, recordId) =>
  get(state, ["activities", "fetching", "byId", recordId]);

export const report = (state, reportId) =>
  get(state, ["reports", "report", "byId", reportId], []);

export const fields = (state, reportId) =>
  get(state, ["reports", "report", "byId", reportId, "fields"], []);

export const rows = (state, reportId) =>
  get(state, ["reports", "report", "byId", reportId, "rows"], []);
