import React, { Component } from "react";
import { get } from "lodash";
import * as R from "ramda";
import isValid from "utils/value-types/validations/is-valid";

import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Body from "components/Global/Modal/Layout/ScrollableBody";
import {
  ButtonGroup,
  Submit,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";
import getMetaData from "utils/value-types/get-meta-data";
import { Div, Dropdown, Span } from "components/Base";
import { Loading } from "components/Global/Module/Modals/AddRecord/Layouts/Common";
import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";
import resolveEditor from "components/Global/StandAloneEditors/utils/resolveEditor";
import FormElements from "components/Global/Modal/Layout/FormElements";
const { Label, InputSection, InputGroup } = FormElements;
import * as STANDARD_MODULE_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import AddScheduleModal from "Schedules/Create/View";

import Api from "Schedules/Schedule/api";
import { connect } from "react-redux";
import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";

import moment from "moment";

const decorate = connect(state => ({
  credentials: getCredentials(state),
  eventId: getEventId(state)
}));

class AddRecordModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      loadingFields: false,
      saving: false,
      type: props.onlyRecordTypeId || null,
      values: { ...props.values },
      scene: 0,
      createdRecord: null,
      // schedule props
      showEmptyState: false,
      selectedScheduleId: props.moduleId === "master" ? null : props.moduleId,
      schedule: props.schedule || {},
      fields: props.fields || [],
      preferences: props.preferences || {},
      schedules: props.schedules || []
    };
  }

  async componentDidMount() {
    let selectedScheduleId = this.state.selectedScheduleId;

    const { payload: schedulesPayload } = await Api.getSchedules({
      credentials: this.props.credentials,
      eventId: this.props.eventId
    });

    const schedules = R.filter(s => s.id !== "master")(schedulesPayload);

    if (!schedules.length) {
      this.setState({
        showEmptyState: true,
        loading: false
      });
      return true;
    }

    if (!selectedScheduleId) {
      selectedScheduleId = R.compose(
        R.prop("id"),
        R.head
      )(schedules);
    }

    const [
      schedulePayload,
      fieldsPayload,
      preferencesPayload
    ] = await Promise.all([
      Api.getSchedule({
        credentials: this.props.credentials,
        eventId: this.props.eventId,
        moduleId: selectedScheduleId
      }),
      Api.getFields({
        credentials: this.props.credentials,
        moduleId: selectedScheduleId,
        data: {
          eventId: this.props.eventId
        }
      }),
      Api.getActiveView({
        credentials: this.props.credentials,
        eventId: this.props.eventId,
        moduleId: selectedScheduleId
      })
    ]);

    const schedule = schedulePayload.payload;
    const fields = fieldsPayload.payload.all_fields;
    const preferences = preferencesPayload.payload;

    this.setState({
      loading: false,
      schedule,
      fields: R.compose(
        R.sortBy(f => preferences.field_order[f.id]),
        R.filter(f => preferences.visible_fields.includes(f.id)),
        R.filter(
          f =>
            ![
              STANDARD_MODULE_FIELD_IDS.SCHEDULES.SCHEDULE,
              STANDARD_MODULE_FIELD_IDS.SCHEDULES.COMPLETE
            ].includes(f.id)
        )
      )(fields),
      preferences,
      selectedScheduleId,
      schedules
    });
    return true;
  }

  componentWillReceiveProps(newProps) {
    this.setState({ ...newProps.values });
    if (this.state.type === null && newProps.recordTypes.length) {
      this.setState({
        type:
          get(newProps.recordTypes.find(t => t.is_default), "id") ||
          get(newProps.recordTypes, "[0].id")
      });
    }
  }

  updateSelectedSchedule = async scheduleId => {
    this.setState({ loadingFields: true });

    const [
      schedulePayload,
      fieldsPayload,
      preferencesPayload
    ] = await Promise.all([
      Api.getSchedule({
        credentials: this.props.credentials,
        eventId: this.props.eventId,
        moduleId: scheduleId
      }),
      Api.getFields({
        credentials: this.props.credentials,
        moduleId: scheduleId,
        data: {
          eventId: this.props.eventId
        }
      }),
      Api.getActiveView({
        credentials: this.props.credentials,
        eventId: this.props.eventId,
        moduleId: scheduleId
      })
    ]);

    const schedule = schedulePayload.payload;
    const fields = fieldsPayload.payload.all_fields;
    const preferences = preferencesPayload.payload;

    this.setState({
      loadingFields: false,
      selectedScheduleId: scheduleId,
      schedule,
      fields: R.compose(
        R.sortBy(f => preferences.field_order[f.id]),
        R.filter(f => preferences.visible_fields.includes(f.id)),
        R.filter(
          f =>
            ![
              STANDARD_MODULE_FIELD_IDS.SCHEDULES.SCHEDULE,
              STANDARD_MODULE_FIELD_IDS.SCHEDULES.COMPLETE
            ].includes(f.id)
        )
      )(fields),
      preferences
    });
  };

  getRecordType = id => {
    return this.props.recordTypes.find(t => t.id === id);
  };

  getRecordTypeName = id => {
    const type = this.props.recordTypes.find(t => t.id === id);
    return type && type.name ? type.name : type;
  };

  getFieldsToShow = () => {
    return [];
  };

  validateForm = () => {
    if (this.state.showEmptyState) {
      return false;
    }

    const requiredFields = R.compose(R.filter(R.prop("isRequired")))(
      this.getFieldsToShow()
    );

    if (requiredFields.length) {
      for (const field of requiredFields) {
        const isValidValue = isValid(this.state.values[field.id], field.type);
        if (!isValidValue) {
          return false;
        }
      }
    }

    return true;
  };

  saveFieldValue = (id, val) => {
    // don't save empty values, but do save zero values
    this.setState(state => {
      if (val && (val.value || typeof val.value === "number")) {
        let startsAtValue =
          state.values[STANDARD_MODULE_FIELD_IDS.SCHEDULES.STARTS_AT];
        let startsAtIso = R.path(["value", "iso"])(startsAtValue);

        let endsAtValue =
          state.values[STANDARD_MODULE_FIELD_IDS.SCHEDULES.ENDS_AT];
        let endsAtIso = R.path(["value", "iso"])(endsAtValue);

        if (id === STANDARD_MODULE_FIELD_IDS.SCHEDULES.STARTS_AT) {
          startsAtIso = R.path(["value", "iso"])(val);
          if (startsAtIso && !endsAtIso) {
            endsAtValue = val;
          } else if (
            startsAtIso &&
            moment(new Date(startsAtIso)).isAfter(endsAtIso)
          ) {
            endsAtValue = val;
          }
        }

        if (id === STANDARD_MODULE_FIELD_IDS.SCHEDULES.ENDS_AT) {
          endsAtIso = R.path(["value", "iso"])(val);
          if (endsAtIso && !startsAtIso) {
            startsAtValue = val;
          } else if (
            endsAtIso &&
            moment(new Date(startsAtIso)).isAfter(endsAtIso)
          ) {
            startsAtValue = val;
          }
        }

        state.values = {
          ...state.values,
          [STANDARD_MODULE_FIELD_IDS.SCHEDULES.STARTS_AT]: startsAtValue,
          [STANDARD_MODULE_FIELD_IDS.SCHEDULES.ENDS_AT]: endsAtValue,
          [id]: val
        };
        return state;
      }
      delete state.values[id];
      return state;
    });
  };

  resetState = valuesToPersist => {
    this.setState(
      state => {
        state.scene = 0;
        state.createdRecord = null;
        Object.keys(state).forEach(id => delete state.values[id]);
        return state;
      },
      () => {
        this.setState({ values: { ...this.props.values, ...valuesToPersist } });
      }
    );
  };

  handleSave = async () => {
    if (this.validateForm()) {
      this.setState({ saving: true });
      const { payload } = await Api.addActivity(
        {
          credentials: this.props.credentials,
          moduleId: this.state.selectedScheduleId
        },
        {
          eventId: this.props.eventId,
          moduleId: this.state.selectedScheduleId,
          values: R.compose(
            vals =>
              R.reduce((map, fieldId) => {
                map[fieldId] = {
                  fieldId,
                  value: vals[fieldId]
                };
                return map;
              }, {})(R.keys(vals)),
            R.pick(this.state.fields.map(f => f.id))
          )(this.state.values)
        }
      );
      this.setState({ saving: false });
      return payload;
    }

    return false;
  };

  // eslint-disable-next-line consistent-return
  saveAndKeepOpen = async () => {
    const record = await this.handleSave();

    // fields to persist
    const fieldIds = R.compose(
      R.map(R.prop("id")),
      R.filter(f => ["lookup", "date", "time", "datetime"].includes(f.type))
    )(this.state.fields);

    this.resetState(R.pick(fieldIds, this.state.values));

    // call onSave to trigger specified action even though
    // we're keeping modal open. example use case: the caller
    // needs to refetch records when new record is added.
    if (this.props.onSave) {
      return this.props.onSave(record);
    }
    return false;
  };

  saveAndClose = async () => {
    const record = await this.handleSave();
    if (this.props.onSave) {
      this.props.onSave(record);
    }
    this.props.hideModal();
  };

  cancelAndClose = () => {
    if (typeof this.props.onCancel === "function") {
      this.props.onCancel();
    }
    this.props.hideModal();
  };

  getRowMetaData = ({ row, field, fields }) =>
    getMetaData({
      row,
      rowId: 0,
      field,
      fields,
      orgDetails: this.props.orgDetails,
      orgId: get(this.props.orgDetails, "id"),
      eventDetails: this.props.eventDetails,
      eventId: get(this.props.eventDetails, "id"),
      moduleId: this.state.selectedScheduleId,
      references: this.props.references
    });

  getFieldEditor = ({ field, fields }) => {
    const Editor = resolveEditor(field);
    let disabled;

    if (field.settings?.isReferenceField) {
      const referenceField = this.state.fields.find(
        f => f.id === field.settings.lookupFieldId
      );
      disabled = referenceField ? (
        <Div mt={2} bg="neutral0" p={3} fs={2} bra={1}>
          This field references the record you select for "{referenceField.name}
          "
        </Div>
      ) : (
        false
      );
    }

    return (
      <Div>
        <Label>
          <Div display="row.space-between.center" mb={1}>
            <Div fw={3} color="gray7">
              {field.name}
            </Div>
            {field.isRequired ? (
              <Div color="gray5" fs={1}>
                * required
              </Div>
            ) : null}
          </Div>
        </Label>
        {disabled || (
          <Editor
            rowMetaData={this.getRowMetaData({
              row: this.state.values,
              field,
              fields
            })}
            value={this.state.values[field.id]}
            onChange={val => this.saveFieldValue(field.id, val)}
            {...resolveEditorProps(field, this.props.eventDetails)}
            data-cy={field.id}
          />
        )}
      </Div>
    );
  };

  renderFields = fields => {
    return (
      <div>
        {fields.length > 0 ? (
          fields.map(field => {
            return (
              <InputSection key={field.id}>
                <InputGroup>
                  {this.getFieldEditor({
                    field,
                    fields
                  })}
                </InputGroup>
              </InputSection>
            );
          })
        ) : (
          <span>No visible fields!</span>
        )}
      </div>
    );
  };

  showAddScheduleModal = () => {
    this.props.showModal({
      content: <AddScheduleModal />,
      wrapper: ModalWrapper
    });
  };

  render() {
    const {
      loading,
      loadingFields,
      schedule,
      showEmptyState,
      schedules,
      fields,
      selectedScheduleId,
      saving
    } = this.state;

    return (
      <StyleWrapper
        bodyStyles={{ padding: 0 }}
        containerStyles={{ overflowY: "hidden" }}
        heading={
          <Div display="column">
            <span>Add Activity</span>
            <span
              style={{
                fontSize: 13,
                color: "rgba(255, 255, 255, 0.8)"
              }}
            >
              {schedule.name}
            </span>
          </Div>
        }
        hideModal={this.cancelAndClose}
        width={475}
        data-cy="add-record-modal"
      >
        <Body style={{ padding: "20px 30px 10px 30px" }}>
          {loading ? (
            <Loading />
          ) : showEmptyState ? (
            <Div py={3}>
              You haven't created any schedules yet.{" "}
              <Span
                style={{
                  textDecoration: "underline"
                }}
                onClick={this.showAddScheduleModal}
              >
                Click here
              </Span>{" "}
              to create a schedule.
            </Div>
          ) : (
            <Div>
              <Div display="column" width={1}>
                <InputGroup>
                  <Label>
                    <Div display="row.space-between.center" mb={1}>
                      <Div fw={3} color="gray7">
                        Schedule
                      </Div>
                    </Div>
                  </Label>

                  <Dropdown
                    clearable={false}
                    onChange={({ value }) => this.updateSelectedSchedule(value)}
                    usePortal
                    options={schedules.map(f => ({
                      label: f.name,
                      value: f.id
                    }))}
                    value={selectedScheduleId}
                  />
                </InputGroup>
              </Div>
              <Div>
                {loadingFields ? <Loading /> : this.renderFields(fields)}
              </Div>
            </Div>
          )}
        </Body>
        <Div
          display="row.space-between.center"
          style={{
            padding: "0 30px"
          }}
        >
          <ButtonGroup>
            <Submit
              title="Save"
              onClick={this.saveAndClose}
              disabled={!this.validateForm()}
              data-cy="add-record-submit"
            />
            <ButtonOutline title="Cancel" onClick={this.cancelAndClose} />
          </ButtonGroup>
          {showEmptyState ? null : (
            <Div
              style={{
                color: "#52495b",
                fontSize: 16,
                opacity: 0.49,
                padding: "5px 0",
                textAlign: "center",
                textDecoration: "underline",
                cursor: this.validateForm() ? "pointer" : "not-allowed"
              }}
              onClick={
                saving
                  ? undefined
                  : this.validateForm()
                  ? this.saveAndKeepOpen
                  : null
              }
            >
              {saving ? "Saving..." : "Save and add another"}
            </Div>
          )}
        </Div>
      </StyleWrapper>
    );
  }
}

AddRecordModal.defaultProps = {
  values: {}
};

export default decorate(AddRecordModal);
