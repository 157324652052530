import { defaultMealState } from "./reducer";
import { get } from "lodash";

export const latestScan = (state, mealId) =>
  get(
    state.catering.checkin.mealCheckins.meals,
    [mealId, "latestScan"],
    defaultMealState.latestScan
  );
export const scans = (state, mealId) =>
  get(
    state.catering.checkin.mealCheckins.meals,
    [mealId, "scans"],
    defaultMealState.scans
  );
export const countOfOverrides = (state, mealId) =>
  get(
    state.catering.checkin.mealCheckins.meals,
    [mealId, "overrides"],
    defaultMealState.overrides
  );
export const countOfCheckins = (state, mealId) =>
  get(
    state.catering.checkin.mealCheckins.meals,
    [mealId, "checkins"],
    defaultMealState.checkins
  );
export const isScanning = state => state.catering.checkin.mealCheckins.fetching;
