import React from "react";
import { connect } from "react-redux";

import { actions, getters } from "EventLight/Expo/Sales/model";

import {
  getModalSelectedQuestions,
  getModalQuestions,
  getSelectQuestionsModalTitle
} from "EventLight/Expo/Sales/selectors";

import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import SelectFields from "ui-kit/SelectFields/View";

import { noop } from "utils/General";

const decorate = connect(
  (state, props) => ({
    iniSelected: getModalSelectedQuestions(state, props),
    fields: getModalQuestions(state, props),
    showModal: getters.selectingQuestions(state, props),
    title: getSelectQuestionsModalTitle(state, props)
  }),
  {
    hideModal: () => actions.closeSelectQuestionsModal(),
    onDone: actions.setVisibleFields,
    onClose: () => actions.closeSelectQuestionsModal()
  }
);

const SelectQuestionsModal = ({
  fields = [],
  iniSelected = [],
  onClose = noop,
  onDone = noop,
  showModal = false,
  title = "Select Fields"
}) => {
  return (
    <MiniModalWrapper
      showModal={showModal}
      title={title}
      hideModal={() => onClose()}
      display="column.flex-start.stretch"
      width={845}
      height={600}
    >
      <SelectFields
        instanceId="selectQuestions"
        fields={fields}
        iniSelected={iniSelected}
        iniClearSearch={true}
        onCancel={onClose}
        onDone={onDone}
      />
    </MiniModalWrapper>
  );
};

export default decorate(SelectQuestionsModal);
