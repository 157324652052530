import React from "react";

import { createContext } from "redux-mvc";
import { connect } from "react-redux";

import * as R from "ramda";

import module from "../index";

import Layout from "./Layout";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import SendEmail from "SendEmailModal/View";
import BulkEditLineItemsModal from "Orders/BulkEditLineItemsModal";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import * as SEND_TO_OPTION_TYPES from "SendEmailModal/utils/send-to-option-types";
import * as EMAIL_TEMPLATES from "utils/Emails/default-emails";

import { hideModal, showModal } from "redux/modules/modal/actions";

import { noop } from "utils/General";
import rootSaga from "../sagas";
module.setRootSaga(rootSaga);

const decorate = createContext({
  module: module,
  handlers: {
    showSendEmailModal(emailTemplate = EMAIL_TEMPLATES.ITEM_SUMMARY) {
      this.props.showModal({
        content: (
          <SendEmail
            moduleId={
              this.props.hasContactId
                ? STANDARD_MODULE_IDS.contacts.id
                : STANDARD_MODULE_IDS.accounts.id
            }
            records={[this.props.recordId]}
            selectedOptions={
              this.props.hasContactId
                ? null
                : [SEND_TO_OPTION_TYPES.ACCOUNT_PRIMARY_CONTACTS]
            }
            onDone={noop}
            {...emailTemplate}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showBulkEditModal(lineItemIds) {
      this.props.showModal({
        content: (
          <BulkEditLineItemsModal
            where={{ lineItemId: lineItemIds }}
            onDone={() => {
              this.props.refetchData();
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    }
  },
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    persist: false,
    observedDomains: ["user", "event", "modal", "snackbar"]
  }
});

export default R.compose(
  connect(
    null,
    {
      showModal,
      hideModal
    }
  ),
  decorate
)(Layout);
