import React from "react";
import PaymentModal from "./PaymentModal";
import { StripeProvider, Elements } from "react-stripe-elements";
import StripeScriptLoader from "./StripeScriptLoader";

export default props => (
  <StripeScriptLoader>
    <StripeProvider apiKey={window.__STRIPE_API_KEY__}>
      <Elements>
        <PaymentModal {...props} />
      </Elements>
    </StripeProvider>
  </StripeScriptLoader>
);
