import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE } from "./constants";

const iniState = {
  modulesFilter: "",
  modules: [],
  selectedModuleIds: [],
  loading: false
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    cancelInstance: R.always(iniState),
    init: R.identity,
    setInitialData: (state, { payload: { modules } }) => ({
      modules
    }),
    toggleModule: (state, { payload: { moduleId, fieldId } }) => ({
      selectedModuleIds: state.selectedModuleIds.some(
        m => m.id === moduleId && m.fieldId === fieldId
      )
        ? state.selectedModuleIds.filter(
            m => !(m.id === moduleId && m.fieldId === fieldId)
          )
        : [...state.selectedModuleIds, { id: moduleId, fieldId }]
    }),
    removeAllSelectedModules: () => ({
      selectedModuleIds: []
    }),
    updateSelectedModuleOrder: (state, { payload: modules }) => ({
      selectedModuleIds: modules.map(q => q.id)
    })
  }
});

export default handlers;
