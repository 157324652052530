import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";

import * as R from "ramda";

import { actions, getters } from "./index";

import { registerError } from "redux/modules/errors/actions";

import { eventId as getEventId } from "redux/modules/event/selectors";

import { getCredentials } from "redux/modules/user/selectors";

import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  return {
    credentials,
    eventId
  };
};

const reorderQuestions = function*({ payload: { reorderedQuestions } }) {
  try {
    yield put(actions.setLoading(true));

    const { credentials } = yield call(getParams);

    const variantIds = yield select(getters.variantIds);
    const questionIds = R.compose(
      R.map(({ question }) => question.id),
      R.filter(({ question }) => question !== null)
    )(reorderedQuestions);
    const questionSetIds = R.compose(
      R.map(({ question_set }) => question_set.id),
      R.filter(({ question_set }) => question_set !== null)
    )(reorderedQuestions);
    const questionAndQuestionSetOrder = R.map(
      ({ question, question_set }) =>
        question !== null ? question.id : question_set.id,
      reorderedQuestions
    );

    yield call(Api.saveSelectedIds, {
      data: {
        mode: "variant",
        variantIds,
        questionIds,
        questionSetIds,
        questionAndQuestionSetOrder
      },
      credentials
    });
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error ocurred adding a new question"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const watchReorder = function*() {
  yield takeEvery(actions.reorderQuestions.type, reorderQuestions);
};

const rootSaga = function*() {
  yield all([fork(watchReorder)]);
};

export default rootSaga;
