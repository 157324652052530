import React from "react";
import * as R from "ramda";
import {
  Div,
  CircleIcon,
  CircleHalfFilledIcon,
  ThreeQuarterSizeDotIcon,
  SmallShadedBox,
  NoneIcon,
  HalfSizeDotIcon,
  ClockIcon,
  CheckIcon,
  CloseIcon,
  LockIcon,
  LockOpenIcon,
  ErrorIcon
} from "components/Base";
import { IntegrationIconsById } from "components/Event/Settings/Credentials/constants";

const iconDict = {
  none: NoneIcon,
  empty: CircleIcon,
  half: CircleHalfFilledIcon,
  full: ThreeQuarterSizeDotIcon,
  error: ErrorIcon,
  clock: ClockIcon,
  check: CheckIcon,
  close: CloseIcon,
  lock: LockIcon,
  unlock: LockOpenIcon
};

const STATUS_STATES = {
  NONE: "NONE",
  NOT_STARTED: "NOT_STARTED",
  ATTENTION_NOT_STARTED: "ATTENTION_NOT_STARTED",
  ERROR: "ERROR",
  WAITING: "WAITING",
  IN_PROGRESS: "IN_PROGRESS",
  SUCCESS: "SUCCESS",
  ENABLED: "ENABLED",
  DISABLED: "DISABLED",
  FAILURE: "FAILURE",
  DONE: "DONE",
  WAITING_ACTION: "WAITING_ACTION",
  LOCKED: "LOCKED",
  UNLOCKED: "UNLOCKED",
  OPEN: "OPEN",
  CLOSED: "CLOSED"
};

const tagProps = {
  NONE: {
    icon: "none",
    color: "neutral7"
  },
  NOT_STARTED: {
    icon: "empty",
    color: "neutral8"
  },
  WAITING: {
    icon: "clock",
    color: "yellow9"
  },
  WAITING_ACTION: {
    icon: "clock",
    color: "purple8"
  },
  ERROR: {
    icon: "error",
    color: "yellow9"
  },
  ATTENTION_NOT_STARTED: {
    icon: "empty",
    color: "yellow9"
  },
  IN_PROGRESS: {
    icon: "half",
    color: "orange8"
  },
  DONE: {
    icon: "full",
    color: "green8"
  },
  SUCCESS: {
    icon: "check",
    color: "green8"
  },
  ENABLED: {
    color: "green8"
  },
  DISABLED: {
    color: "red8"
  },
  FAILURE: {
    icon: "close",
    color: "red8"
  },
  UNLOCKED: {
    icon: "unlock",
    color: "gray6"
  },
  LOCKED: {
    icon: "lock",
    color: "gray6"
  },
  OPEN: {
    icon: "empty",
    color: "green8"
  },
  CLOSED: {
    icon: "empty",
    color: "red8"
  }
};

const makeStatus = displayResolver => statusMap => {
  const statusTagDict = R.map(displayResolver)({
    ...statusMap,
    none: { state: "NONE", verbiage: "" }
  });

  return ({ status, ...props }) => {
    const Comp = statusTagDict[R.isNil(statusMap[status]) ? "none" : status];
    return <Comp {...props} />;
  };
};

const makeStatusTag = makeStatus(({ state, verbiage }) => {
  const { icon, color } = tagProps[state];
  const LeftIcon = icon ? iconDict[icon] || NoneIcon : undefined;
  return props => (
    <Div {...props}>
      <SmallShadedBox
        pill
        color={color}
        LeftIcon={props.providerId ? undefined : LeftIcon}
      >
        {props.providerId ? IntegrationIconsById[props.providerId] : null}
        {verbiage}
      </SmallShadedBox>
    </Div>
  );
});

const makeStatusDot = makeStatus(({ state }) => {
  const { color } = tagProps[state];
  return props => (
    <Div>
      <HalfSizeDotIcon size={16} color={color} {...props} />
    </Div>
  );
});

const makeStatusIcon = makeStatus(({ state }) => {
  const { icon, color } = tagProps[state];
  const OnlyIcon = iconDict[icon] || NoneIcon;
  return props => (
    <Div {...props}>
      <SmallShadedBox pill color={color} OnlyIcon={OnlyIcon} />
    </Div>
  );
});

export { STATUS_STATES, makeStatusTag, makeStatusDot, makeStatusIcon };
