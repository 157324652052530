/* eslint-disable no-underscore-dangle */
import React, { Component } from "react";
import Helpers from "utils/Global/Helpers";
import { get } from "lodash";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_MODULE_FIELD_IDS from "utils/standard-module-field-ids";

import PortalUsersApi from "redux/modules/portal/users/api";
import SubmissionApi from "redux/modules/formsV2/submission/api";
import UserApi from "redux/modules/user/api";
import getFormVersion from "components/Event/FormsV2/Utils/get-form-version";
import isFormLocked from "components/Event/FormsV2/Utils/is-form-locked";

import View from "./View";
import { LoadingIcon, Div } from "components/Base";

const LoginException = function(value) {
  this.value = value;
  this.message = "Unknown Submission Login Error";
  this.toString = () => `${this.message}: ${JSON.stringify(this.value)}`;
};

class Controller extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      searching: false,
      searchingUsers: false,
      processing: false,
      error: null,
      //
      formLinkId: props.params.formLinkId,
      //
      email: props.location.query.email ? props.location.query.email : "",
      password: "",
      firstName: "",
      lastName: "",
      accountName: "",
      //
      showAddAccount: false,
      redirectToPortal: false,
      //
      userExists: null,
      isVirtualUser: false,
      portalUserExists: null,
      //
      link: null
    };
  }

  async componentDidMount() {
    if (this.props.params.formLinkId) {
      const payload = await this.props.getFormLink(
        this.props.params.formLinkId
      );

      // if we have an existing submission ID, redirect to it
      if (payload.link.submission_id) {
        window.location = `/open-submissions/${this.props.form.event.slug}/${this.props.form.slug}/${payload.link.submission_id}`;
      }
      return false;
    } else {
      await this.props.getForm(this.props.params.formId);
    }
    const portalUser =
      this.props.userCredentials && this.props.userCredentials.userId
        ? await this.getPortalUser(this.props.userCredentials.userId)
        : null;
    const isLocked = isFormLocked(this.props.form);

    if (
      this.props.form.scope === "account" &&
      portalUser &&
      portalUser.accounts.length === 0
    ) {
      // Conditions:
      // - Form is scoped to account
      // - Portal user exists
      // - Portal user has no accounts
      // Action:
      // - Show "Add account" scene
      return this.setState({
        loading: false,
        showAddAccount: true
      });
    } else if (
      !isLocked &&
      !this.props.form.require_login &&
      portalUser &&
      portalUser.accounts.length === 0
    ) {
      // Conditions:
      // - Form is not locked
      // - Form does not require login
      // - Portal user exists
      // - Portal user has no accounts
      // Action:
      // - Create open submission and redirect to it
      return this.createOpenSubmissionAndRedirect(portalUser.user_email);
    } else if (
      !isLocked &&
      this.props.form.require_login &&
      portalUser &&
      portalUser.accounts.length === 0
    ) {
      // Conditions:
      // - Form is not locked
      // - Form requires login
      // - Portal user exists
      // - Portal user has no accounts
      // Action:
      // - Create submission and redirect to it
      return this.createSubmissionAndRedirect(portalUser.user_id);
    }
    this.setState({ loading: false });
    return true;
  }

  toggleAddAccount = () => {
    this.setState({
      error: null,
      loading: false,
      showAddAccount: !this.state.showAddAccount,
      redirectToPortal: false
    });
  };

  toggleRedirectToPortal = () => {
    if (document.getElementsByTagName("input")[0]) {
      document.getElementsByTagName("input")[0].focus();
    }
    this.setState({
      error: null,
      redirectToPortal: !this.state.redirectToPortal,
      showAddAccount: false
    });
  };

  createUser = () => {
    const { email, password, firstName, lastName } = this.state;
    return UserApi.post(this.props.userCredentials, {
      email,
      password,
      firstName,
      lastName,
      eventId: this.props.form.event_id,
      contactTypeId: this.props.form.submitter_record_type_id,
      createContactAsDraft: true
    }).then(result => ({ userId: result.payload.user.id }));
  };

  convertVirtualUser = () => {
    const { email, password } = this.state;
    return UserApi.convert(this.props.userCredentials, {
      email,
      password
    }).then(result => ({
      userId: result.payload.user.id
    }));
  };

  loginUser = ({ job }) => {
    window.webAuth.login(
      {
        username: this.state.email,
        password: this.state.password,
        realm: window.__AUTH0_REALM__,
        redirectUri: `${window.__LENND_APP_URL__}/submission-callback?formId=${
          this.props.form.id
        }&job=${job}&accountName=${encodeURIComponent(this.state.accountName)}${
          this.state.redirectToPortal ? "&redirectToPortal=true" : ""
        }`
      },
      err => {
        if (err) {
          console.error(
            `[Submission Landing] Error logging in`,
            err.error_description
          );
          this.setState({
            processing: false,
            error: err.error_description
          });
        } else {
          this.setState({
            processing: true
          });
        }
      }
    );
  };

  createPortalUser = userId =>
    PortalUsersApi.post(this.props.userCredentials, {
      eventId: this.props.form.event_id,
      userId
    });

  getPortalUser = userId => {
    return this.props.getPortalUser({
      userId,
      eventId: this.props.form.event.uuid
    });
  };

  assignForm = (userId, accountId) => {
    if (accountId) {
      return this.props.assignFormToAccount({
        eventId: this.props.form.event.id,
        recordIds: [accountId],
        formIds: [this.props.form.id]
      });
    }
    return this.props.assignFormToContact({
      eventId: this.props.form.event.id,
      recordIds: [userId],
      formIds: [this.props.form.id]
    });
  };

  createSubmission = async (userId, accountId) => {
    const { form } = this.props;
    const version = getFormVersion(form);
    let submissionResult;

    if (version === 3) {
      const submissionModuleRecord = await this.props.addRecord({
        moduleId: form.base_module_id,
        record: {
          isDraft: true
        },
        options: {
          eventId: form.event_id,
          userId
        }
      });

      submissionResult = await SubmissionApi.post(this.props.userCredentials, {
        eventId: form.event_id,
        formId: form.id,
        accountId,
        userId,
        submissionRecordId: submissionModuleRecord.id
      });
    } else {
      submissionResult = await SubmissionApi.post(this.props.userCredentials, {
        eventId: form.event_id,
        formId: form.id,
        userId,
        accountId
      });
    }

    return submissionResult;
  };

  showError = error => {
    const message = get(error, "error");
    console.log("Submission Login Error", error);

    if (window.__ROLLBAR_CONFIG__ && window.Rollbar) {
      window.Rollbar.log("Submission Login Error", new LoginException(error));
    }

    this.setState({
      processing: false,
      error: message || "Oops. We encountered an error. Please try again soon."
    });
  };

  addAccount = async userId => {
    if (this.props.form.scope === "account") {
      const account = await this.props.addRecord({
        moduleId: STANDARD_MODULE_IDS.accounts.id,
        typeId: this.props.form.scope_record_type_id,

        // @NOTE: We can toggle if an account should be in draft mode here
        // isDraft: true,

        record: {
          [STANDARD_MODULE_FIELD_IDS.ACCOUNTS.NAME]: {
            type: "text",
            value: this.state.accountName
          }
        },

        options: {
          eventId: this.props.form.event_id,
          userId
        }
      });

      await this.addAccountUserRelationships(userId, account.id);

      return { accountId: account.id };
    }

    return { accountId: null };
  };

  addAccountUserRelationships = (userId, accountId) => {
    return Promise.all([
      this.addAccountUser(userId, accountId),
      this.updatePortalUser(userId, null, accountId)
    ]);
  };

  handleCreateAccountForExistingUser = async () => {
    const [account] = await Promise.all([
      this.addAccount(this.props.user.id),
      this.createPortalUser(this.props.user.id)
    ]);

    await this.assignForm(this.props.user.id, account.accountId);

    return this.handleRegisterCompletion(this.props.user.id, account.accountId);
  };

  handleSubmit = async e => {
    e.preventDefault();
    const {
      processing,
      userExists,
      isVirtualUser,
      portalUserExists,
      showAddAccount
    } = this.state;

    if (processing) {
      return false;
    }

    this.setState({ processing: true });

    if (showAddAccount) {
      return this.handleCreateAccountForExistingUser();
    }
    if (userExists === null) {
      this.search();
      return false;
    }
    if (userExists === false) {
      const errors = this.validate();
      if (errors.length) {
        return this.setState({
          processing: false,
          error: `${errors.join(", ")} ${
            errors.length > 1 ? "are" : "is"
          } required.`
        });
      }
      // handle: user does not exist
      return this.handleRegisterNewUser();
    } else if (
      userExists === true &&
      isVirtualUser === false &&
      portalUserExists === false
    ) {
      // handle: user exists, is not a virtual user, is not a portal user
      return this.handleRegisterExistingUser();
    } else if (
      userExists === true &&
      isVirtualUser === true &&
      portalUserExists === true
    ) {
      // handle: user exists, is a virtual user, is a portal user
      return this.handleConvertVirtualUser();
    } else if (
      userExists === true &&
      isVirtualUser === true &&
      portalUserExists === false
    ) {
      // handle: user exists, is a virtual user, is a not portal user
      return this.handleRegisterVirtualUser();
    } else if (userExists === true) {
      return this.handleLogin();
    }
    return true;
  };

  handleRegisterNewUser = async () => {
    try {
      await this.createUser();

      this.loginUser({
        job: "handleRegisterNewUser"
      });
    } catch (err) {
      this.showError(err);
    }
  };

  handleRegisterVirtualUser = async () => {
    try {
      await this.convertVirtualUser();

      await this.loginUser();

      /*
      await this.createPortalUser(userId);

      const { accountId } = await this.addAccount(userId);

      await this.assignForm(userId, accountId);

      return this.handleRegisterCompletion(userId, accountId);
      */
    } catch (err) {
      this.showError(err);
    }
    return true;
  };

  handleConvertVirtualUser = async () => {
    try {
      await this.convertVirtualUser();

      await this.loginUser();

      /*
      const { accountId } = await this.addAccount(userId);

      await this.assignForm(userId, accountId);

      return this.handleRegisterCompletion(userId, accountId);
      */
    } catch (err) {
      this.showError(err);
    }
    return true;
  };

  handleRegisterExistingUser = async () => {
    try {
      await this.loginUser({
        job: "handleRegisterExistingUser"
      });

      /*
      await this.createPortalUser(userId);

      const { accountId } = await this.addAccount(userId);

      await this.assignForm(userId, accountId);

      return this.handleRegisterCompletion(userId, accountId);
      */
    } catch (err) {
      this.showError(err);
    }
    return true;
  };

  handleRegisterCompletion = async (userId, accountId) => {
    const {
      form,
      form: { event }
    } = this.props;

    if (this.state.redirectToPortal) {
      window.location = `/portals/${event.slug}/${event.uuid}`;
    } else {
      const { submission } = await this.createSubmission(userId, accountId);
      window.location = `/submissions/${event.slug}/${form.slug}/${submission.id}`;
    }

    return null;
  };

  handleLogin = async () => {
    try {
      this.loginUser({ job: "handleLogin" });
    } catch (err) {
      this.showError(err);
    }
  };

  search = e => {
    // if coming from form, prevent submission
    if (e) {
      e.preventDefault();
    }

    // if form does not require login, we'll create a virtual user if we don't find it
    // so we can continue to the form.  otherwise, we'll not create it and only search
    // and force the user to enter a password or create a password.
    if (!this.props.form.require_login) {
      return this.searchAndCreate();
    }
    return this.searchAndDoNotCreate();
  };

  searchAndDoNotCreate = () => {
    const { email } = this.state;
    if (this.state.email.length && Helpers.isValidEmail(this.state.email)) {
      this.setState({ searchingUsers: true }, () => {
        PortalUsersApi.searchUsers(this.props.userCredentials, {
          eventId: this.props.form.event_id,
          formId: this.props.form.id,
          email
        })
          .then(
            ({ payload: { userExists, isVirtualUser, portalUserExists } }) => {
              this.setState({
                error: null,
                searchingUsers: false,
                userExists,
                isVirtualUser,
                portalUserExists
              });
            }
          )
          .catch(error => this.setState({ searchingUsers: false, error }));
      });
    } else {
      this.setState({ error: "Please enter a valid email address." });
    }
  };

  searchAndCreate = async () => {
    const { email } = this.state;
    if (!email.length || !Helpers.isValidEmail(email)) {
      return this.setState({ error: "Please enter a valid email address." });
    }
    return this.createOpenSubmissionAndRedirect(email);
  };

  async createOpenSubmissionAndRedirect(email) {
    const {
      createOpenFormSubmission,
      form,
      form: { event = {} }
    } = this.props;
    try {
      const { payload } = await createOpenFormSubmission({
        email,
        formId: this.props.form.id
      });

      // redirect to the open submission
      window.location = `/open-submissions/${event.slug}/${form.slug}/${payload.submission_id}`;
    } catch (error) {
      return this.showError(error);
    }
    return true;
  }

  async createSubmissionAndRedirect(userId, accountId) {
    const {
      form,
      form: { event = {} }
    } = this.props;
    try {
      const { submission } = await this.createSubmission(userId, accountId);

      // redirect to the submission
      window.location = `/submissions/${event.slug}/${form.slug}/${submission.id}`;
    } catch (error) {
      return this.showError(error);
    }
    return true;
  }

  onFirstNameChange = e => {
    this.setState({
      error: null,
      firstName: e.target.value
    });
  };

  onLastNameChange = e => {
    this.setState({
      error: null,
      lastName: e.target.value
    });
  };

  onAccountNameChange = e => {
    this.setState({
      error: null,
      accountName: e.target.value
    });
  };

  onEmailChange = e => {
    if (this.state.email === e.target.value) return;
    this.setState({
      redirectToPortal: false,
      userExists: null,
      isVirtualUser: null,
      portalUserExists: null,
      error: null,
      email: e.target.value
    });
  };

  onPasswordChange = e => {
    this.setState({
      error: null,
      password: e.target.value
    });
  };

  validate = () => {
    const fieldsToValidate = [
      {
        field: "Valid password",
        property: "password"
      },
      {
        field: "First Name",
        property: "firstName"
      },
      {
        field: "Last Name",
        property: "lastName"
      }
    ];

    return fieldsToValidate.reduce((errors, { field, property }) => {
      if (property === "password") {
        if (
          !this.state[property] ||
          !Helpers.isValidPassword(this.state[property])
        ) {
          errors.push(field);
        }
      }
      if (!this.state[property]) {
        errors.push(field);
      }
      return errors;
    }, []);
  };

  addAccountUser = (userId, accountId) => {
    return this.props.addAccountUser({
      eventId: this.props.form.event_id,
      userId,
      accountId
    });
  };

  updatePortalUser = (userId, contactId, accountId) => {
    if (!contactId && !accountId) return null;

    return this.props.updatePortalUser({
      eventId: this.props.form.event_id,
      userId,
      activeView: accountId || contactId
    });
  };

  handleSwitcherSelect = (contactId, accountId) => {
    this.updatePortalUser(this.props.portalUser.user_id, contactId, accountId);
    return this.handleRegisterCompletion(
      this.props.portalUser.user_id,
      accountId
    );
  };

  render() {
    const {
      form,
      form: { event = {} },
      isFetching,
      errorMessages,
      user,
      portalUser: { accounts }
    } = this.props;
    const {
      loading,
      searching,
      searchingUsers,
      processing,
      error,
      //
      email,
      password,
      firstName,
      lastName,
      accountName,
      //
      showAddAccount,
      redirectToPortal,
      //
      userExists,
      isVirtualUser,
      portalUserExists
    } = this.state;

    const isLocked = isFormLocked(form);
    const requiresAccount = form.scope === "account";

    if (loading) {
      return (
        <Div
          display="column.center.center"
          height={370}
          style={{ width: "100%" }}
        >
          <LoadingIcon size={60} color="neutral4" />
          <Div color="neutral4" mt={6}>
            Loading...
          </Div>
        </Div>
      );
    }
    return (
      <View
        {...{
          loading,
          searching,
          searchingUsers,
          processing,
          error,
          //
          email,
          password,
          firstName,
          lastName,
          accountName,
          //
          showAddAccount,
          redirectToPortal,
          //
          userExists,
          isVirtualUser,
          portalUserExists,
          requiresAccount,
          isLocked,
          isFetching,
          isPortalUser: true,
          isCurrentUser: Boolean(this.props.user.id),
          //
          form,
          userName: user.fname ? `${user.fname} ${user.lname}` : user.email,
          logoutPath: `/logout?returnTo=/forms/${event.slug}/${form.slug}/${form.id}`,
          backgroundImageUrl:
            form.background_image_url || event.background_image_url,
          event,
          errorMessages,
          accounts: (accounts || []).map(account => ({
            id: account.id,
            name: account.name,
            typeName: get(account, "type.name", ""),
            typeColor: get(account, "type.text_color", ""),
            backgroundColor: get(account, "type.background_color", ""),
            icon: get(account, "type.icon"),
            onClick: () => this.handleSwitcherSelect(null, account.id)
          })),
          //
          onUserClick: () =>
            this.handleSwitcherSelect(this.props.portalUser.user_contact_id),
          handleSubmit: this.handleSubmit,
          onEmailChange: this.onEmailChange,
          onFirstNameChange: this.onFirstNameChange,
          onLastNameChange: this.onLastNameChange,
          onPasswordChange: this.onPasswordChange,
          onAccountNameChange: this.onAccountNameChange,
          search: this.search,
          toggleRedirectToPortal: this.toggleRedirectToPortal,
          toggleAddAccount: this.toggleAddAccount
        }}
      />
    );
  }
}

export default Controller;
