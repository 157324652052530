import * as R from "ramda";

import React from "react";
import MediaQuery from "react-responsive";

import { Div } from "components/Base";

import Toggle, { Tab } from "components/Event/FormsV2/Sections/FormGrid/Toggle";
import ErrorList from "components/Event/FormsV2/Sections/FormPeople/ErrorList";

import CardView from "./CardView";
import TableView from "./TableView";

const Wrapper = ({ children }) => (
  <Div
    style={{
      marginBottom: 20
    }}
  >
    {children}
  </Div>
);

const InnerTab = ({ icon, name }) => (
  <span>
    <i
      className="material-icons"
      style={{
        fontSize: 13,
        color: "#C1C1C1",
        verticalAlign: "sub",
        marginRight: 5
      }}
    >
      {icon}
    </i>
    {name}
  </span>
);

const ViewSwitcher = ({ views }) => (
  <MediaQuery query="(min-width: 768px)">
    <div
      style={{
        marginBottom: 20
      }}
    >
      <Toggle>
        {views.map(view => (
          <Tab key={view.id} active={view.active} onClick={view.onClick}>
            <InnerTab icon={view.icon} name={view.name} />
          </Tab>
        ))}
      </Toggle>
    </div>
  </MediaQuery>
);

const Layout = ({ activeView, views, ...props }) => {
  const View = R.propOr(CardView, activeView, {
    card: CardView,
    table: TableView
  });
  return (
    <Wrapper>
      {!(
        props.isEditing ||
        props.isPreviewing ||
        !props.isLenndAdmin ||
        !props.settingsAllowNewRows
      ) && <ViewSwitcher views={views} />}
      <ErrorList
        type="card"
        isValid={props.isValid}
        errorMessages={props.errors}
        view={props.settingsView}
        allowNewRows={props.settingsAllowNewRows}
        rows={props.rows}
      >
        <View {...props} />
      </ErrorList>
    </Wrapper>
  );
};

export default Layout;
