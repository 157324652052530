import { select, call, put, takeEvery, all, fork } from "redux-saga/effects";

import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { registerError } from "redux/modules/errors/actions";
import { openInNewWindow } from "utils/General";
import { getEventShowcaseUrl } from "Sponsors/SelfSetup/selectors";

import { actions } from "Sponsors/SelfSetup";
import Api from "Sponsors/SelfSetup/api";

import createPackage from "./createPackage";
import createItems from "./createItems";
import eventShowcase from "./eventShowcase";
import application from "./application";
import payment from "./payment";
import addItems from "./addItems";

const fetchItemTypes = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  try {
    const { payload } = yield call(Api.getItemTypes, {
      credentials,
      eventId
    });
    yield put(actions.itemTypesResponse(payload));
  } catch (e) {
    yield put(
      registerError([
        {
          system: e,
          user: "An error occurred fetching the item types"
        }
      ])
    );
  }
};

const viewSalesPortal = function*() {
  const url = yield select(getEventShowcaseUrl);
  yield call(openInNewWindow, url);
};

const watchFetchItemTypes = function*() {
  yield takeEvery(actions.itemTypesRequest.type, fetchItemTypes);
};

const watchViewSalesPortal = function*() {
  yield takeEvery(actions.viewSalesPortal.type, viewSalesPortal);
};

const rootSaga = function*() {
  yield all([
    fork(watchFetchItemTypes),
    fork(watchViewSalesPortal),
    fork(createPackage),
    fork(createItems),
    fork(eventShowcase),
    fork(application),
    fork(payment),
    fork(addItems)
  ]);
};

export default rootSaga;
