import Helpers from "utils/Global/Helpers";

/* eslint no-underscore-dangle: "off" */

export default {
  getForm: ({ credentials, formId, accountId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/portal/forms/${formId}${
          accountId ? `/accounts/${accountId}` : ""
        }`,
        credentials,
        success,
        error
      });
    })
};
