import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "Integrations/Fuzion";
import FuzionMappingModal from "Integrations/FuzionMappingModal/View";

const decorate = connect(
  state => ({
    showMappingModal: getters.showMappingModal(state)
  }),
  {
    hideModal: () => actions.setShowMappingModal(false)
  }
);

const MappingModal = ({ hideModal, showMappingModal }) =>
  showMappingModal ? <FuzionMappingModal hideModal={hideModal} /> : null;

export default decorate(MappingModal);
