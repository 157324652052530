import React from "react";
import { Div } from "components/Base";

const Panel = ({ children, width = 1, styleProps = {}, ...otherProps }) => (
  <Div
    width={width}
    bg="neutral0"
    style={{
      borderRadius: "10px",
      boxShadow:
        "0 9px 17px 0 rgba(0, 0, 0, 0.15), 0 1px 3px 0 rgba(0, 0, 0, 0.17)",
      padding: 25,
      ...styleProps
    }}
    {...otherProps}
  >
    {children}
  </Div>
);

export default Panel;
