import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const PoweredbyLennd = ({ source = "powered-by-lennd", ...props }) => (
  <a
    styleName="branding"
    href={`https://www.lennd.com/?source=${source}`}
    target="_blank"
    {...props}
  >
    <img
      src="https://d2dks4tzxs6xee.cloudfront.net/img/powered-by-lennd.svg"
      styleName="brandingImg"
      alt="Lennd"
    />
  </a>
);

PoweredbyLennd.propTypes = {
  source: PropTypes.string
};

export default CSSModules(PoweredbyLennd, css);
