import { createSelector } from "reselect";
import * as R from "ramda";
import { TAB_IDS } from "./constants";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";

const isActiveRoute = (routes, route) => {
  if (Array.isArray(route)) {
    return R.compose(
      Boolean,
      R.length,
      R.intersection(route),
      R.map(R.prop("name"))
    )(routes);
  }

  return routes.some(r => r.name === route);
};

export const getCurrentRoute = createSelector(
  (_, props) => props.routes,
  routes => {
    if (R.any(R.propEq("name", "EventLightCustomModuleManage"))(routes)) {
      return TAB_IDS.MANAGE;
    } else if (
      R.any(R.propEq("name", "EventLightCustomModuleSettingsApprovers"))(routes)
    ) {
      return TAB_IDS.APPROVERS;
    }
    /*
      return TAB_IDS.TYPES;
    } else if (
      R.any(R.propEq("name", "EventLightCustomModuleSettingsFields"))(routes)
    ) {
      return TAB_IDS.FIELDS;
    } else if (
      R.any(R.propEq("name", "EventLightCustomModuleSettingsReportTemplates"))(
        routes
      )
    ) {
      return TAB_IDS.REPORT_TEMPLATES;
    } else if (
      R.any(
        R.propEq("name", "EventLightCustomModuleSettingsDocumentTemplates")
      )(routes)
    ) {
      return TAB_IDS.DOCUMENT_TEMPLATES;
    } else if (
      R.any(R.propEq("name", "EventLightCustomModuleSettingsEmailTemplates"))(
        routes
      )
    ) {
      return TAB_IDS.EMAIL_TEMPLATES;
    }
    */
    return null;
  }
);

export const getLinks = createSelector(
  (_, props) => props.params,
  getCurrentRoute,
  (params, currentRoute) => {
    return [
      {
        key: TAB_IDS.MANAGE,
        label: "Manage",
        active: currentRoute === TAB_IDS.MANAGE,
        to: `/event-light/${params.eventId}/module/${params.moduleId}/manage`,
        visible: true
      }
      /*
      {
        key: TAB_IDS.SETTINGS,
        label: "Settings",
        active: currentRoute === TAB_IDS.SETTINGS,
        to: `/event-light/${params.eventId}/settings/email-templates`,
        visible: true
      }
      */
    ].filter(l => l.visible);
  }
);

export const getTabs = createSelector(
  (_, props) => props.routes,
  (_, props) => props.params,
  getEventDetails,
  getCurrentRoute,
  (routes, params, eventDetails, currentRoute) => {
    if ([TAB_IDS.APPROVERS].includes(currentRoute)) {
      return [
        {
          key: "approvers",
          label: "Approvers",
          active: isActiveRoute(
            routes,
            "EventLightCustomModuleSettingsApprovers"
          ),
          to: `/event-light/${eventDetails.id}/custom/${params.moduleId}/settings/approvers/`
        }
      ];
    }

    return [];
  }
);
