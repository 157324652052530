import React from "react";

import { connect } from "react-redux";

import {
  Div,
  PopMenu,
  PopMenuCard,
  MediumOutlineButton
} from "components/Base";

import { actions } from "../index";

const decorate = connect(
  null,
  {
    downloadDocument: actions.downloadDocument,
    showModal: actions.showEditTemplateModal
  }
);

const TemplateOptionsPopover = ({
  downloadDocument,
  showDeleteConfirmation,
  templateUrl,
  showModal,
  template
}) => {
  return (
    <PopMenu
      Label={({ onClick }) => (
        <MediumOutlineButton onClick={onClick} width={1}>
          Options...
        </MediumOutlineButton>
      )}
    >
      {({ closeMenu }) => (
        <PopMenuCard position="bottom" align="right">
          <Div width={175}>
            <Div
              p={2}
              bg={{ default: "white", hover: "neutral0" }}
              onClick={() => {
                showModal({ template });
                closeMenu();
              }}
              color="black"
              fw={3}
            >
              Edit Template
            </Div>
            <Div
              p={2}
              bg={{ default: "white", hover: "neutral0" }}
              onClick={() => {
                downloadDocument(templateUrl);
                closeMenu();
              }}
              color="black"
              fw={3}
            >
              Download .docx
            </Div>
            <Div
              p={2}
              bg={{ default: "white", hover: "neutral0" }}
              onClick={() => {
                showDeleteConfirmation();
              }}
              color="black"
              fw={3}
            >
              Delete
            </Div>
          </Div>
        </PopMenuCard>
      )}
    </PopMenu>
  );
};

export default decorate(TemplateOptionsPopover);
