import PropTypes from "prop-types";
import React, { Component } from "react";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import CreateOrderModal from "Orders/CreateOrderModal";
import ViewOrderModal from "Orders/OrderModal/View";
import SendEmailModal from "SendEmailModal/View";
import moment from "moment";
import View from "components/Event/Accounts/Account/CredentialOrders/View";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as R from "ramda";
import { capitalize } from "utils/General";

class Controller extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    this.getInformation().then(() => {
      this.setState({ loading: false });
    });
  }

  getInformation = () => {
    return Promise.all([this.getSummary(), this.getOrders()]);
  };

  getSummary = () => {
    const { eventId, recordId } = this.props.params;
    return this.props.getOrderSummaryForContact({
      eventId,
      contactId: recordId
    });
  };

  getOrders = () => {
    const { eventId, recordId } = this.props.params;
    return this.props.getOrdersForContact({
      eventId,
      contactId: recordId
    });
  };

  showCredentialOrderModal = () => {
    this.props.showModal({
      content: (
        <CreateOrderModal
          customerContactId={this.props.params.recordId}
          customerContactName={this.props.contactName}
          customerContactEmail={this.props.contactEmail}
          customerAccountId={
            this.props.account ? this.props.account.account_id : null
          }
          customerAccountName={
            this.props.account ? this.props.account.name : null
          }
          onDone={this.getInformation}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showViewOrderModal = orderId => {
    this.props.showModal({
      content: (
        <ViewOrderModal
          orderId={orderId}
          hideModal={() => {
            this.getInformation();
            this.props.hideModal();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showSendEmailModal = selectedOption => {
    this.props.showModal({
      content: (
        <SendEmailModal
          moduleId={STANDARD_MODULE_IDS.contacts.id}
          records={[this.props.params.recordId]}
          selectedOptions={selectedOption ? [selectedOption] : []}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const { isFetching, credentials, orders, summary } = this.props;

    const { recordId } = this.props.params;

    const formattedOrders = orders.map(order => {
      const tags = [];

      if (order.is_allocation) {
        tags.push("Allocation");
      }

      if (
        order.order_type === "group" &&
        (order.require_assignment || order.is_allocation)
      ) {
        tags.push("Assignment Required");
      }

      return {
        title:
          order.order_type === "individual"
            ? [
                R.path(["customer", "first_name"], order),
                R.path(["customer", "last_name"], order)
              ]
                .filter(v => v)
                .join(" ")
            : R.path(["customer", "account_name"], order),
        groupName: R.path(["customer", "account_name"], order),
        orderNumber: order.number,
        tags, // ['Paid', 'Partial Issue'],
        orderType: capitalize(order.order_type),
        source: [
          R.path(["created_by_user", "first_name"], order),
          R.path(["created_by_user", "last_name"], order)
        ].join(" "), // 'Guest Credentials',
        requestedBy: [
          R.path(["customer", "first_name"], order),
          R.path(["customer", "last_name"], order)
        ].join(" "), // 'Bryan Bangeter',
        pickup: [
          R.path(["pickup_information", "first_name"], order),
          R.path(["pickup_information", "last_name"], order)
        ].join(" "),
        orderDate: moment(order.created_at).format("M/DD/YYYY [at] h:mma"),
        onClick: () => this.showViewOrderModal(order.id)
      };
    });

    return (
      <View
        {...{
          loading: this.state.loading,
          isFetching,
          recordId: recordId,
          hasContactId: true,
          credentials,
          showGroupNameColumn: true,
          orders: formattedOrders,
          summary,
          reportTableData: R.map(type => ({
            ...type,
            items: R.reduce((list, item) => {
              return [...list, ...item.items];
            }, [])(type.items)
          }))(summary.variants),
          showCredentialOrderModal: this.showCredentialOrderModal,
          shareAssignmentManagerLink: () => this.showSendEmailModal(),
          refetchData: this.getInformation
        }}
      />
    );
  }
}

Controller.propTypes = {
  addAccountForm: PropTypes.func.isRequired,
  deleteAccountForm: PropTypes.func.isRequired,
  deleteSubmission: PropTypes.func.isRequired,
  forms: PropTypes.arrayOf(PropTypes.object).isRequired,
  getAccount: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  params: PropTypes.shape({
    accountId: PropTypes.string,
    eventId: PropTypes.string
  }).isRequired,
  setSubmissionLocked: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  eventDetails: PropTypes.object.isRequired,
  getCredentials: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  credentials: PropTypes.arrayOf({
    id: PropTypes.string,
    value: PropTypes.name
  }).isRequired
};

export default Controller;
