const getRouteContext = ({ routes = [], params = {}, context = {} }) => {
  const routeNames = routes.map(r => r.name);
  const routeContext = {
    ...(params || {}),
    ...(context || {})
  };
  return routeContext;
};

export default getRouteContext;
