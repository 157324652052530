import React from "react";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import css from "../styles.scss";

import Loading from "components/Global/Loading";
import NotFound from "components/Event/FormsV2/NotFound";
import FormBackground from "components/Event/FormsV2/Sections/FormBackground";
import FormInfo from "components/Event/FormsV2/Sections/FormInfo";
import FormFooter from "components/Event/FormsV2/Form/FormFooter";
import Section from "components/Event/FormsV2/Sections/Section";

import Sidebar from "./Sidebar";
import SimpleSideBar from "components/Event/FormsV2/Form/Views/V3_Edit/SimpleSideBar";
import ReactSidebar from "react-sidebar";
import { Div } from "components/Base";

import { MODES } from "ui-kit/Datagrid/constants";

const DetailsScene = ({
  isFetching,
  form,
  eventDetails,
  dispatch,
  preferences,
  sortedFormFields,
  moveField,
  getFormRows,
  showModal,
  addSectionRef,
  showAddQuestionModal,
  showEditFieldModal,
  showEditItemBlockModal,
  getColumnModalFields,
  registerRef
}) => {
  return (
    <Div px={5}>
      {!isFetching ? (
        <Section
          ref={addSectionRef}
          sectionId={0}
          form={form}
          preferences={preferences}
          fields={sortedFormFields}
          eventDetails={eventDetails}
          moveField={moveField}
          getFormRows={getFormRows}
          dispatch={dispatch}
          showModal={showModal}
          showAddQuestionModal={showAddQuestionModal}
          showEditQuestionModal={showEditFieldModal}
          showEditItemBlockModal={showEditItemBlockModal}
          getColumnModalFields={getColumnModalFields}
          isFillingFormOut={false}
          isApprovingForm={false}
          isResponseLocked={false}
          isViewingApproval={false}
          formVersion={3}
          isEditing
          mode={MODES.EDIT}
          registerRef={registerRef}
          scrollTopId="builder-content"
        />
      ) : null}

      <br />
      <br />

      <FormFooter formId={form.id} />
    </Div>
  );
};

const OrderReviewScene = ({}) => <Div>Order review</Div>;

const OrderConfirmationScene = ({}) => <Div>Order confirmation</Div>;

const View = CSSModules(
  ({
    isFetching,
    form,
    eventDetails,
    dispatch,
    preferences,
    sortedFormFields,
    moveField,
    getFormRows,
    showModal,
    addFormRef,
    addSectionRef,
    showFilePicker,
    showAddQuestionModal,
    showEditFieldModal,
    showEditItemBlockModal,
    getColumnModalFields,
    backgroundUrl,
    activeScene,
    registerRef,
    canShowSimpleSideBar
  }) => {
    if (!isFetching && !form.id) {
      return <NotFound />;
    }

    if (isFetching) {
      return <Loading dark content="Loading form..." styleName="loading" />;
    }

    const sideBar =
      !isFetching && form.id ? (
        canShowSimpleSideBar ? (
          <SimpleSideBar />
        ) : (
          <Sidebar />
        )
      ) : null;

    return (
      <ReactSidebar
        docked
        sidebar={sideBar}
        styles={{
          root: { top: 56 },
          content: { transform: "translate(0px, 0px)" },
          sidebar: { overflowY: "hidden" }
        }}
        transitions={false}
        contentId="builder-content"
      >
        <form
          ref={addFormRef}
          noValidate
          styleName="form"
          className="page-form"
        >
          <div styleName="editContainer">
            <div styleName="editContent">
              <div styleName="container">
                {/* form top */}
                <FormBackground
                  backgroundUrl={backgroundUrl}
                  showFilePicker={showFilePicker}
                  isEditing
                />

                {/* form content */}
                <div styleName="contentContainer" className="page-form-content">
                  {/* form header */}
                  <div styleName="headerContainer">
                    <FormInfo
                      dispatch={dispatch}
                      eventId={form.event_id}
                      formId={form.id || ""}
                      title={form.title}
                      description={form.description}
                    />
                  </div>

                  {/*
                  // @NOTE: Hiding scenes navigation for now
                  {scenes.length ? <Scenes scenes={scenes} /> : null}
                  */}

                  {R.prop(activeScene, {
                    details: (
                      <DetailsScene
                        {...{
                          isFetching,
                          form,
                          eventDetails,
                          dispatch,
                          preferences,
                          sortedFormFields,
                          moveField,
                          getFormRows,
                          showModal,
                          addSectionRef,
                          showAddQuestionModal,
                          showEditFieldModal,
                          showEditItemBlockModal,
                          getColumnModalFields,
                          registerRef
                        }}
                      />
                    ),
                    review: <OrderReviewScene />,
                    confirmation: <OrderConfirmationScene />
                  })}
                </div>
              </div>
            </div>
          </div>
        </form>
      </ReactSidebar>
    );
  },
  css
);

export default View;
