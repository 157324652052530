import * as R from "ramda";
import moment from "moment";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";

import {
  put,
  call,
  takeEvery,
  all,
  fork,
  select,
  delay
} from "redux-saga/effects";
import { actions } from "Integrations/Fuzion";
import {
  actions as ConnectModalActions,
  getters as ConnectModalGetters
} from "Integrations/FuzionConnectModal";

import {
  actions as MappingModalActions,
  getters as MappingModalGetters
} from "Integrations/FuzionMappingModal";

import { registerError } from "redux/modules/errors/actions";

import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";
import { showSnackbar } from "redux/modules/snackbar/actions";
import Api from "./api";

import { MODULE } from "@lennd/value-types/src/constants/standard-module-field-ids";

const accountReadOnlyFields = resolveReadOnlyFields({
  moduleId: STANDARD_MODULES.accounts.id
});
const contactReadOnlyFields = resolveReadOnlyFields({
  moduleId: STANDARD_MODULES.contacts.id
}).filter(fId => fId !== MODULE.TYPE);

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  const eventDetails = yield select(getEventDetails);
  const userId = yield select(getUserId);

  return {
    credentials,
    eventId,
    eventDetails,
    userId
  };
};

const closeConnectModal = function*({ payload: show }) {
  if (!show) {
    yield all([
      put(ConnectModalActions.setFuzionEventId(null)),
      put(ConnectModalActions.setError(null))
    ]);
  }
};

const indexRecords = records =>
  R.reduce((map, r) => {
    map[r.id] = r.name;
    return map;
  }, {})(records);

const toggleMappingModal = function*({ payload: show }) {
  if (show) {
    try {
      yield put(MappingModalActions.setLoading(true));

      const { credentials, eventId } = yield call(getParams);
      const [
        mappingPayload,
        {
          fields: { fields: accountFields }
        },
        { record_types: accountRecordTypes },
        {
          fields: { fields: contactFields }
        },
        { record_types: contactRecordTypes }
      ] = yield all([
        call(Api.getFields, {
          credentials,
          eventId
        }),
        Api.getModuleFields({
          credentials,
          moduleId: STANDARD_MODULES.accounts.id,
          eventId
        }),
        Api.getRecordTypes({
          credentials,
          moduleId: STANDARD_MODULES.accounts.id,
          eventId
        }),
        Api.getModuleFields({
          credentials,
          moduleId: STANDARD_MODULES.contacts.id,
          eventId
        }),
        Api.getRecordTypes({
          credentials,
          moduleId: STANDARD_MODULES.contacts.id,
          eventId
        })
      ]);

      const disallowedFieldTypes = ["lookup", "reference"];

      const indexedAccountFields = indexRecords(
        R.filter(
          f =>
            !disallowedFieldTypes.includes(f.type) &&
            !R.contains(f.id, accountReadOnlyFields)
        )(accountFields)
      );
      const indexedContactFields = indexRecords(
        R.filter(
          f =>
            !disallowedFieldTypes.includes(f.type) &&
            !R.contains(f.id, contactReadOnlyFields)
        )(contactFields)
      );

      yield all([
        // mappings
        put(MappingModalActions.setMapping(mappingPayload.payload.mappings)),
        put(
          MappingModalActions.setIsPreviewEnabled(
            mappingPayload.payload.is_preview_enabled
          )
        ),
        put(
          MappingModalActions.setRequireProfileApproval(
            mappingPayload.payload.require_profile_approval
          )
        ),
        put(
          MappingModalActions.setCustomLabels(
            mappingPayload.payload.custom_labels
          )
        ),
        put(
          MappingModalActions.setFieldIndex({
            ...indexedAccountFields,
            ...indexedContactFields
          })
        ),
        put(
          MappingModalActions.setWriteDataToLennd(
            mappingPayload.payload.write_data_to_lennd
          )
        ),

        // account
        put(MappingModalActions.setAccountFields(indexedAccountFields)),
        put(
          MappingModalActions.setAccountRecordTypes(
            indexRecords(accountRecordTypes)
          )
        ),

        // contact
        put(MappingModalActions.setContactFields(indexedContactFields)),
        put(
          MappingModalActions.setContactRecordTypes(
            indexRecords(contactRecordTypes)
          )
        ),

        // loading
        put(MappingModalActions.setLoading(false))
      ]);

      yield put(actions.setLoading(false));
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error occurred getting settings"
          }
        ])
      );
    }
  }
};

const getConnection = function*() {
  try {
    const { credentials, eventId } = yield call(getParams);

    const { payload } = yield call(Api.getConnection, {
      credentials,
      eventId
    });

    if (payload.is_connected) {
      yield put(
        actions.setInitialData({
          isActivated: true,
          hasMappings: payload.has_mappings,
          createdBy: payload.configuration.created_by_user.first_name
            ? [
                payload.configuration.created_by_user.first_name,
                payload.configuration.created_by_user.last_name
              ].join(" ")
            : payload.configuration.created_by_user.email,
          createdOn: moment(payload.configuration.created_at).format(
            "dddd, MMMM Do YYYY, h:mm:ss a"
          ),
          lastSyncedFromFuzionAt: payload.configuration.last_synced_to_lennd_at
            ? `Last synced ${moment(
                payload.configuration.last_synced_to_lennd_at
              ).fromNow()}`
            : "Not synced yet",
          lastSyncedToFuzionAt: payload.configuration.last_synced_from_lennd_at
            ? `Last synced ${moment(
                payload.configuration.last_synced_from_lennd_at
              ).fromNow()}`
            : "Not synced yet",
          enableSyncingFromFuzion: payload.configuration.write_data_to_lennd
        })
      );
    }

    yield put(actions.setLoading(false));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred getting connection"
        }
      ])
    );
  }
};

const createConnection = function*() {
  try {
    const { credentials, eventId } = yield call(getParams);

    const fuzionEventId = yield select(ConnectModalGetters.fuzionEventId);

    yield call(Api.createConnection, {
      credentials,
      eventId,
      data: {
        fuzionEventId
      }
    });

    yield put(
      showSnackbar({
        message: "Connection Created",
        action: "OK"
      })
    );

    yield put(actions.setShowConnectModal(false));

    yield call(getConnection);

    yield put(actions.setShowMappingModal(true));
  } catch (error) {
    console.error("Error Connecting:", error);
    yield put(
      ConnectModalActions.setError(
        error && error.error && error.error.message
          ? error.error.message
          : "There was an issue connecting"
      )
    );
  }
};

const deleteConnection = function*() {
  try {
    const { credentials, eventId } = yield call(getParams);

    yield call(Api.deleteConnection, {
      credentials,
      eventId,
      data: {}
    });

    yield put(
      showSnackbar({
        message: "Connection Removed",
        action: "OK"
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred removing connection"
        }
      ])
    );
  }
};

const updateMapping = function*() {
  try {
    yield put(MappingModalActions.setSaving(true));

    const { credentials, eventId } = yield call(getParams);

    const mapping = yield select(MappingModalGetters.mapping);
    const isPreviewEnabled = yield select(MappingModalGetters.isPreviewEnabled);
    const requireProfileApproval = yield select(
      MappingModalGetters.requireProfileApproval
    );
    const customLabels = yield select(MappingModalGetters.customLabels);
    const writeDataToLennd = yield select(MappingModalGetters.writeDataToLennd);

    yield call(Api.updateMapping, {
      credentials,
      eventId,
      data: {
        isPreviewEnabled,
        requireProfileApproval,
        customLabels,
        writeDataToLennd,
        mappings: {
          exhibitor: {
            field_mapping: mapping.exhibitor.field_mapping,
            record_type_mapping: mapping.exhibitor.record_type_mapping
          }
        }
      }
    });

    yield put(actions.setShowMappingModal(false));

    yield put(
      showSnackbar({
        message: "Settings Updated",
        action: "OK"
      })
    );

    yield call(getConnection);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred saving settings"
        }
      ])
    );
  } finally {
    yield put(MappingModalActions.setSaving(false));
  }
};

const syncFromFuzion = function*() {
  const { credentials, eventId } = yield call(getParams);

  yield put(actions.setIsSyncingFromFuzion(true));

  try {
    const initialResult = yield call(Api.syncData, credentials, {
      eventId,
      jobType: "syncToLennd"
    });

    yield delay(500);

    let progressResult = yield call(Api.getJobStatus, credentials, {
      jobId: initialResult.payload.jobId
    });

    while (["pending", "processing"].includes(progressResult.payload.status)) {
      yield delay(3000);
      progressResult = yield call(Api.getJobStatus, credentials, {
        jobId: initialResult.payload.jobId
      });
    }

    yield call(getConnection);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred syncing from Fuzion"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setIsSyncingFromFuzion(false));
  }
};

const syncToFuzion = function*() {
  const { credentials, eventId } = yield call(getParams);

  yield put(actions.setIsSyncingToFuzion(true));

  try {
    const initialResult = yield call(Api.syncData, credentials, {
      eventId,
      jobType: "syncFromLennd"
    });

    yield delay(500);

    let progressResult = yield call(Api.getJobStatus, credentials, {
      jobId: initialResult.payload.jobId
    });

    while (["pending", "processing"].includes(progressResult.payload.status)) {
      yield delay(3000);
      progressResult = yield call(Api.getJobStatus, credentials, {
        jobId: initialResult.payload.jobId
      });
    }

    yield call(getConnection);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred syncing to Fuzion"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setIsSyncingToFuzion(false));
  }
};

const watchGetConnection = function*() {
  yield takeEvery(actions.getConnection.type, getConnection);
};

const watchCreateConnection = function*() {
  yield takeEvery(ConnectModalActions.createConnection.type, createConnection);
};

const watchDeleteConnection = function*() {
  yield takeEvery(actions.deleteConnection.type, deleteConnection);
};

const watchCloseConnectModal = function*() {
  yield takeEvery(actions.setShowConnectModal.type, closeConnectModal);
};

const watchToggleMappingModal = function*() {
  yield takeEvery(actions.setShowMappingModal.type, toggleMappingModal);
};

const watchUpdateMapping = function*() {
  yield takeEvery(MappingModalActions.saveMapping.type, updateMapping);
};

const watchSyncFromFuzion = function*() {
  yield takeEvery(actions.syncFromFuzion.type, syncFromFuzion);
};

const wathSyncToFuzion = function*() {
  yield takeEvery(actions.syncToFuzion.type, syncToFuzion);
};

const rootSaga = function*() {
  yield all([
    fork(watchGetConnection),
    fork(watchCreateConnection),
    fork(watchDeleteConnection),
    fork(watchCloseConnectModal),
    fork(watchToggleMappingModal),
    fork(watchUpdateMapping),
    fork(watchSyncFromFuzion),
    fork(wathSyncToFuzion)
  ]);
};

export default rootSaga;
