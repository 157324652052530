import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Label from "components/Global-2016/Forms/Label";
import BoolCheckbox from "components/Global-2016/Forms/BoolCheckbox";
import moment from "moment";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const formats = ["MMMM	Do LT", "LLL", "l LT", "YYYY-MM-DD LT"];

@CSSModules(css)
class DateTimeFormatSettings extends Component {
  @autobind
  onFormatChange(e) {
    this.props.onChange({ ...this.props.value, format: e.target.value });
  }

  render() {
    const { value: { format } } = this.props;
    return (
      <div>
        <div className="input-wrapper">
          <label>Date Format</label>
          <select
            onChange={this.onFormatChange}
            value={format}
            styleName="input"
          >
            {formats.map(f => (
              <option key={f} value={f}>
                {moment().format(f)}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}

DateTimeFormatSettings.propTypes = {
  styles: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default DateTimeFormatSettings;
