import * as R from "ramda";
import { createHandlers, createModule } from "redux-mvc";

import peopleTypePanel from "Accounts/PeopleTypePanel";
import peopleTable from "Accounts/PeopleTable";

const handlers = createHandlers({
  iniState: {
    groups: [],
    loading: false,
    updateRows: false
  },
  reducers: {
    removeRelatedContact: R.identity,
    refreshData: R.identity,
    groupTypesResponse: (s, { payload: groups, error }) =>
      error
        ? s
        : {
            groups
          }
  },
  namespace: "PeoplePanels"
});

const module = createModule(handlers);

module.plugModule(peopleTypePanel);
module.plugModule(peopleTable);

const { actions, getters } = module;

export { actions, getters };

export default module;
