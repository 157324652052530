import api from "./api";
import { CREATE, BULK_CREATE } from "./constants";
import { registerError } from "redux/modules/errors/actions";
import { eventId as getEventId } from "redux/modules/event/selectors";

export function addValue(data) {
  return async (dispatch, getState) => {
    const eventId = getEventId(getState());
    try {
      dispatch({
        type: CREATE,
        payload: data
      });
      return await api.post(getState().user.user.credentials, {
        eventId,
        ...data
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding value"
          }
        ])
      );
    }
  };
}

export function addValues(data) {
  return async (dispatch, getState) => {
    const eventId = getEventId(getState());
    try {
      dispatch({
        type: BULK_CREATE,
        payload: data
      });
      return await api.post(getState().user.user.credentials, {
        bulk: true,
        eventId,
        ...data
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding values"
          }
        ])
      );
    }
  };
}
