import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE, PAGES } from "./constants";

const iniState = {
  bulkQuestionsPage: PAGES.SELECT_QUESTIONS,
  questionsFilter: "",
  variantsFilter: "",
  loading: false,
  itemTypes: [],
  typeIds: [],
  groupIds: {},
  variantIds: {},
  questions: [],
  sets: [],
  setsIds: [],
  itemQuestionsIds: [],
  test: []
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    cancelInstance: R.always(iniState),
    init: R.identity,
    assignQuestionsToItems: R.identity,
    selectItems: R.identity,
    refetchSets: R.identity,
    setInitialData: (
      state,
      { payload: { questionSetIds, questionIds, itemTypes, questions, sets } }
    ) => {
      const itemTypesFormatted = R.reduce(
        (acc, type) => {
          if (R.length(type.groups) < 1) {
            return acc;
          }
          return [
            ...acc,
            {
              ...type,
              groups: R.map(
                ({ id, name, items }) => ({
                  id,
                  name,
                  variants: R.compose(
                    R.flatten,
                    R.map(R.propOr([], "variants")),
                    R.flatten
                  )(items)
                }),
                type.groups
              )
            }
          ];
        },
        [],
        itemTypes
      );
      return {
        setsIds: questionSetIds,
        questionSetIds,
        itemQuestionsIds: questionIds,
        questionIds,
        questions,
        sets,
        bulkQuestionsPage:
          R.length([...questionSetIds, ...questionIds]) > 0
            ? PAGES.ASSIGN_ITEMS
            : PAGES.SELECT_QUESTIONS,
        itemTypes: itemTypesFormatted
      };
    },
    goToSelectItems: (state, { payload: { variantList } }) => {
      const { typeIds, groupIds, variantIds } = R.compose(
        R.reduce(
          (acc, variant) => {
            const hasType = R.any(id => id === variant.typeId, acc.typeIds);
            const hasGroup = R.has(variant.typeId, acc.groupIds);
            const hasVariant = R.has(variant.groupId, acc.variantIds);
            return {
              typeIds: hasType ? acc.typeIds : [...acc.typeIds, variant.typeId],
              groupIds: hasGroup
                ? {
                    ...acc.groupIds,
                    [variant.typeId]: R.uniq([
                      ...acc.groupIds[variant.typeId],
                      variant.groupId
                    ])
                  }
                : {
                    ...acc.groupIds,
                    [variant.typeId]: [variant.groupId]
                  },
              variantIds: hasVariant
                ? {
                    ...acc.variantIds,
                    [variant.groupId]: [
                      ...acc.variantIds[variant.groupId],
                      variant.id
                    ]
                  }
                : {
                    ...acc.variantIds,
                    [variant.groupId]: [variant.id]
                  }
            };
          },
          {
            typeIds: [],
            groupIds: {},
            variantIds: {}
          }
        ),
        R.filter(variant => R.any(id => id === variant.id, variantList)),
        R.reduce((acc, group) => {
          return [
            ...acc,
            ...R.map(
              variant => ({
                ...variant,
                groupId: group.id,
                typeId: group.typeId
              }),
              group.variants
            )
          ];
        }, []),
        R.reduce((acc, type) => {
          return [
            ...acc,
            ...R.map(group => ({ ...group, typeId: type.id }), type.groups)
          ];
        }, [])
      )(state.itemTypes);
      return {
        bulkQuestionsPage: PAGES.ASSIGN_ITEMS,
        typeIds,
        groupIds,
        variantIds
      };
    },
    toggleItemQuestion: (state, { payload: { isEnabled, id } }) => ({
      itemQuestionsIds: isEnabled
        ? R.filter(questionId => questionId !== id, state.itemQuestionsIds)
        : [...state.itemQuestionsIds, id]
    }),
    toggleSet: (state, { payload: { isEnabled, id } }) => ({
      setsIds: isEnabled
        ? R.filter(setId => setId !== id, state.setsIds)
        : [...state.setsIds, id]
    }),
    removeAllQuestionsAndSets: () => ({
      setsIds: [],
      itemQuestionsIds: []
    }),
    toggleType: (state, { payload: { typeId, isEnabled, groups } }) => ({
      typeIds: isEnabled
        ? R.filter(id => id !== typeId, state.typeIds)
        : [...state.typeIds, typeId],
      groupIds: isEnabled
        ? R.omit([typeId], state.groupIds)
        : {
            ...state.groupIds,
            [typeId]: R.map(R.prop("id"), groups)
          },
      variantIds: isEnabled
        ? R.omit(R.map(R.prop("id"), groups), state.variantIds)
        : {
            ...state.variantIds,
            ...R.reduce(
              (acc, group) => {
                return {
                  ...acc,
                  [group.id]: R.map(R.prop("id"), group.variants)
                };
              },
              {},
              groups
            )
          }
    }),
    toggleGroup: (
      state,
      { payload: { typeId, groupId, isEnabled, variants } }
    ) => {
      const manageIds = () => {
        const hasType = R.has(typeId, state.groupIds);
        if (!isEnabled) {
          return {
            typeIds: !hasType ? [...state.typeIds, typeId] : state.typeIds,
            groupIds: !hasType
              ? { ...state.groupIds, [typeId]: [groupId] }
              : {
                  ...state.groupIds,
                  [typeId]: [...state.groupIds[typeId], groupId]
                },
            variantIds: {
              ...state.variantIds,
              [groupId]: R.map(R.prop("id"), variants)
            }
          };
        }
        const hasOneGroupId = R.propOr([], typeId, state.groupIds).length === 1;
        return {
          typeIds: hasOneGroupId
            ? R.filter(id => id !== typeId, state.typeIds)
            : state.typeIds,
          groupIds: hasOneGroupId
            ? R.omit([typeId], state.groupIds)
            : {
                ...state.groupIds,
                [typeId]: R.filter(id => id !== groupId, state.groupIds[typeId])
              },
          variantIds: R.omit([groupId], state.variantIds)
        };
      };
      const { groupIds, variantIds, typeIds } = manageIds();
      return {
        groupIds,
        variantIds,
        typeIds
      };
    },
    toggleVariant: (
      state,
      { payload: { typeId, groupId, variantId, isEnabled } }
    ) => {
      const manageIds = () => {
        const hasGroup = R.has(groupId, state.variantIds);
        const hasType = R.has(typeId, state.groupIds);
        if (!isEnabled) {
          const newGroupIds = () => {
            if (!hasType) {
              return { ...state.groupIds, [typeId]: [groupId] };
            } else {
              if (!hasGroup) {
                return {
                  ...state.groupIds,
                  [typeId]: [...state.groupIds[typeId], groupId]
                };
              }
              return state.groupIds;
            }
          };
          return {
            typeIds: !hasType ? [...state.typeIds, typeId] : state.typeIds,
            variantIds: !hasGroup
              ? { ...state.variantIds, [groupId]: [variantId] }
              : {
                  ...state.variantIds,
                  [groupId]: [...state.variantIds[groupId], variantId]
                },
            groupIds: newGroupIds()
          };
        }
        const hasOnevariantId =
          R.propOr([], groupId, state.variantIds).length === 1;
        if (hasOnevariantId) {
          const groupsLength = R.length(R.propOr([], typeId, state.groupIds));
          return {
            variantIds: R.omit([groupId], state.variantIds),
            groupIds:
              groupsLength > 1
                ? {
                    ...state.groupIds,
                    [typeId]: R.filter(
                      id => id !== groupId,
                      state.groupIds[typeId]
                    )
                  }
                : R.omit([typeId], state.groupIds),
            typeIds:
              groupsLength > 1
                ? state.typeIds
                : R.filter(id => id !== typeId, state.typeIds)
          };
        }
        return {
          variantIds: {
            ...state.variantIds,
            [groupId]: R.filter(
              id => id !== variantId,
              state.variantIds[groupId]
            )
          },
          groupIds: state.groupIds,
          typeIds: state.typeIds
        };
      };
      const { variantIds, groupIds, typeIds } = manageIds();
      return {
        variantIds,
        groupIds,
        typeIds
      };
    },
    removeVariant: (state, { payload: { typeId, groupId, variantId } }) => {
      const hasOnevariantId =
        R.propOr([], groupId, state.variantIds).length === 1;
      if (hasOnevariantId) {
        const groupsLength = R.length(R.propOr([], typeId, state.groupIds));
        return {
          variantIds: R.omit([groupId], state.variantIds),
          groupIds:
            groupsLength > 1
              ? {
                  ...state.groupIds,
                  [typeId]: R.filter(
                    id => id !== groupId,
                    state.groupIds[typeId]
                  )
                }
              : R.omit([typeId], state.groupIds),
          typeIds:
            groupsLength > 1
              ? state.typeIds
              : R.filter(id => id !== typeId, state.typeIds)
        };
      }
      return {
        variantIds: {
          ...state.variantIds,
          [groupId]: R.filter(id => id !== variantId, state.variantIds[groupId])
        },
        groupIds: state.groupIds,
        typeIds: state.typeIds
      };
    }
  }
});

export default handlers;
