import Helpers from "utils/Global/Helpers";

export default {
  getPortals: ({ credentials, eventId }) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/portal/event/${eventId}/portals`,
        credentials,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  delete: ({ credentials, formId }) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/form/form/${formId}`,
        credentials,
        data: {},
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  postSubmission: ({ credentials, data }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/form/form/${data.formId}/submissions`,
        credentials,
        data,
        success,
        error
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  addRecord: ({ credentials, data, eventId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */

      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/modules/${data.moduleId}/records`,
        credentials,
        data,
        qs: { eventId },
        success,
        error
      });
    })
};
