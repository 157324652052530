import React from "react";
import { connect } from "react-redux";

import { actions, getters } from "../../model";

import MiniModalWrapper from "ui-kit/MiniModalWrapper";

import MiniItemsSelector from "ui-kit/MiniItemsSelector/View";

import { noop } from "utils/General";

import {
  getAssignedIds,
  getAssignModalItems,
  getAssignModalTitle
} from "../../selectors";

const decorate = connect(
  state => ({
    items: getAssignModalItems(state),
    assignedIds: getAssignedIds(state),
    assignModalType: getters.assignModalType(state),
    title: getAssignModalTitle(state)
  }),
  {
    hideModal: () => actions.setShowAssignModal(false),
    updateAssignedList: actions.updateAssignedList
  }
);

const AssignModal = ({
  assignedIds = [],
  hideModal = noop,
  items = [],
  updateAssignedList = noop,
  assignModalType = "",
  title = ""
}) => {
  return (
    <MiniModalWrapper
      showModal
      hideModal={hideModal}
      title={title}
      display="column.flex-start.stretch"
      width={845}
      height={600}
    >
      <MiniItemsSelector
        iniClearSearch
        itemGroups={[{ id: assignModalType, items }]}
        iniSelected={assignedIds}
        onCancel={hideModal}
        onDone={updateAssignedList}
      />
    </MiniModalWrapper>
  );
};

export default decorate(AssignModal);
