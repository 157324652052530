export const CARD_ACTIONS = {
  ADD_GROUP: "addGroup",
  MANAGE: "manage",
  PORTALS: "portals",
  SEND_EMAIL: "send-card-email",
  SETTINGS: "settings"
};

export const TABLE_ACTIONS = {
  ADD_ALLOCATIONS: "add-allocations",
  ADD_CONTACT: "add-contact",
  COPY_SHARE_LINK: "copy-share-link",
  DISABLE_GROUP: "disable-group",
  EDIT_ALLOCATIONS: "edit-allocations",
  ENABLE_GROUP: "enable-group",
  OPEN_GROUP_PROFILE: "open-group-profile",
  REVIEW_PENDING_REQUESTS: "review-pending-requests",
  SEND_EMAIL: "send-email",
  SEND_LINK: "send-link",
  SHOW_SUMMARY: "show-summary",
  VIEW_GUEST_LIST: "view-guest-list"
};

export const GROUP_FILTER_ID = "group-filter";

export const FILTER_ID = {
  ALL: "all-groups",
  NO_ALLOCATIONS: "no-allocations",
  NO_CONTACTS: "no-primary-contacts"
};
