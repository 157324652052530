import React from "react";
import * as R from "ramda";
import {
  BigOutlineButton,
  Span,
  BigOutlineInput,
  BigShadedBox,
  BoxGroup,
  CheckIcon,
  CloseIcon,
  Collapsable,
  Div,
  DownFilledIcon,
  DownIcon,
  FullSizeDotIcon,
  MediumOutlineButton,
  MediumShadedBox,
  PopoverMenu,
  RightIcon,
  SearchIcon,
  Popover,
  SmallTextButton,
  SmallCheckbox,
  SmallOutlineButton,
  Text0,
  MediumTextButton,
  AssignmentIcon,
  NumberSelector,
  Text1,
  Text2,
  Text5,
  collapsableHandler,
  makeTable
} from "components/Base";
import { BulkApprovalButton } from "components/Global/Approvals/BulkApprovalButton";
import ApproverLabel from "components/Global/Approvals/ApproversLabel";
import {
  defaultProps,
  withProps,
  withState,
  mapProps,
  addS,
  noop
} from "utils/General";
import { StatusTag } from "utils/status-comps";

const isOdd = x => x % 2 === 1;

const AssignmentCounter = withState("counter", "setCounter", 1)(
  ({ counter, setCounter, onAssign, closePopover, maxValue }) => (
    <Div bra={1} width={150}>
      <MediumTextButton
        LeftIcon={withProps({ color: "primary5" })(AssignmentIcon)}
        onClick={() => {
          onAssign(maxValue);
          closePopover();
        }}
      >
        Assign all to...
      </MediumTextButton>
      <Div bt={1} pt={2} px={2} bc="neutral5">
        <Text0 color="neutral4" uppercase bold>
          set qty to assign
        </Text0>
        <NumberSelector
          value={counter}
          onChangeValue={setCounter}
          maxValue={maxValue}
        />
      </Div>
      <SmallTextButton
        my={1}
        LeftIcon={withProps({ color: "primary5" })(AssignmentIcon)}
        onClick={() => {
          onAssign(counter);
          closePopover();
        }}
      >
        Assign {counter} item to...
      </SmallTextButton>
    </Div>
  )
);

const TableStyler = withProps({
  my: 3,
  bg: "white"
})(Div);

const HeaderStyler = withProps({
  display: "row.flex-start.center",
  pb: 3
})(Div);

const RowStyler = R.compose(
  withProps({
    display: "row.flex-start.center",
    bc: "neutral2",
    bt: 1,
    py: 1,
    px: 1
  }),
  mapProps(({ toggleCollapsed, onCollapse, style, ...props }) => ({
    onClick: toggleCollapsed
      ? () => {
          toggleCollapsed();
          if (typeof onCollapse === "function") onCollapse();
        }
      : undefined,
    style: { ...style, cursor: toggleCollapsed ? "pointer" : "default" },
    ...props
  }))
)(Div);

const BaseCell = defaultProps({ display: "inline-block", bold: true })(Text2);

const ExpandCell = ({ isNested, collapsed }) =>
  isNested ? collapsed ? <RightIcon /> : <DownIcon /> : null;
const RequestedCell = ({ numRequired }) => <BaseCell>{numRequired}</BaseCell>;
const ApprovedCell = ({ numApproved }) => (
  <BaseCell>{numApproved || "—"}</BaseCell>
);
const DeniedCell = ({ numRejected }) => (
  <BaseCell>{numRejected || "—"}</BaseCell>
);

const ItemCell = ({ itemName, itemColor }) => (
  <BaseCell display="flex-inline.flex-start.center">
    {itemColor && (
      <Div pill size={10} mr={2} style={{ backgroundColor: itemColor }} />
    )}
    {itemName}
  </BaseCell>
);
const OrderCell = ({ orders }) => (
  <BaseCell
    pr={2}
    title={R.compose(
      R.join(", "),
      R.sort((a, b) => {
        return b - a;
      }),
      R.map(R.prop("number"))
    )(orders)}
    style={{ overflow: "hidden", width: "100%", textOverflow: "ellipsis" }}
  >
    {R.compose(
      R.map(({ number, onClick }) => (
        <Span key={number} mr={1} onClick={onClick}>
          #{number}
        </Span>
      )),
      R.sort(R.prop("number"))
    )(orders)}
  </BaseCell>
);

const AssignedCell = ({
  isNested,
  assignedTo,
  numRequired,
  onRemoveAssignment,
  onAssign
}) => {
  if (isNested) {
    return <BaseCell>{"—"}</BaseCell>;
  } else if (!onAssign) {
    if (assignedTo) {
      return (
        <Text2 truncate pr={1}>
          {assignedTo}
        </Text2>
      );
    }
    return <BaseCell>{"—"}</BaseCell>;
  } else if (assignedTo) {
    return (
      <Div truncate display="row.flex-start.center">
        <Text2>{assignedTo}</Text2>
        <CloseIcon
          sizeWFS={1}
          ml={1}
          title={"Remove assignment"}
          onClick={onRemoveAssignment}
        />
      </Div>
    );
  } else if (numRequired > 1) {
    return (
      <Popover
        wrapperProps={{ display: "row.flex-start.center" }}
        Label={({ onClick }) => (
          <Text2
            onClick={onClick}
            bold
            color={{ default: "primary5", hover: "primary4" }}
          >
            Assign
          </Text2>
        )}
      >
        {({ closePopover }) => (
          <AssignmentCounter
            maxValue={numRequired}
            closePopover={closePopover}
            onAssign={onAssign}
          />
        )}
      </Popover>
    );
  } else {
    return (
      <Text2 onClick={() => onAssign(1)} bold color="primary5">
        Assign
      </Text2>
    );
  }
};

const ActionsCell = ({
  numRejected,
  numRequired,
  numApproved,
  approvalProps,
  bulkApprovalProps
}) => {
  let content = "";
  if (approvalProps) {
    content = <ApproverLabel {...approvalProps} />;
  }
  if (numRequired === numApproved) {
    content = <StatusTag status="approved" />;
  } else if (numRequired === numRejected) {
    content = <StatusTag status="rejected" />;
  } else if (bulkApprovalProps && !bulkApprovalProps.isUserApprover) {
    content = <StatusTag status="pending" />;
  } else if (
    bulkApprovalProps &&
    bulkApprovalProps.countOfRemainingReviews === 0
  ) {
    content = <StatusTag status="reviewed" />;
  } else if (bulkApprovalProps) {
    content = (
      <BulkApprovalButton
        totalUserApprovals={bulkApprovalProps.countOfRemainingReviews}
        approveRemaining={bulkApprovalProps.onBulkApprove}
        denyRemaining={bulkApprovalProps.onBulkReject}
        LabelComp={Text2}
        labelProps={{
          color: { default: "primary5", hover: "primary4" },
          display: "row.flex-start.center",
          bold: true
        }}
      />
    );
  }

  return (
    <Div display="row.flex-start.center" height={30}>
      {content}
    </Div>
  );
};
const stopPropagation = cb => e => {
  e.stopPropagation();
  cb();
};
const AccountCell = ({ accountName }) => (
  <BaseCell>{accountName || "—"}</BaseCell>
);
const ContactCell = ({ contactName }) => (
  <BaseCell>{contactName || "—"}</BaseCell>
);

const HeaderCell = withProps({ bold: true })(Text1);
const ApprovedCountHeader = () => (
  <Div display="row.flex-start.flex-end">
    <CheckIcon title="Approved" />
  </Div>
);
const RequestedCountHeader = withProps({ children: "Req #" })(HeaderCell);
const DeniedCountHeader = () => (
  <Div display="row.flex-start.flex-end">
    <CloseIcon title="Rejected" />
  </Div>
);
const ItemHeader = () => <HeaderCell>Item</HeaderCell>;
const OrderHeader = () => <HeaderCell pr={2}>Order</HeaderCell>;
const AssignedHeader = () => <HeaderCell>Assigned to</HeaderCell>;

// TODO commented out until can handle sorting nested tables with shared column headers
// const AssignedHeader = ({ toggleSort }) => (
//   <Text1 onClick={() => toggleSort("assignedTo")} bold>
//     Assigned to
//   </Text1>
// );
const ActionsHeader = () => <HeaderCell>Actions</HeaderCell>;
const AccountHeader = withProps({ children: "Related Group" })(HeaderCell);
const ContactHeader = withProps({ children: "Related Person" })(HeaderCell);

const tableSettings = {
  TableStyler,
  HeaderStyler,
  RowStyler,
  HeaderCellStyler: Div,
  RowCellStyler: Div,
  columnProps: [
    { width: 30 },
    { width: 3 / 14 },
    { width: 50 },
    { width: 50 },
    { width: 50 },
    { width: 3 / 14 },
    { width: 2 / 14 },
    { width: 3 / 14 }
  ]
};

const peopleTableProps = {
  ...tableSettings,
  headerCellComps: [
    HeaderCell,
    ItemHeader,
    RequestedCountHeader,
    DeniedCountHeader,
    ApprovedCountHeader,
    AssignedHeader,
    OrderHeader,
    ActionsHeader
  ],
  rowCellComps: [
    ExpandCell,
    ItemCell,
    RequestedCell,
    DeniedCell,
    ApprovedCell,
    AssignedCell,
    OrderCell,
    ActionsCell
  ]
};
const accountsTableProps = peopleTableProps;
const typesTableProps = {
  ...tableSettings,
  headerCellComps: [
    HeaderCell,
    AccountHeader,
    ContactHeader,
    RequestedCountHeader,
    DeniedCountHeader,
    ApprovedCountHeader,
    AssignedHeader,
    OrderHeader,
    ActionsHeader
  ],
  rowCellComps: [
    ExpandCell,
    AccountCell,
    ContactCell,
    RequestedCell,
    DeniedCell,
    ApprovedCell,
    AssignedCell,
    OrderCell,
    ActionsCell
  ],
  columnProps: [
    { width: 30 },
    { width: 3 / 17 },
    { width: 3 / 17 },
    { width: 50 },
    { width: 50 },
    { width: 50 },
    { width: 3 / 17 },
    { width: 2 / 17 },
    { width: 3 / 17 }
  ]
};

const getCollapsableRowComp = parentTableSettings =>
  makeTable({
    ...parentTableSettings,
    TableStyler: withProps({ bg: "neutral0" })(Div),
    HeaderStyler: Div,
    headerCellComps: []
  });

const buildReportTable = settings =>
  makeTable({
    ...settings,
    RowCollapsableComp: getCollapsableRowComp(settings)
  });

const ReportTable = buildReportTable(peopleTableProps);
const TypesReportTable = buildReportTable(typesTableProps);

// @NOTE: Commenting this out. Was throwing for being a duplicate call.
// const ReportTable = makeTable({ ...tableSettings, RowCollapsableComp });

const ReportCard = collapsableHandler(
  ({
    bulkApprovalProps,
    headerSubmessages = [],
    headerTitle,
    headerIconColor,
    percentComplete,
    numPendingReview,
    collapsed,
    toggleCollapsed,
    rowData = [],
    collapsableRowsData,
    ReportTableComp = ReportTable
  }) => (
    <Div bg="white" shadow={1} bra={1} mt={2}>
      <Div
        onClick={toggleCollapsed}
        ml={5}
        my={3}
        mr={4}
        display="row.flex-start.center"
      >
        {/* <Div
          display="row.center.center"
          pill
          size={40}
          bg="yellow7"
          color="yellow0"
          fs={2}
          fw={3}
        /> */}
        {headerIconColor && (
          <Div
            size={15}
            mr={2}
            style={{ backgroundColor: headerIconColor }}
            pill
          />
        )}
        <Text5 ml={2} color="neutral8" bold>
          {headerTitle}
        </Text5>
        <Div ml={2} flex={1} display="row.flex-start.center">
          {R.addIndex(R.map)(
            ({ value, onClick }, i, list) => (
              <Div display="row.flex-start.center">
                <Text1 color="neutral4" onClick={onClick}>
                  {value}
                </Text1>
                {i < R.length(list) - 1 && (
                  <Text1 px={1} color="neutral4">
                    {"·"}
                  </Text1>
                )}
              </Div>
            ),
            headerSubmessages
          )}
        </Div>
        <Div display="column.flex-start.flex-end">
          <Text2 color="neutral5" bold>
            {Number.parseFloat(percentComplete).toFixed(0)}% complete
          </Text2>
          {numPendingReview > 0 ? (
            <Text2 color="neutral5" bold>
              {numPendingReview} item
              {addS(numPendingReview)} pending review
            </Text2>
          ) : (
            ""
          )}
        </Div>
      </Div>
      <Collapsable collapsed={collapsed}>
        <Div ml={5}>
          <ReportTableComp
            rowsData={rowData}
            rowsCollapsableData={collapsableRowsData}
          />
        </Div>

        <Div mx={2} mb={2} display="row.flex-end.center">
          {bulkApprovalProps &&
          bulkApprovalProps.countOfRemainingReviews !== 0 ? (
            <BulkApprovalButton
              totalUserApprovals={bulkApprovalProps.countOfRemainingReviews}
              approveRemaining={bulkApprovalProps.onBulkApprove}
              denyRemaining={bulkApprovalProps.onBulkReject}
              LabelComp={MediumOutlineButton}
            />
          ) : (
            ""
          )}
        </Div>
      </Collapsable>
    </Div>
  )
);

const FilterTag = ({ onClose, message1, message2 }) => (
  <MediumShadedBox color="neutral8">
    <Text1>{message1}</Text1>
    <Text1 ml={1} bold>
      {message2}
    </Text1>
    <CloseIcon
      size={16}
      ml={2}
      onClick={onClose}
      color={{ default: "inherit", hover: "red7" }}
    />
  </MediumShadedBox>
);

const TopBar = ({ filterBy, searchFor, onSearch }) => (
  <BoxGroup bg="white" bra={1} display="row.flex-start.center">
    <BigOutlineButton bold RightIcon={DownIcon}>
      {`Filter ${filterBy}`}
    </BigOutlineButton>
    <BigOutlineInput
      flex={1}
      LeftIcon={SearchIcon}
      continuous
      placeholder={`Search for a ${searchFor}`}
      onChange={onSearch}
    />
  </BoxGroup>
);

const CheckboxSection = ({ name, options }) => (
  <Div width={200}>
    <Div color="neutral6" pt={4} pb={2} fs={0} fw={3} uppercase>
      {name}
    </Div>
    {R.map(
      ({ name: checkboxLabel, selected, onToggleCheckbox }) => (
        <Div display="row.flex-start.center" pb={2}>
          <SmallCheckbox
            mr={1}
            selected={selected}
            onClick={onToggleCheckbox}
          />
          <Text2 color="neutral6" bold>
            {checkboxLabel}
          </Text2>
        </Div>
      ),
      options
    )}
  </Div>
);

const FilterSection = collapsableHandler(
  ({ toggleCollapsed, collapsed, selectionSections, name, index }) => (
    <Div>
      <Div
        onClick={toggleCollapsed}
        display="row.flex-start.center"
        py={2}
        bt={index === 0 ? 0 : 1}
        bc="neutral3"
      >
        <FullSizeDotIcon color="primary6" />
        <Text2 color="primary6" flex={1} pl={2} bold>
          {name}
        </Text2>
        {collapsed ? <RightIcon size={24} /> : <DownIcon size={24} />}
      </Div>
      <Collapsable collapsed={collapsed}>
        <Div mb={2} display="row">
          <Div display="column">
            {R.addIndex(R.map)(
              (props, i) => (isOdd(i) ? null : <CheckboxSection {...props} />),
              selectionSections
            )}
          </Div>
          <Div display="column">
            {R.addIndex(R.map)(
              (props, i) => (isOdd(i) ? <CheckboxSection {...props} /> : null),
              selectionSections
            )}
          </Div>
        </Div>
      </Collapsable>
    </Div>
  )
);

const FilterPopup = ({
  onClickGroupBy,
  filterSections,
  resultsPerPage,
  setResultsPerPage = noop,
  resultsPerPageOptions = []
}) => (
  <Div width={400} m={10} bra={1} shadow={2}>
    <Div py={4} px={5} bb={1} bc="neutral3">
      <Text5 bold>Showing line items:</Text5>
      <BoxGroup mt={3} display="row.flex-start.center">
        <BigShadedBox>Grouped by</BigShadedBox>
        <BigOutlineButton
          display="row.space-between.center"
          onClick={onClickGroupBy}
          flex={1}
          RightIcon={DownFilledIcon}
        >
          {"Related Group"}
        </BigOutlineButton>
      </BoxGroup>
    </Div>
    <Div px={5} bb={1} bc="neutral3">
      <Text5 mt={3} bold>
        ...that match:
      </Text5>

      {R.addIndex(R.map)(
        (props, index) => (
          <FilterSection {...props} index={index} />
        ),
        filterSections
      )}
    </Div>
    <Div display="row.flex-start.center" py={2} px={5}>
      <Text2 bold>Results per page:</Text2>
      <PopoverMenu
        Label={({ onClick }) => (
          <SmallOutlineButton
            ml={5}
            onClick={onClick}
            RightIcon={DownFilledIcon}
          >
            {resultsPerPage}
          </SmallOutlineButton>
        )}
        menuItems={R.map(
          option => [option, setResultsPerPage(option)],
          resultsPerPageOptions
        )}
      />
    </Div>
  </Div>
);

/*
const formatReportInput = ({
  goToOrder,
  goToContact,
  approveAll,
  rejectAll,
  approveOrder,
  rejectOrder
}) =>
  R.map(
    ({
      name,
      email,
      account_name: account,
      total_count_of_requested: totalRequested,
      total_count_of_approved: totalApproved,
      line_items: items
    }) => ({
      headerSubmessages: [email, account],
      headerTitle: name,
      percentComplete: (totalApproved * 100 / totalRequested).toFixed(0),
      numPendingReview: totalRequested - totalApproved,
      onApproveAll: approveAll,
      onRejectAll: rejectAll,
      rowData: R.map(
        ({
          count_of_requested,
          count_of_approved,
          name: itemName,
          price,
          order,
          assigned_to
        }) => ({
          numRequired: count_of_requested,
          numApproved: count_of_approved,
          itemName,
          price: R.isNil(price) || R.isEmpty(price) ? undefined : price,
          orderNumber: order.number,
          assignedTo: assigned_to.name,
          onApprove: () => approveOrder(order.id),
          onReject: () => rejectOrder(order.id),
          onClickOrderNumber: () => goToOrder(order.id),
          onClickAssignedTo: () => goToContact(assigned_to.contact_id)
        }),
        items || []
      )
    })
  );
*/

const ReportCards = ({ rows }) => (
  <Div>
    {R.map(
      row => (
        <ReportCard {...row} />
      ),
      rows
    )}
  </Div>
);

export { FilterPopup, FilterTag, ReportCards, TopBar, TypesReportTable };
