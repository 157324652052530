import React from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import {
  ButtonRed,
  ButtonOutline,
  ButtonGroup
} from "components/Global/Modal/Layout/Buttons";

import { connect } from "react-redux";
import { hideModal } from "redux/modules/modal/actions";

const decorate = connect(
  null,
  {
    hideModal
  }
);

const DeleteConfirmationModal = ({
  heading,
  hideModal,
  message,
  onConfirm,
  confirmLabel
}) => (
  <StyleWrapper heading={heading} hideModal={hideModal}>
    {message}
    <ButtonGroup>
      <ButtonRed
        onClick={() => {
          onConfirm();
          hideModal();
        }}
        title={confirmLabel}
      />
      <ButtonOutline onClick={hideModal} title="Cancel" />
    </ButtonGroup>
  </StyleWrapper>
);

DeleteConfirmationModal.defaultProps = {
  confirmLabel: "Delete"
};

export default decorate(DeleteConfirmationModal);
