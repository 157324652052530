import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import { sortBy } from "lodash";
import Toggle from "material-ui/Toggle";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as FormsActions from "redux/modules/formsV2/forms/actions";
import * as FormsSelectors from "redux/modules/formsV2/forms/selectors";
import * as EventSelectors from "redux/modules/event/selectors";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

export const FormOption = CSSModules(
  ({ title, selected, ...other }) => (
    <div
      styleName={selected ? "optionContainerSelected" : "optionContainer"}
      {...other}
    >
      <i className={["material-icons", css.optionLeftIcon].join(" ")}>done</i>
      <div>
        <div styleName="optionTitle">{title}</div>
      </div>
    </div>
  ),
  css
);

FormOption.propTypes = {
  styles: PropTypes.object,
  title: PropTypes.string.isRequired,
  selected: PropTypes.bool
};

@CSSModules(css)
export class PortalFormsSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.value ? [...this.props.value] : []
    };
  }

  componentDidMount() {
    this.props.getForms(this.props.eventId);
  }

  @autobind
  onSelect(selected, e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState(
      state => {
        if (state.selected.includes(selected)) {
          state.selected = state.selected.filter(id => id !== selected);
        } else {
          state.selected.push(selected);
        }
        return state;
      },
      () => {
        this.props.onSelect(this.state.selected);
      }
    );
  }

  render() {
    const sortedForms = sortBy(
      this.props.forms,
      f => (f.name ? f.name.toLowerCase() : "")
    );
    return (
      <div>
        {sortedForms.map(form => (
          <div
            key={form.id}
            styleName="container"
            onClick={e => this.onSelect(form.id, e)}
          >
            <label styleName="label">{form.name}</label>
            <Toggle
              toggled={this.state.selected.includes(form.id)}
              style={{ width: 40 }}
            />
          </div>
        ))}
      </div>
    );
  }
}

PortalFormsSelect.propTypes = {
  eventId: PropTypes.number.isRequired,
  getForms: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  forms: PropTypes.object.isRequired,
  value: PropTypes.array
};

function mapStateToProps(state) {
  return {
    forms: FormsSelectors.forms(state),
    eventId: EventSelectors.eventDetails(state).id
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(FormsActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PortalFormsSelect);
