import React from "react";
import { connect } from "react-redux";

import module from "Items/QuestionsDetails";
import rootSaga from "Items/QuestionsDetails/sagas";

import { Popover } from "components/Base";

import Menu from "./Menu";
import { actions, getters } from "Items/QuestionsDetails";
import { getQuestionsToShow } from "Items/QuestionsDetails/selectors";

import { noop } from "utils/General";

module.setRootSaga(rootSaga);

const decorate = connect(
  (state, props) => ({
    modified: getters.modified(state),
    fullQuestions: getters.fullQuestions(state, props),
    clickedOn: getters.clickedOn(state, props),
    questionsToShow: getQuestionsToShow(state, props)
  }),
  {
    getQuestions: actions.questionsRequest,
    onClose: modified => actions.hideMenu(modified)
  }
);

const QuestionDetails = ({
  recordIds,
  getQuestions = noop,
  onClose = noop,
  children,
  modified,
  questionsToShow = [],
  fullQuestions,
  clickedOn,
  ...styleProps
}) => (
  <Popover
    uniqueKey={`${questionsToShow.length}_${fullQuestions}_${clickedOn}`}
    Label={({ onClick }) => (
      <div
        onClick={e => {
          getQuestions(recordIds);
          onClick(e);
        }}
        {...styleProps}
      >
        {children}
      </div>
    )}
    onClose={() => onClose(modified)}
    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
  >
    {({ closePopover }) => (
      <Menu closePopover={closePopover} recordIds={recordIds} />
    )}
  </Popover>
);

export default decorate(QuestionDetails);
