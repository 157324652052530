import React, { useRef } from "react";
import * as R from "ramda";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { actions } from "../model";
import { showEditor } from "../selectors";
import { withProps } from "utils/General";

import { Div, Text2 } from "components/Base";
import { FieldEditor, FieldFormatter } from "ui-kit/FieldTypes/View";
import EditorWrapper from "./EditorWrapper";

const FieldRow = withProps({
  display: "row.flex-start.flex-start",
  mb: 2,
  style: {
    minHeight: 45
  }
})(Div);

const decorate = connect(
  (state, props) => ({
    showEditor: showEditor(state, props)
  }),
  {
    onEdit: actions.onEdit
  }
);

const Field = ({ showEditor, field, data, onEdit }) => {
  const containerEl = useRef();

  return (
    <div ref={containerEl}>
      <FieldRow>
        <Text2 width={0.3} style={{ paddingTop: "10px" }}>
          {R.propOr("", "name", field)}
        </Text2>
        {showEditor ? (
          <EditorWrapper
            anchorEl={containerEl.current}
            fieldId={R.prop("id", field)}
            type={R.prop("type", field)}
          >
            <FieldEditor field={field} data={data} />
          </EditorWrapper>
        ) : (
          <Div
            display="row.flex-start.center"
            width={0.7}
            py={2}
            px={1}
            height={40}
            onClick={e => {
              onEdit(field.id);
              e.stopPropagation();
            }}
            bg={{ default: "white", hover: "neutral0" }}
            bra={1}
          >
            <FieldFormatter field={field} data={data} />
          </Div>
        )}
      </FieldRow>
    </div>
  );
};

Field.propTypes = {
  field: PropTypes.object,
  data: PropTypes.object
};

export default decorate(Field);
