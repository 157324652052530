import * as R from "ramda";
import PropTypes from "prop-types";
import React from "react";
import autobind from "autobind-decorator";
import FormBaseInput from "../FormBaseInput";
import FormInputWrapper from "components/Event/FormsV2/Sections/FormInputWrapper";
import Dropdown from "components/Global-2016/Forms/Dropdown";
import isDropDownRequiredValid from "utils/value-types/validations/dropdown/required";
import isMultipleSelectRequiredValid from "utils/value-types/validations/multiple_select/required";
import { get, isEqual } from "lodash";
import getValue from "utils/value-types/get-value/dropdown";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FormDropdown extends FormBaseInput {
  constructor(props) {
    super(props);

    this.state = {
      value: get(getValue(props.formValues[props.field.id]), "records", []),
      isShown: false
    };
  }

  wrapValue(value) {
    return {
      type: "dropdown",
      value: { records: value }
    };
  }

  @autobind
  async isValid() {
    const errors = [];
    const {
      field: {
        settings: { allowMultipleSelect }
      }
    } = this.props;
    const required = this.props.field.is_required;

    // We need to mock this value as value types expect this schema
    const value = {
      value: {
        records: this.state.value
      }
    };

    if (required) {
      if (allowMultipleSelect && !isMultipleSelectRequiredValid(value)) {
        errors.push("This is a required question");
      } else if (!isDropDownRequiredValid(value)) {
        errors.push("This is a required question");
      }
    }
    this.setState({
      errors
    });
    return !errors.length;
  }

  @autobind
  handleSave() {
    this.save(this.props.field.id, this.wrapValue(this.state.value));
  }

  getSelected(list) {
    if (!this.props.field.settings.allowMultipleSelect) {
      const record = list
        ? this.props.field.settings.options.find(
            ({ value }) => value === list.value
          )
        : list;
      const records = [record];
      return records;
    } else if (list && list.length) {
      const records = list
        ? list.map(({ value: v }) =>
            this.props.field.settings.options.find(({ value }) => value === v)
          )
        : [];
      return records;
    }
    return [];
  }

  @autobind
  handleChange(list) {
    this.setState({
      value: this.getSelected(list)
    });
  }

  @autobind
  handleToogleShowEditMode() {
    this.setState({
      isShown: R.not(this.state.isShown)
    });
  }

  componentWillUpdate(nextProps) {
    if (
      !this.props.isEditing &&
      !isEqual(
        this.props.formValues[this.props.field.id],
        nextProps.formValues[this.props.field.id]
      )
    ) {
      this.setState({
        value: get(
          getValue(nextProps.formValues[this.props.field.id]),
          "records",
          []
        )
      });
    }
  }

  render() {
    let handleSave;
    if (!this.props.isEditing) {
      handleSave = this.handleSave;
    }

    return (
      <FormInputWrapper
        isEditing={this.props.isEditing}
        isValid={this.state.isValid}
        errorMessages={this.state.errors}
        fieldData={this.props.field}
        instanceId={this.props.field.id}
        isShown={this.state.isShown}
        setIsShown={() => this.handleToogleShowEditMode()}
        connectDragSource={this.props.connectDragSource}
      >
        <Dropdown
          disabled={this.props.disabled}
          label={this.props.field.name}
          selected={this.state.value.map(v => v.value)}
          onChange={this.handleChange}
          onBlur={handleSave}
          required={this.props.field.is_required}
          isAdminField={this.props.field.settings.isAdminField}
          options={this.props.field.settings.options.map(o => o.value)}
          isMulti={this.props.field.settings.allowMultipleSelect}
          description={this.props.field.settings.description}
          placeholder={this.props.field.settings.placeholder}
          instanceId={this.props.field.id}
          fieldData={this.props.field}
          isShown={this.state.isShown}
          canShowSimpleSideBar
          readOnly={this.props.field.is_readonly}
          setIsShown={() => this.handleToogleShowEditMode()}
        />
      </FormInputWrapper>
    );
  }
}

FormDropdown.propTypes = {
  formValues: PropTypes.object,
  field: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  updateFormValue: PropTypes.func.isRequired
};

export default FormDropdown;
