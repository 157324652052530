import React from "react";
import * as R from "ramda";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import ScrollableModalBody from "components/Global/Modal/Layout/ScrollableBody";
import DeleteConfirmation from "components/Atoms/DeleteConfirmation";
import { addS } from "utils/General";

import {
  Div,
  DarkTab,
  BigFilledButton,
  BigOutlineButton,
  BigTextButton,
  LoadingIcon,
  FontIcon,
  RightIcon
} from "components/Base";

const Type = ({
  name,
  color,
  icon,
  countOfItems,
  countOfCategories,
  onClick
}) => (
  <Div
    display="row.flex-start.center"
    b={1}
    bc="gray1"
    bra={1}
    py={3}
    px={3}
    mb={2}
    shadow={{ default: 1, hover: 2 }}
    width={1}
    onClick={onClick}
    bg="white"
  >
    <Div
      display="column.center.center"
      width={36}
      height={36}
      bra={2}
      mr={3}
      style={{
        backgroundColor: color,
        flexShrink: 0
      }}
    >
      <FontIcon fs={4} color="white">
        {icon}
      </FontIcon>
    </Div>
    <Div display="column" width={1}>
      <Div fw={3} fs={5} color="gray7">
        {name}
      </Div>
      <Div fs={1} color="gray9">
        {countOfItems} item{addS(countOfItems)} &middot; {countOfCategories}{" "}
        {countOfCategories !== 1 ? "categories" : "category"}
      </Div>
    </Div>
    <Div flex={1} />
    <RightIcon size={40} color="gray3" />
  </Div>
);

const View = ({ loading, hideModal, types }) => (
  <StyleWrapper
    heading="Select items available"
    hideModal={hideModal}
    bodyStyles={{ padding: 0 }}
    width={501}
  >
    <Div>
      <ScrollableModalBody
        style={{
          minHeight: 400,
          display: "flex",
          backgroundColor: "rgba(224, 224, 224, 0.19)"
        }}
      >
        <Div display="column" style={{ width: "100%" }} px={6} py={4} flex={1}>
          <Div uppercase color="gray6" mb={3} fw={3} fs={2}>
            Select the type of items...
          </Div>
          {loading ? (
            <Div py={20} display="row.center.center" flex={1}>
              <LoadingIcon size={60} color="neutral4" />
            </Div>
          ) : (
            types.map(type => <Type key={type.id} {...type} />)
          )}
        </Div>
      </ScrollableModalBody>
    </Div>
  </StyleWrapper>
);

export default View;
