import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { actions } from "Modules/ImportModal";
import thunkMiddleware from "redux-thunk";
import { Provider } from "react-redux";
import importModal from "Modules/ImportModal";
import * as R from "ramda";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import ImportModalSagas from "Modules/ImportModal/sagas";

import Layout from "Modules/ImportModal/View/Layout";

importModal.setRootSaga(ImportModalSagas);

class Modal extends Component {
  static contextTypes = {
    store: PropTypes.shape({
      subscribe: PropTypes.func.isRequired,
      dispatch: PropTypes.func.isRequired,
      getState: PropTypes.func.isRequired
    })
  };

  constructor(props, context) {
    super(props);

    const { store } = importModal.createStore({
      middleware: [thunkMiddleware],
      globalStore: context.store,
      observedDomains: ["user", "event", "organization"],
      dispatchToGlobal: R.compose(
        R.not,
        R.test(new RegExp(importModal.namespace)),
        R.prop("type")
      )
    });
    const moduleId =
      this.props.moduleId ||
      R.propOr(STANDARD_MODULE_IDS.contacts.id, "moduleId")(this.props.params);
    this.store = store;
    this.store.dispatch(actions.setModuleId(moduleId));
    this.store.dispatch(actions.setEventId(R.propOr(0, "eventId")(this.props)));
    this.store.dispatch(
      actions.setAccountId(R.propOr("", "accountId")(this.props))
    );
    this.store.dispatch(
      actions.setRecordTypeId(R.propOr("", "typeId")(this.props))
    );
    this.store.dispatch(
      actions.setMaxAmount(R.propOr(0, "maxAmount")(this.props))
    );
    this.store.dispatch(
      actions.setBlockId(R.propOr("", "blockId")(this.props))
    );
    this.store.dispatch(
      actions.setSubmissionId(R.propOr("", "submissionId")(this.props))
    );
    this.store.dispatch(actions.setOnDoneFn(R.prop("onDone")(this.props)));

    importModal.run();
  }

  componentWillUnmount() {
    this.store.dispatch(actions.cancelInstance());
  }

  render() {
    const handlers = {
      hideModal: this.props.hideModal,
      blockId: R.propOr("", "blockId", this.props),
      submissionId: R.propOr("", "submissionId", this.props),
      eventId: R.propOr(0, "eventId", this.props)
    };
    return (
      <Provider store={this.store}>
        <Layout handlers={handlers} />
      </Provider>
    );
  }
}

export default withRouter(Modal);
