import PropTypes from "prop-types";
import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router";
import Immutable from "immutable";
import { StyleRoot } from "radium"; // @NOTE: This can be removed when we remove radium'
import ErrorBoundary from "react-error-boundary";
import { globalErrorHandler } from "utils/errors";
import { GlobalError } from "components/Global/ErrorViews";

class Root extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired
  };

  componentDidMount() {
    // make Immutable globally available for use with react-data-grid
    window.Immutable = Immutable;
  }

  render() {
    return (
      <StyleRoot style={{ height: "100%" }}>
        <Provider store={this.props.store}>
          <ErrorBoundary
            FallbackComponent={GlobalError}
            onError={globalErrorHandler}
          >
            <Router history={this.props.history}>{this.props.routes}</Router>
          </ErrorBoundary>
        </Provider>
      </StyleRoot>
    );
  }
}
export default Root;
