import { combineReducers } from "redux";
import Api from "utils/EventContacts/WebAPIUtils";
import _ from "lodash";
import { addReferences } from "redux/modules/entityReferences/actions";

// ------------------------------------
// Constants
// ------------------------------------
const INVALIDATE_EVENT_CONTACT_LIST = "INVALIDATE_EVENT_CONTACT_LIST";
const REQUEST_EVENT_CONTACT_LIST = "REQUEST_EVENT_CONTACT_LIST";
const RECEIVE_EVENT_CONTACT_LIST = "RECEIVE_EVENT_CONTACT_LIST";
const REFRESH_CONTACT_LIST_COLUMNS = "REFRESH_CONTACT_LIST_COLUMNS";

const UPDATE_CONTACT_STANDARD_FIELD_VALUE =
  "UPDATE_CONTACT_STANDARD_FIELD_VALUE";
const UPDATE_CONTACT_CUSTOM_FIELD_VALUE = "UPDATE_CONTACT_CUSTOM_FIELD_VALUE";
const UPDATE_CONTACT_CUSTOM_FIELD_VALUES = "UPDATE_CONTACT_CUSTOM_FIELD_VALUES";

// ------------------------------------
// Actions
// ------------------------------------
const fetchList = query => (dispatch, getState) => {
  dispatch({
    type: REQUEST_EVENT_CONTACT_LIST
  });

  return Api.fetchContactList(getState().user.user.credentials, query).then(
    list => {
      dispatch(addReferences(list.references));
      return dispatch({
        type: RECEIVE_EVENT_CONTACT_LIST,
        list
      });
    }
  );
};

const invalidateList = () => ({
  type: INVALIDATE_EVENT_CONTACT_LIST
});

const refreshColumns = (eventId, listId) => (dispatch, getState) =>
  Api.fetchColumns(getState().user.user.credentials, {
    eventId,
    listId
  }).then(result =>
    dispatch({
      type: REFRESH_CONTACT_LIST_COLUMNS,
      columnMeta: result.meta
    })
  );

const updateStandardFieldValue = data => (dispatch, getState) => {
  dispatch({
    type: UPDATE_CONTACT_STANDARD_FIELD_VALUE,
    data
  });
  return Api.updateStandardFieldValue(getState().user.user.credentials, data);
};

const updateFieldValue = data => (dispatch, getState) => {
  dispatch({
    type: UPDATE_CONTACT_CUSTOM_FIELD_VALUE,
    data
  });
  return Api.updateFieldValue(getState().user.user.credentials, data);
};

const updateFieldValues = data => (dispatch, getState) => {
  dispatch({
    type: UPDATE_CONTACT_CUSTOM_FIELD_VALUES,
    data
  });
  return Api.updateFieldValue(getState().user.user.credentials, {
    bulk: true,
    ...data
  });
};

export const actions = {
  fetchList,
  invalidateList,
  refreshColumns,
  updateFieldValue,
  updateFieldValues,
  updateStandardFieldValue
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isFetching: false,
  isImporting: false,
  list: {
    id: 0,
    contacts: [],
    columns: []
  }
};

const data = (state = initialState, action) => {
  switch (action.type) {
    case INVALIDATE_EVENT_CONTACT_LIST:
      return initialState;

    case REQUEST_EVENT_CONTACT_LIST:
      return _.extend({}, state, {
        isFetching: true,
        isImporting: false
      });

    case RECEIVE_EVENT_CONTACT_LIST:
      return _.extend({}, state, {
        isFetching: false,
        isImporting: false,
        list: action.list
      });

    case REFRESH_CONTACT_LIST_COLUMNS:
      return _.assignIn({}, state, {
        isFetching: false,
        list: _.assignIn({}, state.list, action.columnMeta)
      });

    case UPDATE_CONTACT_STANDARD_FIELD_VALUE:
    case UPDATE_CONTACT_CUSTOM_FIELD_VALUE: {
      const idx = _.findIndex(
        state.list.contacts,
        c => c.id === action.data.contactId
      );
      const list = _.cloneDeep(state.list);
      _.update(
        list,
        `contacts[${idx}].values[${action.data.fieldId}]`,
        () => action.data.value
      );
      return _.assignIn({}, state, { list });
    }

    case UPDATE_CONTACT_CUSTOM_FIELD_VALUES: {
      const list = _.cloneDeep(state.list);
      action.data.values.forEach(({ contactId, fieldId, value }) => {
        const idx = _.findIndex(state.list.contacts, c => c.id === contactId);
        _.update(list, `contacts[${idx}].values[${fieldId}]`, () => value);
      });
      return _.assignIn({}, state, { list });
    }

    default:
      return state;
  }
};

const rootReducer = combineReducers({
  data
});

export default rootReducer;
