import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { LoadingIcon } from "components/Base";

const NotConfigured = ({
  configured = false,
  text = "Configured",
  loading = false
}) =>
  loading ? (
    <LoadingIcon color="gray3" size={12} />
  ) : (
    <div styleName="configured" style={!configured ? {} : { color: "#4B8275" }}>
      <span
        class="material-icons"
        style={!configured ? {} : { color: "#4B8275" }}
      >
        {!configured ? "do_disturb" : "task_alt"}
      </span>
      {configured ? text : "Not Configured"}
    </div>
  );

export default CSSModules(css)(NotConfigured);
