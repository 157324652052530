import React from "react";
import { SubNavWrapper, SubNavLink } from "EventLight/Common/SubNav";
import { withRouter } from "react-router";
import * as R from "ramda";
import { connect } from "react-redux";
import { getLinks } from "../selectors";

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    links: getLinks(state, props)
  }))
);

const SubNav = ({ router, links }) => (
  <SubNavWrapper supportId="emails-history">
    {links.map(l => (
      <SubNavLink
        key={l.id}
        name={l.name}
        fs={4}
        style={{
          paddingBottom: "4px",
          height: "32px"
        }}
        active={l.active}
        onClick={() =>
          router.push({
            pathname: l.path
          })
        }
      />
    ))}
  </SubNavWrapper>
);

export default decorate(SubNav);
