import * as R from "ramda";
import React from "react";

import {
  DOCUMENTS_FORM_ID,
  DOCUMENTS_FIELDS
} from "Sponsors/SelfSetup/constants";

import { WithThemeConsumer, defaultTh, os, VARIANTS } from "ui-kit/Theme";
import {
  WithFormInstanceProvider,
  Column,
  Label,
  TextAreaInput,
  Input
} from "ui-kit/Form/View";

import { Div } from "components/Base";
import { PageTitle } from "Sponsors/Common/View";

const decorate = R.compose(
  WithThemeConsumer({ variant: VARIANTS.BACKGROUND }),
  WithFormInstanceProvider(R.always(DOCUMENTS_FORM_ID))
);

const DocumentsPage = ({ Th = defaultTh, ...styleProps }) => (
  <Div display="column" {...os(styleProps)}>
    <PageTitle>Documents, Forms & Message</PageTitle>
    <Column {...Th(["bg"], { variant: Th.VARIANTS.SURFACE, p: 3, mt: 3 })}>
      <Column>
        <Label mb={2}>Header of message:</Label>
        <Input fieldId={DOCUMENTS_FIELDS.HEADER} />
      </Column>
      <Column mt={3}>
        <Label mb={2}>Body of message:</Label>
        <TextAreaInput fieldId={DOCUMENTS_FIELDS.BODY} />
      </Column>
    </Column>
  </Div>
);

export default decorate(DocumentsPage);
