import { call, put, fork, all, select, takeEvery } from "redux-saga/effects";
import * as R from "ramda";
import { actions as ActivityLogsActions } from "Event/ActivityLogs/model";
import { getSearchAndFilters } from "Event/ActivityLogs/selectors";

import { getters, actions } from "./model";

import { registerError } from "redux/modules/errors/actions";
import { getCredentials } from "redux/modules/user/selectors";
import Api from "./api";

const search = function*(action) {
  yield put(ActivityLogsActions.setLoading(true));
  try {
    const credentials = yield select(getCredentials);
    const eventId = yield select(getters.eventId);
    const orgId = yield select(getters.orgId);
    const accountId = yield select(getters.accountId);
    const contactId = yield select(getters.contactId);
    let currentPage = yield select(getters.currentPage);
    const isEventSection = !R.isNil(eventId);
    const from = isEventSection ? "event" : "organization";
    const sectionId = isEventSection ? eventId : orgId;
    const profileApi = accountId
      ? "getAccountActivityLogs"
      : "getContactActivityLogs";
    const profileId = accountId ? accountId : contactId;
    const searchAndFilters = yield select(getSearchAndFilters);
    if (
      action &&
      [
        ActivityLogsActions.toggleFilter.type,
        ActivityLogsActions.search.type
      ].includes(action.type)
    ) {
      yield put(ActivityLogsActions.setDays([]));
      currentPage = -1;
    }
    const queryParams = {
      ...searchAndFilters,
      page: currentPage + 1
    };
    const {
      payload: { count_of_pages, current_page, ...data }
    } = yield call(
      Api[profileApi],
      from,
      credentials,
      sectionId,
      profileId,
      queryParams
    );
    const canLoadMore = current_page < count_of_pages;
    yield put(actions.setPages({ count_of_pages, current_page }));
    yield put(
      ActivityLogsActions.setInitialData({
        ...data,
        canLoadMore,
        isEventSection
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred loading activity logs"
        }
      ])
    );
  } finally {
    yield put(ActivityLogsActions.setLoading(false));
  }
};

const init = function*() {
  yield put(actions.setPages({ count_of_pages: 0, current_page: 0 }));
  yield put(ActivityLogsActions.setDays([]));
  yield fork(search);
};

const loadMore = function*() {
  const currentPage = yield select(getters.currentPage);
  const countOfPages = yield select(getters.countOfPages);
  if (countOfPages > currentPage) {
    yield put(ActivityLogsActions.setLoadingMessage(true));
    yield fork(search);
    yield put(ActivityLogsActions.setLoadingMessage(false));
  }
};

const watchLoadMore = function*() {
  yield takeEvery(ActivityLogsActions.loadMoreData.type, loadMore);
};

const watchSearch = function*() {
  yield takeEvery(
    [ActivityLogsActions.toggleFilter.type, ActivityLogsActions.search.type],
    search
  );
};

const watchIniState = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([fork(watchIniState), fork(watchLoadMore), fork(watchSearch)]);
};

export default rootSaga;
