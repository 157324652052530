import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  currentPage: 0,
  countOfPages: 0
};

const model = createHandlers({
  iniState,
  reducers: {
    setPages: (_, { payload: { count_of_pages, current_page } }) => ({
      currentPage: current_page,
      countOfPages: count_of_pages
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
