import { createHandlers } from "redux-mvc";
import * as R from "ramda";

import { NAMESPACE } from "Submission/Editor/constants";

const iniState = {
  id: "",
  form_id: "",
  parent_submission_id: null,
  is_default: false,
  is_submitted: true,
  is_confirmation_sent: null,
  is_locked: true,
  is_deleted: null,
  submission_record_id: "",
  created_at: "",
  updated_at: "",
  values: {},
  related_order: {
    customer: {},
    line_items: [],
    approval_status: "",
    fulfillment_status: "",
    integrations: []
  },
  collaborators: [],
  people_blocks: {},
  preferences: {},
  review: {},
  comments: [],
  module_record: {
    module: {},
    lookup_modules: [],
    related_modules: [],
    record: {},
    references: {},
    preferences: {},
    fields: {}
  },
  form: {
    id: "",
    org_id: "",
    event_id: "",
    type: "",
    scope: null,
    name: "",
    title: "",
    slug: "",
    internal_description: "",
    description: "",
    background_image_url: null,
    is_locked: true,
    show_share_link: false,
    lock_on_submission: false,
    require_login: true,
    close_date: "",
    custom_close_date: "",
    is_subform: false,
    base_module_id: "",
    form_record_id: "",
    confirmation_message: null,
    is_deleted: null,
    created_at: "",
    updated_at: "",
    fields: [],
    form_users: [],
    preferences: {},
    fields_grouped_by_section: [],
    colaborator: {
      fields: []
    },
    event: {}
  },
  event: {
    id: 0,
    uuid: "",
    name: "",
    slug: "",
    logo_image_url: "",
    background_image_url: "",
    date_from: "",
    date_to: "",
    date_groups: [],
    modules: {},
    module_settings: {
      credentials: {},
      catering: {}
    }
  },
  fetching: false,
  saving: false,
  updatePeopleTable: true,
  error: {}
};

const handlers = createHandlers({
  iniState,
  reducers: {
    fetch: R.always({ fetching: true }),
    response: (_, { payload }) => ({
      ...payload
    }),
    createValue: R.always({ saving: true }),
    createComplete: (state, { payload: { value } }) => ({
      saving: false,
      values: {
        ...state.values,
        [value.field_id]: value.value
      }
    }),
    createCustomerBlockValueComplete: (
      state,
      { payload: { accountValues, contactValues } }
    ) => ({
      saving: false,
      related_order: {
        ...state.related_order,
        customer: {
          ...state.related_order.customer,
          account_values: accountValues,
          contact_values: contactValues
        }
      }
    }),
    createFulfillmentBlockValueComplete: (state, { payload }) => ({
      saving: false,
      related_order: {
        ...state.related_order,
        ...payload
      }
    }),
    createReview: (state, action) => ({
      review:
        action.payload.submissionId === state.id
          ? {
              status: action.payload.status
            }
          : state.review,
      values: {
        ...state.values,
        // if handling a subform review, process update
        [action.payload.subformId]: action.payload.subformId
          ? {
              ...state.values[action.payload.subformId],
              value: {
                ...state.values[action.payload.subformId].value,
                submissions: {
                  ...state.values[action.payload.subformId].value.submissions,
                  [action.payload.submissionId]: {
                    ...state.values[action.payload.subformId].value.submissions[
                      action.payload.submissionId
                    ],
                    review: {
                      status: action.payload.status
                    }
                  }
                }
              }
            }
          : undefined
      }
    }),
    addValue: R.identity,
    addCustomerBlockValue: R.identity,
    addOrderDetailsBlockValue: R.identity,
    addFulfillmentBlockValue: R.identity,
    updateSubmission: R.identity
  },
  namespace: NAMESPACE
});

export default handlers;
