import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import getValue from "utils/value-types/get-value/phone";
import MaskedInput from "react-text-mask";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FormPhoneMask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.formatValue(props.value)
    };
  }

  componentWillUpdate(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        value: this.formatValue(nextProps.value)
      });
    }
  }

  formatValue(value) {
    const formattedValue = getValue(value);
    return formattedValue ? formattedValue.replace(/^\+1/, "").trim() : "";
  }

  wrapValue(value) {
    return {
      type: "phone",
      value: value ? `+1 ${value}` : undefined
    };
  }

  @autobind
  onChange(e) {
    this.setState({
      value: e.target.value
    });
    if (this.props.continuous) {
      this.props.onChange(this.wrapValue(e.target.value));
    }
  }

  @autobind
  onBlur() {
    this.props.onChange(this.wrapValue(this.state.value));
  }

  render() {
    const { disabled, placeholder, required, autocomplete } = this.props;

    return (
      <MaskedInput
        autocomplete={autocomplete}
        disabled={disabled}
        required={required}
        value={this.state.value}
        placeholder={placeholder || "(123) 456-7890"}
        onChange={this.onChange}
        onBlur={this.onBlur}
        placeholderChar={"\u2000"}
        mask={[
          "(",
          /\d/,
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/
        ]}
        styleName="input"
        type="tel"
      />
    );
  }
}

FormPhoneMask.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.object
};

export default FormPhoneMask;
