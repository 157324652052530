import React from "react";
import { connect } from "react-redux";
import { noop } from "utils/General";

import { actions as SelectActions } from "ui-kit/SelectRows";

import { getIsRowSelected } from "ui-kit/SelectRows/selectors";

import { Div, Text4 } from "components/Base";

import CollapsablePanel from "ui-kit/CollapsiblePanel";
import PanelInfo from "./PanelInfo";

const instanceId = "CollapsedPanels";

const decorate = connect(
  (state, props) => ({
    opened: !getIsRowSelected(state, { ...props, instanceId })
  }),
  {
    toggleCollapsed: id => SelectActions.toggleRow(id, { meta: { instanceId } })
  }
);

const Panel = ({
  title,
  id,
  color,
  group,
  opened = true,
  toggleCollapsed = noop,
  handlers,
  children,
  showAdd,
  isAdminView,
  instanceId
}) => (
  <Div mb={4}>
    <CollapsablePanel
      bg="white"
      right={false}
      pt={2}
      px={2}
      bt={opened ? 3 : 0}
      bl={opened ? 0 : 3}
      bra={opened ? 0 : 1}
      bc={color}
      opened={opened}
      arrowColor="gray7"
      onClick={() => toggleCollapsed(id)}
      style={{
        overflow: "visible"
      }}
      heading={
        <Div display="row.space-between.center" flex={1} height={50}>
          <Text4 bold color="gray7" ml={1}>
            {title}
          </Text4>
          <PanelInfo
            typeId={id}
            handlers={handlers}
            showAdd={showAdd}
            isAdminView={isAdminView}
            group={group}
            instanceId={instanceId}
          />
        </Div>
      }
    >
      {children}
    </CollapsablePanel>
  </Div>
);

export default decorate(Panel);
