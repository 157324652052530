import React, { Fragment } from "react";
import { Link } from "react-router";
import DocumentTitle from "components/Global/DocumentTitle";
import CSSModules from "react-css-modules";
import css from "../shared/styles.scss";
import { SmallCheckbox } from "components/Base";

const View = CSSModules(
  ({
    onSubmit,
    onFieldChange,
    onToggleCheckbox,
    user,
    isUserLoggedIn,
    company,
    fname,
    lname,
    email,
    error,
    emailExists,
    existingUserRequiresVerification,
    processing,
    agreeToTerms,
    receiveUpdates
  }) => (
    <DocumentTitle title="Sign up to get started">
      <div styleName="container">
        {/* logo */}
        <div styleName="logoWrapper">
          <img
            src="https://d2dks4tzxs6xee.cloudfront.net/img/Lennd-white-login.png"
            width="154px"
          />
        </div>

        <div styleName="content">
          <div styleName="wrapper">
            <div styleName="left">
              {/* form */}
              <form onSubmit={onSubmit}>
                <div>
                  {isUserLoggedIn ? (
                    <div styleName="title">Add a new organization</div>
                  ) : (
                    <>
                      <div styleName="title">Sign up to get started</div>
                      <div styleName="signin">
                        Already registered? <a href="/login">Sign in</a>
                      </div>
                    </>
                  )}

                  {/* field: company */}
                  <div styleName="inputWrapper">
                    <input
                      className="styled"
                      type="text"
                      name="company"
                      placeholder="Company Name"
                      required
                      autoFocus
                      onChange={onFieldChange}
                      value={company}
                      data-cy="org-company-name-input"
                    />
                  </div>

                  {/* field: name */}
                  {!isUserLoggedIn ? (
                    <Fragment>
                      <div styleName="inputCombo">
                        <div styleName="inputWrapper">
                          <input
                            className="styled"
                            type="text"
                            name="fname"
                            placeholder="First name"
                            required
                            onChange={onFieldChange}
                            value={fname}
                            data-cy="signup-first-name-input"
                          />
                        </div>
                        <div styleName="inputWrapper">
                          <input
                            className="styled"
                            type="text"
                            name="lname"
                            placeholder="Last name"
                            required
                            onChange={onFieldChange}
                            value={lname}
                            data-cy="signup-last-name-input"
                          />
                        </div>
                      </div>

                      {/* field: email */}
                      <div styleName="inputWrapper">
                        <input
                          className="styled"
                          type="email"
                          name="email"
                          placeholder="Email address"
                          required
                          onChange={onFieldChange}
                          value={email}
                          data-cy="signup-email-input"
                        />
                      </div>
                    </Fragment>
                  ) : (
                    <div styleName="logged-in">
                      You're logged in as {user.fname} {user.lname} and will be
                      added to your new organization.
                    </div>
                  )}

                  {/* message */}
                  {emailExists &&
                    (existingUserRequiresVerification ? (
                      <div styleName="message">
                        The email address you entered is already in use. To
                        complete the signup process, please check your email or{" "}
                        <Link
                          to="/signup/resend"
                          styleName="resendConfirmation"
                        >
                          re-send the confirmation message
                        </Link>
                        .
                      </div>
                    ) : (
                      <div styleName="message">
                        The email address you entered is already in use. If you
                        have an account, please{" "}
                        <Link to="/login" styleName="loginLink">
                          login with your existing account
                        </Link>
                        .
                      </div>
                    ))}
                  {!isUserLoggedIn ? (
                    <>
                      <div
                        styleName="checkboxWrapper"
                        onClick={() =>
                          onToggleCheckbox("agreeToTerms", !agreeToTerms)
                        }
                      >
                        <SmallCheckbox
                          mr={1}
                          selected={agreeToTerms}
                          onClick={() => {}}
                          data-cy="signup-agree-to-terms-checkbox"
                        />
                        <div styleName="checkboxLabel">
                          I agree to Lennd's{" "}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.lennd.com/legal/terms-of-service"
                          >
                            Terms of Service
                          </a>{" "}
                          and{" "}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.lennd.com/legal/privacy-policy"
                          >
                            Privacy Policy
                          </a>
                          . <span styleName="required">(Required)</span>
                        </div>
                      </div>

                      <div
                        styleName="checkboxWrapper"
                        onClick={() =>
                          onToggleCheckbox("receiveUpdates", !receiveUpdates)
                        }
                      >
                        <SmallCheckbox
                          mr={1}
                          selected={receiveUpdates}
                          onClick={() => {}}
                          data-cy="signup-agree-to-updates-checkbox"
                        />
                        <div styleName="checkboxLabel">
                          I agree to receive product updates and marketing
                          communications from Lennd
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                {!emailExists && error && (
                  <div styleName="message">{error}</div>
                )}

                {/* button */}
                {processing ? (
                  <button styleName="buttonWrapper">
                    <span styleName="button">
                      Creating your organization...
                    </span>
                  </button>
                ) : (
                  <button
                    styleName="buttonWrapper"
                    type="submit"
                    data-cy="org-create-organization-button"
                  >
                    <span styleName="button">
                      {isUserLoggedIn ? "Create My Organization" : "Sign up"}
                    </span>
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </DocumentTitle>
  ),
  css
);

export default View;
