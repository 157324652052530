import Container from "components/Event/InventoryV2";
import NeedsByStatus from "components/Event/InventoryV2/Needs/ByStatus";
import NeedsOverview from "components/Event/InventoryV2/Needs/Overview";
import Home from "components/Event/InventoryV2/Home";
import Catalog from "components/Event/InventoryV2/Catalog";
import Manage from "Inventory/Manage/View";
import ManageContainer from "Inventory/Main/View";
import Dashboard from "Inventory/Dashboard/View";

import { hot } from "App/Config/hot";

const Inventory = {
  Container,
  NeedsByStatus,
  NeedsOverview,
  Home,
  Catalog,
  Manage,
  ManageContainer,
  Dashboard
};

export default hot(Inventory);
