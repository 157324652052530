import React from "react";
import { connect } from "react-redux";

import { getters } from "../model";

import Body from "./Body";

const decorate = connect((state, props) => ({
  showModal: getters.showModal(state, props)
}));

const Modal = ({
  showModal = false,
  accept = ["image/*", "video/*", "application/*"],
  instanceId
}) => {
  if (!showModal) return null;

  return <Body instanceId={instanceId} accept={accept} />;
};

export default decorate(Modal);
