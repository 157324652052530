import React from "react";
import * as R from "ramda";

import { connect } from "react-redux";
import { WithFormInstanceProvider } from "ui-kit/Form/View";
import { Div, BigFilledButton } from "components/Base";
import { Label, Divider } from "EventLight/Common/FieldLayout";

import { FORM_ID, FIELD_IDS } from "../constants";
import { actions, getters } from "../model";
import { getIsValid } from "../selectors";

import { Input, Image } from "ui-kit/Form/View";

const decorate = R.compose(
  WithFormInstanceProvider(R.always(FORM_ID)),
  connect(
    (state, props) => ({
      data: getters.data(state),
      isValid: getIsValid(state, props)
    }),
    {
      save: actions.save,
      updateField: actions.updateField
    }
  )
);

const Body = ({ data, isValid, save }) => (
  <Div width={1}>
    <Div display="column" mb={5} width={1}>
      <Label>Organization Name</Label>
      <Input
        fieldId={FIELD_IDS.NAME}
        width={1}
        iniValue={R.prop(FIELD_IDS.NAME)(data)}
        size="big"
        inputType="outline"
      />
    </Div>

    <Div display="column" mb={5} width={1}>
      <Label tooltip="This is used in the URL that people will visit">
        Organization Slug
      </Label>
      <Input
        fieldId={FIELD_IDS.SLUG}
        width={1}
        iniValue={R.prop(FIELD_IDS.SLUG)(data)}
        size="big"
        inputType="outline"
      />
    </Div>

    <Div display="row.flex-start.flex-start" mb={5}>
      <Div display="column" flex={1}>
        <Label>Logo Image</Label>
        <Image
          fieldId={FIELD_IDS.LOGO_IMAGE_URL}
          containerWidth="auto"
          containerProps={{
            display: "row.flex-start.flex-start",
            flex: 1
          }}
          imageWidth={120}
          imageHeight={120}
          recommendedWidth={"300"}
          recommendedHeight={"300"}
          iniValue={
            R.prop(FIELD_IDS.LOGO_IMAGE_URL, data)
              ? [
                  {
                    url: R.prop(FIELD_IDS.LOGO_IMAGE_URL, data)
                  }
                ]
              : null
          }
        />
      </Div>

      <Div display="column" flex={1} ml={5}>
        <Label>Background Image</Label>
        <Image
          fieldId={FIELD_IDS.BACKGROUND_IMAGE_URL}
          containerWidth="auto"
          containerProps={{
            display: "row.flex-start.flex-start",
            flex: 1
          }}
          imageWidth={198}
          imageHeight={57}
          recommendedWidth={"1500"}
          recommendedHeight={"500"}
          iniValue={
            R.prop(FIELD_IDS.BACKGROUND_IMAGE_URL, data)
              ? [
                  {
                    url: R.prop(FIELD_IDS.BACKGROUND_IMAGE_URL, data)
                  }
                ]
              : null
          }
        />
      </Div>
    </Div>

    <Divider />

    <BigFilledButton
      bg="altB5"
      color="white"
      disabled={!isValid}
      onClick={save}
      mt={3}
    >
      Save Details
    </BigFilledButton>
  </Div>
);

export default decorate(Body);
