import React, { Component } from "react";
import * as R from "ramda";
import View from "./View";
import { oxfordJoin, withState, isEmptyOrNil } from "utils/General";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import WorkflowModal from "components/Event/Settings/ApprovalWorkflows/Workflow/index";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import EditCollaboratorInfoModal from "components/Global/Modals/EditCollaboratorInfoModal";
import AddEventTeamUserModal from "components/Global/Modals/AddEventTeamMemberModal";

const escapeRegex = str => str.replace(/([.?*+^$[\]\\(){}|-])/g, "\\$1");
const filterValues = searchTerm => inputValues => {
  if (isEmptyOrNil(searchTerm)) {
    return inputValues;
  }
  const myRegex = new RegExp(escapeRegex(R.toLower(searchTerm)), "g");
  const newValues = R.filter(
    el => R.test(myRegex, R.toLower(R.join(" ", R.values(el)))),
    inputValues
  );
  return newValues;
};

class Controller extends Component {
  state = { loading: true };

  async componentDidMount() {
    const space = await this.getSpace();
    await Promise.all([
      this.fetchUsers(),
      this.getDefaultWorkflow(space[0].id)
    ]);
    this.setState({ loading: false });
  }

  fetchUsers = async () => {
    return await Promise.all([
      this.props.getUsers(this.props.eventDetails.id),
      this.props.getModuleManagers(STANDARD_MODULES.credentials.id)
    ]);
  };

  showEditInfoModal = record => {
    const modal = (
      <EditCollaboratorInfoModal
        hideModal={this.props.hideModal}
        userId={record.user_id}
        onUpdated={this.fetchUsers}
        showPermissions
      />
    );

    this.props.showModal({ content: modal });
  };

  showAddEventTeamUserModal = () => {
    const modal = (
      <AddEventTeamUserModal
        hideModal={this.props.hideModal}
        addToEventId={this.props.eventDetails.id}
        onAdded={this.fetchUsers}
      />
    );

    this.props.showModal({ content: modal });
  };

  getDefaultWorkflow = spaceId =>
    this.props.getWorkflow({
      spaceId,
      workflowId: "default"
    });

  getSpace = () =>
    this.props.getSpace({
      eventId: this.props.eventDetails.id,
      key: this.props.spaceKey,
      type: this.props.spaceType,
      moduleId: this.props.params.moduleId
    });

  updateSpace = data => {
    return this.props.updateSpace({
      eventId: this.props.eventDetails.id,
      type: this.props.spaceType,
      moduleId: this.props.params.moduleId,
      ...data
    });
  };

  showWorkflowModal = workflowId => {
    this.props.showModal({
      content: (
        <WorkflowModal
          spaceKey={this.props.spaceKey}
          spaceId={this.props.space.id}
          workflowId={workflowId}
          getSpace={this.getSpace}
          toggleDefaultIsAny={this.toggleDefaultIsAny}
          onDone={() => {}}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  reorderWorkflows = newOrder => {
    return this.props
      .updateWorkflows({
        spaceId: this.props.space.id,
        workflows: newOrder.map(({ id }, i) => ({ id, order: i + 1 }))
      })
      .then(this.getSpace);
  };

  addWorkflow = () => {
    return this.props
      .addWorkflow({
        spaceId: this.props.space.id,
        name: "Custom Workflow",
        order: R.length(this.props.space.workflows) + 1 || 1
      })
      .then(this.getSpace);
  };

  toggleWorkflows = () => {
    if (this.props.space.is_enabled) {
      return this.deactivateWorkflows();
    }
    return this.activateWorkflows();
  };

  activateWorkflows = async () => {
    await this.updateSpace({
      isEnabled: true
    });

    return this.getSpace();
  };

  deactivateWorkflows = async () => {
    await this.updateSpace({
      isEnabled: false
    });

    return this.getSpace();
  };

  toggleDefaultIsAny = async isAny => {
    await this.updateSpace({
      isAny
    });

    return this.getSpace();
  };

  updateWorkflowActive = (newState, workflowId) => {
    return this.props
      .updateWorkflow({
        spaceId: this.props.space.id,
        workflow: {
          id: workflowId,
          isEnabled: newState
        }
      })
      .then(this.getSpace);
  };

  deleteWorkflow = workflowId => {
    return this.props
      .deleteWorkflow({
        spaceId: this.props.space.id,
        workflowId
      })
      .then(this.getSpace);
  };

  goToView = view => {
    this.props.router.push({
      pathname: `/event/${this.props.eventDetails.id}/passes${view}`
    });
  };

  toggleApprover = async userId => {
    let approvers = R.map(R.prop("user_id"))(this.getDefaultApprovers());

    if (this.isApprover(userId)) {
      approvers = R.without([userId], approvers);
    } else {
      approvers = [...approvers, userId];
    }
    await this.props.updateDefaultApprovers({
      approvers: approvers,
      spaceId: this.props.space.id
    });
    return this.getDefaultWorkflow(this.props.space.id);
  };

  getDefaultApprovers = () =>
    R.pathOr([], ["blocks", 0, "approvers"])(this.props.defaultWorkflow);

  isApprover = userId =>
    R.any(a => a.user_id === userId)(this.getDefaultApprovers());

  render() {
    // Get active route: Either basic or advanced view of approvers
    const activeRoute = this.props.routes.some(
      r => r.name === "PassesSetupApproversAdvanced"
    )
      ? "PassesSetupApproversAdvanced"
      : "PassesSetupApprovers";

    const tabs = [
      {
        id: "basic",
        name: "Managers",
        isActive: activeRoute === "PassesSetupApprovers",
        onClick:
          activeRoute === "PassesSetupApprovers"
            ? undefined
            : () => this.goToView("/setup/approvers")
      },
      {
        id: "advanced",
        name: "Advanced Approvals (beta)",
        isActive: activeRoute === "PassesSetupApproversAdvanced",
        onClick:
          activeRoute === "PassesSetupApproversAdvanced"
            ? undefined
            : () => this.goToView("/setup/approvers/advanced")
      }
    ];

    const managerData = R.compose(
      R.sortBy(R.prop("name")),
      filterValues(this.props.searchTerm),
      R.map(user => ({
        id: user.id,
        name: [user.first_name, user.last_name].filter(v => v).join(" "),
        email: user.email,
        photoUrl: user.photo_url,
        permissionTitle: user.permission_role,
        role: user.role,
        canApprove: this.isApprover(user.id),
        onToggleApprover: () => this.toggleApprover(user.id),
        onEdit: () =>
          this.showEditInfoModal({
            is_event_user: true,
            user_id: user.id,
            role: user.role,
            user_fname: user.first_name,
            user_lname: user.last_name,
            user_email: user.email,
            permission_role: user.permission_role_id
          })
      }))
    )(this.props.managers);

    const managerIds = R.map(R.prop("id"))(managerData);

    const userData = R.compose(
      R.sortBy(R.prop("name")),
      filterValues(this.props.searchTerm),
      R.map(user => ({
        id: user.user_id,
        name: [user.user_fname, user.user_lname].filter(v => v).join(" "),
        email: user.user_email,
        photoUrl: user.user_photo_url,
        permissionTitle: user.permission_role_name,
        role: user.role,
        onEdit: () => this.showEditInfoModal(user)
      })),
      R.filter(u => !managerIds.includes(u.user_id))
    )(this.props.users);

    return (
      <View
        {...{
          tabs,
          searchTerm: this.props.searchTerm,
          onSearch: this.props.setSearch,
          activeRoute,
          managerData,
          managerRoles: oxfordJoin(this.props.managerRoles.map(m => m.name)),
          userData,
          onInviteUser: this.showAddEventTeamUserModal,
          eventId: this.props.eventDetails.id,
          workflowsAreActive: this.props.space.is_enabled,
          workflows: R.map(workflow => {
            const hasTrigger = !isEmptyOrNil(workflow.triggers);
            const hasApprover = !isEmptyOrNil(workflow.blocks);
            const hasTriggerAndApproval = hasApprover && hasTrigger;

            return {
              ...workflow,
              active: workflow.is_enabled,
              hasTriggerAndApproval,
              openWorkflow: () => this.showWorkflowModal(workflow.id),
              duplicateWorkflow: () => this.duplicateWorkflow(workflow),
              deleteWorkflow: () => this.deleteWorkflow(workflow.id),
              toggleWorkflow: () =>
                hasTriggerAndApproval
                  ? this.updateWorkflowActive(!workflow.is_enabled, workflow.id)
                  : () => {}
            };
          })(this.props.space.workflows || []),
          defaultWorkflowSelected: false,
          toggleWorkflows: this.toggleWorkflows,
          openDefaultWorkflow: () => this.showWorkflowModal("default"),
          addWorkflow: this.addWorkflow,
          reorderWorkflows: this.reorderWorkflows
        }}
      />
    );
  }
}

export default withState("searchTerm", "setSearch", "")(Controller);
