import PropTypes from "prop-types";
import React, { Component } from "react";
import { get } from "lodash";
import PoweredbyLennd from "../PoweredbyLennd";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { Span, Text1, Div } from "components/Base";
import AccountsList from "./AccountsList";

@CSSModules(css)
class PortalSwitch extends Component {
  state = {
    error: null
  };

  async componentDidMount() {
    const portalUserResult = await this.props.getPortalUser({
      userId: this.props.user.id,
      eventId: this.props.params.portalId
    });

    if (!portalUserResult) {
      this.setState({ error: "You do not have access to this event portal." });
    }
  }

  updateActiveView(activeViewId) {
    this.props
      .updatePortalUser({
        eventId: this.props.portal.event.id,
        userId: this.props.portalUser.user_id,
        activeView: activeViewId
      })
      .then(() => {
        window.location = `/portals/${this.props.portal.event.slug}/${this.props.portal.event.uuid}`;
      });
  }

  render() {
    const { portal, portalUser, accounts } = this.props;
    const name = portalUser.user_fname
      ? `${portalUser.user_fname} ${portalUser.user_lname}`
      : portalUser.user_email;
    const accountsList = accounts.map(account => ({
      id: account.id,
      name: account.name,
      typeName: get(account, "type.name", ""),
      typeColor: get(account, "type.text_color", ""),
      backgroundColor: get(account, "type.background_color", ""),
      icon: get(account, "type.icon"),
      onClick: () => this.updateActiveView(account.id)
    }));
    return (
      <div styleName="container">
        <div
          styleName="background"
          style={{ backgroundImage: `url('${portal.background_image_url}')` }}
        />
        <div styleName="panel">
          <div styleName="brandingWrapper">
            <div styleName="branding">
              <img
                styleName="brandImage"
                src={portal.event.logo_image_url}
                alt=""
              />
            </div>
          </div>
          <div styleName="list">
            {this.state.error ? (
              <Div color="gray6" fs={3} py={3}>
                You do not have access this event portal. If you need access,
                please contact your event point of contact.
              </Div>
            ) : (
              <>
                <div styleName="heading">Continue as:</div>
                <AccountsList
                  accounts={accountsList}
                  onUserClick={() => this.updateActiveView(portalUser.user_id)}
                  name={name}
                  includeUser={portal.event.enable_individual_portal}
                  userName={[
                    this.props.portalUser.user_fname,
                    this.props.portalUser.user_lname
                  ]
                    .filter(v => v)
                    .join(" ")}
                />
              </>
            )}

            <Div display="column" mt={4} pt={4} bt={1} color="gray3">
              <Div fs={2} color="gray6">
                Logged in as {name}{" "}
              </Div>
              <a
                href={`/logout?returnTo=/portal-login/${portal.event.slug}/${this.props.params.portalId}`}
              >
                <Span
                  color={{ default: "gray5", hover: "gray3" }}
                  underline
                  transition="fast"
                  fs={2}
                >
                  Logout
                </Span>
              </a>
            </Div>
          </div>
        </div>
        <PoweredbyLennd />
      </div>
    );
  }
}

PortalSwitch.defaultProps = {
  accounts: []
};

PortalSwitch.propTypes = {
  portalUser: PropTypes.shape({
    id: PropTypes.string,
    user_id: PropTypes.number,
    user_fname: PropTypes.string,
    user_lname: PropTypes.string
  }).isRequired,
  updatePortalUser: PropTypes.func.isRequired,
  accounts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.shape({
        name: PropTypes.string,
        icon: PropTypes.string,
        color: PropTypes.string
      })
    })
  ).isRequired,
  portal: PropTypes.shape({
    background_image_url: PropTypes.string.isRequired,
    event: PropTypes.shape({
      id: PropTypes.number,
      slug: PropTypes.string,
      uuid: PropTypes.string,
      logo_image_url: PropTypes.string
    })
  }).isRequired,
  getPortalUser: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  params: PropTypes.shape({
    portalId: PropTypes.string
  }).isRequired
};

export default PortalSwitch;
