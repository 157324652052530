import React from "react";
import { Div } from "components/Base";

const Html = ({ content }) => (
  <Div>
    <span
      dangerouslySetInnerHTML={{
        __html: content
      }}
    />
  </Div>
);

export default Html;
