import React from "react";
import { withProps } from "utils/General";
import {
  MediumOutlineButton,
  BigFilledButton,
  BigOutlineButton,
  BigShadedButton,
  PopoverMenu,
  Text2,
  Text6,
  SendIcon,
  AddIcon,
  DataTable,
  LoadingIcon,
  Div
} from "components/Base";
import { Title, Breadcrumbs } from "Passes/Common";
import DefaultEmptyState from "components/Global/OrderReports/EmptyState";
import ApproversLabel from "components/Global/Approvals/ApproversLabel";
import { parseApproval } from "components/Global/Approvals/utils/approvals-helpers";

const Header = withProps({ py: 3 })(Div);
const COLUMNS = [
  {
    Header: ({ column: { isAllSelected, onSelectAll } }) => (
      <Div pl={2}>
        <input type="checkbox" checked={isAllSelected} onClick={onSelectAll} />
      </Div>
    ),
    accessor: "checked",
    width: 50,
    sortable: false,
    Cell: ({ value, original }) => (
      <Div pl={2}>
        <input type="checkbox" checked={value} onClick={original.onSelect} />
      </Div>
    )
  },
  {
    Header: <Header>Status</Header>,
    accessor: "approval",
    Cell: ({ value, original }) => (
      <ApproversLabel
        {...{
          ...parseApproval({
            record: {
              approval_manifest: value
            },
            userId: original.meta.userId
          }),
          approveLineItems: original.meta.approveRecord,
          rejectLineItems: original.meta.rejectRecord,
          removeApproval: original.meta.removeReview,
          showMenu: true
        }}
      />
    )
  },
  {
    Header: <Header>Group Name</Header>,
    accessor: "name",
    Cell: ({ value, original }) => (
      <Text2 bold onClick={original.onClick}>
        {value}
      </Text2>
    )
  },
  {
    Header: <Header>Submitted At</Header>,
    accessor: "submittedAt"
  },
  {
    Header: "",
    accessor: "actions",
    sortable: false,
    Cell: ({
      original: {
        onSendEmail,
        onCopyShareLink,
        onViewGroupProfile,
        onViewGuestList
      }
    }) => (
      <PopoverMenu
        Label={({ onClick }) => (
          <MediumOutlineButton onClick={onClick}>Actions</MediumOutlineButton>
        )}
        menuItems={[
          ["Send Email", onSendEmail],
          ["Copy Share Link", onCopyShareLink],
          ["Open Group Profile", onViewGroupProfile],
          ["Go to Guest List", onViewGuestList]
        ]}
      />
    )
  }
];

const View = ({
  onSelectAll,
  onAddGroup,
  onInviteGroups,
  recordTypeId,
  tableRows,
  loading,
  isAllSelected,
  formName,
  onEditForm,
  onSubmitForm
}) => {
  COLUMNS[0].onSelectAll = onSelectAll;
  COLUMNS[0].isAllSelected = isAllSelected;
  return (
    <Div>
      <Div
        bb={1}
        bc={"neutral3"}
        bg={"white"}
        display={"row.space-between.center"}
      >
        <Div ml={6} mt={6} mb={6} flex={1}>
          <Breadcrumbs>Passes / Manage / Application Forms</Breadcrumbs>
          <Title>{formName}</Title>
        </Div>

        <Div mx={4} display={"row.flex-start.flex-end"}>
          <BigOutlineButton onClick={onEditForm} mr={2}>
            Edit Form
          </BigOutlineButton>
          <BigFilledButton bg="altB5" onClick={onSubmitForm}>
            Submit
          </BigFilledButton>
        </Div>
      </Div>

      {loading ? (
        <Div mt={10} display="column.center.center" flex={1}>
          <LoadingIcon size={60} color="neutral4" />
          <Div mt={5}>Loading application submissions...</Div>
        </Div>
      ) : tableRows.length ? (
        <Div mt={3} mx={5} b={1} bc="neutral0">
          <Div mx={5} my={3}>
            <DataTable
              EmptyState={
                loading ? (
                  <Div py={20} display="column.center.center">
                    <LoadingIcon size={40} />
                  </Div>
                ) : (
                  <Div py={20} display="column.center.center">
                    <Text6 pb={4} bold>
                      No groups added yet
                    </Text6>
                    <Div display="row.flex-start-center">
                      <BigFilledButton
                        mr={2}
                        bg="altB5"
                        onClick={onAddGroup}
                        LeftIcon={AddIcon}
                      >
                        Add a group
                      </BigFilledButton>
                      <BigShadedButton
                        onClick={onInviteGroups}
                        LeftIcon={SendIcon}
                      >
                        Invite groups to register
                      </BigShadedButton>
                    </Div>
                  </Div>
                )
              }
              columns={COLUMNS}
              rows={tableRows}
              id={recordTypeId}
            />
          </Div>
        </Div>
      ) : (
        <DefaultEmptyState
          title={"There are no submissions to review"}
          bg="white"
        />
      )}
    </Div>
  );
};

export default View;
