import React, { Component } from "react";
import * as R from "ramda";
import { Div, OpenInNewIcon } from "components/Base";
import { MODULE } from "utils/standard-module-field-ids";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as ModalActions from "redux/modules/modal/actions";
import * as RecordActions from "redux/modules/modules/records/actions";

import OrderModal from "Orders/OrderModal/View";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import ViewRecordModal from "components/Global/Module/Modals/ViewRecord";

const getSourceValue = R.path([MODULE.SOURCE, "value"]);
const getEventId = R.path(["meta", "eventId"]);
const getModuleId = R.path(["meta", "moduleId"]);
const getRecordId = R.path(["meta", "rowId"]);
const getSubmissionId = R.path(["meta", "submissionId"]);
const getHelpers = R.path(["helpers"]);

const mapDispatchToProps = (dispatch, ownProps) => {
  const actions = bindActionCreators(
    {
      showModal: ModalActions.showModal,
      hideModal: ModalActions.hideModal,
      getRecords: RecordActions.getRecords
    },
    dispatch
  );

  // Check to see if we're overriding `hideModal` with a custom handler
  const helpers = getHelpers(ownProps.dependentValues);
  if (helpers.hideModal) {
    actions.hideModal = helpers.hideModal;
  }

  return actions;
};

const decorate = connect(
  null,
  mapDispatchToProps
);

class OpenRecordCellFormatter extends Component {
  refetchRecords = () => {
    const moduleId = getModuleId(this.props.dependentValues);
    if (moduleId) {
      this.props.getRecords({
        moduleId,
        options: {
          eventId: getEventId(this.props.dependentValues)
        }
      });
    }
  };

  refetchRecordsAndHideModal = () => {
    this.refetchRecords();
    this.props.hideModal();
  };

  showRecordModal = (moduleId, recordId) =>
    this.props.showModal({
      content: (
        <ViewRecordModal
          moduleId={moduleId}
          recordId={recordId}
          onClose={this.refetchRecords}
        />
      ),
      wrapper: ModalWrapper
    });

  showSubmissionSummaryModal = submissionId =>
    this.props.showModal({
      content: (
        <OrderModal
          hideModal={this.refetchRecordsAndHideModal}
          submissionId={submissionId}
        />
      ),
      wrapper: ModalWrapper
    });

  onClick = () => {
    const value = getSourceValue(this.props.dependentValues);
    switch (value.type) {
      case "submission":
        return this.showSubmissionSummaryModal(getSubmissionId(value));
      default:
        return this.showRecordModal(
          getModuleId(this.props.dependentValues),
          getRecordId(this.props.dependentValues)
        );
    }
  };

  render() {
    return (
      <Div
        display="row.center.center"
        onClick={this.onClick}
        color={{
          hover: "primary9",
          default: "gray5"
        }}
      >
        <OpenInNewIcon
          style={{
            color: "inherit"
          }}
        />
      </Div>
    );
  }
}

export default decorate(OpenRecordCellFormatter);
