import {
  take,
  call,
  put,
  select,
  takeEvery,
  all,
  fork
} from "redux-saga/effects";
import * as R from "ramda";

import { actions } from "./model";
import { getters as TableGetters } from "Submission/PeopleBlockTable";
import { actions as ManageItemsActions } from "Items/Manage";
import { actions as TabActions } from "ui-kit/Tabs";
import { review } from "App/Data/sagas";
import { getOrderIds, getVariantIdsByBlockId } from "./selectors";
import { getters as SubmissionGetters } from "Submission/Editor";
import { actions as OrderModalActions } from "Orders/OrderModal";

import { registerError } from "redux/modules/errors/actions";

const DEFAULT_TABS = ["people", "summary"];

const watchReview = function*() {
  for (;;) {
    const { payload, type } = yield take([
      actions.approve.type,
      actions.deny.type
    ]);

    const response = R.propOr(null, type, {
      [actions.approve.type]: "approve",
      [actions.deny.type]: "reject"
    });

    try {
      const res = yield call(review, {
        response,
        targetType: "lineItemId",
        recordIds: payload.recordIds
      });

      yield put(actions.reviewResponse(res));
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error occurred approving line items"
          }
        ])
      );
    }
  }
};

const setOrderAndVariantIds = function*(action) {
  const instanceId = R.path(["meta", "instanceId"], action);
  const tableIds = yield select(TableGetters.tableIds);

  if (
    R.contains(instanceId, tableIds) &&
    !R.contains(action.payload, DEFAULT_TABS)
  ) {
    const submissionId = yield select(SubmissionGetters.id);

    const orderIds = yield select(getOrderIds, {
      instanceId,
      params: { submissionId, fieldId: instanceId }
    });

    const variantIds =
      action.payload === "overview"
        ? []
        : yield select(getVariantIdsByBlockId, {
            instanceId,
            itemBlockId: action.payload,
            params: { submissionId, fieldId: instanceId }
          });

    yield put(
      ManageItemsActions.setOrderIdsAndVariantIds(
        { orderIds, variantIds },
        { meta: action.meta }
      )
    );
  }
};

const watchTabChange = function*() {
  let last = {};
  for (;;) {
    let { payload: next, meta = {} } = yield take(
      TabActions.setSelectedTab.type
    );
    const instanceId = R.prop("instanceId", meta);

    if (
      last[instanceId] &&
      !R.contains(last[instanceId], DEFAULT_TABS) &&
      R.contains(next, DEFAULT_TABS)
    ) {
      const submissionId = yield select(SubmissionGetters.id);
      yield put(OrderModalActions.fetchOrder({ submissionId }));
    }
    last[instanceId] = next;
  }
};

const watchTabs = function*() {
  yield takeEvery(TabActions.setSelectedTab.type, setOrderAndVariantIds);
};

const rootSaga = function*() {
  yield all([fork(watchReview), fork(watchTabs), fork(watchTabChange)]);
};

export default rootSaga;
