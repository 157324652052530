/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  getContacts: ({ credentials, org_id, accountId, excludeEventId }) =>
    new Promise((success, error) => {
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/account/organization/${org_id}/account/${accountId}/contacts`,
        credentials,
        qs: {
          excludeEventId
        },
        success,
        error
      };
      Helpers.request(options);
    }),
  addContactsToEvent: ({ credentials, orgId, accountId, data }) =>
    new Promise((success, error) => {
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/account/organization/${orgId}/account/${accountId}/contacts`,
        credentials,
        data,
        success,
        error
      };
      Helpers.request(options);
    }),
  getPeopleTypes: ({ credentials, eventId, accountId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/account/event/${eventId}/account/${accountId}/contact-types`,
        credentials,
        success,
        error
      });
    })
};
