import React from "react";
import * as R from "ramda";
import { createContext } from "redux-mvc";

import * as SubmissionActions from "Submission/Editor/actions";

import orderModal, { actions } from "Orders/OrderModal";
import orderSagas from "Orders/OrderModal/sagas";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { connect } from "react-redux";

import Layout from "Orders/OrderModal/View/Layout";

import * as SEND_TO_OPTION_TYPES from "SendEmailModal/utils/send-to-option-types";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import CreateOrderModal from "Orders/CreateOrderModal";
import EditOrderModal from "Orders/EditOrderModal";
import SendEmailModal from "SendEmailModal/View";
import AddLineItemsModal from "Orders/AddLineItemsModal";
import BulkEditLineItemsModal from "Orders/BulkEditLineItemsModal";
import DeleteConfirmation from "components/Global/CRM/Modals/DeleteConfirmation";
import PaymentModal from "Orders/PaymentModal";
import ManualPaymentModal from "Orders/ManualPaymentModal";
import OrderModal from "Orders/OrderModal/View";

import { noop } from "utils/General";

orderModal.setRootSaga(orderSagas);

const decorate = createContext({
  module: orderModal,
  options: {
    observedDomains: [
      "user",
      "event",
      "organization",
      "portal",
      "formsV2",
      "Data",
      "permissions",
      "ticketing",
      "@flopflip"
    ],
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(`(${R.join("|", orderModal.modules)})\/`)),
      R.prop("type")
    ),
    persist: false
  },
  lifeCycle: {
    constructor() {
      this.modified = false;
    },

    componentDidMount() {
      const { orderNumber, orderId, submissionId, portal } = this.props;

      this.store.dispatch(
        actions.init({
          orderNumber,
          orderId,
          submissionId,
          fetchFeed: true,
          isPortalUser: portal
        })
      );
    },

    componentWillUnmount() {
      if (this.modified && this.props.onDone) {
        this.props.onDone();
      }
    }
  },
  handlers: {
    flagAsModified() {
      this.modified = true;
    },

    showPaymentModal(orderId, chargeAmount, email) {
      this.props.showModal({
        content: (
          <PaymentModal
            amount={chargeAmount}
            email={email}
            showSendConfirmationOption={true}
            transactionParams={{
              orderId,
              amount: chargeAmount,
              method: "manual",
              source: "admin",
              sendConfirmation: true
            }}
            onDone={() => {
              this.handlers.flagAsModified();
              this.handlers.refetchData();
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    showManualPaymentModal(chargeAmount, email) {
      this.props.showModal({
        content: (
          <ManualPaymentModal
            amount={chargeAmount}
            email={email}
            addTransaction={({ amount, reference }) => {
              this.handlers.flagAsModified();

              this.store.dispatch(
                actions.addManualTransaction({
                  amount,
                  reference
                })
              );
            }}
            onDone={() => {}}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    send(orderId) {
      this.props.showModal({
        content: (
          <SendEmailModal
            emailType="confirmation"
            moduleId={STANDARD_MODULE_IDS.orders.id}
            selectedOptions={[SEND_TO_OPTION_TYPES.ORDER_CUSTOMER]}
            sourceOrderId={orderId}
            records={[orderId]}
            onDone={() => {
              this.handlers.flagAsModified();

              this.store.dispatch(actions.fetchOrder({ orderId }));
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    emailInvoice(orderId) {
      this.props.showModal({
        content: (
          <SendEmailModal
            emailType="invoice"
            moduleId={STANDARD_MODULE_IDS.orders.id}
            selectedOptions={[SEND_TO_OPTION_TYPES.ORDER_CUSTOMER]}
            sourceOrderId={orderId}
            records={[orderId]}
            onDone={() => {
              this.handlers.flagAsModified();

              this.store.dispatch(actions.fetchOrder({ orderId }));
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    bulkEdit(where) {
      this.props.showModal({
        content: (
          <BulkEditLineItemsModal
            where={{
              eventId: this.props.eventDetails.id,
              ...where
            }}
            onDone={() => {
              this.handlers.flagAsModified();
              this.handlers.refetchData();
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    addItems(orderId) {
      this.props.showModal({
        content: (
          <AddLineItemsModal
            orderId={orderId}
            onDone={() => {
              this.handlers.flagAsModified();
              this.handlers.refetchData();
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    showAddOrderModal({
      submissionRecordId,
      accountId,
      accountName,
      orderType
    }) {
      this.props.showModal({
        content: (
          <CreateOrderModal
            orderType={orderType}
            customerAccountId={accountId}
            customerAccountName={accountName}
            relatedRecordId={submissionRecordId}
            onDone={() => {
              this.handlers.flagAsModified();
              this.handlers.refetchData();
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    showOrderModal(orderId) {
      this.props.showModal({
        content: (
          <OrderModal
            orderId={orderId}
            onDone={() => {
              this.handlers.flagAsModified();
              this.handlers.refetchData();
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    editOrder(orderId) {
      this.props.showModal({
        content: (
          <EditOrderModal
            orderId={orderId}
            onDone={() => {
              this.handlers.flagAsModified();
              this.handlers.refetchData();
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    async deleteSubmission(submissionId) {
      await this.store.dispatch(
        SubmissionActions.deleteSubmission({ submissionId })
      );

      this.handlers.flagAsModified();

      this.props.hideModal();
    },

    reviewSingleSubmission(status) {
      this.handlers.flagAsModified();

      const payload = {
        review: {
          // record
          moduleId: this.props.form.base_module_id,
          recordId: this.props.submission.submission_record_id,
          userId: this.props.user.id,
          // submission
          rowId: this.props.submission.id,
          submissionRecordId: this.props.submission.submission_record_id,
          submissionId: this.props.submission.id,
          response: status
        }
      };

      if (!status) {
        return this.props.removeReviewV3(payload);
      }

      return this.props.createReviewV3(payload);
    },

    refetchData() {
      const { orderNumber, orderId, submissionId } = this.props;
      this.store.dispatch(
        actions.fetchOrder({ orderNumber, orderId, submissionId })
      );
    },

    updateAddress(orderId) {
      this.handlers.editOrder(orderId);
    },

    cancelOrder(orderId) {
      this.props.showModal({
        content: (
          <DeleteConfirmation
            hideModal={this.props.hideModal}
            heading="Cancel Order?"
            confirmLabel="Yes, Cancel Order"
            message={<div>Are you sure you want to cancel this order?</div>}
            onConfirm={async () => {
              this.handlers.flagAsModified();

              await this.store.dispatch(actions.cancelOrder(orderId));

              this.props.hideModal();
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },

    viewAttendeeBreakdown: noop,
    enterCardInfo: noop,
    manualPayment: noop,
    hideModal(...params) {
      this.props.hideModal(...params);
    }
  }
});

export default R.compose(
  connect(state => ({
    eventDetails: getEventDetails(state)
  })),
  decorate
)(Layout);
