import React from "react";
import * as R from "ramda";
import { DarkTab, Div, Text6, FontIcon } from "components/Base";
import { addS } from "utils/General";

const View = ({
  tabs,
  ActiveTabComponent,
  viewProps,
  itemTypes,
  showSidebar
}) => {
  return (
    <Div style={{ minHeight: "100%" }} height={1}>
      <Div bb={1} bc="gray3" bg="white" display="row.flex-start.flex-end">
        <Div flex={1}>
          <Text6 pl={8} pt={6}>
            Item Catalog
          </Text6>

          <Div pt={3} pl={8}>
            {R.compose(
              R.map(({ key, label, active, onClick }) => {
                return (
                  <DarkTab
                    key={key}
                    px={2}
                    fs={3}
                    active={active}
                    onClick={!active ? onClick : undefined}
                  >
                    {label}
                  </DarkTab>
                );
              }),
              R.filter(R.prop("visible"))
            )(tabs)}
          </Div>
        </Div>
      </Div>
      <Div
        style={{ minHeight: "100%" }}
        height={1}
        display="row.flex-start.flex-start"
      >
        {showSidebar && (
          <Div
            display="column"
            bg="gray0"
            width={260}
            height={1}
            px={5}
            pt={5}
            pb={8}
            style={{
              flexShrink: 0,
              minHeight: "100%"
            }}
          >
            {itemTypes.map(type => (
              <Div
                key={type.id}
                display="row.flex-start.center"
                p={2}
                onClick={type.onClick}
                bra={1}
                bg={
                  type.active
                    ? "gray2"
                    : {
                        default: "gray0",
                        hover: "gray2"
                      }
                }
              >
                <Div
                  display="column.center.center"
                  width={36}
                  height={36}
                  bra={2}
                  mr={3}
                  style={{
                    backgroundColor: type.color,
                    flexShrink: 0
                  }}
                >
                  <FontIcon fs={4} color="white">
                    {type.icon}
                  </FontIcon>
                </Div>

                <Div display="column">
                  <Div fs={3} fw={3} color="black">
                    {type.name}
                  </Div>
                  <Div fs={1} color="gray7">
                    {type.countOfItems} item
                    {addS(type.countOfItems)} · {type.countOfCategories}{" "}
                    {type.countOfCategories === 1 ? "category" : "categories"}
                  </Div>
                </Div>
              </Div>
            ))}
          </Div>
        )}
        <Div
          flex={1}
          height={1}
          mb={4}
          style={{
            minHeight: "100%"
          }}
        >
          <ActiveTabComponent {...viewProps} />
        </Div>
      </Div>
    </Div>
  );
};

export default View;
