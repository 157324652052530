export default {
  id: null,
  number: null,
  friendlyId: null,
  options: {
    requireAssignment: true
  },
  relatedRecord: {
    id: null,
    values: {}
  },
  customer: {
    contact: {
      id: null,
      email: null,
      name: null,
      phone: null,
      values: {}
    },
    account: {
      id: null,
      name: null,
      values: {}
    }
  },
  shipping: {
    method: "pickup",
    fname: "",
    lname: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    options: {
      beingPickedUpBy: {
        id: null,
        email: null,
        name: null,
        values: {}
      },
      pickupDate: null
    }
  },
  billing: {
    sameAsShipping: false,
    fname: "",
    lname: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    country: ""
  },
  note: ""
};
