import React from "react";
import { Div, FontIcon } from "components/Base";
import { getPlaceholderByItemType } from "../utils";

const Product = ({ photo_url, name, price_label, type_id, onClick }) => (
  <Div
    onClick={onClick}
    shadow={{
      default: 1,
      hover: 2
    }}
    style={{
      marginRight: 17,
      marginBottom: 17,
      background: "#FFFFFF",
      width: 175,
      borderRadius: "3px",
      overflow: "hidden"
    }}
  >
    <Div
      style={{
        width: "100%",
        height: 175,
        backgroundImage: photo_url ? `url(${photo_url})` : null,
        backgroundSize: "cover",
        backgroundPosition: "top center",
        backgroundColor: "#fafafa"
      }}
      display="row.center.center"
    >
      {photo_url ? null : (
        <FontIcon
          style={{
            fontSize: "90px",
            color: "#e8e8e8"
          }}
        >
          {getPlaceholderByItemType(type_id)}
        </FontIcon>
      )}
    </Div>

    <Div style={{ padding: "15px 10px" }}>
      <Div
        fw={3}
        style={{
          fontSize: 13,
          color: "#222",
          marginBottom: 4
        }}
      >
        {name}
      </Div>
      <Div
        color="gray6"
        style={{
          fontSize: 12
        }}
      >
        {price_label}
      </Div>
    </Div>
  </Div>
);

export default Product;
