import * as R from "ramda";
import React from "react";

import { connect } from "react-redux";
import { actions, getters } from "../model";

import {
  Div,
  UploadIcon,
  DownloadIcon,
  MediumFilledButton,
  DownFilledIcon,
  MenuItem
} from "components/Base";
import CanUserDo from "components/Global/Security/CanUserDo";
import MenuPopover from "ui-kit/MenuPopover";

import CSSModules from "react-css-modules";
import css from "ui-kit/ViewPicker/View/styles.scss";

import { noop } from "utils/General";

const decorate = R.compose(
  connect(
    (state, props) => ({
      moduleId: getters.moduleId(state, props)
    }),
    { onExport: actions.exportData }
  ),
  CSSModules(css)
);

const OptionsButton = ({
  handlers = {},
  onExport = noop,
  wrapperStyle = {},
  moduleId,
  ...styleProps
}) => (
  <MenuPopover
    Label={
      <MediumFilledButton
        className={css.button2}
        hover={false}
        color="white"
        RightIcon={DownFilledIcon}
        {...styleProps}
      >
        Options
      </MediumFilledButton>
    }
    wrapperStyle={wrapperStyle}
  >
    {({ closePopover }) => (
      <Div bra={3} bg="white" py={1}>
        <CanUserDo action={`${moduleId}_create`}>
          <MenuItem
            LeftIcon={UploadIcon}
            onClick={() => {
              handlers.showImportModal();
              closePopover();
            }}
          >
            Import .CSV
          </MenuItem>
        </CanUserDo>
        <CanUserDo action={`${moduleId}_export`}>
          <MenuItem
            LeftIcon={DownloadIcon}
            onClick={() => {
              onExport("csv");
              closePopover();
            }}
          >
            Export .CSV
          </MenuItem>
          <MenuItem
            LeftIcon={DownloadIcon}
            onClick={() => {
              onExport("xlsx");
              closePopover();
            }}
          >
            Export .XLSX
          </MenuItem>
        </CanUserDo>
      </Div>
    )}
  </MenuPopover>
);

export default decorate(OptionsButton);
