import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Required = ({ children }) => (
  <div styleName="container">
    <div styleName="title">{children}</div>
    <div styleName="required">*</div>
  </div>
);

Required.propTypes = {
  children: PropTypes.node.isRequired
};
export default CSSModules(Required, css);
