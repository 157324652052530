import React from "react";
import { SVG } from "../Base/index";

const lenndSVGs = {
  fullSizeSquare: ({ otherTags = null, ...svgProps }) => (
    <SVG {...svgProps} viewBox="0 0 48 48">
      {otherTags}
      <rect x="0" y="0" width="48" height="48" />
    </SVG>
  ),
  threeQuarterSizeSquare: ({ otherTags = null, ...svgProps }) => (
    <SVG {...svgProps} viewBox="0 0 48 48">
      {otherTags}
      <g transform="matrix(0.75,0,0,0.75,6,6)">
        <rect x="0" y="0" width="48" height="48" />
      </g>
    </SVG>
  ),
  halfSizeSquare: ({ otherTags = null, ...svgProps }) => (
    <SVG {...svgProps} viewBox="0 0 48 48">
      {otherTags}
      <g transform="matrix(0.5,0,0,0.5,12,12)">
        <rect x="0" y="0" width="48" height="48" />
      </g>
    </SVG>
  ),
  quarterSizeSquare: ({ otherTags = null, ...svgProps }) => (
    <SVG {...svgProps} viewBox="0 0 48 48">
      {otherTags}
      <g transform="matrix(0.25,0,0,0.25,18,18)">
        <rect x="0" y="0" width="48" height="48" />
      </g>
    </SVG>
  ),
  eigthSizeSquare: ({ otherTags = null, ...svgProps }) => (
    <SVG {...svgProps} viewBox="0 0 48 48">
      {otherTags}
      <g transform="matrix(0.125,0,0,0.125,21,21)">
        <rect x="0" y="0" width="48" height="48" />
      </g>
    </SVG>
  ),
  fullSizeDot: ({ otherTags = null, ...svgProps }) => (
    <SVG {...svgProps} viewBox="0 0 48 48">
      {otherTags}
      <g transform="matrix(6,0,0,6,-144,-144)">
        <circle cx="28" cy="28" r="4" />
      </g>
    </SVG>
  ),
  threeQuarterSizeDot: ({ otherTags = null, ...svgProps }) => (
    <SVG {...svgProps} viewBox="0 0 48 48">
      {otherTags}
      <g transform="matrix(4.5,0,0,4.5,-102,-102)">
        <circle cx="28" cy="28" r="4" />
      </g>
    </SVG>
  ),
  halfSizeDot: ({ otherTags = null, ...svgProps }) => (
    <SVG {...svgProps} viewBox="0 0 48 48">
      {otherTags}
      <g transform="matrix(3,0,0,3,-60,-60)">
        <circle cx="28" cy="28" r="4" />
      </g>
    </SVG>
  ),
  quarterSizeDot: ({ otherTags = null, ...svgProps }) => (
    <SVG {...svgProps} viewBox="0 0 48 48">
      {otherTags}
      <g transform="matrix(1.5,0,0,1.5,-18,-18)">
        <circle cx="28" cy="28" r="4" />
      </g>
    </SVG>
  ),
  eigthSizeDot: ({ otherTags = null, ...svgProps }) => (
    <SVG {...svgProps} viewBox="0 0 48 48">
      {otherTags}
      <g transform="matrix(0.75,0,0,0.75,3,3)">
        <circle cx="28" cy="28" r="4" />
      </g>
    </SVG>
  ),
  circleHalfFilled: ({ otherTags = null, ...svgProps }) => (
    <SVG {...svgProps} stroke="currentColor" viewBox="0 0 48 48">
      {otherTags}
      <circle cx="24" cy="24" r="18" strokeWidth="3" fill="none" />
      <g transform="matrix(1,0,0,1,6,6)">
        <path d="M36,18.116C35.937,27.997 27.896,36 18,36C8.066,36 0,27.934 0,18L36,18L36,18.116Z" />
      </g>
    </SVG>
  ),
  circle: ({ otherTags = null, ...svgProps }) => (
    <SVG {...svgProps} stroke="currentColor" viewBox="0 0 48 48">
      {otherTags}
      <circle cx="24" cy="24" r="18" strokeWidth="3" fill="none" />
    </SVG>
  ),

  drag: ({ otherTags = null, ...svgProps }) => (
    <SVG {...svgProps} viewBox="0 0 48 48">
      {otherTags}
      <g transform="matrix(1,0,0,1,0,0)">
        <g transform="matrix(0.75,0,0,0.75,21,-3)">
          <circle cx="12" cy="12" r="4" />
        </g>
        <g transform="matrix(0.75,0,0,0.75,9,-3)">
          <circle cx="12" cy="12" r="4" />
        </g>
        <g transform="matrix(0.75,0,0,0.75,21,9)">
          <circle cx="12" cy="12" r="4" />
        </g>
        <g transform="matrix(0.75,0,0,0.75,9,9)">
          <circle cx="12" cy="12" r="4" />
        </g>
        <g transform="matrix(0.75,0,0,0.75,21,21)">
          <circle cx="12" cy="12" r="4" />
        </g>
        <g transform="matrix(0.75,0,0,0.75,9,21)">
          <circle cx="12" cy="12" r="4" />
        </g>
        <g transform="matrix(0.75,0,0,0.75,21,33)">
          <circle cx="12" cy="12" r="4" />
        </g>
        <g transform="matrix(0.75,0,0,0.75,9,33)">
          <circle cx="12" cy="12" r="4" />
        </g>
      </g>
    </SVG>
  ),

  expandVert: ({ otherTags = null, ...svgProps }) => (
    <SVG {...svgProps} viewBox="0 0 48 48">
      {otherTags}
      <g transform="matrix(0.707107,-0.707107,0.707107,0.707107,-9.94113,21)">
        <path d="M12,36L24,36L24,40L8,40L8,24L12,24L12,36Z" />
      </g>
      <g transform="matrix(-0.707107,0.707107,-0.707107,-0.707107,57.9411,27)">
        <path d="M12,36L24,36L24,40L8,40L8,24L12,24L12,36Z" />
      </g>
    </SVG>
  ),

  expand: ({ otherTags = null, ...svgProps }) => (
    <SVG {...svgProps} viewBox="0 0 48 48">
      {otherTags}
      <path d="M12,36L24,36L24,40L8,40L8,24L12,24L12,36Z" />
      <g transform="matrix(-1,0,0,-1,48,48)">
        <path d="M12,36L24,36L24,40L8,40L8,24L12,24L12,36Z" />
      </g>
    </SVG>
  ),

  inverseGrid: ({ otherTags = null, ...svgProps }) => (
    <SVG {...svgProps} viewBox="0 0 48 48">
      {otherTags}
      <rect x="5" y="5" width="10" height="10" />
      <rect x="19" y="5" width="10" height="10" />
      <rect x="33" y="5" width="10" height="10" />
      <rect x="5" y="19" width="10" height="10" />
      <rect x="19" y="19" width="10" height="10" />
      <rect x="33" y="19" width="10" height="10" />
      <rect x="5" y="33" width="10" height="10" />
      <rect x="19" y="33" width="10" height="10" />
      <rect x="33" y="33" width="10" height="10" />
    </SVG>
  ),
  searchFolder: ({ otherTags = null, ...svgProps }) => (
    <SVG {...svgProps} viewBox="0 0 48 48">
      {otherTags}
      <path
        d="M34.822 26.994h-1.046l-.361-.358a8.32 8.32 0 0 0 2.064-5.495c0-4.669-3.826-8.453-8.547-8.453-4.72 0-8.547 3.784-8.547 8.453 0 4.67 3.826 8.454 8.547 8.454a8.55 8.55 0 0 0 5.556-2.035l.361    .358v1.027l6.575 6.49 1.96-1.938-6.562-6.503zm-7.89 0c-3.268 0-5.917-2.62-5.917-5.853 0-3.232 2.65-5.852 5.917-5.852 3.268 0 5.918 2.62 5.918 5.852s-2.65 5.853-5.918 5.853z"
        fill="#000"
      />
      <path
        d="M16.932 3.857H30.22c1.836 0 3.322 1.47 3.322 3.286v5.786a10.795 10.795 0 0 0-3.322-1.393V7.143H3.645V23.57h13.414a10.494 10.494 0 0 0 1.09 3.286H3.644c-1.836 0-3.323-1.47-3.323-3.286L.34     3.857A3.288 3.288 0 0 1 3.645.57h9.966l3.32 3.286z"
        fill="#000"
      />
    </SVG>
  ),
  none: ({ otherTags = null, ...svgProps }) => (
    <SVG {...svgProps} viewBox="0 0 48 48">
      {otherTags}
    </SVG>
  )
};

export { lenndSVGs };
