import React from "react";
import { connect } from "react-redux";
import { getTabs } from "EventLight/Passes/selectors";
import { withRouter } from "react-router";
import * as R from "ramda";
import AccessGridTable from "ui-kit/AccessGridTable/View";
import { Div } from "components/Base";

import { Page, Title } from "EventLight/Common/FieldLayout";

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    tabs: getTabs(state, props)
  }))
);

const Layout = ({ tabs, handlers = {} }) => {
  return (
    <>
      <Page>
        <Title
          icon="assignment_turned_in"
          title="Zone Grid"
          description="Manage your zones"
          tabs={tabs}
        />

        <Div pb={3} pl={7}>
          <AccessGridTable
            tableStyle={{ width: 810, height: 500 }}
            handlers={handlers}
          />
        </Div>
      </Page>
    </>
  );
};

export default decorate(Layout);
