import getValue from "../get-value/time";
import sortEmpty from "./sort-empty";

function createDateTime(time) {
  return new Date(`1970/01/01 ${time}`);
}

export default {
  ASC: sortEmpty(
    "time",
    (a, b) => createDateTime(getValue(a)) - createDateTime(getValue(b))
  ),
  DESC: sortEmpty(
    "time",
    (a, b) => createDateTime(getValue(b)) - createDateTime(getValue(a))
  )
};
