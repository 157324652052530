import React, { useEffect } from "react";
import * as R from "ramda";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import { connect } from "react-redux";
import { actions, getters } from "../model";
import Body from "./Body";
import Loading from "ui-kit/Loading";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const decorate = R.compose(
  connect(
    state => ({
      loading: getters.loading(state)
    }),
    {
      fetchUser: actions.fetchUser,
      hideModal: actions.hideModal
    }
  ),
  CSSModules(css)
);

const UserInfoModal = ({ hideModal, fetchUser, loading }) => {
  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <MiniModalWrapper
      showModal={true}
      hideModal={hideModal}
      showHeader={false}
      width={424}
      height={400}
      stopPropagation
    >
      {loading ? (
        <div styleName="wrapper">
          <Loading />
        </div>
      ) : (
        <Body />
      )}
    </MiniModalWrapper>
  );
};

export default decorate(UserInfoModal);
