import { createHandlers } from "redux-mvc";
import * as R from "ramda";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: false,
  order: {},
  items: [],
  compressedList: [],
  filterList: [],
  canDrag: true,
  filterName: "",
  selectedIds: [],
  showCheck: true
};

const getChildrenIds = ({ id, order }) => {
  const childrenIds = R.propOr([], id, order);

  if (R.isEmpty(childrenIds)) {
    return [];
  }

  return [
    ...childrenIds,
    ...R.reduce(
      (acc, childId) => {
        return [...acc, ...getChildrenIds({ id: childId, order })];
      },
      [],
      childrenIds
    )
  ];
};

const model = createHandlers({
  iniState,
  reducers: {
    reorder: R.identity,
    saveAfterReordering: R.identity,
    setInitialData: (
      _,
      { payload: { items, canDrag = true, showCheck = true } }
    ) => ({
      items,
      order: R.reduce(
        (acc, item) => {
          const parent = item.parent || "root";
          const parentField = acc[parent];

          if (!parentField) {
            return {
              ...acc,
              [parent]: [item.id]
            };
          }
          return {
            ...acc,
            [parent]: [...acc[parent], item.id]
          };
        },
        {},
        items
      ),
      canDrag,
      showCheck
    }),
    toggleCompressedList: (state, { payload: { id, isExpanded } }) => ({
      compressedList: !isExpanded
        ? R.filter(compressedId => compressedId !== id, state.compressedList)
        : [...state.compressedList, id]
    }),
    reorderRootChildren: (state, { payload: { movedId } }) => ({
      order: {
        ...state.order,
        root: [
          ...R.filter(id => id !== movedId, R.propOr([], "root", state.order)),
          movedId
        ]
      }
    }),
    reorderSameParentChildren: (
      state,
      { payload: { movedId, droppedId, parentId } }
    ) => {
      const childrenList = state.order[parentId];
      const movedIndex = R.findIndex(id => id === movedId, childrenList);
      const droppedIndex = R.findIndex(id => id === droppedId, childrenList);

      const moved = childrenList[movedIndex];
      const dropped = childrenList[droppedIndex];

      const updatedList = R.update(movedIndex, dropped, childrenList);

      return {
        order: {
          ...state.order,
          [parentId]: R.update(droppedIndex, moved, updatedList)
        }
      };
    },
    moveToDifferentParent: (
      state,
      { payload: { movedId, droppedId, droppedParentId, movedParentId } }
    ) => {
      const movedChildrenList = state.order[movedParentId];
      const droppedChildrenList = state.order[droppedParentId];

      const movedIndex = R.findIndex(id => id === movedId, movedChildrenList);
      const droppedIndex = R.findIndex(
        id => id === droppedId,
        droppedChildrenList
      );

      const moved = movedChildrenList[movedIndex];
      return {
        order: {
          ...state.order,
          [movedParentId]: R.filter(
            childId => childId !== movedId,
            movedChildrenList
          ),
          [droppedParentId]: [
            ...droppedChildrenList.slice(0, droppedIndex),
            moved,
            ...droppedChildrenList.slice(droppedIndex)
          ]
        }
      };
    },
    movePageIntoFolder: (
      state,
      { payload: { movedId, droppedId, movedParentId } }
    ) => {
      const movedChildrenList = state.order[movedParentId];
      const droppedChildrenList = R.propOr([], droppedId, state.order);

      const movedIndex = R.findIndex(id => id === movedId, movedChildrenList);

      const moved = movedChildrenList[movedIndex];

      return {
        order: {
          ...state.order,
          [movedParentId]: R.filter(
            childId => childId !== movedId,
            movedChildrenList
          ),
          [droppedId]: [...droppedChildrenList, moved]
        }
      };
    },
    toggleSelectedId: (state, { payload: { id, selected } }) => {
      const childrenIds = getChildrenIds({ id, order: state.order });
      return {
        selectedIds: selected
          ? R.filter(
              selectedId =>
                selectedId !== id &&
                !R.any(childId => childId === selectedId, childrenIds),
              state.selectedIds
            )
          : [
              ...R.filter(
                selectedId =>
                  !R.any(childId => childId === selectedId, childrenIds),
                state.selectedIds
              ),
              id,
              ...childrenIds
            ]
      };
    }
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
