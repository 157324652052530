import PropTypes from "prop-types";
import * as R from "ramda";
import { cloneElement, Component } from "react";
import autobind from "autobind-decorator";
import getValue from "utils/value-types/get-value/file";
import uuid from "node-uuid";
import Helpers from "utils/Global/Helpers";
import { ACCEPT_UPLOAD_FILE } from "utils/file-types-upload";

class FileUploadEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || null
    };
  }

  @autobind
  getFiles() {
    return getValue(this.props.value);
  }

  @autobind
  deleteFile(id) {
    const existingFiles = this.getFiles();
    this.onChange({
      type: "file",
      value: {
        files: [...existingFiles.filter(f => f.id !== id)]
      }
    });
  }

  @autobind
  onChange(value) {
    this.setState(
      {
        value
      },
      () => this.props.onChange(this.state.value)
    );
  }

  @autobind
  showFilepicker() {
    const multiple = R.pathOr(
      false,
      ["settings", "allowMultiple"],
      this.props.column
    );

    const callback = files => {
      if (files.length) {
        const filteredFiles = files.filter(f => typeof f.url !== "undefined");

        if (filteredFiles.length) {
          const existingFiles = multiple ? this.getFiles() : [];
          this.onChange({
            type: "file",
            value: {
              files: [
                ...existingFiles,
                ...filteredFiles.map(f => ({
                  ...f,
                  filepickerId: f.id,
                  id: uuid.v4(),
                  uploadedAt: new Date()
                }))
              ]
            }
          });
        }
      }
    };
    const accept = R.compose(
      R.flatten,
      R.map(({ value }) => ACCEPT_UPLOAD_FILE[value]),
      R.pathOr([], ["settings", "allowedFileTypes"])
    )(this.props.column);

    const options = {
      multiple,
      accept,
      fromSources: ["local_file_system", "dropbox"]
    };

    const path = { path: "event-files/" };

    Helpers.getFilepicker(options, path, callback);
  }

  componentWillUpdate(nextProps) {
    if (!R.equals(this.props.value, nextProps.value)) {
      this.setState({
        value: nextProps.value
      });
    }
  }

  render() {
    // only send necessary props to children
    // eslint-disable-next-line no-unused-vars
    const { value, onChange, ...rest } = this.props;

    return cloneElement(this.props.children, {
      ...rest,
      files: this.getFiles(),
      deleteFile: this.deleteFile,
      showFilepicker: this.showFilepicker
    });
  }
}

FileUploadEditor.defaultProps = {
  allowDownload: false,
  placeholder: "Add files to this submission"
};

FileUploadEditor.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
  column: PropTypes.object
};

export default FileUploadEditor;
