import React from "react";

import { connect } from "react-redux";

import { actions } from "../index";

import { Cards } from "components/Base";

import QuestionsSetsCollapsable from "./QuestionsSets";

import ItemQuestions from "./ItemQuestions";

const decorate = connect(
  null,
  {
    reorderQuestions: actions.reorderQuestions
  }
);

const Card = ({
  question_set,
  showEditQuestionSetQuestionsModal,
  required,
  question
}) =>
  question_set ? (
    <QuestionsSetsCollapsable
      {...question_set}
      showEditQuestionSetQuestionsModal={showEditQuestionSetQuestionsModal}
    />
  ) : (
    <ItemQuestions {...question} required={required} />
  );

const QCards = Cards(Card, "VARIANT_QUESTIONS");

const QuestionCards = ({
  questions,
  reorderQuestions,
  showEditQuestionSetQuestionsModal
}) => (
  <QCards
    cards={questions}
    onReorder={reorderedQuestions => {
      reorderQuestions({ reorderedQuestions });
    }}
    showEditQuestionSetQuestionsModal={showEditQuestionSetQuestionsModal}
  />
);

export default decorate(QuestionCards);
