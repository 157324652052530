import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import AddInventoryModal from "AddInventoryModal/View";

const decorate = connect(
  state => ({
    showAddInventoryModal: getters.showAddInventoryModal(state)
  }),
  {
    setShowAddInventoryModal: actions.setShowAddInventoryModal,
    refreshAfterSaving: actions.refreshAfterSaving
  }
);

const AddInventoryModalWrapper = ({
  showAddInventoryModal,
  setShowAddInventoryModal,
  refreshAfterSaving
}) =>
  showAddInventoryModal ? (
    <AddInventoryModal
      hideModal={() => setShowAddInventoryModal(false)}
      onSave={refreshAfterSaving}
    />
  ) : null;

export default decorate(AddInventoryModalWrapper);
