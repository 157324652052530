import Helpers from "utils/Global/Helpers";
import getQuery from "redux/modules/modules/utils/get-query";

module.exports = {
  get: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery(data.options);

      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/schedules/assigned-activities/${
          data.recordId
        }${query || ""}`,
        credentials,
        success: result => {
          resolve(result);
        },
        error: error => {
          reject(error);
        }
      };
      /* eslint-enable no-underscore-dangle */

      Helpers.request(options);
    })
};
