import React from "react";
import { connect } from "react-redux";

import { eventId } from "redux/modules/portal/selectors";
import { portalUser } from "redux/modules/portal/user/selectors";

const decorate = connect(state => ({
  eventId: eventId(state),
  portalUser: portalUser(state)
}));

const Summary = () => {
  return <p>Summary</p>;
};

export default decorate(Summary);
