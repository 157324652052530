import React from "react";
import { Div } from "components/Base";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import css from "ui-kit/ViewPicker/View/styles.scss";

import { noop } from "utils/General";

import { actions, getters } from "Forms/Results2";
import { RightIcon, LeftIcon } from "components/Base";

const decorate = connect(
  state => ({
    collapsed: getters.collapsed(state) // no props, use the default instance
  }),
  { onToggleCollapsed: () => actions.toggleCollapse() } // no bind, use the default instance
);

const CollapseButton = ({ collapsed = true, onToggleCollapsed = noop }) => (
  <Div
    width={collapsed ? 40 : 30}
    height={40}
    bra={2}
    onClick={onToggleCollapsed}
    display="row.center.center"
    className={css.button5}
    style={{
      flexShrink: 0
    }}
  >
    {collapsed ? (
      <RightIcon sizeWFS={6} color="white" />
    ) : (
      <LeftIcon sizeWFS={6} color="white" />
    )}
  </Div>
);

export default CSSModules(decorate(CollapseButton), css);
