import {
  put,
  all,
  take,
  takeEvery,
  fork,
  select,
  call
} from "redux-saga/effects";
import { push } from "react-router-redux";
import * as R from "ramda";
import { actions, getters } from "./model";
import { registerError } from "redux/modules/errors/actions";
import { actions as AddPersonModalActions } from "PortalHub/AddPersonModal/model";
import {
  actions as PaginationActions,
  getters as PaginationGetters
} from "ui-kit/PaginationBar";
import {
  actions as SearchbarActions,
  getters as SearchbarGetters
} from "ui-kit/SearchBar";
import { actions as TableActions } from "ui-kit/Table/model";
import { apiCall } from "App/Data/sagas";
import { eventDetails as getEventDetails } from "redux/modules/portal/selectors";
import { portalUser as getPortalUser } from "redux/modules/portal/user/selectors";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { userId as getUserId } from "redux/modules/user/selectors";
import { getPeople } from "./selectors";

import { AVAILABLE_SECTIONS } from "./constants";
import { summary_mock } from "./mockData";

const getParams = function*() {
  const eventDetails = yield select(getEventDetails);
  const portalUser = yield select(getPortalUser);
  const isViewingAsAccount = R.pathEq(["active_view", "type"], "account")(
    portalUser
  );
  const accountId = isViewingAsAccount
    ? R.path(["active_view", "id"], portalUser)
    : null;
  const userId = yield select(getUserId);
  const recordTypeId = yield select(getters.recordTypeId);

  return {
    eventId: eventDetails.id,
    eventSlug: eventDetails.slug,
    eventUUID: eventDetails.uuid,
    accountId,
    userId,
    recordTypeId
  };
};

const getSummaryData = function*() {
  // we need to refetch it when a new request is created.
  const payload = summary_mock;
  yield put(
    actions.setSummaryData({
      dueDates: R.prop("due_dates", payload),
      summary: R.prop("summary", payload)
    })
  );
};

const init = function*(opts) {
  try {
    if (actions.init.type === opts.type) {
      yield all([
        put(actions.setRecordTypeId(opts.payload.recordTypeId)),
        fork(getSummaryData),
        put(AddPersonModalActions.loadData())
      ]);
    }

    yield put(actions.setLoading(true));
    const search = yield select(SearchbarGetters.searchTerm);
    const { eventId, accountId, recordTypeId } = yield call(getParams);

    if (
      [
        SearchbarActions.clearSearch.type,
        SearchbarActions.setSearchTerm.type,
        PaginationActions.setPageSize.type,
        actions.refetchData.type
      ].includes(opts.type)
    ) {
      yield put(
        PaginationActions.setPagination({
          page: 0
        })
      );
    }
    // TODO: send filters to API when is finished. Posibilities: view_all, has_items, no_items
    const page = yield select(PaginationGetters.page);
    const { payload: data } = yield call(apiCall, {
      method: "get",
      url: `/portal/event/${eventId}/account/${accountId}/people/${recordTypeId}`,
      qs: {
        page: page + 1,
        pageSize: 10,
        ...(search ? { search } : {})
      }
    });
    yield put(actions.setInitialData({ data: data }));

    const people = yield select(getPeople);

    for (const person of people) {
      // we have tables for requests. setup the data here:
      const requestTypesOfPerson = R.path(
        ["sections", AVAILABLE_SECTIONS.REQUESTS, "types"],
        person
      );
      const personId = R.prop("id", person);
      for (const requestType of requestTypesOfPerson) {
        const requestTypeId = R.prop("id", requestType);
        const requestGroupId = R.path(["groups", 0, "group_id"], requestType);
        const items = R.path(["groups", 0, "items"], requestType);
        yield put(
          actions.selectTabOfRequestType({
            personId,
            requestTypeId,
            requestGroupId,
            items
          })
        );
      }
    }
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred geting people data for the account Id"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const handleSelectedPeopleIds = function*() {
  const selectedPeopleIds = yield select(getters.selectedPeopleIds);

  const { eventId, eventSlug, eventUUID, accountId, recordTypeId } = yield call(
    getParams
  );

  yield call(apiCall, {
    method: "put",
    url: accountId
      ? `/orders/event/${eventId}/account/${accountId}/cart`
      : `/orders/event/${eventId}/cart`,
    data: {
      customers: R.map(
        contactId => ({ contactId, accountId }),
        selectedPeopleIds
      )
    }
  });

  yield put(
    push(`/hubs/${eventSlug}/${eventUUID}/people/${recordTypeId}/requests`)
  );
};

const submitRequest = function*({ payload: contactId }) {
  const { eventId, eventSlug, eventUUID, accountId, recordTypeId } = yield call(
    getParams
  );

  yield call(apiCall, {
    method: "put",
    url: accountId
      ? `/orders/event/${eventId}/account/${accountId}/cart`
      : `/orders/event/${eventId}/cart`,
    data: {
      customers: [{ contactId, accountId }]
    }
  });

  yield put(
    push(`/hubs/${eventSlug}/${eventUUID}/people/${recordTypeId}/requests`)
  );
};

const saveNewPerson = function*({ payload }) {
  const { accountId, eventId, userId } = yield call(getParams);

  const { record } = yield call(apiCall, {
    method: "post",
    url: `/modules/${STANDARD_MODULE_IDS.contacts.id}/records`,
    data: payload,
    qs: {
      eventId,
      userId
    }
  });
  yield call(apiCall, {
    method: "post",
    url: `/account/${accountId}/contact-relationships`,
    data: {
      eventId,
      isPrimary: record.isPrimary,
      role: record.role,
      accountId,
      contactId: record.id
    },
    qs: { userId }
  });

  yield all([
    put(actions.refetchData()),
    put(actions.setIsAddPersonModalOpened(false))
  ]);
};

const viewAllReports = function*() {
  const { eventSlug, eventUUID, recordTypeId } = yield call(getParams);

  yield put(
    push(`/hubs/${eventSlug}/${eventUUID}/people/${recordTypeId}/reports`)
  );
};

const openDocument = function*() {
  for (;;) {
    const { payload } = yield take(actions.openDocument.type);
    yield call(window.open, payload.url, "_blank");
  }
};

const changeTabOfRequestType = function*() {
  for (;;) {
    const { payload } = yield take(actions.selectTabOfRequestType.type);
    const personId = R.prop("personId", payload);
    const requestTypeId = R.prop("requestTypeId", payload);
    const requestGroupId = R.prop("requestGroupId", payload);
    const requestsCols = yield select(getters.requestsCols);
    const data = yield select(getters.data);
    const colWidths = R.propOr({}, "column_widths", data);

    yield put(
      TableActions.setData(
        {
          columns: R.propOr([], requestGroupId, requestsCols),
          rows: R.propOr([], "items", payload),
          columnWidths: R.prop(requestGroupId, colWidths),
          canEditCells: false
        },
        {
          meta: {
            instanceId: `${personId}-${requestTypeId}`
          }
        }
      )
    );
  }
};

const watchSetSelectedPeopleIds = function*() {
  yield takeEvery(
    actions.handleSelectedPeopleIds.type,
    handleSelectedPeopleIds
  );
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchSearch = function*() {
  yield takeEvery(
    [
      PaginationActions.setPage.type,
      PaginationActions.setPageSize.type,
      SearchbarActions.clearSearch.type,
      SearchbarActions.setSearchTerm.type,
      actions.refetchData.type
    ],
    init
  );
};

const watchOnAddPersonSubmitted = function*() {
  yield takeEvery(AddPersonModalActions.onSaveFinished.type, saveNewPerson);
};

const watchSubmitRequest = function*() {
  yield takeEvery(actions.submitRequest.type, submitRequest);
};

const watchGoToReports = function*() {
  yield takeEvery(actions.goToViewAllReports.type, viewAllReports);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchSearch),
    fork(watchSetSelectedPeopleIds),
    fork(watchOnAddPersonSubmitted),
    fork(watchSubmitRequest),
    fork(watchGoToReports),
    fork(changeTabOfRequestType),
    fork(openDocument)
  ]);
};

export default rootSaga;
