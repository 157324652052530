import * as R from "ramda";

import React from "react";
import { getValue } from "../../utils";

import FileIcon from "components/Atoms/FileIcon";

const File = props => {
  const files = getValue(props, "files", []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        overflow: "hidden"
      }}
    >
      {R.map(
        file => (
          <div
            title={file.filename}
            style={{
              backgroundColor: "white",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              border: "1px solid #D4D4D4",
              boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.07)",
              borderRadius: 3,
              margin: "3px 0 3px 3px",
              padding: "0 5px",
              height: 28,
              minWidth: 80
            }}
          >
            <div
              style={{
                width: 14,
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                marginRight: 5
              }}
            >
              <FileIcon size={14} mimetype={file.mimetype} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "calc(100% - 14px - 5px)"
              }}
            >
              <div
                style={{
                  color: "#625D6C",
                  fontSize: 11,
                  marginRight: 3,
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis"
                }}
              >
                {file.filename}
              </div>
            </div>
          </div>
        ),
        files
      )}
    </div>
  );
};

export default File;
