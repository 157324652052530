import { put, take, call, fork, cancel, all } from "redux-saga/effects";
import { eventChannel, buffers } from "redux-saga";
import * as R from "ramda";
import { actions } from "ui-kit/Datagrid";

import { debounce } from "utils/General/sagas";

import { getEditorId } from "ui-kit/Datagrid/utils";

const setSearch = function*({ payload, meta }) {
  yield put(actions.setSearchTerm(payload, { meta }));
};

const watchSetSearch = debounce(actions.setSearch.type, setSearch);

const translateRe = /translate\(-?(\d*)px, -?(\d*)px\)/;

const getCurrentY = R.compose(
  R.nth(2),
  R.match(translateRe),
  R.pathOr("", ["style", "transform"])
);

const setCurrentY = (elm, newY) => {
  const newTransform = elm.style.transform.replace(
    translateRe,
    `translate($1px, ${newY}px)`
  );

  elm.style.transform = newTransform;
};

function scrollEvents(elm) {
  return eventChannel(emitter => {
    const listener = e => {
      emitter(e.target.scrollTop);
    };
    elm.addEventListener("scroll", listener);
    emitter(elm.scrollTop);
    return () => {
      elm.removeEventListener("scroll", listener);
    };
  }, buffers.sliding(10));
}

const fixScroll = function*({ parentElm, instanceId }) {
  const editorId = getEditorId({ instanceId: instanceId || "" });
  const editorElm = yield call(
    [document, document.querySelector],
    `#${editorId} .rdg-editor-container`
  );

  if (editorElm && parentElm) {
    const scrollChan = yield call(scrollEvents, parentElm);

    const iniYString = yield call(getCurrentY, editorElm);
    const iniY = Number(iniYString);

    for (;;) {
      const deltaY = yield take(scrollChan);
      yield call(setCurrentY, editorElm, -deltaY + iniY);
    }
  }
};

const watchEditing = function*() {
  yield take(actions.setIniData.type);
  const parentElm = yield call(
    [document, document.getElementById],
    "orderModal"
  ); // TODO: be able to dinamically set the parentElm
  for (;;) {
    const { meta } = yield take(actions.requestEdit.type);
    const task = yield fork(fixScroll, {
      parentElm,
      instanceId: meta.instanceId || ""
    });
    yield take([actions.updateCell.type, actions.cancelEdit.type]);
    yield cancel(task);
  }
};

const rootSaga = function*() {
  yield all([fork(watchEditing), fork(watchSetSearch)]);
};

export default rootSaga;
