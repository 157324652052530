import PropTypes from "prop-types";
import React, { Fragment } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import DeleteConfirmation from "components/Global/CRM/Popovers/DeleteConfirmation";

const AccountContactsActionBar = ({ helpers, selectedRows }) => {
  const countOfSelectedRows = selectedRows.length;
  return (
    <div styleName="container">
      <div styleName="count">
        {countOfSelectedRows} selected row
        {countOfSelectedRows !== 1 ? "s" : ""}
      </div>
      <div
        styleName="menuItem"
        onClick={helpers.edit}
        role="button"
        tabIndex="0"
      >
        <span styleName="text">Edit role</span>
      </div>

      {helpers.params.eventId ? (
        <Fragment>
          <div
            styleName="menuItem"
            onClick={helpers.giveLoginAccess}
            role="button"
            tabIndex="0"
          >
            <span styleName="text">Give login access</span>
          </div>
          <div
            styleName="menuItem"
            onClick={helpers.shareLoginDetails}
            role="button"
            tabIndex="0"
          >
            <span styleName="text">Share login details</span>
          </div>
        </Fragment>
      ) : null}

      <DeleteConfirmation
        onConfirm={helpers.deleteAccounts}
        text={`
          Are you sure you want to remove
          ${countOfSelectedRows > 1 ? "these people" : "this person"}
          from this group?
        `}
      >
        <div styleName="menuItem">
          <span styleName="text">Remove</span>
        </div>
      </DeleteConfirmation>
    </div>
  );
};

AccountContactsActionBar.propTypes = {
  helpers: PropTypes.shape({
    edit: PropTypes.func,
    deleteAccounts: PropTypes.func
  }).isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default CSSModules(AccountContactsActionBar, css);
