import React from "react";
import { Div } from "components/Base";

const Header = () => (
  <Div
    height={150}
    width={1}
    display="row.center.center"
    style={{
      backgroundColor: "#813CD2"
    }}
  />
);

export default Header;
