import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { any, prop, map } from "ramda";
import Helpers from "utils/Global/Helpers";
import autobind from "autobind-decorator";

import Snackbar from "components/Global/Snackbar";

import MyHeader from "components/Global/Headers/MyHeader";
import EventHeader from "components/Global/Headers/EventHeader";
import OrganizationHeader from "components/Global/Headers/Organization";

import LayoutHeader from "components/Global/Layout/LayoutHeader";
import LayoutContent from "components/Global/Layout/LayoutContent";

import Modal from "components/Global/Modal";

@CSSModules(css)
class PlatformLoaded extends Component {
  constructor(props) {
    super(props);

    this.seedInitialData();
  }

  componentDidMount() {
    this.props.getEvents(this.props.user.id);
  }

  @autobind
  seedInitialData() {
    if (!Helpers.isBrowser()) {
      return;
    }

    if (window.LENND_INITIAL_DATA) {
      this.props.routes
        .filter(route => route.component.seedData)
        .reduce((promises, route) => {
          route.component.seedData(window.LENND_INITIAL_DATA);
        }, {});

      // after seeding initial route, nullify serverData
      window.LENND_INITIAL_DATA = null;
    }
  }

  @autobind
  showSnackbar(options) {
    this.props.showSnackbar({
      key: Date.now(),
      ...options
    });
  }

  @autobind
  showModal(modal) {
    this.props.showModal(modal.content ? modal : { content: modal });
  }

  @autobind
  hideModal() {
    this.props.hideModal();
  }

  @autobind
  renderHeader() {
    const routeNames = map(prop("name"), this.props.routes);

    let showEventHeader = routeNames.includes("editEventProfile");
    const showMyHeader = any(r => ["HomeView", "accountSettings"].includes(r))(
      routeNames
    );
    const showOrgHeader = routeNames.includes("organization");

    // Disable event header for edit event form
    // Disable event header for catering checkin
    if (
      any(r =>
        [
          "editEventFormsForm",
          "eventCateringCheckin",
          "eventCredentialsCheckin",
          "ModuleForm"
        ].includes(r)
      )(routeNames)
    ) {
      showEventHeader = false;
    }

    if (showMyHeader) {
      return (
        <LayoutHeader>
          <MyHeader
            {...this.props}
            showModal={this.showModal}
            hideModal={this.hideModal}
          />
        </LayoutHeader>
      );
    }
    if (showEventHeader) {
      return (
        <LayoutHeader>
          <EventHeader
            {...this.props}
            showModal={this.showModal}
            hideModal={this.hideModal}
          />
        </LayoutHeader>
      );
    }

    // orgs header
    if (showOrgHeader) {
      return (
        <LayoutHeader>
          <OrganizationHeader {...this.props} />
        </LayoutHeader>
      );
    }

    return undefined;
  }

  render() {
    return (
      <div styleName="container">
        {this.renderHeader()}

        <LayoutContent routes={this.props.routes}>
          {React.cloneElement(this.props.children, {
            showSnackbar: this.showSnackbar,
            showModal: this.showModal,
            hideModal: this.hideModal,
            dispatch: this.props.dispatch
          })}
        </LayoutContent>

        <Snackbar />
        <Modal params={this.props.params} router={this.props.router} />
      </div>
    );
  }
}

PlatformLoaded.propTypes = {
  dispatch: PropTypes.func,
  routes: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  snackbar: PropTypes.object,
  user: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  getEvents: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  hideSnackbar: PropTypes.func.isRequired
};

export default PlatformLoaded;
