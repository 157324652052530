import React from "react";

import {
  put,
  all,
  take,
  takeEvery,
  fork,
  select,
  call
} from "redux-saga/effects";
import * as R from "ramda";

import { CREDENTIAL_TYPE_ID } from "utils/item-types";
import { TABLE_INSTANCE_ID, FIELD_IDS, FORM_ID } from "./constants";
import { ROW_ACTIONS } from "ui-kit/FieldTypes/constants";

import { getCredentials } from "redux/modules/user/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { makeFuture } from "utils/General/sagas";

import { actions as TableActions } from "ui-kit/Table/model";
import { actions, getters } from "./model";
import { registerError } from "redux/modules/errors/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal } from "redux/modules/modal/actions";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import ItemTypeModal from "components/Event/Settings/Catalog/Modals/ItemType";

import Api from "./api";
import itemTypesApi from "redux/modules/items/types/api";

import { getEditedField } from "./selectors";
import { actions as FormActions } from "ui-kit/Form/model";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventDetails = yield select(getEventDetails);

  return {
    credentials,
    eventDetails
  };
};

const loadData = function*({ payload = {} }) {
  try {
    if (!payload.refresh) {
      yield put(actions.setLoading(true));
    }
    const { credentials, eventDetails } = yield call(getParams);

    const result = yield call(Api.getContent, {
      credentials,
      eventId: eventDetails.id
    });

    yield all([
      put(actions.setInitialData(result.payload)),
      put(
        TableActions.setData(
          {
            canEditCells: false,
            columns: R.prop("columns")(result.payload),
            rows: R.prop("rows")(result.payload)
          },
          {
            meta: {
              instanceId: TABLE_INSTANCE_ID
            }
          }
        )
      )
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting content"
        }
      ])
    );
  } finally {
    if (!payload.refresh) {
      yield put(actions.setLoading(false));
    }
  }
};

const handleShowAddPassModal = function*({ id, clone = false }) {
  const handleModal = makeFuture();
  yield put(
    showModal({
      content: (
        <ItemTypeModal
          typeId={CREDENTIAL_TYPE_ID}
          itemId={id}
          clone={clone}
          onDone={handleModal.done}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleModal.onRealized);
};

const showAddPassModal = function*() {
  const result = yield call(handleShowAddPassModal, {});

  if (result) {
    yield put(showSnackbar({ message: "Content added", action: "OK" }));
  }
};

const showEditContentModal = function*({ payload: { row } }) {
  yield put(actions.setData(row.data));
  yield put(actions.setShowAddContentModal(true));
};

const onShowSelectPassesModal = function*({ payload: showModal }) {
  if (showModal) {
    try {
      yield put(actions.setLoadingItemTypes(true));
      const { credentials, eventDetails } = yield call(getParams);

      const result = yield call(
        itemTypesApi.getItemTypesByEvent,
        credentials,
        eventDetails.id
      );

      yield put(actions.setItemTypes(result.payload));
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error occurred while getting items"
          }
        ])
      );
    } finally {
      yield put(actions.setLoadingItemTypes(false));
    }
  }
};

const onSaveSelectPassesModal = function*({ payload }) {
  try {
    const { credentials, eventDetails } = yield call(getParams);

    const items = R.compose(
      R.map(itemId => ({
        id: itemId,
        enabled: true
      }))
    )(R.keys(payload));

    yield call(Api.updateContent, {
      credentials,
      eventId: eventDetails.id,
      data: { items }
    });
    yield call(loadData, { payload: { refresh: true } });
    yield put(showSnackbar({ message: "Passes updated", action: "OK" }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating items"
        }
      ])
    );
  } finally {
    yield put(actions.setShowSelectPassesModal(false));
  }
};

const goToCatalog = function*() {
  const { eventDetails } = yield call(getParams);
  window.location = `/event/${eventDetails.id}/settings/catalog/type/${CREDENTIAL_TYPE_ID}/items`;
};

const onHideAddContentModal = function*({ payload }) {
  if (!payload) {
    yield put(actions.setData({}));

    yield put(
      FormActions.clearValues(null, {
        meta: {
          instanceId: FORM_ID
        }
      })
    );
  }
};

const save = function*() {
  try {
    const { credentials, eventDetails } = yield call(getParams);

    const data = yield select(getters.data);

    const toSave = {
      videoUrl: data.video_url,
      videoThumbnailUrl: data.video_thumbnail_url,
      title: data.title,
      order: data.order,
      label: data.label,
      visible: data.visible,
      variantIds: []
    };

    yield call(Api.addContent, {
      credentials,
      eventId: eventDetails.id,
      data: toSave
    });

    yield put(showSnackbar({ message: "Content Added" }));
    yield put(actions.setShowAddContentModal(false));

    yield call(loadData, { payload: { refresh: true } });
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while saving content"
        }
      ])
    );
  }
};

const update = function*() {
  try {
    const { credentials, eventDetails } = yield call(getParams);

    const data = yield select(getters.data);

    const toSave = {
      id: data.id,
      videoUrl: data.video_url,
      videoThumbnailUrl: data.video_thumbnail_url,
      title: data.title,
      order: data.order,
      label: data.label,
      visible: data.visible,
      variantIds: []
    };

    yield call(Api.updateContent, {
      credentials,
      eventId: eventDetails.id,
      data: {
        content: [toSave]
      }
    });

    yield put(showSnackbar({ message: "Content Updated" }));
    yield put(actions.setShowAddContentModal(false));

    yield call(loadData, { payload: { refresh: true } });
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while saving content"
        }
      ])
    );
  }
};

const deleteContent = function*({ payload: { row } }) {
  try {
    const { credentials, eventDetails } = yield call(getParams);

    yield call(Api.deleteContent, {
      credentials,
      eventId: eventDetails.id,
      data: {
        contentIds: [row.id]
      }
    });

    yield put(showSnackbar({ message: "Content Deleted" }));
    yield call(loadData, { payload: { refresh: true } });
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while deleting content"
        }
      ])
    );
  }
};

const fieldUpdate = function*({ id, fieldId }) {
  const newValue = yield select(getEditedField, {
    instanceId: id,
    fieldId
  });

  const processedValue = R.propOr(newValue, fieldId, {
    [FIELD_IDS.THUMBNAIL_URL]: R.path([0, "url"], newValue),
    [FIELD_IDS.ORDER]: isNaN(newValue) ? null : newValue
  });

  yield put(actions.updateField({ field: fieldId, value: processedValue }));
};

const watchSave = function*() {
  yield takeEvery(actions.save.type, save);
};

const watchUpdate = function*() {
  yield takeEvery(actions.update.type, update);
};

const watchDelete = function*() {
  yield takeEvery(actions.deleteContent.type, deleteContent);
};

const watchTableActions = function*() {
  for (;;) {
    const action = yield take(TableActions.executeAction.type);
    const delegate = R.prop(action.payload.actionId, {
      [ROW_ACTIONS.OPEN_RECORD]: showEditContentModal,
      [ROW_ACTIONS.EDIT_RECORD]: showEditContentModal,
      [ROW_ACTIONS.DELETE_RECORD]: deleteContent
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, loadData);
};

const watchShowAddPassModal = function*() {
  yield takeEvery(actions.showAddPassModal.type, showAddPassModal);
};

const watchShowSelectPassesModal = function*() {
  yield takeEvery(
    actions.setShowSelectPassesModal.type,
    onShowSelectPassesModal
  );
};

const watchShowAddContentModal = function*() {
  yield takeEvery(actions.setShowAddContentModal.type, onHideAddContentModal);
};

const watchSaveSelectPassesModal = function*() {
  yield takeEvery(actions.saveSelectPassesModal.type, onSaveSelectPassesModal);
};

const watchGoToCatalog = function*() {
  yield takeEvery(actions.goToCatalog.type, goToCatalog);
};

const watchUpdateFields = function*() {
  for (;;) {
    const {
      meta: { instanceId, fieldId }
    } = yield take(FormActions.setFieldValue.type);
    if (instanceId === FORM_ID) {
      yield call(fieldUpdate, {
        id: instanceId,
        fieldId
      });
    }
  }
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchShowAddContentModal),
    fork(watchShowAddPassModal),
    fork(watchShowSelectPassesModal),
    fork(watchSaveSelectPassesModal),
    fork(watchGoToCatalog),
    fork(watchTableActions),
    fork(watchUpdateFields),
    fork(watchSave),
    fork(watchUpdate),
    fork(watchDelete)
  ]);
};

export default rootSaga;
