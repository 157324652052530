import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "../index";
import {
  Div,
  Text6,
  DarkTab,
  BigOutlineButton,
  RightIcon,
  BigShadedInput,
  SearchIcon,
  CollapsablePanelType1
} from "components/Base";
import { addS } from "utils/General";
import { FieldsTable } from "components/Event/Settings/Module/Tables";
import { getFieldGroups } from "../selectors";

const decorate = connect(
  (state, props) => ({
    fieldGroups: getFieldGroups(state, props)
  }),
  {
    setFilter: actions.setFilter
  }
);

const Fields = ({ onFieldsSearch, fieldGroups }) => (
  <Div display="row.flex-start.flex-start" width={1}>
    <Div pr={4} width={2 / 3} px={8}>
      <Div display="row.flex-start.center" pt={4} pb={4}>
        <BigShadedInput
          continuous
          LeftIcon={SearchIcon}
          flex={1}
          placeholder="Search fields"
          onChange={onFieldsSearch}
        />
        {/*}
        <BigOutlineButton ml={1} onClick={onCreateFieldGroup}>
          Create field group
        </BigOutlineButton>
        <BigFilledButton bg="altB5" ml={1} onClick={onCreateField}>
          Create field
        </BigFilledButton>
        */}
      </Div>
      {fieldGroups.map((fieldGroup, i) => {
        const fields = fieldGroup.fields;
        const numFields = fields.length || 0;
        const subText = `${numFields} field${addS(numFields)}`;
        return (
          <CollapsablePanelType1
            key={i}
            headingText={fieldGroup.name}
            subText={subText}
            forceCollapse={fieldGroup.forceCollapse}
            collapsedDefault={fieldGroup.collapsedDefault}
            menuItems={
              fieldGroup.isDefault
                ? null
                : [["Add Field", fieldGroup.onAddField]]
            }
          >
            <FieldsTable fields={fields} />
          </CollapsablePanelType1>
        );
      })}
    </Div>
  </Div>
);

const Layout = ({ fieldGroups, setFilter, handlers }) => (
  <Div width={1} height={1} style={{ overflowY: "auto" }}>
    <Div bb={1} bc="gray3" bg="white" display="row.flex-start.flex-end">
      <Div flex={1}>
        <Text6 pl={8} pt={6}>
          Schedules
        </Text6>

        <Div pt={3} pl={8}>
          <DarkTab key="fields" px={2} fs={3} active={true} onClick={() => {}}>
            Fields
          </DarkTab>
        </Div>
      </Div>

      <Div
        display="row.flex-start.center"
        style={{ alignSelf: "center" }}
        pr={4}
      >
        <BigOutlineButton
          RightIcon={RightIcon}
          onClick={handlers.goToMasterSchedule}
        >
          Go to master schedule
        </BigOutlineButton>
      </Div>
    </Div>
    <Div width={1} flex={1} p={4} display="row">
      <Fields
        {...{
          onFieldsSearch: setFilter,
          fieldGroups
        }}
      />
    </Div>
  </Div>
);

export default decorate(Layout);
