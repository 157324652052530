import React from "react";

import { getValue } from "../../utils";

import CheckBox from "material-ui/Checkbox";

const Checkbox = props => {
  const value = getValue(props);
  return <CheckBox checked={value} style={{ width: "auto" }} />;
};

export default Checkbox;
