import React, { useEffect } from "react";
import { connect } from "react-redux";

import { actions, getters } from "EventLight/Expo/Sales/model";

import {
  getSelectedItemsToShow,
  getItemsToShow,
  getItemsToShowModalTitle
} from "EventLight/Expo/Sales/selectors";

import MiniModalWrapper from "ui-kit/MiniModalWrapper";

import { SelectAndCreateVariants } from "ui-kit/MiniItemsSelector/View";
import { MODE_PRICE_SELECT } from "ui-kit/MiniItemsSelector/constants";

import { noop } from "utils/General";

const decorate = connect(
  (state, props) => ({
    iniSelectedFields: getSelectedItemsToShow(state, props),
    showModal: getters.selectingItemsToShow(state, props),
    fields: getItemsToShow(state, props),
    title: getItemsToShowModalTitle(state, props)
  }),
  {
    hideModal: () => actions.closeItemsToShowModal(),
    onDone: actions.setItemsToShowRequest,
    onClose: () => actions.closeItemsToShowModal(),
    init: () => actions.itemTypesRequest()
  }
);

const SelectItemsModal = ({
  fields = [],
  iniSelectedFields = [],
  onClose = noop,
  onDone = noop,
  init = noop,
  showModal = false,
  title = "Select Items To Show"
}) => {
  useEffect(() => {
    init();
  }, []);
  return (
    <MiniModalWrapper
      showModal={showModal}
      hideModal={() => onClose()}
      title={title}
      display="column.flex-start.stretch"
      width={845}
      height={600}
    >
      <SelectAndCreateVariants
        instanceId="selectItems"
        fields={fields}
        iniSelected={iniSelectedFields}
        iniClearSearch={true}
        onCancel={onClose}
        onDone={onDone}
        mode={MODE_PRICE_SELECT}
        enableReorder={true}
      />
    </MiniModalWrapper>
  );
};

export default decorate(SelectItemsModal);
