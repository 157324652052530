import React from "react";

import { Div } from "components/Base";

import { HEIGHTS } from "../constants";

import Modules from "./Modules";

const bodyHeight = 661 - (HEIGHTS.HEADER + HEIGHTS.FOOTER);

const Body = () => (
  <Div width={1} height={bodyHeight}>
    <Modules />
  </Div>
);

export default Body;
