import PropTypes from "prop-types";
import React, { Component } from "react";

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 40
  },
  message: {
    fontSize: 20,
    backgroundColor: "rgb(232, 231, 232)",
    borderRadius: 10,
    padding: "20px 60px"
  }
};

const EmptyState = ({ message }) => (
  <div style={styles.container}>
    <div style={styles.message}>{message}</div>
  </div>
);

EmptyState.propTypes = {
  message: PropTypes.string.isRequired
};
EmptyState.defaultProps = {
  message: "Loading..."
};

export default EmptyState;
