import { createHandlers, createModule } from "redux-mvc";

import selectRowsModule from "ui-kit/SelectRows";
import paginationModule from "ui-kit/PaginationBar";
import searchBarModule from "ui-kit/SearchBar";

const handlers = createHandlers({
  iniState: {
    showTable: ""
  },
  namespace: "PeopleTypePanel"
});

const module = createModule(handlers);

module.plugModule(selectRowsModule);
module.plugModule(paginationModule);
module.plugModule(searchBarModule);

const { actions, getters } = module;

export { actions, getters };

export default module;
