import React from "react";
import moment from "moment";
import { connect } from "react-redux";

import { actions } from "Orders/OrderModal";
import { getTransactions } from "Orders/OrderModal/selectors";
import { capitalizeFirst } from "utils/General";

import {
  Div,
  Text4,
  CreditCardIcon,
  MoneyIcon,
  CheckIcon
} from "components/Base";

import Panel from "ui-kit/Panel";

const decorate = connect(
  state => ({
    transactions: getTransactions(state)
  }),
  {
    viewInvoice: actions.viewInvoice
  }
);

const Transaction = ({
  kind,
  amount,
  amount_formatted,
  created_at,
  foreign_transaction_id,
  source
}) => (
  <Div display="row.flex-start" bb={1} bc="gray2" py={4} px={2}>
    <Div display="row.space-between.center" flex={1}>
      <Div display="column" width={275}>
        <Div display="row.flex-start.center" color="gray7" uppercase fs={1}>
          <CheckIcon color="success6" />
          {kind}
        </Div>
        <Div color="gray7">
          {amount_formatted} on{" "}
          {moment(new Date(created_at)).format("MMM. D, YYYY")}
        </Div>
      </Div>
      <Div display="column" flex={1}>
        <Div color="gray7" uppercase fs={1}>
          Source
        </Div>
        <Div color="gray7">{capitalizeFirst(source)}</Div>
      </Div>
      <Div display="column.flex-start.flex-end" width={200}>
        <Div color="gray7" uppercase fs={1}>
          Reference
        </Div>
        <Div color="gray5" uppercase fs={1}>
          {foreign_transaction_id}
        </Div>
      </Div>
    </Div>
  </Div>
);

const Transactions = ({ handlers, transactions }) => {
  return (
    <Panel display="column" px={8} py={3}>
      <Div display="column.flex-start" width={300}>
        <Div display="row.flex-start.center">
          <MoneyIcon size={32} mr={2} />
          <Text4 bold color="gray7">
            Transactions
          </Text4>
        </Div>
      </Div>
      {transactions.map(t => (
        <Transaction key={t.id} {...t} />
      ))}
    </Panel>
  );
};

export default decorate(Transactions);
