import * as R from "ramda";
import { createSelector } from "reselect";

import { getters } from "SalesPortal/Home";
import { user as getUser } from "redux/modules/user/selectors";
import * as FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { getTotalCartItems } from "SalesPortal/ItemsModal/selectors";
import Helpers from "utils/Global/Helpers";
import isValidValue from "utils/value-types/validations/is-valid";

export const getItemTypes = createSelector(
  getters.salesData,
  R.prop("items")
);

export const hasItemsForTypeId = (salesData, typeId) =>
  R.compose(
    R.find(R.propEq("type_id", typeId)),
    R.prop("items")
  )(salesData);

export const hasItemsForId = (salesData, id) => {
  return R.compose(
    R.find(R.propEq("id", id)),
    R.prop("items")
  )(salesData);
};

export const hasItemsForButtonType = createSelector(
  getters.salesData,
  (_, { id }) => id,
  (salesData, id) => Boolean(hasItemsForId(salesData, id))
);

export const getCurrency = createSelector(
  getters.salesData,
  R.prop("currency")
);

export const getIsLoggedIn = createSelector(
  getUser,
  user => {
    if (!user || !user.id) {
      return false;
    }
    return true;
  }
);

export const getUserId = createSelector(
  getUser,
  user => {
    if (!user || !user.id) {
      return null;
    }
    return user.id;
  }
);

export const getUsername = createSelector(
  getUser,
  user => {
    if (!user || !user.id) {
      return "";
    }
    return R.compose(
      R.join(" "),
      u => [R.prop("fname")(u), R.prop("lname")(u)]
    )(user);
  }
);

export const getIsPaymentEnabled = createSelector(
  getters.salesData,
  R.prop("payments_enabled")
);

export const getRequiredFields = createSelector(
  getters.salesData,
  getters.values,
  getters.fieldErrors,
  (salesData, values, fieldErrors) => {
    const formatFields = moduleId =>
      R.compose(
        R.map(f => ({
          ...f.field,
          is_required: f.is_required,
          value: values[f.field.id],
          valid: Boolean(
            !fieldErrors[f.field.id] &&
              isValidValue(values[f.field.id], f.field.type)
          )
        })),
        R.filter(R.pathEq(["field", "module_id"], moduleId)),
        R.propOr([], "fields")
      )(salesData);

    return [
      {
        id: FIELD_IDS.CONTACTS.FIRST_NAME,
        name: "First Name",
        type: "text",
        settings: {},
        is_required: true,
        valid: Boolean(
          !fieldErrors[FIELD_IDS.CONTACTS.FIRST_NAME] &&
            isValidValue(values[FIELD_IDS.CONTACTS.FIRST_NAME], "text")
        ),
        value: values[FIELD_IDS.CONTACTS.FIRST_NAME]
      },
      {
        id: FIELD_IDS.CONTACTS.LAST_NAME,
        name: "Last Name",
        type: "text",
        settings: {},
        is_required: true,
        valid: Boolean(
          !fieldErrors[FIELD_IDS.CONTACTS.LAST_NAME] &&
            isValidValue(values[FIELD_IDS.CONTACTS.LAST_NAME], "text")
        ),
        value: values[FIELD_IDS.CONTACTS.LAST_NAME]
      },
      {
        id: FIELD_IDS.CONTACTS.EMAIL,
        name: "Email",
        type: "email",
        settings: {},
        is_required: true,
        valid: Boolean(
          !fieldErrors[FIELD_IDS.CONTACTS.EMAIL] &&
            isValidValue(values[FIELD_IDS.CONTACTS.EMAIL], "email")
        ),
        value: values[FIELD_IDS.CONTACTS.EMAIL]
      },
      {
        id: FIELD_IDS.ACCOUNTS.NAME,
        name: "Company Name",
        type: "text",
        settings: {},
        is_required: true,
        valid: Boolean(
          !fieldErrors[FIELD_IDS.ACCOUNTS.NAME] &&
            isValidValue(values[FIELD_IDS.ACCOUNTS.NAME], "text")
        ),
        value: values[FIELD_IDS.ACCOUNTS.NAME]
      },
      ...formatFields(STANDARD_MODULE_IDS.contacts.id),
      ...formatFields(STANDARD_MODULE_IDS.accounts.id),
      ...formatFields(STANDARD_MODULE_IDS.orders.id)
    ].filter(f => f.is_required);
  }
);

export const getFields = createSelector(
  getters.salesData,
  getters.touchedValues,
  getters.fieldErrors,
  getters.values,
  getters.paymentValues,
  (_, props) => R.prop("handlers")(props),
  (salesData, touchedValues, fieldErrors, values, paymentValues, handlers) => {
    const formatFields = moduleId =>
      R.compose(
        R.map(f => ({
          ...f.field,
          is_required: f.is_required,
          value: values[f.field.id],
          valid: touchedValues[f.field.id]
            ? Boolean(
                !fieldErrors[f.field.id] && f.is_required
                  ? isValidValue(values[f.field.id], f.field.type)
                  : true
              )
            : true,
          onChange: handlers
            ? value =>
                handlers.updateValue({
                  fieldId: f.field_id,
                  value
                })
            : undefined
        })),
        R.sortBy(R.prop("order")),
        R.filter(R.pathEq(["field", "module_id"], moduleId)),
        R.propOr([], "fields")
      )(salesData);

    return {
      contact: [
        {
          id: FIELD_IDS.CONTACTS.FIRST_NAME,
          name: "First Name",
          type: "text",
          settings: {},
          is_required: true,
          value: values[FIELD_IDS.CONTACTS.FIRST_NAME],
          valid: touchedValues[FIELD_IDS.CONTACTS.FIRST_NAME]
            ? Boolean(
                !fieldErrors[FIELD_IDS.CONTACTS.FIRST_NAME] &&
                  isValidValue(values[FIELD_IDS.CONTACTS.FIRST_NAME], "text")
              )
            : true,
          onChange: handlers
            ? value =>
                handlers.updateValue({
                  fieldId: FIELD_IDS.CONTACTS.FIRST_NAME,
                  value
                })
            : undefined
        },
        {
          id: FIELD_IDS.CONTACTS.LAST_NAME,
          name: "Last Name",
          type: "text",
          settings: {},
          is_required: true,
          value: values[FIELD_IDS.CONTACTS.LAST_NAME],
          valid: touchedValues[FIELD_IDS.CONTACTS.LAST_NAME]
            ? Boolean(
                !fieldErrors[FIELD_IDS.CONTACTS.LAST_NAME] &&
                  isValidValue(values[FIELD_IDS.CONTACTS.LAST_NAME], "text")
              )
            : true,
          onChange: handlers
            ? value =>
                handlers.updateValue({
                  fieldId: FIELD_IDS.CONTACTS.LAST_NAME,
                  value
                })
            : undefined
        },
        {
          id: FIELD_IDS.CONTACTS.EMAIL,
          name: "Email",
          type: "email",
          settings: {},
          is_required: true,
          value: values[FIELD_IDS.CONTACTS.EMAIL],
          valid: touchedValues[FIELD_IDS.CONTACTS.EMAIL]
            ? Boolean(
                !fieldErrors[FIELD_IDS.CONTACTS.EMAIL] &&
                  isValidValue(values[FIELD_IDS.CONTACTS.EMAIL], "email")
              )
            : true,
          onChange: handlers
            ? value =>
                handlers.updateValue({
                  fieldId: FIELD_IDS.CONTACTS.EMAIL,
                  value
                })
            : undefined
        },
        ...formatFields(STANDARD_MODULE_IDS.contacts.id)
      ],
      account: [
        {
          id: FIELD_IDS.ACCOUNTS.NAME,
          name: "Company Name",
          type: "text",
          settings: {},
          is_required: true,
          value: values[FIELD_IDS.ACCOUNTS.NAME],
          valid: touchedValues[FIELD_IDS.ACCOUNTS.NAME]
            ? Boolean(
                !fieldErrors[FIELD_IDS.ACCOUNTS.NAME] &&
                  isValidValue(values[FIELD_IDS.ACCOUNTS.NAME], "text")
              )
            : true,
          onChange: handlers
            ? value =>
                handlers.updateValue({
                  fieldId: FIELD_IDS.ACCOUNTS.NAME,
                  value
                })
            : undefined
        },
        ...formatFields(STANDARD_MODULE_IDS.accounts.id)
      ],
      order: formatFields(STANDARD_MODULE_IDS.orders.id),
      payment: {
        cardNumber: {
          value: paymentValues.cardNumber !== false,
          onChange: handlers
            ? ({ complete }) => {
                if (complete) {
                  handlers.updatePaymentValue({
                    fieldId: "cardNumber",
                    value: true
                  });
                } else {
                  handlers.updatePaymentValue({
                    fieldId: "cardNumber",
                    value: false
                  });
                }
              }
            : undefined
        },
        name: {
          valid: touchedValues.name
            ? paymentValues.name && paymentValues.name.length
            : true,
          value: paymentValues.name,
          onChange: handlers
            ? value =>
                handlers.updatePaymentValue({
                  fieldId: "name",
                  value
                })
            : undefined
        },
        expires: {
          value: paymentValues.expires !== false,
          onChange: handlers
            ? ({ complete }) => {
                if (complete) {
                  handlers.updatePaymentValue({
                    fieldId: "expires",
                    value: true
                  });
                } else {
                  handlers.updatePaymentValue({
                    fieldId: "expires",
                    value: false
                  });
                }
              }
            : undefined
        },
        cvc: {
          value: paymentValues.cvc !== false,
          onChange: handlers
            ? ({ complete }) => {
                if (complete) {
                  handlers.updatePaymentValue({
                    fieldId: "cvc",
                    value: true
                  });
                } else {
                  handlers.updatePaymentValue({
                    fieldId: "cvc",
                    value: false
                  });
                }
              }
            : undefined
        },
        zip: {
          value: paymentValues.zip !== false,
          onChange: handlers
            ? ({ complete }) => {
                if (complete) {
                  handlers.updatePaymentValue({
                    fieldId: "zip",
                    value: true
                  });
                } else {
                  handlers.updatePaymentValue({
                    fieldId: "zip",
                    value: false
                  });
                }
              }
            : undefined
        }
      }
    };
  }
);

export const isCheckoutValid = createSelector(
  getters.salesData,
  getters.values,
  getters.paymentValues,
  getTotalCartItems,
  getIsPaymentEnabled,
  getRequiredFields,
  (
    salesData,
    values,
    paymentValues,
    totalItemsInCart,
    isPaymentEnabled,
    requiredFields
  ) => {
    const isPaymentValid = isPaymentEnabled
      ? Boolean(
          paymentValues.cardNumber &&
            paymentValues.expires &&
            paymentValues.cvc &&
            paymentValues.zip
        )
      : true;

    const areRequiredFieldsValid = !requiredFields
      .map(f => f.valid)
      .filter(valid => !valid).length;

    return Boolean(
      totalItemsInCart &&
        areRequiredFieldsValid &&
        values[FIELD_IDS.CONTACTS.FIRST_NAME] &&
        values[FIELD_IDS.CONTACTS.FIRST_NAME].value.length &&
        values[FIELD_IDS.CONTACTS.LAST_NAME] &&
        values[FIELD_IDS.CONTACTS.LAST_NAME].value.length &&
        values[FIELD_IDS.CONTACTS.EMAIL] &&
        values[FIELD_IDS.CONTACTS.EMAIL].value.length &&
        Helpers.isValidEmail(values[FIELD_IDS.CONTACTS.EMAIL].value) &&
        values[FIELD_IDS.ACCOUNTS.NAME] &&
        values[FIELD_IDS.ACCOUNTS.NAME].value.length &&
        isPaymentValid
    );
  }
);
