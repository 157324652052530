import Helpers from "utils/Global/Helpers";
import * as R from "ramda";

export default {
  getDownloadUrl: ({
    credentials,
    userId,
    orgId,
    eventId,
    moduleId,
    submissionId,
    blockId,
    accountId,
    recordTypeId
  }) => {
    const blockUrl = R.or(R.isEmpty(blockId), R.isEmpty(submissionId))
      ? ""
      : `&submissionRecordId=${submissionId}&blockId=${blockId}`;

    const portalUrl = R.or(R.isEmpty(accountId), R.isEmpty(recordTypeId))
      ? ""
      : `&accountId=${accountId}&recordTypeId=${recordTypeId}`;

    return new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules/${moduleId}/export?userId=${userId}&contentType=csv&orgId=${orgId}&eventId=${eventId}${blockUrl}${portalUrl}`,
        credentials,
        success,
        error
      });
    });
  },
  setImportFile: ({ moduleId, data, credentials }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/modules/${moduleId}/import/mapping`,
        credentials,
        data,
        success,
        error
      });
    }),
  postCsvData: ({ moduleId, data, credentials }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/modules/${moduleId}/import`,
        credentials,
        data,
        success,
        error
      });
    }),
  getCsvUploadStatus: ({ moduleId, importId, credentials }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules/${moduleId}/import/${importId}`,
        credentials,
        success,
        error
      });
    })
};
