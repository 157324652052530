/* eslint-disable no-underscore-dangle */
import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import * as R from "ramda";
import { EDIT_MODAL_DATA } from "../constants";
import { apiCall } from "App/Data/sagas";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { registerError } from "redux/modules/errors/actions";
import { actions, getters } from "../model";
import { getters as MiniItemsGetters } from "ui-kit/MiniItemsSelector";
import { getters as FormGetters } from "ui-kit/Form/model";
import { formatDateTimezone } from "./index";
import { getDataOrdered } from "../utils";

const getFileRequests = function*({ payload: showLoading }) {
  const eventId = yield select(getEventId);
  const recordTypeId = yield select(getters.selectedTypeId);

  try {
    if (showLoading) {
      yield put(actions.setLoadingDocumentRequests(true));
    }

    const [{ payload }, { payload: assignedDocumentRequests }] = yield all([
      call(apiCall, {
        method: "get",
        url: `/file-requests/event/${eventId}`
      }),
      call(apiCall, {
        method: "get",
        url: `/hub/admin/event/${eventId}/type/${recordTypeId}/document-requests`
      })
    ]);

    yield put(
      actions.setInitialDocumentRequestsData({
        documentRequests: R.propOr([], "allItems", payload),
        assignedDocumentRequests: assignedDocumentRequests
      })
    );

    yield put(actions.setLoadingDocumentRequests(false));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred white loading document requests"
        }
      ])
    );
  }
};

const goToDocumentRequests = function*() {
  const eventId = yield select(getEventId);

  yield call(
    window.open,
    `${window.__LENND_APP_URL__}/event-light/${eventId}/file-requests`,
    "_blank"
  );
};

const deleteAssignedDocumentRequest = function*({
  payload: documentRequestId
}) {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "delete",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/document-request/${documentRequestId}`
    });

    yield put(actions.getFileRequests());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error ocurred deleting document request"
        }
      ])
    );
  }
};

export const toggleRequiredDocument = function*({ fieldId, required }) {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/document-request/${fieldId}`,
      data: {
        required
      }
    });

    yield put(actions.getFileRequests());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error ocurred updating assigned document"
        }
      ])
    );
  }
};

const updateAssignedDocuments = function*() {
  try {
    const eventId = yield select(getEventId);
    const assignedIds = yield select(MiniItemsGetters.selectedItems);
    const recordTypeId = yield select(getters.selectedTypeId);
    const assignedItems = yield select(getters.assignedDocumentRequests);

    const items = getDataOrdered({
      assignedIds,
      assignedItems
    });

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/document-requests`,
      data: { items }
    });

    yield all([
      put(actions.getFileRequests()),
      put(actions.setShowAssignModal(false))
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error updating assigned documents"
        }
      ])
    );
  }
};

const reorderDocuments = function*({ payload: orderedItems }) {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/document-requests`,
      data: {
        items: orderedItems.map(({ id }, order) => ({ id, order }))
      }
    });

    yield put(actions.getFileRequests());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error updating assigned documents"
        }
      ])
    );
  }
};

const updateDocumentModalData = function*() {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);
    const fieldId = yield select(getters.selectedModalId);
    const fields = yield select(FormGetters.fields);
    const closeDate =
      R.pathOr(null, [EDIT_MODAL_DATA.DUE_DATE, "value"], fields) || null;
    const formattedDate = yield call(formatDateTimezone, closeDate);

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/document-request/${fieldId}`,
      data: {
        alias: R.pathOr("", [EDIT_MODAL_DATA.ALIAS, "value"], fields),
        [EDIT_MODAL_DATA.REQUIRED]: R.pathOr(
          false,
          [EDIT_MODAL_DATA.REQUIRED, "value"],
          fields
        ),
        close_date: formattedDate,
        order: R.pathOr(0, [EDIT_MODAL_DATA.ORDER, "value"], fields)
      }
    });

    yield all([
      put(actions.getFileRequests(true)),
      put(actions.closeUpdateModal())
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error ocurred updating assigned document"
        }
      ])
    );
  }
};

const watchGetFileRequests = function*() {
  yield takeEvery(actions.getFileRequests.type, getFileRequests);
};

const watchGoToDocumentRequests = function*() {
  yield takeEvery(actions.goToDocumentRequests.type, goToDocumentRequests);
};

const watchDeleteAssignedDocumentRequest = function*() {
  yield takeEvery(
    actions.deleteAssignedDocumentRequest.type,
    deleteAssignedDocumentRequest
  );
};

const watchUpdateAssignedDocuments = function*() {
  yield takeEvery(
    actions.updateAssignedDocuments.type,
    updateAssignedDocuments
  );
};

const watchUpdateDocumentModalData = function*() {
  yield takeEvery(
    actions.updateDocumentModalData.type,
    updateDocumentModalData
  );
};

const watchReorderDocuments = function*() {
  yield takeEvery(actions.reorderDocuments.type, reorderDocuments);
};

const rootSaga = function*() {
  yield all([
    fork(watchGetFileRequests),
    fork(watchGoToDocumentRequests),
    fork(watchDeleteAssignedDocumentRequest),
    fork(watchUpdateAssignedDocuments),
    fork(watchUpdateDocumentModalData),
    fork(watchReorderDocuments)
  ]);
};

export default rootSaga;
