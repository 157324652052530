import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import CircularProgress from "material-ui/CircularProgress";

import getLayout from "./Layouts";

export const Loading = CSSModules(
  () => (
    <div styleName="progress">
      <CircularProgress color="#ccc" />
    </div>
  ),
  css
);

@CSSModules(css)
class AddRecordModal extends Component {
  render() {
    const Layout = getLayout(this.props.moduleId, this.props.typeId);
    return <Layout {...this.props} />;
  }
}

AddRecordModal.defaultProps = {
  values: [],
  references: []
};

export default AddRecordModal;
