import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Provider } from "react-redux";
import thunkMiddleware from "redux-thunk";
import * as R from "ramda";
import { Div } from "components/Base";

import salesPortal, { actions } from "SalesPortal/Home";
import SalesPortalSagas from "SalesPortal/Home/sagas";

import Modal from "components/Global/Modal";
import EnvironmentTag from "components/Platform/EnvironmentTag";
import Snackbar from "components/Global/Snackbar";
import Loading from "SalesPortal/Home/View/Loading";
import Layout from "./Layout";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import salesPortalItemsModal from "SalesPortal/ItemsModal";

import { showModal, hideModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { snackbar } from "redux/modules/snackbar/selectors";

salesPortal.plugModule(salesPortalItemsModal);
salesPortal.setRootSaga(SalesPortalSagas);

const decorate = R.compose(
  withRouter,
  connect(
    state => ({
      snackbar: snackbar(state)
    }),
    {
      showModal,
      hideModal,
      showSnackbar
    }
  )
);

class SalesPortal extends Component {
  static contextTypes = {
    store: PropTypes.shape({
      subscribe: PropTypes.func.isRequired,
      dispatch: PropTypes.func.isRequired,
      getState: PropTypes.func.isRequired
    })
  };

  constructor(props, context) {
    super(props);
    const { store } = salesPortal.createStore({
      middleware: [thunkMiddleware],
      globalStore: context.store,
      observedDomains: [
        "user",
        "event",
        "modal",
        "SalesPortalItemsModal",
        "SalesPortal",
        "snackbar"
      ],
      dispatchToGlobal: R.compose(
        R.not,
        R.test(new RegExp(salesPortal.namespace)),
        R.prop("type")
      )
    });
    this.store = store;

    salesPortal.run();
  }

  componentDidMount() {
    this.store.dispatch(
      actions.getPortalDetails({
        eventId: this.props.params.eventId,
        getUser: true
      })
    );

    if (window.Intercom) {
      window.Intercom("update", {
        hide_default_launcher: true
      });
    }
  }

  openOrderModal = () => {
    this.props.showModal({
      content: <Div>Table</Div>,
      wrapper: ModalWrapper
    });
  };

  updateValue = ({ fieldId, value }) => {
    this.store.dispatch(
      actions.updateValue({
        fieldId,
        value
      })
    );
  };

  updatePaymentValue = ({ fieldId, value }) => {
    this.store.dispatch(
      actions.updatePaymentValue({
        fieldId,
        value
      })
    );
  };

  render() {
    return (
      <Provider store={this.store}>
        <Loading>
          <Layout
            {...this.props}
            handlers={{
              updateValue: this.updateValue,
              updatePaymentValue: this.updatePaymentValue,
              openOrderModal: this.openOrderModal
            }}
          />
          <Modal params={this.props.params} router={this.props.router} />
          <Snackbar />
          <EnvironmentTag />
        </Loading>
      </Provider>
    );
  }
}

export default decorate(SalesPortal);
