import React from "react";

import { connect } from "react-redux";

import {
  Div,
  Text2,
  Text1,
  BigFilledInput,
  BigFilledButton,
  SearchIcon,
  BigOutlineButton
} from "components/Base";

import { getters, actions } from "../index";

import ItemQuestions from "./ItemQuestions";

import QuestionsSets from "./QuestionsSets";

const decorate = connect(
  state => ({
    questionsFilter: getters.questionsFilter(state)
  }),
  {
    setQuestionsFilter: actions.setQuestionsFilter
  }
);

const Layout = ({ questionsFilter, setQuestionsFilter, handlers }) => (
  <Div
    width={800}
    height={1}
    p={3}
    pl={6}
    style={{
      paddingBottom: 100
    }}
  >
    <Div bg="altB1" p={4} bra={2}>
      <Text2 bold>
        Item Questions allow you to collect answers specific to a requested item
      </Text2>
      <Text1 mt={1}>
        Create the questions you'd like to ask when people request certain items
        or categories of items
      </Text1>
      {/* <Text1>
        Questions CAN be configured to modify an item's price - by settings a
        price on a question's options.
      </Text1> */}
    </Div>
    <Div display="row.flex-start.center" my={3}>
      <BigFilledInput
        placeholder="Search questions and sets..."
        LeftIcon={SearchIcon}
        flex={1}
        bg="neutral1"
        color="neutral6"
        value={questionsFilter}
        onChange={value => setQuestionsFilter(value)}
        continuous
      />
      <BigOutlineButton
        ml={3}
        onClick={() => handlers.showAddQuestionSetModal()}
      >
        New Question Set
      </BigOutlineButton>
      <BigFilledButton
        bg="altB4"
        ml={2}
        onClick={() => handlers.showAddQuestionModal()}
      >
        New Question
      </BigFilledButton>
    </Div>
    <ItemQuestions handlers={handlers} />
    <Div bg="altB1" p={4} bra={2} my={3}>
      <Text2 bold>
        Questions Sets allow you to apply many questions to items
      </Text2>
      <Text1 mt={1}>
        You can choose the question order and assign common questions to items &
        categories all at once
      </Text1>
    </Div>
    <QuestionsSets handlers={handlers} />
  </Div>
);

export default decorate(Layout);
