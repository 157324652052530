import PropTypes from "prop-types";
import React from "react";
import Separator from "./Separator";
import ListButton from "./ListButton";
import SearchResults from "./SearchResults";
import NoMatches from "./NoMatches";
import Fuse from "fuse.js";
import constructName from "../../utils/construct-name";
import constructPlaceholder from "../../utils/construct-placeholder";
import isPerson from "../../utils/is-person";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const searchOptions = {
  keys: ["first", "last", "display"],
  threshold: 0.3
};

const addDisplay = references =>
  references.map(reference => ({
    ...reference,
    display: isPerson(reference)
      ? constructName(reference.first, reference.last)
      : constructPlaceholder(reference.placeholder, reference.count)
  }));

const searchPeople = (references, search) => {
  const fuse = new Fuse(references, searchOptions);
  return fuse.search(search);
};

const ListScene = ({
  changeScene,
  isFetching,
  onSelect,
  references,
  referenceValues,
  search,
  types
}) => {
  const results = addDisplay(references);
  const matchedReferences = search ? searchPeople(results, search) : results;

  return (
    <div styleName="listContainer">
      {matchedReferences.length ? (
        <div>
          <div styleName="optionsList">
            <SearchResults
              isFetching={isFetching}
              search={search}
              references={matchedReferences}
              referenceValues={referenceValues}
              onSelect={onSelect}
            />
          </div>
          <Separator />
          {types.includes("user") ? (
            <div>
              <ListButton
                title="Add new person"
                description={search ? `${search}...` : "First & last name"}
                onClick={() => changeScene("person", "forward")}
              />
            </div>
          ) : (
            ""
          )}
          {types.includes("placeholder") ? (
            <div>
              <Separator />
              <ListButton
                title="Add person placeholder"
                description="ie: Crew Member #1"
                onClick={() => changeScene("placeholder", "forward")}
              />
              <ListButton
                title="Add group placeholder"
                description="ie: Security Team (25)"
                onClick={() => changeScene("group", "forward")}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <NoMatches
          addPerson={() => changeScene("person", "forward")}
          search={search}
        />
      )}
    </div>
  );
};

ListScene.propTypes = {
  changeScene: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  references: PropTypes.array.isRequired,
  referenceValues: PropTypes.object.isRequired,
  search: PropTypes.string.isRequired,
  types: PropTypes.array.isRequired
};

export default CSSModules(ListScene, css);
