import React from "react";
import { SubNavWrapper, SubNavLink } from "EventLight/Common/SubNav";
import { TAB_IDS } from "./constants";
import { withRouter } from "react-router";

const SubNav = ({ selectedView, router, params, tabs = [] }) => (
  <SubNavWrapper tabs={tabs} supportId="forms">
    <SubNavLink
      name="Results"
      active={selectedView === TAB_IDS.RESULTS}
      onClick={() =>
        router.push({
          pathname: `/event-light/${params.eventId}/forms/${params.formId}/results`
        })
      }
    />
    <SubNavLink
      name="Settings"
      active={selectedView === TAB_IDS.SETTINGS}
      onClick={() =>
        router.push({
          pathname: `/event-light/${params.eventId}/forms/${params.formId}/settings`
        })
      }
    />
  </SubNavWrapper>
);

export default withRouter(SubNav);
