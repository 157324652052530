import React from "react";
import Body from "OrgLight/Events/View/Body";
import SubNav from "./SubNav";
import PageTitle from "OrgLight/Common/PageTitle";

const Layout = ({ handlers }) => (
  <>
    <PageTitle titles={["Events"]} />
    <SubNav />
    <Body handlers={handlers} />
  </>
);

export default Layout;
