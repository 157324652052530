import React from "react";
import moment from "moment";
import {
  Div,
  Text1,
  DarkCloseButton,
  UpDownButtons,
  HorzInfoList,
  LoadingIcon,
  QuarterLeftTemplate,
  NoShrink,
  YFill,
  Fill,
  InitialTitleTagDisplay
} from "components/Base";

const Frame = ({ children }) => (
  <Div m={5} style={{ overflow: "none" }} display={"row.center.center"}>
    <Div
      display="row.flex-start.stretch"
      bra={2}
      shadow={2}
      bg="white"
      style={{
        height: 600,
        maxWidth: 1200,
        width: "100%",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxHeight: "calc(100% - 100px)"
      }}
      onMouseDown={e => e.stopPropagation()}
    >
      {children}
    </Div>
  </Div>
);

const templatePadding = {
  px: 5,
  py: 3
};

const RecordView = ({
  loading,
  title = "Record",
  recordColor = "teal4",
  recordDisplayId,
  createdAt,
  lastUpdatedAt,
  showInitials,
  recordType,

  hideModal,
  goToPreviousRecord,
  goToNextRecord,

  middleContentBg,

  leftContent,
  leftContentProps = {},
  topBarContent,
  middleContent,
  rightContent
}) => {
  if (loading) {
    return (
      <Frame>
        <Div display="row.center.center" flex={1}>
          <LoadingIcon size={60} color="neutral4" />
        </Div>
      </Frame>
    );
  }
  return (
    <Frame>
      <QuarterLeftTemplate
        wrapperProps={{ flex: 1 }}
        leftProps={{
          br: 1,
          bc: "neutral3"
        }}
        leftChildren={[
          <NoShrink
            key={1}
            {...templatePadding}
            bg={recordColor}
            height={120}
            display="column.space-between.stretch"
          >
            <Div display="row.flex-start.center">
              <UpDownButtons
                goUp={goToPreviousRecord}
                goDown={goToNextRecord}
                flex={1}
              />
              <Text1 color="white">
                Last updated {moment(lastUpdatedAt).fromNow()}
              </Text1>
            </Div>
            <InitialTitleTagDisplay
              showInitials={showInitials}
              color={recordColor}
              title={title}
              tag={recordType}
            />
          </NoShrink>,
          <YFill
            key={2}
            {...templatePadding}
            {...leftContentProps}
            bg={"#fcfcfc"}
          >
            {leftContent}
          </YFill>,
          <NoShrink key={3} {...templatePadding} bg={"#fcfcfc"}>
            <HorzInfoList
              info={[
                recordDisplayId
                  ? { key: "Record ID", value: recordDisplayId }
                  : null,
                { key: "Created", value: moment(createdAt).fromNow() }
              ].filter(v => v)}
            />
          </NoShrink>
        ]}
        rightChildren={[
          <NoShrink
            key={1}
            display="row.space-between.center"
            pb={0}
            bc={"neutral3"}
            bb={1}
            height={40}
            px={5}
          >
            {topBarContent}
            <DarkCloseButton size={20} onClick={hideModal} />
          </NoShrink>,
          <Fill
            key={2}
            display="row.flex-start.stretch"
            style={{
              overflowY: "auto"
            }}
          >
            <Div
              bg={middleContentBg}
              width={rightContent ? 2 / 3 : 1}
              bc="neutral3"
              br={rightContent ? 1 : 0}
              p={5}
              style={{
                overflowY: "scroll",
                height: "inherit"
              }}
            >
              {middleContent}
            </Div>
            {rightContent && (
              <Div height={1} width={1 / 3}>
                {rightContent}
              </Div>
            )}
          </Fill>
        ]}
      />
    </Frame>
  );
};

export default RecordView;
