import React from "react";

import { Text1, Div, SettingsIcon } from "components/Base";

import Sidebar, { getLinks } from "ui-kit/Sidebar/View";
import { isOpened } from "ui-kit/Sidebar/selectors";
import CollapsablePanel from "ui-kit/CollapsiblePanel";

import { connect } from "react-redux";
import { actions } from "ui-kit/Sidebar";

import { pure } from "utils/General";

const decorate = connect(
  state => ({
    opened: isOpened(state, { id: "setup" })
  }),
  { onClick: () => actions.toggleMenu("setup") }
);

// @NOTE: use oppacity, (STEVE)
const SetupPanel = decorate(({ link, opened = true, onClick }) => (
  <CollapsablePanel
    arrowColor="rgba(242, 241, 244, 0.3)"
    heading={
      <Div display="row.flex-start" width={1}>
        <SettingsIcon sizeWFS={2} color="rgba(242, 241, 244, 0.3)" />{" "}
        <Text1 color="rgba(242, 241, 244, 0.3)" bold ml={1}>
          SETTINGS
        </Text1>
      </Div>
    }
    bg="transparent"
    opened={opened}
    mt={4}
    onClick={onClick}
  >
    {getLinks(link, 1)}
  </CollapsablePanel>
));

const PassesSidebar = ({ links, setupLink }) => (
  <Sidebar
    links={links}
    title="Passes"
    footer={(setupLink.links.length && <SetupPanel link={setupLink} />) || null}
    iniOpenedMenues={["setup", "PassesContainer"]}
  />
);

export default pure(PassesSidebar);
