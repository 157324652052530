import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Message = ({ children }) => <div styleName="message">{children}</div>;

Message.propTypes = {
  children: PropTypes.object.isRequired
};

export default CSSModules(Message, css);
