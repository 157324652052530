import { get } from "lodash";

export const isFetching = (state, reportId) =>
  get(state, ["accounts", "reports", "fetching", reportId], false);
export const reports = (state, reportId) =>
  get(state, ["accounts", "reports", "reports", reportId], {
    accounts: [],
    fields: [],
    preferences: {}
  });
export const reportRows = (state, reportId) =>
  reports(state, reportId).accounts;
export const reportFields = (state, reportId) =>
  reports(state, reportId).fields;
export const preferences = (state, reportId) =>
  reports(state, reportId).preferences;
