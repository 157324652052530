import React, { useEffect } from "react";
import * as R from "ramda";
import { Div, BigFilledButton, BigOutlineButton } from "components/Base";
import { connect } from "react-redux";
import { PhoneInput, WithFormInstanceProvider } from "ui-kit/Form/View";
import { FORM_INSTANCE_ID, PHONE_FIELD_ID } from "../constants";
import { getUserPhoneNumber } from "redux/modules/user/selectors";

import * as selectors from "../selectors";

import { actions } from "../model";

import {
  ButtonGroup,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";

const decorate = R.compose(
  connect(
    (state, props) => ({
      initPhone: getUserPhoneNumber(state, props),
      isSaveDisabled: selectors.isSaveDisabled(state, props)
    }),
    {
      hideModal: actions.hideModal,
      save: actions.save,
      init: actions.init
    }
  ),
  WithFormInstanceProvider(R.always(FORM_INSTANCE_ID))
);

const Body = ({ init, hideModal, save, isSaveDisabled, initPhone }) => {
  useEffect(() => {
    init({
      phone: initPhone
    });
  }, [init]);

  return (
    <Div display="column" height={1}>
      <Div
        fw={3}
        pl={8}
        pr={8}
        pb={4}
        pt={4}
        bc="gray2"
        bb={1}
        color="gray7"
        fs={5}
      >
        Enable SMS Notifications
      </Div>
      <Div
        display="column"
        pl={8}
        pr={8}
        pb={5}
        pt={5}
        style={{ flexShrink: 0 }}
      >
        <Div>
          <Div fs={4} mb={2} color="neutral9">
            Mobile Phone Number:
          </Div>
          <PhoneInput fieldId={PHONE_FIELD_ID} iniValue={initPhone} autoFocus />
        </Div>
        <Div fs={1} color="gray5" mb={3}>
          Standard text message charges apply
        </Div>

        <Div display="row.flex-start.center">
          <ButtonGroup>
            <BigFilledButton color="white" bg="altB5" onClick={save}>
              Save
            </BigFilledButton>

            <BigOutlineButton ml={2} onClick={hideModal}>
              Cancel
            </BigOutlineButton>
          </ButtonGroup>
        </Div>
      </Div>
    </Div>
  );
};

export default decorate(Body);
