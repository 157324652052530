import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Checkbox from "material-ui/Checkbox";

@CSSModules(css)
class Recipient extends Component {
  @autobind
  toggleRecipient(e, val) {
    return this.props.toggleRecipient(val, this.props.email);
  }

  render() {
    const { email, selected } = this.props;

    return (
      <div styleName="container">
        <div styleName="checkboxWrapper">
          <Checkbox
            checked={selected}
            iconStyle={{ alignSelf: "center" }}
            labelStyle={{ marginBottom: 0 }}
            label={
              <div className={css.label}>
                <div className={css.avatar}>
                  <i className="material-icons">person</i>
                </div>
                <div className={css.labelBody}>
                  <div>{email}</div>
                </div>
              </div>
            }
            onCheck={this.toggleRecipient}
          />
        </div>
      </div>
    );
  }
}

Recipient.propTypes = {
  selected: PropTypes.bool,
  email: PropTypes.string.isRequired,
  toggleRecipient: PropTypes.func.isRequired
};

export default Recipient;
