export const data = {
  success: true,
  payload: {
    records: [
      {
        id: 1,
        parent: null,
        type: "file",
        name: "Event-FAQ.pdf",
        mimetype: "application/pdf",
        modified: "8/12/20, 4:10pm",
        assigned_to: "15 groups",
        size: "1.8 KB",
        url: "https://cdn.filestackcontent.com/jwhCQYxTuaLPGrfE5npT"
      },
      {
        id: 2,
        parent: null,
        children: [3, 5, 4],
        type: "folder",
        folder_type: "custom",
        name: "2020 Site Plans"
      },
      {
        id: 3,
        parent: 2,
        type: "file",
        name: "Site_CAD_layout_v3.jpg",
        mimetype: "image/jpeg",
        modified: "8/12/20 4:10pm",
        assigned_to: "8 groups",
        size: "12.2 MB",
        url: "https://cdn.filestackcontent.com/jwhCQYxTuaLPGrfE5npT"
      },
      {
        id: 5,
        parent: 2,
        children: [6, 7],
        type: "folder",
        folder_type: "custom",
        name: "2019 Site Plans"
      },
      {
        id: 4,
        parent: 2,
        type: "file",
        name: "On-Site Contact Sheet.jpg",
        mimetype: "image/jpeg",
        modified: "8/12/20 4:10pm",
        assigned_to: "All groups",
        size: "50 KB",
        url: "https://cdn.filestackcontent.com/jwhCQYxTuaLPGrfE5npT"
      },
      {
        id: 6,
        parent: 5,
        type: "file",
        name: "Site_CAD_layout_v4.jpg",
        mimetype: "image/jpeg",
        modified: "8/12/20 4:10pm",
        assigned_to: "8 groups",
        size: "12.2 MB",
        url: "https://cdn.filestackcontent.com/jwhCQYxTuaLPGrfE5npT"
      },
      {
        id: 7,
        parent: 5,
        type: "file",
        name: "On-Site Contact Sheet (2).jpg",
        mimetype: "image/jpeg",
        modified: "8/12/20 4:10pm",
        assigned_to: "All groups",
        size: "50 KB",
        url: "https://cdn.filestackcontent.com/jwhCQYxTuaLPGrfE5npT"
      },
      {
        id: 8,
        parent: null,
        children: [10, 11, 12],
        type: "folder",
        folder_type: "document_request",
        name: "COI - $1M"
      },
      {
        id: 10,
        parent: 8,
        type: "file",
        name: "ABC Rentals - COI $1M.pdf",
        mimetype: "application/pdf",
        modified: "8/12/20 4:10pm",
        assigned_to: "1 group",
        size: "50 KB",
        url: "https://cdn.filestackcontent.com/jwhCQYxTuaLPGrfE5npT"
      },
      {
        id: 11,
        parent: 8,
        type: "file",
        name: "Big Company - COI $1M.pdf",
        mimetype: "application/pdf",
        modified: "8/12/20 4:10pm",
        assigned_to: "1 group",
        size: "50 KB",
        url: "https://cdn.filestackcontent.com/jwhCQYxTuaLPGrfE5npT"
      },
      {
        id: 12,
        parent: 8,
        type: "file",
        name: "Small Company - COI $1M.pdf",
        mimetype: "application/pdf",
        modified: "8/12/20 4:10pm",
        assigned_to: "1 group",
        size: "50 KB",
        url: "https://cdn.filestackcontent.com/jwhCQYxTuaLPGrfE5npT"
      },
      {
        id: 13,
        parent: null,
        children: [14, 15, 16],
        type: "folder",
        folder_type: "document_request",
        name: "Signed Contracts"
      },
      {
        id: 14,
        parent: 13,
        type: "file",
        name: "ABC Rentals - Contract.pdf",
        mimetype: "application/pdf",
        modified: "8/12/20 4:10pm",
        assigned_to: "1 group",
        size: "50 KB",
        url: "https://cdn.filestackcontent.com/jwhCQYxTuaLPGrfE5npT"
      },
      {
        id: 15,
        parent: 13,
        type: "file",
        name: "Big Company - Contract.pdf",
        mimetype: "application/pdf",
        modified: "8/12/20 4:10pm",
        assigned_to: "1 group",
        size: "50 KB",
        url: "https://cdn.filestackcontent.com/jwhCQYxTuaLPGrfE5npT"
      },
      {
        id: 16,
        parent: 13,
        type: "file",
        name: "Small Company - Contract.pdf",
        mimetype: "application/pdf",
        modified: "8/12/20 4:10pm",
        assigned_to: "1 group",
        size: "50 KB",
        url: "https://cdn.filestackcontent.com/jwhCQYxTuaLPGrfE5npT"
      },
      {
        id: 17,
        parent: null,
        children: [18, 25],
        type: "folder",
        folder_type: "file_field",
        name: "Group Files"
      },
      {
        id: 18,
        parent: 17,
        children: [19, 20, 21],
        type: "folder",
        folder_type: "file_field",
        name: "Logo"
      },
      {
        id: 19,
        parent: 18,
        type: "file",
        name: "ABC Rentals - Logo.jpg",
        mimetype: "image/jpeg",
        modified: "8/12/20 4:10pm",
        assigned_to: "1 group",
        size: "50 KB",
        url: "https://cdn.filestackcontent.com/jwhCQYxTuaLPGrfE5npT"
      },
      {
        id: 20,
        parent: 18,
        type: "file",
        name: "Big Company - Logo.jpg",
        mimetype: "image/jpeg",
        modified: "8/12/20 4:10pm",
        assigned_to: "1 group",
        size: "50 KB",
        url: "https://cdn.filestackcontent.com/jwhCQYxTuaLPGrfE5npT"
      },
      {
        id: 21,
        parent: 18,
        type: "file",
        name: "Small Company - Logo.jpg",
        mimetype: "image/jpeg",
        modified: "8/12/20 4:10pm",
        assigned_to: "1 group",
        size: "50 KB",
        url: "https://cdn.filestackcontent.com/jwhCQYxTuaLPGrfE5npT"
      },
      {
        id: 25,
        parent: 17,
        children: [26, 27, 28],
        type: "folder",
        folder_type: "file_field",
        name: "Social Cover Image"
      },
      {
        id: 26,
        parent: 25,
        type: "file",
        name: "ABC Rentals - Cover.jpg",
        mimetype: "image/jpeg",
        modified: "8/12/20 4:10pm",
        assigned_to: "1 group",
        size: "50 KB",
        url: "https://cdn.filestackcontent.com/jwhCQYxTuaLPGrfE5npT"
      },
      {
        id: 27,
        parent: 25,
        type: "file",
        name: "Big Company - Cover.jpg",
        mimetype: "image/jpeg",
        modified: "8/12/20 4:10pm",
        assigned_to: "1 group",
        size: "50 KB",
        url: "https://cdn.filestackcontent.com/jwhCQYxTuaLPGrfE5npT"
      },
      {
        id: 28,
        parent: 25,
        type: "file",
        name: "Small Company - Cover.jpg",
        mimetype: "image/jpeg",
        modified: "8/12/20 4:10pm",
        assigned_to: "1 group",
        size: "50 KB",
        url: "https://cdn.filestackcontent.com/jwhCQYxTuaLPGrfE5npT"
      }
    ],
    fields: [
      { id: "name", name: "Name", type: "text", settings: {} },
      { id: "modified", name: "Modified", type: "text", settings: {} },
      { id: "assigned_to", name: "Assigned To", type: "text", settings: {} },
      { id: "size", name: "File Size", type: "text", settings: {} }
    ]
  }
};
