import React, { Component } from "react";
import PropTypes from "prop-types";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";
import InternalHandler from "./Internal";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import ItemBlockItemsModal from "components/Event/Settings/Credentials/Modals/AddItemBlock";

class ExternalHandler extends Component {
  getBlock = () => this.props.getItemBlock(this.props.itemBlockId);

  handleDelete = async () => {
    await this.props.deleteItemBlock({ blockId: this.props.itemBlockId });

    if (this.props.onDone) {
      this.props.onDone();
    }

    this.props.hideModal();
  };

  showItemsModal = () => {
    this.props.showModal({
      content: (
        <ItemBlockItemsModal
          onDone={() => {
            this.getBlock();

            if (this.props.onDone) {
              this.props.onDone();
            }
          }}
          itemBlockId={this.props.itemBlockId}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  reorderItems = async variants => {
    await this.props.bulkUpdateItemBlockVariants({
      variants: variants.map(({ id }, order) => ({
        id,
        order
      }))
    });

    return this.getBlock();
  };

  render() {
    return <InternalHandler {...this} {...this.props} />;
  }
}

ExternalHandler.propTypes = {
  itemBlockId: PropTypes.string.isRequired,
  eventDetails: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  getItemBlock: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default ExternalHandler;
