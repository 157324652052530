import * as R from "ramda";
import { createSelector } from "reselect";

import { getters } from "ui-kit/SelectFilter";

export const getActiveFilter = createSelector(
  getters.activeFilterId,
  (_, props) => R.prop("filters", props),
  (activeFilterId, filters) =>
    activeFilterId
      ? R.find(
          R.compose(
            R.equals(activeFilterId),
            R.prop("id")
          ),
          filters
        )
      : R.head(filters)
);

export const getActiveFilterName = createSelector(
  getActiveFilter,
  R.propOr("", "name")
);
