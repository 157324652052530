import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const normalizeName = R.compose(
  str => R.toUpper(R.head(str)) + R.tail(str),
  R.replace(/_/g, " ")
);

const iniState = {
  loading: true,
  message: null,
  error: null,
  saving: false,
  fields: [],
  paymentsEnabled: false,
  invalidIds: [],
  total: 0
};

export const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    submitForm: R.always({ saving: true, error: null }),
    setInitialData: (_, { payload: { fields, paymentsEnabled, total } }) => ({
      loading: false,
      paymentsEnabled,
      total,
      fields: R.compose(
        R.map(
          R.when(R.is(String), str => ({
            name: normalizeName(str),
            id: normalizeName(str),
            required: false,
            isValid: true,
            settings: {},
            type: "section"
          }))
        ),
        R.reduce((acc, curr) => R.concat(acc, curr.flat()), []),
        R.toPairs
      )(fields)
    }),
    onPaymentFieldChange: (state, action) => {
      return {};
    }
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
