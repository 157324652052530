import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import ReactHoverObserver from "react-hover-observer";
import { getters, actions } from "Toolbar/QuickActionsNavigation";
import {
  getFilteredModules,
  getDefaultActions
} from "Toolbar/QuickActionsNavigation/selectors";
import {
  Div,
  FontIcon,
  SettingsIcon,
  PeopleIcon,
  BagIcon,
  RightIcon,
  OpenInNewIcon,
  ClockIcon,
  CheckIcon
} from "components/Base";
import { withProps } from "utils/General";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import MediumFilledInputFocus from "./MediumFilledInputFocus";
import Circle from "ui-kit/Circle";

const decorate = connect(
  (state, props) => ({
    modules: getFilteredModules(state, props),
    defaultActions: getDefaultActions(state, props),
    filter: getters.filter(state, props),
    lastFetched: getters.lastFetched(state, props)
  }),
  {
    setFilter: actions.setFilter,
    openMenu: actions.openMenu
  }
);

const NavIcon = withProps({
  fs: 3,
  color: "white"
})(FontIcon);

const Title = withProps({
  fs: 1,
  fw: 3,
  color: "neutral6",
  truncate: true,
  uppercase: true,
  style: {
    letterSpacing: ".4px"
  }
})(Div);

const NotificationBadge = ({ count }) => (
  <Div
    p={1}
    bra={1}
    height={20}
    bg="altB2"
    display="row.center.center"
    style={{ minWidth: "26px" }}
    ml={2}
    mr={1}
  >
    <Div fs={1} fw={3} color="altB7">
      {count >= 100 ? "99+" : count}
    </Div>
  </Div>
);

const MyApprovals = ({ action, closePopover, pending }) => {
  return (
    <Div
      display="row.flex-start.center"
      py={2}
      px={3}
      bg={{
        default: "transparent",
        hover: "neutral1"
      }}
      onClick={() => {
        closePopover();
        action.onClick();
      }}
    >
      <Div display="row.flex-start.center" flex={1}>
        <Circle size={26} bg="altB2">
          <ClockIcon color="altB6" size={16} />
        </Circle>
        <Div fs={3} fw={3} color="black" ml={2}>
          My Approvals
        </Div>
      </Div>
      {pending ? <NotificationBadge count={pending} /> : null}
      <RightIcon size={20} />
    </Div>
  );
};

const MyTasks = ({ action, closePopover, pending }) => {
  return (
    <Div
      display="row.flex-start.center"
      py={2}
      px={3}
      bg={{
        default: "transparent",
        hover: "neutral1"
      }}
      onClick={() => {
        closePopover();
        action.onClick();
      }}
    >
      <Div display="row.flex-start.center" flex={1}>
        <Circle size={26} bg="success2">
          <CheckIcon color="success6" size={16} />
        </Circle>
        <Div fs={3} fw={3} color="black" ml={2}>
          My Tasks
        </Div>
      </Div>
      {pending ? <NotificationBadge count={pending} /> : null}
      <RightIcon size={20} />
    </Div>
  );
};

const Caret = () => (
  <Div
    style={{
      width: 0,
      height: 0,
      borderLeft: "15px solid transparent",
      borderRight: "15px solid transparent",
      borderBottom: "15px solid #f8f8f8",
      position: "absolute",
      top: "-11px",
      left: 0,
      right: "-34px",
      margin: "0 auto"
    }}
  />
);

const getIcon = ({ moduleId, icon, color }) => {
  let iconToShow = <NavIcon icon={icon} />;
  let colorToShow = color;

  if (moduleId === STANDARD_MODULE_IDS.accounts.id) {
    iconToShow = <BagIcon color="white" fs={3} />;
    colorToShow = "#FF9F25";
  } else if (moduleId === STANDARD_MODULE_IDS.contacts.id) {
    iconToShow = <PeopleIcon color="white" fs={3} />;
    colorToShow = "#00A7F0";
  } else if (moduleId === STANDARD_MODULE_IDS.inventory.id) {
    iconToShow = <NavIcon icon="widgets" />;
    colorToShow = "#080078";
  } else if (moduleId === STANDARD_MODULE_IDS.portal.id) {
    iconToShow = <NavIcon icon="pages" />;
    colorToShow = "#7529CA";
  } else if (moduleId === STANDARD_MODULE_IDS.users.id) {
    iconToShow = <PeopleIcon color="white" fs={3} />;
    colorToShow = "#295DAB";
  } else if (moduleId === STANDARD_MODULE_IDS.reports.id) {
    iconToShow = <NavIcon icon="show_chart" />;
    colorToShow = "#cc5788";
  } else if (moduleId === STANDARD_MODULE_IDS.booths.id) {
    iconToShow = <NavIcon icon="store_mall_directory" />;
    colorToShow = "#51cf9b";
  } else if (moduleId === STANDARD_MODULE_IDS.orders.id) {
    iconToShow = <NavIcon icon="attach_money" />;
    colorToShow = "#51cf66";
  } else if (moduleId === STANDARD_MODULE_IDS.schedules.id) {
    iconToShow = <NavIcon icon="schedule" />;
    colorToShow = "#0073ff";
  } else if (moduleId === STANDARD_MODULE_IDS.sessions.id) {
    iconToShow = <NavIcon icon="event_note" />;
    colorToShow = "#23ccbc";
  } else if (moduleId === STANDARD_MODULE_IDS.virtual.id) {
    iconToShow = <NavIcon icon="present_to_all" />;
    colorToShow = "#0A29CA";
  } else if (moduleId === STANDARD_MODULE_IDS.settings.id) {
    iconToShow = <SettingsIcon color="white" fs={3} />;
    colorToShow = "#CCCCCC";
  }

  return (
    <Div
      bra={2}
      color="white"
      style={{ backgroundColor: colorToShow || "#eee", flexShrink: 0 }}
      width={26}
      height={26}
      display="row.center.center"
      mr={2}
    >
      {iconToShow}
    </Div>
  );
};

const Action = ({ action, closePopover }) =>
  action.isTitle ? (
    <Title mb={1} px={4} pt={4}>
      {action.name}
    </Title>
  ) : (
    <Div
      key={action.id}
      py={2}
      px={4}
      display="row.space-between.center"
      bg={{
        default: "transparent",
        hover: "neutral1"
      }}
      onClick={() => {
        closePopover();
        action.onClick();
      }}
    >
      <Div display="row.flex-start.center" fs={3} fw={3} color="black">
        {action.name}
        {action.newWindow ? (
          <OpenInNewIcon ml={2} size={12} color="gray6" />
        ) : null}
      </Div>
      {action.count_of_alerts > 0 && (
        <NotificationBadge count={action.count_of_alerts} />
      )}
    </Div>
  );

const MenuItem = ({
  mod,
  idx,
  activeRow,
  closePopover,
  setActions,
  setActiveRow,
  setHovered,
  isHovering = false
}) => {
  useEffect(() => {
    if (isHovering) {
      setActions(R.propOr([], "actions", mod));
      setHovered(true);
      setActiveRow(idx);
    }
  });
  return (
    <Div
      key={mod.id}
      px={4}
      py={1}
      bg={activeRow !== idx ? "white" : "neutral0"}
      transition="fast"
      display="row.space-between.center"
      style={{
        cursor: "pointer",
        paddingTop: "6px",
        paddingBottom: "6px"
      }}
      onClick={() => {
        closePopover();
        mod.goTo();
      }}
    >
      <Div display="row.flex-start.center">
        {getIcon({ moduleId: mod.id, icon: mod.icon, color: mod.color })}
        <Div fs={2} fw={3} color="black">
          {mod.name}
        </Div>
      </Div>
      <Div display="row.flex-start.center">
        {mod.count_of_alerts ? (
          <NotificationBadge count={mod.count_of_alerts} />
        ) : null}

        <RightIcon size={20} />
      </Div>
    </Div>
  );
};

const Menu = ({
  handlers,
  defaultActions,
  closePopover,
  modules,
  filter,
  setFilter,
  setBtnActive,
  lastFetched,
  openMenu
}) => {
  const [hovered, setHovered] = useState(false);
  const [actions, setActions] = useState([]);
  const [activeRow, setActiveRow] = useState(-1);
  const inputEl = useRef(null);

  useEffect(() => {
    setBtnActive(true);

    inputEl.current.focus();
    return () => setBtnActive(false);
  }, []);

  return (
    <Div
      display="row.flex-start.stretch"
      style={{
        positon: "relative"
      }}
      onMouseLeave={() => {
        setActions([]);
        setHovered(false);
        setActiveRow(-1);
      }}
    >
      <Caret />
      <Div
        width={250}
        style={{
          minHeight: 260,
          maxHeight: 415,
          overflowY: "auto",
          borderRadius: "5px 0 0 5px"
        }}
        pb={2}
      >
        <Div
          width={1}
          px={3}
          pt={3}
          pb={2}
          onMouseOver={() => {
            setActions([]);
            setHovered(false);
            setActiveRow(-1);
          }}
        >
          <MediumFilledInputFocus
            inputRef={inputEl}
            value={filter}
            onChange={value => setFilter(value)}
          />
        </Div>
        {modules.map((mod, idx) => {
          return (
            <ReactHoverObserver hoverDelayInMs={100} hoverOffDelayInMs={100}>
              <MenuItem
                mod={mod}
                idx={idx}
                activeRow={activeRow}
                closePopover={closePopover}
                setActions={setActions}
                setActiveRow={setActiveRow}
                setHovered={setHovered}
              />
            </ReactHoverObserver>
          );
        })}
      </Div>
      <Div
        width={255}
        bg="neutral0"
        display="column"
        pb={4}
        style={{
          minHeight: 260,
          maxHeight: 415,
          overflowY: "auto",
          borderRadius: "0 5px 5px 0"
        }}
      >
        <Div display="column" mb={1} px={4} pt={4}>
          <Title>
            {activeRow !== -1 && modules[activeRow]
              ? modules[activeRow].name
              : "ACTIONS"}
          </Title>
        </Div>
        {hovered && modules[activeRow] ? (
          <Div>
            {R.map(
              action =>
                action.is_my_approvals ? (
                  <MyApprovals
                    key={action.id}
                    action={action}
                    closePopover={closePopover}
                    pending={action.count_of_alerts}
                  />
                ) : (
                  <Action
                    key={action.id}
                    action={action}
                    closePopover={closePopover}
                  />
                ),
              actions
            )}
          </Div>
        ) : (
          <Div>
            {R.map(
              action =>
                action.is_my_approvals ? (
                  <MyApprovals
                    key={action.id}
                    action={action}
                    closePopover={closePopover}
                    pending={action.count_of_alerts}
                  />
                ) : action.is_my_tasks ? (
                  <MyTasks
                    key={action.id}
                    action={action}
                    closePopover={closePopover}
                    pending={action.count_of_alerts}
                  />
                ) : (
                  <Action
                    key={action.id}
                    action={action}
                    closePopover={closePopover}
                  />
                ),
              defaultActions
            )}
          </Div>
        )}

        {activeRow !== -1 &&
        modules[activeRow] &&
        modules[activeRow].types.length ? (
          <>
            <Div display="column" mb={1} px={4} pt={4}>
              <Title>DASHBOARDS</Title>
            </Div>
            <Div>
              {R.map(
                action => (
                  <Action
                    key={action.id}
                    action={action}
                    closePopover={closePopover}
                  />
                ),
                modules[activeRow].types
              )}
            </Div>
          </>
        ) : null}
      </Div>
    </Div>
  );
};

export default decorate(Menu);
