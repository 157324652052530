import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";

const keys = {
  13: "Enter",
  9: "Tab"
};

export default class BeforeClose extends Component {
  @autobind
  handleKeyboardCommit(e) {
    if (e.keyCode in keys) {
      e.preventDefault();
      e.stopPropagation();
      this.props.do();
    }
  }

  render() {
    const { children } = this.props;
    return <div onKeyDown={this.handleKeyboardCommit}>{children}</div>;
  }
}

BeforeClose.propTypes = {
  do: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};
