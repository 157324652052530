import * as R from "ramda";
import { createSelector } from "reselect";

import { getters } from "Schedules/Create";
import { getters as allowAccessModalGetters } from "Schedules/AllowAccessUsersModal";
import {
  DEFAULT_SELECTED_USER_PROFILES,
  DATE_TIME_TABLE_CONFIG,
  TYPES_OF_SCHEDULES,
  SCHEDULE_TYPE
} from "./constants";

const normalizeString = R.compose(
  R.toLower,
  R.replace(/[_ ]/g, "")
);

export const getVisibleColumns = createSelector(
  getters.dateTimeOptions,
  selectedOptions =>
    R.map(header => {
      const isSameColumn = R.find(
        option => normalizeString(option) === normalizeString(header)
      )(selectedOptions);

      return [
        0.2,
        "left",
        { style: { display: isSameColumn ? "block" : "none" } }
      ];
    })(DATE_TIME_TABLE_CONFIG.headers)
);

const compareUserId = selectedUserId =>
  R.compose(
    R.equals(selectedUserId),
    R.compose(
      R.toString,
      R.prop("user_id")
    )
  );

const mapSelectedIds = (users, profiles) => (
  { userIds, profileIds },
  selectedId
) => ({
  userIds: R.any(compareUserId(selectedId), users)
    ? [...userIds, selectedId]
    : [...userIds],
  profileIds: R.any(R.propEq("id", selectedId), profiles)
    ? [...profileIds, selectedId]
    : [...profileIds]
});

export const selectedUserAndProfiles = createSelector(
  allowAccessModalGetters.selectedItemIds,
  allowAccessModalGetters.users,
  allowAccessModalGetters.permissionProfiles,
  (selectedIds, users, profiles) =>
    R.reduce(
      mapSelectedIds(users, profiles),
      DEFAULT_SELECTED_USER_PROFILES,
      selectedIds
    )
);
export const getIsProject = createSelector(
  getters.scheduleType,
  R.equals(SCHEDULE_TYPE.PROJECT)
);

export const getTypesOfSchedule = R.always(TYPES_OF_SCHEDULES);
