import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import External from "./External";

import * as ModalActions from "redux/modules/modal/actions";
import * as SnackbarActions from "redux/modules/snackbar/actions";
import * as ModuleFieldsActions from "redux/modules/modules/fields/actions";
import * as ModuleVisibleFieldsActions from "redux/modules/modules/visibleFields/actions";
import * as ModuleFieldGroupsActions from "redux/modules/modules/fieldGroups/actions";
import * as RelatedModulesActions from "redux/modules/modules/relatedModules/actions";
import {
  addFieldGroupField,
  updateFieldGroupField,
  deleteFieldGroupField
} from "redux/modules/modules/fieldGroupFields/actions";
import * as ModulePrimaryFieldsActions from "redux/modules/modules/primaryFields/actions";
import * as ModuleStarredFieldsActions from "redux/modules/modules/starredFields/actions";
import {
  addLayout,
  updateLayout,
  deleteLayout
} from "redux/modules/modules/layouts/actions";
import { getLayout } from "redux/modules/modules/layout/actions";

import {
  layout,
  fieldGroups,
  fields,
  starredFields,
  relatedModules
} from "redux/modules/modules/layout/selectors";

function mapStateToProps(state, props) {
  return {
    activeLayout: layout(state, props.moduleId),
    fieldGroups: fieldGroups(state, props.moduleId),
    starredFields: starredFields(state, props.moduleId),
    fields: fields(state, props.moduleId),
    relatedModules: relatedModules(state, props.moduleId)
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      ...SnackbarActions,
      ...ModalActions,
      ...ModuleFieldsActions,
      ...ModuleFieldGroupsActions,
      ...ModulePrimaryFieldsActions,
      ...ModuleStarredFieldsActions,
      ...ModuleVisibleFieldsActions,
      ...RelatedModulesActions,
      addFieldGroupField,
      updateFieldGroupField,
      deleteFieldGroupField,
      addLayout,
      updateLayout,
      deleteLayout,
      getLayout: props.selectedLayoutId
        ? () =>
            getLayout({
              moduleId: props.moduleId,
              layoutId: props.selectedLayoutId,
              options: {
                orgId: props.params.orgId,
                eventId: props.params.eventId
              }
            })
        : () => {}
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(External)
);
