import * as R from "ramda";
import { createHandlers } from "redux-mvc";

import { NAMESPACE, TEMP_ID, MODES } from "ui-kit/Datagrid/constants";

import { DEFAULT_APPROVAL_FIELD_APPROVE_REJECT as reviewV2 } from "components/Event/FormsV2/constants";
import { DEFAULT_APPROVAL_FIELD_APPROVE_REJECT as reviewV3 } from "components/Event/FormsV3/constants";

import { getIsRowEmpty } from "ui-kit/Datagrid/utils";

const EMPTY_VALUES = {
  string: "",
  number: "",
  array: [],
  date: null,
  boolean: null,
  email: null,
  role: null,
  "item-block": [],
  "event-days": []
};

const updateIndexes = replace => arr =>
  R.reduce(
    (acc, [key, val]) => R.update(R.indexOf(key, acc), val, acc),
    arr,
    Object.entries(replace)
  );

const getEmptyValue = (val, type) => {
  if (R.has(type, EMPTY_VALUES)) {
    return EMPTY_VALUES[type];
  }
  if (val !== null && typeof val === "object") {
    return Object.entries(val).reduce(
      (acc, [key, d]) => ({
        ...acc,
        [key]: Array.isArray(d)
          ? EMPTY_VALUES.array
          : R.has(typeof d, EMPTY_VALUES)
          ? EMPTY_VALUES[typeof d]
          : d
      }),
      {}
    );
  }
  return R.has(typeof val, EMPTY_VALUES) ? EMPTY_VALUES[typeof val] : val;
};

const emptyValues = (row, columns) =>
  Object.values(columns).reduce((acc, column) => {
    const data = R.path([column.id], row);
    if (data && data.value) {
      return {
        ...acc,
        [column.id]: {
          ...data,
          type: column.type,
          value: getEmptyValue(data.value, column.type)
        }
      };
    }
    return {
      ...acc,
      [column.id]: {
        columnId: column.id,
        type: column.type,
        value: getEmptyValue(null, column.type)
      }
    };
  }, row || {});

const resetApproval = (data, version) => {
  if (!R.has(reviewV2.id, data) && !R.has(reviewV3.id, data)) {
    return data;
  }

  if (version === 3) {
    return R.mergeAll([
      data,
      {
        [reviewV3.id]: {
          ...data[reviewV3.id],
          value: {
            ...R.pathOr({}, [reviewV3.id, "value"], data),
            status: "pending",
            all_approvers: R.map(
              a => ({ ...a, review: null, reviewed_at: null }),
              R.pathOr([], [reviewV3.id, "value", "all_approvers"], data)
            )
          }
        }
      }
    ]);
  } else if (version === 2) {
    return R.mergeAll([
      data,
      {
        [reviewV2.id]: {
          ...data[reviewV2.id],
          approvers: R.map(
            a => ({ ...a, review: null, reviewed_at: null }),
            R.pathOr([], [reviewV3.id, "approvers"], data)
          )
        }
      }
    ]);
  }
  return data;
};

const updateApproval = (data, { response, user_id, updated_at }) => {
  const currentApprovers = R.pathOr(
    [],
    [reviewV3.id, "value", "current_approvers"],
    data
  );
  const allApprovers = R.pathOr(
    [],
    [reviewV3.id, "value", "all_approvers"],
    data
  );

  const approvers = R.map(
    a =>
      a.user_id === user_id
        ? { ...a, review: response, reviewed_at: updated_at }
        : a,
    R.isEmpty(currentApprovers) ? allApprovers : currentApprovers
  );

  const status = R.reduce(
    (acc, a) => {
      if (acc === "reject") {
        return R.reduced("rejected");
      }
      if (a.review === "reject") {
        return R.reduced("rejected");
      }
      if (a.review === "approve") {
        return "approved";
      }
      return acc;
    },
    "pending",
    approvers
  );

  return {
    ...data,
    [reviewV3.id]: {
      ...data[reviewV3.id],
      value: {
        ...R.pathOr({}, [reviewV3.id, "value"], data),
        status,
        current_approvers: status === "pending" ? approvers : [],
        all_approvers: approvers
      }
    }
  };
};

// Initial State
const iniState = {
  selectedRows: [],
  rows: {},
  rowsIndex: [],
  meta: {},
  tempIds: {},
  newIds: [],
  loading: false,
  searchTerm: "",
  colWidths: {},
  mode: MODES.EDIT,
  updateEnabled: true
};

const setRows = ({ tempIds, rows }, { payload: data }) => {
  const rowsIndex = [];
  const emptyRows = [];
  const newRows = R.reduce(
    (acc, r) => {
      if (getIsRowEmpty({ row: r })) {
        emptyRows.push(r.id);
      } else {
        rowsIndex.push(r.id);
      }
      acc[r.id] = r;
      return acc;
    },
    {},
    data
  );

  const temp = R.pick(R.keys(tempIds), rows);

  return {
    rows: R.mergeAll([newRows, temp]),
    rowsIndex: R.concat(rowsIndex, R.concat(emptyRows, R.keys(tempIds)))
  };
};

// Handlers
const handlers = createHandlers({
  iniState,
  reducers: {
    setSearch: R.identity, // just for debouncing setSearchTerm
    setIniData: (state, { payload: { rows, meta, mode } }) =>
      state.updateEnabled
        ? {
            ...setRows(state, { payload: rows }),
            meta,
            mode
          }
        : state,
    setRows,
    selectRows: ({ selectedRows, tempIds }, { payload: rows }) => ({
      selectedRows: R.concat(
        selectedRows,
        R.without(R.values(tempIds), R.map(R.prop("rowIdx"), rows))
      )
    }),
    deselectRows: ({ selectedRows }, { payload: rows }) => ({
      selectedRows: R.without(R.map(R.prop("rowIdx"), rows), selectedRows)
    }),
    addRowRequest: R.always({ loading: true }),
    addRow: (
      { rows, rowsIndex, meta, tempIds },
      { payload: tId = TEMP_ID }
    ) => ({
      rows: {
        ...rows,
        [tId]: R.mergeAll([
          resetApproval(rows[rowsIndex[0]] || {}, meta.version), // reset review status
          emptyValues(
            rows[rowsIndex[0]] || {},
            R.omit(
              [reviewV2.id, reviewV3.id], // do not empty review list
              R.propOr({}, "columns", meta)
            )
          ),
          { id: tId, key: tId, meta, isRowEmpty: true }
        ])
      },
      rowsIndex: R.concat(rowsIndex, [tId]),
      tempIds: {
        ...tempIds,
        [tId]: tId
      }
    }),
    addRowResponse: (
      { tempIds, newIds, rows, rowsIndex },
      { payload: { tId, submission }, error }
    ) =>
      error
        ? { loading: false }
        : {
            rows: R.mergeAll([
              {
                [submission.id]: R.mergeAll([
                  R.propOr({}, tId, rows),
                  {
                    id: submission.id,
                    key: submission.id,
                    submission_record_id: submission.submission_record_id,
                    contact_id: submission.contact_id,
                    related_order: submission.related_order
                  }
                ])
              },
              R.omit([tId], rows)
            ]),
            tempIds: R.omit([tId], tempIds),
            newIds: R.uniq(R.append(submission.id, newIds)),
            loading: false,
            rowsIndex: R.update(
              R.indexOf(tId, rowsIndex),
              submission.id,
              rowsIndex
            )
          },
    removeRowRequest: R.identity,
    removeRow: ({ selectedRows, rows, rowsIndex }) => ({
      rowsIndex: R.without(
        R.map(idx => rowsIndex[idx], selectedRows),
        rowsIndex
      ),
      rows: R.omit(R.map(idx => rowsIndex[idx], selectedRows), rows),
      selectedRows: []
    }),
    removeRowResponse: R.identity,
    duplicateRowRequest: R.always({ loading: true }),
    duplicateRow: (
      { selectedRows, rows, rowsIndex, meta },
      { payload: tempIds }
    ) => {
      const newRows = R.reduce(
        (acc, selectedIdx) => {
          acc[tempIds[rowsIndex[selectedIdx]]] = {
            ...resetApproval(rows[rowsIndex[selectedIdx]], meta.version),
            id: tempIds[rowsIndex[selectedIdx]],
            key: tempIds[rowsIndex[selectedIdx]]
          };
          return acc;
        },
        {},
        selectedRows
      );

      return {
        rows: {
          ...rows,
          ...newRows
        },
        rowsIndex: R.concat(rowsIndex, R.values(tempIds)),
        tempIds
      };
    },
    duplicateRowResponse: (
      { rows, tempIds, rowsIndex },
      { payload: { submissions, tempIds: tIds }, error }
    ) =>
      error
        ? { tempIds: R.omit(R.keys(tIds), tempIds) }
        : {
            rows: R.omit(
              R.values(tIds),
              R.reduce(
                (acc, [id, row]) => {
                  const submission = R.head(R.values(row.cloned_submissions));
                  acc[submission.id] = R.mergeAll([
                    acc[tempIds[id]],
                    { key: submission.id },
                    R.omit(["values"], submission),
                    submission.values
                  ]);

                  return acc;
                },
                rows,
                Object.entries(submissions)
              )
            ),
            tempIds: R.omit(R.keys(tIds), tempIds),
            rowsIndex: updateIndexes(
              R.zipObj(
                R.map(id => tempIds[id], R.keys(submissions)),
                R.map(
                  R.compose(
                    R.prop("id"),
                    R.head,
                    R.values,
                    R.prop("cloned_submissions")
                  ),
                  R.values(submissions)
                )
              )
            )(rowsIndex),
            loading: false
          },
    updateCellRequest: R.always({ loading: true }),

    /*
      PAYLOAD:
        cellKey
        updated
        action: "CELL_UPDATE" || "CELL_DRAG" || "COPY_PASTE"
        fromRow: row idx
        fromRowId: row uuid
        fromRowData: object {}
        rowIds: [row uuid]
        toRow: row idx
        toRowId: row uuid
    */
    updateCell: ({ rows }, { payload: { fromRowId, cellKey, updated } }) => {
      return {
        rows: R.assocPath(
          [fromRowId, cellKey],
          {
            ...R.propOr({}, cellKey, updated),
            columnId: cellKey,
            key: `${fromRowId}_${cellKey}`
          },
          rows
        )
      };
    },

    updateCellResponse: R.always({ loading: false }),
    makeReviewRequest: R.always({ loading: true }), // @TODO move review actions to the ApproversLabel domain
    makeReview: ({ rows }, { payload: { rowId, response } }) => ({
      rows: {
        ...rows,
        [rowId]: updateApproval(rows[rowId], response)
      }
    }),
    makeReviewResponse: R.always({ loading: false }),
    reviewRemaining: R.always({ loading: true }),
    reviewRemainingResponse: ({ rows }, { payload: reviews }) => ({
      loading: false,
      rows: {
        ...rows,
        ...R.mergeAll(
          R.indexBy(
            R.prop("id"),
            R.map(
              review => updateApproval(rows[review.rowId], review.response),
              R.filter(review => R.isNil(review.error), reviews)
            )
          )
        )
      }
    }),
    requestEdit: R.identity(),
    cancelEdit: R.identity(),
    columnResizeRequest: R.identity(),
    columnResize: ({ colWidths }, { payload: { id, width } }) =>
      id
        ? {
            colWidths: R.mergeAll([
              colWidths,
              {
                [id]: width
              }
            ])
          }
        : {},
    columnResizeResponse: R.identity(),
    importRequest: R.identity(),
    saveRequest: R.identity(),
    save: R.identity()
  },
  namespace: NAMESPACE
});

export default handlers;
