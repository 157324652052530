import Helpers from "utils/Global/Helpers";

export default {
  get: (credentials, { moduleId, eventId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/modules/event/${eventId}/${moduleId}/managers`,
        credentials,
        success,
        error
      });
    })
};
