import React from "react";
import * as R from "ramda";

import { connect } from "react-redux";
import { Div } from "components/Base";

import { DRAGGABLE_IDS } from "../constants";
import { getGroupFields } from "../selectors";
import { actions } from "../model";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import FieldsList from "./FieldsList";

const decorate = R.compose(
  connect(
    state => ({
      fields: getGroupFields(state)
    }),
    {
      onAddField: actions.showAddFieldModal,
      onEditField: actions.showEditFieldModal,
      onDeleteField: actions.showDeleteFieldModal,
      //
      onAddFieldGroup: actions.showAddFieldGroupModal,
      onEditFieldGroup: actions.showEditFieldGroupModal,
      onDeleteFieldGroup: actions.showDeleteFieldGroupModal,
      //
      onSearch: actions.setGroupSearch
    }
  ),
  CSSModules(css)
);

const GroupFields = ({
  fields,
  onSearch,
  onReorder,
  onAddField,
  onEditField,
  onDeleteField,
  onAddFieldGroup,
  onEditFieldGroup,
  onDeleteFieldGroup
}) => {
  const fieldsToPass = fields.map(fg => ({
    ...fg,
    fields: fg.fields.map(f => ({
      ...f,
      onClick: () => onEditField({ moduleId: f.module_id, fieldId: f.id }),
      onDelete: () => onDeleteField({ moduleId: f.module_id, fieldId: f.id })
    }))
  }));

  return (
    <FieldsList
      fieldsId={DRAGGABLE_IDS.GROUP}
      title="Group Fields"
      icon="groups"
      onSearch={onSearch}
      onReorder={onReorder}
      onAddFieldGroup={() =>
        onAddFieldGroup({
          moduleId: STANDARD_MODULE_IDS.accounts.id
        })
      }
      onAddField={() =>
        onAddField({
          moduleId: STANDARD_MODULE_IDS.accounts.id
        })
      }
      fields={fieldsToPass}
    />
  );
};

export default decorate(GroupFields);
