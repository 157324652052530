import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AssignToEvent from "./Assign";

import { orgEvents } from "redux/modules/organization/selectors";
import {
  assignEvents,
  removeEventAssignments
} from "redux/modules/modules/records/actions";
import { hideModal } from "redux/modules/modal/actions";
import { records } from "redux/modules/modules/records/selectors";

function mapStateToProps(state, props) {
  return {
    list: orgEvents(state),
    moduleRecords: records(state, props.moduleId)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      assignEvents,
      unassignEvents: removeEventAssignments,
      hideModal
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignToEvent);
