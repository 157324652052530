import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "./constants";

const iniState = {};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {}
});

export default handlers;
