import React from "react";
import { Div, LoadingIcon } from "components/Base";

const Confirmation = () => (
  <Div display="column.center.center">
    <Div display="column.center.center" width={300} mt={15}>
      <Div fw={4} mb={2} fs={5} color="gray7">
        Cloning your event...
      </Div>
      <Div mb={5} color="gray6" textAlign="center">
        This can take a few minutes. You'll be redirected to your new event when
        the process is complete.
      </Div>
      <LoadingIcon size={75} />
    </Div>
  </Div>
);

export default Confirmation;
