import React from "react";

import { Div } from "components/Base";

const FiltersOnTag = ({ on }) => (
  <Div
    color="success8"
    bg="success1"
    bra={1}
    fs={1}
    fw={3}
    p={1}
    mr={1}
    uppercase
    style={{ visibility: on ? "visible" : "hidden" }}
  >
    on
  </Div>
);

export default FiltersOnTag;
