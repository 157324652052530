import PropTypes from "prop-types";
import React from "react";
import Sidebar, {
  Header as SidebarHeader,
  Item as SidebarItem
} from "components/Event/Settings/Users/Permissions/Sidebar";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import { Divider } from "material-ui";
import CreatePermission from "../CreatePermission";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const popoverPosition = {
  vertical: "bottom",
  horizontal: "right"
};

function stopBubbling(event) {
  event.stopPropagation();
}

const EventPermissionsSidebar = ({
  permissionProfiles,
  activeRole,
  onCreate,
  onRoleSelect,
  onMenuClick
}) => (
  <Sidebar>
    <section styleName="sideBarSection">
      <SidebarHeader>Default Profiles</SidebarHeader>
      {permissionProfiles.filter(p => !p.editable).map(role => (
        <span className={css.sidebarItem}>
          <SidebarItem
            key={role.id}
            isActive={activeRole === role.id}
            className={css.SidebarItem}
            onMouseUp={onRoleSelect.bind(null, role.id)}
            data-role-key={role.id}
          >
            <span className={css.sideBarLabel}>{role.name}</span>
            <span className={css.sideBarIconMenu}>
              <IconMenu
                anchorOrigin={popoverPosition}
                onMouseUp={stopBubbling}
                onChange={onMenuClick.bind(null, role.id)}
                iconButtonElement={
                  <span>
                    <i
                      className={["material-icons", css.sideBarMenuIcon].join(
                        " "
                      )}
                    >
                      more_vert
                    </i>
                  </span>
                }
              >
                <MenuItem
                  value="duplicate"
                  leftIcon={
                    <i className="material-icons">control_point_duplicate</i>
                  }
                  primaryText="Duplicate"
                />
              </IconMenu>
            </span>
          </SidebarItem>
        </span>
      ))}
    </section>
    <section styleName="sideBarSection">
      <SidebarHeader>Custom Profiles</SidebarHeader>
      {permissionProfiles.filter(p => p.editable).map(role => (
        <span className={css.sidebarItem}>
          <SidebarItem
            key={role.id}
            isActive={activeRole === role.id}
            className={css.SidebarItem}
            onMouseUp={onRoleSelect.bind(null, role.id)}
            data-role-key={role.id}
          >
            <span className={css.sideBarLabel}>{role.name}</span>
            <span className={css.sideBarIconMenu}>
              <IconMenu
                anchorOrigin={popoverPosition}
                onMouseUp={stopBubbling}
                onChange={onMenuClick.bind(null, role.id)}
                iconButtonElement={
                  <i
                    className={["material-icons", css.sideBarMenuIcon].join(
                      " "
                    )}
                  >
                    more_vert
                  </i>
                }
              >
                <MenuItem
                  value="edit"
                  leftIcon={<i className="material-icons">border_color</i>}
                  primaryText="Edit"
                />
                <MenuItem
                  value="duplicate"
                  leftIcon={
                    <i className="material-icons">control_point_duplicate</i>
                  }
                  primaryText="Duplicate"
                />
                <Divider />
                <MenuItem
                  value="delete"
                  leftIcon={<i className="material-icons">delete</i>}
                  primaryText="Delete"
                />
              </IconMenu>
            </span>
          </SidebarItem>
        </span>
      ))}
    </section>
    <br />
    <section>
      <CreatePermission type="submit" onCreate={onCreate} />
    </section>
  </Sidebar>
);

EventPermissionsSidebar.propTypes = {
  activeRole: PropTypes.string.isRequired,
  onCreate: PropTypes.func.isRequired,
  styles: PropTypes.object,
  onMenuClick: PropTypes.func.isRequired,
  onRoleSelect: PropTypes.func.isRequired,
  permissionProfiles: PropTypes.array.isRequired
};

export default CSSModules(EventPermissionsSidebar, css);
