import PropTypes from "prop-types";
import React, { Component } from "react";
import { get, first } from "lodash";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class QuantityInput extends Component {
  render() {
    const { disabled, selectedCredentials, column, className } = this.props;
    const id = first(get(column, "settings.availableCredentials", []));
    const quantity = get(selectedCredentials, ["0", "quantity"]);

    return (
      <input
        type="number"
        min="0"
        className={["form-control", css.input, className].join(" ")}
        value={quantity}
        onChange={e => this.props.updateQuantity({ id }, e.target.value || 0)}
        disabled={disabled}
      />
    );
  }
}

QuantityInput.propTypes = {
  className: PropTypes.string,
  updateQuantity: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  selectedCredentials: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired
};

export default QuantityInput;
