import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { Div, Text5 } from "components/Base";
import { NumberPill } from "Orders/OrderModal/View/Common";

import { getters, actions } from "Orders/OrderModal";
import { getTabs } from "Orders/OrderModal/selectors";

const defaultStyles = {
  display: "row.center.center",
  py: 3,
  px: 8
};

const getTabStyles = R.ifElse(
  R.equals(true),
  R.always(
    R.merge(defaultStyles, {
      bg: "white",
      bt: 1,
      br: 1,
      bl: 1,
      bc: "neutral3"
    })
  ),
  R.always(
    R.merge(defaultStyles, {
      bg: "neutral1",
      bb: 1,
      bc: "neutral3"
    })
  )
);

const decorate = connect(
  (state, props) => ({
    selectedTab: getters.selectedTab(state),
    tabs: getTabs(state, { handlers: props.handlers })
  }),
  {
    selectTab: actions.setSelectedTab
  }
);

const Tabs = ({ tabs, selectedTab, selectTab }) => (
  <Div display="row">
    {R.map(
      tab => (
        <Div
          {...getTabStyles(tab.id === selectedTab)}
          style={{ position: "relative", bottom: -1, zInxex: 2 }}
          onClick={() => selectTab(tab.id)}
          key={tab.id}
        >
          <Text5 bold>{tab.name}</Text5>
          {tab.count ? (
            <NumberPill ml={5} alert={tab.alert}>
              {tab.count}
            </NumberPill>
          ) : null}
        </Div>
      ),
      tabs
    )}
  </Div>
);

export default decorate(Tabs);
