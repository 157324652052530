import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getters } from "Orders/ImportModal";

import Header from "Orders/ImportModal/View/Header";
import Body from "Orders/ImportModal/View/Body";
import Footer from "Orders/ImportModal/View/Footer";
import Loading from "Orders/ImportModal/View/Loading";

import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import { WIZARD_PAGE } from "Orders/ImportModal/constants";

const decorate = connect(state => ({
  loading: getters.loading(state),
  page: getters.wizardPage(state)
}));

const modalHeight = {
  [WIZARD_PAGE.REVIEW]: 600,
  [WIZARD_PAGE.BULK_IMPORT]: null
};

const Layout = ({ page = "", loading = false }) => (
  <MiniModalWrapper
    showHeader={false}
    showModal={true}
    stopPropagation
    bg="white"
    style={{
      minWidth: 1020,
      height:
        modalHeight[page] === null ? "calc(100% - 100px)" : modalHeight[page],
      overflow: page !== WIZARD_PAGE.REVIEW ? "hidden" : "unset"
    }}
  >
    {loading && <Loading />}
    <Header />
    <Body style={{ overflow: "hidden" }} />
    <Footer />
  </MiniModalWrapper>
);

Layout.propTypes = {
  loading: PropTypes.bool.isRequired,
  page: PropTypes.string.isRequired
};

export default decorate(Layout);
