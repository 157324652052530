import React, { useState } from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { actions } from "../model";

import { Div, MediumFilledButton, MediumClearButton } from "components/Base";

import Popover from "@lennd/material-ui/Popover";

import { FIELDS_ON_POPOVER } from "../constants";
import { withProps, noop } from "utils/General";

const EditorContainer = withProps({
  width: 0.7,
  p: 1
})(Div);

const decorate = connect(
  null,
  {
    onDone: actions.onSavePopoverEditor,
    onClose: () => actions.edit(null)
  }
);

const PopoverEditor = decorate(
  ({ children, anchorEl, fieldId, onDone = noop, onClose = noop }) => {
    const [open, setOpen] = useState(true);
    return (
      <Popover
        open={open}
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        onClose={onClose}
      >
        <Div
          display="column.flex-start.stretch"
          onClick={e => e.stopPropagation()}
        >
          <Div p={2}>{children}</Div>
          <Div display="row.space-between.center" px={2} pb={2}>
            <MediumFilledButton
              bg="primary7"
              flex={1}
              onClick={() => {
                onDone({ fieldId });
                setOpen(false);
              }}
            >
              Done
            </MediumFilledButton>
            <MediumClearButton
              mr={1}
              flex={1}
              ml={1}
              onClick={() => {
                setOpen(false);
                onClose();
              }}
            >
              Cancel
            </MediumClearButton>
          </Div>
        </Div>
      </Popover>
    );
  }
);

const EditorWrapper = ({ children, anchorEl, fieldId, type }) => (
  <>
    {R.contains(type, FIELDS_ON_POPOVER) ? (
      <PopoverEditor anchorEl={anchorEl} fieldId={fieldId}>
        {children}
      </PopoverEditor>
    ) : (
      <EditorContainer onClick={e => e.stopPropagation()}>
        {children}
      </EditorContainer>
    )}
  </>
);

export default EditorWrapper;
