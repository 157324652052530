import React from "react";
import { Helmet } from "react-helmet";

import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { connect } from "react-redux";

const decorate = connect(state => ({
  eventDetails: getEventDetails(state)
}));

const PageTitle = ({ eventDetails, titles = [] }) => {
  return (
    <>
      <Helmet>
        <title>
          {titles.join(" - ")} - {eventDetails.name} | Lennd
        </title>
      </Helmet>
    </>
  );
};

export default decorate(PageTitle);
