import { createHandlers } from "redux-mvc";
import * as R from "ramda";
import { NAMESPACE } from "Toolbar/QuickActionsNavigation/constants";

const iniState = {
  loading: false,
  filter: "",
  salesPortal: {},
  lastFetched: null
};

const handlers = createHandlers({
  iniState,
  reducers: {
    cancelInstance: R.always(iniState),
    init: R.identity,
    openMenu: R.identity
  },
  namespace: NAMESPACE
});

export default handlers;
