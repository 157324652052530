import React from "react";
import * as R from "ramda";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import { getters } from "../model";

import { Div, BigFilledButton, RightIcon } from "components/Base";
import MealDays from "./MealDays";
import MealsTotal from "./MealsTotal";

const decorate = R.compose(
  withRouter,
  connect(state => ({
    data: getters.data(state)
  }))
);

const Dashboard = ({
  data: { count_of_requests_for_approval },
  params: { eventId },
  router: { push }
}) => (
  <Div
    width={1}
    height={1}
    style={{ overflow: "auto", height: "calc(100vh - 116px)" }}
    bg="neutral1"
  >
    <Div width={1} px={6} pb={6}>
      <Div display="row" width={1} mt={4}>
        <Div width={315} mr={6}>
          {count_of_requests_for_approval > 0 && (
            <Div width={1} bg="white" p={4} bra={2} mb={2}>
              <Div width={1} display="row.space-between.center" mb={2}>
                <Div fw={3} fs={3} color="gray7">
                  For your approval
                </Div>
              </Div>
              <BigFilledButton
                RightIcon={RightIcon}
                bg="primary7"
                width={1}
                display="row.space-between.center"
                px={2}
                onClick={() => {
                  push(
                    `/event-light/${eventId}/catering/requests/all-requests`
                  );
                }}
              >
                {`${count_of_requests_for_approval} requests to review`}
              </BigFilledButton>
            </Div>
          )}
          <MealsTotal />
        </Div>
        <MealDays />
      </Div>
    </Div>
  </Div>
);

export default decorate(Dashboard);
