import PropTypes from "prop-types";
import React, { Component } from "react";
import md5 from "md5";

class Gravatar extends Component {
  render() {
    const hash = this.props.email
      ? md5(this.props.email.trim().toLowerCase())
      : "";
    const base = "https://secure.gravatar.com/avatar/";
    let query = `s=${this.props.size}&r=${this.props.rating}&d=${this.props.missing}`;

    if (!this.props.email) {
      query = `d=${this.props.missing}`;
    }

    let src = `${base}${hash}/?${query}`;

    if (this.props.url) {
      src = this.props.url;
    }

    return (
      <div
        {...this.props}
        style={{
          borderRadius: 3,
          width: this.props.size,
          height: this.props.size,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundImage: `url(${src})`,
          ...this.props.style
        }}
      />
    );
  }
}

Gravatar.propTypes = {
  url: PropTypes.string,
  email: PropTypes.string,
  size: PropTypes.number,
  rating: PropTypes.string,
  missing: PropTypes.string,
  style: PropTypes.object
};

Gravatar.defaultProps = {
  size: 40,
  missing: "mm",
  rating: "g",
  email: "",
  url: ""
};

export default Gravatar;
