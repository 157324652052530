import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "Portal/PortalPeople/model";
import Body from "./Body";
import { getBackgroundImageUrl } from "redux/modules/portal/selectors";
import {
  getPeopleTitle,
  getPeopleDescription
} from "redux/modules/portal/settings/selectors";
import Page from "Portal/Page";

const decorate = connect(
  (state, props) => ({
    title: getPeopleTitle(state, props),
    description: getPeopleDescription(state, props),
    backgroundImageUrl: getBackgroundImageUrl(state, props)
  }),
  { init: actions.init }
);

const Layout = ({ title, description, backgroundImageUrl }) => {
  return (
    <div>
      <Page
        instanceId="people"
        title={title}
        description={description}
        backgroundImageUrl={backgroundImageUrl}
      >
        <Body />
      </Page>
    </div>
  );
};

export default decorate(Layout);
