import Helpers from "utils/Global/Helpers";
import getQuery from "redux/modules/modules/utils/get-query";

/* eslint no-underscore-dangle: "off" */

export default {
  getRelatedModules: ({ credentials, moduleId, options }) =>
    new Promise((success, error) => {
      const query = getQuery(options);

      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/modules/${moduleId}/related-modules${query || ""}`,
        credentials,
        success,
        error
      });
    })
};
