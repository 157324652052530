import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const ParagraphContainer = ({ children, ...props }) => (
  <div {...props} styleName="paragraphContainer">
    {children}
  </div>
);

ParagraphContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export default CSSModules(ParagraphContainer, css);
