import React, { Component } from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import { Div, Text2 } from "components/Base";

import { RadioButton } from "material-ui/RadioButton";
import CheckBox from "material-ui/Checkbox";

import { actions, getters } from "ui-kit/DoubleSelect";
import { toString } from "utils/General";

import { bindInstance } from "redux-mvc";

import { getSelectedSubOptions } from "ui-kit/DoubleSelect/selectors";

const decorate = connect(
  (state, props) => ({
    selectedOption: getters.selectedOption(state, props),
    selectedSubOptions: getSelectedSubOptions(state, props)
  }),
  bindInstance({
    toggleSubOption: actions.toggleSubOption,
    selectOption: actions.selectOption,
    init: actions.init
  })
);

class SelectView extends Component {
  constructor(props) {
    super(props);

    this.props.init(this.props.iniSelected || {});
  }
  render() {
    const {
      options,
      selectedOption,
      selectedSubOptions,
      selectOption,
      toggleSubOption,
      iniSelected, // eslint-disable-line no-unused-vars
      init, // eslint-disable-line no-unused-vars
      ...styleProps
    } = this.props;
    return (
      <Div {...styleProps}>
        {options.map(option => (
          <Div key={option.id} mb={3}>
            <Div>
              <RadioButton
                key={option.id}
                value={option.id}
                checked={R.equals(toString(option.id), selectedOption)}
                onClick={() => selectOption(toString(option.id))}
                label={
                  <Text2
                    bold
                    color={
                      R.equals(option.id, selectedOption)
                        ? "primary7"
                        : "neutral8"
                    }
                  >
                    {option.name}
                  </Text2>
                }
                iconStyle={{ alignSelf: "center" }}
                labelStyle={{ width: "100%" }}
                style={{ marginBottom: 5 }}
              />
            </Div>
            {option.options.map(subOption => (
              <Div
                key={subOption.id}
                display="row.flex-start.center"
                onClick={() =>
                  toggleSubOption(toString(subOption.id), { meta: { option } })
                }
                px={4}
              >
                <CheckBox
                  checked={R.has(toString(subOption.id), selectedSubOptions)}
                  style={{ width: "auto" }}
                />

                <Text2
                  bold
                  color={
                    R.has(toString(subOption.id), selectedSubOptions)
                      ? "primary7"
                      : "neutral8"
                  }
                >
                  {subOption.name}
                </Text2>
              </Div>
            ))}
          </Div>
        ))}
      </Div>
    );
  }
}

export default decorate(SelectView);
