import * as R from "ramda";
import { createSelector } from "reselect";

export const isPassesRoute = createSelector(
  R.prop("routes"),
  R.compose(
    R.not,
    R.isNil,
    R.find(r => r.name === "ModulePasses" || r.name === "ModuleCatering")
  )
);
