import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Accounts from "./Accounts";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import {
  addRecords,
  deleteRecord,
  updateType
} from "redux/modules/modules/records/actions";
import { bulkRelateRecords } from "redux/modules/accounts/values/actions";
import { addValues } from "redux/modules/modules/values/actions";
import { deselectAllRows } from "redux/modules/modules/records/settings/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";

import { orgDetails } from "redux/modules/organization/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import { fields } from "redux/modules/modules/records/selectors";
import { moduleDetails } from "redux/modules/modules/module/selectors";
import { recordTypes } from "redux/modules/modules/recordTypes/selectors";
import { records } from "redux/modules/modules/records/selectors";
import { references } from "redux/modules/entityReferences/selectors";
import { selectedRows } from "redux/modules/modules/records/settings/selectors";
import { user } from "redux/modules/user/selectors";

function mapStateToProps(state, props) {
  const selectedRowIds = selectedRows(state, props.moduleId);

  return {
    orgDetails: orgDetails(state),
    eventDetails: eventDetails(state),
    fields: fields(state, props.moduleId),
    module: moduleDetails(state, props.moduleId),
    records: records(state, props.moduleId).filter(a =>
      selectedRowIds.includes(a.id)
    ),
    recordTypes: recordTypes(state, props.moduleId),
    references: references(state),
    selectedRows: selectedRowIds,
    user: user(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        showSnackbar,
        showModal,
        hideModal,
        addValues,
        addRecords,
        deleteRecord,
        updateType,
        bulkRelateRecords,
        deselectAllRows: () => deselectAllRows(STANDARD_MODULE_IDS.accounts.id)
      },
      dispatch
    )
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Accounts);
