import PropTypes from "prop-types";
import React, { Component } from "react";
import Helpers from "utils/Global/Helpers";

class FileIcon extends Component {
  static propTypes = {
    mimetype: PropTypes.string,
    size: PropTypes.number
  };

  static defaultProps = {
    mimetype: ""
  };

  render() {
    let icon;
    if (Helpers.isImage(this.props.mimetype)) {
      icon = (
        <svg preserveAspectRatio="xMidYMid meet" viewBox="0 0 100 139.1">
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4687C7"
              d="M4,0.1h63.1L100,33.2v101.9c0,2.2-1.8,4-4,4H4c-2.2,0-4-1.8-4-4V4.1C0,1.9,1.8,0.1,4,0.1z"
            />
            <path
              fill="#FFFFFF"
              d="M13.8,82.4c-0.5,0-1-0.2-1.3-0.6c-0.7-0.7-0.6-1.9,0.1-2.5L35.8,58c0.4-0.3,0.8-0.5,1.3-0.5c0.5,0,0.9,0.2,1.2,0.6L58,79.5c0.7,0.7,0.6,1.9-0.1,2.5c-0.7,0.7-1.9,0.6-2.5-0.1L36.9,61.8L15,82C14.7,82.3,14.3,82.4,13.8,82.4z"
            />
            <path
              fill="#FFFFFF"
              d="M85.2,93.1c-0.5,0-0.9-0.2-1.3-0.5L65.6,74.3l-13,13c-0.7,0.7-1.8,0.7-2.5,0c-0.7-0.7-0.7-1.8,0-2.5l14.3-14.3c0.7-0.7,1.8-0.7,2.5,0l19.6,19.6c0.7,0.7,0.7,1.8,0,2.5C86.1,93,85.6,93.1,85.2,93.1z"
            />
            <path
              fill="#FFFFFF"
              d="M57.5,50.1c-5.3,0-9.5,4.3-9.5,9.5s4.3,9.5,9.5,9.5c5.3,0,9.5-4.3,9.5-9.5S62.8,50.1,57.5,50.1z M57.5,65.1c-3,0-5.5-2.5-5.5-5.5c0-3,2.5-5.5,5.5-5.5c3,0,5.5,2.5,5.5,5.5C63,62.7,60.5,65.1,57.5,65.1z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#387DC0"
              d="M67,29.1V0l33,33.1H71"
            />
          </g>
        </svg>
      );
    }
    if (Helpers.isPDF(this.props.mimetype)) {
      icon = (
        <svg preserveAspectRatio="xMidYMid meet" viewBox="0 0 100.3 138.8">
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F15641"
              d="M4,0.1h63.3l33,33v101.7c0,2.2-1.8,4-4,4H4c-2.2,0-4-1.8-4-4V4.1C0,1.9,1.8,0.1,4,0.1z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E54B36"
              d="M67.2,29.1V0l33.1,33.1H71.2"
            />
            <path
              fill="#FFFFFF"
              d="M27.9,105.8L27.9,105.8c-0.8,0-1.6-0.3-2.3-0.8c-2.4-1.9-2.8-3.9-2.6-5.4c0.4-3.9,5.1-8,14-12.1c3.5-7.9,6.9-17.6,8.9-25.7c-2.3-5.2-4.6-11.9-2.9-15.9c0.6-1.4,1.6-2.4,2.9-2.8c0.5-0.2,1.1-0.3,1.5-0.3c1.2,0,2.2,0.5,3,1.5c0.7,0.9,2.8,3.6-0.4,17.1c3.2,6.7,7.6,13.5,11.9,18.1c3.1-0.6,5.7-0.9,7.9-0.9c3.7,0,5.9,0.9,6.8,2.7c0.7,1.5,0.4,3.2-0.9,5.2c-1.3,1.9-3.1,2.9-5.2,2.9c-2.8,0-6.2-1.8-9.9-5.5c-6.6,1.4-14.4,4-20.6,6.8c-2,4.2-3.8,7.7-5.6,10.2C32,104.2,29.9,105.8,27.9,105.8z M34.1,93.5c-5,2.9-7,5.2-7.2,6.6c0,0.2-0.1,0.8,1,1.7C28.3,101.6,30.3,100.7,34.1,93.5zM65.6,82.9c1.9,1.5,3.6,2.3,4.8,2.3c0.5,0,1.3-0.1,2-1.2c0.3-0.5,0.5-0.8,0.5-1c-0.3-0.2-1.1-0.5-3.2-0.5C68.6,82.6,67.2,82.7,65.6,82.9z M48.5,67.1c-1.7,5.9-3.9,12.3-6.3,18.1c4.9-1.9,10.2-3.6,15.2-4.8C54.3,76.7,51.2,72,48.5,67.1zM47.3,46.8c-0.1,0-0.1,0-0.2,0.1c-0.2,0.1-0.4,0.2-0.6,0.6c-0.6,1.4-0.1,4.2,0.8,7.1C48.1,49.5,47.7,47.4,47.3,46.8z"
            />
          </g>
        </svg>
      );
    }
    if (Helpers.isSpreadsheet(this.props.mimetype)) {
      icon = (
        <svg preserveAspectRatio="xMidYMid meet" viewBox="0 -19.8 100 139.1">
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#34B565"
              d="M4-19.7h63.1L100,13.4v101.9c0,2.2-1.8,4-4,4H4c-2.2,0-4-1.8-4-4v-131C0-17.9,1.8-19.7,4-19.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25A55A"
              d="M67,9.3v-29.1l33,33.1H71"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M19,20.6v16.7v3v14.1v2.9v14.3v2.6v17.1h62V74.2v-2.6V57.3v-2.9V40.3v-3V20.6H19z M36,88.3H22v-14h14V88.3z M36,71.3H22v-14h14V71.3z M36,54.3H22v-14h14V54.3z M36,37.3H22v-14h14V37.3z M78,88.3H39v-14h39V88.3z M78,71.3H39v-14h39V71.3z M78,54.3H39v-14h39V54.3z M78,37.3H39v-14h39V37.3z"
            />
          </g>
        </svg>
      );
    }
    if (Helpers.isPowerpoint(this.props.mimetype)) {
      icon = (
        <svg preserveAspectRatio="xMidYMid meet" viewBox="0 -19.8 100 139.1">
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F05641"
              d="M4-19.7h63.1L100,13.4v101.9c0,2.2-1.8,4-4,4H4c-2.2,0-4-1.8-4-4
                    v-131C0-17.9,1.8-19.7,4-19.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E44B36"
              d="M67,9.3v-29.1l33,33.1H71"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M49,56.3h20c0,11.6-9.3,21-20.9,21C36.4,77.3,27,68,27,56.4
                    c0-11.6,9.4-21.2,21.1-21.2c0.3,0,0.6,0.1,0.9,0.1V56.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M53,52.3v-21c11.6,0,21,9.4,21,21L53,52.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M16,88.3h68v3H16V88.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M16,19.3h68v3H16V19.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M46,91.3h8v3h-8V91.3z"
            />
          </g>
        </svg>
      );
    }
    if (Helpers.isWordDocument(this.props.mimetype)) {
      icon = (
        <svg preserveAspectRatio="xMidYMid meet" viewBox="0 0 100 139.1">
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#66CDEE"
              d="M4,0.1h63.1L100,33.2v101.9c0,2.2-1.8,4-4,4H4
                    c-2.2,0-4-1.8-4-4V4.1C0,1.9,1.8,0.1,4,0.1z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M18,54.1h64v5H18V54.1z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M18,66.1h64v5H18V66.1z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M18,79.1h64v5H18V79.1z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M18,91.1h64v5H18V91.1z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M18,103.1h26v5H18V103.1z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M37,42.1h26v5H37V42.1z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#59BCD9"
              d="M67,29.1V0l33,33.1H71"
            />
          </g>
        </svg>
      );
    }
    if (Helpers.isZipFile(this.props.mimetype)) {
      icon = (
        <svg preserveAspectRatio="xMidYMid meet" viewBox="0 -19.8 100 139.1">
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#363956"
              d="M4-19.7h63.1L100,13.4v101.9c0,2.2-1.8,4-4,4H4c-2.2,0-4-1.8-4-4
                    v-131C0-17.9,1.8-19.7,4-19.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M67,9.3v-29.1l33,33.1H71"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="none"
              d="M45,48.3h10v9H45V48.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="none"
              d="M45,58.3h10v4H45V58.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M44,47.3h12v16H44V47.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45,43.3h5v2h-5V43.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,41.3h5v2h-5V41.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,45.3h5v2h-5V45.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45,39.3h5v2h-5V39.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45,35.3h5v2h-5V35.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,33.3h5v2h-5V33.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,37.3h5v2h-5V37.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45,31.3h5v2h-5V31.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45,27.3h5v2h-5V27.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,25.3h5v2h-5V25.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,29.3h5v2h-5V29.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45,23.3h5v2h-5V23.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45,19.3h5v2h-5V19.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,17.3h5v2h-5V17.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,21.3h5v2h-5V21.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45,15.3h5v2h-5V15.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45,11.3h5v2h-5V11.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,9.3h5v2h-5V9.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,13.3h5v2h-5V13.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45,7.3h5v2h-5V7.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45,3.3h5v2h-5V3.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,1.3h5v2h-5V1.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,5.3h5v2h-5V5.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45-0.7h5v2h-5V-0.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45-4.7h5v2h-5V-4.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50-6.7h5v2h-5V-6.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50-2.7h5v2h-5V-2.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45-8.7h5v2h-5V-8.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45-12.7h5v2h-5V-12.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50-14.7h5v2h-5V-14.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50-10.7h5v2h-5V-10.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45-16.7h5v2h-5V-16.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45-19.7h5v1h-5V-19.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50-18.7h5v2h-5V-18.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M47-19.7h6v67h-6V-19.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M45,48.3h10v9H45V48.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M45,58.3h10v4H45V58.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45,43.3h5v2h-5V43.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,41.3h5v2h-5V41.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,45.3h5v2h-5V45.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45,39.3h5v2h-5V39.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45,35.3h5v2h-5V35.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,33.3h5v2h-5V33.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,37.3h5v2h-5V37.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45,31.3h5v2h-5V31.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45,27.3h5v2h-5V27.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,25.3h5v2h-5V25.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,29.3h5v2h-5V29.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45,23.3h5v2h-5V23.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45,19.3h5v2h-5V19.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,17.3h5v2h-5V17.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,21.3h5v2h-5V21.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45,15.3h5v2h-5V15.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45,11.3h5v2h-5V11.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,9.3h5v2h-5V9.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,13.3h5v2h-5V13.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45,7.3h5v2h-5V7.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45,3.3h5v2h-5V3.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,1.3h5v2h-5V1.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50,5.3h5v2h-5V5.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45-0.7h5v2h-5V-0.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45-4.7h5v2h-5V-4.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50-6.7h5v2h-5V-6.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50-2.7h5v2h-5V-2.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45-8.7h5v2h-5V-8.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45-12.7h5v2h-5V-12.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50-14.7h5v2h-5V-14.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50-10.7h5v2h-5V-10.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45-16.7h5v2h-5V-16.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M45-19.7h5v1h-5V-19.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#25273B"
              d="M50-18.7h5v2h-5V-18.7z"
            />
          </g>
        </svg>
      );
    }
    if (Helpers.isAudio(this.props.mimetype)) {
      icon = (
        <svg preserveAspectRatio="xMidYMid meet" viewBox="0 -19.8 100 139.1">
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F0646A"
              d="M4-19.7h63.1L100,13.4v101.9c0,2.2-1.8,4-4,4H4c-2.2,0-4-1.8-4-4v-131C0-17.9,1.8-19.7,4-19.7z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E55A5E"
              d="M67,9.3v-29.1l33,33.1H71"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M76.3,31.4c-6.1-5.6-12.9-5.2-15.3-4.9l-0.1,39.7c0.1,0.6,0.2,1.2,0.2,1.8c0,6.2-6.8,11.3-15.1,11.3c-8.3,0-15.1-5.1-15.1-11.3c0-6.2,6.8-11.3,15.1-11.3c5.1,0,9.2,1.6,11.9,4.6v-37l1.4-0.5c0.4-0.1,10.2-2.6,18.6,5.3L76.3,31.4z"
            />
          </g>
        </svg>
      );
    }
    if (Helpers.isVideo(this.props.mimetype)) {
      icon = (
        <svg preserveAspectRatio="xMidYMid meet" viewBox="0 0 100 139">
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F15641"
              d="M4,0h63.1L100,33.1V135c0,2.2-1.8,4-4,4H4c-2.2,0-4-1.8-4-4V4C0,1.8,1.8,0,4,0z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E54B36"
              d="M67,29V-0.1L100,33H71"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#FFFFFF"
              d="M79.9,95H20.1c-2.3,0-4.1-1.8-4.1-4.1V47.1c0-2.3,1.8-4.1,4.1-4.1h59.8c2.3,0,4.1,1.8,4.1,4.1v43.8C84,93.2,82.2,95,79.9,95z M23,46h-2.7c-0.7,0-1.3,0.6-1.3,1.4v1.4h4V46z M23,51.5h-4v2.8h4V51.5zM23,57.1h-4v2.8h4V57.1z M23,62.6h-4v2.8h4V62.6z M23,68.1h-4v2.8h4V68.1z M23,73.7h-4v2.8h4V73.7z M23,79.2h-4v2.8h4V79.2zM23,84.7h-4v2.8h4V84.7z M23,90.2h-4v1.4c0,0.8,0.6,1.4,1.3,1.4H23V90.2z M26,93h48V46H26V93z M81,47.4c0-0.8-0.6-1.4-1.3-1.4H77v2.8h4V47.4z M81,51.5h-4v2.8h4V51.5z M81,57.1h-4v2.8h4V57.1z M81,62.6h-4v2.8h4V62.6z M81,68.1h-4v2.8h4V68.1z M81,73.7h-4v2.8h4V73.7z M81,79.2h-4v2.8h4V79.2z M81,84.7h-4v2.8h4V84.7z M81,90.2h-4V93h2.7c0.7,0,1.3-0.6,1.3-1.4V90.2z M44,78l0-19l16,9.5L44,78zM46.7,73.2l7.9-4.7l-7.9-4.7L46.7,73.2z"
            />
          </g>
        </svg>
      );
    }
    if (Helpers.isIllustrator(this.props.mimetype)) {
      icon = (
        <svg preserveAspectRatio="xMidYMid meet" viewBox="0 -19.8 100.3 138.8">
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#F38E21"
              d="M4-19.7h63.3l33,33V115c0,2.2-1.8,4-4,4H4c-2.2,0-4-1.8-4-4V-15.7C0-17.9,1.8-19.7,4-19.7z"
            />
            <path
              fill="#FFFFFF"
              d="M45.7,30.8h-7.2L23.8,68.4h5.4c0.6,0,1.1-0.2,1.5-0.5c0.4-0.3,0.7-0.7,0.8-1.1l2.8-7.7h15.6l2.8,7.7c0.2,0.5,0.5,0.8,0.8,1.1c0.4,0.3,0.9,0.5,1.5,0.5h5.4L45.7,30.8z M40.7,41.8c0.2-0.6,0.5-1.2,0.7-2c0.2-0.8,0.5-1.6,0.7-2.5c0.2,0.9,0.5,1.7,0.7,2.5c0.3,0.8,0.5,1.4,0.7,2l4.6,12.5h-12L40.7,41.8z"
            />
            <path
              fill="#FFFFFF"
              d="M64.4,41.7v26.7h6.4V41.7H64.4z M71.4,32.3c-0.2-0.5-0.5-0.9-0.9-1.3c-0.4-0.4-0.8-0.7-1.3-0.9c-0.5-0.2-1-0.3-1.6-0.3c-0.6,0-1.1,0.1-1.6,0.3c-0.5,0.2-0.9,0.5-1.3,0.9c-0.4,0.4-0.7,0.8-0.9,1.3c-0.2,0.5-0.3,1-0.3,1.6c0,0.6,0.1,1.1,0.3,1.6c0.2,0.5,0.5,0.9,0.9,1.3c0.4,0.4,0.8,0.7,1.3,0.9c0.5,0.2,1,0.3,1.6,0.3c0.6,0,1.1-0.1,1.6-0.3c0.5-0.2,0.9-0.5,1.3-0.9c0.4-0.4,0.7-0.8,0.9-1.3c0.2-0.5,0.3-1,0.3-1.6C71.8,33.4,71.6,32.8,71.4,32.3z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#E17F26"
              d="M67.2,9.3v-29.1l33.1,33.1H71.2"
            />
          </g>
        </svg>
      );
    }
    if (Helpers.isPhotoshop(this.props.mimetype)) {
      icon = (
        <svg preserveAspectRatio="xMidYMid meet" viewBox="0 -19.8 100 139.1">
          <g>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#4686C6"
              d="M4-19.7h63.1L100,13.4v101.9c0,2.2-1.8,4-4,4H4c-2.2,0-4-1.8-4-4v-131C0-17.9,1.8-19.7,4-19.7z"
            />
            <path
              fill="#FFFFFF"
              d="M37.8,55.5c2.4,0,4.6-0.3,6.4-0.9c1.8-0.6,3.3-1.5,4.5-2.5c1.2-1.1,2.1-2.4,2.7-3.9c0.6-1.5,0.9-3.2,0.9-5c0-1.8-0.3-3.4-0.9-4.8c-0.6-1.5-1.5-2.7-2.7-3.8c-1.2-1.1-2.7-1.9-4.5-2.5c-1.8-0.6-4-0.9-6.5-0.9H25.5v37.6h7V55.5H37.8zM32.5,36.6h5.3c1.3,0,2.4,0.2,3.4,0.5c1,0.3,1.7,0.7,2.4,1.3c0.6,0.6,1.1,1.3,1.4,2.1c0.3,0.8,0.5,1.7,0.5,2.7c0,1.1-0.2,2-0.5,2.9c-0.3,0.9-0.8,1.6-1.4,2.2c-0.6,0.6-1.4,1.1-2.4,1.4c-1,0.3-2.1,0.5-3.4,0.5h-5.3V36.6z"
            />
            <path
              fill="#FFFFFF"
              d="M74.7,45c-1-1-2.3-1.8-3.8-2.4c-1.5-0.6-3.2-0.9-5-0.9c-1.6,0-3.1,0.2-4.4,0.7c-1.3,0.4-2.3,1-3.1,1.8c-0.8,0.7-1.5,1.6-1.9,2.6c-0.4,1-0.6,2-0.6,3.1c0,1.2,0.2,2.2,0.6,3c0.4,0.8,0.9,1.5,1.5,2.1c0.6,0.6,1.3,1,2.1,1.4c0.8,0.4,1.6,0.7,2.4,1c0.8,0.3,1.6,0.5,2.4,0.8c0.8,0.2,1.5,0.5,2.1,0.8c0.6,0.3,1.1,0.6,1.5,1c0.4,0.4,0.6,0.9,0.6,1.5c0,0.4-0.1,0.8-0.2,1.2c-0.2,0.4-0.4,0.7-0.8,1c-0.4,0.3-0.8,0.5-1.4,0.7c-0.5,0.2-1.2,0.3-2,0.3c-1,0-1.8-0.1-2.4-0.3c-0.6-0.2-1.2-0.5-1.7-0.7c-0.5-0.3-0.9-0.5-1.2-0.7c-0.4-0.2-0.7-0.3-1.1-0.3c-0.4,0-0.7,0.1-1,0.2c-0.3,0.2-0.5,0.4-0.7,0.7l-1.5,2.4c0.5,0.5,1.1,0.9,1.8,1.3c0.7,0.4,1.4,0.7,2.2,1c0.8,0.3,1.6,0.5,2.5,0.7c0.9,0.2,1.8,0.2,2.7,0.2c1.7,0,3.2-0.2,4.6-0.7s2.4-1.1,3.3-1.8c0.9-0.8,1.6-1.7,2.1-2.8c0.5-1.1,0.7-2.3,0.7-3.5c0-1.1-0.2-2-0.6-2.8c-0.4-0.8-0.9-1.4-1.5-2c-0.6-0.5-1.3-1-2.1-1.4c-0.8-0.4-1.6-0.7-2.4-0.9c-0.8-0.3-1.6-0.5-2.4-0.8c-0.8-0.2-1.5-0.5-2.1-0.8c-0.6-0.3-1.1-0.6-1.5-1c-0.4-0.4-0.6-0.9-0.6-1.4c0-0.9,0.4-1.6,1.1-2.1c0.7-0.5,1.7-0.8,3-0.8c0.8,0,1.5,0.1,2.1,0.3c0.6,0.2,1.1,0.4,1.6,0.6c0.5,0.2,0.9,0.4,1.2,0.6c0.4,0.2,0.7,0.3,1,0.3c0.3,0,0.5-0.1,0.7-0.2c0.2-0.1,0.4-0.3,0.5-0.6L74.7,45z"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill="#387DBF"
              d="M67,9.3v-29.1l33,33.1H71"
            />
          </g>
        </svg>
      );
    }

    return (
      <div
        style={{
          width: this.props.size,
          height: this.props.size * 1.4
        }}
      >
        {icon}
      </div>
    );
  }
}

export default FileIcon;
