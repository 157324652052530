import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import { find, get } from "lodash";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import InlineInput from "../InlineInput";
import getMetaData from "utils/value-types/get-meta-data";
import { Div } from "components/Base";

@CSSModules(css)
class EditProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeElement: ""
    };
  }

  @autobind
  onItemSave(data, fromModal) {
    this.props.updateField(data, this.props.fromModal);
    this.closeForm();
  }

  @autobind
  openForm(id) {
    if (!this.state.activeElement) {
      this.setState({ activeElement: id });
    }
  }

  @autobind
  closeForm() {
    this.setState({ activeElement: "" });
  }

  renderInput(bio, record) {
    const disabled =
      this.props.disabled || get(record.field, ["settings", "readOnly"]);
    const metaData = getMetaData({
      row: bio.values,
      rowId: record.id,
      field: record.field,
      eventDetails: this.props.eventDetails,
      eventId: get(this.props.eventDetails, "id"),
      userId: this.props.userId,
      references: this.props.references
    });
    return (
      <InlineInput
        key={`${record.field.id}`}
        activeElement={this.state.activeElement}
        closeForm={this.closeForm}
        disabled={disabled}
        eventDetails={this.props.eventDetails}
        field={record.field}
        handleSave={this.onItemSave}
        maxWidth={this.props.maxWidth}
        metaData={metaData}
        openForm={disabled ? () => {} : this.openForm}
        showBg={this.props.fromModal}
        readOnly={this.props.readOnly}
        value={get(bio.values, [record.field.id, "value"], {})}
      />
    );
  }

  @autobind
  newGetList(groupName) {
    const { bio } = this.props;
    const fieldSet = this.getFieldSet(groupName);

    if (typeof fieldSet !== "undefined") {
      return this.getSortedFields(fieldSet).map(record =>
        this.renderInput(bio, record)
      );
    }
    return null;
  }

  @autobind
  getStarredList() {
    const { bio } = this.props;
    return bio.starred_fields
      .sort((a, b) => a.order - b.order)
      .map(record => this.renderInput(bio, record));
  }

  @autobind
  fieldSetWithHeading(group) {
    const fieldSet = this.getFieldSet(group.name);
    if (fieldSet && fieldSet.fields.length) {
      return (
        <div key={`${group.id}`}>
          <h5 className={css.heading}>{group.name}</h5>
          <ul>{this.newGetList(group.name)}</ul>
        </div>
      );
    }
    return "";
  }

  @autobind
  allFields(fieldGroups) {
    return fieldGroups.map(fieldGroup => this.fieldSetWithHeading(fieldGroup));
  }

  // gets object from field_groups based on name of group
  @autobind
  getFieldSet(name) {
    const fieldSet = find(this.props.bio.field_groups, { name });
    return fieldSet || undefined;
  }

  // return array of sorted field names
  @autobind
  getSortedFields(fieldSet) {
    return fieldSet.fields.sort((a, b) => a.order - b.order);
  }

  render() {
    let info;
    if (this.props.bio && this.props.fieldSet) {
      info = this.newGetList(this.props.fieldSet);
    } else if (this.props.showStarredFields) {
      info = this.getStarredList();
    } else {
      if (!(this.props.bio && this.props.bio.field_groups)) {
        return (
          <ul>
            <li>
              <Div mt={4} display="column.flex-end.flex-start">
                <Div bg="neutral0" width={1} height={25} mb={1} />
              </Div>
            </li>
            <li>
              <Div display="column.flex-end.flex-start">
                <Div bg="neutral0" width={1} height={25} mb={1} />
              </Div>
            </li>
            <li>
              <Div display="column.flex-end.flex-start">
                <Div bg="neutral0" width={1} height={25} mb={1} />
              </Div>
            </li>
          </ul>
        );
      }
      info = this.allFields(this.props.bio.field_groups);
    }

    return <ul>{info}</ul>;
  }
}

EditProfile.propTypes = {
  bio: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  eventDetails: PropTypes.object.isRequired,
  fieldSet: PropTypes.string,
  fromModal: PropTypes.bool,
  maxWidth: PropTypes.string,
  styles: PropTypes.string.isRequired,
  updateField: PropTypes.func.isRequired,
  showStarredFields: PropTypes.bool
};

export default EditProfile;
