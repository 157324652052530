import React from "react";
import { connect } from "react-redux";
import { BigFilledButton, RightArrowIcon } from "components/Base";
import { actions, getters } from "HealthPass/HealthPassForm/model";
import {
  getNextButtonText,
  getNextButtonDisabled
} from "HealthPass/HealthPassForm/selectors";

const decorate = connect(
  state => ({
    text: getNextButtonText(state),
    disabled: getNextButtonDisabled(state),
    saving: getters.saving(state),
    searching: getters.searching(state)
  }),
  {
    nextPage: actions.nextPage
  }
);

const NextButton = ({ nextPage, text, disabled, saving, searching }) => (
  <BigFilledButton
    color="white"
    RightIcon={saving ? undefined : RightArrowIcon}
    bg="indigo7"
    style={{ borderRadius: "20px" }}
    onClick={
      saving || searching
        ? undefined
        : () => {
            nextPage();
          }
    }
    disabled={disabled}
  >
    {saving ? "Completing..." : text}
  </BigFilledButton>
);

export default decorate(NextButton);
