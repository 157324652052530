import { SHOW, HIDE } from "./constants";

export function showSidebar(moduleId = "default") {
  return {
    type: SHOW,
    moduleId
  };
}

export function hideSidebar(moduleId = "default") {
  return {
    type: HIDE,
    moduleId
  };
}
