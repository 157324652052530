import React, { Component } from "react";
import PropTypes from "prop-types";
import InternalHandler from "./Internal";
import EditSummaryModal from "Passes/Dashboard/SummaryPanel/EditSummaryModal";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";

const FIELD_OPTIONS = [
  { key: "requested", name: "Requested", visible: true },
  { key: "approved", name: "Approved", visible: true },
  { key: "issued", name: "Issued", visible: true, view: "issued" },
  { key: "budget", name: "Budget", visible: true },
  { key: "remaining", name: "Remaining", visible: true },
  { key: "progress", name: "Progress", visible: true }
];

class ExternalHandler extends Component {
  state = {
    loading: true
  };

  async componentDidMount() {
    await Promise.all([
      this.getItemsPendingApproval(),
      this.getActionItems(),
      this.getReviewProgress(),
      this.getCredentialsReports(),
      this.fetchDashboard(),
      this.props.getItemGroupsByEventAndType(
        this.props.params.eventId,
        CREDENTIAL_TYPE_ID
      )
    ]);
    return this.setState({ loading: false });
  }

  addSummary = async summary => {
    this.props.hideModal();
    await this.props.addWidget(summary);
    return this.fetchDashboard();
  };

  emptySummary = () => {
    return {
      name: "",
      type: "credentials-summary-v3",
      settings: {
        credentials: this.props.eventCredentials.map(c => c.id),
        fields: FIELD_OPTIONS,
        filters: {}
      },
      value: {
        credentials: this.props.eventCredentials
      },
      dashboardId: this.props.dashboard.id
    };
  };

  showSummaryModal = () => {
    this.props.showModal({
      content: (
        <EditSummaryModal
          summary={this.emptySummary()}
          cancel={this.props.hideModal}
          onSave={this.addSummary}
          eventCredentials={this.props.eventCredentials}
        />
      )
    });
  };

  getItemsPendingApproval = () => {};

  getActionItems = () => {};

  getReviewProgress = () => {};

  getCredentialsReports = () => {};

  fetchDashboard = () => {
    return this.props.getDashboard(
      "event_credentials_v3",
      this.props.params.eventId
    );
  };

  render() {
    return (
      <InternalHandler {...this} {...this.props} loading={this.state.loading} />
    );
  }
}

ExternalHandler.propTypes = {
  addOrder: PropTypes.func.isRequired,
  getDashboard: PropTypes.func.isRequired,
  createCharge: PropTypes.func.isRequired,
  getItemTypesByEvent: PropTypes.func.isRequired,
  dashboard: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  params: PropTypes.shape({
    eventId: PropTypes.string
  }).isRequired,
  eventCredentials: PropTypes.shape({
    id: PropTypes.string
  }).isRequired
};

export default ExternalHandler;
