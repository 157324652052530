import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Controller from "./Controller";

import * as SubmissionActions from "redux/modules/formsV2/submission/actions";
import * as ValueActions from "redux/modules/formsV2/submission/values/actions";
import * as SubmissionSelectors from "redux/modules/formsV2/submission/selectors";
import * as UserSelectors from "redux/modules/user/selectors";
import * as ReferenceValueSelectors from "redux/modules/formsV2/references/values/selectors";
import {
  cart,
  isFetchingBulk
} from "redux/modules/formsV2/submission/cart/selectors";
import { references } from "redux/modules/entityReferences/selectors";

import * as ModalActions from "redux/modules/modal/actions";
import * as SnackbarActions from "redux/modules/snackbar/actions";
import { updateContactValue } from "redux/modules/formsV2/form/fields/transactions/actions";
import { addReferenceValue } from "redux/modules/formsV2/references/values/actions";
import {
  removeItemFromCart,
  removeItemsFromCart
} from "redux/modules/formsV2/submission/cart/actions";

import { push } from "react-router-redux";
function mapStateToProps(state, props) {
  return {
    user: UserSelectors.user(state),
    cart: cart(state, props.params.submissionId),
    isFetchingBulk: isFetchingBulk(state),
    form: SubmissionSelectors.form(state),
    sortedFormFields: SubmissionSelectors.sortedFormFields(state),
    eventDetails: SubmissionSelectors.eventDetails(state),
    preferences: SubmissionSelectors.preferences(state),
    submission: SubmissionSelectors.submission(state),
    collaborators: SubmissionSelectors.collaborators(state),
    comments: SubmissionSelectors.comments(state),
    isFetching: SubmissionSelectors.isFetching(state),
    errorMessages: [state.formsV2.submission.error].filter(e => e),
    moduleRecordReferences: references(state),
    references: {
      values: ReferenceValueSelectors.values(state)
    }
  };
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(
      {
        ...SubmissionActions,
        ...ValueActions,
        ...ModalActions,
        ...SnackbarActions,
        updateContactValue,
        addReferenceValue,
        removeItemFromCart,
        removeItemsFromCart,
        push
      },
      dispatch
    ),
    dispatch
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
