import React from "react";
import { connect } from "react-redux";
import { createContext } from "redux-mvc";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import moment from "moment";
import MessageModal from "./MessageModal";
import Wrapper from "components/Global/Modal/Wrappers/Black";
import EmptyState from "components/PortalV2/Home/EmptyState";
import Page from "Portal/Page";

import {
  getUpdatesTitle,
  getUpdatesDescription
} from "redux/modules/portal/settings/selectors";
import { getBackgroundImageUrl } from "redux/modules/portal/selectors";

import * as R from "ramda";
import module from "./index";

const decorate = R.compose(
  createContext({ module }),
  connect(state => ({
    title: getUpdatesTitle(state),
    description: getUpdatesDescription(state),
    backgroundImageUrl: getBackgroundImageUrl(state)
  })),
  CSSModules(css)
);

const Update = CSSModules(
  ({ title, content_plaintext, updated_at, onClick }) => (
    <div styleName="message" onClick={onClick}>
      <div styleName="messageContent">
        <div styleName="messageTitle">{title}</div>
        <div styleName="messageBody">{content_plaintext}</div>
      </div>
      <div styleName="messageDate">{moment(updated_at).fromNow()}</div>
    </div>
  ),
  css
);

const Updates = ({
  messages,
  showModal,
  title,
  description,
  backgroundImageUrl
}) => {
  const handleClick = message => {
    showModal({
      content: <MessageModal {...message} />,
      wrapper: Wrapper
    });
  };

  const updates = messages.length ? (
    messages
      .sort((a, b) => Date.parse(b.updated_at) - Date.parse(a.updated_at))
      .map(message => (
        <Update
          key={message.updated_at}
          onClick={() => handleClick(message)}
          {...message}
        />
      ))
  ) : (
    <EmptyState title="There are no updates available." />
  );

  return (
    <Page
      instanceId="updates"
      title={title}
      description={description}
      backgroundImageUrl={backgroundImageUrl}
    >
      <div>{updates}</div>
    </Page>
  );
};

export default decorate(Updates);
