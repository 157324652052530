import React, { Component } from "react";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";
import moveItemInArray from "utils/move-item-in-array";
import * as R from "ramda";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import CredentialTypeModal from "components/Event/Settings/Catalog/Modals/ItemType";
import CredentialGroupModal from "components/Event/Settings/Module/Modals/FieldGroup";
import DeleteCredentialGroupModal from "components/Event/Settings/Credentials/Modals/DeleteCredentialGroup";
import InternalHandler from "./Internal";

class ExternalHandler extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }

  async componentDidMount() {
    await this.props.getItemGroupsByEventAndType(
      this.props.eventDetails.id,
      CREDENTIAL_TYPE_ID
    );

    this.setState({ loading: false });
  }

  /* credential groups */
  getCredentialGroups = () => {
    this.props.getItemGroupsByEventAndType(
      this.props.eventDetails.id,
      CREDENTIAL_TYPE_ID
    );
  };

  addCredentialGroup = data => {
    this.props
      .addItemGroup({
        eventId: this.props.eventDetails.id,
        typeId: CREDENTIAL_TYPE_ID,
        order: !R.isNil(
          R.path(
            ["itemGroups", this.props.itemGroups.length - 1, "order"],
            this.props
          )
        )
          ? this.props.itemGroups[this.props.itemGroups.length - 1].order + 1
          : 0,
        ...data
      })
      .then(() => {
        this.props.showSnackbar({ message: "Category added", action: "OK" });
        return this.getCredentialGroups();
      });
  };

  updateCredentialGroup = group => {
    this.props
      .updateItemGroup({
        groupId: group.id,
        group
      })
      .then(() => {
        this.props.showSnackbar({ message: "Category updated", action: "OK" });
        return this.getCredentialGroups();
      });
  };

  deleteCredentialGroup = data => {
    this.props
      .deleteItemGroup({
        groupId: data.groupId,
        replaceWithGroupId: data.replaceWithGroupId
      })
      .then(() => {
        this.props.showSnackbar({ message: "Category deleted", action: "OK" });
        return this.getCredentialGroups();
      });
  };

  moveGroupUp = currentPosition => {
    if (currentPosition === 0) return;
    this.props
      .bulkUpdateItemGroups({
        bulk: true,
        groups: moveItemInArray(
          this.props.itemGroups,
          currentPosition,
          currentPosition - 1
        ).map((g, order) => ({
          id: g.id,
          order
        }))
      })
      .then(() => this.getCredentialGroups());
  };

  moveGroupDown = currentPosition => {
    if (currentPosition === this.props.itemGroups.length - 1) return;
    this.props
      .bulkUpdateItemGroups({
        eventId: this.props.eventDetails.id,
        bulk: true,
        groups: moveItemInArray(
          this.props.itemGroups,
          currentPosition,
          currentPosition + 1
        ).map((g, order) => ({
          id: g.id,
          order
        }))
      })
      .then(() => this.getCredentialGroups());
  };

  /* credentials */

  reorderCredentials = (groupId, newOrder) => {
    this.props
      .bulkUpdateItems({
        eventId: this.props.eventDetails.id,
        bulk: true,
        items: newOrder.map(({ id }, i) => ({ id, order: i }))
      })
      .then(() => this.getCredentialGroups());
  };

  addCredential = data => {
    this.props.addItem(data).then(() => {
      this.props.showSnackbar({ message: "Credential added", action: "OK" });
      return this.getCredentialGroups();
    });
  };

  updateCredential = data => {
    this.props.updateItem(data).then(() => {
      this.props.showSnackbar({ message: "Credential updated", action: "OK" });
      return this.getCredentialGroups();
    });
  };

  deleteCredential = itemId => {
    this.props
      .deleteItem({
        itemId
      })
      .then(() => {
        this.props.showSnackbar({
          message: "Credential deleted",
          action: "OK"
        });
        return this.getCredentialGroups();
      });
  };

  moveCredentialUp = (groupId, currentPosition) => {
    const credentials = this.props.itemGroups.find(g => g.id === groupId).items;
    if (currentPosition === 0) return;
    this.props
      .bulkUpdateItems({
        eventId: this.props.eventDetails.id,
        bulk: true,
        items: moveItemInArray(
          credentials,
          currentPosition,
          currentPosition - 1
        ).map((g, order) => ({
          id: g.id,
          order
        }))
      })
      .then(() => this.getCredentialGroups());
  };

  moveCredentialDown = (groupId, currentPosition) => {
    const credentials = this.props.itemGroups.find(g => g.id === groupId).items;
    if (currentPosition === credentials.length - 1) return;
    this.props
      .bulkUpdateItems({
        eventId: this.props.eventDetails.id,
        bulk: true,
        items: moveItemInArray(
          credentials,
          currentPosition,
          currentPosition + 1
        ).map((g, order) => ({
          id: g.id,
          order
        }))
      })
      .then(() => this.getCredentialGroups());
  };

  /* modals */
  showAddCredentialGroupModal = () => {
    this.props.showModal({
      content: (
        <CredentialGroupModal
          label="Category"
          onSave={this.addCredentialGroup}
          hideModal={this.props.hideModal}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showUpdateCredentialGroupModal = group => {
    this.props.showModal({
      content: (
        <CredentialGroupModal
          label="Category"
          data={{
            ...group
          }}
          onSave={this.updateCredentialGroup}
          hideModal={this.props.hideModal}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showDeleteCredentialGroupModal = group => {
    this.props.showModal({
      content: (
        <DeleteCredentialGroupModal
          name={group.name}
          handleDelete={() =>
            this.deleteCredentialGroup({
              groupId: group.id,
              replaceWithGroupId: this.props.itemGroups.filter(
                g => g.id !== group.id
              )[0].id
            })
          }
          hideModal={this.props.hideModal}
        />
      )
    });
  };

  showCredentialModal = (id, clone = false) => {
    this.props.showModal({
      content: (
        <CredentialTypeModal
          onDone={id && !clone ? this.updateCredential : this.addCredential}
          credentialId={id}
          onUpdate={() => this.getCredentialGroups()}
          clone={clone}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showCloneCredentialModal = id => this.showCredentialModal(id, true);

  render() {
    return <InternalHandler {...this} {...this.props} />;
  }
}

export default ExternalHandler;
