import * as R from "ramda";
import {
  select,
  call,
  put,
  takeEvery,
  all,
  fork,
  take
} from "redux-saga/effects";

import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { registerError } from "redux/modules/errors/actions";

import { getters, actions } from "Sponsors/SelfSetup";
import Api from "Sponsors/SelfSetup/api";

import { STEPS } from "Sponsors/SelfSetup/constants";

const mapWithIndex = R.addIndex(R.map);

const getItemTypes = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  try {
    const { payload } = yield call(Api.getItemTypes, {
      credentials,
      eventId
    });

    const { items } = R.reduce(
      (list, type) => {
        type.groups.forEach(group => {
          group.items
            .filter(i => i.is_package)
            .forEach(item => {
              list.items.push({
                ...item,
                prices: item.variants[0].prices
              });
            });
        });
        return list;
      },
      {
        items: []
      }
    )(payload);

    const packageItems = R.reduce((map, pkg) => {
      map[pkg.id] = R.reduce((map2, rel) => {
        map2[rel.variant_id] = rel.quantity;
        return map2;
      }, {})(pkg.package_variants);
      return map;
    }, {})(items);

    yield all([
      put(actions.setItemPackages(items)),
      put(actions.setPackageItems(packageItems))
    ]);
  } catch (e) {
    yield put(
      registerError([
        {
          system: e,
          user: "An error occurred fetching the item types"
        }
      ])
    );
  }
};

const init = function*() {
  for (;;) {
    const { payload } = yield take(actions.init.type);
    if (payload === STEPS.ADD_ITEMS) {
      yield put(actions.setPackageItemsLoading(true));
      yield call(getItemTypes);
      yield put(actions.setPackageItemsLoading(false));
    }
  }
};

const saveItems = function*({ payload: items }) {
  const credentials = yield select(getCredentials);
  const itemId = yield select(getters.selectingItems);

  const data = {
    itemId,
    variants: R.compose(
      R.filter(R.prop("quantity")),
      mapWithIndex((variantId, idx) => ({
        variantId,
        price: null,
        quantity: items[variantId],
        order: idx
      })),
      R.keys
    )(items)
  };

  try {
    yield call(Api.updatePackageItems, {
      credentials,
      data
    });
    yield put(actions.setPackageItemsResponse(null));
    yield call(getItemTypes);
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred updating the package items"
          }
        ])
      ),
      put(actions.setPackageItemsResponse(null))
    ]);
  }
};

const watchSetPackageItemsRequest = function*() {
  yield takeEvery(actions.setPackageItemsRequest.type, saveItems);
};

const watchSaveItems = function*() {
  yield all([fork(init), fork(watchSetPackageItemsRequest)]);
};

export default watchSaveItems;
