import Helpers from "utils/Global/Helpers";

module.exports = {
  post: (credentials, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/orders/fulfillments`,
        credentials,
        data,
        success,
        error
      });
    }),
  delete: (credentials, fulfillmentId) =>
    new Promise((resolve, reject) => {
      const options = {
        method: "delete",
        url: `${
          window.__LENND_API_BASE_URL__
        }/orders/fulfillments/${fulfillmentId}`,
        credentials,
        success: result => {
          resolve(result);
        },
        error: err => {
          reject(err);
        }
      };
      Helpers.request(options);
    })
};
