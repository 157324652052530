import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Header = ({ children, ...props }) => (
  <div styleName="header" {...props}>
    {children}
  </div>
);

Header.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
export default CSSModules(Header, css);
