import * as R from "ramda";
import {
  STYLE_KEYS,
  FEATURES,
  THEMES,
  VARIANTS,
  PALETTE,
  NEGATIVE_VARIANT,
  ACCENT_VARIANT
} from "ui-kit/Theme/constants";

// overrideStyle
const overrideStyle = (styleProps = {}, style = {}) => {
  if (R.has("style", styleProps)) {
    return R.pick(STYLE_KEYS, {
      ...styleProps,
      style: {
        ...style,
        ...R.prop("style", styleProps)
      }
    });
  }
  if (R.isEmpty(style)) {
    return R.pick(STYLE_KEYS, styleProps);
  }
  return R.pick(STYLE_KEYS, {
    ...styleProps,
    style
  });
};

const withoutStyleProps = R.pickBy((_, key) =>
  R.not(R.contains(key, STYLE_KEYS))
);

const filterNotNil = R.filter(
  R.compose(
    R.not,
    R.isNil
  )
);

const getFeatures = ({ features, select, active, hover }) =>
  R.mergeAll(
    R.map(featureKey => {
      const activeFeature = `${featureKey}a`;
      if (active && R.has(activeFeature, features)) {
        return { [featureKey]: features[activeFeature] };
      }
      const hoverFeature = `${featureKey}h`;
      if (hover && R.has(hoverFeature, features)) {
        return {
          [featureKey]: {
            default: features[featureKey],
            hover: features[hoverFeature]
          }
        };
      }
      return { [featureKey]: features[featureKey] };
    }, select)
  );

const memoize = R.memoizeWith((va, th) => `${va}@${th}`);

const applyStyle = memoize(
  (va, th) => (
    select = ["bg", "color"],
    { active = false, hover = false, theme, variant, ...overrides } = {},
    style
  ) =>
    overrideStyle(
      R.mergeAll([
        getFeatures({
          features: R.path([theme || th, variant || va], FEATURES),
          select,
          active,
          hover
        }),
        filterNotNil(overrides)
      ]),
      style
    )
);

export const makeThemeFn = memoize((variant, theme) => {
  if (process.env.NODE_ENV !== "produciton" && (!theme || !variant)) {
    console.warn("undefined theme or variant");
    console.warn("theme", theme);
    console.warn("variant", variant);
  }

  const Th = applyStyle(variant, theme);

  Th.PALETTE = PALETTE;
  Th.THEMES = THEMES;
  Th.VARIANTS = VARIANTS;
  Th.FEATURES = R.pathOr(
    FEATURES[THEMES.PRIMARY][VARIANTS.PRIMARY],
    [theme, variant],
    FEATURES
  );

  Th.activeTheme = theme;
  Th.activeVariant = variant;

  Th.negativeVariant = NEGATIVE_VARIANT[variant];
  Th.accentVariant = ACCENT_VARIANT[variant];

  return Th;
});

export const defaultTh = makeThemeFn(VARIANTS.PRIMARY, THEMES.PRIMARY);

export { applyStyle as as, overrideStyle as os, withoutStyleProps as wsp };
