import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Main from "components/Global/CRM/Forms/Form/Main";
import Header from "components/Global/CRM/Forms/Form/Header";
import Title from "components/Global/CRM/Forms/Form/Title";
import List from "components/Global/CRM/Forms/Form/List";
import SubTitle, { Green } from "components/Global/CRM/Forms/Form/SubTitle";
import FormOwnerSubmission from "components/Event/Accounts/Account/Forms/Form/Submission";
import PortalUserSubmission from "components/PortalV2/Home/Form/Submission";
import ActionButton from "components/PortalV2/Home/UI/ActionButton";
import { Div, PopoverMenu, MoreIcon, FontIcon } from "components/Base";
import isFormLocked from "components/Event/FormsV2/Utils/is-form-locked";
import formatExpirationMessage from "components/Event/FormsV2/Utils/format-expiration-message";
import Tooltip from "components/Global/Tooltip";

@CSSModules(css)
class AccountForm extends Component {
  state = { collapsed: true };

  toggleCollapse = () =>
    this.setState(state => ({ collapsed: !state.collapsed }));

  addSubmission = () => {
    this.props.addSubmission(this.props.form);
  };

  render() {
    const {
      eventDetails,
      form,
      isFormManager,
      onClickEditCloseDate,
      onUnassign,
      isAssignedToType
    } = this.props;

    const { submissions, name, type } = form;
    const isLocked = isFormLocked(form);
    const closeDate = form.custom_close_date || form.close_date;

    const isIntakeForm = type === "intake";
    let icon = <div style={{ width: 24 }} />;
    let headerProps = {};
    let submissionCount = <div styleName="subEmpty">No submissions yet</div>;
    if (submissions.length) {
      submissionCount = (
        <div styleName="subCount">
          {submissions.length}&nbsp;Submission
          {submissions.length > 1 ? "s" : ""}
        </div>
      );
      headerProps = {
        onClick: this.toggleCollapse,
        style: {
          cursor: "pointer"
        }
      };
      icon = (
        <i
          className="material-icons"
          style={{
            transform: `rotate(${this.state.collapsed ? "-90deg" : "0"})`
          }}
        >
          arrow_drop_down
        </i>
      );
    }

    return (
      <div styleName="container">
        <Header {...headerProps}>
          {icon}
          <Main>
            <Title>
              <Div display="row.flex-start.center">
                {name || "(Untitled)"}
                {isAssignedToType ? (
                  <Tooltip
                    tooltip="Form is assigned to group's type"
                    placement="bottom"
                  >
                    <FontIcon fs={2} ml={1} color="neutral3">
                      info
                    </FontIcon>
                  </Tooltip>
                ) : null}
              </Div>
            </Title>
            <SubTitle>
              <Div display="row.flex-start.center">
                {isLocked ? "Closed" : <Green>Open</Green>}
                {closeDate ? (
                  <Div display="row.flex-start.center">
                    <Div mx={1}>&middot;</Div>
                    <span styleName="subCount">
                      {formatExpirationMessage(form)}
                    </span>
                  </Div>
                ) : null}
                <Div mx={1}>&middot;</Div>
                {submissionCount}
              </Div>
            </SubTitle>
          </Main>
          {!isLocked && !isIntakeForm && !submissions.length ? (
            <ActionButton onClick={this.addSubmission} label="Fill Out" />
          ) : null}
          {isFormManager ? (
            <PopoverMenu
              menuItems={[
                ["Set due date", onClickEditCloseDate],
                onUnassign ? ["Unassign", onUnassign] : undefined
              ].filter(o => o)}
              Label={({ onClick }) => (
                <Div
                  onClick={onClick}
                  b={1}
                  bc="neutral1"
                  display="row.center.center"
                  width={33}
                  height={33}
                  ml={2}
                  style={{
                    borderRadius: 100
                  }}
                >
                  <MoreIcon fs={4} />
                </Div>
              )}
            />
          ) : null}
        </Header>
        {this.state.collapsed ? (
          ""
        ) : (
          <div styleName="details">
            <List>
              {submissions
                .sort((a, b) => b.created_at - a.created_at)
                .map(s =>
                  isFormManager ? (
                    <FormOwnerSubmission
                      key={s.id}
                      deleteSubmission={this.props.deleteSubmission}
                      form={this.props.form}
                      formPath={`/submissions/${eventDetails.slug}/${form.slug}`}
                      id={s.id}
                      showSubmissionSummary={this.props.showSubmissionSummary}
                      submission={s}
                      setSubmissionLocked={this.props.setSubmissionLocked}
                      sendConfirmation={this.props.sendConfirmation}
                    />
                  ) : (
                    <PortalUserSubmission
                      key={s.id}
                      form={this.props.form}
                      formPath={`/submissions/${eventDetails.slug}/${form.slug}`}
                      showSubmissionSummary={this.props.showSubmissionSummary}
                      submission={s}
                    />
                  )
                )}
            </List>
            {!isLocked && !isIntakeForm ? (
              <div styleName="detailsToolbar">
                <ActionButton onClick={this.addSubmission} label="Fill Out" />
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default AccountForm;
