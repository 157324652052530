import React from "react";
import { connect } from "react-redux";

import { noop } from "utils/General";
import { Div } from "components/Base";

import { getters, actions } from "EventLight/Common/Manage";
import {
  getSelectedTabTotal,
  getGroupBy,
  getFiltersOn,
  getFiltersCaption,
  showFiltersClearButton
} from "EventLight/Common/Manage/selectors";
import { MANAGE_BY, TABLE_VIEW_ID } from "EventLight/Common/Manage/constants";

import PaginationBar from "./PaginationBar";
import AllPassesView from "./AllPassesView";
import AttendeeListView from "./AttendeeListView";
import OrderListView from "./OrderListView";
import CategoryListView from "./CategoryListView";
import ItemTypeListView from "./ItemTypeListView";
import GroupAffiliationView from "./GroupAffiliationView";
import Toolbar from "./Toolbar";
import Tabs from "./Tabs";
import QuestionsDetailsModal from "Items/QuestionsDetailsModal/View";
import StatsModal from "./StatsModal";
import Sidebar from "./Sidebar";
import ShowNoResults from "ui-kit/ShowNoResults";

import LoadingOverlay from "ui-kit/LoadingOverlay";
import FiltersOn from "ui-kit/FiltersOn";

import AddZonesModal from "Orders/AddZonesModal/View";

import { groups } from "redux/modules/items/item-groups/selectors";

import { getters as SearchBarGetters } from "ui-kit/SearchBar";

const selectView = {
  [MANAGE_BY.ALL_PASSES]: AllPassesView,
  [MANAGE_BY.GROUP_AFFILIATION]: GroupAffiliationView,
  [MANAGE_BY.ATTENDEE_LIST]: AttendeeListView,
  [MANAGE_BY.ORDER_NUMBER]: OrderListView,
  [MANAGE_BY.INTEGRATION]: OrderListView,
  [MANAGE_BY.CATEGORY]: CategoryListView,
  [MANAGE_BY.ITEM_TYPE]: ItemTypeListView
};

const renderTable = ({
  loading,
  showNoResults,
  passesTypes,
  view,
  total,
  handlers,
  instanceId = ""
}) => {
  if (loading) {
    return (
      <React.Fragment>
        <LoadingOverlay />
        {(total > 0 &&
          React.createElement(selectView[view] || AllPassesView, {
            passesTypes,
            handlers,
            instanceId
          })) ||
          null}
      </React.Fragment>
    );
  }

  if (showNoResults) {
    return <ShowNoResults flex={1} instanceId={instanceId} />;
  }

  return React.createElement(selectView[view] || AllPassesView, {
    passesTypes,
    handlers,
    instanceId
  });
};

const decorate = connect(
  (state, props) => ({
    view: getGroupBy(state, props),
    loading: getters.loading(state),
    searchTerm: SearchBarGetters.searchTerm(state),
    showNoResults: getters.showNoResults(state),
    total: getSelectedTabTotal(state),
    filtersOn: getFiltersOn(state),
    passesTypes: groups(state),
    filtersCaption: getFiltersCaption(state),
    showFiltersClearButton: showFiltersClearButton(state)
  }),
  {
    onClearFilters: () => actions.clearFilters(),
    updateVariantZones: actions.updateVariantZones
  }
);

const ManagementView = ({
  passesTypes,
  groupTypes,
  attendeeTypes,
  view,
  handlers = {},
  loading = false,
  showNoResults = false,
  total = 0,
  filtersOn = false,
  onClearFilters = noop,
  filtersCaption,
  showFiltersClearButton,
  updateVariantZones = noop,
  instanceId = ""
}) => {
  return (
    <Div display="row.center.stretch" style={{ height: "calc(100vh - 159px)" }}>
      <Sidebar
        attendeeTypes={attendeeTypes}
        groupTypes={groupTypes}
        handlers={handlers}
        instanceId={instanceId}
      />
      <Div
        flex={1}
        display="column.center.stretch"
        width={5} // @NOTE: This is a hack to have the table width nicely fit
      >
        <Div
          px={4}
          bg="white"
          display="column.space-between.stretch"
          style={{ flexShrink: 0, position: "relative" }}
          bb={1}
          bc="neutral3"
        >
          <Toolbar handlers={handlers} instanceId={instanceId} />
          <Tabs />
          {/* Removing create order button*/}
        </Div>
        <FiltersOn
          on={filtersOn}
          onClearFilters={showFiltersClearButton ? onClearFilters : undefined}
          caption={filtersCaption}
          instanceId={instanceId}
        />
        <Div
          bg="neutral1"
          flex={1}
          display="column.space-between.stretch"
          style={{
            overflowY: loading ? "hidden" : "auto",
            position: "relative"
          }}
          id={TABLE_VIEW_ID}
        >
          {renderTable({
            loading,
            showNoResults,
            passesTypes,
            view,
            total,
            handlers,
            instanceId
          })}
        </Div>
        <PaginationBar instanceId={instanceId} />
        <QuestionsDetailsModal />
        <StatsModal />
        <AddZonesModal onSave={updateVariantZones} />
      </Div>
    </Div>
  );
};

export default decorate(ManagementView);
