import * as R from "ramda";
import React, { useEffect } from "react";

import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import { noop } from "utils/General";

import { actions } from "ui-kit/Form/model";
import { FIELD_TYPES } from "ui-kit/Form/constants";
import VideoRecorder from "ui-kit/VideoRecorder/View";

import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";

const decorate = R.compose(
  WithFormInstanceConsumer({ fieldType: FIELD_TYPES.VALUE }),
  connect(
    null,
    bindInstance({
      setIniValue: actions.setIniValue,
      onChange: actions.setFieldValue
    })
  )
);

export const Video = ({
  iniValue,
  fieldId,
  fieldType,
  setIniValue = noop,
  onChange = noop,
  // eslint-disable-next-line no-unused-vars
  instanceId,
  ...videoProps
}) => {
  useEffect(() => {
    setIniValue(iniValue, {
      meta: { fieldType, fieldId }
    });
  }, []);

  return (
    <VideoRecorder
      iniValue={iniValue}
      fieldId={fieldId}
      onChange={videoUrl =>
        onChange(videoUrl, { meta: { fieldId, fieldType } })
      }
      {...videoProps}
    />
  );
};

export const VideoRecorderElem = decorate(Video);
