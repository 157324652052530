import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import External from "./External";
import { withRouter } from "react-router";

import { eventDetails } from "redux/modules/event/selectors";
import { getItem } from "redux/modules/items/items/actions";

import { item } from "redux/modules/items/items/selectors";

function mapStateToProps(state, props) {
  return {
    mode: props.mealId ? (props.clone ? "clone" : "update") : "create",
    meal: props.mealId ? item(state, props.mealId) : null,
    eventDetails: eventDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getItem
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(External)
);
