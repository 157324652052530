import React, { Component } from "react";
import { isEqual } from "lodash";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { connect } from "react-redux";
import EditWrapper from "components/Global/Table3/CellFormatters/EditWrapper";
import StatusCircle from "ui-kit/StatusCircle";
import Tooltip from "components/Global/Tooltip";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import BulkEditLineItemsModal from "Orders/BulkEditLineItemsModal";

const decorate = connect(
  null,
  {
    showModal,
    hideModal
  }
);

class PaymentFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  showBulkEditModal = () => {
    this.props.showModal({
      content: (
        <BulkEditLineItemsModal
          where={
            this.props.dependentValues.meta.moduleId ===
            STANDARD_MODULE_IDS.accounts.id
              ? {
                  eventId: this.props.dependentValues.meta.eventId,
                  orderAccountId: this.props.dependentValues.meta.rowId,
                  orderType: "group"
                }
              : {
                  eventId: this.props.dependentValues.meta.eventId,
                  orderContactId: this.props.dependentValues.meta.rowId,
                  orderType: "individual"
                }
          }
          onDone={() => {
            this.props.dependentValues.helpers.refreshRecords();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    let status =
      this.props.value && this.props.value.value
        ? this.props.value.value.status
        : null;
    let owed =
      this.props.value && this.props.value.value
        ? this.props.value.value.owed
        : null;
    let paid =
      this.props.value && this.props.value.value
        ? this.props.value.value.paid
        : null;
    let tooltip;
    if (status === "no-payment-against-balance") {
      status = "rejected";
      tooltip = (
        <span>
          <b>No payments made</b>
          <br />${owed} due
        </span>
      );
    } else if (status === "remaining-balance") {
      status = "pending";
      tooltip = (
        <span>
          <b>Has remaining balance</b>
          <br />${paid} of ${owed} paid
        </span>
      );
    } else if (status === "paid") {
      status = "approved";
      tooltip = (
        <span>
          <b>No pending balance</b>
          <br />${paid} of ${owed} paid
        </span>
      );
    } else {
      tooltip = "No payment necessary";
    }

    return (
      <EditWrapper display="row.center.center" onClick={this.showBulkEditModal}>
        <Tooltip tooltip={tooltip} placement="bottom">
          <span>
            <StatusCircle status={status} />
          </span>
        </Tooltip>
      </EditWrapper>
    );
  }
}

export default decorate(PaymentFormatter);
