import React from "react";

import { connect } from "react-redux";

import { getters } from "Items/AddQuestionSetModal";

import { Div } from "components/Base";

import Loading from "ui-kit/Loading";

import Footer from "./Footer";

import Body from "./Body";

import Header from "./Header";

import Modal from "components/Global/Modal/Layout/StyleWrapper";

const decorate = connect(state => ({
  loading: getters.loading(state)
}));

const Layout = ({ hideModal, questionSetId, onDone, loading }) => (
  <Modal width={436} hideHeader bodyStyles={{ padding: 0 }}>
    <Div width={1} height={1}>
      <Header
        hideModal={hideModal}
        text={`${questionSetId ? "Editing" : "Adding"} Question Set`}
      />
      {loading ? <Loading /> : <Body />}
      <Footer hideModal={hideModal} onDone={onDone} />
    </Div>
  </Modal>
);

export default decorate(Layout);
