import React from "react";
import * as R from "ramda";

import {
  Div,
  SelectableGroupsList,
  selectableHandler,
  SelectableItemsList,
  SelectorTemplate,
  Text0,
  Text3,
  TinyTextButton,
  MediumTextButton,
  MediumFilledButton,
  searchHandler
} from "../index";
import { CheckboxAvatarLabel } from "./Cards/CheckboxAvatarLabel";
import { AvatarLabelRemove } from "./Cards/AvatarLabelRemove";
import { CheckboxIconLabel } from "./Cards/CheckboxIconLabel";
import { IconLabelRemove } from "./Cards/IconLabelRemove";

// Item${addS( numItems )} // maybe add this back

const BigSelectorHeader = ({ numItems, onSelectAll, onRemoveAll }) => (
  <Div mt={2} display={"row.flex-start.center"}>
    <Text3
      flex={1}
      py={1}
      bold
      color={"neutral7"}
    >{`${numItems}  Found`}</Text3>
    <Div display={"row.flex-start.center"}>
      <TinyTextButton onClick={onSelectAll} color={"primary7"}>
        Select All
      </TinyTextButton>
      <TinyTextButton onClick={onRemoveAll} color={"primary7"}>
        Remove All
      </TinyTextButton>
    </Div>
  </Div>
);

const SmallSelectorHeader = ({ title, onSelectAll, onRemoveAll }) => (
  <Div mt={2} display={"row.flex-start.center"}>
    <Text0 flex={1} bold color={"neutral7"} uppercase>
      {title}
    </Text0>
    <Div display={"row.flex-start.center"}>
      <TinyTextButton onClick={onSelectAll} color={"primary7"}>
        Select All
      </TinyTextButton>
      <TinyTextButton onClick={onRemoveAll} color={"primary7"}>
        Remove All
      </TinyTextButton>
    </Div>
  </Div>
);

const SelectionHeader = ({ numItems, onRemoveAll }) => {
  const noItems = numItems === 0;
  return (
    <Div py={1} display={"row.flex-start.center"}>
      <Text3 flex={1} bold color={"neutral7"}>
        {noItems ? "None Selected" : "Selected:"}
      </Text3>
      {!noItems && (
        <Div display={"row.flex-start.center"}>
          <TinyTextButton onClick={onRemoveAll} color={"primary7"}>
            Remove All
          </TinyTextButton>
        </Div>
      )}
    </Div>
  );
};

const makeMultiSelect = (isGrouped, SelectorItemComp, SelectionItemComp) => {
  const GroupSelector = SelectableGroupsList({
    GroupsHeaderComp: BigSelectorHeader,
    GroupHeaderComp: SmallSelectorHeader,
    ItemComp: SelectorItemComp
  });

  const Selector = SelectableItemsList({
    HeaderComp: BigSelectorHeader,
    ItemComp: SelectorItemComp
  });

  const SelectionList = SelectableItemsList({
    HeaderComp: SelectionHeader,
    ItemComp: SelectionItemComp
  });

  const AppliedSelector = isGrouped ? GroupSelector : Selector;
  return selectableHandler(
    searchHandler("items", "searchFilteredItems")(
      ({
        groupsInfo, // only needed if grouped
        items,
        searchFilteredItems,
        searchPlaceholder,
        onSearch,
        onCancel,
        onSave,
        selection, // from handler
        onSelect, // from handler
        onRemove // from handler
      }) => {
        return (
          <SelectorTemplate
            leftChildren={[
              <AppliedSelector
                key={1}
                groupsInfo={groupsInfo} // will not be used if is not grouped
                selection={selection}
                items={searchFilteredItems}
                onSelect={onSelect}
                onRemove={onRemove}
              />
            ]}
            rightChildren={[
              <Div key={1} p={2} bra={1} bg={"neutral0"}>
                <SelectionList
                  selection={selection}
                  items={R.filter(({ id }) => R.contains(id, selection), items)}
                  onRemove={onRemove}
                />
              </Div>,
              <Div display={"row.flex-end.center"} mt={2} key={2}>
                <MediumTextButton onClick={onCancel}>Cancel</MediumTextButton>
                <MediumFilledButton
                  bg={"blue6"}
                  onClick={() => onSave(selection)}
                >
                  Save
                </MediumFilledButton>
              </Div>
            ]}
            searchPlaceholder={searchPlaceholder}
            onSearch={onSearch}
          />
        );
      }
    )
  );
};

const AvatarLabelMultiSelect = makeMultiSelect(
  false,
  CheckboxAvatarLabel,
  AvatarLabelRemove
);

const IconLabelGroupedMultiSelect = makeMultiSelect(
  true,
  CheckboxIconLabel,
  IconLabelRemove
);

const IconLabelMultiSelect = makeMultiSelect(
  false,
  CheckboxIconLabel,
  IconLabelRemove
);

export {
  AvatarLabelMultiSelect,
  IconLabelGroupedMultiSelect,
  IconLabelMultiSelect
};
