import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { Link } from "react-router";
import Popover from "@lennd/material-ui/Popover";

@CSSModules(css)
class InsertTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null,
      selected: this.props.value || null
    };
  }

  @autobind
  onSelect(e, selected) {
    this.setState({
      open: false,
      selected
    });
    this.props.onSelect(selected);
  }

  @autobind
  handleOpen(event) {
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget
    });
  }

  @autobind
  handleRequestClose() {
    this.setState({
      open: false
    });
  }

  @autobind
  handleSelect(id) {
    this.props.onTemplateSelect(id);
    this.handleRequestClose();
  }

  render() {
    if (!this.props.templates) {
      return <div />;
    }
    const templateList = this.props.templates.map(template => (
      <div
        key={template.id}
        className={css.template}
        onClick={this.handleSelect.bind(null, template.id)}
      >
        {template.title}
      </div>
    ));

    return (
      <div>
        <span onClick={this.handleOpen} styleName="label">
          Insert Template <i className="material-icons">arrow_drop_down</i>
        </span>
        <Popover
          anchorOrigin={{
            horizontal: "left",
            vertical: "top"
          }}
          transformOrigin={{
            horizontal: "left",
            vertical: "top"
          }}
          style={{
            width: this.props.width
          }}
          open={this.state.open}
          canAutoPosition={false}
          onClose={this.handleRequestClose}
          anchorEl={this.state.anchorEl}
        >
          <div styleName="title">Insert email template</div>

          <div styleName="templateList" style={{ ...this.props.menuStyle }}>
            {templateList}
          </div>

          <Link
            to={`/event/${this.props.eventId}/settings/email-templates`}
            onClick={this.handleRequestClose}
            target="_blank"
            className={css.linkContainer}
          >
            <i className={["material-icons", css.linkLeftIcon].join(" ")}>
              open_in_new
            </i>
            <div>
              <div className={css.linkTitle}>Edit/View Templates</div>
              <div className={css.linkDescription}>Opens in a new window</div>
            </div>
          </Link>
        </Popover>
      </div>
    );
  }
}

InsertTemplate.defaultProps = {
  width: 250,
  menuStyle: {
    maxHeight: 150
  }
};

InsertTemplate.propTypes = {
  templates: PropTypes.array.isRequired,
  eventId: PropTypes.string.isRequired,
  onTemplateSelect: PropTypes.func.isRequired,
  value: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  styles: PropTypes.object,
  width: PropTypes.number,
  menuStyle: PropTypes.object
};

export default InsertTemplate;
