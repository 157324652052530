import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  items: [],
  showCart: false
};

const getFirstId = R.compose(
  R.head,
  R.keys
);

export const model = createHandlers({
  iniState,
  reducers: {
    addItem: ({ items }, { payload: { item, category, categoryId, name } }) => {
      const id = getFirstId(item);

      const product = {
        id,
        category,
        categoryId,
        name,
        ...item[id]
      };

      const coincidence = R.find(
        item =>
          R.equals(R.omit(["quantity"], product), R.omit(["quantity"], item)),
        items
      );

      if (coincidence) {
        return {
          items: R.map(
            R.when(
              item =>
                R.equals(
                  R.omit(["quantity"], coincidence),
                  R.omit(["quantity"], item)
                ),
              R.evolve({
                quantity: R.add(product.quantity)
              })
            ),
            items
          )
        };
      }

      return {
        items: R.append(product, items)
      };
    },
    removeItem: ({ items }, { payload }) => ({
      items: R.reject(R.equals(payload), items)
    }),
    modifyItem: (
      { items },
      { payload: { item: toChange, category, categoryId, name, oldItem } }
    ) => {
      const id = getFirstId(toChange);
      const product = {
        id,
        category,
        categoryId,
        name,
        ...toChange[id]
      };

      const oldProd = {
        category,
        categoryId,
        name,
        ...oldItem
      };

      const found = R.findIndex(
        item =>
          R.equals(R.omit(["quantity"], product), R.omit(["quantity"], item)),
        items
      );

      const findOld = R.findIndex(
        item =>
          R.equals(R.omit(["quantity"], oldProd), R.omit(["quantity"], item)),
        items
      );

      if (found == -1) {
        return {
          items: R.update(findOld, product, items)
        };
      } else if (found == findOld) {
        return {
          items: R.adjust(R.assoc("quantity", product.quantity), found, items)
        };
      } else {
        return {
          items: R.compose(
            R.remove(findOld, 1),
            R.adjust(
              R.evolve({
                quantity: R.add(product.quantity)
              }),
              found
            )
          )(items)
        };
      }
    },
    checkout: R.identity,
    handleModifyItemModal: R.identity,
    handleDeleteItemModal: R.identity,
    toggleShowDetail: R.evolve({ showCart: R.not }),
    clearItemsInCart: () => ({
      items: []
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
