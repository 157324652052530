import React from "react";
import * as R from "ramda";
import { DarkTab, Div, Text6 } from "components/Base";

const View = ({ tabs, activeTabValue }) => (
  <Div style={{ minHeight: "100%" }}>
    <Div bb={1} bc="gray3" bg="white" display="row.flex-start.flex-end">
      <Div flex={1}>
        <Text6 pl={8} pt={6}>
          Orders
        </Text6>

        <Div pt={3} pl={8}>
          {R.compose(
            R.map(({ label, key, onClick }) => {
              const active = R.equals(key)(activeTabValue);
              return (
                <DarkTab
                  key={label}
                  px={2}
                  fs={3}
                  active={active}
                  onClick={!active ? onClick : undefined}
                >
                  {label}
                </DarkTab>
              );
            }),
            R.filter(R.prop("visible"))
          )(tabs)}
        </Div>
      </Div>
    </Div>
    <Div style={{ minHeight: "100%" }} pb={14}>
      {R.compose(
        R.prop("comp"),
        R.find(r => r.key === activeTabValue && r.visible)
      )(tabs)}
    </Div>
  </Div>
);

export default View;
