import React, { Component } from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import Fuse from "fuse.js";
import View from "./View";
import { ListView } from "components/Global/Module/Sidebar/ViewSelector/Popover/Icons";
import { bindInstance } from "redux-mvc";

import { getters, actions } from "ui-kit/ViewPicker";

const decorate = connect(
  (state, props) => ({
    views: getters.views(state, props),
    activeViewId: getters.activeViewId(state, props),
    activeViewName: getters.activeViewName(state, props)
  }),
  bindInstance({
    selectView: actions.selectView,
    addView: actions.addView
  })
);

const searchWithFuse = (searchTerm, fuse, opt, matchWith) => {
  if (R.isNil(searchTerm) || R.isEmpty(searchTerm)) {
    return opt;
  }
  return R.compose(
    R.reject(R.isNil),
    R.map(o => R.find(R.propEq(matchWith, o.id), opt))
  )(fuse.search(searchTerm));
};

class InternalHandler extends Component {
  state = {
    searchTerm: "",
    addingView: false,
    tempName: ""
  };

  selectView = viewId => {
    if (viewId === this.props.activeViewId) return;
    this.props.selectView(viewId);
    this.props.composeClosePopover();
  };

  componentDidMount() {
    this.viewsFuse = new Fuse(this.props.views, {
      threshold: 0.3,
      keys: ["name", "type"],
      shouldSort: true
    });

    if (this.viewRefs[`ref_${this.props.activeViewId}`]) {
      this.viewRefs[`ref_${this.props.activeViewId}`].scrollIntoView(false);
    }
    if (this.viewRefs.ref_search) {
      this.viewRefs.ref_search.focus();
    }
  }

  componentWillReceiveProps(nextProps) {
    this.viewsFuse = new Fuse(nextProps.views, {
      threshold: 0.3,
      keys: ["name", "type"],
      shouldSort: true
    });
  }

  onSearch = e => this.setState({ searchTerm: e.target.value });

  onClearSearch = () => this.setState({ searchTerm: "" });

  viewRefs = {};

  registerRef = (ref, id) => {
    this.viewRefs[`ref_${id}`] = ref;
  };

  render() {
    const { searchTerm } = this.state;
    const { onSortViews, views, activeViewId, moduleId } = this.props;
    const isSearching = Boolean(searchTerm.length);
    const viewsWithHandlers = R.map(
      view => ({
        ...view,
        icon: <ListView size={13} />,
        isActive: view.id === activeViewId,
        onClick: () => this.selectView(view.id)
      }),
      searchWithFuse(searchTerm, this.viewsFuse, views, "id")
    );

    return (
      <View
        {...{
          views: viewsWithHandlers,
          onSearch: this.onSearch,
          onClearSearch: this.onClearSearch,
          searchTerm,
          countOfMatchingViews: viewsWithHandlers.length,
          countOfAllViews: views.length,
          isSearching,
          onSortViews,
          addView: () => {
            this.props.addView({ name: this.state.tempName });
            this.props.composeClosePopover();
          },
          addingView: this.state.addingView,
          toggleAddView: (toggle, cb) => {
            this.setState(
              {
                addingView: toggle,
                tempName:
                  this.props.activeViewId === "default"
                    ? this.props.activeViewName
                    : `${this.props.activeViewName} (Copy)`
              },
              cb
            );
          },
          tempName: this.state.tempName,
          updateTempName: tempName => this.setState({ tempName }),
          registerRef: this.registerRef,
          moduleId
        }}
      />
    );
  }
}

export default decorate(InternalHandler);
