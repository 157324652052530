import React from "react";
import { connect } from "react-redux";
import {
  getNextBtnText,
  getShowDraftBtn,
  getShowNextIcon,
  getNextBtnWidth,
  getShowBackBtn,
  getIsValid
} from "EventLight/Agenda/AddSessionModal/selectors";
import { actions } from "EventLight/Agenda/AddSessionModal/model";
import {
  Div,
  BigFilledButton,
  BigOutlineButton,
  RightArrowIcon,
  LeftArrowIcon
} from "components/Base";

const decorate = connect(
  state => ({
    nextBtnText: getNextBtnText(state),
    showNextIcon: getShowNextIcon(state),
    nextBtnWidth: getNextBtnWidth(state),
    showBackBtn: getShowBackBtn(state),
    showDraftBtn: getShowDraftBtn(state),
    isValid: getIsValid(state)
  }),
  {
    nextPage: actions.nextPage,
    previousPage: actions.previousPage,
    saveAsDraft: actions.saveAsDraft
  }
);

const Footer = ({
  handlers,
  nextBtnText,
  nextPage,
  showNextIcon,
  nextBtnWidth,
  showBackBtn,
  previousPage,
  showDraftBtn,
  saveAsDraft,
  isValid
}) => (
  <Div
    bg="white"
    bt={1}
    bc="neutral2"
    height={97}
    p={6}
    display="row.space-between.center"
  >
    {/*
    {showBackBtn ? (
      <BigOutlineButton
        width={100}
        LeftIcon={LeftArrowIcon}
        display="row.space-between.center"
        color="neutral5"
        onClick={previousPage}
      >
        Back
      </BigOutlineButton>
    ) : (
      <BigOutlineButton width={236} onClick={handlers.showImportModal}>
        Import Sessions
      </BigOutlineButton>
    )}
    */}
    <Div display="row.flex-start.center">
      {/*
      {showDraftBtn ? (
        <BigFilledButton
          color="neutral7"
          bg="neutral1"
          mr={2}
          onClick={saveAsDraft}
        >
          Save as Draft
        </BigFilledButton>
      ) : null}
      */}
      <BigFilledButton
        width={nextBtnWidth}
        bg="altB5"
        color="white"
        disabled={!isValid}
        onClick={() =>
          nextPage({
            onDone: handlers.onDone
          })
        }
        RightIcon={showNextIcon ? RightArrowIcon : null}
      >
        {nextBtnText}
      </BigFilledButton>
    </Div>
  </Div>
);

export default decorate(Footer);
