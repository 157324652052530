import PropTypes from "prop-types";
import React from "react";
import * as R from "ramda";
import autobind from "autobind-decorator";
import FormBaseInput from "../FormBaseInput";
import FormInputWrapper from "components/Event/FormsV2/Sections/FormInputWrapper";
import Input from "components/Global-2016/Forms/Input";
import isRequiredValid from "utils/value-types/validations/text/required";
import isEmailInvalid from "utils/value-types/validations/email/isEmail";
import getValue from "utils/value-types/get-value/text";
import { isEqual } from "lodash";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FormInput extends FormBaseInput {
  constructor(props) {
    super(props);

    this.state = {
      value: getValue(props.formValues[props.field.id]),
      isShown: false
    };
  }

  wrapValue(value) {
    return {
      type: "text",
      value
    };
  }

  @autobind
  async isValid() {
    const errors = [];
    const { type, field } = this.props;

    // We need to mock this value as value types expect this schema
    const value = {
      type,
      value: this.state.value
    };

    if (field.is_required && !isRequiredValid(value)) {
      errors.push("This is a required question");
    }
    if (type === "email") {
      if (!(await isEmailInvalid(value))) {
        errors.push("Please use a valid email address");
      }
    }
    this.setState({
      errors
    });
    return !errors.length;
  }

  @autobind
  handleSave() {
    this.save(
      this.props.field.id,
      this.wrapValue(
        this.state.value ? String(this.state.value).trim() : this.state.value
      )
    );
  }

  @autobind
  handleChange(e) {
    this.setState({
      value: e.target.value
    });
  }

  @autobind
  handleToogleShowEditMode() {
    this.setState({
      isShown: R.not(this.state.isShown)
    });
  }

  componentWillUpdate(nextProps) {
    if (
      !this.props.isEditing &&
      !isEqual(
        this.props.formValues[this.props.field.id],
        nextProps.formValues[this.props.field.id]
      )
    ) {
      this.setState({
        value: getValue(nextProps.formValues[this.props.field.id])
      });
    }
  }

  render() {
    let handleSave;
    if (!this.props.isEditing) {
      handleSave = this.handleSave;
    }
    const maxLength = R.pathOr(
      "",
      ["field", "settings", "maxLength"],
      this.props
    );
    const { connectDragSource, canShowSimpleSideBar } = this.props;
    return (
      <FormInputWrapper
        isEditing={this.props.isEditing}
        isValid={this.state.isValid}
        errorMessages={this.state.errors}
        fieldData={this.props.field}
        instanceId={this.props.field.id}
        isShown={this.state.isShown}
        setIsShown={() => this.handleToogleShowEditMode()}
        connectDragSource={connectDragSource}
      >
        <Input
          key={this.props.field.id}
          disabled={this.props.disabled}
          placeholder={this.props.field.settings.placeholder}
          description={this.props.field.settings.description}
          label={this.props.field.name}
          value={this.state.value}
          onChange={this.handleChange}
          onBlur={handleSave}
          required={this.props.field.is_required}
          isAdminField={this.props.field.settings.isAdminField}
          type={this.props.type}
          autoComplete="off"
          maxLength={maxLength}
          instanceId={this.props.field.id}
          fieldData={this.props.field}
          isShown={this.state.isShown}
          canShowSimpleSideBar={canShowSimpleSideBar}
          readOnly={this.props.field.is_readonly}
          setIsShown={() => this.handleToogleShowEditMode()}
        />
        {!this.props.disabled && maxLength ? (
          <div styleName="charLimit">
            {this.state.value ? this.state.value.length : 0}/{maxLength}{" "}
            Character
            {this.state.value
              ? this.state.value.length !== 1
                ? "s"
                : ""
              : "s"}
          </div>
        ) : null}
      </FormInputWrapper>
    );
  }
}

FormInput.propTypes = {
  formValues: PropTypes.object,
  field: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  updateFormValue: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default FormInput;
