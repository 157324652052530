import React from "react";
import { Div } from "components/Base";

const OptionQuantity = ({ quantity, onClick }) => {
  const style = onClick ? { cursor: "pointer" } : {};
  return (
    <Div
      display="row.flex-start.center"
      minHeight={23}
      onClick={onClick}
      style={{
        ...style,
        userSelect: "none",
        overflow: "hidden"
      }}
    >
      {quantity}
    </Div>
  );
};

export default OptionQuantity;
