import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Search = CSSModules(
  ({ searchTerm, onSearch, onClearSearch, registerRef }) => (
    <div styleName="searchWrapper">
      <div styleName="searchIcon">
        {searchTerm.length ? (
          <i
            className="material-icons"
            onClick={onClearSearch}
            styleName="searchIconClear"
            role="button"
            tabIndex="0"
          >
            cancel
          </i>
        ) : (
          <i className="material-icons" styleName="searchIconSearch">
            search
          </i>
        )}
      </div>
      <input
        ref={ref => registerRef(ref, "search")}
        styleName="searchInput"
        type="text"
        placeholder="Find a view"
        value={searchTerm}
        onChange={onSearch}
      />
    </div>
  ),
  css
);

const ViewRow = CSSModules(
  ({ isActive, id, registerRef, name, icon, onClick, is_public }) => (
    <div
      ref={ref => registerRef(ref, id)}
      styleName="view"
      onClick={onClick}
      role="button"
      tabIndex="0"
    >
      <div styleName="viewActive">
        {isActive ? <i className="material-icons">check</i> : null}
      </div>
      <div styleName="viewIcon">{icon}</div>
      <div styleName="viewName">{name}</div>
      {!is_public ? (
        <i className="material-icons" styleName="isPublic">
          person
        </i>
      ) : null}
    </div>
  ),
  css
);

const Type = CSSModules(
  ({ name, picker, icon, onClick, addView }) =>
    picker ? (
      <div styleName="type" role="button" tabIndex="0">
        <picker.component {...picker.data} onSelect={addView}>
          {icon}
          <span styleName="typeName">{name}</span>
        </picker.component>
        <i className="material-icons" styleName="dropdownArrow">
          arrow_drop_down
        </i>
      </div>
    ) : (
      <div styleName="type" role="button" tabIndex="0" onClick={onClick}>
        {icon}
        <span styleName="typeName">{name}</span>
      </div>
    ),
  css
);

const MainContainer = ({
  searchTerm,
  onSearch,
  onClearSearch,
  views,
  types,
  countOfMatchingViews,
  registerRef
}) => (
  <div styleName="container">
    <Search
      {...{
        searchTerm,
        onSearch,
        onClearSearch,
        registerRef
      }}
    />
    <div styleName="views">
      {countOfMatchingViews ? (
        views.map(view => (
          <ViewRow key={view.id} registerRef={registerRef} {...view} />
        ))
      ) : (
        <div styleName="empty">No matching views</div>
      )}
    </div>
    <div styleName="addContainer">
      <div styleName="label">Add a view:</div>
      {types.map(type => <Type key={type.id} {...type} />)}
    </div>
  </div>
);

export default CSSModules(MainContainer, css);
