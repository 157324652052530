import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDataGrid from "@lennd/react-data-grid/dist/react-data-grid";
import { get } from "lodash";
import { DEFAULT_APPROVAL_FIELD_APPROVE_REJECT } from "components/Event/FormsV2/constants";

class FormApprovalRow extends Component {
  setScrollLeft(scrollLeft) {
    this.refs.row.setScrollLeft(scrollLeft);
  }

  getClassName() {
    switch (get(
      this.props.row,
      `['${DEFAULT_APPROVAL_FIELD_APPROVE_REJECT.id}'].value`,
      "pending"
    )) {
      case "approved":
        return "react-grid-Lennd-Form-Row-Approved";
      case "rejected":
        return "react-grid-Lennd-Form-Row-Rejected";
      default:
        return "react-grid-Lennd-Form-Row-Pending";
    }
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <ReactDataGrid.Row ref="row" {...this.props} />
      </div>
    );
  }
}

FormApprovalRow.propTypes = {
  row: PropTypes.object.isRequired
};

export default FormApprovalRow;
