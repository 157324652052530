import React from "react";
import { Div, MediumFilledButton } from "components/Base";

const EmptyState = ({ title, action, goToSetup, ...props }) => (
  <Div mt={12}>
    <Div
      display="row.space-between.center"
      bg="neutral0"
      b={1}
      bc="gray2"
      mb={3}
      p={4}
      bra={1}
      style={{ maxWidth: 570, margin: "0 auto" }}
      {...props}
    >
      <Div fw={3} fs={4}>
        {title}
      </Div>
      {action ? (
        <MediumFilledButton onClick={action.onClick} bg="purple8">
          <Div fw={3}>{action.title}</Div>
        </MediumFilledButton>
      ) : null}
    </Div>
  </Div>
);

export default EmptyState;
