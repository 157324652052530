import React from "react";
import {
  Action,
  ActionLink
} from "components/Global/CRM/Forms/Form/Submission/Actions";
import Submission from "components/Global/CRM/Forms/Form/Submission";
import MenuItem from "material-ui/MenuItem";
import DeleteConfirmation from "components/Global/CRM/Popovers/DeleteConfirmation";
import {
  getPortalBadge,
  getSubmissionState
} from "components/Global/CRM/Forms/utils/utils";
import {
  Approved,
  BadgeGroup,
  InReview,
  Rejected,
  Reviewed,
  Submitted,
  NotSubmitted
} from "components/Global/CRM/Forms/Form/Submission/Badges";

const PORTAL_SUBMISSION_BADGES = {
  approved: Approved,
  inReview: InReview,
  notSubmitted: NotSubmitted,
  rejected: Rejected,
  reviewed: Reviewed,
  submitted: Submitted,
  wrapper: BadgeGroup
};

class PortalSubmission extends Submission {
  getContext = (submission, form) => {
    const context = {};
    switch (getSubmissionState(submission, form.fields)) {
      case "submitted":
        context.verb = "Submitted";
        context.actions = [
          <Action key="action" onClick={this.showSubmissionSummary}>
            View
          </Action>,
          !submission.is_locked ? (
            <ActionLink
              key="link"
              to={`${this.props.formPath}/${submission.id}`}
            >
              Edit
            </ActionLink>
          ) : null
        ];
        context.menuItems = [
          this.props.deleteSubmission ? (
            <DeleteConfirmation
              onConfirm={this.deleteSubmission}
              text="Are you sure you want to delete this submission?"
            >
              <MenuItem key="delete" value="delete" primaryText="Delete" />
            </DeleteConfirmation>
          ) : (
            undefined
          )
        ];
        context.timeAgo = submission.created_at;
        break;
      case "reviewing":
        context.verb = "Submitted";
        context.actions = [
          <Action key="action" onClick={this.showSubmissionSummary}>
            Quick View
          </Action>
        ];
        context.menuItems = [
          <MenuItem
            key="viewOriginal"
            value="viewOriginal"
            primaryText="View original"
          />
        ];
        context.timeAgo = submission.created_at;
        break;
      case "rejected":
      case "reviewed":
      case "approved":
        context.verb = "Reviewed";
        context.actions = [
          <Action
            key="action"
            onClick={() => this.showSubmissionSummary(submission.id)}
          >
            Quick View
          </Action>
        ];
        context.menuItems = [
          <MenuItem
            key="viewOriginal"
            value="viewOriginal"
            primaryText="View original"
          />
        ];
        context.timeAgo = submission.updated_at;
        break;
      default:
        // not submitted
        context.verb = "Last updated";
        context.actions = [
          <ActionLink key="link" to={`${this.props.formPath}/${submission.id}`}>
            Continue
          </ActionLink>
        ];
        context.menuItems = [
          this.props.deleteSubmission ? (
            <DeleteConfirmation
              onConfirm={this.deleteSubmission}
              text="Are you sure you want to delete this submission?"
            >
              <MenuItem key="delete" value="delete" primaryText="Delete" />
            </DeleteConfirmation>
          ) : (
            undefined
          )
        ];
        context.timeAgo = submission.updated_at;
    }

    context.badges = getPortalBadge(
      submission,
      form.fields,
      PORTAL_SUBMISSION_BADGES
    );
    return context;
  };

  onContextMenuChange = (event, type) => {
    switch (type) {
      case "viewOriginal":
        window.location = `${this.props.formPath}/${this.props.submission.id}`;
        break;
      case "delete":
        this.deleteSubmission();
        break;
      default:
        break;
    }
  };
}

export default PortalSubmission;
