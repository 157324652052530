import React from "react";
import {
  put,
  all,
  takeEvery,
  fork,
  take,
  call,
  select
} from "redux-saga/effects";
import { makeFuture } from "utils/General/sagas";
import { apiCall } from "App/Data/sagas";

import { actions, getters } from "Portal/PortalHome/model";
import { getSubmissionsInstanceIds } from "Portal/PortalHome/selectors";
import { actions as TableActions } from "ui-kit/Table/model";

import { registerError } from "redux/modules/errors/actions";
import { actions as TabActions } from "Portal/Tabs";
import {
  FILTER,
  TASK_ACTIONS,
  CART_TABS,
  ROW_ACTIONS_ITEMS
} from "Portal/PortalHome/constants";
import { portalUser as getPortalUser } from "redux/modules/portal/user/selectors";
import { user as getUser } from "redux/modules/user/selectors";
import { push } from "react-router-redux";
// import { deleteFormSubmission } from "redux/modules/portal/forms/actions";
import OrderModal from "Orders/OrderModal/View";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { showModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import DocumentRequestModal from "Portal/PortalDocuments/RequestModal";
import DeleteConfirmation from "components/Global/CRM/Modals/DeleteConfirmation";
import { actions as DataActions } from "App/Data/model";

import { getCredentials } from "redux/modules/user/selectors";

import Api from "./api";
import PeopleApi from "../PortalPeople/api";
import SubmissionApi from "redux/modules/formsV2/submission/api";
import RecordsApi from "redux/modules/modules/records/api";

import * as R from "ramda";

import { eventDetails as getEventDetails } from "redux/modules/portal/selectors";
import getValue from "@lennd/value-types/src/get-value";
import toString from "@lennd/value-types/src/to-string";

const refreshData = function*({ payload = {} } = { payload: {} }) {
  const portalUser = yield select(getPortalUser);
  const eventDetails = yield select(getEventDetails);

  const isViewingAsAccount = R.pathEq(
    ["active_view", "type"],
    "account"
  )(portalUser);

  const accountId = isViewingAsAccount
    ? R.path(["active_view", "id"], portalUser)
    : null;

  const credentials = yield select(getCredentials);

  try {
    const { payload: portalPayload } = yield call(Api.getPortalData, {
      credentials,
      eventId: eventDetails.id,
      accountId,
      scope: payload.scope || "home"
    });

    let accountContacts = [];

    if (accountId) {
      const { payload: peoplePayload } = yield call(PeopleApi.getContacts, {
        credentials,
        org_id: eventDetails.org_id,
        accountId,
        excludeEventId: eventDetails.id
      });
      accountContacts = peoplePayload.contacts;
    }

    const tableDataList = R.compose(
      R.filter(
        item =>
          !R.isEmpty(R.propOr([], "submissions", item)) &&
          !R.isNil(R.propOr([], "submissions", item))
      ),
      payload => [
        ...R.pathOr([], ["tasks", "past_due"], payload),
        ...R.pathOr([], ["tasks", "due_next_7_days"], payload),
        ...R.pathOr([], ["tasks", "due_this_month"], payload)
      ]
    )(portalPayload);

    if (accountId && portalPayload.hasOwnProperty("tasks")) {
      const peopleTaskIndex = R.findIndex(R.propEq("id", "people"))(
        portalPayload.tasks.due_this_month
      );
      if (peopleTaskIndex !== -1) {
        portalPayload.tasks.due_this_month[peopleTaskIndex].count =
          accountContacts.length;
      }
    }

    yield all([
      put(
        actions.setInitialData({
          ...portalPayload,
          tableDataList,
          peopleCount: accountContacts.length
        })
      ),
      put(DataActions.addReferences(portalPayload.references)),
      ...R.map(
        submission =>
          put(
            TableActions.setData(
              {
                canEditCells: false,
                columns: R.propOr([], "columns", submission),
                rows: R.propOr([], "submissions", submission),
                columnWidths: submission.preferences.field_widths
              },
              {
                meta: {
                  instanceId: submission.id
                }
              }
            )
          ),
        tableDataList
      )
    ]);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred geting portal tasks"
          }
        ])
      )
    ]);
  }
};

const init = function*({ payload = {} }) {
  yield put(TabActions.setSelectedTab(FILTER.TASKS));
  yield call(refreshData, { payload });
};

const form = function*({ payload }) {
  const eventDetails = yield select(getEventDetails);
  yield put(
    push(
      `/portals/${eventDetails.slug}/${eventDetails.uuid}/form/${payload.data.id}`
    )
  );
};

const invoice = function*({
  payload: {
    data: { id }
  }
}) {
  const url = `${window.__LENND_APP_URL__}/invoice/${id}`; // eslint-disable-line no-underscore-dangle
  yield call([window, window.open], url, "_blank");
};

const people = function*(
  {
    //payload
  }
) {
  const eventDetails = yield select(getEventDetails);
  // const canViewNewPortal = yield select(
  //   selectFeatureFlag(flags.CAN_VIEW_NEW_PORTAL.NAME)
  // );

  yield put(push(`/portals/${eventDetails.slug}/${eventDetails.uuid}/people`));

  /*
  if (canViewNewPortal) {
    yield put(
      push(
        `/portals/${eventDetails.slug}/${eventDetails.uuid}/people/${payload.data.id}`
      )
    );
  } else {
    yield put(
      push(`/portals/${eventDetails.slug}/${eventDetails.uuid}/people`)
    );
  }
  */
};

const customTask = function*({
  payload: {
    data: { id, path }
  }
}) {
  const eventDetails = yield select(getEventDetails);

  if (id === "preview-profile") {
    yield put(
      push(`/portals/${eventDetails.slug}/${eventDetails.uuid}/profile`)
    );
  } else if (id === "submit-order") {
    yield put(push(path));
  } else if (id === "visit-media-hub") {
    if (eventDetails.id === 2941) {
      // us open
      yield put(
        push(
          `/portals/${eventDetails.slug}/${eventDetails.uuid}/pages/2de924e1-9992-454c-ac75-fe9a6c85fec1`
        )
      );
    } else if (eventDetails.id === 2680) {
      // kpmg
      yield put(
        push(
          `/portals/${eventDetails.slug}/${eventDetails.uuid}/pages/8004423d-5193-4b53-86b4-14d3aff4b134`
        )
      );
    } else if (eventDetails.id === 3079) {
      // us open womens
      yield put(
        push(
          `/portals/${eventDetails.slug}/${eventDetails.uuid}/pages/177f718b-6f5f-4f8f-afbe-46c3a035dd59`
        )
      );
    } else if (eventDetails.id === 3084) {
      // pto
      yield put(
        push(
          `/portals/${eventDetails.slug}/${eventDetails.uuid}/pages/00f5df5e-f452-450b-8fbf-72ea87d0dd78`
        )
      );
    } else if (eventDetails.id === 3136) {
      // farmers insurance
      yield put(
        push(
          `/portals/${eventDetails.slug}/${eventDetails.uuid}/pages/98624b29-50b0-429a-9461-6565dde31466`
        )
      );
    } else if (eventDetails.id === 3219) {
      // xgames
      yield put(
        push(
          `/portals/${eventDetails.slug}/${eventDetails.uuid}/pages/ab131d52-78de-4795-b667-4a2d7ffd73b1`
        )
      );
    } else if (eventDetails.id === 3361) {
      // us open mens
      yield put(
        push(
          `/portals/${eventDetails.slug}/${eventDetails.uuid}/pages/f4d5248e-05d5-4143-a03a-20aa73a6bc75`
        )
      );
    } else if (eventDetails.id === 3362) {
      // us open womens
      yield put(
        push(
          `/portals/${eventDetails.slug}/${eventDetails.uuid}/pages/fdf5abf4-a13a-42c4-8569-2768ba495669`
        )
      );
    } else if (eventDetails.id === 3207) {
      // pga champ 2021
      yield put(
        push(
          `/portals/${eventDetails.slug}/${eventDetails.uuid}/pages/aa294241-e22f-436e-ae76-3977b217d322`
        )
      );
    } else if (eventDetails.id === 3328) {
      // pga womens champ 2021
      yield put(
        push(
          `/portals/${eventDetails.slug}/${eventDetails.uuid}/pages/7fe84533-ca42-4eaf-a80b-eb4a64e79759`
        )
      );
    } else if (eventDetails.id === 3327) {
      // pga senios champ 2021
      yield put(
        push(
          `/portals/${eventDetails.slug}/${eventDetails.uuid}/pages/34b7e9b8-05a2-4623-a869-a66f956978dd`
        )
      );
    } else if (eventDetails.id === 3495) {
      // olympics
      yield put(
        push(
          `/portals/${eventDetails.slug}/${eventDetails.uuid}/pages/62d3eceb-bd38-41b5-99d4-9080a4c3c2cc`
        )
      );
    } else if (eventDetails.id === 3494) {
      // paralympics
      yield put(
        push(
          `/portals/${eventDetails.slug}/${eventDetails.uuid}/pages/9f571390-42df-4eec-9a2a-87d9732786c0`
        )
      );
    } else if (eventDetails.id === 3503) {
      // xgames summer
      yield put(
        push(
          `/portals/${eventDetails.slug}/${eventDetails.uuid}/pages/1f1b3c64-35bd-4f87-9ac0-95ea677831da`
        )
      );
    } else if (eventDetails.id === 3552) {
      // ryders cup test event
      yield put(
        push(
          `/portals/${eventDetails.slug}/${eventDetails.uuid}/pages/b5fe8719-7359-4678-8a65-fdb042175492`
        )
      );
    } else if (eventDetails.id === 2652) {
      // pga
      yield put(
        push(
          `/portals/${eventDetails.slug}/${eventDetails.uuid}/pages/e41826dc-2f24-4581-96b8-a1680e562367`
        )
      );
    }
  }

  return false;
};

const toggleTask = function*({ payload: { data } }) {
  try {
    const portalUser = yield select(getPortalUser);
    const eventDetails = yield select(getEventDetails);

    const isViewingAsAccount = R.pathEq(
      ["active_view", "type"],
      "account"
    )(portalUser);
    const accountId = isViewingAsAccount
      ? R.path(["active_view", "id"], portalUser)
      : null;

    yield call(apiCall, {
      method: "put",
      url: `/portal/tasks/event/${eventDetails.id}${
        accountId ? `/accounts/${accountId}` : ""
      }/task/${data.id}`
    });

    yield call(refreshData);
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred toggling task"
          }
        ])
      )
    ]);
  }
};

const assignedTask = function*({ payload: { data } }) {
  if (R.prop("url")(data.meta || {})) {
    yield call([window, window.open], data.meta.url, "_blank");

    if (!R.propEq("completionMethod", "click")(data.meta)) {
      return false;
    }
  }

  if (!data.meta.url || data.status !== "complete") {
    yield call(toggleTask, { payload: { data } });
  }

  return false;
};

const openFile = function*({ payload: { url } }) {
  yield call([window, window.open], url, "_blank");
  return true;
};

const goToPage = function*({ payload: { id } }) {
  const eventDetails = yield select(getEventDetails);
  yield put(
    push(`/portals/${eventDetails.slug}/${eventDetails.uuid}/pages/${id}`)
  );
  return true;
};

const goToAlert = function*({ payload: { id } }) {
  const eventDetails = yield select(getEventDetails);
  yield put(
    push(`/portals/${eventDetails.slug}/${eventDetails.uuid}/alerts/${id}`)
  );
  return true;
};

const getSubmissions = function*(activeTab, data, filter) {
  const eventDetails = yield select(getEventDetails);
  const allSubmissions = R.propOr([], "submissions", data);

  const allSubmissionsFiltered = R.isEmpty(filter)
    ? allSubmissions
    : R.filter(
        submission =>
          R.compose(
            R.any(column => {
              const value = getValue(column, column.type);
              const valueString = toString(value, column.type, eventDetails, {
                ...column.settings
              });
              return valueString
                ? R.contains(R.toLower(filter), R.toLower(valueString))
                : false;
            }),
            R.filter(R.has("type")),
            R.values
          )(submission),
        allSubmissions
      );

  if (activeTab === CART_TABS.SUBMITTED) {
    return R.filter(R.propEq("is_submitted", true), allSubmissionsFiltered);
  } else if (activeTab === CART_TABS.DRAFT) {
    return R.filter(R.propEq("is_draft", true), allSubmissionsFiltered);
  }
  return allSubmissionsFiltered;
};

const setSubmissionsTabFilter = function*({
  payload: { activeSubmissionTab, instanceId }
}) {
  const oldActiveTab = yield select(getters.activeSubmissionTab);
  const submissionsFilter = yield select(getters.submissionsFilter);
  const filter = R.propOr("", instanceId, submissionsFilter);

  if (activeSubmissionTab !== R.propOr("", instanceId, oldActiveTab)) {
    const tasks = yield select(getters.tasks);
    const data = R.compose(R.find(R.propEq("id", instanceId)), payload => [
      ...R.propOr([], "past_due", payload),
      ...R.propOr([], "due_next_7_days", payload),
      ...R.propOr([], "due_this_month", payload)
    ])(tasks);
    const submissions = yield call(
      getSubmissions,
      activeSubmissionTab,
      data,
      filter
    );

    yield all([
      put(
        actions.updateActiveSubmissionTab({
          id: instanceId,
          value: activeSubmissionTab
        })
      ),
      put(
        TableActions.setData(
          {
            canEditCells: false,
            columns: R.propOr([], "columns", data),
            rows: submissions,
            columnWidths: data.preferences.field_widths
          },
          {
            meta: {
              instanceId
            }
          }
        )
      )
    ]);
  }
};

const setSubmissionsFilterData = function*({ payload: { value, instanceId } }) {
  const activeSubmissionTab = yield select(getters.activeSubmissionTab);
  const activeTab = R.propOr(CART_TABS, instanceId, activeSubmissionTab);

  const tasks = yield select(getters.tasks);
  const data = R.compose(R.find(R.propEq("id", instanceId)), payload => [
    ...R.propOr([], "past_due", payload),
    ...R.propOr([], "due_next_7_days", payload),
    ...R.propOr([], "due_this_month", payload)
  ])(tasks);

  const submissions = yield call(getSubmissions, activeTab, data, value);

  yield all([
    put(actions.updateSubmissionsFilter({ value, id: instanceId })),
    put(
      TableActions.setData(
        {
          canEditCells: false,
          columns: R.propOr([], "columns", data),
          rows: submissions,
          columnWidths: data.preferences.field_widths
        },
        {
          meta: {
            instanceId
          }
        }
      )
    )
  ]);
};

const addSubmission = function*({ payload: { id, slug, baseModuleId } }) {
  const eventDetails = yield select(getEventDetails);
  const portalUser = yield select(getPortalUser);
  const user = yield select(getUser);
  const credentials = yield select(getCredentials);

  try {
    yield put(actions.updateAddLoading({ id, value: true }));

    const contactId = R.pathEq(["active_view", "type"], "user", portalUser)
      ? R.pathOr(undefined, ["active_view", "contact_id"], portalUser)
      : undefined;

    const accountId = R.pathEq(["active_view", "type"], "account", portalUser)
      ? R.pathOr(undefined, ["active_view", "id"], portalUser)
      : undefined;

    const { record } = yield call(RecordsApi.post, credentials, {
      moduleId: baseModuleId,
      record: {
        isDraft: true
      },
      options: {
        eventId: eventDetails.id
      }
    });

    const { submission } = yield call(SubmissionApi.post, credentials, {
      formId: id,
      eventId: eventDetails.id,
      userId: user.id,
      contactId,
      accountId,
      submissionRecordId: record.id
    });

    yield put(
      push(`/submissions/${eventDetails.slug}/${slug}/${submission.id}`)
    );
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred adding a submission"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.updateAddLoading({ id, value: false }));
  }
};

const openSubmissionModal = function*({ payload: { row } }) {
  yield put(
    showModal({
      content: <OrderModal portal submissionId={row.id} />,
      wrapper: ModalWrapper
    })
  );
};

const editRow = function*({ payload: { row } }) {
  window.location = `/submissions/${row.event_slug}/${row.form_slug}/${row.id}`;
};

const showDocumentRequestModal = function*({
  requestTypeId,
  accountId,
  contactId
}) {
  const eventDetails = yield select(getEventDetails);
  const handleModal = makeFuture();
  yield put(
    showModal({
      content: (
        <DocumentRequestModal
          // recordId={recordId}
          eventId={eventDetails.id}
          requestTypeId={requestTypeId}
          accountId={accountId}
          contactId={contactId}
          onDone={() => {
            handleModal.done(true);
          }}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleModal.onRealized);
};

const documentRequest = function*({ payload: { data } }) {
  const done = yield call(showDocumentRequestModal, data.meta);

  if (done) {
    const scope = yield select(getters.scope);
    yield put(actions.init({ scope }));
  }
};

const showDeleteConfirmation = function*() {
  const handleDelete = makeFuture();
  yield put(
    showModal({
      content: (
        <DeleteConfirmation
          heading="Delete Record?"
          message={
            <div>
              {`
                Are you sure you want to remove this record?
                `}
              <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                This cannot be undone.
              </div>
            </div>
          }
          onConfirm={() => handleDelete.done(true)}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleDelete.onRealized);
};

const removeSubmission = function*({ payload: { row } }) {
  const scope = yield select(getters.scope);
  const credentials = yield select(getCredentials);
  const shouldDelete = yield call(showDeleteConfirmation);

  if (shouldDelete) {
    try {
      yield call(SubmissionApi.delete, credentials, {
        submissionId: row.id
      });
      yield put(actions.init({ scope }));
      yield put(showSnackbar({ message: "Submission deleted" }));
    } catch (e) {
      yield all([
        put(
          registerError([
            {
              system: e,
              user: "An error occurred removing a submission"
            }
          ])
        )
      ]);
    }
  }
};

const watchSetSubmissionsFilter = function*() {
  yield takeEvery(
    actions.setSubmissionsFilterData.type,
    setSubmissionsFilterData
  );
};

const watchSetSubmissionsTabFilter = function*() {
  yield takeEvery(
    actions.setSubmissionsTabFilter.type,
    setSubmissionsTabFilter
  );
};

const watchExecuteAction = function*() {
  for (;;) {
    const action = yield take(actions.executeAction.type);
    const delegate = R.prop(action.payload.type, {
      [TASK_ACTIONS.FORM]: form,
      [TASK_ACTIONS.DOCUMENT_REQUEST]: documentRequest,
      [TASK_ACTIONS.INVOICE]: invoice,
      [TASK_ACTIONS.PEOPLE]: people,
      [TASK_ACTIONS.CUSTOM_TASK]: customTask,
      [TASK_ACTIONS.ASSIGNED_TASK]: assignedTask,
      [TASK_ACTIONS.TOGGLE_TASK]: toggleTask,
      [TASK_ACTIONS.OPEN_FILE]: openFile,
      [TASK_ACTIONS.GO_TO_PAGE]: goToPage,
      [TASK_ACTIONS.GO_TO_ALERT]: goToAlert
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const watchTableActions = function*() {
  for (;;) {
    const action = yield take(TableActions.executeAction.type);
    const instaceIdList = yield select(getSubmissionsInstanceIds);

    if (
      R.any(instanceId => instanceId === action.meta.instanceId, instaceIdList)
    ) {
      const delegate = R.prop(action.payload.actionId, {
        [ROW_ACTIONS_ITEMS.OPEN_RECORD]: openSubmissionModal,
        [ROW_ACTIONS_ITEMS.OPEN_SUBMISSION_MODAL]: openSubmissionModal,
        [ROW_ACTIONS_ITEMS.EDIT_ROW]: editRow,
        [ROW_ACTIONS_ITEMS.DELETE_ROW]: removeSubmission
      });

      if (delegate) {
        yield fork(delegate, action);
      }
    }
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchAddSubmission = function*() {
  yield takeEvery(actions.addSubmission.type, addSubmission);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchExecuteAction),
    fork(watchSetSubmissionsTabFilter),
    fork(watchSetSubmissionsFilter),
    fork(watchAddSubmission),
    fork(watchTableActions)
  ]);
};

export default rootSaga;
