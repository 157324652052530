import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import { noop } from "utils/General";

import { getters, actions } from "Sponsors/SelfSetup";
import { getShowAddEndDate } from "Sponsors/SelfSetup/selectors";

import {
  SETTINGS_FORM_ID,
  SETTINGS_FIELDS,
  DATETIME_FORMAT
} from "Sponsors/SelfSetup/constants";

import { EmailPanel } from "Sponsors/Common/View";
import { Div, Text2, SmallFilledButton } from "components/Base";

import { WithThemeConsumer, VARIANTS, defaultTh } from "ui-kit/Theme";

import {
  Input,
  Dropdown,
  DateInput,
  ColorPicker,
  Label,
  Row,
  Column,
  WithFormInstanceProvider
} from "ui-kit/Form/View";

const OPENS_TO_OPTIONS = [
  { id: "booths", label: "Booth Packages" },
  { id: "sponsorships", label: "Sponsorship Packages" },
  { id: "addons", label: "Add-ons" }
];

const PrimaryButton = ({ onClick, color }) => (
  <SmallFilledButton
    onClick={onClick}
    color="white"
    bg={color || "info6"}
    style={{
      height: 32
    }}
  >
    Button
  </SmallFilledButton>
);

const decorate = R.compose(
  WithFormInstanceProvider(R.always(SETTINGS_FORM_ID)),
  WithThemeConsumer({ variant: VARIANTS.SURFACE }),
  connect(
    (state, props) => ({
      showAddEndDate: getShowAddEndDate(state, props),
      backgroundImageUrl: R.prop(
        "background_image_url",
        getters.eventShowcase(state, props)
      )
    }),
    {
      onShowAddEndDate: () => actions.setShowAddEndDate(false),
      onShowBgModal: () => actions.showBgModal(false)
    }
  )
);

const Settings = ({
  showAddEndDate = false,
  onShowAddEndDate = noop,
  onShowBgModal = noop,
  backgroundImageUrl = null,
  Th = defaultTh,
  ...styleProps
}) => (
  <>
    <Div {...Th(["bg"], { bra: 1, ...styleProps })}>
      <Row>
        <Column flex={1}>
          <Label>Event name</Label>
          <Input width={1} fieldId={SETTINGS_FIELDS.NAME} />
        </Column>
        <Column width={200} ml={3}>
          <Label># of Attendees</Label>
          <Input width={1} fieldId={SETTINGS_FIELDS.ATTENDEES} />
        </Column>
      </Row>
      <Row mt={2} display="row.flex-start.stretch">
        <Column width={200}>
          <Label>Event date</Label>
          <DateInput
            showTime
            showCalendar
            fieldId={SETTINGS_FIELDS.DATE}
            format={DATETIME_FORMAT}
          />
        </Column>
        <Column ml={2} display="column.flex-end.flex-start" width={200}>
          <Label>{showAddEndDate ? "" : "Event end date"}</Label>
          {showAddEndDate ? (
            <Text2
              {...Th(["color"], { bold: true, active: true, mb: 2 })}
              onClick={onShowAddEndDate}
            >
              + Add End Date
            </Text2>
          ) : (
            <DateInput
              showTime
              showCalendar
              fieldId={SETTINGS_FIELDS.END_DATE}
              format={DATETIME_FORMAT}
              width={1}
            />
          )}
        </Column>
      </Row>
      <Row mt={2}>
        <Column flex={1}>
          <Label>Event Header</Label>
          {/* <Input width={1} fieldId={SETTINGS_FIELDS.HEADER} /> */}

          <Div
            color="purple7"
            bc="purple7"
            b={1}
            bra={1}
            py={1}
            width={110}
            display="row.center.center"
            fs={2}
            onClick={onShowBgModal}
            style={{
              flexShrink: 0
            }}
          >
            Upload Header
          </Div>

          {backgroundImageUrl && backgroundImageUrl.length ? (
            <Div
              bg="neutral0"
              width={450}
              height={130}
              b={1}
              mt={2}
              bc="neutral2"
              bra={1}
              style={{
                flexShrink: 0,
                backgroundImage: backgroundImageUrl
                  ? `url("${backgroundImageUrl}")`
                  : "inherit",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "top center",
                backgroundSize: "cover"
              }}
            />
          ) : null}
        </Column>
      </Row>
      <Row mt={2}>
        <Column flex={1}>
          <Label>Primary Button Text</Label>
          <Input
            width={1}
            maxLength={28}
            fieldId={SETTINGS_FIELDS.PRIMARY_BUTTON_TEXT}
          />
        </Column>
        <Div display="column.flex-start.flex-start" width={100} ml={3}>
          <Label>Button color:</Label>
          <ColorPicker
            fieldId={SETTINGS_FIELDS.PRIMARY_BUTTON_COLOR}
            LabelComponent={PrimaryButton}
          />
        </Div>
        <Column width={250} ml={3}>
          <Label>Opens to:</Label>
          <Dropdown
            clearable={false}
            options={OPENS_TO_OPTIONS}
            fieldId={SETTINGS_FIELDS.PRIMARY_BUTTON_OPENS_TO}
          />
        </Column>
      </Row>
      <Row mt={2}>
        <Column flex={1}>
          <Label>Secondary Button Text</Label>
          <Input
            width={1}
            maxLength={28}
            fieldId={SETTINGS_FIELDS.SECONDARY_BUTTON_TEXT}
          />
        </Column>
        <Column width={250} ml={3}>
          <Label>Opens to:</Label>
          <Dropdown
            clearable={false}
            options={OPENS_TO_OPTIONS}
            fieldId={SETTINGS_FIELDS.SECONDARY_BUTTON_OPENS_TO}
          />
        </Column>
      </Row>
    </Div>

    <EmailPanel
      title="Event Description"
      fieldId={SETTINGS_FIELDS.DESCRIPTION}
      accentBorder="neutral5"
      mt={4}
      variant={Th.VARIANTS.BACKGROUND}
    />
    <EmailPanel
      title="Event Details & Notes"
      fieldId={SETTINGS_FIELDS.DETAILS}
      accentBorder="neutral5"
      mt={4}
      variant={Th.VARIANTS.BACKGROUND}
    />
    <EmailPanel
      title="Terms & Conditions / Cancellation Policy"
      fieldId={SETTINGS_FIELDS.TERMS}
      accentBorder="neutral5"
      mt={2}
      variant={Th.VARIANTS.BACKGROUND}
    />
    <EmailPanel
      title="Confirmation Message"
      fieldId={SETTINGS_FIELDS.CONFIRMATION}
      accentBorder="neutral5"
      mt={4}
      variant={Th.VARIANTS.BACKGROUND}
    />
  </>
);
export default decorate(Settings);
