import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { noop, withProps } from "utils/General";

import { actions } from "ui-kit/Form/model";
import { getValue } from "ui-kit/Form/selectors";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";
import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";

import {
  Div,
  TinyClearButton,
  SmallClearButton,
  MediumClearButton,
  BigClearButton,
  SuperClearButton,
  TinyFilledButton,
  SmallFilledButton,
  MediumFilledButton,
  BigFilledButton,
  SuperFilledButton,
  HalfSizeDotIcon
} from "components/Base";

const decorate = R.compose(
  WithFormInstanceConsumer({ fieldType: FIELD_TYPES.TOGGLE_OPTION }),
  WithThemeConsumer({ variant: VARIANTS.SURFACE }),
  connect(
    (state, props) => ({
      value: getValue(state, props)
    }),
    bindInstance({
      onChange: actions.setFieldValue,
      setIniValue: actions.setIniValue
    })
  )
);

const buttons = {
  active: {
    tiny: TinyFilledButton,
    small: SmallFilledButton,
    medium: MediumFilledButton,
    big: BigFilledButton,
    super: SuperFilledButton
  },
  default: {
    tiny: TinyClearButton,
    small: SmallClearButton,
    medium: MediumClearButton,
    big: BigClearButton,
    super: SuperClearButton
  }
};

export const ToggleOption = decorate(
  ({
    value = "",
    iniValue,
    onChange = noop,
    setIniValue = noop,
    fieldId,
    fieldType,
    size = "small",
    options = [],
    Th = defaultTh,
    pill = false,
    activeColor = null,
    ...styleProps
  }) => {
    useEffect(() => {
      setIniValue(iniValue, {
        meta: { fieldType, fieldId }
      });
    }, []);
    return (
      <Div
        display="row.flex-start.center"
        style={{ flexShrink: 0 }}
        pill={pill}
        {...Th(["bg"], { p: 1, bra: 1, ...styleProps })}
      >
        {options.map(
          (option, index) =>
            React.createElement(
              value === option.id
                ? buttons.active[size]
                : buttons.default[size],
              {
                key: option.id,
                onClick: () =>
                  onChange(option.id, { meta: { fieldId, fieldType } }),
                ...Th(["color", "bg"], {
                  ml: index === 0 ? 0 : 1,
                  active: value === option.id,
                  color: value === option.id ? activeColor : null,
                  bg: "white",
                  pill
                }),
                RightIcon: option.hasNotification
                  ? withProps({ color: "red" })(HalfSizeDotIcon)
                  : null
              },
              option.label
            ),
          options
        )}
      </Div>
    );
  }
);
