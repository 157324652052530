import React from "react";
import { Div } from "components/Base";
import AccessGridTable from "ui-kit/AccessGridTable/View";

const Layout = ({ handlers }) => {
  return (
    <Div py={3} pl={7}>
      <AccessGridTable
        tableStyle={{ width: 810, height: 500 }}
        handlers={handlers}
      />
    </Div>
  );
};

export default Layout;
