import PropTypes from "prop-types";
import React, { Component } from "react";
import Card from "./";
import { DragSource } from "react-dnd";

const styles = {
  marginBottom: 10
};
const cardSource = {
  beginDrag(props) {
    return {
      cardId: props.row.id
    };
  }
};

@DragSource("kanban-card", cardSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
}))
class DraggableCard extends Component {
  render() {
    const { isDropped, isDragging, connectDragSource, ...rest } = this.props;
    const opacity = isDragging ? 0.4 : 1;

    return connectDragSource(
      <div style={{ opacity, ...styles }}>
        <Card {...rest} />
      </div>
    );
  }
}

DraggableCard.propTypes = {};

export default DraggableCard;
