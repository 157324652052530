import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import { getters, actions } from "../model";
import { getFilters } from "../selectors";

import { Div } from "components/Base";

import styleSpects from "components/Base/Base/styleSpecs";
import { noop } from "utils/General";

import MenuPopover from "ui-kit/MenuPopover";

const MenuItem = ({
  rightIcon = "",
  children,
  isActive = false,
  onClick = noop,
  style = {}
}) => (
  <Div
    fs={2}
    px={3}
    py={2}
    onClick={onClick}
    display="row.flex-start.center"
    bg={{
      hover: "neutral0",
      default: "white"
    }}
    color={isActive ? "primary7" : "neutral7"}
    {...style}
  >
    {rightIcon ? (
      <Div display="row.space-between.center" flex={1}>
        {children}
        <i
          className="material-icons"
          style={{ color: "black", fontSize: styleSpects.fontSize[3] }}
        >
          {rightIcon}
        </i>
      </Div>
    ) : (
      children
    )}
  </Div>
);

const decorate = connect(
  state => ({
    filters: getFilters(),
    activeFilterId: getters.activeFilterId(state)
  }),
  {
    changeFilter: actions.setActiveFilterId
  }
);

const Filters = ({
  filters = [],
  activeFilterId = null,
  changeFilter = noop,
  className = "",
  children,
  ...styleProps
}) => (
  <MenuPopover Label={children} className={className}>
    {({ closePopover }) => (
      <Div bra={3} bg="white" width={200} py={2} {...styleProps}>
        {R.map(
          filter => (
            <MenuItem
              key={filter.id}
              isActive={activeFilterId === filter.id}
              rightIcon={filter.rightIcon}
              onClick={() => {
                changeFilter(filter.id);
                closePopover();
              }}
              style={filter.style}
            >
              {filter.name}
            </MenuItem>
          ),
          filters
        )}
      </Div>
    )}
  </MenuPopover>
);

export default decorate(Filters);
