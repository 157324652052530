import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";

import * as R from "ramda";

import { actions, getters } from "Items/AssignQuestionsItemsModal";

import { registerError } from "redux/modules/errors/actions";

import { eventId as getEventId } from "redux/modules/event/selectors";

import { getCredentials } from "redux/modules/user/selectors";

import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  return {
    credentials,
    eventId
  };
};

const init = function*({ payload: { variants } }) {
  try {
    yield put(actions.setLoading(true));

    const { credentials, eventId } = yield call(getParams);

    const [
      { payload: sets },
      { payload: questions },
      {
        payload: {
          question_ids: itemQuestionsIds,
          question_set_ids: setsIds,
          variant_ids: variantIds,
          variant_names: variantNames,
          question_and_question_set_order: questionsAndSetsIds
        }
      }
    ] = yield all([
      call(Api.getQuestionSets, {
        credentials,
        eventId
      }),
      call(Api.getQuestions, {
        credentials,
        eventId
      }),
      call(Api.getSelectedIds, {
        data: { variantIds: variants },
        credentials
      })
    ]);

    yield put(
      actions.setInitialData({
        questions,
        sets,
        setsIds,
        itemQuestionsIds,
        variantIds,
        variantNames,
        questionsAndSetsIds
      })
    );
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading questions"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const refetchSets = function*() {
  try {
    yield put(actions.setLoading(true));

    const { credentials, eventId } = yield call(getParams);

    const { payload: sets } = yield call(Api.getQuestionSets, {
      credentials,
      eventId
    });

    yield put(actions.setSets(sets));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error ocurred adding a new question"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const refetchQuestions = function*() {
  try {
    yield put(actions.setLoading(true));

    const { credentials, eventId } = yield call(getParams);

    const { payload: questions } = yield call(Api.getQuestions, {
      credentials,
      eventId
    });

    yield put(actions.setQuestions(questions));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error ocurred adding a new question"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const save = function*({ payload: { onDone } }) {
  try {
    yield put(actions.setSaving(true));

    const { credentials } = yield call(getParams);

    const variantIds = yield select(getters.variantIds);
    const questionIds = yield select(getters.itemQuestionsIds);
    const questionSetIds = yield select(getters.setsIds);
    const questionAndQuestionSetOrder = yield select(
      getters.questionsAndSetsIds
    );

    yield call(Api.saveSelectedIds, {
      data: {
        mode: "variant",
        variantIds,
        questionIds,
        questionSetIds,
        questionAndQuestionSetOrder
      },
      credentials
    });
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error ocurred adding a new question"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setSaving(false));
    yield call(onDone);
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchRefetchSets = function*() {
  yield takeEvery(actions.refetchSets.type, refetchSets);
};

const watchRefetchQuestions = function*() {
  yield takeEvery(actions.refetchQuestions.type, refetchQuestions);
};

const watchSave = function*() {
  yield takeEvery(actions.save.type, save);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchRefetchSets),
    fork(watchSave),
    fork(watchRefetchQuestions)
  ]);
};

export default rootSaga;
