import { createSelector } from "reselect";
import * as R from "ramda";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { TAB_IDS } from "./constants";

const isActiveRoute = (routes, route) => {
  if (Array.isArray(route)) {
    return R.compose(
      Boolean,
      R.length,
      R.intersection(route),
      R.map(R.prop("name"))
    )(routes);
  }

  return routes.some(r => r.name === route);
};

export const getCurrentRoute = createSelector(
  (_, props) => props.routes,
  routes => {
    if (R.any(R.propEq("name", "EventLightInventoryDashboard"))(routes)) {
      return TAB_IDS.DASHBOARD;
    } else if (R.any(R.propEq("name", "EventLightInventoryRequests"))(routes)) {
      return TAB_IDS.REQUESTS;
    } else if (R.any(R.propEq("name", "EventLightInventorySettings"))(routes)) {
      return TAB_IDS.CATALOG;
    } else if (
      R.any(R.propEq("name", "EventLightInventorySettingsQuestions"))(routes)
    ) {
      return TAB_IDS.QUESTIONS;
    } else if (
      R.any(R.propEq("name", "EventLightInventorySettingsApprovers"))(routes)
    ) {
      return TAB_IDS.APPROVERS;
    } else if (
      R.any(R.propEq("name", "EventLightInventorySendEmails"))(routes)
    ) {
      return TAB_IDS.SEND_EMAILS;
    }
    return null;
  }
);

export const getTabs = createSelector(
  (_, props) => props.routes,
  (_, props) => props.params,
  getEventDetails,
  getCurrentRoute,
  (routes, params, eventDetails, currentRoute) => {
    if (
      [TAB_IDS.CATALOG, TAB_IDS.QUESTIONS, TAB_IDS.APPROVERS].includes(
        currentRoute
      )
    ) {
      return [
        {
          key: "assets",
          label: "Assets",
          active: isActiveRoute(routes, "EventLightInventorySettings"),
          to: `/event-light/${eventDetails.id}/inventory/settings`
        },
        {
          key: "questions",
          label: "Questions",
          active: isActiveRoute(routes, "EventLightInventorySettingsQuestions"),
          to: `/event-light/${eventDetails.id}/inventory/settings/questions`
        },
        {
          key: "approvers",
          label: "Approvers",
          active: isActiveRoute(routes, "EventLightInventorySettingsApprovers"),
          to: `/event-light/${eventDetails.id}/inventory/settings/approvers`
        }
      ];
    }

    if ([TAB_IDS.REQUESTS].includes(currentRoute)) {
      return [
        {
          key: "all-requests",
          label: "All Requests",
          active:
            isActiveRoute(routes, "EventLightInventoryRequests") &&
            params.viewId === "all-requests",
          to: `/event-light/${eventDetails.id}/inventory/requests/all-requests`
        },
        {
          key: "my-approvals",
          label: "My Approvals",
          active:
            isActiveRoute(routes, "EventLightInventoryRequests") &&
            params.viewId === "my-approvals",
          to: `/event-light/${eventDetails.id}/inventory/requests/my-approvals`
        }
        /*
        {
          key: "issue",
          label: "Checkin / Checkout",
          active:
            isActiveRoute(routes, "EventLightInventoryRequests") &&
            params.viewId === "issue",
          to: `/event-light/${eventDetails.id}/inventory/requests/issue`
        }
        */
      ];
    }

    return [];
  }
);
