import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import { actions } from "Schedules/TemplatesPicker/model";
import { actions as GenerateDocumentsModalActions } from "Modules/GenerateDocumentsModal";

import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { registerError } from "redux/modules/errors/actions";

import api from "components/Event/Settings/DocumentTemplates/api";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  return {
    credentials,
    eventId
  };
};

const init = function*({ payload: moduleId }) {
  try {
    const { credentials, eventId } = yield call(getParams);
    const uuModuleId =
      moduleId === "master" ? STANDARD_MODULE_IDS.schedules.id : moduleId;
    const { payload } = yield call(api.getDocumentTemplatesByModuleId, {
      credentials,
      eventId,
      moduleId: uuModuleId
    });
    yield put(actions.setDocuments(payload));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred fetching document templates"
        }
      ])
    );
  }
};

const printCurrentView = function*() {
  yield all([
    put(actions.setSelected("")),
    put(GenerateDocumentsModalActions.setSelectedTemplate(null))
  ]);

  yield put(actions.setShowGenerateTemplateModal(true));
};

const manageTemplates = function*() {
  const eventId = yield select(getEventId);
  const url = `/event/${eventId}/settings/module/${STANDARD_MODULE_IDS.schedules.id}/document-templates`;
  yield call(window.open, url, "_blank");
};

const generateTemplate = function*({ payload: template }) {
  yield all([
    put(actions.setSelected([template.id])),
    put(GenerateDocumentsModalActions.setSelectedTemplate({ id: template.id }))
  ]);

  yield put(actions.setShowGenerateTemplateModal(true));
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchPrintCurrentView = function*() {
  yield takeEvery(actions.printCurrentView.type, printCurrentView);
};

const watchCreatePrintTemplate = function*() {
  yield takeEvery(actions.manageTemplates.type, manageTemplates);
};

const watchGenerateTemplate = function*() {
  yield takeEvery(actions.generateTemplate.type, generateTemplate);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchPrintCurrentView),
    fork(watchCreatePrintTemplate),
    fork(watchGenerateTemplate)
  ]);
};

export default rootSaga;
