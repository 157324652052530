import Helpers from "utils/Global/Helpers";

export default {
  getTasks: ({ credentials, eventId, userId }) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/schedules/my-tasks/${userId}/event/${eventId}`,
        credentials,
        qs: { widget: 1 },
        success: resolve,
        error: reject
      };
      Helpers.request(options);
    }),
  addValues: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/schedules/field-values`,
        credentials,
        data,
        success,
        error
      });
    })
};
