import React, { Component } from "react";
import { Div } from "components/Base";
import GuestList from "Passes/GuestLists/SettingsModal";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { getGuestLists } from "redux/modules/guestLists/actions";
import { guestLists } from "redux/modules/guestLists/selectors";

function mapStateToProps(state, props) {
  const guestListsToPass = guestLists(state);
  const guestListSettings = guestListsToPass.find(
    g => g.record_type_id === props.selectedTypeId
  );

  return {
    guestListSettings: guestListSettings || {}
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getGuestLists
    },
    dispatch
  );
}

const decorate = connect(
  mapStateToProps,
  mapDispatchToProps
);

class GuestListSettings extends Component {
  componentDidMount() {
    this.props.getGuestLists();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedTypeId !== this.props.selectedTypeId) {
      this.props.getGuestLists();
    }
  }

  render() {
    return (
      <Div>
        <GuestList
          isModal={false}
          recordTypeId={this.props.selectedTypeId}
          guestList={this.props.guestListSettings}
        />
      </Div>
    );
  }
}

export default decorate(GuestListSettings);
