import { createSelector } from "reselect";
import * as R from "ramda";
import { TAB_IDS } from "./constants";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import {
  eventDetails as getEventDetails,
  eventId as getEventId
} from "redux/modules/event/selectors";
import { canUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

const getCanUserDo = R.memoizeWith(getEventId, state =>
  canUserDo(state, getEventId(state))
);

const isActiveRoute = (routes, route) => {
  if (Array.isArray(route)) {
    return R.compose(
      Boolean,
      R.length,
      R.intersection(route),
      R.map(R.prop("name"))
    )(routes);
  }

  return routes.some(r => r.name === route);
};

export const getCurrentRoute = createSelector(
  (_, props) => props.routes,
  routes => {
    if (R.any(R.propEq("name", "EventLightCRMManage"))(routes)) {
      return TAB_IDS.MANAGE;
    } else if (
      R.any(R.propEq("name", "EventLightCRMSettingsTypes"))(routes) ||
      R.any(R.propEq("name", "EventLightCRMSettingsTypesPortal"))(routes) ||
      R.any(R.propEq("name", "EventLightCRMSettingsTypesLayout"))(routes) ||
      R.any(R.propEq("name", "EventLightCRMSettingsTypesGuestList"))(routes) ||
      R.any(R.propEq("name", "EventLightCRMSettingsTypesAddRecordLayout"))(
        routes
      )
    ) {
      return TAB_IDS.TYPES;
    } else if (R.any(R.propEq("name", "EventLightCRMSettingsFields"))(routes)) {
      return TAB_IDS.FIELDS;
    } else if (
      R.any(R.propEq("name", "EventLightCRMSettingsReportTemplates"))(routes)
    ) {
      return TAB_IDS.REPORT_TEMPLATES;
    } else if (
      R.any(R.propEq("name", "EventLightCRMSettingsDocumentTemplates"))(routes)
    ) {
      return TAB_IDS.DOCUMENT_TEMPLATES;
    } else if (
      R.any(R.propEq("name", "EventLightCRMSettingsEmailTemplates"))(routes)
    ) {
      return TAB_IDS.EMAIL_TEMPLATES;
    } else if (R.any(R.propEq("name", "EventLightCRMHistory"))(routes)) {
      return TAB_IDS.HISTORY;
    }
    return null;
  }
);

export const getTabs = createSelector(
  (_, props) => props.routes,
  (_, props) => props.params,
  getEventDetails,
  getCurrentRoute,
  getCanUserDo,
  (routes, params, eventDetails, currentRoute, canDo) => {
    let tabs = [];
    if (
      [
        TAB_IDS.FIELDS,
        TAB_IDS.TYPES,
        TAB_IDS.PORTAL,
        TAB_IDS.GUEST_LIST,
        TAB_IDS.LAYOUT,
        TAB_IDS.REPORT_TEMPLATES,
        TAB_IDS.DOCUMENT_TEMPLATES,
        TAB_IDS.MANAGE_DUPLICATES
      ].includes(currentRoute)
    ) {
      tabs = [
        {
          key: "fields",
          label: "Fields",
          enabled: true,
          active: isActiveRoute(routes, "EventLightCRMSettingsFields"),
          to: `/event-light/${eventDetails.id}/crm/settings/fields`
        },
        {
          key: "types",
          label: "Groups",
          enabled: canDo(`${STANDARD_MODULE_IDS.accounts.id}_manage`),
          active:
            params.moduleId === STANDARD_MODULE_IDS.accounts.id &&
            (isActiveRoute(routes, "EventLightCRMSettingsTypes") ||
              isActiveRoute(routes, "EventLightCRMSettingsTypesPortal") ||
              isActiveRoute(routes, "EventLightCRMSettingsTypesLayout") ||
              isActiveRoute(routes, "EventLightCRMSettingsTypesGuestList") ||
              isActiveRoute(
                routes,
                "EventLightCRMSettingsTypesAddRecordLayout"
              )),
          to: `/event-light/${eventDetails.id}/crm/settings/${STANDARD_MODULE_IDS.accounts.id}/types`
        },
        {
          key: "types",
          label: "People",
          enabled: canDo(`${STANDARD_MODULE_IDS.contacts.id}_manage`),
          active:
            params.moduleId === STANDARD_MODULE_IDS.contacts.id &&
            (isActiveRoute(routes, "EventLightCRMSettingsTypes") ||
              isActiveRoute(routes, "EventLightCRMSettingsTypesPortal") ||
              isActiveRoute(routes, "EventLightCRMSettingsTypesLayout") ||
              isActiveRoute(routes, "EventLightCRMSettingsTypesGuestList") ||
              isActiveRoute(
                routes,
                "EventLightCRMSettingsTypesAddRecordLayout"
              )),
          to: `/event-light/${eventDetails.id}/crm/settings/${STANDARD_MODULE_IDS.contacts.id}/types`
        },
        {
          key: "report-templates",
          label: "Report Templates",
          enabled: true,
          active: false,
          to: `/event-light/${eventDetails.id}/reports`
        },
        {
          key: "email-templates",
          label: "Email Templates",
          enabled: true,
          active: false,
          to: `/event-light/${eventDetails.id}/settings/email-templates`
        },
        {
          key: "document-templates",
          label: "Document Templates",
          enabled: true,
          active: false,
          to: `/event-light/${eventDetails.id}/settings/document-templates`
        }
      ];
    }

    return tabs;
  }
);
