import { combineReducers } from "redux";
import { RECEIVE, REQUEST, ERROR, CLEAR_ERROR, INVALIDATE } from "./constants";

import {
  addChangeToSubmission,
  updateApprovalNoteOnSubmission,
  toggleRowApprovalOnSubmission
} from "redux/modules/formRequests/helpers";

import { findIndex } from "lodash";

const form = (state = {}, action) => {
  let idx;

  switch (action.type) {
    case RECEIVE:
      return action.payload.form.form;
    case INVALIDATE:
      return {};

    case "RECORD_REQUESTS_RESPONSE_CHANGE":
      idx = findIndex(state.responses, { id: action.data.responseId });
      if (idx !== -1) {
        state.responses[idx] = addChangeToSubmission(
          state.responses[idx],
          action.data
        );
      }
      return state;

    case "RECORD_REQUESTS_APPROVAL_NOTE_UPDATE":
      idx = findIndex(state.responses, { id: action.data.responseId });
      if (idx !== -1) {
        state.responses[idx] = updateApprovalNoteOnSubmission(
          state.responses[idx],
          action.data
        );
      }
      return state;

    case "RECORD_REQUESTS_APPROVE_ROW":
    case "RECORD_REQUESTS_REJECT_ROW":
      idx = findIndex(state.responses, { id: action.data.responseId });
      if (idx !== -1) {
        state.responses[idx] = toggleRowApprovalOnSubmission(
          state.responses[idx],
          action.data
        );
      }
      return state;

    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

const error = (state = false, action) => {
  switch (action.type) {
    case ERROR:
      return action.payload;
    case CLEAR_ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  form,
  error,
  fetching
});
