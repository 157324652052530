import React from "react";
import Panel from "components/Global-2016/Panel";
import Table from "../Table";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const ReportSummaryPanel = ({ ...props }) => (
  <Panel style={css.panel}>
    <Table {...props} />
  </Panel>
);

export default CSSModules(ReportSummaryPanel, css);
