import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { noop } from "utils/General";
import { bindInstance } from "redux-mvc";

import {
  Div,
  Text3,
  Text2,
  FilterIcon,
  MediumFilledButton
} from "components/Base";

import { RadioButton } from "material-ui/RadioButton";
import CheckBox from "material-ui/Checkbox";

import CollapsiblePanel from "ui-kit/CollapsiblePanel";

import { actions } from "ui-kit/FiltersPanel";
import {
  getFiltersOn,
  getSelectedOptions
} from "ui-kit/FiltersPanel/selectors";

const Heading = ({ on = false }) => (
  <Div display="row.center.center" py={3} flex={1}>
    <Div flex={1} display="row.flex-start.center" pl={3}>
      <FilterIcon color="neutral8" />
      <Text3 color="neutral8" bold ml={2}>
        Filters
      </Text3>
    </Div>
    {(on && (
      <Div
        color="success8"
        bg="success1"
        bra={1}
        fs={1}
        fw={3}
        p={1}
        mr={1}
        uppercase
      >
        on
      </Div>
    )) ||
      null}
  </Div>
);

const decorate = connect(
  (state, props) => ({
    filtersOn: getFiltersOn(state, props),
    selectedOptions: getSelectedOptions(state, props)
  }),
  bindInstance({
    clearFilters: actions.clearFilters,
    toggleOption: actions.toggleFilter,
    init: actions.init
  })
);

class Filters extends React.Component {
  componentWillReceiveProps(newProps) {
    if (this.props.sections !== newProps.sections) {
      this.props.init(newProps.sections);
    }
  }

  render() {
    const {
      sections = [],
      clearFilters = noop,
      filtersOn = false,
      selectedOptions,
      toggleOption = noop,
      footer,
      style = {},
      init, // eslint-disable-line no-unused-vars
      ...styleProps
    } = this.props;

    return (
      <CollapsiblePanel
        heading={<Heading on={filtersOn} />}
        bra={1}
        display="column.space-between.stretch"
        style={{ overflow: "initial", flexShrink: 0, ...style }}
        {...styleProps}
      >
        {R.map(
          section => (
            <Div key={section.id} mb={3} px={4}>
              <Text3 bold mb={2}>
                {section.name}
              </Text3>
              {R.map(
                option =>
                  option.all ? (
                    <RadioButton
                      key={option.id}
                      value={option.id}
                      checked={R.contains(option.id, selectedOptions)}
                      onClick={() => {
                        if (!R.contains(option.id, selectedOptions)) {
                          toggleOption(option.id, {
                            meta: { option, section }
                          });
                        }
                      }}
                      label={
                        <Text2
                          bold
                          color={
                            R.contains(option.id, selectedOptions)
                              ? "primary7"
                              : "neutral8"
                          }
                        >
                          {option.name}
                        </Text2>
                      }
                      iconStyle={{ alignSelf: "center" }}
                    />
                  ) : (
                    <Div
                      key={option.id}
                      display="row.flex-start.center"
                      onClick={() =>
                        toggleOption(option.id, {
                          meta: { option, section }
                        })
                      }
                    >
                      <CheckBox
                        checked={R.contains(option.id, selectedOptions)}
                        style={{ width: "auto" }}
                      />
                      <Text2
                        bold
                        color={
                          R.contains(option.id, selectedOptions)
                            ? "primary7"
                            : "neutral8"
                        }
                      >
                        {option.name}
                      </Text2>
                    </Div>
                  ),
                section.options
              )}
            </Div>
          ),
          sections
        )}
        {footer}
        <MediumFilledButton
          bg="neutral2"
          color="primary7"
          mt={4}
          onClick={() => clearFilters()}
        >
          CLEAR FILTERS
        </MediumFilledButton>
      </CollapsiblePanel>
    );
  }
}

export default decorate(Filters);
