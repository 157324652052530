import { combineReducers } from "redux";
import { RECEIVE, REQUEST, ERROR, DELETE } from "./constants";

const list = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return {
        ...action.payload.reduce((map, template) => {
          map[template.id] = template;
          return map;
        }, {})
      };
    case DELETE:
      const result = {
        ...state
      };
      delete result[action.payload];
      return result;
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

const error = (state = {}, action) => {
  switch (action.type) {
    case ERROR:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  list,
  error,
  fetching
});
