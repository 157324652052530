import PropTypes from "prop-types";
import React from "react";
import Editor from "../../Editors/TypesEditor";
import SelectBase from "../SelectBase";

const TypeSelect = ({ onSelect, value, list, rowMetaData }) => (
  <SelectBase
    formatter={value}
    editor={
      <Editor onSelect={onSelect} list={list} rowMetaData={rowMetaData} />
    }
  />
);

TypeSelect.propTypes = {
  value: PropTypes.string,
  list: PropTypes.arrayOf(PropTypes.object),
  rowMetaData: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired
};

export default TypeSelect;
