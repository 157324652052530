import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";

class Dialog extends Component {
  @autobind
  onCancel() {
    if (this.props.onCancel) {
      this.props.onCancel();
    }
    if (this.props.hideModal) {
      this.props.hideModal();
    }
  }

  @autobind
  onConfirm() {
    if (this.props.onConfirm) {
      this.props.onConfirm();
    }
    if (this.props.hideModal) {
      this.props.hideModal();
    }
  }

  render() {
    const { confirmLabel, cancelLabel, title, message } = this.props;
    return (
      <div>
        <div className="modal-header">
          <div className="modal-title">{title || "Are you sure?"}</div>
        </div>

        <div className="modal-body">
          <div className="modal-body-wrapper">
            <div>{message}</div>
            <br />
            <div className="actions-wrapper">
              <a className="action-cancel small clear" onClick={this.onCancel}>
                {cancelLabel || "Cancel"}
              </a>
              <span className="action-positive small" onClick={this.onConfirm}>
                {confirmLabel || "Okay"}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Dialog.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default Dialog;
