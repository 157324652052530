import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Controller from "./Controller";
import { withRouter } from "react-router";

import SignupActions from "utils/Global/WebApiUtils";
import * as SnackbarActions from "redux/modules/snackbar/actions";

function mapStateToProps(state) {
  return {
    sidebar: state.sidebar
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...SnackbarActions
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
