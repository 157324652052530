import { put, all, fork, take, select, call } from "redux-saga/effects";
import * as R from "ramda";
import { batchActions } from "redux-batched-actions";

import { eventId as getEventId } from "redux/modules/event/selectors";

import { actions } from "Items/Manage"; // circular reference otherwise
import { getAllItemTypesByEvent } from "redux/modules/items/types/actions";
import { getForms } from "redux/modules/formsV2/forms/actions";
import { getUsers as fetchUsers } from "redux/modules/eventUsers/actions";

import { actions as FilterControlsActions } from "ui-kit/FilterControls";
import { actions as MiniItemsSelectorActions } from "ui-kit/MiniItemsSelector";

import {
  MEALS_FILTER_ID,
  PASSES_FILTER_ID,
  SOURCE_FILTER_ID,
  REQUESTER_FILTER_ID,
  GROUP_BY_FILTER_ID,
  ATTENDEE_FILTER_ID,
  GROUP_FILTER_ID,
  SHOW_MEALS_FILTER_ID,
  INVENTORY_FILTER_ID,
  BOOTHS_FILTER_ID,
  SPONSORSHIPS_FILTER_ID
} from "Passes/Common/constants";

const watchApplyItemFilters = function*() {
  for (;;) {
    const action = yield take(FilterControlsActions.applyFilters.type);
    const filter = R.path(["meta", "instanceId"], action);
    const selectedIds = R.keys(action.payload);
    switch (filter) {
      case REQUESTER_FILTER_ID:
        yield put(actions.setSelectedRequester(selectedIds));
        break;
      case PASSES_FILTER_ID:
        yield put(actions.setSelectedPasses(selectedIds));
        break;
      case SOURCE_FILTER_ID:
        yield put(actions.setSelectedSources(selectedIds));
        break;
      case MEALS_FILTER_ID:
        yield put(actions.setSelectedMeals(selectedIds));
        break;
      case INVENTORY_FILTER_ID:
        yield put(actions.setSelectedInventoryItems(selectedIds));
        break;
      case BOOTHS_FILTER_ID:
        yield put(actions.setSelectedBoothItems(selectedIds));
        break;
      case SPONSORSHIPS_FILTER_ID:
        yield put(actions.setSelectedSponsorshipItems(selectedIds));
        break;
      default:
        break;
    }
  }
};

const watchClearItemFilters = function*() {
  for (;;) {
    const action = yield take(FilterControlsActions.clear.type);
    const filter = R.path(["meta", "instanceId"], action);
    switch (filter) {
      case MEALS_FILTER_ID:
        yield put(actions.setSelectedMeals([]));
        break;
      case PASSES_FILTER_ID:
        yield put(actions.setSelectedPasses([]));
        break;
      case SOURCE_FILTER_ID:
        yield put(actions.setSelectedSources([]));
        break;
      case REQUESTER_FILTER_ID:
        yield put(actions.setSelectedRequester([]));
        break;
      case INVENTORY_FILTER_ID:
        yield put(actions.setSelectedInventoryItems([]));
        break;
      case BOOTHS_FILTER_ID:
        yield put(actions.setSelectedBoothItems([]));
        break;
      case SPONSORSHIPS_FILTER_ID:
        yield put(actions.setSelectedSponsorshipItems([]));
        break;
      default:
        break;
    }
  }
};

const watchApplyViewFilter = function*() {
  for (;;) {
    const action = yield take(FilterControlsActions.toggleOption.type);
    if (
      R.contains(R.path(["meta", "instanceId"], action), [
        GROUP_BY_FILTER_ID,
        ATTENDEE_FILTER_ID,
        GROUP_FILTER_ID,
        SHOW_MEALS_FILTER_ID
      ])
    ) {
      yield put(actions.fetchData());
    }
  }
};

const watchClearViewFilter = function*() {
  for (;;) {
    const action = yield take([
      FilterControlsActions.clear.type,
      actions.clearFilters.type
    ]);
    if (
      R.contains(R.path(["meta", "instanceId"], action), [
        GROUP_BY_FILTER_ID,
        ATTENDEE_FILTER_ID,
        GROUP_FILTER_ID
      ])
    ) {
      yield put(actions.fetchData());
    } else if (action.type === actions.clearFilters.type) {
      yield put(
        batchActions(
          R.map(
            instanceId =>
              FilterControlsActions.clear(null, { meta: { instanceId } }),
            [ATTENDEE_FILTER_ID, GROUP_FILTER_ID]
          )
        )
      );
      yield put(actions.fetchData());
    }
  }
};

const watchFetchFilterData = function*() {
  for (;;) {
    const { meta } = yield take(FilterControlsActions.showModal.type);
    const eventId = yield select(getEventId);

    if (
      R.contains(meta.instanceId, [
        INVENTORY_FILTER_ID,
        PASSES_FILTER_ID,
        SOURCE_FILTER_ID,
        REQUESTER_FILTER_ID
      ])
    ) {
      yield put(MiniItemsSelectorActions.setLoading(true, { meta }));

      let res = Promise.resolve();

      switch (meta.instanceId) {
        case PASSES_FILTER_ID:
          res = yield put(getAllItemTypesByEvent(eventId));
          break;
        case INVENTORY_FILTER_ID:
          res = yield put(getAllItemTypesByEvent(eventId));
          break;
        case SOURCE_FILTER_ID:
          res = yield put(getForms(eventId));
          break;
        case REQUESTER_FILTER_ID:
          res = yield put(fetchUsers(eventId));
          break;
        default:
          break;
      }

      yield call(R.always(res));
      yield put(MiniItemsSelectorActions.setLoading(false, { meta }));
    }
  }
};

const rootSaga = function*() {
  yield all([
    fork(watchFetchFilterData),
    fork(watchApplyViewFilter),
    fork(watchClearViewFilter),
    fork(watchApplyItemFilters),
    fork(watchClearItemFilters)
  ]);
};

export default rootSaga;
