import React, { Component } from "react";
import View from "./View";
import ReactSidebar from "react-sidebar";
import Sidebar from "./Sidebar";

const mql = window.matchMedia("(min-width: 821px)");

class Controller extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarDocked: mql.matches,
      sidebarOpen: mql.matches,
      loading: true,
      hideSidebar: null,
      pageId: null,
      contentBackgroundColor: undefined
    };
  }

  componentWillMount() {
    mql.addListener(this.mediaQueryChanged);
  }

  componentWillUnmount() {
    mql.removeListener(this.mediaQueryChanged);
  }

  async componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      await this.handlePageSettings(this.props);
    }

    return true;
  }

  async handlePageSettings(props) {
    const hideSidebar = props.router.routes.some(
      r => r.name === "portalV2_PortalCommunity"
    );

    this.setState({
      loading: false,
      hideSidebar
    });

    return true;
  }

  /* eslint-disable */
  async componentDidMount() {
    const user = await this.props.getPortalUser({
      userId: this.props.user.id,
      eventId: this.props.params.portalId
    });

    // redirect to portal switcher
    if (!user || !user.active_view) {
      window.location = `/portal-switch/${this.props.params.portalSlug}/${this.props.params.portalId}`;
      return null;
    }

    await this.props.getSettings(
      this.props.portal.event.id,
      user.active_view.id
    );

    await this.handlePageSettings(this.props);

    // get count of unread notifications
    /*
      // @NOTE: Hiding until reimplemented
      if (user.active_view.type === "account") {
        this.props.fetchCountOfUnseenNotifications({
          eventId: this.props.porzztal.event.id,
          accountId: user.active_view.id
        });
      } else {
        this.props.fetchCountOfUnseenNotifications({
          eventId: this.props.portal.event.id
        });
      }
      */
  }
  /* eslint-enable */

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      if (this.state.pageId === nextProps.router.params.pageId) {
        return false;
      } else if (!nextProps.router.params.pageId) {
        this.setState({ hideSidebar: false });
      } else {
        this.setState({ hideSidebar: null });
      }
    }
    return true;
  }

  onSetSidebarOpen = open => {
    this.setState({ sidebarOpen: open });
  };

  mediaQueryChanged = () => {
    this.setState({ sidebarDocked: mql.matches, sidebarOpen: false });
  };

  render() {
    const {
      user,
      showModal,
      hideModal,
      params,
      routes,
      portal,
      portalUser,
      children,
      isFetchingPortalUser,
      eventDetails,
      messages,
      atLanding,
      settings
    } = this.props;

    const { hideSidebar, contentBackgroundColor } = this.state;

    if (
      this.state.hideSidebar === null ||
      this.state.loading ||
      isFetchingPortalUser ||
      !portalUser.active_view ||
      !portal.id
    ) {
      return null;
    }

    const partialPath = `/portals/${params.portalSlug}/${params.portalId}`;

    // const sidebarStyles = {
    //   sidebar: {
    //     backgroundColor: "#232a2b",
    //     width: "300px"
    //   },
    //   content: { overflowY: "auto" }
    // };

    const child = (
      <View
        user={user}
        showModal={showModal}
        hideModal={hideModal}
        partialPath={partialPath}
        params={params}
        routes={routes}
        portal={portal}
        portalUser={portalUser}
        children={children}
        isFetchingPortalUser={isFetchingPortalUser}
        eventDetails={eventDetails}
        messages={messages}
        hideSidebar={hideSidebar}
        atLanding={atLanding}
        settings={settings}
        toggleSidebar={this.onSetSidebarOpen}
        isSidebarOpen={this.state.sidebarOpen}
      />
    );

    if (hideSidebar) {
      return child;
    }

    return (
      <ReactSidebar
        onSetOpen={this.onSetSidebarOpen}
        sidebar={
          <Sidebar
            hideMenu={
              this.state.sidebarDocked
                ? undefined
                : () => this.onSetSidebarOpen(false)
            }
          />
        }
        docked={this.state.sidebarDocked}
        styles={{
          sidebar: {
            backgroundColor: "#232a2b",
            width: this.state.sidebarOpen ? "300px" : "0px"
          },
          content: {
            overflowY: "auto",
            backgroundColor: contentBackgroundColor
          }
        }}
        transitions={false}
        open={this.state.sidebarOpen}
      >
        {child}
      </ReactSidebar>
    );
  }
}

export default Controller;
