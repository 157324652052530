import React, { Fragment } from "react";
import * as R from "ramda";
import { Div, FontIcon, SmallCheckbox, AddIcon } from "components/Base";
import { Tabs, Content, Column } from "../Common";

const GroupType = ({ selected, name, onClick }) => (
  <Div display="row.flex-start.center" mb={2} onClick={onClick}>
    <SmallCheckbox selected={selected} onClick={() => {}} />
    <Div ml={3} fs={4} fw={3} color="gray7">
      {name}
    </Div>
  </Div>
);

const Group = ({ name, closeDate, onUnassign, onClickDueDate, hasExpired }) => (
  <Div display="row.space-between.center" bb={1} bc="neutral1" px={2} py={3}>
    <Div display="row.flex-start.center" fs={1} fw={3} color="gray7">
      {name}
    </Div>
    <Div fs={1} display="row.flex-start.center">
      <Div
        onClick={onClickDueDate}
        color={
          hasExpired
            ? {
                default: "red6",
                hover: "red8"
              }
            : closeDate
            ? {
                default: "green6",
                hover: "green9"
              }
            : {
                default: "gray5",
                hover: "purple9"
              }
        }
      >
        {closeDate ? closeDate : "Add due date"}
      </Div>
      <Div
        style={{
          marginLeft: 3,
          marginRight: 3
        }}
      >
        &middot;
      </Div>
      <Div
        onClick={onUnassign}
        color={{
          default: "gray5",
          hover: "purple9"
        }}
      >
        Unassign
      </Div>
    </Div>
  </Div>
);

const Action = ({ name, description, icon, onClick }) => (
  <Div p={5} width={500} bg="white" bra={1} display="row">
    <Div
      width={50}
      mr={5}
      style={{
        flexShrink: 0
      }}
    >
      <Div
        p={2}
        bg="gray0"
        color="gray3"
        display="column.center.center"
        bra={1}
        style={{
          backgroundColor: "#F5F5F5"
        }}
      >
        <FontIcon
          fs={7}
          style={{
            color: "#D8D8D8"
          }}
        >
          {icon}
        </FontIcon>
      </Div>
    </Div>
    <Div display="column">
      <Div fs={3} fw={4} color="gray7" mb={1}>
        {name}
      </Div>
      <Div fs={2} mb={4} color="gray6">
        {description}
      </Div>
      <Div
        bra={1}
        p={2}
        color="gray7"
        fs={1}
        display="row.center.center"
        bg={{
          default: "neutral1",
          hover: "neutral2"
        }}
        onClick={onClick}
        width={85}
        height={35}
      >
        Let's do it
      </Div>
    </Div>
  </Div>
);

const SceneLink = ({
  actions,
  link,
  copyButtonText,
  linkInputRef,
  //
  onCopyLinkInput,
  onClickLinkInput,
  onClickSendForm
}) => {
  return (
    <Div display="column">
      <Div fs={7} fw={3} mb={1} color="gray7">
        Your form lives at this link
      </Div>
      <Div fs={4} mb={4} color="gray7">
        Share it with people so they can visit and respond to your form
      </Div>
      <Div display="row.flex-start.center" mb={8}>
        <input
          ref={linkInputRef}
          style={{
            display: "flex",
            width: "570px",
            background: "none",
            border: "1px solid #C9C9C9",
            borderRight: 0,
            outline: "none",
            fontSize: 18,
            color: "#494949",
            cursor: "pointer",
            backgroundColor: "#EFF2F5",
            height: 43,
            padding: "0 15px",
            borderRadius: "7px 0 0 7px"
          }}
          value={link}
          onChange={() => {}}
          onClick={onClickLinkInput}
        />
        <Div
          uppercase
          bg={{
            default: "purple8",
            hover: "purple9"
          }}
          color="white"
          onClick={onCopyLinkInput}
          mr={3}
          display="row.center.center"
          px={3}
          style={{
            borderRadius: "0 7px 7px 0",
            letterSpacing: "1px"
          }}
          fs={2}
          height={43}
        >
          {copyButtonText}
        </Div>
        <Div
          uppercase
          bg={{
            default: "orange5",
            hover: "orange6"
          }}
          color="white"
          onClick={onClickSendForm}
          height={43}
          px={3}
          display="row.center.center"
          fs={2}
          style={{
            borderRadius: 7,
            letterSpacing: "1px"
          }}
        >
          Send Form
        </Div>
      </Div>

      {actions.length ? (
        <Fragment>
          <Div fs={4} fw={3} mb={4} color="gray7">
            What else can you do?
          </Div>
          {actions.map(action => (
            <Action key={action.id} {...action} />
          ))}
        </Fragment>
      ) : null}
    </Div>
  );
};

const SceneAssign = ({
  formScope,
  groupTypes,
  groups,
  //
  onClickAddToAllGroups,
  onClickRemoveFromAllGroups,
  onClickAssignToGroup,
  //
  onClickAddToAllPeople,
  onClickAssignToPerson,
  onClickRemoveFromAllPeople,
  peopleTypes,
  people
}) => {
  return (
    <Column>
      <Div fs={7} fw={3} mb={1} color="gray7">
        Assign Form
      </Div>
      <Div fs={4} mb={4} color="gray7">
        Control which people and groups can fill out the form via your hubs
      </Div>

      {formScope === "account" || !formScope ? (
        <Div bra={1} p={5} bg="white">
          <Div fs={5} fw={3} mb={1} color="gray7">
            Which groups should see this form?
          </Div>
          <Div display="row.flex-start.center" fs={1} color="purple8" mb={3}>
            <Div
              onClick={onClickAddToAllGroups}
              color={{
                default: "purple8",
                hover: "purple9"
              }}
            >
              Add to all
            </Div>
            <Div
              style={{
                marginLeft: 3,
                marginRight: 3
              }}
            >
              &middot;
            </Div>
            <Div
              onClick={onClickRemoveFromAllGroups}
              color={{
                default: "purple8",
                hover: "purple9"
              }}
            >
              Remove from all group types
            </Div>
          </Div>
          <Div>
            {groupTypes.map(groupType => (
              <GroupType key={groupType.id} {...groupType} />
            ))}
          </Div>
          <Div fw={4} fs={2} color="gray7" mt={8}>
            Specific groups with access to this form
            {groups.length ? ` (${groups.length})` : null}:
          </Div>
          <Div>
            {groups.map(group => (
              <Group key={group.id} {...group} />
            ))}
          </Div>
          <Div
            bg={{
              default: "neutral1",
              hover: "neutral2"
            }}
            display="row.center.center"
            color="purple8"
            fs={2}
            bra={1}
            fw={3}
            onClick={onClickAssignToGroup}
            height={35}
            width={145}
            mt={5}
          >
            <AddIcon fs={2} color="purple8" mr={2} />
            Assign to group
          </Div>
        </Div>
      ) : null}

      {!formScope ? (
        <Div
          bg="gray4"
          width={1}
          my={3}
          style={{
            height: "1px"
          }}
        ></Div>
      ) : null}

      {formScope === "contact" || !formScope ? (
        <Div bra={1} p={5} bg="white">
          <Div fs={5} fw={3} mb={1} color="gray7">
            Which people should see this form?
          </Div>
          <Div display="row.flex-start.center" fs={1} color="purple8" mb={3}>
            <Div
              onClick={onClickAddToAllPeople}
              color={{
                default: "purple8",
                hover: "purple9"
              }}
            >
              Add to all
            </Div>
            <Div
              style={{
                marginLeft: 3,
                marginRight: 3
              }}
            >
              &middot;
            </Div>
            <Div
              onClick={onClickRemoveFromAllPeople}
              color={{
                default: "purple8",
                hover: "purple9"
              }}
            >
              Remove from all people types
            </Div>
          </Div>
          <Div>
            {peopleTypes.map(peopleType => (
              <GroupType key={peopleType.id} {...peopleType} />
            ))}
          </Div>
          <Div fw={4} fs={2} color="gray7" mt={8}>
            Specific individuals with access to this form
            {people.length ? ` (${people.length})` : null}:
          </Div>
          <Div>
            {people.map(person => (
              <Group key={person.id} {...person} />
            ))}
          </Div>
          <Div
            bg={{
              default: "neutral1",
              hover: "neutral2"
            }}
            display="row.center.center"
            color="purple8"
            fs={2}
            bra={1}
            fw={3}
            onClick={onClickAssignToPerson}
            height={35}
            width={170}
            mt={5}
          >
            <AddIcon fs={2} color="purple8" mr={2} />
            Assign to individual
          </Div>
        </Div>
      ) : null}
    </Column>
  );
};

const View = ({ tabs, activeRoute, ...props }) => {
  return (
    <Div>
      <Tabs tabs={tabs} />
      <Content>
        <Div>
          {R.prop(activeRoute, {
            ModuleFormShare: <SceneLink {...props} />,
            ModuleFormShareAssign: <SceneAssign {...props} />
          })}
        </Div>
      </Content>
    </Div>
  );
};

export default View;
