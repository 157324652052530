import React from "react";
import { Div } from "components/Base";

const Group = ({ children }) => (
  <Div bra={1} bg="white" p={5}>
    {children}
  </Div>
);
const GroupTitle = ({ children }) => (
  <Div fs={4} fw={3} mb={3}>
    {children}
  </Div>
);

const View = ({ accountRecordTypes }) => (
  <Div>
    <Div
      color="white"
      py={7}
      px={7}
      fw={3}
      fs={6}
      style={{
        backgroundColor: "#813CD2"
      }}
    >
      Reports
    </Div>
    <Div py={5} px={7}>
      <Group>
        <GroupTitle>Groups by Type</GroupTitle>
        {accountRecordTypes.map(type => (
          <Div key={type.id} onClick={type.goToTypeReport} fw={3} mb={2} fs={3}>
            {type.name}
          </Div>
        ))}
      </Group>
    </Div>
  </Div>
);

export default View;
