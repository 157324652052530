import React from "react";
import { SubNavWrapper, SubNavLink } from "EventLight/Common/SubNav";
import { TAB_IDS } from "./constants";
import { withRouter } from "react-router";

const SubNav = ({ selectedView, router, params, tabs = [] }) => (
  <SubNavWrapper tabs={tabs} supportId="customModule">
    <SubNavLink
      name="Manage"
      active={selectedView === TAB_IDS.MANAGE}
      onClick={() =>
        router.push({
          pathname: `/event-light/${params.eventId}/custom/${params.moduleId}/manage`
        })
      }
    />

    <SubNavLink
      name="Settings"
      active={TAB_IDS.APPROVERS === selectedView}
      onClick={() =>
        router.push({
          pathname: `/event-light/${params.eventId}/custom/${params.moduleId}/settings/approvers/`
        })
      }
    />
  </SubNavWrapper>
);

export default withRouter(SubNav);
