import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE, PAGES } from "./constants";

const iniState = {
  selectedPage: PAGES.SELECT_EVENT,
  organization: {},
  allUserEvents: [],
  eventsFilter: "",
  selectedEvent: {},
  contentType: "everything",
  processing: false,
  loading: false,
  includeAllRecordData: false,
  eventOptions: {},
  includeAllRecords: [],
  isLenndAdmin: false,
  excludedContent: {
    users: [],
    modules: [],
    forms: [],
    document_requests: []
  }
};

const handlers = createHandlers({
  iniState,
  reducers: {
    cancelInstance: R.always(iniState),
    cloneData: R.identity,
    getEventOptions: R.identity,
    selectEvent: R.identity,
    toggleExcludedContent: (state, { payload: { type, id } }) => {
      if (state.excludedContent[type].includes(id)) {
        return {
          excludedContent: {
            ...state.excludedContent,
            [type]: state.excludedContent[type].filter(typeId => typeId !== id)
          }
        };
      }

      return {
        excludedContent: {
          ...state.excludedContent,
          [type]: [...state.excludedContent[type], id]
        }
      };
    },
    toggleChildren: (state, { payload: { id, type, selected } }) => {
      const item = state.eventOptions[type].find(item => item.id === id);
      const allIds = R.reduce((map, row) => {
        if (map[row.type]) {
          map[row.type] = [...map[row.type], row.id];
        } else {
          map[row.type] = [row.id];
        }

        return map;
      }, {})([item, ...item.related]);

      if (selected) {
        return {
          excludedContent: R.compose(
            R.reduce((map, key) => {
              if (allIds[key]) {
                map[key] = state.excludedContent[key].filter(
                  id => !allIds[key].includes(id)
                );
              } else {
                map[key] = state.excludedContent[key];
              }
              return map;
            }, {}),
            R.keys
          )(state.excludedContent)
        };
      }

      return {
        excludedContent: R.compose(
          R.reduce((map, key) => {
            if (allIds[key]) {
              map[key] = R.uniq([
                ...allIds[key],
                ...state.excludedContent[key]
              ]);
            } else {
              map[key] = state.excludedContent[key];
            }
            return map;
          }, {}),
          R.keys
        )(state.excludedContent)
      };
    },
    toggleIncludeAllRecords: (state, { payload: { id } }) => {
      if (state.includeAllRecords.includes(id)) {
        return {
          includeAllRecords: state.includeAllRecords.filter(
            typeId => typeId !== id
          )
        };
      }

      return {
        includeAllRecords: [...state.includeAllRecords, id]
      };
    },
    toggleAllContentType: (state, { payload: { type, selected } }) => {
      const allIds = R.compose(
        R.reduce((map, row) => {
          if (map[row.type]) {
            map[row.type] = [...map[row.type], row.id];
          } else {
            map[row.type] = [row.id];
          }

          row.related.forEach(subrow => {
            if (map[subrow.type]) {
              map[subrow.type] = [...map[subrow.type], subrow.id];
            } else {
              map[subrow.type] = [subrow.id];
            }
          });

          return map;
        }, {})
      )(state.eventOptions[type]);

      if (selected) {
        return {
          excludedContent: R.compose(
            R.reduce((map, key) => {
              if (allIds[key]) {
                map[key] = state.excludedContent[key].filter(
                  id => !allIds[key].includes(id)
                );
              } else {
                map[key] = state.excludedContent[key];
              }
              return map;
            }, {}),
            R.keys
          )(state.excludedContent)
        };
      }

      return {
        excludedContent: R.compose(
          R.reduce((map, key) => {
            if (allIds[key]) {
              map[key] = R.uniq([
                ...allIds[key],
                ...state.excludedContent[key]
              ]);
            } else {
              map[key] = state.excludedContent[key];
            }
            return map;
          }, {}),
          R.keys
        )(state.excludedContent)
      };
    }
  },
  namespace: NAMESPACE
});

export default handlers;
