import React from "react";
import {
  SearchIcon,
  BigFilledInput,
  SuperFilledButton,
  Div,
  Stepper,
  DarkTab,
  LoadingIcon
} from "components/Base";
import * as R from "ramda";
import { withProps } from "utils/General";
import { CREDENTIAL_TYPE_ID, MEAL_TYPE_ID } from "utils/item-types";

import Modal from "components/Global/Modal/Layout/StyleWrapper";
import PassSelector from "Orders/CreateOrderModal/PassSelector";
import MealSelector from "Orders/CreateOrderModal/MealSelector";

import { ToggleFeature } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

const Tab = withProps({
  fs: 3,
  mr: 4
})(DarkTab);
const OverflowScroll = withProps({
  style: {
    overflowY: "auto"
  }
})(Div);
const Frame = withProps({
  height: 500
})(Div);
const Footer = withProps({
  display: "row.space-between.center",
  px: 5,
  py: 3,
  bt: 1,
  bc: "gray3"
})(Div);

const getEntityType = isAllocation => (isAllocation ? "Allocation" : "Order");

const Items = ({
  saving,
  itemTypes,
  activeItemTypeId,
  itemTypeProps,
  onItemTypeSearchChange,
  isValidStep,
  onSave,
  countOfSelectedItems
}) => {
  return (
    <Frame>
      <Div px={10}>
        <Div display="row.flex-start.center" pt={3}>
          {R.map(type => (
            <Tab onClick={type.onClick} active={type.active}>
              {type.name}
            </Tab>
          ))(itemTypes)}
        </Div>

        <BigFilledInput
          bg="neutral1"
          color="gray6"
          continuous
          onChange={onItemTypeSearchChange}
          placeholder="Search items..."
          LeftIcon={SearchIcon}
          rightIconColor="gray6"
          width={1}
          mb={4}
        />

        <OverflowScroll height={327}>
          {R.propOr(
            itemTypeProps && itemTypeProps[activeItemTypeId] ? (
              <PassSelector {...itemTypeProps[activeItemTypeId]} />
            ) : null,
            activeItemTypeId,
            {
              [MEAL_TYPE_ID]:
                itemTypeProps && itemTypeProps[MEAL_TYPE_ID] ? (
                  <MealSelector {...itemTypeProps[MEAL_TYPE_ID]} />
                ) : null
            }
          )}
        </OverflowScroll>
      </Div>

      <Footer>
        <Div uppercase color="gray7" fs={3} fw={3}>
          Quantity: {countOfSelectedItems}
        </Div>

        <SuperFilledButton
          bg={isValidStep ? "orange5" : "neutral2"}
          mr={3}
          onClick={isValidStep ? onSave : undefined}
        >
          <Div display="row.center.center">
            <span>{saving ? "Saving..." : "Save"}</span>
            {saving ? <LoadingIcon size={20} color="white" ml={3} /> : null}
          </Div>
        </SuperFilledButton>
      </Footer>
    </Frame>
  );
};

const View = ({
  onItemTypeSearchChange,
  onSave,
  //
  loading,
  saving,
  itemTypes,
  activeItemTypeId,
  itemTypeProps,
  isValidStep,
  countOfSelectedItems
}) => {
  return (
    <Modal
      width={915}
      heading={
        <Div display="row.space-between.center" width={1}>
          <Div>Add Items</Div>
        </Div>
      }
      bodyStyles={{ padding: 0 }}
    >
      <Div>
        {loading ? (
          <Div py={20} display="row.center.center">
            <LoadingIcon size={60} color="neutral4" />
          </Div>
        ) : (
          <Items
            {...{
              onSave,
              onItemTypeSearchChange,
              //
              saving,
              itemTypes,
              activeItemTypeId,
              itemTypeProps,
              isValidStep,
              countOfSelectedItems
            }}
          />
        )}
      </Div>
    </Modal>
  );
};

export default View;
