import { MODULES as NAV_MODULES } from "components/Global/NavIcons";
import * as MODULES from "@lennd/value-types/src/constants/standard-modules";

const ALL_ITEM_TYPE_IDS = "all";
const CREDENTIAL_TYPE_ID = "f43199fe-4c5b-418d-b36c-8aba2e05cbe8";
const ZONE_TYPE_ID = "319f5fbd-9eef-4f63-923d-69582d0766c9";
const INVENTORY_TYPE_ID = "350da531-7e64-403f-866e-3685fafbabc4";
const LOCATION_TYPE_ID = "78771f81-50d6-4c13-a473-ca908c3795bd";
const MEAL_TYPE_ID = "0c2d196e-aa8e-4b67-b718-1eda4eae7597";
const ASSETS_TYPE_ID = "350da531-7e64-403f-866e-3685fafbabc4";
const SPONSORSHIP_TYPE_ID = "64911bf7-d0c7-48b9-bf2d-41d48e633316";
const REGISTRATION_TYPE_ID = "fbe99450-c271-4a23-ba82-b2acd4006586";
const BOOTH_TYPE_ID = "a0b55a3e-e81a-4100-8d42-0db08e5a1440";
const LODGING_TYPE_ID = "21e2069f-df9b-480c-ab5d-8083dd8e2140";
const AUCTION_ITEMS_TYPE_ID = "24d96005-0399-4c5d-9c11-57714ca72e32";
const CUSTOM_ITEMS_TYPE_ID = "235d6bc2-8164-4b60-94cb-3bafc5db104c";

const LABELS = {
  [CREDENTIAL_TYPE_ID]: {
    icon: NAV_MODULES.credentials.iconKey,
    color: NAV_MODULES.credentials.color,
    label: "Passes",
    id: CREDENTIAL_TYPE_ID
  },
  [LODGING_TYPE_ID]: {
    icon: "hotel",
    color: NAV_MODULES.credentials.color,
    label: "Lodging",
    id: LODGING_TYPE_ID
  },
  [ZONE_TYPE_ID]: {
    icon: "",
    color: "gray",
    label: "Zones",
    id: ZONE_TYPE_ID
  },
  [INVENTORY_TYPE_ID]: {
    icon: NAV_MODULES.inventory.iconKey,
    color: NAV_MODULES.inventory.color,
    label: "Inventory",
    id: INVENTORY_TYPE_ID
  },
  [LOCATION_TYPE_ID]: {
    icon: "map",
    color: "green",
    label: "Locations",
    id: LOCATION_TYPE_ID
  },
  [MEAL_TYPE_ID]: {
    icon: NAV_MODULES.catering.iconKey,
    color: NAV_MODULES.catering.color,
    label: "Meals",
    id: MEAL_TYPE_ID
  },
  [ASSETS_TYPE_ID]: {
    icon: "build",
    color: NAV_MODULES.inventory.color,
    label: "Assets & Equipment",
    id: ASSETS_TYPE_ID
  },
  [SPONSORSHIP_TYPE_ID]: {
    icon: "attach_money",
    color: "rgb(0, 120, 42)",
    label: "Sponsorships",
    id: SPONSORSHIP_TYPE_ID
  },
  [REGISTRATION_TYPE_ID]: {
    icon: "list",
    color: "rgb(0, 120, 42)",
    label: "Registrations",
    id: REGISTRATION_TYPE_ID
  },
  [BOOTH_TYPE_ID]: {
    icon: "where_to_vote",
    color: "rgb(0, 120, 42)",
    label: "Booths & Expo Space",
    id: BOOTH_TYPE_ID
  },
  [AUCTION_ITEMS_TYPE_ID]: {
    icon: "where_to_vote",
    color: "rgb(0, 120, 42)",
    label: "Auction Items",
    id: AUCTION_ITEMS_TYPE_ID
  },
  [CUSTOM_ITEMS_TYPE_ID]: {
    icon: "where_to_vote",
    color: "rgb(0, 120, 42)",
    label: "custom Items",
    id: CUSTOM_ITEMS_TYPE_ID
  }
};
const ITEM_MODULE_MAP = {
  [CREDENTIAL_TYPE_ID]: MODULES.credentials,
  [MEAL_TYPE_ID]: MODULES.catering
};

export {
  ALL_ITEM_TYPE_IDS,
  ITEM_MODULE_MAP,
  LODGING_TYPE_ID,
  CREDENTIAL_TYPE_ID,
  ZONE_TYPE_ID,
  INVENTORY_TYPE_ID,
  LOCATION_TYPE_ID,
  MEAL_TYPE_ID,
  ASSETS_TYPE_ID,
  SPONSORSHIP_TYPE_ID,
  REGISTRATION_TYPE_ID,
  BOOTH_TYPE_ID,
  AUCTION_ITEMS_TYPE_ID,
  CUSTOM_ITEMS_TYPE_ID,
  LABELS
};
