import * as R from "ramda";
import { createContext } from "redux-mvc";

import manage from "Passes/Manage";
import ManageView, { contextConfig, ManageProps } from "Items/Manage/View";

const decorate = R.compose(
  ManageProps,
  createContext({ module: manage, ...contextConfig })
);

export default decorate(ManageView);
