export const MANAGE_BY = {
  ALL_PASSES: "all-passes",
  ATTENDEE_LIST: "attendee-list",
  GROUP_AFFILIATION: "group-affiliation",
  ORDER_NUMBER: "order-numbers-list"
};

export const MODES = {
  REVIEW: "review",
  ISSUANCE: "issuance"
};

export const PASS_MODAL_ID = "passesModal";
export const MEAL_MODAL_ID = "mealsModal";
export const USER_MODAL_ID = "userModal";
export const SOURCE_MODAL_ID = "sourceModal";

// filters
export const MEALS_FILTER_ID = "itemsFilter";
export const SHOW_MEALS_FILTER_ID = "showMeals";
export const PASSES_FILTER_ID = "passesFilter";
export const SOURCE_FILTER_ID = "sourceFilter";
export const REQUESTER_FILTER_ID = "requesterFilter";
export const GROUP_BY_FILTER_ID = "groupByFilter";
export const ATTENDEE_FILTER_ID = "attendeeFilter";
export const GROUP_FILTER_ID = "groupFilter";
export const INVENTORY_FILTER_ID = "inventoryFilter";
export const BOOTHS_FILTER_ID = "boothsFilter";
export const SPONSORSHIPS_FILTER_ID = "sponsorshipsFilter";
export const REFRESH_DASHBOARDS = "refreshDashboards";

export const ALL_GROUPS = "allGroups";
export const EVERYONE = "everyone";

export const CREATED_VIA_GLM = "sourceCreatedViaGLM";
export const CREATED_VIA_PORTAL = "sourceCreatedViaPortal";

export const TABLE_VIEW_ID = "order-mgm-table-view";
