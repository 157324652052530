import React, { Component } from "react";
import { Div, SearchIcon } from "components/Base";

class ForceFocus extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.form = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("keypress", this.handleKeypress);
    document.addEventListener("submit", this.reset);
  }

  componentWillUnmount() {
    document.removeEventListener("keypress", this.handleKeypress);
    document.removeEventListener("submit", this.reset);
  }

  handleKeypress = e => {
    if (this.props.allowFocus) {
      this.input.current.focus();
    }
  };

  handleFormSubmit = e => {
    e.preventDefault();
    const v = this.input.current ? this.input.current.value : "";
    if (v.length) this.props.onSubmit(v);
  };

  reset = () => {
    this.input.current.value = "";
  };

  render() {
    return (
      <form
        style={{ height: "100%", width: "100%" }}
        ref={this.form}
        onSubmit={this.handleFormSubmit}
      >
        {this.props.children({
          inputProps: {
            ref: this.input,
            style: {
              outline: "none",
              height: "100%",
              width: "100%",
              border: "none"
            }
          }
        })}
      </form>
    );
  }
}

ForceFocus.defaultProps = {
  allowFocus: true,
  placeholder: "Scan barcode or RFID",
  children: ({ inputProps }) => (
    <Div display="row.flex-start.center">
      <SearchIcon mr={2} sizeWFS={6} />
      <input {...inputProps} />
    </Div>
  )
};

export default ForceFocus;
