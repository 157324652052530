import { createSelector } from "reselect";
import numeral from "numeral";

import * as R from "ramda";

import { getters } from "./model";

import { getInvalidRequiredCategories } from "PortalHub/CreateOrderRequest/SelectItems/selectors";

export const itemsInCart = createSelector(
  getters.items,
  R.length
);

export const showWidget = createSelector(
  itemsInCart,
  // eslint-disable-next-line no-underscore-dangle
  R.gt(R.__, 0)
);

export const totalValue = createSelector(
  getters.items,
  items =>
    R.compose(
      cost => numeral(cost).format("$0.00"),
      R.reduce((acc, curr) => acc + curr.quantity * curr.price, 0)
    )(items)
);

export const getInvalidMessage = createSelector(
  getInvalidRequiredCategories,
  invalidRequiredCategories => {
    if (invalidRequiredCategories && invalidRequiredCategories.length) {
      return `You must add an item from the required categor${
        invalidRequiredCategories.length === 1 ? "y" : "ies"
      }: ${R.compose(R.map(c => c.name))(invalidRequiredCategories)}`;
    }

    return null;
  }
);

export const groupBy = createSelector(
  getters.items,
  items =>
    R.compose(
      R.groupBy(R.path(["item", "category"])),
      R.map(i => ({
        item: i,
        meta: {
          formattedPrice: numeral(i.price).format("$0.00"),
          total: numeral(i.quantity * i.price).format("$0.00")
        }
      }))
    )(items)
);
