import React from "react";
import * as R from "ramda";
import {
  put,
  takeEvery,
  all,
  fork,
  take,
  select,
  call
} from "redux-saga/effects";
import { actions } from "Portal/List/model";
import { actions as popoverActions } from "ui-kit/PopoverMenu/model";
import { push } from "react-router-redux";
import { registerError } from "redux/modules/errors/actions";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import FormWizardModal from "Forms/WizardModal/View";
import { ROW_ACTIONS } from "Portal/List/constants";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import { showSnackbar } from "redux/modules/snackbar/actions";
import Api from "./api";
import SendFormModal from "components/Event/FormsV2/Modals/FormSendModal";
import { makeFuture } from "utils/General/sagas";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { navigateTo } from "utils/General";
import { showModal, hideModal } from "redux/modules/modal/actions";
import ViewPortalAsModal from "Portal/ViewPortalAsModal/View";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

const getParams = function*() {
  const eventId = yield select(getEventId);
  const credentials = yield select(getCredentials);
  const eventDetails = yield select(getEventDetails);

  return { eventId, credentials, eventDetails };
};

const init = function*() {
  const { eventId, credentials } = yield call(getParams);

  try {
    const { payload } = yield call(Api.getPortals, {
      credentials,
      eventId
    });

    yield put(actions.setData(payload));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred white loading portals"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const showFormWizardModal = function*() {
  yield put(
    showModal({
      content: <FormWizardModal />,
      wrapper: ModalWrapper
    })
  );
};

const showSendFormModal = function*(data) {
  const waitOnSave = makeFuture();
  yield put(
    showModal({
      content: (
        <SendFormModal
          formId={data.id}
          formSlug={data.slug}
          emails={[]}
          handleFormSent={waitOnSave.done}
        />
      )
    })
  );

  return yield call(waitOnSave.onRealized);
};

const handleSendFormModal = function*({ payload: { data } }) {
  yield call(showSendFormModal, data);
  yield put(showSnackbar({ message: "Form sent", action: "OK" }));
};

const handleBasicSettings = function*({ payload: { data } }) {
  const { eventId } = yield call(getParams);

  yield put(
    push(
      `/event/${eventId}/module/${data.base_module_id}/form/${data.id}/settings`
    )
  );
};

const handleEditForm = function*({ payload: { data } }) {
  const { eventId } = yield call(getParams);

  yield put(
    push(`/event/${eventId}/module/${data.base_module_id}/form/${data.id}`)
  );
};

const handleViewPortalAs = function*({ payload: data }) {
  yield put(
    showModal({
      content: (
        <ViewPortalAsModal moduleId={data.module_id} recordTypeId={data.id} />
      ),
      wrapper: ModalWrapper
    })
  );
};

const handleConfirmationMessage = function*({ payload: { data } }) {
  const { eventId } = yield call(getParams);

  yield put(
    push(
      `/event/${eventId}/module/${data.base_module_id}/form/${data.id}/settings/communication`
    )
  );
};

const copyToClipboard = text =>
  new Promise((resolve, reject) => {
    navigator.clipboard.writeText(text).then(
      function() {
        resolve();
      },
      function(err) {
        reject(err);
      }
    );
  });

const handleCopyLink = function*({ payload: { data } }) {
  const { eventDetails } = yield call(getParams);
  /* eslint-disable no-underscore-dangle */
  const text = `${window.__LENND_APP_URL__}/forms/${eventDetails.slug}/${data.slug}/${data.id}`;
  /* eslint-enable no-underscore-dangle */
  yield call(copyToClipboard, text);
  yield put(showSnackbar({ message: "Copied", action: "OK" }));
};

const deleteForm = function*({ payload: { data } }) {
  const { credentials } = yield call(getParams);

  try {
    yield call(Api.delete, { credentials, formId: data.id });

    yield all([
      put(showSnackbar({ message: "Form deleted", action: "OK" })),
      call(init)
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred deleting form"
        }
      ])
    );
  }
};

const copyPortalLink = function*() {
  const { eventDetails } = yield call(getParams);
  /* eslint-disable no-underscore-dangle */
  const text = `${window.__LENND_APP_URL__}/portal-login/${eventDetails.slug}/${eventDetails.uuid}`;
  /* eslint-enable no-underscore-dangle */
  yield call(copyToClipboard, text);
  yield put(showSnackbar({ message: "Copied", action: "OK" }));
};

const goToPortal = function*() {
  const { eventDetails } = yield call(getParams);
  window.open(`/portals/${eventDetails.slug}/${eventDetails.uuid}`);
};

const goToPortalSettings = function*({ payload: data }) {
  const { eventId } = yield call(getParams);
  yield put(
    push(`/event/${eventId}/settings/module/${data.module_id}/types/${data.id}`)
  );
};

const watchTableActions = function*() {
  for (;;) {
    const action = yield take(popoverActions.executeAction.type);

    const delegate = R.prop(action.payload.actionId, {
      [ROW_ACTIONS.DELETE]: deleteForm,
      [ROW_ACTIONS.COPY_LINK]: handleCopyLink,
      [ROW_ACTIONS.SHARE_LINK]: handleSendFormModal,
      [ROW_ACTIONS.BASIC_SETTINGS]: handleBasicSettings,
      [ROW_ACTIONS.CONFIRMATION_MESSAGE]: handleConfirmationMessage
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchEditForm = function*() {
  yield takeEvery(actions.editForm.type, handleEditForm);
};

const watchShowFormWizardModal = function*() {
  yield takeEvery(actions.showFormWizardModal.type, showFormWizardModal);
};

const watchViewPortalAs = function*() {
  yield takeEvery(actions.viewPortalAs.type, handleViewPortalAs);
};

const watchGoToPortal = function*() {
  yield takeEvery(actions.goToPortal.type, goToPortal);
};

const watchCopyPortalLink = function*() {
  yield takeEvery(actions.copyPortalLink.type, copyPortalLink);
};

const watchGoToPortalSettings = function*() {
  yield takeEvery(actions.goToPortalSettings.type, goToPortalSettings);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchShowFormWizardModal),
    fork(watchTableActions),
    fork(watchEditForm),
    fork(watchViewPortalAs),
    fork(watchGoToPortal),
    fork(watchCopyPortalLink),
    fork(watchGoToPortalSettings)
  ]);
};

export default rootSaga;
