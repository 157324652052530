import * as R from "ramda";
import { createSelector } from "reselect";

export const getSelectedTypeItems = createSelector(
  (state, props) => R.propOr([], "selectedVariants", props),
  (state, props) => R.propOr([], "itemTypes", props),
  (selectedVariants, itemTypes) =>
    R.reduce(
      (acc, variant) => {
        const typeIndex = R.findIndex(R.propEq("id", variant.typeId), acc);

        if (typeIndex === -1) {
          return [
            ...acc,
            {
              id: variant.typeId,
              name: R.compose(
                R.propOr("", "name"),
                R.find(R.propEq("id", variant.typeId))
              )(itemTypes),
              items: [variant]
            }
          ];
        }
        return R.update(
          typeIndex,
          {
            ...acc[typeIndex],
            items: [
              ...R.filter(
                R.compose(
                  R.not,
                  R.propEq("id", variant.id)
                ),
                acc[typeIndex].items
              ),
              variant
            ]
          },
          acc
        );
      },
      [],
      selectedVariants
    )
);
