import { createModule } from "redux-mvc";
import handlers from "./handlers";
import accessGridTableModule from "ui-kit/AccessGridTable";
import zonesModule from "Event/Zones";
import addBulkItemsModalModule from "Event/AddBulkItemsModal";

const { actions, getters } = handlers;

export { actions, getters };

const module = createModule(handlers);
module.plugModule(accessGridTableModule);
module.plugModule(zonesModule);

module.plugModule(addBulkItemsModalModule);

export default module;
