import { createHandlers } from "redux-mvc";
import * as R from "ramda";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: false,
  data: {
    count_of_requests_for_approval: 0,
    meal_totals_by_day: [],
    total_meals: 0,
    days: []
  }
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    getCateringDashboardData: R.identity,
    downloadReportDays: R.identity,
    setInitialData: (_, { payload: { data } }) => ({
      data
    })
  }
});

export default handlers;
