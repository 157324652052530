import * as R from "ramda";

import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

export const user = R.pathOr({}, ["user", "user"]);
export const userId = R.compose(R.propOr("", "id"), user);
export const getUserTimezone = R.compose(R.prop("timezone"), user);

export const getUserMutedSound = R.compose(R.prop("mute_sounds"), user);
export const userEvents = R.compose(R.propOr([], "events"), user);

export const getCredentials = state => state.user.user.credentials;
export const isSessionCheckComplete = state =>
  state.user.user.sessionCheckComplete;

export const isFormManager = state => {
  const canDo = canEventUserDo(state);
  return canDo(`${STANDARD_MODULE_IDS.settings.id}_manage_all_module_data`);
};

export const getShowAdminFields = (state, props) =>
  R.has("showAdminFields", props)
    ? R.prop("showAdminFields", props)
    : isFormManager(state, props);

export const getReadOnly = (state, props) =>
  R.has("readOnly", props)
    ? R.prop("readOnly", props)
    : R.not(isFormManager(state, props));

export const getUserPhoneNumber = R.compose(R.prop("phone"), user);

export const hasUserEnteredPhoneNumber = R.compose(Boolean, getUserPhoneNumber);
