import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Label from "components/Global-2016/Forms/Label";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const formats = [
  { format: "0,0%", label: "1,000%" },
  { format: "0,0.0%", label: "1,000.0%" },
  { format: "0,0.00%", label: "1,000.00%" }
];

@CSSModules(css)
class PercentSettings extends Component {
  @autobind
  onChange(e) {
    this.props.onChange({ percentFormat: e.target.value });
  }

  render() {
    const { percentFormat } = this.props.value;
    return (
      <div>
        <div className="input-wrapper">
          <Label>Format</Label>
          <select
            key="type"
            styleName="select"
            onChange={this.onChange}
            value={percentFormat}
          >
            {formats.map(({ format, label }) => (
              <option value={format} key={format}>
                {label}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
}

PercentSettings.propTypes = {
  value: PropTypes.shape({
    percentFormat: PropTypes.string.isRequired
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default PercentSettings;
