const FULFILLMENT_OPTIONS = [
  {
    id: "pickup",
    name: "Pickup/Will Call"
  },
  {
    id: "mail",
    name: "Mail"
  }
];

export { FULFILLMENT_OPTIONS };
