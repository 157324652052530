/* eslint-disable react/prop-types */

import React from "react";
import * as R from "ramda";
import {
  Div,
  Text2,
  Text3,
  Text5,
  SimpleTable,
  BigFilledButton,
  DownloadIcon,
  CancelIcon,
  CheckCircleIcon
} from "components/Base";
import ClickToPrint from "../ClickToPrint";
import Tooltip from "components/Global/Tooltip";
import { addS } from "utils/General";

const StatusIcons = {
  pending: null,
  rejected: <CancelIcon title="Rejected" sizeWFS={3} color="neutral5" mr={1} />,
  approved: (
    <CheckCircleIcon title="Approved" sizeWFS={3} color="altA5" mr={1} />
  )
};

const makeStatusIcon = status => (
  <Div display="row.flex-start.center">
    {StatusIcons[status]}
    <Text2 capitalize>{status}</Text2>
  </Div>
);

const SummaryTable = SimpleTable({
  columnProps: [
    [6 / 12, "left", { px: 2 }],
    [2 / 12, "right", { px: 2 }],
    [2 / 12, "center", { px: 2 }],
    [2 / 12, "center", { px: 2 }]
  ],
  headerCellComps: [
    "Item",
    "Requested",
    () => <CancelIcon title="Rejected" sizeWFS={2} color="neutral5" />,
    () => <CheckCircleIcon title="Approved" sizeWFS={2} color="altA5" />
  ],
  rowCellComps: ["item", "requested", "rejected", "approved"]
});

const makeRequestsTable = ({ fields }) =>
  SimpleTable({
    columnProps: fields.map(v => [v.span, v.align || "left", { px: 2 }]),
    headerCellComps: fields.map(f => f.name),
    rowCellComps: [
      ({ orderType }) => <Text2 capitalize>{orderType}</Text2>,
      ({ type }) => <Text2 bold>{type}</Text2>,
      ({ quantity }) => <Text2>{quantity}</Text2>,
      ({ details }) => (
        <>
          {details && details.length ? (
            <Tooltip
              placement="bottom"
              tooltip={
                <Div>
                  {R.map(d => {
                    return (
                      <Div key={d.id}>
                        <b>{d.name}</b> - {d.value}
                      </Div>
                    );
                  })(details)}
                </Div>
              }
            >
              <Text2>
                {details.length} Detail{addS(details.length)}
              </Text2>
            </Tooltip>
          ) : (
            "-"
          )}
        </>
      ),
      ({ price }) => <Text2>{price || "-"}</Text2>,
      ({ status }) => makeStatusIcon(status)
    ]
  });

const RequestsTable = ({ fields = [], values = [] }) => {
  const Table = makeRequestsTable({ fields });
  return (
    <Div>
      <Table
        rowsData={values} // [{ [f.id]: '':String }]
      />
    </Div>
  );
};

const Summary = ({ title, values = [] }) => (
  <Div bra={1} maxWidth={500} bc="neutral2" ba={1} bl={3} p={3} pb={1} mb={5}>
    <Text3 bold pl={2}>
      {title}
    </Text3>
    <SummaryTable rowsData={values} />
  </Div>
);

const PortalReportView = ({
  onClickToPrint,
  title,
  summaryValues,
  fields,
  values,
  showTitle = true
}) => (
  <Div brb={2} brr={2} p={4} bg="white" mb={4}>
    <Div display="row.space-between.center" pb={3}>
      {showTitle ? <Text5 bold>{title}</Text5> : null}
      <BigFilledButton
        className="no-print"
        onClick={onClickToPrint}
        pill
        LeftIcon={DownloadIcon}
      >
        Print
      </BigFilledButton>
    </Div>
    {/*
    {modules.map(({ title, summaryValues, requestValues }) => (
      <Div key={title} pb={6}>
        <Summary title={title} values={summaryValues} />
        <RequestsTable
          fields={requestValues.fields}
          values={requestValues.values}
        />
      </Div>
    ))}
  */}
    <Div key={title} pb={6}>
      <Summary title="Summary By Pass" values={summaryValues} />
      <Text3 bold>Details</Text3>
      <RequestsTable fields={fields} values={values} />
    </Div>
  </Div>
);
const AdminReportView = ({
  onClickToPrint,
  title,
  summaryValues,
  fields,
  values,
  showTitle = true
}) => (
  <Div bg="white" mb={4}>
    <Div px={2}>
      <Div display="row.space-between.center" pb={3}>
        {showTitle ? <Text5 bold>{title}</Text5> : null}
        <BigFilledButton
          className="no-print"
          onClick={onClickToPrint}
          pill
          LeftIcon={DownloadIcon}
        >
          Print
        </BigFilledButton>
      </Div>
      <Summary title="Summary By Pass" values={summaryValues} />
    </Div>
    <Text3 bold>Details</Text3>
    <RequestsTable fields={fields} values={values} />
  </Div>
);

export const AdminView = ClickToPrint(AdminReportView);
export const PortalView = ClickToPrint(PortalReportView);
