import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { get } from "lodash";
import getValue from "utils/value-types/get-value";
import toString from "utils/value-types/to-string";
import File from "components/Global/Table3/CellFormatters/File/File";
import FileViewer from "components/Global/Modals/FileViewer";
import FileViewerWrapper from "components/Global/Modals/FileViewer/Wrapper";
import FileUploadEditor from "./FileUpload";
import Feed from "components/Global/Module/Feed";
import FileCard from "../Shared/FileCard";
import Title from "../Shared/Title";
import Well from "../Shared/Well";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import {
  DOCUMENT_REQUEST_TYPES,
  DOCUMENT_REQUEST_REVISIONS
} from "utils/standard-module-field-ids";

const { FILES, REVISION_STATUS } = DOCUMENT_REQUEST_REVISIONS;

const { SAMPLE_DOCUMENTS, CRITERIA } = DOCUMENT_REQUEST_TYPES;

@CSSModules(css)
class PortalView extends Component {
  state = { files: [] };

  showFileViewer = (file, files) => {
    this.props.showModal({
      content: (
        <FileViewer
          onFileDelete={this.deleteFile}
          start={files.indexOf(file)}
          files={files}
          isReadOnly
        />
      ),
      wrapper: FileViewerWrapper
    });
  };

  deleteFile = () => {
    this.props.markAsModified();
    return "";
  };

  handleFileUpload = ({ value: { files } }) => {
    this.props.markAsModified();
    this.setState({ files });
  };

  handleClearFiles = () => {
    this.props.markAsModified();
    this.setState({ files: [] });
  };

  createRevision = () => {
    this.props.markAsModified();

    this.props
      .addRecord({
        moduleId: STANDARD_MODULE_IDS.documentRequestRevisions.id,
        record: {
          requestRecordId: this.props.record.id,
          [FILES]: {
            type: "file",
            value: {
              files: this.state.files
            }
          },
          [REVISION_STATUS]: {
            type: "dropdown",
            value: { records: [{ value: "Pending" }] }
          }
        },
        options: {
          eventId: this.props.eventDetails.id
        }
      })
      .then(() => {
        this.handleClearFiles();
        this.props.fetchRecord();
      });
  };

  renderRevision = (revision, showStatus, isCurrent) => {
    const { onRevisionMenuChange } = this.props;
    const files = getValue(get(revision.values, [FILES]), "file");
    const status = toString(
      getValue(get(revision.values, [REVISION_STATUS]), "dropdown"),
      "dropdown"
    );
    return (
      <FileCard
        files={files}
        showFileViewer={this.showFileViewer}
        showStatus={showStatus}
        status={status}
        isCurrent={isCurrent}
        onRevisionMenuChange={onRevisionMenuChange}
        menuOptions={[]}
      />
    );
  };

  render() {
    const { record, eventDetails, requestType, revisions, status } = this.props;

    const criteria = getValue(get(requestType.values, [CRITERIA]), "text");
    const sampleDocuments = getValue(
      get(requestType.values, [SAMPLE_DOCUMENTS]),
      "file"
    );

    return (
      <div styleName="container">
        <div styleName="body">
          <div styleName="infoSection">
            <div styleName="section">
              {criteria ? <div styleName="criteria">{criteria}</div> : null}
            </div>
            <div styleName="section">
              {sampleDocuments && sampleDocuments.length ? (
                <span>
                  <Title>
                    Sample Document
                    {sampleDocuments.length > 1 ? "s" : ""}
                  </Title>
                  <div styleName="list">
                    {sampleDocuments.map(file => (
                      <File
                        key={file.id}
                        isClickable
                        onClick={() =>
                          this.showFileViewer(file, sampleDocuments)
                        }
                        {...file}
                      />
                    ))}
                  </div>
                </span>
              ) : null}
            </div>
          </div>

          <Well>
            {status !== "Approved" ? (
              <span>
                <div styleName="heading">Files</div>
                <FileUploadEditor
                  allowDownload
                  value={{ value: { files: this.state.files } }}
                  placeholder="Add files to create a new revision"
                  onChange={this.handleFileUpload}
                  maxSize={26214400} // 25MB
                />
                {this.state.files.length ? (
                  <div styleName="buttonGroup">
                    <div styleName="buttonSave" onClick={this.createRevision}>
                      Submit
                    </div>
                    <div styleName="button" onClick={this.handleClearFiles}>
                      Cancel
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </span>
            ) : null}
            {status === "Not Submitted" || !revisions.length ? null : (
              <div styleName="section">
                <Title>Version History</Title>
                {revisions.map((r, idx) =>
                  this.renderRevision(r, true, idx === 0)
                )}
              </div>
            )}
          </Well>
        </div>
        <Feed
          eventId={eventDetails.id}
          moduleId={STANDARD_MODULE_IDS.documentRequests.id}
          recordId={record.id}
          whitelist={["messages"]}
        />
      </div>
    );
  }
}

PortalView.propTypes = {
  showModal: PropTypes.func.isRequired,
  revisions: PropTypes.array.isRequired,
  latestRevision: PropTypes.object,
  record: PropTypes.object.isRequired,
  requestType: PropTypes.object.isRequired,
  eventDetails: PropTypes.object.isRequired,
  fetchRecord: PropTypes.func.isRequired,
  addRecord: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  onRevisionMenuChange: PropTypes.func.isRequired
};

export default PortalView;
