import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getters, actions } from "EventLight/Integrations/FuzionConnectModal";
import { Div, BigFilledButton, MediumInsetInput } from "components/Base";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";

const decorate = connect(
  (state, props) => ({
    fuzionEventId: getters.fuzionEventId(state, props),
    saving: getters.saving(state, props),
    error: getters.error(state, props)
  }),
  {
    setInitialData: actions.setInitialData,
    setFuzionEventId: actions.setFuzionEventId,
    createConnection: actions.createConnection
  }
);

const ConnectIntegrationModal = ({
  saving,
  setInitialData,
  iniData = {},
  setFuzionEventId,
  fuzionEventId,
  hideModal,
  error,
  createConnection
}) => {
  useEffect(() => {
    setInitialData(iniData);
  }, []);

  return (
    <MiniModalWrapper
      showModal
      hideModal={hideModal}
      title="Connect Fuzion Integration"
      style={{ width: 550, height: 370 }}
      isPortal={false}
      bg="white"
      stopPropagation
    >
      <Div mb={3} px={8} py={5}>
        <Div mb={5}>
          <strong>Fuzion</strong> requires some additional information in order
          to connect with LENND. Fill out the information below and then
          connect.
        </Div>
        <Div>
          <Div mb={2}>
            <Div mb={1} fw={3}>
              Fuzion Event ID
            </Div>
            <MediumInsetInput
              continuous
              onChange={setFuzionEventId}
              value={fuzionEventId}
              width={320}
            />
          </Div>
        </Div>

        {error ? (
          <Div p={4} bg="danger3" bra={1} mb={3}>
            Oops, we ran into an issue - {error}
          </Div>
        ) : null}

        <Div display="row.space-between.center" bt={1} bc="gray2" pt={3} mt={5}>
          <BigFilledButton
            bg="altB5"
            color="white"
            onClick={createConnection}
            disabled={saving}
          >
            {saving ? "Connecting..." : "Connect"}
          </BigFilledButton>
        </Div>
      </Div>
    </MiniModalWrapper>
  );
};

export default decorate(ConnectIntegrationModal);
