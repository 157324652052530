import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { get } from "lodash";

import SendDocumentRequest from "./SendDocumentRequest";

import { showModal, hideModal } from "redux/modules/modal/actions";
import { addRecord, getRecords } from "redux/modules/modules/records/actions";

import * as ModuleRecordsSelectors from "redux/modules/modules/records/selectors";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { eventDetails } from "redux/modules/event/selectors";
import { DOCUMENT_REQUEST_TYPES } from "utils/standard-module-field-ids";

const { NAME, ASSIGNMENT_SCOPE } = DOCUMENT_REQUEST_TYPES;

function mapStateToProps(state, props) {
  return {
    eventDetails: eventDetails(state),
    documentRequestTypes: ModuleRecordsSelectors.records(
      state,
      STANDARD_MODULE_IDS.documentRequestTypes.id
    )
      .filter(
        type => get(type, ["values", ASSIGNMENT_SCOPE, "value"]) === props.scope
      )
      .map(type => ({
        id: type.id,
        title: get(type, ["values", NAME, "value"], "Untitled"),
        description: null
      }))
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addRecord,
      showModal,
      hideModal,
      getRecords
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendDocumentRequest);
