import Helpers from "utils/Global/Helpers";
import getQuery from "redux/modules/modules/utils/get-query";

/* eslint no-underscore-dangle: "off" */

export default {
  getModule: ({ credentials, moduleId, orgId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules/${moduleId}?orgId=${orgId}`,
        credentials,
        success,
        error
      });
    }),
  getRecordType: ({ credentials, moduleId, orgId, recordTypeId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules/${moduleId}/record-types/${recordTypeId}?orgId=${orgId}`,
        credentials,
        success,
        error
      });
    }),
  getRecordTypes: ({ credentials, moduleId, orgId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules/${moduleId}/record-types?orgId=${orgId}`,
        credentials,
        success,
        error
      });
    }),
  getFields: ({ credentials, moduleId, options }) =>
    new Promise((success, error) => {
      const query = getQuery(options);

      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/modules/${moduleId}/fields${query || ""}`,
        credentials,
        success,
        error
      });
    }),
  search: (credentials, userId, orgId, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules-v2/organization/${orgId}/dashboard/${data.moduleId}`,
        qs: { orgId, ...data },
        credentials,
        success,
        error
      });
    }),
  export: ({ credentials, orgId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules-v2/organization/${orgId}/dashboard/${data.moduleId}/export`,
        qs: { ...data },
        credentials,
        success,
        error
      });
    }),
  getViews: ({ credentials, orgId, recordTypeId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules-v2/dashboard/${data.moduleId}/views`,
        qs: { orgId, recordTypeId },
        data,
        credentials,
        success,
        error
      });
    }),
  addView: ({ credentials, orgId, recordTypeId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/modules-v2/dashboard/${data.moduleId}/views`,
        qs: { orgId, recordTypeId },
        data,
        credentials,
        success,
        error
      });
    }),
  updateView: ({ credentials, orgId, recordTypeId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/modules-v2/dashboard/${data.moduleId}/view`,
        qs: { orgId, recordTypeId },
        data,
        credentials,
        success,
        error
      });
    }),
  deleteView: ({ credentials, orgId, recordTypeId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/modules-v2/dashboard/${data.moduleId}/view`,
        qs: { orgId, recordTypeId },
        data,
        credentials,
        success,
        error
      });
    }),
  selectView: ({ credentials, orgId, recordTypeId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/modules-v2/dashboard/${data.moduleId}/active-view`,
        qs: { orgId, recordTypeId },
        data,
        credentials,
        success,
        error
      });
    }),
  getActiveView: ({ credentials, orgId, recordTypeId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules-v2/dashboard/${data.moduleId}/active-view`,
        qs: { orgId, recordTypeId },
        data,
        credentials,
        success,
        error
      });
    }),
  getStatus: (credentials, { jobId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/jobs/progress/${jobId}`,
        credentials,
        success,
        error
      });
    })
};
