import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Sidebar from "./Sidebar";
import { withRouter } from "react-router";
import * as flags from "utils/feature-flags";

import { addModule } from "redux/modules/modules/module/actions";
import { getEvent } from "redux/modules/event/actions";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";
import { selectFeatureFlag } from "@flopflip/react-redux";

function mapStateToProps(state) {
  return {
    canDo: canEventUserDo(state),
    isLenndAdmin: selectFeatureFlag(flags.IS_LENND_ADMIN.NAME)(state),
    canViewInventory: selectFeatureFlag(flags.CAN_VIEW_INVENTORY.NAME)(state),
    canViewSchedules: selectFeatureFlag(flags.CAN_VIEW_PORTAL_SCHEDULE.NAME)(
      state
    ),
    canViewApprovalWorkflows: selectFeatureFlag(
      flags.CAN_VIEW_APPROVAL_WORKFLOWS.NAME
    )(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addModule,
      getEvent
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Sidebar)
);
