import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Card from "components/Global/CRM/Card";
import * as R from "ramda";
import EmptyState, {
  ButtonWrapper
} from "components/Global/CRM/Card/EmptyState";
import Header from "components/Global/CRM/Card/Header";
import IconTitle from "components/Global/CRM/Card/IconTitle";
import {
  OutlineButton,
  HelpButton,
  SolidButton
} from "components/Global/CRM/Card/Buttons";
import HelpArticleUrls from "utils/help-article-urls";
import Actions from "components/Global/CRM/Card/Actions";
import Title from "components/Global/CRM/Card/Title";
import Body from "components/Global/CRM/Card/Body";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import Loading from "ui-kit/Loading";
import Form from "./Form";

import { connect } from "react-redux";
import { getters, actions } from "../model";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import SendSummaryConfirmations from "components/Event/FormsV2/Overview/SendSummaryConfirmations";
import AssignFormsModal from "components/Global/CRM/Modals/Assign/AssignForms/AssignAccountForms";
import EditAccountFormAssignment from "components/Event/Module/Form/Modals/EditAccountFormAssignment";
import OrderModal from "Orders/OrderModal/View";
import {
  eventId as getEventId,
  eventDetails as getEventDetails
} from "redux/modules/event/selectors";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { userId as getUserId } from "redux/modules/user/selectors";
import { addRecord } from "redux/modules/modules/records/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import {
  deleteSubmission,
  createSubmission,
  setSubmissionLocked
} from "redux/modules/formsV2/submission/actions";
import {
  addAccountForm,
  deleteAccountForm
} from "redux/modules/accounts/forms/actions";
import {
  updateAccountFormAssignment,
  removeAccountFormAssignment
} from "redux/modules/formsV2/form/assignments/account/actions";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

const COLOR = STANDARD_MODULE_IDS.forms.color;
const ICON = STANDARD_MODULE_IDS.forms.icon;

const decorate = connect(
  state => ({
    loading: getters.loading(state),
    forms: getters.forms(state),
    recordId: getters.recordId(state),
    eventId: getEventId(state),
    userId: getUserId(state),
    eventDetails: getEventDetails(state),
    canDo: canEventUserDo(state)
  }),
  {
    refreshData: actions.refreshData,
    showModal,
    hideModal,
    addRecord,
    showSnackbar,
    deleteSubmission,
    createSubmission,
    setSubmissionLocked,
    addAccountForm,
    deleteAccountForm,
    updateAccountFormAssignment,
    removeAccountFormAssignment
  }
);

@CSSModules(css)
class Forms extends Component {
  fetchAccount = this.props.refreshData;

  showSubmissionSummaryModal = (isFormManager, { id }) => {
    const submissionSummaryOptions = isFormManager
      ? {
          submissionId: id,
          hideModal: () => {
            this.fetchAccount();
            this.props.hideModal();
          }
        }
      : {
          submissionId: id,
          readOnly: true,
          hideModal: () => {
            this.fetchAccount();
            this.props.hideModal();
          }
        };

    const Modal = <OrderModal {...submissionSummaryOptions} />;
    this.props.showModal({ content: Modal, wrapper: ModalWrapper });
  };

  setSubmissionLocked = (isLocked, submission) =>
    this.props
      .setSubmissionLocked(isLocked, submission)
      .then(() => this.props.showSnackbar({ message: "Submission updated" }))
      .then(this.fetchAccount);

  showSendSubmissionConfirmationModal = (submission, form) => {
    const modal = (
      <SendSummaryConfirmations
        form={form}
        eventDetails={this.props.eventDetails}
        hasChanges={false}
        submission={submission}
        fields={form.fields || []}
        params={{
          eventId: this.props.eventId,
          recordId: this.props.recordId
        }}
        handleApprovalSent={this.handleApprovalSent}
      />
    );

    this.props.showModal({ content: modal, wrapper: ModalWrapper });
  };

  handleApprovalSent = count => {
    this.props.showSnackbar({
      message: `${count} confirmation ${count > 1 ? "emails" : "email"} sent`,
      action: "OK"
    });
  };

  handleDeleteSubmission = (formId, submissionId) =>
    this.props.deleteSubmission({ submissionId }).then(this.fetchAccount);

  handleAddSubmission = async form => {
    const { eventDetails } = this.props;
    const accountId = this.props.recordId;
    let submissionResult;

    // @NOTE: If we're filling out an intake form, we want to use
    // this account or contact's record ID instead of creating a new record
    let submissionModuleRecord = {};

    if (form.type === "intake") {
      // @NOTE: In the future, we'll want to add a condition here to pass in
      // either the account's or contact's ID based on portal type. Currently
      // we assume that intake forms are only used on account portals.
      submissionModuleRecord = {
        id: accountId
      };
    } else {
      submissionModuleRecord = await this.props.addRecord({
        moduleId: form.base_module_id,
        record: {
          isDraft: true
        },
        options: {
          eventId: form.event_id
        }
      });
    }

    submissionResult = await this.props.createSubmission({
      formId: form.id,
      eventId: eventDetails.id,
      userId: this.props.userId,
      accountId,
      submissionRecordId: submissionModuleRecord.id
    });

    window.location = `/submissions/${eventDetails.slug}/${form.slug}/${submissionResult.id}?redirectToAccount=${accountId}`;
  };

  assignForms = formIds => {
    const { eventId, recordId } = this.props;
    const existingForms = this.props.forms.map(({ id }) => id);

    const formsToAdd = formIds
      .filter(id => !existingForms.includes(id))
      .map(formId =>
        this.props.addAccountForm({ eventId, accountId: recordId, formId })
      );

    const formsToRemove = this.props.forms
      .filter(form => !formIds.includes(form.id))
      .map(({ account_form_id }) =>
        this.props.deleteAccountForm({
          accountFormId: account_form_id,
          accountId: recordId
        })
      );

    Promise.all([...formsToRemove, ...formsToAdd])
      .then(this.fetchAccount)
      .then(() => {
        if (formsToAdd.length || formsToRemove.length) {
          this.props.showSnackbar({
            message: "Updated forms assigned to this group"
          });
        }
      });
  };

  showAssignFormsModal = () => {
    this.props.showModal({
      content: (
        <AssignFormsModal
          moduleId={STANDARD_MODULE_IDS.accounts.id}
          recordNameSingular="Group"
          recordNamePlural="Groups"
          recordIds={[this.props.recordId]}
          onDone={this.props.refreshData}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  onClickEditCloseDate = form => {
    this.props.showModal({
      content: (
        <EditAccountFormAssignment
          eventId={this.props.eventId}
          accountName={this.props.accountName}
          closeDate={form.close_date}
          customCloseDate={form.custom_close_date}
          onSave={data =>
            this.onUpdateGroupAssignment({
              formId: form.id,
              ...data
            })
          }
        />
      ),
      wrapper: ModalWrapper
    });
  };

  onRemoveAssignmentFromGroup = async formId => {
    await this.props.removeAccountFormAssignment({
      formId,
      accountIds: [this.props.recordId]
    });

    this.props.showSnackbar({ message: "Form Unassigned", action: "OK" });

    return this.props.refreshData();
  };

  onUpdateGroupAssignment = async ({ formId, closeDate }) => {
    await this.props.updateAccountFormAssignment({
      eventId: this.props.eventId,
      formId,
      accountId: this.props.recordId,
      closeDate
    });

    this.props.showSnackbar({ message: "Assignment Updated", action: "OK" });

    return this.props.refreshData();
  };

  isEventAdmin = this.props.canDo(
    `${STANDARD_MODULE_IDS.settings.id}_manage_all_module_data`
  );

  render() {
    const body =
      this.props.forms && this.props.forms.length ? (
        R.compose(
          R.map(f => {
            const isFormManager = this.isEventAdmin;
            return (
              <Form
                key={f.id}
                addSubmission={this.handleAddSubmission}
                deleteSubmission={this.handleDeleteSubmission}
                eventDetails={this.props.eventDetails}
                form={f}
                showSubmissionSummary={this.showSubmissionSummaryModal.bind(
                  null,
                  isFormManager
                )}
                setSubmissionLocked={this.setSubmissionLocked}
                sendConfirmation={this.showSendSubmissionConfirmationModal}
                isFormManager={isFormManager}
                isAssignedToType={!f.account_form_id}
                onClickEditCloseDate={() => this.onClickEditCloseDate(f)}
                onUnassign={
                  f.account_form_id
                    ? () => this.onRemoveAssignmentFromGroup(f.id)
                    : undefined
                }
              />
            );
          }),
          R.sortBy(
            R.compose(
              name => (name ? R.toLower(name) : name),
              R.propOr("", "name")
            )
          )
        )(this.props.forms)
      ) : (
        <EmptyState
          icon={
            <img
              src="//d2dks4tzxs6xee.cloudfront.net/img/inbox_icon.png"
              alt=""
            />
          }
        >
          <div>
            Forms are ways to collect simple or complex information from a group
            or individual.
          </div>
          <ButtonWrapper>
            <SolidButton onClick={this.showAssignFormsModal}>
              <i className="material-icons">add</i>
              Assign Forms
            </SolidButton>
          </ButtonWrapper>
        </EmptyState>
      );

    return (
      <Card color={COLOR}>
        <Header>
          <Title>
            <IconTitle title="Forms" color={COLOR}>
              <i className={`material-icons ${css.icon}`}>{ICON}</i>
            </IconTitle>

            <Actions>
              <OutlineButton onClick={this.showAssignFormsModal}>
                Assign Forms
              </OutlineButton>
              <HelpButton url={HelpArticleUrls.MANAGE_ACCOUNT_FORMS}>
                Learn about managing forms
              </HelpButton>
            </Actions>
          </Title>
        </Header>
        <Body>{this.props.loading ? <Loading /> : body}</Body>
      </Card>
    );
  }
}

export default decorate(Forms);
