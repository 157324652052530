import React, { Component } from "react";
import PropTypes from "prop-types";
import Loading from "components/Global/Loading";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class OrganizationHandler extends Component {
  componentDidMount() {
    this.props.fetchOrganization(this.props.params.orgId);
  }

  render() {
    if (!("id" in this.props.orgDetails)) {
      return (
        <div styleName="loadingWrapper">
          <Loading content="Loading organization..." />
        </div>
      );
    }

    if (this.props.orgDetails.is_light) {
      window.location = `/org-light/${this.props.orgDetails.id}/dashboard`;
      return null;
    }

    return <>{this.props.children}</>;
  }
}

OrganizationHandler.propTypes = {
  fetchOrganization: PropTypes.func.isRequired,
  params: PropTypes.shape({
    orgId: PropTypes.string
  }).isRequired
};

export default OrganizationHandler;
