import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class Group extends Component {
  render() {
    const { id, name, color, onClick } = this.props;
    return (
      <div
        styleName={onClick ? "containerWithHover" : "container"}
        onClick={onClick}
      >
        <div
          styleName="colorBox"
          style={{
            backgroundColor: color,
            border:
              color === "#ffffff" ? "1px solid #eee" : `1px solid ${color}`
          }}
        />
        <div styleName="name">{name}</div>
        <div styleName="separator" />
        {/*
        <div styleName="icons">
          <div styleName="icon">x</div>
        </div>
        */}
      </div>
    );
  }
}

Group.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default Group;
