import React from "react";
import { path } from "ramda";
import { connect } from "react-redux";

import {
  DownFilledIcon,
  MenuItem,
  RightIcon,
  CheckCircleIcon,
  CancelIcon,
  EditIcon,
  OpenInNewIcon,
  MediumOutlineButton,
  SendIcon,
  PopMenu,
  PopMenuCard
} from "components/Base";

import { withProps } from "utils/General";
import { actions } from "ApproversLabel";

const checkCircleIconViolet = withProps({ color: "primary2" })(CheckCircleIcon);
const checkCircleIconGreen = withProps({ color: "altA4" })(CheckCircleIcon);
const cancelIconRed = withProps({ color: "danger4" })(CancelIcon);

const BulkMenuItem = withProps({
  py: 1,
  bb: 1,
  bc: "neutral3",
  width: 1
})(MenuItem);

const buttons = {
  reviewAllPasses: ({ handlers, meta }) => {
    if (
      !meta.allCredentialLineItemIds ||
      !meta.allCredentialLineItemIds.length
    ) {
      return null;
    }

    return (
      <PopMenu
        width={1}
        Label={({ onClick }) => (
          <BulkMenuItem
            LeftIcon={checkCircleIconViolet}
            RightIcon={RightIcon}
            onClick={onClick}
          >
            Review Passes
          </BulkMenuItem>
        )}
      >
        {({ closeMenu }) => (
          <PopMenuCard position="right" align="top">
            <BulkMenuItem
              LeftIcon={checkCircleIconGreen}
              onClick={() => {
                handlers.onApprove({
                  type: "lineItem",
                  recordIds: meta.allCredentialLineItemIds
                });
                handlers.closeMenu();
                closeMenu();
              }}
            >
              Approve {meta.allCredentialLineItemIds.length} Remaining
            </BulkMenuItem>
            <BulkMenuItem
              LeftIcon={cancelIconRed}
              onClick={() => {
                handlers.onDeny({
                  type: "lineItem",
                  recordIds: meta.allCredentialLineItemIds
                });
                handlers.closeMenu();
                closeMenu();
              }}
            >
              Deny {meta.allCredentialLineItemIds.length} Remaining
            </BulkMenuItem>
          </PopMenuCard>
        )}
      </PopMenu>
    );
  },
  reviewAllMeals: ({ handlers, meta }) => {
    if (!meta.allMealLineItemIds || !meta.allMealLineItemIds.length) {
      return null;
    }

    return (
      <PopMenu
        width={1}
        Label={({ onClick }) => (
          <BulkMenuItem
            LeftIcon={checkCircleIconViolet}
            RightIcon={RightIcon}
            onClick={onClick}
          >
            Review Meals
          </BulkMenuItem>
        )}
      >
        {({ closeMenu }) => (
          <PopMenuCard position="right" align="top">
            <BulkMenuItem
              LeftIcon={checkCircleIconGreen}
              onClick={() => {
                handlers.onApprove({
                  type: "lineItem",
                  recordIds: meta.allMealLineItemIds
                });
                handlers.closeMenu();
                closeMenu();
              }}
            >
              Approve {meta.allMealLineItemIds.length} Remaining
            </BulkMenuItem>
            <BulkMenuItem
              LeftIcon={cancelIconRed}
              onClick={() => {
                handlers.onDeny({
                  type: "lineItem",
                  recordIds: meta.allMealLineItemIds
                });
                handlers.closeMenu();
                closeMenu();
              }}
            >
              Deny {meta.allMealLineItemIds.length} Remaining
            </BulkMenuItem>
          </PopMenuCard>
        )}
      </PopMenu>
    );
  },
  bulkEditOrderItems: ({ handlers, meta }) => (
    <BulkMenuItem
      LeftIcon={EditIcon}
      onClick={() => {
        handlers.bulkEdit({ orderId: meta.orderId });
        handlers.closeMenu();
      }}
    >
      Bulk Edit All Items
    </BulkMenuItem>
  ),
  bulkEditAllItems: ({ handlers, meta }) => (
    <BulkMenuItem
      LeftIcon={EditIcon}
      onClick={() => {
        handlers.bulkEdit({ lineItemId: meta.allLineItemIds });
        handlers.closeMenu();
      }}
    >
      Bulk Edit All Items
    </BulkMenuItem>
  ),
  viewGroupProfile: ({ handlers }) => (
    <BulkMenuItem
      LeftIcon={OpenInNewIcon}
      onClick={() => {
        handlers.viewProfile();
        handlers.closeMenu();
      }}
    >
      Open Group Profile
    </BulkMenuItem>
  ),
  openOrderDetails: ({ handlers }) => (
    <BulkMenuItem
      LeftIcon={OpenInNewIcon}
      onClick={e => {
        handlers.openOrderDetails(e);
        handlers.closeMenu();
      }}
    >
      Open Order Details
    </BulkMenuItem>
  ),
  viewAttendeeProfile: ({ handlers }) => (
    <BulkMenuItem
      LeftIcon={OpenInNewIcon}
      onClick={() => {
        handlers.viewProfile();
        handlers.closeMenu();
      }}
    >
      View Attendee Profile
    </BulkMenuItem>
  ),
  sendEmail: ({ handlers }) => (
    <BulkMenuItem
      LeftIcon={SendIcon}
      onClick={() => {
        handlers.sendEmail();
        handlers.closeMenu();
      }}
    >
      Send Email
    </BulkMenuItem>
  ),
  sendConfirmation: ({ handlers, meta }) => (
    <BulkMenuItem
      LeftIcon={SendIcon}
      onClick={() => {
        handlers.sendConfirmation({ orderId: meta.orderId });
        handlers.closeMenu();
      }}
    >
      Send Confirmation
    </BulkMenuItem>
  )
};

const buttonsByCard = {
  byPerson: {
    issuance: ["bulkEditAllItems", "sendEmail"],
    review: [
      "reviewAllPasses",
      "reviewAllMeals",
      "bulkEditAllItems",
      "sendEmail"
    ]
  },
  byOrder: {
    issuance: ["bulkEditOrderItems", "sendConfirmation"],
    review: [
      "reviewAllPasses",
      "reviewAllMeals",
      "bulkEditOrderItems",
      "sendConfirmation"
    ]
  },
  byAccount: {
    issuance: ["viewGroupProfile", "sendEmail"],
    review: [
      "reviewAllPasses",
      "reviewAllMeals",
      "viewGroupProfile",
      "sendEmail"
    ]
  },
  byCategory: {
    issuance: ["bulkEditAllItems"],
    review: ["reviewAllPasses", "reviewAllMeals", "bulkEditAllItems"]
  }
};

const getButtonsByCard = ({ cardType, mode }) =>
  path([cardType, mode], buttonsByCard) || buttonsByCard.byPerson.issuance;

const decorate = connect(
  null,
  {
    onApprove: actions.onApprove,
    onDeny: actions.onDeny
  }
);

const BulkActions = ({
  cardType = "byPerson",
  mode = "issuance",
  handlers = {},
  meta = {},
  onApprove,
  onDeny
}) => (
  <PopMenu
    Label={({ onClick }) => (
      <MediumOutlineButton onClick={onClick} RightIcon={DownFilledIcon} ml={2}>
        Bulk Actions
      </MediumOutlineButton>
    )}
  >
    {({ closeMenu }) => (
      <PopMenuCard position="bottom" align="left">
        {getButtonsByCard({ cardType, mode }).map(buttonName => {
          const Elm = buttons[buttonName];

          return (
            <Elm
              key={buttonName}
              handlers={{ ...handlers, onApprove, onDeny, closeMenu }}
              meta={meta}
            />
          );
        })}
      </PopMenuCard>
    )}
  </PopMenu>
);

export default decorate(BulkActions);
