import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Module from "./Documents";

import { addReference } from "redux/modules/entityReferences/actions";
import { addValue, addValues } from "redux/modules/modules/values/actions";
import { deselectAllRows } from "redux/modules/modules/records/settings/actions";
import {
  deleteRecord,
  updateType
} from "redux/modules/modules/records/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";

import { orgDetails } from "redux/modules/organization/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import { fields } from "redux/modules/modules/records/selectors";
import { moduleDetails } from "redux/modules/modules/module/selectors";
import { recordTypes } from "redux/modules/modules/recordTypes/selectors";
import { references } from "redux/modules/entityReferences/selectors";
import { selectedRows } from "redux/modules/modules/records/settings/selectors";

function mapStateToProps(state, props) {
  return {
    orgDetails: orgDetails(state),
    eventDetails: eventDetails(state),
    fields: fields(state, props.moduleId),
    module: moduleDetails(state, props.moduleId),
    recordTypes: recordTypes(state, props.moduleId),
    references: references(state),
    selectedRows: selectedRows(state, props.moduleId)
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    dispatch,
    ...bindActionCreators(
      {
        showModal,
        hideModal,
        addReference,
        addValue,
        addValues,
        deleteRecord,
        deselectAllRows: () => deselectAllRows(props.moduleId),
        showSnackbar,
        updateType
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Module);
