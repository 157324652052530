import * as R from "ramda";
import React from "react";
import CSSModules from "react-css-modules";
import { connect } from "react-redux";

import { actions } from "../model";
import {
  Text3,
  MediumOutlineButton,
  VisibleIcon,
  FilterIcon
} from "components/Base";
import ProgressBar from "ui-kit/ProgressBar";

import styles from "./styles.scss";

import { noop } from "utils/General";

const mapIcons = {
  visibleIcon: VisibleIcon,
  filter: FilterIcon
};

const decorate = R.compose(
  connect(
    null,
    {
      executeAction: actions.executeAction
    }
  ),
  CSSModules(styles)
);

const ActionSection = decorate(
  ({
    title = "Alerts",
    buttons = [],
    executeAction = noop,
    data = {},
    progress = null,
    progressCaption = "",
    showDivider = true,
    children
  }) => (
    <div styleName="action-section">
      <div styleName="buttons">
        <h3 styles>{title}</h3>
        {R.map(
          button => (
            <MediumOutlineButton
              LeftIcon={mapIcons[button.icon]}
              key={button.id}
              onClick={() => executeAction({ actionId: button.id, data })}
              mr={2}
              color={button.primary ? "primary7" : ""}
            >
              {button.label}
            </MediumOutlineButton>
          ),
          buttons
        )}
        <div styleName="spacer">a</div>
        {typeof progress === "number" ? (
          <div styleName="progress">
            <Text3 mb={2}>
              {progress}% {progressCaption}
            </Text3>
            <ProgressBar progress={progress / 100} />
          </div>
        ) : null}
      </div>
      {children}
      {showDivider ? <div className={styles.divider}>a</div> : null}
    </div>
  )
);

export { ActionSection };
