import React, { useState } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { actions } from "../model";
import { ACTION_TYPES } from "ui-kit/PopoverMenu/constants";

import { Div } from "components/Base";
import Popover from "@lennd/material-ui/Popover";

import styleSpects from "components/Base/Base/styleSpecs";
import { noop } from "utils/General";

import DeleteConfirmation from "ui-kit/DeleteConfirmation";

const MenuPopover = ({
  Label,
  onClose = noop,
  children,
  wrapperStyle = {},
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <div style={wrapperStyle}>
      {React.cloneElement(Label, {
        onClick: ev => {
          setAnchorEl(ev.target);
          setOpen(!open);
        }
      })}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setOpen(false);
          onClose();
        }}
        {...props}
      >
        {children({
          open,
          closePopover: () => setOpen(false)
        })}
      </Popover>
    </div>
  );
};

const Header = ({ children, showBorder }) => (
  <Div
    uppercase
    color="black"
    fs={0}
    px={3}
    pt={showBorder ? 3 : 2}
    mt={showBorder ? 1 : 0}
    fw={3}
    mb={children ? 1 : null}
    bt={showBorder ? 1 : 0}
    bc="gray3"
  >
    {children}
  </Div>
);

const MenuItem = ({
  leftIcon = "",
  rightIcon = "",
  children,
  onClick = noop
}) => (
  <Div
    fs={2}
    px={3}
    py={2}
    onClick={onClick}
    display="row.flex-start.center"
    bg={{
      hover: "neutral0",
      default: "white"
    }}
    color="black"
  >
    {leftIcon ? (
      <Div display="row.flex-start.center" width={25}>
        <i
          className="material-icons"
          style={{ color: "black", fontSize: styleSpects.fontSize[3] }}
        >
          {leftIcon}
        </i>
      </Div>
    ) : (
      <Div width={10} />
    )}
    {rightIcon ? (
      <Div display="row.space-between.center" flex={1}>
        {children}
        <i
          className="material-icons"
          style={{ color: "black", fontSize: styleSpects.fontSize[3] }}
        >
          {rightIcon}
        </i>
      </Div>
    ) : (
      children
    )}
  </Div>
);

const decorate = connect(
  null,
  bindInstance({
    execute: actions.executeAction
  })
);

/* another prop is rowActionsType = GROUPING_ACTIONS for getting actions for group-header */
const Actions = ({
  actions = [],
  execute = noop,
  data,
  column,
  children,
  onClickItem = noop,
  ...styleProps
}) => (
  <MenuPopover Label={children}>
    {({ closePopover }) => (
      <Div bra={3} bg="white" width={200} py={2} {...styleProps}>
        {actions.map((action, idx) => {
          switch (action.type) {
            case ACTION_TYPES.HEADER:
              return (
                <Header showBorder={idx !== 0} key={idx}>
                  {action.name}
                </Header>
              );
            case ACTION_TYPES.SEPARATOR:
              return (
                <Div style={{ height: 1 }} width={1} bg="gray5" key={idx} />
              );
            case ACTION_TYPES.SUBMENU:
              return (
                <Actions
                  actions={action.submenu}
                  onClickItem={closePopover}
                  execute={execute}
                  row={data}
                  column={column}
                  key={idx}
                  style={{ padding: 0 }}
                >
                  <MenuItem
                    leftIcon={action.leftIcon}
                    rightIcon="keyboard_arrow_right"
                    onClick={() => {
                      execute({ actionId: action.id, data });
                      closePopover();
                    }}
                  >
                    {action.name}
                  </MenuItem>
                </Actions>
              );
            default:
              return action.confirm ? (
                <DeleteConfirmation
                  key={action.id}
                  onConfirm={() => {
                    execute({ actionId: action.id, data });
                    onClickItem(action.id);
                    closePopover();
                  }}
                  text="Are you sure?"
                >
                  <MenuItem
                    key={action.id}
                    leftIcon={action.leftIcon}
                    rightIcon={action.rightIcon}
                  >
                    {action.name}
                  </MenuItem>
                </DeleteConfirmation>
              ) : (
                <MenuItem
                  key={action.id}
                  leftIcon={action.leftIcon}
                  rightIcon={action.rightIcon}
                  onClick={() => {
                    execute({ actionId: action.id, data });
                    onClickItem(action.id);
                    closePopover();
                  }}
                >
                  {action.name}
                </MenuItem>
              );
          }
        })}
      </Div>
    )}
  </MenuPopover>
);

export default decorate(Actions);
