import React, { Fragment } from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { getters, actions } from "Forms/WizardModal";
import Header from "Forms/WizardModal/View/Header";
import {
  Div,
  SuperOutlineButton,
  // Text0,
  // Text2,
  Text4,
  Text6,
  // MediumFilledButton,
  RightArrowIcon,
  SuperFilledButton
} from "components/Base";
import FieldGroup from "./FieldGroup";
import { APPLICATIONS_PAGES } from "Forms/WizardModal/constants";
import {
  getFilteredApplicationsSelectFields,
  shouldShowApplicationPasses
} from "Forms/WizardModal/selectors";

const decorate = connect(
  state => ({
    applicationsSelectFields: getFilteredApplicationsSelectFields(state),
    shouldShowApplicationPasses: shouldShowApplicationPasses(state),
    requestType: getters.requestType(state),
    contactTypes: getters.contactTypes(state),
    primaryContactTypeId: getters.primaryContactTypeId(state),
    attendeeContactTypeId: getters.attendeeContactTypeId(state)
  }),
  {
    toggleGroupField: actions.toggleApplicationEnabledGroupField,
    showCollectFields: actions.showCollectFieldsModal,
    toggleField: actions.toggleApplicationsSelectFields,
    toggleRequiredField: actions.toggleRequiredApplicationsSelectFields,
    back: () =>
      actions.setSelectedApplications(APPLICATIONS_PAGES.SELECT_ACCOUNTS),
    goToNextStep: shouldShowApplicationPasses =>
      actions.setSelectedApplications(
        shouldShowApplicationPasses
          ? APPLICATIONS_PAGES.SELECT_PASSES
          : APPLICATIONS_PAGES.REVIEW
      ),
    getItemTypes: actions.getItemTypes,
    setPrimaryContactTypeId: actions.setPrimaryContactTypeId,
    setAttendeeContactTypeId: actions.setAttendeeContactTypeId
  }
);

// This will be used in the future
// const Section = ({ iconUrl, onClick = () => {}, title, description = "" }) => (
//   <Div
//     width={595}
//     height={54}
//     bra={1}
//     bc="neutral2"
//     ba={2}
//     pr={2}
//     pl={3}
//     display="row.space-between.center"
//     mt={3}
//     bg="white"
//   >
//     <Div display="row.flex-start.center">
//       <img src={iconUrl} width={30} height={30} />
//       <Div ml={2}>
//         <Text2 bold>{title}</Text2>
//         {!R.isEmpty(description) && (
//           <Text0 color="neutral5">{description}</Text0>
//         )}
//       </Div>
//     </Div>
//     <MediumFilledButton onClick={onClick} color="black" bg="neutral2">
//       ADD SECTION
//     </MediumFilledButton>
//   </Div>
// );

const SelectFields = ({
  handlers,
  showCollectFields,
  applicationsSelectFields,
  toggleField,
  toggleRequiredField,
  toggleGroupField,
  back,
  goToNextStep,
  getItemTypes,
  shouldShowApplicationPasses,
  requestType,
  contactTypes,
  primaryContactTypeId,
  attendeeContactTypeId,
  setPrimaryContactTypeId,
  setAttendeeContactTypeId
}) => (
  <Div width={1} height={1}>
    <Header
      steps={["Step 1", "Step 2", "Step 3", "Step 4"]}
      handlers={handlers}
    />
    <Div
      bg="neutral0"
      display="column.flex-start.center"
      p={5}
      style={{ height: "calc(100% - 157px)", overflow: "auto" }}
    >
      <Text6 bold>Select the questions you want to ask:</Text6>
      <Div width={1}>
        <Div bg="neutral0" display="column.flex-start.center" p={5}>
          {requestType === "group" ? (
            <Fragment>
              <FieldGroup
                showModal={showCollectFields}
                title="Company/Group Information"
                iconUrl="http://d2dks4tzxs6xee.cloudfront.net/img/icons8-organization-96.png"
                buttonText="View all company/group questions"
                groupData={R.propOr(
                  {},
                  "accountFields",
                  applicationsSelectFields
                )}
                toggle={toggleField}
                toggleRequiredField={toggleRequiredField}
                fieldGroupName="accountFields"
                editItem={handlers.showEditFieldModal}
                toggleGroupField={toggleGroupField}
              />
              <Div width={595} mt={3} mb={2}>
                <Text4 bold>Additional Sections</Text4>
              </Div>
              <FieldGroup
                showModal={showCollectFields}
                title="Primary Contact Section"
                description="Will create a contact record related to the applying company/group"
                iconUrl="http://d2dks4tzxs6xee.cloudfront.net/img/icons8-user-96.png"
                buttonText="View all contact questions"
                groupData={R.propOr(
                  {},
                  "contactFields",
                  applicationsSelectFields
                )}
                toggle={toggleField}
                toggleRequiredField={toggleRequiredField}
                fieldGroupName="contactFields"
                editItem={handlers.showEditFieldModal}
                toggleGroupField={toggleGroupField}
                showTypesDropdown
                typesList={contactTypes}
                selectedTypeId={primaryContactTypeId}
                dropdownText="Person Type"
                selectTypeId={setPrimaryContactTypeId}
              />
              {/* <Section
            iconUrl="http://d2dks4tzxs6xee.cloudfront.net/img/icons8-bulleted-list-96-1.png"
            title="Add 'Qualifications' Section"
          /> */}
              <FieldGroup
                showModal={showCollectFields}
                iconUrl="http://d2dks4tzxs6xee.cloudfront.net/img/icons8-bulleted-list-96.png"
                title="Add Personnel/Attendee Applicant Section"
                description="Will create one or more attendees related to the applying company/group"
                buttonText="View all attendee questions"
                groupData={R.propOr(
                  {},
                  "attendeeFields",
                  applicationsSelectFields
                )}
                toggle={toggleField}
                toggleRequiredField={toggleRequiredField}
                fieldGroupName="attendeeFields"
                editItem={handlers.showEditFieldModal}
                toggleGroupField={toggleGroupField}
                showTypesDropdown
                typesList={contactTypes}
                selectedTypeId={attendeeContactTypeId}
                dropdownText="Person Type"
                selectTypeId={setAttendeeContactTypeId}
              />
            </Fragment>
          ) : (
            <FieldGroup
              showModal={showCollectFields}
              title="Applicant Information"
              iconUrl="http://d2dks4tzxs6xee.cloudfront.net/img/icons8-user-96.png"
              buttonText="View all questions"
              groupData={{
                ...R.propOr({}, "contactFields", applicationsSelectFields),
                enabled: true,
                locked: true
              }}
              toggle={toggleField}
              toggleRequiredField={toggleRequiredField}
              fieldGroupName="contactFields"
              editItem={handlers.showEditFieldModal}
              toggleGroupField={toggleGroupField}
            />
          )}
        </Div>
      </Div>
    </Div>
    <Div
      display="row.space-between.center"
      width={1}
      bt={1}
      bc="neutral2"
      bg="white"
      p={5}
      height={90}
      style={{ flexShrink: 0 }}
    >
      <SuperOutlineButton onClick={back}>Back</SuperOutlineButton>
      <SuperFilledButton
        ml={3}
        bg="altB5"
        onClick={() => {
          if (shouldShowApplicationPasses) {
            getItemTypes();
          }
          goToNextStep(shouldShowApplicationPasses);
        }}
        RightIcon={RightArrowIcon}
      >
        Next
      </SuperFilledButton>
    </Div>
  </Div>
);

export default decorate(SelectFields);
