import getValue from "../get-value/lookup";
import toString from "../to-string/lookup";
import sortEmpty from "./sort-empty";

export default {
  ASC: sortEmpty("lookup", (a, b, meta) =>
    toString(getValue(a), meta)
      .toLowerCase()
      .localeCompare(toString(getValue(b), meta).toLowerCase())
  ),

  DESC: sortEmpty("lookup", (a, b, meta) =>
    toString(getValue(b), meta)
      .toLowerCase()
      .localeCompare(toString(getValue(a), meta).toLowerCase())
  )
};
