import PropTypes from "prop-types";
import React from "react";
import EditorBase from "./TableConnector";
import { get } from "lodash";
import Cell from "./CellBase";
import Editor from "components/Global/Editors/CredentialEditor";
import Formatter from "../CellFormatters/Credential";

export default class CredentialEditor extends EditorBase {
  render() {
    const { rowMetaData, column } = this.props;
    const type = get(column, "settings.selectType");
    const editor = (
      <Editor
        {...this.props}
        className="editor-main"
        value={this.state}
        onChange={this.handleChange}
      />
    );

    if (type === "quantity-only-input") {
      return editor;
    }

    return (
      <Cell
        ref="editor"
        formatter={
          <Formatter value={this.state} dependentValues={rowMetaData} />
        }
        editor={editor}
      />
    );
  }
}

CredentialEditor.propTypes = {
  ...EditorBase.propTypes,
  rowMetaData: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired
};
