import React from "react";
import FileIcon from "components/Atoms/FileIcon";
import { Div } from "components/Base";

const File = ({ filename, mimetype, isClickable, onClick }) => {
  // TODO: A couple ways to do this. Mimetype, extension, has map of the two.
  const fileType = mimetype.split("/").pop();
  return (
    <Div
      title={filename}
      onClick={isClickable ? onClick : undefined}
      display="row.flex-start.center"
      bg={{ default: "white", hover: "neutral0" }}
      height={28}
      width={120}
      style={{
        border: "1px solid #D4D4D4",
        boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.07)",
        borderRadius: 3,
        margin: "3px 0 3px 3px",
        padding: "0 5px",
        cursor: "pointer"
      }}
    >
      <Div
        width={14}
        display="row.flex-start.center"
        style={{
          marginRight: 5
        }}
      >
        <FileIcon size={14} mimetype={mimetype} />
      </Div>
      <Div
        display="column"
        style={{
          width: "calc(100% - 14px - 5px)"
        }}
      >
        <Div
          truncate
          fw={3}
          style={{
            color: "#625D6C",
            fontSize: 11,
            marginRight: 3
          }}
        >
          {filename}
        </Div>
        <Div
          truncate
          uppercase
          fw={3}
          style={{
            fontSize: 8,
            color: "rgba(#625D6C, 0.73)",
            marginRight: 3
          }}
        >
          {fileType}
        </Div>
      </Div>
    </Div>
  );
};

export default File;
