import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import { get, find, sortBy, debounce, flattenDeep, uniq, values } from "lodash";

import FormOptions from "components/Event/FormsV2/Overview/FormOptions";
import Loading from "components/Global/Loading";
import SwimLanes from "./SwimLanes";
import TableIcon from "components/Atoms/TableIcon";
import SearchInput from "components/Atoms/AsyncSearchInput";
import searchTable from "utils/Search/table-search";
import constructSearchObject from "utils/Search/construct-searchable-object";
import ActionButton from "./ActionButton";
import getSubmitter from "components/Event/FormsV2/Utils/getSubmitter";
import hiddenFields from "components/Event/FormsV2/Utils/field-blacklist";
import SendSummaryConfirmations from "./SendSummaryConfirmations";
import AddSubmissionAsModal from "components/Event/FormsV2/Modals/AddSubmissionAsModal";
import SendFormModal from "components/Event/FormsV2/Modals/FormSendModal";
import SubmissionSummary from "./SubmissionSummary";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import SubmissionApi from "redux/modules/formsV2/submission/api";
import countApprovals from "components/Event/FormsV2/Utils/count-approvals";
import CustomizeCardPopover from "./CustomizeCardPopover";
import isSubmissionSynced from "components/Event/FormsV2/Utils/is-submission-synced";
import orderIsEventbrite from "components/Event/Credentials/Utils/eventbrite/order-is-eventbrite";
import LockSubmissionsPopover from "./LockSubmissionsPopover";
import getFormVersion from "components/Event/FormsV2/Utils/get-form-version";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const hasCredentials = fields =>
  fields.find(({ type }) => type === "credentials");

function formHasCredentialIntegrations(integrations, fields) {
  const isEventBriteEnabled = integrations.find(
    ({ name }) => name === "eventbrite"
  );
  if (isEventBriteEnabled) {
    const subforms = fields.filter(({ type }) => type === "subform");
    return (
      hasCredentials(fields) ||
      subforms.some(({ subform }) => hasCredentials(subform.form.fields))
    );
  }
  return false;
}

// Gather all fields (parent + child) into a one level array
function aggregateFields(fields) {
  return fields.reduce(
    (allFields, field) => {
      if (field.type === "subform") {
        allFields.push(...field.subform.form.fields);
      }
      return allFields;
    },
    [...fields, { id: "primaryCollaborator" }]
  );
}

function createSubmissionSearchObjects(fields, values, id, eventDetails) {
  return fields
    .filter(field => field.type === "subform")
    .map(field => {
      const subformSubmissions = values[field.id].value.submissions;
      return Object.keys(subformSubmissions).map(key =>
        constructSearchObject(
          field.subform.form.fields,
          subformSubmissions[key].values,
          id,
          eventDetails
        )
      );
    });
}

function createSubmissionsSearchObjects(fields, submissions, eventDetails) {
  return flattenDeep(
    submissions.map(submission => [
      {
        id: submission.id,
        primaryCollaborator: getSubmitter(submission.collaborators, {
          onlyShowAccountName: true
        })
      },
      constructSearchObject(
        fields,
        submission.values,
        submission.id,
        eventDetails
      ),
      createSubmissionSearchObjects(
        fields,
        submission.values,
        submission.id,
        eventDetails
      )
    ])
  );
}

const hasSubForms = form =>
  Boolean(get(form, "fields", []).filter(f => f.type === "subform").length);

@CSSModules(css)
class Overview extends Component {
  constructor(props) {
    super(props);

    this.cache = {};
    this.search = debounce(this.search, 500);
    this.state = {
      loading: true,
      submissionToOrderMap: {},
      isSearching: false,
      filteredSubmissions: null,
      customCardFields: [],
      showSummaries: true
    };
  }

  @autobind
  defaultCardFields() {
    return this.getFieldsToShow(get(this.props, "overview.form.fields", []))
      .slice(0, 3)
      .map(({ id }) => id);
  }

  fetchCustomCardFields() {
    return (
      JSON.parse(
        localStorage.getItem(`card_settings_${this.props.params.formId}`)
      ) || {
        fields: this.defaultCardFields(),
        showSummaries: true
      }
    );
  }

  @autobind
  saveCustomCardFields() {
    localStorage.setItem(
      `card_settings_${this.props.params.formId}`,
      JSON.stringify({
        fields: this.state.customCardFields,
        showSummaries: this.state.showSummaries
      })
    );
  }

  componentDidMount() {
    // Fetch overview
    return Promise.all([
      this.getOverview(),
      this.props.getForm(this.props.params.formId),
      // Fetch integrations
      this.props.getIntegrations({
        eventId: this.props.params.eventId
      })
    ])
      .then(this.fetchIntegrationOrders)
      .then(() => {
        // Conditionally show modals
        if (this.props.params.submissionId) {
          this.showSubmissionSummary(this.props.params.submissionId);
        }
        if (this.props.location.query.sendApprovalEmailTo) {
          this.showSendSubmissionConfirmationModal(
            this.props.location.query.sendApprovalEmailTo
          );
        }

        // set default card fields from localStorage
        const { fields, showSummaries } = this.fetchCustomCardFields();
        this.setState({ customCardFields: fields, showSummaries });
      })
      .then(() =>
        this.setState({
          loading: false
        })
      );
  }

  @autobind
  fetchIntegrationOrders() {
    // Determine if we have integrations and load if we do
    const form = get(this.props, "overview.form", {});
    const fields = get(form, "fields", []);
    const integrations = this.props.integrations;
    const allSubmissions = get(this.props, "overview.submissions", []);
    const submissionIds = allSubmissions.map(s => s.id);
    if (
      formHasCredentialIntegrations(integrations, fields) &&
      submissionIds.length
    ) {
      return this.props
        .findOrdersBySubmissionIds(this.props.params.eventId, submissionIds)
        .then(results => {
          this.setState({
            submissionToOrderMap: results.list
          });

          return this.props.searchOrders(
            this.props.params.eventId,
            { ids: values(results.list) },
            "eventbrite" // TODO: Hardcoding for now
          );
        });
    }
    return null;
  }

  componentWillUnmount() {
    this.search.cancel();
    this.props.invalidate();
  }

  @autobind
  updateCustomCardFields(customCardFields) {
    this.setState({ customCardFields }, () => this.saveCustomCardFields());
  }

  @autobind
  toggleSummaries(showSummaries) {
    this.setState({ showSummaries }, () => this.saveCustomCardFields());
  }

  @autobind
  getOverview() {
    return this.props.getOverview(this.props.params.formId);
  }

  @autobind
  editForm() {
    this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/forms-v2/${this.props.params.formId}/edit`
    });
  }

  @autobind
  viewForm() {
    this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/forms-v2/${this.props.params.formId}/preview`
    });
  }

  @autobind
  goToForms() {
    this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/forms-v2`
    });
  }

  @autobind
  goToOrders(submissionId) {
    this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/credentials/manage/orders?filters={"orderId":"${this.state.submissionToOrderMap[submissionId]}"}`
    });
  }

  @autobind
  viewRequests() {
    return this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/forms-v2/${this.props.params.formId}/requests`
    });
  }

  addSubmission = async accountId => {
    const {
      user,
      overview: { form, event }
    } = this.props;
    const version = getFormVersion(form);
    let submissionResult;

    if (version === 3) {
      const submissionModuleRecord = await this.props.addRecord({
        moduleId: form.base_module_id,
        record: {
          isDraft: true
        },
        options: {
          eventId: form.event_id
        }
      });

      submissionResult = await SubmissionApi.post(
        { userId: user.id },
        {
          eventId: form.event_id,
          formId: form.id,
          userId: user.id,
          accountId,
          suppressSubmissionStartedEmail: true,
          submissionRecordId: submissionModuleRecord.id
        }
      );
    } else {
      submissionResult = await SubmissionApi.post(
        { userId: user.id },
        {
          eventId: form.event_id,
          formId: form.id,
          userId: user.id,
          accountId,
          suppressSubmissionStartedEmail: true
        }
      );
    }

    window.location = `/submissions/${event.slug}/${form.slug}/${submissionResult.submission.id}?redirectToOverview=1`;
  };

  @autobind
  showSubmissionSummary(id) {
    const fieldsToShow = this.getFieldsToShow(
      get(this.props, "overview.form.fields", [])
    );
    const submissionToOrderMap = this.state.submissionToOrderMap;
    const orders = this.props.orders;
    let hasEventbrite = false;
    let synced = false;
    let orderId;
    if (id in submissionToOrderMap) {
      orderId = submissionToOrderMap[id];
      const order = orders[orderId];
      if (order) {
        hasEventbrite = orderIsEventbrite(order);
        synced = isSubmissionSynced(id, submissionToOrderMap, orders);
      }
    }
    const modal = (
      <SubmissionSummary
        formId={this.props.params.formId}
        submissionId={id}
        getMetaData={this.getMetaData}
        fieldsToShow={fieldsToShow}
        showAdminFields
        noActions={synced}
        readOnly={synced}
        hasIntegration={hasEventbrite}
        orderId={orderId}
        isSynced={Boolean(synced)}
        onHide={() => {
          this.getOverview();
          this.fetchIntegrationOrders();
          this.props.router.push({
            pathname: `/event/${this.props.params.eventId}/forms-v2/${this.props.params.formId}/overview`
          });
        }}
      />
    );
    this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/forms-v2/${this.props.params.formId}/overview/${id}`
    });
    this.props.showModal({ content: modal, wrapper: ModalWrapper });
  }

  @autobind
  toggleFormLock() {
    const {
      overview: { form }
    } = this.props;
    this.props.updateForm({
      formId: form.id,
      isLocked: !form.is_locked
    });
  }

  @autobind
  showSendSubmissionConfirmationModal(submissionId) {
    const {
      overview: { form, submissions }
    } = this.props;
    const submission = find(submissions, { id: submissionId });
    const modal = (
      <SendSummaryConfirmations
        form={form}
        eventDetails={this.props.overview.event}
        hasChanges={false}
        submission={submission}
        fields={get(form, "fields", [])}
        params={this.props.params}
        handleApprovalSent={this.handleApprovalSent}
      />
    );

    this.props.showModal({ content: modal, wrapper: ModalWrapper });
  }

  showAddSubmissionAsModal = () => {
    this.props.showModal({
      content: <AddSubmissionAsModal onDone={this.addSubmission} />,
      wrapper: ModalWrapper
    });
  };

  @autobind
  showSendFormModal(emails = []) {
    const modal = (
      <SendFormModal
        formId={this.props.overview.form.id}
        formSlug={this.props.overview.form.slug}
        emails={emails}
        handleFormSent={this.handleFormSent}
      />
    );

    this.props.showModal({ content: modal });
  }

  @autobind
  handleFormSent() {
    this.props.showSnackbar({ message: "Form sent", action: "OK" });
  }

  @autobind
  handleApprovalSent(count) {
    this.props.showSnackbar({
      message: `${count} confirmation ${count > 1 ? "emails" : "email"} sent`,
      action: "OK"
    });
  }

  @autobind
  handleDeleteSubmission(submissionId) {
    this.props.deleteOverviewSubmission({ submissionId });
    this.props.showSnackbar({ message: "Submission deleted", action: "OK" });
  }

  @autobind
  getMetaData(fields, submission, rowData = {}, field) {
    return {
      ...rowData,
      meta: {
        changes: get(submission, "changes", []),
        columnId: field.id,
        columns: fields,
        columnSettings: field.settings,
        contactId: submission.contact_id,
        eventDetails: this.props.details,
        eventId: this.props.params.eventId,
        formId: this.props.params.formId,
        isApprovingForm: true,
        isResponseLocked: submission.is_locked,
        recipientHash: this.props.params.recipientHash,
        recipientId: rowData.recipientId,
        responseId: rowData.responseId,
        rowId: rowData.rowId,
        via: "requests",
        viewingContactVia: { viaFormRequests: this.props.params.formId }
      },
      helpers: {
        createReview: this.props.createReview,
        dispatch: this.props.dispatch,
        router: this.props.router,
        showModal: this.props.showModal
      }
    };
  }

  @autobind
  async search(keys, searchObjects, term) {
    if (!term) {
      // Bail out if we don't have a search term
      return this.setState({
        filteredSubmissions: null
      });
    }

    this.setState({ isSearching: true });

    const filteredSubmissions = await searchTable(keys, searchObjects, term);
    this.setState({
      filteredSubmissions: uniq(filteredSubmissions.map(r => r.id)),
      isSearching: false
    });
  }

  @autobind
  clearSearch() {
    this.setState({
      filteredSubmissions: null
    });
  }

  getFieldsToShow(fields) {
    return sortBy(fields, "order").filter(f => !hiddenFields.includes(f.type));
  }

  @autobind
  reviewSingleSubmission(submissionId, status) {
    this.props.createReview({
      eventId: this.props.overview.event.id,
      formId: this.props.overview.form.id,
      submissionId,
      status
    });

    this.props.showSnackbar({ message: `Submission ${status}`, action: "OK" });
  }

  @autobind
  onSubmissionMenuChange(submission, event, value) {
    if (event) {
      event.stopPropagation();
    }
    switch (value) {
      case "approve":
        this.reviewSingleSubmission(submission.id, "approved");
        break;
      case "reject":
        this.reviewSingleSubmission(submission.id, "rejected");
        break;
      case "email":
        break;
      case "lock":
      case "unlock":
        return this.props.setSubmissionLocked(
          !submission.is_locked,
          submission
        );
      case "profile":
        break;
      case "resend_confirmation":
      case "send_confirmation":
        return this.showSendSubmissionConfirmationModal(submission.id);
      case "go_to_credentials":
        return this.goToOrders(submission.id);
      case "delete":
        return this.handleDeleteSubmission(submission.id);
      default:
        break;
    }
  }

  @autobind
  handleLockAllSubmissions() {
    return this.props
      .bulkEdit({ formId: this.props.overview.form.id, isLocked: true })
      .then(this.getOverview);
  }

  render() {
    const form = get(this.props, "overview.form", {});
    const allSubmissions = get(this.props, "overview.submissions", []);
    const submissions = this.state.filteredSubmissions
      ? allSubmissions.filter(s =>
          this.state.filteredSubmissions.includes(s.id)
        )
      : allSubmissions;
    const fields = get(form, "fields", []);

    const search = term => {
      const searchObjects = createSubmissionsSearchObjects(
        fields,
        allSubmissions,
        this.props.overview.event
      );
      const searchFields = aggregateFields(fields).map(f => f.id);
      this.search(searchFields, searchObjects, term);
    };

    const fieldsToShow = this.getFieldsToShow(fields).filter(f =>
      this.state.customCardFields.includes(f.id)
    );
    const getMetaData = (...args) => this.getMetaData(fieldsToShow, ...args);
    const requestedCount = submissions.reduce(
      (count, s) => count + countApprovals(s, fields).requested,
      0
    );

    const showSynced = formHasCredentialIntegrations(
      this.props.integrations,
      fields
    );

    return (
      <div styleName="container">
        <div styleName="headerContainer">
          {/* back button */}
          <div>
            <div styleName="backButton" onClick={this.goToForms}>
              <i className="material-icons" styleName="backButtonIcon">
                &#xE314;
              </i>
              <div>All Forms</div>
            </div>
          </div>

          {/* name */}
          {/* form name and buttons */}
          <div styleName="metaRow">
            <div styleName="nameWrapper">
              <div styleName="name">
                {get(this.props.overview, "form.name")}
              </div>
              <FormOptions
                location={this.props.location}
                showModal={this.props.showModal}
                form={this.props.overview.form}
                showSnackbar={this.props.showSnackbar}
                params={this.props.params}
                dispatch={this.props.dispatch}
              />
            </div>

            <div styleName="searchInput">
              <SearchInput
                findIconStyle={{
                  color: "#A979E0"
                }}
                style={{
                  marginRight: "10px",
                  color: "#535353",
                  height: "40px",
                  width: "100%",
                  paddingLeft: "4px",
                  background: "#fff",
                  borderRadius: "5px",
                  boxShadow: "0 2px 5px 1px rgba(0,0,0,0.07)"
                }}
                onChange={search}
                onClear={this.clearSearch}
                isProcessing={this.state.isSearching}
                placeholder="Search submissions..."
                results={
                  this.state.filteredSubmissions
                    ? `${this.state.filteredSubmissions.length} ${
                        this.state.filteredSubmissions.length === 1
                          ? "result"
                          : "results"
                      }`
                    : ""
                }
              />
            </div>

            <div styleName="separator" />

            {/* buttons */}
            <div styleName="metaButtons">
              {/*
              // @NOTE: This is the original state of the button when we're ready to allow editing.
              <div styleName="metaButtonLeft" onClick={countOfRecipients ? this.viewForm : this.editForm}>
                <i className="material-icons" styleName="metaButtonIcon">&#xE89E;</i>
                <div styleName="metaButtonText">{countOfRecipients ? 'Preview form' : 'Edit Form'}</div>
              </div>
              */}

              <div styleName="metaButtonLeft" onClick={this.viewForm}>
                <i className="material-icons" styleName="metaButtonIcon">
                  &#xE89E;
                </i>
                <div styleName="metaButtonText">Preview Form</div>
              </div>

              <div
                styleName="metaButton"
                onClick={() => this.showSendFormModal()}
              >
                <i className="material-icons" styleName="metaButtonIcon">
                  &#xE163;
                </i>
                <div styleName="metaButtonText">Send form</div>
              </div>
            </div>
          </div>
        </div>
        <div styleName="actionBar">
          <div styleName="actionBarLeft">
            <ActionButton onClick={this.viewRequests}>
              <TableIcon className={css.tableIcon} color="rgba(0,0,0,0.24)" />
              All Requests{" "}
              <span styleName="allRequestsCountBadge">{requestedCount}</span>
            </ActionButton>
            <CustomizeCardPopover
              showSummaries={this.state.showSummaries}
              toggleSummaries={this.toggleSummaries}
              updateFields={this.updateCustomCardFields}
              fields={get(this.props, "overview.form.fields", [])}
              selectedFields={this.state.customCardFields}
            >
              <ActionButton onClick={this.customizeCards}>
                <i
                  className="material-icons"
                  styleName="actionButtonIcon"
                  style={{ color: "rgba(0,0,0,0.24)" }}
                >
                  view_stream
                </i>
                Edit Cards
              </ActionButton>
            </CustomizeCardPopover>
            <ActionButton onClick={this.showAddSubmissionAsModal}>
              <i
                className="material-icons"
                styleName="actionButtonIcon"
                style={{ color: "rgba(0,0,0,0.24)" }}
              >
                add_circle
              </i>
              Add submission
            </ActionButton>
          </div>
          <div styleName="actionBarRight">
            <div styleName="formLockWrapper">
              <LockSubmissionsPopover
                locked={form.is_locked}
                lockAllSubmissions={this.handleLockAllSubmissions}
                toggleFormLock={this.toggleFormLock}
              >
                <div styleName={form.is_locked ? "formLockOff" : "formLockOn"}>
                  {form.is_locked
                    ? "Not Accepting Submissions"
                    : "Accepting Submissions"}
                </div>
              </LockSubmissionsPopover>
            </div>
          </div>
        </div>
        {this.state.loading ? (
          <Loading dark content="Loading form..." className={css.loading} />
        ) : (
          <SwimLanes
            orders={this.props.orders}
            submissionToOrderMap={this.state.submissionToOrderMap}
            showSynced={showSynced}
            addSubmission={this.showAddSubmissionAsModal}
            eventDetails={this.props.details}
            eventId={this.props.params.eventId}
            fields={get(form, "fields", [])}
            fieldsToShow={fieldsToShow}
            getMetaData={getMetaData}
            hasSubforms={hasSubForms(form)}
            onSendSubmissionConfirmation={
              this.showSendSubmissionConfirmationModal
            }
            onShowSubmissionSummary={this.showSubmissionSummary}
            onSubmissionMenuChange={this.onSubmissionMenuChange}
            showSendFormModal={() => this.showSendFormModal()}
            showSummaries={this.state.showSummaries}
            submissions={submissions}
          />
        )}
      </div>
    );
  }
}

Overview.propTypes = {
  searchOrders: PropTypes.func.isRequired,
  orders: PropTypes.object.isRequired,
  findOrdersBySubmissionIds: PropTypes.func.isRequired,
  getIntegrations: PropTypes.func.isRequired,
  createReview: PropTypes.func.isRequired,
  deleteOverviewSubmission: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  getOverview: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  invalidate: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  logAction: PropTypes.func.isRequired,
  overview: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  setSubmissionLocked: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  integrations: PropTypes.array.isRequired,
  addRecord: PropTypes.array.isRequired,
  getForm: PropTypes.func.isRequired
};

export default Overview;
