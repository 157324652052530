import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Controller from "./Controller";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";

import { user } from "redux/modules/user/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import { recordTypes } from "redux/modules/modules/recordTypes/selectors";

function mapStateToProps(state) {
  return {
    eventDetails: eventDetails(state),
    user: user(state),
    accountRecordTypes: recordTypes(state, STANDARD_MODULE_IDS.accounts.id)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getRecordTypes
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Controller));
