import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

export const Tab = CSSModules(
  ({ children, active, ...props }) => (
    <div {...props} styleName={active ? "tabActive" : "tab"}>
      {children}
    </div>
  ),
  css
);

const ToggleTabs = ({ children }) => (
  <div styleName="container">{children}</div>
);

ToggleTabs.propTypes = {
  children: PropTypes.node
};

export default CSSModules(ToggleTabs, css);
