import * as R from "ramda";
import { makeInstanceSelector } from "redux-mvc";
import { getters } from "./model";

import { EMPTY_ERRORS } from "./constants";

const getKey = (_, props) => R.prop("instanceId", props);

export const getError = (state, props) =>
  R.propOr(EMPTY_ERRORS, props.id, getters.errors(state, props));

export const isValid = makeInstanceSelector(
  getters.errors,
  R.compose(
    R.all(R.prop("valid")),
    R.values
  )
)(getKey);
