import { createSelector } from "reselect";
import { defaultInstanceId } from "./bindInstance";

export const makeInstanceSelector = (...params) => getKey => {
  let pool = {};

  const selector = (state, props) => {
    const key = getKey(state, props) || defaultInstanceId;
    const poolSelector = pool[key];
    if (poolSelector) {
      return poolSelector(state, props);
    }
    pool[key] = createSelector(...params);

    return pool[key](state, props);
  };

  selector.clearPool = () => {
    pool = {};
  };

  return selector;
};
