import React, { Component } from "react";
import View from "./View";

import { FULFILLMENT_OPTIONS } from "Orders/constants/fulfillment-options";

class Controller extends Component {
  state = {};

  componentWillReceiveProps(props) {
    if (!props.field) {
      this.props.returnTo();
    }
  }

  reloadForm = () => this.props.refreshForm(this.props.form.id);

  updateBlockName = async name => {
    await this.props.updateFormField({
      fieldId: this.props.field.id,
      name
    });
    this.reloadForm();
  };

  updateFieldSettings = async (property, value) => {
    await this.props.updateFormField({
      fieldId: this.props.field.id,
      settings: {
        ...this.props.field.settings,
        [property]: value
      }
    });
    this.reloadForm();
  };

  updateBlockDescription = async description => {
    await this.props.updateFormField({
      fieldId: this.props.field.id,
      settings: {
        ...this.props.field.settings,
        description
      }
    });
    this.reloadForm();
  };

  render() {
    const { field } = this.props;

    const fulfillmentOptions = FULFILLMENT_OPTIONS.map(({ id, name }) => ({
      label: name,
      value: id
    }));

    return (
      <View
        {...{
          field: field || {},
          updateBlockName: this.updateBlockName,
          updateBlockDescription: this.updateBlockDescription,
          updateMethod: value => this.updateFieldSettings("method", value),
          onReturn: () => this.props.returnTo(),
          fulfillmentOptions
        }}
      />
    );
  }
}

export default Controller;
