import * as R from "ramda";

import { createHandlers } from "redux-mvc";

const model = createHandlers({
  namespace: "UIVideoRecorder",
  iniState: {
    video: null,
    iniValue: null,
    srcVideo: null,
    loadingProgress: 0,
    isUploading: false
  },
  reducers: {
    initVideo: R.identity,
    initRecorder: R.identity,
    importVideo: R.identity,
    uploadVideo: R.always({ isUploading: true }),
    clearVideo: R.always({
      video: null,
      srcVideo: null
    }),
    setUploadedUrl: (_, { payload: url }) => ({
      srcVideo: url,
      loadingProgress: 0,
      isUploading: false
    }),
    setImportedUrl: (_, { payload: url }) => ({
      srcVideo: url,
      video: null
    }),
    cancelInstance: R.identity,
    cancelUploadVideo: R.identity
  }
});

const { actions, getters } = model;

export { actions, getters };

export default model;
