import * as R from "ramda";

export const getParents = (id, list) => {
  const page = R.find(R.propEq("id", id), list);

  if (!page.parent) {
    return [id];
  }

  return [id, ...getParents(page.parent, list)];
};

export const getChildren = (id, list) => {
  const page = R.find(R.propEq("id", id), list);
  const children = R.propOr([], "children", page);

  if (R.isEmpty(children)) {
    return [id];
  }

  return [
    id,
    ...R.reduce(
      (acc, childId) => [...acc, ...getChildren(childId, list)],
      [],
      children
    )
  ];
};

export const getFolderOrderRecursive = (id, list) => {
  const page = R.find(R.propEq("id", id), list);
  const childrenIds = R.propOr([], "children", page);

  if (R.isEmpty(childrenIds)) {
    return {};
  }

  return {
    [id]: childrenIds,
    ...R.reduce(
      (acc, childId) => ({
        ...acc,
        ...getFolderOrderRecursive(childId, list)
      }),
      {},
      childrenIds
    )
  };
};

export const getParentsOrderRecursive = (id, list, order) => {
  const parentId =
    R.compose(
      R.propOr("", "parent"),
      R.find(R.propEq("id", id))
    )(list) || "root";

  const children = R.propOr([], parentId, order);
  const childrenHasId = R.any(childId => childId === id, children);

  if (parentId === "root") {
    return {
      ...order,
      [parentId]: childrenHasId ? [...children] : [...children, id]
    };
  }

  return {
    ...order,
    [parentId]: childrenHasId ? [...children] : [...children, id],
    ...getParentsOrderRecursive(parentId, list, order)
  };
};

export const getPagesOrder = (id, list, order) => {
  const page = R.find(R.propEq("id", id), list);
  const parentId = page.parent || "root";
  const pagesList = R.propOr([], parentId, order);

  const updatedOrder = {
    ...order,
    [parentId]: [...pagesList, id]
  };

  if (parentId === "root") {
    return updatedOrder;
  }

  return {
    ...updatedOrder,
    ...getParentsOrderRecursive(parentId, list, updatedOrder)
  };
};

export const getFolderOrder = (id, list, order) => {
  return {
    ...getParentsOrderRecursive(id, list, order),
    ...getFolderOrderRecursive(id, list)
  };
};

const removeParentsOrderRecursive = (id, list, order) => {
  const parentId =
    R.compose(
      R.propOr("", "parent"),
      R.find(R.propEq("id", id))
    )(list) || "root";

  const children = R.propOr([], parentId, order);
  const updatePagesList = R.filter(pageId => pageId !== id, children);

  const updatedOrder = R.isEmpty(updatePagesList)
    ? R.dissoc(parentId, order)
    : {
        ...order,
        [parentId]: updatePagesList
      };

  if (parentId === "root" || !R.isEmpty(updatePagesList)) {
    return updatedOrder;
  }

  return removeParentsOrderRecursive(parentId, list, updatedOrder);
};

export const removePagesOrder = (id, list, order) => {
  return removeParentsOrderRecursive(id, list, order);
};

export const updateOrderRecursive = (id, pagesOrder, treeOrder) => {
  const treeChildren = R.propOr([], id, treeOrder);

  if (R.isEmpty(treeChildren)) {
    return [];
  }

  const pagesChildren = R.propOr([], id, pagesOrder);
  const IDsDiff = R.difference(treeChildren, pagesChildren);

  return {
    [id]: [...pagesChildren, ...IDsDiff],
    ...R.reduce(
      (acc, currentId) => {
        return {
          ...acc,
          ...updateOrderRecursive(currentId, pagesOrder, treeOrder)
        };
      },
      {},
      [...pagesChildren, ...IDsDiff]
    )
  };
};

export const getLinksRecursive = (id, order, pages) => {
  const children = R.propOr([], id, order);

  if (R.isEmpty(children)) {
    return [];
  }

  return children.reduce(
    (acc, childId, idx) => [
      ...acc,
      {
        id: childId,
        order: idx,
        parent: id === "root" ? null : id,
        children: R.propOr([], childId, order),
        content_type: R.any(page => page.id === childId, pages)
          ? "page"
          : "section"
      },
      ...getLinksRecursive(childId, order, pages)
    ],
    []
  );
};

export const convertLinksIntoPagesOrder = (links, sections) => {
  const updatedLinks = R.filter(({ id, content_type }) => {
    if (content_type !== "section") {
      return true;
    }

    const section = R.find(sec => sec.id === id, sections);
    return section.isEnabled && !section.locked;
  }, links);

  const notOrderedChildren = R.reduce(
    (acc, link) => {
      const parentId = R.isNil(link.parent) ? "root" : link.parent;
      const children = R.propOr([], parentId, acc);

      return {
        ...acc,
        [parentId]: [...children, { id: link.id, order: link.order }]
      };
    },
    {},
    updatedLinks
  );

  const parentsIds = R.keys(notOrderedChildren);
  const sortByOrder = (a, b) => a.order - b.order;
  return R.reduce(
    (acc, parentId) => {
      return {
        ...acc,
        [parentId]: R.compose(
          R.map(R.prop("id")),
          R.sort(sortByOrder),
          R.propOr([], parentId)
        )(notOrderedChildren)
      };
    },
    {},
    parentsIds
  );
};
