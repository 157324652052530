import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Helpers from "utils/Global/Helpers";
import FileIcon from "components/Atoms/FileIcon";
import Popover from "@lennd/material-ui/Popover";
import DeletePopover from "components/Atoms/File/DeletePopover";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class File extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeletePopover: false,
      anchorEl: null
    };
  }

  @autobind
  viewFile() {
    window.open(this.props.url, "_blank");
  }

  @autobind
  onDelete(e) {
    e.stopPropagation();
    this.handleRequestClose();
    this.props.deleteFile(this.props.id);
  }

  @autobind
  showDeletePopover(e) {
    e.stopPropagation();
    this.setState({
      showDeletePopover: true,
      anchorEl: this.refs.file
    });
  }

  @autobind
  handleRequestClose() {
    this.setState({ showDeletePopover: false });
  }

  render() {
    const { filename, mimetype, allowDownload, disabled } = this.props;
    return (
      <div styleName="container" ref="file">
        <div styleName="fileIcon">
          <FileIcon size={28} mimetype={mimetype} />
        </div>
        <div styleName="name">{Helpers.truncateString(filename, 25)}</div>
        {!disabled ? (
          <i
            className="material-icons"
            styleName="deleteIcon"
            onClick={this.showDeletePopover}
          >
            delete_forever
          </i>
        ) : (
          ""
        )}
        {allowDownload ? (
          <i
            className="material-icons"
            styleName="downloadIcon"
            onClick={this.viewFile}
          >
            file_download
          </i>
        ) : (
          ""
        )}

        <Popover
          open={this.state.showDeletePopover}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: "middle", vertical: "bottom" }}
          transformOrigin={{ horizontal: "middle", vertical: "bottom" }}
          onClose={this.handleRequestClose}
        >
          <DeletePopover
            delete={this.onDelete}
            cancel={this.handleRequestClose}
          />
        </Popover>
      </div>
    );
  }
}

File.propTypes = {
  id: PropTypes.string.isRequired,
  allowDownload: PropTypes.bool,
  disabled: PropTypes.bool,
  mimetype: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  deleteFile: PropTypes.func.isRequired
};

export default File;
