import { hot } from "App/Config/hot";

import EventLightGate from "EventLight/Gate";
import EventLightLayout from "EventLight/Layout/View";
import EventLightDashboard from "EventLight/Dashboard/View";
import EventLightDetails from "EventLight/Details/View";
import EventLightContent from "EventLight/Content/View";
import EventLightCRM from "EventLight/CRM/View";
import EventLightCustomModule from "EventLight/CustomModule/View";
import EventLightTeam from "EventLight/Team/View";
import EventLightAgenda from "EventLight/Agenda/View";
import EventLightPortals from "EventLight/Portals/View";
import EventLightForms from "EventLight/Forms/Forms/View";
import EventLightForm from "EventLight/Forms/Form/View";
import EventLightProductionSchedules from "EventLight/ProductionSchedules/View";
import EventLightAlerts from "EventLight/Alerts/View";
import EventLightPasses from "EventLight/Passes/View";
import EventLightInventory from "EventLight/Inventory/View";
import EventLightBooths from "EventLight/Booths/View";
import EventLightCatering from "EventLight/Catering/View";
import EventLightReports from "EventLight/Reports/View";
import EventLightExpo from "EventLight/Expo/View";
import EventLightSendEmail from "EventLight/Common/SendEmail/View";
import EventLightFileRequests from "EventLight/FileRequests/List/View";
import EventLightFileRequest from "EventLight/FileRequests/Results/View";
import EventLightHealthPass from "EventLight/HealthPass/View";
import EventLightIntegrations from "EventLight/Integrations/Integrations/View";
import EventLightIntegrationsFuzion from "EventLight/Integrations/Fuzion/View";
import EventLightGuestLists from "EventLight/GuestLists/View";
import EventLightSettings from "EventLight/Settings/View";
import EventLightTasks from "EventLight/Tasks/View";

// Emails
import EventLightEmailsWrapper from "EventLight/Emails/View";
import EventLightEmailsHistory from "EventLight/Emails/History/View";

const EventLight = {
  EventLightGate,
  EventLightLayout,
  EventLightDashboard,
  EventLightDetails,
  EventLightContent,
  EventLightCRM,
  EventLightCustomModule,
  EventLightTeam,
  EventLightAgenda,
  EventLightPortals,
  EventLightForms,
  EventLightForm,
  EventLightProductionSchedules,
  EventLightAlerts,
  EventLightEmailsWrapper,
  EventLightEmailsHistory,
  EventLightPasses,
  EventLightCatering,
  EventLightBooths,
  EventLightInventory,
  EventLightReports,
  EventLightExpo,
  EventLightIntegrations,
  EventLightIntegrationsFuzion,
  EventLightSendEmail,
  EventLightFileRequests,
  EventLightFileRequest,
  EventLightHealthPass,
  EventLightGuestLists,
  EventLightSettings,
  EventLightTasks
};

export default hot(EventLight);
