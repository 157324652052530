export default type => {
  switch (type) {
    case "text":
      return "text_format";
    case "textarea":
      return "wrap_text";
    case "email":
      return "mail_outline";
    case "approval":
      return "done";
    case "boolean":
      return "check_box";
    case "catalog-item":
      return "link";
    case "catering":
      return "restaurant";
    case "countries":
      return "map";
    case "credentials":
      return "link";
    case "date":
      return "today";
    case "datetime":
      return "today";
    case "dropdown":
      return "view_list";
    case "event-days":
      return "today";
    case "file":
      return "attach_file";
    case "lookup":
      return "link";
    case "number":
      return "text_format";
    case "phone":
      return "phone";
    case "source":
      return "input";
    case "time":
      return "access_time";
    case "user":
      return "person";
    default:
      return "text_format";
  }
};
