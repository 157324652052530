import React from "react";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import Steps from "./Steps";
import Footer from "./Footer";
import Body from "./Body";
import { Div } from "components/Base";

const Layout = ({ hideModal, handlers, fetchPermissionSets, selectedTypeName }) => (
  <MiniModalWrapper
    hideModal={hideModal}
    title={`${selectedTypeName}: Intake Form & Portal Onboarding Steps`}
    isPortal={false}
    showModal
    style={{ width: 838, height: 700 }}
  >
    <Div bg="white" flex={1}>
      <Steps />
      <Body handlers={handlers} />
      <Footer hideModal={hideModal} fetchPermissionSets={fetchPermissionSets} />
    </Div>
  </MiniModalWrapper>
);

export default Layout;
