import { createSelector } from "reselect";
import * as R from "ramda";
import * as STANDARD_MODULE_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import { DEFAULT_FIELD_ORDER } from "./constants";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";

import { getters } from "./model";
import * as FormSelectors from "ui-kit/Form/selectors";

const contactReadOnlyFields = resolveReadOnlyFields({
  moduleId: STANDARD_MODULES.contacts.id
});
const accountReadOnlyFields = resolveReadOnlyFields({
  moduleId: STANDARD_MODULES.accounts.id
});
const hiddenFieldTypes = ["event-days", "lookup", "reference"];

export const getEditedField = R.compose(
  field => R.prop("value", field),
  FormSelectors.getField
);

export const getRequiredFields = createSelector(
  getters.requiredIds,
  getters.fields,
  (requiredIds, fields) =>
    R.map(id => R.find(field => id === field.id, fields), requiredIds)
);

const selectedFieldsDescription = createSelector(
  getters.selectedFields,
  getters.description,
  getters.alias,
  (fields, description, alias) =>
    R.map(
      field => ({
        ...field,
        description: R.propOr("", field.id, description),
        alias: R.propOr("", field.id, alias)
      }),
      fields
    )
);

export const getNotDraggableFields = createSelector(
  selectedFieldsDescription,
  R.compose(
    R.sortBy(f => R.prop(f.id)(DEFAULT_FIELD_ORDER))
    /*
    R.filter(({ id }) =>
      [
        STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME,
        STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME,
        STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL
      ].includes(id)
    )
    */
  )
);

export const getDraggableFields = createSelector(
  selectedFieldsDescription,
  getters.requiredIds,
  getters.selectedOrder,
  (selectedFields, requiredIds, selectedOrder) => {
    const byOrder = R.ascend(R.prop("order"));
    const fields = R.compose(
      R.map(field => ({
        ...field,
        required: R.any(id => id === field.id, requiredIds),
        order: R.propOr(0, field.id, selectedOrder),
        isHeader: false
      }))
      /*
      R.filter(
        ({ id }) =>
          ![
            STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME,
            STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME,
            STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL
          ].includes(id)
      )
      */
    )(selectedFields);

    if (R.isEmpty(selectedOrder)) {
      return fields;
    }

    return R.sort(byOrder, fields);
  }
);

export const getDraggableSectionsAndFields = createSelector(
  getDraggableFields,
  getters.headerSections,
  (draggableFields, headerSections) => {
    const orderSectionSort = R.sortWith([
      R.ascend(R.prop("order")),
      R.descend(R.prop("isHeader"))
    ]);

    return orderSectionSort([...draggableFields, ...headerSections]);
  }
);

export const getDraggableFieldsIds = createSelector(
  getNotDraggableFields,
  R.map(R.prop("id"))
);

export const getFieldsToSave = createSelector(
  selectedFieldsDescription,
  getters.requiredIds,
  getters.selectedOrder,
  getters.description,
  getters.headerSections,
  getters.alias,
  (
    selectedFields,
    requiredIds,
    selectedOrder,
    description,
    headerSections,
    alias
  ) => {
    const orderSectionSort = R.compose(
      items =>
        items.map((i, idx) => ({
          ...i,
          order: idx
        })),
      R.sortWith([R.ascend(R.prop("order")), R.descend(R.prop("isHeader"))])
    );

    const fields = R.compose(
      R.map(field => ({
        id: field.id,
        alias: alias[field.id] || null,
        description: description[field.id] || null,
        required: R.any(id => id === field.id, requiredIds),
        order: R.propOr(0, field.id, selectedOrder),
        /*
        order: [
          STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME,
          STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME,
          STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL
        ].includes(field.id)
          ? R.prop(field.id)(DEFAULT_FIELD_ORDER)
          : R.propOr(0, field.id, selectedOrder),
          */
        isHeader: false
      }))
    )(selectedFields);

    if (R.isEmpty(selectedOrder)) {
      return fields;
    }

    return orderSectionSort([...fields, ...headerSections]);
  }
);

export const getFieldsForModal = createSelector(
  getters.accountFields,
  getters.contactFields,
  (accountFields, contactFields) => {
    return [
      {
        name: "Attendee Fields", // "People Fields", //  Future: Reveal when group fields are needed
        id: "people-fields",
        items: R.compose(
          R.map(group => ({
            id: group.id,
            name: group.name,
            items: R.compose(
              R.filter(
                field =>
                  !R.contains(field.id, contactReadOnlyFields) &&
                  !R.contains(field.type, hiddenFieldTypes)
              ),
              R.map(field => ({
                id: field.field.id,
                name: field.field.name
              }))
            )(group.fields)
          })),
          R.prop("field_groups")
        )(contactFields)
      }
      /*
      // Future: Reveal when group fields are needed
      {
        name: "Group Fields",
        id: "group-fields",
        items: R.compose(
          R.map(group => ({
            id: group.id,
            name: group.name,
            items: R.compose(
              R.filter(field => !R.contains(field.id, accountReadOnlyFields)),
              R.map(field => ({
                id: field.field.id,
                name: field.field.name
              }))
            )(group.fields)
          })),
          R.prop("field_groups")
        )(accountFields)
      }
      */
    ];
  }
);

export const getSelectedFieldIds = createSelector(
  getters.selectedFields,
  selectedFields => R.map(R.prop("id"))(selectedFields)
);
