import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import ButtonEditColumnsRow from "components/Global/Table3/ButtonEditColumnsRow";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class ColumnGroup extends Component {
  @autobind
  moveRow(dragIndex, hoverIndex) {
    this.props.moveRow(this.props.index, dragIndex, hoverIndex);
  }

  @autobind
  toggleColumnVisibility(id, visible) {
    return this.props.toggleColumnVisibility(this.props.index, [id], visible);
  }

  toggleGroup = (visible = true) =>
    this.props.toggleColumnVisibility(
      this.props.index,
      this.props.group.columns.map(c => c.id),
      visible
    );

  render() {
    const { group } = this.props;
    return (
      <div>
        <div styleName="header">
          <span styleName="title">{group.header}</span>
          <span styleName="toggle" onClick={() => this.toggleGroup(true)}>
            All
          </span>{" "}
          <span styleName="toggle" onClick={() => this.toggleGroup(false)}>
            None
          </span>
        </div>
        {group.columns.map((column, index) => (
          <ButtonEditColumnsRow
            key={`${column.id}-${column.visible}`}
            dragId={group.header.replace(/[ ]/g, "-")}
            index={index}
            toggleColumn={this.toggleColumnVisibility}
            column={column}
            updateColumnOrder={this.props.updateColumnOrder}
            moveRow={this.moveRow}
          />
        ))}
      </div>
    );
  }
}

ColumnGroup.propTypes = {
  group: PropTypes.shape({
    columns: PropTypes.object
  }).isRequired,
  index: PropTypes.number.isRequired,
  moveRow: PropTypes.func.isRequired,
  toggleColumnVisibility: PropTypes.func.isRequired,
  updateColumnOrder: PropTypes.func.isRequired
};

export default ColumnGroup;
