import React from "react";
import { connect } from "react-redux";
import { Div, Text2, PopoverMenu, DownFilledIcon } from "components/Base";
import { actions } from "EventLight/Forms/Forms/model";
import { getSortingLabel } from "EventLight/Forms/Forms/selectors";
import { SORTING } from "EventLight/Forms/Forms/constants";

const decorate = connect(
  state => ({
    sortingLabel: getSortingLabel(state)
  }),
  {
    setActiveSorting: actions.setActiveSorting
  }
);

const SortByPopover = ({ setActiveSorting, sortingLabel }) => (
  <PopoverMenu
    menuItems={[
      [
        "Most Pending",
        () => {
          setActiveSorting(SORTING.PENDING);
        }
      ],
      [
        "Created Date",
        () => {
          setActiveSorting(SORTING.CREATED_AT);
        }
      ],
      [
        "ABC",
        () => {
          setActiveSorting(SORTING.ABC);
        }
      ]
    ]}
    Label={({ onClick }) => (
      <Div
        display="row.flex-start.center"
        onClick={onClick}
        style={{
          marginBottom: "9px"
        }}
      >
        <Text2 color="black">Sort by: </Text2>
        <Text2 color="black" bold mx={1}>
          {sortingLabel}
        </Text2>
        <DownFilledIcon />
      </Div>
    )}
  />
);

export default decorate(SortByPopover);
