import { createModule } from "redux-mvc";

import model from "./model";

import allNewsAndAlertsList from "Portal/PortalPagePGA/AllNewsAndAlertsList";
import allReplaysList from "Portal/PortalPagePGA/AllReplaysList";
import notificationModal from "Portal/PortalPagePGA/NotificationModal";
import allNewsAndAlertsSingle from "Portal/PortalPagePGA/AllNewsAndAlertsSingle";
import overviewAndResources from "Portal/PortalPagePGA/OverviewAndResources";
import sliderSidebar from "ui-kit/SliderSidebar";

import sagasModule from "Portal/PortalPagePGA/PortalPagePGA/sagas";

const module = createModule(model);

module.plugModule(allNewsAndAlertsList);
module.plugModule(allReplaysList);
module.plugModule(notificationModal);
module.plugModule(allNewsAndAlertsSingle);
module.plugModule(overviewAndResources);
module.plugModule(sliderSidebar);

module.setRootSaga(sagasModule);

export default module;
