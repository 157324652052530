import React from "react";

import { connect } from "react-redux";

import { actions, getters } from "Items/AddQuestionModal";

import { HEIGHTS, PAGES } from "Items/AddQuestionModal/constants";

import {
  isSelectFieldsPage,
  isNewSetPage
} from "Items/AddQuestionModal/selectors";

import { Div, BigFilledButton, BigOutlineButton } from "components/Base";

const decorate = connect(
  state => ({
    showAddQuestionSetBtn: isSelectFieldsPage(state),
    showNewSetBtn: isNewSetPage(state),
    questionName: getters.questionName(state),
    questionId: getters.questionId(state),
    saving: getters.saving(state)
  }),
  {
    goToAddQuestion: () => actions.setItemQuestionModalPage(PAGES.ADD_QUESTION),
    save: actions.save
  }
);

const Footer = ({
  hideModal,
  showAddQuestionSetBtn,
  goToAddQuestion,
  onDone,
  save,
  questionName,
  questionId,
  saving
}) => (
  <Div
    width={1}
    bt={1}
    bc="neutral1"
    display="row.space-between.center"
    px={4}
    height={HEIGHTS.FOOTER}
  >
    {showAddQuestionSetBtn ? (
      <BigFilledButton
        bg="altB4"
        color="white"
        width={268}
        onClick={goToAddQuestion}
        disabled={questionName.length < 2}
      >
        Next: Add Question to Set
      </BigFilledButton>
    ) : (
      <Div display="row.flex-start.center">
        <BigFilledButton
          bg="altB4"
          color="white"
          disabled={saving}
          onClick={() =>
            save({
              onDone: () => {
                onDone();
                hideModal();
              },
              addAnother: false
            })
          }
        >
          {saving ? "Saving..." : "Save"}
        </BigFilledButton>
        {!questionId && (
          <BigOutlineButton
            onClick={() =>
              save({
                onDone: () => {
                  onDone();
                },
                addAnother: true
              })
            }
            disabled={saving}
            ml={2}
          >
            Save & Add Another
          </BigOutlineButton>
        )}
      </Div>
    )}
    <BigOutlineButton width={121} onClick={hideModal}>
      Cancel
    </BigOutlineButton>
  </Div>
);

export default decorate(Footer);
