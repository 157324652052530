import React, { Component } from "react";
import PropTypes from "prop-types";
import autobind from "autobind-decorator";

import getValue from "utils/value-types/get-value/multiple_select";
import { Dropdown } from "components/Base";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { ArrowRenderer } from "components/Global/Editors/utils/reactSelect";

const styles = {
  option: {
    marginRight: "5px",
    borderRadius: "2px",
    height: 10,
    width: 10,
    display: "inline-block"
  },
  value: {
    fontSize: "12px",
    padding: "4px 8px",
    borderRadius: "24px",
    display: "inline-block"
  }
};

@CSSModules(css)
class MultipleSelectEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: this.formatValue(props.value).records || [],
      isLastRow: null
    };

    this.select = React.createRef();
  }

  componentDidMount() {
    if (this.state.isLastRow === null) {
      const parentContainer = this.props.scrollParent;
      const select = this.select.current;

      if (parentContainer && select) {
        const containerBottom =
          parentContainer.getBoundingClientRect().top +
          parentContainer.clientHeight;
        const selectBottom =
          select.getBoundingClientRect().top + select.clientHeight;
        const minDistance = this.props.height * 5;

        this.setState({
          isLastRow: selectBottom + minDistance >= containerBottom
        });
      } else {
        this.setState({ isLastRow: false });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    const nextPropsRecords = this.formatValue(nextProps.value).records;
    if (nextPropsRecords !== this.formatValue(this.props.value).records) {
      this.setState({
        records: nextPropsRecords || []
      });
    }
  }

  @autobind
  wrapValue(records) {
    return {
      type: "multiple_select",
      value:
        records && records.length
          ? {
              records: records || []
            }
          : undefined
    };
  }

  formatValue(value) {
    return getValue(value);
  }

  @autobind
  updateRecords(data) {
    const records = data
      ? data.map(({ value: v }) =>
          this.props.options.find(({ value }) => value === v)
        )
      : [];
    this.props.onChange(this.wrapValue(records));
    this.setState({
      records
    });
  }

  @autobind
  renderOption(option) {
    const border =
      option.backgroundColor === "#FFFFFF" ? "1px solid #cccccc" : "";
    return (
      <div>
        <span
          style={{
            ...styles.option,
            backgroundColor: option.backgroundColor || "#EBEBEB",
            border
          }}
        />
        <span>{option.label}</span>
      </div>
    );
  }

  render() {
    const {
      autofocus,
      required,
      disabled,
      options,
      placeholder,
      noTether,
      usePortal
    } = this.props;

    const { isLastRow } = this.state;

    const value = this.state.records.filter(r => r).map(record => record.value);

    return (
      <div
        styleName={isLastRow ? "container-up" : "container"}
        ref={this.select}
      >
        <Dropdown
          arrowRenderer={ArrowRenderer}
          autofocus={autofocus}
          clearable={false}
          disabled={disabled}
          closeMenuOnSelect={false}
          isMulti
          noTether={noTether}
          onChange={this.updateRecords}
          openAfterFocus={autofocus}
          optionRenderer={this.renderOption}
          options={options.map(option => ({ label: option.value, ...option }))}
          placeholder={placeholder || "Select..."}
          required={required}
          searchable
          value={value}
          usePortal={usePortal}
          menuPlacement="auto"
        />
      </div>
    );
  }
}

MultipleSelectEditor.propTypes = {
  autofocus: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  noTether: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.object
};

export default MultipleSelectEditor;
