import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { Div } from "components/Base";

import { actions as ViewPickerActions } from "Schedules/ScheduleViewPicker";
import { actions, getters } from "../../index";

import {
  getIsViewingMasterSchedule,
  getIsSchedulePrivate,
  getHasViewChanged
} from "../../selectors";

import { VIEWPICKER_INSTANCE_ID } from "../../constants";

import CanUserDo from "components/Global/Security/CanUserDo";
import SidebarLink from "Modules/Dashboard/View/Sidebar/SidebarLink";
import ScheduleViewPicker from "Schedules/ScheduleViewPicker/View";

import Header from "./Header";
import FieldFilter from "./FieldFilter";
import SortBy from "./SortBy";
import SelectFields from "./SelectFields";
import Schedules from "./Schedules";
import Editors from "./Editors";
import ToggleCalendarView from "./ToggleCalendarView";

import SaveView from "ui-kit/ViewPicker/View/SaveView"; // fix export in SaveView, we should only export from root

const decorate = connect(
  (state, props) => ({
    isViewingMasterSchedule: getIsViewingMasterSchedule(state, props),
    isSchedulePrivate: getIsSchedulePrivate(state, props),
    preferences: getters.preferences(state, props),
    moduleId: getters.moduleId(state, props),
    hasViewChanged: getHasViewChanged(state),
    collapsed: getters.collapsed(state)
  }),
  {
    onToggleCollapsed: actions.toggleCollapse,
    handleOpen: () => ViewPickerActions.setIsDrawerOpen(true)
  }
);

const Sidebar = ({
  preferences,
  handlers,
  isViewingMasterSchedule,
  isSchedulePrivate,
  moduleId,
  hasViewChanged,
  collapsed,
  handleOpen
}) => {
  return (
    <Div
      width={1}
      pt={2}
      pb={5}
      pl={4}
      pr={3}
      style={{
        backgroundColor: "#813CD2",
        minHeight: "100%"
      }}
      display="column.flex-start.stretch"
    >
      <Header />
      <ToggleCalendarView mb={2} />
      <ScheduleViewPicker mb={2} handlers={handlers} />

      <SaveView
        instanceId={VIEWPICKER_INSTANCE_ID}
        hasViewChanged={hasViewChanged}
        collapsed={collapsed}
        height={40}
        onClick={handleOpen}
        small
        mr={0}
        mb={2}
      />

      {isViewingMasterSchedule ? <Schedules mb={2} /> : null}

      <SelectFields
        onClick={() => handlers.showSelectFieldsModal(preferences)}
        mb={2}
      />
      <SortBy mb={2} />
      <FieldFilter mb={2} />
      {isSchedulePrivate ? <Editors /> : null}

      <Div flex={1} />

      {/* NOTE: add related forms */}

      <CanUserDo action={`${moduleId}_manage`}>
        <SidebarLink name="Setup" onClick={handlers.goToSetup} />
      </CanUserDo>

      {/* NOTE: add reports, add create form*/}
    </Div>
  );
};

export default R.compose(
  withRouter,
  decorate
)(Sidebar);
