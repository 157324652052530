import * as R from "ramda";
import Layout from "./Layout";
import { createContext } from "redux-mvc";
import module from "../index";
import { actions } from "../model";
import { withRouter } from "react-router";

const decorate = createContext({
  module,
  options: {
    observedDomains: ["user", "event", "permissions", "organization"],
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    persist: false
  },
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(
        actions.init({
          recordTypeId: this.props.params.recordTypeId
        })
      );
    }
  },
  handlers: {}
});

export default R.compose(
  withRouter,
  decorate
)(Layout);
