import React from "react";

import { connect } from "react-redux";

import { getters, actions } from "SalesPortal/Home";

import { Div } from "components/Base";

import MiniModalWrapper from "Orders/Common/View/MiniModalWrapper";

const decorate = connect(
  (state, props) => ({
    salesData: getters.salesData(state, props)
  }),
  {
    hideModal: () => actions.hideTermsModal()
  }
);

const TermsModal = ({ hideModal, salesData: { event_terms = "" } }) => (
  <MiniModalWrapper
    showModal
    title="Terms & Conditions"
    width={500}
    hideModal={hideModal}
  >
    <Div
      p={5}
      fs={3}
      className="lennd-wysiwyg-content"
      style={{
        width: "100%",
        height: "100%",
        overflowY: "auto"
      }}
    >
      <span
        dangerouslySetInnerHTML={{
          __html: event_terms
        }}
      />
    </Div>
  </MiniModalWrapper>
);

export default decorate(TermsModal);
