import { BEGIN, COMMIT, REVERT } from "redux-optimist";
import uuid from "node-uuid";
import {
  REQUEST,
  RECEIVE,
  UPDATE,
  UPDATE_COMPLETE,
  ERROR,
  CLEAR_ERROR,
  INVALIDATE
} from "./constants";
import * as selectors from "./selectors";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getForm(formId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const form = await api.get(getState().user.user.credentials, formId);
      dispatch({ type: RECEIVE, payload: { form } });
      return form;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting form"
          }
        ])
      );
    }
  };
}

export function getFormLink(formLinkId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const { payload } = await api.getFormLink(
        getState().user.user.credentials,
        formLinkId
      );
      dispatch({ type: RECEIVE, payload: { form: { form: payload.form } } });
      return payload;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting form link"
          }
        ])
      );
    }
  };
}

export function refreshForm(formId) {
  return async (dispatch, getState) => {
    try {
      const form = await api.get(getState().user.user.credentials, formId);
      dispatch({ type: RECEIVE, payload: { form } });
      return form;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred refreshing form"
          }
        ])
      );
    }
  };
}

export function updateForm(data) {
  return async (dispatch, getState) => {
    const transactionId = uuid.v4();
    dispatch({
      type: UPDATE,
      payload: data,
      optimist: { type: BEGIN, id: transactionId }
    });
    try {
      const result = await api.put(getState().user.user.credentials, data);
      dispatch({
        type: UPDATE_COMPLETE,
        payload: { form: result.form },
        optimist: { type: COMMIT, id: transactionId }
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" },
        optimist: { type: REVERT, id: transactionId }
      });
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating form"
          }
        ])
      );
    }
  };
}

export function deleteForm(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.delete(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting form"
          }
        ])
      );
    }
  };
}

export function invalidate() {
  return {
    type: INVALIDATE
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR
  };
}
