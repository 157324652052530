import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function addPrimaryField(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding primary field"
          }
        ])
      );
    }
  };
}

export function deletePrimaryField(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.delete(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting primary field"
          }
        ])
      );
    }
  };
}
