/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  getDetails: ({ credentials, eventId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/virtual/event/${eventId}/details`,
        credentials,
        success,
        error
      });
    }),
  updateDetails: ({ credentials, eventId, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/virtual/event/${eventId}/details`,
        credentials,
        data,
        success,
        error
      });
    }),
  getItemTypes: ({ credentials, eventId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/items/types/event/${eventId}`,
        credentials,
        success,
        error
      });
    })
};
