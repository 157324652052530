import React from "react";
import { SubNavWrapper, SubNavLink } from "OrgLight/Common/SubNav";
import { actions, getters } from "../index";
import { connect } from "react-redux";
import { EVENT_VIEWS } from "../constants";

const decorate = connect(
  (state, props) => ({
    selectedView: getters.selectedView(state, props)
  }),
  { onSelectView: actions.setSelectedView }
);

const SubNav = ({ selectedView, onSelectView }) => (
  <SubNavWrapper supportId="orgEvents">
    <SubNavLink
      name="Events"
      active={selectedView === EVENT_VIEWS.GRID}
      onClick={() => onSelectView(EVENT_VIEWS.GRID)}
    />
    <SubNavLink
      name="Calendar"
      active={selectedView === EVENT_VIEWS.CALENDAR}
      onClick={() => onSelectView(EVENT_VIEWS.CALENDAR)}
    />
  </SubNavWrapper>
);

export default decorate(SubNav);
