import * as R from "ramda";
import {
  CREDENTIAL_TYPE_ID,
  MEAL_TYPE_ID,
  BOOTH_TYPE_ID,
  SPONSORSHIP_TYPE_ID,
  ASSETS_TYPE_ID
} from "utils/item-types";

export const FEATURES = {
  OVERVIEW: "overview",
  DATES: "dates",
  TRACKING: "tracking",
  PRICES: "prices",
  VARIANTS: "variants",
  QUESTIONS: "questions",
  ZONES: "zones"
};

export const getEnabledFeatures = ({ typeId }) =>
  R.propOr(
    [FEATURES.OVERVIEW, FEATURES.PRICES],
    typeId
  )({
    [CREDENTIAL_TYPE_ID]: [
      FEATURES.OVERVIEW,
      FEATURES.DATES,
      FEATURES.TRACKING,
      FEATURES.PRICES,
      FEATURES.VARIANTS,
      // FEATURES.QUESTIONS,
      FEATURES.ZONES
    ],
    [MEAL_TYPE_ID]: [FEATURES.OVERVIEW],
    [BOOTH_TYPE_ID]: [FEATURES.OVERVIEW, FEATURES.PRICES],
    [SPONSORSHIP_TYPE_ID]: [FEATURES.OVERVIEW, FEATURES.DATES, FEATURES.PRICES],
    [ASSETS_TYPE_ID]: [
      FEATURES.OVERVIEW,
      FEATURES.DATES,
      FEATURES.TRACKING,
      FEATURES.PRICES,
      FEATURES.VARIANTS,
      FEATURES.QUESTIONS
    ]
  });

export const getItemName = typeId =>
  R.propOr(
    "item",
    typeId
  )({
    [CREDENTIAL_TYPE_ID]: "pass",
    [MEAL_TYPE_ID]: "meal",
    [BOOTH_TYPE_ID]: "booth",
    [SPONSORSHIP_TYPE_ID]: "sponsorship",
    [ASSETS_TYPE_ID]: "asset",
    default: "item"
  });
