import { all, takeEvery, fork, put, select, call } from "redux-saga/effects";
import { debounce } from "utils/General/sagas";
import * as R from "ramda";

import { getters, actions } from "./model";
import { userId as getUserId } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";

import { registerError } from "redux/modules/errors/actions";
import { actions as FormActions } from "ui-kit/Form/model";

import { TYPES } from "./constants";
import { apiCall } from "App/Data/sagas";

const resetData = function*() {
  yield put(actions.clearData());
};

const watchSave = function*() {
  yield takeEvery(actions.save.type, resetData);
};

const loadOptions = function*({ payload: search, meta: { callback } }) {
  try {
    const userId = yield select(getUserId);
    const eventId = yield select(getEventId);
    const type = yield select(getters.type);
    const optionsUrl =
      type === TYPES.ACCOUNT
        ? `/account/list/${eventId}/search`
        : `/contacts/event/${eventId}/contacts/search`;
    const filter = { userId };

    const result = yield call(apiCall, {
      method: "get",
      url: optionsUrl,
      qs: { ...filter, q: search }
    });

    const payloadName = type === TYPES.CONTACT ? "contacts" : "accounts";
    const payload = R.prop(payloadName, result);

    const options = R.map(el => ({
      value: R.prop("id", el),
      label: R.prop("name", el)
    }))(payload);
    callback(options);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred fetching options for async dropdown"
          }
        ])
      )
    ]);
  }
};

const watchLoadAsyncDropdownOptions = debounce(
  FormActions.dataRequest.type,
  loadOptions,
  250
);

const rootSaga = function*() {
  yield all([fork(watchSave), fork(watchLoadAsyncDropdownOptions)]);
};

export default rootSaga;
