import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import { noop } from "utils/General";

import { actions } from "EventLight/Expo/Sales/model";
import { STEPS } from "EventLight/Expo/Sales/constants";

import { WithThemeConsumer, defaultTh, VARIANTS, os } from "ui-kit/Theme";

import { Div, Text3 } from "components/Base";
import { PageTitle } from "Sponsors/Common/View";

import Settings from "./Settings";
import Address from "./Address";

const decorate = R.compose(
  WithThemeConsumer({ variant: VARIANTS.BACKGROUND }),
  connect(
    null,
    {
      init: actions.init
    }
  )
);

const SettingsPage = ({ init = noop, Th = defaultTh, ...styleProps }) => {
  useEffect(() => {
    init(STEPS.DETAILS);
  }, []);

  return (
    <Div px={4} display="column" {...os(styleProps)}>
      <PageTitle>Event Details</PageTitle>
      <Text3 {...Th(["color"], { mt: 4, ml: 2 })}>
        Fill out the details that you would like for your potential exhibitors
        and sponsors to see. Any changes you make will only impact what displays
        on your Sales Portal and not your overall event settings.
      </Text3>
      <Settings mt={4} p={3} />
      <Address mt={4} p={3} />
    </Div>
  );
};

export default decorate(SettingsPage);
