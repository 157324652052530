import PropTypes from "prop-types";
import React, { cloneElement } from "react";

const hasProp = prop => typeof prop !== "undefined";

export const ReadOnlyFactory = ({ children, ...props }) =>
  cloneElement(children, {
    showAdminFields: hasProp(props.showAdminFields)
      ? props.showAdminFields
      : props.isFormManager,
    readOnly: hasProp(props.readOnly) ? props.readOnly : !props.isFormManager
  });

ReadOnlyFactory.propTypes = {
  children: PropTypes.element.isRequired
};

export default Child => props => (
  <ReadOnlyFactory {...props}>
    <Child {...props} />
  </ReadOnlyFactory>
);
