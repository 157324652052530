import React from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import css from "ui-kit/ViewPicker/View/styles.scss";
import CSSModules from "react-css-modules";
import * as R from "ramda";

import { noop } from "utils/General";

import Circle from "ui-kit/Circle";
import MenuPopover from "ui-kit/MenuPopover";
import {
  PrevNext,
  DateRangeSelector,
  EventDatesCalendar
} from "ui-kit/TimeTable/View";

import OptionsButton from "./OptionsButton";

import {
  DownFilledIcon,
  AddIcon,
  MediumFilledButton,
  Div
} from "components/Base";
import AddButton from "./AddButton";

import { getIsViewingMasterSchedule } from "../selectors";
import { actions } from "../model";

import CanUserDo from "components/Global/Security/CanUserDo";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

const wrapperStyle = {
  display: "flex",
  flexDirection: "column",
  marginLeft: 8
};

const decorate = R.compose(
  connect(
    (state, props) => ({
      isViewingMasterSchedule: getIsViewingMasterSchedule(state, props)
    }),
    bindInstance({
      create: actions.eventCreate
    })
  ),
  CSSModules(css)
);

const Toolbar = ({
  create = noop,
  isViewingMasterSchedule,
  moduleId,
  instanceId,
  handlers,
  ...styleProps
}) => (
  <Div
    display="row.flex-start.center"
    style={{
      padding: "10px 20px 5px"
    }}
    {...styleProps}
  >
    <AddButton />

    <MenuPopover
      Label={
        <MediumFilledButton
          RightIcon={DownFilledIcon}
          className={css.button1Light}
          ml={2}
        >
          Go to...
        </MediumFilledButton>
      }
    >
      {({ closePopover }) => (
        <EventDatesCalendar
          instanceId={instanceId}
          closePopover={closePopover}
        />
      )}
    </MenuPopover>

    <PrevNext instanceId={instanceId} inverse />
    <Div display="row.center.stretch">
      <CanUserDo
        any={[
          `${
            isViewingMasterSchedule ? STANDARD_MODULES.schedules.id : moduleId
          }_manage`,
          `${
            isViewingMasterSchedule ? STANDARD_MODULES.schedules.id : moduleId
          }_export`
        ]}
      >
        <OptionsButton handlers={handlers} />
      </CanUserDo>
    </Div>
  </Div>
);

export default decorate(Toolbar);
