import React, { Component } from "react";
import * as R from "ramda";
import Tooltip from "components/Global/Tooltip";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { getOrderSummaryForAccount } from "redux/modules/orders/orderSummaryByRecord/actions";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import ScrollableModalBody from "components/Global/Modal/Layout/ScrollableBody";
import BulkEditLineItemsModal from "Orders/BulkEditLineItemsModal";
import { AllocationsModal } from "./Items";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import CanUserDo from "components/Global/Security/CanUserDo";
import {
  MEAL_TYPE_ID,
  CREDENTIAL_TYPE_ID,
  ITEM_MODULE_MAP,
  LABELS
} from "utils/item-types";

import {
  Div,
  NotVisibleIcon,
  SmallShadedBox,
  RightIcon,
  CheckIcon,
  ErrorIcon,
  LoadingIcon,
  Text1,
  Text2,
  Text3,
  Text5,
  DarkTab,
  SimpleTable,
  BigFilledButton,
  EditIcon,
  BigOutlineButton,
  AddBoxIcon
} from "components/Base";
import { withState, defaultProps, withProps } from "utils/General";

import {
  isFetching,
  passesSummary,
  mealsSummary
} from "redux/modules/orders/orderSummaryByRecord/selectors";
import { hideModal, showModal } from "redux/modules/modal/actions";
import { eventId, eventDetails } from "redux/modules/event/selectors";

function mapStateToProps(state, props) {
  const passes = passesSummary(state, props.groupId);
  const meals = mealsSummary(state, props.groupId);
  return {
    passesSummary: passes,
    mealsSummary: meals,
    eventId: eventId(state),
    hasEventCatering: eventDetails(state).modules.catering.enabled,
    isLoading:
      isFetching(state, props.groupId) && !passes.length && !meals.length
  };
}

function mapDispatchToProps(dispatch, props) {
  return bindActionCreators(
    {
      getOrderSummaryForAccount,
      hideModal,
      showModal
    },
    dispatch
  );
}

const PX = 6;

const Table = SimpleTable({
  TableStyler: defaultProps({
    p: 2,
    bc: "neutral3",
    bra: 1
  })(Div),
  RowStyler: defaultProps({
    display: "row.flex-start.center",
    bg: { default: "white", hover: "neutral0" },
    transition: "fast",
    py: 1,
    bra: 0
  })(Div),
  columnProps: [
    [4 / 12, "left"],
    [2 / 12, "right"],
    [2 / 12, "right"],
    [2 / 12, "right"],
    [2 / 12, "right"]
  ],
  headerCellComps: [
    "type",
    "requested",
    () => (
      <Div display="row.flex-start.center" fw={3} fs={0} bold>
        Allocated
        <Tooltip
          tooltip={
            <span>
              Allocations are <strong>approved</strong> pass items.
            </span>
          }
        >
          <span>
            <ErrorIcon sizeWFS={1} ml={1} />
          </span>
        </Tooltip>
      </Div>
    ),
    () => (
      <Div display="row.flex-start.center" fw={3} fs={0} bold>
        Assigned
        <Tooltip
          tooltip={
            <span>
              A count of <strong>approved</strong> pass items that have names
              assigned.
            </span>
          }
        >
          <span>
            <ErrorIcon sizeWFS={1} ml={1} />
          </span>
        </Tooltip>
      </Div>
    ),
    "issued"
  ],
  rowCellComps: [
    ({ type }) => (
      <Div display="row.flex-start.center">
        <Div size={10} pill mr={2} style={{ backgroundColor: type.color }} />
        <Text2 bold>{type.name}</Text2>
      </Div>
    ),
    "requested",
    "allocated",
    ({ assigned, allocated }) => {
      if (allocated > 0 && assigned >= allocated) {
        return (
          <Tooltip
            tooltip={
              <span>
                <strong>All passes</strong> assigned to people
              </span>
            }
          >
            <span>
              <SmallShadedBox LeftIcon={CheckIcon} color="success8">
                {assigned}
              </SmallShadedBox>
            </span>
          </Tooltip>
        );
      } else if (assigned > 0) {
        return <SmallShadedBox>{assigned}</SmallShadedBox>;
      }
      return assigned;
    },
    "issued"
  ]
});

const passRowMaker = R.compose(
  R.sortBy(
    R.compose(
      R.toLower,
      R.pathOr("", ["type", "name"])
    )
  ),
  R.map(({ items }) => ({
    type: { name: items[0].name, color: items[0].color },
    allocated: items[0].count_of_approved || <span>&mdash;</span>,
    assigned: items[0].count_of_approved_assigned || <span>&mdash;</span>,
    requested: items[0].count_of_requested || <span>&mdash;</span>,
    issued: items[0].count_of_issued || <span>&mdash;</span>
  }))
);

class SummaryModal extends Component {
  modified = false;

  componentDidMount() {
    this.fetchData();
  }
  componentWillUnmount() {
    if (this.modified && this.props.onClose) {
      this.props.onClose();
    }
  }
  flagAsModified = () => {
    this.modified = true;
  };
  handleHideModal = () => {
    this.props.hideModal();
  };
  fetchData = () => {
    this.props.getOrderSummaryForAccount({
      accountId: this.props.groupId,
      eventId: this.props.eventId,
      allocations: true
    });
  };
  gotoProfile = () => {
    window.open(
      `${window.__LENND_APP_URL__}/event/${this.props.eventId}/account/${this.props.groupId}`
    );
  };

  showBulkEditModal = () => {
    this.props.showModal({
      content: (
        <BulkEditLineItemsModal
          viaAllocations={true}
          showOverview={false}
          accountName={this.props.groupName}
          where={{
            eventId: this.props.eventId,
            orderAccountId: this.props.groupId,
            requireAssignment: true,
            orderType: "group"
          }}
          onDone={() => {
            this.flagAsModified();
            this.fetchData();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showAddItemsModal = () => {
    this.props.showModal({
      content: (
        <AllocationsModal
          itemTypeId={this.props.activeTab}
          onSave={() => {
            this.flagAsModified();
            this.fetchData();
          }}
          groupId={this.props.groupId}
          groupName={this.props.groupName}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const {
      activeTab,
      setActiveTab,
      groupName,
      passesSummary,
      mealsSummary,
      isLoading
    } = this.props;

    const [visiblePasses, hiddenPasses] = R.partition(
      R.path(["items", 0, "show_in_assignment_manager"]),
      passesSummary
    );

    return (
      <StyleWrapper
        heading="Allocation Summary"
        hideModal={this.handleHideModal}
        width={700}
        bodyStyles={{ padding: 0 }}
      >
        <Div bb={1} bc="neutral4" pt={3} px={PX} bg="white">
          <Div display="row.space-between.center">
            <Text5 bold>{groupName}</Text5>
            <BigOutlineButton RightIcon={RightIcon} onClick={this.gotoProfile}>
              Open Group Profile
            </BigOutlineButton>
          </Div>

          <DarkTab
            mr={3}
            onClick={() => setActiveTab(CREDENTIAL_TYPE_ID)}
            active={activeTab === CREDENTIAL_TYPE_ID}
          >
            Passes
          </DarkTab>
          {this.props.hasEventCatering && (
            <DarkTab
              mr={3}
              onClick={() => setActiveTab(MEAL_TYPE_ID)}
              active={activeTab === MEAL_TYPE_ID}
            >
              Meals
            </DarkTab>
          )}
        </Div>
        <ScrollableModalBody style={{ minHeight: 400 }}>
          {isLoading ? (
            <Div width={700} height={400} display="row.center.center">
              <LoadingIcon size={60} />
            </Div>
          ) : (
            <Div py={6} px={PX}>
              {activeTab === CREDENTIAL_TYPE_ID ? (
                <React.Fragment>
                  <Text3 pb={2} bold>
                    Passes Available on Guest List
                  </Text3>
                  <Table
                    emptyState={
                      <Text2 bold py={6} textAlign="center">
                        No passes allocated to this group.
                      </Text2>
                    }
                    rowsData={passRowMaker(visiblePasses)}
                  />
                  {Boolean(hiddenPasses.length) && (
                    <React.Fragment>
                      <Div pt={4} pb={2} display="row.flex-start.center">
                        <NotVisibleIcon size={25} mr={2} />
                        <div>
                          <Text3 bold>
                            Passes <em>Disabled</em> on Guest List
                          </Text3>
                          <Text1>
                            These pass types will not show up for anyone viewing
                            a guest list. This can be changed in the pass type
                            settings.
                          </Text1>
                        </div>
                      </Div>
                      <Table rowsData={passRowMaker(hiddenPasses)} />
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Text3 pb={2} bold>
                    Meals Available on Guest List
                  </Text3>
                  <Table
                    emptyState={
                      <Text2 bold py={6} textAlign="center">
                        No meals allocated to this group.
                      </Text2>
                    }
                    rowsData={passRowMaker(mealsSummary)}
                  />
                </React.Fragment>
              )}
            </Div>
          )}
        </ScrollableModalBody>
        <Div py={3} px={PX} bg="white" display="row.flex-start.center">
          <CanUserDo action={`${ITEM_MODULE_MAP[activeTab].id}_add_line_items`}>
            <BigOutlineButton
              LeftIcon={withProps({ color: "info5" })(AddBoxIcon)}
              mr={1}
              onClick={this.showAddItemsModal}
            >
              Add {LABELS[activeTab].label}
            </BigOutlineButton>
          </CanUserDo>
          <CanUserDo action={`${ITEM_MODULE_MAP[activeTab].id}_add_line_items`}>
            <BigOutlineButton
              LeftIcon={EditIcon}
              mr={1}
              onClick={this.showBulkEditModal}
            >
              Edit Allocation
            </BigOutlineButton>
          </CanUserDo>
          <BigFilledButton
            flex={1}
            display="row.center.center"
            bg="altB5"
            onClick={this.handleHideModal}
          >
            Done
          </BigFilledButton>
        </Div>
      </StyleWrapper>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withState("activeTab", "setActiveTab", R.prop("itemTypeId"))(SummaryModal));
