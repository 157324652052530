import React from "react";

import { connect } from "react-redux";

import {
  Div,
  MediumOutlineButton,
  Text0,
  MediumFilledButton,
  NumberSelector
} from "components/Base";

import { actions, getters } from "Orders/TicketsPasses";

import { getProfileNoneSelected } from "Orders/TicketsPasses/selectors";

import ProfilePricesPopOver from "Orders/TicketsPasses/View/ProfilePricesPopOver";

const decorate = connect(
  state => ({
    profileLimitTemp: getters.profileLimitTemp(state),
    noneSelected: getProfileNoneSelected(state)
  }),
  {
    updateProfileLimitTemp: actions.updateProfileLimitTemp,
    updateProfileLimit: actions.updateItemProfileLimit
  }
);

const LimitPopOver = ({
  handlers: { prices, closeMenu },
  profileLimitTemp,
  updateProfileLimitTemp,
  updateProfileLimit,
  noneSelected
}) => {
  return (
    <Div p={3} mb={1} width={200}>
      <Text0 color="neutral6" bold mb={1}>
        PRICES
      </Text0>
      <ProfilePricesPopOver handlers={{ prices }} />
      <Text0 color="neutral6" bold mb={1}>
        ITEM REQUEST LIMIT
      </Text0>
      <NumberSelector
        value={profileLimitTemp}
        minValue={0}
        onChangeValue={limit => {
          updateProfileLimitTemp({ limit });
        }}
        WrapperProps={{
          height: 30
        }}
      />
      <Div display="row.space-between.center" mt={2}>
        <MediumOutlineButton
          onClick={() => {
            closeMenu();
          }}
        >
          Cancel
        </MediumOutlineButton>
        <MediumFilledButton
          bg="primary7"
          onClick={() => {
            updateProfileLimit();
            closeMenu();
          }}
          disabled={noneSelected}
        >
          Assign Item
        </MediumFilledButton>
      </Div>
    </Div>
  );
};

export default decorate(LimitPopOver);
