import React from "react";
import { Div, Span } from "components/Base";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as R from "ramda";
import { SIDEBAR_INSTANCE_ID } from "../constants";
import { actions as sliderSidebarActions } from "ui-kit/SliderSidebar/model";

import {
  getEventTitle,
  getEventSubtitle,
  getEventLogoUrl,
  getContentSection1Label,
  getContentSection2Label,
  getContentSection3Label,
  getContentSection1Type,
  getContentSection2Type,
  getContentSection3Type,
  getContentSection1Link,
  getContentSection2Link,
  getContentSection3Link,
  getShowCountOfPeople
} from "../selectors";

import { getters } from "../model";

const decorator = R.compose(
  withRouter,
  connect(
    state => ({
      eventTitle: getEventTitle(state),
      eventSubtitle: getEventSubtitle(state),
      eventLogoUrl: getEventLogoUrl(state),
      contentSection1Label: getContentSection1Label(state),
      contentSection2Label: getContentSection2Label(state),
      contentSection3Label: getContentSection3Label(state),
      contentSection1Type: getContentSection1Type(state),
      contentSection2Type: getContentSection2Type(state),
      contentSection3Type: getContentSection3Type(state),
      contentSection1Link: getContentSection1Link(state),
      contentSection2Link: getContentSection2Link(state),
      contentSection3Link: getContentSection3Link(state),
      showCountOfPeople: getShowCountOfPeople(state),
      countOfPeople: getters.countOfPeople(state)
    }),
    {
      openNetworkSidebar: () =>
        sliderSidebarActions.setIsDrawerOpen(true, {
          meta: {
            instanceId: SIDEBAR_INSTANCE_ID
          }
        })
    }
  )
);

const OverviewAndDetails = ({
  eventTitle,
  eventSubtitle,
  eventLogoUrl,
  contentSection1Label,
  contentSection2Label,
  contentSection3Label,
  contentSection1Type,
  contentSection2Type,
  contentSection3Type,
  contentSection1Link,
  contentSection2Link,
  contentSection3Link,
  router,
  openNetworkSidebar,
  countOfPeople,
  showCountOfPeople,
  ...otherprops
}) => (
  <Div display="row.flex-start.stretch" bg="white" {...otherprops}>
    {eventLogoUrl ? (
      <Div p={5} style={{ borderRight: "2px solid #fafafa" }}>
        <Div
          width={90}
          height={90}
          style={{
            backgroundImage: `url("${eventLogoUrl}")`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "contain"
          }}
        />
      </Div>
    ) : null}
    <Div
      display="column.space-between.flex-start"
      style={{
        paddingTop: "27px",
        paddingBottom: "27px",
        paddingLeft: "27px",
        paddingRight: "27px"
      }}
    >
      <Div>
        <Div
          color="#323232"
          style={{
            fontSize: "24px",
            fontFamily: "'Roboto', sans-serif",
            letterSpacing: "-0.26px",
            fontWeight: 500
          }}
        >
          {eventTitle}
        </Div>
        <Div
          uppercase
          color="#222222"
          style={{
            fontFamily: "'Roboto Condensed', sans-serif",
            fontSize: "16px",
            marginBottom: "13px"
          }}
        >
          {eventSubtitle}
        </Div>

        {contentSection1Label ||
        contentSection2Label ||
        contentSection3Label ? (
          <Div
            display="row.flex-start.center"
            style={{
              flexWrap: "wrap"
            }}
          >
            {contentSection1Label ? (
              <Div
                uppercase
                color={{
                  default: "#1789EB",
                  hover: "#ffffff"
                }}
                bg={{
                  hover: "#1789EB",
                  default: "#ffffff"
                }}
                style={{
                  fontFamily: "'Roboto Condensed', sans-serif",
                  fontSize: "14px",
                  letterSpacing: "0.4px",
                  border: "1px solid #1789EB",
                  borderRadius: "4px",
                  display: "inline-block",
                  height: "inherit",
                  padding: "11px 18px"
                }}
                mr={3}
                onClick={() =>
                  contentSection1Type === "link"
                    ? window.open(contentSection1Link)
                    : router.push(`${router.location.pathname}/sections/1`)
                }
              >
                {contentSection1Label}
              </Div>
            ) : null}

            {contentSection2Label ? (
              <Div
                uppercase
                color={{
                  default: "#1789EB",
                  hover: "#ffffff"
                }}
                bg={{
                  hover: "#1789EB",
                  default: "#ffffff"
                }}
                style={{
                  fontFamily: "'Roboto Condensed', sans-serif",
                  fontSize: "14px",
                  letterSpacing: "0.4px",
                  border: "1px solid #1789EB",
                  borderRadius: "4px",
                  display: "inline-block",
                  height: "inherit",
                  padding: "11px 18px"
                }}
                mr={3}
                onClick={() =>
                  contentSection2Type === "link"
                    ? window.open(contentSection2Link)
                    : router.push(`${router.location.pathname}/sections/2`)
                }
              >
                {contentSection2Label}
              </Div>
            ) : null}

            {contentSection3Label ? (
              <Div
                uppercase
                color={{
                  default: "#1789EB",
                  hover: "#ffffff"
                }}
                bg={{
                  hover: "#1789EB",
                  default: "#ffffff"
                }}
                style={{
                  fontFamily: "'Roboto Condensed', sans-serif",
                  fontSize: "14px",
                  letterSpacing: "0.4px",
                  border: "1px solid #1789EB",
                  borderRadius: "4px",
                  display: "inline-block",
                  height: "inherit",
                  padding: "11px 18px"
                }}
                mr={3}
                onClick={() =>
                  contentSection3Type === "link"
                    ? window.open(contentSection3Link)
                    : router.push(`${router.location.pathname}/sections/3`)
                }
              >
                {contentSection3Label}
              </Div>
            ) : null}
          </Div>
        ) : null}

        {showCountOfPeople && countOfPeople - 1 > 0 ? (
          <Div
            style={{
              marginTop: "20px",
              color: "#929292",
              fontSize: "15px",
              fontFamily: "Roboto, sans-serif"
            }}
          >
            You are here with {countOfPeople - 1} other{" "}
            {countOfPeople - 1 === 1 ? "person" : "people"}.
            <Span
              style={{
                display: "inline-block",
                marginLeft: "3px",
                textDecoration: "underline",
                fontFamily: "Roboto, sans-serif"
              }}
              onClick={openNetworkSidebar}
              color={{
                default: "#929292",
                hover: "#666"
              }}
            >
              Say hello.
            </Span>
          </Div>
        ) : null}
      </Div>
    </Div>
  </Div>
);

export default decorator(OverviewAndDetails);
