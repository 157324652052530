import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  addNote,
  updateNote,
  deleteNote
} from "redux/modules/modules/notes/actions";
import { eventId } from "redux/modules/event/selectors";

function mapStateToProps(state) {
  return {
    eventId: eventId(state),
    userId: state.user.user.id
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addNote,
      updateNote,
      deleteNote
    },
    dispatch
  );
}

function NotesController(Child) {
  return class Controller extends Component {
    payloadOptions = () => {
      return {
        eventId: this.props.eventId,
        userId: this.props.userId
      };
    };

    addNote = async ({ moduleId, recordId, note }) => {
      const data = {
        options: this.payloadOptions(),
        moduleId,
        recordId,
        note
      };
      return await this.props.addNote(data);
    };

    updateNote = async ({ note, noteId, recordId }) => {
      const data = {
        options: this.payloadOptions(),
        recordId,
        noteId,
        note
      };
      return await this.props.updateNote(data);
    };

    deleteNote = async ({ noteId, moduleId, recordId }) => {
      return await this.props.deleteNote({ moduleId, recordId, noteId });
    };

    render() {
      const { addNote, updateNote, deleteNote, ...props } = this.props;

      const methods = {
        addNote: this.addNote,
        updateNote: this.updateNote,
        deleteNote: this.deleteNote
      };

      return <Child {...props} {...methods} />;
    }
  };
}

export default Child =>
  connect(mapStateToProps, mapDispatchToProps)(NotesController(Child));
