import { takeEvery, all, fork, put } from "redux-saga/effects";
import { actions } from "./model";
import { updateField } from "redux/modules/formsV2/form/fields/actions";

const updateText = function*({ payload: { text, fieldData, type } }) {
  let data = {};
  if (type === "description") {
    data = {
      settings: { description: text }
    };
  } else {
    data = {
      name: text
    };
  }
  yield put(updateField({ fieldId: fieldData.id, ...data }));
};

const watchUpdateText = function*() {
  yield takeEvery(actions.updateText.type, updateText);
};

const rootSaga = function*() {
  yield all([fork(watchUpdateText)]);
};

export default rootSaga;
