import {
  all,
  takeEvery,
  fork,
  put,
  select,
  take,
  call
} from "redux-saga/effects";
import * as R from "ramda";
import { registerError } from "redux/modules/errors/actions";
import { actions, getters } from "./model";
import { actions as TableActions } from "ui-kit/Table/model";
import { TABLE_INSTANCE_ID, ROW_ACTIONS, FIELD_IDS } from "./constants";
import { getColumns, getRows, getSubscriptionsSave } from "./selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { apiCall } from "App/Data/sagas";
import {
  actions as FormActions,
  getters as FormGetters
} from "ui-kit/Form/model";
import Helpers from "utils/Global/Helpers";

const init = function*() {
  try {
    yield put(actions.setLoading(true));
    const columns = yield select(getColumns);
    const eventId = yield select(getEventId);

    const { payload } = yield call(apiCall, {
      method: "get",
      url: `/event/${eventId}/webhooks`
    });

    yield put(actions.setWebhooks(payload));

    const rows = yield select(getRows);

    yield put(
      TableActions.setData(
        {
          canEditCells: false,
          columns,
          rows
        },
        {
          meta: {
            instanceId: TABLE_INSTANCE_ID
          }
        }
      )
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting webhooks"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const refetch = function*() {
  yield all([put(actions.setShowAddWebhookModal(false)), call(init)]);
};

const deleteWebhook = function*({
  payload: {
    row: { id }
  }
}) {
  try {
    const eventId = yield select(getEventId);
    yield call(apiCall, {
      method: "delete",
      url: `/event/${eventId}/webhook/${id}`
    });
    yield call(init);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while deleting webhook"
        }
      ])
    );
  }
};

const editWebhook = function*({
  payload: {
    row: {
      id,
      url: { value: webhookUrl }
    }
  }
}) {
  yield put(FormActions.clearValues(null));
  const webhooks = yield select(getters.webhooks);
  const webhook = R.find(R.propEq("id", id), webhooks);
  const triggers = R.propOr({}, "triggers", webhook);

  yield put(
    actions.setModalFields({
      webhookId: id,
      showAddWebhookModal: true,
      modalData: {
        [FIELD_IDS.URL]: webhookUrl,
        [FIELD_IDS.CREATE_ORDER]: R.propOr(
          false,
          FIELD_IDS.CREATE_ORDER,
          triggers
        ),
        [FIELD_IDS.UPDATE_ORDER]: R.propOr(
          false,
          FIELD_IDS.UPDATE_ORDER,
          triggers
        ),
        [FIELD_IDS.DELETE_ORDER]: R.propOr(
          false,
          FIELD_IDS.DELETE_ORDER,
          triggers
        ),
        [FIELD_IDS.ADD_LINE_ITEM]: R.propOr(
          false,
          FIELD_IDS.ADD_LINE_ITEM,
          triggers
        ),
        [FIELD_IDS.UPDATE_LINE_ITEM]: R.propOr(
          false,
          FIELD_IDS.UPDATE_LINE_ITEM,
          triggers
        ),
        [FIELD_IDS.DELETE_LINE_ITEM]: R.propOr(
          false,
          FIELD_IDS.DELETE_LINE_ITEM,
          triggers
        ),
        [FIELD_IDS.CREATE_EVENT_REGISTRATION]: R.propOr(
          false,
          FIELD_IDS.CREATE_EVENT_REGISTRATION,
          triggers
        )
      }
    })
  );
};

const watchTableActions = function*() {
  for (;;) {
    const action = yield take(TableActions.executeAction.type);

    const delegate = R.prop(action.payload.actionId, {
      [ROW_ACTIONS.DELETE]: deleteWebhook,
      [ROW_ACTIONS.EDIT]: editWebhook
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const openAddWebhookModal = function*() {
  yield put(FormActions.clearValues(null));
  yield put(
    actions.setModalFields({
      webhookId: "",
      showAddWebhookModal: true,
      modalData: null
    })
  );
};

const save = function*() {
  try {
    const webhookId = yield select(getters.webhookId);
    const fields = yield select(FormGetters.fields);
    const url = R.pathOr("", [FIELD_IDS.URL, "value"], fields);

    if (!Helpers.isValidHTTPSURL(url)) {
      yield put(
        registerError([
          {
            system: "Please enter a valid HTTPS URL",
            user: "Please enter a valid HTTPS URL"
          }
        ])
      );
    } else {
      const subscriptions = yield select(getSubscriptionsSave);

      if (R.isEmpty(subscriptions)) {
        yield put(
          registerError([
            {
              system: "Please select at least one trigger",
              user: "Please select at least one trigger"
            }
          ])
        );
      } else {
        const data = {
          url,
          subscriptions
        };
        const eventId = yield select(getEventId);
        yield call(apiCall, {
          method: webhookId ? "put" : "post",
          url: `/event/${eventId}/webhook${webhookId ? `/${webhookId}` : ""}`,
          data
        });
        yield put(actions.refetchList());
      }
    }
  } catch (error) {
    const webhookId = yield select(getters.webhookId);
    yield put(
      registerError([
        {
          system: error,
          user: `An error occurred while ${
            webhookId ? "adding" : "updating"
          } webhook`
        }
      ])
    );
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchRefetch = function*() {
  yield takeEvery(actions.refetchList.type, refetch);
};

const watchShowAddWebhookModal = function*() {
  yield takeEvery(actions.openWebhookModal.type, openAddWebhookModal);
};

const watchSave = function*() {
  yield takeEvery(actions.save.type, save);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchTableActions),
    fork(watchShowAddWebhookModal),
    fork(watchRefetch),
    fork(watchSave)
  ]);
};

export default rootSaga;
