import * as R from "ramda";
import { createSelector } from "reselect";

import { getPathname } from "App/Router/selectors";
import { getters } from "EventLight/Expo/Sales/model";

import { STEPS } from "EventLight/Expo/Sales/constants";

const steps = [
  { id: STEPS.OPPORTUNITIES, name: "Create Packages" },
  { id: STEPS.ITEMS, name: "Create Items" },
  { id: STEPS.ADD_ITEMS, name: "Add Items to Packages" },
  { id: STEPS.DETAILS, name: "Event Details" },
  { id: STEPS.SPACES, name: "Photos & Floormap" },
  { id: STEPS.APPLICATION, name: "Configure Checkout" },
  // { id: STEPS.NOTIFICATIONS, name: "Notifications" },
  { id: STEPS.PAYMENT, name: "Payment Options" },
  { id: STEPS.FINISHED, name: "Review" }
];

export const getSteps = createSelector(
  (_, { params }) => R.prop("stepId", params),
  (_, { params }) => R.prop("eventId", params),
  state => R.propOr([], "completed_steps")(getters.eventShowcase(state)),
  (selectedStepId, eventId, completedSteps) =>
    R.map(step => {
      return {
        ...step,
        selected: R.equals(step.id, selectedStepId),
        completed: R.contains(step.id, completedSteps),
        to: `/event-light/${eventId}/expo/sales/${step.id}`
      };
    }, steps)
);

const getCurrentIndex = createSelector(
  (_, { params }) => R.prop("stepId", params),
  stepId =>
    R.findIndex(
      R.compose(
        R.equals(stepId),
        R.prop("id")
      ),
      steps
    )
);

export const getIsLast = createSelector(
  getCurrentIndex,
  currentIndex => currentIndex === R.length(steps) - 1
);

export const getNextStep = createSelector(
  getPathname,
  getCurrentIndex,
  (_, { params }) => R.prop("eventId", params),
  (path, currentIndex, eventId) => {
    if (currentIndex < R.length(steps) - 1) {
      const next = steps[currentIndex + 1];
      return `/event-light/${eventId}/expo/sales/${next.id}`;
    }
    return `${path}/#`;
  }
);

export const getIsFirst = createSelector(
  getCurrentIndex,
  currentIndex => currentIndex === 0
);

export const getPrevStep = createSelector(
  getPathname,
  getCurrentIndex,
  (_, { params }) => R.prop("eventId", params),
  (path, currentIndex, eventId) => {
    if (currentIndex > 0) {
      const prev = steps[currentIndex - 1];
      return `/event-light/${eventId}/expo/sales/${prev.id}`;
    }
    return `${path}/#`;
  }
);

export const getStepsProgress = createSelector(
  state => getters.eventShowcase(state),
  eventShowcase => {
    return (
      R.compose(
        R.length,
        R.propOr([], "completed_steps")
      )(eventShowcase) / R.length(steps)
    );
  }
);
