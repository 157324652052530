// Portal V2
import Intake from "components/PortalV2/Intake";
import LoggedInView from "components/PortalV2/LoggedInView";
import LoggedOutView from "components/PortalV2/LoggedOutView";
import PortalSettings from "components/PortalV2/Home/Settings";
import PortalProfile from "components/PortalV2/Home/Profile";
import PortalSwitch from "components/PortalV2/PortalSwitch";
import PortalLogin from "components/PortalV2/Login";
import PortalLoginCallback from "components/PortalV2/Login/Callback";
import GroupManager from "components/PortalV2/GroupManager";

import Portal from "Portal/PortalWrapper/View";
import ManagePortals from "Portal/Manage/View";
import PortalSidebar from "Portal/PortalSidebar";
import PortalHome from "Portal/PortalHome/View";
import PortalForms from "Portal/PortalForms";
import PortalPreviewProfile from "Portal/PortalPreviewProfile/View";
import PortalReports from "Portal/PortalReports";
import PortalDocuments from "Portal/PortalDocuments";
import PortalPeople from "Portal/PortalPeople/View";
import PortalMessages from "Portal/PortalMessages";
import PortalCommunity from "Portal/PortalCommunity/View";
import PortalUpdates from "Portal/PortalUpdates/View";
import PortalForm from "Portal/PortalForm/View";
import PortalPage from "Portal/PortalPageWrapper/View";
import PortalSession from "Portal/PortalSession/View";
import PortalPOSPeople from "Portal/POSPeople/View";
import PortalPOSPeopleRequests from "Portal/PortalPageOrders/View";

import { hot } from "App/Config/hot";

const PortalModule = {
  Intake,
  LoggedInView,
  LoggedOutView,
  Portal,
  ManagePortals,
  PortalSidebar,
  PortalHome,
  PortalForms,
  PortalPreviewProfile,
  PortalReports,
  PortalDocuments,
  PortalPeople,
  PortalMessages,
  PortalCommunity,
  PortalUpdates,
  PortalSettings,
  PortalProfile,
  PortalSwitch,
  PortalLogin,
  PortalLoginCallback,
  GroupManager,
  PortalForm,
  PortalPage,
  PortalSession,
  PortalPOSPeople,
  PortalPOSPeopleRequests
};

export default hot(PortalModule);
