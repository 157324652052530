import PropTypes from "prop-types";
import React, { Component } from "react";
import Editor from "../../Editors/TimeEditor";

export default class TimeEditor extends Component {
  render() {
    return <Editor {...this.props} className="styled" />;
  }
}

TimeEditor.propTypes = {};
