import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import { actions, getters } from "Modules/AddEditColumnModal/model";
import {
  getIsStandardField,
  getShowMaxLength
} from "Modules/AddEditColumnModal/selectors";
import Options from "./Options";
import FieldGroupSettings from "components/Global/Table3/ModalColumn/FieldGroupSettings";
import TextArea from "components/Global-2016/Forms/TextArea";
import getColumnTypes from "components/Event/Settings/Module/utils/get-column-types";
import Loading from "./Loading";
import StandardField from "./StandardField";
import css from "./style.scss";
import { withRouter } from "react-router";

import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => {
      const opts = {
        orgId: props.params.eventId
          ? undefined
          : props.params.orgId || getOrgId(state),
        eventId: props.params.orgId
          ? undefined
          : props.params.eventId || getEventId(state)
      };

      return {
        ...opts,
        loading: getters.loading(state, props),
        type: getters.type(state, props),
        error: getters.error(state, props),
        fieldGroup: getters.fieldGroup(state, props),
        fieldGroups: getters.fieldGroups(state, props),
        description: getters.description(state, props),
        fieldTypes: getColumnTypes(props.moduleId, opts.orgId, opts.eventId),
        name: getters.name(state, props),
        isStandardField: getIsStandardField(state, props),
        maxLength: getters.maxLength(state, props),
        showMaxLength: getShowMaxLength(state, props)
      };
    },
    {
      init: actions.init,
      handleField: actions.handleField,
      setDescription: actions.setDescription,
      handleTypeChange: actions.handleTypeChange,
      handleSave: actions.handleSave,
      updateMaxLength: actions.updateMaxLength
    }
  ),
  CSSModules(css)
);

const Layout = props => {
  const {
    init,
    loading,
    fieldTypes,
    type,
    error,
    fieldGroup,
    fieldGroups,
    setDescription,
    handleField,
    description,
    handleTypeChange,
    onSave,
    hideModal,
    name,
    handleSave,
    addAtPosition,
    fieldId,
    isStandardField,
    updateMaxLength,
    maxLength,
    showMaxLength
  } = props;
  useEffect(() => {
    init({
      ...props
    });
  }, []);
  if (loading) {
    return <Loading />;
  }

  if (isStandardField) {
    return <StandardField />;
  }

  return (
    <div styleName="modalWrapper">
      <div className="modal-header">
        <div className="modal-title" styleName="title">
          {fieldId ? "Edit" : "Add"} Field
        </div>
      </div>

      <div className="modal-body" styleName="modalBody">
        <div className="modal-body-wrapper">
          {/* field name */}
          <div className="input-wrapper">
            <label styleName="formLabel">Field Name</label>
            <input
              autoFocus
              key="name"
              styleName="input"
              type="text"
              value={name}
              placeholder="Field Name"
              required="required"
              onChange={ev =>
                handleField({ field: "name", value: ev.target.value })
              }
            />
          </div>

          {/* field type */}
          <div className="input-wrapper">
            <label styleName="formLabel">Field Type</label>
            <select
              key="type"
              styleName="select"
              onChange={ev => handleTypeChange({ type: ev.target.value })}
              defaultValue={type}
            >
              {fieldTypes
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(item => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>

          <div className="input-wrapper">
            <label styleName="formLabel">Field Description</label>
            <TextArea
              onChange={ev => setDescription(ev.target.value)}
              placeholder="Description of field..."
              rows={2}
              value={description}
            />
          </div>

          <FieldGroupSettings
            onChange={ev =>
              handleField({ field: "fieldGroup", value: ev.target.value })
            }
            fieldGroups={fieldGroups}
            value={fieldGroup}
          />

          {/* field options */}
          <Options moduleId={props.moduleId} />

          {showMaxLength ? (
            <div className="input-wrapper">
              <label styleName="formLabel">Maximum Length</label>
              <input
                styleName="input"
                type="text"
                value={maxLength}
                onChange={ev => updateMaxLength(ev.target.value)}
              />
            </div>
          ) : null}

          {/* error */}
          {error ? <div styleName="error">{error}</div> : ""}

          {/* actions */}
          <div styleName="actionsWrapper">
            <div
              styleName="actionSave"
              onClick={() => handleSave({ addAtPosition, fieldGroup, onSave })}
              role="button"
              tabIndex="0"
            >
              Save
            </div>
            <div
              styleName="actionCancel"
              onClick={hideModal}
              role="button"
              tabIndex="0"
            >
              Cancel
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default decorate(Layout);
