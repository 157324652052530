import React, { Component } from "react";
import * as R from "ramda";
import Helpers from "utils/Global/Helpers";
import View from "./View";
import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";
import resolveEditor from "components/Global/StandAloneEditors/utils/resolveEditor";

const FIELDS = [
  {
    id: "is_primary",
    name: "Is a primary point of contact?",
    type: "checkbox",
    settings: {},
    required: false
  }
];

class AddPersonModalController extends Component {
  state = {
    loading: true,
    error: null,
    typeId: this.props.typeId || null,
    values: {},
    saving: false
  };

  componentDidMount() {
    this.props
      .getSettings(this.props.eventId, this.props.accountId)
      .then(() => {
        this.setState({ loading: false });
      });
  }

  componentDidUpdate() {
    // set default type
    if (this.props.types.length && !this.state.typeId) {
      this.setState({
        typeId: this.props.types[0].id
      });
    }
  }

  handleSave = () => {
    if (this.validate()) {
      this.setState({ saving: true });
      const { typeId, values } = this.state;

      const valuesToPass = values;
      const isPrimary = R.path(["is_primary", "value"])(values);
      const role = R.path(["role", "value"])(values);

      delete valuesToPass.is_primary;
      delete valuesToPass.role;
      // visual hack to see the loading button
      setTimeout(() => {
        this.setState({ saving: false });
        this.props.hideModal();

        this.props.onSave({
          typeId,
          values: valuesToPass,
          isPrimary,
          role
        });
      }, 1000);
    }
  };

  validate = () => {
    const { typeId, values } = this.state;
    const { types } = this.props;
    const error = [];

    const fields = R.compose(
      R.concat(FIELDS),
      R.propOr([], "fields"),
      R.find(R.propEq("id", typeId))
    )(types);

    /*
    // @NOTE: Commenting out so admin can still add even if no types are enabled
    if (R.isEmpty(typeId) || R.isNil(typeId)) {
      error.push("Type");
    }
    */

    fields.forEach(field => {
      const val = R.path([field.id, "value"])(values);
      if (field.required && (R.isEmpty(val) || R.isNil(val))) {
        error.push(field.name);
      } else if (
        field.type === "email" &&
        !(R.isEmpty(val) || R.isNil(val)) &&
        !Helpers.isValidEmail(val)
      ) {
        error.push("Valid Email");
      }
    });

    if (error.length) {
      this.setState({
        error: `* ${error.join(", ")} field${
          error.length === 1 ? " is" : "s are"
        } required`.replace(/(,\s)(?!.*,\s)/, " and ")
      });
      return false;
    }

    this.setState({ error: null });

    return true;
  };

  updateValue = (fieldId, value) => {
    this.setState(state => {
      state.error = null;
      state.values[fieldId] = value;
      return state;
    });
  };

  updateType = ({ value }) => {
    this.setState({ typeId: value, error: null, values: {} });
  };

  render() {
    const { error, loading, values, typeId, saving } = this.state;
    const { hideModal, eventDetails, types } = this.props;

    const selectedType = R.find(R.propEq("id", typeId), types);

    const fieldsToPass = R.compose(
      R.map(field => ({
        id: field.id,
        name: field.name,
        isRequired: field.required,
        Editor: resolveEditor(field),
        editorProps: {
          usePortal: true,
          ...resolveEditorProps(field, eventDetails)
        },
        value: values[field.id],
        onChange: value => this.updateValue(field.id, value)
      })),
      fields => [...fields, ...FIELDS],
      R.propOr([], "fields")
    )(selectedType);

    const typesToPass = types.map(({ id, name }) => ({
      value: id,
      label: name
    }));

    return (
      <View
        {...{
          error,
          hideModal,
          loading,
          handleSave: this.handleSave,
          onTypeChange: this.updateType,
          fields: fieldsToPass,
          typeId,
          types: typesToPass,
          showTypes: types.length > 1,
          saving
        }}
      />
    );
  }
}

export default AddPersonModalController;
