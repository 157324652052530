import * as R from "ramda";
import React from "react";
import {
  AvatarLabelContainer,
  SmallShadedButton,
  Div,
  Text2,
  PopoverMenu,
  MoreIcon
} from "components/Base";
import StarrableHugeAvatar from "./StarrableHugeAvatar";
import { ToggleFeature } from "@flopflip/react-redux";
import { withProps } from "utils/General";
import * as flags from "utils/feature-flags";

const OrgCard = ({
  id: orgId,
  name,
  photoURL,
  goToOrg,
  archiveOrg,
  unpinEvent,
  pinEvent,
  events
}) => (
  <AvatarLabelContainer
    mb={4}
    id={orgId}
    title={name}
    titleProps={{
      onClick: goToOrg,
      underline: { default: false, hover: true }
    }}
    photoURL={photoURL}
    headerContent={
      R.isNil(goToOrg) ? null : (
        <Div display="row.center.center">
          <ToggleFeature
            flag={flags.IS_LENND_ADMIN.NAME}
            untoggledComponent={Div}
            toggledComponent={withProps({
              Label: ({ onClick }) => (
                <MoreIcon size={20} mr={2} pill onClick={onClick} />
              ),
              menuItems: [["Archive", archiveOrg]]
            })(PopoverMenu)}
          />
          <SmallShadedButton pill onClick={goToOrg}>
            Go to org
          </SmallShadedButton>
        </Div>
      )
    }
  >
    <Div mt={2} display={"row"} style={{ flexWrap: "wrap" }}>
      {R.map(
        ({ name, id, is_event_starred, goToEvent }) => (
          <Div
            key={id}
            p={2}
            onClick={goToEvent}
            display={"column.flex-start.center"}
          >
            <StarrableHugeAvatar
              id={id}
              name={name}
              starred={is_event_starred}
              onClickStar={() =>
                is_event_starred ? unpinEvent(id) : pinEvent(id)
              }
            />
            <Text2 textAlign={"center"} maxWidth={80} mt={1} color={"gray7"}>
              {name}
            </Text2>
          </Div>
        ),
        events
      )}
    </Div>
  </AvatarLabelContainer>
);

export default OrgCard;
