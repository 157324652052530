import React from "react";
import moment from "moment";
import { Div } from "components/Base";
import { noop } from "utils/General";

const EventTooltip = ({ events = [], onEventClick = noop }) => (
  <Div display="column.flex-start.center">
    {events.map((event, index) => (
      <Div
        display="column.flex-start.flex-start"
        key={event.id}
        onClick={() => onEventClick(event)}
        style={{
          margin: "0 10px",
          padding: "10px 0",
          borderBottom:
            events.length - 1 === index ? "none" : "1px solid #f7f7f7"
        }}
      >
        <Div fs={2} color={event.color} style={{ marginBottom: 1 }}>
          {event.name}
        </Div>
        <Div color="black" fs={1}>{`${moment(event.date_from).format(
          "MMM D"
        )} - ${moment(event.date_to).format("MMM D")}${
          event.location ? ` ${event.location}` : ""
        }`}</Div>
      </Div>
    ))}
  </Div>
);

export default EventTooltip;
