import React from "react";
import { SVG } from "../Base/index";

const featherSVGs = {
  clock: ({ otherTags = null, ...svgProps }) => (
    <SVG {...svgProps} viewBox="0 0 24 24">
      {otherTags}
      <path d="M12 1C5.9 1 1 5.9 1 12s4.9 11 11 11 11-4.9 11-11S18.1 1 12 1zm0 20c-5 0-9-4-9-9s4-9 9-9 9 4 9 9-4 9-9 9z" />
      <path d="M13 11.6V6c0-.6-.4-1-1-1s-1 .4-1 1v6c0 .3.1.5.3.7l3 3c.2.2.5.3.7.3s.5-.1.7-.3c.4-.4.4-1 0-1.4L13 11.6z" />
    </SVG>
  ),
  alignCenter: ({ ...svgProps }) => (
    <SVG
      {...svgProps}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      className="feather feather-align-justify"
    >
      <line x1="21" y1="10" x2="3" y2="10" />
      <line x1="21" y1="6" x2="3" y2="6" />
      <line x1="21" y1="14" x2="3" y2="14" />
      <line x1="21" y1="18" x2="3" y2="18" />
    </SVG>
  ),
  copy: ({ ...svgProps }) => (
    <SVG
      {...svgProps}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      className="feather feather-copy"
    >
      <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
      <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
    </SVG>
  ),
  cheveronsRight: ({ ...svgProps }) => (
    <SVG
      {...svgProps}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      className="feather feather-chevrons-right"
    >
      <polyline points="13 17 18 12 13 7"></polyline>
      <polyline points="6 17 11 12 6 7"></polyline>
    </SVG>
  ),
  cheveronsLeft: ({ ...svgProps }) => (
    <SVG
      {...svgProps}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      className="feather feather-chevrons-left"
    >
      <polyline points="11 17 6 12 11 7"></polyline>
      <polyline points="18 17 13 12 18 7"></polyline>
    </SVG>
  )
};

export { featherSVGs };
