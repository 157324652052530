import React from "react";
import * as R from "ramda";
import { actions } from "../model";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { Input } from "ui-kit/Form/View";
import { FIELDS } from "../constants";
import NextButton from "./NextButton";

const decorate = R.compose(
  connect(
    null,
    {
      toggleEvent: actions.toggleEvent
    }
  ),
  CSSModules(css)
);

const YourOrganization = () => (
  <div styleName="pageWrapper">
    <div styleName="title">Let's setup your organization</div>
    <div styleName="label">So we can help you to create the perfect event.</div>
    <div styleName="fieldWrapper">
      <div styleName="label">
        What would you like to name your organization? (required)
      </div>
      <Input
        fieldId={FIELDS.ORGANIZATION_NAME}
        iniValue=""
        placeholder="Organization Name"
        style={{ width: "100%" }}
        size="big"
      />
    </div>
    <div styleName="fieldWrapper">
      <div styleName="label">Add some collaborators? (optional)</div>
      <Input
        fieldId={FIELDS.COLLABORATOR_EMAIL_1}
        iniValue=""
        placeholder="Ex. ben@gmail.com"
        style={{ width: "100%" }}
        type="email"
        size="big"
      />
      <Input
        fieldId={FIELDS.COLLABORATOR_EMAIL_2}
        iniValue=""
        placeholder="Ex. ben@gmail.com"
        style={{ width: "100%" }}
        type="email"
        mt={2}
        size="big"
      />
      <Input
        fieldId={FIELDS.COLLABORATOR_EMAIL_3}
        iniValue=""
        placeholder="Ex. ben@gmail.com"
        style={{ width: "100%" }}
        type="email"
        mt={2}
        size="big"
      />
    </div>
    <NextButton />
  </div>
);

export default decorate(YourOrganization);
