import React from "react";
import moment from "moment-timezone";
import * as R from "ramda";
import CSSModules from "react-css-modules";

import { noop } from "utils/General";

import css from "./monthStyles.scss";

import { Div } from "components/Base";

const mapIndex = R.addIndex(R.map);

const getWeeks = R.memoizeWith(
  (day, timezone) => moment(day).format("MM/YYYY") + timezone,
  (currentMonthDate, timezone) => {
    const currentDate = timezone
      ? moment(currentMonthDate).tz(timezone)
      : currentMonthDate;
    return R.compose(
      R.splitEvery(7),
      days => {
        const lastDayOfMonth = R.last(days);
        const lastDayOfWeek = moment(lastDayOfMonth).day();

        if (lastDayOfWeek === 6) {
          return days;
        }

        const append = R.map(
          delta => moment(lastDayOfMonth).add(delta, "d"),
          R.range(1, 6 - lastDayOfWeek + 1)
        );

        return R.concat(days, append);
      },
      days => {
        const firstDayOfMonth = R.head(days);
        const firstDayOfWeek = moment(firstDayOfMonth).day();

        if (firstDayOfWeek === 0) {
          return days;
        }

        const prepend = R.map(
          delta => moment(firstDayOfMonth).subtract(delta, "d"),
          R.reverse(R.range(1, firstDayOfWeek + 1))
        );

        return R.concat(prepend, days);
      },
      R.map(day => moment(currentDate).date(day)),
      R.range(1),
      date => moment(date).daysInMonth() + 1
    )(currentDate);
  }
);

const Day = ({ date }) => (
  <Div height={30} width={30}>
    {date.date()}
  </Div>
);

const WeekDay = ({ day }) => (
  <th>
    <Div className="month-week-day">{day}</Div>
  </th>
);

export const MonthCalendar = ({
  currentMonthDate = moment(),
  currentDate = moment(),
  dayOcurrences = {},
  DayComponent = Day,
  onDayClick = noop,
  timezone = ""
}) => {
  return (
    <div className="month-calendar-container">
      <div className="month-name">
        {moment(currentMonthDate).format("MMMM YYYY")}
      </div>
      <table className="month-table">
        <thead className="month-head">
          <tr className="month-week-names">
            {R.map(
              day => (
                <WeekDay day={day} key={day} />
              ),
              ["Su", "M", "T", "W", "T", "F", "Sa"]
            )}
          </tr>
        </thead>
        <tbody className="month-body">
          {mapIndex(
            (week, index) => (
              <tr key={index} className="month-body-week">
                {R.map(
                  date => (
                    <DayComponent
                      key={date.toISOString()}
                      date={date}
                      currentDate={currentDate}
                      dayOcurrences={dayOcurrences}
                      onClick={onDayClick}
                    />
                  ),
                  week
                )}
              </tr>
            ),
            getWeeks(currentMonthDate, timezone)
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CSSModules(MonthCalendar, css);
