import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";
import ContactProfile from "./ContactProfile";
import ContactProfileController from "components/Global/Controllers/ContactProfile";

function mapStateToProps(state) {
  return {
    canShowDeduplicate: selectFeatureFlag(
      flags.CAN_VIEW_RECORDS_DEDUPLICATION.NAME
    )(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactProfileController(ContactProfile));
