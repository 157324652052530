import * as R from "ramda";

import PropTypes from "prop-types";
import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Textarea from "react-textarea-autosize";

@CSSModules(css)
class ModalTextarea extends Component {
  selectText() {
    findDOMNode(this.textarea).select();
  }

  render() {
    const inputProps = {
      ref: ref => {
        this.textarea = ref;
      },
      styleName: "input",
      ...this.props
    };

    if (this.props.autoSize) {
      return <Textarea {...R.omit(["maxHeight", "maxWidth"], inputProps)} />;
    }
    return <textarea {...inputProps} />;
  }
}

ModalTextarea.propTypes = {
  autoSize: PropTypes.bool
};

export default ModalTextarea;
