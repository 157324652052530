import {
  UPDATE_COLLABORATORS,
  FETCH_RECEIVE,
  FETCH_REQUEST
} from "./constants";

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_REQUEST:
      return {
        ...state,
        [action.payload]: {
          fetching: true
        }
      };
    case FETCH_RECEIVE:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          fetching: false
        }
      };
    case UPDATE_COLLABORATORS:
      return {
        ...state,
        [action.payload.roleId]: {
          ...state[action.payload.roleId],
          list: action.payload.collaborators
        }
      };
    default:
      return state;
  }
};
