import {
  text,
  email,
  textarea,
  dropdown,
  radio,
  date,
  datetime,
  time,
  checkbox,
  file,
  phone,
  countries,
  contactReference,
  submitterReference,
  accountReference,
  credentialOrderReference,
  reference,
  percent,
  currency,
  calculatedNumber
} from "utils/value-types/schema";

export default [
  text,
  email,
  textarea,
  dropdown,
  radio,
  date,
  datetime,
  time,
  checkbox,
  file,
  phone,
  countries,
  contactReference,
  credentialOrderReference,
  accountReference,
  submitterReference,
  reference,
  percent,
  currency,
  calculatedNumber
];
