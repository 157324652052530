import React from "react";
import * as R from "ramda";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import ScrollableModalBody from "components/Global/Modal/Layout/ScrollableBody";
import DeleteConfirmation from "components/Atoms/DeleteConfirmation";

import {
  Div,
  DarkTab,
  BigFilledButton,
  BigOutlineButton,
  BigTextButton,
  LoadingIcon
} from "components/Base";
import Items from "./Scenes/Items";
import Settings from "./Scenes/Settings";

const View = ({
  hideModal,
  tabs,
  activeTab,
  saving,
  loading,
  itemBlock,
  items,
  showItemsModal,
  reorderItems,
  handleFieldChange,
  canSave,
  handleSave,
  handleDelete
}) => (
  <StyleWrapper
    heading="Edit Item Block"
    hideModal={hideModal}
    bodyStyles={{ padding: 0 }}
    width={501}
  >
    {loading ? (
      <Div py={20} display="row.center.center" flex={1}>
        <LoadingIcon size={60} color="neutral4" />
      </Div>
    ) : (
      <Div>
        <Div pt={2} px={5} bb={1} bc="gray2" display="row">
          {R.map(({ id, name, active, onClick }) => (
            <DarkTab
              key={id}
              px={2}
              fs={2}
              display="row.center.center"
              active={active}
              onClick={onClick}
            >
              {name}
            </DarkTab>
          ))(tabs)}
        </Div>
        <ScrollableModalBody
          style={{
            minHeight: 400,
            display: "flex",
            backgroundColor: "rgba(224, 224, 224, 0.19)"
          }}
        >
          <Div px={6} py={4} flex={1} style={{ width: "100%" }}>
            {R.prop(activeTab, {
              settings: <Settings {...{ itemBlock, handleFieldChange }} />,
              items: (
                <Items
                  {...{ itemBlock, items, showItemsModal, reorderItems }}
                />
              )
            })}
          </Div>
        </ScrollableModalBody>
        <Div display="row.space-between.center" py={3} px={5}>
          <Div display="row.flex-start.center">
            <BigFilledButton
              bg={canSave && !saving ? "altB5" : "neutral1"}
              mr={1}
              onClick={canSave && !saving ? handleSave : null}
            >
              {saving ? "Saving" : "Save"}
            </BigFilledButton>
            <BigOutlineButton onClick={hideModal}>Cancel</BigOutlineButton>
          </Div>
          <DeleteConfirmation
            button={
              <BigTextButton onClick={() => {}} color="danger9">
                Delete
              </BigTextButton>
            }
            handleDelete={handleDelete}
            message="Are you sure you want to delete this item block? This cannot be undone."
            title="Delete item block?"
          />
        </Div>
      </Div>
    )}
  </StyleWrapper>
);

export default View;
