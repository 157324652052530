import PropTypes from "prop-types";
import React, { cloneElement, useState } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Sidebar from "components/Global/Module/Sidebar";
import SettingsSidebar from "components/Event/Settings/Layout/Sidebar";

const SettingsHandler = props => {
  const [expanded, setExpanded] = useState(true);

  return (
    <Sidebar
      moduleId="settings"
      contentClassName={css.content}
      sidebarClassName={expanded ? null : css.compressed}
      sidebar={
        <SettingsSidebar
          {...{
            user: props.user,
            location: props.location,
            params: props.params,
            showModal: props.showModal,
            hideModal: props.hideModal,
            dispatch: props.dispatch,
            showSnackbar: props.showSnackbar,
            details: props.details,
            setExpanded,
            expanded
          }}
        />
      }
    >
      <div styleName="rightColumn">
        {cloneElement(props.children, {
          user: props.user,
          location: props.location,
          params: props.params,
          showModal: props.showModal,
          hideModal: props.hideModal,
          dispatch: props.dispatch,
          showSnackbar: props.showSnackbar,
          details: props.details
        })}
      </div>
    </Sidebar>
  );
};

SettingsHandler.propTypes = {
  children: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired
};

export default CSSModules(SettingsHandler, css);
