import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import External from "./External";

import * as ModalActions from "redux/modules/modal/actions";
import * as SnackbarActions from "redux/modules/snackbar/actions";
import { getEvent } from "redux/modules/event/actions";
import {
  updateModule,
  deleteModule,
  getModule
} from "redux/modules/modules/module/actions";
import * as ModuleFieldsActions from "redux/modules/modules/fields/actions";
import * as ModuleFieldGroupsActions from "redux/modules/modules/fieldGroups/actions";
import {
  addFieldGroupField,
  updateFieldGroupField,
  deleteFieldGroupField
} from "redux/modules/modules/fieldGroupFields/actions";
import * as ModulePrimaryFieldsActions from "redux/modules/modules/primaryFields/actions";

import {
  moduleDetails,
  fields,
  fieldGroups,
  starredFields
} from "redux/modules/modules/module/selectors";

const getContext = ({ params = {}, context = {} }) => ({
  ...params,
  ...context
});

function mapStateToProps(state, props) {
  const context = getContext(props);
  const moduleToPass = moduleDetails(state, context.moduleId);
  return {
    params: context,
    fieldGroups: fieldGroups(state, context.moduleId),
    starredFields: starredFields(state, context.moduleId),
    fields: fields(state, context.moduleId),
    module: moduleToPass,
    hasFetchedModule: Boolean(moduleToPass && Object.keys(moduleToPass).length)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...SnackbarActions,
      ...ModalActions,
      ...ModuleFieldsActions,
      ...ModuleFieldGroupsActions,
      ...ModulePrimaryFieldsActions,
      addFieldGroupField,
      updateFieldGroupField,
      deleteFieldGroupField,
      updateModule,
      getModule,
      deleteModule,
      getEvent
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(External)
);
