import React from "react";
import EditorBase from "./TableConnector";
import Checkbox from "components/Global/Editors/CheckboxEditor";

export default class CheckboxEditor extends EditorBase {
  render() {
    return (
      <Checkbox
        className="editor-main"
        value={this.state}
        onChange={this.handleChange}
      />
    );
  }
}
