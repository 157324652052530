import update from "immutability-helper";
import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import {
  ButtonOutline,
  ButtonOrange,
  ButtonGroup
} from "components/Global/Modal/Layout/Buttons";
import Body from "components/Global/Modal/Layout/ScrollableBody";
import Group from "./ColumnGroup";

class EditColumnsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: this.wrapColumns(props.columns) || [],
      visibleColumns: props.columns.filter(c => c.visible)
    };
  }

  wrapColumns = columns => {
    if (columns.some(c => "columns" in c)) {
      return columns;
    }
    return [
      {
        header: "Fields",
        columns
      }
    ];
  };

  componentDidMount() {
    this.setState({
      columns: this.wrapColumns(this.props.columns)
    });
  }

  handleColumnChange = (column, index) => {
    this.setState(state => {
      state.columns[index] = column;
      return state;
    });
  };

  @autobind
  updateColumnOrder() {
    const columns = {};
    let offSet = 0;

    this.state.columns.forEach(group => {
      group.columns.forEach((c, index) => {
        columns[c.id] = index + offSet;
      });
      offSet += group.columns.length;
    });
    return columns;
  }

  @autobind
  saveColumns() {
    const data = {
      columnOrder: this.updateColumnOrder(),
      visibleColumns: []
        .concat(...this.state.columns.map(c => c.columns))
        .filter(c => c.visible)
        .map(c => c.id)
    };
    this.props.onSave(data);
    this.props.hideModal();
  }

  @autobind
  moveRow(groupIndex, dragIndex, hoverIndex) {
    const { columns } = this.state.columns[groupIndex];
    const dragColumn = columns[dragIndex];
    this.setState(state => {
      state.columns[groupIndex] = update(this.state.columns[groupIndex], {
        columns: {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragColumn]]
        }
      });
      return state;
    });
  }

  toggleVisibility = (groupIndex, ids, visible) =>
    this.setState(state => {
      state.columns[groupIndex].columns = state.columns[groupIndex].columns.map(
        c => {
          if (ids.includes(c.id)) {
            c.visible = visible;
          }
          return c;
        }
      );
      return state;
    });

  render() {
    let offSet = 0;
    return (
      <StyleWrapper
        hideModal={this.props.hideModal}
        heading="Show/Hide Fields"
        width={410}
      >
        <Body style={{ height: 400 }}>
          {this.state.columns.map((group, index) => {
            const Element = (
              <Group
                key={index}
                index={index}
                offSet={offSet}
                group={group}
                toggleColumnVisibility={this.toggleVisibility}
                updateColumnOrder={this.updateColumnOrder}
                moveRow={this.moveRow}
              />
            );
            offSet += group.columns.length;
            return Element;
          })}
        </Body>
        <ButtonGroup>
          <ButtonOrange onClick={this.saveColumns} title="Save" />
          <ButtonOutline onClick={this.props.hideModal} title="Cancel" />
        </ButtonGroup>
      </StyleWrapper>
    );
  }
}

EditColumnsModal.propTypes = {
  columns: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired
};

export default EditColumnsModal;
