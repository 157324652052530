import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { noop } from "utils/General";

import { actions } from "ui-kit/Form/model";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import { Editor } from "@tinymce/tinymce-react";

import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";

export const DEFAULT_MODULES = "table media link code lists paste image";
export const DEFAULT_FORMATS = `bold italic | link | bullist numlist outdent indent | image`;

// for more formaters check https://www.tiny.cloud/docs/integrations/react/

const decorate = R.compose(
  WithFormInstanceConsumer({ fieldType: FIELD_TYPES.VALUE }),
  connect(
    () => ({}),
    bindInstance({
      onChange: actions.setFieldValueRequest,
      onFocus: actions.focusField,
      onBlur: actions.blurField,
      setIniValue: actions.setIniValue,
      uploadWYSIWYGImg: actions.uploadWYSIWYGImg
    })
  )
);

export const AdvancedWYSIWYG = decorate(
  ({
    iniValue,
    onChange = noop,
    onFocus = noop,
    onBlur = noop,
    setIniValue = noop,
    uploadWYSIWYGImg = noop,
    fieldId,
    fieldType,
    modules = DEFAULT_MODULES,
    formats = DEFAULT_FORMATS,
    placeholder = "Content...",
    settings = {}
  }) => {
    const [newValue, setNewValue] = useState(iniValue);
    useEffect(() => {
      setIniValue(iniValue, {
        meta: { fieldType, fieldId }
      });
    }, []);

    const onEditorChange = val => {
      setNewValue(val);
      onChange(val, { meta: { fieldId, fieldType } });
    };

    return (
      <Editor
        // eslint-disable-next-line no-underscore-dangle
        apiKey={window.__TINY_MCE_API_KEY__}
        onFocus={() => onFocus(null, { meta: { fieldId, fieldType } })}
        onEditorChange={onEditorChange}
        onBlur={() => onBlur(null, { meta: { fieldId, fieldType } })}
        value={newValue}
        init={{
          placeholder,
          plugins: modules,
          toolbar: formats,
          relative_urls: false,
          remove_script_host: false,
          image_title: true,
          file_picker_callback: uploadWYSIWYGImg,
          ...{
            paste_as_text: true,
            menubar: false,
            branding: false,
            relative_urls: false,
            remove_script_host: false,
            image_caption: true,
            image_title: true,
            urlconverter_callback: function(url) {
              return url;
            },
            ...settings
          }
        }}
      />
    );
  }
);
