import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: false,
  managers: [],
  managersFilter: "",
  usersFilter: "",
  data: null,
  moduleId: null,
  typeId: null
};

const handlers = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    refreshData: R.identity,
    updateApprover: R.identity,
    cancelInstance: R.always(iniState),
    setInitialData: (state, { payload: { data, moduleId, typeId } }) => ({
      data,
      moduleId,
      typeId
    })
  },
  namespace: NAMESPACE
});

export default handlers;
