import React from "react";
import PropTypes from "prop-types";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

const EmptyState = CSSModules(
  ({ children }) => <div styleName="container">{children}</div>,
  css
);

EmptyState.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
};

export default EmptyState;
