import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import ModalBase from "components/Global/Modal/Layout/Base";
import Loading from "components/Global/Modal/Layout/Loading";
import SubTitle from "components/Event/InventoryV2/Layout/Sidebar/SubTitle";
import SubItem from "components/Event/InventoryV2/Layout/Sidebar/SubItem";
import AccountSelector from "components/Global/StandAloneEditors/Lookup";
import { accounts } from "@lennd/value-types/src/constants/standard-modules";
import Tab from "components/Global/CRM/Tabs/Tab";
import Tabs from "components/Global/CRM/Tabs";
import Details from "./Details";
import Summary from "./Summary";
import { get } from "lodash";

const AssetReportModal = ({
  activeAccountId,
  activeCategoryId,
  fetchReport,
  hideModal,
  isFetching,
  onAccountChange,
  onCategoryChange,
  report = {}
}) => {
  const categoryList = report.counts
    ? Object.keys(report.counts)
        .sort((a, b) =>
          report.counts[a].name
            .toLowerCase()
            .localeCompare(report.counts[b].name.toLowerCase())
        )
        .map(id => (
          <SubItem
            key={id}
            active={id === activeCategoryId}
            onClick={onCategoryChange(id)}
            style={{ margin: "1px 0" }}
          >
            <span styleName="sidebarLabel">{report.counts[id].name}</span>
          </SubItem>
        ))
    : [];

  return (
    <ModalBase
      style={{ width: 900, backgroundColor: "#813cd2" }}
      wrapperStyle={{ alignItems: "flex-start" }}
    >
      <div styleName="container">
        <div styleName="sidebar">
          <SubTitle style={{ paddingLeft: 0 }}>Account Name</SubTitle>
          <div styleName="selectWrapper" className="react-select-white">
            <AccountSelector
              onChange={onAccountChange}
              clearable={false}
              rowMetaData={{
                meta: {
                  columnSettings: {
                    moduleId: accounts.id,
                    fieldId: "e8f3dac1-cce5-42be-bab1-0a88e8c03a72"
                  }
                }
              }}
              value={{
                type: "lookup",
                value: {
                  moduleId: accounts.id,
                  records: [activeAccountId]
                }
              }}
            />
          </div>
          {categoryList.length ? (
            <span>
              <SubTitle style={{ paddingLeft: 0 }}>Asset Categories</SubTitle>
              {categoryList}
            </span>
          ) : (
            ""
          )}
        </div>
        {!categoryList.length && !isFetching ? (
          <div styleName="empty">
            No assets have been requested for this group.
          </div>
        ) : (
          <div styleName="content">
            <div styleName="heading">
              {get(report, ["counts", activeCategoryId, "name"], "")}
            </div>
            <Tabs
              style={{
                backgroundColor: "#FAFAFA",
                height: "calc(100% - 48px)",
                overflow: "hidden"
              }}
              labelsStyles={{ paddingLeft: 30, backgroundColor: "white" }}
            >
              <Tab label="Item Summary" onActive={fetchReport}>
                <div styleName="body" style={{ paddingLeft: 12 }}>
                  {isFetching ? (
                    <Loading />
                  ) : !get(report, ["counts", activeCategoryId]) &&
                    !isFetching ? (
                    <div styleName="empty">No report available</div>
                  ) : (
                    <Summary summary={report.counts[activeCategoryId]} />
                  )}
                </div>
              </Tab>

              <Tab label="Request Details">
                <div styleName="body">
                  {isFetching && !report.details ? (
                    <Loading />
                  ) : (
                    <Details
                      activeAccountId={activeAccountId}
                      categoryId={activeCategoryId}
                      details={report.details}
                    />
                  )}
                </div>
              </Tab>
            </Tabs>
          </div>
        )}

        <div styleName="close" onClick={hideModal}>
          <i className="material-icons">close</i>
        </div>
      </div>
    </ModalBase>
  );
};

AssetReportModal.propTypes = {
  activeAccountId: PropTypes.string.isRequired,
  activeCategoryId: PropTypes.string,
  fetchReport: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  onAccountChange: PropTypes.func.isRequired,
  onCategoryChange: PropTypes.func.isRequired,
  report: PropTypes.arrayOf(
    PropTypes.shape({
      module: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
      }),
      counts: PropTypes.object
    })
  ).isRequired
};

export default CSSModules(AssetReportModal, css);
