import React from "react";
import SubNav from "./SubNav";
import { createSelector } from "reselect";
import * as R from "ramda";
import { connect } from "react-redux";
import { TAB_IDS } from "./constants";
import { withRouter } from "react-router";
import PageTitle from "EventLight/Common/PageTitle";

import Schedules from "./Schedule/View";
// import Settings from "./Settings/View";

export const getCurrentRoute = createSelector(
  (_, props) => props.routes,
  routes => {
    if (R.any(R.propEq("name", "EventLightProductionSchedules"))(routes)) {
      return TAB_IDS.SCHEDULES;
    } else if (R.any(R.propEq("name", "EventLightScheduleSettings"))(routes)) {
      return TAB_IDS.SETTIGNS;
    }
    return null;
  }
);

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    currentRoute: getCurrentRoute(state, props)
  }))
);

const Wrapper = ({ currentRoute }) => {
  const Page = R.prop(currentRoute)({
    [TAB_IDS.SCHEDULES]: Schedules
    // [TAB_IDS.SETTINGS]: Settings
  });

  return (
    <>
      <PageTitle titles={["Schedules"]} />
      {/* <SubNav selectedView={currentRoute} /> */}
      <Page />
    </>
  );
};

export default decorate(Wrapper);
