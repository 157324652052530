import { REQUEST, RECEIVE } from "./constants";
import api from "./api";
import { isFetching } from "./selectors";
import { registerError } from "redux/modules/errors/actions";

export function getPermissionSets(eventId) {
  return async (dispatch, getState) => {
    if (isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const result = await api.get(getState().user.user.credentials, eventId);
      dispatch({ type: RECEIVE, payload: result.payload });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating permission set"
          }
        ])
      );
    }
  };
}

export function updateIntakeForm(data) {
  return async (dispatch, getState) => {
    try {
      return api.updateIntakeForm(getState().user.user.credentials, data);
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating intake form"
          }
        ])
      );
    }
  };
}
