import React from "react";
import EditorBase from "../TableConnector";
import Input from "components/Global/Editors/Phone";
import jQuery from "jquery";
import BeforeClose from "../BeforeClose";
import ReactDOM from "react-dom";

export default class PhoneEditor extends EditorBase {
  componentDidMount() {
    const node = ReactDOM.findDOMNode(this.focusRef);
    this.focusElement = this.findInput(node);
  }

  findInput(node) {
    if (node) {
      const inputs = jQuery(node)
        .find("input")
        .addBack("input");
      if (inputs.length) {
        return inputs[0];
      }
    }
  }

  handleClose = () => {
    // @NOTE: Currently BeforeClose never reaches this method. RDG unmounts it seems
    // before this is fired, thus tabs and enters do not save value.
    return this.props.onCommit();
  };

  render() {
    return (
      <div style={{ width: this.props.column.width - 3 }}>
        <BeforeClose do={this.handleClose}>
          <Input
            ref={c => (this.focusRef = c)}
            value={this.state}
            phoneDisplayType={this.props.column.settings.phoneDisplayType}
            onChange={this.handleChange}
          />
        </BeforeClose>
      </div>
    );
  }
}
