import PropTypes from "prop-types";
import React from "react";
import EditorBase from "../TableConnector";
import Date from "components/Global/Editors/DateEditor";
import jQuery from "jquery";
import ReactDOM from "react-dom";
import BeforeClose from "../BeforeClose";

export default class DateEditor extends EditorBase {
  componentDidMount() {
    const node = ReactDOM.findDOMNode(this.focusRef);
    this.focusElement = this.findInput(node);
  }

  findInput(node) {
    if (node) {
      const inputs = jQuery(node)
        .find("input")
        .addBack("input");
      if (inputs.length) {
        return inputs[0];
      }
    }
  }

  handleClose = () => {
    this.props.onCommit();
  };

  render() {
    return (
      <div style={{ width: this.props.column.width - 3 }}>
        <BeforeClose do={this.handleClose}>
          <Date
            {...this.props}
            ref={c => (this.focusRef = c)}
            displayType={this.props.column.settings.displayType}
            value={this.state}
            onChange={this.handleChange}
          />
        </BeforeClose>
      </div>
    );
  }
}

DateEditor.propTypes = {
  ...EditorBase.propTypes,
  showTodayAsDefault: PropTypes.bool
};
