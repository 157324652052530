import React from "react";
import { SubNavWrapper, SubNavLink } from "EventLight/Common/SubNav";
import { TAB_IDS } from "./constants";
import { withRouter } from "react-router";
import CanUserDo from "components/Global/Security/CanUserDo";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

const SubNav = ({ selectedView, router, params, tabs = [] }) => (
  <SubNavWrapper tabs={tabs} supportId="catering">
    <SubNavLink
      name="Dashboard"
      active={selectedView === TAB_IDS.DASHBOARD}
      onClick={() =>
        router.push({
          pathname: `/event-light/${params.eventId}/catering`
        })
      }
    />
    <SubNavLink
      name="Manage"
      active={selectedView === TAB_IDS.REQUESTS}
      onClick={() =>
        router.push({
          pathname: `/event-light/${params.eventId}/catering/requests/all-requests`
        })
      }
    />
    <SubNavLink
      name="Send Emails"
      active={selectedView === TAB_IDS.SEND_EMAILS}
      onClick={() =>
        router.push({
          pathname: `/event-light/${params.eventId}/catering/send-emails`
        })
      }
    />
    <CanUserDo action={`${STANDARD_MODULE_IDS.catering.id}_checkin`}>
      <SubNavLink
        name="Check In"
        active={false}
        onClick={() => {
          window.open(`/event/${params.eventId}/catering/checkin`, "_blank");
        }}
      />
    </CanUserDo>
    <CanUserDo action={`${STANDARD_MODULE_IDS.catering.id}_manage`}>
      <SubNavLink
        name="Settings"
        active={[TAB_IDS.MEALS, TAB_IDS.APPROVERS, TAB_IDS.OVERRIDE].includes(
          selectedView
        )}
        onClick={() =>
          router.push({
            pathname: `/event-light/${params.eventId}/catering/settings`
          })
        }
      />
    </CanUserDo>
  </SubNavWrapper>
);

export default withRouter(SubNav);
