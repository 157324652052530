import PropTypes from "prop-types";
import React from "react";
import moment from "moment";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Timestamp = ({ stamp }) => (
  <div styleName="timestamp">{moment(stamp).fromNow()}</div>
);

Timestamp.propTypes = {
  stamp: PropTypes.string.isRequired
};

export default CSSModules(Timestamp, css);
