import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const ReturnToSearch = ({ returnToSearch }) => (
  <div>
    <div styleName="returnToLink" onClick={returnToSearch}>
      <i className="material-icons">keyboard_arrow_left</i>
      back to results
    </div>
  </div>
);

ReturnToSearch.propTypes = {
  returnToSearch: PropTypes.func.isRequired
};

export default CSSModules(ReturnToSearch, css);
