import PropTypes from "prop-types";
import React from "react";
import * as R from "ramda";
import autobind from "autobind-decorator";
import Label from "components/Global-2016/Forms/Label";
import Editor from "components/Global/Editors/TextArea";
import FormBaseInput from "../FormBaseInput";
import FormInputWrapper from "components/Event/FormsV2/Sections/FormInputWrapper";
import isRequiredValid from "utils/value-types/validations/text/required";
import InputGroup from "components/Global-2016/Forms/InputGroup";
import { isEqual } from "lodash";
import LabelSimpleForm from "components/Event/FormsV2/Sections/LabelSimpleForm/view";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FormTextarea extends FormBaseInput {
  constructor(props) {
    super(props);

    this.state = {
      value: props.formValues[props.field.id],
      isShown: false
    };
  }

  componentWillUpdate(nextProps) {
    if (
      !this.props.isEditing &&
      !isEqual(
        this.props.formValues[this.props.field.id],
        nextProps.formValues[this.props.field.id]
      )
    ) {
      this.setState({
        value: nextProps.formValues[this.props.field.id]
      });
    }
  }

  @autobind
  handleChange(value) {
    this.setState({ value });
  }

  @autobind
  handleSave() {
    this.save(this.props.field.id, this.state.value);
  }

  @autobind
  handleToogleShowEditMode() {
    this.setState({
      isShown: R.not(this.state.isShown)
    });
  }

  @autobind
  async isValid() {
    const errors = [];
    const required = this.props.field.is_required;
    const value = this.state.value;
    if (required && !isRequiredValid(value)) {
      errors.push("This is a required question");
    }
    this.setState({
      errors
    });
    return !errors.length;
  }

  render() {
    let handleSave;
    const {
      field: {
        name,
        settings: { description, displayType, isAdminField, placeholder }
      },
      connectDragSource,
      canShowSimpleSideBar
    } = this.props;
    const required = this.props.field.is_required;
    const readonly = this.props.field.is_readonly;

    const maxLength = R.pathOr(
      "",
      ["field", "settings", "maxLength"],
      this.props
    );

    if (!this.props.isEditing) {
      handleSave = this.handleSave;
    }

    return (
      <FormInputWrapper
        isEditing={this.props.isEditing}
        isValid={this.state.isValid}
        errorMessages={this.state.errors}
        fieldData={this.props.field}
        instanceId={this.props.field.id}
        isShown={this.state.isShown}
        setIsShown={() => this.handleToogleShowEditMode()}
        connectDragSource={connectDragSource}
      >
        <InputGroup style={this.props.groupStyles}>
          {name ? (
            !canShowSimpleSideBar ? (
              <Label
                isAdminField={isAdminField}
                required={required}
                description={description}
                readonly={readonly}
              >
                {name}
              </Label>
            ) : (
              <LabelSimpleForm
                isAdminField={isAdminField}
                required={required}
                description={description}
                readonly={readonly}
                instanceId={this.props.field.id}
                fieldData={this.props.field}
                isShown={this.state.isShown}
                setIsShown={() => this.handleToogleShowEditMode()}
              >
                {name}
              </LabelSimpleForm>
            )
          ) : null}
          <div styleName="picker">
            <Editor
              required={required}
              disabled={this.props.disabled}
              placeholder={placeholder}
              value={this.state.value}
              displayType={displayType}
              onChange={this.handleChange}
              onBlur={handleSave}
              maxLength={maxLength}
            />
          </div>
          {!this.props.disabled && maxLength ? (
            <div styleName="charLimit">
              {R.pathOr("", ["value", "value"], this.state).length}/{maxLength}{" "}
              Character
              {R.pathOr("", ["value", "value"], this.state).length !== 1
                ? "s"
                : ""}
            </div>
          ) : null}
        </InputGroup>
      </FormInputWrapper>
    );
  }
}

FormTextarea.propTypes = {
  groupStyles: PropTypes.object,
  formValues: PropTypes.object,
  field: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  updateFormValue: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default FormTextarea;
