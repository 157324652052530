import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Home from "./Home";

import { getCategoryOverviewReport } from "redux/modules/inventory/reports/category-overview/actions";
import {
  categoryOverviewReport,
  isFetching
} from "redux/modules/inventory/reports/category-overview/selectors";
import { hideModal, showModal } from "redux/modules/modal/actions";

function mapStateToProps(state, props) {
  return {
    categories: categoryOverviewReport(state, props.params.eventId),
    isFetching: isFetching(state, props.params.eventId)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getCategoryOverviewReport,
      hideModal,
      showModal
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
