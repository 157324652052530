import React from "react";
import * as R from "ramda";
import { Text2, Div, MediumOutlineButton, WarningIcon } from "components/Base";

import MessageCard from "ui-kit/MessageCard";
import Circle from "ui-kit/Circle";

import { noop } from "utils/General";

const MESSAGE_TYPES = {
  ADMIN: "admin",
  MESSAGE: "message",
  NOTE: "note",
  ALERT: "alert"
};

const colorMap = {
  [MESSAGE_TYPES.ADMIN]: "info5",
  [MESSAGE_TYPES.MESSAGE]: "neutral2",
  [MESSAGE_TYPES.NOTE]: "orange1",
  [MESSAGE_TYPES.ALERT]: "altB3"
};

const getBorderStyles = position =>
  position === "right"
    ? {
        brl: 2
      }
    : {
        brr: 2
      };

const Message = ({ children, position, type, ...styleProps }) => (
  <Div
    brt={2}
    p={3}
    pl={4}
    {...getBorderStyles(position)}
    bg={colorMap[type] || colorMap[MESSAGE_TYPES.NOTE]}
    style={{ maxWidth: 450, whiteSpace: "pre-line" }}
    {...styleProps}
  >
    {children}
  </Div>
);

const renderMessageMap = {
  [MESSAGE_TYPES.ALERT]: ({
    message,
    type,
    footer,
    position,
    id,
    handlers = { updateAddress: noop, markResolved: noop }
  }) => (
    <MessageCard
      icon={
        <Circle size={32}>
          <WarningIcon size={20} color="altB4" />
        </Circle>
      }
      position={position}
      mb={2}
    >
      <Div display="column.flex-start.flex-start" mx={3}>
        <Message type={type} position={position}>
          <Text2 color="neutral8">{message}</Text2>
          <Div mt={2} display="row.flex-start.center">
            {type === "GBS_UPDATE_ADDRESS" ? (
              <MediumOutlineButton
                ba={1}
                bc="neutral3"
                mr={2}
                onClick={() => handlers.updateAddress(id)}
              >
                Update Address
              </MediumOutlineButton>
            ) : null}
            <MediumOutlineButton
              ba={1}
              bc="neutral3"
              onClick={() => handlers.markResolved(id)}
            >
              Mark Resolved
            </MediumOutlineButton>
          </Div>
        </Message>
        <Text2 mt={1} color="neutral5">
          {footer}
        </Text2>
      </Div>
    </MessageCard>
  ),
  [MESSAGE_TYPES.MESSAGE]: ({
    message,
    type,
    avatar,
    icon,
    footer,
    position
  }) => (
    <MessageCard avatar={avatar} icon={icon} position={position} mb={2}>
      <Div display="column.flex-start.flex-start" mx={3}>
        <Message type={type} position={position}>
          <Text2 color="black">{message}</Text2>
        </Message>
        <Text2 mt={1} color="neutral5">
          {footer}
        </Text2>
      </Div>
    </MessageCard>
  ),
  [MESSAGE_TYPES.NOTE]: ({ message, type, avatar, icon, footer, position }) => (
    <MessageCard avatar={avatar} icon={icon} position={position} mb={2}>
      <Div display="column.flex-start.flex-start" mx={3}>
        <Message type={type} position={position}>
          <Text2 color="black">{message}</Text2>
        </Message>
        <Text2 mt={1} color="neutral5">
          {footer}
        </Text2>
      </Div>
    </MessageCard>
  )
};

const MessageFeed = ({ messages, handlers }) => (
  <Div>
    {R.map(
      message =>
        React.createElement(
          renderMessageMap[message.type] ||
            renderMessageMap[MESSAGE_TYPES.NOTE],
          { ...message, handlers }
        ),
      messages
    )}
  </Div>
);

export default MessageFeed;
