import api from "./api";
import { registerError } from "redux/modules/errors/actions";
import { SHOW, HIDE } from "./constants";

export function addVisibleField(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SHOW,
      payload: {
        moduleId: data.moduleId,
        fieldId: data.field.fieldId,
        fieldGroupId: data.field.fieldGroupId
      }
    });
    try {
      const result = await api.post(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding visible field"
          }
        ])
      );
    }
  };
}

export function deleteVisibleField(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: HIDE,
      payload: {
        moduleId: data.moduleId,
        fieldId: data.field.fieldId,
        fieldGroupId: data.field.fieldGroupId
      }
    });
    try {
      const result = await api.delete(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting visible field"
          }
        ])
      );
    }
  };
}
