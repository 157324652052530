import React from "react";
import CSSModules from "react-css-modules";
import css from "Schedules/Schedule/View/styles.scss";
import { connect } from "react-redux";

import { Div, Text2, PeopleIcon, TinyFilledButton } from "components/Base";

import { getEditorsCount } from "Schedules/Schedule/selectors";
import { actions } from "Schedules/Schedule";

const decorate = connect(
  (state, props) => ({
    editorsCount: getEditorsCount(state, props)
  }),
  { openAllowAccessModal: () => actions.showEditorsModal() }
);

const Editors = ({ editorsCount, openAllowAccessModal }) => {
  return (
    <Div
      display="row.center.center"
      py={2}
      onClick={openAllowAccessModal}
      bra={1}
      className={css.button2}
    >
      <Div flex={1} display="row.flex-start.center" pl={3}>
        <Div display="row.flex-start.center" mb={1}>
          <PeopleIcon mr={2} color="purple4" />
          <Text2 color="white" bold>
            Editors
          </Text2>
        </Div>
      </Div>
      <TinyFilledButton
        color="white"
        bg="primary6"
        mx={3}
        onClick={openAllowAccessModal}
      >
        {editorsCount}
      </TinyFilledButton>
    </Div>
  );
};

export default CSSModules(decorate(Editors), css);
