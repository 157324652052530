import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import styles from "./styles.scss";

const Sidebar = CSSModules(
  ({ children }) => <aside styleName="container">{children}</aside>,
  styles
);

Sidebar.propTypes = {
  children: PropTypes.node
};

export { Sidebar as default };

export const Header = CSSModules(
  ({ children }) => <h2 styleName="header">{children}</h2>,
  styles
);

Header.propTypes = {
  children: PropTypes.node
};

export const Item = CSSModules(
  ({ isActive, children, ...other }) => (
    <button styleName={isActive ? "itemActive" : "item"} {...other}>
      {children}
    </button>
  ),
  styles
);

Item.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.node
};
