import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Panel from "./Panel";
import Toolbar from "./Toolbar";
import Loading from "components/Global/Modal/Layout/Loading";

const cachedRecords = (moduleId, needRecords, getRecords) => {
  const recordsFromState = getRecords(moduleId);
  if (recordsFromState.length) {
    const needRecordIds = needRecords.map(r => r.id);
    return recordsFromState.filter(r => needRecordIds.includes(r.id));
  }
  return needRecords;
};

@CSSModules(css)
class ItemSummaryNeeds extends Component {
  state = {
    statusFilter: undefined,
    searchTerm: null
  };

  componentDidMount() {
    this.fetchNeeds();
  }

  fetchNeeds = () => {
    const { getNeeds, params, catalogItem } = this.props;
    return getNeeds({ eventId: params.eventId, catalogItemId: catalogItem.id });
  };

  setStatusFilter = statusFilter => this.setState({ statusFilter });

  handleSearchChange = e => this.setState({ searchTerm: e.target.value });

  render() {
    const { isFetching, needs, catalogItem, getRecords } = this.props;

    return (
      <div>
        <Toolbar
          onStatusFilterChange={this.setStatusFilter}
          onSearchChange={this.handleSearchChange}
          searchTerm={this.state.searchTerm}
          activeFilter={this.state.statusFilter}
          catalogItem={catalogItem}
        />
        {isFetching ? (
          <Loading />
        ) : (
          needs.map(need => (
            <Panel
              key={need.module.id}
              defaultOpen
              parent={need.module}
              fetchParents={this.fetchNeeds}
              status={this.state.statusFilter} // required for the approval footer
              records={cachedRecords(need.module.id, need.records, getRecords)}
              searchTerm={this.state.searchTerm}
            />
          ))
        )}
      </div>
    );
  }
}

ItemSummaryNeeds.propTypes = {
  catalogItem: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.string
  }).isRequired,
  params: PropTypes.shape({
    eventId: PropTypes.string.isRequired
  }).isRequired,
  getNeeds: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  needs: PropTypes.array.isRequired,
  getRecords: PropTypes.func.isRequired
};

export default ItemSummaryNeeds;
