import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "./constants";

const model = createHandlers({
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
