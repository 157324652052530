import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Api from "utils/EventContacts/WebAPIUtils";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class ContactSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCreatingNewDestination: false,
      newDestinationName: ""
    };
  }

  @autobind
  getOptions() {}

  @autobind
  addDestination() {
    const { newDestinationName } = this.state;
    if (newDestinationName.length === 0) {
      return null;
    }
    return Api.addContactList(
      { userId: 0 },
      {
        eventId: this.props.eventId,
        name: newDestinationName
      }
    ).then(newDestination => {
      this.selectDestinationId(newDestination.id);
      this.setState({
        isCreatingNewDestination: false,
        newDestinationName: ""
      });
      return null;
    });
  }

  @autobind
  selectDestinationId(destinationId) {
    this.props.updateContactSettings({
      ...this.props.settings,
      destinationId
    });
  }

  @autobind
  toggleField(property) {
    if (this.props.editing) {
      return false;
    }
    this.props.updateContactSettings({
      ...this.props.settings,
      [property]: !this.props.settings[property]
    });
  }

  render() {
    const { settings } = this.props;
    const { isCreatingNewDestination, newDestinationName } = this.state;

    return (
      <div>
        <div styleName="optionsTitle">Contact Options</div>

        <div styleName="destinationSelectWrapper">
          <div styleName="destinationSelectLabel">
            Contacts will save to list:
          </div>
          {/* create new destination */}
          {isCreatingNewDestination ? (
            <div>
              <input
                key="name"
                styleName="input"
                type="text"
                value={newDestinationName}
                placeholder="Name the list..."
                onChange={e =>
                  this.setState({ newDestinationName: e.target.value })
                }
              />
              <div styleName="addNewDestinationActions">
                <div
                  styleName="addNewDestination"
                  onClick={this.addDestination}
                >
                  Add
                </div>
                <div
                  styleName="cancelNewDestination"
                  onClick={() =>
                    this.setState({ isCreatingNewDestination: false })
                  }
                >
                  Cancel
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

ContactSettings.propTypes = {
  eventId: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  updateContactSettings: PropTypes.func.isRequired,
  editing: PropTypes.bool
};

export default ContactSettings;
