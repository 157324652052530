import React from "react";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import {
  ButtonGroup,
  Submit,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";
import TemplateSelect from "./TemplateSelect";
import Snackbar from "ui-kit/Snackbar/View";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import { noop } from "utils/General";

import { connect } from "react-redux";
import { Div } from "components/Base";
import { getters, actions } from "../index";

const decorate = connect(
  state => ({
    selectedTemplate: getters.selectedTemplate(state),
    templates: getters.templates(state),
    creating: getters.creating(state),
    showDetailsErrorsModal: getters.showDetailsErrorsModal(state),
    generateDetailsErrors: getters.generateDetailsErrors(state)
  }),
  {
    onTemplateSelect: actions.setSelectedTemplate,
    createDocuments: actions.createDocuments,
    hideDetailsErrorsModal: () => actions.setShowDetailsErrorsModal(false)
  }
);

const Layout = ({
  handlers = {},
  hideModal,
  selected = [],
  templates = [],
  selectedTemplate,
  onTemplateSelect,
  creating,
  createDocuments = noop,
  hideDetailsErrorsModal = noop,
  showDetailsErrorsModal = false,
  generateDetailsErrors = []
}) => (
  <StyleWrapper
    hideModal={hideModal}
    heading={`Generate Document${selected.length > 1 ? "s" : ""}`}
    width={410}
    containerStyles={{ overflowY: "visible" }}
  >
    <span>
      <form
        onSubmit={e => {
          e.preventDefault();
          createDocuments({ onDone: handlers.onDone });
        }}
      >
        <TemplateSelect
          templates={templates}
          selected={selectedTemplate}
          onSelect={onTemplateSelect}
        />
        <br />
        <ButtonGroup>
          <Submit
            type="submit"
            disabled={creating || !selectedTemplate}
            title={
              creating ? (
                <span>
                  <i
                    className={[
                      "fa",
                      "fa-circle-o-notch",
                      css.processingIcon
                    ].join(" ")}
                  />
                  Creating
                </span>
              ) : (
                `Generate Document${selected.length > 1 ? "s" : ""}`
              )
            }
          />
          <ButtonOutline onClick={hideModal} title="Cancel" />
        </ButtonGroup>
      </form>
    </span>
    <Snackbar />
    <MiniModalWrapper
      showModal={showDetailsErrorsModal}
      hideModal={hideDetailsErrorsModal}
      title="Generate Document Error Details"
      width={650}
      style={{
        height: "calc(100vh - 100px)",
        maxHeight: "400px",
        backgroundColor: "white"
      }}
      stopPropagation
    >
      <Div
        p={5}
        style={{
          overflowY: "auto"
        }}
      >
        <ul>
          {R.map(
            error => (
              <li>{error}</li>
            ),
            generateDetailsErrors
          )}
        </ul>
      </Div>
    </MiniModalWrapper>
  </StyleWrapper>
);

export default CSSModules(decorate(Layout), css);
