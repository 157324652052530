import { REQUEST, RECEIVE } from "./constants";
import * as selectors from "./selectors";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getModule(data) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({
        type: REQUEST,
        payload: {
          moduleId: data.moduleId
        }
      });
      const result = await api.get(getState().user.user.credentials, data);
      dispatch({
        type: RECEIVE,
        payload: {
          moduleId: data.moduleId,
          module: result.module
        }
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching module"
          }
        ])
      );
    }
  };
}

export function addModule(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post(getState().user.user.credentials, data);
      return result.module;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding module"
          }
        ])
      );
    }
  };
}

export function updateModule(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.put(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating module"
          }
        ])
      );
    }
  };
}

export function deleteModule(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.delete(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting module"
          }
        ])
      );
    }
  };
}

export function copyModule(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.copy(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred copying module"
          }
        ])
      );
    }
  };
}
