import {
  all,
  takeEvery,
  fork,
  put,
  select,
  call,
  take
} from "redux-saga/effects";
import { actions, getters } from "Portal/PortalPeople/model";
import { registerError } from "redux/modules/errors/actions";
import { actions as TabActions } from "Portal/Tabs";
import {
  actions as TableActions,
  getters as TableGetters
} from "ui-kit/Table/model";
import { getCredentials } from "redux/modules/user/selectors";
import { eventDetails as getEventDetails } from "redux/modules/portal/selectors";
import { portalUser as getPortalUser } from "redux/modules/portal/user/selectors";
import { userList, getTableIds } from "Portal/PortalPeople/selectors";
import { actions as SearchBarActions } from "ui-kit/SearchBar";
import { actions as TabsActions } from "Portal/Tabs";
import { eventId as getEventId } from "redux/modules/portal/selectors";
import { showSnackbar } from "redux/modules/snackbar/actions";

import * as R from "ramda";
import Api from "./api";

import {
  FILTER,
  COLUMN_WIDTHS,
  CONTACTS_TABLE_INSTANCE_ID,
  COLUMN_HEADERS,
  PEOPLE_FILTERS,
  ROW_ACTIONS_ITEMS
} from "Portal/PortalPeople/constants";

const setSingleTable = function*() {
  const data = yield select(userList);
  yield put(
    TableActions.setData(
      {
        columns: COLUMN_HEADERS,
        rows: R.map(R.prop("values"))(data),
        columnWidths: COLUMN_WIDTHS,
        canEditCells: false
      },
      {
        meta: {
          instanceId: CONTACTS_TABLE_INSTANCE_ID
        }
      }
    )
  );
};

const setGroupedTables = function*() {
  const data = yield select(userList);
  yield all(
    R.map(
      type =>
        put(
          TableActions.setData(
            {
              columns: COLUMN_HEADERS,
              rows: R.map(R.prop("values"))(R.propOr([], "contact_ids", type)),
              columnWidths: COLUMN_WIDTHS,
              canEditCells: false
            },
            {
              meta: {
                instanceId: type.id
              }
            }
          )
        ),
      data.types
    )
  );
};

const refreshData = function*() {
  const credentials = yield select(getCredentials);
  const eventDetails = yield select(getEventDetails);
  const portalUser = yield select(getPortalUser);
  const eventId = yield select(getEventId);

  const accountType = R.pathEq(["active_view", "type"], "account")(portalUser);
  const accountId = accountType
    ? R.path(["active_view", "id"], portalUser)
    : R.prop(["user_contact_id"], portalUser);

  try {
    const { payload } = yield call(Api.getContacts, {
      credentials,
      org_id: eventDetails.org_id,
      accountId,
      excludeEventId: eventId
    });
    const response = yield call(Api.getPeopleTypes, {
      credentials,
      accountId,
      eventId
    });
    const availableTypes = R.filter(
      group => group.is_enabled,
      response.payload || []
    );

    yield put(
      actions.setData({
        ...payload,
        types: availableTypes
      })
    );
  } catch (error) {
    put(
      registerError([
        {
          system: error,
          user: "An error occurred loading portal people"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const init = function*() {
  yield put(TabActions.setSelectedTab(FILTER.THIS_EVENT));
  yield call(refreshData);
};

const onSave = function*() {
  yield put(actions.setSaving(true));

  const credentials = yield select(getCredentials);
  const eventDetails = yield select(getEventDetails);
  const portalUser = yield select(getPortalUser);
  const eventId = yield select(getEventId);
  const contactIds = yield select(getters.selectedIds);
  const recordTypeId = yield select(getters.selectedTypeId);
  const addCustomRole = yield select(getters.addCustomRole);
  const role = yield select(getters.role);
  const markAsPrimaryContact = yield select(getters.markAsPrimaryContact);

  const accountType = R.pathEq(["active_view", "type"], "account")(portalUser);
  const accountId = accountType
    ? R.path(["active_view", "id"], portalUser)
    : R.prop(["user_contact_id"], portalUser);

  try {
    yield call(Api.addContactsToEvent, {
      credentials,
      orgId: eventDetails.org_id,
      accountId,
      data: {
        accountId,
        eventId,
        contactIds,
        recordTypeId,
        role: addCustomRole ? role : null,
        addAsPrimaryContact: markAsPrimaryContact
      }
    });

    yield put(actions.setSelectedIds([]));
    yield put(actions.setIsAddUserModalVisible(false));
    yield put(TabActions.setSelectedTab(FILTER.THIS_EVENT));
    yield put(
      showSnackbar({
        message: `${contactIds.length} ${
          contactIds.length === 1 ? "person" : "people"
        } added`
      })
    );
  } catch (error) {
    put(
      registerError([
        {
          system: error,
          user: "An error occurred adding to event"
        }
      ])
    );
  } finally {
    yield put(actions.setSaving(false));
  }
};

const removeAllSelectedIds = function*() {
  const instaceIdList = yield select(getTableIds);
  yield all([
    put(actions.selectIds([])),
    ...R.map(id =>
      put(
        TableActions.clearSelectedRows(null, {
          meta: {
            instanceId: id
          }
        })
      )
    )(instaceIdList)
  ]);
};

const selectFilter = function*() {
  const filter = yield select(getters.filterby);
  if (filter === PEOPLE_FILTERS.BY_EVENT) {
    yield all([call(removeAllSelectedIds), call(setGroupedTables)]);
  } else {
    yield all([call(removeAllSelectedIds), call(setSingleTable)]);
  }
};

const watchRowSelection = function*() {
  for (;;) {
    const action = yield take(TableActions.toggleRow.type);
    const instaceIdList = yield select(getTableIds);
    if (
      R.any(instanceId => instanceId === action.meta.instanceId, instaceIdList)
    ) {
      const selectedIdsFromTables = yield all(
        R.map(id => select(TableGetters.toggledRows, { instanceId: id }))(
          instaceIdList
        )
      );
      yield put(
        actions.selectIds(R.reduce(R.concat, [], selectedIdsFromTables))
      );
    }
  }
};

const watchSelectAll = function*() {
  for (;;) {
    const action = yield take(TableActions.toggleAllRows.type);
    const instaceIdList = yield select(getTableIds);
    if (
      R.any(instanceId => instanceId === action.meta.instanceId, instaceIdList)
    ) {
      const selectedIdsFromTables = yield all(
        R.map(id => select(TableGetters.toggledRows, { instanceId: id }))(
          instaceIdList
        )
      );
      yield put(
        actions.selectIds(R.reduce(R.concat, [], selectedIdsFromTables))
      );
    }
  }
};

const selectAllCategory = function*({ payload }) {
  yield put(
    TableActions.toggleAllRows(null, { meta: { instanceId: payload } })
  );
};

const searchTerm = function*() {
  const filter = yield select(getters.filterby);
  if (filter === PEOPLE_FILTERS.BY_EVENT) {
    yield call(setGroupedTables);
  } else {
    yield call(setSingleTable);
  }
};

const setSelectedTab = function*({ payload }) {
  const filter = yield select(getters.filterby);
  if (payload === FILTER.PREVIOUS_EVENTS) {
    yield put(actions.setLoading(true));
    yield call(refreshData);
  }
  if (payload === FILTER.PREVIOUS_EVENTS && filter === PEOPLE_FILTERS.ALL) {
    yield call(setSingleTable);
  }
};

const showAddToEventModal = function*({ payload: { row } }) {
  yield put(actions.setSelectedIds([row.id]));
  yield put(actions.setIsAddUserModalVisible(true));
};

const watchTabChange = function*() {
  yield takeEvery(actions.selectFilter.type, selectFilter);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchRemoveAllSelectedIds = function*() {
  yield takeEvery(actions.removeAllSelectedIds.type, removeAllSelectedIds);
};

const watchSelectAllCategory = function*() {
  yield takeEvery(actions.selectAllCategory.type, selectAllCategory);
};

const watchSearchBar = function*() {
  yield takeEvery(SearchBarActions.setSearchTerm.type, searchTerm);
};

const watchSetSelectedTab = function*() {
  yield takeEvery(TabsActions.setSelectedTab.type, setSelectedTab);
};

const watchOnSave = function*() {
  yield takeEvery(actions.onSave.type, onSave);
};

const watchTableActions = function*() {
  for (;;) {
    const action = yield take(TableActions.executeAction.type);
    const delegate = R.prop(action.payload.actionId, {
      [ROW_ACTIONS_ITEMS.ADD_TO_EVENT]: showAddToEventModal
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchTabChange),
    fork(watchRemoveAllSelectedIds),
    fork(watchSelectAllCategory),
    fork(watchSearchBar),
    fork(watchSetSelectedTab),
    fork(watchSelectAll),
    fork(watchRowSelection),
    fork(watchOnSave),
    fork(watchTableActions)
  ]);
};

export default rootSaga;
