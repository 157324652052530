import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as R from "ramda";

import { getters, actions } from "Schedules/ScheduleViewPicker";

import SearchBar from "ui-kit/SearchBar/View";

import {
  Div,
  BigFilledButton,
  AddIcon,
  Text3,
  Text1,
  VisibleIcon,
  MoreVertIcon,
  SmallTextButton
} from "components/Base";

import { VARIANTS } from "ui-kit/Theme/constants";
import {
  BUTTONS_TYPE,
  NAMESPACE
} from "Schedules/ScheduleViewPicker/constants";
import { VIEWPICKER_INSTANCE_ID } from "Schedules/Schedule/constants";

import {
  getFilteredIndividualSchedules,
  getMasterSchedule,
  getActiveSchedule
} from "Schedules/ScheduleViewPicker/selectors";

import ViewPickerTabs from "./Tabs";
import Header from "./Header";
import ActionsButton from "./ActionsButton";

const MoreButton = ({ meta }) => (
  <ActionsButton
    label={({ onClick }) => (
      <MoreVertIcon
        size={20}
        onClick={e => {
          onClick(e);
        }}
      />
    )}
    buttonType={BUTTONS_TYPE.SCHEDULES}
    meta={meta}
  />
);

const ViewsButton = ({ meta }) => (
  <ActionsButton
    label={({ onClick }) => (
      <SmallTextButton
        isPadded={false}
        onClick={onClick}
        bold
        color="neutral8"
        LeftIcon={() => <VisibleIcon color="neutral8" size={16} mr={1} />}
      >
        {meta.views.length}
      </SmallTextButton>
    )}
    buttonType={BUTTONS_TYPE.VIEWS}
    meta={meta}
  />
);

const RightMultiButtonsCard = ({ children, buttons = null, ...props }) => (
  <Div
    bra={1}
    bg="white"
    display="row.space-between.flex-start"
    shadow={{ default: 1, hover: 2 }}
    pl={4}
    pr={2}
    py={3}
    my={2}
    {...props}
  >
    <Div flex={1}>{children}</Div>
    <Div flex={0} display="row.flex-start.flex-start">
      {buttons}
    </Div>
  </Div>
);

const renderIndividualSchedules = (
  selectSchedule,
  selectViewOfSchedule,
  activeScheduleId,
  individualSchedules,
  closeSidebar
) =>
  R.map(
    ({ id, name, updatedAt, views }) => (
      <RightMultiButtonsCard
        key={id}
        ba={1}
        bl={id === activeScheduleId ? 4 : 1}
        bc={id === activeScheduleId ? "purple8" : "neutral3"}
        width={0.48}
        height={70}
        onClick={event => {
          if (event.target.className.includes("invisible")) {
            return;
          }
          selectSchedule({ scheduleId: id });
          closeSidebar();
        }}
        buttons={
          <>
            {!R.isEmpty(views) && (
              <ViewsButton meta={{ views, scheduleId: id }} />
            )}
            {id !== "master" ? <MoreButton meta={{ id: id }} /> : null}
          </>
        }
      >
        <Text3 bold width={1}>
          {name}
        </Text3>
        {updatedAt ? <Text1>Updated {updatedAt}</Text1> : null}
      </RightMultiButtonsCard>
    ),
    individualSchedules
  );

const renderViewWithSelected = (
  selectedViewId,
  masterScheduleId,
  selectViewOfSchedule,
  closeSidebar
) => ({ id, name }) => (
  <RightMultiButtonsCard
    key={id}
    ba={1}
    bl={id === selectedViewId ? 4 : 1}
    bc={id === selectedViewId ? "purple8" : "neutral3"}
    width={1}
    onClick={
      id === selectedViewId
        ? undefined
        : () => {
            selectViewOfSchedule({
              moduleId: masterScheduleId,
              viewId: id
            });
            closeSidebar();
          }
    }
    buttons={null}
    shadow={id === selectedViewId ? 0 : { default: 1, hover: 2 }}
  >
    <Text3 bold width={1} primary={id === selectedViewId}>
      {name}
    </Text3>
  </RightMultiButtonsCard>
);

const renderActiveSchedule = (
  { id, name, updatedAt, views },
  selectedViewId,
  activeScheduleId,
  selectSchedule,
  selectViewOfSchedule,
  closeSidebar
) => (
  <RightMultiButtonsCard
    ba={1}
    bl={id === activeScheduleId ? 4 : 1}
    bc={id === activeScheduleId ? "purple8" : "neutral3"}
    width={1}
    shadow={0}
    buttons={id !== "master" ? <MoreButton meta={{ id: id }} /> : null}
  >
    <Text3 bold width={1}>
      {name}
    </Text3>
    {updatedAt ? <Text1>Updated {updatedAt}</Text1> : null}
    {R.map(
      renderViewWithSelected(
        selectedViewId,
        id,
        selectViewOfSchedule,
        closeSidebar
      ),
      views
    )}
  </RightMultiButtonsCard>
);

const decorate = connect(
  (state, props) => ({
    activeSchedule: getActiveSchedule(state, props),
    masterSchedule: getMasterSchedule(state, props),
    activeViewId: getters.activeViewId(state, props),
    activeScheduleId: getters.activeScheduleId(state, props),
    individualSchedules: getFilteredIndividualSchedules(state, props)
  }),
  {
    selectSchedule: actions.selectSchedule,
    selectViewOfSchedule: actions.selectViewOfSchedule,
    closeSidebar: () =>
      actions.setIsDrawerOpen(false, {
        meta: { instanceId: VIEWPICKER_INSTANCE_ID }
      }),
    createSchedule: actions.editSettings
  }
);

const Body = ({
  activeSchedule,
  activeViewId,
  activeScheduleId,
  individualSchedules,
  selectSchedule,
  selectViewOfSchedule,
  closeSidebar,
  createSchedule
}) => (
  <Div display="column.flex-start.flex-start" mb={4} width={1}>
    <Header mb={4} pl={4} pr={2} width={1} handleClose={closeSidebar} />
    <Div display="row.flex-start.center" p={4} width={1}>
      <SearchBar
        pill
        variant={VARIANTS.SURFACE}
        flex={1}
        instanceId={NAMESPACE}
        placeholder="Search schedules"
      />
      <BigFilledButton
        LeftIcon={AddIcon}
        bg="purple6"
        color="white"
        ml={3}
        onClick={() => {
          closeSidebar();
          createSchedule();
        }}
        pill
      >
        Create New
      </BigFilledButton>
    </Div>
    <ViewPickerTabs mb={4} pl={4} pr={4} width={1} />
    <Div display="column.flex-start.flex-start" px={4} width={1}>
      {activeScheduleId &&
        renderActiveSchedule(
          activeSchedule,
          activeViewId,
          activeScheduleId,
          selectSchedule,
          selectViewOfSchedule,
          closeSidebar
        )}
    </Div>
    <Div display="column.flex-start.flex-start" p={4} width={1}>
      <Div fs={1} color="gray7" fw={3} uppercase width={1}>
        Other Schedules ({individualSchedules.length})
      </Div>
      <Div
        display="row.space-between.center"
        width={1}
        style={{
          flexWrap: "wrap"
        }}
      >
        {renderIndividualSchedules(
          selectSchedule,
          selectViewOfSchedule,
          activeScheduleId,
          individualSchedules,
          closeSidebar
        )}
      </Div>
    </Div>
  </Div>
);

Body.propTypes = {
  createNew: PropTypes.func,
  masterSchedule: PropTypes.shape(),
  activeViewId: PropTypes.string,
  activeScheduleId: PropTypes.string,
  individualSchedules: PropTypes.arrayOf(PropTypes.shape()),
  selectSchedule: PropTypes.func,
  selectViewOfSchedule: PropTypes.func,
  closeSidebar: PropTypes.func,
  handlers: PropTypes.shape()
};

export default decorate(Body);
