import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const PrimaryField = ({ children, onClick, ...props }) => (
  <div className={css.link} onClick={() => onClick(props)}>
    {children}
  </div>
);

PrimaryField.propTypes = {
  children: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

export default CSSModules(PrimaryField, css);
