import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Forms from "./Forms";

import {
  deleteSubmission,
  createSubmission,
  setSubmissionLocked
} from "redux/modules/formsV2/submission/actions";
import {
  addAccountForm,
  deleteAccountForm
} from "redux/modules/accounts/forms/actions";
import {
  updateContactFormAssignment,
  removeContactFormAssignment
} from "redux/modules/formsV2/form/assignments/contact/actions";
import * as snackbarActions from "redux/modules/snackbar/actions";
import * as modalActions from "redux/modules/modal/actions";
import { getCredentials } from "redux/modules/accounts/credentials/actions";
import { addRecord } from "redux/modules/modules/records/actions";

import * as UserSelectors from "redux/modules/user/selectors";
import * as EventSelectors from "redux/modules/event/selectors";

function mapStateToProps(state) {
  return {
    user: UserSelectors.user(state),
    eventDetails: EventSelectors.eventDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addRecord,
      getCredentials,
      addAccountForm,
      createSubmission,
      deleteAccountForm,
      deleteSubmission,
      setSubmissionLocked,
      updateContactFormAssignment,
      removeContactFormAssignment,
      ...modalActions,
      ...snackbarActions
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Forms);
