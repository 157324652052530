/* eslint-disable no-underscore-dangle */
import { put, takeEvery, all, fork, call, select } from "redux-saga/effects";
import { registerError } from "redux/modules/errors/actions";
import { actions, getters } from "../model";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { apiCall } from "App/Data/sagas";
import { getters as FormGetters } from "ui-kit/Form/model";
import * as R from "ramda";
import { APPEARANCE_FORM_ID } from "../constants";
import { showSnackbar } from "redux/modules/snackbar/actions";

const getAppearanceDetails = function*() {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    yield put(actions.setLoadingAppearance(true));

    const { payload } = yield call(apiCall, {
      method: "get",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/permission-set`
    });
    yield put(actions.setPermissionSet(payload));
    yield put(actions.setLoadingAppearance(false));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred white loading appearance settings"
        }
      ])
    );
  }
};

const updateAppearanceSettings = function*() {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    const fields = yield select(FormGetters.fields, {
      instanceId: APPEARANCE_FORM_ID
    });
    const title = R.pathOr(null, ["title", "value"], fields);
    const backgroundImageUrl = R.path(
      ["hub_background_image_url", "value", 0, "url"],
      fields
    );
    const message = R.pathOr(null, ["message", "value"], fields);
    const enableAlerts = R.pathOr(null, ["enable_alerts", "value"], fields);
    const enableReports = R.pathOr(null, ["enable_reports", "value"], fields);

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/permission-set`,
      data: {
        title,
        backgroundImageUrl,
        message,
        enableAlerts,
        enableReports
      }
    });

    yield all([
      put(actions.setShowAppearanceSettingsModal(false)),
      put(actions.getAppearanceDetails()),
      put(showSnackbar({ message: "Settings Updated" }))
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while updating item"
        }
      ])
    );
  }
};

const watchUpdateAppearanceSettings = function*() {
  yield takeEvery(
    actions.updateAppearanceSettings.type,
    updateAppearanceSettings
  );
};
const watchGetAppearanceDetails = function*() {
  yield takeEvery(actions.getAppearanceDetails.type, getAppearanceDetails);
};

const rootSaga = function*() {
  yield all([
    fork(watchGetAppearanceDetails),
    fork(watchUpdateAppearanceSettings)
  ]);
};

export default rootSaga;
