import { connect } from "react-redux";
import Controller from "./Controller";
import * as R from "ramda";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { getAllItemTypesByEvent } from "redux/modules/items/types/actions";
import { addLineItems } from "redux/modules/orders/line-items/actions";
import { withRouter } from "react-router";

import { eventDetails } from "redux/modules/event/selectors";
import { user } from "redux/modules/user/selectors";
import { allTypes as itemTypes } from "redux/modules/items/types/selectors";

function mapStateToProps(state) {
  const { types, itemGroups, items, variants } = itemTypes(state).reduce(
    (list, type) => {
      list.types.push(type);
      type.groups.forEach(group => {
        list.itemGroups.push(group);
        group.items.forEach(item => {
          list.items.push(item);
          item.variants.forEach(variant => {
            list.variants.push({
              ...variant,
              type_id: group.type_id,
              item
            });
          });
        });
      });
      return list;
    },
    {
      types: [],
      itemGroups: [],
      items: [],
      variants: []
    }
  );

  return {
    user: user(state),
    eventDetails: eventDetails(state),
    //
    types,
    itemGroups,
    items,
    variants
  };
}

export default R.compose(
  withRouter,
  connect(
    mapStateToProps,
    {
      showSnackbar,
      addLineItems,
      getAllItemTypesByEvent
    }
  )
)(Controller);
