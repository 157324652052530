import Helpers from "utils/Global/Helpers";

module.exports = {
  updateItemBlockVariant: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/items/item-block-variants/${
          data.blockId
        }`,
        credentials,
        data,
        success,
        error
      });
    }),
  /* eslint-enable no-underscore-dangle */

  bulkUpdateItemBlockVariants: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "put",
        url: `${
          window.__LENND_API_BASE_URL__
        }/items/item-block-variants/bulk-update`,
        credentials,
        data,
        success,
        error
      });
    })
  /* eslint-enable no-underscore-dangle */
};
