import React from "react";
import * as R from "ramda";
import {
  Div,
  Text2,
  Text3,
  Text4,
  CheckIcon,
  MediumFilledButton,
  Text1,
  Text0,
  CircleIcon,
  DownFilledIcon
} from "components/Base";

const getPercentage = ({ done, total, barText }) => {
  const division = (done / total) * 100;
  const roundedDivision = Math.round(division);
  let label;
  let color;

  if (division <= 0) {
    label = "0% reviewed";
  } else if (roundedDivision >= 100 && done < total) {
    label = "99% reviewed";
  } else if (division >= 100) {
    label = "100% reviewed";
  } else {
    label = `${roundedDivision}% ${barText}`;
  }

  if (division <= 25) {
    color = "yellow4";
  } else if (division <= 50) {
    color = "lime4";
  } else if (division <= 75) {
    color = "lime5";
  } else {
    color = "lime6";
  }

  return {
    label,
    color,
    rawNumber: division,
    number: roundedDivision
  };
};

const CardRow = ({
  complete,
  push,
  collapsed = false,
  total,
  done,
  missing,
  name,
  groups = [],
  url = "",
  barText = "reviewed",
  btnText = "Go Review"
}) => {
  const percentage = getPercentage({ done, total, barText });

  return (
    <Div
      px={2}
      bg={complete ? "neutral0" : { default: "white", hover: "neutral0" }}
      transition="fast"
      bra={1}
      display="row.flex-start.center"
      height={52}
      className="hoverContainer"
      onClick={
        groups.length
          ? undefined
          : ev => {
              ev.stopPropagation();
              push(url);
            }
      }
    >
      <Div display="row.space-between.center" flex={1} pr={3}>
        <Div
          display="row.flex-start.center"
          style={{
            maxWidth: "360px"
          }}
        >
          {complete || percentage.rawNumber >= 100 ? (
            <CheckIcon color="neutral6" size={24} mr={3} />
          ) : !R.length(groups) ? (
            <CircleIcon size={24} color="neutral4" mr={3} />
          ) : (
            <DownFilledIcon
              size={24}
              color="black"
              mr={3}
              style={{ transform: `rotate(${collapsed ? "-90" : "0"}deg)` }}
            />
          )}
          <Text4 truncate bold color="black">
            {name}
          </Text4>
          {missing ? (
            <Div
              p={1}
              bra={1}
              height={20}
              bg="gray2"
              display="row.center.center"
              ml={2}
              style={{ minWidth: "26px" }}
            >
              <Div fs={1} fw={3} color="gray7">
                {missing >= 100 ? "99+" : missing}
              </Div>
            </Div>
          ) : null}
        </Div>
        <Div
          width={256}
          height={20}
          bg="neutral1"
          bra={2}
          style={{
            overflow: "hidden",
            position: "relative",
            padding: "2px"
          }}
          display="row.flex-start.center"
        >
          <Text0
            bold
            color={percentage.number < 25 ? "gray7" : "white"}
            style={{ position: "absolute", left: "10px" }}
          >
            {percentage.label}
          </Text0>
          <Div
            height={1}
            style={{
              width: `${(done / total) * 100}%`
            }}
            bg={percentage.color}
            px={2}
            display="row.flex-start.center"
            {...{
              brl: percentage.number <= 99 ? 2 : undefined,
              bra: percentage.number <= 99 ? undefined : 2
            }}
          />
          <Text0
            style={{ position: "absolute", right: "10px" }}
            bold
            color="black"
          >{`${done}/${total}`}</Text0>
        </Div>
        {/*
        <MediumFilledButton
          bg="primary7"
          className="showOnHover"
          style={{
            position: "absolute",
            right: "20px",
            top: "0px",
            bottom: "0px",
            margin: "0 auto"
          }}
          onClick={
            groups.length
              ? ev => {
                  ev.stopPropagation();
                  push(url);
                }
              : undefined
          }
        >
          {btnText}
        </MediumFilledButton>
        */}
      </Div>
    </Div>
  );
};

export default CardRow;
