import React from "react";
import * as R from "ramda";
import thunkMiddleware from "redux-thunk";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { createContext } from "redux-mvc";

import ordersList, { actions } from "Orders/List";
import ordersTable from "Orders/Table";
import tabsModule from "ui-kit/Tabs";

import ordersListSagas from "Orders/List/sagas";

import Page from "Orders/List/View/Page";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import ViewOrderModal from "Orders/OrderModal/View";
import ViewRecordModal from "components/Global/Module/Modals/ViewRecord";
import DeleteConfirmation from "components/Global/CRM/Modals/DeleteConfirmation";
import CreateOrderModal from "Orders/CreateOrderModal";

import { showModal, hideModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { updateOrder, deleteOrder } from "redux/modules/orders/orders/actions";

import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

import { noop } from "utils/General";

ordersList.setRootSaga(ordersListSagas);
ordersList.plugModule(tabsModule);
ordersList.plugModule(ordersTable);

const makeContext = (module, Page) =>
  R.compose(
    connect(
      state => ({ canDo: canEventUserDo(state) }),
      {
        showModal,
        hideModal,
        showSnackbar,
        updateOrder,
        deleteOrder
      }
    ),
    createContext({
      module,
      middleware: [thunkMiddleware],
      lifeCycle: {
        constructor() {
          this.store.dispatch(actions.setView(this.props.params.view));
        }
      },
      handlers: {
        showAddOrderModal() {
          this.props.showModal({
            content: (
              <CreateOrderModal
                onDone={() => this.store.dispatch(actions.search())}
              />
            ),
            wrapper: ModalWrapper
          });
        },

        // setup organization order modal
        showAddOrganizationOrderModal: noop,

        showOrderModal({ orderId, orderNumber }) {
          this.props.showModal({
            content: (
              <ViewOrderModal
                orderId={orderId}
                orderNumber={orderNumber}
                hideModal={() => {
                  this.props.hideModal();
                }}
              />
            ),
            wrapper: ModalWrapper
          });
        },

        showDeleteOrder({ orderId }) {
          this.props.showModal({
            content: (
              <DeleteConfirmation
                hideModal={this.props.hideModal}
                heading="Delete order?"
                message={
                  <div>
                    Are you sure you want to delete this order?
                    <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                      This cannot be undone.
                    </div>
                  </div>
                }
                onConfirm={() => this.handlers.handleDeleteOrder(orderId)}
              />
            ),
            wrapper: ModalWrapper
          });
        },

        async handleDeleteOrder(orderId) {
          await this.props.deleteOrder({
            orderId
          });

          this.props.showSnackbar({
            message: "Order deleted",
            action: "OK"
          });

          return this.store.dispatch(actions.search());
        },

        showAccountModal(accountId) {
          this.handlers.showRecordModal(
            STANDARD_MODULES.accounts.id,
            accountId
          );
        },

        showContactModal(contactId) {
          this.handlers.showRecordModal(
            STANDARD_MODULES.contacts.id,
            contactId
          );
        },

        showRecordModal(moduleId, recordId) {
          this.props.showModal({
            content: (
              <ViewRecordModal
                moduleId={moduleId}
                recordId={recordId}
                onClose={() => {
                  return this.props.hideModal();
                }}
              />
            ),
            wrapper: ModalWrapper
          });
        },

        async archiveOrder({ orderId }) {
          await this.props.updateOrder({
            orderId,
            order: {
              isArchived: true
            }
          });

          this.props.showSnackbar({
            message: "Order archived",
            action: "OK"
          });

          return this.store.dispatch(actions.search());
        },

        async unarchiveOrder({ orderId }) {
          await this.props.updateOrder({
            orderId,
            order: {
              isArchived: false
            }
          });

          this.props.showSnackbar({
            message: "Order unarchived",
            action: "OK"
          });

          return this.store.dispatch(actions.search());
        }
      }
    }),
    withRouter
  )(Page);

export default makeContext(ordersList, Page);
