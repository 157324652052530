import * as R from "ramda";
import { createSelector } from "reselect";

import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

import { MEAL_TYPE_ID } from "utils/item-types";

import {
  ORDER_LINE_ITEMS_REPORT_ID,
  MEALS_BY_GROUP_REPORT_ID,
  MEALS_BY_DAY_REPORT_ID
} from "components/Event/Reports/utils/constants";

const getUrl = createSelector(
  R.path(["eventDetails", "id"]),
  eventId => (view, base = "/catering") => `/event/${eventId}${base}${view}`
);

export const getLinks = createSelector(
  R.prop("groupRecordTypes"),
  R.prop("eventDetails"),
  R.prop("cando"),
  getUrl,
  R.prop("showCreateOrderModal"),
  R.prop("showImportOrdersModal"),
  (
    groupRecordTypes,
    eventDetails,
    cando,
    getUrl,
    showCreateOrderModal,
    showImportOrdersModal
  ) => {
    let LINKS = [
      {
        id: "dashboard",
        to: getUrl("/dashboard"),
        name: "Dashboard",
        activeRoutes: ["CateringDashboard"]
      },
      {
        id: "myApprovals",
        to: getUrl("/manage/my-approvals"),
        name: "My Approvals",
        activeRoutes: ["CateringManageBy"],
        activeParams: { viewId: "my-approvals" }
      },
      {
        id: "dashboardDivider",
        divide: true
      },
      {
        id: "allRequests",
        to: getUrl("/manage/all-requests"),
        name: "All Requests",
        activeRoutes: ["CateringManageBy"],
        activeParams: { viewId: "all-requests" }
      },
      {
        id: "issue",
        to: getUrl("/manage/fulfill"),
        name: "Issue",
        activeRoutes: ["CateringManageBy"],
        activeParams: { viewId: "fulfill" }
      },
      cando(`${STANDARD_MODULES.catering.id}_add_line_items`)
        ? {
            id: "checkin",
            name: "Check In",
            external: true,
            activeRoutes: ["Checkin"],
            to: getUrl("/checkin")
          }
        : null
    ];

    // Allocations
    if (groupRecordTypes.length) {
      LINKS = [
        ...LINKS,
        {
          id: "allocationDivider",
          divide: true
        },
        {
          id: "allocations",
          name: "Allocations",
          activeRoutes: ["PassesGuestList"],
          links: R.compose(
            R.sortBy(
              R.compose(
                R.toLower,
                R.prop("name")
              )
            ),
            R.map(l => ({
              id: l.id,
              to: getUrl(`/guest-list/${l.id}`, "/passes"),
              activeRoutes: ["PassesGuestList"],
              activeParams: { recordTypeId: l.id },
              name: l.name
            }))
          )(groupRecordTypes)
        },
        {
          id: "allocationDivider2",
          divide: true
        }
      ];
    }

    LINKS = [
      ...LINKS,
      /*
      {
        id: "inventory",
        name: "Inventory",
        to: getUrl("/manage/inventory")
      },
      {
        id: "inventoryDivider",
        divide: true
      },
      */
      {
        id: "subMenu",
        links: [
          {
            id: "create-order",
            name: "Create Order",
            onClick: () => showCreateOrderModal()
          },
          cando(`${STANDARD_MODULES.catering.id}_add_line_items`)
            ? {
                id: "import-order",
                name: "Import Orders",
                onClick: () => showImportOrdersModal()
              }
            : null,
          {
            id: "confirm-emails",
            name: "Send Emails",
            to: getUrl("/confirm"),
            activeRoutes: ["CateringConfirmation"]
          }
        ]
      },
      {
        id: "reports",
        name: "Reports",
        links: [
          {
            id: "report-items",
            to: getUrl(ORDER_LINE_ITEMS_REPORT_ID, "/reports/"),
            name: "Passes & Meals by Person"
          },
          {
            id: "report-groups",
            to: getUrl(MEALS_BY_GROUP_REPORT_ID, "/reports/"),
            name: "Meals by Group"
          },
          {
            id: "report-groups2",
            to: getUrl(MEALS_BY_DAY_REPORT_ID, "/reports/"),
            name: "Meals by Day"
          }
        ]
      }
    ].filter(
      R.compose(
        R.not,
        R.either(R.isEmpty, R.isNil)
      )
    );

    return LINKS;
  }
);

export const getSetupLink = createSelector(
  R.prop("cando"),
  getUrl,
  (cando, getUrl) => {
    return {
      id: "setupLinks",
      links: cando(`${STANDARD_MODULES.catering.id}_manage`)
        ? [
            {
              id: "manage-meal-types",
              name: "Manage Meal Types",
              to: getUrl("/settings/catering", "")
            },
            {
              id: "manage-forms",
              name: "Manage Forms",
              to: getUrl("/forms-v2", "")
            },
            {
              id: "manage-approvers",
              name: "Manage Approvers",
              activeRoutes: ["PassesSetupApprovers"],
              to: getUrl(`/settings/catalog/type/${MEAL_TYPE_ID}/approvers`, "")
            }
          ]
        : []
    };
  }
);
