import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router";

import { noop, withProps } from "utils/General";
import { getters } from "Sponsors/SelfSetup";
import { getIsLast } from "Sponsors/SelfSetup/selectors";

import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";
import { actions } from "Sponsors/SelfSetup";
import { getSteps, getNextStep } from "Sponsors/SelfSetup/selectors";

import {
  Div,
  Text2,
  TinyToggle,
  BigFilledButton,
  BigOutlineButton,
  OpenInNewIcon
} from "components/Base";
import { Row, Column } from "ui-kit/Form/View";
import Step from "./Step";

const NewIcon = withProps({ size: 12, ml: 2 })(OpenInNewIcon);

const decorate = R.compose(
  WithThemeConsumer({ variant: VARIANTS.BACKGROUND }),
  withRouter,
  connect(
    (state, props) => ({
      steps: getSteps(state, props),
      stepCompleted: R.contains(
        R.path(["params", "stepId"], props),
        R.propOr([], "completed_steps")(getters.eventShowcase(state))
      ),
      nextStep: getNextStep(state, props),
      isLast: getIsLast(state, props)
    }),
    {
      toggleComplete: actions.toggleCompletedStep,
      viewSalesPortal: actions.viewSalesPortal
    }
  )
);

const BarProgress = ({
  steps = [],
  Th = defaultTh,
  stepCompleted = false,
  toggleComplete = noop,
  nextStep = "#",
  isLast = false,
  params,
  viewSalesPortal,
  ...styleProps
}) => (
  <Div
    {...Th(["bc"], {
      bl: 1,
      width: 300,
      style: { flexShrink: 0 },
      ...styleProps
    })}
  >
    <Column mb={3}>
      {R.map(
        step => (
          <Step {...step} key={step.id} />
        ),
        steps
      )}
    </Column>
    <Column mb={3}>
      {/*
         NOTE: completion status removed
      */}

      <Row mt={4}>
        <TinyToggle
          active={stepCompleted}
          onClick={() => toggleComplete(params.stepId)}
        />
        <Text2 {...Th(["color"], { bold: true, ml: 2 })}>
          Mark step as complete
        </Text2>
      </Row>
    </Column>

    {!isLast ? (
      <Column mt={4} display="column.flex-start.stretch">
        <Link to={nextStep}>
          <BigFilledButton
            {...Th(["bg", "color"], {
              width: 1,
              mb: 1,
              variant: Th.VARIANTS.SECONDARY
            })}
          >
            Next Step
          </BigFilledButton>
        </Link>
        <Link to={nextStep}>
          <BigFilledButton
            {...Th(["bg", "color"], {
              width: 1,
              mb: 1,
              variant: Th.VARIANTS.SURFACE
            })}
          >
            Skip Step
          </BigFilledButton>
        </Link>
      </Column>
    ) : null}

    <Div pt={3} mt={3} mb={3} bc="gray3" bt={1}>
      <BigOutlineButton
        {...Th(["color"], {
          width: 1,
          variant: Th.VARIANTS.SURFACE
        })}
        RightIcon={NewIcon}
        onClick={() => viewSalesPortal()}
      >
        View Sales Portal
      </BigOutlineButton>
    </Div>
  </Div>
);

export default decorate(BarProgress);
