import React from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";

import { Div } from "components/Base";

import Body from "./Body";
import Loading from "ui-kit/Loading";

import { connect } from "react-redux";
import { getters } from "../index";

const decorate = connect(state => ({
  loading: getters.loading(state)
}));

const Layout = ({ handlers, loading }) => {
  return (
    <StyleWrapper hideHeader bodyStyles={{ padding: 0 }} width={600}>
      {loading ? (
        <Div display="row.center.center" width={1} height={1}>
          <Loading />
        </Div>
      ) : (
        <Body handlers={handlers} />
      )}
    </StyleWrapper>
  );
};

export default decorate(Layout);
