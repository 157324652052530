import * as R from "ramda";
import Layout from "./Layout";
import { actions } from "../model";
import { createContext } from "redux-mvc";
import module from "Portal/PortalForm";

const decorate = createContext({
  module,
  options: {
    observedDomains: [
      "user",
      "event",
      "permissions",
      "organization",
      "portal",
      "records",
      "Data",
      "submission",
      "@flopflip"
    ],
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    persist: false
  },
  lifeCycle: {
    componentDidMount() {
      const formId = R.pathOr("", ["params", "formId"], this.props);
      const accountId = R.pathOr(
        "",
        ["portalUser", "active_view", "id"],
        this.props
      );

      this.store.dispatch(actions.init({ formId, accountId }));
    }
  },
  handlers: {}
});

export default decorate(Layout);
