import { createModule } from "redux-mvc";

import model from "./model";
import searchBar from "ui-kit/SearchBar";
import table from "ui-kit/Table";
import addActivityRow from "Schedules/AddActivityRow";
import viewPicker from "Schedules/ScheduleViewPicker";
import addActivityPopover from "Schedules/AddActivityPopover";
import editors from "Schedules/AllowAccessUsersModal";
import createScheduleModal from "Schedules/Create";
import filterControls from "ui-kit/FilterControls";
import timeTable from "ui-kit/TimeTable";
import activityDetailsModal from "Schedules/ActivityDetailsModal";
import templatesPicker from "Schedules/TemplatesPicker";
import activityDetailsSidebar from "Schedules/ActivityDetailsSidebar";

const module = createModule(model);

module.plugModule(searchBar);
module.plugModule(table);
module.plugModule(addActivityRow);
module.plugModule(viewPicker);
module.plugModule(addActivityPopover);
module.plugModule(editors);
module.plugModule(createScheduleModal);
module.plugModule(filterControls);
module.plugModule(activityDetailsModal);
module.plugModule(templatesPicker);
module.plugModule(timeTable);
module.plugModule(activityDetailsSidebar);

const { actions, getters } = module;

export { actions, getters };

export default module;
