import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as flags from "utils/feature-flags";

import EventsDropdown from "./EventsDropdown";

import * as ModalActions from "redux/modules/modal/actions";
import * as SnackbarActions from "redux/modules/snackbar/actions";
import { toggleStarredEvent } from "redux/modules/events/actions";
import { addEvent } from "redux/modules/event/actions";

import * as EventSelectors from "redux/modules/event/selectors";
import * as EventsSelectors from "redux/modules/events/selectors";
import * as UserSelectors from "redux/modules/user/selectors";
import { selectFeatureFlag } from "@flopflip/react-redux";

function mapStateToProps(state) {
  return {
    myEvents: EventsSelectors.events(state),
    eventDetails: EventSelectors.eventDetails(state),
    user: UserSelectors.user(state),
    isLenndAdmin: selectFeatureFlag(flags.IS_LENND_ADMIN.NAME)(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...SnackbarActions,
      ...ModalActions,
      addEvent,
      toggleStarredEvent
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsDropdown);
