import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class Wrapper extends Component {
  render() {
    return <div styleName="wrapper">{this.props.children}</div>;
  }
}

Wrapper.propTypes = {
  modal: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired
};

export default Wrapper;
