import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { noop } from "utils/General";

import { actions } from "ui-kit/Form/model";
import { getValue } from "ui-kit/Form/selectors";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";

import {
  Div,
  VertColorGrid,
  Popover,
  MediumFilledButton,
  DownFilledIcon
} from "components/Base";

const DefaultButton = ({ onClick, color = "#FFF" }) => (
  <MediumFilledButton
    onClick={onClick}
    RightIcon={DownFilledIcon}
    color="neutral9"
    bg="neutral0"
  >
    <Div height={20} width={20} bra={2} bg={color} />
  </MediumFilledButton>
);

const decorate = R.compose(
  WithFormInstanceConsumer({ fieldType: FIELD_TYPES.COLOR_PICKER }),
  connect(
    (state, props) => ({
      value: getValue(state, props)
    }),
    bindInstance({
      setIniValue: actions.setIniValue,
      onChange: actions.setFieldValue
    })
  )
);

export const UCColorPicker = ({
  value = "",
  onChange = noop,
  fieldId,
  fieldType,
  LabelComponent = DefaultButton,
  setIniValue,
  iniValue
}) => {
  useEffect(() => {
    setIniValue(iniValue, {
      meta: { fieldType, fieldId }
    });
  }, []);
  return (
    <Div display="row.flex-start.flex-start">
      <Popover
        anchorOrigin={{ horizontal: "center", vertical: "center" }}
        Label={({ onClick }) => (
          <LabelComponent color={value} onClick={onClick} />
        )}
      >
        {({ closePopover }) => (
          <Div bg="white" display="row.flex-start.flex-start">
            <VertColorGrid
              selectedColor={value}
              onSelectColor={val => {
                onChange(val, {
                  meta: { fieldType, fieldId }
                });
                closePopover();
              }}
            />
          </Div>
        )}
      </Popover>
    </Div>
  );
};

export const ColorPicker = decorate(UCColorPicker);
