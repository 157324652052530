import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const EmptyState = ({ title, description }) => (
  <div styleName="empty">
    <div styleName="emptyTitle">{title}</div>
    <div>{description}</div>
  </div>
);

EmptyState.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default CSSModules(EmptyState, css);
