import { createModule } from "redux-mvc";

import model from "Orders/CreateOrderModal/Customer/model";
import sagas from "Orders/CreateOrderModal/Customer/sagas";
import searchBarModule from "ui-kit/SearchBar";

const module = createModule(model);

module.plugModule(searchBarModule);
module.setRootSaga(sagas);

export default module;
