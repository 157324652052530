import { connect } from "react-redux";
import { createContext } from "redux-mvc";
import * as R from "ramda";
import reports, { actions } from "../index";
import Layout from "./Layout";
import rootSaga from "../sagas";

import { hideModal, showModal } from "redux/modules/modal/actions";

reports.setRootSaga(rootSaga);

const contextConfig = {
  module: reports,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(
        actions.init({
          recordIds: this.props.recordIds,
          moduleId: this.props.moduleId
        })
      );
    }
  },
  handlers: {},
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", reports.modules))),
      R.prop("type")
    ),
    observedDomains: ["user", "event", "modal", "organization"]
  }
};

const decorate = R.compose(
  connect(
    null,
    {
      showModal,
      hideModal
    }
  ),
  createContext(contextConfig)
);

export default decorate(Layout);
