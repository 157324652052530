import * as R from "ramda";

import { createHandlers } from "redux-mvc";

const model = createHandlers({
  namespace: "UIAlert",
  reducers: {
    executeAction: R.identity
  }
});

const { actions, getters } = model;

export { actions, getters };

export default model;
