import Helpers from "utils/Global/Helpers";
import getQuery from "redux/modules/modules/utils/get-query";

module.exports = {
  getOrderSummaryForAccount: (
    credentials,
    { eventId, recordId, allocations = false, typeIds }
  ) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery({
        eventId,
        typeIds,
        allocations
      });

      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/account/${recordId}/orders/summary${query || ""}`,
        credentials,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */,

  getOrderSummaryForContact: (credentials, { eventId, recordId, typeIds }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery({
        eventId,
        typeIds
      });

      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/contacts/${recordId}/orders/summary${query || ""}`,
        credentials,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */
};
