import Helpers from "utils/Global/Helpers";

export default {
  getPeople: ({ credentials, eventId, accountId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/account/event/${eventId}/account/${accountId}/contact-types`,
        credentials,
        success,
        error
      });
    })
};
