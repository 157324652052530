import React from "react";
import Draggable from "components/Event/FormsV2/Form/Views/V3_Edit/Shared/DraggableField";
import {
  Tab,
  Tabs,
  Sidebar
} from "components/Event/FormsV2/Form/Views/V3_Edit/Shared/Shared";
import {
  Div,
  AddIcon,
  DragIcon,
  Text2,
  Type11Card,
  Type12Card,
  Type13Card,
  Text4,
  SearchIcon,
  BigShadedInput,
  MediumClearButton,
  FontIcon,
  CardGroup2,
  collapsableHandler,
  RightIcon,
  DownIcon,
  Collapsable,
  AddBoxIcon
} from "components/Base";
import { withProps } from "utils/General";

const TogglableGroup = collapsableHandler(
  ({
    name,
    toggleCollapsed,
    collapsed,
    relatedFieldGroups,
    getRelatedFields,
    onAddField
  }) => {
    const toggle = () => {
      if (collapsed) {
        getRelatedFields();
      }
      toggleCollapsed();
    };
    return (
      <Div mb={1} bg="neutral0" p={2}>
        <Div display="row.space-between.center" onClick={toggle}>
          <Div fs={2} color="gray7" fw={3}>
            {name}
          </Div>
          {collapsed ? (
            <RightIcon size={20} color={"neutral6"} />
          ) : (
            <DownIcon size={20} color={"neutral6"} />
          )}
        </Div>
        <Collapsable collapsed={collapsed}>
          {relatedFieldGroups.map(g => (
            <Div key={g.id}>
              <Div uppercase fs={1} color="gray6" mt={1} mb={1}>
                {g.name}
              </Div>
              {g.fields.map(f => (
                <Div key={f.id}>
                  <DragField
                    id={f.id}
                    field={f.rawField}
                    onClick={f.onAdd}
                    onDrop={f.onDrop}
                    onDragStart={f.onDragStart}
                    onEdit={f.onEdit}
                    name={f.name}
                    subname={f.subname}
                    icon={f.icon}
                  />
                </Div>
              ))}
            </Div>
          ))}
          <MediumClearButton LeftIcon={AddIcon} onClick={onAddField}>
            Add field
          </MediumClearButton>
        </Collapsable>
      </Div>
    );
  }
);

const TabBody = ({ children }) => (
  <Div
    px={4}
    py={4}
    style={{
      overflowY: "auto",
      height: "calc(100vh - 107px)"
    }}
  >
    {children}
  </Div>
);

const ViewTabs = ({ views }) => (
  <Tabs>
    {views.map(view => (
      <Tab
        key={view.name}
        active={view.active}
        onClick={!view.active ? view.onClick : null}
      >
        <view.Icon mr={2} color={view.active ? "primary5" : "neutral4"} />
        {view.name}
      </Tab>
    ))}
  </Tabs>
);

const BlockElement = ({ Icon, name, onClick }) => (
  <Div
    size={100}
    shadow={{ default: 2, hover: 3 }}
    bra={1}
    display="column.center.center"
    style={{ position: "relative" }}
    className="hoverContainer"
    onClick={() => onClick()} // do not pass event
    bg="white"
  >
    <div
      className="showOnHover"
      style={{ position: "absolute", left: 0, top: 5 }}
    >
      <DragIcon />
    </div>
    <Icon size={30} />
    <Text2 bold>{name}</Text2>
  </Div>
);
const DragField = Draggable(Type11Card);
const DragBlock = Draggable(BlockElement);

const Empty = ({ searchTerm, label }) => (
  <Div bg="neutral1" bra={1} mb={2} p={4} display="column.center.center">
    <Text4 color="gray5">
      {searchTerm ? `No ${label} match search` : `No ${label} added`}
    </Text4>
  </Div>
);

const CreateNew = ({ blocks }) => {
  return (
    <Div>
      <Div pt={1} my={4}>
        <Div display="row.space-between.center">
          {blocks.map(block => (
            <DragBlock key={block.id} {...block} onClick={block.onAdd} />
          ))}
        </Div>
      </Div>

      {/* @NOTE: Hiding until implemented Advanced Collection Blocks */}
    </Div>
  );
};

const View = ({
  activeView,
  blocks,
  fields,
  relatedFieldGroups,
  itemBlocks,
  onAddField,
  onSearch,
  searchTerm,
  views,
  onEditOrderCustomer,
  onEditOrderFulfillment,
  onEditOrderDetails,
  addOrderCustomerField,
  addOrderFulfillmentField,
  addOrderDetailsField,
  itemBlocksExist,
  canAddCustomFieldsToForm
}) => (
  <Sidebar>
    <ViewTabs views={views} />
    {activeView === "fields" ? (
      <TabBody>
        <BigShadedInput
          onChange={onSearch}
          continuous
          width={1}
          RightIcon={SearchIcon}
          placeholder="Search..."
          mb={6}
        />
        {canAddCustomFieldsToForm ? (
          <CardGroup2
            mb={5}
            heading="Fields"
            actions={[]}
            footer={
              <MediumClearButton LeftIcon={AddIcon} onClick={onAddField}>
                Add a custom field to this form
              </MediumClearButton>
            }
          >
            {fields.length ? (
              fields.map(f => {
                return (
                  <DragField
                    key={f.id}
                    id={f.id}
                    field={f.rawField}
                    onClick={f.onAdd}
                    onDrop={f.onDrop}
                    onDragStart={f.onDragStart}
                    onEdit={f.onEdit}
                    name={f.name}
                    subname={f.subname}
                    icon={f.icon}
                  />
                );
              })
            ) : (
              <Empty searchTerm={searchTerm} label={"fields"} />
            )}
          </CardGroup2>
        ) : null}

        <Div px={2} mb={5}>
          <Div mb={1} display="row.flex-start.center">
            <FontIcon mr={3} color="gray5" mr={1}>
              link
            </FontIcon>
            <Div color="gray7" fw={3} fs={3}>
              Related Fields
            </Div>
          </Div>
          {relatedFieldGroups.map(fieldGroup => (
            <TogglableGroup
              key={fieldGroup.id}
              {...fieldGroup}
              collapsedDefault
            />
          ))}
        </Div>

        {itemBlocks.map(({ Icon, name, onCreateItem, items }) => (
          <CardGroup2
            pb={8}
            heading={`${name} Blocks`}
            Icon={Icon}
            actions={[]}
            footer={
              <MediumClearButton LeftIcon={AddIcon} onClick={onCreateItem}>
                Create a new {name.toLowerCase()} block
              </MediumClearButton>
            }
          >
            {items.length ? (
              items.map(item => {
                if (item.onManage) {
                  return (
                    <Type12Card
                      name={item.name}
                      subname={item.subname}
                      tooltip={item.message}
                      onAction={item.onManage}
                      onEdit={item.onEdit}
                      actionText="Manage"
                    />
                  );
                }
                return (
                  <Type13Card
                    name={item.name}
                    tooltip={item.message}
                    onEdit={item.onEdit}
                    onAdd={item.onAdd}
                  />
                );
              })
            ) : (
              <Empty searchTerm={searchTerm} label={"blocks"} />
            )}
          </CardGroup2>
        ))}

        {itemBlocksExist ? (
          <CardGroup2
            pb={8}
            heading="Order Information"
            Icon={withProps({
              style: { fontSize: 24 },
              children: "shopping_cart"
            })(FontIcon)}
            actions={[]}
          >
            {onEditOrderCustomer ? (
              <Type12Card
                name="Customer Information"
                tooltip="Select what details to collect from customers"
                onAction={onEditOrderCustomer}
                onEdit={onEditOrderCustomer}
                actionText="Manage"
              />
            ) : (
              <Type13Card
                name="Customer Information"
                tooltip="Select what details to collect from customers"
                onEdit={() => {}}
                onAdd={() => addOrderCustomerField()}
              />
            )}
            {onEditOrderFulfillment ? (
              <Type12Card
                name="Fulfillment Information"
                tooltip="Select what fulfillment details to collect"
                onAction={onEditOrderFulfillment}
                onEdit={onEditOrderFulfillment}
                actionText="Manage"
              />
            ) : (
              <Type13Card
                name="Fulfillment Information"
                tooltip="Select what fulfillment details to collect"
                onEdit={() => {}}
                onAdd={() => addOrderFulfillmentField()}
              />
            )}
            {onEditOrderDetails ? (
              <Type12Card
                name="Order Information"
                tooltip="Select additional questions to ask"
                onAction={onEditOrderDetails}
                onEdit={onEditOrderDetails}
                actionText="Manage"
              />
            ) : (
              <Type13Card
                name="Order Information"
                tooltip="Select additional questions to ask"
                onEdit={() => {}}
                onAdd={() => addOrderDetailsField()}
              />
            )}
          </CardGroup2>
        ) : null}

        <CardGroup2
          mb={5}
          heading="Display blocks"
          actions={[]}
          Icon={AddBoxIcon}
        >
          <CreateNew blocks={blocks} />
        </CardGroup2>
      </TabBody>
    ) : null}
  </Sidebar>
);

export default View;
