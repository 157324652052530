import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { getters } from "EventLight/Common/Dashboard";

import {
  getShowPeopleSection,
  getModuleId
} from "EventLight/Common/Dashboard/selectors";

import SecondarySidebar from "ui-kit/SecondarySidebar";

import { Div } from "components/Base";
import CanUserDo from "components/Global/Security/CanUserDo";

import Title from "./Title";
import ShowHideFields from "./ShowHideFields";
import Fields from "./FieldFilter";
import GroupBy from "./GroupByFilter";
import ViewPicker from "./ViewPicker";
import SortByPicker from "./SortByPicker";
import RefreshDashboards from "./RefreshDashboards";
import People from "./People";
import Shortcuts from "./Shortcuts";

const filters = {
  title: <Title />,
  viewPicker: ({ instanceId, moduleId }) => (
    <ViewPicker mt={2} instanceId={instanceId} moduleId={moduleId} />
  ),
  groupBy: <GroupBy mt={2} />,
  sortBy: <SortByPicker mt={2} />,
  fields: <Fields mt={2} />,
  showHideFields: ({ showSelectFieldsModal, preferences, moduleId }) => (
    <CanUserDo action={`${moduleId}_can_manage_shared_views`}>
      <ShowHideFields
        onClick={() => showSelectFieldsModal(preferences)}
        mt={2}
      />
    </CanUserDo>
  ),
  refreshDashboards: () => <RefreshDashboards />,
  people: ({ showPeopleSection }) =>
    showPeopleSection ? <People mt={2} /> : null,
  shortcuts: <Shortcuts mt={2} />
};

const OPTIONS = [
  "title",
  "viewPicker",
  "showHideFields",
  // "groupBy",
  "fields",
  "sortBy",
  "people",
  "shortcuts",
  "refreshDashboards"
];

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    preferences: getters.preferences(state, props),
    mode: getters.mode(state, props),
    showPeopleSection: getShowPeopleSection(state, props),
    moduleId: getModuleId(state)
  }))
);

const Sidebar = ({
  preferences,
  mode,
  instanceId,
  moduleId,
  showPeopleSection,
  handlers: { showSelectFieldsModal }
}) => (
  <SecondarySidebar display="column.flex-start.stretch">
    <Div px={3} pb={2} display="column.flex-start.stretch">
      {R.map(
        filter =>
          typeof filters[filter] === "function"
            ? React.createElement(filters[filter], {
                showPeopleSection,
                preferences,
                mode,
                showSelectFieldsModal,
                instanceId,
                moduleId
              })
            : filters[filter],
        OPTIONS
      )}
    </Div>
  </SecondarySidebar>
);

export default decorate(Sidebar);
