import React from "react";
import {
  put,
  all,
  takeEvery,
  fork,
  select,
  call,
  take
} from "redux-saga/effects";
import * as R from "ramda";
import { makeFuture } from "utils/General/sagas";
import { actions as DataActions } from "App/Data/model";

import { actions, getters } from "Portal/PortalForm/model";
import { actions as TableActions } from "ui-kit/Table/model";
import { registerError } from "redux/modules/errors/actions";
import {
  TABLE_INSTANCE_ID,
  TABS,
  ROW_ACTIONS_ITEMS
} from "Portal/PortalForm/constants";
import OrderModal from "Orders/OrderModal/View";
import { showModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { eventDetails as getEventDetails } from "redux/modules/portal/selectors";
import { portalUser as getPortalUser } from "redux/modules/portal/user/selectors";
import { user as getUser } from "redux/modules/user/selectors";
import getValue from "@lennd/value-types/src/get-value";
import toString from "@lennd/value-types/src/to-string";
import Api from "./api";
import SubmissionApi from "redux/modules/formsV2/submission/api";
import RecordsApi from "redux/modules/modules/records/api";
import { getCredentials } from "redux/modules/user/selectors";
import { push } from "react-router-redux";
import DeleteConfirmation from "components/Global/CRM/Modals/DeleteConfirmation";

const init = function*({ payload: { formId } }) {
  const credentials = yield select(getCredentials);
  const portalUser = yield select(getPortalUser);

  const isViewingAsAccount = R.pathEq(["active_view", "type"], "account")(
    portalUser
  );
  const accountId = isViewingAsAccount
    ? R.path(["active_view", "id"], portalUser)
    : null;

  const { payload } = yield call(Api.getForm, {
    credentials,
    formId,
    accountId
  });

  try {
    yield all([
      put(
        TableActions.setData(
          {
            canEditCells: false,
            columns: R.propOr([], "columns", payload),
            rows: R.propOr([], "submissions", payload),
            columnWidths: payload.preferences.field_widths
          },
          {
            meta: {
              instanceId: TABLE_INSTANCE_ID
            }
          }
        )
      ),
      put(
        actions.setData({
          ...payload,
          formId,
          accountId
        })
      ),
      put(DataActions.addReferences(payload.references))
    ]);
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred geting submissions form"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const getSubmissions = function*(activeTab, data, filter) {
  const eventDetails = yield select(getEventDetails);
  const allSubmissions = R.propOr([], "submissions", data);

  const allSubmissionsFiltered = R.isEmpty(filter)
    ? allSubmissions
    : R.filter(
        submission =>
          R.compose(
            R.any(column => {
              const value = getValue(column, column.type);
              const valueString = toString(value, column.type, eventDetails, {
                ...column.settings
              });
              return R.contains(R.toLower(filter), R.toLower(valueString));
            }),
            R.filter(R.has("type")),
            R.values
          )(submission),
        allSubmissions
      );

  if (activeTab === TABS.SUBMITTED) {
    return R.filter(R.propEq("is_submitted", true), allSubmissionsFiltered);
  } else if (activeTab === TABS.DRAFT) {
    return R.filter(R.propEq("is_draft", true), allSubmissionsFiltered);
  }
  return allSubmissionsFiltered;
};

const setSubmissionsTabFilter = function*({ payload: activeTab }) {
  const oldActiveTab = yield select(getters.activeTab);
  const filter = yield select(getters.filter);

  if (activeTab !== oldActiveTab) {
    const data = yield select(getters.data);
    const submissions = yield call(getSubmissions, activeTab, data, filter);

    yield all([
      put(actions.setActiveTab(activeTab)),
      put(
        TableActions.setData(
          {
            canEditCells: false,
            columns: R.propOr([], "columns", data),
            rows: submissions,
            columnWidths: data.preferences.field_widths
          },
          {
            meta: {
              instanceId: TABLE_INSTANCE_ID
            }
          }
        )
      )
    ]);
  }
};

const setSubmissionsFilter = function*({ payload: filter }) {
  const activeTab = yield select(getters.activeTab);

  const data = yield select(getters.data);
  const submissions = yield call(getSubmissions, activeTab, data, filter);

  yield all([
    put(actions.setFilter(filter)),
    put(
      TableActions.setData(
        {
          canEditCells: false,
          columns: R.propOr([], "columns", data),
          rows: submissions,
          columnWidths: data.preferences.field_widths
        },
        {
          meta: {
            instanceId: TABLE_INSTANCE_ID
          }
        }
      )
    )
  ]);
};

const openSubmissionModal = function*({ payload: { row } }) {
  yield put(
    showModal({
      content: <OrderModal portal submissionId={row.id} />,
      wrapper: ModalWrapper
    })
  );
};

const goToForms = function*() {
  const eventDetails = yield select(getEventDetails);

  yield put(push(`/portals/${eventDetails.slug}/${eventDetails.uuid}`));
};

const editRow = function*({ payload: { row } }) {
  window.location = `/submissions/${row.event_slug}/${row.form_slug}/${row.id}`;
};

const showDeleteConfirmation = function*() {
  const handleDelete = makeFuture();
  yield put(
    showModal({
      content: (
        <DeleteConfirmation
          heading="Delete Record?"
          message={
            <div>
              {`
                Are you sure you want to remove this record?
                `}
              <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                This cannot be undone.
              </div>
            </div>
          }
          onConfirm={() => handleDelete.done(true)}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleDelete.onRealized);
};

const deleteSubmission = function*({ payload: { row } }) {
  const data = yield select(getters.data);
  const credentials = yield select(getCredentials);
  const shouldDelete = yield call(showDeleteConfirmation);

  if (shouldDelete) {
    try {
      yield call(SubmissionApi.delete, credentials, {
        submissionId: row.id
      });
      yield put(
        actions.init({ formId: data.formId, accountId: data.accountId })
      );
      yield put(showSnackbar({ message: "Submission deleted" }));
    } catch (e) {
      yield all([
        put(
          registerError([
            {
              system: e,
              user: "An error occurred removing a submission"
            }
          ])
        )
      ]);
    }
  }
};

const downloadForm = function*() {};

const addSubmission = function*() {
  const eventDetails = yield select(getEventDetails);
  const portalUser = yield select(getPortalUser);
  const form = yield select(getters.data);
  const user = yield select(getUser);
  const credentials = yield select(getCredentials);

  try {
    yield put(actions.setAddLoading(true));

    const contactId = R.pathEq(["active_view", "type"], "user", portalUser)
      ? R.pathOr(undefined, ["active_view", "contact_id"], portalUser)
      : undefined;

    const accountId = R.pathEq(["active_view", "type"], "account", portalUser)
      ? R.pathOr(undefined, ["active_view", "id"], portalUser)
      : undefined;

    const { record } = yield call(RecordsApi.post, credentials, {
      moduleId: form.base_module_id,
      record: {
        isDraft: true
      },
      options: {
        eventId: eventDetails.id
      }
    });

    const { submission } = yield call(SubmissionApi.post, credentials, {
      formId: form.id,
      eventId: eventDetails.id,
      userId: user.id,
      contactId,
      accountId,
      submissionRecordId: record.id
    });

    yield put(
      push(`/submissions/${eventDetails.slug}/${form.slug}/${submission.id}`)
    );
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred adding a submission"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setAddLoading(false));
  }
};

const watchTableActions = function*() {
  for (;;) {
    const action = yield take(TableActions.executeAction.type);
    if (action.meta.instanceId === TABLE_INSTANCE_ID) {
      const delegate = R.prop(action.payload.actionId, {
        [ROW_ACTIONS_ITEMS.OPEN_RECORD]: openSubmissionModal,
        [ROW_ACTIONS_ITEMS.OPEN_SUBMISSION_MODAL]: openSubmissionModal,
        [ROW_ACTIONS_ITEMS.EDIT]: editRow,
        [ROW_ACTIONS_ITEMS.DELETE]: deleteSubmission
      });

      if (delegate) {
        yield fork(delegate, action);
      }
    }
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchDownloadForm = function*() {
  yield takeEvery(actions.downloadForm.type, downloadForm);
};

const watchSetSubmissionsTabFilter = function*() {
  yield takeEvery(
    actions.setSubmissionsTabFilter.type,
    setSubmissionsTabFilter
  );
};

const watchSetSubmissionsFilter = function*() {
  yield takeEvery(actions.setSubmissionsFilter.type, setSubmissionsFilter);
};

const watchAddSubmission = function*() {
  yield takeEvery(actions.addSubmission.type, addSubmission);
};

const watchGoToForms = function*() {
  yield takeEvery(actions.goToForms.type, goToForms);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchGoToForms),
    fork(watchSetSubmissionsTabFilter),
    fork(watchSetSubmissionsFilter),
    fork(watchTableActions),
    fork(watchDownloadForm),
    fork(watchAddSubmission)
  ]);
};

export default rootSaga;
