import {
  call,
  put,
  fork,
  all,
  takeEvery,
  take,
  select
} from "redux-saga/effects";
import * as R from "ramda";

import { actions, getters } from "Schedules/ScheduleViewPicker";
import { actions as sliderSidebarActions } from "ui-kit/SliderSidebar/model";

import SchedulsApi from "Schedules/Schedule/api";

import { VIEWPICKER_INSTANCE_ID } from "Schedules/Schedule/constants";
import { navigateTo } from "utils/General";

import { getCredentials } from "redux/modules/user/selectors";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const eventId = yield select(getEventId);

  return {
    credentials,
    orgId,
    eventId
  };
};

const getSchedules = function*({ payload: isToggled }) {
  yield put(
    sliderSidebarActions.setIsDrawerOpen(isToggled, {
      meta: { instanceId: VIEWPICKER_INSTANCE_ID }
    })
  );
  if (!isToggled) {
    return false;
  }

  const { credentials, eventId } = yield call(getParams);

  const schedulesPayload = yield call(SchedulsApi.getSchedules, {
    credentials,
    eventId
  });
  const schedules = schedulesPayload.payload;

  yield all([actions.setLoading(true), put(actions.setSchedules(schedules))]);

  return true;
};

const deleteSchedule = function*({ payload: moduleId }) {
  const { credentials, eventId } = yield call(getParams);
  const activeScheduleId = yield select(getters.activeScheduleId);

  yield call(SchedulsApi.deleteSchedule, {
    credentials,
    moduleId
  });

  if (activeScheduleId === moduleId) {
    yield call(navigateTo, `/event/${eventId}/schedule/master`);
    return true;
  }

  yield call(getSchedules, { payload: true });

  return true;
};

const watchOpenPicker = function*() {
  for (;;) {
    const action = yield take(actions.setIsDrawerOpen.type);

    if (R.path(["meta", "instanceId"], action) === VIEWPICKER_INSTANCE_ID) {
      yield call(getSchedules, action);
    }
  }
};

const watchDeleteSchedule = function*() {
  yield takeEvery(actions.deleteSchedule.type, deleteSchedule);
};

const rootSaga = function*() {
  yield all([fork(watchOpenPicker), fork(watchDeleteSchedule)]);
};

export default rootSaga;
