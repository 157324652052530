import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { Div, Text3 } from "components/Base";

import { noop } from "utils/General";

import { actions } from "ui-kit/MiniItemsSelector";
import { bindInstance } from "redux-mvc";

import { getSelectedTab } from "ui-kit/MiniItemsSelector/selectors";

const decorate = connect(
  (state, props) => ({
    selectedId: getSelectedTab(state, props)
  }),
  bindInstance({
    selectTab: actions.setSelectedTab
  })
);

const VariantTabs = ({ selectedId = "", selectTab = noop, groups = [] }) => (
  <Div display="row" bc="neutral2" bb={1} px={4}>
    {R.map(
      group => (
        <Div
          key={group.id}
          py={2}
          px={3}
          bc="primary7"
          bb={group.id === selectedId ? 2 : 0}
          onClick={() => {
            selectTab(group.id);
          }}
        >
          <Text3 bold>{group.name}</Text3>
        </Div>
      ),
      groups
    )}
  </Div>
);

export default decorate(VariantTabs);
