import React from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

import { eventDetails as getEventDetails } from "redux/modules/event/selectors";

const decorate = connect(state => ({
  eventDetails: getEventDetails(state)
}));

const PageTitle = ({ eventDetails, titles = [] }) => (
  <Helmet>
    <title>
      {titles.join(" - ")} - {eventDetails.name} | Lennd
    </title>
  </Helmet>
);

export default decorate(PageTitle);
