import React from "react";
import { connect } from "react-redux";
import { Div, Text2, Text3, BigAvatar, CheckIcon } from "components/Base";
import { actions, getters } from "../model";
import { getManagers, getManagerRoles } from "../selectors";
import { ManagersTable } from "./Tables";
import { withProps } from "utils/General";

const decorate = connect(
  state => ({
    managers: getManagers(state),
    managerRoles: getManagerRoles(state),
    searchTerm: getters.usersFilter(state)
  }),
  {
    updateApprover: actions.updateApprover
  }
);

const Well = withProps({
  width: 1,
  bra: 2,
  bg: "gray0",
  p: 4,
  display: "row.flex-start.center"
})(Div);

const HasAccess = ({
  managers,
  managerRoles,
  updateApprover,
  searchTerm,
  handlers
}) => (
  <Div pb={10}>
    <Well mt={4}>
      <BigAvatar Icon={CheckIcon} bg="success5" />
      <Div ml={4}>
        <Text3 bold>
          To give a user access - You must assign them a permission profile with
          access.
        </Text3>
        <Text2 bold>Permission Profiles with access: {managerRoles}</Text2>
        <Text2>
          To create a new permission profile - go to: Settings > Users >
          Permissions
        </Text2>
      </Div>
    </Well>

    <ManagersTable
      emptyState={
        <Div py={10} display="row.center.center">
          <Text3>
            No managers {searchTerm.length ? `found for '${searchTerm}'` : ""}{" "}
          </Text3>
        </Div>
      }
      rowsData={managers.map(m => ({
        ...m,
        onToggleApprover: () =>
          updateApprover({
            userId: m.user_id,
            canApprove: !m.is_approver
          }),
        onEdit: () => handlers.showEditUserModal(m)
      }))}
    />
  </Div>
);

export default decorate(HasAccess);
