import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AssignForms from "./AssignForms";
import * as R from "ramda";

import * as EventSelectors from "redux/modules/event/selectors";
import * as FormsSelectors from "redux/modules/formsV2/forms/selectors";
import * as AccountAssignedFormsSelectors from "redux/modules/accounts/assignedForms/selectors";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { getForms } from "redux/modules/formsV2/forms/actions";
import {
  getAssignedForms,
  addAssignedForms,
  deleteAssignedForms
} from "redux/modules/accounts/assignedForms/actions";

function mapStateToProps(state) {
  return {
    eventDetails: EventSelectors.eventDetails(state),
    isFetchingAssignedForms: AccountAssignedFormsSelectors.isFetching(state),
    isFetchingForms: FormsSelectors.isFetching(state),
    assignedForms: AccountAssignedFormsSelectors.assignedForms(state).reduce(
      (a, b) => {
        a[b.id] = b;
        return a;
      },
      {}
    ),
    forms: R.compose(
      R.reduce((a, b) => {
        a[b.id] = b;
        return a;
      }, {}),
      R.map(f => ({
        ...f,
        name: f.name,
        search: f.name.toLowerCase()
      })),
      R.sortBy(
        R.compose(name => (name ? R.toLower(name) : name), R.propOr("", "name"))
      )
    )(FormsSelectors.forms(state))
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSnackbar,
      getForms,
      getAssignedForms,
      addAssignedForms,
      deleteAssignedForms
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignForms);
