import React from "react";
import { Div, BigFilledButton } from "components/Base";
import {
  getNotificationListFiltered,
  getHasMoreToShow,
  showEventName
} from "../selectors";
import { connect } from "react-redux";
import { ORDERED_TIMEFRAMES } from "../constants";
import NotificationItem from "./NotificationItem";
import EmptyState from "./Row/EmptyState";
import { actions, getters } from "../model";
import Loading from "./Row/EmptyState";
import { bindInstance } from "redux-mvc";

const NotificationHeader = ({ title }) => (
  <Div
    style={{
      height: "30px",
      display: "flex",
      alignItems: "center",
      fontSize: 13,
      fontWeight: 600,
      paddingLeft: "18px",
      borderTop: "1px solid #eaeaea",
      borderBottom: "1px solid #eaeaea"
    }}
  >
    {title}
  </Div>
);

const NotificationSection = ({
  title,
  list,
  instanceId,
  sectionId,
  showEventName
}) => (
  <Div>
    <NotificationHeader title={title} />
    <Div>
      {list.map((notification, index) => (
        <NotificationItem
          instanceId={instanceId}
          key={notification.notification_ids[0]}
          notification={notification}
          last={index === list.length - 1}
          sectionId={sectionId}
          showEventName={showEventName}
        />
      ))}
    </Div>
  </Div>
);

const decorator = connect(
  (state, props) => ({
    showEventName: showEventName(state, props),
    notifications: getNotificationListFiltered(state, props),
    hasMoreToShow: getHasMoreToShow(state, props),
    refreshing: getters.refreshing(state, props),
    loadingNext: getters.loadingNext(state, props),
    countOfVisibleNotifications: getters.countOfVisibleNotifications(
      state,
      props
    )
  }),
  bindInstance({
    loadNext: actions.loadNext
  })
);

export const NotificationsList = ({
  notifications,
  instanceId,
  refreshing,
  loadingNext,
  loadNext,
  countOfVisibleNotifications,
  hasMoreToShow,
  showEventName
}) => (
  <Div height={1} style={{ overflowY: "auto" }} width={1}>
    {refreshing ? (
      <Div display="row.center.center" height={1}>
        <Loading>Loading notifications...</Loading>
      </Div>
    ) : !countOfVisibleNotifications ? (
      <Div display="column.center.center" width={1} height={1}>
        <EmptyState>
          {showEventName
            ? "You have no notifications."
            : "You have no notifications for this event."}
        </EmptyState>
      </Div>
    ) : (
      <>
        {ORDERED_TIMEFRAMES.filter(({ id }) =>
          notifications.hasOwnProperty(id)
        ).map(({ name, id }) => (
          <NotificationSection
            instanceId={instanceId}
            key={id}
            title={name}
            list={notifications[id]}
            sectionId={id}
            showEventName={showEventName}
          />
        ))}
        {hasMoreToShow ? (
          <BigFilledButton
            bg="neutral0"
            width={1}
            isPadded={false}
            onClick={loadingNext ? undefined : () => loadNext()}
            bold
            color="#7700d0"
          >
            {loadingNext ? "Loading..." : "Load more..."}
          </BigFilledButton>
        ) : null}
      </>
    )}
  </Div>
);

export default decorator(NotificationsList);
