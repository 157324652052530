import * as R from "ramda";
import React from "react";

import { getValue, getSettings } from "../../utils";

const Dropdown = props => {
  const records = getValue(props, "records", []);
  const options = getSettings(props, "options", []);

  return (
    <div
      style={{
        userSelect: "none",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start"
      }}
    >
      {R.map(record => {
        if (record && record.value && typeof record.value !== "object") {
          const selectedOption = R.find(R.propEq("id", record.id), options);
          return (
            <div
              key={record.id}
              style={{
                marginRight: 4,
                borderRadius: 25,
                fontSize: 12,
                padding: "4px 8px",
                lineHeight: "normal",
                whiteSpace: "nowrap",
                flexBasis: "auto",
                flexShrink: 0,
                backgroundColor:
                  R.prop("backgroundColor", selectedOption) || "#EBEBEB",
                color: R.prop("textColor", selectedOption) || "#494949"
              }}
            >
              {record.value}
            </div>
          );
        }
        return "";
      }, records)}
    </div>
  );
};

export default Dropdown;
