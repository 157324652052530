import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import HeaderTitle from "components/Atoms/HeaderTitle";

const Header = ({ children, title }) => (
  <div styleName="headerContainer">
    {title ? <HeaderTitle title={title} /> : ""}
    {children}
  </div>
);

Header.propTypes = {
  children: PropTypes.node
};

export default CSSModules(Header, css);
