import * as R from "ramda";
import { createSelector } from "reselect";

import { FILTERS } from "Accounts/PeoplePanels/constants";

import { getters } from "Accounts/PeoplePanels";
import { getters as PeopleTypeGetters } from "Accounts/PeopleTypePanel";
import { getters as SelectFilterGetters } from "ui-kit/SelectFilter";
import { getters as SearchBarGetters } from "ui-kit/SearchBar";

import { eventId } from "redux/modules/event/selectors";
import { eventId as portalEventId } from "redux/modules/portal/selectors";
import { portalUser } from "redux/modules/portal/user/selectors";
import { getPathname } from "App/Router/selectors";

export const getFilters = R.always([
  { id: FILTERS.ALL, name: "All People" },
  { id: FILTERS.WITH_ASSIGNMENTS, name: "Has Items Assigned" },
  { id: FILTERS.WITHOUT_ASSIGNMENTS, name: "No Items Assigned" },
  { id: FILTERS.PRIMARY_CONTACT, name: "Primary Contacts" },
  { id: FILTERS.LOGIN_USERS, name: "Login Users" }
]);

const mapItems = ({ line_item_id, title, is_issued, order }) => ({
  id: line_item_id,
  name: title,
  issued: is_issued,
  order: order
});

const mapPasses = ({ group_summary, items }) =>
  R.length(items) === 1
    ? {
        ...mapItems(R.head(items)),
        name: R.prop("display_name")(R.head(items)),
        count: 1,
        children: []
      }
    : {
        id: R.propOr("", "title", group_summary),
        name: R.propOr("", "title", group_summary),
        count: R.propOr("", "requested", group_summary),
        order: R.propOr("", "order", group_summary),
        children: R.map(mapItems, items || [])
      };

const mapContact = contact => ({
  ...contact,
  id: R.prop("contact_id", contact),
  phone: "",
  passes: R.compose(
    R.sortBy(R.prop("order")),
    contact =>
      R.map(
        mapPasses,
        R.flatten(R.map(R.prop("item_groups"), R.prop("line_items", contact)))
      )
  )(contact)
});

const mapGroup = group => ({
  ...group,
  contacts: R.map(mapContact, group.contacts || [])
});

const getGroups = createSelector(
  getters.groups,
  R.map(mapGroup)
);

export const getFilteredContactsGroups = createSelector(
  SelectFilterGetters.activeFilterId,
  R.compose(
    R.toLower,
    SearchBarGetters.searchTerm
  ),
  getGroups,
  (activeFilterId, searchTerm, groups) => {
    const conds = [];
    if (R.not(R.isEmpty(searchTerm))) {
      conds.push(
        R.compose(
          R.contains(searchTerm),
          R.toLower,
          R.prop("name")
        )
      );
    }
    if (R.not(R.isEmpty(activeFilterId))) {
      if (
        activeFilterId === FILTERS.WITH_ASSIGNMENTS ||
        activeFilterId === FILTERS.WITHOUT_ASSIGNMENTS
      ) {
        conds.push(
          R.compose(
            activeFilterId === FILTERS.WITH_ASSIGNMENTS ? R.lt(0) : R.equals(0),
            R.length,
            R.prop("passes")
          )
        );
      }
      if (activeFilterId === FILTERS.PRIMARY_CONTACT) {
        conds.push(R.propEq("is_primary", true));
      }
      if (activeFilterId === FILTERS.LOGIN_USERS) {
        conds.push(R.propEq("can_login", true));
      }
    }
    if (R.length(conds)) {
      return R.map(
        group => ({
          ...group,
          contacts: R.filter(R.allPass(conds), group.contacts || [])
        }),
        groups
      );
    }

    return groups;
  }
);

export const getCountOfEnabledGroups = createSelector(
  getGroups,
  R.compose(
    R.length,
    R.filter(R.propEq("is_enabled", true))
  )
);

export const getAccountId = createSelector(
  portalUser,
  getPathname,
  (user, pathname) => {
    const portalAccountId = R.path(["active_view", "id"], user);
    const profileAccountId = R.prop(
      1,
      pathname.match(/account\/([a-z\d-]+)\/?/i)
    );
    return portalAccountId || profileAccountId;
  }
);

export const getSelectedGroup = createSelector(
  PeopleTypeGetters.showTable,
  getters.groups,
  (groupId, groups) => R.find(R.propEq("id", groupId), groups)
);

export const getEventId = R.ifElse(eventId, eventId, portalEventId);
