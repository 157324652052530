import React from "react";
import * as R from "ramda";
import moment from "moment";
import {
  Div,
  Text0,
  Text1,
  Text2,
  Text3,
  SmallAvatar,
  NoneIcon,
  EditIcon,
  RightIcon,
  Popover,
  PopoverMenu,
  MediumFilledButton,
  MediumShadedButton,
  MediunmTextButton,
  MediumOutlineButton,
  DownFilledIcon,
  SmallTextBox
} from "components/Base";

import { StatusIcon, StatusTag } from "utils/status-comps";
import { toClass } from "utils/General";
import statuses from "components/Global/Approvals/utils/statuses";

const Container = ({ children, ...props }) => (
  <Div display="row.space-between.center" width={1} {...props}>
    {children}
  </Div>
);

const RowStatusIcon = ({
  review,
  isAny,
  anyHaveApproved,
  groupProcessed,
  isDenied
}) => {
  switch (review) {
    case "approve":
      return <StatusIcon disabled={!groupProcessed} status={"approved"} />;
    case "reject":
      return <StatusIcon disabled={!groupProcessed} status={"denied"} />;
    default:
      if ((anyHaveApproved && isAny) || isDenied) {
        return <NoneIcon size={16} />;
      } else {
        return <StatusIcon disabled={!groupProcessed} status={"pending"} />;
      }
  }
};

const Row = ({
  anyHaveApproved,
  review,
  reviewedAt,
  isAny,
  name,
  photoURL,
  groupProcessed,
  isDenied
}) => {
  return (
    <Div display={"row.flex-start.center"} py={1}>
      <SmallAvatar id={name} text={name.replace(" ", "")} photoURL={photoURL} />
      <Div ml={2} display="column.center.flex-start">
        <Text2 bold>{name}</Text2>
        {reviewedAt && (
          <Text0
            title={moment(reviewedAt).format("dddd, MMMM Do YYYY, h:mm:ss a")}
          >
            {moment(reviewedAt).fromNow()}
          </Text0>
        )}
      </Div>
      <RowStatusIcon
        review={review}
        isAny={isAny}
        anyHaveApproved={anyHaveApproved}
        groupProcessed={groupProcessed}
        isDenied={isDenied}
      />
    </Div>
  );
};

// return isAny ? (
//   <NoneIcon size={16} />
// ) : (
// );
{
  /* {showAwaitingAny && (
                <Div display={"row.flex-end.center"}>
                  <Text1 py={1} color={"neutral6"} bold>
                    any required
                  </Text1>
                </Div>
              )} */
}

const ApproversPopup = ({ groups, currentGroupIndex, isDenied }) => {
  return (
    <Div p={2}>
      <Text2 color={"neutral8"} bold>
        Approvers
      </Text2>
      {R.addIndex(R.map)((group, i) => {
        const groupIsAny = group[0].isAny;
        const anyHaveApproved = R.any(R.propEq("review", "approve"), group);

        return (
          <Div key={group[0].id}>
            <Div py={1} display={"row.flex-start.center"}>
              {R.length(groups) !== 1 && (
                <Text1 mr={2} color={"neutral6"} bold>
                  {i + 1}
                </Text1>
              )}
              <Div height={2} bt={1} flex={1} bc={"neutral3"} />
              {R.length(group) !== 1 && (
                <Text1 ml={2} color={"neutral6"} bold>
                  {groupIsAny ? "any" : "all"}
                </Text1>
              )}
            </Div>

            <Div>
              {R.map(
                ({
                  id,
                  review,
                  isAny,
                  reviewed_at,
                  name,
                  photoURL,
                  sequence
                }) => {
                  return (
                    <Row
                      key={id}
                      groupProcessed={sequence <= currentGroupIndex}
                      review={review}
                      isAny={isAny}
                      anyHaveApproved={anyHaveApproved}
                      reviewedAt={reviewed_at}
                      name={name}
                      photoURL={photoURL}
                      isDenied={isDenied}
                    />
                  );
                },
                group
              )}
            </Div>
          </Div>
        );
      }, groups)}
    </Div>
  );
};

const Button = ({ onClick, status, top, bottom }) => (
  <Div flex={1} onClick={onClick} display={"row.flex-start.center"}>
    <StatusIcon status={status} />
    <Div ml={1} width={1} maxWidth={100}>
      <Text0 color={"neutral6"} bold truncate>
        {top}
      </Text0>
      <Text2 color={"neutral8"} bold truncate>
        {" "}
        {bottom}
      </Text2>
    </Div>
  </Div>
);

const AutoApprovedButton = toClass(({ onClick }) => (
  <Button onClick={onClick} status={"approved"} bottom={"Auto-Approved"} />
));

const ApprovedButton = toClass(({ onClick }) => (
  <StatusTag onClick={onClick} status={"approved"} />
));

const DeniedButton = toClass(({ onClick }) => (
  <StatusTag onClick={onClick} status={"denied"} />
));

const PendingButton = toClass(({ onClick, numPending }) => (
  <Button
    onClick={onClick}
    status={"pending"}
    top={"Pending approval"}
    bottom={`${numPending} remaining`}
  />
));

const PendingPreApproverButton = toClass(({ onClick, numToUser }) => (
  <Button
    onClick={onClick}
    status={"pending"}
    top={"Pending approval"}
    bottom={`${numToUser} before you`}
  />
));

const PendingPostApproverButton = toClass(({ onClick, numPending }) => (
  <Button
    onClick={onClick}
    status={"approved"}
    top={"You Approved"}
    bottom={`${numPending} remaining`}
  />
));

const ReviewMenuRow = ({ onClick, children }) => {
  return (
    <Div
      onClick={onClick}
      bg={{ default: "white", hover: "neutral0" }}
      display={"row.flex-start.center"}
      p={2}
      bb={1}
      bc={"neutral1"}
    >
      {children}
    </Div>
  );
};

const ReviewMenuApproversButton = toClass(
  ({ onClick, totalNumApprovers, userApproverPosition }) => {
    return (
      <ReviewMenuRow onClick={onClick}>
        <Div>
          <Text2>You are approver</Text2>
          <Text2 bold>
            {`${userApproverPosition + 1} of ${totalNumApprovers}`}
          </Text2>
        </Div>
        <Div
          bra={1}
          bg="neutral0"
          display="column.center.center"
          height={40}
          ml={3}
          px={1}
        >
          <RightIcon color="neutral6" />
        </Div>
      </ReviewMenuRow>
    );
  }
);

const ReviewMenu = ({
  approveLineItems,
  rejectLineItems,
  editLineItems,
  groups,
  currentGroupIndex,
  totalNumApprovers,
  userApproverPosition,
  isDenied
}) => {
  return (
    <Div>
      <Popover
        Label={ReviewMenuApproversButton}
        labelProps={{
          totalNumApprovers,
          userApproverPosition
        }}
      >
        {({}) => {
          return (
            <ApproversPopup
              groups={groups}
              isDenied={isDenied}
              currentGroupIndex={currentGroupIndex}
            />
          );
        }}
      </Popover>
      <ReviewMenuRow onClick={approveLineItems}>
        <StatusIcon status={"approved"} />
        <Text2 bold ml={1}>
          Approve
        </Text2>
      </ReviewMenuRow>
      <ReviewMenuRow onClick={rejectLineItems}>
        <StatusIcon status={"denied"} />
        <Text2 bold ml={1}>
          Deny
        </Text2>
      </ReviewMenuRow>
      {/*
      // @TODO: Hiding until we have an edit modal
      <ReviewMenuRow onClick={editLineItems}>
        <SmallTextBox OnlyIcon={EditIcon} />
        <Text3 bold ml={1}>
          Edit
        </Text3>
      </ReviewMenuRow>
      */}
    </Div>
  );
};

const ReviewButton = ({
  groups,
  currentGroupIndex,
  approveLineItems,
  rejectLineItems,
  editLineItems,
  totalNumApprovers,
  userApproverPosition,
  isDenied
}) => (
  <Popover
    Label={({ onClick }) => {
      return (
        <Div
          fs={2}
          onClick={onClick}
          fw={3}
          display="row.flex-start.center"
          color={{ default: "primary5", hover: "primary4" }}
        >
          Review <DownFilledIcon ml={1} color="primary5" />
        </Div>
      );
    }}
  >
    {({}) => {
      return (
        <ReviewMenu
          groups={groups}
          isDenied={isDenied}
          currentGroupIndex={currentGroupIndex}
          approveLineItems={approveLineItems}
          rejectLineItems={rejectLineItems}
          editLineItems={editLineItems}
          totalNumApprovers={totalNumApprovers}
          userApproverPosition={userApproverPosition}
        />
      );
    }}
  </Popover>
);

const ApproverLabel = ({
  approval_manifest,
  isApprover,
  isDenied,
  dateReviewed,
  atApproverStage,
  groups,
  currentGroupIndex,
  totalNumApprovers,
  numPending,
  approverGroupIndex,
  numToUser,
  approverApproved,
  approverReviewed,
  isApproved,
  containerProps = {},
  // handlers
  editLineItems,
  approveLineItems,
  rejectLineItems,
  removeApproval,
  showMenu,
  menuItems
}) => {
  // build options menu
  let menu = null;
  let items = menuItems ? [...menuItems] : [];
  if (showMenu) {
    if (approverReviewed) {
      items.push([statuses["undo"], removeApproval]);
    }

    if (items.length) {
      menu = (
        <PopoverMenu
          Label={({ onClick }) => (
            <MediumOutlineButton
              onClick={onClick}
              p={0}
              ml={2}
              style={{ width: 30, padding: 0 }}
            >
              <DownFilledIcon size={24} />
            </MediumOutlineButton>
          )}
          menuItems={items}
        />
      );
    }
  }

  if (
    R.isNil(approval_manifest) ||
    R.isEmpty(approval_manifest) ||
    R.isNil(approval_manifest.id)
  ) {
    return (
      <Container {...containerProps}>
        <AutoApprovedButton />
        {menu}
      </Container>
    );
  }

  const getPopoverProps = () => {
    if (isDenied) {
      return {
        Label: DeniedButton
      };
    }

    if (atApproverStage) {
      return {
        Label: ReviewButton,
        labelProps: {
          groups,
          currentGroupIndex,
          approveLineItems,
          rejectLineItems,
          editLineItems,
          totalNumApprovers,
          isDenied,
          userApproverPosition: totalNumApprovers - numPending
        }
      };
    }

    if (isApprover && approverGroupIndex > currentGroupIndex) {
      return {
        Label: PendingPreApproverButton,
        labelProps: {
          numToUser
        }
      };
    }

    if (isApprover && approverGroupIndex < currentGroupIndex) {
      if (approverApproved) {
        return {
          Label: PendingPostApproverButton,
          labelProps: { numPending }
        };
      } else {
        return {
          Label: PendingButton,
          labelProps: { numPending }
        };
      }
    }

    if (isApproved) {
      return {
        Label: ApprovedButton
      };
    }

    return {
      Label: PendingButton,
      labelProps: { numPending }
    };
  };

  return (
    <ApproversPopup
      groups={groups}
      currentGroupIndex={currentGroupIndex}
      isDenied={isDenied}
    />
  );
};

export default ApproverLabel;
