export const NAMESPACE = "PortalSession";

export const CONFERENCE_TABS_INSTANCE_ID = "conference-details";

export const PAGE_ACTIONS = {
  OPEN_LINK: "open-link",
  OPEN_ALERT: "open-alert",
  OPEN_SESSION: "open-session",
  OPEN_CONFERENCE: "open-conference",
  OPEN_REPLAY: "open-replay",
  OPEN_TRANSCRIPT: "open-transcript"
};

export const NUMBER_OF_VIDEOS = 4;

export const SESSION_DETAILS_TYPES = [
  { title: "People", id: "people" },
  { title: "Q&A", id: "questions" },
  { title: "Related", id: "related_content" }
];
