import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import { actions } from "../model";

import { Text2 } from "components/Base";
import { RECORD_TYPES } from "../constants";
import { withProps } from "utils/General";

const ActionsLink = withProps({ color: "primary5", bold: true })(Text2);

const getActionText = type =>
  R.propOr("View Record", type, {
    [RECORD_TYPES.INVITE_TO_EVENT]: undefined,
    [RECORD_TYPES.CREATE_ORDER]: "View order",
    [RECORD_TYPES.CANCEL_ORDER]: "View order",
    [RECORD_TYPES.SEND_EMAIL]: "View Email"
  });

const decorate = connect(
  null,
  {
    executeAction: actions.executeAction
  }
);

const CustomCellAction = ({ row, executeAction }) => {
  const actionType = R.prop("action", row);
  const text = getActionText(actionType);
  return (
    <ActionsLink
      onClick={
        !R.isNil(text)
          ? () =>
              executeAction({
                actionType,
                recordId: R.prop("id", row),
                moduleId: R.prop("moduleId", row)
              })
          : null
      }
    >
      {text || " - "}
    </ActionsLink>
  );
};

export default decorate(CustomCellAction);
