import * as R from "ramda";
import { createModule, createHandlers } from "redux-mvc";

import dataGrid from "ui-kit/Datagrid";

export const handlers = createHandlers({
  iniState: {
    tableIds: []
  },
  reducers: {
    addTableId: ({ tableIds }, { payload: tableId }) => ({
      tableIds: R.uniq(R.append(tableId, tableIds))
    })
  },
  namespace: "PeopleBlockTable"
});

const peopleBlock = createModule(handlers);

peopleBlock.plugModule(dataGrid);

const { actions, getters } = peopleBlock;

export { actions, getters };

export default peopleBlock;
