import React, { useEffect } from "react";
import SidebarWrapper from "OrgLight/Layout/View/Sidebar/SidebarWrapper";
import Header from "OrgLight/Layout/View/Header";
import Sidebar from "OrgLight/Layout/View/Sidebar";
import { Div } from "components/Base";
import { Helmet } from "react-helmet";

import { user as getUser } from "redux/modules/user/selectors";
import { orgDetails as getOrgDetails } from "redux/modules/organization/selectors";
import { actions } from "../model";

import { connect } from "react-redux";

const decorate = connect(
  state => ({
    orgDetails: getOrgDetails(state),
    user: getUser(state)
  }),
  {
    init: actions.init
  }
);

const Layout = ({ children, orgDetails, user, init }) => {
  useEffect(() => {
    init();

    const intercomId = "yaea3j4o";

    window.intercomSettings = {
      app_id: intercomId,
      name: [user.fname, user.lname].filter(v => v).join(" "),
      email: user.email,
      created_at: new Date(user.created_at).getTime(),
      user_id: user.id,
      company: {
        id: orgDetails.id,
        name: orgDetails.name
      }
    };

    if (!window.Intercom) {
      (function() {
        var w = window;
        var ic = w.Intercom;
        if (typeof ic === "function") {
          ic("reattach_activator");
          ic("update", w.intercomSettings);
        } else {
          var d = document;
          var i = function() {
            i.c(arguments);
          };
          i.q = [];
          i.c = function(args) {
            i.q.push(args);
          };
          w.Intercom = i;
          var l = function() {
            var s = d.createElement("script");
            s.type = "text/javascript";
            s.async = true;
            s.src = "https://widget.intercom.io/widget/" + intercomId;
            var x = d.getElementsByTagName("script")[0];
            x.parentNode.insertBefore(s, x);
          };
          if (document.readyState === "complete") {
            l();
          } else if (w.attachEvent) {
            w.attachEvent("onload", l);
          } else {
            w.addEventListener("load", l, false);
          }
        }
      })();
    }

    if (window.Intercom) {
      if (process.env.NODE_ENV !== "production") {
        // eslint-disable-next-line no-console
        console.log(`[Intercom] Booting for Intercom ID ${intercomId}...`);
      }

      window.Intercom("boot", {
        app_id: intercomId
      });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{orgDetails.name} | Lennd</title>
      </Helmet>
      <Header />
      <SidebarWrapper sidebar={<Sidebar />}>
        <Div
          flex={1}
          bg="#fff"
          style={{
            padding: "0 0",
            width: "100%",
            height: "100%",
            position: "relative",
            overflowY: "auto"
          }}
        >
          {children}
        </Div>
      </SidebarWrapper>
    </>
  );
};

export default decorate(Layout);
