import * as R from "ramda";
import { createSelector } from "reselect";
import * as STANDARD_MODULE_FIELDS from "@lennd/value-types/src/constants/standard-module-field-ids";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import { SPONSORSHIP_TYPE_ID, BOOTH_TYPE_ID } from "utils/item-types";
import { TYPE_ORDER } from "EventLight/Expo/Sales/constants";

import { makeInstanceSelector } from "redux-mvc";

import { getters } from "EventLight/Expo/Sales/model";

const mapWithIndex = R.addIndex(R.map);

export const FIELDS_ORDER = {
  [STANDARD_MODULE_FIELDS.ACCOUNTS.NAME]: -10,
  [STANDARD_MODULE_FIELDS.ACCOUNTS.ADDRESS_LINE_1]: -9,
  [STANDARD_MODULE_FIELDS.ACCOUNTS.ADDRESS_LINE_2]: -8,
  [STANDARD_MODULE_FIELDS.ACCOUNTS.ADDRESS_CITY]: -7,
  [STANDARD_MODULE_FIELDS.ACCOUNTS.ADDRESS_STATE]: -6,
  [STANDARD_MODULE_FIELDS.ACCOUNTS.ADDRESS_ZIP]: -5,
  [STANDARD_MODULE_FIELDS.CONTACTS.FIRST_NAME]: -10,
  [STANDARD_MODULE_FIELDS.CONTACTS.LAST_NAME]: -9,
  [STANDARD_MODULE_FIELDS.CONTACTS.EMAIL]: -8,
  [STANDARD_MODULE_FIELDS.CONTACTS.MOBILE_PHONE]: -7
};

const AUTO_ENABLED = [
  STANDARD_MODULE_FIELDS.ACCOUNTS.NAME,
  STANDARD_MODULE_FIELDS.CONTACTS.FIRST_NAME,
  STANDARD_MODULE_FIELDS.CONTACTS.LAST_NAME,
  STANDARD_MODULE_FIELDS.CONTACTS.EMAIL
];

const DEFAULT_VISIBLE_FIELDS = [
  STANDARD_MODULE_FIELDS.ACCOUNTS.NAME,
  STANDARD_MODULE_FIELDS.ACCOUNTS.ADDRESS_LINE_1,
  STANDARD_MODULE_FIELDS.ACCOUNTS.ADDRESS_LINE_2,
  STANDARD_MODULE_FIELDS.ACCOUNTS.ADDRESS_CITY,
  STANDARD_MODULE_FIELDS.ACCOUNTS.ADDRESS_STATE,
  STANDARD_MODULE_FIELDS.ACCOUNTS.ADDRESS_ZIP,
  STANDARD_MODULE_FIELDS.CONTACTS.FIRST_NAME,
  STANDARD_MODULE_FIELDS.CONTACTS.LAST_NAME,
  STANDARD_MODULE_FIELDS.CONTACTS.EMAIL,
  STANDARD_MODULE_FIELDS.CONTACTS.MOBILE_PHONE
];

const getModuleId = (_, props) => R.prop("moduleId", props);

const getVisibleByModule = makeInstanceSelector(
  getModuleId,
  getters.visibleFieldsByModule,
  (moduleId, visible) => R.propOr([], moduleId, visible)
)(getModuleId);

const formatField = ({ field, eventShowcase, visible, idx }) => {
  const autoEnabled = R.contains(field.id, AUTO_ENABLED);
  const existingField = R.find(R.propEq("field_id", field.id))(
    eventShowcase.fields
  );
  const isVisible =
    R.contains(field.id, visible) ||
    R.contains(field.id, DEFAULT_VISIBLE_FIELDS);

  return {
    ...field,
    order: autoEnabled
      ? FIELDS_ORDER[field.id]
      : existingField
      ? existingField.order
      : FIELDS_ORDER[field.id] || idx,
    visible: isVisible || autoEnabled || Boolean(existingField),
    autoEnabled,
    include: autoEnabled || Boolean(existingField),
    required: autoEnabled
      ? true
      : existingField
      ? existingField.is_required
      : false
  };
};

const getFieldsByModule = makeInstanceSelector(
  getters.eventShowcase,
  getModuleId,
  getters.fieldsByModule,
  getVisibleByModule,
  (eventShowcase, moduleId, fieldsByModule, visible) => {
    return mapWithIndex(
      (field, idx) => formatField({ field, eventShowcase, visible, idx }),
      R.propOr([], moduleId, fieldsByModule)
    );
  }
)(getModuleId);

const getAllFields = createSelector(
  getters.eventShowcase,
  getters.fieldsByModule,
  getVisibleByModule,
  (eventShowcase, fieldsByModule, visible) =>
    mapWithIndex(
      (field, idx) => formatField({ field, eventShowcase, visible, idx }),
      R.compose(
        R.flatten,
        R.values
      )(fieldsByModule)
    )
);

export const getFieldById = createSelector(
  getAllFields,
  (_, props) => R.prop("fieldId", props),
  (fields, fieldId) => R.propEq("id", fieldId)(fields)
);

export const getVisibleFields = createSelector(
  getAllFields,
  R.filter(R.prop("visible"))
);

const visibleFieldsByModule = makeInstanceSelector(
  getFieldsByModule,
  R.filter(R.prop("visible"))
)(getModuleId);

export const getVisibleFieldsByModule = makeInstanceSelector(
  visibleFieldsByModule,
  R.indexBy(R.prop("id"))
)(getModuleId);

export const getOrderByModule = makeInstanceSelector(
  visibleFieldsByModule,
  R.compose(
    R.map(R.prop("id")),
    R.sortBy(R.prop("order"))
  )
)(getModuleId);

export const getModalQuestions = (state, props) =>
  getFieldsByModule(state, {
    ...props,
    moduleId: getters.selectingQuestions(state, props)
  });

export const getModalSelectedQuestions = (state, props) =>
  R.map(
    R.prop("id"),
    visibleFieldsByModule(state, {
      ...props,
      moduleId: getters.selectingQuestions(state, props)
    })
  );

export const getVisiblePackagesByTypeId = createSelector(
  getters.eventShowcase,
  (_, props) => R.prop("typeId", props),
  (eventShowcase, typeId) => {
    return R.compose(
      R.map(v => v.variant_id),
      R.filter(
        v => v.variant.item.is_package && v.variant.item.type_id === typeId
      )
    )(eventShowcase.variants);
  }
);

export const getVisibleAddons = createSelector(
  getters.eventShowcase,
  eventShowcase => {
    return R.compose(
      R.map(v => v.variant_id),
      R.filter(v => !v.variant.item.is_package)
    )(eventShowcase.variants);
  }
);

export const getVisiblePackages = createSelector(
  state => getVisiblePackagesByTypeId(state, { typeId: BOOTH_TYPE_ID }),
  state => getVisiblePackagesByTypeId(state, { typeId: SPONSORSHIP_TYPE_ID }),
  getVisibleAddons,
  (boothPackages, sponsorPackages, addons) => {
    return [
      {
        name: "Exhibitor Packages",
        id: BOOTH_TYPE_ID,
        label: "package",
        visible: boothPackages.length
      },
      {
        name: "Sponsor Packages",
        id: SPONSORSHIP_TYPE_ID,
        label: "package",
        visible: sponsorPackages.length
      },
      {
        name: "Add-On / Upgrade Items",
        id: "addons",
        label: "add-on",
        visible: addons.length
      }
    ];
  }
);

export const getAllVariants = createSelector(
  getters.itemTypes,
  R.compose(
    R.flatten,
    R.map(R.prop("variants")),
    R.flatten,
    R.map(R.prop("items")),
    R.flatten,
    R.map(R.prop("groups"))
  )
);

export const getSelectedItemsToShow = createSelector(
  getters.eventShowcase,
  getAllVariants,
  getters.selectingItemsToShow,
  (showcase, variants, viewingTypeId) =>
    R.compose(
      R.reduce((map, variant) => {
        /*
        const fullVariant = variants.find(v => v.id === variant.variant_id);
        const defaultPriceId = R.compose(
              R.prop("id"),
              R.find(R.propEq("is_default", true))
            )(fullVariant ? fullVariant.prices : []);
        */

        map[variant.variant_id] = {
          priceId: variant.price_id
        };
        return map;
      }, {}),
      R.filter(v =>
        viewingTypeId === "addons" ? v.is_addon === true : v.is_addon === false
      ),
      R.sortBy(R.prop("order")),
      R.prop("variants")
    )(showcase)
);

export const getItemsToShow = createSelector(
  getters.selectingItemsToShow,
  getters.itemTypes,
  (typeId, itemTypes) => {
    const showPackages = [BOOTH_TYPE_ID, SPONSORSHIP_TYPE_ID].includes(typeId);
    return R.compose(
      R.sortBy(R.prop("order")),
      R.map(tab => ({
        id: tab.id,
        name: tab.name,
        order: TYPE_ORDER(tab.id),
        items: R.map(
          item => ({
            id: item.id,
            name: item.name,
            items: R.compose(
              R.map(v => ({
                ...v,
                name: v.display_name
              })),
              R.flatten,
              R.map(R.prop("variants")),
              R.filter(i => (showPackages ? i.is_package : !i.is_package))
            )(item.items)
          }),
          tab.groups
        )
      })),
      R.filter(t => (showPackages ? t.id === typeId : t))
    )(itemTypes);
  }
);

const selectTitles = {
  [STANDARD_MODULES.accounts.id]: "Select Group Fields",
  [STANDARD_MODULES.contacts.id]: "Select Person Fields",
  [STANDARD_MODULES.orders.id]: "Select Order Fields"
};

export const getSelectQuestionsModalTitle = R.compose(
  R.propOr("Select Fields", R.__, selectTitles), // eslint-disable-line no-underscore-dangle
  getters.selectingQuestions
);

const createTitles = {
  [STANDARD_MODULES.accounts.id]: "Add a New Group Field",
  [STANDARD_MODULES.contacts.id]: "Add a New Person Field",
  [STANDARD_MODULES.orders.id]: "Add a New Order Field"
};

export const getCreateQuestionsModalTitle = R.compose(
  R.propOr("Adding a New Question", R.__, createTitles), // eslint-disable-line no-underscore-dangle
  getters.creatingQuestion
);

export const getItemsToShowModalTitle = createSelector(
  getters.selectingItemsToShow,
  typeId => {
    if (typeId === "addons") {
      return "Select addons to show";
    } else if (typeId === SPONSORSHIP_TYPE_ID) {
      return "Select sponsor packages to show";
    } else if (typeId === BOOTH_TYPE_ID) {
      return "Select booth packages to show";
    }

    return "Select items to show";
  }
);
