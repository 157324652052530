import React from "react";
import { connect } from "react-redux";
import { getters } from "Modules/MergeTagTableModal";
import { PAGES } from "Modules/MergeTagTableModal/constants";
import { Div } from "components/Base";
import ChooseOptions from "./ChooseOptions";
import CopyText from "./CopyText";

const decorate = connect(state => ({
  selectedPage: getters.selectedPage(state)
}));

const Body = ({ selectedPage, setInputRef }) => (
  <Div width={1} height={463}>
    {selectedPage === PAGES.COPY_TEXT ? (
      <CopyText setInputRef={setInputRef} />
    ) : (
      <ChooseOptions />
    )}
  </Div>
);

export default decorate(Body);
