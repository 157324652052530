import { createSelector } from "reselect";
import * as R from "ramda";
import { getters } from "./model";

import moment from "moment";
import createDateRanges from "components/Global/Editors/EventDaysEditor/utils/create-date-ranges";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";

const getTrackingMethodName = method => {
  switch (method) {
    case "barcode":
      return "Barcode";
    case "rfid":
      return "RFID";
    case "number_range":
      return "Number Range";
    default:
      return "None";
  }
};

export const getItemGroups = createSelector(
  getters.itemGroups,
  getters.searchTerm,
  getEventDetails,
  (itemGroups, searchTerm, eventDetails) => {
    return R.map(group => {
      const term = searchTerm ? searchTerm.toLowerCase() : "";
      const items =
        searchTerm && searchTerm.length
          ? R.filter(i => i.name.toLowerCase().includes(term))(group.items)
          : group.items;
      return {
        ...group,
        countOfItems: items.length,
        forceCollapse: searchTerm.length && !items.length,
        collapsedDefault: !items.length,
        items: R.map(item => {
          const variant = item.variants[0];
          return {
            id: item.id,
            name: item.name,
            color: item.background_color,
            source: item.source,
            quantity: variant.track_inventory
              ? variant.inventory_quantity || "—"
              : "—",
            type: variant.track_inventory
              ? getTrackingMethodName(variant.tracking_method)
              : "—",
            numZones: 0,
            dateGroups: eventDetails.date_groups,
            dates: variant.rules
              ? createDateRanges(
                  variant.rules.map(rule =>
                    moment(rule.value).format("YYYY-MM-DD")
                  )
                )
              : null
          };
        }, items)
      };
    }, itemGroups);
  }
);
