import PropTypes from "prop-types";
import React, { Component } from "react";
import Editor from "./Editor";
import Loading from "components/Global/Loading";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class PortalProfile extends Component {
  render() {
    return (
      <div>
        <div styleName="title">Profile</div>
        {this.props.isFetchingPortalUser ? (
          <Loading content="Loading profile..." />
        ) : (
          <Editor {...this.props} />
        )}
      </div>
    );
  }
}

PortalProfile.propTypes = {
  isFetchingPortalUser: PropTypes.bool.isRequired
};

export default CSSModules(PortalProfile, css);
