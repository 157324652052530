import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { Link } from "react-router";

const Tab = ({ name, icon, action, isActive, style }) => (
  <Link className={!isActive ? css.tab : css.active} to={action} style={style}>
    <div>{name}</div>
    {icon}
  </Link>
);

Tab.propTypes = {
  name: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  icon: PropTypes.node
};

export default CSSModules(Tab, css);
