import React from "react";
import * as R from "ramda";
import {
  Div,
  Text0,
  Text1,
  Text3,
  MediumFilledButton,
  DownIcon,
  TinyToggle
} from "components/Base";
import FieldItem from "./FieldItem";
import TypesDropdown from "Forms/WizardModal/View/Common/TypesDropdown";
import { noop } from "utils/General";

const disabledStyleProps = {
  height: 54,
  bc: "neutral2",
  ba: 2,
  pr: 2,
  pl: 3,
  display: "row.space-between.center",
  bg: "white"
};

const enabledStyleProps = {
  p: 3,
  bg: "neutral1"
};

const FieldGroup = ({
  showModal,
  title,
  description = "",
  iconUrl,
  buttonText,
  groupData: { list, enabled, locked },
  toggle,
  fieldGroupName,
  toggleRequiredField,
  editItem,
  toggleGroupField,
  showTypesDropdown = false,
  typesList = [],
  selectedTypeId = null,
  dropdownText = "",
  selectTypeId = noop
}) => (
  <Div
    bra={1}
    width={595}
    mb={2}
    {...(enabled ? enabledStyleProps : disabledStyleProps)}
  >
    <Div display="row.space-between.center" mb={enabled ? 3 : 0} width={1}>
      <Div display="row.flex-start.center">
        <img
          src={iconUrl}
          width={36}
          height={36}
          style={{
            marginRight: 10
          }}
        />
        <Div>
          <Text3 bold>{title}</Text3>
          {!R.isEmpty(description) && (
            <Text0 color="neutral5">{description}</Text0>
          )}
        </Div>
      </Div>
      {!enabled && (
        <MediumFilledButton
          onClick={() => {
            toggleGroupField({ fieldGroupName });
          }}
          color="black"
          bg="neutral2"
        >
          ADD SECTION
        </MediumFilledButton>
      )}
      {enabled && !locked && (
        <Div mr={3} display="row.flex-start.center">
          <Text1 mr={2} color="neutral6">
            Added
          </Text1>
          <TinyToggle
            active={true}
            onClick={() => {
              toggleGroupField({ fieldGroupName });
            }}
          />
        </Div>
      )}
    </Div>
    {enabled && showTypesDropdown && (
      <Div mb={2}>
        <TypesDropdown
          typesList={typesList}
          selectedTypeId={selectedTypeId}
          text={dropdownText}
          selectTypeId={selectTypeId}
        />
      </Div>
    )}
    {enabled && (
      <Div width={1}>
        <Div width={1} display="row.space-between.center" px={1} mb={1}>
          <Div display="row">
            <Text0 color="black" bold ml={3}>
              Include
            </Text0>
            <Text0 color="black" bold ml={4}>
              Field Name
            </Text0>
          </Div>
          <Text0 color="black" bold mr={3}>
            *Require
          </Text0>
        </Div>
        <Div width={1}>
          {R.map(
            field => (
              <FieldItem
                key={field.id}
                name={field.name}
                type={field.type}
                id={field.id}
                selected={field.selected}
                required={field.required}
                toggleSelectedModuleField={() => {
                  toggle({ fieldId: field.id, fieldGroupName });
                }}
                toggleRequiredModuleField={() => {
                  toggleRequiredField({ fieldId: field.id, fieldGroupName });
                }}
                editItem={() => {
                  editItem(field.moduleId, field.id);
                }}
              />
            ),
            list
          )}
        </Div>
        <MediumFilledButton
          color="black"
          bg="neutral2"
          LeftIcon={DownIcon}
          onClick={() => showModal({ fieldGroupName })}
        >
          {buttonText}
        </MediumFilledButton>
      </Div>
    )}
  </Div>
);

export default FieldGroup;
