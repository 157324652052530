import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "./constants";

const iniState = {
  title: "",
  backgroundImageUrl: "",
  remindersEnabled: false,
  logoImageUrl: "",
  featuredContent: [],
  leftSidebarSections: [],
  featuredContentTitle: "",
  footerSections: [],
  activeAlerts: [],
  alertVisible: true
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    executeAction: R.identity,
    setFeaturedVideoId: (state, { payload: videoId }) => ({
      featuredContent: R.map(
        video => ({ ...video, featured: video.id === videoId }),
        state.featuredContent
      )
    }),
    setInitialData: (
      _,
      {
        payload: {
          title,
          background_image_url: backgroundImageUrl,
          reminders_enabled: remindersEnabled,
          logo_image_url: logoImageUrl,
          featured_content: featuredContent,
          left_sidebar_sections: leftSidebarSections,
          featured_content_title: featuredContentTitle,
          footer_sections: footerSections,
          active_alerts: activeAlerts
        }
      }
    ) => ({
      title,
      backgroundImageUrl,
      remindersEnabled,
      logoImageUrl,
      featuredContent,
      leftSidebarSections,
      featuredContentTitle,
      footerSections,
      activeAlerts
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
