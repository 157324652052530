import React, { Component, Fragment } from "react";
import {
  SmallOutlineButton,
  SearchIcon,
  MediumTextButton,
  MediumTextBox,
  MediumFilledButton,
  CloseIcon,
  Paginator,
  ScanIcon,
  SmallShadedBox,
  FullSizeDotIcon,
  Div,
  Text1,
  Text2,
  Text3,
  Text4,
  LoadingIcon
} from "components/Base";
import Modal from "components/Global/Modal/Layout/StyleWrapper";
import FocusableSearch from "./FocusableSearch";

const EmptyState = ({ show, isSearching, isLoading }) => {
  if (!show) return <div />;
  if (isLoading) {
    return (
      <Div height={300} display="column.center.center">
        <LoadingIcon size={40} />
      </Div>
    );
  }
  let title = "Search Existing Pass Inventory";
  let content =
    "Only passes that are tracked by barcode, UID, or number range are available to search.";
  if (isSearching) {
    title = "We didn't find any matching passes";
    content =
      "It has not been scanned for this event yet or uploaded as available inventory.";
  }
  return (
    <Div height={300} display="column.center.center">
      <Text4 bold pb={2}>
        {title}
      </Text4>
      <Text2 textAlign="center" maxWidth={250}>
        {content}
      </Text2>
    </Div>
  );
};
const Card = ({
  title = "Not Yet Issued",
  passName,
  passColor,
  groupName,
  orderNumber,
  onOrderClick,
  codes = [],
  issuance,
  actions = []
}) => (
  <Div
    width={1}
    px={4}
    py={2}
    ba={1}
    bc="neutral1"
    bra={1}
    mb={1}
    display="row.flex-start.flex-start"
  >
    <Div flex={1}>
      <Div display="row">
        <Div width={1} maxWidth={282} truncate>
          <Text3 bold>{title}</Text3>
          <Div display="row.flex-start.center">
            <Div size={10} mr={1} pill style={{ backgroundColor: passColor }} />
            <Text1 bold>{passName}</Text1>
          </Div>
        </Div>
        <Div flex={1}>
          {codes.map(code => (
            <SmallShadedBox key={code} bg="neutral1">
              {code}
            </SmallShadedBox>
          ))}
        </Div>
      </Div>
      {orderNumber || issuance || groupName ? (
        <Div pt={3} display="row.space-between.flex-start">
          <Div flex={1}>
            <Text1 color="gray4" bold uppercase>
              Related Group
            </Text1>
            <Text1 bold>{groupName}</Text1>
          </Div>
          <Div flex={1}>
            <Text1 color="gray4" bold uppercase>
              order
            </Text1>
            <Text1 bold onClick={onOrderClick}>
              {orderNumber}
            </Text1>
          </Div>
          <Div flex={2}>
            <Text1 color="gray4" bold uppercase>
              Issuance
            </Text1>
            <Text1 bold>{issuance}</Text1>
          </Div>
        </Div>
      ) : (
        ""
      )}
    </Div>
    <Div flex={0} display="column">
      {actions.map(([title, action]) => (
        <SmallOutlineButton onClick={action} mb={1}>
          {title}
        </SmallOutlineButton>
      ))}
    </Div>
  </Div>
);

class InventoryLookupView extends Component {
  render() {
    const {
      totalResults,
      onSearch,
      results,
      page,
      gotoPage,
      clearSearchResults,
      loading
    } = this.props;
    return (
      <FocusableSearch onSubmit={() => {}} onChange={onSearch}>
        {({ inputProps, resetInput }) => (
          <Modal
            width={600}
            heading="Search Pass Inventory"
            bodyStyles={{ padding: 0 }}
          >
            <Div p={4}>
              <Div bra={2} bc="primary5" ba={1} display="row.flex-start.center">
                <ScanIcon mx={2} size={25} color="primary5" />
                <input {...inputProps} />
                {inputProps.value.length ? (
                  <MediumFilledButton
                    OnlyIcon={CloseIcon}
                    onClick={() => {
                      clearSearchResults();
                      resetInput();
                    }}
                    bg={"primary5"}
                    brl={0}
                  />
                ) : (
                  <MediumTextBox OnlyIcon={SearchIcon} brl={0} />
                )}
              </Div>
              {inputProps.value.length && !loading ? (
                <Fragment>
                  <Div display="row.flex-start.center" pt={4}>
                    <Div bb={2} bc="primary5">
                      <Text2 bold px={3}>
                        Showing results for "{inputProps.value}"
                      </Text2>
                    </Div>
                    <MediumTextButton
                      color="primary5"
                      onClick={() => {
                        clearSearchResults();
                        resetInput();
                      }}
                    >
                      CLEAR SEARCH
                    </MediumTextButton>
                  </Div>
                  <Div
                    display="row.flex-start.center"
                    bg="neutral1"
                    brt={1}
                    py={3}
                    px={2}
                  >
                    <Text2 bold flex={2}>
                      Assigned To
                    </Text2>
                    <Text2 bold flex={1}>
                      Tracking Codes
                    </Text2>
                    <Text2 bold flex={0}>
                      Actions
                    </Text2>
                  </Div>
                  {results.map(r => <Card {...r} />)}
                  {totalResults > 10 ? (
                    <Div display="row.center.center">
                      <Paginator
                        goTo={newpage => gotoPage(newpage, inputProps.value)}
                        showResultsDisplay={false}
                        showPerPageSelector={false}
                        selectOptions={[]}
                        resultsPerPage={10}
                        currentPage={page}
                        totalNumResults={totalResults}
                        paginatorLength={5}
                      />
                    </Div>
                  ) : (
                    ""
                  )}
                </Fragment>
              ) : (
                ""
              )}
              <EmptyState
                show={!results.length || loading}
                isLoading={loading}
                isSearching={inputProps.value.length && !results.length}
              />
            </Div>
          </Modal>
        )}
      </FocusableSearch>
    );
  }
}

export default InventoryLookupView;
