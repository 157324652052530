import React from "react";
import { connect } from "react-redux";

import { Div, LeftIcon, Text2 } from "components/Base";
import css from "ui-kit/ViewPicker/View/styles.scss";

import { actions, getters } from "EventLight/Forms/Form/Results";

const decorate = connect(
  state => ({
    form: getters.form(state)
  }),
  {
    goToAllForms: actions.goToAllForms
  }
);

const Header = ({ form, goToAllForms }) => (
  <Div display="row.flex-start.center" width={1} mt={3} mb={3}>
    {form.id ? (
      <Div display="row.space-between.center" width={1}>
        <Div display="column">
          <Div
            display="row.center.center"
            onClick={goToAllForms}
            bra={1}
            className={css.button2}
            mb={3}
            style={{
              width: "109px",
              height: "32px",
              padding: 0
            }}
          >
            <Div flex={1} display="row.flex-start.center" pl={3}>
              <Div display="row.flex-start.center">
                <LeftIcon mr={2} color="white" />
                <Text2 color="white" bold>
                  All forms
                </Text2>
              </Div>
            </Div>
          </Div>

          <Div color="black" fw={3} fs={5} mr={2}>
            {form.name}
          </Div>
        </Div>
      </Div>
    ) : (
      <Div height={32} width={1}></Div>
    )}
  </Div>
);

export default decorate(Header);
