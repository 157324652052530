import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { getters, actions } from "Modules/ImportModal";
import {
  Div,
  Text5,
  Text4,
  Text3,
  Text2,
  SuperFilledButton,
  Text1,
  CheckIcon,
  SearchIcon,
  CloseIcon,
  BigShadedInput
} from "components/Base";
import CheckBox from "material-ui/Checkbox";
import { FIELD_IDS } from "Modules/ImportModal/constants";
import { getFilterEnabledGroups } from "Modules/ImportModal/selectors";

const decorate = connect(
  state => ({
    mapObject: getters.mapObject(state),
    fieldGroupsIndex: getters.fieldGroupsIndex(state),
    enabledFieldsGroup: getters.enabledFieldsGroup(state),
    filteredFields: getFilterEnabledGroups(state)
  }),
  {
    hideSelectMoreModal: actions.hideSelectMoreModal,
    toggleEnabledField: actions.toggleEnabledField,
    updateEnabledFields: actions.updateEnabledFields,
    disableField: actions.disableField,
    enableAllFields: actions.enableAllFields,
    disableAllFields: actions.disableAllFields,
    setSelectedFieldsFilter: actions.setSelectedFieldsFilter
  }
);

const fieldProps = {
  display: "row.flex-start.center",
  style: { width: "calc(100% - 10px)" },
  height: 32,
  bra: 1,
  mt: 1,
  bc: "neutral3",
  px: 2
};

const shouldUpdatedField = field =>
  !field.required && field.id !== FIELD_IDS.FULL_NAME;

const SelectMoreModal = ({
  hideSelectMoreModal,
  enabledFieldsGroup,
  toggleEnabledField,
  updateEnabledFields,
  fieldGroupsIndex,
  disableField,
  enableAllFields,
  disableAllFields,
  setSelectedFieldsFilter,
  filteredFields
}) => (
  <Div
    display="row.center.center"
    flex={1}
    style={{
      position: "absolute",
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      backgroundColor: "rgba(0,0,0,0.3)",
      zIndex: 5
    }}
  >
    <Div
      display="column.space-between"
      width={715}
      height={528}
      bra={1}
      bg="white"
      p={6}
    >
      <Div>
        <Text5 bold>Choose more group fields to map CSV data into</Text5>
        <Div display="row" mt={1}>
          <Div onClick={enableAllFields}>
            <Text1 color="primary9" underline>
              All
            </Text1>
          </Div>
          <Div onClick={disableAllFields}>
            <Text1 color="primary9" underline ml={1}>
              None
            </Text1>
          </Div>
        </Div>
      </Div>
      <Div display="row.space-between" height={360} style={{ width: "100%" }}>
        <Div
          width={330}
          bg="white"
          mr={3}
          py={1}
          height={360}
          style={{ overflowY: "auto" }}
        >
          <BigShadedInput
            continuous
            LeftIcon={SearchIcon}
            flex={1}
            placeholder="Search fields"
            onChange={text => {
              setSelectedFieldsFilter(text);
            }}
            bg="neutral0"
            mb={1}
            style={{ width: "calc(100% - 10px)" }}
          />
          {R.map(
            field =>
              field.required || field.id === FIELD_IDS.FULL_NAME ? (
                <Div {...fieldProps} key={field.id}>
                  <CheckIcon color="primary9" size={20} ml={1} />
                  <Text2 bold ml={4}>
                    {field.name}
                  </Text2>
                </Div>
              ) : (
                <Div
                  {...fieldProps}
                  key={field.id}
                  onClick={() => {
                    toggleEnabledField({ id: field.id });
                  }}
                >
                  <CheckBox
                    style={{ width: "auto" }}
                    checked={field.enabled}
                    onClick={() => {}}
                  />
                  <Text2 bold>{field.name}</Text2>
                </Div>
              ),
            filteredFields
          )}
        </Div>

        <Div
          width={310}
          height={360}
          bg="neutral0"
          bra={2}
          p={4}
          style={{ overflowY: "auto" }}
        >
          <Text3 bold>Selected</Text3>
          {R.compose(
            R.map(field => (
              <Div
                shadow={1}
                mt={1}
                bg={
                  shouldUpdatedField(field)
                    ? { default: "white", hover: "gray1" }
                    : "white"
                }
                color="black"
                style={{ width: "calc(100% - 10px)" }}
                display="row.space-between.center"
                onClick={() => {
                  if (shouldUpdatedField(field)) {
                    disableField({ id: field.id });
                  }
                }}
                bra={1}
                height={33}
                px={3}
                key={field.id}
              >
                <Text2 bold>{field.name}</Text2>
                {shouldUpdatedField(field) && (
                  <CloseIcon
                    color={{ default: "neutral6", hover: "danger8" }}
                  />
                )}
              </Div>
            )),
            R.filter(field => field.enabled)
          )(enabledFieldsGroup)}
        </Div>
      </Div>
      <Div display="row.flex-start">
        <SuperFilledButton
          bg="altB5"
          width={164}
          onClick={() => {
            updateEnabledFields({ index: fieldGroupsIndex });
          }}
        >
          Done
        </SuperFilledButton>
        <Div
          width={102}
          ml={2}
          onClick={hideSelectMoreModal}
          bg="white"
          bc="neutral1"
          bra={1}
          display="row.center.center"
          ba={2}
        >
          <Text4 color="neutral5" bold>
            Cancel
          </Text4>
        </Div>
      </Div>
    </Div>
  </Div>
);

export default decorate(SelectMoreModal);
