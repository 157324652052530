import * as R from "ramda";

import React from "react";
import moment from "moment-timezone";

import { getValue, getSettings } from "../../utils";

const Datetime = props => {
  const value = getValue(props, "iso");
  const format = getSettings(props, "format", "LLL");
  const timezone = getSettings(props, "timezone");

  if (!timezone) {
    console.warn("no timezone passed");
  }

  if (R.isEmpty(value) || R.isNil(value)) {
    return <div />;
  }

  return <div>{moment.tz(value, timezone).format(format)}</div>;
};

export default Datetime;
