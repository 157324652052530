import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import * as R from "ramda";
import { push } from "react-router-redux";
import { actions, getters } from "./index";
import { actions as itemsActions } from "Event/ItemCatalogItems";
import { actions as zoneActions } from "Event/Zones/model";
import { getZones } from "Event/Zones/selectors";
import { actions as accessGridTableActions } from "ui-kit/AccessGridTable/model";
import {
  actions as addBulkItemsActions,
  getters as addBulkItemsGetters
} from "Event/AddBulkItemsModal";
import { getItemsList } from "Event/AddBulkItemsModal/selectors";

import { registerError } from "redux/modules/errors/actions";

import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";
import { getTypeItemList } from "Event/ItemCatalogItems/selectors";
import { showSnackbar } from "redux/modules/snackbar/actions";
import moveItemInArray from "utils/move-item-in-array";
import itemTypesApi from "redux/modules/items/types/api";
import Api from "./api";
import { SUB_TABS } from "Event/ItemCatalog/constants";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  const eventDetails = yield select(getEventDetails);
  const userId = yield select(getUserId);

  return {
    credentials,
    eventId,
    eventDetails,
    userId
  };
};

const init = function*() {
  const typesData = yield select(getters.types);
  const accessGridData = yield select(getters.accessGrid);

  if (!R.isNil(accessGridData)) {
    yield put(accessGridTableActions.setData(accessGridData));
  }

  if (R.isEmpty(typesData)) {
    try {
      yield put(actions.setLoading(true));

      const { credentials, eventId } = yield call(getParams);

      const [
        { payload },
        { payload: dataGrid },
        { payload: zones }
      ] = yield all([
        call(itemTypesApi.getItemTypesByEvent, credentials, eventId),
        call(Api.getAccessGrid, { credentials, eventId }),
        call(Api.getZoneGroups, { credentials, eventId })
      ]);

      const sortedTypes = R.sort((a, b) => a.order - b.order, payload);
      yield put(
        actions.setInitialData({
          types: sortedTypes,
          dataGrid
        })
      );
      yield put(itemsActions.init({ types: sortedTypes }));
      yield put(zoneActions.setZones(zones));
      yield put(accessGridTableActions.setData(dataGrid));
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error loading types"
          }
        ])
      );
    } finally {
      yield put(actions.setLoading(false));
    }
  }
};

const showBulkModal = function*({ payload: { isZones = false } }) {
  const typeId = yield select(getters.typeId);
  const typesData = yield select(getters.types);
  const type = R.find(R.propEq("id", typeId), typesData);
  const typeName = R.propOr("", "name", type);

  yield put(actions.setIsZones(isZones));
  if (!isZones) {
    const groups = R.propOr([], "groups", type);
    const groupId = R.compose(
      R.propOr("", "id"),
      grps => grps[0],
      R.propOr([], "groups")
    )(type);

    yield put(
      addBulkItemsActions.setInitialData({
        typeId,
        typeName,
        groups,
        groupId
      })
    );
  } else {
    const zones = yield select(getZones);
    yield put(
      addBulkItemsActions.setInitialData({
        typeId,
        typeName,
        groups: zones,
        groupId: R.propOr("", "id", zones[0])
      })
    );
  }
  yield put(actions.setShowBulkItemsModal(true));
};

const fetchTypes = function*() {
  try {
    const { credentials, eventId } = yield call(getParams);
    const typeId = yield select(getters.typeId);

    const { payload } = yield call(
      itemTypesApi.getItemTypesByEvent,
      credentials,
      eventId
    );
    const sortedTypes = R.sort((a, b) => a.order - b.order, payload);

    yield put(actions.setInitialData({ types: sortedTypes, typeId }));
    yield put(itemsActions.init({ types: sortedTypes, typeId }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error loading types"
        }
      ])
    );
  }
};

const updateTypeId = function*({ payload: { typeId } }) {
  yield put(actions.setTypeId(typeId));
  yield put(itemsActions.setTypeId(typeId));
};

const fetchZones = function*() {
  try {
    const { credentials, eventId } = yield call(getParams);

    const { payload: zones } = yield call(Api.getZoneGroups, {
      credentials,
      eventId
    });

    yield put(zoneActions.setZones(zones));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error loading zones"
        }
      ])
    );
  }
};

const addCategory = function*({ payload: data }) {
  try {
    const { credentials, eventId } = yield call(getParams);
    const typeId = yield select(getters.typeId);

    yield call(Api.addItemGroup, {
      credentials,
      data: {
        ...data,
        typeId,
        eventId
      }
    });

    yield put(
      showSnackbar({
        message: "Category added",
        action: "OK"
      })
    );

    yield call(fetchTypes);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error adding category"
        }
      ])
    );
  }
};

const addZoneCategory = function*({ payload: data }) {
  try {
    const { credentials, eventId } = yield call(getParams);

    yield call(Api.addZoneGroup, {
      credentials,
      data: {
        ...data,
        eventId
      }
    });

    yield put(
      showSnackbar({
        message: "Category added",
        action: "OK"
      })
    );

    yield call(fetchZones);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error adding category"
        }
      ])
    );
  }
};

const updateCategory = function*({ payload: group }) {
  try {
    const { credentials } = yield call(getParams);

    yield call(Api.updateItemGroup, {
      credentials,
      data: {
        group,
        groupId: group.id
      }
    });

    yield put(
      showSnackbar({
        message: "Category updated",
        action: "OK"
      })
    );

    yield call(fetchTypes);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error updating category"
        }
      ])
    );
  }
};

const updateZoneCategory = function*({ payload: group }) {
  try {
    const { credentials } = yield call(getParams);

    yield call(Api.updateZoneGroup, {
      credentials,
      data: {
        group,
        groupId: group.id
      }
    });

    yield put(
      showSnackbar({
        message: "Category updated",
        action: "OK"
      })
    );

    yield call(fetchZones);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error updating category"
        }
      ])
    );
  }
};

const deleteCategory = function*({ payload: { group } }) {
  try {
    const { credentials } = yield call(getParams);

    const itemType = yield select(getTypeItemList);

    const data = {
      groupId: group.id,
      replaceWithGroupId: R.propOr(
        "",
        "id",
        itemType.groups.filter(g => g.id !== group.id)[0]
      )
    };

    yield call(Api.deleteItemGroup, {
      credentials,
      data
    });

    yield put(
      showSnackbar({
        message: "Category deleted",
        action: "OK"
      })
    );

    yield call(fetchTypes);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error deleting item"
        }
      ])
    );
  }
};

const refetchAcessGridData = function*() {
  try {
    const { credentials, eventId } = yield call(getParams);

    const { payload } = yield call(Api.getAccessGrid, { credentials, eventId });
    yield put(actions.setAccessGrid(payload));
    yield put(accessGridTableActions.setData(payload));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred fetching access grid"
        }
      ])
    );
  }
};

const deleteZoneCategory = function*({ payload: { group } }) {
  try {
    const { credentials } = yield call(getParams);

    const zoneGroups = yield select(getZones);

    const data = {
      groupId: group.id,
      replaceWithGroupId: R.propOr(
        "",
        "id",
        zoneGroups.filter(g => g.id !== group.id)[0]
      )
    };

    yield call(Api.deleteZoneGroup, {
      credentials,
      data
    });

    yield put(
      showSnackbar({
        message: "Category deleted",
        action: "OK"
      })
    );

    yield all([call(fetchZones), call(refetchAcessGridData)]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error deleting zone"
        }
      ])
    );
  }
};

const moveCategoryUp = function*({ payload: { currentPosition } }) {
  if (currentPosition > 0) {
    try {
      const { credentials, eventId } = yield call(getParams);

      const itemType = yield select(getTypeItemList);
      const groups = R.propOr([], "groups", itemType);

      yield call(Api.bulkUpdateItemGroups, {
        credentials,
        data: {
          eventId,
          bulk: true,
          groups: moveItemInArray(
            groups,
            currentPosition,
            currentPosition - 1
          ).map((g, order) => ({
            id: g.id,
            order
          }))
        }
      });

      yield call(fetchTypes);
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error moving up category"
          }
        ])
      );
    }
  }
};

const moveCategoryDown = function*({ payload: { currentPosition } }) {
  try {
    const { credentials, eventId } = yield call(getParams);

    const itemType = yield select(getTypeItemList);
    const groups = R.propOr([], "groups", itemType);

    yield call(Api.bulkUpdateItemGroups, {
      credentials,
      data: {
        eventId,
        bulk: true,
        groups: moveItemInArray(
          groups,
          currentPosition,
          currentPosition + 1
        ).map((g, order) => ({
          id: g.id,
          order
        }))
      }
    });

    yield call(fetchTypes);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error moving down category"
        }
      ])
    );
  }
};

const moveItemUp = function*({ payload: { groupId, order: currentPosition } }) {
  if (currentPosition > 0) {
    try {
      const { credentials, eventId } = yield call(getParams);

      const itemType = yield select(getTypeItemList);
      const items = R.compose(
        R.propOr([], "items"),
        R.find(({ id }) => id === groupId),
        R.propOr([], "groups")
      )(itemType);

      yield call(Api.bulkUpdateItems, {
        credentials,
        data: {
          eventId,
          bulk: true,
          items: moveItemInArray(
            items,
            currentPosition,
            currentPosition - 1
          ).map((g, order) => ({
            id: g.id,
            order
          }))
        }
      });

      yield call(fetchTypes);
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error moving up item"
          }
        ])
      );
    }
  }
};

const moveItemDown = function*({
  payload: { groupId, order: currentPosition }
}) {
  try {
    const { credentials, eventId } = yield call(getParams);

    const itemType = yield select(getTypeItemList);
    const items = R.compose(
      R.propOr([], "items"),
      R.find(({ id }) => id === groupId),
      R.propOr([], "groups")
    )(itemType);

    yield call(Api.bulkUpdateItems, {
      credentials,
      data: {
        eventId,
        bulk: true,
        items: moveItemInArray(items, currentPosition, currentPosition + 1).map(
          (g, order) => ({
            id: g.id,
            order
          })
        )
      }
    });

    yield call(fetchTypes);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error moving down item"
        }
      ])
    );
  }
};

const addUpdateItem = function*({ payload: { data, id, clone } }) {
  try {
    const { credentials } = yield call(getParams);

    const selectedApi = R.isEmpty(id) || clone ? Api.addItem : Api.updateItem;

    yield call(selectedApi, {
      credentials,
      data
    });

    yield put(
      showSnackbar({
        message: `Item ${R.isEmpty(id) || clone ? "added" : "updated"}`,
        action: "OK"
      })
    );

    yield call(fetchTypes);
    yield call(refetchAcessGridData);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error updating item"
        }
      ])
    );
  }
};

const addUpdateZoneItem = function*({ payload: { data, id, clone } }) {
  try {
    const { credentials } = yield call(getParams);

    const selectedApi = R.isEmpty(id) || clone ? Api.addZone : Api.updateZone;

    yield call(selectedApi, {
      credentials,
      data
    });

    yield put(
      showSnackbar({
        message: `Zone ${R.isEmpty(id) || clone ? "added" : "updated"}`,
        action: "OK"
      })
    );

    yield all([call(fetchZones), call(refetchAcessGridData)]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error updating zone"
        }
      ])
    );
  }
};

const deleteItem = function*({ payload: { itemId } }) {
  try {
    const { credentials } = yield call(getParams);

    yield call(Api.deleteItem, {
      credentials,
      data: { itemId }
    });

    yield put(
      showSnackbar({
        message: "Item deleted",
        action: "OK"
      })
    );

    yield call(fetchTypes);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error deleting item"
        }
      ])
    );
  }
};

const reorderItems = function*({ payload: newOrder }) {
  try {
    const { credentials, eventId } = yield call(getParams);

    yield call(Api.bulkUpdateItems, {
      credentials,
      data: {
        eventId,
        bulk: true,
        items: newOrder.map(({ id }, i) => ({ id, order: i }))
      }
    });

    yield call(fetchTypes);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error reordering items"
        }
      ])
    );
  }
};

const addZone = function*({ payload: data }) {
  try {
    const credentials = yield select(getCredentials);

    yield call(Api.addZone, {
      credentials,
      data
    });

    yield put(
      showSnackbar({
        message: "Zone Added",
        action: "OK"
      })
    );

    yield all([call(fetchZones), call(refetchAcessGridData)]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error adding zone"
        }
      ])
    );
  }
};

const selectMultipleZoneItem = function*({ payload: { values } }) {
  try {
    const { credentials } = yield call(getParams);

    yield call(Api.addZoneItemAssociations, {
      credentials,
      data: {
        bulk: true,
        values
      }
    });

    yield all([call(fetchZones), call(refetchAcessGridData)]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error adding zone"
        }
      ])
    );
  }
};

const deselectMultipleZoneItem = function*({ payload: { values } }) {
  try {
    const { credentials } = yield call(getParams);

    yield call(Api.deleteZoneItemAssociations, {
      credentials,
      data: {
        bulk: true,
        values
      }
    });

    yield all([call(fetchZones), call(refetchAcessGridData)]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error adding zone"
        }
      ])
    );
  }
};

const toggleZoneItemAssociations = function*({
  payload: { hasValue, ...data }
}) {
  try {
    const { credentials } = yield call(getParams);

    yield call(
      !hasValue ? Api.addZoneItemAssociations : Api.deleteZoneItemAssociations,
      {
        credentials,
        data
      }
    );

    yield all([call(fetchZones), call(refetchAcessGridData)]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error adding zone"
        }
      ])
    );
  }
};

const refreshZoneAssignments = function*() {
  try {
    const { credentials, eventId } = yield call(getParams);
    yield put(actions.setRefreshingZoneAssignments(true));

    yield call(Api.refreshZoneAssignments, {
      credentials,
      eventId
    });

    yield put(
      showSnackbar({
        message: "Success! Zones have been refreshed",
        action: "OK"
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred refreshing zone assignments"
        }
      ])
    );
  } finally {
    yield put(actions.setRefreshingZoneAssignments(false));
  }
};

const setZonesSubTab = function*() {
  const { eventId } = yield call(getParams);
  const typeId = yield select(getters.typeId);

  yield put(actions.setActiveSubTab(SUB_TABS.ZONES));
  yield put(push(`/event/${eventId}/settings/catalog/type/${typeId}/zones`));
};

const setAccessGridSubTab = function*() {
  const { eventId } = yield call(getParams);
  const typeId = yield select(getters.typeId);
  yield put(actions.setActiveSubTab(SUB_TABS.ACCESS_GRID));
  yield put(
    push(`/event/${eventId}/settings/catalog/type/${typeId}/access-grid`)
  );
};

const setActionsSubTab = function*() {
  const { eventId } = yield call(getParams);
  const typeId = yield select(getters.typeId);
  yield put(actions.setActiveSubTab(SUB_TABS.ACTIONS));
  yield put(push(`/event/${eventId}/settings/catalog/type/${typeId}/actions`));
};

const setApproversSubTab = function*() {
  const { eventId } = yield call(getParams);
  const typeId = yield select(getters.typeId);

  yield put(actions.setActiveSubTab(SUB_TABS.APPROVERS));
  yield put(
    push(`/event/${eventId}/settings/catalog/type/${typeId}/approvers`)
  );
};

const setItemsSubTab = function*() {
  const { eventId } = yield call(getParams);
  const typeId = yield select(getters.typeId);

  yield put(actions.setActiveSubTab(SUB_TABS.ITEMS));
  yield put(push(`/event/${eventId}/settings/catalog/type/${typeId}/items`));
};

const saveBulkItems = function*() {
  try {
    const isZones = yield select(getters.isZones);
    const backgroundColor = yield select(addBulkItemsGetters.selectedColor);
    const { eventId, credentials } = yield call(getParams);
    const groupId = yield select(addBulkItemsGetters.groupId);
    if (!isZones) {
      const typeId = yield select(addBulkItemsGetters.typeId);

      const itemsList = yield select(getItemsList);

      const data = {
        bulk: true,
        items: R.map(
          item => ({
            name: item,
            groupId,
            typeId,
            eventId,
            textColor: "#000",
            backgroundColor
          }),
          itemsList
        )
      };

      yield call(Api.addItem, {
        credentials,
        data
      });

      yield all([
        put(actions.setShowBulkItemsModal(false)),
        call(fetchTypes),
        call(refetchAcessGridData)
      ]);
    } else {
      const itemsList = yield select(getItemsList);
      const data = {
        bulk: true,
        zones: R.map(item => {
          const itemSplit = R.split(",", item);
          const name = itemSplit[0];
          const codeSplit = R.trim(itemSplit[1] || "");
          const code =
            R.length(codeSplit) > 0
              ? R.slice(0, 3, codeSplit)
              : R.compose(
                  R.slice(0, 3),
                  R.toUpper,
                  R.trim
                )(name);
          return {
            name,
            code,
            groupId,
            eventId,
            parentZoneId: null,
            description: null,
            textColor: "#000",
            backgroundColor
          };
        }, itemsList)
      };

      yield call(Api.addZone, {
        credentials,
        data
      });

      yield all([
        put(actions.setShowBulkItemsModal(false)),
        call(fetchZones),
        call(refetchAcessGridData)
      ]);
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error ocurred adding items"
        }
      ])
    );
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchUpdateTypeId = function*() {
  yield takeEvery(actions.updateTypeId.type, updateTypeId);
};

const watchAddCategory = function*() {
  yield takeEvery(actions.addCategory.type, addCategory);
};

const watchAddZoneCategory = function*() {
  yield takeEvery(actions.addZoneCategory.type, addZoneCategory);
};

const watchUpdateCategory = function*() {
  yield takeEvery(actions.updateCategory.type, updateCategory);
};

const watchUpdateZoneCategory = function*() {
  yield takeEvery(actions.updateZoneCategory.type, updateZoneCategory);
};

const watchAddUpdateItem = function*() {
  yield takeEvery(actions.addUpdateItem.type, addUpdateItem);
};

const watchAddUpdateZoneItem = function*() {
  yield takeEvery(actions.addUpdateZoneItem.type, addUpdateZoneItem);
};

const watchFetchTypes = function*() {
  yield takeEvery(actions.fetchTypes.type, fetchTypes);
};

const watchDeleteGroup = function*() {
  yield takeEvery(actions.deleteCategory.type, deleteCategory);
};

const watchDeleteZoneGroup = function*() {
  yield takeEvery(actions.deleteZoneCategory.type, deleteZoneCategory);
};

const watchDeleteItem = function*() {
  yield takeEvery(itemsActions.deleteItem.type, deleteItem);
};

const watchReorderItems = function*() {
  yield takeEvery(itemsActions.reorderItems.type, reorderItems);
};

const watchMoveItemUp = function*() {
  yield takeEvery(itemsActions.moveItemUp.type, moveItemUp);
};

const watchMoveItemDown = function*() {
  yield takeEvery(itemsActions.moveItemDown.type, moveItemDown);
};

const watchMoveCategoryUp = function*() {
  yield takeEvery(itemsActions.moveCategoryUp.type, moveCategoryUp);
};

const watchMoveCategoryDown = function*() {
  yield takeEvery(itemsActions.moveCategoryDown.type, moveCategoryDown);
};

const watchAddZone = function*() {
  yield takeEvery(actions.addZone.type, addZone);
};

const watchSelectMultipleZoneItem = function*() {
  yield takeEvery(
    accessGridTableActions.selectMultipleZoneItem.type,
    selectMultipleZoneItem
  );
};

const watchAddZoneItemAssociation = function*() {
  yield takeEvery(
    accessGridTableActions.toggleZoneItemAssociations.type,
    toggleZoneItemAssociations
  );
};

const watchDeselectZoneItemAssociationsRow = function*() {
  yield takeEvery(
    accessGridTableActions.deselectMultipleZoneItem.type,
    deselectMultipleZoneItem
  );
};

const watchSetZonesSubTab = function*() {
  yield takeEvery(actions.setZonesSubTab.type, setZonesSubTab);
};

const watchSetAccessGridSubTab = function*() {
  yield takeEvery(actions.setAccessGridSubTab.type, setAccessGridSubTab);
};

const watchSetActionsSubTab = function*() {
  yield takeEvery(actions.setActionsSubTab.type, setActionsSubTab);
};

const watchSetApproversSubTab = function*() {
  yield takeEvery(actions.setApproversSubTab.type, setApproversSubTab);
};

const watchSetItemsSubTab = function*() {
  yield takeEvery(actions.setItemsSubTab.type, setItemsSubTab);
};

const watchRefecthAccessGridData = function*() {
  yield takeEvery(actions.refetchAcessGridData.type, refetchAcessGridData);
};

const watchshowBulkModal = function*() {
  yield takeEvery(actions.showBulkModal.type, showBulkModal);
};

const watchSaveBulkItems = function*() {
  yield takeEvery(addBulkItemsActions.saveBulkItems.type, saveBulkItems);
};

const watchRefreshZoneAssignments = function*() {
  yield takeEvery(actions.refreshZoneAssignments.type, refreshZoneAssignments);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchUpdateTypeId),
    fork(watchAddZoneCategory),
    fork(watchAddCategory),
    fork(watchAddUpdateItem),
    fork(watchAddUpdateZoneItem),
    fork(watchFetchTypes),
    fork(watchDeleteGroup),
    fork(watchDeleteZoneGroup),
    fork(watchDeleteItem),
    fork(watchUpdateCategory),
    fork(watchUpdateZoneCategory),
    fork(watchReorderItems),
    fork(watchMoveItemUp),
    fork(watchMoveItemDown),
    fork(watchMoveCategoryUp),
    fork(watchMoveCategoryDown),
    fork(watchAddZone),
    fork(watchAddZoneItemAssociation),
    fork(watchDeselectZoneItemAssociationsRow),
    fork(watchSelectMultipleZoneItem),
    fork(watchSetZonesSubTab),
    fork(watchSetAccessGridSubTab),
    fork(watchSetActionsSubTab),
    fork(watchSetApproversSubTab),
    fork(watchSetItemsSubTab),
    fork(watchRefecthAccessGridData),
    fork(watchshowBulkModal),
    fork(watchSaveBulkItems),
    fork(watchRefreshZoneAssignments)
  ]);
};

export default rootSaga;
