import { get } from "lodash";
import {
  isManifestPending,
  isManifestApproved,
  isManifestRejected
} from "components/Global/Approvals/utils/approvals-helpers";
import getApprovalValue from "utils/value-types/get-value/approval";
import { APPROVAL } from "utils/system-field-ids";

export default row => {
  // if approval manifest, then handle appropriately (vs. legacy format)
  if (get(row, ["values", APPROVAL])) {
    const approval = get(row, ["values", APPROVAL]);
    if (isManifestApproved(getApprovalValue(approval))) {
      return "approved";
    }
    if (isManifestRejected(getApprovalValue(approval))) {
      return "rejected";
    }
    if (isManifestPending(getApprovalValue(approval))) {
      return "pending";
    }
    return "";
  }
  // the Forms v2 review is an object
  return get(row, ["review", "status"]);
};
