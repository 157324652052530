import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import BaseLayoutController from "./BaseController";
import { map } from "ramda";
import { withRouter } from "react-router";

import { getFields } from "redux/modules/modules/fields/actions";
import {
  getLayoutVisibleFields,
  addLayoutVisibleFields,
  removeLayoutVisibleFields,
  updateLayoutVisibleFields
} from "redux/modules/modules/layouts/visibleFields/actions";

import { visibleFields } from "redux/modules/modules/layouts/visibleFields/selectors";
import { fields } from "redux/modules/modules/fields/selectors";

function mapStateToProps(state, props) {
  const visibleFieldsToPass = visibleFields(
    state,
    props.params.typeId,
    props.layoutType
  );

  const fieldsToPass = map(f => {
    const matchingField = visibleFieldsToPass.find(vf => vf.field_id === f.id);
    return {
      ...f,
      order: matchingField ? matchingField.order : null,
      isVisible: Boolean(matchingField)
    };
  })(fields(state, props.params.moduleId));

  return {
    fields: fieldsToPass
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getFields,
      getLayoutVisibleFields,
      addLayoutVisibleFields,
      removeLayoutVisibleFields,
      updateLayoutVisibleFields
    },
    dispatch
  );
}

export default Child =>
  withRouter(
    connect(mapStateToProps, mapDispatchToProps)(BaseLayoutController(Child))
  );
