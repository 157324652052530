import { createModule } from "redux-mvc";
import model from "./model";
import table from "ui-kit/Table";
import rootSaga from "./sagas";
import searchBar from "ui-kit/SearchBar";
import paginationModule from "ui-kit/PaginationBar";

const module = createModule({
  ...model,
  observedDomains: [
    "user",
    "event",
    "modal",
    "organization",
    "permissions",
    "userAccessLevels"
  ]
});

module.plugModule(table);
module.plugModule(paginationModule);
module.plugModule(searchBar);
module.setRootSaga(rootSaga);

export default module;
