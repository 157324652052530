import * as R from "ramda";
import { createSelector } from "reselect";
import { getters } from "Schedules/Schedule";
import { getters as AddActivityRowGetters } from "./";

import * as STANDARD_MODULE_FIELDS from "@lennd/value-types/src/constants/standard-module-field-ids";
import { TYPES as VALIDATION_TYPES } from "ui-kit/Validator/constants";

export const currentFields = createSelector(
  AddActivityRowGetters.moduleId,
  getters.schedules,
  (moduleId, schedules) =>
    R.compose(
      R.prop("fields"),
      R.head,
      R.filter(R.pathEq(["id"], R.prop("value", moduleId)))
    )(schedules) || []
);

const customValidations = createSelector(
  AddActivityRowGetters.values,
  values => ({
    [STANDARD_MODULE_FIELDS.SCHEDULES.ENDS_AT]: {
      type: VALIDATION_TYPES.DATE_GREATER_THAN_IF_VALUE_EXISTS,
      compareWithKey: R.prop(
        "name",
        values[STANDARD_MODULE_FIELDS.SCHEDULES.STARTS_AT]
      ),
      compareWithValue: R.prop(
        "value",
        values[STANDARD_MODULE_FIELDS.SCHEDULES.STARTS_AT]
      )
    }
  })
);

export const getValidations = createSelector(
  customValidations,
  AddActivityRowGetters.values,
  (customValidations, fieldValues) =>
    R.compose(
      R.indexBy(R.prop("id")),
      R.map(field => ({
        id: R.prop("id", field),
        key: R.prop("name", field),
        required: R.propOr(false, "is_required", field),
        ...R.propOr({}, R.prop("id", field), customValidations)
      })),
      R.values
    )(fieldValues)
);

export const valuesToValidate = createSelector(
  AddActivityRowGetters.values,
  getValidations,
  (values, validators) =>
    R.compose(
      R.map(fieldValues => ({
        ...fieldValues,
        value: R.pathOr("", ["value", "value"], fieldValues),
        ...validators[R.prop("id", fieldValues)]
      })),
      R.values
    )(values)
);
