import React from "react";
import { connect } from "react-redux";
import { actions } from "Portal/List/model";
import {
  Div,
  MediumFilledButton,
  FontIcon,
  OpenInNewIcon,
  BigFilledButton,
  BigOutlineButton
} from "components/Base";
import { WIDTH } from "Portal/List/constants";
import { getPortalLink } from "Portal/List/selectors";
import { withProps } from "utils/General";

const CopyIconColor = withProps({
  color: "neutral5",
  fs: 3,
  children: "file_copy"
})(FontIcon);

const decorate = connect(
  state => ({
    portalLink: getPortalLink(state)
  }),
  {
    goToPortal: actions.goToPortal,
    copyPortalLink: actions.copyPortalLink,
    goToPortalSettings: actions.goToPortalSettings
  }
);

const PortalBar = ({ goToPortal, copyPortalLink, portalLink }) => (
  <Div
    width={1}
    display="row.center.flex-start"
    py={2}
    bg="neutral1"
    style={{
      boxShadow: "inset 0px 8px 8px -10px #CCC"
    }}
  >
    <Div
      width={1}
      style={{
        maxWidth: WIDTH
      }}
    >
      <Div display="row.flex-start.center">
        <Div
          color="black"
          fs={1}
          mr={2}
          width={113}
          style={{
            flexShrink: 0
          }}
        >
          Portal Login URL:
        </Div>
        <Div fs={1} color="gray6" truncate>
          {portalLink}
        </Div>

        <BigOutlineButton
          ml={2}
          width={156}
          LeftIcon={() => (
            <FontIcon fs={5} color="black">
              file_copy
            </FontIcon>
          )}
        >
          <Div fs={3} fw={3} color="black" ml={2} onClick={copyPortalLink}>
            Copy Link
          </Div>
        </BigOutlineButton>

        <BigFilledButton
          color="white"
          bg="altB5"
          LeftIcon={OpenInNewIcon}
          onClick={goToPortal}
          ml={2}
        >
          View My Portal
        </BigFilledButton>
      </Div>
    </Div>
  </Div>
);

export default decorate(PortalBar);
