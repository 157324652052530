import React from "react";

import { createContext } from "redux-mvc";

import * as R from "ramda";

import module, { actions } from "../index";

import Layout from "./Layout";

import rootSaga from "../sagas";

import AddFieldModal from "Modules/AddEditColumnModal/View";

module.setRootSaga(rootSaga);

const decorate = createContext({
  module: module,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(
        actions.init({
          moduleId: this.props.moduleId,
          recordTypeId: this.props.recordTypeId,
          selectedFieldIds: this.props.selectedFieldIds,
          visibleFields: this.props.visibleFields,
          fieldOrder: this.props.fieldOrder,
          mode: this.props.mode
        })
      );
    }
  },
  handlers: {
    showAddFieldModal({ categoryId } = {}) {
      this.props.showModal({
        content: (
          <AddFieldModal
            eventId={this.props.eventId}
            moduleId={this.props.moduleId}
            fieldGroup={categoryId}
            onSave={() => {
              this.store.dispatch(actions.refreshFields());
            }}
          />
        )
      });
    },

    onSave(data) {
      this.props.onSave(data);
      this.props.hideModal();
    }
  },
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: ["user", "event", "modal", "organization", "permissions"]
  }
});

export default decorate(Layout);
