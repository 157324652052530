import React from "react";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import DocumentRequestModal from "Portal/PortalDocuments/RequestModal";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

const showDocumentRequest = ({ recordId, meta }) => {
  meta.showModal({
    content: (
      <DocumentRequestModal
        view="admin"
        recordId={recordId}
        hideModal={meta.hideModal}
      />
    ),
    wrapper: ModalWrapper
  });
};

export default function resolveTitle(props) {
  switch (props.moduleId) {
    case STANDARD_MODULE_IDS.documentRequests.id:
      return showDocumentRequest({
        recordId: props.meta.recordId,
        meta: props.meta
      });
    default:
      return null;
  }
}
