import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const ROUTES_WITH_GRIDS = ["editEventFormsRequests", "formsV2_Requests"];
const ROUTES_WITHOUT_HEADER = ["ModuleForm"];

const isRouteWithGrid = currentRoutes =>
  ROUTES_WITH_GRIDS.some(r => currentRoutes.includes(r));

const isRouteWithoutHeader = currentRoutes =>
  ROUTES_WITHOUT_HEADER.some(r => currentRoutes.includes(r));

@CSSModules(css)
class LayoutContent extends Component {
  render() {
    const routes = this.props.routes.map(r => r.name);
    const paddingTop = isRouteWithoutHeader(routes) ? 0 : 45;
    if (isRouteWithGrid(routes)) {
      return (
        <div
          styleName="withGrid"
          style={{
            paddingTop
          }}
        >
          {this.props.children}
        </div>
      );
    }

    return (
      <div
        styleName="withoutGrid"
        style={{
          paddingTop
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

export default LayoutContent;
