import React from "react";

import * as R from "ramda";

import { connect } from "react-redux";

import {
  Div,
  PopMenu,
  PopMenuCard,
  Text2,
  DownFilledIcon
} from "components/Base";

import { actions, getters } from "../index";

const decorate = connect(
  state => ({
    selectedModule: getters.selectedModule(state),
    modules: getters.modules(state)
  }),
  {
    selectModule: actions.selectModule
  }
);

const TemplatesPopover = ({ selectedModule, modules, selectModule }) => {
  return (
    <PopMenu
      Label={({ onClick }) => (
        <Div
          display="row.center.center"
          width={175}
          bra={1}
          shadow={1}
          bg="white"
          height={40}
          px={3}
          mr={2}
          onClick={onClick}
        >
          <Div display="row.flex-start.center">
            <Text2 bold color="primary7">
              {R.propOr("All Templates", "name", selectedModule)}
            </Text2>
            <DownFilledIcon color="primary7" ml={1} />
          </Div>
        </Div>
      )}
    >
      {({ closeMenu }) => (
        <PopMenuCard position="bottom" align="left">
          <Div width={200}>
            <Div
              p={2}
              bg={{ default: "white", hover: "neutral0" }}
              onClick={() => {
                selectModule(null);
                closeMenu();
              }}
            >
              All Templates
            </Div>
            {R.map(
              module => (
                <Div
                  key={module.id}
                  p={2}
                  bg={{ default: "white", hover: "neutral0" }}
                  onClick={() => {
                    selectModule(module);
                    closeMenu();
                  }}
                  truncate
                >
                  {module.name}
                </Div>
              ),
              modules
            )}
          </Div>
        </PopMenuCard>
      )}
    </PopMenu>
  );
};

export default decorate(TemplatesPopover);
