import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import { actions } from "Sponsors/SelfSetup";
import { VARIANTS } from "ui-kit/Theme";

import { ItemCard } from "Sponsors/Common/View";
import { Text2, Text3 } from "components/Base";

import { noop } from "utils/General";

const includedItemsMsg = count =>
  count > 1 ? `${count} included items` : `${count} included item`;

const getItemsText = R.compose(
  R.join(", "),
  R.map(item => ` (${item.count}) ${item.name}`)
);

const decorate = connect(
  null,
  { selectItems: actions.setSelectingItems }
);

const PackageCard = ({
  id = "",
  name = "",
  price = "",
  items = [],
  selectItems = noop,
  ...styleProps
}) => (
  <ItemCard
    title={name}
    caption={
      <>
        <Text3 bold mr={3}>
          $ {price}
        </Text3>
        {R.length(items) > 0 ? (
          <Text3>{includedItemsMsg(R.length(items))}</Text3>
        ) : (
          <Text3>No included items</Text3>
        )}
      </>
    }
    footer={
      R.length(items) > 0 && <Text2 bold>Included: {getItemsText(items)}</Text2>
    }
    primaryText={R.length(items) === 0 ? "Add Items" : "Edit Items"}
    primaryAction={() => selectItems(id)}
    {...styleProps}
    variant={R.length(items) > 0 ? VARIANTS.BACKGROUND : VARIANTS.PRIMARY}
  />
);

export default decorate(PackageCard);
