import React from "react";
import { connect } from "react-redux";
import { Div, SuperFilledButton, SuperOutlineButton } from "components/Base";
import { getFooterText, getShowBackBtn } from "../selectors";
import { actions } from "../model";

const decorate = connect(
  state => ({
    btnText: getFooterText(state),
    showBackBtn: getShowBackBtn(state)
  }),
  {
    nextView: actions.nextView,
    previousView: actions.previousView
  }
);

const Footer = ({
  hideModal,
  btnText,
  nextView,
  previousView,
  showBackBtn,
  fetchPermissionSets
}) => (
  <Div display="row.flex-start.center" height={80} bt={1} bc="neutral1" px={4}>
    {showBackBtn && (
      <SuperOutlineButton onClick={previousView} mr={2}>
        Back
      </SuperOutlineButton>
    )}
    <SuperFilledButton
      bg="altB5"
      onClick={() => nextView({ hideModal, fetchPermissionSets })}
    >
      {btnText}
    </SuperFilledButton>
  </Div>
);

export default decorate(Footer);
