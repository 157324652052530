import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import {
  Div,
  Text1,
  Text2,
  AddIcon,
  CloseIcon,
  FontIcon
} from "components/Base";
import { noop } from "utils/General";

import { Panel } from "ui-kit/FilterControls/View";

import CSSModules from "react-css-modules";
import css from "ui-kit/ViewPicker/View/styles.scss";

import SortPicker from "components/Global/Module/Sidebar/Popovers/Sort";

import { actions } from "EventLight/FileRequests/Results";

import {
  getFieldFilters,
  getSortBy
} from "EventLight/FileRequests/Results/selectors";

const decorate = R.compose(
  connect(
    state => ({
      fields: getFieldFilters(state),
      sortBy: getSortBy(state)
    }),
    {
      setFilters: actions.setSelectedSortBy,
      removeFilter: actions.removeSelectedSortBy
    }
  ),
  CSSModules(css)
);

const Sorter = ({
  id = "",
  name = "",
  direction = "",
  onRemove = noop,
  style = {}
}) => (
  <Div
    display="row.space-between.center"
    width={1}
    fs={1}
    mb={1}
    bra={1}
    p={2}
    style={{
      backgroundColor: "#eee"
    }}
  >
    <Text1 color="#222" flex={1} truncate style={style}>
      {name}
    </Text1>
    <Div display="row.flex-start.center">
      <Text1 color="#222" bold>
        {direction}
      </Text1>
      <CloseIcon
        size={15}
        ml={1}
        style={{
          flexShrink: 0
        }}
        color={{
          default: "gray5",
          hover: "gray7"
        }}
        onClick={e => {
          e.stopPropagation();
          onRemove(id);
        }}
      />
    </Div>
  </Div>
);

const FieldSelect = ({
  fields,
  setFilters,
  removeFilter,
  sortBy,
  ...styleProps
}) => {
  if (!fields.length) return null;

  return (
    <Panel
      label={
        <Div display="row.flex-start.center" mb={1}>
          <Text2 bold>Sort By</Text2>
        </Div>
      }
      collapsedDefault={false}
      filtersOn={sortBy && sortBy.length}
      bodyProps={{ ml: 3, mr: 2 }}
      {...styleProps}
    >
      {sortBy && sortBy.length ? (
        <Sorter {...R.head(sortBy)} m={1} onRemove={removeFilter} />
      ) : null}
      {R.map(
        sort => (
          <Div display="row.flex-start.center" ml={1}>
            <Text2 color="#222" mr={2} ml={1}>
              then
            </Text2>
            <Sorter
              {...sort}
              onRemove={removeFilter}
              key={sort.fieldId}
              flex={1}
              style={{
                maxWidth: "85px"
              }}
            />
          </Div>
        ),
        R.tail(sortBy)
      )}

      <Div display="column.flex-start.flex-start">
        <SortPicker sortBy={sortBy} onChange={setFilters} fields={fields}>
          <Div
            display="row"
            onClick={noop}
            mt={sortBy && sortBy.length ? 2 : 0}
          >
            <Div fs={2} fw={3} color="primary7">
              Add Sort By
            </Div>
            <AddIcon color="primary7" ml={1} />
          </Div>
        </SortPicker>
      </Div>
    </Panel>
  );
};

export default decorate(FieldSelect);
