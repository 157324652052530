import React from "react";
import { Div } from "components/Base";
import { connect } from "react-redux";
import { getters } from "OrgLight/Dashboard/model";
import Loading from "ui-kit/Loading";
import EventsListHeader from "./EventsListHeader";
import EventsListBody from "./EventsListBody";
import EventsCalendarBody from "./EventsCalendarBody";
import { EVENT_OPTIONS } from "../constants";

const decorate = connect(state => ({
  loadingEvents: getters.loadingEvents(state),
  eventSelectedOption: getters.eventSelectedOption(state)
}));

const EventsList = ({ loadingEvents, eventSelectedOption }) => (
  <Div
    width={1}
    bra={1}
    bg="white"
    style={{
      boxShadow: "rgba(0, 0, 0, 0.07) 0px 1px 3px",
      border: "1px solid #e7e6ea",
      marginTop: "20px"
    }}
  >
    {loadingEvents ? (
      <Loading />
    ) : (
      <Div>
        <EventsListHeader />
        {eventSelectedOption === EVENT_OPTIONS.LIST ? (
          <EventsListBody />
        ) : eventSelectedOption === EVENT_OPTIONS.CALENDAR ? (
          <EventsCalendarBody />
        ) : null}
      </Div>
    )}
  </Div>
);

export default decorate(EventsList);
