import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Button = ({ children, classNames, onClick }) => (
  <div styleName="container" className={classNames.container} onClick={onClick}>
    <div styleName="label" className={classNames.label}>
      {children}
    </div>
  </div>
);

Button.defaultProps = {
  classNames: {}
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  classNames: PropTypes.object
};

export default CSSModules(Button, css);
