import fields from "components/Event/Accounts/constants";

const {
  address_city,
  address_country,
  address_state,
  address_street_line_1,
  address_street_line_2,
  address_zip,
  attachments,
  description,
  name,
  phone,
  website
} = fields;

export default {
  mustBeChildField: false,
  canBeChildField: false,
  canBeParentField: true,
  canBeChildOfFieldTypes: [],
  canBeParentOfGridFieldTypes: ["contact", "reference"],
  mappableFields: [
    {
      id: name,
      name: "Name",
      mappableFieldTypes: ["text"]
    },
    {
      id: address_street_line_1,
      name: "Address Street",
      mappableFieldTypes: ["text"]
    },
    {
      id: address_street_line_2,
      name: "Address Street (Line 2)",
      mappableFieldTypes: ["text"]
    },
    {
      id: address_city,
      name: "Address City",
      mappableFieldTypes: ["text"]
    },
    {
      id: address_state,
      name: "Address State",
      mappableFieldTypes: ["text"]
    },
    {
      id: address_zip,
      name: "Address Zip",
      mappableFieldTypes: ["text"]
    },
    {
      id: address_country,
      name: "Address Country",
      mappableFieldTypes: ["text", "countries"]
    },
    {
      id: attachments,
      name: "Attachments",
      mappableFieldTypes: ["file"]
    },
    {
      id: description,
      name: "Description",
      mappableFieldTypes: ["text"]
    },
    {
      id: website,
      name: "Website",
      mappableFieldTypes: ["text"]
    },
    {
      id: phone,
      name: "Phone",
      mappableFieldTypes: ["text", "phone"]
    }
  ]
};
