import { createSelector } from "reselect";
import * as R from "ramda";
import { TAB_IDS } from "./constants";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";

const isActiveRoute = (routes, route) => {
  if (Array.isArray(route)) {
    return R.compose(
      Boolean,
      R.length,
      R.intersection(route),
      R.map(R.prop("name"))
    )(routes);
  }

  return routes.some(r => r.name === route);
};

export const getCurrentRoute = createSelector(
  (_, props) => props.routes,
  routes => {
    if (R.any(R.propEq("name", "OrgLightPeopleManage"))(routes)) {
      return TAB_IDS.MANAGE;
    } else if (R.any(R.propEq("name", "OrgLightPeopleSettingsTypes"))(routes)) {
      return TAB_IDS.TYPES;
    } else if (
      R.any(R.propEq("name", "OrgLightPeopleSettingsFields"))(routes)
    ) {
      return TAB_IDS.FIELDS;
    } else if (
      R.any(R.propEq("name", "OrgLightPeopleSettingsReportTemplates"))(routes)
    ) {
      return TAB_IDS.REPORT_TEMPLATES;
    } else if (
      R.any(R.propEq("name", "OrgLightPeopleSettingsDocumentTemplates"))(routes)
    ) {
      return TAB_IDS.DOCUMENT_TEMPLATES;
    } else if (
      R.any(R.propEq("name", "OrgLightPeopleSettingsEmailTemplates"))(routes)
    ) {
      return TAB_IDS.EMAIL_TEMPLATES;
    }
    return null;
  }
);

export const getLinks = createSelector(
  (_, props) => props.params,
  getCurrentRoute,
  (params, currentRoute) => {
    return [
      {
        key: TAB_IDS.MANAGE,
        label: "Manage",
        active: currentRoute === TAB_IDS.MANAGE,
        to: `/org-light/${params.orgId}/people/manage/${STANDARD_MODULE_IDS.contacts.id}`,
        visible: true
      },
      {
        key: TAB_IDS.SETTINGS,
        label: "Settings",
        active: currentRoute === TAB_IDS.SETTINGS,
        to: `/org-light/${params.orgId}/people/settings/email-templates`,
        visible: true
      }
    ].filter(l => l.visible);
  }
);

export const getTabs = createSelector(
  (_, props) => props.routes,
  (_, props) => props.params,
  getEventDetails,
  getCurrentRoute,
  (routes, params, eventDetails, currentRoute) => {
    if (
      [
        TAB_IDS.FIELDS,
        TAB_IDS.TYPES,
        TAB_IDS.PORTAL,
        TAB_IDS.GUEST_LIST,
        TAB_IDS.LAYOUT,
        TAB_IDS.REPORT_TEMPLATES,
        TAB_IDS.DOCUMENT_TEMPLATES,
        TAB_IDS.EMAIL_TEMPLATES
      ].includes(currentRoute)
    ) {
      return [
        {
          key: "email-templates",
          label: "Email Templates",
          active: isActiveRoute(routes, "OrgLightPeopleSettingsEmailTemplates"),
          to: `/org-light/${eventDetails.id}/people/settings/email-templates`
        }
        /*
        {
          key: "types",
          label: "Types",
          active: isActiveRoute(routes, "OrgLightPeopleSettingsTypes"),
          to: `/event-light/${eventDetails.id}/people/settings/types`
        },
        {
          key: "fields",
          label: "Fields",
          active: isActiveRoute(routes, "OrgLightPeopleSettingsFields"),
          to: `/event-light/${eventDetails.id}/people/settings/fields`
        },
        {
          key: "report-templates",
          label: "Report Templates",
          active: isActiveRoute(
            routes,
            "OrgLightPeopleSettingsReportTemplates"
          ),
          to: `/event-light/${eventDetails.id}/people/settings/report-templates`
        },
        {
          key: "document-templates",
          label: "Document Templates",
          active: isActiveRoute(
            routes,
            "OrgLightPeopleSettingsDocumentTemplates"
          ),
          to: `/event-light/${eventDetails.id}/people/settings/document-templates`
        }
        */
      ];
    }

    return [];
  }
);
