/* eslint-disable consistent-return */
import React, { Component } from "react";
import * as R from "ramda";
import moment from "moment";
import View from "./View";
import DeleteFormModal from "components/Event/Module/Form/Modals/DeleteForm";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import isFormLocked from "components/Event/FormsV2/Utils/is-form-locked";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

const buildState = props => ({
  title: props.form.title || "",
  internalTitle: props.form.name || "",
  closeDate: props.form.close_date
    ? moment(new Date(props.form.close_date))
    : null,
  isOpen: !props.form.is_locked,
  allowToEdit: !props.form.lock_on_submission,
  enableSaveAndSubmitLater: props.form.enable_save_and_submit_later,
  requireLogin: props.form.require_login,
  confirmationMessage: props.form.confirmation_message || "",
  scopeRecordTypeId: props.form.scope_record_type_id,
  submitterRecordTypeId: props.form.submitter_record_type_id,
  enableReminders: props.form.enable_reminders,
  //
  sendConfirmationEmail: props.form.send_confirmation_email,
  confirmationEmailMessage: props.form.confirmation_email_message || "",
  //
  addSubmitterToAccount: props.form.add_submitter_to_account,
  addSubmitterAsPrimaryContact: props.form.add_submitter_as_primary_contact,
  singleSubmission: R.pathOr(false, ["form", "single_submission"], props)
});

class Controller extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      ...buildState(props)
    };
  }

  async componentDidMount() {
    await this.getRecordTypes();

    this.setState({
      loading: false
    });
  }

  componentDidUpdate(prevProps) {
    if (
      R.path(["form", "id"])(this.props) !== R.path(["form", "id"])(prevProps)
    ) {
      this.getRecordTypes();
      this.setState({ ...buildState(this.props) });
    }
  }

  getRecordTypes = async () => {
    if (this.props.form.scope === "account") {
      await this.props.getRecordTypes({
        moduleId: STANDARD_MODULE_IDS.accounts.id,
        options: {
          orgId: this.props.params.orgId,
          eventId: this.props.params.eventId
        }
      });
    }

    await this.props.getRecordTypes({
      moduleId: STANDARD_MODULE_IDS.contacts.id,
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId
      }
    });

    return true;
  };

  goToScene = path => {
    if (this.props.eventDetails.is_light) {
      this.props.router.push({
        pathname: `/event-light/${this.props.params.eventId}/forms/${this.props.params.viewId}${path}`
      });
    } else {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/module/${this.props.params.moduleId}/form/${this.props.params.viewId}${path}`
      });
    }
  };

  onBlurTitle = async title => {
    if (!title.length) {
      return this.setState({
        title: this.props.form.title
      });
    }

    this.setState({ title });

    await this.props.updateForm({
      formId: this.props.form.id,
      title
    });

    this.props.fetchForm();
  };
  onBlurInternalTitle = async internalTitle => {
    if (!internalTitle.length) {
      return this.setState({
        internalTitle: this.props.form.name
      });
    }

    this.setState({ internalTitle });

    await this.props.updateForm({
      formId: this.props.form.id,
      name: internalTitle
    });

    this.props.fetchForm();
  };
  onBlurConfirmationMessage = () => {
    if (
      this.props.form.confirmation_message !== this.state.confirmationMessage
    ) {
      this.props.updateForm({
        formId: this.props.form.id,
        confirmationMessage: this.state.confirmationMessage
      });
    }
  };
  onBlurConfirmationEmailMessage = () => {
    if (
      this.props.form.email_confirmation_message !==
      this.state.confirmationEmailMessage
    ) {
      this.props.updateForm({
        formId: this.props.form.id,
        confirmationEmailMessage: this.state.confirmationEmailMessage
      });
    }
  };

  onChangeTitle = title => {
    this.setState({ title });
  };
  onChangeInternalTitle = internalTitle => {
    this.setState({ internalTitle });
  };
  onChangeConfirmationMessage = confirmationMessage => {
    this.setState({ confirmationMessage });
  };
  onChangeConfirmationEmailMessage = confirmationEmailMessage => {
    this.setState({ confirmationEmailMessage });
  };
  onChangeCloseDate = async closeDate => {
    this.setState({ closeDate });

    await this.props.updateForm({
      formId: this.props.form.id,
      closeDate
    });

    this.props.fetchForm();
  };
  onChangeIsOpen = async isOpen => {
    this.setState({ isOpen });

    await this.props.updateForm({
      formId: this.props.form.id,
      isLocked: !isOpen
    });

    this.props.fetchForm();
  };
  onChangeEnableReminders = async enableReminders => {
    this.setState({ enableReminders });

    await this.props.updateForm({
      formId: this.props.form.id,
      enableReminders
    });

    this.props.fetchForm();
  };
  onChangeAllowToEdit = async allowToEdit => {
    this.setState({ allowToEdit });

    await this.props.updateForm({
      formId: this.props.form.id,
      lockOnSubmission: !allowToEdit
    });

    this.props.fetchForm();
  };

  onChangeEnableSaveAndSubmitLater = async enableSaveAndSubmitLater => {
    this.setState({ enableSaveAndSubmitLater });

    await this.props.updateForm({
      formId: this.props.form.id,
      enableSaveAndSubmitLater: enableSaveAndSubmitLater
    });

    this.props.fetchForm();
  };

  onChangeRequireLogin = async requireLogin => {
    this.setState({ requireLogin });

    await this.props.updateForm({
      formId: this.props.form.id,
      requireLogin
    });

    this.props.fetchForm();
  };

  onChangeSingleSubmission = async singleSubmission => {
    this.setState({ singleSubmission });

    await this.props.updateForm({
      formId: this.props.form.id,
      singleSubmission
    });

    this.props.fetchForm();
  };
  onChangeSendConfirmationEmail = async sendConfirmationEmail => {
    this.setState({ sendConfirmationEmail });

    await this.props.updateForm({
      formId: this.props.form.id,
      sendConfirmationEmail
    });

    this.props.fetchForm();
  };
  onChangeAddSubmitterToAccount = async addSubmitterToAccount => {
    this.setState({ addSubmitterToAccount });

    await this.props.updateForm({
      formId: this.props.form.id,
      addSubmitterToAccount
    });

    this.props.fetchForm();
  };
  onChangeAddSubmitterAsPrimaryContact = async addSubmitterAsPrimaryContact => {
    this.setState({ addSubmitterAsPrimaryContact });

    await this.props.updateForm({
      formId: this.props.form.id,
      addSubmitterAsPrimaryContact
    });

    this.props.fetchForm();
  };
  onToggleLockAll = async isLocked => {
    await this.props.bulkEdit({ formId: this.props.form.id, isLocked });
    this.props.showSnackbar({
      message: `Submissions ${isLocked ? "locked" : "unlocked"}`,
      action: "OK"
    });
  };
  onClickRemoveForm = () => {
    this.props.showModal({
      content: <DeleteFormModal onDelete={this.onDeleteForm} />,
      wrapper: ModalWrapper
    });
  };
  onDeleteForm = async () => {
    await this.props.deleteForm({
      eventId: this.props.params.eventId,
      formId: this.props.form.id
    });

    this.props.router.push({
      pathname: `/event-light/${this.props.params.eventId}/forms`
    });
  };
  onChangeScopeRecordTypeId = async ({ value }) => {
    this.setState({ scopeRecordTypeId: value });

    await this.props.updateForm({
      formId: this.props.form.id,
      scopeRecordTypeId: value
    });

    this.props.fetchForm();
  };
  onChangeSubmitterRecordTypeId = async ({ value }) => {
    this.setState({ submitterRecordTypeId: value });

    await this.props.updateForm({
      formId: this.props.form.id,
      submitterRecordTypeId: value
    });

    this.props.fetchForm();
  };

  render() {
    const {
      routes,
      contactRecordTypes,
      accountRecordTypes,
      isConferenceTemplate
    } = this.props;

    let recordTypes = [];

    if (recordTypes.length) {
      recordTypes = R.map(r => ({
        label: r.name,
        value: r.id
      }))(recordTypes);
    }

    let tabs = [
      {
        routeName: "ModuleFormSettings",
        tabName: "Basic",
        onClick: () => this.goToScene("/settings"),
        visible: true
      },
      {
        routeName: "ModuleFormSettingsCommunication",
        tabName: "Communication",
        onClick: () => this.goToScene("/settings/communication"),
        visible: true
      },
      {
        routeName: "ModuleFormSettingsApprovers",
        tabName: "Approvers",
        onClick: () => this.goToScene("/settings/approvers"),
        visible: this.props.eventDetails.is_light
      },
      {
        routeName: "ModuleFormSettingsAdvanced",
        tabName: "Advanced",
        onClick: () => this.goToScene("/settings/advanced"),
        visible: true
      }
    ].filter(t => t.visible);

    tabs = R.map(t => ({
      ...t,
      isActive: R.any(
        R.compose(
          R.equals(t.routeName),
          R.prop("name")
        )
      )(routes)
    }))(tabs);

    const viewHelpers = {
      onChangeTitle: this.onChangeTitle,
      onChangeInternalTitle: this.onChangeInternalTitle,
      onChangeCloseDate: this.onChangeCloseDate,
      onChangeIsOpen: this.onChangeIsOpen,
      onChangeEnableSaveAndSubmitLater: this.onChangeEnableSaveAndSubmitLater,
      onChangeRequireLogin: this.onChangeRequireLogin,
      onChangeSingleSubmission: this.onChangeSingleSubmission,
      onToggleLockAll: this.onToggleLockAll,
      onBlurTitle: this.onBlurTitle,
      onBlurInternalTitle: this.onBlurInternalTitle,
      onClickRemoveForm: this.onClickRemoveForm,
      onChangeScopeRecordTypeId: this.onChangeScopeRecordTypeId,
      onChangeSubmitterRecordTypeId: this.onChangeSubmitterRecordTypeId,
      onChangeAddSubmitterToAccount: this.onChangeAddSubmitterToAccount,
      onChangeAddSubmitterAsPrimaryContact: this
        .onChangeAddSubmitterAsPrimaryContact,
      onChangeEnableReminders: this.onChangeEnableReminders,
      onChangeAllowToEdit: this.onChangeAllowToEdit,
      //
      onChangeConfirmationMessage: this.onChangeConfirmationMessage,
      onBlurConfirmationMessage: this.onBlurConfirmationMessage,
      //
      onChangeSendConfirmationEmail: this.onChangeSendConfirmationEmail,
      onChangeConfirmationEmailMessage: this.onChangeConfirmationEmailMessage,
      onBlurConfirmationEmailMessage: this.onBlurConfirmationEmailMessage
    };

    if (recordTypes.length) {
      recordTypes = R.map(r => ({
        label: r.name,
        value: r.id
      }))(recordTypes);
    }

    return (
      <View
        {...{
          isConferenceTemplate,
          moduleId: this.props.form.base_module_id,
          tabs,
          activeRoute: R.compose(
            R.prop("routeName"),
            R.find(
              R.compose(
                R.equals(true),
                R.prop("isActive")
              )
            )
          )(tabs),
          loading: this.state.loading,
          formScope: this.props.form.scope,
          contactRecordTypes: R.map(r => ({
            label: r.name,
            value: r.id
          }))(contactRecordTypes),
          accountRecordTypes: R.map(r => ({
            label: r.name,
            value: r.id
          }))(accountRecordTypes),
          ...viewHelpers,
          ...this.state,
          isFormLocked: isFormLocked({
            close_date: this.state.closeDate,
            is_locked: !this.state.isOpen
          })
        }}
      />
    );
  }
}

export default Controller;
