import Immutable from "immutable";
import { combineReducers } from "redux";
import { RECEIVE, REQUEST, SET_ACTIVE, UPDATE } from "./constants";
import { ERROR } from "redux/modules/errors/constants";

const views = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn(
          [action.payload.moduleId, "byId"],
          action.payload.views.reduce((a, b) => {
            a[b.id] = b;
            return a;
          }, {})
        )
        .toJS();
    case SET_ACTIVE:
      return Immutable.fromJS(state)
        .setIn(
          [action.payload.moduleId, "byId"],
          Object.keys(state[action.payload.moduleId].byId).reduce(
            (list, viewId) => {
              list[viewId] = {
                ...state[action.payload.moduleId].byId[viewId],
                is_active: action.payload.viewId === viewId
              };
              return list;
            },
            {}
          )
        )
        .toJS();
    case UPDATE:
      return Immutable.fromJS(state)
        .setIn([action.payload.moduleId, "byId", action.payload.viewId], {
          ...state[action.payload.moduleId].byId[action.payload.viewId],
          ...Object.keys(action.payload.view).reduce((obj, key) => {
            if (key === "visibleFields") {
              obj.visible_fields = action.payload.view[key];
            } else if (key === "fieldOrder") {
              obj.field_order = action.payload.view[key];
            } else if (key === "fieldWidths") {
              obj.field_widths = action.payload.view[key];
            } else if (key === "groupedBy") {
              obj.grouped_by = action.payload.view[key];
            } else if (key === "cardFields") {
              obj.card_fields = action.payload.view[key];
            } else if (key === "colorBy") {
              obj.color_by = action.payload.view[key];
            } else if (key === "filters") {
              obj.filters = action.payload.view[key];
            } else if (key === "quickFilters") {
              obj.quick_filters = action.payload.view[key];
            } else if (key === "filterByEventId") {
              obj.filter_by_event_id = action.payload.view[key];
            } else if (key === "sortBy") {
              obj.sort_by = action.payload.view[key];
            } else if (key === "rowOrder") {
              obj.row_order = action.payload.view[key];
            } else if (key === "isPublic") {
              obj.is_public = action.payload.view[key];
            } else if (key === "name") {
              obj.name = action.payload.view[key];
            }
            return obj;
          }, {})
        })
        .toJS();
    default:
      return state;
  }
};

const fetching = (state = {}, action) => {
  switch (action.type) {
    case REQUEST:
      return Immutable.fromJS(state)
        .setIn([action.payload.moduleId], true)
        .toJS();
    case ERROR:
      // @NOTE: On error of any fetching, we clear out all fetching states since
      // we don't know where exactly we failed
      return {};
    case RECEIVE:
      return Immutable.fromJS(state)
        .setIn([action.payload.moduleId], false)
        .toJS();
    default:
      return state;
  }
};

export default combineReducers({
  views,
  fetching
});
