import PropTypes from "prop-types";
import Calendar from "./Calendar";
import Mask from "./Mask";
import React, { Component } from "react";

export default class DateEditor extends Component {
  render() {
    return (
      <Editor
        {...this.props}
        displayType={this.props.column.settings.displayType}
      />
    );
  }
}

DateEditor.propTypes = {
  column: PropTypes.object.isRequired
};

const Editor = props =>
  props.displayType === "mask" ? <Mask {...props} /> : <Calendar {...props} />;

Editor.propTypes = {
  displayType: PropTypes.string
};
