import { get } from "lodash";

export const ordersByTypeId = (state, typeId) =>
  get(state, ["orders", "orders", "orders", "byTypeId", typeId], []);

export const isFetchingOrders = state => state.orders.orders.isFetchingOrders;

export const order = (state, orderId) =>
  get(state, ["orders", "orders", "order", "byId", orderId]);
export const isFetchingOrder = (state, orderId) =>
  get(state, ["orders", "orders", "fetchingOrder", "byId", orderId]);
