import { createModule } from "redux-mvc";

import model from "OrgLight/Dashboard/model";
import sagas from "OrgLight/Dashboard/sagas";

import activityDetailsSidebar from "Schedules/ActivityDetailsSidebar";

const module = createModule(model);

module.plugModule(activityDetailsSidebar);

module.setRootSaga(sagas);

export default module;
