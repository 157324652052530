import React from "react";
import * as R from "ramda";
import CSSModules from "react-css-modules";

import { CheckboxInput } from "ui-kit/Form/View";
import css from "./styles.scss";

const decorate = CSSModules(css);

const RowCheckboxInput = ({ fieldId, label, data }) => (
  <div styleName="row" style={{ marginBottom: 4 }}>
    <CheckboxInput
      fieldId={fieldId}
      iniValue={R.prop(fieldId, data)}
      width={40}
      iconStyle={{
        marginRight: 8
      }}
    />
    <div styleName="label" style={{ margin: 0 }}>
      {label}
    </div>
  </div>
);

export default decorate(RowCheckboxInput);
