import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { actions } from "Modules/CloneModuleModal";
import thunkMiddleware from "redux-thunk";
import { Provider } from "react-redux";
import cloneModuleModal from "Modules/CloneModuleModal";
import * as R from "ramda";
import Layout from "Modules/CloneModuleModal/View/Layout";
import CloneEventModalSagas from "Modules/CloneModuleModal/sagas";

cloneModuleModal.setRootSaga(CloneEventModalSagas);

class Modal extends Component {
  static contextTypes = {
    store: PropTypes.shape({
      subscribe: PropTypes.func.isRequired,
      dispatch: PropTypes.func.isRequired,
      getState: PropTypes.func.isRequired
    })
  };

  constructor(props, context) {
    super(props);

    const { store } = cloneModuleModal.createStore({
      middleware: [thunkMiddleware],
      globalStore: context.store,
      observedDomains: ["user", "event"],
      dispatchToGlobal: R.compose(
        R.not,
        R.test(new RegExp(cloneModuleModal.namespace)),
        R.prop("type")
      )
    });
    this.store = store;

    this.store.dispatch(actions.setAllUserEvents(this.props.allUserEvents));
    this.store.dispatch(actions.setOrganization(this.props.organization));
    this.store.dispatch(actions.setIsLenndAdmin(this.props.isLenndAdmin));

    cloneModuleModal.run();
  }

  componentWillUnmount() {
    if (this.props.onDone) {
      this.props.onDone();
    }
    cloneModuleModal.cancel();
  }

  render() {
    const { hideModal, addEvent, allUserEvents, isCloning } = this.props;
    const handlers = {
      hideModal,
      addEvent,
      allUserEvents,
      isCloning
    };
    return (
      <Provider store={this.store}>
        <Layout handlers={handlers} />
      </Provider>
    );
  }
}

export default withRouter(Modal);
