import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Tooltip from "components/Global/Tooltip";

const TooltipLabel = CSSModules(
  ({ color, text }) => (
    <div styleName="tooltipLabel">
      <div
        styleName="tooltipColor"
        style={{ backgroundColor: color, display: color ? "block" : "none" }}
      />
      <div styleName="tooltipText">{text}</div>
    </div>
  ),
  css
);

@CSSModules(css)
class ProgressChart extends Component {
  // overflow hidden not working in chrome, can't figure out why, so here is the hack.
  getBorderRadius(width) {
    if (width === "100%") {
      return 67;
    }
    return "67px 0 0 67px";
  }

  formatTooltip({ width, title }) {
    return width === "100%" ? title : `${width} ${title}`;
  }

  render() {
    if (this.props.empty) {
      return (
        <div styleName="fullBar">
          <div
            styleName="emptyMainBar"
            style={{
              backgroundColor: "#E6E4EA",
              width: `${Math.floor(Math.random() * 90)}%`
            }}
          />
        </div>
      );
    }

    const { primary, secondary, tertiary, quaternary } = this.props;

    return (
      <div styleName="container">
        {primary && (
          <div
            styleName="fullBarWrapper"
            style={{
              width: primary.width,
              borderRadius: this.getBorderRadius(primary.width)
            }}
          >
            {primary.title ? (
              <Tooltip
                className="credential-progress"
                tooltip={
                  <TooltipLabel
                    color={primary.color}
                    text={this.formatTooltip(primary)}
                  />
                }
                placement="top"
              >
                <div
                  styleName="fullBar"
                  style={{ backgroundColor: primary.color, zIndex: 1 }}
                />
              </Tooltip>
            ) : (
              <div
                styleName="fullBar"
                style={{ backgroundColor: primary.color, zIndex: 1 }}
              />
            )}
          </div>
        )}

        {secondary && (
          <div
            styleName="fullBarWrapper"
            style={{
              width: secondary.width,
              borderRadius: this.getBorderRadius(secondary.width)
            }}
          >
            <Tooltip
              className="credential-progress"
              tooltip={
                <TooltipLabel
                  color={secondary.color}
                  text={this.formatTooltip(secondary)}
                />
              }
              placement="top"
            >
              <div
                styleName="fullBar"
                style={{ backgroundColor: secondary.color, zIndex: 2 }}
              />
            </Tooltip>
          </div>
        )}

        {tertiary && (
          <div
            styleName="fullBarWrapper"
            style={{
              width: tertiary.width,
              borderRadius: this.getBorderRadius(tertiary.width)
            }}
          >
            <Tooltip
              className="credential-progress"
              tooltip={
                <TooltipLabel
                  color={tertiary.color}
                  text={this.formatTooltip(tertiary)}
                />
              }
              placement="top"
            >
              <div
                styleName="fullBar"
                style={{ backgroundColor: tertiary.color, zIndex: 3 }}
              />
            </Tooltip>
          </div>
        )}

        {quaternary && (
          <div styleName="innerBarWrapper" style={{ width: quaternary.width }}>
            <Tooltip
              className="credential-progress"
              tooltip={
                <TooltipLabel
                  color={quaternary.color}
                  text={quaternary.title}
                />
              }
              placement="top"
            >
              <div
                styleName="innerBar"
                style={{ backgroundColor: quaternary.color, zIndex: 4 }}
              />
            </Tooltip>
          </div>
        )}
      </div>
    );
  }
}

const progressPropTypes = {
  color: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired
};

ProgressChart.propTypes = {
  empty: PropTypes.bool,
  primary: PropTypes.shape(progressPropTypes),
  secondary: PropTypes.shape(progressPropTypes),
  tertiary: PropTypes.shape(progressPropTypes),
  quaternary: PropTypes.shape(progressPropTypes)
};

export default ProgressChart;
