import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { Link } from "react-router";
import Fuse from "fuse.js";
import GeminiScrollbar from "components/Global/GeminiScrollbar";

import Rows from "./Rows";
import SearchInput from "components/Atoms/SearchInput";
import AddCollaboratorInfoModal from "components/Global/Modals/AddCollaboratorInfoModal";
import AddEventModal from "components/Global/Modals/AddEventModal";

@CSSModules(css)
class EventsDropdown extends Component {
  state = { filter: "" };

  searchEvents(events) {
    if (this.state.filter.length) {
      const fuse = new Fuse(events, {
        threshold: 0.3,
        keys: ["name"]
      });
      return fuse.search(this.state.filter);
    }
    return events;
  }

  handleChange = event => {
    this.setState({
      filter: event.target.value
    });
  };

  toggleStarredEvent = ({ eventId, starred }, e) => {
    e.stopPropagation();
    this.props.toggleStarredEvent({
      eventId,
      starred
    });
  };

  showAcceptInvitationConfirmation = selectedEvent => {
    this.props.showModal({
      content: (
        <AddCollaboratorInfoModal
          eventId={selectedEvent.id}
          eventName={selectedEvent.name}
          onUpdated={this.handleInfoUpdated}
        />
      )
    });
  };

  handleInfoUpdated = eventId => {
    window.location = `/event-light/${eventId}/dashboard`;
  };

  showAddEventModal = () => {
    this.props.showModal({
      content: (
        <AddEventModal
          eventName={this.state.filter}
          handleCreateEvent={this.handleCreateEvent}
          close={this.props.hideModal}
          hideModal={this.props.hideModal}
        />
      )
    });
  };

  handleCreateEvent = eventData => {
    this.props.addEvent(eventData).then(createdEvent => {
      // redirect window to event to ensure we have a clean state
      window.location = `/event-light/${createdEvent.id}/dashboard`;
    });
  };

  goToEvent = (item, e) => {
    e.stopPropagation();
    if (item.status === "pending") {
      this.showAcceptInvitationConfirmation(item);
    } else {
      window.location = `/event-light/${item.id}/dashboard`;
    }
    this.props.hideDropdown();
  };

  goToOrg = id => {
    window.location = `/org-light/${id}/dashboard`;
    this.props.hideDropdown();
  };

  render() {
    const { myEvents, eventId } = this.props;
    const filteredEvents = this.searchEvents(myEvents);
    const starredEvents = filteredEvents.filter(i => i.starred);
    const organizationsWithEvents = filteredEvents
      .filter(e => Boolean(e.org_id))
      .reduce((hash, e) => {
        if (hash[e.org_id]) return hash;
        hash[e.org_id] = {
          name: e.org_name,
          events: filteredEvents.filter(f => e.org_id === f.org_id)
        };
        return hash;
      }, {});
    const otherEvents = filteredEvents.filter(e => !e.org_id);

    return (
      <div styleName="dropdownEvents" className="header-events-dropdown">
        <div styleName="inputWrapper">
          <SearchInput
            autoFocus
            containerType="dropdown"
            onChange={this.handleChange}
            placeholder="Find events by name..."
            value={this.state.filter}
          />
        </div>
        <GeminiScrollbar className={css.scrollbarWrapper}>
          {starredEvents.length ? (
            <Rows
              activeEventId={eventId}
              events={starredEvents}
              goToEvent={this.goToEvent}
              title="Starred Events"
              toggleStarredEvent={this.toggleStarredEvent}
            />
          ) : null}
          {Object.keys(organizationsWithEvents).map(id => (
            <Rows
              key={id}
              activeEventId={eventId}
              events={organizationsWithEvents[id].events}
              goToEvent={this.goToEvent}
              goToOrg={() => this.goToOrg(id)}
              title={organizationsWithEvents[id].name}
              toggleStarredEvent={this.toggleStarredEvent}
            />
          ))}
          {otherEvents.length ? (
            <Rows
              activeEventId={eventId}
              events={otherEvents}
              goToEvent={this.goToEvent}
              title="Other Events"
              toggleStarredEvent={this.toggleStarredEvent}
            />
          ) : (
            ""
          )}
        </GeminiScrollbar>
        <Link className={css.allEvents} to="/home">
          View All Events
        </Link>
      </div>
    );
  }
}

EventsDropdown.propTypes = {
  myEvents: PropTypes.array.isRequired,
  hideDropdown: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  toggleStarredEvent: PropTypes.func.isRequired,
  eventId: PropTypes.string
};

export default EventsDropdown;
