import React, { Component } from "react";
import View from "./View";

class InternalHandler extends Component {
  render() {
    const { dashboard, params, showSummaryModal, fetchDashboard } = this.props;
    return (
      <View
        {...{
          eventId: params.eventId,
          onAddWidget: showSummaryModal,
          onFetchDashboard: fetchDashboard,
          widgets: dashboard.widgets
        }}
      />
    );
  }
}

export default InternalHandler;
