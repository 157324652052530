import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import {
  ButtonGroup,
  ButtonOutline,
  Submit
} from "components/Global/Modal/Layout/Buttons";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import { ArrowRenderer } from "components/Global/Editors/utils/reactSelect";
import FormElements from "components/Global/Modal/Layout/FormElements";
import { Dropdown } from "components/Base";

const { Label, InputGroup } = FormElements;

class DeleteProfile extends Component {
  state = {
    replaceWithProfileId: null
  };

  handleProfileChange = selection =>
    this.setState({
      replaceWithProfileId: selection ? selection.value : null
    });

  handleSave = () => {
    this.props.done({
      replaceWithProfileId: this.state.replaceWithProfileId
    });
  };

  render() {
    const { profileId, permissionProfiles, hideModal } = this.props;
    return (
      <StyleWrapper heading="Delete profile" hideModal={hideModal}>
        <form onSubmit={this.handleSave}>
          <InputGroup>
            <Label>Select a profile to replace with</Label>
            <Dropdown
              usePortal
              arrowRenderer={ArrowRenderer}
              onChange={this.handleProfileChange}
              options={permissionProfiles
                .filter(p => p.id !== profileId)
                .map(({ id, name }) => ({ label: name, value: id }))}
              value={this.state.replaceWithProfileId}
            />
          </InputGroup>
          <ButtonGroup>
            <Submit
              title="Delete"
              disabled={!this.state.replaceWithProfileId}
            />
            <ButtonOutline title="Cancel" onClick={hideModal} />
          </ButtonGroup>
        </form>
      </StyleWrapper>
    );
  }
}

DeleteProfile.propTypes = {
  hideModal: PropTypes.func.isRequired,
  done: PropTypes.func.isRequired,
  profileId: PropTypes.string.isRequired,
  permissionProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired
};

export default CSSModules(DeleteProfile, css);
