import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Popover from "@lennd/material-ui/Popover";
import Toggle from "material-ui/Toggle";

@CSSModules(css)
class LockSubmissionsPopover extends Component {
  state = { showPopover: false };

  onRequestClose = () => this.setState({ showPopover: false });

  toggleFormLock = () => {
    if (!this.props.locked) {
      this.setState({ showPopover: true });
    }
    return this.props.toggleFormLock();
  };

  lockAllSubmissions = () => {
    this.onRequestClose();
    return this.props.lockAllSubmissions();
  };

  render() {
    return (
      <div>
        <div
          styleName="label"
          ref={ref => {
            this.anchor = ref;
          }}
        >
          {this.props.children}
          <Toggle
            onToggle={this.toggleFormLock}
            toggled={!this.props.locked}
            style={{ width: "auto" }}
          />
        </div>
        <Popover
          anchorEl={this.anchor}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          onClose={this.onRequestClose}
          open={this.state.showPopover}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
        >
          <div styleName="popover">
            <div styleName="text">
              This form is no longer accepting submissions.
            </div>
            <div styleName="option" onClick={this.lockAllSubmissions}>
              Lock existing submissions
              <i className="material-icons">lock</i>
            </div>
            <div styleName="option" onClick={this.onRequestClose}>
              Don't do anything
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}

LockSubmissionsPopover.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired
};

export default LockSubmissionsPopover;
