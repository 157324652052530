import { REQUEST, RECEIVE, ERROR } from "./constants";
import { combineReducers } from "redux";

const meals = (state = [], action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload;
    default:
      return state;
  }
};
const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE:
    case ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  meals,
  fetching
});
