import React, { Component, useState } from "react";
import CSSModules from "react-css-modules";
import * as R from "ramda";
import { get } from "lodash";
import css from "./styles.scss";
import SwitchAccounts from "./SwitchAccounts";
import { Div, Popover, DownFilledIcon, SmallAvatar } from "components/Base";
import GeminiScrollbar from "components/Global/GeminiScrollbar";
// import NewMessageTag from "components/Global/CRM/Forms/Form/Submission/Messages/NewMessageTag";
import MenuRow from "./MenuRow";

const isAccount = activeView => get(activeView, "type") === "account";

const EventSwitcher = CSSModules(({ currentEventName, events = [] }) => {
  const [term, setTerm] = useState("");
  const searchTerm = term.toLowerCase();

  return (
    <Popover
      innerSpanStyle={{
        display: "block",
        width: "100%"
      }}
      Label={({ onClick }) => (
        <Div
          width={1}
          height={45}
          display="row.flex-start.center"
          onClick={onClick}
          px={4}
          className={css.eventSwitcher}
        >
          {/*
          <HomeIcon
            size={20}
            style={{
              color: "#C2C4C4",
              flexShrink: 0
            }}
            color={{
              default: "neutral0",
              hover: "white"
            }}
            tooltip="View all events"
            onClick={e => {
              e.stopPropagation();
              console.log("go to home...");
            }}
          />
          */}
          <Div ml={3} fs={3} fw={3} color="white" flex={1} truncate>
            {currentEventName}
          </Div>
          <DownFilledIcon
            style={{
              color: "#C2C4C4",
              flexShrink: 0
            }}
            size={21}
          />
        </Div>
      )}
    >
      {({ closePopover }) => (
        <Div
          bg="white"
          width={350}
          // height={320}
          height={284}
          style={{
            borderRadius: "0px 0px 6px 6px",
            boxShadow: "0px 3px 6px rgba(74, 74, 74, 0.21)"
          }}
        >
          <Div
            style={{
              padding: "6px 10px",
              backgroundColor: "#505b5d"
            }}
          >
            <div
              style={{
                display: "flex",
                position: "relative",
                margin: "0 0 5px 0",
                height: "30px"
              }}
            >
              <i
                className="material-icons"
                style={{
                  position: "absolute",
                  left: "6px",
                  top: "6px",
                  fontSize: "20px",
                  color: "#FFFFFF",
                  transform: "rotate(90deg)"
                }}
              >
                &#xE8B6;
              </i>
              <input
                autoFocus
                onChange={e => setTerm(e.target.value)}
                value={term}
                placeholder="Search my events..."
                className={css.input}
                style={{
                  background: "#505b5d",
                  border: "none",
                  borderRadius: "3px",
                  fontSize: "15px",
                  color: "#FFFFFF",
                  paddingLeft: "33px",
                  width: "330px"
                }}
              />
            </div>
          </Div>
          <GeminiScrollbar
            style={{
              width: "350px",
              height: "237px"
            }}
          >
            <Div>
              {events
                .filter(e =>
                  e.name ? e.name.toLowerCase().includes(searchTerm) : false
                )
                .map(e => (
                  <div
                    className={css.eventRow}
                    key={e.id}
                    onClick={() => {
                      window.location = `/portals/${e.slug}/${e.uuid}`;
                      closePopover();
                    }}
                  >
                    {e.name}
                  </div>
                ))}
            </Div>
          </GeminiScrollbar>
          {/* <Link to="/home">View All Events</Link> */}
        </Div>
      )}
    </Popover>
  );
}, css);

const SECTIONS = {
  dashboard: {
    route: ["portalV2_PortalLanding"],
    path: ""
  },
  messages: {
    route: ["portalV2_PortalMessages"],
    path: "messages"
  },
  page: {
    route: ["portalV2_PortalPage"],
    path: "pages"
  },
  session: {
    route: ["portalV2_PortalSession"],
    path: "session"
  },
  forms: {
    route: ["portalV2_PortalForms"],
    path: "forms"
  },
  people: {
    route: ["portalV2_PortalPeople"],
    path: "people"
  },
  reports: {
    route: ["portalV2_PortalReports"],
    path: "reports"
  },
  documents: {
    route: ["portalV2_PortalDocuments", "portalV2_PortalDocumentsRequestModal"],
    path: "documents"
  },
  updates: {
    route: ["portalV2_PortalUpdates"],
    path: "updates"
  },
  profile: {
    route: ["portalV2_PortalPreviewProfile"],
    path: "profile"
  }
};

@CSSModules(css)
class PortalSidebar extends Component {
  activeAccount = (portalUser, accounts) => {
    const acct = accounts.find(
      ({ id }) => id === get(portalUser, "active_view.id")
    );
    if (acct) {
      return acct;
    }
    return {
      type: {
        icon: "person"
      },
      name: this.renderName(portalUser)
    };
  };

  renderName = ({ user_lname, user_fname, user_email }) =>
    user_fname && user_lname ? `${user_fname} ${user_lname}` : user_email;

  render() {
    const {
      portal,
      portalUser,
      accounts,
      hideMenu,
      sections,
      settings,
      flagCanViewPeopleTabOnPortals
    } = this.props;

    const updatedSections = R.compose(
      R.reject(s => s.id === "people" && !flagCanViewPeopleTabOnPortals),
      R.map(section => {
        if (section.content_type === "page") {
          return {
            ...section,
            isEnabled: true,
            route: [],
            path: `pages/${section.id}`
          };
        }
        return {
          ...section,
          isEnabled:
            section.id !== "dashboard"
              ? settings[`is_${section.id}_enabled`]
              : true,
          name: section.id === "dashboard" ? "Home" : section.title,
          ...R.propOr({}, section.id, SECTIONS)
        };
      }),
      R.sort((a, b) => a.order - b.order),

      // @NOTE: temp filtering out messages
      R.filter(s => s.id !== "messages")
    )(sections);

    // temp prevent render when portalUser is not defined
    if (!("id" in portalUser)) {
      return <div />;
    }

    const isViewingAsAccount = isAccount(get(portalUser, "active_view"));
    const accountName = isViewingAsAccount
      ? this.activeAccount(portalUser, accounts).name
      : null;
    const userName = this.renderName(portalUser);

    return (
      <div
        style={{
          backgroundColor: "#232a2b",
          position: "relative",
          width: "100%",
          overflowX: "hidden"
        }}
      >
        <EventSwitcher
          currentEventName={portal.event.name}
          events={portal.events}
        />
        <Div
          style={{
            padding: "0 28px"
          }}
        >
          <Div mt={4} mb={5}>
            {accountName ? (
              <Div
                fw={4}
                color="white"
                mb={1}
                style={{
                  fontSize: "20px"
                }}
              >
                {accountName}
              </Div>
            ) : null}
            <Div display="row.flex-start.center">
              <SmallAvatar text={userName} />
              <Div fs={2} fw={3} ml={2} color="white">
                {userName}
              </Div>
            </Div>
          </Div>
        </Div>
        <Div display="column">
          {updatedSections
            .filter(s => s.isEnabled && !s.parent)
            .map(s => {
              return (
                <MenuRow
                  key={s.id}
                  hideMenu={hideMenu}
                  contentType={s.content_type}
                  pagesChildren={R.propOr([], "children", s)}
                  name={s.name}
                  path={s.path}
                  route={s.route}
                  sections={updatedSections}
                  id={s.id}
                >
                  {s.name}
                </MenuRow>
              );
            })}
        </Div>
        <Div
          mt={6}
          style={{
            padding: "0 28px"
          }}
        >
          {accounts.length ||
          portalUser.user_id !== get(portalUser, "active_view.id") ? (
            <div styleName="section">
              <SwitchAccounts portalUser={portalUser} accounts={accounts} />
            </div>
          ) : null}

          {portalUser.is_event_user ? (
            <a
              href={`/event-light/${portal.event.id}/dashboard`}
              styleName="adminButton"
            >
              <i className="material-icons">reply</i>
              Back to Admin Mode
            </a>
          ) : null}

          <a
            href={`/logout?returnTo=/portal-login/${portal.event.slug}/${portal.event.uuid}`}
            styleName="logoutButton"
          >
            Logout
          </a>
        </Div>
      </div>
    );
  }
}

export default PortalSidebar;
