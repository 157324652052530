import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "OrgLight/Dashboard/model";
import {
  Div,
  CalendarIcon,
  AddIcon,
  MediumFilledButton
} from "components/Base";
import { EVENT_TABS, EVENT_OPTIONS } from "OrgLight/Dashboard/constants";
import Circle from "ui-kit/Circle";
import YearSelector from "./YearSelector";

const decorate = connect(
  state => ({
    eventsSelectedTab: getters.eventsSelectedTab(state),
    eventSelectedOption: getters.eventSelectedOption(state)
  }),
  {
    setEventsSelectedTab: actions.setEventsSelectedTab,
    setEventSelectedOption: actions.setEventSelectedOption,
    handleAddEventModal: actions.handleAddEventModal
  }
);

const UpcomingHeader = ({
  eventsSelectedTab,
  setEventsSelectedTab,
  eventSelectedOption,
  setEventSelectedOption,
  handleAddEventModal
}) => (
  <Div bb={1} bc="neutral2">
    <Div display="row.space-between.center" p={4}>
      <Div display="row.flex-start.center">
        <Circle size={40} bg="neutral1">
          <CalendarIcon size={24} />
        </Circle>
        <Div color="neutral7" fw={4} ml={2} fs={5}>
          Events
        </Div>
      </Div>
      <Div display="row.flex-start.center">
        {eventSelectedOption === EVENT_OPTIONS.CALENDAR && (
          <Div mr={2}>
            <YearSelector />
          </Div>
        )}

        <Div
          bg="neutral0"
          style={{ borderRadius: 20 }}
          p={1}
          display="row.flex-start.center"
          mr={2}
        >
          <MediumFilledButton
            bg={
              eventSelectedOption === EVENT_OPTIONS.LIST ? "white" : "neutral0"
            }
            pill
            color="neutral7"
            width={78}
            onClick={() => setEventSelectedOption(EVENT_OPTIONS.LIST)}
          >
            List
          </MediumFilledButton>
          <MediumFilledButton
            bg={
              eventSelectedOption === EVENT_OPTIONS.CALENDAR
                ? "white"
                : "neutral0"
            }
            pill
            color="neutral7"
            ml={2}
            onClick={() => setEventSelectedOption(EVENT_OPTIONS.CALENDAR)}
          >
            Calendar
          </MediumFilledButton>
        </Div>
        <Div
          width={40}
          height={40}
          bg="neutral1"
          style={{ borderRadius: "50%" }}
          onClick={handleAddEventModal}
          display="row.center.center"
        >
          <AddIcon size={24} />
        </Div>
      </Div>
    </Div>

    {eventSelectedOption === EVENT_OPTIONS.LIST && (
      <Div display="row.flex-start.center" px={4}>
        <Div
          pb={2}
          mr={2}
          color={
            eventsSelectedTab === EVENT_TABS.UPCOMING ? "primary7" : "black"
          }
          bc={
            eventsSelectedTab === EVENT_TABS.UPCOMING
              ? "primary7"
              : "transparent"
          }
          bb={2}
          onClick={() => setEventsSelectedTab(EVENT_TABS.UPCOMING)}
          px={1}
          display="row.flex-start.center"
        >
          <Div
            fs={2}
            fw={3}
            color={
              eventsSelectedTab === EVENT_TABS.UPCOMING ? "primary7" : "black"
            }
          >
            Upcoming
          </Div>
        </Div>
        <Div
          pb={2}
          mr={2}
          bc={
            eventsSelectedTab === EVENT_TABS.PAST ? "primary7" : "transparent"
          }
          bb={2}
          onClick={() => setEventsSelectedTab(EVENT_TABS.PAST)}
          px={1}
          display="row.flex-start.center"
        >
          <Div
            fs={2}
            fw={3}
            color={eventsSelectedTab === EVENT_TABS.PAST ? "primary7" : "black"}
          >
            Past
          </Div>
        </Div>
      </Div>
    )}
  </Div>
);

export default decorate(UpcomingHeader);
