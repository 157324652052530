import React, { Component, Fragment } from "react";
import "styles/less/includes/plugins/react-toggle-switch.less";
import Panel from "./Panel";
import Group from "./Group";
import Permission from "./Permission";
import Well from "./Well";
import ModifyRoleModal from "./Modals/ModifyRole";
import DeleteProfileModal from "./Modals/DeleteProfile";
import PermissionsSidebar from "./PermissionsSidebar";
import PermissionsHeader from "./PermissionsHeader";
import Search from "./Search";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as R from "ramda";
import SelectItemsModal from "./SelectItemsModal";
import { isEventFeatureEnabled } from "redux/modules/event/selectors";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { Radio, Div, SmallCheckbox } from "components/Base";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import { showModal, hideModal } from "redux/modules/modal/actions";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { getters, actions } from "../model";

const decorate = R.compose(
  withRouter,
  connect(
    state => ({
      permissionGroups: getters.permissionGroups(state),
      permissionProfiles: getters.permissionProfiles(state),
      eventDetails: getEventDetails(state),
      search: getters.search(state),
      activePermissionProfileId: getters.activePermissionProfileId(state),
      showItemsModalForModuleId: getters.showItemsModalForModuleId(state),
      isEventFeatureEnabled: isEventFeatureEnabled(state)
    }),
    {
      showModal,
      hideModal,
      updatePermissionProfilePermissions:
        actions.updatePermissionProfilePermissions,
      getEventPermissionProfiles: actions.getEventPermissionProfiles,
      setSearch: actions.setSearch,
      setActivePermissionProfileId: actions.setActivePermissionProfileId,
      setShowItemsModalForModuleId: actions.setShowItemsModalForModuleId,
      addEventPermissionProfile: actions.addEventPermissionProfile,
      updatePermissionProfile: actions.updatePermissionProfile,
      deletePermissionProfile: actions.deletePermissionProfile
    }
  ),
  CSSModules(css)
);

const ReadPermission = ({ selected, name, onClick, children }) => (
  <Div display="column">
    <Div display="row.flex-start.center" onClick={onClick} mb={2}>
      <Radio selected={selected} mr={1} size={20} />
      <Div fs={2} color="black">
        {name}
      </Div>
    </Div>
    {children}
  </Div>
);

class Body extends Component {
  updateSearch = e => this.props.setSearch({ search: e.target.value });

  searchQueryExists = () => Boolean(this.props.search.trim().length);

  groupNameMatchesSearch = name =>
    name
      .toLowerCase()
      .trim()
      .indexOf(this.props.search) > -1;

  getActivePermissionProfile = () =>
    this.props.permissionProfiles.find(
      p => p.id === this.props.activePermissionProfileId
    );

  handlePermissionGroup = (permissionGroupId, enabled) => {
    const activePermissionProfile = this.getActivePermissionProfile();
    const permissionGroup = this.props.permissionGroups.find(
      g => g.id === permissionGroupId
    );
    let permissions = [...activePermissionProfile.permissions];

    if (enabled) {
      const permissionGroupHasDefaultPermissions = Boolean(
        permissionGroup.permissions.filter(p => p.is_default).length
      );
      const permissionsToAdd = permissionGroupHasDefaultPermissions
        ? permissionGroup.permissions.reduce((result, permission) => {
            if (permission.is_default) {
              result.push(permission);
            }
            return result;
          }, [])
        : permissionGroup.permissions;

      permissions = [
        ...permissions,
        ...permissionsToAdd,
        permissionGroup.read_permissions[0],
        permissionGroup.add_line_item_permissions[0]
      ].filter(v => v);
    } else {
      permissions = permissions.filter(
        permission =>
          permissionGroup.permissionList.indexOf(permission.slug) === -1
      );
    }

    this.props.updatePermissionProfilePermissions({
      eventId: this.props.params.eventId,
      profileId: this.props.activePermissionProfileId,
      permissions: permissions.map(p => this.formatPermission(p))
    });
  };

  handlePermission = (permission, enabled) => {
    const activePermissionProfile = this.getActivePermissionProfile();
    let permissions = [...activePermissionProfile.permissions];
    if (enabled) {
      permissions.push(permission);
    } else {
      permissions = permissions.filter(p => p.slug !== permission.slug);
    }

    this.props.updatePermissionProfilePermissions({
      eventId: this.props.params.eventId,
      profileId: this.props.activePermissionProfileId,
      permissions: permissions.map(p => this.formatPermission(p))
    });
  };

  selectReadPermission = (readPermission, permissionGroup) => {
    const activePermissionProfile = this.getActivePermissionProfile();
    let permissions = [...activePermissionProfile.permissions];

    // remove any other activated read permissions in the same group then add new one
    permissions = permissions.filter(
      p => p.slug && !p.slug.includes(`${permissionGroup.id}_read`)
    );
    permissions.push(readPermission);

    this.props.updatePermissionProfilePermissions({
      eventId: this.props.params.eventId,
      profileId: this.props.activePermissionProfileId,
      permissions: permissions.map(p => this.formatPermission(p))
    });
  };

  selectAddLineItemPermission = (addPermission, permissionGroup) => {
    const activePermissionProfile = this.getActivePermissionProfile();
    let permissions = [...activePermissionProfile.permissions];

    // remove any other activated read permissions in the same group then add new one
    permissions = permissions.filter(
      p => p.slug && !p.slug.includes(`${permissionGroup.id}_add_line_items`)
    );
    if (addPermission) {
      permissions.push(addPermission);
    }

    this.props.updatePermissionProfilePermissions({
      eventId: this.props.params.eventId,
      profileId: this.props.activePermissionProfileId,
      permissions: permissions.map(p => this.formatPermission(p))
    });
  };

  selectPermissionRecordType = (permission, type, enabled) => {
    const activePermissionProfile = this.getActivePermissionProfile();
    let permissions = [...activePermissionProfile.permissions];

    if (enabled) {
      /*
      // if parent toggle is not enabled, enable it too
      if (
        !permissions.some(
          p => p.slug === permission.slug && p.record_type_id === null
        )
      ) {
        permissions.push(permission);
      }
      */

      permissions.push({
        ...permission,
        record_type_id: type.id
      });
    } else {
      permissions = permissions.filter(p => {
        if (p.slug === permission.slug && p.record_type_id === type.id) {
          return false;
        }
        return true;
      });
    }

    this.props.updatePermissionProfilePermissions({
      eventId: this.props.params.eventId,
      profileId: this.props.activePermissionProfileId,
      permissions: permissions.map(p => this.formatPermission(p))
    });
  };

  enableAllPermissions = () => {
    const permissions = this.props.permissionGroups.reduce(
      (result, group) =>
        [
          ...result,
          ...group.permissions,
          group.read_permissions[0],
          group.add_line_item_permissions[0]
        ].filter(v => v),
      []
    );

    this.props.updatePermissionProfilePermissions({
      eventId: this.props.params.eventId,
      profileId: this.props.activePermissionProfileId,
      permissions: permissions.map(p => this.formatPermission(p))
    });
  };

  disableAllPermissions = () => {
    this.props.updateRolePermissions(
      this.props.params.eventId,
      this.props.activePermissionProfileId,
      []
    );
  };

  isPermissionGroupSwitchOn = permissionGroup => {
    const activePermissionProfile = this.props.permissionProfiles.find(
      p => p.id === this.props.activePermissionProfileId
    );
    return [
      ...permissionGroup.permissions,
      ...permissionGroup.read_permissions,
      ...permissionGroup.add_line_item_permissions
    ]
      .map(p => p.slug)
      .some(slug => activePermissionProfile.permissionList.indexOf(slug) > -1);
  };

  isPermissionSwitchOn = permissionSlug =>
    this.props.permissionProfiles
      .find(p => p.id === this.props.activePermissionProfileId)
      .permissionList.indexOf(permissionSlug) > -1;

  isPermissionTypeSwitchOn = (permissionSlug, typeId) => {
    return this.props.permissionProfiles
      .find(p => p.id === this.props.activePermissionProfileId)
      .permissions.some(p => {
        return p.record_type_id === typeId;
      });
  };

  isPermissionItemSwitchOn = (permissionSlug, typeId) => {
    return this.props.permissionProfiles
      .find(p => p.id === this.props.activePermissionProfileId)
      .permissions.some(p => {
        return p.record_type_id === typeId;
      });
  };

  onPermissionMenuClick = (permissionProfileId, event, command) => {
    event.preventDefault();
    switch (command) {
      case "edit":
        this.showModifyRoleModal(permissionProfileId);
        break;
      case "delete":
        this.showDeletePermissionProfileModal(permissionProfileId);
        break;
      case "duplicate":
        const permissionProfile = this.props.permissionProfiles.find(
          p => p.id === permissionProfileId
        );
        const name = `${permissionProfile.name} Copy`;
        this.onCreatePermissionProfile(
          this.generateCopyName(name, this.props.permissionProfiles),
          permissionProfile.permissions
        );
        break;
      default:
        break;
    }
  };

  generateCopyName = (name, permissionProfiles, count = 0) => {
    const newName = `${name}${count > 0 ? ` (${count})` : ""}`;
    const duplicate = permissionProfiles.find(r => r.name === newName);
    if (duplicate) {
      return this.generateCopyName(name, permissionProfiles, count + 1);
    }
    return newName;
  };

  getDefaultPermissionProfile = permissionProfiles =>
    permissionProfiles.filter(p => !p.editable)[0].id;

  selectPermissionProfile = activePermissionProfileId =>
    this.props.setActivePermissionProfileId(activePermissionProfileId);

  formatPermission = permission => ({
    permissionId: permission.id,
    permissionModuleId: permission.module_id,
    recordTypeId: permission.record_type_id,
    itemId: permission.item_id
  });

  onCreatePermissionProfile = async (name, permissions = []) => {
    await this.props.addEventPermissionProfile({
      eventId: this.props.params.eventId,
      name,
      editable: true,
      description: null,
      permissions: permissions.map(p => this.formatPermission(p))
    });
  };

  showModifyRoleModal = permissionProfileId => {
    const { name, description } = this.props.permissionProfiles.find(
      p => p.id === permissionProfileId
    );
    const { hideModal } = this.props;
    const done = (newName, newDescription) => {
      this.props.updatePermissionProfile({
        eventId: this.props.params.eventId,
        profileId: permissionProfileId,
        name: newName,
        description: newDescription
      });
    };

    this.props.showModal({
      content: <ModifyRoleModal {...{ done, name, description, hideModal }} />
    });
  };

  showDeletePermissionProfileModal = permissionProfileId => {
    const permissionProfile = this.props.permissionProfiles.find(
      p => p.id === permissionProfileId
    );
    const { hideModal } = this.props;
    const eventId = this.props.params.eventId;
    const done = ({ replaceWithProfileId }) => {
      hideModal();

      if (this.props.activePermissionProfileId === permissionProfileId) {
        this.props.setActivePermissionProfileId(replaceWithProfileId);
      }

      this.props.deletePermissionProfile({
        eventId,
        profileId: permissionProfile.id,
        replaceWithProfileId
      });
    };

    this.props.showModal({
      content: (
        <DeleteProfileModal
          {...{
            done,
            eventId,
            profileId: permissionProfile.id,
            hideModal,
            permissionProfiles: this.props.permissionProfiles
          }}
        />
      )
    });
  };

  showItemsModal = moduleId =>
    this.props.setShowItemsModalForModuleId(moduleId);

  hideItemsModal = () => this.props.setShowItemsModalForModuleId(null);

  onUpdateItems = ({ addPermission, selectedItemIds }) => {
    const activePermissionProfile = this.getActivePermissionProfile();
    let permissions = [...activePermissionProfile.permissions].filter(p => {
      if (p.slug === addPermission.slug && p.item_id) {
        return false;
      }
      return true;
    });

    selectedItemIds.forEach(itemId => {
      permissions.push({
        ...addPermission,
        item_id: itemId
      });
    });

    this.props.updatePermissionProfilePermissions({
      eventId: this.props.params.eventId,
      profileId: this.props.activePermissionProfileId,
      permissions: R.uniq(permissions.map(p => this.formatPermission(p)))
    });

    this.hideItemsModal();
  };

  getSelectedItemIds = moduleId => {
    return R.compose(
      R.map(p => p.item_id),
      R.filter(p => p.item_id && p.module_id === moduleId),
      R.prop("permissions"),
      R.find(p => p.id === this.props.activePermissionProfileId)
    )(this.props.permissionProfiles);
  };

  doesPermissionExistWithPrefix = prefix => {
    return R.compose(
      R.length,
      R.filter(p => p && p.includes(prefix)),
      R.prop("permissionList"),
      R.find(p => p.id === this.props.activePermissionProfileId)
    )(this.props.permissionProfiles);
  };

  renderPermissionGroups = ({
    groups,
    canUpdatePermission,
    forceEnableAllPermissions
  }) =>
    groups.map(permissionGroup => {
      const isPermissionGroupSwitchOn = this.isPermissionGroupSwitchOn(
        permissionGroup
      );
      const handlePermissionGroup = () => {
        if (canUpdatePermission) {
          this.handlePermissionGroup(
            permissionGroup.id,
            !isPermissionGroupSwitchOn
          );
        }
      };

      let permissions = permissionGroup.permissions;

      if (permissionGroup.read_permissions.length === 1) {
        permissions = [...permissionGroup.read_permissions, ...permissions];
      }
      if (permissionGroup.add_line_item_permissions.length === 1) {
        permissions = [
          ...permissionGroup.add_line_item_permissions,
          ...permissions
        ];
      }

      return (
        <Fragment>
          <Group
            permissionGroup={permissionGroup}
            enabled={canUpdatePermission}
            on={forceEnableAllPermissions || isPermissionGroupSwitchOn}
            onClick={handlePermissionGroup}
            key={`${this.props.activePermissionProfileId}_${permissionGroup.id}`}
          >
            {permissionGroup.read_permissions.length > 1 ? (
              <Div
                display="row.flex-start.flex-start"
                py={1}
                bb={1}
                bc="gray2"
                styleName="container"
              >
                <Div fs={2} color="black" fw={3} mr={2}>
                  Can View
                </Div>
                <Div display="column">
                  {permissionGroup.read_permissions.map(readPermission => {
                    const isPermissionSwitchOn = this.isPermissionSwitchOn(
                      readPermission.slug
                    );

                    const forceReadPermission =
                      forceEnableAllPermissions &&
                      readPermission.slug === `${permissionGroup.id}_read`
                        ? true
                        : false;

                    return (
                      <ReadPermission
                        key={readPermission.id}
                        name={readPermission.name}
                        selected={forceReadPermission || isPermissionSwitchOn}
                        onClick={
                          canUpdatePermission && !isPermissionSwitchOn
                            ? () => {
                                this.selectReadPermission(
                                  readPermission,
                                  permissionGroup
                                );
                              }
                            : undefined
                        }
                      >
                        {isPermissionSwitchOn &&
                          readPermission.types.map(typePermission => {
                            const isPermissionTypeSwitchOn = this.isPermissionTypeSwitchOn(
                              readPermission.slug,
                              typePermission.id
                            );
                            return (
                              <Div
                                display="row.flex-start.center"
                                pl={5}
                                onClick={() => {
                                  this.selectPermissionRecordType(
                                    readPermission,
                                    typePermission,
                                    !isPermissionTypeSwitchOn
                                  );
                                }}
                              >
                                <SmallCheckbox
                                  mr={1}
                                  selected={isPermissionTypeSwitchOn}
                                  onClick={() => {}}
                                />
                                <Div fs={1} color="gray7">
                                  {typePermission.name}
                                </Div>
                              </Div>
                            );
                          })}
                      </ReadPermission>
                    );
                  })}
                </Div>
              </Div>
            ) : null}

            {permissionGroup.add_line_item_permissions.length > 1 ? (
              <Div
                display="row.flex-start.flex-start"
                py={2}
                bb={1}
                bc="gray2"
                styleName="container"
              >
                <Div fs={2} color="black" fw={3} mr={2}>
                  Can Add
                </Div>
                <Div display="column">
                  <ReadPermission
                    name="Nothing"
                    selected={
                      !this.doesPermissionExistWithPrefix(
                        `${permissionGroup.id}_add_line_items`
                      )
                    }
                    onClick={
                      canUpdatePermission
                        ? () => {
                            this.selectAddLineItemPermission(
                              null,
                              permissionGroup
                            );
                          }
                        : undefined
                    }
                  />

                  {permissionGroup.add_line_item_permissions.map(
                    addPermission => {
                      const isPermissionSwitchOn = this.isPermissionSwitchOn(
                        addPermission.slug
                      );

                      const forceReadPermission =
                        forceEnableAllPermissions &&
                        addPermission.slug ===
                          `${permissionGroup.id}_add_line_items`
                          ? true
                          : false;

                      const selectedIds = this.getSelectedItemIds(
                        permissionGroup.id
                      );

                      return (
                        <ReadPermission
                          key={addPermission.id}
                          name={addPermission.name}
                          selected={forceReadPermission || isPermissionSwitchOn}
                          onClick={
                            canUpdatePermission && !isPermissionSwitchOn
                              ? () => {
                                  this.selectAddLineItemPermission(
                                    addPermission,
                                    permissionGroup
                                  );
                                }
                              : undefined
                          }
                        >
                          {isPermissionSwitchOn &&
                          addPermission.items.length ? (
                            <Div>
                              <Div
                                color="primary8"
                                underline
                                fs={2}
                                mb={1}
                                onClick={() =>
                                  this.showItemsModal(permissionGroup.id)
                                }
                              >
                                Select Items ({selectedIds.length} selected)
                              </Div>
                              {this.props.showItemsModalForModuleId ===
                                permissionGroup.id && (
                                <SelectItemsModal
                                  showModal
                                  itemType={addPermission.items}
                                  selectedItemIds={selectedIds}
                                  onClose={() => this.hideItemsModal()}
                                  onDone={selectedItemIds =>
                                    this.onUpdateItems({
                                      addPermission,
                                      selectedItemIds
                                    })
                                  }
                                />
                              )}
                            </Div>
                          ) : null}
                        </ReadPermission>
                      );
                    }
                  )}
                </Div>
              </Div>
            ) : null}
            {permissions.map(permission => {
              const isPermissionSwitchOn = this.isPermissionSwitchOn(
                permission.slug
              );
              const handlePermission = () => {
                if (canUpdatePermission) {
                  this.handlePermission(permission, !isPermissionSwitchOn);
                }
              };

              return (
                <Permission
                  key={`${permissionGroup.id}_${permission.slug}`}
                  permission={permission}
                  onClick={handlePermission}
                  enabled={canUpdatePermission}
                  on={forceEnableAllPermissions || isPermissionSwitchOn}
                />
              );
            })}
          </Group>
        </Fragment>
      );
    });

  render() {
    const { permissionProfiles, eventDetails } = this.props;

    const activeRole = this.getActivePermissionProfile();

    // override switches for admin profile to show all turned on
    const forceEnableAllPermissions = [
      "7c1fba5d-633a-4d54-9077-a50a5bd4e7e0",
      "45f029df-33ca-4c53-a02c-bbd6c92a5f35"
    ].includes(activeRole.id);

    const canUpdatePermission = !!activeRole.editable;
    const searchQueryExists = this.searchQueryExists();

    let standardModulePermissionGroups = searchQueryExists
      ? this.props.permissionGroups.filter(
          g => g.source === "standard" && this.groupNameMatchesSearch(g.name)
        )
      : this.props.permissionGroups.filter(g => g.source === "standard");
    standardModulePermissionGroups = standardModulePermissionGroups.filter(g =>
      isEventFeatureEnabled(g.id)
    );

    const customModulePermissionGroups = searchQueryExists
      ? this.props.permissionGroups.filter(
          g => g.source !== "standard" && this.groupNameMatchesSearch(g.name)
        )
      : this.props.permissionGroups.filter(g => g.source !== "standard");

    return (
      <Div display="row.flex-start.flex-start">
        <PermissionsSidebar
          permissionProfiles={permissionProfiles}
          activeRole={this.props.activePermissionProfileId}
          onRoleSelect={this.selectPermissionProfile}
          onCreate={this.onCreatePermissionProfile}
          onMenuClick={this.onPermissionMenuClick}
        />
        <Well>
          <PermissionsHeader
            canEditPermissions={canUpdatePermission}
            role={activeRole}
            enableAllPermissions={this.enableAllPermissions}
            disableAllPermissions={this.disableAllPermissions}
          />
          <Search onChange={this.updateSearch} query={this.props.search} />

          {standardModulePermissionGroups.length ? (
            <Panel>
              {this.renderPermissionGroups({
                groups: [
                  ...standardModulePermissionGroups.filter(
                    g => g.id === STANDARD_MODULE_IDS.settings.id
                  ),
                  ...standardModulePermissionGroups.filter(
                    g => g.id !== STANDARD_MODULE_IDS.settings.id
                  )
                ],
                canUpdatePermission
              })}
            </Panel>
          ) : null}

          {customModulePermissionGroups.length ? (
            <div>
              <div styleName="moduleTypeTitle">Custom Modules</div>
              <Panel>
                {this.renderPermissionGroups({
                  groups: customModulePermissionGroups,
                  canUpdatePermission,
                  forceEnableAllPermissions
                })}
              </Panel>
            </div>
          ) : null}

          <br />
          <br />
        </Well>
      </Div>
    );
  }
}

export default decorate(Body);
