import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: false,
  data: [],
  moduleId: ""
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    handleAddRecordModal: R.identity,
    handleRecordTypeModal: R.identity,
    handleEditRecordTypeModal: R.identity,
    setManager: R.identity,
    setApprover: R.identity,
    enablePeopleTypes: R.identity,
    enableItemCatalogAccess: R.identity,
    assignForms: R.identity,
    assignDocRequest: R.identity,
    assignTask: R.identity,
    copyInviteLink: R.identity,
    viewLink: R.identity,
    editLink: R.identity,
    uploadFiles: R.identity,
    allocateMeals: R.identity,
    allocatePass: R.identity,
    setInitialData: (_, { payload: { data, moduleId } }) => ({
      data,
      moduleId
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
