import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "../index";
import { Div, BigFilledInput, SearchIcon } from "components/Base";
import TemplatesPopover from "./TemplatesPopover";
import TemplateList from "./TemplateList";
import UploadDocumentModal from "./UploadDocumentModal";
import MergeTagModal from "./MergeTagModal";
import MergeTags from "./MergeTags";

const decorate = connect(
  state => ({
    templatesFilter: getters.templatesFilter(state),
    hasModuleId: getters.hasModuleId(state)
  }),
  {
    setTemplatesFilter: actions.setTemplatesFilter
  }
);

const Layout = ({
  templatesFilter,
  setTemplatesFilter,
  handlers,
  hasModuleId
}) => (
  <Div width={1} height={1}>
    <UploadDocumentModal />
    <MergeTagModal />
    {!hasModuleId && (
      <Div
        px={4}
        height={84}
        bb={1}
        bc="neutral2"
        color="neutral7"
        fw={2}
        fs={6}
        bg="white"
        display="row.flex-start.center"
      >
        Document Templates
      </Div>
    )}
    <Div width={1} flex={1} p={4} display="row">
      <Div width={650} height={1}>
        <Div display="row.flex-start.center">
          {!hasModuleId && <TemplatesPopover />}
          <BigFilledInput
            flex={1}
            bg="neutral1"
            color="neutral5"
            RightIcon={SearchIcon}
            placeholder="Search document templates..."
            value={templatesFilter}
            onChange={value => setTemplatesFilter(value)}
            continuous
          />
        </Div>
        <TemplateList handlers={handlers} />
      </Div>
      <MergeTags />
    </Div>
  </Div>
);

export default decorate(Layout);
