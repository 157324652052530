import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { actions } from "ui-kit/FilterControls";
import { getOptions, getFiltersOn } from "ui-kit/FilterControls/selectors";

import { Text2, CheckIcon, CancelIcon } from "components/Base";
import { noop } from "utils/General";

import { TYPES } from "ui-kit/FilterControls/constants";

import { Panel } from "./Panel";
import { Box } from "./Box";

const decorate = connect(
  (state, props) => ({
    options: getOptions(state, { ...props, type: TYPES.MULTIPLE }),
    filtersOn: getFiltersOn(state, props)
  }),
  bindInstance({
    init: actions.init,
    toggleOption: actions.toggleOption
  })
);

const MultipleFilter = decorate(
  ({
    options = [],
    filtersOn = false,
    children: label,
    multiSelect = false,
    iniSelected = [],
    init = noop,
    toggleOption = noop,
    collapsedDefault,
    ...styleProps
  }) => {
    useEffect(() => {
      init({ iniSelected, multiSelect });
    }, []);

    return (
      <Panel
        label={label}
        filtersOn={filtersOn}
        collapsedDefault={collapsedDefault}
        {...styleProps}
      >
        {R.map(
          option => (
            <Box
              bg={{
                default: option.selected ? "neutral1" : "transparent",
                hover: "neutral1"
              }}
              key={option.id}
              onClick={() => toggleOption(option.id)}
            >
              <CheckIcon
                mr={2}
                style={{ visibility: option.selected ? "visible" : "hidden" }}
              />
              <Text2 flex={1} bold={option.selected}>
                {option.name}
              </Text2>
              <CancelIcon
                onClick={e => {
                  e.stopPropagation();
                  toggleOption(option.id);
                }}
                ml={2}
                style={{
                  visibility: option.selected ? "visible" : "hidden"
                }}
              />
            </Box>
          ),
          options
        )}
      </Panel>
    );
  }
);

export { MultipleFilter };
