import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import { getInheritOptions, getSelectedInheritOption } from "../selectors";
import * as R from "ramda";
import { Div, Cards } from "components/Base";
import CSSModules from "react-css-modules";
import css from "./style.scss";
import {
  FORM_ID,
  FIELD_IDS,
  INTERCOM_METHODS,
  CONTENT_SECTION_TYPES,
  FONT_FAMILIES,
  VIDEO_SORT_METHODS
} from "../constants";
import {
  WithFormInstanceProvider,
  Input,
  Wysiwyg,
  Toggle,
  Image,
  Dropdown as FormDropdown,
  Color,
  TextAreaInput
} from "ui-kit/Form/View";

import {
  Panel,
  PanelTitle,
  FieldWrapper,
  Label,
  Link
} from "EventLight/Common/FieldLayout";

const decorate = R.compose(
  WithFormInstanceProvider(R.always(FORM_ID)),
  connect(
    (state, props) => ({
      data: getters.settings(state, props),
      inheritOptions: getInheritOptions(state, props),
      selectedInheritOption: getSelectedInheritOption(state, props)
    }),
    {
      toggleRightSidebarLink: actions.toggleRightSidebarLink,
      onReorderRightSidebarLinks: actions.onReorderRightSidebarLinks,
      onUpdateRightSidebarValue: actions.onUpdateRightSidebarValue
    }
  )
);

const RightSidebarLinks = Cards(Link, "MANAGE_RIGHT_SIDEBAR_LINKS");

const Content = ({
  data,
  onUpdateRightSidebarValue,
  onReorderRightSidebarLinks,
  inheritOptions,
  selectedInheritOption
}) => (
  <Div width={1} style={{ padding: 40 }}>
    {/* page settings */}
    <Div bg="white" bra={1} p={5} display="column" mb={4}>
      <PanelTitle>Settings</PanelTitle>

      <FieldWrapper>
        <Label>Enable Media Hub</Label>
        <Toggle
          size="medium"
          fieldId={FIELD_IDS.MEDIA_CENTER.ENABLE_COMMUNITY_HUB}
          iniValue={["true", true].includes(
            R.prop(FIELD_IDS.MEDIA_CENTER.ENABLE_COMMUNITY_HUB, data)
          )}
          bg="green6"
        />
      </FieldWrapper>

      <FieldWrapper>
        <Label>Use same settings as other portal</Label>
        <FormDropdown
          fieldId={FIELD_IDS.MEDIA_CENTER.INHERIT_PORTAL}
          width={"100%"}
          iniValue={selectedInheritOption}
          options={inheritOptions}
          size="big"
        />
      </FieldWrapper>

      {selectedInheritOption ? null : (
        <>
          <FieldWrapper>
            <Label tooltip="Add a label to the link a user sees on their portal dashboard">
              Dashboard Label
            </Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.DASHBOARD_LABEL}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.DASHBOARD_LABEL)(data)}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label tooltip="Add a description to the link a user sees on their portal dashboard">
              Dashboard Description
            </Label>
            <TextAreaInput
              fieldId={FIELD_IDS.MEDIA_CENTER.DASHBOARD_DESCRIPTION}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.DASHBOARD_DESCRIPTION)(
                data
              )}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Intercom ID</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.INTERCOM_ID}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.INTERCOM_ID)(data)}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Intercom Method</Label>
            <FormDropdown
              fieldId={FIELD_IDS.MEDIA_CENTER.INTERCOM_METHOD}
              width={"100%"}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.INTERCOM_METHOD)(data)}
              options={INTERCOM_METHODS}
              size="big"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Google Tag Manager ID</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.GOOGLE_TAG_MANAGER_ID}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.GOOGLE_TAG_MANAGER_ID)(
                data
              )}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Font Family</Label>
            <FormDropdown
              fieldId={FIELD_IDS.MEDIA_CENTER.FONT_FAMILY}
              width={"100%"}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.FONT_FAMILY)(data)}
              options={FONT_FAMILIES}
              size="big"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Sidebar Color</Label>
            <Color
              fieldId={FIELD_IDS.MEDIA_CENTER.LEFT_SIDEBAR_COLOR}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.LEFT_SIDEBAR_COLOR)(data)}
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label tooltip="Darker shade of sidebar color. Used for tabs.">
              Sidebar Secondary Color
            </Label>
            <Color
              fieldId={FIELD_IDS.MEDIA_CENTER.LEFT_SIDEBAR_SECONDARY_COLOR}
              iniValue={R.prop(
                FIELD_IDS.MEDIA_CENTER.LEFT_SIDEBAR_SECONDARY_COLOR
              )(data)}
            />
          </FieldWrapper>

          {/*
      <FieldWrapper>
        <Label>Link Color</Label>
          <Color
        fieldId={FIELD_IDS.MEDIA_CENTER.LINK_COLOR}
          iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.LINK_COLOR)(data)}
        />
      </FieldWrapper>
      */}
        </>
      )}
    </Div>

    {selectedInheritOption ? null : (
      <>
        {/* left sidebar video */}
        <Div bg="white" bra={1} p={5} display="column" mb={4}>
          <PanelTitle>Left Sidebar</PanelTitle>

          <FieldWrapper>
            <Label>Sidebar Logo</Label>

            <Image
              fieldId={FIELD_IDS.MEDIA_CENTER.LEFT_SIDEBAR_LOGO_IMAGE_URL}
              containerWidth="auto"
              containerProps={{
                display: "row.flex-start.flex-start",
                flex: 1
              }}
              imageWidth={120}
              imageHeight={35}
              recommendedWidth={"480"}
              recommendedHeight={"140"}
              iniValue={
                R.prop(FIELD_IDS.MEDIA_CENTER.LEFT_SIDEBAR_LOGO_IMAGE_URL, data)
                  ? [
                      {
                        url: R.prop(
                          FIELD_IDS.MEDIA_CENTER.LEFT_SIDEBAR_LOGO_IMAGE_URL,
                          data
                        )
                      }
                    ]
                  : null
              }
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Interview Schedule Title</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.INTERVIEW_SCHEDULE_TITLE}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.INTERVIEW_SCHEDULE_TITLE)(
                data
              )}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Interview Schedule Subtitle</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.INTERVIEW_SCHEDULE_SUBTITLE}
              width={1}
              iniValue={R.prop(
                FIELD_IDS.MEDIA_CENTER.INTERVIEW_SCHEDULE_SUBTITLE
              )(data)}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Show "Request an Interview" button?</Label>
            <Toggle
              size="medium"
              fieldId={FIELD_IDS.MEDIA_CENTER.ENABLE_REQUEST_INTERVIEW_BUTTON}
              iniValue={["true", true].includes(
                R.prop(
                  FIELD_IDS.MEDIA_CENTER.ENABLE_REQUEST_INTERVIEW_BUTTON,
                  data
                )
              )}
              bg="green6"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Interview / Replays URL</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.WATCH_INTERVIEW_REPLAYS_URL}
              width={1}
              iniValue={R.prop(
                FIELD_IDS.MEDIA_CENTER.WATCH_INTERVIEW_REPLAYS_URL
              )(data)}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>
        </Div>

        {/* stream 1 */}
        <Div bg="white" bra={1} p={5} display="column" mb={4}>
          <PanelTitle>Video Feed #1</PanelTitle>

          <FieldWrapper>
            <Label>Show Feed #1</Label>
            <Toggle
              size="medium"
              fieldId={FIELD_IDS.MEDIA_CENTER.ENABLE_SIDEBAR_VIDEO_1}
              iniValue={["true", true].includes(
                R.prop(FIELD_IDS.MEDIA_CENTER.ENABLE_SIDEBAR_VIDEO_1, data)
              )}
              bg="green6"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Video Feed #1 Label</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.SIDEBAR_VIDEO_1_LABEL}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.SIDEBAR_VIDEO_1_LABEL)(
                data
              )}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Video Feed #1 Title</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.SIDEBAR_VIDEO_1_TITLE}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.SIDEBAR_VIDEO_1_TITLE)(
                data
              )}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Video Feed #1 URL</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.SIDEBAR_VIDEO_1_URL}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.SIDEBAR_VIDEO_1_URL)(
                data
              )}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>
        </Div>

        {/* stream 2 */}
        <Div bg="white" bra={1} p={5} display="column" mb={4}>
          <PanelTitle>Video Feed #2</PanelTitle>

          <FieldWrapper>
            <Label>Show Feed #2</Label>
            <Toggle
              size="medium"
              fieldId={FIELD_IDS.MEDIA_CENTER.ENABLE_SIDEBAR_VIDEO_2}
              iniValue={["true", true].includes(
                R.prop(FIELD_IDS.MEDIA_CENTER.ENABLE_SIDEBAR_VIDEO_2, data)
              )}
              bg="green6"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Video Feed #2 Label</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.SIDEBAR_VIDEO_2_LABEL}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.SIDEBAR_VIDEO_2_LABEL)(
                data
              )}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Video Feed #2 Title</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.SIDEBAR_VIDEO_2_TITLE}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.SIDEBAR_VIDEO_2_TITLE)(
                data
              )}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Video Feed #2 URL</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.SIDEBAR_VIDEO_2_URL}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.SIDEBAR_VIDEO_2_URL)(
                data
              )}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>
        </Div>

        {/* stream 3 */}
        <Div bg="white" bra={1} p={5} display="column" mb={4}>
          <PanelTitle>Video Feed #3</PanelTitle>

          <FieldWrapper>
            <Label>Show Feed #3</Label>
            <Toggle
              size="medium"
              fieldId={FIELD_IDS.MEDIA_CENTER.ENABLE_SIDEBAR_VIDEO_3}
              iniValue={["true", true].includes(
                R.prop(FIELD_IDS.MEDIA_CENTER.ENABLE_SIDEBAR_VIDEO_3, data)
              )}
              bg="green6"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Video Feed #3 Label</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.SIDEBAR_VIDEO_3_LABEL}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.SIDEBAR_VIDEO_3_LABEL)(
                data
              )}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Video Feed #3 Title</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.SIDEBAR_VIDEO_3_TITLE}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.SIDEBAR_VIDEO_3_TITLE)(
                data
              )}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Video Feed #3 URL</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.SIDEBAR_VIDEO_3_URL}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.SIDEBAR_VIDEO_3_URL)(
                data
              )}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>
        </Div>

        {/* event details */}
        <Div bg="white" bra={1} p={5} display="column" mb={4}>
          <PanelTitle>Event Details</PanelTitle>
          <FieldWrapper>
            <Label>Event Logo</Label>
            <Image
              fieldId={FIELD_IDS.MEDIA_CENTER.EVENT_LOGO_URL}
              containerWidth="auto"
              containerProps={{
                display: "row.flex-start.flex-start",
                flex: 1
              }}
              imageWidth={80}
              imageHeight={80}
              recommendedWidth={"500"}
              recommendedHeight={"500"}
              iniValue={
                R.prop(FIELD_IDS.MEDIA_CENTER.EVENT_LOGO_URL, data)
                  ? [
                      {
                        url: R.prop(FIELD_IDS.MEDIA_CENTER.EVENT_LOGO_URL, data)
                      }
                    ]
                  : null
              }
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Event Title</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.EVENT_TITLE}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.EVENT_TITLE)(data)}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Event Subtitle</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.EVENT_SUBTITLE}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.EVENT_SUBTITLE)(data)}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Event Website URL</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.EVENT_WEBSITE_URL}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.EVENT_WEBSITE_URL)(data)}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>
        </Div>

        {/* community */}
        <Div bg="white" bra={1} p={5} display="column" mb={4}>
          <PanelTitle>Community</PanelTitle>

          <FieldWrapper>
            <Label tooltip="Shows # of people that have access to the same portal. On click, it opens the chat sidebar and shows the list of people.">
              Show Count of People
            </Label>
            <Toggle
              size="medium"
              fieldId={FIELD_IDS.MEDIA_CENTER.SHOW_COUNT_OF_PEOPLE}
              iniValue={["true", true].includes(
                R.prop(FIELD_IDS.MEDIA_CENTER.SHOW_COUNT_OF_PEOPLE, data)
              )}
              bg="green6"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label tooltip="Add polls to your event and allow people to vote.">
              Enable Polls
            </Label>
            <Toggle
              size="medium"
              fieldId={FIELD_IDS.MEDIA_CENTER.ENABLE_POLLS}
              iniValue={["true", true].includes(
                R.prop(FIELD_IDS.MEDIA_CENTER.ENABLE_POLLS, data)
              )}
              bg="green6"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label tooltip="Allow attendees to submit questions and provide real-time answers.">
              Enable Q&A
            </Label>
            <Toggle
              size="medium"
              fieldId={FIELD_IDS.MEDIA_CENTER.ENABLE_QA}
              iniValue={["true", true].includes(
                R.prop(FIELD_IDS.MEDIA_CENTER.ENABLE_QA, data)
              )}
              bg="green6"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Chat Tab Label</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.NETWORK_CHAT_LABEL}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.NETWORK_CHAT_LABEL)(data)}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>People Tab Label</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.NETWORK_PEOPLE_LABEL}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.NETWORK_PEOPLE_LABEL)(
                data
              )}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>
        </Div>

        {/* content section 1 */}
        <Panel>
          <PanelTitle>Content Page #1</PanelTitle>

          <FieldWrapper>
            <Label>Page #1 Label</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_1_LABEL}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_1_LABEL)(
                data
              )}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Page #1 Type</Label>
            <FormDropdown
              fieldId={FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_1_TYPE}
              width={"100%"}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_1_TYPE)(
                data
              )}
              options={CONTENT_SECTION_TYPES}
              size="big"
            />
          </FieldWrapper>

          {R.prop(FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_1_TYPE)(data) ===
          "link" ? (
            <FieldWrapper>
              <Label>Page #1 Link URL</Label>
              <Input
                fieldId={FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_1_LINK}
                width={1}
                iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_1_LINK)(
                  data
                )}
                size="big"
                inputType="outline"
              />
            </FieldWrapper>
          ) : null}

          {R.prop(FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_1_TYPE)(data) ===
          "content" ? (
            <FieldWrapper>
              <Label>Page #1 Content</Label>
              <Wysiwyg
                style={{
                  width: "100%",
                  resize: "none"
                }}
                fieldId={FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_1_CONTENT}
                width={1}
                mb={2}
                color="neutral9"
                bg="neutral0"
                bc={{ default: "neutral2", hover: "purple5" }}
                iniValue={R.prop(
                  FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_1_CONTENT
                )(data)}
                placeholder="Content..."
              />
            </FieldWrapper>
          ) : null}
        </Panel>

        {/* content section 2 */}
        <Panel>
          <PanelTitle>Content Page #2</PanelTitle>

          <FieldWrapper>
            <Label>Page #2 Label</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_2_LABEL}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_2_LABEL)(
                data
              )}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Page #2 Type</Label>
            <FormDropdown
              fieldId={FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_2_TYPE}
              width={"100%"}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_2_TYPE)(
                data
              )}
              options={CONTENT_SECTION_TYPES}
              size="big"
            />
          </FieldWrapper>

          {R.prop(FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_2_TYPE)(data) ===
          "link" ? (
            <FieldWrapper>
              <Label>Page #2 Link URL</Label>
              <Input
                fieldId={FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_2_LINK}
                width={1}
                iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_2_LINK)(
                  data
                )}
                size="big"
                inputType="outline"
              />
            </FieldWrapper>
          ) : null}

          {R.prop(FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_2_TYPE)(data) ===
          "content" ? (
            <FieldWrapper>
              <Label>Page #2 Content</Label>
              <Wysiwyg
                style={{
                  width: "100%",
                  resize: "none"
                }}
                fieldId={FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_2_CONTENT}
                width={1}
                mb={2}
                color="neutral9"
                bg="neutral0"
                bc={{ default: "neutral2", hover: "purple5" }}
                iniValue={R.prop(
                  FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_2_CONTENT
                )(data)}
                placeholder="Content..."
              />
            </FieldWrapper>
          ) : null}
        </Panel>

        {/* content section 3 */}
        <Panel>
          <PanelTitle>Content Page #3</PanelTitle>

          <FieldWrapper>
            <Label>Page #3 Label</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_3_LABEL}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_3_LABEL)(
                data
              )}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Page #3 Type</Label>
            <FormDropdown
              fieldId={FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_3_TYPE}
              width={"100%"}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_3_TYPE)(
                data
              )}
              options={CONTENT_SECTION_TYPES}
              size="big"
            />
          </FieldWrapper>

          {R.prop(FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_3_TYPE)(data) ===
          "link" ? (
            <FieldWrapper>
              <Label>Page #3 Link URL</Label>
              <Input
                fieldId={FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_3_LINK}
                width={1}
                iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_3_LINK)(
                  data
                )}
                size="big"
                inputType="outline"
              />
            </FieldWrapper>
          ) : null}

          {R.prop(FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_3_TYPE)(data) ===
          "content" ? (
            <FieldWrapper>
              <Label>Page #3 Content</Label>
              <Wysiwyg
                style={{
                  width: "100%",
                  resize: "none"
                }}
                fieldId={FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_3_CONTENT}
                width={1}
                mb={2}
                color="neutral9"
                bg="neutral0"
                bc={{ default: "neutral2", hover: "purple5" }}
                iniValue={R.prop(
                  FIELD_IDS.MEDIA_CENTER.CONTENT_SECTION_3_CONTENT
                )(data)}
                placeholder="Content..."
              />
            </FieldWrapper>
          ) : null}
        </Panel>

        {/* alerts */}
        <Panel>
          <PanelTitle>Alerts</PanelTitle>

          <FieldWrapper>
            <Label>Show Alerts Section</Label>
            <Toggle
              size="medium"
              fieldId={FIELD_IDS.MEDIA_CENTER.ENABLE_ALERTS_SECTION}
              iniValue={["true", true].includes(
                R.prop(FIELD_IDS.MEDIA_CENTER.ENABLE_ALERTS_SECTION, data)
              )}
              bg="green6"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Alerts Section Title</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.ALERTS_TITLE}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.ALERTS_TITLE)(data)}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Alerts Section Subtitle</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.ALERTS_SUBTITLE}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.ALERTS_SUBTITLE)(data)}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Alerts View All Label</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.ALERTS_VIEW_ALL_LABEL}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.ALERTS_VIEW_ALL_LABEL)(
                data
              )}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>
        </Panel>

        {/* videos */}
        <Panel>
          <PanelTitle>Videos</PanelTitle>

          <FieldWrapper>
            <Label>Show Videos Section</Label>
            <Toggle
              size="medium"
              fieldId={FIELD_IDS.MEDIA_CENTER.ENABLE_VIDEOS_SECTION}
              iniValue={["true", true].includes(
                R.prop(FIELD_IDS.MEDIA_CENTER.ENABLE_VIDEOS_SECTION, data)
              )}
              bg="green6"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Videos Section Title</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.VIDEOS_TITLE}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.VIDEOS_TITLE)(data)}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Videos Section Subtitle</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.VIDEOS_SUBTITLE}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.VIDEOS_SUBTITLE)(data)}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Videos View All Label</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.VIDEOS_VIEW_ALL_LABEL}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.VIDEOS_VIEW_ALL_LABEL)(
                data
              )}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Sort Videos By</Label>
            <FormDropdown
              fieldId={FIELD_IDS.MEDIA_CENTER.SORT_VIDEOS_BY}
              width={"100%"}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.SORT_VIDEOS_BY)(data)}
              options={VIDEO_SORT_METHODS}
              size="big"
            />
          </FieldWrapper>
        </Panel>

        {/* social feed */}
        <Panel>
          <PanelTitle>Social Feed</PanelTitle>

          <FieldWrapper>
            <Label>Show Social Feed</Label>
            <Toggle
              size="medium"
              fieldId={FIELD_IDS.MEDIA_CENTER.ENABLE_SOCIAL_SECTION}
              iniValue={["true", true].includes(
                R.prop(FIELD_IDS.MEDIA_CENTER.ENABLE_SOCIAL_SECTION, data)
              )}
              bg="green6"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Social Feed Title</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.SOCIAL_TITLE}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.SOCIAL_TITLE)(data)}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Social Feed Subtitle</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.SOCIAL_SUBTITLE}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.SOCIAL_SUBTITLE)(data)}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label tooltip="Enter the ID of your Curator.io feed (e.g. 418c3d19-f47e-4895-b223-7a370a742c65)">
              Curator.io Feed ID
            </Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.SOCIAL_FEED_ID}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.SOCIAL_FEED_ID)(data)}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label tooltip="Enter the ID that Curator.io uses for embedding your social feed (Default: 'curator-feed-default-feed-layout')">
              Curator.io Feed Container ID
            </Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.SOCIAL_FEED_CONTAINER_ID}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.SOCIAL_FEED_CONTAINER_ID)(
                data
              )}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>
        </Panel>

        {/* right sidebar */}
        <Panel>
          <PanelTitle>Right Sidebar</PanelTitle>
          <FieldWrapper>
            <Label>Sidebar Logo</Label>
            <Image
              fieldId={FIELD_IDS.MEDIA_CENTER.RIGHT_SIDEBAR_LOGO_URL}
              containerWidth="auto"
              containerProps={{
                display: "row.flex-start.flex-start",
                flex: 1
              }}
              imageWidth={80}
              imageHeight={80}
              recommendedWidth={"500"}
              recommendedHeight={"500"}
              iniValue={
                R.prop(FIELD_IDS.MEDIA_CENTER.RIGHT_SIDEBAR_LOGO_URL, data)
                  ? [
                      {
                        url: R.prop(
                          FIELD_IDS.MEDIA_CENTER.RIGHT_SIDEBAR_LOGO_URL,
                          data
                        )
                      }
                    ]
                  : null
              }
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Mobile Title Line 1</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.MOBILE_TITLE_LINE_1}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.MOBILE_TITLE_LINE_1)(
                data
              )}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Mobile Title Line 2</Label>
            <Input
              fieldId={FIELD_IDS.MEDIA_CENTER.MOBILE_TITLE_LINE_2}
              width={1}
              iniValue={R.prop(FIELD_IDS.MEDIA_CENTER.MOBILE_TITLE_LINE_2)(
                data
              )}
              size="big"
              inputType="outline"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Use Material Icon Library</Label>
            <Toggle
              size="medium"
              fieldId={FIELD_IDS.MEDIA_CENTER.USE_MATERIAL_ICONS}
              iniValue={["true", true].includes(
                R.prop(FIELD_IDS.MEDIA_CENTER.USE_MATERIAL_ICONS, data)
              )}
              bg="green6"
            />
          </FieldWrapper>

          <FieldWrapper>
            <Label>Sidebar Links</Label>
            <RightSidebarLinks
              draggable
              iconSet="custom"
              cards={R.propOr(
                [],
                FIELD_IDS.MEDIA_CENTER.RIGHT_SIDEBAR_LINKS,
                data
              )}
              onReorder={onReorderRightSidebarLinks}
              onUpdateValue={onUpdateRightSidebarValue}
            />
          </FieldWrapper>
        </Panel>
      </>
    )}
  </Div>
);

export default R.compose(
  decorate,
  CSSModules(css)
)(Content);
