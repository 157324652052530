import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getters, actions } from "../model";
import PageTitle from "EventLight/Common/PageTitle";

import Loading from "ui-kit/Loading";
import { Div } from "components/Base";

import InPersonDashboard from "./InPersonDashboard/Main";

const decorate = connect(
  state => ({
    loading: getters.loading(state)
  }),
  {
    init: actions.init
  }
);

const View = ({ init, loading, ...props }) => {
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <PageTitle titles={["Dashboard"]} />
      <Div
        width={1}
        bg="white"
        style={{
          padding: "20px 20px"
        }}
      >
        {loading ? <Loading /> : <InPersonDashboard {...props} />}
      </Div>
    </>
  );
};

export default decorate(View);
