import React from "react";

import * as R from "ramda";

import {
  Div,
  collapsableHandler,
  Collapsable,
  Text0,
  Text1,
  Text2,
  EigthSizeDotIcon,
  DownFilledIcon,
  DragIcon,
  MediumFilledButton,
  EditIcon,
  NotVisibleIcon,
  StarIcon
} from "components/Base";

import { noop, addS, capitalizeFirst } from "utils/General";

import Tooltip from "components/Global/Tooltip";

const SetCollapsable = collapsableHandler(
  ({
    toggleCollapsed,
    collapsed,
    name,
    questions: setQuestions,
    showEditQuestionSetQuestionsModal,
    id
  }) => (
    <Div shadow={1} bg="white" bra={1} p={2} ba={1} bc="neutral2" mt={2}>
      <Div width={1} display="row.flex-start.center">
        <DragIcon color="neutral3" size={24} />
        <DownFilledIcon
          mr={1}
          color="black"
          size={24}
          style={{ transform: `rotate(${collapsed ? "-90" : "0"}deg)` }}
          onClick={toggleCollapsed}
        />
        <Div width={1} display="row.flex-start.center">
          <Div display="row.space-between.center" flex={1}>
            <Div onClick={toggleCollapsed} flex={1}>
              <Text2 bold color="black">
                {name || "-"}
              </Text2>
              <Div display="row.flex-start.center">
                <Text0 color="neutral6" bold>
                  {`${setQuestions.length} question${addS(
                    setQuestions.length
                  )}`}
                </Text0>
              </Div>
            </Div>
            <MediumFilledButton
              bg="neutral0"
              color="primary7"
              onClick={() => showEditQuestionSetQuestionsModal(id)}
            >
              Manage Question Set
            </MediumFilledButton>
          </Div>
        </Div>
      </Div>
      <Collapsable collapsed={collapsed}>
        <Div>
          {R.map(
            setQuestion => (
              <Div
                width={1}
                bra={1}
                px={2}
                py={1}
                bc="neutral2"
                ba={1}
                bg="white"
                display="row.flex-start.center"
                mt={2}
              >
                <Div
                  display="row.space-between.center"
                  flex={1}
                  pl={2}
                  className="hoverContainer"
                >
                  <Div>
                    <Div display="row.flex-start">
                      <Text2 bold color="black">
                        {setQuestion.name}
                      </Text2>
                      {setQuestion.required && (
                        <Text2 bold color="danger8" ml={1}>
                          *
                        </Text2>
                      )}
                    </Div>
                    <Div display="row.flex-start.center">
                      {/* <Text0 color="neutral6" bold>
                        2 options
                      </Text0>
                      <EigthSizeDotIcon color="neutral6" /> */}
                      <Text0 color="neutral6" bold>
                        {capitalizeFirst(setQuestion.type)}
                      </Text0>
                    </Div>
                  </Div>
                  <Div style={{ position: "relative" }} height={1}>
                    {/* <Text1 bold color="neutral4">
                      Contains $Price Modifiers
                    </Text1> */}
                    <Div
                      display="row.flex-end.center"
                      style={{ position: "absolute", top: 0 }}
                      pr={2}
                      height={1}
                      bg="white"
                      width={1}
                      className="showOnHover"
                    >
                      {/* <Div
                        style={{ position: "absolute", right: 0 }}
                        display="row.flex-start.center"
                      >
                        <EditIcon
                          color="black"
                          ml={2}
                          size={20}
                          onClick={noop}
                        />
                      </Div> */}
                    </Div>
                  </Div>
                </Div>
              </Div>
            ),
            setQuestions
          )}
        </Div>
      </Collapsable>
    </Div>
  )
);

export default SetCollapsable;
