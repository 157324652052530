import React, { Component } from "react";
import * as R from "ramda";
import View from "./View";

class Controller extends Component {
  render() {
    return <View {...{}} />;
  }
}

export default Controller;
