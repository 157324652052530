import * as R from "ramda";
import { get } from "lodash";

export const isFetching = state => state.formsV2.submission.fetching;
export const isSaving = state => state.formsV2.submission.saving;
export const submission = state => state.formsV2.submission.submission;
export const form = state =>
  get(state.formsV2.submission, "submission.form", {});
export const eventDetails = R.pathOr({}, [
  "formsV2",
  "submission",
  "submission",
  "event"
]);
export const eventId = R.path([
  "formsV2",
  "submission",
  "submission",
  "event",
  "id"
]);
export const preferences = state =>
  get(state.formsV2.submission, "submission.preferences", {});
export const collaborators = state =>
  get(state.formsV2.submission, "submission.collaborators", []);
export const comments = state =>
  get(state.formsV2.submission, "submission.comments", []);
export const error = state => state.formsV2.submission.error;
export const sortedFormFields = state =>
  get(state.formsV2.submission, "submission.form.fields")
    ? state.formsV2.submission.submission.form.fields.sort(
        (a, b) => a.order - b.order
      )
    : [];
export const subformSubmission = (state, subformId, submissionId) =>
  state.formsV2.submission.submission.values[subformId].value.submissions[
    submissionId
  ];
