import * as R from "ramda";
import { get } from "lodash";
import numeral from "numeral";

const CURRENCIES = {
  USD: "USD",
  EUR: "EUR",
  GBP: "GBP",
  CAD: "CAD",
  AUD: "AUD"
};

export const isFetching = state =>
  state.event.event.fetching || !state.event.event.event.id;

export const eventDetails = state => state.event.event.event;

export const isEventError = state => state.event.event.eventError;

export const eventId = state => get(state.event, "event.event.id");
export const eventUUID = R.pathOr("", ["event", "event", "event", "uuid"]);
export const eventSlug = R.pathOr("", ["event", "event", "event", "slug"]);
export const getEventTimezone = R.path(["event", "event", "event", "timezone"]);
export const getEventCurrency = R.path(["event", "event", "event", "currency"]);
export const credentials = state =>
  get(eventDetails(state), "module_settings.credentials.credentials", []);
export const getEventScope = R.path(["event", "event", "event", "scope"]);
export const getEventTemplate = R.path(["event", "event", "event", "template"]);
export const getIsLightEvent = R.pathOr(false, [
  "event",
  "event",
  "event",
  "is_light"
]);

export const isConferenceTemplate = state =>
  getEventTemplate(state) === "conference";
export const isHybridEvent = state => getEventScope(state) === "hybrid";
export const isLiveEvent = state => getEventScope(state) === "live";
export const isLiveOrHybridEvent = state =>
  isHybridEvent(state) || isLiveEvent(state);

export const isEventFeatureEnabled = state => feature => {
  return R.pathOr(
    [],
    ["event", "event", "event", "enabled_features"]
  )(state).includes(feature);
};

export const formatAmount = (amount, currency) => {
  let formattedAmount = amount;

  switch (currency) {
    case CURRENCIES.EUR:
      formattedAmount =
        "€ " +
        Number(amount).toLocaleString("es-ES", { minimumFractionDigits: 2 });
      break;
    case CURRENCIES.GBP:
      formattedAmount = "£" + numeral(amount).format("0,0[.]00");
      break;
    case CURRENCIES.AUD:
      formattedAmount = "A$" + numeral(amount).format("0,0[.]00");
      break;
    case CURRENCIES.CAD:
      formattedAmount = "C$" + numeral(amount).format("0,0[.]00");
      break;
    case CURRENCIES.USD:
    default:
      formattedAmount = "$" + numeral(amount).format("0,0[.]00");
      break;
  }

  return formattedAmount;
};

export const formatAmountForEvent = state => amount => {
  const currency = R.path(["event", "event", "event", "currency"])(state);
  return formatAmount(amount, currency);
};

export const getCurrencySignForEvent = state => {
  const currency = R.path(["event", "event", "event", "currency"])(state);

  switch (currency) {
    case CURRENCIES.EUR:
      return "€";
    case CURRENCIES.GBP:
      return "£";
    case CURRENCIES.AUD:
      return "A$";
    case CURRENCIES.CAD:
      return "C$";
    case CURRENCIES.USD:
    default:
      return "$";
  }
};

export const accountTypes = state =>
  get(eventDetails(state), "module_settings.accounts.types", []).sort(
    (a, b) => b.name.toLowerCase() - a.name.toLowerCase()
  );
export const eventHomeAccountRecordTypes = state =>
  get(state.event, "event.eventHomeAccountRecordTypes");

export const getBackgroundUrl = R.compose(
  url => `url(${url})`,
  R.pathOr("", ["event", "event", "event", "background_image_url"])
);
