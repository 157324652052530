import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { collapsableHandler, Collapsable } from "components/Base";
import { withProps } from "utils/General";

@CSSModules(css)
class MoreInfo extends Component {
  render() {
    if (!this.props.children.length) {
      return <div />;
    }
    const { title, expandedTitle } = this.props;
    return (
      <div>
        <Collapsable collapsed={this.props.collapsed}>
          {this.props.children}
        </Collapsable>
        {!this.props.collapsed ? (
          <div styleName="moreInfo" onClick={this.props.toggleCollapsed}>
            <i className="material-icons expanded">arrow_drop_down</i>
            {expandedTitle}
          </div>
        ) : (
          <div styleName="moreInfo" onClick={this.props.toggleCollapsed}>
            <i className="material-icons collapsed">arrow_drop_down</i>
            {title}
          </div>
        )}
      </div>
    );
  }
}

MoreInfo.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  expandedTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default withProps({ collapsedDefault: true })(
  collapsableHandler(MoreInfo)
);
