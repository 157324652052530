import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const NewMessageTag = ({ count = 0 }) => {
  if (count > 0) {
    return <span styleName="count">{count}</span>;
  }
  return "";
};

NewMessageTag.propTypes = {
  count: PropTypes.number
};

export default CSSModules(NewMessageTag, css);
