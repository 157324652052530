import React from "react";
import Body from "components/Event/InventoryV2/Layout/Body";
import Content from "components/Event/InventoryV2/Layout/Content";
import { locationAreas } from "@lennd/value-types/src/constants/standard-modules";
import Table from "./ModuleTable";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";
import LocationSidebar from "./Sidebar";
import Sidebar from "components/Global/Module/Sidebar";

const readOnlyFields = resolveReadOnlyFields({ moduleId: locationAreas.id });

const View = ({
  params,
  locations,
  areas,
  areaFields,
  showAddLocationModal,
  showEditLocationModal,
  getAllLocationsPath,
  isViewingAllLocations
}) => (
  <Sidebar
    moduleId="location"
    sidebar={
      <LocationSidebar
        locations={locations}
        showAddLocationModal={showAddLocationModal}
        showEditLocationModal={showEditLocationModal}
        getAllLocationsPath={getAllLocationsPath}
        isViewingAllLocations={isViewingAllLocations}
      />
    }
  >
    <Content>
      <Body type="tall">
        <Table
          fields={areaFields.filter(f => !readOnlyFields.includes(f.id))}
          context={{
            ...params,
            moduleId: locationAreas.id,
            view: "list"
          }}
          records={areas}
        />
      </Body>
    </Content>
  </Sidebar>
);

export default View;
