import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "../model";

import { TABLE_INSTANCE_ID } from "../constants";

import { Div } from "components/Base";
import EmptyState from "EventLight/Common/EmptyState";

import Table, { TableWrapper } from "ui-kit/Table/View";

const decorate = connect(
  (state, props) => ({
    loading: getters.loading(state, props),
    showNoResults: getters.showNoResults(state, props)
  }),
  {
    openAddAlertModal: actions.openAddAlertModal
  }
);

const Body = ({
  loading = false,
  showNoResults = false,
  openAddAlertModal
}) => (
  <Div display="column" width={1}>
    {showNoResults ? (
      <EmptyState
        icon="mail"
        title="You haven't sent any alerts yet"
        description="Send alerts regarding your event to your attendees via email, SMS and your virtual venue."
        action="Send Alert"
        onClick={openAddAlertModal}
      />
    ) : (
      <TableWrapper
        loading={loading}
        style={{ flex: 1 }}
        instanceId={TABLE_INSTANCE_ID}
      >
        <Table
          instanceId={TABLE_INSTANCE_ID}
          showCheckbox={false}
          showOpenRecord
        />
      </TableWrapper>
    )}
  </Div>
);

export default decorate(Body);
