import React, { Component } from "react";
import InternalHandler from "./Internal";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";

class ExternalHandler extends Component {
  getItemGroups = () => this.props.getZoneGroups(this.props.eventDetails.id);

  getPassTypes = () =>
    this.props.getItemGroupsByEventAndType(
      this.props.eventDetails.id,
      CREDENTIAL_TYPE_ID
    );

  fetchItem = () => this.props.getZone(this.props.itemId);

  saveItem = item => {
    this.props.onDone(item);
    this.props.hideModal();
  };

  render() {
    return <InternalHandler {...this} {...this.props} />;
  }
}

export default ExternalHandler;
