import { bindActionCreators } from "redux";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import ViewOrderModal from "Orders/OrderModal/View";
import { debounce } from "lodash";
import React, { Component } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import View from "./View";
import { ACCOUNTS, CONTACTS } from "utils/standard-module-field-ids";
import moment from "moment";
import { revertCheckin } from "redux/modules/passesCheckin/actions";

import { showSnackbar } from "redux/modules/snackbar/actions";
import {
  currentPage,
  totalResults,
  searchResults,
  loading
} from "redux/modules/items/item-variant-inventory/selectors";
import {
  search,
  clearSearch
} from "redux/modules/items/item-variant-inventory/actions";

function mapStateToProps(state) {
  return {
    loading: loading(state),
    results: searchResults(state),
    currentPage: currentPage(state),
    totalResults: totalResults(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      clearSearch,
      search,
      showSnackbar,
      revertCheckin
    },
    dispatch
  );
}

class FindItemInventoryModal extends Component {
  componentWillUnmount() {
    this.props.clearSearch();
  }
  handleSearch = (uid, page) => {
    this.searchTerm = uid;
    return this.props.search(uid, page);
  };
  debouncedSearch = debounce(this.handleSearch, 250);
  gotoPage = (page, searchTerm) => {
    return this.props.search(searchTerm, page);
  };

  showOrderModal = orderId => {
    this.props.showModal({
      content: <ViewOrderModal orderId={orderId} />,
      wrapper: ModalWrapper
    });
  };

  undoIssuance = async (variantId, recordId, issuanceId) => {
    await this.props.revertCheckin({
      variantId,
      recordId,
      options: { issuanceIds: [issuanceId] }
    });
    this.props.showSnackbar({ message: "Issuance Removed" });
    return this.handleSearch(this.searchTerm, this.props.currentPage);
  };

  formatResult = r => {
    let props = {
      title: "Available",
      passColor: r.variant.item.background_color,
      passName: r.variant.display_name,
      codes: [r.uid],
      actions: []
    };
    if (r.orderLineItemIssuance.length) {
      const lineItem = r.orderLineItemIssuance[0].orderLineItem;
      const order = lineItem.order;
      const group = R.pathOr({}, ["order", "customer_account"], lineItem);
      const contact = R.pathOr({}, ["order", "customer_contact"], lineItem);
      const groupValues = group.values
        ? group.values.reduce((agg, val) => {
            agg[val.field_id] = val.value.value;
            return agg;
          }, {})
        : {};
      const contactValues = R.pathOr([], ["values"], contact).reduce(
        (agg, val) => {
          agg[val.field_id] = val.value.value;
          return agg;
        },
        {}
      );

      props = {
        ...props,
        title: !R.isEmpty(contactValues)
          ? `${contactValues[CONTACTS.FIRST_NAME] || ""} ${contactValues[
              CONTACTS.LAST_NAME
            ] || ""}`.trim()
          : groupValues[ACCOUNTS.NAME],
        onOrderClick: () => this.showOrderModal(order.id),
        groupName: groupValues[ACCOUNTS.NAME],
        orderNumber: `#${order.number}`,
        issuance: moment(r.orderLineItemIssuance[0].created_at).format(
          "h:mma M/D/YY"
        ),
        actions: [
          [
            "Detach & Return",
            () =>
              this.undoIssuance(
                r.variant_id,
                contact.id || group.id,
                r.orderLineItemIssuance[0].id
              )
          ],
          ["Open Order", () => this.showOrderModal(order.id)]
        ]
      };
    }
    return props;
  };

  render() {
    const {
      currentPage,
      results,
      loading,
      totalResults,
      clearSearch
    } = this.props;
    return (
      <View
        {...{
          loading,
          page: currentPage,
          totalResults,
          gotoPage: this.gotoPage,
          clearSearchResults: clearSearch,
          onSearch: this.debouncedSearch,
          results: results.map(this.formatResult)
        }}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FindItemInventoryModal);
