import React from "react";
import moment from "moment";

import { EMAIL_FIELDS } from "Sponsors/SelfSetup/constants";
import { Text3, Text2, BigOutlineButton, Div } from "components/Base";
import { PackageContainer, EmailPanel, Panel } from "Sponsors/Common/View";

import {
  Column,
  Row,
  Label,
  DateInput,
  Field,
  TextAreaInput
} from "ui-kit/Form/View";
import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";

const decorate = WithThemeConsumer({ variant: VARIANTS.SURFACE });

const EmailSettings = ({ Th = defaultTh }) => (
  <PackageContainer title="Invoice & Email Settings" caption="Preview Invoice">
    <Column
      {...Th(["bg"], {
        p: 3,
        bra: 1,
        width: 1,
        display: "column.flex-start.stretch"
      })}
    >
      <Label mb={2}>Payment Related Emails</Label>
      <Panel accentBorder="danger5" variant={Th.VARIANTS.BACKGROUND}>
        <Row p={3}>
          <BigOutlineButton>Set "Bill to" Company </BigOutlineButton>
          <Text2 ml={3}>Required - Not set yet</Text2>
        </Row>
      </Panel>
      <Column mt={3}>
        <Label>"Balance Due" Invoice Date</Label>
        <Row mt={1}>
          <DateInput fieldId={"date"} />
          <Field fieldId={"date"} render={Text3} ml={2}>
            {field => (
              <Text2 ml={3}>
                {moment(field.value, field.format).format("MMM DD, YYYY")}{" "}
                {moment(field.value, field.format).fromNow()}
              </Text2>
            )}
          </Field>
        </Row>
      </Column>
      <Column mt={3}>
        <Label>Invoice Terms & Notes</Label>
        <TextAreaInput
          mt={1}
          width={1}
          fieldId="invoiceTerms"
          variant={Th.VARIANTS.BACKGROUND}
        />
      </Column>
      <EmailPanel
        mt={3}
        title="Payment Request / Invoice Email"
        fieldId={EMAIL_FIELDS.CONFIRMATION}
        accentBorder="neutral3"
        variant={Th.VARIANTS.BACKGROUND}
      />
      <EmailPanel
        title="Payment Processed Email (Receipt)"
        fieldId={EMAIL_FIELDS.APPROVED}
        mt={3}
        accentBorder="neutral3"
        variant={Th.VARIANTS.BACKGROUND}
      />
      <EmailPanel
        title="Payment Method Failed (Declined Card)"
        fieldId={EMAIL_FIELDS.DENIED}
        mt={3}
        accentBorder="neutral3"
        variant={Th.VARIANTS.BACKGROUND}
      />
      <EmailPanel
        title="Payment Overdue Email"
        fieldId={EMAIL_FIELDS.PAYMENT}
        mt={3}
        accentBorder="neutral3"
        variant={Th.VARIANTS.BACKGROUND}
      />
    </Column>
  </PackageContainer>
);

export default decorate(EmailSettings);
