export default record => {
  if (record) {
    let result = {
      ...record,
      permissionList: record.permissions.map(p => p.slug)
    };
    if (record.read_permissions) {
      result.permissionList = [
        ...result.permissionList,
        ...record.read_permissions.map(p => p.slug)
      ];
    }
    if (record.add_line_item_permissions) {
      result.permissionList = [
        ...result.permissionList,
        ...record.add_line_item_permissions.map(p => p.slug)
      ];
    }
    return result;
  }
  return {
    permissionList: []
  };
};
