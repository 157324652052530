import PropTypes from "prop-types";
import React, { Component, cloneElement } from "react";
import constructSearchObject from "utils/Search/construct-searchable-object";
import formatFields from "./utils/format-fields";
import formatRows from "./utils/format-rows";

class ExportContainer extends Component {
  exportContent = async (contentType, moduleName, records, fields) => {
    const {
      showSnackbar,
      details,
      references,
      exportSpreadsheetArray
    } = this.props;
    const visibleFields = formatFields(fields).filter(f => f.visible);

    showSnackbar({ message: "Exporting...", action: "OK" });

    const sheet = formatRows(records, fields, {
      eventDetails: details
    }).reduce(
      (sheet, row) => {
        const valueObject = constructSearchObject(
          visibleFields,
          row.values,
          row.id,
          details,
          {
            references
          }
        );

        // constructSearchObject converts each value to a string,
        // we need to convert back into a number to handle type correctly for xlsx
        visibleFields.filter(f => f.type === "number").forEach(({ id }) => {
          if (valueObject[id]) {
            valueObject[id] = Number(valueObject[id]);
          }
        });

        sheet.push(visibleFields.map(col => valueObject[col.id]));
        return sheet;
      },
      [visibleFields.map(col => col.name)]
    );

    try {
      const { url } = await exportSpreadsheetArray({
        contentType,
        source: {
          type: module.record_name_plural
        },
        sheet
      });
      if (!url) throw new Error();
      /* eslint-disable no-underscore-dangle */
      window.location = url;
      /* eslint-enable no-underscore-dangle */
    } catch (e) {
      showSnackbar({ message: "Oops, there was an error.", action: "OK" });
    }
  };

  render() {
    return cloneElement(this.props.children, {
      exportSheet: this.exportContent
    });
  }
}

ExportContainer.defaultProps = {
  selectedRows: []
};
ExportContainer.propTypes = {
  showSnackbar: PropTypes.func.isRequired,
  exportSpreadsheetArray: PropTypes.func.isRequired,
  references: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

export default Child => props => (
  <ExportContainer {...props}>
    <Child {...props} />
  </ExportContainer>
);
