import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE } from "./constants";

const iniState = {
  moduleId: null,
  templates: [],
  loading: false
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    init: R.identity,
    createTemplate: R.identity,
    copyTemplate: R.identity,
    refetchTemplates: R.identity,
    deleteTemplate: R.identity,
    goToReports: R.identity,
    setInitialData: (state, { payload: { moduleId, templates } }) => ({
      moduleId,
      templates
    })
  }
});

export default handlers;
