import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "Orders/CreateOrderModal/SelectItems/model";
import { Div, BigClearInput, SearchIcon } from "components/Base";

const decorate = connect(
  state => ({
    filterItem: getters.filterItem(state)
  }),
  {
    setFilterItem: actions.setFilterItem
  }
);

const ItemsSearchBar = ({ filterItem, setFilterItem }) => (
  <Div mb={4} bc="neutral2" bb={1} pb={2}>
    <BigClearInput
      LeftIcon={SearchIcon}
      placeholder="Search items..."
      width={1}
      continuous
      value={filterItem}
      onChange={setFilterItem}
    />
  </Div>
);

export default decorate(ItemsSearchBar);
