import React from "react";
import { createSelector } from "reselect";
import { connect } from "react-redux";
import * as R from "ramda";

import { getters } from "Items/Manage";
import { SOURCE_FILTER_ID, REQUESTER_FILTER_ID } from "Passes/Common/constants";
import { getSourceFilters, getUserFilters } from "Passes/Common/selectors";

import { MultipleItemFilter } from "ui-kit/FilterControls/View";
import { Text2 } from "components/Base";

const getFilters = createSelector(
  getSourceFilters,
  getters.selectedSources,
  getUserFilters,
  getters.selectedRequester,
  (sources, selectedSources, requesters, selectedRequester) => [
    {
      instanceId: SOURCE_FILTER_ID,
      itemGroups: sources,
      modalTitle: "Select Sources to show",
      selectedText: "Sources",
      iniSelected: selectedSources,
      selectedCount: R.length(selectedSources)
    },
    {
      instanceId: REQUESTER_FILTER_ID,
      itemGroups: requesters,
      modalTitle: "Select Submitter to show",
      selectedText: "Submitters",
      iniSelected: selectedRequester,
      selectedCount: R.length(selectedRequester)
    }
  ]
);

const decorate = connect((state, props) => ({
  filters: getFilters(state, props)
}));

const SourcesAndSubmitters = ({ filters = [], ...styleProps }) => (
  <MultipleItemFilter filters={filters} collapsedDefault {...styleProps}>
    <Text2 bold>Source & Submitter</Text2>
  </MultipleItemFilter>
);

export default decorate(SourcesAndSubmitters);
