import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const ActionButton = ({ title, ...props }) => (
  <div styleName="container" {...props}>
    {title}
  </div>
);

ActionButton.propTypes = {
  title: PropTypes.string.isRequired
};

export default CSSModules(ActionButton, css);
