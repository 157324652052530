import PropTypes from "prop-types";
import React, { Component } from "react";
import PrimaryButton from "components/Global-2016/PrimaryButton";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

const defaultState = {
  numberOfCopies: 1,
  processing: false,
  button: "Add Requests",
  error: null
};

@CSSModules(css)
class MakeCopies extends Component {
  constructor(props) {
    super(props);
    this.state = defaultState;
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  onCopy = async () => {
    const quantity = parseInt(this.state.numberOfCopies, 10);

    // verify we have a valid number
    if (!quantity) {
      this.setState({ error: "Please enter a valid number." });
      return;
    }

    // verify we have a number <= 100
    if (quantity > 100) {
      this.setState({ error: "Oops. Max 100 copies at a time." });
      return;
    }

    if (this.state.processing) {
      return;
    }
    this.setState({
      processing: true,
      error: null,
      button: (
        <span>
          <i styleName="spinIcon" className="material-icons">
            cached
          </i>{" "}
          Adding...
        </span>
      )
    });
    await this.props.duplicate(this.state.numberOfCopies);

    this.setState({
      button: (
        <span>
          <i styleName="icon" className="material-icons">
            check
          </i>{" "}
          {`${this.state.numberOfCopies} ${
            this.state.numberOfCopies > 1 ? "copies" : "copy"
          } added!`}
        </span>
      )
    });

    this.timeout = setTimeout(() => {
      // close popover after delay
      this.props.onClose();
    }, 1000);
  };

  onCopiesChange = e => {
    if (e.target.value !== "" && isNaN(parseInt(e.target.value, 10))) {
      return;
    }
    this.setState({
      numberOfCopies: e.target.value
    });
  };

  render() {
    const buttonStyle = this.state.processing
      ? { backgroundColor: "#424242" }
      : {};
    return (
      <div styleName="container">
        <label className={css.label}>
          How many?
          <input
            className={[css.input, "styled"].join(" ")}
            defaultValue={this.state.numberOfCopies}
            onChange={this.onCopiesChange}
            autoFocus="true"
            min="0"
            max="100"
            type="number"
          />
          {this.state.error ? (
            <div styleName="error">{this.state.error}</div>
          ) : (
            ""
          )}
          <hr className={css.divider} />
          <PrimaryButton style={buttonStyle} onClick={this.onCopy}>
            {this.state.button}
          </PrimaryButton>
        </label>
      </div>
    );
  }
}

MakeCopies.propTypes = {
  duplicate: PropTypes.func.isRequired
};

export default MakeCopies;
