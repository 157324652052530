import React from "react";
import { withRouter } from "react-router";
import * as R from "ramda";
import { createContext } from "redux-mvc";

import module, { actions } from "Toolbar/QuickActionsNavigation";
import { actions as EventHomeActions } from "Event/Home";
import rootSaga from "Toolbar/QuickActionsNavigation/sagas";

import Layout from "./Layout";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import AddFileModal from "EventLight/FileRequests/AddFileModal/View";
import AddEventTeamUserModal from "components/Global/Modals/AddEventTeamMemberModal";
import ImportOrdersModal from "Orders/ImportModal/View";
import CreateOrderModal from "Orders/CreateOrderModal";
import FormWizardModal from "Forms/WizardModal/View";
import SubmitFormModal from "Modules/SubmitFormModal/View";
import CreateScheduleModal from "Schedules/Create/View";

import { connect } from "react-redux";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import { addRecord } from "redux/modules/modules/records/actions";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import {
  CREDENTIAL_TYPE_ID,
  MEAL_TYPE_ID,
  ASSETS_TYPE_ID,
  BOOTH_TYPE_ID
} from "utils/item-types";

module.setRootSaga(rootSaga);

const decorate = createContext({
  module: module,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(actions.init({}));
    }
  },
  handlers: {
    showCreateScheduleModal: function() {
      this.props.showModal({
        content: <CreateScheduleModal hideModal={this.props.hideModal} />,
        wrapper: ModalWrapper
      });
    },
    showSubmitFormModal: function() {
      this.props.showModal({
        content: <SubmitFormModal hideModal={this.props.hideModal} />,
        wrapper: ModalWrapper
      });
    },
    showAddRecordModal: function({ moduleId }) {
      this.props.showModal({
        content: (
          <AddRecordModal
            moduleId={moduleId}
            onSave={() =>
              this.store.dispatch(EventHomeActions.getEventRecordTypes())
            }
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showAddAccountModal: function(groupId, groupName) {
      this.props.showModal({
        content: (
          <AddRecordModal
            moduleId={STANDARD_MODULE_IDS.accounts.id}
            onSave={() =>
              this.store.dispatch(EventHomeActions.getEventRecordTypes())
            }
            onlyRecordTypeId={groupId}
            onlyRecordTypeName={groupName}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showAddContactModal: function(groupId, groupName) {
      this.props.showModal({
        content: (
          <AddRecordModal
            moduleId={STANDARD_MODULE_IDS.contacts.id}
            onSave={() =>
              this.store.dispatch(EventHomeActions.getEventRecordTypes())
            }
            onlyRecordTypeId={groupId}
            onlyRecordTypeName={groupName}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showCreateOrderModal: function() {
      this.props.showModal({
        content: (
          <CreateOrderModal
            onDone={() => {
              this.props.showSnackbar({ message: "Order created" });
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showAddFormModal: function() {
      this.props.showModal({
        content: <FormWizardModal />,
        wrapper: ModalWrapper
      });
    },
    showAddEventTeamUserModal: function() {
      const modal = (
        <AddEventTeamUserModal
          hideModal={this.props.hideModal}
          addToEventId={this.props.params.eventId}
          onAdded={() => {
            this.props.showSnackbar({ message: "User invited" });
          }}
        />
      );

      this.props.showModal({ content: modal });
    },
    viewMyApprovals: function(moduleId) {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/${
          moduleId
            ? `${R.prop(moduleId)({
                [STANDARD_MODULE_IDS.credentials.id]: "passes/manage",
                [STANDARD_MODULE_IDS.catering.id]: "catering/manage",
                [STANDARD_MODULE_IDS.inventory.id]: "inventory/manage",
                [STANDARD_MODULE_IDS.booths.id]: "booths/manage"
              })}/my-approvals`
            : "my-approvals"
        }`
      });
    },
    viewMyTasks: function() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/my-tasks`
      });
    },
    viewModuleRecords: function({ moduleId }) {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/module/${moduleId}/dashboard`
      });
    },
    viewMasterSchedule: function() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/schedule/master`
      });
    },
    viewScheduleFields: function() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/settings/schedules/fields`
      });
    },
    viewSchedule: function({ id }) {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/schedule/${id}`
      });
    },
    viewRecordTypeRecords: function({ moduleId, typeId }) {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/module/${moduleId}/dashboard/type/${typeId}`
      });
    },
    viewManageFields: function({ moduleId }) {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/settings/module/${moduleId}`
      });
    },
    viewManageApprovers: function({ moduleId }) {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/settings/module/${moduleId}/approval-workflows`
      });
    },
    viewPortals: function() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/portals`
      });
    },
    viewEventSettings: function() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/settings`
      });
    },
    viewPaymentSettings: function() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/settings/orders/payments`
      });
    },
    viewEmailTemplates: function() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/settings/email-templates`
      });
    },
    viewUsers: function() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/settings/users`
      });
    },
    viewPermissions: function() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/settings/permissions`
      });
    },
    viewPortal: function() {
      window.open(
        `/portals/${this.props.eventDetails.slug}/${this.props.eventDetails.uuid}`,
        "_blank"
      );
    },
    viewCateringCheckin: function() {
      window.open(
        `/event/${this.props.params.eventId}/catering/checkin`,
        "_blank"
      );
    },
    viewPassesCheckin: function() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/passes/manage/fulfill`
      });
    },
    viewForms: function() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/forms-v2`
      });
    },
    viewSessions: function() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/sessions`
      });
    },
    viewVirtual: function() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/virtual/dashboard`
      });
    },
    viewVirtualPreview: function() {
      window.open(
        `/register/${this.props.eventDetails.slug}/${this.props.eventDetails.uuid}`,
        "_blank"
      );
    },
    viewVirtualDetails: function() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/virtual/details`
      });
    },
    viewVirtualTickets: function() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/virtual/tickets`
      });
    },
    viewVirtualRegistration: function() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/virtual/registration`
      });
    },
    viewReports: function() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/reports`
      });
    },
    viewOrders: function() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/orders/orders`
      });
    },
    viewInvoices: function() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/orders/invoices`
      });
    },
    viewTransactions: function() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/orders/transactions`
      });
    },
    viewOldInventoryDashboard: function() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/inventory`
      });
    },
    viewOldInventoryNeeds: function() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/inventory/needs`
      });
    },
    viewItemTypeDashboard: function({ moduleId }) {
      this.props.router.push({
        pathname: R.prop(moduleId)({
          [STANDARD_MODULE_IDS.credentials
            .id]: `/event/${this.props.params.eventId}/passes/dashboard`,
          [STANDARD_MODULE_IDS.catering
            .id]: `/event/${this.props.params.eventId}/catering/dashboard`,
          [STANDARD_MODULE_IDS.booths
            .id]: `/event/${this.props.params.eventId}/booths/dashboard`,
          [STANDARD_MODULE_IDS.inventory
            .id]: `/event/${this.props.params.eventId}/inventory/dashboard`
        })
      });
    },
    viewBoothSetup: function() {
      this.props.router.push({
        pathname: `/event/${this.props.params.eventId}/booths/setup/packages`
      });
    },
    viewBoothSalesPortal: function() {
      window.open(
        `/sales-portal/${this.props.eventDetails.slug}/${this.props.eventDetails.uuid}`,
        "_blank"
      );
    },
    viewItemTypeSendEmails: function({ moduleId }) {
      this.props.router.push({
        pathname: R.prop(moduleId)({
          [STANDARD_MODULE_IDS.credentials
            .id]: `/event/${this.props.params.eventId}/passes/confirm`,
          [STANDARD_MODULE_IDS.catering
            .id]: `/event/${this.props.params.eventId}/catering/confirm`
        })
      });
    },
    viewItemTypes: function({ moduleId }) {
      this.props.router.push({
        pathname:
          moduleId === STANDARD_MODULE_IDS.catering.id
            ? `event/${this.props.params.eventId}/settings/catering`
            : `/event/${
                this.props.params.eventId
              }/settings/catalog/type/${R.prop(moduleId)({
                [STANDARD_MODULE_IDS.credentials.id]: CREDENTIAL_TYPE_ID,
                [STANDARD_MODULE_IDS.catering.id]: MEAL_TYPE_ID,
                [STANDARD_MODULE_IDS.booths.id]: BOOTH_TYPE_ID,
                [STANDARD_MODULE_IDS.inventory.id]: ASSETS_TYPE_ID
              })}/items`
      });
    },
    viewItemTypeApprovers: function({ moduleId }) {
      this.props.router.push({
        pathname: R.prop(moduleId)({
          [STANDARD_MODULE_IDS.credentials
            .id]: `/event/${this.props.params.eventId}/settings/catalog/type/${CREDENTIAL_TYPE_ID}/approvers`,
          [STANDARD_MODULE_IDS.catering
            .id]: `/event/${this.props.params.eventId}/settings/catalog/type/${MEAL_TYPE_ID}/approvers`,
          [STANDARD_MODULE_IDS.booths
            .id]: `/event/${this.props.params.eventId}/settings/catalog/type/${BOOTH_TYPE_ID}/approvers`,
          [STANDARD_MODULE_IDS.inventory
            .id]: `/event/${this.props.params.eventId}/settings/catalog/type/${ASSETS_TYPE_ID}/approvers`
        })
      });
    },
    viewSetup: function({ moduleId }) {
      this.props.router.push({
        pathname: R.prop(moduleId)({
          [STANDARD_MODULE_IDS.credentials
            .id]: `/event/${this.props.params.eventId}/settings/catalog/type/${CREDENTIAL_TYPE_ID}/approvers`,
          [STANDARD_MODULE_IDS.catering
            .id]: `/event/${this.props.params.eventId}/settings/catalog/type/${MEAL_TYPE_ID}/approvers`,
          [STANDARD_MODULE_IDS.booths
            .id]: `/event/${this.props.params.eventId}/settings/catalog/type/${BOOTH_TYPE_ID}/approvers`,
          [STANDARD_MODULE_IDS.inventory
            .id]: `/event/${this.props.params.eventId}/settings/catalog/type/${ASSETS_TYPE_ID}/approvers`
        })
      });
    },
    viewItemTypeRequests: function({ moduleId }) {
      this.props.router.push({
        pathname: R.prop(moduleId)({
          [STANDARD_MODULE_IDS.credentials
            .id]: `/event/${this.props.params.eventId}/passes/manage/all-requests`,
          [STANDARD_MODULE_IDS.catering
            .id]: `/event/${this.props.params.eventId}/catering/manage/all-requests`,
          [STANDARD_MODULE_IDS.booths
            .id]: `/event/${this.props.params.eventId}/booths/manage/all-requests`,
          [STANDARD_MODULE_IDS.inventory
            .id]: `/event/${this.props.params.eventId}/inventory/manage/all-requests`
        })
      });
    },
    showImportOrdersModal: function() {
      this.props.showModal({
        content: <ImportOrdersModal />,
        wrapper: ModalWrapper
      });
    },
    showCreateDocRequestModal: function() {
      this.props.showModal({
        content: <AddFileModal />,
        wrapper: ModalWrapper
      });
    }
  },
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: [
      "user",
      "event",
      "modal",
      "organization",
      "permissions",
      "@flopflip"
    ]
  }
});

export default R.compose(
  connect(
    state => ({
      canDo: canEventUserDo(state),
      eventDetails: eventDetails(state)
    }),
    { showModal, showSnackbar, addRecord, hideModal }
  ),
  withRouter,
  decorate
)(Layout);
