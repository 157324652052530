import React from "react";
import * as R from "ramda";

import { actions, getters } from "../model";
import { getSelectedItems, getItemGroups, getIsValid } from "../selectors";
import { FIELD_IDS, FORM_ID } from "../constants";
import { connect } from "react-redux";
import { Div, BigFilledButton } from "components/Base";
import { Label, Divider } from "EventLight/Common/FieldLayout";
import { WithFormInstanceProvider } from "ui-kit/Form/View";

import { Input, Image, CheckboxInput } from "ui-kit/Form/View";

import MiniModalWrapper from "ui-kit/MiniModalWrapper";

const decorate = R.compose(
  WithFormInstanceProvider(R.always(FORM_ID)),
  connect(
    state => ({
      data: getters.data(state),
      showModal: getters.showAddContentModal(state),
      itemGroups: getItemGroups(state),
      loading: getters.loadingItemTypes(state),
      selectedItems: getSelectedItems(state),
      isValid: getIsValid(state)
    }),
    {
      hideModal: () => actions.setShowAddContentModal(false),
      onDone: actions.saveSelectPassesModal,
      save: actions.save,
      update: actions.update
    }
  )
);

const WithModal = ({ showModal, hideModal, data, isValid, save, update }) => {
  return (
    <MiniModalWrapper
      showModal={showModal}
      hideModal={hideModal}
      title={data.id ? "Update Content" : "Add Content"}
    >
      <Div
        bg="white"
        p={8}
        style={{
          overflowY: "auto"
        }}
      >
        <Div display="column" mb={5} width={1}>
          <Label>Title</Label>
          <Input
            fieldId={FIELD_IDS.TITLE}
            width={1}
            iniValue={R.prop(FIELD_IDS.TITLE)(data)}
            size="big"
            inputType="outline"
          />
        </Div>

        <Div display="column" mb={5} width={1}>
          <Label>Label</Label>
          <Input
            fieldId={FIELD_IDS.LABEL}
            width={1}
            iniValue={R.prop(FIELD_IDS.LABEL)(data)}
            size="big"
            inputType="outline"
          />
        </Div>

        <Div display="column" mb={5} width={1}>
          <Label>URL</Label>
          <Input
            fieldId={FIELD_IDS.URL}
            width={1}
            iniValue={R.prop(FIELD_IDS.URL)(data)}
            size="big"
            inputType="outline"
          />
        </Div>

        <Div display="column" mb={5} width={1}>
          <Label>Thumbnail Image</Label>
          <Image
            fieldId={FIELD_IDS.THUMBNAIL_URL}
            containerWidth="auto"
            containerProps={{
              display: "row.flex-start.flex-start",
              flex: 1
            }}
            imageWidth={123}
            imageHeight={70}
            recommendedWidth={"1280"}
            recommendedHeight={"720"}
            iniValue={
              R.prop(FIELD_IDS.LOGO_IMAGE_URL, data)
                ? [
                    {
                      url: R.prop(FIELD_IDS.LOGO_IMAGE_URL, data)
                    }
                  ]
                : null
            }
          />
        </Div>

        <Div display="column" mb={5} width={1}>
          <Label>Visible</Label>
          <CheckboxInput
            fieldId={FIELD_IDS.VISIBLE}
            iniValue={R.prop(FIELD_IDS.VISIBLE)(data)}
          />
        </Div>

        <Div display="column" mb={5} width={1}>
          <Label>Order</Label>
          <Input
            fieldId={FIELD_IDS.ORDER}
            width={1}
            iniValue={R.prop(FIELD_IDS.ORDER)(data)}
            size="big"
            inputType="outline"
          />
        </Div>

        <Divider />

        <BigFilledButton
          bg="altB5"
          color="white"
          disabled={!isValid}
          onClick={data.id ? update : save}
        >
          {data.id ? "Update Content" : "Add Content"}
        </BigFilledButton>
      </Div>
    </MiniModalWrapper>
  );
};

export default decorate(WithModal);
