import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { Div, Text4, Text1 } from "components/Base";

import { getters, actions } from "Schedules/Create";
import { getTypesOfSchedule } from "Schedules/Create/selectors";

const Toggler = ({ name, description, Icon, selected, onClick }) => (
  <Div
    bra={1}
    display="column.flex-start.flex-start"
    bg={
      selected
        ? { default: "primary8", hover: "primary9" }
        : { default: "neutral1", hover: "neutral2" }
    }
    bc={selected ? "primary4" : ""}
    ba={selected ? 2 : 0}
    onClick={onClick}
    p={4}
    width={0.5}
    mx={1}
    style={{ cursor: "pointer" }}
  >
    <Icon sizeWFS={6} color={selected ? "neutral3" : "neutral6"} my={1} />
    <Text4 bold color={selected ? "white" : "neutral8"} mb={1}>
      {name}
    </Text4>
    <Text1 color={selected ? "white" : "neutral8"} mb={1}>
      {description}
    </Text1>
  </Div>
);

const decorate = connect(
  (state, props) => ({
    scheduleTypes: getTypesOfSchedule(state, props),
    scheduleTypeSelected: getters.scheduleType(state, props)
  }),
  {
    selectScheduleType: actions.setScheduleType
  }
);

const ScheduleTypeToggler = ({
  scheduleTypes,
  scheduleTypeSelected,
  selectScheduleType
}) => (
  <Div display="row.space-between.flex-start" mb={6}>
    {R.map(
      type => (
        <Toggler
          key={type.id}
          selected={scheduleTypeSelected === type.id}
          onClick={() => selectScheduleType(type.id)}
          {...type}
        />
      ),
      scheduleTypes
    )}
  </Div>
);

export default decorate(ScheduleTypeToggler);
