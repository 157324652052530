import PropTypes from "prop-types";
import React, { Component } from "react";
import SendEmail from "SendEmailModal/View";
import BulkEdit from "components/Global/CRM/Modals/BulkEdit";
import ChangeRecordType from "components/Global/CRM/Modals/ChangeRecordType";
import DeleteConfirmation from "components/Global/CRM/Modals/DeleteConfirmation";
import DeleteOrRemoveConfirmation from "components/Global/CRM/Modals/DeleteOrRemoveConfirmation";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import SendRecordFormModal from "components/Global/Module/Modals/SendRecordForm";
import AssignOwnersModal from "components/Global/Module/Modals/AssignOwners";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";
import resolveEventHandler from "components/Event/Module/utils/resolveEventHandler";
import * as R from "ramda";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import CreateDocuments from "Modules/GenerateDocumentsModal/View";

class ToolbarActionsBase extends Component {
  static pluralOrSingular(count, module = {}) {
    return count === 1 ? module.record_name : module.record_name_plural;
  }

  getRowMetaData = (rowData, column) => ({
    ...rowData,
    meta: {
      userId: this.props.user.id,
      orgId: this.props.orgDetails.id,
      eventId: this.props.eventDetails.id,
      contactId: rowData.id,
      rowId: rowData.id,
      columnId: column.id,
      columnSettings: column.settings,
      eventDetails: this.props.eventDetails,
      references: this.props.references
    },
    helpers: {
      showModal: this.props.showModal,
      addReference: this.props.addReference
    }
  });

  approveRecords = () => {
    this.props.createReviews({
      reviews: this.props.selectedRows.map(recordId => ({
        userId: this.props.user.id,
        moduleId: this.props.module.id,
        recordId,
        response: "approve"
      }))
    });
    this.props.deselectAllRows();
    this.props.showSnackbar({
      message: "Records updated",
      action: "OK"
    });
  };

  denyRecords = () => {
    this.props.createReviews({
      reviews: this.props.selectedRows.map(recordId => ({
        userId: this.props.user.id,
        moduleId: this.props.module.id,
        recordId,
        response: "reject"
      }))
    });
    this.props.deselectAllRows();
    this.props.showSnackbar({
      message: "Records updated",
      action: "OK"
    });
  };

  editRecords = (data, recordIds) => {
    this.props
      .addValues({
        moduleId: this.props.module.id,
        values: recordIds.map(recordId => ({ recordId, ...data }))
      })
      .then(() => {
        this.props.deselectAllRows();
        this.props.showSnackbar({
          message: "Records updated",
          action: "OK"
        });
      });
  };

  deleteRecords = async (ids = [], removeOnly = false) => {
    await Promise.all(
      ids.map(recordId =>
        this.props.deleteRecord({
          moduleId: this.props.module.id,
          orgId: this.props.orgDetails.id,
          eventId: this.props.eventDetails.id,
          record: { id: recordId },
          options: {
            orgId: this.props.orgDetails.id,
            eventId: this.props.eventDetails.id,
            removeOnly
          }
        })
      )
    );

    this.props.deselectAllRows();
    this.props.fetchRecords();
    this.props.showSnackbar({
      message: `${ToolbarActionsBase.pluralOrSingular(
        ids.length,
        this.props.module
      )} removed`,
      action: "OK"
    });

    resolveEventHandler({
      moduleId: this.props.module.id,
      event: "delete_records",
      meta: {
        dispatch: this.props.dispatch,
        orgId: this.props.orgDetails.id,
        eventId: this.props.eventDetails.id
      }
    });
  };

  updateRecordType = async ({ type }) => {
    const {
      selectedRows,
      updateType,
      orgDetails,
      eventDetails,
      deselectAllRows,
      showSnackbar,
      module
    } = this.props;

    await Promise.all(
      selectedRows.map(recordId =>
        updateType({
          moduleId: module.id,
          recordId,
          type,
          options: {
            orgId: orgDetails.id,
            eventId: eventDetails.id
          }
        })
      )
    );
    await this.props.fetchRecords();
    deselectAllRows();
    showSnackbar({
      message: `${ToolbarActionsBase.pluralOrSingular(
        selectedRows.length,
        this.props.module
      )} updated`,
      action: "OK"
    });
  };

  sendEmailModal = async () => {
    this.props.showModal({
      content: (
        <SendEmail
          records={this.props.selectedRows}
          onDone={() => {
            this.props.deselectAllRows();
            this.props.fetchRecords();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  createSubmissionModal = async accountLookupField => {
    const accountsAndRecordIds = R.compose(
      R.filter(f => f.recordId && f.accountId),
      R.map(id => ({
        recordId: id,
        accountId: R.path(
          ["values", accountLookupField.id, "value", "records", 0],
          this.props.records.find(r => r.id === id) || ""
        )
      }))
    )(this.props.selectedRows);

    this.props.showModal({
      content: (
        <SendRecordFormModal
          moduleId={this.props.module.id}
          accountsAndRecordIds={accountsAndRecordIds}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showDeleteRecordsModal = () => {
    const { selectedRows, showModal, hideModal } = this.props;

    if (
      !this.props.orgDetails.id &&
      [
        STANDARD_MODULE_IDS.accounts.id,
        STANDARD_MODULE_IDS.contacts.id
      ].includes(this.props.module.id)
    ) {
      showModal({
        content: (
          <DeleteOrRemoveConfirmation
            hideModal={hideModal}
            countOfSelected={selectedRows.length}
            heading={`Remove ${ToolbarActionsBase.pluralOrSingular(
              selectedRows.length,
              this.props.module
            )}?`}
            onRemove={() => this.deleteRecords(selectedRows, true)}
            onDelete={() => this.deleteRecords(selectedRows, false)}
          />
        ),
        wrapper: ModalWrapper
      });
    } else {
      showModal({
        content: (
          <DeleteConfirmation
            hideModal={hideModal}
            heading={`Delete ${ToolbarActionsBase.pluralOrSingular(
              selectedRows.length,
              this.props.module
            )}?`}
            message={
              <div>
                {`
                  Are you sure you want to remove
                  ${
                    selectedRows.length === 1
                      ? "this"
                      : `these ${selectedRows.length}`
                  }
                  ${ToolbarActionsBase.pluralOrSingular(
                    selectedRows.length,
                    this.props.module
                  )}?
                  `}
                <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                  This cannot be undone.
                </div>
              </div>
            }
            onConfirm={() => this.deleteRecords(selectedRows, false)}
          />
        ),
        wrapper: ModalWrapper
      });
    }
  };

  showEditRecordsModal = () => {
    const { showModal, fields, selectedRows, module } = this.props;
    showModal({
      content: (
        <BulkEdit
          onSave={this.editRecords}
          selected={selectedRows}
          getMetaData={this.getRowMetaData}
          columns={fields.filter(c =>
            typeof c.settings.editable !== "undefined"
              ? c.settings.editable
              : !resolveReadOnlyFields({
                  moduleId: module.id
                }).includes(c.id)
          )}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  changeRecordTypes = () => {
    const { showModal, selectedRows, hideModal, recordTypes } = this.props;
    showModal({
      content: (
        <ChangeRecordType
          update={data => this.updateRecordType(data, selectedRows)}
          types={recordTypes}
          hideModal={hideModal}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showAssignOwnersModal = () => {
    const {
      showModal,
      selectedRows,
      module,
      fetchRecords,
      deselectAllRows
    } = this.props;
    showModal({
      content: (
        <AssignOwnersModal
          moduleId={module.id}
          recordNameSingular={module.record_name}
          recordNamePlural={module.record_name_plural}
          recordIds={selectedRows}
          onDone={() => {
            deselectAllRows();
            fetchRecords();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showGenerateDocumentsModal = () => {
    const {
      selectedRows,
      showModal,
      orgDetails,
      eventDetails,
      module
    } = this.props;

    showModal({
      content: (
        <CreateDocuments
          onDone={() => {
            this.props.deselectAllRows();
          }}
          orgId={orgDetails.id}
          eventId={eventDetails.id}
          moduleId={module.id}
          selected={selectedRows}
          showSaveAndSend={false}
        />
      ),
      wrapper: ModalWrapper
    });
  };
}

ToolbarActionsBase.propTypes = {
  addReference: PropTypes.func.isRequired,
  addValues: PropTypes.func.isRequired,
  createReviews: PropTypes.func.isRequired,
  deleteRecord: PropTypes.func.isRequired,
  deselectAllRows: PropTypes.func.isRequired,
  eventDetails: PropTypes.shape({
    id: PropTypes.string
  }).isRequired,
  fetchRecords: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  hideModal: PropTypes.func.isRequired,
  module: PropTypes.shape({
    id: PropTypes.string,
    record_name: PropTypes.string,
    record_name_plural: PropTypes.string
  }).isRequired,
  recordTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.string).isRequired,
  showModal: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired
};

export default ToolbarActionsBase;
