import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { actions } from "Portal/CustomizeSectionsModal/model";
import { getSections } from "Portal/CustomizeSectionsModal/selectors";
import { Div } from "components/Base";
import SectionCard from "./SectionCard";

const decorate = connect(
  state => ({
    sections: getSections(state)
  }),
  {
    updateField: actions.updateField,
    updateTitle: actions.updateTitle,
    toggleIsEnabled: actions.toggleIsEnabled
  }
);

const CardList = ({ updateField, updateTitle, sections, toggleIsEnabled }) => (
  <Div width={731}>
    {R.map(
      section => (
        <SectionCard
          key={section.id}
          {...section}
          updateField={updateField}
          updateTitle={updateTitle}
          toggleIsEnabled={toggleIsEnabled}
        />
      ),
      sections
    )}
    {/* <SectionPage /> */}
  </Div>
);

export default decorate(CardList);
