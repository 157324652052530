import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import AccountTypeIcon from "components/Global/CRM/AccountTypeIcon";
import UserTypeIcon from "components/PortalV2/UserTypeIcon";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Account = CSSModules(
  ({ name, type, icon, onClick }) => (
    <div styleName="account" onClick={onClick}>
      {icon}
      <div styleName="accountBody">
        <div styleName="accountTitle">{name}</div>
        <div styleName="accountType">{type}</div>
      </div>
      <i className="material-icons">keyboard_arrow_right</i>
    </div>
  ),
  css
);

class AccountsList extends PureComponent {
  render() {
    return (
      <div>
        {this.props.accounts.map(
          ({
            name,
            typeName,
            textColor,
            backgroundColor,
            icon,
            id,
            onClick
          }) => (
            <Account
              key={id}
              name={name}
              type={typeName || "Account"}
              icon={
                <AccountTypeIcon
                  name={name}
                  color={textColor}
                  backgroundColor={backgroundColor}
                  icon={icon}
                  size={48}
                />
              }
              onClick={onClick}
            />
          )
        )}
        {!this.props.accounts.length || this.props.includeUser ? (
          <Account
            name={this.props.userName}
            type="Portal User"
            icon={
              <UserTypeIcon
                color="#FDE23D"
                size={48}
                name={this.props.userName}
              />
            }
            onClick={this.props.onUserClick}
          />
        ) : null}
      </div>
    );
  }
}

AccountsList.defaultProps = {
  accounts: []
};

export default AccountsList;
