export const ACCEPT_UPLOAD_FILE = {
  word: [
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
  ],

  excel: [
    "text/csv",
    "application/excel",
    "application/x-excel",
    "application/x-msexcel",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  ],

  ppt: [
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation"
  ],

  pdf: ["application/pdf"],

  image: ["image/jpeg", "image/svg+xml", "image/bmp", "image/png"],

  video: [
    "video/mp4",
    "video/quicktime",
    "video/x-msvideo",
    "video/x-ms-wmv",
    "video/3gpp",
    "application/x-mpegURL"
  ],

  audio: [
    "audio/mid",
    "audio/mpeg",
    "audio/mp4",
    "audio/x-mpegurl",
    "audio/vnd.wav",
    "audio/x-wav",
    "audio/wave",
    "audio/wav"
  ]
};
