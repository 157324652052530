import React, { Component } from "react";
import PropTypes from "prop-types";
import CateringPicker from "components/Global/Editors/CateringEditor";
import { MEAL_TYPE_ID } from "utils/item-types";
import { get } from "lodash";

class Meals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: {
        type: "catering",
        value: {
          meals: props.selectedMeals
        }
      }
    };
  }

  handleCateringChange = value => {
    this.setState({ value });
  };

  handleClose = () => {
    const { mealItems } = this.props;

    const assignments = get(this.state, "value.value.meals", []).reduce(
      (list, { id, dates }) => {
        const mealItem = mealItems.find(item => item.id === id);
        if (dates && dates.length) {
          dates.forEach(date => {
            const variant = mealItem.variants.find(v =>
              v.dates_available.includes(date.date)
            );
            list.push({
              typeId: MEAL_TYPE_ID,
              id: variant.id,
              quantity: date.quantity
            });
          });
        }
        return list;
      },
      []
    );
    this.props.onSave({ typeId: MEAL_TYPE_ID, values: assignments });
    this.props.onClose();
  };

  render() {
    const {
      column = { type: "catering", settings: {} },
      rowMetaData,
      meals,
      mealDays,
      selectedDays
    } = this.props;

    return (
      <CateringPicker
        value={this.state.value}
        close={this.handleClose}
        onChange={this.handleCateringChange}
        column={column}
        rowMetaData={rowMetaData}
        selectedDays={selectedDays}
        meals={meals}
        mealDays={mealDays}
      />
    );
  }
}

Meals.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};
export default Meals;
