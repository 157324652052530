import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

export const Submit = CSSModules(
  ({ title, ...props }) => (
    <button type="submit" styleName="actionButton" {...props}>
      {title}
    </button>
  ),
  css
);

export const ButtonGroup = CSSModules(
  ({ children, ...props }) => (
    <div styleName="actionButtonGroup" {...props}>
      {Array.isArray(children) ? (
        children.map(child => (
          <div styleName="actionButtonGroupChild" key={child.key}>
            {child}
          </div>
        ))
      ) : (
        <div styleName="actionButtonGroupChild" key={children.key}>
          {children}
        </div>
      )}
    </div>
  ),
  css
);

export const ButtonOutline = CSSModules(
  ({ title, ...props }) => (
    <button styleName="actionButtonNoBg" {...props}>
      {title}
    </button>
  ),
  css
);
export const ButtonOrange = CSSModules(
  ({ title, ...props }) => (
    <div styleName="actionButton" {...props}>
      {title}
    </div>
  ),
  css
);
export const ButtonRed = CSSModules(
  ({ title, ...props }) => (
    <div styleName="actionButtonRed" {...props}>
      {title}
    </div>
  ),
  css
);
export const TextButton = CSSModules(
  ({ title, ...props }) => (
    <div styleName="textButton" {...props}>
      {title}
    </div>
  ),
  css
);
