import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";
import {
  catalogItems,
  catalogCategories
} from "@lennd/value-types/src/constants/standard-modules";
import getLookupValue from "utils/value-types/get-value/lookup";
import { pathOr } from "ramda";
import {
  CATALOG_CATEGORIES,
  CATALOG_ITEMS
} from "utils/standard-module-field-ids";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { hideModal, showModal } from "redux/modules/modal/actions";
import { getModule } from "redux/modules/modules/module/actions";
import {
  getRecords,
  deleteRecord
} from "redux/modules/modules/records/actions";
import { getEvent } from "redux/modules/event/actions";

import { eventDetails } from "redux/modules/event/selectors";
import { records } from "redux/modules/modules/records/selectors";
import { fields } from "redux/modules/modules/module/selectors";

function mapStateToProps(state) {
  // buld category map
  const catalogCategoryRecordMap = records(state, catalogCategories.id).reduce(
    (map, record) => {
      map[record.id] = {
        id: record.id,
        name: pathOr(
          null,
          ["values", CATALOG_CATEGORIES.NAME, "value"],
          record
        ),
        items: []
      };
      return map;
    },
    {}
  );

  // add items to category
  records(state, catalogItems.id).forEach(record => {
    const categoryId = pathOr(
      null,
      ["records", 0],
      getLookupValue(pathOr(null, ["values", CATALOG_ITEMS.CATEGORY], record))
    );
    if (categoryId && catalogCategoryRecordMap[categoryId]) {
      catalogCategoryRecordMap[categoryId].items.push({
        id: record.id,
        name: pathOr(null, ["values", CATALOG_ITEMS.ITEM_NAME, "value"], record)
      });
    }
  });

  const categoriesToPass = Object.keys(catalogCategoryRecordMap).reduce(
    (a, b) => {
      a.push(catalogCategoryRecordMap[b]);
      return a;
    },
    []
  );

  return {
    eventDetails: eventDetails(state),
    items: categoriesToPass.reduce((a, b) => [...a, ...b.items], []),
    catalogCategories: categoriesToPass
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getModule,
      getRecords,
      deleteRecord,
      getEvent
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controller);
