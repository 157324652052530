import React from "react";

import Table, { TableWrapper } from "ui-kit/Table/View";

const AllPassesView = ({ instanceId, loading = false, preferences = {} }) => {
  return (
    <TableWrapper>
      <Table
        instanceId={instanceId}
        {...{
          columnWidths: preferences.field_widths,
          meta: {
            showButton: true,
            enableViewOrder: true
          },
          loading
        }}
      />
    </TableWrapper>
  );
};

export default AllPassesView;
