import React from "react";
import {
  Div,
  SmallToggle,
  Text0,
  Text1,
  Text3,
  MediumOutlineInput,
  CollapsablePanelType2
} from "components/Base";
import { noop } from "utils/General";
import { TriggersView, NoTriggersView } from "./TriggersView";
import {
  ApproversView,
  NoApproversView,
  DefaultApproversView,
  NoDefaultApproverView
} from "./ApproversView";

const DefaultView = ({
  title,
  subtitle,
  rows,
  quanta,
  menuItems = [],
  editDefaultApprovers,
  addDefaultApprovers,
  hasApprover
}) => {
  return (
    <Div width={1} p={3}>
      <Div pb={2}>
        <Text3 bold>Basic Approval</Text3>
        <Text1 bold mt={1}>
          Approvers you specify below will review any items which are not
          triggered by other workflows.
        </Text1>
      </Div>

      {hasApprover ? (
        <DefaultApproversView
          title={title}
          subtitle={subtitle}
          rows={rows}
          quanta={quanta}
          menuItems={menuItems}
          editDefaultApprovers={editDefaultApprovers}
        />
      ) : (
        <NoDefaultApproverView onClick={addDefaultApprovers} />
      )}
    </Div>
  );
};

const View = ({
  name,
  active,
  triggers,
  approverGroups,
  multiStepApprovalsActive,
  toggleMultiStepApprovals,
  toggleWorkflow,
  changeWorkflowName,
  reorderTriggers,
  newApproverGroup,
  addTriggerMenuItems,
  reorderApproverGroups,
  hasTrigger,
  hasApprover,
  hasTriggerAndApproval
}) => {
  return (
    <Div width={1} p={6}>
      <Div pb={3}>
        <Text3 bold>Workflow Name</Text3>
        <MediumOutlineInput
          value={name}
          placeholder={"Workflow Name"}
          onChange={changeWorkflowName}
        />
        <Div py={2} display={"row.flex-start.center"}>
          <SmallToggle
            active={active}
            onClick={hasTriggerAndApproval ? toggleWorkflow : noop}
          />

          <Div ml={2}>
            <Text3 bold>Workflow is {active ? "active" : "inactive"}</Text3>
            {!hasTriggerAndApproval && (
              <Text0 color={"neutral6"} bold>
                Workflow must have at least one trigger AND one approver
              </Text0>
            )}
          </Div>
        </Div>
      </Div>

      <CollapsablePanelType2 headingText="Triggers">
        {hasTrigger ? (
          <TriggersView
            triggers={triggers}
            addTriggerMenuItems={addTriggerMenuItems}
            reorderTriggers={reorderTriggers}
          />
        ) : (
          <NoTriggersView menuItems={addTriggerMenuItems} />
        )}
      </CollapsablePanelType2>

      <CollapsablePanelType2 headingText="Approvers">
        {hasApprover ? (
          <ApproversView
            multiStepApprovalsActive={multiStepApprovalsActive}
            toggleMultiStepApprovals={toggleMultiStepApprovals}
            approverGroups={approverGroups}
            reorderApproverGroups={reorderApproverGroups}
            newApproverGroup={newApproverGroup}
          />
        ) : (
          <NoApproversView onClick={newApproverGroup} />
        )}
      </CollapsablePanelType2>
    </Div>
  );
};

export { View, DefaultView };
