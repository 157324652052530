import React from "react";
import {
  Div,
  Text1,
  Text2,
  Text3,
  DragIcon,
  CloseIcon,
  Cards
} from "components/Base";

import { actions } from "../index";

import { connect } from "react-redux";

import { getSelectedModules } from "../selectors";

const decorate = connect(
  state => ({
    modules: getSelectedModules(state)
  }),
  {
    updateSelectedModuleOrder: actions.updateSelectedModuleOrder,
    toggleModule: actions.toggleModule,
    removeAllSelectedModules: actions.removeAllSelectedModules
  }
);

const Module = ({ toggleModule, ...q }) => (
  <Div bra={1} bg="altB1" width={1} p={2} mt={2}>
    <Div display="row.space-between.center">
      <Div display="row.flex-start.center">
        <DragIcon />
        <Text2 ml={2} color="black" bold>
          {q.name}
        </Text2>
      </Div>
      <Div display="row.flex-start.center">
        <CloseIcon
          color={{
            default: "gray4",
            hover: "gray5"
          }}
          onClick={() =>
            toggleModule({
              moduleId: q.id,
              fieldId: q.lookup_field.id
            })
          }
        />
      </Div>
    </Div>
  </Div>
);

const ModuleRows = Cards(Module, "SELECT_MODULE_MODULES");

const SelectedModules = ({
  modules,
  updateSelectedModuleOrder,
  removeAllSelectedModules,
  toggleModule
}) => (
  <Div width={1} height={1} style={{ overflowY: "auto" }} p={3}>
    <Div display="row.space-between.center">
      <Text3 bold color="black">
        Selected Modules
      </Text3>
      <Text1 color="primary7" underline onClick={removeAllSelectedModules}>
        Remove All
      </Text1>
    </Div>
    <Div width={1} mt={1}>
      <ModuleRows
        cards={modules}
        onReorder={updateSelectedModuleOrder}
        toggleModule={toggleModule}
      />
    </Div>
  </Div>
);

export default decorate(SelectedModules);
