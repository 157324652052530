/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  getRecordsIsApproverFor: ({
    credentials,
    recordType = "lineItem",
    recordIds
  }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/approval-workflows/approvers/records`,
        credentials,
        data: { ids: recordIds, recordType },
        success,
        error
      });
    })
};
