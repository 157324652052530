import React, { Component } from "react";
import * as R from "ramda";
import Avatar from "components/Atoms/Avatar";
import { Div } from "components/Base";

import { getValue } from "ui-kit/Datagrid/utils";

const styles = {
  record: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  avatarWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginRight: 5,
    width: 25,
    height: 25
  },
  name: {
    fontSize: 14,
    display: "flex",
    marginLeft: 3
  }
};

export default class UserCellFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props.value, nextProps.value);
  }

  render() {
    const records = getValue(this.props, "records", []);
    const showName = records.length === 1;

    const sortedRecords = R.sortBy(r => {
      const sortId = r.type === "member" ? 1 : 2;
      return `${sortId}_${r.id}`;
    }, records);

    return (
      <Div display="row.flex-start.center">
        {sortedRecords.map(record => {
          const splitName = record.name ? record.name.split(" ") : {};
          return (
            <div key={record.id} style={styles.record}>
              <div style={styles.avatarWrapper}>
                <Avatar
                  userId={record.id}
                  imageUrl={record.photoUrl}
                  firstName={splitName[0] || ""}
                  lastName={splitName[1] || ""}
                  size={25}
                  alt
                  border
                />
              </div>
              {showName && <div style={styles.name}>{record.name}</div>}
            </div>
          );
        })}
      </Div>
    );
  }
}
