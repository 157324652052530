import React, { Component } from "react";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import DocumentsCard from "./DocumentsCard";
import RequestModal from "./RequestModal";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import Page from "Portal/Page";
import FileRequests from "EventLight/CRMProfile/FileRequests/View";

import {
  getDocumentsTitle,
  getDocumentsDescription
} from "redux/modules/portal/settings/selectors";
import { getBackgroundImageUrl } from "redux/modules/portal/selectors";

const baseUrl = ({ portalId, portalSlug }) =>
  `/portals/${portalSlug}/${portalId}/documents`;

const decorate = connect(state => ({
  title: getDocumentsTitle(state),
  description: getDocumentsDescription(state),
  backgroundImageUrl: getBackgroundImageUrl(state)
}));

@CSSModules(css)
class Documents extends Component {
  componentDidMount() {
    if (this.props.params.requestId) {
      this.showRequestModal({
        recordId: this.props.params.requestId
      });
    }
    this.fetchDocuments();
  }

  fetchDocuments = () => {
    const { portalUser } = this.props;
    const recordId =
      portalUser.active_view.type === "user"
        ? portalUser.active_view.contact_id
        : portalUser.active_view.id;
    const moduleId =
      portalUser.active_view.type === "user"
        ? STANDARD_MODULE_IDS.contacts.id
        : STANDARD_MODULE_IDS.accounts.id;
    const valueName =
      portalUser.active_view.type === "user" ? "contactId" : "accountId";

    this.props.getRelatedRecords({
      recordId,
      moduleId: STANDARD_MODULE_IDS.files.id,
      eventId: this.props.eventDetails.id,
      options: {
        eventId: this.props.eventDetails.id,
        valueId: recordId,
        valueName,
        valueModuleId: moduleId
      }
    });
  };

  showRequestModal = ({ recordId }) => {
    this.props.showModal({
      content: (
        <RequestModal
          view="portal"
          recordId={recordId}
          hideModal={this.onHideModal}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  onHideModal = () => {
    this.props.hideModal();
    this.props.router.push({ pathname: baseUrl(this.props.params) });
  };

  render() {
    const {
      title,
      description,
      backgroundImageUrl,
      documents,
      params,
      portalUser,
      showModal
    } = this.props;

    const recordId =
      portalUser.active_view.type === "user"
        ? portalUser.active_view.contact_id
        : portalUser.active_view.id;
    const recordName = portalUser.active_view.name;

    const moduleId =
      portalUser.active_view.type === "user"
        ? STANDARD_MODULE_IDS.contacts.id
        : STANDARD_MODULE_IDS.accounts.id;

    return (
      <Page
        instanceId="documents"
        title={title}
        description={description}
        backgroundImageUrl={backgroundImageUrl}
      >
        <div>
          <div styleName="section">
            <FileRequests
              key="file-requests"
              mode="portal"
              moduleId={moduleId}
              recordId={recordId}
            />
          </div>

          <DocumentsCard
            eventId={this.props.eventDetails.id}
            recordId={recordId}
            recordName={recordName}
            documents={documents}
            showModal={showModal}
            getAccount={() => this.props.getPortal(params.portalId)}
          />
        </div>
      </Page>
    );
  }
}

export default decorate(Documents);
