import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import NewMessageTag from "./NewMessageTag";
import Icon from "./Icon";

const Messages = ({ countOfMessages, countOfUnreadMessages, onClick }) => {
  if (countOfMessages > 0) {
    return (
      <div styleName="container" onClick={onClick}>
        <div styleName="tag">
          <NewMessageTag count={countOfUnreadMessages} />
        </div>
        <Icon />
        <span styleName="count">{countOfMessages}</span>
      </div>
    );
  }
  return "";
};

Messages.propTypes = {
  onClick: PropTypes.func.isRequired,
  countOfMessages: PropTypes.number.isRequired,
  countOfUnreadMessages: PropTypes.number.isRequired
};

export default CSSModules(Messages, css);
