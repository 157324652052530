import Api from "utils/Global/WebApiUtils";
import { getLoggedInUserDetails } from "redux/modules/user/actions";

const updateUser = data => (dispatch, getState) => {
  return Api.updateUser(getState().user.user.credentials, data).then(() => {
    dispatch(getLoggedInUserDetails());
    return null;
  });
};

export const actions = {
  updateUser
};
