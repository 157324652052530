import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { getSalesPortals } from "EventLight/Portals/Portals/selectors";
import { Div, Text5 } from "components/Base";
import PortalRow from "./PortalRow";

const decorate = connect(state => ({
  list: getSalesPortals(state)
}));

const SalesPortals = ({ list }) => (
  <Div mt={5}>
    <Div display="row.flex-start.center" pl={1}>
      <Text5 bold color="black">
        Sales Portals
      </Text5>
      <Text5 ml={1} color="black">
        ({R.length(list)})
      </Text5>
    </Div>
    <Div mt={2}>
      {R.map(
        row => (
          <PortalRow row={row} />
        ),
        list
      )}
    </Div>
  </Div>
);

export default decorate(SalesPortals);
