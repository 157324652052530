import React from "react";

const mergeClassNames = (c1, c2) => {
  if (!c2) return c1;
  return `${c1} ${c2}`;
};
const FIELD_ICON_MAP = {
  "catalog-item": "link",
  "event-days": "today",
  approval: "done",
  boolean: "check_box",
  catering: "restaurant",
  countries: "map",
  credentials: "link",
  date: "today",
  datetime: "today",
  dropdown: "view_list",
  email: "mail_outline",
  file: "attach_file",
  lookup: "link",
  number: "text_format",
  phone: "phone",
  source: "input",
  text: "text_format",
  textarea: "wrap_text",
  time: "access_time",
  user: "person"
};
export function fieldTypeIcon(type) {
  return FIELD_ICON_MAP[type] || FIELD_ICON_MAP.text;
}
export default ({ type, className, ...rest }) => {
  return (
    <i className={mergeClassNames("material-icons", className)} {...rest}>
      {fieldTypeIcon(type)}
    </i>
  );
};
