import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import MakeCopies from "components/Event/FormsV2/Sections/FormGrid/CardView/Edit/MakeCopies";

@CSSModules(css)
class CardHeader extends Component {
  state = { showMakeCopy: false };

  onMenuClick = (event, command) => {
    event.preventDefault();
    switch (command) {
      case "delete":
        this.props.removeSelectedRows([this.props.rowId]);
        break;
      case "duplicate":
        this.setState({ showMakeCopy: true });
        break;
      default:
        break;
    }
  };

  stopPropagation(e) {
    e.stopPropagation();
  }

  duplicateRow = qty => this.props.duplicateRow(this.props.rowId, qty);

  renderMenu = () => (
    <IconMenu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right"
      }}
      onChange={this.onMenuClick}
      iconButtonElement={
        <span onClick={this.stopPropagation}>
          <i className={["material-icons", css.menuIcon].join(" ")}>
            more_vert
          </i>
        </span>
      }
    >
      <MenuItem
        value="duplicate"
        leftIcon={<i className="material-icons">control_point_duplicate</i>}
        primaryText="Duplicate"
      />
      <MenuItem
        value="delete"
        leftIcon={<i className="material-icons">delete</i>}
        primaryText="Delete"
      />
    </IconMenu>
  );

  headerLabel(status) {
    switch (status) {
      case "approved":
        return (
          <div styleName="approved">
            <i className="material-icons">check</i>
            <span>Approved</span>
          </div>
        );
      case "rejected":
        return (
          <div styleName="rejected">
            <i className="material-icons">close</i>
            <span>Rejected</span>
          </div>
        );
      default:
        return undefined;
    }
  }

  render() {
    const { index, collapsed, showMenu, onCollapse, status } = this.props;
    return (
      <div styleName="container" onClick={onCollapse}>
        {collapsed ? (
          <div styleName="iconWrapperHover">
            <i className="material-icons">keyboard_arrow_right</i>
          </div>
        ) : (
          <div
            styleName="iconWrapper"
            style={{ backgroundColor: "white", borderColor: "#EFEFEF" }}
          >
            <i className="material-icons">keyboard_arrow_down</i>
          </div>
        )}
        <div styleName="title">Request {index}</div>
        {this.headerLabel(status)}
        {showMenu ? (
          <div styleName="menuWrapper">
            {this.renderMenu()}
            {this.state.showMakeCopy ? (
              <span onClick={this.stopPropagation}>
                <MakeCopies
                  duplicate={this.duplicateRow}
                  forceOpen
                  onHide={() => this.setState({ showMakeCopy: false })}
                />
              </span>
            ) : null}
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

CardHeader.propTypes = {
  collapsed: PropTypes.bool,
  duplicateRow: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  onCollapse: PropTypes.func.isRequired,
  removeSelectedRows: PropTypes.func.isRequired,
  showMenu: PropTypes.bool,
  status: PropTypes.oneOf(["rejected", "approved"])
};

export default CardHeader;
