import React, { useEffect } from "react";
import * as R from "ramda";
import { withRouter } from "react-router";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import { actions, getters } from "../model";
import css from "./styles.scss";
import { getIsSaveBtnDisabled } from "../selectors";
import Loading from "ui-kit/Loading";
import Content from "./Content";
import Tabs from "./Tabs";
import { noop } from "utils/General";
import { BigFilledButton } from "components/Base";
import AddAssignmentModal from "EventLight/FileRequests/AddAssignmentModal/View";
import EditCloseDateModal from "./EditCloseDateModal";

const decorate = R.compose(
  connect(
    state => ({
      name: getters.name(state),
      loading: getters.loading(state),
      dueDate: getters.dueDate(state),
      files: getters.files(state),
      enableReminders: getters.enableReminders(state),
      criteria: getters.criteria(state),
      isSaveBtnDisabled: getIsSaveBtnDisabled(state),
      showAddAssignmentModal: getters.showAddAssignmentModal(state),
      type: getters.type(state),
      editDueDateModalId: getters.editDueDateModalId(state),
      saving: getters.saving(state)
    }),
    {
      init: actions.init,
      setName: actions.setName,
      setDueDate: actions.setDueDate,
      toggleEnableReminders: actions.toggleEnableReminders,
      setCriteria: actions.setCriteria,
      save: actions.save,
      hideCreateModal: actions.hideCreateModal
    }
  ),
  withRouter,
  CSSModules(css)
);

const AddFileModal = ({
  hideCreateModal,
  init,
  loading,
  requestId = "",
  onSave = noop,
  save,
  isSaveBtnDisabled,
  showAddAssignmentModal,
  type,
  editDueDateModalId,
  saving
}) => {
  useEffect(() => {
    init({ requestId });
  }, []);

  return (
    <MiniModalWrapper
      showModal={true}
      hideModal={hideCreateModal}
      title={`${R.isEmpty(requestId) ? "Create new" : "Edit"} file request`}
      width={500}
      height={675}
      stopPropagation
      isPortal={true}
    >
      {!R.isEmpty(editDueDateModalId) ? <EditCloseDateModal /> : null}
      {showAddAssignmentModal ? <AddAssignmentModal type={type} /> : null}
      {loading ? (
        <div styleName="wrapper">
          <Loading />
        </div>
      ) : (
        <div styleName="wrapper">
          <Tabs />
          <Content />
          <div styleName="footer">
            <BigFilledButton
              bg={isSaveBtnDisabled ? "neutral3" : "primary7"}
              disabled={isSaveBtnDisabled}
              onClick={() => save({ onSave })}
            >
              {saving ? "Saving..." : "Save"}
            </BigFilledButton>
          </div>
        </div>
      )}
    </MiniModalWrapper>
  );
};

export default decorate(AddFileModal);
