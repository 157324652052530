import { createModule } from "redux-mvc";

import selectFields from "ui-kit/SelectFields";
import miniItemsSelector from "ui-kit/MiniItemsSelector";
import handlers from "Orders/ImportModal/handlers";

const module = createModule(handlers);

module.plugModule(selectFields);
module.plugModule(miniItemsSelector);

const { actions, getters } = module;

export { actions, getters };

export default module;
