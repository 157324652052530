import React from "react";
import {
  Div,
  CloseIcon,
  SmallToggle,
  BigOutlineButton,
  BigFilledButton,
  RightArrowIcon,
  MediumOutlineInput
} from "components/Base";
import { connect } from "react-redux";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import * as R from "ramda";
import StatusCircle from "ui-kit/StatusCircle";
import { addS } from "utils/General";

import { getters, actions } from "Portal/PortalPeople/model";

import { getPeopleTypes, isValid } from "Portal/PortalPeople/selectors";

const decorate = connect(
  (state, props) => ({
    isModalVisible: getters.isAddUserModalVisible(state, props),
    selectedIds: getters.selectedIds(state, props),
    options: getPeopleTypes(state),
    markAsPrimaryContact: getters.markAsPrimaryContact(state),
    addCustomRole: getters.addCustomRole(state),
    role: getters.role(state),
    isValid: isValid(state)
  }),
  {
    onClose: () => actions.setIsAddUserModalVisible(false),
    onSave: actions.onSave,
    setSelectedTypeId: actions.setSelectedTypeId,
    setMarkAsPrimaryContact: actions.setMarkAsPrimaryContact,
    setAddCustomRole: actions.setAddCustomRole,
    setRole: actions.setRole,
    setSelectedIds: actions.setSelectedIds
  }
);

const Box = ({ children, ...props }) => (
  <Div
    display="column"
    bg="white"
    px={3}
    py={3}
    bra={3}
    mb={3}
    shadow={{
      default: 1,
      hover: 2
    }}
    style={{
      cursor: "pointer"
    }}
    {...props}
  >
    {children}
  </Div>
);

export const AddUserModal = ({
  saving,
  isModalVisible,
  onClose,
  selectedIds,
  options,
  setSelectedTypeId,
  markAsPrimaryContact,
  setMarkAsPrimaryContact,
  addCustomRole,
  setAddCustomRole,
  role,
  setRole,
  onSave,
  isValid,
  setSelectedIds
}) => (
  <MiniModalWrapper
    showModal={isModalVisible}
    hideModal={() => {
      setSelectedIds([]);
      onClose();
    }}
    style={{ width: 480, height: 540 }}
    isPortal={true}
    bg="neutral0"
    stopPropagation
    showHeader={false}
  >
    <>
      <Div bg="white" display="row.flex-start.center" p={5}>
        <Div flex={1} fs={5} color="black" fw={4}>
          Adding {R.length(selectedIds)}{" "}
          {R.length(selectedIds) === 1 ? "person" : "people"} to this event...
        </Div>
        <CloseIcon
          size={25}
          ml={2}
          color="black"
          onClick={() => {
            setSelectedIds([]);
            onClose();
          }}
        />
      </Div>
      <Div
        p={5}
        flex={1}
        style={{
          overflowY: "auto"
        }}
      >
        <Div>
          <Div fs={3} mr={2} fw={3} mb={2} color="black">
            Select the category to add{" "}
            {R.length(selectedIds) === 1 ? "this person" : "people"} to:
          </Div>
          <Div>
            {options.map(option => (
              <Box
                key={option.id}
                onClick={
                  option.canAdd ? () => setSelectedTypeId(option.id) : undefined
                }
              >
                <Div display="row.flex-start.center">
                  <StatusCircle
                    status={option.selected ? "approved" : "default"}
                    size={30}
                  />
                  <Div
                    display="column"
                    color="black"
                    fs={4}
                    ml={4}
                    mr={4}
                    fw={3}
                  >
                    {option.categoryName}

                    {!option.usesSpotsLeft || option.canAdd ? null : (
                      <Div color="gray5" fs={2}>
                        Not enough spots available.
                      </Div>
                    )}
                  </Div>
                </Div>
              </Box>
            ))}
          </Div>
        </Div>

        <Div mt={5}>
          <Div fs={3} mr={2} fw={3} mb={2} color="black">
            Options
          </Div>
          <Div>
            <Box
              onClick={ev => {
                ev.stopPropagation();
                setMarkAsPrimaryContact(!markAsPrimaryContact);
              }}
            >
              <Div display="row.flex-start.center">
                <SmallToggle active={markAsPrimaryContact} onClick={() => {}} />
                <Div color="black" fs={4} ml={4} fw={3}>
                  Mark as primary contact{addS(R.length(selectedIds))}
                </Div>
              </Div>
            </Box>
            <Box
              onClick={ev => {
                ev.stopPropagation();
                setAddCustomRole(!addCustomRole);
              }}
            >
              <Div display="row.flex-start.center">
                <SmallToggle active={addCustomRole} onClick={() => {}} />
                <Div color="black" fs={4} ml={4} fw={3}>
                  Enter a role/job title for{" "}
                  {R.length(selectedIds) === 1 ? "this person" : "the people"}
                </Div>
              </Div>
              {addCustomRole ? (
                <Div
                  onClick={ev => {
                    ev.stopPropagation();
                  }}
                  style={{
                    marginLeft: "55px"
                  }}
                >
                  <MediumOutlineInput
                    placeholder="e.g. Event Staff, On-Site Contact"
                    width={300}
                    mt={3}
                    value={role}
                    onChange={setRole}
                  />
                </Div>
              ) : null}
            </Box>
          </Div>
        </Div>
      </Div>
      <Div
        display="row.flex-start.center"
        bg="white"
        p={5}
        height={70}
        style={{
          flexShrink: 0
        }}
      >
        <BigOutlineButton
          mr={3}
          onClick={() => {
            setSelectedIds([]);
            onClose();
          }}
        >
          Cancel
        </BigOutlineButton>
        <BigFilledButton
          bg="indigo7"
          onClick={saving ? undefined : onSave}
          RightIcon={RightArrowIcon}
          flex={1}
          disabled={!isValid}
        >
          {saving ? "Saving..." : "Add to event"}
        </BigFilledButton>
      </Div>
    </>
  </MiniModalWrapper>
);

export default decorate(AddUserModal);
