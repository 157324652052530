import { REQUEST, RECEIVE } from "./constants";
import api from "./api";
import { addReferences } from "redux/modules/entityReferences/actions";
import { registerError } from "redux/modules/errors/actions";

export function getRecords(data) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: REQUEST,
        payload: {
          moduleId: data.moduleId,
          groupId: data.groupId
        }
      });
      const result = await api.get(getState().user.user.credentials, data);

      dispatch(addReferences(result.records.references));
      dispatch({
        type: RECEIVE,
        payload: {
          moduleId: data.moduleId,
          groupId: data.groupId,
          records: result.records
        }
      });
      return result.records;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching records"
          }
        ])
      );
    }
  };
}

export function addRecords(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.bulkPost(getState().user.user.credentials, data);
      return result.records;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding records"
          }
        ])
      );
      return false;
    }
  };
}

export function addRecord(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post(getState().user.user.credentials, data);
      return result.record;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding record"
          }
        ])
      );
      return false;
    }
  };
}

export function deleteRecord(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.delete(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting record"
          }
        ])
      );
      return false;
    }
  };
}

export function updateType({ moduleId, recordId, type, options }) {
  return async (dispatch, getState) => {
    try {
      return api.put(getState().user.user.credentials, {
        moduleId,
        recordId,
        type,
        options
      });
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating types"
          }
        ])
      );
    }
  };
}

export function search(eventId, moduleId, query, fields) {
  return async (dispatch, getState) => {
    try {
      return api.search(
        getState().user.user.credentials,
        eventId,
        moduleId,
        query,
        fields
      );
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred searching records"
          }
        ])
      );
      return false;
    }
  };
}

export function assignEvents({ eventIds, moduleId, recordIds, options }) {
  return async (dispatch, getState) => {
    try {
      return api.assignEvents(getState().user.user.credentials, {
        eventIds,
        moduleId,
        recordIds,
        options
      });
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred assigning event to record"
          }
        ])
      );
      return false;
    }
  };
}

export function removeEventAssignments({
  eventIds,
  recordIds,
  moduleId,
  options
}) {
  return async (dispatch, getState) => {
    try {
      return api.unassignEvents(getState().user.user.credentials, {
        moduleId,
        eventIds,
        recordIds,
        options
      });
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred assigning event to record"
          }
        ])
      );
      return false;
    }
  };
}
