import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  currentPage: 0,
  countOfPages: 0,
  accountId: null,
  eventId: null,
  contactId: null,
  orgId: null
};

const model = createHandlers({
  iniState,
  reducers: {
    init: (_, { payload: { accountId, eventId, orgId, contactId } }) => ({
      accountId,
      eventId,
      contactId,
      orgId
    }),
    setPages: (_, { payload: { count_of_pages, current_page } }) => ({
      currentPage: current_page,
      countOfPages: count_of_pages
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
