import { createHandlers } from "redux-mvc";

import * as R from "ramda";

const iniState = {};

const model = createHandlers({
  iniState,
  reducers: { executeAction: R.identity },
  namespace: "UiActionsMenu"
});

const { actions, getters } = model;

export { actions, getters };

export default model;
