import { get } from "lodash";
import { combineReducers } from "redux";
import { RECEIVE } from "redux/modules/credentials/integrations/constants";

const ticketClasses = (state = [], action) => {
  switch (action.type) {
    case RECEIVE:
      return get(
        action.payload.find(i => i.name === "eventbrite"),
        "synced_ticket_classes",
        []
      );
    default:
      return state;
  }
};

const ticketClassesMap = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return get(
        action.payload.find(i => i.name === "eventbrite"),
        "synced_ticket_classes",
        []
      ).reduce((credentialMap, ticketClass) => {
        credentialMap[ticketClass.credential_id] = ticketClass;
        return credentialMap;
      }, {});
    default:
      return state;
  }
};

export default combineReducers({
  ticketClasses,
  ticketClassesMap
});
