import React, { Fragment } from "react";
import { connect } from "react-redux";
import { getters, actions } from "Forms/WizardModal";
import { getShowIconColor } from "Forms/WizardModal/selectors";
import {
  Div,
  SuperOutlineButton,
  SuperFilledButton,
  AddCircleIcon,
  LoadingIcon,
  Span,
  SmallToggle,
  Text3
} from "components/Base";

import { Label, Input } from "components/Event/Module/Form/Common";
import DatePicker from "components/Event/FormsV2/Modals/FormEditModal/Calendar";
import isFormLocked from "components/Event/FormsV2/Utils/is-form-locked";
import SelectColorIcon from "./SelectColorIcon";

import Header from "Forms/WizardModal/View/Header";

const decorate = connect(
  state => ({
    saving: getters.saving(state),
    title: getters.formName(state),
    internalTitle: getters.formTitle(state),
    closeDate: getters.formCloseDate(state),
    isLocked: isFormLocked({
      close_date: getters.formCloseDate(state),
      is_locked: getters.formIsLocked(state)
    }),
    showOnEventHome: getters.showOnEventHome(state),
    showIconColor: getShowIconColor(state)
  }),
  {
    onChangeTitle: actions.setFormName,
    onChangeInternalTitle: actions.setFormTitle,
    onChangeCloseDate: actions.setFormCloseDate,
    onChangeIsLocked: actions.setFormIsLocked,
    setShowOnEventHome: actions.setShowOnEventHome
  }
);

const ReviewForm = ({
  handlers,
  steps,
  saveForm,
  saveBtnEnabled,
  goBack,
  saving,
  //
  title,
  closeDate,
  isLocked,
  //
  onChangeTitle,
  onChangeCloseDate,
  onChangeIsLocked,
  setShowOnEventHome,
  showOnEventHome,
  showIconColor
}) => {
  return (
    <Div width={1} display="column.space-between" height={1} bg="neutral0">
      <Header handlers={handlers} steps={steps} />
      <Div
        bg="neutral0"
        display="column.flex-start.center"
        flex={1}
        p={5}
        pt={0}
        style={{ overflow: "auto" }}
      >
        <Div fs={6} fw={4} color="black" my={5} style={{ flexShrink: 0 }}>
          Review Form Settings
        </Div>

        <Div display="row">
          <Div width={420}>
            <Label mb={1}>Form Name</Label>
            <Input
              value={title}
              onChange={e => onChangeTitle(e.target.value)}
              mb={6}
            />

            <Div
              display={
                closeDate
                  ? "row.space-between.center"
                  : "row.space-between.flex-start"
              }
              mb={6}
            >
              <Div display="column">
                <Label>Close Date</Label>
                <Div fs={1} color="neutral6">
                  If set, form and submissions will close after specified date
                </Div>
              </Div>

              <DatePicker value={closeDate} onChange={onChangeCloseDate} />
            </Div>

            <Div display="row.space-between.center" mb={4}>
              {closeDate ? (
                isLocked ? (
                  <Fragment>
                    <Div display="column">
                      <Label>Accepting Submissions</Label>
                      <Div fs={1} color="neutral6">
                        Form is closed because close date has passed
                      </Div>
                    </Div>

                    <Div fw={3} color="red7">
                      Form Closed
                    </Div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Div display="column">
                      <Label>Accepting Submissions</Label>
                      <Div fs={1} color="neutral6">
                        Form will be closed when close date passes
                      </Div>
                    </Div>

                    <Div fw={3} color="green7">
                      Form Open
                    </Div>
                  </Fragment>
                )
              ) : (
                <Div display="row.flex-start.center">
                  {!isLocked ? (
                    <Div fw={3} color="green7" mr={3}>
                      Form Open
                    </Div>
                  ) : (
                    <Div fw={3} color="red7" mr={3}>
                      Form Closed
                    </Div>
                  )}
                  <SmallToggle
                    active={!isLocked}
                    onClick={() => onChangeIsLocked(!isLocked)}
                  />
                </Div>
              )}
            </Div>

            {!isLocked ? (
              <Div
                bg="green0"
                p={3}
                bra={1}
                mb={6}
                style={{
                  border: "1px solid rgba(0, 193, 124, 0.28)"
                }}
                display="row.flex-start.center"
              >
                This form <Span fw={3}>&nbsp;is accepting&nbsp;</Span> new
                submissions
              </Div>
            ) : (
              <Div
                p={3}
                bra={1}
                mb={6}
                style={{
                  backgroundColor: "rgba(233, 147, 134, .12)",
                  border: "1px solid rgba(233, 147, 134, .33)"
                }}
              >
                <Div display="row.flex-start.center">
                  <AddCircleIcon
                    mr={1}
                    style={{
                      transform: "rotate(45deg)"
                    }}
                  />
                  This form <Span fw={3}>&nbsp;is not accepting&nbsp;</Span> new
                  submissions
                </Div>
              </Div>
            )}
          </Div>
          {showIconColor && (
            <Div ml={4} mt={6}>
              <Div display="row.flex-start.center" mb={2}>
                <SmallToggle
                  active={showOnEventHome}
                  onClick={() => setShowOnEventHome(!showOnEventHome)}
                />
                <Text3 bold ml={2}>
                  Show on event home
                </Text3>
              </Div>
              <SelectColorIcon />
            </Div>
          )}
        </Div>
      </Div>
      <Div
        display="row.space-between.center"
        width={1}
        height={90}
        bt={1}
        bc="neutral2"
        bg="white"
        px={7}
        style={{ flexShrink: 0 }}
      >
        <SuperOutlineButton onClick={goBack}>Back</SuperOutlineButton>

        {saving ? (
          <SuperFilledButton ml={3} bg="altB5" RightIcon={LoadingIcon}>
            Saving
          </SuperFilledButton>
        ) : (
          <SuperFilledButton
            ml={3}
            bg="altB5"
            onClick={() => saveForm()}
            disabled={!saveBtnEnabled}
          >
            Create Form
          </SuperFilledButton>
        )}
      </Div>
    </Div>
  );
};

export default decorate(ReviewForm);
