import React from "react";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { PopMenuCard, PopMenu, ExpandIcon } from "components/Base";

import Circle from "ui-kit/Circle";

import Preview from "../Preview";

const decorate = CSSModules(css);

const ExpandButton = ({ fieldId, isReadOnly = false, small, options }) => {
  return (
    <PopMenu
      Label={({ onClick }) => (
        <Circle size={25} bg="white" onClick={onClick} ml={1}>
          <ExpandIcon />
        </Circle>
      )}
    >
      {() => (
        <PopMenuCard position="bottom" align="right">
          <Preview
            isReadOnly={isReadOnly}
            fieldId={fieldId}
            options={options}
            small={small}
          />
        </PopMenuCard>
      )}
    </PopMenu>
  );
};

export default decorate(ExpandButton);
