export const NAMESPACE = "ItemAssetReports";

export const INSTANCE_SUMMARY_T = "SummaryTable";
export const INSTANCE_ALL_ITEMS_T = "AllItemsTable";

export const SUMMARY_FIELDS = [
  {
    id: "name",
    type: "text",
    name: "Item"
  },
  {
    id: "requested",
    type: "text",
    name: "Requested"
  },
  {
    id: "pending", // this prop is not on the payload
    type: "text",
    name: "Pending 🕑"
  },
  {
    id: "rejected",
    type: "text",
    name: "Rejected 🚫"
  },
  {
    id: "approved",
    type: "text",
    name: "Approved ✅"
  }
];

export const SUMMARY_FIELDS_WIDTHS = {
  name: 250,
  requested: 140,
  pending: 140,
  rejected: 140,
  approved: 140
};

export const ALL_FIELDS = [
  {
    id: "contactName",
    type: "text",
    name: "Assigned To"
  },
  {
    id: "type",
    type: "text",
    name: "Item"
  },
  {
    id: "quantity",
    type: "text",
    name: "Quantity"
  },
  {
    id: "details",
    type: "text",
    name: "Details"
  },
  {
    id: "status",
    type: "order-status",
    name: "Status"
  }
];

export const ALL_FIELDS_WIDTHS = {
  contactName: 250,
  type: 200,
  quantity: 140,
  details: 200,
  status: 140
};
