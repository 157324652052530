import * as R from "ramda";
import Layout from "./Layout";
import { createContext } from "redux-mvc";
import module from "../index";

const decorate = createContext({
  module,
  options: {
    observedDomains: ["user", "event", "portal"],
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    persist: false
  },
  handlers: {}
});

export default decorate(Layout);
