import * as R from "ramda";
import { makeInstanceSelector } from "redux-mvc";
import { createSelector } from "reselect";

import { DEFAULT_APPROVAL_FIELD_APPROVE_REJECT } from "components/Event/FormsV2/constants";
import { DEFAULT_APPROVAL_FIELD_APPROVE_REJECT as DEFAULT_APPROVAL_FIELD_APPROVE_REJECT_V3 } from "components/Event/FormsV3/constants";
import getFormVersion from "components/Event/FormsV2/Utils/get-form-version";

import { user } from "redux/modules/user/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { eventDetails as getSubmissionEventDetails } from "redux/modules/formsV2/submission/selectors";

import {
  EMPTY_SUBMISSION,
  EMPTY_PREFERENCES,
  EMPTY_MENU
} from "Submission/Subform/constants";

import { getters as DatagridGetters } from "ui-kit/Datagrid";
import { MODES, BUTTONS } from "ui-kit/Datagrid/constants";

// this selectors are not ment to work with instances
const getFieldId = (_, props) => R.pathOr("", ["field", "id"], props);
const getKey = getFieldId;
const getSubmission = (_, props) => {
  return R.propOr(EMPTY_SUBMISSION, "submission", props);
};

const getForm = (_, props) => R.prop("form", getSubmission(_, props));
const getValues = (_, props) => R.prop("values", getSubmission(_, props));

export const getField = makeInstanceSelector(
  getForm,
  getFieldId,
  (form, fieldId) =>
    R.find(
      R.compose(
        R.equals(fieldId),
        R.prop("id")
      ),
      R.propOr([], "fields", form)
    ) || { subform: { form: EMPTY_SUBMISSION.form } }
)(getKey);

export const getFieldName = makeInstanceSelector(
  getField,
  R.pathOr("", ["subform", "form", "name"])
)(getKey);

const getSubformForm = makeInstanceSelector(
  getField,
  R.path(["subform", "form"])
)(getKey);

const getVersion = R.compose(
  getFormVersion,
  R.prop("form"),
  getSubmission
);

const getPreferences = (state, props) => {
  const submission = getSubmission(state, props);
  const field = getField(state, props);
  const preferences = R.path([
    "preferences",
    R.path(["subform", "form_id"])(field)
  ])(submission);
  return preferences || EMPTY_PREFERENCES;
};

const prependApproval = version => columns => {
  const approvalCol =
    version === 3
      ? DEFAULT_APPROVAL_FIELD_APPROVE_REJECT_V3
      : DEFAULT_APPROVAL_FIELD_APPROVE_REJECT;
  return R.prepend(
    approvalCol,
    R.filter(
      R.compose(
        R.not,
        R.equals(approvalCol.id),
        R.prop("id")
      ),
      columns
    )
  );
};

const getWidths = makeInstanceSelector(
  getSubformForm,
  getPreferences,
  (state, props) =>
    DatagridGetters.colWidths(state, {
      ...props,
      instanceId: getKey(state, props)
    }),
  (subformForm, preferences, colWidths) => {
    const defaultWidths = R.compose(
      R.reduce((widths, field) => {
        if (R.path(["settings", "width"])(field)) {
          widths[field.id] = field.settings.width;
        }
        return widths;
      }, {}),
      R.prop("fields")
    )(subformForm);

    return R.mergeAll([
      defaultWidths,
      R.propOr({}, "field_widths", preferences),
      colWidths
    ]);
  }
)(getKey);

export const getColumns = makeInstanceSelector(
  getSubformForm,
  getWidths,
  getVersion,
  (_, props) => R.prop("prependApproval", props),
  (form, widths, version, addApproval) =>
    R.compose(
      R.map(c => ({
        ...c,
        key: c.id,
        editable:
          c.type !== "static" && !R.pathOr(false, ["schema", "locked"], c),
        required: c.is_required || R.path(["settings", "required"])(c),
        description: R.path(["settings", "description"])(c),
        resizable: true,
        width: widths[c.id] || 200
      })),
      addApproval ? prependApproval(version) : R.identity,
      R.reduce(R.concat, []),
      R.map(
        R.compose(
          R.sortBy(R.prop("order")),
          R.propOr([], "fields")
        )
      ),
      R.sortBy(R.prop("order")),
      R.propOr([], "fields_grouped_by_section")
    )(form)
)(getKey);

export const getSectionByFieldId = makeInstanceSelector(
  getSubformForm,
  (_, props) => R.prop("fieldId", props),
  (subform, fieldId) =>
    R.filter(
      section =>
        R.any(field => field.id === fieldId, R.propOr([], "fields", section)),
      R.propOr([], "fields_grouped_by_section", subform)
    ) || {}
)(getKey);

export const getHeaders = makeInstanceSelector(
  getSubformForm,
  getWidths,
  (form, widths) =>
    R.compose(
      R.map(h => ({
        ...h,
        width: R.reduce(
          (acc, field) => acc + (widths[field.id] || 200),
          0,
          R.propOr([], "fields", h)
        )
      })),
      R.sortBy(R.prop("order")),
      R.propOr([], "fields_grouped_by_section")
    )(form)
)(getKey);

export const getEventDetailsToUse = createSelector(
  getEventDetails,
  getSubmissionEventDetails,
  (eventDetails, submissionEventDetails) => {
    return eventDetails && eventDetails.id
      ? eventDetails
      : submissionEventDetails;
  }
);

export const getMetaData = makeInstanceSelector(
  (_, { readOnly }) => readOnly,
  getColumns,
  getSubmission,
  getForm,
  getEventDetailsToUse,
  user,
  getVersion,
  getField,
  (
    readOnly,
    columns,
    submission,
    form,
    eventDetails,
    user,
    version,
    field
  ) => ({
    readOnly,
    orgId: "", // @TODO get actual value
    eventDetails,
    columns: R.indexBy(R.prop("id"), columns),
    references: {}, // @TODO get actual value
    form, // parent form
    field,
    submission,
    user,
    version
  })
)(getKey);

export const getSubmissions = makeInstanceSelector(
  getValues,
  getFieldId,
  getForm,
  getField,
  (state, props) =>
    props.mode ||
    DatagridGetters.mode(state, {
      ...props,
      instanceId: getKey(state, props)
    }),
  (values, fieldId, form, field, mode) => {
    // @NOTE: When in preview mode, the data is located in a different location
    if ([MODES.EDIT, MODES.PREVIEW].includes(mode)) {
      return R.compose(
        R.values,
        R.path(["subform", "form", "submissions"])
      )(field);
    }

    return R.values(R.pathOr([], [fieldId, "value", "submissions"], values));
  }
)(getKey);

export const getRows = makeInstanceSelector(
  getSubmissions,
  getMetaData,
  (submissions, meta) => {
    return R.map(
      r =>
        R.mergeAll([
          {
            id: r.id,
            key: r.id,
            meta
          },
          R.omit(["values"], r),
          R.indexBy(
            R.prop("columnId"),
            R.map(
              ([columnId, val]) => ({
                columnId,
                key: `${r.id}_${columnId}`,
                ...val
              }),
              Object.entries(r.values)
            )
          )
        ]),
      submissions
    );
  }
)(getKey);

export const getMenu = makeInstanceSelector(
  getFieldId,
  (state, props) =>
    props.mode ||
    DatagridGetters.mode(state, {
      ...props,
      instanceId: getKey(state, props)
    }),
  (_, { menuHandlers = {} }) => menuHandlers,
  (fieldId, mode, menuHandlers) =>
    mode === MODES.EDIT
      ? [
          {
            title: "Toggle Required",
            icon: "done",
            action: ({ columnId }) => {
              menuHandlers.toggleRequiredField({
                fieldId: columnId,
                subformFieldId: fieldId
              });
            }
          }
        ]
      : EMPTY_MENU
)(getKey);

export const getEnabledButtons = makeInstanceSelector(
  (state, props) =>
    props.mode ||
    DatagridGetters.mode(state, {
      ...props,
      instanceId: getKey(state, props)
    }),
  (state, props) => props.readOnly,
  (mode, readOnly) => {
    if (mode === MODES.PREVIEW) {
      return [];
    }
    if (mode === MODES.edit) {
      return [BUTTONS.ADD_ROW, BUTTONS.REMOVE_ROW, BUTTONS.DUPLICATE_ROW];
    }
    if (mode === MODES.SUBMISSION_MODAL) {
      return [
        BUTTONS.ADD_ROW,
        BUTTONS.REMOVE_ROW,
        BUTTONS.DUPLICATE_ROW,
        BUTTONS.REVIEW
      ];
    }
    if (mode === MODES.SUBMISSION) {
      return readOnly
        ? []
        : [BUTTONS.ADD_ROW, BUTTONS.REMOVE_ROW, BUTTONS.DUPLICATE_ROW];
    }
    return [];
  }
)(getKey);
