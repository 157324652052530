import { createSelector } from "reselect";

import * as R from "ramda";

import { getters } from "Portal/Settings/AddPageModal/model";
import * as FormSelectors from "ui-kit/Form/selectors";

import {
  STEPS,
  FIELD_IDS,
  FORM_ID
} from "Portal/Settings/AddPageModal/constants";
import { getters as FormGetters } from "ui-kit/Form/model";

export const getActiveIndex = createSelector(
  getters.activePage,
  activePage => {
    const activeIndex = R.findIndex(page => page === activePage, STEPS);

    return activeIndex === -1 ? 0 : activeIndex;
  }
);

export const getIsNextBtnDisabled = createSelector(
  state => FormGetters.fields(state, { instanceId: FORM_ID }),
  fieldsValue => {
    return (
      !R.path([FIELD_IDS.CUSTOM.PAGE_TITLE, "value"])(fieldsValue) ||
      !R.path([FIELD_IDS.CUSTOM.PAGE_CONTENT, "value"])(fieldsValue) ||
      !R.path([FIELD_IDS.CUSTOM.PAGE_TITLE, "value"])(fieldsValue).length ||
      !R.path([FIELD_IDS.CUSTOM.PAGE_CONTENT, "value"])(fieldsValue).length
    );
  }
);

export const getEditedField = R.compose(
  field => R.prop("value", field),
  FormSelectors.getField
);

export const getAllVariants = createSelector(
  getters.itemTypes,
  R.compose(
    R.flatten,
    R.map(R.prop("variants")),
    R.flatten,
    R.map(R.prop("items")),
    R.flatten,
    R.map(R.prop("groups"))
  )
);

export const getCountOfSelectedItems = createSelector(
  getters.settings,
  settings =>
    R.compose(
      R.length,
      R.keys,
      R.propOr({}, "variants")
    )(settings)
);

export const getSelectedItemsToShow = createSelector(
  getters.settings,
  settings => R.compose(R.propOr({}, "variants"))(settings)
);

export const getItemsToShow = createSelector(
  getters.itemTypes,
  itemTypes => {
    return R.compose(
      R.map(tab => ({
        id: tab.id,
        name: tab.name,
        items: R.map(
          item => ({
            id: item.id,
            name: item.name,
            items: R.compose(
              R.map(v => ({
                ...v,
                name: v.display_name
              })),
              R.flatten,
              R.map(R.prop("variants"))
            )(item.items)
          }),
          tab.groups
        )
      }))
    )(itemTypes);
  }
);
