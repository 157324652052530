import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Popover from "@lennd/material-ui/Popover";

@CSSModules(css)
class AddBudget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null
    };
  }

  @autobind
  handleOpen(event) {
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget
    });
  }

  @autobind
  handleRequestClose() {
    this.setState({
      open: false
    });
  }

  @autobind
  handleSubmit() {
    this.props.updateBudget(this.input.value, this.props.id);
    this.handleRequestClose();
  }

  @autobind
  clearForm() {
    this.input.value = 0;
  }

  @autobind
  select() {
    // ensure ref has been assigned before attempting to select
    if (this.input) {
      this.input.select();
    }
  }

  render() {
    const { backgroundColor, counts, id, value, canUpdateBudget } = this.props;

    return (
      <div>
        {counts.budget && counts.budget > 0 ? (
          <div
            styleName={canUpdateBudget ? "actionItemNumber" : undefined}
            onClick={canUpdateBudget ? this.handleOpen : undefined}
          >
            {counts.budget}
            <i className="material-icons" styleName="icon">
              mode_edit
            </i>
          </div>
        ) : canUpdateBudget ? (
          <div styleName="actionItemAdd" onClick={this.handleOpen}>
            + Add
          </div>
        ) : (
          "-"
        )}

        <Popover
          anchorOrigin={{
            horizontal: "left",
            vertical: "top"
          }}
          transformOrigin={{
            horizontal: "left",
            vertical: "top"
          }}
          open={this.state.open}
          canAutoPosition
          onClose={this.handleRequestClose}
          anchorEl={this.state.anchorEl}
        >
          <div styleName="popover">
            <div styleName="titleWrapper">
              <div
                styleName="color"
                style={{
                  backgroundColor: backgroundColor || "lightgrey",
                  border:
                    backgroundColor === "#FFFFFF" ? "1px solid #E9E9E9" : "none"
                }}
              />
              {value}
            </div>
            <div styleName="inputWrapper">
              <input
                autoFocus
                ref={input => (this.input = input)}
                onFocus={this.select}
                type="number"
                defaultValue={counts.budget}
                styleName="input"
                min="0"
                onKeyDown={e =>
                  e.keyCode === 13 ? this.handleSubmit() : undefined
                }
              />
              <div styleName="clearable" onClick={this.clearForm}>
                <i className="material-icons" styleName="icon">
                  close
                </i>
              </div>
            </div>
            <div styleName="actionItems">
              <div styleName="done" onClick={this.handleSubmit}>
                Done
              </div>
              <div styleName="cancel" onClick={this.handleRequestClose}>
                Cancel
              </div>
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}

AddBudget.propTypes = {
  updateBudget: PropTypes.func.isRequired
};

export default AddBudget;
