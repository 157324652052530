import React from "react";
import { actions } from "Modules/UploadDocumentTemplateModal";
import { connect } from "react-redux";
import { Div, BigFilledButton, BigOutlineButton } from "components/Base";
import {
  getIsValid,
  uploadBtnText
} from "Modules/UploadDocumentTemplateModal/selectors";

const decorate = connect(
  state => ({
    isValid: getIsValid(state),
    btnText: uploadBtnText(state)
  }),
  {
    saveDocumentTemplate: actions.saveDocumentTemplate
  }
);

const Footer = ({
  hideModal,
  onDone,
  saveDocumentTemplate,
  isValid,
  btnText
}) => (
  <Div
    width={1}
    height={70}
    bt={1}
    bc="neutral1"
    display="row.flex-start.center"
    px={5}
    bg="white"
  >
    <BigFilledButton
      bg="altB5"
      onClick={() => saveDocumentTemplate({ onDone })}
      disabled={!isValid}
    >
      {btnText}
    </BigFilledButton>
    <BigOutlineButton ml={2} onClick={hideModal}>
      Cancel
    </BigOutlineButton>
  </Div>
);

export default decorate(Footer);
