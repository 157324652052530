import { createModule } from "redux-mvc";
import model from "./model";

import formModule from "ui-kit/Form";

const module = createModule({
  ...model,
  observedDomains: ["event", "organization", "Data"]
});

module.plugModule(formModule);

export default module;
