import React, { Fragment } from "react";
import { connect } from "react-redux";

import {
  getShowAcceptPayment,
  getShowTransactions
} from "Orders/OrderModal/selectors";

import Invoice from "Orders/OrderModal/View/Invoice";
import EmailConfirmation from "Orders/OrderModal/View/EmailConfirmation";
import EmailInvoice from "Orders/OrderModal/View/EmailInvoice";
import AcceptPayment from "Orders/OrderModal/View/AcceptPayment";
import Transactions from "Orders/OrderModal/View/Transactions";
import Passes from "Orders/OrderModal/View/Passes";
import OrderStatusbar from "Orders/OrderModal/View/OrderStatusbar";
import OrderFields from "Orders/OrderModal/View/OrderFields";
import Integrations from "Orders/OrderModal/View/Integrations";

const decorate = connect(state => ({
  showAcceptPayment: getShowAcceptPayment(state),
  showTransactions: getShowTransactions(state)
}));

const SummaryView = ({
  handlers = {},
  showAcceptPayment,
  showTransactions
}) => (
  <Fragment>
    <OrderStatusbar handlers={handlers} />
    <Passes handlers={handlers} />
    <OrderFields handlers={handlers} />
    {showAcceptPayment ? <Invoice /> : null}
    {showAcceptPayment ? <AcceptPayment handlers={handlers} /> : null}
    {showAcceptPayment ? (
      <EmailInvoice handlers={handlers} />
    ) : (
      <EmailConfirmation handlers={handlers} />
    )}
    <Integrations handlers={handlers} />
    {showTransactions ? <Transactions handlers={handlers} /> : null}
  </Fragment>
);

export default decorate(SummaryView);
