import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AssignOwners from "./AssignOwners";

import { orgDetails } from "redux/modules/organization/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import * as ModuleRecordOwnersSelectors from "redux/modules/modules/owners/selectors";
import * as EventUserSelectors from "redux/modules/eventUsers/selectors";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { getUsers } from "redux/modules/eventUsers/actions";
import {
  getOwners,
  addOwners,
  deleteOwners
} from "redux/modules/modules/owners/actions";

function mapStateToProps(state, props) {
  return {
    orgDetails: orgDetails(state),
    eventDetails: eventDetails(state),
    isFetchingOwners: ModuleRecordOwnersSelectors.isFetching(
      state,
      props.moduleId
    ),
    isFetchingUsers: EventUserSelectors.isFetching(state),
    owners: ModuleRecordOwnersSelectors.owners(state, props.moduleId).reduce(
      (a, b) => {
        a[b.user_id] = b.user;
        return a;
      },
      {}
    ),
    users: EventUserSelectors.users(state)
      .filter(u => u.is_event_user)
      .map(u => ({
        ...u,
        search: `${u.user_fname} ${u.user_lname}`.toLowerCase()
      }))
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSnackbar,
      getUsers,
      getOwners,
      addOwners,
      deleteOwners
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssignOwners);
