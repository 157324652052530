import React from "react";
import { connect } from "react-redux";
import { actions } from "../model";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import Body from "./Body";
import Footer from "./Footer";

const decorate = connect(null, {
  hideModal: actions.hideModal
});

const Layout = ({ hideModal, width = 743, height = 700 }) => {
  return (
    <MiniModalWrapper
      title="Select fields"
      hideModal={hideModal}
      width={width}
      height={height}
      style={{ height: "700px" }}
      bg="neutral0"
      showModal
      isPortal={false}
      stopPropagation
    >
      <Body />
      <Footer />
    </MiniModalWrapper>
  );
};

export default decorate(Layout);
