const INTEGRATIONS = {
  eventbrite: {
    id: "9e2f89eb-0161-48d8-9529-f8bf2ad666d8",
    name: "Eventbrite",
    slug: "eventbrite",
    image_url:
      "//d2dks4tzxs6xee.cloudfront.net/img/integration-eventbrite-small.png"
  },
  frontgate: {
    id: "119ea5b5-7f1e-44f7-82a4-f12953d65332",
    name: "Front Gate",
    slug: "frontgate",
    image_url:
      "//d2dks4tzxs6xee.cloudfront.net/img/integration-frontgate-small.png"
  },
  gingerbreadshed: {
    id: "a8168bdc-466d-4743-bde2-df7c4d9a3750",
    name: "Elevate Tickets",
    slug: "gingerbreadshed",
    image_url:
      "//d2dks4tzxs6xee.cloudfront.net/img/integration-elevate-small.png"
  },
  intellitix: {
    id: "fff97f9a-8a11-4f68-a0d1-a8afee651876",
    name: "Intellitix",
    slug: "intellitix",
    image_url:
      "//d2dks4tzxs6xee.cloudfront.net/img/integration-intellitix-small.png"
  },
  glownet: {
    id: "b6d7ab89-20d2-4639-916a-49dffbf18779",
    name: "Glownet",
    slug: "glownet",
    image_url:
      "//d2dks4tzxs6xee.cloudfront.net/img/integration-glownet-small.png"
  },
  token: {
    id: "b83b14c1-5215-4ec7-bd2e-288cf1b683e9",
    name: "Token",
    slug: "token",
    image_url: "//d2dks4tzxs6xee.cloudfront.net/img/integration-tkn-small.png"
  }
};

const INTEGRATIONS_BY_ID = Object.keys(INTEGRATIONS).reduce((map, key) => {
  map[INTEGRATIONS[key].id] = INTEGRATIONS[key];
  return map;
}, {});

export { INTEGRATIONS, INTEGRATIONS_BY_ID };
