export default variant => {
  if (!variant || !variant.item || !variant.values) return null;

  const options = variant.item.options
    .filter(o => !o.is_default)
    .sort((a, b) => a.order - b.order);
  if (!options.length) return null;

  const optionsMap = options.reduce((a, b) => {
    a[b.id] = b;
    return a;
  }, {});

  const values = variant.values.reduce((a, b) => {
    if (b && b.value) {
      a[b.option_id] = b;
    }
    return a;
  }, {});

  const optionIds = Object.keys(optionsMap).filter(
    optionId => values[optionId]
  );

  // @NOTE: this is a short circuit due to updates around how
  // we are moving away from item options + values
  if (!optionIds || !optionIds.length) {
    return variant.display_name;
  }

  return optionIds.map(optionId => values[optionId].value).join(" - ");
};
