import Helpers from "utils/Global/Helpers";
import getQuery from "redux/modules/modules/utils/get-query";

module.exports = {
  get: (credentials, reportId, options) =>
    new Promise((resolve, reject) => {
      const query = getQuery(options);

      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/reports/${reportId}${query ||
          ""}`,
        credentials,
        success: resolve,
        error: reject
      });
    })
};
