import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Search = ({ onChange, query }) => (
  <div styleName="container">
    <i className="material-icons">search</i>
    <input
      placeholder="Search..."
      type="text"
      styleName="input"
      onChange={onChange}
      value={query}
    />
  </div>
);

Search.propTypes = {
  onChange: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired
};

export default CSSModules(Search, css);
