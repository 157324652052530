import PropTypes from "prop-types";
import React from "react";
import { get } from "lodash";
import SelectBase from "../SelectBase";
import Formatter from "../../Table3/CellFormatters/EventDays";
import Editor from "../../Editors/EventDaysEditor";

const EventDaysSelect = ({
  endDate,
  startDate,
  dayGroups,
  disabled,
  onChange,
  value,
  rowMetaData
}) => (
  <SelectBase
    formatter={
      get(value, "value.length") ? (
        <Formatter dependentValues={rowMetaData} value={value} />
      ) : null
    }
    editor={
      <Editor
        disabled={disabled}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        dayGroups={dayGroups}
        value={value}
      />
    }
  />
);

EventDaysSelect.propTypes = {
  value: PropTypes.object,
  rowMetaData: PropTypes.object.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  dayGroups: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default EventDaysSelect;
