export const NAMESPACE = "HubPeople";

export const FILTER_IDS = {
  ALL: "view_all",
  WiTH_ITEMS: "has_items",
  NO_ITEMS: "not_items"
};

export const AVAILABLE_SECTIONS = {
  DETAILS: "details",
  DOCUMENTS: "documents",
  FORMS: "forms",
  REQUESTS: "requests"
};

export const SUMMARY_TAB_IDS = {
  DUE_DATES: "all_due_dates",
  SUMMARY: "requests_summary"
};

export const CARD_ACTIONS = {
  VIEW_PEOPLE_REPORT: "cardViewPeopleReport",
  SUBMIT_DOCUMENT: "cardSubmitDocument",
  SUBMIT_FORM: "cardSubmitForm",
  VIEW_FILE: "cardViewFile",
  DOWNLOAD_FILE: "cardDownloadFile"
};
