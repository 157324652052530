import { REQUEST, RECEIVE } from "./constants";
import * as selectors from "./selectors";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getEventPermissionGroups(eventId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const { payload } = await api.get(
        getState().user.user.credentials,
        eventId
      );
      dispatch({ type: RECEIVE, payload });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching event permission groups"
          }
        ])
      );
    }
  };
}

export function getOrganizationPermissionGroups(orgId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const { payload } = await api.getOrganization(
        getState().user.user.credentials,
        orgId
      );
      dispatch({ type: RECEIVE, payload });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching organization permission groups"
          }
        ])
      );
    }
  };
}
