import PropTypes from "prop-types";
import React, { Component } from "react";
import DefaultWrapper from "components/Global/Modal/Wrappers/Purple";

class Modal extends Component {
  render() {
    return (
      <div>
        {this.props.modals.map((modal, index) => {
          const Wrapper = modal.wrapper ? modal.wrapper : DefaultWrapper;
          return (
            <div
              key={index}
              onMouseDown={
                modal.disableClickToClose
                  ? undefined
                  : modal.content.props.hideModal || this.props.hideModal
              }
              style={{ zIndex: 100 + index }}
            >
              <Wrapper modal={modal}>
                {React.cloneElement(modal.content, {
                  dispatch: this.props.dispatch,
                  hideModal:
                    modal.content.props.hideModal || this.props.hideModal,
                  router: this.props.router,
                  params: this.props.params,
                  showModal: this.props.showModal,
                  showSnackbar: this.props.showSnackbar
                })}
              </Wrapper>
            </div>
          );
        })}
      </div>
    );
  }
}

Modal.propTypes = {
  showSnackbar: PropTypes.func,
  showModal: PropTypes.func,
  dispatch: PropTypes.func,
  params: PropTypes.object,
  router: PropTypes.object,
  hideModal: PropTypes.func.isRequired,
  modals: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.object,
      wrapper: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
      disableClickToClose: PropTypes.bool
    })
  ).isRequired
};

export default Modal;
