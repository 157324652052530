import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import CanUserDo from "components/Global/Security/CanUserDo";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import Base from "../Base";
import SendEmail from "SendEmailModal/View";
import BulkLoginDetails from "components/Event/Accounts/Account/People/BulkLoginDetailsModal";
import ChangeRecordType from "components/Global/CRM/Modals/ChangeRecordType";
import BulkEdit from "components/Global/CRM/Modals/BulkEdit";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { addS } from "utils/General";

import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";
import { updateType } from "redux/modules/modules/records/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";

import { recordTypes } from "redux/modules/modules/recordTypes/selectors";

const decorate = connect(
  state => ({
    recordTypes: recordTypes(state, STANDARD_MODULE_IDS.contacts.id)
  }),
  {
    getRecordTypes,
    updateType,
    showSnackbar
  }
);

class DataTableActionsBar extends Base {
  componentDidMount() {
    this.props.getRecordTypes({
      moduleId: STANDARD_MODULE_IDS.contacts.id,
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId
      }
    });
  }

  sendEmailModal = async () => {
    this.props.showModal({
      content: (
        <SendEmail
          moduleId={STANDARD_MODULE_IDS.contacts.id}
          records={this.props.selectedRows}
          onDone={() => {}}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  giveLoginAccessEmail = async () => {
    this.props.showModal({
      content: (
        <BulkLoginDetails
          contacts={this.props.contactsAndAccounts}
          onDone={() => {
            this.props.fetchRecords();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  shareLoginDetails = async () => {
    this.props.showModal({
      content: (
        <BulkLoginDetails
          shareOnly
          contacts={this.props.contactsAndAccounts}
          onDone={() => {}}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showEditRecordsModal = () => {
    const { showModal, selectedRows } = this.props;
    showModal({
      content: (
        <BulkEdit
          onSave={this.editRecords}
          selected={selectedRows}
          getMetaData={this.getRowMetaData}
          columns={[]}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  changeRecordTypes = () => {
    const { showModal, hideModal, recordTypes } = this.props;
    showModal({
      content: (
        <ChangeRecordType
          update={this.updateRecordType}
          types={recordTypes}
          hideModal={hideModal}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  updateRecordType = async ({ type }) => {
    const {
      selectedRows,
      updateType,
      params,
      deselectAllRows,
      showSnackbar
    } = this.props;

    await Promise.all(
      selectedRows.map(recordId =>
        updateType({
          moduleId: STANDARD_MODULE_IDS.contacts.id,
          recordId,
          type,
          options: {
            orgId: params.orgId,
            eventId: params.eventId
          }
        })
      )
    );
    await this.props.fetchRecords();
    deselectAllRows();
    showSnackbar({
      message: `${selectedRows.length} contact${addS(
        selectedRows.length
      )} updated`,
      action: "OK"
    });
  };

  render() {
    const { selectedRows, moduleId, deselectAllRows } = this.props;
    const selectedRowsCount = selectedRows.length;
    return (
      <div styleName="container">
        <div styleName="count">{selectedRowsCount} selected</div>
        <CanUserDo action={`${moduleId}_update`} className={css.candoWrapper}>
          {/*
          // @TODO: Allow bulk editing of: Role & "Is primary contact?"
          <div role="button" tabIndex={0} styleName="menuItem" onClick={this.showEditRecordsModal}>
            <i className="material-icons">edit</i>
            Edit
          </div>
          */}
          <div
            role="button"
            tabIndex={0}
            styleName="menuItem"
            onClick={this.sendEmailModal}
          >
            Send Email
            {selectedRowsCount !== 1 ? "s" : ""}
          </div>
          <div
            role="button"
            tabIndex={0}
            styleName="menuItem"
            onClick={this.giveLoginAccessEmail}
          >
            Give login access
          </div>
          <div
            role="button"
            tabIndex={0}
            styleName="menuItem"
            onClick={this.shareLoginDetails}
          >
            Share login details
          </div>
          <div
            role="button"
            tabIndex={0}
            styleName="menuItem"
            onClick={this.changeRecordTypes}
          >
            Change Type
            {selectedRowsCount !== 1 ? "s" : ""}
          </div>
        </CanUserDo>
        <div
          role="button"
          tabIndex={0}
          styleName="menuTextButton"
          onClick={deselectAllRows}
        >
          Clear selected
        </div>
      </div>
    );
  }
}

DataTableActionsBar.propTypes = {
  deselectAllRows: PropTypes.func.isRequired,
  moduleId: PropTypes.string,
  selectedRows: PropTypes.arrayOf(PropTypes.string).isRequired,
  contactsAndAccounts: PropTypes.arrayOf(
    PropTypes.shape({
      accountId: PropTypes.string,
      contactId: PropTypes.string
    })
  )
};

export default decorate(CSSModules(DataTableActionsBar, css));
