import * as R from "ramda";
import { matchModules } from "utils/match-id-name-actions";
import {
  SCHEDULES,
  CONTACTS,
  ACCOUNTS,
  LOCATIONS,
  LOCATION_AREAS,
  CATALOG_ITEMS,
  CATALOG_CATEGORIES
} from "utils/standard-module-field-ids";

export default matchModules({
  contacts: rec =>
    R.path(["values", CONTACTS.FIRST_NAME, "value"])(rec)
      ? [
          R.pathOr("", ["values", CONTACTS.FIRST_NAME, "value"])(rec),
          R.pathOr("", ["values", CONTACTS.LAST_NAME, "value"])(rec)
        ].join(" ")
      : R.pathOr("", ["values", CONTACTS.EMAIL, "value"])(rec),
  accounts: R.pathOr("", ["values", ACCOUNTS.NAME, "value"]),
  schedules: R.pathOr("", ["values", SCHEDULES.NAME, "value"]),
  locations: R.pathOr("", ["values", LOCATIONS.NAME, "value"]),
  locationAreas: R.pathOr("", ["values", LOCATION_AREAS.NAME, "value"]),
  catalogItems: R.pathOr("", ["values", CATALOG_ITEMS.ITEM_NAME, "value"]),
  catalogCategories: R.pathOr("", ["values", CATALOG_CATEGORIES.NAME, "value"]),
  orders: rec => `${rec.record_prefix}-${rec.friendly_id}`,
  default: rec => `${rec.record_prefix}-${rec.friendly_id}`
});
