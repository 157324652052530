export const NAMESPACE = "EventLightContent";

export const TABLE_INSTANCE_ID = "EventLightContentTable";

export const FORM_ID = "EventLightContent";

export const FIELD_IDS = {
  TITLE: "title",
  THUMBNAIL_URL: "video_thumbnail_url",
  URL: "video_url",
  VISIBLE: "visible",
  ORDER: "order",
  LABEL: "label"
};
