import * as R from "ramda";
import { createSelector } from "reselect";

import { TAB_IDS } from "./constants";

import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { getters } from "./model";

import * as TableSelectors from "ui-kit/Table/selectors";

import { dateRange } from "utils/General";

export const getTabs = R.always([
  { id: TAB_IDS.MEALS, title: "Meals" },
  { id: TAB_IDS.APPROVERS, title: "Approval Workflows" },
  { id: TAB_IDS.MANAGER_PASSWORD, title: "Manager Override Code" }
]);

export const getSelectedDays = createSelector(
  getters.selectedDays,
  R.sortBy(R.identity)
);

export const getDateGroupsById = createSelector(
  R.compose(
    R.propOr([], "date_groups"),
    getEventDetails
  ),
  R.indexBy(R.prop("id"))
);

export const getDayMap = createSelector(
  getEventDetails,
  getSelectedDays,
  (eventDetails, selectedDays) => {
    const start = eventDetails.date_from;
    const end = eventDetails.date_to;
    const dayGroups = eventDetails.date_groups;

    const groups = R.reduce(
      (groups, group) => ({
        ...groups,
        ...R.reduce(
          (days, day) => ({
            ...days,
            [day]: { groupId: group.id, color: group.color }
          }),
          {},
          group.days
        )
      }),
      {},
      dayGroups
    );

    const range = dateRange({ start, end });

    return R.reduce(
      (dayMap, day) => ({
        ...dayMap,
        [day]: {
          color: "#333333",
          ...groups[day],
          isSelected: R.contains(day, selectedDays)
        }
      }),
      {},
      range
    );
  }
);

const getOrderedMealItems = createSelector(
  getters.mealItems,
  getters.mealItemsOrder,
  (mealItems, order) =>
    R.filter(R.identity, R.map(R.prop(R.__, mealItems), order)) //eslint-disable-line no-underscore-dangle
);

const map = R.addIndex(R.map);

export const getMeals = createSelector(
  getOrderedMealItems,
  items =>
    map(
      (item, order) => ({
        ...item,
        value: item.name,
        order,
        color:
          item.background_color && item.background_color.length
            ? item.background_color
            : null,
        first: order === 0,
        last: order === items.length - 1
      }),
      items
    )
);

export const getMealDays = createSelector(
  getEventDetails,
  getters.mealDaysDiff,
  (eventDetails, mealDaysDiff) =>
    R.symmetricDifferenceWith(
      (a, b) => a.mealId === b.mealId && a.dayId === b.dayId,
      mealDaysDiff,
      R.pathOr([], ["module_settings", "catering", "meal_days"], eventDetails)
    )
);

export const getDietaryRestrictions = createSelector(
  getters.removedDietaryRestrictions,
  getters.updatedDietaryRestrictions,
  R.compose(
    R.pathOr([], ["module_settings", "catering", "dietary_restrictions"]),
    getEventDetails
  ),
  (removed, updated, restrictions) =>
    R.compose(
      R.map(restriction =>
        updated[restriction.id]
          ? {
              ...restriction,
              ...updated[restriction.id]
            }
          : restriction
      ),
      R.filter(restriction => !R.contains(restriction.id, removed)),
      R.uniqBy(R.prop("id"))
    )(R.concat(restrictions, R.values(updated)))
);

export const getItemBlocksCount = createSelector(
  TableSelectors.getRowsIndex,
  R.length
);

export const getManagerOverrideIsActive = createSelector(
  getEventDetails,
  R.path(["module_settings", "catering", "manager_override_is_active"])
);
