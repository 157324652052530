import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import resolveFormatter from "components/Global/Table3/CellFormatters/utils/resolveFormatter";

const FieldEntry = ({ label, field, value, meta }) => {
  const Formatter = resolveFormatter(field);
  return (
    <div styleName="field">
      <div styleName="fieldLabel">{label}</div>
      <div styleName="fieldValue">
        <Formatter value={value} dependentValues={meta} />
      </div>
    </div>
  );
};

FieldEntry.propTypes = {
  field: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  meta: PropTypes.object.isRequired,
  value: PropTypes.object.isRequired
};

export default CSSModules(FieldEntry, css);
