import React from "react";

import { HEIGHTS } from "../constants";

import { Div, CloseIcon } from "components/Base";

const Header = ({ hideModal }) => (
  <Div
    width={1}
    height={HEIGHTS.HEADER}
    display="row.space-between.center"
    px={4}
    bg="primary8"
  >
    <Div fs={4} fw={3} color="white">
      Select related modules to add
    </Div>
    <CloseIcon color="white" size={24} onClick={hideModal} />
  </Div>
);

export default Header;
