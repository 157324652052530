import React from "react";
import { Div, RightArrowIcon } from "components/Base";

const Direction = ({ a, b }) => (
  <Div
    display="row.flex-start.center"
    fs={1}
    p={1}
    bra={1}
    bg="neutral0"
    color="gray6"
  >
    {a}
    <RightArrowIcon size={12} mx={1} style={{ color: "#c6b8d4" }} />
    {b}
  </Div>
);

const getDirectionComponent = dir => {
  switch (dir) {
    case "ASC":
      return <Direction a="A" b="Z" />;
    case "DESC":
      return <Direction a="Z" b="A" />;
    default:
      return null;
  }
};

const SortItem = ({ name, direction, removeIcon }) => (
  <Div display="row.space-between.center" mb={2}>
    <Div fw={3} fs={2} color="gray7" title={name} mr={2} truncate>
      {name}
    </Div>
    <Div display="row.flex-end.center">
      <Div uppercase fs={1} color="neutral0" mr={2}>
        {getDirectionComponent(direction)}
      </Div>
      {removeIcon}
    </Div>
  </Div>
);

export default SortItem;
