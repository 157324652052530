import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const PortalAccountIcon = ({ name, color, size, fontSize }) => (
  <div
    styleName="container"
    style={{
      height: size,
      width: size,
      backgroundColor: color
    }}
    title={name}
  >
    <div
      styleName="initials"
      style={{
        fontSize: fontSize || Math.floor(size * 0.6)
      }}
    >
      {name ? name[0] : ""}
    </div>
  </div>
);

PortalAccountIcon.defaultProps = {
  size: 36
};

PortalAccountIcon.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.number,
  fontSize: PropTypes.number
};

export default CSSModules(PortalAccountIcon, css);
