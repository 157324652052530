export const TAB_IDS = {
  MANAGE: "manage",
  SETTINGS: "settings",
  //
  FIELDS: "fields",
  TYPES: "types",
  PORTAL: "portal",
  GUEST_LIST: "guest_list",
  LAYOUT: "layout",
  REPORT_TEMPLATES: "report_templates",
  DOCUMENT_TEMPLATES: "document_templates",
  EMAIL_TEMPLATES: "email_templates"
};
