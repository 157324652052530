import PropTypes from "prop-types";
import React, { Component } from "react";
import NeutralButton from "components/Global/Module/Buttons/NeutralButton";
import ActionButton from "components/Global/Module/Buttons/ActionButton";
import ActionsButtonMenu from "components/Global/CRM/TableViews/ListView/ActionsButtonMenu";
import resolveActionsMenu from "../../utils/resolveActionsMenu";
import CSSModules from "react-css-modules";
import css from "../styles.scss";

@CSSModules(css)
class FileActions extends Component {
  render() {
    if (!this.props.meta.module) return null;
    return (
      <div styleName="actionButtons">
        <div styleName="buttonWrapper">
          <ActionsButtonMenu menu={resolveActionsMenu(this.props)}>
            <NeutralButton
              label={
                <span className={css.buttonLabel}>
                  Options <i className="material-icons">arrow_drop_down</i>
                </span>
              }
            />
          </ActionsButtonMenu>
        </div>
        <ActionButton
          label="Upload File"
          handleClick={this.props.meta.showAddRecordModal}
        />
      </div>
    );
  }
}

FileActions.propTypes = {
  meta: PropTypes.object.isRequired
};

export default FileActions;
