import React from "react";

import { Div } from "components/Base";

export const Box = ({ children, ...props }) => {
  return (
    <Div
      py={2}
      bg="neutral1"
      bra={1}
      mx={1}
      px={2}
      py={1}
      display="row.space-between.center"
      style={{ cursor: "pointer" }}
      {...props}
    >
      {children}
    </Div>
  );
};
