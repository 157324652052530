export const NAMESPACE = "EventLightDashboard";
export const TABLE_INSTANCE_ID = "EventLightRecentAttendeesTable";
export const STEPS = {
  SETUP_EVENT_DETAILS: "setup_event_details",
  ADD_TICKETS: "add_tickets",
  CONFIGURE_REGISTRATION_PAGE: "configure_registration_page",
  SETUP_VENUE: "setup_venue",
  PREVIEW_PUBLISH: "preview_publish",

  CUSTOMIZE_SESSIONS_FORM: "customize_sessions_form",
  CUSTOMIZE_SPEAKER_PORTAL: "customize_speaker_portal",
  MANAGE_SUBMISSIONS: "manage_submissions",
  MANAGE_AGENDA: "manage_agenda",
  VIEW_SPEAKERS: "view_speakers",

  CUSTOMIZE_EXHIBITOR_INTAKE_FORM: "customize_exhibitor_intake_form",
  CUSTOMIZE_EXHIBITOR_PORTAL: "customize_exhibitor_portal",
  SEND_EXHIBITOR_PORTAL_INVITES: "send_exhibitor_portal_invites",
  VIEW_EXHIBITORS: "view_exhibitors",

  CUSTOMIZE_SPONSOR_INTAKE_FORM: "customize_sponsor_intake_form",
  CUSTOMIZE_SPONSOR_PORTAL: "customize_sponsor_portal",
  SEND_SPONSOR_PORTAL_INVITES: "send_sponsor_portal_invites",
  VIEW_SPONSORS: "view_sponsors"
};
