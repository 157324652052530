import React, { useState } from "react";
import * as R from "ramda";
import passwordValidator from "password-validator";

export const basePassRules = {
  minLength: 8,
  maxLength: 100,
  uppercase: true,
  lowercase: true,
  numbers: true,
  symbols: true
};

export const addRulesToSchema = (customRules = {}) => {
  const schema = new passwordValidator();
  const rules = { ...basePassRules, ...customRules };
  if (rules.minLength) {
    schema.is().min(rules.minLength);
  }
  if (rules.maxLength) {
    schema.is().max(rules.maxLength);
  }
  if (rules.uppercase) {
    schema.has().uppercase();
  }
  if (rules.lowercase) {
    schema.has().lowercase();
  }
  if (rules.numbers) {
    schema.has().digits(2);
  }
  if (rules.symbols) {
    schema.has().symbols(1);
  }
  return schema;
};

const valid = schema => (value = "") => {
  const otherThanAsteriscs = [...value].filter(c => c !== "*");
  let messages = [];
  if (!R.isEmpty(otherThanAsteriscs)) {
    if (!schema.validate(value)) {
      messages = schema.validate(value, { details: true });
    }
  }
  return messages;
};

export const passwordValidatorHelper = (customRules = {}) => {
  const schema = addRulesToSchema(customRules);
  return valid(schema);
};

export const usePasswordValidator = (customRules = {}) => {
  const [schemaMessages, setSchemaMessages] = useState([]);

  const schema = addRulesToSchema(customRules);

  const validate = (value = "") => {
    setSchemaMessages(valid(schema)(value));
  };

  const checkMessages = R.compose(
    R.not,
    R.either(R.isEmpty, R.isNil)
  );

  const messages = checkMessages(schemaMessages)
    ? schemaMessages.map(({ message }, index) => (
        <div
          key={`message-${index}`}
          style={{
            fontSize: "13px",
            marginTop: "5px",
            color: "red"
          }}
        >
          {message}
        </div>
      ))
    : null;

  return [validate, messages, schemaMessages];
};
