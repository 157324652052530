import React from "react";
import { Div, CloseIcon } from "components/Base";

const Header = ({ hideModal, heading = "", typeName = "" }) => (
  <Div width={1} p={5} bg="primary8" display="row.space-between">
    <Div display="row.flex-start.center">
      <Div fs={5} color="white">
        {heading}
      </Div>
      <Div ml={1} fs={5} color="white" style={{ opacity: 0.5 }}>
        ({typeName})
      </Div>
    </Div>
    <CloseIcon size={30} color="white" onClick={hideModal} />
  </Div>
);

export default Header;
