import React from "react";

import { getValue } from "../../utils";

const Text = props => {
  const value = getValue(props);
  return (
    <div
      style={{
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }}
    >
      {["number", "string"].includes(typeof value) ? value : " - "}
    </div>
  );
};

export default Text;
