import PropTypes from "prop-types";
import React, { Component } from "react";
import Fuse from "fuse.js";

import Rows from "components/Global/Headers/EventsDropdown/Rows";

import { connect } from "react-redux";
import { toggleStarredEvent } from "redux/modules/events/actions";
import * as EventsSelectors from "redux/modules/events/selectors";
import { orgDetails } from "redux/modules/organization/selectors";

import {
  Div,
  SearchIcon,
  BigShadedInput,
  DownFilledIcon,
  Popover
} from "components/Base";

const mapStateToProps = state => {
  const orgDetailsToPass = orgDetails(state);
  const eventsToPass = EventsSelectors.events(state).filter(
    e => e.org_id === orgDetailsToPass.id
  );

  return {
    orgDetails: orgDetailsToPass,
    myEvents: eventsToPass
  };
};

const mapDispatchToProps = {
  toggleStarredEvent
};

const decorate = connect(
  mapStateToProps,
  mapDispatchToProps
);

const Picker = ({
  onSearchChange,
  onSelectEvent,
  onStarEvent,
  selectedEventId,
  starredEvents,
  orgName,
  events,
  filter
}) => {
  return (
    <Div bg="white" width={350} bra={2}>
      <BigShadedInput
        display={"row.center.center"}
        continuous
        placeholder="Search events..."
        LeftIcon={SearchIcon}
        onChange={onSearchChange}
        bg="neutral0"
        value={filter}
      />
      <Div
        display="column"
        width={1}
        height={225}
        style={{
          overflowY: "auto"
        }}
      >
        {starredEvents.length ? (
          <Rows
            activeEventId={selectedEventId}
            events={starredEvents}
            goToEvent={onSelectEvent}
            title="Starred Events"
            toggleStarredEvent={onStarEvent}
          />
        ) : null}
        <Rows
          activeEventId={selectedEventId}
          events={events}
          goToEvent={onSelectEvent}
          title={orgName}
          toggleStarredEvent={onStarEvent}
        />
      </Div>
    </Div>
  );
};

class EventsDropdown extends Component {
  state = { filter: "" };

  searchEvents(events) {
    if (this.state.filter.length) {
      const fuse = new Fuse(events, {
        threshold: 0.3,
        keys: ["name"]
      });
      return fuse.search(this.state.filter);
    }
    return events;
  }

  handleChange = filter => {
    this.setState({
      filter
    });
  };

  toggleStarredEvent = ({ eventId, starred }, e) => {
    e.stopPropagation();
    this.props.toggleStarredEvent({
      eventId,
      starred
    });
  };

  render() {
    const { myEvents, orgDetails, selectedEventId } = this.props;
    const filteredEvents = this.searchEvents(myEvents);
    const starredEvents = filteredEvents.filter(i => i.starred);
    const selectedEvent = myEvents.find(e => e.id === selectedEventId);

    return (
      <Div mt={3} mb={3}>
        <Popover
          innerSpanStyle={{
            width: "100%"
          }}
          Label={({ onClick }) => (
            <Div
              display="row.flex-start.center"
              bg={{
                hover: "primary7",
                default: "primary6"
              }}
              pl={4}
              pr={2}
              py={2}
              bra={1}
              onClick={onClick}
            >
              <Div display="column" flex={1} mr={2} width={158}>
                <Div uppercase color="neutral1" fs={0}>
                  Filter by event
                </Div>
                <Div fw={3} color="white" fs={2} truncate width={1}>
                  {selectedEvent ? selectedEvent.name : "Select an event"}
                </Div>
              </Div>
              <DownFilledIcon
                color="neutral1"
                size={25}
                style={{
                  flexShrink: 0
                }}
              />
            </Div>
          )}
        >
          {({ closePopover }) => (
            <Picker
              onSearchChange={this.handleChange}
              onSelectEvent={eventId => {
                this.props.onSelectEvent(eventId);
                this.handleChange("");
                closePopover();
              }}
              onStarEvent={this.toggleStarredEvent}
              selectedEventId={selectedEventId}
              starredEvents={starredEvents}
              orgName={orgDetails.name}
              events={filteredEvents}
              filter={this.state.filter}
            />
          )}
        </Popover>
      </Div>
    );
  }
}

EventsDropdown.propTypes = {
  myEvents: PropTypes.array.isRequired,
  hideDropdown: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  toggleStarredEvent: PropTypes.func.isRequired,
  eventId: PropTypes.string
};

export default decorate(EventsDropdown);
