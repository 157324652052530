import React from "react";
import { connect } from "react-redux";
import {
  getSelecteEvents,
  getEmptyEventsText,
  isNotCurrentYear
} from "OrgLight/Dashboard/selectors";
import { actions } from "OrgLight/Dashboard/model";
import { Div, Text3, RightArrowIcon } from "components/Base";
import SliderYearCalendar from "ui-kit/SliderYearCalendar";
import { getters } from "../model";
import { withRouter, Link } from "react-router";
import * as R from "ramda";

const decorate = R.compose(
  withRouter,
  connect(
    state => ({
      selectedEvents: getSelecteEvents(state),
      emptyEventsText: getEmptyEventsText(state),
      eventList: getters.eventList(state),
      currentYear: getters.currentYear(state),
      isNotCurrentYear: isNotCurrentYear(state)
    }),
    {
      handleAddEventModal: actions.handleAddEventModal,
      goToEvent: actions.goToEvent,
      goToAllEventsCalendar: actions.goToAllEventsCalendar
    }
  )
);

const EventCalendarBody = ({
  eventList,
  handleAddEventModal,
  goToEvent,
  currentYear,
  isNotCurrentYear,
  goToAllEventsCalendar,
  params
}) => (
  <>
    <Div p={3}>
      <SliderYearCalendar
        onClick={handleAddEventModal}
        onEventClick={goToEvent}
        events={eventList}
        year={currentYear}
        showMonthAndYear={isNotCurrentYear}
      />
    </Div>

    <Link to={`/org-light/${params.orgId}/events?calendar=1`}>
      <Div
        px={4}
        display="row.flex-start.center"
        height={50}
        bt={1}
        bc="neutral2"
      >
        <Text3 color="primary8" bold mr={2}>
          View Events Calendar
        </Text3>
        <RightArrowIcon color="neutral7" />
      </Div>
    </Link>
  </>
);

export default decorate(EventCalendarBody);
