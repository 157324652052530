import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE, TABS } from "./constants";

const iniState = {
  loading: false,
  data: {},
  selectedTab: TABS.ALL,
  filter: "",
  eventId: ""
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    openSidebar: R.identity,
    toggleTask: R.identity,
    handleShowAddRecordModal: R.identity,
    setInitialData: (_, { payload: { data } }) => ({
      data
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
