import { withProps } from "utils/General";

import CSSModules from "react-css-modules";
import css from "./styles.css";
import { Div } from "components/Base";

const TableStyler = withProps({
  my: 3,
  bra: 1,
  shadow: 1
})(Div);

const HeaderStyler = withProps({
  display: "row.flex-start.center",
  bg: "neutral1",
  py: 3,
  pl: 4
})(Div);

const RowStyler = CSSModules(
  withProps({
    display: "row.flex-start.center",
    bc: "neutral2",
    bg: "white",
    bt: 1,
    pl: 4,
    styleName: "parentContainer"
  })(Div),
  css
);

export { TableStyler, HeaderStyler, RowStyler };
