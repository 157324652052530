import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const ProgressWidget = ({ ...props }) => <div />;

ProgressWidget.propTypes = {};

export default CSSModules(ProgressWidget, css);
