import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Modal from "components/Global/Modal";
import EnvironmentTag from "components/Platform/EnvironmentTag";
import Snackbar from "components/Global/Snackbar";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class PortalUserView extends Component {
  @autobind
  showModal(modal) {
    this.props.showModal(modal.content ? modal : { content: modal });
  }

  @autobind
  hideModal() {
    this.props.hideModal();
  }

  componentDidMount() {
    // this.props.getLoggedInUserDetails({ noRedirect: true });
  }

  render() {
    return (
      <div styleName="container">
        <div style={{ height: "100%" }}>
          {React.cloneElement(this.props.children, {
            showModal: this.showModal,
            hideModal: this.hideModal
          })}
          <Modal params={this.props.params} router={this.props.router} />
          <Snackbar />
        </div>
        <EnvironmentTag />
      </div>
    );
  }
}

PortalUserView.propTypes = {
  children: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default PortalUserView;
