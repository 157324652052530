import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { TAB_IDS } from "./constants";

import { getCurrentRoute } from "./selectors";
import PageTitle from "EventLight/Common/PageTitle";

import GuestList from "./GuestLists/View";

import { Div } from "components/Base";

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    currentRoute: getCurrentRoute(state, props)
  }))
);

const Pages = {
  [TAB_IDS.GUEST_LISTS]: GuestList
};

const Wrapper = ({ currentRoute = "" }) => {
  return (
    <Div
      display="column.flex-start.stretch"
      height={1}
      style={{ maxHeight: "100%" }}
    >
      <PageTitle titles={["Guest Lists"]} />
      {React.createElement(R.propOr("div", currentRoute, Pages))}
    </Div>
  );
};

export default decorate(Wrapper);
