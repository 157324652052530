import * as R from "ramda";
import { makeInstanceSelector } from "redux-mvc";

import { getters } from "ui-kit/MiniItemsSelector";
import { getters as SearchBarGetters } from "ui-kit/SearchBar";
import {
  DEFAULT_MODE,
  MODE_SINGLE_SELECT,
  MODE_PRICE_SELECT
} from "ui-kit/MiniItemsSelector/constants";

export const getKey = (s, props) => R.prop("instanceId", props);

export const getIsSingleSelect = makeInstanceSelector(
  (_, props) => R.propOr(DEFAULT_MODE, "mode", props),
  mode => mode === MODE_SINGLE_SELECT
)(getKey);

export const getSearchTerm = makeInstanceSelector(
  SearchBarGetters.searchTerm,
  R.toLower
)(getKey);

export const getHasSearchTerm = R.compose(
  R.length,
  getSearchTerm
);

export const getSelectedItems = makeInstanceSelector(
  getters.selectedItems,
  getters.selectedItemsCount,
  getters.selectedItemsPrice,
  getters.mode,
  getters.itemOrder,
  (selectedItems, selectedItemsCount, selectedItemsPrice, mode, itemOrder) => {
    return mode === MODE_SINGLE_SELECT
      ? selectedItems
      : mode === MODE_PRICE_SELECT
      ? R.reduce(
          (acc, id) => ({
            ...acc,
            [id]: {
              qty: selectedItemsCount[id] || 1,
              price: selectedItemsPrice[id],
              order: itemOrder[id]
            }
          }),
          {},
          selectedItems
        )
      : R.reduce(
          (acc, id) => ({
            ...acc,
            [id]: selectedItemsCount[id] || 1
          }),
          {},
          selectedItems
        );
  }
)(getKey);

export const getSelectedItemsMap = makeInstanceSelector(
  getters.selectedItems,
  getters.selectedItemsCount,
  (items, itemCount) => {
    return R.reduce((acc, id) => ({ ...acc, [id]: itemCount[id] || 1 }), {})(
      items
    );
  }
)(getKey);

export const getSelectedCount = makeInstanceSelector(
  getSelectedItems,
  R.length
)(getKey);

export const getSelectedTab = makeInstanceSelector(
  getters.selectedTab,
  selected => {
    return selected;
  }
)(getKey);

const getGroups = (_, props) => R.propOr([], "groups", props);
const getAllItems = makeInstanceSelector(
  getGroups,
  R.compose(
    R.flatten,
    R.map(R.propOr([], "items")),
    R.flatten,
    R.map(R.propOr([], "items"))
  )
)(getKey);

const getSelectedGroupItems = makeInstanceSelector(
  getGroups,
  getters.selectedTab,
  (groups, selectedTab) => {
    return R.propOr(
      [],
      "items",
      R.find(group => group.id === selectedTab, groups) || {}
    );
  }
)(getKey);

export const getEnabledVariantTypes = makeInstanceSelector(
  getAllItems,
  getSelectedItemsMap,
  getters.itemOrder,
  getters.enableReorder,
  (items, selectedItems, itemOrder, enableReorder) =>
    R.compose(
      items =>
        enableReorder ? R.sortBy(item => itemOrder[item.id])(items) : items,
      R.filter(item => R.has(item.id, selectedItems))
    )(items)
)(getKey);

export const getSelectedVariantType = makeInstanceSelector(
  getSelectedGroupItems,
  getSearchTerm,
  (groups, searchTerm) => {
    return R.filter(
      group => group.items.length,
      R.length(searchTerm)
        ? R.map(
            group => ({
              ...group,
              items: R.filter(
                item => item.name.toLowerCase().includes(searchTerm),
                group.items
              )
            }),
            groups
          )
        : groups
    );
  }
)(getKey);

export const getItemGroups = makeInstanceSelector(
  (_, props) => R.propOr([], "itemGroups", props),
  getHasSearchTerm,
  getSearchTerm,
  (itemGroups, hasSearchTerm, searchTerm) =>
    R.filter(
      group => group.items.length,
      hasSearchTerm
        ? R.map(
            group => ({
              ...group,
              items: R.filter(
                item => item.name.toLowerCase().includes(searchTerm),
                group.items
              )
            }),
            itemGroups
          )
        : itemGroups
    )
)(getKey);

export const getLoading = (state, props) => {
  if (typeof props.loading !== "undefined") {
    return props.loading;
  }
  return getters.loading(state, props);
};
