import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import factory from "./export";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { exportSpreadsheetArray } from "redux/modules/export/actions";
import {
  exportReport,
  printReport
} from "redux/modules/reports/export/actions";

import { eventDetails } from "redux/modules/event/selectors";
import { references } from "redux/modules/entityReferences/selectors";

function mapStateToProps(state) {
  return {
    details: eventDetails(state),
    references: references(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      exportSpreadsheetArray,
      exportReport,
      printReport,
      showSnackbar
    },
    dispatch
  );
}

export default Child =>
  connect(mapStateToProps, mapDispatchToProps)(factory(Child));
