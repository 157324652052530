import * as R from "ramda";
import { namedColors, indexedColors as colors } from "./Base/colorSpecs";

const adjustColor = (step, color) => {
  if (color === "white" || color === "black") {
    return color;
  } else if (colors[color]) {
    const hueName = color.slice(0, -1);
    const shadeValue = parseInt(color.slice(-1));
    const newShadeValue = Math.max(0, shadeValue + step);
    return `${hueName}${newShadeValue}`;
  }
  return color;
};

const setShade = (step, color) => {
  if (color === "white" || color === "black") {
    return color;
  } else if (colors[color]) {
    const hueName = color.slice(0, -1);
    return `${hueName}${step}`;
  }
  return color;
};

const lighten = (step, color) => adjustColor(-step, color);
const darken = adjustColor;

const getColors = colors => (hues, shades) =>
  R.compose(
    R.flatten,
    R.values,
    R.map(hue => R.map(i => hue[i], shades)),
    R.pick(hues)
  )(colors);

const getNamedColors = getColors(namedColors);

const getColor = x => colors[x] || x;

export { lighten, darken, setShade, getColors, getColor, getNamedColors };
