import React from "react";
import { Div, MoreVertIcon, PopoverMenu } from "components/Base";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import { actions } from "../../model";

const decorator = connect(
  null,
  bindInstance({
    markAsRead: actions.markAsRead,
    markAsArchive: actions.markAsArchive
  })
);

export const Actions = ({ markAsRead, markAsArchive, id, sectionId }) => (
  <Div display="column.center.center">
    <PopoverMenu
      Label={({ onClick }) => <MoreVertIcon size={20} onClick={onClick} />}
      menuItems={[
        ["Mark as read", () => markAsRead({ id, status: "read", sectionId })],
        ["Archive", () => markAsArchive({ id, status: "archive", sectionId })]
      ]}
    />
  </Div>
);

export default decorator(Actions);
