import getValue from "../get-value/static";
import sortEmpty from "./sort-empty";

export default {
  ASC: sortEmpty("static", (a, b) =>
    getValue(a)
      .toLowerCase()
      .localeCompare(getValue(b).toLowerCase())
  ),
  DESC: sortEmpty("static", (a, b) =>
    getValue(b)
      .toLowerCase()
      .localeCompare(getValue(a).toLowerCase())
  )
};
