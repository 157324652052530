import { createModule } from "redux-mvc";

import model from "Orders/CreateOrderModal/SelectItems/model";

import AddZonesModal from "Orders/AddZonesModal";

const module = createModule(model);
module.plugModule(AddZonesModal);

export default module;
