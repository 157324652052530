import * as R from "ramda";
import { makeInstanceSelector } from "redux-mvc";

import * as DataSelectors from "App/Data/selectors";

import { getters as TabGetters } from "ui-kit/Tabs";

import { getFieldId } from "App/Data/utils";
import {
  sumRequested,
  sumApproved,
  sumRejected,
  sumPending,
  sumPrice
} from "Submission/PeopleBlockViewer/utils";

import { formatAmountForEvent } from "redux/modules/event/selectors";

const getKey = (_, props) => props.instanceId;

const blocks = makeInstanceSelector(
  (state, { params }) => DataSelectors.getFieldById(state, { params }),
  field => {
    const fieldOrder = R.pathOr({}, ["people_block", "block", "field_order"])(
      field
    );
    return R.sortBy(block => R.prop(block.id)(fieldOrder))(
      R.pathOr([], ["people_block", "block", "item_blocks"])(field)
    );
  }
)(getKey);

const lineItems = makeInstanceSelector(
  (state, { params }) => DataSelectors.getSubmissionById(state, { params }),
  (state, props) => getFieldId(props),
  (submission, fieldId) =>
    R.compose(
      R.groupBy(R.path(["variant", "id"])),
      R.flatten,
      R.map(R.path(["related_order", "line_items"])),
      R.pathOr([], ["people_blocks", fieldId, "people"])
    )(submission)
)(getKey);

export const getOrderIds = makeInstanceSelector(
  (state, { params }) => DataSelectors.getSubmissionById(state, { params }),
  (state, props) => getFieldId(props),
  (submission, fieldId) => {
    const ids = R.compose(
      R.map(R.path(["related_order", "id"])),
      R.pathOr([], ["people_blocks", fieldId, "people"])
    )(submission);
    return ids.length ? ids : ["none"];
  }
)(getKey);

export const getLineItemIds = makeInstanceSelector(
  (state, { params }) => DataSelectors.getSubmissionById(state, { params }),
  (state, props) => getFieldId(props),
  (submission, fieldId) => {
    return R.compose(
      R.map(R.prop("id")),
      R.flatten,
      R.map(R.path(["related_order", "line_items"])),
      R.pathOr([], ["people_blocks", fieldId, "people"])
    )(submission);
  }
)(getKey);

export const getLineItemIdsByBlockId = makeInstanceSelector(
  blocks,
  lineItems,
  (_, props) => props.itemBlockId,
  (blocks, lineItems, itemBlockId) => {
    return R.compose(
      block => {
        const blockLineItems = R.flatten(
          R.map(
            variant =>
              R.propOr([], R.path(["variant", "id"], variant), lineItems),
            block.variants
          )
        );
        return R.map(R.prop("id"), blockLineItems);
      },
      R.find(block => block.id === itemBlockId)
    )(blocks);
  }
)(getKey);

export const getVariantIdsByBlockId = makeInstanceSelector(
  blocks,
  (_, props) => props.itemBlockId,
  (blocks, itemBlockId) => {
    return R.compose(
      block => {
        return R.map(R.path(["variant", "id"]), block.variants);
      },
      R.find(block => block.id === itemBlockId)
    )(blocks);
  }
)(getKey);

export const getBlocksWithLineItems = makeInstanceSelector(
  formatAmountForEvent,
  blocks,
  lineItems,
  (formatAmountForEvent, blocks, lineItems) =>
    R.map(block => {
      const variants = R.filter(
        R.compose(R.not, R.isNil),
        R.map(variant => {
          const lines = R.propOr(
            [],
            R.path(["variant", "id"], variant),
            lineItems
          );
          if (!R.isEmpty(lines)) {
            return {
              ...variant,
              summary: {
                requested: sumRequested(lines),
                approved: sumApproved(lines),
                rejected: sumRejected(lines),
                pending: sumPending(lines),
                price: formatAmountForEvent(R.pathOr(0, [0, "price"])(lines)),
                totalPrice: formatAmountForEvent(sumPrice(lines))
              }
            };
          }
          return null;
        }, block.variants)
      );
      const pendingReview = R.sum(
        R.map(R.pathOr(0, ["summary", "pending"]), variants)
      );
      const blockLineItems = R.flatten(
        R.map(
          variant =>
            R.propOr([], R.path(["variant", "id"], variant), lineItems),
          block.variants
        )
      );

      return {
        ...block,
        variants,
        pendingReview: pendingReview > 0,
        lineItemIds: R.map(R.prop("id"), blockLineItems),
        pendingLineItemIds: R.map(
          R.prop("id"),
          R.filter(R.propEq("approval_status", "pending"), blockLineItems)
        )
      };
    }, blocks)
)(getKey);

const mainTabs = makeInstanceSelector(
  (_, props) => props.readOnly,
  R.compose(R.length, blocks),
  (readOnly, len) =>
    len > 0
      ? [
          { id: "people", title: "People" },
          { id: "summary", title: "Summary" },
          readOnly ? null : { id: "overview", title: "All Items" }
        ].filter(o => o)
      : [{ id: "people", title: "People" }]
)(getKey);

export const getTabs = makeInstanceSelector(
  (_, props) => props.readOnly,
  blocks,
  mainTabs,
  (readOnly, blocks, tabs) =>
    readOnly
      ? tabs
      : R.concat(
          tabs,
          R.map(({ name: title, id }) => ({ title, id }), blocks)
        )
)(getKey);

export const getIsManageView = makeInstanceSelector(
  TabGetters.selectedTab,
  blocks,
  (selectedId, blocks) =>
    selectedId === "overview" || R.any(R.propEq("id", selectedId), blocks)
)(getKey);
