import uuid from "node-uuid";

export default selectedVariants =>
  Object.keys(selectedVariants).reduce((list, variantId) => {
    const qty = selectedVariants[variantId] || 0;
    for (let i = 0; i < qty; i++) {
      list.push({
        id: uuid.v4(),
        variantId,
        type: "item",
        assignedTo: null,
        values: {}
      });
    }
    return list;
  }, []);
