import { createHandlers } from "redux-mvc";

const model = createHandlers({
  iniState: {
    loading: true,
    alert: {}
  },
  reducers: {
    fetchResponse: (_, { payload: alert }) => ({
      loading: false,
      alert
    })
  },
  namespace: "HubAlert"
});

const { actions, getters } = model;

export { actions, getters };

export default model;
