import { put, all, takeEvery, fork, select, call } from "redux-saga/effects";
import { actions, getters } from "Modules/AddEditColumnModal/model";
import {
  getErrorMessage,
  getShowMaxLength,
  getMaxLengthFormatted
} from "Modules/AddEditColumnModal/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { registerError } from "redux/modules/errors/actions";
import { hideModal } from "redux/modules/modal/actions";
import { fieldGroups as getFieldGroups } from "redux/modules/modules/module/selectors";
import { apiCall } from "App/Data/sagas";
import Api from "./api";

import * as R from "ramda";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const eventId = yield select(getEventId);

  return {
    orgId,
    eventId,
    credentials
  };
};

const getIds = function*() {
  const orgId = yield select(getters.orgId);
  const eventId = yield select(getters.eventId);
  const moduleId = yield select(getters.moduleId);
  const fieldId = yield select(getters.fieldId);

  return {
    eventId,
    moduleId,
    orgId,
    fieldId
  };
};

const init = function*({ payload: props }) {
  const { orgId: orgIdGet, eventId: eventIdGet } = yield call(getParams);

  try {
    const {
      module: { fields, field_groups }
    } = yield call(apiCall, {
      method: "get",
      url: `/modules/${props.moduleId}`,
      qs: {
        orgId: props.orgId || orgIdGet,
        eventId: props.eventId || eventIdGet
      }
    });

    if (!props.fieldId) {
      yield put(
        actions.setInitialData({
          moduleId: props.moduleId,
          orgId: R.propOr("", "orgId", props) || orgIdGet,
          eventId: R.propOr("", "eventId", props) || eventIdGet,
          fields,
          fieldGroups: field_groups
        })
      );
    } else {
      yield put(
        actions.setInitialEditData({
          moduleId: props.moduleId,
          orgId: R.propOr("", "orgId", props) || orgIdGet,
          eventId: R.propOr("", "eventId", props) || eventIdGet,
          fieldId: props.fieldId,
          fields,
          fieldGroups: field_groups
        })
      );
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const getData = function*() {
  const name = yield select(getters.name);
  const fieldGroup = yield select(getters.fieldGroup);
  const description = yield select(getters.description);
  const type = yield select(getters.type);
  const dateSettings = yield select(getters.dateSettings);
  const dropdownSettings = yield select(getters.dropdownSettings);
  const dateTimeSettings = yield select(getters.dateTimeSettings);
  const percentSettings = yield select(getters.percentSettings);
  const calculatedNumberSettings = yield select(
    getters.calculatedNumberSettings
  );
  const currencySettings = yield select(getters.currencySettings);
  const lookupSettings = yield select(getters.lookupSettings);
  const referenceSettings = yield select(getters.referenceSettings);
  const phoneSettings = yield select(getters.phoneSettings);
  const textSettings = yield select(getters.textSettings);
  const cateringSettings = yield select(getters.cateringSettings);
  const fileSettings = yield select(getters.fileSettings);
  const maxLength = yield select(getMaxLengthFormatted);
  const showMaxLength = yield select(getShowMaxLength);

  const data = {
    name,
    type,
    fieldGroup,
    settings: {
      ...R.propOr({}, type, {
        dropdown: {
          allowMultipleSelect: dropdownSettings.allowMultipleSelect,
          options: dropdownSettings.options.filter(option => option.value)
        },
        date: dateSettings,
        datetime: dateTimeSettings,
        percent: percentSettings,
        "calculated-number": calculatedNumberSettings,
        currency: currencySettings,
        lookup: lookupSettings,
        reference: referenceSettings,
        phone: phoneSettings,
        text: {
          readOnly: textSettings.readOnly
        },
        catering: cateringSettings,
        file: fileSettings
      }),
      description,
      ...(showMaxLength && maxLength > 0 ? { maxLength } : {})
    }
  };

  return data;
};

const addField = function*({ addAtPosition, fieldGroups, onSave }) {
  try {
    yield put(actions.setLoading(true));
    const data = yield call(getData);

    if (typeof addAtPosition !== "undefined") {
      data.addAtPosition = addAtPosition;
    }

    const { orgId, eventId, moduleId } = yield call(getIds);
    const { credentials } = yield call(getParams);

    const { layout } = yield call(Api.getLayout, {
      credentials,
      moduleId,
      options: {
        orgId,
        eventId
      }
    });

    const { field } = yield call(Api.addField, {
      credentials,
      data: {
        moduleId,
        field: {
          ...data,
          layoutId: layout.id
        },
        options: {
          orgId,
          eventId
        }
      }
    });

    // step: add field to group
    if (String(data.fieldGroup) !== String(0)) {
      const fieldGroupToAddTo = fieldGroups.find(
        fg => fg.id === data.fieldGroup
      );
      yield call(Api.addFieldGroupField, {
        credentials,
        data: {
          moduleId,
          fieldId: field.id,
          groupId: data.fieldGroup,
          order: R.pathOr(0, ["fields", "length"], fieldGroupToAddTo)
        }
      });
    }

    yield call(onSave, field);

    yield put(hideModal());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const editField = function*({ onSave }) {
  try {
    yield put(actions.setLoading(true));
    const data = yield call(getData);
    const { fieldId, moduleId, orgId, eventId } = yield call(getIds);
    const { credentials } = yield call(getParams);
    const description = yield select(getters.description);

    yield call(Api.updateField, {
      credentials,
      data: {
        moduleId,
        field: {
          id: fieldId,
          name: data.name,
          type: data.type,
          settings: {
            ...data.settings,
            description
          }
        },
        options: {
          orgId,
          eventId
        }
      }
    });

    const oldFieldGroup = yield select(getters.oldFieldGroup);
    const fieldGroups = yield select(getFieldGroups, moduleId);

    if (data.fieldGroup !== R.toString(oldFieldGroup.id)) {
      const fieldGroup = fieldGroups.find(
        fg => R.toString(fg.id) === R.toString(data.fieldGroup)
      );
      const fieldGroupField = oldFieldGroup.fields.find(
        fgf => fgf.field_id === fieldId
      );

      const isDefaultGroup = id => R.toString(id) === "0";
      if (
        !isDefaultGroup(oldFieldGroup.id) &&
        !isDefaultGroup(data.fieldGroup)
      ) {
        yield call(Api.updateFieldGroup, {
          credentials,
          id: fieldGroupField.id,
          data: {
            moduleId,
            fieldId,
            groupId: data.fieldGroup,
            order: R.pathOr(0, ["fields", "length"], fieldGroup)
          }
        });
      } else if (
        isDefaultGroup(oldFieldGroup.id) &&
        !isDefaultGroup(data.fieldGroup)
      ) {
        yield call(Api.addFieldGroupField, {
          credentials,
          data: {
            moduleId,
            fieldId,
            groupId: data.fieldGroup,
            order: R.pathOr(0, ["fields", "length"], fieldGroup)
          }
        });
      } else if (
        !isDefaultGroup(oldFieldGroup.id) &&
        isDefaultGroup(data.fieldGroup)
      ) {
        // Custom group -> Standard
        yield call(Api.deleteFieldGroupField, {
          id: fieldGroupField.id,
          moduleId,
          credentials
        });
      }
    }

    yield call(onSave, data);
    yield put(hideModal());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const handleSave = function*({ payload: { addAtPosition, onSave } }) {
  const error = yield select(getErrorMessage);
  yield put(actions.setError(error));

  if (R.isNil(error)) {
    const { fieldId } = yield call(getIds);
    const fieldGroups = yield select(getters.fieldGroups);

    if (R.isEmpty(fieldId)) {
      yield call(addField, { addAtPosition, fieldGroups, onSave });
    } else {
      yield call(editField, { onSave });
    }
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchSave = function*() {
  yield takeEvery(actions.handleSave.type, handleSave);
};

const rootSaga = function*() {
  yield all([fork(watchInit), fork(watchSave)]);
};

export default rootSaga;
