import PropTypes from "prop-types";
import React from "react";

const BookmarkIcon = ({ color }) => {
  const svg = `
  <svg width="14px" height="24px" viewBox="0 0 14 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs></defs>
    <g stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
      <g transform="translate(-359.000000, -314.000000)">
        <g transform="translate(341.000000, 297.000000)">
          <g transform="translate(10.000000, 10.000000)">
            <g transform="translate(1.000000, 0.000000)">
              <g transform="translate(0.000000, 5.000000)">
                <rect x="0" y="0" width="28" height="28"></rect>
                <path d="M9,2 C7.895,2 7.01,2.895 7.01,4 C7.01,4 7.00666667,10.6666667 7.005,14.5 L7,26 L14,23 L21,26 L21,4 C21,2.895 20.105,2 19,2 L9,2 Z" fill=${color}></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
  `;
  return <span dangerouslySetInnerHTML={{ __html: svg }} />;
};

BookmarkIcon.propTypes = {
  color: PropTypes.string.isRequired
};

export default BookmarkIcon;
