import React from "react";
import { connect } from "react-redux";
import { actions, getters } from "EventLight/Agenda/AddSessionModal/model";
import {
  Div,
  SmallToggle,
  DownFilledIcon,
  FontIcon,
  BigOutlineInput,
  TinyToggle,
  DragIcon,
  DeleteIcon,
  BigOutlineButton
} from "components/Base";
import CollapsablePanel from "ui-kit/CollapsiblePanel";
import { FIELD_IDS } from "EventLight/Agenda/AddSessionModal/constants";
import * as R from "ramda";

import { Input } from "ui-kit/Form/View";

const decorate = connect(
  state => ({
    data: getters.data(state)
  }),
  {
    updateField: actions.updateField
  }
);

const Content = ({ data }) => (
  <Div height={500} bg="neutral0" style={{ overflowY: "auto" }}>
    <Div
      style={{
        paddingLeft: 40,
        paddingTop: 32,
        paddingBottom: 32,
        paddingRight: 63
      }}
    >
      <Div display="row.space-between.flex-start" width={1}>
        <Div color="black" fw={3} fs={3} mt={2}>
          Multi-Media
        </Div>
        <Div width={681}>
          <CollapsablePanel
            bg="white"
            right={false}
            bra={1}
            arrowColor="black"
            arrowIcon={DownFilledIcon}
            style={{ boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.05)" }}
            heading={
              <Div
                width={1}
                bra={1}
                p={2}
                pl={1}
                display="row.space-between.flex-start"
              >
                <Div display="row.flex-start.center">
                  <FontIcon mr={2} color="black">
                    ondemand_video
                  </FontIcon>
                  <Div color="black" fw={3} style={{ fontSize: 16 }}>
                    Embed Video / Livestream Player
                  </Div>
                </Div>
                {/* <SmallToggle /> */}
              </Div>
            }
          >
            <Div pl={9} pt={3} pb={5}>
              <Div display="row.flex-start.center">
                <div
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "black",
                    marginRight: 30
                  }}
                >
                  URL
                </div>
                <Input
                  fieldId={FIELD_IDS.VIDEO_URL}
                  width={380}
                  iniValue={R.prop(FIELD_IDS.VIDEO_URL)(data)}
                  size="big"
                  inputType="outline"
                />
              </Div>

              <Div display="row.flex-start.center" mt={2}>
                <div
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "black",
                    marginRight: 30
                  }}
                >
                  Title
                </div>
                <Input
                  fieldId={FIELD_IDS.VIDEO_TITLE}
                  width={560}
                  iniValue={R.prop(FIELD_IDS.VIDEO_TITLE)(data)}
                  size="big"
                  inputType="outline"
                  placeholder="Add a title to video / stream (optional)"
                />
              </Div>

              {/*
              <Div display="row.flex-start.center" width={1} mt={4}>
                <TinyToggle active={false} />
                <Div color="black" fw={3} ml={2} style={{ fontSize: 14 }}>
                  Restrict Access
                </Div>
              </Div>
              */}
            </Div>
          </CollapsablePanel>

          <CollapsablePanel
            bg="white"
            right={false}
            bra={1}
            mt={2}
            arrowColor="black"
            arrowIcon={DownFilledIcon}
            style={{ boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.05)" }}
            heading={
              <Div
                width={1}
                bra={1}
                p={2}
                pl={1}
                display="row.space-between.flex-start"
              >
                <Div display="row.flex-start.center">
                  <FontIcon mr={2} color="black">
                    ondemand_video
                  </FontIcon>
                  <Div color="black" fw={3} style={{ fontSize: 16 }}>
                    Video Conference Link
                  </Div>
                </Div>
                {/* <SmallToggle /> */}
              </Div>
            }
          >
            <Div pl={9} pt={3} pb={5}>
              <Div display="row.flex-start.center">
                <div
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "black",
                    marginRight: 30
                  }}
                >
                  URL
                </div>
                <Input
                  fieldId={FIELD_IDS.CONFERENCE_URL}
                  width={380}
                  iniValue={R.prop(FIELD_IDS.CONFERENCE_URL)(data)}
                  size="big"
                  inputType="outline"
                />
              </Div>
              <Div display="row.flex-start.center" mt={2}>
                <div
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "black",
                    marginRight: 30
                  }}
                >
                  Title
                </div>
                <Input
                  fieldId={FIELD_IDS.CONFERENCE_TITLE}
                  width={560}
                  iniValue={R.prop(FIELD_IDS.CONFERENCE_TITLE)(data)}
                  size="big"
                  inputType="outline"
                  placeholder="Add a title to video / stream (optional)"
                />
              </Div>

              {/*
              <Div display="row.flex-start.center" width={1} mt={4}>
                <TinyToggle active={false} />
                <Div color="black" fw={3} ml={2} style={{ fontSize: 14 }}>
                  Restrict Access
                </Div>
              </Div>
              */}

              {/*
              <Div display="row.flex-start.center" width={1} mt={4}>
                <TinyToggle active={false} />
                <div style={{ marginLeft: 8 }}>&#128075;</div>
                <Div
                  color="black"
                  style={{ fontSize: 14 }}
                  display="row.flex-start.center"
                >
                  <Div fw={3} mr={1}>
                    Enable Question Queue
                  </Div>
                  (Attendee can raise their hand)
                </Div>
              </Div>
              */}
            </Div>
          </CollapsablePanel>

          <CollapsablePanel
            bg="white"
            right={false}
            bra={1}
            mt={2}
            arrowColor="black"
            arrowIcon={DownFilledIcon}
            style={{ boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.05)" }}
            heading={
              <Div
                width={1}
                bra={1}
                p={2}
                pl={1}
                display="row.space-between.flex-start"
              >
                <Div display="row.flex-start.center">
                  <FontIcon mr={2} color="black">
                    ondemand_video
                  </FontIcon>
                  <Div color="black" fw={3} style={{ fontSize: 16 }}>
                    Transcript Link
                  </Div>
                </Div>
              </Div>
            }
          >
            <Div pl={9} pt={3} pb={5}>
              <Div display="row.flex-start.center">
                <div
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "black",
                    marginRight: 30
                  }}
                >
                  URL
                </div>
                <Input
                  fieldId={FIELD_IDS.TRANSCRIPT_URL}
                  width={380}
                  iniValue={R.prop(FIELD_IDS.TRANSCRIPT_URL)(data)}
                  size="big"
                  inputType="outline"
                />
              </Div>
            </Div>
          </CollapsablePanel>

          <CollapsablePanel
            bg="white"
            right={false}
            bra={1}
            mt={2}
            arrowColor="black"
            arrowIcon={DownFilledIcon}
            style={{ boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.05)" }}
            heading={
              <Div
                width={1}
                bra={1}
                p={2}
                pl={1}
                display="row.space-between.flex-start"
              >
                <Div display="row.flex-start.center">
                  <FontIcon mr={2} color="black">
                    ondemand_video
                  </FontIcon>
                  <Div color="black" fw={3} style={{ fontSize: 16 }}>
                    Replay Link
                  </Div>
                </Div>
              </Div>
            }
          >
            <Div pl={9} pt={3} pb={5}>
              <Div display="row.flex-start.center">
                <div
                  style={{
                    fontSize: 16,
                    fontWeight: 600,
                    color: "black",
                    marginRight: 30
                  }}
                >
                  URL
                </div>
                <Input
                  fieldId={FIELD_IDS.REPLAY_URL}
                  width={380}
                  iniValue={R.prop(FIELD_IDS.REPLAY_URL)(data)}
                  size="big"
                  inputType="outline"
                />
              </Div>
            </Div>
          </CollapsablePanel>
        </Div>
      </Div>

      {/*
      <Div display="row.space-between.flex-start" width={1} mt={5}>
        <Div color="black" fw={3} fs={3} mt={2}>
          Related Content
        </Div>
        <Div width={681}>
          <Div
            bg="white"
            bra={1}
            p={2}
            width={1}
            style={{ boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.05)" }}
            display="row.space-between.center"
            height={51}
          >
            <Div display="row.flex-start.center">
              <DragIcon color="neutral7" />
              <Div
                trunc
                color="black"
                ml={2}
                style={{ fontSize: 14 }}
                width={394}
                fw={3}
              >
                Press FAQ's
              </Div>
            </Div>
            <Div display="row.flex-start.center">
              <Div bra={3} bg="neutral2" py={1} px={2} mr={4} fs={0}>
                PAGE
              </Div>
              <DeleteIcon color={{ default: "neutral5", hover: "primary7" }} />
            </Div>
          </Div>
          <Div display="row.flex-start.flex-start" mt={3}>
            <BigOutlineButton>
              <Div color="primary8" fw={3} fs={2}>
                + Add related pages
              </Div>
            </BigOutlineButton>
            <BigOutlineButton ml={2}>
              <Div color="primary8" fw={3} fs={2}>
                + Add related session
              </Div>
            </BigOutlineButton>
            <BigOutlineButton ml={2}>
              <Div color="primary8" fw={3} fs={2}>
                + Upload file
              </Div>
            </BigOutlineButton>
            <BigOutlineButton ml={2}>
              <Div color="primary8" fw={3} fs={2}>
                + Add link
              </Div>
            </BigOutlineButton>
          </Div>
        </Div>
      </Div>
      */}
    </Div>
  </Div>
);

export default decorate(Content);
