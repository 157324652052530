import { createSelector } from "reselect";

import * as R from "ramda";

import { getters } from "./index";

import { addS } from "utils/General";

export const getRecordTypeIds = createSelector(
  getters.template,
  R.prop("record_type_ids")
);

export const getSections = createSelector(getters.template, template =>
  R.compose(
    R.map(s => ({
      ...s,
      sections: R.sort((a, b) => a.order - b.order)(s.sections)
    })),
    R.sort((a, b) => a.order - b.order)
  )(R.propOr([], "sections", template))
);

export const getRecordTypesLabel = createSelector(
  getters.template,
  getters.recordTypes,
  (template, recordTypes) => {
    const selectedRecordLength = R.length(R.prop("record_type_ids", template));

    if (
      selectedRecordLength === 0 ||
      selectedRecordLength === R.length(recordTypes)
    ) {
      return "All record types";
    }
    return `${selectedRecordLength} type${addS(selectedRecordLength)} selected`;
  }
);
