import * as R from "ramda";

import React, { useEffect } from "react";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";

import css from "./styles.scss";

import { getters } from "../model";
import { TABLE_VIEW_ID } from "../constants";

import LoadingOverlay from "ui-kit/LoadingOverlay";

const decorate = R.compose(
  connect((state, props) => ({
    columnWidths: getters.columnWidths(state, props)
  })),
  CSSModules(css)
);

export const TableWrapper = decorate(
  ({
    children,
    loading = false,
    transparentLoading = false,
    style = {},
    innerStyle = {},
    styles,
    columnWidths,
    instanceId
  }) => {
    useEffect(() => {
      const elm = document.getElementById(`table-styles-${instanceId}`);
      const sheet = elm || document.createElement("style");

      sheet.type = "text/css";
      sheet.id = `table-styles-${instanceId}`;

      sheet.innerHTML = R.join(
        " ",
        R.map(
          ([columnId, width]) =>
            `.${
              styles["ui-table"]
            } .th.th-${columnId} { min-width: ${width}px; max-width: ${width}px; width: ${width}px }`,
          Object.entries(columnWidths)
        )
      );

      if (!elm) {
        document.getElementsByTagName("head")[0].appendChild(sheet);
      }
    }, [columnWidths, styles, instanceId]);

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "stretch",
          overflowY: "auto",
          position: "relative",
          flex: 1,
          ...style
        }}
      >
        <div
          id={TABLE_VIEW_ID}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "stretch",
            overflowY: loading ? "hidden" : "auto",
            ...innerStyle
          }}
        >
          {children}
        </div>
        {loading && <LoadingOverlay transparent={transparentLoading} />}
      </div>
    );
  }
);

export const GroupWrapper = ({ children, style = {} }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      ...style
    }}
  >
    {children}
  </div>
);
