import React from "react";
import MiniModalWrapper from "Orders/Common/View/MiniModalWrapper";
import { SelectAndCreateVariants } from "ui-kit/MiniItemsSelector/View";
import { connect } from "react-redux";
import { noop } from "utils/General";
import { MODE_PRICE_SELECT } from "ui-kit/MiniItemsSelector/constants";

import { actions } from "../../model";
import { getItemsTypes, getAutoAssignedIds } from "../../selectors";
import { SELECT_AUTO_ASSIGN_ITEMS_MODAL_ID } from "../../constants";

const decorate = connect(
  state => ({
    itemsTypes: getItemsTypes(state),
    selectedItemIds: getAutoAssignedIds(state)
  }),
  {
    hideModal: () => actions.setShowSelectAutoAssignItemsModal(false),
    onDone: actions.updateAssignItems
  }
);

const SelectAutoAssignItemsModal = ({
  itemsTypes = [],
  selectedItemIds = [],
  hideModal = noop,
  onDone = noop
}) => {
  return (
    <MiniModalWrapper
      showModal={true}
      title="Choose items"
      hideModal={hideModal}
      width={920}
      style={{
        height: "95%"
      }}
    >
      {React.createElement(SelectAndCreateVariants, {
        instanceId: SELECT_AUTO_ASSIGN_ITEMS_MODAL_ID,
        fields: itemsTypes,
        iniSelected: selectedItemIds,
        iniClearSearch: true,
        onCancel: hideModal,
        onDone: onDone,
        mode: MODE_PRICE_SELECT
      })}
    </MiniModalWrapper>
  );
};

export default decorate(SelectAutoAssignItemsModal);
