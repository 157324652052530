import * as R from "ramda";
import React from "react";
import { createSelector } from "reselect";
import { connect } from "react-redux";

import { getMeals, getMealDays, getSelectedDays } from "../selectors";
import { actions } from "../model";

import { noop } from "utils/General";

import MealMatrixSelector from "components/Event/Settings/Catering/MealMatrixSelector";
import { Div } from "components/Base";

const isMealDaySelected = createSelector(
  getMealDays,
  mealDays => (mealId, dayId) =>
    R.find(day => day.mealId === mealId && day.dayId === dayId, mealDays)
);

const decorate = connect(
  (state, props) => ({
    meals: getMeals(state, props),
    mealDays: getMealDays(state, props),
    selectedDays: getSelectedDays(state, props),
    isMealDaySelected: isMealDaySelected(state, props)
  }),
  {
    handleMealDayChange: actions.toggleMealDay
  }
);

const MealsPerDay = ({
  meals = [],
  mealDays = [],
  selectedDays = [],
  isMealDaySelected = R.always(false),
  handleMealDayChange = noop
}) => (
  <Div p={5}>
    <MealMatrixSelector
      meals={meals}
      days={selectedDays}
      isMealDaySelected={isMealDaySelected}
      countOfMealDaysSelected={R.length(mealDays)}
      handleChange={(mealId, dayId) => handleMealDayChange({ mealId, dayId })}
    />
  </Div>
);

export default decorate(MealsPerDay);
