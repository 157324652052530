import Approved from "./Approved";
import InReview from "./InReview";
import NeedsReview from "./NeedsReview";
import Reviewed from "./Reviewed";
import Rejected from "./Rejected";
import Sent from "./Sent";
import Synced from "./Synced";
import { getAdminBadge } from "components/Global/CRM/Forms/utils/utils";

const badgeSet = {
  approved: Approved,
  inReview: InReview,
  needsReview: NeedsReview,
  rejected: Rejected,
  reviewed: Reviewed,
  synced: Synced,
  sent: Sent
};

export default (submission, fields, options) =>
  getAdminBadge(submission, fields, badgeSet, options);
