import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import {
  Submit,
  ButtonOutline,
  ButtonGroup
} from "components/Global/Modal/Layout/Buttons";
import Helpers from "utils/Global/Helpers";
import ManageEventActions from "actions/Event/DetailsActions";
import PortalUsersApi from "redux/modules/portal/users/api";

const isEmailValid = email => Boolean(email) && Helpers.isValidEmail(email);

@CSSModules(css)
class AddUserToAccount extends Component {
  state = {
    results: null,
    inputType: "text",
    error: null
  };

  search = () => {
    if (this.input.value.length) {
      Helpers.searchUsers(
        this.props.userCredentials,
        this.input.value,
        ({ payload }) =>
          this.setState({
            results: payload,
            inputType: payload.length ? "text" : "email",
            error: null
          }),
        {
          eventId: this.props.eventId
        }
      );
    } else {
      this.setState({ results: null, error: null });
    }
  };

  isUserAlreadyAssignedToAccount = user =>
    Boolean(this.props.existingUsers.find(u => u.user_id === user.user_id));

  selectUser = user => {
    if (this.isUserAlreadyAssignedToAccount(user)) {
      this.setState({
        error: `${
          user.user_fname
            ? `${user.user_fname} ${user.user_lname}`
            : user.user_email
        } is alread a member of this group.`
      });
    } else {
      PortalUsersApi.post(this.props.userCredentials, {
        eventId: this.props.eventId,
        userId: user.user_id,
        status: "pending"
      }).then(() => {
        this.props.onUserSelect(user);
        this.props.hideModal();
      });
    }
  };

  handleNewUserInvite = e => {
    e.preventDefault();

    if (!isEmailValid(this.input.value)) {
      this.setState({
        error: "Please enter a valid email address."
      });
      return false;
    }

    // send data
    ManageEventActions.inviteNewUserToEvent(
      this.props.userCredentials,
      {
        addToEventId: this.props.eventId,
        email: this.input.value,
        fname: this.firstName.value,
        lname: this.lastName.value,
        suppressEmail: true
      },
      user =>
        this.selectUser({
          user_id: user.id
        })
    );

    return false;
  };

  emptyState = () => (
    <div>
      <div styleName="label">What are account users?</div>
      <div styleName="helpContent">
        Account users are usually managers for a company or organization. They
        have access to fill out forms assigned to this group, as well as update
        any form submissions for this group.
      </div>
    </div>
  );

  createResultsView = () => (
    <div>
      <div styleName="label">Results</div>
      <div styleName="list">
        {this.state.results.map(result => (
          <div
            styleName="result"
            key={result.user_email}
            onClick={() => this.selectUser(result)}
          >
            <div styleName="avatar">
              {result.user_fname
                ? Helpers.buildUserAcronym(result.user_fname, result.user_lname)
                : Helpers.buildUserAcronym(result.user_email, "")}
            </div>
            {result.user_fname ? (
              <div>
                <div
                  styleName="name"
                  title={`${result.user_fname} ${result.user_lname}`}
                >
                  {result.user_fname} {result.user_lname}
                </div>
                <div styleName="email">{result.user_email}</div>
              </div>
            ) : (
              <div styleName="name" title={result.user_email}>
                {result.user_email}
              </div>
            )}
          </div>
        ))}
      </div>
      <div styleName="well">
        <span styleName="bold">Don&#39;t see them?</span> Enter their full email
        address above.
      </div>
    </div>
  );

  createUserView = () => (
    <div>
      <div styleName="well">
        We don&#39;t know <span styleName="bold">{this.input.value}</span> yet.
        <br />
        Add their name below & click "Add User".
      </div>
      <div styleName="inputSection">
        <div styleName="inputGroup">
          <div styleName="label">First Name</div>
          <input
            ref={ref => {
              this.firstName = ref;
            }}
            required
            styleName="input"
            type="text"
          />
        </div>
        <div styleName="inputGroup">
          <div styleName="label">Last Name</div>
          <input
            ref={ref => {
              this.lastName = ref;
            }}
            required
            styleName="input"
            type="text"
          />
        </div>
      </div>
      <ButtonGroup>
        <div styleName="buttonLeft">
          <Submit title="Add User" />
        </div>
        <ButtonOutline title="Cancel" />
      </ButtonGroup>
    </div>
  );

  renderBody = () => {
    if (this.state.results) {
      if (this.state.results.length > 0) {
        return this.createResultsView();
      }
      if (this.state.results.length === 0) {
        return this.createUserView();
      }
    }
    return this.emptyState();
  };

  render() {
    return (
      <StyleWrapper
        width={500}
        hideModal={this.props.hideModal}
        heading="Add user to account"
      >
        <div styleName="container">
          <form onSubmit={this.handleNewUserInvite}>
            <div styleName="inputWrapper">
              <input
                autoFocus
                onChange={this.search}
                placeholder="Search by email - ie: chris@lennd.com"
                ref={ref => {
                  this.input = ref;
                }}
                styleName="input"
                type={this.state.inputType}
                required
              />
              <i className="material-icons">search</i>
            </div>
            {this.state.error ? (
              <div styleName="error">{this.state.error}</div>
            ) : null}
            {this.renderBody()}
          </form>
        </div>
      </StyleWrapper>
    );
  }
}

AddUserToAccount.propTypes = {
  eventId: PropTypes.string.isRequired,
  hideModal: PropTypes.func.isRequired,
  onUserSelect: PropTypes.func.isRequired,
  userCredentials: PropTypes.shape({
    userId: PropTypes.number
  }).isRequired,
  existingUsers: PropTypes.array.isRequired
};

export default AddUserToAccount;
