import React, { Component } from "react";
import * as R from "ramda";
import View from "./View";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

class Internal extends Component {
  render() {
    const {
      recordTypes,
      showRecordTypeModal,
      showDeleteRecordTypeModal,
      handleUpdateDefaultRecordType,
      setActiveRecordType,
      selectedRecordType,
      setActiveRecordTypeSection,
      routes,
      selectedModule
    } = this.props;

    let tabs = [];

    if (
      [
        STANDARD_MODULE_IDS.accounts.id,
        STANDARD_MODULE_IDS.contacts.id
      ].includes(selectedModule.id)
    ) {
      tabs.push({
        routeName: "eventSettingsModuleTypesPortal",
        tabName: "Portal",
        onClick: () => setActiveRecordTypeSection("portal")
      });
    }

    tabs.push({
      routeName: "eventSettingsModuleTypesRecordLayout",
      tabName: "Record Layout",
      onClick: () => setActiveRecordTypeSection("record-layout")
    });

    if (selectedModule.id === STANDARD_MODULE_IDS.accounts.id) {
      tabs.push({
        routeName: "eventSettingsModuleTypesGuestList",
        tabName: "Guest List",
        onClick: () => setActiveRecordTypeSection("guest-list")
      });
    }

    tabs.push(
      {
        routeName: "eventSettingsModuleTypesAddRecordLayout",
        tabName: "Add Record Layout",
        onClick: () => setActiveRecordTypeSection("add-record-layout")
      }
      /*
      // @TODO
      {
        routeName: "eventSettingsModuleTypesCardLayout",
        tabName: "Card Layout",
        onClick: () => setActiveRecordTypeSection("card-layout")
      },
      {
        routeName: "eventSettingsModuleTypesReportLayout",
        tabName: "Report Layout",
        onClick: () => setActiveRecordTypeSection("report-layout")
      }
      */
    );

    tabs = R.map(t => ({
      ...t,
      isActive: R.any(
        R.compose(
          R.equals(t.routeName),
          R.prop("name")
        )
      )(routes)
    }))(tabs);

    if (
      R.compose(
        R.not,
        R.length,
        R.filter(
          R.compose(
            R.equals(true),
            R.prop("isActive")
          )
        )
      )(tabs)
    ) {
      tabs = R.set(R.lensPath([0, "isActive"]), true)(tabs);
    }

    const recordTypesWithHandlers = R.map(type => {
      return {
        id: type.id,
        name: type.name,
        icon: type.icon,
        color: type.background_color,
        isDefault: type.is_default,
        isActive: selectedRecordType
          ? selectedRecordType.id === type.id
          : false,
        goToType: () => setActiveRecordType(type.id),
        showEditTypeModal: () => showRecordTypeModal(type)
      };
    })(recordTypes);

    const selectedType = selectedRecordType
      ? {
          id: selectedRecordType.id,
          color: selectedRecordType.background_color,
          icon: selectedRecordType.icon,
          name: selectedRecordType.name,
          isDefault: selectedRecordType.is_default,
          permissionSetId: selectedRecordType.permission_set_id,
          layoutId: selectedRecordType.layout_id,
          menuItems: [
            ["Edit", () => showRecordTypeModal(selectedRecordType)],
            [
              "Set as default",
              () => handleUpdateDefaultRecordType(selectedRecordType)
            ],
            ["Delete", () => showDeleteRecordTypeModal(selectedRecordType)]
          ]
        }
      : {};

    return (
      <View
        {...{
          tabs,
          typeIsSelected: Boolean(selectedRecordType),
          types: recordTypesWithHandlers,
          selectedType,
          activeRecordTypeSection: R.compose(
            R.prop("routeName"),
            R.find(
              R.compose(
                R.equals(true),
                R.prop("isActive")
              )
            )
          )(tabs),
          showAddTypeModal: () => showRecordTypeModal()
        }}
      />
    );
  }
}

export default Internal;
