/* eslint-disable no-underscore-dangle */

import * as R from "ramda";

import { createSelector } from "reselect";

import { getters } from "EventLight/ProductionSchedules/Schedule";
import { getters as TableGetters } from "ui-kit/Table/model";
import { getters as allowAccessModalGetters } from "Schedules/AllowAccessUsersModal";

import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";

import * as STANDARD_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import {
  TABLE_INSTANCE_ID,
  DEFAULT_SELECTED_USER_PROFILES,
  QTY_FIELDS_GROUP_BY,
  GROUP_BY_DATE_VALUE,
  GROUP_BY_BUTTONS,
  WITH_POPOVER_BUTTON,
  ROW_ACTIONS,
  SCHEDULE_VIEWS,
  SCHEDULE_VIEW_IDS
} from "./constants";
import { COLUMN_ACTIONS } from "ui-kit/Table/constants";

import { ACTION_TYPES } from "ui-kit/Table/constants";

import { eventDetails as getEventDetails } from "redux/modules/event/selectors";

export const getKey = (_, props) => R.prop("instanceId", props);

const compareUserId = selectedUserId =>
  R.compose(
    R.equals(selectedUserId),
    R.compose(
      R.toString,
      R.prop("user_id")
    )
  );
const mapSelectedIds = (users, profiles) => (
  { users: userIds, profiles: profileIds },
  selectedId
) => ({
  users: R.any(compareUserId(selectedId), users)
    ? [...userIds, selectedId]
    : [...userIds],
  profiles: R.any(R.propEq("id", selectedId), profiles)
    ? [...profileIds, selectedId]
    : [...profileIds]
});

// schedule
export const getIsViewingMasterSchedule = createSelector(
  (_, props) => R.pathEq(["params", "moduleId"], "master")(props),
  Boolean
);

export const isMasterSchedule = createSelector(
  getters.scheduleId,
  R.equals("master")
);

export const getIsSchedulePrivate = createSelector(
  getters.schedule,
  schedule => R.propOr(false, "is_private", schedule)
);

export const schedulesList = createSelector(
  getters.schedules,
  R.compose(
    R.map(schedule => ({
      label: R.prop("name")(schedule),
      value: R.prop("id")(schedule)
    })),
    R.reject(R.propEq("id", "master"))
  )
);

export const getEditorsCount = createSelector(
  getters.schedule,
  schedule => {
    const { users, profiles } = R.propOr(
      DEFAULT_SELECTED_USER_PROFILES,
      "share_with",
      schedule
    );
    return users.length + profiles.length;
  }
);

export const selectedEditors = createSelector(
  allowAccessModalGetters.selectedItemIds,
  allowAccessModalGetters.users,
  allowAccessModalGetters.permissionProfiles,
  (selectedIds, users, profiles) =>
    R.reduce(
      mapSelectedIds(users, profiles),
      DEFAULT_SELECTED_USER_PROFILES,
      selectedIds
    )
);

// filters: fields
export const getFieldFilters = createSelector(
  getters.columns,
  getters.moduleId,
  getters.preferences,
  (fields, moduleId, preferences) => {
    const readOnlyFields = resolveReadOnlyFields({
      for: "filter",
      moduleId
    });
    return R.compose(
      R.sortBy(f => preferences.sort_by[f.id]),
      R.filter(
        f =>
          !f.settings.allowMultipleSelect &&
          !readOnlyFields.includes(f.id) &&
          preferences.visible_fields.includes(f.id)
      )
    )(fields);
  }
);
export const getFieldFiltersSelectedCount = createSelector(
  getters.preferences,
  R.pathOr(0, ["filters", "filters", "filters", "length"])
);

export const getSelectedFieldFilters = createSelector(
  getFieldFilters,
  getters.preferences,
  (fields, preferences) => {
    const filters = R.pathOr({}, ["filters", "filters"])(preferences);
    if (filters.filters) {
      filters.filters.map(filter => {
        filter.title = R.compose(
          R.prop("name"),
          R.find(R.propEq("id", filter.fieldId))
        )(fields);
        return filter;
      });
    }
    return filters;
  }
);

// filters: bar
const getItemFiltersOn = createSelector(
  state => getters.preferences(state).filters,
  (...args) =>
    R.any(
      R.compose(
        R.not,
        val =>
          val === null || typeof val === "boolean" ? !val : R.isEmpty(val)
      ),
      args
    )
);

export const getFiltersOn = createSelector(
  getItemFiltersOn,
  (...args) => R.any(R.identity, args)
);

// filters: sort by
export const getSortBy = createSelector(
  getters.columns,
  state => getters.preferences(state).sort_by,
  (fields, sortBy) =>
    R.compose(
      R.filter(R.prop("name")),
      R.map(s => ({
        ...s,
        name: R.compose(
          R.prop("name"),
          R.find(R.propEq("id", s.fieldId))
        )(fields)
      })),
      R.filter(s => !s.isDefault)
    )(sortBy)
);

export const getSortByDefaultExists = createSelector(
  state => getters.preferences(state).sort_by,
  sortBy => R.any(s => s.isDefault)(sortBy)
);

export const isOpened = (state, props) =>
  R.contains(props.id, getters.openedMenues(state, props));

export const getToggledRows = createSelector(
  state =>
    TableGetters.toggledRows(state, {
      instanceId: TABLE_INSTANCE_ID
    }),
  R.identity
);

export const getCountOfToggledRows = createSelector(
  getToggledRows,
  R.length
);

export const getFieldsForEditModal = createSelector(
  getters.columns,
  getters.moduleId,
  getters.preferences,
  (fields, moduleId, preferences) => {
    const readOnlyFields = resolveReadOnlyFields({
      moduleId
    });
    return R.compose(
      R.sortBy(f => preferences.field_order[f.id]),
      R.filter(
        f =>
          preferences.visible_fields.includes(f.id) &&
          ![
            "form",
            "document-request",
            "item-group",
            "payment",
            "primary-contacts",
            "allocated-passes",
            "allocated-meals",
            "signature"
          ].includes(f.type) &&
          ![STANDARD_FIELD_IDS.ACCOUNTS.NAME].includes(f.id) &&
          !readOnlyFields.includes(f.id)
      )
    )(fields);
  }
);

export const getActiveViewId = createSelector(
  getters.preferences,
  R.prop("id")
);

export const getHasViewChanged = createSelector(
  getters.preferences,
  getters.originalPreferences,
  (preferences, originalPreferences) => {
    return !R.equals(preferences, originalPreferences);
  }
);

const mappedSchedules = createSelector(
  getters.schedules,
  R.map(({ id, name, visible = null }) => ({
    // count_of_activities = null
    id,
    name,
    // info: count_of_activities || 0,
    selected: visible || false
  }))
);

export const getIndividualSchedules = createSelector(
  mappedSchedules,
  schedules =>
    R.compose(
      R.sortBy(
        R.compose(
          R.toLower,
          R.prop("name")
        )
      ),
      R.filter(
        R.compose(
          R.not,
          R.propEq("id", "master")
        )
      )
    )(schedules)
);

export const getRowActions = R.always([
  //@NOTE: implement duplicate and insert
  {
    id: ROW_ACTIONS.REMOVE,
    type: ACTION_TYPES.ITEM,
    name: "Remove row",
    leftIcon: "remove"
  },
  {
    id: ROW_ACTIONS.OPEN_ACTIVITY,
    type: ACTION_TYPES.ITEM,
    name: "Edit activity",
    leftIcon: "edit"
  },
  {
    id: ROW_ACTIONS.DUPLICATE,
    type: ACTION_TYPES.ITEM,
    name: "Duplicate",
    leftIcon: "content_copy"
  }
]);

export const getColumnActions = createSelector(
  isMasterSchedule,
  isMaster =>
    R.filter(R.prop("visible"), [
      {
        id: COLUMN_ACTIONS.EDIT,
        type: ACTION_TYPES.ITEM,
        name: "Edit Column",
        leftIcon: "edit",
        visible: true
      },
      {
        id: COLUMN_ACTIONS.HIDE,
        type: ACTION_TYPES.ITEM,
        name: "Hide Column",
        leftIcon: "remove_red_eye",
        visible: true
      },
      {
        id: COLUMN_ACTIONS.GROUP_BY,
        type: ACTION_TYPES.ITEM,
        name: "Group by",
        leftIcon: "group_work",
        visible: true
      },
      {
        type: ACTION_TYPES.SEPARATOR,
        visible: !isMaster
      },
      {
        id: COLUMN_ACTIONS.INSERT_LEFT,
        type: ACTION_TYPES.ITEM,
        name: "Insert Left",
        leftIcon: "arrow_back",
        visible: !isMaster
      },
      {
        id: COLUMN_ACTIONS.INSERT_RIGHT,
        type: ACTION_TYPES.ITEM,
        name: "Insert Right",
        leftIcon: "arrow_forward",
        visible: !isMaster
      }
    ])
);

export const getGroupedByField = createSelector(
  getters.preferences,
  R.propOr(null, "grouped_by")
);

export const availableFieldsToGroupBy = createSelector(
  getters.columns,
  getters.showAllGroupByFields,
  (fields, showAllGroupByFields) =>
    R.compose(
      R.take(showAllGroupByFields ? fields.length : QTY_FIELDS_GROUP_BY),
      R.sortBy(
        R.compose(
          R.toLower,
          R.prop("name")
        )
      )
    )(fields)
);

export const getGroupByButtons = createSelector(
  availableFieldsToGroupBy,
  fields => {
    if (!fields || !fields.length) {
      return GROUP_BY_BUTTONS.filter(b => b.type !== WITH_POPOVER_BUTTON);
    }
    return GROUP_BY_BUTTONS;
  }
);

export const quantityFields = createSelector(
  getters.columns,
  fields => R.length(fields)
);

export const selectedGroupByFieldName = createSelector(
  getters.columns,
  getGroupedByField,
  (fields, selectedFieldId) =>
    !R.isEmpty(selectedFieldId)
      ? R.propOr("", "name", R.find(R.propEq("id", selectedFieldId))(fields))
      : ""
);

export const getResourceOptions = createSelector(
  getters.resourceOptions,
  R.map(resource => ({ ...resource, title: resource.name }))
);

export const getSelectedView = createSelector(
  getters.preferences,
  preferences =>
    SCHEDULE_VIEW_IDS.includes(R.prop("mode", preferences))
      ? R.prop("mode", preferences)
      : SCHEDULE_VIEWS.GRID
);

export const getSelectedViewForRendering = createSelector(
  getters.preferences,
  preferences =>
    SCHEDULE_VIEW_IDS.includes(R.prop("mode", preferences))
      ? SCHEDULE_VIEWS.CALENDAR
      : SCHEDULE_VIEWS.GRID
);

export const getScheduleTimezone = (state, props) =>
  R.prop("timezone", getters.schedule(state, props)) ||
  R.prop("timezone", getEventDetails(state, props));

export const getModuleId = (state, props) => {
  const moduleId = getters.moduleId(state, props);
  return moduleId === "master" ? STANDARD_MODULE_IDS.schedule.id : moduleId;
};

export const getTabs = createSelector(
  getters.preferences,
  () => {
    return [
      {
        id: SCHEDULE_VIEWS.GRID,
        title: "List"
      },
      {
        id: SCHEDULE_VIEWS.MONTH,
        title: "Month"
      },
      {
        id: SCHEDULE_VIEWS.WEEK,
        title: "Week"
      },
      {
        id: SCHEDULE_VIEWS.RESOURCE,
        title: "Resource"
      }
    ];
  }
);
