import { registerError } from "redux/modules/errors/actions";
import api from "./api";

export function syncTicketClasses(data) {
  return async (dispatch, getState) => {
    try {
      return await api.post(getState().user.user.credentials, data);
    } catch (error) {
      return dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred saving Eventbrite ticket classes"
          }
        ])
      );
    }
  };
}
