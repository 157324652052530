import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Editor from "./Editor";

import { getAccountAsPortal } from "redux/modules/accounts/profile/actions";
import { addValue } from "redux/modules/modules/values/actions";
import { account } from "redux/modules/accounts/profile/selectors";

function mapStateToProps(state) {
  return {
    account: account(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addValue,
      getAccount: getAccountAsPortal
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
