import EventHandler from "components/Event";
import EventHomeHandler from "Event/Home/View";
import EventHomeContent from "Event/Home/View/Main";
import EventHomeMyApprovals from "Event/MyApprovals/View/Page";
import EventHomeSetupChecklists from "Event/Home/View/SetupChecklists";

import SettingsHandler from "components/Event/Settings";
import SettingsSettingsHandler from "components/Event/Settings/EventSettings";
import SettingsUsersHandler from "components/Event/Settings/Users";
import SettingsCredentials from "components/Event/Settings/Credentials";
import SettingsCatering from "components/Event/Settings/Catering";
import SettingsInventoryCatalog from "components/Event/Settings/Inventory/Catalog";
import SettingsEmailTemplates from "components/Event/Settings/EmailTemplates";
import SettingsDocumentTemplates from "components/Event/Settings/DocumentTemplates/View";
import SettingsFormTemplates from "components/Event/Settings/Forms";
import SettingsPortal from "components/Event/Settings/Portal";
import SettingsSalesPortal from "SalesPortal/Settings/View";
import SettingsLocations from "components/Event/Settings/Locations";
import SettingsCatalog from "components/Event/Settings/Catalog";
import SettingsCatalogQuestions from "Items/Questions/View";
import SettingsCatalogZones from "Event/Zones/View/Layout";
import RefactorSettingsCatalog from "Event/ItemCatalog/View";
import RefactorCatalogItems from "Event/ItemCatalogItems/View";
import ItemCatalogApprovers from "Event/ItemCatalogApprovers/View";
import ItemCatalogInventory from "Event/ItemCatalogInventory/View";
import AccessGrid from "Passes/AccessGrid/View/Layout";
import Actions from "Passes/Actions/View/Layout";

import SettingsOrders from "components/Event/Settings/Orders";
import SettingsSchedules from "components/Event/Settings/Schedules/View";
import SettingsApprovalWorkflows from "components/Event/Settings/ApprovalWorkflows";
import SettingsIntegrationFuzion from "Integrations/Fuzion/View";

import SettingsIntegrations from "SettingsIntegrations/View";

import EventSettingsModule from "components/Event/Settings/Module";

import Reports from "components/Event/Reports";
import Report from "components/Event/Reports/Report";

import Files from "Files/Manage/View";

import ContactProfileContainer from "components/Event/ContactProfile";
import Locations from "components/Event/Locations";

import AccountsQuickSetup from "Accounts/QuickSetup/View";

import MyTasks from "Event/MyTasks/View";
import EventActivities from "Event/Activities/View";

import PortalSettingsPage from "Portal/Settings/Page/View";

import { hot } from "App/Config/hot";

const Event = {
  EventHandler,
  EventHomeContent,
  EventHomeHandler,
  EventHomeMyApprovals,
  EventHomeSetupChecklists,
  SettingsHandler,
  SettingsSettingsHandler,
  SettingsUsersHandler,
  SettingsCredentials,
  SettingsCatering,
  SettingsInventoryCatalog,
  SettingsEmailTemplates,
  SettingsDocumentTemplates,
  SettingsFormTemplates,
  SettingsPortal,
  SettingsSalesPortal,
  SettingsLocations,
  SettingsCatalog,
  SettingsOrders,
  SettingsSchedules,
  SettingsApprovalWorkflows,
  SettingsIntegrationFuzion,
  EventSettingsModule,
  Reports,
  Report,
  ContactProfileContainer,
  Locations,
  Files,
  SettingsIntegrations,
  SettingsCatalogQuestions,
  SettingsCatalogZones,
  RefactorSettingsCatalog,
  RefactorCatalogItems,
  ItemCatalogApprovers,
  AccessGrid,
  Actions,
  AccountsQuickSetup,
  MyTasks,
  PortalSettingsPage,
  EventActivities,
  ItemCatalogInventory
};

export default hot(Event);
