import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import CSSModules from "react-css-modules";
import moment from "moment-timezone";
import cx from "classnames";

import { noop } from "utils/General";

import { actions, getters } from "../model";

import MonthCalendar from "./MonthCalendar";

import { LeftIcon, RightIcon } from "components/Base";
import Circle from "ui-kit/Circle";
import styles from "./calendarStyles.scss";

import { getDateGroups, getDayOcurrences } from "../selectors";

const Day = ({ date, currentDate, dayOcurrences = {}, onClick = noop }) => {
  return (
    <td>
      <div style={{ position: "relative" }}>
        {R.path([date.format("YYYY-MM-DD"), "hasEvents"], dayOcurrences) ? (
          <Circle size={4} bg="red" className="has-events">
            a
          </Circle>
        ) : null}
        <Circle
          size={24}
          bg="transparent"
          className={cx(
            "month-body-day",
            R.pathOr(
              "",
              [date.format("YYYY-MM-DD"), "colorClass"],
              dayOcurrences
            ),
            {
              current: moment(date).isSame(currentDate, "day")
            }
          )}
          onClick={() => onClick(date)}
        >
          {date.date()}
        </Circle>
      </div>
    </td>
  );
};

const decorate = R.compose(
  connect(
    (state, props) => ({
      dateGroups: getDateGroups(state, props),
      dayOcurrences: getDayOcurrences(state, props),
      currentMonthDate: getters.currentMonthDate(state, props),
      timezone: getters.timezone(state, props)
    }),
    bindInstance({
      onDayClick: actions.setCurrentDate,
      prev: actions.prevMonth,
      next: actions.nextMonth,
      jump: actions.jump
    })
  ),
  CSSModules(styles)
);

const EventDatesCalendar = ({
  dateGroups = [],
  dayOcurrences = {},
  currentMonthDate,
  timezone,
  onDayClick = noop,
  closePopover = noop,
  prev = noop,
  next = noop,
  jump = noop
}) => (
  <div styleName="event-days-calendar">
    {dateGroups && dateGroups.length ? (
      <div styleName="jump-to-group">
        <div
          styleName="jump-to"
          onClick={() => {
            closePopover();
            jump(moment());
          }}
        >
          Today's Date
        </div>
        <div styleName="jump-to-start">JUMP TO START OF:</div>
        {R.map(
          group => (
            <div
              styleName="jump-to"
              onClick={() => {
                closePopover();
                jump(moment(group.firstDate).tz(timezone));
              }}
            >
              <Circle
                bg={group.color}
                size={10}
                style={{ marginTop: 4, marginRight: 8 }}
              />
              <div styleName="jump-text">{group.name}</div>
            </div>
          ),
          dateGroups
        )}
      </div>
    ) : null}
    <div>
      <div styleName="navigate-month">
        <div styleName="arrow-container" onClick={prev}>
          <LeftIcon color="black" size={26} />
        </div>
        <div styleName="month-name">
          {moment(currentMonthDate).format("MMMM YYYY")}
        </div>
        <div styleName="arrow-container" onClick={next}>
          <RightIcon color="black" size={26} />
        </div>
      </div>
      <MonthCalendar
        currentMonthDate={currentMonthDate}
        DayComponent={Day}
        dayOcurrences={dayOcurrences}
        onDayClick={(...params) => {
          closePopover();
          onDayClick(...params);
        }}
        timezone={timezone}
      />
    </div>
  </div>
);

export default decorate(EventDatesCalendar);
