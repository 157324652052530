export default (collaborators, options = {}) => {
  const user = collaborators.find(c => c.is_primary);
  if (user) {
    const { fname, lname, email, account_name } = user;
    if (account_name) {
      if (options.onlyShowAccountName) {
        return account_name;
      }
      return `${account_name} (${fname ? `${fname} ${lname}` : email})`;
    }
    return fname ? `${fname} ${lname}` : email;
  }
  return "";
};
