import * as R from "ramda";

import { all, spawn } from "redux-saga/effects";

import globalModule from "./modules/global";

const rootSaga = function*() {
  const sagas = R.filter(
    R.compose(
      R.not,
      R.isNil
    ),

    R.map(R.prop("sagas"), globalModule.all)
  );
  yield all(R.map(spawn, sagas));
};

export default rootSaga;
