import * as R from "ramda";

export const mapActionItemStatus = ({ status, required, pastDue }) => {
  if (required && R.contains(status, ["not_submitted", "incomplete"])) {
    if (pastDue) {
      return "alert_error";
    }
    return "alert";
  }
  if (R.contains(status, ["approved", "pending", "complete"])) {
    return "done";
  }
  return "not_required";
};
