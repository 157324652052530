import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  list: [],
  lifecycleList: []
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    deleteEmailTemplate: R.identity,
    updateEmailTemplate: R.identity,
    getEmailTemplates: R.identity,
    createEmailTemplate: R.identity,
    duplicateEmailTemplate: R.identity,
    openAddTemplateModal: R.identity,
    openEditTemplateModal: R.identity,
    updateLifecycleEmailTemplate: R.identity
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
