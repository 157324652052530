import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
require("flatpickr/dist/themes/airbnb.css");
import moment from "moment";

import { noop } from "utils/General";

import Flatpickr from "react-flatpickr";

import { WithFormInstanceConsumer } from "../Context";

import { actions } from "../../model";
import { getValue } from "../../selectors";
import { FIELD_TYPES, DEFAULT_DATE_FORMAT } from "../../constants";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

const decorate = R.compose(
  WithFormInstanceConsumer({
    fieldType: FIELD_TYPES.DATETIME
  }),
  connect(
    (state, props) => ({
      value: getValue(state, props)
    }),
    bindInstance({
      onChange: actions.setFieldValue,
      setIniValue: actions.setIniValue
    })
  ),
  CSSModules(css)
);

const formatDate = (date, format) =>
  moment([
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  ]).format(format);

export const DateTimeInput = decorate(
  ({
    format = DEFAULT_DATE_FORMAT,
    dateFormat = "",
    disabled,
    fieldId,
    fieldType,
    onChange = noop,
    value = "",
    iniValue,
    setIniValue = noop,
    inputClassName = css.dateTimeInput,
    altFormat = "MMM D, YYYY @ h:mm a",
    enableTime = true,
    noCalendar = false,
    minDate = null,
    maxDate = null,
    "data-cy": dataCy
  }) => {
    useEffect(() => {
      setIniValue(iniValue, {
        meta: { fieldId, fieldType, format: dateFormat }
      });
    }, []);

    return (
      <Flatpickr
        className={inputClassName}
        disabled={disabled}
        options={{
          minDate,
          maxDate,
          altInput: true,
          altFormat: altFormat,
          enableTime,
          noCalendar,
          minuteIncrement: 1,
          dateFormat,
          formatDate
        }}
        value={value}
        onChange={val => {
          onChange(val[0], {
            meta: { fieldId, fieldType, format }
          });
        }}
        data-cy={dataCy}
      />
    );
  }
);
