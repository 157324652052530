import React from "react";
import { withRouter } from "react-router";
import {
  Div,
  Text2,
  MediumOutlineButton,
  CheckIcon,
  Text0
} from "components/Base";

const ReviewedRow = ({
  name,
  router: { push },
  barText,
  total,
  url,
  btnText = ""
}) => (
  <Div
    px={2}
    bg={{ default: "neutral0", hover: "neutral1" }}
    transition="fast"
    bra={1}
    display="row.flex-start.center"
    height={52}
    className="hoverContainer"
  >
    <Div display="row.space-between.center" flex={1} pr={3}>
      <Div display="row.flex-start.center">
        <CheckIcon color="neutral6" size={24} mr={4} />
        <Text2 bold color="black">
          {name}
        </Text2>
      </Div>
      <Div
        width={265}
        height={17}
        bg="neutral1"
        bra={2}
        style={{ overflow: "hidden", position: "relative" }}
        display="row.flex-start.center"
      >
        <Div
          height={1}
          width={1}
          bg="lime4"
          px={2}
          display="row.flex-start.center"
        >
          <Text0 bold color="white">
            {barText}
          </Text0>
        </Div>
        <Text0
          style={{ position: "absolute", right: "5px" }}
          bold
          color="black"
        >{`${total}/${total}`}</Text0>
      </Div>
    </Div>
    <Div width={95}>
      <MediumOutlineButton className="showOnHover" onClick={() => push(url)}>
        {btnText}
      </MediumOutlineButton>
    </Div>
  </Div>
);

export default withRouter(ReviewedRow);
