import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import uuid from "node-uuid";
import DropdownOption from "components/Global/Table3/ModalColumn/DropdownOption";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Draggable from "../Draggable";
import update from "immutability-helper";
import Bulk from "./Bulk";

@CSSModules(css)
class DropdownOptions extends Component {
  state = { addBulkOptions: false };

  componentWillMount() {
    // auto add an option if there are none
    if (!this.props.settings.options.length) {
      this.addEmpty([]);
    }
  }

  componentWillReceiveProps(newProps) {
    // force one input open at all times
    if (!newProps.settings.options.length) {
      this.addEmpty([]);
    }
  }

  toggleBulkOptions = () => {
    this.setState({ addBulkOptions: !this.state.addBulkOptions });
  };

  addEmpty(options) {
    return this.handleOptionChange(options.length, {});
  }

  @autobind
  handleMultipleSelectChange() {
    return this.handleChange({
      allowMultipleSelect: !this.props.settings.allowMultipleSelect
    });
  }

  @autobind
  handleChange(setting) {
    this.props.onChange({ ...this.props.settings, ...setting });
  }

  @autobind
  handleOptionChange(idx, value) {
    const options = [...this.props.settings.options];
    options[idx] = { ...options[idx], ...value };

    if (!options[idx].id) options[idx].id = uuid.v4();

    this.handleChange({ options });
  }

  @autobind
  handleRemove(idx) {
    const options = [...this.props.settings.options];
    options.splice(idx, 1);
    this.handleChange({ options });
  }

  moveOption = (dragIndex, hoverIndex) => {
    const { options } = this.props.settings;
    const dragField = options[dragIndex];
    const newOptions = update(this.props.settings.options, {
      $splice: [[dragIndex, 1], [hoverIndex, 0, dragField]]
    });
    this.handleChange({ options: newOptions });
  };

  addOptions = list => {
    this.toggleBulkOptions();
    const options = [
      ...this.props.settings.options,
      ...list.map(value => ({ id: uuid.v4(), value }))
    ];
    this.handleChange({ options });
  };

  render() {
    const { showColorPicker, inputType, settings } = this.props;
    return (
      <div>
        <div>
          {settings.options.map((value, index) => (
            <Draggable
              key={index}
              dndId="DROPDOWN_FIELD_SETTINGS"
              index={index}
              moveCard={this.moveOption}
            >
              <DropdownOption
                handleOptionChange={this.handleOptionChange}
                handleRemove={this.handleRemove}
                index={index}
                inputType={inputType}
                isLastOption={false}
                showColorPicker={showColorPicker}
                value={value}
              />
            </Draggable>
          ))}
          {this.state.addBulkOptions ? (
            <Bulk onSave={this.addOptions} onCancel={this.toggleBulkOptions} />
          ) : (
            <div styleName="actions">
              <div
                styleName="textAction"
                onClick={() => this.addEmpty(settings.options)}
              >
                <i className="material-icons">add_circle</i> Add option
              </div>
              <div styleName="textAction" onClick={this.toggleBulkOptions}>
                <i className="material-icons">playlist_add</i> Add multiple
              </div>
            </div>
          )}
        </div>
        {settings.enableMultipleSelect && (
          <div className="input-wrapper">
            <label styleName="checkboxWrapper">
              <input
                type="checkbox"
                disabled={!!this.props.editing}
                onChange={this.handleMultipleSelectChange}
                checked={settings.allowMultipleSelect}
              />
              <div styleName="checkboxLabel">Allow Multiple Select?</div>
            </label>
          </div>
        )}
      </div>
    );
  }
}

DropdownOptions.defaultProps = {
  showColorPicker: true,
  inputType: "text"
};

DropdownOptions.propTypes = {
  editing: PropTypes.bool,
  showColorPicker: PropTypes.bool,
  inputType: PropTypes.string,
  settings: PropTypes.shape({
    allowMultipleSelect: PropTypes.bool.isRequired,
    enableMultipleSelect: PropTypes.bool.isRequired,
    options: PropTypes.array.isRequired
  }).isRequired
};

export default DropdownOptions;
