import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import getValue from "utils/value-types/get-value/lookup";
import Picker from "./Picker";
import { get } from "lodash";
import * as SYSTEM_FIELD_IDS from "utils/system-field-ids";

const { ID } = SYSTEM_FIELD_IDS;

class LookupEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recordId: this.formatValue(props.value).recordId
    };
  }

  componentWillReceiveProps(props) {
    const recordId = get(this.formatValue(props.value), ["records", 0]);
    if (recordId !== this.state.recordId) {
      this.setState(
        {
          recordId
        },
        () => {
          // Since autoload is true, we get the first value resolution for free
          // but if we update it programmatically we need to trigger a refresh manually
          // See https://github.com/JedWatson/react-select/issues/761
          this.select.loadOptions("");
        }
      );
    }
  }

  @autobind
  getInputNode() {
    return this.picker;
  }

  getModuleId() {
    return (
      this.props.moduleId ||
      get(this.props, "rowMetaData.meta.columnSettings.moduleId")
    );
  }

  getFieldId() {
    // @NOTE: We're falling back to the system field ID if a field is not specified on the lookup
    return (
      this.props.fieldId ||
      get(this.props, "rowMetaData.meta.columnSettings.fieldId", ID)
    );
  }

  @autobind
  wrapValue(record) {
    return {
      type: "lookup",
      value: record
        ? {
            moduleId: this.getModuleId(),
            recordId: record.id
          }
        : undefined
    };
  }

  formatValue(value) {
    return getValue(value);
  }

  @autobind
  selectRecord(record) {
    const moduleId = this.getModuleId();
    if (record) {
      this.props.addReference(moduleId, {
        [record.id]: record.value
      });
    }
    this.props.onChange(
      this.wrapValue(
        record
          ? {
              id: record.id
            }
          : null
      )
    );
    this.setState({
      recordId: record ? record.id : ""
    });
  }

  render() {
    const { search, params, innerRef, noTether } = this.props;
    const moduleId = this.getModuleId();
    const primaryFieldId = this.getFieldId();

    return (
      <Picker
        ref={ref => (this.picker = ref)}
        noTether={noTether}
        primaryFieldId={primaryFieldId}
        params={params}
        moduleId={moduleId}
        search={search}
        selectRef={c => {
          this.select = c;
          if (innerRef) innerRef(c);
        }}
        selectedRecordId={this.state.recordId}
        selectRecord={this.selectRecord}
        multi={false}
        {...this.props}
      />
    );
  }
}

LookupEditor.propTypes = {
  addReference: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  params: PropTypes.object.isRequired,
  references: PropTypes.object.isRequired,
  search: PropTypes.func.isRequired,
  value: PropTypes.object,
  moduleId: PropTypes.string,
  fieldId: PropTypes.string
};

export default LookupEditor;
