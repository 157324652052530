import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import getValue from "utils/value-types/get-value/reference";
import Search from "./Search";
import { pick } from "lodash";
import constructReferenceMap from "./utils/construct-map";

const referenceProperties = [
  "count",
  "first",
  "last",
  "id",
  "owner",
  "placeholder",
  "type"
];

const wrapValue = value => ({
  type: "reference",
  value: value || undefined
});

export default class Reference extends Component {
  @autobind
  onSelect(referenceId) {
    const referenceMap = constructReferenceMap(this.props.references);
    if (referenceId && referenceId in referenceMap) {
      const reference = pick(referenceMap[referenceId], referenceProperties);

      // Hydrate owner if one exists
      if (reference.owner && reference.owner in referenceMap) {
        reference.owner = pick(
          referenceMap[reference.owner],
          referenceProperties
        );
      }
      return this.props.onChange(wrapValue(reference));
    }
    // Clear value
    return this.props.onChange(wrapValue(null));
  }

  @autobind
  addReferenceDangerous(...args) {
    const { user, eventId } = this.props;
    return this.props.addReferenceDangerous(eventId, user.id, ...args);
  }

  render() {
    const {
      references,
      placeholder,
      disabled,
      value,
      values,
      open,
      isFetching
    } = this.props;

    return (
      <Search
        addReferenceDangerous={this.addReferenceDangerous}
        disabled={disabled}
        isFetching={isFetching}
        onSelect={this.onSelect}
        open={open}
        placeholder={placeholder}
        references={references}
        referenceValues={values}
        types={["user"]}
        value={getValue(value)}
      />
    );
  }
}

Reference.propTypes = {
  addReferenceDangerous: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  eventId: PropTypes.string.isRequired,
  getReferences: PropTypes.func.isRequired,
  isFetching: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  open: PropTypes.bool,
  placeholder: PropTypes.string,
  references: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  value: PropTypes.object,
  values: PropTypes.object.isRequired
};
