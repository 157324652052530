import { REQUEST, RECEIVE } from "./constants";
import * as selectors from "./selectors";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";
import { FORBIDDEN_OPERATION_ERROR } from "constants/ErrorMessages";

export function getEventUserPermissionProfile(eventId, userId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({
        type: REQUEST,
        payload: {
          key: eventId,
          userId
        }
      });
      let payload;
      try {
        const result = await api.get(
          getState().user.user.credentials,
          eventId,
          userId
        );
        payload = result.payload;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log("[Error: getEventUserPermissionProfile]:", err);
      }
      dispatch({
        type: RECEIVE,
        payload: {
          key: eventId,
          userId,
          permissionProfile: payload ?? { dontHavePermission: true }
        }
      });
    } catch (error) {
      if (error.status === FORBIDDEN_OPERATION_ERROR) {
        dispatch({
          type: RECEIVE,
          payload: {
            key: eventId,
            userId,
            permissionProfile: { dontHavePermission: true }
          }
        });
      } else {
        dispatch(
          registerError([
            {
              system: error,
              user: "An error occurred fetching event user permission profile"
            }
          ])
        );
      }
    }
  };
}

export function getOrganizationUserPermissionProfile(orgId, userId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      const { payload } = await api.getOrganization(
        getState().user.user.credentials,
        orgId,
        userId
      );
      dispatch({
        type: RECEIVE,
        payload: {
          key: orgId,
          userId,
          permissionProfile: payload
        }
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user:
              "An error occurred fetching organization user permission profile"
          }
        ])
      );
    }
  };
}
