import { createSelector } from "reselect";
import * as R from "ramda";
import { TAB_IDS } from "./constants";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";

const isActiveRoute = (routes, route) => {
  if (Array.isArray(route)) {
    return R.compose(
      Boolean,
      R.length,
      R.intersection(route),
      R.map(R.prop("name"))
    )(routes);
  }

  return routes.some(r => r.name === route);
};

export const getCurrentRoute = createSelector(
  (_, props) => props.routes,
  routes => {
    if (R.any(R.propEq("name", "EventLightHealthPassManage"))(routes)) {
      return TAB_IDS.MANAGE;
    } else if (
      R.any(R.propEq("name", "EventLightHealthPassQuestions"))(routes)
    ) {
      return TAB_IDS.QUESTIONS;
    } else if (R.any(R.propEq("name", "EventLightHealthPassEmails"))(routes)) {
      return TAB_IDS.EMAILS;
    } else if (R.any(R.propEq("name", "EventLightHealthPassAssign"))(routes)) {
      return TAB_IDS.ASSIGN;
    }
    return null;
  }
);

export const getLinks = createSelector(
  (_, props) => props.params,
  getCurrentRoute,
  (params, currentRoute) => {
    return [
      {
        key: TAB_IDS.MANAGE,
        label: "Manage",
        active: currentRoute === TAB_IDS.MANAGE,
        to: `/event-light/${params.eventId}/health-pass/manage`,
        visible: true
      },
      {
        key: TAB_IDS.SETTINGS,
        label: "Settings",
        active: [TAB_IDS.QUESTIONS, TAB_IDS.EMAILS, TAB_IDS.ASSIGN].includes(
          currentRoute
        ),
        to: `/event-light/${params.eventId}/health-pass/settings/questionnaire`,
        visible: true
      }
    ].filter(l => l.visible);
  }
);

export const getTabs = createSelector(
  (_, props) => props.routes,
  getEventDetails,
  getCurrentRoute,
  (routes, eventDetails, currentRoute) => {
    if (
      [TAB_IDS.QUESTIONS, TAB_IDS.EMAILS, TAB_IDS.ASSIGN].includes(currentRoute)
    ) {
      return [
        {
          key: "questions",
          label: "Questionnaire",
          active: isActiveRoute(routes, "EventLightHealthPassQuestions"),
          to: `/event-light/${eventDetails.id}/health-pass/settings/questionnaire`
        },
        /*
        {
          key: "emails",
          label: "Emails",
          active: isActiveRoute(routes, "EventLightHealthPassEmails"),
          to: `/event-light/${eventDetails.id}/health-pass/settings/emails`
        },
          */
        {
          key: "assign",
          label: "Assign",
          active: isActiveRoute(routes, "EventLightHealthPassAssign"),
          to: `/event-light/${eventDetails.id}/health-pass/settings/assign`
        }
      ];
    }

    return [];
  }
);
