import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import { Div } from "components/Base";

import { getFiles } from "Portal/PortalHome/selectors";
import { actions } from "Portal/PortalHome/model";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { TASK_ACTIONS } from "../constants";

const decorate = R.compose(
  connect(
    state => ({
      files: getFiles(state)
    }),
    {
      executeAction: actions.executeAction
    }
  ),
  CSSModules(css)
);

const getIcon = type =>
  R.prop(type)({
    excel: (
      <Div width={25} height={25} ml={1}>
        <img
          src="https://d2dks4tzxs6xee.cloudfront.net/img/portal-icon-people.png"
          width="25"
          height="25"
        />
      </Div>
    ),
    word: (
      <Div width={25} height={25} ml={1}>
        <img
          src="https://d2dks4tzxs6xee.cloudfront.net/img/portal-icon-task.png"
          width="25"
          height="25"
        />
      </Div>
    ),
    pdf: (
      <Div width={30} height={30} ml={1}>
        <img
          src="https://d2dks4tzxs6xee.cloudfront.net/img/portal-icon-invoice.png"
          width="30"
          height="30"
        />
      </Div>
    )
  }) || (
    <Div width={26} height={26} ml={1}>
      <img
        src="https://d2dks4tzxs6xee.cloudfront.net/img/portal-icon-document.png"
        width="26"
        height="26"
      />
    </Div>
  );

export const File = ({ executeAction, ...file }) => {
  const { mimetype, label, description, file_url } = file;

  const icon = getIcon(mimetype);

  return (
    <Div
      display="column"
      bg="white"
      px={3}
      py={3}
      bra={3}
      mb={3}
      shadow={{
        default: 0,
        hover: 2
      }}
      style={{
        cursor: "pointer",
        minHeight: "54px"
      }}
    >
      <Div
        display="column"
        width={1}
        onClick={() =>
          executeAction({ type: TASK_ACTIONS.OPEN_FILE, url: file_url })
        }
      >
        <Div display="row.flex-start.flex-start" width={1}>
          {icon}
          <Div display="column" flex={1} ml={2}>
            <Div fs={5} fw={3} color="black">
              {label}
            </Div>
            {description && description.length ? (
              <Div
                width={1}
                fs={3}
                mt={1}
                color="gray6"
                style={{
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word"
                }}
              >
                {description}
              </Div>
            ) : null}
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

const Files = ({ files, executeAction }) => {
  if (!files.length) {
    return null;
  }

  return (
    <Div
      bg="neutral1"
      style={{
        borderRadius: 10,
        padding: "10px 20px",
        marginBottom: "40px"
      }}
    >
      <Div display="row.flex-start.center" mb={4}>
        <Div
          className={css.tab}
          fw={4}
          style={{
            fontSize: "30px"
          }}
        >
          Files
        </Div>
      </Div>
      <Div>
        {files.map(file => (
          <File key={file.id} executeAction={executeAction} {...file} />
        ))}
      </Div>
    </Div>
  );
};

export default decorate(Files);
