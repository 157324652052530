import React from "react";
import * as R from "ramda";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Stepper from "./Stepper";

import {
  Div,
  BigFilledButton,
  LoadingIcon,
  LeftArrowIcon,
  RightArrowIcon
} from "components/Base";
import Types from "./Scenes/Types";
import Items from "./Scenes/Items";
import Fields from "./Scenes/Fields";
import Success from "./Scenes/Success";

const View = ({
  loading,
  steps,
  title,
  subtitle,
  saving,
  hideModal,
  isValid,
  onBack,
  onNext,
  handleSave,
  selectAll,
  removeAll,
  activeStep,
  types,
  activeType,
  countOfSelectedVariants,
  onSearchChange,
  searchTerm,
  cateringMealsPerDay,
  eventDateGroups,
  onSuccessDone
}) => (
  <StyleWrapper
    hideHeader
    hideModal={hideModal}
    bodyStyles={{ padding: 0 }}
    width={530}
  >
    {loading ? (
      <Div pt={20} display="row.center.center">
        <LoadingIcon fs={5} />
      </Div>
    ) : (
      <Div>
        <Div mt={6} mb={3}>
          <Stepper steps={steps} />
        </Div>
        {title ? (
          <Div display="row.center.center" mb={6}>
            <Div display="column.center.center" maxWidth={430}>
              <Div
                color="gray7"
                fs={5}
                fw={3}
                style={{
                  textAlign: "center"
                }}
              >
                {title}
              </Div>
              {subtitle ? (
                <Div
                  color="gray7"
                  fs={2}
                  mt={2}
                  style={{
                    textAlign: "center"
                  }}
                >
                  {subtitle}
                </Div>
              ) : null}
            </Div>
          </Div>
        ) : null}

        {R.prop(activeStep.type, {
          types: <Types {...{ loading, hideModal, handleSave, types }} />,
          items: (
            <Items
              {...{
                loading,
                saving,
                hideModal,
                handleSave,
                activeType,
                selectAll,
                removeAll,
                countOfSelectedVariants,
                onSearchChange,
                searchTerm,
                cateringMealsPerDay,
                eventDateGroups
              }}
            />
          ),
          fields: (
            <Fields
              {...{
                activeStep
              }}
            />
          ),
          success: <Success {...{ onSuccessDone }} />
        })}

        {onNext || onBack ? (
          <Div display="row.space-between.center" py={3} px={5}>
            {onBack ? (
              <BigFilledButton
                bg={!saving && onBack ? "neutral3" : "neutral1"}
                onClick={!saving ? onBack : null}
              >
                <LeftArrowIcon mr={4} color="white" />
                Go back
              </BigFilledButton>
            ) : (
              <Div />
            )}
            <Div>
              {/*
              // @NOTE: Temp hiding Cancel button until
              // we decide if we really need it
              <BigOutlineButton onClick={hideModal} mr={1}>
                Cancel
              </BigOutlineButton>
              */}
              <BigFilledButton
                bg={isValid && !saving ? "altB5" : "neutral1"}
                onClick={isValid && !saving ? onNext : null}
              >
                {saving ? "Saving..." : "Next"}
                {saving ? null : <RightArrowIcon ml={4} color="white" />}
              </BigFilledButton>
            </Div>
          </Div>
        ) : null}
      </Div>
    )}
  </StyleWrapper>
);

export default View;
