import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const ModalInputGroup = ({ children, ...props }) => (
  <div styleName="inputGroup" {...props}>
    {children}
  </div>
);

export default CSSModules(ModalInputGroup, css);
