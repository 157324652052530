import PropTypes from "prop-types";
import React from "react";
import Badge from "components/Atoms/Badge";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Approved = ({ changes }) => (
  <Badge styleName="badge">{`Approved${changes ? " w/ changes" : ""}`}</Badge>
);

Approved.propTypes = {
  changes: PropTypes.bool
};

export default CSSModules(Approved, css);
