import React from "react";

export const Video = ({ url }) => (
  <>
    <div
      style={{
        padding: "43% 0 0 0",
        position: "relative"
      }}
    >
      <iframe
        src={`${url}?byline=0`}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%"
        }}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      ></iframe>
    </div>
    <script src="https://player.vimeo.com/api/player.js"></script>
  </>
);

export default Video;
