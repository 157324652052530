import React from "react";
import { Div, Text1, AddIcon } from "components/Base";

const Button = ({ title = "", width = 104 }) => (
  <Div
    display="row.flex-start.center"
    bra={3}
    bg="neutral2"
    width={width}
    height={21}
    px={2}
  >
    <AddIcon color="neutral6" mr={1} />
    <Text1 color="neutral6">{title}</Text1>
  </Div>
);

export default Button;
