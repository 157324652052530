import React, { Component } from "react";
import getValue from "utils/value-types/get-value/number";
import toString from "utils/value-types/to-string/percent";
import { isEqual } from "lodash";

export default class PercentCellFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  render() {
    const value = getValue(this.props.value);
    const formatted = value
      ? toString(value, this.props.dependentValues.meta.columnSettings)
      : "";
    return (
      <div
        style={{
          lineHeight: "34px"
        }}
        title={formatted}
      >
        {formatted}
      </div>
    );
  }
}
