/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";
import getQuery from "redux/modules/modules/utils/get-query";

export default {
  getConnection: ({ credentials, eventId }) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/integrations/fuzion/events/${eventId}/configuration`,
        credentials,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  createConnection: ({ credentials, eventId, data }) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/integrations/fuzion/events/${eventId}/configuration`,
        credentials,
        data,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  deleteConnection: ({ credentials, eventId, data }) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/integrations/fuzion/events/${eventId}/configuration`,
        credentials,
        data,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  getModuleFields: ({ credentials, moduleId, eventId }) =>
    new Promise((success, error) => {
      const query = getQuery({ eventId });

      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/modules/${moduleId}/fields${query || ""}`,
        credentials,
        success,
        error
      });
    }),
  getRecordTypes: ({ credentials, moduleId, eventId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/modules/${moduleId}/record-types?eventId=${eventId}`,
        credentials,
        success,
        error
      });
    }),
  getFields: ({ credentials, eventId }) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/integrations/fuzion/events/${eventId}/fields`,
        credentials,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  updateMapping: ({ credentials, eventId, data }) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/integrations/fuzion/events/${eventId}/mappings`,
        credentials,
        data,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  syncData: (credentials, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/integrations/fuzion/events/${data.eventId}/sync/queue`,
        credentials,
        data,
        success,
        error
      });
    }),
  getJobStatus: (credentials, { jobId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/jobs/progress/${jobId}`,
        credentials,
        success,
        error
      });
    })
};
