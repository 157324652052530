import PropTypes from "prop-types";
import React, { Component } from "react";
import SwimLane from "./";
import { DropTarget } from "react-dnd";

const styles = {
  margin: "0 10px",
  borderRadius: 4,
  width: 260,
  display: "flex",
  marginBottom: 50
};

const laneTarget = {
  drop(props, monitor) {
    props.onDrop({ ...monitor.getItem(), option: props.option });
  }
};

@DropTarget(["kanban-card"], laneTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop()
}))
class DropLane extends Component {
  render() {
    const { canDrop, isOver, connectDropTarget, ...props } = this.props;
    const isActive = canDrop && isOver;

    return connectDropTarget(
      <div style={isActive ? { border: "1px dashed", ...styles } : styles}>
        <SwimLane {...props} />
      </div>
    );
  }
}

DropLane.propTypes = {
  canDrop: PropTypes.bool,
  connectDropTarget: PropTypes.func,
  isOver: PropTypes.bool,
  onDrop: PropTypes.func.isRequired
};

export default DropLane;
