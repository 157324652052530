import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE, PAGES } from "./constants";

const iniState = {
  loading: false,
  fields: [],
  selectedFields: [],
  module: {},
  moduleId: "",
  moduleName: "",
  fieldIdEditingLabel: "",
  tempLabel: "",
  selectedPage: PAGES.CHOOSE_OPTIONS,
  templateText: ""
};
const mapIndexed = R.addIndex(R.map);
const handlers = createHandlers({
  iniState,
  reducers: {
    cancelInstance: R.always(iniState),
    setInitialData: (
      state,
      { payload: { module, moduleId, fields, moduleName } }
    ) => ({
      module,
      moduleId,
      fields,
      moduleName
    }),
    toggleField: (state, { payload: { id, name, tag, isSelected } }) => ({
      selectedFields: isSelected
        ? R.filter(field => field.id !== id, state.selectedFields)
        : [
            ...state.selectedFields,
            { id, name, tag, label: "", order: R.length(state.selectedFields) }
          ]
    }),
    disableAllFields: () => ({
      selectedFields: [],
      fieldIdEditingLabel: ""
    }),
    enableAllFields: state => {
      if (!R.length(state.selectedFields)) {
        return {
          selectedFields: mapIndexed(
            (field, order) => ({
              id: field.id,
              name: field.name,
              tag: field.tag,
              order,
              label: ""
            }),
            state.fields
          )
        };
      }
      const cmp = (x, y) => x.id === y.id;
      const diffFields = R.differenceWith(
        cmp,
        state.fields,
        state.selectedFields
      );
      const orderStart = R.length(state.selectedFields);
      return {
        selectedFields: [
          ...state.selectedFields,
          ...mapIndexed(
            (field, index) => ({
              id: field.id,
              name: field.name,
              tag: field.tag,
              order: orderStart + index,
              label: ""
            }),
            diffFields
          )
        ]
      };
    },
    reorderSelectedFields: (state, { payload: { orderedFields } }) => ({
      selectedFields: mapIndexed(
        (field, order) => ({ ...field, order }),
        orderedFields
      )
    }),
    updateFieldLabel: (state, { payload: { id } }) => {
      const index = R.findIndex(R.propEq("id", id), state.selectedFields);
      const field = state.selectedFields[index];
      return {
        fieldIdEditingLabel: "",
        selectedFields: R.update(
          index,
          {
            ...field,
            label: state.tempLabel
          },
          state.selectedFields
        ),
        tempLabel: ""
      };
    },
    generateText: state => {
      let htmlDataColumns = "";
      let htmlDataRows = "";
      let fillerCells = [];

      for (let i = 0; i < state.selectedFields.length - 1; i++) {
        fillerCells.push("<td></td>");
      }
      fillerCells = fillerCells.join("");

      R.forEach(field => {
        htmlDataColumns = `${htmlDataColumns}
        <td><b>${R.isEmpty(field.label) ? field.name : field.label}</b></td>`;
        htmlDataRows = `${htmlDataRows}
        <td>{{INS $record.${field.tag}}}</td>`;
      })(state.selectedFields);

      const html = `
      <table border="1">
      <tr>
      ${htmlDataColumns}
      </tr>
      <tr>
        <td>{{FOR record in ${state.module.tag}}}</td>
        ${fillerCells}
      </tr>
      <tr>
      ${htmlDataRows}
      </tr>
      <tr>
        <td>{{END-FOR record}}</td>
        ${fillerCells}
      </tr>
    
    </table>    
      `;

      return {
        templateText: html,
        selectedPage: PAGES.COPY_TEXT
      };
    }
  },
  namespace: NAMESPACE
});

export default handlers;
