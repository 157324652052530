import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDataGrid from "@lennd/react-data-grid/dist/react-data-grid";

class DefaultRow extends Component {
  setScrollLeft(scrollLeft) {
    this.refs.row.setScrollLeft(scrollLeft);
  }

  getClassName() {
    return this.props.row.id === 0 ? "empty-row" : "";
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <ReactDataGrid.Row ref="row" {...this.props} />
      </div>
    );
  }
}

DefaultRow.propTypes = {
  row: PropTypes.object.isRequired
};

export default DefaultRow;
