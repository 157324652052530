import { TRACK } from "./constants";
import { EventTypes } from "redux-segment";

export function track({ event, properties = {} }) {
  return dispatch => {
    if (event) {
      dispatch({
        type: TRACK,
        payload: {},
        meta: {
          analytics: {
            eventType: EventTypes.track,
            eventPayload: {
              event,
              properties
            }
          }
        }
      });
    }
  };
}
