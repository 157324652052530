import { put, select, call } from "redux-saga/effects";

import { registerError } from "redux/modules/errors/actions";
import { actions } from "./model";

import { getPathname } from "App/Router/selectors";
import { eventId as getPortalEventId } from "redux/modules/portal/selectors";

import { apiCall } from "App/Data/sagas";

const fetch = function*() {
  const eventId = yield select(getPortalEventId);

  // eslint-disable-next-line no-unused-vars
  const [_, pageId] = yield select(getPathname, {
    match: /\/pages\/([a-z\d-]+)\/?/i
  });

  try {
    const { payload: page } = yield call(apiCall, {
      method: "get",
      url: `/hub/event/${eventId}/page/${pageId}`
    });
    yield put(actions.fetchResponse(page));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while loading the page"
        }
      ])
    );
  }
};

export default fetch;
