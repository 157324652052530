import React from "react";
import { connect } from "react-redux";

import { noop } from "utils/General";

import { SmallTextInput, Div, CloseIcon, SearchIcon } from "components/Base";
import { getters, actions } from "ui-kit/Datagrid";

import { bindInstance } from "redux-mvc";

const decorate = connect(
  state => ({
    searchTerm: getters.searchTerm(state)
  }),
  bindInstance({
    onSearch: actions.setSearch,
    clearSearch: (_, ...params) => actions.setSearchTerm("", ...params)
  })
);

const Search = ({
  searchTerm = "",
  onSearch = noop,
  clearSearch = noop,
  ...styleProps
}) => (
  <Div
    bg="white"
    bra={2}
    display="row.flex-start.stretch"
    mr={2}
    ba={1}
    bc="neutral3"
    {...styleProps}
    onClick={e => e.stopPropagation()}
  >
    <Div flex={1} display="row" py={1}>
      <SmallTextInput
        placeholder="Search..."
        continuous
        value={searchTerm}
        onChange={val => onSearch(val)}
      />
    </Div>
    {searchTerm.length ? (
      <Div
        width={50}
        bg={{ default: "primary5", hover: "primary7" }}
        display="row.center.center"
        onClick={clearSearch}
      >
        <CloseIcon color="white" sizeWFS={5} />
      </Div>
    ) : (
      <Div width={50} display="row.center.center">
        <SearchIcon sizeWFS={5} />
      </Div>
    )}
  </Div>
);

export default decorate(Search);
