import React, { Component } from "react";
import { LOGOUT_RETURN_TO_KEY } from "../LogoutView";
import PlatformLoading from "components/Platform/PlatformLoading";

class LogoutCallbackHandler extends Component {
  componentDidMount() {
    const url = localStorage.getItem(LOGOUT_RETURN_TO_KEY);
    localStorage.removeItem(LOGOUT_RETURN_TO_KEY);

    if (url && url.length) {
      window.location = url;
    } else {
      window.location = `${
        window.__LENND_APP_URL__
      }/login?message=${encodeURIComponent("You have been logged out.")}`;
    }
  }

  render() {
    return <PlatformLoading message="Logging out..." />;
  }
}

export default LogoutCallbackHandler;
