import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import {
  Div,
  MediumOutlineButton,
  SendIcon,
  DownloadIcon
} from "components/Base";

import SelectiveApproversLabel from "SelectiveApproversLabel/View";
import * as EMAIL_TEMPLATES from "utils/Emails/default-emails";
import { actions } from "../index";

import {
  CREDENTIAL_TYPE_ID,
  INVENTORY_TYPE_ID,
  MEAL_TYPE_ID,
  SPONSORSHIP_TYPE_ID,
  BOOTH_TYPE_ID
} from "utils/item-types";

const getTemplateForItemTypeId = typeId =>
  R.prop(typeId)({
    [CREDENTIAL_TYPE_ID]: EMAIL_TEMPLATES.CREDENTIALS_SUMMARY,
    [INVENTORY_TYPE_ID]: EMAIL_TEMPLATES.INVENTORY_SUMMARY,
    [MEAL_TYPE_ID]: EMAIL_TEMPLATES.CATERING_SUMMARY,
    [SPONSORSHIP_TYPE_ID]: EMAIL_TEMPLATES.SPONSORSHIPS_SUMMARY,
    [BOOTH_TYPE_ID]: EMAIL_TEMPLATES.BOOTHS_SUMMARY
  });

const decorate = connect(
  null,
  {
    onExportItemSummaries: actions.exportItemSummaries,
    approveRequests: actions.approveRequests,
    denyRequests: actions.denyRequests
  }
);

const FooterButtons = ({
  handlers,
  onExportItemSummaries,
  lineItemIds,
  pendingLineItemIds,
  requestTypeId,
  approveRequests,
  denyRequests
}) => {
  return (
    <Div pt={3} pb={2} display="row.flex-start.center">
      {pendingLineItemIds.length ? (
        <SelectiveApproversLabel
          {...{
            type: "lineItem",
            recordIds: pendingLineItemIds,
            onApprove: approveRequests,
            onDeny: denyRequests,
            ButtonProps: {
              color: "neutral6"
            }
          }}
        />
      ) : null}
      <MediumOutlineButton
        color="neutral6"
        ml={pendingLineItemIds.length ? 2 : 0}
        mr={2}
        onClick={() => handlers.showBulkEditModal(lineItemIds)}
      >
        Bulk Edit
      </MediumOutlineButton>
      <MediumOutlineButton
        LeftIcon={SendIcon}
        color="neutral6"
        mr={2}
        onClick={() =>
          handlers.showSendEmailModal(getTemplateForItemTypeId(requestTypeId))
        }
      >
        Send Summary
      </MediumOutlineButton>
      <MediumOutlineButton
        color="neutral6"
        mr={2}
        isPadded={false}
        onClick={() => onExportItemSummaries(requestTypeId)}
      >
        <DownloadIcon color="neutral6" />
      </MediumOutlineButton>
    </Div>
  );
};

export default decorate(FooterButtons);
