/* eslint-disable react/prop-types */ //
import PropTypes from "prop-types";
import React, { Component } from "react";
import getRows from "utils/EventForms/FormGrid/getRows";
import isRowNotEmpty from "utils/EventForms/FormGrid/isRowNotEmpty";
import { get, find, isEqual } from "lodash";
import * as R from "ramda";

import getFormVersion from "components/Event/FormsV2/Utils/get-form-version";
import getModuleFieldId from "components/Event/FormsV2/Utils/get-module-field-id";
import formatVariantTitle from "components/Event/Credentials/Modals/ViewOrder/utils/format-variant-title";
import Table3Helpers from "components/Global/Table3/Utilities/Helpers";
import isFormLocked from "components/Event/FormsV2/Utils/is-form-locked";

import { CREDENTIAL_TYPE_ID, MEAL_TYPE_ID } from "utils/item-types";
import { NotFound, Submission, Confirmation } from "./View";

const TYPE_NAMES_MAP = {
  // @NOTE: We null this out because there's no one name that suits every customer
  [CREDENTIAL_TYPE_ID]: null,
  [MEAL_TYPE_ID]: "Meals"
};

const shouldShowChildren = itemsByItemId => {
  const doAllItemsNamesEqualSubNames = R.all(i => {
    return i.name === i.subname;
  })(itemsByItemId);

  return !doAllItemsNamesEqualSubNames;
};

class Controller extends Component {
  viewRefs = {};

  constructor(props) {
    super(props);

    this.handleUnloadListener = e => {
      if (
        this.props.location.query.redirectToOverview ||
        this.props.location.query.redirectToDashboard ||
        this.props.location.query.redirectToSource ||
        this.props.location.query.redirectToApplication ||
        this.props.location.query.redirectToAccount ||
        this.props.location.query.redirectToContact ||
        this.props.submission.is_locked ||
        this.isViewingConfirmation()
      ) {
        return undefined;
      }
      const confirmationMessage =
        "Are you sure you want to leave your submission?";

      (e || window.event).returnValue = confirmationMessage; // Gecko + IE
      return confirmationMessage; // Gecko + Webkit, Safari, Chrome etc.
    };

    this.validators = [];
  }

  getFormRows({
    schema,
    gridId,
    formValues,
    response,
    isApprovingForm,
    isFillingFormOut,
    isViewingApproval,
    recipientCanViewChanges
  }) {
    let rows = getRows({
      schema,
      gridId,
      formValues,
      response,
      isApprovingForm,
      isFillingFormOut,
      isViewingApproval,
      recipientCanViewChanges
    });

    if (get(response, "status") === "approval_sent") {
      rows = rows.filter(row => isRowNotEmpty(row, schema.columns));
    }

    return rows;
  }

  componentDidMount() {
    if (window.Intercom) {
      window.Intercom("update", {
        hide_default_launcher: true
      });
    }

    window.addEventListener("beforeunload", this.handleUnloadListener);
    this.props.getSubmission(this.props.params.submissionId);
  }

  componentDidUpdate(prevProps) {
    // handle pressing "back" from confirmation page - refetch submission so we can lock it if necessary
    if (this.props.location !== prevProps.location) {
      if (
        prevProps.location.pathname.includes("/confirmation") &&
        !this.props.location.pathname.includes("/confirmation")
      ) {
        this.props.getSubmission(this.props.params.submissionId);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.handleUnloadListener);
  }

  onKeyPress = e => {
    if (e.target.type !== "textarea" && e.which === 13 /* Enter */) {
      e.preventDefault();
    }
  };

  onSubmit = async e => {
    e.preventDefault();

    if (await this.isValid()) {
      this.viewRefs.submitButton.showSubmit(this.submitForm);
    } else {
      const errors = document.querySelectorAll("[data-section-error=true]");
      if (errors.length) {
        errors[0].scrollIntoView(false);
      }
    }
  };

  submitForm = e => {
    if (e) {
      e.preventDefault();
    }

    this.props
      .updateSubmission({
        id: this.props.submission.id,
        submissionId: this.props.submission.id,
        isSubmitted: true,
        suppressSubmissionConfirmationEmail:
          !!this.props.location.query.redirectToOverview ||
          !!this.props.location.query.redirectToDashboard ||
          !!this.props.location.query.redirectToSource ||
          !!this.props.location.query.redirectToApplication ||
          !!this.props.location.query.redirectToAccount ||
          !!this.props.location.query.redirectToContact
      })
      .then(() => {
        // @NOTE: Only redirect after we've request has been completed, else
        // a race case on the submission's status could occur
        if (this.props.location.query.redirectToAccount) {
          this.goToAccount({ confirmation: "submitted" });
        } else if (this.props.location.query.redirectToContact) {
          this.goToContact({ confirmation: "submitted" });
        } else if (this.props.location.query.redirectToOverview) {
          this.goToOverview({ confirmation: "submitted" });
        } else if (this.props.location.query.redirectToDashboard) {
          this.goToDashboard();
        } else if (this.props.location.query.redirectToSource) {
          this.goToSource({ confirmation: "submitted" });
        } else if (this.props.location.query.redirectToApplication) {
          this.goToApplication({ confirmation: "submitted" });
        } else {
          this.goToSubmissionConfirmation(this.isOpenSubmission());
        }
      });
  };

  createTheQuery = (search = {}) => {
    if (R.isEmpty(search)) return null;
    const key = R.keys(search).toString();
    return "?" + key + "=" + R.prop(key)(search);
  };

  goToPortal = (search = {}) => {
    this.props.push({
      pathname: `/portals/${this.props.eventDetails.slug}/${this.props.eventDetails.uuid}`,
      search: this.createTheQuery(search)
    });
  };

  goToSubmission = () => {
    this.props.push({
      pathname: `/submissions/${this.props.eventDetails.slug}/${this.props.form.slug}/${this.props.submission.id}`
    });
  };

  goToAccount = () => {
    this.props.push(
      `/event-light/${this.props.eventDetails.id}/account/${this.props.location.query.redirectToAccount}`
    );
  };

  goToContact = () => {
    this.props.push(
      `/event-light/${this.props.eventDetails.id}/contact/${this.props.location.query.redirectToContact}`
    );
  };

  goToSource = () => {
    this.props.push(
      `/event-light/${this.props.eventDetails.id}/forms/${this.props.form.id}/results`
    );
  };

  goToApplication = () => {
    this.props.push(
      `/event-light/${this.props.eventDetails.id}/forms/${this.props.form.id}/results`
    );
  };

  goToDashboard = () => {
    this.props.push(
      `/event-light/${this.props.eventDetails.id}/module/${this.props.location.query.moduleId}/dashboard/type/${this.props.location.query.recordTypeId}`
    );
  };

  goToOverview = (search = {}) => {
    this.props.push({
      pathname: `/event-light/${this.props.eventDetails.id}/forms/${this.props.form.id}/results`,
      search: this.createTheQuery(search)
    });
  };

  goToSubmissionConfirmation = isOpenSubmission => {
    this.props.push({
      pathname: `/${isOpenSubmission ? "open-submissions" : "submissions"}/${
        this.props.eventDetails.slug
      }/${this.props.form.slug}/${this.props.submission.id}/confirmation`
    });
  };

  updateFormValue = (fieldId, value) => {
    if (!isEqual(this.props.submission.values[fieldId], value)) {
      this.props.addValue({
        version: getFormVersion(this.props.form),
        submissionRecordId: this.props.submission.submission_record_id,
        moduleFieldId: getModuleFieldId(fieldId, this.props.form.fields),
        submissionId: this.props.submission.id,
        fieldId,
        value,
        userId: this.props.user.id
      });
    }

    return true;
  };

  getContextMenus = () => {
    const headerCellMenu = [
      {
        type: "row",
        title: "Sort A-Z",
        icon: "sort_by_alpha",
        allow: info => {
          const field = find(this.props.form.fields, { id: info.gridId });
          const subformField = find(field.subform.form.fields, {
            id: info.column
          });
          const id = subformField ? subformField.id : info.column;
          const type = subformField ? subformField.type : "text";
          return Object.keys(Table3Helpers.rowComparers(id)).includes(type);
        },
        action: info => {
          const field = find(this.props.form.fields, { id: info.gridId });
          const subformField = find(field.subform.form.fields, {
            id: info.column
          });
          const id = subformField ? subformField.id : info.column;
          const type = subformField ? subformField.type : "text";
          const settings = subformField ? subformField.settings : {};
          this.viewRefs[`grid_${info.gridId}`].wrappedInstance.onRowSort(
            Table3Helpers.getSortFunction({ id, type, settings }, "ASC"),
            subformField
          );
        }
      },
      {
        type: "row",
        title: "Sort Z-A",
        icon: "sort_by_alpha",
        allow: info => {
          const field = find(this.props.form.fields, { id: info.gridId });
          const subformField = find(field.subform.form.fields, {
            id: info.column
          });
          const id = subformField ? subformField.id : info.column;
          const type = subformField ? subformField.type : "text";
          return Object.keys(Table3Helpers.rowComparers(id)).includes(type);
        },
        action: info => {
          const field = find(this.props.form.fields, { id: info.gridId });
          const subformField = find(field.subform.form.fields, {
            id: info.column
          });
          const id = subformField ? subformField.id : info.column;
          const type = subformField ? subformField.type : "text";
          const settings = subformField ? subformField.settings : {};
          this.viewRefs[`grid_${info.gridId}`].wrappedInstance.onRowSort(
            Table3Helpers.getSortFunction({ id, type, settings }, "DESC", {}),
            subformField
          );
        }
      }
    ];

    return { headerCellMenu };
  };

  /**
   * columns
   */
  registerValidator = isValid => {
    this.validators.push(isValid);
  };

  unregisterValidator = isValid => {
    const index = this.validators.indexOf(isValid);
    if (index !== -1) {
      this.validators.splice(index, 1);
    }
  };

  isValid = async () => {
    return (await Promise.all(this.validators.map(isValid => isValid()))).every(
      v => v
    );
  };

  removeItemFromCart = lineItemId => {
    this.props.removeItemFromCart({
      submissionId: this.props.submission.id,
      lineItemId
    });
  };

  removeItemsFromCart = lineItemIds => {
    this.props.removeItemsFromCart({
      submissionId: this.props.submission.id,
      lineItemIds
    });
  };

  isOpenSubmission = () =>
    !this.props.form.require_login ||
    window.location.pathname.includes("/open-submissions/");

  isViewingConfirmation = () => {
    return [
      "formsV2_SubmissionConfirmation",
      "formsV2_FormOpenSubmissionConfirmation"
    ].includes(this.props.route.name);
  };

  registerRef = (ref, id) => {
    this.viewRefs[id] = ref;
  };

  render() {
    const {
      isFetching,
      errorMessages,
      form,
      submission,
      cart,
      eventDetails,
      collaborators,
      user,
      preferences,
      sortedFormFields,
      isFetchingBulk = false,
      dispatch,
      showModal,
      hideModal,
      moduleRecordReferences
    } = this.props;

    if (errorMessages.length || (!isFetching && !form.id)) {
      return <NotFound />;
    }

    const isViewingConfirmation = this.isViewingConfirmation();
    const isOpenSubmission = this.isOpenSubmission();
    const collaborator = collaborators.find(c => c.user_id === user.id);

    if (isViewingConfirmation) {
      return (
        <Confirmation
          {...{
            isOpenSubmission,
            form,
            eventDetails,
            collaborator,
            message: form.confirmation_message,
            goToPortal: isOpenSubmission
              ? null
              : () =>
                  this.goToPortal({
                    confirmation:
                      this.props.submission.is_submitted === false
                        ? "submitted"
                        : "updated"
                  })
          }}
        />
      );
    }

    const isThisFormLocked = isFormLocked(form, submission.is_locked);
    const isLocked = isThisFormLocked;
    const formType = form.type;
    const isOrderFormType = formType === "order";

    const cartByVariantId = R.reduce((map, cartItem) => {
      const key = `${cartItem.variant.id}_${cartItem.question_values_and_zones_token}`;
      if (!map[key]) {
        map[key] = {
          typeId: cartItem.variant.item.type_id,
          variantId: cartItem.variant.id,
          itemId: cartItem.variant.item.id,
          name: cartItem.variant.item.name,
          itemName: cartItem.variant.item.name,
          subname:
            cartItem.variant.item.type_id === MEAL_TYPE_ID
              ? formatVariantTitle(cartItem.variant)
              : cartItem.variant.display_name,
          itemSortOrder: cartItem.variant.item.order,
          variantSortOrder: cartItem.variant.order,
          quantity: cartItem.quantity,
          price: cartItem.price,
          priceEach: cartItem.price,
          questions: cartItem.question_values_preview,
          zones: cartItem.zones_preview,
          itemIds: [cartItem.id]
        };
      } else {
        map[key].quantity = R.sum([map[key].quantity, cartItem.quantity]);
        map[key].price = R.sum([map[key].price, cartItem.price]);
        map[key].itemIds = R.concat(map[key].itemIds, [cartItem.id]);
        if (!map[key].priceEach) {
          map[key].priceEach = cartItem.price;
        }
      }
      return map;
    }, {})(cart);

    const cartWithHandlers = R.compose(
      R.sort(a => (R.isNil(a.name) ? -1 : 1)),
      cartByTypeId =>
        R.reduce((list, typeId) => {
          list.push({
            id: typeId,
            name: TYPE_NAMES_MAP[typeId],
            ...R.compose(
              cartByItemId =>
                R.reduce(
                  (itemList, itemId) => {
                    const itemToPush = {
                      id: itemId,
                      name: cartByItemId[itemId][0].name,
                      questions: cartByItemId[itemId][0].questions,
                      zones: cartByItemId[itemId][0].zones,
                      items: R.sortBy(i => {
                        if (typeId === MEAL_TYPE_ID) {
                          return new Date(i.subname);
                        }
                        return i.variantSortOrder;
                      })(cartByItemId[itemId]),
                      quantity: R.compose(
                        R.sum,
                        R.map(R.prop("quantity"))
                      )(cartByItemId[itemId]),
                      totalPrice: R.compose(
                        R.sum,
                        R.map(R.propOr(0, "price"))
                      )(cartByItemId[itemId]),
                      priceEach: cartByItemId[itemId][0].priceEach,
                      showChildren: shouldShowChildren(cartByItemId[itemId]),
                      onRemove: () =>
                        this.removeItemsFromCart(
                          R.compose(
                            R.flatten,
                            R.map(R.prop("itemIds"))
                          )(cartByItemId[itemId])
                        )
                    };
                    itemList.quantity = itemList.quantity + itemToPush.quantity;
                    itemList.items.push(itemToPush);
                    return itemList;
                  },
                  {
                    quantity: 0,
                    items: []
                  }
                )(R.keys(cartByItemId)),
              R.groupBy(i => {
                return `${i.itemId}_${i.groupingKey}`;
              }),
              R.sortBy(i => {
                if (i.typeId === MEAL_TYPE_ID) {
                  return i.itemSortOrder;
                }
                return i.name;
              })
            )(cartByTypeId[typeId])
          });
          return list;
        }, [])(R.keys(cartByTypeId)),
      R.groupBy(R.prop("typeId")),
      R.map(key => {
        const variantId = cartByVariantId[key].variantId;
        return {
          id: variantId,
          groupingKey: key,
          typeId: cartByVariantId[key].typeId,
          itemId: cartByVariantId[key].itemId,
          itemSortOrder: cartByVariantId[key].itemSortOrder,
          variantSortOrder: cartByVariantId[key].variantSortOrder,
          name: cartByVariantId[key].name,
          subname: cartByVariantId[key].subname,
          quantity: cartByVariantId[key].quantity,
          price: cartByVariantId[key].price,
          priceEach: cartByVariantId[key].priceEach,
          itemIds: cartByVariantId[key].itemIds,
          questions: cartByVariantId[key].questions,
          zones: cartByVariantId[key].zones,
          onRemove: () => this.removeItemsFromCart(cartByVariantId[key].itemIds)
        };
      })
    )(R.keys(cartByVariantId));

    const totalQtyOfCartItems = R.reduce(
      (total, item) =>
        total + R.compose(R.sum, R.map(R.prop("quantity")))(item.items),
      0
    )(cartWithHandlers);

    const totalPriceOfCartItems = R.reduce(
      (total, item) =>
        total + R.compose(R.sum, R.map(R.propOr(0, "totalPrice")))(item.items),
      0
    )(cartWithHandlers);

    return (
      <Submission
        {...{
          isFetching,
          eventDetails,
          isOpenSubmission,
          isOrderFormType,
          totalQtyOfCartItems,
          totalPriceOfCartItems,
          isLocked,
          isFormLocked: isThisFormLocked,
          form,
          submission,
          collaborator,
          user,
          preferences,
          moduleRecordReferences,
          sortedFormFields,
          isFetchingBulk,
          dispatch,
          showModal,
          hideModal,
          cartWithHandlers,
          updateFormValue: this.updateFormValue,
          registerValidator: this.registerValidator,
          unregisterValidator: this.unregisterValidator,
          submitButton: this.viewRefs.submitButton,
          refs: this.viewRefs,
          registerRef: this.registerRef,
          getContextMenus: this.getContextMenus,
          onSubmit: this.onSubmit,
          onKeyPress: this.onKeyPress,
          goToPortal: this.goToPortal,
          goToSubmission: this.goToSubmission,
          getFormRows: this.getFormRows
        }}
      />
    );
  }
}

Controller.propTypes = {
  references: PropTypes.shape({
    values: PropTypes.object.isRequired
  }).isRequired,
  routes: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  submission: PropTypes.object.isRequired,
  preferences: PropTypes.object.isRequired,
  eventDetails: PropTypes.object.isRequired,
  sortedFormFields: PropTypes.object.isRequired,
  isFetchingBulk: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  getSubmission: PropTypes.func.isRequired,
  updateSubmission: PropTypes.func.isRequired,
  addValue: PropTypes.func.isRequired,
  errorMessages: PropTypes.array.isRequired,
  bulkAddValue: PropTypes.func.isRequired,
  addReferenceValue: PropTypes.func.isRequired,
  collaborators: PropTypes.array.isRequired
};

export default Controller;
