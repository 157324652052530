const REPORT_TYPES = {
  ACCOUNT_BY_ACCOUNT_ON_ORDER: "account_by_account_on_order",
  PERSON_BY_ASSIGNED_TO: "person_by_assigned_to",
  PERSON_BY_NAME_ON_ORDER: "person_by_name_on_order",
  PERSON_BY_PICKUP_PERSON: "person_by_pickup_person",
  TYPE_BY_ITEM_TYPE: "type_by_item_type",
  VARIANT_OPTION: "meal_by_variant_option"
};

export default REPORT_TYPES;
