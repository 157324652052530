import React, { Component } from "react";
import { Div } from "components/Base";

export default class ViewSubmissionCellFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.dependentValues, nextProps.dependentValues);
  }

  render() {
    const {
      dependentValues: {
        submissionId,
        helpers: { showSubmissionSummary }
      }
    } = this.props;

    return (
      <Div
        textAlign="center"
        onClick={() => showSubmissionSummary(submissionId)}
      >
        View Submission
      </Div>
    );
  }
}
