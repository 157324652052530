/* eslint no-underscore-dangle: "off" */
import React, { Component } from "react";
import * as R from "ramda";
import { find, pathOr, propEq } from "ramda";
import View from "./View";
import { LABELS } from "utils/item-types";
import Empty from "./Empty";
import isFormLocked from "components/Event/FormsV2/Utils/is-form-locked";

class Internal extends Component {
  render() {
    const {
      peopleTypesOnly,
      eventDetails,
      selectedTypeName,
      loading,
      activePermissionSet,
      addPermissionSet,
      itemBlocks,
      onAddItemBlock,
      onEditItemBlock,
      peopleTypes,
      updateRecordTypeSetting,
      showMessageModal,
      updateCloseDate,
      toggleIsLocked,
      showFormWizardModal,
      showIntakeSettingsModal,
      hideIntakeSettingsModal,
      openIntakeSettingsModal,
      fetchPermissionSets,
      onClickLinkInput,
      onCopyLinkInput,
      disableIntakeForm,
      applicationForms,
      goToForm,
      showCustomizeSectionsModal,
      hideCustomizeSectionsModal,
      openCustomizeSectionsModal,
      isAccountPortal,
      onToggleRequiredItemBlock,
      showAppearanceSettingsModal
    } = this.props;

    if (loading) {
      return <View loading />;
    }

    if (!activePermissionSet) {
      return (
        <Empty
          action={addPermissionSet}
          text="No portal has been configured yet"
        />
      );
    }

    const permissionProps = {
      peopleTypesOnly,
      isAccountPortal,
      selectedTypeName,
      loading,

      onClickLinkInput,
      onCopyLinkInput,
      intakeForm: R.propOr({}, "intake_form", activePermissionSet) || {},
      customizeSettings: R.propOr({}, "settings", activePermissionSet) || {},
      intakeFormUrl:
        activePermissionSet.intake_form && activePermissionSet.intake_form.id
          ? `${window.__LENND_APP_URL__}/intake/${eventDetails.slug}/${activePermissionSet.intake_form.id}`
          : null,

      permissionProfileId: activePermissionSet.id,

      hasMessage: activePermissionSet.title && activePermissionSet.title.length,
      showMessageModal,
      showFormWizardModal,
      showIntakeSettingsModal,
      hideIntakeSettingsModal,
      openIntakeSettingsModal,
      fetchPermissionSets: () => fetchPermissionSets(),
      disableIntakeForm,
      showCustomizeSectionsModal,
      hideCustomizeSectionsModal,
      openCustomizeSectionsModal,
      showAppearanceSettingsModal,

      closeDate: activePermissionSet.close_date,
      isOpen: !activePermissionSet.is_locked,
      updateCloseDate,
      toggleIsLocked: () => toggleIsLocked(!activePermissionSet.is_locked),
      isLocked: isFormLocked({
        close_date: activePermissionSet.close_date,
        is_locked: activePermissionSet.is_locked
      }),

      applicationForms: applicationForms.map(f => ({
        ...f,
        url: `${window.__LENND_APP_URL__}/forms/${eventDetails.slug}/${f.slug}/${f.id}`,
        isClosed: isFormLocked(f),
        goToFormEditor: () => goToForm(f),
        goToFormSettings: () => goToForm(f, "/settings"),
        goToFormShare: () => goToForm(f, "/share")
      })),

      people: {
        toggleAll: () => {},
        peopleTypes: peopleTypes.map(type => {
          const currentType =
            find(propEq("record_type_id", type.id))(
              pathOr([], ["types"], activePermissionSet)
            ) || {};

          return {
            active: currentType.is_enabled,
            countOfFieldsSelected:
              currentType && currentType.visible_fields
                ? currentType.visible_fields.length
                : 0,
            showEditColumnsModal: () => {
              this.props.showEditColumnsModal({
                visibleFields:
                  currentType && currentType.visible_fields
                    ? currentType.visible_fields
                    : [],
                fieldOrder:
                  currentType && currentType.field_order
                    ? currentType.field_order
                    : {},
                onSave: ({ visibleColumns, columnOrder, requiredFields }) =>
                  updateRecordTypeSetting(activePermissionSet.id, type.id, {
                    visible_fields: visibleColumns,
                    field_order: columnOrder,
                    required_fields: requiredFields
                  }),
                requiredFields: R.propOr([], "required_fields", currentType)
              });
            },
            availableItems: itemBlocks.length
              ? activePermissionSet.item_blocks
                  .filter(i => i.record_type_id === type.id)
                  .map(i => {
                    const block = itemBlocks.find(b => b.id === i.block_id);
                    const required = R.propOr(false, "is_required", i);

                    return {
                      id: i.id,
                      name: block.name,
                      quantity: block.items.length,
                      type: LABELS[block.item_type_id].label,
                      required,
                      onEdit: () => onEditItemBlock(i.block_id),
                      toggleRequired: () =>
                        onToggleRequiredItemBlock({
                          permissionSetId: activePermissionSet.id,
                          blockId: block.id,
                          recordTypeId: type.id,
                          required: !required
                        })
                    };
                  })
              : [],
            backgroundColor: type.background_color || "lightgrey",
            canRequestItems: currentType.can_request_items,
            icon: type.icon,
            id: type.id,
            name: type.name,
            onUpdateSelectedIntakeForm: ({ value }) =>
              updateRecordTypeSetting(activePermissionSet.id, type.id, {
                intake_form_id: value
              }),
            onAddItemBlock: () =>
              onAddItemBlock(activePermissionSet.id, type.id),
            textColor: type.text_color || "black",
            toggleCanRequestItems: () =>
              updateRecordTypeSetting(activePermissionSet.id, type.id, {
                can_request_items: !currentType.can_request_items
              }),
            toggleType: () =>
              updateRecordTypeSetting(activePermissionSet.id, type.id, {
                is_enabled: !currentType.is_enabled
              })
          };
        })
      }
    };

    return <View {...permissionProps} />;
  }
}

export default Internal;
