import {
  put,
  select,
  call,
  takeEvery,
  all,
  fork,
  take,
  delay
} from "redux-saga/effects";
import * as R from "ramda";

import { eventId } from "redux/modules/event/selectors";
import { userId, getCredentials } from "redux/modules/user/selectors";
import { registerError } from "redux/modules/errors/actions";

import { actions } from "Passes/Confirmations";
import { getCurrentPage, getPageSize } from "Passes/Confirmations/selectors";
import api from "Passes/Confirmations/api";

import { debounce } from "utils/General/sagas";

import {
  CREATED_VIA_PORTAL,
  CREATED_VIA_GLM,
  PASS_MODAL_ID,
  USER_MODAL_ID,
  SOURCE_MODAL_ID,
  EVERYONE,
  ALL_GROUPS,
  MANAGE_BY,
  TABLE_VIEW_ID
} from "Passes/Common/constants";

import { getters } from "Passes/Confirmations";

import {
  actions as SearchBarActions,
  getters as SearchBarGetters
} from "ui-kit/SearchBar";
import { actions as FiltersActions } from "ui-kit/FiltersPanel";
import * as FilterSelectors from "ui-kit/FiltersPanel/selectors";

import {
  actions as MiniItemsActions,
  getters as MiniItemsGetters
} from "ui-kit/MiniItemsSelector";

import {
  actions as DoubleSelectActions,
  getters as DoubleSelectGetters
} from "ui-kit/DoubleSelect";

const notResetPagination = [
  actions.setCurrentPage.type,
  actions.fetchData.type
];

const getSearchData = (state, actionType) => {
  const itemTypeIds = getters.itemTypeIds(state);

  const sourceIds = MiniItemsGetters.selectedItems(state, {
    instanceId: SOURCE_MODAL_ID
  });

  const resetPagination = R.not(
    R.any(R.equals(actionType), notResetPagination)
  );

  const option = DoubleSelectGetters.selectedOption(state);

  return {
    itemTypeIds,
    confirmationStatus: option,
    status: ["unpaid"].includes(option)
      ? "approved"
      : DoubleSelectGetters.selectedSubOptions(state),
    accountRecordTypes: R.without(
      [ALL_GROUPS],
      FilterSelectors.getSelectedOptionsForSection(state, {
        id: MANAGE_BY.GROUP_AFFILIATION
      })
    ),
    contactRecordTypes: R.without(
      [EVERYONE],
      FilterSelectors.getSelectedOptionsForSection(state, {
        id: MANAGE_BY.ATTENDEE_LIST
      })
    ),
    search: SearchBarGetters.searchTerm(state), // string
    variantIds: MiniItemsGetters.selectedItems(state, {
      instanceId: PASS_MODAL_ID
    }),
    sourceFormIds: R.without([CREATED_VIA_GLM, CREATED_VIA_PORTAL], sourceIds),
    sourceUserIds: MiniItemsGetters.selectedItems(state, {
      instanceId: USER_MODAL_ID
    }),
    [CREATED_VIA_PORTAL]: R.contains(CREATED_VIA_PORTAL, sourceIds),
    [CREATED_VIA_GLM]: R.contains(CREATED_VIA_GLM, sourceIds),
    page: resetPagination ? 1 : getCurrentPage(state) + 1, // integer 1 based index
    pageSize: getPageSize(state) // integer
  };
};

const loading = function*(scrollTop = true) {
  if (scrollTop) {
    const elm = yield call([document, document.getElementById], TABLE_VIEW_ID);
    if (elm) {
      elm.scrollTop = 0;
    }
  }
  yield put(actions.setLoading(true));
  yield all([take(actions.receiveList.type), delay(400)]);
  yield put(actions.setLoading(false));
};

const search = function*({ type }) {
  const credentials = yield select(getCredentials);
  const userIdToUse = yield select(userId);
  const eventIdToUse = yield select(eventId);

  const state = yield select(R.identity);

  const data = getSearchData(state, type);

  yield fork(loading);
  try {
    const result = yield call(
      api.search,
      credentials,
      userIdToUse,
      eventIdToUse,
      data
    );
    yield put(actions.receiveList(result.payload));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred fetching passes list"
          }
        ])
      ),
      put(actions.setLoading(false))
    ]);
  }
};

const deselect = function*() {
  yield put(actions.selectAll());
};

const watchInitialized = function*() {
  yield all([
    take(FiltersActions.init.type),
    take(DoubleSelectActions.init.type)
  ]);
  yield put(actions.fetchData());
};

const watchClear = function*() {
  for (;;) {
    const action = yield take(FiltersActions.clearFilters.type);
    yield all([
      put(
        MiniItemsActions.clearAll(null, { meta: { instanceId: USER_MODAL_ID } })
      ),
      put(
        MiniItemsActions.clearAll(null, { meta: { instanceId: PASS_MODAL_ID } })
      ),
      put(
        MiniItemsActions.clearAll(null, {
          meta: { instanceId: SOURCE_MODAL_ID }
        })
      )
    ]);
    yield call(search, action);
  }
};

const watchSearch = function*() {
  yield takeEvery(
    [
      actions.setCurrentPage.type,
      actions.setPageSize.type,
      actions.setTypeFilters.type,
      actions.fetchData.type,
      FiltersActions.toggleFilter.type,
      DoubleSelectActions.selectOption.type,
      DoubleSelectActions.toggleSubOption.type
    ],
    search
  );
};

const watchOptionChange = function*() {
  yield takeEvery(
    [
      actions.setCurrentPage.type,
      actions.setPageSize.type,
      actions.setTypeFilters.type,
      FiltersActions.toggleFilter.type,
      DoubleSelectActions.selectOption.type,
      DoubleSelectActions.toggleSubOption.type
    ],
    deselect
  );
};

const watchSearchTerm = debounce(
  SearchBarActions.setSearchTerm.type,
  search,
  500
);

const rootSaga = function*() {
  yield all([
    fork(watchSearch),
    fork(watchOptionChange),
    fork(watchSearchTerm),
    fork(watchInitialized),
    fork(watchClear)
  ]);
};

export default rootSaga;
