import React from "react";
import { Div } from "components/Base";
import { connect } from "react-redux";
import { getters } from "../../model";

const decorate = connect((state, props) => ({
  session: getters.sessionDetails(state, props)
}));

const Details = ({ session: { title, description } }) => (
  <Div>
    <Div fw={4} fs={6} color="neutral9" style={{ marginBottom: 14 }}>
      {title}
    </Div>
    <Div>{description}</Div>
  </Div>
);

export default decorate(Details);
