import React from "react";
import * as R from "ramda";

import DocumentTitle from "components/Global/DocumentTitle";
import {
  Div,
  ThirdLeftTemplate,
  OpenInNewIcon,
  CalendarIcon
} from "components/Base";
import OrgsList from "./OrgsList";
import Pinned from "./Pinned";
import OrgCard from "./OrgCard";
import EventListCard from "./EventListCard";
const View = ({
  isSearching,
  greetingTime,
  name,
  orgs,
  orgsWithEvents,
  onSearch,
  addOrganization,
  pinnedEvents,
  managedEvents,
  portals,
  scrollToEvents,
  setEventsRef,
  scrollToPortals,
  setPortalsRef,
  hasPortals,
  hasEvents
}) => (
  <DocumentTitle title="Overview">
    <Div flex={1} style={{ position: "relative" }}>
      <Div pt={20} pl={15} pb={5} bg={"#552193"}>
        <Div fs={8} fw={3} color={"white"} data-cy="logged-in-welcome-msg">
          {`Good ${greetingTime}${name}`}
        </Div>
      </Div>
      <ThirdLeftTemplate
        wrapperProps={{ px: 15, py: 5, maxWidth: 1200 }}
        leftProps={{ pr: 6 }}
        leftChildren={[
          <OrgsList
            key={"orglist"}
            onSearch={onSearch}
            organizations={orgs}
            addOrganization={addOrganization}
            isSearching={isSearching}
            scrollToPortals={scrollToPortals}
            scrollToEvents={scrollToEvents}
            hasPortals={hasPortals}
            hasEvents={hasEvents}
          />
        ]}
        rightChildren={[
          <Pinned key={"pinned"} events={pinnedEvents} />,
          ...R.map(
            ({
              id,
              name,
              unpinEvent,
              pinEvent,
              photoURL,
              goToOrg,
              archiveOrg,
              events,
              setOrgRef
            }) => (
              <div key={id} ref={setOrgRef}>
                <OrgCard
                  id={id}
                  name={name}
                  unpinEvent={unpinEvent}
                  pinEvent={pinEvent}
                  photoURL={photoURL}
                  goToOrg={goToOrg}
                  archiveOrg={archiveOrg}
                  events={events}
                />
              </div>
            ),
            orgsWithEvents
          ),
          <div key="events" ref={setEventsRef}>
            <EventListCard
              Icon={CalendarIcon}
              name={"Events you manage"}
              titleMessage={"You manage these events"}
              events={managedEvents}
            />
          </div>,
          <div key="portals" ref={setPortalsRef}>
            <EventListCard
              Icon={OpenInNewIcon}
              name={"Events shared with you"}
              titleMessage={"You have portal access to these events"}
              events={portals}
            />
          </div>
        ]}
      />
    </Div>
  </DocumentTitle>
);
export default View;
