import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";

import { actions, getters } from "Items/AddQuestionSetModal";

import { eventId as getEventId } from "redux/modules/event/selectors";

import { getCredentials } from "redux/modules/user/selectors";

import { registerError } from "redux/modules/errors/actions";

import Api from "Items/AddQuestionSetModal/api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  return {
    credentials,
    eventId
  };
};

const init = function*({ payload: { questionSetId } = {} }) {
  try {
    yield put(actions.setLoading(true));

    if (questionSetId) {
      const { credentials } = yield call(getParams);
      const { payload: existingQuestionSet } = yield call(
        Api.getQuestionSet,
        credentials,
        questionSetId
      );
      yield put(actions.setInitialQuestionSet(existingQuestionSet));
    }
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading questions"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const save = function*({ payload: { onDone, hideModal } }) {
  try {
    yield put(actions.setSaving(true));
    const { credentials, eventId } = yield call(getParams);

    const name = yield select(getters.name);

    const questionSetId = yield select(getters.questionSetId);

    const saveFn = questionSetId ? Api.updateQuestionSet : Api.addQuestionSet;

    const fnPayload = questionSetId
      ? {
          itemQuestionSet: { id: questionSetId, name }
        }
      : {
          name,
          eventId
        };

    if (name.length >= 2) {
      yield call(saveFn, {
        credentials,
        eventId,
        data: fnPayload
      });
    }
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occured while adding question set"
          }
        ])
      )
    ]);
  } finally {
    yield call(onDone);
    yield call(hideModal);
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchSave = function*() {
  yield takeEvery(actions.save.type, save);
};

const rootSaga = function*() {
  yield all([fork(watchInit), fork(watchSave)]);
};

export default rootSaga;
