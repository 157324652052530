export const NAMESPACE = "AddSessionModal";

import { MEDIA_CONTENT_INSTANCE_ID } from "ui-kit/MediaContentModal/constants";
export const CONTENT_MODAL_ID = MEDIA_CONTENT_INSTANCE_ID;

export const TABS = {
  BASIC_DETAILS: "basicDetails",
  ATTENDEE_SETTINGS: "attendeeSettings",
  CONTENT: "content"
};

export const SESSION_FORM_ID = "AddSessionForm";

export const DATE_FORMAT = "M/D [at] h:mma";

export const LOCATION_TYPE_IDS = {
  STAGE: "stage",
  ROOM: "room",
  BOOTH: "booth",
  NETWORKING: "networking",
  EXTERNAL: "external",
  BIDDING: "bidding"
};

export const LOCATION_TYPES = [
  {
    id: LOCATION_TYPE_IDS.STAGE,
    label: "Stage"
  },
  {
    id: LOCATION_TYPE_IDS.ROOM,
    label: "Room"
  },
  {
    id: LOCATION_TYPE_IDS.BOOTH,
    label: "Booth"
  },
  {
    id: LOCATION_TYPE_IDS.NETWORKING,
    label: "Networking"
  },
  {
    id: LOCATION_TYPE_IDS.BIDDING,
    label: "Bidding"
  },
  {
    id: LOCATION_TYPE_IDS.EXTERNAL,
    label: "External"
  }
];

export const FIELD_IDS = {
  IS_PUBLIC: "is_public",
  TAGS: "tags",
  LEVEL: "level",
  LANGUAGE: "language",
  FORMAT: "format",
  SPONSORS: "sponsors",
  SPEAKERS: "speakers",
  LOCATION: "location",
  BOOTH: "location_booth_id",
  ROOM: "location_room_id",
  STAGE: "location_stage_id",
  LOCATION_EXTERNAL_URL: "location_external_url",
  //
  TITLE: "title",
  DESCRIPTION: "description",
  STARTS_AT: "starts_at",
  ENDS_AT: "ends_at",
  BACKGROUND_IMAGE_URL: "background_image_url",
  VIDEO_URL: "video_url",
  VIDEO_TITLE: "video_title",
  CONFERENCE_URL: "conference_url",
  CONFERENCE_TITLE: "conference_title",
  TRANSCRIPT_URL: "transcript_url",
  REPLAY_URL: "replay_url"
};

export const CONTENT_VIDEO = "videoContent";
