import React, { Component } from "react";
import View from "./View";
import formatVariantTitle from "components/Event/Credentials/Modals/ViewOrder/utils/format-variant-title";
import { CREDENTIAL_TYPE_ID, MEAL_TYPE_ID } from "utils/item-types";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as R from "ramda";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import ItemBlockModal from "components/Event/Settings/Credentials/Modals/ItemBlock";

const hydrateFields = (ids, fields) => {
  return ids.map(id => fields.find(f => f.id === id)).filter(f => f);
};

const buildState = template => {
  const visibleAccountFields = [];
  const requiredAccountFields = [];

  const visibleContactFields = [];
  const requiredContactFields = [];

  template.steps.forEach(step => {
    if (step.type === "fields") {
      step.groups.forEach(group => {
        if (group.type === "account") {
          group.autoInclude.forEach(({ id }) => {
            visibleAccountFields.push(id);
          });
          group.autoRequire.forEach(({ id }) => {
            requiredAccountFields.push(id);
          });
        } else if (group.type === "contact") {
          group.autoInclude.forEach(({ id }) => {
            visibleContactFields.push(id);
          });
          group.autoRequire.forEach(({ id }) => {
            requiredContactFields.push(id);
          });
        }
      });
    }
  });

  return {
    searchTerm: "",
    activeStep: 0,
    selectedTypeId: template.defaultItemType,
    selectedVariants: {},
    visibleAccountFields,
    requiredAccountFields,
    visibleContactFields,
    requiredContactFields
  };
};

class Controller extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saving: false,
      loading: true,
      visibleAccountFields: [],
      requiredAccountFields: [],
      visibleContactFields: [],
      requiredContactFields: [],
      showingSecondaryFields: [],

      // Sets default state to Pass Type selection or Field Selection, based on
      // what first scene is in resolved setup type
      ...buildState(props.template)
    };
  }

  async componentDidMount() {
    await Promise.all([
      this.getTypes(),
      this.props.getFields({
        moduleId: STANDARD_MODULE_IDS.accounts.id,
        options: {
          eventId: this.props.eventDetails.id
        }
      }),
      this.props.getFields({
        moduleId: STANDARD_MODULE_IDS.contacts.id,
        options: {
          eventId: this.props.eventDetails.id
        }
      })
    ]);

    this.setState({ loading: false });
  }

  getTypes = () => this.props.getItemTypesByEvent(this.props.eventDetails.id);

  onSave = async () => {
    this.setState({ saving: true });

    const block = await this.handleAdd();

    if (this.props.onDone) {
      this.props.onDone({
        itemBlockId: block ? block.id : null,
        visibleAccountFields: hydrateFields(
          this.state.visibleAccountFields,
          this.props.accountFields
        ),
        requiredAccountFields: hydrateFields(
          this.state.requiredAccountFields,
          this.props.accountFields
        ),
        visibleContactFields: hydrateFields(
          this.state.visibleContactFields,
          this.props.contactFields
        ),
        requiredContactFields: hydrateFields(
          this.state.requiredContactFields,
          this.props.contactFields
        )
      });
    }
    return block;
  };

  onSuccessDone = () =>
    this.props.goToNextStep
      ? this.props.goToNextStep()
      : this.props.hideModal();

  handleAdd = () => {
    const { eventDetails, formName, template } = this.props;
    const { selectedTypeId, selectedVariants } = this.state;
    const variantIds = Object.keys(selectedVariants);

    if (!variantIds.length) {
      return null;
    }

    return this.props.addItemBlock({
      eventId: eventDetails.id,
      itemTypeId: selectedTypeId,
      name: template.blockName || `${formName} - Items`,
      description: null,
      displayType: "grid",
      selectionType: "multiple",
      enableQuantityInput: true,
      enableItemLimit: false,
      variants: variantIds.map((id, idx) => ({
        variantId: id,
        priceId: null,
        limit: selectedVariants[id] ? selectedVariants[id] : null,
        order: idx,
        defaultQuantity: null,
        defaultAddToCart: null,
        disableRemoveFromCart: null,
        disableAddItem: null
      }))
    });
  };

  selectType = typeId => this.setState({ selectedTypeId: typeId });

  onVariantSelect = id => {
    this.setState(state => {
      if (typeof state.selectedVariants[id] !== "undefined") {
        delete state.selectedVariants[id];
      } else {
        state.selectedVariants[id] = null;
      }
      return state;
    });
  };

  isVariantSelected = id => {
    return typeof this.state.selectedVariants[id] === "undefined"
      ? false
      : true;
  };

  selectAllVariants = () => {
    const activeType = this.props.types.find(
      t => t.id === this.state.selectedTypeId
    );
    this.setState(state => {
      state.selectedVariants = this.getGroupsWithItems(
        activeType.groups
      ).reduce((map, group) => {
        this.getItemsMatchingSearch(group.items).forEach(item => {
          item.variants.forEach(variant => {
            map[variant.id] = state.selectedVariants[variant.id]
              ? state.selectedVariants[variant.id]
              : null;
          });
        });

        return map;
      }, {});

      return state;
    });
  };

  removeAllVariants = () => {
    this.setState({
      selectedVariants: {}
    });
  };

  onSearchChange = searchTerm => {
    this.setState({ searchTerm });
  };

  getItemsMatchingSearch = items => {
    if (!this.state.searchTerm || !this.state.searchTerm.length) return items;

    const term = this.state.searchTerm.toLowerCase();

    return items.filter(i => i.name.toLowerCase().includes(term));
  };

  getGroupsWithItems = groups => {
    return groups.filter(g => this.getItemsMatchingSearch(g.items).length);
  };

  updateVariantQuantity = (id, quantity) =>
    this.setState(state => {
      state.selectedVariants = {
        ...state.selectedVariants,
        [id]: quantity <= 0 ? 0 : quantity
      };
      return state;
    });

  showItemBlockModal = id => {
    this.props.showModal({
      content: <ItemBlockModal onDone={this.props.onDone} itemBlockId={id} />,
      wrapper: ModalWrapper
    });
  };

  isValid = () => {
    return true;
  };

  toggleField = (path, fieldId, toggle) => {
    return this.setState(state => {
      if (toggle) {
        state[path].push(fieldId);
      } else {
        state[path] = state[path].filter(id => id !== fieldId);
      }
      return state;
    });
  };

  toggleSecondaryFields = (groupId, toggle) => {
    return this.setState(state => {
      if (toggle) {
        state.showingSecondaryFields.push(groupId);
      } else {
        state.showingSecondaryFields = state.showingSecondaryFields.filter(
          id => id !== groupId
        );
      }
      return state;
    });
  };

  getActiveStepDetails = () => this.props.template.steps[this.state.activeStep];

  render() {
    const { hideModal, types, eventDetails, template } = this.props;
    const {
      loading,
      saving,
      selectedTypeId,
      selectedVariants,
      searchTerm,
      activeStep
    } = this.state;

    const stepsWithHandlers = template.steps.map((step, idx) => ({
      ...step,
      active: activeStep >= idx
    }));

    let itemTypes = [];
    let itemTypesWithHandlers = [];
    let activeType = null;
    let activeStepWithHandlers = {
      ...this.getActiveStepDetails()
    };

    if (activeStepWithHandlers.type === "types") {
      // add handlers to item types
      itemTypes = types.map(type => {
        let color = "#ccc";
        let icon = "done";
        const groups = this.getGroupsWithItems(type.groups);

        if (type.id === CREDENTIAL_TYPE_ID) {
          color = STANDARD_MODULE_IDS.credentials.color;
          icon = STANDARD_MODULE_IDS.credentials.icon;
        } else if (type.id === MEAL_TYPE_ID) {
          color = STANDARD_MODULE_IDS.catering.color;
          icon = STANDARD_MODULE_IDS.catering.icon;
        }

        return {
          id: type.id,
          name: type.name,
          color,
          icon,
          countOfItems: groups.reduce((a, b) => {
            a = a + b.items.length;
            return a;
          }, 0),
          countOfCategories: groups.length,
          onClick: () => this.selectType(type.id)
        };
      });
    } else if (activeStepWithHandlers.type === "items") {
      // add handlers to items
      itemTypesWithHandlers = R.map(type => {
        let color = "#ccc";
        let icon = "done";
        const groups = this.getGroupsWithItems(type.groups);

        if (type.id === CREDENTIAL_TYPE_ID) {
          color = STANDARD_MODULE_IDS.credentials.color;
          icon = STANDARD_MODULE_IDS.credentials.icon;
        } else if (type.id === MEAL_TYPE_ID) {
          color = STANDARD_MODULE_IDS.catering.color;
          icon = STANDARD_MODULE_IDS.catering.icon;
        }

        return {
          id: type.id,
          name: type.name,
          color,
          icon,
          countOfItems: groups.reduce((a, b) => {
            a = a + b.items.length;
            return a;
          }, 0),
          countOfCategories: groups.length,
          onClick: () => this.selectType(type.id),
          groups: R.compose(
            R.filter(g => this.getItemsMatchingSearch(g.items).length),
            R.map(group => ({
              ...group,
              items: R.map(item => {
                const variantId = item.variants[0].id;
                return {
                  ...item,
                  variants: R.map(variant => {
                    const quantity =
                      selectedVariants[variant.id] >= 0
                        ? selectedVariants[variant.id]
                        : 0;
                    return {
                      ...variant,
                      quantity,
                      count: selectedVariants[variant.id],
                      selected: this.isVariantSelected(variant.id),
                      onToggle: () => this.onVariantSelect(variant.id),
                      incrementQuantity: () =>
                        this.updateVariantQuantity(variant.id, quantity + 1),
                      decrementQuantity: () =>
                        this.updateVariantQuantity(variant.id, quantity - 1),
                      updateVariantQuantity: qty =>
                        this.updateVariantQuantity(
                          variant.id,
                          parseInt(qty, 10) || 0
                        )
                    };
                  }, item.variants)
                };
              })(R.filter(i => i.variants.length)(group.items))
            }))
          )(type.groups)
        };
      }, types);

      activeType = itemTypesWithHandlers.find(
        ({ id }) => id === selectedTypeId
      );
    } else if (activeStepWithHandlers.type === "fields") {
      const prepareField = (type, field) => {
        const isVisibleKey =
          type === "account" ? "visibleAccountFields" : "visibleContactFields";
        const isRequiredKey =
          type === "account"
            ? "requiredAccountFields"
            : "requiredContactFields";

        const isVisible = this.state[isVisibleKey].includes(field.id);
        const isRequired = this.state[isRequiredKey].includes(field.id);

        return {
          ...field,
          isVisible,
          isRequired,
          onToggleVisible: () =>
            this.toggleField(isVisibleKey, field.id, !isVisible),
          onToggleRequired: () =>
            this.toggleField(isRequiredKey, field.id, !isRequired)
        };
      };

      activeStepWithHandlers = {
        ...activeStepWithHandlers,
        groups: R.map(group => {
          const isShowingSecondaryFields = this.state.showingSecondaryFields.includes(
            group.id
          );
          return {
            ...group,
            toggleSecondaryFields: () =>
              this.toggleSecondaryFields(group.id, !isShowingSecondaryFields),
            isShowingSecondaryFields,
            primary: R.map(f => prepareField(group.type, f))(group.primary),
            secondary: R.map(f => prepareField(group.type, f))(group.secondary)
          };
        })(activeStepWithHandlers.groups)
      };
    }

    activeStepWithHandlers = {
      ...activeStepWithHandlers,
      active: true,
      onBack:
        // disable if it's last step or no previous step
        activeStep !== template.steps.length - 1 &&
        template.steps[activeStep - 1]
          ? () =>
              this.setState({
                activeStep: activeStep - 1
              })
          : undefined,
      onNext: template.steps[activeStep + 1]
        ? async () => {
            // if there's only one step left, assume last step is "success" state
            // so fire the onSave now
            if (!template.steps[activeStep + 2]) {
              await this.onSave();
            }

            this.setState({
              activeStep: activeStep + 1,
              saving: false
            });
          }
        : undefined
    };

    return (
      <View
        {...{
          hideModal,
          //
          loading,
          saving,
          searchTerm,
          activeType,
          //
          types: itemTypes,
          steps: stepsWithHandlers,
          //
          activeStep: activeStepWithHandlers,
          title: activeStepWithHandlers.title,
          subtitle: activeStepWithHandlers.subtitle,
          isValid: this.isValid(),
          onBack: activeStepWithHandlers.onBack,
          onNext: activeStepWithHandlers.onNext,
          //
          onSuccessDone: this.onSuccessDone,
          selectAll: this.selectAllVariants,
          removeAll: this.removeAllVariants,
          countOfSelectedVariants: Object.keys(selectedVariants).length,
          onSearchChange: this.onSearchChange,
          eventDateGroups: eventDetails.date_groups,
          cateringMealsPerDay: R.pathOr(
            [],
            ["module_settings", "catering", "meal_days"],
            eventDetails
          )
        }}
      />
    );
  }
}

Controller.defaultProps = {
  itemBlockId: null,
  itemBlock: {},
  items: []
};

export default Controller;
