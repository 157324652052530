import React, { Component } from "react";

import toCurrencyString from "utils/value-types/to-string/currency";
import View from "./View";
import CanUserDo from "components/Global/Security/CanUserDo";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_MODULE_FIELDS from "utils/standard-module-field-ids";
import * as R from "ramda";
import {
  Text2,
  Div,
  SmallOutlineButton,
  PopoverMenu,
  FontIcon,
  HalfSizeDotIcon,
  ErrorIcon,
  indexedColors,
  BigTextBox,
  RefreshIcon,
  MediumFilledButton
} from "components/Base";
import { withProps } from "utils/General";
import { StatusTag } from "utils/status-comps";
import {
  EVENTBRITE_LOGO_SRC,
  FRONTGATE_LOGO_SRC
} from "components/Event/Settings/Credentials/constants";
import resolveFormatter from "components/Global/Table3/CellFormatters/utils/resolveFormatter";
import getMetaData from "utils/value-types/get-meta-data";

import { INTEGRATIONS } from "utils/integration-types";

const ticketingProviderLookup = {
  frontgate: {
    logoUrl: FRONTGATE_LOGO_SRC
  },
  eventbrite: {
    logoUrl: EVENTBRITE_LOGO_SRC
  }
};

const headerCellWithAvatar = (key, label, photoURL) => ({
  sortKey,
  SortIcon
}) => {
  return (
    <Div width={1} display="row.flex-start.center">
      <Div
        size={15}
        bg="orange5"
        mr={1}
        style={{
          flexShrink: 0,
          backgroundImage: `url(${photoURL})`,
          backgroundPosition: "center center",
          backgroundSize: "cover"
        }}
      />
      <Text2 bold>{label}</Text2>
      {sortKey === key && <SortIcon size={16} ml={1} />}
    </Div>
  );
};

const Actions = ({
  viewOrder,
  deleteOrder,
  archiveOrder,
  unarchiveOrder,
  isArchived,
  canDo
}) => {
  const menuItems = [];
  if (canDo(`${STANDARD_MODULE_IDS.orders}_read`)) {
    menuItems.push([["View", viewOrder]]);
  }
  if (canDo(`${STANDARD_MODULE_IDS.orders.id}_update`)) {
    if (isArchived) {
      menuItems.push(["Unarchive", unarchiveOrder]);
    } else {
      menuItems.push(["Archive", archiveOrder]);
    }
  }
  if (deleteOrder && canDo(`${STANDARD_MODULE_IDS.orders.id}_delete`)) {
    menuItems.push(["Delete", deleteOrder]);
  }
  return (
    <Div display="row.flex-start.center">
      <CanUserDo action={`${STANDARD_MODULE_IDS.orders}_read`}>
        <SmallOutlineButton
          onClick={viewOrder}
          mr={1}
          style={{
            height: 26
          }}
        >
          <Div fs={2}> Open </Div>{" "}
        </SmallOutlineButton>{" "}
      </CanUserDo>
      {menuItems.length ? (
        <PopoverMenu
          Label={({ onClick }) => (
            <SmallOutlineButton onClick={onClick} py={1}>
              <FontIcon fs={3}> more_horiz </FontIcon>{" "}
            </SmallOutlineButton>
          )}
          menuItems={menuItems}
        />
      ) : (
        ""
      )}
    </Div>
  );
};

const integrationDetails = {
  frontgate: {
    unsynced: {
      title:
        "This view shows all orders that are approved, ASSIGNED, and NOT synced to FrontGate.",
      message:
        "NOTE:  Syncing these orders will NOT include 'bulk pack orders' for which you may intend to collect names prior to issuance.  Bulk orders can be synced explicitly on the Bulk Orders tab.",
      LeftContent: ({ onClick, timeAgo }) => (
        <Div display="column.flex-end.flex-start">
          <CanUserDo action={`${STANDARD_MODULE_IDS.orders.id}_sync`}>
            <MediumFilledButton
              LeftIcon={RefreshIcon}
              bg="altB5"
              onClick={() => onClick(INTEGRATIONS.frontgate.id)}
            >
              Run Sync
            </MediumFilledButton>
          </CanUserDo>
          <Text2 bold pt={1}>
            Last Synced {timeAgo}
          </Text2>
        </Div>
      )
    },
    unsynced_bulk: {
      title:
        "This view shows all orders that are approved, UNASSIGNED, and NOT synced to FrontGate.",
      message:
        "NOTE:  All unassigned items will sync to FrontGate as a single 'bulk order' with the Group / Account designated as the Lennd customer name.",
      LeftContent: () => (
        <BigTextBox LeftIcon={withProps({ color: "altB5" })(ErrorIcon)}>
          Bulk Orders require syncing one by one
        </BigTextBox>
      )
    }
  }
};

class InternalHandler extends Component {
  resetView = () => {
    this.props.goToView();
  };

  render() {
    const {
      orderTypeName,
      loading,
      canDo,
      orders,
      location,
      goToSetup,
      showViewOrderModal,
      goToView,
      goToSubView,
      isActiveView,
      isActiveSubView,
      exportData,
      ticketingProviderConfigurations,
      archiveOrder,
      unarchiveOrder,
      deleteOrder,
      orderType,
      showAccountModal,
      showContactModal
    } = this.props;

    const customFields = R.pathOr([], [0, "custom_fields"])(orders);

    // make views
    const views = [
      {
        id: "all-orders",
        name: "All Orders",
        selectView: () => goToView(),
        active: !location.query.view,
        subViews: [],
        filter: ({ isArchived }) => !isArchived
      },
      {
        id: "pending-approval",
        name: "Pending Approval",
        selectView: () => goToView("pending-approval"),
        active: isActiveView("pending-approval"),
        subViews: [],
        filter: ({ approvalStatus, isArchived }) =>
          approvalStatus !== "approved" && !isArchived
      },
      {
        id: "not-fulfilled",
        name: "Not Fulfilled",
        selectView: () => goToView("not-fulfilled"),
        active: isActiveView("not-fulfilled"),
        subViews: [],
        filter: ({ fulfillmentStatus, isArchived }) =>
          fulfillmentStatus !== "fulfilled" && !isArchived
      },
      {
        id: "archived",
        name: "Archived",
        selectView: () => goToView("archived"),
        active: isActiveView("archived"),
        subViews: [],
        filter: ({ isArchived }) => isArchived
      }
    ];

    /*
    // @NOTE: Commenting out until re-visited
    for (const providerConfig of ticketingProviderConfigurations) {
      views.push({
        id: providerConfig.provider.slug,
        name: providerConfig.provider.name,
        selectView: () => goToView(providerConfig.provider.slug),
        active: isActiveView(providerConfig.provider.slug),
        subViews: [
          {
            id: `${providerConfig.provider.slug}_unsynced`,
            name: "Ready to Sync",
            defaultSubView: true,
            selectSubView: () =>
              goToSubView(
                providerConfig.provider.slug,
                `${providerConfig.provider.slug}_unsynced`
              ),
            active:
              !location.query.subView ||
              isActiveSubView(
                providerConfig.provider.slug,
                `${providerConfig.provider.slug}_unsynced`
              ),
            icon: ErrorIcon,
            iconColor: indexedColors.warning5,
            header: integrationDetails[providerConfig.provider.slug].unsynced,
            filter: order => {
              return (
                !order.isBulkOrder &&
                !(
                  order[`${providerConfig.provider.slug}_synced`] == "synced"
                ) &&
                order.integrations &&
                R.find(
                  integration =>
                    integration.slug == providerConfig.provider.slug,
                  order.integrations
                )
              );
            }
          },
          {
            id: `${providerConfig.provider.slug}_unsynced_bulk`,
            name: "Unsynced Bulk Orders",
            selectSubView: () =>
              goToSubView(
                providerConfig.provider.slug,
                `${providerConfig.provider.slug}_unsynced_bulk`
              ),
            active: isActiveSubView(
              providerConfig.provider.slug,
              `${providerConfig.provider.slug}_unsynced_bulk`
            ),
            icon: ErrorIcon,
            iconColor: indexedColors.warning5,
            header:
              integrationDetails[providerConfig.provider.slug].unsynced_bulk,
            filter: order => {
              return (
                order.isBulkOrder &&
                !(
                  order[`${providerConfig.provider.slug}_synced`] == "synced"
                ) &&
                order.integrations &&
                R.find(
                  integration =>
                    integration.slug == providerConfig.provider.slug,
                  order.integrations
                )
              );
            }
          },
          {
            id: `${providerConfig.provider.slug}_synced`,
            name: "Synced",
            selectSubView: () =>
              goToSubView(
                providerConfig.provider.slug,
                `${providerConfig.provider.slug}_synced`
              ),
            active: isActiveSubView(
              providerConfig.provider.slug,
              `${providerConfig.provider.slug}_synced`
            ),
            icon: HalfSizeDotIcon,
            iconColor: indexedColors.success8,
            header: integrationDetails[providerConfig.provider.slug].synced,
            filter: order => {
              return (
                order[`${providerConfig.provider.slug}_synced`] == "synced" &&
                order.integrations &&
                R.find(
                  integration =>
                    integration.slug == providerConfig.provider.slug,
                  order.integrations
                )
              );
            }
          }
        ],
        filter: order => {
          return (
            order.integrations &&
            R.find(
              integration => integration.slug == providerConfig.provider.slug,
              order.integrations
            )
          );
        }
      });
    }
    */

    let activeView = R.find(view => isActiveView(view.id), views);
    if (!activeView) {
      activeView = R.find(view => view.id == "all-orders", views);
    }
    let activeSubView =
      activeView && activeView.subViews
        ? R.find(
            subView => isActiveSubView(activeView.id, subView.id),
            activeView.subViews
          )
        : null;
    if (
      !activeSubView &&
      activeView.subViews &&
      activeView.subViews.length > 0
    ) {
      activeSubView = R.find(
        subView => subView.defaultSubView == true,
        activeView.subViews
      );
    }

    let fieldsToShow = [
      {
        id: "order",
        name: "Order",
        type: "text",
        Header: "Order",
        accessor: ({ order, viewOrder }) => ({ order, viewOrder }),
        Cell: ({ value }) => (
          <Text2 onClick={value.viewOrder} color={"primary7"} bold>
            {value.order}
          </Text2>
        ),
        visible: true
      },
      {
        id: "contact",
        name: "Contact",
        type: "text",
        Header: "Contact",
        accessor: ({ contact, showContactModal }) => ({
          contact,
          showContactModal
        }),
        Cell: ({ value }) => (
          <Text2
            underline={
              showContactModal ? { default: false, hover: true } : false
            }
            bold
            onClick={value.showContactModal}
          >
            {value.contact}
          </Text2>
        ),
        visible: true,
        settings: {
          moduleId: "24f40f97-c249-4d86-8328-8f2e9ad69536",
          fieldId: "full_name"
        }
      },
      {
        id: "account",
        name: "Account",
        type: "text",
        Header: "Account",
        accessor: ({ account, showAccountModal }) => ({
          account,
          showAccountModal
        }),
        Cell: ({ value }) => (
          <Text2
            underline={
              showAccountModal ? { default: false, hover: true } : false
            }
            bold
            onClick={value.showAccountModal}
          >
            {value.account}
          </Text2>
        ),
        visible: true,
        settings: {
          moduleId: "575edc78-bfb4-4efa-8225-0fa0a93a1ff0",
          fieldId: "name"
        }
      },
      {
        id: "approvalStatus",
        name: "Approval Status",
        type: "text",
        Header: "Approval",
        accessor: ({ approvalStatus }) => approvalStatus,
        Cell: ({ value }) => (
          <Div>
            <StatusTag status={value} />
          </Div>
        ),
        visible: true
      },
      {
        id: "fulfillmentStatus",
        name: "Fulfillment Status",
        type: "text",
        Header: "Fulfillment",
        accessor: ({ fulfillmentStatus }) => fulfillmentStatus,
        Cell: ({ value }) => (
          <Div>
            <StatusTag status={value} />
          </Div>
        ),
        visible: true
      },
      {
        id: "total",
        name: "Total",
        type: "text",
        Header: "Total",
        accessor: ({ total }) => total,
        Cell: ({ value }) => (
          <Text2 color={"neutral7"} bold>
            {toCurrencyString(value)}
          </Text2>
        ),
        visible: true
      },
      {
        id: "source",
        name: "Source",
        type: "text",
        Header: "Source",
        accessor: ({ source }) => source,
        Cell: ({ value }) => (
          <Text2 color={"neutral7"} bold>
            {value}
          </Text2>
        ),
        visible: true
      }
    ].filter(Boolean);

    // Generate integration columns from ticketing service providers
    /*
    // @NOTE: Hiding until implemented
    ticketingProviderConfigurations.forEach(providerConfig => {
      const syncedId = `${providerConfig.provider.slug}_synced`;
      const statusId = `${providerConfig.provider.slug}_status`;

      fieldsToShow.push({
        id: syncedId,
        name: `${providerConfig.provider.name} - Sync Status`,
        Header: headerCellWithAvatar(
          syncedId,
          "Sync Status",
          ticketingProviderLookup[providerConfig.provider.slug].logoUrl
        ),
        type: "text",
        is_primary_field: false,
        visible: true,
        accessor: row => row[syncedId],
        Cell: ({ value }) => (
          <Div>{value ? <StatusTag status={value} /> : "—"}</Div>
        )
      });
    });
    */

    fieldsToShow.push(
      ...customFields.map(field => {
        const Renderer = resolveFormatter(field);
        return {
          id: field.id,
          name: field.name,
          type: field.type,
          is_primary_field: field.is_primary_field,
          Header: field.name,
          accessor: ({ relatedRecord }) => ({
            relatedRecord
          }),
          Cell: ({ value }) => {
            const meta = {
              ...getMetaData({
                row: R.path(["relatedRecord", "values"])(value),
                rowId: R.path(["relatedRecord", "id"])(value),
                field,
                eventId: this.props.eventId
              }),
              helpers: {}
            };
            const props = {
              dependentValues: meta,
              value: R.path(["relatedRecord", "values", field.id])(value)
            };
            return <Renderer {...props} />;
          },
          visible: true
        };
      }),
      {
        id: "actions",
        name: "Actions",
        type: "text",
        is_primary_field: false,
        Header: "Actions",
        accessor: ({
          viewOrder,
          deleteOrder,
          archiveOrder,
          unarchiveOrder,
          isArchived,
          canDo
        }) => ({
          viewOrder,
          deleteOrder,
          archiveOrder,
          unarchiveOrder,
          isArchived,
          canDo
        }),
        Cell: ({ value }) => <Actions {...value} />,
        visible: false
      }
    );

    // make column props
    const filterFn = activeSubView ? activeSubView.filter : activeView.filter;

    const orderSyncStatus = (
      syncedIntegrationItemCount,
      totalIntegrationItemCount
    ) => {
      if (totalIntegrationItemCount == 0) {
        return null;
      } else if (syncedIntegrationItemCount == 0) {
        return "not_synced";
      } else if (totalIntegrationItemCount == syncedIntegrationItemCount) {
        return "synced";
      } else {
        return "partially_synced";
      }
    };

    // we parse the table data here from the orders
    const data = R.compose(
      R.filter(filterFn),
      R.map(props => {
        const viewOrder = () =>
          showViewOrderModal({
            orderId: props.id
          });

        // get order
        const order = "#" + R.propOr("", "number", props);

        // get contact
        const contact = R.compose(
          R.join(" "),
          R.map(R.prop("value")),
          R.props([
            STANDARD_MODULE_FIELDS.CONTACTS.FIRST_NAME,
            STANDARD_MODULE_FIELDS.CONTACTS.LAST_NAME
          ])
        )(R.pathOr({}, ["customer", "contact_values"], props)).trim();

        // get account
        const account_name = R.path(["customer", "account_name"], props);
        const account = account_name;

        // get approvalStatus
        const approvalStatus = R.prop("approval_status", props);

        // get paymentStatus
        // TODO

        // get fulfillmentStatus
        const fulfillmentStatus = R.prop("fulfillment_status", props);

        // get total
        const total = props.line_items.reduce(
          (sum, { quantity, price }) => sum + quantity * price,
          0
        );

        // get source
        const source = R.pathOr("—", ["source", "form_name"], props);

        // get actions
        const actions = [];

        // get integrations
        const orderIntegrations = R.propOr([], "integration_orders")(props);

        const isBulkOrder = !!R.find(
          lineItem => R.isNil(R.path(["assigned_to", "id"], lineItem)),
          props.line_items || []
        );

        const totalItemCount = props.line_items.reduce(
          (sum, { quantity }) => sum + quantity,
          0
        );

        const integrationDict = {};
        const contactId = R.path(["customer", "contact_id"])(props);
        const accountId = R.path(["customer", "account_id"])(props);

        return {
          id: props.id,
          order,
          contact,
          account,
          approvalStatus,
          fulfillmentStatus,
          relatedRecord: props.related_record,
          viewOrder,
          showContactModal: contactId
            ? () => showContactModal(contactId)
            : undefined,
          showAccountModal: accountId
            ? () => showAccountModal(accountId)
            : undefined,
          deleteOrder: () =>
            deleteOrder({
              orderId: props.id
            }),
          archiveOrder: () =>
            archiveOrder({
              orderId: props.id
            }),
          unarchiveOrder: () =>
            unarchiveOrder({
              orderId: props.id
            }),
          // paymentStatus,
          total,
          source,
          isBulkOrder: isBulkOrder,
          isArchived: props.is_archived,
          ...integrationDict,
          actions,
          canDo,

          // Spread the related record values here so they can be picked up by the export helper
          ...R.pathOr({}, ["related_record", "values"])(props)
        };
      })
    )(orders || []);

    return (
      <View
        orderTypeName={orderTypeName}
        loading={loading}
        columns={fieldsToShow}
        totalPrice={`${toCurrencyString(
          data.reduce((sum, { total }) => sum + total, 0)
        )}`}
        moduleId={STANDARD_MODULE_IDS.orders.id}
        views={views}
        activeView={activeView}
        activeSubView={activeSubView}
        data={data}
        syncAllOrders={this.props.syncAllOrders}
        showAddOrderModal={this.props.showAddOrderModal}
        goToSetup={goToSetup}
        exportXLSX={() => exportData("xlsx", data, fieldsToShow)}
        exportCSV={() => exportData("csv", data, fieldsToShow)}
        isFiltered={location.query.view && location.query.view.length}
        resetView={this.resetView}
        orderType={orderType}
        EmptyState={this.props.EmptyState}
        Actions={this.props.Actions}
        title={this.props.title}
      />
    );
  }
}

export default InternalHandler;
