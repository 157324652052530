import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function updateItemBlockVariant(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.updateItemBlock(
        getState().user.user.credentials,
        data
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating item block variant"
          }
        ])
      );
    }
  };
}

export function bulkUpdateItemBlockVariants(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.bulkUpdateItemBlockVariants(
        getState().user.user.credentials,
        {
          ...data,
          bulk: true
        }
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred bulk updating item block variants"
          }
        ])
      );
    }
  };
}
