import * as R from "ramda";
import {
  put,
  all,
  takeEvery,
  select,
  call,
  fork,
  take
} from "redux-saga/effects";

import {
  getItem,
  getUpdatedCategory,
  getUpdatedItemFormFields,
  getCategoryToAdd
} from "Sponsors/SelfSetup/selectors";
import { BOOTH_TYPE_ID } from "utils/item-types";

import { STEPS } from "Sponsors/SelfSetup/constants";

import { ITEM_FIELDS } from "Sponsors/PackageTable/constants";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import { BEGIN, COMMIT, REVERT } from "redux-optimist";

import { actions as FormActions } from "ui-kit/Form/model";
import { actions as TabActions } from "ui-kit/Tabs";
import { actions } from "Sponsors/SelfSetup";

import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { registerError } from "redux/modules/errors/actions";

import uuid from "node-uuid";

import Api from "../api";

const setFormFields = function*(items = []) {
  const forms = R.map(
    form => ({
      id: form.id,
      values: R.map(value => ({ value }), R.pick(R.values(ITEM_FIELDS), form))
    }),
    items
  );
  yield put(FormActions.bulkWriteForms(forms));
};

const getItemTypes = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  try {
    const { payload } = yield call(Api.getItemTypes, {
      credentials,
      eventId
    });

    const { types, groups, items, variants } = R.reduce(
      (list, type) => {
        list.types.push(type);
        type.groups.forEach(group => {
          list.groups.push({
            ...group,
            type_id: type.id
          });
          group.items.forEach(item => {
            list.items.push({
              ...item,
              group_order: group.order,
              type
            });
            item.variants.forEach(variant => {
              list.variants.push({
                ...variant,
                item
              });
            });
          });
        });
        return list;
      },
      {
        types: [],
        groups: [],
        items: [],
        variants: []
      }
    )(payload);

    yield all([
      put(
        actions.setItemData({
          types,
          groups,
          items,
          variants
        })
      ),
      put(TabActions.setSelectedTab(BOOTH_TYPE_ID))
    ]);

    yield call(setFormFields, items);
  } catch (e) {
    yield put(
      registerError([
        {
          system: e,
          user: "An error occurred fetching the item types"
        }
      ])
    );
  }
};

const init = function*() {
  for (;;) {
    const { payload } = yield take(actions.init.type);
    if (payload === STEPS.ITEMS) {
      yield put(actions.setLoading(true));
      yield call(getItemTypes);
      yield put(actions.setLoading(false));
    }
  }
};

const addItem = function*({ payload }) {
  const transactionId = yield call([uuid, uuid.v4]);
  yield put(
    actions.addItem(payload, {
      optimist: { type: BEGIN, id: transactionId }
    })
  );

  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  try {
    const {
      payload: { item }
    } = yield call(Api.createPackage, {
      credentials,
      item: {
        name: "",
        typeId: payload.type,
        eventId
      }
    });

    yield put(
      actions.addItemResponse(
        { oldId: payload.id, newItem: item },
        {
          optimist: { type: COMMIT, id: transactionId }
        }
      )
    );

    yield call(setFormFields, [item]);
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred creating the item"
          }
        ])
      ),
      yield put(
        actions.addItemResponse(null, {
          optimist: { type: REVERT, id: transactionId }
        })
      )
    ]);
  }
};

const revert = function*({ payload: itemId }) {
  const item = yield select(getItem, { id: itemId });
  yield call(setFormFields, [item]);
};

const watchRevertUpdate = function*() {
  for (;;) {
    const action = yield take(actions.updateItemResponse.type);
    if (action.error) {
      yield call(revert, action);
    }
  }
};

const watchAddItem = function*() {
  yield takeEvery(actions.addItemRequest.type, addItem);
};

const updateItem = function*({ item }) {
  const credentials = yield select(getCredentials);
  try {
    yield call(Api.updatePackage, {
      credentials,
      item
    });
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred updating the item"
          }
        ])
      ),
      put(actions.updateItemResponse(item.id, {}, true))
    ]);
  }
};

const fieldDiffs = (newPkg, oldPkg) =>
  R.pick(
    R.filter(key => newPkg[key] !== oldPkg[key], Object.keys(newPkg)),
    newPkg
  );

const followUpdate = function*({ id, item }) {
  for (;;) {
    const {
      meta: { instanceId }
    } = yield take(FormActions.blurField.type);
    if (instanceId === id) {
      break;
    }
  }

  const newItem = yield select(getUpdatedItemFormFields, { id });
  const diffItem = fieldDiffs(newItem, item);
  yield fork(updateItem, { item: { id: id, ...diffItem } });
};

const watchUpdateItem = function*() {
  for (;;) {
    const {
      meta: { instanceId, fieldType }
    } = yield take(FormActions.setFieldValue.type);

    const item = yield select(getItem, { id: instanceId });
    if (item) {
      if (fieldType !== FIELD_TYPES.VALUE) {
        const item = yield select(getUpdatedCategory, { id: instanceId });
        yield fork(updateItem, { item });
      } else {
        yield call(followUpdate, { id: instanceId, item });
      }
    }
  }
};

const deleteItem = function*({ payload: itemId }) {
  const transactionId = yield call([uuid, uuid.v4]);
  yield put(
    actions.deleteItem(itemId, {
      optimist: { type: BEGIN, id: transactionId }
    })
  );

  const credentials = yield select(getCredentials);
  try {
    yield call(Api.deletePackage, {
      credentials,
      itemId
    });
    yield put(
      actions.deleteItemResponse(itemId, {
        optimist: { type: COMMIT, id: transactionId }
      })
    );
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred removing the item"
          }
        ])
      ),
      put(
        actions.deleteItemResponse(
          itemId,
          {
            optimist: { type: REVERT, id: transactionId }
          },
          true
        )
      )
    ]);
  }
};

const watchDeleteItem = function*() {
  yield takeEvery(actions.deleteItemRequest.type, deleteItem);
};

const addCategory = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  const category = yield select(getCategoryToAdd);
  const transactionId = yield call([uuid, uuid.v4]);

  try {
    yield call(Api.addItemGroup, {
      credentials,
      data: {
        ...category,
        eventId
      }
    });
    yield put(
      actions.setAddCategoryResponse(null, {
        optimist: { type: COMMIT, id: transactionId }
      })
    );
    yield call(getItemTypes);
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred adding category"
          }
        ])
      ),
      put(
        actions.setAddCategoryResponse(
          null,
          {
            optimist: { type: REVERT, id: transactionId }
          },
          true
        )
      )
    ]);
  }
};

const watchAddCategory = function*() {
  yield takeEvery(actions.setAddCategoryRequest.type, addCategory);
};

const rootSaga = function*() {
  yield all([
    fork(init),
    fork(watchRevertUpdate),
    fork(watchAddItem),
    fork(watchDeleteItem),
    fork(watchUpdateItem),
    fork(watchAddCategory)
  ]);
};

export default rootSaga;
