import React, { Component } from "react";
import CSSModules from "react-css-modules";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Body from "components/Global/Modal/Layout/ScrollableBody";
import Loading from "components/Global/Modal/Layout/Loading";
import css from "./styles.scss";
import * as R from "ramda";
import { Div } from "components/Base";

const ListItem = CSSModules(
  ({ item, selected, onSelect }) => (
    <Div
      display="row.flex-start.center"
      b={1}
      bc={
        selected
          ? "gray2"
          : {
              default: "white",
              hover: "gray1"
            }
      }
      bra={1}
      p={3}
      mb={1}
    >
      <Div fw={3} color="gray7" fs={4} flex={1}>
        {item.name}
      </Div>
      <div styleName="select" onClick={() => onSelect(item)}>
        select
      </div>
    </Div>
  ),
  css
);

@CSSModules(css)
class Controller extends Component {
  state = {
    loading: true,
    saving: false,
    scene: 1,
    orgs: [],
    events: [],
    orgId: this.props.orgId || null,
    eventId: this.props.eventId || null
  };

  async componentDidMount() {
    const orgs = await this.getEventbriteOrgs();

    if (orgs.length === 1) {
      const orgId = orgs[0].id;
      this.setState({
        orgs
      });

      return await this.onSelectOrg({
        id: orgId
      });
    }

    return this.setState({
      loading: false,
      orgs
    });
  }

  getEventbriteOrgs = async () => {
    const result = await this.props.getProviderConfigurationSettings(
      this.props.provider,
      {
        eventId: this.props.eventDetails.id
      },
      1
    );

    return result.configurations[0].configuration.form[0].value.selectValue
      .values;
  };

  getEventbriteEvents = async () => {
    const result = await this.props.getProviderConfigurationSettings(
      this.props.provider,
      {
        eventId: this.props.eventDetails.id
      },
      2
    );

    return result.configurations[0].configuration.form[0].value.selectValue
      .values;
  };

  onSelectOrg = async selectedOrg => {
    this.setState({
      loading: true,
      orgId: selectedOrg.id,
      eventId: selectedOrg.id !== this.state.orgId ? null : this.state.eventId
    });

    // save org id
    await this.props.updateProviderConfigurationSettings(this.props.provider, {
      eventId: this.props.eventDetails.id,
      settings: {
        configuration: {
          eventbriteOrganizationId: selectedOrg.id
        }
      }
    });

    // get events
    const events = await this.getEventbriteEvents();

    this.setState({
      loading: false,
      events,
      scene: 2
    });
  };

  onSave = async selectedEvent => {
    this.setState({
      error: null,
      saving: true,
      eventId: selectedEvent.id
    });

    // save event id
    const result = await this.props.updateProviderConfigurationSettings(
      this.props.provider,
      {
        eventId: this.props.eventDetails.id,
        settings: {
          configuration: {
            eventbriteOrganizationId: this.state.orgId,
            eventbriteEventId: selectedEvent.id
          }
        }
      }
    );

    // handle error
    const hasError = result ? Boolean(result.errorCode) : false;
    if (hasError) {
      this.setState({
        error: {
          code: result ? result.errorCode : null,
          details: result
            ? result.errorDetail
            : "We encountered some trouble updating your integration."
        }
      });
    } else {
      this.props.onDone({
        resetProviderMapping: this.props.eventId !== selectedEvent.id
      });
      this.props.hideModal();
    }

    this.setState({
      saving: false
    });
  };

  render() {
    const { hideModal } = this.props;
    const { scene, orgId, eventId } = this.state;

    return (
      <StyleWrapper
        heading="Connect your Eventbrite event"
        hideModal={hideModal}
        width={550}
      >
        <Body style={{ maxHeight: 400, borderBottom: 0 }}>
          {this.state.loading ? (
            <Loading />
          ) : scene === 1 ? (
            <Div>
              <div styleName="heading">available eventbrite organizations</div>
              {this.state.orgs.map(o => (
                <ListItem
                  key={o.id}
                  selected={o.id === orgId}
                  item={o}
                  onSelect={this.onSelectOrg}
                />
              ))}
            </Div>
          ) : (
            <Div>
              <div styleName="heading">available eventbrite events</div>
              {this.state.events.map(e => (
                <ListItem
                  key={e.id}
                  selected={e.id === eventId}
                  item={e}
                  onSelect={this.onSave}
                />
              ))}
            </Div>
          )}
        </Body>
      </StyleWrapper>
    );
  }
}

export default Controller;
