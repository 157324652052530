import * as R from "ramda";
import { createModule, createHandlers } from "redux-mvc";

import selectPrice from "ui-kit/SelectPrice";

import { VIEWS } from "Sponsors/PackageTable/constants";

const iniState = {
  view: VIEWS.CREATE_PACKAGES,
  expanded: [],
  active: {},
  order: [],
  draggingId: "",
  dropId: ""
};

const reducers = {
  init: (_, { payload: { view, iniOrder } }) => ({ view, order: iniOrder }),
  toggleExpand: ({ expanded }, { payload: id }) => ({
    expanded: R.contains(id, expanded)
      ? R.without([id], expanded)
      : R.uniq(R.append(id, expanded))
  }),
  activate: ({ active }, { payload: { column, id } }) => ({
    active: {
      ...active,
      [column]: R.append(id, active[column] || [])
    }
  }),
  switchIndexes: (state, { payload: { id: dropId, up } }) => {
    if (state.draggingId === dropId) {
      return state;
    }
    const removed = R.without([state.draggingId], state.order);

    const delta = up ? 0 : 1;

    return {
      order: R.insert(
        R.findIndex(R.equals(dropId), removed) + delta,
        state.draggingId,
        removed
      )
    };
  },
  updateOrder: (state, { payload: newItems }) => {
    const newDiff = R.without(state.order, newItems);
    if (R.length(newDiff) === 0) {
      return state;
    }
    return {
      order: R.concat(state.order, newDiff)
    };
  }
};

const handlers = createHandlers({
  iniState,
  reducers,
  namespace: "PackageTable"
});

const { actions, getters, reducer } = handlers;

export { actions, getters, reducer };

const module = createModule(handlers);

module.plugModule(selectPrice);

export default module;
