import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Checkbox from "material-ui/Checkbox";
import Label from "components/Global-2016/Forms/Label";
import { get } from "lodash";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const getIsPrimary = value =>
  get(value, ["accountContact", "isPrimary"], false);

@CSSModules(css)
class ContactMappedToAccountSettings extends Component {
  @autobind
  onIsPrimaryChange() {
    this.props.onChange({
      ...this.props.value,
      accountContact: {
        isPrimary: !getIsPrimary(this.props.value)
      }
    });
  }
  render() {
    const isPrimary = getIsPrimary(this.props.value);
    return (
      <div>
        <div className="input-wrapper">
          <Label>Is primary account contact</Label>
          <Checkbox
            label="Yes"
            checked={isPrimary}
            onClick={this.onIsPrimaryChange}
          />
        </div>
      </div>
    );
  }
}

ContactMappedToAccountSettings.propTypes = {
  value: PropTypes.shape({
    accountContact: PropTypes.shape({
      isPrimary: PropTypes.bool
    })
  }).isRequired,
  onChange: PropTypes.func.isRequired
};

export default ContactMappedToAccountSettings;
