import React from "react";
import { connect } from "react-redux";
import { getters } from "Modules/SelectRequiredFieldsModal";
import { Div, BigFilledButton, BigOutlineButton } from "components/Base";

const decorate = connect(state => ({
  visibleColumns: getters.visibleFields(state),
  columnOrder: getters.fieldOrder(state),
  requiredFields: getters.requiredFields(state)
}));

const Footer = ({
  hideModal,
  onSave,
  visibleColumns,
  columnOrder,
  requiredFields
}) => (
  <Div
    height={80}
    width={1}
    bg="white"
    bt={1}
    bc="neutral1"
    display="row.space-between.center"
    px={3}
  >
    <BigFilledButton
      bg="altB4"
      onClick={() => {
        onSave({ visibleColumns, columnOrder, requiredFields });
        hideModal();
      }}
    >
      Done
    </BigFilledButton>
    <BigOutlineButton onClick={hideModal}>Cancel</BigOutlineButton>
  </Div>
);

export default decorate(Footer);
