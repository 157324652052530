import React, { Component } from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import autobind from "autobind-decorator";
import EventsDropdown from "./EventsDropdown";
import listensToClickOutside from "react-onclickoutside";

const styles = {
  container: {
    height: "45px",
    display: "flex",
    alignItems: "center"
  },
  titleArrow: {
    fontSize: 23,
    display: "inline-block",
    verticalAlign: "middle",
    marginLeft: 15
  },
  add: {
    background: "#00CC83",
    borderRadius: 5,
    fontWeight: 600,
    fontSize: 21,
    color: "#FFFFFF",
    lineHeight: "25px",
    width: 27,
    height: 28,
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: 21,
    marginLeft: 19,
    textAlign: "center"
  },
  addIcon: {
    fontSize: 21,
    lineHeight: "27px"
  },
  title: {
    position: "relative",
    fontSize: 19,
    fontWeight: "normal",
    color: "#ffffff",
    cursor: "pointer",
    paddingRight: 11,
    paddingLeft: 13,
    userSelect: "none",
    display: "flex",
    alignItems: "center"
  },
  titleText: {
    fontSize: 16,
    fontWeight: 600,
    maxWidth: 225,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "inline-block",
    verticalAlign: "middle"
  }
};

@listensToClickOutside
@Radium
export default class HeaderTitle extends Component {
  static propTypes = {
    myEvents: PropTypes.array.isRequired,
    isEvent: PropTypes.bool,
    name: PropTypes.string.isRequired,
    hideModal: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      showEventsDropdown: false
    };
  }

  handleClickOutside(event) {
    if (this.state.showEventsDropdown) {
      this.setState({ showEventsDropdown: false });
    }
  }

  render() {
    let eventsDropdown;
    if (this.state.showEventsDropdown) {
      eventsDropdown = (
        <EventsDropdown
          location={this.props.location}
          myEvents={this.props.myEvents}
          user={this.props.user}
          showModal={this.props.showModal}
          hideModal={this.props.hideModal}
          hideDropdown={this._hideEventsDropdown}
        />
      );
    }

    let titleResponsive;
    let titleResponsiveWide;
    let iconResponsive;
    let iconResponsiveWide;
    if (this.props.isEvent) {
      titleResponsiveWide = {
        "@media (max-width: 1000px)": {
          fontSize: 14
        }
      };
      titleResponsive = {
        "@media (max-width: 850px)": {
          // display: 'none'
        }
      };
      iconResponsiveWide = {
        "@media (max-width: 1000px)": {
          marginLeft: 10
        }
      };
      iconResponsive = {
        "@media (max-width: 850px)": {
          marginLeft: 0
        }
      };
    } else {
      titleResponsive = {
        "@media (max-width: 680px)": {
          // display: 'none'
        }
      };
      iconResponsive = {
        "@media (max-width: 680px)": {
          marginLeft: 0
        }
      };
    }

    return (
      <div style={styles.container}>
        <div
          className="header-events-dropdown-toggle"
          style={styles.title}
          onClick={this._toggleShowEventsDropdown}
        >
          <span
            style={[styles.titleText, titleResponsive, titleResponsiveWide]}
          >
            {this.props.name}
          </span>
          <i
            className="material-icons"
            style={[styles.titleArrow, iconResponsive, iconResponsiveWide]}
          >
            &#xE313;
          </i>
        </div>

        {eventsDropdown}
      </div>
    );
  }

  @autobind
  _toggleShowEventsDropdown() {
    this.setState({
      showEventsDropdown: !this.state.showEventsDropdown
    });
  }

  @autobind
  _hideEventsDropdown() {
    this.setState({ showEventsDropdown: false });
  }
}
