import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import moment from "moment";
import autobind from "autobind-decorator";
import { isEqual } from "lodash";
import ALL_TIMEZONES from "utils/timezones";

import ManageEventActions from "actions/Event/DetailsActions";
import Helpers from "utils/Global/Helpers";

@CSSModules(css)
class EventDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.details.name,
      date: moment(new Date(this.props.details.date_from)),
      modules: this.props.details.modules,
      logoImageUrl: this.props.details.logo_image_url,
      backgroundImageUrl: this.props.details.background_image_url,
      timezone: this.props.details.timezone
    };
  }

  @autobind
  handleChange(event) {
    const state = this.state;
    state[event.target.getAttribute("name")] = event.target.value;
    this.setState(state);
  }

  @autobind
  handleDateChange(date) {
    this.setState({ date });
  }

  @autobind
  validateData() {
    if (!this.state.name) {
      this.props.showSnackbar({
        message: "Enter the name of your event"
      });
    } else if (!this.state.date) {
      this.props.showSnackbar({
        message: "Select the date of your event"
      });
    } else {
      this.saveChanges();
    }
  }

  @autobind
  saveChanges() {
    const details = this.props.details;
    details.name = this.state.name;
    details.modules = this.state.modules;
    details.logo_image_url = this.state.logoImageUrl;
    details.background_image_url = this.state.backgroundImageUrl;
    details.timezone = this.state.timezone;
    ManageEventActions.updateEvent(
      this.props.user.credentials,
      details.id,
      details,
      () => {
        this.props.getEvent(details.id);
        this.props.getEvents(this.props.user.id);
        this.props.getUserAccessLevels();
      }
    );
    this.props.showSnackbar({
      message: "Your event has been updated"
    });
  }

  @autobind
  isChecked(key) {
    const { modules } = this.state;
    return modules[key] && modules[key].enabled === true;
  }

  @autobind
  toggleModule(key) {
    this.setState(state => {
      state.modules[key].enabled = !state.modules[key].enabled;
      return state;
    });
  }

  selectLogoImageUrl = () => {
    this.selectUrl({
      path: "event-logo-image/",
      key: "logoImageUrl"
    });
  };

  selectBackgroundImageUrl = () => {
    this.selectUrl({
      path: "event-background-image/",
      key: "backgroundImageUrl"
    });
  };

  selectUrl = ({ path, key }) => {
    const opts = { path };
    const callback = InkBlobs => {
      if (InkBlobs[0] && typeof InkBlobs[0].url !== "undefined") {
        this.setState({
          [key]: InkBlobs[0].url
        });
      }
    };
    Helpers.getFilepicker({}, opts, callback);
  };

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.details, nextProps.details)) {
      this.setState({
        name: nextProps.details.name,
        date: moment(new Date(nextProps.details.date_from)),
        modules: nextProps.details.modules,
        logoImageUrl: nextProps.details.logo_image_url,
        backgroundImageUrl: nextProps.details.background_image_url,
        timezone: nextProps.details.timezone
      });
    }
  }

  render() {
    return (
      <div styleName="container">
        <div className="clearfix">
          <div className="input-wrapper">
            <label styleName="label">Event Name</label>
            <input
              styleName="input"
              type="text"
              value={this.state.name}
              placeholder="Name of your event"
              name="name"
              onChange={this.handleChange}
            />
          </div>
          <div className="input-wrapper">
            <label styleName="label">Event Logo</label>
            <div
              role="button"
              tabIndex="0"
              styleName={
                this.state.logoImageUrl ? "logoContainer" : "logoContainerEmpty"
              }
              style={
                this.state.logoImageUrl
                  ? { backgroundImage: `url(${this.state.logoImageUrl})` }
                  : null
              }
              onClick={this.selectLogoImageUrl}
            >
              <div styleName="containerOverlay">
                <span>Upload logo</span>&nbsp;
                <i className="material-icons">file_upload</i>
              </div>
            </div>
          </div>
          <div className="input-wrapper">
            <label styleName="label">Event Background Image</label>
            <div
              role="button"
              tabIndex="0"
              styleName={
                this.state.backgroundImageUrl
                  ? "backgroundContainer"
                  : "backgroundContainerEmpty"
              }
              style={
                this.state.backgroundImageUrl
                  ? { backgroundImage: `url(${this.state.backgroundImageUrl})` }
                  : null
              }
              onClick={this.selectBackgroundImageUrl}
            >
              <div styleName="containerOverlay">
                <span>Upload background</span>&nbsp;
                <i className="material-icons">file_upload</i>
              </div>
            </div>
          </div>

          <div
            className="input-wrapper"
            style={{
              maxWidth: "440px"
            }}
          >
            <label styleName="label">Event Timezone</label>
            <select
              className="styled"
              name="timezone"
              value={this.state.timezone}
              onChange={this.handleChange}
            >
              {ALL_TIMEZONES.map(({ value, label }) => (
                <option value={value}>{label}</option>
              ))}
            </select>
          </div>

          {this.props.location.query.admin ? (
            <div>
              <label styleName="label">Tools</label>

              <div styleName="module-wrapper">
                <input
                  type="checkbox"
                  styleName="checkbox"
                  id="schedule"
                  checked={this.isChecked("schedule")}
                  onClick={() => this.toggleModule("schedule")}
                />
                <label styleName="checkbox-label" htmlFor="schedule">
                  Schedule
                </label>
              </div>
              <div styleName="module-wrapper">
                <input
                  type="checkbox"
                  styleName="checkbox"
                  id="orders"
                  checked={this.isChecked("orders")}
                  onClick={() => this.toggleModule("orders")}
                />
                <label styleName="checkbox-label" htmlFor="orders">
                  Orders
                </label>
              </div>
              <div styleName="module-wrapper">
                <input
                  type="checkbox"
                  styleName="checkbox"
                  id="inventory"
                  checked={this.isChecked("inventory")}
                  onClick={() => this.toggleModule("inventory")}
                />
                <label styleName="checkbox-label" htmlFor="inventory">
                  Inventory
                </label>
              </div>
              <div styleName="module-wrapper">
                <input
                  type="checkbox"
                  styleName="checkbox"
                  id="contacts"
                  checked={this.isChecked("contacts")}
                  onClick={() => this.toggleModule("contacts")}
                />
                <label styleName="checkbox-label" htmlFor="contacts">
                  People
                </label>
              </div>
              <div styleName="module-wrapper">
                <input
                  type="checkbox"
                  styleName="checkbox"
                  id="forms"
                  checked={this.isChecked("forms")}
                  onChange={() => this.toggleModule("forms")}
                />
                <label styleName="checkbox-label" htmlFor="forms">
                  Forms
                </label>
              </div>
              <div styleName="module-wrapper">
                <input
                  type="checkbox"
                  styleName="checkbox"
                  id="formsv3"
                  checked={this.isChecked("formsv3")}
                  onChange={() => this.toggleModule("formsv3")}
                />
                <label styleName="checkbox-label" htmlFor="formsv3">
                  Forms v3
                </label>
              </div>
              <div styleName="module-wrapper">
                <input
                  type="checkbox"
                  styleName="checkbox"
                  id="catering"
                  checked={this.isChecked("catering")}
                  onChange={() => this.toggleModule("catering")}
                />
                <label styleName="checkbox-label" htmlFor="catering">
                  Catering
                </label>
              </div>
              <div styleName="module-wrapper">
                <input
                  type="checkbox"
                  styleName="checkbox"
                  id="credentials"
                  checked={this.isChecked("credentials")}
                  onChange={() => this.toggleModule("credentials")}
                />
                <label styleName="checkbox-label" htmlFor="credentials">
                  Credentials
                </label>
              </div>
              <div styleName="module-wrapper">
                <input
                  type="checkbox"
                  styleName="checkbox"
                  id="portal"
                  checked={this.isChecked("portal")}
                  onChange={() => this.toggleModule("portal")}
                />
                <label styleName="checkbox-label" htmlFor="portal">
                  Portal
                </label>
              </div>
              <div styleName="module-wrapper">
                <input
                  type="checkbox"
                  styleName="checkbox"
                  id="accounts"
                  checked={this.isChecked("accounts")}
                  onChange={() => this.toggleModule("accounts")}
                />
                <label styleName="checkbox-label" htmlFor="accounts">
                  Groups
                </label>
              </div>
              <div styleName="module-wrapper">
                <input
                  type="checkbox"
                  styleName="checkbox"
                  id="files"
                  checked={this.isChecked("files")}
                  onChange={() => this.toggleModule("files")}
                />
                <label styleName="checkbox-label" htmlFor="files">
                  Files
                </label>
              </div>
              <div styleName="module-wrapper">
                <input
                  type="checkbox"
                  styleName="checkbox"
                  id="document_requests"
                  checked={this.isChecked("document_requests")}
                  onChange={() => this.toggleModule("document_requests")}
                />
                <label styleName="checkbox-label" htmlFor="document_requests">
                  File Requests
                </label>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <a styleName="button" onClick={this.validateData}>
          Save Changes
        </a>
      </div>
    );
  }
}

EventDetails.propTypes = {
  location: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  getEvent: PropTypes.func.isRequired,
  getEvents: PropTypes.func.isRequired
};

export default EventDetails;
