import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import listensToClickOutside from "react-onclickoutside";

@listensToClickOutside
@CSSModules(css)
class EditName extends Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.state = { name: this.props.name };
  }

  componentDidMount() {
    // @NOTE: Need to delay selecting here in order not to prematurely
    // trigger onBlur on input
    setTimeout(() => {
      if (this.input.current) {
        this.input.current.focus();
      }
    }, 300);
  }

  handleClickOutside(e) {
    e.stopPropagation();
    this.save();
  }

  handleKeyDown = e => {
    if (e.keyCode === 27) {
      this.props.cancel();
    } else if (e.keyCode === 13) {
      this.save();
    }
  };

  handleNameChange = e => this.setState({ name: e.target.value });

  save = () =>
    this.state.name.length
      ? this.props.save(this.state.name)
      : this.props.cancel();

  onFocus = event => event.target.select();

  render() {
    return (
      <input
        ref={this.input}
        type="text"
        value={this.state.name}
        onChange={this.handleNameChange}
        onFocus={this.onFocus}
        onKeyDown={this.handleKeyDown}
        styleName="input"
        maxLength={255}
        style={{
          width: 140,
          borderRadius: 3,
          padding: 6,
          background: "white",
          fontSize: "14px"
        }}
      />
    );
  }
}

export default EditName;
