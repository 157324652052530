export const NAMESPACE = "UINotificaiton";

export const FILTER = {
  ALL: "all",
  BY_EVENT: "by event"
};

export const FILTER_BY_TYPE = {
  "form-new-submission": "Forms",
  "new-message": "Message",
  "new-record-message": "Record",
  "new-record-note": "Note",
  "event-invite": "Invite",
  task: "Tasks"
};

export const ORDERED_TIMEFRAMES = [
  { id: "today", name: "Today" },
  { id: "past_7_days", name: "Past 7 days" },
  { id: "this_month", name: "This month" },
  { id: "older", name: "Older" }
];

export const TASK_ACTIONS = {
  FORM_NEW_SUBMISSION: "form-new-submission",
  NEW_MESSAGE: "new-message",
  NEW_RECORD_MESSAGE: "new-record-message",
  NEW_RECORD_NOTE: "new-record-note",
  ORG_INVITE: "org-invite",
  EVENT_INVITE: "event-invite",
  TASK: "assigned-task"
};
