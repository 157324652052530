import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import External from "./ExternalController";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { getPeople } from "redux/modules/portal/people/actions";
import { addRelationship } from "redux/modules/accounts/contactRelationship/actions";
import { addRecord } from "redux/modules/modules/records/actions";
import { removePerson } from "redux/modules/portal/person/actions";
import { getSettings } from "redux/modules/portal/settings/actions";

import { people, isFetching } from "redux/modules/portal/people/selectors";
import { recordTypes } from "redux/modules/modules/recordTypes/selectors";
import { eventDetails } from "redux/modules/event/selectors";

function mapStateToProps(state, props) {
  const details = eventDetails(state);
  const peopleToPass = people(state);
  return {
    eventDetails: {
      id: details.id,
      uuid: details.uuid,
      ...resolveEditorProps({ type: "event-days" }, details)
    },
    accountId: props.params.recordId,
    people: peopleToPass,
    isFetching: isFetching(state),
    types: recordTypes(state, STANDARD_MODULE_IDS.contacts.id),
    allPeople: peopleToPass.reduce((a, b) => {
      return [...a, ...b.contacts];
    }, [])
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSnackbar,
      showModal,
      hideModal,
      addRecord,
      getPeople,
      addRelationship,
      removePerson,
      getSettings
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(External);
