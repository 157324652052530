import React from "react";
import { Div, Text2, TinyToggle, Text1, EditIcon } from "components/Base";

const FieldItem = ({
  name,
  type,
  id,
  selected,
  required,
  toggleSelectedModuleField,
  toggleRequiredModuleField,
  editItem
}) => (
  <Div
    bra={1}
    width={1}
    bg="white"
    ba={1}
    bc="neutral2"
    shadow={1}
    px={4}
    display="row.space-between.center"
    height={50}
    mb={1}
    style={{ flexShrink: 0 }}
    className="hoverContainer"
    key={id}
  >
    <Div display="row.flex-start.center">
      <Div width={50}>
        <TinyToggle active={selected} onClick={toggleSelectedModuleField} />
      </Div>
      <Div display="column">
        <Div display="row">
          <Text2 bold>{name}</Text2>
        </Div>
        <Text1 color="neutral5">{type}</Text1>
      </Div>
    </Div>
    <Div display="row.flex-start.center">
      <Div className="showOnHover">
        <EditIcon
          size={24}
          mr={2}
          onClick={editItem}
          color={{ default: "neutral4", hover: "primary5" }}
        />
      </Div>

      <TinyToggle active={required} onClick={toggleRequiredModuleField} />
    </Div>
  </Div>
);

export default FieldItem;
