import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import External from "./External";
import { withRouter } from "react-router";
import * as R from "ramda";

import { getItemBlock } from "redux/modules/items/item-block/actions";
import { getItemTypesByEvent } from "redux/modules/items/types/actions";
import {
  addItemBlock,
  updateItemBlock,
  deleteItemBlock
} from "redux/modules/items/item-blocks/actions";

import { eventDetails } from "redux/modules/event/selectors";
import { itemBlock } from "redux/modules/items/item-block/selectors";
import { types } from "redux/modules/items/types/selectors";

function mapStateToProps(state, props) {
  const typesToPass = types(state);
  return {
    mode: props.itemBlockId ? "update" : "add",
    itemBlock: itemBlock(state, props.itemBlockId),
    types: typesToPass,
    variants: R.compose(
      R.flatten,
      R.map(R.prop("variants")),
      R.flatten,
      R.map(R.prop("items")),
      R.flatten,
      R.map(R.prop("groups"))
    )(typesToPass),
    eventDetails: eventDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getItemTypesByEvent,
      addItemBlock,
      getItemBlock,
      updateItemBlock,
      deleteItemBlock
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(External)
);
