import React, { useEffect, useRef, useState } from "react";
import {
  any,
  assoc,
  compose,
  filter,
  groupBy,
  isEmpty,
  map,
  path,
  pipe,
  prop,
  sortBy,
  uniq,
  uniqBy,
  values as rValues
} from "ramda";
import eventDaysString from "utils/value-types/to-string/event-days";
import View from "./View";
import {
  enforceVariantLimitsOnAssigments,
  formatAssignmentsFromProps,
  groupedAssignmentsFromProps,
  initials,
  mergeAssignments,
  resolveName,
  searchWithFuse
} from "./utils";
import { datesInRanges } from "./Popovers/Credentials/utils";
import Fuse from "fuse.js";
import PersonModal from "./Modals/PersonModal";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { LABELS, MEAL_TYPE_ID } from "utils/item-types";
import { hashStrPickEl } from "utils/General";
import { getNamedColors } from "components/Base";
import formatVariantTitle from "components/Event/Credentials/Modals/ViewOrder/utils/format-variant-title";
import formCloseDateHasExpired from "../../Event/FormsV2/Utils/form-close-date-has-expired";

const eventColorSet = getNamedColors(
  ["purple", "blue", "teal", "yellow", "orange", "red"],
  [6, 8]
);
const colorBuilder = hashStrPickEl(eventColorSet);

const GroupManager = props => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isRemovingPerson, setIsRemovingPerson] = useState(false);

  const timerRef = useRef(undefined);
  let peopleSearch;
  const {
    showModal,
    showSnackbar,
    group,
    event,
    user,
    resources,
    people,
    params,
    settings,
    getGroupPeople,
    fetchGroupResources,
    getDetails,
    addPerson,
    updatePerson,
    removePerson
  } = props;

  const fetchPeople = () => {
    getGroupPeople(params);
  };
  const fetchResources = () => {
    fetchGroupResources(params);
  };
  const fetchDetails = () => {
    getDetails(params);
  };
  const fetchItems = () => {
    fetchPeople();
    fetchResources();
  };

  useEffect(() => {
    if (window.Intercom) {
      window.Intercom("update", {
        hide_default_launcher: true
      });
    }
    fetchDetails();
    fetchItems();
    timerRef.current = setInterval(() => fetchItems(), 60 * 1000);

    return () => {
      timerRef.current = null;
    };
  }, []);

  useEffect(() => {
    peopleSearch = new Fuse(props, {
      threshold: 0.3,
      keys: ["contact.fname", "contact.lname", "contact.email"],
      shouldSort: true
    });
  });

  const handleSearchChange = searchTerm => setSearchTerm(searchTerm);
  const handleAddPerson = async ({ contact, assignments, custom_values }) => {
    await addPerson({
      ...params,
      values: { contact, assignments, custom_values }
    });
    showSnackbar({
      message: "Contact added"
    });
    fetchPeople();
    return fetchResources();
  };
  const handleUpdatePerson = async ({
    contact,
    id,
    assignments,
    shouldUpdateContactValues,
    custom_values
  }) => {
    const succeeded = await updatePerson({
      ...params,
      values: {
        contact,
        id,
        assignments: enforceVariantLimitsOnAssigments({
          assignments,
          variantLimits: path(["settings", "settings", "variant_limits"], props)
        }),
        shouldUpdateContactValues:
          typeof shouldUpdateContactValues === "undefined",
        custom_values
      }
    });
    fetchPeople();
    if (succeeded) {
      showSnackbar({
        message: "Contact updated"
      });
    }
    return fetchResources();
  };

  const showPersonModal = (id, defaultValues) => {
    showModal({
      content: (
        <PersonModal
          id={id}
          onSave={id ? handleUpdatePerson : handleAddPerson}
          defaultValues={defaultValues}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  const handleRemovePerson = async memberId => {
    setIsRemovingPerson(true);
    await removePerson({ ...params, memberId });
    setIsRemovingPerson(false);
    showSnackbar({
      message: "Contact removed"
    });
    fetchPeople();
    return fetchResources();
  };

  const handleCopyPerson = assignTo => typeId => copyFrom => {
    let assignments = people.find(item => prop("id", item) === copyFrom)
      .assignments;
    const existingAssignments = people.find(
      item => prop("id", item) === assignTo
    ).assignments;
    if (typeId) {
      assignments = assignments.filter(
        assignment => path(["item", "type", "id"], assignment) === typeId
      );
    }
    return this.handleAssignmentChange(assignTo)({
      typeId,
      values: [
        ...formatAssignmentsFromProps(
          groupedAssignmentsFromProps(existingAssignments)
        ),
        ...formatAssignmentsFromProps(groupedAssignmentsFromProps(assignments))
      ]
    });
  };

  const handleAssignmentChange = id => ({ typeId, values: valuesList }) => {
    const member = people.find(person => prop("id", person) === id);
    return handleUpdatePerson({
      id,
      shouldUpdateContactValues: false,
      assignments: mergeAssignments([
        ...formatAssignmentsFromProps(
          filter(
            value => path([0, "item", "type", "id"], value) !== typeId,
            groupedAssignmentsFromProps(prop("assignments", member))
          )
        ),
        ...valuesList.filter(value => prop("quantity", value) > 0)
      ])
    });
  };

  const handleRemoveAssignment = id => assignmentId => {
    const member = people.find(p => p.id === id);

    return handleUpdatePerson({
      id,
      shouldUpdateContactValues: false,
      assignments: map(
        assignment => ({
          id: path([0, "id"], assignment),
          quantity:
            path([0, "id"], assignment) === assignmentId
              ? Math.max(0, prop("length", assignment) - 1)
              : prop("length", assignment),
          name: path([0, "name"], assignment)
        }),
        rValues(
          groupBy(
            assignment => prop("id", assignment),
            prop("assignments", member)
          )
        )
      )
    });
  };

  const handleCopyFrom = id => {
    const copyValues = people.find(p => p.id === id);
    const placeholder = {
      contact: {
        days_on_site: copyValues.contact.days_on_site
      },
      assignments: copyValues.assignments
    };
    showPersonModal(undefined, placeholder);
  };

  const groupLevelSettings = prop("settings", settings);
  const guestListEnabled = path(["group", "guest_list_enabled"], settings);

  const isLocked = formCloseDateHasExpired(
    prop("close_date", groupLevelSettings)
  );

  const formatAssignedItem = ({ id, name, is_issued, onRemove }) => ({
    id,
    sublabel: name,
    issued: is_issued,
    onRemove: () => onRemove(id)
  });

  const formatAssignedItems = val => {
    const item = path([0, "item"], val);
    return {
      quantity: val.length,
      title: val.length > 1 ? prop("name", item) : val[0].name,
      color: prop("background_color", item),
      id: prop("id", item),
      items: map(formatAssignedItem, val)
    };
  };

  const formatPerson = ({ id, contact, assignments }) => ({
    id,
    name: resolveName(contact),
    email: contact.email,
    daysOnSite: eventDaysString(datesInRanges(contact.days_on_site), group),
    initials: initials(resolveName(contact)),
    color: colorBuilder(resolveName(contact)),
    onCopy: handleCopyPerson(id),
    onEdit: () => showPersonModal(id),
    isRemoving: isRemovingPerson,
    onRemove: any(prop("is_issued"), assignments)
      ? undefined
      : () => handleRemovePerson(id),
    onDuplicate: () => handleCopyFrom(id),
    onChange: handleAssignmentChange(id),
    rawAssignments: assignments,
    rawMember: { id, contact, assignments },
    assignedItems: compose(
      map(formatAssignedItems),
      rValues,
      groupBy(path(["item", "id"])),
      map(assoc("onRemove", handleRemoveAssignment(id)))
    )(assignments)
  });

  const resourceGroups = pipe(
    map(r => r.group),
    uniqBy(r => r.id)
  )(resources);
  const resourceTypes = uniq(map(r => r.type_id, resources));
  const availableItemList = pipe(
    map(typeId => ({
      label: `Available ${LABELS[typeId].label}`,
      icon: LABELS[typeId].icon,
      color: LABELS[typeId].color,
      items: pipe(
        map(g => ({
          id: g.id,
          label: g.name,
          list: pipe(
            filter(r => r.group.id === g.id && r.type_id === typeId),
            map(r => ({
              id: r.id,
              order: r.item.order,
              color: r.background_color,
              label: r.name,
              sortBy: formatVariantTitle(r),
              remaining: r.count_of_available - r.count_of_assigned,
              total: r.count_of_available
            })),
            sortBy(r => {
              if (typeId === MEAL_TYPE_ID) {
                return new Date(r.sortBy);
              }
              return r.name;
            }),
            sortBy(prop("order"))
          )(resources)
        })),
        filter(s => !isEmpty(s.list))
      )(resourceGroups)
    }))
  )(resourceTypes);

  const viewProps = {
    title: groupLevelSettings.title,
    message: groupLevelSettings.message,
    isEnabled: guestListEnabled,
    isLocked: isLocked,
    onCreateFrom: handleCopyFrom,
    portalTitle: event.name,
    portalPath: `/portal-switch/${params.eventSlug}/${params.eventUUID}`,
    portalLoginPath: `/portal-login/${params.eventSlug}/${params.eventUUID}`,
    adminPath: `/event-light/${event.id}/dashboard`,
    accountName: group.name,
    backgroundUrl: event.background_image_url,
    onAddPerson: () => showPersonModal(),
    onSearchChange: handleSearchChange,
    searchTerm: searchTerm,
    resourceTypeIds: resourceTypes,
    user: {
      name: resolveName(user),
      isAdmin: user && user.credentials && user.credentials.userId
    },
    list: searchWithFuse(
      searchTerm,
      peopleSearch,
      people.map(formatPerson),
      "id"
    ),
    availableItemList
  };

  return <View {...viewProps} />;
};

export default GroupManager;
