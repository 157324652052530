import { createSelector } from "reselect";
import * as R from "ramda";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { getters } from "./model";
import { FIELDS } from "./constants";

export const getUsername = createSelector(
  getters.user,
  getters.loading,
  (user, loading) => {
    if (loading) {
      return "";
    }
    return `${user.user_fname} ${user.user_lname}`;
  }
);

export const getUserId = createSelector(
  getters.user,
  R.prop("user_id")
);

export const getEventName = createSelector(
  getEventDetails,
  getters.loading,
  (eventDetails, loading) => {
    if (loading) {
      return "";
    }
    return eventDetails.name;
  }
);

export const getPermissionRole = createSelector(
  getters.user,
  R.prop(FIELDS.PERMISSION_ROLE)
);

export const getApiToken = createSelector(
  getters.user,
  R.prop(FIELDS.API_TOKEN)
);

export const getEnableApi = createSelector(
  getters.user,
  R.prop(FIELDS.ENABLE_API)
);
