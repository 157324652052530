/* eslint-disable react/prop-types */

import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import { Div } from "components/Base";

import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { eventDetails as getEventDetails } from "redux/modules/portal/selectors";

import LinkCard from "./LinkCard";
import { getters } from "../model";
import { getLinks } from "../selectors";

const decorate = R.compose(
  connect(state => ({
    enableCommunity: getters.enable_community_hub(state),
    title: getters.community_hub_title(state),
    links: getLinks(state),
    eventDetails: getEventDetails(state)
  })),
  CSSModules(css)
);

const Community = ({ links, eventDetails }) => {
  if (!links || !links.length) {
    return null;
  }

  return (
    <Div
      bg="neutral1"
      style={{
        borderRadius: 10,
        padding: "10px 20px",
        marginBottom: "40px"
      }}
    >
      <Div display="row.flex-start.center" mb={4}>
        <Div
          className={css.tab}
          fw={4}
          style={{
            fontSize: "30px"
          }}
        >
          Links
        </Div>
      </Div>
      <Div>
        {links.map(link => (
          <LinkCard link={link} eventDetails={eventDetails} />
        ))}
      </Div>
    </Div>
  );
};

export default decorate(Community);
