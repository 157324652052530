import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE } from "./constants";

const iniState = {
  buttonLabel: "Add to Cart",
  item: {
    variants: []
  },
  limit: undefined,
  remaining: undefined,
  selectedVariants: {},
  selectedVariantPrices: {},
  selectedVariantQuestions: {},
  isValid: true,
  disabled: false,
  loading: false,
  hasSingleVariant: false,
  disableChangingPrice: false,
  color: "",
  imageUrl: null,
  showInternalQuestions: true
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    init: R.identity,
    onSave: R.identity,
    updateQuestionValue: (
      state,
      { payload: { variantId, question, value } }
    ) => {
      const existingValues = R.propOr({}, variantId)(
        state.selectedVariantQuestions
      );

      return {
        selectedVariantQuestions: {
          ...state.selectedVariantQuestions,
          [variantId]: {
            ...existingValues,
            [question.id]: value
          }
        }
      };
    },
    updateSingleVariantQuantity: (state, { payload }) => {
      return {
        selectedVariants: {
          ...state.selectedVariants,
          [state.item.variants[0].id]: payload
        }
      };
    },
    updateSingleVariantPrice: (state, { payload }) => {
      return {
        selectedVariantPrices: {
          ...state.selectedVariantPrices,
          [state.item.variants[0].id]: payload
        }
      };
    },
    updateVariantQuantity: (state, { payload }) => {
      return {
        selectedVariants: {
          ...state.selectedVariants,
          [payload.variantId]: payload.quantity
        }
      };
    },
    updateVariantPrice: (state, { payload }) => {
      return {
        selectedVariantPrices: {
          ...state.selectedVariantPrices,
          [payload.variantId]: payload.priceId
        }
      };
    },
    setInitialData: (
      state,
      {
        payload: {
          item,
          hasSingleVariant,
          selectedPriceId,
          disableChangingPrice,
          limit,
          remaining,
          quantity,
          questions = {},
          color,
          buttonLabel,
          imageUrl,
          showInternalQuestions
        }
      }
    ) => ({
      selectedVariantQuestions: R.isEmpty(questions)
        ? {}
        : { [item.variants[0].id]: questions },
      item,
      hasSingleVariant,
      selectedVariants: hasSingleVariant
        ? {
            [item.variants[0].id]: quantity || 1
          }
        : {},
      selectedVariantPrices: R.reduce((map, variant) => {
        map[variant.id] = R.compose(
          R.prop("id"),
          R.find(p =>
            selectedPriceId
              ? R.propEq("id", selectedPriceId)(p)
              : R.propEq("is_default", true)(p)
          ),
          R.prop("prices")
        )(variant);

        return map;
      }, {})(item.variants),
      disableChangingPrice:
        typeof disableChangingPrice === undefined
          ? state.disableChangingPrice
          : disableChangingPrice,
      limit: typeof limit === undefined ? state.limit : limit,
      remaining: typeof remaining === undefined ? state.remaining : remaining,
      color,
      buttonLabel: buttonLabel || state.buttonLabel,
      imageUrl,
      showInternalQuestions
    })
  }
});

export default handlers;
