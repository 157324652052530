import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "Portal/PortalForm/model";
import { getIsClosed, getBlockSubmit } from "Portal/PortalForm/selectors";
import {
  Div,
  ReplyIcon,
  MediumFilledButton,
  BigFilledButton,
  BigFilledInput,
  Text0,
  Text4,
  LoadingIcon,
  LockIcon
} from "components/Base";
import SubmissionsCard from "./SubmissionsCard";
import Description from "Portal/Description";

const decorate = connect(
  state => ({
    isClosed: getIsClosed(state),
    data: getters.data(state),
    filter: getters.filter(state),
    addLoading: getters.addLoading(state),
    blockSubmit: getBlockSubmit(state)
  }),
  {
    setSubmissionsFilter: actions.setSubmissionsFilter,
    addSubmission: actions.addSubmission,
    goToForms: actions.goToForms
  }
);

const Table = ({
  isClosed,
  data,
  filter,
  setSubmissionsFilter,
  addLoading,
  addSubmission,
  goToForms,
  blockSubmit
}) => (
  <Div display="column.flex-start.flex-start">
    <Div display="row.space-between.center" width={1}>
      <MediumFilledButton
        LeftIcon={ReplyIcon}
        style={{ borderRadius: 20 }}
        bg="neutral1"
        color="neutral7"
        onClick={goToForms}
      >
        All forms
      </MediumFilledButton>
      <Div display="column.flex-start.flex-end" mt={3}>
        <Text0 color="neutral5" uppercase>
          {data.due_title}
        </Text0>
        <Text4 uppercase>{data.due_label}</Text4>
      </Div>
    </Div>

    <Div mt={4} mb={5}>
      <Description
        instanceId={data.id}
        title={data.name}
        description={data.description}
      />
    </Div>

    <Div bra={2} bg="neutral1" width={1} p={3}>
      {!data.submissions.length ? (
        <BigFilledButton
          style={{ borderRadius: 20 }}
          color={isClosed ? "gray7" : "white"}
          bg={isClosed ? "gray4" : "indigo7"}
          width={1}
          ml={2}
          disabled={isClosed || addLoading}
          onClick={addSubmission}
        >
          {isClosed ? (
            <Div display="row.center.center" width={1}>
              <LockIcon color="gray6" mr={2} />
              This form is has been closed
            </Div>
          ) : !addLoading ? (
            "Fill Out"
          ) : (
            <LoadingIcon />
          )}
        </BigFilledButton>
      ) : (
        <Div display="row.flex-start.center">
          <BigFilledInput
            flex={1}
            bg="neutral2"
            style={{ borderRadius: 20 }}
            placeholder="Search requests..."
            value={filter}
            onChange={value => setSubmissionsFilter(value)}
            color="neutral5"
          />
          {isClosed || blockSubmit ? null : (
            <BigFilledButton
              style={{ borderRadius: 20 }}
              bg="indigo7"
              width={92}
              ml={2}
              disabled={addLoading}
              onClick={addSubmission}
            >
              {!addLoading ? "Add" : <LoadingIcon />}
            </BigFilledButton>
          )}
        </Div>
      )}
      {data.submissions.length ? <SubmissionsCard /> : null}
    </Div>
  </Div>
);

export default decorate(Table);
