import * as R from "ramda";
import PropTypes from "prop-types";
import React, { Component, cloneElement } from "react";
import { connect } from "react-redux";
import { get } from "lodash";
import { withRouter } from "react-router";

import { actions, getters } from "Event/Home";

import { userAccessLevels } from "redux/modules/user/access-levels/selectors";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import Header from "./Header";

import { Div } from "components/Base";

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => {
      const accessLevels = userAccessLevels(state);
      const orgEvents = get(accessLevels, "organizations", []).reduce(
        (a, b) => {
          return [...a, ...b.events];
        },
        []
      );
      const eventFromParentOrg = orgEvents.find(
        e => parseInt(e.id, 10) === parseInt(props.params.eventId, 10)
      );

      return {
        loading: getters.isFetching(state),
        activity: getters.activities(state),
        parentOrgWithAccess: eventFromParentOrg
          ? accessLevels.organizations.find(
              o => o.id === eventFromParentOrg.org_id
            )
          : null
      };
    },
    {
      fetchActivities: actions.fetchActivities,
      getEventRecordTypes: actions.getEventRecordTypes
    }
  )
);

@CSSModules(css)
class EventHome extends Component {
  goToDashboard = () => {
    this.props.router.push({
      pathname: `/home`
    });
  };

  goToEventHome = () => {
    this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/home`
    });
  };

  goToOrg = () => {
    window.location = `/organization/${this.props.parentOrgWithAccess.id}/home`;
  };

  isViewingMyApprovals = () =>
    R.contains("/my-approvals")(window.location.href);

  goToAccountSettings = groupId => {
    window.location = `/event/${this.props.params.eventId}/settings/module/${STANDARD_MODULE_IDS.accounts.id}/types/${groupId}/portal`;
  };

  goToContactSettings = groupId => {
    window.location = `/event/${this.props.params.eventId}/settings/module/${STANDARD_MODULE_IDS.contacts.id}/types/${groupId}`;
  };

  render() {
    const { parentOrgWithAccess } = this.props;

    const isViewingMyApprovals = this.isViewingMyApprovals();
    const titleAction = isViewingMyApprovals
      ? this.goToEventHome
      : parentOrgWithAccess
      ? this.goToOrg
      : this.goToDashboard;
    const titleActionLabel = isViewingMyApprovals
      ? "Event Home"
      : parentOrgWithAccess
      ? parentOrgWithAccess.name
      : "My Home";

    return (
      <div styleName="container">
        <div styleName="headerBar" />
        <Div width={880} display="column" py={5}>
          <Header
            {...{
              ...this.props,
              titleAction,
              titleActionLabel
            }}
          />
          {cloneElement(this.props.children, { ...this.props })}
        </Div>
      </div>
    );
  }
}

EventHome.propTypes = {
  params: PropTypes.object.isRequired,
  details: PropTypes.object.isRequired,
  activity: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchActivities: PropTypes.func.isRequired
};

export default decorate(EventHome);
