import React from "react";
import { Div } from "components/Base";
import * as R from "ramda";

const Label = ({ field }) => (
  <>
    <Div
      display="row.flex-start"
      color={field.isValid ? "black" : "danger7"}
      fw={3}
      mb={1}
      width={1}
    >
      {field.name}
      {field.required ? (
        <Div color={field.isValid ? "black" : "danger7"} fw={3} ml={1}>
          *
        </Div>
      ) : null}
    </Div>
    {R.path(["settings", "description", "length"], field) ? (
      <Div
        fs={2}
        color="gray6"
        mb={1}
        width={1}
        style={{
          whiteSpace: "pre-line"
        }}
      >
        {field.settings.description}
      </Div>
    ) : null}
  </>
);

export default Label;
