import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Tabs from "components/Global/CRM/Tabs";
import Tab from "components/Global/CRM/Tabs/Tab";
import Needs from "./Needs";
import { CATALOG_ITEMS } from "utils/standard-module-field-ids";
import { catalogItems } from "@lennd/value-types/src/constants/standard-modules";
import { get } from "lodash";
import getValue from "utils/value-types/get-value/file";

@CSSModules(css)
class ItemSummaryModal extends Component {
  state = { tab: "needs" };

  componentDidMount() {
    this.fetchRecords();
  }

  fetchRecords = () =>
    this.props.getRecords({
      moduleId: catalogItems.id,
      options: { eventId: this.props.params.eventId }
    });

  activateTab = tab => {
    this.setState({ tab });
  };

  renderBody = () => {
    switch (this.state.tab) {
      case "needs":
      default:
        return (
          <Needs
            catalogItemId={this.props.catalogItem.id}
            params={this.props.params}
            catalogItem={this.props.catalogItem}
          />
        );
    }
  };

  getRecordImage = () => {
    const files = getValue(
      get(this.props.record, ["values", CATALOG_ITEMS.IMAGE], {})
    );
    if (files.length) {
      return files[0].url;
    }
    return undefined;
  };

  render() {
    const { hideModal, catalogItem } = this.props;
    const itemImage = this.getRecordImage();
    return (
      <StyleWrapper
        bodyStyles={{ padding: 0, position: "relative" }}
        heading="Modal"
        hideHeader
        hideModal={hideModal}
        width="90%"
      >
        <div styleName="header">
          <div styleName="main">
            <div styleName="title">{catalogItem.value}</div>
            <Tabs>
              <Tab onActive={() => this.activateTab("needs")} label="Needs" />
            </Tabs>
          </div>
          {itemImage ? (
            <div
              styleName="imageWrapper"
              style={{ backgroundImage: `url('${itemImage}')` }}
            />
          ) : (
            ""
          )}
          <div styleName="close" onClick={hideModal}>
            <i className="material-icons">close</i>
          </div>
        </div>
        <div styleName="body">{this.renderBody()}</div>
      </StyleWrapper>
    );
  }
}

ItemSummaryModal.propTypes = {
  catalogItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  }).isRequired,
  hideModal: PropTypes.func.isRequired
};

export default ItemSummaryModal;
