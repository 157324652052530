import React from "react";
import * as R from "ramda";
import Toggle from "material-ui/Toggle";
import Tooltip from "components/Global/Tooltip";
import CardTypes from "./CardTypes";
import {
  CardGroup1,
  Cards,
  CollapsablePanelType1,
  Div,
  FontIcon,
  NullFontIcon,
  SearchIcon,
  RightIcon,
  DownIcon,
  BigOutlineButton,
  BigFilledButton,
  PopoverMenu,
  Text1,
  Text2,
  Text3,
  Type4Card,
  Type5Card,
  BigShadedInput
} from "components/Base";
import Loading from "components/Event/Settings/Module/Loading";
import css from "./cardStyles.scss";
import CSSModules from "react-css-modules";

const mapIndexed = R.addIndex(R.map);

const addS = thing => (thing === 1 ? "" : "s");

const AboutFieldCards = Cards(Type4Card, CardTypes.ABOUT_FIELD_CARD);
const RelatedModuleCards = Cards(Type4Card, CardTypes.RELATED_MODULE_CARD);

const LayoutCard = CSSModules(
  ({
    visible,
    starred,
    locked,
    name,
    typeName,
    typeIcon,
    isDragging,
    onClickVisible,
    onClickStar,
    onClickName,
    onEdit,
    onDelete
  }) => {
    const iconProps = {
      pl: 2,
      fs: 4
    };
    const nameWithTypeIcon = (
      <Div display={"row.flex-start.center"}>
        <FontIcon fs={2} mr={1} color={"inherit"}>
          {typeIcon}
        </FontIcon>
        {name}
      </Div>
    );
    return (
      <Div
        m={2}
        px={2}
        py={3}
        bg={"white"}
        shadow={{ default: 1, hover: 2 }}
        display={"row.flex-start.center"}
        style={{ opacity: isDragging ? 0 : 1 }}
        styleName="parentContainer"
      >
        {/*
          // @NOTE: Save for future impl.
          <DragIcon size={20} />
        */}

        {starred ? (
          <Tooltip tooltip={"Remove from Starred Fields"}>
            <FontIcon
              {...iconProps}
              color={{ default: "yellow4", hover: "neutral4" }}
              onClick={onClickStar}
            >
              star
            </FontIcon>
          </Tooltip>
        ) : (
          <Tooltip tooltip={"Add to Starred Fields"}>
            <FontIcon
              {...iconProps}
              color={{ default: "neutral4", hover: "yellow4" }}
              onClick={onClickStar}
              styleName="childContainer"
            >
              star_border
            </FontIcon>
          </Tooltip>
        )}

        {visible ? (
          <Tooltip tooltip={"Hide in this layout"}>
            <FontIcon
              {...iconProps}
              color={{ default: "neutral4", hover: "neutral6" }}
              onClick={onClickVisible}
              styleName="childContainer"
            >
              visibility_off
            </FontIcon>
          </Tooltip>
        ) : (
          <Tooltip tooltip={"Show in this layout"}>
            <FontIcon
              {...iconProps}
              color={{ default: "neutral4", hover: "neutral6" }}
              onClick={onClickVisible}
              styleName="childContainer"
            >
              visibility
            </FontIcon>
          </Tooltip>
        )}
        {visible ? (
          <Text3 bold pl={3} flex={1} onClick={onClickName} primary>
            {nameWithTypeIcon}
          </Text3>
        ) : (
          <Text3 pl={3} flex={1} onClick={onClickName} color={"neutral5"}>
            {nameWithTypeIcon}
          </Text3>
        )}

        <Text1 color={"neutral5"}>
          {!visible ? `Hidden · ${typeName}` : `${typeName}`}
        </Text1>

        {locked ? (
          <Tooltip tooltip={"System field"}>
            <FontIcon color={"neutral4"} {...iconProps}>
              lock
            </FontIcon>
          </Tooltip>
        ) : (
          <NullFontIcon {...iconProps} />
        )}
        <PopoverMenu
          Label={({ onClick }) => (
            <FontIcon {...iconProps} onClick={onClick}>
              more_vert
            </FontIcon>
          )}
          menuItems={
            locked
              ? [
                  [visible ? "Hide" : "Show", onClickVisible],
                  [starred ? "Unstar" : "Star", onClickStar]
                ]
              : [
                  [visible ? "Hide" : "Show", onClickVisible],
                  [starred ? "Unstar" : "Star", onClickStar],
                  ["Edit", onEdit],
                  ["Delete", onDelete]
                ]
          }
        />
      </Div>
    );
  },
  css
);

const View = ({
  loading,
  onFieldsSearch,
  onCreateFieldGroup,
  onCreateField,
  fieldGroups,
  aboutFields,
  onAboutFieldsReorder,
  onRelatedModulesReorder,
  relatedModules
}) =>
  loading ? (
    <Loading />
  ) : (
    <Div display="row.flex-start.flex-start">
      <Div width={1 / 3} px={3} br={1} bc="neutral1" pt={6}>
        <CardGroup1
          heading="Starred Fields"
          icon="star"
          iconColor="yellow4"
          subtext={
            <Text2 color="neutral6">
              These are fields you want to see first across all records of this
              type.
            </Text2>
          }
        >
          {aboutFields.length ? (
            <Div bg="gray1" py={2} px={3} bra={1}>
              <AboutFieldCards
                cards={aboutFields.map(f => ({
                  ...f,
                  locked: false
                }))}
                onReorder={onAboutFieldsReorder}
              />
            </Div>
          ) : (
            <Text2 color="neutral6" bg="gray1" p={4} mt={1} bra={1}>
              There are no starred fields.
            </Text2>
          )}
        </CardGroup1>
        <Div pt={1}>&nbsp;</Div>
        <CardGroup1 heading="Related Modules" icon="link" iconColor="neutral6">
          {relatedModules.all.length ? (
            <Div bg="gray1" py={2} px={3} mt={2} bra={1}>
              <RelatedModuleCards
                cards={relatedModules.visible.map(m => ({
                  ...m,
                  key: `${m.id}_${m.fieldId}`,
                  icon: null
                  /*
                // @NOTE: Hiding icons for now because of reduced space
                icon: (
                  <FontIcon
                    bg={m.color || "neutral5"}
                    color="white"
                    pill
                    p={1}
                    ml={1}
                    fs={2}
                  >
                    {m.icon}
                  </FontIcon>
                )
                */
                }))}
                onReorder={onRelatedModulesReorder}
              />

              {relatedModules.hidden.length ? (
                <Div mt={4}>
                  <Text2>Hidden modules</Text2>
                  {relatedModules.hidden.map(
                    ({ id, name, fieldId, subname, onAdd }) => (
                      <Type5Card
                        key={`${id}_${fieldId}`}
                        name={name}
                        subname={subname}
                        icon={
                          <Tooltip tooltip="Show in this layout">
                            <FontIcon
                              fs={3}
                              onClick={onAdd}
                              color={{ default: "neutral4", hover: "neutral6" }}
                            >
                              visibility_off
                            </FontIcon>
                          </Tooltip>
                        }
                      />
                    )
                  )}
                </Div>
              ) : null}
            </Div>
          ) : (
            <Text3 color="neutral6" bg="gray1" p={4} mt={1} bra={1}>
              There are no related modules.
            </Text3>
          )}
        </CardGroup1>
      </Div>
      <Div width={2 / 3} px={6} pt={6}>
        <Div display="row.flex-start.center" pb={6}>
          <BigShadedInput
            continuous
            LeftIcon={SearchIcon}
            flex={1}
            placeholder="Search fields"
            onChange={onFieldsSearch}
          />
          <BigOutlineButton ml={1} onClick={onCreateFieldGroup}>
            Create field group
          </BigOutlineButton>
          <BigFilledButton bg="altB5" ml={1} onClick={onCreateField}>
            Create field
          </BigFilledButton>
        </Div>
        {mapIndexed((fieldGroup, i) => {
          const rowsData = fieldGroup.fields;
          const numFields = rowsData.length || 0;
          const subText = `${numFields} field${addS(numFields)}`;
          // @NOTE: For future impl.
          // const LayoutCards = Cards(LayoutCard, `${CardTypes.LAYOUT_CARD}_${fieldGroup.id}`);
          return (
            <CollapsablePanelType1
              key={i}
              collapsedDefault={i !== 0}
              headingText={fieldGroup.name}
              subText={subText}
              menuItems={
                fieldGroup.isDefault
                  ? null
                  : [
                      ["Edit", fieldGroup.onEdit],
                      ["Delete", fieldGroup.onDelete]
                    ]
              }
              onClickMore={fieldGroup.onMore}
            >
              {rowsData.map(r => (
                <LayoutCard key={r.id} {...r} />
              ))}
              {/*
              <LayoutCards onReorder={fieldGroup.onReorder} cards={rowsData} />
            */}
            </CollapsablePanelType1>
          );
        }, fieldGroups)}
      </Div>
    </Div>
  );

export default View;
