/* eslint-disable no-unused-vars */

import React from "react";
import * as R from "ramda";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import MoreInfoModal from "components/Event/ContactProfile/MoreInfoModal";

import { BaseController, withState } from "./BaseController";

import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

const CONTACTS_ID = STANDARD_MODULES.contacts.id;

function ContactProfileController(Child) {
  class Controller extends BaseController {
    showViewAllPropertiesModal = () => {
      this.props.showModal({
        content: (
          <MoreInfoModal
            name="Contact"
            contactId={this.props.params.recordId}
            updateField={this.updateField}
            readOnly={this.props.readOnly}
            goToManageFields={
              this.props.params.orgId
                ? undefined
                : this.props.canManageFields
                ? () => {
                    this.props.router.replace({
                      pathname: this.props.params.orgId
                        ? `/organization/${this.props.params.orgId}/settings/module/${CONTACTS_ID}/fields`
                        : `/event/${this.props.params.eventId}/settings/module/${CONTACTS_ID}/fields`
                    });
                  }
                : null
            }
          />
        ),
        wrapper: ModalWrapper
      });
    };
    render() {
      // remove local dependencies
      const {
        addNote,
        addRelationship,
        addValue,
        deleteNote,
        deleteRecord,
        deleteRelationship,
        getRecord,
        invalidateRecord,
        loading,
        logAction,
        record,
        recordName,
        email,
        updateContactNotifications,
        updateNote,
        updateRelationship,
        ...props
      } = this.props;

      if ((!record || !record.id) && !loading) return <div />;

      const methods = {
        // getFieldValue: this.getStandardFieldValue,
        isLoading: loading,
        addNote: this.addNote,
        deleteNote: this.deleteNote,
        editRelatedAccount: this.showEditRelatedAccountModal,
        onToggleLoginAccess: this.handleLoginAccess,
        fetchProfile: this.fetchProfile,
        getFieldValue: this.getFieldValue,
        isEnabledModule: this.isEnabledModule,
        onRemoveRelationship: this.removeRelationship,
        onRemoveFromEvent: this.handleRemoveFromEvent,
        showAddRelatedAccountModal: this.showAddRelatedAccountModal,
        showAddRelationshipModal: this.showAddRelationshipModal,
        showEditRelationshipModal: this.showEditRelationshipModal,
        showSendEmailModal: this.showSendEmailModal,
        showViewAllPropertiesModal: this.showViewAllPropertiesModal,
        showViewMoreModal: this.showViewMoreModal,
        showChangeRecordTypeModal: this.showChangeRecordTypeModal,
        showChangeAccountRecordTypeModal: this.showChangeAccountRecordTypeModal,
        showDeleteContactModal: this.showDeleteContactModal,
        updateRelatedContact: this.updateRelatedContact,
        removeRelatedContact: this.removeRelatedContact,
        runReport: this.runReport,
        updateField: this.updateField,
        updateNote: this.updateNote,
        goToView: this.goToView,
        showLoginDetailsModal: this.showLoginDetailsModal,
        getRecord: this.props.getRecord
      };

      const activeView = this.getActiveView();
      const isOrgProfile = Boolean(this.props.params.orgId);

      // explicitly add dependencies, rather than passing everything
      return (
        <Child
          {...{
            ...props,
            updateNotifications: updateContactNotifications,
            contact: record,
            name: recordName,
            email: email,
            activeView,
            isOrgProfile,
            ...methods
          }}
        />
      );
    }
  }
  return Controller;
}

export default R.compose(
  withState,
  ContactProfileController
);
