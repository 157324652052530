import React from "react";
import NotFound from "components/Event/FormsV2/NotFound";
import formCloseDateHasExpired from "components/Event/FormsV2/Utils/form-close-date-has-expired";
import PasswordField from "components/Global/Inputs/Password";

import { get } from "lodash";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

const View = CSSModules(
  ({
    portal,
    eventDetails,
    intakeDetails,
    form,
    isFetching,
    errorMessages,
    searching,
    processing,
    searchingUsers,
    userExists,
    isVirtualUser,
    handleSubmit,
    onEmailChange,
    onFirstNameChange,
    onLastNameChange,
    onPasswordChange,
    firstName,
    lastName,
    email,
    password,
    search,
    error
  }) => {
    if (errorMessages.length || (!isFetching && !portal.id)) {
      return <NotFound />;
    }

    return (
      <div styleName="wrapper">
        <div
          styleName="background"
          style={{
            backgroundImage: `url(${get(portal, "background_image_url")})`
          }}
        />
        <div styleName="container">
          <div styleName="bodyTop">
            <div styleName="logoContainer">
              <img styleName="logoImage" src={eventDetails.logo_image_url} />
            </div>
            <div styleName="bodyContent">
              <div styleName="bodyContentInner">
                <div styleName="details">
                  <div styleName="superText">Get started as:</div>
                  <div>{intakeDetails.name}</div>
                </div>
                <div styleName="form">
                  {form.is_locked ? (
                    <div styleName="lockedMessage">
                      <i
                        className="material-icons"
                        styleName="lockedMessageIcon"
                      >
                        lock
                      </i>
                      <div>This form is no longer accepting submissions</div>
                    </div>
                  ) : (
                    <form
                      styleName="formWrapper"
                      method="post"
                      onSubmit={handleSubmit}
                    >
                      <div styleName="fieldWrapper">
                        <div styleName="inputWrapper">
                          <div styleName="inputGroup">
                            <div styleName="inputLabel">Email</div>
                            <input
                              autoFocus
                              name="email"
                              onChange={onEmailChange}
                              placeholder="your_email_address@email.com"
                              required
                              styleName={"input"}
                              type="email"
                              value={email}
                              disabled={processing}
                            />
                            {searchingUsers ? (
                              <i
                                className="fa fa-circle-o-notch"
                                styleName="processingIcon"
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          {userExists === true || userExists === false ? (
                            <div styleName="inputGroup">
                              <div styleName="inputLabel">Password</div>
                              <PasswordField
                                name="password"
                                onChange={onPasswordChange}
                                placeholder={
                                  userExists === false || isVirtualUser === true
                                    ? "Create your password"
                                    : "Enter your password"
                                }
                                required
                                className={css.input}
                                value={password}
                                disabled={processing}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {userExists === false ? (
                            <div>
                              <div styleName="inputGroup">
                                <div styleName="inputLabel">First Name</div>
                                <input
                                  name="firstName"
                                  onChange={onFirstNameChange}
                                  required
                                  styleName="input"
                                  type="text"
                                  value={firstName}
                                  disabled={processing}
                                />
                              </div>
                              <div styleName="inputGroup">
                                <div styleName="inputLabel">Last Name</div>
                                <input
                                  name="lastName"
                                  onChange={onLastNameChange}
                                  required
                                  styleName="input"
                                  type="text"
                                  value={lastName}
                                  disabled={processing}
                                />
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {userExists === null ? (
                          <div styleName="nextStepButton" onClick={search}>
                            Next
                          </div>
                        ) : (
                          ""
                        )}
                        {userExists === true || userExists === false ? (
                          <div styleName="actions">
                            <div>
                              <button
                                type="submit"
                                styleName="button"
                                disabled={processing}
                              >
                                {processing ? (
                                  <div styleName="buttonInner">
                                    <i
                                      className="fa fa-circle-o-notch"
                                      styleName="buttonLoadingIcon"
                                    />
                                    {userExists === true &&
                                    isVirtualUser === false ? (
                                      <span>Logging in...</span>
                                    ) : (
                                      <span>Processing...</span>
                                    )}
                                  </div>
                                ) : (
                                  <div styleName="buttonInner">
                                    <span>Continue to form</span>
                                  </div>
                                )}
                              </button>
                            </div>
                            {userExists === true ? (
                              <div>
                                <a
                                  href={`/forgot?email=${email}`}
                                  styleName="actionForgotPassword"
                                  target="_blank"
                                >
                                  Forgot your password?
                                </a>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        {error ? <div styleName="error">{error}</div> : ""}
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          styleName="branding"
          href={`https://www.lennd.com/?source=intake&type_id=${
            intakeDetails.id
          }`}
          target="_blank"
        >
          <img
            src="https://d2dks4tzxs6xee.cloudfront.net/img/powered-by-lennd.svg"
            styleName="brandingImg"
          />
        </a>
      </div>
    );
  },
  css
);

export default View;
