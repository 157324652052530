import { createHandlers } from "redux-mvc";
import * as R from "ramda";
import { NAMESPACE } from "./constants";

const iniState = {
  title: "",
  url: "",
  thumbnailImageUrl: null
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.always(iniState),
    uploadThumbnailImage: R.identity
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
