import { createModule } from "redux-mvc";

import handlers from "Submission/Editor/handlers";
import subform from "Submission/Subform";
import peopleTable from "Submission/PeopleBlockTable";
import peopleBlockViewer from "Submission/PeopleBlockViewer";
import manageBy from "Items/Manage";

const submissionEditor = createModule({ ...handlers, optimist: true });

submissionEditor.plugModule(subform);
submissionEditor.plugModule(peopleTable);
submissionEditor.plugModule(peopleBlockViewer);
submissionEditor.plugModule(manageBy);

const { actions, getters } = submissionEditor;

export { actions, getters };

export default submissionEditor;
