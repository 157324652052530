import React from "react";
import * as R from "ramda";

import ApproversLabel from "ApproversLabel/View";

import Slim from "components/Global/ReactTable/Pagination/Slim";
import Tooltip from "components/Global/Tooltip";

import QuestionDetails from "Items/QuestionsDetails/View";

import {
  Div,
  Text0,
  Text2,
  Text3,
  Text6,
  DataTable,
  BigAvatar,
  FullSizeDotIcon,
  CheckIcon,
  Popover,
  MediumClearButton,
  MoreIcon,
  MenuCard,
  ReplyIcon,
  MenuItem,
  RightIcon,
  BoxGroup,
  DownFilledIcon
} from "components/Base";

import { liftToArr } from "utils/General";

const Header = props => <Div py={2} pl={2} {...props} />;

const IssuanceMenu = ({ onCheckin, onUndoCheckin }) => (
  <Div display="row">
    <Popover
      Label={({ onClick }) => (
        <BoxGroup addSpacers display="row.flex-start.center">
          <MediumClearButton
            bg="neutral5"
            color="primary5"
            onClick={onCheckin}
            uppercase
          >
            Pending Pick up
          </MediumClearButton>
          <MediumClearButton onClick={onClick} OnlyIcon={DownFilledIcon} />
        </BoxGroup>
      )}
    >
      {({ closePopover }) => (
        <MenuCard>
          <MenuItem
            onClick={() => {
              closePopover();
              onUndoCheckin();
            }}
            LeftIcon={ReplyIcon}
            RightIcon={RightIcon}
          >
            Undo Issuance
          </MenuItem>
        </MenuCard>
      )}
    </Popover>
  </Div>
);

const ActionsCell = ({ value, original }) => (
  <ApproversLabel
    {...{
      // data
      type: "lineItem",
      status: value.status,
      manifestId: value.manifestId,
      recordIds: value.recordIds,
      // handlers
      onEdit: () =>
        original.handlers.bulkEdit({
          lineItemId: value.recordIds
        }),
      handlers: original.handlers,
      // style
      containerProps: {
        display: "row.flex-start.center",
        pl: 2
      }
    }}
  />
);

const IssueActionsCell = ({ value }) => {
  if (value.isPickedUp) {
    return (
      <Div display="row.flex-start.center" truncate>
        <Tooltip tooltip={value.title}>
          <Div
            style={{
              maxWidth: "80%"
            }}
            truncate
            display="row.flex-start.center"
          >
            <CheckIcon color="success5" mr={2} />
            <Text2 color="neutral4" truncate>
              {value.title}
            </Text2>
          </Div>
        </Tooltip>
        {value.enabled ? (
          <Popover
            Label={({ onClick }) => (
              <MediumClearButton ml={2} OnlyIcon={MoreIcon} onClick={onClick} />
            )}
          >
            {({ closePopover }) => (
              <MenuCard>
                <MenuItem
                  LeftIcon={ReplyIcon}
                  RightIcon={RightIcon}
                  onClick={() => {
                    closePopover();
                    value.undoCheckin();
                  }}
                >
                  Undo Fulfillment
                </MenuItem>
              </MenuCard>
            )}
          </Popover>
        ) : null}
      </Div>
    );
  }
  if (value.enabled && value.isIssued) {
    return (
      <IssuanceMenu
        {...{
          onCheckin: value.checkin,
          onUndoCheckin: value.undoCheckin
        }}
      />
    );
  }
  if (!value.enabled) {
    return null;
  }
  if (value.needsPayment) {
    return (
      <Div
        fw={3}
        height={32}
        color="gray7"
        width={1}
        display="row.flex-start.center"
        uppercase
        ml={3}
      >
        Unpaid
      </Div>
    );
  }
  if (value.isPending) {
    return (
      <Div
        fw={3}
        height={32}
        color="gray7"
        width={1}
        display="row.flex-start.center"
        uppercase
        ml={3}
      >
        Pending
      </Div>
    );
  }
  return (
    <MediumClearButton
      bg="neutral5"
      color="primary5"
      onClick={value.checkin}
      uppercase
    >
      Check-in
    </MediumClearButton>
  );
};

export const COLUMNS = {
  location: {
    Header: <Header>Location</Header>,
    sortable: true,
    accessor: "location",
    minWidth: 120,
    Cell: ({ value }) => (
      <Text3
        pl={2}
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden"
        }}
      >
        {value}
      </Text3>
    )
  },
  daysNeeded: {
    Header: <Header>Days Needed</Header>,
    sortable: true,
    accessor: "daysNeeded",
    minWidth: 120,
    Cell: ({ value }) => (
      <Text3
        pl={2}
        style={{
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden"
        }}
      >
        {value}
      </Text3>
    )
  },
  quantity: {
    Header: <Header display="row.flex-end.center">QTY</Header>,
    sortable: true,
    accessor: "quantity",
    width: 60,
    Cell: ({ value, original }) => (
      <Div pl={2} display="row.flex-end.center" width={1}>
        {(R.path(["passType", "color"], original) && (
          <FullSizeDotIcon
            size={8}
            style={{ color: original.passType.color }}
          />
        )) ||
          null}
        <Text2 ml={4} bold display="row.flex-end.center">
          {value}
        </Text2>
      </Div>
    )
  },
  item: {
    Header: <Header>Item</Header>,
    sortable: true,
    accessor: "passType",
    minWidth: 120,
    style: {
      paddingLeft: 13,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start"
    },
    Cell: ({ value }) => (
      <>
        <Text3
          bold
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden"
          }}
        >
          {value.name}
        </Text3>
        <Div display="row.flex-start.center">
          {value.statuses.map(status => (
            <Text0 uppercase key={status} key={status} pr={2}>
              {status}
            </Text0>
          ))}
        </Div>
      </>
    )
  },
  attendee: {
    Header: <Header pl={2}>Attendee</Header>,
    sortable: true,
    accessor: "attendee",
    minWidth: 120,
    Cell: ({ value }) => (
      <Div display="row.flex-start.center" pl={2}>
        <BigAvatar
          photoURL={value.photoUrl}
          bg="neutral3"
          text={value.name || ""}
          singleInitial
        />
        <Div ml={2}>
          <Text2 bold width={1}>
            {value.name}
          </Text2>
          <Div display="row.flex-start.center">
            <Text0 uppercase>
              {[value.contactType, value.groupName]
                .filter(v => v && v.length)
                .join(" · ")}
            </Text0>
          </Div>
        </Div>
      </Div>
    )
  },
  order: {
    Header: (
      <Header pr={3} display="row.flex-end.center">
        Order #
      </Header>
    ),
    sortable: true,
    accessor: "orderId",
    width: 100,
    Cell: ({ value, original }) => (
      <Div display="row.flex-end.center" width={1}>
        {liftToArr(value).map(value => (
          <Text2
            key={value.id}
            mr={2}
            onClick={
              original.enableViewOrder
                ? () => original.handlers.viewOrder(null, value.id)
                : null
            }
            bb={original.enableViewOrder ? 1 : 0}
            bc="neutral4"
          >
            #{value.number}
          </Text2>
        ))}
      </Div>
    )
  },
  details: {
    Header: <Header pl={2}>Details</Header>,
    sortable: true,
    accessor: "details",
    minWidth: 150,
    Cell: ({ value, original }) =>
      value && value.length ? (
        <QuestionDetails
          pl={2}
          fs={2}
          truncate
          recordIds={R.pathOr([], ["approval", "recordIds"], original)}
        >
          {value}
        </QuestionDetails>
      ) : null
  },
  actions: {
    Header: <Header>Actions</Header>,
    sortable: true,
    accessor: "approval",
    width: 197,
    Cell: props => <ActionsCell {...props} />
  },
  issueActions: {
    Header: <Header>Actions</Header>,
    sortable: true,
    accessor: "checkin",
    Cell: props => <IssueActionsCell {...props} />
  },
  status: {
    Header: <Header pl={2}>Status</Header>,
    sortable: true,
    accessor: "approval",
    width: 197,
    Cell: props => <ActionsCell {...props} />
  }
};

const defaultColumns = R.props(
  ["quantity", "item", "attendee", "order", "actions"],
  COLUMNS
);

const PassesTable = ({ columns = defaultColumns, rows = [] }) => (
  <DataTable
    columns={columns}
    rows={rows}
    EmptyState={
      <Div display="column.center.center">
        <Text6 bold>There are no items yet.</Text6>
      </Div>
    }
    PaginationBarComp={Slim}
  />
);

export { PassesTable };
