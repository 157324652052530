import Helpers from "utils/Global/Helpers";

export default {
  postForm: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        // eslint-disable-next-line no-underscore-dangle
        url: `${window.__LENND_API_BASE_URL__}/form/form/wizard`,
        data,
        credentials,
        success,
        error
      });
    }),
  addLayout: ({ credentials, data }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */

      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/modules/${data.moduleId}/layouts`,
        qs: data.options,
        credentials,
        data,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */,
  addRecordType: ({ credentials, data }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */

      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/modules/${data.moduleId}/record-types`,
        qs: data.options,
        credentials,
        data,
        success,
        error
      });
    })
};
