import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Controller from "./Controller";

import {
  getPortalUser,
  updatePortalUser
} from "redux/modules/portal/user/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { getSettings } from "redux/modules/portal/settings/actions";

import { isFetching, portalUser } from "redux/modules/portal/user/selectors";
import { eventDetails, messages } from "redux/modules/portal/selectors";
import { user, getCredentials } from "redux/modules/user/selectors";
import { settings } from "redux/modules/portal/settings/selectors";

function mapStateToProps(state, props) {
  const atLandingPage = props.routes.some(
    r => r.name === "portalV2_PortalLanding"
  );
  return {
    isFetchingPortalUser: isFetching(state),
    portalUser: portalUser(state),
    eventDetails: eventDetails(state),
    messages: messages(state),
    user: user(state),
    settings: settings(state),
    atLanding: atLandingPage,
    credentials: getCredentials(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal,
      hideModal,
      getPortalUser,
      updatePortalUser,
      getSettings
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
