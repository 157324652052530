import React from "react";
import { connect } from "react-redux";
import { Div } from "components/Base";
import { getters as TabsGetters } from "Portal/Tabs";
import Tasks from "./Tasks";
import Schedule from "./Schedule";
import { FILTER } from "Portal/PortalHome/constants";
import { filteredTabs } from "Portal/PortalHome/selectors";

import Tabs from "Portal/Tabs/View";

const decorate = connect((state, props) => ({
  selectedTab: TabsGetters.selectedTab(state, props),
  tabs: filteredTabs(state)
}));

const Activities = ({ tabs, selectedTab }) => (
  <Div
    bg="neutral1"
    style={{
      borderRadius: 10,
      padding: "10px 20px",
      marginBottom: "40px"
    }}
  >
    <Tabs tabs={tabs} mb={4} />
    <Div>
      {selectedTab === FILTER.TASKS ? (
        <Tasks />
      ) : selectedTab === FILTER.SCHEDULE ? (
        <Schedule />
      ) : null}
    </Div>
  </Div>
);

export default decorate(Activities);
