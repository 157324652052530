export const NAMESPACE = "AddFileModal";

export const TABS = {
  DETAILS: "details",
  MANAGERS: "managers",
  ASSIGN: "assign"
};

export const TABS_LIST = [
  {
    id: TABS.DETAILS,
    title: "Details"
  },
  {
    id: TABS.MANAGERS,
    title: "Managers"
  },
  {
    id: TABS.ASSIGN,
    title: "Assign"
  }
];

export const TABLE_INSTANCE_ID = "addFileModalManagersTable";
