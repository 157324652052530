import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { createContext } from "redux-mvc";
import * as R from "ramda";
import reportTemplate, { actions } from "../index";
import Layout from "./Layout";
import rootSaga from "../sagas";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import SelectRelatedModulesModal from "Modules/SelectRelatedModulesModal/View";
import SelectFieldsModal from "Modules/SelectFieldsModal/View";

import { hideModal, showModal } from "redux/modules/modal/actions";

reportTemplate.setRootSaga(rootSaga);

const contextConfig = {
  module: reportTemplate,
  lifeCycle: {
    componentDidMount() {
      const { templateId } = this.props;
      this.store.dispatch(actions.init({ templateId }));
    },
    componentWillUnmount() {
      if (this.props.onDone) {
        this.props.onDone();
      }
    }
  },
  handlers: {
    showAddRelatedRecordsModal({ parentSectionId, moduleId }) {
      this.props.showModal({
        content: (
          <SelectRelatedModulesModal
            moduleId={moduleId}
            onDone={selectedModules => {
              this.store.dispatch(
                actions.addRelatedModuleSections({
                  parentSectionId,
                  selectedModules
                })
              );
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showSelectFieldsModal({ moduleId, sectionId, fields, selectedFieldIds }) {
      this.props.showModal({
        content: (
          <SelectFieldsModal
            moduleId={moduleId}
            selectedFieldIds={selectedFieldIds}
            fields={fields}
            onDone={selectedFields => {
              this.store.dispatch(
                actions.selectModuleFields({
                  moduleId,
                  sectionId,
                  selectedFields
                })
              );
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    }
  },
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", reportTemplate.modules))),
      R.prop("type")
    ),
    observedDomains: ["user", "event", "modal", "organization"]
  }
};

const decorate = R.compose(
  withRouter,
  connect(
    null,
    {
      showModal,
      hideModal
    }
  ),
  createContext(contextConfig)
);

export default decorate(Layout);
