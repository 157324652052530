import { put, takeEvery, all, fork, select, call } from "redux-saga/effects";
import * as R from "ramda";
import { getters, actions } from "./model";

import { getSettings } from "redux/modules/portal/settings/actions";
import { getPeople as fetchPeople } from "redux/modules/portal/people/actions";

import { eventDetails as getEventDetails } from "redux/modules/portal/selectors";
import { portalUser as getPortalUser } from "redux/modules/portal/user/selectors";
import { settings } from "redux/modules/portal/settings/selectors";
import { people as getPeople } from "redux/modules/portal/people/selectors";
import Helpers from "utils/Global/Helpers";
import { EXTRA_FIELDS } from "./constants";

const getParams = function*() {
  const eventDetails = yield select(getEventDetails);
  const portalUser = yield select(getPortalUser);
  const isViewingAsAccount = R.pathEq(["active_view", "type"], "account")(
    portalUser
  );
  const accountId = isViewingAsAccount
    ? R.path(["active_view", "id"], portalUser)
    : null;

  return {
    eventId: eventDetails.id,
    accountId
  };
};

const loadPortalData = function*() {
  const { eventId, accountId } = yield call(getParams);
  yield put(getSettings(eventId, accountId));
  yield put(fetchPeople(eventId, accountId));
};

const init = function*({ payload: { iniTypeId = null } }) {
  const eventDetailsToPass = yield select(getEventDetails);
  const eventId = eventDetailsToPass.id;
  const portalSettings = yield select(settings);

  const types =
    portalSettings && portalSettings.types
      ? portalSettings.types.map(t => ({
          id: t.id,
          name: t.name,
          fields: t.fields
        }))
      : [];
  const people = yield select(getPeople);

  yield put(
    actions.setInitialData({
      eventId,
      portalSettings,
      types,
      eventDetails: eventDetailsToPass,
      people,
      typeId: iniTypeId || types[0].id
    })
  );
};

const validate = function*({ typeId, values }) {
  const error = [];
  const types = yield select(getters.types);
  const fields = R.compose(
    R.concat(EXTRA_FIELDS),
    R.propOr([], "fields"),
    R.find(R.propEq("id", typeId))
  )(types);

  /*
  // @NOTE: Commenting out so admin can still add even if no types are enabled
  if (R.isEmpty(typeId) || R.isNil(typeId)) {
    error.push("Type");
  }
  */

  fields.forEach(field => {
    const val = R.path([field.id, "value"])(values);
    if (field.required && (R.isEmpty(val) || R.isNil(val))) {
      error.push(field.name);
    } else if (
      field.type === "email" &&
      !(R.isEmpty(val) || R.isNil(val)) &&
      !Helpers.isValidEmail(val)
    ) {
      error.push("Email is invalid");
    }
  });
  if (error.length) {
    yield put(
      actions.setError(
        `* ${error.join(", ")} field${
          error.length === 1 ? " is" : "s are"
        } required`.replace(/(,\s)(?!.*,\s)/, " and ")
      )
    );
    return false;
  }
  yield put(actions.setError(null));
  return true;
};

const handleSave = function*() {
  const typeId = yield select(getters.typeId);
  const values = yield select(getters.values);
  const isValid = yield call(validate, { typeId, values });
  if (isValid) {
    const valuesToPass = R.compose(
      R.dissoc("role"),
      R.dissoc("is_primary")
    )(values);
    const isPrimary = R.path(["is_primary", "value"])(values);
    const role = R.path(["role", "value"])(values);

    yield put(
      actions.onSaveFinished({
        typeId,
        record: valuesToPass,
        isPrimary,
        role
      })
    );
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchLoadPortalData = function*() {
  yield takeEvery(actions.loadData.type, loadPortalData);
};

const watchOnSave = function*() {
  yield takeEvery(actions.handleSave.type, handleSave);
};

const rootSaga = function*() {
  yield all([fork(watchLoadPortalData), fork(watchInit), fork(watchOnSave)]);
};

export default rootSaga;
