import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import { actions } from "Catering/Dashboard";
import { userId } from "redux/modules/user/selectors";
import { eventId } from "redux/modules/event/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import CateringDashboardApi from "Catering/Dashboard/api";
import { registerError } from "redux/modules/errors/actions";
import { navigateTo } from "utils/General";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const id = yield select(userId);
  const event = yield select(eventId);

  return {
    userId: id,
    eventId: event,
    credentials
  };
};

const getCateringDashboardData = function*() {
  const params = yield call(getParams);
  yield put(actions.setLoading(true));
  try {
    const { payload } = yield call(
      CateringDashboardApi.getCateringDashboard,
      params
    );
    yield put(actions.setInitialData({ data: payload }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred importing the catering data"
        }
      ])
    );
  }
  yield put(actions.setLoading(false));
};

const downloadReportDays = function*({ payload: exportUrl }) {
  try {
    const { credentials } = yield call(getParams);
    const result = yield call(CateringDashboardApi.export, {
      url: exportUrl,
      credentials
    });
    yield call(navigateTo, result.payload.url);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred exporting meals"
          }
        ])
      ),
      put(actions.setLoading(false, {}, true))
    ]);
  }
};

const watchGetCateringDashboardData = function*() {
  yield takeEvery(
    actions.getCateringDashboardData.type,
    getCateringDashboardData
  );
};

const watchDownloadReportDays = function*() {
  yield takeEvery(actions.downloadReportDays.type, downloadReportDays);
};

const rootSaga = function*() {
  yield all([
    fork(watchGetCateringDashboardData),
    fork(watchDownloadReportDays)
  ]);
};

export default rootSaga;
