import React from "react";
import * as R from "ramda";
import { Div, StarIcon, Text4, MediumAvatar, Text3 } from "components/Base";
import { isEmptyOrNil } from "utils/General";

const Pinned = ({ events }) => {
  return isEmptyOrNil(events) ? null : (
    <Div mb={4}>
      <Div ml={2} mb={1} display={"row.flex-start.center"}>
        <StarIcon size={24} color={"yellow7"} />
        <Text4 ml={1} color={"neutral6"} bold>
          Pinned
        </Text4>
      </Div>

      <Div display={"row"} style={{ flexWrap: "wrap" }}>
        {R.map(
          ({ id, name, goToEvent, photoURL }) => (
            <Div
              bra={2}
              key={id}
              p={2}
              width={230}
              display={"row.flex-start.center"}
              bg={{ default: "transparent", hover: "neutral1" }}
              onClick={goToEvent}
            >
              <MediumAvatar
                longInitials
                square
                id={id}
                photoURL={photoURL}
                text={name}
              />
              <Text3 ml={2} color={"neutral6"} color="gray7">
                {name}
              </Text3>
            </Div>
          ),
          R.sortBy(R.prop("name"), events)
        )}
      </Div>
    </Div>
  );
};

export default Pinned;
