import { mergeWith, isNumber } from "lodash";

const add = (a, b) => {
  if (isNumber(a) || isNumber(b)) {
    return (isNumber(a) ? a : 0) + (isNumber(b) ? b : 0);
  }
};

export default function(...args) {
  return mergeWith(...args, add);
}
