import React from "react";

import { connect } from "react-redux";

import * as R from "ramda";

import { getters, actions } from "Items/BulkQuestionItemsModal";

import { getFilteredTypes } from "Items/BulkQuestionItemsModal/selectors";

import {
  Div,
  DownFilledIcon,
  SmallCheckbox,
  collapsableHandler,
  Collapsable,
  Text0,
  Text1
} from "components/Base";

const decorate = connect(
  state => ({
    itemTypes: getFilteredTypes(state),
    typeIds: getters.typeIds(state),
    groupIds: getters.groupIds(state),
    variantIds: getters.variantIds(state)
  }),
  {
    toggleType: actions.toggleType,
    toggleGroup: actions.toggleGroup,
    toggleVariant: actions.toggleVariant
  }
);

const Variant = ({ name, isEnabled, toggleVariant, id, groupId, typeId }) => (
  <Div display="row.flex-start.center">
    <SmallCheckbox
      selected={isEnabled}
      onClick={() =>
        toggleVariant({ typeId, groupId, variantId: id, isEnabled })
      }
    />
    <Div flex={1}>
      <Text0 bold ml={2}>
        {name}
      </Text0>
    </Div>
  </Div>
);

const Group = collapsableHandler(
  ({
    toggleCollapsed,
    collapsed,
    name,
    variants,
    toggleGroup,
    isEnabled,
    id,
    typeId,
    toggleVariant,
    variantIds
  }) => (
    <Div width={1} pl={6}>
      <Div width={1} p={1}>
        <Div display="row.flex-start.center">
          <DownFilledIcon
            mr={1}
            color="black"
            size={24}
            style={{ transform: `rotate(${collapsed ? "-90" : "0"}deg)` }}
            onClick={toggleCollapsed}
          />
          <SmallCheckbox
            selected={isEnabled}
            onClick={() =>
              toggleGroup({ typeId, groupId: id, isEnabled, variants })
            }
          />
          <Div flex={1} onClick={toggleCollapsed}>
            <Text1 bold ml={2}>
              {name}
            </Text1>
          </Div>
        </Div>
      </Div>
      <Collapsable collapsed={collapsed}>
        <Div width={1} p={1} pl={15}>
          {R.map(
            variant => (
              <Variant
                {...variant}
                key={variant.id}
                toggleVariant={toggleVariant}
                isEnabled={R.compose(
                  R.any(gId => gId === variant.id),
                  R.propOr([], id)
                )(variantIds)}
                groupId={id}
                typeId={typeId}
              />
            ),
            variants
          )}
        </Div>
      </Collapsable>
    </Div>
  )
);

const Type = collapsableHandler(
  ({
    toggleCollapsed,
    collapsed,
    name,
    groups,
    toggleType,
    typeIdEnabled,
    id,
    toggleGroup,
    groupIds,
    toggleVariant,
    variantIds
  }) => (
    <Div width={1} p={2} bra={1} ba={1} bc="neutral2" bg="white" mb={1}>
      <Div display="row.flex-start.center">
        <DownFilledIcon
          mr={1}
          color={R.length(groups) > 0 ? "black" : "neutral5"}
          size={24}
          style={{ transform: `rotate(${collapsed ? "-90" : "0"}deg)` }}
          onClick={() => {
            if (R.length(groups) > 0) {
              toggleCollapsed();
            }
          }}
        />
        <SmallCheckbox
          selected={typeIdEnabled}
          onClick={() =>
            toggleType({ typeId: id, isEnabled: typeIdEnabled, groups })
          }
        />
        <Div flex={1} onClick={toggleCollapsed}>
          <Text1 color="black" bold ml={2}>
            {name}
          </Text1>
        </Div>
      </Div>
      <Collapsable collapsed={collapsed}>
        {R.map(group => {
          const isEnabled = R.compose(
            R.any(gId => gId === group.id),
            R.propOr([], id)
          )(groupIds);
          return (
            <Group
              key={group.id}
              {...group}
              toggleGroup={toggleGroup}
              isEnabled={isEnabled}
              collapsedDefault={!isEnabled}
              typeId={id}
              toggleVariant={toggleVariant}
              variantIds={variantIds}
            />
          );
        }, groups)}
      </Collapsable>
    </Div>
  )
);

const ItemsList = ({
  itemTypes,
  toggleType,
  toggleGroup,
  typeIds,
  groupIds,
  toggleVariant,
  variantIds
}) => {
  return (
    <Div width={1}>
      {R.map(
        type => (
          <Type
            key={type.id}
            {...type}
            collapsedDefault={!R.any(id => id === type.id, typeIds)}
            toggleType={toggleType}
            typeIdEnabled={R.any(id => id === type.id, typeIds)}
            toggleGroup={toggleGroup}
            groupIds={groupIds}
            toggleVariant={toggleVariant}
            variantIds={variantIds}
          />
        ),
        itemTypes
      )}
    </Div>
  );
};

export default decorate(ItemsList);
