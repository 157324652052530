import * as R from "ramda";
import { createHandlers } from "redux-mvc";

const iniState = {
  data: {},
  passFilter: ""
};

export const model = createHandlers({
  iniState,
  reducers: {
    toggleZoneItemAssociations: R.identity,
    deselectMultipleZoneItem: R.identity,
    selectMultipleZoneItem: R.identity,
    init: (state, { payload: { data } }) => ({
      data
    })
  },
  namespace: "AccessGridTable"
});

const { actions, getters } = model;

export { actions, getters };

export default model;
