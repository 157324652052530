import React, { Component } from "react";
import ItemBlockList from "./List";
import ItemBlockQuantity from "./QuantityInput";
import { get, isEqual } from "lodash";

class ItemBlock extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  render() {
    const { value, dependentValues } = this.props;

    // ensure we're only handling data of type 'item-block'
    if (get(value, "type") !== "item-block") {
      return null;
    }
    const type = get(dependentValues, "meta.columnSettings.selection_type");
    const items = get(value, "value", []);
    if (type === "quantity-only-input") {
      return <ItemBlockQuantity quantity={get(items, ["0", "quantity"])} />;
    }
    return <ItemBlockList items={items} />;
  }
}

export default ItemBlock;
