import React from "react";
import { connect } from "react-redux";

import { getters } from "../model";

import MenuPopover from "ui-kit/MenuPopover";
import { PrevNext } from "ui-kit/TimeTable/View";
import OptionsButton from "./OptionsButton";
import { DownFilledIcon, MediumFilledButton, Div } from "components/Base";
import MonthCalendar from "./MonthCalendar";
import { withProps } from "utils/General";
import css from "ui-kit/ViewPicker/View/styles.scss";

import CanUserDo from "components/Global/Security/CanUserDo";

const wrapperStyle = {
  display: "flex",
  flexDirection: "column",
  marginLeft: 8
};

const decorate = connect((state, props) => ({
  moduleId: getters.moduleId(state, props)
}));

const Toolbar = ({ instanceId, handlers, moduleId, ...styleProps }) => (
  <Div display="row.flex-start.center" mb={2} {...styleProps}>
    <Div p={1} bg="white" ml={2}>
      <MenuPopover
        Label={
          <MediumFilledButton
            className={css.button1}
            hover={false}
            color="white"
            RightIcon={withProps({
              color: "purple4"
            })(DownFilledIcon)}
          >
            Go to...
          </MediumFilledButton>
        }
      >
        {({ closePopover }) => <MonthCalendar closePopover={closePopover} />}
      </MenuPopover>
    </Div>
    <PrevNext inverse instanceId={instanceId} />
    <Div display="row.center.stretch">
      <CanUserDo any={[`${moduleId}_manage`, `${moduleId}_export`]}>
        <OptionsButton
          wrapperStyle={wrapperStyle}
          flex={1}
          handlers={handlers}
        />
      </CanUserDo>
    </Div>
  </Div>
);

export default decorate(Toolbar);
