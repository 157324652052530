import React from "react";
import { put, all, takeEvery, fork, select, call } from "redux-saga/effects";
import { actions, getters } from "./model";
import {
  actions as SearchBarActions,
  getters as SearchBarGetters
} from "ui-kit/SearchBar";
import {
  actions as CartActions,
  getters as CartGetters
} from "Portal/Cart/model";
import { makeFuture } from "utils/General/sagas";
import ItemDetailsModal from "Orders/ItemDetailsModal/View";
import { showModal, hideModal } from "redux/modules/modal/actions";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import {
  actions as FormActions,
  getters as FormGetters
} from "ui-kit/Form/model";
import { apiCall } from "App/Data/sagas";
import { eventId as getEventId } from "redux/modules/portal/selectors";
import { portalUser as getPortalUser } from "redux/modules/portal/user/selectors";
import { registerError } from "redux/modules/errors/actions";
import { debounce } from "utils/General/sagas";

import * as R from "ramda";
import { FILTER_MODE, PEOPLE_LIST_ID } from "./constants";

const getParams = function*() {
  const eventId = yield select(getEventId);
  const portalUser = yield select(getPortalUser);
  const isViewingAsAccount = R.pathEq(["active_view", "type"], "account")(
    portalUser
  );
  const accountId = isViewingAsAccount
    ? R.path(["active_view", "id"], portalUser)
    : null;

  return {
    eventId,
    accountId
  };
};

const updateCartItems = function*({ cartInfo, categories }) {
  const lineItems = R.propOr([], "line_items", cartInfo);

  const allItems = R.reduce(
    (acc, category) => {
      return [
        ...acc,
        ...R.reduce(
          (acc, group) => [
            ...acc,
            ...R.map(
              ({ id, name }) => ({
                id,
                name,
                category: group.id,
                categoryName: group.name
              }),
              group.items
            )
          ],
          [],
          category.groups
        )
      ];
    },
    [],
    categories
  );

  const cartItems = R.filter(
    item => R.any(lineItem => lineItem.variant_id === item.id, lineItems),
    allItems
  );

  const mappedCartItems = R.map(item => {
    const itemInfo = R.find(
      lineItem => lineItem.variant_id === item.id,
      lineItems
    );

    return {
      ...item,
      quantity: R.propOr(1, "quantity", itemInfo),
      price: R.propOr(0, "price", itemInfo),
      questions: R.propOr(0, "question_values", itemInfo)
    };
  }, cartItems);

  yield put(CartActions.setItems(mappedCartItems));
};

const init = function*({ payload: { recordTypeId } }) {
  try {
    yield put(actions.setLoading(true));
    const { eventId, accountId } = yield call(getParams);

    const [{ payload }, { payload: cartInfo }] = yield all([
      call(apiCall, {
        method: "get",
        url: `/portal/event/${eventId}/account/${accountId}/people/${recordTypeId}/requestable-items`
      }),
      call(apiCall, {
        method: "get",
        url: accountId
          ? `/orders/event/${eventId}/account/${accountId}/cart`
          : `/orders/event/${eventId}/cart`
      })
    ]);

    yield all([
      yield call(updateCartItems, {
        cartInfo,
        categories: R.propOr([], "categories", payload)
      }),
      yield put(
        actions.setInitialData({
          recordTypeId,
          ...payload
        })
      ),
      yield put(actions.setSelectedPeople(R.propOr([], "customers", cartInfo)))
    ]);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred getting data"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const selectCategory = function*() {
  const text = yield select(SearchBarGetters.searchTerm);
  if (text) {
    yield put(actions.setFilterMode(FILTER_MODE.BY_CATEGORY));
  }
};

const search = function*({ payload: strToSearch }) {
  const { eventId, accountId } = yield call(getParams);

  const text = R.trim(strToSearch);
  if (text) {
    yield put(actions.setFilterMode(FILTER_MODE.BY_INPUT));
  } else {
    yield put(actions.setFilterMode(FILTER_MODE.BY_CATEGORY));
  }
  const recordTypeId = yield select(getters.recordTypeId);

  const { payload } = yield call(apiCall, {
    method: "get",
    url: `/portal/event/${eventId}/account/${accountId}/people/${recordTypeId}/requestable-items`,
    qs: { search: text }
  });

  yield put(
    actions.setInitialData({
      recordTypeId,
      ...payload
    })
  );
};

const showAddItemdModal = function*({ item }) {
  const handleIssuance = makeFuture();
  const color = yield select(getters.highlightColor);
  yield put(
    showModal({
      content: (
        <ItemDetailsModal
          itemId={item}
          variantId={item}
          color={color}
          disableChangingPrice={true}
          onSave={item => handleIssuance.done({ type: "done", item })}
          showInternalQuestions={false}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleIssuance.onRealized);
};

const handleAddItemModal = function*({
  payload: { item, category, categoryId, name }
}) {
  const result = yield call(showAddItemdModal, { item });

  if (result.type === "done") {
    yield all([
      put(hideModal),
      put(
        CartActions.addItem({ item: result.item, category, categoryId, name })
      )
    ]);
  }
};

const getPeopleList = function*({ meta: { callback }, payload: search }) {
  const { eventId, accountId } = yield call(getParams);
  const recordTypeId = yield select(getters.recordTypeId);

  const { payload } = yield call(apiCall, {
    method: "get",
    url: `/portal/event/${eventId}/account/${accountId}/people/${recordTypeId}/requestable-items`,
    qs: {
      search,
      ...(search ? {} : { pageSize: 30 })
    }
  });

  callback(
    R.map(({ id, name }) => ({ value: id, label: name }))(
      R.propOr([], "people", payload)
    )
  );
};

const updateSelectedPeople = function*() {
  try {
    yield put(actions.setSelectedPeopleLoading(true));
    const formFields = yield select(FormGetters.fields);
    const { eventId, accountId } = yield call(getParams);
    const selectedPeople = yield select(getters.selectedPeople);

    const selectedDropdown = R.compose(
      R.map(({ value }) => ({ contactId: value, accountId })),
      R.pathOr([], [PEOPLE_LIST_ID, "value"])
    )(formFields);

    yield call(apiCall, {
      method: "put",
      url: accountId
        ? `/orders/event/${eventId}/account/${accountId}/cart`
        : `/orders/event/${eventId}/cart`,
      data: {
        customers: R.uniq([
          ...R.map(
            ({ contact_id, account_id }) => ({
              accountId: account_id,
              contactId: contact_id
            }),
            selectedPeople
          ),
          ...selectedDropdown
        ])
      }
    });

    const { payload } = yield call(apiCall, {
      method: "get",
      url: accountId
        ? `/orders/event/${eventId}/account/${accountId}/cart`
        : `/orders/event/${eventId}/cart`
    });

    yield put(actions.setSelectedPeople(R.propOr([], "customers", payload)));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred updating items/people"
          }
        ])
      )
    ]);
  } finally {
    yield all([
      put(actions.setSelectedPeopleLoading(false)),
      put(actions.setIsSelectPeopleModalOpen(false))
    ]);
  }
};

const updateSelectedPeopleAndItems = function*() {
  try {
    const lineItems = yield select(CartGetters.items);
    const selectedPeople = yield select(getters.selectedPeople);
    const { eventId, accountId } = yield call(getParams);

    yield call(apiCall, {
      method: "put",
      url: accountId
        ? `/orders/event/${eventId}/account/${accountId}/cart`
        : `/orders/event/${eventId}/cart`,
      data: {
        lineItems: R.map(
          ({ id, price, quantity, questions }) => ({
            variantId: id,
            price,
            quantity,
            questionValues: questions
          }),
          lineItems
        ),
        customers: R.map(
          ({ contact_id, account_id }) => ({
            accountId: account_id,
            contactId: contact_id
          }),
          selectedPeople
        )
      }
    });
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred updating items/people"
          }
        ])
      )
    ]);
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchHandleAddItemModal = function*() {
  yield takeEvery(actions.handleAddItemModal.type, handleAddItemModal);
};

const watchSetSelectedCategory = function*() {
  yield takeEvery(actions.setSelectedCategory.type, selectCategory);
};

const watchSearch = function*() {
  yield takeEvery(SearchBarActions.setSearchTerm.type, search);
};

const watchAysncDropdown = debounce(
  FormActions.dataRequest.type,
  getPeopleList,
  250
);

const watchUpdateSelectedPeopleAndItems = function*() {
  yield takeEvery(
    [
      CartActions.addItem.type,
      CartActions.removeItem.type,
      CartActions.modifyItem.type,
      actions.removeSelectedPerson.type
    ],
    updateSelectedPeopleAndItems
  );
};

const watchUpdateSelectedPeople = function*() {
  yield takeEvery(actions.updateSelectedPeople.type, updateSelectedPeople);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchSetSelectedCategory),
    fork(watchSearch),
    fork(watchHandleAddItemModal),
    fork(watchAysncDropdown),
    fork(watchUpdateSelectedPeopleAndItems),
    fork(watchUpdateSelectedPeople)
  ]);
};

export default rootSaga;
