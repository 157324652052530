import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Tooltip from "components/Global/Tooltip";

const SidebarTooltip = ({ message, ...props }) => (
  <Tooltip tooltip={message} placement="top" {...props}>
    <div styleName="icon">
      <span>?</span>
    </div>
  </Tooltip>
);

SidebarTooltip.propTypes = {
  message: PropTypes.string.isRequired
};

export default CSSModules(SidebarTooltip, css);
