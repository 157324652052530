import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import * as FieldActions from "redux/modules/formsV2/form/fields/actions";

@CSSModules(css)
class AddSectionModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      error: null
    };
  }

  @autobind
  handleNameChange(e) {
    this.setState({ name: e.target.value, error: null });
  }

  @autobind
  handleTypeChange(e) {
    this.setState({ type: e.target.value, error: null });
  }

  @autobind
  onSave() {
    if (!this.state.name) {
      this.setState({ error: "Please enter the grid's name" });
    } else {
      this.props.dispatch(
        FieldActions.addField({
          eventId: this.props.eventId,
          formId: this.props.formId,
          type: "section",
          name: this.state.name,
          order: this.props.index
        })
      );

      this.props.hideModal();
    }
  }

  render() {
    const { name, error } = this.state;
    return (
      <div>
        <div className="modal-header">
          <div className="modal-title" styleName="title">
            Add Section
          </div>
        </div>

        <div className="modal-body" styleName="modalBody">
          <div className="modal-body-wrapper">
            <div className="input-wrapper">
              <label styleName="formLabel">Section Name</label>
              <input
                key="name"
                styleName="input"
                type="text"
                value={name}
                placeholder="Section Name"
                required="required"
                onChange={this.handleNameChange}
              />
            </div>

            {/* error */}
            {error !== null ? <div styleName="error">{error}</div> : ""}

            <div styleName="actionsWrapper">
              <div styleName="actionSave" onClick={this.onSave}>
                Save
              </div>
              <div styleName="actionCancel" onClick={this.props.hideModal}>
                Cancel
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AddSectionModal.getModalClassName = () => "OptionsModal";

AddSectionModal.propTypes = {
  eventId: PropTypes.number.isRequired,
  formId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  dispatch: PropTypes.func,
  hideModal: PropTypes.func
};

export default AddSectionModal;
