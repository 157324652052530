import * as R from "ramda";
import cx from "classnames";

import React from "react";

import Cell from "./Cell";
import ToggleRow from "./ToggleRow";
import Actions from "./Actions";
import Indicator from "./Indicator";

import { ROW_ACTIONS } from "../constants";

export const Row = React.memo(
  ({
    row,
    columnIndex,
    columns,
    instanceId,
    actions,
    group,
    showCheckbox,
    showOpenRecord,
    executeAction,
    canShowDeDuplicate,
    moduleId
  }) => (
    <tr className="tr">
      {R.length(actions) > 0 || showCheckbox || showOpenRecord ? (
        <td
          className={cx("td action", {
            extended: showCheckbox && showOpenRecord && R.length(actions) > 0,
            shortened: !showCheckbox && !showOpenRecord && !R.length(actions)
          })}
          key="actions"
        >
          <div className="utils">
            {showCheckbox && (
              <ToggleRow rowId={row.id} instanceId={instanceId} />
            )}
            {showOpenRecord && (
              <i
                className="material-icons open-record"
                onClick={() =>
                  executeAction({ row, actionId: ROW_ACTIONS.OPEN_RECORD })
                }
              >
                open_in_new
              </i>
            )}
            {R.length(actions) > 0 && (
              <Actions
                row={row}
                actions={actions}
                instanceId={instanceId}
                group={group}
              >
                <div className="action-wrapper">
                  <i className="material-icons flash">flash_on</i>
                  <i className="material-icons down">keyboard_arrow_down</i>
                </div>
              </Actions>
            )}
            {canShowDeDuplicate && row.hasDuplicate ? (
              <Indicator
                row={row}
                instanceId={instanceId}
                moduleId={moduleId}
              />
            ) : null}
          </div>
        </td>
      ) : (
        <td
          className={cx("td action", {
            extended: showCheckbox && showOpenRecord && R.length(actions) > 0,
            shortened: !showCheckbox && !showOpenRecord && !R.length(actions)
          })}
          key="actions"
        >
          <div className="utils"></div>
        </td>
      )}
      {R.map(
        columnId => (
          <Cell
            row={row}
            column={R.prop(columnId, columns)}
            key={columnId}
            instanceId={instanceId}
          />
        ),
        columnIndex
      )}
    </tr>
  )
);

Row.defaultProps = {
  useRowActions: true,
  showCheckbox: true,
  showOpenRecord: true,
  showDeduplicateColumn: true,
  row: {},
  columns: {},
  columnIndex: [],
  actions: [],
  group: {}
};
