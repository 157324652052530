import React from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import {
  VisibleFields,
  AvailableFields
} from "components/Event/Settings/Module/Types/Layouts/Base";
import { Div, SuperFilledButton } from "components/Base";

const View = ({
  allFields,
  visibleFields,
  hideModal,
  onFieldsReorder,
  onAddSort,
  onRemoveSort,
  sortBy
}) => {
  return (
    <StyleWrapper
      width={795}
      bodyStyles={{ padding: 0 }}
      hideModal={hideModal}
      heading="Related Module Fields"
    >
      <Div
        display="row"
        pb={3}
        pt={5}
        px={6}
        bc="neutral1"
        bb={1}
        style={{
          backgroundColor: "#f7f5fa"
        }}
      >
        <VisibleFields
          maxHeight={284}
          width={280}
          mr={2}
          visibleFields={visibleFields}
          onFieldsReorder={onFieldsReorder}
          emptyMessage="No fields selected. By default, all fields will show."
          {...{
            allFields,
            onAddSort,
            onRemoveSort,
            sortBy
          }}
        />
        <AvailableFields
          maxHeight={284}
          flex={1}
          px={2}
          allFields={allFields}
        />
      </Div>
      <Div my={3} mx={6}>
        <SuperFilledButton
          bg="orange5"
          style={{
            cursor: "pointer"
          }}
          mr={3}
          onClick={hideModal}
        >
          <Div fw={3}>Done</Div>
        </SuperFilledButton>
      </Div>
    </StyleWrapper>
  );
};

export default View;
