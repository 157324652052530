import React from "react";
import * as R from "ramda";

import {
  SimpleTable,
  Text1,
  Text2,
  Text3,
  Div,
  FullSizeDotIcon,
  CheckIcon,
  CloseIcon
} from "components/Base";
import { defaultProps } from "utils/General";

const Num = (align = "center") => accessor =>
  R.compose(
    number => (
      <Text2 display={`row.${align}.center`} flex={1}>
        {number}
      </Text2>
    ),
    R.ifElse(R.either(Number.isNaN, R.equals(0)), () => "-", R.identity),
    Number,
    R.path(R.split(".", accessor))
  );

const Price = (align = "center") => accessor =>
  R.compose(
    number => (
      <Text2
        display={`row.${align}.center`}
        flex={1}
        style={{
          whiteSpace: "nowrap"
        }}
      >
        {number}
      </Text2>
    ),
    R.path(R.split(".", accessor))
  );

export const COLUMNS = {
  passType: {
    cell: ({ passType }) => (
      <Div display="row.flex-start.center">
        <FullSizeDotIcon
          mr={passType.IntegrationIcon ? 2 : 0}
          size={8}
          style={{ color: passType.color }}
        />
        {passType.IntegrationIcon}
        <Div display="column" ml={passType.IntegrationIcon ? 0 : 4} width={1}>
          <Text3 bold>{passType.name}</Text3>
          {passType.subname ? (
            <Text1 color="gray5">{passType.subname}</Text1>
          ) : null}
        </Div>
      </Div>
    ),
    size: [3 / 12, "left"],
    header: () => <Text1 bold>PASS TYPE</Text1>
  },
  requested: {
    cell: Num()("summary.requested"),
    size: [1 / 12, "center"],
    header: () => <Text1 bold>REQ</Text1>
  },
  rejected: {
    cell: Num()("summary.rejected"),
    size: [1 / 12, "center"],
    header: CloseIcon
  },
  approved: {
    cell: Num()("summary.approved"),
    size: [1 / 12, "center"],
    header: CheckIcon
  },
  price: {
    cell: Price("flex-end")("summary.price"),
    size: [2 / 12, "right"],
    header: () => <Text1 bold>UNIT PRICE</Text1>
  },
  issued: {
    cell: Num("flex-end")("summary.issued"),
    size: [2 / 12, "right"],
    header: () => <Text1 bold>ISSUED</Text1>
  },
  received: {
    cell: Num("flex-end")("summary.received"),
    size: [2 / 12, "right"],
    header: () => <Text1 bold>RECEIVED</Text1>
  },
  total: {
    cell: Price("flex-end")("summary.total"),
    size: [2 / 12, "right"],
    header: () => <Text1 bold>TOTAL</Text1>
  }
};

const createPassesSimpleTable = ({ columns, style = {} }) =>
  SimpleTable({
    columnProps: R.map(R.prop("size"), columns),
    headerCellComps: R.map(R.prop("header"), columns),
    rowCellComps: R.map(R.prop("cell"), columns),
    HeaderStyler: defaultProps({
      display: "row.flex-start.center",
      pb: 2,
      bb: 1,
      bc: "neutral3",
      fs: 3,
      ...style
    })(Div)
  });

export default createPassesSimpleTable;
