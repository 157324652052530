import React from "react";
import Html from "./Html";
import Sessions from "./Sessions";

const Section = ({ section }) => {
  if (section.type === "sessions") {
    return <Sessions content={section.content} />;
  } else if (section.type === "html") {
    return <Html content={section.content} />;
  }
  return null;
};

export default Section;
