import React from "react";

import { noop } from "utils/General";

import { ButtonFilter } from "ui-kit/FilterControls/View";
import { Text2, VisibleIcon } from "components/Base";

const ShowHideFields = ({ onClick = noop }) => (
  <ButtonFilter hover mt={2} height={38} onClick={onClick}>
    <VisibleIcon fs={4} mr={2} />
    <Text2 bold>Show/Hide Fields</Text2>
  </ButtonFilter>
);

export default ShowHideFields;
