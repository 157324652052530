import Helpers from "utils/Global/Helpers";

const baseUrl = (uuid, accountId) =>
  `${
    window.__LENND_API_BASE_URL__
  }/portal/assignment-manager/event/${uuid}/group/${accountId}/resources`;

module.exports = {
  get: ({ eventUUID, accountId }) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: baseUrl(eventUUID, accountId),
        data: {},
        success: result => {
          resolve(result.payload.items);
        },
        error: err => {
          reject(err);
        }
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  post: () => {},
  put: () => {},
  delete: () => {}
};
