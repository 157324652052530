import PATTERNS from "utils/value-types/filter/patterns";

const { IS_CHECKED, IS_NOT_CHECKED } = PATTERNS;

module.exports = ({ pattern, recordValue }) => {
  switch (pattern) {
    case IS_CHECKED.pattern:
      return recordValue && recordValue.value;
    case IS_NOT_CHECKED.pattern:
      return !recordValue || !recordValue.value;
    default:
      return false;
  }
};
