import { createSelector } from "reselect";
import moment from "moment";
import * as R from "ramda";

import { eventDetails } from "redux/modules/event/selectors";
import { canUserDo } from "redux/modules/permissions/user-permission-profile/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { getters } from "./model";

export const getEventInitial = createSelector(
  eventDetails,
  eventDetails => (eventDetails.name ? eventDetails.name[0] : "")
);

export const getEventVenue = R.always("Zoom");

export const getShowPublishButton = createSelector(
  eventDetails,
  eventDetails =>
    eventDetails.scope === "virtual" && !eventDetails.is_virtual_event_published
);

export const getLogoImageUrl = createSelector(
  eventDetails,
  eventDetails => eventDetails.logo_image_url
);

export const getEventDates = createSelector(
  eventDetails,
  event => {
    return event.date_from && event.date_to
      ? `${moment(event.date_from)
          .tz(event.timezone)
          .format("MMM D h:mm A")} - ${moment(event.date_to)
          .tz(event.timezone)
          .format("MMM D h:mm A")} (${moment()
          .tz(event.timezone)
          .format("z")})`
      : null;
  }
);

const isActiveRoute = (routes, params, route, routeParams) => {
  const paramsMatch = routeParams ? R.whereEq(routeParams)(params) : true;

  if (Array.isArray(route)) {
    return (
      R.compose(
        Boolean,
        R.length,
        R.intersection(route),
        R.map(R.prop("name"))
      )(routes) && paramsMatch
    );
  }

  return routes.some(r => r.name === route) && paramsMatch;
};

export const getIsOrgUser = createSelector(
  getters.navigation,
  R.prop("is_org_user")
);

export const getLinks = createSelector(
  getters.navigation,
  (_, props) => props.routes,
  (_, props) => props.params,
  eventDetails,
  state => canUserDo(state, getEventId(state)),
  getEventId,
  getters.openLinks,
  (navigation, routes, params, eventDetails, canDo, eventId, openLinks) => {
    const links = navigation.links || [];

    // replace undefined values with null, so we can match more accurately
    const paramsWithStubs = R.map(v => (typeof v === "undefined" ? null : v), {
      recordTypeId: null,
      ...params
    });

    return R.map(
      l => ({
        ...l,
        selected: isActiveRoute(routes, paramsWithStubs, l.routes, l.params),
        open: openLinks.includes(l.id),
        options: R.map(
          o => ({
            ...o,
            selected: isActiveRoute(routes, paramsWithStubs, o.routes, o.params)
          }),
          l.options || []
        )
      }),
      links
    );
  }
);
