import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import autobind from "autobind-decorator";
import moment from "moment";
import {
  portalModules as modules,
  portalFormats as formats
} from "utils/Quill/Options";

import Panel from "components/Global-2016/Panel";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import EditPortalMessageModal from "components/Event/Settings/Modals/EditPortalMessageModal";
import DeleteItemModal from "components/Event/Settings/Modals/DeleteItemModal";

class FormTemplatePanel extends Component {
  @autobind
  onMenuSelect(event, val) {
    event.stopPropagation();
    switch (val) {
      case "edit":
        this.openModal();
        break;
      case "delete":
        this.deleteTemplate();
        break;
      default:
    }
  }

  @autobind
  deleteTemplate() {
    this.props.showModal({
      content: (
        <DeleteItemModal
          name="event message"
          done={this.props.deleteTemplate.bind(null, this.props.template.id)}
        />
      )
    });
  }

  @autobind
  openModal() {
    this.props.showModal({
      content: (
        <EditPortalMessageModal
          done={this.props.editTemplate}
          template={this.props.template}
          deleteTemplate={this.deleteTemplate}
          wysiwygProps={{ formats, modules }}
        />
      )
    });
  }

  render() {
    const { template } = this.props;
    const notice =
      template.created_at === template.updated_at ? (
        <span>Created {moment(template.created_at).fromNow()}</span>
      ) : (
        <span>Updated {moment(template.updated_at).fromNow()}</span>
      );
    return (
      <Panel style={css.panel}>
        <div styleName="body" onClick={this.openModal}>
          <div styleName="top">
            <span styleName="title">{template.title} —&nbsp;</span>
            <span styleName="summary">{template.content_plaintext}</span>
          </div>
          <div styleName="bottom">{notice}</div>
        </div>
        <div styleName="menu">
          <IconMenu
            onChange={this.onMenuSelect}
            iconButtonElement={
              <i className={[css.icon, "material-icons"].join(" ")}>
                more_vert
              </i>
            }
          >
            <MenuItem value="edit" primaryText="Open" />
            <MenuItem value="delete" primaryText="Delete" />
          </IconMenu>
        </div>
      </Panel>
    );
  }
}

FormTemplatePanel.propTypes = {
  showModal: PropTypes.func.isRequired,
  editTemplate: PropTypes.func.isRequired,
  deleteTemplate: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
  duplicate: PropTypes.func.isRequired
};

export default CSSModules(FormTemplatePanel, css);
