import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import { getters as PageOrdersGetters } from "Portal/PortalPageOrders/model";
import { withRouter } from "react-router";
import * as R from "ramda";

import {
  Div,
  CheckCircleIcon,
  SuperFilledButton,
  SuperOutlineButton
} from "components/Base";

const decorate = R.compose(
  connect(
    (state, props) => ({
      requestId: getters.requestId(state, props),
      message: PageOrdersGetters.confirmationPageMessage(state, props)
    }),
    {
      init: actions.init
    }
  ),
  withRouter
);

const Layout = ({ router: { push, params }, requestId, message }) => (
  <Div
    display="column.flex-start.flex-start"
    bg="#FFFFFF"
    style={{ borderRadius: 6, maxWidth: 1000, width: "100%" }}
  >
    <Div
      width={1}
      display="row.center.center"
      style={{
        padding: 22,
        position: "relative",
        borderBottom: "1px solid #e2e2e2",
        marginBottom: 40
      }}
    >
      <Div color="#424242" style={{ fontSize: 25, fontWeight: "bold" }}>
        Confirmation
      </Div>
    </Div>
    <Div width={1} style={{ padding: 22 }}>
      <Div style={{ marginBottom: 34 }} display="column.flex-start.center">
        <Div style={{ marginBottom: 31 }}>
          <CheckCircleIcon color="success5" size={72} mr={2} />
        </Div>
        <Div uppercase color="#9F9F9F" style={{ marginBottom: 15 }}>
          Request SUBMITTED (#{requestId})
        </Div>
        <Div
          color="#424242"
          textAlign="center"
          style={{
            fontSize: "20px",
            maxWidth: "570px"
          }}
        >
          <span
            className="lennd-wysiwyg-content"
            dangerouslySetInnerHTML={{
              __html: message
            }}
          />
        </Div>
      </Div>

      <Div display="column.flex-start.center">
        <SuperFilledButton
          color="#161616"
          bg="#F4D288"
          onClick={() =>
            push(`/portals/${params.portalSlug}/${params.portalId}/reports`)
          }
          style={{ marginBottom: 15 }}
        >
          View your requests
        </SuperFilledButton>
        <SuperOutlineButton
          onClick={() =>
            push(
              `/portals/${params.portalSlug}/${params.portalId}/pages/${params.pageId}/`
            )
          }
        >
          Select more items
        </SuperOutlineButton>
      </Div>
    </Div>
  </Div>
);

export default decorate(Layout);
