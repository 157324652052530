import React, { Component } from "react";
import getValue from "utils/value-types/get-value/event-list";
import * as R from "ramda";
import { isEqual } from "lodash";
import QuestionDetails from "Items/QuestionsDetails/View";

export default class OrderItemDetailsFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  render() {
    const value = getValue(this.props.value);
    return value && value.text && value.text.length ? (
      <QuestionDetails
        pl={2}
        fs={2}
        truncate
        recordIds={R.pathOr([], ["recordIds"], value)}
      >
        {value.text}
      </QuestionDetails>
    ) : null;
  }
}
