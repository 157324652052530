import React from "react";
import { connect } from "react-redux";

import { getters, actions } from "Orders/ImportModal";

import {
  getFieldsBySection,
  getEnabledFieldsBySection
} from "Orders/ImportModal/selectors";

import MiniModalWrapper from "Orders/Common/View/MiniModalWrapper";
import SelectFields from "ui-kit/SelectFields/View";

import { noop } from "utils/General";

const decorate = connect(
  state => ({
    fields: getFieldsBySection(state, {
      sectionId: getters.selectedGroupId(state)
    }),
    iniSelectedFields: getEnabledFieldsBySection(state, {
      sectionId: getters.selectedGroupId(state)
    }).map(f => f.id)
  }),
  {
    hideModal: () => actions.hideSelectMoreModal(),
    onDone: actions.updateEnabledFields,
    onClose: () => actions.hideSelectMoreModal()
  }
);

const SelectFieldsModal = ({
  fields = [],
  iniSelectedFields = [],
  onClose = noop,
  onDone = noop
}) => (
  <MiniModalWrapper
    showModal
    title="Select more fields to map CSV data into"
    hideModal={() => onClose()}
    width={865}
    style={{
      height: "95%"
    }}
  >
    {React.createElement(SelectFields, {
      fields: fields,
      iniSelected: iniSelectedFields,
      iniClearSearch: true,
      onCancel: onClose,
      onDone: onDone
    })}
  </MiniModalWrapper>
);

export default decorate(SelectFieldsModal);
