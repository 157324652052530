import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";
import { get } from "lodash";
import * as SYSTEM_FIELD_IDS from "utils/system-field-ids";

import { showModal, hideModal } from "redux/modules/modal/actions";
import { references } from "redux/modules/entityReferences/selectors";

const { ID } = SYSTEM_FIELD_IDS;

function mapStateToProps(state, props) {
  const referencesToPass = references(state);
  const fieldId = get(props, "rowMetaData.meta.columnId");
  const moduleId = get(props, "rowMetaData.meta.moduleId");
  const linkedModuleId = get(props, "rowMetaData.meta.columnSettings.moduleId");
  const linkedFieldId = get(
    props,
    "rowMetaData.meta.columnSettings.fieldId",
    ID
  );
  const linkedModule = get(referencesToPass, linkedModuleId, {});

  return {
    moduleId,
    fieldId,
    linkedModuleId,
    linkedFieldId,
    linkedModule
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal,
      hideModal
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Controller);
