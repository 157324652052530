import * as R from "ramda";
import { connect } from "react-redux";

import { PASS_MODAL_ID } from "Passes/Common/constants";
import TypeSelectButton from "ui-kit/TypeSelectButton";

import { getPassFiltersCount } from "Passes/Confirmations/selectors";

import * as MiniItemsSelectors from "ui-kit/MiniItemsSelector/selectors";

import { actions } from "Passes/Confirmations";

const decorate = R.compose(
  connect(
    (state, props) => ({
      selectedCount: MiniItemsSelectors.getSelectedCount(state, {
        instanceId: PASS_MODAL_ID
      }),
      count: getPassFiltersCount(state, props),
      title: "Pass Types"
    }),
    {
      showModal: () => actions.setShowPassModal(true)
    }
  )
);

export default decorate(TypeSelectButton);
