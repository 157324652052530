import React from "react";
import { connect } from "react-redux";

import { GROUP_FILTER_ID } from "../constants";

import { getGroupOptions } from "../selectors";

import { Div, Text2 } from "components/Base";

import SecondarySidebar from "ui-kit/SecondarySidebar";
import { RadioFilter } from "ui-kit/FilterControls/View";

const decorate = connect((state, props) => ({
  options: getGroupOptions(state, props)
}));

const Sidebar = ({ groupOptions = [], ...styleProps }) => {
  return (
    <SecondarySidebar
      display="column.flex-start.stretch"
      style={{ flexShrink: 0, zIndex: 10 }}
    >
      <Div px={3} pb={2} display="column.flex-start.stretch" mt={2}>
        <RadioFilter
          options={groupOptions}
          filtersOn
          instanceId={GROUP_FILTER_ID}
          {...styleProps}
        >
          <Text2 bold>Group Type</Text2>
        </RadioFilter>
      </Div>
    </SecondarySidebar>
  );
};

export default decorate(Sidebar);
