import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actions, getters } from "../model";
import { Div } from "components/Base";
import { withRouter } from "react-router";

import Cart from "Portal/Cart/View";
import Confirmation from "Portal/Confirmation/View";
import Checkout from "Portal/Checkout/View";
import { getters as cartGetters } from "Portal/Cart/model";
import { totalValue } from "Portal/Cart/selectors";

import * as R from "ramda";
import Body from "./Body";

const decorate = R.compose(
  connect(
    (state, props) => ({
      items: cartGetters.items(state, props),
      total: totalValue(state, props),
      fields: getters.fields(state, props),
      paymentsEnabled: getters.paymentsEnabled(state, props)
    }),
    { init: actions.init }
  ),
  withRouter
);

const Layout = ({
  init,
  location,
  items,
  total,
  fields,
  paymentsEnabled,
  params
}) => {
  useEffect(() => {
    init({
      recordTypeId: params.recordTypeId
    });
  }, []);

  let view = <Body />;

  if (location.pathname.includes("/cart")) {
    view = <Cart items={items} total={total} />;
  } else if (location.pathname.includes("/checkout")) {
    view = (
      <Div
        display="row.flex-start.flex-start"
        style={{
          maxWidth: "900px",
          margin: "0 auto"
        }}
      >
        <Checkout
          items={items}
          total={total}
          fields={fields}
          paymentsEnabled={paymentsEnabled}
        />
        <Div
          style={{
            width: "300px",
            marginLeft: "15px",
            position: "sticky",
            top: "0"
          }}
        >
          <Cart small={true} />
        </Div>
      </Div>
    );
  } else if (location.pathname.includes("/confirmation")) {
    view = <Confirmation />;
  }

  return (
    <Div
      bg="#f1f3f6"
      style={{ height: "100%", padding: "20px 40px" }}
      display="column.flex-start.center"
    >
      {view}
    </Div>
  );
};

export default decorate(Layout);
