import { createSelector } from "reselect";
import * as R from "ramda";
import { getters } from "./model";
import { FILTER_IDS, SUMMARY_TAB_IDS, AVAILABLE_SECTIONS } from "./constants";
import { portalUser as getPortalUser } from "redux/modules/portal/user/selectors";
import { LABELS } from "utils/item-types";

export const getPeople = createSelector(
  getters.data,
  getters.fieldsOfSections,
  getters.collapsableIds,
  getters.openedRequestTabs,
  getters.selectedPeopleIds,
  (
    data,
    fieldsOfSections,
    collapsableIds,
    openedRequestTabs,
    selectedPeopleIds
  ) =>
    R.map(person => {
      const requestTypesWithAllReq = R.map(requestType => {
        return {
          id: requestType.item_type_id,
          name: requestType.item_type_name,
          icon: R.path([requestType.item_type_id, "icon"], LABELS),
          groups: requestType.groups
        };
      }, R.path(["sections", AVAILABLE_SECTIONS.REQUESTS, "data"], person));

      return {
        ...person,
        selected: R.any(id => id === person.id, selectedPeopleIds),
        opened: collapsableIds[person.id],
        sections: {
          ...person.sections,
          [AVAILABLE_SECTIONS.REQUESTS]: {
            title: R.pathOr(
              "",
              ["sections", AVAILABLE_SECTIONS.REQUESTS, "title"],
              person
            ),
            types: requestTypesWithAllReq
          }
        }
      };
    }, R.propOr([], "people", data))
);

export const getPagination = createSelector(
  getters.data,
  R.propOr(
    {
      page: 1,
      offset: 10,
      total: 0
    },
    "pagination"
  )
);

export const getIsPeopleSelected = createSelector(
  getters.selectedPeopleIds,
  selectedPeopleIds => !R.isEmpty(selectedPeopleIds)
);

export const getFilters = R.always([
  {
    id: FILTER_IDS.ALL,
    name: "View all"
  },
  {
    id: FILTER_IDS.WiTH_ITEMS,
    name: "Has items"
  },
  {
    id: FILTER_IDS.NO_ITEMS,
    name: "Does not have items"
  }
]);

export const accountId = createSelector(
  getPortalUser,
  portalUser =>
    R.pathEq(["active_view", "type"], "account")(portalUser)
      ? R.path(["active_view", "id"], portalUser)
      : null
);

export const getSummaryTabs = createSelector(
  getters.activeSummaryTab,
  activeTab => [
    {
      id: SUMMARY_TAB_IDS.DUE_DATES,
      name: "Due Dates",
      icon: "date_range",
      isActive: activeTab === SUMMARY_TAB_IDS.DUE_DATES
    },
    {
      id: SUMMARY_TAB_IDS.SUMMARY,
      name: "Summary",
      icon: "bar_chart",
      isActive: activeTab === SUMMARY_TAB_IDS.SUMMARY
    }
  ]
);

export const getSummaryActiveContent = createSelector(
  getters.activeSummaryTab,
  getters.requestSummary,
  getters.allDueDates,
  (activeTab, requestSummary, allDueDates) =>
    R.prop(activeTab, {
      [SUMMARY_TAB_IDS.DUE_DATES]: allDueDates,
      [SUMMARY_TAB_IDS.SUMMARY]: requestSummary
    })
);
