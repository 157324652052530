import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Label = ({ children, ...props }) => (
  <div styleName="label" {...props}>
    {children}
  </div>
);

Label.propTypes = {
  children: PropTypes.node.isRequired
};

export default CSSModules(Label, css);
