import React from "react";

import { connect } from "react-redux";

import * as R from "ramda";

import { actions, getters } from "../model";

import { getFilteredQuestions, getIsAllSelectedQuestions } from "../selectors";

import { noop, addS, capitalizeFirst } from "utils/General";

import {
  collapsableHandler,
  Div,
  Collapsable,
  SmallCheckbox,
  Text1,
  Text2,
  Text3,
  MediumOutlineButton,
  DownIcon,
  RightIcon,
  MoneyIcon
} from "components/Base";

import ItemQuestionsActions from "./ItemQuestionsActions";
import Tooltip from "components/Global/Tooltip";

const ROW = {
  HEIGHT: 46
};

const decorate = connect(
  state => ({
    questions: getFilteredQuestions(state),
    itemQuestionsIds: getters.itemQuestionsIds(state),
    isAllSelectedQuestions: getIsAllSelectedQuestions(state)
  }),
  {
    toggleItemQuestion: actions.toggleItemQuestion,
    toggleAllQuestions: actions.toggleAllQuestions
  }
);

const iconProps = {
  size: 24,
  color: "black"
};

const Header = ({ toggleAllQuestions, isAllSelectedQuestions }) => (
  <Div display="row.space-between.center" height={ROW.HEIGHT} width={1} px={2}>
    <Div display="row.flex-start.center" style={{ width: "35%" }}>
      {/*<SmallCheckbox
        onClick={() => toggleAllQuestions()}
        selected={IsAllSelectedQuestions}
      />*/}
      <Text1 bold color="black" ml={1}>
        Name
      </Text1>
    </Div>
    <Div style={{ width: "20%" }}>
      <Text1 bold color="black">
        Type of Question
      </Text1>
    </Div>
    <Div style={{ width: "25%" }}>
      <Text1 bold color="black">
        Used in
      </Text1>
    </Div>
    <Div style={{ width: "15%" }}>
      <Text1 bold color="black">
        Actions
      </Text1>
    </Div>
  </Div>
);

const Row = ({
  id,
  name,
  type,
  count_of_question_sets,
  count_of_items,
  has_price,
  is_internal,
  showAddItemModal,
  isEnabled,
  toggleItemQuestion,
  showEditQuestionModal,
  showDeleteQuestionModal,
  showAssignQuestionToItemsModal,
  showManageQuestionSetsModal
}) => (
  <Div
    display="row.space-between.center"
    height={ROW.HEIGHT}
    width={1}
    px={2}
    bt={1}
    bc="neutral1"
  >
    <Div display="row.flex-start.center" style={{ width: "35%" }}>
      {/*<SmallCheckbox
        selected={isEnabled}
        onClick={() => toggleItemQuestion({ id, isEnabled })}
      />*/}
      <Text3
        // bold
        // color="primary7"
        ml={1}
        // onClick={() =>
        //   showBulkQuestionsModal({
        //     selectedQuestionIds: [id]
        //   })
        // }
        truncate
      >
        {name}
      </Text3>
      {has_price && <MoneyIcon color="black" />}
      {is_internal && (
        <Tooltip tooltip="Internal Question" placement="top">
          <span
            className="material-icons"
            style={{
              fontSize: 16,
              cursor: "pointer",
              color: "#888",
              marginLeft: 8
            }}
          >
            visibility_off
          </span>
        </Tooltip>
      )}
    </Div>
    <Div style={{ width: "20%" }}>
      <Text3>{capitalizeFirst(type)}</Text3>
    </Div>
    <Div style={{ width: "25%" }} display="row.flex-start.center">
      <Div display="row.flex-start.center">
        <Text3 underline color="neutral7" onClick={showManageQuestionSetsModal}>
          {`${count_of_question_sets} set${addS(count_of_question_sets)}`}
        </Text3>
        <Text3 mx={1}>/</Text3>
        <Text3
          underline
          color="neutral7"
          onClick={showAssignQuestionToItemsModal}
        >
          {`${count_of_items} item${addS(count_of_items)}`}
        </Text3>
      </Div>
    </Div>
    <Div style={{ width: "15%" }} display="row">
      <MediumOutlineButton onClick={showAssignQuestionToItemsModal}>
        Assign
      </MediumOutlineButton>
      <ItemQuestionsActions
        onEdit={showEditQuestionModal}
        onDelete={showDeleteQuestionModal}
        showManageQuestionSetsModal={showManageQuestionSetsModal}
        showAssignQuestionToItemsModal={showAssignQuestionToItemsModal}
      />
    </Div>
  </Div>
);

const QuestionsCollapsable = collapsableHandler(
  ({
    toggleCollapsed,
    collapsed,
    questions,
    itemQuestionsIds,
    toggleItemQuestion,
    toggleAllQuestions,
    IsAllSelectedQuestions,
    handlers
  }) => (
    <Div>
      <Div
        onClick={toggleCollapsed}
        fs={4}
        fw={3}
        height={ROW.HEIGHT}
        bg="white"
        display="row.space-between.center"
        pr={1}
      >
        <Div display="row.flex-start.center">
          {collapsed ? (
            <RightIcon {...iconProps} />
          ) : (
            <DownIcon {...iconProps} />
          )}
          <Div color="black">Item Questions</Div>
        </Div>
      </Div>
      <Collapsable collapsed={collapsed}>
        <Div bg="white" shadow={1}>
          <Header
            toggleAllQuestions={toggleAllQuestions}
            IsAllSelectedQuestions={IsAllSelectedQuestions}
          />
          <Div>
            {R.map(
              question => (
                <Row
                  {...question}
                  key={question.id}
                  showEditQuestionModal={() =>
                    handlers.showEditQuestionModal(question.id)
                  }
                  showManageQuestionSetsModal={() =>
                    handlers.showEditQuestionModal(question.id, true)
                  }
                  showDeleteQuestionModal={() =>
                    handlers.showDeleteQuestionModal(question.id)
                  }
                  showAssignQuestionToItemsModal={() =>
                    handlers.showAssignQuestionToItemsModal({
                      questionIds: [question.id]
                    })
                  }
                  isEnabled={R.any(id => id === question.id, itemQuestionsIds)}
                  toggleItemQuestion={toggleItemQuestion}
                />
              ),
              questions
            )}
          </Div>
        </Div>
      </Collapsable>
    </Div>
  )
);

const ItemQuestions = props => <QuestionsCollapsable {...props} />;

export default decorate(ItemQuestions);
