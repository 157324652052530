import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { Div } from "components/Base";
import EditText from "./EditText/view";
import * as R from "ramda";
import { connect } from "react-redux";
import { createContext } from "redux-mvc";
import module from "./index";
import { noop } from "utils/General";
const decorate = R.compose(
  createContext({
    module
  }),
  connect(
    null,
    {}
  ),
  CSSModules(css)
);

const LabelSimpleForm = ({
  children = "",
  description = null,
  required = false,
  style = null,
  instanceId = null,
  fieldData = null,
  isShown = false,
  setIsShown = noop
}) => {
  return (
    <label styleName="label" style={style}>
      <Div display="row.flex-start.center">
        <EditText
          instanceId={instanceId + "-children"}
          fieldData={fieldData}
          type="name"
          isShown={isShown}
          setIsShown={setIsShown}
        >
          {children}
        </EditText>
        <span styleName="required">{required ? "*" : ""}</span>
      </Div>
      <EditText
        instanceId={instanceId + "-description"}
        fieldData={fieldData}
        type="description"
        isShown={isShown}
        setIsShown={setIsShown}
      >
        {description}
      </EditText>
    </label>
  );
};

LabelSimpleForm.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  required: PropTypes.bool,
  isAdminField: PropTypes.bool,
  description: PropTypes.string
};

export default decorate(LabelSimpleForm);
