import Dashboard from "Catering/Dashboard/View";
import Main from "Catering/Main/View";
import Manage from "Catering/Manage/View";
import Checkin from "Catering/Checkin";

import { hot } from "App/Config/hot";

const Catering = {
  Dashboard,
  Main,
  Manage,
  Checkin
};

export default hot(Catering);
