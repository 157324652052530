import * as R from "ramda";

export const getValue = (props, key, def) => {
  const column = R.propOr({}, "field", props);
  const val = R.path(["data", column.id, "value"], props);

  if (typeof key === "undefined" || key === R.identity) {
    if (typeof def !== "undefined") {
      return typeof val !== "undefined" ? val : def;
    }
    return val;
  }

  if (typeof def === "undefined") {
    if (Array.isArray(key)) {
      return R.path(key, val);
    }
    return R.prop(key, val);
  }
  if (Array.isArray(key)) {
    return R.pathOr(def, key, val);
  }
  return R.propOr(def, key, val);
};

export const getField = (props, key, def) => {
  const val = R.propOr({}, "field", props);

  if (typeof key === "undefined" || key === R.identity) {
    if (typeof def !== "undefined") {
      return val || def;
    }
    return val;
  }

  if (typeof def === "undefined") {
    if (Array.isArray(key)) {
      return R.path(key, val);
    }
    return R.prop(key, val);
  }
  if (Array.isArray(key)) {
    return R.pathOr(def, key, val);
  }
  return R.propOr(def, key, val);
};

export const getSettings = (props, key, def) => {
  const val = R.pathOr({}, ["field", "settings"], props);

  if (typeof key === "undefined" || key === R.identity) {
    if (typeof def !== "undefined") {
      return val || def;
    }
    return val;
  }

  if (typeof def === "undefined") {
    if (Array.isArray(key)) {
      return R.path(key, val);
    }
    return R.prop(key, val);
  }
  if (Array.isArray(key)) {
    return R.pathOr(def, key, val);
  }
  return R.propOr(def, key, val);
};
