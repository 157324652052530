import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "Organizations/Home/constants";
import { TABS, EVENT_TABS, EVENT_OPTIONS } from "./constants";
import moment from "moment";

const iniState = {
  loading: false,
  loadingEvents: false,
  isCloningEvent: false,
  tasks: {
    count_of_assigned_to_me: 0,
    count_of_due: 0,
    assigned_to_me: [],
    completed: [],
    due: []
  },
  selectedTab: TABS.DUE,
  eventsSelectedTab: EVENT_TABS.UPCOMING,
  eventSelectedOption: EVENT_OPTIONS.LIST,
  eventList: [],
  currentYear: moment().year()
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    handleAddEventModal: R.identity,
    deleteEvent: R.identity,
    cloneEvent: R.identity,
    viewEvent: R.identity,
    editEvent: R.identity,
    goToMyTasks: R.identity,
    goToSchedule: R.identity,
    viewTask: R.identity,
    handleShowAddRecordModal: R.identity,
    toggleTask: (state, { payload: { task, toggled } }) => {
      const updatedTask = {
        ...task,
        is_complete: toggled
      };

      return {
        tasks: toggled
          ? {
              count_of_assigned_to_me: state.tasks.count_of_assigned_to_me - 1,
              count_of_due: task.is_due
                ? state.tasks.count_of_due - 1
                : state.tasks.count_of_due,
              assigned_to_me: R.filter(t => t.id !== task.id)(
                state.tasks.assigned_to_me
              ),
              completed: [...state.tasks.completed, updatedTask],
              due: R.filter(t => t.id !== task.id)(state.tasks.due)
            }
          : {
              count_of_assigned_to_me: state.tasks.count_of_assigned_to_me + 1,
              count_of_due: task.is_due
                ? state.tasks.count_of_due + 1
                : state.tasks.count_of_due,
              assigned_to_me: [...state.tasks.assigned_to_me, updatedTask],
              completed: R.filter(t => t.id !== task.id)(state.tasks.completed),
              due: task.is_due
                ? [...state.tasks.due, updatedTask]
                : state.tasks.due
            }
      };
    },
    goToAllEvents: R.identity,
    goToAllEventsCalendar: R.identity,
    goToEvent: R.identity,
    setInitialData: (state, { payload: { tasks, eventList } }) => ({
      tasks,
      eventList
    }),
    changeYear: ({ currentYear }, { payload }) => ({
      currentYear: currentYear + payload
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
