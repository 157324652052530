import React from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import "react-table-hoc-fixed-columns/lib/styles.css";
import {
  Div,
  Text2,
  MediumFilledButton,
  AddIcon,
  SearchIcon,
  BigFilledInput
} from "components/Base";
import { getters, actions } from "ui-kit/AccessGridTable/model";
import Table from "./Table";

const decorate = connect(
  (state, props) => ({
    passFilter: getters.passFilter(state, props)
  }),
  bindInstance({
    setPassFilter: actions.setPassFilter
  })
);

const AccessGridTable = ({
  passFilter,
  setPassFilter,
  handlers,
  tableStyle = {
    width: "100%",
    height: "100%"
  }
}) => (
  <Div>
    <Div>
      <BigFilledInput
        RightIcon={SearchIcon}
        width={810}
        bg="neutral1"
        color="neutral5"
        placeholder="Search pass name..."
        value={passFilter}
        onChange={setPassFilter}
        continuous
      />
      <Div display="row.flex-start" mt={2}>
        <Table style={tableStyle} />
        <Div height={50} display="row.flex-start.center" ml={2}>
          <MediumFilledButton
            bg="primary7"
            onClick={() => handlers.addZone({})}
          >
            <AddIcon color="white" size={24} />
          </MediumFilledButton>
          <Text2 color="black" ml={1} bold>
            Zone
          </Text2>
        </Div>
      </Div>
      <Div height={50} display="row.flex-start.center" mt={2}>
        <MediumFilledButton
          bg="primary7"
          onClick={() => handlers.addUpdateItem({})}
        >
          <AddIcon color="white" size={24} />
        </MediumFilledButton>
        <Text2 color="black" ml={1} bold>
          Pass Type
        </Text2>
      </Div>
    </Div>
  </Div>
);

export default decorate(AccessGridTable);
