import React from "react";
import { connect } from "react-redux";
import { BigFilledButton, LeftArrowIcon } from "components/Base";
import { actions } from "HealthPass/HealthPassForm/model";
import { getShowBackButton } from "HealthPass/HealthPassForm/selectors";

const decorate = connect(
  state => ({
    showButton: getShowBackButton(state)
  }),
  {
    backPage: actions.backPage
  }
);

const BackButton = ({ backPage, showButton }) =>
  showButton ? (
    <BigFilledButton
      color="neutral7"
      LeftIcon={LeftArrowIcon}
      bg="neutral2"
      style={{ borderRadius: "20px" }}
      onClick={backPage}
    >
      Back
    </BigFilledButton>
  ) : null;

export default decorate(BackButton);
