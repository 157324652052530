import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { noop } from "utils/General";
import {
  TinyToggle,
  SmallToggle,
  MediumToggle,
  BigToggle,
  SuperToggle
} from "components/Base";

import { actions } from "ui-kit/Form/model";
import { getValue } from "ui-kit/Form/selectors";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";
import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";

const decorate = R.compose(
  WithFormInstanceConsumer({ fieldType: FIELD_TYPES.TOGGLE }),
  WithThemeConsumer({ variant: VARIANTS.PRIMARY }),
  connect(
    (state, props) => ({
      value: getValue(state, props)
    }),
    bindInstance({
      onChange: actions.setFieldValue,
      setIniValue: actions.setIniValue
    })
  )
);

const toggles = {
  tiny: TinyToggle,
  small: SmallToggle,
  medium: MediumToggle,
  big: BigToggle,
  super: SuperToggle
};

export const Toggle = decorate(
  ({
    value = false,
    iniValue,
    onChange = noop,
    setIniValue = noop,
    fieldId,
    fieldType,
    size = "small",
    Th = defaultTh,
    disabled = false,
    ...styleProps
  }) => {
    useEffect(() => {
      setIniValue(iniValue, {
        meta: { fieldType, fieldId }
      });
    }, []);
    return React.createElement(toggles[size] || TinyToggle, {
      active: value,
      onClick: disabled
        ? noop
        : () => onChange(!value, { meta: { fieldId, fieldType } }),
      ...Th(["bg"], styleProps)
    });
  }
);
