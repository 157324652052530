import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { getDeliveryMethods, hasAlreadyBeenSent } from "../selectors";
import { getters } from "../model";
import { FIELDS_IDS } from "../constants";

import { Text1, CalendarIcon, Div } from "components/Base";

import { Toggle } from "ui-kit/Form/View";
import Container from "./Container";

const decorate = connect(
  (state, props) => ({
    data: getters.data(state, props),
    deliveryMethodSelected: getDeliveryMethods(state, props),
    hasAlreadyBeenSent: hasAlreadyBeenSent(state, props)
  }),
  null
);

const DeliveryMethods = ({
  deliveryMethodSelected,
  data,
  hasAlreadyBeenSent
}) =>
  hasAlreadyBeenSent ? null : (
    <Container
      title="Delivery methods"
      subtitle={deliveryMethodSelected}
      Icon={CalendarIcon}
    >
      <Div display="row.flex-start.center" mb={2}>
        <Toggle
          size="tiny"
          fieldId={FIELDS_IDS.DELIVERY.PORTAL_NOTIFICATION}
          iniValue={R.prop(FIELDS_IDS.DELIVERY.PORTAL_NOTIFICATION, data)}
          bg="green6"
        />
        <Text1 color="neutral9" ml={1}>
          Show on Portal
        </Text1>
      </Div>

      <Div display="row.flex-start.center" mb={2}>
        <Toggle
          size="tiny"
          fieldId={FIELDS_IDS.DELIVERY.EMAIL}
          bg="green6"
          iniValue={R.prop(FIELDS_IDS.DELIVERY.EMAIL, data)}
        />
        <Text1 color="neutral9" ml={1}>
          Email Alert
        </Text1>
      </Div>

      <Div display="row.flex-start.center" mb={2}>
        <Toggle
          size="tiny"
          fieldId={FIELDS_IDS.DELIVERY.SMS}
          bg="green6"
          iniValue={R.prop(FIELDS_IDS.DELIVERY.SMS, data)}
        />
        <Div display="row.space-between.center" width={1} ml={1}>
          <Text1 color="neutral9">SMS Text Alert*</Text1>
          <Text1 color="neutral6">
            User must have valid cell phone number & be opted in
          </Text1>
        </Div>
      </Div>
    </Container>
  );

export default decorate(DeliveryMethods);
