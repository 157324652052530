import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "HealthPass/HealthPassStatus/constants";

const iniState = {
  loading: true,
  notFound: false,
  linkId: null,
  status: null,
  name: null,
  group: null,
  backgroundImageUrl: null,
  title: null,
  date: null,
  expired: false,
  expiresAt: null,
  confirmationMessage: null
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    seedFormData: (_, { payload: { formPayload } }) => ({
      status: formPayload.status,
      name: formPayload.contact_name,
      group: formPayload.group_name,
      expires: formPayload.expires_at,
      title: formPayload.title,
      backgroundImageUrl: formPayload.background_image_url,
      date: formPayload.date,
      expired: formPayload.expired,
      expiresAt: formPayload.expires_at,
      confirmationMessage: formPayload.confirmation_message
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
