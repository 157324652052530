import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import { get } from "lodash";
import moment from "moment";
import Helpers from "utils/Global/Helpers";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

// TODO: Global localizer kind of sucks.
import momentLocalizer from "react-widgets-moment";
import Flatpickr from "react-flatpickr";

const FORMAT = "YYYY-MM-DD";
const DISPLAY_FORMAT = "LL";

@CSSModules(css)
class FormDate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.formatValue(props.value)
    };
  }

  componentWillUpdate(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        value: this.formatValue(nextProps.value)
      });
    }
  }

  componentWillMount() {
    moment.locale(Helpers.getLocale());
    momentLocalizer(moment);
  }

  formatValue(value) {
    return get(value, "value") && moment(value.value).isValid()
      ? moment(value.value).toDate()
      : null;
  }

  wrapValue(value) {
    return {
      type: "date",
      value:
        value && moment(value).isValid()
          ? moment(value).format(FORMAT)
          : undefined
    };
  }

  @autobind
  onChange(value) {
    this.props.onChange(this.wrapValue(value));
  }

  parse(d) {
    if (!d) {
      return null;
    }
    let date;
    moment.locale(Helpers.getLocale());

    // Try to use the date constructor
    date = new Date(d);
    if (moment(date).isValid()) {
      return date;
    }

    // Case where date constructor can't handle it, attempt to parse with the foramt
    date = moment(d, DISPLAY_FORMAT);
    if (moment(date).isValid()) {
      return date;
    }

    return null;
  }

  render() {
    const { disabled, placeholder } = this.props;

    return (
      <div
        className={css.container}
        onBlur={event => {
          if (!event.target.className.includes("dateTimeInput")) {
            event.stopPropagation();
          }
        }}
      >
        <Flatpickr
          className={css.dateTimeInput}
          disabled={disabled}
          options={{
            altInput: true,
            enableTime: false,
            noCalendar: false,
            static: true
          }}
          value={this.state.value}
          onChange={val => this.onChange(val[0])}
          onClose={val => this.onChange(val[0])}
          placeholder={placeholder}
        />
      </div>
    );
  }
}

FormDate.propTypes = {
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object
};

export default FormDate;
