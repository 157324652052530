import React from "react";
import { Div } from "components/Base";

const Message = ({ children }) => (
  <Div
    fs={2}
    color="#121111"
    style={{ marginBottom: 3 }}
    dangerouslySetInnerHTML={{
      __html: children
    }}
  />
);

export default Message;
