import Helpers from "utils/Global/Helpers";

module.exports = {
  getOrderIdByOrderNumber: (credentials, { eventId, orderNumber }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/orders/order-id/event/${eventId}/${orderNumber}`,
        credentials,
        success,
        error
      });
    }),
  /* eslint-enable no-underscore-dangle */

  getOrdersByEvent: (credentials, { eventId, typeId, sourceId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/orders/orders/event/${eventId}`,
        qs: { typeId, sourceId },
        credentials,
        success,
        error
      });
    }),
  /* eslint-enable no-underscore-dangle */

  getOrder: (credentials, orderId) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/orders/orders/${orderId}`,
        credentials,
        success,
        error
      });
    }),
  /* eslint-enable no-underscore-dangle */

  addOrder: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/orders/orders`,
        credentials,
        data,
        success,
        error
      });
    }),
  /* eslint-enable no-underscore-dangle */

  updateOrder: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/orders/orders/${data.orderId}`,
        credentials,
        data,
        success,
        error
      });
    }),
  /* eslint-enable no-underscore-dangle */

  bulkUpdateOrders: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/orders/orders/bulk-update`,
        credentials,
        data,
        success,
        error
      });
    }),
  /* eslint-enable no-underscore-dangle */

  deleteOrder: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/orders/orders/${data.orderId}`,
        credentials,
        data,
        success,
        error
      });
    })
  /* eslint-enable no-underscore-dangle */
};
