import { createSelector } from "reselect";
import * as R from "ramda";

import { getters } from "ui-kit/SelectRows";

export const getAllSelected = createSelector(
  getters.selectedRows,
  (_, props) => R.propOr([], "rows", props),
  (selectedRows, rows = []) => R.equals(R.length(selectedRows), R.length(rows))
);

export const getIsRowSelected = (state, props) =>
  R.contains(R.propOr("", "id", props), getters.selectedRows(state, props));
