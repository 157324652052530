import React from "react";
import { connect } from "react-redux";
import {
  getItemsBtnText,
  getIsBtnDisabled
} from "Event/AddBulkItemsModal/selectors";
import { getters as itemCatalogItemsGetters } from "Event/ItemCatalog";
import { actions } from "Event/AddBulkItemsModal/model";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import {
  Div,
  BigFilledButton,
  BigOutlineButton,
  RightArrowIcon
} from "components/Base";
import Body from "./Body";

const decorate = connect(
  state => ({
    btnText: getItemsBtnText(state),
    isBtnDisabled: getIsBtnDisabled(state),
    isZones: itemCatalogItemsGetters.isZones(state)
  }),
  {
    saveBulkItems: actions.saveBulkItems
  }
);

const Layout = ({
  hideModal,
  btnText,
  isBtnDisabled,
  saveBulkItems,
  isZones
}) => (
  <MiniModalWrapper
    hideModal={hideModal}
    title={`Create Multiple ${isZones ? "Zones" : "Items"}`}
    isPortal={false}
    showModal
    style={{ width: 550 }}
  >
    <Body />
    <Div
      height={70}
      bg="white"
      width={1}
      display="row.space-between.center"
      px={5}
      bt={1}
      bc="neutral2"
    >
      <BigFilledButton
        bg={isBtnDisabled ? "neutral3" : "altB5"}
        color={isBtnDisabled ? "neutral6" : "white"}
        disabled={isBtnDisabled}
        RightIcon={RightArrowIcon}
        width={360}
        display="row.space-between.center"
        onClick={saveBulkItems}
      >
        {btnText}
      </BigFilledButton>
      <BigOutlineButton width={110} onClick={hideModal}>
        Cancel
      </BigOutlineButton>
    </Div>
  </MiniModalWrapper>
);

export default decorate(Layout);
