import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE, STATUS } from "./constants";

export const getDefaultOpenedMenues = () => {
  const menues = ["people", "workspaces"];
  if (window.location.pathname.includes("/passes/manage/")) {
    menues.push("manage");
    menues.push("ModuleDashboardPasses");
  }
  if (window.location.pathname.includes("/catering/manage/")) {
    menues.push("manage");
    menues.push("ModuleDashboardCatering");
  }
  if (window.location.pathname.includes("/inventory/manage/")) {
    menues.push("manage");
    menues.push("ModuleDashboardInventory");
  }
  if (window.location.pathname.includes("/booths/manage/")) {
    menues.push("manage");
    menues.push("ModuleDashboardBooths");
  }
  return menues;
};

const zeroIndexPage = R.compose(
  R.dec,
  R.pathOr(1, ["pagination", "page"])
);
const DEFAULT_PAGINATION = {
  pageSize: 25,
  pages: 0,
  page: 0
};
const DEFAULT_PREFERENCES = {
  visible_fields: [],
  field_order: {},
  field_widths: {},
  quick_filters: [],
  sort_by: [],
  page_size: null,
  contact_record_type_id: null,
  filters: {}
};

const iniState = {
  loading: true,
  selectedTab: STATUS.ALL,
  collapsed: false,
  module: {},
  recordType: {},
  recordTypes: [],
  peopleRecordTypes: [],
  moduleId: null,
  recordTypeId: null,
  contactRecordTypeId: null,
  preferences: DEFAULT_PREFERENCES,
  originalPreferences: DEFAULT_PREFERENCES,
  catalogItemsModules: [],
  openedMenues: getDefaultOpenedMenues(),
  rows: [],
  columns: [],
  showNoResults: false,
  refreshDashboards: false,
  references: {},
  groupedByField: null,
  views: [],
  tabStats: Object.values(STATUS).reduce(
    (acc, val) => ({ ...acc, [val]: 0 }),
    {}
  ),
  pagination: DEFAULT_PAGINATION,
  refreshIntervalId: 0
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    cancelInstance: R.always(iniState),
    init: R.identity,
    fetchData: R.identity,
    fetchViews: R.identity,
    exportData: R.identity,
    groupBy: R.identity,
    updateViewFields: R.identity,
    insertField: R.identity,
    hideColumn: R.identity,
    showSubscribeModal: R.identity,
    updatePeopleType: R.identity,
    approve: R.identity,
    deny: R.identity,
    setInitialData: (
      state,
      {
        payload: {
          statusList,
          module,
          recordType,
          recordTypes,
          peopleRecordTypes,
          moduleId,
          recordTypeId,
          contactRecordTypeId,
          preferences
        }
      }
    ) => ({
      statusList,
      module,
      recordType,
      recordTypes,
      peopleRecordTypes,
      moduleId,
      recordTypeId,
      contactRecordTypeId:
        contactRecordTypeId || preferences.contact_record_type_id,
      pagination: preferences.page_size
        ? {
            ...DEFAULT_PAGINATION,
            pageSize: preferences.page_size
          }
        : DEFAULT_PAGINATION,
      preferences: contactRecordTypeId
        ? {
            ...preferences,
            contact_record_type_id: contactRecordTypeId
          }
        : preferences,
      originalPreferences: preferences
    }),
    receiveList: ({ tabStats }, { payload }) => ({
      rows: payload.records || [],
      columns: payload.fields || [],
      tabStats: {
        ...tabStats,
        ...(payload.counters || {})
      },
      pagination: {
        ...payload.pagination,
        page: zeroIndexPage(payload)
      },
      showNoResults: (payload.records || []).length === 0,
      groupedByField: R.propOr("", "grouped_by", payload)
    }),
    addViewResponse: (state, { payload: newView }) => ({
      views: [...state.views, newView],
      preferences: newView,
      originalPreferences: newView
    }),
    saveViewChangesResponse: (state, { payload: newView }) => ({
      views: state.views.map(v => (v.id === newView.id ? newView : v)),
      preferences: newView,
      originalPreferences: newView
    }),
    revertViewChanges: state => ({
      preferences: state.originalPreferences
    }),
    selectActiveView: (state, { payload: activeView }) => ({
      preferences: activeView,
      originalPreferences: activeView,
      pagination: {
        ...state.pagination,
        page: 0
      }
    }),
    selectView: (state, { payload: view }) => {
      return {
        preferences: view,
        originalPreferences: view,
        pagination: {
          ...state.pagination,
          page: 0
        }
      };
    },
    setGroupedByField: ({ preferences }, { payload: groupedBy }) => ({
      preferences: {
        ...preferences,
        grouped_by:
          !R.isNil(groupedBy) && groupedBy === R.prop("grouped_by", preferences)
            ? null
            : groupedBy
      }
    }),
    setPageSize: (state, { payload }) => ({
      preferences: {
        ...state.preferences,
        page_size: payload
      },
      pagination: {
        ...state.pagination,
        pageSize: payload
      }
    }),
    setCurrentPage: ({ pagination }, { payload }) => ({
      pagination: {
        ...pagination,
        page: payload
      }
    }),
    setSelectedTab: (state, { payload: selectedTab }) => ({
      selectedTab
    }),
    toggleCollapse: ({ collapsed }) => ({ collapsed: !collapsed }),
    toggleMenu: ({ openedMenues }, { payload: menuId }) => ({
      openedMenues: R.contains(menuId, openedMenues)
        ? R.without([menuId], openedMenues)
        : R.concat([menuId], openedMenues)
    }),
    //
    setSelectedFieldFilters: (state, { payload: filters }) => ({
      preferences: {
        ...state.preferences,
        filters
      }
    }),
    removeSelectedFieldFilter: (state, { payload: idxOfFilter }) => {
      const updatedFilters = {
        filters: {
          ...state.preferences.filters.filters,
          filters: R.remove(idxOfFilter, 1)(
            state.preferences.filters.filters.filters
          )
        }
      };
      return {
        preferences: {
          ...state.preferences,
          filters: updatedFilters.filters.filters.length ? updatedFilters : {}
        }
      };
    },
    //
    setSelectedSortBy: (state, { payload: sortBy }) => ({
      preferences: {
        ...state.preferences,
        sort_by: [
          ...R.filter(f => f.fieldId !== sortBy.fieldId)(
            state.preferences.sort_by
          ),
          sortBy
        ]
      }
    }),
    removeSelectedSortBy: (state, { payload: idxOfFilter }) => {
      return {
        preferences: {
          ...state.preferences,
          sort_by: R.remove(idxOfFilter, 1)(state.preferences.sort_by)
        }
      };
    },
    //
    clearFilters: ({ preferences }) => {
      return {
        preferences: {
          ...preferences,
          sort_by: [],
          filters: {}
        }
      };
    },
    updateFieldWidths: ({ preferences }, { payload: fieldWidths }) => ({
      preferences: {
        ...preferences,
        field_widths: fieldWidths
      }
    }),
    updateVisibleFields: (
      { preferences },
      { payload: { visibleFields, fieldOrder } }
    ) => ({
      preferences: {
        ...preferences,
        visible_fields: visibleFields || preferences.visible_fields,
        field_order: fieldOrder || preferences.field_order
      }
    }),
    updateViewName: (
      { preferences, originalPreferences, views },
      { payload: { name } }
    ) => ({
      preferences: {
        ...preferences,
        name
      },
      originalPreferences: {
        ...originalPreferences,
        name
      },
      views: views.map(v => ({
        ...v,
        name: v.id === preferences.id ? name : v.name
      }))
    }),
    setFilteredPeopleView: (state, { payload }) => ({
      preferences: {
        ...state.preferences,
        contact_record_type_id: payload
      }
    }),
    toggleRefreshDashboards: state => ({
      refreshDashboards: !state.refreshDashboards
    }),
    updateIntervalId: (_, { payload: refreshIntervalId }) => ({
      refreshIntervalId
    })
  }
});

export default handlers;
