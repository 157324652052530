import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import { actions, getters } from "./index";
import Api from "./api";

import { registerError } from "redux/modules/errors/actions";

import { getCredentials } from "redux/modules/user/selectors";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";

import { actions as EventHomeActions } from "Event/Home";
import { getVisibleModulesToReference } from "Toolbar/QuickActionsNavigation/selectors";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const eventId = yield select(getEventId);

  return {
    credentials,
    orgId,
    eventId
  };
};

const init = function*() {
  /*
  // @NOTE: We'll use this when needing to fetch notifications for my approvals in nav
  try {
    yield put(actions.setLoading(true));

    yield put(actions.setInitialData({ data }));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading data"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
  */
};

const openMenu = function*() {
  yield put(actions.setLastFetched(new Date()));
  yield put(actions.setFilter(""));
  yield put(EventHomeActions.getEventRecordTypes());

  // fetch sales portal if not fetched yet and if booths enabled
  /*
  const { eventId, credentials } = yield call(getParams);
  const enabledModules = yield select(getVisibleModulesToReference);
  const salesPortal = yield select(getters.salesPortal);

  if (
    salesPortal &&
    !salesPortal.id &&
    enabledModules.some(m => m.id === STANDARD_MODULE_IDS.booths.id)
  ) {
    const { payload } = yield call(Api.getSalesPortal, {
      credentials,
      eventId
    });
    yield put(actions.setSalesPortal(payload));
  }
  */
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchOpenMenu = function*() {
  yield takeEvery(actions.openMenu.type, openMenu);
};

const rootSaga = function*() {
  yield all([fork(watchInit), fork(watchOpenMenu)]);
};

export default rootSaga;
