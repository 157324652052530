export const NAMESPACE = "InventoryDashboard";

export const COLUMNS_SIZE = {
  TYPE: 250,
  PENDING: 58,
  APPROVED: 63,
  PLANNED: 58,
  REMAINNG: 50,
  PROGRESS: 180
};

export const SUMMARY_TYPE = {
  PENDING: "PENDING",
  APPROVED: "APPROVED"
};

export const COLLAPSABLE_TYPE_TOTAL = {
  PENDING: "PENDING",
  TOTAL: "TOTAL"
};

export const COLLAPSABLE_TYPE_APPROVED = {
  APPROVED: "APPROVED",
  DENIED: "DENIED"
};
