import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Controller from "./Controller";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { addRecord } from "redux/modules/modules/records/actions";
import { addModule } from "redux/modules/modules/module/actions";
import { createFormFromTemplate } from "redux/modules/formsV2/templates/actions";

import { eventDetails } from "redux/modules/event/selectors";
import { user } from "redux/modules/user/selectors";
import { recordTypes } from "redux/modules/modules/recordTypes/selectors";

function mapStateToProps(state) {
  return {
    eventDetails: eventDetails(state),
    user: user(state),
    canViewPasses: selectFeatureFlag(flags.CAN_VIEW_PASSES.NAME)(state),
    guestLists: recordTypes(state, STANDARD_MODULES.accounts.id)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addModule,
      addRecord,
      showModal,
      hideModal,
      showSnackbar,
      createFormFromTemplate
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
