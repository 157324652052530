import React from "react";
import { connect } from "react-redux";
import { createContext } from "redux-mvc";
import * as R from "ramda";
import reports, { actions } from "../index";
import Layout from "./Layout";
import rootSaga from "../sagas";

import { hideModal, showModal } from "redux/modules/modal/actions";

reports.setRootSaga(rootSaga);

const contextConfig = {
  module: reports,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(
        actions.init({
          itemId: this.props.itemId,
          variantId: this.props.variantId,
          selectedPriceId: this.props.selectedPriceId,
          disableChangingPrice: this.props.disableChangingPrice,
          buttonLabel: this.props.buttonLabel,
          limit: this.props.limit,
          remaining: this.props.remaining,
          quantity: this.props.selectedQuantity,
          questions: this.props.questions,
          color: this.props.color || "purple6",
          showInternalQuestions: this.props.showInternalQuestions
        })
      );
    }
  },
  handlers: {
    onSave: function(data) {
      this.props.onSave(data);
      this.props.hideModal();
    },
    hideModal: function() {
      this.props.hideModal();
    }
  },
  options: {
    persist: false,
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", reports.modules))),
      R.prop("type")
    ),
    observedDomains: [
      "user",
      "event",
      "modal",
      "organization",
      "permissions",
      "formsV2"
    ]
  }
};

const decorate = R.compose(
  connect(
    null,
    {
      showModal,
      hideModal
    }
  ),
  createContext(contextConfig)
);

export default decorate(Layout);
