import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Back from "../Common/Back";
import PrimaryAction from "../Common/PrimaryAction";
import SecondaryAction from "../Common/SecondaryAction";
import ButtonContainer from "../Common/ButtonContainer";
import Label from "../Common/Label";
import Form from "../Common/Form";
import Input from "../Common/Input";
import NumberInput from "../Common/Number";
import Search from "../../Search";
import ErrorMessage from "../Common/Error";
import Container from "../Common/Container";
import constructReferenceMap from "../../utils/construct-map";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const isValid = (placeholder, count, owner) => placeholder && count && owner;
const onSubmit = event => event.preventDefault();

const getReference = (references, id) => {
  if (id) {
    return constructReferenceMap(references)[id];
  }
  return null;
};

@CSSModules(css)
export default class Group extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placeholder: props.search || "",
      count: 0,
      processing: false,
      owner: null
    };
  }

  @autobind
  onSelect(id) {
    this.setState({
      owner: id
    });
  }

  @autobind
  changeState(field, event) {
    this.setState({
      [field]: event.target.value
    });
  }

  @autobind
  changeCount(count) {
    if (count >= 0) {
      this.setState({
        count
      });
    }
  }

  @autobind
  async createGroup() {
    const { placeholder, count, owner } = this.state;
    if (!isValid(placeholder, count, owner)) return;

    this.setState({
      processing: true
    });

    try {
      await this.props.createGroup(placeholder, count, owner);
    } catch (e) {
      this.setState({
        processing: false,
        error: "Something went wrong! Please try again."
      });
    }
  }

  render() {
    const {
      changeScene,
      close,
      addReferenceDangerous,
      references
    } = this.props;
    const { placeholder, count, owner, processing, error } = this.state;
    const ownerReference = getReference(references, owner);

    return (
      <Container>
        <Back onClick={() => changeScene("list", "back")} />
        <Form onSubmit={onSubmit}>
          <div styleName="sideBySideFields">
            <div styleName="sideBySideField">
              <Label>Role/job title of group</Label>
              <Input
                styleName="input"
                value={placeholder}
                onChange={this.changeState.bind(null, "placeholder")}
                type="text"
              />
            </div>
            <div styleName="sideBySideField">
              <Label>Size of group</Label>
              <NumberInput onChange={this.changeCount} value={count} />
            </div>
          </div>
          <Label>Who’s responsible for this group?</Label>
          <Search
            types={["user"]}
            addReferenceDangerous={addReferenceDangerous}
            onSelect={this.onSelect}
            references={references}
            value={ownerReference}
          />
          <br />
          <ButtonContainer>
            <PrimaryAction
              disabled={!isValid(placeholder, count, owner)}
              processing={processing}
              onClick={this.createGroup}
            >
              Add Placeholder
            </PrimaryAction>
            <SecondaryAction onClick={close}>Cancel</SecondaryAction>
          </ButtonContainer>
          {error ? <ErrorMessage>{error}</ErrorMessage> : ""}
        </Form>
      </Container>
    );
  }
}

Group.propTypes = {
  search: PropTypes.string.isRequired,
  references: PropTypes.array.isRequired,
  addReferenceDangerous: PropTypes.func.isRequired,
  createGroup: PropTypes.func.isRequired,
  changeScene: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired
};
