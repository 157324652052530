import PropTypes from "prop-types";
import React, { Component } from "react";

import {
  ButtonGroup,
  ButtonOutline,
  Submit
} from "components/Global/Modal/Layout/Buttons";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import { ArrowRenderer } from "components/Global/Editors/utils/reactSelect";
import FormElements from "components/Global/Modal/Layout/FormElements";
import { Dropdown } from "components/Base";

const { Label, InputGroup } = FormElements;

class DeleteRecordType extends Component {
  state = {
    replaceWithRecordTypeId: null
  };

  handleRecordTypeChange = selection =>
    this.setState({
      replaceWithRecordTypeId: selection ? selection.value : null
    });

  handleSave = () => {
    const data = {
      ...this.props.recordType,
      replaceWithRecordTypeId: this.state.replaceWithRecordTypeId
    };
    this.props.onSave(data);
    this.props.hideModal();
  };

  render() {
    const { recordType, recordTypes, hideModal } = this.props;
    return (
      <StyleWrapper heading="Delete type" hideModal={hideModal}>
        <form onSubmit={this.handleSave}>
          <InputGroup>
            <Label>Select a type to replace with</Label>
            <Dropdown
              arrowRenderer={ArrowRenderer}
              onChange={this.handleRecordTypeChange}
              options={recordTypes
                .filter(r => r.id !== recordType.id)
                .map(({ id, name }) => ({ label: name, value: id }))}
              value={this.state.replaceWithRecordTypeId}
              usePortal
            />
          </InputGroup>
          <ButtonGroup>
            <Submit
              title="Save"
              disabled={!this.state.replaceWithRecordTypeId}
            />
            <ButtonOutline title="Cancel" onClick={hideModal} />
          </ButtonGroup>
        </form>
      </StyleWrapper>
    );
  }
}

DeleteRecordType.propTypes = {
  hideModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  recordType: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  recordTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  ).isRequired
};

export default DeleteRecordType;
