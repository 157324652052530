import React from "react";
import { Page, Title } from "EventLight/Common/FieldLayout";
import Body from "./Body";

const Layout = () => (
  <>
    <Page>
      <Title
        icon="restaurant"
        title="Manager Override Code"
        description="Add an override code for managers to use during catering checkin"
      />
      <Body />
    </Page>
  </>
);

export default Layout;
