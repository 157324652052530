import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import { noop } from "utils/General";

import { getters, actions } from "../index";

import CSSModules from "react-css-modules";
import css from "./styles.scss";
import {
  MediumFilledButton,
  FontIcon,
  BigFilledButton,
  AddIcon,
  SmallFilledButton
} from "components/Base";

import Tooltip from "components/Global/Tooltip";
import { DarkTab } from "components/Base";
import { Link } from "react-router";
import { getTabs, getHasViewChanged } from "../selectors";

import { VARIANTS } from "ui-kit/Theme/constants";
import UISearchBar from "ui-kit/SearchBar/View";
import SaveView from "ui-kit/ViewPicker/View/SaveViewHorizontal";
import Tabs from "ui-kit/Tabs/View";

import {
  TABLE_INSTANCE_ID,
  VIEWPICKER_INSTANCE_ID
} from "EventLight/ProductionSchedules/Schedule/constants";

const decorate = R.compose(
  connect(
    (state, props) => ({
      tabs: getTabs(state, props),
      hasViewChanged: getHasViewChanged(state),
      schedule: getters.schedule(state)
    }),
    {
      showSubscribeModal: actions.showSubscribeModal,
      onSelectView: actions.setSelectedView
    }
  ),
  CSSModules(css)
);

const Toolbar = ({
  tabs,
  hasViewChanged,
  onSelectView,
  schedule,
  handlers
}) => (
  <div styleName="toolbar">
    <div styleName="details">
      <div styleName="details-top">
        <div styleName="name-wrapper">
          <span className="material-icons-outlined">schedule</span>
          <div styleName="name">{schedule.name}</div>
        </div>

        <div styleName="name-separator">/</div>

        <SaveView
          instanceId={VIEWPICKER_INSTANCE_ID}
          hasViewChanged={hasViewChanged}
          collapsed={true}
          small={true}
        />
      </div>
      <div styleName="subnavTabs">
        <div styleName="subNavTabsInner">
          <Tabs
            tabs={tabs}
            tabStyle={{
              fs: 3
            }}
          />
        </div>
      </div>
    </div>

    <div styleName="actions">
      <UISearchBar
        variant={VARIANTS.SURFACE}
        flex={1}
        placeholder="Search"
        size="medium"
        style={{
          maxWidth: 445
        }}
      />

      <Tooltip tooltip="Create new schedule">
        <span>
          <MediumFilledButton
            ml={3}
            bg="warning7"
            color="white"
            onClick={handlers.showAddScheduleModal}
            style={{
              width: "37px",
              padding: 0
            }}
          >
            <AddIcon size={25} color="white" />
          </MediumFilledButton>
        </span>
      </Tooltip>
    </div>
  </div>
);

export default decorate(Toolbar);
