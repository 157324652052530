import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Provider } from "react-redux";
import thunkMiddleware from "redux-thunk";
import * as R from "ramda";
import salesPortalSettings from "SalesPortal/Settings";
import salesPortalTypesModal from "SalesPortal/TypesModal";
import salesPortalSettingsSagas from "SalesPortal/Settings/sagas";
import Layout from "./Layout";

salesPortalSettings.setRootSaga(salesPortalSettingsSagas);
salesPortalSettings.plugModule(salesPortalTypesModal);

class SalesPortalSettings extends Component {
  static contextTypes = {
    store: PropTypes.shape({
      subscribe: PropTypes.func.isRequired,
      dispatch: PropTypes.func.isRequired,
      getState: PropTypes.func.isRequired
    })
  };

  constructor(props, context) {
    super(props);
    const { store } = salesPortalSettings.createStore({
      middleware: [thunkMiddleware],
      globalStore: context.store,
      observedDomains: ["user", "event", "modal", "organization"],
      dispatchToGlobal: R.compose(
        R.not,
        R.test(new RegExp(salesPortalSettings.namespace)),
        R.prop("type")
      )
    });
    this.store = store;

    salesPortalSettings.run();
  }

  render() {
    return (
      <Provider store={this.store}>
        <Layout />
      </Provider>
    );
  }
}

export default withRouter(SalesPortalSettings);
