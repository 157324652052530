import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Select from "components/Global/Inputs/Select";
import Popover from "@lennd/material-ui/Popover";
import { sortBy, get } from "lodash";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

export const Option = CSSModules(
  ({ title, description, selected, ...other }) => (
    <div
      styleName={selected ? "optionContainerSelected" : "optionContainer"}
      {...other}
    >
      <i className={["material-icons", css.optionLeftIcon].join(" ")}>done</i>
      <div>
        <div styleName="optionTitle">{title}</div>
        <div styleName="optionDescription">{description}</div>
      </div>
    </div>
  ),
  css
);

Option.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  selected: PropTypes.bool
};

@CSSModules(css)
class TemplateSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      anchorEl: null
    };
  }

  @autobind
  onSelect(e, selected) {
    this.setState({
      open: false
    });
    this.props.onSelect(selected);
  }

  @autobind
  handleOpen(event) {
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget
    });
  }

  @autobind
  handleRequestClose() {
    this.setState({
      open: false
    });
  }

  render() {
    const { templates, selected } = this.props;
    const sorted = sortBy(templates, t => t.title);
    return (
      <Select
        width={this.props.width}
        onClick={this.handleOpen}
        onFocus={this.handleOpen}
        value={selected ? get(selected, "title") : null}
        placeholder="Select Request Type"
      >
        <Popover
          anchorOrigin={{
            horizontal: "left",
            vertical: "top"
          }}
          transformOrigin={{
            horizontal: "left",
            vertical: "top"
          }}
          style={{
            width: this.props.width
          }}
          open={this.state.open}
          canAutoPosition={false}
          onClose={this.handleRequestClose}
          anchorEl={this.state.anchorEl}
        >
          <div>
            <div className={css.selectHeader}>Select Request Type</div>
            <div
              className={css.optionsList}
              style={{ ...this.props.menuStyle }}
            >
              {sorted.map(template => (
                <Option
                  key={template.id}
                  selected={template === selected}
                  onClick={e => this.onSelect(e, template)}
                  title={template.title}
                  description={template.description}
                />
              ))}
            </div>
          </div>
        </Popover>
      </Select>
    );
  }
}

TemplateSelect.defaultProps = {
  width: 250,
  menuStyle: {
    maxHeight: 150
  }
};

TemplateSelect.propTypes = {
  menuStyle: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  templates: PropTypes.array.isRequired,
  selected: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default TemplateSelect;
