import Helpers from "utils/Global/Helpers";

export const getSourcesByItemType = (credentials, eventId, typeId) =>
  new Promise((success, error) => {
    /* eslint-disable no-underscore-dangle */
    Helpers.request({
      method: "get",
      url: `${window.__LENND_API_BASE_URL__}/orders/sources`,
      credentials,
      qs: { eventId, typeId },
      success,
      error
    });
  });
