import * as R from "ramda";
import { createSelector } from "reselect";

import { getters } from "./index";

export const getTypeItemList = createSelector(
  getters.types,
  getters.typesFilter,
  getters.typeId,
  (types, typesFilter, typeId) => {
    const itemType = R.find(({ id }) => id === typeId, types);
    if (R.isEmpty(typesFilter)) {
      return itemType;
    }
    return {
      ...itemType,
      groups: R.reduce(
        (acc, group) => {
          const sortedItems = R.sortBy(R.prop("order"))(group.items);
          const items = typesFilter.length
            ? R.filter(
                item =>
                  R.contains(R.toLower(typesFilter), R.toLower(item.name)),
                sortedItems
              )
            : sortedItems;
          if (!typesFilter.length || R.length(items) > 0) {
            return [
              ...acc,
              {
                ...group,
                items
              }
            ];
          }
          return acc;
        },
        [],
        R.sortBy(R.prop("order"))(itemType.groups)
      )
    };
  }
);
