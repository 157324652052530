import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Form from "./Form";
import can from "components/Global/Security";
import { withRouter } from "react-router";
import { isViewingAsOrg } from "utils/is-viewing-as-context";
import getRouteContext from "components/Event/Module/utils/get-route-context";

import { getModule } from "redux/modules/modules/module/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { getForm, updateForm } from "redux/modules/formsV2/form/actions";
import {
  createView,
  updateView,
  deleteView,
  setActiveView
} from "redux/modules/modules/views/actions";

import { user } from "redux/modules/user/selectors";
import { form } from "redux/modules/formsV2/form/selectors";
import {
  moduleDetails,
  forms,
  fields,
  fieldGroups
} from "redux/modules/modules/module/selectors";
import {
  eventDetails,
  isConferenceTemplate
} from "redux/modules/event/selectors";
import { userPermissionProfile } from "redux/modules/permissions/user-permission-profile/selectors";
import { views, activeView } from "redux/modules/modules/views/selectors";
import { logAction } from "redux/modules/log/actions";
import { previousPath } from "redux/modules/history";

function mapStateToProps(state, props) {
  const context = getRouteContext({
    routes: props.routes,
    params: props.params,
    context: props.context
  });

  context.view = "form";

  // build contextual props based on org vs event
  let contextProps = {};
  if (isViewingAsOrg(context)) {
    contextProps = {
      moduleUrl: `/organization/${context.orgId}/module/${context.moduleId}`,
      previewUrl: `/organization/${context.orgId}/forms-v3/${context.viewId}/preview`,
      editFormUrl: `/organization/${context.orgId}/module/${context.moduleId}/form/${context.viewId}`
    };
  } else {
    contextProps = {
      moduleUrl: `/event/${context.eventId}/module/${context.moduleId}`,
      previewUrl: `/event/${context.eventId}/forms-v3/${context.viewId}/preview`,
      editFormUrl: `/event/${context.eventId}/module/${context.moduleId}/form/${context.viewId}`
    };
  }

  return {
    ...contextProps,
    isConferenceTemplate: isConferenceTemplate(state),
    form: form(state),
    user: user(state),
    fields: fields(state, context.moduleId),
    fieldGroups: fieldGroups(state, context.moduleId),
    module: moduleDetails(state, context.moduleId),
    forms: forms(state, context.moduleId),
    eventDetails: eventDetails(state),
    readOnly: !can(
      userPermissionProfile(state, context.eventId, state.user.user.id)
    ).do(`${context.moduleId}_update`),
    views: views(state, context.moduleId),
    activeView: activeView(
      state,
      context.moduleId,
      context.viewId,
      context.groupId
    ),
    context,
    params: context,
    referralPath: previousPath(state)
  };
}

function mapDispatchToProps(dispatch, props) {
  const context = getRouteContext({
    routes: props.routes,
    params: props.params,
    context: props.context
  });

  // build contextual actions based on org vs event
  let actions = {};
  if (isViewingAsOrg(context)) {
    const orgOpts = {
      moduleId: context.moduleId,
      options: {
        orgId: context.orgId
      }
    };
    actions = {
      getModule: () => getModule(orgOpts)
    };
  } else {
    const eventOpts = {
      moduleId: context.moduleId,
      options: {
        eventId: context.eventId
      }
    };
    actions = {
      getModule: () => getModule(eventOpts)
    };
  }

  return bindActionCreators(
    {
      ...actions,
      getForm,
      updateForm,
      showSnackbar,
      showModal,
      hideModal,
      createView,
      updateView,
      deleteView,
      setActiveView,
      logAction
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Form)
);
