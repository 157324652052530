import PropTypes from "prop-types";
import React, { Component } from "react";
import FileViewer from "components/Global/Modals/FileViewer";
import {
  ButtonGroup,
  Submit,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";
import { get } from "lodash";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import CloseWarn from "./CloseWarn";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { FILES } from "utils/standard-module-field-ids";

const { MIMETYPE, FILENAME, DESCRIPTION, TITLE, URL } = FILES;

const saveDocuments = ({
  documents,
  createDocument,
  associateDocumentToModuleRecord
}) =>
  Promise.all(
    documents.map(async document => {
      try {
        const result = await createDocument({
          moduleId: STANDARD_MODULE_IDS.files.id,
          record: {
            [MIMETYPE]: { type: "text", value: document.mimetype },
            [FILENAME]: { type: "text", value: `${document.title}.pdf` },
            [URL]: { type: "text", value: document.url },
            [TITLE]: { type: "text", value: document.title },
            [DESCRIPTION]: {
              type: "text",
              value: "This is a generated document"
            }
          },
          options: {
            eventId: document.eventId
          }
        });

        await associateDocumentToModuleRecord({
          recordId: document.recordId,
          moduleId: document.moduleId,
          documentId: result.id
        });

        return result;
      } catch (error) {
        return null;
      }
    })
  );

@CSSModules(css)
class DocumentPreview extends Component {
  saveDocuments = async send => {
    const {
      documents,
      addRecord,
      associateDocumentToModuleRecord
    } = this.props;

    const results = (await saveDocuments({
      documents,
      createDocument: addRecord,
      associateDocumentToModuleRecord
    })).filter(r => r);

    if (!send) {
      this.props.showSnackbar({
        message: `${results.length} Document${
          results.length > 1 ? "s" : ""
        } saved`
      });
    }
    this.props.hideModal();
    this.props.onDone({
      documents,
      send
    });
  };

  onHide = () => {
    this.props.showModal({
      content: (
        <CloseWarn
          count={this.props.documents.length}
          onDone={this.props.hideModal}
          hideModal={this.props.hideModal}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  onSaveDocumentClick = () => this.saveDocuments(false);
  onSaveAndSendDocumentClick = () => this.saveDocuments(true);

  render() {
    const { showSaveAndSend } = this.props;
    return (
      <FileViewer
        start={0}
        files={this.props.documents}
        hideModal={this.onHide}
        isReadOnly
        showCount
        canDownload={false}
        controls={
          <ButtonGroup>
            <ButtonOutline
              onClick={this.onSaveDocumentClick}
              title={`Save ${this.props.documents.length} Document${
                this.props.documents.length > 1 ? "s" : ""
              }`}
            />
            {showSaveAndSend ? (
              <Submit
                type="button"
                onClick={this.onSaveAndSendDocumentClick}
                title={`Save and Send ${this.props.documents.length} Document${
                  this.props.documents.length > 1 ? "s" : ""
                }`}
              />
            ) : (
              ""
            )}
          </ButtonGroup>
        }
      />
    );
  }
}

DocumentPreview.propTypes = {
  showSaveAndSend: PropTypes.bool.isRequired,
  onDone: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  addRecord: PropTypes.func.isRequired,
  associateDocumentToModuleRecord: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      recordId: PropTypes.string.isRequired,
      moduleId: PropTypes.string.isRequired,
      eventId: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      filename: PropTypes.string.isRequired,
      mimetype: PropTypes.string.isRequired,
      size: PropTypes.number.isRequired
    })
  ).isRequired
};

export default DocumentPreview;
