import React from "react";
import { connect } from "react-redux";
import {
  getContactData,
  getContactDataFieldsFiltered,
  getContactFirstLastNameFields,
  getUserName,
  getAvailableContacts
} from "Portal/IntakeForm/selectors";
import { getters, actions } from "Portal/IntakeForm/model";
import InputFields, { Field } from "./InputFields";
import { Div, Dropdown } from "components/Base";

const decorate = connect(
  state => ({
    error: getters.error(state),
    isLoggedIn: getters.isLoggedIn(state),
    data: getContactData(state),
    contactValues: getters.contactValues(state),
    contactDataFields: getContactDataFieldsFiltered(state),
    firstLastNameFields: getContactFirstLastNameFields(state),
    userName: getUserName(state),
    availableContacts: getAvailableContacts(state),
    selectedContactId: getters.selectedContactId(state)
  }),
  {
    updateContactValues: actions.updateContactValues,
    updateSelectedContactId: actions.updateSelectedContactId
  }
);

const ContactPage = ({
  handlers,
  error,
  data,
  updateContactValues,
  contactValues,
  contactDataFields,
  firstLastNameFields,
  isLoggedIn,
  availableContacts,
  selectedContactId,
  updateSelectedContactId
}) => {
  return (
    <InputFields
      disableShowAlert
      handlers={handlers}
      error={error}
      data={data}
      values={contactValues}
      updateValues={updateContactValues}
      fields={contactDataFields}
    >
      <>
        {isLoggedIn && availableContacts.length ? (
          <Div className="lennd-valid" mb={3}>
            <Div display="row.flex-start" color="black" fw={3} mb={1}>
              Select a person to fill out this form as:
            </Div>
            <Dropdown
              options={availableContacts}
              selected={selectedContactId}
              onChange={({ value }) => updateSelectedContactId(value)}
              width={250}
            />
          </Div>
        ) : null}
        <Div display="row.flex-start.flex-end">
          <Div flex={1}>
            <Field
              field={firstLastNameFields[0]}
              values={contactValues}
              updateValues={updateContactValues}
            />
          </Div>
          <Div flex={1} ml={2}>
            <Field
              field={firstLastNameFields[1]}
              values={contactValues}
              updateValues={updateContactValues}
            />
          </Div>
        </Div>
        <Field
          field={firstLastNameFields[2]}
          values={contactValues}
          updateValues={updateContactValues}
        />
      </>
    </InputFields>
  );
};

export default decorate(ContactPage);
