import { combineReducers } from "redux";
import { ERROR, CLEAR_ERROR } from "./constants";

const errors = (state = [], action) => {
  switch (action.type) {
    case ERROR:
      return [...state, ...action.payload];
    case CLEAR_ERROR:
      return [];
    default:
      return state;
  }
};

export default combineReducers({
  errors
});
