import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const AccountContactsActionBar = ({ helpers, selectedRows }) => {
  const countOfSelectedRows = selectedRows.length;
  return (
    <div styleName="container">
      <div styleName="count">
        {countOfSelectedRows} selected row{countOfSelectedRows !== 1 ? "s" : ""}
      </div>
      {helpers.edit && selectedRows.length === 1 ? (
        <div styleName="menuItem" onClick={() => helpers.edit(selectedRows[0])}>
          <i className="material-icons">edit</i>
          <span styleName="text">Edit</span>
        </div>
      ) : (
        ""
      )}
      {/*
      {helpers.sendForm ? (
        <div
          styleName="menuItem"
          onClick={() => helpers.sendForm(selectedRows)}
        >
          <i className="material-icons">send</i>
          <span styleName="text">Send Form</span>
        </div>
      ) : null}
      */}
      <div styleName="menuItem" onClick={helpers.deleteRecords}>
        <span styleName="text">Delete</span>
      </div>
    </div>
  );
};

AccountContactsActionBar.propTypes = {
  helpers: PropTypes.shape({
    edit: PropTypes.func,
    deleteRecords: PropTypes.func
  }).isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default CSSModules(AccountContactsActionBar, css);
