import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "Organizations/CloneEventModal/model";
import { PAGES } from "Organizations/CloneEventModal/constants";
import { Div, Text5, BigFilledButton, RightArrowIcon } from "components/Base";

const ImportBox = ({ children, onClick, ...styleProps }) => (
  <Div
    bg={{
      default: "neutral0",
      hover: "neutral1"
    }}
    width={468}
    height={346}
    display="row.center.center"
    bra={2}
    onClick={onClick}
    {...styleProps}
  >
    {children}
  </Div>
);

const decorate = connect(
  state => ({
    canClone: getters.canClone(state)
  }),
  {
    goToCreateEvent: () => actions.setSelectedPage(PAGES.CREATE_NEW_EVENT),
    goToSelectEvent: () => actions.setSelectedPage(PAGES.SELECT_EVENT),
    goToContactSupport: () => actions.setSelectedPage(PAGES.CONTACT_SUPPORT)
  }
);

const NewEvent = ({
  goToCreateEvent,
  goToSelectEvent,
  goToContactSupport,
  canClone
}) => (
  <Div p={7} display="column">
    <Div display="row.space-between.flex-start" flex={1}>
      <ImportBox
        onClick={() => {
          goToCreateEvent();
        }}
      >
        <Div display="column.space-between.center" height={200} width={468}>
          <Div
            textAlign="center"
            width={468}
            height={200}
            display="column.space-between.center"
          >
            <img src="http://d2dks4tzxs6xee.cloudfront.net/img/clone-event-icon.svg" />
            <Text5 bold>Create event from scratch</Text5>
            <BigFilledButton
              color="neutral1"
              bg="purple8"
              RightIcon={RightArrowIcon}
              data-cy="org-create-from-scratch-event-button"
            >
              Get started
            </BigFilledButton>
          </Div>
        </Div>
      </ImportBox>
      <ImportBox onClick={!canClone ? goToContactSupport : goToSelectEvent}>
        <Div
          textAlign="center"
          width={468}
          height={200}
          display="column.space-between.center"
        >
          <img src="https://d2dks4tzxs6xee.cloudfront.net/img/upload-copy-paste-icon.svg" />
          <Text5 bold>Clone an existing event</Text5>
          <BigFilledButton color="purple8" bg="neutral2">
            {!canClone ? "Contact support" : "Select Event"}
          </BigFilledButton>
        </Div>
      </ImportBox>
    </Div>
    {/*
    <Div
      bg="neutral0"
      display="row.space-between.center"
      height={71}
      flex={1}
      bra={2}
      mt={6}
      pl={8}
      pr={3}
    >
      <Div>
        <Text3 bold>Import Events</Text3>
        <Text2>Import your list of upcoming events</Text2>
      </Div>
      <BigShadedButton
        color="purple8"
        bg="neutral3"
        onClick={showImportEventsModal}
      >
        Import
      </BigShadedButton>
    </Div>
   */}

    {/*
    // @NOTE: Hide until implemented
    <Div
      bg="neutral0"
      display="row.space-between.center"
      height={71}
      flex={1}
      bra={2}
      mt={6}
      pl={8}
      pr={3}
    >
      <Div>
        <Text3 bold>Event Templates</Text3>
        <Text2>
          Choose from pre-built event templates crafted by other event pro's
        </Text2>
      </Div>
      <BigShadedButton color="purple8" bg="neutral3" onClick={() => {}}>
        Explore Templates
      </BigShadedButton>
    </Div>
    */}
  </Div>
);

export default decorate(NewEvent);
