import PropTypes from "prop-types";
import React from "react";
import { Editors } from "react-data-grid-addons";
import NumberedRowActionsCell from "../Numbered";

const CheckboxEditor = Editors.CheckboxEditor;

class NumberedSelectableRowActionsCell extends React.Component {
  renderRowIndex() {
    return (
      <div className="rdg-row-index">
        <NumberedRowActionsCell {...this.props} />
      </div>
    );
  }

  renderSelect() {
    const isSelected = this.props.value;
    const editorClass = isSelected
      ? "rdg-actions-checkbox selected"
      : "rdg-actions-checkbox";

    return (
      <div className={editorClass}>
        <CheckboxEditor
          column={this.props.column}
          rowIdx={this.props.rowIdx}
          dependentValues={this.props.dependentValues}
          value={this.props.value}
        />
      </div>
    );
  }

  render() {
    const { value: isSelected } = this.props;
    return !isSelected ? (
      <div>
        {this.renderRowIndex()}
        {this.renderSelect()}
      </div>
    ) : (
      this.renderSelect()
    );
  }
}

NumberedSelectableRowActionsCell.propTypes = {
  rowIdx: PropTypes.number.isRequired,
  column: PropTypes.object,
  dependentValues: PropTypes.object,
  value: PropTypes.bool
};

export default NumberedSelectableRowActionsCell;
