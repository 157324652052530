import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actions, getters } from "Portal/PortalPageWrapper/model";

import PortalPage from "Portal/PortalPage/View";
import PortalPageTrainingCenter from "Portal/PortalPageTrainingCenter/View";
import PortalPagePGA from "Portal/PortalPagePGA/PortalPagePGA/View";
import PortalPageOrders from "Portal/PortalPageOrders/View";
import PortalCustom from "Portal/PortalCustom/View";

import * as R from "ramda";
import Loading from "ui-kit/Loading";

const decorate = connect(
  (state, props) => ({
    loading: getters.loading(state, props),
    pagePayload: getters.pagePayload(state, props)
  }),
  { init: actions.init }
);

const Layout = ({
  init,
  loading,
  params: { pageId },
  pagePayload: pageData
}) => {
  useEffect(() => {
    if (pageId) {
      init(pageId);
    }
  }, [pageId]);

  return loading ? (
    <Loading />
  ) : R.prop("template", pageData) === "pga_media_center" ? (
    <PortalPagePGA pageData={pageData} />
  ) : R.prop("template", pageData) === "training_center" ? (
    <PortalPageTrainingCenter pageData={pageData} />
  ) : R.prop("template", pageData) === "checkout" ? (
    <PortalPageOrders pageData={pageData} />
  ) : R.prop("template", pageData) === "custom" ? (
    <PortalCustom pageData={pageData} />
  ) : (
    <PortalPage pageData={pageData} />
  );
};

export default decorate(Layout);
