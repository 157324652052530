import React from "react";
import * as R from "ramda";
import QuestionDetails from "Items/QuestionsDetails/View";

import { getValue } from "../../utils";

const OrderItem = props => {
  const value = getValue(props);
  return value && value.text && value.text.length ? (
    <QuestionDetails
      style={{
        paddingLeft: "8px",
        fontSize: "12px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        cursor: "pointer"
      }}
      className="underline-on-hover"
      recordIds={R.pathOr([], ["recordIds"], value)}
    >
      {value.text}
    </QuestionDetails>
  ) : null;
};

export default OrderItem;
