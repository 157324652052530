import React, { Component } from "react";
import PropTypes from "prop-types";
import Radium from "radium";
import autobind from "autobind-decorator";
import ReactDOM from "react-dom";
import GeminiScrollbarJs from "gemini-scrollbar";
import ScrollLocked from "./ScrollLocked";
import _ from "lodash";

class GeminiScrollbar extends Component {
  static propTypes = {
    autoshow: PropTypes.bool,
    lockDisabled: PropTypes.bool,
    onScrollToTop: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.node,
    forceGemini: PropTypes.bool
  };

  static defaultProps = {
    autoshow: false
  };

  constructor(props) {
    super(props);

    /**
     * Holds the reference to the GeminiScrollbarJs instance.
     * @property scrollbar <public> [Object]
     */
    this.scrollbar = null;
    this.isComponentMounted = false;
    this.scrollNode = null;
  }

  componentDidMount() {
    this.isComponentMounted = true;
    if (!this.props.lockDisabled) {
      this.scrollbar = new GeminiScrollbarJs({
        element: ReactDOM.findDOMNode(this),
        forceGemini: this.props.forceGemini,
        createElements: false
      }).create();

      let node;
      if (typeof $ !== "undefined") {
        node = $(ReactDOM.findDOMNode(this)).find(".gm-scroll-view")[0];
        this.scrollNode = node;
      }

      if (this.props.onScrollToTop && node) {
        node.addEventListener("scroll", _.throttle(this._handleScroll, 500));
      }
    }
  }

  componentDidUpdate() {
    if (!this.props.lockDisabled) {
      this.scrollbar.update();
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
    if (!this.props.lockDisabled && this.scrollbar) {
      this.scrollbar.destroy();
      this.scrollbar = null;
    }
  }

  render() {
    const { className, children, ...other } = this.props;
    let classes = "gm-scrollbar-flex";

    if (className) {
      classes += ` ${className}`;
    }

    let content;
    if (this.props.lockDisabled) {
      content = (
        <div className="gm-scroll-view" ref="scroll-view">
          {children}
        </div>
      );
    } else {
      content = (
        <ScrollLocked className="gm-scroll-view" ref="scroll-view">
          {children}
        </ScrollLocked>
      );
    }

    return (
      <div {...other} className={classes}>
        <div className="gm-scrollbar -vertical">
          <div className="thumb" />
        </div>
        <div className="gm-scrollbar -horizontal">
          <div className="thumb" />
        </div>
        {content}
      </div>
    );
  }

  @autobind
  _handleScroll() {
    if (!this.isComponentMounted) {
      return false;
    }

    if (this.scrollNode.scrollTop < 100) {
      // calculate current scrollHeight so we can compare after new content added
      const scrollHeightBefore = this.scrollNode.scrollHeight;

      // tell parent that we hit waypoint and wait for callback to adjust scroll
      this.props.onScrollToTop(() => {
        const scrollHeightAfter = this.scrollNode.scrollHeight;
        this.scrollNode.scrollTop = scrollHeightAfter - scrollHeightBefore;
      });
    }
  }
}

export default Radium(GeminiScrollbar);
