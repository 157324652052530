import PropTypes from "prop-types";
import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class QuantityInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false
    };
  }

  @autobind
  onBlur() {
    this.setState({
      focused: false
    });
  }

  @autobind
  onFocus() {
    this.setState({
      focused: true
    });
  }

  @autobind
  onChange(e) {
    if (
      this.props.max &&
      parseInt(e.target.value, 10) &&
      parseInt(e.target.value, 10) > this.props.max
    ) {
      return false;
    }

    this.props.onChange(this.props.item, e.target.value);
  }

  render() {
    const { disabled, quantity, max } = this.props;
    if (Number(quantity) || this.state.focused) {
      return (
        <input
          ref={c => (this.input = c)}
          onChange={this.onChange}
          styleName="input"
          type="number"
          min="0"
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          defaultValue={quantity}
          disabled={disabled}
          max={max}
        />
      );
    }
    return "";
  }
}

QuantityInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  quantity: PropTypes.number,
  disabled: PropTypes.bool
};

export default QuantityInput;
