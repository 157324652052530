import IntakeForm from "Portal/IntakeForm/View";

import { hot } from "App/Config/hot";

const Intake = {
  IntakeForm,
  IntakeLogin: IntakeForm,
  IntakeCallback: IntakeForm
};

export default hot(Intake);
