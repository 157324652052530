import React, { Component } from "react";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_MODULE_FIELD_IDS from "utils/standard-module-field-ids";
import PlatformLoading from "components/Platform/PlatformLoading";

import PortalUsersApi from "redux/modules/portal/users/api";
import SubmissionApi from "redux/modules/formsV2/submission/api";
import ActivityApi from "redux/modules/activity/api";
import ActivityContstants from "redux/modules/activity/constants";

import getFormVersion from "components/Event/FormsV2/Utils/get-form-version";
import { withRouter } from "react-router";

import { connect } from "react-redux";
import * as Selectors from "redux/modules/formsV2/form/selectors";
import { user, getCredentials } from "redux/modules/user/selectors";
import * as FormActions from "redux/modules/formsV2/form/actions";
import * as ModalActions from "redux/modules/modal/actions";
import * as SnackbarActions from "redux/modules/snackbar/actions";
import { addRecord } from "redux/modules/modules/records/actions";
import * as ContactForms from "redux/modules/contacts/assignedForms/actions";
import * as AccountForms from "redux/modules/accounts/assignedForms/actions";
import { getLoggedInUserDetails, login } from "redux/modules/user/actions";
import { createOpenFormSubmission } from "redux/modules/portal/openFormSubmissions/actions";
import * as PortalUserActions from "redux/modules/portal/user/actions";
import * as PortalUserSelectors from "redux/modules/portal/user/selectors";
import { addAccountUser } from "redux/modules/accounts/users/actions";

const decorate = connect(
  state => ({
    userCredentials: getCredentials(state),
    user: user(state),
    portalUser: PortalUserSelectors.portalUser(state),
    form: Selectors.form(state),
    isFetching: Selectors.isFetching(state),
    errorMessages: [state.formsV2.form.error].filter(e => e)
  }),
  {
    loginUser: login,
    fetchUser: getLoggedInUserDetails,
    createOpenFormSubmission,
    assignFormToContact: ContactForms.addAssignedForms,
    assignFormToAccount: AccountForms.addAssignedForms,
    addRecord,
    addAccountUser,
    ...FormActions,
    ...ModalActions,
    ...SnackbarActions,
    ...PortalUserActions
  }
);

const LoginException = function(value) {
  this.value = value;
  this.message = "Unknown Submission Login Error";
  this.toString = () => `${this.message}: ${JSON.stringify(this.value)}`;
};

class SubmisssionCallbackController extends Component {
  state = {
    form: {}
  };

  async componentDidMount() {
    setTimeout(async () => {
      const { form } = await this.props.getForm(
        this.props.location.query.formId
      );
      this.setState({ form });

      const jobs = {
        handleCreateAccountForExistingUser: this
          .handleCreateAccountForExistingUser,
        handleRegisterNewUser: this.handleRegisterNewUser,
        handleRegisterVirtualUser: this.handleRegisterVirtualUser,
        handleConvertVirtualUser: this.handleConvertVirtualUser,
        handleRegisterExistingUser: this.handleRegisterExistingUser,
        handleLogin: this.handleLogin
      };

      const job = this.props.location.query.job;

      await ActivityApi.post({
        eventId: this.state.form.event_id,
        actorId: this.props.userCredentials.userId,
        type: ActivityContstants.LOGIN
      });

      if (!jobs[job]) {
        throw new Error(`Error: Callback job ${job} not defined`);
      }

      jobs[job]();
    }, 500);
  }

  // Helpers
  addAccountUser = accountId => {
    return this.props.addAccountUser({
      eventId: this.state.form.event_id,
      userId: this.props.userCredentials.userId,
      accountId
    });
  };

  updatePortalUser = (contactId, accountId) => {
    if (!contactId && !accountId) return null;

    return this.props.updatePortalUser({
      eventId: this.state.form.event_id,
      userId: this.props.userCredentials.userId,
      activeView: accountId || contactId
    });
  };

  createPortalUser = () =>
    PortalUsersApi.post(this.props.userCredentials, {
      eventId: this.state.form.event_id,
      userId: this.props.userCredentials.userId
    });

  getPortalUser = () => {
    return this.props.getPortalUser({
      userId: this.props.userCredentials.userId,
      eventId: this.state.form.event.uuid
    });
  };

  assignForm = accountId => {
    if (accountId) {
      return this.props.assignFormToAccount({
        eventId: this.state.form.event.id,
        recordIds: [accountId],
        formIds: [this.state.form.id]
      });
    }
    return this.props.assignFormToContact({
      eventId: this.state.form.event.id,
      recordIds: [this.props.userCredentials.userId],
      formIds: [this.state.form.id]
    });
  };

  createSubmission = async accountId => {
    const { form } = this.props;
    const version = getFormVersion(form);
    let submissionResult;

    if (version === 3) {
      const submissionModuleRecord = await this.props.addRecord({
        moduleId: form.base_module_id,
        record: {
          isDraft: true
        },
        options: {
          eventId: form.event_id,
          userId: this.props.userCredentials.userId
        }
      });

      submissionResult = await SubmissionApi.post(this.props.userCredentials, {
        eventId: form.event_id,
        formId: form.id,
        accountId,
        userId: this.props.userCredentials.userId,
        submissionRecordId: submissionModuleRecord.id
      });
    } else {
      submissionResult = await SubmissionApi.post(this.props.userCredentials, {
        eventId: form.event_id,
        formId: form.id,
        userId: this.props.userCredentials.userId,
        accountId
      });
    }

    return submissionResult;
  };

  showError = error => {
    if (window.__ROLLBAR_CONFIG__ && Rollbar) {
      Rollbar.log("Submission Login Error", new LoginException(error));
    }
  };

  addAccount = async () => {
    if (this.state.form.scope === "account") {
      const account = await this.props.addRecord({
        moduleId: STANDARD_MODULE_IDS.accounts.id,
        typeId: this.state.form.scope_record_type_id,

        // @NOTE: We can toggle if an account should be in draft mode here
        // isDraft: true,

        record: {
          [STANDARD_MODULE_FIELD_IDS.ACCOUNTS.NAME]: {
            type: "text",
            value: this.props.location.query.accountName
          }
        },

        options: {
          eventId: this.state.form.event_id,
          userId: this.props.userCredentials.userId
        }
      });

      await this.addAccountUserRelationships(account.id);

      return { accountId: account.id };
    }

    return { accountId: null };
  };

  addAccountUserRelationships = accountId => {
    return Promise.all([
      this.addAccountUser(accountId),
      this.updatePortalUser(null, accountId)
    ]);
  };

  // Jobs
  handleCreateAccountForExistingUser = async () => {
    const [account] = await Promise.all([
      this.addAccount(),
      this.createPortalUser()
    ]);

    await this.assignForm(this.props.userCredentials.userId, account.accountId);

    return this.handleRegisterCompletion(
      this.props.userCredentials.userId,
      account.accountId
    );
  };

  handleRegisterNewUser = async () => {
    try {
      await this.createPortalUser();

      const { accountId } = await this.addAccount();

      await this.assignForm(accountId);

      return this.handleRegisterCompletion(accountId);
    } catch (err) {
      this.showError(err);
    }
  };

  handleRegisterVirtualUser = async () => {
    try {
      await this.createPortalUser();

      const { accountId } = await this.addAccount();

      await this.assignForm(accountId);

      return this.handleRegisterCompletion(accountId);
    } catch (err) {
      this.showError(err);
    }
  };

  handleConvertVirtualUser = async () => {
    try {
      const { accountId } = await this.addAccount();

      await this.assignForm(accountId);

      return this.handleRegisterCompletion(accountId);
    } catch (err) {
      this.showError(err);
    }
  };

  handleRegisterExistingUser = async () => {
    try {
      await this.createPortalUser();

      const { accountId } = await this.addAccount();

      await this.assignForm(accountId);

      return this.handleRegisterCompletion(accountId);
    } catch (err) {
      this.showError(err);
    }
  };

  handleRegisterCompletion = async accountId => {
    const {
      form,
      form: { event }
    } = this.props;

    if (this.props.location.query.redirectToPortal) {
      window.location = `/portals/${event.slug}/${event.uuid}`;
    } else {
      const { submission } = await this.createSubmission(accountId);
      window.location = `/submissions/${event.slug}/${form.slug}/${submission.id}`;
    }

    return null;
  };

  handleLogin = async () => {
    try {
      const {
        form,
        form: { event }
      } = this.props;
      const portalUser = await this.getPortalUser();

      if (portalUser.accounts.length) {
        // Conditions:
        // - Portal user has accounts
        // Action:
        // - Show "Account picker" scene
        window.location = `/forms/${event.slug}/${form.slug}/${form.id}`;
        return null;
      } else if (
        this.props.form.scope === "account" &&
        !portalUser.accounts.length
      ) {
        // Conditions:
        // - Form is scoped to account
        // - Portal user has accounts
        // Action:
        // - Show create account scene
        window.location = `/forms/${event.slug}/${form.slug}/${form.id}`;
        return null;
      } else {
        // Conditions:
        // - Form is not scoped to account
        // - Portal user does not have account
        // Action:
        // - Create submission
        return this.handleRegisterCompletion();
      }
    } catch (err) {
      this.showError(err);
    }
  };

  render() {
    return <PlatformLoading message="Loading..." />;
  }
}

export default withRouter(decorate(SubmisssionCallbackController));
