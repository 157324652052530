import React from "react";

import { createContext } from "redux-mvc";

import { connect } from "react-redux";

import * as R from "ramda";

import module, { actions } from "../index";

import { showModal, hideModal } from "redux/modules/modal/actions";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";

import DeleteConfirmation from "components/Global/CRM/Modals/DeleteConfirmation";

import uploadTemplateModule from "Modules/UploadDocumentTemplateModal";

import mergeTagTableModule from "Modules/MergeTagTableModal";

import Layout from "./Layout";

import rootSaga from "../sagas";

module.setRootSaga(rootSaga);

module.plugModule(uploadTemplateModule);

module.plugModule(mergeTagTableModule);

const decorate = createContext({
  module: module,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(actions.init({ moduleId: this.props.moduleId }));
    }
  },
  handlers: {
    showDeleteConfirmation(templateId) {
      this.props.showModal({
        content: (
          <DeleteConfirmation
            hideModal={this.props.hideModal}
            heading="Delete report template?"
            confirmLabel="Yes, delete template"
            message={
              <div>
                Are you sure you want to delete this template?
                <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                  This cannot be undone.
                </div>
              </div>
            }
            onConfirm={() => {
              this.store.dispatch(
                actions.deleteTemplate({
                  templateId
                })
              );
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    }
  },
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: ["user", "event", "modal", "organization"]
  }
});

export default connect(
  null,
  { hideModal, showModal }
)(decorate(Layout));
