import React from "react";

import { connect } from "react-redux";

import * as R from "ramda";

import { actions } from "Items/AssignQuestionsItemsModal";

import { getSelectedQuestionsAndSets } from "Items/AssignQuestionsItemsModal/selectors";

import {
  Div,
  Text1,
  Text2,
  Text3,
  DragIcon,
  CloseIcon,
  Cards
} from "components/Base";

const decorate = connect(
  state => ({
    selectedQuestionsAndSets: getSelectedQuestionsAndSets(state)
  }),
  {
    removeAllQuestionsAndSets: actions.removeAllQuestionsAndSets,
    toggleItemQuestion: actions.toggleItemQuestion,
    reorderQuestionsAndSetsIds: actions.reorderQuestionsAndSetsIds,
    removeSelectedId: actions.removeSelectedId
  }
);

const Item = ({ id, removeSelectedId, questions = [], name }) => (
  <Div bra={1} bg="altB1" width={1} p={2} mt={2}>
    <Div display="row.space-between.center" key={id} width={1}>
      <Div
        display="row.flex-start.center"
        style={{
          width: "calc(100% - 24px)"
        }}
      >
        <DragIcon />
        <Text2
          ml={2}
          color="black"
          bold
          style={{
            width: "calc(100% - 16px)",
            textOverflow: "ellipsis",
            overflow: "hidden"
          }}
        >
          {name || "-"}
        </Text2>
      </Div>
      <CloseIcon onClick={() => removeSelectedId({ id })} />
    </Div>
    {R.length(questions) > 0 && (
      <Div width={1} px={7} mt={2}>
        {R.map(
          question => (
            <Text1
              key={question.id}
              style={{ overflow: "hidden", textOverflow: "ellipsis" }}
            >
              {question.name}
            </Text1>
          ),
          questions
        )}
      </Div>
    )}
  </Div>
);

const ItemCards = Cards(Item, "ITEM");

const SelectedQuestions = ({
  removeAllQuestionsAndSets,
  removeSelectedId,
  selectedQuestionsAndSets,
  reorderQuestionsAndSetsIds
}) => (
  <Div width={1} height={1} style={{ overflowY: "auto" }} p={3}>
    <Div display="row.space-between.center">
      <Text3 bold color="black">
        Selected Questions
      </Text3>
      <Text1
        color="primary7"
        underline
        onClick={() => removeAllQuestionsAndSets()}
      >
        Remove All
      </Text1>
    </Div>
    <Div width={1} mt={1}>
      <ItemCards
        cards={selectedQuestionsAndSets}
        removeSelectedId={removeSelectedId}
        onReorder={seletedItems => reorderQuestionsAndSetsIds({ seletedItems })}
      />
    </Div>
  </Div>
);

export default decorate(SelectedQuestions);
