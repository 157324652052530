import { createHandlers } from "redux-mvc";
import * as R from "ramda";
import { NAMESPACE, SIDEBAR_OPTIONS } from "./constants";

const iniState = {
  loading: false,
  items: [],
  sidebarOption: SIDEBAR_OPTIONS.ALL,
  showAddPageModal: false,
  showAddFolderModal: false,
  showAddVideoLinkModal: false,
  folderName: "",
  selectedPageId: null,
  selectedFolderId: null
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    edit: R.identity,
    deleteItem: R.identity,
    filterPageStatus: R.identity,
    closeAddPageModal: R.identity,
    addFolder: R.identity,
    setInitialData: (_, { payload: { items } }) => ({
      items
    }),
    closeAddFolderModal: () => ({
      showAddFolderModal: false,
      folderName: ""
    }),
    openAddFolderModal: (_, { payload: { id, folderName } }) => ({
      showAddFolderModal: true,
      selectedFolderId: id,
      folderName
    }),
    openAddPageModal: (_, { payload: { id } }) => ({
      showAddPageModal: true,
      selectedPageId: id
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
