import { createSelector } from "reselect";
import { getters } from "./index";
import * as R from "ramda";

export const getFilteredDocumentTemplates = createSelector(
  getters.documentTemplates,
  getters.templatesFilter,
  getters.selectedModule,
  (documentTemplates, templatesFilter, selectedModule) => {
    if (R.isEmpty(templatesFilter)) {
      if (R.isNil(selectedModule)) {
        return documentTemplates;
      }
      return R.filter(
        template => template.module_id === selectedModule.id,
        documentTemplates
      );
    }
    return R.filter(
      field => R.contains(R.toLower(templatesFilter), R.toLower(field.title)),
      documentTemplates
    );
  }
);

export const getFilteredMergeTags = createSelector(
  getters.mergeData,
  getters.tagsFilter,
  (mergeData, tagsFilter) => {
    const mergeTags = R.propOr([], "merge_tags", mergeData);
    if (R.isEmpty(tagsFilter)) {
      return mergeTags;
    }

    return R.compose(
      R.filter(({ templateTags }) => R.length(templateTags) > 0),
      R.map(mergeTag => ({
        ...mergeTag,
        templateTags: R.filter(
          templateTag =>
            R.contains(R.toLower(tagsFilter), R.toLower(templateTag.tag)),
          mergeTag.templateTags
        )
      }))
    )(mergeTags);
  }
);
