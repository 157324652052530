import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "components/Event/ContactProfile/RelatedAccounts/AddRelatedAccountModal/styles.scss";
import DeleteConfirmation from "components/Global/CRM/Popovers/DeleteConfirmation";

import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import {
  Submit,
  ButtonGroup,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";

@CSSModules(css)
class EditRelatedAccountModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      role: props.role || ""
    };
  }

  @autobind
  handleRoleChange(e) {
    this.setState({ role: e.target.value });
  }

  @autobind
  handleSubmit(e) {
    e.preventDefault();
    const data = { role: this.state.role };
    if (this.props.relationshipId) {
      data.relationshipId = this.props.relationshipId;
    }
    this.props.onSave(data);
    this.props.hideModal();
  }

  @autobind
  handleRemove() {
    this.props.hideModal();
    this.props.onDelete({ relationshipId: this.props.relationshipId });
  }

  render() {
    const { hideModal, relationshipId, onDelete } = this.props;
    return (
      <StyleWrapper heading="Editing role" hideModal={hideModal} width={500}>
        <form onSubmit={this.handleSubmit}>
          <div styleName="roleWrapper">
            <div styleName="label">
              <span>
                Role for {relationshipId ? "this person" : "these people"}
              </span>
              <span styleName="caption">Optional*</span>
            </div>
            <input
              autoFocus
              onChange={this.handleRoleChange}
              styleName="input"
              type="text"
              value={this.state.role}
            />
          </div>
          <div styleName="footer">
            <ButtonGroup>
              <Submit title="Add" />
              <ButtonOutline title="Cancel" onClick={hideModal} />
            </ButtonGroup>
            {onDelete ? (
              <DeleteConfirmation
                onConfirm={this.handleRemove}
                text="Are you sure you want to remove this person?"
              >
                <div styleName="remove">Remove</div>
              </DeleteConfirmation>
            ) : (
              ""
            )}
          </div>
        </form>
      </StyleWrapper>
    );
  }
}

EditRelatedAccountModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  relationshipId: PropTypes.string,
  role: PropTypes.string
};

export default EditRelatedAccountModal;
