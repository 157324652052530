import PropTypes from "prop-types";
import React from "react";
import EditProfile from "components/Global/CRM/About/EditProfileValue";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import {
  ButtonGroup,
  TextButton,
  ButtonOrange
} from "components/Global/Modal/Layout/Buttons";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const MoreInfoModal = ({
  hideModal,
  goToManageFields,
  data,
  name,
  updateField,
  readOnly,
  eventDetails
}) => (
  <StyleWrapper
    heading={`${name} Properties`}
    hideModal={hideModal}
    width={500}
  >
    <div>
      <div className="input-wrapper">
        <EditProfile
          readOnly={readOnly}
          bio={data}
          eventDetails={eventDetails}
          fromModal
          maxWidth="278px"
          updateField={updateField}
        />
      </div>
      <div styleName="buttonGroup">
        <ButtonGroup>
          <ButtonOrange title="Done" onClick={hideModal} />
        </ButtonGroup>
        {goToManageFields ? (
          <TextButton
            title="Manage Fields"
            onClick={goToManageFields}
            style={{ fontSize: 16 }}
          />
        ) : null}
      </div>
    </div>
  </StyleWrapper>
);

MoreInfoModal.propTypes = {
  data: PropTypes.object.isRequired,
  eventDetails: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired,
  goToManageFields: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  updateField: PropTypes.func.isRequired
};

export default CSSModules(MoreInfoModal, css);
