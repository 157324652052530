import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import {
  ButtonGroup,
  ButtonOutline,
  Submit
} from "components/Global/Modal/Layout/Buttons";
import { MODES } from "../../constants";

import { connect } from "react-redux";
import { actions, getters } from "SendEmailModal";

const decorate = connect(
  state => ({
    skippedToSend: getters.skippedToSend(state)
  }),
  {
    setActiveStepIndex: actions.setActiveStepIndex,
    setSkippedToSend: actions.setSkippedToSend
  }
);

const Footer = ({
  mode,
  activeStepIndex,
  isCurrentStateValid,
  handleNextStep,
  handleBackStep,
  onDone,
  hideModal,
  fetching,
  sending,
  setActiveStepIndex,
  setSkippedToSend,
  skippedToSend
}) => {
  switch (activeStepIndex) {
    case 0:
      const reviewTitle = fetching ? (
        <span>
          <i
            className={["fa", "fa-circle-o-notch", css.processingIcon].join(
              " "
            )}
          />
          Preparing...
        </span>
      ) : (
        "Review"
      );

      return (
        <ButtonGroup className={css.container}>
          <Submit
            title={reviewTitle}
            onClick={handleNextStep}
            disabled={!isCurrentStateValid || fetching}
            className={css.nextButton}
          />
          <ButtonOutline
            title="Skip Review and Send"
            disabled={!isCurrentStateValid || fetching}
            onClick={() => {
              setActiveStepIndex(2);
              setSkippedToSend(true);
            }}
            className={css.grayButtonLong}
          />
          <ButtonOutline
            title="Cancel"
            onClick={hideModal}
            className={css.grayButton}
          />
        </ButtonGroup>
      );
    case 1: {
      return (
        <ButtonGroup className={css.container}>
          <ButtonOutline
            title={
              <span className={css.label}>
                <i className={["material-icons", css.buttonArrow].join(" ")}>
                  arrow_back
                </i>
                Back
              </span>
            }
            onClick={sending ? undefined : handleBackStep}
            className={css.grayButton}
          />
          <Submit
            title={mode === MODES.SMS ? "Send Messages" : "Send Emails"}
            disabled={!isCurrentStateValid}
            onClick={handleNextStep}
            className={css.nextButton}
          />
        </ButtonGroup>
      );
    }
    case 2: {
      return (
        <ButtonGroup className={css.container}>
          <ButtonOutline
            title={
              <span className={css.label}>
                <i className={["material-icons", css.buttonArrow].join(" ")}>
                  arrow_back
                </i>
                Back
              </span>
            }
            onClick={
              sending
                ? undefined
                : skippedToSend
                ? () => {
                    setActiveStepIndex(0);
                    setSkippedToSend(false);
                  }
                : handleBackStep
            }
            className={css.grayButton}
          />
        </ButtonGroup>
      );
    }
    case 3:
      return (
        <div styleName="container">
          <Submit
            title="Done"
            onClick={onDone}
            disabled={!isCurrentStateValid}
            className={css.doneButton}
          />
        </div>
      );
    default:
      return <div />;
  }
};

Footer.propTypes = {
  fetching: PropTypes.bool.isRequired,
  sending: PropTypes.bool.isRequired,
  activeStepIndex: PropTypes.number.isRequired,
  hideModal: PropTypes.func.isRequired,
  isCurrentStateValid: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  handleBackStep: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired
};

export default CSSModules(decorate(Footer), css);
