import React from "react";
import { Div, SearchIcon } from "components/Base";

class MediumFilledInputFocus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocused: false
    };
  }

  onFocus = () => {
    this.setState({ isFocused: true });
  };

  onBlur = () => {
    this.setState({ isFocused: false });
  };

  onInputChange = ev => {
    this.props.onChange(ev.target.value);
  };

  render() {
    const { isFocused } = this.state;
    return (
      <Div
        width={1}
        bg="neutral1"
        height={32}
        p={2}
        display="row.flex-start.center"
        style={{ borderRadius: "15px" }}
        bc={isFocused ? "primary7" : "transparent"}
        ba={1}
        transition="fast"
      >
        <SearchIcon
          mr={1}
          size={18}
          color={isFocused ? "primary7" : "neutral5"}
        />
        <input
          ref={this.props.inputRef}
          style={{
            backgroundColor: "transparent",
            border: 0,
            color: "#868686",
            fontSize: "14px",
            fontWeight: 600,
            flex: 1
          }}
          placeholder="Search..."
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.onInputChange}
          value={this.props.value}
        />
      </Div>
    );
  }
}

export default MediumFilledInputFocus;
