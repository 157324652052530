import { ADD_COUNT } from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getModuleCounts(moduleId) {
  return async (dispatch, getState) => {
    try {
      const result = await api.getByModule(
        getState().user.user.credentials,
        moduleId
      );
      ["pending", "approved", "rejected"].map(status =>
        dispatch({
          type: ADD_COUNT,
          payload: {
            status,
            moduleId,
            count: result.payload[status]
          }
        })
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching inventory counts"
          }
        ])
      );
    }
  };
}

export function getModuleCountsByStatus(moduleId, status) {
  return async (dispatch, getState) => {
    try {
      const result = await api.getByModuleAndStatus(
        getState().user.user.credentials,
        moduleId,
        status
      );
      dispatch({
        type: ADD_COUNT,
        payload: {
          status,
          moduleId,
          count: result.payload
        }
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching inventory counts"
          }
        ])
      );
    }
  };
}
