import {
  REQUEST,
  RECEIVE_LIST,
  RECEIVE_COUNTS,
  RECEIVE_PAGINATION,
  RECEIVE_RECORD_WITH_ISSUANCE,
  INVALIDATE_RECORD_WITH_ISSUANCE
} from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";
import { eventId } from "redux/modules/event/selectors";
// import { CREDENTIAL_TYPE_ID } from "utils/item-types";

export function getStats(recordTypeId) {
  return async (dispatch, getState) => {
    try {
      const result = await api.getStats(
        getState().user.user.credentials,
        eventId(getState()),
        recordTypeId
      );
      dispatch({
        type: RECEIVE_COUNTS,
        payload: result
      });
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching checkin stats"
          }
        ])
      );
    }
  };
}

export function searchCheckins(data) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: REQUEST });

      const result = await api.searchCheckins(
        getState().user.user.credentials,
        eventId(getState()),
        {
          ...data
        }
      );
      dispatch({
        type: RECEIVE_LIST,
        payload: result.items
      });
      dispatch({
        type: RECEIVE_PAGINATION,
        payload: result.pagination || {}
      });
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching checkin list"
          }
        ])
      );
    }
  };
}

export function checkin({
  actions,
  recordId,
  variantId,
  orderType,
  customerAccountId,
  customerContactId,
  options
}) {
  return async (dispatch, getState) => {
    try {
      return await api.checkin(getState().user.user.credentials, {
        recordId,
        actions,
        variantId,
        orderType,
        customerAccountId,
        customerContactId,
        options,
        eventId: eventId(getState())
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating checkin"
          }
        ])
      );
    }
  };
}

export function revertCheckin({
  recordId,
  orderType,
  customerAccountId,
  customerContactId,
  variantId,
  options,
  action
}) {
  return async (dispatch, getState) => {
    try {
      const response = await api.revertCheckin(
        getState().user.user.credentials,
        {
          recordId,
          orderType,
          customerAccountId,
          customerContactId,
          action,
          variantId,
          options,
          eventId: eventId(getState())
        }
      );

      return response;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating checkin"
          }
        ])
      );
    }
  };
}

export function getRecordWithIssuance({
  recordId,
  variantId,
  fulfillmentId,
  orderType,
  customerAccountId,
  customerContactId
}) {
  return async (dispatch, getState) => {
    try {
      const result = await api.getRecordWithIssuance(
        getState().user.user.credentials,
        eventId(getState()),
        recordId,
        variantId,
        fulfillmentId,
        orderType,
        customerAccountId,
        customerContactId
      );
      dispatch({
        type: RECEIVE_RECORD_WITH_ISSUANCE,
        payload: {
          key: `${recordId}_${variantId}`,
          record: result.payload
        }
      });
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching record with issuance"
          }
        ])
      );
    }
  };
}

export function invalidateRecordWithIssuance({ recordId, variantId }) {
  return async dispatch => {
    dispatch({
      type: INVALIDATE_RECORD_WITH_ISSUANCE,
      payload: {
        key: `${recordId}_${variantId}`
      }
    });
  };
}
