import {
  isManifestPending,
  isManifestApproved,
  isManifestRejected
} from "components/Global/Approvals/utils/approvals-helpers";

export default function(value) {
  if (isManifestRejected(value)) return "Rejected";
  if (isManifestApproved(value)) return "Approved";
  if (isManifestPending(value)) return "Pending";
  return "";
}
