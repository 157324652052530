import {
  put,
  call,
  takeEvery,
  all,
  fork,
  select,
  take
} from "redux-saga/effects";
import * as R from "ramda";

import { push } from "react-router-redux";

import { actions, getters } from "EventLight/ProductionSchedules/Schedule";
import {
  selectedEditors,
  getIsSchedulePrivate
} from "EventLight/ProductionSchedules/Schedule/selectors";
import { getPathname } from "App/Router/selectors";

import {
  getters as filterControlsGetters,
  actions as filterControlsActions
} from "ui-kit/FilterControls";

import { actions as TimeTableActions } from "ui-kit/TimeTable/model";
import { actions as ScheduleViewPickerActions } from "Schedules/ScheduleViewPicker";
import { actions as EditorActions } from "Schedules/AllowAccessUsersModal";
import { actions as SchedulesModalActions } from "Schedules/Create";
import * as snackbarActions from "redux/modules/snackbar/actions";
import { actions as ActivityDetailsModalActions } from "Schedules/ActivityDetailsModal/model";
import { actions as LayoutActions } from "EventLight/Layout/model";

import {
  actions as ViewPickerActions,
  getters as ViewPickerGetters
} from "ui-kit/ViewPicker";

import { getCredentials } from "redux/modules/user/selectors";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { registerError } from "redux/modules/errors/actions";
import {
  VIEWPICKER_INSTANCE_ID,
  DEFAULT_SELECTED_USER_PROFILES,
  SCHEDULES_FILTER_INSTANCE_ID,
  SCHEDULE_VIEWS,
  SCHEDULE_INSTANCE_ID,
  SCHEDULE_VIEW_IDS
} from "EventLight/ProductionSchedules/Schedule/constants";

import Api from "../api";

import searchSagas from "./search";
import tableSagas from "./table";
import actionSagas from "./actions";
import timeTableSagas from "./timeTable";

import { getParams } from "./utils";

import { actions as TabActions } from "ui-kit/Tabs";

const init = function*() {
  try {
    yield put(actions.setLoading(true));

    // @FIXME: This current stops all rendering and silently dies.
    // yield take("Schedule/GlobalUpdate");

    const { credentials, eventId } = yield call(getParams);

    //eslint-disable-next-line no-unused-vars
    const [_, moduleId] = yield select(getPathname, {
      match: /\/schedules\/([a-z\d-]+)\/?/i
    });

    const [schedulePayload, preferencesPayload, schedulesPayload] = yield all([
      call(Api.getSchedule, {
        credentials,
        eventId,
        moduleId
      }),
      call(Api.getActiveView, {
        credentials,
        eventId,
        moduleId
      }),
      call(Api.getSchedules, {
        credentials,
        eventId
      })
    ]);
    const schedule = schedulePayload.payload;
    const preferences = preferencesPayload.payload;
    const schedules = schedulesPayload.payload;
    const { users, profiles } = R.propOr(
      DEFAULT_SELECTED_USER_PROFILES,
      "share_with",
      schedule
    );
    const userIds = R.map(R.prop("id"), users);
    const profileIds = R.map(R.prop("id"), profiles);

    yield all([
      put(
        ViewPickerActions.setActiveView(
          {
            activeViewId: preferences.id,
            activeViewName: preferences.name
          },
          {
            meta: {
              instanceId: VIEWPICKER_INSTANCE_ID
            }
          }
        )
      ),
      put(
        actions.setInitialData({
          schedule,
          moduleId,
          scheduleId: schedule.id,
          preferences,
          schedules,
          showNoSchedules:
            schedules.filter(s => s.id !== "master").length === 0,
          quickAddFields: schedule.quick_add_fields,
          selectedView: preferences.mode
        })
      )
    ]);

    yield put(ActivityDetailsModalActions.setScheduleId(moduleId));

    yield put(
      TabActions.setSelectedTab(
        SCHEDULE_VIEW_IDS.includes(preferences.mode)
          ? preferences.mode
          : SCHEDULE_VIEWS.GRID
      )
    );

    yield all([
      put(actions.fetchData()),
      put(actions.fetchViews()),
      put(
        ScheduleViewPickerActions.setInitialData({
          activeViewId: R.propOr(null, "id", preferences),
          activeScheduleId: moduleId,
          schedules
        })
      ),
      put(actions.setSelectedItemIds([...userIds, ...profileIds])),
      put(EditorActions.setSelectedItemIds([...userIds, ...profileIds]))
    ]);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred loading initializing view"
          }
        ])
      )
    ]);
  }
};

const refreshSchedule = function*({ payload: updatedScheduleId }) {
  const { credentials, eventId } = yield call(getParams);
  const moduleId = yield select(getters.moduleId);

  if (updatedScheduleId === moduleId) {
    try {
      const schedulePayload = yield call(Api.getSchedule, {
        credentials,
        eventId,
        moduleId
      });

      const schedule = schedulePayload.payload;

      const { users, profiles } = R.propOr(
        DEFAULT_SELECTED_USER_PROFILES,
        "share_with",
        schedule
      );
      const userIds = R.map(R.prop("id"), users);
      const profileIds = R.map(R.prop("id"), profiles);

      yield put(
        actions.setScheduleData({
          schedule,
          quickAddFields: schedule.quick_add_fields
        })
      );

      yield all([
        put(actions.setSelectedItemIds([...userIds, ...profileIds])),
        put(EditorActions.setSelectedItemIds([...userIds, ...profileIds]))
      ]);
    } catch (error) {
      yield all([
        put(
          registerError([
            {
              system: error,
              user: "An error occurred refreshing schedule"
            }
          ])
        )
      ]);
    }
  }
};

const watchRefreshSchedule = function*() {
  yield takeEvery(actions.refreshSchedule.type, refreshSchedule);
};

const updateSchedule = function*({
  payload: { scheduleName, showOnPortals, showOnProfiles }
}) {
  const eventId = yield select(getEventId);
  const credentials = yield select(getCredentials);
  const moduleId = yield select(getters.moduleId);

  try {
    const { payload } = yield call(
      Api.updateSchedule,
      {
        credentials,
        eventId,
        moduleId
      },
      {
        name: scheduleName,
        showOnPortals,
        showOnProfiles
      }
    );

    yield put(actions.updateScheduleView(payload));

    // Update global navigation view here
    yield put(LayoutActions.refreshNavigation());
  } catch (error) {
    yield put(actions.setIsEditScheduleLoading(false));

    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred white loading the schedule"
        }
      ])
    );
  }
};

const watchUpdateSchedule = function*() {
  yield takeEvery(actions.updateSchedule.type, updateSchedule);
};

const fetchViews = function*() {
  const credentials = yield select(getCredentials);
  const eventIdToUse = yield select(getEventId);
  const orgIdToUse = yield select(getOrgId);
  const moduleId = yield select(getters.moduleId);

  try {
    const { payload: views } = yield call(Api.getViews, {
      credentials,
      eventId: eventIdToUse,
      orgId: orgIdToUse,
      moduleId
    });
    yield put(
      ViewPickerActions.setViews(views, {
        meta: {
          instanceId: VIEWPICKER_INSTANCE_ID
        }
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred gettings views"
        }
      ])
    );
  }
};

const watchFetchViews = function*() {
  yield takeEvery(actions.fetchViews.type, fetchViews);
};

const addView = function*({ payload: newViewData = {} }) {
  const credentials = yield select(getCredentials);
  const eventIdToUse = yield select(getEventId);
  const orgIdToUse = yield select(getOrgId);
  const preferences = yield select(getters.preferences);
  const moduleId = yield select(getters.moduleId);

  try {
    const { payload } = yield call(Api.addView, {
      credentials,
      eventId: eventIdToUse,
      orgId: orgIdToUse,
      moduleId,
      name: newViewData.name
        ? newViewData.name
        : preferences.name && preferences.name.length
        ? preferences.id === "default"
          ? "New View"
          : `${preferences.name} (Copy)`
        : "New View",
      visibleFields: preferences.visible_fields,
      fieldOrder: preferences.field_order,
      sortBy: preferences.sort_by,
      filters: preferences.filters,
      fieldWidths: preferences.field_widths,
      quickFilters: preferences.quick_filters,
      groupedBy: preferences.grouped_by
    });

    yield all([
      put(actions.addViewResponse(payload)),
      put(
        ViewPickerActions.addNewActiveView(payload, {
          meta: {
            instanceId: VIEWPICKER_INSTANCE_ID
          }
        })
      )
    ]);

    yield put(ScheduleViewPickerActions.setActiveViewId(payload.id));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred adding view"
        }
      ])
    );
  }
};

const watchAddView = function*() {
  yield takeEvery(ViewPickerActions.addView.type, addView);
};

const updateView = function*({ payload: data }) {
  const credentials = yield select(getCredentials);
  const eventIdToUse = yield select(getEventId);
  const orgIdToUse = yield select(getOrgId);
  const preferences = yield select(getters.preferences);
  const moduleId = yield select(getters.moduleId);

  try {
    yield call(Api.updateView, {
      credentials,
      eventId: eventIdToUse,
      orgId: orgIdToUse,
      moduleId,
      viewId: preferences.id,
      ...data
    });
    yield put(actions.fetchViews());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating view"
        }
      ])
    );
  }
};

const watchUpdateView = function*() {
  yield takeEvery(
    [ViewPickerActions.updateView.type, ViewPickerActions.updateViewName.type],
    updateView
  );
};

const updateViewName = function*({ payload }) {
  yield put(actions.updateViewName(payload));
};

const watchUpdateViewName = function*() {
  yield takeEvery(ViewPickerActions.updateViewName.type, updateViewName);
};

const revertViewChanges = function*({ payload }) {
  yield put(actions.revertViewChanges(payload));
};

const watchRevertViewChanges = function*() {
  yield takeEvery(ViewPickerActions.revertViewChanges.type, revertViewChanges);
};

const saveViewChanges = function*() {
  const credentials = yield select(getCredentials);
  const eventIdToUse = yield select(getEventId);
  const orgIdToUse = yield select(getOrgId);
  const preferences = yield select(getters.preferences);
  const moduleId = yield select(getters.moduleId);

  try {
    if (preferences.id === "default") {
      const { payload } = yield call(Api.addView, {
        credentials,
        eventId: eventIdToUse,
        orgId: orgIdToUse,
        moduleId,
        name: "New View",
        visibleFields: preferences.visible_fields,
        fieldOrder: preferences.field_order,
        sortBy: preferences.sort_by,
        filters: preferences.filters,
        fieldWidths: preferences.field_widths,
        quickFilters: preferences.quick_filters,
        groupedBy: preferences.grouped_by,
        mode: preferences.mode
      });

      yield all([
        put(
          ViewPickerActions.addNewActiveView(payload, {
            meta: {
              instanceId: VIEWPICKER_INSTANCE_ID
            }
          })
        ),
        put(actions.addViewResponse(payload))
      ]);

      yield put(ScheduleViewPickerActions.setActiveViewId(payload.id));
    } else {
      yield all([
        put(
          ViewPickerActions.updateViewPreferences(preferences, {
            meta: {
              instanceId: VIEWPICKER_INSTANCE_ID
            }
          })
        ),
        put(actions.saveViewChangesResponse(preferences))
      ]);

      yield call(Api.updateView, {
        credentials,
        eventId: eventIdToUse,
        orgId: orgIdToUse,
        moduleId,
        viewId: preferences.id,
        visibleFields: preferences.visible_fields,
        fieldOrder: preferences.field_order,
        sortBy: preferences.sort_by,
        filters: preferences.filters,
        fieldWidths: preferences.field_widths,
        quickFilters: preferences.quick_filters,
        groupedBy: preferences.grouped_by,
        mode: preferences.mode
      });
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred saving view changes"
        }
      ])
    );
  }
};

const watchSaveViewChanges = function*() {
  yield takeEvery(ViewPickerActions.saveViewChanges.type, saveViewChanges);
};

const deleteView = function*({ payload: data }) {
  const credentials = yield select(getCredentials);
  const eventIdToUse = yield select(getEventId);
  const orgIdToUse = yield select(getOrgId);
  const preferences = yield select(getters.preferences);
  const moduleId = yield select(getters.moduleId);

  try {
    yield call(Api.deleteView, {
      credentials,
      eventId: eventIdToUse,
      orgId: orgIdToUse,
      moduleId,
      viewId: preferences.id,
      ...data
    });

    const [activeViewResult] = yield all([
      call(Api.getActiveView, {
        credentials,
        eventId: eventIdToUse,
        orgId: orgIdToUse,
        moduleId
      }),
      put(actions.fetchViews())
    ]);

    yield put(actions.selectActiveView(activeViewResult.payload));

    yield put(actions.fetchData());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred deleting view"
        }
      ])
    );
  }
};

const watchDeleteView = function*() {
  yield takeEvery(ViewPickerActions.deleteView.type, deleteView);
};

// eslint-disable-next-line consistent-return
const selectView = function*({ type, payload }) {
  const credentials = yield select(getCredentials);
  const eventIdToUse = yield select(getEventId);
  const orgIdToUse = yield select(getOrgId);
  const moduleId = yield select(getters.moduleId);

  const views = yield select(ViewPickerGetters.views, {
    instanceId: VIEWPICKER_INSTANCE_ID
  });

  let viewId = payload;

  if (type === ScheduleViewPickerActions.selectViewOfSchedule.type) {
    viewId = payload.viewId;

    if (payload.moduleId !== moduleId) {
      yield call(Api.selectView, {
        credentials,
        eventId: eventIdToUse,
        orgId: orgIdToUse,
        moduleId: payload.moduleId,
        viewId: payload.viewId
      });

      yield put(push(`/event/${eventIdToUse}/schedule/${payload.moduleId}`));
      return true;
    }
  }

  const view = views.find(v => v.id === viewId);

  yield all([
    put(actions.selectView(view)),
    filterControlsActions.updateSelectedOptions(
      R.map(id => ({ id }))(view.quick_filters),
      {
        meta: {
          instanceId: SCHEDULES_FILTER_INSTANCE_ID
        }
      }
    )
  ]);

  try {
    yield call(Api.selectView, {
      credentials,
      eventId: eventIdToUse,
      orgId: orgIdToUse,
      moduleId,
      viewId
    });

    yield put(ScheduleViewPickerActions.setActiveViewId(viewId));

    yield put(actions.fetchData());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred selecting view"
        }
      ])
    );
  }
};

const watchSelectView = function*() {
  yield takeEvery(
    [
      ViewPickerActions.selectView.type,
      ScheduleViewPickerActions.selectViewOfSchedule.type
    ],
    selectView
  );
};

const addActivity = function*({ payload: { values, moduleId: modId } }) {
  const credentials = yield select(getCredentials);
  const eventIdToUse = yield select(getEventId);
  const stateModuleId = yield select(getters.moduleId);
  const moduleId = modId || stateModuleId;

  try {
    yield call(
      Api.addActivity,
      {
        credentials,
        moduleId
      },
      {
        eventId: eventIdToUse,
        moduleId,
        values
      }
    );

    yield put(actions.fetchData());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred adding activity"
        }
      ])
    );
  }
};

const openActivityDetailsModal = function*({
  payload: { activityId = "", values = {} }
}) {
  yield put(actions.setShowActivityDetailsModal(true));
  yield put(ActivityDetailsModalActions.getActivity({ activityId, values }));
};

const hideActivityDetailsModal = function*() {
  yield put(actions.setShowActivityDetailsModal(false));
  yield put(ActivityDetailsModalActions.setActivityId(""));
};

const watchOpenActivityDetailsModal = function*() {
  yield takeEvery(
    actions.openActivityDetailsModal.type,
    openActivityDetailsModal
  );
};

const watchHideActivityDetailsModal = function*() {
  yield takeEvery(
    ActivityDetailsModalActions.hideModal.type,
    hideActivityDetailsModal
  );
};

const watchAddActivity = function*() {
  yield takeEvery(actions.addActivity.type, addActivity);
};

const updateViewFields = function*({ payload: fields }) {
  yield put(
    actions.updateVisibleFields({
      visibleFields: fields.map(f => f.id),
      fieldOrder: fields.reduce((map, f) => {
        map[f.id] = f.order;
        return map;
      }, {})
    })
  );

  yield put(actions.fetchData());

  yield call(saveViewChanges);
};

const watchUpdateViewFields = function*() {
  yield takeEvery(actions.updateViewFields.type, updateViewFields);
};

const updateSelectedSchedule = function*({ payload }) {
  const { scheduleId } = payload;
  const eventId = yield select(getEventId);
  yield put(push(`/event/${eventId}/schedule/${scheduleId}`));
};

const watchSelectedSchedule = function*() {
  yield takeEvery(
    ScheduleViewPickerActions.selectSchedule.type,
    updateSelectedSchedule
  );
};

const saveNewEditors = function*() {
  const isEditModalOpen = yield select(getters.showCreateModal);

  if (isEditModalOpen) return;

  const { users: userIds, profiles: profileIds } = yield select(
    selectedEditors
  );
  const isPrivate = yield select(getIsSchedulePrivate);
  const schedulePayload = {
    isPrivate,
    shareWith: {
      userIds,
      profileIds
    }
  };
  const moduleId = yield select(getters.moduleId);
  const { credentials, eventId } = yield call(getParams);
  const scheduleResponse = yield call(
    Api.updateSchedule,
    {
      credentials,
      eventId,
      moduleId
    },
    schedulePayload
  );
  yield put(actions.setSchedule(R.prop("payload", scheduleResponse)));
  yield put(
    snackbarActions.showSnackbar({
      message: "Schedule Updated",
      action: "OK"
    })
  );
};

const showEditScheduleModal = function*({ payload: scheduleId }) {
  if (scheduleId) {
    yield put(SchedulesModalActions.setScheduleId(scheduleId));
  }
  yield put(actions.setShowCreateModal(true));
  yield put(EditorActions.setSelectedItemIds([]));
};

const showEditorsModal = function*() {
  const selectedItemIds = yield select(getters.selectedItemIds);
  yield put(EditorActions.showModalAndUpdateItemIds({ selectedItemIds }));
};

const selectViewMethod = function*({ payload: tabId }) {
  if (
    [
      SCHEDULE_VIEWS.MONTH,
      SCHEDULE_VIEWS.WEEK,
      SCHEDULE_VIEWS.RESOURCE
    ].includes(tabId)
  ) {
    yield put(
      TimeTableActions.setSelectedRange(tabId, {
        meta: {
          instanceId: SCHEDULE_INSTANCE_ID
        }
      })
    );
    yield put(actions.setSelectedView(tabId));
  } else {
    yield put(actions.setSelectedView(tabId));
  }
};

const watchUpdateEditors = function*() {
  yield takeEvery(EditorActions.onSave.type, saveNewEditors);
};

const watchUpdateScheduleModal = function*() {
  yield takeEvery(
    ScheduleViewPickerActions.editSettings.type,
    showEditScheduleModal
  );
};

const watchShowEditorsModal = function*() {
  yield takeEvery(actions.showEditorsModal.type, showEditorsModal);
};

const watchScheduleFilterChange = function*() {
  for (;;) {
    const { meta } = yield take(filterControlsActions.toggleOption.type);
    if (meta.instanceId === SCHEDULES_FILTER_INSTANCE_ID) {
      const scheduleSelections = yield select(
        filterControlsGetters.selectedOptions,
        {
          instanceId: SCHEDULES_FILTER_INSTANCE_ID
        }
      );
      const preferences = yield select(getters.preferences);
      yield put(
        actions.setPreferences({
          ...preferences,
          quick_filters: R.keys(scheduleSelections || {})
        })
      );
      yield put(actions.fetchData());
    }
  }
};

const watchTabs = function*() {
  yield takeEvery(TabActions.setSelectedTab.type, selectViewMethod);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([
    fork(init),
    fork(searchSagas),
    fork(tableSagas),
    fork(actionSagas),
    fork(timeTableSagas),
    fork(watchInit),
    fork(watchUpdateViewFields),
    fork(watchFetchViews),
    fork(watchAddView),
    fork(watchDeleteView),
    fork(watchUpdateView),
    fork(watchUpdateViewName),
    fork(watchRevertViewChanges),
    fork(watchSelectView),
    fork(watchSaveViewChanges),
    fork(watchAddActivity),
    fork(watchSelectedSchedule),
    fork(watchUpdateEditors),
    fork(watchUpdateScheduleModal),
    fork(watchHideActivityDetailsModal),
    fork(watchOpenActivityDetailsModal),
    fork(watchShowEditorsModal),
    fork(watchScheduleFilterChange),
    fork(watchRefreshSchedule),
    fork(watchUpdateSchedule),
    fork(watchTabs)
  ]);
};

export default rootSaga;
