import React, { cloneElement } from "react";

import { SidebarWrapper } from "ui-kit/Sidebar/View";
import PassesSidebar from "Passes/Main/View/Sidebar";

import { pure } from "utils/General";

const View = ({
  router,
  routes,
  params,
  links,
  setupLink,
  children,
  showModal,
  hideModal,
  showSnackbar
}) => (
  <SidebarWrapper
    instanceId="passes"
    sidebar={
      <PassesSidebar links={links} title="Passes" setupLink={setupLink} />
    }
  >
    {cloneElement(children, {
      router,
      routes,
      params,
      showModal,
      hideModal,
      showSnackbar
    })}
  </SidebarWrapper>
);

export default pure(View);
