import React, { Component } from "react";
import * as R from "ramda";
import { cloneDeep, get, set } from "lodash";
import View from "./View";
import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";
import resolveEditor from "components/Global/StandAloneEditors/utils/resolveEditor";
import * as STANDARD_MODULE_FIELD_IDS from "utils/standard-module-field-ids";
import isValid from "utils/value-types/validations/is-valid";
import isValidEmail from "utils/value-types/validations/email/isEmail";
import autobind from "autobind-decorator";

class Controller extends Component {
  constructor(props) {
    super(props);

    this.state = {
      order: props.order ? cloneDeep(props.order) : {},
      errors: {},
      isValid: true
    };
  }

  componentDidMount() {
    if (typeof this.props.register === "function") {
      this.props.register(this.validate);
    }
  }

  componentWillUnmount() {
    if (typeof this.props.unregister === "function") {
      this.props.unregister(this.validate);
    }
  }

  @autobind
  async validate() {
    const isValid = await this.isValid();
    this.setState({
      isValid
    });
    return isValid;
  }

  @autobind
  async isValid() {
    const errors = {};
    const fields = this.getFields();

    // iterate over required fields
    // any fields with invalid values, add errors for
    for (const field of fields) {
      if (field.required) {
        const isValidValue = isValid(field.value, field.type);

        if (!isValidValue) {
          errors[field.id] = "This is a required question";
        }

        if (field.type === "email") {
          const validEmail = await isValidEmail(field.value);
          if (!validEmail) {
            errors[field.id] = "Please use a valid email address";
          }
        }
      }
    }

    this.setState({
      errors
    });

    return !Object.keys(errors).length;
  }

  getValue = data => {
    if (data) {
      const value = get(this.state.order, data.path);
      if (!value && value !== false) {
        return typeof data.defaultValue === "undefined"
          ? ""
          : data.defaultValue;
      }
      return value;
    }
    return this.state.order;
  };

  updateValue = data => {
    const order = { ...this.state.order };
    const { path, value } = data;
    set(order, path, value);
    this.setState({ order }, async () => {
      this.updateFulfillmentMethod();

      // update valid status
      const isValid = await this.validate();
      this.setState({
        isValid
      });
    });
  };

  updateFulfillmentMethod = () => {
    const { order } = this.state;

    const data = {
      method: this.props.field.settings.method,

      address: {
        addressLine1: order.shipping_address_line_1,
        addressLine2: order.shipping_address_line_2,
        city: order.shipping_city,
        state: order.shipping_state,
        zip: order.shipping_zip,
        country: order.shipping_country
      },

      beingPickedUpBy: {
        fname: order.pickup_person_fname,
        lname: order.pickup_person_lname
      }
    };

    if (this.props.order) {
      return this.props.updateFulfillmentMethod({
        orderId: this.props.order.id,
        ...data
      });
    }
  };

  getFields = () => {
    const { field } = this.props;
    let fields = [];

    if (field.settings.method === "pickup") {
      fields = [
        {
          id: STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME,
          type: "text",
          name: "First Name",
          settings: {},
          required: true,
          value: {
            type: "text",
            value: this.getValue({
              path: ["pickup_person_fname"]
            })
          },
          onValueChange: value =>
            this.updateValue({
              path: ["pickup_person_fname"],
              value: value.value
            })
        },
        {
          id: STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME,
          type: "text",
          name: "Last Name",
          settings: {},
          required: true,
          value: {
            type: "text",
            value: this.getValue({
              path: ["pickup_person_lname"]
            })
          },
          onValueChange: value =>
            this.updateValue({
              path: ["pickup_person_lname"],
              value: value.value
            })
        }
      ].filter(f => f);
    } else if (field.settings.method === "mail") {
      fields = [
        {
          id: "addressLine1",
          type: "text",
          name: "Address Line 1",
          settings: {},
          required: true,
          value: {
            type: "text",
            value: this.getValue({
              path: ["shipping_address_line_1"]
            })
          },
          onValueChange: value =>
            this.updateValue({
              path: ["shipping_address_line_1"],
              value: value.value
            })
        },
        {
          id: "addressLine2",
          type: "text",
          name: "Address Line 2",
          settings: {},
          required: false,
          value: {
            type: "text",
            value: this.getValue({
              path: ["shipping_address_line_2"]
            })
          },
          onValueChange: value =>
            this.updateValue({
              path: ["shipping_address_line_2"],
              value: value.value
            })
        },
        {
          id: "city",
          type: "text",
          name: "City",
          settings: {},
          required: true,
          value: {
            type: "text",
            value: this.getValue({
              path: ["shipping_city"]
            })
          },
          onValueChange: value =>
            this.updateValue({
              path: ["shipping_city"],
              value: value.value
            })
        },
        {
          id: "state",
          type: "text",
          name: "State",
          settings: {},
          required: true,
          value: {
            type: "text",
            value: this.getValue({
              path: ["shipping_state"]
            })
          },
          onValueChange: value =>
            this.updateValue({
              path: ["shipping_state"],
              value: value.value
            })
        },
        {
          id: "zip",
          type: "text",
          name: "Postal Code",
          settings: {},
          required: true,
          value: {
            type: "text",
            value: this.getValue({
              path: ["shipping_zip"]
            })
          },
          onValueChange: value =>
            this.updateValue({
              path: ["shipping_zip"],
              value: value.value
            })
        },
        {
          id: "country",
          type: "text",
          name: "Country",
          settings: {},
          required: true,
          value: {
            type: "text",
            value: this.getValue({
              path: ["shipping_country"]
            })
          },
          onValueChange: value =>
            this.updateValue({
              path: ["shipping_country"],
              value: value.value
            })
        }
      ].filter(f => f);
    }

    return fields;
  };

  render() {
    const { field, eventDetails } = this.props;
    const { errors } = this.state;

    let fields = this.getFields();

    const fieldsWithHandlers = R.map(f => {
      return {
        ...f,
        isEditing: this.props.isEditing,
        isValid: !Boolean(errors[f.id]),
        errors: errors[f.id] ? [errors[f.id]] : null,
        required: f.required,
        disabled: this.props.disabled,
        Editor: resolveEditor(f),
        editorProps: resolveEditorProps(f, eventDetails)
      };
    })(fields);

    return (
      <View
        {...{
          isEnabled: field.settings.method,
          name: field.name,
          description: field.settings.description,
          fields: fieldsWithHandlers
        }}
      />
    );
  }
}

export default Controller;
