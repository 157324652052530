import * as R from "ramda";

import React, { useMemo } from "react";
import { Link, withRouter } from "react-router";
import CSSModules from "react-css-modules";

import { DarkTab } from "components/Base";

import css from "./styles.scss";

const SubNavLink = ({ name = "", active = false, to = "", ...styleProps }) => (
  <Link to={to}>
    <DarkTab active={active} {...styleProps}>
      {name}
    </DarkTab>
  </Link>
);

const isActive = ({ tab, currentRoutes = [] }) => {
  if (typeof tab.isActive === "boolean") {
    return tab.isActive;
  }

  if (Array.isArray(tab.active)) {
    return R.length(R.intersection(tab.active, currentRoutes)) > 0;
  }

  return currentRoutes.includes(tab.id);
};

const decorate = R.compose(
  withRouter,
  CSSModules(css)
);

const SubNav = ({ routes = [], tabs = [], subtabs = [] }) => {
  const currentRoutes = useMemo(() => R.map(R.propOr("", "name"), routes), [
    routes
  ]);
  return (
    <>
      <div styleName="subnav">
        <div styleName="subnavInner">
          {R.map(
            tab => (
              <SubNavLink
                key={tab.id}
                name={tab.name}
                active={isActive({ tab, currentRoutes })}
                to={tab.to}
                style={{
                  paddingBottom: "8px",
                  height: "40px"
                }}
                color="gray4"
                fs={5}
              />
            ),
            tabs
          )}
        </div>
      </div>
      {subtabs.length ? (
        <div styleName="subnavTabs">
          <div styleName="subnavTabsInner">
            {R.map(tab => {
              return (
                <SubNavLink
                  key={tab.id}
                  name={tab.name}
                  active={isActive({ tab, currentRoutes })}
                  to={tab.to}
                  px={0}
                  fs={3}
                />
              );
            }, subtabs)}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default decorate(SubNav);
