import React from "react";
import { connect } from "react-redux";
import { Div, SuperOutlineButton, Text4, Text2 } from "components/Base";
import { PAGES, PASSES_TYPES } from "Forms/WizardModal/constants";
import { actions } from "Forms/WizardModal";
import Header from "Forms/WizardModal/View/Header";
import TitleBox from "Forms/WizardModal/View/Common/LayoutMock/TitleBox";
import Button from "Forms/WizardModal/View/Common/LayoutMock/Button";
import InputFieldStatic from "Forms/WizardModal/View/Common/LayoutMock/InputFieldStatic";

const decorate = connect(
  null,
  {
    back: () => actions.setSelectedPage(PAGES.SELECT_METHOD),
    configureGroupRegistration: actions.configureGroupRegistration,
    goToApplications: () => actions.getRecordTypes({ requestType: "group" }),
    goToApplicationsSelectAccounts: actions.getRecordTypes
  }
);

const Option = ({
  styleProps = {},
  title,
  goToSelectPasses,
  btnText = "SELECT",
  description,
  children = null
}) => (
  <Div
    bra={2}
    ba={2}
    width={1}
    bc={{ default: "neutral2", hover: "info6" }}
    bg="white"
    display="row.space-between.center"
    pr={5}
    mt={2}
    {...styleProps}
    style={{ minHeight: "100px", overflow: "hidden" }}
  >
    <Div display="row.flex-start.center">
      <Div
        width={136}
        bg="neutral1"
        style={{ minHeight: "100px", flexShrink: 0 }}
        display="row.center.center"
        p={4}
      >
        {children}
      </Div>
      <Div pl={5}>
        <Text4 bold color="black">
          {title}
        </Text4>
        <Text2>{description}</Text2>
      </Div>
    </Div>
    <Div
      bra={1}
      bg={{ default: "neutral2", hover: "info6" }}
      color={{
        default: "neutral9",
        hover: "white"
      }}
      px={4}
      py={2}
      style={{ cursor: "pointer", flexShrink: 0 }}
      fs={2}
      transition="fast"
      textAlign="center"
      width={110}
      ml={2}
      onClick={goToSelectPasses}
    >
      {btnText}
    </Div>
  </Div>
);

const SelectType = ({
  handlers,
  back,
  configureGroupRegistration,
  goToApplications,
  goToApplicationsSelectAccounts
}) => (
  <Div width={1} display="column.flex-start" height={1}>
    <Header
      handlers={handlers}
      steps={["Step 1", "Step 2", "Step 3", "Step 4"]}
    />
    <Div
      bg="neutral0"
      display="column.flex-start.center"
      p={5}
      flex={1}
      style={{ overflowY: "auto" }}
    >
      <Div fs={6} fw={4} color="black">
        Select Type of Request / Order Form
      </Div>

      <Div width={1} mt={5}>
        <Option
          title="Individual/Attendee Request Form"
          description="All items will be held under one person's name for the event."
          goToSelectPasses={() => {
            goToApplicationsSelectAccounts({
              requestType: PASSES_TYPES.INDIVIDUAL,
              goToApplications: false
            });
          }}
        >
          <Div width={1}>
            <TitleBox />
            <InputFieldStatic mb={2} />
            <Button title="GA Saturday" />
          </Div>
        </Option>

        <Option
          title="Company/Group Bulk Request Form"
          description="All items requested will be held under the group's name. You will have options to collect/assign individuals names later."
          goToSelectPasses={() => {
            goToApplicationsSelectAccounts({
              requestType: PASSES_TYPES.COMPANY_GROUP,
              goToApplications: false
            });
          }}
        >
          <Div width={1}>
            <TitleBox />
            <InputFieldStatic mb={2} />
            <Button title="All Access" />
          </Div>
        </Option>

        <Option
          title="Group Registration via Form"
          description="All people/attendees are added under the group record - and all items request for each person are help under that person's name - with a reference to their affiliated group."
          goToSelectPasses={goToApplications}
        >
          <Div width={1}>
            <TitleBox />
            <InputFieldStatic mb={2} />
            <Button title="Add Person" />
          </Div>
        </Option>

        <Option
          title="Group Registration via Portal"
          description="Collect registrations for individuals at a group or company via the custom branded event portal (website). Groups can come back and add more at any time - and see approval status in realtime."
          btnText="CONFIGURE"
          goToSelectPasses={() => {
            configureGroupRegistration();
          }}
        >
          <Div width={1}>
            <Button title="Add Person" />
            <Div mt={2}>
              <Button title="Add Person" />
            </Div>
          </Div>
        </Option>
      </Div>
    </Div>

    <Div
      display="row.space-between.center"
      width={1}
      height={90}
      bt={1}
      bc="neutral2"
      bg="white"
      px={7}
      style={{ flexShrink: 0 }}
    >
      <SuperOutlineButton onClick={back}>Back</SuperOutlineButton>
      <SuperOutlineButton onClick={handlers.hideModal}>
        Cancel
      </SuperOutlineButton>
    </Div>
  </Div>
);

export default decorate(SelectType);
