import PropTypes from "prop-types";
import React from "react";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { Div, SearchIcon, BigShadedInput, DarkTab } from "components/Base";
import { CREDENTIAL_TYPE_ID, MEAL_TYPE_ID } from "utils/item-types";
import { withState, mapProps } from "utils/General";

import CredentialItems from "./CredentialItems";
import MealItems from "./MealItems";

const ItemsScene = CSSModules(
  ({
    types = [],
    searchTerm,
    activeType,
    setSearchTerm,
    cateringMealsPerDay,
    eventDateGroups,
    method
  }) => (
    <div>
      <div styleName="items">
        {Boolean(types.length) && (
          <Div pt={3}>
            {R.map(type => (
              <DarkTab
                key={type.id}
                px={2}
                fs={3}
                active={type.active}
                onClick={!type.active ? type.selectType : null}
              >
                {type.name}
              </DarkTab>
            ))(types)}
          </Div>
        )}
        <BigShadedInput
          LeftIcon={SearchIcon}
          onChange={(val, e) => setSearchTerm(val)}
          continuous
          placeholder="Search items..."
          display="row.flex-start.center"
          mb={4}
        />
        {activeType.id === MEAL_TYPE_ID ? (
          <MealItems
            activeType={activeType}
            cateringMealsPerDay={cateringMealsPerDay}
            eventDateGroups={eventDateGroups}
          />
        ) : (
          <CredentialItems
            searchTerm={searchTerm}
            activeType={activeType}
            method={method}
          />
        )}
      </div>
    </div>
  ),
  css
);

ItemsScene.propTypes = {
  types: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      selectType: PropTypes.func,
      active: PropTypes.bool
    })
  ),
  activeType: PropTypes.shape({
    groups: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string
          })
        )
      })
    )
  })
};

const nameMatchesSearch = (searchTerm, name) =>
  name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;

export default R.compose(
  withState("searchTerm", "setSearchTerm", ""),
  mapProps(({ activeType, searchTerm, ...props }) => ({
    activeType: {
      ...activeType,
      groups: R.compose(
        R.reject(
          R.compose(
            R.isEmpty,
            R.prop("items")
          )
        ),
        R.map(g => ({
          ...g,
          items: g.items.filter(i => nameMatchesSearch(searchTerm, i.name))
        }))
      )(activeType.groups)
    },
    searchTerm,
    ...props
  }))
)(ItemsScene);
