/* eslint-disable no-use-before-define */
import {
  delay,
  put,
  select,
  call,
  takeEvery,
  all,
  fork
} from "redux-saga/effects";
import * as R from "ramda";

import { getCredentials } from "redux/modules/user/selectors";
import { registerError } from "redux/modules/errors/actions";

import { actions, getters } from "SendEmailModal";
import {
  getIsCurrentStateValid,
  getCurrentPage,
  getPageSize
} from "SendEmailModal/selectors";
import Api from "SendEmailModal/api";
import { findDOMNode } from "react-dom";

import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { DEFAULT_PAGE_SIZE } from "./constants";

const zeroIndexPage = R.compose(R.dec, R.pathOr(1, ["pagination", "page"]));

const getPayload = (state, send = false, resetPagination = false) => ({
  mode: getters.mode(state),
  emailType: getters.emailType(state),
  moduleId: getters.moduleId(state),
  orgId: getOrgId(state),
  eventId: getEventId(state),
  message: getters.message(state),
  recordIds: getters.records(state),
  sourceRecordId: getters.sourceRecordId(state),
  sourceFormId: getters.sourceFormId(state),
  sourceOrderId: getters.sourceOrderId(state),
  page: resetPagination ? 1 : getCurrentPage(state) + 1,
  pageSize: getPageSize(state),
  options: {
    groupByRecipient: getters.groupByRecipient(state),
    recipientKeys: getters.selectedOptions(state)
      ? [getters.selectedOptions(state)]
      : [],
    send,
    subject: getters.subject(state),
    replyTo: getters.replyTo(state),
    attachments: getters.attachments(state)
  }
});

const handleNextStep = function*() {
  const state = yield select(R.identity);
  const isValid = yield select(getIsCurrentStateValid);
  const activeStepIndex = yield select(getters.activeStepIndex);
  const bodyRef = yield select(getters.bodyRef);
  const emailType = yield select(getters.emailType);
  const invoiceDueDate = yield select(getters.invoiceDueDate);
  const invoiceNotes = yield select(getters.invoiceNotes);
  const records = yield select(getters.records);

  const credentials = yield select(getCredentials);
  const res = {};

  if (isValid) {
    if (activeStepIndex === 0) {
      // generate previews
      yield call(refreshPreviews, { payload: { pageChange: true } });
    } else if (activeStepIndex === 2) {
      // send emails
      yield put(actions.setSendingEmails(true));

      if (emailType === "invoice") {
        yield all(
          records.map(orderId =>
            call(Api.updateOrder, credentials, {
              orderId,
              eventId: getEventId(state),
              order: {
                invoiceDueDate,
                invoiceNotes
              }
            })
          )
        );
      }

      const initialResult = yield call(
        Api.post,
        credentials,
        getPayload(state, true)
      );

      yield delay(300);

      let progressResult = yield call(Api.getStatus, credentials, {
        jobId: initialResult.payload.job_id
      });

      while (
        ["pending", "processing"].includes(progressResult.payload.status)
      ) {
        yield delay(1000);
        progressResult = yield call(Api.getStatus, credentials, {
          jobId: initialResult.payload.job_id
        });
      }

      if (progressResult.payload.status === "error") {
        yield all([
          put(
            registerError([
              {
                system: "An error occurred sending emails",
                user: "An error occurred sending emails"
              }
            ])
          ),
          put(actions.setSendingEmails(false))
        ]);
        return false;
      }

      res.sendingEmails = false;
    }

    findDOMNode(bodyRef).scrollTop = 0;

    yield put(
      actions.handleResponse({
        preview: null,
        activeStepIndex: activeStepIndex + 1,
        generatingPreviews: false,
        sendingEmails: false,
        ...res
      })
    );
  }

  return true;
};

const refreshPreviews = function*(
  { payload: { pageChange = false } } = { payload: {} }
) {
  const state = yield select(R.identity);
  const credentials = yield select(getCredentials);

  yield put(actions.setPreview(null));
  yield put(actions.setGeneratingPreviews(true));

  try {
    const { payload } = yield call(
      Api.getPreviews,
      credentials,
      getPayload(state, false)
    );

    const pagination = {
      ...payload.pagination,
      page: zeroIndexPage(payload)
    };

    yield put(
      actions.handleResponse({
        preview: null,
        generatingPreviews: false,
        messagePreviews: payload.previews,
        pagination: pageChange
          ? pagination
          : {
              pageSize: DEFAULT_PAGE_SIZE,
              pages: 0,
              page: 0
            }
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred getting preview"
        }
      ])
    );
  } finally {
    yield put(actions.setGeneratingPreviews(false));
  }

  return true;
};

const setCurrentPage = function*() {
  yield call(refreshPreviews, { payload: { pageChange: true } });
};

const getPreview = function*({ payload: { parentRecordId, childRecordId } }) {
  try {
    const state = yield select(R.identity);
    const credentials = yield select(getCredentials);

    yield put(actions.setGeneratingPreviews(true));

    const { payload: previewPayload } = yield call(
      Api.getPreview,
      credentials,
      {
        ...getPayload(state, false),
        parentRecordId,
        childRecordId
      }
    );

    yield put(
      actions.setPreview({
        id: previewPayload.meta.id,
        subject: previewPayload.variables.subject,
        phone: previewPayload.meta.phone,
        email: previewPayload.meta.email,
        name: previewPayload.meta.name,
        content: previewPayload.preview
      })
    );
    yield put(actions.setGeneratingPreviews(false));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred getting preview"
        }
      ])
    );
  }

  return true;
};

const watchHandleNextStep = function*() {
  yield takeEvery(actions.handleNextStep.type, handleNextStep);
};

const watchRefreshPreviews = function*() {
  yield takeEvery(actions.refreshPreviews.type, refreshPreviews);
};

const watchGetPreview = function*() {
  yield takeEvery(actions.getPreview.type, getPreview);
};

const watchSetPage = function*() {
  yield takeEvery(actions.setCurrentPage.type, setCurrentPage);
};

const rootSaga = function*() {
  yield all([
    fork(watchHandleNextStep),
    fork(watchRefreshPreviews),
    fork(watchGetPreview),
    fork(watchSetPage)
  ]);
};

export default rootSaga;
