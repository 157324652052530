import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import { get, has } from "lodash";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class Day extends Component {
  getBackgroundColor(data) {
    return data.isSelected === true ? data.color : "#FFFFFF";
  }

  getTextColor(data) {
    return data.isSelected === true ? "#FFFFFF" : data.color;
  }

  render() {
    const {
      meta,
      disabled,
      isAvailable,
      availableDayMap,
      onClick
    } = this.props;
    const styles =
      isAvailable && has(availableDayMap, meta.ydm)
        ? {
            backgroundColor: this.getBackgroundColor(availableDayMap[meta.ydm]),
            color: this.getTextColor(availableDayMap[meta.ydm])
          }
        : {};
    return (
      <div
        styleName={
          isAvailable && !disabled ? "selectableContainer" : "container"
        }
        style={styles}
        onClick={
          isAvailable && !disabled && onClick
            ? onClick.bind(this, meta.ydm)
            : undefined
        }
      >
        {isAvailable ? meta.day : ""}
      </div>
    );
  }
}

Day.propTypes = {
  meta: PropTypes.object.isRequired,
  isAvailable: PropTypes.bool.isRequired,
  availableDayMap: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default Day;
