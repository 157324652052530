import Helpers from "utils/Global/Helpers";
import getQuery from "redux/modules/modules/utils/get-query";

module.exports = {
  get: (credentials, { moduleId, options }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery(options);

      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/modules/${moduleId}/records${query || ""}`,
        credentials,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */,

  bulkPost: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery(data.options);

      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/modules/records${query || ""}`,
        credentials,
        data,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */,

  post: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery(data.options);

      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/modules/${
          data.moduleId
        }/records${query || ""}`,
        credentials,
        data,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */,

  put: (credentials, { moduleId, recordId, type, options }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery(options);

      Helpers.request({
        method: "put",
        url: `${
          window.__LENND_API_BASE_URL__
        }/modules/${moduleId}/record/${recordId}/type${query || ""}`,
        credentials,
        data: {
          type
        },
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */,

  delete: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery(data.options);

      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/modules/${
          data.moduleId
        }/record${query || ""}`,
        credentials,
        data,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */,

  search: (credentials, eventId, moduleId, query, fields) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/modules/${moduleId}/records/search`,
        qs: {
          q: query,
          eventId,
          field: fields
        },
        credentials,
        success,
        error
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  assignEvents: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery(data.options);

      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/modules/${
          data.moduleId
        }/records${query || ""}/events`,
        credentials,
        data,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */,

  unassignEvents: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const query = getQuery(data.options);

      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/modules/${
          data.moduleId
        }/records${query || ""}/events`,
        credentials,
        data,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */
};
