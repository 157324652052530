import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { Link } from "react-router";

const SidebarNavItem = ({
  linkTo,
  iconRight,
  active = false,
  count = 0,
  children,
  ...props
}) => {
  if (linkTo) {
    return (
      <Link
        to={linkTo}
        className={active ? css.itemActive : css.item}
        {...props}
      >
        {children}
        {count > 0 ? <div styleName="badge">{count}</div> : ""}
        {iconRight}
      </Link>
    );
  }
  return (
    <div className={active ? css.itemActive : css.item} {...props}>
      {children}
      {count > 0 ? <div styleName="badge">{count}</div> : ""}
      {iconRight}
    </div>
  );
};

SidebarNavItem.propTypes = {
  active: PropTypes.bool,
  count: PropTypes.number,
  linkTo: PropTypes.string,
  iconRight: PropTypes.element
};

export default CSSModules(SidebarNavItem, css);
