import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

function IntegrationSuccessFail({ success = false, onSuccess }) {
  if (success) {
    return (
      <div styleName="wrapper">
        <div styleName="container" onMouseDown={e => e.stopPropagation()}>
          <div styleName="iconWrapper">
            <i className="material-icons">check</i>
          </div>
          <div styleName="message">
            You have successfully authenticated with this provider.
          </div>
          <div styleName="button" onClick={onSuccess}>
            Continue
          </div>
        </div>
      </div>
    );
  }
  return (
    <div styleName="wrapper">
      <div styleName="container">
        <div styleName="iconWrapperFail">
          <i className="material-icons">close</i>
        </div>
        <div styleName="message">Authentication failed, please try again.</div>
        <div styleName="button">Okay</div>
      </div>
    </div>
  );
}

IntegrationSuccessFail.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  success: PropTypes.bool
};

export default CSSModules(IntegrationSuccessFail, css);
