import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "Portal/PortalPagePGA/AllReplaysList/constants";

const DEFAULT_PAGINATION = {
  pageSize: 15,
  pages: 0,
  page: 0
};

const iniState = {
  show: "",
  player: "all_players",
  sortBy: "newest",
  pagination: DEFAULT_PAGINATION,
  videos: [],
  players: [],
  loading: true
};

export const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    search: R.identity,
    setInitialData: (
      _,
      {
        payload: { show, player, sort_by: sortBy, pagination, videos, players }
      }
    ) => ({
      loading: false,
      show,
      player,
      sortBy,
      pagination,
      videos,
      players
    }),
    reset: () => ({
      player: "all_players",
      sortBy: "newest"
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
