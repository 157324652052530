import React from "react";

import {
  AddIcon,
  Collapsable,
  Div,
  DownIcon,
  // DownArrowIcon,
  EditIcon,
  FontIcon,
  PeopleIcon,
  RightIcon,
  SimpleTable,
  SmallTextButton,
  SmallToggle,
  // Text0,
  Text1,
  Text2,
  Text3,
  DownFilledIcon,
  collapsableHandler,
  MediumOutlineButton,
  CheckCircleIcon,
  PopoverMenu
} from "components/Base";
import { addS, withProps } from "utils/General";
import Avatar from "components/PortalV2/GroupManager/ScalableAvatar";
import Loading from "components/Event/Settings/Module/Loading";
import DatePicker from "components/Event/FormsV2/Modals/FormEditModal/Calendar";
import IntakeSettingsModal from "Portal/IntakeSettingsModal/View";
import CustomizeSectionsModal from "Portal/CustomizeSectionsModal/View";

const headerBgColor = "neutral0";
const borderProps = { bra: 1, bc: "neutral3", ba: 1 };

const CollapseIcon = ({ collapsed, ...props }) =>
  collapsed ? (
    <RightIcon color="neutral8" {...props} />
  ) : (
    <DownIcon color="neutral8" {...props} />
  );

const PrimaryHeader = ({
  onClick,
  title,
  headingExtras,
  headingProps = {},
  caption,
  ...props
}) => (
  <Div display="row.flex-start.center" px={2} {...props}>
    <Div flex={1} p={2} onClick={onClick}>
      <Div fs={5} fw={3} color="gray9" {...headingProps}>
        {title}
      </Div>
      {caption && <Text1 color="neutral6">{caption}</Text1>}
    </Div>
    <Div pr={3} display="row.flex-end.center">
      {headingExtras}
    </Div>
  </Div>
);

const InsetCollapseCard = collapsableHandler(
  ({
    collapsed,
    toggleCollapsed,
    title,
    headingExtras,
    children,
    disabled
  }) => (
    <Div {...borderProps} mb={2}>
      <Div p={2} pr={3} bg={headerBgColor} display="row.flex-start.center">
        <CollapseIcon collapsed={collapsed} fs={3} onClick={toggleCollapsed} />
        <Text3 onClick={toggleCollapsed} flex={1}>
          {title}
        </Text3>
        <Div display="row.flex-end.center">
          {headingExtras({ collapsed, toggleCollapsed })}
        </Div>
      </Div>
      <Collapsable collapsed={collapsed}>
        <Div
          bc="neutral3"
          bg="white"
          bt={1}
          p={3}
          style={{ position: "relative" }}
        >
          {disabled && (
            <div
              style={{
                backgroundColor: "rgba(255,255,255,0.6)",
                cursor: "default",
                height: "100%",
                position: "absolute",
                right: 0,
                top: 0,
                width: "100%"
              }}
            />
          )}
          {children}
        </Div>
      </Collapsable>
    </Div>
  )
);

const PrimaryPanel = ({
  children,
  title,
  caption,
  headingExtras,
  headingProps
}) => (
  <Div bra={1} ba={1} bc="neutral3" bg="white" mb={5}>
    {title ? (
      <PrimaryHeader
        title={title}
        caption={caption}
        headingExtras={headingExtras}
        headingProps={headingProps}
        py={2}
      />
    ) : null}
    {children ? <Div p={2}>{children}</Div> : null}
  </Div>
);

const OptionWithToggle = ({ title, caption, isToggled = false, onClick }) => (
  <OptionWrapper>
    <OptionText title={title} caption={caption} isToggled={isToggled} />
    <SmallToggle active={isToggled} onClick={onClick} />
  </OptionWrapper>
);
const OptionText = ({ title, caption, isToggled = true }) => (
  <Div flex={1}>
    <Div fw={3} fs={3} color={isToggled ? "neutral8" : "neutral5"} pr={3}>
      {title}
    </Div>
    <Div color="neutral6" pr={3}>
      {caption}
    </Div>
  </Div>
);
const OptionWrapper = ({ children }) => (
  <Div display="row.flex-start.center" py={2}>
    {children}
  </Div>
);

const OptionsArrowIcon = withProps({
  color: "primary7",
  fs: 1
})(DownFilledIcon);

const CopyLink = ({
  copyButtonText = "Copy Link",
  link = "http",
  onClickLinkInput,
  onCopyLinkInput,
  ...props
}) => {
  let textInput = React.createRef();

  return (
    <Div display="row.flex-start.center" mb={8} flex={1} {...props}>
      <input
        ref={textInput}
        style={{
          display: "flex",
          flex: 1,
          background: "none",
          border: 0,
          outline: "none",
          fontSize: 15,
          color: "#494949",
          cursor: "pointer",
          backgroundColor: "#efefef",
          height: 40,
          padding: "0 15px",
          borderRadius: "5px 0 0 5px"
        }}
        value={link}
        onChange={() => {}}
        onClick={() => onClickLinkInput(textInput)}
      />
      <Div
        bg={{
          default: "purple8",
          hover: "purple9"
        }}
        color="white"
        onClick={() => onCopyLinkInput(textInput)}
        display="row.center.center"
        px={3}
        fw={3}
        style={{
          borderRadius: "0 5px 5px 0"
        }}
        width={150}
        fs={1}
        height={40}
      >
        <FontIcon color="white" mr={2} fs={2}>
          file_copy
        </FontIcon>
        {copyButtonText}
      </Div>
    </Div>
  );
};

const BlockTable = SimpleTable({
  RowStyler: ({ children, onEdit }) => (
    <Div
      display="row.flex-start.center"
      py={2}
      bg={{ default: "white", hover: "neutral0" }}
      onClick={onEdit}
    >
      {children}
    </Div>
  ),
  columnProps: [
    [3 / 12, "left"],
    [2 / 12, "right"],
    [3 / 12, "right"],
    [3 / 12, "right"],
    [1 / 12, "right"]
  ],
  headerCellComps: ["Block Name", "Required", "# of Items", "Item Type", ""],
  rowCellComps: [
    "name",
    ({ required, toggleRequired }) => (
      <SmallToggle
        active={required}
        onClick={e => {
          e.stopPropagation();
          toggleRequired();
        }}
      />
    ),
    "quantity",
    "type",
    ({}) => <EditIcon sizeWFS={2} />
  ]
});

const PrimaryOptions = ({
  isAccountPortal,
  people,
  intakeForm,
  intakeFormUrl,
  closeDate,
  isOpen,
  isLocked,
  toggleIsLocked,
  updateCloseDate,
  // showFormWizardModal,
  openIntakeSettingsModal,
  onClickLinkInput,
  onCopyLinkInput,
  disableIntakeForm,
  // applicationForms,
  // showMessageModal,
  // hasMessage,
  openCustomizeSectionsModal
}) => (
  <>
    {isAccountPortal ? (
      <Div display="row.flex-start.center" mb={3}>
        <img
          src="https://d2dks4tzxs6xee.cloudfront.net/img/icons8-downloads.png"
          height={24}
        />
        <Div fs={5} color="gray7" fw={4} ml={3}>
          Getting Groups into your Event
        </Div>
      </Div>
    ) : null}
    <Div>
      {/* intake */}
      {isAccountPortal ? (
        <>
          {intakeForm.is_enabled ? (
            <Div bg="gray0" bra={1} p={4} width={1} mb={2}>
              <Div mb={3}>
                <Div display="row.flex-start.center">
                  <Text3 bold color="black">
                    Intake Form & Portal Onboarding Steps
                  </Text3>
                  <CheckCircleIcon ml={1} color="green5" />
                </Div>
                <Text1
                  mt={1}
                  style={{
                    maxWidth: "400px"
                  }}
                >
                  Onboard new or existing groups directly to their event portal
                  without being reviewed by an administrator.
                </Text1>
              </Div>

              <CopyLink
                copyButtonText="Copy Invite Link"
                link={intakeFormUrl}
                mb={3}
                onClickLinkInput={onClickLinkInput}
                onCopyLinkInput={onCopyLinkInput}
              />

              <Div display="row.space-between.center">
                <MediumOutlineButton onClick={openIntakeSettingsModal}>
                  <Text2 color="primary7" bold>
                    Edit Intake Form
                  </Text2>
                </MediumOutlineButton>

                <PopoverMenu
                  Label={({ onClick }) => (
                    <MediumOutlineButton
                      onClick={onClick}
                      RightIcon={OptionsArrowIcon}
                    >
                      <Text2 color="primary7" bold>
                        Options
                      </Text2>
                    </MediumOutlineButton>
                  )}
                  menuItems={[
                    [
                      "Disable Intake Form",
                      () => disableIntakeForm(intakeForm.id)
                    ]
                  ]}
                />
              </Div>
            </Div>
          ) : (
            <Div
              bg="white"
              bra={1}
              shadow={2}
              display="row.space-between.center"
              p={4}
              width={1}
              mb={2}
            >
              <Div>
                <Div display="row.flex-start.center">
                  <Text3 bold color="black">
                    Intake Form & Portal Onboarding Steps
                  </Text3>
                  {/*
            <Div py={1} px={2} bg="warning2" bra={3} ml={1}>
              <Text0 color="black">Not Completed</Text0>
            </Div>
            */}
                </Div>
                <Text1
                  mt={1}
                  style={{
                    maxWidth: "400px"
                  }}
                >
                  Onboard new or existing groups directly to their event portal
                  without being reviewed by an administrator.
                </Text1>
              </Div>
              <MediumOutlineButton onClick={openIntakeSettingsModal}>
                <Text2 color="primary7" bold>
                  Set up Intake Form
                </Text2>
              </MediumOutlineButton>
            </Div>
          )}

          {/*
    <Div
      bg={applicationForms.length ? "gray0" : "white"}
      bra={1}
      shadow={applicationForms.length ? 0 : 2}
      p={4}
      width={1}
      mb={2}
    >
      <Div display="row.space-between.center">
        <Div>
          <Div display="row.flex-start.center">
            <Text3 bold color="black">
              Application Forms
            </Text3>
          </Div>
          <Text1
            mt={1}
            style={{
              maxWidth: "400px"
            }}
          >
            Have new groups apply & be reviewed by an administrator before
            continuing to their event portal.
          </Text1>
        </Div>
        <MediumOutlineButton>
          <Text2 color="primary7" bold onClick={showFormWizardModal}>
            Create an application form
          </Text2>
        </MediumOutlineButton>
      </Div>

      {applicationForms.length ? (
        <Div pt={3}>
          {applicationForms.map(f => (
            <Div bg="white" bra={1} shadow={2} px={4} py={3} width={1} mb={3}>
              <Div display="row.space-between.center" mb={3}>
                <Div display="row.flex-start.center" fw={3} color="black">
                  {f.name}
                  {f.isClosed ? (
                    <Div py={1} px={2} bg="red2" bra={3} ml={2}>
                      <Text0 color="gray8">Closed</Text0>
                    </Div>
                  ) : (
                    <Div py={1} px={2} bg="green1" bra={3} ml={2}>
                      <Text0 color="gray8">Open</Text0>
                    </Div>
                  )}
                </Div>
                <Div>
                  <PopoverMenu
                    Label={({ onClick }) => (
                      <MediumOutlineButton
                        onClick={onClick}
                        RightIcon={OptionsArrowIcon}
                      >
                        <Text2 color="primary7" bold>
                          Options
                        </Text2>
                      </MediumOutlineButton>
                    )}
                    menuItems={[
                      ["Form Builder", () => f.goToFormEditor()],
                      ["Form Settings", () => f.goToFormSettings()],
                      ["Share Form", () => f.goToFormShare()]
                    ]}
                  />
                </Div>
              </Div>

              <CopyLink
                copyButtonText="Copy Form Link"
                link={f.url}
                mb={3}
                onClickLinkInput={onClickLinkInput}
                onCopyLinkInput={onCopyLinkInput}
              />
            </Div>
          ))}
        </Div>
      ) : null}
    </Div>

    */}
        </>
      ) : null}

      {/* portal settings and messages */}
      <Div display="row.flex-start.center" mb={3} mt={8}>
        <img
          src="https://d2dks4tzxs6xee.cloudfront.net/img/icons8-services.png"
          height={24}
        />
        <Div fs={5} color="gray7" fw={4} ml={3}>
          Portal Settings & Messages
        </Div>
      </Div>

      <Div
        bg="white"
        bra={1}
        shadow={2}
        display="row.space-between.center"
        p={4}
        width={1}
        mb={2}
      >
        <Div>
          <Div display="row.flex-start.center">
            <Text3 bold color="black" mr={1}>
              Customize Welcome Messages & Sections
            </Text3>
            {/* <CheckCircleIcon size={16} color="altA5" /> */}
          </Div>
          <Text1 mt={1}>
            You can show/hide and adjust the titles of portal sections as well
            as
          </Text1>
          <Text1>add instrucional text to each page.</Text1>
        </Div>
        <MediumOutlineButton onClick={openCustomizeSectionsModal}>
          <Text2 color="primary7" bold>
            Customize Sections
          </Text2>
        </MediumOutlineButton>
      </Div>

      {/* close date */}
      {isAccountPortal ? (
        <PrimaryPanel>
          <Div display="column" pt={2} px={2}>
            <Div display="row.space-between.center" mb={6}>
              <Div fs={3} fw={3} color="gray9">
                Close Date
              </Div>

              <Div display="column.center.flex-end">
                <DatePicker
                  label="Set close date"
                  value={closeDate}
                  onChange={updateCloseDate}
                />
                <Div fs={1} color="neutral6">
                  If set, no additions or changes will be allowed after
                  specified date
                </Div>
              </Div>
            </Div>

            <Div display="row.space-between.center" mb={4}>
              <Div display="column">
                <Div fs={3} fw={3} color="gray9">
                  Allow additions and changes
                </Div>
                <Div fs={1} color="neutral6">
                  Groups of this type will be able to add and update existing
                  people
                </Div>
              </Div>

              {closeDate ? (
                isLocked ? (
                  <Div display="column.center.flex-end">
                    <Div fw={3} color="red7">
                      Closed
                    </Div>
                    <Div fs={1} color="neutral6">
                      Closed because close date has passed
                    </Div>
                  </Div>
                ) : (
                  <Div display="column.center.flex-end">
                    <Div fw={3} color="green7">
                      Open
                    </Div>
                    <Div fs={1} color="neutral6">
                      Will not be able to add or update people when close date
                      passes
                    </Div>
                  </Div>
                )
              ) : (
                <Div display="row.flex-start.center">
                  {isOpen ? (
                    <Div fw={3} color="green7" mr={3}>
                      Open
                    </Div>
                  ) : (
                    <Div fw={3} color="red7" mr={3}>
                      Closed
                    </Div>
                  )}
                  <SmallToggle active={isOpen} onClick={toggleIsLocked} />
                </Div>
              )}
            </Div>
          </Div>
        </PrimaryPanel>
      ) : null}

      {/* people types */}
      {isAccountPortal && Boolean(people.peopleTypes.length) && (
        <PrimaryPanel
          title="People types this group can add"
          caption="Specify what types of people a group can add, and the types of things they can request."
        >
          {people.peopleTypes.map(p => (
            <InsetCollapseCard
              key={p.id}
              collapsedDefault={!p.active}
              disabled={!p.active}
              title={
                <Div fw={3} display="row.flex-start.center" color="neutral8">
                  <Avatar
                    size={25}
                    style={{ backgroundColor: p.backgroundColor }}
                    content={
                      p.icon ? (
                        <FontIcon
                          fs={3}
                          style={{ color: p.color, opacity: 0.6 }}
                        >
                          {p.icon}
                        </FontIcon>
                      ) : (
                        <PeopleIcon />
                      )
                    }
                    mr={2}
                    ml={2}
                  />
                  {p.name}
                </Div>
              }
              headingExtras={({ toggleCollapsed, collapsed }) => (
                <SmallToggle
                  active={p.active}
                  onClick={() => {
                    if (!p.active && collapsed) toggleCollapsed();
                    p.toggleType();
                  }}
                />
              )}
            >
              <PrimaryPanel
                title={"Select fields to show"}
                caption="The fields that are filled out per person"
                headingExtras={
                  <Div>
                    <Div
                      onClick={p.showEditColumnsModal}
                      fs={2}
                      fw={3}
                      color="purple8"
                    >
                      {p.countOfFieldsSelected} field
                      {addS(p.countOfFieldsSelected)} selected
                    </Div>
                  </Div>
                }
                headingProps={{
                  fs: 3
                }}
              />

              <Div {...borderProps} py={2} px={4}>
                <OptionWithToggle
                  isToggled={p.canRequestItems}
                  onClick={p.toggleCanRequestItems}
                  title="Available Items to Request"
                />
                {p.canRequestItems ? (
                  <Div>
                    <SmallTextButton
                      color="primary6"
                      onClick={p.onAddItemBlock}
                      LeftIcon={AddIcon}
                    >
                      Assign / Create
                    </SmallTextButton>
                    {p.availableItems.length ? (
                      <BlockTable rowsData={p.availableItems} />
                    ) : (
                      ""
                    )}
                  </Div>
                ) : null}
              </Div>

              {/*
            <Div
              bg="white"
              bra={1}
              shadow={2}
              display="row.space-between.center"
              p={4}
              width={1}
              mb={2}
              mt={2}
            >
              <Div>
                <Div display="row.flex-start.center">
                  <img
                    src="https://d2dks4tzxs6xee.cloudfront.net/img/icons8-fantasy.png"
                    height={24}
                  />
                  <Text3 bold color="black" ml={1} mr={1}>
                    Auto-Assign Items to these people
                  </Text3>
                  <Div p={1} bg="neutral2" bra={3} ml={1} px={1}>
                    <Text0 color="black">Premium</Text0>
                  </Div>
                </Div>
                <Text1 mt={1}>
                  Automatically add credentials, tickets, meals or other items
                  to
                </Text1>
                <Text1>these people when they are added to the event.</Text1>
              </Div>
              <MediumOutlineButton onClick={openIntakeSettingsModal}>
                <Text2 color="primary7" bold>
                  Enable & Assign Items
                </Text2>
              </MediumOutlineButton>
            </Div>
            */}
            </InsetCollapseCard>
          ))}
        </PrimaryPanel>
      )}
    </Div>
  </>
);

const View = ({
  loading,
  selectedTypeName,
  permissionProfileId,
  people,
  onUpdateSelectedIntakeForm,
  selectedIntakeForm,
  accountIntakeForms,
  contactIntakeForms,
  hasMessage,
  showMessageModal,
  closeDate,
  isOpen,
  isLocked,
  toggleIsLocked,
  updateCloseDate,
  showFormWizardModal,
  showIntakeSettingsModal,
  hideIntakeSettingsModal,
  openIntakeSettingsModal,
  fetchPermissionSets,
  intakeForm,
  intakeFormUrl,
  onClickLinkInput,
  onCopyLinkInput,
  disableIntakeForm,
  applicationForms,
  showCustomizeSectionsModal,
  hideCustomizeSectionsModal,
  openCustomizeSectionsModal,
  customizeSettings,
  isAccountPortal
}) =>
  loading ? (
    <Loading />
  ) : (
    <Div display="row">
      {showIntakeSettingsModal && (
        <IntakeSettingsModal
          hideModal={hideIntakeSettingsModal}
          permissionSetId={permissionProfileId}
          fetchPermissionSets={fetchPermissionSets}
          intakeForm={intakeForm}
          selectedTypeName={selectedTypeName}
        />
      )}
      {showCustomizeSectionsModal && (
        <CustomizeSectionsModal
          hideCustomizeSectionsModal={hideCustomizeSectionsModal}
          selectedTypeName={selectedTypeName}
          permissionSetId={permissionProfileId}
          settings={customizeSettings}
          fetchPermissionSets={fetchPermissionSets}
        />
      )}
      <Div px={8} pt={5} pb={2} flex={1}>
        <PrimaryOptions
          {...{
            key: permissionProfileId,
            isAccountPortal,
            intakeForm,
            intakeFormUrl,
            people,
            accountIntakeForms,
            contactIntakeForms,
            selectedIntakeForm,
            onUpdateSelectedIntakeForm,
            hasMessage,
            showMessageModal,
            closeDate,
            isOpen,
            isLocked,
            toggleIsLocked,
            updateCloseDate,
            showFormWizardModal,
            openIntakeSettingsModal,
            onClickLinkInput,
            onCopyLinkInput,
            disableIntakeForm,
            applicationForms,
            openCustomizeSectionsModal
          }}
        />
      </Div>
    </Div>
  );

export default View;
