import Helpers from "utils/Global/Helpers";

module.exports = {
  get: (credentials, invoiceId) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/orders/invoices/${invoiceId}`,
        credentials,
        success,
        error
      });
    }),
  /* eslint-enable no-underscore-dangle */

  addInvoiceByOrderId: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/orders/invoices/order/${
          data.orderId
        }`,
        credentials,
        data,
        success,
        error
      });
    }),
  /* eslint-enable no-underscore-dangle */

  addInvoiceTransaction: (credentials, data) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/orders/invoices/order/${
          data.orderId
        }/transactions`,
        credentials,
        data,
        success,
        error
      });
    })
};
