import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { pathOr } from "ramda";
import People from "../ExternalController";
import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";

import { people, isFetching } from "redux/modules/portal/people/selectors";
import { eventDetails } from "redux/modules/portal/selectors";
import { portalUser } from "redux/modules/portal/user/selectors";
import { settings } from "redux/modules/portal/settings/selectors";

import { getPeople } from "redux/modules/portal/people/actions";
import { getSettings } from "redux/modules/portal/settings/actions";
import { addRelationship } from "redux/modules/accounts/contactRelationship/actions";
import { addRecord } from "redux/modules/modules/records/actions";
import { removePerson } from "redux/modules/portal/person/actions";

function mapStateToProps(state) {
  const details = eventDetails(state);
  const peopleToPass = people(state);

  return {
    eventDetails: {
      id: details.id,
      uuid: details.uuid,
      ...resolveEditorProps({ type: "event-days" }, details)
    },
    accountId: pathOr("", ["active_view", "id"], portalUser(state)),
    people: peopleToPass,
    isFetching: isFetching(state),
    allPeople: peopleToPass.reduce((a, b) => {
      return [...a, ...b.contacts];
    }, []),
    settings: settings(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addRecord,
      getPeople,
      addRelationship,
      removePerson,
      getSettings
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(People);
