import { put, take, takeEvery, fork, select, all } from "redux-saga/effects";
import * as R from "ramda";

import { getters, actions } from "./model";
import { getLogRows, getColsByType } from "./selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { orgId as getOrganizationId } from "redux/modules/organization/selectors";

import { actions as SearchBarActions } from "ui-kit/SearchBar";
import { actions as TableActions } from "ui-kit/Table/model";
import { getUsers as fetchUsers } from "redux/modules/eventUsers/actions";
import { getUsers as fetchOrgUsers } from "redux/modules/organization/users/actions";

import {
  ACTIVITY_INSTANCE_ID,
  TABLE_LOGS_INSTANCE_ID,
  COL_WIDTH,
  RECORD_TYPES
} from "./constants";

const onRefreshData = function*() {
  yield put(
    actions.search(null, {
      meta: {
        instanceId: ACTIVITY_INSTANCE_ID
      }
    })
  );
};

const loadLogsOfActivity = function*({
  payload: { id: activityId, type: activityType, moduleId }
}) {
  const openedRows = yield select(getters.openedRows);
  if (!R.isNil(openedRows[activityId])) {
    const logRows = yield select(getLogRows, {
      activityType,
      moduleId,
      rows: openedRows[activityId]
    });
    const columns = yield select(getColsByType, { activityType });
    yield put(
      TableActions.setData(
        {
          canEditCells: false,
          columns,
          rows: logRows,
          columnWidths: COL_WIDTH
        },
        {
          meta: {
            instanceId: `${TABLE_LOGS_INSTANCE_ID}${activityId}`
          }
        }
      )
    );
  }
};

const showRecordModal = function*(/* { payload: { recordId, moduleId } }*/) {
  // PENDING: execute this action
};

const showOrderModal = function*(/* { payload: { recordId, moduleId } }*/) {
  // PENDING: execute this action
};

const showEmailModal = function*(/* { payload: { recordId, moduleId } }*/) {
  // PENDING: execute this action
};

const watchSearch = function*() {
  yield takeEvery(
    action =>
      (action.type === SearchBarActions.setSearchTerm.type ||
        action.type === SearchBarActions.clearSearch.type) &&
      R.path(["meta", "instanceId"], action) === ACTIVITY_INSTANCE_ID,
    onRefreshData
  );
};

const watchOnOpenActivityLogs = function*() {
  yield takeEvery(actions.toggleActivityLog.type, loadLogsOfActivity);
};

const watchActionExecuted = function*() {
  for (;;) {
    const action = yield take(actions.executeAction.type);
    const delegate = R.prop(action.payload.actionType, {
      [RECORD_TYPES.ADD_RECORD]: showRecordModal,
      [RECORD_TYPES.UPDATE_RECORD]: showRecordModal,
      [RECORD_TYPES.DELETE_RECORD]: showRecordModal,
      [RECORD_TYPES.CREATE_ORDER]: showOrderModal,
      [RECORD_TYPES.CANCEL_ORDER]: showOrderModal,
      [RECORD_TYPES.SEND_EMAIL]: showEmailModal
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const fetchUsersOnInit = function*() {
  const eventId = yield select(getEventId);
  const orgId = yield select(getOrganizationId);
  if (eventId) {
    yield put(fetchUsers(eventId));
  } else {
    yield put(fetchOrgUsers(orgId));
  }
};

const rootSaga = function*() {
  yield all([
    fork(fetchUsersOnInit),
    fork(watchSearch),
    fork(watchOnOpenActivityLogs),
    fork(watchActionExecuted)
  ]);
};

export default rootSaga;
