import { connect } from "react-redux";
import { actions } from "Forms/WizardModal";
import { CUSTOM_PAGES } from "Forms/WizardModal/constants";
import {
  getSelectedCustomModule,
  isCustomModuleReviewButtonDisabled
} from "Forms/WizardModal/selectors";
import SelectQuestions from "Forms/WizardModal/View/Common/SelectQuestions";

const decorate = connect(
  state => ({
    moduleSelected: getSelectedCustomModule(state),
    isButtonDisabled: isCustomModuleReviewButtonDisabled(state)
  }),
  {
    back: () => actions.backToSelectForm(),
    toggleSelectedModuleField: actions.toggleSelectedModuleField,
    toggleRequiredModuleField: actions.toggleRequiredModuleField,
    goToReview: () => actions.setSelectedCustomModules(CUSTOM_PAGES.REVIEW),
    selectedAllModuleField: actions.selectedAllModuleField,
    deselectedAllModuleField: actions.deselectedAllModuleField
  }
);

export default decorate(SelectQuestions);
