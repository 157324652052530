import * as R from "ramda";
import { createHandlers } from "redux-mvc";

const model = createHandlers({
  iniState: {},
  reducers: {
    executeAction: R.identity
  },
  namespace: "UIActionsPopover"
});

const { actions, getters } = model;

export { actions, getters };

export default model;
