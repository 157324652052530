export const NAMESPACE = "Submission";

export const FIELD_TYPES = {
  people: "people",
  item: "order-items",
  subform: "subform",
  customer: "order-customer",
  fulfillment: "order-fulfillment",
  details: "order-details"
};
