import React, { Component } from "react";
import * as R from "ramda";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { defaultInstanceId, bindInstance } from "redux-mvc";

import { Div } from "components/Base";

import SidebarLink from "./SidebarLink";
import SidebarWrapper from "./SidebarWrapper";

import { actions } from "../index";

const getLinks = (link, level = 0, instanceId = defaultInstanceId) =>
  link.links ? (
    <SidebarLink key={link.id} {...link} level={level} instanceId={instanceId}>
      {R.compose(
        R.map(l => getLinks(l, level + 1, instanceId)),
        R.filter(l => l)
      )(link.links)}
    </SidebarLink>
  ) : (
    <SidebarLink
      key={link.id}
      {...link}
      level={level}
      instanceId={instanceId}
    />
  );

const decorate = R.compose(
  withRouter,
  connect(
    null,
    bindInstance({
      setActiveRoutes: actions.setActiveRoutes,
      init: actions.init
    })
  )
);

const DefaultHeader = ({ title }) => (
  <Div color="white" fw={3} fs={6} mb={3} mr={60}>
    {title}
  </Div>
);

class Sidebar extends Component {
  componentDidMount() {
    this.props.init({
      links: this.props.links,
      routes: R.filter(
        R.compose(
          R.not,
          R.isNil
        ),
        R.map(R.prop("name"), this.props.routes)
      ),
      iniOpened: this.props.iniOpenedMenues || []
    });
  }

  render() {
    const {
      title,
      links,
      footer,
      instanceId,
      Header = DefaultHeader
    } = this.props;

    if (!title && !footer && Header === DefaultHeader) {
      return R.map(getLinks, links);
    }

    return (
      <Div
        width={250}
        py={5}
        pl={4}
        pr={3}
        style={{
          backgroundColor: "#813CD2",
          minHeight: "100%"
        }}
        display="column.flex-start.stretch"
      >
        <Header title={title} />
        {R.compose(
          R.map(l => getLinks(l, 0, instanceId)),
          R.filter(l => l)
        )(links)}
        <Div flex={1} />
        {footer}
      </Div>
    );
  }
}

export { SidebarWrapper, getLinks };

export default decorate(Sidebar);
