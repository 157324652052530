import React from "react";

import { connect } from "react-redux";

import { getters } from "../index";

import { Div } from "components/Base";

import Modal from "components/Global/Modal/Layout/StyleWrapper";

import Loading from "ui-kit/Loading";

import Header from "./Header";

import Body from "./Body";

import Footer from "./Footer";

const decorate = connect(state => ({
  loading: getters.loading(state)
}));

const Layout = ({ hideModal, loading, handlers }) => {
  return (
    <Modal height={661} width={743} hideHeader bodyStyles={{ padding: 0 }}>
      {loading ? (
        <Div display="row.center.center" width={1} height={1}>
          <Loading />
        </Div>
      ) : (
        <Div width={1} height={1}>
          <Header hideModal={hideModal} />
          <Body />
          <Footer handlers={handlers} hideModal={hideModal} />
        </Div>
      )}
    </Modal>
  );
};

export default decorate(Layout);
