import React from "react";
import { put, takeEvery, all, fork, select, call } from "redux-saga/effects";
import { actions } from "./model";
import { getters as PageOrdersGetters } from "Portal/PortalPageOrders/model";
import { makeFuture } from "utils/General/sagas";
import ItemDetailsModal from "Orders/ItemDetailsModal/View";
import { showModal, hideModal } from "redux/modules/modal/actions";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { push } from "react-router-redux";

import { actions as SearchBarActions } from "ui-kit/SearchBar";

const showModifyItemdModal = function*({ item }) {
  const handleIssuance = makeFuture();
  const color = yield select(PageOrdersGetters.highlightColor);

  yield put(
    showModal({
      content: (
        <ItemDetailsModal
          buttonLabel="Update Cart"
          itemId={item.id}
          variantId={item.id}
          selectedQuantity={item.quantity}
          selectedPriceId={item.priceId}
          questions={item.questions}
          color={color}
          disableChangingPrice={true}
          onSave={newItem =>
            handleIssuance.done({ type: "done", item: newItem, oldItem: item })
          }
          showInternalQuestions={false}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleIssuance.onRealized);
};

const handleModifyItemModal = function*({
  payload: {
    item: { category, categoryId, name, ...item }
  }
}) {
  const result = yield call(showModifyItemdModal, { item });

  if (result.type === "done") {
    yield all([
      put(hideModal),
      put(
        actions.modifyItem({
          item: result.item,
          category,
          categoryId,
          name,
          oldItem: result.oldItem
        })
      )
    ]);
  }
};

const checkout = function*({ payload: { params } }) {
  yield all([
    put(SearchBarActions.setSearchTerm("")),
    put(
      push(
        `/hubs/${params.portalSlug}/${params.portalId}/people/${params.recordTypeId}/requests/cart`
      )
    )
  ]);
};

const watchHandleModifyItemModal = function*() {
  yield takeEvery(actions.handleModifyItemModal.type, handleModifyItemModal);
};

const watchCheckout = function*() {
  yield takeEvery(actions.checkout.type, checkout);
};

const rootSaga = function*() {
  yield all([fork(watchHandleModifyItemModal), fork(watchCheckout)]);
};

export default rootSaga;
