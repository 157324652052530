import React from "react";
import { Div, DownFilledIcon } from "components/Base";

const InputFieldStatic = ({
  width = 110,
  mt = 0,
  downIcon = false,
  mb = 0
}) => (
  <Div
    ba={2}
    bra={1}
    bc="neutral2"
    height={30}
    width={width}
    bg="neutral2"
    mt={mt}
    color="neutral5"
    fs={1}
    display="row.flex-end.center"
    px={2}
    mb={mb}
  >
    {downIcon && <DownFilledIcon />}
  </Div>
);

export default InputFieldStatic;
