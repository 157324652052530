import { connect } from "react-redux";
import { withRouter } from "react-router";
import { createContext } from "redux-mvc";
import * as R from "ramda";
import module, { actions } from "EventLight/Integrations/Fuzion";
import Layout from "./Layout";
import rootSaga from "EventLight/Integrations/Fuzion/sagas";
import { showModal, hideModal } from "redux/modules/modal/actions";

module.setRootSaga(rootSaga);

const decorate = createContext({
  module,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(actions.getConnection());
    }
  },
  handlers: {},
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: [
      "user",
      "event",
      "modal",
      "organization",
      "permissions",
      "userAccessLevels",
      "@flopflip"
    ],
    persist: true
  }
});

export default R.compose(
  connect(
    null,
    { showModal, hideModal }
  ),
  withRouter,
  decorate
)(Layout);
