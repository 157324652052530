import React from "react";
import { get } from "lodash";
import * as R from "ramda";
import BaseController from "components/Global/Module/Modals/AddRecord/Layouts/Base";

import FormElements from "components/Global/Modal/Layout/FormElements";
import TypePicker from "components/Global/StandAloneEditors/Types";

const { Label, InputSection, InputGroup } = FormElements;

class AddRecordModal extends BaseController {
  async componentDidMount() {
    await Promise.all([
      this.props.getModule({
        moduleId: this.props.moduleId,
        options: {
          orgId: this.props.orgId,
          eventId: this.props.eventId
        }
      }),
      this.props.getFields({
        moduleId: this.props.moduleId,
        options: {
          orgId: this.props.orgId,
          eventId: this.props.eventId
        }
      }),
      this.props.getRecordTypes({
        moduleId: this.props.moduleId,
        options: {
          orgId: this.props.orgId,
          eventId: this.props.eventId
        }
      }),
      this.props.getLayoutVisibleFields({
        layoutType: "add-record-layout",
        orgId: this.props.orgId,
        eventId: this.props.eventId,
        // @NOTE: By not passing `recordTypeId` here we receive back all toggled fields
        // across all record types. This way we can conditionally show the fields based on
        // what record type is selected -- or fallback to defaults if no fields available.
        // recordTypeId: [empty],
        moduleId: this.props.moduleId,
        options: {
          orgId: this.props.orgId,
          eventId: this.props.eventId
        }
      })
    ]);

    this.setState({ loading: false });
  }

  componentWillReceiveProps(newProps) {
    this.setState({ ...newProps.values });
    if (this.state.type === null && newProps.recordTypes.length) {
      this.setState({
        type:
          get(newProps.recordTypes.find(t => t.is_default), "id") ||
          get(newProps.recordTypes, "[0].id")
      });
    }
  }

  validateForm = () => Object.keys(this.getValuesForSelectedType()).length;

  handleSave = () =>
    new Promise((resolve, reject) => {
      if (this.validateForm()) {
        this.props
          .addRecord({
            orgId: this.props.orgId,
            eventId: this.props.eventId,
            moduleId: this.props.moduleId,
            record: { ...this.getValuesForSelectedType() },
            typeId: this.state.type,
            options: {
              orgId: this.props.orgId,
              eventId: this.props.eventId
            }
          })
          .then(record => {
            resolve(record);
          });
      } else {
        return reject();
      }
    });

  handleTypeSelect = ({ id }) => {
    this.setState({ type: id });
  };

  renderFields = fields => {
    // @NOTE: Currently, we only show types if in an event
    return (
      <div>
        {!this.props.onlyRecordTypeId &&
        this.props.eventId &&
        this.props.recordTypes.length > 1 ? (
          <InputGroup>
            <Label>Type</Label>
            <TypePicker
              value={this.getRecordTypeName(this.state.type)}
              list={[...this.props.recordTypes]}
              onSelect={this.handleTypeSelect}
            />
          </InputGroup>
        ) : (
          ""
        )}
        {fields.length > 0 ? (
          fields.map(field => {
            return (
              <InputSection key={field.id}>
                <InputGroup>
                  {this.getFieldEditor({
                    field,
                    fields
                  })}
                </InputGroup>
              </InputSection>
            );
          })
        ) : (
          <span>No visible fields!</span>
        )}
      </div>
    );
  };

  getFieldsToShow = () =>
    this.props.getFieldsToShow(this.state.type || "default");

  getValuesForSelectedType = () => {
    const fieldIds = R.map(R.prop("id"))(this.getFieldsToShow());
    return R.pick(fieldIds, this.state.values);
  };
}

export default AddRecordModal;
