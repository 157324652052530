import PropTypes from "prop-types";
import React from "react";
import BaseTableView from "../BaseTableView";
import { merge } from "lodash";
import TableSizer from "components/Global/ReactTable/TableSizer";
import Table from "components/Global/ReactTable/Table";
import getCheckboxColumn from "components/Global/ReactTable/Columns/Checkbox";
import getOpenRecordColumn from "components/Global/ReactTable/Columns/OpenRecord";
import getColumn from "components/Global/ReactTable/utils/get-column";
import getRowsSelectedHeader from "components/Global/ReactTable/TableHeaders/RowsSelected";
import HeightSizer from "components/Global/Table3/Sizers/Expand";
import flattenFields from "../utils/flatten-fields";
import EmptyRowsView from "../HelperComponents/EmptyRows";
import ContextMenu from "components/Global/Table3/ContextMenu/ContextMenu";
import Table3Helpers from "components/Global/Table3/Utilities/Helpers";
import PaginationManager from "components/Global/ReactTable/Pagination/Manager";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

class ListView extends BaseTableView {
  onColumnHeaderClick = (e, state, rowInfo, column) => {
    // if (e.target.className !== "rt-resizer") this.toggleSort(column.id);
  };

  onRowSelect = rowId => {
    if (this.isChecked(rowId)) {
      this.props.setSelectedRows(
        this.props.selectedRows.filter(id => id !== rowId)
      );
    } else {
      this.props.setSelectedRows([...this.props.selectedRows, rowId]);
    }
  };

  columnDefinitions = columns =>
    columns.filter(c => c.visible).map(column =>
      getColumn({
        column: {
          ...column,
          isSorting: this.state.sortByField === column.id,
          sortByDirection: this.state.sortByDirection
        },
        getRowMetaData: this.getRowMetaData
      })
    );

  hasColumnGroups = columns => columns.some(c => "columns" in c);

  getColumns(columns = [], rows) {
    if (this.hasColumnGroups(columns)) {
      return [
        {
          Header: "",
          columns: [
            getCheckboxColumn({
              selectAll: () => this.selectAllRows(rows),
              isSelectAllChecked: () => this.isSelectAllChecked(rows),
              onClick: this.onRowSelect,
              isChecked: this.isChecked
            })
          ]
        },
        ...columns.map(group => ({
          Header: group.header,
          columns: this.columnDefinitions(group.columns)
        }))
      ];
    }

    const cols = [
      getCheckboxColumn({
        selectAll: () => this.selectAllRows(rows),
        isSelectAllChecked: () => this.isSelectAllChecked(rows),
        onClick: this.onRowSelect,
        isChecked: this.isChecked
      })
    ];

    // @NOTE: Currently we don't add for accounts or contacts
    if (
      ![
        STANDARD_MODULE_IDS.accounts.id,
        STANDARD_MODULE_IDS.contacts.id,
        STANDARD_MODULE_IDS.documentRequests.id
      ].includes(this.props.moduleId)
    ) {
      cols.push(
        getOpenRecordColumn({
          moduleId: this.props.moduleId,
          eventId: this.props.eventDetails.id,
          hideModal: () => {
            this.props.hideModal();
            this.props.fetchRecords();
          }
        })
      );
    }

    return [...cols, ...this.columnDefinitions(columns)];
  }

  isSelectAllChecked = rows =>
    rows.length === this.props.selectedRows.length &&
    this.props.selectedRows.length;

  isChecked = rowId => this.props.selectedRows.includes(rowId);

  toggleSort = fieldId => {
    if (fieldId === "checkbox") return;
    const { sortByDirection, sortByField } = this.state;
    let sort = {
      sortByField: fieldId,
      sortByDirection: "ASC"
    };

    if (sortByField === fieldId) {
      switch (sortByDirection) {
        case "DESC":
          sort = {
            sortByField: null,
            sortByDirection: "ASC"
          };
          break;
        default:
          sort = {
            sortByField: fieldId,
            sortByDirection: "DESC"
          };
      }
    }

    const rowOrder = this.getRowOrderToSave(
      this.getVisibleRecords(),
      flattenFields(this.props.fields),
      this.props.references,
      sort.sortByField,
      sort.sortByDirection
    );

    return this.saveRowOrder(rowOrder);
  };

  selectAllRows = rows => {
    if (this.isSelectAllChecked(rows)) {
      this.props.setSelectedRows([]);
    } else {
      this.props.setSelectedRows(rows.map(c => c.id));
    }
  };

  render() {
    const {
      EmptyRowsComponent,
      PaginationComponent,
      addRecord,
      contextMenuActions,
      contextMenuOrder,
      fields,
      loading,
      onColumnResize,
      plural,
      references,
      singular,
      tableThreadConfiguration
    } = this.props;

    const contextMenus = Table3Helpers.defaultContextMenus(
      fields,
      contextMenuActions,
      {
        headerMenus: {},
        rowMenus: {}
      },
      contextMenuOrder
    );

    const rows = this.getVisibleRecords();
    const columns = this.getColumns(fields, rows);
    const threadComponent = tableThreadConfiguration
      ? getRowsSelectedHeader(
          merge(
            {
              selectedRows: this.props.selectedRows,
              helpers: {
                getRowMetaData: this.getRowMetaData,
                selectAll: () => this.selectAllRows(rows),
                isSelectAllChecked: () => this.isSelectAllChecked(rows)
              }
            },
            tableThreadConfiguration
          )
        )
      : undefined;

    return (
      <div ref="wrapper">
        <HeightSizer
          computeHeight={height => height - 62 /* 62 = height of pagination */}
        >
          <TableSizer>
            <PaginationManager>
              <Table
                onResizedChange={(newResized, e) =>
                  onColumnResize(newResized[0].id, newResized[0].value)
                }
                noDataText={
                  loading ? (
                    ""
                  ) : (
                    <EmptyRowsComponent
                      addRecord={addRecord}
                      moduleId={this.props.moduleId}
                    />
                  )
                }
                data={rows}
                loading={loading}
                columns={columns}
                onColumnHeaderClick={this.onColumnHeaderClick}
                rowsText={rows.length === 1 ? singular : plural}
                TheadComponent={threadComponent}
                PaginationComponent={PaginationComponent}
              />
            </PaginationManager>
          </TableSizer>
        </HeightSizer>
        {/*
        // @TODO: Uncomment to add context menus to list view
        {contextMenus ? (
          <ContextMenu
            contextMenus={contextMenus}
            scrollParent={this.refs.wrapper}
            parentId="ReactTable"
          />
        ) : null}
        */}
      </div>
    );
  }
}

ListView.defaultProps = {
  plural: "",
  singular: "",
  EmptyRowsComponent: EmptyRowsView
};

ListView.propTypes = {
  addRecord: PropTypes.func.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  loading: PropTypes.bool,
  moduleId: PropTypes.string.isRequired,
  plural: PropTypes.string.isRequired,
  records: PropTypes.arrayOf(PropTypes.object).isRequired,
  references: PropTypes.object.isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  singular: PropTypes.string.isRequired,
  tableThreadConfiguration: PropTypes.object,
  EmptyRowsComponent: PropTypes.object
};

export default ListView;
