import React from "react";
import { Div } from "components/Base";
import Sidebar from "Passes/SetupSidebar";

const View = ({
  links,
  hasSources,
  guestLists,
  forms,
  applications,
  children
}) => (
  <Div display="row">
    <Sidebar
      {...{
        links,
        hasSources,
        guestLists,
        forms,
        applications
      }}
    />
    <Div
      display="column"
      ml={9}
      pr={8}
      pt={8}
      pb={8}
      style={{
        width: 820,
        maxWidth: 820
      }}
    >
      {children}
    </Div>
  </Div>
);

export default View;
