import Helpers from "utils/Global/Helpers";
import getQuery from "redux/modules/modules/utils/get-query";

export default {
  exportContactSummary: (credentials, { recordId, typeId }) =>
    new Promise((success, error) => {
      const query = getQuery({
        typeId
      });

      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/contacts/${recordId}/orders/summary/export${query || ""}`,
        credentials,
        success,
        error
      });
    }),
  exportAccountSummary: (credentials, { recordId, typeId }) =>
    new Promise((success, error) => {
      const query = getQuery({
        typeId
      });

      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/account/${recordId}/orders/summary/export${query || ""}`,
        credentials,
        success,
        error
      });
    })
};
