import { get } from "lodash";
import getApprovalValue from "utils/value-types/get-value/approval";
import * as R from "ramda";

import {
  isManifestApproved,
  isManifestPending,
  isManifestRejected
} from "components/Global/Approvals/utils/approvals-helpers";

import * as SYSTEM_FIELD_IDS from "utils/system-field-ids";
const { OWNERS, APPROVAL, SOURCE, EVENT_LIST } = SYSTEM_FIELD_IDS;

import {
  VIEW_MY_RECORDS_ID,
  VIEW_PENDING_RECORDS_ID,
  VIEW_APPROVED_RECORDS_ID,
  VIEW_DENIED_RECORDS_ID
} from "components/Event/Module/utils/views";

const filterMyRecord = meta => record => {
  const owners = get(record.values, [OWNERS, "value", "records"]);
  if (!owners) return false;
  return Boolean(owners.find(o => o.id === meta.user.id));
};

const filterPendingRecord = record =>
  isManifestPending(getApprovalValue(record.values[APPROVAL]));

const filterApprovedRecord = record =>
  isManifestApproved(getApprovalValue(record.values[APPROVAL]));

const filterDeniedRecord = record =>
  isManifestRejected(getApprovalValue(record.values[APPROVAL]));

const filterByRecordTypeIds = typeIds => record =>
  typeIds.includes(record.type_id);

const filterByFormIds = formIds =>
  R.compose(
    value => R.contains(value, formIds),
    R.path(["values", SOURCE, "value", "meta", "formId"])
  );

const filterByEventId = (eventId, recordTypeFilter) =>
  R.compose(
    recordTypeFilter,
    R.path(["values", EVENT_LIST, "value"])
  );

const filterByProperty = (property, value) => record =>
  record[property] === value;

export default function resolveQueryFilteredRecords({
  filterByEventId: eventFilter,
  quickFilters,
  forms,
  query,
  records,
  recordTypes,
  meta
}) {
  if (
    (!query.toString().length && !quickFilters.length && !eventFilter) ||
    !records.length
  ) {
    return records;
  }

  const queryType = query.get("type");
  const queryField = query.entries().next().value;

  const availableFormIds = forms.map(f => f.id);
  const recordTypeIds = quickFilters.filter(
    id =>
      ![
        VIEW_MY_RECORDS_ID,
        VIEW_PENDING_RECORDS_ID,
        VIEW_APPROVED_RECORDS_ID,
        VIEW_DENIED_RECORDS_ID,
        ...availableFormIds
      ].includes(id)
  );
  const formIds = quickFilters.filter(id => availableFormIds.includes(id));

  // check for query-based record type filtering
  if (queryType && recordTypeIds.includes(queryType)) {
    recordTypeIds.push(queryType);
  }

  let andStatements = quickFilters
    .map(id => {
      switch (id) {
        case VIEW_MY_RECORDS_ID:
          return filterMyRecord(meta);
        default:
          return null;
      }
    })
    .filter(s => s);

  const approvalStatusStatements = quickFilters
    .map(id => {
      switch (id) {
        case VIEW_PENDING_RECORDS_ID:
          return filterPendingRecord;
        case VIEW_APPROVED_RECORDS_ID:
          return filterApprovedRecord;
        case VIEW_DENIED_RECORDS_ID:
          return filterDeniedRecord;
        default:
          return null;
      }
    })
    .filter(s => s);

  const recordTypeStatements =
    !eventFilter && recordTypeIds.length
      ? [filterByRecordTypeIds(recordTypeIds)]
      : [];

  if (eventFilter) {
    const recordTypeFilter = recordTypeIds.length
      ? R.any(
          v =>
            v.event_id === eventFilter &&
            recordTypeIds.includes(v.record_type_id)
        )
      : R.any(R.propEq("event_id", eventFilter));

    andStatements.push(filterByEventId(eventFilter, recordTypeFilter));
  }

  if (formIds.length) {
    andStatements.push(filterByFormIds(formIds));
  }

  if (queryField && records[0].hasOwnProperty(queryField[0])) {
    andStatements.push(filterByProperty(queryField[0], queryField[1]));
  }

  // case: andStatements + approvalStatusFilters + recordTypeFilters
  if (
    andStatements.length &&
    approvalStatusStatements.length &&
    recordTypeStatements.length
  ) {
    return records.filter(
      record =>
        andStatements.every(andStatement => andStatement(record)) &&
        approvalStatusStatements.some(orStatement => orStatement(record)) &&
        recordTypeStatements.some(orStatement => orStatement(record))
    );
  }

  // case: andStatements + (either approvalStatusFilters or recordTypeFilters)
  if (
    andStatements.length &&
    (approvalStatusStatements.length || recordTypeStatements.length)
  ) {
    const orStatements = approvalStatusStatements.length
      ? approvalStatusStatements
      : recordTypeStatements;
    return records.filter(
      record =>
        andStatements.every(andStatement => andStatement(record)) &&
        orStatements.some(orStatement => orStatement(record))
    );
  }

  // case: andStatements
  if (andStatements.length) {
    return records.filter(record =>
      andStatements.every(andStatement => andStatement(record))
    );
  }

  // case: both approvalStatusFilters and recordTypeFilters
  if (approvalStatusStatements.length && recordTypeStatements.length) {
    return records.filter(
      record =>
        approvalStatusStatements.some(orStatement => orStatement(record)) &&
        recordTypeStatements.some(orStatement => orStatement(record))
    );
  }

  // case: either approvalStatusFilters or recordTypeFilters
  if (approvalStatusStatements.length || recordTypeStatements.length) {
    const orStatements = approvalStatusStatements.length
      ? approvalStatusStatements
      : recordTypeStatements;
    return records.filter(record =>
      orStatements.some(orStatement => orStatement(record))
    );
  }

  return records;
}
