export const RESPONSIVE_WIDTH = {
  small: 340,
  medium: 380,
  big: 828
};

export const MEDIA_MAX_WIDTH = {
  small: 500,
  medium: 768
};

export const NAMESPACE = "PortalPersonModal";
