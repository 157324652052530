import React from "react";
import { connect } from "react-redux";
import { actions } from "Event/TasksWidget/model";
import { Div, Text0, Text2 } from "components/Base";
import StatusCircle from "ui-kit/StatusCircle";

const decorate = connect(
  null,
  {
    toggleTask: actions.toggleTask,
    viewTask: actions.viewTask
  }
);

const Task = ({ task, goToSchedule, toggleTask, viewTask }) => (
  <Div
    height={50}
    bg={{ default: "white", hover: "neutral0" }}
    transition="fast"
    bb={1}
    bc="neutral1"
    display="row.flex-start.center"
    px={2}
    className="hoverContainer"
    onClick={() => viewTask(task)}
  >
    <StatusCircle
      status={task.is_complete ? "task_complete" : "task_not_complete"}
      size={26}
      onClick={e => {
        e.stopPropagation();
        toggleTask({
          task,
          toggled: !task.is_complete
        });
      }}
    />
    <Div ml={2}>
      <Text2 bold color="gray7">
        {task.name}
      </Text2>
      <Div display="row.flex-start.center">
        <Text0
          bold
          color={
            task.is_complete
              ? "gray6"
              : task.is_overdue
              ? "orange9"
              : task.is_due
              ? "orange5"
              : "gray6"
          }
        >
          {task.due_label}
        </Text0>
        <Text0
          bold
          color="neutral5"
          ml={task.due_label ? 2 : 0}
          className="showOnHover"
          underline
          onClick={e => {
            e.stopPropagation();
            goToSchedule();
          }}
        >
          {task.schedule_name}
        </Text0>
      </Div>
    </Div>
  </Div>
);

export default decorate(Task);
