import React from "react";

import { connect } from "react-redux";

import { getters, actions } from "Items/AddQuestionModal";

import { Div, Text2, BigOutlineInput } from "components/Base";

const decorate = connect(
  state => ({
    questionSetName: getters.questionSetName(state)
  }),
  {
    setQuestionSetName: actions.setQuestionSetName
  }
);

const NewQuestionSet = ({ questionSetName, setQuestionSetName }) => (
  <Div width={1}>
    <Text2 color="black" mb={1} bold>
      Name of Question Set
    </Text2>
    <BigOutlineInput
      width={1}
      value={questionSetName}
      onChange={value => setQuestionSetName(value)}
      continuous
    />
  </Div>
);

export default decorate(NewQuestionSet);
