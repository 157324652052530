import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import autobind from "autobind-decorator";

import * as FieldActions from "redux/modules/formsV2/form/fields/actions";

import Helpers from "utils/Global/Helpers";

class FormImage extends Component {
  @autobind
  showFilePicker() {
    const callback = blob => {
      if (blob[0] && typeof blob[0].url !== "undefined") {
        this.props.dispatch(
          FieldActions.updateField({
            fieldId: this.props.field.id,
            settings: {
              url: blob[0].url
            }
          })
        );
      }
    };
    const path = { path: "event-form-image/" };

    Helpers.getFilepicker({}, path, callback);
  }

  render() {
    const imageUrl = this.props.field.settings.url;

    return (
      <div styleName="container">
        <img styleName="image" src={imageUrl} />
      </div>
    );
  }
}

FormImage.propTypes = {
  formValues: PropTypes.object,
  field: PropTypes.object.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isResponseLocked: PropTypes.bool.isRequired,
  isApproving: PropTypes.bool.isRequired,
  isFillingFormOut: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired
};

export default CSSModules(FormImage, css);
