import { RECEIVE } from "./constants";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getAssignments(formId) {
  return async (dispatch, getState) => {
    try {
      const { payload } = await api.get(
        getState().user.user.credentials,
        formId
      );
      dispatch({
        type: RECEIVE,
        payload: {
          formId,
          assignments: payload
        }
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred fetching form assignments"
          }
        ])
      );
    }
  };
}
