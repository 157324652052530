import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import Controller from "./Controller";

import {
  getForm,
  updateForm,
  deleteForm
} from "redux/modules/formsV2/form/actions";
import { bulkEdit } from "redux/modules/formsV2/submissions/bulk/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal } from "redux/modules/modal/actions";
import { getRecordTypes } from "redux/modules/modules/recordTypes/actions";

import { form } from "redux/modules/formsV2/form/selectors";
import { recordTypes } from "redux/modules/modules/recordTypes/selectors";
import {
  eventDetails,
  isConferenceTemplate
} from "redux/modules/event/selectors";

function mapStateToProps(state) {
  const accountRecordTypes = recordTypes(
    state,
    STANDARD_MODULE_IDS.accounts.id
  );
  const contactRecordTypes = recordTypes(
    state,
    STANDARD_MODULE_IDS.contacts.id
  );

  return {
    isConferenceTemplate: isConferenceTemplate(state),
    form: form(state),
    accountRecordTypes,
    contactRecordTypes,
    eventDetails: eventDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal,
      showSnackbar,
      bulkEdit,
      getForm,
      updateForm,
      deleteForm,
      getRecordTypes
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
