import React from "react";
import { withProps } from "utils/General";

import {
  makeTable,
  Text2,
  Div,
  SuperClearInput,
  SearchIcon
} from "components/Base";

import Checkbox from "material-ui/Checkbox";

const HeaderStyler = withProps({
  display: "row.flex-start.center",
  py: 2,
  pt: 3,
  px: 4,
  mx: 1
})(Div);

const RowStyler = ({ children, ...props }) => {
  const variant = props.variants[0];

  return (
    <Div
      display="row.flex-start.center"
      bg="white"
      py={2}
      pt={3}
      px={4}
      mx={1}
      shadow={1}
      mb={1}
      bra={1}
      onClick={variant.onToggle}
    >
      {children}
    </Div>
  );
};

const CredentialsTable = makeTable({
  TableStyler: Div,
  HeaderStyler,
  RowStyler,
  HeaderCellStyler: Div,
  RowCellStyler: Div,
  columnProps: [
    { width: 1 / 10 },
    { width: 9 / 10 }
    // { width: 3 / 10 }
  ],
  headerCellComps: [
    () => <Div />,
    ({ groupName }) => (
      <Text2 bold uppercase color="gray6">
        {groupName}
      </Text2>
    )
    /*
    // @NOTE: Removing until payments are in place
    () => (
      <Text1 bold uppercase color="gray6">
        Price
      </Text1>
    ),
    */
    /*
    // @NOTE: Removing until limits are in place
    () => (
      <Text1 bold uppercase color="gray6" pl={9}>
        Limit
      </Text1>
    )
    */
  ],
  rowCellComps: [
    ({ variants }) => {
      const variant = variants[0];
      return (
        <Div>
          <Checkbox checked={variant.selected} onCheck={() => {}} />
        </Div>
      );
    },
    ({ name }) => (
      <Div
        fw={3}
        fs={4}
        truncate
        color="gray7"
        style={{ flexShrink: 0 }}
        title={name}
      >
        {name}
      </Div>
    )
    /*
    // @NOTE: Removing until payments are in place
    ({ price }) => (
      <Text2 truncate color="gray7">
        price
      </Text2>
    ),
    */
    /*
    // @NOTE: Removing until limits are in place
    ({ count, onChangeCount }) => (
      <Div display="row.flex-end">
        <NumberSelector
          value={count}
          maxValue={undefined}
          onChangeValue={onChangeCount}
        />
      </Div>
    )
    */
  ]
});

const Group = ({ group }) => (
  <Div mb={4}>
    <CredentialsTable
      rowsData={group.items}
      headerData={{ groupName: group.name }}
    />
  </Div>
);

const Credentials = ({ activeType, onSearchChange, searchTerm }) => (
  <Div py={1}>
    <Div bb={1} bc="gray2" mb={2}>
      <SuperClearInput
        continuous
        placeholder="Search..."
        onChange={onSearchChange}
        LeftIcon={SearchIcon}
        leftIconColor="gray6"
        flex={1}
      />
    </Div>
    {activeType.groups.map(group => <Group key={group.id} group={group} />)}
  </Div>
);

export default Credentials;
