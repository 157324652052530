import PropTypes from "prop-types";
import React from "react";
import { EVENTBRITE_LOGO_SRC } from "components/Event/Settings/Credentials/constants";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const IntegrationHeader = ({ integration, children }) => {
  switch (integration) {
    case "eventbrite":
      return (
        <div styleName="container">
          <div styleName="label">
            <img src={EVENTBRITE_LOGO_SRC} width="10" height="10" alt="" />
          </div>
          <div>{children}</div>
        </div>
      );
    default:
      return (
        <div styleName="container">
          <div styleName="label">{integration}</div>
          <div>{children}</div>
        </div>
      );
  }
};

IntegrationHeader.propTypes = {
  integration: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired
};

export default CSSModules(IntegrationHeader, css);
