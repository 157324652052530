import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import { canViewInventory, isReportsEmpty, getLinks } from "../selectors";

import { Div, Text4, Text3 } from "components/Base";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { REPORT_TYPES } from "../constants";
import { noop } from "utils/General";

import ItemAssetReport from "PortalHub/Reports/ItemAssetReport/View";
import CateringReport from "PortalHub/Reports/CateringReport/View";
import CredentialsReport from "PortalHub/Reports/CredentialsReport/View";

const reportViews = activeReport =>
  R.propOr("div", activeReport, {
    [REPORT_TYPES.ASSETS]: ItemAssetReport,
    [REPORT_TYPES.CREDENTIALS]: CredentialsReport,
    [REPORT_TYPES.MEALS]: CateringReport
  });

const decorate = R.compose(
  connect(
    state => ({
      canViewInventory: canViewInventory(state),
      isEmpty: isReportsEmpty(state),
      activeReport: getters.activeReport(state),
      links: getLinks(state)
    }),
    {
      changeActiveReport: actions.goToReport
    }
  ),
  CSSModules(css)
);

const Body = ({
  isEmpty = false,
  activeReport = null,
  links = [],
  changeActiveReport = noop
}) => (
  <div styleName="body">
    {isEmpty ? (
      <Div
        brb={2}
        brr={2}
        p={4}
        bg="white"
        mb={4}
        styleName="emptyStateContainer"
      >
        <Div display="column.center.center" mt={6} flex={1}>
          <img
            src="https://d2dks4tzxs6xee.cloudfront.net/img/faces.png"
            width="180"
            height="100"
            alt="Setup"
          />
          <Text4 bold mt={4} mb={1} color="gray7">
            There's nothing to report yet.
          </Text4>
          <Text3
            maxWidth={320}
            style={{ textAlign: "center" }}
            color="gray7"
            mb={4}
          >
            After your requests are submitted, you'll be able to view the status
            of your requests here.
          </Text3>
        </Div>
      </Div>
    ) : (
      <>
        <div styleName="tabs">
          {R.map(
            link => (
              <div
                styleName="tab"
                key={link.id}
                onClick={() => changeActiveReport(link.id)}
                style={
                  link.active
                    ? {
                        borderBottomColor: "#9548e8",
                        color: "#9548e8"
                      }
                    : {}
                }
              >
                {link.name}
              </div>
            ),
            links
          )}
        </div>
        <Div display="column.flex-start.center" width={1}>
          {React.createElement(reportViews(activeReport), {
            showTitle: false,
            activeReport
          })}
        </Div>
      </>
    )}
  </div>
);

export default decorate(Body);
