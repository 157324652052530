import React from "react";
import * as R from "ramda";
import { Div } from "components/Base";
import PassSelector from "./PassSelector";
import MealSelector from "./MealSelector";
import { MEAL_TYPE_ID } from "utils/item-types";
import ItemsSearchBar from "./ItemsSearchBar";

const ItemsSelectors = ({ itemTypeProps, activeItemTypeId }) => (
  <Div
    height={1}
    width={0.5}
    br={1}
    bc="neutral2"
    p={4}
    style={{ overflowY: "auto" }}
  >
    <ItemsSearchBar />
    {R.propOr(
      itemTypeProps && itemTypeProps[activeItemTypeId] ? (
        <PassSelector {...itemTypeProps[activeItemTypeId]} />
      ) : null,
      activeItemTypeId,
      {
        [MEAL_TYPE_ID]:
          itemTypeProps && itemTypeProps[MEAL_TYPE_ID] ? (
            <MealSelector {...itemTypeProps[MEAL_TYPE_ID]} />
          ) : null
      }
    )}
  </Div>
);

export default ItemsSelectors;
