import * as R from "ramda";
import { createContext } from "redux-mvc";

import dashboard from "EventLight/Common/Dashboard";
import DashboardView, {
  contextConfig,
  getDashboardProps
} from "EventLight/Common/Dashboard/View";

const decorate = R.compose(
  getDashboardProps({
    emptyStateIcon: "apps",
    emptyStateTitle: "No records have been added yet",
    emptyStateDescription: "Manage records for your custom application"
  }),
  createContext({ module: dashboard, ...contextConfig })
);

export default decorate(DashboardView);
