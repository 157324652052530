import { createSelector } from "reselect";
import * as R from "ramda";
import moment from "moment";
import formatCloseDate from "components/Event/Module/Form/utils/format-close-date";
import { getters } from "./model";
import { TYPES } from "EventLight/FileRequests/AddAssignmentModal/constants";
import { TABS_LIST } from "./constants";
import { userId as getUserId } from "redux/modules/user/selectors";

export const getTabs = createSelector(
  getters.activeTab,
  activeTab => {
    return TABS_LIST.map(t => ({
      ...t,
      active: activeTab === t.id
    }));
  }
);

export const getIsEditing = createSelector(
  getters.requestId,
  requestId => !R.isEmpty(requestId)
);

export const getIsSaveBtnDisabled = createSelector(
  getters.name,
  getters.dueDate,
  (name, dueDate) => R.isEmpty(name) || R.isNil(dueDate)
);

export const getEventUsers = createSelector(
  getters.eventUsers,
  getters.managers,
  getUserId,
  getIsEditing,
  (eventUsers, managers, userId, isEditing) =>
    R.map(
      user => ({
        ...user,
        isManager:
          !isEditing && userId === user.user_id
            ? true
            : R.any(managerId => managerId === user.user_id, managers),
        disable: parseInt(userId, 10) === parseInt(user.user_id, 10)
      }),
      eventUsers
    )
);

export const getContactRecordTypes = createSelector(
  getters.contactRecordTypes,
  getters.assignedToPeopleTypes,
  (contactRecordTypes, assignedToPeopleTypes) =>
    R.map(
      record => ({
        ...record,
        selected: R.any(
          recordId => recordId === record.id,
          assignedToPeopleTypes
        )
      }),
      contactRecordTypes
    )
);

export const getAccountRecordTypes = createSelector(
  getters.accountRecordTypes,
  getters.assignedToGroupTypes,
  (accountRecordTypes, assignedToGroupTypes) =>
    R.map(
      record => ({
        ...record,
        selected: R.any(
          recordId => recordId === record.id,
          assignedToGroupTypes
        )
      }),
      accountRecordTypes
    )
);

export const getAssignedToGroupTypesMapped = createSelector(
  getters.assignedToGroupTypes,
  R.map(groupType => ({
    ...groupType,
    hasExpired: groupType.close_date
      ? moment().isAfter(moment(groupType.close_date))
      : false,
    closeDate: groupType.close_date
      ? formatCloseDate(groupType.close_date, "MMM D, YYYY - h:mmA zz")
      : null
  }))
);

export const getEditGroupOrPerson = createSelector(
  getters.editDueDateModalId,
  getters.type,
  getters.assignedToGroups,
  getters.assignedToPeople,
  (editDueDateModalId, type, assignedToGroups, assignedToPeople) => {
    if (type === TYPES.ACCOUNT) {
      return R.find(R.propEq("id", editDueDateModalId), assignedToGroups);
    }

    return R.find(R.propEq("id", editDueDateModalId), assignedToPeople);
  }
);
