import React from "react";

import {
  put,
  all,
  take,
  takeEvery,
  fork,
  select,
  call
} from "redux-saga/effects";
import * as R from "ramda";
import { makeFuture } from "utils/General/sagas";
import copy from "copy-to-clipboard";

import { FIELD_IDS, FORM_ID, REQUIRED_ERROR_MESSAGE } from "./constants";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_MODULE_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

import { getCredentials } from "redux/modules/user/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { getEditedField } from "./selectors";

import { actions, getters } from "./model";
import { registerError } from "redux/modules/errors/actions";
import { actions as FormActions } from "ui-kit/Form/model";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal } from "redux/modules/modal/actions";
import { actions as LayoutActions } from "EventLight/Layout/model";
import { getEvent } from "redux/modules/event/actions";

import { getFieldsToSave } from "./selectors";

import AddFieldModal from "Modules/AddEditColumnModal/View";
import EditFieldModal from "Modules/AddEditColumnModal/View";

import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventDetails = yield select(getEventDetails);

  return {
    credentials,
    eventDetails
  };
};

const init = function*() {
  try {
    yield put(actions.setLoading(true));
    const { credentials, eventDetails } = yield call(getParams);

    const { payload } = yield call(Api.getRegistration, {
      credentials,
      eventId: eventDetails.id
    });

    const [
      { fields: accountFields },
      { fields: contactFields },
      { record_types: accountTypes },
      { record_types: contactTypes }
    ] = yield all([
      call(Api.getModuleFields, {
        credentials,
        eventId: eventDetails.id,
        moduleId: STANDARD_MODULES.accounts.id
      }),
      call(Api.getModuleFields, {
        credentials,
        eventId: eventDetails.id,
        moduleId: STANDARD_MODULES.contacts.id
      }),
      call(Api.getRecordTypes, {
        credentials,
        eventId: eventDetails.id,
        moduleId: STANDARD_MODULES.accounts.id
      }),
      call(Api.getRecordTypes, {
        credentials,
        eventId: eventDetails.id,
        moduleId: STANDARD_MODULES.contacts.id
      })
    ]);

    yield put(
      actions.setInitialData({
        ...payload,
        registrationUrl: payload.registration_url,
        contactFields,
        accountFields,
        contactTypes: contactTypes.map(t => ({
          id: t.id,
          label: t.name
        })),
        accountTypes: accountTypes.map(t => ({
          id: t.id,
          label: t.name
        }))
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting details"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const save = function*() {
  try {
    const { credentials, eventDetails } = yield call(getParams);

    const data = yield select(getters.data);
    const fields = yield select(getFieldsToSave);

    const fnameField = fields.find(
      f => f.id === STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME
    );
    const lnameField = fields.find(
      f => f.id === STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME
    );
    const emailField = fields.find(
      f => f.id === STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL
    );

    if (!fnameField || !lnameField || !emailField) {
      yield put(actions.setError(REQUIRED_ERROR_MESSAGE));
      return false;
    }

    if (!fnameField.required || !lnameField.required || !emailField.required) {
      yield put(actions.setError(REQUIRED_ERROR_MESSAGE));
      return false;
    }

    yield put(actions.setError(null));

    const toSave = {
      registrationType: data.registration_type,
      virtualRegistrationTemplate: data.virtual_registration_template,
      virtualRegistrationDescription: data.virtual_registration_description,
      virtualRegistrationImageUrl: data.virtual_registration_image_url,
      virtualRegistrationContactRecordTypeId:
        data.virtual_registration_contact_record_type_id,
      virtualRegistrationAccountRecordTypeId:
        data.virtual_registration_account_record_type_id,
      virtualRegistrationSource: data.virtual_registration_source,
      //
      virtualRegistrationEnableSponsors:
        data.virtual_registration_enable_sponsors,
      virtualRegistrationSponsorsLabel:
        data.virtual_registration_sponsors_label,
      virtualRegistrationSponsorsDescription:
        data.virtual_registration_sponsors_description,
      //
      virtualRegistrationEnableSpeakers:
        data.virtual_registration_enable_speakers,
      virtualRegistrationSpeakersLabel:
        data.virtual_registration_speakers_label,
      virtualRegistrationSpeakersDescription:
        data.virtual_registration_speakers_description,
      //
      virtualRegistrationEnableSchedule:
        data.virtual_registration_enable_schedule,
      virtualRegistrationScheduleLabel:
        data.virtual_registration_schedule_label,
      virtualRegistrationScheduleDescription:
        data.virtual_registration_schedule_description,
      //
      fields
    };

    yield call(Api.updateRegistration, {
      credentials,
      eventId: eventDetails.id,
      data: toSave
    });
    yield put(getEvent(eventDetails.id));
    yield put(LayoutActions.refreshNavigation());

    yield put(showSnackbar({ message: "Details Updated" }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while saving details"
        }
      ])
    );
  }
};

const fetchFields = function*() {
  const { credentials, eventDetails } = yield call(getParams);

  try {
    const [{ fields: accountFields }, { fields: contactFields }] = yield all([
      call(Api.getModuleFields, {
        credentials,
        eventId: eventDetails.id,
        moduleId: STANDARD_MODULES.accounts.id
      }),
      call(Api.getModuleFields, {
        credentials,
        eventId: eventDetails.id,
        moduleId: STANDARD_MODULES.contacts.id
      })
    ]);

    yield all([
      yield put(
        actions.setAllFields(
          [...accountFields.fields, ...contactFields.fields].map(f => ({
            ...f,
            moduleLabel:
              f.module_id === STANDARD_MODULES.accounts.id
                ? "Group Field"
                : "Attendee Field"
          }))
        )
      ),
      yield put(actions.setAccountFields(accountFields)),
      yield put(actions.setContactFields(contactFields))
    ]);
  } catch (e) {
    yield all([
      put(
        registerError([
          {
            system: e,
            user: "An error occurred geting module fields"
          }
        ])
      )
    ]);
  }
};

const handleShowEditFieldModal = function*({ id }) {
  const handleModal = makeFuture();
  const { eventDetails } = yield call(getParams);
  const allFields = yield select(getters.allFields);
  const field = R.find(R.propEq("id", id))(allFields);

  yield put(
    showModal({
      content: (
        <EditFieldModal
          eventId={eventDetails.id}
          moduleId={field.module_id}
          fieldId={id}
          onSave={handleModal.done}
        />
      )
    })
  );
  return yield call(handleModal.onRealized);
};

const showEditFieldModal = function*({ payload: id }) {
  const result = yield call(handleShowEditFieldModal, { id });

  if (result) {
    yield put(showSnackbar({ message: "Field updated", action: "OK" }));
    yield call(fetchFields);
  }
};

const handleShowAddFieldModal = function*({ moduleId }) {
  const handleModal = makeFuture();
  const { eventDetails } = yield call(getParams);

  yield put(
    showModal({
      content: (
        <AddFieldModal
          eventId={eventDetails.id}
          moduleId={moduleId}
          onSave={handleModal.done}
        />
      )
    })
  );
  return yield call(handleModal.onRealized);
};

const showAddFieldModal = function*({ payload: moduleId }) {
  const result = yield call(handleShowAddFieldModal, { moduleId });

  if (result) {
    yield put(showSnackbar({ message: "Field added", action: "OK" }));
    yield call(fetchFields);
  }
};

const onSaveSelectFieldsModal = function*({ payload }) {
  try {
    yield put(actions.updateSelectedFields(payload));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred updating items"
        }
      ])
    );
  } finally {
    yield put(actions.setShowSelectFieldsModal(false));
  }
};

const fieldUpdate = function*({ id, fieldId }) {
  const newValue = yield select(getEditedField, {
    instanceId: id,
    fieldId
  });

  const processedValue = R.propOr(newValue, fieldId, {
    [FIELD_IDS.TEMPLATE]: R.path([0], newValue),
    [FIELD_IDS.CONTACT_RECORD_TYPE_ID]: R.path([0], newValue),
    [FIELD_IDS.ACCOUNT_RECORD_TYPE_ID]: R.path([0], newValue),
    [FIELD_IDS.REGISTRATION_SOURCE]: R.path([0], newValue),
    [FIELD_IDS.REGISTRATION_IMAGE_URL]: R.path([0, "url"], newValue)
  });

  yield put(actions.updateField({ field: fieldId, value: processedValue }));
};

const openRegistrationPage = function*() {
  const registrationUrl = yield select(getters.registrationUrl);
  yield call(window.open, registrationUrl, "_blank");
};

const copyValue = function*({ payload }) {
  yield call(copy, payload);
  yield put(showSnackbar({ message: "Link Copied" }));
};

const watchUpdateFields = function*() {
  for (;;) {
    const {
      meta: { instanceId, fieldId }
    } = yield take(FormActions.setFieldValue.type);
    if (instanceId === FORM_ID) {
      yield call(fieldUpdate, {
        id: instanceId,
        fieldId
      });
    }
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchSave = function*() {
  yield takeEvery(actions.save.type, save);
};

const watchEditField = function*() {
  yield takeEvery(actions.editField.type, showEditFieldModal);
};

const watchAddField = function*() {
  yield takeEvery(actions.addField.type, showAddFieldModal);
};

const watchSaveSelectFieldsModal = function*() {
  yield takeEvery(actions.saveSelectFieldsModal.type, onSaveSelectFieldsModal);
};

const watchOpenRegistrationPage = function*() {
  yield takeEvery(actions.openRegistrationPage.type, openRegistrationPage);
};

const watchCopyValue = function*() {
  yield takeEvery(actions.copyValue.type, copyValue);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchSave),
    fork(watchUpdateFields),
    fork(watchEditField),
    fork(watchAddField),
    fork(watchOpenRegistrationPage),
    fork(watchCopyValue),
    fork(watchSaveSelectFieldsModal)
  ]);
};

export default rootSaga;
