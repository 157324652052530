import React, { cloneElement } from "react";
import {
  Div,
  LeftIcon,
  OpenInNewIcon,
  CheckIcon,
  RightIcon,
  LockIcon
} from "components/Base";

import Container from "components/Global/Module/Container";
import Separator from "components/Global/Module/Separator";

import getColumnTypes from "components/Event/Settings/Module/utils/get-column-types";

import EditFormModal from "components/Event/FormsV2/Modals/FormEditModal";
import SendFormModal from "components/Event/FormsV2/Modals/FormSendModal";
import ModalColumnEdit from "Modules/AddEditColumnModal/View";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import isFormLocked from "components/Event/FormsV2/Utils/is-form-locked";

import NavigationBase from "components/Event/Module/Navigation/Base";

const Step = ({ children, active, onClick, IconRight }) => (
  <Div
    display="row.center.center"
    height={1}
    bc={active ? "primary8" : "white"}
    bb={3}
    color={{ default: active ? "primary8" : "gray7", hover: "primary8" }}
    px={2}
    fw={3}
    fs={3}
    mr={4}
    onClick={onClick}
    style={{
      borderBottomWidth: 3
    }}
  >
    {children}
    {IconRight ? <IconRight fs={2} ml={2} /> : null}
  </Div>
);

// @NOTE Commenting out until necessary
// import CloneFormModal from 'components/Event/FormsV2/Modals/FormCloneModal';
// import DeleteFormModal from 'components/Event/FormsV2/Modals/FormDeleteModal';
// import AddManagerModal from 'components/Event/FormsV2/Modals/AddManagerModal';

class Form extends NavigationBase {
  state = { referralPath: this.props.referralPath };
  componentDidMount() {
    this.fetchForm(this.props);
    // this.fetchModule(this.props);
  }

  componentWillReceiveProps() {
    /*
    if (nextProps.context.moduleId !== this.props.context.moduleId) {
      this.fetchModule(nextProps);
    }
    */
  }

  showEditFieldModal = fieldId => {
    const { context, showModal } = this.props;
    const { moduleId, orgId, eventId } = context;

    const modal = (
      <ModalColumnEdit
        orgId={orgId}
        eventId={eventId}
        moduleId={moduleId || this.props.form.base_module_id}
        fieldId={fieldId}
        onSave={data => this.handleEditField(data)}
      />
    );
    showModal({ content: modal });
  };

  showEditSubformFieldModal = ({ field, fields, onDone }) => {
    const { context, fieldGroups, showModal } = this.props;
    const { moduleId, orgId, eventId } = context;
    const fieldGroup = fieldGroups.find(group =>
      group.fields.some(f => f.field_id === field.id)
    );
    const modal = (
      <ModalColumnEdit
        orgId={orgId}
        eventId={eventId}
        column={{ ...field, locked: true }}
        columns={fields}
        columnTypes={getColumnTypes(moduleId)}
        allowPortalVisibility
        fieldGroups={fieldGroups}
        fieldGroup={String(fieldGroup.id)}
        handleEditColumn={data =>
          this.handleEditField(data, field, fieldGroup).then(() => {
            if (onDone) return onDone(data);
            return null;
          })
        }
      />
    );
    showModal({ content: modal });
  };

  handleEditField = () => {
    this.props.showSnackbar({ message: "Field updated", action: "OK" });
    return this.props.getForm(this.props.context.viewId);
  };

  fetchForm = (props = this.props) => props.getForm(props.context.viewId);

  fetchModule = () => this.props.getModule();

  openFormPreview = () => {
    const { previewUrl } = this.props;
    window.open(previewUrl, "_blank");
  };

  showFormSettings = () => {
    const {
      form,
      context,
      hideModal,
      updateForm,
      showModal,
      showSnackbar
    } = this.props;
    showModal({
      content: (
        <EditFormModal
          bulkEdit={this.toggleLockAllSubmissions}
          orgId={context.orgId}
          eventId={context.eventId}
          form={form}
          handleEditForm={data => {
            updateForm(data);
            showSnackbar({ message: "Form updated", action: "OK" });
          }}
          hideModal={hideModal}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showSendForm = () => {
    const { form, showModal, showSnackbar } = this.props;
    showModal({
      content: (
        <SendFormModal
          formId={form.id}
          formSlug={form.slug}
          handleFormSent={() => {
            showSnackbar({ message: "Form sent", action: "OK" });
          }}
        />
      )
    });
  };

  goBack = () => {
    if (this.props.eventDetails.is_light) {
      this.props.router.push(
        `/event-light/${this.props.context.eventId}/forms`
      );
    } else {
      this.props.router.push(`/event/${this.props.context.eventId}/forms-v2`);
    }
  };

  goToFormBuilder = () => {
    if (this.props.eventDetails.is_light) {
      this.props.router.push({
        pathname: `/event-light/${this.props.context.eventId}/forms/${this.props.context.viewId}/builder`
      });
    } else {
      this.props.router.push({
        pathname: this.props.editFormUrl
      });
    }
  };

  goToFormSettings = () => {
    if (this.props.eventDetails.is_light) {
      this.props.router.push({
        pathname: `/event-light/${this.props.context.eventId}/forms/${this.props.context.viewId}/settings`
      });
    } else {
      this.props.router.push({
        pathname: `/event/${this.props.context.eventId}/module/${this.props.module.id}/form/${this.props.context.viewId}/settings`
      });
    }
  };

  goToFormShare = () => {
    if (this.props.isConferenceTemplate) {
      this.props.router.push({
        pathname: `/event-light/${this.props.context.eventId}/forms/${this.props.context.viewId}/settings/share/assign`
      });
    } else {
      this.props.router.push({
        pathname: `/event-light/${this.props.context.eventId}/forms/${this.props.context.viewId}/settings/share`
      });
    }
  };

  goToFormResults = () => {
    if (this.props.eventDetails.is_light) {
      this.props.router.push(
        `/event-light/${this.props.context.eventId}/forms/${this.props.form.id}/results`
      );
    } else {
      this.props.router.push(
        `/event/${this.props.context.eventId}/forms/${this.props.form.id}/results`
      );
    }
  };

  render() {
    const { children, routes, form } = this.props;

    const steps = [
      {
        name: "Build",
        onClick: this.goToFormBuilder,
        active: routes.find(f => f.name === "formsV3_Edit"),
        goToNextLabel: "Settings",
        goToNextAction: this.goToFormSettings
      },
      {
        name: "Settings",
        onClick: this.goToFormSettings,
        active: routes.find(f =>
          [
            "ModuleFormSettings",
            "ModuleFormSettingsCommunication",
            "ModuleFormSettingsAdvanced",
            "ModuleFormSettingsApprovers"
          ].includes(f.name)
        ),
        goToNextLabel: "Share",
        goToNextAction: this.goToFormShare
      },
      {
        name: "Share",
        onClick: this.goToFormShare,
        active: routes.find(f =>
          ["ModuleFormShare", "ModuleFormShareAssign"].includes(f.name)
        ),
        goToNextLabel: "Results",
        goToNextAction: this.goToFormResults
      },
      {
        name: "Results",
        // IconRight: OpenInNewIcon,
        onClick: this.goToFormResults,
        active: false
      }
    ];

    const activeStep = steps.find(s => s.active);
    const isLocked = isFormLocked(form);

    return (
      <Container>
        <Div
          bg="white"
          color="gray7"
          display="row.flex-start.center"
          height={56}
          bb={1}
          bc="neutral1"
          px={5}
          style={{
            whiteSpace: "nowrap",
            boxShadow:
              "0px 7px 17px rgba(0, 0, 0, 0.04), 0px 32px 50px rgba(0, 0, 0, 0.01)"
          }}
        >
          <Div
            mr={8}
            fw={3}
            onClick={this.goBack}
            display="row.flex-start.center"
          >
            <LeftIcon size={20} mr={2} />
            Back
          </Div>
          <Div display="row.flex-start.center" mr={29}>
            <Div
              fw={3}
              bc="gray2"
              b={1}
              bra={1}
              px={3}
              py={2}
              height={33}
              display="row.center.center"
            >
              {form.name}
            </Div>
            {isLocked ? <LockIcon color="red6" ml={2} /> : null}
          </Div>
          <Div display="row.center.center" height={1}>
            {steps.map(({ name, onClick, active, IconRight }) => (
              <Step
                key={name}
                active={active}
                onClick={onClick}
                IconRight={IconRight}
              >
                {name}
              </Step>
            ))}
          </Div>
          <Separator />
          <Div display="row.flex-start.center">
            <Div mr={4} display="row.center.center" color="gray7">
              <CheckIcon mr={2} color="gray7" />
              Saved
            </Div>
            <Div
              px={2}
              height={32}
              mr={4}
              bra={1}
              fw={3}
              color="gray7"
              bg={{
                default: "neutral2",
                hover: "neutral3"
              }}
              display="row.center.center"
              onClick={this.openFormPreview}
            >
              <OpenInNewIcon mr={2} color="gray7" />
              Preview
            </Div>
            {activeStep ? (
              <Div
                px={2}
                height={32}
                bra={1}
                color="white"
                bg={{
                  default: "orange5",
                  hover: "orange6"
                }}
                display="row.center.center"
                onClick={activeStep.goToNextAction}
              >
                <Div fw={4} mr={1}>
                  Next:
                </Div>
                <Div mr={2}>{activeStep.goToNextLabel}</Div>
                <RightIcon size={20} color="white" />
              </Div>
            ) : null}
          </Div>
        </Div>
        <div>
          {cloneElement(children, {
            showEditFieldModal: this.showEditFieldModal,
            fetchForm: this.fetchForm
          })}
        </div>
      </Container>
    );
  }
}

export default Form;
