import React, { Component } from "react";
import { Div, OpenInNewIcon } from "components/Base";
import { MODULE } from "utils/standard-module-field-ids";

import * as ModalActions from "redux/modules/modal/actions";
import * as RecordActions from "redux/modules/modules/records/actions";

import OrderModal from "Orders/OrderModal/View";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import ViewRecordModal from "components/Global/Module/Modals/ViewRecord";

import { getMeta, getHandlers, getRow } from "ui-kit/Datagrid/utils";

class OpenRecordCellFormatter extends Component {
  refetchRecords = () => {
    const props = this.props;
    const handlers = getHandlers(props);
    const moduleId = getMeta(props, ["field", "settings", "moduleId"]);
    if (moduleId) {
      handlers.dispatch(
        RecordActions.getRecords({
          moduleId,
          options: {
            eventId: getMeta(props, ["eventDetails", "id"])
          }
        })
      );
    }
  };

  refetchRecordsAndHideModal = () => {
    const handlers = getHandlers(this.props);

    this.refetchRecords();
    handlers.dispatch(ModalActions.hideModal());
  };

  showRecordModal = (moduleId, recordId) => {
    const handlers = getHandlers(this.props);
    handlers.dispatch(
      ModalActions.showModal({
        content: (
          <ViewRecordModal
            moduleId={moduleId}
            recordId={recordId}
            onClose={this.refetchRecords}
          />
        ),
        wrapper: ModalWrapper
      })
    );
  };

  showSubmissionSummaryModal = submissionId => {
    const handlers = getHandlers(this.props);
    handlers.dispatch(
      ModalActions.showModal({
        content: (
          <OrderModal
            hideModal={this.refetchRecordsAndHideModal}
            submissionId={submissionId}
          />
        ),
        wrapper: ModalWrapper
      })
    );
  };

  onClick = () => {
    const props = this.props;
    const value = getRow(props, [MODULE.SOURCE, "value"]);
    switch (value.type) {
      case "submission":
        return this.showSubmissionSummaryModal(
          getMeta(props, ["submission", "id"])
        );
      default:
        return this.showRecordModal(
          getMeta(props, ["field", "settings", "moduleId"]),
          getRow(props, "id")
        );
    }
  };

  render() {
    return (
      <Div
        display="row.center.center"
        onClick={this.onClick}
        color={{
          hover: "primary9",
          default: "gray5"
        }}
      >
        <OpenInNewIcon
          style={{
            color: "inherit"
          }}
        />
      </Div>
    );
  }
}

export default OpenRecordCellFormatter;
