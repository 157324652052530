import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "Event/ItemCatalogItems/constants";

const iniState = {
  loading: false,
  types: [],
  typesFilter: "",
  typeId: ""
};

const handlers = createHandlers({
  iniState,
  reducers: {
    deleteItem: R.identity,
    reorderItems: R.identity,
    moveItemUp: R.identity,
    moveItemDown: R.identity,
    moveCategoryUp: R.identity,
    moveCategoryDown: R.identity,
    init: (state, { payload: { types, typeId } }) => ({
      types,
      typeId
    })
  },
  namespace: NAMESPACE
});

export default handlers;
