import { createSelector } from "reselect";
import { getters } from "SalesPortal/TypesModal";
import * as R from "ramda";

export const getTypesFiltered = createSelector(
  getters.types,
  getters.itemFilter,
  (types, itemFilter) => {
    if (R.isEmpty(types)) {
      return types;
    }
    return R.filter(
      type => R.contains(R.toLower(itemFilter), R.toLower(type.name)),
      types
    );
  }
);

export const getSelectedItems = createSelector(
  getters.types,
  R.compose(
    R.reduce((acc, curr) => {
      if (R.length(curr.items) === 0) {
        return acc;
      }
      return [
        ...acc,
        ...R.map(
          item => ({ typeId: curr.id, typeName: curr.name, ...item }),
          curr.items
        )
      ];
    }, []),
    R.map(type => ({
      ...type,
      items: R.filter(
        item => R.any(({ selected }) => selected === true, item.variants),
        type.items
      )
    }))
  )
);
