import React from "react";

import { connect } from "react-redux";

import { actions, getters } from "Items/AddQuestionModal";

import { getShowPrices } from "Items/AddQuestionModal/selectors";

import { QUESTION_TYPES } from "Items/AddQuestionModal/constants";
import resolveFieldTypeIcon from "components/Global/Table3/FieldTypeIcons/resolve-field-type-icon";

import {
  Div,
  Text2,
  Dropdown,
  BigOutlineInput,
  MediumOutlineInput,
  TextAreaInput,
  Cards,
  DragIcon,
  FontIcon,
  SmallAvatar
} from "components/Base";

import CurrencySettings from "components/Global/Table3/ModalColumn/CurrencySettings";
import DateEditorSettings from "components/Global/Table3/ModalColumn/DateEditorSettings";
import DateFormatSettings from "components/Global/Table3/ModalColumn/DateFormatSettings";
import DateTimeEditorSettings from "components/Global/Table3/ModalColumn/DateTimeEditorSettings";
import DateTimeFormatSettings from "components/Global/Table3/ModalColumn/DateTimeFormatSettings";
import DropdownOptions from "components/Global/Table3/ModalColumn/DropdownOptions";
import PercentSettings from "components/Global/Table3/ModalColumn/PercentSettings";
import PhoneEditorSettings from "components/Global/Table3/ModalColumn/PhoneEditorSettings";
import TextSettings from "components/Global/Table3/ModalColumn/TextSettings";
import Checkbox from "material-ui/Checkbox";
import Tooltip from "components/Global/Tooltip";

import { noop, withProps } from "utils/General";

const decorate = connect(
  state => ({
    questionType: getters.questionType(state),
    questionName: getters.questionName(state),
    questionDescription: getters.questionDescription(state),
    questionTypeSettings: getters.questionTypeSettings(state),
    isInternal: getters.isInternal(state),
    showPrices: getShowPrices(state)
  }),
  {
    setQuestionType: actions.setQuestionType,
    setQuestionName: actions.setQuestionName,
    setQuestionDescription: actions.setQuestionDescription,
    setQuestionTypeSetting: actions.setQuestionTypeSetting,
    setIsInternal: actions.setIsInternal
  }
);

const OptionsWrapper = ({ children }) => <Div>{children}</Div>;

const Options = ({ type, currentSettings, updateSetting }) => {
  switch (type) {
    case "dropdown":
      return (
        <OptionsWrapper>
          <DropdownOptions
            settings={currentSettings}
            onChange={settings => updateSetting({ type: "dropdown", settings })}
          />
        </OptionsWrapper>
      );
    /*
    case "date":
      return (
        <OptionsWrapper>
          <DateEditorSettings
            value={currentSettings}
            onChange={settings => updateSetting({ type: "date", settings })}
          />
          <DateFormatSettings
            settings={currentSettings}
            updateDateSettings={settings => updateSetting("date", settings)}
          />
        </OptionsWrapper>
      );
    case "datetime":
      return (
        <OptionsWrapper>
          <DateTimeFormatSettings
            value={currentSettings}
            onChange={settings => updateSetting({ type: "datetime", settings })}
          />
          <DateTimeEditorSettings
            value={currentSettings}
            onChange={settings => updateSetting({ type: "datetime", settings })}
          />
        </OptionsWrapper>
      );
    */
    case "percent":
      return (
        <OptionsWrapper>
          <PercentSettings
            value={currentSettings}
            onChange={settings => updateSetting({ type: "percent", settings })}
          />
        </OptionsWrapper>
      );
    case "currency":
      return (
        <OptionsWrapper>
          <CurrencySettings
            value={currentSettings}
            onChange={settings => updateSetting({ type: "currency", settings })}
          />
        </OptionsWrapper>
      );
    case "phone":
      return (
        <OptionsWrapper>
          <PhoneEditorSettings
            value={currentSettings}
            onChange={settings => updateSetting({ type: "phone", settings })}
          />
        </OptionsWrapper>
      );
    case "text":
      return (
        <OptionsWrapper>
          <TextSettings
            settings={currentSettings}
            updateTextSettings={settings =>
              updateSetting({ type: "text", settings })
            }
          />
        </OptionsWrapper>
      );
    default:
      return null;
  }
};

const Option = ({ name, price }) => (
  <Div
    display="row.flex-start.center"
    px={2}
    mb={1}
    bra={1}
    bc="neutral1"
    bg="white"
    shadow={1}
    py={1}
  >
    <MediumOutlineInput width={270} value={name} continuous onChange={noop} />
    <MediumOutlineInput
      width={80}
      ml={2}
      mr={1}
      value={price}
      type="number"
      continuous
      onChange={noop}
    />
    <DragIcon color="neutral2" />
  </Div>
);

const QuestionType = ({ type, name }) => (
  <Div display="row.flex-start.center" height={1}>
    <SmallAvatar
      id={type}
      Icon={withProps({
        style: { fontSize: 16 },
        children: resolveFieldTypeIcon({
          type,
          style: {
            fontSize: "16px"
          }
        })
      })(FontIcon)}
      square
    />

    <Text2 bold color="black" ml={2}>
      {name}
    </Text2>
  </Div>
);

const SelectQuestionFields = ({
  questionName,
  questionType,
  questionDescription,
  questionTypeSettings,
  setQuestionType,
  setQuestionName,
  setQuestionDescription,
  setQuestionTypeSetting,
  isInternal,
  setIsInternal
}) => {
  const OptionsPicker = (
    <Options
      type={questionType}
      currentSettings={questionTypeSettings[questionType]}
      updateSetting={setQuestionTypeSetting}
    />
  );
  return (
    <Div width={1} height={1} p={6}>
      <Div mb={3}>
        <Text2 color="black" mb={1}>
          Question Type
        </Text2>
        <Dropdown
          usePortal
          value={questionType}
          options={QUESTION_TYPES.map(({ id, name }) => ({
            value: id,
            label: <QuestionType type={id} name={name} />
          }))}
          onChange={v => setQuestionType(v ? v.value : null)}
        />
      </Div>
      <Div width={1} mb={3}>
        <Text2 color="black" mb={1}>
          Question Label
        </Text2>
        <BigOutlineInput
          width={1}
          value={questionName}
          onChange={value => setQuestionName(value)}
          continuous
        />
      </Div>
      <Div width={1}>
        <Text2 color="black" mb={1}>
          Question Description
        </Text2>
        <TextAreaInput
          width={1}
          value={questionDescription}
          onChange={value => setQuestionDescription(value)}
          continuous
        />
      </Div>

      <Div display="row.flex-start.center">
        <Div width={225}>
          <Checkbox
            checked={isInternal}
            iconStyle={{ marginRight: 8 }}
            labelStyle={{
              whiteSpace: "nowrap"
            }}
            onCheck={() => setIsInternal(!isInternal)}
            label="This is an internal question"
          />
        </Div>
        <Tooltip
          tooltip="Internal questions are only visible on the admin side. These are good for tracking information you don't want shown to others."
          placement="top"
        >
          <span
            className="material-icons"
            style={{ fontSize: 16, cursor: "pointer", color: "#888" }}
          >
            help
          </span>
        </Tooltip>
      </Div>

      {questionTypeSettings[questionType] ? (
        <Div width={1}>
          <Text2 color="black" mb={1}>
            Question Settings
          </Text2>

          {OptionsPicker}
        </Div>
      ) : null}
    </Div>
  );
};

export default decorate(SelectQuestionFields);
