import CONTACT_FIELDS from "./constants/contact-fields";

export default {
  mustBeChildField: false,
  canBeChildField: true,
  canBeParentField: true,
  canBeChildOfFieldTypes: [
    "contact",
    "inventory",
    "schedule-activity",
    "reference",
    "account"
  ],
  mappableFields: CONTACT_FIELDS
};
