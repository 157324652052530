import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import autobind from "autobind-decorator";
import { get } from "lodash";
import getValue from "utils/value-types/get-value/dependency";
import dropdownToString from "utils/value-types/to-string/dropdown";
import getDropDownValue from "utils/value-types/get-value/dropdown";

export default class DependencyEditor extends Component {
  @autobind
  getInputNode() {
    return ReactDOM.findDOMNode(this);
  }

  formatValue(value) {
    return get(getValue(value), "records[0].value", "");
  }

  @autobind
  onClick() {
    this.getInputNode().focus();
  }

  @autobind
  onDoubleClick() {
    this.getInputNode().focus();
  }

  @autobind
  wrapValue(value) {
    return {
      type: "dropdown",
      value: { records: [{ value }] }
    };
  }

  getSettings(column) {
    return ["inventory", "schedule-activity"].includes(column.type)
      ? column.settings.dependencySettings
      : column.settings;
  }

  renderOptions() {
    const options = [];
    let dependencyOptions = [];
    const settings = this.getSettings(this.props.column);
    const dependencyColumnId = settings.columnId;
    const dependencyValue = dropdownToString(
      getDropDownValue(get(this.props, `rowMetaData[${dependencyColumnId}]`))
    );
    if (settings.items[dependencyValue]) {
      dependencyOptions = settings.items[dependencyValue].options;
    }

    options.unshift(<option key="default" value="" />);

    dependencyOptions.forEach(({ value }, index) => {
      options.push(
        <option key={index} value={value}>
          {value}
        </option>
      );
    });
    return options;
  }

  @autobind
  onChange(e) {
    this.props.onChange(this.wrapValue(e.target.value));
  }

  render() {
    return (
      <select
        className="styled"
        style={this.props.style}
        value={this.formatValue(this.props.value)}
        onChange={this.onChange}
      >
        {this.renderOptions()}
      </select>
    );
  }
}

DependencyEditor.propTypes = {
  style: PropTypes.object,
  rowMetaData: PropTypes.object,
  value: PropTypes.object,
  column: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};
