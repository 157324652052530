import * as R from "ramda";

export const NAMESPACE = "ScheduleViewPicker";

const sortByDate = (a, b) =>
  Date.parse(b.updatedAtTimestamp) - Date.parse(a.updatedAtTimestamp);

export const FILTER = {
  ALL: "all",
  BY_USER: "by_user"
};

export const SORT = {
  RECENT: "Most_Recent",
  ALPHABETH: "Alphabetically"
};

export const SORTERS = [
  { id: SORT.RECENT, title: "Most Recent", sortFn: R.sort(sortByDate) },
  {
    id: SORT.ALPHABETH,
    title: "A to Z",
    sortFn: R.sortBy(
      R.compose(
        R.toLower,
        R.prop("name")
      )
    )
  }
];

export const TABS = [
  { id: FILTER.ALL, title: "All" },
  { id: FILTER.BY_USER, title: "By Me" }
];

export const BUTTONS_TYPE = {
  SCHEDULES: "scheduleActions",
  VIEWS: "viewOfSchedules"
};
