/* eslint-disable react/prop-types */

import React from "react";
import * as R from "ramda";

import { connect } from "react-redux";
import { WithFormInstanceProvider } from "ui-kit/Form/View";
import { Div, BigFilledButton, InfoIcon } from "components/Base";
import { Label, Divider, FloatingSaveBar } from "EventLight/Common/FieldLayout";
import Tabs from "ui-kit/Tabs/View";
import { getters as TabGetters } from "ui-kit/Tabs";

import {
  FORM_ID,
  FIELD_IDS,
  DATE_FORMAT,
  EVENT_TYPES,
  TYPE_TABS,
  CUSTOM_TYPE_TAB,
  FEATURE_TAB_INSTANCE_ID,
  FEATURE_TABS,
  CUSTOM_FEATURE_TAB,
  TYPE_TAB_INSTANCE_ID
} from "../constants";
import { actions, getters } from "../model";
import {
  getIsValid,
  getTimezones,
  getCanAddLiveEvents,
  getFeaturesToShow,
  getTypesToShow
} from "../selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";

import {
  Input,
  DateTimeInput,
  Dropdown,
  Image,
  MarkEventDays
} from "ui-kit/Form/View";

import { setLocalZone, getCurrentTimezone } from "utils/General";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

const decorate = R.compose(
  WithFormInstanceProvider(R.always(FORM_ID)),
  connect(
    (state, props) => ({
      data: getters.data(state),
      isValid: getIsValid(state, props),
      timezones: getTimezones(state),
      availableFeatures: getFeaturesToShow(state),
      availableTypes: getTypesToShow(state),
      availableCurrencies: getters.availableCurrencies(state),
      canAddLiveEvents: getCanAddLiveEvents(state),
      eventDetails: getEventDetails(state),
      currentTypeTab: TabGetters.selectedTab(state, {
        instanceId: TYPE_TAB_INSTANCE_ID
      }),
      currentFeatureTab: TabGetters.selectedTab(state, {
        instanceId: FEATURE_TAB_INSTANCE_ID
      }),
      canViewLenndFeatures: selectFeatureFlag(
        flags.CAN_VIEW_LENND_FEATURES.NAME
      )(state)
    }),
    {
      save: actions.save,
      updateField: actions.updateField,
      updateType: actions.updateType,
      updateFeature: actions.updateFeature
    }
  ),
  CSSModules(css)
);

const Scope = ({ tag, last }) => {
  return (
    <Div
      className={css.tag}
      uppercase
      fs={0}
      py={1}
      mr={last ? 0 : 1}
      style={{
        padding: "1px 0px",
        fontSize: "9px"
      }}
    >
      {tag}
    </Div>
  );
};

const Card = ({
  icon,
  title,
  description,
  selected,
  disabled,
  autoselected,
  onClick,
  beta,
  tags = [],
  scopes = []
}) => (
  <Div
    className={
      disabled
        ? css.disabledCard
        : autoselected || selected
        ? css.selectedCard
        : css.card
    }
    display="column.center.center"
    width={200}
    mr={3}
    mb={3}
    onClick={autoselected || disabled ? undefined : onClick}
    b={1}
    p={5}
    bra={1}
    color={
      disabled
        ? "gray5"
        : autoselected || selected
        ? "gray7"
        : {
            default: "gray5",
            hover: "gray7"
          }
    }
    bc={
      disabled
        ? "gray5"
        : autoselected || selected
        ? "gray7"
        : {
            default: "gray5",
            hover: "gray7"
          }
    }
  >
    {beta ? <div className={css.beta}>Admin</div> : null}
    {/*
    {autoselected || selected ? (
      <Tooltip tooltip="Settings">
        <div
          className={css.settings}
          onClick={e => {
            e.stopPropagation();
            onSettingsClick();
          }}
        >
          <span className="material-icons-outlined">settings</span>
        </div>
      </Tooltip>
    ) : null}
        */}
    {autoselected || selected ? (
      <div className={css.selected}>
        <span className="material-icons-outlined">done</span>
      </div>
    ) : null}
    <Div display="row.center.center" fs={4} fw={3}>
      <span className="material-icons-outlined">{icon}</span>
    </Div>
    <Div display="row.center.center" fs={4} fw={3}>
      {title}
      {description && description.length ? (
        <InfoIcon ml={1} tooltip={description} color="gray5" size={13} />
      ) : null}
    </Div>
    {scopes && scopes.length ? (
      <Div display="row.center.center" fs={1} mt={2}>
        {scopes.map((tag, idx) => (
          <Scope key={tag} tag={tag} last={tags.length - 1 === idx} />
        ))}
      </Div>
    ) : null}
  </Div>
);

const Body = ({
  data,
  isValid,
  save,
  timezones,
  availableFeatures,
  updateFeature,
  availableTypes,
  availableCurrencies,
  updateType,
  canAddLiveEvents,
  currentTypeTab,
  currentFeatureTab,
  canViewLenndFeatures
}) => (
  <Div width={1}>
    <Div display="column" mb={5} width={1}>
      <Label>Event Name</Label>
      <Input
        fieldId={FIELD_IDS.NAME}
        width={1}
        iniValue={R.prop(FIELD_IDS.NAME)(data)}
        size="big"
        inputType="outline"
      />
    </Div>

    <Div display="column" mb={5} width={1}>
      <Label tooltip="This is the event name that will appear publicly in your form and portal URLs">
        Event Slug
      </Label>
      <Input
        fieldId={FIELD_IDS.SLUG}
        width={1}
        iniValue={R.prop(FIELD_IDS.SLUG)(data)}
        size="big"
        inputType="outline"
      />
    </Div>

    <Div display="column" mb={5} width={1}>
      <Label>What best describes your event?</Label>
      <Dropdown
        fieldId={FIELD_IDS.TYPE}
        width="100%"
        iniValue={R.prop(FIELD_IDS.TYPE)(data)}
        options={EVENT_TYPES}
        size="big"
        usePortal
      />
    </Div>

    <Div display="column" mb={5} width={1}>
      <Label>Timezone</Label>
      <Dropdown
        fieldId={FIELD_IDS.TIMEZONE}
        width="100%"
        iniValue={R.prop(FIELD_IDS.TIMEZONE)(data)}
        options={timezones}
        size="big"
        usePortal
      />
    </Div>

    <Div mb={5} display="row.flex-start.center" width={1}>
      <Div display="column" flex={1}>
        <Label tooltip="This is the earliest date you want to set for assets, catering, or passes. Usually this covers load-in days.">
          Starts At
        </Label>
        <DateTimeInput
          inputClassName={css.dateTimeInput}
          fieldId={FIELD_IDS.DATE_FROM}
          dateFormat={DATE_FORMAT}
          iniValue={setLocalZone(
            R.propOr("", FIELD_IDS.DATE_FROM, data),
            getCurrentTimezone()
          )}
          data-cy="event-details-start-at-input"
        />
      </Div>
      <Div ml={5} display="column" flex={1}>
        <Label tooltip="This is the last date you want to set for assets, catering, or passes. Usually this covers load-out days.">
          Ends At
        </Label>
        <DateTimeInput
          inputClassName={css.dateTimeInput}
          fieldId={FIELD_IDS.DATE_TO}
          dateFormat={DATE_FORMAT}
          iniValue={setLocalZone(
            R.propOr("", FIELD_IDS.DATE_TO, data),
            getCurrentTimezone()
          )}
          data-cy="event-details-ends-at-input"
        />
      </Div>
    </Div>

    <Div display="column" mb={5} width={1}>
      <Label>Currency</Label>
      <Dropdown
        fieldId={FIELD_IDS.CURRENCY}
        width="100%"
        iniValue={R.prop(FIELD_IDS.CURRENCY)(data)}
        options={availableCurrencies}
        size="big"
        usePortal
      />
    </Div>

    <Div display="row.flex-start.flex-start" mb={5}>
      <Div display="column" flex={1}>
        <Label>Logo Image</Label>
        <Image
          fieldId={FIELD_IDS.LOGO_IMAGE_URL}
          containerWidth="auto"
          containerProps={{
            display: "row.flex-start.flex-start",
            flex: 1
          }}
          imageWidth={120}
          imageHeight={120}
          recommendedWidth={"300"}
          recommendedHeight={"300"}
          iniValue={
            R.prop(FIELD_IDS.LOGO_IMAGE_URL, data)
              ? [
                  {
                    url: R.prop(FIELD_IDS.LOGO_IMAGE_URL, data)
                  }
                ]
              : null
          }
        />
      </Div>

      <Div display="column" flex={1} ml={5}>
        <Label>Background Image</Label>
        <Image
          fieldId={FIELD_IDS.BACKGROUND_IMAGE_URL}
          containerWidth="auto"
          containerProps={{
            display: "row.flex-start.flex-start",
            flex: 1
          }}
          imageWidth={198}
          imageHeight={57}
          recommendedWidth={"1500"}
          recommendedHeight={"500"}
          iniValue={
            R.prop(FIELD_IDS.BACKGROUND_IMAGE_URL, data)
              ? [
                  {
                    url: R.prop(FIELD_IDS.BACKGROUND_IMAGE_URL, data)
                  }
                ]
              : null
          }
        />
      </Div>
    </Div>

    <Div display="column" mb={5} width={1}>
      <Label>Manage Dates</Label>
      <Div fs={2} color="gray6" mb={2}>
        Select event dates to mark their purpose.{" "}
        <span className={css.strong}>All days must be marked.</span>
      </Div>
      <MarkEventDays
        iniValue={R.prop(FIELD_IDS.EVENT_DAYS, data)}
        fieldId={FIELD_IDS.EVENT_DAYS}
        startDate={R.propOr("", FIELD_IDS.DATE_FROM, data)}
        endDate={R.propOr("", FIELD_IDS.DATE_TO, data)}
      />
    </Div>

    <>
      <Divider />

      <Label mb={canAddLiveEvents ? 3 : 0}>
        What types of groups and people are you managing for this event?
      </Label>

      <Tabs
        instanceId={TYPE_TAB_INSTANCE_ID}
        tabs={TYPE_TABS}
        flex={1}
        mb={3}
        tabStyle={{
          fs: 4
        }}
      />

      <Div
        display="row.flex-start.center"
        style={{
          maxWidth: "900px",
          flexWrap: "wrap"
        }}
      >
        {currentTypeTab === CUSTOM_TYPE_TAB ? (
          <Div
            p={5}
            bra={1}
            bg="neutral1"
            color="gray7"
            display="row.center.center"
            width={1}
            mb={3}
          >
            <span>
              Interested in managing or onboarding more of your groups and
              people?{" "}
              <span
                className={css.link}
                onClick={() => {
                  if (window.Intercom) {
                    window.Intercom(
                      "showNewMessage",
                      `Hello! I am interested in adding additional types of groups or people. I would like to add...`
                    );
                  }
                }}
              >
                Contact us for assistance
              </span>
              .
            </span>
          </Div>
        ) : null}
        {availableTypes.map(f => (
          <Card
            key={f.id}
            title={f.name}
            icon={f.icon}
            description={f.description}
            selected={f.selected}
            disabled={f.disabled}
            autoselected={f.autoselected}
            tags={f.plans}
            scopes={f.scopes}
            onClick={() =>
              updateType({
                field: f.id,
                value: !f.selected
              })
            }
          />
        ))}
      </Div>
    </>

    {canViewLenndFeatures ? (
      <>
        <Divider />

        <Label mb={canAddLiveEvents ? 3 : 0}>
          What features do you want to enable for this event?
        </Label>

        <Tabs
          instanceId={FEATURE_TAB_INSTANCE_ID}
          tabs={FEATURE_TABS}
          flex={1}
          mb={3}
          tabStyle={{
            fs: 4
          }}
        />

        <Div
          display="row.flex-start.center"
          mb={5}
          style={{
            maxWidth: "900px",
            flexWrap: "wrap",
            alignItems: "flex-start"
          }}
        >
          {currentFeatureTab === CUSTOM_FEATURE_TAB ? (
            <Div
              p={5}
              bra={1}
              bg="neutral1"
              color="gray7"
              display="row.center.center"
              width={1}
              mb={3}
            >
              <span>
                Interested in creating custom applications to mange your event's
                data?{" "}
                <span
                  className={css.link}
                  onClick={() => {
                    if (window.Intercom) {
                      window.Intercom(
                        "showNewMessage",
                        `Hello! I am interested in adding a custom application to my event. I will use it to...`
                      );
                    }
                  }}
                >
                  Contact us for assistance
                </span>
                .
              </span>
            </Div>
          ) : null}

          {availableFeatures.map(f => (
            <Card
              key={f.id}
              title={f.name}
              icon={f.icon}
              description={f.description}
              selected={f.selected}
              disabled={f.disabled}
              autoselected={f.autoselected}
              beta={f.beta}
              tags={f.plans}
              onClick={() =>
                updateFeature({
                  field: f.id,
                  value: !f.selected
                })
              }
            />
          ))}
        </Div>
      </>
    ) : null}

    <FloatingSaveBar>
      <Div display="row.flex-start.center">
        <BigFilledButton
          bg="altB5"
          color="white"
          disabled={isValid !== true}
          onClick={save}
          mr={4}
          data-cy="event-details-save-button"
        >
          Save
        </BigFilledButton>
        {isValid !== true ? (
          <Div fs={1} color="gray7">
            {isValid}
          </Div>
        ) : null}
      </Div>
    </FloatingSaveBar>
  </Div>
);

export default decorate(Body);
