import PropTypes from "prop-types";
import React, { Component } from "react";
import { get, find } from "lodash";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class MappedFields extends Component {
  render() {
    const {
      fields,
      parentFieldId,
      mappableFields,
      mappedFields,
      updateMappedFields
    } = this.props;

    return (
      <div>
        {mappableFields.map(field => (
          <label key={field.id} styleName="row">
            <div styleName="label">
              {typeof field.name !== "undefined"
                ? field.name
                : field.schema.label}:
            </div>
            <div>
              <select
                styleName="select"
                value={get(
                  find(mappedFields, { destinationFieldId: field.id }),
                  "sourceFieldId",
                  null
                )}
                onChange={e =>
                  updateMappedFields(parentFieldId, e.target.value, field.id)
                }
              >
                <option value="" />
                {fields
                  .filter(f => field.mappableFieldTypes.includes(f.type))
                  .map(f => (
                    <option key={f.id} value={f.id}>
                      {typeof f.name !== "undefined" ? f.name : f.schema.label}
                    </option>
                  ))}
              </select>
            </div>
          </label>
        ))}
        {mappableFields.length === 0 && <div>There are no related fields.</div>}
      </div>
    );
  }
}

MappedFields.propTypes = {
  fields: PropTypes.array.isRequired,
  parentFieldId: PropTypes.string.isRequired,
  mappableFields: PropTypes.array.isRequired,
  mappedFields: PropTypes.array.isRequired,
  updateMappedFields: PropTypes.func.isRequired
};

export default MappedFields;
