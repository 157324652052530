import PropTypes from "prop-types";
import React, { Component, cloneElement } from "react";
import HeightSizer from "components/Global/Table3/Sizers/Expand";
import Table from "./Table";

class Table3 extends Component {
  render() {
    return cloneElement(this.props.heightSizer || <HeightSizer />, {
      children: <Table ref="Table" {...this.props} />
    });
  }
}

Table3.propTypes = {
  heightSizer: PropTypes.Component
};

export default Table3;
