import { put, all, takeEvery, fork, call, select } from "redux-saga/effects";
import { actions } from "Portal/PortalPageWrapper/model";
import { getCredentials } from "redux/modules/user/selectors";
import { relatedAccountId } from "redux/modules/portal/user/selectors";

import api from "./api";

import { registerError } from "redux/modules/errors/actions";

const init = function*({ payload: pageId }) {
  yield put(actions.setLoading(true));

  const credentials = yield select(getCredentials);
  const accountId = yield select(relatedAccountId);

  try {
    const { payload } = yield call(api.getPortalData, {
      credentials: credentials,
      pageId: pageId,
      accountId
    });

    yield put(
      actions.setInitialData({
        ...payload
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred getting portal media center"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([fork(watchInit)]);
};

export default rootSaga;
