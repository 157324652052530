import api from "./api";
import { registerError } from "redux/modules/errors/actions";
import { SHOW, HIDE, UPDATE } from "./constants";

export function addVisibleRelatedModule(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: SHOW,
      payload: {
        moduleId: data.moduleId,
        relatedModuleId: data.relatedModule.id,
        fieldId: data.relatedModule.fieldId,
        order: data.relatedModule.order
      }
    });
    try {
      const result = await api.post(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding visible related module"
          }
        ])
      );
    }
  };
}

export function updateVisibleRelatedModule(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: UPDATE,
      payload: {
        moduleId: data.moduleId,
        relatedModules: data.relatedModules
      }
    });
    try {
      const result = await api.put(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating visible related module"
          }
        ])
      );
    }
  };
}

export function deleteVisibleRelatedModule(data) {
  return async (dispatch, getState) => {
    dispatch({
      type: HIDE,
      payload: {
        moduleId: data.moduleId,
        relatedModuleId: data.relatedModule.id,
        fieldId: data.relatedModule.fieldId
      }
    });
    try {
      const result = await api.delete(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting visible related module"
          }
        ])
      );
    }
  };
}
