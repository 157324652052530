const R = require("ramda");
import {
  CREDENTIAL_TYPE_ID,
  INVENTORY_TYPE_ID,
  MEAL_TYPE_ID,
  SPONSORSHIP_TYPE_ID,
  BOOTH_TYPE_ID
} from "utils/item-types";

const getPlaceholderByItemType = id =>
  R.prop(id)({
    [CREDENTIAL_TYPE_ID]: "confirmation_number",
    [INVENTORY_TYPE_ID]: "construction",
    [MEAL_TYPE_ID]: "restaurant",
    [SPONSORSHIP_TYPE_ID]: "storefront",
    [BOOTH_TYPE_ID]: "storefront"
  });

export { getPlaceholderByItemType };
