import React, { Component } from "react";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import IconMenu from "material-ui/IconMenu";
import MenuItem from "material-ui/MenuItem";
import CircularProgress from "material-ui/CircularProgress";
import { Div } from "components/Base";
import { withState } from "utils/General";

import Panel from "components/Global-2016/Panel";
import ProgressChart from "./ProgressChart";
import CredentialLabel from "./CredentialLabel";
import AddBudget from "./AddBudget";
import EditSummaryModal from "./EditSummaryModal";
import { Table as EmptyTable } from "EventLight/Passes/Dashboard/View/EmptySummaryPanel";

const Title = ({ loading, children }) => (
  <Div color="gray7" display="row.flex-start.center" fw={3} fs={5} height={50}>
    <Div mr={4}>{children}</Div>
    {loading ? <CircularProgress size={17.85} mode="indeterminate" /> : null}
  </Div>
);

const TotalCell = ({ children }) => <Div pt={3}>{children}</Div>;

@CSSModules(css)
class SummaryPanel extends Component {
  @autobind
  refreshDashboard() {
    this.props.refreshDashboard();
  }

  @autobind
  toggleColumn() {
    this.props.updateIssuedView(
      this.props.issuedView === "issued" ? "picked_up" : "issued"
    );
  }

  @autobind
  handleSummaryUpdate(summary) {
    this.props.hideModal();
    this.props
      .updateWidget({ ...summary, widgetId: summary.id })
      .then(() => this.refreshDashboard());
  }

  @autobind
  onMenuSelect(event, val) {
    event.stopPropagation();
    switch (val) {
      case "edit":
        const modalProps = {
          summary: this.getWidget(),
          cancel: this.props.hideModal,
          onSave: this.handleSummaryUpdate,
          eventCredentials: this.props.eventCredentials,
          onDelete: this.deleteWidget,
          hideModal: this.props.hideModal
        };
        this.props.showModal({ content: <EditSummaryModal {...modalProps} /> });
        break;
      default:
    }
  }

  @autobind
  deleteWidget() {
    this.props.hideModal();
    this.props
      .deleteWidget({ widgetId: this.props.widget.id })
      .then(() => this.refreshDashboard());
  }

  @autobind
  getWidget() {
    return this.props.widgets[this.props.widget.id] || {};
  }

  updateBudget = async (val, id) => {
    await this.props.updateVariant({
      variant: {
        id,
        trackInventory: true,
        inventoryQuantity: val
      }
    });
    return this.refreshDashboard();
  };

  formatWidth(val) {
    return val >= 1 ? "100%" : `${Math.round(Math.floor(val * 100))}%`;
  }

  @autobind
  getField(key) {
    const widget = this.getWidget();
    return widget.fields ? widget.fields.find(f => f.key === key) : {};
  }

  progressProps({ requested, approved, budget, activated, issued, picked_up }) {
    // default empty state
    const progressProps = {
      primary: {
        color: "#eae8ed",
        width: "100%"
      }
    };

    if (requested) {
      progressProps.primary.color = "#d1d0d3";
      progressProps.primary.title = "Requested";
    }
    if (approved) {
      progressProps.tertiary = {
        color: "#00c57c",
        width: this.formatWidth(approved / requested),
        title: "Approved"
      };
    }
    if (budget && budget > 0) {
      progressProps.primary.color = "#eae8ed";
      progressProps.primary.title = "Budget";
      progressProps.secondary = {
        color: "#d1d0d3",
        width: this.formatWidth(requested / budget),
        title: "Requested"
      };
    }
    if (budget && budget > 0 && approved) {
      progressProps.tertiary.width = this.formatWidth(approved / budget);
    }
    if (budget && budget > 0 && approved && approved > budget) {
      progressProps.tertiary = {
        width: this.formatWidth(1),
        color: "#8b0000",
        title: "Over budget"
      };
    }

    if (issued || picked_up) {
      // This bar is relative to the width of tertiary bar (approved).
      // However, the width should be expressed relative to the full bar width 100%.
      const denominator = budget && budget > 0 && approved ? budget : requested;
      progressProps.quaternary = {
        color: "#38578e",
        width: this.formatWidth(
          (approved / denominator) *
            (this.props.issuedView === "issued"
              ? issued / approved
              : picked_up / approved)
        ),
        title: this.props.issuedView === "issued" ? "Issued" : "Picked Up"
      };
    }

    return progressProps;
  }

  render() {
    const { name, fetching, value, settings } = this.getWidget();
    const { widget, canUpdateBudget, issuedView } = this.props;

    if ((fetching && !value) || !settings) {
      return (
        <Panel style={css.panel}>
          <Title loading>{widget.name}</Title>
          <EmptyTable />
        </Panel>
      );
    }

    const { credentials, fields } = settings;

    const fieldsMap = fields.reduce(
      (a, field) => {
        a[field.key] = field;
        return a;
      },
      {
        requested: {},
        approved: {},
        issued: {},
        budget: {},
        remaining: {},
        progress: {}
      }
    );

    const rows = credentials.map(credentialId => {
      const credential = value.credentials.find(c => c.id === credentialId);
      if (credential) {
        return (
          <tr key={credential.id} styleName="row">
            <td styleName="credential">
              <CredentialLabel {...credential} />
            </td>
            {fieldsMap.requested.visible && (
              <td styleName="number">{credential.counts.requested}</td>
            )}
            {fieldsMap.approved.visible && (
              <td styleName="number">{credential.counts.approved}</td>
            )}
            {fieldsMap.issued.visible && (
              <td styleName="number">
                {issuedView === "issued"
                  ? credential.counts.issued
                  : credential.counts.picked_up}
              </td>
            )}
            {fieldsMap.budget.visible && (
              <td styleName="number">
                <AddBudget
                  updateBudget={this.updateBudget}
                  canUpdateBudget={canUpdateBudget}
                  {...credential}
                />
              </td>
            )}
            {fieldsMap.remaining.visible && (
              <td
                styleName="number"
                style={
                  credential.counts.remaining < 0
                    ? { color: "#8b0000", fontWeight: 600 }
                    : {}
                }
              >
                {credential.counts.remaining || "—"}
              </td>
            )}
            {fieldsMap.progress.visible && (
              <td styleName="chart">
                <ProgressChart {...this.progressProps(credential.counts)} />
              </td>
            )}
          </tr>
        );
      }
    });

    rows.push(
      <tr
        styleName="row"
        style={{
          paddingTop: 5,
          borderTop: "1px solid #eee"
        }}
      >
        <td styleName="credential">
          <TotalCell>
            <Div fs={2} fw={3}>
              TOTAL
            </Div>
          </TotalCell>
        </td>
        {fieldsMap.requested.visible && (
          <td styleName="number">
            <TotalCell>{value.totals.requested}</TotalCell>
          </td>
        )}
        {fieldsMap.approved.visible && (
          <td styleName="number">
            <TotalCell>{value.totals.approved}</TotalCell>
          </td>
        )}
        {fieldsMap.issued.visible && (
          <td styleName="number">
            <TotalCell>
              {issuedView === "issued"
                ? value.totals.issued
                : value.totals.picked_up}
            </TotalCell>
          </td>
        )}
        {fieldsMap.budget.visible && (
          <td styleName="number">
            <TotalCell>{value.totals.budget}</TotalCell>
          </td>
        )}
        {fieldsMap.remaining.visible && (
          <td
            styleName="number"
            style={
              value.totals.remaining < 0
                ? { color: "#8b0000", fontWeight: 600 }
                : {}
            }
          >
            <TotalCell>{value.totals.remaining || "—"}</TotalCell>
          </td>
        )}
        {fieldsMap.progress.visible && (
          <td styleName="chart">
            <TotalCell>
              <ProgressChart {...this.progressProps(value.totals)} />
            </TotalCell>
          </td>
        )}
      </tr>
    );

    return (
      <Panel style={css.panel}>
        <Title loading={fetching}>
          <Div mr={2}>{name}</Div>
        </Title>
        <div styleName="menuWrapper">
          <IconMenu
            onChange={this.onMenuSelect}
            iconButtonElement={<i className="material-icons">more_vert</i>}
          >
            <MenuItem value="edit" primaryText="Edit" />
          </IconMenu>
        </div>
        <table>
          <thead>
            <tr>
              <td styleName="header">Type</td>
              {fieldsMap.requested.visible && (
                <td styleName="numberHeader">Requested</td>
              )}
              {fieldsMap.approved.visible && (
                <td styleName="numberHeader">Approved</td>
              )}
              {fieldsMap.issued.visible && (
                <td styleName="numberHeader" onClick={this.toggleColumn}>
                  <div styleName="toggle">
                    {issuedView === "issued" ? "Issued" : "Picked Up"}
                  </div>
                </td>
              )}
              {fieldsMap.budget.visible && (
                <td styleName="numberHeader">Budget</td>
              )}
              {fieldsMap.remaining.visible && (
                <td styleName="numberHeader">Remain.</td>
              )}
              {fieldsMap.progress.visible && (
                <td styleName="chartHeader">Progress</td>
              )}
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </Panel>
    );
  }
}

export default withState("issuedView", "updateIssuedView", "issued")(
  SummaryPanel
);
