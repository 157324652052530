import React from "react";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import {
  Div,
  LeftIcon,
  SuperFilledButton,
  EditIcon,
  CloseIcon,
  Span
} from "components/Base";
import { withRouter } from "react-router";
import * as R from "ramda";
import { getters, actions } from "../model";
import { getters as SelectItemsGetters } from "PortalHub/CreateOrderRequest/SelectItems/model";

import Tooltip from "components/Global/Tooltip";
import DeleteConfirmation from "ui-kit/DeleteConfirmation";

import { itemsInCart, totalValue, groupBy } from "../selectors";
import { getEventCurrency } from "redux/modules/portal/selectors";
import { addS } from "utils/General";

import styles from "./styles.scss";

var getObjLen = R.compose(
  R.length,
  R.keys
);
const indexedMap = R.addIndex(R.map);

const decorate = R.compose(
  connect(
    (state, props) => ({
      itemsInCartLen: itemsInCart(state, props),
      items: getters.items(state, props),
      total: totalValue(state, props),
      groups: groupBy(state, props),
      highlightColor: SelectItemsGetters.highlightColor(state, props),
      currency: getEventCurrency(state),
      saving: getters.saving(state)
    }),
    {
      handleModifyItemModal: actions.handleModifyItemModal,
      removeItem: actions.removeItem,
      submitForm: actions.submitForm
    }
  ),
  withRouter,
  CSSModules(styles)
);

const Questions = ({ questions }) =>
  !R.isEmpty(questions) ? (
    <Tooltip
      placement="bottom"
      tooltip={
        <Div>
          {indexedMap(([id, question], index) => {
            return (
              <Div key={id}>
                Detail {index + 1} - {question.value}
              </Div>
            );
          }, R.toPairs(questions))}
        </Div>
      }
    >
      <Span>
        - {getObjLen(questions)} Detail{addS(questions.length)}
      </Span>
    </Tooltip>
  ) : null;

const Layout = ({
  itemsInCartLen,
  total,
  groups,
  handleModifyItemModal,
  removeItem,
  highlightColor,
  currency,
  router: { push, params },
  small = false,
  saving,
  submitForm,
  stripe,
  items
}) => (
  <Div styleName="cart-container">
    <Div styleName="cart-header">
      {small ? null : (
        <Div
          styleName="all-items-link"
          onClick={() =>
            push(
              `/hubs/${params.portalSlug}/${params.portalId}/people/${params.recordTypeId}/requests`
            )
          }
        >
          <LeftIcon size={30} color="#333333" />
          <Div>All Items</Div>
        </Div>
      )}

      <Div
        color="#424242"
        style={{ fontSize: small ? 14 : 25, fontWeight: "bold" }}
      >
        Request Summary{" "}
        <span style={{ fontWeight: 500 }}>({itemsInCartLen} Items)</span>
      </Div>
    </Div>

    {R.gt(itemsInCartLen, 0) ? (
      <Div styleName="items-list">
        <Div width={1} style={{ marginTop: 32 }}>
          {R.compose(
            R.map(([category, items]) => (
              <Div width={1} style={{ marginBottom: 40 }} key={category}>
                <Div
                  width={1}
                  textAlign="center"
                  style={{ fontWeight: "bold", marginBottom: 40 }}
                  uppercase
                  color="#111111"
                >
                  {category}
                </Div>
                <Div width={1} display="column.flex-start.center">
                  {R.map(
                    ([index, { item, meta }]) => (
                      <Div
                        key={item.id + index.toString()}
                        display="row.flex-start.center"
                        height={50}
                        flex={1}
                        width={1}
                        style={{
                          borderBottom: "1px dashed rgba(0, 0, 0, 0.29)"
                        }}
                      >
                        <Div color="#000000" width={40}>
                          {item.quantity} X
                        </Div>
                        <Div width={1}>
                          <Div color="#424242" fw={4}>
                            {item.name}
                          </Div>
                          <Div
                            color="#1A1A1A"
                            style={{
                              opacity: 0.65,
                              fontSize: 13
                            }}
                          >
                            {meta.formattedPrice} each{" "}
                            <Questions questions={item.questions} />
                          </Div>
                        </Div>
                        <Div textAlign="right" width={80} mr={2}>
                          {meta.total}
                        </Div>
                        <Div display="row.flex-start.center">
                          <Div
                            bg="#F0EFF1"
                            width={26}
                            height={26}
                            display="row.center.center"
                            style={{ borderRadius: 5, marginRight: 3 }}
                          >
                            <EditIcon
                              onClick={() =>
                                handleModifyItemModal({ item, index })
                              }
                              color="#000000"
                            />
                          </Div>
                          <DeleteConfirmation
                            width={190}
                            onConfirm={() => removeItem(item)}
                            text="Remove Item"
                            paragraph="Are you sure?"
                            confirmLabel="Delete"
                            buttonStyle={{ color: highlightColor }}
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center"
                            }}
                          >
                            <Div
                              bg="#F0EFF1"
                              width={26}
                              height={26}
                              display="row.center.center"
                              style={{ borderRadius: 5 }}
                            >
                              <CloseIcon color="#000000" />
                            </Div>
                          </DeleteConfirmation>
                        </Div>
                      </Div>
                    ),
                    R.toPairs(items)
                  )}
                </Div>
              </Div>
            )),
            R.toPairs
          )(groups)}
        </Div>
        <Div width={1} display="row.center.center">
          <Div
            width={600}
            display="row.space-between.center"
            style={{ marginBottom: 40 }}
          >
            <Div color="#000000">Subtotal ({currency})</Div>
            <Div
              color={highlightColor}
              style={{
                fontSize: 22,
                fontWeight: "bold"
              }}
            >
              {total}
            </Div>
          </Div>
        </Div>

        <Div
          display="row.center.center"
          width={1}
          style={{ padding: 22, borderTop: "1px solid #e2e2e2" }}
        >
          <Div style={{ marginRight: 80 }}>
            <Div
              color="#000000"
              style={{
                fontSize: 15,
                marginBottom: 7
              }}
            >
              Payment processing details will be sent upon approval.
            </Div>
            {/* <Div
              color="#1A1A1A"
              style={{
                fontSize: 13,
                opacity: 0.5
              }}
            >
              By submitting this order, you agree to the terms & conditions
            </Div> */}
          </Div>
          <SuperFilledButton
            color="#161616"
            bg="#F4D288"
            onClick={
              saving
                ? undefined
                : () => submitForm({ stripe, total, items, params })
            }
          >
            {saving ? "Processing..." : "Submit"}
          </SuperFilledButton>
        </Div>

        {/*
        {small ? null : (
          <Div display="row.center.center" width={1}>
            <SuperFilledButton
              color="#161616"
              width={495}
              bg="#F4D288"
              onClick={() =>
                push(
        `/hubs/${params.portalSlug}/${params.portalId}/people/${params.recordTypeId}/requests/checkout`
                )
              }
            >
              Proceed to Checkout
            </SuperFilledButton>
          </Div>
        )}
       */}
      </Div>
    ) : (
      <Div width={1} display="column.center.center" style={{ padding: 22 }}>
        <Div
          color="#424242"
          style={{ fontSize: 25, fontWeight: "normal", marginBottom: 20 }}
          textAlign="center"
        >
          There are no items in the cart
        </Div>

        {small ? null : (
          <SuperFilledButton
            color="#161616"
            bg="#F4D288"
            onClick={() =>
              push(
                `/hubs/${params.portalSlug}/${params.portalId}/people/${params.recordTypeId}/requests`
              )
            }
          >
            Go Back
          </SuperFilledButton>
        )}
      </Div>
    )}
  </Div>
);

export default decorate(Layout);
