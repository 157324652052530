import { createModule } from "redux-mvc";

import model from "./model";
import sagasModule from "./sagas";
import miniItemsSelector from "ui-kit/MiniItemsSelector";
import formModule from "ui-kit/Form";
import selectRequiredFieldsModalModule from "Modules/SelectRequiredFieldsModalRefactored";
import recordTypeModalModule from "ui-kit/RecordTypeModal";
import mediaContentModalModule from "ui-kit/MediaContentModal";
import communityHubSettingsModalModule from "EventLight/Common/Types/View/CommunityHubSettingsModal";
import addContentModalModule from "EventLight/Content/AddContentModal";
import addModalPageModule from "Portal/Settings/AddPageModal";

const module = createModule(model);

module.plugModule(miniItemsSelector);
module.plugModule(formModule);
module.plugModule(selectRequiredFieldsModalModule);
module.plugModule(recordTypeModalModule);
module.plugModule(mediaContentModalModule);
module.plugModule(communityHubSettingsModalModule);
module.plugModule(addContentModalModule);
module.plugModule(addModalPageModule);
module.setRootSaga(sagasModule);

export default module;
