import { CONTACT } from "./constants/order-line-item/standard-fields";

export default {
  mustBeChildField: false,
  canBeChildField: false,
  canBeParentField: true,
  canBeChildOfFieldTypes: [],
  mappableFields: [
    {
      id: CONTACT,
      name: "Owner",
      required: true,
      mappableFieldTypes: ["reference", "contact"]
    }
  ]
};
