import Helpers from "utils/Global/Helpers";

module.exports = {
  get: (credentials, { eventId, catalogItemId }) =>
    new Promise((resolve, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/inventory/event/${eventId}/catalog-items/${catalogItemId}`,
        credentials,
        qs: {
          eventId
        },
        success: result => resolve(result.payload),
        error
      });
    })
  /* eslint-enable no-underscore-dangle */
};
