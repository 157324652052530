import Immutable from "immutable";
import { RECEIVE_ITEM, REQUEST_ITEM, INVALIDATE_ITEM } from "./constants";
import { ERROR } from "redux/modules/errors/constants";
import { combineReducers } from "redux";

const zone = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_ITEM:
      return Immutable.fromJS(state)
        .setIn(["byId", action.payload.itemId], action.payload.item)
        .toJS();
    case INVALIDATE_ITEM:
      return Immutable.fromJS(state)
        .deleteIn(["byId", action.payload.itemId])
        .toJS();
    default:
      return state;
  }
};

const fetchingZone = (state = {}, action) => {
  switch (action.type) {
    case REQUEST_ITEM:
      return Immutable.fromJS(state)
        .setIn(["byId", action.payload.itemId], true)
        .toJS();
    case RECEIVE_ITEM:
      return Immutable.fromJS(state)
        .setIn(["byId", action.payload.itemId], false)
        .toJS();
    case ERROR:
      return {};
    default:
      return state;
  }
};

export default combineReducers({
  zone,
  fetchingZone
});
