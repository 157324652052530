import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import getCredentialPrice from "Orders/utils/get-credential-price";
import Counter from "../Counter";
import { Div } from "components/Base";

const Credential = ({ item, method }) => {
  const variant = item;

  const price = getCredentialPrice({
    price: variant.price,
    currency: variant.currency
  });
  return (
    <Div
      display="row.flex-start.center"
      bg="white"
      bra={1}
      mb={3}
      onClick={
        method === "radio" ? () => variant.updateVariantQuantity(1) : undefined
      }
      style={{
        boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.12)",
        padding: "10px 11px"
      }}
    >
      {method === "radio" ? (
        <Div
          pill
          b={3}
          bc={variant.quantity ? "primary7" : "gray5"}
          width={20}
          height={20}
          display="row.center.center"
          mr={3}
        >
          {variant.quantity ? (
            <Div pill width={10} height={10} bg="primary7" />
          ) : null}
        </Div>
      ) : (
        <div
          styleName="color"
          style={{ backgroundColor: variant.background_color }}
        />
      )}
      <div styleName="main">
        <div styleName="title" title={variant.name}>
          <span styleName="truncate">{variant.name}</span>
        </div>

        {variant.message ? (
          <div styleName="subTitle">
            <Div
              display="inline-row"
              fs={0}
              uppercase
              bg="neutral1"
              p={1}
              bra={1}
              color="neutral7"
              mt={1}
            >
              {variant.message}
            </Div>
          </div>
        ) : null}
      </div>
      <div styleName="price">{price}</div>
      {method === "count" ? (
        <Counter
          {...{
            quantity: variant.quantity,
            incrementQuantity: variant.incrementQuantity,
            decrementQuantity: variant.decrementQuantity,
            updateVariantQuantity: variant.updateVariantQuantity
          }}
        />
      ) : null}
    </Div>
  );
};

Credential.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    background_color: PropTypes.string,
    variants: PropTypes.arrayOf(
      PropTypes.shape({
        quantity: PropTypes.number,
        inventory_quantity: PropTypes.number,
        incrementQuantity: PropTypes.func,
        decrementQuantity: PropTypes.func,
        updateVariantQuantity: PropTypes.func,
        price: PropTypes.number,
        currency: PropTypes.string
      })
    )
  }).isRequired
};

export default CSSModules(Credential, css);
