import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import FormSendModal from "./FormSendModal";

import { sendInvite } from "redux/modules/formsV2/invites/actions";

import { showModal, hideModal } from "redux/modules/modal/actions";

import { user } from "redux/modules/user/selectors";
import { eventDetails } from "redux/modules/event/selectors";

function mapStateToProps(state) {
  return {
    user: user(state),
    eventDetails: eventDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      sendInvite,
      showModal,
      hideModal
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(FormSendModal);
