import React, { Component } from "react";

import { getValue } from "../../utils";

import { Div, CancelIcon } from "components/Base";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";

import { IFrame } from "SendEmailModal/View/Scenes/Review/BuildIframe";

import { noop } from "utils/General";

const IframePopup = ({ closeModal = noop, content }) => (
  <MiniModalWrapper
    hideModal={closeModal}
    isPortal={false}
    showModal={true}
    showHeader={false}
    zIndex={300}
    style={{
      width: "90%",
      backgroundColor: "white",
      height: "90vh"
    }}
    bg="neutral0"
  >
    <div
      onClick={closeModal}
      style={{
        backgroundColor: "white",
        position: "absolute",
        top: "3vh",
        height: "36px",
        width: "36px",
        right: "4%",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
      }}
    >
      <CancelIcon color="neutral8" sizeWFS={12} />
    </div>
    <Div
      bg="white"
      width={1}
      height={1}
      display="column.flex-start.stretch"
      p={4}
      bt={1}
      bc="neutral2"
      style={{
        overflowY: "auto"
      }}
    >
      <IFrame
        style={{
          width: "100%",
          height: "100%",
          border: 0
        }}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: content
          }}
        />
      </IFrame>
    </Div>
  </MiniModalWrapper>
);

class IframeOverlay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    };
  }

  render() {
    const label = getValue(this.props, "label");
    const content = getValue(this.props, "content");

    return (
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%"
        }}
      >
        <div
          title={label}
          className="underline-on-hover"
          style={{
            fontSize: "16px",
            fontWeight: 600,
            color: "rgb(130, 61, 209)",
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: "pointer"
          }}
          onClick={() =>
            this.setState({
              showModal: true
            })
          }
        >
          {label}
        </div>
        {this.state.showModal ? (
          <IframePopup
            content={content}
            closeModal={() => this.setState({ showModal: false })}
          />
        ) : null}
      </div>
    );
  }
}

export default IframeOverlay;
