import { put, takeEvery, select } from "redux-saga/effects";
import { actions } from "./model";
import { actions as TableActions } from "ui-kit/Table/model";

import { getSummaryValues, getAllAssignmentsRows } from "./selectors";
import {
  INSTANCE_SUMMARY_T,
  SUMMARY_FIELDS,
  SUMMARY_FIELDS_WIDTHS,
  ALL_FIELDS,
  ALL_FIELDS_WIDTHS,
  INSTANCE_ALL_ITEMS_T
} from "./constants";

const init = function*() {
  yield put(actions.setLoading(true));
  const rows = yield select(getSummaryValues);
  const allFieldsRows = yield select(getAllAssignmentsRows);

  yield put(
    TableActions.setData(
      {
        columns: SUMMARY_FIELDS,
        rows: rows,
        columnWidths: SUMMARY_FIELDS_WIDTHS,
        canEditCells: false
      },
      {
        meta: {
          instanceId: INSTANCE_SUMMARY_T
        }
      }
    )
  );

  yield put(
    TableActions.setData(
      {
        columns: ALL_FIELDS,
        rows: allFieldsRows,
        columnWidths: ALL_FIELDS_WIDTHS,
        canEditCells: false
      },
      {
        meta: {
          instanceId: INSTANCE_ALL_ITEMS_T
        }
      }
    )
  );

  yield put(actions.setLoading(false));
};

const rootSaga = function*() {
  yield takeEvery(actions.init.type, init);
};

export default rootSaga;
