import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import * as R from "ramda";
import update from "immutability-helper";
import moment from "moment";

import { debounce } from "utils/General/sagas";

import { actions, getters } from "EventLight/Agenda/Manage";
import {
  actions as TableActions,
  getters as TableGetters
} from "ui-kit/Table/model";
import * as snackbarActions from "redux/modules/snackbar/actions";

import { actions as ViewPickerActions } from "ui-kit/ViewPicker";

import { actions as SearchBarActions } from "ui-kit/SearchBar";

import { getCredentials } from "redux/modules/user/selectors";
import { TABLE_INSTANCE_ID } from "EventLight/Agenda/Manage/constants";
import { formatValue } from "EventLight/Agenda/Manage/selectors";

import { COMMIT } from "redux-optimist";

import Api from "../api";

const resizeColumns = function*({ meta }) {
  const widths = yield select(TableGetters.columnWidths, {
    instanceId: meta.instanceId
  });
  const preferences = yield select(getters.preferences);
  const updatedFieldWidths = {
    ...preferences.field_widths,
    ...widths
  };

  yield put(actions.updateFieldWidths(updatedFieldWidths));
};

const watchResizeColumns = debounce(
  action =>
    action.type === TableActions.saveColumnWidth.type &&
    R.path(["meta", "instanceId"], action) === TABLE_INSTANCE_ID,
  resizeColumns,
  500
);

const untoggleRows = function*() {
  yield put(
    TableActions.clearSelectedRows(null, {
      meta: {
        instanceId: TABLE_INSTANCE_ID
      }
    })
  );
};

const watchUntoggleRows = function*() {
  yield takeEvery(
    [
      actions.setSelectedFieldFilters.type,
      actions.clearFilters.type,
      actions.removeSelectedFieldFilter.type,
      actions.setSelectedTab.type,
      ViewPickerActions.revertViewChanges.type,
      ViewPickerActions.deleteView.type,
      ViewPickerActions.selectView.type,
      ViewPickerActions.addView.type,
      SearchBarActions.clearSearch.type
    ],
    untoggleRows
  );
};

const hideColumn = function*({ payload: field }) {
  const state = yield select(R.identity);
  const preferences = getters.preferences(state);
  const columns = getters.columns(state);

  yield all([
    put(
      TableActions.setColumns(columns.filter(c => c.id !== field.id), {
        meta: {
          instanceId: TABLE_INSTANCE_ID
        }
      })
    ),
    put(actions.setColumns(columns.filter(c => c.id !== field.id))),
    put(
      actions.setPreferences({
        ...preferences,
        visible_fields: preferences.visible_fields.filter(
          fId => fId !== field.id
        )
      })
    )
  ]);
};

const groupBy = function*({ payload: field }) {
  yield put(actions.setGroupedByField(field.id));
};

const insertField = function*({
  payload: { originalField, newField, position }
}) {
  const state = yield select(R.identity);
  const columns = getters.columns(state);
  const index = R.findIndex(R.propEq("id", originalField.id))(columns);

  const updatedFields = update(columns, {
    $splice: [
      [Math.max(0, position === "left" ? index - 1 : index + 1), 0, newField]
    ]
  });

  yield put(
    actions.updateVisibleFields({
      visibleFields: updatedFields.map(f => f.id),
      fieldOrder: updatedFields.reduce((map, f, idx) => {
        map[f.id] = idx;
        return map;
      }, {})
    })
  );

  yield all([
    put(
      snackbarActions.showSnackbar({
        message: "Field Added",
        action: "OK"
      })
    ),
    put(actions.fetchData())
  ]);
};

const saveCell = function*({ meta, optimist, payload }) {
  if (meta.instanceId === TABLE_INSTANCE_ID) {
    const {
      column: { id }
    } = payload;
    if ((id === "starts_at" || id === "ends_at") && payload.value) {
      const timezone = R.path(["column", "settings", "timezone"], payload);
      const {
        row: { starts_at, ends_at },
        value
      } = payload;

      if (id === "starts_at") {
        const start = moment(value.iso);
        const end = ends_at.value
          ? moment(ends_at.value.iso)
          : start.clone().subtract(1, "hours");

        if (start.isAfter(end)) {
          const nextEnd = start
            .clone()
            .add(1, "hours")
            .tz(timezone)
            .utc()
            .format();

          yield put(
            TableActions.saveValue(
              {
                value: {
                  iso: nextEnd,
                  formatted: start
                    .clone()
                    .add(1, "hours")
                    .tz(timezone)
                    .toDate()
                },
                column: { id: "ends_at" },
                row: payload.row
              },
              { meta }
            )
          );
        }
      } else {
        const end = moment(value.iso);
        const start = starts_at.value
          ? moment(starts_at.value.iso)
          : end.clone().add(1, "hours");

        if (end.isBefore(start)) {
          const nextStart = end
            .clone()
            .subtract(1, "hours")
            .tz(timezone)
            .utc()
            .format();

          yield put(
            TableActions.saveValue(
              {
                value: {
                  iso: nextStart,
                  formatted: start
                    .clone()
                    .subtract(1, "hours")
                    .tz(timezone)
                    .toDate()
                },
                column: { id: "starts_at" },
                row: payload.row
              },
              { meta }
            )
          );
        }
      }
    }

    const credentials = yield select(getCredentials);
    let fieldId = payload.column.id;

    const toSave = {
      sessionId: payload.row.id,
      ...formatValue(fieldId, payload.value)
    };

    yield call(Api.updateSession, {
      credentials,
      data: toSave
    });

    yield put(
      TableActions.saveResponse(null, {
        optimist: { ...optimist, type: COMMIT },
        meta
      })
    );
  }
};

const watchHideColumn = function*() {
  yield takeEvery(actions.hideColumn.type, hideColumn);
};

const watchSaveCell = function*() {
  yield takeEvery(TableActions.save.type, saveCell);
};

const watchGroupBy = function*() {
  yield takeEvery(actions.groupBy.type, groupBy);
};

const watchInsertField = function*() {
  yield takeEvery(actions.insertField.type, insertField);
};

const rootSaga = function*() {
  yield all([
    fork(watchUntoggleRows),
    fork(watchResizeColumns),
    fork(watchHideColumn),
    fork(watchGroupBy),
    fork(watchInsertField),
    fork(watchSaveCell)
  ]);
};

export default rootSaga;
