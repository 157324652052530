import React from "react";

import { createContext } from "redux-mvc";

import * as R from "ramda";

import module, { actions } from "../index";

import Layout from "./Layout";

import rootSaga from "../sagas";

import AddSubmissionAsModal from "components/Event/FormsV2/Modals/AddSubmissionAsModal";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";

module.setRootSaga(rootSaga);

const decorate = createContext({
  module: module,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(actions.init());
    }
  },
  handlers: {
    goToSubmitForm({ baseModuleId, formId, userId, slug, scope }) {
      this.props.showModal({
        content: (
          <AddSubmissionAsModal
            scope={scope}
            onDone={accountId => {
              this.store.dispatch(
                actions.addSubmission({
                  baseModuleId,
                  formId,
                  userId,
                  accountId,
                  slug
                })
              );
              this.props.hideModal();
            }}
            hideModal={this.props.hideModal}
          />
        ),
        wrapper: ModalWrapper
      });
    }
  },
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: [
      "user",
      "event",
      "modal",
      "organization",
      "permissions",
      "userAccessLevels",
      "@flopflip"
    ]
  }
});

export default decorate(Layout);
