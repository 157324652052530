import React from "react";
import { get } from "lodash";
import Icon from "./Icon";
import PillButton from "components/Global/CRM/PillButton";
import { Div } from "components/Base";
import { Link } from "react-router";

const getPath = ({ orgId, eventId, accountId }) => {
  if (orgId) {
    return `/organization/${orgId}/account/${accountId}`;
  }
  return `/event/${eventId}/account/${accountId}`;
};

const AccountNameFormatter = ({ value, row, column, dependentValues }) => {
  const path = getPath({
    accountId: row.account_id || row.id,
    orgId: get(dependentValues, "meta.orgDetails").id,
    eventId: get(dependentValues, "meta.eventDetails").id
  });

  return (
    <Link to={path}>
      <Div
        display="row.flex-start.center"
        width={1}
        fw={3}
        height={45}
        style={{
          position: "relative",
          fontSize: 15,
          width: column.width || 400
        }}
      >
        <Icon type={row.type} />
        <Div color="purple8">{get(value, "value")}</Div>

        {/*
      <Div
        style={{
          display: "none",
          position: "absolute",
          top: 10,
          right: 0
        }}
      >
        <PillButton title="View Group" linkTo={path} />
      </Div>
      */}
      </Div>
    </Link>
  );
};

export default AccountNameFormatter;
