import * as STANDARD_MODULE_FIELDS from "utils/standard-module-field-ids";

export const NAMESPACE = "FormWizardModal";

export const PAGES = {
  SELECT_METHOD: "selectMethod",
  INVENTORY: "inventory",
  PASSES: "passes",
  MEALS: "meals",
  NEW_INVENTORY: "newInventory",
  CUSTOM: "custom",
  APPLICATIONS: "applications",
  SCHEDULES: "schedules"
};

export const FORMS = {
  INVENTORY: "inventory",
  PASSES: "passes",
  CUSTOM: "custom",
  APPLICATIONS: "applications"
};

export const INVENTORY_PAGES = {
  INVENTORY_CATEGORIES: "inventoryCategories",
  SELECT_FIELDS: "selectFields",
  SELECT_METHOD: "selectMethod",
  REVIEW: "review"
};

export const PASSES_PAGES = {
  SELECT_TYPE: "selectType",
  SELECT_PASSES: "selectPasses",
  SELECT_FIELDS: "selectFields",
  REVIEW: "review",
  SELECT_ACCOUNTS: "selectAccounts"
};

export const COLLECT_PASSES = {
  MEAL: "meal",
  NOT_MEAL: "notMeal"
};

export const PASSES_TYPES = {
  INDIVIDUAL: "individual",
  COMPANY_GROUP: "group",
  GROUP_FORM: "group",
  GROUP_PORTAL: "groupPortal"
};

export const CUSTOM_PAGES = {
  SELECT_FORM: "selectForm",
  SELECT_QUESTIONS: "selectQuestions",
  REVIEW: "review"
};

export const SCHEDULES_PAGES = {
  SELECT_FORM: "selectForm",
  SELECT_QUESTIONS: "selectQuestions",
  REVIEW: "review"
};

export const APPLICATIONS_PAGES = {
  SELECT_METHOD: "selectMethod",
  SELECT_ACCOUNTS: "selectAccounts",
  SELECT_FIELDS: "selectFields",
  SELECT_PASSES: "selectPasses",
  REVIEW: "review"
};

export const MODULE_FIELDS_TO_IGNORE = [
  STANDARD_MODULE_FIELDS.MODULE.SUBMITTER_ACCOUNT_FIELD,
  STANDARD_MODULE_FIELDS.MODULE.SUBMITTER_CONTACT_FIELD
];

export const LOCKED_APPLICATION_ACCOUNT_FIELDS = [
  STANDARD_MODULE_FIELDS.ACCOUNTS.NAME
];

export const VISIBLE_APPLICATION_ACCOUNT_FIELDS = [
  STANDARD_MODULE_FIELDS.ACCOUNTS.NAME,
  STANDARD_MODULE_FIELDS.ACCOUNTS.ADDRESS_LINE_1,
  STANDARD_MODULE_FIELDS.ACCOUNTS.ADDRESS_LINE_2,
  STANDARD_MODULE_FIELDS.ACCOUNTS.ADDRESS_CITY,
  STANDARD_MODULE_FIELDS.ACCOUNTS.ADDRESS_STATE,
  STANDARD_MODULE_FIELDS.ACCOUNTS.ADDRESS_ZIP,
  STANDARD_MODULE_FIELDS.ACCOUNTS.ADDRESS_COUNTRY
];

export const LOCKED_APPLICATION_CONTACT_FIELDS = [
  STANDARD_MODULE_FIELDS.CONTACTS.FIRST_NAME,
  STANDARD_MODULE_FIELDS.CONTACTS.LAST_NAME,
  STANDARD_MODULE_FIELDS.CONTACTS.EMAIL
];

export const VISIBLE_APPLICATION_CONTACT_FIELDS = [
  STANDARD_MODULE_FIELDS.CONTACTS.FIRST_NAME,
  STANDARD_MODULE_FIELDS.CONTACTS.LAST_NAME,
  STANDARD_MODULE_FIELDS.CONTACTS.EMAIL,
  STANDARD_MODULE_FIELDS.CONTACTS.MOBILE_PHONE
];

export const SELECT_FORM_STEPS = ["Step 1", "Step 2"];
