import * as R from "ramda";
import { getters } from "Portal/PortalPeople/PersonModal/model";
import { createSelector } from "reselect";

import { eventDetails as eventEventDetails } from "redux/modules/event/selectors";
import { eventDetails as portalEventDetails } from "redux/modules/portal/selectors";

import isFormLocked from "components/Event/FormsV2/Utils/is-form-locked";

export const eventDetailsToPass = (state, props) => {
  return props?.routes?.find(r =>
    ["PortalV2_LoggedInView", "PortalV2_LoggedOutView"].includes(r.name)
  )
    ? portalEventDetails(state)
    : eventEventDetails(state);
};

export const isReadOnly = createSelector(
  getters.accountPermissionSet,
  props => props,
  (accountPermissionSet, props) =>
    !props.isViewingAsAdmin && isFormLocked(accountPermissionSet)
);

export const getSelectedFields = createSelector(
  getters.contactPermissionSet,
  R.propOr([], "fields")
);

export const getRequestableItems = createSelector(
  getters.itemBlocks,
  isReadOnly,
  (itemBlocks, isReadOnly) =>
    !isReadOnly && itemBlocks && itemBlocks.length
      ? itemBlocks.map(block => ({
          ...block,
          title: block.name,
          description: block.description,
          status: block.status,
          type: "credentials",
          required: R.propOr(false, "required", block)
        }))
      : []
);

export const getMessageError = createSelector(getters.itemBlocks, itemBlocks =>
  R.compose(
    R.addIndex(R.reduce)((acc, block, idx) => {
      if (idx === 0) {
        return `${acc}You must make a selection from ${block.name}`;
      } else {
        return `${acc}${itemBlocks.length - 1 === idx ? " and" : ","} ${
          block.name
        }`;
      }
    }, ""),
    R.filter(({ status, required }) => status === "not_started" && required)
  )(itemBlocks)
);

export const getCanCloseModal = createSelector(
  getRequestableItems,
  requestableItems =>
    R.isEmpty(requestableItems) ||
    R.all(
      ({ status, required }) =>
        required !== true || (required === true && status !== "not_started"),
      requestableItems
    )
);
