import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import TableModal from "./TableModal";
import PeopleTypePanel from "Accounts/PeopleTypePanel/View";

import SearchBar from "ui-kit/SearchBar/View";
import LoadingOverlay from "ui-kit/LoadingOverlay";
import { Div, AddPersonIcon, BigFilledButton } from "components/Base";

import {
  getFilters,
  getFilteredContactsGroups,
  getCountOfEnabledGroups
} from "Accounts/PeoplePanels/selectors";

import { getters } from "Accounts/PeoplePanels";
import { VARIANTS } from "ui-kit/Theme/constants";

const decorate = connect((state, props) => ({
  groups: getFilteredContactsGroups(state, props),
  countOfEnabledGroups: getCountOfEnabledGroups(state, props),
  filters: getFilters(state, props),
  loading: getters.loading(state, props)
}));

const PeoplePanels = ({
  groups = [],
  isAdminView = true,
  handlers = {},
  filters = [],
  showAdd = false,
  loading = false,
  countOfEnabledGroups
}) => (
  <Div
    display="column.flex-start.stretch"
    mb={4}
    style={{ minHeight: 200, position: "relative" }}
  >
    <Div display="row.flex-start.center" mb={4}>
      <SearchBar
        pill
        filters={filters}
        variant={isAdminView ? VARIANTS.SURFACE : VARIANTS.BLUE}
        flex={1}
      />
      {showAdd && countOfEnabledGroups ? (
        <BigFilledButton
          LeftIcon={AddPersonIcon}
          bg={isAdminView ? "purple6" : "indigo7"}
          color="white"
          ml={3}
          onClick={handlers.addPerson}
          pill
        >
          Add
        </BigFilledButton>
      ) : null}
    </Div>
    {R.map(
      group => (
        <PeopleTypePanel
          key={group.id}
          group={group}
          instanceId={group.id}
          handlers={handlers}
          isAdminView={isAdminView}
          showAdd={group.is_enabled && showAdd ? true : false}
        />
      ),
      groups
    )}
    {loading && <LoadingOverlay />}
    <TableModal handlers={handlers} />
  </Div>
);

export default decorate(PeoplePanels);
