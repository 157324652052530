import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import RequestsTable from "components/Event/FormsV2/Requests/Table/RequestsTable";
import FormApprovalRowRenderer from "components/Global/Table3/RowRenderers/FormV2ApprovalRow";

class Requests extends Component {
  @autobind
  rowGetter(rows, rowIdx) {
    return rows[rowIdx];
  }

  render() {
    const { rows, fields, height, saveCell } = this.props;

    return (
      <div styleName="tableContainer">
        <div ref={c => (this.scrollParent = c)} styleName="scrollContainer">
          <RequestsTable
            columns={fields}
            height={height}
            rows={rows}
            contextMenus={this.props.contextMenus}
            onColumnResize={this.props.onColumnResize}
            onRowSelect={this.props.onRowSelect}
            selectedRows={this.props.selectedRows}
            onRowUpdated={saveCell}
            onRowSort={this.props.onRowSort}
            rowGetter={this.rowGetter.bind(null, rows)}
            rowOrder={this.props.rowOrder}
            rowRenderer={FormApprovalRowRenderer}
          />
        </div>
      </div>
    );
  }
}

Requests.propTypes = {
  contextMenus: PropTypes.object.isRequired,
  onRowSort: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  height: PropTypes.number,
  onColumnResize: PropTypes.func.isRequired,
  onRowSelect: PropTypes.func.isRequired,
  rowOrder: PropTypes.object,
  rows: PropTypes.array.isRequired,
  saveCell: PropTypes.func.isRequired,
  selectedRows: PropTypes.func.isRequired
};

export default Requests;
