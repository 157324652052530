import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import { NAMESPACE, TABS } from "./constants";

const iniState = {
  loading: true,
  preferences: {
    field_widths: {}
  },
  data: {
    submissions: []
  },
  activeTab: TABS.ALL,
  filter: "",
  addLoading: false,
  submissionId: "",
  viewMore: false,
  doneSubmitting: false
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    goToForms: R.identity,
    setSubmissionsFilter: R.identity,
    setSubmissionsTabFilter: R.identity,
    downloadForm: R.identity,
    deleteSubmission: R.identity,
    addSubmission: R.identity,
    toggleDoneSubmitting: ({ doneSubmitting }) => ({
      doneSubmitting: R.not(doneSubmitting)
    }),
    toggleShowMore: ({ viewMore }) => ({
      viewMore: R.not(viewMore)
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
