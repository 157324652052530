import PropTypes from "prop-types";
import React from "react";
import Link from "components/Global/Module/Navigation/Link";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const LinkTree = ({ parent, active, links }) => (
  <div styleName="linkTree">
    {parent}
    {active ? <div styleName="links">{links.map(link => link)}</div> : null}
  </div>
);

LinkTree.propTypes = {
  parent: PropTypes.object,
  links: PropTypes.array
};

export default CSSModules(LinkTree, css);
