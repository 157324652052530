import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import _ from "lodash";

import Week from "components/Global/EventDaySelector/Calendar/Week";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class Calendar extends Component {
  render() {
    const { dates, handleDayClick, disabled } = this.props;
    return (
      <div styleName="container">
        <div styleName="dayLabels">
          {["M", "T", "W", "Th", "F", "Sa", "Su"].map(d => (
            <div key={d} styleName="dayLabel">
              {d}
            </div>
          ))}
        </div>
        <div styleName="weeks">
          {Object.keys(dates.weeks).map(key => (
            <Week
              key={key}
              disabled={disabled}
              days={dates.weeks[key]}
              availableDayKeys={dates.availableDayKeys}
              availableDayMap={dates.availableDayMap}
              handleDayClick={handleDayClick}
            />
          ))}
        </div>
      </div>
    );
  }
}

Calendar.propTypes = {
  dates: PropTypes.object.isRequired,
  handleDayClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default Calendar;
