import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import {
  Div,
  PopMenu,
  PopMenuCard,
  Text2,
  MediumFilledButton,
  BigFilledButton,
  BigOutlineButton,
  EditIcon,
  MoneyIcon,
  MediumClearInput,
  AddIcon,
  CloseIcon
} from "components/Base";
import { getters, actions } from "Orders/TicketsPasses";

const decorate = connect(
  state => ({
    pricesListTemp: getters.pricesListTemp(state)
  }),
  {
    resetPriceItem: actions.resetPriceItem,
    updateItemPricesList: actions.updateItemPricesList,
    updateSelectedPrice: actions.updateSelectedPrice,
    addPriceRow: actions.addPriceRow,
    removePriceRow: actions.removePriceRow
  }
);

const EditPricesPopOver = ({
  pricesListTemp,
  id,
  updateItemPricesList,
  updateSelectedPrice,
  resetPriceItem,
  addPriceRow,
  removePriceRow
}) => {
  const prices = R.compose(
    R.propOr([], "prices"),
    R.find(R.propEq("sort_order", id))
  )(pricesListTemp);
  return (
    <PopMenu
      Label={({ onClick }) => (
        <BigFilledButton
          LeftIcon={EditIcon}
          bg="altB5"
          onClick={() => {
            resetPriceItem({ id });
            onClick();
          }}
        >
          Edit Prices
        </BigFilledButton>
      )}
    >
      {({ closeMenu }) => (
        <PopMenuCard
          position="right"
          align="left"
          customStyle={{ top: "15px" }}
        >
          <Div
            width={360}
            bra={1}
            shadow={1}
            p={4}
            bg="white"
            style={{ overflow: "visible" }}
          >
            <Div width={1}>
              <Div>
                <Div display="row.space-between">
                  <Div width={130} mb={1}>
                    <Text2 color="neutral6" bold>
                      PRICE
                    </Text2>
                  </Div>
                  <Div flex={1}>
                    <Text2 color="neutral6" bold>
                      LABEL
                    </Text2>
                  </Div>
                </Div>
                <Div>
                  {R.map(
                    ({ tempId, name, amount }) => (
                      <Div display="row.flex-start.center" key={tempId}>
                        <Div width={130}>
                          <Div
                            bc="neutral2"
                            ba={1}
                            display="row"
                            flex={1}
                            height={40}
                          >
                            <Div
                              bg="neutral1"
                              display="column.center.center"
                              px={1}
                            >
                              <MoneyIcon color="neutral7" size={24} />
                            </Div>
                            <Div
                              flex={1}
                              height={40}
                              display="column.center.center"
                            >
                              <MediumClearInput
                                width={1}
                                placeholder="$price"
                                value={amount}
                                color="neutral7"
                                type="number"
                                onChange={value => {
                                  updateSelectedPrice({
                                    tempId,
                                    id,
                                    key: "amount",
                                    value
                                  });
                                }}
                                continuous
                              />
                            </Div>
                          </Div>
                        </Div>
                        <Div flex={1}>
                          <Div
                            bc="neutral2"
                            ba={1}
                            flex={1}
                            height={40}
                            bl={0}
                            display="column.center.center"
                          >
                            <MediumClearInput
                              width={1}
                              placeholder="enter the price label..."
                              value={name}
                              color="neutral7"
                              onChange={value => {
                                updateSelectedPrice({
                                  tempId,
                                  id,
                                  key: "name",
                                  value
                                });
                              }}
                              continuous
                            />
                          </Div>
                        </Div>
                        <Div>
                          <CloseIcon
                            size={20}
                            ml={1}
                            color={{ default: "neutral5", hover: "danger7" }}
                            transition="fast"
                            onClick={() => {
                              removePriceRow({ id, tempId });
                            }}
                          />
                        </Div>
                      </Div>
                    ),
                    prices
                  )}
                </Div>
                <Div display="row">
                  <MediumFilledButton
                    bg="neutral1"
                    mt={2}
                    onClick={() => {
                      addPriceRow({ id });
                    }}
                    ml={2}
                  >
                    <AddIcon color="black" />
                  </MediumFilledButton>
                </Div>
              </Div>
              <Div display="row" width={1} mt={2}>
                <BigOutlineButton mr={2} onClick={closeMenu}>
                  Cancel
                </BigOutlineButton>
                <BigFilledButton
                  bg="primary7"
                  flex={1}
                  disabled={R.any(
                    price => price.amount <= 0 || price.name.length < 2,
                    prices
                  )}
                  onClick={() => {
                    updateItemPricesList({ id });
                    closeMenu();
                  }}
                >
                  Save Changes
                </BigFilledButton>
              </Div>
            </Div>
          </Div>
        </PopMenuCard>
      )}
    </PopMenu>
  );
};

export default decorate(EditPricesPopOver);
