import React from "react";
import EditorBase from "./TableConnector";
import Editor from "components/Global/Editors/Reference";

import { getMeta, ep } from "ui-kit/Datagrid/utils";

export default class ReferenceEditor extends EditorBase {
  render() {
    const props = ep(this.props);
    const editorProps = {
      eventId: getMeta(props, ["eventDetails", "eventId"])
    };

    return (
      <div style={{ fontSize: "13px" }}>
        <Editor
          {...editorProps}
          open
          value={this.state}
          onChange={this.handleChange}
        />
      </div>
    );
  }
}
