export const VIEWS = {
  CREATE_PACKAGES: "createPackages",
  CREATE_ITEMS: "createItems",
  APPLICATION: "application"
};

export const PACKAGE_FIELDS = {
  NAME: "name",
  PRICE: "price",
  ADD_PRICE: "addPrice",
  DESCRIPTION: "description",
  CATEGORY: "category"
};

export const ITEM_FIELDS = {
  NAME: "name",
  DESCRIPTION: "description",
  CATEGORY: "group_id"
};

export const BOOTH_FIELDS = {
  NAME: "name",
  PRICE: PACKAGE_FIELDS.PRICE,
  ADD_PRICE: PACKAGE_FIELDS.ADD_PRICE,
  DESCRIPTION: PACKAGE_FIELDS.DESCRIPTION,
  LIMIT: "limit"
};

export const APPLICATION_FIELDS = {
  REQUIRE: "required",
  INCLUDE: "include"
};
