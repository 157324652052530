import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import Popover from "@lennd/material-ui/Popover";
import { Item as SidebarItem } from "../Sidebar";
import PrimaryButton from "components/Global-2016/PrimaryButton";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const defaultState = {
  open: false,
  name: "",
  error: "",
  anchorEl: null
};

@CSSModules(css)
export default class CreatePermission extends Component {
  constructor(props) {
    super(props);

    this.state = defaultState;
  }

  @autobind
  handleClick(event) {
    event.preventDefault();

    this.setState({
      open: true,
      anchorEl: event.currentTarget
    });
  }

  @autobind
  handleRequestClose() {
    this.setState(defaultState);
  }

  @autobind
  onNameChange(event) {
    this.setState({
      name: event.target.value
    });
  }

  @autobind
  onCreate(event) {
    event.preventDefault();
    if (!this.state.name) {
      return this.setState({
        error: "Please enter a name."
      });
    }
    this.props.onCreate(this.state.name);
    this.setState(defaultState);
  }

  render() {
    return (
      <Fragment>
        <SidebarItem onClick={this.handleClick}>
          <span styleName="createNewButton">+ Create new profile</span>
        </SidebarItem>
        <Popover
          open={this.state.open}
          onClose={this.handleRequestClose}
          anchorEl={this.state.anchorEl}
        >
          <form styleName="container">
            <label className={css.label}>
              Name profile as...
              <input
                maxLength="60"
                className={[css.input, "styled"].join(" ")}
                defaultValue={this.state.value}
                onChange={this.onNameChange}
                autoComplete="off"
                autoFocus="true"
                type="text"
                placeholder="ie: Volunteers or Sponsors"
                name="name"
              />
              <div className="error">{this.state.error}</div>
              <PrimaryButton onClick={this.onCreate}>Create</PrimaryButton>
            </label>
          </form>
        </Popover>
      </Fragment>
    );
  }
}

CreatePermission.propTypes = {
  onCreate: PropTypes.func.isRequired,
  styles: PropTypes.object
};
