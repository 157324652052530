import * as R from "ramda";
import React from "react";

import countries from "world-countries";

import { Dropdown } from "ui-kit/Form/View";

import { getValue, getField } from "../../utils";

const options = R.map(
  country => ({
    id: country.ccn3,
    label: country.name.common
  }),
  countries
);

const Countries = props => {
  const value = getValue(props, R.identity, "");
  const field = getField(props);

  return (
    <Dropdown
      iniValue={value}
      options={options}
      fieldId={field.id}
      instanceId={props.instanceId}
    />
  );
};

export default Countries;
