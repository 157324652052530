import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters, actions } from "./model";
import { getItemGroups, getAllItems } from "./selectors";
import { ZONES_MODAL_SELECTOR_INSTANCE } from "./constants";

import MiniItemsSelector from "ui-kit/MiniItemsSelector/View";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import Loading from "ui-kit/Loading";

const decorate = connect(
  (state, props) => ({
    loading: getters.loading(state, props),
    isOpen: getters.isOpen(state, props),
    itemGroups: getItemGroups(state, props),
    selectedItems: getters.defaultSelectedZones(state, props),
    allItems: getAllItems(state, props),
    itemId: getters.itemId(state, props)
  }),
  {
    hideModal: actions.closeModal
  }
);

const Layout = ({
  loading,
  isOpen,
  hideModal = () => {},
  onSave = () => {},
  itemGroups,
  selectedItems,
  allItems,
  itemId
}) => {
  const onDone = selections => {
    const selectedIds = R.keys(selections);
    const selectedZones = R.map(id => R.prop(id, allItems), selectedIds);
    onSave({ itemId, selectedZones });
    hideModal();
  };
  return (
    <MiniModalWrapper
      showModal={isOpen}
      hideModal={hideModal}
      title="Edit Assigned Zones"
      bg="white"
    >
      {loading ? (
        <Loading />
      ) : (
        <MiniItemsSelector
          instanceId={ZONES_MODAL_SELECTOR_INSTANCE}
          iniClearSearch
          itemGroups={itemGroups}
          iniSelected={selectedItems}
          onCancel={hideModal}
          onDone={onDone}
        />
      )}
    </MiniModalWrapper>
  );
};

export default decorate(Layout);
