import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Formatter from "./Formatter";

import { user } from "redux/modules/user/selectors";
import {
  createReview,
  removeReview
} from "redux/modules/formsV2/submission/approvals/actions";

function mapStateToProps(state, props) {
  return {
    user: user(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      createReview,
      removeReview
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Formatter);
