import PropTypes from "prop-types";
import React from "react";
import { get, sumBy } from "lodash";
import LinkTree from "components/Global/Module/Navigation/LinkTree";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { DOCUMENT_REQUEST_TYPES } from "utils/standard-module-field-ids";
import AddFileModal from "EventLight/FileRequests/AddFileModal/View";
import ShareModal from "components/Global/CRM/Modals/Share";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import ActionText from "components/Global/Module/Sidebar/ActionText";
import SidebarBase from "../Base";
import Container from "components/Global/Module/Sidebar/Container";
import Title from "components/Global/Module/Sidebar/Title";
import ListItem from "components/Global/Module/Sidebar/ListItem";
import { EditIcon, ShareIcon } from "components/Global/Module/Navigation/Icons";
import Separator from "components/Global/Module/Navigation/Separator";

const { NAME } = DOCUMENT_REQUEST_TYPES;

class DocumentsNav extends SidebarBase {
  goToDocuments = () => {
    this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/module/${STANDARD_MODULE_IDS.files.id}`
    });
  };

  goToRequests = () => {
    this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/module/${STANDARD_MODULE_IDS.documentRequests.id}/view/${this.props.params.viewId}`
    });
  };

  goToRequestsType = typeId => {
    this.props.router.push({
      pathname: `/event/${this.props.params.eventId}/module/${STANDARD_MODULE_IDS.documentRequests.id}/view/${this.props.params.viewId}`,
      query: {
        request_type_id: typeId
      }
    });
  };

  showEditRequestTypeModal = () =>
    this.props.showModal({
      content: (
        <AddFileModal
          requestId={this.getSelectedDocumentRequestTypeId()}
          onSave={() => {
            this.fetchDocumentRequestTypes();
          }}
        />
      ),
      wrapper: ModalWrapper
    });

  showShareRequestTypeModal = () => {
    const record = this.props.documentRequestTypes.find(
      t => t.id === this.getSelectedDocumentRequestTypeId()
    );

    this.props.showModal({
      content: (
        <ShareModal
          moduleId={STANDARD_MODULE_IDS.documentRequestTypes.id}
          recordId={record.id}
          onDone={this.fetchDocumentRequestTypes}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  fetchDocumentRequestTypes = () => {
    this.props.getRecords({
      moduleId: STANDARD_MODULE_IDS.documentRequestTypes.id,
      options: {
        eventId: this.props.params.eventId
      }
    });
  };

  getSelectedDocumentRequestTypeId = () =>
    get(this.props.location, "query.request_type_id");

  getDocumentRequestTypeName = type =>
    get(type, ["values", NAME, "value"], "Untitled");

  isOwnerOfRequestType = requestTypeId => {
    const requestType = this.props.documentRequestTypes.find(
      t => t.id === requestTypeId
    );
    return (
      requestType &&
      Boolean(
        requestType.owners.find(
          o => parseInt(o.id, 10) === parseInt(this.props.user.id, 10)
        )
      )
    );
  };

  componentDidMount = () => this.fetchDocumentRequestTypes();

  render() {
    const { documentRequestTypes } = this.props;
    const { params } = this.props;
    const documentRequestsIsActive =
      STANDARD_MODULE_IDS.documentRequests.id === params.moduleId;
    const selectedDocumentRequestTypeId = this.getSelectedDocumentRequestTypeId();

    return (
      <Container>
        <Title>File Requests</Title>
        <LinkTree
          active
          parent={
            <ListItem
              active={
                documentRequestsIsActive && !selectedDocumentRequestTypeId
              }
              onClick={this.goToRequests}
              badgeText={sumBy(documentRequestTypes, t => t.count_of_requests)}
            >
              All Requests
            </ListItem>
          }
          links={[
            ...documentRequestTypes
              .sort((a, b) =>
                this.getDocumentRequestTypeName(a)
                  .toLowerCase()
                  .localeCompare(
                    this.getDocumentRequestTypeName(b).toLowerCase()
                  )
              )
              .map(type => (
                <ListItem
                  key={type.id}
                  active={selectedDocumentRequestTypeId === type.id}
                  onClick={() => this.goToRequestsType(type.id)}
                  badgeText={type.count_of_requests}
                >
                  {this.getDocumentRequestTypeName(type)}
                </ListItem>
              ))
          ]}
        />
        {this.isOwnerOfRequestType(selectedDocumentRequestTypeId) ? (
          <div>
            <Separator style={{ opacity: 0.3 }} />
            <ActionText onClick={this.showEditRequestTypeModal}>
              <EditIcon />
              Edit Request
            </ActionText>
            <ActionText onClick={this.showShareRequestTypeModal}>
              <ShareIcon />
              Share request
            </ActionText>
          </div>
        ) : (
          ""
        )}
      </Container>
    );
  }
}

DocumentsNav.propTypes = {
  addValue: PropTypes.func.isRequired,
  eventId: PropTypes.string,
  documentRequestTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  ).isRequired,
  fetchModule: PropTypes.func,
  location: PropTypes.object,
  params: PropTypes.object,
  records: PropTypes.array,
  recordTypes: PropTypes.array,
  router: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  showModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};

export default DocumentsNav;
