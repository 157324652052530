import React from "react";
import { Modal, Div, Text5, Text2, CloseIcon } from "components/Base";
import { noop } from "utils/General";

const Header = ({ title = "", subtitle = "", hideModal = noop }) => (
  <Div
    style={{
      flexShrink: 0
    }}
    display="row.space-between.center"
    bg="purple8"
    px={8}
    py={4}
    brt={1}
  >
    <Div>
      {typeof title === "string" ? (
        <Text5 bold color="white">
          {title}
        </Text5>
      ) : (
        title
      )}
      {typeof subtitle === "string" && subtitle?.length ? (
        <Text2 bold color="white">
          {subtitle}
        </Text2>
      ) : (
        subtitle
      )}
    </Div>
    {hideModal !== noop && (
      <Div onClick={hideModal}>
        <CloseIcon color="white" sizeWFS={6} />
      </Div>
    )}
  </Div>
);

const MiniModalWrapper = ({
  children,
  showModal = false,
  hideModal = noop,
  title = "",
  subtitle = "",
  isPortal = true,
  style = {},
  showHeader = true,
  stopPropagation = false,
  zIndex = 100,
  ...styleProps
}) => (
  <Modal
    isPortal={isPortal}
    background="translucent"
    isModalShowing={showModal}
    zIndex={zIndex}
  >
    <Div display="column.center.center" width={1}>
      <Div
        width={600}
        height={700}
        bra={1}
        style={{ height: "700px", overflow: "hidden", ...style }}
        display="column.flex-start.stretch"
        {...styleProps}
        onMouseDown={e => {
          if (stopPropagation) {
            e.stopPropagation();
          }
        }}
      >
        {showHeader && (
          <Header hideModal={hideModal} title={title} subtitle={subtitle} />
        )}
        {children}
      </Div>
    </Div>
  </Modal>
);

export default MiniModalWrapper;
