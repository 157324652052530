import { get, find } from "lodash";

export const isFetching = state => state.formsV2.form.fetching;
export const form = state => state.formsV2.form.form;
export const fields = state => state.formsV2.form.form.fields || [];
export const sortedFormFields = state =>
  state.formsV2.form.form.fields
    ? state.formsV2.form.form.fields.sort((a, b) => a.order - b.order)
    : [];
export const preferences = state =>
  get(state.formsV2.form, "form.preferences", {});
export const eventDetails = state => get(state.formsV2.form, "form.event");
export const error = state => state.formsV2.form.error;
export const subformSubmission = (state, subformId, submissionId) =>
  get(
    find(state.formsV2.form.form.fields, { id: subformId }),
    `subform.form.submissions[${submissionId}]`,
    {}
  );
