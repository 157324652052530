import * as R from "ramda";
import React, { useMemo } from "react";
import { connect } from "react-redux";

import { dateFormatter, getSelectedDays } from "utils/EventDays";

import { getValue } from "../../utils";

import { eventDetails } from "redux/modules/event/selectors";

const decorate = connect((state, props) => ({
  event: eventDetails(state, props)
}));

const EventDays = props => {
  const eventDays = getValue(props, R.identity, "");
  const dayGroups = R.path(["event", "dayGroups"], props);

  const days = useMemo(() => getSelectedDays({ eventDays }), [eventDays]);

  const formattedDates = useMemo(() => dateFormatter(days, dayGroups), [
    days,
    dayGroups
  ]);
  const countOfDates = formattedDates.length;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        userSelect: "none",
        overflow: "hidden"
      }}
    >
      {R.map(
        ({ label, color }, idx) => (
          <div
            key={`${label}_${color}`}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 20,
              flexShrink: 0,
              marginRight: countOfDates - 1 === idx ? 0 : 4,
              fontSize: 12,
              borderRadius: 2,
              color: "#000",
              padding: "0 5px",
              backgroundColor: `rgba(${color}, 0.15)`
            }}
          >
            {label}
          </div>
        ),
        formattedDates
      )}
    </div>
  );
};

export default decorate(EventDays);
