import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import {
  Div,
  BigFilledInput,
  BigFilledButton,
  LoadingIcon,
  Text1
} from "components/Base";
import Table, { TableWrapper } from "ui-kit/Table/View";
import { CART_TABS } from "Portal/PortalHome/constants";
import { getters, actions } from "Portal/PortalHome/model";
import { getRowActions } from "Portal/PortalHome/selectors";

const decorate = connect(
  state => ({
    activeSubmissionTab: getters.activeSubmissionTab(state),
    submissionsFilter: getters.submissionsFilter(state),
    addLoading: getters.addLoading(state),
    rowActions: getRowActions(state)
  }),
  {
    setSubmissionsTabFilter: actions.setSubmissionsTabFilter,
    setSubmissionsFilterData: actions.setSubmissionsFilterData,
    addSubmission: actions.addSubmission
  }
);

const SubmissionsTable = ({
  id,
  slug,
  baseModuleId,
  count,
  countOfDrafts,
  countOfSubmitted,
  isOpen,
  activeSubmissionTab,
  setSubmissionsTabFilter,
  setSubmissionsFilterData,
  submissionsFilter,
  addSubmission,
  rowActions,
  addLoading,
  blockSubmit
}) => {
  return (
    <Div width={1} mt={2}>
      <Div width={1} display="row.flex-start.center" height={30} px={4}>
        <Div
          color={
            R.propOr(CART_TABS.ALL, id, activeSubmissionTab) === CART_TABS.ALL
              ? "indigo7"
              : "black"
          }
          fw={3}
          fs={3}
          onClick={() =>
            setSubmissionsTabFilter({
              activeSubmissionTab: CART_TABS.ALL,
              instanceId: id
            })
          }
          height={1}
          display="row.flex-start.center"
          bb={2}
          bc={
            R.propOr(CART_TABS.ALL, id, activeSubmissionTab) === CART_TABS.ALL
              ? "indigo7"
              : "transparent"
          }
          px={1}
        >
          All
          <Div bg="neutral2" pill pr={2} pl={2} ml={2}>
            <Text1 bold>{count}</Text1>
          </Div>
        </Div>
        <Div
          color={
            R.propOr(CART_TABS.ALL, id, activeSubmissionTab) ===
            CART_TABS.SUBMITTED
              ? "indigo7"
              : "black"
          }
          fw={3}
          fs={3}
          ml={4}
          onClick={() =>
            setSubmissionsTabFilter({
              activeSubmissionTab: CART_TABS.SUBMITTED,
              instanceId: id
            })
          }
          height={1}
          display="row.flex-start.center"
          bb={2}
          bc={
            R.propOr(CART_TABS.ALL, id, activeSubmissionTab) ===
            CART_TABS.SUBMITTED
              ? "indigo7"
              : "transparent"
          }
          px={1}
        >
          Submitted
          <Div bg="neutral2" pill pr={2} pl={2} ml={2}>
            <Text1 bold>{countOfSubmitted}</Text1>
          </Div>
        </Div>
        <Div
          color={
            R.propOr(CART_TABS.ALL, id, activeSubmissionTab) === CART_TABS.DRAFT
              ? "indigo7"
              : "black"
          }
          fw={3}
          fs={3}
          ml={4}
          onClick={() =>
            setSubmissionsTabFilter({
              activeSubmissionTab: CART_TABS.DRAFT,
              instanceId: id
            })
          }
          height={1}
          display="row.flex-start.center"
          bb={2}
          bc={
            R.propOr(CART_TABS.ALL, id, activeSubmissionTab) === CART_TABS.DRAFT
              ? "indigo7"
              : "transparent"
          }
          px={1}
        >
          Drafts
          <Div bg="neutral2" pill pr={2} pl={2} ml={2}>
            <Text1 bold>{countOfDrafts}</Text1>
          </Div>
        </Div>
      </Div>
      <Div display="row.flex-start.center" mt={2} mb={2}>
        <BigFilledInput
          flex={1}
          continuous
          bg="neutral1"
          style={{ borderRadius: 20 }}
          placeholder="Search requests..."
          value={R.propOr("", id, submissionsFilter)}
          onChange={value =>
            setSubmissionsFilterData({ value, instanceId: id })
          }
          color="neutral5"
        />
        {isOpen && !blockSubmit ? (
          <BigFilledButton
            style={{ borderRadius: 20 }}
            bg="indigo7"
            width={92}
            ml={2}
            onClick={() => addSubmission({ id, slug, baseModuleId })}
            disabled={addLoading[id]}
          >
            {!addLoading[id] ? "Add" : <LoadingIcon />}
          </BigFilledButton>
        ) : null}
      </Div>
      <TableWrapper instanceId={id} loading={false} style={{ flex: 1 }}>
        <Table
          showOpenRecord
          showCheckbox={false}
          rowActions={rowActions}
          columnActions={[]}
          canEditCells={false}
          instanceId={id}
        />
      </TableWrapper>
    </Div>
  );
};
export default decorate(SubmissionsTable);
