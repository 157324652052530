import React from "react";
import * as R from "ramda";

import Types from "./Scenes/Types";
import Items from "./Scenes/Items";

const View = ({
  loading,
  saving,
  hideModal,
  canSave,
  handleSave,
  selectAll,
  removeAll,
  activeTab,
  types,
  activeType,
  countOfSelectedVariants,
  onSearchChange,
  searchTerm,
  cateringMealsPerDay,
  eventDateGroups
}) =>
  R.prop(activeTab, {
    types: <Types {...{ loading, hideModal, canSave, handleSave, types }} />,
    items: (
      <Items
        {...{
          loading,
          saving,
          hideModal,
          canSave,
          handleSave,
          activeType,
          selectAll,
          removeAll,
          countOfSelectedVariants,
          onSearchChange,
          searchTerm,
          cateringMealsPerDay,
          eventDateGroups
        }}
      />
    )
  });

export default View;
