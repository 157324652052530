import PropTypes from "prop-types";
import React, { Component } from "react";
import { get } from "lodash";
import Checkbox from "material-ui/Checkbox";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import QuantityInput from "./QuantityInput";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Footer from "../Footer";
import { NONE } from "utils/system-field-values";
import { Div } from "components/Base";
import * as R from "ramda";

const styles = {
  label: {
    flex: 1,
    color: "#424242",
    fontSize: "16px",
    fontWeight: 600,
    display: "block",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "100%",
    marginTop: "3px"
  },
  item: {
    padding: "5px 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  }
};

@CSSModules(css)
class ItemEditorList extends Component {
  createIconStyle(background) {
    const style = {
      marginTop: "1.5px",
      marginRight: "8px",
      fill: background
    };
    if (background === "#FFFFFF") {
      return {
        ...style,
        stroke: "#ccc",
        strokeWidth: "1px"
      };
    }
    return style;
  }

  renderQuantity(options) {
    const radios = options.map(item => {
      const value = this.props.selectedItems.find(
        selected => selected.id === item.id
      );

      // calculate a limit based on item block level limit + item limit
      let inputLimit = item.limit;
      let limitOverride = item.limitOverride;

      if (inputLimit) {
        if (
          typeof limitOverride !== "undefined" &&
          limitOverride < inputLimit
        ) {
          inputLimit = limitOverride + (value ? value.quantity : 0);
        }
      } else if (typeof limitOverride !== "undefined") {
        inputLimit = limitOverride + (value ? value.quantity : 0);
      }

      return (
        <li key={item.id} style={styles.item}>
          <div
            onClick={e => e.stopPropagation()}
            className={css.checkboxWithQuantity}
          >
            <Checkbox
              style={{
                flex: 1,
                display: "flex"
              }}
              checked={value}
              value={value ? 0 : 1}
              onCheck={e =>
                !this.props.disabled
                  ? this.props.updateQuantity(item, e.target.value)
                  : undefined
              }
              label={item.value}
              labelStyle={styles.label}
              iconStyle={this.createIconStyle(item.color)}
              disabled={
                (((value && value.quantity === 0) || !value) &&
                  inputLimit === 0) ||
                this.props.disabled
              }
            />
          </div>
          <Div display="row.flex-start.center">
            <QuantityInput
              onChange={this.props.updateQuantity}
              item={item}
              quantity={value ? value.quantity : 0}
              max={inputLimit}
              disabled={this.props.disabled}
            />
            {value && item.limit ? (
              <Div color="gray5" ml={1} fs={2}>
                / {item.limit}
              </Div>
            ) : null}
            {item.price ? (
              <Div display="row.flex-start.center" ml={1}>
                (${item.price})
              </Div>
            ) : null}
          </Div>
        </li>
      );
    });

    return <ul styleName="itemList">{radios}</ul>;
  }

  renderMultiple(options) {
    const radios = options.map(item => {
      const checked =
        this.props.selectedItems.find(selected => selected.id === item.id) || 0;

      return (
        <div onClick={e => e.stopPropagation()}>
          <Checkbox
            key={item.id}
            style={{
              ...styles.item
            }}
            checked={checked}
            value={checked ? 0 : 1}
            onCheck={e =>
              !this.props.disabled
                ? this.props.updateQuantity(item, e.target.value)
                : undefined
            }
            label={item.value}
            labelStyle={styles.label}
            iconStyle={this.createIconStyle(item.color)}
            disabled={this.props.disabled}
          />
        </div>
      );
    });

    return <div styleName="itemList">{radios}</div>;
  }

  renderSingle(options) {
    const selectedId = get(this.props, "selectedItems[0].id", null);
    const selected = options.find(c => c.id === selectedId);
    const radios = options.map(item => (
      <RadioButton
        style={{
          ...styles.item
        }}
        value={item}
        label={item.value}
        labelStyle={styles.label}
        iconStyle={this.createIconStyle(item.color)}
        disabled={this.props.disabled}
      />
    ));

    return (
      <div onClick={e => e.stopPropagation()}>
        <RadioButtonGroup
          valueSelected={selected}
          onChange={(event, value) =>
            !this.props.disabled
              ? this.props.updateSingleItem(value)
              : undefined
          }
          className={css.itemList}
        >
          {radios}
        </RadioButtonGroup>
      </div>
    );
  }

  renderItems(type, options) {
    switch (type) {
      case "quantity":
        return this.renderQuantity(options);
      case "single":
        return this.renderSingle(options);
      case "multiple":
        return this.renderMultiple(options);
      default:
        return "";
    }
  }

  render() {
    const {
      customWidth,
      disabled,
      onClose,
      onSelectNone,
      options,
      limit,
      selectType,
      selectedItems
    } = this.props;

    const showQuantity = selectType === "quantity";

    let sumRequested;
    let remaining;
    let optionsToPass = options;

    if (limit) {
      sumRequested = R.compose(
        R.sum,
        R.map(R.prop("quantity"))
      )(this.props.selectedItems);

      remaining = limit - sumRequested;
      optionsToPass = options.map(o => ({
        ...o,
        limitOverride: remaining
      }));
    }

    return (
      <div
        styleName="itemSelectBox"
        style={{
          width: customWidth
        }}
        onClick={e => {
          e.stopPropagation();
          e.nativeEvent.stopImmediatePropagation();
        }}
      >
        {showQuantity ? (
          <div styleName="heading">
            <span>Types</span>
            <span>QTY per person</span>
          </div>
        ) : (
          ""
        )}
        {!options.length ? (
          <div styleName="empty">No available passes</div>
        ) : (
          this.renderItems(selectType, optionsToPass)
        )}
        <Footer
          onClose={onClose}
          onSelectNone={onSelectNone}
          isNone={selectedItems.some(c => c.id === NONE.id)}
          disabled={disabled}
          limit={limit}
          remaining={remaining}
        />
      </div>
    );
  }
}

ItemEditorList.propTypes = {
  updateQuantity: PropTypes.func.isRequired,
  updateSingleItem: PropTypes.func.isRequired,
  selectedItems: PropTypes.object.isRequired,
  onSelectNone: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  selectType: PropTypes.string.isRequired,
  customWidth: PropTypes.number
};

export default ItemEditorList;
