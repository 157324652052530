import React from "react";

const UnknownSubmission = () => (
  <div
    style={{
      lineHeight: "34px",
      color: "#2F2D2D",
      fontSize: 15
    }}
  >
    —
  </div>
);

export default UnknownSubmission;
