import PropTypes from "prop-types";
import React, { Component } from "react";
import Helpers from "utils/Global/Helpers";
import Tooltip from "@material-ui/core/Tooltip";

class TooltipComponent extends Component {
  render() {
    // disable tooltip for touch devices
    // prevents forced doubleclick issue when tooltip child has an onClick handler.
    if (Helpers.isTouchDevice()) {
      return <div>{this.props.children}</div>;
    }

    return (
      <Tooltip placement={this.props.placement} title={this.props.tooltip}>
        {this.props.children}
      </Tooltip>
    );
  }
}

TooltipComponent.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  placement: PropTypes.string,
  key: PropTypes.string
};

TooltipComponent.defaultProps = {
  key: "tip",
  placement: "bottom"
};

export default TooltipComponent;
