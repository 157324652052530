import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  showContentModal: false,
  loading: true,
  data: {
    tags: []
  }
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    save: R.identity,
    setInitialData: (_, { payload: { data = {} } }) => ({
      data: {
        ...data,
        tags: R.compose(
          R.map(t => ({
            value: t.id,
            label: t.name
          })),
          R.propOr([], "tags")
        )(data)
      },
      loading: false
    }),
    showContentModal: () => ({
      showContentModal: true,
      loading: true,
      data: {}
    }),
    closeContentModal: () => ({
      showContentModal: false
    }),
    updateField: (state, { payload: { field, value } }) => ({
      data: R.assoc(field, value, state.data)
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
