import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

// show or hide password text

@CSSModules(css)
class Password extends Component {
  state = { type: "password" };
  toggleType = () =>
    this.setState(state => {
      state.type = state.type === "password" ? "text" : "password";
      return state;
    });
  render() {
    const { disabled, ...props } = this.props;
    return (
      <div styleName="container">
        <input
          {...props}
          disabled={disabled}
          className={["jaco-hide", props.className].filter(c => c).join(" ")}
          type={this.state.type}
        />
        {!disabled && (
          <i
            className={`material-icons ${this.state.type}`}
            onClick={this.toggleType}
          >
            visibility
          </i>
        )}
      </div>
    );
  }
}

export default Password;
