import React from "react";
import * as R from "ramda";

import { connect } from "react-redux";
import { WithFormInstanceProvider } from "ui-kit/Form/View";
import { Div, BigFilledButton } from "components/Base";
import { Label, FloatingSaveBar } from "EventLight/Common/FieldLayout";

import {
  FORM_ID,
  FIELD_IDS,
  REGISTRATION_SOURCES,
  REGISTRATION_TYPES
} from "../constants";
import { actions, getters } from "../model";

import { Input, Dropdown, AdvancedWYSIWYG, Image } from "ui-kit/Form/View";

import SectionCard from "./SectionCard";
import Fields from "./Fields";
import Preview from "./Preview";

const decorate = R.compose(
  WithFormInstanceProvider(R.always(FORM_ID)),
  connect(
    state => ({
      error: getters.error(state),
      data: getters.data(state),
      templates: getters.templates(state),
      accountTypes: getters.accountTypes(state),
      contactTypes: getters.contactTypes(state)
    }),
    {
      save: actions.save
    }
  )
);

const Body = ({ data, save, templates, error, contactTypes, accountTypes }) => (
  <Div width={1}>
    <Preview />

    <SectionCard name="Details" showToggle={false}>
      <Div px={8} pt={3}>
        {/*
        <Div display="column" mb={5} width={1}>
          <Label tooltip="Select the template to use for your registration page">
            Registration Template
          </Label>
          <Dropdown
            fieldId={FIELD_IDS.TEMPLATE}
            width={"100%"}
            iniValue={R.prop(FIELD_IDS.TEMPLATE)(data)}
            options={templates}
            size="big"
            usePortal
          />
        </Div>
        */}

        {/*
        <Div display="column" mb={5} width={1}>
          <Label tooltip="Select the record type that registered individuals should be created as">
            Attendee Record Type
          </Label>
          <Dropdown
            fieldId={FIELD_IDS.CONTACT_RECORD_TYPE_ID}
            width={"100%"}
            iniValue={R.prop(FIELD_IDS.CONTACT_RECORD_TYPE_ID)(data)}
            options={contactTypes}
            size="big"
            usePortal
          />
        </Div>

        <Div display="column" mb={5} width={1}>
          <Label tooltip="Select the record type that registered groups should be created as">
            Attendee Group Record Type
          </Label>
          <Dropdown
            fieldId={FIELD_IDS.ACCOUNT_RECORD_TYPE_ID}
            width={"100%"}
            iniValue={R.prop(FIELD_IDS.ACCOUNT_RECORD_TYPE_ID)(data)}
            options={accountTypes}
            size="big"
            usePortal
          />
        </Div>
        */}

        <Div display="column" mb={5} width={1}>
          <Label>Registration Type</Label>
          <Dropdown
            fieldId={FIELD_IDS.REGISTRATION_TYPE}
            width={"100%"}
            iniValue={R.prop(FIELD_IDS.REGISTRATION_TYPE)(data)}
            options={REGISTRATION_TYPES}
            size="big"
            usePortal
          />
        </Div>

        <Div display="column" mb={5} width={1}>
          <Label>Header Image</Label>
          <Image
            fieldId={FIELD_IDS.REGISTRATION_IMAGE_URL}
            containerWidth="auto"
            containerProps={{
              display: "row.flex-start.flex-start",
              flex: 1
            }}
            imageWidth={198}
            imageHeight={57}
            recommendedWidth={"1500"}
            recommendedHeight={"500"}
            iniValue={
              R.prop(FIELD_IDS.REGISTRATION_IMAGE_URL, data)
                ? [
                    {
                      url: R.prop(FIELD_IDS.REGISTRATION_IMAGE_URL, data)
                    }
                  ]
                : null
            }
          />
        </Div>

        <Div display="column" mb={5} width={1}>
          <Label tooltip="Enter details about your event to show on the registration page">
            Event Details
          </Label>
          <AdvancedWYSIWYG
            fieldId={FIELD_IDS.DESCRIPTION}
            width={"100%"}
            iniValue={R.prop(FIELD_IDS.DESCRIPTION)(data)}
          />
        </Div>

        {/*
        <Div display="column" mb={5} width={1}>
          <Label tooltip="Where are your registrations taking place?">
            Registration Source
          </Label>
          <Dropdown
            fieldId={FIELD_IDS.REGISTRATION_SOURCE}
            width={"100%"}
            iniValue={R.prop(FIELD_IDS.REGISTRATION_SOURCE)(data)}
            options={REGISTRATION_SOURCES}
            size="big"
            usePortal
          />
        </Div>
        */}

        {R.prop(FIELD_IDS.REGISTRATION_SOURCE)(data) === "lennd" ? (
          <Div display="column" mb={5} width={1}>
            <Label tooltip="Select what fields to show on the registration form">
              Registration Fields
            </Label>
            <Fields />
          </Div>
        ) : null}
      </Div>
    </SectionCard>

    {/*
    <SectionCard
      collapsedDefault={true}
      name="Show Sponsors"
      isEnabled={data[FIELD_IDS.SPONSORS_ENABLED]}
      fieldId={FIELD_IDS.SPONSORS_ENABLED}
    >
      <Div px={8} pt={3}>
        <Div display="column" mb={5} width={1}>
          <Label tooltip="Enter a custom title for the Sponsors section. Default: Sponsors">
            Section Title
          </Label>
          <Input
            fieldId={FIELD_IDS.SPONSORS_LABEL}
            width={1}
            iniValue={R.prop(FIELD_IDS.SPONSORS_LABEL)(data)}
            size="big"
            inputType="outline"
          />
        </Div>

        <Div display="column" mb={5} width={1}>
          <Label tooltip="Enter a description to show above your list of sponsors">
            Section Description
          </Label>
          <AdvancedWYSIWYG
            fieldId={FIELD_IDS.SPONSORS_DESCRIPTION}
            width={"100%"}
            iniValue={R.prop(FIELD_IDS.SPONSORS_DESCRIPTION)(data)}
          />
        </Div>
      </Div>
    </SectionCard>

    <SectionCard
      collapsedDefault={true}
      name="Show Speakers"
      isEnabled={data[FIELD_IDS.SPEAKERS_ENABLED]}
      fieldId={FIELD_IDS.SPEAKERS_ENABLED}
    >
      <Div px={8} pt={3}>
        <Div display="column" mb={5} width={1}>
          <Label tooltip="Enter a custom title for the Speakers section. Default: Speakers">
            Section Title
          </Label>
          <Input
            fieldId={FIELD_IDS.SPEAKERS_LABEL}
            width={1}
            iniValue={R.prop(FIELD_IDS.SPEAKERS_LABEL)(data)}
            size="big"
            inputType="outline"
          />
        </Div>

        <Div display="column" mb={5} width={1}>
          <Label tooltip="Enter a description to show above your list of speakers">
            Section Description
          </Label>
          <AdvancedWYSIWYG
            fieldId={FIELD_IDS.SPEAKERS_DESCRIPTION}
            width={"100%"}
            iniValue={R.prop(FIELD_IDS.SPEAKERS_DESCRIPTION)(data)}
          />
        </Div>
      </Div>
    </SectionCard>
    */}

    <SectionCard
      collapsedDefault={true}
      name="Show Schedule"
      isEnabled={data[FIELD_IDS.SCHEDULE_ENABLED]}
      fieldId={FIELD_IDS.SCHEDULE_ENABLED}
    >
      <Div px={8} pt={3}>
        <Div display="column" mb={5} width={1}>
          <Label tooltip="Enter a custom title for the Schedule section. Default: Schedule">
            Section Title
          </Label>
          <Input
            fieldId={FIELD_IDS.SCHEDULE_LABEL}
            width={1}
            iniValue={R.prop(FIELD_IDS.SCHEDULE_LABEL)(data)}
            size="big"
            inputType="outline"
          />
        </Div>

        <Div display="column" mb={5} width={1}>
          <Label tooltip="Enter a description to show above your list of sessions">
            Section Description
          </Label>
          <AdvancedWYSIWYG
            fieldId={FIELD_IDS.SCHEDULE_DESCRIPTION}
            width={"100%"}
            iniValue={R.prop(FIELD_IDS.SCHEDULE_DESCRIPTION)(data)}
          />
        </Div>
      </Div>
    </SectionCard>

    <FloatingSaveBar>
      <BigFilledButton bg="altB5" color="white" onClick={save} mr={4}>
        Save
      </BigFilledButton>
      {error ? (
        <Div fs={1} color="danger8">
          {error}
        </Div>
      ) : null}
    </FloatingSaveBar>
  </Div>
);

export default decorate(Body);
