import React, { Component } from "react";
import autobind from "autobind-decorator";
import getValue from "utils/value-types/get-value/time";
import moment from "moment";
import Helpers from "utils/Global/Helpers";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import Flatpickr from "react-flatpickr";

// TODO: Global localizer kind of sucks.
import momentLocalizer from "react-widgets-moment";

const DISPLAY_FORMAT = "h:mm a";

@CSSModules(css)
class Time extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.formatValue(props.value)
    };
  }

  componentWillUpdate(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({
        value: this.formatValue(nextProps.value)
      });
    }
  }

  componentWillMount() {
    moment.locale(Helpers.getLocale());
    momentLocalizer(moment);
  }

  formatValue(value) {
    const unwrappedValue = getValue(value);
    if (!unwrappedValue) return null;
    const time = moment(unwrappedValue, "HH:mm a");
    if (!time.isValid()) return null;
    return time.toDate();
  }

  wrapValue(value) {
    return {
      type: "time",
      value: value ? moment(value).format(DISPLAY_FORMAT) : undefined
    };
  }

  @autobind
  onChange(value) {
    this.props.onChange(this.wrapValue(value));
  }

  render() {
    const { disabled, placeholder } = this.props;

    return (
      <Flatpickr
        className={css.dateTimeInput}
        disabled={disabled}
        options={{
          altInput: true,
          enableTime: true,
          noCalendar: true,
          minuteIncrement: 5,
          static: true
        }}
        value={this.state.value}
        onClose={val => this.onChange(val[0])}
        onChange={val => this.onChange(val[0])}
        placeholder={placeholder || moment().format(DISPLAY_FORMAT)}
      />
    );
  }
}

export default Time;
