import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import ModalBase from "../Base";
import { DefaultHeader } from "../Headers";

const ModalStyleWrapper = ({
  bodyStyles,
  children,
  containerStyles,
  Header = DefaultHeader,
  heading,
  hideHeader = false,
  hideModal,
  width = 410,
  "data-cy": dataCy
}) => (
  <ModalBase style={{ ...{ width }, ...containerStyles }} data-cy={dataCy}>
    {hideHeader ? "" : <Header hideModal={hideModal} heading={heading} />}
    <div styleName="body" style={{ ...bodyStyles }}>
      {children}
    </div>
  </ModalBase>
);

ModalStyleWrapper.propTypes = {
  children: PropTypes.node,
  containerStyles: PropTypes.object,
  bodyStyles: PropTypes.object,
  hideHeader: PropTypes.bool,
  Header: PropTypes.element,
  heading: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  hideModal: PropTypes.func.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default CSSModules(ModalStyleWrapper, css);
