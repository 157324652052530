import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function addBlock(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post(getState().user.user.credentials, data);
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding workflow"
          }
        ])
      );
    }
  };
}

export function updateBlock(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.put(getState().user.user.credentials, data);
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating workflow"
          }
        ])
      );
    }
  };
}

export function updateBlocks(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.put(getState().user.user.credentials, {
        ...data,
        bulk: true
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating workflows"
          }
        ])
      );
    }
  };
}

export function deleteBlock(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.delete(getState().user.user.credentials, data);
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting workflow"
          }
        ])
      );
    }
  };
}
