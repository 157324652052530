import { registerError } from "redux/modules/errors/actions";
import api from "./api";

export function updateRecordType({ permissionSetId, typeId, values }) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post(getState().user.user.credentials, {
        permissionSetId,
        typeId,
        values
      });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred updating permission set"
          }
        ])
      );
    }
  };
}
