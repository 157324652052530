import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "../../styles.scss";
import { get } from "lodash";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Body from "components/Global/Modal/Layout/ScrollableBody";
import {
  ButtonGroup,
  Submit,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";
import FormElements from "components/Global/Modal/Layout/FormElements";
import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";
import resolveEditor from "components/Global/StandAloneEditors/utils/resolveEditor";
import getMetaData from "utils/value-types/get-meta-data";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";

import { FILES } from "@lennd/value-types/src/constants/standard-module-field-ids";
const { TITLE, DESCRIPTION } = FILES;
const order = {
  [TITLE]: 1,
  [DESCRIPTION]: 2
};

const { Label, InputSection, InputGroup } = FormElements;

const UPLOAD_FIELD_ID = "d512aeba-ae22-4623-b64c-90f293e37f16";

import { Loading } from "components/Global/Module/Modals/AddRecord/Layouts/Common";

@CSSModules(css)
class AddRecordModal extends Component {
  constructor(props) {
    super(props);

    this.state = { ...props.values };
  }

  componentWillMount() {
    this.props.getModule({
      moduleId: this.props.moduleId,
      options: {
        orgId: get(this.props.orgDetails, "id"),
        eventId: get(this.props.eventDetails, "id")
      }
    });
  }

  componentWillReceiveProps(newProps) {
    this.setState({ ...newProps.values });
  }
  // populateValues = (values) => {
  //   values.forEach((val) => {
  //     this.state[val.fieldId] = val.value;
  //   });
  // }

  validateForm = () => {
    const files = get(this.state, [UPLOAD_FIELD_ID, "value", "files"]);

    if (!files || !files.length) {
      return false;
    }

    return true;
  };

  saveFieldValue = (id, val) => {
    // don't save empty values
    this.setState(state => {
      if (val && val.value) {
        state[id] = val;
        return state;
      }
      delete state[id];
      return state;
    });
  };

  resetState = () => {
    this.setState(
      state => {
        Object.keys(state).forEach(id => delete state[id]);
        return state;
      },
      () => {
        this.setState({ ...this.props.values });
      }
    );
  };

  saveAndKeepOpen = () => {
    this.handleSave().then(record => {
      this.resetState();

      // call onClose to trigger specified action even though
      // we're keeping modal open. example use case: the caller
      // needs to refetch records when new record is added.
      this.props.onSave(record);
    });
  };

  saveAndClose = () => {
    this.handleSave().then(record => {
      this.props.onSave(record);
      this.props.hideModal();
    });
  };

  handleSave = () =>
    new Promise((resolve, reject) => {
      if (this.validateForm()) {
        this.props
          .addRecord({
            moduleId: this.props.module.id,
            record: this.props.relatedRecord
              ? {
                  relatedModuleId: this.props.relatedRecord.moduleId,
                  relatedRecordId: this.props.relatedRecord.id,
                  ...this.state
                }
              : {
                  ...this.state
                },
            options: {
              orgId: get(this.props.orgDetails, "id"),
              eventId: get(this.props.eventDetails, "id")
            }
          })
          .then(record => {
            resolve(record);
          });
      } else {
        return reject();
      }
    });

  getRowMetaData = field =>
    getMetaData({
      row: {},
      rowId: 0,
      field,
      eventDetails: this.props.eventDetails,
      eventId: get(this.props.eventDetails, "id")
    });

  renderFieldGroup = () => {
    const fieldGroups = [
      ...[
        {
          id: "upload",
          name: "Upload File",
          source: "standard",
          fields: [
            {
              field_id: UPLOAD_FIELD_ID,
              order: 0,
              field: {
                id: UPLOAD_FIELD_ID,
                name: "File",
                internal_name: "upload",
                type: "file",
                settings: {
                  allowMultiple: false,
                  placeholder: "Select a file to upload"
                },
                source: "standard",
                order: 1
              }
            }
          ]
        }
      ],
      ...this.props.fieldGroups
        .map(fg => ({
          ...fg,
          fields: fg.fields
            .filter(
              f =>
                !resolveReadOnlyFields({
                  moduleId: this.props.module.id
                }).includes(f.field_id)
            )
            .map(f => ({
              ...f,
              order: order[f.field.id]
            }))
        }))
        .filter(fg => fg.fields.length)
    ];
    return fieldGroups.map(group => (
      <div key={group.id}>
        <div className={css.heading}>{group.name}</div>
        {group.fields
          .sort((a, b) => a.order - b.order)
          .map(({ field_id, field }) => {
            const Editor = resolveEditor(field);
            return (
              <InputSection key={field_id}>
                <InputGroup>
                  <Label>{field.name}</Label>
                  <Editor
                    rowMetaData={this.getRowMetaData(field)}
                    value={this.state[field_id]}
                    onChange={val => this.saveFieldValue(field_id, val)}
                    {...resolveEditorProps(field, this.props.eventDetails)}
                  />
                </InputGroup>
              </InputSection>
            );
          })}
      </div>
    ));
  };

  render() {
    const { isFetching } = this.props;

    return (
      <StyleWrapper
        bodyStyles={{ padding: 0 }}
        containerStyles={{ overflowY: "hidden" }}
        heading="Upload File"
        hideModal={this.props.hideModal}
        width={475}
      >
        <Body style={{ padding: "20px 30px 10px 30px" }}>
          {isFetching ? <Loading /> : this.renderFieldGroup()}
        </Body>
        <div className={css.footer}>
          <ButtonGroup>
            <Submit
              title="Save"
              onClick={this.saveAndClose}
              disabled={!this.validateForm()}
            />
            <ButtonOutline title="Cancel" onClick={this.props.hideModal} />
          </ButtonGroup>
          <div
            key={this.state}
            className={css.actionLink}
            style={{ cursor: this.validateForm() ? "pointer" : "not-allowed" }}
            onClick={this.validateForm() ? this.saveAndKeepOpen : null}
          >
            Save and add another
          </div>
        </div>
      </StyleWrapper>
    );
  }
}

AddRecordModal.defaultProps = {
  values: []
};

AddRecordModal.propTypes = {
  relatedRecord: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    moduleId: PropTypes.string,
    moduleInternalName: PropTypes.string
  }).isRequired,
  values: PropTypes.shape({
    fieldId: PropTypes.shape({
      fieldId: PropTypes.string,
      value: PropTypes.shape({
        type: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.array,
          PropTypes.object
        ])
      })
    })
  }),
  isFetching: PropTypes.bool.isRequired,
  addRecord: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  params: PropTypes.shape({
    eventId: PropTypes.string
  }).isRequired,
  moduleId: PropTypes.string.isRequired,
  getModule: PropTypes.func.isRequired,
  starredFields: PropTypes.array.isRequired,
  fieldGroups: PropTypes.array.isRequired,
  module: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    record_name: PropTypes.string,
    record_name_plural: PropTypes.string,
    color: PropTypes.string,
    icon: PropTypes.string,
    event_id: PropTypes.number
  }).isRequired,
  eventDetails: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  onSave: PropTypes.func.isRequired
};

export default AddRecordModal;
