import PropTypes from "prop-types";
import React from "react";

const tableDataStyle = {
  fontFamily: "arial",
  color: "#5D5D5D",
  fontSize: "12px",
  border: "none",
  padding: "4px 0",
  width: "50%"
};
const labelStyle = {
  ...tableDataStyle,
  fontWeight: "bold",
  whiteSpace: "nowrap"
};

const Row = ({ label, value }) => (
  <tr>
    <td style={labelStyle}>{label}</td>
    <td style={tableDataStyle}>{value || "—"}</td>
  </tr>
);
Row.propTypes = {
  label: PropTypes.node.isRequired,
  value: PropTypes.node.isRequired
};

export default Row;
