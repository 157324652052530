import PropTypes from "prop-types";
import React from "react";
import { isNumber } from "lodash";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const add = value => (isNumber(value) ? value + 1 : 1);
const subtract = value => (isNumber(value) ? value - 1 : 0);

const onClick = (onChange, action, value) => () => onChange(action(value));
const onInputChange = onChange => event => onChange(event.target.value);

const Input = ({ onChange, value, ...props }) => (
  <div styleName="container">
    <button
      styleName="button"
      onClick={onClick(onChange, subtract, Number(value))}
    >
      <i className={["material-icons", css.buttonIcon].join(" ")}>remove</i>
    </button>
    <input
      styleName="input"
      onChange={onInputChange(onChange)}
      value={value}
      {...props}
    />
    <button styleName="button" onClick={onClick(onChange, add, Number(value))}>
      <i className={["material-icons", css.buttonIcon].join(" ")}>add</i>
    </button>
  </div>
);

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired
};

export default CSSModules(Input, css);
