import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "Portal/PortalPagePGA/OverviewAndResources/constants";

const iniState = {};

export const model = createHandlers({
  iniState,
  reducers: {},
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
