import React from "react";
import { createSelector } from "reselect";
import * as R from "ramda";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { canUserDo } from "redux/modules/permissions/user-permission-profile/selectors";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";
import { getters } from "./model";
import { TABS, EVENT_TABS } from "Organizations/Home/constants";
import moment from "moment";

export const getApps = createSelector(
  (state, props) => canUserDo(state, props.params.orgId),
  state => selectFeatureFlag(flags.CAN_VIEW_TICKET_REQUESTS.NAME)(state),
  state => selectFeatureFlag(flags.CAN_VIEW_NEW_FILES.NAME)(state),
  state => selectFeatureFlag(flags.CAN_VIEW_ORG_SCHEDULES.NAME)(state),
  (cando, canViewTicketRequests, canViewNewFiles, canViewOrgSchedules) =>
    [
      {
        id: "events",
        internal_name: "events",
        source: "standard"
      },
      cando(`${STANDARD_MODULE_IDS.accounts.id}_read`) &&
        STANDARD_MODULE_IDS.accounts,
      cando(`${STANDARD_MODULE_IDS.contacts.id}_read`) &&
        STANDARD_MODULE_IDS.contacts,
      canViewOrgSchedules && STANDARD_MODULE_IDS.schedules,
      canViewNewFiles && STANDARD_MODULE_IDS.files,
      canViewTicketRequests && STANDARD_MODULE_IDS.requests,
      canViewTicketRequests && STANDARD_MODULE_IDS.requestsPortal
    ].filter(Boolean)
);

export const getSelectedTasks = createSelector(
  getters.tasks,
  getters.selectedTab,
  (tasks, selectedTab) =>
    R.compose(
      R.slice(0, 10),
      R.propOr([], selectedTab)
    )(tasks)
);

export const getDueLength = createSelector(
  getters.tasks,
  R.compose(
    R.length,
    R.propOr([], "due")
  )
);

export const getAssignedLength = createSelector(
  getters.tasks,
  R.compose(
    R.length,
    R.propOr([], "assigned_to_me")
  )
);

export const getEmptyTaskText = createSelector(
  getters.selectedTab,
  selectedTab => {
    if (selectedTab === TABS.DUE) {
      return "You have nothing due at this time";
    }

    if (selectedTab === TABS.ASSIGNED_TO_ME) {
      return "You have no assigned tasks";
    }

    return "You haven't completed any tasks yet";
  }
);

export const getSelecteEvents = createSelector(
  getters.eventList,
  getters.eventsSelectedTab,
  (eventList, selectedTab) => {
    const isOpen = selectedTab === EVENT_TABS.UPCOMING ? true : false;

    return R.filter(R.propEq("is_open", isOpen), eventList);
  }
);

export const getEmptyEventsText = createSelector(
  getters.eventsSelectedTab,
  eventsSelectedTab => `You don't have any ${eventsSelectedTab} events`
);

export const isNotCurrentYear = createSelector(
  getters.currentYear,
  currentYear => moment().year() !== currentYear
);

export const isPrevYearButtonEnabled = createSelector(
  getters.eventList,
  getters.currentYear,
  (events, currentYear) => {
    return R.any(event => {
      const { date_from, date_to, isDraft } = event;
      if (date_from && date_to && !isDraft) {
        const start = moment(date_from).year();
        const end = moment(date_to).year();
        return start > currentYear || end > currentYear;
      }
      return false;
    }, events);
  }
);
