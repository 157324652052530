import React from "react";
// import moment from "moment";
import Datetime from "react-datetime";
import { BigOutlineInput } from "components/Base";

const TimePicker = () => (
  <Datetime
    dateFormat={false}
    // onChange={time => console.log(moment(time).format("LT"))}
    renderInput={props => (
      <BigOutlineInput continuous {...props} width={93} ba={2} />
    )}
  />
);

export default TimePicker;
