import React from "react";
import { Div } from "components/Base";
import Header from "./Header";
import Body from "./Body";

const Layout = () => (
  <Div mb={5}>
    <Header />
    <Body />
  </Div>
);

export default Layout;
