import PropTypes from "prop-types";
import React, { Component } from "react";
import Popover from "@lennd/material-ui/Popover";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import jQuery from "jquery";

@CSSModules(css)
class CellPortal extends Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
    this.anchor = React.createRef();
    this.state = {
      showEditor: false
    };
  }

  componentDidMount() {
    // @NOTE: We're setting state here because we need refs to be set
    // before rendering. Thus, setting state forces re-render w/ resolved refs
    this.setState({
      showEditor: true
    });
  }

  onClickOutside = e => {
    if (this.state.showEditor) {
      // If clicking on modal underneath Material UI popover content, then commit change
      const $target = jQuery(e.target);
      if (
        $target.hasClass("cell-portal-modal") ||
        $target
          .parents()
          .first()
          .hasClass("cell-portal-modal")
      ) {
        e.stopPropagation();
        this.props.onClose();
      }
    }
  };

  render() {
    const { editor } = this.props;
    return (
      <div ref={this.container} style={{ position: "relative" }}>
        <div className="react-grid-user-container" ref={this.anchor}>
          <Popover
            anchorOrigin={{
              horizontal: "left",
              vertical: "top"
            }}
            transformOrigin={{
              horizontal: "left",
              vertical: "top"
            }}
            open={this.state.showEditor}
            transitionDuration={0.1}
            container={this.container.current}
            anchorEl={this.anchor.current}
            PaperProps={{
              style: {
                boxShadow: "none",
                overflow: "visible"
              }
            }}
            onMouseDown={this.onClickOutside}
            className="cell-portal-modal"
          >
            <div className="lennd">
              <div styleName="input" className="react-grid-Cell">
                {editor}
              </div>
            </div>
          </Popover>
        </div>
      </div>
    );
  }
}

CellPortal.propTypes = {
  editor: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired
};

export default CellPortal;
