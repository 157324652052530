import Helpers from "utils/Global/Helpers";

export default {
  getCateringDashboard: ({ eventId, userId, credentials }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/catering/event/${eventId}/dashboard?userId=${userId}`,
        credentials,
        success,
        error
      });
    }),
  export: ({ url, credentials }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url,
        credentials,
        success,
        error
      });
    })
};
