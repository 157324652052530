import { createModule } from "redux-mvc";

import model from "./model";

import sliderSidebar from "ui-kit/SliderSidebar";

import sagasModule from "./sagas";

const module = createModule(model);

module.setRootSaga(sagasModule);

module.plugModule(sliderSidebar);

export default module;
