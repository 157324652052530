/* eslint-disable no-underscore-dangle */
import { put, all, takeEvery, fork, select, call } from "redux-saga/effects";
import * as R from "ramda";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { actions, getters } from "./model";
import { FIELDS } from "./constants";
import { getUserId, getApiToken } from "./selectors";
import { getters as FormGetters } from "ui-kit/Form/model";
import { registerError } from "redux/modules/errors/actions";
import { apiCall } from "App/Data/sagas";
import { showSnackbar } from "redux/modules/snackbar/actions";

const fetchUser = function*() {
  try {
    const userId = yield select(getters.activeUserId);
    yield put(actions.setLoading(true));
    const eventId = yield select(getEventId);

    const { payload } = yield call(apiCall, {
      method: "get",
      url: `/event-users/${eventId}/users/${userId}`
    });

    yield put(actions.setUser(payload));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting user details"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const save = function*() {
  try {
    yield put(actions.setLoading(true));
    const eventId = yield select(getEventId);

    const user = yield select(getters.user);
    const fields = yield select(FormGetters.fields);

    const data = {
      userId: user.user_id,
      data: {
        eventPermissionsRole: user.permission_role,
        eventRole: R.path([FIELDS.ROLE, "value"], fields)
      }
    };

    yield call(apiCall, {
      method: "put",
      url: `/event/${eventId}/team`,
      baseUrl: window.__LENND_API_URL__,
      data
    });

    yield all([put(actions.setShowModal(false)), put(actions.saveResponse())]);

    yield put(actions.saveResponse());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while saving user details"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const generateApiToken = function*() {
  try {
    const eventId = yield select(getEventId);
    const userId = yield select(getUserId);

    yield call(apiCall, {
      method: "post",
      url: `/event-users/${eventId}/generate-token`,
      data: {
        userId
      }
    });

    yield all([
      put(actions.fetchUser()),
      put(showSnackbar({ message: "API token has been refreshed" }))
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while generating new token"
        }
      ])
    );
  }
};

const toggleTokenAccess = function*() {
  try {
    const eventId = yield select(getEventId);
    const userId = yield select(getUserId);

    yield call(apiCall, {
      method: "put",
      url: `/event-users/${eventId}/enable-api`,
      data: {
        userId
      }
    });

    yield all([
      put(actions.fetchUser()),
      put(showSnackbar({ message: "Preferences Saved" }))
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while toggling access token"
        }
      ])
    );
  }
};

const copyToClipboard = text =>
  new Promise((resolve, reject) => {
    navigator.clipboard.writeText(text).then(
      function() {
        resolve();
      },
      function(err) {
        reject(err);
      }
    );
  });

const copyApiToken = function*() {
  const apiToken = yield select(getApiToken);
  yield call(copyToClipboard, apiToken);
  yield put(showSnackbar({ message: "Copied" }));
};

const watchFetchUser = function*() {
  yield takeEvery(actions.fetchUser.type, fetchUser);
};

const watchSave = function*() {
  yield takeEvery(actions.save.type, save);
};

const watchCopyApiToken = function*() {
  yield takeEvery(actions.copyApiToken.type, copyApiToken);
};

const watchToggleTokenAccess = function*() {
  yield takeEvery(actions.toggleTokenAccess.type, toggleTokenAccess);
};

const watchGenerateApiToken = function*() {
  yield takeEvery(actions.generateApiToken.type, generateApiToken);
};

const rootSaga = function*() {
  yield all([
    fork(watchFetchUser),
    fork(watchSave),
    fork(watchGenerateApiToken),
    fork(watchCopyApiToken),
    fork(watchToggleTokenAccess)
  ]);
};

export default rootSaga;
