const keyMirror = require("keymirror");

module.exports = {
  ActionTypes: keyMirror({
    RECEIVE_LOGGED_IN_PROFILE: null,
    RECEIVE_PROFILE_USERS: null,
    RECEIVE_INVITED_PROFILE_USERS: null,
    RECEIVE_USER_NOTIFICATIONS: null
  }),

  PayloadSources: keyMirror({
    SERVER_ACTION: null,
    VIEW_ACTION: null
  })
};
