import * as R from "ramda";
import React from "react";
import CSSModules from "react-css-modules";
import { connect } from "react-redux";
import { createContext } from "redux-mvc";

import module from "./index";
import { getters } from "./model";

import styles from "./styles.scss";

const decorate = R.compose(
  createContext({
    module,
    options: {
      persist: false
    }
  }),
  connect((state, props) => ({
    alert: getters.alert(state, props)
  })),
  CSSModules(styles)
);

const Layout = ({ alert }) => (
  <section styleName="grid">
    <h1 styleName="title">{alert.title}</h1>
    <div dangerouslySetInnerHTML={{ __html: alert.content }} />
  </section>
);

export default decorate(Layout);
