import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { actions, getters } from "Forms/WizardModal";
import {
  Div,
  SuperOutlineButton,
  SuperFilledButton,
  RightArrowIcon,
  // AddCircleIcon,
  // BigFilledButton,
  // AssignmentIcon,
  Text1,
  // Text2,
  Text4,
  // PopoverMenu,
  LoadingIcon,
  DownIcon,
  MediumFilledButton
} from "components/Base";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import TypesDropdown from "Forms/WizardModal/View/Common/TypesDropdown";
import { PASSES_PAGES } from "Forms/WizardModal/constants";
import Header from "Forms/WizardModal/View/Header";
import FieldItem from "Forms/WizardModal/View/Common/FieldItem";
import { getItemFields } from "Forms/WizardModal/selectors";
import resolveFieldTypeName from "components/Global/Table3/FieldTypeIcons/resolve-field-type-name";

const decorate = connect(
  state => ({
    saveBtnEnabled: true,
    saving: getters.saving(state),
    fieldSets: getItemFields(state)
  }),
  {
    back: () => actions.setSelectedPasses(PASSES_PAGES.SELECT_PASSES),
    goToReview: () => actions.setSelectedPasses(PASSES_PAGES.REVIEW),
    toggleField: actions.toggleItemField,
    toggleRequired: actions.toggleItemFieldRequired,
    showModal: actions.showPassesModal,
    setAccountSelectedTypeId: actions.setAccountSelectedTypeId,
    setContactSelectedTypeId: actions.setContactSelectedTypeId
  }
);

const SelectFields = ({
  back,
  handlers,
  goToReview,
  saveBtnEnabled,
  saving,
  fieldSets,
  toggleField,
  toggleRequired,
  showModal,
  setAccountSelectedTypeId,
  setContactSelectedTypeId
}) => {
  return (
    <Div width={1} display="column.space-between" height={1} bg="neutral0">
      <Header
        handlers={handlers}
        steps={["Step 1", "Step 2", "Step 3", "Step 4"]}
      />
      <Div
        bg="neutral0"
        display="column.flex-start.center"
        flex={1}
        p={5}
        pt={0}
        style={{ overflow: "auto" }}
      >
        <Div my={5} display="column.center.center" style={{ flexShrink: 0 }}>
          <Div fs={6} fw={4} color="black">
            Select the group and contact information to collect:
          </Div>
        </Div>

        {R.compose(
          R.map(({ id, name, viewAllLabel, fields, dropdown }) => (
            <Div
              key={id}
              mb={4}
              bg="neutral1"
              width={600}
              bra={2}
              p={3}
              display="column"
              style={{ flexShrink: 0 }}
            >
              <Text4 color="gray7" bold ml={1}>
                {name}
              </Text4>
              <Div mt={2}>
                <TypesDropdown
                  {...dropdown}
                  selectTypeId={
                    STANDARD_MODULE_IDS.accounts.id === id
                      ? setAccountSelectedTypeId
                      : setContactSelectedTypeId
                  }
                />
              </Div>
              <Div display="row.space-between" mt={2} mb={1} px={1}>
                <Text1 color="neutral6">Include Question</Text1>
                <Text1 color="neutral6">*Require</Text1>
              </Div>
              <Div display="column" width={1}>
                {R.map(
                  field => (
                    <FieldItem
                      key={field.id}
                      name={field.name}
                      fieldId={field.id}
                      moduleId={id}
                      selected={field.selected}
                      required={field.required}
                      handlers={handlers}
                      toggleField={toggleField}
                      toggleRequired={toggleRequired}
                      description={resolveFieldTypeName(field.type)}
                    />
                  ),
                  fields
                )}
                <Div display="row.space-between" width={1} mt={1}>
                  {/*
                  <BigFilledButton
                    LeftIcon={AddCircleIcon}
                    bg="neutral2"
                    color="neutral7"
                    onClick={() =>
                      handlers.showAddFieldModal({
                        moduleId: id,
                        source: "passes"
                      })
                    }
                  >
                    Add question
                  </BigFilledButton>
                  */}

                  <MediumFilledButton
                    color="black"
                    bg="neutral2"
                    LeftIcon={DownIcon}
                    onClick={() => {
                      showModal({ moduleId: id });
                    }}
                  >
                    {viewAllLabel}
                  </MediumFilledButton>
                </Div>
              </Div>
            </Div>
          ))
        )(fieldSets)}
      </Div>

      <Div
        display="row.space-between.center"
        width={1}
        height={90}
        bt={1}
        bc="neutral2"
        bg="white"
        px={7}
        style={{ flexShrink: 0 }}
      >
        {saving ? (
          <Div />
        ) : (
          <SuperOutlineButton onClick={back}>Back</SuperOutlineButton>
        )}

        {saving ? (
          <SuperFilledButton ml={3} bg="altB5" RightIcon={LoadingIcon}>
            Saving
          </SuperFilledButton>
        ) : (
          <SuperFilledButton
            ml={3}
            bg="altB5"
            onClick={goToReview}
            RightIcon={RightArrowIcon}
            disabled={!saveBtnEnabled}
          >
            Review
          </SuperFilledButton>
        )}
      </Div>
    </Div>
  );
};

export default decorate(SelectFields);
