import { ADD_REFERENCE_VALUE } from "./constants";
import api from "./api";

export function addReferenceValue(
  eventId,
  userId,
  referenceId,
  destinationField,
  value
) {
  return async (dispatch, getState) => {
    dispatch({
      type: ADD_REFERENCE_VALUE,
      payload: {
        referenceId,
        destinationField,
        value
      }
    });
    try {
      await api.post(getState().user.user.credentials, userId, eventId, {
        record_id: referenceId,
        destination_field_id: destinationField,
        value
      });
    } catch (error) {
      // Swallow error
    }
  };
}
