import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  portalId: null,
  //
  id: null,
  title: null,
  backgroundImageUrl: null,
  event: {},
  accounts: []
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    setInitialData: (
      _,
      {
        payload: { portalId, id, title, background_image_url, event, accounts }
      }
    ) => ({
      loading: false,
      id,
      portalId,
      title,
      backgroundImageUrl: background_image_url,
      event,
      accounts
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
