import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "Forms/WizardModal";
import SelectForm from "./SelectForm";
import SelectQuestions from "./SelectQuestions";
import { SCHEDULES_PAGES } from "Forms/WizardModal/constants";
import ReviewForm from "Forms/WizardModal/View/ReviewForm";
import { shouldEnableSaveModuleForm } from "Forms/WizardModal/selectors";

const decorate = connect(
  state => ({
    saveBtnEnabled: shouldEnableSaveModuleForm(state),
    selectedSchedules: getters.selectedSchedules(state)
  }),
  {
    reviewGoBack: () =>
      actions.setSelectedSchedules(SCHEDULES_PAGES.SELECT_QUESTIONS),
    saveForm: actions.saveModuleForm
  }
);

const ScheduleModule = ({
  handlers,
  selectedSchedules,
  reviewGoBack,
  saveForm,
  saveBtnEnabled
}) => {
  if (selectedSchedules === SCHEDULES_PAGES.SELECT_QUESTIONS) {
    return <SelectQuestions handlers={handlers} />;
  } else if (selectedSchedules === SCHEDULES_PAGES.REVIEW) {
    return (
      <ReviewForm
        handlers={handlers}
        goBack={reviewGoBack}
        saveForm={saveForm}
        saveBtnEnabled={saveBtnEnabled}
      />
    );
  }
  return <SelectForm handlers={handlers} />;
};

export default decorate(ScheduleModule);
