import { createModule } from "redux-mvc";

import miniItemsSelector from "ui-kit/MiniItemsSelector";

import model from "./model";

import rootSaga from "./sagas";

const module = createModule(model);

module.plugModule(miniItemsSelector);

module.setRootSaga(rootSaga);

export default module;
