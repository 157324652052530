import React from "react";
import PropTypes from "prop-types";
import {
  Div,
  BigFilledButton,
  BigOutlineButton,
  Text1,
  CloseIcon,
  Text2,
  Text3,
  SmallCheckbox,
  SmallTextButton,
  DragIcon,
  MediumInsetInput,
  MediumFilledButton,
  Popover,
  Cards
} from "components/Base";

import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import { withState, withHandlers } from "utils/General";
import { sort, join, map, prop, compose, omit, without } from "ramda";

const ItemCard = ({ name, isDragging, label, toggleField, updateLabel }) => (
  <Div
    style={{ opacity: isDragging ? 0 : 1 }}
    bg="white"
    display="row.flex-start.flex-start"
    px={1}
    py={2}
    mb={1}
  >
    <DragIcon size={20} flex={0} />
    <Div flex={1} pl={3}>
      <Text2 bold pb={1}>
        {name}
      </Text2>
      <Div display="row.flex-start.flex-end">
        <InputPopover onSave={updateLabel} value={label}>
          {({ onClick }) => (
            <Text1 color="primary5" bold onClick={onClick} pr={2}>
              {label ? "Edit" : "Add Label"}
            </Text1>
          )}
        </InputPopover>
        {Boolean(label) && <Text1>Label: {label}</Text1>}
      </Div>
    </Div>
    <CloseIcon size={15} onClick={toggleField} />
  </Div>
);

const List = Cards(ItemCard, "FIELD_CARD");

const TagBuilderHandlers = compose(
  withState("selectedFields", "selectFields", []),
  withState("fieldOrder", "setOrders", {}),
  withState("labels", "setLabels", {}),
  withHandlers({
    toggleField: props => fieldId => {
      if (props.selectedFields.includes(fieldId)) {
        props.selectFields(without(fieldId, props.selectedFields));
        return props.setOrders(omit(fieldId, props.fieldOrder));
      }
      props.selectFields([...props.selectedFields, fieldId]);
      return props.setOrders({
        fieldId: props.selectedFields.length,
        ...props.fieldOrder
      });
    },
    updateLabel: props => (fieldId, label) => {
      return props.setLabels({ ...props.labels, [fieldId]: label });
    },
    setAll: props => () => {
      const fields = map(prop("id"), props.fields);
      props.selectFields(fields);
      props.setOrders(
        fields.reduce((list, v, idx) => {
          list[v] = idx;
          return list;
        }, {})
      );
    },
    setNone: props => () => {
      props.selectFields([]);
      props.setOrders({});
    },
    setOrder: props => orderedFields => {
      props.setOrders(
        orderedFields.reduce((list, { id }, idx) => {
          list[id] = idx;
          return list;
        }, {})
      );
    },
    generate: ({
      selectedFields,
      labels,
      fieldOrder,
      moduleName,
      fields,
      onSave
    }) => () => {
      const title = moduleName.replace(" ", "");
      const start = `{{#${title}}}`;
      const end = `{{/${title}}}`;
      const body = compose(
        join(","),
        map(
          id =>
            `${prop("internal_name", fields.find(f => f.id === id))}${
              labels[id] ? `||${labels[id]}` : ""
            }`
        ),
        sort((a, b) => fieldOrder[a] - fieldOrder[b])
      )(selectedFields);
      return onSave(`${start}${body}${end}`);
    }
  })
);
const TableBuilder = ({
  toggleField,
  selectedFields,
  updateLabel,
  setOrder,
  fieldOrder,
  labels,
  fields,
  setAll,
  setNone
}) => {
  return (
    <Div display="row.flex-start.stretch" height={1}>
      <Div flex={1} display="column.flex-start.flex-start">
        <Div p={4} pb={2} width={1} display="row.flex-start.center">
          <Text3 bold flex={1}>
            Available Fields
          </Text3>
          <SmallTextButton onClick={setAll} underline>
            All
          </SmallTextButton>
          <SmallTextButton onClick={setNone} underline>
            None
          </SmallTextButton>
        </Div>
        <Div p={4} pt={2} flex={1} width={1} style={{ overflowY: "auto" }}>
          {fields.map(({ id, name }) => (
            <Div
              key={id}
              bg="white"
              p={2}
              bra={1}
              display="row.flex-start.center"
              onClick={() => toggleField(id)}
              mb={1}
            >
              <SmallCheckbox selected={selectedFields.includes(id)} />
              <Text1 bold>{name}</Text1>
            </Div>
          ))}
        </Div>
      </Div>
      <Div flex={1} bg="white" style={{ overflowY: "auto" }} p={4}>
        <Div bg="neutral0" p={3} bra={1} width={1}>
          <Text2 pb={3}>Selected fields to show</Text2>
          <List
            cards={compose(
              map(id => ({
                id,
                name: fields.find(f => f.id === id).name,
                label: labels[id],
                updateLabel: val => updateLabel(id, val),
                toggleField: () => toggleField(id)
              })),
              sort((a, b) => fieldOrder[a] - fieldOrder[b])
            )(selectedFields)}
            onReorder={setOrder}
          />
        </Div>
      </Div>
    </Div>
  );
};

const InputPopover = withState(
  "value",
  "updateValue",
  props => props.value || ""
)(({ onSave, value, children, updateValue }) => (
  <Popover Label={children}>
    {({ closePopover }) => (
      <Div p={3} bg="white">
        <form
          method="post"
          onSubmit={e => {
            e.preventDefault();
            closePopover();
            onSave(value);
          }}
        >
          <MediumInsetInput
            autoFocus
            value={value}
            onChange={val => updateValue(val)}
            mr={1}
            placeholder="Label..."
          />

          <MediumFilledButton type="submit" bg="info5">
            Save
          </MediumFilledButton>
        </form>
      </Div>
    )}
  </Popover>
));

const TagRenderer = ({ tag }) => (
  <Div p={3}>
    <Text3 bold pb={2}>
      Copy this into your .docx template
    </Text3>
    <Div bg="white" bra={1} p={3} style={{ wordWrap: "break-word" }}>
      <pre style={{ whiteSpace: "pre-wrap" }}>{tag}</pre>
    </Div>
  </Div>
);

const GenerateTableModal = ({
  fieldOrder,
  fields,
  hideModal,
  labels,
  moduleName,
  selectedFields,
  setAll,
  setNone,
  setOrder,
  toggleField,
  updateLabel,
  generatedTag,
  generate
}) => (
  <Div width={1}>
    <StyleWrapper
      heading={`GenerateTable of Related: ${moduleName}`}
      hideModal={hideModal}
      width={600}
      bodyStyles={{ padding: 0 }}
    >
      <Div bg="neutral0" height={300}>
        {generatedTag ? (
          <TagRenderer tag={generatedTag} />
        ) : (
          <TableBuilder
            {...{
              fieldOrder,
              fields,
              labels,
              selectedFields,
              setAll,
              setNone,
              setOrder,
              toggleField,
              updateLabel
            }}
          />
        )}
      </Div>
      <Div bt={1} bc="neutral3" p={3} display="row.flex-start.center">
        {/* {generatedTag ? (
            <ClickToCopy text="Copy" textToCopy={generatedTag}>
              {({ label, onClick }) => (
                <BigFilledButton mr={2} onClick={onClick} bg="altB5">
                  {label}
                </BigFilledButton>
              )}
            </ClickToCopy>
          ) : ( */}
        <BigFilledButton
          mr={2}
          disabled={!selectedFields.length}
          onClick={generate}
          bg="altB5"
        >
          Insert
        </BigFilledButton>
        {/* )} */}
        <BigOutlineButton onClick={hideModal}>Cancel</BigOutlineButton>
      </Div>
    </StyleWrapper>
  </Div>
);

GenerateTableModal.propTypes = {
  fields: PropTypes.array,
  hideModal: PropTypes.func.isRequired,
  moduleName: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  tag: PropTypes.string
};

const Table = TagBuilderHandlers(GenerateTableModal);

export default Table;
