import { registerError } from "redux/modules/errors/actions";
import { REQUEST, RECEIVE, ERROR, CLEAR_ERROR } from "./constants";
import * as selectors from "./selectors";
import api from "./api";

export function getGroupPeople({ accountId, eventUUID }) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const data = await api.get(getState().user.user.credentials, {
        accountId,
        eventUUID
      });
      dispatch({ type: RECEIVE, payload: data });
      return data;
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function addPerson({ values, accountId, eventUUID }) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: CLEAR_ERROR });
      return await api.post(getState().user.user.credentials, {
        values,
        accountId,
        eventUUID
      });
    } catch ({ error }) {
      dispatch(
        registerError([
          {
            system: error,
            user: error.message
          }
        ])
      );
      dispatch({
        type: ERROR,
        payload: { error: error.message || "An error occurred!" }
      });
    }
  };
}

export function updatePerson({ accountId, eventUUID, values }) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      await api.put(getState().user.user.credentials, {
        accountId,
        eventUUID,
        values
      });
    } catch ({ error }) {
      dispatch(
        registerError([
          {
            system: error,
            user: error.message
          }
        ])
      );
      dispatch({
        type: ERROR,
        payload: { error: error.message || "An error occurred!" }
      });
      return false;
    }
    return true;
  };
}

export function removePerson({ accountId, eventUUID, memberId }) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      return await api.delete(getState().user.user.credentials, {
        accountId,
        eventUUID,
        memberId
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}
export function clearError() {
  return {
    type: CLEAR_ERROR
  };
}
