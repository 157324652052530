import * as STANDARD_MODULE_FIELD_IDS from "utils/standard-module-field-ids";

export const WIZARD_PAGE = {
  IMPORT: "import",
  CUSTOMIZE: "customize",
  BULK_IMPORT: "bulkImport",
  REVIEW: "review",
  CONFIRM: "confirm"
};

export const NAMESPACE = "ImportOrders";

export const ORDER_TYPE = {
  INDIVIDUAL: "individual",
  GROUP: "group"
};

export const VARIANTS_GROUP_ID = "e9d14c00-e74c-4520-85c4-0a3f6b6cc9d1";
export const ZONES_GROUP_ID = "f8e14c00-e74c-4520-85c4-0a3f6b6cc9d1";

export const PERSON_SECTIONS = ["Person Fields"];

export const GROUP_SECTIONS = ["Group Fields"];

export const OTHER_SECTIONS = ["Items", "Zones", "Order Details"];

export const DEFAULT_VALUE = "DefaultValue";

export const DEFAULT_VALUE_FIELD_WIDTH = 188;

export const FIELD_IDS = {
  FULL_NAME: "4da66db6-a49a-469f-a5d5-d89a17dfd739",
  FIRST_NAME: STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME,
  LAST_NAME: STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME,
  ACCOUNT_NAME: STANDARD_MODULE_FIELD_IDS.ACCOUNTS.NAME
};

export const BUTTON_TYPES = {
  BACK: "back",
  DONE: "done",
  CANCEL: "cancel",
  REVIEW: "review",
  UNDO: "undo",
  UPLOAD_RESOLVED: "uploadResolved",
  RE_UPLOAD_RESOLVED: "reUploadResolved",
  IMPORT_VALID: "importValid",
  IMPORT_ALL: "importValid",
  IMPORT: "import",
  CAPTION: "caption",
  DOWNLOAD: "download"
};

export const TYPE_SETTINGS = {
  ACCOUNT: "accountImportSettings",
  CONTACT: "contactImportSettings"
};

export const MATCH_OPTION = {
  FIRST_MATCH: "use_first_match",
  WARN_ME: "warn_me",
  SKIP_IMPORT: "skip_import"
};
