import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  users: [],
  searchTerm: "",
  error: null,
  lenndEvents: [],
  campaigns: [],
  mappings: {},
  isCampaignsModalOpen: null,
  loadingMappings: false,
  selectedCampaignId: null,
  selectedEventId: null,
  syncing: null,
  //
  eventbriteOrganizations: [],
  //
  isClassyModalOpen: false,
  isNeonOneModalOpen: false,
  isPardotModalOpen: false,
  isSalesforceModalOpen: false,
  isRaisersEdgeModalOpen: false,
  isEventbriteModalOpen: false,
  isHubspotModalOpen: false,
  //
  integrations: {
    classy: {
      connected: false,
      connected_at: null,
      config_id: null
    },
    neonone: {
      connected: false,
      connected_at: null,
      config_id: null
    },
    pardot: {
      connected: false,
      configured: false,
      connected_at: null,
      config_id: null,
      settings: {
        businessUnitId: null,
        allowDuplicateEmails: false,
        overwritePardotData: false
      }
    },
    salesforce: {
      connected: false,
      configured: false,
      connected_at: null,
      config_id: null,
      settings: {
        username: null,
        password: null,
        environment: null,
        external_org_id: null,
        instance_url: null
      }
    },
    raisersedge: {
      connected: false,
      connected_at: null,
      config_id: null
    },
    hubspot: {
      connected: false,
      connected_at: null,
      config_id: null
    },
    eventbrite: {
      connected: false,
      connected_at: null,
      config_id: null,
      settings: {
        eventbriteOrgId: null
      }
    }
  },
  //
  data: {
    classyClientId: null,
    classyClientSecret: null,
    classyOrgId: null,
    //
    pardotBusinessUnitId: null,
    pardotAllowDuplicateEmails: false,
    pardotOverwritePardotData: false,
    //
    sfUsername: null,
    sfPassword: null,
    sfEnvironment: null,
    sfExternalOrgId: null,
    sfInstanceUrl: null,
    //
    eventbriteOrgId: null
  }

  //
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    setIntegrationData: (_, { payload }) => ({
      integrations: payload,
      data: {
        //
        pardotBusinessUnitId: R.path(["pardot", "settings", "businessUnitId"])(
          payload
        ),
        pardotAllowDuplicateEmails: R.path([
          "pardot",
          "settings",
          "allowDuplicateEmails"
        ])(payload),
        pardotOverwritePardotData: R.path([
          "pardot",
          "settings",
          "overwritePardotData"
        ])(payload),
        //
        sfUsername: R.path(["salesforce", "settings", "username"])(payload),
        sfPassword: R.path(["salesforce", "settings", "password"])(payload),
        sfEnvironment: R.path(["salesforce", "settings", "environment"])(
          payload
        ),
        sfExternalOrgId: R.path(["salesforce", "settings", "external_org_id"])(
          payload
        ),
        sfInstanceUrl: R.path(["salesforce", "settings", "instance_url"])(
          payload
        ),
        //
        eventbriteOrgId: R.path(["eventbrite", "settings", "eventbriteOrgId"])(
          payload
        )
      }
    }),
    setIniIntegrationMappings: (_, { payload: { campaigns, mappings } }) => ({
      campaigns,
      mappings
    }),
    //
    connectClassy: R.identity,
    connectNeonOne: R.identity,
    connectPardot: R.identity,
    savePardotSettings: R.identity,
    connectSalesforce: R.identity,
    saveSalesforceSettings: R.identity,
    connectRaisersEdge: R.identity,
    connectHubspot: R.identity,
    saveHubspotSettings: R.identity,
    connectEventbrite: R.identity,
    saveEventbriteSettings: R.identity,
    //
    saveCampaignMappings: R.identity,
    removeMapping: R.identity,
    syncIntegration: R.identity,
    syncHubspotEvent: R.identity
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
