/* eslint-disable no-underscore-dangle */
import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import * as R from "ramda";
import { actions, getters } from "../model";
import { EDIT_MODAL_DATA } from "../constants";
import { apiCall } from "App/Data/sagas";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { registerError } from "redux/modules/errors/actions";
import { getters as MiniItemsGetters } from "ui-kit/MiniItemsSelector";
import { getters as FormGetters } from "ui-kit/Form/model";
import { formatDateTimezone } from "./index";
import { getDataOrdered } from "../utils";

const getTasks = function*({ payload: showLoading }) {
  try {
    if (showLoading) {
      yield put(actions.setLoadingTasks(true));
    }
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    const [{ payload: tasks }, { payload }] = yield all([
      call(apiCall, {
        method: "get",
        url: `/tasks/event/${eventId}/tasks/search`
      }),
      call(apiCall, {
        method: "get",
        url: `/hub/admin/event/${eventId}/type/${recordTypeId}/tasks`
      })
    ]);

    yield put(actions.setTasks(tasks));
    yield put(
      actions.setAssignedTasks(R.sort((a, b) => a.order - b.order, payload))
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error loading tasks"
        }
      ])
    );
  } finally {
    yield put(actions.setLoadingTasks(false));
  }
};

const goToTasks = function*() {
  const eventId = yield select(getEventId);

  yield call(
    window.open,
    `${window.__LENND_APP_URL__}/event-light/${eventId}/tasks`,
    "_blank"
  );
};

const updateAssignedTasks = function*() {
  try {
    const eventId = yield select(getEventId);
    const assignedIds = yield select(MiniItemsGetters.selectedItems);
    const recordTypeId = yield select(getters.selectedTypeId);
    const assignedItems = yield select(getters.assignedTasks);

    const items = getDataOrdered({
      assignedIds,
      assignedItems
    });

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/tasks`,
      data: { items }
    });

    yield all([
      put(actions.getTasks()),
      put(actions.setShowAssignModal(false))
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error updating assigned tasks"
        }
      ])
    );
  }
};

const deleteAssginedTask = function*({ payload: taskId }) {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "delete",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/task/${taskId}`
    });

    yield put(actions.getTasks());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error ocurred deleting assigned task"
        }
      ])
    );
  }
};

const updateAssginedTask = function*() {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);
    const taskId = yield select(getters.selectedModalId);
    const fields = yield select(FormGetters.fields);
    const closeDate =
      R.pathOr(null, [EDIT_MODAL_DATA.DUE_DATE, "value"], fields) || null;
    const formattedDate = yield call(formatDateTimezone, closeDate);

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/task/${taskId}`,
      data: {
        alias: R.pathOr("", [EDIT_MODAL_DATA.ALIAS, "value"], fields),
        [EDIT_MODAL_DATA.REQUIRED]: R.pathOr(
          false,
          [EDIT_MODAL_DATA.REQUIRED, "value"],
          fields
        ),
        close_date: formattedDate,
        order: 0
      }
    });

    yield all([put(actions.getTasks(true)), put(actions.closeUpdateModal())]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error ocurred updating assigned task"
        }
      ])
    );
  }
};

export const toggleRequiredTask = function*({ fieldId, required }) {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/task/${fieldId}`,
      data: {
        required
      }
    });

    yield put(actions.getTasks());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error ocurred updating assigned task"
        }
      ])
    );
  }
};

const reorderTasks = function*({ payload: orderedItems }) {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/tasks`,
      data: {
        items: orderedItems.map(({ id }, order) => ({ id, order }))
      }
    });

    yield all([
      put(actions.getTasks()),
      put(actions.setShowAssignModal(false))
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error updating assigned tasks"
        }
      ])
    );
  }
};

const watchGetTasks = function*() {
  yield takeEvery(actions.getTasks.type, getTasks);
};

const watchGoToTasks = function*() {
  yield takeEvery(actions.goToTasks.type, goToTasks);
};

const watchUpdateAssignedTasks = function*() {
  yield takeEvery(actions.updateAssignedTasks.type, updateAssignedTasks);
};

const watchDeleteAssignedTasks = function*() {
  yield takeEvery(actions.deleteAssginedTask.type, deleteAssginedTask);
};

const watchUpdateAssginedTask = function*() {
  yield takeEvery(actions.updateAssginedTask.type, updateAssginedTask);
};

const watchReorderTasks = function*() {
  yield takeEvery(actions.reorderTasks.type, reorderTasks);
};

const rootSaga = function*() {
  yield all([
    fork(watchGetTasks),
    fork(watchGoToTasks),
    fork(watchUpdateAssignedTasks),
    fork(watchDeleteAssignedTasks),
    fork(watchUpdateAssginedTask),
    fork(watchReorderTasks)
  ]);
};

export default rootSaga;
