import * as R from "ramda";

import React, { Component } from "react";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import StatusCircle from "ui-kit/StatusCircle";
import Tooltip from "components/Global/Tooltip";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";

import DocumentRequestModal from "Portal/PortalDocuments/RequestModal";
import AssignDocumentRequestsModal from "components/Global/CRM/Modals/Assign/AssignDocumentRequests";
import { noop, parseComboId } from "utils/General";

import { getValue, getSettings } from "../../utils";
import { actions } from "../../model";

const decorate = connect(
  null,
  bindInstance({
    showModal,
    hideModal,
    onDone: actions.updateData
  })
);

class DocumentRequest extends Component {
  showDocRequestModal = () => {
    const recordId = getValue(this.props, "recordId");
    const requestTypeId = getValue(this.props, "requestTypeId");
    const accountId = getValue(this.props, "accountId");
    const contactId = getValue(this.props, "contactId");

    this.props.showModal({
      content: (
        <DocumentRequestModal
          view="admin"
          recordId={recordId}
          requestTypeId={requestTypeId}
          accountId={accountId}
          contactId={contactId}
          onDone={() => {
            this.props.onDone();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showAssignDocRequestModal = () => {
    const moduleId = getSettings(this.props, "moduleId");

    this.props.showModal({
      content: (
        <AssignDocumentRequestsModal
          moduleId={moduleId}
          recordNameSingular={
            moduleId === STANDARD_MODULE_IDS.accounts.id ? "Group" : "Person"
          }
          recordNamePlural={
            moduleId === STANDARD_MODULE_IDS.accounts.id ? "Groups" : "People"
          }
          documentRequestIds={[this.props.field.id]}
          recordIds={[parseComboId(this.props.data.id)[0]]}
          onDone={() => {
            this.props.onDone();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    let status = getValue(this.props, "status", "");
    const isFieldReadOnly = getSettings(this.props, "readOnly", false);

    const statusMap = {
      "not-submitted": {
        status: "not-submitted",
        tooltip: "Not Submitted"
      },
      pending: {
        status: "pending",
        tooltip: "Pending Approval"
      },
      approved: {
        status: "approved",
        tooltip: "Approved"
      },
      rejected: {
        status: "rejected",
        tooltip: "Rejected"
      },
      default: {
        status: "not-assigned",
        tooltip: "Not Assigned"
      }
    };

    return (
      <div
        onClick={
          isFieldReadOnly
            ? noop
            : ["pending", "approved", "rejected", "not-submitted"].includes(
                status
              )
            ? this.showDocRequestModal
            : this.showAssignDocRequestModal
        }
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: "100%",
          padding: 8,
          borderRadius: 4,
          cursor: isFieldReadOnly ? "default" : "pointer"
        }}
      >
        <Tooltip
          tooltip={R.pathOr(
            statusMap.default.tooltip,
            [status, "tooltip"],
            statusMap
          )}
          placement="bottom"
        >
          <span>
            <StatusCircle
              status={R.pathOr(
                statusMap.default.status,
                [status, "status"],
                statusMap
              )}
            />
          </span>
        </Tooltip>
      </div>
    );
  }
}

export default decorate(DocumentRequest);
