import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Provider } from "react-redux";
import thunkMiddleware from "redux-thunk";
import * as R from "ramda";
import editEventModal, { actions } from "Organizations/EditEventModal";
import editEventModalSagas from "Organizations/EditEventModal/sagas";
import tagsVenues from "Organizations/TagsVenues";
import eventDetails from "Organizations/EventDetails";
import ticketsPasses from "Orders/TicketsPasses";
import Layout from "Organizations/EditEventModal/View/Layout";

editEventModal.plugModule(tagsVenues);
editEventModal.plugModule(eventDetails);
editEventModal.plugModule(ticketsPasses);
editEventModal.setRootSaga(editEventModalSagas);

class EditEventModal extends Component {
  static contextTypes = {
    store: PropTypes.shape({
      subscribe: PropTypes.func.isRequired,
      dispatch: PropTypes.func.isRequired,
      getState: PropTypes.func.isRequired
    })
  };

  constructor(props, context) {
    super(props);
    const { store } = editEventModal.createStore({
      middleware: [thunkMiddleware],
      globalStore: context.store,
      observedDomains: ["user", "event", "modal", "organization"],
      dispatchToGlobal: R.compose(
        R.not,
        R.test(new RegExp(R.join("|", editEventModal.modules))),
        R.prop("type")
      )
    });
    this.store = store;

    editEventModal.run();
  }

  componentDidMount() {
    this.store.dispatch(
      actions.getInitialData({
        id: this.props.id,
        initialScene: this.props.initialScene,
        org: this.props.params.orgId
      })
    );
  }

  componentWillUnmount() {
    this.props.onDone();
    editEventModal.cancel();
  }

  render() {
    return (
      <Provider store={this.store}>
        <Layout />
      </Provider>
    );
  }
}

export default withRouter(EditEventModal);
