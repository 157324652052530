import React from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import { Div, BigFilledButton, MediumInsetInput, Span } from "components/Base";

const View = ({
  saving,
  name,
  hideModal,
  fields,
  onSave,
  error,
  contactSupport
}) => (
  <StyleWrapper
    heading="Activate Integration"
    hideModal={hideModal}
    width={550}
  >
    <Div mb={3}>
      <Div mb={5}>
        <strong>{name}</strong> requires some additional information in order to
        connect with LENND. Fill out the information below and then connect.
      </Div>
      <Div>
        {fields.map(field => (
          <Div key={field.id} mb={2}>
            <Div mb={1} fw={3}>
              {field.label}
            </Div>
            <MediumInsetInput
              continuous
              onChange={field.onChange}
              type={field.type}
              value={field.value}
            />
          </Div>
        ))}
      </Div>

      {error ? (
        <Div p={4} bg="danger3" bra={1} mb={3}>
          Oops, we ran into an issue - {error.details}
          {error.code ? ` (${error.code}).` : null}
        </Div>
      ) : null}

      <Div display="row.space-between.center" bt={1} bc="gray2" pt={3} mt={5}>
        <BigFilledButton
          bg="altB5"
          color="white"
          onClick={onSave}
          disabled={saving}
        >
          {saving ? "Connecting..." : "Connect"}
        </BigFilledButton>
        <Div fs={2} color="gray5">
          Need help? You can{" "}
          <Span onClick={contactSupport} underline>
            contact us
          </Span>{" "}
          at anytime.
        </Div>
      </Div>
    </Div>
  </StyleWrapper>
);

export default View;
