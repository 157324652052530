import React from "react";
import { Page, Title } from "EventLight/Common/FieldLayout";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";

import Approvers from "EventLight/Common/Items/Approvers/View";

const Layout = () => (
  <>
    <Page>
      <Title
        icon="assignment_turned_in"
        title="Approvers"
        description="Manage who can approve requests for passes"
      />
      <Approvers typeId={CREDENTIAL_TYPE_ID} />
    </Page>
  </>
);

export default Layout;
