/* eslint-disable no-underscore-dangle */
import superagent from "superagent";
import Helpers from "utils/Global/Helpers";

module.exports = {
  get: async credentials => {
    return await superagent
      .get(`${window.__LENND_API_BASE_URL__}/integrations/ticketing/providers`)
      .set("Authorization", `Bearer ${credentials.idToken}`);
  },
  eventConfigurations: async (credentials, eventId) => {
    return await superagent
      .get(
        `${window.__LENND_API_BASE_URL__}/integrations/ticketing/provider-configurations/events/${eventId}`
      )
      .set("Authorization", `Bearer ${credentials.idToken}`);
  },
  getProviderEvents: async (credentials, providerConfigurationId) => {
    return await superagent
      .get(
        `${window.__LENND_API_BASE_URL__}/integrations/ticketing/provider-configurations/${providerConfigurationId}/provider-events`
      )
      .set("Authorization", `Bearer ${credentials.idToken}`);
  },
  getTicketTypes: async (credentials, providerId, eventId) => {
    return await superagent
      .get(
        `${window.__LENND_API_BASE_URL__}/integrations/ticketing/providers/${providerId}/events/${eventId}/ticket-types`
      )
      .set("Authorization", `Bearer ${credentials.idToken}`);
  },
  getTicketTypeMapping: async (credentials, providerId, eventId) => {
    return await superagent
      .get(
        `${window.__LENND_API_BASE_URL__}/integrations/ticketing/providers/${providerId}/events/${eventId}/ticket-types/mappings`
      )
      .set("Authorization", `Bearer ${credentials.idToken}`);
  },
  syncTicketTypes: async (
    credentials,
    providerConfiguration,
    eventId,
    ticketMapping
  ) => {
    return await superagent
      .put(
        `${window.__LENND_API_BASE_URL__}/integrations/ticketing/providers/${providerConfiguration.provider.id}/events/${eventId}/ticket-types/mappings`
      )
      .set("Authorization", `Bearer ${credentials.idToken}`)
      .send({ eventId, ticketMapping });
  },
  configureProviderForEvent: async (credentials, configurationProps) => {
    return await superagent
      .post(
        `${window.__LENND_API_BASE_URL__}/integrations/ticketing/provider-configurations`
      )
      .set("Authorization", `Bearer ${credentials.idToken}`)
      .send(configurationProps);
  },
  removeProviderFromEvent: async (
    credentials,
    providerConfiguration,
    eventId
  ) => {
    return await superagent
      .delete(
        `${window.__LENND_API_BASE_URL__}/integrations/ticketing/providers/${providerConfiguration.provider.id}/events/${eventId}/configuration`
      )
      .set("Authorization", `Bearer ${credentials.idToken}`);
  },
  getAuth1stStep: async (credentials, providerId) => {
    return await superagent
      .get(
        `${window.__LENND_API_BASE_URL__}/integrations/ticketing/providers/${providerId}/auth/1stStep`
      )
      .set("Authorization", `Bearer ${credentials.idToken}`);
  },
  getAuth2ndStep: (credentials, providerId, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/integrations/ticketing/providers/${providerId}/auth/2ndStep`,
        data,
        credentials,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),
  getAuth2ndStepHack: (credentials, providerId, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/integrations/ticketing/providers/${providerId}/auth/2ndStep/hack`,
        data,
        credentials,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  getProviderConfigurationSettings: (credentials, providerId, data, page = 1) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/integrations/ticketing/providers/${providerId}/events/${data.eventId}/configuration/settings?page=${page}`,
        credentials,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  updateProviderConfigurationSettings: (credentials, providerId, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/integrations/ticketing/providers/${providerId}/events/${data.eventId}/configuration/settings`,
        data,
        credentials,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
