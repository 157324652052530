import { createModule } from "redux-mvc";

import model from "./model";

import sagasModule from "./sagas";
import TableModule from "ui-kit/Table";

const module = createModule({
  ...model,
  observedDomains: ["portal", "reports"]
});
module.plugModule(TableModule);
module.setRootSaga(sagasModule);

export default module;
