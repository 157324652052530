import React, { Fragment } from "react";
import * as R from "ramda";
import { actions } from "Forms/WizardModal";
import { connect } from "react-redux";
import {
  Div,
  CloseIcon,
  SmallFilledInput,
  BigOutlineButton,
  EditIcon,
  AddCircleIcon,
  AddListIcon,
  SmallFilledButton,
  Popover
} from "components/Base";

import Bulk from "components/Global/Table3/ModalColumn/DropdownOptions/Bulk";

const decorate = connect(
  null,
  {
    toggleCategoryAddItem: actions.toggleCategoryAddItem,
    toggleCategoryRemoveItem: actions.toggleCategoryRemoveItem,
    addRecordItem: ({ value, parentCategoryRecordId }) =>
      actions.addRecordItem({ value, parentCategoryRecordId }),
    addRecordItems: ({ value, parentCategoryRecordId }) =>
      actions.addRecordItems({ value, parentCategoryRecordId }),
    setAddItemName: ({ id, addItemName }) =>
      actions.setAddItemName({ id, addItemName }),
    updateItemName: ({ categoryId, itemId, editName }) =>
      actions.updateItemName({ categoryId, itemId, editName }),
    enabledUpdateItemName: ({ categoryId, itemId }) =>
      actions.enabledUpdateItemName({ categoryId, itemId }),
    disableUpdateItemName: ({ categoryId, itemId }) =>
      actions.disableUpdateItemName({ categoryId, itemId }),
    updateItem: ({ value, itemRecordId }) =>
      actions.updateItem({ value, itemRecordId }),
    deleteItem: ({ itemRecordId }) => actions.deleteItem({ itemRecordId })
  }
);

const ItemList = ({
  items,
  addRecordItem,
  addRecordItems,
  categoryRecordId,
  addItemName,
  categoryId,
  setAddItemName,
  updateItemName,
  isAddingItem,
  enabledUpdateItemName,
  disableUpdateItemName,
  updateItem,
  deleteItem,
  toggleCategoryAddItem,
  toggleCategoryRemoveItem
}) => (
  <Div display="column" style={{ paddingLeft: "55px" }} pb={3}>
    {R.map(
      item =>
        !item.isEditing ? (
          <Div
            display="row.flex-start.center"
            className="hoverContainer"
            height={24}
            flex={1}
            key={item.id}
          >
            <Div bra={1} mr={1} fs={2} color="gray7" fw={3} truncate>
              {item.name}
            </Div>

            {item.isRemoving ? (
              <Div display="row.flex-start.center">
                <Div fs={2} ml={2}>
                  Are you sure?
                </Div>
                <SmallFilledButton
                  bg="danger8"
                  color="white"
                  onClick={() => deleteItem({ itemRecordId: item.id })}
                  ml={3}
                >
                  Yes, delete
                </SmallFilledButton>
                <SmallFilledButton
                  bg="neutral2"
                  color="gray7"
                  onClick={() =>
                    toggleCategoryRemoveItem({
                      categoryId,
                      itemId: item.id,
                      isRemoving: false
                    })
                  }
                  ml={1}
                >
                  Cancel
                </SmallFilledButton>
              </Div>
            ) : (
              <Fragment>
                <Div
                  width={20}
                  height={24}
                  bra={1}
                  display="row.center.center"
                  className="showOnHover"
                  transition="fast"
                  ml={3}
                  onClick={() => {
                    enabledUpdateItemName({ categoryId, itemId: item.id });
                  }}
                  bg={{
                    default: "neutral2",
                    hover: "purple8"
                  }}
                  color={{
                    default: "gray7",
                    hover: "white"
                  }}
                >
                  <EditIcon
                    size={14}
                    style={{
                      color: "inherit"
                    }}
                  />
                </Div>

                <Div
                  width={20}
                  height={24}
                  bra={1}
                  display="row.center.center"
                  className="showOnHover"
                  transition="fast"
                  ml={1}
                  onClick={() =>
                    toggleCategoryRemoveItem({
                      categoryId,
                      itemId: item.id,
                      isRemoving: true
                    })
                  }
                  bg={{
                    default: "neutral2",
                    hover: "purple8"
                  }}
                  color={{
                    default: "gray7",
                    hover: "white"
                  }}
                >
                  <CloseIcon
                    size={14}
                    style={{
                      color: "inherit"
                    }}
                  />
                </Div>
              </Fragment>
            )}
          </Div>
        ) : (
          <Div display="row" key={item.id}>
            <SmallFilledInput
              bg="neutral1"
              width={200}
              color="black"
              continuous
              value={item.editName}
              onChange={text =>
                updateItemName({
                  categoryId,
                  itemId: item.id,
                  editName: text
                })
              }
            />

            <SmallFilledButton
              bg="purple8"
              color="white"
              onClick={() => {
                updateItem({ value: item.editName, itemRecordId: item.id });
                disableUpdateItemName({
                  categoryId,
                  itemId: item.id
                });
              }}
              ml={3}
              disabled={item.editName.length < 2}
            >
              Save
            </SmallFilledButton>
            <SmallFilledButton
              bg="neutral2"
              color="gray7"
              onClick={() =>
                disableUpdateItemName({
                  categoryId,
                  itemId: item.id
                })
              }
              ml={1}
            >
              Cancel
            </SmallFilledButton>
          </Div>
        ),
      items
    )}
    {isAddingItem ? (
      <Div display="row.flex-start.center">
        <SmallFilledInput
          bg="neutral1"
          placeholder="Enter item name..."
          width={200}
          color="black"
          continuous
          onChange={text =>
            setAddItemName({ addItemName: text, id: categoryId })
          }
        />
        <SmallFilledButton
          bg="purple8"
          color="white"
          onClick={() => {
            setAddItemName({ addItemName: "", id: categoryId });
            toggleCategoryAddItem({ id: categoryId });
            addRecordItem({
              parentCategoryRecordId: categoryRecordId,
              value: addItemName
            });
          }}
          ml={3}
          disabled={addItemName.length < 2}
        >
          Save
        </SmallFilledButton>
        <SmallFilledButton
          bg="neutral2"
          color="gray7"
          onClick={() => {
            toggleCategoryAddItem({ id: categoryId });
          }}
          ml={1}
        >
          Cancel
        </SmallFilledButton>
      </Div>
    ) : null}
    <Div display="row" mt={2}>
      <BigOutlineButton
        LeftIcon={AddCircleIcon}
        onClick={() => {
          toggleCategoryAddItem({ id: categoryId });
        }}
      >
        {isAddingItem ? "Cancel" : "Add Item"}
      </BigOutlineButton>

      <Popover
        Label={({ onClick }) => {
          return (
            <BigOutlineButton ml={3} LeftIcon={AddListIcon} onClick={onClick}>
              Add Multiple
            </BigOutlineButton>
          );
        }}
      >
        {({ closePopover }) => {
          return (
            <Bulk
              onSave={value => {
                addRecordItems({
                  parentCategoryRecordId: categoryRecordId,
                  value
                });
                closePopover();
              }}
              onCancel={closePopover}
            />
          );
        }}
      </Popover>
    </Div>
  </Div>
);

export default decorate(ItemList);
