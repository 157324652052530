import React from "react";
import { SubNavWrapper, SubNavLink } from "EventLight/Common/SubNav";
import { TAB_IDS } from "./constants";
import { withRouter } from "react-router";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import { connect } from "react-redux";
import * as R from "ramda";
import {
  isEventFeatureEnabled,
  isConferenceTemplate
} from "redux/modules/event/selectors";
import { FEATURES } from "utils/event-types-and-feature-constants";

import CanUserDo from "components/Global/Security/CanUserDo";

const decorate = R.compose(
  connect(state => ({
    isEnabled: isEventFeatureEnabled(state),
    isConferenceTemplate: isConferenceTemplate(state)
  })),
  withRouter
);

const SubNav = ({
  selectedView,
  router,
  params,
  tabs = [],
  isEnabled,
  isConferenceTemplate
}) => (
  <SubNavWrapper tabs={tabs} supportId="settings">
    <CanUserDo
      any={[`${STANDARD_MODULE_IDS.settings.id}_manage_email_templates`]}
    >
      <SubNavLink
        name="Email Templates"
        active={selectedView === TAB_IDS.EMAIL_TEMPLATES}
        onClick={() =>
          router.push({
            pathname: `/event-light/${params.eventId}/settings/email-templates`
          })
        }
      />
    </CanUserDo>

    <CanUserDo
      any={[`${STANDARD_MODULE_IDS.settings.id}_manage_all_module_data`]}
    >
      <SubNavLink
        name="Document Templates"
        active={selectedView === TAB_IDS.DOCUMENT_TEMPLATES}
        onClick={() =>
          router.push({
            pathname: `/event-light/${params.eventId}/settings/document-templates`
          })
        }
      />
    </CanUserDo>

    {/*
    <CanUserDo
      any={[`${STANDARD_MODULE_IDS.settings.id}_manage_all_module_data`]}
    >
    */}

    {!isConferenceTemplate ? (
      <SubNavLink
        name="Webhooks"
        active={selectedView === TAB_IDS.WEBHOOKS}
        onClick={() =>
          router.push({
            pathname: `/event-light/${params.eventId}/settings/webhooks`
          })
        }
      />
    ) : null}

    {/* </CanUserDo> */}

    {/*
      <SubNavLink
        name="Approval Workflows"
        active={selectedView === TAB_IDS.APPROVAL_WORKFLOWS}
        onClick={() =>
          router.push({
            pathname: `/event-light/${params.eventId}/settings/approval-workflows`
          })
        }
      />
      */}

    {!isConferenceTemplate ? (
      <CanUserDo
        any={[`${STANDARD_MODULE_IDS.settings.id}_manage_all_module_data`]}
      >
        {isEnabled(FEATURES.INVOICES) ? (
          <SubNavLink
            name="Invoices"
            active={selectedView === TAB_IDS.INVOICE_SETTINGS}
            onClick={() =>
              router.push({
                pathname: `/event-light/${params.eventId}/settings/invoice-settings`
              })
            }
          />
        ) : null}
      </CanUserDo>
    ) : null}
  </SubNavWrapper>
);

export default decorate(SubNav);
