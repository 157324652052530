import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import { get } from "lodash";
import css from "./styles.scss";
import {
  ButtonGroup,
  ButtonOutline,
  Submit
} from "components/Global/Modal/Layout/Buttons";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";

class FieldGroupModal extends Component {
  state = { name: get(this.props, "data.name", "") };

  handleNameChange = e => this.setState({ name: e.target.value });

  handleSave = () => {
    const data = { ...this.props.data, name: this.state.name };
    this.props.hideModal();
    this.props.onSave(data);
  };

  render() {
    const { data, label, hideModal } = this.props;
    return (
      <StyleWrapper
        heading={`${data ? "Edit" : "Add"} ${label}`}
        hideModal={hideModal}
      >
        <form onSubmit={this.handleSave}>
          <div styleName="inputGroup">
            <label>Name</label>
            <input
              type="text"
              value={this.state.name}
              autoFocus
              onChange={this.handleNameChange}
            />
          </div>
          <ButtonGroup>
            <Submit title="Save" />
            <ButtonOutline title="Cancel" onClick={hideModal} />
          </ButtonGroup>
        </form>
      </StyleWrapper>
    );
  }
}

FieldGroupModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string
  })
};

export default CSSModules(FieldGroupModal, css);
