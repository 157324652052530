import * as R from "ramda";
import { createSelector } from "reselect";
import {
  DEFAULT_VALUE,
  FIELD_IDS,
  GROUP_SECTIONS,
  PERSON_SECTIONS,
  TYPE_SETTINGS,
  VARIANTS_GROUP_ID
} from "Orders/ImportModal/constants";
import { addS } from "utils/General";

import { getters } from "Orders/ImportModal";
import {
  isAccountsModule,
  isContactsModule,
  isContactsOrAccountsModule
} from "./customizeDownload";

const getMappingField = (line, mappingField) => line[mappingField] || "";

const getDefaultValue = (line, fieldColumn, mappingField) =>
  R.pathOr(getMappingField(line, mappingField), ["defaultValue"], fieldColumn);

const getFromLineValueOrDefault = (line, mappingField, fieldColumn) =>
  mappingField === -1
    ? getDefaultValue(line, fieldColumn, mappingField)
    : getMappingField(line, mappingField);

const getSectionFields = fields =>
  Array.isArray(R.head(fields)) ? R.head(fields) : fields;

const groupFieldsBy = (key = "name", map = R.identity) =>
  R.compose(
    R.map(([id, val]) => ({
      id,
      name: R.path(["settings", `${key}Name`], R.head(val)),
      items: map(val)
    })),
    Object.entries,
    R.groupBy(R.path(["settings", `${key}Id`]))
  );

const groupByGroupName = groupFieldsBy("group");
const groupByTypeName = groupFieldsBy("type", groupByGroupName);

export const getFilterEnabledGroups = createSelector(
  getters.enabledFieldsGroup,
  getters.selectedFieldsFilter,
  (enabledFieldsGroup, selectedFieldsFilter) => {
    if (R.isEmpty(selectedFieldsFilter)) {
      return enabledFieldsGroup;
    }
    return R.filter(
      field =>
        R.contains(R.toLower(selectedFieldsFilter), R.toLower(field.name)),
      enabledFieldsGroup
    );
  }
);

const flatGroupFields = groupFields =>
  R.reduce(
    (acc, group) => [
      ...acc,
      ...R.map(field => ({ ...field, groupName: group.name }), group.fields)
    ],
    []
  )(groupFields);

export const getMessages = createSelector(
  getters.fieldsGroups,
  R.compose(
    R.map(
      ({ groupName, name }) =>
        `Map the "${groupName} - ${name}" field to a CSV column`
    ),
    R.filter(
      ({ column, defaultValue, selected }) =>
        selected &&
        (column.length < 2 ||
          (column === DEFAULT_VALUE && defaultValue.length < 2))
    ),
    flatGroupFields,
    R.filter(R.propEq("selected", true))
  )
);

export const isImportBtnDisabled = createSelector(
  getters.fieldsGroups,
  fieldsGroups => {
    // First, flatten the fields from all field groups in order to be easily manipulate
    const flattenedFields = flatGroupFields(fieldsGroups);

    // If there isn't any field that is selected, the button have to be disabled
    if (R.not(R.find(R.propEq("selected", true))(flattenedFields))) {
      return true;
    }

    // Using `R.find` for performance optimization instead of `R.filter`
    return R.compose(
      Boolean,
      R.find(
        ({ column, defaultValue, selected }) =>
          selected &&
          (column.length < 2 ||
            (column === DEFAULT_VALUE && defaultValue.length < 2))
      )
    )(flattenedFields);
  }
);

export const getSelectedFieldsMessage = createSelector(
  getters.totalCountOfRecordsToProcess,
  count => `${count} row${addS(count)} will be processed`
);

export const getBulkImportCaption = createSelector(
  getSelectedFieldsMessage,
  getMessages,
  (fieldsMessage, messages) =>
    messages.length > 0 ? R.last(messages) : fieldsMessage
);

export const getMappedFields = createSelector(
  getters.fieldsGroups,
  getters.ordersModule,
  isContactsOrAccountsModule,
  (fieldsGroups, ordersModule, isContactsOrAccountsModule) =>
    R.compose(
      groups => {
        const orderModuleIndex = R.findIndex(
          R.propEq("module_id", ordersModule.id),
          groups
        );

        // If importing persons or groups, do not mess with variants/items
        if (orderModuleIndex === -1 && !isContactsOrAccountsModule) {
          const invariantId = fieldsGroups[2].id;
          const invariantGroupIndex = R.findIndex(
            R.propEq("module_id", invariantId),
            groups
          );
          if (invariantGroupIndex !== -1) {
            return [
              ...groups.slice(0, invariantGroupIndex),
              {
                module_id: ordersModule.id,
                name: ordersModule.name,
                fields: []
              },
              ...groups.slice(invariantGroupIndex)
            ];
          }
          return [
            ...groups,
            {
              module_id: ordersModule.id,
              name: ordersModule.name,
              fields: []
            }
          ];
        }

        return groups;
      },
      R.map(({ id, name, fields, selectedRecordTypeId }) => ({
        module_id: id,
        name,
        selectedRecordTypeId,
        fields: R.compose(
          R.map(field => {
            const data = {
              id: field.id,
              column: field.column,
              type: field.type,
              required: field.required
            };
            if (field.defaultValue) {
              data.default_value = field.defaultValue;
            }
            return data;
          }),
          R.filter(R.propEq("selected", true))
        )(fields)
      }))
      // R.filter(R.propEq("selected", true))
    )(fieldsGroups)
);

const mapGroupItemsByType = R.compose(
  R.map(type => {
    return {
      ...type,
      groups: R.reduce(
        (acc, item) => {
          const itemIndex = R.findIndex(
            f => f.groupId === item.settings.groupId,
            acc
          );

          if (itemIndex === -1) {
            return [
              ...acc,
              {
                groupId: item.settings.groupId,
                groupName: item.settings.groupName,
                items: [item]
              }
            ];
          }
          return [
            ...acc.slice(0, itemIndex),
            {
              ...acc[itemIndex],
              items: [...acc[itemIndex].items, item]
            },
            ...acc.slice(itemIndex + 1)
          ];
        },
        [],
        type.groups
      )
    };
  }),
  R.reduce((acc, field) => {
    const groupIndex = R.findIndex(
      f => f.typeId === field.settings.typeId,
      acc
    );
    if (groupIndex === -1) {
      return [
        ...acc,
        {
          typeId: field.settings.typeId,
          typeName: field.settings.typeName,
          groups: [field]
        }
      ];
    }
    return [
      ...acc.slice(0, groupIndex),
      {
        ...acc[groupIndex],
        groups: [...acc[groupIndex].groups, field]
      },
      ...acc.slice(groupIndex + 1)
    ];
  }, [])
);

export const getFilteredVariants = createSelector(
  getters.variantsTemp,
  getters.typesFilter,
  (variantsTemp, typesFilter) => {
    return R.filter(
      item =>
        R.contains(R.toLower(typesFilter), R.toLower(item.settings.itemName)),
      variantsTemp
    );
  }
);

export const getGroupedItemsByType = createSelector(
  getters.variantsTemp,
  variantsTemp => mapGroupItemsByType(variantsTemp)
);

export const getSelectedType = createSelector(
  getFilteredVariants,
  getters.selectedVariantType,
  (variantsTemp, selectedVariantType) =>
    R.compose(
      R.find(R.propEq("typeId", selectedVariantType)),
      mapGroupItemsByType
    )(variantsTemp)
);

export const getPreviewList = createSelector(
  getters.fieldsGroups,
  getters.firstLines,
  getters.importedColumns,
  isContactsModule,
  isAccountsModule,
  (
    fieldsGroups,
    firstLines,
    importedColumns,
    isContactsModule,
    isAccountsModule
  ) => {
    /**
     * In order to mount the correct preview list,
     * we need to understand which module we're in.
     * First we need to get the corresponding group fields.
     */
    const [PERSON_SECTIONS_NAME] = PERSON_SECTIONS;
    const [GROUP_SECTIONS_NAME] = GROUP_SECTIONS;

    const contactsGroup = R.or(
      R.find(group => group.name === PERSON_SECTIONS_NAME, fieldsGroups),
      {}
    );
    const accountsGroup = R.or(
      R.find(group => group.name === GROUP_SECTIONS_NAME, fieldsGroups),
      {}
    );
    const variantsGroup = R.or(
      R.find(
        group =>
          group.name !== PERSON_SECTIONS_NAME &&
          group.name !== GROUP_SECTIONS_NAME,
        fieldsGroups
      ),
      {}
    );

    /**
     * `fullName`, `firstName`, `lastName` and `email` are present on every import type.
     * @type {*|(function(*): (*))}
     */
    const fullNameField = R.find(
      R.propEq("id", FIELD_IDS.FULL_NAME),
      contactsGroup.fields
    );
    const firstName = R.find(
      R.propEq("id", FIELD_IDS.FIRST_NAME),
      contactsGroup.fields
    );
    const lastName = R.find(
      R.propEq("id", FIELD_IDS.LAST_NAME),
      contactsGroup.fields
    );
    const emailField = R.find(R.propEq("name", "Email"), contactsGroup.fields);

    const mapping = {
      fullName: R.findIndex(
        column => column === fullNameField.column,
        importedColumns
      ),
      email: R.findIndex(
        column => column === emailField.column,
        importedColumns
      ),
      firstName: R.findIndex(
        column => column === firstName.column,
        importedColumns
      ),
      lastName: R.findIndex(
        column => column === lastName.column,
        importedColumns
      )
    };

    let accountNameField = {};
    /**
     * For groups import, try to find this column as well.
     */
    if (isAccountsModule) {
      accountNameField = R.find(R.propEq("name", "Name"), accountsGroup.fields);

      mapping.accountName = R.findIndex(
        column => column === accountNameField.column,
        importedColumns
      );
    }

    /**
     * Here occurs the mapping of the imported parsed data with the groups fields.
     * Based on `firstLines`, which is the BE's response as the imported data,
     * compose an array of objects contaning the data that will be handled on the UI layer
     * @type {*|(function(*): (*))}
     */
    return R.map(line => {
      const fName = getFromLineValueOrDefault(
        line,
        mapping.firstName,
        firstName
      );
      const lName = getFromLineValueOrDefault(line, mapping.lastName, lastName);
      const email = getFromLineValueOrDefault(line, mapping.email, emailField);
      const accountName = getFromLineValueOrDefault(
        line,
        mapping.accountName,
        accountNameField
      );
      let variantsItems = [];

      // If importing persons or groups, do not mess with variants/items (2)
      if (!isContactsModule || !isAccountsModule) {
        variantsItems = R.compose(
          R.filter(
            field =>
              !isNaN(parseInt(field.amount, 10)) &&
              parseInt(field.amount, 10) > 0
          ),
          R.map(field => {
            const amountIndex = R.findIndex(
              column => column === field.column,
              importedColumns
            );

            return {
              id: field.id,
              name: field.settings.variantDisplayName,
              amount: line[amountIndex],
              backgroundColor: field.settings.backgroundColor
            };
          }),
          R.filter(
            ({ selected, column }) => selected === true && !R.isEmpty(column)
          )
        )(R.or(variantsGroup.fields, []));
      }

      if (mapping.fullName === -1 && !fullNameField.defaultValue) {
        return {
          name: `${fName} ${lName}`,
          email,
          initials: `${R.propOr("", "0", fName)}${R.propOr("", "0", lName)}`,
          variantsItems,
          accountName
        };
      }

      const name = getFromLineValueOrDefault(
        line,
        mapping.fullName,
        fullNameField
      );
      return {
        name,
        email,
        initials: R.compose(
          vector =>
            vector.length === 1
              ? `${vector[0][0]}`
              : `${vector[0][0]}${vector[1][0]}`,
          R.split(" ")
        )(name),
        variantsItems,
        accountName
      };
    }, firstLines);
  }
);

export const getImportSettings = createSelector(
  getters.settingsType,
  getters.accountImportSettings,
  getters.contactImportSettings,
  (settingsType, accountImportSettings, contactImportSettings) => {
    if (settingsType === TYPE_SETTINGS.CONTACT) {
      return contactImportSettings;
    }
    return accountImportSettings;
  }
);

export const getFieldsBySection = createSelector(
  getters.fieldsGroups,
  (state, props) => props.sectionId,
  (fieldGroups, sectionId) => {
    const fields = getSectionFields(
      R.propOr(
        [],
        "fields",
        R.find(R.compose(R.equals(sectionId), R.prop("id")), fieldGroups)
      )
    );
    if (sectionId === VARIANTS_GROUP_ID) {
      return groupByTypeName(fields);
    }
    return fields;
  }
);

export const getEnabledFieldsBySection = createSelector(
  getFieldsBySection,
  (state, props) => props.sectionId,
  (fields, sectionId) => {
    if (sectionId === VARIANTS_GROUP_ID) {
      return R.compose(
        R.map(R.prop("id")),
        R.filter(R.prop("enabled")),
        R.flatten,
        R.map(R.prop("items")),
        R.flatten,
        R.map(R.prop("items"))
      )(fields);
    }
    return R.compose(R.map(R.prop("id")), R.filter(R.prop("enabled")))(fields);
  }
);
