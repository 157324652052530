import * as R from "ramda";

export const getDataOrdered = ({ assignedIds, assignedItems }) => {
  if (R.isEmpty(assignedIds)) {
    return [];
  }

  const newAssignedIds = R.difference(
    assignedIds,
    R.map(R.prop("id"), assignedItems)
  );
  const oldAssignedIds = R.intersection(
    assignedIds,
    R.map(R.prop("id"), assignedItems)
  );

  if (R.isEmpty(oldAssignedIds)) {
    return newAssignedIds.map((id, idx) => ({
      id,
      order: idx
    }));
  }

  const mapIndexed = R.addIndex(R.map);

  const oldAssignedItems = R.compose(
    mapIndexed(({ id }, order) => ({ id, order })),
    R.sort((a, b) => a.order - b.order),
    R.map(oldAssignedId => ({
      id: oldAssignedId,
      order: R.compose(
        R.prop("order"),
        R.find(R.propEq("id", oldAssignedId))
      )(assignedItems)
    }))
  )(oldAssignedIds);

  const getMaxOrder = R.compose(
    R.reduce((max, curr) => (max > curr ? max : curr), 0),
    R.map(R.prop("order"))
  )(oldAssignedItems);

  return [
    ...oldAssignedItems,
    ...newAssignedIds.map((id, idx) => ({
      id,
      order: getMaxOrder + 1 + idx
    }))
  ];
};
