import React from "react";
import { Div, CloseIcon } from "components/Base";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import Video from "./Video";
import moment from "moment";

const decorator = connect(
  (state, props) => ({
    videoModal: getters.videoModal(state, props)
  }),
  {
    hideVideoModal: actions.hideVideoModal
  }
);

const VideoModal = ({ videoModal, hideVideoModal }) => (
  <MiniModalWrapper
    showModal={videoModal.show}
    isPortal={true}
    stopPropagation
    showHeader={false}
    style={{
      overflow: "initial !important",
      width: "90%",
      maxWidth: "700px",
      marginBottom: "100px"
    }}
  >
    {videoModal.show && (
      <>
        <Div
          pt={1}
          pb={2}
          display="row.flex-end.center"
          onClick={hideVideoModal}
        >
          <Div
            uppercase
            style={{
              color: "#FFFFFF",
              fontFamily: "Roboto Condensed",
              fontSize: "14px",
              letterSpacing: "0px",
              marginRight: "5px"
            }}
          >
            close
          </Div>
          <CloseIcon color="white" size={25} />
        </Div>
        <Video url={videoModal.data.video_url} playing />
        <Div
          p={7}
          bg="white"
          display="column.space-between.stretch"
          style={{
            padding: "30px 36px"
          }}
        >
          <Div
            display="row.space-between.center"
            style={{
              marginBottom: "16px"
            }}
          >
            <Div
              uppercase
              style={{
                color: "#686868",
                fontFamily: "Roboto Condensed",
                fontSize: "14px",
                letterSpacing: 0
              }}
            >
              {/* shot replay */}
              video
            </Div>
            <Div
              uppercase
              style={{
                color: "#686868",
                fontFamily: "Roboto Condensed",
                fontSize: "14px",
                letterSpacing: 0
              }}
            >
              {moment(videoModal.data.date).fromNow()}
            </Div>
          </Div>
          <Div display="row.space-between.center">
            <Div>
              <Div fw={4} fs={5} color="black">
                {videoModal.data.name}
              </Div>
              <Div
                style={{
                  color: "#222222",
                  fontFamily: "Roboto",
                  fontSize: "18px",
                  letterSpacing: 0
                }}
              >
                {videoModal.data.label}
              </Div>
            </Div>
            <Div
              uppercase
              style={{
                color: "#222222",
                fontFamily: "Roboto",
                fontSize: "18px",
                letterSpacing: "0px"
              }}
            >
              {videoModal.data.score && videoModal.data.score.length
                ? videoModal.data.score
                : ""}
            </Div>
          </Div>
        </Div>
      </>
    )}
  </MiniModalWrapper>
);

export default decorator(VideoModal);
