import { put, takeEvery, all, fork, select, call } from "redux-saga/effects";
import { actions } from "./model";
import { getCredentials } from "redux/modules/user/selectors";
import * as R from "ramda";
import { userId as getUserId } from "redux/modules/user/selectors";
import { actions as confirmationActions } from "Portal/Confirmation/model";
import { actions as cartActions } from "Portal/Cart/model";
import { push } from "react-router-redux";
import { registerError } from "redux/modules/errors/actions";
import { portalUser as getPortalUser } from "redux/modules/portal/user/selectors";

import Api from "./api";

const init = function*({ payload }) {
  yield put(actions.setInitialData(payload));
};

// eslint-disable-next-line consistent-return
const submitFormV2 = function*({ payload: { total, items, params } }) {
  const credentials = yield select(getCredentials);

  let stripeTokenId = null;
  const userId = yield select(getUserId);
  const portalUser = yield select(getPortalUser);
  const isViewingAsAccount = R.pathEq(["active_view", "type"], "account")(
    portalUser
  );
  const accountId = isViewingAsAccount
    ? R.path(["active_view", "id"], portalUser)
    : null;

  const data = {
    total: total,
    stripeTokenId,
    submittingUserId: userId,
    submittingGroupId: accountId,
    lineItems: items
  };

  try {
    const {
      payload: { order_number }
    } = yield call(Api.postOrderData, {
      credentials,
      pageId: params.pageId,
      data
    });

    yield all([
      put(actions.setSaving(false)),
      put(confirmationActions.setRequestId(order_number)),
      put(cartActions.clearItemsInCart()),
      put(
        push(
          `/hubs/${params.portalSlug}/${params.portalId}/people/${params.recordTypeId}/requests/confirmation`
        )
      )
    ]);
  } catch (error) {
    yield all([
      put(actions.setSaving(false)),
      put(
        registerError([
          {
            system: error,
            user: "An error occurred processing your order"
          }
        ])
      )
    ]);
  }
};

// eslint-disable-next-line consistent-return
/*
const submitForm = function*({ payload: { stripe, total, items, params } }) {
  const credentials = yield select(getCredentials);
  const fields = yield select(getters.fields);

  const paymentIsEnabled = yield select(getters.paymentsEnabled);

  const fieldsValue = yield select(FormGetters.fields, {
    instanceId: FORM_INSTANCE_ID
  });

  const invalidIds = R.compose(
    R.map(R.prop("id")),
    R.filter(
      ({ id, required, type }) =>
        required &&
        R.compose(
          value => !isValidValue({ value }, type),
          R.pathOr("", [id, "value"])
        )(fieldsValue)
    ),
    R.reject(R.propEq("type", "section"))
  )(fields);

  const emailValue = fieldsValue[STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL];
  const email = emailValue ? emailValue.value.trim().toLowerCase() : null;

  if (!email || !Helpers.isValidEmail(email)) {
    yield put(actions.setError("Please enter a correct email address"));
    invalidIds.push(STANDARD_MODULE_FIELD_IDS.CONTACTS.EMAIL);
  }

  yield put(actions.setInvalidIds(R.uniq(invalidIds)));

  if (invalidIds.length) {
    yield put(actions.setSaving(false));
    return false;
  }

  let stripeTokenId = null;
  if (paymentIsEnabled && total > 0) {
    const { token, error } = yield call(stripe.createToken, {});

    if (error) {
      yield all([
        put(actions.setError(error.message || DEFAULT_STRIPE_MESSAGE)),
        put(actions.setSaving(false))
      ]);
      return false;
    } else if (!token || !token.id) {
      yield all([
        put(actions.setError(DEFAULT_STRIPE_MESSAGE)),
        put(actions.setSaving(false))
      ]);
      return false;
    }

    stripeTokenId = token.id;
  }

  const splitByUnderscore = R.split(" ");
  let lastKey = "";

  const valuesToSend = R.reduce(
    (acc, curr) => {
      if (curr.type === "section") {
        lastKey = R.toLower(R.head(splitByUnderscore(curr.name))) + "Values";
        acc[lastKey] = {};
      } else if (fieldsValue[curr.id]) {
        acc[lastKey][curr.id] = fieldsValue[curr.id].value;
      }
      return acc;
    },
    {},
    fields
  );

  const userId = yield select(getUserId);
  const portalUser = yield select(getPortalUser);
  const isViewingAsAccount = R.pathEq(["active_view", "type"], "account")(
    portalUser
  );
  const accountId = isViewingAsAccount
    ? R.path(["active_view", "id"], portalUser)
    : null;

  const data = {
    total: total,
    stripeTokenId,
    submittingUserId: userId,
    submittingGroupId: accountId,
    lineItems: items,
    ...valuesToSend
  };

  try {
    const {
      payload: { order_number }
    } = yield call(Api.postOrderData, {
      credentials,
      pageId: params.pageId,
      data
    });

    yield all([
      put(actions.setSaving(false)),
      put(confirmationActions.setRequestId(order_number)),
      put(cartActions.clearItemsInCart()),
      put(
        push(
          `/hubs/${params.portalSlug}/${params.portalId}/people/${params.recordTypeId}/requests/confirmation`
        )
      )
    ]);
  } catch (error) {
    yield all([
      put(actions.setSaving(false)),
      put(
        registerError([
          {
            system: error,
            user: "An error occurred during the payment"
          }
        ])
      )
    ]);
  }
};
*/

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchSubmitForm = function*() {
  yield takeEvery(actions.submitForm.type, submitFormV2);
};

const rootSaga = function*() {
  yield all([fork(watchInit), fork(watchSubmitForm)]);
};

export default rootSaga;
