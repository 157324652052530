import PropTypes from "prop-types";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import React, { Component } from "react";
import { Link } from "react-router";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import PillButton from "components/Global/CRM/PillButton";
import basePath from "utils/org-event-base-path";

import MoreVertIcon from "material-ui/svg-icons/navigation/more-vert";
import IconButton from "material-ui/IconButton";
import Menu from "components/Global/CRM/Popovers/AccountRelationshipMenu";
import AccountTypeIcon from "components/Global/CRM/AccountTypeIcon";
import CanUserDo from "components/Global/Security/CanUserDo";

@CSSModules(css)
class ContactAccount extends Component {
  @autobind
  removeRelationship() {
    this.props.onRemove({
      accountId: this.props.account_id,
      relationshipId: this.props.relationship_id
    });
  }

  @autobind
  editRelationship() {
    const { role, type, name, relationship_id } = this.props;
    this.props.onEdit({ role, type, name, relationship_id });
  }

  render() {
    const {
      name,
      type,
      params,
      account_id,
      canViewAccounts,
      readOnly,
      role
    } = this.props;

    return (
      <div styleName="contactContainer">
        {!readOnly && (
          <div styleName="iconWrapper">
            <Menu
              onEdit={this.editRelationship}
              onRemove={this.removeRelationship}
            >
              <IconButton>
                <MoreVertIcon />
              </IconButton>
            </Menu>
          </div>
        )}
        <div styleName="accountTypeIconWrapper">
          <AccountTypeIcon
            {...type}
            backgroundColor={type.background_color}
            color={type.text_color}
          />
        </div>
        <div>
          <Link
            to={
              canViewAccounts
                ? `${basePath(params)}/account/${account_id}`
                : null
            }
            className={css.name}
          >
            {name}
          </Link>
          <div styleName="title">{role}</div>
        </div>
        <div styleName="preview">
          <CanUserDo action={`${STANDARD_MODULES.accounts.id}_read`}>
            <PillButton
              title="Preview"
              linkTo={`${basePath(params)}/account/${account_id}`}
            />
          </CanUserDo>
        </div>
      </div>
    );
  }
}

ContactAccount.propTypes = {
  account_id: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  relationship_id: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  styles: PropTypes.object.isRequired,
  type: PropTypes.object.isRequired
};

export default ContactAccount;
