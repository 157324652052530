import React from "react";
import { connect } from "react-redux";
import { Div, Stepper, CloseIcon } from "components/Base";
import { withProps } from "utils/General";
import { getStepIndex } from "Forms/WizardModal/selectors";

const decorate = connect(state => ({
  setIndex: getStepIndex(state)
}));

const NeutralCloseIcon = withProps({
  color: { default: "neutral5", hover: "danger7" }
})(CloseIcon);

const Header = ({
  handlers: { hideModal },
  setIndex,
  steps = ["Step 1", "Step 2", "Step 3"]
}) => (
  <Div
    px={7}
    display="row.flex-start.center"
    color="neutral4"
    height={67}
    style={{
      flexShrink: 0
    }}
    bg="neutral0"
  >
    <Div display="row.center" width={1}>
      <Stepper steps={steps} width={550} activeStepIndex={setIndex} />
    </Div>
    <NeutralCloseIcon size={30} onClick={hideModal} />
  </Div>
);

export default decorate(Header);
