import React from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { actions } from "./model";
import { ACTION_TYPES } from "./constants";

import { Div } from "components/Base";

import styleSpects from "components/Base/Base/styleSpecs";
import { noop } from "utils/General";

import MenuPopover from "ui-kit/MenuPopover";
import DeleteConfirmation from "ui-kit/DeleteConfirmation";

const Header = ({ children, showBorder }) => (
  <Div
    uppercase
    color="black"
    fs={0}
    px={3}
    pt={showBorder ? 3 : 2}
    mt={showBorder ? 1 : 0}
    fw={3}
    mb={children ? 1 : null}
    bt={showBorder ? 1 : 0}
    bc="gray3"
  >
    {children}
  </Div>
);

const MenuItem = ({
  leftIcon = "",
  rightIcon = "",
  children,
  onClick = noop,
  style = {}
}) => (
  <Div
    fs={2}
    px={3}
    py={2}
    onClick={onClick}
    display="row.flex-start.center"
    bg={{
      hover: "neutral0",
      default: "white"
    }}
    color="black"
    {...style}
  >
    {leftIcon ? (
      <Div display="row.flex-start.center" width={25}>
        <i
          className="material-icons"
          style={{ color: "black", fontSize: styleSpects.fontSize[3] }}
        >
          {leftIcon}
        </i>
      </Div>
    ) : (
      <Div width={10} />
    )}
    {rightIcon ? (
      <Div display="row.space-between.center" flex={1}>
        {children}
        <i
          className="material-icons"
          style={{ color: "black", fontSize: styleSpects.fontSize[3] }}
        >
          {rightIcon}
        </i>
      </Div>
    ) : (
      children
    )}
  </Div>
);

const decorate = connect(
  null,
  bindInstance({
    execute: actions.executeAction
  })
);

/* another prop is rowActionsType = GROUPING_ACTIONS for getting actions for group-header */
const Actions = ({
  actions = [],
  execute = noop,
  data = {},
  children,
  onClickItem = noop,
  ...styleProps
}) => (
  <MenuPopover Label={children}>
    {({ closePopover }) => (
      <Div bra={3} bg="white" width={200} py={2} {...styleProps}>
        {actions.map((action, idx) => {
          switch (action.type) {
            case ACTION_TYPES.HEADER:
              return (
                <Header showBorder={idx !== 0} key={idx}>
                  {action.name}
                </Header>
              );
            case ACTION_TYPES.SEPARATOR:
              return (
                <Div
                  style={{ height: 1, background: "#ddd", margin: "5px 0px" }}
                  width={1}
                  key={idx}
                />
              );
            case ACTION_TYPES.SUBMENU:
              return (
                <Actions
                  actions={action.submenu}
                  onClickItem={closePopover}
                  execute={execute}
                  data={data}
                  key={idx}
                  style={{ padding: 0 }}
                >
                  <MenuItem
                    leftIcon={action.leftIcon}
                    rightIcon="keyboard_arrow_right"
                    onClick={() => {
                      execute({ data, actionId: action.id });
                      closePopover();
                    }}
                  >
                    {action.name}
                  </MenuItem>
                </Actions>
              );
            default:
              const onClick = () => {
                execute({ data, actionId: action.id });
                onClickItem(action.id);
                closePopover();
              };
              const item = (
                <MenuItem
                  key={action.id}
                  leftIcon={action.leftIcon}
                  rightIcon={action.rightIcon}
                  onClick={onClick}
                  style={action.style}
                >
                  {action.name}
                </MenuItem>
              );
              if (action.confirm) {
                return (
                  <DeleteConfirmation
                    text={action.confirm}
                    confirmLabel={action.confirmLabel}
                    onConfirm={onClick}
                  >
                    {item}
                  </DeleteConfirmation>
                );
              }
              return item;
          }
        })}
      </Div>
    )}
  </MenuPopover>
);

export default decorate(Actions);
