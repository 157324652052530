import React, { Component } from "react";
import { compose, map, filter, difference } from "ramda";
import View from "./View";
import { LABELS } from "utils/item-types";
import AddItemBlockModal from "components/Event/Settings/Credentials/Modals/AddItemBlock";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

class AssignBlocksController extends Component {
  state = {
    loading: true,
    selected: [],
    searchTerm: "",
    existing: this.props.existingAssignmentIds
  };

  componentDidMount() {
    this.props.getItemBlocks(this.props.eventId).then(() => {
      this.setState({ loading: false });
    });
  }

  /*
  componentWillReceiveProps(newProps) {
    if (newProps.existingAssignmentIds !== this.props.existingAssignmentIds) {
      this.setState({ existing: newProps.existingAssignmentIds });
    }
  }
  */

  handleSearchChange = searchTerm => {
    return this.setState({ searchTerm });
  };

  filterList = list => {
    if (this.state.searchTerm.length) {
      return list.filter(
        i =>
          i.name.toLowerCase().indexOf(this.state.searchTerm.toLowerCase()) > -1
      );
    }
    return list;
  };

  removeSelected = id => {
    return this.setState({
      selected: this.state.selected.filter(s => s !== id)
    });
  };

  addSelected = id => {
    return this.setState({ selected: [...this.state.selected, id] });
  };

  toggleSelected = id => {
    if (this.props.existingAssignmentIds.includes(id)) {
      if (this.state.existing.includes(id)) {
        return this.removeExisting(id);
      }
      return this.addExisting(id);
    }
    if (this.state.selected.includes(id)) {
      return this.removeSelected(id);
    }
    return this.addSelected(id);
  };

  removeExisting = id => {
    return this.setState({
      existing: this.state.existing.filter(e => e !== id)
    });
  };

  addExisting = id => {
    return this.setState({
      existing: [...this.state.existing, id]
    });
  };

  handleSave = () => {
    this.props.onSave({
      blocksToAdd: this.state.selected,
      blocksToRemove: difference(
        this.props.existingAssignmentIds,
        this.state.existing
      )
    });
    this.props.hideModal();
  };

  showAddItemBlockModal = () => {
    this.props.showModal({
      content: (
        <AddItemBlockModal
          onDone={async id => {
            await this.props.getItemBlocks(this.props.eventId);

            if (id) {
              this.addSelected(id);
            }
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const { itemBlocks, hideModal, existingAssignmentIds } = this.props;
    const { loading } = this.state;

    const blockMapper = i => ({
      id: i.id,
      onSelect: () => this.toggleSelected(i.id),
      selected:
        this.state.existing.includes(i.id) ||
        this.state.selected.includes(i.id),
      name: i.name,
      description: i.description,
      fields: [
        { name: "item type", value: LABELS[i.item_type_id].label },
        { name: "# of items", value: i.items.length }
      ]
    });

    const viewProps = {
      loading,
      hideModal,
      onSave: this.handleSave,
      showAddItemBlockModal: this.showAddItemBlockModal,
      existing: compose(
        map(i => ({
          ...blockMapper(i),
          selected: this.state.existing.includes(i.id),
          onSelect: () => this.toggleSelected(i.id)
        })),
        filter(i => existingAssignmentIds.includes(i.id))
      )(itemBlocks),
      selected: compose(
        map(i => ({
          ...blockMapper(i),
          onSelect: () => this.removeSelected(i.id)
        })),
        filter(i => this.state.selected.includes(i.id))
      )(itemBlocks),
      list: compose(
        this.filterList,
        map(blockMapper)
      )(itemBlocks),
      onSearchChange: this.handleSearchChange
    };

    return <View {...viewProps} />;
  }
}

AssignBlocksController.propTypes = {};

export default AssignBlocksController;
