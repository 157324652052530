import { createSelector } from "reselect";
import * as R from "ramda";

import { getName } from "Passes/Common/utils";

import { users } from "redux/modules/eventUsers/selectors";
import { forms } from "redux/modules/formsV2/forms/selectors";

import { CREATED_VIA_PORTAL, CREATED_VIA_GLM } from "Passes/Common/constants";

export const getUserFilters = createSelector(users, userTypes => {
  const format = R.compose(
    R.sortBy(R.prop("name")),
    R.map(user => ({
      ...user,
      id: user.user_id,
      name: `${getName(user.user_fname, user.user_lname)} (${user.user_email})`
    }))
  );

  return [
    {
      id: "admins",
      name: "EVENT ADMINS",
      items: format(userTypes.filter(u => u.is_event_user))
    },
    {
      id: "portal",
      name: "PORTAL USERS",
      items: format(userTypes.filter(u => !u.is_event_user))
    }
  ];
});

export const getSourceFilters = createSelector(forms, formTypes => [
  {
    id: "forms",
    name: "FORMS",
    items: R.sortBy(
      R.prop("name"),
      R.filter(
        R.compose(
          R.not,
          R.isEmpty,
          R.prop("name")
        ),
        formTypes
      )
    )
  },
  {
    id: "other",
    name: "OTHER",
    items: [
      {
        id: CREATED_VIA_PORTAL,
        name: "Created via Portal"
      },
      {
        id: CREATED_VIA_GLM,
        name: "Created via Guest List Manager"
      }
    ]
  }
]);
