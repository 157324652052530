import React from "react";
import { connect } from "react-redux";

import { getters } from "../index";
import { EVENT_VIEWS } from "../constants";

import GridLayout from "./GridLayout";
import CalendarLayout from "./CalendarLayout";

const decorate = connect((state, props) => ({
  selectedView: getters.selectedView(state, props)
}));

const views = {
  [EVENT_VIEWS.GRID]: GridLayout,
  [EVENT_VIEWS.CALENDAR]: CalendarLayout
};

const Body = ({ selectedView, handlers }) =>
  React.createElement(views[selectedView] || "div", {
    handlers,
    bg: "primary8",
    display: "column.flex-start.stretch",
    flex: 1,
    pl: 2,
    pt: 2,
    pb: 2,
    pr: 4,
    style: { overflow: "hidden" }
  });

export default decorate(Body);
