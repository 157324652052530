import React from "react";
import * as R from "ramda";
import ScrollableModalBody from "components/Global/Modal/Layout/ScrollableBody";
import { CREDENTIAL_TYPE_ID, MEAL_TYPE_ID } from "utils/item-types";

import { Div } from "components/Base";
import Credentials from "./Credentials";
import Catering from "./Catering";

const Items = ({
  activeType,
  selectAll,
  removeAll,
  onSearchChange,
  searchTerm,
  cateringMealsPerDay,
  eventDateGroups
}) => (
  <Div>
    <ScrollableModalBody
      style={{
        minHeight: 400,
        display: "flex",
        backgroundColor: "rgba(224, 224, 224, 0.19)"
      }}
    >
      <Div display="column" width={1}>
        <Div display="row.flex-start.center" mt={6} mb={3} px={6}>
          <Div fw={3} onClick={selectAll} color="purple8" fs={2}>
            Select all items
          </Div>
          <Div color="purple8" mx={2}>
            &middot;
          </Div>
          <Div fw={3} onClick={removeAll} color="purple8" fs={2}>
            Remove all items
          </Div>
        </Div>

        <Div px={6} flex={1} style={{ width: "100%" }}>
          {R.prop(activeType.id, {
            [CREDENTIAL_TYPE_ID]: (
              <Credentials
                activeType={activeType}
                onSearchChange={onSearchChange}
                searchTerm={searchTerm}
              />
            ),
            [MEAL_TYPE_ID]: (
              <Catering
                activeType={activeType}
                cateringMealsPerDay={cateringMealsPerDay}
                eventDateGroups={eventDateGroups}
              />
            )
          })}
        </Div>
      </Div>
    </ScrollableModalBody>
  </Div>
);

export default Items;
