import React, { Component } from "react";
import moment from "moment";
import * as R from "ramda";
import { withState, toClass, capitalize, addS } from "utils/General";

import { CREDENTIAL_TYPE_ID } from "utils/item-types";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as SEND_TO_OPTION_TYPES from "SendEmailModal/utils/send-to-option-types";
import { ASSIGNMENT_MANAGER } from "utils/Emails/default-emails";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import AddLineItemsModal from "Orders/AddLineItemsModal";
import CreateOrderModal from "Orders/CreateOrderModal";
import {
  Div,
  MediumNumberSelector,
  Text2,
  Popover,
  MediumFilledButton,
  EditIcon
} from "components/Base";
import { AllocationsModal } from "Passes/GuestLists/Items";
import SwapItemsModal from "Orders/BulkEditLineItemsModal/SwapItemsModal";
import ViewOrderModal from "Orders/OrderModal/View";
import View from "./View";
import SendEmailModal from "SendEmailModal/View";

const liftToArr = val => (Array.isArray(val) ? val : [val]);

const getReviewFormatting = ({
  type,
  count,
  shareAssignmentManagerLink,
  goToOrdersTab
}) => {
  switch (type) {
    case "need_assignment":
      return {
        frontText: `${count} order${addS(count)}`,
        backText: `still require${count === 1 ? "s" : ""} name assignment`,
        buttons: [
          {
            text: "Share Link",
            onClick: shareAssignmentManagerLink
          },
          {
            text: "Add Names",
            onClick: goToOrdersTab
          }
        ]
      };
    case "review":
      return {
        frontText: `${count} request${addS(count)}`,
        backText: `need${count === 1 ? "s" : ""} to be reviewed`,
        buttons: [
          {
            text: "View Orders",
            onClick: goToOrdersTab
          }
        ]
      };
    default:
      return {};
  }
};

const decorateConditionWithAssignee = (condition, variant) => {
  if (
    R.compose(
      R.equals("account"),
      R.path(["assigned_to", "type"])
    )(variant)
  ) {
    condition.orderAccountId = variant.assigned_to.id;
  }
  return condition;
};

const Picker = withState("value", "setValue", ({ defaultValue }) => ({
  saving: false,
  count: defaultValue
}))(
  ({
    value,
    setValue,
    onSave,
    closePopover,
    maxValue,
    valueLabel,
    saveLabel
  }) => (
    <Div bra={1} px={6} py={4} display="column.center.flex-start">
      <Div>
        <Text2 color="neutral7" bold mb={1}>
          {valueLabel}
        </Text2>
        <MediumNumberSelector
          onChangeValue={count =>
            setValue({
              ...value,
              count
            })
          }
          value={value.count}
          maxValue={maxValue}
          WrapperProps={{
            width: 140,
            mb: 1
          }}
        />
      </Div>
      <MediumFilledButton
        my={1}
        bg="altB5"
        disabled={value.saving}
        onClick={async () => {
          setValue({
            ...value,
            saving: true
          });
          await onSave(value.count);
          closePopover();
        }}
      >
        {value.saving ? "Saving..." : saveLabel}
      </MediumFilledButton>
    </Div>
  )
);

const ValueCell = toClass(({ children, onClick }) => (
  <Div
    bra={1}
    bg={onClick ? { default: "gray1", hover: "gray2" } : "inherit"}
    fs={2}
    fw={3}
    height={42}
    display="row.flex-start.center"
    truncate
    px={2}
    mr={2}
    onClick={onClick}
    width={1}
  >
    {onClick ? (
      <Div display="row.space-between.center" width={1}>
        <Div truncate width={1}>
          {children}
        </Div>
        <EditIcon
          color="blue6"
          sizeWFS={2}
          ml={2}
          style={{
            flexShrink: 0
          }}
        />
      </Div>
    ) : (
      children
    )}
  </Div>
));

const HeaderCell = toClass(({ children, ...props }) => (
  <Div
    pl={2}
    fs={2}
    fw={3}
    height={30}
    uppercase
    display="row.flex-start.center"
    {...props}
  >
    {children}
  </Div>
));

const COLUMNS = [
  {
    id: "name",
    Header: props => {
      const name = R.path(["data", 0, "name", "type_name"])(props);
      return (
        <HeaderCell uppercase={name ? false : true}>
          {name || "Name"}
        </HeaderCell>
      );
    },
    accessor: ({
      editable,
      type_name,
      item_name,
      variant_title,
      count,
      onSaveSwapQuantity
    }) => ({
      editable,
      type_name,
      item_name,
      variant_title,
      count,
      onSaveSwapQuantity
    }),
    Cell: ({ value }) =>
      value.editable ? (
        <Popover
          wrapperProps={{
            width: 1
          }}
          innerSpanStyle={{
            width: "100%"
          }}
          Label={({ onClick }) => (
            <ValueCell onClick={onClick}>
              <Div display="column.center.flex-start">
                <Div>{value.item_name}</Div>
                {value.variant_title && value.variant_title.length ? (
                  <Div fs={1} fw={1}>
                    {value.variant_title}
                  </Div>
                ) : null}
              </Div>
            </ValueCell>
          )}
        >
          {({ closePopover }) => (
            <Picker
              valueLabel="How many should change?"
              saveLabel={"Change type to..."}
              defaultValue={value.count}
              minValue={0}
              maxValue={value.count}
              closePopover={closePopover}
              onSave={value.onSaveSwapQuantity}
            />
          )}
        </Popover>
      ) : (
        <ValueCell>
          <Div display="column.center.flex-start">
            <Div>{value.item_name}</Div>
            {value.variant_title && value.variant_title.length ? (
              <Div fs={1} fw={1}>
                {value.variant_title}
              </Div>
            ) : null}
          </Div>
        </ValueCell>
      ),
    width: 340
  },
  {
    id: "qty",
    Header: <HeaderCell>QTY</HeaderCell>,
    accessor: ({ editable, count, onSaveQuantity }) => ({
      editable,
      count,
      onSaveQuantity
    }),
    Cell: ({ value }) =>
      value.editable ? (
        <Popover
          wrapperProps={{
            width: 1
          }}
          innerSpanStyle={{
            width: "100%"
          }}
          Label={({ onClick }) => (
            <ValueCell onClick={onClick}>{value.count}</ValueCell>
          )}
        >
          {({ closePopover }) => (
            <Picker
              valueLabel="Change QTY to:"
              saveLabel="Save new quantity"
              defaultValue={value.count}
              maxValue={undefined}
              closePopover={closePopover}
              onSave={value.onSaveQuantity}
            />
          )}
        </Popover>
      ) : (
        <ValueCell>{value.count}</ValueCell>
      ),
    width: 90
  },
  {
    id: "assigned",
    Header: <HeaderCell>Assigned to</HeaderCell>,
    accessor: ({ customer }) => (customer ? customer.name : ""),
    Cell: ({ value }) => <ValueCell>{value}</ValueCell>,
    width: 180
  }
  /*
  // @NOTE: Temp. Removing until removal is clarified
  {
    id: "delete",
    Header: <div />,
    accessor: ({ onSaveQuantity }) => ({ onSaveQuantity }),
    Cell: ({ value }) => (
      <Div
        bra={1}
        bg={{ default: "gray1", hover: "gray2" }}
        fs={2}
        fw={3}
        height={42}
        display="row.center.center"
        px={2}
        onClick={() => value.onSaveQuantity(0)}
        width={1}
        color="orange9"
      >
        <CloseIcon sizeWFS={3} />
      </Div>
    ),
    width: 52
  }
  */
];

class Controller extends Component {
  state = {
    loading: true,
    activeView: this.props.showOverview ? "overview" : "editable",
    addedLineItemIds: []
  };

  modified = false;

  componentDidMount() {
    this.getInformation().then(() => {
      return this.setState({ loading: false });
    });
  }

  componentWillUnmount() {
    if (this.modified && this.props.onDone) {
      this.props.onDone();
    }
  }

  flagAsModified = () => {
    this.modified = true;
  };

  getWhereStatement = () => {
    const where = this.props.where;
    if (this.props.where && this.props.where.lineItemId) {
      where.lineItemId = [
        ...liftToArr(this.props.where.lineItemId),
        ...this.state.addedLineItemIds
      ];
    }
    return where;
  };

  getInformation = () => {
    return Promise.all([
      this.getSummary(),
      this.getOrders(),
      this.fetchLineItems()
    ]);
  };

  getSummary = () => {
    if (!this.props.getOrderSummary) return true;

    return this.props.getOrderSummary({
      eventId: this.props.eventDetails.id,
      accountId: this.props.accountId,
      contactId: this.props.contactId,
      allocations: false
    });
  };

  getOrders = () => {
    if (!this.props.getOrders) return true;

    return this.props.getOrders({
      eventId: this.props.eventDetails.id,
      accountId: this.props.accountId,
      contactId: this.props.contactId
    });
  };

  fetchLineItems = () => {
    return this.props.prepareLineItems({
      actions: [
        {
          action: "prepare",
          where: this.getWhereStatement()
        }
      ]
    });
  };

  handleAction = async ({ action, set, by, where }) => {
    this.flagAsModified();

    const result = await this.props.updateLineItems({
      actions: [
        {
          action,
          set,
          by,
          where
        }
      ]
    });

    // if handling bulk line items + increasing quantity,
    // add the returned line items id to the array we're evaluating
    if (
      ["increase-quantity", "swap-items"].includes(action) &&
      this.props.where &&
      this.props.where.lineItemId
    ) {
      const addedLineItems = result[0];
      const addedLineItemIds =
        action === "swap-items"
          ? addedLineItems.created
          : addedLineItems.map(addedLineItem => addedLineItem.id);

      this.setState({
        addedLineItemIds: [...this.state.addedLineItemIds, ...addedLineItemIds]
      });
    }

    this.getInformation();
  };

  showCreateOrderModal = () => {
    const name = R.prop("name")(this.props.summary);
    const email = R.prop("email")(this.props.summary);
    this.props.showModal({
      content: (
        <CreateOrderModal
          orderType={this.props.where.orderAccountId ? "group" : "individual"}
          customerContactId={this.props.contactId}
          customerContactName={this.props.contactId ? name : undefined}
          customerContactEmail={this.props.contactId ? email : undefined}
          customerAccountId={this.props.accountId}
          customerAccountName={this.props.accountId ? name : undefined}
          onDone={() => {
            this.flagAsModified();
            this.getInformation();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showAddItemsToOrderModal = () => {
    this.props.showModal({
      content: (
        <AddLineItemsModal
          orderId={this.props.where.orderId}
          onDone={() => {
            this.flagAsModified();
            this.getInformation();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showAddAllocationsToAccountModal = () => {
    this.props.showModal({
      content: (
        <AllocationsModal
          hideModal={this.props.hideModal}
          itemTypeId={CREDENTIAL_TYPE_ID}
          onSave={() => {
            this.flagAsModified();
            this.getInformation();
          }}
          groupId={this.props.where.orderAccountId}
          groupName={this.props.accountName}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showSwapItemsModal = ({ variant, qty }) => {
    this.props.showModal({
      content: (
        <SwapItemsModal
          variantId={variant.variant_id}
          qty={qty}
          onSave={async newVariantId => {
            this.flagAsModified();

            const assignee = decorateConditionWithAssignee({}, variant);

            await this.handleAction({
              action: "swap-items",
              by: qty,
              set: {
                variantId: newVariantId
              },
              where: {
                ...this.getWhereStatement(),
                eventId: this.props.eventDetails.id,
                variantId: variant.variant_id,
                ...assignee
              }
            });

            this.getInformation();

            return true;
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showViewOrderModal = orderId => {
    this.props.showModal({
      content: (
        <ViewOrderModal
          orderId={orderId}
          onDone={() => {
            this.flagAsModified();
            this.getInformation();
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  shareAssignmentManagerLink = () => {
    this.props.showModal({
      content: (
        <SendEmailModal
          moduleId={STANDARD_MODULE_IDS.accounts.id}
          records={[this.props.accountId]}
          selectedOptions={[SEND_TO_OPTION_TYPES.ACCOUNT_CONTACT_USERS]}
          subject={ASSIGNMENT_MANAGER.subject}
          message={ASSIGNMENT_MANAGER.message}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const { loading, activeView } = this.state;
    const {
      lineItems,
      hideModal,
      canUserEditItemType,
      eventDetails
    } = this.props;
    const isViewingEditable = activeView === "editable";

    const formatLineItems = R.map(type => ({
      ...type,
      variants: R.map(variant => ({
        ...variant,
        type_name: type.name,
        editable: isViewingEditable && canUserEditItemType(type.id),
        onSaveQuantity: qty => {
          let condition = {
            ...this.getWhereStatement(),
            variantId: variant.variant_id,
            eventId: eventDetails.id
          };

          condition = decorateConditionWithAssignee(condition, variant);

          if (qty < variant.count) {
            return this.handleAction({
              action: "decrease-quantity",
              by: Math.abs(qty - variant.count),
              set: null,
              where: condition
            });
          } else if (qty > variant.count) {
            return this.handleAction({
              action: "increase-quantity",
              by: qty - variant.count,
              set: condition,
              where: {
                eventId: eventDetails.id
              }
            });
          }
          return true;
        },
        onSaveSwapQuantity: qty =>
          qty ? this.showSwapItemsModal({ variant, qty }) : null
      }))(type.variants)
    }));

    const data = R.path([0])(lineItems);
    const types = data
      ? formatLineItems(isViewingEditable ? data.editable : data.locked)
      : [];

    let tabs = [
      {
        tabName: "Editable",
        onClick: () => this.setState({ activeView: "editable" }),
        isActive: activeView === "editable",
        count: data ? data.count_of_editable : 0
      }
    ];
    if (data && data.count_of_locked) {
      tabs.push({
        tabName: "Locked",
        onClick: () => this.setState({ activeView: "locked" }),
        isActive: activeView === "locked",
        count: data ? data.count_of_locked : 0
      });
    }

    if (this.props.showOverview) {
      tabs.unshift({
        tabName: "Overview",
        onClick: () => this.setState({ activeView: "overview" }),
        isActive: activeView === "overview",
        count: undefined
      });
      tabs.push({
        tabName: "Orders",
        onClick: () => this.setState({ activeView: "orders" }),
        isActive: activeView === "orders",
        count: this.props.orders.length
      });
    }

    if (this.props.viaAllocations) {
      tabs.push({
        tabName: "Orders",
        onClick: () => this.setState({ activeView: "orders" }),
        isActive: activeView === "orders",
        count: this.props.orders.length
      });
    }

    // format summary & orders
    const filteredOrders = this.props.viaAllocations
      ? this.props.orders.filter(o => o.require_assignment || o.is_allocation)
      : this.props.orders;
    let formattedOrders = filteredOrders.map(order => {
      const tags = [];

      if (order.is_allocation) {
        tags.push("Allocation");
      }

      if (
        order.order_type === "group" &&
        (order.require_assignment || order.is_allocation)
      ) {
        tags.push("Assignment Required");
      }

      return {
        title:
          order.order_type === "individual"
            ? [
                R.path(["customer", "first_name"], order),
                R.path(["customer", "last_name"], order)
              ]
                .filter(v => v)
                .join(" ")
            : R.path(["customer", "account_name"], order),
        orderNumber: order.number,
        tags, // ['Paid', 'Partial Issue'],
        orderType: capitalize(order.order_type),
        source: [
          R.path(["created_by_user", "first_name"], order),
          R.path(["created_by_user", "last_name"], order)
        ].join(" "), // 'Guest Credentials',
        requestedBy: [
          R.path(["customer", "first_name"], order),
          R.path(["customer", "last_name"], order)
        ].join(" "), // 'Bryan Bangeter',
        pickup: [
          R.path(["pickup_information", "first_name"], order),
          R.path(["pickup_information", "last_name"], order)
        ].join(" "),
        orderDate: moment(order.created_at).format("M/DD/YYYY [at] h:mma"),
        onClick: () => this.showViewOrderModal(order.id)
      };
    });

    const summaryData = R.map(type => ({
      ...type,
      items: R.reduce((list, item) => {
        return [...list, ...item.items];
      }, [])(type.items)
    }))(this.props.summary.variants);

    return (
      <View
        {...{
          modalTitle: this.props.viaAllocations
            ? "Edit Allocations"
            : this.props.showOverview
            ? "Item Summary"
            : "Edit Order Items",
          modalSubtitle: R.prop("name")(this.props.summary),
          viaAllocations: this.props.viaAllocations,
          loading,
          activeView,
          countOfEditable: data ? data.count_of_editable : 0,
          countOfLocked: data ? data.count_of_locked : 0,
          types,
          hideModal,
          columns: COLUMNS,
          tabs,
          summaryData,
          orders: formattedOrders,
          countOfOrders: filteredOrders.length,
          viewingAsContact: Boolean(this.props.where.orderContactId),
          showAddItemsModal: this.props.viaAllocations
            ? this.showAddAllocationsToAccountModal
            : this.props.where.orderId
            ? this.showAddItemsToOrderModal
            : this.showCreateOrderModal,
          rowContents: this.props.summary.review.map(({ type, count }) => ({
            ...getReviewFormatting({
              type,
              count,
              shareAssignmentManagerLink: this.shareAssignmentManagerLink,
              goToOverviewTab: () => this.setState({ activeView: "overview" }),
              goToOrdersTab: () => this.setState({ activeView: "orders" })
            }),
            rowColor: "primary5"
          }))
        }}
      />
    );
  }
}

export default Controller;
