import { put, take } from "redux-saga/effects";

import { actions } from "./index";
import { actions as TableActions } from "ui-kit/Datagrid";

const watchSave = function*() {
  for (;;) {
    yield take(TableActions.save.type);
    yield put(actions.setShowTable(""));
  }
};
export default watchSave;
