import Helpers from "utils/Global/Helpers";

module.exports = {
  getByModuleAndStatus: (credentials, moduleId, status) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/modules/${moduleId}/records/status/${status}/count`,
        credentials,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */,

  getByModule: (credentials, moduleId) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/modules/${moduleId}/records/status/count`,
        credentials,
        success,
        error
      });
    })
  /* eslint-enable no-underscore-dangle */
};
