import { createSelector } from "reselect";
import * as R from "ramda";

import { getters } from "./index";

export const getFields = createSelector(
  getters.selectedFieldIds,
  getters.fields,
  getters.fieldsFilter,
  (selectedFieldIds, fields, filter) => {
    const filterToUse =
      filter && filter.length ? filter.toLowerCase().trim() : null;
    const results = fields
      .map(f => ({
        ...f,
        selected: selectedFieldIds.includes(f.id)
      }))
      .filter(f => !f.defaultSelected);
    if (filterToUse) {
      return R.sortBy(f => f.name.toLowerCase())(
        results.filter(f => f.name.toLowerCase().includes(filterToUse))
      );
    }
    return R.sortBy(f => f.name.toLowerCase())(results);
  }
);

export const getSelectedFields = createSelector(
  getters.selectedFieldIds,
  getters.requiredFieldIds,
  getters.defaultSelectedFieldIds,
  getters.defaultRequiredFieldIds,
  getters.fields,
  (
    selectedFieldIds,
    requiredFieldIds,
    defaultSelectedFieldIds,
    defaultRequiredFieldIds,
    fields
  ) => {
    return R.compose(
      R.filter(R.prop("id")),
      fieldIds =>
        fieldIds.map((fId, idx) => ({
          ...(fields.find(f => f.id === fId) || {}),
          order: idx,
          defaultSelected: defaultSelectedFieldIds.includes(fId),
          defaultRequired: defaultRequiredFieldIds.includes(fId),
          required: requiredFieldIds.includes(fId)
        }))
    )(R.uniq([...defaultSelectedFieldIds, ...selectedFieldIds]));
  }
);

export const getNonDefaultSelectedFields = createSelector(
  getSelectedFields,
  R.filter(f => !f.defaultSelected)
);

export const getDefaultSelectedFields = createSelector(
  getSelectedFields,
  R.filter(R.prop("defaultSelected"))
);
