import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "./constants";

const iniState = {
  title: "",
  backgroundImageUrl: "",
  courses: [],
  currentCourseId: null
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    executeAction: R.identity,
    updateCompletedStatus: ({ courses }, { payload: id }) => ({
      courses: R.map(
        R.when(R.propEq("id", id), R.evolve({ completed: R.not })),
        courses
      )
    }),
    setInitialData: (
      _,
      {
        payload: {
          details: { title, background_image_url: backgroundImageUrl, courses }
        }
      }
    ) => ({
      title,
      backgroundImageUrl,
      courses,
      currentCourseId: R.compose(
        R.prop("id"),
        R.head
      )(courses)
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
