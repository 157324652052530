import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const SidebarNavItem = ({
  hoverIcon,
  leftIcon,
  active = false,
  children,
  onClick,
  badgeText
}) => (
  <div
    className={active ? css.itemActive : css.item}
    onClick={active ? undefined : onClick}
  >
    {leftIcon ? <div styleName="leftIcon">{leftIcon}</div> : ""}
    <div styleName="children">{children}</div>
    {badgeText ? <div styleName="badge">{badgeText}</div> : ""}
    {hoverIcon ? (
      <div styleName="hoverIcon" style={active ? { opacity: 1 } : {}}>
        {hoverIcon}
      </div>
    ) : (
      ""
    )}
  </div>
);

SidebarNavItem.propTypes = {
  active: PropTypes.bool,
  count: PropTypes.number,
  hoverIcon: PropTypes.element,
  leftIcon: PropTypes.element,
  onClick: PropTypes.func
};

export default CSSModules(SidebarNavItem, css);
