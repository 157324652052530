import { connect } from "react-redux";
import TableView from "./TableView";

import * as FieldActions from "redux/modules/formsV2/form/fields/actions";
import { actions } from "Submission/Subform";

export default connect(
  null,
  { ...FieldActions, addTableId: actions.addTableId }
)(TableView);
