export const NAMESPACE = "ManageItems";

export const TABLE_INSTANCE_ID = "manage-items-table";
export const VIEWPICKER_INSTANCE_ID = "items-view-picker";

export const MANAGE_BY = {
  ALL_PASSES: "all-passes",
  ATTENDEE_LIST: "attendee-list",
  GROUP_AFFILIATION: "group-affiliation",
  ORDER_NUMBER: "order-numbers-list",
  INTEGRATION: "integration-orders",
  CATEGORY: "category",
  ITEM_TYPE: "item-type"
};

export const MAP_VIEW_TO_ROWS = {
  [MANAGE_BY.ALL_PASSES]: "allPassesRows",
  [MANAGE_BY.CATEGORY]: "categoryRows",
  [MANAGE_BY.ITEM_TYPE]: "itemTypeRows",
  [MANAGE_BY.GROUP_AFFILIATION]: "groupAffiliationRows",
  [MANAGE_BY.ATTENDEE_LIST]: "attendeeListRows",
  [MANAGE_BY.ORDER_NUMBER]: "orderNumberRows",
  [MANAGE_BY.INTEGRATION]: "orderNumberRowsIntegration"
};

export const MODES = {
  REVIEW: "review",
  ISSUANCE: "issuance",
  PRINT: "print"
};

export const ACTION_CELLS = {
  [MODES.REVIEW]: "actions",
  [MODES.ISSUANCE]: "issueActions",
  [MODES.PRINT]: "actions"
};

export const FILTERS = {
  PENDING: "pending",
  APPROVED: "approved",
  REJECTED: "rejected",
  PENDING_PICKUP: "pending-pickup",
  COMPLETED: "fulfilled",
  NO_STATUS: "no-status"
};

export const ALL_GROUPS = "allGroups";
export const EVERYONE = "everyone";

export const STATUS = {
  ALL: "all",
  PENDING_MY_REVIEW: "pending-my-review",
  PENDING_REVIEW: "pending-review",
  PENDING_PICKUP: "pending-pickup",
  PENDING_PHOTO: "pending-photo",
  PRINTED: "printed",
  NOT_PRINTED: "not-printed",
  APPROVED: "approved",
  FULFILLED: "fulfilled",
  NO_STATUS: "no-status",
  CONFIRMATION_NOT_SENT: "confirmation-not-sent",
  NOT_YET_ISSUED: "not-yet-issued",
  COMPLETED: "completed",
  DENIED: "denied",
  UNPAID: "unpaid",
  APPROVED_NOT_SYNCED: "approved-not-synced",
  INTEGRATION_ERROR: "integration-error",
  SYNCED: "synced"
};

export const TABLE_VIEW_ID = "order-mgm-table-view";

export const EMPTY_ACCOUNT_ID = "emptyAccount";
export const EMPTY_CATEGORY_ID = "emptyCategory";
export const EMPTY_ITEM_ID = "emptyItem";

export const SUMMARIES = {
  ACCOUNT: "account",
  CATEGORY: "category",
  ITEM_TYPE: "itemType"
};

export const CONTENT_TYPES = {
  XLSX: "xlsx",
  CSV: "csv"
};

export const ROW_ACTIONS = {
  PRINT_ITEMS: "row-printItems",
  UNDO_PRINT_ITEMS: "row-undoPrintItems",
  ISSUE_ITEMS: "row-issueItems",
  UNDO_CHECKIN: "row-undoCheckin",
  EDIT_ZONES: "edit-lineitem-zone"
};

export const UPDATE_ZONES_ACTION = "set-zones";
