import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as R from "ramda";
import { getters } from "Event/Home";
import { getUserName } from "Event/Home/selectors";
import {
  Div,
  Text3,
  Text4,
  CloseIcon,
  ClockIcon,
  collapsableHandler,
  Collapsable
} from "components/Base";
import Circle from "ui-kit/Circle";
import CardRow from "Event/MyApprovals/View/Common/CardRow";

const decorate = connect(state => ({
  data: getters.setupChecklists(state),
  userName: getUserName(state)
}));

const CardCollapsable = collapsableHandler(
  ({ pending, push, toggleCollapsed, collapsed }) => (
    <Div>
      <Div onClick={toggleCollapsed}>
        <CardRow
          total={pending.count_of_all_steps}
          missing={pending.count_of_remaining_steps}
          push={push}
          done={pending.count_of_completed_steps}
          url={pending.url}
          name={pending.name}
          children={pending.children}
          collapsed={collapsed}
          barText="Setup"
        />
      </Div>
      <Collapsable collapsed={collapsed}>
        <Div pl={8}>
          {R.map(
            child => (
              <CardRow
                key={child.id}
                total={child.count_of_all_steps}
                missing={child.count_of_remaining_steps}
                push={push}
                done={child.count_of_completed_steps}
                url={child.url}
                name={child.name}
                barText="Setup"
              />
            ),
            pending.children
          )}
        </Div>
      </Collapsable>
    </Div>
  )
);

const PendingCard = ({
  data,
  router: { push },
  params: { eventId },
  userName
}) => (
  <Div width={1} p={4} bra={1} bg="white" shadow={1}>
    <Div display="row.space-between.center">
      <Div display="row.flex-start.center">
        <Text3 bold color="black">
          {`Hey ${userName} -`}
        </Text3>
        <Text3 ml={1}>complete a few more steps in your event setup:</Text3>
      </Div>
      <CloseIcon
        size={30}
        style={{ cursor: "pointer" }}
        onClick={() => push(`/event-light/${eventId}/dashboard`)}
      />
    </Div>
    <Div mt={3}>
      <Div display="row.flex-start.center">
        <Circle size={36} bg="altB2">
          <ClockIcon color="altB6" size={24} />
        </Circle>
        <Text4 bold color="black" ml={2}>
          Continue Setup
        </Text4>
      </Div>
      <Div mt={2}>
        {R.map(
          pending =>
            !R.length(R.propOr([], "children", pending)) ? (
              <CardRow
                key={pending.id}
                total={pending.count_of_all_steps}
                missing={pending.count_of_remaining_steps}
                push={push}
                done={pending.count_of_completed_steps}
                url={pending.url}
                name={pending.name}
                barText="Setup"
              />
            ) : (
              <CardCollapsable
                key={pending.id}
                pending={pending}
                push={push}
                collapsedDefault
              />
            ),
          R.propOr([], "pending_setup", data)
        )}
      </Div>
    </Div>
  </Div>
);

export default R.compose(withRouter, decorate)(PendingCard);
