import React from "react";
import ReactDOM from "react-dom";
import { Menu } from "react-data-grid-addons";
const { ContextMenu, MenuItem } = Menu;

import { Div, FontIcon } from "components/Base";

class Portal extends React.Component {
  componentWillUnmount() {
    if (this.defaultNode) {
      document.body.removeChild(this.defaultNode);
    }
    this.defaultNode = null;
  }

  render() {
    if (!this.props.node && !this.defaultNode) {
      this.defaultNode = document.createElement("div");
      document.body.appendChild(this.defaultNode);
    }
    return ReactDOM.createPortal(
      this.props.children,
      this.props.node || this.defaultNode
    );
  }
}

const ContextMenuItems = ({ menu }) => (
  <Div
    bg="white"
    bra={1}
    shadow={3}
    width={170}
    style={{
      overflow: "hidden"
    }}
  >
    {menu.map((item, index) => {
      if (item.type === "separator") {
        return (
          <Div
            key={index}
            my={2}
            bg="gray5"
            style={{
              height: "1px"
            }}
          />
        );
      }

      return (
        <MenuItem
          key={index}
          onClick={(e, data) => {
            item.action(data, e);
          }}
        >
          <Div
            py={2}
            px={3}
            display="row.flex-start.center"
            bg={{
              default: "white",
              hover: "gray0"
            }}
            style={{
              cursor: "pointer"
            }}
          >
            <FontIcon color="gray7" mr={3} fs={3}>
              {item.icon}
            </FontIcon>
            <Div fs={2} color="gray7">
              {item.title}
            </Div>
          </Div>
        </MenuItem>
      );
    })}
  </Div>
);

const GridContextMenu = ({ id, menu }) => (
  <Portal>
    <ContextMenu id={id}>
      <ContextMenuItems menu={menu} />
    </ContextMenu>
  </Portal>
);

export default GridContextMenu;
