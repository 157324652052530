import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import External from "./External";
import { withRouter } from "react-router";

import { getItemBlock } from "redux/modules/items/item-block/actions";
import {
  updateItemBlock,
  deleteItemBlock
} from "redux/modules/items/item-blocks/actions";

import { bulkUpdateItemBlockVariants } from "redux/modules/items/item-block-variants/actions";

import { eventDetails } from "redux/modules/event/selectors";
import { itemBlock } from "redux/modules/items/item-block/selectors";

function mapStateToProps(state, props) {
  const itemBlockToPass = itemBlock(state, props.itemBlockId);
  return {
    itemBlock: itemBlockToPass,
    items: itemBlockToPass.items || [],
    eventDetails: eventDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      bulkUpdateItemBlockVariants,
      getItemBlock,
      updateItemBlock,
      deleteItemBlock
    },
    dispatch
  );
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(External)
);
