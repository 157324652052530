import Helpers from "utils/Global/Helpers";

export default {
  search: (eventId, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/orders/line-items/${
          data.grouping
        }`,
        qs: { eventId, ...data },
        success,
        error
      });
    }),
  getStats: eventId =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/orders/line-items/stats`,
        qs: { eventId },
        success,
        error
      });
    })
};
