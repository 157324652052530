import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE } from "./constants";

const iniState = {
  questionsFilter: "",
  loading: false,
  saving: false,
  questions: [],
  sets: [],
  itemQuestionsIds: [],
  setsIds: [],
  questionsAndSetsIds: [],
  variantIds: [],
  variantNames: []
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    cancelInstance: R.always(iniState),
    init: R.identity,
    refetchSets: R.identity,
    save: R.identity,
    refetchQuestions: R.identity,
    setInitialData: (
      state,
      {
        payload: {
          questions,
          sets,
          setsIds,
          itemQuestionsIds,
          variantIds,
          variantNames,
          questionsAndSetsIds
        }
      }
    ) => ({
      questions,
      sets,
      setsIds,
      itemQuestionsIds,
      variantIds,
      variantNames,
      questionsAndSetsIds
    }),
    toggleItemQuestion: (state, { payload: { isEnabled, id } }) => ({
      itemQuestionsIds: isEnabled
        ? R.filter(questionId => questionId !== id, state.itemQuestionsIds)
        : [...state.itemQuestionsIds, id],
      questionsAndSetsIds: isEnabled
        ? R.filter(questionId => questionId !== id, state.questionsAndSetsIds)
        : [...state.questionsAndSetsIds, id]
    }),
    toggleSet: (state, { payload: { isEnabled, id } }) => ({
      setsIds: isEnabled
        ? R.filter(setId => setId !== id, state.setsIds)
        : [...state.setsIds, id],
      questionsAndSetsIds: isEnabled
        ? R.filter(questionId => questionId !== id, state.questionsAndSetsIds)
        : [...state.questionsAndSetsIds, id]
    }),
    removeAllQuestionsAndSets: () => ({
      setsIds: [],
      itemQuestionsIds: [],
      questionsAndSetsIds: []
    }),
    removeSelectedId: (state, { payload: { id } }) => ({
      itemQuestionsIds: R.filter(
        questionId => questionId !== id,
        state.itemQuestionsIds
      ),
      setsIds: R.filter(questionId => questionId !== id, state.setsIds),
      questionsAndSetsIds: R.filter(
        questionId => questionId !== id,
        state.questionsAndSetsIds
      )
    }),
    reorderQuestionsAndSetsIds: (state, { payload: { seletedItems } }) => ({
      questionsAndSetsIds: R.map(({ id }) => id, seletedItems)
    })
  }
});

export default handlers;
