/* eslint-disable no-underscore-dangle */
import { put, takeEvery, all, fork, call, select } from "redux-saga/effects";
import { registerError } from "redux/modules/errors/actions";
import { actions, getters } from "../model";
import { actions as mediaContentModalActions } from "ui-kit/MediaContentModal/model";
import { MEDIA_CONTENT_ID } from "../constants";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { apiCall } from "App/Data/sagas";
import { actions as addContentModalActions } from "EventLight/Content/AddContentModal/model";
import { getters as MiniItemsGetters } from "ui-kit/MiniItemsSelector";
import { actions as addPageModalActions } from "Portal/Settings/AddPageModal/model";
import { getDataOrdered } from "../utils";

const getPages = function*({ payload: showLoading = true }) {
  try {
    if (showLoading) {
      yield put(actions.setLoadingPages(true));
    }
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    const [
      {
        payload: { pages }
      },
      { payload }
    ] = yield all([
      call(apiCall, {
        method: "get",
        url: `/sites/pages/event/${eventId}`
      }),
      call(apiCall, {
        method: "get",
        url: `/hub/admin/event/${eventId}/type/${recordTypeId}/pages`
      })
    ]);

    yield put(
      actions.setInitialPagesData({
        assignedPages: payload,
        pages
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error loading pages"
        }
      ])
    );
  } finally {
    yield put(actions.setLoadingPages(false));
  }
};

const reorderPages = function*({ payload: orderedItems }) {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/pages`,
      data: {
        items: orderedItems.map(({ page_id }, order) => ({
          id: page_id,
          order
        }))
      }
    });

    yield put(actions.getPages(false));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error loading pages"
        }
      ])
    );
  }
};

const updateAssignedPages = function*() {
  try {
    const eventId = yield select(getEventId);
    const assignedIds = yield select(MiniItemsGetters.selectedItems);
    const recordTypeId = yield select(getters.selectedTypeId);
    const assignedItems = yield select(getters.assignedPages);

    const items = getDataOrdered({
      assignedIds,
      assignedItems
    });

    yield call(apiCall, {
      method: "put",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/pages`,
      data: {
        items
      }
    });

    yield all([
      put(actions.getPages()),
      put(actions.setShowAssignModal(false))
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error updating assigned pages"
        }
      ])
    );
  }
};

const downloadShareFile = function*({ payload: url }) {
  yield call(window.open, url, "_blank");
};

const openContentModal = function*() {
  yield put(
    mediaContentModalActions.setShowModal(true, {
      meta: { instanceId: MEDIA_CONTENT_ID }
    })
  );
};

const removeAssinedPage = function*({ payload: fileId }) {
  try {
    const eventId = yield select(getEventId);
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "delete",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/page/${fileId}`
    });

    yield put(actions.getPages());
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error deleting pages"
        }
      ])
    );
  }
};

const updatePage = function*({ payload: { id } }) {
  yield put(addContentModalActions.showContentModal({ id }));
};

const closeAddPageModal = function*() {
  yield all([put(actions.getPages()), put(actions.closeAddPageModal())]);
};

const watchGetPages = function*() {
  yield takeEvery(actions.getPages.type, getPages);
};

const watchUpdateAssignedPages = function*() {
  yield takeEvery(actions.updateAssignedPages.type, updateAssignedPages);
};

const watchReorderPages = function*() {
  yield takeEvery(actions.reorderPages.type, reorderPages);
};

const watchDownloadShareFile = function*() {
  yield takeEvery(actions.downloadShareFile.type, downloadShareFile);
};

const watchOpenContentModal = function*() {
  yield takeEvery(actions.openContentModal.type, openContentModal);
};

const watchRemoveAssinedPage = function*() {
  yield takeEvery(actions.removeAssinedPage.type, removeAssinedPage);
};

const watchUpdatePage = function*() {
  yield takeEvery(actions.updatePage.type, updatePage);
};

const watchUpdateFile = function*() {
  yield takeEvery(addContentModalActions.save.type, getPages);
};

const watchCloseAddPageModal = function*() {
  yield takeEvery(addPageModalActions.closeModal.type, closeAddPageModal);
};

const rootSaga = function*() {
  yield all([
    fork(watchGetPages),
    fork(watchReorderPages),
    fork(watchDownloadShareFile),
    fork(watchOpenContentModal),
    fork(watchRemoveAssinedPage),
    fork(watchUpdatePage),
    fork(watchUpdateFile),
    fork(watchUpdateAssignedPages),
    fork(watchCloseAddPageModal)
  ]);
};

export default rootSaga;
