import { createModule } from "redux-mvc";

import model from "./model";
import sagas from "./sagas/index";
import table from "ui-kit/Table";
import pagination from "ui-kit/PaginationBar";
import actionComps from "PortalHub/ActionComponents";

const module = createModule({
  ...model,
  observedDomains: [
    "routing",
    "@flopflip",
    "Data",
    "activities",
    "event",
    "organization",
    "permissions",
    "portal",
    "records",
    "submission",
    "user"
  ]
});

module.setRootSaga(sagas);
module.plugModule(table);
module.plugModule(pagination);
module.plugModule(actionComps);

export default module;
