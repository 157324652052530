import React from "react";
import OpenRecordFormatter from "components/Global/Table3/CellFormatters/OpenRecord";

import { Div, OpenInNewIcon } from "components/Base";

const OpenRecordHeader = () => (
  <Div display="row.center.center">
    <OpenInNewIcon />
  </Div>
);

export default ({ hideModal, moduleId, eventId }) => ({
  id: "open-record",
  visible: true,
  width: 40,
  sortable: false,
  resizable: false,
  Header: () => <OpenRecordHeader />,
  Cell: ({ original }) => {
    return (
      <OpenRecordFormatter
        dependentValues={{
          ...original.values,
          meta: {
            rowId: original.id,
            moduleId,
            eventId
          },
          helpers: {
            hideModal
          }
        }}
      />
    );
  }
});
