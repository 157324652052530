import React from "react";
import { Div, LoadingIcon } from "components/Base";

const Loading = ({
  height = 1,
  width = 1,
  iconSize = 60,
  paddingVertical = 12
}) => (
  <Div
    height={height}
    width={width}
    display="row.center.center"
    py={paddingVertical}
  >
    <LoadingIcon color="gray3" size={iconSize} />
  </Div>
);

export default Loading;
