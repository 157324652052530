import { createSelector } from "reselect";

import * as R from "ramda";

import { getters } from "Portal/PortalPage/model";
import { getters as TabsGetters } from "ui-kit/Tabs";

export const currentVideo = createSelector(
  getters.featuredContent,
  R.find(R.propEq("featured", true))
);

export const sections = createSelector(
  getters.footerSections,
  R.indexBy(R.prop("name"))
);

export const getLeftSidebarSections = createSelector(
  getters.leftSidebarSections,
  R.map(({ name }) => ({
    id: R.toLower(name),
    title: name
  }))
);

export const parsedSections = createSelector(
  getters.leftSidebarSections,
  R.compose(
    R.map(
      R.cond([[R.propEq("type", "sessions"), R.identity], [R.T, R.identity]])
    ),
    R.sortBy(R.prop("id"))
  )
);

export const currentSection = createSelector(
  TabsGetters.selectedTab,
  parsedSections,
  (selectedTab, sections) =>
    R.find(session => selectedTab === R.toLower(session.name), sections) || {}
);
