import * as R from "ramda";
import React, { useMemo, useState } from "react";

import { noop } from "utils/General";

import {
  Div,
  MediumFilledButton,
  MediumClearButton,
  FontIcon
} from "components/Base";
import { FieldEditor } from "ui-kit/FieldTypes/View";

import { Row, Column, Label, WithFormInstanceProvider } from "ui-kit/Form/View";
import Popover from "@material-ui/core/Popover";
import { VARIANTS } from "ui-kit/Theme";

const decorate = WithFormInstanceProvider();

// eslint-disable-next-line no-underscore-dangle
const getWidth = R.propOr(250, R.__, {
  "attendee-photo": 374,
  textarea: 374,
  file: 374,
  "event-days": 328
});

const getHeight = ({ expand, column, row }) => {
  if (expand) {
    return 500;
  }
  const value = R.path([column.id, "value"], row);
  if (column.type === "textarea" && (R.isEmpty(value) || R.isNil(value))) {
    return { height: 120 };
  }
  return {};
};

const EditorPopover = ({
  column,
  row,
  anchorEl,
  onDone = noop,
  onCancel = noop,
  onClear = noop,
  instanceId = "",
  ...styleProps
}) => {
  const [expand, setExpand] = useState(false);
  const sublabel = useMemo(
    () =>
      R.propOr("", column.type, {
        datetime: R.path(["settings", "timezone"], column)
          ? `Timezone: ${R.path(["settings", "timezone"], column)}`
          : ""
      }),
    [column.type]
  );

  const height = useMemo(() => getHeight({ expand, column, row }), [expand]);

  return (
    <Popover
      open={true}
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      transformOrigin={{ horizontal: "left", vertical: "top" }}
      PaperProps={{
        style: {
          width: expand ? 670 : getWidth(column.type)
        }
      }}
      style={{ zIndex: 95 }}
      onEscapeKeyDown={onCancel}
      onBackdropClick={onCancel}
      disableScrollLock
      {...styleProps}
      disableEnforceFocus
    >
      <div style={{ padding: 8, minWidth: 250, position: "relative" }}>
        {R.pathEq(["settings", "clearable"], false)(column) ? null : (
          <FontIcon
            tooltip="Clear Value"
            onClick={onClear}
            color={{
              default: "gray5",
              hover: "gray7"
            }}
            fs={3}
            style={{ position: "absolute", right: 5, top: 5 }}
          >
            delete
          </FontIcon>
        )}
        {column.type === "textarea" && !expand ? (
          <FontIcon
            tooltip="Expand"
            onClick={() => setExpand(true)}
            color={{
              default: "gray5",
              hover: "gray7"
            }}
            fs={3}
            style={{ position: "absolute", right: 25, top: 5 }}
          >
            open_in_new
          </FontIcon>
        ) : null}
        <Column mt={1} {...height}>
          <Label variant={VARIANTS.BACKGROUND} mb={1}>
            {column.name}
          </Label>

          <FieldEditor field={column} data={row} instanceId={instanceId} />
          {sublabel ? (
            <Div fs={1} my={1} color="gray5">
              {sublabel}
            </Div>
          ) : null}
        </Column>
      </div>
      <Row px={2} pb={2} style={{ maxWidth: 250 }}>
        <MediumFilledButton bg="primary7" flex={1} onClick={onDone}>
          Done
        </MediumFilledButton>
        <MediumClearButton mr={1} flex={1} ml={1} onClick={onCancel}>
          Cancel
        </MediumClearButton>
      </Row>
    </Popover>
  );
};

export default decorate(EditorPopover);
