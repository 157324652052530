import { createModule } from "redux-mvc";

import handlers from "./handlers";
import validator from "ui-kit/Validator";

// Needs redux optimist in context

const module = createModule({
  ...handlers,
  optimist: true
});

module.plugModule(validator);

const { actions, getters } = module;

export default module;

export { actions, getters };
