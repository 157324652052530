import React from "react";
import { Div, Text5, CloseIcon } from "components/Base";
import { connect } from "react-redux";
import { actions } from "../model";
import { getSceneTitle } from "../selectors";

const decorate = connect(
  (state, props) => ({
    title: getSceneTitle(state, props)
  }),
  {
    hideModal: actions.closeModal
  }
);

const Header = ({ title = "", hideModal = () => {} }) => (
  <Div
    style={{
      flexShrink: 0
    }}
    display="row.space-between.center"
    bg="white"
    p={4}
    bb={1}
    bc="neutral2"
    brt={1}
  >
    <Text5 bold color="neutral8">
      {title}
    </Text5>

    <Div onClick={hideModal}>
      <CloseIcon color="neutral6" sizeWFS={6} />
    </Div>
  </Div>
);

export default decorate(Header);
