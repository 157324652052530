import React from "react";
import { get } from "lodash";
import PrimaryFieldFormatter from "components/Global/ReactTable/CellFormatters/PrimaryField";
import resolveFormatter from "components/Global/ReactTable/CellFormatters/utils/resolveFormatter";
import Header from "components/Global/ReactTable/ColumnHeaders/Header";
import HeaderIntegration from "components/Global/ReactTable/ColumnHeaders/Integration";
import HeaderSort from "components/Global/ReactTable/ColumnHeaders/Sort";

const resolveHeader = column => {
  if (column.Header) {
    return column.Header;
  }

  const types = [];

  // header: integration
  if (column.integration) {
    types.push({
      TypeComponent: HeaderIntegration,
      meta: {
        integration: column.integration
      }
    });
  }

  // header: sort
  if (column.isSorting) {
    types.push({
      TypeComponent: HeaderSort,
      meta: {
        sortByDirection: column.sortByDirection
      }
    });
  }

  return <Header types={types}>{column.Header || column.name}</Header>;
};

const resolveHeaderClassName = column => {
  // @NOTE: Uncomment these to use context menus on list view
  // const classes = ["react-grid-HeaderCell", "react-grid-HeaderCell-ReactTable"];
  const classes = [];
  if (column.sortable) {
    classes.push("sortable");
  }
  if (column.isSorting) {
    classes.push("is-sorting");
  }
  return classes.length ? classes.join(" ") : null;
};

export default ({ column, getRowMetaData }) => ({
  id: column.id,
  Header: resolveHeader(column),
  headerClassName: resolveHeaderClassName(column),
  getHeaderProps: (state, rowInfo, column, instance) => {
    return {
      // ...column,
      "data-column": column.id
    };
  },
  accessor: column.accessor || `values.${column.id}`,
  width: column.width || get(column, "settings.defaultWidth") || 200,
  sortable: false, // force false here since we have our own sorting
  resizable: true,
  Cell: props => {
    if (column.Cell) return column.Cell(props);
    if (!props.value) return "—";
    const Formatter = column.formatter || resolveFormatter(column);
    const result = (
      <Formatter
        {...props}
        row={props.original}
        column={column}
        dependentValues={getRowMetaData(props.original, column)}
      />
    );
    if (column.is_primary_field) {
      return (
        <PrimaryFieldFormatter {...props} onClick={column.onClick}>
          {result}
        </PrimaryFieldFormatter>
      );
    }
    return result;
  }
});
