import {
  put,
  all,
  take,
  takeEvery,
  fork,
  select,
  call
} from "redux-saga/effects";

import { getCredentials } from "redux/modules/user/selectors";
import { orgDetails as getOrgDetails } from "redux/modules/organization/selectors";

import { actions } from "./model";
import { registerError } from "redux/modules/errors/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import moment from "moment";

import { FORM_ID } from "./constants";
import { getEditedField } from "./selectors";
import { getters } from "./model";
import { fetchOrganization } from "redux/modules/organization/actions";
import { actions as FormActions } from "ui-kit/Form/model";

import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const orgDetails = yield select(getOrgDetails);

  return {
    credentials,
    orgDetails
  };
};

const goToStripeAuthUrl = function*() {
  const { credentials, orgDetails } = yield call(getParams);

  try {
    // get url
    const { payload } = yield call(Api.postStripeUrl, credentials, {
      orgId: orgDetails.id
    });

    // go to url
    window.location = payload.url;
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred getting the Stripe Connect Auth URL"
        }
      ])
    );
  }
};

const disconnectPaymentsAuth = function*() {
  const { credentials, orgDetails } = yield call(getParams);
  const stripeStatus = yield select(getters.stripeStatus);

  try {
    // get url
    const { payload } = yield call(
      Api.deleteStripeDetails,
      credentials,
      orgDetails.id,
      stripeStatus.id
    );

    // go to url
    window.location = payload.url;
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred disconnecting Stripe account"
        }
      ])
    );
  }
};

const viewStripeDashboard = function*() {
  const stripeStatus = yield select(getters.stripeStatus);
  window.open(stripeStatus.dashboard_url, "_blank");
};

const init = function*() {
  try {
    yield put(actions.setLoading(true));
    const { credentials, orgDetails } = yield call(getParams);

    /*
    const { payload } = yield call(Api.getToken, {
      credentials,
      orgId: orgDetails.id
    });
    */

    const { payload: billingPayload } = yield call(
      Api.getStripeDetails,
      credentials,
      orgDetails.id
    );

    /*
    const { payload: subscriptionPayload } = yield call(
      Api.getSubscriptionDetails,
      {
        credentials,
        orgId: orgDetails.id
      }
    );
    */

    const stripePayload = billingPayload || {};

    const stripeStatus = {
      isActivated: Boolean(stripePayload && stripePayload.id),
      ...stripePayload
    };
    if (stripeStatus.isActivated) {
      stripeStatus.createdBy = `${stripePayload.user.first_name} ${stripePayload.user.last_name} (${stripePayload.user.email})`;
      stripeStatus.createdOn = moment(stripePayload.created_at).format(
        "dddd, MMMM Do YYYY, h:mm:ss a"
      );
    }

    // yield put(actions.setBillingPortal(payload));
    yield put(actions.setStripeStatus(stripeStatus));
    // yield put(actions.setSubscription(subscriptionPayload));
    yield put(
      actions.setData({
        billing_email: orgDetails.billing_email
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting details"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const saveBillingDetails = function*() {
  try {
    const { credentials, orgDetails } = yield call(getParams);
    const data = yield select(getters.data);
    const toSave = {
      billingEmail: data.billing_email
    };

    yield call(Api.updateDetails, {
      credentials,
      orgId: orgDetails.id,
      data: toSave
    });

    yield put(fetchOrganization(orgDetails.id));
    yield put(showSnackbar({ message: "Details Updated" }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while saving details"
        }
      ])
    );
  }
};

const fieldUpdate = function*({ id, fieldId }) {
  const newValue = yield select(getEditedField, {
    instanceId: id,
    fieldId
  });

  yield put(actions.updateField({ field: fieldId, value: newValue }));
};

const watchUpdateFields = function*() {
  for (;;) {
    const {
      meta: { instanceId, fieldId }
    } = yield take(FormActions.setFieldValue.type);
    if (instanceId === FORM_ID) {
      yield call(fieldUpdate, {
        id: instanceId,
        fieldId
      });
    }
  }
};

const watchSaveBillingDetails = function*() {
  yield takeEvery(actions.saveBillingDetails.type, saveBillingDetails);
};

const watchGoToStripeAuthUrl = function*() {
  yield takeEvery(actions.goToStripeAuthUrl.type, goToStripeAuthUrl);
};

const watchViewStripeDashboard = function*() {
  yield takeEvery(actions.viewStripeDashboard.type, viewStripeDashboard);
};

const watchDisconnectFromStripe = function*() {
  yield takeEvery(actions.disconnectPaymentsAuth.type, disconnectPaymentsAuth);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([
    fork(watchGoToStripeAuthUrl),
    fork(watchViewStripeDashboard),
    fork(watchDisconnectFromStripe),
    fork(watchUpdateFields),
    fork(watchSaveBillingDetails),
    fork(watchInit)
  ]);
};

export default rootSaga;
