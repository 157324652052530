import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import PageTitle from "EventLight/Common/PageTitle";

import Loading from "ui-kit/Loading";
import { Page, Title } from "EventLight/Common/FieldLayout";
import Body from "./Body";
import AlertsCreateModal from "Portal/Alerts/Create/View";
import { AddCircleIcon, BigFilledButton } from "components/Base";

const decorate = connect(
  state => ({
    loading: getters.loading(state)
  }),
  {
    init: actions.init,
    openAddAlertModal: actions.openAddAlertModal
  }
);

const Layout = ({ loading, init, openAddAlertModal }) => {
  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <PageTitle titles={["Alerts"]} />
      <Page>
        <Title
          icon="campaign"
          title="Alerts"
          description="Send broadcast messages via email and SMS to individuals"
          supportId="alerts"
          buttons={
            <BigFilledButton
              color="white"
              bg="altB5"
              LeftIcon={AddCircleIcon}
              onClick={openAddAlertModal}
            >
              Send Alert
            </BigFilledButton>
          }
        />

        {loading ? <Loading /> : <Body />}
      </Page>
      <AlertsCreateModal />
    </>
  );
};

export default decorate(Layout);
