import PropTypes from "prop-types";
import React, { Component } from "react";
import { get } from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { getLoggedInUserDetails } from "redux/modules/user/actions";
import { getUserAccessLevels } from "redux/modules/user/access-levels/actions";
import {
  getOrganizationUserPermissionProfile,
  getEventUserPermissionProfile
} from "redux/modules/permissions/user-permission-profile/actions";

import { hasFetchedAccessLevels } from "redux/modules/user/access-levels/selectors";
import { hasFetchedUserPermissionProfile } from "redux/modules/permissions/user-permission-profile/selectors";

import * as UserSelectors from "redux/modules/user/selectors";
import EnvironmentTag from "components/Platform/EnvironmentTag";
import PlatformLoading from "components/Platform/PlatformLoading";
import PlatformLoaded from "components/Platform/PlatformLoaded";

const styles = {
  container: {
    width: "100%",
    height: "100%",
    overflow: "auto"
  }
};

class PlatformView extends Component {
  componentDidMount() {
    const isEventRoute =
      this.props.routes.some(r => r.name === "editEventProfile") ||
      this.props.routes.some(r => r.name === "eventLightGate");
    const isOrgRoute = this.props.routes.some(r => r.name === "organization");

    this.props.getUserAccessLevels();

    // after user is fetched, get the user's access levels and org/event permissions
    if (isOrgRoute) {
      this.props.getOrganizationUserPermissionProfile(
        this.props.params.orgId,
        this.props.user.id
      );
    }

    if (isEventRoute) {
      this.props.getEventUserPermissionProfile(
        this.props.params.eventId,
        this.props.user.id
      );
    }

    return true;
  }

  render() {
    const {
      user,
      fetchedAccessLevels,
      fetchedPermissions,
      dispatch,
      router,
      routes,
      params,
      location,
      children
    } = this.props;

    if (!get(user, "id") || !fetchedAccessLevels || !fetchedPermissions) {
      return <PlatformLoading />;
    }

    return (
      <div style={styles.container}>
        <PlatformLoaded
          dispatch={dispatch}
          router={router}
          routes={routes}
          params={params}
          location={location}
        >
          {children}
        </PlatformLoaded>
        <EnvironmentTag />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const userToPass = UserSelectors.user(state);
  return {
    user: userToPass,
    fetchedAccessLevels: hasFetchedAccessLevels(state),
    fetchedPermissions: hasFetchedUserPermissionProfile(
      state,
      props.params.orgId || props.params.eventId
    )
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getLoggedInUserDetails,
      getUserAccessLevels,
      getOrganizationUserPermissionProfile,
      getEventUserPermissionProfile
    },
    dispatch
  );
}

PlatformView.propTypes = {
  dispatch: PropTypes.func,
  router: PropTypes.object.isRequired,
  routes: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlatformView);
