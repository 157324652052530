import WebAPIUtils from "utils/Global/WebApiUtils";

module.exports = {
  verifyEmail: (hash, newPassword, cb) => {
    WebAPIUtils.verifyEmail(hash, newPassword, result => {
      cb(result);
    });
  },

  getVerificationLink: (hash, cb) => {
    WebAPIUtils.getVerificationLink(hash, result => {
      cb(result);
    });
  },

  claimProfile: data => {
    WebAPIUtils.claimProfile(data, () => {
      // track analytics here
    });
  },

  requestInvite: data => {
    WebAPIUtils.requestInvite(data, () => {
      // track analytics here
    });
  },

  resendConfirmation: (data, cb) => {
    WebAPIUtils.resendConfirmation(data, result => {
      cb(result);
    });
  },

  forgotPassword: (email, cb) => {
    WebAPIUtils.forgotPassword(email, result => {
      cb(result);
    });
  }
};
