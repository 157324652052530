import * as R from "ramda";
import { NAMESPACE, TABS, PEOPLE_FILTERS } from "Portal/PortalPeople/constants";

import { createHandlers } from "redux-mvc";

const iniState = {
  loading: true,
  saving: false,
  tabs: TABS,
  eventId: null,
  filterby: PEOPLE_FILTERS.ALL,
  data: { contacts: [], events: [], types: [] },
  selectedIds: [],
  selectedTypeId: null,
  isAddUserModalVisible: false,
  markAsPrimaryContact: false,
  addCustomRole: false,
  role: ""
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    onSave: R.identity,
    toggleShowMore: ({ viewMore }) => ({
      viewMore: R.not(viewMore)
    }),
    setInitialData: (
      _,
      { payload: { title, background_image_url, description } }
    ) => ({
      title,
      background_image_url,
      description
    }),
    selectIds: (_, { payload }) => ({
      selectedIds: payload
    }),
    removeAllSelectedIds: R.identity,
    selectFilter: (_, { payload: { type, id = null } }) => ({
      filterby: type,
      eventId: id
    }),
    selectAllCategory: R.identity
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
