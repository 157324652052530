import React from "react";
import * as R from "ramda";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import ScrollableModalBody from "components/Global/Modal/Layout/ScrollableBody";
import { CREDENTIAL_TYPE_ID, MEAL_TYPE_ID } from "utils/item-types";
import { addS } from "utils/General";

import {
  Div,
  BigFilledButton,
  BigOutlineButton,
  LoadingIcon
} from "components/Base";
import Credentials from "./Credentials";
import Catering from "./Catering";

const Items = ({
  saving,
  loading,
  hideModal,
  canSave,
  handleSave,
  activeType,
  selectAll,
  removeAll,
  countOfSelectedVariants,
  onSearchChange,
  searchTerm,
  cateringMealsPerDay,
  eventDateGroups
}) => (
  <StyleWrapper
    heading="Add available items"
    hideModal={hideModal}
    bodyStyles={{ padding: 0 }}
    width={650}
  >
    {loading ? (
      <Div py={20} display="row.center.center" flex={1}>
        <LoadingIcon size={60} color="neutral4" />
      </Div>
    ) : (
      <Div>
        <ScrollableModalBody
          style={{
            minHeight: 400,
            display: "flex",
            backgroundColor: "rgba(224, 224, 224, 0.19)"
          }}
        >
          <Div px={6} flex={1} style={{ width: "100%" }}>
            {R.propOr(
              <Credentials
                activeType={activeType}
                onSearchChange={onSearchChange}
                searchTerm={searchTerm}
              />,
              activeType.id,
              {
                [MEAL_TYPE_ID]: (
                  <Catering
                    activeType={activeType}
                    cateringMealsPerDay={cateringMealsPerDay}
                    eventDateGroups={eventDateGroups}
                  />
                )
              }
            )}
          </Div>
        </ScrollableModalBody>
        <Div display="row.space-between.center" py={3} px={5}>
          <Div>
            <Div uppercase fw={3} fs={2} color="gray7">
              {countOfSelectedVariants} item
              {addS(countOfSelectedVariants)} selected
            </Div>
            <Div display="row.flex-start.center">
              <Div fw={3} onClick={selectAll} color="purple8" fs={2}>
                Select all
              </Div>
              <Div color="purple8" mx={1}>
                &middot;
              </Div>
              <Div fw={3} onClick={removeAll} color="purple8" fs={2}>
                Remove all
              </Div>
            </Div>
          </Div>
          <Div>
            <BigOutlineButton onClick={hideModal} mr={1}>
              Cancel
            </BigOutlineButton>
            <BigFilledButton
              bg={canSave && !saving ? "altB5" : "neutral1"}
              onClick={canSave && !saving ? handleSave : null}
            >
              {saving ? "Saving" : "Save"}
            </BigFilledButton>
          </Div>
        </Div>
      </Div>
    )}
  </StyleWrapper>
);

export default Items;
