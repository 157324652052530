import React, { Component } from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import ScrollableModalBody from "components/Global/Modal/Layout/ScrollableBody";

import * as R from "ramda";
import {
  Div,
  Text0,
  Text1,
  Text2,
  Text3,
  Text5,
  HalfSizeDotIcon,
  Type10Card,
  LoadingIcon,
  BigFilledButton,
  BigOutlineButton,
  AddBoxIcon,
  DarkTab,
  makeTable,
  TinyShadedBox
} from "components/Base";
import { addS, withProps } from "utils/General";
import ReactTable from "react-table";

const AddItems = ({ showAddItemsModal, label }) => {
  return showAddItemsModal ? (
    <BigOutlineButton
      mb={3}
      onClick={showAddItemsModal}
      LeftIcon={withProps({ color: "info6" })(AddBoxIcon)}
    >
      {label ? label : "Add Items"}
    </BigOutlineButton>
  ) : null;
};

const Type = ({ name, columns, variants }) => (
  <Div mb={3}>
    <ReactTable
      key={`table_${name}_${variants.length}`}
      className="ReactTable--Lennd ReactTable--Lennd-Simple"
      noDataText="No line items"
      columns={columns}
      data={variants}
      resizable={false}
      sortable={false}
      pageSize={variants.length === 0 ? 3 : variants.length}
      PaginationComponent={null}
      showPageSizeOptions={false}
      showPagination={false}
      showPaginationBottom={false}
    />
  </Div>
);

const RowText = ({ text = "—" }) => (
  <Text3 color="neutral7" bold>
    {text === 0 ? "-" : text}
  </Text3>
);

const headerCell = text => () => (
  <Text1 color="neutral5" bold uppercase>
    {text}
  </Text1>
);

const HeaderStyler = withProps({
  display: "row.flex-start.center",
  pb: 1
})(Div);

const RowStyler = withProps({
  display: "row.flex-start.center",
  bg: "white",
  py: 1
})(Div);

const ReportTable = ({ type, items, isLastTable }) => {
  const Table = makeTable({
    TableStyler: Div,
    HeaderStyler,
    RowStyler,
    HeaderCellStyler: Div,
    RowCellStyler: Div,
    columnProps: [
      { width: 2 / 6 },
      { width: 1 / 6 },
      { width: 1 / 6 },
      { width: 1 / 6 },
      { width: 1 / 6 }
    ],
    headerCellComps: [
      headerCell(type.name),
      headerCell("Requested"),
      headerCell("Approved"),
      headerCell("Assigned"),
      headerCell("Issued")
    ],
    rowCellComps: [
      ({ name, variant_name, color }) => (
        <Div display="row.flex-start.center">
          <HalfSizeDotIcon color={color} size={16} />
          <Div display="column.flex-start.flex-start" ml={1}>
            <Text3 color="neutral7" bold>
              {name}
            </Text3>
            {variant_name ? (
              <Text1 color="neutral5">{variant_name}</Text1>
            ) : null}
          </Div>
        </Div>
      ),
      ({ count_of_requested }) => <RowText text={count_of_requested} />,
      ({ count_of_approved }) => <RowText text={count_of_approved} />,
      ({ count_of_approved_assigned }) => (
        <RowText text={count_of_approved_assigned} />
      ),
      ({ count_of_issued }) => <RowText text={count_of_issued} />
    ]
  });

  return (
    <Div mb={isLastTable ? 0 : 5}>
      <Table rowsData={items} />
    </Div>
  );
};

const Overview = ({
  countOfOrders,
  rowContents,
  summaryData,
  showAddItemsModal
}) => {
  return (
    <Div px={6} pb={3}>
      {R.map(
        ({ buttons, frontText, backText, rowColor }) => (
          <Type10Card
            buttons={buttons}
            frontText={frontText}
            backText={backText}
            color={rowColor}
          />
        ),
        rowContents
      )}

      <Div pb={2} pt={5} display="row.space-between.center">
        <Div color="gray9" fs={2} fw={3}>
          Summary
        </Div>
        <Text2>{`${countOfOrders} order${addS(countOfOrders)}/request${addS(
          countOfOrders
        )}`}</Text2>
      </Div>
      <Div p={3} bra={1} bc="neutral1" mb={2}>
        {summaryData.length ? (
          summaryData.map((itemType, idx) => (
            <ReportTable
              key={itemType.id}
              type={itemType}
              items={itemType.items}
              isLastTable={idx === summaryData.length - 1}
            />
          ))
        ) : (
          <Div fs={2} px={5} py={2} color="gray6" textAlign="center">
            There are no items to summarize.
          </Div>
        )}
      </Div>
      <AddItems showAddItemsModal={showAddItemsModal} />
    </Div>
  );
};

const OrderColumn = ({ header, value }) => (
  <Div width={1 / 4}>
    <Text0 color="neutral6" bold uppercase>
      {header}
    </Text0>
    <Text2 mt={1}>{value}</Text2>
  </Div>
);

const OrderCard = ({
  showGroupNameColumn,
  title,
  orderNumber,
  tags,
  source,
  orderType,
  requestedBy,
  groupName,
  orderDate,
  onClick
}) => (
  <Div
    bra={1}
    bc={[{ default: "neutral1", hover: "neutral4" }]}
    bg="white"
    py={3}
    px={4}
    display="row.flex-start.center"
    mb={2}
    onClick={onClick}
  >
    {/* <PercentageIndicator /> */}
    <Div flex={1} display="column.flex-start.stretch">
      <Div display="row.flex-start.center">
        <Text5 bold>{title}</Text5>
        <Div ml={2} flex={1}>
          {R.map(
            name => (
              <TinyShadedBox uppercase ml={1}>
                {name}
              </TinyShadedBox>
            ),
            tags
          )}
        </Div>
        <Text1 bold color="neutral6">{`Order #${orderNumber}`}</Text1>
      </Div>
      <Div mt={2} display="row.flex-start.center">
        <OrderColumn header="Type" value={orderType} />
        {showGroupNameColumn ? (
          <OrderColumn header="Group" value={groupName || "-"} />
        ) : null}
        <OrderColumn header="Point of Contact" value={requestedBy} />
        {/* <OrderColumn header="Pickup People" value={pickup} /> */}
        <OrderColumn header="Source" value={source} />
        <OrderColumn header="Order Date" value={orderDate} />
      </Div>
    </Div>
  </Div>
);

const OrderCardList = ({ showGroupNameColumn, orders }) => (
  <Div>
    {R.map(
      props => (
        <OrderCard showGroupNameColumn={showGroupNameColumn} {...props} />
      ),
      orders
    )}
  </Div>
);

const Orders = ({
  orders,
  viewingAsContact,
  showAddItemsModal,
  viaAllocations
}) => {
  return (
    <Div px={6} pb={3} pt={3}>
      <OrderCardList showGroupNameColumn={viewingAsContact} orders={orders} />
      <AddItems
        showAddItemsModal={showAddItemsModal}
        label={viaAllocations ? "Add Items" : "Add Order"}
      />
    </Div>
  );
};

const Items = ({ types, activeView, columns, showAddItemsModal }) => {
  return (
    <Div>
      {activeView === "locked" ? (
        <Div bra={1} bg="neutral3" mx={6} p={4} my={3}>
          Items are locked when they have been checked out or issued.
        </Div>
      ) : null}
      <Div px={6} py={4} flex={1}>
        {types.map(type => (
          <Type key={type.id} columns={columns} {...type} />
        ))}
        {activeView === "editable" ? (
          <AddItems showAddItemsModal={showAddItemsModal} />
        ) : null}
      </Div>
    </Div>
  );
};

class View extends Component {
  render() {
    const {
      modalTitle,
      modalSubtitle,
      loading,
      types,
      hideModal,
      columns,
      showAddItemsModal,
      tabs,
      activeView,
      countOfOrders,
      summaryData,
      orders,
      viewingAsContact,
      rowContents,
      viaAllocations
    } = this.props;

    return (
      <StyleWrapper
        heading={
          <Div display="column">
            <span>{modalTitle}</span>
            {modalSubtitle ? (
              <span
                style={{
                  fontSize: 13,
                  color: "rgba(255, 255, 255, 0.8)"
                }}
              >
                {modalSubtitle}
              </span>
            ) : null}
          </Div>
        }
        hideModal={hideModal}
        bodyStyles={{ padding: 0 }}
        width={760}
      >
        {loading ? (
          <Div py={20} display="row.center.center">
            <LoadingIcon size={60} color="neutral4" />
          </Div>
        ) : (
          <>
            <Div bb={1} bc="gray2" px={6}>
              {tabs.map(({ tabName, onClick, isActive, count }) => {
                return (
                  <DarkTab
                    key={tabName}
                    px={2}
                    pb={4}
                    pt={5}
                    fs={3}
                    active={isActive}
                    onClick={isActive ? undefined : onClick}
                  >
                    {tabName}
                    {count ? ` (${count})` : null}
                  </DarkTab>
                );
              })}
            </Div>
            <ScrollableModalBody
              style={{
                minHeight: 400,
                display: "flex",
                backgroundColor: "rgba(224, 224, 224, 0.19)",
                flexDirection: "column"
              }}
            >
              {R.propOr(
                <Items
                  {...{
                    types,
                    activeView,
                    columns,
                    showAddItemsModal
                  }}
                />,
                activeView
              )({
                overview: (
                  <Overview
                    {...{
                      summaryData,
                      countOfOrders,
                      rowContents,
                      showAddItemsModal
                    }}
                  />
                ),
                orders: (
                  <Orders
                    {...{
                      viaAllocations,
                      orders,
                      viewingAsContact,
                      showAddItemsModal
                    }}
                  />
                )
              })}
            </ScrollableModalBody>
            <Div display="flex-start" py={3} px={5}>
              <BigFilledButton bg="altB5" mr={1} onClick={hideModal}>
                Done
              </BigFilledButton>
            </Div>
          </>
        )}
      </StyleWrapper>
    );
  }
}

export default View;
