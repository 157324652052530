import { createModule } from "redux-mvc";

import model from "./model";

import sagas from "./sagas";

import filters from "ui-kit/FilterControls";
import tabs from "ui-kit/Tabs";
import table from "ui-kit/Table";
import searchBar from "ui-kit/SearchBar";
import actionPopover from "ui-kit/ActionsPopover";
import modals from "EventLight/GuestLists/GuestLists/View/SettingsModal/index.js";

const module = createModule({
  ...model,
  observedDomains: ["user", "event", "permissions", "@flopflip"]
});

module.plugModule(filters);
module.plugModule(tabs);
module.plugModule(table);
module.plugModule(searchBar);
module.plugModule(actionPopover);
module.plugModule(modals);
module.setRootSaga(sagas);

export default module;
