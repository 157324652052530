import React, { Component } from "react";
import autobind from "autobind-decorator";
import jQuery from "jquery";

import { debounce } from "lodash";

// @NOTE: Comment until until we need this
// import ButtonEditColumns from 'components/Global/Table3/ButtonEditColumns';

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import PeopleBlockTable from "Submission/PeopleBlockTable/View";

const styles = {
  editingWrapper: {
    marginTop: 35,
    marginBottom: 60
  },
  fillingOutWrapper: {
    marginBottom: 0
  }
};

// @listensToClickOutside
@CSSModules(css)
class TableView extends Component {
  constructor(props) {
    super(props);
    this.handleResize = debounce(this.handleResize, 500);

    this.state = {
      zoomMode: false,
      columnWidthMap: {},
      documentWidth: this.getDocumentWidth(),
      height: 500,
      selectedRows: [],
      currentScroll: ""
    };

    this.props.addTableId(props.field.id);
  }

  /**
   * ui helpers
   */
  @autobind
  toggleZoom() {
    const elm =
      document.getElementById(this.props.scrollTopId) ||
      document.documentElement;
    this.setState(
      {
        zoomMode: !this.state.zoomMode,
        lastScroll: !this.state.zoomMode ? elm.scrollTop : this.state.lastScroll
      },
      () => {
        if (this.state.zoomMode) {
          elm.scrollTop = 0;
          elm.style.overflow = "hidden";
        } else {
          elm.scrollTop = this.state.lastScroll;
          elm.style.overflow = "auto";
        }
      }
    );
  }

  @autobind
  getDocumentWidth() {
    return jQuery(".page-form-content").width();
  }

  @autobind
  handleResize() {
    this.setState({
      documentWidth: this.getDocumentWidth()
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    this.handleResize.cancel();
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    let zoomIcon;

    if (this.state.zoomMode) {
      zoomIcon = (
        <div styleName="gridButtonClose" key="expand" onClick={this.toggleZoom}>
          <i className="material-icons">clear</i>
        </div>
      );
    } else {
      zoomIcon = (
        <div
          styleName="gridButtonExpand"
          key="expand"
          onClick={this.toggleZoom}
        >
          <i className="material-icons">code</i>
          expand
        </div>
      );
    }

    return (
      <div
        styleName={this.props.isEditing ? "borderEditing" : "border"}
        style={
          !this.state.zoomMode && this.props.isEditing
            ? styles.editingWrapper
            : {}
        }
      >
        <div
          styleName={this.state.zoomMode ? "zoomWrapper" : "noZoomWrapper"}
          style={this.props.isFillingFormOut ? styles.fillingOutWrapper : {}}
          className="react-grid-Lennd-Form-Wrapper show-drag-handle"
          data-grid-id={this.props.field.id}
        >
          {zoomIcon}
          <div
            styleName={this.state.zoomMode ? "zoomWrapperInner" : ""}
            style={{ width: "100%" }}
          >
            <div
              className={`react-grid-Lennd-Form react-grid-Lennd-Form_${this.props.field.id}`}
              styleName="container"
            >
              <div
                style={{ overflowY: "auto", overflowX: "hidden" }}
                onClick={e => {
                  e.stopPropagation();
                }}
              >
                {/* context for this table is in components/Event/Formv2/Sections/Section/index */}
                <PeopleBlockTable
                  showImportModal={this.props.showImportModal}
                  submission={this.props.parentSubmission}
                  field={this.props.field}
                  mode={this.props.mode}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TableView;
