import React from "react";
import { connect } from "react-redux";
import { actions as itemCatalogActions } from "Event/ItemCatalog";
import { BigFilledButton, PopoverMenu } from "components/Base";

const decorate = connect(
  null,
  {
    showBulkModal: () => itemCatalogActions.showBulkModal({ isZones: true })
  }
);

const AddItemPopover = ({ addItem, showBulkModal }) => (
  <PopoverMenu
    Label={({ onClick }) => (
      <BigFilledButton bg="altB5" ml={1} onClick={onClick}>
        Add
      </BigFilledButton>
    )}
    menuItems={[["Add Zone", addItem], ["Bulk Add Zones", showBulkModal]]}
  />
);

export default decorate(AddItemPopover);
