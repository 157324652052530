import React from "react";

import { connect } from "react-redux";

import * as R from "ramda";

import { actions, getters } from "Items/BulkQuestionItemsModal";

import { getFilteredQuestions } from "Items/BulkQuestionItemsModal/selectors";

import {
  Div,
  collapsableHandler,
  Collapsable,
  RightIcon,
  DownIcon,
  Text0,
  SmallCheckbox,
  Text1,
  Text2,
  EigthSizeDotIcon
} from "components/Base";

import { addS, capitalizeFirst } from "utils/General";

const iconProps = {
  size: 24,
  color: "black"
};

const decorate = connect(
  state => ({
    questions: getFilteredQuestions(state),
    itemQuestionsIds: getters.itemQuestionsIds(state)
  }),
  {
    toggleItemQuestion: actions.toggleItemQuestion
  }
);

const IndividualQuestionsCollapsable = collapsableHandler(
  ({
    toggleCollapsed,
    collapsed,
    questions,
    toggleItemQuestion,
    itemQuestionsIds
  }) => {
    const questionsLength = questions.length;
    return (
      <Div>
        <Div
          onClick={toggleCollapsed}
          fs={4}
          fw={3}
          display="row.space-between.flex-end"
          pr={1}
        >
          <Div display="row.flex-start.center">
            {collapsed ? (
              <RightIcon {...iconProps} />
            ) : (
              <DownIcon {...iconProps} />
            )}
            <Div color="neutral7" fw={3} fs={4}>
              Individual Questions
            </Div>
          </Div>
          <Text0 bold color="neutral5">
            {`${questionsLength} question${addS(questionsLength)}`}
          </Text0>
        </Div>
        <Collapsable collapsed={collapsed}>
          <Div>
            {R.map(({ id, name, has_price, type, count_of_items }) => {
              const isEnabled = R.any(
                selectedId => selectedId === id,
                itemQuestionsIds
              );
              return (
                <Div
                  width={1}
                  bra={1}
                  p={1}
                  shadow={1}
                  bg="white"
                  display="row.flex-start.center"
                  key={id}
                  mt={1}
                >
                  <SmallCheckbox
                    selected={isEnabled}
                    onClick={() => toggleItemQuestion({ id, isEnabled })}
                  />
                  <Div display="row.space-between.center" flex={1} pl={2}>
                    <Div>
                      <Text2 bold color="black">
                        {name || "-"}
                      </Text2>
                      <Div display="row.flex-start.center">
                        {/* <Text0 color="neutral6" bold>
                          {`${count_of_items} item${addS(count_of_items)}`}
                        </Text0>
                        <EigthSizeDotIcon color="neutral6" /> */}
                        <Text0 color="neutral6" bold>
                          {capitalizeFirst(type)}
                        </Text0>
                      </Div>
                    </Div>
                    {has_price && (
                      <Text1 bold color="neutral4">
                        Contains Price Modifiers
                      </Text1>
                    )}
                  </Div>
                </Div>
              );
            }, questions)}
          </Div>
        </Collapsable>
      </Div>
    );
  }
);

export default decorate(IndividualQuestionsCollapsable);
