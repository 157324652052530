import React from "react";
import * as R from "ramda";
import { get, uniq } from "lodash";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Base from "../Base";
import resolveTitle from "components/Event/Module/utils/resolveTitle";
import resolveRecordName from "components/Event/Module/utils/resolveRecordName";
import kanbanFieldFilter from "components/Event/Module/utils/kanban-field-filter";
import flattenFields from "components/Global/CRM/TableViews/utils/flatten-fields";
import CanUserDo from "components/Global/Security/CanUserDo";

import Container from "components/Global/Module/Sidebar/Container";
import ActionText from "components/Global/Module/Sidebar/ActionText";
import Well from "components/Global/Module/Sidebar/Well";
import SelectedWellItem from "components/Global/Module/Sidebar/Well/SelectedItem";
import SortItem from "components/Global/Module/Sidebar/Well/SortItem";
import GroupByPicker from "components/Global/Module/Sidebar/Popovers/GroupBy";
import FilterPicker from "components/Global/Module/Sidebar/Popovers/Filter";
import SortPicker from "components/Global/Module/Sidebar/Popovers/Sort";
import CustomCards from "components/Global/CRM/TableViews/KanbanView/CustomCards";
import QuickFilters from "components/Event/Module/Navigation/Common/QuickFilters";
import Views from "components/Event/Module/Navigation/Common/Views";
import SidebarTitle from "components/Event/Module/Navigation/Common/SidebarTitle";

import EventPicker from "./EventPicker";

import { Div, CloseIcon } from "components/Base";

import { VIEW_MY_RECORDS_ID } from "components/Event/Module/utils/views";

import { connect } from "react-redux";
import { getAllEventRecordTypes } from "redux/modules/modules/recordTypes/actions";
import { allEventRecordTypes } from "redux/modules/modules/recordTypes/selectors";

const mapStateToProps = (state, props) => ({
  allEventRecordTypes: allEventRecordTypes(state, props.moduleId)
});

const mapDispatchToProps = {
  getAllEventRecordTypes
};

const decorate = connect(
  mapStateToProps,
  mapDispatchToProps
);

const PrimaryLink = CSSModules(
  ({ name, active, onClick }) => (
    <div
      styleName={active ? "primaryLinkActive" : "primaryLink"}
      onClick={onClick}
      role="link"
      tabIndex="0"
    >
      {name}
    </div>
  ),
  css
);

const Pickers = ({
  type,
  active,
  activeView,
  fields,
  updateViewMeta,
  addSort
}) => {
  switch (type) {
    case "filter":
      return (
        <FilterPicker
          filters={get(activeView, "filters")}
          onChange={updateViewMeta}
          fields={fields.filter(f =>
            [
              "text",
              "number",
              "email",
              "textarea",
              "checkbox",
              "dropdown",
              "source"
            ].includes(f.type)
          )}
        >
          {active ? (
            <ActionText
              style={{
                padding: 0,
                margin: "10px 5px 0px",
                fontSize: 11
              }}
            >
              <i
                className="material-icons"
                style={{ marginRight: 1, fontSize: 11 }}
              >
                add
              </i>
              Add filter
            </ActionText>
          ) : (
            <ActionText>
              <i className="material-icons">add_circle</i>
              Add filter
            </ActionText>
          )}
        </FilterPicker>
      );
    case "sortBy":
      return (
        <SortPicker
          sortBy={get(activeView, "sort_by")}
          onChange={addSort}
          fields={fields}
        >
          {active ? (
            <ActionText
              style={{
                padding: 0,
                margin: "10px 5px 0px",
                fontSize: 11
              }}
            >
              <i
                className="material-icons"
                style={{ marginRight: 1, fontSize: 11 }}
              >
                add
              </i>
              Add sort by
            </ActionText>
          ) : (
            <ActionText>
              <i className="material-icons">sort</i>
              Sort by
            </ActionText>
          )}
        </SortPicker>
      );
    default:
      return null;
  }
};

const SelectedPicker = ({
  type,
  active,
  picker,
  fields,
  activeView,
  findField,
  removeFilter,
  removeSort,
  updateViewMeta,
  kanbanFieldFilter,
  handleKanbanFieldsChange
}) => {
  switch (type) {
    case "filter":
      return active ? (
        <Well>
          <ActionText
            style={{
              padding: 0,
              margin: 5,
              marginBottom: 10
            }}
          >
            <i className="material-icons">filter_list</i> Filters
          </ActionText>
          {activeView.filters.filters.map((filter, idx) => (
            <SelectedWellItem
              title={`${get(findField(filter.fieldId), "name")} ${
                filter.label
              }`}
              icon={
                <i
                  role="button"
                  tabIndex="0"
                  className="material-icons"
                  style={{ cursor: "pointer" }}
                  onClick={() => removeFilter(idx)}
                >
                  close
                </i>
              }
            />
          ))}
          {picker}
        </Well>
      ) : (
        picker
      );
    case "sortBy":
      return active ? (
        <Well>
          <ActionText
            style={{
              padding: 0,
              margin: 5,
              marginBottom: 10
            }}
          >
            <i className="material-icons">filter_list</i> Sort by
          </ActionText>
          {activeView.sort_by.map((sortCase, idx) => (
            <SortItem
              name={get(findField(sortCase.fieldId), "name")}
              direction={sortCase.direction}
              removeIcon={
                <i
                  role="button"
                  tabIndex="0"
                  className="material-icons"
                  style={{ cursor: "pointer" }}
                  onClick={() => removeSort(idx)}
                >
                  close
                </i>
              }
            />
          ))}
          {picker}
        </Well>
      ) : (
        picker
      );
    case "groupBy": {
      if (activeView.view_type === "list") {
        return null;
      } else if (activeView.view_type === "kanban") {
        return (
          <div>
            <div styleName="actionWrapper">
              <Well>
                <ActionText
                  style={{
                    padding: 0,
                    margin: 5,
                    marginBottom: 10
                  }}
                >
                  <i className="material-icons">group_work</i> Grouping by
                </ActionText>
                <SelectedWellItem
                  title={get(
                    findField(activeView.grouped_by),
                    "name",
                    "Choose a field..."
                  )}
                  icon={
                    <GroupByPicker
                      onChange={updateViewMeta}
                      fields={fields.filter(kanbanFieldFilter)}
                      selectedFieldId={get(activeView, "grouped_by")}
                    >
                      <i
                        className="material-icons"
                        style={{ cursor: "pointer" }}
                      >
                        edit
                      </i>
                    </GroupByPicker>
                  }
                />
              </Well>
            </div>
            <CustomCards
              fields={fields}
              updateFields={handleKanbanFieldsChange}
              selectedFields={activeView.card_fields}
              dropdownFields={fields.filter(kanbanFieldFilter)}
            >
              <ActionText>
                <i className="material-icons">view_stream</i>
                Customize Cards
              </ActionText>
            </CustomCards>
          </div>
        );
      } else if (active) {
        return (
          <div styleName="actionWrapper">
            <Well>
              <ActionText
                style={{
                  padding: 0,
                  margin: 5,
                  marginBottom: 10
                }}
              >
                <i className="material-icons">group_work</i> Grouping by
              </ActionText>
              <SelectedWellItem
                title={get(findField(activeView.grouped_by), "name")}
                icon={
                  <i
                    className="material-icons"
                    style={{ cursor: "pointer" }}
                    onClick={() => updateViewMeta({ groupedBy: null })}
                    role="button"
                    tabIndex="0"
                  >
                    close
                  </i>
                }
              />
            </Well>
          </div>
        );
      }

      return (
        <GroupByPicker onChange={updateViewMeta} fields={fields}>
          <ActionText>
            <i className="material-icons">group_work</i>
            Group by
          </ActionText>
        </GroupByPicker>
      );
    }
    default:
      return null;
  }
};

const PanelWrapper = ({ active, children }) => (
  <Div my={active ? 2 : 0}>{children}</Div>
);

const Panel = props => (
  <PanelWrapper active={props.active}>
    <SelectedPicker {...{ ...props, picker: <Pickers {...props} /> }} />
  </PanelWrapper>
);

const Link = ({}) => {};

@CSSModules(css)
class OrgNav extends Base {
  duplicateView = async () => {
    const isReport = this.props.activeView.view_type === "report";
    const view = await this.props.createView({
      moduleId: this.props.moduleId,
      name: `${this.props.activeView.name} copy`,
      viewType: this.props.activeView.view_type,
      reportId: this.props.activeView.report_id,
      visibleFields: isReport ? null : this.props.activeView.visible_fields,
      fieldOrder: isReport ? null : this.props.activeView.field_order,
      fieldWidths: isReport ? null : this.props.activeView.field_widths,
      isPublic: this.props.activeView.is_public,
      groupedBy: this.props.activeView.grouped_by,
      cardFields: this.props.activeView.card_fields,
      filters: this.props.activeView.filters,
      quickFilters: this.props.activeView.quick_filters,
      filterByEventId: this.props.activeView.filter_by_event_id,
      setAsActive: true,
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId
      }
    });

    await this.props.getViews({
      moduleId: this.props.moduleId,
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId,
        accountRecordTypeId: this.props.params.groupId
      }
    });

    this.selectView(view.id);
  };

  componentDidMount() {
    this.fetchRecordTypes();
  }

  canMakePrivateOrDelete = viewId => {
    const otherViews = this.props.views.filter(v => v.id !== viewId);
    return otherViews.length && otherViews.filter(v => v.is_public).length;
  };

  deleteView = async () => {
    if (!this.canMakePrivateOrDelete(this.props.activeView.id)) {
      return this.props.showSnackbar({
        message: "Oops! You must have at least one public view",
        action: "OK"
      });
    }

    await this.props.deleteView({
      moduleId: this.props.moduleId,
      viewId: this.props.activeView.id,
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId
      }
    });

    await this.props.getViews({
      moduleId: this.props.moduleId,
      options: {
        orgId: this.props.params.orgId,
        eventId: this.props.params.eventId,
        accountRecordTypeId: this.props.params.groupId
      }
    });

    return this.selectView(
      this.props.views.filter(v => v.id !== this.props.activeView.id)[0].id
    );
  };

  selectQuickFilter = ({ id }) => {
    const { deselectAllRows } = this.props;
    const quickFilters = this.props.activeView.quick_filters || [];

    if (id) {
      if (quickFilters.includes(id)) {
        deselectAllRows();
        return this.updateViewMeta({
          quickFilters: quickFilters.filter(f => f !== id)
        });
      }

      return this.updateViewMeta({
        quickFilters: uniq([...quickFilters, id])
      });
    }

    return this.updateViewMeta({
      quickFilters: quickFilters.filter(f => f !== VIEW_MY_RECORDS_ID)
    });
  };

  toggleIsPublic = isPublic => {
    if (!this.canMakePrivateOrDelete(this.props.activeView.id)) {
      return this.props.showSnackbar({
        message: "Oops! You must have at least one public view",
        action: "OK"
      });
    }

    this.updateViewMeta({ isPublic });
  };

  selectMyRecords = () => {
    this.selectQuickFilter({
      id: VIEW_MY_RECORDS_ID
    });
  };

  updateViewName = name => {
    this.updateViewMeta({ name });
  };

  parentSource = () => {
    if (
      this.props.routes.find(
        r => r.name && r.name.toLowerCase().includes("inventory")
      )
    ) {
      return true;
    }
    return false;
  };

  selectEvent = ({ id }) => {
    const { deselectAllRows } = this.props;

    deselectAllRows();

    return this.updateViewMeta({
      filterByEventId: id,
      quickFilters: []
    });
  };

  fetchRecordTypes = () => {
    this.props.getAllEventRecordTypes({
      moduleId: this.props.moduleId,
      options: {
        orgId: this.props.params.orgId,
        allEvents: true
      }
    });
  };

  render() {
    const {
      forms,
      allEventRecordTypes,
      records,
      moduleDetails,
      fields,
      location,
      params,
      activeView,
      user
    } = this.props;
    const quickFilters = get(activeView, "quick_filters", []);

    const selectedEventId = this.props.activeView.filter_by_event_id;

    const eventRecordTypes = allEventRecordTypes.filter(
      r => r.event_id === selectedEventId
    );

    const recordTypesWithHandlers = eventRecordTypes
      .map(recordType => ({
        ...recordType,
        isSelected: quickFilters.includes(recordType.id),
        onClick: () => this.selectQuickFilter({ id: recordType.id }),
        countOfRecords: this.countRows(recordType.id, records)
      }))
      .sort((a, b) => a.name.localeCompare(b.name));

    const flattenedFields = flattenFields(fields);
    const findField = key => flattenedFields.find(f => f.id === key);
    const title = resolveTitle({
      moduleId: moduleDetails.id,
      meta: { module: moduleDetails }
    });
    const recordNamePluralLowercase = resolveRecordName({
      moduleId: moduleDetails.id,
      plural: true
    }).toLowerCase();

    const panels = [
      {
        type: "filter",
        active: get(activeView, "filters.filters.length"),
        enabled: true
      },
      {
        type: "sortBy",
        active: get(activeView, "sort_by.length"),
        enabled: true
      },
      {
        type: "groupBy",
        active: Boolean(activeView.grouped_by),
        enabled: !["report", "list"].includes(activeView.view_type)
      }
    ].filter(p => p.enabled);

    return (
      <Container>
        <SidebarTitle
          {...{
            title,
            location,
            params
          }}
        />
        <Views
          {...{
            moduleId: params.moduleId,
            viewId: params.viewId,
            activeViewName: activeView.name,
            activeViewType: activeView.view_type,
            updateViewName: this.updateViewName,
            activeViewIsPublic: activeView.is_public,
            toggleIsPublic: this.toggleIsPublic,
            selectView: this.selectView,
            duplicateView: this.duplicateView,
            deleteView: this.deleteView,
            reports: this.getReports()
          }}
        />

        <EventPicker
          onSelectEvent={this.selectEvent}
          selectedEventId={selectedEventId}
        />
        {selectedEventId ? (
          <Div
            pl={1}
            fs={1}
            color="white"
            display="row.flex-start.center"
            onClick={() => {
              this.selectEvent({ id: null });
            }}
          >
            <CloseIcon mr={1} color="white" /> Clear filters
          </Div>
        ) : null}

        <QuickFilters
          {...{
            showMainFilters: false,
            moduleHasApproval: false,
            selectQuickFilter: this.selectQuickFilter,
            selectMyRecords: this.selectMyRecords,
            myRecordsIsSelected: quickFilters.includes(VIEW_MY_RECORDS_ID),
            allRecordsIsSelected: !quickFilters.includes(VIEW_MY_RECORDS_ID),
            recordNamePluralLowercase,
            countOfRecords: this.countRows(null, records),
            countOfMyRecords: this.countRows(VIEW_MY_RECORDS_ID, records),
            recordTypes: recordTypesWithHandlers,
            forms: []
          }}
        />

        <div styleName="separator" />
        {panels.map(({ type, active }) => (
          <Panel
            {...{
              key: type,
              type,
              active,
              activeView,
              fields: flattenedFields,
              findField,
              kanbanFieldFilter,
              addSort: this.addSort,
              updateViewMeta: this.updateViewMeta,
              removeFilter: this.removeFilter,
              removeSort: this.removeSort,
              handleKanbanFieldsChange: this.handleKanbanFieldsChange
            }}
          />
        ))}
        {!["kanban", "calendar"].includes(activeView.view_type) ? (
          <ActionText onClick={this.showEditColumnsModal}>
            <i className="material-icons">view_column</i>
            Show/Hide Fields
          </ActionText>
        ) : null}
        <div styleName="separator" />

        <CanUserDo action={`${this.props.moduleId}_manage`}>
          <PrimaryLink
            name="Setup"
            active={false}
            onClick={this.goToModuleSetup}
          />
        </CanUserDo>
      </Container>
    );
  }
}

export default decorate(OrgNav);
