import { get } from "lodash";

export default function(value) {
  return get(value, "records", [])
    .sort((a, b) =>
      String(a.name)
        .toLowerCase()
        .localeCompare(String(b.name).toLowerCase())
    )
    .map(({ name }) => name)
    .join(", ");
}
