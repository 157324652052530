import React from "react";
import { get } from "lodash";
import { baseIcon, baseText, baseContainer } from "../_base";

const getEventbriteEventId = value => get(value, ["meta", "eventbriteEventId"]);
const getEventbriteOrderId = value => get(value, ["meta", "eventbriteOrderId"]);

const SourceEventbriteOrder = ({ value }) => {
  const orderId = getEventbriteOrderId(value);
  return (
    <div style={baseContainer}>
      <i
        className="material-icons"
        style={{ ...baseIcon, transform: "rotateZ(135deg)" }}
      >
        link
      </i>
      <a
        href={`https://www.eventbrite.com/reports?eid=${getEventbriteEventId(
          value
        )}&rid=h&filterby=all,${orderId}`}
        target="_blank"
        style={baseText}
        title="View order on Eventbrite"
      >
        Order #{orderId}
      </a>
    </div>
  );
};

SourceEventbriteOrder.defaultProps = {
  value: {}
};

export default SourceEventbriteOrder;
