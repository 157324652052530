import PropTypes from "prop-types";
import React from "react";
import Loading from "components/Global/Loading";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Content = ({ children, loading }) => (
  <div styleName="container">
    {children}
    {loading ? <Loading className={css.loading} content="Loading..." /> : null}
  </div>
);

Content.defaultProps = {
  loading: false
};

Content.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
export default CSSModules(Content, css);
