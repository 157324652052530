import React, { Component } from "react";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import * as R from "ramda";

import Field from "Submission/Editor/View/Field";
import { actions } from "Submission/Editor";
import * as SubmissionSelectors from "Submission/Editor/selectors";
import * as UserSelectors from "redux/modules/user/selectors";

import { Div } from "components/Base";

const decorate = connect(
  (state, props) => ({
    user: UserSelectors.user(state),
    details: SubmissionSelectors.eventDetails(state),
    submission: SubmissionSelectors.submission(state),
    form: SubmissionSelectors.form(state),
    fulfillmentBlockFieldsAndValues: SubmissionSelectors.fulfillmentBlockFieldsAndValues(
      state,
      props
    )
  }),
  {
    addValue: actions.addFulfillmentBlockValue
  }
);

class FulfillmentField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldBeingEdited: null
    };
  }

  onChange = ({ field: { id, savePath }, fieldValue }) => {
    if (
      !isEqual(
        this.props.fulfillmentBlockFieldsAndValues.values[id],
        fieldValue
      )
    ) {
      const value = fieldValue.value;
      const order = this.props.submission.related_order;

      this.props.addValue({
        // submission props
        submissionRecordId: this.props.submission.submission_record_id,
        moduleFieldId: this.props.field.id,
        submissionId: this.props.submission.id,
        fieldId: savePath,
        userId: this.props.user.id,
        // block props
        orderId: this.props.submission.related_order.id,
        method: this.props.field.settings.method,
        address: {
          addressLine1: order.shipping_address_line_1,
          addressLine2: order.shipping_address_line_2,
          city: order.shipping_city,
          state: order.shipping_state,
          zip: order.shipping_zip,
          country: order.shipping_country,
          [savePath]: value
        },
        beingPickedUpBy: {
          fname: order.pickup_person_fname,
          lname: order.pickup_person_lname,
          [savePath]: value
        }
      });
    }
  };

  closeField = () => {
    this.setState({
      fieldBeingEdited: null
    });
  };

  editField = field => {
    if (!this.state.fieldBeingEdited) {
      this.setState({
        fieldBeingEdited: field.id
      });
    }
  };

  render() {
    const {
      readOnly,
      details,
      submission,
      fulfillmentBlockFieldsAndValues
    } = this.props;
    const fieldsToShow = fulfillmentBlockFieldsAndValues.fields;

    const fieldsWithHandlers = R.compose(
      R.map(field => {
        return {
          ...field,
          disabled: this.props.disabled,
          name: field.alias || field.name,
          value: fulfillmentBlockFieldsAndValues.values[field.id],
          changeField: fieldValue =>
            this.onChange({
              field,
              fieldValue
            })
        };
      })
    )(fieldsToShow);

    if (!fieldsWithHandlers.length) {
      return null;
    }

    return (
      <Div width={1} bra={2} bc="gray2" b={1} p={5}>
        <Div fw={3} fs={4} color="gray7" mb={2}>
          {this.props.field.name}
        </Div>

        {fieldsWithHandlers.map(field => {
          return (
            <Field
              key={field.id}
              readOnly={readOnly}
              isEditing={field.id === this.state.fieldBeingEdited}
              eventDetails={details}
              editField={() => this.editField(field)}
              closeField={this.closeField}
              changeField={field.changeField}
              value={field.value}
              values={fulfillmentBlockFieldsAndValues.values}
              fields={fieldsToShow}
              field={field}
              metaData={this.props.getMetaData(
                fieldsToShow,
                submission,
                fulfillmentBlockFieldsAndValues.values,
                field
              )}
            />
          );
        })}
      </Div>
    );
  }
}

export default decorate(FulfillmentField);
