import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";

import { actions, getters } from "Reports/EditReportTemplateModal";

import { registerError } from "redux/modules/errors/actions";

import { getCredentials } from "redux/modules/user/selectors";

import { eventId as getEventId } from "redux/modules/event/selectors";

import Api from "./api";
import { makeFuture } from "../../utils/General/sagas";
import { showModal } from "../../redux/modules/modal/actions";
import DeleteConfirmation from "../../components/Global/CRM/Modals/DeleteConfirmation";
import ModalWrapper from "../../components/Global/Modal/Wrappers/Black";
import React from "react";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  return {
    credentials,
    eventId
  };
};

const init = function*({ payload: { templateId } }) {
  try {
    yield put(actions.setLoading(true));

    const { credentials, eventId } = yield call(getParams);

    const { payload: template } = yield call(Api.getTemplate, {
      credentials,
      templateId
    });

    const { record_types: recordTypes } = yield call(Api.getRecordTypes, {
      credentials,
      eventId,
      moduleId: template.module_id
    });

    yield put(actions.setInitialData({ template, recordTypes }));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading template"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const refetchTemplate = function*({ payload: templateId }) {
  try {
    const { credentials } = yield call(getParams);

    const { payload: template } = yield call(Api.getTemplate, {
      credentials,
      templateId: templateId
    });

    yield put(actions.setTemplate(template));
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading template"
          }
        ])
      )
    ]);
  }
};

const addRelatedModuleSections = function*({
  payload: { parentSectionId, selectedModules }
}) {
  const template = yield select(getters.template);
  try {
    const { credentials } = yield call(getParams);

    yield call(Api.createTemplateSection, {
      credentials,
      data: {
        bulk: true,
        sections: selectedModules.map(m => ({
          parentSectionId,
          templateId: template.id,
          moduleId: m.id,
          lookupFieldId: m.lookup_field.id,
          name: `${m.name} (${m.lookup_field.name})`,
          order: m.order
        }))
      }
    });
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error add sections"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.refetchTemplate(template.id));
  }
};

const updateSection = function*() {
  try {
    yield put(actions.setEditingSectionId(-1));
    const { credentials } = yield call(getParams);
    const section = yield select(getters.tempSection);

    yield call(Api.updateTemplateSection, {
      credentials,
      data: { section: { ...section, sectionId: section.id } }
    });
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error add sections"
          }
        ])
      )
    ]);
  } finally {
    const template = yield select(getters.template);
    yield put(actions.refetchTemplate(template.id));
  }
};

const deleteSection = function*({ payload: sectionId }) {
  try {
    yield put(actions.setEditingSectionId(-1));
    const { credentials } = yield call(getParams);

    yield call(Api.deleteTemplateSection, {
      credentials,
      data: { sectionId }
    });
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error deleting sections"
          }
        ])
      )
    ]);
  } finally {
    const template = yield select(getters.template);
    yield put(actions.refetchTemplate(template.id));
  }
};

const updateSectionOrder = function*({ payload: orderedSections }) {
  try {
    yield put(actions.setEditingSectionId(-1));
    const { credentials } = yield call(getParams);

    yield call(Api.updateTemplateSection, {
      credentials,
      data: {
        bulk: true,
        sections: orderedSections.map(({ id }, order) => ({
          sectionId: id,
          order
        }))
      }
    });
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error add sections"
          }
        ])
      )
    ]);
  } finally {
    const template = yield select(getters.template);
    yield put(actions.refetchTemplate(template.id));
  }
};

const updateTemplate = function*({ payload: { onDone } }) {
  try {
    const { credentials } = yield call(getParams);
    const template = yield select(getters.template);

    yield call(Api.updateTemplate, {
      credentials,
      data: {
        templateId: template.id,
        name: template.name,
        visibleFields: template.visible_fields,
        fieldOrder: template.field_order,
        sortBy: template.sort_by,
        filters: template.filters,
        recordTypeIds: template.record_type_ids
      }
    });
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error updating template"
          }
        ])
      )
    ]);
  } finally {
    yield call(onDone);
  }
};

const showDeleteReportModal = function*() {
  const handleDelete = makeFuture();
  yield put(
    showModal({
      content: (
        <DeleteConfirmation
          heading="Delete Record?"
          message={
            <div>
              {`
                Are you sure you want to remove this report template?
                `}
              <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                This cannot be undone.
              </div>
            </div>
          }
          onConfirm={() => handleDelete.done(true)}
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleDelete.onRealized);
};

const deleteTemplate = function*({ payload: { onDone, templateId } }) {
  const result = yield call(showDeleteReportModal);

  if (!result) {
    return false;
  }

  try {
    const { credentials } = yield call(getParams);

    yield call(Api.deleteTemplate, {
      credentials,
      templateId: templateId
    });
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error deleting a template"
          }
        ])
      )
    ]);
  } finally {
    yield call(onDone);
  }

  return true;
};

const updateTemplateLandscape = function*({ payload: landscape }) {
  const template = yield select(getters.template);

  try {
    const { credentials } = yield call(getParams);

    yield call(Api.updateTemplate, {
      credentials,
      data: {
        templateId: template.id,
        landscape
      }
    });
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error updating template landscape"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.refetchTemplate(template.id));
  }
};

const updateRecordTypedIds = function*({ payload: { isSelected, id } }) {
  try {
    yield put(actions.toggleRecordTypes({ isSelected, id }));

    const { credentials } = yield call(getParams);

    const template = yield select(getters.template);

    yield call(Api.updateTemplate, {
      credentials,
      data: {
        templateId: template.id,
        recordTypeIds: template.record_type_ids
      }
    });
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error updating template"
          }
        ])
      )
    ]);
  }
};

const selectAllRecordTypedIds = function*() {
  try {
    yield put(actions.clearSelectedRecordTypes());

    const { credentials } = yield call(getParams);

    const template = yield select(getters.template);

    yield call(Api.updateTemplate, {
      credentials,
      data: {
        templateId: template.id,
        recordTypeIds: template.record_type_ids
      }
    });
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error updating template"
          }
        ])
      )
    ]);
  }
};

const selectModuleFields = function*({
  payload: { sectionId, selectedFields }
}) {
  const template = yield select(getters.template);

  try {
    const { credentials } = yield call(getParams);
    const data = {
      visibleFields: selectedFields.map(f => f.id),
      fieldOrder: selectedFields.reduce((map, f, index) => {
        map[f.id] = index;
        return map;
      }, {})
    };

    if (sectionId) {
      yield put(actions.setEditingSectionId(-1));
      yield call(Api.updateTemplateSection, {
        credentials,
        data: { section: { ...data, sectionId } }
      });
    } else {
      yield call(Api.updateTemplate, {
        credentials,
        data: {
          templateId: template.id,
          ...data
        }
      });
    }
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error updating template"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.refetchTemplate(template.id));
  }
};

const addSort = function*({ payload: { sectionId, item } }) {
  const template = yield select(getters.template);
  const { credentials } = yield call(getParams);

  try {
    if (sectionId) {
      const updatedSortBy = [
        ...template.sections
          .find(s => s.id === sectionId)
          .sort_by.filter(f => f.fieldId !== item.fieldId),
        item
      ];

      const data = {
        sortBy: updatedSortBy
      };

      yield put(actions.setEditingSectionId(-1));
      yield call(Api.updateTemplateSection, {
        credentials,
        data: { section: { ...data, sectionId } }
      });
    } else {
      const updatedSortBy = [
        ...template.sort_by.filter(f => f.fieldId !== item.fieldId),
        item
      ];

      const data = {
        sortBy: updatedSortBy
      };

      yield call(Api.updateTemplate, {
        credentials,
        data: {
          templateId: template.id,
          ...data
        }
      });
    }
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error updating template"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.refetchTemplate(template.id));
  }
};

const removeSort = function*({ payload: { sectionId, idx } }) {
  const template = yield select(getters.template);
  const { credentials } = yield call(getParams);

  try {
    if (sectionId) {
      const updatedSortBy = template.sections
        .find(s => s.id === sectionId)
        .sort_by.filter((f, i) => i !== idx);

      const data = {
        sortBy: updatedSortBy
      };

      yield put(actions.setEditingSectionId(-1));
      yield call(Api.updateTemplateSection, {
        credentials,
        data: { section: { ...data, sectionId } }
      });
    } else {
      const updatedSortBy = template.sort_by.filter((f, i) => i !== idx);

      const data = {
        sortBy: updatedSortBy
      };

      yield call(Api.updateTemplate, {
        credentials,
        data: {
          templateId: template.id,
          ...data
        }
      });
    }
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error updating template"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.refetchTemplate(template.id));
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchUpdateTemplate = function*() {
  yield takeEvery(actions.updateTemplate.type, updateTemplate);
};

const watchDeleteTemplate = function*() {
  yield takeEvery(actions.deleteTemplate.type, deleteTemplate);
};

const watchUpdateTemplateLandscape = function*() {
  yield takeEvery(
    actions.updateTemplateLandscape.type,
    updateTemplateLandscape
  );
};

const watchRefetchTemplate = function*() {
  yield takeEvery(actions.refetchTemplate.type, refetchTemplate);
};

const watchAddRelatedModuleSections = function*() {
  yield takeEvery(
    actions.addRelatedModuleSections.type,
    addRelatedModuleSections
  );
};

const watchUpdateSection = function*() {
  yield takeEvery(actions.updateSection.type, updateSection);
};

const watchDeleteSection = function*() {
  yield takeEvery(actions.deleteSection.type, deleteSection);
};

const watchUpdateSectionOrder = function*() {
  yield takeEvery(actions.updateSectionOrder.type, updateSectionOrder);
};

const watchUpdateRecordTypedIds = function*() {
  yield takeEvery(actions.updateRecordTypedIds.type, updateRecordTypedIds);
};

const watchSelectAllRecordTypedIds = function*() {
  yield takeEvery(
    actions.selectAllRecordTypedIds.type,
    selectAllRecordTypedIds
  );
};

const watchSelectModuleFields = function*() {
  yield takeEvery(actions.selectModuleFields.type, selectModuleFields);
};

const watchAddSort = function*() {
  yield takeEvery(actions.addSort.type, addSort);
};

const watchRemoveSort = function*() {
  yield takeEvery(actions.removeSort.type, removeSort);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchRefetchTemplate),
    fork(watchUpdateTemplate),
    fork(watchUpdateTemplateLandscape),
    fork(watchAddRelatedModuleSections),
    fork(watchUpdateSection),
    fork(watchUpdateSectionOrder),
    fork(watchUpdateRecordTypedIds),
    fork(watchSelectAllRecordTypedIds),
    fork(watchDeleteSection),
    fork(watchSelectModuleFields),
    fork(watchAddSort),
    fork(watchRemoveSort),
    fork(watchDeleteTemplate)
  ]);
};

export default rootSaga;
