import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { getGroupAffiliationRows } from "Items/Manage/selectors";
import { getters } from "Items/Manage";

import CardByAccount from "Items/Manage/View/CardByAccount";
import { Div } from "components/Base";

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    rows: getGroupAffiliationRows(state, props),
    mode: getters.mode(state, props)
  }))
);

const GroupAffiliationView = ({
  rows,
  mode,
  handlers,
  location,
  instanceId = ""
}) => (
  <Div m={3}>
    {rows.map(
      ({
        passes,
        allLineItemIds,
        allCredentialLineItemIds,
        allMealLineItemIds,
        account,
        contacts
      }) => (
        <CardByAccount
          instanceId={instanceId}
          key={account.id}
          passes={passes}
          allLineItemIds={allLineItemIds}
          allCredentialLineItemIds={allCredentialLineItemIds}
          allMealLineItemIds={allMealLineItemIds}
          account={account}
          contacts={contacts}
          mode={mode}
          expandUrl={`${location.pathname}/account/${account.id}`}
          handlers={handlers}
        />
      )
    )}
  </Div>
);

export default decorate(GroupAffiliationView);
