import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const NumberedRowActionsCell = ({ rowIdx }) => (
  <div styleName="content">{rowIdx + 1}</div>
);

NumberedRowActionsCell.propTypes = {
  rowIdx: PropTypes.number.isRequired
};

NumberedRowActionsCell.defaultProps = {
  rowIdx: 0
};
export default CSSModules(NumberedRowActionsCell, css);
