import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Needs from "./Needs";

import { getNeeds } from "redux/modules/inventory/itemSummary/actions";
import {
  needs,
  isFetching
} from "redux/modules/inventory/itemSummary/selectors";
import { records } from "redux/modules/modules/records/selectors";

function mapStateToProps(state) {
  return {
    isFetching: isFetching(state),
    needs: needs(state),
    getRecords: moduleId => records(state, moduleId)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getNeeds
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Needs);
