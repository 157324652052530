import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import { NAMESPACE, RECURRENCE_TYPES } from "./constants";

const iniState = {
  loading: false,
  activityId: "",
  scheduleId: "",
  name: "",
  isTask: false,
  dependentOn: [],
  recurrence: RECURRENCE_TYPES.NO_REPEAT,
  startsAtDate: "",
  startsAtTime: "",
  endsAtDate: "",
  endsAtTime: "",
  duration: "",
  isAllDay: false,
  visibility: "default",
  color: "#fff",
  notifications: [],
  values: {},
  fields: [],
  fieldsById: {},
  history: [],
  editingFieldId: "",
  activityEdited: false
};

const model = createHandlers({
  iniState,
  reducers: {
    saveActivity: R.identity,
    openActivity: R.identity,
    closeSidebar: R.identity,
    refreshData: R.identity,
    markCompleted: R.identity,
    onSavePopoverEditor: R.identity,
    closeEditor: R.identity,
    onEdit: R.identity,
    setInitialData: (
      state,
      {
        payload: {
          activity: {
            name,
            dependent_on,
            is_task,
            is_all_day,
            visibility,
            color,
            notifications
          },
          startsAtDate,
          startsAtTime,
          endsAtDate,
          endsAtTime,
          fields,
          values,
          history,
          activityId,
          scheduleId
        }
      }
    ) => ({
      name,
      startsAtDate,
      startsAtTime,
      endsAtDate,
      endsAtTime,
      dependentOn: dependent_on,
      isTask: is_task,
      isAllDay: is_all_day,
      color,
      notifications,
      visibility,
      values,
      history,
      fields,
      fieldsById: R.indexBy(R.prop("id"), fields),
      activityId,
      scheduleId,
      editingFieldId: "",
      activityEdited: false
    }),
    edit: (_, { payload: fieldId }) => ({
      editingFieldId: fieldId
    }),
    updateValue: ({ values }, { payload: { fieldId, value } }) => ({
      editingFieldId: "",
      values: {
        ...values,
        [fieldId]: value
      }
    }),
    updateOptionsField: (_, { payload: { fieldId, value } }) => ({
      [fieldId]: value
    }),
    markAsEdited: R.always({ activityEdited: true })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
