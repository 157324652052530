import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { actions } from "../model";
import CollapsablePanel from "ui-kit/CollapsiblePanel";
import { Div, Text1 } from "components/Base";
import { addS } from "utils/General";
import { TypesTable } from "./Tables";
import GroupMorePopover from "./GroupMorePopover";

const decorate = connect(
  null,
  {
    deleteZoneItem: actions.deleteZoneItem,
    reorderZoneItems: actions.reorderZoneItems,
    moveZoneItemUp: actions.moveZoneItemUp,
    moveZoneItemDown: actions.moveZoneItemDown,
    moveZoneCategoryUp: actions.moveZoneCategoryUp,
    moveZoneCategoryDown: actions.moveZoneCategoryDown,
    addUpdateZoneItem: actions.addUpdateZoneItem,
    showDeleteGroupModal: actions.showDeleteGroupModal,
    showUpdateGroupModal: actions.showUpdateGroupModal
  }
);

const CollapsableGroup = ({
  group,
  groupsLength,
  deleteZoneItem,
  reorderZoneItems,
  moveZoneItemUp,
  moveZoneItemDown,
  moveZoneCategoryUp,
  moveZoneCategoryDown,
  addUpdateZoneItem,
  showDeleteGroupModal,
  showUpdateGroupModal
}) => (
  <CollapsablePanel
    bg="transparent"
    arrowColor="neutral7"
    mt={2}
    right={false}
    heading={() => (
      <Div display="row.space-between.center" width={1} pr={2} py={1}>
        <Div display="row.flex-start.center">
          <Div color="neutral7" fs={4} fw={4} mr={2}>
            {group.name}
          </Div>
          <GroupMorePopover
            options={[
              [
                "Edit",
                () => {
                  showUpdateGroupModal({ group });
                }
              ],
              groupsLength > 1
                ? [
                    "Delete",
                    () => {
                      showDeleteGroupModal({ group });
                    }
                  ]
                : [],
              [
                "Move Up",
                () => {
                  moveZoneCategoryUp({ group, currentPosition: group.order });
                }
              ],
              [
                "Move Down",
                () => {
                  moveZoneCategoryDown({ group, currentPosition: group.order });
                }
              ]
            ]}
          />
        </Div>
        <Text1>{`${R.length(group.zones)} zone${addS(
          R.length(group.zones)
        )}`}</Text1>
      </Div>
    )}
  >
    <TypesTable
      types={R.map(
        item => ({
          ...item,
          onCredentialClick: () =>
            addUpdateZoneItem({
              id: item.id
            }),
          onEdit: () =>
            addUpdateZoneItem({
              id: item.id
            }),
          onClone: () =>
            addUpdateZoneItem({
              id: item.id,
              clone: true
            }),
          onDelete: () => {
            deleteZoneItem({ itemId: item.id });
          },
          moveCredentialUp: () =>
            moveZoneItemUp({ item, order: item.order, groupId: group.id }),
          moveCredentialDown: () =>
            moveZoneItemDown({ item, order: item.order, groupId: group.id })
        }),
        group.zones
      )}
      dragTypeId={"TypesTable" + group.id}
      onReorder={newOrder => {
        reorderZoneItems(newOrder);
      }}
    />
  </CollapsablePanel>
);

export default decorate(CollapsableGroup);
