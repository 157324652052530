import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Input from "components/Global-2016/Forms/Input";
import Wysiwyg from "components/Global/Wysiwyg";

@CSSModules(css)
class EditPortalMessageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      title: props.template.title,
      contentFormatted: props.template.content_formatted,
      contentPlaintext: props.template.content_plaintext
    };
  }

  @autobind
  onSave(e) {
    e.preventDefault();
    if (!this.state.contentPlaintext.length) {
      return this.setState({ error: "Cannot save empty message" });
    }
    const { done, hideModal, template } = this.props;
    const { title, contentFormatted, contentPlaintext } = this.state;

    done({ title, contentFormatted, contentPlaintext, id: template.id });
    hideModal();
  }

  @autobind
  deleteTemplate() {
    this.props.hideModal();
    this.props.deleteTemplate(this.props.template.id);
  }
  @autobind
  onTitleChange(e) {
    this.setState({ title: e.target.value });
  }

  @autobind
  onTextChange(contentFormatted, contentPlaintext) {
    this.setState({ contentFormatted, contentPlaintext });
  }

  render() {
    return (
      <div>
        <div className="modal-header">
          <div className="modal-title">
            {this.props.deleteTemplate ? "Edit" : "Create"} Event Update
          </div>
        </div>

        <div className="modal-body">
          <div className="modal-body-wrapper">
            <div styleName="body">
              <form onSubmit={this.onSave}>
                <div styleName="inputWrapper">
                  <Input
                    label="Title"
                    onChange={this.onTitleChange}
                    value={this.state.title}
                    required
                  />
                </div>
                <span
                  style={{ color: "red", fontSize: 12, fontStyle: "italic" }}
                >
                  {this.state.error}
                </span>
                <Wysiwyg
                  text={this.state.contentFormatted}
                  onChange={this.onTextChange}
                  height={400}
                  placeholder="Enter text here..."
                  {...this.props.wysiwygProps}
                />
                <div styleName="actionRow">
                  <button styleName="done" type="submit">
                    Done
                  </button>
                  {!!this.props.deleteTemplate && (
                    <a onClick={this.deleteTemplate}>
                      <i
                        className="material-icons"
                        title="Delete"
                        styleName="icon"
                      >
                        delete
                      </i>
                    </a>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditPortalMessageModal.getModalClassName = () => "EditTemplateModal";

EditPortalMessageModal.propTypes = {
  hideModal: PropTypes.func,
  deleteTemplate: PropTypes.func,
  done: PropTypes.func.isRequired,
  template: PropTypes.object.isRequired,
  wysiwygProps: PropTypes.object
};

export default EditPortalMessageModal;
