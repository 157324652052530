import React from "react";
import * as R from "ramda";

import { connect } from "react-redux";
import { getters } from "EventLight/Agenda/AddSessionModal/model";
import { Div } from "components/Base";
import { TABS } from "EventLight/Agenda/AddSessionModal/constants";
import BasicDetails from "./BasicDetails";
import AttendeeSettings from "./AttendeeSettings";
import Content from "./Content";
import { WithFormInstanceProvider } from "ui-kit/Form/View";

import { SESSION_FORM_ID } from "EventLight/Agenda/AddSessionModal/constants";

const decorate = R.compose(
  WithFormInstanceProvider(R.always(SESSION_FORM_ID)),
  connect(
    state => ({
      activeTab: getters.activeTab(state)
    }),
    null
  )
);

const Body = ({ activeTab }) => (
  <Div>
    {activeTab === TABS.BASIC_DETAILS ? <BasicDetails /> : null}
    {activeTab === TABS.ATTENDEE_SETTINGS ? <AttendeeSettings /> : null}
    {activeTab === TABS.CONTENT ? <Content /> : null}
  </Div>
);

export default decorate(Body);
