import PropTypes from "prop-types";
import React, { Component } from "react";
import Label from "components/Global-2016/Forms/Label";
import resolveRecordName from "components/Event/Module/utils/resolveRecordName";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { SmallToggle, Div, DatePicker, Dropdown } from "components/Base";
import Tooltip from "components/Global/CRM/Tooltip";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import moment from "moment";
import { MODES } from "../../../../constants";

const Divider = ({ name }) => (
  <Div display="row.flex-start.center" uppercase mt={7} mb={3}>
    <Div fw={3} color="gray7" mr={3} fs={3}>
      {name}
    </Div>
    <Div height={2} bg="gray2" flex={1} />
  </Div>
);

const SelectDateButton = ({ onClick, customDateString }) => (
  <Div onClick={onClick} display="row.flex-start.center">
    {customDateString} &middot;
    <Div underline ml={1}>
      Change Date
    </Div>
  </Div>
);

@CSSModules(css)
class Sidebar extends Component {
  onReplyToChange = e => this.props.updateReplyTo(e.target.value);
  onInvoiceDueDateChange = value => this.props.updateInvoiceDueDate(value);
  onInvoiceNotesChange = e => this.props.updateInvoiceNotes(e.target.value);

  render() {
    const {
      mode,
      records,
      onOptionSelect,
      options,
      selectedOptions,
      replyTo,
      moduleId,
      toggleGroupByRecipient,
      groupByRecipient,
      emailType,
      invoiceDueDate,
      invoiceNotes
    } = this.props;
    const recordName = resolveRecordName({ moduleId, plural: false });
    const recordNamePluralLowercase = resolveRecordName({
      moduleId,
      plural: true
    }).toLowerCase();
    const recordNameLowercase = recordName.toLowerCase();
    const showGroupEmailsToggle = moduleId !== STANDARD_MODULE_IDS.orders.id;

    return (
      <div className={css.sidebarContainer}>
        <div className={css.line}>
          <div className={css.selectedRecords}>
            <i className="material-icons">filter_list</i>
            {` ${records.length} selected ${
              records.length > 1
                ? recordNamePluralLowercase
                : recordNameLowercase
            }`}
          </div>
        </div>
        <div className={css.line}>
          <Label>
            <span className={css.label}>Send to:</span>
          </Label>
          <Dropdown
            onChange={onOptionSelect}
            options={options}
            value={selectedOptions}
          />
        </div>

        {mode === MODES.EMAIL ? (
          <div className={css.line}>
            <Label>
              <span className={css.label}>Replies sent to:</span>
            </Label>
            <input
              className={css.replyEmail}
              type="text"
              name="from"
              onChange={this.onReplyToChange}
              value={replyTo}
            />
          </div>
        ) : null}

        {showGroupEmailsToggle ? (
          <div className={css.line}>
            <Label>
              <span
                className={css.label}
                style={{
                  marginRight: 10
                }}
              >
                Group {mode === MODES.SMS ? "messages" : "emails"} by recipient:
              </span>
              <Tooltip
                message={`If enabled, the records you've selected will be grouped by the ${
                  mode === MODES.SMS ? "phone number" : "email address"
                } of the recipient. You can disable this if you want a recipient to receive multiple ${
                  mode === MODES.SMS ? "messages" : "emails"
                }.`}
                placement="bottom"
              />
            </Label>
            <SmallToggle
              active={groupByRecipient}
              onClick={toggleGroupByRecipient}
            />
          </div>
        ) : null}

        {emailType === "invoice" ? (
          <Div>
            <Divider name="Invoice Details" />

            <Div display="column" width={1}>
              <Div mb={3}>
                <div className={css.line}>
                  <Label>
                    <span className={css.label}>Due Date</span>
                  </Label>
                  <Div bg="neutral1" p={2} display="row.flex-start.center">
                    <DatePicker
                      defaultValue={invoiceDueDate}
                      onChange={this.onInvoiceDueDateChange}
                      Label={SelectDateButton}
                      labelProps={{
                        customDateString: moment(invoiceDueDate).format(
                          "MMM Do YYYY"
                        )
                      }}
                    />
                  </Div>
                </div>
              </Div>

              <Div>
                <div className={css.line}>
                  <Label>
                    <span className={css.label}>Invoice Terms & Notes</span>
                  </Label>
                  <textarea
                    style={{
                      width: "100%",
                      height: 80
                    }}
                    placeholder="ex: Terms of Service or thank you notes"
                    value={invoiceNotes}
                    onChange={this.onInvoiceNotesChange}
                  />
                </div>
              </Div>
            </Div>
          </Div>
        ) : null}

        {/*
        <div className={css.line}>
          <Label><span className={css.label}>Test Message</span></Label>
          <ButtonOutline
            className={css.testEmailButton}
            title="Send a test email"
          />
        </div>
        */}
      </div>
    );
  }
}

Sidebar.propTypes = {
  records: PropTypes.array.isRequired,
  options: PropTypes.shape({
    optionType: PropTypes.string,
    optionName: PropTypes.string,
    fieldId: PropTypes.string
  }).isRequired,
  selectedOptions: PropTypes.shape({
    optionType: PropTypes.string,
    optionName: PropTypes.string,
    fieldId: PropTypes.string
  }).isRequired,
  onOptionSelect: PropTypes.func.isRequired,
  updateReplyTo: PropTypes.func.isRequired,
  replyTo: PropTypes.string.isRequired,
  moduleId: PropTypes.string.isRequired
};

export default Sidebar;
