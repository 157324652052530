import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Heading = ({ title, linkTo, linkTitle, children, ...props }) => (
  <div styleName="container">
    <div styleName="text">{title}</div>
    {linkTo && (
      <Link to={linkTo} styleName="link" {...props}>
        {linkTitle}{" "}
        <i className="material-icons" styleName="icon">
          arrow_forward
        </i>
      </Link>
    )}
    {children}
  </div>
);

Heading.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  linkTo: PropTypes.string,
  linkTitle: PropTypes.string
};

export default CSSModules(Heading, css);
