import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { getActivities } from "redux/modules/activities/actions";
import { isFetching, activities } from "redux/modules/activities/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import { withRouter } from "react-router";
import { push } from "react-router-redux";

function mapStateToProps(state, props) {
  return {
    isFetching: isFetching(state, props.recordId),
    activities: activities(state, props.recordId),
    eventId: props.eventId || eventDetails(state).id
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getActivities,
      showModal,
      hideModal,
      push
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
