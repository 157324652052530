import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import { getFilteredDocumentTemplates } from "../selectors";
import { actions, getters } from "../index";

import { Div, AddIcon } from "components/Base";
import Circle from "ui-kit/Circle";
import Loading from "ui-kit/Loading";
import TemplateDocument from "./TemplateDocument";

const decorate = connect(
  state => ({
    documentTemplates: getFilteredDocumentTemplates(state),
    loading: getters.loading(state)
  }),
  {
    addUploadDocument: actions.addUploadDocument
  }
);

const TemplateList = ({
  documentTemplates,
  addUploadDocument,
  handlers,
  loading
}) => (
  <Div width={1} bra={1} bg="neutral1" mt={4} pt={3}>
    {loading ? (
      <Loading />
    ) : (
      <Div display="row" style={{ flexWrap: "wrap" }} height={1}>
        <Div
          width={199}
          height={240}
          display="row.center.center"
          bg={{ default: "neutral1", hover: "neutral2" }}
          style={{ cursor: "pointer" }}
          bra={1}
          ml={3}
          onClick={() => addUploadDocument()}
        >
          <Circle size={88} bg="primary7">
            <AddIcon color="white" size={48} />
          </Circle>
        </Div>
        {R.map(
          template => (
            <TemplateDocument template={template} handlers={handlers} />
          ),
          documentTemplates
        )}
      </Div>
    )}
  </Div>
);

export default decorate(TemplateList);
