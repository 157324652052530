import {
  put,
  all,
  takeEvery,
  fork,
  take,
  call,
  select
} from "redux-saga/effects";
import { actions, getters } from "Portal/PortalPagePGA/PortalPagePGA/model";
import { actions as NotificationModalActions } from "Portal/PortalPagePGA/NotificationModal/model";
import {
  getInterviewReplaysUrl,
  getHasEnabledPhoneNumber
} from "Portal/PortalPagePGA/PortalPagePGA/selectors";

import * as R from "ramda";
import { PAGE_ACTIONS, EVENT_TYPES } from "./constants";
import { eventId as getEventId } from "redux/modules/portal/selectors";
import { registerError } from "redux/modules/errors/actions";
import { getCredentials } from "redux/modules/user/selectors";
import communityApi from "Portal/PortalCommunity/api";
import * as snackbarActions from "redux/modules/snackbar/actions";
import { relatedAccountId } from "redux/modules/portal/user/selectors";

import { createPusherChannel } from "App/Data/sagas";

const mapIndexed = R.addIndex(R.map);

import { eventUUID as getEventUUID } from "redux/modules/portal/selectors";

function* pageUpdate() {
  const credentials = yield select(getCredentials);
  const accountId = yield select(relatedAccountId);
  const eventUUID = yield select(getEventUUID);

  try {
    const { payload } = yield call(communityApi.getPortalData, {
      credentials,
      eventUUID,
      accountId
    });

    yield put(
      actions.setInitialData({
        ...payload
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred getting portal media center"
        }
      ])
    );
  }
}

function* preferencesUpdate() {
  const credentials = yield select(getCredentials);
  const eventUUID = yield select(getEventUUID);

  try {
    const { payload } = yield call(communityApi.getPreferences, {
      credentials: credentials,
      eventUUID
    });

    yield put(actions.setPreferences(payload));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred getting preferences"
        }
      ])
    );
  }
}

function* sessionDelete() {
  yield call(pageUpdate);
}
function* sessionCreate() {
  yield call(pageUpdate);
}
function* sessionUpdate() {
  yield call(pageUpdate);
}
function* alertUpdate() {
  yield call(pageUpdate);
}
function* alertCreate() {
  yield call(pageUpdate);
}
function* alertDelete() {
  yield call(pageUpdate);
}
function* videoCreate() {
  yield call(pageUpdate);
}

export function* watchUpdates() {
  const eventId = yield select(getEventId);
  const socketChannel = yield call(createPusherChannel, {
    channelId: `event-${eventId}`,
    eventTypes: R.values(EVENT_TYPES),
    withEventTypes: true
  });

  while (true) {
    try {
      const action = yield take(socketChannel);
      const delegate = R.prop(action.eventType, {
        [EVENT_TYPES.SESSION_DELETE]: sessionDelete,
        [EVENT_TYPES.SESSION_CREATE]: sessionCreate,
        [EVENT_TYPES.SESSION_UPDATE]: sessionUpdate,
        [EVENT_TYPES.ALERT_UPDATE]: alertUpdate,
        [EVENT_TYPES.ALERT_CREATE]: alertCreate,
        [EVENT_TYPES.ALERT_DELETE]: alertDelete,
        [EVENT_TYPES.PAGE_UPDATE]: pageUpdate,
        [EVENT_TYPES.VIDEO_CREATE]: videoCreate
      });

      if (delegate) {
        yield fork(delegate, action);
      }
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error occurred while subscribing to media center updates"
          }
        ])
      );
      socketChannel.close();
    }
  }
}

const init = function*({ payload: pageData }) {
  try {
    yield put(actions.setLoading(true));

    const credentials = yield select(getCredentials);
    const eventUUID = yield select(getEventUUID);

    const [{ payload: preferences }] = yield all([
      call(communityApi.getPreferences, {
        credentials: credentials,
        eventUUID
      })
    ]);

    yield put(
      actions.setInitialData({
        ...pageData,
        countOfPeople: 0,
        preferences
      })
    );

    yield call(watchUpdates);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred getting count of people"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const requestInterview = function*() {
  if (window.Intercom) {
    window.Intercom("show");
  }
};

const watchInterviewReplay = function*() {
  const url = yield select(getInterviewReplaysUrl);

  yield call(window.open, url, "_blank");
};

const joinAsParticipant = function*({ payload }) {
  yield call(window.open, payload.url, "_blank");
};

const watch = function*({ payload }) {
  yield call(window.open, payload.url, "_blank");
};

const viewTranscript = function*({ payload }) {
  yield call(window.open, payload.url, "_blank");
};

const viewReplay = function*({ payload }) {
  yield call(window.open, payload.url, "_blank");
};

const notifyMe = function*({
  payload: { status, section, id, force = false, changeNumber = false }
}) {
  const eventId = yield select(getEventId);
  const hasEnabledPhoneNumber = yield select(getHasEnabledPhoneNumber);
  const credentials = yield select(getCredentials);

  if (changeNumber || (!force && !hasEnabledPhoneNumber)) {
    yield put(
      NotificationModalActions.updateNotification({
        changeNumber,
        section,
        id,
        status
      })
    );
  } else {
    try {
      let notificationPayload = null;

      if (section === "session") {
        notificationPayload = {
          eventId,
          contentTypes: {
            sessions: {
              emailEnabled: status,
              activityLogEnabled: false,
              smsEnabled: status,
              records: {
                [id]: status
              },
              activities: []
            }
          }
        };
      } else {
        notificationPayload = {
          eventId,
          contentTypes: {
            alerts: {
              emailEnabled: status,
              activityLogEnabled: false,
              smsEnabled: status,
              records: {},
              activities: []
            }
          }
        };
      }

      yield call(communityApi.updateNotificationSettings, {
        credentials,
        data: notificationPayload
      });

      yield put(
        snackbarActions.showSnackbar({
          message: `Notifications ${status ? "enabled" : "disabled"}`,
          action: "OK"
        })
      );

      yield call(preferencesUpdate);
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error occurred updating notification settings"
          }
        ])
      );
    }
  }
};

const refresh = function*({ payload: { section, id, status } }) {
  if (section === "alerts") {
    yield put(actions.setIsSubscribedToNotifications(status));
  } else if (section === "session") {
    const sessions = yield select(getters.sessions);
    const selectedDate = yield select(getters.selectedSessionDate);
    const newState = mapIndexed((session, index) => {
      if (index === selectedDate) {
        return {
          ...session,
          sessions: R.map(
            R.ifElse(
              R.propEq("id", id),
              R.assoc("subscribed_to_notifications", status),
              R.identity
            ),
            session.sessions
          )
        };
      }
      return session;
    }, sessions);
    yield put(actions.setSessions(newState));
  }
};

const showVideoModal = function*() {
  yield put(actions.setPlaying(false));
};

const watchPageActions = function*() {
  for (;;) {
    const action = yield take(actions.executeAction.type);

    const delegate = R.prop(action.payload.type, {
      [PAGE_ACTIONS.REQUEST_INTERVIEW]: requestInterview,
      [PAGE_ACTIONS.WATCH_INTERVIEW_REPLAY]: watchInterviewReplay,
      [PAGE_ACTIONS.JOIN_AS_PARTICIPANT]: joinAsParticipant,
      [PAGE_ACTIONS.WATCH]: watch,
      [PAGE_ACTIONS.NOTIFY_ME]: notifyMe,
      [PAGE_ACTIONS.VIEW_TRANSCRIPT]: viewTranscript,
      [PAGE_ACTIONS.VIEW_REPLAY]: viewReplay
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchShowVideoModal = function*() {
  yield takeEvery(actions.showVideoModal.type, showVideoModal);
};

const watchRefresh = function*() {
  yield takeEvery(actions.refresh.type, refresh);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchRefresh),
    fork(watchPageActions),
    fork(watchShowVideoModal)
  ]);
};

export default rootSaga;
