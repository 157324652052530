export const STYLE_KEYS = [
  // padding
  "p",
  "px",
  "py",
  "pt",
  "pb",
  "pl",
  "pr",

  // margin
  "m",
  "mx",
  "my",
  "mr",
  "ml",
  "mt",
  "mb",

  // border
  "bra",
  "ba",
  "bc",
  "bx",
  "by",
  "bt",
  "bb",
  "bl",
  "br",

  // font
  "fs",
  "fw",
  "color",

  // size
  "width",
  "w",
  "height",
  "h",
  "size",
  "sizeWFS",

  // flex
  "display",
  "flex",

  // background
  "bg",

  // animation
  "transition",

  // text
  "textAlign",
  "noLineHeight",
  "uppercase",
  "lowercase",
  "capitalize",
  "italics",
  "underline",
  "truncate",
  "color",
  "bold",

  // box
  "pill",
  "raised",
  "bg",
  "disabled",
  "bigIcons",
  "isPadded",
  "shadow",

  // style
  "style",
  "styleName",
  "className"
];

export const PALETTE = {
  PRIMARY: {
    LIGHT: "primary5",
    DARK: "primary9"
  },
  BLUE: {
    LIGHT: "indigo7",
    DARK: "indigo9"
  },
  SECONDARY: {
    LIGHT: "green5",
    DARK: "green7"
  },
  WHITE: {
    LIGHT: "neutral1",
    DARK: "white"
  },
  BLACK: {
    LIGHT: "neutral2",
    DARK: "neutral7"
  }
};

// themes

export const THEMES = {
  PRIMARY: "primary",
  PORTAL: "portal"
};

export const VARIANTS = {
  PRIMARY: "primary",
  PRIMARY_VARIANT: "primaryVariant",
  SECONDARY: "secondary",
  BACKGROUND: "background",
  SURFACE: "surface",
  BLUE: "blue",
  BLUE_SECONDARY: "blueSecondary"
};

export const NEGATIVE_VARIANT = {
  [VARIANTS.SURFACE]: VARIANTS.BACKGROUND
};

export const ACCENT_VARIANT = {
  [VARIANTS.BACKGROUND]: VARIANTS.PRIMARY
};

export const FEATURES = {
  [THEMES.PRIMARY]: {
    [VARIANTS.PRIMARY]: {
      bg: PALETTE.PRIMARY.LIGHT,
      bga: PALETTE.PRIMARY.DARK,
      bca: PALETTE.PRIMARY.DARK,
      color: PALETTE.WHITE.DARK,
      colora: PALETTE.WHITE.DARK
    },
    [VARIANTS.PRIMARY_VARIANT]: {
      bg: PALETTE.PRIMARY.DARK,
      bc: PALETTE.PRIMARY.LIGHT,
      bga: PALETTE.PRIMARY.LIGHT,
      bca: PALETTE.PRIMARY.LIGHT,
      color: PALETTE.WHITE.LIGHT,
      colora: PALETTE.WHITE.DARK
    },
    [VARIANTS.SECONDARY]: {
      bg: PALETTE.PRIMARY.LIGHT,
      bga: PALETTE.PRIMARY.DARK,
      bca: PALETTE.PRIMARY.DARK,
      color: PALETTE.WHITE.DARK,
      colora: PALETTE.WHITE.DARK
    },
    [VARIANTS.BACKGROUND]: {
      bg: PALETTE.WHITE.DARK,
      bc: PALETTE.BLACK.LIGHT,
      bca: PALETTE.PRIMARY.LIGHT,
      bch: PALETTE.PRIMARY.LIGHT,
      color: PALETTE.BLACK.DARK,
      colora: PALETTE.PRIMARY.LIGHT
    },
    [VARIANTS.SURFACE]: {
      bg: PALETTE.WHITE.LIGHT,
      bc: PALETTE.BLACK.LIGHT,
      bca: PALETTE.PRIMARY.LIGHT,
      color: PALETTE.BLACK.DARK,
      colora: PALETTE.PRIMARY.LIGHT
    },
    [VARIANTS.BLUE]: {
      bg: PALETTE.WHITE.LIGHT,
      bc: PALETTE.BLACK.LIGHT,
      bca: PALETTE.BLUE.LIGHT,
      color: PALETTE.BLACK.DARK,
      colora: PALETTE.BLUE.LIGHT
    },
    [VARIANTS.BLUE_SECONDARY]: {
      bg: PALETTE.BLACK.LIGHT,
      bc: PALETTE.BLACK.LIGHT,
      bca: PALETTE.BLUE.LIGHT,
      color: PALETTE.BLACK.DARK,
      colora: PALETTE.BLUE.LIGHT
    }
  },

  [THEMES.PORTAL]: {
    [VARIANTS.PRIMARY]: {
      bg: "info5",
      bga: "info5",
      bgh: "info4",
      color: PALETTE.WHITE.LIGHT,
      colora: PALETTE.WHITE.DARK,
      colorh: PALETTE.WHITE.DARK
    },
    [VARIANTS.SECONDARY]: {
      bg: "altB5",
      bgh: "altB6",
      color: PALETTE.WHITE.DARK
    },
    [VARIANTS.BACKGROUND]: {
      bg: PALETTE.WHITE.DARK,
      bga: "info5",
      bgh: "info4",
      bc: PALETTE.BLACK.LIGHT,
      bca: PALETTE.PRIMARY.LIGHT,
      color: PALETTE.BLACK.DARK,
      colora: PALETTE.WHITE.DARK,
      colorh: PALETTE.WHITE.DARK
    },
    [VARIANTS.SURFACE]: {
      bg: PALETTE.WHITE.LIGHT,
      bga: PALETTE.WHITE.DARK,
      bc: { default: PALETTE.WHITE.LIGHT, hover: PALETTE.PRIMARY.LIGHT },
      color: PALETTE.BLACK.DARK,
      colora: PALETTE.PRIMARY.DARK
    },
    [VARIANTS.BLUE]: {
      bg: PALETTE.WHITE.LIGHT,
      bga: PALETTE.WHITE.DARK,
      bc: PALETTE.WHITE.LIGHT,
      color: PALETTE.BLACK.DARK,
      colora: PALETTE.PRIMARY.BLUE
    }
  }
};
