import React, { Component } from "react";
import getValue from "utils/value-types/get-value/event-list";
import { Div, Text2, MediumAvatar, Text0 } from "components/Base";
import { isEqual } from "lodash";
import { connect } from "react-redux";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";

const decorate = connect(state => ({
  eventDetails: getEventDetails(state)
}));

class OrderNameFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  goToAccountProfile = accountId => {
    window.open(
      `/event/${this.props.eventDetails.id}/account/${accountId}`,
      "_blank"
    );
  };

  goToContactProfile = contactId => {
    window.open(
      `/event/${this.props.eventDetails.id}/contact/${contactId}`,
      "_blank"
    );
  };

  render() {
    const value = getValue(this.props.value);
    return (
      <Div display="row.flex-start.center" pl={2}>
        {value.orderType === "individual" ? (
          <>
            <MediumAvatar
              //photoURL={value.photoUrl}
              bg="neutral3"
              text={value.personName || ""}
              singleInitial
            />
            <Div ml={2}>
              <Text2
                underline={{ default: false, hover: true }}
                onClick={() => this.goToContactProfile(value.personId)}
                bold
                width={1}
              >
                {value.personName}
              </Text2>
              <Div display="row.flex-start.center" uppercase fs={0}>
                {value.groupType}
                {value.groupType && value.groupType.length ? (
                  <Text0 mx={1}>·</Text0>
                ) : null}
                {value.groupName && value.groupName.length ? (
                  <Text0
                    underline={{ default: false, hover: true }}
                    onClick={() => this.goToAccountProfile(value.groupId)}
                  >
                    {value.groupName}
                  </Text0>
                ) : null}
              </Div>
            </Div>
          </>
        ) : (
          <>
            <MediumAvatar
              photoURL={value.photoUrl}
              bg="neutral3"
              text={value.groupName || ""}
              singleInitial
            />
            <Div ml={2}>
              <Text2
                bold
                width={1}
                underline={{ default: false, hover: true }}
                onClick={() => this.goToAccountProfile(value.groupId)}
              >
                {value.groupName}
              </Text2>
              <Div display="row.flex-start.center" uppercase fs={0}>
                {value.groupType}
                {value.groupType && value.groupType.length ? (
                  <Text0 mx={1}>·</Text0>
                ) : null}
                {value.personName && value.personName.length ? (
                  <Text0
                    underline={{ default: false, hover: true }}
                    onClick={() => this.goToContactProfile(value.personId)}
                  >
                    {value.personName}
                  </Text0>
                ) : null}
              </Div>
            </Div>
          </>
        )}
      </Div>
    );
  }
}

export default decorate(OrderNameFormatter);
