import PropTypes from "prop-types";
import React, { Component } from "react";
import Container from "components/Global/Alerts/Container";
import Header from "components/Global/Alerts/Header";
import Separator from "components/Global/Alerts/Separator";
import PrimaryAction from "components/Global/Alerts/PrimaryAction";
import SecondaryAction from "components/Global/Alerts/SecondaryAction";
import Buttons from "components/Global/Alerts/ButtonContainer";
import Paragraph from "components/Global/Alerts/ParagraphContainer";
import Checkbox from "material-ui/Checkbox";
import InReview from "components/Event/FormsV2/Overview/StatusBadges/InReview";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FirstRowApproved extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locked: false
    };
  }

  @autobind
  onLockToggle() {
    this.setState({
      locked: !this.state.locked
    });
  }

  render() {
    const { locked } = this.state;
    const { onAccept, onCancel } = this.props;
    return (
      <Container>
        <Header>Heads up,</Header>
        <Paragraph>
          This action will move this submission to{" "}
          <div styleName="reviewBadge">
            <InReview />
          </div>
        </Paragraph>
        <Paragraph>
          <Checkbox
            checked={locked}
            labelStyle={{
              fontSize: "14px",
              fontWeight: "normal",
              color: "#4A4A4A"
            }}
            iconStyle={{
              width: "20px",
              height: "20px",
              marginRight: "5px",
              color: "#813CD2"
            }}
            onCheck={this.onLockToggle}
            label="Lock submission for recipient"
          />
        </Paragraph>
        {locked ? (
          <Paragraph className={css.lockedMessage}>
            You can{" "}
            <i styleName="lockIcon" className="material-icons">
              lock_open
            </i>{" "}
            it later if needed.
          </Paragraph>
        ) : (
          ""
        )}
        <Separator />
        <Buttons>
          <PrimaryAction onClick={() => onAccept(locked)}>
            {locked ? "Lock & Continue" : "Continue"}
          </PrimaryAction>
          <SecondaryAction onClick={onCancel}>Cancel</SecondaryAction>
        </Buttons>
      </Container>
    );
  }
}

FirstRowApproved.propTypes = {
  onAccept: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default FirstRowApproved;
