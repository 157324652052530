import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../model";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import {
  BigOutlineButton,
  FilterIcon,
  AddPersonIcon,
  FontIcon
} from "components/Base";
import { getIsPeopleSelected } from "../selectors";
import SearchBar from "ui-kit/SearchBar/View";
import { withProps, noop } from "utils/General";
import Filters from "./Filters";

const decorate = R.compose(
  connect(
    state => ({
      selectedPeopleIds: getters.selectedPeopleIds(state),
      isPeopleSelected: getIsPeopleSelected(state)
    }),
    {
      clearSelectedPeopleIds: actions.clearSelectedPeopleIds,
      handleSelectedPeopleIds: actions.handleSelectedPeopleIds,
      showAddPersonModal: actions.showAddPersonModal
    }
  ),
  CSSModules(css)
);

const Header = ({
  selectedPeopleIds,
  clearSelectedPeopleIds,
  isPeopleSelected,
  handleSelectedPeopleIds,
  showAddPersonModal
}) => (
  <div styleName="toolbar">
    <div styleName="buttons">
      {!isPeopleSelected ? (
        <>
          <SearchBar flex={1} placeholder="Search by name or email" />
          <Filters className={css.button}>
            <BigOutlineButton LeftIcon={FilterIcon} onClick={noop}>
              Filter
            </BigOutlineButton>
          </Filters>
          <div className={css.button}>
            <BigOutlineButton
              LeftIcon={withProps({
                children: "sort_by_alpha",
                fs: 4
              })(FontIcon)}
              onClick={noop}
            >
              Sort
            </BigOutlineButton>
          </div>
          <div styleName="spacer" />
        </>
      ) : (
        <div styleName="peopleSelectedContainer">
          <div styleName="peopleSelected">
            {R.length(selectedPeopleIds)} selected
          </div>
          <div styleName="peopleSelectedButtons">
            <BigOutlineButton onClick={handleSelectedPeopleIds} ml={1}>
              Submit Requests
            </BigOutlineButton>
            <div styleName="clearSelected" onClick={clearSelectedPeopleIds}>
              Clear selected
            </div>
          </div>
        </div>
      )}
      <BigOutlineButton
        LeftIcon={AddPersonIcon}
        color="primary7"
        onClick={showAddPersonModal}
      >
        Add
      </BigOutlineButton>
    </div>
  </div>
);

export default decorate(Header);
