import { put, call, takeEvery, all, fork } from "redux-saga/effects";
import Helpers from "utils/Global/Helpers";
import { actions } from "SalesPortal/Settings";

const getFilePicker = () =>
  new Promise(resolve => {
    const options = {
      multiple: false,
      accept: [
        "image/bmp",
        "image/gif",
        "image/jpeg",
        "image/svg+xml",
        "image/png"
      ]
    };

    const path = { path: "sales-portal/" };

    Helpers.getFilepicker(options, path, resolve);
  });

const importFile = function*({ url, type }) {
  yield put(actions.setSaving(true));
  if (type === "images") {
    yield put(actions.addImage({ url }));
  } else {
    yield put(actions.updateFloorImage({ url }));
  }
  yield put(actions.setSaving(false));
};

const uploadFile = function*({ payload: { type } }) {
  const files = yield call(getFilePicker);
  const url = files[0].url;

  yield call(importFile, { url, type });
};

const watchUploadFile = function*() {
  yield takeEvery(actions.uploadFile.type, uploadFile);
};

const rootSaga = function*() {
  yield all([fork(watchUploadFile)]);
};

export default rootSaga;
