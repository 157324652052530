import React from "react";

export default () => (
  <svg width="17px" height="14px" viewBox="0 0 17 14" version="1.1">
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.82579291"
      strokeLinejoin="round"
    >
      <g
        transform="translate(-737.000000, -218.000000)"
        strokeWidth="1.5"
        stroke="#979797"
      >
        <g transform="translate(343.000000, -118.598000)">
          <g transform="translate(0.000000, 0.333333)">
            <g transform="translate(138.000000, 317.264667)">
              <g transform="translate(246.000000, 13.000000)">
                <path d="M18.5789474,15.8421053 C22.7646844,15.8421053 26.1578947,13.8627326 26.1578947,11.4210526 C26.1578947,8.97937269 22.7646844,7 18.5789474,7 C14.3932103,7 11,8.97937269 11,11.4210526 C11,12.7016911 11.93344,13.8551532 13.425087,14.6625532 C13.5263158,17.7368421 13.425087,19 13.425087,19 C13.425087,19 16.0526316,17.1052632 17.7719025,15.8173355 C18.0370766,15.833709 18.3063311,15.8421053 18.5789474,15.8421053 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
