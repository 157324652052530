import Helpers from "utils/Global/Helpers";

/* eslint no-underscore-dangle: "off" */

export default {
  getFieldGroups: ({ credentials, eventId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/schedules/field-groups/event/${eventId}`,
        credentials,
        success,
        error
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
