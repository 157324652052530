import getValue from "../get-value/dropdown";
import toString from "../to-string/dropdown";
import sortEmpty from "./sort-empty";

export default {
  ASC: sortEmpty("dropdown", (a, b) =>
    toString(getValue(a))
      .toLowerCase()
      .localeCompare(toString(getValue(b)).toLowerCase())
  ),
  DESC: sortEmpty("dropdown", (a, b) =>
    toString(getValue(b))
      .toLowerCase()
      .localeCompare(toString(getValue(a)).toLowerCase())
  )
};
