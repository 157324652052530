import { combineReducers } from "redux";
import { SHOW, HIDE } from "./constants";
import { LOCATION_CHANGE } from "react-router-redux";

const modals = (state = [], action) => {
  switch (action.type) {
    case SHOW:
      return [...state, action.payload];
    case LOCATION_CHANGE:
      return [];
    case HIDE: {
      const newState = [...state];
      // Currently assuming `HIDE` wants to hide the top most modal
      // TODO: I'm guessing there are some use cases where we want
      // to hide a specific modal, so need to add in some kind of
      // identifier.
      newState.pop();
      return newState;
    }
    default:
      return state;
  }
};

export default combineReducers({
  modals
});
