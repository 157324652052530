import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserSelectors from "redux/modules/user/selectors";
import { user as fetchedUser } from "redux/modules/eventUsers/selectors";
import { getEvents } from "redux/modules/events/actions";
import { getUser } from "redux/modules/eventUsers/actions";
import { eventDetails } from "redux/modules/event/selectors";

import EditCollaboratorInfoModal from "./EditCollaboratorInfoModal";

function mapStateToProps(state) {
  return {
    eventDetails: eventDetails(state),
    currentUser: UserSelectors.user(state),
    user: fetchedUser(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEvents,
      getUser
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditCollaboratorInfoModal);
