import React from "react";
import { Div } from "components/Base";
import * as R from "ramda";
import { connect } from "react-redux";
import CategoryProducts from "./CategoryProducts";

import { filteredProducts, getSeachText } from "../selectors";

const decorator = connect((state, props) => ({
  filteredProducts: filteredProducts(state, props),
  searchText: getSeachText(state, props)
}));

const Products = ({ filteredProducts, searchText }) => (
  <Div>
    <Div
      style={{
        fontSize: 26,
        marginBottom: 14,
        color: "#333333"
      }}
    >
      {searchText}
    </Div>
    {R.map(
      category => (
        <CategoryProducts key={category.id} category={category} />
      ),
      filteredProducts
    )}
  </Div>
);

export default decorator(Products);
