import * as R from "ramda";
import * as FormSelectors from "ui-kit/Form/selectors";
import { createSelector } from "reselect";
import { getters } from "./model";
import { FIELD_IDS } from "./constants";
import Helpers from "utils/Global/Helpers";

export const getEditedField = R.compose(
  field => R.prop("value", field),
  FormSelectors.getField
);

export const getIsValidBillingEmail = createSelector(
  getters.data,
  data => {
    return data[FIELD_IDS.BILLING_EMAIL] &&
      Helpers.isValidEmail(data[FIELD_IDS.BILLING_EMAIL].trim())
      ? true
      : false;
  }
);

export const getSubscription = createSelector(
  getters.subscription,
  subscription => {
    return {
      ...subscription
    };
  }
);

export const getCurrentPlanId = createSelector(
  getters.subscription,
  R.prop("plan_id")
);

export const getSubscriptionId = createSelector(
  getters.subscription,
  R.prop("subscription_id")
);
