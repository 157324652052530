import React from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { Div, Text2, AddCircleIcon } from "components/Base";

import { Panel } from "ui-kit/FilterControls/View";

import { getShortcutLinks } from "EventLight/Common/Dashboard/selectors";
import { Link } from "react-router";

const decorate = connect(
  (state, props) => ({
    links: getShortcutLinks(state, props)
  }),
  bindInstance({})
);

const Shortcuts = ({ links, ...styleProps }) => {
  return !links.length ? null : (
    <Panel
      label={
        <Div display="row.flex-start.center" mb={1}>
          <AddCircleIcon fs={4} mr={2} />
          <Text2 bold>Shortcuts</Text2>
        </Div>
      }
      collapsedDefault={false}
      filtersOn={false}
      bodyProps={{ ml: 3, mr: 2 }}
      {...styleProps}
    >
      <Div display="column.flex-start.flex-start">
        {links.map(l => (
          <Link
            to={l.to}
            key={l.id}
            style={{
              display: "block",
              width: "100%"
            }}
          >
            <Div
              py={1}
              fs={2}
              onClick={() => {}}
              width={1}
              fw={l.isRouteActive ? 3 : 2}
              color={
                l.isRouteActive
                  ? "primary7"
                  : {
                      default: "inherit",
                      hover: "black"
                    }
              }
            >
              {l.name}
            </Div>
          </Link>
        ))}
      </Div>
    </Panel>
  );
};

export default decorate(Shortcuts);
