import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function addLineItem(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post(getState().user.user.credentials, data);
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding line item"
          }
        ])
      );
    }
  };
}

export function addLineItems(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.post(getState().user.user.credentials, {
        bulk: true,
        ...data
      });
      return result.payload;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred adding line items"
          }
        ])
      );
    }
  };
}

export function deleteLineItem(data) {
  return async (dispatch, getState) => {
    try {
      const result = await api.delete(getState().user.user.credentials, data);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting line item"
          }
        ])
      );
    }
  };
}

export function deleteLineItems(lineItemIds) {
  return async (dispatch, getState) => {
    try {
      const result = await api.delete(getState().user.user.credentials, {
        bulk: true,
        lineItemIds: lineItemIds
      });
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred deleting line item"
          }
        ])
      );
    }
  };
}

export function assignLineItem({ contactId, lineItemId }) {
  return async (dispatch, getState) => {
    try {
      const result = await api.assignLineItem(
        getState().user.user.credentials,
        { contactId, lineItemId }
      );
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred removing assignment"
          }
        ])
      );
    }
  };
}

export function deleteLineItemAssignment({ assignmentId }) {
  return async (dispatch, getState) => {
    try {
      const result = await api.deleteAssignment(assignmentId);
      return result;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred removing assignment"
          }
        ])
      );
    }
  };
}
