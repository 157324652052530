import * as R from "ramda";
import React from "react";
import { VisibleIcon, NotVisibleIcon, DragIcon } from "components/Base";
import Toggle from "material-ui/Toggle";
import { Div } from "components/Base";
import { connect } from "react-redux";
import * as FieldActions from "redux/modules/formsV2/form/fields/actions.js";
import { noop } from "utils/General";
import { actions } from "../model";

const decorate = R.compose(
  connect(
    null,
    {
      handleMarkAsReadonly: FieldActions.updateField,
      handleToggleAction: FieldActions.updateField,
      handleRemoveField: actions.removeFormField
    }
  )
);
const HeaderContent = ({
  TypeComponent = null,
  fieldData = null,
  handleMarkAsReadonly = noop,
  handleToggleAction = noop,
  handleRemoveField = noop,
  connectDragSource = noop
}) => {
  const readOnlyObj = {
    fieldId: fieldData.id,
    isRequired: false,
    isReadonly: !fieldData.is_readonly
  };
  const requiredObj = {
    fieldId: fieldData.id,
    isRequired: !fieldData.is_required
  };

  const handleMarkEvent = e => {
    e.stopPropagation();
    handleMarkAsReadonly(readOnlyObj);
  };

  const cursorPointer = {
    cursor: "pointer"
  };

  return (
    <Div display="row.space-between.center">
      <Div display="row.center.center">
        {connectDragSource(
          <div>
            <DragIcon size={20} />
          </div>
        )}
        <Div className="info" ml={1}>
          <TypeComponent withName={true} />
        </Div>
      </Div>
      <Div
        className="actions"
        display="row.space-around.center"
        style={{ flex: "0.1 1 auto" }}
        onClick={e => e.stopPropagation()}
      >
        <Div className="requiredAction" display="row.space-around.center">
          <label
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: "#545454"
            }}
          >
            Required
          </label>
          <Toggle
            defaultToggled={fieldData.is_required}
            onClick={e => e.stopPropagation()}
            onToggle={e => {
              e.stopPropagation();
              handleToggleAction(requiredObj);
            }}
            style={cursorPointer}
          />
        </Div>
        <Div className="removeAction" style={cursorPointer}>
          <label
            style={{
              fontSize: 14,
              fontWeight: 500,
              color: "#545454",
              cursor: "pointer"
            }}
            onClick={e => {
              e.stopPropagation();
              handleRemoveField({ data: fieldData.id });
            }}
          >
            Remove
          </label>
        </Div>
        <Div style={cursorPointer} onClick={e => e.stopPropagation()}>
          {fieldData.is_readonly ? (
            <NotVisibleIcon style={cursorPointer} onClick={handleMarkEvent} />
          ) : (
            <VisibleIcon style={cursorPointer} onClick={handleMarkEvent} />
          )}
        </Div>
      </Div>
    </Div>
  );
};
export default decorate(HeaderContent);
