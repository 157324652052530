import React from "react";
import { withRouter } from "react-router";
import * as R from "ramda";
import { createContext } from "redux-mvc";
import { connect } from "react-redux";
import module from "../index";
import { actions } from "../model";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import View from "./View";
import { showModal } from "redux/modules/modal/actions";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import CreateOrderModal from "Orders/CreateOrderModal";
import FormWizardModal from "Forms/WizardModal/View";
import AddEventTeamUserModal from "components/Global/Modals/AddEventTeamMemberModal";
import SubmitFormModal from "Modules/SubmitFormModal/View";
import { showSnackbar } from "redux/modules/snackbar/actions";

const decorate = R.compose(
  connect(
    state => ({
      canDo: canEventUserDo(state)
    }),
    { showModal, showSnackbar }
  ),
  withRouter,
  createContext({
    module: module,
    handlers: {
      showSubmitFormModal: function() {
        this.props.showModal({
          content: <SubmitFormModal hideModal={this.props.hideModal} />,
          wrapper: ModalWrapper
        });
      },
      showAddRecordModal: function({ moduleId }) {
        this.props.showModal({
          content: (
            <AddRecordModal
              moduleId={moduleId}
              onSave={() => this.store.dispatch(actions.init())}
            />
          ),
          wrapper: ModalWrapper
        });
      },
      showAddAccountModal: function(groupId, groupName) {
        this.props.showModal({
          content: (
            <AddRecordModal
              moduleId={STANDARD_MODULE_IDS.accounts.id}
              onSave={() => this.store.dispatch(actions.init())}
              onlyRecordTypeId={groupId}
              onlyRecordTypeName={groupName}
            />
          ),
          wrapper: ModalWrapper
        });
      },
      showAddContactModal: function(groupId, groupName) {
        this.props.showModal({
          content: (
            <AddRecordModal
              moduleId={STANDARD_MODULE_IDS.contacts.id}
              onSave={() => this.store.dispatch(actions.init())}
              onlyRecordTypeId={groupId}
              onlyRecordTypeName={groupName}
            />
          ),
          wrapper: ModalWrapper
        });
      },
      showCreateOrderModal: function() {
        this.props.showModal({
          content: (
            <CreateOrderModal
              onDone={() => {
                this.props.showSnackbar({ message: "Order created" });
              }}
            />
          ),
          wrapper: ModalWrapper
        });
      },
      showAddFormModal: function() {
        this.props.showModal({
          content: <FormWizardModal />,
          wrapper: ModalWrapper
        });
      },
      showAddEventTeamUserModal: function() {
        const modal = (
          <AddEventTeamUserModal
            hideModal={this.props.hideModal}
            addToEventId={this.props.params.eventId}
            onAdded={() => {
              this.props.showSnackbar({ message: "User invited" });
            }}
          />
        );

        this.props.showModal({ content: modal });
      }
    }
  })
);

export default decorate(View);
