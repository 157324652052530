import React from "react";

export const GridView = ({ color = "#EBAC08", size = 15 }) => (
  <span className="lennd-icon-GridView" style={{ color, fontSize: size }}>
    <span className="path1" />
    <span className="path2" />
  </span>
);

export const ListView = ({ color = "#468EE5", size = 15 }) => (
  <span className="lennd-icon-ListView" style={{ color, fontSize: size }}>
    <span className="path1" />
    <span className="path2" />
  </span>
);

export const KanbanView = ({ color = "#9B51E0", size = 15 }) => (
  <span className="lennd-icon-KanbanView" style={{ color, fontSize: size }}>
    <span className="path1" />
    <span className="path2" />
  </span>
);

export const ReportView = ({ color = "#e74c3c", size = 19 }) => (
  <i
    className="material-icons"
    style={{ color, fontSize: size, marginLeft: -2, marginRight: -2 }}
  >
    insert_chart
  </i>
);
