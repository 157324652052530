import React from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { noop } from "utils/General";
import { actions } from "../model";

import { getToggled } from "ui-kit/Table/selectors";
import Checkbox from "material-ui/Checkbox";

const decorate = connect(
  (state, props) => ({
    toggled: getToggled(state, props)
  }),
  bindInstance({
    toggleRow: actions.toggleRow
  })
);

const ToggleRow = ({ rowId, toggled = false, toggleRow = noop }) => (
  <div
    className="action-checkbox"
    onClick={ev => {
      ev.preventDefault();
      toggleRow(rowId);
    }}
  >
    <Checkbox
      checked={toggled}
      style={{ width: "auto" }}
      iconStyle={{
        alignSelf: "center",
        marginRight: 6
      }}
    />
  </div>
);

export default decorate(ToggleRow);
