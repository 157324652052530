import React from "react";

import {
  Div,
  CloseIcon,
  HalfSizeDotIcon,
  MediumNumberSelector
} from "components/Base";
import PriceSelector from "Orders/CreateOrderModal/PriceSelector";

import { connect } from "react-redux";
import {
  getHeaderInformation,
  getSingleVariantHasPrices,
  getSingleVariantPrices,
  getSingleVariantQuantity,
  getSingleVariantSelectedPriceId
} from "../selectors";
import { getters, actions } from "../index";

const decorate = connect(
  state => ({
    headerInformation: getHeaderInformation(state),
    hasSingleVariant: getters.hasSingleVariant(state),
    singleVariantHasPrices: getSingleVariantHasPrices(state),
    singleVariantPrices: getSingleVariantPrices(state),
    singleVariantQuantity: getSingleVariantQuantity(state),
    singleVariantSelectedPriceId: getSingleVariantSelectedPriceId(state),
    remaining: getters.remaining(state),
    limit: getters.limit(state),
    disableChangingPrice: getters.disableChangingPrice(state),
    color: getters.color(state),
    imageUrl: getters.imageUrl(state)
  }),
  {
    updateSingleVariantQuantity: actions.updateSingleVariantQuantity,
    updateSingleVariantPrice: actions.updateSingleVariantPrice
  }
);

const Header = ({
  hasSingleVariant,
  singleVariantHasPrices,
  singleVariantPrices,
  singleVariantSelectedPriceId,
  singleVariantQuantity,
  headerInformation,
  handlers,
  updateSingleVariantQuantity,
  updateSingleVariantPrice,
  disableChangingPrice,
  remaining,
  limit,
  color,
  imageUrl
}) => {
  return (
    <Div bb={1} bc="gray2" pb={5}>
      <Div display="row.flex-end" width={1} px={3} pt={3} mb={2}>
        <CloseIcon
          color={{
            default: "gray5",
            hover: "gray6"
          }}
          onClick={handlers.hideModal}
        />
      </Div>

      <Div display="row.flex-start.flex-start" width={1} px={7}>
        {imageUrl ? (
          <Div
            mt={2}
            style={{
              width: 100,
              height: 100,
              backgroundImage: `url(${imageUrl})`,
              backgroundSize: "cover",
              backgroundPosition: "top center",
              backgroundColor: "#fafafa",
              flexShrink: 0,
              marginRight: 20
            }}
            display="row.center.center"
          />
        ) : null}

        <Div display="column" width={1}>
          <Div display="row.flex-start.center" width={1}>
            <Div display="row.flex-start.center" width={1}>
              <HalfSizeDotIcon
                color="neutral5"
                mr={2}
                style={{
                  color: headerInformation.color || "#ccc",
                  flexShrink: 0
                }}
              />
              <Div flex={1} fs={4} fw={4} color="gray7">
                {headerInformation.name}
              </Div>
              {hasSingleVariant ? (
                <Div display="row.flex-end.center">
                  {singleVariantHasPrices && !disableChangingPrice ? (
                    <PriceSelector
                      color={color}
                      prices={singleVariantPrices}
                      selectedPriceId={singleVariantSelectedPriceId}
                      onChange={updateSingleVariantPrice}
                    />
                  ) : null}
                  <Div width={20} />
                  <MediumNumberSelector
                    color={color}
                    value={singleVariantQuantity}
                    onChangeValue={updateSingleVariantQuantity}
                    maxValue={
                      !isNaN(remaining) && remaining !== null
                        ? remaining
                        : undefined
                    }
                  />
                </Div>
              ) : null}
            </Div>
          </Div>

          {headerInformation.description &&
          headerInformation.description.length ? (
            <Div fs={2} color="gray8" mt={2} width={1}>
              {headerInformation.description}
            </Div>
          ) : null}
          {!isNaN(remaining) && remaining !== null ? (
            <Div fs={2} color="gray6" mt={1} width={1}>
              Limit: {limit} &middot; Remaining: {remaining}
            </Div>
          ) : null}
        </Div>
      </Div>
    </Div>
  );
};

export default decorate(Header);
