import React from "react";
import { connect } from "react-redux";

import { Div } from "components/Base";

import SubmissionEditor from "Submission/Editor/View";

import { getters } from "Orders/OrderModal";

const decorate = connect((state, props) => ({
  restricted: getters.isPortalUser(state, props)
}));

const SubmissionFields = ({ restricted }) => {
  return (
    <Div
      bg="white"
      bc="neutral3"
      bb={1}
      bl={1}
      br={1}
      brb={1}
      py={3}
      style={{ overflow: "visible" }}
    >
      <SubmissionEditor readOnly={restricted} />
    </Div>
  );
};

export default decorate(SubmissionFields);
