import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { Div, Text3, Text4, Text6, SmallCheckbox } from "components/Base";
import { getWelcomeData } from "Portal/IntakeForm/selectors";
import { getters, actions } from "Portal/IntakeForm/model";
import NextButton from "./NextButton";

const decorate = connect(
  state => ({
    data: getWelcomeData(state),
    termsAccepted: getters.termsAccepted(state)
  }),
  {
    toggleTermsAccepted: actions.toggleTermsAccepted
  }
);

const WelcomePage = ({ data, mobile, termsAccepted, toggleTermsAccepted }) => (
  <Div>
    <Div mb={6}>
      <Text6 bold color="black">
        {R.propOr("", "title", data)}
      </Text6>
      {R.propOr(false, "show_description", data) && (
        <Div
          color="gray8"
          fs={3}
          className="lennd-wysiwyg-content portal-message-text"
        >
          <span
            dangerouslySetInnerHTML={{
              __html: R.propOr("", "description", data)
            }}
          />
        </Div>
      )}
    </Div>
    {R.propOr(false, "show_terms", data) && (
      <Div>
        <Text6 bold color="black">
          Agree to the terms & conditions to continue...
        </Text6>
        <Div
          value={R.propOr("", "terms", data)}
          disabled
          width={1}
          bc="neutral1"
          bg="neutral0"
          p={4}
          mt={4}
          height={200}
          style={{ overflowY: "scroll", minHeight: 380, resize: "vertical" }}
          mb={4}
          className="lennd-wysiwyg-content portal-message-text"
        >
          <span
            dangerouslySetInnerHTML={{
              __html: R.propOr("", "terms", data)
            }}
          />
        </Div>
      </Div>
    )}
    <Div display="row.space-between.center">
      {R.propOr(false, "show_terms", data) && (
        <Div display="row.flex-start.center" onClick={toggleTermsAccepted}>
          <SmallCheckbox
            size={30}
            selected={termsAccepted}
            selectedColor="teal6"
          />
          {mobile ? (
            <Text3 ml={4} color="black" bold mr={3}>
              I agree to the terms & conditions
            </Text3>
          ) : (
            <Text4 ml={4} color="black" bold mr={3}>
              I agree to the terms & conditions
            </Text4>
          )}
        </Div>
      )}
      <NextButton />
    </Div>
  </Div>
);

export default decorate(WelcomePage);
