import React from "react";
import { Div } from "components/Base";

export const UnseenCount = ({ count, ...styles }) => (
  <Div
    bg="#FF3A3A"
    color="#fff"
    style={{
      minWidth: 10,
      borderRadius: 4,
      fontSize: 12,
      padding: "0px 6px"
    }}
    {...styles}
  >
    {count}
  </Div>
);

export default UnseenCount;
