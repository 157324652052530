import { createModule } from "redux-mvc";
import handlers from "Organizations/EventDetails/handlers";

const module = createModule(handlers);

const { actions, getters } = module;

export { actions, getters };

export default module;
