import PropTypes from "prop-types";
import React, { Component } from "react";
import Helpers from "utils/Global/Helpers";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class Preview extends Component {
  render() {
    const { file, isSelected, onClick } = this.props;
    const { pdfToJpg, resizeImage } = Helpers;
    const url = resizeImage(pdfToJpg(file.url));

    return (
      <div
        onClick={onClick}
        styleName={isSelected ? "selectedPreview" : "preview"}
        style={{ backgroundImage: `url(${url})` }}
      />
    );
  }
}

Preview.propTypes = {
  file: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func
};

export default Preview;
