import React from "react";
import { connect } from "react-redux";
import { BEGIN } from "redux-optimist";
import uuid from "node-uuid";

import { getters, actions } from "../model";

import { Stepper, Step, StepLabel, StepContent } from "material-ui/Stepper";

import RaisedButton from "material-ui/RaisedButton";
import FlatButton from "material-ui/FlatButton";
import { Div, Text3 } from "components/Base";

import Days from "./StepDays";
import Meals from "./StepMeals";
import MealsPerDay from "./StepMealsPerDay";
import DietaryRestrictions from "./StepDietaryRestrictions";
import ItemBlocks from "./ItemBlocks";

import { noop } from "utils/General";

const steps = [
  { id: "days", label: "Select days with catering", view: Days },
  { id: "meals", label: "Add available meals", view: Meals },
  {
    id: "mealsPerDay",
    label: "Review & adjust meals per day",
    view: MealsPerDay
  },
  {
    id: "restrictions",
    label: "Edit dietary restriction / food preference options",
    view: DietaryRestrictions
  }
];

const NextPrev = ({
  index = 0,
  next = noop,
  prev = noop,
  disabled = false
}) => (
  <Div p={4}>
    {index > 0 && (
      <FlatButton
        label="Back"
        disableTouchRipple
        disableFocusRipple
        onClick={prev}
        disabled={disabled}
        style={{ marginRight: 12 }}
      />
    )}
    <RaisedButton
      label="Next"
      disableTouchRipple
      disableFocusRipple
      primary
      onClick={() =>
        next(null, { optimist: { type: BEGIN, transactionId: uuid.v4() } })
      }
      disabled={disabled}
    />
  </Div>
);

const decorate = connect(
  (state, props) => ({
    activeStep: getters.mealStep(state, props)
  }),
  {
    next: actions.nextMealStep,
    prev: actions.prevMealStep,
    resetSteps: actions.finishMealsSetup
  }
);

const MealsWorkflow = ({
  loading,
  activeStep = 0,
  next = noop,
  prev = noop,
  resetSteps = noop
}) => (
  <Div display="row" px={8}>
    <Div
      width={2 / 3}
      pr={3}
      py={4}
      br={1}
      bc="gray3"
      style={{ maxWidth: 800 }}
    >
      <Div mt={5} ml={5}>
        {loading ? (
          <Div />
        ) : (
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map(step => (
              <Step key={step.id}>
                <StepLabel>{step.label}</StepLabel>
                <StepContent>
                  {React.createElement(step.view || "div")}
                  <NextPrev next={next} prev={prev} index={activeStep} />
                </StepContent>
              </Step>
            ))}
          </Stepper>
        )}

        {activeStep === 4 && (
          <Div p={5}>
            <Text3 bold mb={3}>
              Your settings have been saved.
            </Text3>
            <RaisedButton
              label="Done"
              disableTouchRipple
              disableFocusRipple
              primary
              onClick={resetSteps}
            />
          </Div>
        )}
      </Div>
    </Div>
    <Div py={8} flex={1} pl={6} style={{ maxWidth: 500 }}>
      <ItemBlocks />
    </Div>
  </Div>
);

export default decorate(MealsWorkflow);
