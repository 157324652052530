import React, { Fragment, Component } from "react";
import {
  MediumTextBox,
  Div,
  Popover,
  MediumOutlineButton,
  RightArrowIcon,
  modalHandler,
  Modal,
  SmallOutlineBox
} from "components/Base";
import RelatedModuleTableBuilder from "components/Global/CRM/MergeTags/GenerateTable";

const MenuItem = ({ children, isTable, isSummary, onClick, ...props }) => (
  <Div
    px={4}
    py={2}
    bg={{ default: "white", hover: "neutral1" }}
    transition="fast"
    width={1}
    fw={3}
    onClick={onClick} // tag.tag
    display="row.flex-start.center"
    {...props}
  >
    {children}
    {isTable && <SmallOutlineBox ml={2}>Table</SmallOutlineBox>}
    {isSummary && <SmallOutlineBox ml={2}>Summary</SmallOutlineBox>}
  </Div>
);

const TableMenuItem = modalHandler()(
  ({
    showModal,
    closeModal,
    tag,
    fields,
    isModalShowing,
    onClick,
    children
  }) => [
    <MenuItem isTable onClick={showModal}>
      {children}
    </MenuItem>,
    <Modal isPortal background="translucent" isModalShowing={isModalShowing}>
      <RelatedModuleTableBuilder
        fields={fields}
        moduleName={tag}
        hideModal={closeModal}
        onSave={derivedTag => {
          closeModal();
          onClick(derivedTag);
        }}
      />
    </Modal>
  ]
);

const formatTag = tag => `{{{${tag}}}}`;

class MergeTagMenu extends Component {
  render() {
    const { onMergeTagSelect, mergeTags } = this.props;

    return (
      <Popover
        Label={({ onClick }) => (
          <MediumOutlineButton type="button" onClick={onClick}>
            Merge Tags
          </MediumOutlineButton>
        )}
      >
        {({ closePopover }) => (
          <Div width={250} display="column.flex-start.flex-start">
            {mergeTags.map(({ name, primary, summary, forms, secondary }) => (
              <Fragment>
                <MediumTextBox fw={3}>{name}</MediumTextBox>
                {(primary.length ? primary : secondary).map(tag => {
                  if ("fields" in tag) {
                    return (
                      <TableMenuItem
                        key={tag.field_id}
                        onClick={derivedTag => {
                          closePopover();
                          onMergeTagSelect(derivedTag);
                        }}
                        fields={tag.fields}
                        tag={tag.tag}
                      >
                        {tag.name}
                      </TableMenuItem>
                    );
                  }
                  return (
                    <MenuItem
                      key={tag.field_id}
                      onClick={() => {
                        closePopover();
                        onMergeTagSelect(formatTag(tag.tag));
                      }}
                    >
                      {tag.name}
                    </MenuItem>
                  );
                })}

                {forms.length > 0 ? (
                  <Popover
                    wrapperProps={{
                      width: 1,
                      display: "row.flex-start.center"
                    }}
                    innerSpanStyle={{ flex: 1 }}
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                    Label={({ onClick }) => (
                      <MenuItem
                        onClick={onClick}
                        display="row.flex-start.center"
                        flex={1}
                      >
                        <span>Forms</span>
                        <RightArrowIcon ml={2} sizeWFS={3} />
                      </MenuItem>
                    )}
                  >
                    {props => (
                      <Div width={250} display="column.flex-start.flex-start">
                        {forms.map(tag => {
                          return (
                            <MenuItem
                              key={tag.field_id}
                              onClick={() => {
                                props.closePopover();
                                closePopover();
                                onMergeTagSelect(formatTag(tag.tag));
                              }}
                            >
                              {tag.name}
                            </MenuItem>
                          );
                        })}
                      </Div>
                    )}
                  </Popover>
                ) : null}

                {summary.length > 0 ? (
                  <Popover
                    wrapperProps={{
                      width: 1,
                      display: "row.flex-start.center"
                    }}
                    innerSpanStyle={{ flex: 1 }}
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                    Label={({ onClick }) => (
                      <MenuItem
                        onClick={onClick}
                        display="row.flex-start.center"
                        flex={1}
                      >
                        <span>Summary</span>
                        <RightArrowIcon ml={2} sizeWFS={3} />
                      </MenuItem>
                    )}
                  >
                    {props => (
                      <Div width={250} display="column.flex-start.flex-start">
                        {summary.map(tag => {
                          return (
                            <MenuItem
                              key={tag.field_id}
                              onClick={() => {
                                props.closePopover();
                                closePopover();
                                onMergeTagSelect(formatTag(tag.tag));
                              }}
                            >
                              {tag.name}
                            </MenuItem>
                          );
                        })}
                      </Div>
                    )}
                  </Popover>
                ) : null}

                {primary.length > 0 && secondary.length > 0 ? (
                  <Popover
                    wrapperProps={{
                      width: 1,
                      display: "row.flex-start.center"
                    }}
                    innerSpanStyle={{ flex: 1 }}
                    anchorOrigin={{ horizontal: "right", vertical: "top" }}
                    transformOrigin={{ horizontal: "left", vertical: "top" }}
                    Label={({ onClick }) => (
                      <MenuItem
                        onClick={onClick}
                        display="row.flex-start.center"
                        flex={1}
                      >
                        <span>More</span>
                        <RightArrowIcon ml={2} sizeWFS={3} />
                      </MenuItem>
                    )}
                  >
                    {props => (
                      <Div width={250} display="column.flex-start.flex-start">
                        {secondary.map(tag => {
                          if ("fields" in tag) {
                            return (
                              <TableMenuItem
                                key={tag.field_id}
                                onClick={derivedTag => {
                                  props.closePopover();
                                  closePopover();
                                  onMergeTagSelect(derivedTag);
                                }}
                                fields={tag.fields}
                                tag={tag.tag}
                              >
                                {tag.name}
                              </TableMenuItem>
                            );
                          }
                          return (
                            <MenuItem
                              key={tag.field_id}
                              onClick={() => {
                                props.closePopover();
                                closePopover();
                                onMergeTagSelect(formatTag(tag.tag));
                              }}
                            >
                              {tag.name}
                            </MenuItem>
                          );
                        })}
                      </Div>
                    )}
                  </Popover>
                ) : (
                  ""
                )}
              </Fragment>
            ))}
          </Div>
        )}
      </Popover>
    );
  }
}

MergeTagMenu.propTypes = {};

export default modalHandler()(MergeTagMenu);
