import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import UserEditor from "./UserEditor";
import * as FormActions from "redux/modules/formsV2/form/actions";
import * as FormsActions from "redux/modules/formsV2/forms/actions";
import * as EventUserActions from "redux/modules/eventUsers/actions";

import * as EventSelectors from "redux/modules/event/selectors";
import * as EventUserSelectors from "redux/modules/eventUsers/selectors";
import * as UserSelectors from "redux/modules/user/selectors";
import { showModal, hideModal } from "redux/modules/modal/actions";

function mapStateToProps(state) {
  return {
    eventDetails: EventSelectors.eventDetails(state),
    isFetchingUsers: EventUserSelectors.isFetching(state),
    users: EventUserSelectors.users(state),
    user: UserSelectors.user(state),
    router: state.router
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...FormActions,
      ...FormsActions,
      ...EventUserActions,
      showModal,
      hideModal
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserEditor);
