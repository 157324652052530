import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AddButton from "./AddButton";
import * as flags from "utils/feature-flags";

import { addModule, copyModule } from "redux/modules/modules/module/actions";
import * as snackbarActions from "redux/modules/snackbar/actions";
import * as modalActions from "redux/modules/modal/actions";
import { getEvent } from "redux/modules/event/actions";

import { userPermissionProfile } from "redux/modules/permissions/user-permission-profile/selectors";
import { eventDetails } from "redux/modules/event/selectors";
import { user } from "redux/modules/user/selectors";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as EventsSelectors from "redux/modules/events/selectors";

function mapStateToProps(state) {
  const details = eventDetails(state);
  return {
    eventDetails: details,
    userPermissionProfile: userPermissionProfile(
      state,
      details.id,
      state.user.user.id
    ),
    user: user(state),
    canViewSchedules: selectFeatureFlag(flags.CAN_VIEW_PORTAL_SCHEDULE.NAME)(
      state
    ),
    isLenndAdmin: selectFeatureFlag(flags.IS_LENND_ADMIN.NAME)(state),
    allUserEvents: EventsSelectors.events(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addModule,
      copyModule,
      getEvent,
      ...modalActions,
      ...snackbarActions
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddButton);
