import React from "react";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import { Div, MediumFilledButton } from "components/Base";
import { withRouter } from "react-router";
import * as R from "ramda";

import { getters as SelectItemsGetters } from "PortalHub/CreateOrderRequest/SelectItems/model";
import { actions } from "../model";
import { addS } from "utils/General";

import { itemsInCart, getInvalidMessage } from "../selectors";
import styles from "./styles.scss";

const decorate = R.compose(
  connect(
    (state, props) => ({
      itemsInCartLen: itemsInCart(state, props),
      highlightColor: SelectItemsGetters.highlightColor(state, props),
      invalidMessage: getInvalidMessage(state, props)
    }),
    {
      checkout: actions.checkout
    }
  ),
  withRouter,
  CSSModules(styles)
);

const Layout = ({
  itemsInCartLen,
  highlightColor,
  checkout,
  router,
  invalidMessage
}) => (
  <Div styleName="widget-container">
    <Div display="column" mr={3}>
      <Div
        color="black"
        style={{ fontWeight: 500 }}
      >{`You have ${itemsInCartLen} item${addS(
        itemsInCartLen
      )} in your cart`}</Div>
      {invalidMessage ? (
        <Div fs={1} color="gray7" mt={1}>
          {invalidMessage}
        </Div>
      ) : null}
    </Div>

    <MediumFilledButton
      onClick={() => checkout(router)}
      bg={highlightColor}
      fw={3}
      color="white"
      disabled={invalidMessage && invalidMessage.length ? true : false}
    >
      View Cart
    </MediumFilledButton>
  </Div>
);

export default decorate(Layout);
