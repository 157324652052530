import React, { Fragment } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { sortBy } from "lodash";
import Item from "components/Global/Module/Sidebar/Item";
import Title from "components/Global/Module/Sidebar/Title";
import Separator from "components/Global/Module/Sidebar/Separator";
import SubTitle from "components/Global/Module/Sidebar/SubTitle";
import SubItem from "components/Global/Module/Sidebar/SubItem";
import Content from "components/Global/Module/Sidebar/Container";
import CanUserDo from "components/Global/Security/CanUserDo";

const NeedsSidebarScene = ({
  active,
  modules,
  onSceneChange,
  params = {},
  router
}) => (
  <Content>
    <Title onClick={onSceneChange} style={{ cursor: "pointer" }}>
      <i className="material-icons">keyboard_arrow_left</i>Needs
    </Title>
    <Item
      active={active === "needs"}
      linkTo={`/event/${params.eventId}/inventory/needs`}
    >
      Overview
    </Item>

    {/*
    // @NOTE: Commenting this out until we figure out if we want to keep it
    <Item
      active={active === "pending"}
      linkTo={`/event/${params.eventId}/inventory/needs/status/pending`}
    >
      Pending
    </Item>
    <Item
      active={active === "approved"}
      linkTo={`/event/${params.eventId}/inventory/needs/status/approved`}
    >
      Approved
    </Item>
    */}

    <Separator />
    <SubTitle>By Source</SubTitle>
    {sortBy(modules, m => m.name.toLowerCase()).map(moduleDetails => (
      <SubItem
        key={moduleDetails.id}
        active={params.moduleId === moduleDetails.id}
        linkProps={{
          pathname: `/event/${params.eventId}/module/${moduleDetails.id}/grid`,
          query: { returnTo: `/event/${params.eventId}/inventory/needs` }
        }}
      >
        {moduleDetails.name}
      </SubItem>
    ))}
    <CanUserDo action={"read_catalog_items"}>
      <Fragment>
        <Separator />
        <Item
          onClick={() => {
            onSceneChange("catalog");
            router.push({
              pathname: `/event/${params.eventId}/settings/inventory/catalog`
            });
          }}
        >
          Catalog
        </Item>
      </Fragment>
    </CanUserDo>
    {/* <Item linkTo={`/event/${params.eventId}/settings`}>
      <span styleName="settings"><i className="material-icons">settings</i>Settings</span>
    </Item> */}
  </Content>
);

export default CSSModules(NeedsSidebarScene, css);
