import {
  REQUEST,
  RECEIVE,
  ERROR,
  CLEAR_ERROR,
  INVALIDATE,
  DELETE_SUBMISSION
} from "./constants";
import { RECEIVE as RECEIVE_FORM } from "redux/modules/formsV2/form/constants";
import { deleteSubmission as deleteSubmissionApi } from "../submission/actions";
import * as selectors from "./selectors";
import api from "./api";

export function getOverview(formId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const overview = await api.get(getState().user.user.credentials, formId);
      dispatch({ type: RECEIVE, payload: { overview } });
      dispatch({
        type: RECEIVE_FORM,
        payload: {
          form: {
            form: overview.overview.form
          }
        }
      });
    } catch (error) {
      dispatch({
        type: ERROR,
        payload: { error: error.error || "An error occurred!" }
      });
    }
  };
}

export function deleteOverviewSubmission(data) {
  return dispatch =>
    Promise.all([
      dispatch(deleteSubmissionApi(data)),
      dispatch({ type: DELETE_SUBMISSION, payload: data })
    ]);
}

export function invalidate() {
  return {
    type: INVALIDATE
  };
}

export function clearError() {
  return {
    type: CLEAR_ERROR
  };
}
