import React, { Component } from "react";
import "styles/less/includes/plugins/react-toggle-switch.less";

import Panel from "./Panel";
import Group from "./Group";
import Permission from "./Permission";
import Well from "./Well";
import ModifyRoleModal from "./Modals/ModifyRole";
import DeleteProfileModal from "./Modals/DeleteProfile";
import PermissionsSidebar from "./PermissionsSidebar";
import PermissionsHeader from "./PermissionsHeader";
import Search from "./Search";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as R from "ramda";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { Radio, Div } from "components/Base";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import { showModal, hideModal } from "redux/modules/modal/actions";
import { orgDetails as getOrgDetails } from "redux/modules/organization/selectors";
import { getters, actions } from "../model";

const decorate = R.compose(
  withRouter,
  connect(
    state => ({
      permissionGroups: getters.permissionGroups(state),
      permissionProfiles: getters.permissionProfiles(state),
      orgDetails: getOrgDetails(state),
      search: getters.search(state),
      activePermissionProfileId: getters.activePermissionProfileId(state),
      showItemsModalForModuleId: getters.showItemsModalForModuleId(state)
    }),
    {
      showModal,
      hideModal,
      updatePermissionProfilePermissions:
        actions.updatePermissionProfilePermissions,
      getOrgPermissionProfiles: actions.getOrgPermissionProfiles,
      setSearch: actions.setSearch,
      setActivePermissionProfileId: actions.setActivePermissionProfileId,
      setShowItemsModalForModuleId: actions.setShowItemsModalForModuleId,
      addOrgPermissionProfile: actions.addOrgPermissionProfile,
      updatePermissionProfile: actions.updatePermissionProfile,
      deletePermissionProfile: actions.deletePermissionProfile
    }
  ),
  CSSModules(css)
);

const ReadPermission = ({ selected, name, onClick, children }) => (
  <Div display="column">
    <Div display="row.flex-start.center" onClick={onClick} mb={2}>
      <Radio selected={selected} mr={1} size={20} />
      <Div fs={2} color="black">
        {name}
      </Div>
    </Div>
    {children}
  </Div>
);

class Body extends Component {
  updateSearch = e => this.props.setSearch({ search: e.target.value });

  searchQueryExists = () => Boolean(this.props.search.trim().length);

  groupNameMatchesSearch = name =>
    name
      .toLowerCase()
      .trim()
      .indexOf(this.props.search) > -1;

  getActivePermissionProfile = () =>
    this.props.permissionProfiles.find(
      p => p.id === this.props.activePermissionProfileId
    );

  handlePermissionGroup = (permissionGroupId, enabled) => {
    const activePermissionProfile = this.getActivePermissionProfile();
    const permissionGroup = this.props.permissionGroups.find(
      g => g.id === permissionGroupId
    );
    let permissions = [...activePermissionProfile.permissions];

    if (enabled) {
      const permissionGroupHasDefaultPermissions = Boolean(
        permissionGroup.permissions.filter(p => p.is_default).length
      );
      const permissionsToAdd = permissionGroupHasDefaultPermissions
        ? permissionGroup.permissions.reduce((result, permission) => {
            if (permission.is_default) {
              result.push(permission);
            }
            return result;
          }, [])
        : permissionGroup.permissions;

      permissions = [...permissions, ...permissionsToAdd];
    } else {
      permissions = permissions.filter(
        permission =>
          permissionGroup.permissionList.indexOf(permission.slug) === -1
      );
    }

    this.props.updatePermissionProfilePermissions({
      orgId: this.props.params.orgId,
      profileId: this.props.activePermissionProfileId,
      permissions: permissions.map(this.formatPermission)
    });
  };

  handlePermission = (permission, enabled) => {
    const activePermissionProfile = this.getActivePermissionProfile();
    let permissions = [...activePermissionProfile.permissions];
    if (enabled) {
      permissions.push(permission);
    } else {
      permissions = permissions.filter(p => p.slug !== permission.slug);
    }

    this.props.updatePermissionProfilePermissions({
      orgId: this.props.params.orgId,
      profileId: this.props.activePermissionProfileId,
      permissions: permissions.map(this.formatPermission)
    });
  };

  enableAllPermissions = () => {
    const permissions = this.props.permissionGroups.reduce(
      (result, group) => [...result, ...group.permissions],
      []
    );

    this.props.updatePermissionProfilePermissions({
      orgId: this.props.params.orgId,
      profileId: this.props.activePermissionProfileId,
      permissions: permissions.map(this.formatPermission)
    });
  };

  disableAllPermissions = () => {
    this.props.updateRolePermissions(
      this.props.params.orgId,
      this.props.activePermissionProfileId,
      []
    );
  };

  isPermissionGroupSwitchOn = permissionGroup => {
    const activePermissionProfile = this.props.permissionProfiles.find(
      p => p.id === this.props.activePermissionProfileId
    );
    return permissionGroup.permissions
      .map(p => p.slug)
      .some(slug => activePermissionProfile.permissionList.indexOf(slug) > -1);
  };

  isPermissionSwitchOn = permissionSlug =>
    this.props.permissionProfiles
      .find(p => p.id === this.props.activePermissionProfileId)
      .permissionList.indexOf(permissionSlug) > -1;

  onPermissionMenuClick = (permissionProfileId, event, command) => {
    event.preventDefault();
    switch (command) {
      case "edit":
        this.showModifyRoleModal(permissionProfileId);
        break;
      case "delete":
        this.showDeletePermissionProfileModal(permissionProfileId);
        break;
      case "duplicate":
        const permissionProfile = this.props.permissionProfiles.find(
          p => p.id === permissionProfileId
        );
        const name = `${permissionProfile.name} Copy`;
        this.onCreatePermissionProfile(
          this.generateCopyName(name, this.props.permissionProfiles),
          permissionProfile.permissions
        );
        break;
      default:
        break;
    }
  };

  generateCopyName = (name, permissionProfiles, count = 0) => {
    const newName = `${name}${count > 0 ? ` (${count})` : ""}`;
    const duplicate = permissionProfiles.find(r => r.name === newName);
    if (duplicate) {
      return this.generateCopyName(name, permissionProfiles, count + 1);
    }
    return newName;
  };

  getDefaultPermissionProfile = permissionProfiles =>
    permissionProfiles.filter(p => !p.editable)[0].id;

  selectPermissionProfile = activePermissionProfileId =>
    this.props.setActivePermissionProfileId(activePermissionProfileId);

  formatPermission = permission => ({
    permissionId: permission.id,
    permissionModuleId: permission.module_id
  });

  onCreatePermissionProfile = (name, permissions = []) => {
    this.props.addOrgPermissionProfile({
      orgId: this.props.params.orgId,
      name,
      editable: true,
      description: null,
      permissions: permissions.map(this.formatPermission)
    });
  };

  showModifyRoleModal = permissionProfileId => {
    const { name, description } = this.props.permissionProfiles.find(
      p => p.id === permissionProfileId
    );
    const { hideModal } = this.props;
    const done = (newName, newDescription) => {
      this.props.updatePermissionProfile({
        orgId: this.props.params.orgId,
        profileId: permissionProfileId,
        name: newName,
        description: newDescription
      });
    };

    this.props.showModal({
      content: <ModifyRoleModal {...{ done, name, description, hideModal }} />
    });
  };

  showDeletePermissionProfileModal = permissionProfileId => {
    const permissionProfile = this.props.permissionProfiles.find(
      p => p.id === permissionProfileId
    );
    const { hideModal } = this.props;
    const orgId = this.props.params.orgId;
    const done = ({ replaceWithProfileId }) => {
      hideModal();

      if (this.props.activePermissionProfileId === permissionProfileId) {
        this.props.setActivePermissionProfileId(replaceWithProfileId);
      }

      this.props.deletePermissionProfile({
        orgId,
        profileId: permissionProfile.id,
        replaceWithProfileId
      });
    };

    this.props.showModal({
      content: (
        <DeleteProfileModal
          {...{
            done,
            orgId,
            profileId: permissionProfile.id,
            hideModal,
            permissionProfiles: this.props.permissionProfiles
          }}
        />
      )
    });
  };

  renderPermissionGroups = ({
    groups,
    canUpdatePermission,
    forceEnableAllPermissions
  }) =>
    groups.map(permissionGroup => {
      const isPermissionGroupSwitchOn = this.isPermissionGroupSwitchOn(
        permissionGroup
      );
      const handlePermissionGroup = () => {
        if (canUpdatePermission) {
          this.handlePermissionGroup(
            permissionGroup.id,
            !isPermissionGroupSwitchOn
          );
        }
      };

      return (
        <Group
          permissionGroup={permissionGroup}
          enabled={canUpdatePermission}
          on={forceEnableAllPermissions || isPermissionGroupSwitchOn}
          onClick={handlePermissionGroup}
          key={`${this.props.activePermissionProfileId}_${permissionGroup.id}`}
        >
          {permissionGroup.permissions
            .filter(
              p =>
                !p.slug.includes("read_typed_records") &&
                !p.slug.includes("read_owned_records")
            )
            .map(permission => {
              const isPermissionSwitchOn = this.isPermissionSwitchOn(
                permission.slug
              );
              const handlePermission = () => {
                if (canUpdatePermission) {
                  this.handlePermission(permission, !isPermissionSwitchOn);
                }
              };

              return (
                <Permission
                  key={`${permissionGroup.id}_${permission.slug}`}
                  permission={{
                    ...permission
                  }}
                  onClick={handlePermission}
                  enabled={canUpdatePermission}
                  on={forceEnableAllPermissions || isPermissionSwitchOn}
                />
              );
            })}
        </Group>
      );
    });

  render() {
    const { permissionProfiles, orgDetails } = this.props;

    const activeRole = this.getActivePermissionProfile();

    // override switches for admin profile to show all turned on
    const forceEnableAllPermissions = [
      "7c1fba5d-633a-4d54-9077-a50a5bd4e7e0",
      "45f029df-33ca-4c53-a02c-bbd6c92a5f35"
    ].includes(activeRole.id);

    const canUpdatePermission = !!activeRole.editable;
    const searchQueryExists = this.searchQueryExists();
    let standardModulePermissionGroups = searchQueryExists
      ? this.props.permissionGroups.filter(
          g => g.source === "standard" && this.groupNameMatchesSearch(g.name)
        )
      : this.props.permissionGroups.filter(g => g.source === "standard");
    standardModulePermissionGroups = standardModulePermissionGroups.filter(g =>
      orgDetails.enabled_modules.find(m => m.id === g.id)
    );

    const customModulePermissionGroups = searchQueryExists
      ? this.props.permissionGroups.filter(
          g => g.source !== "standard" && this.groupNameMatchesSearch(g.name)
        )
      : this.props.permissionGroups.filter(g => g.source !== "standard");

    return (
      <Div display="row.flex-start.flex-start">
        <PermissionsSidebar
          permissionProfiles={permissionProfiles}
          activeRole={this.props.activePermissionProfileId}
          onRoleSelect={this.selectPermissionProfile}
          onCreate={this.onCreatePermissionProfile}
          onMenuClick={this.onPermissionMenuClick}
        />
        <Well>
          <PermissionsHeader
            canEditPermissions={canUpdatePermission}
            role={activeRole}
            enableAllPermissions={this.enableAllPermissions}
            disableAllPermissions={this.disableAllPermissions}
          />
          <Search onChange={this.updateSearch} query={this.props.search} />

          {standardModulePermissionGroups.length ? (
            <Panel>
              {this.renderPermissionGroups({
                groups: [
                  ...standardModulePermissionGroups.filter(
                    g => g.id === STANDARD_MODULE_IDS.settings.id
                  ),
                  ...standardModulePermissionGroups.filter(
                    g => g.id !== STANDARD_MODULE_IDS.settings.id
                  )
                ],
                canUpdatePermission
              })}
            </Panel>
          ) : null}

          {customModulePermissionGroups.length ? (
            <div>
              <div styleName="moduleTypeTitle">Custom Modules</div>
              <Panel>
                {this.renderPermissionGroups({
                  groups: customModulePermissionGroups,
                  canUpdatePermission,
                  forceEnableAllPermissions
                })}
              </Panel>
            </div>
          ) : null}

          <br />
          <br />
        </Well>
      </Div>
    );
  }
}

export default decorate(Body);
