import React, { Component } from "react";
import { withState } from "utils/General";
import Report from "Portal/PortalReports/AssetReport";
import ItemReport from "Portal/PortalReports/ItemAssetReport";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getAssetReport,
  getItemAssetReport
} from "redux/modules/portal/reports/actions";
import { Div, LoadingIcon } from "components/Base";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getAssetReport,
      getItemAssetReport
    },
    dispatch
  );
}

class AccountAssetReport extends Component {
  async componentDidMount() {
    if (this.props.canViewInventory) {
      await this.props.getItemAssetReport({
        eventId: this.props.params.eventId,
        accountId: this.props.params.recordId
      });
    } else {
      await this.props.getAssetReport({
        eventId: this.props.params.eventId,
        accountId: this.props.params.recordId
      });
    }

    this.props.setLoadState(false);
  }

  componentDidUpdate(prevProps) {
    // @NOTE: Remove this when all events are using new inventory
    if (
      prevProps.canViewInventory !== this.props.canViewInventory &&
      this.props.canViewInventory === true
    ) {
      this.props.getItemAssetReport({
        eventId: this.props.params.eventId,
        accountId: this.props.params.recordId
      });
    }
  }

  render() {
    if (this.props.loading) {
      <Div p={10} display="row.center.center">
        <LoadingIcon size={40} />
      </Div>;
    }
    return this.props.canViewInventory ? (
      <ItemReport view="admin" />
    ) : (
      <Report view="admin" />
    );
  }
}

export default connect(
  state => ({
    canViewInventory: selectFeatureFlag(flags.CAN_VIEW_INVENTORY.NAME)(state)
  }),
  mapDispatchToProps
)(withState("loading", "setLoadState", true)(AccountAssetReport));
