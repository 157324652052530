import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "./constants";

const iniState = {
  templatesFilter: "",
  loading: false,
  documentTemplates: [],
  modules: [],
  selectedModule: null,
  showUploadTemplateModal: false,
  template: null,
  templateUrl: "",
  templateTitle: "",
  templateImageUrl: "",
  moduleId: null,
  selectedMergeModuleId: "",
  mergeData: null,
  mergeTagsLoading: false,
  showMergetagModal: false,
  selectedRelatedModule: null,
  viewMoreTagsIds: [],
  hasModuleId: false,
  tagsFilter: ""
};

const handlers = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    cancelInstance: R.always(iniState),
    editTemplate: R.identity,
    deleteTemplate: R.identity,
    refetchDocumentTemplates: R.identity,
    addUploadDocument: R.identity,
    downloadDocument: R.identity,
    fetchMergeData: R.identity,
    selectModule: R.identity,
    setInitialData: (
      state,
      {
        payload: {
          documentTemplates,
          modules,
          moduleId,
          mergeData,
          hasModuleId
        }
      }
    ) => ({
      documentTemplates: R.sortBy(
        R.compose(
          R.toLower,
          R.prop("title")
        ),
        [...documentTemplates]
      ),
      modules,
      moduleId,
      selectedMergeModuleId: R.propOr("", "id", modules[0]),
      mergeData,
      hasModuleId
    }),
    showEditTemplateModal: (state, { payload: { template } }) => ({
      template,
      showUploadTemplateModal: true
    }),
    showAddTemplateModal: (
      state,
      { payload: { templateUrl, templateTitle, templateImageUrl } }
    ) => ({
      templateTitle,
      templateUrl,
      templateImageUrl,
      showUploadTemplateModal: true
    }),
    hideTemplateModal: () => ({
      showUploadTemplateModal: false,
      template: null,
      templateUrl: "",
      templateImageUrl: ""
    }),
    showMergeTagModal: (state, { payload: { selectedRelatedModule } }) => ({
      selectedRelatedModule,
      showMergetagModal: true
    }),
    toggleViewMoreTagsIds: (state, { payload: { id, isExpanded } }) => ({
      viewMoreTagsIds: isExpanded
        ? R.filter(tagId => tagId !== id, state.viewMoreTagsIds)
        : [...state.viewMoreTagsIds, id]
    })
  },
  namespace: NAMESPACE
});

export default handlers;
