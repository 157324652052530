import * as R from "ramda";

export const isFetching = state => state.portal.user.fetching;
export const portalUser = state => state.portal.user.user;
export const accounts = state =>
  R.pathOr([], ["portal", "user", "user", "accounts"])(state);
export const relatedAccountId = state =>
  R.compose(
    R.prop("id"),
    R.head
  )(accounts(state));
export const relatedAccountName = state =>
  R.compose(
    R.prop("name"),
    R.head
  )(accounts(state));
