import PropTypes from "prop-types";
import React, { Component } from "react";
import Helpers from "utils/Global/Helpers";
import ClassNames from "classnames";
import autobind from "autobind-decorator";
import ManageEventActions from "actions/Event/DetailsActions";
import PermissionSelect from "components/Global/Inputs/PermissionSelect";
import PortalFormsSelect from "components/Global/Inputs/PortalFormsSelect";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import PortalUsersApi from "redux/modules/portal/users/api";

@CSSModules(css)
class AddEventTeamMemberModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: "",
      fname: "",
      lname: "",
      userType: "admin",
      permission: null,
      forms: [],
      error: null,
      matches: [],
      selectedRecord: null
    };
  }

  @autobind
  handleQueryChange(event) {
    const val = event.target.value;
    this.setState({
      query: val.toLowerCase(),
      fname: Helpers.isValidEmail(val)
        ? val[0].toUpperCase() + val.substring(0, val.indexOf("@")).slice(1)
        : "",
      lname: "",
      error: null
    });
    this.search(val);
  }

  @autobind
  handleFirstNameChange(event) {
    this.setState({ fname: event.target.value });
  }

  @autobind
  handleLastNameChange(event) {
    this.setState({ lname: event.target.value });
  }

  @autobind
  onPermissionsChange(permission) {
    this.setState({
      permission,
      error: null
    });
  }

  @autobind
  onFormsChange(forms) {
    this.setState({
      forms,
      error: null
    });
  }

  @autobind
  emailExistsWithinResults() {
    return !!this.state.matches.filter(
      r => r.user_email.toLowerCase() === this.state.query
    ).length;
  }

  @autobind
  search(q) {
    if (q.length) {
      Helpers.searchUsers(
        this.props.user.credentials,
        q,
        this.handleSearchResult,
        {
          eventId: this.props.addToEventId
        }
      );
    } else {
      this.setState({ matches: [] });
    }
  }

  @autobind
  handleSearchResult(result) {
    this.setState({ matches: result.payload });
  }

  @autobind
  handleInvite() {
    if (!this.state.fname.length || !this.state.lname.length) {
      this.setState({ error: "Please enter a first and last name." });
    } else if (this.state.userType === "admin" && !this.state.permission) {
      this.setState({ error: "Please select a permission." });
    } else {
      this.setState({ error: null });

      if (this.state.userType === "admin") {
        this.inviteNewUserToEvent();
      } else if (this.state.userType === "portal") {
        this.inviteNewUserToPortal();
      }
    }
  }

  @autobind
  inviteNewUserToEvent() {
    // build data
    const data = {
      email: this.state.query,
      fname: this.state.fname,
      lname: this.state.lname,
      permission: this.state.permission,
      addToEventId: this.props.addToEventId || 0
    };

    // send data
    ManageEventActions.inviteNewUserToEvent(
      this.props.user.credentials,
      data,
      user => {
        this.props.onAdded(data, user, "invited");
        this.props.hideModal();
      }
    );
  }

  @autobind
  inviteNewUserToPortal() {
    // build data
    const data = {
      email: this.state.query,
      fname: this.state.fname,
      lname: this.state.lname,
      suppressEmail: true
    };

    // send data
    ManageEventActions.inviteNewUserToEvent(
      this.props.user.credentials,
      data,
      user => {
        // Create portal record
        PortalUsersApi.post(
          { userId: 7 },
          {
            eventId: this.props.addToEventId,
            userId: user.id,
            status: "pending",
            forms: this.state.forms
          }
        ).then(() => {
          this.props.onAdded(null, user, "invited");
          this.props.hideModal();
        });
      }
    );
  }

  @autobind
  selectRecord(record) {
    this.setState({
      selectedRecord: record
    });
  }

  @autobind
  cancelRecordSelection() {
    this.setState({
      selectedRecord: null
    });
  }

  @autobind
  handleAdd() {
    const { permission, userType } = this.state;

    if (userType === "admin" && !permission) {
      this.setState({ error: "Please select a permission." });
    } else {
      this.setState({ error: null });

      if (this.state.userType === "admin") {
        this.addExistingUserToEvent();
      } else if (this.state.userType === "portal") {
        this.addExistingUserToPortal();
      }
    }
  }

  @autobind
  addExistingUserToEvent() {
    const { selectedRecord, permission } = this.state;

    // build data
    const data = {
      userId: selectedRecord.user_id,
      permission,
      addToEventId: this.props.addToEventId || 0
    };

    // send data
    ManageEventActions.inviteExistingUserToEvent(
      this.props.user.credentials,
      data,
      () => {
        this.props.onAdded(data, selectedRecord, "added");
        this.props.hideModal();
      }
    );
  }

  @autobind
  addExistingUserToPortal() {
    const { selectedRecord, forms } = this.state;

    // Create portal record and send email to access portal for one of the form ids
    PortalUsersApi.post(
      { userId: 7 },
      {
        eventId: this.props.addToEventId,
        userId: selectedRecord.user_id,
        status: "pending",
        forms
      }
    ).then(() => {
      this.props.onAdded(null, selectedRecord, "added");
      this.props.hideModal();
    });
  }

  @autobind
  renderSettings() {
    return (
      <div styleName="settingsContainer">
        <div styleName="settingsTitle">What type of user is this?</div>

        <div
          styleName="settingsRow"
          onClick={() => this.setState({ userType: "admin" })}
        >
          <div styleName="settingsRadioWrapper">
            <input
              checked={this.state.userType === "admin"}
              styleName="settingsRadio"
              type="radio"
            />
          </div>
          <div styleName="settingsDescription">
            <div styleName="settingsDescriptionTitle">Event Admin</div>
            <div styleName="settingsDescriptionDescription">
              Allow the user to manage your event and access the event portal.
            </div>
            {this.state.userType === "admin" ? (
              <div className="search- -field">
                <div styleName="inputContainer" className="clearfix">
                  <label>
                    Permissions
                    <PermissionSelect
                      width="199px"
                      onSelect={this.onPermissionsChange}
                    />
                  </label>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        {/*
        <div
          styleName="settingsRow"
          onClick={() => this.setState({ userType: "portal" })}
        >
          <div styleName="settingsRadioWrapper">
            <input
              checked={this.state.userType === "portal"}
              styleName="settingsRadio"
              type="radio"
            />
          </div>

          <div styleName="settingsDescription">
            <div styleName="settingsDescriptionTitle">Portal User</div>
            <div styleName="settingsDescriptionDescription">
              Only allow the user to access the event portal.
            </div>
            {this.state.userType === "portal" ? (
              <div className="search-add-field">
                <div styleName="inputContainer" className="clearfix">
                  <label>
                    Can view forms:
                    <PortalFormsSelect
                      eventId={this.props.addToEventId}
                      width="199px"
                      onSelect={this.onFormsChange}
                    />
                  </label>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      */}
      </div>
    );
  }

  render() {
    let description;
    let results;
    let tip;
    let error;

    if (this.state.error) {
      error = <div className="search-error">{this.state.error}</div>;
    }

    /**
     *  invite new user
     */
    if (this.state.selectedRecord) {
      results = (
        <div className="search-add">
          <div className="search-add-description">
            {this.state.selectedRecord.user_fname ? (
              <div>
                Add{" "}
                <strong>
                  {`${this.state.selectedRecord.user_fname} ${this.state.selectedRecord.user_lname}`}
                </strong>{" "}
                ({this.state.selectedRecord.user_email}) to this event. Select a
                permission and click “Send” and we’ll send them an invite email.
                They’ll automatically receive access to the event once they
                accept the invite.
              </div>
            ) : (
              <div>
                Add <strong>{this.state.selectedRecord.user_email}</strong> to
                this event. Select a permission and click “Send” and we’ll send
                them an invite email. They’ll automatically receive access to
                the event once they accept the invite.
              </div>
            )}
          </div>

          {this.renderSettings()}

          <div className="search-submit" onClick={this.handleAdd}>
            Send
          </div>
          <div styleName="cancel" onClick={this.cancelRecordSelection}>
            Select different person
          </div>
          {error}
        </div>
      );
    } else if (
      Helpers.isValidEmail(this.state.query) &&
      !this.emailExistsWithinResults()
    ) {
      results = (
        <div className="search-add">
          <div className="search-add-description">
            <strong className="heavy">{this.state.query}?</strong> We don’t know
            that person yet. Add their name and click “Send” and we’ll send them
            an invite email. They’ll automatically receive access to the event
            once they sign up and confirm their email address.
          </div>
          <div className="search-add-field">
            <label>First and Last Name</label>

            <div styleName="inputContainer" className="clearfix">
              <input
                name="first_name"
                value={this.state.fname}
                onChange={this.handleFirstNameChange}
                type="text"
                className="styled input-fname"
                placeholder="First Name"
                styleName="nameInput"
              />
              <input
                name="last_name"
                value={this.state.lname}
                onChange={this.handleLastNameChange}
                type="text"
                className="styled input-lname"
                placeholder="Last Name"
                styleName="lnameInput"
              />
            </div>
          </div>

          {this.renderSettings()}

          <div className="search-submit" onClick={this.handleInvite}>
            Send
          </div>
          {error}
        </div>
      );
    } else if (!this.state.matches || !this.state.matches.length) {
      description = (
        <div className="description">
          <span className="heavy">Enter an email address </span>
          to invite someone new or search for a person in Lennd by name or email
          address.
        </div>
      );
      tip = (
        <div
          className="modal-tip"
          style={{
            color: "#fff"
          }}
        >
          <strong>Helpful tip: </strong>
          You can add volunteers, internal team members, sponsors, external
          vendors, etc. Don’t worry, you can set their permissions level later
          on.
        </div>
      );
    } else {
      results = (
        <div className="search-results">
          <div className="search-results-header">Select to add</div>
          {this.state.matches.map(member => {
            // build photo attributes
            let acronym;
            const memberPhotoStyle = {};
            let memberPhotoClasses = {
              photo: true
            };
            if (member.user_photo_url) {
              memberPhotoStyle.backgroundImage = `url('${member.user_photo_url}/convert?w=70&h=70&fit=clip')`;
            } else {
              acronym = member.user_fname
                ? Helpers.buildUserAcronym(member.user_fname, member.user_lname)
                : Helpers.buildUserAcronym(member.user_email, "");
              memberPhotoClasses.empty = true;
            }
            memberPhotoClasses = ClassNames(memberPhotoClasses);

            return (
              <div
                key={member.user_id}
                className="search-results-result clearfix"
                onClick={this.selectRecord.bind(null, member)}
              >
                <i className="icon icon-angle-right fa fa-angle-right" />
                <div className={memberPhotoClasses} style={memberPhotoStyle}>
                  {acronym}
                </div>
                <div className="info">
                  {member.user_fname ? (
                    <div>
                      <div className="name">
                        {member.user_fname} {member.user_lname}
                      </div>
                      <div className="email">{member.user_email}</div>
                    </div>
                  ) : (
                    <div className="name">{member.user_email}</div>
                  )}
                </div>
              </div>
            );
          })}
          <div className="search-results-footer">
            <strong>Don’t see them?</strong> Enter their email address above.
          </div>
        </div>
      );
    }

    return (
      <div>
        <div className="modal-header">
          <div className="modal-title">Add user</div>
        </div>

        <div className="modal-body">
          <div className="modal-body-wrapper">
            {!this.state.selectedRecord ? (
              <div className="input-wrapper">
                <input
                  name="member"
                  value={this.state.query}
                  onChange={this.handleQueryChange}
                  type="text"
                  className="styled input-query"
                  placeholder="example: chris@lennd.com"
                />
              </div>
            ) : (
              ""
            )}
            {results}
          </div>
        </div>
        {tip}
      </div>
    );
  }
}

AddEventTeamMemberModal.propTypes = {
  addToEventId: PropTypes.number,
  onAdded: PropTypes.func,
  hideModal: PropTypes.func
};

AddEventTeamMemberModal.getModalClassName = () => "AddEventTeamMemberModal";

export default AddEventTeamMemberModal;
