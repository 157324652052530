import { createSelector } from "reselect";
import * as R from "ramda";
import { INTEGRATION_IDS } from "./constants";
import { getters } from "./model";

export const getIntegrationName = createSelector(
  getters.isCampaignsModalOpen,
  id =>
    R.prop(id)({
      [INTEGRATION_IDS.CLASSY]: `Classy`,
      [INTEGRATION_IDS.NEONONE]: `Neon CRM`,
      [INTEGRATION_IDS.PARDOT]: `Pardot`,
      [INTEGRATION_IDS.SALESFORCE]: `Salesforce`,
      [INTEGRATION_IDS.RAISERSEDGE]: `Raiser's Edge`,
      [INTEGRATION_IDS.EVENTBRITE]: `Eventbrite`,
      [INTEGRATION_IDS.HUBSPOT]: `Hubspot`
    })
);

export const getIntegrationObjectName = createSelector(
  getters.isCampaignsModalOpen,
  id =>
    R.propOr("Campaign", id)({
      [INTEGRATION_IDS.CLASSY]: `Campaign`,
      [INTEGRATION_IDS.NEONONE]: `Event`,
      [INTEGRATION_IDS.RAISERSEDGE]: `Event`,
      [INTEGRATION_IDS.EVENTBRITE]: `Event`
    })
);

export const getLenndEvents = createSelector(
  getters.lenndEvents,
  getters.mappings,
  (events, mappings) => {
    const existing = R.map(id => `${id}`)(R.keys(mappings));

    return R.compose(
      R.map(e => ({
        label: e.name,
        value: `${e.id}`
      })),
      R.filter(e => !existing.includes(`${e.id}`))
    )(events);
  }
);

export const getCampaigns = createSelector(
  getters.campaigns,
  getters.mappings,
  (campaigns, mappings) => {
    const existing = R.map(id => `${id}`)(R.values(mappings));
    return R.compose(
      R.map(c => ({
        label: c.name,
        value: `${c.id}`
      })),
      R.filter(c => !existing.includes(`${c.id}`))
    )(campaigns);
  }
);

export const getExistingMappings = createSelector(
  getters.mappings,
  getters.lenndEvents,
  getters.campaigns,
  getters.syncing,
  (mappings, events, campaigns, syncing) => {
    return R.reduce((a, b) => {
      a.push({
        syncing: `${b}` === `${syncing}`,
        event_id: `${b}`,
        campaign_id: `${mappings[b]}`,
        event_name: R.compose(
          R.propOr("(Unknown)", "name"),
          R.find(e => `${e.id}` === `${b}`)
        )(events),
        campaign_name: R.compose(
          R.propOr("(Unknown)", "name"),
          R.find(c => `${c.id}` === `${mappings[b]}`)
        )(campaigns)
      });
      return a;
    }, [])(R.keys(mappings));
  }
);

export const getIsMappingValid = createSelector(
  getters.selectedCampaignId,
  getters.selectedEventId,
  (selectedCampaignId, selectedEventId) => {
    return selectedCampaignId && selectedEventId ? true : false;
  }
);

export const getClassyDetails = createSelector(
  getters.integrations,
  R.prop(INTEGRATION_IDS.CLASSY)
);

export const getNeonOneDetails = createSelector(
  getters.integrations,
  R.prop(INTEGRATION_IDS.NEONONE)
);

export const getPardotDetails = createSelector(
  getters.integrations,
  R.prop(INTEGRATION_IDS.PARDOT)
);

export const getSalesforceDetails = createSelector(
  getters.integrations,
  R.prop(INTEGRATION_IDS.SALESFORCE)
);

export const getEventbriteDetails = createSelector(
  getters.integrations,
  R.prop(INTEGRATION_IDS.EVENTBRITE)
);

export const getHubspotDetails = createSelector(
  getters.integrations,
  R.prop(INTEGRATION_IDS.HUBSPOT)
);

export const getRaisersEdgeDetails = createSelector(
  getters.integrations,
  R.prop(INTEGRATION_IDS.RAISERSEDGE)
);
