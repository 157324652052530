import React from "react";
import { Div } from "components/Base";

import { connect } from "react-redux";
import {
  getFiltersOn,
  getRowActions,
  getColumnActions
} from "EventLight/ProductionSchedules/Schedule/selectors";

import { actions, getters } from "EventLight/ProductionSchedules/Schedule";
import { TABLE_INSTANCE_ID } from "EventLight/ProductionSchedules/Schedule/constants";

import Toolbar from "./Toolbar";
import AddActivityRow from "Schedules/AddActivityRow/View";
import FiltersOn from "ui-kit/FiltersOn";
import ShowNoResults from "ui-kit/ShowNoResults";
import ShowNoSchedules from "./ShowNoSchedules";

import Table, { TableWrapper } from "ui-kit/Table/View";

import styleSpects from "components/Base/Base/styleSpecs";

const decorate = connect(
  (state, props) => ({
    filtersOn: getFiltersOn(state),
    loading: getters.loading(state),
    showNoResults: getters.showNoResults(state),
    showNoSchedules: getters.showNoSchedules(state),
    preferences: getters.preferences(state),
    moduleId: getters.moduleId(state),
    rowActions: getRowActions(state),
    columnActions: getColumnActions(state, props)
  }),
  {
    onClearFilters: actions.clearFilters
  }
);

const GridLayout = ({
  handlers,
  filtersOn,
  onClearFilters,
  showNoResults,
  showNoSchedules,
  moduleId,
  loading,
  rowActions = [],
  columnActions,
  ...styleProps
}) => (
  <>
    <Toolbar handlers={handlers} />

    <Div
      {...styleProps}
      style={{
        height: "calc(100vh - 211px)"
      }}
    >
      {/* {showNoSchedules ? null : <AddActivityRow key={moduleId} />} */}
      <FiltersOn
        on={filtersOn}
        onClearFilters={onClearFilters}
        caption="Only showing results that contain or match filters"
      />
      {showNoSchedules ? (
        <ShowNoSchedules handlers={handlers} />
      ) : showNoResults ? (
        <ShowNoResults />
      ) : (
        <TableWrapper
          innerStyle={{ borderRadius: styleSpects.borderRadius[1] }}
          loading={loading}
          transparentLoading
          instanceId={TABLE_INSTANCE_ID}
        >
          <Table
            instanceId={TABLE_INSTANCE_ID}
            showOpenRecord
            {...{
              meta: {
                moduleId,
                showButton: true
              },
              rowActions,
              columnActions
            }}
          />
        </TableWrapper>
      )}
    </Div>
  </>
);

export default decorate(GridLayout);
