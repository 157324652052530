import React, { Component } from "react";
import getApprovalValue from "utils/value-types/get-value/approval";
import ApproversLabel from "components/Global/Approvals/ApproversLabel";
import { parseApproval } from "components/Global/Approvals/utils/approvals-helpers";

import * as R from "ramda";

import { getMeta, getValue, getHandlers } from "ui-kit/Datagrid/utils";

import { noop } from "utils/General";

class ApprovalCellFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !R.equals(this.props.value, nextProps.value);
  }

  render() {
    const props = this.props;
    const userId = getMeta(props, ["user", "id"]);
    const value = getValue(props);
    const approveRecord = getHandlers(props, "approveRecord", noop);
    const rejectRecord = getHandlers(props, "rejectRecord", noop);
    const removeReview = getHandlers(props, "removeReview", noop);
    return (
      <ApproversLabel
        {...{
          ...parseApproval({
            record: {
              approval_manifest: getApprovalValue(value)
            },
            userId: userId
          }),
          approveLineItems: approveRecord,
          rejectLineItems: rejectRecord,
          removeApproval: removeReview,
          showMenu: true
        }}
      />
    );
  }
}

export default ApprovalCellFormatter;
