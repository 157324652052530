import React from "react";
import { Div } from "components/Base";
import AvailableFields from "./AvailableFields";
import SelectFieldsShow from "./SelectFieldsShow";

const ChooseOptions = () => (
  <Div display="row">
    <AvailableFields />
    <SelectFieldsShow />
  </Div>
);

export default ChooseOptions;
