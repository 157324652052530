import React from "react";
import PeopleCards from "./PeopleCards";
import Summary from "./Summary";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Body = () => (
  <div styleName="body">
    <PeopleCards />
    <Summary />
  </div>
);

export default CSSModules(css)(Body);
