import PropTypes from "prop-types";
import React from "react";

const tableStyle = {
  borderTop: "1px solid #e2e2e2",
  width: "100%",
  tableLayout: "fixed"
};

const titleStyle = {
  textTransform: "uppercase",
  color: "#5D5D5D",
  fontSize: "9px",
  fontFamily: "arial"
};

const Table = ({ title, children }) => (
  <div>
    <p style={titleStyle}>{title}</p>
    <table style={tableStyle}>
      <tbody>{children}</tbody>
    </table>
  </div>
);
Table.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default Table;
