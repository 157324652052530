export const NAMESPACE = "FuzionIntegration";

export const EXHIBITORS = "exhibitors";
export const BOOTHS = "booths";
export const SPEAKERS = "speakers";

export const TYPES = [
  {
    label: "Exhibitors",
    value: EXHIBITORS
  }
];

export const LABELS = {
  GROUP: "Group",
  PERSON: "Person"
};
