import React from "react";
import * as R from "ramda";
import { DarkTab, LightTab, Div } from "../index";

const Tabs = TabType => ({ tabs, ...props }) => (
  <Div {...props}>
    {R.map(({ label, active, onClick, value, ...tabProps }) => (
      <TabType
        key={value}
        px={2}
        fs={3}
        active={active}
        onClick={onClick}
        {...tabProps}
      >
        {label}
      </TabType>
    ))(tabs)}
  </Div>
);

const DarkTabs = Tabs(DarkTab);
const LightTabs = Tabs(LightTab);

export { DarkTabs, LightTabs };
