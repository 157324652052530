import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { getters } from "../model";
import { FIELDS_IDS, SEND_DATE } from "../constants";
import { getSendingSubtitle } from "../selectors";

import { CalendarIcon, Div, Text1 } from "components/Base";

import { RadioList, DateInput } from "ui-kit/Form/View";
import Container from "./Container";

const decorate = connect(
  (state, props) => ({
    data: getters.data(state, props),
    subtitle: getSendingSubtitle(state, props)
  }),
  null
);

const sendTimeOptions = [
  {
    id: SEND_DATE.NOW,
    label: "Send Now"
  },
  {
    id: SEND_DATE.LATER,
    label: "Send Later"
  }
];

const Sending = ({ data, subtitle }) => (
  <Container
    title="When should this alert be sent"
    subtitle={subtitle}
    Icon={CalendarIcon}
  >
    <RadioList
      fieldId={FIELDS_IDS.DELIVERY.SEND_AT_TYPE}
      options={sendTimeOptions}
      iniValue={R.prop(FIELDS_IDS.DELIVERY.SEND_AT_TYPE, data)}
      ml={3}
      mb={2}
    />
    {R.propOr("", FIELDS_IDS.DELIVERY.SEND_AT_TYPE, data) ===
      SEND_DATE.LATER && (
      <Div display="row.flex-start.center" ml={6} mb={2}>
        <Text1 color="neutral9">Send At:</Text1>
        <DateInput
          ml={2}
          fieldId={FIELDS_IDS.DELIVERY.SEND_AT}
          format="LLL"
          iniValue={R.propOr("", FIELDS_IDS.DELIVERY.SEND_AT, data)}
          showTime
          showCalendar
        />
      </Div>
    )}
  </Container>
);

export default decorate(Sending);
