import * as R from "ramda";
import { createHandlers, createModule } from "redux-mvc";

const iniState = Object.freeze({
  fuzionEventId: null,
  saving: false,
  error: null
});

const handlers = createHandlers({
  iniState,
  namespace: "FuzionConnectModal",
  reducers: {
    init: R.identity,
    createConnection: R.identity,
    setInitialData: (_, { payload: { fuzionEventId } }) => ({
      fuzionEventId
    })
  }
});

const module = createModule(handlers);

const { actions, getters } = module;

export { actions, getters };

export default module;
