import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getters, actions } from "../index";
import {
  getCountOfToggledRows,
  getSearchbarPlaceholder,
  getToggledRows
} from "../selectors";

import { withProps } from "utils/General";

import CSSModules from "react-css-modules";
import css from "ui-kit/ViewPicker/View/styles.scss";

import {
  Div,
  DownloadIcon,
  MediumFilledButton,
  BigFilledButton,
  DownFilledIcon,
  PopMenu,
  PopMenuCard,
  MenuItem,
  AddIcon
} from "components/Base";

import { VARIANTS } from "ui-kit/Theme/constants";
import UISearchBar from "ui-kit/SearchBar/View";
import CanUserDo from "components/Global/Security/CanUserDo";

const decorate = R.compose(
  withRouter,
  connect(
    state => ({
      moduleId: getters.moduleId(state),
      countOfToggledRows: getCountOfToggledRows(state),
      toggledRows: getToggledRows(state),
      placeholder: getSearchbarPlaceholder(state),
      collapsed: getters.collapsed(state)
    }),
    {
      onExport: actions.exportData,
      showAddEventModal: actions.showAddEventModal,
      showDeleteEventsModal: actions.showDeleteEventsModal,
      clearSelectedRows: actions.clearSelectedRows
    }
  )
);

const Button = ({ LeftIcon, RightIcon, children, onClick }) => (
  <MediumFilledButton
    className={css.button1}
    color="white"
    hover={false}
    onClick={onClick}
    mr={2}
    LeftIcon={LeftIcon}
    RightIcon={RightIcon}
  >
    {children}
  </MediumFilledButton>
);

const FilesToolbar = ({
  moduleId,
  countOfToggledRows,
  toggledRows,
  clearSelectedRows,
  showDeleteEventsModal
}) => (
  <Div display="row.flex-start.center">
    <Div fw={4} mr={4} fs={4} color="white">
      {countOfToggledRows} Selected
    </Div>

    <CanUserDo action={`${moduleId}_update`}>
      <CanUserDo action={`${moduleId}_delete`}>
        <Button
          onClick={() => {
            showDeleteEventsModal(toggledRows);
          }}
        >
          Delete
        </Button>
      </CanUserDo>
    </CanUserDo>
    <Div ml={2} fw={4} color="white" onClick={clearSelectedRows}>
      Clear selected
    </Div>
  </Div>
);

const Toolbar = ({
  onExport,
  handlers,
  moduleId,
  countOfToggledRows,
  toggledRows,
  clearSelectedRows,
  placeholder,
  showAddEventModal,
  showDeleteEventsModal
}) => (
  <Div
    display="row.space-between.center"
    width={1}
    height={75}
    px={3}
    style={{ position: "relative", backgroundColor: "#813CD2", flexShrink: 0 }}
  >
    {!countOfToggledRows ? (
      <>
        <Div display="row.flex-start.center" flex={1}>
          <UISearchBar
            variant={VARIANTS.SURFACE}
            flex={1}
            mr={2}
            placeholder={placeholder}
            height={43}
            style={{
              maxWidth: 445
            }}
          />
        </Div>

        <Div display="row.flex-start.center">
          <CanUserDo any={[`${moduleId}_manage`, `${moduleId}_export`]}>
            <PopMenu
              Label={({ onClick }) => (
                <BigFilledButton
                  className={css.button1}
                  hover={false}
                  color="white"
                  RightIcon={withProps({
                    color: "purple4"
                  })(DownFilledIcon)}
                  onClick={onClick}
                >
                  Options
                </BigFilledButton>
              )}
            >
              {({ closeMenu }) => (
                <PopMenuCard position="bottom" align="right" zIndex={20}>
                  <CanUserDo action={`${moduleId}_export`}>
                    <MenuItem
                      LeftIcon={DownloadIcon}
                      onClick={() => {
                        onExport("csv");
                        closeMenu();
                      }}
                    >
                      Export .CSV
                    </MenuItem>
                    <MenuItem
                      LeftIcon={DownloadIcon}
                      onClick={() => {
                        onExport("xlsx");
                        closeMenu();
                      }}
                    >
                      Export .XLSX
                    </MenuItem>
                  </CanUserDo>
                </PopMenuCard>
              )}
            </PopMenu>
          </CanUserDo>

          <CanUserDo action={`${moduleId}_create`}>
            <BigFilledButton
              bg="orange5"
              color="white"
              ml={2}
              onClick={showAddEventModal}
              LeftIcon={AddIcon}
            >
              Upload
            </BigFilledButton>
          </CanUserDo>
        </Div>
      </>
    ) : (
      <FilesToolbar
        {...{
          moduleId,
          countOfToggledRows,
          toggledRows,
          clearSelectedRows,
          showDeleteEventsModal,
          handlers
        }}
      />
    )}
  </Div>
);

export default CSSModules(decorate(Toolbar), css);
