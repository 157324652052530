import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  users: [],
  searchTerm: "",
  showUserInfoModal: false
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    getUsers: R.identity,
    openUserInfoModal: R.identity,
    setInitialData: (_, { payload: { users } }) => ({
      users
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
