import React from "react";
import { Page, Title } from "EventLight/Common/FieldLayout";
import { BOOTH_TYPE_ID } from "utils/item-types";

import Approvers from "EventLight/Common/Items/Approvers/View";

const Layout = () => (
  <>
    <Page>
      <Title
        icon="storefront"
        title="Approvers"
        description="Manage who can approve requests for booths and sponsorships"
      />
      <Approvers typeId={BOOTH_TYPE_ID} />
    </Page>
  </>
);

export default Layout;
