import React, { Component, createRef } from "react";
import { compose } from "ramda";
import {
  BigFilledButton,
  BigShadedBox,
  Div,
  BigInsetInput,
  Text2
} from "components/Base";
import { withState } from "utils/General";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import { BaseHeader } from "components/Global/Modal/Layout/Headers";

import { validateManagerOverride } from "redux/modules/catering/checkin/meal/api";

import { connect } from "react-redux";
import { getCredentials } from "redux/modules/user/selectors";

const decorate = connect(state => ({
  userCredentials: getCredentials(state)
}));

class CateringOverrideModal extends Component {
  constructor(props) {
    super(props);

    this.input = createRef();
  }

  componentDidMount() {
    if (this.input.current) {
      this.input.current.focus();
    }
  }

  assignAndMark = async e => {
    e.preventDefault();
    const isValid = await this.validateManagerCode(this.props.code);
    if (this.props.code && isValid) {
      this.props.onOverride(this.props.note);
      this.props.hideModal();
    } else {
      this.props.setError("Invalid Code");
    }
  };

  validateManagerCode = async code => {
    return await validateManagerOverride(this.props.userCredentials, {
      eventId: this.props.eventId,
      code
    });
  };

  handleCodeChange = e => {
    if (this.props.errorMessage) {
      this.props.setError(undefined);
    }
    return this.props.onCodeChange(e.target.value);
  };

  handleNoteChange = e => {
    return this.props.onNoteChange(e.target.value);
  };

  render() {
    const { hideModal, errorMessage, code, mealName } = this.props;
    return (
      <StyleWrapper
        hideModal={hideModal}
        heading="Manager Meal Assignment (Override)"
        Header={BaseHeader}
        bodyStyles={{ padding: 0 }}
        width={500}
      >
        <Div p={4}>
          <form method="post" onSubmit={this.assignAndMark}>
            <BigShadedBox display="row.center.center">{mealName}</BigShadedBox>
            <Div py={5} display="column.center.center">
              <Text2 bold uppercase pb={1 / 2} color="gray6">
                Manager Code
              </Text2>

              {/* @TODO: Bump out into input so we can focus the ref */}
              <input
                autoFocus
                type="password"
                autoComplete="off"
                onChange={this.handleCodeChange}
                ref={this.input}
                style={{ padding: "10px 7px" }}
              />
            </Div>
            <Div py={5} display="column.center.center">
              <Text2 bold uppercase pb={1 / 2} color="gray6">
                Note / Reason
              </Text2>

              <textarea onChange={this.handleNoteChange} rows={5} />

              <Text2 bold color="danger5">
                {errorMessage}
              </Text2>
            </Div>
            <Div display="row.center.center">
              <BigFilledButton
                bg="warning5"
                disabled={!code}
                onClick={this.assignAndMark}
              >
                Assign + Scan
              </BigFilledButton>
            </Div>
          </form>
        </Div>
      </StyleWrapper>
    );
  }
}

export default compose(
  withState("errorMessage", "setError"),
  withState("code", "onCodeChange"),
  withState("note", "onNoteChange")
)(decorate(CateringOverrideModal));
