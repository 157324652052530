import React, { Component } from "react";
import InternalHandler from "./Internal";

import resolveNewViewName from "components/Event/Module/utils/resolve-new-view-name";

class ExternalHandler extends Component {
  onSortViews = viewOrder => {};

  addView = async ({ name, type, reportId }) => {
    let view;
    const viewName = resolveNewViewName(
      this.props.views,
      name || type,
      reportId
    );
    const activeViewIsReport = this.props.activeView.view_type === "report";
    view = await this.props.createView({
      moduleId: this.props.moduleId,
      name: viewName,
      viewType: type,
      reportId,
      visibleFields: activeViewIsReport
        ? null
        : this.props.activeView.visible_fields,
      fieldOrder: activeViewIsReport ? null : this.props.activeView.field_order,
      fieldWidths: activeViewIsReport
        ? null
        : this.props.activeView.field_widths,
      isPublic: true,
      setAsActive: true,
      accountRecordTypeId: this.props.groupId,
      options: {
        orgId: this.props.orgDetails.id,
        eventId: this.props.eventDetails.id
      }
    });

    await this.props.getViews({
      moduleId: this.props.moduleId,
      options: {
        orgId: this.props.orgDetails.id,
        eventId: this.props.eventDetails.id,
        accountRecordTypeId: this.props.groupId
      }
    });

    this.selectView(view.id);

    this.props.showEditName();
  };

  selectView = viewId => {
    if (viewId === this.props.activeViewId) return;
    this.props.composeClosePopover(() => {
      this.props.selectView(viewId);
    })();
  };

  render() {
    return <InternalHandler {...this.props} {...this} />;
  }
}

export default ExternalHandler;
