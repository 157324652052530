import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Message from "./Message";
import EmptyState from "components/PortalV2/Home/EmptyState";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import OrderModal from "Orders/OrderModal/View";
import { connect } from "react-redux";
import Page from "Portal/Page";

import { getBackgroundImageUrl } from "redux/modules/portal/selectors";
import {
  getMessagesTitle,
  getMessagesDescription
} from "redux/modules/portal/settings/selectors";

const decorate = connect(state => ({
  title: getMessagesTitle(state),
  description: getMessagesDescription(state),
  backgroundImageUrl: getBackgroundImageUrl(state)
}));

@CSSModules(css)
class Messages extends Component {
  componentWillMount() {
    this.getMessages();

    if (this.props.params.submissionId) {
      this.showSubmissionSummary(this.props.params.submissionId);
    }
  }

  getMessages = () => {
    if (this.props.portalUser.active_view.type === "account") {
      this.props.getMessages({
        eventId: this.props.portal.event.id,
        accountId: this.props.portalUser.active_view.id
      });
    } else {
      this.props.getMessages({
        eventId: this.props.portal.event.id
      });
    }
  };

  getCountOfUnreadNotifications = () => {
    /*
    // @NOTE: Hiding until reimplemented
    if (this.props.portalUser.active_view.type === "account") {
      this.props.fetchCountOfUnseenNotifications({
        eventId: this.props.portal.event.id,
        accountId: this.props.portalUser.active_view.id
      });
    } else {
      this.props.fetchCountOfUnseenNotifications({
        eventId: this.props.portal.event.id
      });
    }
    */
  };

  onSubmissionCardClick = ({ id }) => {
    this.props.router.push({
      pathname: `/portals/${this.props.params.portalSlug}/${this.props.params.portalId}/messages/summary/${id}`
    });
    this.showSubmissionSummary(id);
  };

  showSubmissionSummary = submissionId => {
    const modal = (
      <OrderModal
        portal
        hideModal={() => {
          this.props.hideModal();
          this.props.router.push({
            pathname: `/portals/${this.props.params.portalSlug}/${this.props.params.portalId}/messages`
          });
          this.getMessages();
          this.getCountOfUnreadNotifications();
        }}
        eventId={this.props.portal.event.id}
        submissionId={submissionId}
      />
    );
    this.props.showModal({ content: modal, wrapper: ModalWrapper });
  };

  render() {
    const { messages, title, description, backgroundImageUrl } = this.props;

    return (
      <Page
        instanceId="messages"
        title={title}
        description={description}
        backgroundImageUrl={backgroundImageUrl}
      >
        {!messages.length ? (
          <div>
            <EmptyState title="You don't have any messages." />
          </div>
        ) : (
          <div styleName="container">
            <div styleName="title">Inbox</div>
            <p>Any messages sent to you can be found here.</p>
            <div>
              {messages.map(message => (
                <Message
                  key={message.submission.id}
                  {...message}
                  showSubmissionSummary={this.onSubmissionCardClick}
                />
              ))}
            </div>
          </div>
        )}
      </Page>
    );
  }
}

export default decorate(Messages);
