import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";
import { map } from "ramda";

import { getFields } from "redux/modules/modules/fields/actions";
import {
  getRelatedModuleVisibleFields,
  addRelatedModuleVisibleFields,
  removeRelatedModuleVisibleFields,
  updateRelatedModuleVisibleFields
} from "redux/modules/modules/relatedModules/visibleFields/actions";
import { updateVisibleRelatedModule } from "redux/modules/modules/relatedModules/actions";
import { visibleFields } from "redux/modules/modules/relatedModules/visibleFields/selectors";
import { fields } from "redux/modules/modules/fields/selectors";
import { relatedModules } from "redux/modules/modules/layout/selectors";

function mapStateToProps(state, props) {
  const visibleFieldsToPass = visibleFields(
    state,
    props.moduleId,
    props.relatedModuleId
  );
  const relatedModulesToPass = relatedModules(state, props.moduleId);
  const relatedModule = relatedModulesToPass.find(
    r => r.id === props.relatedModuleId
  );

  const fieldsToPass = map(f => {
    const matchingField = visibleFieldsToPass.find(vf => vf.field_id === f.id);
    return {
      ...f,
      order: matchingField ? matchingField.order : null,
      isVisible: Boolean(matchingField)
    };
  })(fields(state, props.relatedModuleId));

  return {
    fields: fieldsToPass,
    sortBy: relatedModule ? relatedModule.sort_by : []
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getFields,
      getRelatedModuleVisibleFields,
      addRelatedModuleVisibleFields,
      removeRelatedModuleVisibleFields,
      updateRelatedModuleVisibleFields,
      updateVisibleRelatedModule
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Controller);
