import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { noop } from "utils/General";

import { actions } from "ui-kit/Form/model";
import { getValue } from "ui-kit/Form/selectors";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";
import { WithThemeConsumer, defaultTh, VARIANTS, os } from "ui-kit/Theme";

import { Div, Text2 } from "components/Base";
import { RadioButton } from "material-ui/RadioButton";

import RadioButtonChecked from "material-ui/svg-icons/toggle/radio-button-checked";

const decorate = R.compose(
  WithFormInstanceConsumer({ fieldType: FIELD_TYPES.RADIO_LIST }),
  WithThemeConsumer({ variant: VARIANTS.SURFACE }),
  connect(
    (state, props) => ({
      value: getValue(state, props)
    }),
    bindInstance({
      onChange: actions.setFieldValue,
      setIniValue: actions.setIniValue
    })
  )
);

export const RadioList = decorate(
  ({
    value = "",
    iniValue = "",
    fieldId,
    fieldType,
    options = [],
    onChange = noop,
    setIniValue = noop,
    Th = defaultTh,
    disabled = false,
    ...styleProps
  }) => {
    useEffect(() => {
      setIniValue(iniValue, {
        meta: { fieldType, fieldId }
      });
    }, []);
    return R.map(
      option => (
        <Div
          display="row.flex-start.center"
          {...os(styleProps)}
          key={option.id}
        >
          <RadioButton
            key={option.id}
            value={option.id}
            checkedIcon={
              <RadioButtonChecked
                {...(styleProps.color
                  ? { style: { fill: styleProps.color } }
                  : {})}
              />
            }
            disabled={disabled}
            checked={R.contains(option.id, value)}
            onClick={
              disabled
                ? undefined
                : () =>
                    onChange(option.id, {
                      meta: { fieldType, fieldId }
                    })
            }
            label=""
            iconStyle={{
              alignSelf: "center",
              marginRight: 8,
              color: styleProps.color
            }}
            inputStyle={{ width: 24 }}
            labelStyle={{ width: 0 }}
            style={{ width: 24 }}
          />
          <Div display="column">
            <Text2
              {...Th(["color"], {
                active: R.contains(option.id, value),
                bold: true,
                color: R.contains(option.id, value)
                  ? styleProps.color
                  : "neutral7"
              })}
              onClick={
                disabled
                  ? undefined
                  : () =>
                      onChange(option.id, {
                        meta: { fieldType, fieldId }
                      })
              }
            >
              {option.label}
            </Text2>
            {option.description ? (
              <Div fs={1} color="gray7">
                {option.description}
              </Div>
            ) : null}
          </Div>
        </Div>
      ),
      options
    );
  }
);
