import React from "react";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";

import Item from "components/Global/CRM/Modals/Assign/Item";
import {
  SmallTextButton,
  BigFilledButton,
  BigOutlineButton,
  CloseIcon,
  Div,
  LoadingIcon,
  MediumOutlineButton,
  SearchIcon,
  SuperClearInput,
  Text1
} from "components/Base";
import RecordCard from "components/Global/CRM/RecordCardList/RecordCard";

const Loading = () => (
  <Div display="column.center.center" height={370} style={{ width: "100%" }}>
    <LoadingIcon size={60} color="neutral4" />
    <Div color="neutral4" mt={6}>
      Loading...
    </Div>
  </Div>
);

const Empty = ({ children }) => (
  <Div
    display="flex.center.center"
    style={{
      backgroundColor: "#EEE"
    }}
    color="gray5"
    bra={1}
    fw={3}
    fs={3}
    p={3}
  >
    {children}
  </Div>
);

const RecordItem = ({ name, onClick, icon, ...props }) => (
  <Div
    onClick={onClick}
    display="row.space-between.center"
    mb={1}
    bra={1}
    py={2}
    px={2}
    {...props}
  >
    <Div flex={1} fs={1} fw={3} color="gray9" truncate>
      {name}
    </Div>
    {icon}
  </Div>
);

const SelectedItem = ({ name, onClick }) => (
  <RecordItem
    name={name}
    onClick={onClick}
    style={{
      backgroundColor: "#FDE7CB"
    }}
    icon={<CloseIcon size={15} color="primary7" />}
  />
);

const Panel = ({ title, children }) => (
  <Div
    py={3}
    px={3}
    bra={1}
    style={{
      backgroundColor: "#FAFAFC"
    }}
  >
    <Div mb={2} fw={3}>
      {title}
    </Div>
    {children}
  </Div>
);

const Sidebar = ({ existing, selected }) => (
  <Div
    height={1}
    width={272}
    px={3}
    py={3}
    bg="white"
    style={{
      overflowY: "auto"
    }}
  >
    <Panel title="Adding...">
      <Div>
        {!selected.length ? (
          <Empty>No records selected</Empty>
        ) : (
          selected.map(s => (
            <SelectedItem key={s.id} name={s.name} onClick={s.onSelect} />
          ))
        )}
      </Div>
    </Panel>

    {existing.length ? (
      <Panel title="Existing Assigned Item Blocks">
        <Div mt={2}>
          {existing.map(item => (
            <Item
              key={item.id}
              name={item.name}
              onClick={item.onSelect}
              selected={item.selected}
              faded={!item.selected}
              style={{
                backgroundColor: item.selected ? "#F5F3F8" : "transparent",
                marginBottom: 3
              }}
              icon={
                Boolean(item.selected) ? (
                  <SmallTextButton OnlyIcon={CloseIcon} />
                ) : (
                  <SmallTextButton underline onClick={item.onSelect}>
                    Undo
                  </SmallTextButton>
                )
              }
            />
          ))}
        </Div>
      </Panel>
    ) : null}
  </Div>
);

const AddBlock = ({ showAddItemBlockModal }) => (
  <Div display="column.center.center" my={5} mb={3}>
    <Div fs={3} mb={3} color="gray7">
      Don't see an item block you were looking for?
    </Div>
    <MediumOutlineButton color="purple9" onClick={showAddItemBlockModal}>
      Create a new item block
    </MediumOutlineButton>
  </Div>
);

const View = ({
  showAddItemBlockModal,
  existing = [],
  hideModal,
  list = [],
  loading,
  onSave,
  onSearchChange,
  selected = []
}) => (
  <StyleWrapper
    bodyStyles={{ padding: 0 }}
    containerStyles={{ overflowY: "hidden", height: 600 }}
    hideHeader
    hideModal={hideModal}
    width={745}
  >
    <Div
      display="row.flex-start.center"
      flex={1}
      bb={1}
      bc="neutral0"
      pl={1}
      pr={3}
      height={67}
    >
      <SuperClearInput
        continuous
        placeholder="Search..."
        onChange={onSearchChange}
        LeftIcon={SearchIcon}
        leftIconColor="gray6"
        flex={1}
      />
      <CloseIcon size={30} onClick={hideModal} />
    </Div>
    <Div
      display="row"
      height={460}
      style={{
        overflowY: "auto",
        maxHeight: "inherit"
      }}
    >
      {loading ? (
        <Loading />
      ) : (
        <Div display="row.flex-start.flex-start">
          <Div
            width={473}
            height={1}
            px={5}
            py={4}
            style={{
              backgroundColor: "#FAF9FC",
              overflowY: "auto"
            }}
          >
            {!list.length ? (
              <Empty>No records matched your search</Empty>
            ) : (
              <Div>
                {list.map(record => (
                  <RecordCard
                    key={record.id}
                    name={record.name}
                    type={record.type}
                    onClick={record.onSelect}
                    selected={record.selected}
                    metaValues={record.fields}
                    selectionMode="multiple"
                  />
                ))}
              </Div>
            )}
            <AddBlock showAddItemBlockModal={showAddItemBlockModal} />
          </Div>
          <Sidebar
            {...{
              selected,
              existing
            }}
          />
        </Div>
      )}
    </Div>
    <Div display="row.flex-start.center" p={5}>
      <BigFilledButton bg="altB5" mr={2} onClick={onSave}>
        Save
      </BigFilledButton>
      <BigOutlineButton onClick={hideModal}>Cancel</BigOutlineButton>
    </Div>
  </StyleWrapper>
);

export default View;
