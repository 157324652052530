import PropTypes from "prop-types";
import React from "react";
import moment from "moment";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

const MealMatrixSelector = ({
  meals,
  days,
  handleChange,
  isMealDaySelected,
  countOfMealDaysSelected
}) => (
  <div styleName="container">
    <div styleName="top">
      <div styleName="title">Catering Days</div>
      <table>
        <thead>
          <tr>
            <th />
            {meals.map(meal => (
              <th key={`meal_header_${meal.id}`}>
                <div styleName="columnHeader">{meal.value}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {days.map((day, dayIdx) => (
            <tr key={`day_${dayIdx}`}>
              <td>
                <div styleName="rowLabel">{moment(day).format("ddd D")}</div>
              </td>
              {meals.map(meal => (
                <td key={meal.id}>
                  <div styleName="input">
                    <input
                      type="checkbox"
                      checked={isMealDaySelected(meal.id, day)}
                      onClick={handleChange.bind(this, meal.id, day)}
                    />
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    <div styleName="bottom">
      <div styleName="mealsAvailable">
        {`${countOfMealDaysSelected} ${
          countOfMealDaysSelected !== 1 ? "meals" : "meal"
        } available`}
      </div>
    </div>
  </div>
);

MealMatrixSelector.propTypes = {
  meals: PropTypes.array.isRequired,
  days: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  isMealDaySelected: PropTypes.func.isRequired,
  countOfMealDaysSelected: PropTypes.number
};

export default CSSModules(MealMatrixSelector, css);
