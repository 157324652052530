export const DATA = {
  page_title: "Add your people",
  page_description: "<p><b>Please</p> add your people below!</p>",

  section_columns: {
    info: [
      {
        id: "actions",
        name: "Actions",
        type: "text",
        settings: {}
      },
      {
        id: "email",
        name: "Email",
        type: "email",
        settings: {}
      },
      {
        id: "title",
        name: "Title",
        type: "text",
        settings: {}
      },
      {
        id: "phone",
        name: "Mobile Phone",
        type: "text",
        settings: {}
      },
      {
        id: "days",
        name: "Days On-Site",
        type: "text",
        settings: {}
      },
      {
        id: "advance",
        name: "Advance Contact",
        type: "text",
        settings: {}
      },
      {
        id: "onsite",
        name: "Onsite Contact",
        type: "text",
        settings: {}
      }
    ],

    radios: [
      {
        id: "actions",
        name: "Actions",
        type: "text",
        settings: {}
      },
      {
        id: "item",
        name: "Item",
        type: "text",
        settings: {}
      },
      {
        id: "quantity",
        name: "Quantity",
        type: "text",
        settings: {}
      },
      {
        id: "details",
        name: "Details",
        type: "text",
        settings: {}
      },
      {
        id: "price",
        name: "Price",
        type: "text",
        settings: {}
      },
      {
        id: "status",
        name: "Status",
        type: "text",
        settings: {}
      },
      {
        id: "paid",
        name: "Paid",
        type: "text",
        settings: {}
      }
    ],

    credentials: [
      {
        id: "actions",
        name: "Actions",
        type: "text",
        settings: {}
      },
      {
        id: "item",
        name: "Item",
        type: "text",
        settings: {}
      },
      {
        id: "quantity",
        name: "Quantity",
        type: "text",
        settings: {}
      },
      {
        id: "details",
        name: "Details",
        type: "text",
        settings: {}
      },
      {
        id: "price",
        name: "Price",
        type: "text",
        settings: {}
      },
      {
        id: "status",
        name: "Status",
        type: "text",
        settings: {}
      },
      {
        id: "paid",
        name: "Paid",
        type: "text",
        settings: {}
      }
    ],

    catering: [
      {
        id: "actions",
        name: "Actions",
        type: "text",
        settings: {}
      },
      {
        id: "item",
        name: "Item",
        type: "text",
        settings: {}
      },
      {
        id: "quantity",
        name: "Quantity",
        type: "text",
        settings: {}
      },
      {
        id: "details",
        name: "Details",
        type: "text",
        settings: {}
      },
      {
        id: "price",
        name: "Price",
        type: "text",
        settings: {}
      },
      {
        id: "status",
        name: "Status",
        type: "text",
        settings: {}
      },
      {
        id: "paid",
        name: "Paid",
        type: "text",
        settings: {}
      }
    ],

    hotels: [
      {
        id: "actions",
        name: "Actions",
        type: "text",
        settings: {}
      },
      {
        id: "requested",
        name: "Requested",
        type: "text",
        settings: {}
      },
      {
        id: "checkin",
        name: "Check In",
        type: "text",
        settings: {}
      },
      {
        id: "checkout",
        name: "Check out",
        type: "text",
        settings: {}
      },
      {
        id: "details",
        name: "Details",
        type: "text",
        settings: {}
      },
      {
        id: "status",
        name: "Status",
        type: "text",
        settings: {}
      },
      {
        id: "assigned",
        name: "Assigned",
        type: "text",
        settings: {}
      },
      {
        id: "price",
        name: "Price",
        type: "text",
        settings: {}
      },
      {
        id: "paid",
        name: "Paid",
        type: "text",
        settings: {}
      }
    ]
  },

  column_widths: {
    info: {},
    radios: {},
    credentials: {},
    catering: {},
    hotels: {}
  },

  people: [
    {
      id: 1,
      name: "Ben Levitt",
      image_url: null,
      sections: [
        {
          id: "info",
          name: "Info",
          rows: [
            {
              id: 1,
              email: {
                type: "email",
                value: "ben@lennd.com"
              },
              phone: {
                type: "text",
                value: "814-232-2323"
              },
              days: {
                type: "text",
                value: "Jan 1 - Jan 20"
              },
              onsite: {
                type: "text",
                value: "ben@lennd.com"
              },
              advance: {
                type: "text",
                value: "ben@lennd.com"
              }
            }
          ]
        },
        {
          id: "radios",
          name: "Radios",
          rows: [
            {
              id: 1,
              item: {
                type: "text",
                value: "Radio"
              },
              quantity: {
                type: "text",
                value: 1
              },
              details: {
                type: "text",
                value: "5 details"
              },
              price: {
                type: "text",
                value: "$20"
              },
              status: {
                type: "text",
                value: "Approved"
              },
              paid: {
                type: "text",
                value: "Yes"
              }
            }
          ]
        },
        {
          id: "credentials",
          name: "Credentials",
          rows: [
            {
              id: 1,
              item: {
                type: "text",
                value: "Meal 1"
              },
              quantity: {
                type: "text",
                value: 1
              },
              details: {
                type: "text",
                value: "5 details"
              },
              price: {
                type: "text",
                value: "$20"
              },
              status: {
                type: "text",
                value: "Approved"
              },
              paid: {
                type: "text",
                value: "Yes"
              }
            },
            {
              id: 2,
              item: {
                type: "text",
                value: "Meal 2"
              },
              quantity: {
                type: "text",
                value: 1
              },
              details: {
                type: "text",
                value: "5 details"
              },
              price: {
                type: "text",
                value: "$20"
              },
              status: {
                type: "text",
                value: "Approved"
              },
              paid: {
                type: "text",
                value: "Yes"
              }
            }
          ]
        },
        {
          id: "hotels",
          name: "Hotels",
          rows: [
            {
              id: 1,
              email: {
                type: "email",
                value: "ben@lennd.com"
              },
              phone: {
                type: "text",
                value: "814-232-2323"
              },
              days: {
                type: "text",
                value: "Jan 1 - Jan 20"
              },
              onsite: {
                type: "text",
                value: "ben@lennd.com"
              },
              advance: {
                type: "text",
                value: "ben@lennd.com"
              }
            }
          ]
        }
      ]
    },
    {
      id: 2,
      name: "Josh Parolin",
      image_url:
        "https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/557057:30711c5f-862c-4fcd-b523-a263a273acc0/349131ce-e919-42e7-9f40-6f958cf8ad14/48",
      sections: [
        {
          id: "info",
          name: "Info",
          rows: [
            {
              id: 1,
              email: {
                type: "email",
                value: "ben@lennd.com"
              },
              phone: {
                type: "text",
                value: "814-232-2323"
              },
              days: {
                type: "text",
                value: "Jan 1 - Jan 20"
              },
              onsite: {
                type: "text",
                value: "ben@lennd.com"
              },
              advance: {
                type: "text",
                value: "ben@lennd.com"
              }
            }
          ]
        },
        {
          id: "radios",
          name: "Radios",
          rows: [
            {
              id: 1,
              item: {
                type: "text",
                value: "Radio"
              },
              quantity: {
                type: "text",
                value: 1
              },
              details: {
                type: "text",
                value: "5 details"
              },
              price: {
                type: "text",
                value: "$20"
              },
              status: {
                type: "text",
                value: "Approved"
              },
              paid: {
                type: "text",
                value: "Yes"
              }
            }
          ]
        },
        {
          id: "credentials",
          name: "Credentials",
          rows: [
            {
              id: 1,
              item: {
                type: "text",
                value: "Meal 1"
              },
              quantity: {
                type: "text",
                value: 1
              },
              details: {
                type: "text",
                value: "5 details"
              },
              price: {
                type: "text",
                value: "$20"
              },
              status: {
                type: "text",
                value: "Approved"
              },
              paid: {
                type: "text",
                value: "Yes"
              }
            },
            {
              id: 2,
              item: {
                type: "text",
                value: "Meal 2"
              },
              quantity: {
                type: "text",
                value: 1
              },
              details: {
                type: "text",
                value: "5 details"
              },
              price: {
                type: "text",
                value: "$20"
              },
              status: {
                type: "text",
                value: "Approved"
              },
              paid: {
                type: "text",
                value: "Yes"
              }
            }
          ]
        },
        {
          id: "hotels",
          name: "Hotels",
          rows: [
            {
              id: 1,
              email: {
                type: "email",
                value: "ben@lennd.com"
              },
              phone: {
                type: "text",
                value: "814-232-2323"
              },
              days: {
                type: "text",
                value: "Jan 1 - Jan 20"
              },
              onsite: {
                type: "text",
                value: "ben@lennd.com"
              },
              advance: {
                type: "text",
                value: "ben@lennd.com"
              }
            }
          ]
        }
      ]
    }
  ],

  pagination: {
    page: 0,
    offset: 10,
    total: 100
  }
};
