import React from "react";
import * as R from "ramda";

import Tooltip from "components/Global/Tooltip";

const TooltipComp = ({ tooltip = null, children, style, ...props }) =>
  !R.isNil(tooltip) ? (
    <Tooltip tooltip={tooltip} {...props}>
      <span
        style={{
          padding: "2px 1px",
          display: "flex",
          cursor: "pointer",
          ...style
        }}
      >
        {children}
      </span>
    </Tooltip>
  ) : (
    <>{children}</>
  );

export default TooltipComp;
