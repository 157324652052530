import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import { noop } from "utils/General";

import { getters, actions } from "../index";

import { getIsViewingMasterSchedule, isMasterSchedule } from "../selectors";

import CanUserDo from "components/Global/Security/CanUserDo";
import {
  Div,
  // UploadIcon,
  DownloadIcon,
  MediumFilledButton,
  DownFilledIcon,
  MenuItem,
  DeleteIcon,
  EditIcon
} from "components/Base";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

import CSSModules from "react-css-modules";
import css from "ui-kit/ViewPicker/View/styles.scss";
import MenuPopover from "ui-kit/MenuPopover";
import DeleteConfirmation from "components/Global/CRM/Popovers/DeleteConfirmation";
const decorate = R.compose(
  connect(
    (state, props) => ({
      moduleId: getters.moduleId(state, props),
      isViewingMasterSchedule: getIsViewingMasterSchedule(state, props),
      isMasterSchedule: isMasterSchedule(state, props)
    }),
    {
      onExport: actions.exportData,
      deleteSchedule: actions.deleteSchedule,
      showEditScheduleModal: () => actions.setIsShowingEditScheduleModal(true)
    }
  ),
  CSSModules(css)
);

const OptionsButton = ({
  isViewingMasterSchedule = false,
  moduleId = "",
  // handlers = {},
  onExport = noop,
  deleteSchedule = noop,
  showEditScheduleModal = noop,
  isMasterSchedule = false,
  wrapperStyle = {},
  ...styleProps
}) => (
  <MenuPopover
    Label={
      <MediumFilledButton
        className={css.button1Light}
        hover={false}
        color="#222"
        RightIcon={DownFilledIcon}
        {...styleProps}
      >
        Options
      </MediumFilledButton>
    }
    wrapperStyle={wrapperStyle}
  >
    {({ closePopover }) => (
      <Div bra={3} bg="white" py={1}>
        {/* {isViewingMasterSchedule ? null : (
          <CanUserDo
            action={
              moduleId === STANDARD_MODULES.accounts.id
                ? `${moduleId}_accounts_add`
                : `${moduleId}_create`
            }
          >
            <MenuItem
              LeftIcon={UploadIcon}
              onClick={() => {
                handlers.showImportModal();
                closePopover();
              }}
            >
              Import .CSV
            </MenuItem>
          </CanUserDo>
        )} */}
        <CanUserDo
          action={`${
            isViewingMasterSchedule ? STANDARD_MODULES.schedules.id : moduleId
          }_export`}
        >
          {isViewingMasterSchedule ? null : (
            <Div width={1} style={{ height: "1px" }} bg="gray3" />
          )}
          <MenuItem
            LeftIcon={DownloadIcon}
            onClick={() => {
              onExport({ contentType: "csv" });
              closePopover();
            }}
          >
            Export .CSV
          </MenuItem>
          <MenuItem
            LeftIcon={DownloadIcon}
            onClick={() => {
              onExport({ contentType: "xlsx" });
              closePopover();
            }}
          >
            Export .XLSX
          </MenuItem>
          {!isMasterSchedule && (
            <MenuItem
              LeftIcon={EditIcon}
              onClick={() => {
                showEditScheduleModal();
                closePopover();
              }}
            >
              Edit Schedule
            </MenuItem>
          )}
        </CanUserDo>
        {isMasterSchedule ? null : (
          <DeleteConfirmation
            onConfirm={() => {
              deleteSchedule(moduleId);
              closePopover();
            }}
            text="Are you sure you want to delete this schedule?"
          >
            <MenuItem LeftIcon={DeleteIcon}>Delete Schedule</MenuItem>
          </DeleteConfirmation>
        )}
      </Div>
    )}
  </MenuPopover>
);

export default decorate(OptionsButton);
