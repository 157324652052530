import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const AuthViewWrapper = ({ children }) => (
  <div styleName="container">
    <div styleName="content">
      <div styleName="logoWrapper">
        <img
          src="https://d2dks4tzxs6xee.cloudfront.net/img/Lennd-white-login.png"
          width="154px"
        />
      </div>
      {children}
    </div>
  </div>
);

AuthViewWrapper.propTypes = {
  children: PropTypes.array
};

export default CSSModules(AuthViewWrapper, css);
