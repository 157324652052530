import React, { Component } from "react";
import * as R from "ramda";
import {
  LeftIcon,
  RightIcon,
  DownIcon,
  Div,
  MediumOutlineButton,
  PopoverMenu
} from "components/Base";

// from https://github.com/deoxxa/paginator/blob/master/index.js
const getPaginatorValues = (
  perPage = 25,
  length = 10,
  _totalResults,
  _currentPage
) => {
  const totalPages = Math.ceil(_totalResults / perPage);

  const totalResults = parseInt(_totalResults, 10);
  let currentPage = parseInt(_currentPage, 10) || 1;

  if (currentPage < 1) {
    currentPage = 1;
  }

  if (currentPage > totalPages) {
    currentPage = totalPages;
  }

  let firstPage = Math.max(1, currentPage - Math.floor(length / 2));
  let lastPage = Math.min(totalPages, currentPage + Math.floor(length / 2));

  // This is triggered if we're at or near one of the extremes; we won't have
  // enough page links. We need to adjust our bounds accordingly.
  if (lastPage - firstPage + 1 < length) {
    if (currentPage < totalPages / 2) {
      lastPage = Math.min(
        totalPages,
        lastPage + (length - (lastPage - firstPage))
      );
    } else {
      firstPage = Math.max(1, firstPage - (length - (lastPage - firstPage)));
    }
  }

  // This can be triggered if the user wants an odd number of pages.
  if (lastPage - firstPage + 1 > length) {
    // We want to move towards whatever extreme we're closest to at the time.
    if (currentPage > totalPages / 2) {
      firstPage += 1;
    } else {
      lastPage -= 1;
    }
  }

  // First result on the page. This, along with the field below, can be used to
  // do "showing x to y of z results" style things.
  let firstResult = perPage * (currentPage - 1);
  if (firstResult < 0) {
    firstResult = 0;
  }

  // Last result on the page.
  let lastResult = perPage * currentPage - 1;
  if (lastResult < 0) {
    lastResult = 0;
  }
  if (lastResult > Math.max(totalResults - 1, 0)) {
    lastResult = Math.max(totalResults - 1, 0);
  }

  return {
    totalPages,
    pages: Math.min(lastPage - firstPage + 1, totalPages),
    currentPage,
    firstPage,
    lastPage,
    previousPage: currentPage - 1,
    nextPage: currentPage + 1,
    hasPreviousPage: currentPage > 1,
    hasNextPage: currentPage < totalPages,
    totalResults,
    results: Math.min(lastResult - firstResult + 1, totalResults),
    firstResult,
    lastResult
  };
};

const Counter = ({
  goTo,
  firstPage,
  currentPage,
  pages,
  lastPage,
  totalPages,
  previousPage,
  nextPage,
  NextButton,
  PreviousButton,
  Ellipses,
  PageButton,
  onClickEllipses
}) => {
  const paginatorArr = [];

  if (currentPage > 1) {
    paginatorArr.push(<PreviousButton onClick={() => goTo(previousPage)} />);
  }
  if (firstPage > 1) {
    paginatorArr.push(
      <PageButton num={1} onClick={() => goTo(1)} isSelected={false} />
    );
  }

  if (firstPage > 2) {
    paginatorArr.push(<Ellipses onClick={onClickEllipses} />);
  }

  R.forEach(page => {
    const thisPage = firstPage + page;
    paginatorArr.push(
      <PageButton
        num={thisPage}
        onClick={() => goTo(thisPage)}
        isSelected={thisPage === currentPage}
      />
    );
  }, R.times(R.identity, pages));

  if (lastPage === totalPages) {
    // don't push anything
  } else if (lastPage === totalPages - 1) {
    paginatorArr.push(
      <PageButton
        num={totalPages}
        onClick={() => goTo(totalPages)}
        isSelected={false}
      />
    );
  } else {
    paginatorArr.push(<Ellipses />);
    paginatorArr.push(
      <PageButton
        num={totalPages}
        onClick={() => goTo(totalPages)}
        isSelected={false}
      />
    );
  }

  if (currentPage < totalPages) {
    paginatorArr.push(<NextButton onClick={() => goTo(nextPage)} />);
  }

  return <Div display="row.center.center">{R.map(i => i, paginatorArr)}</Div>;
};

const makePaginator = ({
  ShowingResultsDisplay,
  PerPageSelector,
  NextButton,
  PreviousButton,
  Ellipses,
  PageButton
}) => ({
  goTo,
  resultsPerPage,
  paginatorLength,
  totalNumResults,
  currentPage,
  selectOptions,
  onSelectResultsPerPage,
  onClickEllipses,
  showingResultsProps,
  showPerPageSelector = true,
  showResultsDisplay = true
}) => {
  const paginatorValues = getPaginatorValues(
    resultsPerPage,
    paginatorLength,
    totalNumResults,
    currentPage
  );
  return (
    <Div display="row.space-between.center">
      {showResultsDisplay ? (
        <ShowingResultsDisplay {...paginatorValues} {...showingResultsProps} />
      ) : null}
      <Counter
        goTo={goTo}
        {...{
          ...paginatorValues,
          NextButton,
          PreviousButton,
          Ellipses,
          PageButton,
          onClickEllipses
        }}
      />
      {showPerPageSelector ? (
        <PerPageSelector
          selectedOption={resultsPerPage}
          options={selectOptions}
          onSelectOption={onSelectResultsPerPage}
        />
      ) : null}
    </Div>
  );
};

// //////////////////////////////////////////////////////////////////////////////

const ShowingResultsDisplay = ({
  totalResults,
  firstResult,
  lastResult,
  label
}) => (
  <Div fs={2}>{`${firstResult + 1} - ${lastResult + 1} of ${totalResults}${
    label ? ` ${label}` : null
  }`}</Div>
);

const PerPageSelector = ({ selectedOption, options, onSelectOption }) => (
  <PopoverMenu
    Label={({ onClick }) => (
      <MediumOutlineButton onClick={onClick} RightIcon={DownIcon}>
        {`Show: ${selectedOption}`}
      </MediumOutlineButton>
    )}
    menuItems={R.map(option => [option, () => onSelectOption(option)], options)}
  />
);

const PageButton = ({ num, onClick, isSelected }) => (
  <Div
    onClick={onClick}
    p={2}
    mx={1}
    bra={1}
    fw={3}
    fs={2}
    bg={isSelected ? "primary5" : "transparent"}
    color={isSelected ? "white" : { default: "neutral7", hover: "neutral5" }}
  >
    {num}
  </Div>
);

// @TODO: add dropdown for enter page selection
const Ellipses = ({ onClick }) => <Div>...</Div>;

const PreviousButton = ({ onClick }) => (
  <MediumOutlineButton onClick={onClick} OnlyIcon={LeftIcon} />
);

const NextButton = ({ onClick }) => (
  <MediumOutlineButton onClick={onClick} OnlyIcon={RightIcon} />
);

const DefaultPaginator = makePaginator({
  ShowingResultsDisplay,
  PerPageSelector,
  NextButton,
  PreviousButton,
  Ellipses,
  PageButton
});

// //////////////////////////////////////////////////////////////////////////////

const paginatorResults = ({ records, resultsPerPage, currentPage = 1 }) => {
  return R.compose(
    R.pathOr([], [currentPage - 1]),
    R.splitEvery(resultsPerPage)
  )(records);
};

// //////////////////////////////////////////////////////////////////////////////

// exported for testing
export const defaultOptions = {
  resultsPerPage: 50,
  currentPage: 1
};

// exported for testing
export class PaginationController extends Component {
  state = {
    resultsPerPage: this.props.resultsPerPage || defaultOptions.resultsPerPage,
    currentPage: this.props.currentPage || defaultOptions.currentPage
  };

  onSelectResultsPerPage = resultsPerPage => this.setState({ resultsPerPage });

  goToPage = currentPage => this.setState({ currentPage });

  render() {
    const { goToPage, onSelectResultsPerPage } = this;
    return (
      <div
        {...this.props}
        {...this.state}
        {...{ goToPage, onSelectResultsPerPage }}
      />
    );
  }
}

const paginatorHandler = function(Comp, options = defaultOptions) {
  const mergeOptions = { ...defaultOptions, ...options };
  return class extends PaginationController {
    state = {
      resultsPerPage: this.props.resultsPerPage || mergeOptions.resultsPerPage,
      currentPage: this.props.currentPage || mergeOptions.currentPage
    };
    render() {
      const { goToPage, onSelectResultsPerPage } = this;
      return (
        <Comp
          {...this.props}
          {...this.state}
          {...{ goToPage, onSelectResultsPerPage }}
        />
      );
    }
  };
};

export { paginatorHandler, paginatorResults, DefaultPaginator as Paginator };
