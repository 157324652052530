import React from "react";
import * as R from "ramda";

import { connect } from "react-redux";
import { Div } from "components/Base";

import { getters } from "../model";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

import GroupFields from "./GroupFields";
import PeopleFields from "./PeopleFields";

const decorate = R.compose(
  connect(state => ({
    types: getters.types(state)
  })),
  CSSModules(css)
);

const Body = ({}) => (
  <div styleName="container">
    <GroupFields />
    <PeopleFields />
  </div>
);

export default decorate(Body);
