import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import PropTypes from "prop-types";
import Checkbox from "rc-checkbox";

const SelectAllHeaderCell = ({ checked, onClick }) => (
  <div styleName="label">
    <Checkbox onChange={onClick} checked={checked} />
  </div>
);

SelectAllHeaderCell.propTypes = {
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool
};

export default CSSModules(SelectAllHeaderCell, css);
