import React from "react";
import { Div } from "components/Base";
import GroupCards from "./GroupCards";
import PeopleCards from "./PeopleCards";

const CustomersResults = ({ onSelectCustomer, handlers }) => (
  <Div pt={4}>
    <GroupCards />
    <PeopleCards onSelectCustomer={onSelectCustomer} />
    <Div
      bra={1}
      bg="neutral0"
      p={4}
      mt={5}
      display="column.flex-start.center"
      textAlign="center"
      height={113}
    >
      <Div>Can't find the account you're looking for?</Div>
      <Div display="row.flex-start.center" mt={4}>
        <Div
          width={148}
          height={38}
          bra={1}
          ba={1}
          bg={{
            default: "neutral0",
            hover: "neutral1"
          }}
          bc="primary7"
          color="primary7"
          display="row.center.center"
          onClick={() => {
            handlers.showAddContactModal({
              isLastStep: false
            });
          }}
        >
          Add New Person
        </Div>
        <Div
          width={148}
          height={38}
          ml={2}
          bra={1}
          ba={1}
          bg={{
            default: "neutral0",
            hover: "neutral1"
          }}
          bc="primary7"
          color="primary7"
          display="row.center.center"
          onClick={() => {
            handlers.showAddAccountModal({
              isLastStep: false
            });
          }}
        >
          Add New Group
        </Div>
      </Div>
    </Div>
  </Div>
);

export default CustomersResults;
