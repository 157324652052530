import {
  put,
  all,
  take,
  takeEvery,
  fork,
  select,
  call
} from "redux-saga/effects";
import * as R from "ramda";

import { TABLE_INSTANCE_ID, FIELD_IDS, FORM_ID } from "./constants";
import { ROW_ACTIONS } from "ui-kit/FieldTypes/constants";

import { eventId as getEventId } from "redux/modules/event/selectors";

import { actions as TableActions } from "ui-kit/Table/model";
import { actions, getters } from "./model";
import { registerError } from "redux/modules/errors/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";

import { getEditedField } from "./selectors";
import { actions as FormActions } from "ui-kit/Form/model";

import { apiCall } from "App/Data/sagas";

const search = function*({ type }) {
  try {
    yield put(actions.setLoading(true));
    const eventId = yield select(getEventId);

    const { payload } = yield call(apiCall, {
      method: "get",
      url: `/tasks/event/${eventId}`
    });

    yield all([
      put(actions.setData(R.propOr([], "rows")(payload))),
      put(
        TableActions.setData(
          {
            canEditCells: false,
            columns: R.prop("columns")(payload),
            rows: R.prop("rows")(payload),
            columnWidths: R.path(["preferences", "columnWidths"], payload)
          },
          {
            meta: {
              instanceId: TABLE_INSTANCE_ID
            }
          }
        )
      )
    ]);

    // on init set empty state
    if (type === actions.init.type && !R.propOr([], "rows")(payload).length) {
      yield put(actions.setShowEmptyState(true));
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting data"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const init = function*(opts) {
  yield put(actions.setShowEmptyState(true));
  yield call(search, opts);
};

const showEditModal = function*({ payload: { row } }) {
  yield put(actions.setDetails(row.data));
  yield put(actions.setShowAddModal(true));
};

const onHideModal = function*({ payload }) {
  if (!payload) {
    yield put(actions.setDetails({}));
    yield put(
      FormActions.clearValues(null, {
        meta: {
          instanceId: FORM_ID
        }
      })
    );
  }
};

const save = function*() {
  try {
    const eventId = yield select(getEventId);
    const data = yield select(getters.details);
    // TODO: maybe we can map the field_ids from data object
    const toSave = {
      name: data.name,
      completionMethod: data.completion_method,
      description: data.description,
      url: data.url
    };

    yield call(apiCall, {
      method: "post",
      url: `/tasks/event/${eventId}`,
      data: toSave
    });

    yield put(showSnackbar({ message: "Task Added" }));
    yield put(actions.setShowAddModal(false));

    yield call(search, { payload: { refresh: true } });
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while creating new task"
        }
      ])
    );
  }
};

const update = function*() {
  try {
    const data = yield select(getters.details);
    const eventId = yield select(getEventId);

    const toSave = {
      name: data.name,
      completionMethod: data.completion_method,
      description: data.description,
      url: data.url
    };

    yield call(apiCall, {
      method: "put",
      url: `/tasks/event/${eventId}/tasks/${data.id}`,
      data: toSave
    });

    yield put(showSnackbar({ message: "Task Updated" }));
    yield put(actions.setShowAddModal(false));

    yield call(search, { payload: { refresh: true } });
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while updating the task"
        }
      ])
    );
  }
};

const deleteItem = function*({ payload: { row } }) {
  const eventId = yield select(getEventId);

  try {
    yield call(apiCall, {
      method: "delete",
      url: `/tasks/event/${eventId}/tasks/${row.id}`
    });

    yield put(showSnackbar({ message: "Task Deleted" }));
    yield call(search, { payload: { refresh: true } });
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while deleting task"
        }
      ])
    );
  }
};

const fieldUpdate = function*({ id, fieldId }) {
  const newValue = yield select(getEditedField, {
    instanceId: id,
    fieldId
  });

  const processedValue = R.propOr(newValue, fieldId, {
    [FIELD_IDS.METHOD]: R.path([0], newValue),
    [FIELD_IDS.NAME]: newValue
  });
  yield put(actions.updateField({ field: fieldId, value: processedValue }));
};

const watchSave = function*() {
  yield takeEvery(actions.save.type, save);
};

const watchUpdate = function*() {
  yield takeEvery(actions.update.type, update);
};

const watchTableActions = function*() {
  for (;;) {
    const action = yield take(TableActions.executeAction.type);
    const delegate = R.prop(action.payload.actionId, {
      [ROW_ACTIONS.EDIT_RECORD]: showEditModal,
      [ROW_ACTIONS.DELETE_RECORD]: deleteItem
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchShowAddContentModal = function*() {
  yield takeEvery(actions.setShowAddModal.type, onHideModal);
};

const watchUpdateFields = function*() {
  for (;;) {
    const {
      meta: { instanceId, fieldId }
    } = yield take(FormActions.setFieldValue.type);
    if (instanceId === FORM_ID) {
      yield call(fieldUpdate, {
        id: instanceId,
        fieldId
      });
    }
  }
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchShowAddContentModal),
    fork(watchTableActions),
    fork(watchUpdateFields),
    fork(watchSave),
    fork(watchUpdate)
  ]);
};

export default rootSaga;
