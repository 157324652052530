import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Option = ({ option }) => (
  <div role="link" tabIndex="0" styleName="container" onClick={option.onClick}>
    <i
      className="material-icons"
      styleName="icon"
      style={{ color: option.iconColor }}
    >
      {option.icon}
    </i>
    <span styleName="name">{option.name}</span>
  </div>
);

Option.propTypes = {
  option: PropTypes.shape({
    onClick: PropTypes.func,
    name: PropTypes.string,
    icon: PropTypes.string,
    iconColor: PropTypes.string
  }).isRequired
};

export default CSSModules(Option, css);
