import PropTypes from "prop-types";
import React, { Component } from "react";
import Menu from "material-ui/Menu";
import MenuItem from "material-ui/MenuItem";

class ActionsButtonMenu extends Component {
  render() {
    const { exportXLSX, exportCSV, wrapClick } = this.props;

    return (
      <Menu>
        <MenuItem
          primaryText="Export as XLSX"
          onClick={wrapClick(exportXLSX)}
        />
        <MenuItem primaryText="Export as CSV" onClick={wrapClick(exportCSV)} />
      </Menu>
    );
  }
}

ActionsButtonMenu.propTypes = {
  exportXLSX: PropTypes.func.isRequired,
  exportCSV: PropTypes.func.isRequired,
  wrapClick: PropTypes.func.isRequired
};

export default ActionsButtonMenu;
