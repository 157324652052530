import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const PrimaryAction = ({ children, ...props }) => (
  <div {...props} styleName="primaryAction">
    {children}
  </div>
);

PrimaryAction.propTypes = {
  children: PropTypes.node.isRequired
};

export default CSSModules(PrimaryAction, css);
