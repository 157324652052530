import React from "react";
import { Div } from "components/Base";
import NewsAndAlerts from "./NewsAndAlerts";
import VideoReplays from "./VideoReplays";
import OverviewAndDetails from "./OverviewAndDetails";
import SocialFeed from "./SocialFeed";
import DetailView from "./DetailView";
import { withRouter } from "react-router";
import { connect } from "react-redux";

import {
  getEnableAlertsSection,
  getEnableSocialSection,
  getEnableVideosSection
} from "../selectors";

const decorator = connect(state => ({
  enableAlertsSection: getEnableAlertsSection(state),
  enableSocialSection: getEnableSocialSection(state),
  enableVideosSection: getEnableVideosSection(state)
}));

class CentralSection extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      const myElement = document.getElementById("pga-top");
      const topPos = myElement.offsetTop;

      if (document.getElementById("pga-wrapper")) {
        document.getElementById("pga-wrapper").scrollTop = topPos;
      }
    }
  }

  render() {
    const {
      location: { pathname },
      style = {},
      params,
      ...otherProps
    } = this.props;

    const isDetailPage =
      (pathname.includes("alerts") && params.alertId) ||
      pathname.includes("overview-and-resources") ||
      pathname.includes("sections") ||
      pathname.includes("people") ||
      pathname.includes("videos")
        ? true
        : false;

    return (
      <Div
        {...otherProps}
        id="pga-top"
        style={{
          ...style,
          backgroundColor: isDetailPage ? "#ffffff !important" : "inherit"
        }}
      >
        {pathname.includes("alerts") ||
        pathname.includes("videos") ||
        pathname.includes("sections") ||
        pathname.includes("people") ||
        pathname.includes("overview-and-resources") ? (
          <DetailView />
        ) : (
          <>
            <OverviewAndDetails
              style={{
                marginBottom: "66px"
              }}
            />
            {this.props.enableAlertsSection ? (
              <NewsAndAlerts
                style={{
                  marginBottom: "76px"
                }}
              />
            ) : null}
            {this.props.enableVideosSection ? (
              <VideoReplays
                style={{
                  marginBottom: "76px"
                }}
              />
            ) : null}
            {this.props.enableSocialSection ? (
              <SocialFeed
                style={{
                  marginBottom: "76px"
                }}
              />
            ) : null}
          </>
        )}
      </Div>
    );
  }
}

export default withRouter(decorator(CentralSection));
