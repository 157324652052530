import getValue from "../get-value/user";
import sortEmpty from "./sort-empty";

export default {
  ASC: sortEmpty(
    "user",
    (a, b) => (getValue(a).records.length > getValue(b).records.length ? 1 : -1)
  ),
  DESC: sortEmpty(
    "user",
    (a, b) => (getValue(b).records.length > getValue(a).records.length ? 1 : -1)
  )
};
