import { createContext } from "redux-mvc";
import { withRouter } from "react-router";
import * as R from "ramda";
import module from "../index";
import { actions } from "../model";
import Layout from "./Layout";

const decorate = createContext({
  module,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(
        actions.init({
          eventId: R.pathOr("", ["params", "eventId"], this.props)
        })
      );
    }
  },
  handlers: {},
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: [
      "user",
      "event",
      "modal",
      "organization",
      "permissions",
      "userAccessLevels",
      "eventUsers"
    ],
    persist: true
  }
});

export default R.compose(
  withRouter,
  decorate
)(Layout);
