import { RECEIVE, REQUEST } from "./constants";
import { ERROR } from "redux/modules/errors/constants";
import { combineReducers } from "redux";

const sources = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE:
      return { ...state, [action.payload.typeId]: action.payload.data };
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case RECEIVE:
    case ERROR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  sources,
  fetching
});
