import React from "react";
import { connect } from "react-redux";
import { Div } from "components/Base";
import Loading from "ui-kit/Loading";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Body from "./Body";
import Footer from "./Footer";
import { getters } from "../index";
import * as R from "ramda";

const decorate = connect(
  state => ({
    loading: getters.loading(state),
    template: getters.template(state)
  }),
  {}
);

const Layout = ({ handlers, hideModal, loading, template }) => (
  <StyleWrapper
    bodyStyles={{ padding: 0 }}
    containerStyles={{ overflowY: "hidden" }}
    heading={
      <Div display="column">
        <span>Edit Report Template</span>
        {!R.isNil(R.prop("module", template)) && (
          <span
            style={{
              fontSize: 13,
              color: "rgba(255, 255, 255, 0.8)"
            }}
          >
            {R.path(["module", "name"], template)}
          </span>
        )}
      </Div>
    }
    hideModal={hideModal}
    width={600}
  >
    {loading ? (
      <Div display="row.center.center" width={1} height={1}>
        <Loading />
      </Div>
    ) : (
      <Body handlers={handlers} />
    )}
    <Footer
      handlers={handlers}
      hideModal={hideModal}
      templateId={R.prop("id", template)}
    />
  </StyleWrapper>
);

export default decorate(Layout);
