import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CardManager from "./CollapseManager";
import AddRequest from "./AddRequest";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { get } from "lodash";

import ButtonAction from "components/Global/Table3/ButtonAction";

import CollapsedView from "./Card";
import EditView from "./Edit";
import { DEFAULT_APPROVAL_FIELD_APPROVE_REJECT } from "components/Event/FormsV2/constants";

@CSSModules(css)
class CardView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCount: 10,
      start: 0
    };
  }

  stopPropagation(event) {
    event.stopPropagation();
  }

  shouldDisableRow = row =>
    ["rejected"].includes(
      get(row.values, `${DEFAULT_APPROVAL_FIELD_APPROVE_REJECT.id}.value`)
    ) ||
    this.props.isPreviewing ||
    this.props.submission.is_locked;

  @autobind
  async addRow() {
    if (!this.props.allowNewRows) {
      return;
    }
    const row = await this.props.addNewRow();
    const newRow = this.getRow(row.id);
    this.setPagination();
  }

  @autobind
  setPagination() {
    this.setState({
      start:
        Math.floor(
          Math.max(this.props.rows.length - 1, 0) / this.state.showCount
        ) * this.state.showCount
    });
  }

  @autobind
  duplicateRow(id, quantity) {
    const row = this.getRow(id);
    this.props.duplicateRow([row.id], quantity || 1);
  }

  @autobind
  nextPage() {
    this.setState({
      start: this.state.start + this.state.showCount
    });
  }

  @autobind
  prevPage() {
    this.setState({
      start: this.state.start - this.state.showCount
    });
  }

  getRow(id) {
    return this.props.rows.find(row => row.id === id);
  }

  showMenu = () => {
    const {
      isFillingFormOut,
      isPreviewing,
      isResponseLocked,
      allowNewRows
    } = this.props;
    return (
      (isFillingFormOut || isPreviewing) && !isResponseLocked && allowNewRows
    );
  };

  isLocked = () => {
    const { allowNewRows, isFillingFormOut, isPreviewing, rows } = this.props;
    return (
      !allowNewRows && (isFillingFormOut || isPreviewing) && rows.length === 1
    );
  };

  renderEntries(rows) {
    const {
      allowNewRows,
      field,
      parentForm,
      form,
      submission,
      fieldsGroupedBySection,
      getRowMetaData,
      invalidRows,
      isPreviewing,
      isResponseLocked,
      showAdminFields,
      scrollParent,
      updateFormValue,
      removeSelectedRows
    } = this.props;
    const { start, showCount } = this.state;

    const filteredFields = fieldsGroupedBySection.filter(
      f => !["section"].includes(f.type)
    );
    return rows.slice(start, start + showCount).map((row, index) => (
      <CardManager
        key={row.id}
        invalidRows={invalidRows}
        defaultCollapsed={
          index !== 0 - start || rows.length > showCount // when default rows are greater than one and cannot add new rows, then expand first and collapse the rest // anytime there is pagination, collapse by default
        }
        rows={rows}
        isPreviewing={isPreviewing}
        index={index + start}
        locked={this.isLocked()}
        submission={submission}
        row={row}
        field={field}
        collapsedElement={
          <CollapsedView
            duplicateRow={this.duplicateRow}
            filteredFields={filteredFields}
            getRowMetaData={getRowMetaData}
            index={index + start + 1}
            invalidRows={invalidRows}
            removeSelectedRows={removeSelectedRows}
            row={row}
            showMenu={this.showMenu()}
            submission={submission}
          />
        }
        expandedElement={
          <EditView
            duplicateRow={this.duplicateRow}
            index={index + start + 1}
            isResponseLocked={this.shouldDisableRow(row)}
            locked={this.isLocked()}
            removeSelectedRows={removeSelectedRows}
            showMenu={this.showMenu()}
            {...{
              allowNewRows,
              field,
              fieldsGroupedBySection,
              filteredFields,
              form,
              getRowMetaData,
              invalidRows,
              isPreviewing,
              isResponseLocked,
              parentForm,
              row,
              rows,
              scrollParent,
              showAdminFields,
              submission,
              updateFormValue
            }}
          />
        }
      />
    ));
  }

  renderPagination(rowsToShow) {
    const { styles } = this.props;
    if (
      rowsToShow.length <= this.state.start &&
      rowsToShow.length >= this.state.showCount
    ) {
      return this.setPagination();
    }
    return rowsToShow.length > this.state.showCount ? (
      <div styleName="pagination">
        {this.state.start > 0 ? (
          <ButtonAction
            className={css.actionButton}
            type="back"
            icon={
              <i className={["material-icons", css.buttonIcon].join(" ")}>
                keyboard_arrow_left
              </i>
            }
            label={<span className={css.buttonTitle}>Back</span>}
            action={this.prevPage}
          />
        ) : (
          ""
        )}
        {rowsToShow.length > this.state.start + this.state.showCount ? (
          <ButtonAction
            className={css.actionButton}
            type="next"
            icon={
              <i className={["material-icons", css.buttonIcon].join(" ")}>
                keyboard_arrow_right
              </i>
            }
            label={<span className={css.buttonTitle}>Next</span>}
            action={this.nextPage}
          />
        ) : (
          ""
        )}
      </div>
    ) : (
      ""
    );
  }

  render() {
    const {
      rows,
      allowNewRows,
      isPreviewing,
      isFillingFormOut,
      isResponseLocked
    } = this.props;
    const rowsToShow = rows;
    return (
      <div>
        {this.renderPagination(rowsToShow)}
        {this.renderEntries(rowsToShow)}
        {(allowNewRows && isFillingFormOut && !isResponseLocked) ||
        (isPreviewing && allowNewRows) ? (
          <div styleName="toolbar">
            <AddRequest action={isPreviewing ? "" : this.addRow} />
          </div>
        ) : (
          ""
        )}
        {this.renderPagination(rowsToShow)}
      </div>
    );
  }
}

CardView.propTypes = {
  addNewRow: PropTypes.func.isRequired,
  allowNewRows: PropTypes.bool.isRequired,
  duplicateRow: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  fieldsGroupedBySection: PropTypes.array.isRequired,
  form: PropTypes.object.isRequired,
  formId: PropTypes.string.isRequired,
  getRowMetaData: PropTypes.func.isRequired,
  headers: PropTypes.array.isRequired,
  invalidRows: PropTypes.array.isRequired,
  isFillingFormOut: PropTypes.bool.isRequired,
  isPreviewing: PropTypes.bool,
  isResponseLocked: PropTypes.bool.isRequired,
  removeSelectedRows: PropTypes.func.isRequired,
  rows: PropTypes.array.isRequired,
  scrollParent: PropTypes.object.isRequired,
  showAdminFields: PropTypes.bool.isRequired,
  styles: PropTypes.object,
  updateFormValue: PropTypes.func.isRequired,
  submission: PropTypes.object
};

export default CardView;
