import {
  put,
  call,
  takeEvery,
  all,
  fork,
  select,
  take,
  cancel
} from "redux-saga/effects";
import * as R from "ramda";
import { defaultInstanceId } from "redux-mvc";

import { getSelectedView } from "../selectors";
import { actions, getters } from "EventLight/ProductionSchedules/Schedule";
import { actions as DataActions } from "App/Data/model";

import { actions as TableActions } from "ui-kit/Table/model";
import { actions as ViewPickerActions } from "ui-kit/ViewPicker";
import { actions as TimeTableActions } from "ui-kit/TimeTable/model";
import { actions as TabsActions } from "ui-kit/Tabs";
import {
  actions as SearchBarActions,
  getters as SearchBarGetters
} from "ui-kit/SearchBar";
import { actions as filterControlsActions } from "ui-kit/FilterControls";
import { actions as ActivityDetailsActions } from "Schedules/ActivityDetailsSidebar/model";

import { getCredentials } from "redux/modules/user/selectors";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";
import { registerError } from "redux/modules/errors/actions";

import {
  TABLE_VIEW_ID,
  TABLE_INSTANCE_ID,
  VIEWPICKER_INSTANCE_ID,
  SCHEDULES_FILTER_INSTANCE_ID,
  SCHEDULE_INSTANCE_ID,
  SCHEDULE_VIEW_IDS
} from "../constants";
import { GROUP_BY_COLUMN_ID } from "ui-kit/Table/constants";

import { navigateTo } from "utils/General";
import { mapResources } from "../utils";

import Api from "../api";

export const loading = function*(scrollTop = true) {
  yield put(actions.setLoading(true));
  if (scrollTop) {
    const elm = yield call([document, document.getElementById], TABLE_VIEW_ID);
    if (elm) {
      elm.scrollTop = 0;
    }

    const elms = yield call(
      [document, document.getElementsByClassName],
      TABLE_VIEW_ID
    );
    Array.prototype.forEach.call(elms, elm => {
      elm.scrollTop = 0;
    });
  }
  yield take(actions.receiveList.type);
  yield put(actions.setLoading(false));
};

const getSearchParams = state => {
  const preferences = getters.preferences(state);

  return {
    moduleId: getters.moduleId(state),
    search: SearchBarGetters.searchTerm(state),
    preferences: JSON.stringify(preferences)
  };
};

const exportData = function*({ payload: { contentType = "csv", recordIds } }) {
  const credentials = yield select(getCredentials);
  const state = yield select(R.identity);
  const eventIdToUse = yield select(getEventId);
  const orgIdToUse = yield select(getOrgId);

  const data = getSearchParams(state);

  yield fork(loading);
  try {
    const result = yield call(Api.export, {
      ...data,
      eventId: eventIdToUse,
      orgId: orgIdToUse,
      credentials,
      contentType,
      recordIds
    });
    yield all([
      call(navigateTo, result.payload.url),
      put(actions.setLoading(false))
    ]);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred exporting data"
          }
        ])
      ),
      put(actions.setLoading(false, {}, true))
    ]);
  }
};

const watchExport = function*() {
  yield takeEvery(actions.exportData.type, exportData);
};

export const search = function*({ type }) {
  yield put(actions.setShowNoResults(false));
  const credentials = yield select(getCredentials);
  const userId = yield select(getUserId);
  const eventId = yield select(getEventId);
  const orgId = yield select(getOrgId);
  const state = yield select(R.identity);

  const data = getSearchParams(state, type);

  yield fork(loading);
  try {
    const result = yield call(
      Api.search,
      credentials,
      userId,
      eventId,
      orgId,
      data
    );

    const selectedView = yield select(getSelectedView);
    if (SCHEDULE_VIEW_IDS.includes(selectedView)) {
      yield all([
        put(actions.setResourceOptions(result.payload.resource_fields)),
        put(
          TimeTableActions.setResources(mapResources(result.payload.records), {
            meta: { instanceId: SCHEDULE_INSTANCE_ID }
          })
        )
      ]);
    } else {
      const isGroupedBy = R.compose(
        R.not,
        R.isNil,
        R.prop("grouped_by")
      )(result.payload);

      const rows = isGroupedBy // fake group by column
        ? R.flatten(R.map(R.prop("records"), result.payload.records))
        : result.payload.records;

      const groupByIndex = isGroupedBy
        ? {
            [GROUP_BY_COLUMN_ID]: R.map(
              group => ({
                id: group.title,
                list: R.map(R.prop("id"), group.records),
                ...group
              }),
              result.payload.records
            )
          }
        : {};
      yield put(
        TableActions.setData(
          {
            columns: result.payload.fields,
            rows,
            columnWidths: result.payload.preferences.field_widths,
            activeIndex: isGroupedBy ? GROUP_BY_COLUMN_ID : "",
            groupByIndex
          },
          {
            meta: {
              instanceId: TABLE_INSTANCE_ID
            }
          }
        )
      );
    }

    yield all([
      put(DataActions.addReferences(result.payload.references)),
      put(
        ViewPickerActions.setActiveView(
          {
            activeViewId: result.payload.preferences.id,
            activeViewName: result.payload.preferences.name
          },
          {
            meta: {
              instanceId: VIEWPICKER_INSTANCE_ID
            }
          }
        )
      ),
      put(
        filterControlsActions.updateSelectedOptions(
          R.map(id => ({ id }), result.payload.preferences.quick_filters || []),
          {
            meta: {
              instanceId: SCHEDULES_FILTER_INSTANCE_ID
            }
          }
        )
      ),
      put(actions.receiveList(result.payload))
    ]);
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred fetching data"
          }
        ])
      ),
      put(actions.setLoading(false, {}, true))
    ]);
  }
};

const watchSearch = function*() {
  yield takeEvery(
    action =>
      action.type === SearchBarActions.setSearchTerm.type &&
      R.path(["meta", "instanceId"], action) === defaultInstanceId,
    search
  );
};

const watchClearSearch = function*() {
  for (;;) {
    const action = yield take(SearchBarActions.clearSearch.type);
    if (R.isNil(R.path(["meta", "instanceId"], action))) {
      yield call(search, action);
    }
  }
};

const setPreferencesAndSearch = function*(action) {
  const { payload } = action;
  const preferences = yield select(getters.preferences);

  yield put(
    actions.setPreferences({ ...preferences, resource_field_id: payload })
  );
  yield call(search, action);
};

const watch = function*() {
  let searchTask;
  for (;;) {
    const action = yield take([
      actions.setGroupedByField.type,
      actions.setSelectedFieldFilters.type,
      actions.setSelectedSortBy.type,
      actions.removeSelectedSortBy.type,
      actions.clearFilters.type,
      actions.removeSelectedFieldFilter.type,
      actions.fetchData.type,
      actions.setSelectedView.type,
      TableActions.refreshRecords.type,
      TabsActions.setSelectedTab.type,
      ActivityDetailsActions.refreshData.type
    ]);

    if (searchTask) {
      yield cancel(searchTask);
    }

    if (
      action.type === TabsActions.setSelectedTab.type &&
      R.path(["meta", "instanceId"], action) === SCHEDULE_INSTANCE_ID
    ) {
      searchTask = yield fork(setPreferencesAndSearch, action);
    } else {
      searchTask = yield fork(search, action);
    }
  }
};

const rootSaga = function*() {
  yield all([
    fork(watch),
    fork(watchSearch),
    fork(watchClearSearch),
    fork(watchExport)
  ]);
};

export default rootSaga;
