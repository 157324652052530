import React from "react";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

const COLOR = STANDARD_MODULE_IDS.credentials.color;

import { Div, AssignmentIcon } from "components/Base";
import CredentialRequestCard from "Accounts/RequestsAndOrders/View";
import { TabValues } from "Accounts/RequestsAndOrders/constants";
import { addS } from "utils/General";

const getReviewFormatting = ({
  type,
  count,
  shareAssignmentManagerLink,
  goToOrdersTab
}) => {
  switch (type) {
    case "need_assignment":
      return {
        frontText: `${count} item${addS(count)}`,
        backText: `still require${count === 1 ? "s" : ""} name assignment`,
        buttons: [
          {
            text: "Share Link",
            onClick: shareAssignmentManagerLink
          }
          /*
          // @NOTE: Temp hide until we have this
          {
            text: "Add Names",
            onClick: goToOrdersTab
          }
          */
        ]
      };
    case "review":
      return {
        frontText: `${count} request${addS(count)}`,
        backText: "need to be reviewed",
        buttons: [
          {
            text: "View Orders",
            onClick: goToOrdersTab
          }
        ]
      };
    default:
      return {};
  }
};

const View = ({
  loading,
  isFetching,
  showCredentialOrderModal,
  shareAssignmentManagerLink,
  summary,
  orders,
  showGroupNameColumn,
  reportTableData,
  refetchData,
  recordId,
  hasContactId
}) => {
  return (
    <Div>
      <CredentialRequestCard
        moduleId={STANDARD_MODULE_IDS.accounts.id}
        recordId={recordId}
        hasContactId={hasContactId}
        refetchData={refetchData}
        loading={loading || isFetching}
        defaultTab={TabValues.OVERVIEW}
        headerIconColor={COLOR}
        HeaderIcon={AssignmentIcon}
        onClickHeaderButton={showCredentialOrderModal}
        rowContents={summary.review.map(({ type, count }) => ({
          ...getReviewFormatting({
            type,
            count,
            shareAssignmentManagerLink
          }),
          rowColor: "primary5"
        }))}
        numOrders={summary.count_of_orders}
        reportTableData={reportTableData}
        showGroupNameColumn={showGroupNameColumn}
        allOrders={orders}
      />
    </Div>
  );
};

export default View;
