import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { Link } from "react-router";
import { Div, LeftIcon } from "components/Base";

import CanUserDo from "components/Global/Security/CanUserDo";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

@CSSModules(css)
class Header extends Component {
  render() {
    const { details, titleAction, titleActionLabel } = this.props;
    return (
      <div styleName="container">
        <div styleName="titlePanel">
          <Div display="column">
            <Div
              onClick={titleAction}
              color="primary3"
              fs={2}
              underline
              display="row.flex-start.center"
              mb={1}
            >
              <LeftIcon color="primary3" size={15} />
              {titleActionLabel}
            </Div>
            <div styleName="name">{details.name}</div>
          </Div>
          <div styleName="separator" />
          <div styleName="options">
            <CanUserDo action={`${STANDARD_MODULE_IDS.settings.id}_read`}>
              <Link to={`/event/${details.id}/settings`} styleName="settings">
                <i className="material-icons" styleName="settingsIcon">
                  settings
                </i>
                <span>Settings</span>
              </Link>
            </CanUserDo>
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  details: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  fetchActivities: PropTypes.func.isRequired,
  userPermissionProfile: PropTypes.shape({
    id: PropTypes.string,
    permission: PropTypes.array,
    permissionList: PropTypes.array
  }).isRequired
};

export default Header;
