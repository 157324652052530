import { createContext } from "redux-mvc";
import * as R from "ramda";
import module from "../index";
import { actions } from "../model";
import Layout from "Organizations/CloneEventModal/View/Layout";

const decorate = createContext({
  module,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(
        actions.init({
          organization: this.props.organization,
          isLenndAdmin: this.props.isLenndAdmin,
          eventIdToClone: this.props.eventIdToClone,
          startDate: this.props.startDate,
          endDate: this.props.endDate,
          canClone: this.props.canClone
        })
      );
    }
  },
  handlers: {},
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: [
      "user",
      "event",
      "events",
      "modal",
      "organization",
      "permissions",
      "userAccessLevels",
      "@flopflip"
    ],
    persist: true
  }
});

export default decorate(Layout);
