import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CollapsablePanel from "components/Global/CRM/CollapsablePanel";
import Tooltip from "components/Global/CRM/Tooltip";
import Contact from "./Contact";
import getEmail from "./utils/get-contact-email";
import getFirstName from "./utils/get-contact-first-name";
import getLastName from "./utils/get-contact-last-name";
import getPhone from "./utils/get-contact-phone";
import { sortBy } from "lodash";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class PrimaryContacts extends Component {
  @autobind
  removePrimary(data) {
    this.props
      .updateRelationship({
        ...data,
        orgId: this.props.orgId,
        eventId: this.props.eventId
      })
      .then(() => this.props.getAccount());
  }

  render() {
    const { contacts, basePath, accountId, readOnly } = this.props;
    const list = sortBy(contacts, c => getFirstName(c.values)).map(c => (
      <Contact
        key={c.relationship_id}
        basePath={basePath}
        accountId={accountId}
        onRemove={this.removePrimary}
        email={getEmail(c.values)}
        fname={getFirstName(c.values)}
        lname={getLastName(c.values)}
        phone={getPhone(c.values)}
        readOnly={readOnly}
        {...c}
      />
    ));

    return (
      <CollapsablePanel
        title={
          <div className={css.primaryContacts}>
            <i className="material-icons star">star</i> Primary Contacts
            <div className={css.tooltip}>
              <Tooltip
                message="Primary contacts are the go-to people on this group"
                placement="bottom"
              />
            </div>
          </div>
        }
      >
        <div>
          <div>{list}</div>
        </div>
      </CollapsablePanel>
    );
  }
}

PrimaryContacts.propTypes = {
  contacts: PropTypes.array.isRequired,
  eventId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  getAccount: PropTypes.func.isRequired,
  updateRelationship: PropTypes.func.isRequired
};

export default PrimaryContacts;
