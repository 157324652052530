import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import Checkbox from "material-ui/Checkbox";
import { Div, OpenInNewIcon, FontIcon, Text1 } from "components/Base";
import FileIcon from "ui-kit/FileIcon";

import { actions } from "../../model";
import { getValue } from "../../utils";
import { ROW_ACTIONS } from "../../constants";

const decorate = connect(
  null,
  bindInstance({
    execute: actions.executeAction
  })
);

const TreeNode = ({ data, field, execute }) => {
  const value = getValue({ data, field });
  const { type: nodeType, name, level, isExpanded, isChecked } = value;
  const folderColor = R.propOr("#70A3F1", R.prop("folder_type", value), {
    document_request: "primary8",
    file_field: "yellow7"
  });
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        paddingLeft: level * 16
      }}
    >
      <Div
        display="row.center.center"
        color="gray5"
        onClick={ev => {
          ev.preventDefault();
          execute({
            row: data,
            column: field,
            actionId: ROW_ACTIONS.TOGGLE_CHECKBOX_ROW
          });
        }}
      >
        <Checkbox
          checked={isChecked}
          style={{ width: "auto" }}
          iconStyle={{
            alignSelf: "center",
            marginRight: 6
          }}
        />
      </Div>
      <Div display="row.center.center">
        {nodeType === "folder" ? (
          <Div
            display="row.center.center"
            onClick={() =>
              execute({
                row: data,
                column: field,
                actionId: ROW_ACTIONS.TOGGLE_FOLDER_ROW
              })
            }
            color={{
              hover: "primary9",
              default: "gray6"
            }}
          >
            <FontIcon
              style={{
                transform: `rotate(${isExpanded ? 90 : 0}deg)`,
                color: "inherit"
              }}
            >
              arrow_right
            </FontIcon>
          </Div>
        ) : (
          <Div
            display="row.center.center"
            onClick={() =>
              execute({
                row: data,
                column: field,
                actionId: ROW_ACTIONS.OPEN_RECORD
              })
            }
            color={{
              hover: "primary9",
              default: "gray5"
            }}
          >
            <OpenInNewIcon
              style={{
                color: "inherit"
              }}
            />
          </Div>
        )}
        <Div
          display="row.center.center"
          style={{ fontWeight: nodeType === "folder" ? 600 : "normal" }}
        >
          {nodeType === "folder" ? (
            <FontIcon
              ml={1}
              color={nodeType === "folder" ? folderColor : "red6"}
            >
              folder
            </FontIcon>
          ) : (
            <FileIcon ml={2} size={16} mimetype={R.prop("mimetype", value)} />
          )}
          <Text1 ml={2} bold={nodeType === "folder"}>
            {name}
          </Text1>
        </Div>
      </Div>
    </div>
  );
};

export default decorate(TreeNode);
