import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as R from "ramda";
import { Div, InverseGridIcon } from "components/Base";
import { getApps } from "Organizations/Home/selectors";
import { LargeMenuIcon } from "components/Global/NavIcons";
import Circle from "ui-kit/Circle";

const decorate = connect((state, props) => ({
  apps: getApps(state, props)
}));

const AppsContainer = ({ apps, params }) => {
  return (
    <Div
      p={3}
      bra={1}
      bg="white"
      style={{
        boxShadow:
          "0px 8px 20px rgba(0, 0, 0, 0.04), 0px 35px 54px rgba(0, 0, 0, 0.01)"
      }}
    >
      <Div display="row.flex-start.center" mb={2}>
        <Circle size={40} bg="neutral1">
          <InverseGridIcon />
        </Circle>
        <Div ml={2} fs={5} fw={4} color="gray7" mr={5}>
          Apps
        </Div>
      </Div>
      <Div
        display="row"
        style={{ flexWrap: "wrap", width: "270px", margin: "0 auto" }}
        className="org-home-icons"
      >
        {R.map(app => {
          return (
            <LargeMenuIcon
              key={app.id}
              module={app}
              eventDetails={{
                id: 0
              }}
              params={params}
              showLink
              showLabel
            />
          );
        }, apps)}
      </Div>
    </Div>
  );
};

export default R.compose(
  withRouter,
  decorate
)(AppsContainer);
