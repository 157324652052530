import { createModule } from "redux-mvc";

import filterControls from "ui-kit/FilterControls";
import searchBar from "ui-kit/SearchBar";
import table from "ui-kit/Table";

import model from "./model";
import rootSaga from "./sagas";

const module = createModule({
  ...model
});

module.plugModule(filterControls);
module.plugModule(searchBar);
module.plugModule(table);

module.setRootSaga(rootSaga);

export default module;
