import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  recordTypeId: null,
  data: {},
  collapsableIds: {},
  selectedTabs: {},
  pagination: {
    page: 1,
    offset: 10,
    total: 0
  },
  selectedPeopleIds: []
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    download: R.identity,
    showOpenEmailsModal: R.identity,
    showAddStaffModal: R.identity,
    toggleCollapsibleClick: R.identity,
    duplicatePerson: R.identity,
    removePerson: R.identity,
    selectSectionTab: R.identity,
    handleSelectedPeopleIds: R.identity,
    showAddPersonModal: R.identity,
    refetchData: R.identity,
    submitRequest: R.identity,
    setInitialData: (_, { payload: { data } }) => ({
      data,
      collapsableIds: R.compose(
        R.reduce(
          (acc, person) => ({
            ...acc,
            [person.id]: false
          }),
          {}
        ),
        R.propOr([], "people")
      )(data),
      selectedTabs: R.compose(
        R.reduce(
          (acc, person) => ({
            ...acc,
            [person.id]: R.pathOr("", ["sections", 0, "id"], person)
          }),
          {}
        ),
        R.propOr([], "people")
      )(data)
    }),
    toggleCollapsibleId: (state, { payload: id }) => ({
      collapsableIds: R.assoc(
        id,
        !R.propOr(true, id, state.collapsableIds),
        state.collapsableIds
      )
    }),
    updateSelectedTab: (state, { payload: { personId, sectionId } }) => ({
      selectedTabs: R.assoc(personId, sectionId, state.selectedTabs)
    }),
    toggleSelectedPeopleIds: (state, { payload: { id, selected } }) => ({
      selectedPeopleIds: selected
        ? R.filter(personId => personId !== id, state.selectedPeopleIds)
        : [...state.selectedPeopleIds, id]
    }),
    clearSelectedPeopleIds: () => ({
      selectedPeopleIds: []
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
