import * as R from "ramda";

import { createHandlers } from "redux-mvc";
import moment from "moment";

import { NAMESPACE, CALENDAR_VIEW } from "./constants";

const iniState = {
  currentDate: "",
  currentMonthDate: "",
  today: "",
  resources: [],
  updatedEvents: {},
  resizing: {},
  selectedRange: CALENDAR_VIEW.MONTH,
  timezone: ""
};

const addMap = {
  [CALENDAR_VIEW.DAY]: "d",
  [CALENDAR_VIEW.RESOURCE]: "d",
  [CALENDAR_VIEW.MONTH]: "M",
  [CALENDAR_VIEW.WEEK]: "w"
};

const reducers = {
  init: (
    { currentDate, currentMonthDate },
    { payload: { today, selectedRange, timezone } }
  ) => ({
    currentDate: currentDate || today,
    currentMonthDate: currentMonthDate || today,
    today,
    selectedRange,
    timezone
  }),
  setEventDate: (state, { payload: currentDate }) =>
    currentDate.isValid()
      ? {
          currentDate,
          currentMonthDate: currentDate
        }
      : state,
  next: ({ currentDate, selectedRange }) => ({
    currentDate: moment(currentDate).add(
      1,
      R.propOr("d", selectedRange, addMap)
    )
  }),
  prev: ({ currentDate, selectedRange }) => ({
    currentDate: moment(currentDate).subtract(
      1,
      R.propOr("d", selectedRange, addMap)
    )
  }),
  nextMonth: ({ currentMonthDate }) => ({
    currentMonthDate: moment(currentMonthDate).add(1, "M")
  }),
  prevMonth: ({ currentMonthDate }) => ({
    currentMonthDate: moment(currentMonthDate).subtract(1, "M")
  }),
  jump: (_, { payload: currentDate }) => ({
    currentDate,
    currentMonthDate: currentDate
  }),
  addUpdatedEvent: ({ updatedEvents }, { payload: newEvent }) => ({
    updatedEvents: {
      ...updatedEvents,
      [newEvent.id]: newEvent
    }
  }),
  eventCreate: R.identity,
  eventEdit: R.identity,
  eventResizeStart: R.identity,
  eventResizeRequest: R.identity,
  eventResizeResponse: R.identity,
  eventDragStart: R.identity,
  eventDropRequest: R.identity,
  eventDropResponse: R.identity
};

const model = createHandlers({ iniState, reducers, namespace: NAMESPACE });

const { actions, getters } = model;

export { actions, getters };

export default model;
