import React from "react";
import { connect } from "react-redux";
import { noop } from "utils/General";

import { Div } from "components/Base";

import PaginationBar from "components/Global/ReactTable/Pagination/General";

import { getters, actions } from "SendEmailModal";

const decorate = connect(
  (state, props) => ({
    pagination: getters.pagination(state, props)
  }),
  {
    onPageSizeChange: actions.setPageSize,
    onPageChange: actions.setCurrentPage
  }
);

const BottomPagination = ({
  pagination,
  onPageSizeChange = noop,
  onPageChange = noop
}) => (
  <Div bg="white" bt={1} bc="neutral3" width={1} mt={2}>
    <PaginationBar
      {...{
        ...pagination,
        onPageSizeChange,
        onPageChange,
        showPageSizeOptions: false,
        pageSizeOptions: [10],
        showPageJump: false,
        showLocation: false,
        canPrevious: pagination.page > 0,
        canNext: pagination.page < pagination.pages - 1,
        total: pagination.total,
        rowsText: "",
        style: {
          marginRight: 0,
          padding: "10px 0 0 0"
        }
      }}
    />
  </Div>
);

export default decorate(BottomPagination);
