import formCloseDateHasExpired from "components/Event/FormsV2/Utils/form-close-date-has-expired";

export default (form, isLocked) => {
  /*
    The check for `undefined` needs to be done because this function
    is used in so many places omitting the second argument `isLocked`
    that is hard to identify and refactor it properly.

    There are two situations identified were this function is used:
      - To check if a form is locked or not. If that's the case, the
        `isLocked` argument is NOT passed, so the logic to determine
        the truthiness relies on form attributes, expected as the first
        argument `form`.
      - To check if a *submission* is locked or not. If that's the case,
        the `isLocked` argument is expected as boolean, and the truthiness
        of this argument should determine the return the function, since
        the submission locked state must prevail over its own form lock
        or dates.
  */
  const wasLockedSubmissionFlagPassed = isLocked !== undefined;

  /*
    If `isLocked` is present, i.e. not undefined, the *submission*
    `is_locked` must have the prevalence over other attributes.
  */
  if (wasLockedSubmissionFlagPassed) {
    if (isLocked === true) {
      return true;
    }
    if (isLocked === false) {
      return false;
    }
  }

  // If a custom close date has been specified, use it to deterime the lock
  if (form.custom_close_date) {
    return formCloseDateHasExpired(form.custom_close_date);
  }

  // If a close date has been specified, use it to deterime the lock
  if (form.close_date) {
    return formCloseDateHasExpired(form.close_date);
  }

  return form.is_locked;
};
