import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "./constants";

const iniState = {
  loading: false,
  filter: "",
  fieldGroups: []
};

const handlers = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    setInitialData: (state, { payload: { fieldGroups } }) => ({
      fieldGroups
    })
  },
  namespace: NAMESPACE
});

export default handlers;
