import React from "react";
import { connect } from "react-redux";
import { getters, actions } from "Event/ItemCatalogApprovers";
import { Div, BigFilledInput, SearchIcon } from "components/Base";
import NotAccess from "./NotAccess";
import HasAccess from "./HasAccess";
import Loading from "ui-kit/Loading";

const decorate = connect(
  state => ({
    usersFilter: getters.usersFilter(state),
    loading: getters.loading(state)
  }),
  {
    setUsersFilter: actions.setUsersFilter
  }
);

const Layout = ({ usersFilter, setUsersFilter, loading, handlers }) => (
  <Div px={6} pb={6} display="column" maxWidth={900}>
    {/*
      <Div display="row.space-between.center" mb={4}>
        <Text6 bold color="black">
          Approvers
        </Text6>
        <MediumOutlineButton RightIcon={OpenInNewIcon}>
          Approval Workflows
        </MediumOutlineButton>
      </Div>
    */}
    {/* <Div display="row.flex-start.center" width={1}>
        <SmallToggle />
        <Text3 ml={2} bold>
          Always require approval
        </Text3>
      </Div> */}
    <BigFilledInput
      bg="neutral1"
      LeftIcon={SearchIcon}
      color="neutral6"
      width={1}
      mt={2}
      continuous
      value={usersFilter}
      onChange={value => setUsersFilter(value)}
      placeholder="Search all users..."
    />
    {loading ? (
      <Loading />
    ) : (
      <>
        <HasAccess handlers={handlers} />
        <NotAccess handlers={handlers} />
      </>
    )}
  </Div>
);

export default decorate(Layout);
