import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import autobind from "autobind-decorator";
import css from "./styles.scss";
import { get } from "lodash";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Body from "components/Global/Modal/Layout/ScrollableBody";
import {
  ButtonGroup,
  Submit,
  ButtonOutline
} from "components/Global/Modal/Layout/Buttons";
import FormElements from "components/Global/Modal/Layout/FormElements";
import CircularProgress from "material-ui/CircularProgress";
import Panel from "./Panel";
import User from "./User";

const { Label, InputSection, InputGroup } = FormElements;

export const Loading = CSSModules(
  () => (
    <div styleName="progress">
      <CircularProgress color="#ccc" />
    </div>
  ),
  css
);

@CSSModules(css)
class AssignOwnersModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      saving: false,
      filter: null,
      usersToAdd: {},
      usersToRemove: {}
    };
  }

  componentWillMount() {
    Promise.all([
      this.props.getUsers(this.props.eventDetails.id),
      this.props.getOwners({
        moduleId: this.props.moduleId,
        recordIds: this.props.recordIds,
        options: {
          orgId: this.props.orgDetails.id,
          eventId: this.props.eventDetails.id
        }
      })
    ]).then(() => {
      this.setState({ loading: false });
    });
  }

  onFilterChange = e => this.setState({ filter: e.target.value });

  clearFilter = e => this.setState({ filter: null });

  onUserSelect = user => {
    this.setState(state => {
      if (state.usersToAdd[user.user_id]) {
        delete state.usersToAdd[user.user_id];
      } else {
        state.usersToAdd[user.user_id] = user;
      }
      return state;
    });
  };

  onOwnerSelect = user => {
    this.setState(state => {
      if (state.usersToRemove[user.id]) {
        delete state.usersToRemove[user.id];
      } else {
        state.usersToRemove[user.id] = user;
      }
      return state;
    });
  };

  getFilteredUsers = (filter, users) => {
    if (!filter) return users;

    const formattedFilter = filter.trim().toLowerCase();
    return users.filter(u => u.search.indexOf(formattedFilter) > -1);
  };

  isUserSelected = user => Boolean(this.state.usersToAdd[user.user_id]);

  isOwnerSelected = user => Boolean(this.state.usersToRemove[user.id]);

  @autobind
  async deleteOwners() {
    const userIdsToRemove = Object.keys(this.state.usersToRemove);

    if (!userIdsToRemove.length) return true;

    const result = await this.props.deleteOwners({
      moduleId: this.props.moduleId,
      recordIds: this.props.recordIds,
      userIds: userIdsToRemove,
      options: {
        orgId: this.props.orgDetails.id,
        eventId: this.props.eventDetails.id
      }
    });

    return result;
  }

  @autobind
  async addOwners() {
    const userIdsToAdd = Object.keys(this.state.usersToAdd);

    if (!userIdsToAdd.length) return true;

    const result = await this.props.addOwners({
      moduleId: this.props.moduleId,
      recordIds: this.props.recordIds,
      userIds: userIdsToAdd,
      options: {
        orgId: this.props.orgDetails.id,
        eventId: this.props.eventDetails.id
      }
    });

    return result;
  }

  @autobind
  async saveAndClose() {
    this.setState({ saving: true });
    await this.deleteOwners();
    await this.addOwners();
    this.props.hideModal();
    this.props.showSnackbar({ message: "Owners updated" });
    if (this.props.onDone) {
      this.props.onDone();
    }
  }

  render() {
    const { loading, saving, filter, usersToAdd } = this.state;
    const {
      owners,
      users,
      recordIds,
      recordNamePlural,
      recordNameSingular
    } = this.props;
    const filteredUsers = this.getFilteredUsers(filter, users);

    return (
      <StyleWrapper
        bodyStyles={{ padding: 0 }}
        containerStyles={{ overflowY: "hidden" }}
        heading={`Assigning owners to ${recordIds.length} ${
          recordIds.length !== 1 ? recordNamePlural : recordNameSingular
        }`}
        hideModal={this.props.hideModal}
        width={745}
        height={600}
      >
        <Body
          style={{
            height: 400,
            padding: "20px 30px 0px 30px"
          }}
        >
          {loading ? (
            <Loading />
          ) : (
            <div styleName="container">
              <div styleName="users">
                <div styleName="inputWrapper">
                  <input
                    autoFocus
                    value={filter}
                    onChange={this.onFilterChange}
                    placeholder="Search users..."
                    styleName="input"
                    type="text"
                  />
                  {filter ? (
                    <i
                      className="material-icons"
                      onClick={this.clearFilter}
                      styleName="clearFilter"
                    >
                      close
                    </i>
                  ) : (
                    <i className="material-icons">search</i>
                  )}
                </div>
                <div styleName="usersLists">
                  {!filteredUsers.length ? (
                    <div styleName="empty">No users matched your search</div>
                  ) : null}
                  {filteredUsers.map(user => (
                    <User
                      key={user.user_id}
                      user={user}
                      onClick={() => this.onUserSelect(user)}
                      selected={this.isUserSelected(user)}
                      showEmail
                      showCheckbox
                    />
                  ))}
                </div>
              </div>
              <div styleName="owners">
                <Panel title="Selected:">
                  <div styleName="usersList">
                    {!Object.keys(usersToAdd).length ? (
                      <div styleName="empty">No users selected</div>
                    ) : null}
                    {Object.keys(usersToAdd).map(userId => {
                      const user = usersToAdd[userId];
                      return (
                        <User
                          key={user.user_id}
                          user={user}
                          onClick={() => this.onUserSelect(user)}
                          selected={this.isUserSelected(user)}
                          style={{
                            backgroundColor: "#FDE7CB",
                            marginBottom: 3
                          }}
                          icon={
                            <i
                              className={[
                                "material-icons",
                                css.removeIcon
                              ].join(" ")}
                            >
                              close
                            </i>
                          }
                        />
                      );
                    })}
                  </div>
                </Panel>

                {Object.keys(owners).length ? (
                  <Panel
                    title="Existing owners:"
                    description={`These users are currently assigned to 1 or more of the ${
                      recordIds.length
                    } selected ${
                      recordIds.length !== 1
                        ? recordNamePlural
                        : recordNameSingular
                    }.`}
                  >
                    <div styleName="usersList">
                      {Object.keys(owners).map(userId => {
                        const user = owners[userId];
                        const isOwnerSelected = this.isOwnerSelected(user);
                        return (
                          <User
                            key={user.id}
                            user={{
                              user_id: user.id,
                              user_email: user.email,
                              user_fname: user.fname,
                              user_lname: user.lname,
                              user_photo_url: user.photo_url
                            }}
                            onClick={() => this.onOwnerSelect(user)}
                            selected={isOwnerSelected}
                            faded={isOwnerSelected}
                            style={{
                              backgroundColor: "#F5F3F8",
                              marginBottom: 3
                            }}
                            icon={
                              isOwnerSelected ? (
                                <span className={css.undo}>Undo</span>
                              ) : (
                                <i
                                  className={[
                                    "material-icons",
                                    css.removeIcon
                                  ].join(" ")}
                                >
                                  close
                                </i>
                              )
                            }
                          />
                        );
                      })}
                    </div>
                  </Panel>
                ) : null}
              </div>
            </div>
          )}
        </Body>
        <div className={css.footer}>
          <ButtonGroup>
            <Submit
              disabled={saving}
              title={
                <span>
                  <span>{saving ? "Saving" : "Save"}</span>
                  {saving ? (
                    <i
                      className={[
                        "fa",
                        "fa-circle-o-notch",
                        css.processingIcon
                      ].join(" ")}
                    />
                  ) : null}
                </span>
              }
              onClick={this.saveAndClose}
            />
            <ButtonOutline title="Cancel" onClick={this.props.hideModal} />
          </ButtonGroup>
        </div>
      </StyleWrapper>
    );
  }
}

AssignOwnersModal.propTypes = {
  moduleId: PropTypes.string.isRequired,
  recordIds: PropTypes.array.isRequired,
  getUsers: PropTypes.func.isRequired,
  getOwners: PropTypes.func.isRequired,
  addOwners: PropTypes.func.isRequired,
  deleteOwners: PropTypes.func.isRequired,
  showSnackbar: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  eventDetails: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  owners: PropTypes.array.isRequired,
  recordNameSingular: PropTypes.string.isRequired,
  recordNamePlural: PropTypes.string.isRequired,
  onDone: PropTypes.func.isRequired
};

export default AssignOwnersModal;
