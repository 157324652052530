import React, { useState } from "react";
import { Div, Span } from "components/Base";

import { getters, actions } from "../model";
import { connect } from "react-redux";

import Video from "./Video";

import {
  getLeftSidebarLogoImageUrl,
  getVideoStreams,
  getLeftSidebarSecondaryColor
} from "../selectors";

const decorate = connect(
  (state, props) => ({
    playing: getters.playing(state, props),
    leftSidebarLogoImageUrl: getLeftSidebarLogoImageUrl(state),
    videoStreams: getVideoStreams(state),
    secondaryColor: getLeftSidebarSecondaryColor(state)
  }),
  {
    play: () => actions.setPlaying(true),
    pause: () => actions.setPlaying(false)
  }
);

const CurrentVideo = ({
  playing,
  play,
  pause,
  leftSidebarLogoImageUrl,
  videoStreams = [],
  secondaryColor
}) => {
  const [activeStreamIdx, setActiveStream] = useState(0);
  const streamIdxToUse = videoStreams[activeStreamIdx] ? activeStreamIdx : 0;
  const streamToUse = videoStreams[streamIdxToUse];
  const color = secondaryColor || "#084289";

  return (
    <Div bb={1} bc="#114178">
      {leftSidebarLogoImageUrl ? (
        <Div p={5} display="row.center.center" height={89}>
          <img
            alt="Left Sidebar Image"
            src={leftSidebarLogoImageUrl}
            // width={120}
            height={37}
          />
        </Div>
      ) : null}

      {videoStreams.length > 1 ? (
        <Div
          style={{
            padding: "0 25px",
            marginBottom: "16px"
          }}
        >
          <Div
            display="row.flex-start.center"
            flex={1}
            width={1}
            height={37}
            style={{
              minWidth: "0px",
              border: `1px solid ${color}`,
              borderRadius: "6px",
              overflow: "hidden"
            }}
          >
            {videoStreams.map((s, idx) => (
              <Div
                flex={1}
                height={1}
                uppercase
                display="row.center.center"
                onClick={() => {
                  if (idx !== streamIdxToUse) {
                    pause();
                    setActiveStream(idx);
                    play();
                  }
                }}
                bg={
                  streamIdxToUse === idx
                    ? {
                        default: color,
                        hover: color
                      }
                    : {
                        default: "tranparent",
                        hover: color
                      }
                }
                color={
                  streamIdxToUse === idx
                    ? {
                        default: "#fff",
                        hover: "#fff"
                      }
                    : {
                        default: "#7AB3FF",
                        hover: "#fff"
                      }
                }
                style={{
                  width: "33.333%",
                  fontSize: "14px",
                  fontFamily: "'Roboto', sans-serif",
                  letterSpacing: "0.4px",
                  padding: "0 10px"
                }}
              >
                <Span truncate>{s.label}</Span>
              </Div>
            ))}
          </Div>
        </Div>
      ) : null}

      {videoStreams.length && streamToUse ? (
        <>
          <Video
            url={streamToUse.video_url}
            playing={playing}
            onPlay={play}
            onPause={pause}
          />
          <Div
            py={5}
            color="white"
            display="row.space-between.center"
            style={{
              paddingLeft: "25px",
              paddingRight: "43px"
            }}
          >
            <Div
              fs={4}
              uppercase
              style={{
                fontFamily: "'Roboto Condensed', sans-serif",
                letterSpacing: "0.4px"
              }}
            >
              now playing
            </Div>
            <Div
              fs={4}
              style={{
                fontFamily: "'Roboto', sans-serif",
                letterSpacing: "-0.19px"
              }}
            >
              {streamToUse.title}
            </Div>
          </Div>
        </>
      ) : null}
    </Div>
  );
};

export default decorate(CurrentVideo);
