import { all, takeEvery, fork, select, put, call } from "redux-saga/effects";
import { getSectionsData } from "Portal/CustomizeSectionsModal/selectors";
import { actions, getters } from "Portal/CustomizeSectionsModal/model";
import {
  actions as treeTableActions,
  getters as treeTableGetters
} from "ui-kit/TreeTable/model";
import { getCredentials } from "redux/modules/user/selectors";
import { registerError } from "redux/modules/errors/actions";
import { eventId as getEventId } from "redux/modules/event/selectors";
import Api from "./api";

const init = function*({ payload: { permissionSetId } }) {
  try {
    yield put(actions.setLoading(true));
    yield put(actions.setPermissionSetId(permissionSetId));

    const eventId = yield select(getEventId);
    const credentials = yield select(getCredentials);

    const [
      {
        payload: { is_group_portal, settings, name, links }
      },
      {
        payload: { pages }
      }
    ] = yield all([
      call(Api.getSettings, {
        permissionSetId,
        credentials
      }),
      yield call(Api.getPages, { eventId, credentials })
    ]);

    yield all([
      put(
        treeTableActions.setInitialData({
          items: pages,
          canDrag: false,
          showCheck: false
        })
      ),
      put(
        actions.setInitialData({
          is_group_portal,
          settings,
          name,
          links,
          pages
        })
      )
    ]);
  } catch (e) {
    yield put(
      registerError([
        {
          system: e,
          user: "An error ocurred loading data"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const save = function*({ payload: { hideModal, fetchPermissionSets } }) {
  try {
    yield put(actions.setLoading(true));

    const data = yield select(getSectionsData);
    const permissionSetId = yield select(getters.permissionSetId);
    const credentials = yield select(getCredentials);

    yield call(Api.saveSections, {
      credentials,
      permissionSetId,
      data
    });
    yield call(fetchPermissionSets);
    yield call(hideModal);
  } catch (e) {
    yield put(
      registerError([
        {
          system: e,
          user: "An error ocurred saving sections"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const selectAllPages = function*() {
  const treeOrder = yield select(treeTableGetters.order);
  yield put(actions.enableAllPages({ treeOrder }));
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchSave = function*() {
  yield takeEvery(actions.save.type, save);
};

const watchSelectAllPages = function*() {
  yield takeEvery(actions.selectAllPages.type, selectAllPages);
};

const rootSaga = function*() {
  yield all([fork(watchSave), fork(watchInit), fork(watchSelectAllPages)]);
};

export default rootSaga;
