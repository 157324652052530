import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE, TABS, SORTING } from "./constants";

const iniState = {
  loading: true,
  showEmptyState: false,
  data: {},
  filter: "",
  activeTab: TABS.ALL_FORMS,
  activeSorting: SORTING.PENDING,
  showAddFileModal: false,
  requestId: ""
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    goToResults: R.identity,
    openCreateRequestModal: () => ({
      showAddFileModal: true,
      requestId: ""
    }),
    openEditRequestModal: (_, { payload: requestId }) => ({
      showAddFileModal: true,
      requestId
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
