import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "Portal/PortalPagePGA/AllNewsAndAlertsSingle/constants";

const iniState = {
  title: "",
  content: "",
  loading: true
};

export const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    setInitialData: (_, { payload: { title, content } }) => ({
      loading: false,
      title,
      content
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
