import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";

import {
  Div,
  HalfSizeDotIcon,
  TinyShadedBox,
  makeTable,
  Text0,
  Text1,
  Text2,
  Text4,
  Text5,
  Type10Card,
  MoneyIcon
} from "components/Base";

import { withProps } from "utils/General";
import { TabValues } from "../constants";
import TabsWithButtons from "./TabsWithButtons";
import FooterButtons from "./FooterButtons";

import { actions } from "../index";

const RowText = ({ text = "—" }) => (
  <Text2 color="gray7" bold>
    {text === 0 ? "-" : text}
  </Text2>
);

const headerCell = text => () => (
  <Text1 color="neutral6" bold uppercase>
    {text}
  </Text1>
);

const HeaderStyler = withProps({
  display: "row.flex-start.center",
  pb: 1
})(Div);

const RowStyler = withProps({
  display: "row.flex-start.center",
  bg: "white",
  py: 1
})(Div);

const CardWithTitle = ({ label, children, ...props }) => (
  <Div bg="white" bra={1} bc="neutral3" ba={1} p={2} {...props}>
    <Text4
      color="gray7"
      px={2}
      bold
      bg="white"
      display="inline-block"
      style={{ position: "absolute", marginTop: "-20px" }}
    >
      {label}
    </Text4>
    <Div mt={3}>{children}</Div>
  </Div>
);

const ReportTable = ({ type, items, isLastTable, handlers }) => {
  const requestTypeId = R.propOr("", "id", type);
  const withAmount = R.propOr(false, "amount", type);
  const extractId = val => R.last(val.split("_"));
  const lineItemIds = R.compose(
    R.map(extractId),
    R.flatten,
    R.map(R.prop("line_item_ids"))
  )(items);
  const pendingLineItemIds = R.compose(
    R.map(extractId),
    R.flatten,
    R.map(R.prop("line_item_ids")),
    R.filter(l => l.count_of_requested !== l.count_of_approved)
  )(items);
  const Table = makeTable({
    TableStyler: Div,
    HeaderStyler,
    RowStyler,
    HeaderCellStyler: Div,
    RowCellStyler: Div,
    columnProps: [
      { width: 2 / 6 },
      { width: 1 / 6 },
      { width: 1 / 6 },
      { width: 1 / 6 },
      { width: 1 / 6 }
    ],
    headerCellComps: [
      headerCell("Item"),
      headerCell("Requested"),
      headerCell("Approved"),
      headerCell("Assigned"),
      headerCell("Issued")
    ],
    rowCellComps: [
      ({ name, variant_name, color }) => (
        <Div display="row.flex-start.center" pr={2}>
          <HalfSizeDotIcon color={color} size={16} />
          <Div display="column.flex-start.flex-start" ml={1}>
            <Div fs={3} color="gray7" fw={3}>
              {name}
              {withAmount && <MoneyIcon color="success5" sizeWFS={2} ml={2} />}
            </Div>
            {variant_name ? (
              <Text1 color="neutral5">{variant_name}</Text1>
            ) : null}
          </Div>
        </Div>
      ),
      ({ count_of_requested }) => <RowText text={count_of_requested} />,
      ({ count_of_approved }) => <RowText text={count_of_approved} />,
      ({ count_of_approved_assigned }) => (
        <RowText text={count_of_approved_assigned} />
      ),
      ({ count_of_issued }) => <RowText text={count_of_issued} />
    ]
  });

  return (
    <CardWithTitle label={type.name} mb={isLastTable ? 0 : 5}>
      <Table rowsData={items} />
      <FooterButtons
        lineItemIds={lineItemIds}
        pendingLineItemIds={pendingLineItemIds}
        requestTypeId={requestTypeId}
        handlers={handlers}
      />
    </CardWithTitle>
  );
};

const OrderColumn = ({ header, value }) => (
  <Div width={1 / 4}>
    <Text0 color="neutral6" bold uppercase>
      {header}
    </Text0>
    <Text2 mt={1}>{value}</Text2>
  </Div>
);

const OrderCard = ({
  showGroupNameColumn,
  title,
  orderNumber,
  tags,
  source,
  orderType,
  requestedBy,
  groupName,
  orderDate,
  onClick
}) => (
  <Div
    bra={1}
    bc={[{ default: "neutral1", hover: "neutral4" }]}
    py={3}
    px={4}
    display="row.flex-start.center"
    mb={2}
    onClick={onClick}
  >
    <Div flex={1} display="column.flex-start.stretch">
      <Div display="row.flex-start.center">
        <Text5 bold>{title}</Text5>
        <Div ml={2} flex={1}>
          {R.addIndex(R.map)(
            (name, idx) => (
              <TinyShadedBox key={`${name}-${idx}`} uppercase ml={1}>
                {name}
              </TinyShadedBox>
            ),
            tags
          )}
        </Div>
        <Text1 bold color="neutral6">{`Order #${orderNumber}`}</Text1>
      </Div>
      <Div mt={2} display="row.flex-start.center">
        <OrderColumn header="Type" value={orderType} />
        {showGroupNameColumn ? (
          <OrderColumn header="Group" value={groupName || "-"} />
        ) : null}
        <OrderColumn header="Point of Contact" value={requestedBy} />
        <OrderColumn header="Source" value={source} />
        <OrderColumn header="Order Date" value={orderDate} />
      </Div>
    </Div>
  </Div>
);

const OrderCardList = ({ showGroupNameColumn, orders }) => (
  <Div>
    {R.addIndex(R.map)(
      (props, idx) => (
        <OrderCard
          key={`${idx}-${R.propOr("orderNumber", "", props)}`}
          showGroupNameColumn={showGroupNameColumn}
          {...props}
        />
      ),
      orders
    )}
  </Div>
);

const decorate = connect(
  null,
  {
    setDefaultTab: actions.setActiveTabValue,
    setRecordId: actions.setRecordId,
    setHasContactId: actions.setHasContactId,
    changeTab: actions.setActiveTabValue
  }
);

const CredentialRequestCard = ({
  rowContents,
  numOrders,
  reportTableData,
  showGroupNameColumn,
  allOrders,
  HeaderIcon,
  headerIconColor,
  onClickHeaderButton,
  defaultTab = TabValues.OVERVIEW,
  handlers,
  setDefaultTab,
  changeTab,
  recordId,
  loading,
  setRecordId,
  hasContactId = false,
  setHasContactId
}) => {
  useEffect(() => {
    if (defaultTab) {
      setDefaultTab(defaultTab);
    }
  }, [defaultTab]);
  useEffect(() => {
    if (recordId) {
      setRecordId(recordId);
    }
  }, [recordId]);
  useEffect(() => {
    setHasContactId(hasContactId);
  }, [hasContactId]);
  const isEmpty = R.equals(numOrders, 0);
  const tabs = isEmpty
    ? []
    : [
        {
          label: "Overview",
          fs: 2,
          value: TabValues.OVERVIEW,
          onClick: () => changeTab(TabValues.OVERVIEW),
          component: (
            <Div m={4}>
              {R.addIndex(R.map)(
                ({ buttons, frontText, backText, rowColor }, idx) => (
                  <Type10Card
                    key={`${idx}-${frontText}`}
                    buttons={buttons}
                    frontText={frontText}
                    backText={backText}
                    color={rowColor}
                  />
                ),
                rowContents
              )}

              <Div py={4}>
                {reportTableData.length ? (
                  reportTableData.map((itemType, idx) => (
                    <ReportTable
                      key={itemType.id}
                      type={itemType}
                      items={itemType.items}
                      isLastTable={idx === reportTableData.length - 1}
                      handlers={handlers}
                    />
                  ))
                ) : (
                  <Div fs={2} px={5} py={2} color="gray6" textAlign="center">
                    There are no items on the orders to summarize.
                  </Div>
                )}
              </Div>
            </Div>
          )
        },
        {
          label: `Manage Orders${
            isEmpty || !numOrders ? "" : ` (${numOrders})`
          }`,
          value: TabValues.MANAGE_ORDERS,
          onClick: () => changeTab(TabValues.MANAGE_ORDERS),
          fs: 2,
          component: (
            <Div mx={4} mb={3} pb={2} pt={3}>
              <OrderCardList
                showGroupNameColumn={showGroupNameColumn}
                orders={allOrders}
              />
            </Div>
          )
        }
      ];

  return (
    <Div>
      <TabsWithButtons
        headerText="Requests & Orders"
        headerIconColor={headerIconColor}
        onClickHeaderButton={onClickHeaderButton}
        HeaderIcon={HeaderIcon}
        loading={loading}
        tabs={tabs}
        handlers={handlers}
        hideButtons={isEmpty}
        emptyComponent={
          isEmpty ? (
            <Div fs={2} fw={3} px={5} py={4} color="gray8">
              0 requests / orders
            </Div>
          ) : null
        }
      />
    </Div>
  );
};

export default decorate(CredentialRequestCard);
