import PropTypes from "prop-types";
import React, { Component } from "react";
import Preview from "../Preview";
import ReactDOM from "react-dom";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import jQuery from "jquery";
import css from "./styles.scss";
import Checkbox from "material-ui/Checkbox";

@CSSModules(css)
class Recipient extends Component {
  @autobind
  preview() {
    const element = ReactDOM.findDOMNode(this.previewComponent);
    if (element) {
      return element.outerHTML;
    }
    return false;
  }

  @autobind
  openPreview() {
    const content = this.preview();
    if (content) {
      const preview = window.open();
      jQuery(preview.document.head).append(
        "<style>.hide-in-preview { display: none !important; }</style>"
      );
      jQuery(preview.document.body).html(content);
    }
  }

  @autobind
  toggleRecipient(e, val) {
    return this.props.toggleRecipient(val, this.props.email, () =>
      this.preview()
    );
  }

  render() {
    const {
      email,
      requests,
      selected,
      content,
      topLevelRequest,
      header,
      eventDetails
    } = this.props;

    return (
      <div styleName="container">
        <div styleName="checkboxWrapper">
          <Checkbox
            checked={selected}
            iconStyle={{ alignSelf: "center" }}
            labelStyle={{ marginBottom: 0 }}
            label={
              <div className={css.label}>
                <div className={css.avatar}>
                  <i className="material-icons">person</i>
                </div>
                <div className={css.labelBody}>
                  <div>{email}</div>
                  {requests.length ? (
                    <div className={css.subLabel}>
                      {requests.length} requests
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            }
            onCheck={this.toggleRecipient}
          />
        </div>
        <a tabIndex="0" onClick={this.openPreview} styleName="preview">
          Preview Email
        </a>
        <div style={{ display: "none" }}>
          <Preview
            ref={c => {
              this.previewComponent = c;
            }}
            email={email}
            requests={requests}
            header={header}
            content={content}
            topLevelRequest={topLevelRequest}
            eventDetails={eventDetails}
          />
        </div>
      </div>
    );
  }
}

Recipient.propTypes = {
  styles: PropTypes.object,
  selected: PropTypes.bool,
  header: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  eventDetails: PropTypes.object.isRequired,
  requests: PropTypes.array.isRequired,
  toggleRecipient: PropTypes.func.isRequired,
  topLevelRequest: PropTypes.object.isRequired
};

export default Recipient;
