import React, { Component } from "react";
import moment from "moment";
import PropTypes from "prop-types";

class TimeKeeper extends Component {
  static defaultState(props) {
    return {
      fromNow: props.fromNow ? moment(props.fromNow).fromNow() : undefined,
      now: moment().format(props.format)
    };
  }
  state = TimeKeeper.defaultState(this.props);

  componentDidMount() {
    this.initInterval(this.props);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.fromNow !== this.props.fromNow) {
      this.initInterval(newProps);
    }
  }
  componentWillUnmount() {
    this.clear();
  }
  clear() {
    clearInterval(this.timer);
  }
  initInterval(props) {
    this.clear();
    this.timer = setInterval(
      () => this.setState(TimeKeeper.defaultState(props)),
      5000
    );
  }
  render() {
    return this.props.children(this.state);
  }
}

TimeKeeper.defaultProps = {
  format: "dddd, MMMM D, h:mmA"
};

TimeKeeper.propTypes = {
  children: PropTypes.func.isRequired,
  format: PropTypes.string,
  fromNow: PropTypes.string
};

export default TimeKeeper;
