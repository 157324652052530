import * as R from "ramda";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import autobind from "autobind-decorator";

import { actions } from "../model";

import styleSpecs from "components/Base/Base/styleSpecs";

const decorate = connect(
  null,
  bindInstance({
    saveColumnWidth: actions.saveColumnWidth
  })
);

class DefaultTh extends Component {
  constructor(props) {
    super(props);

    this.cellRef = React.createRef();
    this.iniX = 0;
    this.state = {
      dragging: false
    };
  }

  @autobind
  onMouseDown() {
    const headNode = this.props.headRef.current;
    if (headNode) {
      headNode.addEventListener("mousemove", this.onMouseMove);
      headNode.addEventListener("mouseup", this.onMouseUp);
    }
    this.iniX = this.cellRef.current.getBoundingClientRect().left;
    this.setState({ dragging: true });
  }

  @autobind
  onMouseUp() {
    const headNode = this.props.headRef.current;
    if (headNode) {
      headNode.removeEventListener("mousemove", this.onMouseMove);
      headNode.removeEventListener("mouseup", this.onMouseUp);
    }
    this.props.saveColumnWidth({
      columnId: R.path(["column", "id"], this.props),
      width: this.right
    });
    this.setState({ dragging: false });
  }

  @autobind
  onMouseMove(e) {
    if (this.cellRef.current) {
      this.right = e.pageX - this.iniX;
      this.cellRef.current.style.width = this.right + "px";
      this.cellRef.current.style.maxWidth = this.right + "px";
      this.cellRef.current.style.minWidth = this.right + "px";
    }
  }

  render() {
    const { title, children, className } = this.props;

    return (
      <th className={className} title={title} ref={this.cellRef}>
        <div className="cell">
          <div
            style={{
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              userSelect: "none"
            }}
          >
            {children}
          </div>
          <div
            className="spacer"
            onMouseDown={this.onMouseDown}
            style={
              this.state.dragging
                ? {
                    cursor: "ew-resize",
                    backgroundColor: styleSpecs.color.gray3
                  }
                : {}
            }
          ></div>
        </div>
      </th>
    );
  }
}

export default decorate(DefaultTh);
