import React from "react";
import { connect } from "react-redux";
import RecordCard from "components/Global/CRM/RecordCardList/RecordCard";
import * as R from "ramda";
import { getters } from "Modules/ImportModal";
import { Div, Text4 } from "components/Base";
import { getRecords } from "Modules/ImportModal/selectors";

const decorate = connect(state => ({
  fieldGroups: getters.fieldGroups(state),
  mappedRecords: getRecords(state)
}));

const PreviewRecords = ({ mappedRecords }) => (
  <Div>
    {mappedRecords.length ? (
      <Div bb={1} bc="neutral2" height={30} pb={3} mb={3} mt={2}>
        <Text4 bold>Preview</Text4>
      </Div>
    ) : null}
    {R.map(
      ({ name, metaValues, id }) => (
        <RecordCard key={id} name={name} metaValues={metaValues} />
      ),
      mappedRecords
    )}
  </Div>
);

export default decorate(PreviewRecords);
