import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import Loading from "ui-kit/Loading";
import {
  Div,
  MediumOutlineButton,
  BigFilledButton,
  CloseIcon,
  CheckIcon
} from "components/Base";

import { WithFormInstanceProvider } from "ui-kit/Form/View";

import { getters, actions } from "../model";

import ActionButtons from "./ActionButtons";
import OptionsAndSettings from "./OptionsAndSettings";
import Fields from "./Fields";
import TitleEditor from "./TitleEditor";
// import DependantNotification from "./DependantNotification";

import { ACTIVITY_DETAILS_FORM_ID } from "../constants";
import { withProps, noop } from "utils/General";
import { getIsComplete } from "../selectors";

const FieldsContainer = withProps({
  display: "column.flex-start.stretch",
  py: 4,
  px: 6
})(Div);

const TopRowContainer = withProps({
  display: "row.space-between.center",
  px: 4,
  py: 4,
  flexShrink: 0
})(Div);

const RowContainer = withProps({
  display: "row.space-between.center",
  px: 4,
  py: 2,
  flexShrink: 0
})(Div);

const DarkCloseIcon = withProps({
  color: "black",
  size: 30
})(CloseIcon);

const decorate = R.compose(
  WithFormInstanceProvider(R.always(ACTIVITY_DETAILS_FORM_ID)),
  connect(
    (state, props) => ({
      loading: getters.loading(state, props),
      isTask: getters.isTask(state, props),
      isCompleted: getIsComplete(state, props)
    }),
    {
      handleClose: actions.closeSidebar,
      markCompleted: actions.markCompleted,
      closeEditor: actions.closeEditor
    }
  )
);

const Body = ({
  loading,
  isTask,
  isCompleted,
  handleClose,
  markCompleted = noop,
  hasDependentTasks = false,
  closeEditor
}) => (
  <Div height={1}>
    <TopRowContainer>
      <Div>
        {isTask && (
          <MediumOutlineButton
            isPadded={false}
            onClick={() => markCompleted(!isCompleted)}
            LeftIcon={CheckIcon}
            bg={isCompleted ? "altA5" : "white"}
            color={isCompleted ? "white" : "neutral8"}
          >
            {isCompleted ? "Completed" : "Mark Complete"}
          </MediumOutlineButton>
        )}
      </Div>
      <Div display="row.flex-end.center">
        <ActionButtons />
        <DarkCloseIcon ml={4} onClick={() => handleClose()} />
      </Div>
    </TopRowContainer>

    <Div
      style={{
        position: "relative",
        height: "calc(100vh - 120px)"
      }}
      bt={1}
      bb={1}
      bc="neutral3"
    >
      {loading && <Loading />}

      {!loading && (
        <>
          {/* <DependantNotification /> */}
          <FieldsContainer
            style={{
              overflowY: "auto",
              cursor: "default",
              height: hasDependentTasks
                ? "calc(100vh - 175px)"
                : "calc(100vh - 122px)"
            }}
            onClick={closeEditor}
          >
            <TitleEditor />
            <Fields />
            <OptionsAndSettings />
          </FieldsContainer>
        </>
      )}
    </Div>
    <RowContainer>
      <BigFilledButton bg="altB5" onClick={() => handleClose()}>
        Close
      </BigFilledButton>
    </RowContainer>
  </Div>
);

export default decorate(Body);
