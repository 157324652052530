import React from "react";
import ViewPicker from "ui-kit/ViewPicker/View";
import { Div } from "components/Base";
import { VIEWPICKER_INSTANCE_ID } from "EventLight/Agenda/Manage/constants";
import SaveViewVertical from "ui-kit/ViewPicker/View/SaveViewVertical";
import { connect } from "react-redux";

import { getHasViewChanged } from "EventLight/Agenda/Manage/selectors";

const decorate = connect((state, props) => ({
  hasViewChanged: getHasViewChanged(state, props)
}));

const ViewPickerWrapper = ({ hasViewChanged, ...styleProps }) => {
  return (
    <Div bg="neutral1" bra={1} p={1} {...styleProps}>
      <ViewPicker
        white
        instanceId={VIEWPICKER_INSTANCE_ID}
        shadow={{ default: "1", hover: "4" }}
      />
      <SaveViewVertical
        instanceId={VIEWPICKER_INSTANCE_ID}
        hasViewChanged={hasViewChanged}
        collapsed={false}
        white
        vertical
      />
    </Div>
  );
};

export default decorate(ViewPickerWrapper);
