import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import * as R from "ramda";

import { getSupportArticlesById } from "App/Data/selectors";
import { connect } from "react-redux";
import { getEventScope } from "redux/modules/event/selectors";

const decorate = connect((state, props) => ({
  articles: getSupportArticlesById(state, props),
  eventScope: getEventScope(state, props)
}));

const Support = ({ eventScope, articles, small = false, style = {} }) => {
  const supportItem = articles ? articles[eventScope] || articles.all : null;

  return supportItem && Object.keys(supportItem) ? (
    <div className={css.subNavLearn} style={style}>
      {supportItem.wistia_video_id ? (
        <div
          className={`wistia_embed wistia_async_${supportItem.wistia_video_id} popover=true popoverContent=link ${css.subNavLearnItemWrapper}`}
        >
          <a
            className={small ? css.subNavLearnItemSmall : css.subNavLearnItem}
            href="#"
          >
            <span className="material-icons-outlined">video_library</span>
            <span className={css.subNavLearnItemLabel}>Watch Video</span>
          </a>
        </div>
      ) : supportItem.video_url ? (
        <a
          className={small ? css.subNavLearnItemSmall : css.subNavLearnItem}
          href={supportItem.video_url}
          target="_blank"
        >
          <span className="material-icons-outlined">video_library</span>
          <span className={css.subNavLearnItemLabel}>Watch Video</span>
        </a>
      ) : null}

      {supportItem.link_url ? (
        <a
          className={small ? css.subNavLearnItemSmall : css.subNavLearnItem}
          href={supportItem.link_url}
          target="_blank"
        >
          <span className="material-icons-outlined">open_in_new</span>
          <span className={css.subNavLearnItemLabel}>
            {supportItem.link_label}
          </span>
        </a>
      ) : null}
    </div>
  ) : null;
};

export default R.compose(
  decorate,
  CSSModules(css)
)(Support);
