import * as R from "ramda";
import { makeInstanceSelector } from "redux-mvc";
import { createSelector } from "reselect";
import * as STANDARD_MODULE_FIELD_IDS from "utils/standard-module-field-ids";

import getFormVersion from "components/Event/FormsV2/Utils/get-form-version";

import { user } from "redux/modules/user/selectors";

import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { eventDetails as getSubmissionEventDetails } from "redux/modules/formsV2/submission/selectors";

import { DEFAULT_APPROVAL_FIELD_APPROVE_REJECT } from "components/Event/FormsV2/constants";
import { DEFAULT_APPROVAL_FIELD_APPROVE_REJECT as DEFAULT_APPROVAL_FIELD_APPROVE_REJECT_V3 } from "components/Event/FormsV3/constants";

import {
  EMPTY_SUBMISSION,
  EMPTY_PREFERENCES,
  EMPTY_MENU
} from "Submission/Subform/constants";

import { getters as DatagridGetters } from "ui-kit/Datagrid";
import { MODES, BUTTONS } from "ui-kit/Datagrid/constants";

// this selectors are not ment to work with instances
const getFieldId = (_, props) => R.pathOr("", ["field", "id"], props);
const getKey = getFieldId;
const getSubmission = (_, props) => {
  return R.propOr(EMPTY_SUBMISSION, "submission", props);
};

const getForm = (_, props) => R.prop("form", getSubmission(_, props));
const getValues = (_, props) =>
  R.path(["people_blocks"], getSubmission(_, props));

export const getField = makeInstanceSelector(
  getForm,
  getFieldId,
  (form, fieldId) =>
    R.find(
      R.compose(
        R.equals(fieldId),
        R.prop("id")
      ),
      R.propOr([], "fields", form)
    ) || { subform: { form: EMPTY_SUBMISSION.form } }
)(getKey);

export const getFieldName = makeInstanceSelector(
  getField,
  R.pathOr("", ["subform", "form", "name"])
)(getKey);

const getBlock = makeInstanceSelector(
  getField,
  R.path(["people_block", "block"])
)(getKey);

const getVersion = R.compose(
  getFormVersion,
  R.prop("form"),
  getSubmission
);

export const getEventDetailsToUse = createSelector(
  getEventDetails,
  getSubmissionEventDetails,
  (eventDetails, submissionEventDetails) => {
    return eventDetails && eventDetails.id
      ? eventDetails
      : submissionEventDetails;
  }
);

const getPreferences = (state, props) => {
  const submission = getSubmission(state, props);
  const field = getField(state, props);
  const preferences = R.path([
    "preferences",
    R.path(["subform", "form_id"])(field)
  ])(submission);
  return preferences || EMPTY_PREFERENCES;
};

const getWidths = makeInstanceSelector(
  getBlock,
  getPreferences,
  (state, props) =>
    DatagridGetters.colWidths(state, {
      ...props,
      instanceId: getKey(state, props)
    }),
  (block, preferences, colWidths) => {
    const defaultWidths = R.compose(
      R.reduce((widths, field) => {
        if (R.path(["settings", "width"])(field)) {
          widths[field.id] = field.settings.width;
        }
        return widths;
      }, {}),
      R.prop("fields")
    )(block);

    return R.mergeAll([
      defaultWidths,
      R.propOr({}, "field_widths", preferences),
      colWidths
    ]);
  }
)(getKey);

const prependApproval = version => columns => {
  const approvalCol =
    version === 3
      ? DEFAULT_APPROVAL_FIELD_APPROVE_REJECT_V3
      : DEFAULT_APPROVAL_FIELD_APPROVE_REJECT;
  return R.prepend(
    approvalCol,
    R.filter(
      R.compose(
        R.not,
        R.equals(approvalCol.id),
        R.prop("id")
      ),
      columns
    )
  );
};

export const getColumns = makeInstanceSelector(
  getBlock,
  getWidths,
  getVersion,
  (_, props) => R.prop("showApproval", props),
  (block, widths, version, showApproval) => {
    const fields = R.compose(
      R.map(column => ({
        ...column,
        key: column.id,
        editable:
          column.type !== "static" &&
          !R.pathOr(false, ["schema", "locked"], column),
        description: R.path(["settings", "description"], column),
        resizable: true,
        width: widths[column.id] || 200,
        settings: {
          ...column.settings,
          suppressLinkToRecord: true
        }
      })),
      showApproval ? prependApproval(version) : R.identity,
      R.propOr([], "fields")
    )(block);

    const role =
      R.prop("collect_role", block) &&
      R.prop("collect_role_method", block) === "collect_for_each"
        ? [
            {
              id: "role",
              key: "role",
              name:
                block.role_field_alias && block.role_field_alias.length
                  ? block.role_field_alias
                  : "Role",
              type: "text",
              settings: {},
              resizable: true,
              width: widths.role || 200
            }
          ]
        : [];

    const itemBlocks = R.propOr([], "item_blocks", block).map(column => ({
      id: column.id,
      key: column.id,
      name: column.name,
      type: "item-block",
      settings: column,
      resizable: true,
      width: widths[column.id] || 200
    }));

    return R.compose(
      R.sortBy(R.prop("order")),
      R.map(f => ({
        ...f,
        required:
          R.contains(f.id, R.propOr([], "required_fields", block)) ||
          [
            STANDARD_MODULE_FIELD_IDS.CONTACTS.FIRST_NAME,
            STANDARD_MODULE_FIELD_IDS.CONTACTS.LAST_NAME
          ].includes(f.id),
        order: R.prop(f.id, R.propOr({}, "field_order", block))
      }))
    )([...fields, ...role, ...itemBlocks]);
  }
)(getKey);

export const getMetaData = makeInstanceSelector(
  (_, { readOnly }) => readOnly,
  (_, { saveAsDraft }) => saveAsDraft,
  getColumns,
  getSubmission,
  getForm,
  getEventDetailsToUse,
  user,
  getVersion,
  getField,
  (
    readOnly,
    saveAsDraft,
    columns,
    submission,
    form,
    eventDetails,
    user,
    version,
    field
  ) => ({
    saveAsDraft,
    readOnly,
    orgId: "", // @TODO get actual value
    eventDetails,
    columns: R.indexBy(R.prop("id"), columns),
    references: {}, // @TODO get actual value
    form, // parent form
    field,
    submission,
    user,
    version
  })
)(getKey);

export const getSubmissions = makeInstanceSelector(
  getValues,
  getFieldId,
  (values, fieldId) => R.pathOr([], [fieldId, "people"], values)
)(getKey);

const getItemBlocks = makeInstanceSelector(
  getBlock,
  R.compose(
    R.indexBy(R.prop("id")),
    R.propOr([], "item_blocks")
  )
)(getKey);

export const getRows = makeInstanceSelector(
  getSubmissions,
  getItemBlocks,
  getMetaData,
  getColumns,
  (_, { handlers }) => handlers,
  (submissions, blocks, meta, columns, handlers) => {
    return R.map(
      row =>
        R.mergeAll([
          {
            id: row.id,
            key: row.id,
            meta,
            handlers
          },
          R.omit(["values"], row),
          R.indexBy(
            R.prop("columnId"),
            R.map(column => {
              const type = R.prop("type", column);
              if (type === "item-block") {
                const lineItems = R.pathOr(
                  [],
                  ["related_order", "line_items"],
                  row
                );
                const block = R.propOr({}, column.id, blocks);
                const items = R.compose(
                  R.sortBy(R.prop("order")),
                  groupedLines =>
                    R.map(
                      lineId => ({
                        id: groupedLines[lineId][0].variant.id,
                        value: groupedLines[lineId][0].variant.item.name,
                        quantity: R.sum(
                          R.map(R.prop("quantity"))(groupedLines[lineId])
                        ),
                        price: R.path([0, "price"])(groupedLines[lineId]),
                        color:
                          groupedLines[lineId][0].variant.item.background_color,
                        order: R.compose(
                          R.prop("order"),
                          R.find(
                            R.propEq(
                              "variant_id",
                              groupedLines[lineId][0].variant.id
                            )
                          )
                        )(block.variants)
                      }),
                      R.keys(groupedLines)
                    ),
                  R.groupBy(R.path(["variant", "id"])),
                  R.filter(li => {
                    return R.contains(R.path(["variant", "id"])(li))(
                      R.map(R.prop("variant_id"))(block.variants)
                    );
                  })
                )(lineItems);

                return {
                  columnId: column.id,
                  key: `${row.id}_${column.id}`,
                  value: {
                    type,
                    value: items
                  }
                };
              }
              const val = R.pathOr({}, ["values", column.id], row);
              return {
                columnId: column.id,
                key: `${row.id}_${column.id}`,
                ...val
              };
            }, columns)
          )
        ]),
      submissions
    );
  }
)(getKey);

export const getMenu = makeInstanceSelector(
  getFieldId,
  (state, props) =>
    DatagridGetters.mode(state, {
      ...props,
      instanceId: getKey(state, props)
    }),
  (_, { menuHandlers = {} }) => menuHandlers,
  (fieldId, mode, menuHandlers) =>
    mode === MODES.EDIT
      ? [
          {
            title: "Toggle Required",
            icon: "done",
            action: ({ columnId }) => {
              menuHandlers.toggleRequiredField({
                fieldId: columnId,
                subformFieldId: fieldId
              });
            }
          }
        ]
      : EMPTY_MENU
)(getKey);

const showAddButton = makeInstanceSelector(
  getBlock,
  getSubmissions,
  (block, people) => {
    if (!block.allow_multiple && people.length >= 1) {
      return false;
    }
    if (
      block.max_amount !== null &&
      block.max_amount &&
      people.length >= block.max_amount
    ) {
      return false;
    }
    return true;
  }
)(getKey);

export const getEnabledButtons = makeInstanceSelector(
  showAddButton,
  showAddButton =>
    showAddButton
      ? [
          {
            ...BUTTONS.ADD_ROW,
            label: "Add Person"
          },
          BUTTONS.REMOVE_ROW
        ]
      : [BUTTONS.REMOVE_ROW]
)(getKey);
