import * as R from "ramda";
import { makeInstanceSelector } from "redux-mvc";

import * as DataSelectors from "App/Data/selectors";

import { getters as TabGetters } from "ui-kit/Tabs";

import {
  sumRequested,
  sumApproved,
  sumRejected,
  sumPending,
  sumPrice
} from "Submission/ItemBlockViewer/utils";

import { formatAmountForEvent } from "redux/modules/event/selectors";

const getKey = (_, props) => props.instanceId;

const blocks = makeInstanceSelector(
  (state, { params }) => DataSelectors.getFieldById(state, { params }),
  field => [field.item_block]
)(getKey);

const lineItems = makeInstanceSelector(
  (state, { params }) => DataSelectors.getFieldById(state, { params }),
  (state, { params }) => DataSelectors.getSubmissionById(state, { params }),
  (field, submission) => {
    return R.compose(
      R.groupBy(R.path(["variant", "id"])),
      R.pathOr([], ["related_order", "line_items"])
    )(submission);
  }
)(getKey);

export const getOrderIds = makeInstanceSelector(
  (state, { params }) => DataSelectors.getSubmissionById(state, { params }),
  submission => {
    return [R.pathOr(null, ["related_order", "id"])(submission)];
  }
)(getKey);

export const getLineItemIds = makeInstanceSelector(
  (state, { params }) => DataSelectors.getSubmissionById(state, { params }),
  submission => {
    return R.compose(
      R.map(R.prop("id")),
      R.pathOr([], ["related_order", "line_items"])
    )(submission);
  }
)(getKey);

export const getLineItemIdsByBlockId = makeInstanceSelector(
  blocks,
  lineItems,
  (blocks, lineItems) => {
    return R.compose(block => {
      const blockLineItems = R.flatten(
        R.map(
          variant =>
            R.propOr([], R.path(["variant", "id"], variant), lineItems),
          block.items
        )
      );
      return R.map(R.prop("id"), blockLineItems);
    }, R.head)(blocks);
  }
)(getKey);

export const getVariantIdsByBlockId = makeInstanceSelector(blocks, blocks => {
  return R.compose(block => {
    return R.map(R.path(["variant", "id"]), block.items);
  }, R.head)(blocks);
})(getKey);

export const getBlocksWithLineItems = makeInstanceSelector(
  formatAmountForEvent,
  blocks,
  lineItems,
  (formatAmountForEvent, blocks, lineItems) =>
    R.map(block => {
      const variants = R.filter(
        R.compose(R.not, R.isNil),
        R.map(variant => {
          const lines = R.propOr(
            [],
            R.path(["variant", "id"], variant),
            lineItems
          );
          if (!R.isEmpty(lines)) {
            return {
              ...variant,
              summary: {
                requested: sumRequested(lines),
                approved: sumApproved(lines),
                rejected: sumRejected(lines),
                pending: sumPending(lines),
                price: formatAmountForEvent(R.pathOr(0, [0, "price"])(lines)),
                totalPrice: formatAmountForEvent(sumPrice(lines))
              }
            };
          }
          return null;
        }, block.items)
      );
      const pendingReview = R.sum(
        R.map(R.pathOr(0, ["summary", "pending"]), variants)
      );
      const blockLineItems = R.flatten(
        R.map(
          variant =>
            R.propOr([], R.path(["variant", "id"], variant), lineItems),
          block.items
        )
      );

      return {
        ...block,
        variants,
        pendingReview: pendingReview > 0,
        lineItemIds: R.map(R.prop("id"), blockLineItems),
        pendingLineItemIds: R.map(
          R.prop("id"),
          R.filter(R.propEq("approval_status", "pending"), blockLineItems)
        )
      };
    }, blocks)
)(getKey);

export const getTabs = makeInstanceSelector(
  (_, props) => props.readOnly,
  readOnly =>
    [
      { id: "summary", title: "Summary" },
      readOnly ? null : { id: "overview", title: "Manage" }
    ].filter(o => o)
)(getKey);

export const getIsManageView = makeInstanceSelector(
  TabGetters.selectedTab,
  blocks,
  (selectedId, blocks) =>
    selectedId === "overview" || R.any(R.propEq("id", selectedId), blocks)
)(getKey);
