/* eslint-disable no-underscore-dangle */

import * as R from "ramda";

import { createSelector } from "reselect";

import { getters } from "EventLight/Agenda/Manage";
import { getters as TableGetters } from "ui-kit/Table/model";

import { eventDetails } from "redux/modules/event/selectors";
import { userPermissionProfile } from "redux/modules/permissions/user-permission-profile/selectors";
import { canUserDo } from "redux/modules/permissions/user-permission-profile/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";
import { getGroupUrl } from "EventLight/Common/Manage/utils";

import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";
import getVisibleModules from "components/Event/utils/get-visible-modules";
import { camelcase } from "utils/General";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

import { STATUS, TABLE_INSTANCE_ID } from "../constants";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

const sessionTabs = [
  {
    title: "All Sessions",
    id: STATUS.ALL
  },
  {
    title: "Upcoming",
    id: STATUS.UPCOMING
  },
  {
    title: "Past",
    id: STATUS.PAST
  }
];

export const formatValue = (fieldId, value) => {
  return {
    [camelcase(fieldId)]: R.propOr(value, fieldId)({
      ["starts_at"]: R.path(["iso"])(value),
      ["ends_at"]: R.path(["iso"])(value)
    })
  };
};

// pagination
export const getPageSize = R.compose(
  R.prop("pageSize"),
  getters.pagination
);

export const getCurrentPage = R.compose(
  R.prop("page"),
  getters.pagination
);

// filters: fields
export const getFieldFilters = createSelector(
  getters.columns,
  getters.moduleId,
  getters.preferences,
  (fields, moduleId, preferences) => {
    const readOnlyFields = resolveReadOnlyFields({
      for: "filter",
      moduleId
    });
    return R.compose(
      R.sortBy(f => preferences.sort_by[f.id]),
      R.filter(
        f =>
          !f.settings.allowMultipleSelect &&
          !readOnlyFields.includes(f.id) &&
          preferences.visible_fields.includes(f.id)
      )
    )(fields);
  }
);
export const getFieldFiltersSelectedCount = createSelector(
  getters.preferences,
  R.pathOr(0, ["filters", "filters", "filters", "length"])
);

export const getSelectedFieldFilters = createSelector(
  getFieldFilters,
  getters.preferences,
  (fields, preferences) => {
    const filters = R.pathOr({}, ["filters", "filters"])(preferences);
    if (filters.filters) {
      filters.filters.map(filter => {
        filter.title = R.compose(
          R.prop("name"),
          R.find(R.propEq("id", filter.fieldId))
        )(fields);
        return filter;
      });
    }
    return filters;
  }
);

// filters: bar
const getItemFiltersOn = createSelector(
  state => getters.preferences(state).filters,
  (...args) =>
    R.any(
      R.compose(
        R.not,
        val => (typeof val === "boolean" ? !val : R.isEmpty(val))
      ),
      args
    )
);

export const getFiltersOn = createSelector(
  getItemFiltersOn,
  (...args) => R.any(R.identity, args)
);

// filters: sort by
export const getSortBy = createSelector(
  getters.columns,
  state => getters.preferences(state).sort_by,
  (fields, sortBy) =>
    R.compose(
      R.filter(R.prop("name")),
      R.map(s => ({
        ...s,
        name: R.compose(
          R.prop("name"),
          R.find(R.propEq("id", s.fieldId))
        )(fields)
      }))
    )(sortBy)
);

// tabs
export const getTabs = createSelector(
  getters.tabStats,
  stats => {
    const tabs = sessionTabs;

    return tabs.map(tab => ({
      ...tab,
      title: R.isNil(stats[tab.id])
        ? tab.title
        : `${tab.title} (${stats[tab.id]})`
    }));
  }
);

// sidebar: links
export const isActive = (
  _,
  { routes = [], params = {}, activeRoutes = [], activeParams = {} }
) => {
  if (R.any(r => R.any(R.equals(r.name), activeRoutes), routes)) {
    if (activeParams) {
      return R.all(
        R.equals(true),
        R.map(([key, val]) => {
          if (val === null) {
            return !R.has(key, params) || R.isNil(R.prop(key, params));
          }
          return params[key] === val;
        }, Object.entries(activeParams))
      );
    }
    return true;
  }
  return false;
};

export const isOpened = (state, props) =>
  R.contains(props.id, getters.openedMenues(state, props));

// sidebar: navigation
const getManageUrl = createSelector(
  getEventId,
  getters.moduleId,
  getGroupUrl,
  (eventId, moduleId, groupUrl) => (view, base = "/passes", search = "") =>
    `/event/${eventId}/module/${moduleId}${groupUrl}${base}${view}${search}`
);

const getReturnUrl = createSelector(
  R.path(["location", "pathname"]),
  R.path(["location", "search"]),
  (path, search) => `returnTo=${path}${search}`
);

const getEnabledModules = createSelector(
  eventDetails,
  state => {
    return userPermissionProfile(state, getEventId(state), getUserId(state));
  },
  (eventDetails, userPermissionProfile) => {
    const modules = getVisibleModules(
      userPermissionProfile,
      eventDetails.enabled_modules
    );
    return modules;
  }
);

export const getManageLinks = createSelector(
  getManageUrl,
  eventDetails,
  getEnabledModules,
  state => canUserDo(state, getEventId(state)),
  getReturnUrl,
  getEventId,
  getters.moduleId,
  (
    getUrl,
    eventDetails,
    enabledModules,
    canDo,
    returnTo,
    eventId,
    moduleId
  ) => {
    const canManageApprovers = canDo(`${moduleId}_manage`);

    return [];

    /*
    return [
      {
        id: "settings",
        name: "Settings",
        enabled: true,
        links: [
          {
            id: "edit-form",
            name: "Edit Form",
            enabled: true,
            to: `/event/${eventId}/module/${moduleId}/form/${formId}`
          },
          {
            id: "manageApprovers",
            name: "Manage Approvers",
            enabled: canManageApprovers,
            to: `/event/${eventId}/settings/module/${moduleId}/approval-workflows`
          }
        ].filter(link => link.enabled)
      }
    ].filter(link => link.enabled);
    */
  }
);

export const getToggledRows = createSelector(
  state =>
    TableGetters.toggledRows(state, {
      instanceId: TABLE_INSTANCE_ID
    }),
  R.identity
);

export const getCountOfToggledRows = createSelector(
  getToggledRows,
  R.length
);

export const getFieldsForEditModal = createSelector(
  getters.columns,
  getters.moduleId,
  getters.preferences,
  (fields, moduleId, preferences) => {
    const readOnlyFields = resolveReadOnlyFields({
      moduleId
    });
    return R.compose(
      R.sortBy(f => preferences.field_order[f.id]),
      R.filter(
        f =>
          !f.settings.isReferenceField &&
          preferences.visible_fields.includes(f.id) &&
          ![
            "approval",
            "document-request",
            "item-group",
            "payment",
            "primary-contacts",
            "allocated-passes",
            "allocated-meals",
            "signature"
          ].includes(f.type) &&
          ![STANDARD_FIELD_IDS.ACCOUNTS.NAME].includes(f.id) &&
          !readOnlyFields.includes(f.id)
      )
    )(fields);
  }
);

export const getToggledRowsWithName = createSelector(
  getters.rows,
  getToggledRows,
  getters.moduleId,
  (rows, toggledRows, moduleId) =>
    R.compose(
      R.map(row => ({
        id: row.id,
        name:
          moduleId === STANDARD_MODULE_IDS.accounts.id
            ? R.pathOr("(No Name)", [
                "values",
                STANDARD_FIELD_IDS.ACCOUNTS.NAME,
                "value"
              ])(row)
            : "(No Name)"
      })),
      R.filter(row => toggledRows.includes(row.id))
    )(rows)
);

export const getActiveViewId = createSelector(
  getters.preferences,
  R.prop("id")
);

export const getHasViewChanged = createSelector(
  getters.preferences,
  getters.originalPreferences,
  (preferences, originalPreferences) =>
    !R.equals(preferences, originalPreferences)
);

export const getSearchbarPlaceholder = createSelector(
  getters.moduleId,
  moduleId =>
    R.propOr("Search", moduleId)({
      [STANDARD_MODULE_IDS.accounts.id]: "Search by name",
      [STANDARD_MODULE_IDS.contacts.id]: "Search by name or email"
    })
);

export const getGroupedByField = createSelector(
  getters.preferences,
  R.propOr(null, "grouped_by")
);

export const availableFieldsToGroupBy = createSelector(
  getters.columns,
  fields =>
    R.compose(
      R.sortBy(
        R.compose(
          R.toLower,
          R.prop("name")
        )
      )
    )(fields)
);

export const selectedGroupByFieldName = createSelector(
  getters.columns,
  getGroupedByField,
  (fields, selectedFieldId) =>
    !R.isEmpty(selectedFieldId)
      ? R.propOr("", "name", R.find(R.propEq("id", selectedFieldId))(fields))
      : ""
);

export const getGroupByOptions = createSelector(
  R.identity,
  () => {
    return [
      {
        id: "day",
        name: "Day",
        selected: false
      },
      {
        id: "day",
        name: "Day",
        selected: false
      }
    ];
  }
);

export * from "./tableActions";
