import React from "react";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import Body from "components/Global/Modal/Layout/ScrollableBody";
import Stepper from "./Stepper";
import SetupScene from "./Scenes/Setup";
import ReviewScene from "./Scenes/Review";
import ConfirmScene from "./Scenes/Confirm";
import SentScene from "./Scenes/Sent";
import Footer from "./Footer";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as SEND_TO_OPTION_TYPES from "SendEmailModal/utils/send-to-option-types";
import * as STANDARD_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

import { getters, actions } from "SendEmailModal";
import { getIsCurrentStateValid } from "SendEmailModal/selectors";
import Loading from "ui-kit/Loading";
import { debounce } from "lodash";
import { MODES } from "../constants";

const buildSendToOptions = ({ module, mode }) => {
  const options = [];

  // case: orders
  if (module.id === STANDARD_MODULE_IDS.orders.id) {
    options.push({
      optionType: SEND_TO_OPTION_TYPES.ORDER_CUSTOMER,
      optionName: "Order Customer"
    });
  }

  // case: accounts
  if (module.id === STANDARD_MODULE_IDS.accounts.id) {
    options.push(
      {
        optionType: SEND_TO_OPTION_TYPES.ACCOUNT_PRIMARY_CONTACTS,
        optionName: "Primary Contacts"
      },
      {
        optionType: SEND_TO_OPTION_TYPES.ACCOUNT_CONTACT_USERS,
        optionName: "Group Users"
      }
    );
  }

  // case: not accounts
  if (module.id !== STANDARD_MODULE_IDS.accounts.id) {
    module.fields.forEach(field => {
      /*
      if (field.type === 'lookup' && field.settings.moduleId === STANDARD_MODULE_IDS.accounts.id) {
        options.push({
          optionType: SEND_TO_OPTION_TYPES.ACCOUNT_LOOKUP_PRIMARY_CONTACTS,
          optionName: `${field.name} (Account Primary Contacts)`,
          fieldId: field.id
        }, {
          optionType: SEND_TO_OPTION_TYPES.ACCOUNT_LOOKUP_CONTACT_USERS,
          optionName: `${field.name} (Account Contact Users)`,
          fieldId: field.id
        });
      }
      */

      if (mode === MODES.EMAIL) {
        if (
          field.type === "lookup" &&
          field.settings.moduleId === STANDARD_MODULE_IDS.contacts.id
        ) {
          options.push({
            optionType: field.id,
            optionName: `${field.name} (Person)`,
            fieldId: field.id
          });
        } else if (field.type === "email") {
          options.push({
            optionType: field.id,
            optionName: `${field.name} (Email)`,
            fieldId: field.id
          });
        }
      } else if (mode === MODES.SMS) {
        if (field.id === STANDARD_FIELD_IDS.CONTACTS.MOBILE_PHONE) {
          options.push({
            optionType: field.id,
            optionName: `${field.name}`,
            fieldId: field.id
          });
        }
      }
    });
  }

  return options.map(o => ({
    ...o,
    label: o.optionName,
    value: `${o.optionType}`
  }));
};

const getScene = ({
  refreshPreviews,
  handleNextStep,
  updateMessage,
  updateTemplateTitle,
  updateSubject,
  updateReplyTo,
  updateInvoiceDueDate,
  updateInvoiceNotes,
  updateAttachments,
  toggleTemplate,
  onOptionSelect,
  toggleGroupByRecipient,
  //
  mode,
  module,
  messagePreviews,
  records,
  moduleId,
  mergeTags,
  emailType,
  eventDetails,
  //
  activeStepIndex,
  message,
  newTemplate,
  subject,
  replyTo,
  templateTitle,
  invoiceNotes,
  invoiceDueDate,
  attachments,
  generatingPreviews,
  sendingEmails,
  selectedOptions,
  groupByRecipient
}) => {
  switch (activeStepIndex) {
    case 1:
      return (
        <ReviewScene
          mode={mode}
          moduleId={moduleId}
          refresh={refreshPreviews}
          refreshing={generatingPreviews}
          eventId={eventDetails.id}
          recipients={messagePreviews}
        />
      );
    case 2:
      return (
        <ConfirmScene
          mode={mode}
          recipients={messagePreviews}
          onConfirm={handleNextStep}
          sending={sendingEmails}
        />
      );
    case 3:
      return <SentScene mode={mode} recipients={messagePreviews} />;
    case 0:
    default:
      return (
        <SetupScene
          moduleId={moduleId}
          mode={mode}
          records={records}
          updateMessage={(message, messagePlainText) =>
            updateMessage({ message, messagePlainText })
          }
          updateTemplateTitle={updateTemplateTitle}
          updateSubject={updateSubject}
          updateReplyTo={updateReplyTo}
          updateInvoiceDueDate={updateInvoiceDueDate}
          updateInvoiceNotes={updateInvoiceNotes}
          updateAttachments={updateAttachments}
          toggleTemplate={toggleTemplate}
          attachments={attachments}
          message={message}
          newTemplate={newTemplate}
          subject={subject}
          replyTo={replyTo}
          templateTitle={templateTitle}
          onOptionSelect={onOptionSelect}
          options={buildSendToOptions({ module, mode })}
          selectedOptions={selectedOptions}
          toggleGroupByRecipient={toggleGroupByRecipient}
          groupByRecipient={groupByRecipient}
          mergeTags={mergeTags}
          emailType={emailType}
          invoiceNotes={invoiceNotes}
          invoiceDueDate={invoiceDueDate}
        />
      );
  }
};

const decorate = connect(
  state => ({
    isCurrentStateValid: getIsCurrentStateValid(state),
    loading: getters.loading(state),
    mode: getters.mode(state),
    bodyRef: getters.bodyRef(state),
    moduleId: getters.moduleId(state),
    records: getters.records(state),
    activeStepIndex: getters.activeStepIndex(state),
    generatingPreviews: getters.generatingPreviews(state),
    sendingEmails: getters.sendingEmails(state),
    selectedOptions: getters.selectedOptions(state),
    subject: getters.subject(state),
    message: getters.message(state),
    replyTo: getters.replyTo(state),
    emailType: getters.emailType(state),
    attachments: getters.attachments(state),
    newTemplate: getters.newTemplate(state),
    templateTitle: getters.templateTitle(state),
    invoiceDueDate: getters.invoiceDueDate(state),
    invoiceNotes: getters.invoiceNotes(state),
    groupByRecipient: getters.groupByRecipient(state),
    messagePreviews: getters.messagePreviews(state)
  }),
  {
    handleBackStep: actions.handleBackStep,
    handleNextStep: actions.handleNextStep,
    refreshPreviews: actions.refreshPreviews,
    setBodyRef: actions.setBodyRef,
    updateMessage: actions.updateMessage,
    updateTemplateTitle: actions.setTemplateTitle,
    updateSubject: actions.setSubject,
    updateReplyTo: val => actions.setReplyTo(val || ""),
    updateInvoiceDueDate: actions.setInvoiceDueDate,
    updateInvoiceNotes: val => actions.setInvoiceNotes(val || ""),
    updateAttachments: actions.setAttachments,
    toggleTemplate: actions.toggleTemplate,
    onOptionSelect: actions.onOptionSelect,
    toggleGroupByRecipient: actions.toggleGroupByRecipient
  }
);

const Layout = ({
  handlers,
  hideModal,
  //,
  handleBackStep,
  handleNextStep,
  refreshPreviews,
  setBodyRef,
  updateMessage,
  updateTemplateTitle,
  updateSubject,
  updateReplyTo,
  updateInvoiceDueDate,
  updateInvoiceNotes,
  updateAttachments,
  toggleTemplate,
  onOptionSelect,
  toggleGroupByRecipient,
  //
  isCurrentStateValid,
  loading,
  mode,
  moduleId,
  records,
  activeStepIndex,
  generatingPreviews,
  sendingEmails,
  selectedOptions,
  subject,
  message,
  replyTo,
  emailType,
  attachments,
  newTemplate,
  templateTitle,
  invoiceDueDate,
  invoiceNotes,
  groupByRecipient,
  messagePreviews,
  //
  module,
  mergeTags,
  eventDetails
}) => {
  const entityType =
    mode === MODES.SMS ? "SMS" : emailType === "invoice" ? "Invoice" : "Email";
  const [newValue, setNewValue] = React.useState(message);

  const debouncedUpdateMessage = debounce(updateMessage, 1500);

  const onEditorChange = ({ message, messagePlainText }) => {
    setNewValue(message);
    debouncedUpdateMessage({ message, messagePlainText });
  };

  return (
    <StyleWrapper
      bodyStyles={{ padding: 0 }}
      hideModal={hideModal}
      heading={`Send ${entityType}`}
      width={1100}
    >
      <div styleName="progressHeader">
        <Stepper
          steps={[`Setup ${entityType}`, "Review", "Send"]}
          activeStepIndex={activeStepIndex}
        />
      </div>
      <Body ref={setBodyRef} style={{ height: 400 }}>
        {loading ? (
          <Loading />
        ) : (
          getScene({
            refreshPreviews,
            handleNextStep,
            updateMessage: onEditorChange,
            updateTemplateTitle,
            updateSubject,
            updateReplyTo,
            updateInvoiceDueDate,
            updateInvoiceNotes,
            updateAttachments,
            toggleTemplate,
            onOptionSelect,
            toggleGroupByRecipient,
            //
            mode,
            module,
            messagePreviews,
            records,
            moduleId,
            mergeTags,
            emailType,
            eventDetails,
            //
            activeStepIndex,
            message: newValue,
            newTemplate,
            subject,
            replyTo,
            templateTitle,
            invoiceNotes,
            invoiceDueDate,
            attachments,
            generatingPreviews,
            sendingEmails,
            selectedOptions,
            groupByRecipient
          })
        )}
      </Body>
      <div styleName="footer">
        <Footer
          mode={mode}
          fetching={generatingPreviews}
          sending={sendingEmails}
          isCurrentStateValid={isCurrentStateValid}
          activeStepIndex={activeStepIndex}
          handleNextStep={handleNextStep}
          handleBackStep={handleBackStep}
          hideModal={hideModal}
          onDone={handlers.onDone}
        />
      </div>
    </StyleWrapper>
  );
};

export default decorate(CSSModules(Layout, css));
