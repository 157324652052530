import PropTypes from "prop-types";
import React from "react";
import EditorBase from "./TableConnector";
import Time from "components/Global/Editors/TimeEditor";

export default class TimeEditor extends EditorBase {
  render() {
    return (
      <Time {...this.props} value={this.state} onChange={this.handleChange} />
    );
  }
}

TimeEditor.propTypes = {
  ...EditorBase.propTypes,
  showTodayAsDefault: PropTypes.bool
};
