import React, { Component } from "react";
import { connect } from "react-redux";
import PillButton from "components/Global/CRM/PillButton";
import { bindInstance } from "redux-mvc";

import { getValue, getSettings } from "../../utils";

import { actions } from "../../model";

import { ROW_ACTIONS } from "Organizations/Events/constants";

const decorate = connect(
  null,
  bindInstance({
    execute: actions.executeAction
  })
);

class EventName extends Component {
  viewEvent = () => {
    const isLight = getValue(this.props, "is_light");
    const id = getValue(this.props, "id");

    if (isLight) {
      window.location = `/event-light/${id}/dashboard`;
      return true;
    }

    const { data: row, field: column } = this.props;
    this.props.execute({
      actionId: ROW_ACTIONS.OPEN_EVENT_MODAL,
      row,
      column
    });

    return null;
  };

  render() {
    const props = this.props;

    const name = getValue(props, "name");

    const big = getSettings(props, "big", false);
    const showButton = getSettings(props, "showButton", false);
    const isFieldReadOnly = getSettings(props, "readOnly");
    const suppressLinkToRecord = getSettings(
      props,
      "suppressLinkToRecord",
      false
    );

    return (
      <div
        className="hoverContainer"
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%"
        }}
      >
        {!suppressLinkToRecord && !isFieldReadOnly ? (
          <div
            title={name}
            onClick={this.viewEvent}
            style={{
              fontSize: big ? "18px" : "16px",
              fontWeight: 600,
              color: "rgb(130, 61, 209)",
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer"
            }}
          >
            {name}
          </div>
        ) : (
          <div
            title={name}
            style={{
              fontSize: big ? "18px" : "16px",
              fontWeight: 600,
              color: "#000",
              width: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            {name}
          </div>
        )}
        {showButton && !isFieldReadOnly ? (
          <div
            className="showOnHover"
            style={{
              position: "absolute",
              right: 3,
              top: "50%",
              transform: "translateY(-50%)"
            }}
          >
            <PillButton title="View Event" onClick={this.viewEvent} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default decorate(EventName);
