import getValue from "../get-value/account-list";
import toString from "../to-string/account-list";
import sortEmpty from "./sort-empty";

export default {
  ASC: sortEmpty("account-list", (a, b) =>
    toString(getValue(a))
      .toLowerCase()
      .localeCompare(toString(getValue(b)).toLowerCase())
  ),
  DESC: sortEmpty("account-list", (a, b) =>
    toString(getValue(b))
      .toLowerCase()
      .localeCompare(toString(getValue(a)).toLowerCase())
  )
};
