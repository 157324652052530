import { get } from "lodash";
import getValue from "utils/value-types/get-value";

export default (id, values) => {
  const entries = get(values, [id, "email"]);
  if (entries && entries.length) {
    const { value } = entries[0];
    return getValue(value, value.type);
  }
  return "";
};
