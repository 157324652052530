import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import * as snackbarActions from "redux/modules/snackbar/actions";
import * as modalActions from "redux/modules/modal/actions";

import * as EventSelectors from "redux/modules/event/selectors";
import * as RelatedRecordsActions from "redux/modules/modules/relatedRecords/actions";
import * as RelatedRecordsSelectors from "redux/modules/modules/relatedRecords/selectors";
import * as UserSelectors from "redux/modules/user/selectors";

import { addReference } from "redux/modules/entityReferences/actions";
import { references } from "redux/modules/entityReferences/selectors";

import { deleteRecord } from "redux/modules/modules/records/actions";

import View from "./View";

function mapStateToProps(state, props) {
  return {
    eventDetails: EventSelectors.eventDetails(state),
    isFetching: RelatedRecordsSelectors.isFetching(
      state,
      props.recordId,
      props.relatedModule.id
    ),
    records: RelatedRecordsSelectors.records(
      state,
      props.recordId,
      props.relatedModule.id
    ),
    user: UserSelectors.user(state),
    references: references(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...snackbarActions,
      ...modalActions,
      ...RelatedRecordsActions,
      deleteRecord,
      addReference
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(View);

// withRouter();
