import sortValue from "utils/value-types/sort";

export default ({ sortByField, sortByDirection, references, eventDetails }) => {
  const direction = sortByDirection;
  if (sortByField) {
    return (a, b) =>
      sortValue(sortByField.type)[direction](
        a.values[sortByField.id],
        b.values[sortByField.id],
        {
          references,
          eventDetails
        }
      );
  }

  return () => 0;
};
