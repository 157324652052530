import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import {
  Div,
  MediumOutlineButton,
  DownIcon,
  PopMenu,
  PopMenuCard,
  Text2,
  Text0,
  BigOutlineButton
} from "components/Base";
import { getters } from "Orders/TicketsPasses";
import { PASSES_COLUMNS_WIDTH } from "Orders/TicketsPasses/constants";
import EditPricesPopOver from "./EditPricesPopOver";

const formatPrice = pricesLength => {
  if (pricesLength === 0) {
    return "No price";
  } else if (pricesLength === 1) {
    return "1 Price";
  }
  return `${pricesLength} Prices`;
};

const decorate = connect(state => ({
  pricesTemp: getters.pricesTemp(state),
  credentialsFilter: getters.credentialsFilter(state)
}));

const PriceSelectPopOver = ({ prices, id }) => (
  <PopMenu
    Label={({ onClick }) => (
      <MediumOutlineButton
        onClick={onClick}
        RightIcon={DownIcon}
        ml={2}
        width={PASSES_COLUMNS_WIDTH.PRICE}
      >
        {formatPrice(R.length(prices))}
      </MediumOutlineButton>
    )}
  >
    {({ closeMenu }) => (
      <PopMenuCard position="bottom" align="right">
        <Div
          bra={1}
          shadow={1}
          width={250}
          p={4}
          bg="white"
          style={{ overflow: "visible" }}
        >
          <Text2 bold color="neutral5" mb={2}>
            PRICES
          </Text2>
          <Div width={1} px={4}>
            {R.map(
              price => (
                <Div>
                  <Text2 bold color="black">
                    {price.name}
                  </Text2>
                  <Text0 color="neutral5">{`$${price.amount}`}</Text0>
                </Div>
              ),
              prices
            )}
          </Div>
          <Div display="row" width={1} mt={2}>
            <BigOutlineButton mr={2} onClick={closeMenu}>
              Cancel
            </BigOutlineButton>
            <EditPricesPopOver id={id} />
          </Div>
        </Div>
      </PopMenuCard>
    )}
  </PopMenu>
);

export default decorate(PriceSelectPopOver);
