import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";

import { get } from "lodash";
import * as Selectors from "redux/modules/formsV2/form/selectors";
import { intakeDetails } from "redux/modules/portal/intakeDetails/selectors";

import * as FormActions from "redux/modules/formsV2/form/actions";
import * as ModalActions from "redux/modules/modal/actions";
import * as SnackbarActions from "redux/modules/snackbar/actions";
import { addRecord } from "redux/modules/modules/records/actions";
import { addAssignedForms as addContactAssignedForms } from "redux/modules/contacts/assignedForms/actions";
import { addAssignedForms as addAccountAssignedForms } from "redux/modules/accounts/assignedForms/actions";
import { getIntakeDetails } from "redux/modules/portal/intakeDetails/actions";
import { addAccountUser } from "redux/modules/accounts/users/actions";
import { updatePortalUser } from "redux/modules/portal/user/actions";

function mapStateToProps(state) {
  const intakeDetailsToPass = intakeDetails(state);
  const form = get(intakeDetailsToPass, "permission_set.intake_form", {});

  return {
    form: Selectors.form(state),
    isFetching: Selectors.isFetching(state),
    errorMessages: [state.formsV2.form.error].filter(e => e),
    intakeDetails: intakeDetailsToPass,
    isAccountIntakeForm: intakeDetailsToPass.type === "account",
    isContactIntakeForm: intakeDetailsToPass.type === "contact",
    form
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updatePortalUser,
      addAccountUser,
      getIntakeDetails,
      addContactAssignedForms,
      addAccountAssignedForms,
      addRecord,
      ...FormActions,
      ...ModalActions,
      ...SnackbarActions
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Controller);
