import React from "react";
import { mapProps, withProps } from "utils/General";
import { Div } from "components/Base";

const DivisorTemplate = ({
  direction,
  boxRatio,
  box1Children,
  box2Children,
  box1Props,
  box2Props,
  wrapperProps
}) => (
  <Div display={`${direction}.flex-start.stretch`} {...wrapperProps}>
    <Div width={boxRatio} display={"column.flex-start.stretch"} {...box1Props}>
      {box1Children}
    </Div>
    <Div
      width={1 - boxRatio}
      display={"column.flex-start.stretch"}
      {...box2Props}
    >
      {box2Children}
    </Div>
  </Div>
);

const RowDivisorTemplate = mapProps(
  ({ leftChildren, rightChildren, rightProps, leftProps, ...props }) => ({
    direction: "row",
    box1Children: leftChildren,
    box2Children: rightChildren,
    box1Props: leftProps,
    box2Props: rightProps,
    ...props
  })
)(DivisorTemplate);

const ColumnDivisorTemplate = mapProps(
  ({ topChildren, bottomChildren, topProps, bottomProps, ...props }) => ({
    direction: "column",
    box1Children: topChildren,
    box2Children: bottomChildren,
    box1Props: topProps,
    box2Props: bottomProps,
    ...props
  })
)(DivisorTemplate);

const QuarterLeftTemplate = withProps({ boxRatio: 1 / 4 })(RowDivisorTemplate);
const QuarterRightTemplate = withProps({ boxRatio: 3 / 4 })(RowDivisorTemplate);
const ThirdLeftTemplate = withProps({ boxRatio: 1 / 3 })(RowDivisorTemplate);
const ThirdRightTemplate = withProps({ boxRatio: 2 / 3 })(RowDivisorTemplate);
const HalfAndHalfTemplate = withProps({ boxRatio: 1 / 2 })(RowDivisorTemplate);

const YFill = withProps({ flex: 1, style: { overflowY: "auto" } })(Div);
const XFill = withProps({ flex: 1, style: { overflowX: "auto" } })(Div);
const Fill = withProps({ flex: 1 })(Div);
const NoShrink = withProps({
  flex: 0,
  style: {
    flexShrink: 0
  }
})(Div);

export {
  QuarterLeftTemplate,
  QuarterRightTemplate,
  ThirdLeftTemplate,
  ThirdRightTemplate,
  HalfAndHalfTemplate,
  YFill,
  XFill,
  Fill,
  NoShrink
};
