import {
  put,
  call,
  take,
  takeEvery,
  all,
  fork,
  select,
  delay
} from "redux-saga/effects";
import * as R from "ramda";
import { getters, actions } from "./model";
import { getEditedField } from "./selectors";

import { registerError } from "redux/modules/errors/actions";
import * as snackbarActions from "redux/modules/snackbar/actions";

import { eventId as getEventId } from "redux/modules/event/selectors";
import { getCredentials } from "redux/modules/user/selectors";

import { actions as FormActions } from "ui-kit/Form/model";
import { actions as TabActions } from "ui-kit/Tabs";
import { actions as SearchBarActions } from "ui-kit/SearchBar";

import { stripHtml } from "utils/General";

import {
  ALERTS_FORM_ID,
  FIELDS_IDS,
  INITIAL_ALERT_DATA,
  SEND_DATE
} from "./constants";
import { camelcaseObjKeys } from "utils/General";
import Api from "./api";

const getParams = function*() {
  const eventId = yield select(getEventId);
  const credentials = yield select(getCredentials);

  return { eventId, credentials };
};

const getSettingsData = function*() {
  yield all([
    put(actions.setData(INITIAL_ALERT_DATA)),
    put(actions.setAlertPreview({}))
  ]);
  yield put(actions.setIsOpen(true));
  const params = yield call(getParams);
  try {
    const { payload: settings } = yield call(Api.getSettings, params);
    yield put(actions.setSettings(settings));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while loading alerts data"
        }
      ])
    );
  }
};

const init = function*() {
  yield put(actions.setLoading(true));
  yield call(getSettingsData);
  yield put(actions.setLoading(false));
};

const fieldUpdate = function*({ id, fieldId }) {
  const newValue = yield select(getEditedField, {
    instanceId: id,
    fieldId
  });

  const processedValue = R.propOr(newValue, fieldId, {
    // [FIELDS_IDS.MESSAGE.CONTENT]: newValue, // R.slice(0, 330, newValue),
    [FIELDS_IDS.MESSAGE.TYPE]: R.head(newValue)
  });

  if (fieldId === FIELDS_IDS.MESSAGE.CONTENT) {
    yield put(
      actions.updateField({
        field: FIELDS_IDS.MESSAGE.CONTENT_TEXT,
        value: stripHtml(newValue)
      })
    );
  }

  yield put(actions.updateField({ field: fieldId, value: processedValue }));
};

const createAlert = function*() {
  yield put(actions.setLoading(true));
  const { credentials, eventId } = yield call(getParams);
  try {
    const data = yield select(getters.data);
    const processedData = {
      ...data,
      sendAt: data.sendAtType === SEND_DATE.NOW ? null : data.sendAt
    };
    const {
      payload: { id: alertId }
    } = yield call(Api.create, {
      credentials,
      data: { ...processedData, eventId }
    });
    yield put(actions.updateField({ field: "alertId", value: alertId }));
    const { payload: alertPreview } = yield call(Api.getPreview, {
      credentials,
      eventId,
      alertId: alertId
    });
    yield put(actions.setAlertPreview(alertPreview));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while creating new alert"
        }
      ])
    );
  }
  yield put(actions.setLoading(false));
};

const editAlert = function*({ payload: alertId }) {
  yield put(actions.setLoading(true));
  yield call(getSettingsData);
  const { credentials, eventId } = yield call(getParams);
  try {
    const { payload: data } = yield call(Api.getAlert, {
      credentials,
      eventId,
      alertId
    });
    const processedData = camelcaseObjKeys(data);
    yield put(actions.initData(processedData));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while fetching the alert"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const saveAlert = function*() {
  const { credentials, eventId } = yield call(getParams);
  try {
    const data = yield select(getters.data);
    const processedData = {
      ...data,
      sendAt: undefined
    };
    yield call(Api.create, {
      credentials,
      data: { ...processedData, eventId }
    });

    yield put(actions.closeModal());

    yield put(
      snackbarActions.showSnackbar({
        message: "Alert Saved"
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while saving alert"
        }
      ])
    );
  }
};

const sendAlert = function*() {
  const { credentials, eventId } = yield call(getParams);
  const data = yield select(getters.data);

  yield put(actions.setSending(true));

  try {
    const initialResult = yield call(Api.sendAlert, {
      credentials,
      eventId,
      alertId: R.prop("alertId", data)
    });

    yield delay(500);

    let progressResult = yield call(Api.getJobStatus, credentials, {
      jobId: initialResult.payload.job_id
    });

    while (["pending", "processing"].includes(progressResult.payload.status)) {
      yield delay(3000);
      progressResult = yield call(Api.getJobStatus, credentials, {
        jobId: initialResult.payload.job_id
      });
    }

    yield put(actions.closeModal());
    yield put(
      snackbarActions.showSnackbar({
        message: "Alert Sent" // "Alert scheduled to send"
      })
    );
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error occurred sending alert"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setSending(false));
  }
};

const watchOpenModal = function*() {
  yield takeEvery(actions.openModal.type, init);
};

const watchUpdateFields = function*() {
  for (;;) {
    const {
      meta: { instanceId, fieldId }
    } = yield take(FormActions.setFieldValue.type);
    if (instanceId === ALERTS_FORM_ID) {
      yield call(fieldUpdate, {
        id: instanceId,
        fieldId
      });
    }
  }
};

const clearSearch = function*() {
  yield put(SearchBarActions.clearSearch());
};

const watchChangeTab = function*() {
  yield takeEvery(TabActions.setSelectedTab.type, clearSearch);
};

const watchOnCreate = function*() {
  yield takeEvery(actions.previewAlert.type, createAlert);
};

const watchOnEdit = function*() {
  yield takeEvery(actions.editAlert.type, editAlert);
};

const watchOnSendAlert = function*() {
  yield takeEvery(actions.sendAlert.type, sendAlert);
};

const watchOnSaveAlert = function*() {
  yield takeEvery(actions.saveAlert.type, saveAlert);
};

const rootSaga = function*() {
  yield all([
    fork(watchOpenModal),
    fork(watchOnEdit),
    fork(watchOnCreate),
    fork(watchOnSendAlert),
    fork(watchOnSaveAlert),
    fork(watchUpdateFields),
    fork(watchChangeTab)
  ]);
};

export default rootSaga;
