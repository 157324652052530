import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "Event/Zones/constants";

const iniState = {
  loading: false,
  zones: [],
  typesFilter: ""
};

const model = createHandlers({
  iniState,
  reducers: {
    setInitialData: (state, { payload: { zones } }) => ({
      zones
    }),
    init: R.identity,
    fetchZones: R.identity,
    deleteZoneItem: R.identity,
    reorderZoneItems: R.identity,
    moveZoneItemUp: R.identity,
    moveZoneItemDown: R.identity,
    moveZoneCategoryUp: R.identity,
    moveZoneCategoryDown: R.identity
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
