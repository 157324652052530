import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import CollapsablePanel from "components/Global/CRM/CollapsablePanel";
import Button from "components/Global/CRM/ActionButton";

const MESSAGES = {
  unsubscribed: "You’re not receiving notifications from this record",
  subscribed:
    "You’re receiving notifications because you’re subscribed to this record.",
  ignored: "You're ignoring notifications from this record."
};

@CSSModules(css)
class RecordSubscription extends Component {
  componentWillMount() {
    this.fetchSubscription();
  }

  resolveMessage = () => {
    const { subscription } = this.props;
    if (subscription.is_autosubscribed && subscription.is_ignored) {
      return MESSAGES.ignored;
    }
    if (subscription.is_subscribed) {
      return MESSAGES.subscribed;
    }
    return MESSAGES.unsubscribed;
  };

  fetchSubscription = () => {
    const { moduleId, recordId, eventDetails } = this.props;
    this.props.getSubscription({
      moduleId,
      recordId,
      options: {
        eventId: eventDetails.id
      }
    });
  };

  subscribeToRecord = () => {
    const { moduleId, recordId, eventDetails } = this.props;
    this.props
      .updateSubscription({
        moduleId,
        recordId,
        status: "subscribe",
        options: {
          eventId: eventDetails.id
        }
      })
      .then(() => {
        this.fetchSubscription();
      });
  };

  unsubscribeFromRecord = () => {
    const { moduleId, recordId, eventDetails } = this.props;
    this.props
      .updateSubscription({
        moduleId,
        recordId,
        status: "unsubscribe",
        options: {
          eventId: eventDetails.id
        }
      })
      .then(() => {
        this.fetchSubscription();
      });
  };

  render() {
    const { subscription } = this.props;
    const isSubscribed = !!(
      (subscription.is_subscribed || subscription.is_autosubscribed) &&
      !subscription.is_ignored
    );

    return (
      <CollapsablePanel
        collapsedDefault
        title={<div className={css.primaryContacts}>Notifications</div>}
      >
        <div>
          <div styleName="content">{this.resolveMessage()}</div>
          <div styleName="buttonWrapper">
            {isSubscribed ? (
              <Button
                title={
                  <div className={css.button}>
                    <i className={["material-icons", css.icon].join(" ")}>
                      volume_off
                    </i>
                    <span>Unsubscribe</span>
                  </div>
                }
                style={{
                  width: 160
                }}
                onClick={this.unsubscribeFromRecord}
              />
            ) : (
              <Button
                title={
                  <div className={css.button}>
                    <i className={["material-icons", css.icon].join(" ")}>
                      volume_up
                    </i>
                    <span>Subscribe</span>
                  </div>
                }
                style={{
                  width: 160
                }}
                onClick={this.subscribeToRecord}
              />
            )}
          </div>
        </div>
      </CollapsablePanel>
    );
  }
}

RecordSubscription.propTypes = {
  moduleId: PropTypes.string.isRequired,
  recordId: PropTypes.string.isRequired,
  subscription: PropTypes.shape({
    is_autosubscribed: PropTypes.bool,
    is_subscribed: PropTypes.bool,
    is_ignored: PropTypes.bool
  }).isRequired,
  eventDetails: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  getSubscription: PropTypes.func.isRequired,
  updateSubscription: PropTypes.func.isRequired
};

export default RecordSubscription;
