import React from "react";
import * as R from "ramda";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import ScrollableModalBody from "components/Global/Modal/Layout/ScrollableBody";

import { LoadingIcon } from "components/Base";
import {
  Div,
  DarkTab,
  BigFilledButton,
  BigOutlineButton
} from "components/Base";
import Overview from "./Scenes/Overview";

const View = ({
  hideModal,
  mode,
  tabs,
  activeTab,
  loading,
  meal,
  handleFieldChange,
  handleColorSelect,
  canSave,
  handleSave
}) => (
  <StyleWrapper
    heading={`${["create", "clone"].includes(mode) ? "Add" : "Edit"} Meal`}
    hideModal={hideModal}
    bodyStyles={{ padding: 0 }}
    width={501}
  >
    {loading ? (
      <Div pt={20} display="row.center.center">
        <LoadingIcon fs={5} />
      </Div>
    ) : (
      <Div>
        <Div pt={2} px={5} bb={1} bc="gray2" display="row">
          {R.map(({ id, name, active, onClick }) => (
            <DarkTab
              key={id}
              px={2}
              fs={2}
              display="row.center.center"
              active={active}
              onClick={onClick}
            >
              {name}
            </DarkTab>
          ))(tabs)}
        </Div>
        <ScrollableModalBody
          style={{
            minHeight: 400,
            display: "flex",
            backgroundColor: "rgba(224, 224, 224, 0.19)"
          }}
        >
          <Div px={6} py={4} flex={1}>
            {R.prop(activeTab, {
              overview: (
                <Overview
                  onSelectColor={handleColorSelect}
                  meal={meal}
                  onFieldChange={handleFieldChange}
                />
              )
            })}
          </Div>
        </ScrollableModalBody>
        <Div display="flex-start" py={3} px={5}>
          <BigFilledButton
            bg={canSave ? "altB5" : "neutral1"}
            mr={1}
            onClick={canSave ? handleSave : null}
          >
            Save
          </BigFilledButton>
          <BigOutlineButton onClick={hideModal}>Cancel</BigOutlineButton>
        </Div>
      </Div>
    )}
  </StyleWrapper>
);

export default View;
