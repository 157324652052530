import React from "react";
import { Div } from "components/Base";

import CurrentVideo from "./CurrentVideo";
import InterviewSchedule from "./InterviewSchedule";

const LeftSection = ({ ...otherProps }) => (
  <Div {...otherProps}>
    <CurrentVideo />
    <InterviewSchedule />
  </Div>
);

export default LeftSection;
