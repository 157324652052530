import * as R from "ramda";
import { createSelector } from "reselect";

export const groups = R.path(["items", "groups", "groups"]);

export const getGroupsByType = createSelector(
  (_, props) => R.prop("type", props),
  groups,
  (type, groups) => R.filter(R.propEq("type_id", type), groups)
);
