import React, { Component } from "react";
import getValue from "utils/value-types/get-value/credentials";
import CredentialsList from "./List";
import CredentialQuantity from "./QuantityInput";
import { get, isEqual } from "lodash";

class Credential extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  render() {
    const { value, dependentValues } = this.props;

    // ensure we're only handling data of type 'credentials'
    if (get(value, "type") !== "credentials") {
      return null;
    }
    const type = get(dependentValues, "meta.columnSettings.selectType");
    const credentials = get(
      dependentValues,
      "meta.eventDetails.module_settings.credentials.credentials",
      []
    );
    const records = getValue(value).records;

    if (type === "quantity-only-input") {
      return <CredentialQuantity quantity={get(records, ["0", "quantity"])} />;
    }
    return <CredentialsList credentials={credentials} records={records} />;
  }
}

export default Credential;
