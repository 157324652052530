import * as R from "ramda";

import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "./constants";

const iniState = {
  loading: false,
  activityId: "",
  scheduleId: "",
  name: "",
  values: {},
  visibleFields: [],
  moreFields: [],
  showMoreFields: false,
  updatedIds: [],
  dateFields: []
};

const model = createHandlers({
  iniState,
  reducers: {
    cancelInstance: R.always(iniState),
    saveActivity: R.identity,
    deleteActivity: R.identity,
    hideModal: R.identity,
    getActivity: R.identity,
    setInitialData: (state, { payload: { activity, fields, activityId } }) => ({
      name: "Edit Activity",
      values: R.propOr({}, "values", activity),
      visibleFields: R.propOr([], "visible_fields", fields),
      moreFields: R.propOr([], "more_fields", fields),
      activityId
    }),
    setInitialDataNoId: (state, { payload: { fields, values } }) => ({
      name: "Add Activity",
      visibleFields: R.propOr([], "visible_fields", fields),
      moreFields: R.propOr([], "more_fields", fields),
      values
    }),
    toggleMoreFields: state => ({
      showMoreFields: !state.showMoreFields
    }),
    updateValues: (state, { payload: { fieldId, value } }) => {
      return {
        updatedIds: R.compose(
          updatedIds => [...updatedIds, fieldId],
          R.filter(id => id !== fieldId)
        )(state.updatedIds),
        values: {
          ...state.values,
          [fieldId]: value
        }
      };
    }
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
