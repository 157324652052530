import React from "react";
import { Page, Title } from "EventLight/Common/FieldLayout";
import { SPONSORSHIP_TYPE_ID } from "utils/item-types";

import Catalog from "EventLight/Common/Items/Catalog/View";

const Layout = () => (
  <>
    <Page>
      <Title
        icon="storefront"
        title="Sponsorships"
        description="Manage the types of sponsorship levels that sponsors can have (e.g. Gold, Silver, Bronze)"
      />
      <Catalog typeId={SPONSORSHIP_TYPE_ID} />
    </Page>
  </>
);

export default Layout;
