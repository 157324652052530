import React, { Component } from "react";
import getValue from "utils/value-types/get-value/time";
import { isEqual } from "lodash";
import { Div } from "components/Base";

export default class TimeFormatter extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.value, nextProps.value);
  }

  render() {
    const time = getValue(this.props.value);

    return (
      <Div
        truncate
        style={{
          userSelect: "none",
          lineHeight: "34px"
        }}
      >
        {time}
      </Div>
    );
  }
}
