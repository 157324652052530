export const NAMESPACE = "ItemCatalogInventory";

export const VARIANTS_TRACKED = "variantsTracked";

export const TABLE_INSTANCE_ID = "itemCatalogInventoryTable";

export const DROPDOWN_ITEM = "dropdownItem";

export const DROPDOWN_STATUS = "dropdownStatus";

export const ALL_ITEMS_OPTION = "showAllItems";

export const DROPDOWN_STATUS_VALUES = {
  ALL_INVENTORY: "all_inventory",
  ASSIGNED: "assigned",
  UNASSIGNED: "unassigned"
};

export const DEFAULT_COLUMN_WIDTHS = {
  inventory_id: 143,
  category: 143,
  variant_name: 214,
  assigned_to: 165,
  order_number: 72
};
