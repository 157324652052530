import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions } from "Orders/OrderModal";

import {
  getOrderId,
  getStatuses,
  getIntegrationStatuses,
  getPasses,
  getLineItemIds,
  getPendingLineItemIds,
  getIsSubmission
} from "Orders/OrderModal/selectors";

import { Div, Text4, AddIcon, BigFilledButton } from "components/Base";

import { withProps } from "utils/General";

import Statuses from "ui-kit/Statuses";
import ArrayLayout from "ui-kit/ArrayLayout";

const decorate = connect(
  (state, props) => ({
    orderId: getOrderId(state),
    statuses: getStatuses(state),
    integrationStatuses: getIntegrationStatuses(state),
    passes: getPasses(state, { handlers: props.handlers }),
    lineItemIds: getLineItemIds(state),
    pendingLineItemIds: getPendingLineItemIds(state),
    isSubmission: getIsSubmission(state)
  }),
  {
    approveRemaining: actions.onApprove,
    denyRemaining: actions.onDeny
  }
);

const OrderStatusbar = ({
  orderId,
  statuses = [],
  integrationStatuses = [],
  passes = [],
  handlers
}) => {
  const header = [];

  if (!R.isEmpty(passes)) {
    header.push([
      <Text4 bold mr={3}>
        Status:
      </Text4>,
      <Statuses
        display="row"
        statuses={statuses}
        integrationStatuses={integrationStatuses}
      />
    ]);
  }

  if (R.isEmpty(passes)) {
    header.push(
      <BigFilledButton
        onClick={() => handlers.addItems(orderId)}
        LeftIcon={withProps({ size: 18 })(AddIcon)}
        key="addItems"
        bg="primary7"
      >
        Add items to order
      </BigFilledButton>
    );
  }

  return (
    <Div
      bg="white"
      bc="neutral3"
      bs={1}
      brb={1}
      brr={1}
      style={{ overflow: "visible" }}
    >
      <ArrayLayout components={header} py={3} px={8} />
    </Div>
  );
};

export default decorate(OrderStatusbar);
