import { createModule } from "redux-mvc";

import model from "./model";
import sagas from "./sagas";

import tabs from "ui-kit/Tabs";
import table from "ui-kit/Table";
import approvers from "EventLight/Common/Items/Approvers";

const module = createModule({
  ...model,
  observedDomains: ["event", "items", "user"],
  optimist: true
});

module.setRootSaga(sagas);
module.plugModule(tabs);
module.plugModule(table);
module.plugModule(approvers);

export default module;
