import { createModule } from "redux-mvc";

import model from "./model";

import sagasModule from "./sagas";
import searchBarModule from "ui-kit/SearchBar";
import cartModule from "Portal/Cart";
import confirmationModule from "Portal/Confirmation";
import checkoutModule from "Portal/Checkout";
import formModule from "ui-kit/Form";

const module = createModule({
  ...model,
  observedDomains: ["user", "event", "modal", "portal"]
});

module.plugModule(searchBarModule);
module.plugModule(cartModule);
module.plugModule(confirmationModule);
module.plugModule(checkoutModule);
module.plugModule(formModule);

module.setRootSaga(sagasModule);

export default module;
