import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Popover from "@lennd/material-ui/Popover";
import { ArrowRenderer } from "components/Global/Editors/utils/reactSelect";
import { Div, Dropdown } from "components/Base";

@CSSModules(css)
class SortPicker extends Component {
  state = {
    showPopover: false,
    fieldId: this.props.fieldId,
    direction: "ASC"
  };

  onRequestClose = () =>
    this.setState({
      showPopover: false,
      fieldId: null,
      direction: "ASC"
    });

  handleClick = e => {
    e.stopPropagation();
    return this.setState({ showPopover: true });
  };

  handleAdd = () => {
    const { fieldId, direction } = this.state;
    if (!fieldId) return;

    this.props.onChange({
      fieldId,
      direction
    });
    this.onRequestClose();
  };

  handleSelectChange = selected => {
    this.setState({
      fieldId: selected ? selected.value : selected
    });
  };

  handleDirectionChange = selection =>
    this.setState({ direction: selection ? selection.value : selection });

  render() {
    const { fieldId, direction } = this.state;
    const { fields } = this.props;

    return (
      <div>
        <span
          role="link"
          tabIndex="0"
          onClick={this.handleClick}
          ref={ref => {
            this.anchor = ref;
          }}
        >
          {this.props.children}
        </span>
        <Popover
          anchorEl={this.anchor}
          anchorOrigin={{ horizontal: "left", vertical: "top" }}
          onClose={this.onRequestClose}
          open={this.state.showPopover}
          transformOrigin={{ horizontal: "left", vertical: "top" }}
        >
          <div styleName="container">
            <div styleName="heading">Sort by</div>
            <Dropdown
              arrowRenderer={ArrowRenderer}
              onChange={this.handleSelectChange}
              options={fields.map(field => ({
                label: field.name,
                value: field.id
              }))}
              placeholder="Select a field..."
              searchable
              simpleValue
              value={fieldId}
              usePortal
              menuPlacement="auto"
            />
            <Div my={1} />
            <Dropdown
              arrowRenderer={ArrowRenderer}
              clearable={false}
              onChange={this.handleDirectionChange}
              options={[
                {
                  label: "ASC",
                  value: "ASC"
                },
                {
                  label: "DESC",
                  value: "DESC"
                }
              ]}
              placeholder="Select what direction..."
              searchable
              simpleValue
              value={direction}
              usePortal
              menuPlacement="auto"
            />

            <div styleName="buttons">
              <div
                role="link"
                tabIndex="0"
                styleName="save"
                onClick={this.handleAdd}
              >
                Add sort by
              </div>
              <div
                role="link"
                tabIndex="0"
                styleName="cancel"
                onClick={this.onRequestClose}
              >
                Cancel
              </div>
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}

SortPicker.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      id: PropTypes.string
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedFieldId: PropTypes.string
};

export default SortPicker;
