import Helpers from "utils/Global/Helpers";
import getQuery from "redux/modules/modules/utils/get-query";

/* eslint no-underscore-dangle: "off" */

export default {
  getForm: ({ credentials, formId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/form/form/${formId}`,
        credentials,
        success,
        error
      });
    }),
  getFields: ({ credentials, formId, options }) =>
    new Promise((success, error) => {
      const query = getQuery(options);

      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/form/results/${formId}/fields${query || ""}`,
        credentials,
        success,
        error
      });
    }),
  search: (credentials, eventId, orgId, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/form/results/${data.formId}`,
        qs: { eventId, orgId, ...data },
        credentials,
        success,
        error
      });
    }),
  export: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/form/results/${data.formId}/export`,
        qs: { eventId: eventId, ...data },
        credentials,
        success,
        error
      });
    }),
  getViews: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/form/results/${data.formId}/views`,
        qs: { eventId },
        data,
        credentials,
        success,
        error
      });
    }),
  addView: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/form/results/${data.formId}/views`,
        qs: { eventId },
        data,
        credentials,
        success,
        error
      });
    }),
  updateView: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/form/results/${data.formId}/view`,
        qs: { eventId },
        data,
        credentials,
        success,
        error
      });
    }),
  deleteView: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/form/results/${data.formId}/view`,
        qs: { eventId },
        data,
        credentials,
        success,
        error
      });
    }),
  selectView: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/form/results/${data.formId}/active-view`,
        qs: { eventId },
        data,
        credentials,
        success,
        error
      });
    }),
  getActiveView: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/form/results/${data.formId}/active-view`,
        qs: { eventId },
        data,
        credentials,
        success,
        error
      });
    }),
  postSubmission: ({ credentials, data }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/form/form/${data.formId}/submissions`,
        credentials,
        data,
        success,
        error
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  deleteSubmisions: ({ credentials, data, eventId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */

      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/modules/${data.moduleId}/records`,
        credentials,
        data,
        qs: { eventId },
        success,
        error
      });
    }),

  deleteSubmissions: ({ credentials, data }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */

      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/form/submissions`,
        credentials,
        data,
        success,
        error
      });
    }),

  addRecord: ({ credentials, data, eventId }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */

      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/modules/${data.moduleId}/records`,
        credentials,
        data,
        qs: { eventId },
        success,
        error
      });
    }),
  getStatus: (credentials, { jobId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/jobs/progress/${jobId}`,
        credentials,
        success,
        error
      });
    })
};
