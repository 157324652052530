import React from "react";
import { Div } from "components/Base";
import * as R from "ramda";
import { connect } from "react-redux";
import StatusCircle from "ui-kit/StatusCircle";

import { actions, getters } from "../model";

const decorator = connect(
  (state, props) => ({
    courses: getters.courses(state, props),
    currentCourseId: getters.currentCourseId(state, props)
  }),
  {
    executeAction: actions.executeAction,
    setCurrentCourseId: actions.setCurrentCourseId
  }
);

const CoursesList = ({ courses, currentCourseId, setCurrentCourseId }) => (
  <Div>
    {R.map(
      course => (
        <Div
          key={course.id}
          style={{
            padding: 17,
            cursor: "pointer",
            borderRadius: 4
          }}
          display="row.space-between.center"
          bc="warning4"
          bl={currentCourseId === course.id ? 3 : 0}
          bg={{
            default: currentCourseId === course.id ? "neutral1" : "neutral0",
            hover: "neutral1"
          }}
          onClick={() => setCurrentCourseId(course.id)}
        >
          <Div fs={3} fw={3} width={0.85}>
            {course.name}
          </Div>
          <StatusCircle
            status={course.completed ? "task_complete" : "task_not_complete"}
            size={26}
          />
        </Div>
      ),
      courses
    )}
  </Div>
);

export default decorator(CoursesList);
