import { createModule } from "redux-mvc";

import model from "./model";

import sagasModule from "Portal/PortalPage/sagas";

import tabsModule from "ui-kit/Tabs";

const module = createModule(model);
module.plugModule(tabsModule);

module.setRootSaga(sagasModule);

export default module;
