import React from "react";
import DocumentTitle from "components/Global/DocumentTitle";
import CSSModules from "react-css-modules";
import css from "../shared/styles.scss";

const View = CSSModules(
  ({ resendLink }) => (
    <DocumentTitle title="Sign Up">
      <div styleName="container">
        {/* logo */}
        <div styleName="logoWrapper">
          <img
            src="https://d2dks4tzxs6xee.cloudfront.net/img/Lennd-white-login.png"
            width="154px"
          />
        </div>

        <div styleName="content">
          <div styleName="wrapper">
            <div styleName="left">
              <div>
                <div styleName="title">Check Your Email</div>

                <div>
                  We've sent an confirmation message to you. Please check your
                  email and click on the link in the message to complete setting
                  up your account. Once you confirm your email address, you can
                  continue setting up your event.
                  <p>
                    If you haven't received our email in 15 minutes, please
                    check your spam folder. If you still haven't received it
                    after that time, you can{" "}
                    <a styleName="resendConfirmation" href={resendLink}>
                      re-send it here
                    </a>
                    .
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DocumentTitle>
  ),
  css
);

export default View;
