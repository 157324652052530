import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "ui-kit/Table/constants";

import { LIST_INDEX } from "./constants";

const iniState = {
  meta: {},
  rows: {},
  rowIndex: {
    [LIST_INDEX]: [{ id: LIST_INDEX, list: [] }]
  },
  columns: {},
  columnIndex: [],
  editing: { columnId: "", rowId: "" },
  toggledRows: [],
  columnWidths: {},
  columnDelta: {},
  activeIndex: LIST_INDEX,
  canEditCells: true,
  editableFieldsWhiteList: [],
  editableFieldsBlackList: []
};

const updateRowIndex = ({
  groups,
  group,
  oldRowId,
  newRowId,
  delta = 0,
  operation = "update"
}) => {
  const groupIndex = R.findIndex(R.propEq("id", group.id), groups);
  const position = R.findIndex(R.equals(oldRowId), group.list) + delta;
  const newGroup = {
    ...group,
    list: R.prop(operation, {
      update: () => R.update(position, newRowId, group.list),
      insert: () => R.insert(position < 0 ? 0 : position, newRowId, group.list),
      delete: () => R.remove(position, 1, group.list)
    })()
  };

  return R.update(groupIndex, newGroup, groups);
};

const reducers = {
  resizeColumns: R.identity,
  saveValue: R.identity,

  setData: (
    state,
    {
      payload: {
        canEditCells = true,
        rows,
        columns,
        columnWidths,
        activeIndex,
        groupByIndex = {},
        meta = {},
        editableFieldsWhiteList = [],
        editableFieldsBlackList = []
      }
    }
  ) => ({
    canEditCells,
    rows: R.indexBy(R.prop("id"), rows),
    rowIndex: {
      [LIST_INDEX]: [{ id: LIST_INDEX, list: R.map(R.prop("id"), rows) }],
      ...groupByIndex
    },
    columns: R.indexBy(R.prop("id"), columns),
    columnIndex: R.map(R.prop("id"), columns),
    columnWidths: columnWidths || state.columnWidths,
    activeIndex: activeIndex || LIST_INDEX,
    meta,
    editableFieldsBlackList,
    editableFieldsWhiteList
  }),
  toggleRow: ({ toggledRows }, { payload: rowId }) => ({
    toggledRows: R.contains(rowId, toggledRows)
      ? R.without([rowId], toggledRows)
      : R.concat([rowId], toggledRows)
  }),
  clearSelectedRows: R.always({ toggledRows: [] }),
  toggleAllRows: ({ toggledRows, rowIndex }, { payload }) => {
    const groupIds = payload || R.path([LIST_INDEX, 0, "list"], rowIndex);

    const toggledRowsRaw =
      R.length(R.intersection(toggledRows, groupIds)) === R.length(groupIds)
        ? R.without(groupIds, toggledRows)
        : R.concat(toggledRows, groupIds);

    return {
      toggledRows: [...new Set(toggledRowsRaw)]
    };
  },
  duplicateRow: (
    { rows, rowIndex, activeIndex },
    { payload: { newRow, oldRow, delta = 1, group } }
  ) => ({
    rows: R.mergeAll([rows, { [newRow.id]: newRow }]),
    rowIndex: R.mergeAll([
      rowIndex,
      {
        [LIST_INDEX]: updateRowIndex({
          groups: R.prop(LIST_INDEX, rowIndex),
          group: R.head(R.prop(LIST_INDEX, rowIndex)),
          oldRowId: oldRow.id,
          newRowId: newRow.id,
          delta,
          operation: "insert"
        })
      },
      activeIndex !== LIST_INDEX
        ? {
            [activeIndex]: updateRowIndex({
              groups: R.propOr([], activeIndex, rowIndex),
              group,
              oldRowId: oldRow.id,
              newRowId: newRow.id,
              delta,
              operation: "insert"
            })
          }
        : {}
    ])
  }),
  duplicateRowResponse: ({ rows, rowIndex, activeIndex }, action) => {
    const group = R.path(["payload", "group"], action);
    const oldRowId = R.pathOr("", ["optimist", "id"], action);
    const newRowId = R.pathOr("", ["payload", "id"], action);
    const { [oldRowId]: oldRow, ...rest } = rows;

    return {
      rows: R.mergeAll([rest, { [newRowId]: { ...oldRow, id: newRowId } }]),
      rowIndex: R.mergeAll([
        rowIndex,
        {
          [LIST_INDEX]: updateRowIndex({
            groups: R.prop(LIST_INDEX, rowIndex),
            group: R.head(R.prop(LIST_INDEX, rowIndex)),
            oldRowId,
            newRowId,
            operation: "update"
          })
        },
        activeIndex !== LIST_INDEX
          ? {
              [activeIndex]: updateRowIndex({
                groups: R.propOr([], activeIndex, rowIndex),
                group,
                oldRowId,
                newRowId,
                operation: "update"
              })
            }
          : {}
      ])
    };
  },
  deleteRow: (
    { rows, rowIndex, activeIndex },
    { payload: { row, group } }
  ) => ({
    rows: R.omit([row.id], rows),
    rowIndex: R.mergeAll([
      rowIndex,
      {
        [LIST_INDEX]: updateRowIndex({
          groups: R.prop(LIST_INDEX, rowIndex),
          group: R.head(R.prop(LIST_INDEX, rowIndex)),
          oldRowId: row.id,
          operation: "delete"
        })
      },
      activeIndex !== LIST_INDEX
        ? {
            [activeIndex]: updateRowIndex({
              groups: R.propOr([], activeIndex, rowIndex),
              group,
              oldRowId: row.id,
              operation: "delete"
            })
          }
        : {}
    ])
  }),
  deleteRowResponse: R.identity,
  edit: (_, { payload: { columnId, rowId } }) => ({
    editing: { columnId, rowId }
  }),

  saveRequest: (state, { payload: { cancel } = {} }) =>
    cancel
      ? {
          editing: { columnId: "", rowId: "" }
        }
      : state,
  save: (
    { rows, editing: { columnId, rowId } },
    { payload: { value, column, row } }
  ) => {
    return {
      editing: { columnId: "", rowId: "" },
      rows: R.assocPath(
        [rowId || row.id, columnId || column.id],
        {
          type: column.type,
          ...R.pathOr({}, [rowId || row.id, columnId || column.id], rows),
          value
        },
        rows
      )
    };
  },
  saveResponse: R.always({ editing: { columnId: "", rowId: "" } }),
  addColumn: ({ columns, columnIndex }, { payload: { position, column } }) => ({
    columnIndex: R.insert(position, column.id, columnIndex),
    columns: R.mergeAll([columns, { [column.id]: column }])
  }),
  addColumnResponse: R.identity,
  updateColumn: ({ columns }, { payload: column }) => ({
    columns: R.mergeAll([columns, { [column.id]: column }])
  }),
  updateColumnResponse: R.identity,
  removeColumn: ({ columns, columnIndex }, { payload: column }) => ({
    columns: R.omit([column.id], columns),
    columnIndex: R.without([column.id], columnIndex)
  }),
  refreshRecords: R.identity,
  executeAction: R.identity,
  saveColumnWidth: (
    { columnWidths, columnDelta },
    { payload: { columnId, width } }
  ) => ({
    columnWidths: {
      ...columnWidths,
      [columnId]: width || 200
    },
    columnDelta: R.omit([columnId], columnDelta)
  })
};

const model = createHandlers({ iniState, reducers, namespace: NAMESPACE });

const { actions, getters } = model;

export { actions, getters };

export default model;
