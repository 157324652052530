import isEmpty from "./";
import { get } from "lodash";

export default function(value) {
  // Inventory can be multiple value types, so we inspect the value type
  // and call isEmpty again with the given type.
  if (get(value, "type")) {
    return isEmpty(value, value.type);
  }
  return true;
}
