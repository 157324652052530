import React from "react";
import CSSModules from "react-css-modules";
import css from "./style.scss";

const DropPlace = ({ isOver, isPlaceOver, bottom = false }) => (
  <div
    styleName="drop-place"
    style={
      isOver || isPlaceOver
        ? {
            height: "40px",
            boxSizing: "border-box",
            background: "#f2f1f4"
          }
        : bottom
        ? {
            height: "20px",
            boxSizing: "border-box"
          }
        : {}
    }
  />
);

export default CSSModules(css)(DropPlace);
