import * as R from "ramda";
import { createHandlers, createModule } from "redux-mvc";
import { NAMESPACE } from "./constants";

import notificationsModule from "ui-kit/Notifications";

const iniState = {
  countOfUnseenNotifications: 0
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    init: R.identity,
    openSiderbar: R.identity
  }
});

const module = createModule(handlers);

module.plugModule(notificationsModule);

const { actions, getters } = module;

export { actions, getters };

export default module;
