import { createHandlers } from "redux-mvc";
import * as R from "ramda";
import { NAMESPACE, SHIPPING } from "./constants";

const iniState = {
  loading: false,
  itemTypesList: [],
  shipping: SHIPPING.SAME,
  contact: {
    fullName: "",
    email: "",
    company: ""
  },
  payment: {
    cardNumber: "",
    cvv: "",
    expirationDate: "",
    zipCode: ""
  }
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    getItemTypes: R.identity,
    setInitialData: (_, { payload: { itemTypesList, iniSelectedTab } }) => ({
      itemTypesList: itemTypesList
        .map((itemType, index) => {
          const itemGroups = itemType.groups;
          const variants = R.reduce(
            (acc, group) => [...acc, ...group.variants],
            [],
            itemGroups
          );
          return {
            id: itemType.id,
            name: itemType.name,
            selected: iniSelectedTab
              ? iniSelectedTab === itemType.id
              : index === 0,
            items: R.map(
              item => ({ ...item, quantity: 0, amount: 0 }),
              variants
            )
          };
        })
        .filter(itemType => itemType.items.length)
    }),
    selectItemType: (state, { payload: { id } }) => ({
      itemTypesList: R.map(
        itemType => ({
          ...itemType,
          selected: itemType.id === id
        }),
        state.itemTypesList
      )
    }),
    updateItemQuantity: (state, { payload: { id, quantity, price } }) => {
      const groupIndex = R.findIndex(
        R.propEq("selected", true),
        state.itemTypesList
      );

      const group = state.itemTypesList[groupIndex];
      const itemIndex = R.findIndex(R.propEq("id", id), group.items);
      const item = group.items[itemIndex];
      return {
        itemTypesList: [
          ...state.itemTypesList.slice(0, groupIndex),
          {
            ...group,
            items: [
              ...group.items.slice(0, itemIndex),
              {
                ...item,
                quantity,
                price
              },
              ...group.items.slice(itemIndex + 1)
            ]
          },
          ...state.itemTypesList.slice(groupIndex + 1)
        ]
      };
    },
    updateItemCartQuantity: (
      state,
      { payload: { itemId, groupId, quantity } }
    ) => {
      const groupIndex = R.findIndex(
        R.propEq("id", groupId),
        state.itemTypesList
      );
      const group = state.itemTypesList[groupIndex];
      const itemIndex = R.findIndex(R.propEq("id", itemId), group.items);
      const item = group.items[itemIndex];
      return {
        itemTypesList: [
          ...state.itemTypesList.slice(0, groupIndex),
          {
            ...group,
            items: [
              ...group.items.slice(0, itemIndex),
              {
                ...item,
                quantity
              },
              ...group.items.slice(itemIndex + 1)
            ]
          },
          ...state.itemTypesList.slice(groupIndex + 1)
        ]
      };
    },
    updateContact: (state, { payload: { name, value } }) => ({
      contact: {
        ...state.contact,
        [name]: value
      }
    }),
    updatePayment: (state, { payload: { name, value } }) => ({
      payment: {
        ...state.payment,
        [name]: value
      }
    })
  }
});

export default handlers;
