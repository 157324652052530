import React, { Component } from "react";
import styleSpects from "components/Base/Base/styleSpecs";
import { getValue } from "../../utils";
import Tooltip from "components/Global/Tooltip";
import { OpenInNewIcon } from "components/Base";

class EmsStatus extends Component {
  goToEvent = () => {
    const id = getValue(this.props, "id");
    window.location = `/event-light/${id}/dashboard`;
  };

  goToLightEvent = () => {
    const id = getValue(this.props, "id");
    window.location = `/event-light/${id}/dashboard`;
  };

  goToSetup = () => {
    const id = getValue(this.props, "id");
    window.location = `/event/${id}/setup`;
  };

  render() {
    const hasAccess = getValue(this.props, "has_access");
    const enabled = getValue(this.props, "enabled");
    const isLight = getValue(this.props, "is_light");

    if (!hasAccess) {
      return (
        <Tooltip
          tooltip="You do not have access to this event"
          placement="bottom"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              fontSize: "14px"
            }}
          >
            -
          </div>
        </Tooltip>
      );
    }

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          fontSize: "14px"
        }}
      >
        <div
          onClick={
            isLight
              ? this.goToLightEvent
              : enabled
              ? this.goToEvent
              : this.goToSetup
          }
          className="underline-on-hover"
          style={{
            color: styleSpects.color.primary9,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center"
          }}
        >
          <OpenInNewIcon mr={1} color="purple9" />
          {isLight ? "Manage Event" : enabled ? "Manage Event" : "Enable"}
        </div>
      </div>
    );
  }
}

export default EmsStatus;
