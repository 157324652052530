import { takeEvery, put, all, fork, select, call } from "redux-saga/effects";
import React from "react";
import { actions } from "./model";
import { showModal } from "redux/modules/modal/actions";
import Dialog from "components/Global/Modals/Dialog";
import { deleteField } from "redux/modules/formsV2/form/fields/actions";
import * as FormSelectors from "redux/modules/formsV2/form/selectors";
import { makeFuture } from "utils/General/sagas";

const removeFieldSelected = function*({ payload: { data } }) {
  const formData = yield select(FormSelectors.form);
  const handleRemove = makeFuture();
  yield put(
    showModal({
      content: (
        <Dialog
          onConfirm={handleRemove.done}
          confirmLabel="Continue"
          title="Are you sure?"
          message={
            <div>
              This will only remove the field from this form and will not delete
              any data associated with the field.
            </div>
          }
        />
      )
    })
  );
  yield call(handleRemove.onRealized);
  yield put(deleteField({ fieldId: data, formId: formData.id }));
};

const watchRemoveFieldSelected = function*() {
  yield takeEvery(actions.removeFormField.type, removeFieldSelected);
};

const rootSaga = function*() {
  yield all([fork(watchRemoveFieldSelected)]);
};

export default rootSaga;
