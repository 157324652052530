import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";

import { actions, getters } from "Items/EditQuestionSetQuestionsModal";

import { registerError } from "redux/modules/errors/actions";

import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { getSelectedQuestions } from "Items/EditQuestionSetQuestionsModal/selectors";

import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);

  return {
    credentials,
    eventId
  };
};

const init = function*({ payload: { setId } }) {
  try {
    yield put(actions.setLoading(true));

    const { credentials, eventId } = yield call(getParams);

    const { payload: questionSet } = yield call(Api.getQuestionSet, {
      credentials,
      setId
    });

    const { payload: questions } = yield call(Api.getQuestions, {
      credentials,
      eventId
    });

    yield put(
      actions.setInitialData({
        setId,
        questionSet,
        questions,
        selectedQuestionIds: questionSet.questions.map(q => q.id),
        requiredQuestionIds: questionSet.questions
          .filter(q => q.required)
          .map(q => q.id)
      })
    );
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading question set"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const updateQuestionSet = function*({ payload: { onDone } }) {
  try {
    const { credentials } = yield call(getParams);
    const setId = yield select(getters.setId);
    const questions = yield select(getSelectedQuestions);

    yield call(Api.updateQuestionSet, {
      credentials,
      data: {
        setId,
        itemQuestionSet: {
          id: setId,
          questions
        }
      }
    });
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error updating question set"
          }
        ])
      )
    ]);
  } finally {
    yield call(onDone);
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchUpdateQuestionSet = function*() {
  yield takeEvery(actions.updateQuestionSet.type, updateQuestionSet);
};

const rootSaga = function*() {
  yield all([fork(watchInit), fork(watchUpdateQuestionSet)]);
};

export default rootSaga;
