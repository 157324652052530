import React from "react";

import { getValue } from "../../utils";

const OrderItem = props => {
  const statuses = getValue(props, "statuses", []);
  const name = getValue(props, "name");

  return (
    <div
      style={{
        flexDirection: "column"
      }}
    >
      <div
        style={{
          fontWeight: 600,
          fontSize: "16px",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden"
        }}
      >
        {name}
      </div>
      {statuses && statuses.length ? (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center"
          }}
        >
          {statuses.map(status => (
            <div
              style={{
                textTransform: "uppercase",
                paddingRight: "8px",
                fontSize: "10px"
              }}
              key={status}
            >
              {status}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default OrderItem;
