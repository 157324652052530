import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Overview from "./Overview";

import * as CredentialOrdersSelectors from "redux/modules/credentials/orders/selectors";
import * as OverviewSelectors from "redux/modules/formsV2/overview/selectors";
import * as UserSelectors from "redux/modules/user/selectors";
import * as IntegrationSelectors from "redux/modules/credentials/integrations/selectors";

import * as OverviewActions from "redux/modules/formsV2/overview/actions";
import * as LogActions from "redux/modules/log/actions";
import * as ModalActions from "redux/modules/modal/actions";
import * as FormActions from "redux/modules/formsV2/form/actions";
import * as SubmissionActions from "redux/modules/formsV2/submission/actions";
import * as ReviewActions from "redux/modules/formsV2/submission/reviews/actions";
import * as SnackbarActions from "redux/modules/snackbar/actions";
import { addRecord } from "redux/modules/modules/records/actions";
import { getIntegrations } from "redux/modules/credentials/integrations/actions";
import {
  findOrdersBySubmissionIds,
  searchOrders
} from "redux/modules/credentials/orders/actions";
import { bulkEdit } from "redux/modules/formsV2/submissions/bulk/actions";

function mapStateToProps(state) {
  return {
    user: UserSelectors.user(state),
    overview: OverviewSelectors.overview(state),
    errorMessages: [state.formsV2.overview.error].filter(e => e),
    integrations: IntegrationSelectors.integrations(state),
    orders: CredentialOrdersSelectors.orders(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...ReviewActions,
      ...OverviewActions,
      ...LogActions,
      ...ModalActions,
      ...SnackbarActions,
      ...SubmissionActions,
      ...FormActions,
      addRecord,
      searchOrders,
      getIntegrations,
      findOrdersBySubmissionIds,
      bulkEdit
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
