import React from "react";
import {
  Text0,
  Text2,
  Div,
  makeTable,
  CheckIcon,
  CloseIcon
} from "components/Base";
import { withProps } from "utils/General";
import moment from "moment";
import Tooltip from "components/Global/Tooltip";
import ClickToCopy from "components/Global/ClickToCopy";

export default makeTable({
  TableStyler: withProps({
    flex: 1,
    p: 4
  })(Div),
  HeaderStyler: withProps({ display: "row.flex-start.center" })(Div),
  RowStyler: withProps({
    display: "row.flex-start.center",
    bb: 1,
    bc: "neutral0",
    py: 2
  })(Div),
  HeaderCellStyler: withProps({ bold: true, uppercase: true })(Text0),
  RowCellStyler: Text2,
  columnProps: [
    { width: 2 / 12 },
    { width: 2 / 12 },
    { width: 4 / 12 },
    { width: 4 / 12 }
  ],
  headerCellComps: [
    () => <Div>Date</Div>,
    () => <Div>Total</Div>,
    () => <Div>Type</Div>,
    () => <Div display="row.flex-end">Reference #</Div>
  ],
  rowCellComps: [
    ({ created_at }) => (
      <Div>{moment(new Date(created_at)).format("M/D/YYYY")}</Div>
    ),
    ({ amount }) => <Div>{amount}</Div>,
    ({ kind, status }) => (
      <Div uppercase display="row.flex-start.center">
        {status === "success" ? (
          <CheckIcon color="success6" mr={1} />
        ) : (
          <CloseIcon color="danger6" mr={1} />
        )}
        {kind}
      </Div>
    ),
    ({ reference }) => (
      <Div display="row.flex-end">
        <Tooltip placement="bottom" tooltip="Click to copy">
          <Div flex={1} truncate>
            <ClickToCopy
              text={reference}
              textToCopy={reference}
              wrapperStyle={{
                display: "block",
                width: "100%",
                textAlign: "right",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            />
          </Div>
        </Tooltip>
      </Div>
    )
  ]
});
