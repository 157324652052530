import React, { Component } from "react";
import { connect } from "react-redux";

import {
  ButtonGroup,
  ButtonOutline,
  ButtonOrange
} from "components/Global/Modal/Layout/Buttons";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";
import { Div } from "components/Base";
import EventDaysEditor from "components/Global/Editors/EventDaysEditor";
import { addS } from "utils/General";

import { eventDetails } from "redux/modules/event/selectors";
import moment from "moment";

const decorator = connect(state => ({
  dayGroups: eventDetails(state).date_groups,
  startDate: eventDetails(state).date_from,
  endDate: eventDetails(state).date_to
}));

const selectedDays = dates =>
  dates.reduce((list, { start, end }) => {
    for (let m = moment(start).utc(); m.isSameOrBefore(end); m.add(1, "days")) {
      list.push(m.format("YYYY-MM-DD"));
    }
    return list;
  }, []);

class AddSingleDayVariantModal extends Component {
  state = {
    dates: []
  };

  onDaySelect = ({ value }) => {
    this.setState({
      dates: value || []
    });
  };

  onSave = () => {
    this.props.onSave(this.state.dates);
    this.props.hideModal();
  };

  render() {
    const { dayGroups, startDate, endDate, hideModal } = this.props;
    const isValid = this.state.dates.length > 0;
    const countOfSelectedDays = selectedDays(this.state.dates).length;

    return (
      <StyleWrapper heading="Create Day Passes" hideModal={hideModal}>
        <Div>
          <Div fw={3} fs={4} color="gray7" mt={1} mb={3}>
            Select the dates to create passes for
          </Div>

          <Div display="row.center.center" mb={3}>
            <EventDaysEditor
              onChange={this.onDaySelect}
              value={{ value: this.state.dates }}
              endDate={endDate}
              startDate={startDate}
              dayGroups={dayGroups}
            />
          </Div>
        </Div>
        <ButtonGroup>
          <ButtonOrange
            title={`Add${
              isValid ? ` ${countOfSelectedDays}` : ""
            } Variant${addS(countOfSelectedDays)}`}
            onClick={isValid ? this.onSave : undefined}
            disabled={!isValid}
          />
          <ButtonOutline title="Cancel" onClick={hideModal} />
        </ButtonGroup>
      </StyleWrapper>
    );
  }
}

export default decorator(AddSingleDayVariantModal);
