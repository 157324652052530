import { createContext } from "redux-mvc";

import * as R from "ramda";

import module from "SettingsIntegrations";

import Layout from "./Layout";

const decorate = createContext({
  module: module,
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", module.modules))),
      R.prop("type")
    ),
    observedDomains: ["user", "event", "modal", "organization", "permissions"]
  }
});

export default decorate(Layout);
