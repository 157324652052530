import Helpers from "utils/Global/Helpers";

/* eslint no-underscore-dangle: "off" */

export default {
  eventOptions: (credentials, { eventId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/event/clone-options/${eventId}?include=modules`,
        credentials,
        success,
        error
      });
    }),
  cloneData: (credentials, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/event/clone-content`,
        credentials,
        data,
        success,
        error
      });
    }),
  getCloneStatus: (credentials, { jobId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/jobs/progress/${jobId}`,
        credentials,
        success,
        error
      });
    })
};
