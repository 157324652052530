import React from "react";

import { connect } from "react-redux";

import { getters, actions } from "Orders/TicketsPasses";

import {
  MediumOutlineInput,
  Div,
  Text3,
  MediumFilledButton,
  MediumOutlineButton
} from "components/Base";

import MiniModalWrapper from "Orders/Common/View/MiniModalWrapper";

const decorate = connect(
  state => ({
    modalVisible: getters.categoryModalVisible(state),
    categoryName: getters.categoryName(state)
  }),
  {
    hideModal: () => actions.hideCategoryModal(),
    setCategoryName: actions.setCategoryName,
    addCategory: () => actions.addCategory()
  }
);

const NewCategoryModal = ({
  hideModal,
  modalVisible,
  categoryName,
  setCategoryName,
  addCategory
}) => (
  <MiniModalWrapper
    showModal={modalVisible}
    title="Add Category"
    width={300}
    height={200}
    hideModal={hideModal}
  >
    <Div p={3} width={1}>
      <Text3 bold>Category Name</Text3>
      <MediumOutlineInput
        value={categoryName}
        onChange={value => setCategoryName(value)}
        continuous
        width={1}
        my={2}
      />
      <Div display="row.space-between.center">
        <MediumOutlineButton onClick={hideModal} width={100}>
          Cancel
        </MediumOutlineButton>
        <MediumFilledButton bg="primary7" width={100} onClick={addCategory}>
          Save
        </MediumFilledButton>
      </Div>
    </Div>
  </MiniModalWrapper>
);

export default decorate(NewCategoryModal);
