import React from "react";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { Div, MediumFilledButton, AddIcon } from "components/Base";
import Checkbox from "material-ui/Checkbox";

const Group = ({ name, onUnassign = () => {} }) => (
  <Div display="row.space-between.center" bb={1} bc="neutral1" px={2} py={3}>
    <Div display="row.flex-start.center" fs={1} fw={3} color="gray7">
      {name}
    </Div>
    <Div fs={1} display="row.flex-start.center">
      <Div
        onClick={onUnassign}
        color={{
          default: "gray5",
          hover: "purple9"
        }}
      >
        Unassign
      </Div>
    </Div>
  </Div>
);

const RecordCollapsible = ({
  recordList,
  toggleSelectedRecord,
  selectAllRecords,
  deselectAllRecords,
  title = "",
  showModal,
  btnText,
  selectedList = [],
  selectedText = "",
  unassign = () => {}
}) => (
  <Div width={400} mb={5}>
    <Div display="row.flex-start.center" color="black" fw={3} fs={5} mb={1}>
      {title}
    </Div>

    <div styleName="assignContent">
      <Div display="row.flex-start.center" mb={3} fs={2}>
        <Div styleName="select" onClick={selectAllRecords}>
          Add all
        </Div>
        <span
          class="material-icons"
          style={{
            color: "#8d64d1",
            fontSize: 5,
            marginRight: 4,
            marginLeft: 4
          }}
        >
          fiber_manual_record
        </span>
        <Div styleName="select" onClick={deselectAllRecords}>
          Remove all
        </Div>
      </Div>
      {R.map(
        ({ id, name, selected }) => (
          <div styleName="item">
            <Checkbox
              key={id}
              checked={selected}
              iconStyle={{ marginRight: 8 }}
              labelStyle={{
                fontWeight: 600,
                fontSize: 18,
                color: "#555",
                width: 300
              }}
              onCheck={() => toggleSelectedRecord({ id, selected })}
              label={name}
            />
          </div>
        ),
        recordList
      )}
      <Div fw={4} fs={2} color="gray7" mt={8}>
        {selectedText}
        {selectedList.length ? ` (${selectedList.length})` : null}:
      </Div>
      <Div>
        {selectedList.map(group => (
          <Group
            key={group.id}
            {...group}
            onUnassign={() => unassign(group.id)}
          />
        ))}
      </Div>
      <div styleName="item">
        <MediumFilledButton
          onClick={showModal}
          color="purple8"
          bg="neutral1"
          mt={2}
          LeftIcon={AddIcon}
        >
          {btnText}
        </MediumFilledButton>
      </div>
    </div>
  </Div>
);

export default CSSModules(css)(RecordCollapsible);
