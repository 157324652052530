import React from "react";
import * as R from "ramda";
import { Div, Text3, Text1 } from "components/Base";

const Catalogs = ({ itemTypes }) => {
  return (
    <Div width={230} br={1} bc="neutral2" height={1}>
      <Div display="row.space-between.center" p={2} pt={4} pl={5}>
        <Text1>CATALOGS</Text1>
      </Div>
      {R.map(
        type => (
          <Div
            pl={5}
            py={4}
            bl={3}
            bc={type.active ? "primary7" : "transparent"}
            onClick={type.onClick}
            transition="fast"
          >
            <Text3
              key={type.id}
              color={type.active ? "primary7" : "black"}
              bold
              transition="fast"
            >
              {type.name}
            </Text3>
          </Div>
        ),
        itemTypes
      )}
    </Div>
  );
};

export default Catalogs;
