import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import { actions } from "Sponsors/SelfSetup";
import {
  getVisibleFieldsByModule,
  getOrderByModule
} from "Sponsors/SelfSetup/selectors";

import { Row } from "ui-kit/Form/View";
import { Div, Text4 } from "components/Base";

import PackageTable from "Sponsors/PackageTable/View";
import { VIEWS } from "Sponsors/PackageTable/constants";

import { os } from "ui-kit/Theme";

const decorate = connect(
  (state, props) => ({
    questions: getVisibleFieldsByModule(state, props),
    order: getOrderByModule(state, props)
  }),
  {
    addField: actions.addEventShowcaseModuleField,
    updateField: actions.updateEventShowcaseModuleField,
    deleteField: actions.deleteEventShowcaseModuleField,
    deleteModuleField: actions.deleteField
  }
);

const columns = ["include", "fieldName", "require", "fieldActions"];

const QuestionsList = ({
  title = "",
  caption = "",
  footer = "",
  questions = {},
  order = [],
  moduleId = "",
  handlers,
  deleteModuleField,
  ...styleProps
}) => (
  <Div {...os(styleProps)}>
    <Row display="row.flex-start.center">
      <Text4 bold>{title}</Text4>
      {caption}
    </Row>
    {R.length(R.keys(questions)) > 0 ? (
      <PackageTable
        drag
        columns={columns}
        items={questions}
        iniOrder={order}
        view={VIEWS.APPLICATION}
        handlers={{
          onEditField: (moduleId, fieldId) => {
            handlers.showEditFieldModal(moduleId, fieldId);
          },
          onDeleteField: deleteModuleField
        }}
        drag
        instanceId={moduleId}
      />
    ) : null}
    {(footer && (
      <Row width={1} mt={2} display="row.space-between.center">
        {footer}
      </Row>
    )) ||
      null}
    {/* // @TODO: Create Faqs component */}
  </Div>
);

export default decorate(QuestionsList);
