import React, { Component } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import Tooltip from "components/Global/Tooltip";
import { AllocationsModal } from "Passes/GuestLists/Items";
import SummaryModal from "Passes/GuestLists/SummaryModal";

import { noop } from "utils/General";
import { getValue, getSettings } from "../../utils";
import { showModal, hideModal } from "redux/modules/modal/actions";
import { actions } from "../../model";

const decorate = connect(
  null,
  bindInstance({
    showModal,
    hideModal,
    onDone: actions.updateData
  })
);

class AllocatedItems extends Component {
  showAddItemsModal = () => {
    const itemTypeId = getValue(this.props, "itemTypeId");
    const groupId = getValue(this.props, "groupId");
    const name = getValue(this.props, "name");

    this.props.showModal({
      content: (
        <AllocationsModal
          itemTypeId={itemTypeId}
          groupId={groupId}
          groupName={name}
          onSave={() => {
            this.props.onDone(true);
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showSummaryModal = () => {
    const itemTypeId = getValue(this.props, "itemTypeId");
    const groupId = getValue(this.props, "groupId");
    const name = getValue(this.props, "name");

    this.props.showModal({
      content: (
        <SummaryModal
          itemTypeId={itemTypeId}
          groupId={groupId}
          groupName={name}
          onClose={() => {
            this.props.onDone(true);
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const label = getValue(this.props, "label");
    const canAddItems = true;
    const allocatedCount = getValue(this.props, "allocatedCount");
    const pendingRequestCount = getValue(this.props, "pendingRequestCount");
    const assignedCount = getValue(this.props, "assignedCount");
    const isFieldReadOnly = getSettings(this.props, "readOnly", false);

    let content = null;
    let handler = null;

    if (!allocatedCount && !pendingRequestCount) {
      if (canAddItems && !isFieldReadOnly) {
        handler = this.showAddItemsModal;
        content = (
          <div
            style={{
              fontSize: "16px",
              fontWeight: 600,
              color: "rgb(130, 61, 209)",
              cursor: "pointer"
            }}
          >
            + Allocate {label}
          </div>
        );
      } else {
        content = <span>&mdash;</span>;
      }
    } else {
      const isComplete = getValue(this.props, "isComplete");
      const remaining = getValue(this.props, "remaining");
      handler = this.showSummaryModal;

      content = (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center"
          }}
        >
          <Tooltip
            tooltip={
              isComplete ? (
                <span>
                  <strong>All {label}</strong> assigned to people <br />
                  *some items may be excluded
                </span>
              ) : (
                <span>
                  <strong>
                    {assignedCount} {label}
                  </strong>{" "}
                  assigned to people <br />
                  {remaining && allocatedCount > 0 ? (
                    <span>
                      <strong>
                        {remaining} {label}
                      </strong>{" "}
                      left to assign
                    </span>
                  ) : (
                    <span>
                      <strong>{pendingRequestCount} requests </strong>
                      pending review
                    </span>
                  )}
                  <br />
                  *some items may be excluded
                </span>
              )
            }
          >
            <span>
              <div
                style={{
                  color: isComplete ? "rgb(47, 158, 68)" : "rgb(134, 134, 134)",
                  padding: "0 6px",
                  fontSize: "12px",
                  fontWeight: 600,
                  height: "24px",
                  display: "inline-flex",
                  backgroundColor: isComplete
                    ? "rgb(211, 249, 216)"
                    : "rgb(242, 241, 244)",
                  borderRadius: "4px",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                  cursor: "pointer"
                }}
              >
                {isComplete && (
                  <i
                    className="material-icons"
                    style={{
                      color: "#51cf66",
                      marginRight: "8px",
                      fontSize: "16px"
                    }}
                  >
                    check
                  </i>
                )}
                {assignedCount} of&nbsp;
                <strong>{allocatedCount}</strong>
              </div>
            </span>
          </Tooltip>
        </div>
      );
    }

    return <div onClick={isFieldReadOnly ? noop : handler}>{content}</div>;
  }
}

export default decorate(AllocatedItems);
