import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Controller from "./Controller";
import { withRouter } from "react-router";

import { getLoggedInUserDetails } from "redux/modules/user/actions";

import { user } from "redux/modules/user/selectors";

function mapStateToProps(state) {
  return {
    user: user(state),
    sidebar: state.sidebar
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getLoggedInUserDetails
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
