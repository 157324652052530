import React from "react";

import { connect } from "react-redux";

import { actions, getters } from "Items/AssignQuestionsItemsModal";

import { HEIGHTS } from "Items/AssignQuestionsItemsModal/constants";

import { Div, BigFilledButton, BigOutlineButton } from "components/Base";

const decorate = connect(
  state => ({
    saving: getters.saving(state)
  }),
  {
    save: actions.save
  }
);

const Footer = ({
  hideModal,
  save,
  saving,
  showAddQuestionSetModal,
  showAddQuestionModal,
  saveOnDone
}) => (
  <Div
    width={1}
    bt={1}
    bc="neutral1"
    display="row.space-between.center"
    px={4}
    height={HEIGHTS.FOOTER}
  >
    <Div display="row.flex-start.center">
      <BigFilledButton
        bg="altB4"
        color="white"
        disabled={saving}
        onClick={() =>
          save({
            onDone: () => {
              hideModal();
              saveOnDone();
            }
          })
        }
      >
        {saving ? "Saving..." : "Save"}
      </BigFilledButton>
      <BigOutlineButton
        onClick={() => showAddQuestionModal()}
        ml={2}
        color="neutral7"
      >
        + Add New Question
      </BigOutlineButton>
      <BigOutlineButton
        onClick={() => showAddQuestionSetModal()}
        ml={2}
        color="neutral7"
      >
        + Add New Question Set
      </BigOutlineButton>
    </Div>
    <BigOutlineButton width={121} onClick={hideModal}>
      Cancel
    </BigOutlineButton>
  </Div>
);

export default decorate(Footer);
