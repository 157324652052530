import React from "react";
import { connect } from "react-redux";

import { getValue, getSettings } from "../../utils";
import { actions } from "../../model";
import { ROW_ACTIONS } from "../../constants";

const decorate = connect(
  null,
  {
    execute: actions.executeAction
  }
);

const Title = props => {
  const value = getValue(props);
  const big = getSettings(props, "big", false);
  const clickable = getSettings(props, "clickable", false);
  const isFieldReadOnly = getSettings(props, "readOnly");

  return (
    <div
      className="hoverContainer"
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%"
      }}
    >
      {clickable && !isFieldReadOnly ? (
        <div
          title={value}
          style={{
            fontSize: big ? "18px" : "16px",
            fontWeight: 600,
            color: "rgb(130, 61, 209)",
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            cursor: "pointer"
          }}
          onClick={() =>
            props.execute({
              row: props.data,
              column: props.field,
              actionId: ROW_ACTIONS.OPEN_RECORD
            })
          }
        >
          {value}
        </div>
      ) : (
        <div
          title={value}
          style={{
            fontSize: big ? "18px" : "16px",
            fontWeight: 600,
            color: "#000",
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}
        >
          {value}
        </div>
      )}
    </div>
  );
};

export default decorate(Title);
