import * as R from "ramda";
import React from "react";
import { all, fork, put, call, take, select, race } from "redux-saga/effects";
import moment from "moment-timezone";
import { COMMIT, REVERT } from "redux-optimist";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

import { makeFuture } from "utils/General/sagas";

import { actions, getters } from "../index";
import { actions as ActivityDetailsActions } from "Schedules/ActivityDetailsSidebar/model";
import { getters as TabsGetters } from "ui-kit/Tabs";
import { actions as TimeTableActions } from "ui-kit/TimeTable/model";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { showModal } from "redux/modules/modal/actions";

import { registerError } from "redux/modules/errors/actions";

import * as TimeTableSelectors from "ui-kit/TimeTable/selectors";
import { getScheduleTimezone } from "../selectors";
import { userId as getUserId } from "redux/modules/user/selectors";

import { apiCall } from "App/Data/sagas";

import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";

const showAddRecordModal = function*() {
  for (;;) {
    const {
      payload: { start, end, resource },
      meta
    } = yield take([
      TimeTableActions.eventCreate.type,
      actions.eventCreate.type
    ]);

    const eventId = yield select(getEventId);
    const timezone = yield select(getScheduleTimezone);

    const values = {};

    if (start) {
      values[STANDARD_FIELD_IDS.SCHEDULES.STARTS_AT] = {
        type: "datetime",
        value: {
          iso: start,
          timezone
        }
      };
    }

    if (end) {
      values[STANDARD_FIELD_IDS.SCHEDULES.ENDS_AT] = {
        type: "datetime",
        value: {
          iso: end,
          timezone
        }
      };
    }

    if (resource) {
      const fieldId = yield select(TabsGetters.selectedTab, {
        instanceId: meta.instanceId
      });
      const resourceData = yield select(TimeTableSelectors.getResourceById, {
        resourceId: resource.id,
        instanceId: meta.instanceId
      });
      values[fieldId] = resourceData.value;
    }

    const createRecord = yield call(makeFuture);
    const cancel = yield call(makeFuture);
    const moduleId = yield select(getters.moduleId, {
      instanceId: meta.instanceId
    });

    yield put(
      showModal({
        content: (
          <AddRecordModal
            eventId={eventId}
            moduleId={moduleId}
            typeId={STANDARD_MODULE_IDS.schedules.id}
            onSave={createRecord.done}
            onCancel={cancel.done}
            values={values}
          />
        ),
        wrapper: ModalWrapper
      })
    );

    const { create } = yield race({
      cancel: call(cancel.onRealized),
      create: call(createRecord.onRealized)
    });
    if (create) {
      yield put(actions.fetchData());
    }
  }
};

const showActivityDetailsModal = function*() {
  for (;;) {
    const { payload: activity } = yield take(TimeTableActions.eventEdit.type);
    const scheduleId = yield select(getters.moduleId);

    yield put(
      ActivityDetailsActions.openActivity({
        activityId: activity.id,
        scheduleId
      })
    );
  }
};

const updateDate = function*({ newEvent, oldEvent, end, meta }) {
  const fieldId = end
    ? STANDARD_FIELD_IDS.SCHEDULES.ENDS_AT
    : STANDARD_FIELD_IDS.SCHEDULES.STARTS_AT;

  const eventData = yield select(TimeTableSelectors.getEventByResourceAndId, {
    eventId: oldEvent.id,
    resourceId: oldEvent.resourceId,
    instanceId: meta.instanceId
  });

  const timezone = yield select(getScheduleTimezone, {
    instanceId: meta.instanceId
  });

  if (!R.isEmpty(eventData)) {
    try {
      const userId = yield select(getUserId);
      yield call(apiCall, {
        url: `/schedules/field-values`,
        method: "post",
        data: {
          fieldId,
          recordId: eventData.id,
          value: {
            ...R.propOr({}, fieldId, eventData),
            value: {
              ...R.pathOr({}, [fieldId, "value"], eventData),
              iso: moment
                .tz(end ? newEvent.end : newEvent.start, timezone)
                .utc()
                .toISOString()
            }
          }
        },
        qs: {
          // NOTE: remove when endpoint is validated
          userId
        }
      });
      return true;
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error occurred changing the event date"
          }
        ])
      );
    }
  }
  return false;
};

const eventResize = function*() {
  for (;;) {
    const { payload: oldEvent } = yield take(
      TimeTableActions.eventResizeStart.type
    );
    const { payload: newEvent, meta, optimist } = yield take(
      TimeTableActions.eventResizeRequest.type
    );

    const success = yield call(updateDate, {
      oldEvent,
      newEvent,
      end: true,
      meta
    });

    if (success) {
      yield put(
        TimeTableActions.eventResizeResponse(newEvent, {
          optimist: { ...optimist, type: COMMIT },
          meta: meta
        })
      );
    } else {
      yield put(
        TimeTableActions.eventResizeResponse(newEvent, {
          optimist: { ...optimist, type: REVERT },
          meta: meta
        })
      );
    }
  }
};

const updateResource = function*({ newEvent, oldEvent, meta }) {
  const eventData = yield select(TimeTableSelectors.getEventByResourceAndId, {
    eventId: oldEvent.id,
    resourceId: oldEvent.resourceId,
    instanceId: meta.instanceId
  });
  const resourceData = yield select(TimeTableSelectors.getResourceById, {
    resourceId: newEvent.resourceId,
    instanceId: meta.instanceId
  });
  const fieldId = yield select(TabsGetters.selectedTab, {
    instanceId: meta.instanceId
  });

  if (!R.isEmpty(eventData) && !R.isEmpty(resourceData)) {
    try {
      const userId = yield select(getUserId);
      yield call(apiCall, {
        url: `/schedules/field-values`,
        method: "post",
        data: {
          fieldId,
          recordId: eventData.id,
          value: resourceData.value
        },
        qs: {
          // NOTE: remove when endpoint is validated
          userId
        }
      });
      return true;
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error occurred changing the event resource"
          }
        ])
      );
    }
  }
  return false;
};

const eventDrag = function*() {
  for (;;) {
    const { payload: oldEvent } = yield take(
      TimeTableActions.eventDragStart.type
    );
    const { payload: newEvent, meta, optimist } = yield take(
      TimeTableActions.eventDropRequest.type
    );

    const tasks = {};
    if (!moment(oldEvent.start).isSame(newEvent.start)) {
      tasks.start = call(updateDate, { newEvent, oldEvent, meta });
      tasks.end = call(updateDate, { newEvent, oldEvent, meta, end: true });
    }
    if (oldEvent.resourceId !== newEvent.resourceId) {
      tasks.resource = call(updateResource, { newEvent, oldEvent, meta });
    }

    const { start, end, resource } = yield all(tasks);

    const event = {
      ...oldEvent,
      start: start ? newEvent.start : oldEvent.start,
      end: end ? newEvent.end : oldEvent.end,
      resourceId: resource ? newEvent.resourceId : oldEvent.resourceId
    };

    yield put(
      TimeTableActions.eventDropResponse(event, {
        meta: meta,
        optimist: { ...optimist, type: COMMIT }
      })
    );
  }
};

const rootSaga = function*() {
  yield all([
    fork(showAddRecordModal),
    fork(showActivityDetailsModal),
    fork(eventResize),
    fork(eventDrag)
  ]);
};

export default rootSaga;
