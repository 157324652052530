import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Controller from "./Controller";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { hideModal, showModal } from "redux/modules/modal/actions";
import {
  getSpace,
  updateSpace
} from "redux/modules/approvalWorkflows/spaces/actions";
import { updateDefaultApprovers } from "redux/modules/approvalWorkflows/defaultApprovers/actions";
import {
  updateWorkflows,
  updateWorkflow,
  addWorkflow,
  deleteWorkflow
} from "redux/modules/approvalWorkflows/workflows/actions";

import { eventDetails } from "redux/modules/event/selectors";
import { space } from "redux/modules/approvalWorkflows/spaces/selectors";

function mapStateToProps(state, props) {
  let spaceKey = null;
  let spaceType = null;

  if (
    props.routes.find(
      r => r.name === "editEventSettingsCredentialsApprovalWorkflows"
    )
  ) {
    spaceType = "credentials";
  } else if (
    props.routes.find(
      r => r.name === "editEventSettingsCateringApprovalWorkflows"
    )
  ) {
    spaceType = "catering";
  } else if (
    props.routes.find(
      r => r.name === "editEventSettingsBoothsApprovalWorkflows"
    )
  ) {
    spaceType = "booths";
  } else {
    spaceKey = props.params.moduleId;
    spaceType = "module";
  }

  return {
    spaceKey,
    spaceType,
    space: space(state, spaceKey),
    eventDetails: eventDetails(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSnackbar,
      showModal,
      hideModal,
      getSpace,
      updateSpace,
      updateDefaultApprovers,
      updateWorkflows,
      updateWorkflow,
      addWorkflow,
      deleteWorkflow
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
