import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";

import {
  // updateWorkflows,
  // addWorkflow,
  // deleteWorkflow,
  updateWorkflow,
  getWorkflow
} from "redux/modules/approvalWorkflows/workflows/actions";
import { hideModal, showModal } from "redux/modules/modal/actions";

import { eventDetails } from "redux/modules/event/selectors";
import { workflow } from "redux/modules/approvalWorkflows/workflows/selectors";
import { updateDefaultApprovers } from "redux/modules/approvalWorkflows/defaultApprovers/actions";
import {
  addBlock,
  updateBlock,
  updateBlocks,
  deleteBlock
} from "redux/modules/approvalWorkflows/blocks/actions";
import { updateBlockApprovers } from "redux/modules/approvalWorkflows/blockApprovers/actions";

import {
  addTrigger,
  updateTrigger,
  updateTriggers,
  deleteTrigger
} from "redux/modules/approvalWorkflows/triggers/actions";

import { updateTriggerRecords } from "redux/modules/approvalWorkflows/triggerRecords/actions";

function mapStateToProps(state, props) {
  return {
    eventDetails: eventDetails(state),
    workflow: workflow(state, props.workflowId)
  };
}

function mapDispatchToProps(dispatch, props) {
  const { spaceId, workflowId } = props;

  return bindActionCreators(
    {
      getWorkflow: () => {
        return getWorkflow({
          spaceId,
          workflowId
        });
      },
      addBlock,
      deleteBlock,
      updateBlock,
      updateBlocks,
      updateWorkflow,
      showModal,
      hideModal,
      updateBlockApprovers,
      updateDefaultApprovers,
      addTrigger,
      updateTrigger,
      updateTriggers,
      deleteTrigger,
      updateTriggerRecords
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Controller);
