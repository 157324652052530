import PropTypes from "prop-types";
import React, { Component } from "react";
import Header from "./Header";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import HeightSizer from "components/Global/Table3/Sizers/Rows";
import Panel from "components/Global-2016/Panel";
import PendingFooter from "./Footers/Pending";
import ApprovedFooter from "./Footers/Approved";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import { isManifestPending } from "components/Global/Approvals/utils/approvals-helpers";
import getApprovalValue from "utils/value-types/get-value/approval";
import { APPROVAL } from "utils/system-field-ids";

@CSSModules(css)
class GroupPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: props.defaultOpen
    };
  }

  componentWillReceiveProps(props) {
    if (props.status !== this.props.status) {
      this.setState({
        open: false
      });
    }
  }

  getCount() {
    const { cachedCount } = this.props;
    return cachedCount;
  }

  toggleTable = () => {
    const { open } = this.state;
    if (!open) {
      this.setState({
        open: true
      });
    } else {
      this.setState({
        open: !this.state.open
      });
    }
  };

  goToModule = e => {
    e.stopPropagation();
    const { router, status, eventId, parent, returnTo } = this.props;
    return router.push({
      pathname: `/event/${eventId}/module/${parent.id}/grid`,
      query: {
        returnTo
      }
    });
  };

  fetchRecords = () => {
    const { parent, eventId, getRecords } = this.props;
    return getRecords({
      moduleId: parent.id,
      options: {
        eventId
      }
    });
  };

  fetchCounts = () => {
    const { getModuleCounts, parent } = this.props;
    return getModuleCounts(parent.id);
  };

  getDefaultRecordValues = () => ({});

  showAddRecordModal = () => {
    const { parent, showModal } = this.props;
    showModal({
      content: (
        <AddRecordModal
          moduleId={parent.id}
          onSave={() => {
            this.fetchRecords();
            this.fetchCounts();
          }}
          values={this.getDefaultRecordValues()}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showAddApprovedRecordModal = () => {
    const { parent, showModal } = this.props;
    showModal({
      content: (
        <AddRecordModal
          moduleId={parent.id}
          onSave={async record => {
            await this.approveRow(record.id);
            this.fetchRecords();
            this.fetchCounts();
          }}
          values={this.getDefaultRecordValues()}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  approveRow(rowId) {
    return this.props.createReview(this.props.parent.id, rowId, "approved");
  }

  approveRows = rowIds =>
    this.props.createReviews(this.props.parent.id, rowIds, "approved");

  exportSheet = contentType => {
    const { exportSheet, records, parent, fields } = this.props;

    // All fields are shown here, so set them all as visible
    const allFieldsVisible = fields.map(field => ({ ...field, visible: true }));

    return exportSheet(
      contentType,
      parent.record_name_plural,
      records,
      allFieldsVisible
    );
  };

  renderFooter(status) {
    const { records, selectedRows } = this.props;

    switch (status) {
      case "pending": {
        const pendingRows = records
          .filter(r => isManifestPending(getApprovalValue(r.values[APPROVAL])))
          .map(({ id }) => id);
        const selectedPending = selectedRows.filter(id =>
          pendingRows.includes(id)
        );
        return (
          <PendingFooter
            rows={pendingRows}
            selectedRows={selectedPending}
            addRequest={this.showAddRecordModal}
            approveRows={this.approveRows}
          />
        );
      }
      case "approved": {
        return <ApprovedFooter addRequest={this.showAddApprovedRecordModal} />;
      }
      default:
        return <span />;
    }
  }

  renderTable() {
    const {
      deselectAllRows,
      location,
      eventId,
      parent,
      records,
      selectedRows,
      setSelectedRows,
      status,
      Table
    } = this.props;

    const tableProps = {
      deselectAllRows,
      location,
      heightSizer: <HeightSizer rows={records} maxHeight={310} />,
      hideHeader: true,
      hideSidebar: true,
      context: {
        eventId,
        moduleId: parent.id,
        view: "grid"
      },
      records,
      selectedRows,
      setSelectedRows
    };

    if (!records.length) {
      return (
        <div>
          <Table {...tableProps} />
        </div>
      );
    }
    return (
      <div>
        <Table {...tableProps} />
        {this.renderFooter(status)}
      </div>
    );
  }

  render() {
    const { parent, records, cachedCountOfRecords } = this.props;

    // css.hidden, because we don't know if there are records until the <Table />
    // component fetches them. <Table /> must render in order to fetch, so we
    // render a hidden panel. We do this to prevent showing a bunch of empty panels
    // in the module needs 'ByStatus' view. However, a cached count can be passed in
    // to override this if we the count is known beforehand.

    return (
      <Panel
        style={cachedCountOfRecords || records.length ? css.panel : css.hidden}
      >
        <Header
          count={this.getCount()}
          exportCSV={() => this.exportSheet("csv")}
          exportXLSX={() => this.exportSheet("xlsx")}
          goToModule={this.goToModule}
          isOpen={this.state.open}
          title={parent.name}
          toggleTable={this.toggleTable}
        />
        {this.state.open ? (
          <div styleName="panelBody">{this.renderTable()}</div>
        ) : (
          ""
        )}
      </Panel>
    );
  }
}

GroupPanel.propTypes = {
  cachedCount: PropTypes.number.isRequired,
  createReview: PropTypes.func.isRequired,
  createReviews: PropTypes.func.isRequired,
  defaultOpen: PropTypes.bool.isRequired,
  deselectAllRows: PropTypes.func.isRequired,
  eventId: PropTypes.number.isRequired,
  exportSheet: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  getModuleCounts: PropTypes.func.isRequired,
  getRecords: PropTypes.func.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  parent: PropTypes.object.isRequired,
  records: PropTypes.array.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  selectedRows: PropTypes.arrayOf(PropTypes.string).isRequired,
  setSelectedRows: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  Table: PropTypes.object.isRequired,
  cachedCountOfRecords: PropTypes.number,
  returnTo: PropTypes.string
};

export default GroupPanel;
