import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router";

export default class Logo extends Component {
  render() {
    return (
      <Link to="/">
        <div className="logo">
          <img
            src="//d2dks4tzxs6xee.cloudfront.net/img/corner-logo.png"
            width="11"
            height="17"
          />
        </div>
      </Link>
    );
  }
}
