import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";

import {
  addExistingUser,
  inviteUser
} from "redux/modules/organization/users/actions";

import { user } from "redux/modules/user/selectors";

function mapStateToProps(state) {
  return {
    user: user(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      addExistingUser,
      inviteUser
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Controller);
