import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import CSSModules from "react-css-modules";
import { actions, getters } from "../model";
import css from "./styles.scss";
import { ADD_OPTIONS } from "../constants";
import { Dropdown } from "ui-kit/Form/View";
import { NAMESPACE } from "../constants";
import AutoGenerateOptions from "./AutoGenerateOptions";
import ImportOptions from "./ImportOptions";
import { TextAreaInput } from "components/Base";
import Loading from "ui-kit/Loading";
import Tooltip from "components/Global/Tooltip";

const decorate = R.compose(
  connect(
    state => ({
      addOption: getters.addOption(state),
      items: getters.items(state),
      numbers: getters.numbers(state),
      errorMessage: getters.errorMessage(state),
      loading: getters.loading(state)
    }),
    {
      setNumbers: actions.setNumbers
    }
  ),
  CSSModules(css)
);

const Body = ({
  addOption,
  items,
  numbers,
  setNumbers,
  errorMessage,
  loading
}) =>
  loading ? (
    <Loading />
  ) : (
    <div styleName="body">
      <div styleName="itemWrapper">
        <div styleName="row" style={{ alignItems: "center", marginBottom: 4 }}>
          <div styleName="label" style={{ marginRight: 4 }}>
            Item
          </div>
          <Tooltip
            tooltip="Items that have a tracking method enabled will show up here."
            className="tooltip-inventory-modal-z-index"
            placement="top"
          >
            <span
              className="material-icons"
              style={{ fontSize: 16, cursor: "pointer", color: "#ccc" }}
            >
              info
            </span>
          </Tooltip>
        </div>
        <Dropdown options={items} fieldId={NAMESPACE} />
      </div>
      <div styleName="label">How do you want to add inventory?</div>
      <ImportOptions />
      {addOption === ADD_OPTIONS.GENERATE ? <AutoGenerateOptions /> : null}
      {addOption === ADD_OPTIONS.IMPORT ? (
        <TextAreaInput
          continuous
          style={{ resize: "none", height: 138, marginBottom: 0 }}
          value={numbers}
          onChange={setNumbers}
        />
      ) : null}
      {errorMessage ? <div styleName="errorMessage">{errorMessage}</div> : null}
    </div>
  );

export default decorate(Body);
