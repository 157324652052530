import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const SortHeader = ({ sortByDirection, children }) => {
  if (!sortByDirection) {
    return <div className={css.container}>{children}</div>;
  }
  return (
    <div className={css.container}>
      <div className={css.label}>{children}</div>
      <div className={css.separator} />
      <i className={["material-icons", css.sort].join(" ")}>
        {sortByDirection === "ASC" ? "arrow_drop_down" : "arrow_drop_up"}
      </i>
    </div>
  );
};

SortHeader.propTypes = {
  sortByDirection: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired
};

export default CSSModules(SortHeader, css);
