/* eslint-disable no-underscore-dangle */
import {
  put,
  call,
  takeEvery,
  all,
  fork,
  select,
  take
} from "redux-saga/effects";
import * as R from "ramda";
import moment from "moment";
import { actions, getters } from "../model";
import {
  FORMS_INSTANCE_ID,
  TASKS_INSTANCE_ID,
  CARD_TYPES,
  ASSIGNED_DOCUMENT_INSTANCE_ID,
  DUE_DATE,
  TOGGLE,
  PERSON_FORMS_INSTANCE_ID,
  PERSON_DOCUMENTS_INSTANCE_ID,
  DATE_FORMAT_TZ,
  PERSON_SUBCARDS
} from "../constants";
import { apiCall } from "App/Data/sagas";
import {
  eventId as getEventId,
  eventDetails as getEventDetails
} from "redux/modules/event/selectors";
import { registerError } from "redux/modules/errors/actions";
import {
  actions as FormActions,
  getters as FormGetters
} from "ui-kit/Form/model";

import onboardSagas from "./onboard";
import documentRequestsSagas, {
  toggleRequiredDocument
} from "./documentRequests";
import formsSagas, { toggleRequiredForm } from "./forms";
import tasksSagas, { toggleRequiredTask } from "./tasks";
import sharedFilesSagas from "./sharedFiles";
import staffPersonnelSagas, {
  togglePersonDocument,
  togglePersonForm
} from "./staffPersonnel";
import communitySagas from "./community";
import appearanceSagas from "./appearance";
import guestListSagas from "./guestLists";
import assignPagesSagas from "./assignPages";
import autoAssignItemsSagas from "./autoAssignItems";
import sellItemsSagas from "./sellItems";

export const formatDateTimezone = function*(value) {
  const eventDetails = yield select(getEventDetails);

  return value
    ? moment
        .tz(value, DATE_FORMAT_TZ, R.prop("timezone", eventDetails))
        .utc()
        .format()
    : null;
};

const init = function*({ payload: { moduleId, selectedTypeId } }) {
  try {
    yield put(actions.setLoadingPermissionSet(true));

    const eventId = yield select(getEventId);
    const { payload } = yield call(apiCall, {
      method: "get",
      url: `/hub/admin/event/${eventId}/type/${selectedTypeId}/permission-set`
    });

    yield put(
      actions.setInitialData({
        loadingPermissionSet: false,
        moduleId,
        selectedTypeId,
        selectedPermissionSetId: payload ? payload.id : null,
        permissionSet: payload
      })
    );

    if (payload) {
      yield all([
        put(actions.getOnboardDetails()),
        put(actions.getForms(true)),
        put(actions.getTasks(true)),
        put(actions.getFileRequests()),
        put(actions.getSharedFiles(true)),
        put(actions.getStaffPersonnel({ moduleId })),
        put(actions.getCommunityDetails()),
        put(actions.getGuestListDetails()),
        put(actions.getPages()),
        put(actions.getAutoAssignedItems(true)),
        put(actions.getSellableItems(true))
      ]);
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error loading data"
        }
      ])
    );
  }
};

const addPermissionSet = function*() {
  try {
    const eventId = yield select(getEventId);
    const moduleId = yield select(getters.moduleId);
    const recordTypeId = yield select(getters.selectedTypeId);

    yield call(apiCall, {
      method: "post",
      url: `/hub/admin/event/${eventId}/type/${recordTypeId}/permission-set`
    });

    yield call(init, { payload: { moduleId, selectedTypeId: recordTypeId } });
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error creating permission set"
        }
      ])
    );
  }
};

const getPermissionSets = function*() {
  try {
    yield put(actions.setLoadingPermissionSets(true));
    const eventId = yield select(getEventId);

    const { payload: permissionsSets } = yield call(apiCall, {
      method: "get",
      url: `/account/event/${eventId}/permission-sets`
    });

    yield put(actions.setPermissionsSets(permissionsSets));
    yield put(actions.setLoadingPermissionSets(false));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error loading intake form"
        }
      ])
    );
  }
};

const goToLearnLennd = function*({ payload: { expectedExperienceUrl } }) {
  yield call(
    window.open,
    expectedExperienceUrl
      ? `https://learn.lennd.com/${expectedExperienceUrl}`
      : `https://learn.lennd.com/`,
    "_blank"
  );
};

const updateAssignedList = function*() {
  const assignModalType = yield select(getters.assignModalType);

  if (assignModalType === CARD_TYPES.FORMS) {
    yield put(actions.updateAssignedForms());
  } else if (assignModalType === CARD_TYPES.DOCUMENTS) {
    yield put(actions.updateAssignedDocuments());
  } else if (assignModalType === CARD_TYPES.PERSON_FORMS) {
    yield put(actions.updateAssignedPersonForms());
  } else if (assignModalType === CARD_TYPES.PERSON_DOCUMENTS) {
    yield put(actions.updateAssignedPersonDocuments());
  } else if (assignModalType === CARD_TYPES.PAGES) {
    yield put(actions.updateAssignedPages());
  } else if (assignModalType === CARD_TYPES.TASKS) {
    yield put(actions.updateAssignedTasks());
  }
};

const updateAssignItems = function*({ payload }) {
  const assignModalType = yield select(getters.assignModalType);

  if (assignModalType === CARD_TYPES.AUTO_ASSIGN) {
    yield put(actions.updateAutoAssignedItems(payload));
  } else if (assignModalType === PERSON_SUBCARDS.AUTO_ASSIGN) {
    yield put(actions.onSelectAutoAssignItems(payload));
  }
};

const updateRequestableItems = function*({ payload }) {
  const assignModalType = yield select(getters.assignModalType);

  if (assignModalType === CARD_TYPES.REQUESTABLE) {
    yield put(actions.onSelectRequestableGroupItems(payload));
  } else if (assignModalType === PERSON_SUBCARDS.REQUESTABLE) {
    yield put(actions.onSelectRequestablePersonItems(payload));
  }
};

const watchToggleRequired = function*() {
  for (;;) {
    const {
      meta: { instanceId, fieldId },
      payload
    } = yield take(FormActions.setFieldValue.type);
    if (R.contains(PERSON_DOCUMENTS_INSTANCE_ID, instanceId)) {
      const subtypeId = R.compose(R.propOr("", 1), R.split("/"))(instanceId);

      yield call(togglePersonDocument, {
        subtypeId,
        required: payload,
        variantId: fieldId
      });
    } else if (R.contains(PERSON_FORMS_INSTANCE_ID, instanceId)) {
      const subtypeId = R.compose(R.propOr("", 1), R.split("/"))(instanceId);
      yield call(togglePersonForm, {
        subtypeId,
        required: payload,
        variantId: fieldId
      });
    } else if (instanceId === FORMS_INSTANCE_ID) {
      yield call(toggleRequiredForm, { fieldId, required: payload });
    } else if (instanceId === TASKS_INSTANCE_ID) {
      yield call(toggleRequiredTask, { fieldId, required: payload });
    } else if (instanceId === ASSIGNED_DOCUMENT_INSTANCE_ID) {
      yield call(toggleRequiredDocument, { fieldId, required: payload });
    } else if (fieldId === DUE_DATE) {
      const fields = yield select(FormGetters.fields, { instanceId });
      const value = R.pathOr(null, [fieldId, "value"], fields);

      yield put(actions.updatePersonDueDate({ id: instanceId, value }));
    } else if (fieldId === TOGGLE) {
      const fields = yield select(FormGetters.fields, { instanceId });
      const value = R.pathOr(null, [fieldId, "value"], fields);

      yield put(
        actions.updateEnableSelectFields({
          isEnabled: value,
          typeId: instanceId
        })
      );
    }
  }
};

const updateModalData = function*() {
  const assignModalType = yield select(getters.assignModalType);

  if (assignModalType === CARD_TYPES.FORMS) {
    yield put(actions.updateAssginedForm());
  } else if (assignModalType === CARD_TYPES.TASKS) {
    yield put(actions.updateAssginedTask());
  } else if (assignModalType === CARD_TYPES.DOCUMENTS) {
    yield put(actions.updateDocumentModalData());
  } else if (assignModalType === CARD_TYPES.PERSON_FORMS) {
    yield put(actions.updatePersonAssginedForm());
  } else if (assignModalType === CARD_TYPES.PERSON_DOCUMENTS) {
    yield put(actions.updatePersonAssginedDocumentRequest());
  }
};

const updateItemData = function*() {
  const assignModalType = yield select(getters.assignModalType);

  if (assignModalType === CARD_TYPES.AUTO_ASSIGN) {
    yield put(actions.updateGroupItemData());
  } else if (
    assignModalType === PERSON_SUBCARDS.AUTO_ASSIGN ||
    assignModalType === PERSON_SUBCARDS.REQUESTABLE
  ) {
    yield put(actions.updatePersonItemData());
  } else if (assignModalType === CARD_TYPES.REQUESTABLE) {
    yield put(actions.openUpdateSellItemModal());
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchAddPermissionSet = function*() {
  yield takeEvery(actions.addPermissionSet.type, addPermissionSet);
};

const watchGetPermissionSets = function*() {
  yield takeEvery(actions.getPermissionSets.type, getPermissionSets);
};

const watchGoToLearnLennd = function*() {
  yield takeEvery(actions.goToLearnLennd.type, goToLearnLennd);
};

const watchUpdateAssginedList = function*() {
  yield takeEvery(actions.updateAssignedList.type, updateAssignedList);
};

const watchUpdateModalData = function*() {
  yield takeEvery(actions.updateModalData.type, updateModalData);
};

const watchUpdateAssignItems = function*() {
  yield takeEvery(actions.updateAssignItems.type, updateAssignItems);
};

const watchUpdateItemData = function*() {
  yield takeEvery(actions.updateItemData.type, updateItemData);
};

const watchUpdateRequestableItems = function*() {
  yield takeEvery(actions.updateRequestableItems.type, updateRequestableItems);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchAddPermissionSet),
    fork(watchGetPermissionSets),
    fork(watchGoToLearnLennd),
    fork(watchToggleRequired),
    fork(watchUpdateAssginedList),
    fork(watchUpdateModalData),
    fork(watchUpdateAssignItems),
    fork(watchUpdateItemData),
    fork(watchUpdateRequestableItems),
    //
    fork(onboardSagas),
    fork(documentRequestsSagas),
    fork(formsSagas),
    fork(tasksSagas),
    fork(sharedFilesSagas),
    fork(staffPersonnelSagas),
    fork(communitySagas),
    fork(appearanceSagas),
    fork(guestListSagas),
    fork(assignPagesSagas),
    fork(autoAssignItemsSagas),
    fork(sellItemsSagas)
  ]);
};

export default rootSaga;
