import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import External from "./External";
import { withRouter } from "react-router";

import { eventDetails } from "redux/modules/event/selectors";
import { getZone } from "redux/modules/items/zones/actions";
import { getZoneGroups } from "redux/modules/items/zone-groups/actions";
import { getItemGroupsByEventAndType } from "redux/modules/items/item-groups/actions";

import { groups } from "redux/modules/items/zone-groups/selectors";
import { zone } from "redux/modules/items/zones/selectors";
import { groups as itemGroups } from "redux/modules/items/item-groups/selectors";

function mapStateToProps(state, props) {
  return {
    mode: props.itemId ? (props.clone ? "clone" : "update") : "create",
    item: props.itemId ? zone(state, props.itemId) : null,
    eventDetails: eventDetails(state),
    groups: groups(state),
    itemGroups: itemGroups(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getZone,
      getZoneGroups,
      getItemGroupsByEventAndType
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(External)
);
