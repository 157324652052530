import React from "react";
import { addS } from "utils/General";

import { Div, SuperFilledButton } from "components/Base";

import { connect } from "react-redux";
import { getters, actions } from "../index";
import {
  getIsValid,
  getCountOfSelectedVariants,
  getCostOfSelectedVariants
} from "../selectors";

const decorate = connect(
  state => ({
    isValid: getIsValid(state),
    countOfSelectedVariants: getCountOfSelectedVariants(state),
    costOfSelectedVariants: getCostOfSelectedVariants(state),
    buttonLabel: getters.buttonLabel(state),
    color: getters.color(state)
  }),
  {
    onSave: actions.onSave
  }
);

const Footer = ({
  onSave,
  isValid,
  handlers,
  buttonLabel,
  countOfSelectedVariants,
  costOfSelectedVariants,
  color,
  showBorder
}) => {
  return (
    <>
      <Div
        display="row.space-between.center"
        pt={5}
        pb={6}
        style={{
          borderTop: showBorder ? "1px dotted #ccc" : 0
        }}
      >
        <Div fw={4} fs={5} color="gray7">
          {countOfSelectedVariants} Item
          {addS(countOfSelectedVariants)}
        </Div>
        {costOfSelectedVariants ? (
          <Div display="row.flex-start.center">
            <Div fs={5} fw={4} color="gray5" mr={3}>
              Total:
            </Div>
            <Div fs={5} fw={4} color="gray7">
              {costOfSelectedVariants}
            </Div>
          </Div>
        ) : null}
      </Div>
      <Div>
        <SuperFilledButton
          bg={color}
          mr={3}
          disabled={!isValid}
          onClick={() => onSave({ callback: handlers.onSave })}
          width={1}
        >
          <Div fw={3}>{buttonLabel}</Div>
        </SuperFilledButton>
      </Div>
    </>
  );
};

export default decorate(Footer);
