import { blackOrWhiteByContrast } from "utils/General";
import rgb from "hex-rgb";
import * as R from "ramda";

export const getStyle = ({
  valueColor = undefined,
  style = {},
  ...otherProps
}) => {
  let baseStyle = {
    lineHeight: "34px",
    width: "800px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  };
  if (valueColor) {
    baseStyle = {
      ...baseStyle,
      background: valueColor,
      color: blackOrWhiteByContrast(...rgb(valueColor)),
      borderRadius: 4,
      fontWeight: 600,
      fontSize: 12,
      padding: "0 6px",
      lineHeight: "2em",
      marginRight: 4
    };
  }

  if (
    R.path(["dependentValues", "meta", "columnSettings", "alignRight"])(
      otherProps
    )
  ) {
    baseStyle.textAlign = "right";
    baseStyle.width = "100%";
  }

  if (
    R.path(["dependentValues", "meta", "columnSettings", "bigText"])(otherProps)
  ) {
    baseStyle.fontSize = "16px";
    baseStyle.fontWeight = "600";
  }

  return { ...baseStyle, ...style };
};
