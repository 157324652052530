import React, { Component } from "react";
import {
  Div,
  Text3,
  TextAreaInput,
  VertColorGrid,
  Dropdown,
  BigInsetInput
} from "components/Base";

class Overview extends Component {
  render() {
    const {
      name,
      activeGroupId,
      parentZoneId,
      description,
      code,
      groups,
      zones,
      color,
      onNameChange,
      onCodeChange,
      onDescriptionChange,
      onGroupChange,
      onParentZoneChange,
      onSelectColor
    } = this.props;
    return (
      <Div>
        <Div display="row">
          <Div display="column" flex={1} pr={5}>
            <Div py={1} pb={4}>
              <Text3 bold pb={1}>
                Zone Name
              </Text3>
              <BigInsetInput
                name="name"
                width={1}
                fs={3}
                flex={1}
                onChange={onNameChange}
                continuous
                value={name}
              />
            </Div>

            <Div py={1} pb={4}>
              <Text3 bold pb={1}>
                Zone Code
              </Text3>
              <Div display="row.flex-start.center">
                <BigInsetInput
                  name="code"
                  width={1}
                  fs={3}
                  flex={1}
                  mr={2}
                  onChange={onCodeChange}
                  continuous
                  value={code}
                  maxLength={3}
                  width={65}
                />
                <Div fs={1} color="gray6">
                  Up to 3 characters. Numbers, letters and some special
                  characters supported.
                </Div>
              </Div>
            </Div>

            <Div py={1} pb={4}>
              <Text3 bold pb={1}>
                Category
              </Text3>
              <Dropdown
                options={groups}
                selected={activeGroupId}
                onChange={onGroupChange}
              />
            </Div>

            <Div py={1} pb={4}>
              <Text3 bold>Parent Zone (optional)</Text3>
              <Div fs={1} color="gray6" pb={1}>
                Specify if this zone resides completely inside of another zone &
                requires a parent zone.
              </Div>
              <Dropdown
                clearable
                options={zones}
                selected={parentZoneId}
                onChange={onParentZoneChange}
              />
            </Div>

            <Div py={1}>
              <Text3 bold>Description (optional)</Text3>

              <TextAreaInput
                name="description"
                width={1}
                fs={3}
                autoSize
                onChange={onDescriptionChange}
                continuous
                value={description}
              />
            </Div>
          </Div>

          <Div pb={4}>
            <Text3 bold pb={1}>
              Select Color
            </Text3>
            <Div p={3} bg="white" bs={2} bra={1}>
              <VertColorGrid
                selectedColor={color}
                onSelectColor={onSelectColor}
              />
            </Div>
          </Div>
        </Div>
      </Div>
    );
  }
}

export default Overview;
