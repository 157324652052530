import React from "react";
import * as R from "ramda";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getters } from "Catering/Dashboard";
import {
  Div,
  Text1,
  Text2,
  Text4,
  DownIcon,
  MediumFilledButton,
  BigFilledButton,
  RightIcon
} from "components/Base";
import MealDays from "./MealDays";
import MealsTotal from "./MealsTotal";
import { Title, Breadcrumbs } from "Passes/Common";

const decorate = R.compose(
  withRouter,
  connect(state => ({
    data: getters.data(state)
  }))
);

const Dashboard = ({
  data: { count_of_requests_for_approval },
  params: { eventId },
  router: { push }
}) => (
  <Div width={1} height={1} style={{ overflow: "auto" }} bg="neutral1">
    <Div
      px={6}
      pt={6}
      bg="white"
      width={1}
      bb={1}
      bc="neutral3"
      display="row.space-between.center"
      style={{
        flexShrink: 0
      }}
    >
      <Div pr={5} pb={3}>
        <Breadcrumbs>Catering</Breadcrumbs>
        <Title>Dashboard</Title>
      </Div>
    </Div>

    <Div width={1} px={6} pb={6}>
      <Div display="row" width={1} mt={4}>
        <Div width={315} mr={6}>
          {count_of_requests_for_approval > 0 && (
            <Div width={1} bg="white" p={4} bra={2} mb={2}>
              <Div width={1} display="row.space-between.center" mb={2}>
                <Div fw={3} fs={3} color="gray7">
                  For your approval
                </Div>
              </Div>
              <BigFilledButton
                RightIcon={RightIcon}
                bg="primary7"
                width={1}
                display="row.space-between.center"
                px={2}
                onClick={() => {
                  push(`/event/${eventId}/catering/manage/all-requests`);
                }}
              >
                {`${count_of_requests_for_approval} requests to review`}
              </BigFilledButton>
            </Div>
          )}
          <MealsTotal />
        </Div>
        <MealDays />
      </Div>
    </Div>
  </Div>
);

export default decorate(Dashboard);
