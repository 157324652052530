import React from "react";
import { Div } from "components/Base";
import Panel from "./Panel";
import Sections from "./Sections/Sections";
import SessionDetail from "./SessionDetail";
import CSSModules from "react-css-modules";

import css from "./styles.scss";

const MainSection = () => (
  <Panel
    styleProps={{ marginBottom: 23, padding: 0 }}
    className="portal-session"
  >
    <Div className="main-section">
      <Div
        className="left-sections"
        style={{ paddingLeft: 25, paddingBottom: 25, paddingTop: 25 }}
      >
        <Sections />
      </Div>
      <Div className="session-details">
        <SessionDetail />
      </Div>
    </Div>
  </Panel>
);

export default CSSModules(MainSection, css);
