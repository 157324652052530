import React, { PureComponent } from "react";
import { find } from "lodash";
import { NONE } from "utils/system-field-values";
import { Div } from "components/Base";

const Wrapper = ({ children }) => (
  <Div
    display="row.flex-start.center"
    minHeight={23}
    style={{
      overflow: "hidden",
      userSelect: "none"
    }}
  >
    {children}
  </Div>
);

const Title = ({ children }) => (
  <Div
    style={{
      padding: "3px 6px 3px 8px",
      fontSize: 12
    }}
  >
    {children}
  </Div>
);

class CredentialsList extends PureComponent {
  render() {
    const { credentials, records } = this.props;
    if (records.length && records.every(c => c.id === NONE.id)) {
      return (
        <Wrapper>
          <div
            style={{
              backgroundColor: "#E7E7E7",
              border: "none",
              borderRadius: 25
            }}
          >
            <Title>{NONE.value}</Title>
          </div>
        </Wrapper>
      );
    }
    return (
      <Wrapper>
        {records.map(record => {
          const credential = find(credentials, { id: record.id });

          if (!credential) {
            return null;
          }

          return (
            <Div
              key={credential.id}
              display="row.flex-start.center"
              mr={2}
              style={{
                flexDirection: "row",
                flexBasis: "auto",
                flexShrink: 0,
                borderRadius: 25,
                backgroundColor: "#EBEBEB",
                fontSize: 1,
                color: "#494949",
                padding: "3px 8px",
                lineHeight: "normal",
                whiteSpace: "nowrap",
                backgroundColor: credential.backgroundColor || "#E7E7E7",
                color: credential.textColor,
                border:
                  credential.backgroundColor === "#FFFFFF"
                    ? "1px solid #E7E7E7"
                    : "none"
              }}
            >
              <Title>{credential.value}</Title>
              <Div
                style={{
                  background: "rgba(0,0,0,0.1)",
                  borderRadius: 50,
                  fontSize: 13,
                  padding: "3px 9px",
                  alignSelf: "stretch",
                  fontWeight: "bold"
                }}
              >
                {record.quantity}
              </Div>
            </Div>
          );
        })}
      </Wrapper>
    );
  }
}

export default CredentialsList;
