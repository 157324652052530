/* eslint-disable no-unused-vars */
import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../../model";
import { actions as recordTypeModalActions } from "ui-kit/RecordTypeModal/model";
import { getEnabledPeopleTypes, getEnabledTypesLength } from "../../selectors";
import CollapsablePanel from "ui-kit/CollapsiblePanel";
import { Div, MediumOutlineButton } from "components/Base";
import NotConfigured from "../NotConfigured";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import { LoadingIcon } from "components/Base";
import { noop } from "utils/General";
import CollapsablePerson from "./StaffPersonnelPerson";
import PortalOld from "EventLight/Common/Types/View/PortalPeopleTemp";

const assignedRowsText = "Type";

const decorate = R.compose(
  connect(
    state => ({
      assignedRows: getters.assignedSharedFiles(state),
      loading: getters.loadingSharedFiles(state),
      enabledPeopleTypes: getEnabledPeopleTypes(state),
      enabledTypesLength: getEnabledTypesLength(state)
    }),
    {
      goToLearnLennd: actions.goToLearnLennd,
      openModal: actions.openShareFileModal,
      goToForms: actions.goToForms,
      deleteAssginedForm: actions.deleteAssginedForm,
      openUpdateModal: actions.openShareFileModal,
      reorderSharedFiles: actions.reorderSharedFiles,
      openShowPeopleTypesModal: () => actions.setShowPeopleTypesModal(true),
      openRecordTypeModal: () => recordTypeModalActions.setShowModal(true)
    }
  ),
  CSSModules(css)
);

const CollapsableStaffPersonnelCard = ({
  assignedRows = [],
  loading = false,
  goToLearnLennd = noop,
  enabledPeopleTypes = [],
  enabledTypesLength = 0,
  openShowPeopleTypesModal = noop,
  openRecordTypeModal = noop,
  //
  moduleId,
  selectedTypeId,
  selectedPermissionSetId
}) => (
  <CollapsablePanel
    heading={() => (
      <Div display="row.space-between.center" my={2} width={1} pr={4}>
        <div className={css.title}>Onboard Staff & Personnel</div>
        <NotConfigured
          configured={!R.isEmpty(assignedRows)}
          loading={loading}
          text={`${enabledTypesLength} ${assignedRowsText}${
            enabledTypesLength !== 1 ? "s" : ""
          } Assigned`}
        />
      </Div>
    )}
    bg="white"
    arrowColor="gray7"
    bc="neutral3"
    right={false}
    bra={1}
    mb={3}
    p={3}
    collapsedDefault={true}
  >
    <div styleName="collapsable">
      <div styleName="description">
        Let users add their team and advance credentials, catering and assets.
      </div>

      {/*
      <div styleName="row-btn">
        <MediumOutlineButton
          color="primary7"
          bc="primary7"
          LeftIcon={() => (
            <span
              class="material-icons"
              style={{ fontSize: 16, marginRight: 8, height: 16 }}
            >
              format_list_bulleted
            </span>
          )}
          width={200}
          onClick={openShowPeopleTypesModal}
        >
          Select people types
        </MediumOutlineButton>
        <MediumOutlineButton
          color="primary7"
          bc="primary7"
          LeftIcon={() => (
            <span
              class="material-icons"
              style={{ fontSize: 16, marginRight: 8, height: 16 }}
            >
              settings
            </span>
          )}
          ml={2}
          width={200}
          onClick={openRecordTypeModal}
        >
          Add new people type
        </MediumOutlineButton>
        <MediumOutlineButton
          color="primary7"
          bc="primary7"
          LeftIcon={() => (
            <span
              class="material-icons"
              style={{ fontSize: 16, marginRight: 8, height: 16 }}
            >
              import_contacts
            </span>
          )}
          ml={2}
          width={200}
          onClick={goToLearnLennd}
        >
          Learn more
        </MediumOutlineButton>
      </div>
          */}

      {loading ? (
        <div styleName="center">
          <LoadingIcon color="gray3" size={50} />
        </div>
      ) : (
        <>
          <PortalOld
            peopleTypesOnly
            moduleId={moduleId}
            selectedPermissionSetId={selectedPermissionSetId}
            selectedTypeId={selectedTypeId}
          />

          {/*
          {R.map(
            record => (
              <CollapsablePerson
                {...record}
                instanceId={record.id}
                key={record.id}
              />
            ),
            enabledPeopleTypes
          )}
             */}
        </>
      )}
    </div>
  </CollapsablePanel>
);

export default decorate(CollapsableStaffPersonnelCard);
