import React from "react";

import { noop } from "utils/General";

import { ButtonFilter } from "ui-kit/FilterControls/View";
import { Text2, FontIcon } from "components/Base";

const LookupPassId = ({ onClick = noop }) => (
  <ButtonFilter hover mt={2} height={38} onClick={onClick}>
    <FontIcon icon="search" mr={2} fs={4} />
    <Text2 bold>Lookup Pass ID</Text2>
  </ButtonFilter>
);

export default LookupPassId;
