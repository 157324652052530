import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { actions, getters } from "Forms/List/model";
import {
  Div,
  AddBlocksIcon,
  Text3,
  BigFilledInput,
  SearchIcon,
  BigFilledButton,
  AddIcon
} from "components/Base";
import { WIDTH, TABS } from "Forms/List/constants";
import { withProps } from "utils/General";
import SortByPopover from "./SortByPopover";

const SearchIconColor = withProps({
  color: "neutral5",
  mr: 3
})(SearchIcon);

const decorate = connect(
  state => ({
    data: getters.data(state),
    filter: getters.filter(state),
    activeTab: getters.activeTab(state)
  }),
  {
    setFilter: actions.setFilter,
    setActiveTab: actions.setActiveTab,
    showFormWizardModal: actions.showFormWizardModal
  }
);

const Header = ({
  data,
  filter,
  setFilter,
  activeTab,
  setActiveTab,
  showFormWizardModal
}) => (
  <Div width={1} bg="white" display="row.center.flex-start" pt={3} shadow={1}>
    <Div
      width={1}
      style={{
        maxWidth: WIDTH
      }}
    >
      <Div display="row.flex-start.center">
        <AddBlocksIcon color="primary8" size={24} />
        <Div fs={4} color="primary8" fw={3} ml={2} mr={3}>
          Forms
        </Div>
        <Div flex={1} px={2}>
          <BigFilledInput
            width={1}
            placeholder="Search forms..."
            bg="neutral0"
            RightIcon={SearchIconColor}
            value={filter}
            onChange={value => setFilter(value)}
            color="neutral5"
            style={{ borderRadius: "20px" }}
            continuous
          />
        </Div>
        <BigFilledButton
          bg="primary8"
          color="white"
          ml={3}
          LeftIcon={AddIcon}
          style={{ borderRadius: "20px" }}
          onClick={showFormWizardModal}
        >
          Create Form
        </BigFilledButton>
      </Div>
      <Div display="row.space-between.center" mt={6}>
        <Div display="row.flex-start.center">
          <Text3
            color={activeTab === TABS.ALL_FORMS ? "primary8" : "black"}
            bb={2}
            bc={activeTab === TABS.ALL_FORMS ? "primary8" : "transparent"}
            pb={1}
            bold
            onClick={() => setActiveTab(TABS.ALL_FORMS)}
          >
            All Forms ({R.propOr(0, "all_forms", data)})
          </Text3>
          <Text3
            color={activeTab === TABS.OPEN ? "primary8" : "black"}
            bc={activeTab === TABS.OPEN ? "primary8" : "transparent"}
            bb={2}
            pb={1}
            ml={5}
            bold
            onClick={() => setActiveTab(TABS.OPEN)}
          >
            Open ({R.propOr(0, "open", data)})
          </Text3>
          <Text3
            color={activeTab === TABS.CLOSED ? "primary8" : "black"}
            bc={activeTab === TABS.CLOSED ? "primary8" : "transparent"}
            bb={2}
            pb={1}
            ml={5}
            bold
            onClick={() => setActiveTab(TABS.CLOSED)}
          >
            Closed ({R.propOr(0, "closed", data)})
          </Text3>
        </Div>
        <SortByPopover />
      </Div>
    </Div>
  </Div>
);

export default decorate(Header);
