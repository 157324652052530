import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { actions, getters } from "EventLight/Forms/Forms/model";
import {
  Div,
  Text3,
  BigFilledInput,
  SearchIcon,
  BigFilledButton,
  AddIcon
} from "components/Base";
import { WIDTH, TABS } from "EventLight/Forms/Forms/constants";
import { withProps } from "utils/General";
import SortByPopover from "./SortByPopover";
import * as flags from "utils/feature-flags";
import { selectFeatureFlag } from "@flopflip/react-redux";
import { noop } from "utils/General";

const SearchIconColor = withProps({
  color: "neutral5",
  mr: 3
})(SearchIcon);

const decorate = connect(
  state => ({
    data: getters.data(state),
    filter: getters.filter(state),
    activeTab: getters.activeTab(state),
    canShowFormModal: selectFeatureFlag(flags.SIMPLE_FORMS.NAME)(state)
  }),
  {
    setFilter: actions.setFilter,
    setActiveTab: actions.setActiveTab,
    showFormModal: actions.showFormModal
  }
);

const Header = ({
  data = [],
  filter = {},
  setFilter = noop,
  activeTab = "allForms",
  setActiveTab = noop,
  showFormModal = noop,
  canShowFormModal = true
}) => (
  <Div width={1} bg="white" display="row.flex-start.flex-start">
    <Div
      width={1}
      style={{
        maxWidth: WIDTH
      }}
    >
      <Div display="row.flex-start.center">
        <Div flex={1}>
          <BigFilledInput
            width={1}
            placeholder="Search forms..."
            bg="neutral0"
            RightIcon={SearchIconColor}
            value={filter}
            onChange={value => setFilter(value)}
            color="neutral5"
            style={{ borderRadius: "20px" }}
            continuous
          />
        </Div>
        <BigFilledButton
          bg="primary8"
          color="white"
          ml={3}
          LeftIcon={AddIcon}
          style={{ borderRadius: "20px" }}
          onClick={() => showFormModal(canShowFormModal)}
        >
          Create Form
        </BigFilledButton>
      </Div>
      <Div display="row.space-between.center" mt={6}>
        <Div display="row.flex-start.center">
          <Text3
            color={activeTab === TABS.ALL_FORMS ? "primary8" : "black"}
            bb={2}
            bc={activeTab === TABS.ALL_FORMS ? "primary8" : "transparent"}
            pb={1}
            bold
            onClick={() => setActiveTab(TABS.ALL_FORMS)}
          >
            All Forms ({R.propOr(0, "all_forms", data)})
          </Text3>
          <Text3
            color={activeTab === TABS.OPEN ? "primary8" : "black"}
            bc={activeTab === TABS.OPEN ? "primary8" : "transparent"}
            bb={2}
            pb={1}
            ml={5}
            bold
            onClick={() => setActiveTab(TABS.OPEN)}
          >
            Open ({R.propOr(0, "open", data)})
          </Text3>
          <Text3
            color={activeTab === TABS.CLOSED ? "primary8" : "black"}
            bc={activeTab === TABS.CLOSED ? "primary8" : "transparent"}
            bb={2}
            pb={1}
            ml={5}
            bold
            onClick={() => setActiveTab(TABS.CLOSED)}
          >
            Closed ({R.propOr(0, "closed", data)})
          </Text3>
        </Div>
        <SortByPopover />
      </Div>
    </Div>
  </Div>
);

export default decorate(Header);
