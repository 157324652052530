import React from "react";
import EditorBase from "./TableConnector";
import Editor from "components/Global/Editors/Percent";

import { getSettings, ep } from "ui-kit/Datagrid/utils";

export default class PercentEditor extends EditorBase {
  render() {
    const props = ep(this.props);
    const editorProps = {
      format: getSettings(props, "percentFormat", "")
    };
    return (
      <Editor
        {...editorProps}
        ref={c => (this.focusRef = c)}
        value={this.state}
        onChange={this.handleChange}
        continuous
      />
    );
  }
}
