import React from "react";

const Description = ({ title, description, customStyle = {} }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      width: "366px",
      marginRight: "70px",
      ...customStyle
    }}
  >
    <div
      style={{
        color: "black",
        fontSize: "18px",
        fontWeight: 600,
        marginBottom: "5px"
      }}
    >
      {title}
    </div>
    <div style={{ fontSize: "12px", height: "98px", color: "black" }}>
      {description}
    </div>
  </div>
);

const TermsDescriptions = () => (
  <div style={{ marginTop: "59px", display: "flex", flexDirection: "column" }}>
    <div style={{ fontSize: "27px", color: "black", marginBottom: "23px" }}>
      Key Terms & Descriptions
    </div>
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      <Description
        title="Portals"
        description="Portals are a custom website that your groups can log into to see
          everything the need to complete, or know, about the evnet. You can
          customize there for differnet types (categories or segments) of
          groups, as well as customize them for specific groups or companies."
      />
      <Description
        title="Records & Record Layouts"
        description={`Records are the term for a single group or company. Records have
        information, or properties related to them, that are stored in
        "fileds". For example, a group has a name field, and a website field.
        These fields can be reordered, shown, or hidden, in a "record layout".`}
      />
      <Description
        title="Catalog"
        description="The catalog in Lennd is a list of all the different resources and item
        that are needed at your event. This can include, but is not limited
        to, Credentials, Tickets, Parking Passes, Meals, Equipment &
        Inventory, booth & Reatil Vendor Space, and more. As an event admin -
        you have full flexibility to define categories and items that fits
        your unique event."
        customStyle={{ paddingRight: "20px" }}
      />
      <Description
        title="Assignment Manager"
        description={`The assignment manager is a specific type of "portal" or webpage in Lennd - that 
        works with item allocations. As an admin - you can specify that a group or company has been approved 
        for a number of credentials, tickets, meals, or pieces of equipment.`}
      />
      <Description
        title="Intake Forms"
        description="Intake forms are one of the key ways to onboard new or returning groups.
         They allow you to send out a single link to your groups - fill out any new or updated
          information - and flow directly into the portal for this event."
      />
      <Description
        title="People Registration"
        description="Portals allow groups to input & register their wokring staff, volunteers, crew, etc. As an admin -
         you can determine whether groups are allowed to enter their people, and what information you need to collect from them,
          as well as the types of item in the catalog they are able to request on behalf of each attending or working person."
        customStyle={{ paddingRight: "36px" }}
      />
    </div>
  </div>
);

export default TermsDescriptions;
