import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { getters, actions } from "../model";
import {
  getSelectedContact,
  getAccountsOutsideContact,
  getAccountsInsideContact
} from "../selectors";
import {
  Div,
  Text0,
  Text2,
  Text3,
  SearchIcon,
  LeftIcon
} from "components/Base";
import GroupCard from "./GroupCard";
import Circle from "ui-kit/Circle";
import SearchBar from "ui-kit/SearchBar/View";
import { withProps } from "utils/General";
import Loading from "ui-kit/Loading";
import { addS } from "utils/General";
import EmptyCard from "./EmptyCard";

const SearchIconPurple = withProps({
  color: "primary7"
})(SearchIcon);

const decorate = connect(
  state => ({
    selectedAccounts: getAccountsInsideContact(state),
    selectedContact: getSelectedContact(state),
    loading: getters.loading(state),
    accountsOutsideContact: getAccountsOutsideContact(state)
  }),
  {
    backToResults: actions.backToResults
  }
);

const Contact = ({ name, type_name }) => (
  <Div display="row.flex-start.center" height={1} mb={2}>
    <Circle bg="altB5" size={28}>
      <Text3 bold color="white">
        {R.toUpper(R.propOr("", 0)(name || ""))}
      </Text3>
    </Circle>
    <Div height={1} display="column.space-between" ml={2}>
      <Text3 bold>{name}</Text3>
      <Text0>{type_name}</Text0>
    </Div>
  </Div>
);

const SelectedGroups = ({
  selectedAccounts,
  onSelectCustomer,
  selectedContact,
  backToResults,
  handlers,
  loading,
  accountsOutsideContact
}) => {
  const accountsOutsideContactLength = R.length(accountsOutsideContact);
  return (
    <Div p={8} pt={4}>
      <Div display="row.flex-start.center" mb={2} onClick={backToResults}>
        <LeftIcon color="primary7" />
        <Text2 ml={1} color="primary7">
          back to results
        </Text2>
      </Div>
      <Contact {...selectedContact} />
      <SearchBar
        RightIcon={SearchIconPurple}
        width={1}
        placeholder="Which group is responsible for this?"
        placeholderWithDots={false}
      />
      {loading ? (
        <Loading />
      ) : (
        <>
          {R.map(
            account => (
              <GroupCard
                {...account}
                selectAccount={({ accountId, accountName }) =>
                  onSelectCustomer({
                    accountId,
                    accountName,
                    contactId: selectedContact.id,
                    contactName: selectedContact.name,
                    contactEmail: selectedContact.email
                  })
                }
                contactId={selectedContact.id}
                contactName={selectedContact.name}
              />
            ),
            selectedAccounts
          )}
          {accountsOutsideContactLength > 0 ? (
            <Text2 mt={2}>
              {accountsOutsideContactLength} result
              {addS(accountsOutsideContactLength)} outside of{" "}
              {selectedContact.name}
            </Text2>
          ) : null}
          {R.map(
            account => (
              <GroupCard
                {...account}
                selectAccount={({ accountId, accountName }) =>
                  onSelectCustomer({
                    accountId,
                    accountName,
                    contactId: selectedContact.id,
                    contactName: selectedContact.name,
                    contactEmail: selectedContact.email
                  })
                }
              />
            ),
            accountsOutsideContact
          )}
          <EmptyCard
            label="group"
            onClick={() =>
              onSelectCustomer({
                accountId: null,
                accountName: null,
                contactId: selectedContact.id,
                contactName: selectedContact.name,
                contactEmail: selectedContact.email
              })
            }
          />
          <Div
            bra={1}
            bg="neutral0"
            p={4}
            mt={5}
            display="column.flex-start.center"
            textAlign="center"
            height={113}
          >
            <Div>Can't find the group you're looking for?</Div>
            <Div display="row.flex-start.center" mt={4}>
              <Div
                width={148}
                height={38}
                bra={1}
                ba={1}
                bg={{
                  default: "neutral0",
                  hover: "neutral1"
                }}
                bc="primary7"
                color="primary7"
                display="row.center.center"
                onClick={() => {
                  handlers.showAddAccountModal({
                    contactId: selectedContact.id,
                    contactName: selectedContact.name,
                    contactEmail: selectedContact.email,
                    isLastStep: true
                  });
                }}
              >
                Add New Group
              </Div>
            </Div>
          </Div>
        </>
      )}
    </Div>
  );
};

export default decorate(SelectedGroups);
