import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import { PackageContainer } from "Sponsors/Common/View";
import { getPackagesByType } from "Sponsors/SelfSetup/selectors";

import PackageCard from "./PackageCard";

const decorate = connect((state, props) => ({
  packages: getPackagesByType(state, props)
}));

const PackagesList = ({ title = "", caption = "", packages = [] }) => (
  <PackageContainer title={title} caption={caption}>
    {R.map(
      pack => (
        <PackageCard {...pack} key={pack.id} mb={2} />
      ),
      packages
    )}
  </PackageContainer>
);

export default decorate(PackagesList);
