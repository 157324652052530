import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import Label from "components/Global-2016/Forms/Label";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const allowedTypes = ["number", "percent", "currency"];

@CSSModules(css)
class CalculatedFieldSettings extends Component {
  @autobind
  onChange(e) {
    this.updateExpression(e.target.value);
  }

  @autobind
  onFieldSelect(e) {
    const { value: { calculatedNumberExpression } } = this.props;
    this.updateExpression(`${calculatedNumberExpression} ${e.target.value}`);
  }

  updateExpression(calculatedNumberExpression) {
    const settings = this.props.value;
    this.props.onChange({
      ...settings,
      calculatedNumberExpression
    });
  }

  render() {
    const { value: { calculatedNumberExpression }, columns } = this.props;
    return (
      <div>
        <div className="input-wrapper">
          <Label>Expression</Label>
          <select
            key="type"
            styleName="select"
            onChange={this.onFieldSelect}
            value={0}
          >
            <option value={0}>Insert a field</option>
            {columns
              .filter(f => allowedTypes.includes(f.type))
              .map(({ id, name }) => (
                <option value={`[${name}]{{${id}}}`} key={id}>
                  {name}
                </option>
              ))}
          </select>
          <textarea
            styleName="expression"
            value={calculatedNumberExpression}
            onChange={this.onChange}
          />
        </div>
      </div>
    );
  }
}

CalculatedFieldSettings.propTypes = {
  value: PropTypes.shape({
    calculatedNumberExpression: PropTypes.string.isRequired
  }).isRequired,
  columns: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default CalculatedFieldSettings;
