import React from "react";
import { SubNavWrapper, SubNavLink } from "OrgLight/Common/SubNav";
import { TAB_IDS } from "./constants";
import { withRouter } from "react-router";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

const SubNav = ({ selectedView, router, params, tabs = [] }) => (
  <SubNavWrapper tabs={tabs} supportId="orgGroups">
    <SubNavLink
      name="Manage"
      active={selectedView === TAB_IDS.MANAGE}
      onClick={() =>
        router.push({
          pathname: `/org-light/${params.orgId}/groups/manage/${STANDARD_MODULE_IDS.accounts.id}`
        })
      }
    />
    <SubNavLink
      name="Settings"
      active={[
        TAB_IDS.TYPES,
        TAB_IDS.FIELDS,
        TAB_IDS.GUEST_LIST,
        TAB_IDS.PORTAL,
        TAB_IDS.LAYOUT,
        TAB_IDS.DOCUMENT_TEMPLATES,
        TAB_IDS.REPORT_TEMPLATES,
        TAB_IDS.EMAIL_TEMPLATES
      ].includes(selectedView)}
      onClick={() =>
        router.push({
          pathname: `/org-light/${params.orgId}/groups/settings/email-templates`
        })
      }
    />
  </SubNavWrapper>
);

export default withRouter(SubNav);
