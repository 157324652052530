export default {
  id: "dependency",
  name: "Dependency",
  settings: {
    mustBeChildField: false,
    canBeChildField: true,
    canBeParentField: false,
    canBeChildOfFieldTypes: ["all"]
  }
};
