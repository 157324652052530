import * as R from "ramda";
import { createHandlers } from "redux-mvc";

const model = createHandlers({
  iniState: {
    fetching: {},
    fields: {
      byId: {}
    },
    submissions: {
      byId: {}
    },
    eventTemplates: {
      byId: {}
    },
    recordReferences: {
      byId: {}
    },
    fieldReferences: {
      byId: {}
    },
    support: {
      articles: {}
    },
    zoneCategories: {
      byId: {},
      byEventId: {}
    }
  },
  reducers: {
    error: R.identity,
    fetching: R.identity,
    addReferences: ({ fieldReferences, recordReferences }, { payload }) => ({
      fieldReferences: {
        byId: R.mergeAll([
          fieldReferences.byId,
          R.indexBy(
            R.prop("id"),
            R.reduce(
              (fields, module) =>
                R.concat(
                  fields,
                  R.pathOr(
                    R.propOr([], "fields", module),
                    ["fields", "fields"],
                    module
                  )
                ),
              [],
              R.values(payload)
            )
          )
        ])
      },
      recordReferences: {
        byId: R.mergeAll([
          recordReferences.byId,
          R.indexBy(
            R.prop("id"),
            R.reduce(
              (records, module) => {
                const val = R.propOr([], "records", module);
                if (Array.isArray(val)) {
                  return R.concat(records, val);
                }
                const recordsWithId = R.map(
                  ([id, val]) => ({ id, ...val }),
                  Object.entries(val)
                );
                return R.concat(records, recordsWithId);
              },
              [],
              R.values(payload)
            )
          )
        ])
      }
    }),
    addSubmission: ({ fields, submissions }, { payload: submission }) => ({
      submissions: {
        ...submissions,
        byId: {
          ...submissions.byId,
          [submission.id]: submission
        }
      },
      fields: {
        ...fields,
        byId: R.mergeAll([
          fields.byId,
          R.indexBy(R.prop("id"), R.pathOr([], ["form", "fields"], submission))
        ])
      }
    }),
    addEventTemplate: (state, { payload: { data, eventUUID } }) =>
      data && eventUUID
        ? {
            eventTemplates: {
              ...state.eventTemplates,
              byId: R.mergeAll([
                state.eventTemplates.byId,
                { [eventUUID]: data }
              ])
            }
          }
        : state,
    supportResponse: (_, { payload: { articles } }) => ({
      support: {
        articles
      }
    }),
    zoneCategoriesResponse: (
      { zoneCategories },
      { payload: { zones, eventId } }
    ) => ({
      zoneCategories: {
        byId: R.mergeAll([zoneCategories.byId, R.indexBy(R.prop("id"), zones)]),
        byEventId: R.assoc(
          eventId,
          R.uniq(
            R.concat(
              R.map(R.prop("id"), zones),
              zoneCategories.byEventId[eventId] || []
            )
          ),
          zoneCategories.byEventId
        )
      }
    })
  },
  namespace: "Data"
});

const { actions, getters } = model;

export { actions, getters };

export default model;
