import Helpers from "utils/Global/Helpers";

export default {
  post: (credentials, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/form/people-blocks/${data.blockId}/people`,
        credentials,
        data,
        success,
        error
      });
    }),
  put: (credentials, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/form/people-blocks/${data.blockId}/people`,
        credentials,
        data,
        success,
        error
      });
    }),
  delete: (credentials, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/form/people-blocks/${data.blockId}/people`,
        credentials,
        data,
        success,
        error
      });
    })
};
