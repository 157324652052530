import PropTypes from "prop-types";
import React, { Component } from "react";
import Button from "./Button";
import HeaderMenu from "./Menu";
import ActionsMenuButton from "components/Global/CRM/ActionsMenu";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const style = {
  viewLink: {
    marginLeft: "15px",
    fontWeight: "bold",
    fontSize: 13,
    color: "#515151",
    display: "flex",
    alignItems: "center",
    transition: "background 0.2s ease-in-out",
    cursor: "pointer",
    ":hover": {
      background: "#fafafa"
    }
  }
};

@CSSModules(css)
class Header extends Component {
  render() {
    const {
      title,
      // count,
      toggleTable,
      goToModule,
      exportXLSX,
      exportCSV,
      isOpen
    } = this.props;
    const buttonDetailsProps = {
      style: style.viewLink,
      text: "View All",
      iconRight: (
        <i className={[css.viewIcon, "material-icons"].join(" ")}>arrow_back</i>
      ),
      action: goToModule
    };

    return (
      <div styleName="panelHeading">
        <div
          styleName="headingLeft"
          onClick={toggleTable}
          role="button"
          tabIndex="0"
        >
          <div styleName="section">
            <i className={`material-icons ${isOpen ? "" : "closed"}`}>
              arrow_drop_down
            </i>
            {/*
            {count > 0 ? (
              <div styleName="count">
                {count}
              </div>
            ) : ''}
            */}
            <div styleName="label">{title}</div>
          </div>
          <Button {...buttonDetailsProps} />
        </div>
        <div styleName="headingRight">
          {isOpen ? (
            <ActionsMenuButton>
              <HeaderMenu exportCSV={exportCSV} exportXLSX={exportXLSX} />
            </ActionsMenuButton>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  // count: PropTypes.number.isRequired
  exportCSV: PropTypes.func.isRequired,
  exportXLSX: PropTypes.func.isRequired,
  goToModule: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  toggleTable: PropTypes.func.isRequired
};

export default Header;
