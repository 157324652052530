import { createSelector } from "reselect";

import * as R from "ramda";

import { getters as SearchBarGetters } from "ui-kit/SearchBar";
import { getters } from "./model";
import { getters as CartGetters } from "Portal/Cart/model";
import { getters as FormGetters } from "ui-kit/Form/model";
import { FILTER_MODE, PEOPLE_LIST_ID } from "./constants";

const countAllItems = R.compose(
  R.reduce((acc, curr) => (acc += curr.items.length), 0),
  R.flatten,
  R.pluck("groups")
);

const isCategoryNameEqual = R.propEq("name");

export const products = createSelector(
  getters.categories,
  categories => categories
);

export const filteredProducts = createSelector(
  getters.categories,
  getters.selectedCategory,
  getters.filterMode,
  (categories, selectedCategory, filterMode) => {
    if (filterMode === FILTER_MODE.BY_INPUT) {
      return R.compose(
        R.filter(
          R.compose(
            R.gt(R.__, 0),
            R.length,
            R.prop("items")
          )
        ),
        R.flatten,
        R.pluck("groups"),
        R.map(group =>
          R.evolve({
            groups: R.map(
              R.evolve({
                name: subcategory => `${group.name} / ${subcategory}`
              })
            )
          })(group)
        )
      )(categories);
    }

    if (selectedCategory) {
      const topLevel = R.find(isCategoryNameEqual(selectedCategory))(
        categories
      );

      if (topLevel) return R.propOr([], "groups", topLevel);

      const topLevelContainer = R.find(
        R.compose(
          R.any(isCategoryNameEqual(selectedCategory)),
          R.prop("groups")
        )
      )(categories);

      return [
        R.find(isCategoryNameEqual(selectedCategory))(topLevelContainer.groups)
      ];
    }
    return [];
  }
);

export const getLength = createSelector(
  getters.categories,
  countAllItems
);

export const getSeachText = createSelector(
  SearchBarGetters.searchTerm,
  getLength,
  (searchTerm, length) => {
    const text = R.trim(searchTerm);
    return text ? `${length} Results for "${text}"` : "";
  }
);

export const getParentCategory = createSelector(
  getters.categories,
  getters.selectedCategory,
  (categories, selectedCategory) => {
    const topLevel = R.find(isCategoryNameEqual(selectedCategory), categories);

    if (topLevel) return R.prop("name", topLevel);

    return R.compose(
      R.prop("name"),
      R.find(
        R.compose(
          R.any(isCategoryNameEqual(selectedCategory)),
          R.prop("groups")
        )
      )
    )(categories);
  }
);

export const getInvalidRequiredCategories = createSelector(
  CartGetters.items,
  getters.categories,
  (items, categories) => {
    if (!items || !items.length) {
      return [];
    }

    const requiredCategories = R.compose(
      R.map(c => ({ id: c.id, name: c.name })),
      R.filter(R.propEq("required", true)),
      R.flatten,
      R.pluck("groups")
    )(categories);

    const invalidCategories = [];

    requiredCategories.forEach(c => {
      if (!items.some(i => i.categoryId === c.id)) {
        invalidCategories.push(c);
      }
    });

    return invalidCategories;
  }
);

export const getIsSaveSelectedPeopleEnabled = createSelector(
  FormGetters.fields,
  fields => !R.isEmpty(R.pathOr([], [PEOPLE_LIST_ID, "value"], fields))
);

export const getDropdownPeopleList = createSelector(
  getters.peopleList,
  R.map(({ id, name }) => ({
    value: id,
    label: name
  }))
);
