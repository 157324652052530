import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { actions, getters } from "../model";
import { getDropdownStatus, getTotal } from "../selectors";
import { Div, BigFilledButton } from "components/Base";
import SearchBar from "ui-kit/SearchBar/View";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { Dropdown } from "ui-kit/Form/View";
import Loading from "ui-kit/Loading";
import Table, { TableWrapper } from "ui-kit/Table/View";
import {
  TABLE_INSTANCE_ID,
  VARIANTS_TRACKED,
  DROPDOWN_STATUS,
  DROPDOWN_STATUS_VALUES,
  ALL_ITEMS_OPTION
} from "../constants";
import PaginationBar from "ui-kit/PaginationBar/View/Slim";
import AddInventoryModalWrapper from "./AddInventoryModalWrapper";

const decorate = R.compose(
  connect(
    state => ({
      variantsTracked: getters.variantsTracked(state),
      loading: getters.loading(state),
      refreshing: getters.refreshing(state),
      total: getTotal(state),
      dropdownStatus: getDropdownStatus(state)
    }),
    {
      init: actions.init,
      setShowAddInventoryModal: actions.setShowAddInventoryModal
    }
  ),
  CSSModules(css)
);

const pageOptions = [5, 10, 15, 25, 50];

const ItemCatalogInventory = ({
  init,
  variantsTracked,
  loading,
  refreshing,
  total,
  dropdownStatus,
  setShowAddInventoryModal
}) => {
  useEffect(() => {
    init();
  }, []);
  return loading ? (
    <div styleName="wrapper">
      <Loading />
    </div>
  ) : (
    <div styleName="wrapper">
      <AddInventoryModalWrapper />
      <div styleName="row" style={{ marginBottom: 10 }}>
        <Div display="row.space-between.center" width={1}>
          <Div display="row.flex-start.center">
            <SearchBar width={250} />
            <div styleName="dropdown">
              <Dropdown
                options={dropdownStatus}
                fieldId={DROPDOWN_STATUS}
                usePortal
                placeholder="Filter by status"
                iniValue={DROPDOWN_STATUS_VALUES.ALL_INVENTORY}
              />
            </div>
            <div styleName="dropdown">
              <Dropdown
                options={variantsTracked}
                fieldId={VARIANTS_TRACKED}
                usePortal
                placeholder="Filter by item"
                iniValue={ALL_ITEMS_OPTION}
              />
            </div>
          </Div>

          <BigFilledButton
            bg="altB5"
            ml={1}
            onClick={() => setShowAddInventoryModal(true)}
          >
            Add Inventory
          </BigFilledButton>
        </Div>
      </div>
      <TableWrapper loading={refreshing} instanceId={TABLE_INSTANCE_ID}>
        <Table
          instanceId={TABLE_INSTANCE_ID}
          showEmptyTable={true}
          showOpenRecord={false}
        />
      </TableWrapper>

      <PaginationBar pageSizeOptions={pageOptions} total={total} px={2} />
    </div>
  );
};

export default decorate(ItemCatalogInventory);
