import { createHandlers } from "redux-mvc";

import * as R from "ramda";

import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  showNoResults: false,
  data: {}
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    openAddAlertModal: R.identity
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
