import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
// import moment from "moment";

import { getters, actions } from "../model";
import { CollapsablePanelType1, Div, Text2 } from "components/Base";
import { FieldEditor, FieldFormatter } from "ui-kit/FieldTypes/View";
import { withProps } from "utils/General";

import { optionValues, activityDuration, settingValues } from "../selectors";
import {
  SCHEDULING_OPTIONS_FIELDS
  // SCHEDULING_SETTINGS_FIELDS
} from "../constants";

const FieldRow = withProps({
  display: "row.flex-start.center",
  my: 1,
  height: 40
})(Div);

const FieldsInRow = ({ editingFieldId, name, fields, values, onEdit }) => {
  return (
    <FieldRow>
      <Text2 width={0.3}>{name}</Text2>
      {R.map(
        field => (
          <Div width={0.35} key={field.id} onClick={e => e.stopPropagation()}>
            {editingFieldId === field.id ? (
              <Div
                p={1}
                display={field.name ? "row.flex-start.center" : "block"}
              >
                <FieldEditor field={field} data={values} />
                {field.name && <Text2 ml={2}>{field.name}</Text2>}
              </Div>
            ) : (
              <Div
                width={1}
                py={2}
                px={1}
                onClick={() => onEdit(field.id)}
                bg={{ default: "white", hover: "neutral0" }}
                bra={1}
              >
                <FieldFormatter field={field} data={values} />
              </Div>
            )}
          </Div>
        ),
        fields
      )}
    </FieldRow>
  );
};

const decorate = connect(
  (state, props) => ({
    editingFieldId: getters.editingFieldId(state, props),
    duration: activityDuration(state, props),
    optionValues: optionValues(state, props),
    settingValues: settingValues(state, props),
    history: getters.history(state, props)
  }),
  {
    onEdit: actions.onEdit
  }
);

const OptionsAndSettings = ({
  optionValues,
  //  settingValues,
  duration,
  editingFieldId,
  // history,
  onEdit
}) => (
  <>
    <Div bt={1} bc="neutral4" mb={2}>
      <CollapsablePanelType1
        collapsedDefault
        headingText="All Scheduling Options"
        mt={2}
      >
        <Div display="column.flex-start.stretch" pb={4} pt={2}>
          {R.map(
            ({ id, name, fields }) => (
              <FieldsInRow
                key={id}
                editingFieldId={editingFieldId}
                name={name}
                fields={fields}
                values={optionValues}
                onEdit={onEdit}
              />
            ),
            SCHEDULING_OPTIONS_FIELDS
          )}
          <FieldRow>
            <Text2 width={0.3}>Duration</Text2>
            <Div width={0.7}>{duration}</Div>
          </FieldRow>
        </Div>
      </CollapsablePanelType1>
    </Div>

    {/*
    // @TODO: Need to implement on the API
    <Div bt={1} bc="neutral4" mb={2}>
      <CollapsablePanelType1 collapsedDefault headingText="Settings" mt={2}>
        <Div display="column.flex-start.stretch">
          {R.map(
            ({ id, name, fields }) => (
              <FieldsInRow
                key={id}
                editingField={editingFieldId}
                name={name}
                fields={fields}
                values={settingValues}
                isEditing
                onEdit={onEdit}
              />
            ),
            SCHEDULING_SETTINGS_FIELDS
          )}
        </Div>
      </CollapsablePanelType1>
    </Div>
   */}

    {/*
    // @TODO: Need to implement on the API
    <Div bt={1} pt={4} bc="neutral4" display="column.flex-start.stretch">
      <FieldRow display="row.flex-start.flex-start">
        <Text2 width={0.3}>History</Text2>
        <Div width={0.7} display="column.flex-start.stretch">
          {R.map(
            ({ id, label, created_at }) => (
              <Div key={id} display="row.flex-start.center" mb={2}>
                <Text1>{label}</Text1>
                <Text1 ml={2}>
                  {moment(created_at).format(HISTORY_DATE_FORMAT)}
                </Text1>
              </Div>
            ),
            history
          )}
        </Div>
      </FieldRow>
    </Div>
     */}
  </>
);

export default decorate(OptionsAndSettings);
