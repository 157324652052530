import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { debounce } from "lodash";
import { Div, Text3, Text6 } from "components/Base";
import resolveEditorProps from "components/Global/Editors/utils/resolveEditorProps";
import resolveEditor from "components/Global/StandAloneEditors/utils/resolveEditor";
import BackButton from "./BackButton";
import NextButton from "./NextButton";
import MediaQuery from "react-responsive";
import { getters } from "HealthPass/HealthPassForm/model";
import PasswordChecklist from "react-password-checklist";

const Mobile = props => <MediaQuery {...props} maxWidth={820} />;
const Default = props => <MediaQuery {...props} minWidth={821} />;

export const Section = ({ field }) => {
  return (
    <Div className="lennd-valid" mb={4} mt={7}>
      <Div display="row.flex-start" color="black" fw={3} mb={1} fs={5}>
        {field.name}
      </Div>
      {field.description && field.description.length ? (
        <Div
          fs={2}
          color="gray8"
          mb={1}
          style={{
            whiteSpace: "pre-line"
          }}
        >
          {field.description}
        </Div>
      ) : null}
    </Div>
  );
};

export const Field = ({ field, values, updateValues }) => {
  const maxLength = R.pathOr("", ["settings", "maxLength"], field);
  const Editor = resolveEditor(field);
  const debounceOnChange = debounce(updateValues, 100);

  return (
    <Div className="lennd-valid" mb={3}>
      <Div
        display="row.flex-start"
        color={field.isValid ? "black" : "danger7"}
        fw={3}
        mb={1}
      >
        {field.name}
        {field.required ? (
          <Div color={field.isValid ? "black" : "danger7"} fw={3} ml={1}>
            *
          </Div>
        ) : null}
      </Div>
      {field.settings &&
      field.settings.description &&
      field.settings.description.length ? (
        <Div
          fs={2}
          color="gray6"
          mb={1}
          style={{
            whiteSpace: "pre-line"
          }}
        >
          {field.settings.description}
        </Div>
      ) : null}
      <Editor
        // @NOTE: Only save email onBlur due to pinging API
        continuous={field.type === "email" ? false : true}
        onChange={value => debounceOnChange({ value, id: field.id })}
        value={R.propOr("", field.id, values)}
        autocomplete={
          field.type === "password"
            ? "new-password"
            : `autocomplete_${field.id}`
        }
        disabled={field.disabled}
        maxLength={maxLength}
        {...resolveEditorProps(field, {})}
      />
      {!R.isEmpty(maxLength) ? (
        <div
          style={{
            fontSize: 12,
            padding: "4px 0 0 4px",
            color: "#aaa",
            fontWeight: 600
          }}
        >
          {R.pathOr("", [field.id, "value"], values).length}/{maxLength}{" "}
          Character
          {R.pathOr("", [field.id, "value"], values).length !== 1 ? "s" : ""}
        </div>
      ) : null}
      {field.id === "password" ? (
        <div
          style={{
            marginTop: "5px",
            marginBottom: "10px",
            fontSize: "14px",
            color: "#222"
          }}
        >
          <PasswordChecklist
            iconSize={12}
            rules={["length", "specialChar", "number", "capital"]}
            minLength={8}
            value={R.pathOr("", [field.id, "value"], values)}
          />
        </div>
      ) : null}
    </Div>
  );
};

const FieldSection = ({
  disableShowAlert,
  fieldsChildren,
  updateValues,
  values,
  fields,
  error
}) => (
  <Div width={1} bg="neutral0" bra={2} p={5}>
    {fieldsChildren}
    {R.map(
      field =>
        field.type === "section" ? (
          <Section key={field.id} field={field} />
        ) : (
          <Field
            key={field.id}
            field={field}
            updateValues={updateValues}
            values={values}
          />
        ),
      fields
    )}
    {error && error.length ? (
      <Div bg="danger3" color="gray7" p={3} bra={1} mt={3}>
        {error}
      </Div>
    ) : null}
    <Div display="row.space-between.center" mt={5}>
      <BackButton />
      <NextButton disableShowAlert={disableShowAlert} />
    </Div>
  </Div>
);

const decorate = connect(state => ({
  isLoggedIn: getters.isLoggedIn(state)
}));

const InputFields = ({
  disableShowAlert,
  data,
  updateValues,
  values,
  fields,
  children,
  error
}) => (
  <Div>
    <Div mb={6}>
      <Text6 bold color="black">
        {R.propOr("", "title", data)}
      </Text6>
      {R.propOr(false, "show_description", data) && (
        <Text3
          mt={1}
          style={{
            whiteSpace: "pre-line"
          }}
        >
          {R.propOr("", "description", data)}
        </Text3>
      )}
    </Div>
    <Mobile>
      <Div width={1}>
        <FieldSection
          disableShowAlert={disableShowAlert}
          fieldsChildren={children}
          updateValues={updateValues}
          values={values}
          fields={fields}
          error={error}
        />
      </Div>
    </Mobile>
    <Default>
      <Div display="row">
        <FieldSection
          disableShowAlert={disableShowAlert}
          fieldsChildren={children}
          updateValues={updateValues}
          values={values}
          fields={fields}
          error={error}
        />
      </Div>
    </Default>
  </Div>
);

export default decorate(InputFields);
