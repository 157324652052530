import React, { Component } from "react";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FormSeparator extends Component {
  render() {
    return (
      <div styleName="container">
        <div styleName="separator" />
      </div>
    );
  }
}

FormSeparator.propTypes = {};

export default FormSeparator;
