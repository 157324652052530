import * as R from "ramda";
import React, { useRef } from "react";
import CSSModules from "react-css-modules";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { getEvents } from "../selectors";
import { actions, getters } from "../model";

import { Div } from "components/Base";
import LoadingOverlay from "ui-kit/LoadingOverlay";
import ScheduleList from "./ScheduleList";

import styles from "./styles.scss";

import { useCalendar } from "../utils";

const decorate = R.compose(
  connect(
    (state, props) => ({
      events: getEvents(state, props),
      currentDate: getters.currentDate(state, props)
    }),
    bindInstance({
      init: actions.init,
      eventCreate: actions.eventCreate,
      eventEdit: actions.eventEdit,
      eventResizeStart: actions.eventResizeStart,
      eventResizeRequest: actions.eventResizeRequest,
      eventDragStart: actions.eventDragStart,
      eventDropRequest: actions.eventDropRequest
    })
  ),
  CSSModules(styles, { allowMultiple: true })
);

const MonthLayout = ({
  loading,
  instanceId,
  leftSidebar = false,
  scheduleListEventDateFormat,
  ...props
}) => {
  const wrapperRef = useRef();
  const calendarRef = useRef();
  useCalendar({
    ...props,
    wrapperRef,
    calendarRef,
    height: "parent"
  });

  return (
    <div styleName="calendar row" ref={wrapperRef}>
      {leftSidebar && (
        <ScheduleList
          instanceId={instanceId}
          timezone={props.timezone}
          width={400}
          style={{ minWidth: 400 }}
          br={1}
          bc="neutral3"
          eventDateFormat={scheduleListEventDateFormat}
        />
      )}
      <Div flex={1} style={{ overflow: "auto", flexGrow: 0 }}>
        <div ref={calendarRef} />
      </Div>
      {!leftSidebar && (
        <ScheduleList
          instanceId={instanceId}
          timezone={props.timezone}
          width={400}
          style={{ minWidth: 400 }}
        />
      )}
      {loading && <LoadingOverlay />}
    </div>
  );
};

export default decorate(MonthLayout);
