/* eslint-disable react/prop-types */

import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import { RESPONSIVE_WIDTH, MEDIA_MAX_WIDTH } from "./constants";

import { noop } from "utils/General";
import isValid from "utils/value-types/validations/is-valid";
import getRecordName from "utils/get-record-name";

import MiniModalWrapperResponsive from "ui-kit/MiniModalWrapperResponsive";
import Loading from "ui-kit/Loading";
import { Div } from "components/Base";
import { FieldDisplay } from "components/Global/Module/Modals/ViewRecord/Parts";
import resolveReadOnlyFields from "components/Event/Module/utils/resolveReadOnlyFields";
import Header from "./Header";
import Body from "./Body";
import Footer from "./Footer";
import AddItemsModal from "./Modals/AddItemsModal";
import CredentialsBlockModal from "./Modals/CredentialsBlockModal";

import { actions, getters } from "./model";
import {
  eventDetailsToPass,
  isReadOnly,
  getMessageError,
  getSelectedFields,
  getRequestableItems,
  getCanCloseModal
} from "./selectors";
import { references } from "redux/modules/entityReferences/selectors";

const mapWithIndex = R.addIndex(R.map);

const FieldFormatter = ({
  field,
  record,
  references,
  eventId,
  moduleId,
  isEditing,
  setIsEditing,
  saveField,
  eventDetails,
  readOnlyFields,
  disabled
}) => {
  if (!R.isNil(field)) {
    return (
      <FieldDisplay
        field={field}
        name={field.name}
        required={field.required}
        data={{
          id: field.id,
          type: field.type
        }}
        saveField={saveField}
        eventDetails={eventDetails}
        isEditing={isEditing}
        moduleId={moduleId}
        setIsEditing={setIsEditing}
        readOnlyFields={readOnlyFields}
        references={references}
        value={R.path(["values", field.id], record)}
        disabled={disabled}
        dependentValues={{
          meta: {
            eventId,
            eventDetails,
            columnSettings: {
              ...field.settings,
              suppressLinkToRecord: true
            },
            references
          }
        }}
        bgDefaultColor="white"
      />
    );
  }
  return null;
};

const decorate = connect(
  (state, props) => ({
    moduleId: props.moduleId,
    accountId: props.accountId,
    recordId: props.recordId,
    contactId: props.recordId,

    eventId: eventDetailsToPass(state, props).id,
    eventDetails: eventDetailsToPass(state, props),

    messageError: getMessageError(state),
    requestableItems: getRequestableItems(state),
    selectedFields: getSelectedFields(state),
    summary: getters.summary(state),
    isReadOnly: isReadOnly(props),

    record: getters.record(state),
    references: references(state),

    isLoading: getters.isLoading(state),
    canCloseModal: getCanCloseModal(state)
  }),
  {
    init: actions.init,
    addValue: actions.addValue,
    removeLineItem: actions.removeLineItem,
    updateRelationship: actions.updateRelationship
  }
);

const PortalPeopleView = ({
  isLoading = false,
  canCloseModal = false,
  isViewingAsAdmin = false,
  hideModal = noop,
  isReadOnly = false,
  setItemBlockError = noop,
  selectedFields = [],
  summary = [],
  accountId = null,
  moduleId = null,
  recordId = null,
  contactId = null,
  eventId = null,
  eventDetails = {},
  messageError = "",
  requestableItems = [],
  record = {},
  references = {},
  init = noop,
  addValue = noop,
  removeLineItem = noop,
  updateRelationship = noop
}) => {
  const [isEditing, setIsEditing] = useState(null);
  const [showItemBlockError] = useState(false);
  const [
    isShowingCredentialsBlockModal,
    setIsShowingCredentialsBlockModal
  ] = useState(false);

  const [selectedBlock, setSelectedBlock] = useState({});
  const [isShowAddItemsModal, setIsShowAddItemsModal] = useState(false);

  const handleShowCredentialsBlockModal = block => {
    setSelectedBlock(block);
    setIsShowingCredentialsBlockModal(true);
  };

  const formattedRequestableItems = requestableItems.map(block => ({
    ...block,
    onClick: () => handleShowCredentialsBlockModal(block)
  }));

  if (isViewingAsAdmin) {
    formattedRequestableItems.push({
      title: "Request from all items",
      status: "admin",
      type: "admin",
      onClick: () => setIsShowAddItemsModal(true)
    });
  }

  useEffect(() => {
    init({ moduleId, eventId, accountId, recordId });
  }, []);

  const readOnlyFields = resolveReadOnlyFields({ moduleId });

  const mapFieldFormatter = mapWithIndex((field, i) => (
    <FieldFormatter
      key={i}
      readOnlyFields={readOnlyFields}
      eventId={eventId}
      field={field}
      record={record}
      references={references}
      isEditing={isEditing}
      eventDetails={eventDetails}
      setIsEditing={setIsEditing}
      moduleId={moduleId}
      disabled={isReadOnly}
      saveField={value => {
        if ((field.required && isValid(value, field.type)) || !field.required) {
          if (field.id === "role") {
            updateRelationship({
              role: R.prop("value")(value),
              contactId,
              eventId,
              accountId,
              value,
              field
            });
          } else {
            addValue({
              eventId,
              value,
              fieldId: field.id,
              recordId: contactId,
              moduleId
            });
          }
        }
      }}
    />
  ));

  const summaryWithHandlers = summary.map(section => {
    return {
      ...section,
      item_groups: section.item_groups.map(group => {
        return {
          ...group,
          items: group.items.map(item => {
            return {
              ...item,
              deleteItem: isReadOnly
                ? undefined
                : () => removeLineItem(item.lineItemId)
            };
          })
        };
      })
    };
  });

  const leftContent = <Div>{mapFieldFormatter(selectedFields)}</Div>;

  return (
    <>
      <MiniModalWrapperResponsive
        hideModal={hideModal}
        showHeader={false}
        isPortal={false}
        showModal
        stopPropagation
        responsiveWidth={RESPONSIVE_WIDTH}
        mediaMaxWidth={MEDIA_MAX_WIDTH}
        style={{
          height: "100%",
          maxHeight: "calc(100vh - 60px)",
          minHeight: "500px"
        }}
      >
        {isLoading ? (
          <Div bg="neutral2">
            <Loading />
          </Div>
        ) : (
          <>
            <Header
              hideModal={hideModal}
              heading={getRecordName(STANDARD_MODULE_IDS.contacts.id, record)}
              typeName={R.pathOr("", ["type", "name"], record)}
              canCloseModal={canCloseModal}
            />
            <Body
              {...{
                isReadOnly,
                formattedSummary: summaryWithHandlers,
                requestableItems: formattedRequestableItems,
                removeLineItem,
                showItemBlockError,
                messageError
              }}
              leftContent={leftContent}
            />
            <Footer
              hideModal={hideModal}
              setItemBlockError={setItemBlockError}
              canCloseModal={canCloseModal}
            />
          </>
        )}
      </MiniModalWrapperResponsive>

      {isShowingCredentialsBlockModal && (
        <MiniModalWrapperResponsive showModal showHeader={false}>
          <CredentialsBlockModal
            eventDetails={eventDetails}
            block={selectedBlock}
            summary={summaryWithHandlers}
            accountId={accountId}
            contactId={contactId}
            onDone={() => init({ moduleId, eventId, accountId, recordId })}
            hideModal={() => setIsShowingCredentialsBlockModal(false)}
          />
        </MiniModalWrapperResponsive>
      )}

      {isShowAddItemsModal && (
        <MiniModalWrapperResponsive showModal showHeader={false}>
          <AddItemsModal
            eventDetails={eventDetails}
            accountId={accountId}
            contactId={contactId}
            onDone={() => init({ moduleId, eventId, accountId, recordId })}
          />
        </MiniModalWrapperResponsive>
      )}
    </>
  );
};

export default decorate(PortalPeopleView);
