import DateInput from "./DateInput";
import TextInput from "./TextInput";
import TimeInput from "./TimeInput";
import DropdownInput from "./DropdownInput";
import DatetimeInput from "./DateTimeInput";

const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);

const inputs = {
  DatetimeInput,
  DateInput,
  TextInput,
  TimeInput,
  DropdownInput
};

export const getInput = type => {
  const inputName = `${capitalize(type)}Input`;

  if (inputs.hasOwnProperty(inputName)) {
    return inputs[inputName];
  }

  throw new Error(`the input type: "${type}" doesnt exists`);
};
