/* eslint-disable react/prop-types */

import React from "react";
import {
  Div,
  CheckCircleIcon,
  Text3,
  Text4,
  ErrorIcon,
  CloseIcon,
  SuperFilledButton,
  DownloadIcon
} from "components/Base";
import * as R from "ramda";
import { navigateTo, addS } from "utils/General";
import Circle from "ui-kit/Circle";
import AcceptableFormats from "ui-kit/AcceptableFormats";

import { Counters } from "./ImportCSVSummary";

const ImportCsvInvalid = ({
  countOfRowsWithErrors,
  isThereAnyValidRow = false,
  summaryErrors,
  counters = {},
  url,
  summaryWarnings,
  fields
}) => (
  <Div
    p={5}
    display="row.center.flex-start"
    bb={1}
    bg="neutral0"
    bc="neutral2"
    height={1}
    style={{ overflowY: "auto" }}
  >
    <Div width={755} display="column">
      {isThereAnyValidRow && (
        <Div
          bra={1}
          bg="success1"
          py={2}
          px={4}
          display="row.flex-start.flex-start"
        >
          <CheckCircleIcon color="neutral6" mt={2} />
          <Div display="column" ml={3}>
            <Counters {...counters} textSize={4} willBeImported />
          </Div>
        </Div>
      )}
      {R.map(
        ({ id, description }) => (
          <Div
            bra={1}
            height={48}
            bg="altB1"
            py={2}
            px={4}
            mt={3}
            key={id}
            display="row.flex-start.center"
          >
            <ErrorIcon color="altB6" />
            <Text4 ml={3}>{description}</Text4>
          </Div>
        ),
        summaryWarnings
      )}
      {R.gt(countOfRowsWithErrors, 0) && (
        <Div mt={3} bra={1} bg="danger1" pb={5} pt={1} px={4} display="row">
          <Div display="row">
            <Div bra={1} height={48} display="row.flex-start.center">
              <Circle bg="danger6" size={14}>
                <CloseIcon color="danger1" size={12} />
              </Circle>
            </Div>
          </Div>
          <Div display="column" ml={3}>
            <Div bra={1} height={48} display="row.flex-start.center">
              <Text4 bold>{`${countOfRowsWithErrors} row${addS(
                countOfRowsWithErrors
              )} with issues`}</Text4>
            </Div>

            <Div display="column">
              {R.compose(
                R.map(error => (
                  <Div minHeight={30} key={error.id} py={2}>
                    <Text3>{`${error.description}:`}</Text3>
                    {error.rows.map((rowNumber, rowIndex) => (
                      <Div pl={2} my={2}>
                        <Text3 ml={1} bold>
                          Row {rowNumber}: Column
                          {addS(error.columns[rowIndex].length)}{" "}
                          {R.join(", ", error.columns[rowIndex])}
                        </Text3>
                      </Div>
                    ))}
                  </Div>
                )),
                R.filter(e => e.rows.length)
              )(summaryErrors)}
            </Div>
          </Div>
        </Div>
      )}
      {R.gt(countOfRowsWithErrors, 0) ||
        (summaryErrors.length > 0 && (
          <SuperFilledButton
            LeftIcon={DownloadIcon}
            width={290}
            bg="white"
            color="black"
            leftIconColor="black"
            mt={2}
            onClick={() => {
              navigateTo(url);
            }}
          >
            Download Error List as CSV
          </SuperFilledButton>
        ))}
      {R.gt(countOfRowsWithErrors, 0) && <AcceptableFormats fields={fields} />}
    </Div>
  </Div>
);

export default ImportCsvInvalid;
