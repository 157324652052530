import { createModule } from "redux-mvc";

import model from "./model";

import formModule from "ui-kit/Form";
import miniItemsSelector from "ui-kit/MiniItemsSelector";

import sagasModule from "./sagas";

const module = createModule(model);

module.plugModule(formModule);
module.plugModule(miniItemsSelector);

module.setRootSaga(sagasModule);

export default module;
