import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Credential from "./Credential";
import EmptyState from "./EmptyState";
import * as R from "ramda";

const CredentialItems = CSSModules(
  ({ searchTerm, activeType, method = "count" }) => {
    if (!activeType.groups.length) {
      return <EmptyState searchTerm={searchTerm} />;
    }

    const groups = R.map(group => {
      return {
        ...group,
        items: R.reduce((all, item) => {
          R.compose(
            R.forEach(variant => {
              all.push({
                ...item,
                ...variant,
                name: variant.display_name
              });
            }),
            R.sortBy(R.prop("order"))
          )(item.variants);

          return all;
        }, [])(group.items)
      };
    })(activeType.groups);

    return (
      <div>
        {groups.map(group => (
          <div key={group.id} styleName="group">
            <div styleName="columnTitles">
              <div styleName="groupName">{group.name}</div>
              {/* <div styleName="price">Price</div> */}
              {method === "count" ? (
                <div styleName="howMany">How many?</div>
              ) : null}
            </div>
            <div>
              {group.items.map(item => (
                <Credential key={item.id} item={item} method={method} />
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  },
  css
);

export default CredentialItems;
