import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Controller from "./Controller";
import toString from "utils/value-types/to-string";
import getValue from "utils/value-types/get-value";
import * as SYSTEM_FIELD_IDS from "utils/system-field-ids";
import { get } from "lodash";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { getRecords } from "redux/modules/modules/records/actions";
import { addReferences } from "redux/modules/entityReferences/actions";
import { addValue } from "redux/modules/modules/values/actions";

import * as EventSelectors from "redux/modules/event/selectors";
import { records, fields } from "redux/modules/modules/records/selectors";

const {
  ID,
  APPROVAL,
  OWNERS,
  SOURCE,
  CREATED_AT,
  CREATED_BY,
  LAST_SENT_AT,
  LAST_MODIFIED_AT,
  LAST_MODIFIED_BY
} = SYSTEM_FIELD_IDS;

const METAFIELD_BLACKLIST = [
  ID,
  APPROVAL,
  OWNERS,
  SOURCE,
  CREATED_AT,
  CREATED_BY,
  LAST_SENT_AT,
  LAST_MODIFIED_AT,
  LAST_MODIFIED_BY,
  "full_name",
  "accounts_list"
];

function mapStateToProps(state, props) {
  const linkedModuleFieldsToPass = fields(state, props.linkedModuleId);
  const linkedField = linkedModuleFieldsToPass.find(
    f => f.id === props.linkedFieldId
  );
  const linkedFieldId = linkedField ? linkedField.id : ID;
  const linkedFieldType = linkedField ? linkedField.type : "text";
  const linkedMetaFields = linkedModuleFieldsToPass
    .filter(f => ![...METAFIELD_BLACKLIST, linkedFieldId].includes(f.id))
    .slice(0, 3);

  return {
    eventDetails: EventSelectors.eventDetails(state),
    selectedRecords: {},
    selectionMode: "multiple",
    fields: linkedModuleFieldsToPass,
    records: records(state, props.linkedModuleId)
      .map(r => {
        const name =
          linkedFieldId === ID
            ? `${r.record_prefix}-${r.friendly_id}`
            : toString(
                getValue(r.values[linkedFieldId], linkedFieldType),
                linkedFieldType
              );
        return {
          ...r,
          name,
          search: name.toLowerCase(),
          metaValues: linkedMetaFields.reduce((values, metaField) => {
            const value = toString(
              getValue(r.values[metaField.id], metaField.type),
              metaField.type
            );
            values.push({
              name: metaField.name,
              value: value && value.length ? value : null
            });
            return values;
          }, [])
        };
      })
      .reduce((a, b) => {
        a[b.id] = b;
        return a;
      }, {})
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showSnackbar,
      addValue,
      addReferences,
      getRecords
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controller);
