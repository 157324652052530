import React, { useEffect } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions } from "../../model";
import { getPersonItemData } from "../../selectors";
import {
  EDIT_MODAL_DATA,
  AUTO_ASSIGN_ITEM_SETTINGS_MODAL_FORM_ID
} from "../../constants";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import { Input, Dropdown } from "ui-kit/Form/View";
import { BigFilledButton } from "components/Base";
import { actions as FormActions } from "ui-kit/Form/model";
import { WithFormInstanceProvider } from "ui-kit/Form/View";

const decorate = R.compose(
  WithFormInstanceProvider(R.always(AUTO_ASSIGN_ITEM_SETTINGS_MODAL_FORM_ID)),
  connect(
    state => ({
      data: getPersonItemData(state)
    }),
    {
      hideUpdateModal: () =>
        actions.setShowUpdatePersonAutoAssignItemModal(false),
      updateModalData: actions.updatePersonAutoAssignItemData,
      clearValues: FormActions.clearValues
    }
  ),
  CSSModules(css)
);

const UpdatePersonItemModal = ({
  hideUpdateModal,
  data,
  updateModalData,
  clearValues
}) => {
  useEffect(
    () => () =>
      clearValues(null, {
        meta: {
          instanceId: AUTO_ASSIGN_ITEM_SETTINGS_MODAL_FORM_ID
        }
      }),
    []
  );

  return (
    <MiniModalWrapper
      showModal
      hideModal={hideUpdateModal}
      title="Edit Item"
      display="column.flex-start.stretch"
      width={450}
      height={400}
    >
      <div styleName="edit-modal">
        <div styleName="body">
          <div styleName="fieldWrapper">
            <div styleName="label">Max Quantity</div>
            <Input
              fieldId={EDIT_MODAL_DATA.MAX_QUANTITY}
              iniValue={R.prop(EDIT_MODAL_DATA.MAX_QUANTITY, data)}
              size="big"
              width={1}
              type="number"
              min={0}
            />
          </div>
          <div styleName="fieldWrapper">
            <div styleName="label">Status</div>
            <Dropdown
              fieldId={EDIT_MODAL_DATA.STATUS}
              iniValue={R.prop(EDIT_MODAL_DATA.STATUS, data)}
              width="100%"
              clearable={false}
              options={[
                { id: "approved", label: "Approved" },
                { id: "pending", label: "Pending" }
              ]}
              placeholder="Select status..."
            />
          </div>
        </div>
        <div styleName="footer">
          <BigFilledButton bg="primary7" onClick={updateModalData}>
            Update
          </BigFilledButton>
        </div>
      </div>
    </MiniModalWrapper>
  );
};

export default decorate(UpdatePersonItemModal);
