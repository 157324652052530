import React from "react";
import { connect } from "react-redux";

import { getters } from "../index";
import { getUserTimezone } from "redux/modules/user/selectors";

import TimeTable from "ui-kit/TimeTable/View";
import { Div } from "components/Base";
import Toolbar from "./CalendarToolbar";

const decorate = connect((state, props) => ({
  loading: getters.loading(state, props),
  timezone: getUserTimezone(state)
}));

const CalendarLayout = ({
  handlers,
  loading = false,
  style = {},
  timezone,
  ...styleProps
}) => (
  <Div
    {...styleProps}
    style={{ ...style, overflowX: "hidden", height: "100%" }}
  >
    <Toolbar handlers={handlers} />
    <TimeTable
      loading={loading}
      timezone={timezone}
      leftSidebar
      allowEdit={false}
      scheduleListEventDateFormat="MMMM D"
    />
  </Div>
);

export default decorate(CalendarLayout);
