import React from "react";
import { DropTarget } from "react-dnd";
import { withProps } from "utils/General";

const cardTarget = {
  canDrop() {
    return true;
  },

  drop(props, monitor) {
    const {
      id: movedId,
      parentId: movedParentId,
      type: movedType,
      isExpanded: movedExpanded,
      level: movedLevel
    } = monitor.getItem();
    const {
      id: droppedId,
      parentId: droppedParentId,
      type: droppedType,
      isExpanded: droppedExpanded,
      level: droppedLevel,
      reorder,
      lastItem: droppedLastItem
    } = props;

    reorder({
      isDropPlace: true,
      movedId,
      movedType,
      movedParentId,
      movedExpanded,
      movedLevel,
      droppedId,
      droppedParentId,
      droppedType,
      droppedExpanded,
      droppedLevel,
      droppedLastItem
    });
  }
};

const collectDrop = (connect, monitor) => ({
  connectDropPlace: connect.dropTarget(),
  isPlaceOver: monitor.isOver()
});

const DropPlace = ({ connectDropPlace, dropComponent, ...rest }) => {
  const UpdatedDropComponent = withProps({
    ...rest
  })(dropComponent);
  return connectDropPlace(
    <div>
      <UpdatedDropComponent />
    </div>
  );
};
export default DropTarget("CARD", cardTarget, collectDrop)(DropPlace);
