import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import { getters } from "../model";
import {
  sendBySMS,
  sendViaPortal,
  sendByEmail,
  getStatsRecipients,
  getStatsSMS
} from "../selectors";

import { Div, Text0, Text1, Text3, FontIcon } from "components/Base";
import { SMS_URL_ICON } from "../constants";

const decorate = connect(
  (state, props) => ({
    isSentBySMS: sendBySMS(state, props),
    isSentViaPortal: sendViaPortal(state, props),
    isSentByEmail: sendByEmail(state, props),
    previewData: getters.alertPreview(state, props),
    recipientsCount: getStatsRecipients(state, props),
    recipientsSMSCount: getStatsSMS(state, props)
  }),
  null
);

const PreviewRecipients = ({
  isSentBySMS = false,
  isSentViaPortal = false,
  isSentByEmail = false,
  previewData,
  recipientsCount,
  recipientsSMSCount
}) => (
  <Div
    display="column.flex-start.flex-start"
    width={1}
    p={4}
    bg="white"
    bra={1}
    ba={1}
    shadow={1}
    bc="neutral1"
    mb={4}
  >
    {isSentBySMS && (
      <>
        <Text3 bold color="neutral9" mb={2}>
          SMS ({recipientsSMSCount}{" "}
          {recipientsSMSCount !== 1 ? "people" : "person"})
        </Text3>
        <Div
          display="column.flex-start.flex-start"
          bg="rgba(248, 248, 248, 0.82)"
          bra={2}
          p={2}
          width={300}
          mb={4}
        >
          <Div display="row.space-between.center" width={1}>
            <Div display="row.flex-start.center">
              <Div
                display="row.center.center"
                width={20}
                height={20}
                style={{ overflow: "hidden" }}
              >
                <img src={SMS_URL_ICON} width={22} />
              </Div>
              <Text1 uppercase color="neutral6" ml={2}>
                messages
              </Text1>
            </Div>
            <Text0 uppercase color="neutral6" ml={2}>
              now
            </Text0>
          </Div>
          <Div display="column.flex-start.flex-start" p={2} width={1}>
            <Text1 bold color="neutral9">
              {R.pathOr("", ["sms", "title"], previewData)}
            </Text1>
            <Text1
              color="neutral9"
              style={{
                whiteSpace: "pre-wrap"
              }}
            >
              {R.pathOr("", ["sms", "message"], previewData)}
            </Text1>
          </Div>
        </Div>
      </>
    )}
    {isSentViaPortal && (
      <>
        <Text3 bold color="neutral9" mb={2}>
          Portal ({recipientsCount}{" "}
          {recipientsCount !== 1 ? "people" : "person"})
        </Text3>
        <Div
          display="row.flex-start.flex-start"
          bg="warning0"
          bra={1}
          p={4}
          width={1}
          mb={4}
        >
          <Text0
            uppercase
            color="white"
            bg="warning9"
            mr={3}
            bra={1}
            py={1}
            px={2}
            style={{
              flexShrink: 0
            }}
          >
            {R.pathOr("", ["portal", "label"], previewData)}
          </Text0>
          <Div display="column" flex={1}>
            <Text1 bold color="neutral9" mb={2}>
              {R.pathOr("", ["email", "title"], previewData)}
            </Text1>
            <Text1 color="neutral9">
              <span
                className="lennd-wysiwyg-content"
                dangerouslySetInnerHTML={{
                  __html: R.pathOr("", ["portal", "message"], previewData)
                }}
              />
            </Text1>
          </Div>
        </Div>
      </>
    )}
    {isSentByEmail && (
      <>
        <Text3 bold color="neutral9" mb={2}>
          Email ({recipientsCount} {recipientsCount !== 1 ? "people" : "person"}
          )
        </Text3>
        <Div
          display="column.flex-start.flex-start"
          bg="white"
          bra={2}
          p={4}
          width={1}
          shadow={1}
        >
          <Div display="row.center.center" width={1} mb={2}>
            <Div
              width={100}
              height={100}
              display="row.center.center"
              style={{
                position: "relative",
                backgroundImage: R.pathOr(
                  "none",
                  ["email", "image_url"],
                  previewData
                )
                  ? `url('${R.pathOr(
                      "none",
                      ["email", "image_url"],
                      previewData
                    )}')`
                  : undefined,
                backgroundSize: "cover",
                backgroundPosition: "center center"
              }}
            ></Div>
          </Div>
          <Div
            display="column.flex-start.flex-start"
            p={2}
            width={1}
            bc="neutral0"
            bb={1}
            bt={1}
          >
            <Text0
              uppercase
              color="white"
              bg="warning9"
              mr={3}
              bra={1}
              py={1}
              px={2}
              mb={2}
            >
              {R.pathOr("", ["email", "label"], previewData)}
            </Text0>
            <Text1 bold color="neutral9" mb={2}>
              {R.pathOr("", ["email", "title"], previewData)}
            </Text1>
            <Text1 color="neutral9" mb={2}>
              <span
                className="lennd-wysiwyg-content"
                dangerouslySetInnerHTML={{
                  __html: R.pathOr("", ["email", "message"], previewData)
                }}
              />
            </Text1>
          </Div>
          <Div display="row.center.center" my={4} width={1}>
            <FontIcon color="neutral2" sizeWFS={24}>
              flash_on
            </FontIcon>
            <Text1 color="neutral8" ml={2}>
              Powered by
            </Text1>
            <Text1 uppercase bold color="neutral8" ml={1}>
              lennd
            </Text1>
          </Div>
        </Div>
      </>
    )}
  </Div>
);

export default decorate(PreviewRecipients);
