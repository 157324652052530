/* eslint-disable no-underscore-dangle */
import Helpers from "utils/Global/Helpers";

export default {
  getPermissionProfiles: (credentials, eventId) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/permissions/event/${eventId}/permission-profiles`,
        credentials,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  getPermissionGroups: (credentials, eventId) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/permissions/event/${eventId}/permission-groups`,
        credentials,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  getOrganization: (credentials, orgId) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/permissions/organization/${orgId}/permission-profiles`,
        credentials,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  post: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/permissions/event/${data.eventId}/permission-profiles`,
        credentials,
        data,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  postOrganization: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/permissions/organization/${data.orgId}/permission-profiles`,
        credentials,
        data,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  put: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/permissions/permission-profiles/${data.profileId}`,
        credentials,
        data,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  putPermissions: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/permissions/permission-profiles/${data.profileId}/permissions`,
        credentials,
        data,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    }),

  delete: (credentials, data) =>
    new Promise((resolve, reject) => {
      /* eslint-disable no-underscore-dangle */
      const options = {
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/permissions/permission-profiles/${data.profileId}`,
        credentials,
        data,
        success: resolve,
        error: reject
      };
      /* eslint-enable no-underscore-dangle */
      Helpers.request(options);
    })
};
