import React from "react";
import * as R from "ramda";
import { DragSource, DropTarget } from "react-dnd";
import CSSModules from "react-css-modules";
import css from "./style.scss";
import DropPlace from "./DropPlace";
import { withProps } from "utils/General";

const cardTarget = {
  canDrop({ type }) {
    return type === "folder";
  },

  drop(props, monitor) {
    const {
      id: movedId,
      parentId: movedParentId,
      type: movedType,
      isExpanded: movedExpanded,
      level: movedLevel
    } = monitor.getItem();
    const {
      id: droppedId,
      parentId: droppedParentId,
      type: droppedType,
      isExpanded: droppedExpanded,
      level: droppedLevel,
      reorder
    } = props;

    reorder({
      movedId,
      droppedId,
      droppedParentId,
      movedParentId,
      movedType,
      droppedType,
      movedExpanded,
      droppedExpanded,
      movedLevel,
      droppedLevel,
      isDropPlace: false,
      droppedLastItem: false
    });
  }
};

const collect = (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
});

const collectDrop = (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  isOverCurrent: monitor.isOver({ shallow: true }),
  canDrop: monitor.canDrop(),
  itemType: monitor.getItemType()
});

const cardSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
      parentId: props.parentId,
      type: props.type,
      isExpanded: props.isExpanded,
      level: props.level,
      lastItem: props.lastItem
    };
  }
};

const RowDnD = ({
  connectDragSource,
  connectDropTarget,
  name,
  level,
  type,
  id,
  toggleCompressedList,
  isExpanded,
  isOver,
  modified,
  visible_to,
  status,
  parentId,
  reorder,
  lastItem,
  isDragging,
  rowComponent,
  dropComponent,
  toggleSelectedId,
  selected,
  showCheck
}) => {
  const UpdatedRow = withProps({
    id,
    parentId,
    type,
    isExpanded,
    level,
    reorder,
    status,
    visible_to,
    modified,
    toggleCompressedList,
    name,
    connectDragSource,
    connectDropTarget,
    paddingLeft: showCheck ? 42 + level * 16 : 22 + level * 16
  })(rowComponent);
  const dropProps = {
    id,
    parentId,
    type,
    isExpanded,
    level,
    reorder,
    dropComponent,
    isOver
  };
  return (
    <div>
      {!isDragging ? (
        <div>
          <DropPlace
            {...{
              ...dropProps,
              lastItem: false
            }}
          />
        </div>
      ) : null}
      {connectDropTarget(
        connectDragSource(
          <div
            styleName="row-dnd"
            style={{
              paddingLeft: 22 + level * 16,
              borderBottom: "1px solid #e7e6ea"
            }}
          >
            <span styleName="drag-icon">
              <i class="material-icons">drag_indicator</i>
            </span>
            <div
              styleName="checkbox"
              style={selected ? { background: "#823dd1" } : {}}
              onClick={toggleSelectedId}
            >
              {selected ? (
                <i
                  class="material-icons"
                  style={{ color: "white", fontSize: 16, height: 16 }}
                >
                  {type === "folder" ? "remove" : "done"}
                </i>
              ) : null}
            </div>
            <UpdatedRow />
          </div>
        )
      )}
      {lastItem ? (
        <div
          style={{
            borderBottom: "1px solid #e7e6ea"
          }}
        >
          <DropPlace
            isOver={isOver}
            {...{
              ...dropProps,
              lastItem,
              bottom: true
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

export default R.compose(
  DragSource("CARD", cardSource, collect),
  DropTarget("CARD", cardTarget, collectDrop),
  CSSModules(css)
)(RowDnD);
