import { get } from "lodash";

/**
  Expected structure of references is:
  ```
  {
    forms: {
      [id]: ''
    }
  }
  ```

  You may pass `key` in options to retrieve a property other than name (email)
 */
export default function(value, { references }) {
  switch (value.type) {
    case "user": {
      const userId = get(value, ["meta", "userId"]);
      return get(references, ["users", userId]);
    }
    case "submission": {
      const formId = get(value, ["meta", "formId"]);
      return get(references, ["forms", formId]);
    }
    case "eventbrite-order": {
      const orderId = get(value, ["meta", "eventbriteOrderId"]);
      return `Order #${orderId}`;
    }
    default:
      return "—";
  }
}
