import React from "react";
import {
  ThirdRightTemplate,
  MediumOutlineInput,
  SearchIcon
} from "components/Base";

const SelectorTemplate = ({
  leftChildren,
  rightChildren,
  searchPlaceholder,
  onSearch
}) => {
  return (
    <ThirdRightTemplate
      wrapperProps={{ p: 3 }}
      leftChildren={[
        <MediumOutlineInput
          key={0}
          continuous
          RightIcon={SearchIcon}
          placeholder={searchPlaceholder}
          onChange={onSearch}
          flex={0}
        />,
        ...leftChildren
      ]}
      rightProps={{ ml: 3 }}
      rightChildren={rightChildren}
    />
  );
};

export { SelectorTemplate };
