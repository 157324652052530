import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Portal from "./Portal";
import * as portalMessageActions from "redux/modules/portal/messages/actions";
import * as portalMessageSelectors from "redux/modules/portal/messages/selectors";

function mapStateToProps(state) {
  return {
    list: portalMessageSelectors.list(state),
    loading: portalMessageSelectors.isFetching(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(portalMessageActions, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Portal);
