import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";

import { actions } from "./index";

import { registerError } from "redux/modules/errors/actions";

import { getCredentials } from "redux/modules/user/selectors";
import { orgId as getOrgId } from "redux/modules/organization/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";

import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const orgId = yield select(getOrgId);
  const eventId = yield select(getEventId);

  return {
    credentials,
    orgId,
    eventId
  };
};

const init = function*({ payload: { moduleId } }) {
  try {
    yield put(actions.setLoading(true));

    const { credentials, orgId, eventId } = yield call(getParams);

    const { payload: modules } = yield call(Api.getRelatedModules, {
      credentials,
      moduleId,
      options: {
        orgId,
        eventId
      }
    });

    yield put(
      actions.setInitialData({
        modules
      })
    );
  } catch (error) {
    yield all([
      put(
        registerError([
          {
            system: error,
            user: "An error loading modules"
          }
        ])
      )
    ]);
  } finally {
    yield put(actions.setLoading(false));
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([fork(watchInit)]);
};

export default rootSaga;
