import { createModule } from "redux-mvc";

import model from "./model";
import rootSaga from "./sagas";

const approversModule = createModule({
  ...model,
  observedDomains: ["user", "event", "permissions", "organization"]
});
approversModule.setRootSaga(rootSaga);

export default approversModule;
