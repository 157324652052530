import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import External from "./External";
import exportFactory from "components/Global/CRM/TableViews/HelperComponents/export-factory";

import { showModal, hideModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { syncAllOrders } from "redux/modules/credentials/orders/actions";
import {
  getOrdersByEventAndType,
  updateOrder,
  deleteOrder
} from "redux/modules/orders/orders/actions";
import { getTicketingProviderConfigurations } from "redux/modules/ticketing/providers/actions";

import { eventDetails } from "redux/modules/event/selectors";
import { user } from "redux/modules/user/selectors";
import { ordersByTypeId } from "redux/modules/orders/orders/selectors";
import {
  ticketingProviders,
  ticketingProviderConfigurations,
  ticketTypes
} from "redux/modules/ticketing/providers/selectors";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

function mapStateToProps(state, props) {
  return {
    eventId: eventDetails(state).id,
    user: user(state),
    orders: ordersByTypeId(state, props.typeId),
    ticketingProviderConfigurations: ticketingProviderConfigurations(state),
    canDo: canEventUserDo(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal,
      hideModal,
      showSnackbar,
      updateOrder,
      deleteOrder,
      syncAllOrders,
      getTicketingProviderConfigurations,
      getOrdersByEventAndType
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(exportFactory(withRouter(External)));
