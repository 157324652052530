import * as R from "ramda";

export const getName = (...components) =>
  R.join(
    " ",
    R.filter(
      R.compose(
        R.not,
        R.either(R.isNil, R.isEmpty)
      ),
      components
    )
  );
