import {
  put,
  takeEvery,
  all,
  fork,
  select,
  call,
  take
} from "redux-saga/effects";
import { actions } from "EventLight/FileRequests/List/model";
import { push } from "react-router-redux";
import { registerError } from "redux/modules/errors/actions";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { apiCall } from "App/Data/sagas";
import { actions as addFileModalActions } from "EventLight/FileRequests/AddFileModal/model";
import { actions as popoverActions } from "ui-kit/PopoverMenu/model";
import { ROW_ACTIONS } from "./constants";
import * as R from "ramda";
import { showSnackbar } from "redux/modules/snackbar/actions";

const getParams = function*() {
  const eventId = yield select(getEventId);
  const credentials = yield select(getCredentials);
  const eventDetails = yield select(getEventDetails);

  return { eventId, credentials, eventDetails };
};

const init = function*() {
  const { eventId } = yield call(getParams);

  try {
    const { payload } = yield call(apiCall, {
      method: "get",
      url: `/file-requests/event/${eventId}`
    });

    yield put(actions.setData(payload));

    if (!payload.all_file_requests) {
      yield put(actions.setShowEmptyState(true));
    }
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred white loading forms"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const deleteRequest = function*({ payload: { data } }) {
  const { eventId } = yield call(getParams);

  try {
    yield call(apiCall, {
      method: "delete",
      url: `/file-requests/event/${eventId}/request/${data.id}`
    });

    yield all([
      put(showSnackbar({ message: "Request Type deleted", action: "OK" })),
      call(init)
    ]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred deleting request type"
        }
      ])
    );
  }
};

const openEditRequestModal = function*({ payload: { data } }) {
  yield put(actions.openEditRequestModal(data.id));
};

const watchTableActions = function*() {
  for (;;) {
    const action = yield take(popoverActions.executeAction.type);

    const delegate = R.prop(action.payload.actionId, {
      [ROW_ACTIONS.DELETE]: deleteRequest,
      [ROW_ACTIONS.EDIT]: openEditRequestModal
    });

    if (delegate) {
      yield fork(delegate, action);
    }
  }
};

const reloadAfterAddFileRequest = function*() {
  yield put(actions.setShowEmptyState(false));
  yield all([call(init), put(actions.setShowAddFileModal(false))]);
};

const handleGoToResults = function*({ payload: { data } }) {
  const { eventId } = yield call(getParams);

  yield put(push(`/event-light/${eventId}/file-requests/${data.id}/results`));
};

const hideCreateModal = function*() {
  yield put(actions.setShowAddFileModal(false));
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchGoToResults = function*() {
  yield takeEvery(actions.goToResults.type, handleGoToResults);
};

const watchOnDoneAddFileRequest = function*() {
  yield takeEvery(addFileModalActions.onDone.type, reloadAfterAddFileRequest);
};

const watchHideCreateModal = function*() {
  yield takeEvery(addFileModalActions.hideCreateModal.type, hideCreateModal);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchTableActions),
    fork(watchGoToResults),
    fork(watchOnDoneAddFileRequest),
    fork(watchHideCreateModal)
  ]);
};

export default rootSaga;
