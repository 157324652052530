import React from "react";
import { Text0, Text2, Div, makeTable } from "components/Base";
import { withProps } from "utils/General";

export default makeTable({
  TableStyler: withProps({
    flex: 1,
    p: 4
  })(Div),
  HeaderStyler: withProps({ display: "row.flex-start.center" })(Div),
  RowStyler: withProps({
    display: "row.flex-start.center",
    bb: 1,
    bc: "neutral0",
    py: 2
  })(Div),
  HeaderCellStyler: withProps({ bold: true, uppercase: true })(Text0),
  RowCellStyler: Text2,
  columnProps: [
    { width: 2 / 8 },
    { width: 2 / 8 },
    { width: 2 / 8 },
    { width: 2 / 8 }
  ],
  headerCellComps: [
    () => <Div>Quantity</Div>,
    () => <Div>Item</Div>,
    () => <Div>Price</Div>,
    () => <Div>Total</Div>
  ],
  rowCellComps: [
    ({ quantity }) => <Div>{quantity}</Div>,
    ({ item }) => (
      <Div>
        <Text2>{item.title}</Text2>
        <Text0>{item.caption}</Text0>
      </Div>
    ),
    ({ unitPrice }) => <Div>{unitPrice}</Div>,
    ({ totalPrice }) => <Div>{totalPrice}</Div>
  ]
});
