import React from "react";
import { Div } from "components/Base";

const Sublink = ({ name, onClick, isActive }) => (
  <Div display="row.flex-start.center" onClick={onClick} mb={3}>
    <Div
      ml={4}
      fw={3}
      fs={2}
      style={{
        color: isActive ? "#222024" : "#62778C"
      }}
    >
      {name}
    </Div>
  </Div>
);

const Link = ({ id, name, onClick, isActive, links }) => (
  <div id={id}>
    <Div display="row.flex-start.center" onClick={onClick} mb={3}>
      <Div
        mr={2}
        width={7}
        height={7}
        style={{
          backgroundColor: isActive ? "#7433C7" : "#A2AFBB",
          flexShrink: 0,
          borderRadius: 100
        }}
      />
      <Div
        fw={3}
        style={{
          color: isActive ? "#222024" : "#62778C"
        }}
      >
        {name}
      </Div>
    </Div>
    {isActive && links && links.length ? (
      <Div display="column">
        {links.filter(l => l.show).map(sublink => (
          <Sublink key={sublink.id} {...sublink} />
        ))}
      </Div>
    ) : null}
  </div>
);

const View = ({ links }) => (
  <Div
    display="column"
    pl={8}
    pt={8}
    pb={8}
    width={270}
    style={{
      flexShrink: 0
    }}
  >
    <Div color="neutral7" fs={5} mb={4} fw={4}>
      Setup Guide
    </Div>
    {links.map(link => (
      <Link key={link.id} {...link} />
    ))}
  </Div>
);

export default View;
