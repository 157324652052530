import React from "react";
import { TinyToggle, Div, Text2 } from "components/Base";
import { connect } from "react-redux";
import { actions, getters } from "Modules/Dashboard";
import Tooltip from "components/Global/Tooltip";

const decorate = connect(
  (state, props) => ({
    refreshDashboards: getters.refreshDashboards(state, props)
  }),
  {
    toggleRefreshDashboards: actions.toggleRefreshDashboards
  }
);

const RefreshDashboards = ({ refreshDashboards, toggleRefreshDashboards }) => (
  <Tooltip
    tooltip="Refreshes results every 5 minutes to keep you up to date with any changes"
    placement="top"
  >
    <Div
      display="row.flex-start.center"
      px={1}
      py={2}
      onClick={toggleRefreshDashboards}
    >
      <TinyToggle active={refreshDashboards} />
      <Text2 ml={2} color="white" bold>
        Auto-Refresh
      </Text2>
    </Div>
  </Tooltip>
);

export default decorate(RefreshDashboards);
