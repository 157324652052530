import Helpers from "utils/Global/Helpers";

/* eslint no-underscore-dangle: "off" */

export default {
  search: (credentials, eventId, orgId, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/sessions/event/${eventId}/sessions`,
        qs: data,
        credentials,
        success,
        error
      });
    }),
  export: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/sessions/event/${eventId}/sessions/export`,
        qs: data,
        credentials,
        success,
        error
      });
    }),
  getViews: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/sessions/list/${eventId}/views`,
        data,
        credentials,
        success,
        error
      });
    }),
  addView: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/sessions/list/${eventId}/views`,
        data,
        credentials,
        success,
        error
      });
    }),
  updateView: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/sessions/list/${eventId}/view`,
        data,
        credentials,
        success,
        error
      });
    }),
  deleteView: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/sessions/list/${eventId}/view`,
        data,
        credentials,
        success,
        error
      });
    }),
  selectView: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/sessions/list/${eventId}/active-view`,
        data,
        credentials,
        success,
        error
      });
    }),
  getActiveView: ({ credentials, eventId, ...data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/sessions/list/${eventId}/active-view`,
        data,
        credentials,
        success,
        error
      });
    }),

  deleteSessions: ({ credentials, data }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */

      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/sessions/sessions`,
        credentials,
        data,
        success,
        error
      });
    }),
  duplicateSessions: ({ credentials, eventId, data }) =>
    new Promise((success, error) => {
      /* eslint-disable no-underscore-dangle */

      Helpers.request({
        method: "delete",
        url: `${window.__LENND_API_BASE_URL__}/sessions/event/${eventId}/sessions/duplicate`,
        credentials,
        data,
        success,
        error
      });
    }),
  updateSession: ({ credentials, data }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/sessions/sessions`,
        credentials,
        data,
        success,
        error
      });
    }),
  getStatus: (credentials, { jobId }) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/jobs/progress/${jobId}`,
        credentials,
        success,
        error
      });
    })
};
