import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { Div, Text3 } from "components/Base";

import HeaderAvatar from "./Avatar";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { eventDetails as getEventDetails } from "redux/modules/portal/selectors";
import { getEventInitial, getLogoImgUrl, getPortalAccount } from "../selectors";

const decorate = R.compose(
  connect((state, props) => ({
    eventDetails: getEventDetails(state, props),
    eventInitial: getEventInitial(state, props),
    logoImageUrl: getLogoImgUrl(state, props),
    account: getPortalAccount(state, props)
  })),
  CSSModules(css)
);

const Header = ({
  eventDetails = {},
  eventInitial = "",
  logoImageUrl = "",
  account = {}
}) => {
  return (
    <div className={css.header}>
      <Div display="row.flex-start.center">
        <Div display="row.flex-start.center" ml={2}>
          {logoImageUrl ? (
            <Div
              width={30}
              height={30}
              bra={1}
              bg="gray1"
              display="row.center.center"
              mr={2}
              style={{
                backgroundImage: `url(${logoImageUrl})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
                border: "1px solid #eee"
              }}
            />
          ) : (
            <Div
              width={30}
              height={30}
              bra={1}
              bg={{
                default: "gray1",
                hover: "gray2"
              }}
              display="row.center.center"
              mr={2}
            >
              <Div fw={3} color="#ccc" fs={3}>
                {eventInitial}
              </Div>
            </Div>
          )}
          <Div display="column">
            <Div
              fs={3}
              fw={4}
              color="black"
              style={{
                fontSize: "18px"
              }}
            >
              {eventDetails.name}
            </Div>
          </Div>
        </Div>
      </Div>

      <Div display="row.flex-end.center">
        {account ? (
          <Text3 underline color="primary7" mr={4}>
            {account.name}
          </Text3>
        ) : (
          ""
        )}
        <HeaderAvatar />
      </Div>
    </div>
  );
};

export default decorate(Header);
