/* eslint-disable react/prop-types */

import React from "react";
import { Div } from "components/Base";
import { CredentialsList } from "./CredentialsList";
import StyleWrapper from "components/Global/Modal/Layout/StyleWrapper";

const View = ({
  name,
  description,
  hideModal,
  items,
  limit,
  handleSave,
  isSaving
}) => (
  <StyleWrapper hideHeader hideModal={hideModal} width={500}>
    <Div>
      <Div fw={3} fs={4} color="gray9" mx={2} mb={description ? 1 : 3}>
        {name}
      </Div>
      {description ? (
        <Div
          mx={2}
          color="gray6"
          mb={3}
          fs={2}
          style={{
            whiteSpace: "pre-wrap"
          }}
        >
          {description}
        </Div>
      ) : null}

      <CredentialsList
        items={items}
        limit={limit}
        handleSave={handleSave}
        hideModal={hideModal}
        isSaving={isSaving}
      />
    </Div>
  </StyleWrapper>
);

export default View;
