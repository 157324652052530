import React, { useEffect } from "react";
import { connect } from "react-redux";
import { actions, getters } from "../model";
import SelectItemsModal from "./SelectItemsModal";
import MiniModalWrapper from "ui-kit/MiniModalWrapper";
import Body from "./Body";
import Footer from "./Footer";

const decorate = connect(
  state => ({
    showAddVideoLink: getters.showAddVideoLink(state),
    isLink: getters.isLink(state)
  }),
  {
    init: actions.init,
    setShowAddVideoLink: actions.setShowAddVideoLink
  }
);

const Layout = ({ hideModal, recordTypeId, init }) => {
  useEffect(() => {
    init({ recordTypeId });
  }, []);

  return (
    <>
      <SelectItemsModal />
      <MiniModalWrapper
        showModal
        hideModal={hideModal}
        showHeader
        style={{ width: 958, height: "calc(100vh - 60px)", maxHeight: "800px" }}
        isPortal={false}
        title="Media Hub Settings"
        bg="white"
        stopPropagation
      >
        <Body />
        <Footer />
      </MiniModalWrapper>
    </>
  );
};

export default decorate(Layout);
