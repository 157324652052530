import React from "react";
import PropTypes from "prop-types";
import * as R from "ramda";
import {
  Text3,
  Text2,
  Div,
  FontIcon,
  RightIcon,
  Text1,
  Text5,
  Text6,
  MediumFilledButton,
  MediumClearButton,
  OpenInNewIcon,
  LoadingIcon
} from "components/Base";
import * as flags from "utils/feature-flags";
import { ToggleFeature } from "@flopflip/react-redux";
import Lightbox from "react-images";
import { withState } from "recompose";

class Breakdown extends React.Component {
  state = {
    activeSection: 0
  };

  goToSection = activeSection => this.setState({ activeSection });

  render() {
    const { children } = this.props;

    if (typeof children === "function") {
      return children({
        activeSection: this.state.activeSection,
        goToSection: this.goToSection
      });
    }
  }
}

const Breadcrumbs = ({ children }) => (
  <Text1 pb={1} bold uppercase color={"neutral5"}>
    {children}
  </Text1>
);

const Title = ({ children }) => (
  <Text6 bold color={"neutral8"} mb={4}>
    {children}
  </Text6>
);

const SectionOverviewContainer = ({ children, breadcrumbs = [] }) => (
  <Div maxWidth={685}>
    <Div bra={1} bc="neutral1" bb={1} px={3} pt={2}>
      <Breadcrumbs>{breadcrumbs.join(" / ")}</Breadcrumbs>
    </Div>
    <Div py={5} px={3}>
      {children}
    </Div>
  </Div>
);

const SectionOverviewTemplate = R.compose(
  withState("showingPhoto", "showPhoto", null)
)(
  ({
    title,
    whatSubText,
    steps = [],
    doSectionContent,
    whatSectionContent,
    resources,
    loading,
    showingPhoto,
    showPhoto,
    ...props
  }) => (
    <Div mb={15} {...props}>
      <Div display="column" mb={12}>
        <Div mb={2} display="row.flex-start.center">
          <FontIcon fs={5} color="blue5" mr={1}>
            stars
          </FontIcon>
          <Text5 bold>Getting Started with {title}</Text5>
        </Div>
        <Div bra={1} shadow={1}>
          {steps.map(({ title, subtitle, body, onClick, action }, idx) => (
            <Div
              key={idx}
              bb={1}
              bc="neutral1"
              p={5}
              display="row.flex-start.flex-start"
              onClick={onClick}
              transition="fast"
              bg={{
                default: "white",
                hover: onClick ? "neutral0" : "white"
              }}
            >
              <Div
                pill
                size={30}
                bg="neutral1"
                mr={4}
                display="row.center.center"
                style={{ flexShrink: 0 }}
              >
                <Text2 bold>{idx + 1}</Text2>
              </Div>
              <Div flex={1} display="column" mr={3} pt={subtitle ? 0 : 1}>
                <Text3 bold color="gray7">
                  {title}
                </Text3>
                {subtitle ? <Text1>{subtitle}</Text1> : subtitle}
                {body}
              </Div>
              {action ? action : onClick ? <RightIcon size={25} /> : null}
            </Div>
          ))}
        </Div>
      </Div>

      <Div display="column" mb={10}>
        <Div display="row.flex-start.center">
          <FontIcon fs={5} color="blue5" mr={2}>
            message
          </FontIcon>
          <Text5 bold color="gray7">
            What {title[title.length - 1] === "s" ? "are" : "is"} {title}?
          </Text5>
        </Div>
        {whatSubText ? (
          <Div fs={3} mt={2} mb={2} color="gray6">
            {whatSubText}
          </Div>
        ) : null}
        {/* {whatSectionContent} */}

        <Div>
          <Breakdown>
            {({ activeSection, goToSection }) => {
              const section = resources[activeSection];

              if (loading) {
                return (
                  <Div width={1} height={1} mt={10} display="row.center.center">
                    <LoadingIcon color="neutral5" size={50} />
                  </Div>
                );
              }

              return (
                <Div>
                  <Lightbox
                    backdropClosesModal={true}
                    currentImage={showingPhoto}
                    images={resources.map(({ images, description }) => ({
                      src: images[1],
                      caption: description
                    }))}
                    isOpen={showingPhoto !== null}
                    onClickPrev={() =>
                      showingPhoto - 1 < 0
                        ? showPhoto(showingPhoto - resources.length - 1)
                        : showPhoto(showingPhoto - 1)
                    }
                    onClickNext={() => {
                      if (showingPhoto + 1 > resources.length - 1) {
                        showPhoto(0);
                      } else {
                        showPhoto(showingPhoto + 1);
                      }
                    }}
                    onClose={() => {
                      showPhoto(null);
                    }}
                  />

                  <Div bg="neutral2" p={2}>
                    <Div
                      width={635}
                      height={325}
                      onClick={() => {
                        showPhoto(activeSection);
                      }}
                      style={{
                        margin: "0 auto",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "top center",
                        backgroundSize: "cover",
                        backgroundImage: section
                          ? `url(${section.images[0]})`
                          : undefined
                      }}
                    />
                  </Div>

                  <Div display="row.flex-start.center">
                    {resources.map((resource, idx) => (
                      <Div
                        key={idx}
                        width={1}
                        px={4}
                        py={4}
                        fs={2}
                        fw={activeSection === idx ? 4 : 2}
                        color="gray7"
                        bg={{
                          default:
                            activeSection === idx ? "neutral2" : "neutral0",
                          hover: activeSection === idx ? "neutral2" : "neutral1"
                        }}
                        onClick={() => goToSection(idx)}
                      >
                        {resource.name}
                      </Div>
                    ))}
                  </Div>
                  <Div bg="neutral0" p={5}>
                    {section ? (
                      <Div fs={4} fw={3} color="gray7" mb={2}>
                        {section.title}
                      </Div>
                    ) : null}
                    {section ? (
                      <Div color="gray7">{section.description}</Div>
                    ) : null}
                    <Div display="row.flex-start.center" mt={4}>
                      {section && section.learnMoreUrl ? (
                        <MediumFilledButton
                          bg="blue5"
                          color="white"
                          RightIcon={OpenInNewIcon}
                          mr={2}
                          onClick={() => {
                            window.open(section.learnMoreUrl, "_blank");
                          }}
                        >
                          Learn more
                        </MediumFilledButton>
                      ) : null}
                      <MediumClearButton
                        onClick={() => {
                          window.Intercom("show");
                        }}
                      >
                        Ask a question
                      </MediumClearButton>
                    </Div>
                  </Div>
                </Div>
              );
            }}
          </Breakdown>
        </Div>
      </Div>

      {/*
    <ToggleFeature
      flag={flags.CAN_VIEW_PASSES.NAME}
      untoggledComponent={Div}
      toggledComponent={() => (
        <Div display="column" mb={8}>
          <Div mb={2} display="row.flex-start.center">
            <FontIcon fs={5} color="blue5" mr={2}>
              video_library
            </FontIcon>
            <Text5 bold>What can you do with {title}?</Text5>
          </Div>
          {doSectionContent}
        </Div>
      )}
    />
    */}
    </Div>
  )
);

SectionOverviewTemplate.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      action: PropTypes.element
    })
  )
};

export {
  Breadcrumbs,
  Title,
  SectionOverviewContainer,
  SectionOverviewTemplate
};
