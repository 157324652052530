import { registerError } from "redux/modules/errors/actions";
import api from "./api";

export function goToAuthUrl(data) {
  return async (dispatch, getState) => {
    try {
      // get url
      const url = await api.post(getState().user.user.credentials, data);
      // go to url
      window.location = url;
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting the Eventbrite Auth URL"
          }
        ])
      );
    }
  };
}
