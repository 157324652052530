import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { actions, getters } from "../../model";
import {
  ASSIGNED_DOCUMENT_INSTANCE_ID,
  CARD_TYPES,
  EXPERIENCE_URL
} from "../../constants";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import { WithFormInstanceProvider } from "ui-kit/Form/View";
import CollapsableCard from "../CollapsableCard";

const columns = [
  {
    name: "Name",
    value: "name",
    style: { flex: 1.2 },
    type: "text",
    showTooltip: true
  },
  {
    name: "Alias",
    value: "alias",
    style: { flex: 1 },
    type: "text",
    showTooltip: true
  },
  {
    name: "Due Date",
    value: "close_date_formatted",
    style: { flex: 1.4, fontSize: 15 },
    type: "text",
    showTooltip: true
  },
  {
    name: "Required",
    value: "required",
    type: "required",
    style: { width: 80, textAlign: "center" }
  },
  {
    name: "Actions",
    value: "actions",
    style: { width: 80, textAlign: "center" },
    type: "actions"
  }
];

const decorate = R.compose(
  WithFormInstanceProvider(R.always(ASSIGNED_DOCUMENT_INSTANCE_ID)),
  connect(
    state => ({
      assignedDocumentRequests: getters.assignedDocumentRequests(state),
      loadingDocumentRequests: getters.loadingDocumentRequests(state)
    }),
    {
      goToLearnLennd: actions.goToLearnLennd,
      openModal: () => actions.openAssignModal(CARD_TYPES.DOCUMENTS),
      goToDocumentRequests: actions.goToDocumentRequests,
      deleteAssignedDocumentRequest: actions.deleteAssignedDocumentRequest,
      openUpdateModal: ({ id }) =>
        actions.openUpdateModal({ assignModalType: CARD_TYPES.DOCUMENTS, id }),
      reorderDocuments: actions.reorderDocuments
    }
  ),
  CSSModules(css)
);

const CollectDocuments = ({
  goToLearnLennd,
  assignedDocumentRequests,
  goToDocumentRequests,
  openModal,
  loadingDocumentRequests,
  deleteAssignedDocumentRequest,
  openUpdateModal,
  reorderDocuments
}) => (
  <CollapsableCard
    title="Request Files or Documents"
    description="Let users submit documents electronically."
    btnText="documents"
    goToLearn={() =>
      goToLearnLennd({
        expectedExperienceUrl: EXPERIENCE_URL.collect_documents
      })
    }
    loading={loadingDocumentRequests}
    openModal={openModal}
    goToManage={goToDocumentRequests}
    deleteRow={deleteAssignedDocumentRequest}
    openUpdateModal={openUpdateModal}
    assignedRows={assignedDocumentRequests}
    columnTitle="Document Request"
    assignedRowsText="Document Request"
    onReorder={reorderDocuments}
    emptyText="You haven't assigned any document requests to this portal yet."
    columns={columns}
  />
);

export default decorate(CollectDocuments);
