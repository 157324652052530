export const ROW_ACTIONS = {
  OPEN_RECORD: "open-record",
  EDIT_RECORD: "edit-record",
  DELETE_RECORD: "delete-record",
  SELECT_RECORD: "select-record",
  DOWNLOAD_RECORD: "download-record",
  PLAY_RECORD: "play-record",
  TRANSCRIBE: "transcribe",
  VIEW_TRANSCRIPT: "view-transcript",
  COPY: "copy"
};
