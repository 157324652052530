import React, { Component } from "react";
import { findIndex } from "lodash";
import * as R from "ramda";
import View from "./View";

import buildLineItems from "Orders/utils/build-line-items";

class Controller extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      loading: true,
      activeStepId: "orderItems",
      activeTypeId: props.activeTypeId || null,
      selectedVariants: {},
      lineItems: [],
      processing: false,
      variantsToRemove: []
    };
  }

  componentDidMount() {
    this.props.getItemTypesByEvent(this.props.eventDetails.id).then(() => {
      this.setState({ loading: false });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.activeTypeId && nextProps.types.length) {
      this.setState({ activeTypeId: nextProps.types[0].id });
    }
  }

  onDone = () => {
    const { onDone, hideModal } = this.props;
    if (onDone) {
      onDone();
    }
    hideModal();
  };

  get itemCount() {
    return Object.keys(this.state.selectedVariants).reduce(
      // eslint-disable-next-line no-param-reassign
      (a, id) => (a += this.state.selectedVariants[id]),
      0
    );
  }

  isStepValid = () => this.itemCount !== 0;

  updateLineItem = (lineItemId, values) =>
    this.setState(state => {
      const index = findIndex(this.state.lineItems, { id: lineItemId });
      state.lineItems[index] = {
        ...state.lineItems[index],
        ...values
      };
      return state;
    });

  removeLineItem = lineItemId =>
    this.setState(state => {
      const index = findIndex(this.state.lineItems, { id: lineItemId });
      if (state.lineItems[index].type === "item") {
        state.selectedVariants[state.lineItems[index].variantId] =
          state.selectedVariants[state.lineItems[index].variantId] - 1;
        if (state.selectedVariants[state.lineItems[index].variantId] <= 0) {
          delete state.selectedVariants[state.lineItems[index].variantId];
        }
      }
      state.lineItems = state.lineItems.filter(i => i.id !== lineItemId);
      return state;
    });

  // eslint-disable-next-line no-unused-vars
  removeLineItemByVariantId = variantId => e => {
    this.setState(state => {
      state.selectedVariants = Object.keys(state.selectedVariants)
        .filter(id => id !== variantId)
        .reduce((map, id) => {
          map[id] = state.selectedVariants[id];
          return map;
        }, {});
      state.lineItems = state.lineItems.filter(item => {
        return !(item.type === "item" && item.variantId === variantId);
      });
    });
  };

  updateSearch = e => this.setState({ search: e.target.value });

  nameMatchesSearch = name =>
    name.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1;

  selectType = typeId => this.setState({ activeTypeId: typeId });

  sendVariantsToBeAdded = async () => {
    const {
      addToGroupOrder,
      eventDetails,
      accountId,
      contactId,
      contactIds,
      mode
    } = this.props;
    let selectedVariants = this.state.lineItems.map(lineItem => ({
      id: R.prop("variantId", lineItem),
      quantity: 1,
      operation: null
    }));
    this.state.variantsToRemove.forEach(variant => {
      selectedVariants.push({
        quantity: 0,
        id: variant,
        operation: "remove"
      });
    });
    await addToGroupOrder({
      eventId: R.prop("id", eventDetails),
      accountId: accountId,
      contactId: contactId,
      contactIds: contactIds,
      blockId: null,
      variants: selectedVariants,
      mode: mode
    });
    this.setState(
      {
        processing: false
      },
      () => this.onDone()
    );
  };

  startAddingVariants = async () => {
    this.setState(
      {
        processing: true
      },
      async () => await this.sendVariantsToBeAdded()
    );
  };

  updateVariantQuantity = (id, quantity) =>
    this.setState(state => {
      const { variantsToRemove } = state;
      if (quantity <= 0) {
        variantsToRemove.push(id);
      } else {
        variantsToRemove.splice(variantsToRemove.indexOf(id), 1);
      }
      state.selectedVariants = {
        ...state.selectedVariants,
        [id]: quantity <= 0 ? 0 : quantity
      };
      state.lineItems = buildLineItems(state.selectedVariants);
      state.variantsToRemove = variantsToRemove;
      return state;
    });

  setBodyRef = c => (this.body = c);

  render() {
    const { mode, eventDetails, types, variants, hideModal } = this.props;
    const {
      loading,
      search,
      order,
      lineItems,
      processing,
      selectedVariants,
      activeStepId,
      activeTypeId
    } = this.state;
    const countOfSelectedItems = this.itemCount;
    const steps = [
      {
        id: "orderItems",
        name: "Items",
        active: true,
        enabled: true,
        goToNextStep: () => this.startAddingVariants(),
        processing,
        isValid: this.isStepValid(),
        meta: {
          countOfSelectedItems
        }
      }
    ];

    const itemTypesWithHandlers = R.map(
      type => ({
        ...type,
        active: activeTypeId === type.id,
        selectType: () => this.selectType(type.id),
        groups: R.compose(
          R.filter(g => !!g.items.length),
          R.map(group => ({
            ...group,
            items: R.compose(
              R.filter(i => this.nameMatchesSearch(i.name)),
              R.map(item => ({
                ...item,
                variants: R.map(variant => {
                  const quantity =
                    selectedVariants[variant.id] >= 0
                      ? selectedVariants[variant.id]
                      : 0;
                  return {
                    ...variant,
                    quantity,
                    price: 0,
                    currency: "USD",
                    incrementQuantity: () =>
                      this.updateVariantQuantity(variant.id, quantity + 1),
                    decrementQuantity: () =>
                      this.updateVariantQuantity(variant.id, quantity - 1),
                    updateVariantQuantity: qty =>
                      this.updateVariantQuantity(
                        variant.id,
                        parseInt(qty, 10) || 0
                      )
                  };
                }, item.variants)
              }))
            )(group.items)
          }))
        )(type.groups)
      }),
      types
    );

    const lineItemsWithHandlers = R.map(lineItem => {
      const variant = variants.find(v => v.id === lineItem.variantId);
      return {
        ...lineItem,
        name: variant.item.name,
        textColor: variant.item.text_color,
        backgroundColor: variant.item.background_color,
        updateLineItem: values => this.updateLineItem(lineItem.id, values),
        removeLineItem:
          lineItems.length === 1
            ? null
            : () => this.removeLineItem(lineItem.id), // cannot remove line item if only one, order has min 1 line item
        removeVariant: () => this.removeLineItemByVariantId(lineItem.variantId)
      };
    }, lineItems);

    const activeType = itemTypesWithHandlers.find(
      ({ id }) => id === activeTypeId
    );
    const activeStep = steps.find(({ id }) => id === activeStepId);

    return (
      <View
        {...{
          mode,
          hideModal,
          loading,
          order,
          activeStepId,
          activeType,
          activeStep,
          eventDetails,
          eventDateGroups: eventDetails.date_groups,
          cateringMealsPerDay: R.pathOr(
            [],
            ["module_settings", "catering", "meal_days"]
          )(eventDetails),
          lineItems: lineItemsWithHandlers,
          types: itemTypesWithHandlers,
          steps,
          setBodyRef: this.setBodyRef,
          getValue: this.getValue,
          updateValue: this.updateValue,
          search,
          updateSearch: this.updateSearch
        }}
      />
    );
  }
}

export default Controller;
