import React from "react";
import { Div, Text4 } from "components/Base";
import { Small, Medium, Big } from "./MediaQueryComponents";

const PersonalInfo = ({ leftContent }) => (
  <>
    <Small>
      <Div width={1}>
        <Text4 color="neutral8" fw={3} mb={2}>
          Personal Information
        </Text4>
        {leftContent}
      </Div>
    </Small>
    <Medium>
      <Div width={1}>
        <Text4 color="neutral8" fw={3} mb={2}>
          Personal Information
        </Text4>
        {leftContent}
      </Div>
    </Medium>
    <Big>
      <Div flex={1}>
        <Text4 color="neutral8" fw={3} mb={2}>
          Personal Information
        </Text4>
        {leftContent}
      </Div>
    </Big>
  </>
);

export default PersonalInfo;
