import React from "react";

import { put, all, takeEvery, fork, select, call } from "redux-saga/effects";
import { makeFuture } from "utils/General/sagas";
import { CREDENTIAL_TYPE_ID } from "utils/item-types";
import { FIELD_OPTIONS } from "./constants";

import { getCredentials } from "redux/modules/user/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";

import { actions, getters } from "./model";
import { registerError } from "redux/modules/errors/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { hideModal, showModal } from "redux/modules/modal/actions";
import EditSummaryModal from "EventLight/Passes/Dashboard/View/SummaryPanel/EditSummaryModal";

import Api from "./api";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventDetails = yield select(getEventDetails);

  return {
    credentials,
    eventDetails
  };
};

const init = function*() {
  try {
    yield put(actions.setLoading(true));
    const { credentials, eventDetails } = yield call(getParams);

    const [
      { dashboard: dashboardPayload },
      { payload: itemGroupsPayload }
    ] = yield all([
      call(Api.get, credentials, "event_credentials_v3", eventDetails.id),
      call(
        Api.getItemGroupsByEventAndType,
        credentials,
        eventDetails.id,
        CREDENTIAL_TYPE_ID
      )
    ]);

    const eventCredentials = itemGroupsPayload
      .filter(g => g.type_id === CREDENTIAL_TYPE_ID)
      .reduce((a, b) => [...a, ...b.items], [])
      .reduce((list, item) => {
        const items = item.variants.map(v => ({
          id: v.id,
          textColor: item.text_color,
          isDisabled: false,
          source: item.source,
          backgroundColor: item.background_color,
          value: v.display_name
        }));

        return [...list, ...items];
      }, []);

    yield put(
      actions.setInitialData({
        eventCredentials,
        dashboard: dashboardPayload
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting details"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const refreshDashboard = function*() {
  try {
    const { credentials, eventDetails } = yield call(getParams);

    const [
      { dashboard: dashboardPayload },
      { payload: itemGroupsPayload }
    ] = yield all([
      call(Api.get, credentials, "event_credentials_v3", eventDetails.id),
      call(
        Api.getItemGroupsByEventAndType,
        credentials,
        eventDetails.id,
        CREDENTIAL_TYPE_ID
      )
    ]);

    const eventCredentials = itemGroupsPayload
      .filter(g => g.type_id === CREDENTIAL_TYPE_ID)
      .reduce((a, b) => [...a, ...b.items], [])
      .reduce((list, item) => {
        const items = item.variants.map(v => ({
          id: v.id,
          textColor: item.text_color,
          isDisabled: false,
          source: item.source,
          backgroundColor: item.background_color,
          value: v.display_name
        }));

        return [...list, ...items];
      }, []);

    yield put(
      actions.setInitialData({
        eventCredentials,
        dashboard: dashboardPayload
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while refreshing dashboard"
        }
      ])
    );
  }
};

const showAddRecordModal = function*() {
  const eventCredentials = yield select(getters.eventCredentials);
  const dashboard = yield select(getters.dashboard);

  const emptySummary = {
    name: "",
    type: "credentials-summary-v3",
    settings: {
      credentials: eventCredentials.map(c => c.id),
      fields: FIELD_OPTIONS,
      filters: {}
    },
    value: {
      credentials: eventCredentials
    },
    dashboardId: dashboard.id
  };

  const handleCreate = makeFuture();
  yield put(
    showModal({
      content: (
        <EditSummaryModal
          summary={emptySummary}
          cancel={() => handleCreate.done(false)}
          onSave={summary => handleCreate.done(summary)}
          eventCredentials={eventCredentials}
        />
      )
    })
  );
  return yield call(handleCreate.onRealized);
};

const handleShowAddModal = function*() {
  const { credentials } = yield call(getParams);
  const summary = yield call(showAddRecordModal);

  yield put(hideModal());

  if (summary) {
    try {
      yield call(Api.postWidget, credentials, summary);
      yield call(refreshDashboard);
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error occurred while adding widget"
          }
        ])
      );
    }
  }
};

const watchShowAddModal = function*() {
  yield takeEvery(actions.showAddModal.type, handleShowAddModal);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchRefreshDashboard = function*() {
  yield takeEvery(actions.refreshDashboard.type, refreshDashboard);
};

const rootSaga = function*() {
  yield all([
    fork(watchInit),
    fork(watchRefreshDashboard),
    fork(watchShowAddModal)
  ]);
};

export default rootSaga;
