import PropTypes from "prop-types";
import React from "react";
import Input from "components/Global/Modal/Layout/FormElements/Input";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const RoleInput = ({ onChange }) => (
  <div styleName="inputWrapper">
    <div styleName="labelWithCaption">
      Role with this group
      <span styleName="caption">*Optional</span>
    </div>
    <Input onChange={onChange} type="text" name="role" />
  </div>
);

RoleInput.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default CSSModules(RoleInput, css);
