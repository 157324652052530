import {
  all,
  race,
  fork,
  take,
  delay,
  put,
  takeEvery
} from "redux-saga/effects";

import { actions } from "./model";
import { actions as AlertActions } from "ui-kit/Alert/model";

import { HIDE_DEFAULT } from "./constants";

const runTimmer = function*({ payload: toast, meta }) {
  if (toast.autoHideDuration !== false) {
    const { autoHide } = yield race({
      autoHide: delay(toast.autoHideDuration || HIDE_DEFAULT),
      close: take(
        ac =>
          ac.type === actions.hide.type &&
          ac.payload.id === toast.id &&
          ac.meta.instanceId === meta.instanceId
      )
    });
    if (autoHide) {
      yield put(actions.hide(toast.id, { meta }));
    }
  }
};

const watchShowToast = function*() {
  yield takeEvery(actions.show.type, runTimmer);
};

const watchExecuteAction = function*() {
  for (;;) {
    const {
      payload: { data: toast },
      meta
    } = yield take(AlertActions.executeAction.type);
    yield put(actions.hide(toast.id, { meta }));
  }
};

const rootSaga = function*() {
  yield all([fork(watchShowToast), fork(watchExecuteAction)]);
};

export default rootSaga;
