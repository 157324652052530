import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import Button from "components/Global/CRM/ActionButton";
import CollapsablePanel from "components/Global/CRM/CollapsablePanel";
import EditProfile from "./EditProfileValue";

const About = ({
  data,
  disabled,
  eventDetails,
  name = "",
  readOnly,
  showStarredFields,
  updateField,
  viewAllProperties,
  ...rest
}) => (
  <CollapsablePanel title={`About ${name}`}>
    <div>
      <EditProfile
        bio={data}
        disabled={disabled}
        eventDetails={eventDetails}
        fieldSet={showStarredFields ? null : "About"}
        showStarredFields={showStarredFields}
        updateField={updateField}
        readOnly={readOnly}
        {...rest}
      />

      {!disabled && (
        <div styleName="buttonWrapper">
          <Button title="View all properties" onClick={viewAllProperties} />
        </div>
      )}
    </div>
  </CollapsablePanel>
);

About.propTypes = {
  data: PropTypes.shape({
    fields: PropTypes.object,
    fieldGroups: PropTypes.array,
    values: PropTypes.object
  }).isRequired,
  disabled: PropTypes.bool,
  eventDetails: PropTypes.object.isRequired,
  name: PropTypes.string,
  updateField: PropTypes.func.isRequired,
  viewAllProperties: PropTypes.func.isRequired,
  showStarredFields: PropTypes.bool
};

export default CSSModules(About, css);
