import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import FileIcon from "components/Atoms/FileIcon";
import Helpers from "utils/Global/Helpers";
import File from "./Preview";
import DeletePopover from "components/Atoms/File/DeletePopover";
import Popover from "@lennd/material-ui/Popover";
import EmptyState from "./EmptyState";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FileViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: props.files,
      index: props.start || 0,
      showDeletePopover: false,
      anchorEl: null
    };
  }
  componentDidMount() {
    document.addEventListener("keydown", this.keyDown);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
  }

  @autobind
  keyDown(e) {
    switch (e.which) {
      case 27:
        this.props.hideModal();
        break;
      case 37:
        this.back();
        break;
      case 39:
        this.next();
        break;
      default:
    }
  }

  stopBubbling(e) {
    e.stopPropagation();
  }

  @autobind
  next() {
    if (this.state.index !== this.state.files.length - 1) {
      this.setState({
        index: this.state.index + 1
      });
    }
  }

  @autobind
  back() {
    if (this.state.index !== 0) {
      this.setState({
        index: this.state.index - 1
      });
    }
  }

  downloadFile(url) {
    window.open(url, "_blank");
  }

  @autobind
  deleteFile(file) {
    const { files, index } = this.state;
    this.props.onFileDelete(file);
    this.setState({
      files: files.filter(f => f !== file),
      index: files.length - 1 > index ? index : index - 1
    });
    this.onRequestClose();
  }

  @autobind
  confirmDelete(e) {
    this.setState({
      showDeletePopover: true,
      anchorEl: e.target
    });
  }

  @autobind
  onRequestClose() {
    this.setState({ showDeletePopover: false });
  }

  renderEmptyState() {
    return <EmptyState />;
  }

  render() {
    const { hideModal, controls, showCount } = this.props;
    const { files, index } = this.state;
    if (!files.length) {
      return this.renderEmptyState();
    }
    const cur = files[index];
    const isImage = Helpers.isImage(cur.mimetype);
    const isPdf = Helpers.isPDF(cur.mimetype);
    const canNext = index < files.length - 1;
    const canBack = index >= 1;

    return (
      <div onMouseDown={this.stopBubbling} styleName="wrapper">
        <div styleName="header">
          <FileIcon size={15} mimetype={cur.mimetype} />
          <div styleName="title">
            {cur.filename}
            {showCount ? ` (${index + 1}/${files.length})` : ""}
          </div>
        </div>

        <div styleName="stage">
          <div styleName="content">
            <i
              className="material-icons"
              styleName={
                canBack ? "navigationArrow" : "navigationArrowDisabled"
              }
              onClick={canBack ? this.back : null}
            >
              keyboard_arrow_left
            </i>
            <div styleName="currentPreview">
              {isImage ? (
                <img styleName="image" src={cur.url} />
              ) : isPdf ? (
                <iframe
                  src={`https://docs.google.com/viewer?url=${cur.url}&embedded=true`}
                  type="application/pdf"
                  width="100%"
                  height="100%"
                />
              ) : (
                <iframe
                  src={cur.url.replace("/file", "/preview")}
                  width="100%"
                  height="100%"
                />
              )}
            </div>
            <i
              className="material-icons"
              styleName={
                canNext ? "navigationArrow" : "navigationArrowDisabled"
              }
              onClick={canNext ? this.next : null}
            >
              keyboard_arrow_right
            </i>
          </div>
          <i className="material-icons" styleName="close" onClick={hideModal}>
            close
          </i>
        </div>
        <div styleName="gallery">
          {files.map((file, i) => (
            <div key={file.id} styleName="galleryImage">
              <File
                isSelected={i === index}
                onClick={() => this.setState({ index: i })}
                file={file}
              />
            </div>
          ))}
        </div>
        <div styleName="controls">
          {this.props.onFileDelete && !this.props.isReadOnly ? (
            <i
              className="material-icons"
              styleName="actionIcon"
              onClick={this.confirmDelete}
            >
              delete
            </i>
          ) : null}
          {this.props.canDownload ? (
            <i
              className="material-icons"
              styleName="actionIcon"
              onClick={() => this.downloadFile(cur.url)}
            >
              file_download
            </i>
          ) : null}
          {/* Commented out until we know what full-screen does
            <i className="material-icons" styleName="actionIcon" onClick={() => this.downloadFile(cur.url)}>open_in_new</i>
          */}
          {controls}
        </div>

        <Popover
          open={this.state.showDeletePopover}
          anchorEl={this.state.anchorEl}
          anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
          transformOrigin={{ horizontal: "right", vertical: "bottom" }}
          onClose={this.onRequestClose}
        >
          <DeletePopover
            delete={() => this.deleteFile(cur)}
            cancel={this.onRequestClose}
          />
        </Popover>
      </div>
    );
  }
}

FileViewer.defaultProps = {
  canDownload: true,
  showCount: false
};

FileViewer.propTypes = {
  onFileDelete: PropTypes.func.isRequired,
  controls: PropTypes.node,
  hideModal: PropTypes.func,
  start: PropTypes.number,
  files: PropTypes.array,
  isReadOnly: PropTypes.bool,
  canDownload: PropTypes.bool,
  showCount: PropTypes.bool
};

export default FileViewer;
