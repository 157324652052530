import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as UserSelectors from "redux/modules/user/selectors";
import Controller from "./Controller";

import { getUserAccessLevels } from "redux/modules/user/access-levels/actions";
import { updateUser } from "redux/modules/organization/users/actions";

function mapStateToProps(state) {
  return {
    currentUser: UserSelectors.user(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUserAccessLevels,
      updateUser
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Controller);
