import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE, DEFAULT_FIELD_ORDER } from "./constants";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

const iniState = {
  loading: true,
  timezone: null,
  healthPassFields: {
    field_groups: []
  },
  contactFields: {
    field_groups: []
  },
  allFields: [],
  fields: [],
  requiredIds: [],
  selectedFields: [],
  selectedOrder: DEFAULT_FIELD_ORDER,
  description: {},
  tempDescription: {
    id: "",
    text: ""
  },
  alias: {},
  tempAlias: {
    id: "",
    text: ""
  },
  headerSections: [],
  showSelectFieldsModal: false,
  error: null,
  registrationUrl: "",
  data: {
    welcome_title: "Daily Health Questionnaire",
    welcome_message:
      "<p>Welcome to your Health Pass. Please read the following terms and then go to the next page and submit your questionnaire.</p>",
    approved_message:
      "<p>Please display this pass when arriving to work. You will be notified to submit a new questionnaire when your pass is about to expire.</p>",
    denied_message:
      "<p>Please stay home and someone will reach out to shortly.</p>",
    reminder_message: "<p>Please fill out your health pass questionnaire.</p>",
    background_image_url: null,
    testing_begins_at: null,
    testing_ends_at: null,
    expires_after_num_hours: 24,
    send_at_time: "06:00",
    frequency: "recurring",
    notifyUserIds: []
  }
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    save: R.identity,
    addField: R.identity,
    editField: R.identity,
    saveSelectFieldsModal: R.identity,
    openRegistrationPage: R.identity,
    copyValue: R.identity,
    setInitialData: (
      state,
      {
        payload: {
          timezone,
          healthPassFields,
          contactFields,
          fields,
          sections,
          ...details
        }
      }
    ) => {
      const sortedFields = R.compose(R.sortBy(R.prop("order")))(fields);

      const selectedFields = R.compose(
        R.map(f => ({
          id: f.field.id,
          name: f.field.name,
          type: f.field.type,
          required: f.is_required,
          alias: f.alias,
          description: f.description,
          order: f.order,
          moduleLabel:
            f.field.module_id === STANDARD_MODULES.healthPass.id
              ? "Health Pass Question"
              : "Attendee Field"
        }))
      )(sortedFields);

      const headerSections = R.compose(
        R.map(s => ({
          id: s.id,
          order: s.order,
          title: s.title,
          description: s.description,
          isHeader: true
        }))
      )(sections);

      return {
        timezone,
        fields: sortedFields,
        headerSections,
        allFields: [...healthPassFields.fields, ...contactFields.fields].map(
          f => ({
            ...f,
            moduleLabel:
              f.module_id === STANDARD_MODULES.healthPass.id
                ? "Health Pass Field"
                : "Attendee Field"
          })
        ),
        healthPassFields,
        contactFields,
        requiredIds: R.compose(
          R.map(R.prop("id")),
          R.filter(R.propEq("required", true))
        )(selectedFields),
        selectedOrder: selectedFields.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.id]: curr.order
          }),
          {}
        ),
        description: selectedFields.reduce((acc, curr) => {
          if (R.isNil(curr.description)) {
            return acc;
          }
          return {
            ...acc,
            [curr.id]: curr.description
          };
        }, {}),
        alias: selectedFields.reduce((acc, curr) => {
          if (R.isNil(curr.alias)) {
            return acc;
          }
          return {
            ...acc,
            [curr.id]: curr.alias
          };
        }, {}),
        selectedFields,
        data: {
          ...details,
          welcome_title: details.welcome_title || state.data.welcome_title,
          welcome_message:
            details.welcome_message || state.data.welcome_message,
          reminder_message:
            details.reminder_message || state.data.reminder_message,
          approved_message:
            details.approved_message || state.data.approved_message,
          denied_message: details.denied_message || state.data.denied_message,
          expires_after_num_hours:
            details.expires_after_num_hours ||
            state.data.expires_after_num_hours,
          frequency: details.frequency || state.data.frequency,
          send_at_time: details.send_at_time || state.data.send_at_time
        }
      };
    },
    updateField: (state, { payload: { field, value } }) => ({
      data: R.assoc(field, value, state.data)
    }),
    showCreatedField: (state, { payload: { field } }) => ({
      selectedFields: [...state.selectedFields, field],
      selectedOrder: {
        ...state.selectedOrder,
        [field.id]:
          Math.max(
            Object.keys(state.selectedOrder).map(k => state.selectedOrder[k])
          ) + 1
      }
    }),
    updateSelectedFields: (state, { payload: selectedFieldIds }) => ({
      selectedFields: R.map(
        id => R.find(R.propEq("id", id), state.allFields),
        R.uniq(selectedFieldIds)
      )
    }),
    removeRow: (state, { payload: { id } }) => {
      const selectedFields = R.filter(
        field => field.id !== id,
        state.selectedFields
      );
      return {
        selectedFields,
        requiredIds: R.filter(fieldId => fieldId !== id, state.requiredIds),
        selectedOrder: selectedFields.reduce((acc, field, index) => {
          return {
            ...acc,
            [index]: field.id
          };
        }, {})
      };
    },
    toggleRequiredField: (state, { payload: { id, required } }) => ({
      requiredIds: required
        ? R.filter(fieldId => fieldId !== id, state.requiredIds)
        : [...state.requiredIds, id]
    }),
    reorderFields: (state, { payload: orderedFields }) => {
      let counter = 0;
      const { fieldsOrder, sectionList } = orderedFields.reduce(
        (acc, curr, idx, src) => {
          const isPreviousSection = R.propOr(false, "isHeader", src[idx - 1]);

          if (isPreviousSection) {
            counter = counter + 1;
          }
          if (curr.isHeader) {
            return {
              ...acc,
              sectionList: [
                ...acc.sectionList,
                {
                  ...curr,
                  order: idx - counter
                }
              ]
            };
          }
          return {
            ...acc,
            fieldsOrder: {
              ...acc.fieldsOrder,
              [curr.id]: idx - counter
            }
          };
        },
        { fieldsOrder: {}, sectionList: [] }
      );

      return {
        selectedOrder: fieldsOrder,
        headerSections: sectionList
      };
    },
    updateAlias: (state, { payload: { id, description } }) => ({
      alias: R.assoc(id, description, state.alias),
      tempAlias: {
        id: "",
        text: ""
      }
    }),
    updateDescription: (state, { payload: { id, description } }) => ({
      description: R.assoc(id, description, state.description),
      tempDescription: {
        id: "",
        text: ""
      }
    }),
    clearTempDescriptions: () => ({
      tempDescription: {
        id: "",
        text: ""
      }
    }),
    addHeaderSection: (state, { payload: { order } }) => ({
      headerSections: [
        ...state.headerSections,
        {
          id: new Date().getTime(),
          order,
          title: "",
          description: "",
          isHeader: true
        }
      ]
    }),
    removeHeaderSection: (state, { payload: { id } }) => ({
      headerSections: R.filter(
        R.compose(
          R.not,
          R.propEq("id", id)
        ),
        state.headerSections
      )
    }),
    updateDescriptionHeaderSection: (
      state,
      { payload: { id, description } }
    ) => {
      const headerSectionIndex = R.findIndex(
        R.propEq("id", id),
        state.headerSections
      );
      const headerSection = state.headerSections[headerSectionIndex];
      return {
        headerSections: R.update(
          headerSectionIndex,
          { ...headerSection, description },
          state.headerSections
        ),
        tempDescription: {
          id: "",
          text: ""
        }
      };
    },
    updateTitleHeaderSection: (state, { payload: { id, title } }) => {
      const headerSectionIndex = R.findIndex(
        R.propEq("id", id),
        state.headerSections
      );
      const headerSection = state.headerSections[headerSectionIndex];
      return {
        headerSections: R.update(
          headerSectionIndex,
          { ...headerSection, title },
          state.headerSections
        )
      };
    },
    removeAlias: (state, { payload: { id } }) => ({
      alias: state.activeIndex === 1 ? R.dissoc(id, state.alias) : state.alias
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
