import * as R from "ramda";
import { takeEvery, put, all, fork } from "redux-saga/effects";

import { actions } from "./model";
import validate from "./utils";

const executeValidation = function*({
  payload: { id, type, value, ...params },
  meta
}) {
  try {
    const error = validate({ type, value, ...params });
    yield put(actions.validationResponse({ id, error }, { meta }));
  } catch (error) {
    yield put(actions.validationResponse(null, { meta }, true));
  }
};

const watchExecuteValidation = function*() {
  yield takeEvery(actions.validationRequest.type, executeValidation);
};

const executeBulkValidation = function*({ payload: { values, id }, meta }) {
  try {
    const errors = R.indexBy(R.prop("id"), R.map(validate, values));
    yield put(actions.bulkValidationResponse({ errors, id }, { meta }));
  } catch (error) {
    yield put(actions.bulkValidationResponse(null, { meta }, true));
  }
};

const watchExecuteBulkValidation = function*() {
  yield takeEvery(actions.bulkValidationRequest.type, executeBulkValidation);
};

const rootSaga = function*() {
  yield all([fork(watchExecuteValidation), fork(watchExecuteBulkValidation)]);
};

export default rootSaga;
