import { createHandlers } from "redux-mvc";
import * as R from "ramda";
import { NAMESPACE } from "./constants";

const iniState = {
  editingSectionId: -1,
  loading: false,
  recordTypes: [],
  template: {},
  tempSection: {}
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    addRelatedModuleSections: R.identity,
    addSort: R.identity,
    cancelEditingSectionName: () => ({
      editingSectionId: -1,
      tempSection: {}
    }),
    cancelInstance: R.always(iniState),
    clearSelectedRecordTypes: state => ({
      template: {
        ...state.template,
        record_type_ids: []
      }
    }),
    deleteSection: R.identity,
    deleteTemplate: R.identity,
    deleteTemplateSection: R.identity,
    enabledEditingSectionName: (state, { payload: { editingSectionId } }) => ({
      editingSectionId,
      tempSection: R.find(
        section => section.id === editingSectionId,
        R.propOr([], "sections", state.template)
      )
    }),
    init: R.identity,
    refetchTemplate: R.identity,
    removeSort: R.identity,
    reorderTemplateSections: R.identity,
    selectAllRecordTypedIds: R.identity,
    selectModuleFields: R.identity,
    setInitialData: (state, { payload: { template, recordTypes } }) => ({
      template,
      recordTypes
    }),
    toggleRecordTypes: (state, { payload: { isSelected, id } }) => {
      const recordTypeIds = R.propOr([], "record_type_ids", state.template);
      return {
        template: {
          ...state.template,
          record_type_ids: isSelected
            ? R.filter(selectedId => selectedId !== id, recordTypeIds)
            : [...recordTypeIds, id]
        }
      };
    },
    updateEditingSectionName: (state, { payload: { name } }) => ({
      tempSection: {
        ...state.tempSection,
        name
      }
    }),
    updateRecordTypedIds: R.identity,
    updateSection: R.identity,
    updateSectionOrder: R.identity,
    updateTemplate: R.identity,
    updateTemplateField: (state, { payload: { key, value } }) => ({
      template: {
        ...state.template,
        [key]: value
      }
    }),
    updateTemplateLandscape: R.identity,
    updateTemplateName: R.identity,
    updateTemplateSection: R.identity
  }
});

export default handlers;
