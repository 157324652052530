import Helpers from "utils/Global/Helpers";

/* eslint no-underscore-dangle: "off" */

export default {
  getQuestionSets: (credentials, eventId) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/items/question-sets/event/${eventId}`,
        credentials,
        success,
        error
      });
    }),
  getQuestion: (credentials, questionId) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/items/questions/${questionId}`,
        credentials,
        success,
        error
      });
    }),
  addQuestion: (credentials, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "post",
        url: `${window.__LENND_API_BASE_URL__}/items/questions`,
        credentials,
        data,
        success,
        error
      });
    }),
  updateQuestion: (credentials, data) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "put",
        url: `${window.__LENND_API_BASE_URL__}/items/questions`,
        credentials,
        data,
        success,
        error
      });
    })
};
