import * as R from "ramda";
import React from "react";
import moment from "moment-timezone";
import CSSModules from "react-css-modules";

import { Div, Text3, TinyFilledBox } from "components/Base";
import Tooltip from "components/Global/Tooltip";
import Circle from "ui-kit/Circle";

import { noop } from "utils/General";

import styles from "./scheduleListStyles.scss";

const decorate = CSSModules(styles);

const getSubTitle = ({ event, dateFormat, timezone }) => {
  if (dateFormat === "MMMM D" && moment(event.start).isSame(event.end, "day")) {
    return moment(event.start)
      .tz(timezone)
      .format(dateFormat);
  }
  return `${moment(event.start)
    .tz(timezone)
    .format(dateFormat)} - ${moment(event.end)
    .tz(timezone)
    .format(dateFormat)}`;
};

const EventItem = ({
  event,
  now,
  timezone,
  dateFormat = "h:mm a",
  eventEdit = noop
}) => (
  <div
    styleName={now.isAfter(event.end) ? "event-before" : "event-after"}
    onClick={() => eventEdit(event)}
  >
    {event.thumbnailUrl ? (
      <div
        styleName="thumbnail"
        style={{
          backgroundImage: `url(${event.thumbnailUrl})`
        }}
      />
    ) : null}
    <div styleName="title-container">
      <div styleName="title">{event.title}</div>
      <div styleName="sub-title">
        {getSubTitle({ event, dateFormat, timezone })}
      </div>
      {event.place && <div styleName="sub-title">{event.place}</div>}
      {!R.isEmpty(event.tags) && event.tags && (
        <div styleName="tag-container">
          {R.map(
            tag => (
              <TinyFilledBox pill bg="neutral2" p={1} mr={1} color="neutral7">
                {tag}
              </TinyFilledBox>
            ),
            event.tags
          )}
        </div>
      )}
    </div>
    {now.isBetween(event.start, event.end) ? (
      <Tooltip
        placement="top"
        tooltip={
          <Div display="row.flex-start.center">
            <Circle
              size={7}
              style={{
                backgroundColor: "rgba(255, 0, 0, 0.8)",
                marginRight: 12
              }}
            />
            <Text3 color="white">In Progress</Text3>
          </Div>
        }
      >
        <div
          style={{
            height: 20,
            width: 24,
            position: "absolute",
            left: -24,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Circle
            className="in-progress"
            size={7}
            style={{
              backgroundColor: "rgba(255, 0, 0, 0.8)",
              position: "absolute"
            }}
          />
        </div>
      </Tooltip>
    ) : null}
  </div>
);

export default decorate(EventItem);
