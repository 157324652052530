import { put, all, takeEvery, fork, select, call } from "redux-saga/effects";

import { getCredentials } from "redux/modules/user/selectors";
import { orgDetails as getOrgDetails } from "redux/modules/organization/selectors";

import { actions } from "./model";
import { registerError } from "redux/modules/errors/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";

import Api from "./api";

import addListOfPermissionSlugs from "redux/modules/permissions/utils/add-list-of-permission-slugs";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const orgDetails = yield select(getOrgDetails);

  return {
    credentials,
    orgDetails
  };
};

const init = function*() {
  try {
    const { credentials, orgDetails } = yield call(getParams);

    yield put(actions.setLoading(true));

    const { payload: orgPermissionGroups } = yield call(
      Api.getPermissionGroups,
      credentials,
      orgDetails.id
    );

    const { payload: orgPermissionProfiles } = yield call(
      Api.getPermissionProfiles,
      credentials,
      orgDetails.id
    );

    const permissionGroups = orgPermissionGroups.map(addListOfPermissionSlugs);
    const permissionProfiles = orgPermissionProfiles.map(
      addListOfPermissionSlugs
    );

    yield put(
      actions.setInitData({
        permissionGroups,
        permissionProfiles
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting details"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const getOrgPermissionProfiles = function*() {
  try {
    const { credentials, orgDetails } = yield call(getParams);

    const { payload: orgPermissionProfiles } = yield call(
      Api.getPermissionProfiles,
      credentials,
      orgDetails.id
    );

    const permissionProfiles = orgPermissionProfiles.map(
      addListOfPermissionSlugs
    );

    yield put(actions.setPermissionProfiles(permissionProfiles));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting details"
        }
      ])
    );
  }
};

const updatePermissionProfilePermissions = function*({ payload: data }) {
  try {
    const { credentials } = yield call(getParams);

    yield call(Api.putPermissions, credentials, data);

    yield call(getOrgPermissionProfiles);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while getting details"
        }
      ])
    );
  }
};

const addOrgPermissionProfile = function*({ payload: data }) {
  try {
    const { credentials } = yield call(getParams);

    const { payload } = yield call(Api.post, credentials, data);

    yield call(getOrgPermissionProfiles);

    yield put(actions.setActivePermissionProfileId(payload.id));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while adding profile"
        }
      ])
    );
  }
};

const updatePermissionProfile = function*({ payload: data }) {
  try {
    const { credentials } = yield call(getParams);
    yield call(Api.put, credentials, data);
    yield call(getOrgPermissionProfiles);
    yield put(showSnackbar({ message: "Profile Updated" }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while updating profile"
        }
      ])
    );
  }
};

const deletePermissionProfile = function*({ payload: data }) {
  try {
    const { credentials } = yield call(getParams);
    yield call(Api.delete, credentials, data);
    yield call(getOrgPermissionProfiles);
    yield put(showSnackbar({ message: "Profile Deleted" }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred while deleting profile"
        }
      ])
    );
  }
};

const watchUpdatePermissionProfilePermissions = function*() {
  yield takeEvery(
    actions.updatePermissionProfilePermissions.type,
    updatePermissionProfilePermissions
  );
};

const watchGetOrgPermissionProfiles = function*() {
  yield takeEvery(
    actions.getOrgPermissionProfiles.type,
    getOrgPermissionProfiles
  );
};

const watchAddOrgPermissionProfile = function*() {
  yield takeEvery(
    actions.addOrgPermissionProfile.type,
    addOrgPermissionProfile
  );
};

const watchUpdatePermissionProfile = function*() {
  yield takeEvery(
    actions.updatePermissionProfile.type,
    updatePermissionProfile
  );
};

const watchDeletePermissionProfile = function*() {
  yield takeEvery(
    actions.deletePermissionProfile.type,
    deletePermissionProfile
  );
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([
    fork(watchAddOrgPermissionProfile),
    fork(watchUpdatePermissionProfile),
    fork(watchDeletePermissionProfile),
    fork(watchUpdatePermissionProfilePermissions),
    fork(watchGetOrgPermissionProfiles),
    fork(watchInit)
  ]);
};

export default rootSaga;
