import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import {
  FILES,
  DOCUMENT_REQUEST_TYPES,
  DOCUMENT_REQUESTS,
  DOCUMENT_REQUEST_REVISIONS,
  FORMSV3,
  MODULE
} from "@lennd/value-types/src/constants/standard-module-field-ids";

const {
  ID,
  OWNERS,
  CREATED_AT,
  LAST_MODIFIED_AT,
  LAST_SENT_AT,
  APPROVAL,
  SOURCE,
  EVENT_LIST,
  TYPE,
  SUBMITTING_ACCOUNT,
  SUBMITTING_CONTACT
} = MODULE;

const { MIMETYPE, FILENAME, URL, SIZE } = FILES;

const {
  ASSIGNMENT_SCOPE,
  SAMPLE_DOCUMENTS,
  DUE_DATE,
  CRITERIA,
  ENABLE_REMINDERS
} = DOCUMENT_REQUEST_TYPES;

const {
  REQUEST_NAME,
  REQUEST_REVISION_STATUS,
  REQUEST_TYPE_NAME,
  RECIPIENT_NAME
} = DOCUMENT_REQUESTS;

const { REVISION_STATUS, FILES: REVISION_FILES } = DOCUMENT_REQUEST_REVISIONS;

const fields = [
  ID,
  OWNERS,
  CREATED_AT,
  LAST_MODIFIED_AT,
  LAST_SENT_AT,
  APPROVAL,
  SOURCE,
  TYPE
];

export default function resolveReadOnlyFields(
  props = { moduleId: null, for: null }
) {
  switch (props.moduleId) {
    case STANDARD_MODULE_IDS.files.id:
      return [
        ...fields,
        MIMETYPE,
        FILENAME,
        URL,
        SIZE,
        SUBMITTING_ACCOUNT,
        SUBMITTING_CONTACT
      ];
    case STANDARD_MODULE_IDS.documentRequests.id:
    case STANDARD_MODULE_IDS.documentRequestTypes.id:
    case STANDARD_MODULE_IDS.documentRequestRevisions.id:
      return [
        ...fields,
        // request type
        ASSIGNMENT_SCOPE,
        SAMPLE_DOCUMENTS,
        DUE_DATE,
        CRITERIA,
        ENABLE_REMINDERS,
        // request
        REQUEST_NAME,
        REQUEST_REVISION_STATUS,
        REQUEST_TYPE_NAME,
        RECIPIENT_NAME,
        // revision
        REVISION_STATUS,
        REVISION_FILES
      ];
    case STANDARD_MODULE_IDS.formsv3.id:
      return [...fields, FORMSV3.MODULE];
    case STANDARD_MODULE_IDS.accounts.id:
      return ["filter", "sort"].includes(props.for)
        ? [
            // insert field IDs that shouldn't be filterable / sortable
          ]
        : [...fields, EVENT_LIST, "name", "contact_count", "event_list"];
    case STANDARD_MODULE_IDS.contacts.id:
      return ["filter", "sort"].includes(props.for)
        ? [
            // insert field IDs that shouldn't be filterable / sortable
          ]
        : [...fields, EVENT_LIST, "full_name", "accounts_list", "event_list"];
    case STANDARD_MODULE_IDS.healthPass.id:
      return [...fields, SUBMITTING_ACCOUNT, SUBMITTING_CONTACT];
    default:
      return ["filter", "sort"].includes(props.for)
        ? [
            // insert field IDs that shouldn't be filterable / sortable
          ]
        : fields;
  }
}
