import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getters, actions } from "../model";

import { Div, BigFilledButton } from "components/Base";
import CredentialSummaryWidget from "EventLight/Passes/Dashboard/View/SummaryPanel";
import EmptyCredentialSummaryWidget from "EventLight/Passes/Dashboard/View/EmptySummaryPanel";
import Loading from "ui-kit/Loading";

const decorate = connect(
  state => ({
    loading: getters.loading(state),
    dashboard: getters.dashboard(state),
    credentials: getters.eventCredentials(state)
  }),
  {
    init: actions.init,
    refreshDashboard: actions.refreshDashboard,
    showAddModal: actions.showAddModal
  }
);

const View = ({
  init,
  dashboard: { widgets = [] },
  credentials,
  eventId,
  refreshDashboard,
  showAddModal,
  loading
}) => {
  useEffect(() => {
    init();
  }, []);

  if (loading) {
    return <Loading />;
  }
  return (
    <div
      style={{
        width: "100%",
        height: "calc(100vh - 116px)",
        overflowY: "auto"
      }}
    >
      <BigFilledButton mt={5} ml={5} bg="altB5" onClick={showAddModal}>
        Add Summary
      </BigFilledButton>

      <Div p={5}>
        {widgets.length ? (
          widgets.map(w => (
            <CredentialSummaryWidget
              key={w.id}
              params={{ eventId }}
              refreshDashboard={refreshDashboard}
              widget={w}
              widgets={widgets}
              credentials={credentials}
            />
          ))
        ) : (
          <EmptyCredentialSummaryWidget addWidget={showAddModal} />
        )}
      </Div>
    </div>
  );
};

export default decorate(View);
