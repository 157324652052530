import React from "react";
import { Div, LeftArrowIcon } from "components/Base";
import AllNewsAndAlertsList from "Portal/PortalPagePGA/AllNewsAndAlertsList/View";
import AllNewsAndAlertsSingle from "Portal/PortalPagePGA/AllNewsAndAlertsSingle/View";
import AllReplaysList from "Portal/PortalPagePGA/AllReplaysList/View";
import OverviewAndResources from "Portal/PortalPagePGA/OverviewAndResources/View";
import ResourcesPage from "Portal/PortalPagePGA/Resources/View";
import { withRouter } from "react-router";

const DetailView = ({ params, location, router }) => {
  function goBack() {
    if (window.history.length <= 2) {
      router.replace(
        `/portals/${params.portalSlug}/${params.portalId}/pages/${params.pageId}`
      );
    } else {
      router.goBack();
    }
  }

  return (
    <Div>
      <Div
        display="row.center.center"
        mr={2}
        bg={{
          default: "inherit",
          hover: "#E2E2E2"
        }}
        color={{
          default: "#1789EB",
          hover: "#222222"
        }}
        onClick={goBack}
        uppercase
        style={{
          height: "37px",
          width: "77px",
          border: "1px solid #E6E6E6",
          borderRadius: "6px",
          fontFamily: "Roboto Condensed",
          letterSpacing: "0.4px",
          fontSize: "14px"
        }}
      >
        <LeftArrowIcon fs={5} color="inherit" mr={1} />
        back
      </Div>
      <Div>
        {params.alertId ? (
          <AllNewsAndAlertsSingle />
        ) : !params.alertId && location.pathname.includes("alerts") ? (
          <AllNewsAndAlertsList />
        ) : location.pathname.includes("videos") ? (
          <AllReplaysList />
        ) : location.pathname.includes("sections") ? (
          <ResourcesPage />
        ) : location.pathname.includes("overview-and-resources") ? (
          <OverviewAndResources />
        ) : null}
      </Div>
    </Div>
  );
};

export default withRouter(DetailView);
