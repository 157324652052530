import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";

import {
  Div,
  AttachIcon,
  Text2,
  BigOutlineButton,
  UploadIcon,
  Cards,
  DragIcon,
  EditIcon,
  SmallFilledButton,
  MediumFilledInput,
  MediumFilledButton,
  CloseIcon
} from "components/Base";
import { actions, getters } from "Sponsors/SelfSetup";
import { getFiles } from "Sponsors/SelfSetup/selectors";

import { WithThemeConsumer, defaultTh, VARIANTS } from "ui-kit/Theme";
import { PackageContainer } from "Sponsors/Common/View";
import { Row, Column } from "ui-kit/Form/View";

const decorate = R.compose(
  WithThemeConsumer({ variant: VARIANTS.SURFACE }),
  connect(
    state => ({
      files: getFiles(state),
      editingFileId: getters.editingFileId(state),
      updatedFilename: getters.updatedFilename(state)
    }),
    {
      showSalesDeckModal: actions.showSalesDeckModal,
      reorderEventShowcaseFiles: actions.reorderEventShowcaseFiles,
      deleteEventShowcaseFile: actions.deleteEventShowcaseFile,
      setEditingFileId: actions.setEditingFileId,
      setUpdatedFilename: actions.setUpdatedFilename,
      updateFilename: actions.updateFilename
    }
  )
);

const File = ({
  id,
  title,
  description,
  file_url,
  deleteEventShowcaseFile,
  editingFileId,
  setEditingFileId,
  updatedFilename,
  setUpdatedFilename,
  updateFilename
}) => {
  return (
    <Div
      display="row.flex-start.center"
      mb={3}
      p={2}
      bg="white"
      bc="gray2"
      bra={1}
    >
      <DragIcon size={20} mr={2} />
      <AttachIcon size={20} mr={2} />
      <Div display="column">
        {id !== editingFileId ? (
          <Div display="row.flex-start.center">
            <Div fw={3}>{title}</Div>
            <SmallFilledButton
              ml={1}
              bg="neutral0"
              width={20}
              height={1}
              onClick={() => {
                setUpdatedFilename(title);
                setEditingFileId(id);
              }}
            >
              <EditIcon />
            </SmallFilledButton>
          </Div>
        ) : (
          <Div display="row.flex-start.center">
            <MediumFilledInput
              bg="neutral1"
              width={200}
              height={1}
              value={updatedFilename}
              color="black"
              continuous
              onChange={value => setUpdatedFilename(value)}
            />
            <MediumFilledButton
              bg="primary7"
              onClick={() => {
                updateFilename({ id, title: updatedFilename });
                setEditingFileId(0);
              }}
            >
              Save
            </MediumFilledButton>
            <MediumFilledButton
              bg="neutral1"
              ml={1}
              width={30}
              onClick={() => setEditingFileId(0)}
            >
              <CloseIcon color="black" />
            </MediumFilledButton>
          </Div>
        )}
        <Div fs={2} display="row.flex-start.center">
          <Div color="primary8" onClick={() => window.open(file_url, "_blank")}>
            View
          </Div>
          <Div mx={1}>&middot;</Div>
          <Div color="primary8" onClick={() => deleteEventShowcaseFile(id)}>
            Remove
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

const Files = Cards(File, "FILES");

const SalesDeck = ({
  Th = defaultTh,
  files,
  reorderEventShowcaseFiles,
  showSalesDeckModal,
  deleteEventShowcaseFile,
  editingFileId,
  setEditingFileId,
  updatedFilename,
  setUpdatedFilename,
  updateFilename
}) => (
  <PackageContainer
    title="Sales Deck or Other Documents (.pdf)"
    // caption={<Text2>View Example</Text2>}
  >
    <Column {...Th(["bg"], { flex: 1, p: 3, bra: 1 })}>
      {files.length ? (
        <Column>
          <Files
            updatedFilename={updatedFilename}
            setUpdatedFilename={setUpdatedFilename}
            updateFilename={updateFilename}
            editingFileId={editingFileId}
            setEditingFileId={setEditingFileId}
            deleteEventShowcaseFile={deleteEventShowcaseFile}
            onReorder={reorderEventShowcaseFiles}
            cards={files}
          />
        </Column>
      ) : null}
      <Row>
        <BigOutlineButton
          onClick={showSalesDeckModal}
          LeftIcon={UploadIcon}
          {...Th(["bc", "color"])}
        >
          Upload Document(s)
        </BigOutlineButton>
      </Row>
    </Column>
  </PackageContainer>
);

export default decorate(SalesDeck);
