import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const SelectedWellItem = ({ title, icon }) => (
  <div styleName="container">
    <div styleName="title" title={title}>
      {title}
    </div>
    {icon}
  </div>
);

SelectedWellItem.propTypes = {};

export default CSSModules(SelectedWellItem, css);
