import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";

const iniState = {
  loading: true,
  user: null,
  activeUserId: "",
  showModal: false
};

const model = createHandlers({
  iniState,
  reducers: {
    fetchUser: R.identity,
    save: R.identity,
    saveResponse: R.identity,
    generateApiToken: R.identity,
    copyApiToken: R.identity,
    toggleTokenAccess: R.identity,
    openModal: (_, { payload: activeUserId }) => ({
      activeUserId,
      showModal: true
    }),
    updateUserField: (state, { payload: { field, value } }) => ({
      user: R.assoc(field, value, state.user)
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
