import Worker from "utils/Search/fuse.worker.js";

const defaultOptions = {
  threshold: 0.0
};

/**
 * @param  {Array} keys      Array of keys as strings to use for searching the `objects`
 * @param  {Array} objects   Array of search objects where the key is in the `keys` array and the value is a string
 * @param  {String} term     String to search with
 * @param  {Object} options  Override the default (Fuse) options
 * @return {Promise}         Promise that will provide the search objects that matched
 *
 * @example
    tableSearch(['first', 'last'], [{ first: 'Bob' }, { last: 'Williams' }], 'Bob')
    // Promise returns `{ first: 'Bob' }`
//  */
export default (keys, objects, term, options) => {
  if (typeof term !== "string") return;
  return new Promise((resolve, reject) => {
    try {
      const searchWorker = new Worker();
      searchWorker.postMessage({
        term,
        objects,
        options: {
          ...defaultOptions,
          ...options,
          keys
        }
      });

      searchWorker.onerror = error => reject(error);
      searchWorker.onmessage = event => resolve(event.data);
    } catch (e) {
      reject(e);
    }
  });
};
