import { createModule } from "redux-mvc";

import formModule from "ui-kit/Form";
import mediaContentModalModule from "ui-kit/MediaContentModal";

import model from "./model";

import sagasModule from "./sagas";

const module = createModule(model);

module.setRootSaga(sagasModule);
module.plugModule(formModule);
module.plugModule(mediaContentModalModule);

export default module;
