import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Panel from "components/Global-2016/Panel";
import Popover from "./Popover";
import ActionButton from "components/Global/CRM/ActionButton";
import { Div } from "components/Base";

const ActionTag = ({
  hideModal,
  showModal,
  name,
  type = {},
  onDelete,
  readOnly,
  showChangeRecordTypeModal
}) => (
  <Panel>
    <div styleName="container">
      {type.name ? (
        <div
          styleName="type"
          style={{
            backgroundColor: type.background_color || "#ECECEC",
            color: type.text_color
          }}
        >
          {type.icon ? <i className="material-icons">{type.icon}</i> : ""}
        </div>
      ) : (
        <div styleName="type">
          <Div
            pill
            size={67}
            bg="neutral3"
            uppercase
            display="row.center.center"
            color="white"
            fs={7}
            fw={3}
          >
            {name ? name[0] : ""}
          </Div>
        </div>
      )}

      <div>
        <div styleName="heading">{name}</div>
        <div styleName="subHeading">{type.name}</div>
        {!readOnly && (
          <div styleName="buttonWrapper">
            <Popover
              showChangeRecordTypeModal={showChangeRecordTypeModal}
              deleteAccount={onDelete}
              hideModal={hideModal}
              name={name}
              showModal={showModal}
            >
              <ActionButton
                title={
                  <span className={css.buttonText}>
                    Actions&nbsp;
                    <i className="material-icons">more_horiz</i>
                  </span>
                }
              />
            </Popover>
          </div>
        )}
      </div>
    </div>
  </Panel>
);

ActionTag.propTypes = {
  hideModal: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  styles: PropTypes.object,
  type: PropTypes.object
};

export default CSSModules(ActionTag, css);
