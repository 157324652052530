import { createModule } from "redux-mvc";
import model from "./model";
import rootSaga from "./sagas";
import formModule from "ui-kit/Form";

const module = createModule(model);
module.plugModule(formModule);
module.setRootSaga(rootSaga);

export default module;
