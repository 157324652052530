import React from "react";
import { Div } from "components/Base";
import Header from "./Header";
import Body from "./Body";
import ActivityDetailSidebar from "Schedules/ActivityDetailsSidebar/View";

const Layout = () => (
  <Div>
    <Header />
    <Body />
    <ActivityDetailSidebar />
  </Div>
);

export default Layout;
