import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import { actions, getters } from "Modules/AddEditColumnModal/model";
import Reference from "components/Global/Table3/ModalColumn/ReferenceSettings";
import { noop } from "utils/General";
import css from "./style.scss";

const decorate = R.compose(
  connect(
    (state, props) => ({
      referenceSettings: getters.referenceSettings(state, props),
      orgId: getters.orgId(state, props),
      eventId: getters.eventId(state, props),
      fields: getters.fields(state, props)
    }),
    {
      setReferenceSettings: actions.setReferenceSettings
    }
  ),
  CSSModules(css)
);

const ReferenceSettings = ({
  referenceSettings,
  setReferenceSettings = noop,
  orgId,
  eventId,
  fields
}) => (
  <div styleName="dropdownOptionsWrapper">
    <Reference
      orgId={orgId}
      eventId={eventId}
      value={referenceSettings}
      lookupFields={fields.filter(
        f => f.type === "lookup" && !f.settings.multiple
      )}
      onChange={setReferenceSettings}
    />
  </div>
);
export default decorate(ReferenceSettings);
