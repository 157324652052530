import React from "react";
import { connect } from "react-redux";

import { Div, DarkTabs } from "components/Base";
import { getTabs } from "Modules/SubscribeModal/selectors";
import { actions } from "Modules/SubscribeModal";

const decorate = connect(
  state => ({
    tabs: getTabs(state)
  }),
  {
    setSelectedTabId: actions.setSelectedTabId
  }
);

const Tabs = ({ tabs, setSelectedTabId }) => (
  <Div bb={1} bc="neutral2" pt={3} px={5} mb={5}>
    <DarkTabs
      tabs={tabs.map(t => ({
        ...t,
        onClick: () => setSelectedTabId(t.id)
      }))}
    />
  </Div>
);

export default decorate(Tabs);
