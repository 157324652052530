import React from "react";

const getSenderName = ({ email, fname, lname, id }, currentUser) => {
  if (id === currentUser.id) {
    return "You";
  }

  if (!fname) {
    return email;
  }

  if (fname && lname) {
    return `${fname} ${lname}`;
  }

  return fname;
};

export default ({ user, type }, currentUser) => {
  if (type === "start-submission") {
    return (
      <span>
        <strong>{getSenderName(user, currentUser)}</strong> started filling out
        this form
      </span>
    );
  } else if (type === "start-review") {
    return (
      <span>
        <strong>{getSenderName(user, currentUser)}</strong> started reviewing
        this form
      </span>
    );
  } else if (type.includes("confirmation")) {
    return (
      <span>
        <strong>{getSenderName(user, currentUser)}</strong> sent a confirmation
        email
      </span>
    );
  } else if (type === "comment") {
    return (
      <span>
        <strong>{getSenderName(user, currentUser)}</strong>
      </span>
    );
  }

  return <span>No message exists</span>;
};
