import * as R from "ramda";
import { createHandlers } from "redux-mvc";
import { NAMESPACE, DEFAULT_FIELD_ORDER } from "./constants";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

const iniState = {
  loading: true,
  templates: [],
  accountTypes: [],
  contactTypes: [],
  contactFields: {
    field_groups: []
  },
  accountFields: {
    field_groups: []
  },
  allFields: [],
  fields: [],
  requiredIds: [],
  selectedFields: [],
  selectedOrder: DEFAULT_FIELD_ORDER,
  description: {},
  tempDescription: {
    id: "",
    text: ""
  },
  alias: {},
  tempAlias: {
    id: "",
    text: ""
  },
  headerSections: [],
  showSelectFieldsModal: false,
  error: null,
  registrationUrl: "",
  data: {
    registration_type: null,
    virtual_registration_template: null,
    virtual_registration_description: "",
    virtual_registration_image_url: null,
    virtual_registration_contact_record_type_id: null,
    virtual_registration_account_record_type_id: null,
    virtual_registration_source: "lennd",
    //
    virtual_registration_enable_sponsors: "",
    virtual_registration_sponsors_label: "",
    virtual_registration_sponsors_description: "",
    //
    virtual_registration_enable_speakers: "",
    virtual_registration_speakers_label: "",
    virtual_registration_speakers_description: "",
    //
    virtual_registration_enable_schedule: "",
    virtual_registration_schedule_label: "",
    virtual_registration_schedule_description: ""
  }
};

const model = createHandlers({
  iniState,
  reducers: {
    init: R.identity,
    save: R.identity,
    addField: R.identity,
    editField: R.identity,
    saveSelectFieldsModal: R.identity,
    openRegistrationPage: R.identity,
    copyValue: R.identity,
    setInitialData: (
      state,
      {
        payload: {
          templates,
          accountFields,
          contactFields,
          accountTypes,
          contactTypes,
          fields,
          sections,
          registrationUrl,
          ...details
        }
      }
    ) => {
      const sortedFields = R.compose(R.sortBy(R.prop("order")))(fields);

      const selectedFields = R.compose(
        R.map(f => ({
          id: f.field.id,
          name: f.field.name,
          type: f.field.type,
          required: f.is_required,
          alias: f.alias,
          description: f.description,
          order: f.order,
          moduleLabel:
            f.field.module_id === STANDARD_MODULES.accounts.id
              ? "Group Field"
              : "Attendee Field"
        }))
      )(sortedFields);

      const headerSections = R.compose(
        R.map(s => ({
          id: s.id,
          order: s.order,
          title: s.title,
          description: s.description,
          isHeader: true
        }))
      )(sections);

      return {
        templates: templates,
        fields: sortedFields,
        headerSections,
        allFields: [...contactFields.fields, ...accountFields.fields].map(
          f => ({
            ...f,
            moduleLabel:
              f.module_id === STANDARD_MODULES.accounts.id
                ? "Group Field"
                : "Attendee Field"
          })
        ),
        accountFields,
        contactFields,
        accountTypes,
        contactTypes,
        requiredIds: R.compose(
          R.map(R.prop("id")),
          R.filter(R.propEq("required", true))
        )(selectedFields),
        selectedOrder: selectedFields.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.id]: curr.order
          }),
          {}
        ),
        description: selectedFields.reduce((acc, curr) => {
          if (R.isNil(curr.description)) {
            return acc;
          }
          return {
            ...acc,
            [curr.id]: curr.description
          };
        }, {}),
        alias: selectedFields.reduce((acc, curr) => {
          if (R.isNil(curr.alias)) {
            return acc;
          }
          return {
            ...acc,
            [curr.id]: curr.alias
          };
        }, {}),
        selectedFields,
        registrationUrl,
        data: {
          ...details,
          registration_type: details.registration_type || "rsvp",
          virtual_registration_template:
            details.virtual_registration_template || templates[0].id,
          virtual_registration_source:
            details.virtual_registration_source || "lennd"
        }
      };
    },
    updateField: (state, { payload: { field, value } }) => ({
      data: R.assoc(field, value, state.data)
    }),
    showCreatedField: (state, { payload: { field } }) => ({
      selectedFields: [...state.selectedFields, field],
      selectedOrder: {
        ...state.selectedOrder,
        [field.id]:
          Math.max(
            Object.keys(state.selectedOrder).map(k => state.selectedOrder[k])
          ) + 1
      }
    }),
    updateSelectedFields: (state, { payload: selectedFieldIds }) => ({
      selectedFields: R.map(
        id => R.find(R.propEq("id", id), state.allFields),
        R.uniq(selectedFieldIds)
      )
    }),
    removeRow: (state, { payload: { id } }) => {
      const selectedFields = R.filter(
        field => field.id !== id,
        state.selectedFields
      );
      return {
        selectedFields,
        requiredIds: R.filter(fieldId => fieldId !== id, state.requiredIds),
        selectedOrder: selectedFields.reduce((acc, field, index) => {
          return {
            ...acc,
            [index]: field.id
          };
        }, {})
      };
    },
    toggleRequiredField: (state, { payload: { id, required } }) => ({
      requiredIds: required
        ? R.filter(fieldId => fieldId !== id, state.requiredIds)
        : [...state.requiredIds, id]
    }),
    reorderFields: (state, { payload: orderedFields }) => {
      let counter = 0;
      const { fieldsOrder, sectionList } = orderedFields.reduce(
        (acc, curr, idx, src) => {
          const isPreviousSection = R.propOr(false, "isHeader", src[idx - 1]);

          if (isPreviousSection) {
            counter = counter + 1;
          }
          if (curr.isHeader) {
            return {
              ...acc,
              sectionList: [
                ...acc.sectionList,
                {
                  ...curr,
                  order: idx - counter
                }
              ]
            };
          }
          return {
            ...acc,
            fieldsOrder: {
              ...acc.fieldsOrder,
              [curr.id]: idx - counter
            }
          };
        },
        { fieldsOrder: {}, sectionList: [] }
      );

      return {
        selectedOrder: fieldsOrder,
        headerSections: sectionList
      };
    },
    updateAlias: (state, { payload: { id, description } }) => ({
      alias: R.assoc(id, description, state.alias),
      tempAlias: {
        id: "",
        text: ""
      }
    }),
    updateDescription: (state, { payload: { id, description } }) => ({
      description: R.assoc(id, description, state.description),
      tempDescription: {
        id: "",
        text: ""
      }
    }),
    clearTempDescriptions: () => ({
      tempDescription: {
        id: "",
        text: ""
      }
    }),
    addHeaderSection: (state, { payload: { order } }) => ({
      headerSections: [
        ...state.headerSections,
        {
          id: new Date().getTime(),
          order,
          title: "",
          description: "",
          isHeader: true
        }
      ]
    }),
    removeHeaderSection: (state, { payload: { id } }) => ({
      headerSections: R.filter(
        R.compose(
          R.not,
          R.propEq("id", id)
        ),
        state.headerSections
      )
    }),
    updateDescriptionHeaderSection: (
      state,
      { payload: { id, description } }
    ) => {
      const headerSectionIndex = R.findIndex(
        R.propEq("id", id),
        state.headerSections
      );
      const headerSection = state.headerSections[headerSectionIndex];
      return {
        headerSections: R.update(
          headerSectionIndex,
          { ...headerSection, description },
          state.headerSections
        ),
        tempDescription: {
          id: "",
          text: ""
        }
      };
    },
    updateTitleHeaderSection: (state, { payload: { id, title } }) => {
      const headerSectionIndex = R.findIndex(
        R.propEq("id", id),
        state.headerSections
      );
      const headerSection = state.headerSections[headerSectionIndex];
      return {
        headerSections: R.update(
          headerSectionIndex,
          { ...headerSection, title },
          state.headerSections
        )
      };
    },
    removeAlias: (state, { payload: { id } }) => ({
      alias: state.activeIndex === 1 ? R.dissoc(id, state.alias) : state.alias
    })
  },
  namespace: NAMESPACE
});

const { actions, getters } = model;

export { actions, getters };

export default model;
