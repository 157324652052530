import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";

import { noop } from "utils/General";

import { actions } from "../index";

import CSSModules from "react-css-modules";
import css from "./styles.scss";
import {
  MediumFilledButton,
  Text1,
  Div,
  CloseIcon,
  AddIcon,
  Text2
} from "components/Base";
import MenuPopover from "ui-kit/MenuPopover";

import { getFieldFilters, getSortBy } from "../selectors";
import SortPicker from "components/Global/Module/Sidebar/Popovers/Sort";

const decorate = R.compose(
  connect(
    state => ({
      fields: getFieldFilters(state),
      sortBy: getSortBy(state)
    }),
    {
      setFilters: actions.setSelectedSortBy,
      removeFilter: actions.removeSelectedSortBy
    }
  ),
  CSSModules(css)
);

const Sorter = ({
  id = "",
  name = "",
  direction = "",
  onRemove = noop,
  style = {}
}) => (
  <Div
    display="row.space-between.center"
    width={1}
    fs={1}
    mb={1}
    bra={1}
    p={2}
    style={{
      backgroundColor: "#eee"
    }}
  >
    <Text1 color="#222" flex={1} truncate style={style}>
      {name}
    </Text1>
    <Div display="row.flex-start.center">
      <Text1 color="#222" bold>
        {direction}
      </Text1>
      <CloseIcon
        size={15}
        ml={1}
        style={{
          flexShrink: 0
        }}
        color={{
          default: "gray5",
          hover: "gray7"
        }}
        onClick={e => {
          e.stopPropagation();
          onRemove(id);
        }}
      />
    </Div>
  </Div>
);

const SortButton = ({
  fields,
  setFilters,
  removeFilter,
  sortBy,
  ...styleProps
}) => {
  return (
    <MenuPopover
      Label={
        <MediumFilledButton mr={2} styleName="actionButton" {...styleProps}>
          <span className="material-icons-outlined">swap_vert</span>
          Sort
          {sortBy && sortBy.length ? ` (${sortBy && sortBy.length})` : null}
        </MediumFilledButton>
      }
    >
      {({ closePopover }) => (
        <Div
          display="column.flex-start.flex-start"
          style={{
            width: "200px",
            padding: "10px"
          }}
        >
          <Div display="column.flex-start.flex-start">
            <SortPicker sortBy={sortBy} onChange={setFilters} fields={fields}>
              <Div
                display="row"
                onClick={noop}
                mb={sortBy && sortBy.length ? 2 : 0}
              >
                <Div fs={2} fw={3} color="primary7">
                  Add Sort
                </Div>
                <AddIcon color="primary7" ml={1} />
              </Div>
            </SortPicker>
          </Div>

          {sortBy && sortBy.length ? (
            <Sorter {...R.head(sortBy)} m={1} onRemove={removeFilter} />
          ) : null}
          {R.map(
            sort => (
              <Div display="row.flex-start.center" ml={1}>
                <Text2 color="#222" mr={2} ml={1}>
                  then
                </Text2>
                <Sorter
                  {...sort}
                  onRemove={removeFilter}
                  key={sort.fieldId}
                  flex={1}
                  style={{
                    maxWidth: "85px"
                  }}
                />
              </Div>
            ),
            R.tail(sortBy)
          )}
        </Div>
      )}
    </MenuPopover>
  );
};

export default decorate(SortButton);
