import React from "react";

import { noop } from "utils/General";

import FiltersOnTag from "ui-kit/FiltersOnTag";

import { Div, CancelIcon } from "components/Base";

const ButtonContainer = ({ children, hover, style = {}, ...styleProps }) => (
  <Div
    bg={
      hover
        ? {
            default: "neutral0",
            hover: "neutral1"
          }
        : "neutral0"
    }
    display="row.flex-start.center"
    p={2}
    bra={1}
    style={{ cursor: "pointer", ...style }}
    {...styleProps}
  >
    {children}
  </Div>
);

const ButtonFilter = ({
  children,
  filtersOn,
  onClear = noop,
  hover = false,
  ...styleProps
}) => (
  <ButtonContainer hover={hover} {...styleProps}>
    {children}
    {onClear === noop ? null : (
      <>
        <FiltersOnTag on={filtersOn} />
        <CancelIcon
          onClick={onClear}
          style={{ visibility: filtersOn ? "visible" : "hidden" }}
        />
      </>
    )}
  </ButtonContainer>
);

export { ButtonFilter, ButtonContainer };
