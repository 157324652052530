import PropTypes from "prop-types";
import React from "react";
import Switch from "react-toggle-switch";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import { Div } from "components/Base";

const Group = ({ permission, on, enabled, onClick }) => (
  <Div display="column" py={1} bb={1} bc="gray2" className={css.container}>
    <Div display="row.space-between.center">
      <label className={on ? css.label : css.labelOff}>{permission.name}</label>
      <span className={css.value}>
        <Switch
          className="permission-switch"
          key={`${permission.slug}_${enabled}`}
          value={permission.slug}
          on={on}
          enabled={enabled}
          onClick={onClick}
        />
      </span>
    </Div>
  </Div>
);

Group.propTypes = {
  permission: PropTypes.object.isRequired,
  on: PropTypes.bool,
  enabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

export default CSSModules(Group, css);
