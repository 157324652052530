import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { getters, actions } from "../index";
import {
  getCountOfToggledRows,
  getFieldsForEditModal,
  getToggledRowsWithName,
  getSearchbarPlaceholder,
  getToggledRows,
  getHasViewChanged,
  getIsViewingFilteredPeopleType,
  getIsApprovalsEnabled
} from "../selectors";

import * as EMAIL_TEMPLATES from "utils/Emails/default-emails";
import { EMAIL_FIELD } from "SendEmailModal/utils/send-to-option-types";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";
import { withProps, addS } from "utils/General";
import DeleteConfirmation from "ui-kit/DeleteConfirmation";

import {
  TABLE_INSTANCE_ID,
  // VIEWPICKER_INSTANCE_ID,
  ROW_ACTIONS,
  MODES
} from "../constants";
import { actions as TableActions } from "ui-kit/Table/model";

import CSSModules from "react-css-modules";
import css from "ui-kit/ViewPicker/View/styles.scss";
import Tooltip from "components/Global/Tooltip";
import { noop } from "utils/General";

import {
  Div,
  FontIcon,
  UploadIcon,
  DownloadIcon,
  EditIcon,
  MediumFilledButton,
  BigFilledButton,
  DownFilledIcon,
  PopMenu,
  PopMenuCard,
  MenuItem,
  AddIcon,
  RightIcon
} from "components/Base";

import { VARIANTS } from "ui-kit/Theme/constants";
import UISearchBar from "ui-kit/SearchBar/View";
import CanUserDo from "components/Global/Security/CanUserDo";
// import SaveView from "ui-kit/ViewPicker/View/SaveView";
import SelectiveApproversLabel from "SelectiveApproversLabel/View";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

const decorate = R.compose(
  withRouter,
  connect(
    (state, props) => ({
      recordType: getters.recordType(state),
      moduleId: getters.moduleId(state),
      countOfToggledRows: getCountOfToggledRows(state),
      toggledRows: getToggledRows(state),
      toggledRowsWithName: getToggledRowsWithName(state),
      fields: getFieldsForEditModal(state, props),
      isViewingFilteredPeopleType: getIsViewingFilteredPeopleType(state, props),
      references: getters.references(state),
      placeholder: getSearchbarPlaceholder(state),
      hasViewChanged: getHasViewChanged(state),
      isApprovalsEnabled: getIsApprovalsEnabled(state),
      collapsed: getters.collapsed(state),
      showAddRecordButton: getters.showAddRecordButton(state),
      showDataFeedButton: getters.showDataFeedButton(state),
      showImportButton: getters.showImportButton(state),
      mode: getters.mode(state),
      canShowDeduplicate: selectFeatureFlag(
        flags.CAN_VIEW_RECORDS_DEDUPLICATION.NAME
      )(state)
    }),
    {
      executeAction: TableActions.executeAction,
      onExport: actions.exportData,
      showSubscribeModal: actions.showSubscribeModal,
      showAddAttendeeModal: actions.showAddAttendeeModal,
      showImportModal: actions.showImportModal,
      onApprove: actions.approve,
      onDeny: actions.deny,
      clearSelectedRows: () =>
        TableActions.clearSelectedRows(null, {
          meta: {
            instanceId: TABLE_INSTANCE_ID
          }
        }),
      clickMergeRecords: actions.clickMergeRecords
    }
  )
);

const Button = ({ LeftIcon, RightIcon, children, onClick }) => (
  <MediumFilledButton
    className={css.button1Light}
    color="#222"
    hover={false}
    onClick={onClick}
    mr={2}
    LeftIcon={LeftIcon}
    RightIcon={RightIcon}
  >
    {children}
  </MediumFilledButton>
);

const SendEmailPopoverButton = ({
  moduleId,
  handlers,
  toggledRows,
  countOfToggledRows,
  enableMore = false,
  enableSummary = false,
  enableSMS = false
}) => (
  <PopMenu
    Label={({ onClick }) => (
      <Button
        RightIcon={withProps({
          color: "#999"
        })(DownFilledIcon)}
        onClick={onClick}
      >
        Send
      </Button>
    )}
  >
    {({ closeMenu }) => (
      <PopMenuCard position="bottom" align="left">
        <MenuItem
          onClick={() => {
            handlers.showSendEmailModal(
              toggledRows,
              {
                selectedOptions: [EMAIL_FIELD]
              },
              moduleId
            );
            closeMenu();
          }}
        >
          Send Email{addS(countOfToggledRows)}
        </MenuItem>

        {enableSMS ? (
          <MenuItem
            onClick={() => {
              handlers.showSendEmailModal(
                toggledRows,
                {
                  mode: "sms"
                },
                moduleId
              );
              closeMenu();
            }}
          >
            Send SMS
          </MenuItem>
        ) : null}

        {enableSummary ? (
          <PopMenu
            Label={({ onClick }) => (
              <MenuItem RightIcon={RightIcon} onClick={onClick}>
                Send Item Summary
              </MenuItem>
            )}
          >
            {() => (
              <PopMenuCard position="right" align="top" zIndex={20}>
                <Div width={200} bg="white" bra={1} shadow={1}>
                  <MenuItem
                    onClick={() => {
                      closeMenu();
                      handlers.showSendEmailModal(
                        toggledRows,
                        EMAIL_TEMPLATES.ITEM_SUMMARY,
                        moduleId
                      );
                    }}
                  >
                    All Items (Everything)
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      closeMenu();
                      handlers.showSendEmailModal(
                        toggledRows,
                        EMAIL_TEMPLATES.CREDENTIALS_SUMMARY,
                        moduleId
                      );
                    }}
                  >
                    All Passes
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      closeMenu();
                      handlers.showSendEmailModal(
                        toggledRows,
                        EMAIL_TEMPLATES.CATERING_SUMMARY,
                        moduleId
                      );
                    }}
                  >
                    All Meals
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      closeMenu();
                      handlers.showSendEmailModal(
                        toggledRows,
                        EMAIL_TEMPLATES.CREDENTIALS_AND_CATERING_SUMMARY,
                        moduleId
                      );
                    }}
                  >
                    All Passes and Meals
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      closeMenu();
                      handlers.showSendEmailModal(
                        toggledRows,
                        EMAIL_TEMPLATES.INVENTORY_SUMMARY,
                        moduleId
                      );
                    }}
                  >
                    All Assets &amp; Equipment
                  </MenuItem>
                </Div>
              </PopMenuCard>
            )}
          </PopMenu>
        ) : null}

        {enableMore && (
          <>
            <Div width={1} style={{ height: "1px" }} bg="gray3" />
            <MenuItem
              onClick={() => {
                handlers.showSendEmailModal(
                  toggledRows,
                  EMAIL_TEMPLATES.PORTAL_LOGIN,
                  moduleId
                );
                closeMenu();
              }}
            >
              Send Portal Login Link
            </MenuItem>
            <MenuItem
              onClick={() => {
                handlers.showSendEmailModal(
                  toggledRows,
                  EMAIL_TEMPLATES.ASSIGNMENT_MANAGER,
                  moduleId
                );
                closeMenu();
              }}
            >
              Send Assignment Manager Link
            </MenuItem>
          </>
        )}
      </PopMenuCard>
    )}
  </PopMenu>
);

// const MergeRecordsOption = ({
//   countOfToggledRows = 0,
//   closeMenu = noop,
//   clickMergeRecords = noop,
//   type = "account"
// }) => (
//   <MenuItem
//     onClick={() => {
//       clickMergeRecords({ countOfToggledRows, closeMenu, type });
//     }}
//   >
//     Merge Records
//   </MenuItem>
// );

const AccountToolbar = ({
  countOfToggledRows = 0,
  toggledRows = null,
  toggledRowsWithName = null,
  clearSelectedRows = noop,
  fields = [],
  references = [],
  handlers = null
  // clickMergeRecords = noop,
  // canShowDeduplicate = false
}) => (
  <Div display="row.flex-start.center">
    <Div fw={4} mr={4} fs={4} color="#222">
      {countOfToggledRows} Selected
    </Div>

    <CanUserDo action={`${STANDARD_MODULES.accounts.id}_update`}>
      <Button
        LeftIcon={withProps({
          color: "#999"
        })(EditIcon)}
        onClick={() =>
          handlers.showBulkEditModal({ toggledRows, fields, references })
        }
      >
        Edit
      </Button>
      <Button
        LeftIcon={withProps({
          children: "show_chart",
          color: "#999",
          fs: 4
        })(FontIcon)}
        onClick={() => handlers.showRunReportModal(toggledRows)}
      >
        Run Report
      </Button>
      <SendEmailPopoverButton
        {...{
          handlers,
          toggledRows,
          countOfToggledRows,
          enableSummary: true,
          enableMore: true,
          enableSMS: true
        }}
      />
      <PopMenu
        Label={({ onClick }) => (
          <Button
            onClick={onClick}
            RightIcon={withProps({
              color: "#999"
            })(DownFilledIcon)}
          >
            Assign
          </Button>
        )}
      >
        {({ closeMenu }) => (
          <PopMenuCard position="bottom" align="left">
            <MenuItem
              onClick={() => {
                closeMenu();
                handlers.showAssignFormsModal(toggledRows);
              }}
            >
              Forms
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                handlers.showAssignDocumentRequestsModal(toggledRows);
              }}
            >
              File Requests
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                handlers.showAssignOwnersModal(toggledRows);
              }}
            >
              Owners
            </MenuItem>
          </PopMenuCard>
        )}
      </PopMenu>
      <PopMenu
        Label={({ onClick }) => (
          <Button
            onClick={onClick}
            RightIcon={withProps({
              color: "#999"
            })(DownFilledIcon)}
          >
            More
          </Button>
        )}
      >
        {({ closeMenu }) => (
          <PopMenuCard position="bottom" align="left">
            <MenuItem
              onClick={() => {
                closeMenu();
                handlers.showChangeRecordTypesModal(toggledRows);
              }}
            >
              Change Type{addS(countOfToggledRows)}
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                handlers.showGenerateDocumentsModal(toggledRowsWithName);
              }}
            >
              Generate Document{addS(countOfToggledRows)}
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                handlers.showUploadFilesModal(toggledRows);
              }}
            >
              Upload Files
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                handlers.downloadFiles({ toggledRows, fields });
              }}
            >
              Download Files
            </MenuItem>
            {/* {canShowDeduplicate &&
              React.createElement(MergeRecordsOption, {
                closeMenu,
                countOfToggledRows,
                clickMergeRecords,
                type: "account"
              })} */}
          </PopMenuCard>
        )}
      </PopMenu>
      <CanUserDo action={`${STANDARD_MODULES.accounts.id}_delete`}>
        <DeleteConfirmation
          width={200}
          onConfirm={() => {
            handlers.showDeleteRecordsModal(toggledRows);
          }}
          text={`Are you sure you want to delete ${toggledRows.length} record${
            toggledRows.length !== 1 ? "s" : ""
          }?`}
          paragraph="This cannot be undone"
          confirmLabel="Delete"
        >
          <Button>Delete</Button>
        </DeleteConfirmation>
      </CanUserDo>
    </CanUserDo>
    <Div ml={2} fw={4} color="#222" onClick={clearSelectedRows}>
      Clear selected
    </Div>
  </Div>
);

const ContactToolbar = ({
  countOfToggledRows = 0,
  toggledRows = null,
  clearSelectedRows = noop,
  fields = [],
  references = [],
  handlers = null,
  executeAction = noop
  // clickMergeRecords = noop,
  // canShowDeduplicate = false
}) => (
  <Div display="row.flex-start.center">
    <Div fw={4} mr={4} fs={4} color="#222">
      {countOfToggledRows} Selected
    </Div>

    <CanUserDo action={`${STANDARD_MODULES.contacts.id}_update`}>
      <Button
        LeftIcon={withProps({
          color: "#999"
        })(EditIcon)}
        onClick={() =>
          handlers.showBulkEditModal({ toggledRows, fields, references })
        }
      >
        Edit
      </Button>
      <Button
        LeftIcon={withProps({
          children: "show_chart",
          color: "#999",
          fs: 4
        })(FontIcon)}
        onClick={() => handlers.showRunReportModal(toggledRows)}
      >
        Run Report
      </Button>
      <SendEmailPopoverButton
        {...{
          handlers,
          toggledRows,
          countOfToggledRows,
          enableSummary: true,
          enableSMS: true
        }}
      />

      <PopMenu
        Label={({ onClick }) => (
          <Button
            onClick={onClick}
            RightIcon={withProps({
              color: "#999"
            })(DownFilledIcon)}
          >
            Assign
          </Button>
        )}
      >
        {({ closeMenu }) => (
          <PopMenuCard position="bottom" align="left">
            <MenuItem
              onClick={() => {
                closeMenu();
                handlers.showAssignFormsModal(toggledRows);
              }}
            >
              Forms
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                handlers.showAssignDocumentRequestsModal(toggledRows);
              }}
            >
              File Requests
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                handlers.showAssignOwnersModal(toggledRows);
              }}
            >
              Owners
            </MenuItem>
          </PopMenuCard>
        )}
      </PopMenu>

      <PopMenu
        Label={({ onClick }) => (
          <Button
            onClick={onClick}
            RightIcon={withProps({
              color: "#999"
            })(DownFilledIcon)}
          >
            More
          </Button>
        )}
      >
        {({ closeMenu }) => (
          <PopMenuCard position="bottom" align="left">
            <MenuItem
              onClick={() => {
                closeMenu();
                handlers.showChangeRecordTypesModal(toggledRows);
              }}
            >
              Change Type{addS(countOfToggledRows)}
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                handlers.showUploadFilesModal(toggledRows);
              }}
            >
              Upload Files
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                handlers.downloadFiles({ toggledRows, fields });
              }}
            >
              Download Files
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                executeAction({
                  actionId: ROW_ACTIONS.GIVE_CONTACT_LOGIN_ACCESS
                });
              }}
            >
              Give Portal Access
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                executeAction({
                  actionId: ROW_ACTIONS.SHARE_CONTACT_LOGIN_DETAILS
                });
              }}
            >
              Share login details
            </MenuItem>
            {/* {canShowDeduplicate &&
              React.createElement(MergeRecordsOption, {
                closeMenu,
                countOfToggledRows,
                clickMergeRecords,
                type: "contact"
              })} */}
          </PopMenuCard>
        )}
      </PopMenu>

      <CanUserDo action={`${STANDARD_MODULES.contacts.id}_delete`}>
        <DeleteConfirmation
          width={200}
          onConfirm={() => {
            handlers.showDeleteRecordsModal(toggledRows);
          }}
          text={`Are you sure you want to delete ${toggledRows.length} record${
            toggledRows.length !== 1 ? "s" : ""
          }?`}
          paragraph="This cannot be undone"
          confirmLabel="Delete"
        >
          <Button>Delete</Button>
        </DeleteConfirmation>
      </CanUserDo>
    </CanUserDo>
    <Div ml={2} fw={4} color="#222" onClick={clearSelectedRows}>
      Clear selected
    </Div>
  </Div>
);

const HealthPassToolbar = ({
  countOfToggledRows,
  // toggledRows,
  clearSelectedRows
  // fields,
  // references,
  // handlers,
  // executeAction
}) => (
  <Div display="row.flex-start.center">
    <Div fw={4} mr={4} fs={4} color="#222">
      {countOfToggledRows} Selected
    </Div>

    {/*
    <CanUserDo action={`${STANDARD_MODULES.contacts.id}_update`}>
      <Button
        LeftIcon={withProps({
          color: "#999"
        })(EditIcon)}
        onClick={() =>
          handlers.showBulkEditModal({ toggledRows, fields, references })
        }
      >
        Edit
      </Button>
      <Button
        LeftIcon={withProps({
          children: "show_chart",
          color: "#999",
          fs: 4
        })(FontIcon)}
        onClick={() => handlers.showRunReportModal(toggledRows)}
      >
        Run Report
      </Button>
      <SendEmailPopoverButton
        {...{
          handlers,
          toggledRows,
          countOfToggledRows,
          enableSummary: true
        }}
      />

      <PopMenu
        Label={({ onClick }) => (
          <Button
            onClick={onClick}
            RightIcon={withProps({
              color: "#999"
            })(DownFilledIcon)}
          >
            Assign
          </Button>
        )}
      >
        {({ closeMenu }) => (
          <PopMenuCard position="bottom" align="left">
            <MenuItem
              onClick={() => {
                closeMenu();
                handlers.showAssignFormsModal(toggledRows);
              }}
            >
              Forms
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                handlers.showAssignDocumentRequestsModal(toggledRows);
              }}
            >
              File Requests
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                handlers.showAssignOwnersModal(toggledRows);
              }}
            >
              Owners
            </MenuItem>
          </PopMenuCard>
        )}
      </PopMenu>

      <PopMenu
        Label={({ onClick }) => (
          <Button
            onClick={onClick}
            RightIcon={withProps({
              color: "#999"
            })(DownFilledIcon)}
          >
            More
          </Button>
        )}
      >
        {({ closeMenu }) => (
          <PopMenuCard position="bottom" align="left">
            <MenuItem
              onClick={() => {
                closeMenu();
                handlers.showChangeRecordTypesModal(toggledRows);
              }}
            >
              Change Type{addS(countOfToggledRows)}
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                handlers.showUploadFilesModal(toggledRows);
              }}
            >
              Upload Files
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                executeAction({
                  actionId: ROW_ACTIONS.GIVE_CONTACT_LOGIN_ACCESS
                });
              }}
            >
              Give Portal Access
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                executeAction({
                  actionId: ROW_ACTIONS.SHARE_CONTACT_LOGIN_DETAILS
                });
              }}
            >
              Share login details
            </MenuItem>
          </PopMenuCard>
        )}
      </PopMenu>

      <CanUserDo action={`${STANDARD_MODULES.contacts.id}_delete`}>
        <Button
          onClick={() => {
            handlers.showDeleteRecordsModal(toggledRows);
          }}
        >
          Delete
        </Button>
      </CanUserDo>
    </CanUserDo>
    */}
    <Div ml={2} fw={4} color="#222" onClick={clearSelectedRows}>
      Clear selected
    </Div>
  </Div>
);

const ModuleToolbar = ({
  countOfToggledRows,
  toggledRows,
  clearSelectedRows,
  fields,
  references,
  handlers,
  onApprove,
  onDeny,
  isApprovalsEnabled
}) => (
  <Div display="row.flex-start.center">
    <Div fw={4} mr={4} fs={4} color="#222">
      {countOfToggledRows} Selected
    </Div>

    {isApprovalsEnabled ? (
      <SelectiveApproversLabel
        {...{
          type: "record",
          recordIds: toggledRows,
          onApprove,
          onDeny,
          Button: Button
        }}
      />
    ) : null}

    <CanUserDo action={`${STANDARD_MODULES.contacts.id}_update`}>
      <Button
        LeftIcon={withProps({
          color: "#999"
        })(EditIcon)}
        onClick={() =>
          handlers.showBulkEditModal({ toggledRows, fields, references })
        }
      >
        Edit
      </Button>
      <Button
        LeftIcon={withProps({
          children: "show_chart",
          color: "#999",
          fs: 4
        })(FontIcon)}
        onClick={() => handlers.showRunReportModal(toggledRows)}
      >
        Run Report
      </Button>
      <SendEmailPopoverButton
        {...{
          handlers,
          toggledRows,
          countOfToggledRows,
          enableSummary: false,
          enableSMS: false
        }}
      />

      <PopMenu
        Label={({ onClick }) => (
          <Button
            onClick={onClick}
            RightIcon={withProps({
              color: "#999"
            })(DownFilledIcon)}
          >
            More
          </Button>
        )}
      >
        {({ closeMenu }) => (
          <PopMenuCard position="bottom" align="left">
            <MenuItem
              onClick={() => {
                closeMenu();
                handlers.showChangeRecordTypesModal(toggledRows);
              }}
            >
              Change Type{addS(countOfToggledRows)}
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                handlers.showAssignOwnersModal(toggledRows);
              }}
            >
              Assign Owners
            </MenuItem>
          </PopMenuCard>
        )}
      </PopMenu>

      <CanUserDo action={`${STANDARD_MODULES.contacts.id}_delete`}>
        <DeleteConfirmation
          width={200}
          onConfirm={() => {
            handlers.showDeleteRecordsModal(toggledRows);
          }}
          text={`Are you sure you want to delete ${toggledRows.length} record${
            toggledRows.length !== 1 ? "s" : ""
          }?`}
          paragraph="This cannot be undone"
          confirmLabel="Delete"
        >
          <Button>Delete</Button>
        </DeleteConfirmation>
      </CanUserDo>
    </CanUserDo>
    <Div ml={2} fw={4} color="#222" onClick={clearSelectedRows}>
      Clear selected
    </Div>
  </Div>
);

const PeopleReportToolbar = ({
  // moduleId,
  countOfToggledRows,
  toggledRows,
  clearSelectedRows,
  executeAction,
  handlers
}) => (
  <Div display="row.flex-start.center">
    <Div fw={4} mr={4} fs={4} color="#222">
      {countOfToggledRows} Selected
    </Div>

    <CanUserDo action={`${STANDARD_MODULES.contacts.id}_update`}>
      <Button
        onClick={() => {
          executeAction({ actionId: ROW_ACTIONS.GIVE_LOGIN_ACCESS });
        }}
      >
        Give Login Access
      </Button>

      <Button
        onClick={() => {
          executeAction({ actionId: ROW_ACTIONS.SHARE_LOGIN_DETAILS });
        }}
      >
        Share login details
      </Button>

      <SendEmailPopoverButton
        {...{
          moduleId: STANDARD_MODULES.contacts.id,
          handlers,
          toggledRows,
          countOfToggledRows,
          enableSummary: true,
          enableSMS: false
        }}
      />

      <PopMenu
        Label={({ onClick }) => (
          <Button
            onClick={onClick}
            RightIcon={withProps({
              color: "#999"
            })(DownFilledIcon)}
          >
            More
          </Button>
        )}
      >
        {({ closeMenu }) => (
          <PopMenuCard position="bottom" align="left">
            <MenuItem
              onClick={() => {
                closeMenu();
                handlers.showChangeRecordTypesModal(
                  toggledRows,
                  STANDARD_MODULES.contacts.id
                );
              }}
            >
              Change Type{addS(countOfToggledRows)}
            </MenuItem>
            <MenuItem
              onClick={() => {
                closeMenu();
                handlers.showRunReportModal(
                  toggledRows,
                  STANDARD_MODULES.contacts.id
                );
              }}
            >
              Run Report
            </MenuItem>
          </PopMenuCard>
        )}
      </PopMenu>

      <CanUserDo action={`${STANDARD_MODULES.contacts.id}_delete`}>
        <DeleteConfirmation
          width={200}
          onConfirm={() => {
            handlers.showDeleteRecordsModal(
              toggledRows,
              STANDARD_MODULES.contacts.id
            );
          }}
          text={`Are you sure you want to delete ${toggledRows.length} record${
            toggledRows.length !== 1 ? "s" : ""
          }?`}
          paragraph="This cannot be undone"
          confirmLabel="Delete"
        >
          <Button>Delete</Button>
        </DeleteConfirmation>
      </CanUserDo>
    </CanUserDo>
    <Div ml={2} fw={4} color="#222" onClick={clearSelectedRows}>
      Clear selected
    </Div>
  </Div>
);

const AttendeeToolbar = ({
  countOfToggledRows,
  toggledRows,
  clearSelectedRows,
  fields,
  references,
  handlers
  // executeAction
}) => (
  <Div display="row.flex-start.center">
    <Div fw={4} mr={4} fs={4} color="#222">
      {countOfToggledRows} Selected
    </Div>

    <CanUserDo action={`${STANDARD_MODULES.contacts.id}_update`}>
      <Button
        LeftIcon={withProps({
          color: "#999"
        })(EditIcon)}
        onClick={() =>
          handlers.showBulkEditModal({ toggledRows, fields, references })
        }
      >
        Edit
      </Button>
      {/*
      <Button
        LeftIcon={withProps({
          children: "show_chart",
          color: "#999",
          fs: 4
        })(FontIcon)}
        onClick={() => handlers.showRunReportModal(toggledRows)}
      >
        Run Report
      </Button>
      */}
      <SendEmailPopoverButton
        {...{
          handlers,
          toggledRows,
          countOfToggledRows,
          enableSummary: false,
          enableSMS: true
        }}
      />

      {/*
      <CanUserDo action={`${STANDARD_MODULES.contacts.id}_delete`}>
        <Button
          onClick={() => {
            handlers.showDeleteRecordsModal(toggledRows);
          }}
        >
          Delete
        </Button>
      </CanUserDo>
      */}
    </CanUserDo>
    <Div ml={2} fw={4} color="#222" onClick={clearSelectedRows}>
      Clear selected
    </Div>
  </Div>
);

const Toolbar = ({
  onExport,
  handlers,
  recordType,
  moduleId,
  countOfToggledRows,
  toggledRows,
  toggledRowsWithName,
  clearSelectedRows,
  fields,
  references,
  executeAction,
  placeholder,
  // hasViewChanged,
  // collapsed,
  showSubscribeModal,
  isViewingFilteredPeopleType,
  onApprove,
  onDeny,
  isApprovalsEnabled,
  showAddRecordButton,
  showDataFeedButton,
  showImportButton,
  mode,
  showAddAttendeeModal,
  clickMergeRecords,
  canShowDeduplicate,
  showImportModal
}) => (
  <Div
    display="row.space-between.center"
    width={1}
    height={75}
    px={3}
    style={{ position: "relative", flexShrink: 0 }}
  >
    {!countOfToggledRows ? (
      <>
        <Div display="row.flex-start.center" flex={1}>
          <UISearchBar
            variant={VARIANTS.SURFACE}
            flex={1}
            mr={2}
            placeholder={placeholder}
            height={43}
            style={{
              maxWidth: 445
            }}
          />
        </Div>

        <Div display="row.flex-start.center">
          <CanUserDo any={[`${moduleId}_manage`, `${moduleId}_export`]}>
            {showDataFeedButton ? (
              <Tooltip tooltip="Subscribe to feed">
                <span>
                  <BigFilledButton
                    mr={2}
                    onClick={showSubscribeModal}
                    className={css.button1Light}
                  >
                    <FontIcon fs={4} color="#222">
                      rss_feed
                    </FontIcon>
                  </BigFilledButton>
                </span>
              </Tooltip>
            ) : null}

            <PopMenu
              Label={({ onClick }) => (
                <BigFilledButton
                  className={css.button1Light}
                  hover={false}
                  color="#222"
                  RightIcon={withProps({
                    color: "#999"
                  })(DownFilledIcon)}
                  onClick={onClick}
                >
                  Options
                </BigFilledButton>
              )}
            >
              {({ closeMenu }) => (
                <PopMenuCard position="bottom" align="right" zIndex={20}>
                  {showImportButton ? (
                    <CanUserDo
                      action={
                        moduleId === STANDARD_MODULES.accounts.id
                          ? `${moduleId}_accounts_add`
                          : `${moduleId}_create`
                      }
                    >
                      <MenuItem
                        LeftIcon={UploadIcon}
                        onClick={() => {
                          showImportModal();
                          closeMenu();
                        }}
                      >
                        Import .CSV
                      </MenuItem>
                    </CanUserDo>
                  ) : null}

                  <CanUserDo action={`${moduleId}_export`}>
                    <Div width={1} style={{ height: "1px" }} bg="gray3" />
                    <MenuItem
                      LeftIcon={DownloadIcon}
                      onClick={() => {
                        onExport("csv");
                        closeMenu();
                      }}
                    >
                      Export .CSV
                    </MenuItem>
                    <MenuItem
                      LeftIcon={DownloadIcon}
                      onClick={() => {
                        onExport("xlsx");
                        closeMenu();
                      }}
                    >
                      Export .XLSX
                    </MenuItem>
                  </CanUserDo>
                </PopMenuCard>
              )}
            </PopMenu>
          </CanUserDo>

          {showAddRecordButton ? (
            <CanUserDo
              action={
                moduleId === STANDARD_MODULES.accounts.id
                  ? `${moduleId}_accounts_add`
                  : `${moduleId}_create`
              }
            >
              <BigFilledButton
                bg="orange5"
                color="white"
                ml={2}
                onClick={() => handlers.showAddRecordModal({ recordType })}
                LeftIcon={AddIcon}
              >
                Add
              </BigFilledButton>
            </CanUserDo>
          ) : null}

          {[MODES.ATTENDEES].includes(mode) ? (
            <CanUserDo action={`${moduleId}_create`}>
              <BigFilledButton
                bg="orange5"
                color="white"
                ml={2}
                onClick={() => showAddAttendeeModal()}
                LeftIcon={AddIcon}
              >
                Add
              </BigFilledButton>
            </CanUserDo>
          ) : null}
        </Div>
      </>
    ) : isViewingFilteredPeopleType ? (
      <PeopleReportToolbar
        {...{
          moduleId,
          countOfToggledRows,
          toggledRows,
          clearSelectedRows,
          executeAction,
          handlers
        }}
      />
    ) : [MODES.ATTENDEES, MODES.EXHIBITORS, MODES.SPONSORS].includes(mode) ? (
      <AttendeeToolbar
        {...{
          countOfToggledRows,
          toggledRows,
          toggledRowsWithName,
          clearSelectedRows,
          fields,
          references,
          executeAction,
          handlers
        }}
      />
    ) : moduleId === STANDARD_MODULES.accounts.id ? (
      <AccountToolbar
        {...{
          countOfToggledRows,
          toggledRows,
          toggledRowsWithName,
          clearSelectedRows,
          fields,
          references,
          handlers,
          clickMergeRecords,
          canShowDeduplicate
        }}
      />
    ) : moduleId === STANDARD_MODULES.contacts.id ? (
      <ContactToolbar
        {...{
          countOfToggledRows,
          toggledRows,
          toggledRowsWithName,
          clearSelectedRows,
          fields,
          references,
          executeAction,
          handlers,
          clickMergeRecords,
          canShowDeduplicate
        }}
      />
    ) : moduleId === STANDARD_MODULES.healthPass.id ? (
      <HealthPassToolbar
        {...{
          countOfToggledRows,
          toggledRows,
          toggledRowsWithName,
          clearSelectedRows,
          fields,
          references,
          executeAction,
          handlers
        }}
      />
    ) : (
      <ModuleToolbar
        {...{
          countOfToggledRows,
          toggledRows,
          clearSelectedRows,
          fields,
          references,
          handlers,
          onApprove,
          onDeny,
          isApprovalsEnabled
        }}
      />
    )}
  </Div>
);

export default CSSModules(decorate(Toolbar), css);
