import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const SidebarSubItem = ({
  linkProps,
  active = false,
  iconLeft,
  children,
  ...props
}) => {
  if (linkProps) {
    return (
      <Link
        to={linkProps}
        styleName={active ? "subItemActive" : "subItem"}
        {...props}
      >
        {iconLeft}
        {children}
      </Link>
    );
  }
  return (
    <div styleName={active ? "subItemActive" : "subItem"} {...props}>
      {iconLeft}
      {children}
    </div>
  );
};

SidebarSubItem.propTypes = {
  active: PropTypes.bool,
  iconLeft: PropTypes.element,
  linkProps: PropTypes.shape({
    pathname: PropTypes.string,
    query: PropTypes.object
  })
};

export default CSSModules(SidebarSubItem, css);
