import { createHandlers } from "redux-mvc";
import { NAMESPACE } from "./constants";
import * as R from "ramda";

const iniState = {
  types: [],
  typesUpdated: [],
  loading: false,
  showModal: false,
  itemFilter: ""
};

const handlers = createHandlers({
  iniState,
  namespace: NAMESPACE,
  reducers: {
    getItemTypes: R.identity,
    setInitialData: (_, { payload: { types } }) => {
      const formattedTypes = R.map(type => {
        const items = R.compose(
          R.map(item => ({
            ...item,
            variants: R.map(
              variant => ({ ...variant, selected: false }),
              item.variants
            )
          })),
          R.reduce((acc, curr) => [...acc, ...curr], []),
          R.map(group => group.items)
        )(type.groups);
        return {
          ...type,
          selected: false,
          items
        };
      }, types);
      return {
        types: formattedTypes,
        typesUpdated: formattedTypes
      };
    },
    toggleVariant: (state, { payload: { typeId, itemId, variantId } }) => {
      const typeIndex = R.findIndex(R.propEq("id", typeId), state.types);
      const type = state.types[typeIndex];

      const itemIndex = R.findIndex(R.propEq("id", itemId), type.items);
      const item = type.items[itemIndex];

      const variantIndex = R.findIndex(
        R.propEq("id", variantId),
        item.variants
      );
      const variant = item.variants[variantIndex];
      return {
        types: [
          ...state.types.slice(0, typeIndex),
          {
            ...type,
            items: [
              ...type.items.slice(0, itemIndex),
              {
                ...item,
                variants: [
                  ...item.variants.slice(0, variantIndex),
                  {
                    ...variant,
                    selected: !variant.selected
                  },
                  ...item.variants.slice(variantIndex + 1)
                ]
              },
              ...type.items.slice(itemIndex + 1)
            ]
          },
          ...state.types.slice(typeIndex + 1)
        ]
      };
    },
    removeSeletedItem: (state, { payload: { typeId, itemId } }) => {
      const typeIndex = R.findIndex(R.propEq("id", typeId), state.types);
      const type = state.types[typeIndex];

      const itemIndex = R.findIndex(R.propEq("id", itemId), type.items);
      const item = type.items[itemIndex];
      return {
        types: [
          ...state.types.slice(0, typeIndex),
          {
            ...type,
            items: [
              ...type.items.slice(0, itemIndex),
              {
                ...item,
                variants: R.map(
                  variant => ({ ...variant, selected: false }),
                  item.variants
                )
              },
              ...type.items.slice(itemIndex + 1)
            ]
          },
          ...state.types.slice(typeIndex + 1)
        ]
      };
    },
    hideModal: (state, { payload: { update = false } }) => ({
      showModal: false,
      types: update ? state.types : state.typesUpdated,
      typesUpdated: update ? state.types : state.typesUpdated
    })
  }
});

export default handlers;
