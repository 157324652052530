import React from "react";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import ModuleMenu from "components/Event/Module/ActionsMenu/Module";
import DocumentsRequestsMenu from "components/Event/Module/ActionsMenu/DocumentRequests";
import OrdersMenu from "components/Event/Module/ActionsMenu/Orders";

export default function resolveActionsMenu(props) {
  switch (props.moduleId) {
    case STANDARD_MODULE_IDS.documentRequests.id:
      return <DocumentsRequestsMenu {...props.meta} />;
    case STANDARD_MODULE_IDS.orders.id:
      return <OrdersMenu {...props.meta} />;
    default:
      return <ModuleMenu {...props.meta} showSettings />;
  }
}
