import Helpers from "utils/Global/Helpers";
import getQuery from "redux/modules/modules/utils/get-query";

module.exports = {
  getOrdersForAccount: (credentials, { eventId, recordId, typeIds }) =>
    new Promise((success, error) => {
      const query = getQuery({
        eventId,
        typeIds
      });

      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/account/${recordId}/orders${query || ""}`,
        credentials,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */,

  getOrdersForContact: (credentials, { eventId, recordId, typeIds }) =>
    new Promise((success, error) => {
      const query = getQuery({
        eventId,
        typeIds
      });

      /* eslint-disable no-underscore-dangle */
      Helpers.request({
        method: "get",
        url: `${
          window.__LENND_API_BASE_URL__
        }/contacts/${recordId}/orders${query || ""}`,
        credentials,
        success,
        error
      });
    }) /* eslint-enable no-underscore-dangle */
};
