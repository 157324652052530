import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { actions, getters } from "Event/MyTasks/model";
import { Div, DownFilledIcon, Text0, Text1 } from "components/Base";
import CollapsiblePanel from "ui-kit/CollapsiblePanel";
import ScheduleBadge from "ui-kit/ScheduleBadge";
import StatusCircle from "ui-kit/StatusCircle";
import { TABS } from "Event/MyTasks/constants";

const decorate = connect(
  state => ({
    selectedTab: getters.selectedTab(state)
  }),
  {
    openSidebar: actions.openSidebar,
    toggleTask: actions.toggleTask
  }
);

const TaskContainer = ({
  title,
  items,
  isDue = false,
  count = 0,
  openSidebar,
  toggleTask,
  selectedTab
}) => (
  <Div py={2}>
    <CollapsiblePanel
      heading={
        <Div display="row.flex-start.center" ml={2}>
          <Div color="black" fs={5} fw={3}>
            {title}
          </Div>
          {selectedTab !== TABS.COMPLETE && isDue && count ? (
            <Div
              bra={2}
              px={2}
              bg="danger7"
              height={15}
              display="row.center.center"
              transition="fast"
              ml={2}
            >
              <Text0 color="white">{count}</Text0>
            </Div>
          ) : null}
        </Div>
      }
      collapsedDefault={false}
      bg="transparent"
      expandedBg="transparent"
      px={5}
      right={false}
      arrowIcon={DownFilledIcon}
    >
      <Div mt={2} px={3}>
        {R.map(
          item => (
            <Div
              px={2}
              py={3}
              key={item.id}
              display="row.space-between.center"
              bb={1}
              bc="neutral1"
              bg={{ default: "white", hover: "neutral0" }}
              transition="fast"
              onClick={openSidebar}
            >
              <Div display="row.flex-start.center">
                <StatusCircle
                  status={
                    item.is_complete ? "task_complete" : "task_not_complete"
                  }
                  size={26}
                  onClick={() =>
                    toggleTask({
                      recordId: item.id,
                      toggled: !item.is_complete
                    })
                  }
                />
                <Div ml={3} color="black" fw={3}>
                  {item.name}
                </Div>
              </Div>
              <Div display="row.flex-start.center">
                <Text1
                  color={
                    item.is_complete
                      ? "gray6"
                      : item.is_overdue
                      ? "orange9"
                      : item.is_due
                      ? "orange5"
                      : "gray6"
                  }
                >
                  {item.due_label}
                </Text1>
                <Div width={200} display="row.flex-end">
                  <ScheduleBadge color={item.color} text={item.schedule_name} />
                </Div>
              </Div>
            </Div>
          ),
          items
        )}
      </Div>
    </CollapsiblePanel>
  </Div>
);

export default decorate(TaskContainer);
