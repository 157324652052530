import * as R from "ramda";
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router";

import { getAllGroupsUrl, getGroupName } from "Items/Manage/selectors";

import { ButtonContainer } from "ui-kit/FilterControls/View";
import { Text2, Div, LeftArrowIcon } from "components/Base";

const decorate = R.compose(
  withRouter,
  connect((state, props) => ({
    backToAllGroupsUrl: getAllGroupsUrl(state, props),
    groupName: getGroupName(state, props)
  }))
);

const BackToAllGroups = ({ groupName, backToAllGroupsUrl = "" }) => (
  <ButtonContainer
    mt={2}
    display="column.flex-start.flex-start"
    pl={3}
    bg="transparent"
  >
    <Text2>Filtered to: {groupName}</Text2>
    <Link to={backToAllGroupsUrl}>
      <Div display="row.flex-start.center" pt={2}>
        <LeftArrowIcon color="black" mr={2} />
        <Text2 underline>Go back to view</Text2>
      </Div>
    </Link>
  </ButtonContainer>
);

export default decorate(BackToAllGroups);
