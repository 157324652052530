import React from "react";
import { connect } from "react-redux";
import { createContext } from "redux-mvc";
import * as R from "ramda";
import Layout from "./Layout";

import questions from "../index";
import { actions } from "../model";

import AddQuestionModal from "Items/AddQuestionModal/View";
import AddQuestionSetModal from "Items/AddQuestionSetModal/View";
import EditQuestionSetQuestionsModal from "Items/EditQuestionSetQuestionsModal/View";
import BulkQuestionItemsModal from "Items/BulkQuestionItemsModal/View";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import DeleteConfirmation from "components/Global/CRM/Modals/DeleteConfirmation";

import { hideModal, showModal } from "redux/modules/modal/actions";

const contextConfig = {
  module: questions,
  lifeCycle: {
    componentDidMount() {
      this.store.dispatch(actions.init());
    }
  },
  handlers: {
    showAddQuestionSetModal() {
      this.props.showModal({
        content: (
          <AddQuestionSetModal
            onDone={() => {
              this.store.dispatch(actions.refetchSets());
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showEditQuestionSetModal(questionSetId) {
      this.props.showModal({
        content: (
          <AddQuestionSetModal
            questionSetId={questionSetId}
            onDone={() => {
              this.store.dispatch(actions.refetchSets());
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showDeleteQuestionSetModal(questionSetId) {
      this.props.showModal({
        content: (
          <DeleteConfirmation
            hideModal={this.props.hideModal}
            heading="Delete question set?"
            message={
              <div>
                Are you sure you want to delete this question set?
                <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                  This cannot be undone.
                </div>
              </div>
            }
            onConfirm={() => {
              this.store.dispatch(actions.deleteQuestionSet(questionSetId));
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showAddQuestionModal() {
      this.props.showModal({
        content: (
          <AddQuestionModal
            onDone={() => {
              this.store.dispatch(actions.refetchSets());
              this.store.dispatch(actions.refetchQuestions());
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showEditQuestionModal(questionId, showQuestionSets) {
      this.props.showModal({
        content: (
          <AddQuestionModal
            questionId={questionId}
            showQuestionSets={showQuestionSets}
            onDone={() => {
              this.store.dispatch(actions.refetchSets());
              this.store.dispatch(actions.refetchQuestions());
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showDeleteQuestionModal(questionId) {
      this.props.showModal({
        content: (
          <DeleteConfirmation
            hideModal={this.props.hideModal}
            heading="Delete question?"
            message={
              <div>
                Are you sure you want to delete this question?
                <div style={{ fontWeight: "bold", padding: "10px 0" }}>
                  This cannot be undone.
                </div>
              </div>
            }
            onConfirm={() => {
              this.store.dispatch(actions.deleteQuestion(questionId));
            }}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showEditQuestionSetQuestionsModal(setId) {
      this.props.showModal({
        content: (
          <EditQuestionSetQuestionsModal
            setId={setId}
            hideModal={this.props.hideModal}
            onDone={() => this.store.dispatch(actions.refetchSets())}
          />
        ),
        wrapper: ModalWrapper
      });
    },
    showAssignQuestionToItemsModal({ questionIds = [], questionSetIds = [] }) {
      this.props.showModal({
        content: (
          <BulkQuestionItemsModal
            showModal={this.props.showModal}
            hideModal={this.props.hideModal}
            onDone={() => {
              this.store.dispatch(actions.refetchSets());
              this.store.dispatch(actions.refetchQuestions());
            }}
            questionIds={questionIds}
            questionSetIds={questionSetIds}
          />
        ),
        wrapper: ModalWrapper
      });
    }
  },
  options: {
    dispatchToGlobal: R.compose(
      R.not,
      R.test(new RegExp(R.join("|", questions.modules))),
      R.prop("type")
    ),
    observedDomains: ["user", "event", "modal", "organization"]
  }
};

const decorate = R.compose(
  connect(
    null,
    {
      showModal,
      hideModal
    }
  ),
  createContext(contextConfig)
);

export default decorate(Layout);
