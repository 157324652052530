import React from "react";
import { Div } from "components/Base";

const getMargins = (left, right, height) => {
  const margins = {};
  if (left) {
    margins.paddingLeft = height / 2 + 4;
    margins.marginLeft = height / 2 - 4;
  }
  if (right) {
    margins.paddingRight = height / 2 + 4;
    margins.marginRight = height / 2 - 4;
  }
  return margins;
};

const Pill = ({
  children,
  height,
  left = false,
  leftIcon,
  right = false,
  rightIcon,
  ...props
}) => (
  <Div
    display="row.flex-start.center"
    height={height}
    style={{
      overflow: "initial",
      position: "relative",
      zIndex: 1,
      ...getMargins(leftIcon, rightIcon, height)
    }}
    {...props}
  >
    {left ? (
      <Div
        bg={props.bg}
        width={height}
        height={height}
        style={{
          borderRadius: "50%",
          position: "absolute",
          left: -height / 2 - 2,
          zIndex: !leftIcon ? -1 : 0
        }}
        display="row.center.center"
      >
        {leftIcon}
      </Div>
    ) : null}
    {children}
    {right ? (
      <Div
        bg={props.bg}
        width={height}
        height={height}
        style={{
          borderRadius: "50%",
          position: "absolute",
          right: -height / 2 - 2,
          zIndex: !rightIcon ? -1 : 0
        }}
        display="row.center.center"
      >
        {rightIcon}
      </Div>
    ) : null}
  </Div>
);

export default Pill;
