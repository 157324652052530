import boolean from "./boolean";
import catering from "./catering";
import cateringDietaryRestrictions from "./catering_dietary_restrictions";
import credentials from "./credentials";
import date from "./date";
import datetime from "./datetime";
import dropdown from "./dropdown";
import dependency from "./dependency";
import eventDays from "./event-days";
import multipleSelect from "./multiple_select";
import number from "./number";
import text from "./text";
import time from "./time";
import user from "./user";
import file from "./file";
import staticValue from "./static";
import phone from "./phone";
import countries from "./countries";
import inventoryList from "./inventory-list";
import inventory from "./inventory";
import reference from "./reference";
import source from "./source";
import lookup from "./lookup";
import catalogItem from "./catalog-item";
import approval from "./approval";
import accountList from "./account-list";
import eventList from "./event-list";

export default function(value, type) {
  switch (type) {
    case "checkbox":
    case "boolean":
      return boolean(value);
    case "catering_dietary_restrictions":
    case "catering-dietary-restrictions":
      return cateringDietaryRestrictions(value);
    case "catering":
      return catering(value);
    case "credentials":
      return credentials(value);
    case "catalog-item":
      return catalogItem(value);
    case "date":
      return date(value);
    case "datetime":
      return datetime(value);
    case "dropdown":
      return dropdown(value);
    case "dependency":
      return dependency(value);
    case "event-days":
      return eventDays(value);
    case "multiple_select":
      return multipleSelect(value);
    case "number":
    case "quantity":
    case "percent":
    case "currency":
      return number(value);
    case "email":
    case "contact":
    case "textarea":
    case "status":
    case "text":
    case "order_status":
      return text(value);
    case "time":
      return time(value);
    case "user":
      return user(value);
    case "file":
      return file(value);
    case "static":
      return staticValue(value);
    case "source":
      return source(value);
    case "phone":
      return phone(value);
    case "countries":
      return countries(value);
    case "inventory-list":
      return inventoryList(value);
    case "inventory":
      return inventory(value);
    case "reference":
      return reference(value);
    case "lookup":
      return lookup(value);
    case "approval":
      return approval(value);
    case "account-list":
      return accountList(value);
    case "event-list":
      return eventList(value);
    default:
      return undefined;
    // @NOTE: Uncomment to debug
    // console.log(`No handler defined for ${type}`);
  }
}
