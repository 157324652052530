export const NAMESPACE = "PortalHubNewRequestSelectItems";

export const FILTER_MODE = {
  BY_CATEGORY: "by_category",
  BY_INPUT: "by_input"
};

export const PAGE_ACTIONS = {
  MARK_AS_COMPLETED: "mark_as_completed"
};

export const PEOPLE_LIST_ID = "peopleListNewRequest";

export const SIDEBAR_INSTANCE_ID = "mobileCategoriesSidebar";
