import PropTypes from "prop-types";
import React, { Component } from "react";
import getRows from "utils/EventForms/FormGrid/getRows";
import isRowNotEmpty from "utils/EventForms/FormGrid/isRowNotEmpty";
import { get, find, sortBy } from "lodash";
import Helpers from "utils/Global/Helpers";
import update from "immutability-helper";
import { browserHistory } from "react-router";

import ModalColumnAdd from "Modules/AddEditColumnModal/View";
import ModalColumnDelete from "components/Global/Table3/ModalColumnDelete";
import ModalQuestion from "components/Event/FormsV2/Modals/FormQuestionModal";
import ItemBlockModal from "components/Event/Settings/Credentials/Modals/ItemBlock";
import SetupFormModal from "components/Event/FormsV2/Form/Views/V3_Edit/Modals/SetupFormModal";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import View from "./View";
import reduceFieldOrder from "components/Event/FormsV2/Utils/reduce-field-order";

import { actions as FormActions } from "redux/modules/eventForms";

import FIELD_TYPES from "components/Event/FormsV2/field-types";
import SUBFORM_FIELD_TYPES from "components/Event/FormsV2/Sections/FormGrid/field-types";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";
import * as STANDARD_MODULE_FIELD_IDS from "@lennd/value-types/src/constants/standard-module-field-ids";

import {
  ORDER_ITEMS_BLOCK_TYPE,
  ORDER_CUSTOMER_BLOCK_TYPE,
  ORDER_FULFILLMENT_BLOCK_TYPE,
  ORDER_DETAILS_BLOCK_TYPE
} from "components/Event/FormsV2/Form/Views/V3_Edit/Shared/constants/block-types";

const ORDER_FIELD_SETTINGS_MAP = {
  [ORDER_CUSTOMER_BLOCK_TYPE]: {
    name: "Customer Information",
    settings: {
      description: "Enter the customer's information"
    }
  },
  [ORDER_FULFILLMENT_BLOCK_TYPE]: {
    name: "Pickup Information",
    settings: {
      method: "pickup",
      description: "Enter the information of the person picking this up"
    }
  },
  [ORDER_DETAILS_BLOCK_TYPE]: {
    name: "Order Details",
    settings: {}
  }
};

const ORDER_SCENES = [
  { id: "details", name: "Request Items" },
  { id: "review", name: "Review", locked: true },
  { id: "confirmation", name: "Confirmation", locked: true }
];

class Controller extends Component {
  state = { activeScene: "details" };
  viewRefs = {};

  componentDidMount() {
    this.props.getForm(this.props.params.viewId);

    if (this.props.location.query.setupOrderForm) {
      this.showSetupOrderFormModal(this.props.location.query.template);
    } else if (this.props.location.query.setupApplicationForm) {
      this.showSetupApplicationFormModal(this.props.location.query.template);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.params.viewId !== this.props.form.id) {
      this.props.getForm(nextProps.params.viewId);
    }
  }

  getFormRows = ({
    schema,
    gridId,
    formValues,
    response,
    isApprovingForm,
    isFillingFormOut,
    isViewingApproval,
    recipientCanViewChanges
  }) => {
    let rows = getRows({
      schema,
      gridId,
      formValues,
      response,
      isApprovingForm,
      isFillingFormOut,
      isViewingApproval,
      recipientCanViewChanges
    });

    if (get(response, "status") === "approval_sent") {
      rows = rows.filter(row => isRowNotEmpty(row, schema.columns));
    }

    return rows;
  };

  getContextMenus = () => {
    const headerCellMenu = [
      {
        type: "row",
        title: "Toggle Required",
        icon: "done",
        action: info => {
          const field = find(this.props.form.fields, { id: info.gridId });
          const subformField = find(field.subform.form.fields, {
            id: info.column
          });
          const sectionId = find(
            field.subform.form.fields_grouped_by_section,
            section => !!find(section.fields, { id: subformField.id })
          ).id;
          const subformId = info.gridId;

          this.props.updateSubformField({
            isRequired: !subformField.is_required,
            formId: find(this.props.form.fields, { id: subformId }).subform.form
              .id,
            subformId,
            sectionId,
            fieldId: subformField.id
          });
        }
      },
      {
        type: "row",
        title: "Hide Field",
        icon: "delete",
        action: info => {
          const field = find(this.props.form.fields, { id: info.gridId });
          const subformField = find(field.subform.form.fields, {
            id: info.column
          });
          const sectionId = find(
            field.subform.form.fields_grouped_by_section,
            section => !!find(section.fields, { id: subformField.id })
          ).id;
          this.showDeleteColumnModal(info.gridId, sectionId, subformField);
        }
      }
    ];

    return { headerCellMenu };
  };

  getColumnModalFields = (type, origin, subformId) => {
    if (type === "parent" && origin === "grid") {
      return find(this.props.form.fields, {
        id: subformId
      }).subform.form.fields.map(f => ({
        ...f,
        origin: "grid",
        name: `Grid: ${f.name}`
      }));
    }
    if (type === "parent" && origin === "form") {
      return this.props.form.fields.map(f => ({
        ...f,
        origin: "form",
        name: `Form: ${f.name}`
      }));
    }
    if (type === "child" && origin === "grid") {
      return [].concat(
        this.props.form.fields.map(f => ({
          ...f,
          origin: "form",
          name: `Form: ${f.name}`
        })),
        find(this.props.form.fields, { id: subformId }).subform.form.fields.map(
          f => ({
            ...f,
            origin: "grid",
            name: `Grid: ${f.name}`
          })
        )
      );
    }
    if (type === "child" && origin === "form") {
      const createListitem = (field, origin, prefix) => ({
        ...field,
        origin,
        name: `${prefix}: ${field.name}`
      });
      // Get all form AND all grid fields
      return this.props.form.fields.reduce(
        (fieldSets, f) => {
          if (f.type === "subform") {
            fieldSets.push(
              ...f.subform.form.fields.map(f =>
                createListitem(f, "grid", "Grid")
              )
            );
          }
          return fieldSets;
        },
        [...this.props.form.fields.map(f => createListitem(f, "form", "Form"))]
      );
    }
    return [];
  };

  getAvailableScenes = () => {
    switch (this.props.form.type) {
      case "order":
        return ORDER_SCENES.map((scene, idx) => ({
          name: scene.name,
          onClick: () => this.goToScene(scene.id),
          active: this.state.activeScene === scene.id,
          isLastScene: idx === ORDER_SCENES.length - 1,
          locked: scene.locked
        }));
      default:
        return [];
    }
  };

  updateFormValue = (path, value) => {
    const form = this.props.form;
    this.props.dispatch(
      FormActions.updateFormValue({
        eventId: form.event_id,
        formId: form.id,
        responseId: get(form, "response.id", 0),
        path,
        value,
        sync: true
      })
    );
  };

  stripSetupQueryParams = () => {
    // remove setup params
    browserHistory.replace(
      `/event/${this.props.form.event_id}/module/${this.props.form.base_module_id}/form/${this.props.form.id}`
    );
  };

  showSetupOrderFormModal = template => {
    // @NOTE: When doing catering self-setup, we will want to conditionally
    // select selectedTypeId

    this.props.showModal({
      content: (
        <SetupFormModal
          type="order"
          template={template}
          formName={this.props.form.name}
          hideModal={() => {
            // @NOTE: By default no-op hideModal so we have to force close the modal on success
            return false;
          }}
          goToNextStep={() => {
            this.stripSetupQueryParams();
            this.props.hideModal();
          }}
          onDone={async ({
            itemBlockId,
            visibleAccountFields,
            requiredAccountFields,
            visibleContactFields,
            requiredContactFields
          }) => {
            const order = this.props.sortedFormFields.length + 1;

            await this.props.addFormField({
              version: 3,
              eventId: this.props.form.event_id,
              formId: this.props.form.id,
              itemBlockId,
              type: ORDER_ITEMS_BLOCK_TYPE,
              order
            });

            await Promise.all([
              this.addOrderCustomerField({
                order: order + 1,
                visibleAccountFields,
                requiredAccountFields,
                visibleContactFields,
                requiredContactFields
              }),
              this.addOrderFulfillmentField(order + 2)
            ]);

            this.props.refreshForm(this.props.form.id);
            this.props.getItemBlocks(this.props.form.event_id);
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  showSetupApplicationFormModal = template => {
    this.props.showModal({
      content: (
        <SetupFormModal
          type="application"
          template={template}
          formName={this.props.form.name}
          hideModal={() => {
            // @NOTE: By default no-op hideModal so we have to force close the modal on success
            return false;
          }}
          goToNextStep={() => {
            this.stripSetupQueryParams();
            this.props.hideModal();
          }}
          onDone={async ({ visibleAccountFields, requiredAccountFields }) => {
            this.stripSetupQueryParams();

            // add reference fields
            const referenceFields = await Promise.all(
              visibleAccountFields.map(field => {
                return this.props.addModuleField({
                  moduleId: this.props.form.base_module_id,
                  field: {
                    name: field.name,
                    type: "reference",
                    settings: {
                      lookupFieldId:
                        STANDARD_MODULE_FIELD_IDS.FORMSV3.SUBMITTING_ACCOUNT,
                      referenceFieldId: field.id,
                      description: ""
                    }
                  },
                  options: {
                    eventId: this.props.form.event_id
                  }
                });
              })
            );

            // add form fields
            await Promise.all(
              referenceFields.map((result, order) => {
                // @NOTE: baseModuleId will be populated if this is a subform -
                // the moduleId of the module that will contain the records / be the subform
                const baseModuleId = null;
                return this.props.addFormField({
                  version: 3,
                  eventId: this.props.form.event_id,
                  formId: this.props.form.id,
                  name: null,
                  type: baseModuleId ? "subform" : "forms-v3-reference-field",
                  order,
                  moduleFieldId: result.field.id,
                  baseModuleId,
                  isRequired: requiredAccountFields.some(
                    f => f.id === result.field.settings.referenceFieldId
                  )
                });
              })
            );

            this.props.refreshForm(this.props.form.id);
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  addOrderCustomerField = async ({
    order,
    visibleAccountFields,
    visibleContactFields
  }) => {
    const { field } = await this.props.addFormField({
      version: 3,
      eventId: this.props.form.event_id,
      formId: this.props.form.id,
      name: ORDER_FIELD_SETTINGS_MAP[ORDER_CUSTOMER_BLOCK_TYPE].name,
      settings: ORDER_FIELD_SETTINGS_MAP[ORDER_CUSTOMER_BLOCK_TYPE].settings,
      type: ORDER_CUSTOMER_BLOCK_TYPE,
      order
    });

    const fieldsToAdd = [
      ...visibleAccountFields.map(moduleField => ({
        formFieldId: field.id,
        moduleId: STANDARD_MODULE_IDS.accounts.id,
        fieldId: moduleField.id,
        toggled: true,
        order: 0
      })),
      ...visibleContactFields.map(moduleField => ({
        formFieldId: field.id,
        moduleId: STANDARD_MODULE_IDS.contacts.id,
        fieldId: moduleField.id,
        toggled: true,
        order: 0
      }))
    ];

    await this.props.toggleFields({
      fields: fieldsToAdd.map((f, idx) => ({
        ...f,
        order: idx
      }))
    });

    return field;
  };

  addOrderFulfillmentField = async order => {
    const formField = await this.props.addFormField({
      version: 3,
      eventId: this.props.form.event_id,
      formId: this.props.form.id,
      name: ORDER_FIELD_SETTINGS_MAP[ORDER_FULFILLMENT_BLOCK_TYPE].name,
      settings: ORDER_FIELD_SETTINGS_MAP[ORDER_FULFILLMENT_BLOCK_TYPE].settings,
      type: ORDER_FULFILLMENT_BLOCK_TYPE,
      order
    });

    return formField;
  };

  addOrderDetailsField = async order => {
    const formField = await this.props.addFormField({
      version: 3,
      eventId: this.props.form.event_id,
      formId: this.props.form.id,
      name: ORDER_FIELD_SETTINGS_MAP[ORDER_DETAILS_BLOCK_TYPE].name,
      settings: ORDER_FIELD_SETTINGS_MAP[ORDER_DETAILS_BLOCK_TYPE].settings,
      type: ORDER_DETAILS_BLOCK_TYPE,
      order
    });

    return formField;
  };

  /**
   * modal: add / edit question
   */
  showAddQuestionModal = index => {
    const modal = (
      <ModalQuestion
        index={index}
        eventId={this.props.form.event_id}
        formId={this.props.form.id}
        field={{ id: "NEW_FIELD_ID" }}
        fields={this.props.form.fields}
        availableParentFields={this.getColumnModalFields("parent", "form")}
        availableChildFields={this.getColumnModalFields("child", "form")}
        fieldTypes={FIELD_TYPES}
        fieldRelationships={this.props.form.field_relationships.map(r => ({
          parentFieldId: r.parent_field_id,
          childFieldId: r.child_field_id
        }))}
        fieldMapping={this.props.form.mapped_fields.map(r => ({
          parentFieldId: r.parent_field_id,
          sourceFieldId: r.source_field_id,
          destinationFieldId: r.destination_field_id
        }))}
      />
    );
    this.props.showModal({ content: modal });
  };

  showEditQuestionModal = field => {
    const modal = (
      <ModalQuestion
        eventId={this.props.form.event_id}
        formId={this.props.form.id}
        field={field}
        fields={this.props.form.fields}
        availableParentFields={this.getColumnModalFields("parent", "form")}
        availableChildFields={this.getColumnModalFields("child", "form")}
        fieldTypes={FIELD_TYPES}
        mode="edit"
        fieldRelationships={this.props.form.field_relationships.map(r => ({
          parentFieldId: r.parent_field_id,
          childFieldId: r.child_field_id
        }))}
        fieldMapping={this.props.form.mapped_fields.map(r => ({
          parentFieldId: r.parent_field_id,
          sourceFieldId: r.source_field_id,
          destinationFieldId: r.destination_field_id
        }))}
      />
    );
    this.props.showModal({ content: modal });
  };

  showEditItemBlockModal = field => {
    this.props.showModal({
      content: (
        <ItemBlockModal
          hideModal={() => {
            this.props.refreshForm(this.props.form.id);
            this.props.getItemBlocks(this.props.form.event_id);
            this.props.hideModal();
          }}
          itemBlockId={field.item_block.id}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  toggleEditing = () => {
    this.props.router.push({
      pathname: `/event/${this.props.form.event_id}/forms-v3/${this.props.form.id}/preview`
    });
  };

  /**
   * modal: add field
   */
  showAddColumnModal = (subformId, sectionId, addAtPosition) => {
    const subform = find(this.props.form.fields, { id: subformId }).subform
      .form;
    const section = find(subform.fields_grouped_by_section, { id: sectionId });
    const modal = (
      <ModalColumnAdd
        mode="add"
        source="form"
        column={{ id: "NEW_FIELD_ID" }}
        showAdvancedSettings
        addAtPosition={addAtPosition}
        columnTypes={SUBFORM_FIELD_TYPES}
        formFieldTypes={FIELD_TYPES}
        fieldRelationships={this.props.form.field_relationships.map(r => ({
          parentFieldId: r.parent_field_id,
          childFieldId: r.child_field_id
        }))}
        fieldMapping={this.props.form.mapped_fields.map(r => ({
          parentFieldId: r.parent_field_id,
          sourceFieldId: r.source_field_id,
          destinationFieldId: r.destination_field_id
        }))}
        columns={
          find(this.props.form.fields, { id: subformId }).subform.form.fields
        }
        availableParentColumns={this.getColumnModalFields(
          "parent",
          "grid",
          subformId
        )}
        availableChildColumns={this.getColumnModalFields(
          "child",
          "grid",
          subformId
        )}
        hideModal={this.props.hideModal}
        done={data => {
          this.props
            .addSubformField({
              subformId,
              formId: find(this.props.form.fields, { id: subformId }).subform
                .form.id,
              sectionId,
              order: data.addAtPosition,
              type: data.type,
              name: data.name,
              settings: data.settings,
              parentFields: data.parentFields,
              childFields: data.childFields,
              fieldMapping: data.fieldMapping
            })
            .then(async field => {
              if (!field) {
                // No field created
                return;
              }
              const fields = sortBy(section.fields, "order");
              const insertIndex = addAtPosition >= 0 ? addAtPosition : 0;
              fields.splice(insertIndex, 0, field);

              await this.props.bulkUpdateSubformFields({
                formId: this.props.form.id,
                subformId,
                sectionId,
                fields: fields.map((f, order) => ({ fieldId: f.id, order }))
              });

              this.props.refreshForm(this.props.form.id);
            });
        }}
      />
    );
    this.props.showModal({ content: modal });
  };

  showEditFieldModal = field => {
    this.props.showEditFieldModal(field.module_field_id);
  };

  /**
   * modal: edit field
   */
  showEditFieldModalOld = (subformId, sectionId, subformField) => {
    const modal = (
      <ModalColumnAdd
        mode="edit"
        source="form"
        showAdvancedSettings
        column={{
          ...subformField
        }}
        fieldRelationships={this.props.form.field_relationships.map(r => ({
          parentFieldId: r.parent_field_id,
          childFieldId: r.child_field_id
        }))}
        fieldMapping={this.props.form.mapped_fields.map(r => ({
          parentFieldId: r.parent_field_id,
          sourceFieldId: r.source_field_id,
          destinationFieldId: r.destination_field_id
        }))}
        columns={
          find(this.props.form.fields, { id: subformId }).subform.form.fields
        }
        availableParentColumns={this.getColumnModalFields(
          "parent",
          "grid",
          subformId
        )}
        availableChildColumns={this.getColumnModalFields(
          "child",
          "grid",
          subformId
        )}
        columnTypes={SUBFORM_FIELD_TYPES}
        formFieldTypes={FIELD_TYPES}
        done={data => {
          this.props.updateSubformField({
            ...data,
            formId: find(this.props.form.fields, { id: subformId }).subform.form
              .id,
            subformId,
            sectionId,
            fieldId: subformField.id
          });
        }}
      />
    );
    this.props.showModal({ content: modal });
  };

  /**
   * modal: delete field
   */
  showDeleteColumnModal = (subformId, sectionId, subformField) => {
    const modal = (
      <ModalColumnDelete
        column={{
          ...subformField
        }}
        hideModal={this.props.hideModal}
        handleDeleteColumn={() => {
          this.props.deleteSubformField({
            formId: this.props.form.id,
            subformId,
            sectionId,
            fieldId: subformField.id
          });
        }}
      />
    );
    this.props.showModal({ content: modal });
  };

  /**
   * columns
   */
  showFilePicker = () => {
    const callback = InkBlobs => {
      const form = this.props.form;
      if (InkBlobs[0] && typeof InkBlobs[0].url !== "undefined") {
        this.props.updateForm({
          formId: form.id,
          backgroundImageUrl: InkBlobs[0].url
        });
      }
    };
    Helpers.getFilepicker({}, { path: "event-form-background/" }, callback);
  };

  moveField = (dragIndex, hoverIndex, draggingField, viaSidebar) => {
    const { sortedFormFields } = this.props;
    const fields = sortedFormFields;
    let indexToUse;
    const dragField =
      typeof dragIndex === "undefined" ? draggingField : fields[dragIndex];

    // if dragIndex is undefined...
    // first, try and get via id of existing fields
    // second, assume its the length - 1 (at the end) of all fields
    if (viaSidebar) {
      const keyedFieldsPosition = reduceFieldOrder(fields)[draggingField.id];
      if (typeof keyedFieldsPosition === "undefined") {
        indexToUse = sortedFormFields.length - 1;
      } else {
        indexToUse = keyedFieldsPosition;
      }
    } else {
      indexToUse = dragIndex;
    }

    return this.props.updateFieldOrder({
      formId: this.props.form.id,
      commit: !viaSidebar,
      fields: reduceFieldOrder(
        update(fields, {
          $splice: [[indexToUse, 1], [hoverIndex, 0, dragField]]
        })
      )
    });
  };

  addFormRef = ref => {
    this.container = ref;
  };

  addSectionRef = ref => {
    this.section_0 = ref;
  };

  goToScene = scene => this.setState({ activeScene: scene });

  registerRef = (ref, id) => {
    this.viewRefs[id] = ref;
  };

  render() {
    const { activeScene } = this.state;
    const {
      isFetching,
      form,
      eventDetails,
      preferences,
      dispatch,
      sortedFormFields,
      showModal,
      canShowSimpleSideBar
    } = this.props;

    const scenes = this.getAvailableScenes();

    return (
      <View
        {...{
          activeScene,
          scenes,
          isFetching,
          form,
          eventDetails,
          dispatch,
          preferences,
          sortedFormFields,
          moveField: this.moveField,
          getFormRows: this.getFormRows,
          showModal,
          addFormRef: this.addFormRef,
          addSectionRef: this.addSectionRef,
          showFilePicker: this.showFilePicker,
          showAddQuestionModal: this.showAddQuestionModal,
          showEditFieldModal: this.showEditFieldModal,
          showEditItemBlockModal: this.showEditItemBlockModal,
          getColumnModalFields: this.getColumnModalFields,
          container: this.container,
          getContextMenus: this.getContextMenus,
          backgroundUrl:
            form.background_image_url ||
            get(eventDetails, "background_image_url"),
          registerRef: this.registerRef,
          canShowSimpleSideBar
        }}
      />
    );
  }
}

Controller.propTypes = {
  params: PropTypes.object.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  preferences: PropTypes.object.isRequired,
  eventDetails: PropTypes.object.isRequired,
  sortedFormFields: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  bulkUpdateSubformFields: PropTypes.func.isRequired,
  getForm: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  addSubformField: PropTypes.func.isRequired,
  updateSubformField: PropTypes.func.isRequired,
  deleteSubformField: PropTypes.func.isRequired,
  updateFieldOrder: PropTypes.func.isRequired,
  showEditFieldModal: PropTypes.func.isRequired,
  canShowSimpleSideBar: PropTypes.bool
};

export default Controller;
