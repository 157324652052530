import React from "react";

import {
  Div,
  TinyToggle,
  DownIcon,
  RightIcon,
  DragIcon,
  Cards,
  MediumClearButton,
  LeftArrowIcon,
  AddIcon,
  Dropdown,
  SmallToggle
} from "components/Base";

import {
  Toolbar,
  Sidebar,
  Separator,
  InputGroup,
  InputLabel,
  Input
} from "components/Event/FormsV2/Form/Views/V3_Edit/Shared/Shared";

const AddField = ({ onAddField, label }) => (
  <Div
    display="row.flex-start.center"
    fw={3}
    my={2}
    color={{
      default: "gray6",
      hover: "gray7"
    }}
    onClick={onAddField}
  >
    <AddIcon size={20} mr={1} /> {label}
  </Div>
);

const Field = ({ name, type, isToggled, onToggle, isRequired, onRequire }) => (
  <Div
    display="row.flex-start.center"
    p={2}
    mb={2}
    bra={1}
    b={1}
    bc="neutral2"
    bg="white"
  >
    {isToggled ? (
      <DragIcon size={20} mr={3} style={{ flexShrink: 0, cursor: "move" }} />
    ) : null}

    <Div display="row.space-between.center" width={1}>
      <Div display="column" width={1}>
        <Div fw={3} fs={2} color="gray9" width={160} truncate>
          {name}
        </Div>
        <Div color="gray6" fs={1} truncate>
          {type}
        </Div>
      </Div>
      <Div display="row.flex-start.center">
        <TinyToggle
          mr={isToggled ? 4 : 0}
          active={isToggled}
          onClick={onToggle}
        />
        {isToggled ? (
          <TinyToggle active={isRequired} onClick={onRequire} />
        ) : null}
      </Div>
    </Div>
  </Div>
);

const ContactFields = Cards(Field, "CONTACT_FIELDS");
const AccountFields = Cards(Field, "ACCOUNT_FIELDS");

const View = ({
  field,
  contactFields,
  accountFields,
  onToggleContactFields,
  onToggleAccountFields,
  showUntoggledContactFields,
  showUntoggledAccountFields,
  onReorderContactFields,
  onReorderAccountFields,
  updateBlockName,
  updateBlockDescription,
  onReturn,
  onAddContactField,
  onAddAccountField,
  contactSectionTitle,
  updateContactSectionTitle,
  accountSectionTitle,
  updateAccountSectionTitle,
  contactType,
  contactTypes,
  updateContactRecordType,
  accountType,
  accountTypes,
  updateAccountRecordType,
  markAsPrimary,
  updateMarkAsPrimary
}) => (
  <Sidebar>
    <Toolbar>
      <LeftArrowIcon sizeWFS={4} mr={3} onClick={onReturn} />
      <Div fs={4} fw={3} color="gray7" flex={1}>
        Customer Information
      </Div>
      <MediumClearButton onClick={onReturn} color="primary5">
        Done
      </MediumClearButton>
    </Toolbar>

    <Div py={4} px={6}>
      <InputGroup>
        <InputLabel>Name</InputLabel>
        <Input
          value={field ? field.name : ""}
          onChange={value => updateBlockName(value)}
        />
      </InputGroup>
      <InputGroup>
        <InputLabel>Description</InputLabel>
        <Input
          value={field ? field.settings.description || "" : ""}
          onChange={value => updateBlockDescription(value)}
        />
      </InputGroup>
      <Separator />
      <Div mb={4}>
        <InputGroup>
          <InputLabel>Section Title</InputLabel>
          <Input
            value={contactSectionTitle}
            onChange={value => updateContactSectionTitle(value)}
            placeholder="Contact Details"
          />
        </InputGroup>
        <InputGroup>
          <InputLabel>Contact Record Type</InputLabel>
          <Dropdown
            value={contactType}
            onChange={({ value }) => updateContactRecordType(value)}
            options={contactTypes}
            usePortal
          />
        </InputGroup>
        <InputGroup>
          <InputLabel>Mark as "Primary Contact"</InputLabel>
          <SmallToggle
            active={markAsPrimary}
            onClick={() => updateMarkAsPrimary(!markAsPrimary)}
          />
        </InputGroup>

        <Div display="row.space-between.center" mb={2}>
          <InputLabel>Person Fields</InputLabel>
          {contactFields.toggled.length ? (
            <Div display="row.flex-start.center" mr={3}>
              <Div color="gray5" fs={1} mr={2}>
                Include
              </Div>
              <Div color="gray5" fs={1}>
                Require
              </Div>
            </Div>
          ) : null}
        </Div>
        <ContactFields
          cards={contactFields.toggled}
          onReorder={onReorderContactFields}
        />
        <Div display="row.space-between.flex-end" mt={3} ml={1}>
          <Div display="row.flex-start.center" onClick={onToggleContactFields}>
            {showUntoggledContactFields ? (
              <DownIcon size={25} mr={1} color="gray7" />
            ) : (
              <RightIcon size={25} mr={1} color="gray7" />
            )}
            <Div color="gray7" fs={2} fw={3}>
              See all person fields
            </Div>
          </Div>
          {showUntoggledContactFields ? (
            <Div display="row.flex-start.center" mr={5}>
              <Div color="gray5" fs={1}>
                Include
              </Div>
            </Div>
          ) : null}
        </Div>

        {showUntoggledContactFields ? (
          <Div mx={3} mt={3}>
            {contactFields.untoggled.map(f => (
              <Field key={f.id} {...f} />
            ))}

            <AddField
              label="Create new Contact field"
              onAddField={onAddContactField}
            />
          </Div>
        ) : null}
      </Div>

      <Separator />

      <InputGroup>
        <InputLabel>Section Title</InputLabel>
        <Input
          value={accountSectionTitle}
          onChange={value => updateAccountSectionTitle(value)}
          placeholder="Company/Group Details"
        />
      </InputGroup>
      <InputGroup>
        <InputLabel>Account Record Type</InputLabel>
        <Dropdown
          value={accountType}
          onChange={({ value }) => updateAccountRecordType(value)}
          options={accountTypes}
        />
      </InputGroup>

      <Div display="row.space-between.center" mb={2}>
        <InputLabel>Company/Group Fields</InputLabel>
        {accountFields.toggled.length ? (
          <Div display="row.flex-start.center" mr={3}>
            <Div color="gray5" fs={1} mr={2}>
              Include
            </Div>
            <Div color="gray5" fs={1}>
              Require
            </Div>
          </Div>
        ) : null}
      </Div>
      <Div>
        <AccountFields
          cards={accountFields.toggled}
          onReorder={onReorderAccountFields}
        />

        <Div display="row.space-between.flex-end" mt={3} ml={1}>
          <Div display="row.flex-start.center" onClick={onToggleAccountFields}>
            {showUntoggledAccountFields ? (
              <DownIcon size={25} mr={1} color="gray7" />
            ) : (
              <RightIcon size={25} mr={1} color="gray7" />
            )}
            <Div color="gray7" fs={2} fw={3}>
              See all company/group fields
            </Div>
          </Div>
          {showUntoggledAccountFields ? (
            <Div display="row.flex-start.center" mr={5}>
              <Div color="gray5" fs={1}>
                Include
              </Div>
            </Div>
          ) : null}
        </Div>

        {showUntoggledAccountFields ? (
          <Div mx={3} mt={3}>
            {accountFields.untoggled.map(f => (
              <Field key={f.id} {...f} />
            ))}

            <AddField
              label="Create new Group field"
              onAddField={onAddAccountField}
            />
          </Div>
        ) : null}
      </Div>
    </Div>
  </Sidebar>
);

export default View;
