import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const ModalBase = ({ children, wrapperStyle, ...props }) => (
  <div styleName="wrapper" style={wrapperStyle}>
    <div
      styleName="container"
      onMouseDown={e => e.stopPropagation()}
      {...props}
    >
      {children}
    </div>
  </div>
);

export default CSSModules(ModalBase, css);
