import * as R from "ramda";
import { createSelector } from "reselect";
import STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import { SORTING } from "./constants";

import { getters } from "Modules/SubmitFormModal";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";

import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

export const getFilteredForms = createSelector(
  getters.forms,
  getters.filterForm,
  canEventUserDo,
  getUserId,
  state => selectFeatureFlag(flags.IS_LENND_ADMIN.NAME)(state),
  getters.saving,
  (forms, filterForm, canDo, userId, isLenndAdmin, saving) => {
    const isEventAdmin = canDo(
      `${STANDARD_MODULE_IDS.settings.id}_manage_all_module_data`
    );
    const filteredByUser =
      isLenndAdmin || isEventAdmin
        ? forms
        : forms.filter(form => form.form_users.find(u => u.user_id === userId));

    const filteredForms = R.map(f => ({
      ...f,
      saving: saving && saving === f.id ? true : false
    }))(filteredByUser);

    if (R.isEmpty(filterForm)) {
      return filteredForms;
    }
    return R.filter(
      form => R.contains(R.toLower(filterForm), R.toLower(form.name)),
      filteredForms
    );
  }
);

export const getFormsSorted = createSelector(
  getFilteredForms,
  getters.selectedSorting,
  getters.filterForm,
  (forms, selectedSorting) => {
    if (selectedSorting === SORTING.AZ) {
      const sortByName = R.sortBy(R.prop("name"));
      return sortByName([...forms]);
    }
    const sortByCount = R.sortBy(f => -parseInt(f.count_of_submissions, 10));
    return sortByCount([...forms]);
  }
);
