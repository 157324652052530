import * as R from "ramda";
import { createSelector } from "reselect";

import { getters } from "./model";

export const getSelectedAccount = createSelector(
  getters.accountId,
  getters.accounts,
  (accountId, accounts) =>
    R.isEmpty(accountId)
      ? { id: null, name: "" }
      : R.find(R.propEq("id", accountId), accounts)
);

export const getSelectedContact = createSelector(
  getters.contactId,
  getters.contacts,
  (contactId, contacts) =>
    R.isEmpty(contactId)
      ? { id: null, name: "" }
      : R.find(R.propEq("id", contactId), contacts)
);

export const getIsSelectedAccount = createSelector(
  getters.accountId,
  accountId => !R.isEmpty(accountId)
);

export const getIsSelectedContact = createSelector(
  getters.contactId,
  contactId => !R.isEmpty(contactId)
);

export const getContactsOutsideAccount = createSelector(
  getters.selectedContacts,
  selectedContacts =>
    R.filter(
      ({ relationship_id }) => R.isNil(relationship_id),
      selectedContacts
    )
);

export const getAccountsOutsideContact = createSelector(
  getters.selectedAccounts,
  selectedAccounts =>
    R.filter(
      ({ relationship_id }) => R.isNil(relationship_id),
      selectedAccounts
    )
);

export const getContactsInsideAccount = createSelector(
  getters.selectedContacts,
  selectedContacts =>
    R.filter(
      ({ relationship_id }) => !R.isNil(relationship_id),
      selectedContacts
    )
);

export const getAccountsInsideContact = createSelector(
  getters.selectedAccounts,
  selectedAccounts =>
    R.filter(
      ({ relationship_id }) => !R.isNil(relationship_id),
      selectedAccounts
    )
);
