import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import * as selectors from "redux/modules/features/selectors";

function mapStateToProps(state, props) {
  return {
    enabled: selectors.isEnabled(state, props.feature)
  };
}

export const IsFeatureEnabled = ({ enabled, children }) => (
  <div>{enabled ? children : ""}</div>
);

IsFeatureEnabled.propTypes = {
  enabled: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

export default connect(mapStateToProps)(IsFeatureEnabled);
