import React from "react";
import { connect } from "react-redux";

import { Div, Text6, Text1, HalfSizeDotIcon } from "components/Base";

import { MODES } from "Items/Manage/constants";

import { getters } from "Items/Manage";

const computePercentage = (fav, total) => {
  if (total <= 0 || fav <= 0) {
    return 0;
  }
  return fav / total;
};

const round = val => Math.round(val * 100);

const mapStateToProps = state => ({
  mode: getters.mode(state),
  stats: getters.stats(state)
});

const decorate = connect(mapStateToProps);

const StatsBar = ({
  stats: { total, issued, approved, pendingPickup } = {},
  mode
}) => {
  const mainPercent = computePercentage(
    mode === MODES.ISSUANCE ? issued : approved,
    mode === MODES.ISSUANCE ? approved : total
  );

  const pickUpPercent = computePercentage(issued - pendingPickup, approved);

  return (
    <Div flex={1} display="row">
      <Div flex={1} px={5}>
        <Div width={1} display="row">
          <Div flex={1}>
            <Text6 bold>
              {Number.isNaN(mainPercent) ? "--" : `${round(mainPercent)}%`}
            </Text6>
            <Div display="row">
              <HalfSizeDotIcon color="success5" />
              <Text1 pb={3} ml={2}>
                {mode === MODES.ISSUANCE ? "Issued" : "Approved"}
              </Text1>
            </Div>
          </Div>
          {(mode === MODES.ISSUANCE && (
            <Div flex={1}>
              <Text6 bold>
                {Number.isNaN(pickUpPercent)
                  ? "--"
                  : `${round(pickUpPercent)}%`}
              </Text6>
              <Div display="row">
                <HalfSizeDotIcon color="success8" />
                <Text1 pb={3} ml={2}>
                  Picked-up
                </Text1>
              </Div>
            </Div>
          )) ||
            null}
        </Div>
        <div style={{ position: "relative" }}>
          <Div width={1} bg="neutral1" height={13} bra={2} />
          <Div
            width={mainPercent}
            bg="success5"
            height={7}
            brl={2}
            brr={mainPercent === 1 ? 2 : undefined}
            style={{
              position: "absolute",
              top: "50%",
              left: 3,
              transform: "translateY(-60%)"
            }}
          />
          {mode === MODES.ISSUANCE && (
            <Div
              width={pickUpPercent}
              bg="success8"
              height={7}
              brl={2}
              brr={pickUpPercent === 1 ? 2 : undefined}
              style={{
                position: "absolute",
                top: "50%",
                left: 3,
                transform: "translateY(-60%)",
                zIndex: 10
              }}
            />
          )}
        </div>
      </Div>
      <Text1 italics display="row.flex-start.flex-end">
        {mode === MODES.ISSUANCE ? approved : total}{" "}
        {mode === MODES.ISSUANCE ? "Approved" : "Requested"}
      </Text1>
    </Div>
  );
};

export default decorate(StatsBar);
