import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { getters } from "../model";
import { Div } from "components/Base";
import Panel from "./Panel";
import Tweets from "./Sections/Tweets";
import Links from "./Sections/Links";
import Alerts from "./Sections/Alerts";

const mapIndexed = R.addIndex(R.map);

const decorator = connect((state, props) => ({
  footerSections: getters.footerSections(state, props)
}));

const FooterSections = ({ footerSections }) => {
  if (R.isEmpty(footerSections)) {
    return null;
  }

  return (
    <Div
      style={{
        display: "grid",
        gridGap: 20,
        gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))"
      }}
    >
      {mapIndexed(
        (section, index) => (
          <Panel key={index}>
            {mapIndexed((types, i) => {
              if (types.type === "twitter") {
                return <Tweets key={i} content={types.content} />;
              } else if (types.type === "links") {
                return <Links key={i} data={types} />;
              } else if (types.type === "alerts") {
                return <Alerts key={i} data={types} />;
              }
              return null;
            }, section)}
          </Panel>
        ),
        footerSections
      )}
    </Div>
  );
};

export default decorator(FooterSections);
