import React, { Component } from "react";
import { connect } from "react-redux";
import { debounce, sortBy } from "lodash";
import { orgId } from "redux/modules/organization/selectors";
import { eventId } from "redux/modules/event/selectors";
import { defaultProps } from "utils/General";
import { search } from "redux/modules/accounts/list/api";
import { getCredentials } from "redux/modules/user/selectors";

function mapStateToProps(state) {
  return {
    orgId: orgId(state),
    eventId: eventId(state),
    userId: state.user.user.id,
    userCredentials: getCredentials(state)
  };
}

const defaultState = { searchTerm: undefined, searching: false, results: [] };

class SearchAccountController extends Component {
  state = defaultState;

  componentWillUnmount() {
    // this.search.cancel();
  }

  handleSearch = async term => {
    if (!term || !term.length) {
      return this.setState(defaultState);
    }
    if (term === this.state.searchTerm) return;

    this.setState({ searching: true });
    const { accounts } = await search(
      this.props.orgId,
      this.props.eventId,
      term,
      this.props.userCredentials
    );

    this.setState({
      searchTerm: term,
      searching: false,
      results: sortBy(
        accounts.slice(0, this.props.limit).map(a => ({
          id: a.id,
          name: a.name
        })),
        "name"
      )
    });
  };

  search = debounce(this.handleSearch, 500);

  reset = () => this.setState(defaultState);

  render() {
    const { children } = this.props;

    if (typeof children === "function") {
      return children({
        clearSearch: this.reset,
        searchTerm: this.state.searchTerm,
        onSearch: this.search,
        isSearching: this.state.searching,
        results: this.state.results
      });
    }
  }
}

export default connect(mapStateToProps)(
  defaultProps({ limit: 25 })(SearchAccountController)
);
