import hiddenFields from "components/Event/FormsV2/Utils/field-blacklist";

const HIDDEN_FIELDS = [...hiddenFields, "subform", "people"];

export default ({ getMetaData, submission, fields }) =>
  fields
    .filter(field => !HIDDEN_FIELDS.includes(field.type))
    .filter(field => submission.values[field.id])
    .map(field => ({
      label: field.name,
      value: submission.values[field.id],
      field,
      meta: getMetaData({ submission, rowData: submission.values, field })
    }));
