import { DEFAULT_APPROVAL_FIELD_APPROVE_REJECT } from "components/Event/FormsV2/constants";
import { APPROVAL } from "utils/system-field-ids";
import * as R from "ramda";

export default submission => {
  let approvalStatus = "pending";
  if (submission.values[APPROVAL] && submission.values[APPROVAL].value) {
    approvalStatus = submission.values[APPROVAL].value.status;
  }
  return {
    id: submission.id,
    [DEFAULT_APPROVAL_FIELD_APPROVE_REJECT.id]: {
      type: "text",
      value: approvalStatus
    },
    ...submission.values
  };
};
