import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import Tooltip from "components/Global/Tooltip";

@CSSModules(css)
class DescriptionHeader extends Component {
  render() {
    const { children } = this.props;

    return (
      <div styleName="container">
        <div>{children}</div>
        {this.props.description && this.props.description.length ? (
          <Tooltip tooltip={this.props.description}>
            <div className="header-description-icon" styleName="iconWrapper">
              <i className="material-icons" styleName="circle">
                brightness_1
              </i>
            </div>
          </Tooltip>
        ) : null}
      </div>
    );
  }
}

export default DescriptionHeader;
