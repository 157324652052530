import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import * as R from "ramda";
import itemTypesApi from "redux/modules/items/types/api";
import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { actions, getters } from "SalesPortal/TypesModal";
import { MEAL_TYPE_ID } from "utils/item-types";
import { registerError } from "redux/modules/errors/actions";

const getParams = function*() {
  const eventId = yield select(getEventId);
  const credentials = yield select(getCredentials);

  return { eventId, credentials };
};

const getItemTypes = function*() {
  const oldTypes = yield select(getters.types);

  if (R.isEmpty(oldTypes)) {
    const { eventId, credentials } = yield call(getParams);

    yield put(actions.setLoading(true));

    try {
      const itemTypesResponse = yield call(
        itemTypesApi.getItemTypesByEvent,
        credentials,
        eventId
      );

      const types = itemTypesResponse.payload.filter(
        t => t.id !== MEAL_TYPE_ID
      );

      yield put(actions.setInitialData({ types }));
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error occurred loading item types"
          }
        ])
      );
    }
    yield put(actions.setLoading(false));
  }
};

const watchItemTypes = function*() {
  yield takeEvery(actions.getItemTypes.type, getItemTypes);
};

const rootSaga = function*() {
  yield all([fork(watchItemTypes)]);
};

export default rootSaga;
