import { createModule } from "redux-mvc";
import activityLogsModule from "Event/ActivityLogs";

import model from "./model";
import sagas from "./sagas";

const module = createModule(model);
module.setRootSaga(sagas);

module.plugModule(activityLogsModule);

export default module;
