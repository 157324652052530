import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import ReportSummary from "components/Global/Reports/Summary/Panel";
import Menu from "./Menu";
import AddRecordModal from "components/Global/Module/Modals/AddRecord";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import {
  catalogItems,
  catalogCategories
} from "@lennd/value-types/src/constants/standard-modules";
import { CATALOG_ITEMS } from "utils/standard-module-field-ids";
import NumberFormatter from "./Formatters/Number";
import NameFormatter from "./Formatters/Name";

export const FIELDS = [
  {
    id: "name",
    formatter: NameFormatter,
    label: "Item Name",
    style: { width: "50%" },
    valueGetter: value => value.value
  },
  {
    id: "requested",
    label: "Req",
    formatter: NumberFormatter,
    style: { textAlign: "right" }
  },
  {
    id: "approved",
    label: "Approved",
    formatter: NumberFormatter,
    style: { textAlign: "right" }
  }
];

@CSSModules(css)
class ReportPanel extends Component {
  formatRows(items) {
    return items.map(itemInfo => {
      const { item, approved, requested } = itemInfo;
      return { name: { value: item.name, id: item.id }, approved, requested };
    });
  }

  showAddRecordModal = () => {
    this.props.showModal({
      content: (
        <AddRecordModal
          moduleId={catalogItems.id}
          onSave={this.props.onUpdate}
          values={{
            [CATALOG_ITEMS.CATEGORY]: {
              type: "lookup",
              value: {
                moduleId: catalogCategories.id,
                records: [this.props.id]
              }
            }
          }}
        />
      ),
      wrapper: ModalWrapper
    });
  };

  render() {
    const { name, items } = this.props;

    return (
      <div styleName="wrapper">
        <ReportSummary
          emptyState="No requests yet"
          menu={<Menu onAddItem={this.showAddRecordModal} />}
          title={name}
          fields={FIELDS}
          rows={this.formatRows(items)}
        />
      </div>
    );
  }
}

ReportPanel.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      item: PropTypes.object,
      requested: PropTypes.number,
      pending: PropTypes.number,
      approved: PropTypes.number,
      rejected: PropTypes.number
    })
  ).isRequired
};

export default ReportPanel;
