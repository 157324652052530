import React, { Component } from "react";
import View from "./View";
import * as R from "ramda";

class PortalReportsController extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    if (R.path(["active_view", "type"])(this.props.portalUser) !== "account") {
      return this.setState({ loading: false });
    }

    Promise.all([
      this.props.getCredentialsReport({
        eventId: this.props.eventDetails.id,
        accountId: this.props.portalUser.active_view.id
      }),
      this.props.getCateringReport({
        eventId: this.props.eventDetails.id,
        accountId: this.props.portalUser.active_view.id
      }),
      this.props.canViewInventory
        ? this.props.getItemAssetReport({
            eventId: this.props.eventDetails.id,
            accountId: this.props.portalUser.active_view.id
          })
        : this.props.getAssetReport({
            eventId: this.props.eventDetails.id,
            accountId: this.props.portalUser.active_view.id
          })
    ]).then(() => {
      this.setState({ loading: false });
    });
  }

  goToReport = report => {
    this.props.router.push({
      pathname: `/portals/${this.props.eventDetails.slug}/${this.props.eventDetails.uuid}/reports/${report}`
    });
  };

  render() {
    const {
      eventDetails,
      hasCateringReport,
      hasAssetsReport,
      hasCredentialsReport,
      portalUser,
      params,
      settings
    } = this.props;
    const section = settings.sections.find(s => s.id === "reports") || {};

    const links = [
      hasAssetsReport && {
        id: "assets",
        name: "Assets",
        onClick: () => this.goToReport("assets"),
        active: !params.report || params.report === "assets"
      },
      hasCredentialsReport && {
        id: "credentials",
        name: "Passes",
        onClick: () => this.goToReport("credentials"),
        active:
          (!params.report && !hasAssetsReport) ||
          params.report === "credentials"
      },
      hasCateringReport && {
        id: "meals",
        name: "Catering / Meals",
        onClick: () => this.goToReport("meals"),
        active:
          (!params.report && !hasAssetsReport && !hasCredentialsReport) ||
          params.report === "meals"
      }
    ].filter(Boolean);

    const activeReport = R.prop("id", links.find(l => l.active));

    return (
      <View
        {...{
          links,
          eventDetails,
          portalUser,
          activeReport,
          isEmpty:
            !hasAssetsReport && !hasCredentialsReport && !hasCateringReport,
          loading: this.state.loading,
          canViewInventory: this.props.canViewInventory,
          title: section.title,
          description: section.description
        }}
      />
    );
  }
}

export default PortalReportsController;
