import React from "react";
import { set } from "lodash";

import {
  Div,
  makeTable,
  Text3,
  ExpandIcon,
  ThreeQuarterSizeSquareIcon,
  PopoverMenu,
  SmallOutlineButton,
  FontIcon
} from "components/Base";

import {
  HeaderStyler,
  RowStyler,
  TableStyler
} from "components/Event/Settings/Layout/Tables/stylers";
import EventDaysFormatter from "components/Global/Table3/CellFormatters/EventDays";

import CSSModules from "react-css-modules";
import css from "components/Event/Settings/Layout/Tables/styles.css";

const addS = thing => (thing === 1 ? "" : "s");

const CredentialCell = CSSModules(
  ({ name, color, IntegrationIcon, ...props }) => (
    <Div display="row.flex-start.center" {...props}>
      <ThreeQuarterSizeSquareIcon
        color={color && color.length ? color : null}
        size={12}
        mr={2}
        style={{ minWidth: 12 }}
      />
      {IntegrationIcon}
      <Text3 bold mr={3} primary>
        {name}
      </Text3>
      <SmallOutlineButton
        m={2}
        pill
        styleName="childContainer"
        OnlyIcon={ExpandIcon}
        color="neutral7"
      />
    </Div>
  ),
  css
);

const TypesTable = makeTable({
  TableStyler,
  HeaderStyler,
  RowStyler,
  HeaderCellStyler: Div,
  RowCellStyler: Div,
  columnProps: [
    { width: 5 / 12 },
    { width: 1 / 12 },
    { width: 2 / 12 },
    // { width: 2 / 12 },
    { width: 2 / 12 },
    { width: 2 / 12 }
  ],
  headerCellComps: [
    () => <Text3 bold>Name</Text3>,
    () => <Text3 bold>QTY</Text3>,
    () => <Text3 bold>Type</Text3>,
    // () => <Text3 bold>Zones</Text3>,
    () => <Text3 bold>Dates Valid</Text3>,
    () => <Text3 bold>Actions</Text3>
  ],
  rowCellComps: [
    ({ name, IntegrationIcon, color, onCredentialClick }) => (
      <CredentialCell
        name={name}
        color={color}
        IntegrationIcon={IntegrationIcon}
        onClick={onCredentialClick}
      />
    ),
    ({ quantity }) => <Text3>{quantity}</Text3>,
    ({ type }) => <Text3>{type}</Text3>,
    // ({ numZones }) => <Text3>{`${numZones} zone${addS(numZones)}`}</Text3>,
    ({ dateGroups, dates }) =>
      dates ? (
        <EventDaysFormatter
          dependentValues={set({}, "meta.eventDetails.date_groups", dateGroups)}
          value={{ value: dates }}
          containerStyle={{
            flexWrap: "wrap",
            marginTop: 2
          }}
          dayStyle={{
            fontSize: 10,
            marginBottom: 2
          }}
        />
      ) : (
        "—"
      ),
    ({ onEdit, onClone, onDelete, moveCredentialUp, moveCredentialDown }) => (
      <PopoverMenu
        Label={({ onClick }) => (
          <SmallOutlineButton onClick={onClick} py={1}>
            <FontIcon fs={3}>more_horiz</FontIcon>
          </SmallOutlineButton>
        )}
        menuItems={[
          ["Edit", onEdit],
          ["Clone", onClone],
          ["Delete", onDelete],
          moveCredentialUp ? ["Move up", moveCredentialUp] : undefined,
          moveCredentialDown ? ["Move down", moveCredentialDown] : undefined
        ].filter(i => i)}
      />
    )
  ]
});

export default ({ types, onReorder, dragTypeId }) => (
  <TypesTable rowsData={types} onReorder={onReorder} dragTypeId={dragTypeId} />
);
