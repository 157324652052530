export const NAMESPACE = "TicketsPasses";

export const PASSES_COLUMNS_WIDTH = {
  NAME: 315,
  CATEGORY: 198,
  PRICE: 96,
  WHO_HAS_ACCESS: 196,
  ACTIONS: 60
};

const CREDENTIALS = {
  tempId: 1,
  name: "Credentials"
};

const INTERNAL_TICKETS = {
  tempId: 2,
  name: "Internal Tickets"
};

const PARKING = {
  tempId: 3,
  name: "Parking"
};

export const CATEGORIES = [CREDENTIALS, INTERNAL_TICKETS, PARKING];

export const DATA_LIST = [
  {
    name: "VIP Pass",
    category: CREDENTIALS
  },
  { name: "Backstage", category: CREDENTIALS },
  { name: "All Access", category: CREDENTIALS },
  { name: "GA - 3 Day", category: INTERNAL_TICKETS },
  { name: "GA - Saturday", category: INTERNAL_TICKETS },
  { name: "GA - Sunday", category: INTERNAL_TICKETS },
  { name: "Staff Parking", category: PARKING },
  { name: "Lot A", category: PARKING },
  { name: "Lot B", category: PARKING }
];

export const INITIAL_DATA = DATA_LIST.map(({ name, category }, i) => ({
  sort_order: i,
  name: name,
  locked: true,
  category,
  prices: [],
  who_has_access: []
}));

export const QUICK_ASSIGN_PAGES = {
  PRICES: "prices",
  CREDENTIALS: "credentials"
};
