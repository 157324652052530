import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AddSubmissionAsModal from "./AddSubmissionAsModal";

import * as PortalUserActions from "redux/modules/portal/user/actions";
import * as PortalUserSelectors from "redux/modules/portal/user/selectors";
import * as UserSelectors from "redux/modules/user/selectors";
import * as EventSelectors from "redux/modules/event/selectors";

function mapStateToProps(state) {
  return {
    isFetching: PortalUserSelectors.isFetching(state),
    accounts: PortalUserSelectors.accounts(state).map(a => ({
      ...a,
      search: a.name.toLowerCase()
    })),
    eventDetails: EventSelectors.eventDetails(state),
    user: UserSelectors.user(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...PortalUserActions
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(
  AddSubmissionAsModal
);
