import React from "react";
import Sidebar from "components/Global/Module/Sidebar";
import SettingsSidebar from "components/Organization/Settings/Sidebar";

import CSSModules from "react-css-modules";
import css from "components/Event/Settings/styles.scss";

const Settings = CSSModules(
  ({ children }) => (
    <Sidebar
      moduleId="settings"
      contentClassName={css.content}
      sidebar={<SettingsSidebar />}
    >
      <div styleName="rightColumn">{children}</div>
    </Sidebar>
  ),
  css
);

export default Settings;
