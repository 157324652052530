import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import ModuleSummaries from "components/Event/FormsV2/Overview/ModuleSummaries";
import validatedEntries from "components/Event/FormsV2/Overview/utils/summaries/validEntries";
import cardSummary from "components/Global/CRM/Forms/utils/card-summary";
import Entry from "components/Event/FormsV2/Overview/SwimLanes/SwimLane/SubmissionCard/FieldEntry";

@CSSModules(css)
class SubmissionBody extends Component {
  getMetaData = ({ form, submission, rowData = {}, field }) => ({
    ...rowData,
    meta: {
      columnId: field.id,
      columns: form.fields,
      columnSettings: field.settings,
      contactId: submission.contact_id,
      eventDetails: this.props.eventDetails,
      eventId: form.event_id,
      formId: form.formId
    },
    helpers: {}
  });

  render() {
    const { eventDetails, form, submission } = this.props;

    const cardBody = cardSummary({
      submission,
      eventDetails,
      fields: Object.keys(form.fields).map(id => form.fields[id]),
      getMetaData: ({ ...args }) => this.getMetaData({ form, ...args })
    });

    const { entries, summaries } = cardBody;

    return (
      <div styleName="container">
        {entries.map((entry, i) => (
          <Entry key={`${entry.field.id}-${i}`} {...entry} />
        ))}
        <ModuleSummaries
          showApprovals={summaries.some(({ values }) =>
            values.some(v => v.approved)
          )}
          showRejections={summaries.some(({ values }) =>
            values.some(v => v.rejected)
          )}
          counts={validatedEntries(summaries)}
        />
      </div>
    );
  }
}

SubmissionBody.propTypes = {
  eventDetails: PropTypes.object.isRequired,
  form: PropTypes.shape({
    eventId: PropTypes.string,
    fields: PropTypes.object,
    formId: PropTypes.string
  }).isRequired,
  submission: PropTypes.shape({
    contact_id: PropTypes.string,
    values: PropTypes.object
  }).isRequired
};

export default SubmissionBody;
