import React from "react";

import { connect } from "react-redux";

import { actions } from "Items/EditQuestionSetQuestionsModal";

import { HEIGHTS } from "Items/EditQuestionSetQuestionsModal/constants";

import { Div, BigFilledButton, BigOutlineButton } from "components/Base";

const decorate = connect(
  null,
  {
    updateQuestionSet: actions.updateQuestionSet
  }
);

const Footer = ({ hideModal, handlers, updateQuestionSet }) => (
  <Div
    width={1}
    bt={1}
    bc="neutral1"
    display="row.space-between.center"
    px={4}
    height={HEIGHTS.FOOTER}
  >
    <BigFilledButton
      bg="altB4"
      color="white"
      onClick={() => updateQuestionSet({ onDone: handlers.onDone })}
    >
      Save
    </BigFilledButton>

    <BigOutlineButton width={121} onClick={hideModal}>
      Cancel
    </BigOutlineButton>
  </Div>
);

export default decorate(Footer);
