import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { Div } from "components/Base";
import { actions } from "../../model";
import Tooltip from "components/Global/Tooltip";
import Notifications from "Notifications/View";
import HeaderAvatar from "components/Global/Headers/HeaderAvatar";
import CSSModules from "react-css-modules";
import css from "../styles.scss";
import { Link } from "react-router";

import { orgDetails as getOrgDetails } from "redux/modules/organization/selectors";
import { user as getUser } from "redux/modules/user/selectors";
import { getOrgInitial, getLogoImageUrl } from "../../selectors";
import { canOrgUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

const decorate = R.compose(
  connect(
    (state, props) => ({
      orgDetails: getOrgDetails(state, props),
      user: getUser(state, props),
      orgInitial: getOrgInitial(state),
      logoImageUrl: getLogoImageUrl(state),
      canUpdateSettings: canOrgUserDo(state)(
        `${STANDARD_MODULE_IDS.orgSettings.id}_update`
      ),
      canAddEvents: canOrgUserDo(state)(
        `${STANDARD_MODULE_IDS.orgSettings.id}_update`
      )
    }),
    {
      onToggleCollapsed: actions.toggleCollapse,
      showLogoFilepicker: actions.showLogoFilepicker,
      showAddEventModal: actions.showAddEventModal
    }
  ),
  CSSModules(css)
);

const Header = ({
  orgDetails,
  onToggleCollapsed,
  orgInitial,
  logoImageUrl,
  showLogoFilepicker,
  showAddEventModal,
  canUpdateSettings,
  canAddEvents
}) => {
  return (
    <div className={css.header}>
      <Div display="row.flex-start.center">
        <div className={css.menuIcon} onClick={onToggleCollapsed}>
          <span className="material-icons-outlined">menu</span>
        </div>

        <Tooltip tooltip="View all my organizations" placement="bottom">
          <Link
            to="/home"
            style={{
              display: "flex"
            }}
          >
            <img
              src="https://d2dks4tzxs6xee.cloudfront.net/img/LenndIcon.png"
              height={30}
            />
          </Link>
        </Tooltip>
        <Div display="row.flex-start.center" ml={2}>
          <Div fs={5} fw={4} color="black" mr={2}>
            +
          </Div>

          {logoImageUrl ? (
            <Div
              width={30}
              height={30}
              bra={1}
              bg="gray1"
              display="row.center.center"
              mr={2}
              style={{
                backgroundImage: `url(${logoImageUrl})`,
                backgroundSize: "cover",
                backgroundPosition: "center center"
              }}
            />
          ) : canUpdateSettings ? (
            <Tooltip tooltip="Upload organization logo" placement="bottom">
              <Div
                width={30}
                height={30}
                bra={1}
                bg={{
                  default: "gray1",
                  hover: "gray2"
                }}
                display="row.center.center"
                mr={2}
                onClick={showLogoFilepicker}
              >
                <Div fw={3} color="#ccc" fs={3}>
                  {orgInitial}
                </Div>
              </Div>
            </Tooltip>
          ) : (
            <Div
              width={30}
              height={30}
              bra={1}
              bg={{
                default: "gray1",
                hover: "gray2"
              }}
              display="row.center.center"
              mr={2}
            >
              <Div fw={3} color="#ccc" fs={3}>
                {orgInitial}
              </Div>
            </Div>
          )}

          <Div
            fs={4}
            fw={4}
            color="black"
            style={{
              fontSize: "20px"
            }}
          >
            {orgDetails.name}
          </Div>
        </Div>
      </Div>

      <Div display="row.flex-end.center">
        {canAddEvents ? (
          <div
            className={css.addEventsButton}
            onClick={() => showAddEventModal()}
            data-cy="org-add-event-button"
          >
            <span className="material-icons-outlined">add</span>
            Add Event
          </div>
        ) : null}

        <div styleName="notificationsWrapper">
          <Notifications params={{}} orgId={parseInt(orgDetails.id, 10)} />
        </div>
        {/*
        <div
          styleName="help"
          onClick={() => {
            if (window.Intercom) {
              window.Intercom("show");
            } else {
              window.open("https://learn.lennd.com/", "_blank");
            }
          }}
        >
          <div styleName="content">?</div>
        </div>
        */}

        <HeaderAvatar />
      </Div>
    </div>
  );
};

export default decorate(Header);
