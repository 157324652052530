const types = require("./field-types");

module.exports = (filter, record, meta) =>
  types({
    type: filter.field.type,
    pattern: filter.pattern,
    recordValue: record.values[filter.fieldId],
    filterValue: filter.value,
    meta
  });
