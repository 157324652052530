import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Sidebar from "./Sidebar";
import { withRouter } from "react-router";

import { userPermissionProfile } from "redux/modules/permissions/user-permission-profile/selectors";

function mapStateToProps(state, props) {
  return {
    userPermissionProfile: userPermissionProfile(
      state,
      props.params.eventId,
      state.user.user.id
    )
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Sidebar)
);
