import * as Colors from "material-ui/styles/colors";
import { fade } from "material-ui/utils/colorManipulator";
import Spacing from "material-ui/styles/spacing";

export default {
  spacing: Spacing,
  fontFamily: "proxima-nova, arial, sans-serif",
  palette: {
    primary1Color: "#A96DE3",
    primary2Color: Colors.deepPurple400,
    primary3Color: Colors.lightBlack,
    accent1Color: Colors.deepPurple400,
    accent2Color: Colors.grey100,
    accent3Color: Colors.grey500,
    textColor: Colors.lightBlack,
    alternateTextColor: Colors.white,
    canvasColor: Colors.white,
    borderColor: Colors.grey300,
    disabledColor: fade(Colors.darkBlack, 0.3)
  },
  zIndex: {
    popover: 2101,
    layer: 2100,
    modal: 999
  }
};
