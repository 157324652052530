import React from "react";
import { connect } from "react-redux";

import { bindInstance } from "redux-mvc";

import {
  Div,
  Popover,
  ReplyIcon,
  PeopleIcon,
  RightIcon,
  CheckIcon,
  CloseIcon,
  EditIcon,
  SmallShadedBox,
  LoadingIcon,
  RemoveIcon
} from "components/Base";
import { withProps, noop } from "utils/General";
import ApproversList from "components/Global/Approvals/ApproversList";
import * as STANDARD_MODULE_IDS from "@lennd/value-types/src/constants/standard-modules";

import { actions } from "ApproversLabel";
import { getLoadingApproval, getManifest } from "ApproversLabel/selectors";
import { canEventUserDo } from "redux/modules/permissions/user-permission-profile/selectors";

const UndoIcon = withProps({
  color: "orange6",
  size: 20
})(ReplyIcon);

const ApproverDetailsIcon = withProps({
  color: "gray7",
  size: 20
})(PeopleIcon);

const EditItemsIcon = withProps({
  color: "gray7",
  size: 20
})(EditIcon);

const ApproveIcon = withProps({
  color: "green6",
  pill: true,
  bg: "green1",
  OnlyIcon: CheckIcon
})(SmallShadedBox);

const DenyIcon = withProps({
  color: "red6",
  pill: true,
  bg: "red1",
  OnlyIcon: CloseIcon
})(SmallShadedBox);

const RemoveItemsIcon = withProps({
  color: "red6",
  pill: true,
  bg: "white",
  OnlyIcon: RemoveIcon,
  style: {
    border: "1px solid rgb(250, 82, 82)"
  }
})(SmallShadedBox);

const RightArrowIcon = withProps({
  color: "gray7",
  size: 20
})(RightIcon);

const LoadingOptionsIcon = withProps({
  color: "gray5"
})(LoadingIcon);

const ViewApprovers = ({ manifest, isLast }) => (
  <Popover
    innerSpanStyle={{
      width: "100%"
    }}
    Label={({ onClick }) => {
      return (
        <MenuItem
          {...{
            action: onClick,
            LeftIcon: ApproverDetailsIcon,
            label: "Approver Details",
            RightIcon: RightArrowIcon,
            isLast
          }}
        />
      );
    }}
  >
    {({}) => {
      return (
        <Div>
          <ApproversList
            {...{
              ...manifest
            }}
          />
        </Div>
      );
    }}
  </Popover>
);

const MenuItem = ({ action, LeftIcon, label, RightIcon, isLast }) => (
  <Div
    bb={isLast ? 0 : 1}
    bc="gray0"
    onClick={action}
    width={1}
    display="row.flex-start.center"
    py={2}
    px={2}
    fw={3}
    fs={2}
    color="gray7"
    height={45}
    bg={{
      default: "white",
      hover: "neutral0"
    }}
  >
    {LeftIcon ? (
      <Div
        width={24}
        height={24}
        display="row.center.center"
        mr={2}
        style={{
          flexShrink: 0
        }}
      >
        <LeftIcon />
      </Div>
    ) : null}
    <Div flex={1} display="row.flex-start.center">
      {label}
    </Div>
    {RightIcon ? <RightIcon ml={1} /> : null}
  </Div>
);

const decorate = connect(
  (state, props) => ({
    manifest: getManifest(state, { ...props, id: props.manifestId }),
    loading: getLoadingApproval(state, { ...props, id: props.manifestId }),
    canDo: canEventUserDo(state, props)
  }),
  bindInstance({
    fetchManifest: actions.fetchManifest,
    onUndoReview: actions.onUndoReview,
    onApprove: actions.onApprove,
    onDeny: actions.onDeny,
    onRemove: actions.onRemove
  })
);

const ApproversMenu = props => {
  const {
    type,
    recordIds,
    loading = true,
    manifest,
    closeMenu = noop,
    onUndoReview = noop,
    onEdit = noop,
    onApprove = noop,
    onDeny = noop,
    onRemove = noop,
    canDo = noop
  } = props;
  const items = [];

  const actionWrapper = fn => () => {
    fn(recordIds);
    closeMenu();
  };

  const approvalActionWrapper = fn => () => {
    fn({ type, recordIds });
    closeMenu();
  };

  if (loading) {
    items.push({
      key: "loading",
      label: (
        <Div display="row.center.center" width={1}>
          <LoadingOptionsIcon />
        </Div>
      )
    });
  } else {
    if (
      type === "lineItem" &&
      onEdit !== noop &&
      canDo(`${STANDARD_MODULE_IDS.credentials.id}_update_line_items`)
    ) {
      items.push({
        key: "edit",
        label: "Edit Item(s)",
        action: actionWrapper(onEdit),
        LeftIcon: EditItemsIcon
      });
    }
    items.push({
      key: "approvers",
      Item: withProps({
        manifest
      })(ViewApprovers)
    });
    if (manifest.approver_reviewed) {
      items.push({
        key: "undo",
        label: "Undo review",
        action: approvalActionWrapper(onUndoReview),
        LeftIcon: UndoIcon
      });
    }
    if (manifest.is_approver && !manifest.approver_reviewed) {
      items.push({
        key: "approve",
        label: "Approve",
        action: approvalActionWrapper(onApprove),
        LeftIcon: ApproveIcon
      });
      items.push({
        key: "deny",
        label: "Deny",
        action: approvalActionWrapper(onDeny),
        LeftIcon: DenyIcon
      });
    }
    if (
      type === "lineItem" &&
      onRemove !== noop &&
      canDo(`${STANDARD_MODULE_IDS.credentials.id}_update_line_items`)
    ) {
      items.push({
        key: "remove",
        label: "Remove Line",
        action: actionWrapper(onRemove),
        LeftIcon: RemoveItemsIcon
      });
    }
  }

  return (
    <Div width={190}>
      {items.map(({ Item, key, ...props }, idx) => {
        const isLast = idx === items.length - 1;
        return Item ? (
          <Item key={key} isLast={isLast} />
        ) : (
          <MenuItem key={key} isLast={isLast} {...props} />
        );
      })}
    </Div>
  );
};

export default decorate(ApproversMenu);
