import PropTypes from "prop-types";
import React, { Component } from "react";
import autobind from "autobind-decorator";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FormSubmit extends Component {
  constructor(props) {
    super(props);
    this.state = { animate: "" };
  }

  @autobind
  showSubmit(done) {
    this.setState({ animate: "animate" }, () => {
      setTimeout(() => {
        this.setState({ animate: "success" }, () => {
          setTimeout(() => {
            done();
          }, 1000);
        });
      }, 2000);
    });
  }

  render() {
    return (
      <button
        className={`animated-button ${this.state.animate}`}
        styleName="sendResponseFooter"
        type="submit"
      >
        <div className="text">Submit</div>
        <div className="fill" />
        <div className="success">
          <i className="material-icons">check</i>
        </div>
      </button>
    );
  }
}

export default FormSubmit;
