import getValue from "utils/value-types/get-value/catering";
import { sumBy } from "lodash";

export default function(value, days) {
  return getValue(value).meals.reduce((counts, meal) => {
    counts[meal.id] =
      sumBy(meal.dates.filter(d => days.includes(d.date)), "quantity") +
      (counts[meal.id] || 0);
    return counts;
  }, {});
}
