import { combineReducers } from "redux";
import { RECEIVE, REQUEST } from "./constants";
import { ERROR } from "redux/modules/errors/constants";

const defaultState = {
  comments: [],
  fields: {
    fields: [],
    starred_fields: [],
    field_groups: []
  },
  module: {},
  related_modules: [],
  form: {
    event: {},
    fields: [],
    preferences: {}
  }
};

const record = (state = defaultState, action) => {
  switch (action.type) {
    case RECEIVE:
      return action.payload;
    default:
      return state;
  }
};

const fetching = (state = false, action) => {
  switch (action.type) {
    case REQUEST:
      return true;
    case ERROR:
      return false;
    case RECEIVE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  record,
  fetching
});
