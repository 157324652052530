import React from "react";
import { connect } from "react-redux";
import { getters } from "../model";

import { Div } from "components/Base";
import LoadingOverlay from "ui-kit/LoadingOverlay";

import Toolbar from "./Toolbar";
import ActivityList from "./List";

const decorate = connect(state => ({
  loading: getters.loading(state)
}));

const Layout = ({ loading }) => (
  <Div
    width={1}
    height={1}
    display="column.flex-start.stretch"
    style={{ position: "relative", minHeight: "400px" }}
  >
    <Toolbar />
    {loading ? <LoadingOverlay transparent /> : <ActivityList />}
  </Div>
);

export default decorate(Layout);
