import { put, all, takeEvery, fork, select, call } from "redux-saga/effects";
import { actions, getters } from "HealthPass/HealthPassForm/model";
import { getQuestionsFieldsAndValues } from "HealthPass/HealthPassForm/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import {
  userId as getUserId,
  user as getUser
} from "redux/modules/user/selectors";

import { registerError } from "redux/modules/errors/actions";
import Api from "HealthPass/HealthPassForm/api";

const getParams = function*() {
  const formData = yield select(getters.formData);
  const linkId = yield select(getters.linkId);
  const userId = yield select(getUserId);

  return {
    userId,
    eventId: formData.event_id,
    linkId
  };
};

const init = function*({ payload: { linkId } }) {
  const credentials = yield select(getCredentials);
  const userId = yield select(getUserId);
  const user = yield select(getUser);

  try {
    yield put(actions.setLinkId(linkId));

    const { payload } = yield call(Api.getLink, {
      credentials,
      linkId
    });

    const userEmail = user && user.email ? user.email : null;

    yield put(
      actions.seedFormData({
        userEmail,
        formPayload: payload,
        isLoggedIn: Boolean(userId),
        questionsValues: {}
      })
    );
    yield put(actions.setLoading(false));
  } catch (e) {
    yield put(actions.setNotFound(true));
    yield put(actions.setLoading(false));
  }
};

const saveValues = function*() {
  const { linkId } = yield call(getParams);
  const values = yield select(getQuestionsFieldsAndValues);
  const credentials = yield select(getCredentials);

  yield put(actions.setError(""));
  yield put(actions.setSaving(true));

  try {
    const { payload } = yield call(Api.saveSubmission, {
      credentials,
      linkId,
      data: {
        values
      }
    });

    window.location = `/health-pass/${linkId}/status`;
  } catch (e) {
    yield put(actions.setSaving(false));

    yield put(
      registerError([
        {
          system: e,
          user: "An error occurred saving submission"
        }
      ])
    );
  }
};

const nextPage = function*() {
  const activeIndex = yield select(getters.activeIndex);

  if (activeIndex < 1) {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    yield put(actions.setActiveIndex(activeIndex + 1));
  } else {
    yield call(saveValues);
  }
};

const backPage = function*() {
  const activeIndex = yield select(getters.activeIndex);

  if (activeIndex > 0) {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    yield put(actions.setActiveIndex(activeIndex - 1));
  }
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const watchNextPage = function*() {
  yield takeEvery(actions.nextPage.type, nextPage);
};

const watchBackPage = function*() {
  yield takeEvery(actions.backPage.type, backPage);
};

const rootSaga = function*() {
  yield all([fork(watchInit), fork(watchNextPage), fork(watchBackPage)]);
};

export default rootSaga;
