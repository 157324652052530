import React from "react";
import * as R from "ramda";
import { Link } from "react-router";

import { MODES } from "Items/Manage/constants";

import {
  Div,
  Text0,
  Text4,
  Text2,
  MediumOutlineButton,
  HalfSizeDotIcon,
  DownFilledIcon
} from "components/Base";
import { withProps } from "utils/General";

const ArrowIcon = withProps({
  mt: 3
})(DownFilledIcon);

import { PassesTable, COLUMNS } from "./PassesTable";
import BulkActionsButton from "./BulkActionsButton";

import ArrayLayout from "ui-kit/ArrayLayout";
import ProgressBar from "ui-kit/ProgressBar";

import CollapsablePanel from "ui-kit/CollapsiblePanel";

export const ACTION_CELLS = {
  [MODES.REVIEW]: "status",
  [MODES.ISSUANCE]: "issueActions"
};

const Header = ({ title, color, caption, stats }) => (
  <Div display="column.flex-start.flex-start" ml={4} my={4}>
    <Div mb={4} display="row.flex-start.center">
      <HalfSizeDotIcon
        mr={1}
        style={{ color: color || "#ccc", marginLeft: "-4px" }}
      />
      <Text4 bold>{title}</Text4>
      <Div bg="neutral0" ml={3} bra={1}>
        <Div
          fs={0}
          uppercase
          fw={3}
          p={1}
          style={{
            letterSpacing: ".3px"
          }}
        >
          {caption}
        </Div>
      </Div>
    </Div>
    <Div display="row.flex-start.center">
      {R.map(
        stat => (
          <Div display="column.flex-start.flex-start" key={stat.name} mr={7}>
            <Text0 uppercase bold mb={1}>
              {stat.name}
            </Text0>
            {stat.name === "progress" ? (
              <Div display="row.flex-start.center" width={130}>
                <ProgressBar progress={stat.value / stat.total} />
                <Text0 bold ml={2}>
                  {stat.value} / {stat.total}
                </Text0>
              </Div>
            ) : (
              <Text0 bold>{stat.value}</Text0>
            )}
          </Div>
        ),
        stats
      )}
    </Div>
  </Div>
);

const ByItem = ({
  itemType = {},
  items = [],
  mode = MODES.ISSUANCE,
  expandUrl,
  handlers = {},
  allLineItemIds,
  allCredentialLineItemIds,
  allMealLineItemIds,
  instanceId = ""
}) => {
  const buttons = [
    [
      <MediumOutlineButton key="open">
        <Link to={expandUrl}>
          <Text2 bold>Expand View</Text2>
        </Link>
      </MediumOutlineButton>,
      <BulkActionsButton
        key="bulk"
        cardType="byCategory"
        mode={mode}
        handlers={handlers}
        meta={{
          allLineItemIds,
          allCredentialLineItemIds,
          allMealLineItemIds
        }}
      />
    ]
  ];

  return (
    <CollapsablePanel
      heading={
        <Header
          title={itemType.title}
          color={itemType.color}
          stats={itemType.stats}
          caption={itemType.caption}
        />
      }
      right={false}
      shadow={2}
      mb={2}
      width={1}
      display="column.flex-start.stretch"
      bg="white"
      arrowAlignment="top"
      arrowIcon={ArrowIcon}
      arrowColor="black"
      bra={2}
      collapsedDefault
    >
      <PassesTable
        instanceId={instanceId}
        columns={R.props(
          [
            ACTION_CELLS[mode],
            "quantity",
            "attendee",
            // "location",
            // "daysNeeded",
            "order"
          ],
          COLUMNS
        )}
        rows={items}
      />
      <ArrayLayout components={buttons} m={2} />
    </CollapsablePanel>
  );
};

export default ByItem;
