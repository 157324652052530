import PropTypes from "prop-types";
import React, { Component } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

class ScrollableModalBody extends Component {
  render() {
    const { maxHeight = 400, children, ...props } = this.props;
    return (
      <div styleName="container" style={{ maxHeight, ...props.style }}>
        {children}
      </div>
    );
  }
}
ScrollableModalBody.propTypes = {
  height: PropTypes.number
};

export default CSSModules(ScrollableModalBody, css);
