import React from "react";
import * as R from "ramda";
import {
  AddCircleIcon,
  Span,
  Div,
  SmallToggle,
  LockIcon,
  LockOpenIcon,
  InfoIcon,
  Dropdown,
  LoadingIcon
} from "components/Base";
import { Tabs, Content, Column, Label, Input } from "../Common";
import DatePicker from "components/Event/FormsV2/Modals/FormEditModal/Calendar";
import Wysiwyg from "components/Global/Wysiwyg";
import Approvers from "EventLight/Common/Items/Approvers/View";

import { noop } from "utils/General";

const ToggleButton = ({ onClick, label, Icon }) => (
  <Div
    onClick={onClick}
    display="row.center.center"
    height={32}
    fw={3}
    color="purple8"
    mr={3}
    bg={{
      default: "white",
      hover: "neutral0"
    }}
    style={{
      padding: "0 23px 0 18px",
      boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.15)"
    }}
  >
    <Icon mr={1} />
    {label}
  </Div>
);

const SceneBasic = ({
  isConferenceTemplate,
  title,
  internalTitle,
  closeDate,
  isOpen,
  allowToEdit,
  enableSaveAndSubmitLater = true,
  requireLogin,
  singleSubmission,
  isFormLocked,
  enableReminders,
  onToggleLockAll,
  onChangeTitle,
  onChangeInternalTitle,
  onChangeCloseDate,
  onChangeIsOpen,
  onChangeAllowToEdit,
  onChangeEnableSaveAndSubmitLater = noop,
  onChangeRequireLogin,
  onChangeSingleSubmission,
  onChangeEnableReminders,
  onBlurInternalTitle,
  onBlurTitle,
  onClickRemoveForm
}) => {
  const ButtonGroup = (
    <Div display="row.flex-start.center">
      <ToggleButton
        onClick={() => onToggleLockAll(true)}
        Icon={LockIcon}
        label="Lock all existing submissions"
      />
      <ToggleButton
        onClick={() => onToggleLockAll(false)}
        Icon={LockOpenIcon}
        label="Unlock all"
      />
    </Div>
  );

  return (
    <Column>
      <Label mb={1}>Form Title</Label>
      <Input
        value={title}
        onChange={e => onChangeTitle(e.target.value)}
        onBlur={e => onBlurTitle(e.target.value)}
        mb={6}
      />

      <Label mb={1}>Internal Form Title</Label>
      <Input
        value={internalTitle}
        onChange={e => onChangeInternalTitle(e.target.value)}
        onBlur={e => onBlurInternalTitle(e.target.value)}
        mb={6}
      />

      <Div display="row.space-between.center" mb={6}>
        <Label>Close Date</Label>

        <Div display="column.center.flex-end">
          <DatePicker value={closeDate} onChange={onChangeCloseDate} />

          <Div fs={1} color="neutral6">
            If set, form and submissions will close after specified date
          </Div>
        </Div>
      </Div>

      {closeDate ? (
        <Div display="row.space-between.center" mb={6}>
          <Label>
            <Div display="row.flex-start.center">
              Enable Reminders{" "}
              <InfoIcon
                ml={1}
                color="gray5"
                tooltip="Remind contacts assigned to this form to submit their response. Automated email reminders will be sent in the week before the due date."
              />
            </Div>
          </Label>

          <Div display="column.center.flex-end">
            <SmallToggle
              active={enableReminders}
              onClick={() => onChangeEnableReminders(!enableReminders)}
            />
          </Div>
        </Div>
      ) : null}

      <Div display="row.space-between.center" mb={4}>
        <Label>Accepting Submissions</Label>

        {closeDate ? (
          isFormLocked ? (
            <Div display="column.center.flex-end">
              <Div fw={3} color="red7">
                Form Closed
              </Div>
              <Div fs={1} color="neutral6">
                Form is closed because close date has passed
              </Div>
            </Div>
          ) : (
            <Div display="column.center.flex-end">
              <Div fw={3} color="green7">
                Form Open
              </Div>
              <Div fs={1} color="neutral6">
                Form will be closed when close date passes
              </Div>
            </Div>
          )
        ) : (
          <Div display="row.flex-start.center">
            {isOpen ? (
              <Div fw={3} color="green7" mr={3}>
                Form Open
              </Div>
            ) : (
              <Div fw={3} color="red7" mr={3}>
                Form Closed
              </Div>
            )}
            <SmallToggle
              active={isOpen}
              onClick={() => onChangeIsOpen(!isOpen)}
            />
          </Div>
        )}
      </Div>

      {!isFormLocked ? (
        <Div
          bg="green0"
          p={3}
          bra={1}
          mb={6}
          style={{
            border: "1px solid rgba(0, 193, 124, 0.28)"
          }}
        >
          <Div mb={2}>
            This form <Span fw={3}>is accepting</Span> new submissions
          </Div>
          {ButtonGroup}
        </Div>
      ) : (
        <Div
          p={3}
          bra={1}
          mb={6}
          style={{
            backgroundColor: "rgba(233, 147, 134, .12)",
            border: "1px solid rgba(233, 147, 134, .33)"
          }}
        >
          <Div display="row.flex-start.center" mb={2}>
            <AddCircleIcon
              mr={1}
              style={{
                transform: "rotate(45deg)"
              }}
            />
            This form <Span fw={3}>&nbsp;is not accepting&nbsp;</Span> new
            submissions
          </Div>
          {ButtonGroup}
        </Div>
      )}

      <Div display="row.space-between.center" mb={8}>
        <Label>
          <Div display="row.flex-start.center">
            Allow users to edit submission after submitting{" "}
            <InfoIcon
              ml={1}
              color="gray5"
              tooltip="Toggling this will impact all future submissions but will not impact submissions already submitted"
            />
          </Div>
        </Label>
        <SmallToggle
          active={allowToEdit}
          onClick={() => onChangeAllowToEdit(!allowToEdit)}
        />
      </Div>
      <Div display="row.space-between.center" mb={8}>
        <Label>
          <Div display="row.flex-start.center">
            Allow users to save and submit later{" "}
            <InfoIcon
              ml={1}
              color="gray5"
              tooltip="Toggling this will impact all future submissions but will not impact submissions already submitted"
            />
          </Div>
        </Label>
        <SmallToggle
          active={enableSaveAndSubmitLater}
          onClick={() =>
            onChangeEnableSaveAndSubmitLater(!enableSaveAndSubmitLater)
          }
        />
      </Div>

      {isConferenceTemplate ? null : (
        <Div display="row.space-between.center" mb={8}>
          <Label>
            <Div display="row.flex-start.center">
              Require login to fill out form{" "}
              <InfoIcon
                ml={1}
                color="gray5"
                tooltip="Requires the user to set a password before filling out a form. This will also give the user access to their own portal."
              />
            </Div>
          </Label>
          <SmallToggle
            active={requireLogin}
            onClick={() => onChangeRequireLogin(!requireLogin)}
          />
        </Div>
      )}

      <Div display="row.space-between.center" mb={8}>
        <Label>
          <Div display="row.flex-start.center">
            Only allow one submission on portals
            <InfoIcon
              ml={1}
              color="gray5"
              tooltip="Restrict users to only submitting a form once."
            />
          </Div>
        </Label>
        <SmallToggle
          active={singleSubmission}
          onClick={() => onChangeSingleSubmission(!singleSubmission)}
        />
      </Div>

      <Div
        display="row.flex-start.center"
        color="red6"
        fw={3}
        onClick={onClickRemoveForm}
      >
        <AddCircleIcon
          mr={2}
          color="red6"
          style={{
            transform: "rotate(45deg)"
          }}
        />
        Delete form
      </Div>
    </Column>
  );
};

const SceneCommunication = ({
  confirmationMessage,
  //
  onChangeConfirmationMessage,
  onBlurConfirmationMessage,
  //
  sendConfirmationEmail,
  confirmationEmailMessage,
  onChangeSendConfirmationEmail,
  onChangeConfirmationEmailMessage,
  onBlurConfirmationEmailMessage
}) => {
  return (
    <Column>
      <Div display="column" mb={8}>
        <Label mb={2}>
          <Div display="row.flex-start.center">
            Confirmation Page Message{" "}
            <InfoIcon
              ml={1}
              color="gray5"
              tooltip="The custom message shown after a submission is submitted"
            />
          </Div>
        </Label>
        <Wysiwyg
          text={confirmationMessage}
          placeholder="The user will see this confirmation after submitting the form..."
          onChange={onChangeConfirmationMessage}
          onBlur={onBlurConfirmationMessage}
        />
      </Div>

      <Div display="column" mb={8}>
        <Div display="row.space-between.center">
          <Label mb={2}>
            <Div display="row.flex-start.center">
              Send Confirmation Email{" "}
              <InfoIcon
                ml={1}
                color="gray5"
                tooltip="Send an email to the submitter with a custom message and a link to view the submission"
              />
            </Div>
          </Label>
          <SmallToggle
            active={sendConfirmationEmail}
            onClick={() =>
              onChangeSendConfirmationEmail(!sendConfirmationEmail)
            }
          />
        </Div>
        {sendConfirmationEmail ? (
          <Wysiwyg
            text={confirmationEmailMessage}
            placeholder="Default message: Thank you for your submission. You can view your submission by clicking the button below."
            onChange={onChangeConfirmationEmailMessage}
            onBlur={onBlurConfirmationEmailMessage}
          />
        ) : null}
      </Div>
    </Column>
  );
};

const SceneAdvanced = ({
  formScope,
  scopeRecordTypeId,
  accountRecordTypes,
  contactRecordTypes,
  onChangeScopeRecordTypeId,
  onChangeSubmitterRecordTypeId,
  submitterRecordTypeId,
  addSubmitterToAccount,
  addSubmitterAsPrimaryContact,
  onChangeAddSubmitterToAccount,
  onChangeAddSubmitterAsPrimaryContact
}) => {
  return (
    <Column>
      <Div bg="gray1" bra={2} p={3} mb={5}>
        This form is configured to collect information from{" "}
        {formScope === "account" ? (
          <b>groups</b>
        ) : formScope === "contact" ? (
          <b>people</b>
        ) : (
          <b>people and groups</b>
        )}
      </Div>

      <Div mb={5}>
        <Label mb={1}>What type of person should this form create?</Label>
        <Dropdown
          key={contactRecordTypes.length}
          options={contactRecordTypes}
          selected={submitterRecordTypeId}
          onChange={onChangeSubmitterRecordTypeId}
        />
      </Div>

      {["account"].includes(formScope) ? (
        <>
          <Div mb={5}>
            <Label mb={1}>What type of group should this form create?</Label>
            <Dropdown
              key={accountRecordTypes.length}
              options={accountRecordTypes}
              selected={scopeRecordTypeId}
              onChange={onChangeScopeRecordTypeId}
            />
          </Div>
          <Div mb={5}>
            <Label mb={1}>
              Add submitting person as a member of the group?
            </Label>
            <SmallToggle
              active={addSubmitterToAccount}
              onClick={() =>
                onChangeAddSubmitterToAccount(!addSubmitterToAccount)
              }
            />
          </Div>
          {addSubmitterToAccount ? (
            <Div mb={5}>
              <Label mb={1}>
                Add submitting person as group's primary contact?
              </Label>
              <SmallToggle
                active={addSubmitterAsPrimaryContact}
                onClick={() =>
                  onChangeAddSubmitterAsPrimaryContact(
                    !addSubmitterAsPrimaryContact
                  )
                }
              />
            </Div>
          ) : null}
        </>
      ) : null}
    </Column>
  );
};

const View = ({ loading, tabs, activeRoute, moduleId, ...props }) => {
  return (
    <Div>
      <Tabs tabs={tabs} />
      <Content>
        <Div>
          {loading ? (
            <Div my={10} display="row.center.center">
              <LoadingIcon size={30} color="gray5" />
            </Div>
          ) : (
            R.prop(activeRoute, {
              ModuleFormSettings: <SceneBasic {...props} />,
              ModuleFormSettingsCommunication: (
                <SceneCommunication {...props} />
              ),
              ModuleFormSettingsApprovers: <Approvers moduleId={moduleId} />,
              ModuleFormSettingsAdvanced: <SceneAdvanced {...props} />
            })
          )}
        </Div>
      </Content>
    </Div>
  );
};

export default View;
