import React from "react";
import * as R from "ramda";
import { basePropTypesOnly } from "../Base/index";
import { pure, deepMapFlat, sortByPickers, multiDimCond } from "../utils";
import { sizes, styles, functionalities } from "./BoxSpec";

const containerPassedPropKeys = [
  "bg",
  "color",
  "m",
  "mx",
  "my",
  "mr",
  "ml",
  "mt",
  "mb",
  "pill",
  "flex",
  "display",
  "uppercase",
  "isPadded",
  "bold",
  "disabled",
  "raised",
  "width",
  // discouraged
  "style",
  "styleName",
  "className",
  "title",
  "type",
  // for box group @TODO: somehow take this out
  "brr",
  "brl",
  "bl",
  "data-cy",
  "data-path",
  "hover"
];

const isEmptyStringSetUndefined = x => (x === "" ? undefined : x);

const GeneralFormatter = (
  Comp,
  functionalityPassedPropKeys = [],
  enabledStates = [],
  isPadded
) => (sizeProps, styleFunc) => ({
  OnlyIcon,
  LeftIcon,
  RightIcon,
  children,
  leftIconColor,
  rightIconColor,
  bigIcons,
  ...other
}) => {
  // input destructuring
  const [functionalityPassedProps, containerPassedProps] = sortByPickers([
    functionalityPassedPropKeys,
    containerPassedPropKeys
  ])(other);

  const {
    isPadded: isPaddedOverride,
    bold,
    disabled,
    raised,
    style,
    ...otherContainerPassedProps
  } = containerPassedProps;

  const { color, bg, bc, shadow } = styleFunc(
    R.pick(disabled ? ["default"] : [...enabledStates, "default"])
  )({
    color: isEmptyStringSetUndefined(containerPassedProps.color),
    bg: isEmptyStringSetUndefined(containerPassedProps.bg),
    hover: containerPassedProps.hover
  });

  const {
    iconSize,
    fs,
    fw,
    h,
    ba,
    bra,
    insideIconSpacing,
    outsideIconSpacing,
    textSpacing,
    extraSpacing
  } = sizeProps;

  // Spacing Diagram:
  // |extraPadding|outsideIconSpacing|<icon>|insideIconSpacing|<text>|textSpacing|extraPadding|
  // (extraPadding is toggled by 'isPadded')

  const determinePadding = (Icon, OppositeIcon) => {
    if (OnlyIcon || bigIcons) {
      return 0;
    }
    let result = multiDimCond([Icon, OppositeIcon], R.always(0), {
      T: R.always(outsideIconSpacing),
      F: R.always(textSpacing)
    });
    if (R.isNil(isPaddedOverride) ? isPadded : isPaddedOverride) {
      result += extraSpacing;
    }
    return result;
  };
  containerPassedPropKeys.isPadded = undefined;

  let appliedShadow = shadow;
  if (R.isNil(shadow)) {
    if (raised) {
      if (!disabled) {
        appliedShadow = { default: 2, hover: 3 };
      } else {
        appliedShadow = 2;
      }
    } else {
      appliedShadow = 0;
    }
  }

  // output structuring
  const containerProps = {
    ...otherContainerPassedProps,
    transition: "fast",
    noWrap: true,
    ba,
    bc,
    bg,
    bra,
    color,
    fs,
    fw: bold ? fw + 1 : fw,
    pr: determinePadding(RightIcon, LeftIcon),
    pl: determinePadding(LeftIcon, RightIcon),
    shadow: appliedShadow,
    h,
    disabled,
    style: {
      ...style,
      ...(disabled && { opacity: 0.5 })
    },
    ...(OnlyIcon && { w: h })
  };

  // the 4 below is the magic scaling constant, see getStyles @TODO: pull this out somehow
  const baseIconProps = bigIcons
    ? { color, size: (h - ba / 2) * 4 }
    : { color, size: iconSize };

  return (
    <Comp
      onlyIconProps={baseIconProps}
      leftIconProps={{
        ...baseIconProps,
        color: leftIconColor || "inherit",
        mr: insideIconSpacing
      }}
      rightIconProps={{
        ...baseIconProps,
        color: rightIconColor || "inherit",
        ml: insideIconSpacing
      }}
      {...{
        containerProps,
        LeftIcon,
        RightIcon,
        OnlyIcon,
        children,
        ...functionalityPassedProps
      }}
    />
  );
};

// //////////////////////////////////////////////////////////////////////////////

const BoxMaker = R.compose(
  R.fromPairs,
  R.values,
  R.map(({ key, value }) => [key, value]),
  deepMapFlat
);

const boxes = BoxMaker(
  R.map(R.toPairs, [sizes, styles, functionalities]),
  ([
    [[sizeName, size]],
    [[styleName, style]],
    [[funcName, { func, propTypes, defaultProps, enabledStates, isPadded }]]
  ]) => {
    const result = GeneralFormatter(
      func,
      R.keys(propTypes),
      enabledStates,
      isPadded
    )(size, style);
    result.propTypes = {
      ...basePropTypesOnly(containerPassedPropKeys),
      ...propTypes
    };
    result.defaultProps = defaultProps;

    return {
      key: `${sizeName}${styleName}${funcName}`,
      value: pure(result)
    };
  }
);

R.forEach(([name, func]) => {
  exports[name] = func;
}, R.toPairs(boxes));

// for story only
export { boxes };
