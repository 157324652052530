import React from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";

import { getters, actions } from "ui-kit/FilterControls";
import { actions as MiniItemsActions } from "ui-kit/MiniItemsSelector";

import FiltersOnTag from "ui-kit/FiltersOnTag";

import { Div, Text2, FontIcon, CancelIcon } from "components/Base";
import { noop } from "utils/General";

import CollapsiblePanel from "ui-kit/CollapsiblePanel";

const Heading = ({ on = false, label }) => (
  <Div display="row.center.center" py={2} flex={1}>
    <Div flex={1} display="row.flex-start.center" pl={3}>
      {label}
    </Div>
    <FiltersOnTag on={on} />
  </Div>
);

const Sorter = ({
  id = "",
  name = "",
  order = "",
  onRemove = noop,
  ...styleProps
}) => (
  <Div
    bg="neutral1"
    bra={1}
    p={1}
    display="row.flex-start.center"
    style={{
      cursor: "pointer"
    }}
    {...styleProps}
  >
    <Text2 flex={1} bold>
      {name}
    </Text2>
    <Text2 bold>{order}</Text2>
    <CancelIcon
      onClick={e => {
        e.stopPropagation();
        onRemove(id);
      }}
      ml={2}
    />
  </Div>
);

const decorate = connect(
  (state, props) => ({
    showModal: getters.showModal(state, props)
  }),
  bindInstance({
    hideModal: actions.hideModal,
    onEdit: actions.showModal,
    onDone: actions.applyFilters,
    onClear: MiniItemsActions.erase
  })
);

const sortBy = [
  // @NOTE: Mocked data
  { id: "date", name: "Date Created", order: "1->9" },
  { id: "name", name: "Full Name", order: "A->Z" }
];

const SortByFilter = decorate(
  ({
    style = {},
    sortFilters = sortBy,
    onEdit = noop,
    onClearSort = noop,
    onRemove = noop,
    ...styleProps
  }) => {
    return (
      <CollapsiblePanel
        heading={
          <Heading
            on={R.length(sortFilters) > 0}
            label={
              <>
                <FontIcon icon="sort_by_alpha" fs={4} mr={2} />
                <Text2 bold>Sort By</Text2>
              </>
            }
          />
        }
        bra={1}
        display="column.flex-start.stretch"
        style={{ overflow: "initial", flexShrink: 0, ...style }}
        {...styleProps}
      >
        <Sorter {...R.head(sortFilters)} m={1} onRemove={onRemove} />
        {R.map(
          sort => (
            <Div display="row.flex-start.center" m={1}>
              <Text2 mr={2} ml={1}>
                then
              </Text2>
              <Sorter {...sort} onRemove={onRemove} key={sort.id} flex={1} />
            </Div>
          ),
          R.tail(sortFilters)
        )}
        <Div
          display="row.space-between.center"
          p={2}
          style={{ cursor: "pointer" }}
        >
          <Text2 bold color="primary7" onClick={onEdit}>
            Add sort by
          </Text2>
          <Text2 bold onClick={onClearSort}>
            Clear all
          </Text2>
        </Div>
      </CollapsiblePanel>
    );
  }
);

export { SortByFilter };
