import React from "react";
import * as R from "ramda";

import { Div, MediumOutlineButton } from "components/Base";

import CollapsablePanel from "ui-kit/CollapsiblePanel";
import CSSModules from "react-css-modules";
import css from "../onboard.scss";
import { connect } from "react-redux";
import { actions, getters } from "../../model";
import { LoadingIcon } from "components/Base";
import NotConfigured from "../NotConfigured";
import { EXPERIENCE_URL } from "../../constants";

const decorate = R.compose(
  connect(
    (state, props) => ({
      guestListSettings: getters.guestListSettings(state, props),
      loadingGuestList: getters.loadingGuestList(state)
    }),
    {
      init: actions.init,
      showModal: () => actions.setShowGuestListSettingsModal(true),
      goToLearnLennd: actions.goToLearnLennd
    }
  ),
  CSSModules(css)
);

const GuestLists = ({
  guestListSettings,
  showModal,
  loadingGuestList,
  goToLearnLennd
}) => (
  <Div flex={1} display="column.flex-start.stretch">
    <CollapsablePanel
      heading={() => (
        <Div display="row.space-between.center" my={2} width={1} pr={4}>
          <div className={css.title}>Collect Guest Lists</div>
          <NotConfigured
            configured={guestListSettings.is_enabled}
            loading={loadingGuestList}
            text={`Enabled`}
          />
        </Div>
      )}
      bg="white"
      arrowColor="gray7"
      bc="neutral3"
      right={false}
      bra={1}
      mb={3}
      p={3}
      collapsedDefault={true}
    >
      <div styleName="wrapper">
        <div styleName="row">
          <div>
            <div styleName="header">
              <span class="material-icons" style={{ color: "#4ac6db" }}>
                person_add
              </span>
              <div styleName="name">Guest Lists</div>
            </div>
            <div styleName="description">
              Allow groups of this type to add guests along with passes, meals
              or assets based on what you've allocated to them
            </div>
          </div>
          {loadingGuestList ? (
            <div styleName="loading-wrapper">
              <LoadingIcon color="gray3" size={32} />
            </div>
          ) : (
            <div styleName="actions">
              <MediumOutlineButton
                color="primary7"
                bc="primary7"
                mr={2}
                LeftIcon={() => (
                  <span
                    class="material-icons"
                    style={{ fontSize: 12, marginRight: 8, height: 12 }}
                  >
                    border_color
                  </span>
                )}
                width={100}
                onClick={showModal}
              >
                Settings
              </MediumOutlineButton>
              <MediumOutlineButton
                color="primary7"
                bc="primary7"
                LeftIcon={() => (
                  <span
                    class="material-icons"
                    style={{ fontSize: 16, marginRight: 8, height: 16 }}
                  >
                    link
                  </span>
                )}
                onClick={() =>
                  goToLearnLennd({
                    expectedExperienceUrl: EXPERIENCE_URL.collect_guest_lists
                  })
                }
              >
                Learn More
              </MediumOutlineButton>
            </div>
          )}
        </div>
      </div>
    </CollapsablePanel>
  </Div>
);

export default decorate(GuestLists);
