import * as R from "ramda";

import { put, all, select, call } from "redux-saga/effects";

import { actions } from "Schedules/AllowAccessUsersModal";

import { getCredentials } from "redux/modules/user/selectors";

import api from "./api";
import { registerError } from "redux/modules/errors/actions";
import { eventId as getEventId } from "redux/modules/event/selectors";

const loadUsersAndProfiles = function*() {
  const event = yield select(getEventId);
  const credentials = yield select(getCredentials);

  yield put(actions.setLoading(true));

  try {
    const [usersPayload] = yield all([call(api.getUsers, credentials, event)]);

    yield put(
      actions.usersAndProfilesResponse({
        usersList: R.compose(
          R.filter(R.prop("is_event_user")),
          R.propOr([], "payload")
        )(usersPayload),
        profilesList: []
      })
    );
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error occurred fetching users and profiles"
        }
      ])
    );
  }
  yield put(actions.setLoading(false));
};

const rootSaga = function*() {
  yield call(loadUsersAndProfiles);
};

export default rootSaga;
