import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Controller from "./Controller";
import exportFactory from "components/Event/Reports/utils/export-factory";
import { map, compose, filter, prop } from "ramda";

import { showModal, hideModal } from "redux/modules/modal/actions";
import { getReport } from "redux/modules/reports/report/actions";

import { report, fields, rows } from "redux/modules/reports/report/selectors";

const filterVisible = filter(prop("visible"));

const formatField = field => {
  if (field.columns) {
    return {
      Header: field.header,
      columns: compose(
        map(f => ({
          id: f.id,
          Header: f.name,
          name: f.name,
          export_name: f.export_name,
          accessor: d => d[f.id],
          width: f.width || 230
        })),
        filterVisible
      )(field.columns)
    };
  }

  return {
    id: field.id,
    Header: field.name,
    name: field.name,
    export_name: field.export_name,
    accessor: d => d[field.id],
    width: field.width || 230
  };
};

function mapStateToProps(state, props) {
  const reportId = props.params.reportId;
  const fieldsToPass = compose(map(formatField), filterVisible)(
    fields(state, reportId)
  );
  const rowsToPass = rows(state, reportId);

  return {
    reportId,
    report: report(state, reportId),
    fields: fieldsToPass,
    rows: rowsToPass
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      showModal,
      hideModal,
      getReport
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withRouter(exportFactory(Controller))
);
