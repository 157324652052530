import React from "react";
import { Div } from "components/Base";
import { connect } from "react-redux";
import { getSelectedAccount } from "../selectors";

const AddItemsInfo = ({ handlers }) => (
  <Div
    bra={1}
    bg="neutral0"
    p={4}
    mt={5}
    display="column.flex-start.center"
    textAlign="center"
  >
    <div>
      <span style={{ fontWeight: "bold" }}>
        To add items or create an order - first search your existing database.
      </span>{" "}
      If your person or group doesn't exist yet - you can quickly create their
      order.
    </div>
    <Div display="row.flex-start.center" mt={4}>
      <Div
        width={148}
        height={38}
        bra={1}
        ba={1}
        bc="primary7"
        color="primary7"
        display="row.center.center"
        bg={{
          default: "neutral0",
          hover: "neutral1"
        }}
        onClick={() => {
          handlers.showAddContactModal({
            isLastStep: false
          });
        }}
      >
        Add New Person
      </Div>
      <Div
        width={148}
        height={38}
        ml={2}
        bra={1}
        ba={1}
        bc="primary7"
        color="primary7"
        display="row.center.center"
        bg={{
          default: "neutral0",
          hover: "neutral1"
        }}
        onClick={() => {
          handlers.showAddAccountModal({
            isLastStep: false
          });
        }}
      >
        Add New Group
      </Div>
    </Div>
  </Div>
);

export default connect(state => ({
  selectedAccount: getSelectedAccount(state)
}))(AddItemsInfo);
