import PropTypes from "prop-types";
import React, { cloneElement } from "react";
import ReactCSSTransitionReplace from "react-css-transition-replace";
import { get } from "lodash";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Manager = ({ current, scenes, direction }) => (
  <ReactCSSTransitionReplace
    transitionName={`manager-carousel-swap-${direction}`}
    transitionEnterTimeout={200}
    transitionLeaveTimeout={200}
  >
    {cloneElement(get(scenes, current, <span />), { key: current })}
  </ReactCSSTransitionReplace>
);

Manager.propTypes = {
  direction: PropTypes.string.isRequired,
  current: PropTypes.string.isRequired,
  scenes: PropTypes.object.isRequired
};

export default CSSModules(Manager, css);
