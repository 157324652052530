import React from "react";
import * as R from "ramda";
import {
  Div,
  FontIcon,
  SearchIcon,
  BigFilledButton,
  BigShadedInput,
  Text2,
  Text3,
  PopoverMenu,
  TinyOutlineButton,
  makeTable,
  SmallAvatar
} from "components/Base";
import Tooltip from "components/Global/Tooltip";
import CopyToClipboard from "react-copy-to-clipboard";
import { withProps, noop } from "utils/General";
import CanUserDo from "components/Global/Security/CanUserDo";
import * as STANDARD_MODULES from "@lennd/value-types/src/constants/standard-modules";

import CSSModules from "react-css-modules";
import css from "components/Event/Settings/Module/tableStyles.scss";

import { connect } from "react-redux";
import { withRouter } from "react-router";

import { showModal, hideModal } from "redux/modules/modal/actions";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { getEvents } from "redux/modules/events/actions";
import { actions } from "../model";
import { getUsers } from "../selectors";

import { user as getUser } from "redux/modules/user/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";

import ManageEventActions from "actions/Event/DetailsActions";

import RemoveEventTeamUserModal from "components/Global/Modals/RemoveEventTeamMemberModal";
import EditCollaboratorInfoModal from "components/Global/Modals/EditCollaboratorInfoModal";
import LoggedInProfileActions from "actions/Global/LoggedInProfileActions";
import AddEventTeamUserModal from "components/Global/Modals/AddEventTeamMemberModal";
import PortalUsersApi from "redux/modules/portal/users/api";
import UserInfoModal from "EventLight/Team/UserInfoModal/View";
import {
  getters as UserInfoModalGetters,
  actions as UserInfoModalActions
} from "EventLight/Team/UserInfoModal/model";

const decorate = R.compose(
  withRouter,
  connect(
    state => ({
      eventDetails: getEventDetails(state),
      users: getUsers(state),
      user: getUser(state),
      showUserInfoModal: UserInfoModalGetters.showModal(state)
    }),
    {
      showModal,
      hideModal,
      showSnackbar,
      getEvents,
      getUsers: actions.getUsers,
      setSearchTerm: actions.setSearchTerm,
      openUserInfoModal: UserInfoModalActions.openModal,
      closeUserInfoModal: () => UserInfoModalActions.showModal(true)
    }
  )
);

const TableStyler = withProps({
  my: 3,
  bra: 1,
  shadow: 1
})(Div);

const HeaderStyler = withProps({
  display: "row.flex-start.center",
  bg: "neutral1",
  py: 3,
  pl: 4
})(Div);

const RowStyler = CSSModules(
  withProps({
    display: "row.flex-start.center",
    bc: "neutral2",
    bg: "white",
    bt: 1,
    pl: 4,
    pt: 2,
    pb: 2,
    styleName: "parentContainer"
  })(Div),
  css
);

const UsersTable = makeTable({
  TableStyler,
  HeaderStyler,
  RowStyler,
  HeaderCellStyler: Div,
  RowCellStyler: Div,
  columnProps: [
    { width: 4 / 12 },
    { width: 3 / 12 },
    { width: 3 / 12 },
    { width: 2 / 12 }
  ],
  headerCellComps: [
    () => <Text3 bold>Name</Text3>,
    () => <Text3 bold>Role</Text3>,
    () => <Text3 bold>Permissions</Text3>,
    () => <Text3 bold>Actions</Text3>
  ],
  rowCellComps: [
    ({ name, email, photoUrl, onView, isPending, inviteSentAt }) => (
      <Div display="row.flex-start.center" onClick={onView}>
        <SmallAvatar photoURL={photoUrl} text={name.toUpperCase()} />
        <Div display="column.center.flex-start" width={1} mr={4} ml={2}>
          <Div display="row.flex-start.center">
            <Text3 bold primary truncate>
              {name}
            </Text3>
            {isPending ? (
              <Tooltip tooltip={`Invite sent ${inviteSentAt}`}>
                <FontIcon ml={1} fs={2} color="gray6">
                  hourglass_empty
                </FontIcon>
              </Tooltip>
            ) : null}
          </Div>
          <Div fs={1} color="gray5">
            {email}
          </Div>
        </Div>
      </Div>
    ),
    ({ role }) => <Text2>{role}</Text2>,
    ({ permissionRole }) => <Text2>{permissionRole}</Text2>,
    ({ onView, onRemove, onResend, onCopy, locked, isPending, inviteLink }) => (
      <CanUserDo
        any={[
          `${STANDARD_MODULES.settings.id}_manage_users`,
          `${STANDARD_MODULES.orgSettings.id}_manage_users`
        ]}
      >
        <PopoverMenu
          Label={({ onClick }) => (
            <TinyOutlineButton onClick={onClick} py={1}>
              <FontIcon fs={3}>more_horiz</FontIcon>
            </TinyOutlineButton>
          )}
          menuItems={
            locked
              ? [["Edit", onView]]
              : [
                  ["Edit", onView],
                  ["Remove", onRemove],
                  isPending ? ["Resend invite", onResend] : null,
                  isPending && onCopy
                    ? [
                        <CopyToClipboard
                          key="copy"
                          text={inviteLink}
                          onCopy={onCopy}
                        >
                          <span>Copy invite URL</span>
                        </CopyToClipboard>,
                        noop
                      ]
                    : null
                ].filter(o => o)
          }
        />
      </CanUserDo>
    )
  ]
});

class Body extends React.Component {
  onSearch = searchTerm => {
    if (searchTerm !== this.props.searchTerm) {
      this.props.setSearchTerm(searchTerm);
    }
  };

  showEditInfoModal = record => {
    const modal = (
      <EditCollaboratorInfoModal
        hideModal={this.props.hideModal}
        userId={record.user_id}
        record={record}
        onUpdated={this.handleInfoUpdated}
        showPermissions
      />
    );

    this.props.showModal({ content: modal });
  };

  handleInfoUpdated = () => {
    this.props.getUsers(this.props.eventDetails.id);

    this.props.showSnackbar({ message: "User updated", action: "OK" });
  };

  resendInvitation = user => {
    ManageEventActions.resendInvitation(
      this.props.user.credentials,
      this.props.eventDetails.id,
      user.user_id,
      () => {
        this.props.getUsers(this.props.eventDetails.id);
        this.props.showSnackbar({
          message: `Invitation resent to ${user.user_email}`,
          action: "OK"
        });
      }
    );
  };

  handleRemoveMember = data => {
    if (this.props.users.find(u => u.user_id === data.userId).is_portal_user) {
      PortalUsersApi.delete(
        { userId: 7 },
        {
          eventId: this.props.eventDetails.id,
          userId: data.userId
        }
      ).then(() => {
        this.props.getUsers(this.props.eventDetails.id);
        this.props.showSnackbar({ message: "User removed", action: "OK" });
      });
    } else {
      ManageEventActions.removeUserFromEvent(
        this.props.user.credentials,
        this.props.eventDetails.id,
        data.userId,
        () => {
          if (data.userId === this.props.user.id) {
            // refresh events and notifications, then redirect to home
            this.props.getEvents(this.props.user.id);
            LoggedInProfileActions.receiveUserNotifications(
              this.props.user.credentials
            );
            this.props.router.push({ pathname: "/home" });

            this.props.showSnackbar({
              message: "You left the event",
              action: "OK"
            });
          } else {
            this.props.getUsers(this.props.eventDetails.id);
            this.props.showSnackbar({ message: "User removed", action: "OK" });
          }
        }
      );
    }
  };

  showRemoveFromEventModal = (userName, userId, user) => {
    const modal = (
      <RemoveEventTeamUserModal
        user={user}
        userName={userName}
        userId={userId}
        hideModal={this.props.hideModal}
        handleRemoveMember={this.handleRemoveMember}
      />
    );

    this.props.showModal({ content: modal });
  };

  showAddEventTeamUserModal = () => {
    const modal = (
      <AddEventTeamUserModal
        hideModal={this.props.hideModal}
        addToEventId={this.props.eventDetails.id}
        onAdded={this.handleEventTeamUserAdded}
      />
    );

    this.props.showModal({ content: modal });
  };

  handleEventTeamUserAdded = (data, user, action) => {
    this.props.getUsers(this.props.eventDetails.id);

    this.props.showSnackbar({
      message: action === "added" ? "User added" : "User invited",
      action: "OK"
    });
  };

  onCopy = () => {
    this.props.showSnackbar({
      message: "Copied",
      action: "OK"
    });
  };

  render() {
    const usersToUse = R.map(user => ({
      ...user,
      onCopy: this.onCopy,
      onView: () => this.props.openUserInfoModal(user.user_id),
      onRemove: () =>
        this.showRemoveFromEventModal(user.name, user.user_id, user),
      onResend: () => this.resendInvitation(user)
    }))(this.props.users);

    return (
      <Div display="row.flex-start.flex-start">
        {this.props.showUserInfoModal ? <UserInfoModal /> : null}
        <Div flex={1}>
          <CanUserDo
            any={[
              `${STANDARD_MODULES.settings}_invite_users`,
              `${STANDARD_MODULES.orgSettings}_invite_users`
            ]}
          >
            <Div display="row.flex-start.center" pb={4}>
              <BigShadedInput
                continuous
                LeftIcon={SearchIcon}
                flex={1}
                placeholder="Search users"
                onChange={this.onSearch}
              />
              <BigFilledButton
                bg="altB5"
                ml={1}
                onClick={this.showAddEventTeamUserModal}
              >
                Invite user
              </BigFilledButton>
            </Div>
          </CanUserDo>
          <UsersTable headerData={{}} rowsData={usersToUse} />
        </Div>
      </Div>
    );
  }
}

export default decorate(Body);
