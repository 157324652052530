import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const RequiredNotice = () => (
  <div styleName="container">
    <div>You must add at least one person.</div>
    <i className={`material-icons ${css.icon}`}>arrow_upward</i>
  </div>
);

export default CSSModules(RequiredNotice, css);
