import React from "react";
import { connect } from "react-redux";

import {
  getOrderId,
  getPaymentsEnabled,
  getCanAcceptPayment,
  getInvoiceStats,
  getAttendee
} from "Orders/OrderModal/selectors";

import {
  Div,
  Text2,
  Text4,
  AddIcon,
  BigOutlineButton,
  CreditCardIcon
} from "components/Base";

import Panel from "ui-kit/Panel";

const decorate = connect(state => ({
  orderId: getOrderId(state),
  paymentsEnabled: getPaymentsEnabled(state),
  canAcceptPayment: getCanAcceptPayment(state),
  invoiceStats: getInvoiceStats(state),
  attendee: getAttendee(state)
}));

const AcceptPayment = ({
  handlers,
  orderId,
  paymentsEnabled,
  canAcceptPayment,
  invoiceStats,
  attendee
}) => {
  return (
    <Panel
      display="column"
      px={8}
      py={3}
      bg={!canAcceptPayment ? "neutral0" : "white"}
    >
      <Div display="row.space-between.center">
        <Div display="column.flex-start" width={300}>
          <Div display="row.flex-start.center">
            <CreditCardIcon size={32} mr={2} />
            <Text4 bold color={!canAcceptPayment ? "neutral5" : "gray7"}>
              Accept Payment
            </Text4>
          </Div>
          <Div display="column" ml={10}>
            <Text2 color={!canAcceptPayment ? "neutral5" : undefined}>
              Send an invoice and process payment for approved items.
            </Text2>
          </Div>
        </Div>

        <Div display="column" width={300}>
          <BigOutlineButton
            disabled={
              !canAcceptPayment ||
              !paymentsEnabled ||
              parseFloat(invoiceStats.dueRaw) <= 0
            }
            onClick={() =>
              handlers.showPaymentModal(
                orderId,
                invoiceStats.dueRaw,
                attendee.email
              )
            }
          >
            Enter credit card info
          </BigOutlineButton>
          <Div display="row.space-between" mt={2}>
            <BigOutlineButton
              disabled={!canAcceptPayment}
              leftIcon={AddIcon}
              flex={1}
              onClick={() =>
                handlers.showManualPaymentModal(
                  invoiceStats.dueRaw,
                  attendee.email
                )
              }
            >
              Manual Payment
            </BigOutlineButton>
          </Div>
        </Div>
      </Div>
    </Panel>
  );
};

export default decorate(AcceptPayment);
