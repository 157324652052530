import * as R from "ramda";
import React, { useEffect, useState, useRef, useMemo } from "react";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import moment from "moment";

import { noop, liftToArr } from "utils/General";

import EventDaysSelector from "components/Global/EventDaySelector";
import Formatter from "./Formatter";

import { PopMenu, PopMenuCard } from "components/Base";

import { WithFormInstanceConsumer } from "ui-kit/Form/View/Context";

import { actions } from "ui-kit/Form/model";
import { getValue } from "ui-kit/Form/selectors";
import { FIELD_TYPES } from "ui-kit/Form/constants";

import { eventDetails } from "redux/modules/event/selectors";

const buildDayMap = ({
  startDate,
  endDate,
  dayGroups,
  selectedDays = {},
  format = "YYYY-MM-DD"
}) => {
  const map = {};
  dayGroups.forEach(group => {
    group.days.forEach(day => {
      map[day] = {
        color: group.color,
        isSelected: R.contains(day, selectedDays)
      };
    });
  });
  for (
    let m = moment(startDate).utc();
    m.isSameOrBefore(endDate);
    m.add(1, "days")
  ) {
    const day = m.format(format);
    if (!R.has(day, map)) {
      map[day] = {
        color: "#333333",
        isSelected: R.contains(day, selectedDays)
      };
    }
  }
  return map;
};

const decorate = R.compose(
  WithFormInstanceConsumer({
    fieldType: FIELD_TYPES.EVENT_DAYS
  }),
  connect(
    (state, props) => ({
      value: getValue(state, props),
      event: eventDetails(state, props)
    }),
    bindInstance({
      onChange: actions.setFieldValue,
      setIniValue: actions.setIniValue
    })
  )
);

export const EventDays = decorate(
  ({
    fieldId,
    fieldType,
    value = [],
    iniValue,
    event,
    onChange = noop,
    setIniValue = noop,
    withPopover = true,
    containerStyle = {},
    ...props
  }) => {
    useEffect(() => {
      setIniValue(
        { only: iniValue },
        {
          meta: { fieldId, fieldType }
        }
      );
    }, []);

    const anchorEl = useRef();
    const [open, setOpen] = useState(false);

    const startDate = R.prop("date_from", event);
    const endDate = R.prop("date_to", event);
    const dayGroups = R.prop("date_groups", event);

    const dayMap = useMemo(
      () => buildDayMap({ startDate, endDate, dayGroups, selectedDays: value }),
      [startDate, endDate, dayGroups, value]
    );

    let calendarProps = { ...props };

    if (withPopover) {
      calendarProps.close = () => setOpen(false);
    } else {
      calendarProps.noShadow = true;
    }

    const calendar = (
      <EventDaysSelector
        startDate={startDate}
        endDate={endDate}
        dayGroups={dayGroups}
        dayMap={dayMap}
        showCountOfSelectedDays
        countOfSelectedDays={R.length(value)}
        handleDayClick={day =>
          onChange({ toggle: liftToArr(day) }, { meta: { fieldId, fieldType } })
        }
        handleGroupSelectAll={groupId =>
          onChange(
            {
              add: R.propOr(
                [],
                "days",
                R.find(R.propEq("id", groupId), dayGroups)
              )
            },
            { meta: { fieldId, fieldType } }
          )
        }
        handleGroupDeselectAll={groupId =>
          onChange(
            {
              remove: R.propOr(
                [],
                "days",
                R.find(R.propEq("id", groupId), dayGroups)
              )
            },
            { meta: { fieldId, fieldType } }
          )
        }
        {...calendarProps}
      />
    );

    if (withPopover) {
      return (
        <div
          ref={anchorEl}
          onClick={() => {
            setOpen(!open);
          }}
          style={containerStyle}
        >
          <Formatter dayGroups={dayGroups} days={value} />
          <PopMenu open={open} onClick={e => e.stopPropagation()}>
            <PopMenuCard>{calendar}</PopMenuCard>
          </PopMenu>
        </div>
      );
    }
    return calendar;
  }
);
