import React, { useEffect } from "react";
import * as R from "ramda";
import Loading from "ui-kit/Loading";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import Body from "./Body";

import { actions, getters } from "../model";

const decorate = connect(
  (state, props) => ({
    loading: getters.loading(state, props)
  }),
  {
    init: actions.init
  }
);

const Layout = ({ init, loading, params }) => {
  useEffect(() => {
    init({
      alertId: params.alertId
    });
  }, []);

  return loading ? <Loading /> : <Body />;
};

export default R.compose(
  withRouter,
  decorate
)(Layout);
