import PropTypes from "prop-types";
import React, { Component } from "react";
import toString from "utils/value-types/to-string/calculated-number";

export default class CalculatedNumberEditor extends Component {
  render() {
    const { settings, values, fields } = this.props;
    const result = toString(null, {
      ...settings,
      values,
      fields
    });

    return <div>{result}</div>;
  }
}

CalculatedNumberEditor.propTypes = {
  values: PropTypes.object.isRequired,
  settings: PropTypes.shape({
    calculatedNumberExpression: PropTypes.string.isRequired
  }).isRequired,
  fields: PropTypes.array.isRequired
};
