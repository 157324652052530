import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import factory from "./export";

import { showSnackbar } from "redux/modules/snackbar/actions";
import { exportSpreadsheetArray } from "redux/modules/export/actions";
import * as EventSelectors from "redux/modules/event/selectors";
import { references } from "redux/modules/entityReferences/selectors";

function mapStateToProps(state) {
  return {
    details: EventSelectors.eventDetails(state),
    references: references(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      exportSpreadsheetArray,
      showSnackbar
    },
    dispatch
  );
}

export default Child =>
  connect(mapStateToProps, mapDispatchToProps)(factory(Child));
