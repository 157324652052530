import React from "react";
import {
  BigFilledButton,
  BigOutlineButton,
  CollapsablePanelType1,
  Div,
  BigShadedInput,
  SearchIcon
} from "components/Base";

import { TypesTable } from "./Tables";

const addS = thing => (thing === 1 ? "" : "s");

const View = ({
  credentialGroups,
  onSearch,
  showAddCredentialGroupModal,
  showCredentialModal,
  blocks,
  showAddItemBlockModal,
  goToItemBlocksDoc
}) => (
  <Div display="row" px={8}>
    <Div
      width={2 / 3}
      pr={3}
      py={4}
      br={1}
      bc="gray3"
      style={{ maxWidth: 800 }}
    >
      <Div display="row.flex-start.center" pb={2}>
        <BigShadedInput
          mr={3}
          flex={1}
          placeholder="Search types"
          onChange={onSearch}
          continuous
          LeftIcon={SearchIcon}
        />
        <BigOutlineButton mr={1} onClick={showAddCredentialGroupModal}>
          Create group
        </BigOutlineButton>
        <BigFilledButton
          bg="altB5"
          color="altB2"
          onClick={e => showCredentialModal()}
        >
          Add type
        </BigFilledButton>
      </Div>
      <Div pt={4}>
        {credentialGroups.map(
          ({
            id,
            name,
            countOfCredentials,
            onEdit,
            onDelete,
            credentials,
            moveGroupUp,
            moveGroupDown,
            collapsedDefault,
            forceCollapse,
            reorderCredentials
          }) => (
            <CollapsablePanelType1
              key={`${id}_${countOfCredentials}`}
              headingText={name}
              subText={`${countOfCredentials} type${addS(countOfCredentials)}`}
              menuItems={[
                ["Edit", onEdit],
                onDelete ? ["Delete", onDelete] : undefined,
                moveGroupUp ? ["Move up", moveGroupUp] : undefined,
                moveGroupDown ? ["Move down", moveGroupDown] : undefined
              ].filter(i => i)}
              forceCollapse={forceCollapse}
              collapsedDefault={collapsedDefault}
            >
              <TypesTable
                types={credentials}
                dragTypeId={"TypesTable" + id}
                onReorder={reorderCredentials}
              />
            </CollapsablePanelType1>
          )
        )}
      </Div>
    </Div>
  </Div>
);

export default View;
