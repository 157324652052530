import React from "react";
import ReactPlayer from "react-player";

export const Video = ({ url, ...otherProps }) => (
  <div
    className="player-wrapper"
    style={{
      backgroundColor: "#222",
      position: "relative",
      paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 720) */
    }}
  >
    <ReactPlayer
      className="react-player"
      url={url}
      width="100%"
      height="100%"
      pip={false}
      controls={true}
      config={{
        file: {
          attributes: {
            onContextMenu: e => e.preventDefault(),
            disablepictureinpicture: "true",
            controlsList: "nodownload noremoteplayback"
          }
        }
      }}
      style={{
        position: "absolute",
        top: 0,
        left: 0
      }}
      onReady={() => {
        // pause other video
      }}
      {...otherProps}
    />
  </div>
);

export default Video;
