import "core-js/stable";
import "regenerator-runtime/runtime";
import "./auth0";
import "favicons";

// eslint-disable-next-line no-underscore-dangle
if (process.env.NODE_ENV === "development") {
  // eslint-disable-next-line no-console
  const backup = console.error;
  // eslint-disable-next-line no-console
  const backupWarning = console.warn;

  // eslint-disable-next-line no-console
  console.error = function filterErrors(msg) {
    const supressedErrors = ["Warning:"];

    if (
      typeof msg.includes !== "function" ||
      !supressedErrors.some(entry => msg.includes(entry))
    ) {
      backup.apply(console, arguments);
    }
  };

  // eslint-disable-next-line no-console
  console.warn = function filterErrors(msg) {
    const supressedErrors = ["Warning:"];

    if (
      typeof msg.includes !== "function" ||
      !supressedErrors.some(entry => msg.includes(entry))
    ) {
      backupWarning.apply(console, arguments);
    }
  };
}
