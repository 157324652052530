import { connect } from "react-redux";
import Summary from "./Summary";

import { records } from "redux/modules/modules/records/selectors";
import { moduleDetails } from "redux/modules/modules/module/selectors";

function mapStateToProps(state, props) {
  return {
    records: records(state, props.moduleId),
    module: moduleDetails(state, props.moduleId)
  };
}

export default connect(mapStateToProps)(Summary);
