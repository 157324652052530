import * as R from "ramda";
import { createSelector } from "reselect";

import { getters } from "EventLight/Expo/Sales/model";

import { TYPE_ORDER } from "../constants";

export const getAllItems = createSelector(
  getters.itemTypes,
  R.compose(
    R.flatten,
    R.map(R.prop("items")),
    R.flatten,
    R.map(R.prop("groups"))
  )
);

export const getItemsByType = createSelector(
  getters.itemTypes,
  R.compose(
    R.sortBy(R.prop("order")),
    R.map(tab => ({
      id: tab.id,
      name: tab.name,
      order: TYPE_ORDER(tab.id),
      items: R.map(
        item => ({
          id: item.id,
          name: item.name,
          items: R.compose(
            R.map(v => ({
              ...v,
              name: v.display_name
            })),
            R.flatten,
            R.map(R.prop("variants")),
            R.filter(i => !i.is_package)
          )(item.items)
        }),
        tab.groups
      )
    }))
  )
);

export const getPackagesByType = createSelector(
  (_, props) => R.prop("type", props),
  getters.itemPackages,
  (type, itemPackages) =>
    R.map(
      pkg => {
        return {
          ...pkg,
          price: R.prop(
            "price",
            R.find(R.prop("is_default"), pkg.prices || [])
          ),
          items: R.map(item => ({
            id: item.variant_id,
            name: item.name,
            count: item.quantity
          }))(pkg.package_variants)
        };
      },
      R.filter(
        R.compose(
          R.equals(type),
          R.prop("type_id")
        ),
        itemPackages
      )
    )
);

export const getSelectedPackageItems = createSelector(
  getters.selectingItems,
  getters.packageItems,
  (itemId, packageItems) => {
    return packageItems[itemId] || {};
  }
);

export const getSelectItemsModalTitle = createSelector(
  getters.itemPackages,
  getters.selectingItems,
  (packages, packageId) =>
    `Adding Items to ${R.path([packageId, "name"], packages) || "Package"}`
);
