import React from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import CSSModules from "react-css-modules";
import { getIsPhoneDisabled } from "Modules/AddEditColumnModal/selectors";
import { actions, getters } from "Modules/AddEditColumnModal/model";
import PhoneEditorSettings from "components/Global/Table3/ModalColumn/PhoneEditorSettings";
import { noop } from "utils/General";
import css from "./style.scss";

const decorate = R.compose(
  connect(
    (state, props) => ({
      phoneSettings: getters.phoneSettings(state, props),
      isPhoneDisabled: getIsPhoneDisabled(state, props)
    }),
    {
      setPhoneSettings: actions.setPhoneSettings
    }
  ),
  CSSModules(css)
);

const PhoneSettings = ({
  phoneSettings,
  setPhoneSettings = noop,
  isPhoneDisabled
}) => (
  <div styleName="dropdownOptionsWrapper">
    <PhoneEditorSettings
      value={phoneSettings}
      disabled={isPhoneDisabled}
      onChange={setPhoneSettings}
    />
  </div>
);

export default decorate(PhoneSettings);
