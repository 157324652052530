import PropTypes from "prop-types";
import React from "react";
import EditorBase from "./TableConnector";
import Cell from "./CellBase";
import Editor from "components/Global/Editors/CatalogItemEditor";
import Formatter from "../CellFormatters/CatalogItem";

export default class CatalogItemEditor extends EditorBase {
  render() {
    const { rowMetaData, selectType } = this.props;
    const editor = (
      <Editor
        {...this.props}
        className="editor-main"
        value={this.state}
        onChange={this.handleChange}
      />
    );

    if (
      ["quantity-only-input", "quantity-only-dropdown"].includes(selectType)
    ) {
      return editor;
    }

    return (
      <Cell
        ref="editor"
        formatter={
          <Formatter value={this.state} dependentValues={rowMetaData} />
        }
        editor={editor}
      />
    );
  }
}

CatalogItemEditor.propTypes = {
  ...EditorBase.propTypes,
  selectType: PropTypes.string.isRequired,
  rowMetaData: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired
};
