import React from "react";
import {
  Div,
  Text0,
  Text1,
  Text2,
  Text3,
  Text5,
  Text6,
  BigOutlineButton,
  BigFilledButton,
  CheckIcon,
  LoadingIcon
} from "components/Base";
import InvoiceTable from "./InvoiceTable";
import TransactionsTable from "./TransactionsTable";

const nabvarConfigProps = {
  bg: "white",
  width: 1,
  display: "row.center.center",
  bb: 1,
  bc: "neutral3",
  py: 2,
  px: 5
};
const containerConfigProps = {
  width: 1,
  maxWidth: 960,
  display: "row.center.center",
  style: {
    margin: "0 auto"
  }
};
const buttonPayConfigProps = {
  bg: "green7",
  // isPadded: true,
  style: {
    outline: "none"
  }
};
const secondaryButtonStyle = {
  outline: "none"
};
const mainHolderConfigProps = {
  display: "column.flex-start.center",
  width: 1,
  maxWidth: 960,
  bg: "white",
  shadow: 4,
  bra: 1,
  style: {
    margin: "0 auto",
    marginTop: "150px",
    marginBottom: "30px"
  }
};
const dueWarningConfigProps = {
  width: 1,
  bg: "altB1",
  brt: 1,
  display: "row.flex-start.center",
  px: 3,
  py: 2
};
const headingConfigProps = {
  display: "column.flex-start.center",
  width: 1,
  bb: 1,
  bc: "neutral3",
  style: {
    minHeight: "250px"
  },
  p: 8
};
const headingTopConfigProps = {
  display: "row.flex-start.flex-start",
  width: 1,
  style: {
    minHeight: "100px"
  }
};

const headingBottomConfigProps = {
  display: "row.flex-start.flex-start",
  width: 1,
  mt: 4,
  style: {
    minHeight: "100px"
  }
};

const TransactionDetails = ({ transactions }) => {
  return (
    <Div p={4}>
      <Text3 bold flex={1} pr={5}>
        Transaction Details
      </Text3>
      <Div mt={2} ba={1} bra={1} bc="neutral0">
        <TransactionsTable rowsData={transactions} />
      </Div>
    </Div>
  );
};

const View = ({
  loading,
  number,
  orderNumber,
  paymentsEnabled,
  invoiceDate,
  dueDate,
  isPaid,
  logoUrl,
  backgroundUrl,
  due,
  dueRaw,
  total,
  invoiceStatusDueDate,
  onPay,
  onDownload,
  onPrint,
  goToHome,
  title,
  currency,
  addressStreet,
  addressCity,
  addressState,
  addressZip,
  phone,
  customerAccountName,
  customerContactName,
  customerAddressStreet,
  customerAddressCity,
  customerAddressState,
  customerAddressZip,
  customerAddressLocation,
  lineItems,
  transactions,
  terms,
  totalPaymentsApplied,
  lastTransactionAt
}) => {
  if (loading) {
    return (
      <Div width={1} height={1} display="row.center.center">
        <LoadingIcon size={50} color="gray5" />
      </Div>
    );
  }

  return (
    <Div
      className="customer-invoice"
      width={1}
      bg="neutral1"
      height={420}
      style={{
        backgroundImage: `url(${backgroundUrl})`,
        backgroundRepeat: "no-repeat",
        height: 420,
        backgroundColor: "#EDEDED",
        backgroundPosition: "top center",
        backgroundSize: "cover"
      }}
    >
      <Div className="customer-invoice-nabvar" {...nabvarConfigProps}>
        <Div className="container" {...containerConfigProps}>
          <Text6 bold flex={1}>
            Invoice #{number}
          </Text6>
          {/*
          <BigOutlineButton
            onClick={onDownload}
            mr={2}
            style={secondaryButtonStyle}
          >
            Download
          </BigOutlineButton>
          */}
          <BigOutlineButton
            onClick={onPrint}
            mr={2}
            style={secondaryButtonStyle}
          >
            Print
          </BigOutlineButton>
          {!isPaid && paymentsEnabled ? (
            <BigFilledButton
              onClick={onPay}
              {...buttonPayConfigProps}
              style={{
                paddingLeft: "80px",
                paddingRight: "80px"
              }}
            >
              {`Pay ${due}`}
            </BigFilledButton>
          ) : null}
        </Div>
      </Div>
      <Div className="customer-invoice-main" {...mainHolderConfigProps}>
        {isPaid ? (
          <Div
            className="customer-invoice__due-reminder"
            {...dueWarningConfigProps}
            bg="green1"
          >
            <CheckIcon mr={1} color="gray6" />
            <Text2 bold>
              {invoiceStatusDueDate}
              {lastTransactionAt ? ` · ${lastTransactionAt}` : null}
            </Text2>
          </Div>
        ) : (
          <Div
            className="customer-invoice__due-reminder"
            {...dueWarningConfigProps}
          >
            <Text2 bold>{invoiceStatusDueDate}</Text2>
          </Div>
        )}
        <Div className="customer-invoice-heading" {...headingConfigProps}>
          <Div
            className="customer-invoice-heading__top"
            {...headingTopConfigProps}
          >
            <Div
              className="customer-invoice-heading__logo"
              display="column.center.center"
              textAlign="center"
              width={100}
              style={{
                minHeight: 75
              }}
            >
              {logoUrl ? (
                <img
                  style={{
                    maxWidth: "100%"
                  }}
                  src={logoUrl}
                />
              ) : null}
            </Div>
            <Div flex={1} display="column.flex-start.flex-end">
              <Text5 bold flex={1} textAlign="right">
                {title}
              </Text5>
              {/*
              <Text1>{addressStreet}</Text1>
              <Text1>
                {addressCity} {addressState}, {addressZip}
              </Text1>
              <Text1>{phone}</Text1>
              */}
            </Div>
          </Div>
          <Div
            className="customer-invoice-heading__bottom"
            {...headingBottomConfigProps}
            display="row.space-evenly.stretch"
          >
            <Div display="column" style={{ flex: 1 }}>
              <Text0 bold>BILLED TO</Text0>
              <Text5 bold mb={1}>
                {customerAccountName}
              </Text5>
              <Text1>{customerContactName}</Text1>
              <Text1>{customerAddressStreet}</Text1>
              <Text1>{customerAddressLocation}</Text1>
            </Div>
            <Div display="row" flexWrap="wrap" style={{ flex: 1 }}>
              <Div display="column.flex-start.flex-start" p={2}>
                <Text0 uppercase bold>
                  invoice number
                </Text0>
                <Text2 bold>#{number}</Text2>
              </Div>
              {invoiceDate ? (
                <Div display="column.flex-start.flex-start" p={2}>
                  <Text0 uppercase bold>
                    date of issue
                  </Text0>
                  <Text2 bold>{invoiceDate}</Text2>
                </Div>
              ) : null}
              {/*
              <Div display="column.flex-start.flex-start" p={2}>
                <Text0 uppercase bold>
                  order number
                </Text0>
                <Text2 bold>{orderNumber}</Text2>
              </Div>
              */}
              {dueDate ? (
                <Div display="column.flex-start.flex-start" p={2}>
                  <Text0 uppercase bold>
                    due date
                  </Text0>
                  <Text2 bold>{dueDate}</Text2>
                </Div>
              ) : null}
              {isPaid && lastTransactionAt ? (
                <Div display="column.flex-start.flex-start" p={2}>
                  <Text0 uppercase bold>
                    date paid
                  </Text0>
                  <Text2 bold>{lastTransactionAt}</Text2>
                </Div>
              ) : null}
            </Div>
            <Div display="column.flex-end.flex-end" style={{ flex: 1 }}>
              <Text0 uppercase bold>
                {isPaid
                  ? `amount paid (${currency})`
                  : `amount due (${currency})`}
              </Text0>
              <Text6 bold>{isPaid ? totalPaymentsApplied : due}</Text6>
            </Div>
          </Div>
        </Div>
        <Div
          className="customer-invoice__body"
          display="row.flex-start.flex-center"
          width={1}
          p={4}
          mb={5}
        >
          <Div flex={1}>
            <Div
              className="customer-invoice__items"
              m={4}
              ba={1}
              bra={1}
              bc="neutral0"
              flex={1}
            >
              <InvoiceTable rowsData={lineItems} />
              <Div
                display="row.flex-start.center"
                bt={1}
                bc="neutral0"
                px={5}
                py={3}
              >
                <Div display="column.center.flex-end" flex={1}>
                  <Div display="row" mb={1}>
                    <Text2 bold flex={1} mr={5}>
                      Subtotal
                    </Text2>
                    <Text2 flex={0} textAlign="right">
                      {total}
                    </Text2>
                  </Div>
                  {/*
                  <Div display="row">
                    <Text2 bold flex={1} mr={5}>
                      Order Discount
                    </Text2>
                    <Text2 flex={0} textAlign="right">
                      $-15
                    </Text2>
                  </Div>
                  */}
                </Div>
              </Div>
              <Div
                display="row.flex-start.center"
                bt={1}
                bc="neutral0"
                px={4}
                py={3}
              >
                <Div display="row.space-between.center" flex={1}>
                  <Div display="column" flex={1}>
                    {isPaid ? (
                      <Div display="row.flex-start.center">
                        <CheckIcon size={30} color="gray7" mr={1} />
                        <Div fw={4} color="gray7" fs={6}>
                          Paid
                        </Div>
                      </Div>
                    ) : paymentsEnabled && !isPaid ? (
                      <BigFilledButton
                        flex={1}
                        onClick={onPay}
                        {...buttonPayConfigProps}
                        mr={7}
                      >
                        {`Pay ${due}`}
                      </BigFilledButton>
                    ) : null}
                  </Div>
                  <Div display="column">
                    <Div display="row" mb={1}>
                      <Text2 bold flex={1} pr={5}>
                        Payments Applied
                      </Text2>
                      <Text2 flex={1} textAlign="right">
                        {totalPaymentsApplied}
                      </Text2>
                    </Div>
                    <Div display="row.flex-start.stretch">
                      <Text3 bold flex={1} pr={5}>
                        Total Amount Due
                      </Text3>
                      <Text2
                        bold
                        flex={1}
                        textAlign="right"
                        style={{ alignSelf: "center" }}
                      >
                        {due}
                      </Text2>
                    </Div>
                  </Div>
                </Div>
              </Div>
            </Div>
            {transactions.length ? (
              <TransactionDetails transactions={transactions} />
            ) : null}
          </Div>
          {terms && terms.length ? (
            <Div m={4} width={[1, 1, 0.3]}>
              <Text0 bold uppercase mb={2}>
                Term / Notes
              </Text0>
              <Text2
                style={{
                  whiteSpace: "pre-wrap"
                }}
              >
                {terms}
              </Text2>
            </Div>
          ) : null}
        </Div>
        <Div
          className="customer-invoice__footer"
          width={1}
          bt={1}
          bc="neutral0"
          p={3}
          brb={1}
          display="row.flex-start.center"
          style={{
            alignSelf: "flex-end"
          }}
        >
          <Div display="row.flex-start.center" flex={1}>
            <Div display="row.flex-start.center" onClick={goToHome}>
              <img
                src="https://d2dks4tzxs6xee.cloudfront.net/img/favicon-96x96.png"
                width={18}
                height={18}
              />
              <Text1 bold ml={2}>
                Powered by
              </Text1>
              <Text1 bold uppercase ml={1}>
                Lennd
              </Text1>
            </Div>
          </Div>
          <Div flex={0} display="row.flex-end.flex-center">
            <Text1>{title}</Text1>
          </Div>
        </Div>
      </Div>
    </Div>
  );
};

export default View;
