import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";
import {
  ButtonGroup,
  ButtonOutline,
  Submit
} from "components/Global/Modal/Layout/Buttons";

const Footer = ({
  activeStepIndex,
  credentialCount,
  isCurrentStateValid,
  handleNextStep,
  handleBackStep,
  hideModal,
  onDone,
  save,
  processing,
  saving,
  sending,
  shareOnly,
  ...props
}) => {
  if (activeStepIndex === 0 && shareOnly) {
    return (
      <ButtonGroup className={css.container}>
        <div styleName="quantity" />
        <Submit
          title="Continue"
          onClick={handleNextStep}
          disabled={!isCurrentStateValid()}
          className={css.nextButton}
        />
      </ButtonGroup>
    );
  } else if (activeStepIndex === 0) {
    return (
      <ButtonGroup className={css.container}>
        <div styleName="quantity" />
        <Submit
          title={
            saving ? (
              <span>
                <i
                  className={[
                    "fa",
                    "fa-circle-o-notch",
                    css.processingIcon
                  ].join(" ")}
                />
                SAVING
              </span>
            ) : (
              <span>Give Access</span>
            )
          }
          onClick={save}
          disabled={!isCurrentStateValid() || processing || saving}
          className={css.nextButton}
        />
      </ButtonGroup>
    );
  } else if (activeStepIndex === 1) {
    return (
      <ButtonGroup className={css.container}>
        {shareOnly ? (
          <ButtonOutline
            title="Back"
            onClick={handleBackStep}
            className={css.grayButton}
          />
        ) : (
          <div styleName="quantity" />
        )}
        <ButtonOutline
          title="Done"
          onClick={hideModal}
          className={css.grayButton}
        />
      </ButtonGroup>
    );
  }
};

Footer.propTypes = {
  processing: PropTypes.bool.isRequired,
  activeStepIndex: PropTypes.number.isRequired,
  credentialCount: PropTypes.number.isRequired,
  isCurrentStateValid: PropTypes.func.isRequired,
  handleNextStep: PropTypes.func.isRequired,
  handleBackStep: PropTypes.func.isRequired,
  onDone: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired
};

export default CSSModules(Footer, css);
