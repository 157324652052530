import React from "react";

import { connect } from "react-redux";

import { getters } from "Items/AddQuestionModal";

import { Div } from "components/Base";

import { getAddQuestionModalHeight } from "Items/AddQuestionModal/selectors";

import Loading from "ui-kit/Loading";

import Footer from "./Footer";

import Body from "./Body";

import Header from "./Header";

import Modal from "components/Global/Modal/Layout/StyleWrapper";

import { noop } from "utils/General";

const decorate = connect(state => ({
  modalHeight: getAddQuestionModalHeight(state),
  loading: getters.loading(state)
}));

const Layout = ({
  hideModal,
  questionId,
  onDone,
  loading,
  refetchQuestions = noop
}) => (
  <Modal width={460} hideHeader bodyStyles={{ padding: 0 }}>
    {loading ? (
      <Loading />
    ) : (
      <Div width={1} height={1}>
        <Header
          hideModal={hideModal}
          text={`${questionId ? "Editing" : "Adding"} Question`}
        />
        <Body />
        <Footer
          hideModal={hideModal}
          onDone={onDone}
          refetchQuestions={refetchQuestions}
        />
      </Div>
    )}
  </Modal>
);

export default decorate(Layout);
