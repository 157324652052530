import { REQUEST, RECEIVE } from "./constants";
import * as selectors from "./selectors";
import api from "./api";
import { registerError } from "redux/modules/errors/actions";

export function getPeople(eventId, accountId) {
  return async (dispatch, getState) => {
    if (selectors.isFetching(getState())) return;
    try {
      dispatch({ type: REQUEST });
      const people = await api.get(
        getState().user.user.credentials,
        eventId,
        accountId
      );
      dispatch({ type: RECEIVE, payload: people });
    } catch (error) {
      dispatch(
        registerError([
          {
            system: error,
            user: "An error occurred getting people"
          }
        ])
      );
    }
  };
}
