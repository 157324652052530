import React from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

const Loader = CSSModules(
  props => (
    <div styleName="container" {...props}>
      <div styleName="dot" />
      <div styleName="dot" />
      <div styleName="dot" />
    </div>
  ),
  css
);

export default Loader;
