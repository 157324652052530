import { get } from "lodash";

export default function(value, details, meta) {
  return value.records
    .map(({ id, quantity }) => {
      const name =
        get(details, "module_settings.credentials.credentials", []).find(
          c => c.id === id
        ) || {};
      if ("value" in name) {
        return `${name.value}${
          get(meta, "hideQuantity") ? "" : ` (${quantity})`
        }`;
      }
      return "";
    })
    .join(", ");
}
