import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import getRows from "utils/EventForms/FormGrid/getRows";
import isRowNotEmpty from "utils/EventForms/FormGrid/isRowNotEmpty";
import { get, find, sortBy, findIndex } from "lodash";
import Loading from "components/Global/Loading";
import Helpers from "utils/Global/Helpers";
import update from "immutability-helper";

import CSSModules from "react-css-modules";
import css from "../styles.scss";

import NotFound from "components/Event/FormsV2/NotFound";

import PreviewBar from "components/Event/FormsV2/Sections/FormGridBarPreview";

import FormBackground from "components/Event/FormsV2/Sections/FormBackground";
import FormInfo from "components/Event/FormsV2/Sections/FormInfo";
import FormFooter from "components/Event/FormsV2/Form/FormFooter";

import Section from "components/Event/FormsV2/Sections/Section";
import Table3Helpers from "components/Global/Table3/Utilities/Helpers";
import ModalColumnAdd from "Modules/AddEditColumnModal/View";
import ModalColumnDelete from "components/Global/Table3/ModalColumnDelete";
import ModalQuestion from "components/Event/FormsV2/Modals/FormQuestionModal";

import { actions as FormActions } from "redux/modules/eventForms";

import FIELD_TYPES from "components/Event/FormsV2/field-types";
import SUBFORM_FIELD_TYPES from "components/Event/FormsV2/Sections/FormGrid/field-types";

@CSSModules(css)
class FormHandler extends Component {
  getFormRows({
    schema,
    gridId,
    formValues,
    response,
    isApprovingForm,
    isFillingFormOut,
    isViewingApproval,
    recipientCanViewChanges
  }) {
    let rows = getRows({
      schema,
      gridId,
      formValues,
      response,
      isApprovingForm,
      isFillingFormOut,
      isViewingApproval,
      recipientCanViewChanges
    });

    if (get(response, "status") === "approval_sent") {
      rows = rows.filter(row => isRowNotEmpty(row, schema.columns));
    }

    return rows;
  }

  toggleEditing = () => {
    this.props.router.push({
      pathname: `/event/${this.props.form.event_id}/forms-v2/${this.props.form.id}/preview`
    });
  };

  updateFormValue = (path, value) => {
    const form = this.props.form;
    this.props.dispatch(
      FormActions.updateFormValue({
        eventId: form.event_id,
        formId: form.id,
        responseId: get(form, "response.id", 0),
        path,
        value,
        sync: true
      })
    );
  };

  getContextMenus = () => {
    const headerCellMenu = [
      {
        type: "row",
        title: "Edit Field",
        icon: "settings",
        action: info => {
          const field = find(this.props.form.fields, { id: info.gridId });
          const subformField = find(field.subform.form.fields, {
            id: info.column
          });
          const sectionId = find(
            field.subform.form.fields_grouped_by_section,
            section => !!find(section.fields, { id: subformField.id })
          ).id;
          this.showEditFieldModal(
            info.gridId,
            sectionId,
            subformField,
            field.subform.form.base_module_id
          );
        }
      },
      {
        type: "row",
        title: "Add Description",
        icon: "edit",
        action: info => {
          this.props.toggleEditFieldDescription({
            fieldId: info.column
          });
        }
      },
      {
        type: "separator"
      },
      {
        type: "row",
        title: "Insert Left",
        icon: "arrow_back",
        action: info => {
          const field = find(this.props.form.fields, { id: info.gridId });
          const subformField = find(field.subform.form.fields, {
            id: info.column
          });
          const section = find(
            field.subform.form.fields_grouped_by_section,
            section => !!find(section.fields, { id: subformField.id })
          );
          const index = findIndex(
            sortBy(section.fields, "order"),
            f => f.id === subformField.id
          );
          this.showAddColumnModal(
            info.gridId,
            section.id,
            index,
            subformField,
            field.subform.form.base_module_id
          );
        }
      },
      {
        type: "row",
        title: "Insert Right",
        icon: "arrow_forward",
        action: info => {
          const field = find(this.props.form.fields, { id: info.gridId });
          const subformField = find(field.subform.form.fields, {
            id: info.column
          });
          const section = find(
            field.subform.form.fields_grouped_by_section,
            section => !!find(section.fields, { id: subformField.id })
          );
          const index = findIndex(
            sortBy(section.fields, "order"),
            f => f.id === subformField.id
          );
          this.showAddColumnModal(
            info.gridId,
            section.id,
            index + 1,
            subformField,
            field.subform.form.base_module_id
          );
        }
      },
      {
        type: "separator"
      },
      {
        type: "row",
        title: "Sort A-Z",
        icon: "sort_by_alpha",
        allow: info => {
          const field = find(this.props.form.fields, { id: info.gridId });
          const subformField = find(field.subform.form.fields, {
            id: info.column
          });
          const id = subformField ? subformField.id : info.column;
          const type = subformField ? subformField.type : "text";
          return Object.keys(Table3Helpers.rowComparers(id)).includes(type);
        },
        action: info => {
          const field = find(this.props.form.fields, { id: info.gridId });
          const subformField = find(field.subform.form.fields, {
            id: info.column
          });
          const id = subformField ? subformField.id : info.column;
          const type = subformField ? subformField.type : "text";
          this.refs.section_0.refs[
            `grid_${info.gridId}`
          ].refs.wrappedInstance.onRowSort(
            Table3Helpers.getSortFunction({ id, type }, "ASC"),
            subformField
          );
        }
      },
      {
        type: "row",
        title: "Sort Z-A",
        icon: "sort_by_alpha",
        allow: info => {
          const field = find(this.props.form.fields, { id: info.gridId });
          const subformField = find(field.subform.form.fields, {
            id: info.column
          });
          const id = subformField ? subformField.id : info.column;
          const type = subformField ? subformField.type : "text";
          return Object.keys(Table3Helpers.rowComparers(id)).includes(type);
        },
        action: info => {
          const field = find(this.props.form.fields, { id: info.gridId });
          const subformField = find(field.subform.form.fields, {
            id: info.column
          });
          const id = subformField ? subformField.id : info.column;
          const type = subformField ? subformField.type : "text";
          this.refs.section_0.refs[
            `grid_${info.gridId}`
          ].refs.wrappedInstance.onRowSort(
            Table3Helpers.getSortFunction({ id, type }, "DESC"),
            subformField
          );
        }
      },
      {
        type: "separator"
      },
      {
        type: "row",
        title: "Delete Field",
        icon: "delete",
        action: info => {
          const field = find(this.props.form.fields, { id: info.gridId });
          const subformField = find(field.subform.form.fields, {
            id: info.column
          });
          const sectionId = find(
            field.subform.form.fields_grouped_by_section,
            section => !!find(section.fields, { id: subformField.id })
          ).id;
          this.showDeleteColumnModal(info.gridId, sectionId, subformField);
        }
      }
    ];

    return { headerCellMenu };
  };

  /**
   * modal: add / edit question
   */
  showAddQuestionModal = index => {
    const modal = (
      <ModalQuestion
        index={index}
        eventId={this.props.form.event_id}
        formId={this.props.form.id}
        field={{ id: "NEW_FIELD_ID" }}
        fields={this.props.form.fields}
        availableParentFields={this.getColumnModalFields("parent", "form")}
        availableChildFields={this.getColumnModalFields("child", "form")}
        fieldTypes={FIELD_TYPES}
        fieldRelationships={this.props.form.field_relationships.map(r => ({
          parentFieldId: r.parent_field_id,
          childFieldId: r.child_field_id
        }))}
        fieldMapping={this.props.form.mapped_fields.map(r => ({
          parentFieldId: r.parent_field_id,
          sourceFieldId: r.source_field_id,
          destinationFieldId: r.destination_field_id
        }))}
      />
    );
    this.props.showModal({ content: modal });
  };

  showEditQuestionModal = field => {
    const modal = (
      <ModalQuestion
        eventId={this.props.form.event_id}
        formId={this.props.form.id}
        field={field}
        fields={this.props.form.fields}
        availableParentFields={this.getColumnModalFields("parent", "form")}
        availableChildFields={this.getColumnModalFields("child", "form")}
        fieldTypes={FIELD_TYPES}
        mode="edit"
        fieldRelationships={this.props.form.field_relationships.map(r => ({
          parentFieldId: r.parent_field_id,
          childFieldId: r.child_field_id
        }))}
        fieldMapping={this.props.form.mapped_fields.map(r => ({
          parentFieldId: r.parent_field_id,
          sourceFieldId: r.source_field_id,
          destinationFieldId: r.destination_field_id
        }))}
      />
    );
    this.props.showModal({ content: modal });
  };

  getColumnModalFields = (type, origin, subformId) => {
    if (type === "parent" && origin === "grid") {
      return find(this.props.form.fields, {
        id: subformId
      }).subform.form.fields.map(f => ({
        ...f,
        origin: "grid",
        name: `Grid: ${f.name}`
      }));
    }
    if (type === "parent" && origin === "form") {
      return this.props.form.fields.map(f => ({
        ...f,
        origin: "form",
        name: `Form: ${f.name}`
      }));
    }
    if (type === "child" && origin === "grid") {
      return [].concat(
        this.props.form.fields.map(f => ({
          ...f,
          origin: "form",
          name: `Form: ${f.name}`
        })),
        find(this.props.form.fields, { id: subformId }).subform.form.fields.map(
          f => ({
            ...f,
            origin: "grid",
            name: `Grid: ${f.name}`
          })
        )
      );
    }
    if (type === "child" && origin === "form") {
      const createListitem = (field, origin, prefix) => ({
        ...field,
        origin,
        name: `${prefix}: ${field.name}`
      });
      // Get all form AND all grid fields
      return this.props.form.fields.reduce(
        (fieldSets, f) => {
          if (f.type === "subform") {
            fieldSets.push(
              ...f.subform.form.fields.map(f =>
                createListitem(f, "grid", "Grid")
              )
            );
          }
          return fieldSets;
        },
        [...this.props.form.fields.map(f => createListitem(f, "form", "Form"))]
      );
    }
  };

  /**
   * modal: add field
   */
  showAddColumnModal = (
    subformId,
    sectionId,
    addAtPosition,
    addAtPositionReferenceField,
    subformModuleId
  ) => {
    const subform = find(this.props.form.fields, { id: subformId }).subform
      .form;
    const section = find(subform.fields_grouped_by_section, { id: sectionId });
    const modal = (
      <ModalColumnAdd
        mode="add"
        source="form"
        column={{ id: "NEW_FIELD_ID" }}
        moduleId={subformModuleId}
        showAdvancedSettings
        addAtPosition={addAtPosition}
        columnTypes={SUBFORM_FIELD_TYPES}
        formFieldTypes={FIELD_TYPES}
        fieldRelationships={this.props.form.field_relationships.map(r => ({
          parentFieldId: r.parent_field_id,
          childFieldId: r.child_field_id
        }))}
        fieldMapping={this.props.form.mapped_fields.map(r => ({
          parentFieldId: r.parent_field_id,
          sourceFieldId: r.source_field_id,
          destinationFieldId: r.destination_field_id
        }))}
        columns={
          find(this.props.form.fields, { id: subformId }).subform.form.fields
        }
        availableParentColumns={this.getColumnModalFields(
          "parent",
          "grid",
          subformId
        )}
        availableChildColumns={this.getColumnModalFields(
          "child",
          "grid",
          subformId
        )}
        hideModal={this.props.hideModal}
        done={data => {
          this.props
            .addSubformField({
              subformId,
              formId: find(this.props.form.fields, { id: subformId }).subform
                .form.id,
              sectionId,
              order: data.addAtPosition,
              type: data.type,
              name: data.name,
              settings: data.settings,
              parentFields: data.parentFields,
              childFields: data.childFields,
              fieldMapping: data.fieldMapping
            })
            .then(field => {
              if (!field) {
                // No field created
                return;
              }
              const fields = sortBy(section.fields, "order");
              const insertIndex = addAtPosition >= 0 ? addAtPosition : 0;
              fields.splice(insertIndex, 0, field);
              this.props.bulkUpdateSubformFields({
                formId: this.props.form.id,
                subformId,
                sectionId,
                fields: fields.map((f, order) => ({ fieldId: f.id, order }))
              });
            });
        }}
      />
    );
    this.props.showModal({ content: modal });
  };

  /**
   * modal: edit field
   */
  showEditFieldModal = (
    subformId,
    sectionId,
    subformField,
    subformModuleId
  ) => {
    const modal = (
      <ModalColumnAdd
        mode="edit"
        source="form"
        showAdvancedSettings
        column={{
          ...subformField
        }}
        moduleId={subformModuleId}
        fieldRelationships={this.props.form.field_relationships.map(r => ({
          parentFieldId: r.parent_field_id,
          childFieldId: r.child_field_id
        }))}
        fieldMapping={this.props.form.mapped_fields.map(r => ({
          parentFieldId: r.parent_field_id,
          sourceFieldId: r.source_field_id,
          destinationFieldId: r.destination_field_id
        }))}
        columns={
          find(this.props.form.fields, { id: subformId }).subform.form.fields
        }
        availableParentColumns={this.getColumnModalFields(
          "parent",
          "grid",
          subformId
        )}
        availableChildColumns={this.getColumnModalFields(
          "child",
          "grid",
          subformId
        )}
        columnTypes={SUBFORM_FIELD_TYPES}
        formFieldTypes={FIELD_TYPES}
        done={data => {
          this.props.updateSubformField({
            ...data,
            formId: find(this.props.form.fields, { id: subformId }).subform.form
              .id,
            subformId,
            sectionId,
            fieldId: subformField.id
          });
        }}
      />
    );
    this.props.showModal({ content: modal });
  };

  /**
   * modal: delete field
   */
  showDeleteColumnModal = (subformId, sectionId, subformField) => {
    const modal = (
      <ModalColumnDelete
        column={{
          ...subformField
        }}
        hideModal={this.props.hideModal}
        handleDeleteColumn={() => {
          this.props.deleteSubformField({
            formId: this.props.form.id,
            subformId,
            sectionId,
            fieldId: subformField.id
          });
        }}
      />
    );
    this.props.showModal({ content: modal });
  };

  /**
   * columns
   */
  showFilePicker = () => {
    const callback = InkBlobs => {
      const form = this.props.form;
      if (InkBlobs[0] && typeof InkBlobs[0].url !== "undefined") {
        this.props.updateForm({
          formId: form.id,
          backgroundImageUrl: InkBlobs[0].url
        });
      }
    };
    Helpers.getFilepicker({}, { path: "event-form-background/" }, callback);
  };

  moveField = (dragIndex, hoverIndex) => {
    const { sortedFormFields } = this.props;
    const dragField = sortedFormFields[dragIndex];

    this.props.updateFieldOrder({
      formId: this.props.form.id,
      fields: update(sortedFormFields, {
        $splice: [[dragIndex, 1], [hoverIndex, 0, dragField]]
      }).reduce((fieldMap, field, idx) => {
        fieldMap[field.id] = idx;
        return fieldMap;
      }, {})
    });
  };

  componentDidMount() {
    this.props.getForm(this.props.params.formId);
  }

  render() {
    if (
      this.props.errorMessages.length ||
      (!this.props.isFetching && !this.props.form.id)
    ) {
      return <NotFound />;
    }

    return (
      <form ref="container" noValidate styleName="form" className="page-form">
        <PreviewBar
          location={this.props.location}
          router={this.props.router}
          form={this.props.form}
          toggleEditing={this.toggleEditing}
          isEditing
        />

        {/* form container */}
        <div styleName="container">
          {/* form top */}
          <FormBackground
            backgroundUrl={
              this.props.form.background_image_url ||
              get(this.props.eventDetails, "background_image_url")
            }
            showFilePicker={this.showFilePicker}
            isEditing
          />

          {/* form content */}
          <div styleName="contentContainer" className="page-form-content">
            {/* form header */}
            <div styleName="headerContainer">
              <FormInfo
                dispatch={this.props.dispatch}
                eventId={this.props.form.event_id}
                formId={this.props.form.id || ""}
                title={this.props.form.title}
                description={this.props.form.description}
              />
            </div>

            <div styleName="formContainer">
              {!this.props.isFetching ? (
                <Section
                  ref="section_0"
                  sectionId={0}
                  form={this.props.form}
                  preferences={this.props.preferences}
                  fields={this.props.sortedFormFields}
                  eventDetails={this.props.eventDetails}
                  moveField={this.moveField}
                  getFormRows={this.getFormRows}
                  dispatch={this.props.dispatch}
                  showModal={this.props.showModal}
                  showAddQuestionModal={this.showAddQuestionModal}
                  showEditQuestionModal={this.showEditQuestionModal}
                  getColumnModalFields={this.getColumnModalFields}
                  isFillingFormOut={false}
                  isApprovingForm={false}
                  isResponseLocked={false}
                  isViewingApproval={false}
                  isEditing
                />
              ) : (
                ""
              )}

              <div styleName="sendResponseRow">
                <div styleName="sendResponseDisabled">
                  <div>Send</div>
                  <i
                    className="material-icons"
                    styleName="sendResponseIconFooter"
                  >
                    &#xE163;
                  </i>
                </div>
              </div>

              <FormFooter formId={this.props.form.id} />
            </div>
          </div>
        </div>

        {this.props.isFetching ? (
          <Loading dark content="Loading form..." styleName="loading" />
        ) : (
          ""
        )}
      </form>
    );
  }
}

FormHandler.propTypes = {
  routes: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  router: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  preferences: PropTypes.object.isRequired,
  eventDetails: PropTypes.object.isRequired,
  sortedFormFields: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  showModal: PropTypes.func.isRequired,
  hideModal: PropTypes.func.isRequired,
  bulkUpdateSubformFields: PropTypes.func.isRequired,
  toggleEditFieldDescription: PropTypes.func.isRequired,
  getForm: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  addSubformField: PropTypes.func.isRequired,
  updateSubformField: PropTypes.func.isRequired,
  deleteSubformField: PropTypes.func.isRequired,
  updateFieldOrder: PropTypes.func.isRequired,
  errorMessages: PropTypes.array
};

export default FormHandler;
