import * as R from "ramda";
import {
  put,
  all,
  call,
  select,
  takeEvery,
  fork,
  take
} from "redux-saga/effects";

import { actions as PaginationActions } from "ui-kit/PaginationBar";
import { actions as SearchBarActions } from "ui-kit/SearchBar";
import { actions as SelectFilterActions } from "ui-kit/SelectFilter";
import { actions, getters } from "Accounts/PeoplePanels";
import { registerError } from "redux/modules/errors/actions";
import { actions as PeopleTypeActions } from "Accounts/PeopleTypePanel";

import { getEventId } from "Accounts/PeoplePanels/selectors";
import { getCredentials } from "redux/modules/user/selectors";
import { getPathname } from "App/Router/selectors";
import { getUserById } from "redux/modules/accounts/profile/selectors";
import { portalUser } from "redux/modules/portal/user/selectors";

import panelsApi from "./api";
import contactApi from "redux/modules/accounts/contactRelationship/api";
import accountApi from "redux/modules/accounts/users/api";

const resetPagination = function*({ pageSize }) {
  const groups = yield select(getters.groups);
  const reset = { page: 0 };
  if (pageSize) {
    reset.pageSize = pageSize;
  }
  yield all(
    R.map(
      group =>
        put(
          PaginationActions.setPagination(reset, {
            meta: { instanceId: group.id }
          })
        ),
      groups
    )
  );
};

const fetchGroupType = function*({ payload, type }) {
  // refresh data when table modal is closed
  if (type === PeopleTypeActions.setShowTable.type && !R.isEmpty(payload)) {
    return false;
  }
  const credentials = yield select(getCredentials);
  const path = yield select(getPathname);

  const portalAccountId = R.path(
    ["active_view", "id"],
    yield select(portalUser)
  );
  const profileAccountId = R.path([1], path.match(/account\/([a-z\d-]+)\/?/i));
  const accountIdToUse = portalAccountId || profileAccountId;

  if (!accountIdToUse) {
    return false;
  }

  const eventId = yield select(getEventId);

  yield put(actions.setLoading(true));
  try {
    const response = yield call(panelsApi.getPeople, {
      credentials,
      accountId: accountIdToUse,
      eventId
    });
    const modules = R.filter(
      group =>
        R.compose(
          R.not,
          R.isEmpty,
          R.prop("contacts")
        )(group) || group.is_enabled,
      response.payload || []
    );
    yield put(actions.groupTypesResponse(modules));
    yield call(resetPagination, { pageSize: 5 });
  } catch (e) {
    yield put(
      registerError([
        {
          system: e,
          user: "An error occurred fetching the contacts"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
  return true;
};

const removeRelatedContact = function*({
  payload: { id: relationshipId, can_login: userId }
}) {
  const path = yield select(getPathname);
  const [_, accountId] = path.match(/account\/([a-z\d-]+)\/?/i); // eslint-disable-line no-unused-vars
  try {
    yield call(contactApi.delete, { relationshipId });
    const accountUser = yield select(getUserById, { id: userId });
    if (accountUser) {
      yield call(accountApi.delete, {
        accountUserId: accountUser.id,
        accountId
      });
    }
  } catch (e) {
    yield put(
      registerError([
        {
          system: e,
          user: "An error occurred deleting the contact"
        }
      ])
    );
  } finally {
    yield call(fetchGroupType);
  }
};

const watchRefreshData = function*() {
  for (;;) {
    const action = yield take([
      actions.refreshData.type,
      PeopleTypeActions.setShowTable.type
    ]);

    yield put(actions.setUpdateRows(true));
    yield call(fetchGroupType, action);
    yield put(actions.setUpdateRows(false));
  }
};

const watchResetPagination = function*() {
  yield takeEvery(
    [
      SearchBarActions.setSearchTerm.type,
      SelectFilterActions.setActiveFilterId.type
    ],
    resetPagination
  );
};

const watchRemoveRelatedContact = function*() {
  yield takeEvery(actions.removeRelatedContact.type, removeRelatedContact);
};

const rootSaga = function*() {
  yield all([
    fork(watchRefreshData),
    fork(watchRemoveRelatedContact),
    fork(watchResetPagination)
  ]);
};

export default rootSaga;
