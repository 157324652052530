import * as R from "ramda";
import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "./constants";

const iniState = {
  showConnectModal: false,
  showMappingModal: false,
  loading: false,
  isActivated: false,
  hasMappings: false,
  createdBy: null,
  createdOn: null,
  isSyncingToFuzion: false,
  isSyncingFromFuzion: false,
  lastSyncedFromFuzionAt: null,
  lastSyncedToFuzionAt: null,
  enableSyncingFromFuzion: false
};

const handlers = createHandlers({
  iniState,
  reducers: {
    getConnection: R.identity,
    syncFromFuzion: R.identity,
    syncToFuzion: R.identity,
    setInitialData: (
      state,
      {
        payload: {
          isActivated,
          createdBy,
          createdOn,
          hasMappings,
          enableSyncingFromFuzion,
          lastSyncedFromFuzionAt,
          lastSyncedToFuzionAt
        }
      }
    ) => ({
      isActivated,
      createdBy,
      createdOn,
      hasMappings,
      enableSyncingFromFuzion,
      lastSyncedFromFuzionAt,
      lastSyncedToFuzionAt
    }),
    deleteConnection: () => ({
      isActivated: false,
      hasMappings: false,
      createdBy: null,
      createdOn: null
    })
  },
  namespace: NAMESPACE
});

export default handlers;
