import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as R from "ramda";
import { getters, actions } from "Event/ItemCatalogItems";
import { getTypeItemList } from "Event/ItemCatalogItems/selectors";
import {
  Div,
  BigFilledInput,
  BigOutlineButton,
  SearchIcon
} from "components/Base";
import CollapsableGroup from "./CollapsableGroup";
import AddItemPopover from "./AddItemPopover";

const decorate = connect(
  state => ({
    itemType: getTypeItemList(state),
    typesFilter: getters.typesFilter(state),
    typeId: getters.typeId(state)
  }),
  {
    setTypesFilter: actions.setTypesFilter
  }
);

const Layout = ({
  typesFilter,
  setTypesFilter,
  itemType,
  handlers,
  typeId
}) => {
  return (
    <Div px={6} pt={3}>
      <Div flex={1} display="row.flex-start.center">
        <BigFilledInput
          width={1}
          color="neutral5"
          bg="neutral1"
          placeholder="Search Items"
          LeftIcon={SearchIcon}
          value={typesFilter}
          onChange={value => {
            setTypesFilter(value);
          }}
          continuous
        />
        <BigOutlineButton onClick={handlers.addCategory} ml={2}>
          Add Category
        </BigOutlineButton>
        <AddItemPopover addItem={() => handlers.addUpdateItem({ typeId })} />
      </Div>
      <Div>
        {R.map(
          group => (
            <CollapsableGroup
              group={group}
              handlers={handlers}
              groupsLength={R.length(R.propOr([], "groups", itemType))}
            />
          ),
          R.propOr([], "groups", itemType)
        )}
      </Div>
    </Div>
  );
};

export default R.compose(
  withRouter,
  decorate
)(Layout);
