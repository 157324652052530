import PropTypes from "prop-types";
import React, { Component } from "react";
import Helpers from "utils/Global/Helpers";
import { get } from "lodash";
import { connect } from "react-redux";
import * as PortalSelectors from "redux/modules/portal/selectors";
import { withRouter } from "react-router";
import { showSnackbar } from "redux/modules/snackbar/actions";

import PortalUsersApi from "redux/modules/portal/users/api";
import UserApi from "redux/modules/user/api";

import View from "./View";

const decorate = connect(
  state => ({
    eventDetails: PortalSelectors.eventDetails(state),
    isFetching: PortalSelectors.isFetching(state),
    errorMessages: [PortalSelectors.error(state)].filter(Boolean)
  }),
  {
    showSnackbar
  }
);

const LoginException = function LoginException(value) {
  this.value = value;
  this.message = "Unknown Submission Login Error";
  this.toString = () => `${this.message}: ${JSON.stringify(this.value)}`;
};

class Controller extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      searching: false,
      searchingUsers: false,
      processing: false,
      email: props.location.query.email ? props.location.query.email : "",
      password: "",
      firstName: "",
      lastName: "",
      userExists: null,
      isVirtualUser: false,
      portalUserExists: null
    };
  }

  componentDidMount() {
    if (this.props.location.query.email && this.props.location.query.code) {
      this.attemptCodeLogin(this.props.location.query.code);
    } else if (this.props.location.query.email) {
      this.search(this.props.location.query.email);
    }
  }

  createUser = () => {
    const { email, password, firstName, lastName } = this.state;
    return UserApi.post(
      { userId: 0 },
      {
        email,
        password,
        firstName,
        lastName,
        eventId: this.props.eventDetails.id
      }
    ).then(result => ({ userId: result.payload.user.id }));
  };

  convertVirtualUser = async () => {
    const { email, password } = this.state;
    try {
      const result = await UserApi.convert({ userId: 0 }, { email, password });
      return {
        userId: result.payload.user.id
      };
    } catch (err) {
      this.props.showSnackbar({
        message: `${err.message}`
      });
    }
  };

  attemptCodeLogin = code => {
    window.webAuth.login(
      {
        username: this.state.email,
        password: `event:${this.props.eventDetails.id}███${code}`,
        realm: window.__AUTH0_REALM__,
        // eslint-disable-next-line no-underscore-dangle
        redirectUri: `${window.__LENND_APP_URL__}/portal-login-callback?${
          this.props.location.query && this.props.location.query.returnTo
            ? `returnTo=${this.props.location.query.returnTo}&`
            : ""
        }portalId=${this.props.params.portalId}`
      },
      err => {
        if (err) {
          console.error(
            `[Portal Login Page] Error logging in`,
            err.error_description
          );
          this.setState({
            processing: false,
            error: err.error_description
          });
        } else {
          this.setState({
            processing: true
          });
        }
      }
    );
  };

  loginUser = createPortalUser => {
    window.webAuth.login(
      {
        username: this.state.email,
        password: this.state.password,
        realm: window.__AUTH0_REALM__,
        // eslint-disable-next-line no-underscore-dangle
        redirectUri: `${window.__LENND_APP_URL__}/portal-login-callback?${
          this.props.location.query && this.props.location.query.returnTo
            ? `returnTo=${this.props.location.query.returnTo}&`
            : ""
        }portalId=${this.props.params.portalId}${
          createPortalUser ? "&create=true" : ""
        }`
      },
      err => {
        if (err) {
          console.error(
            `[Portal Login Page] Error logging in`,
            err.error_description
          );
          this.setState({
            processing: false,
            error: err.error_description
          });
        } else {
          this.setState({
            processing: true
          });
        }
      }
    );
  };

  createPortalUser = userId => {
    return PortalUsersApi.post(
      { userId },
      {
        eventId: this.props.eventDetails.id,
        userId
      }
    );
  };

  getPortalUser = userId => {
    const { eventDetails } = this.props;
    return PortalUsersApi.get(
      { userId },
      {
        eventId: eventDetails.uuid,
        userId
      }
    );
  };

  showError = error => {
    const message = get(error, "error");

    if (!message) {
      Rollbar.log("Submission Login Error", new LoginException(error));
    }

    this.setState({
      processing: false,
      error:
        get(error, "error") ||
        "Oops. We encountered an error. Please try again soon."
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.processing) {
      return false;
    }

    if (this.state.userExists === null) {
      this.search();
      return false;
    }

    if (this.state.userExists === false) {
      const errors = this.validate();
      if (errors.length) {
        return this.setState({
          error: `${errors.join(", ")} ${
            errors.length > 1 ? "are" : "is"
          } required.`
        });
      }
      // handle: user does not exist
      return this.handleRegisterNewUser();
    } else if (
      this.state.userExists === true &&
      this.state.isVirtualUser === false &&
      this.state.portalUserExists === false
    ) {
      // handle: user exists, is not a virtual user, is not a portal user
      return this.handleRegisterExistingUser();
    } else if (
      this.state.userExists === true &&
      this.state.isVirtualUser === true &&
      this.state.portalUserExists === true
    ) {
      // handle: user exists, is a virtual user, is a portal user
      return this.handleConvertVirtualUser();
    } else if (
      this.state.userExists === true &&
      this.state.isVirtualUser === true &&
      this.state.portalUserExists === false
    ) {
      // handle: user exists, is a virtual user, is a not portal user
      return this.handleRegisterVirtualUser();
    } else if (this.state.userExists === true) {
      return this.loginUser();
    }

    return false;
  };

  handleRegisterNewUser = () => {
    this.createUser()
      .then(() => this.loginUser(true))
      .catch(this.showError);
  };

  handleRegisterVirtualUser = () => {
    this.convertVirtualUser()
      .then(() => this.loginUser(true))
      .catch(this.showError);
  };

  handleConvertVirtualUser = () => {
    this.convertVirtualUser()
      .then(this.loginUser)
      .catch(this.showError);
  };

  handleRegisterExistingUser = () => {
    this.loginUser(true);
  };

  search = () => {
    const { email } = this.state;
    if (this.state.email.length && Helpers.isValidEmail(this.state.email)) {
      this.setState({ searchingUsers: true }, () => {
        PortalUsersApi.searchUsers(
          { userId: 0 },
          {
            eventId: this.props.eventDetails.id,
            email
          }
        )
          .then(
            ({ payload: { userExists, isVirtualUser, portalUserExists } }) => {
              this.setState({
                error: null,
                searchingUsers: false,
                userExists,
                isVirtualUser,
                portalUserExists
              });
            }
          )
          .catch(error => this.setState({ searchingUsers: false, error }));
      });
    } else {
      this.setState({ error: "Please enter a valid email address." });
    }
  };

  onTryAgain = () => {
    this.setState({
      userExists: null,
      isVirtualUser: null,
      portalUserExists: null,
      error: null,
      email: ""
    });
  };

  onEmailChange = e => {
    if (this.state.email === e.target.value) return;
    this.setState({
      userExists: null,
      isVirtualUser: null,
      portalUserExists: null,
      error: null,
      email: e.target.value
    });
  };

  onPasswordChange = e => {
    this.setState({
      error: null,
      password: e.target.value
    });
  };

  onFirstNameChange = e => {
    this.setState({
      error: null,
      firstName: e.target.value
    });
  };

  onLastNameChange = e => {
    this.setState({
      error: null,
      lastName: e.target.value
    });
  };

  isValid = () => {
    if (
      (this.state.userExists === false || this.state.isVirtualUser === true) &&
      !Helpers.isValidPassword(this.state.password)
    ) {
      return false;
    }
    return true;
  };

  validate = () => {
    const fieldsToValidate = [
      {
        field: "Password",
        property: "password"
      },
      {
        field: "First Name",
        property: "firstName"
      },
      {
        field: "Last Name",
        property: "lastName"
      }
    ];

    return fieldsToValidate.reduce((errors, { field, property }) => {
      if (!this.state[property]) {
        errors.push(field);
      }
      return errors;
    }, []);
  };

  render() {
    const {
      portal,
      eventDetails,
      isFetching,
      errorMessages,
      location
    } = this.props;
    const {
      firstName,
      lastName,
      email,
      password,
      processing,
      userExists,
      isVirtualUser,
      searchingUsers,
      error
    } = this.state;

    return (
      <View
        {...{
          portal,
          eventDetails,
          isFetching,
          errorMessages,
          location,
          error,

          firstName,
          lastName,
          email,
          password,
          processing,
          userExists,
          isVirtualUser,
          searchingUsers,
          isValid: this.isValid(),

          handleSubmit: this.handleSubmit,
          search: this.search,
          onTryAgain: this.onTryAgain,
          onEmailChange: this.onEmailChange,
          onFirstNameChange: this.onFirstNameChange,
          onLastNameChange: this.onLastNameChange,
          onPasswordChange: this.onPasswordChange
        }}
      />
    );
  }
}

Controller.propTypes = {
  location: PropTypes.object.isRequired,
  portal: PropTypes.object.isRequired,
  eventDetails: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  errorMessages: PropTypes.array
};

export default withRouter(decorate(Controller));
