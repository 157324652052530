import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import numeral from "numeral";
import css from "./styles.scss";
import {
  ButtonGroup,
  ButtonOutline,
  Submit
} from "components/Global/Modal/Layout/Buttons";

const Footer = ({ amount, hideModal, onSubmit, processing, isValid }) => (
  <ButtonGroup
    className={css.container}
    style={{
      paddingRight: "20px"
    }}
  >
    <ButtonOutline
      title="Cancel"
      onClick={processing ? undefined : hideModal}
    />
    <Submit
      title={
        processing
          ? "Processing..."
          : `Charge ${numeral(amount).format("$0,0.00")}`
      }
      onClick={onSubmit}
      disabled={!isValid || processing}
    />
  </ButtonGroup>
);

Footer.propTypes = {
  processing: PropTypes.bool.isRequired,
  hideModal: PropTypes.func.isRequired,
  isValid: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  amount: PropTypes.string.isRequired
};

export default CSSModules(Footer, css);
