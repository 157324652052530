import React, { useRef } from "react";
import CSSModules from "react-css-modules";
import css from "./styles.scss";

import { Div, AddIcon, MediumFilledButton } from "components/Base";
import CanUserDo from "components/Global/Security/CanUserDo";

const Search = CSSModules(
  ({ searchTerm, onSearch, onClearSearch, registerRef }) => (
    <div styleName="searchWrapper">
      <div styleName="searchIcon">
        {searchTerm.length ? (
          <i
            className="material-icons"
            onClick={onClearSearch}
            styleName="searchIconClear"
            role="button"
            tabIndex="0"
          >
            cancel
          </i>
        ) : (
          <i className="material-icons" styleName="searchIconSearch">
            search
          </i>
        )}
      </div>
      <input
        ref={ref => registerRef(ref, "search")}
        styleName="searchInput"
        type="text"
        placeholder="Find a view"
        value={searchTerm}
        onChange={onSearch}
      />
    </div>
  ),
  css
);

const ViewRow = CSSModules(
  ({ isActive, id, registerRef, name, icon, onClick }) => (
    <div
      ref={ref => registerRef(ref, id)}
      styleName="view"
      onClick={onClick}
      role="button"
      tabIndex="0"
    >
      <div styleName="viewActive">
        {isActive ? <i className="material-icons">check</i> : null}
      </div>
      <div styleName="viewIcon">{icon}</div>
      <div styleName="viewName">{name}</div>
    </div>
  ),
  css
);

const Popover = ({
  searchTerm,
  onSearch,
  onClearSearch,
  views,
  countOfMatchingViews,
  registerRef,
  addView,
  toggleAddView,
  addingView,
  tempName,
  updateTempName,
  countOfAllViews,
  moduleId
}) => {
  const inputEl = useRef(null);

  return (
    <div styleName="container">
      <Search
        {...{
          searchTerm,
          onSearch,
          onClearSearch,
          registerRef
        }}
      />
      <div styleName="views">
        {countOfMatchingViews ? (
          views.map(view => (
            <ViewRow key={view.id} registerRef={registerRef} {...view} />
          ))
        ) : (
          <div styleName="empty">
            {countOfAllViews ? "No matching views" : "No views added yet"}
          </div>
        )}
      </div>
      <CanUserDo action={`${moduleId}_manage`}>
        <div styleName="addContainer">
          {addingView ? (
            <Div display="row.flex-start.center" width={1}>
              <input
                value={tempName}
                onChange={e => updateTempName(e.target.value)}
                ref={inputEl}
                style={{
                  width: "250px",
                  color: "rgb(73, 73, 73)",
                  backgroundColor: "white",
                  fontWeight: 600,
                  fontSize: "14px",
                  borderRadius: "4px",
                  height: "32px",
                  border: "1px solid #ccc",
                  padding: "0 9px"
                }}
              />
              <MediumFilledButton
                bg="orange5"
                color="white"
                onClick={() => addView()}
                ml={2}
              >
                Add
              </MediumFilledButton>
              <MediumFilledButton
                bg="gray1"
                color="gray6"
                onClick={() => toggleAddView(false)}
                ml={1}
              >
                Cancel
              </MediumFilledButton>
            </Div>
          ) : (
            <Div
              onClick={() => {
                toggleAddView(true, () => {
                  if (inputEl.current) {
                    inputEl.current.focus();
                    inputEl.current.setSelectionRange(
                      0,
                      inputEl.current.value.length
                    );
                  }
                });
              }}
              width={1}
              display="row.flex-start.center"
              color={{
                default: "gray7",
                hover: "black"
              }}
            >
              <AddIcon
                style={{
                  color: "inherit"
                }}
                mr={1}
              />
              <Div fs={2}>Add a new view</Div>
            </Div>
          )}
        </div>
      </CanUserDo>
    </div>
  );
};

export default CSSModules(Popover, css);
