import React from "react";

import { put, call, takeEvery, all, fork, select } from "redux-saga/effects";
import * as R from "ramda";
import { actions } from "./model";
import { getZones } from "./selectors";
import { makeFuture } from "utils/General/sagas";

import { registerError } from "redux/modules/errors/actions";

import { getCredentials } from "redux/modules/user/selectors";
import { eventId as getEventId } from "redux/modules/event/selectors";
import { eventDetails as getEventDetails } from "redux/modules/event/selectors";
import { userId as getUserId } from "redux/modules/user/selectors";
import { showSnackbar } from "redux/modules/snackbar/actions";
import { showModal } from "redux/modules/modal/actions";

import moveItemInArray from "utils/move-item-in-array";
import Api from "./api";

import CredentialGroupModal from "components/Event/Settings/Module/Modals/FieldGroup";
import DeleteCredentialGroupModal from "components/Event/Settings/Credentials/Modals/DeleteCredentialGroup";
import ModalWrapper from "components/Global/Modal/Wrappers/Black";
import ZoneModal from "components/Event/Settings/Catalog/Modals/Zone";

import {
  actions as addBulkItemsActions,
  getters as addBulkItemsGetters
} from "Event/AddBulkItemsModal";
import { getItemsList } from "Event/AddBulkItemsModal/selectors";

const getParams = function*() {
  const credentials = yield select(getCredentials);
  const eventId = yield select(getEventId);
  const eventDetails = yield select(getEventDetails);
  const userId = yield select(getUserId);

  return {
    credentials,
    eventId,
    eventDetails,
    userId
  };
};

const init = function*() {
  try {
    const { credentials, eventId } = yield call(getParams);

    const { payload: zones } = yield call(Api.getZoneGroups, {
      credentials,
      eventId
    });

    yield put(actions.setInitialData({ zones }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error loading zones"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const fetchZones = function*() {
  try {
    const { credentials, eventId } = yield call(getParams);

    const { payload: zones } = yield call(Api.getZoneGroups, {
      credentials,
      eventId
    });

    yield put(actions.setInitialData({ zones }));
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error loading zones"
        }
      ])
    );
  } finally {
    yield put(actions.setLoading(false));
  }
};

const moveZoneCategoryUp = function*({ payload: { group, currentPosition } }) {
  if (currentPosition > 0) {
    try {
      const { credentials, eventId } = yield call(getParams);

      const zoneGroups = yield select(getZones);

      const currentZoneIdx = zoneGroups.findIndex(z => z.id === group.id);

      if (currentZoneIdx < 1) {
        return false;
      }

      yield call(Api.bulkUpdateZoneGroups, {
        credentials,
        data: {
          eventId,
          bulk: true,
          groups: moveItemInArray(
            zoneGroups,
            currentPosition,
            currentPosition - 1
          ).map((g, order) => ({
            id: g.id,
            order
          }))
        }
      });

      yield call(fetchZones);
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error moving up category"
          }
        ])
      );
    } finally {
      return true;
    }
  } else {
    return false;
  }
};

const moveZoneCategoryDown = function*({
  payload: { group, currentPosition }
}) {
  try {
    const { credentials, eventId } = yield call(getParams);

    const zoneGroups = yield select(getZones);

    const currentZoneIdx = zoneGroups.findIndex(z => z.id === group.id);

    if (currentZoneIdx >= zoneGroups.length - 1) {
      return false;
    }

    yield call(Api.bulkUpdateZoneGroups, {
      credentials,
      data: {
        eventId,
        bulk: true,
        groups: moveItemInArray(
          zoneGroups,
          currentPosition,
          currentPosition + 1
        ).map((g, order) => ({
          id: g.id,
          order
        }))
      }
    });

    yield call(fetchZones);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error moving down category"
        }
      ])
    );
  } finally {
    return true;
  }
};

const moveZoneItemUp = function*({
  payload: { item, groupId, order: currentPosition }
}) {
  if (currentPosition > 0) {
    try {
      const { credentials, eventId } = yield call(getParams);

      const zoneGroups = yield select(getZones);
      const items = R.compose(
        R.propOr([], "zones"),
        R.find(({ id }) => id === groupId)
      )(zoneGroups);

      const currentZoneIdx = items.findIndex(z => z.id === item.id);

      if (currentZoneIdx < 1) {
        return false;
      }

      yield call(Api.bulkUpdateZones, {
        credentials,
        data: {
          eventId,
          bulk: true,
          zones: moveItemInArray(
            items,
            currentPosition,
            currentPosition - 1
          ).map((g, order) => ({
            id: g.id,
            order
          }))
        }
      });

      yield call(fetchZones);
    } catch (error) {
      yield put(
        registerError([
          {
            system: error,
            user: "An error moving up zone"
          }
        ])
      );
    } finally {
      return true;
    }
  } else {
    return false;
  }
};

const moveZoneItemDown = function*({
  payload: { item, groupId, order: currentPosition }
}) {
  try {
    const { credentials, eventId } = yield call(getParams);

    const zoneGroups = yield select(getZones);
    const items = R.compose(
      R.propOr([], "zones"),
      R.find(({ id }) => id === groupId)
    )(zoneGroups);

    const currentZoneIdx = items.findIndex(z => z.id === item.id);

    if (currentZoneIdx >= items.length - 1) {
      return false;
    }

    yield call(Api.bulkUpdateZones, {
      credentials,
      data: {
        eventId,
        bulk: true,
        zones: moveItemInArray(items, currentPosition, currentPosition + 1).map(
          (g, order) => ({
            id: g.id,
            order
          })
        )
      }
    });

    yield call(fetchZones);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error moving down zone"
        }
      ])
    );
  } finally {
    return true;
  }
};

const deleteZoneItem = function*({ payload: { itemId } }) {
  try {
    const { credentials } = yield call(getParams);

    yield call(Api.deleteZone, {
      credentials,
      data: { zoneId: itemId }
    });

    yield put(
      showSnackbar({
        message: "Zone deleted",
        action: "OK"
      })
    );

    yield call(fetchZones);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error deleting zone"
        }
      ])
    );
  }
};

const reorderZoneItems = function*({ payload: newOrder }) {
  try {
    const { credentials, eventId } = yield call(getParams);

    yield call(Api.bulkUpdateZones, {
      credentials,
      data: {
        eventId,
        bulk: true,
        zones: newOrder.map(({ id }, i) => ({ id, order: i }))
      }
    });

    yield call(fetchZones);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error reordering zones"
        }
      ])
    );
  }
};

const handleAddCategory = function*() {
  const handleModal = makeFuture();
  yield put(
    showModal({
      content: (
        <CredentialGroupModal onSave={handleModal.done} label="Category" />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleModal.onRealized);
};

const addCategory = function*() {
  const result = yield call(handleAddCategory);

  if (result) {
    const { credentials, eventDetails } = yield call(getParams);
    const zoneGroups = yield select(getZones);

    yield call(Api.addZoneGroup, {
      credentials,
      data: {
        eventId: eventDetails.id,
        name: result.name,
        order: !R.isNil(R.path([zoneGroups.length - 1, "order"], zoneGroups))
          ? zoneGroups[zoneGroups.length - 1].order + 1
          : 0
      }
    });

    yield call(fetchZones);

    yield put(showSnackbar({ message: "Category added", action: "OK" }));
  }
};

//

const handleAddUpdateZoneItem = function*({ id, clone }) {
  const handleModal = makeFuture();
  yield put(
    showModal({
      content: (
        <ZoneModal
          itemId={id}
          clone={clone}
          onDone={payload => {
            if (id && !clone) {
              handleModal.done({ action: "update", payload });
            } else {
              handleModal.done({ action: "add", payload });
            }
          }}
          onUpdate={payload =>
            handleModal.done({ action: "updateDone", payload })
          }
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleModal.onRealized);
};

const addUpdateZoneItem = function*({
  payload: { id, clone } = { id: null, clone: false }
}) {
  const { credentials } = yield call(getParams);
  const { action, payload } = yield call(handleAddUpdateZoneItem, {
    id,
    clone
  });

  if (action === "updateDone") {
    yield call(fetchZones);
  } else if (action === "update") {
    yield call(Api.updateZone, { credentials, data: payload });
    yield put(showSnackbar({ message: "Zone updated", action: "OK" }));
    yield call(fetchZones);
  } else if (action === "add") {
    yield call(Api.addZone, { credentials, data: payload });
    yield put(showSnackbar({ message: "Zone created", action: "OK" }));
    yield call(fetchZones);
  }
};

//

const handleShowUpdateGroupModal = function*({ group }) {
  const handleModal = makeFuture();
  yield put(
    showModal({
      content: (
        <CredentialGroupModal
          data={{
            ...group
          }}
          onSave={handleModal.done}
          label="Category"
        />
      ),
      wrapper: ModalWrapper
    })
  );
  return yield call(handleModal.onRealized);
};

const showUpdateGroupModal = function*({ payload: { group } }) {
  const result = yield call(handleShowUpdateGroupModal, { group });

  if (result) {
    const { credentials } = yield call(getParams);

    yield call(Api.updateZoneGroup, {
      credentials,
      data: {
        groupId: group.id,
        group: {
          id: group.id,
          name: result.name,
          order: result.order
        }
      }
    });

    yield call(fetchZones);

    yield put(showSnackbar({ message: "Category updated", action: "OK" }));
  }
};

//

const handleShowDeleteGroupModal = function*({ group }) {
  const handleModal = makeFuture();
  yield put(
    showModal({
      content: (
        <DeleteCredentialGroupModal
          name={group.name}
          handleDelete={() => handleModal.done(true)}
          label="Category"
        />
      )
    })
  );
  return yield call(handleModal.onRealized);
};

const showDeleteGroupModal = function*({ payload: { group } }) {
  const result = yield call(handleShowDeleteGroupModal, { group });

  if (result) {
    const { credentials } = yield call(getParams);
    const zoneGroups = yield select(getZones);

    yield call(Api.deleteZoneGroup, {
      credentials,
      data: {
        groupId: group.id,
        replaceWithGroupId: zoneGroups.filter(g => g.id !== group.id)[0].id
      }
    });

    yield call(fetchZones);

    yield put(showSnackbar({ message: "Category deleted", action: "OK" }));
  }
};

//

const saveBulkItems = function*() {
  try {
    const backgroundColor = yield select(addBulkItemsGetters.selectedColor);
    const { eventId, credentials } = yield call(getParams);
    const groupId = yield select(addBulkItemsGetters.groupId);

    const itemsList = yield select(getItemsList);
    const data = {
      bulk: true,
      zones: R.map(item => {
        const itemSplit = R.split(",", item);
        const name = itemSplit[0];
        const codeSplit = R.trim(itemSplit[1] || "");
        const code =
          R.length(codeSplit) > 0
            ? R.slice(0, 3, codeSplit)
            : R.compose(
                R.slice(0, 3),
                R.toUpper,
                R.trim
              )(name);
        return {
          name,
          code,
          groupId,
          eventId,
          parentZoneId: null,
          description: null,
          textColor: "#000",
          backgroundColor
        };
      }, itemsList)
    };

    yield call(Api.addZone, {
      credentials,
      data
    });

    yield all([put(actions.setShowBulkItemsModal(false)), call(fetchZones)]);
  } catch (error) {
    yield put(
      registerError([
        {
          system: error,
          user: "An error ocurred adding items"
        }
      ])
    );
  }
};

//

const showBulkModal = function*() {
  const zones = yield select(getZones);
  yield put(
    addBulkItemsActions.setInitialData({
      groups: zones,
      groupId: R.propOr("", "id", zones[0])
    })
  );
  yield put(actions.setShowBulkItemsModal(true));
};

//

const watchSaveBulkItems = function*() {
  yield takeEvery(addBulkItemsActions.saveBulkItems.type, saveBulkItems);
};

const watchshowBulkModal = function*() {
  yield takeEvery(actions.showBulkModal.type, showBulkModal);
};

const watchDeleteZoneItem = function*() {
  yield takeEvery(actions.deleteZoneItem.type, deleteZoneItem);
};

const watchZoneReorderItems = function*() {
  yield takeEvery(actions.reorderZoneItems.type, reorderZoneItems);
};

const watchMoveZoneItemUp = function*() {
  yield takeEvery(actions.moveZoneItemUp.type, moveZoneItemUp);
};

const watchMoveZoneItemDown = function*() {
  yield takeEvery(actions.moveZoneItemDown.type, moveZoneItemDown);
};

const watchMoveZoneCategoryUp = function*() {
  yield takeEvery(actions.moveZoneCategoryUp.type, moveZoneCategoryUp);
};

const watchMoveZoneCategoryDown = function*() {
  yield takeEvery(actions.moveZoneCategoryDown.type, moveZoneCategoryDown);
};

const watchAddCategory = function*() {
  yield takeEvery(actions.addCategory.type, addCategory);
};

const watchAddUpdateZoneItem = function*() {
  yield takeEvery(actions.addUpdateZoneItem.type, addUpdateZoneItem);
};

const watchShowUpdateGroupModal = function*() {
  yield takeEvery(actions.showUpdateGroupModal.type, showUpdateGroupModal);
};

const watchShowDeleteGroupModal = function*() {
  yield takeEvery(actions.showDeleteGroupModal.type, showDeleteGroupModal);
};

const watchInit = function*() {
  yield takeEvery(actions.init.type, init);
};

const rootSaga = function*() {
  yield all([
    fork(watchDeleteZoneItem),
    fork(watchZoneReorderItems),
    fork(watchMoveZoneItemUp),
    fork(watchMoveZoneItemDown),
    fork(watchMoveZoneCategoryUp),
    fork(watchMoveZoneCategoryDown),
    fork(watchAddCategory),
    fork(watchAddUpdateZoneItem),
    fork(watchShowUpdateGroupModal),
    fork(watchShowDeleteGroupModal),
    fork(watchshowBulkModal),
    fork(watchSaveBulkItems),
    fork(watchInit)
  ]);
};

export default rootSaga;
