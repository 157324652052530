import React from "react";

import { withState, defaultProps, withProps } from "utils/General";
import {
  Div,
  BigOutlineInput,
  Fade,
  DarkTab,
  SmallShadedButton,
  Text2,
  LoadingIcon
} from "components/Base";

const Separator = () => (
  <Div width={1} my={6} bg="neutral2" style={{ height: 1 }} />
);

const InputGroup = ({ children }) => <Div mb={3}>{children}</Div>;

const InputLabel = ({ children }) => (
  <Div fw={3} mb={1} color="gray7">
    {children}
  </Div>
);

const ToggleGroup = ({ children }) => (
  <InputGroup>
    <Div display="row.space-between.flex-start" mt={6}>
      {children}
    </Div>
  </InputGroup>
);

const InputSubtext = ({ children }) => (
  <Div color="gray7" fs={1}>
    {children}
  </Div>
);

const Input = ({ value, onChange, placeholder }) => (
  <BigOutlineInput
    bg="white"
    value={value}
    onChange={onChange}
    style={{ backgroundColor: "white", width: "100%" }}
    placeholder={placeholder}
  />
);

const Sidebar = ({ children }) => (
  <div
    style={{
      width: 350,
      display: "flex",
      flexDirection: "column",
      height: "100%",
      overflowY: "auto"
    }}
  >
    {children}
  </div>
);

const Tab = defaultProps({
  px: 0,
  mr: 0,
  fs: 3,
  height: 50,
  flex: 1
})(DarkTab);

const Tabs = defaultProps({
  bg: "white",
  bb: 1,
  bc: "neutral2",
  display: "row"
})(Div);

const NotificationWell = ({ Icon, children }) => (
  <Div py={3} px={2} bg="neutral1" display="row.flex-start.center">
    <Icon size={30} mr={2} />
    <Text2 bold>{children}</Text2>
  </Div>
);

const DismissableWell = withState(
  "isActive",
  "toggle",
  props => props.isActive
)(({ toggle, isActive, Icon, children }) => (
  <Fade when={isActive} collapse>
    {isActive ? (
      <Div py={3} px={2} bg="success0" display="row.flex-start.center">
        <Icon size={30} mr={2} color="altA5" />
        <Div flex={1}>{children}</Div>
        <SmallShadedButton color="altA7" onClick={() => toggle(!isActive)}>
          Dismiss
        </SmallShadedButton>
      </Div>
    ) : (
      ""
    )}
  </Fade>
));

const Loading = () => (
  <Sidebar>
    <Div display="row.center.center" mt={10}>
      <LoadingIcon size={50} color="neutral4" />
    </Div>
  </Sidebar>
);

const Toolbar = withProps({
  px: 3,
  height: 50,
  display: "row.flex-start.center",
  bg: "white",
  bb: 1,
  bc: "neutral3"
})(Div);

export {
  Separator,
  NotificationWell,
  DismissableWell,
  Tab,
  Tabs,
  InputGroup,
  Sidebar,
  InputLabel,
  ToggleGroup,
  InputSubtext,
  Input,
  Loading,
  Toolbar
};
