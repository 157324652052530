import React from "react";
import { Div, BigFilledButton } from "components/Base";
import Header from "./Header";
import Filter from "./Filter";
import TypeFilter from "./TypeFilter";
import NotificationsList from "./NotificationsList";
import { connect } from "react-redux";
import { bindInstance } from "redux-mvc";
import { getters, actions } from "../model";
import { actions as sliderSidebarActions } from "ui-kit/SliderSidebar/model";
import { getNotificationListFiltered } from "../selectors";
import * as R from "ramda";

const decorate = connect(
  (state, props) => ({
    showFilters: getters.showFilters(state, props),
    notifications: getNotificationListFiltered(state, props),
    countOfVisibleNotifications: getters.countOfVisibleNotifications(
      state,
      props
    )
  }),
  bindInstance({
    closeSidebar: sliderSidebarActions.close,
    markAsRead: actions.markAsRead
  })
);

export const Body = ({
  instanceId,
  closeSidebar,
  markAsRead,
  showFilters,
  notifications,
  countOfVisibleNotifications
}) => (
  <Div
    display="column.flex-start.flex-start"
    width={1}
    style={{ overflowX: "hidden", overflowY: "hidden", height: "100%" }}
  >
    <Header
      pl={4}
      pr={2}
      width={1}
      handleClose={closeSidebar}
      instanceId={instanceId}
    />
    <Filter pl={4} pr={2} width={1} instanceId={instanceId} />
    {showFilters && (
      <TypeFilter pl={4} pr={2} width={1} instanceId={instanceId} />
    )}

    <NotificationsList instanceId={instanceId} />
    {countOfVisibleNotifications ? (
      <Div
        display="row.center.center"
        height={60}
        style={{
          borderTop: "1px solid #eaeaea",
          flexShrink: 0
        }}
        width={1}
      >
        <BigFilledButton
          bg="white"
          width={1}
          isPadded={false}
          onClick={() => markAsRead()}
          bold
          color="#7700d0"
          disabled={R.isEmpty(notifications)}
        >
          Mark all as read
        </BigFilledButton>
      </Div>
    ) : null}
  </Div>
);

export default decorate(Body);
