import Helpers from "utils/Global/Helpers";

export default {
  search: (credentials, uid, page, eventId) =>
    new Promise((success, error) => {
      Helpers.request({
        method: "get",
        url: `${window.__LENND_API_BASE_URL__}/items/variants/inventory/search`,
        qs: {
          uid,
          eventId,
          page,
          pageSize: 10
        },
        credentials,
        success,
        error
      });
    })
};
