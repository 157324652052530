import PropTypes from "prop-types";
import React, { Component } from "react";

import CSSModules from "react-css-modules";
import css from "./styles.scss";

@CSSModules(css)
class FormRowHasChangesCellFormatter extends Component {
  formatValue(props) {
    if (props.value && props.value.value) {
      return props.value.value;
    }
    return false;
  }

  render() {
    let value;
    if (this.formatValue(this.props) === true) {
      value = <span styleName="yes">Yes</span>;
    } else {
      value = <span styleName="no">No</span>;
    }
    return <div>{value}</div>;
  }
}

FormRowHasChangesCellFormatter.propTypes = {
  value: PropTypes.object
};

export default FormRowHasChangesCellFormatter;
