import PropTypes from "prop-types";
import React from "react";
import CSSModules from "react-css-modules";
import styles from "./styles.scss";

const Well = ({ children }) => <div styleName="container">{children}</div>;

Well.propTypes = {
  children: PropTypes.node
};

export default CSSModules(Well, styles);
