import PropTypes from "prop-types";
import React, { Component } from "react";
import Editor from "../../Editors/CalculatedNumber";

export default class CalculatedNumberEditor extends Component {
  render() {
    return (
      <Editor
        {...this.props}
        fields={this.props.rowMetaData.meta.columns}
        settings={this.props.rowMetaData.meta.columnSettings}
        values={this.props.rowMetaData}
      />
    );
  }
}

CalculatedNumberEditor.propTypes = {
  rowMetaData: PropTypes.shape({
    meta: PropTypes.shape({
      columns: PropTypes.array.isRequired,
      columnSettings: PropTypes.shape({
        calculatedNumberExpression: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};
