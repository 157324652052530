import { get } from "lodash";
import PATTERNS from "utils/value-types/filter/patterns";
import toString from "utils/value-types/to-string/source";

const { IS, IS_NOT, CONTAINS, DOES_NOT_CONTAIN } = PATTERNS;

module.exports = ({ pattern, recordValue, filterValue, meta }) => {
  if (!get(recordValue, "value") || !get(filterValue, "value")) {
    return false;
  }

  let toStringRecordValue = toString(get(recordValue, "value"), meta);
  let toStringFilterValue = filterValue.value;

  if (!toStringRecordValue || !toStringFilterValue) return false;

  switch (pattern) {
    case IS.pattern:
      return (
        toStringRecordValue.toLowerCase() === toStringFilterValue.toLowerCase()
      );
    case IS_NOT.pattern:
      return (
        toStringRecordValue.toLowerCase() !== toStringFilterValue.toLowerCase()
      );
    case CONTAINS.pattern:
      return toStringRecordValue
        .toLowerCase()
        .includes(toStringFilterValue.toLowerCase());
    case DOES_NOT_CONTAIN.pattern:
      return !toStringRecordValue
        .toLowerCase()
        .includes(toStringFilterValue.toLowerCase());
    default:
      return false;
  }
};
