import * as R from "ramda";
import React, { useEffect } from "react";
import { connect } from "react-redux";

import { noop } from "utils/General";

import { actions } from "Sponsors/SelfSetup";
import { STEPS } from "Sponsors/SelfSetup/constants";

import { PAYMENT_FORM_ID, PAYMENT_FIELDS } from "Sponsors/SelfSetup/constants";

import { WithThemeConsumer, defaultTh, os, VARIANTS } from "ui-kit/Theme";
import {
  WithFormInstanceProvider,
  Toggle,
  Row,
  Column
} from "ui-kit/Form/View";

import {
  Div,
  Text4,
  Text3,
  Span,
  BigOutlineButton,
  ReplyIcon
} from "components/Base";

import { PageTitle, PageSubTitle, StepDescription } from "Sponsors/Common/View";

import Questions from "./Questions";
import EmailSettings from "./EmailSettings";

const decorate = R.compose(
  WithFormInstanceProvider(R.always(PAYMENT_FORM_ID)),
  WithThemeConsumer({ variant: VARIANTS.BACKGROUND }),
  connect(
    null,
    {
      init: actions.init
    }
  )
);

const PaymentPage = ({ init = noop, Th = defaultTh, ...styleProps }) => {
  useEffect(() => {
    init(STEPS.PAYMENT);
  }, []);

  return (
    <Div display="column" {...os(styleProps)}>
      <PageTitle>Payment & Invoice Settings</PageTitle>
      <StepDescription mt={4}>
        <Text3 {...Th(["color"], { ml: 2 })}>
          Customize how you would like to accept payment via your Sales Portal.
          {/* If you would like to accept payment online, you will be prompted to
          securely connect to Stripe, our preferred payment processor. */}
        </Text3>
      </StepDescription>

      <Row mb={3} mt={5}>
        <Toggle fieldId={PAYMENT_FIELDS.ACCEPT_PAYMENT} />
        <Text3 {...Th(["color"], { ml: 3, bold: true })}>
          Accept payment for items on checkout page
        </Text3>
      </Row>

      {/*
      <Row>
        <Toggle size="small" fieldId={PAYMENT_FIELDS.ACCEPT_PAYMENT} />
        <Column ml={3} mt={4}>
          <PageSubTitle>Payment Settings</PageSubTitle>
          <Text3>
            Let companies pay you online with a credit card.
          </Text3>
        </Column>
      </Row>
      <Questions />
      */}
      {/*
      <Text4 mt={3} bold>
        Review your package & add on item prices:
      </Text4>
      <Row
        {...Th(["bg"], { bra: 1, p: 3, mt: 2, variant: Th.VARIANTS.SURFACE })}
      >
        <BigOutlineButton LeftIcon={ReplyIcon}>Package Prices</BigOutlineButton>
        <BigOutlineButton ml={2} LeftIcon={ReplyIcon}>
          Add-On Items
        </BigOutlineButton>
      </Row>
      <EmailSettings />
      */}
    </Div>
  );
};

export default decorate(PaymentPage);
