import { createHandlers } from "redux-mvc";

import { NAMESPACE } from "./constants";

const iniState = {};

const handlers = createHandlers({
  iniState,
  reducers: {},
  namespace: NAMESPACE
});

export default handlers;
