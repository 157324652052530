import React from "react";

import { Div } from "components/Base";

import { HEIGHTS } from "../constants";

import Fields from "./Fields";

const bodyHeight = 661 - (HEIGHTS.HEADER + HEIGHTS.FOOTER);

const Body = () => (
  <Div width={1} height={bodyHeight}>
    <Fields />
  </Div>
);

export default Body;
