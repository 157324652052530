import { bindActionCreators } from "redux";

import Controller from "./Controller";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { uniqBy } from "lodash";
import { selectFeatureFlag } from "@flopflip/react-redux";
import * as flags from "utils/feature-flags";

import { updateOrganization } from "redux/modules/organization/actions";
import * as ModalActions from "redux/modules/modal/actions";
import * as UserPortalsActions from "redux/modules/user/portals/actions";
import { toggleStarredEvent } from "redux/modules/events/actions";
import { getUserAccessLevels } from "redux/modules/user/access-levels/actions";

import { userAccessLevels } from "redux/modules/user/access-levels/selectors";
import * as UserSelectors from "redux/modules/user/selectors";

import { addEventSetupProgress } from "redux/modules/event/progress/actions";

function mapStateToProps(state) {
  const accessLevels = userAccessLevels(state);
  const levels = {
    organizations: accessLevels.organizations || [],
    events: accessLevels.events || [],
    portals: accessLevels.portals || []
  };
  const orgEvents = levels.organizations.reduce((a, b) => {
    return [...a, ...b.events];
  }, []);

  return {
    user: UserSelectors.user(state),
    organizations: levels.organizations,
    events: levels.events,
    portals: levels.portals,
    orgEvents,
    allEvents: uniqBy(
      [...orgEvents, ...levels.events, ...levels.portals],
      "id"
    ),
    isLenndAdmin: selectFeatureFlag(flags.IS_LENND_ADMIN.NAME)(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getUserAccessLevels,
      addEventSetupProgress,
      updateOrganization,
      toggleStarredEvent,
      ...ModalActions,
      ...UserPortalsActions
    },
    dispatch
  );
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Controller)
);
